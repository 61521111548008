export interface IPitBoxCoords {
	X: number;
	Y: number;
	Z: number;
}

export interface IPitBoxSpots {
	[index: number]: IPitBoxCoords[];
}

export const pitBoxSpots: IPitBoxSpots = {
	// RaceRoom Raceway
	263: [
		{ X: 329.253, Y: 20.983, Z: -283.4 },
		{ X: 342.73, Y: 20.999, Z: -283.945 },
		{ X: 356.697, Y: 21.113, Z: -284.626 },
		{ X: 369.548, Y: 21.236, Z: -285.293 },
		{ X: 385.314, Y: 21.376, Z: -286.113 },
		{ X: 398.992, Y: 21.505, Z: -286.876 },
		{ X: 412.247, Y: 21.624, Z: -287.542 },
		{ X: 427.565, Y: 21.752, Z: -288.289 },
		{ X: 441.481, Y: 21.856, Z: -289.07 },
		{ X: 454.604, Y: 22.019, Z: -289.781 },
		{ X: 469.457, Y: 22.162, Z: -290.485 },
		{ X: 483.576, Y: 22.265, Z: -291.212 },
		{ X: 497.024, Y: 22.396, Z: -291.781 },
		{ X: 512.226, Y: 22.517, Z: -292.626 },
		{ X: 525.434, Y: 22.594, Z: -293.283 },
		{ X: 538.758, Y: 22.701, Z: -293.918 },
		{ X: 554.04, Y: 22.872, Z: -294.765 },
		{ X: 568.044, Y: 22.964, Z: -295.467 },
		{ X: 581.547, Y: 23.065, Z: -296.212 },
		{ X: 329.346, Y: 20.877, Z: -283.395 },
		{ X: 342.69, Y: 20.993, Z: -283.969 },
		{ X: 356.72, Y: 21.144, Z: -284.643 },
		{ X: 369.55, Y: 21.213, Z: -285.293 },
		{ X: 385.338, Y: 21.38, Z: -286.124 },
		{ X: 398.944, Y: 21.49, Z: -286.888 },
		{ X: 412.276, Y: 21.624, Z: -287.572 },
		{ X: 427.621, Y: 21.741, Z: -288.297 },
		{ X: 441.511, Y: 21.857, Z: -289.078 },
		{ X: 454.675, Y: 22.017, Z: -289.785 },
		{ X: 469.471, Y: 22.178, Z: -290.466 },
		{ X: 483.604, Y: 22.255, Z: -291.22 },
		{ X: 496.985, Y: 22.402, Z: -291.775 },
		{ X: 512.264, Y: 22.478, Z: -292.616 },
		{ X: 525.425, Y: 22.608, Z: -293.285 },
		{ X: 538.765, Y: 22.738, Z: -293.932 },
		{ X: 554.066, Y: 22.867, Z: -294.758 },
		{ X: 568.034, Y: 22.949, Z: -295.469 },
		{ X: 581.534, Y: 23.079, Z: -296.2 }
	],
	// RaceRoom Raceway
	264: [
		{ X: 329.253, Y: 20.983, Z: -283.4 },
		{ X: 342.73, Y: 20.999, Z: -283.945 },
		{ X: 356.697, Y: 21.113, Z: -284.626 },
		{ X: 369.548, Y: 21.236, Z: -285.293 },
		{ X: 385.314, Y: 21.376, Z: -286.113 },
		{ X: 398.992, Y: 21.505, Z: -286.876 },
		{ X: 412.247, Y: 21.624, Z: -287.542 },
		{ X: 427.565, Y: 21.752, Z: -288.289 },
		{ X: 441.481, Y: 21.856, Z: -289.07 },
		{ X: 454.604, Y: 22.019, Z: -289.781 },
		{ X: 469.457, Y: 22.162, Z: -290.485 },
		{ X: 483.576, Y: 22.265, Z: -291.212 },
		{ X: 497.024, Y: 22.396, Z: -291.781 },
		{ X: 512.226, Y: 22.517, Z: -292.626 },
		{ X: 525.434, Y: 22.594, Z: -293.283 },
		{ X: 538.758, Y: 22.701, Z: -293.918 },
		{ X: 554.04, Y: 22.872, Z: -294.765 },
		{ X: 568.044, Y: 22.964, Z: -295.467 },
		{ X: 581.547, Y: 23.065, Z: -296.212 },
		{ X: 329.346, Y: 20.877, Z: -283.395 },
		{ X: 342.69, Y: 20.993, Z: -283.969 },
		{ X: 356.72, Y: 21.144, Z: -284.643 },
		{ X: 369.55, Y: 21.213, Z: -285.293 },
		{ X: 385.338, Y: 21.38, Z: -286.124 },
		{ X: 398.944, Y: 21.49, Z: -286.888 },
		{ X: 412.276, Y: 21.624, Z: -287.572 },
		{ X: 427.621, Y: 21.741, Z: -288.297 },
		{ X: 441.511, Y: 21.857, Z: -289.078 },
		{ X: 454.675, Y: 22.017, Z: -289.785 },
		{ X: 469.471, Y: 22.178, Z: -290.466 },
		{ X: 483.604, Y: 22.255, Z: -291.22 },
		{ X: 496.985, Y: 22.402, Z: -291.775 },
		{ X: 512.264, Y: 22.478, Z: -292.616 },
		{ X: 525.425, Y: 22.608, Z: -293.285 },
		{ X: 538.765, Y: 22.738, Z: -293.932 },
		{ X: 554.066, Y: 22.867, Z: -294.758 },
		{ X: 568.034, Y: 22.949, Z: -295.469 },
		{ X: 581.534, Y: 23.079, Z: -296.2 }
	],
	// RaceRoom Raceway
	265: [
		{ X: 329.253, Y: 20.983, Z: -283.4 },
		{ X: 342.73, Y: 20.999, Z: -283.945 },
		{ X: 356.697, Y: 21.113, Z: -284.626 },
		{ X: 369.548, Y: 21.236, Z: -285.293 },
		{ X: 385.314, Y: 21.376, Z: -286.113 },
		{ X: 398.992, Y: 21.505, Z: -286.876 },
		{ X: 412.247, Y: 21.624, Z: -287.542 },
		{ X: 427.565, Y: 21.752, Z: -288.289 },
		{ X: 441.481, Y: 21.856, Z: -289.07 },
		{ X: 454.604, Y: 22.019, Z: -289.781 },
		{ X: 469.457, Y: 22.162, Z: -290.485 },
		{ X: 483.576, Y: 22.265, Z: -291.212 },
		{ X: 497.024, Y: 22.396, Z: -291.781 },
		{ X: 512.226, Y: 22.517, Z: -292.626 },
		{ X: 525.434, Y: 22.594, Z: -293.283 },
		{ X: 538.758, Y: 22.701, Z: -293.918 },
		{ X: 554.04, Y: 22.872, Z: -294.765 },
		{ X: 568.044, Y: 22.964, Z: -295.467 },
		{ X: 581.547, Y: 23.065, Z: -296.212 },
		{ X: 329.346, Y: 20.877, Z: -283.395 },
		{ X: 342.69, Y: 20.993, Z: -283.969 },
		{ X: 356.72, Y: 21.144, Z: -284.643 },
		{ X: 369.55, Y: 21.213, Z: -285.293 },
		{ X: 385.338, Y: 21.38, Z: -286.124 },
		{ X: 398.944, Y: 21.49, Z: -286.888 },
		{ X: 412.276, Y: 21.624, Z: -287.572 },
		{ X: 427.621, Y: 21.741, Z: -288.297 },
		{ X: 441.511, Y: 21.857, Z: -289.078 },
		{ X: 454.675, Y: 22.017, Z: -289.785 },
		{ X: 469.471, Y: 22.178, Z: -290.466 },
		{ X: 483.604, Y: 22.255, Z: -291.22 },
		{ X: 496.985, Y: 22.402, Z: -291.775 },
		{ X: 512.264, Y: 22.478, Z: -292.616 },
		{ X: 525.425, Y: 22.608, Z: -293.285 },
		{ X: 538.765, Y: 22.738, Z: -293.932 },
		{ X: 554.066, Y: 22.867, Z: -294.758 },
		{ X: 568.034, Y: 22.949, Z: -295.469 },
		{ X: 581.534, Y: 23.079, Z: -296.2 }
	],
	// RaceRoom Raceway
	266: [
		{ X: 329.253, Y: 20.983, Z: -283.4 },
		{ X: 342.73, Y: 20.999, Z: -283.945 },
		{ X: 356.697, Y: 21.113, Z: -284.626 },
		{ X: 369.548, Y: 21.236, Z: -285.293 },
		{ X: 385.314, Y: 21.376, Z: -286.113 },
		{ X: 398.992, Y: 21.505, Z: -286.876 },
		{ X: 412.247, Y: 21.624, Z: -287.542 },
		{ X: 427.565, Y: 21.752, Z: -288.289 },
		{ X: 441.481, Y: 21.856, Z: -289.07 },
		{ X: 454.604, Y: 22.019, Z: -289.781 },
		{ X: 469.457, Y: 22.162, Z: -290.485 },
		{ X: 483.576, Y: 22.265, Z: -291.212 },
		{ X: 497.024, Y: 22.396, Z: -291.781 },
		{ X: 512.226, Y: 22.517, Z: -292.626 },
		{ X: 525.434, Y: 22.594, Z: -293.283 },
		{ X: 538.758, Y: 22.701, Z: -293.918 },
		{ X: 554.04, Y: 22.872, Z: -294.765 },
		{ X: 568.044, Y: 22.964, Z: -295.467 },
		{ X: 581.547, Y: 23.065, Z: -296.212 },
		{ X: 329.346, Y: 20.877, Z: -283.395 },
		{ X: 342.69, Y: 20.993, Z: -283.969 },
		{ X: 356.72, Y: 21.144, Z: -284.643 },
		{ X: 369.55, Y: 21.213, Z: -285.293 },
		{ X: 385.338, Y: 21.38, Z: -286.124 },
		{ X: 398.944, Y: 21.49, Z: -286.888 },
		{ X: 412.276, Y: 21.624, Z: -287.572 },
		{ X: 427.621, Y: 21.741, Z: -288.297 },
		{ X: 441.511, Y: 21.857, Z: -289.078 },
		{ X: 454.675, Y: 22.017, Z: -289.785 },
		{ X: 469.471, Y: 22.178, Z: -290.466 },
		{ X: 483.604, Y: 22.255, Z: -291.22 },
		{ X: 496.985, Y: 22.402, Z: -291.775 },
		{ X: 512.264, Y: 22.478, Z: -292.616 },
		{ X: 525.425, Y: 22.608, Z: -293.285 },
		{ X: 538.765, Y: 22.738, Z: -293.932 },
		{ X: 554.066, Y: 22.867, Z: -294.758 },
		{ X: 568.034, Y: 22.949, Z: -295.469 },
		{ X: 581.534, Y: 23.079, Z: -296.2 }
	],
	// RaceRoom Raceway
	267: [
		{ X: 329.253, Y: 20.983, Z: -283.4 },
		{ X: 342.73, Y: 20.999, Z: -283.945 },
		{ X: 356.697, Y: 21.113, Z: -284.626 },
		{ X: 369.548, Y: 21.236, Z: -285.293 },
		{ X: 385.314, Y: 21.376, Z: -286.113 },
		{ X: 398.992, Y: 21.505, Z: -286.876 },
		{ X: 412.247, Y: 21.624, Z: -287.542 },
		{ X: 427.565, Y: 21.752, Z: -288.289 },
		{ X: 441.481, Y: 21.856, Z: -289.07 },
		{ X: 454.604, Y: 22.019, Z: -289.781 },
		{ X: 469.457, Y: 22.162, Z: -290.485 },
		{ X: 483.576, Y: 22.265, Z: -291.212 },
		{ X: 497.024, Y: 22.396, Z: -291.781 },
		{ X: 512.226, Y: 22.517, Z: -292.626 },
		{ X: 525.434, Y: 22.594, Z: -293.283 },
		{ X: 538.758, Y: 22.701, Z: -293.918 },
		{ X: 554.04, Y: 22.872, Z: -294.765 },
		{ X: 568.044, Y: 22.964, Z: -295.467 },
		{ X: 581.547, Y: 23.065, Z: -296.212 },
		{ X: 329.346, Y: 20.877, Z: -283.395 },
		{ X: 342.69, Y: 20.993, Z: -283.969 },
		{ X: 356.72, Y: 21.144, Z: -284.643 },
		{ X: 369.55, Y: 21.213, Z: -285.293 },
		{ X: 385.338, Y: 21.38, Z: -286.124 },
		{ X: 398.944, Y: 21.49, Z: -286.888 },
		{ X: 412.276, Y: 21.624, Z: -287.572 },
		{ X: 427.621, Y: 21.741, Z: -288.297 },
		{ X: 441.511, Y: 21.857, Z: -289.078 },
		{ X: 454.675, Y: 22.017, Z: -289.785 },
		{ X: 469.471, Y: 22.178, Z: -290.466 },
		{ X: 483.604, Y: 22.255, Z: -291.22 },
		{ X: 496.985, Y: 22.402, Z: -291.775 },
		{ X: 512.264, Y: 22.478, Z: -292.616 },
		{ X: 525.425, Y: 22.608, Z: -293.285 },
		{ X: 538.765, Y: 22.738, Z: -293.932 },
		{ X: 554.066, Y: 22.867, Z: -294.758 },
		{ X: 568.034, Y: 22.949, Z: -295.469 },
		{ X: 581.534, Y: 23.079, Z: -296.2 }
	],
	// Monza Circuit
	1671: [
		{ X: 390.982, Y: -26.432, Z: -341.31 },
		{ X: 398.853, Y: -26.503, Z: -342.181 },
		{ X: 406.607, Y: -26.519, Z: -342.956 },
		{ X: 415.328, Y: -26.579, Z: -343.827 },
		{ X: 422.4, Y: -26.582, Z: -344.609 },
		{ X: 431.093, Y: -26.575, Z: -345.415 },
		{ X: 438.467, Y: -26.601, Z: -346.114 },
		{ X: 446.788, Y: -26.628, Z: -347.05 },
		{ X: 454.382, Y: -26.645, Z: -347.802 },
		{ X: 463.244, Y: -26.644, Z: -348.723 },
		{ X: 470.401, Y: -26.647, Z: -349.459 },
		{ X: 478.217, Y: -26.657, Z: -350.28 },
		{ X: 486.108, Y: -26.724, Z: -351.039 },
		{ X: 494.336, Y: -26.713, Z: -351.94 },
		{ X: 502.004, Y: -26.716, Z: -352.709 },
		{ X: 510.421, Y: -26.728, Z: -353.666 },
		{ X: 517.899, Y: -26.736, Z: -354.321 },
		{ X: 526.192, Y: -26.775, Z: -355.116 },
		{ X: 533.547, Y: -26.787, Z: -355.84 },
		{ X: 541.943, Y: -26.776, Z: -356.748 },
		{ X: 549.623, Y: -26.803, Z: -357.539 },
		{ X: 558.236, Y: -26.799, Z: -358.434 },
		{ X: 565.344, Y: -26.83, Z: -359.192 },
		{ X: 575.288, Y: -26.821, Z: -360.226 },
		{ X: 583.326, Y: -26.867, Z: -361.038 },
		{ X: 591.957, Y: -26.892, Z: -361.882 },
		{ X: 599.262, Y: -26.886, Z: -362.622 },
		{ X: 607.781, Y: -26.914, Z: -363.504 },
		{ X: 615.111, Y: -26.891, Z: -364.277 },
		{ X: 390.997, Y: -26.498, Z: -341.314 },
		{ X: 398.86, Y: -26.541, Z: -342.167 },
		{ X: 406.601, Y: -26.557, Z: -342.951 },
		{ X: 415.325, Y: -26.532, Z: -343.827 },
		{ X: 422.395, Y: -26.564, Z: -344.606 },
		{ X: 431.083, Y: -26.609, Z: -345.411 },
		{ X: 438.443, Y: -26.579, Z: -346.104 },
		{ X: 446.722, Y: -26.635, Z: -347.049 },
		{ X: 454.43, Y: -26.649, Z: -347.777 },
		{ X: 463.259, Y: -26.638, Z: -348.729 },
		{ X: 470.404, Y: -26.677, Z: -349.46 },
		{ X: 478.211, Y: -26.693, Z: -350.275 },
		{ X: 486.077, Y: -26.683, Z: -351.045 },
		{ X: 494.245, Y: -26.699, Z: -351.927 },
		{ X: 502.079, Y: -26.713, Z: -352.716 },
		{ X: 510.444, Y: -26.748, Z: -353.675 },
		{ X: 517.853, Y: -26.778, Z: -354.326 },
		{ X: 526.252, Y: -26.774, Z: -355.119 },
		{ X: 533.488, Y: -26.8, Z: -355.84 },
		{ X: 541.953, Y: -26.806, Z: -356.735 },
		{ X: 549.524, Y: -26.793, Z: -357.524 },
		{ X: 558.245, Y: -26.824, Z: -358.42 },
		{ X: 565.303, Y: -26.836, Z: -359.189 },
		{ X: 575.296, Y: -26.842, Z: -360.228 },
		{ X: 583.289, Y: -26.842, Z: -361.024 },
		{ X: 591.92, Y: -26.869, Z: -361.889 },
		{ X: 599.284, Y: -26.887, Z: -362.633 },
		{ X: 607.741, Y: -26.887, Z: -363.509 },
		{ X: 615.123, Y: -26.934, Z: -364.283 }
	],
	// Monza Circuit
	1672: [
		{ X: 390.982, Y: -26.432, Z: -341.31 },
		{ X: 398.853, Y: -26.503, Z: -342.181 },
		{ X: 406.607, Y: -26.519, Z: -342.956 },
		{ X: 415.328, Y: -26.579, Z: -343.827 },
		{ X: 422.4, Y: -26.582, Z: -344.609 },
		{ X: 431.093, Y: -26.575, Z: -345.415 },
		{ X: 438.467, Y: -26.601, Z: -346.114 },
		{ X: 446.788, Y: -26.628, Z: -347.05 },
		{ X: 454.382, Y: -26.645, Z: -347.802 },
		{ X: 463.244, Y: -26.644, Z: -348.723 },
		{ X: 470.401, Y: -26.647, Z: -349.459 },
		{ X: 478.217, Y: -26.657, Z: -350.28 },
		{ X: 486.108, Y: -26.724, Z: -351.039 },
		{ X: 494.336, Y: -26.713, Z: -351.94 },
		{ X: 502.004, Y: -26.716, Z: -352.709 },
		{ X: 510.421, Y: -26.728, Z: -353.666 },
		{ X: 517.899, Y: -26.736, Z: -354.321 },
		{ X: 526.192, Y: -26.775, Z: -355.116 },
		{ X: 533.547, Y: -26.787, Z: -355.84 },
		{ X: 541.943, Y: -26.776, Z: -356.748 },
		{ X: 549.623, Y: -26.803, Z: -357.539 },
		{ X: 558.236, Y: -26.799, Z: -358.434 },
		{ X: 565.344, Y: -26.83, Z: -359.192 },
		{ X: 575.288, Y: -26.821, Z: -360.226 },
		{ X: 583.326, Y: -26.867, Z: -361.038 },
		{ X: 591.957, Y: -26.892, Z: -361.882 },
		{ X: 599.262, Y: -26.886, Z: -362.622 },
		{ X: 607.781, Y: -26.914, Z: -363.504 },
		{ X: 615.111, Y: -26.891, Z: -364.277 },
		{ X: 390.997, Y: -26.498, Z: -341.314 },
		{ X: 398.86, Y: -26.541, Z: -342.167 },
		{ X: 406.601, Y: -26.557, Z: -342.951 },
		{ X: 415.325, Y: -26.532, Z: -343.827 },
		{ X: 422.395, Y: -26.564, Z: -344.606 },
		{ X: 431.083, Y: -26.609, Z: -345.411 },
		{ X: 438.443, Y: -26.579, Z: -346.104 },
		{ X: 446.722, Y: -26.635, Z: -347.049 },
		{ X: 454.43, Y: -26.649, Z: -347.777 },
		{ X: 463.259, Y: -26.638, Z: -348.729 },
		{ X: 470.404, Y: -26.677, Z: -349.46 },
		{ X: 478.211, Y: -26.693, Z: -350.275 },
		{ X: 486.077, Y: -26.683, Z: -351.045 },
		{ X: 494.245, Y: -26.699, Z: -351.927 },
		{ X: 502.079, Y: -26.713, Z: -352.716 },
		{ X: 510.444, Y: -26.748, Z: -353.675 },
		{ X: 517.853, Y: -26.778, Z: -354.326 },
		{ X: 526.252, Y: -26.774, Z: -355.119 },
		{ X: 533.488, Y: -26.8, Z: -355.84 },
		{ X: 541.953, Y: -26.806, Z: -356.735 },
		{ X: 549.524, Y: -26.793, Z: -357.524 },
		{ X: 558.245, Y: -26.824, Z: -358.42 },
		{ X: 565.303, Y: -26.836, Z: -359.189 },
		{ X: 575.296, Y: -26.842, Z: -360.228 },
		{ X: 583.289, Y: -26.842, Z: -361.024 },
		{ X: 591.92, Y: -26.869, Z: -361.889 },
		{ X: 599.284, Y: -26.887, Z: -362.633 },
		{ X: 607.741, Y: -26.887, Z: -363.509 },
		{ X: 615.123, Y: -26.934, Z: -364.283 }
	],
	// Mid Ohio
	1674: [
		{ X: -68.363, Y: 5.771, Z: 41.712 },
		{ X: -56.995, Y: 5.505, Z: 41.148 },
		{ X: -45.535, Y: 5.307, Z: 40.504 },
		{ X: -34.02, Y: 5.091, Z: 39.775 },
		{ X: -22.563, Y: 4.861, Z: 39.068 },
		{ X: -11.333, Y: 4.587, Z: 38.27 },
		{ X: -0.187, Y: 4.257, Z: 37.576 },
		{ X: 10.981, Y: 4.026, Z: 36.973 },
		{ X: 22.172, Y: 3.829, Z: 36.408 },
		{ X: 33.533, Y: 3.643, Z: 35.828 },
		{ X: 44.905, Y: 3.468, Z: 35.273 },
		{ X: 56.167, Y: 3.315, Z: 34.678 },
		{ X: 67.56, Y: 3.118, Z: 34.082 },
		{ X: 78.853, Y: 2.999, Z: 33.381 },
		{ X: 90.154, Y: 3.042, Z: 32.868 },
		{ X: 101.471, Y: 3.027, Z: 32.268 },
		{ X: 112.628, Y: 3.052, Z: 31.747 },
		{ X: 124.024, Y: 2.973, Z: 31.172 },
		{ X: 135.422, Y: 2.809, Z: 30.639 },
		{ X: 146.816, Y: 2.547, Z: 30.145 },
		{ X: 158.391, Y: 2.299, Z: 29.504 },
		{ X: 169.811, Y: 2.041, Z: 28.863 },
		{ X: 181.051, Y: 1.707, Z: 28.366 },
		{ X: 192.314, Y: 1.415, Z: 27.727 },
		{ X: 203.482, Y: 1.159, Z: 27.249 },
		{ X: 214.937, Y: 0.966, Z: 26.638 },
		{ X: -68.301, Y: 5.714, Z: 41.725 },
		{ X: -56.975, Y: 5.53, Z: 41.13 },
		{ X: -45.6, Y: 5.289, Z: 40.494 },
		{ X: -33.939, Y: 5.075, Z: 39.772 },
		{ X: -22.524, Y: 4.83, Z: 39.077 },
		{ X: -11.396, Y: 4.574, Z: 38.277 },
		{ X: -0.172, Y: 4.28, Z: 37.555 },
		{ X: 10.947, Y: 4.01, Z: 36.963 },
		{ X: 22.156, Y: 3.829, Z: 36.398 },
		{ X: 33.548, Y: 3.694, Z: 35.81 },
		{ X: 44.924, Y: 3.471, Z: 35.28 },
		{ X: 56.224, Y: 3.303, Z: 34.688 },
		{ X: 67.544, Y: 3.094, Z: 34.06 },
		{ X: 78.83, Y: 3.071, Z: 33.396 },
		{ X: 90.161, Y: 3.023, Z: 32.873 },
		{ X: 101.55, Y: 3.047, Z: 32.277 },
		{ X: 112.653, Y: 3.003, Z: 31.734 },
		{ X: 124.021, Y: 2.979, Z: 31.186 },
		{ X: 135.38, Y: 2.781, Z: 30.632 },
		{ X: 146.827, Y: 2.573, Z: 30.141 },
		{ X: 158.371, Y: 2.332, Z: 29.498 },
		{ X: 169.832, Y: 2.057, Z: 28.827 },
		{ X: 181.059, Y: 1.73, Z: 28.366 },
		{ X: 192.296, Y: 1.399, Z: 27.719 },
		{ X: 203.493, Y: 1.134, Z: 27.253 },
		{ X: 214.957, Y: 0.982, Z: 26.655 }
	],
	// Mid Ohio
	1675: [
		{ X: -68.363, Y: 5.771, Z: 41.712 },
		{ X: -56.995, Y: 5.505, Z: 41.148 },
		{ X: -45.535, Y: 5.307, Z: 40.504 },
		{ X: -34.02, Y: 5.091, Z: 39.775 },
		{ X: -22.563, Y: 4.861, Z: 39.068 },
		{ X: -11.333, Y: 4.587, Z: 38.27 },
		{ X: -0.187, Y: 4.257, Z: 37.576 },
		{ X: 10.981, Y: 4.026, Z: 36.973 },
		{ X: 22.172, Y: 3.829, Z: 36.408 },
		{ X: 33.533, Y: 3.643, Z: 35.828 },
		{ X: 44.905, Y: 3.468, Z: 35.273 },
		{ X: 56.167, Y: 3.315, Z: 34.678 },
		{ X: 67.56, Y: 3.118, Z: 34.082 },
		{ X: 78.853, Y: 2.999, Z: 33.381 },
		{ X: 90.154, Y: 3.042, Z: 32.868 },
		{ X: 101.471, Y: 3.027, Z: 32.268 },
		{ X: 112.628, Y: 3.052, Z: 31.747 },
		{ X: 124.024, Y: 2.973, Z: 31.172 },
		{ X: 135.422, Y: 2.809, Z: 30.639 },
		{ X: 146.816, Y: 2.547, Z: 30.145 },
		{ X: 158.391, Y: 2.299, Z: 29.504 },
		{ X: 169.811, Y: 2.041, Z: 28.863 },
		{ X: 181.051, Y: 1.707, Z: 28.366 },
		{ X: 192.314, Y: 1.415, Z: 27.727 },
		{ X: 203.482, Y: 1.159, Z: 27.249 },
		{ X: 214.937, Y: 0.966, Z: 26.638 },
		{ X: -68.301, Y: 5.714, Z: 41.725 },
		{ X: -56.975, Y: 5.53, Z: 41.13 },
		{ X: -45.6, Y: 5.289, Z: 40.494 },
		{ X: -33.939, Y: 5.075, Z: 39.772 },
		{ X: -22.524, Y: 4.83, Z: 39.077 },
		{ X: -11.396, Y: 4.574, Z: 38.277 },
		{ X: -0.172, Y: 4.28, Z: 37.555 },
		{ X: 10.947, Y: 4.01, Z: 36.963 },
		{ X: 22.156, Y: 3.829, Z: 36.398 },
		{ X: 33.548, Y: 3.694, Z: 35.81 },
		{ X: 44.924, Y: 3.471, Z: 35.28 },
		{ X: 56.224, Y: 3.303, Z: 34.688 },
		{ X: 67.544, Y: 3.094, Z: 34.06 },
		{ X: 78.83, Y: 3.071, Z: 33.396 },
		{ X: 90.161, Y: 3.023, Z: 32.873 },
		{ X: 101.55, Y: 3.047, Z: 32.277 },
		{ X: 112.653, Y: 3.003, Z: 31.734 },
		{ X: 124.021, Y: 2.979, Z: 31.186 },
		{ X: 135.38, Y: 2.781, Z: 30.632 },
		{ X: 146.827, Y: 2.573, Z: 30.141 },
		{ X: 158.371, Y: 2.332, Z: 29.498 },
		{ X: 169.832, Y: 2.057, Z: 28.827 },
		{ X: 181.059, Y: 1.73, Z: 28.366 },
		{ X: 192.296, Y: 1.399, Z: 27.719 },
		{ X: 203.493, Y: 1.134, Z: 27.253 },
		{ X: 214.957, Y: 0.982, Z: 26.655 }
	],
	// Mid Ohio
	1676: [
		{ X: -68.363, Y: 5.771, Z: 41.712 },
		{ X: -56.995, Y: 5.505, Z: 41.148 },
		{ X: -45.535, Y: 5.307, Z: 40.504 },
		{ X: -34.02, Y: 5.091, Z: 39.775 },
		{ X: -22.563, Y: 4.861, Z: 39.068 },
		{ X: -11.333, Y: 4.587, Z: 38.27 },
		{ X: -0.187, Y: 4.257, Z: 37.576 },
		{ X: 10.981, Y: 4.026, Z: 36.973 },
		{ X: 22.172, Y: 3.829, Z: 36.408 },
		{ X: 33.533, Y: 3.643, Z: 35.828 },
		{ X: 44.905, Y: 3.468, Z: 35.273 },
		{ X: 56.167, Y: 3.315, Z: 34.678 },
		{ X: 67.56, Y: 3.118, Z: 34.082 },
		{ X: 78.853, Y: 2.999, Z: 33.381 },
		{ X: 90.154, Y: 3.042, Z: 32.868 },
		{ X: 101.471, Y: 3.027, Z: 32.268 },
		{ X: 112.628, Y: 3.052, Z: 31.747 },
		{ X: 124.024, Y: 2.973, Z: 31.172 },
		{ X: 135.422, Y: 2.809, Z: 30.639 },
		{ X: 146.816, Y: 2.547, Z: 30.145 },
		{ X: 158.391, Y: 2.299, Z: 29.504 },
		{ X: 169.811, Y: 2.041, Z: 28.863 },
		{ X: 181.051, Y: 1.707, Z: 28.366 },
		{ X: 192.314, Y: 1.415, Z: 27.727 },
		{ X: 203.482, Y: 1.159, Z: 27.249 },
		{ X: 214.937, Y: 0.966, Z: 26.638 },
		{ X: -68.301, Y: 5.714, Z: 41.725 },
		{ X: -56.975, Y: 5.53, Z: 41.13 },
		{ X: -45.6, Y: 5.289, Z: 40.494 },
		{ X: -33.939, Y: 5.075, Z: 39.772 },
		{ X: -22.524, Y: 4.83, Z: 39.077 },
		{ X: -11.396, Y: 4.574, Z: 38.277 },
		{ X: -0.172, Y: 4.28, Z: 37.555 },
		{ X: 10.947, Y: 4.01, Z: 36.963 },
		{ X: 22.156, Y: 3.829, Z: 36.398 },
		{ X: 33.548, Y: 3.694, Z: 35.81 },
		{ X: 44.924, Y: 3.471, Z: 35.28 },
		{ X: 56.224, Y: 3.303, Z: 34.688 },
		{ X: 67.544, Y: 3.094, Z: 34.06 },
		{ X: 78.83, Y: 3.071, Z: 33.396 },
		{ X: 90.161, Y: 3.023, Z: 32.873 },
		{ X: 101.55, Y: 3.047, Z: 32.277 },
		{ X: 112.653, Y: 3.003, Z: 31.734 },
		{ X: 124.021, Y: 2.979, Z: 31.186 },
		{ X: 135.38, Y: 2.781, Z: 30.632 },
		{ X: 146.827, Y: 2.573, Z: 30.141 },
		{ X: 158.371, Y: 2.332, Z: 29.498 },
		{ X: 169.832, Y: 2.057, Z: 28.827 },
		{ X: 181.059, Y: 1.73, Z: 28.366 },
		{ X: 192.296, Y: 1.399, Z: 27.719 },
		{ X: 203.493, Y: 1.134, Z: 27.253 },
		{ X: 214.957, Y: 0.982, Z: 26.655 }
	],
	// Circuit Zandvoort
	1678: [
		{ X: -213.741, Y: 3.084, Z: 474.251 },
		{ X: -217.646, Y: 3.009, Z: 465.185 },
		{ X: -221.439, Y: 3, Z: 456.317 },
		{ X: -225.257, Y: 2.987, Z: 446.994 },
		{ X: -229.113, Y: 2.978, Z: 438.147 },
		{ X: -232.844, Y: 3.002, Z: 429.321 },
		{ X: -236.608, Y: 2.987, Z: 420.185 },
		{ X: -240.43, Y: 2.98, Z: 411.255 },
		{ X: -244.37, Y: 2.992, Z: 402.375 },
		{ X: -248.256, Y: 2.966, Z: 393.237 },
		{ X: -252.034, Y: 2.955, Z: 384.57 },
		{ X: -255.709, Y: 2.965, Z: 375.823 },
		{ X: -258.888, Y: 2.942, Z: 368.691 },
		{ X: -201.862, Y: 2.912, Z: 502.092 },
		{ X: -206.246, Y: 2.973, Z: 492.067 },
		{ X: -209.957, Y: 2.966, Z: 483.086 },
		{ X: -213.81, Y: 3.033, Z: 474.183 },
		{ X: -217.649, Y: 3.037, Z: 465.212 },
		{ X: -221.452, Y: 3.032, Z: 456.298 },
		{ X: -225.279, Y: 3.025, Z: 446.94 },
		{ X: -229.118, Y: 3.012, Z: 438.14 },
		{ X: -232.821, Y: 2.993, Z: 429.359 },
		{ X: -236.625, Y: 2.989, Z: 420.162 },
		{ X: -240.432, Y: 2.998, Z: 411.253 },
		{ X: -244.353, Y: 3.014, Z: 402.428 },
		{ X: -248.266, Y: 2.979, Z: 393.2 },
		{ X: -252.021, Y: 2.97, Z: 384.611 },
		{ X: -255.71, Y: 2.942, Z: 375.815 },
		{ X: -258.856, Y: 2.953, Z: 368.735 },
		{ X: -201.909, Y: 2.909, Z: 502.026 },
		{ X: -206.277, Y: 2.964, Z: 492.032 },
		{ X: -209.924, Y: 2.995, Z: 483.158 }
	],
	// Circuit Zandvoort
	1679: [
		{ X: -213.741, Y: 3.084, Z: 474.251 },
		{ X: -217.646, Y: 3.009, Z: 465.185 },
		{ X: -221.439, Y: 3, Z: 456.317 },
		{ X: -225.257, Y: 2.987, Z: 446.994 },
		{ X: -229.113, Y: 2.978, Z: 438.147 },
		{ X: -232.844, Y: 3.002, Z: 429.321 },
		{ X: -236.608, Y: 2.987, Z: 420.185 },
		{ X: -240.43, Y: 2.98, Z: 411.255 },
		{ X: -244.37, Y: 2.992, Z: 402.375 },
		{ X: -248.256, Y: 2.966, Z: 393.237 },
		{ X: -252.034, Y: 2.955, Z: 384.57 },
		{ X: -255.709, Y: 2.965, Z: 375.823 },
		{ X: -258.888, Y: 2.942, Z: 368.691 },
		{ X: -201.862, Y: 2.912, Z: 502.092 },
		{ X: -206.246, Y: 2.973, Z: 492.067 },
		{ X: -209.957, Y: 2.966, Z: 483.086 },
		{ X: -213.81, Y: 3.033, Z: 474.183 },
		{ X: -217.649, Y: 3.037, Z: 465.212 },
		{ X: -221.452, Y: 3.032, Z: 456.298 },
		{ X: -225.279, Y: 3.025, Z: 446.94 },
		{ X: -229.118, Y: 3.012, Z: 438.14 },
		{ X: -232.821, Y: 2.993, Z: 429.359 },
		{ X: -236.625, Y: 2.989, Z: 420.162 },
		{ X: -240.432, Y: 2.998, Z: 411.253 },
		{ X: -244.353, Y: 3.014, Z: 402.428 },
		{ X: -248.266, Y: 2.979, Z: 393.2 },
		{ X: -252.021, Y: 2.97, Z: 384.611 },
		{ X: -255.71, Y: 2.942, Z: 375.815 },
		{ X: -258.856, Y: 2.953, Z: 368.735 },
		{ X: -201.909, Y: 2.909, Z: 502.026 },
		{ X: -206.277, Y: 2.964, Z: 492.032 },
		{ X: -209.924, Y: 2.995, Z: 483.158 }
	],
	// Circuit Zandvoort
	1680: [
		{ X: -213.741, Y: 3.084, Z: 474.251 },
		{ X: -217.646, Y: 3.009, Z: 465.185 },
		{ X: -221.439, Y: 3, Z: 456.317 },
		{ X: -225.257, Y: 2.987, Z: 446.994 },
		{ X: -229.113, Y: 2.978, Z: 438.147 },
		{ X: -232.844, Y: 3.002, Z: 429.321 },
		{ X: -236.608, Y: 2.987, Z: 420.185 },
		{ X: -240.43, Y: 2.98, Z: 411.255 },
		{ X: -244.37, Y: 2.992, Z: 402.375 },
		{ X: -248.256, Y: 2.966, Z: 393.237 },
		{ X: -252.034, Y: 2.955, Z: 384.57 },
		{ X: -255.709, Y: 2.965, Z: 375.823 },
		{ X: -258.888, Y: 2.942, Z: 368.691 },
		{ X: -201.862, Y: 2.912, Z: 502.092 },
		{ X: -206.246, Y: 2.973, Z: 492.067 },
		{ X: -209.957, Y: 2.966, Z: 483.086 },
		{ X: -213.81, Y: 3.033, Z: 474.183 },
		{ X: -217.649, Y: 3.037, Z: 465.212 },
		{ X: -221.452, Y: 3.032, Z: 456.298 },
		{ X: -225.279, Y: 3.025, Z: 446.94 },
		{ X: -229.118, Y: 3.012, Z: 438.14 },
		{ X: -232.821, Y: 2.993, Z: 429.359 },
		{ X: -236.625, Y: 2.989, Z: 420.162 },
		{ X: -240.432, Y: 2.998, Z: 411.253 },
		{ X: -244.353, Y: 3.014, Z: 402.428 },
		{ X: -248.266, Y: 2.979, Z: 393.2 },
		{ X: -252.021, Y: 2.97, Z: 384.611 },
		{ X: -255.71, Y: 2.942, Z: 375.815 },
		{ X: -258.856, Y: 2.953, Z: 368.735 },
		{ X: -201.909, Y: 2.909, Z: 502.026 },
		{ X: -206.277, Y: 2.964, Z: 492.032 },
		{ X: -209.924, Y: 2.995, Z: 483.158 }
	],
	// Circuit Zolder
	1684: [
		{ X: -299.382, Y: -29.778, Z: -68.522 },
		{ X: -288.963, Y: -29.734, Z: -61.767 },
		{ X: -278.004, Y: -29.618, Z: -54.478 },
		{ X: -266.235, Y: -29.412, Z: -46.92 },
		{ X: -255.265, Y: -29.249, Z: -39.627 },
		{ X: -244.158, Y: -29.145, Z: -32.298 },
		{ X: -233.095, Y: -28.961, Z: -25.043 },
		{ X: -221.949, Y: -28.822, Z: -17.775 },
		{ X: -210.607, Y: -28.654, Z: -10.401 },
		{ X: -199.341, Y: -28.481, Z: -3.052 },
		{ X: -188.383, Y: -28.319, Z: 4.236 },
		{ X: -177.015, Y: -28.171, Z: 11.591 },
		{ X: -166.067, Y: -28.056, Z: 18.78 },
		{ X: -154.679, Y: -27.909, Z: 26.207 },
		{ X: -143.573, Y: -27.89, Z: 33.474 },
		{ X: -132.309, Y: -27.667, Z: 40.762 },
		{ X: -121.259, Y: -27.522, Z: 48.238 },
		{ X: -110.191, Y: -27.405, Z: 55.488 },
		{ X: -98.884, Y: -27.258, Z: 62.851 },
		{ X: -299.327, Y: -29.836, Z: -68.539 },
		{ X: -288.97, Y: -29.765, Z: -61.771 },
		{ X: -277.989, Y: -29.654, Z: -54.473 },
		{ X: -266.29, Y: -29.399, Z: -46.957 },
		{ X: -255.255, Y: -29.25, Z: -39.622 },
		{ X: -244.198, Y: -29.155, Z: -32.329 },
		{ X: -233.085, Y: -29.008, Z: -25.038 },
		{ X: -221.917, Y: -28.819, Z: -17.744 },
		{ X: -210.62, Y: -28.662, Z: -10.407 },
		{ X: -199.293, Y: -28.499, Z: -3.038 },
		{ X: -188.347, Y: -28.324, Z: 4.266 },
		{ X: -177.065, Y: -28.196, Z: 11.574 },
		{ X: -166.111, Y: -28.043, Z: 18.766 },
		{ X: -154.692, Y: -27.955, Z: 26.2 },
		{ X: -143.581, Y: -27.864, Z: 33.472 },
		{ X: -132.318, Y: -27.716, Z: 40.757 },
		{ X: -121.331, Y: -27.53, Z: 48.194 },
		{ X: -110.185, Y: -27.357, Z: 55.489 },
		{ X: -98.891, Y: -27.24, Z: 62.849 }
	],
	// Nürburgring
	1691: [
		{ X: -1119.549, Y: 111.569, Z: -2026.335 },
		{ X: -1109.866, Y: 111.635, Z: -2016.064 },
		{ X: -1099.812, Y: 111.782, Z: -2005.882 },
		{ X: -1092.085, Y: 111.903, Z: -1997.589 },
		{ X: -1084.027, Y: 112.021, Z: -1989.27 },
		{ X: -1074.074, Y: 112.164, Z: -1978.896 },
		{ X: -1064.415, Y: 112.248, Z: -1968.772 },
		{ X: -1056.898, Y: 112.393, Z: -1960.535 },
		{ X: -1048.803, Y: 112.477, Z: -1952.405 },
		{ X: -1038.89, Y: 112.592, Z: -1942.103 },
		{ X: -1029.242, Y: 112.754, Z: -1931.901 },
		{ X: -1020.759, Y: 112.884, Z: -1922.946 },
		{ X: -1013.392, Y: 112.956, Z: -1915.323 },
		{ X: -1003.407, Y: 113.105, Z: -1904.936 },
		{ X: -993.81, Y: 113.191, Z: -1894.854 },
		{ X: -985.437, Y: 113.326, Z: -1885.885 },
		{ X: -977.664, Y: 113.377, Z: -1877.958 },
		{ X: -967.923, Y: 113.503, Z: -1867.69 },
		{ X: -958.21, Y: 113.646, Z: -1857.542 },
		{ X: -948.559, Y: 113.747, Z: -1847.363 },
		{ X: -939.838, Y: 113.91, Z: -1838.161 },
		{ X: -931.269, Y: 114.014, Z: -1829.22 },
		{ X: -923.198, Y: 114.177, Z: -1821.191 },
		{ X: -1119.505, Y: 111.504, Z: -2026.324 },
		{ X: -1109.912, Y: 111.628, Z: -2016.115 },
		{ X: -1099.841, Y: 111.749, Z: -2005.898 },
		{ X: -1092.031, Y: 111.924, Z: -1997.533 },
		{ X: -1084.002, Y: 112.051, Z: -1989.254 },
		{ X: -1074.121, Y: 112.173, Z: -1978.947 },
		{ X: -1064.435, Y: 112.247, Z: -1968.786 },
		{ X: -1056.911, Y: 112.395, Z: -1960.54 },
		{ X: -1048.822, Y: 112.496, Z: -1952.419 },
		{ X: -1038.93, Y: 112.58, Z: -1942.129 },
		{ X: -1029.232, Y: 112.747, Z: -1931.871 },
		{ X: -1020.794, Y: 112.906, Z: -1922.966 },
		{ X: -1013.395, Y: 112.976, Z: -1915.324 },
		{ X: -1003.392, Y: 113.111, Z: -1904.903 },
		{ X: -993.76, Y: 113.22, Z: -1894.799 },
		{ X: -985.437, Y: 113.337, Z: -1885.886 },
		{ X: -977.667, Y: 113.375, Z: -1877.96 },
		{ X: -967.948, Y: 113.537, Z: -1867.704 },
		{ X: -958.198, Y: 113.62, Z: -1857.553 },
		{ X: -948.581, Y: 113.754, Z: -1847.399 },
		{ X: -939.84, Y: 113.92, Z: -1838.163 },
		{ X: -931.322, Y: 114.039, Z: -1829.251 },
		{ X: -923.153, Y: 114.154, Z: -1821.163 }
	],
	// Nürburgring
	2010: [
		{ X: -1119.52, Y: 111.56, Z: -2026.305 },
		{ X: -1109.864, Y: 111.626, Z: -2016.083 },
		{ X: -1099.871, Y: 111.782, Z: -2005.953 },
		{ X: -1092.051, Y: 111.904, Z: -1997.589 },
		{ X: -1083.949, Y: 112.053, Z: -1989.215 },
		{ X: -1074.119, Y: 112.128, Z: -1978.957 },
		{ X: -1064.432, Y: 112.303, Z: -1968.789 },
		{ X: -1056.865, Y: 112.38, Z: -1960.493 },
		{ X: -1048.759, Y: 112.51, Z: -1952.382 },
		{ X: -1038.924, Y: 112.627, Z: -1942.137 },
		{ X: -1029.241, Y: 112.741, Z: -1931.883 },
		{ X: -1020.759, Y: 112.861, Z: -1922.951 },
		{ X: -1013.404, Y: 112.997, Z: -1915.334 },
		{ X: -1003.393, Y: 113.091, Z: -1904.914 },
		{ X: -993.762, Y: 113.202, Z: -1894.831 },
		{ X: -985.438, Y: 113.309, Z: -1885.895 },
		{ X: -977.65, Y: 113.381, Z: -1877.951 },
		{ X: -967.949, Y: 113.517, Z: -1867.704 },
		{ X: -958.207, Y: 113.647, Z: -1857.556 },
		{ X: -948.55, Y: 113.786, Z: -1847.359 },
		{ X: -939.852, Y: 113.917, Z: -1838.142 },
		{ X: -931.347, Y: 114.031, Z: -1829.244 },
		{ X: -923.19, Y: 114.129, Z: -1821.13 },
		{ X: -1119.534, Y: 111.467, Z: -2026.326 },
		{ X: -1109.868, Y: 111.641, Z: -2016.085 },
		{ X: -1099.8, Y: 111.789, Z: -2005.91 },
		{ X: -1092.036, Y: 111.893, Z: -1997.56 },
		{ X: -1083.984, Y: 112.014, Z: -1989.237 },
		{ X: -1074.09, Y: 112.15, Z: -1978.921 },
		{ X: -1064.452, Y: 112.267, Z: -1968.799 },
		{ X: -1056.885, Y: 112.361, Z: -1960.505 },
		{ X: -1048.762, Y: 112.458, Z: -1952.385 },
		{ X: -1038.917, Y: 112.583, Z: -1942.132 },
		{ X: -1029.283, Y: 112.739, Z: -1931.932 },
		{ X: -1020.755, Y: 112.847, Z: -1922.931 },
		{ X: -1013.379, Y: 112.976, Z: -1915.318 },
		{ X: -1003.441, Y: 113.068, Z: -1904.963 },
		{ X: -993.803, Y: 113.225, Z: -1894.855 },
		{ X: -985.489, Y: 113.292, Z: -1885.948 },
		{ X: -977.632, Y: 113.372, Z: -1877.919 },
		{ X: -967.989, Y: 113.532, Z: -1867.727 },
		{ X: -958.228, Y: 113.648, Z: -1857.574 },
		{ X: -948.574, Y: 113.765, Z: -1847.397 },
		{ X: -939.868, Y: 113.93, Z: -1838.172 },
		{ X: -931.352, Y: 114.017, Z: -1829.248 },
		{ X: -923.183, Y: 114.172, Z: -1821.126 }
	],
	// Nürburgring
	2011: [
		{ X: -1119.52, Y: 111.56, Z: -2026.305 },
		{ X: -1109.864, Y: 111.626, Z: -2016.083 },
		{ X: -1099.871, Y: 111.782, Z: -2005.953 },
		{ X: -1092.051, Y: 111.904, Z: -1997.589 },
		{ X: -1083.949, Y: 112.053, Z: -1989.215 },
		{ X: -1074.119, Y: 112.128, Z: -1978.957 },
		{ X: -1064.432, Y: 112.303, Z: -1968.789 },
		{ X: -1056.865, Y: 112.38, Z: -1960.493 },
		{ X: -1048.759, Y: 112.51, Z: -1952.382 },
		{ X: -1038.924, Y: 112.627, Z: -1942.137 },
		{ X: -1029.241, Y: 112.741, Z: -1931.883 },
		{ X: -1020.759, Y: 112.861, Z: -1922.951 },
		{ X: -1013.404, Y: 112.997, Z: -1915.334 },
		{ X: -1003.393, Y: 113.091, Z: -1904.914 },
		{ X: -993.762, Y: 113.202, Z: -1894.831 },
		{ X: -985.438, Y: 113.309, Z: -1885.895 },
		{ X: -977.65, Y: 113.381, Z: -1877.951 },
		{ X: -967.949, Y: 113.517, Z: -1867.704 },
		{ X: -958.207, Y: 113.647, Z: -1857.556 },
		{ X: -948.55, Y: 113.786, Z: -1847.359 },
		{ X: -939.852, Y: 113.917, Z: -1838.142 },
		{ X: -931.347, Y: 114.031, Z: -1829.244 },
		{ X: -923.19, Y: 114.129, Z: -1821.13 },
		{ X: -1119.534, Y: 111.467, Z: -2026.326 },
		{ X: -1109.868, Y: 111.641, Z: -2016.085 },
		{ X: -1099.8, Y: 111.789, Z: -2005.91 },
		{ X: -1092.036, Y: 111.893, Z: -1997.56 },
		{ X: -1083.984, Y: 112.014, Z: -1989.237 },
		{ X: -1074.09, Y: 112.15, Z: -1978.921 },
		{ X: -1064.452, Y: 112.267, Z: -1968.799 },
		{ X: -1056.885, Y: 112.361, Z: -1960.505 },
		{ X: -1048.762, Y: 112.458, Z: -1952.385 },
		{ X: -1038.917, Y: 112.583, Z: -1942.132 },
		{ X: -1029.283, Y: 112.739, Z: -1931.932 },
		{ X: -1020.755, Y: 112.847, Z: -1922.931 },
		{ X: -1013.379, Y: 112.976, Z: -1915.318 },
		{ X: -1003.441, Y: 113.068, Z: -1904.963 },
		{ X: -993.803, Y: 113.225, Z: -1894.855 },
		{ X: -985.489, Y: 113.292, Z: -1885.948 },
		{ X: -977.632, Y: 113.372, Z: -1877.919 },
		{ X: -967.989, Y: 113.532, Z: -1867.727 },
		{ X: -958.228, Y: 113.648, Z: -1857.574 },
		{ X: -948.574, Y: 113.765, Z: -1847.397 },
		{ X: -939.868, Y: 113.93, Z: -1838.172 },
		{ X: -931.352, Y: 114.017, Z: -1829.248 },
		{ X: -923.183, Y: 114.172, Z: -1821.126 }
	],
	// Nürburgring
	3377: [
		{ X: -1083.839, Y: 112.112, Z: -1988.88 },
		{ X: -1074.094, Y: 112.161, Z: -1978.762 },
		{ X: -1064.48, Y: 112.275, Z: -1968.647 },
		{ X: -1048.512, Y: 112.478, Z: -1951.891 },
		{ X: -1038.714, Y: 112.602, Z: -1941.63 },
		{ X: -1029.1, Y: 112.767, Z: -1931.639 },
		{ X: -1013.088, Y: 112.975, Z: -1914.901 },
		{ X: -993.492, Y: 113.186, Z: -1894.518 },
		{ X: -977.73, Y: 113.376, Z: -1877.865 },
		{ X: -967.873, Y: 113.495, Z: -1867.547 },
		{ X: -958.253, Y: 113.635, Z: -1857.441 },
		{ X: -1109.605, Y: 111.625, Z: -2015.995 },
		{ X: -1119.45, Y: 111.451, Z: -2026.297 },
		{ X: -1099.85, Y: 111.784, Z: -2005.674 },
		{ X: -1003.741, Y: 113.066, Z: -1905.065 },
		{ X: -986.33, Y: 113.312, Z: -1886.805 },
		{ X: -950.209, Y: 113.739, Z: -1848.888 },
		{ X: -942.201, Y: 113.847, Z: -1840.508 },
		{ X: -933.689, Y: 114, Z: -1831.551 },
		{ X: -925.059, Y: 114.119, Z: -1822.735 },
		{ X: -1083.802, Y: 112.059, Z: -1988.84 },
		{ X: -1074.096, Y: 112.125, Z: -1978.762 },
		{ X: -1064.449, Y: 112.268, Z: -1968.604 },
		{ X: -1048.493, Y: 112.479, Z: -1951.861 },
		{ X: -1038.734, Y: 112.616, Z: -1941.64 },
		{ X: -1029.08, Y: 112.732, Z: -1931.604 },
		{ X: -1013.079, Y: 112.983, Z: -1914.9 },
		{ X: -993.492, Y: 113.203, Z: -1894.515 },
		{ X: -977.714, Y: 113.421, Z: -1877.855 },
		{ X: -967.874, Y: 113.5, Z: -1867.548 },
		{ X: -958.229, Y: 113.638, Z: -1857.427 },
		{ X: -1109.6, Y: 111.605, Z: -2015.986 },
		{ X: -1119.46, Y: 111.508, Z: -2026.305 },
		{ X: -1099.875, Y: 111.779, Z: -2005.69 },
		{ X: -1003.73, Y: 113.086, Z: -1905.058 },
		{ X: -986.366, Y: 113.323, Z: -1886.824 },
		{ X: -950.18, Y: 113.76, Z: -1848.87 },
		{ X: -942.207, Y: 113.841, Z: -1840.517 },
		{ X: -933.699, Y: 113.975, Z: -1831.558 },
		{ X: -925.034, Y: 114.121, Z: -1822.719 }
	],
	// Hockenheinring
	1693: [
		{ X: -673.678, Y: -2.243, Z: -48.082 },
		{ X: -668.689, Y: -2.32, Z: -58.446 },
		{ X: -663.712, Y: -2.273, Z: -68.917 },
		{ X: -658.737, Y: -2.276, Z: -79.395 },
		{ X: -653.73, Y: -2.318, Z: -89.799 },
		{ X: -648.783, Y: -2.273, Z: -100.171 },
		{ X: -643.772, Y: -2.294, Z: -110.548 },
		{ X: -638.66, Y: -2.271, Z: -121.165 },
		{ X: -633.739, Y: -2.285, Z: -131.523 },
		{ X: -628.83, Y: -2.223, Z: -141.854 },
		{ X: -623.704, Y: -2.239, Z: -152.286 },
		{ X: -678.788, Y: -2.353, Z: -37.619 },
		{ X: -618.818, Y: -2.227, Z: -162.533 },
		{ X: -688.781, Y: -2.297, Z: -16.801 },
		{ X: -683.803, Y: -2.299, Z: -27.271 },
		{ X: -613.781, Y: -2.214, Z: -172.983 },
		{ X: -608.739, Y: -2.183, Z: -183.538 },
		{ X: -673.663, Y: -2.311, Z: -48.114 },
		{ X: -668.683, Y: -2.266, Z: -58.47 },
		{ X: -663.713, Y: -2.306, Z: -68.9 },
		{ X: -658.738, Y: -2.298, Z: -79.392 },
		{ X: -653.696, Y: -2.256, Z: -89.864 },
		{ X: -648.781, Y: -2.25, Z: -100.172 },
		{ X: -643.782, Y: -2.229, Z: -110.485 },
		{ X: -638.663, Y: -2.271, Z: -121.141 },
		{ X: -633.735, Y: -2.283, Z: -131.545 },
		{ X: -628.827, Y: -2.225, Z: -141.861 },
		{ X: -623.699, Y: -2.205, Z: -152.319 },
		{ X: -678.788, Y: -2.347, Z: -37.632 },
		{ X: -618.811, Y: -2.207, Z: -162.583 },
		{ X: -688.785, Y: -2.338, Z: -16.789 },
		{ X: -683.808, Y: -2.303, Z: -27.248 },
		{ X: -613.776, Y: -2.192, Z: -173 },
		{ X: -608.742, Y: -2.183, Z: -183.521 }
	],
	// Hockenheinring
	1763: [
		{ X: -673.678, Y: -2.243, Z: -48.082 },
		{ X: -668.689, Y: -2.32, Z: -58.446 },
		{ X: -663.712, Y: -2.273, Z: -68.917 },
		{ X: -658.737, Y: -2.276, Z: -79.395 },
		{ X: -653.73, Y: -2.318, Z: -89.799 },
		{ X: -648.783, Y: -2.273, Z: -100.171 },
		{ X: -643.772, Y: -2.294, Z: -110.548 },
		{ X: -638.66, Y: -2.271, Z: -121.165 },
		{ X: -633.739, Y: -2.285, Z: -131.523 },
		{ X: -628.83, Y: -2.223, Z: -141.854 },
		{ X: -623.704, Y: -2.239, Z: -152.286 },
		{ X: -678.788, Y: -2.353, Z: -37.619 },
		{ X: -618.818, Y: -2.227, Z: -162.533 },
		{ X: -688.781, Y: -2.297, Z: -16.801 },
		{ X: -683.803, Y: -2.299, Z: -27.271 },
		{ X: -613.781, Y: -2.214, Z: -172.983 },
		{ X: -608.739, Y: -2.183, Z: -183.538 },
		{ X: -673.663, Y: -2.311, Z: -48.114 },
		{ X: -668.683, Y: -2.266, Z: -58.47 },
		{ X: -663.713, Y: -2.306, Z: -68.9 },
		{ X: -658.738, Y: -2.298, Z: -79.392 },
		{ X: -653.696, Y: -2.256, Z: -89.864 },
		{ X: -648.781, Y: -2.25, Z: -100.172 },
		{ X: -643.782, Y: -2.229, Z: -110.485 },
		{ X: -638.663, Y: -2.271, Z: -121.141 },
		{ X: -633.735, Y: -2.283, Z: -131.545 },
		{ X: -628.827, Y: -2.225, Z: -141.861 },
		{ X: -623.699, Y: -2.205, Z: -152.319 },
		{ X: -678.788, Y: -2.347, Z: -37.632 },
		{ X: -618.811, Y: -2.207, Z: -162.583 },
		{ X: -688.785, Y: -2.338, Z: -16.789 },
		{ X: -683.808, Y: -2.303, Z: -27.248 }
	],
	// Hockenheinring
	1764: [
		{ X: -673.678, Y: -2.243, Z: -48.082 },
		{ X: -668.689, Y: -2.32, Z: -58.446 },
		{ X: -663.712, Y: -2.273, Z: -68.917 },
		{ X: -658.737, Y: -2.276, Z: -79.395 },
		{ X: -653.73, Y: -2.318, Z: -89.799 },
		{ X: -648.783, Y: -2.273, Z: -100.171 },
		{ X: -643.772, Y: -2.294, Z: -110.548 },
		{ X: -638.66, Y: -2.271, Z: -121.165 },
		{ X: -633.739, Y: -2.285, Z: -131.523 },
		{ X: -628.83, Y: -2.223, Z: -141.854 },
		{ X: -623.704, Y: -2.239, Z: -152.286 },
		{ X: -678.788, Y: -2.353, Z: -37.619 },
		{ X: -618.818, Y: -2.227, Z: -162.533 },
		{ X: -688.781, Y: -2.297, Z: -16.801 },
		{ X: -683.803, Y: -2.299, Z: -27.271 },
		{ X: -613.781, Y: -2.214, Z: -172.983 },
		{ X: -608.739, Y: -2.183, Z: -183.538 },
		{ X: -673.663, Y: -2.311, Z: -48.114 },
		{ X: -668.683, Y: -2.266, Z: -58.47 },
		{ X: -663.713, Y: -2.306, Z: -68.9 },
		{ X: -658.738, Y: -2.298, Z: -79.392 },
		{ X: -653.696, Y: -2.256, Z: -89.864 },
		{ X: -648.781, Y: -2.25, Z: -100.172 },
		{ X: -643.782, Y: -2.229, Z: -110.485 },
		{ X: -638.663, Y: -2.271, Z: -121.141 },
		{ X: -633.735, Y: -2.283, Z: -131.545 },
		{ X: -628.827, Y: -2.225, Z: -141.861 },
		{ X: -623.699, Y: -2.205, Z: -152.319 },
		{ X: -678.788, Y: -2.347, Z: -37.632 },
		{ X: -618.811, Y: -2.207, Z: -162.583 },
		{ X: -688.785, Y: -2.338, Z: -16.789 },
		{ X: -683.808, Y: -2.303, Z: -27.248 }
	],
	// Portimao Circuit
	1778: [
		{ X: -216.408, Y: 5.001, Z: -70.009 },
		{ X: -202.138, Y: 4.922, Z: -69.865 },
		{ X: -187.49, Y: 4.992, Z: -69.884 },
		{ X: -172.772, Y: 4.987, Z: -69.88 },
		{ X: -157.936, Y: 4.98, Z: -69.87 },
		{ X: -143.793, Y: 4.997, Z: -69.889 },
		{ X: -128.19, Y: 4.976, Z: -69.889 },
		{ X: -113.388, Y: 5.014, Z: -69.812 },
		{ X: -98.692, Y: 4.971, Z: -69.877 },
		{ X: -84.178, Y: 4.99, Z: -69.77 },
		{ X: -69.917, Y: 5.038, Z: -69.786 },
		{ X: -54.9, Y: 4.999, Z: -69.712 },
		{ X: -40.112, Y: 5.034, Z: -69.686 },
		{ X: -25.597, Y: 5.028, Z: -69.691 },
		{ X: -11.015, Y: 5.068, Z: -69.803 },
		{ X: 3.442, Y: 5.123, Z: -69.654 },
		{ X: 19.241, Y: 5.125, Z: -69.602 },
		{ X: 33.783, Y: 5.171, Z: -69.587 },
		{ X: 48.396, Y: 5.16, Z: -69.63 },
		{ X: 63.312, Y: 5.136, Z: -69.576 },
		{ X: 77.423, Y: 5.144, Z: -69.575 },
		{ X: 93.02, Y: 5.159, Z: -69.535 },
		{ X: 108.444, Y: 5.099, Z: -69.737 },
		{ X: -216.335, Y: 4.947, Z: -70.027 },
		{ X: -202.138, Y: 4.968, Z: -69.866 },
		{ X: -187.54, Y: 4.984, Z: -69.867 },
		{ X: -172.774, Y: 4.93, Z: -69.877 },
		{ X: -157.971, Y: 4.985, Z: -69.859 },
		{ X: -143.784, Y: 4.995, Z: -69.89 },
		{ X: -128.216, Y: 4.963, Z: -69.884 },
		{ X: -113.392, Y: 4.987, Z: -69.81 },
		{ X: -98.633, Y: 5, Z: -69.874 },
		{ X: -84.192, Y: 5.035, Z: -69.766 },
		{ X: -69.845, Y: 5.022, Z: -69.785 },
		{ X: -54.947, Y: 5.067, Z: -69.72 },
		{ X: -40.184, Y: 5.031, Z: -69.687 },
		{ X: -25.556, Y: 5.084, Z: -69.682 },
		{ X: -11.012, Y: 5.126, Z: -69.802 },
		{ X: 3.509, Y: 5.099, Z: -69.652 },
		{ X: 19.228, Y: 5.15, Z: -69.6 },
		{ X: 33.784, Y: 5.174, Z: -69.587 },
		{ X: 48.409, Y: 5.177, Z: -69.631 },
		{ X: 63.36, Y: 5.131, Z: -69.567 },
		{ X: 77.409, Y: 5.18, Z: -69.572 },
		{ X: 93.027, Y: 5.157, Z: -69.515 },
		{ X: 108.47, Y: 5.127, Z: -69.742 }
	],
	// Portimao Circuit
	1783: [
		{ X: -216.408, Y: 5.001, Z: -70.009 },
		{ X: -202.138, Y: 4.922, Z: -69.865 },
		{ X: -187.49, Y: 4.992, Z: -69.884 },
		{ X: -172.772, Y: 4.987, Z: -69.88 },
		{ X: -157.936, Y: 4.98, Z: -69.87 },
		{ X: -143.793, Y: 4.997, Z: -69.889 },
		{ X: -128.19, Y: 4.976, Z: -69.889 },
		{ X: -113.388, Y: 5.014, Z: -69.812 },
		{ X: -98.692, Y: 4.971, Z: -69.877 },
		{ X: -84.178, Y: 4.99, Z: -69.77 },
		{ X: -69.917, Y: 5.038, Z: -69.786 },
		{ X: -54.9, Y: 4.999, Z: -69.712 },
		{ X: -40.112, Y: 5.034, Z: -69.686 },
		{ X: -25.597, Y: 5.028, Z: -69.691 },
		{ X: -11.015, Y: 5.068, Z: -69.803 },
		{ X: 3.442, Y: 5.123, Z: -69.654 },
		{ X: 19.241, Y: 5.125, Z: -69.602 },
		{ X: 33.783, Y: 5.171, Z: -69.587 },
		{ X: 48.396, Y: 5.16, Z: -69.63 },
		{ X: 63.312, Y: 5.136, Z: -69.576 },
		{ X: 77.423, Y: 5.144, Z: -69.575 },
		{ X: 93.02, Y: 5.159, Z: -69.535 },
		{ X: 108.444, Y: 5.099, Z: -69.737 },
		{ X: -216.335, Y: 4.947, Z: -70.027 },
		{ X: -202.138, Y: 4.968, Z: -69.866 },
		{ X: -187.54, Y: 4.984, Z: -69.867 },
		{ X: -172.774, Y: 4.93, Z: -69.877 },
		{ X: -157.971, Y: 4.985, Z: -69.859 },
		{ X: -143.784, Y: 4.995, Z: -69.89 },
		{ X: -128.216, Y: 4.963, Z: -69.884 },
		{ X: -113.392, Y: 4.987, Z: -69.81 },
		{ X: -98.633, Y: 5, Z: -69.874 },
		{ X: -84.192, Y: 5.035, Z: -69.766 },
		{ X: -69.845, Y: 5.022, Z: -69.785 },
		{ X: -54.947, Y: 5.067, Z: -69.72 },
		{ X: -40.184, Y: 5.031, Z: -69.687 },
		{ X: -25.556, Y: 5.084, Z: -69.682 },
		{ X: -11.012, Y: 5.126, Z: -69.802 },
		{ X: 3.509, Y: 5.099, Z: -69.652 },
		{ X: 19.228, Y: 5.15, Z: -69.6 },
		{ X: 33.784, Y: 5.174, Z: -69.587 },
		{ X: 48.409, Y: 5.177, Z: -69.631 },
		{ X: 63.36, Y: 5.131, Z: -69.567 },
		{ X: 77.409, Y: 5.18, Z: -69.572 },
		{ X: 93.027, Y: 5.157, Z: -69.515 },
		{ X: 108.47, Y: 5.127, Z: -69.742 }
	],
	// Portimao Circuit
	1784: [
		{ X: -216.408, Y: 5.001, Z: -70.009 },
		{ X: -202.138, Y: 4.922, Z: -69.865 },
		{ X: -187.49, Y: 4.992, Z: -69.884 },
		{ X: -172.772, Y: 4.987, Z: -69.88 },
		{ X: -157.936, Y: 4.98, Z: -69.87 },
		{ X: -143.793, Y: 4.997, Z: -69.889 },
		{ X: -128.19, Y: 4.976, Z: -69.889 },
		{ X: -113.388, Y: 5.014, Z: -69.812 },
		{ X: -98.692, Y: 4.971, Z: -69.877 },
		{ X: -84.178, Y: 4.99, Z: -69.77 },
		{ X: -69.917, Y: 5.038, Z: -69.786 },
		{ X: -54.9, Y: 4.999, Z: -69.712 },
		{ X: -40.112, Y: 5.034, Z: -69.686 },
		{ X: -25.597, Y: 5.028, Z: -69.691 },
		{ X: -11.015, Y: 5.068, Z: -69.803 },
		{ X: 3.442, Y: 5.123, Z: -69.654 },
		{ X: 19.241, Y: 5.125, Z: -69.602 },
		{ X: 33.783, Y: 5.171, Z: -69.587 },
		{ X: 48.396, Y: 5.16, Z: -69.63 },
		{ X: 63.312, Y: 5.136, Z: -69.576 },
		{ X: 77.423, Y: 5.144, Z: -69.575 },
		{ X: 93.02, Y: 5.159, Z: -69.535 },
		{ X: 108.444, Y: 5.099, Z: -69.737 },
		{ X: -216.335, Y: 4.947, Z: -70.027 },
		{ X: -202.138, Y: 4.968, Z: -69.866 },
		{ X: -187.54, Y: 4.984, Z: -69.867 },
		{ X: -172.774, Y: 4.93, Z: -69.877 },
		{ X: -157.971, Y: 4.985, Z: -69.859 },
		{ X: -143.784, Y: 4.995, Z: -69.89 },
		{ X: -128.216, Y: 4.963, Z: -69.884 },
		{ X: -113.392, Y: 4.987, Z: -69.81 },
		{ X: -98.633, Y: 5, Z: -69.874 },
		{ X: -84.192, Y: 5.035, Z: -69.766 },
		{ X: -69.845, Y: 5.022, Z: -69.785 },
		{ X: -54.947, Y: 5.067, Z: -69.72 },
		{ X: -40.184, Y: 5.031, Z: -69.687 },
		{ X: -25.556, Y: 5.084, Z: -69.682 },
		{ X: -11.012, Y: 5.126, Z: -69.802 },
		{ X: 3.509, Y: 5.099, Z: -69.652 },
		{ X: 19.228, Y: 5.15, Z: -69.6 },
		{ X: 33.784, Y: 5.174, Z: -69.587 },
		{ X: 48.409, Y: 5.177, Z: -69.631 },
		{ X: 63.36, Y: 5.131, Z: -69.567 },
		{ X: 77.409, Y: 5.18, Z: -69.572 },
		{ X: 93.027, Y: 5.157, Z: -69.515 },
		{ X: 108.47, Y: 5.127, Z: -69.742 }
	],
	// Portimao Circuit
	1785: [
		{ X: -216.408, Y: 5.001, Z: -70.009 },
		{ X: -202.138, Y: 4.922, Z: -69.865 },
		{ X: -187.49, Y: 4.992, Z: -69.884 },
		{ X: -172.772, Y: 4.987, Z: -69.88 },
		{ X: -157.936, Y: 4.98, Z: -69.87 },
		{ X: -143.793, Y: 4.997, Z: -69.889 },
		{ X: -128.19, Y: 4.976, Z: -69.889 },
		{ X: -113.388, Y: 5.014, Z: -69.812 },
		{ X: -98.692, Y: 4.971, Z: -69.877 },
		{ X: -84.178, Y: 4.99, Z: -69.77 },
		{ X: -69.917, Y: 5.038, Z: -69.786 },
		{ X: -54.9, Y: 4.999, Z: -69.712 },
		{ X: -40.112, Y: 5.034, Z: -69.686 },
		{ X: -25.597, Y: 5.028, Z: -69.691 },
		{ X: -11.015, Y: 5.068, Z: -69.803 },
		{ X: 3.442, Y: 5.123, Z: -69.654 },
		{ X: 19.241, Y: 5.125, Z: -69.602 },
		{ X: 33.783, Y: 5.171, Z: -69.587 },
		{ X: 48.396, Y: 5.16, Z: -69.63 },
		{ X: 63.312, Y: 5.136, Z: -69.576 },
		{ X: 77.423, Y: 5.144, Z: -69.575 },
		{ X: 93.02, Y: 5.159, Z: -69.535 },
		{ X: 108.444, Y: 5.099, Z: -69.737 },
		{ X: -216.335, Y: 4.947, Z: -70.027 },
		{ X: -202.138, Y: 4.968, Z: -69.866 },
		{ X: -187.54, Y: 4.984, Z: -69.867 },
		{ X: -172.774, Y: 4.93, Z: -69.877 },
		{ X: -157.971, Y: 4.985, Z: -69.859 },
		{ X: -143.784, Y: 4.995, Z: -69.89 },
		{ X: -128.216, Y: 4.963, Z: -69.884 },
		{ X: -113.392, Y: 4.987, Z: -69.81 },
		{ X: -98.633, Y: 5, Z: -69.874 },
		{ X: -84.192, Y: 5.035, Z: -69.766 },
		{ X: -69.845, Y: 5.022, Z: -69.785 },
		{ X: -54.947, Y: 5.067, Z: -69.72 },
		{ X: -40.184, Y: 5.031, Z: -69.687 },
		{ X: -25.556, Y: 5.084, Z: -69.682 },
		{ X: -11.012, Y: 5.126, Z: -69.802 },
		{ X: 3.509, Y: 5.099, Z: -69.652 },
		{ X: 19.228, Y: 5.15, Z: -69.6 },
		{ X: 33.784, Y: 5.174, Z: -69.587 },
		{ X: 48.409, Y: 5.177, Z: -69.631 },
		{ X: 63.36, Y: 5.131, Z: -69.567 },
		{ X: 77.409, Y: 5.18, Z: -69.572 },
		{ X: 93.027, Y: 5.157, Z: -69.515 },
		{ X: 108.47, Y: 5.127, Z: -69.742 }
	],
	// Suzuka Circuit
	1841: [
		{ X: 724.952, Y: -13.497, Z: 18.355 },
		{ X: 714.864, Y: -13.131, Z: 23.857 },
		{ X: 702.9, Y: -12.734, Z: 30.12 },
		{ X: 693.518, Y: -12.402, Z: 35.173 },
		{ X: 683.081, Y: -11.936, Z: 40.681 },
		{ X: 673.435, Y: -11.552, Z: 45.807 },
		{ X: 661.242, Y: -11.122, Z: 52.376 },
		{ X: 651.139, Y: -10.684, Z: 57.824 },
		{ X: 641.125, Y: -10.244, Z: 62.873 },
		{ X: 631.89, Y: -9.917, Z: 68.066 },
		{ X: 620.559, Y: -9.449, Z: 74.013 },
		{ X: 609.528, Y: -9.015, Z: 79.842 },
		{ X: 599.167, Y: -8.559, Z: 85.341 },
		{ X: 589.717, Y: -8.211, Z: 90.419 },
		{ X: 579.643, Y: -7.819, Z: 95.828 },
		{ X: 569.171, Y: -7.339, Z: 101.426 },
		{ X: 559.552, Y: -6.951, Z: 106.577 },
		{ X: 550.585, Y: -6.597, Z: 111.231 },
		{ X: 539.977, Y: -6.174, Z: 116.859 },
		{ X: 529.566, Y: -5.792, Z: 122.365 },
		{ X: 517.863, Y: -5.322, Z: 128.628 },
		{ X: 507.355, Y: -4.831, Z: 134.645 },
		{ X: 495.132, Y: -4.308, Z: 141.186 },
		{ X: 724.987, Y: -13.534, Z: 18.358 },
		{ X: 714.831, Y: -13.169, Z: 23.868 },
		{ X: 702.877, Y: -12.747, Z: 30.12 },
		{ X: 693.533, Y: -12.346, Z: 35.159 },
		{ X: 683.106, Y: -11.934, Z: 40.666 },
		{ X: 673.392, Y: -11.613, Z: 45.818 },
		{ X: 661.252, Y: -11.077, Z: 52.368 },
		{ X: 651.151, Y: -10.685, Z: 57.814 },
		{ X: 641.137, Y: -10.256, Z: 62.863 },
		{ X: 631.893, Y: -9.868, Z: 68.063 },
		{ X: 620.505, Y: -9.413, Z: 74.012 },
		{ X: 609.484, Y: -8.959, Z: 79.855 },
		{ X: 599.184, Y: -8.548, Z: 85.345 },
		{ X: 589.722, Y: -8.231, Z: 90.395 },
		{ X: 579.648, Y: -7.802, Z: 95.824 },
		{ X: 569.118, Y: -7.399, Z: 101.448 },
		{ X: 559.509, Y: -6.966, Z: 106.615 },
		{ X: 550.578, Y: -6.608, Z: 111.256 },
		{ X: 539.969, Y: -6.195, Z: 116.865 },
		{ X: 529.596, Y: -5.725, Z: 122.336 },
		{ X: 517.808, Y: -5.293, Z: 128.668 },
		{ X: 507.357, Y: -4.865, Z: 134.623 },
		{ X: 495.143, Y: -4.317, Z: 141.184 }
	],
	// Suzuka Circuit
	2012: [
		{ X: 724.952, Y: -13.497, Z: 18.355 },
		{ X: 714.864, Y: -13.131, Z: 23.857 },
		{ X: 702.9, Y: -12.734, Z: 30.12 },
		{ X: 693.518, Y: -12.402, Z: 35.173 },
		{ X: 683.081, Y: -11.936, Z: 40.681 },
		{ X: 673.435, Y: -11.552, Z: 45.807 },
		{ X: 661.242, Y: -11.122, Z: 52.376 },
		{ X: 651.139, Y: -10.684, Z: 57.824 },
		{ X: 641.125, Y: -10.244, Z: 62.873 },
		{ X: 631.89, Y: -9.917, Z: 68.066 },
		{ X: 620.559, Y: -9.449, Z: 74.013 },
		{ X: 609.528, Y: -9.015, Z: 79.842 },
		{ X: 599.167, Y: -8.559, Z: 85.341 },
		{ X: 589.717, Y: -8.211, Z: 90.419 },
		{ X: 579.643, Y: -7.819, Z: 95.828 },
		{ X: 569.171, Y: -7.339, Z: 101.426 },
		{ X: 559.552, Y: -6.951, Z: 106.577 },
		{ X: 550.585, Y: -6.597, Z: 111.231 },
		{ X: 539.977, Y: -6.174, Z: 116.859 },
		{ X: 529.566, Y: -5.792, Z: 122.365 },
		{ X: 517.863, Y: -5.322, Z: 128.628 },
		{ X: 507.355, Y: -4.831, Z: 134.645 },
		{ X: 495.132, Y: -4.308, Z: 141.186 },
		{ X: 724.987, Y: -13.534, Z: 18.358 },
		{ X: 714.831, Y: -13.169, Z: 23.868 },
		{ X: 702.877, Y: -12.747, Z: 30.12 },
		{ X: 693.533, Y: -12.346, Z: 35.159 },
		{ X: 683.106, Y: -11.934, Z: 40.666 },
		{ X: 673.392, Y: -11.613, Z: 45.818 },
		{ X: 661.252, Y: -11.077, Z: 52.368 },
		{ X: 651.151, Y: -10.685, Z: 57.814 },
		{ X: 641.137, Y: -10.256, Z: 62.863 },
		{ X: 631.893, Y: -9.868, Z: 68.063 },
		{ X: 620.505, Y: -9.413, Z: 74.012 },
		{ X: 609.484, Y: -8.959, Z: 79.855 },
		{ X: 599.184, Y: -8.548, Z: 85.345 },
		{ X: 589.722, Y: -8.231, Z: 90.395 },
		{ X: 579.648, Y: -7.802, Z: 95.824 },
		{ X: 569.118, Y: -7.399, Z: 101.448 },
		{ X: 559.509, Y: -6.966, Z: 106.615 },
		{ X: 550.578, Y: -6.608, Z: 111.256 },
		{ X: 539.969, Y: -6.195, Z: 116.865 },
		{ X: 529.596, Y: -5.725, Z: 122.336 },
		{ X: 517.808, Y: -5.293, Z: 128.668 },
		{ X: 507.357, Y: -4.865, Z: 134.623 },
		{ X: 495.143, Y: -4.317, Z: 141.184 }
	],
	// Suzuka Circuit
	2013: [
		{ X: -445.895, Y: 10.52, Z: -222.429 },
		{ X: -456.14, Y: 10.449, Z: -222.723 },
		{ X: -466.494, Y: 10.438, Z: -223.328 },
		{ X: -477.102, Y: 10.414, Z: -223.767 },
		{ X: -486.935, Y: 10.416, Z: -224.381 },
		{ X: -497.42, Y: 10.434, Z: -224.919 },
		{ X: -507.758, Y: 10.441, Z: -225.46 },
		{ X: -518.02, Y: 10.437, Z: -225.938 },
		{ X: -528.596, Y: 10.396, Z: -226.45 },
		{ X: -538.794, Y: 10.373, Z: -226.749 },
		{ X: -549.113, Y: 10.409, Z: -227.336 },
		{ X: -559.26, Y: 10.344, Z: -228.009 },
		{ X: -569.42, Y: 10.276, Z: -228.293 },
		{ X: -580.365, Y: 10.151, Z: -228.934 },
		{ X: -590.041, Y: 9.977, Z: -229.092 },
		{ X: -597.603, Y: 9.939, Z: -229.254 },
		{ X: -610.517, Y: 9.606, Z: -230.518 },
		{ X: -445.932, Y: 10.462, Z: -222.44 },
		{ X: -456.095, Y: 10.454, Z: -222.712 },
		{ X: -466.503, Y: 10.46, Z: -223.327 },
		{ X: -477.107, Y: 10.41, Z: -223.767 },
		{ X: -486.91, Y: 10.416, Z: -224.384 },
		{ X: -497.42, Y: 10.425, Z: -224.918 },
		{ X: -507.725, Y: 10.395, Z: -225.45 },
		{ X: -518.017, Y: 10.433, Z: -225.94 },
		{ X: -528.614, Y: 10.392, Z: -226.463 },
		{ X: -538.783, Y: 10.406, Z: -226.751 },
		{ X: -549.073, Y: 10.361, Z: -227.339 },
		{ X: -559.256, Y: 10.307, Z: -228.024 },
		{ X: -569.461, Y: 10.257, Z: -228.286 },
		{ X: -580.376, Y: 10.157, Z: -228.934 },
		{ X: -590.07, Y: 9.994, Z: -229.086 },
		{ X: -597.603, Y: 9.939, Z: -229.254 },
		{ X: -610.72, Y: 9.64, Z: -230.357 }
	],
	// Bathurst Circuit
	1846: [
		{ X: 204.515, Y: -82.479, Z: 1071.442 },
		{ X: 220.314, Y: -82.968, Z: 1068.497 },
		{ X: 239.869, Y: -83.46, Z: 1064.674 },
		{ X: 255.395, Y: -83.841, Z: 1061.64 },
		{ X: 273.017, Y: -84.273, Z: 1058.234 },
		{ X: 288.628, Y: -84.645, Z: 1055.19 },
		{ X: 304.093, Y: -85.039, Z: 1052.156 },
		{ X: 319.641, Y: -85.282, Z: 1049.201 },
		{ X: 338.486, Y: -85.551, Z: 1045.528 },
		{ X: 354.046, Y: -85.678, Z: 1042.498 },
		{ X: 369.662, Y: -85.731, Z: 1039.502 },
		{ X: 385.143, Y: -85.8, Z: 1036.482 },
		{ X: 404.37, Y: -85.85, Z: 1032.804 },
		{ X: 420.027, Y: -85.825, Z: 1029.736 },
		{ X: 435.575, Y: -85.867, Z: 1026.742 },
		{ X: 451.107, Y: -86.045, Z: 1023.719 },
		{ X: 470.096, Y: -86.592, Z: 1020.022 },
		{ X: 485.616, Y: -87.129, Z: 1016.976 },
		{ X: 204.668, Y: -82.552, Z: 1071.482 },
		{ X: 220.385, Y: -82.98, Z: 1068.482 },
		{ X: 239.849, Y: -83.463, Z: 1064.676 },
		{ X: 255.449, Y: -83.811, Z: 1061.624 },
		{ X: 272.958, Y: -84.224, Z: 1058.248 },
		{ X: 288.581, Y: -84.64, Z: 1055.184 },
		{ X: 304.09, Y: -85.016, Z: 1052.157 },
		{ X: 319.635, Y: -85.271, Z: 1049.215 },
		{ X: 338.533, Y: -85.561, Z: 1045.51 },
		{ X: 354.044, Y: -85.659, Z: 1042.495 },
		{ X: 369.649, Y: -85.736, Z: 1039.521 },
		{ X: 385.205, Y: -85.8, Z: 1036.478 },
		{ X: 404.439, Y: -85.86, Z: 1032.809 },
		{ X: 419.945, Y: -85.789, Z: 1029.751 },
		{ X: 435.579, Y: -85.853, Z: 1026.744 },
		{ X: 451.108, Y: -86.099, Z: 1023.719 },
		{ X: 470.086, Y: -86.579, Z: 1020.024 },
		{ X: 485.663, Y: -87.17, Z: 1016.978 }
	],
	// Imola
	1850: [
		{ X: 204.693, Y: 4.49, Z: 334.75 },
		{ X: 217.931, Y: 4.333, Z: 334.569 },
		{ X: 231.711, Y: 4.214, Z: 334.381 },
		{ X: 245.327, Y: 4.164, Z: 334.457 },
		{ X: 258.818, Y: 4.094, Z: 334.499 },
		{ X: 272.296, Y: 4.045, Z: 334.472 },
		{ X: 285.802, Y: 3.988, Z: 334.431 },
		{ X: 299.956, Y: 3.955, Z: 334.457 },
		{ X: 313.492, Y: 3.886, Z: 334.516 },
		{ X: 327.482, Y: 3.818, Z: 334.559 },
		{ X: 342.158, Y: 3.776, Z: 334.577 },
		{ X: 355.364, Y: 3.776, Z: 334.485 },
		{ X: 369.445, Y: 3.803, Z: 334.454 },
		{ X: 383.407, Y: 3.776, Z: 334.46 },
		{ X: 397.92, Y: 3.758, Z: 334.373 },
		{ X: 411.789, Y: 3.702, Z: 334.403 },
		{ X: 426.039, Y: 3.699, Z: 334.449 },
		{ X: 439.905, Y: 3.66, Z: 334.544 },
		{ X: 453.721, Y: 3.638, Z: 334.509 },
		{ X: 204.769, Y: 4.431, Z: 334.734 },
		{ X: 204.778, Y: 4.378, Z: 334.728 },
		{ X: 217.929, Y: 4.31, Z: 334.571 },
		{ X: 231.761, Y: 4.232, Z: 334.386 },
		{ X: 245.309, Y: 4.161, Z: 334.462 },
		{ X: 258.812, Y: 4.099, Z: 334.499 },
		{ X: 272.29, Y: 4.01, Z: 334.474 },
		{ X: 285.802, Y: 3.98, Z: 334.432 },
		{ X: 299.962, Y: 3.955, Z: 334.456 },
		{ X: 313.462, Y: 3.855, Z: 334.523 },
		{ X: 327.463, Y: 3.816, Z: 334.567 },
		{ X: 342.127, Y: 3.782, Z: 334.586 },
		{ X: 355.377, Y: 3.812, Z: 334.479 },
		{ X: 369.456, Y: 3.795, Z: 334.452 },
		{ X: 383.377, Y: 3.75, Z: 334.468 },
		{ X: 397.866, Y: 3.764, Z: 334.39 },
		{ X: 411.787, Y: 3.693, Z: 334.403 },
		{ X: 426.051, Y: 3.653, Z: 334.448 },
		{ X: 439.909, Y: 3.645, Z: 334.542 }
	],
	// Indianapolis 2012
	1852: [
		{ X: 547.301, Y: 0.364, Z: -229.139 },
		{ X: 561.841, Y: 0.268, Z: -229.137 },
		{ X: 577.773, Y: 0.269, Z: -229.178 },
		{ X: 592.027, Y: 0.313, Z: -229.11 },
		{ X: 606.131, Y: 0.273, Z: -229.125 },
		{ X: 622.085, Y: 0.265, Z: -229.102 },
		{ X: 639.246, Y: 0.303, Z: -229.119 },
		{ X: 653.345, Y: 0.312, Z: -229.104 },
		{ X: 667.911, Y: 0.309, Z: -229.144 },
		{ X: 681.837, Y: 0.272, Z: -229.082 },
		{ X: 696.239, Y: 0.268, Z: -229.168 },
		{ X: 710.756, Y: 0.308, Z: -229.146 },
		{ X: 725.157, Y: 0.276, Z: -229.102 },
		{ X: 744.432, Y: 0.288, Z: -229.07 },
		{ X: 763.234, Y: 0.288, Z: -229.152 },
		{ X: 777.659, Y: 0.258, Z: -229.16 },
		{ X: 791.921, Y: 0.268, Z: -229.214 },
		{ X: 805.871, Y: 0.274, Z: -229.151 },
		{ X: 820.324, Y: 0.266, Z: -229.054 },
		{ X: 834.768, Y: 0.302, Z: -229.2 },
		{ X: 849.131, Y: 0.292, Z: -229.103 },
		{ X: 863.772, Y: 0.258, Z: -229.222 },
		{ X: 878.241, Y: 0.264, Z: -229.203 },
		{ X: 547.287, Y: 0.296, Z: -229.112 },
		{ X: 561.785, Y: 0.31, Z: -229.12 },
		{ X: 577.799, Y: 0.301, Z: -229.186 },
		{ X: 592.084, Y: 0.262, Z: -229.127 },
		{ X: 606.167, Y: 0.272, Z: -229.134 },
		{ X: 622.087, Y: 0.276, Z: -229.101 },
		{ X: 639.328, Y: 0.315, Z: -229.14 },
		{ X: 653.422, Y: 0.268, Z: -229.125 },
		{ X: 667.944, Y: 0.266, Z: -229.154 },
		{ X: 681.837, Y: 0.257, Z: -229.083 },
		{ X: 696.167, Y: 0.288, Z: -229.169 },
		{ X: 710.744, Y: 0.293, Z: -229.145 },
		{ X: 725.175, Y: 0.311, Z: -229.106 },
		{ X: 744.461, Y: 0.299, Z: -229.077 },
		{ X: 763.258, Y: 0.287, Z: -229.159 },
		{ X: 777.745, Y: 0.308, Z: -229.163 },
		{ X: 791.918, Y: 0.292, Z: -229.214 },
		{ X: 805.861, Y: 0.266, Z: -229.149 },
		{ X: 820.315, Y: 0.28, Z: -229.072 },
		{ X: 834.805, Y: 0.298, Z: -229.209 },
		{ X: 849.177, Y: 0.27, Z: -229.118 },
		{ X: 863.751, Y: 0.307, Z: -229.213 },
		{ X: 878.198, Y: 0.284, Z: -229.21 }
	],
	// Indianapolis 2012
	2014: [
		{ X: 879.592, Y: 0.365, Z: -229.135 },
		{ X: 864.834, Y: 0.288, Z: -228.949 },
		{ X: 850.317, Y: 0.285, Z: -228.947 },
		{ X: 835.983, Y: 0.258, Z: -228.974 },
		{ X: 821.523, Y: 0.286, Z: -228.811 },
		{ X: 807.053, Y: 0.291, Z: -228.911 },
		{ X: 793.137, Y: 0.299, Z: -228.933 },
		{ X: 778.975, Y: 0.283, Z: -228.899 },
		{ X: 764.431, Y: 0.304, Z: -228.861 },
		{ X: 745.353, Y: 0.306, Z: -228.843 },
		{ X: 726.325, Y: 0.264, Z: -228.85 },
		{ X: 711.895, Y: 0.283, Z: -228.914 },
		{ X: 697.433, Y: 0.303, Z: -228.89 },
		{ X: 683.071, Y: 0.26, Z: -228.862 },
		{ X: 669.175, Y: 0.307, Z: -228.908 },
		{ X: 654.548, Y: 0.314, Z: -228.904 },
		{ X: 640.438, Y: 0.261, Z: -228.897 },
		{ X: 621.705, Y: 0.267, Z: -228.862 },
		{ X: 607.266, Y: 0.255, Z: -228.909 },
		{ X: 593.286, Y: 0.262, Z: -228.864 },
		{ X: 578.935, Y: 0.306, Z: -228.877 },
		{ X: 562.95, Y: 0.314, Z: -228.906 },
		{ X: 548.495, Y: 0.278, Z: -228.903 },
		{ X: 526.322, Y: 0.267, Z: -228.817 },
		{ X: 879.579, Y: 0.292, Z: -229.144 },
		{ X: 864.8, Y: 0.306, Z: -228.958 },
		{ X: 850.324, Y: 0.27, Z: -228.945 },
		{ X: 835.969, Y: 0.289, Z: -228.978 },
		{ X: 821.507, Y: 0.263, Z: -228.819 },
		{ X: 807.061, Y: 0.27, Z: -228.907 },
		{ X: 793.078, Y: 0.284, Z: -228.949 },
		{ X: 779.004, Y: 0.298, Z: -228.89 },
		{ X: 764.427, Y: 0.281, Z: -228.86 },
		{ X: 745.361, Y: 0.264, Z: -228.839 },
		{ X: 726.368, Y: 0.279, Z: -228.861 },
		{ X: 711.861, Y: 0.257, Z: -228.901 },
		{ X: 697.366, Y: 0.305, Z: -228.911 },
		{ X: 683.04, Y: 0.29, Z: -228.872 },
		{ X: 669.171, Y: 0.261, Z: -228.908 },
		{ X: 654.588, Y: 0.29, Z: -228.891 },
		{ X: 640.433, Y: 0.31, Z: -228.897 },
		{ X: 621.708, Y: 0.276, Z: -228.862 },
		{ X: 607.271, Y: 0.308, Z: -228.908 },
		{ X: 593.236, Y: 0.26, Z: -228.856 },
		{ X: 578.92, Y: 0.295, Z: -228.88 },
		{ X: 562.977, Y: 0.313, Z: -228.92 }
	],
	// Sonoma Raceway
	1854: [
		{ X: 339.249, Y: -41.886, Z: 95.733 },
		{ X: 351.769, Y: -42.176, Z: 85.004 },
		{ X: 365.933, Y: -42.365, Z: 73.96 },
		{ X: 379.129, Y: -42.515, Z: 63.33 },
		{ X: 393.374, Y: -42.667, Z: 52.068 },
		{ X: 406.611, Y: -42.767, Z: 41.502 },
		{ X: 419.58, Y: -42.941, Z: 31.535 },
		{ X: 433.065, Y: -43.051, Z: 20.907 },
		{ X: 447.433, Y: -43.21, Z: 9.56 },
		{ X: 461.127, Y: -43.322, Z: -1.189 },
		{ X: 475.068, Y: -43.428, Z: -12.166 },
		{ X: 488.556, Y: -43.463, Z: -23.025 },
		{ X: 536.279, Y: -43.676, Z: -68.898 },
		{ X: 540.98, Y: -43.645, Z: -85.572 },
		{ X: 546.249, Y: -43.617, Z: -102.7 },
		{ X: 551.189, Y: -43.664, Z: -119.411 },
		{ X: 556.56, Y: -43.646, Z: -136.775 },
		{ X: 562.108, Y: -43.605, Z: -153.684 },
		{ X: 567.549, Y: -43.631, Z: -170.794 },
		{ X: 572.658, Y: -43.625, Z: -187.695 },
		{ X: 578.105, Y: -43.637, Z: -204.596 },
		{ X: 583.438, Y: -43.687, Z: -221.481 },
		{ X: 588.79, Y: -43.683, Z: -238.607 },
		{ X: 592.768, Y: -43.707, Z: -250.246 },
		{ X: 596.422, Y: -43.666, Z: -260.583 },
		{ X: 599.492, Y: -43.668, Z: -271.588 },
		{ X: 339.324, Y: -41.965, Z: 95.706 },
		{ X: 351.793, Y: -42.167, Z: 84.997 },
		{ X: 365.856, Y: -42.367, Z: 73.996 },
		{ X: 379.129, Y: -42.522, Z: 63.329 },
		{ X: 393.331, Y: -42.649, Z: 52.084 },
		{ X: 406.621, Y: -42.774, Z: 41.479 },
		{ X: 419.59, Y: -42.91, Z: 31.529 },
		{ X: 433.065, Y: -43.031, Z: 20.864 },
		{ X: 447.436, Y: -43.177, Z: 9.521 },
		{ X: 461.171, Y: -43.339, Z: -1.223 },
		{ X: 475.08, Y: -43.429, Z: -12.17 },
		{ X: 488.605, Y: -43.491, Z: -23.071 },
		{ X: 536.279, Y: -43.618, Z: -68.896 },
		{ X: 540.994, Y: -43.689, Z: -85.568 },
		{ X: 546.224, Y: -43.625, Z: -102.673 },
		{ X: 551.159, Y: -43.612, Z: -119.37 },
		{ X: 556.544, Y: -43.628, Z: -136.762 },
		{ X: 562.071, Y: -43.648, Z: -153.655 },
		{ X: 567.55, Y: -43.655, Z: -170.795 },
		{ X: 572.656, Y: -43.671, Z: -187.682 },
		{ X: 578.11, Y: -43.691, Z: -204.595 },
		{ X: 583.452, Y: -43.686, Z: -221.537 },
		{ X: 588.807, Y: -43.671, Z: -238.592 },
		{ X: 592.836, Y: -43.653, Z: -250.319 },
		{ X: 596.569, Y: -43.688, Z: -260.658 },
		{ X: 599.256, Y: -43.66, Z: -272.639 }
	],
	// Sonoma Raceway
	2016: [
		{ X: 339.249, Y: -41.886, Z: 95.733 },
		{ X: 351.769, Y: -42.176, Z: 85.004 },
		{ X: 365.933, Y: -42.365, Z: 73.96 },
		{ X: 379.129, Y: -42.515, Z: 63.33 },
		{ X: 393.374, Y: -42.667, Z: 52.068 },
		{ X: 406.611, Y: -42.767, Z: 41.502 },
		{ X: 419.58, Y: -42.941, Z: 31.535 },
		{ X: 433.065, Y: -43.051, Z: 20.907 },
		{ X: 447.433, Y: -43.21, Z: 9.56 },
		{ X: 461.127, Y: -43.322, Z: -1.189 },
		{ X: 475.068, Y: -43.428, Z: -12.166 },
		{ X: 488.556, Y: -43.463, Z: -23.025 },
		{ X: 536.279, Y: -43.676, Z: -68.898 },
		{ X: 540.98, Y: -43.645, Z: -85.572 },
		{ X: 546.249, Y: -43.617, Z: -102.7 },
		{ X: 551.189, Y: -43.664, Z: -119.411 },
		{ X: 556.56, Y: -43.646, Z: -136.775 },
		{ X: 562.108, Y: -43.605, Z: -153.684 },
		{ X: 567.549, Y: -43.631, Z: -170.794 },
		{ X: 572.658, Y: -43.625, Z: -187.695 },
		{ X: 578.105, Y: -43.637, Z: -204.596 },
		{ X: 583.438, Y: -43.687, Z: -221.481 },
		{ X: 588.79, Y: -43.683, Z: -238.607 },
		{ X: 592.768, Y: -43.707, Z: -250.246 },
		{ X: 596.422, Y: -43.666, Z: -260.583 },
		{ X: 599.492, Y: -43.668, Z: -271.588 },
		{ X: 339.324, Y: -41.965, Z: 95.706 },
		{ X: 351.793, Y: -42.167, Z: 84.997 },
		{ X: 365.856, Y: -42.367, Z: 73.996 },
		{ X: 379.129, Y: -42.522, Z: 63.329 },
		{ X: 393.331, Y: -42.649, Z: 52.084 },
		{ X: 406.621, Y: -42.774, Z: 41.479 },
		{ X: 419.59, Y: -42.91, Z: 31.529 },
		{ X: 433.065, Y: -43.031, Z: 20.864 },
		{ X: 447.436, Y: -43.177, Z: 9.521 },
		{ X: 461.171, Y: -43.339, Z: -1.223 },
		{ X: 475.08, Y: -43.429, Z: -12.17 },
		{ X: 488.605, Y: -43.491, Z: -23.071 },
		{ X: 536.279, Y: -43.618, Z: -68.896 },
		{ X: 540.994, Y: -43.689, Z: -85.568 },
		{ X: 546.224, Y: -43.625, Z: -102.673 },
		{ X: 551.159, Y: -43.612, Z: -119.37 },
		{ X: 556.544, Y: -43.628, Z: -136.762 },
		{ X: 562.071, Y: -43.648, Z: -153.655 },
		{ X: 567.55, Y: -43.655, Z: -170.795 },
		{ X: 572.656, Y: -43.671, Z: -187.682 },
		{ X: 578.11, Y: -43.691, Z: -204.595 },
		{ X: 583.452, Y: -43.686, Z: -221.537 },
		{ X: 588.807, Y: -43.671, Z: -238.592 },
		{ X: 592.836, Y: -43.653, Z: -250.319 },
		{ X: 596.569, Y: -43.688, Z: -260.658 },
		{ X: 599.256, Y: -43.66, Z: -272.639 }
	],
	// Sonoma Raceway
	3912: [
		{ X: 339.249, Y: -41.886, Z: 95.733 },
		{ X: 351.769, Y: -42.176, Z: 85.004 },
		{ X: 365.933, Y: -42.365, Z: 73.96 },
		{ X: 379.129, Y: -42.515, Z: 63.33 },
		{ X: 393.374, Y: -42.667, Z: 52.068 },
		{ X: 406.611, Y: -42.767, Z: 41.502 },
		{ X: 419.58, Y: -42.941, Z: 31.535 },
		{ X: 433.065, Y: -43.051, Z: 20.907 },
		{ X: 447.433, Y: -43.21, Z: 9.56 },
		{ X: 461.127, Y: -43.322, Z: -1.189 },
		{ X: 475.068, Y: -43.428, Z: -12.166 },
		{ X: 488.556, Y: -43.463, Z: -23.025 },
		{ X: 536.279, Y: -43.676, Z: -68.898 },
		{ X: 540.98, Y: -43.645, Z: -85.572 },
		{ X: 546.249, Y: -43.617, Z: -102.7 },
		{ X: 551.189, Y: -43.664, Z: -119.411 },
		{ X: 556.56, Y: -43.646, Z: -136.775 },
		{ X: 562.108, Y: -43.605, Z: -153.684 },
		{ X: 567.549, Y: -43.631, Z: -170.794 },
		{ X: 572.658, Y: -43.625, Z: -187.695 },
		{ X: 578.105, Y: -43.637, Z: -204.596 },
		{ X: 583.438, Y: -43.687, Z: -221.481 },
		{ X: 588.79, Y: -43.683, Z: -238.607 },
		{ X: 592.768, Y: -43.707, Z: -250.246 },
		{ X: 596.422, Y: -43.666, Z: -260.583 },
		{ X: 599.492, Y: -43.668, Z: -271.588 },
		{ X: 339.324, Y: -41.965, Z: 95.706 },
		{ X: 351.793, Y: -42.167, Z: 84.997 },
		{ X: 365.856, Y: -42.367, Z: 73.996 },
		{ X: 379.129, Y: -42.522, Z: 63.329 },
		{ X: 393.331, Y: -42.649, Z: 52.084 },
		{ X: 406.621, Y: -42.774, Z: 41.479 },
		{ X: 419.59, Y: -42.91, Z: 31.529 },
		{ X: 433.065, Y: -43.031, Z: 20.864 },
		{ X: 447.436, Y: -43.177, Z: 9.521 },
		{ X: 461.171, Y: -43.339, Z: -1.223 },
		{ X: 475.08, Y: -43.429, Z: -12.17 },
		{ X: 488.605, Y: -43.491, Z: -23.071 },
		{ X: 536.279, Y: -43.618, Z: -68.896 },
		{ X: 540.994, Y: -43.689, Z: -85.568 },
		{ X: 546.224, Y: -43.625, Z: -102.673 },
		{ X: 551.159, Y: -43.612, Z: -119.37 },
		{ X: 556.544, Y: -43.628, Z: -136.762 },
		{ X: 562.071, Y: -43.648, Z: -153.655 },
		{ X: 567.55, Y: -43.655, Z: -170.795 },
		{ X: 572.656, Y: -43.671, Z: -187.682 },
		{ X: 578.11, Y: -43.691, Z: -204.595 },
		{ X: 583.452, Y: -43.686, Z: -221.537 },
		{ X: 588.807, Y: -43.671, Z: -238.592 },
		{ X: 592.836, Y: -43.653, Z: -250.319 },
		{ X: 596.569, Y: -43.688, Z: -260.658 },
		{ X: 599.256, Y: -43.66, Z: -272.639 }
	],
	// Sonoma Raceway
	3913: [
		{ X: 339.249, Y: -41.886, Z: 95.733 },
		{ X: 351.769, Y: -42.176, Z: 85.004 },
		{ X: 365.933, Y: -42.365, Z: 73.96 },
		{ X: 379.129, Y: -42.515, Z: 63.33 },
		{ X: 393.374, Y: -42.667, Z: 52.068 },
		{ X: 406.611, Y: -42.767, Z: 41.502 },
		{ X: 419.58, Y: -42.941, Z: 31.535 },
		{ X: 433.065, Y: -43.051, Z: 20.907 },
		{ X: 447.433, Y: -43.21, Z: 9.56 },
		{ X: 461.127, Y: -43.322, Z: -1.189 },
		{ X: 475.068, Y: -43.428, Z: -12.166 },
		{ X: 488.556, Y: -43.463, Z: -23.025 },
		{ X: 536.279, Y: -43.676, Z: -68.898 },
		{ X: 540.98, Y: -43.645, Z: -85.572 },
		{ X: 546.249, Y: -43.617, Z: -102.7 },
		{ X: 551.189, Y: -43.664, Z: -119.411 },
		{ X: 556.56, Y: -43.646, Z: -136.775 },
		{ X: 562.108, Y: -43.605, Z: -153.684 },
		{ X: 567.549, Y: -43.631, Z: -170.794 },
		{ X: 572.658, Y: -43.625, Z: -187.695 },
		{ X: 578.105, Y: -43.637, Z: -204.596 },
		{ X: 583.438, Y: -43.687, Z: -221.481 },
		{ X: 588.79, Y: -43.683, Z: -238.607 },
		{ X: 592.768, Y: -43.707, Z: -250.246 },
		{ X: 596.422, Y: -43.666, Z: -260.583 },
		{ X: 599.492, Y: -43.668, Z: -271.588 },
		{ X: 339.324, Y: -41.965, Z: 95.706 },
		{ X: 351.793, Y: -42.167, Z: 84.997 },
		{ X: 365.856, Y: -42.367, Z: 73.996 },
		{ X: 379.129, Y: -42.522, Z: 63.329 },
		{ X: 393.331, Y: -42.649, Z: 52.084 },
		{ X: 406.621, Y: -42.774, Z: 41.479 },
		{ X: 419.59, Y: -42.91, Z: 31.529 },
		{ X: 433.065, Y: -43.031, Z: 20.864 },
		{ X: 447.436, Y: -43.177, Z: 9.521 },
		{ X: 461.171, Y: -43.339, Z: -1.223 },
		{ X: 475.08, Y: -43.429, Z: -12.17 },
		{ X: 488.605, Y: -43.491, Z: -23.071 },
		{ X: 536.279, Y: -43.618, Z: -68.896 },
		{ X: 540.994, Y: -43.689, Z: -85.568 },
		{ X: 546.224, Y: -43.625, Z: -102.673 },
		{ X: 551.159, Y: -43.612, Z: -119.37 },
		{ X: 556.544, Y: -43.628, Z: -136.762 },
		{ X: 562.071, Y: -43.648, Z: -153.655 },
		{ X: 567.55, Y: -43.655, Z: -170.795 },
		{ X: 572.656, Y: -43.671, Z: -187.682 },
		{ X: 578.11, Y: -43.691, Z: -204.595 },
		{ X: 583.452, Y: -43.686, Z: -221.537 },
		{ X: 588.807, Y: -43.671, Z: -238.592 },
		{ X: 592.836, Y: -43.653, Z: -250.319 },
		{ X: 596.569, Y: -43.688, Z: -260.658 },
		{ X: 599.256, Y: -43.66, Z: -272.639 }
	],
	// Weathertech Raceway Laguna Seca
	1856: [
		{ X: -185.508, Y: 0.514, Z: 34.682 },
		{ X: -178.375, Y: 0.039, Z: 43.775 },
		{ X: -170.8, Y: -0.308, Z: 53.422 },
		{ X: -163.282, Y: -0.559, Z: 62.829 },
		{ X: -156.481, Y: -0.72, Z: 71.301 },
		{ X: -148.06, Y: -0.932, Z: 81.698 },
		{ X: -140.638, Y: -1.08, Z: 90.944 },
		{ X: -133.465, Y: -1.204, Z: 99.889 },
		{ X: -126.055, Y: -1.307, Z: 109.401 },
		{ X: -118.82, Y: -1.401, Z: 118.414 },
		{ X: -112.024, Y: -1.416, Z: 126.931 },
		{ X: -105.072, Y: -1.466, Z: 135.608 },
		{ X: -97.951, Y: -1.466, Z: 144.374 },
		{ X: -90.752, Y: -1.461, Z: 153.402 },
		{ X: -84.009, Y: -1.427, Z: 161.97 },
		{ X: -77.777, Y: -1.427, Z: 169.899 },
		{ X: -71.232, Y: -1.27, Z: 178.094 },
		{ X: -64.357, Y: -1.078, Z: 186.721 },
		{ X: -57.645, Y: -0.898, Z: 195.111 },
		{ X: -51.515, Y: -0.73, Z: 202.848 },
		{ X: -44.918, Y: -0.556, Z: 211.202 },
		{ X: -37.706, Y: -0.436, Z: 220.349 },
		{ X: -31.251, Y: -0.311, Z: 228.456 },
		{ X: -25.1, Y: -0.172, Z: 236.242 },
		{ X: -18.732, Y: -0.061, Z: 244.628 },
		{ X: -185.444, Y: 0.461, Z: 34.819 },
		{ X: -178.377, Y: 0.035, Z: 43.777 },
		{ X: -170.771, Y: -0.312, Z: 53.438 },
		{ X: -163.278, Y: -0.624, Z: 62.834 },
		{ X: -156.474, Y: -0.787, Z: 71.312 },
		{ X: -148.053, Y: -0.898, Z: 81.716 },
		{ X: -140.645, Y: -1.053, Z: 90.89 },
		{ X: -133.497, Y: -1.177, Z: 99.872 },
		{ X: -126.068, Y: -1.305, Z: 109.374 },
		{ X: -118.816, Y: -1.393, Z: 118.421 },
		{ X: -112.015, Y: -1.472, Z: 126.956 },
		{ X: -105.071, Y: -1.45, Z: 135.608 },
		{ X: -97.972, Y: -1.462, Z: 144.379 },
		{ X: -90.725, Y: -1.497, Z: 153.444 },
		{ X: -83.981, Y: -1.42, Z: 162.03 },
		{ X: -77.775, Y: -1.432, Z: 169.908 },
		{ X: -71.231, Y: -1.284, Z: 178.098 },
		{ X: -64.328, Y: -1.105, Z: 186.781 },
		{ X: -57.653, Y: -0.885, Z: 195.089 },
		{ X: -51.512, Y: -0.752, Z: 202.81 },
		{ X: -44.905, Y: -0.559, Z: 211.236 },
		{ X: -37.736, Y: -0.484, Z: 220.336 },
		{ X: -31.213, Y: -0.292, Z: 228.497 },
		{ X: -25.104, Y: -0.155, Z: 236.231 },
		{ X: -18.754, Y: -0.049, Z: 244.59 }
	],
	// Hungaroring
	1866: [
		{ X: -17.452, Y: -27.711, Z: -365.23 },
		{ X: 0.534, Y: -27.801, Z: -365.324 },
		{ X: 19.582, Y: -27.748, Z: -365.575 },
		{ X: 38.446, Y: -27.804, Z: -365.534 },
		{ X: 57.238, Y: -27.805, Z: -365.586 },
		{ X: 76.158, Y: -27.744, Z: -365.503 },
		{ X: 94.851, Y: -27.807, Z: -365.425 },
		{ X: 113.923, Y: -27.806, Z: -365.311 },
		{ X: 133.152, Y: -27.73, Z: -365.26 },
		{ X: 151.892, Y: -27.798, Z: -365.409 },
		{ X: 170.659, Y: -27.768, Z: -365.429 },
		{ X: 189.461, Y: -27.779, Z: -365.313 },
		{ X: 234.153, Y: -27.79, Z: -365.054 },
		{ X: -9.391, Y: -27.771, Z: -365.374 },
		{ X: 8.939, Y: -27.733, Z: -365.468 },
		{ X: 28.23, Y: -27.729, Z: -365.434 },
		{ X: 46.755, Y: -27.743, Z: -365.485 },
		{ X: 65.673, Y: -27.772, Z: -365.551 },
		{ X: 84.562, Y: -27.737, Z: -365.385 },
		{ X: 103.464, Y: -27.801, Z: -365.342 },
		{ X: 122.36, Y: -27.773, Z: -365.282 },
		{ X: 141.69, Y: -27.755, Z: -365.265 },
		{ X: 160.409, Y: -27.74, Z: -365.307 },
		{ X: 179.338, Y: -27.729, Z: -365.374 },
		{ X: -17.469, Y: -27.755, Z: -365.232 },
		{ X: 0.52, Y: -27.755, Z: -365.32 },
		{ X: 19.57, Y: -27.741, Z: -365.572 },
		{ X: 38.421, Y: -27.741, Z: -365.528 },
		{ X: 57.246, Y: -27.802, Z: -365.589 },
		{ X: 76.214, Y: -27.771, Z: -365.497 },
		{ X: 94.868, Y: -27.773, Z: -365.428 },
		{ X: 113.868, Y: -27.805, Z: -365.314 },
		{ X: 133.113, Y: -27.803, Z: -365.251 },
		{ X: 151.831, Y: -27.772, Z: -365.411 },
		{ X: 170.609, Y: -27.765, Z: -365.414 },
		{ X: 189.392, Y: -27.732, Z: -365.316 },
		{ X: 234.172, Y: -27.803, Z: -365.043 },
		{ X: -9.401, Y: -27.737, Z: -365.37 },
		{ X: 8.909, Y: -27.737, Z: -365.48 },
		{ X: 28.182, Y: -27.735, Z: -365.443 },
		{ X: 46.813, Y: -27.764, Z: -365.479 },
		{ X: 65.648, Y: -27.799, Z: -365.545 },
		{ X: 84.584, Y: -27.737, Z: -365.39 },
		{ X: 103.426, Y: -27.754, Z: -365.351 },
		{ X: 122.351, Y: -27.78, Z: -365.298 },
		{ X: 141.722, Y: -27.75, Z: -365.254 },
		{ X: 160.358, Y: -27.737, Z: -365.316 },
		{ X: 179.295, Y: -27.731, Z: -365.363 }
	],
	// Salzburgring
	2026: [
		{ X: 64.671, Y: 0.122, Z: 8.427 },
		{ X: 72.088, Y: 0.091, Z: 10.39 },
		{ X: 79.785, Y: 0.06, Z: 12.196 },
		{ X: 87.282, Y: 0.094, Z: 14.107 },
		{ X: 94.966, Y: 0.082, Z: 16.001 },
		{ X: 101.982, Y: 0.068, Z: 17.65 },
		{ X: 110.038, Y: 0.089, Z: 19.714 },
		{ X: 117.689, Y: 0.103, Z: 21.633 },
		{ X: 125.268, Y: 0.071, Z: 23.522 },
		{ X: 132.699, Y: 0.101, Z: 25.337 },
		{ X: 139.907, Y: 0.095, Z: 27.223 },
		{ X: 147.729, Y: 0.108, Z: 29.077 },
		{ X: 155.33, Y: 0.032, Z: 31.032 },
		{ X: 162.691, Y: 0.086, Z: 32.869 },
		{ X: 170.676, Y: 0.105, Z: 35.033 },
		{ X: 177.653, Y: 0.04, Z: 36.769 },
		{ X: 185.192, Y: 0.05, Z: 38.673 },
		{ X: 192.345, Y: 0.099, Z: 40.416 },
		{ X: 200.124, Y: 0.055, Z: 42.5 },
		{ X: 207.763, Y: 0.058, Z: 44.622 },
		{ X: 64.726, Y: 0.091, Z: 8.424 },
		{ X: 72.052, Y: 0.095, Z: 10.39 },
		{ X: 79.773, Y: 0.089, Z: 12.194 },
		{ X: 87.329, Y: 0.099, Z: 14.109 },
		{ X: 94.943, Y: 0.101, Z: 16.002 },
		{ X: 101.925, Y: 0.102, Z: 17.632 },
		{ X: 110.091, Y: 0.041, Z: 19.716 },
		{ X: 117.635, Y: 0.106, Z: 21.616 },
		{ X: 125.284, Y: 0.101, Z: 23.539 },
		{ X: 132.705, Y: 0.049, Z: 25.336 },
		{ X: 139.945, Y: 0.098, Z: 27.224 },
		{ X: 147.79, Y: 0.105, Z: 29.075 },
		{ X: 155.398, Y: 0.106, Z: 31.049 },
		{ X: 162.66, Y: 0.103, Z: 32.868 },
		{ X: 170.716, Y: 0.105, Z: 35.051 },
		{ X: 177.733, Y: 0.077, Z: 36.786 },
		{ X: 185.15, Y: 0.066, Z: 38.654 },
		{ X: 192.326, Y: 0.094, Z: 40.416 },
		{ X: 200.064, Y: 0.08, Z: 42.481 },
		{ X: 207.809, Y: 0.094, Z: 44.626 }
	],
	// Shanghai Circuit
	2027: [
		{ X: -188.858, Y: -0.545, Z: -127.727 },
		{ X: -178.773, Y: -0.632, Z: -125.38 },
		{ X: -167.848, Y: -0.59, Z: -122.738 },
		{ X: -157.643, Y: -0.638, Z: -120.298 },
		{ X: -147.492, Y: -0.632, Z: -117.904 },
		{ X: -137.531, Y: -0.577, Z: -115.567 },
		{ X: -127.332, Y: -0.583, Z: -113.17 },
		{ X: -116.095, Y: -0.624, Z: -110.513 },
		{ X: -105.943, Y: -0.623, Z: -108.04 },
		{ X: -95.659, Y: -0.593, Z: -105.595 },
		{ X: -85.411, Y: -0.604, Z: -103.139 },
		{ X: -71.031, Y: -0.618, Z: -99.727 },
		{ X: -57.582, Y: -0.605, Z: -96.548 },
		{ X: -47.213, Y: -0.577, Z: -94.127 },
		{ X: -37.347, Y: -0.596, Z: -91.809 },
		{ X: -22.797, Y: -0.632, Z: -88.171 },
		{ X: -9.545, Y: -0.623, Z: -85.186 },
		{ X: 1.084, Y: -0.634, Z: -82.737 },
		{ X: 10.999, Y: -0.615, Z: -80.301 },
		{ X: 25.478, Y: -0.636, Z: -76.841 },
		{ X: 38.85, Y: -0.585, Z: -73.74 },
		{ X: 49.254, Y: -0.619, Z: -71.306 },
		{ X: 59.282, Y: -0.598, Z: -68.803 },
		{ X: 73.26, Y: -0.653, Z: -65.534 },
		{ X: -188.776, Y: -0.649, Z: -127.73 },
		{ X: -178.849, Y: -0.599, Z: -125.405 },
		{ X: -167.821, Y: -0.587, Z: -122.718 },
		{ X: -157.607, Y: -0.646, Z: -120.292 },
		{ X: -147.559, Y: -0.649, Z: -117.938 },
		{ X: -137.454, Y: -0.581, Z: -115.547 },
		{ X: -127.328, Y: -0.582, Z: -113.172 },
		{ X: -116.049, Y: -0.608, Z: -110.475 },
		{ X: -105.991, Y: -0.578, Z: -108.076 },
		{ X: -95.624, Y: -0.644, Z: -105.585 },
		{ X: -85.408, Y: -0.601, Z: -103.139 },
		{ X: -71.092, Y: -0.575, Z: -99.742 },
		{ X: -57.549, Y: -0.573, Z: -96.567 },
		{ X: -47.227, Y: -0.579, Z: -94.128 },
		{ X: -37.349, Y: -0.592, Z: -91.806 },
		{ X: -22.777, Y: -0.595, Z: -88.173 },
		{ X: -9.478, Y: -0.624, Z: -85.182 },
		{ X: 1.078, Y: -0.606, Z: -82.749 },
		{ X: 11.028, Y: -0.573, Z: -80.261 },
		{ X: 25.482, Y: -0.612, Z: -76.858 },
		{ X: 38.822, Y: -0.606, Z: -73.723 },
		{ X: 49.244, Y: -0.613, Z: -71.291 },
		{ X: 59.288, Y: -0.6, Z: -68.804 },
		{ X: 73.27, Y: -0.649, Z: -65.535 }
	],
	// Shanghai Circuit
	4041: [
		{ X: -188.858, Y: -0.545, Z: -127.727 },
		{ X: -178.773, Y: -0.632, Z: -125.38 },
		{ X: -167.848, Y: -0.59, Z: -122.738 },
		{ X: -157.643, Y: -0.638, Z: -120.298 },
		{ X: -147.492, Y: -0.632, Z: -117.904 },
		{ X: -137.531, Y: -0.577, Z: -115.567 },
		{ X: -127.332, Y: -0.583, Z: -113.17 },
		{ X: -116.095, Y: -0.624, Z: -110.513 },
		{ X: -105.943, Y: -0.623, Z: -108.04 },
		{ X: -95.659, Y: -0.593, Z: -105.595 },
		{ X: -85.411, Y: -0.604, Z: -103.139 },
		{ X: -71.031, Y: -0.618, Z: -99.727 },
		{ X: -57.582, Y: -0.605, Z: -96.548 },
		{ X: -47.213, Y: -0.577, Z: -94.127 },
		{ X: -37.347, Y: -0.596, Z: -91.809 },
		{ X: -22.797, Y: -0.632, Z: -88.171 },
		{ X: -9.545, Y: -0.623, Z: -85.186 },
		{ X: 1.084, Y: -0.634, Z: -82.737 },
		{ X: 10.999, Y: -0.615, Z: -80.301 },
		{ X: 25.478, Y: -0.636, Z: -76.841 },
		{ X: 38.85, Y: -0.585, Z: -73.74 },
		{ X: 49.254, Y: -0.619, Z: -71.306 },
		{ X: 59.282, Y: -0.598, Z: -68.803 },
		{ X: 73.26, Y: -0.653, Z: -65.534 },
		{ X: -188.776, Y: -0.649, Z: -127.73 },
		{ X: -178.849, Y: -0.599, Z: -125.405 },
		{ X: -167.821, Y: -0.587, Z: -122.718 },
		{ X: -157.607, Y: -0.646, Z: -120.292 },
		{ X: -147.559, Y: -0.649, Z: -117.938 },
		{ X: -137.454, Y: -0.581, Z: -115.547 },
		{ X: -127.328, Y: -0.582, Z: -113.172 },
		{ X: -116.049, Y: -0.608, Z: -110.475 },
		{ X: -105.991, Y: -0.578, Z: -108.076 },
		{ X: -95.624, Y: -0.644, Z: -105.585 },
		{ X: -85.408, Y: -0.601, Z: -103.139 },
		{ X: -71.092, Y: -0.575, Z: -99.742 },
		{ X: -57.549, Y: -0.573, Z: -96.567 },
		{ X: -47.227, Y: -0.579, Z: -94.128 },
		{ X: -37.349, Y: -0.592, Z: -91.806 },
		{ X: -22.777, Y: -0.595, Z: -88.173 },
		{ X: -9.478, Y: -0.624, Z: -85.182 },
		{ X: 1.078, Y: -0.606, Z: -82.749 },
		{ X: 11.028, Y: -0.573, Z: -80.261 },
		{ X: 25.482, Y: -0.612, Z: -76.858 },
		{ X: 38.822, Y: -0.606, Z: -73.723 },
		{ X: 49.244, Y: -0.613, Z: -71.291 },
		{ X: 59.288, Y: -0.6, Z: -68.804 },
		{ X: 73.27, Y: -0.649, Z: -65.535 }
	],
	// Shanghai Circuit
	4042: [
		{ X: -188.858, Y: -0.545, Z: -127.727 },
		{ X: -178.773, Y: -0.632, Z: -125.38 },
		{ X: -167.848, Y: -0.59, Z: -122.738 },
		{ X: -157.643, Y: -0.638, Z: -120.298 },
		{ X: -147.492, Y: -0.632, Z: -117.904 },
		{ X: -137.531, Y: -0.577, Z: -115.567 },
		{ X: -127.332, Y: -0.583, Z: -113.17 },
		{ X: -116.095, Y: -0.624, Z: -110.513 },
		{ X: -105.943, Y: -0.623, Z: -108.04 },
		{ X: -95.659, Y: -0.593, Z: -105.595 },
		{ X: -85.411, Y: -0.604, Z: -103.139 },
		{ X: -71.031, Y: -0.618, Z: -99.727 },
		{ X: -57.582, Y: -0.605, Z: -96.548 },
		{ X: -47.213, Y: -0.577, Z: -94.127 },
		{ X: -37.347, Y: -0.596, Z: -91.809 },
		{ X: -22.797, Y: -0.632, Z: -88.171 },
		{ X: -9.545, Y: -0.623, Z: -85.186 },
		{ X: 1.084, Y: -0.634, Z: -82.737 },
		{ X: 10.999, Y: -0.615, Z: -80.301 },
		{ X: 25.478, Y: -0.636, Z: -76.841 },
		{ X: 38.85, Y: -0.585, Z: -73.74 },
		{ X: 49.254, Y: -0.619, Z: -71.306 },
		{ X: 59.282, Y: -0.598, Z: -68.803 },
		{ X: 73.26, Y: -0.653, Z: -65.534 },
		{ X: -188.776, Y: -0.649, Z: -127.73 },
		{ X: -178.849, Y: -0.599, Z: -125.405 },
		{ X: -167.821, Y: -0.587, Z: -122.718 },
		{ X: -157.607, Y: -0.646, Z: -120.292 },
		{ X: -147.559, Y: -0.649, Z: -117.938 },
		{ X: -137.454, Y: -0.581, Z: -115.547 },
		{ X: -127.328, Y: -0.582, Z: -113.172 },
		{ X: -116.049, Y: -0.608, Z: -110.475 },
		{ X: -105.991, Y: -0.578, Z: -108.076 },
		{ X: -95.624, Y: -0.644, Z: -105.585 },
		{ X: -85.408, Y: -0.601, Z: -103.139 },
		{ X: -71.092, Y: -0.575, Z: -99.742 },
		{ X: -57.549, Y: -0.573, Z: -96.567 },
		{ X: -47.227, Y: -0.579, Z: -94.128 },
		{ X: -37.349, Y: -0.592, Z: -91.806 },
		{ X: -22.777, Y: -0.595, Z: -88.173 },
		{ X: -9.478, Y: -0.624, Z: -85.182 },
		{ X: 1.078, Y: -0.606, Z: -82.749 },
		{ X: 11.028, Y: -0.573, Z: -80.261 },
		{ X: 25.482, Y: -0.612, Z: -76.858 },
		{ X: 38.822, Y: -0.606, Z: -73.723 },
		{ X: 49.244, Y: -0.613, Z: -71.291 },
		{ X: 59.288, Y: -0.6, Z: -68.804 },
		{ X: 73.27, Y: -0.649, Z: -65.535 }
	],
	// Slovakia Ring
	2064: [
		{ X: -132.45, Y: 0.365, Z: -264.752 },
		{ X: -121.127, Y: 0.323, Z: -264.76 },
		{ X: -109.445, Y: 0.321, Z: -264.563 },
		{ X: -97.169, Y: 0.296, Z: -264.704 },
		{ X: -85.665, Y: 0.285, Z: -264.877 },
		{ X: -75.23, Y: 0.279, Z: -264.909 },
		{ X: -62.094, Y: 0.304, Z: -264.881 },
		{ X: -46.962, Y: 0.307, Z: -264.771 },
		{ X: -32.567, Y: 0.321, Z: -264.687 },
		{ X: -18.532, Y: 0.297, Z: -264.545 },
		{ X: -5.838, Y: 0.316, Z: -264.411 },
		{ X: 6.298, Y: 0.323, Z: -264.531 },
		{ X: 19.032, Y: 0.337, Z: -264.527 },
		{ X: 34.087, Y: 0.307, Z: -264.57 },
		{ X: 49.121, Y: 0.294, Z: -264.639 },
		{ X: 63.726, Y: 0.314, Z: -264.509 },
		{ X: 78.483, Y: 0.298, Z: -264.433 },
		{ X: 93.634, Y: 0.314, Z: -264.438 },
		{ X: 108.071, Y: 0.307, Z: -264.484 },
		{ X: 121.823, Y: 0.307, Z: -264.51 },
		{ X: 132.115, Y: 0.308, Z: -264.43 },
		{ X: 140.942, Y: 0.334, Z: -264.168 },
		{ X: -132.396, Y: 0.276, Z: -264.748 },
		{ X: -121.108, Y: 0.319, Z: -264.774 },
		{ X: -109.505, Y: 0.272, Z: -264.578 },
		{ X: -97.145, Y: 0.33, Z: -264.7 },
		{ X: -85.598, Y: 0.292, Z: -264.88 },
		{ X: -75.203, Y: 0.261, Z: -264.902 },
		{ X: -62.094, Y: 0.331, Z: -264.88 },
		{ X: -46.95, Y: 0.304, Z: -264.768 },
		{ X: -32.545, Y: 0.262, Z: -264.68 },
		{ X: -18.502, Y: 0.304, Z: -264.538 },
		{ X: -5.841, Y: 0.29, Z: -264.413 },
		{ X: 6.279, Y: 0.337, Z: -264.535 },
		{ X: 19.004, Y: 0.273, Z: -264.537 },
		{ X: 34.105, Y: 0.334, Z: -264.567 },
		{ X: 49.073, Y: 0.333, Z: -264.633 },
		{ X: 63.702, Y: 0.305, Z: -264.518 },
		{ X: 78.46, Y: 0.263, Z: -264.438 },
		{ X: 93.591, Y: 0.26, Z: -264.451 },
		{ X: 108.062, Y: 0.335, Z: -264.486 },
		{ X: 121.837, Y: 0.326, Z: -264.506 },
		{ X: 132.144, Y: 0.319, Z: -264.426 },
		{ X: 140.939, Y: 0.301, Z: -264.169 }
	],
	// Macau
	2123: [
		{ X: 103.53, Y: -0.088, Z: 84.276 },
		{ X: 111.61, Y: -0.198, Z: 86.574 },
		{ X: 120.072, Y: -0.133, Z: 89.042 },
		{ X: 128.265, Y: -0.183, Z: 91.799 },
		{ X: 136.16, Y: -0.148, Z: 94.117 },
		{ X: 144.484, Y: -0.207, Z: 96.57 },
		{ X: 152.623, Y: -0.207, Z: 99.191 },
		{ X: 160.799, Y: -0.174, Z: 101.733 },
		{ X: 169.037, Y: -0.165, Z: 104.165 },
		{ X: 177.065, Y: -0.134, Z: 106.623 },
		{ X: 185.449, Y: -0.17, Z: 109.058 },
		{ X: 193.284, Y: -0.136, Z: 111.503 },
		{ X: 201.365, Y: -0.137, Z: 114.002 },
		{ X: 209.634, Y: -0.158, Z: 116.585 },
		{ X: 217.615, Y: -0.142, Z: 119.059 },
		{ X: 225.976, Y: -0.135, Z: 121.643 },
		{ X: 234.081, Y: -0.16, Z: 123.975 },
		{ X: 242.147, Y: -0.139, Z: 126.388 },
		{ X: 250.347, Y: -0.21, Z: 128.881 },
		{ X: 258.543, Y: -0.17, Z: 131.397 },
		{ X: 267.116, Y: -0.124, Z: 134.003 },
		{ X: 103.499, Y: -0.19, Z: 84.282 },
		{ X: 111.582, Y: -0.148, Z: 86.574 },
		{ X: 120.117, Y: -0.196, Z: 89.048 },
		{ X: 128.274, Y: -0.16, Z: 91.801 },
		{ X: 136.171, Y: -0.138, Z: 94.124 },
		{ X: 144.472, Y: -0.187, Z: 96.58 },
		{ X: 152.646, Y: -0.193, Z: 99.184 },
		{ X: 160.802, Y: -0.136, Z: 101.717 },
		{ X: 168.993, Y: -0.177, Z: 104.141 },
		{ X: 177.058, Y: -0.158, Z: 106.617 },
		{ X: 185.483, Y: -0.188, Z: 109.076 },
		{ X: 193.3, Y: -0.156, Z: 111.509 },
		{ X: 201.39, Y: -0.151, Z: 114.019 },
		{ X: 209.624, Y: -0.199, Z: 116.582 },
		{ X: 217.713, Y: -0.157, Z: 119.093 },
		{ X: 226.027, Y: -0.145, Z: 121.674 },
		{ X: 234.088, Y: -0.184, Z: 123.98 },
		{ X: 242.193, Y: -0.2, Z: 126.395 },
		{ X: 250.338, Y: -0.136, Z: 128.876 },
		{ X: 258.531, Y: -0.182, Z: 131.39 }
	],
	// Motorsport Arena Oschersleben
	2384: [
		{ X: 28.234, Y: 2.324, Z: 102.905 },
		{ X: 40.339, Y: 2.43, Z: 102.787 },
		{ X: 52.765, Y: 2.506, Z: 102.928 },
		{ X: 81.69, Y: 2.846, Z: 102.912 },
		{ X: 93.884, Y: 2.974, Z: 102.884 },
		{ X: 106.038, Y: 3.121, Z: 102.926 },
		{ X: 118.13, Y: 3.248, Z: 102.955 },
		{ X: 147.352, Y: 3.559, Z: 102.97 },
		{ X: 159.433, Y: 3.608, Z: 102.974 },
		{ X: 171.787, Y: 3.765, Z: 102.97 },
		{ X: 183.827, Y: 3.824, Z: 102.986 },
		{ X: 68.393, Y: 2.657, Z: 102.89 },
		{ X: 132.908, Y: 3.384, Z: 103.012 },
		{ X: 14.439, Y: 2.087, Z: 102.876 },
		{ X: 28.189, Y: 2.284, Z: 102.889 },
		{ X: 40.359, Y: 2.402, Z: 102.768 },
		{ X: 52.744, Y: 2.549, Z: 102.939 },
		{ X: 81.672, Y: 2.792, Z: 102.887 },
		{ X: 93.876, Y: 2.937, Z: 102.901 },
		{ X: 106.033, Y: 3.07, Z: 102.926 },
		{ X: 118.151, Y: 3.254, Z: 102.961 },
		{ X: 147.344, Y: 3.578, Z: 102.969 },
		{ X: 159.429, Y: 3.603, Z: 102.973 },
		{ X: 171.737, Y: 3.715, Z: 102.97 },
		{ X: 183.849, Y: 3.878, Z: 102.977 },
		{ X: 68.402, Y: 2.688, Z: 102.91 },
		{ X: 132.839, Y: 3.399, Z: 103.008 },
		{ X: 14.405, Y: 2.087, Z: 102.861 },
		{ X: 28.235, Y: 2.256, Z: 102.885 },
		{ X: 40.358, Y: 2.38, Z: 102.767 },
		{ X: 52.791, Y: 2.553, Z: 102.956 },
		{ X: 81.693, Y: 2.802, Z: 102.913 },
		{ X: 93.925, Y: 2.957, Z: 102.918 },
		{ X: 106.091, Y: 3.059, Z: 102.924 },
		{ X: 118.17, Y: 3.243, Z: 102.95 },
		{ X: 147.313, Y: 3.534, Z: 102.94 }
	],
	// Motorsport Arena Oschersleben
	7753: [
		{ X: 28.234, Y: 2.324, Z: 102.905 },
		{ X: 40.339, Y: 2.43, Z: 102.787 },
		{ X: 52.765, Y: 2.506, Z: 102.928 },
		{ X: 81.69, Y: 2.846, Z: 102.912 },
		{ X: 93.884, Y: 2.974, Z: 102.884 },
		{ X: 106.038, Y: 3.121, Z: 102.926 },
		{ X: 118.13, Y: 3.248, Z: 102.955 },
		{ X: 147.352, Y: 3.559, Z: 102.97 },
		{ X: 159.433, Y: 3.608, Z: 102.974 },
		{ X: 171.787, Y: 3.765, Z: 102.97 },
		{ X: 183.827, Y: 3.824, Z: 102.986 },
		{ X: 68.393, Y: 2.657, Z: 102.89 },
		{ X: 132.908, Y: 3.384, Z: 103.012 },
		{ X: 14.439, Y: 2.087, Z: 102.876 },
		{ X: 28.189, Y: 2.284, Z: 102.889 },
		{ X: 40.359, Y: 2.402, Z: 102.768 },
		{ X: 52.744, Y: 2.549, Z: 102.939 },
		{ X: 81.672, Y: 2.792, Z: 102.887 },
		{ X: 93.876, Y: 2.937, Z: 102.901 },
		{ X: 106.033, Y: 3.07, Z: 102.926 },
		{ X: 118.151, Y: 3.254, Z: 102.961 },
		{ X: 147.344, Y: 3.578, Z: 102.969 },
		{ X: 159.429, Y: 3.603, Z: 102.973 },
		{ X: 171.737, Y: 3.715, Z: 102.97 },
		{ X: 183.849, Y: 3.878, Z: 102.977 },
		{ X: 68.402, Y: 2.688, Z: 102.91 },
		{ X: 132.839, Y: 3.399, Z: 103.008 },
		{ X: 14.405, Y: 2.087, Z: 102.861 },
		{ X: 28.235, Y: 2.256, Z: 102.885 },
		{ X: 40.358, Y: 2.38, Z: 102.767 },
		{ X: 52.791, Y: 2.553, Z: 102.956 },
		{ X: 81.693, Y: 2.802, Z: 102.913 },
		{ X: 93.925, Y: 2.957, Z: 102.918 },
		{ X: 106.091, Y: 3.059, Z: 102.924 },
		{ X: 118.17, Y: 3.243, Z: 102.95 },
		{ X: 147.313, Y: 3.534, Z: 102.94 }
	],
	// Motorsport Arena Oschersleben
	7754: [
		{ X: 28.234, Y: 2.324, Z: 102.905 },
		{ X: 40.339, Y: 2.43, Z: 102.787 },
		{ X: 52.765, Y: 2.506, Z: 102.928 },
		{ X: 81.69, Y: 2.846, Z: 102.912 },
		{ X: 93.884, Y: 2.974, Z: 102.884 },
		{ X: 106.038, Y: 3.121, Z: 102.926 },
		{ X: 118.13, Y: 3.248, Z: 102.955 },
		{ X: 147.352, Y: 3.559, Z: 102.97 },
		{ X: 159.433, Y: 3.608, Z: 102.974 },
		{ X: 171.787, Y: 3.765, Z: 102.97 },
		{ X: 183.827, Y: 3.824, Z: 102.986 },
		{ X: 68.393, Y: 2.657, Z: 102.89 },
		{ X: 132.908, Y: 3.384, Z: 103.012 },
		{ X: 14.439, Y: 2.087, Z: 102.876 },
		{ X: 28.189, Y: 2.284, Z: 102.889 },
		{ X: 40.359, Y: 2.402, Z: 102.768 },
		{ X: 52.744, Y: 2.549, Z: 102.939 },
		{ X: 81.672, Y: 2.792, Z: 102.887 },
		{ X: 93.876, Y: 2.937, Z: 102.901 },
		{ X: 106.033, Y: 3.07, Z: 102.926 },
		{ X: 118.151, Y: 3.254, Z: 102.961 },
		{ X: 147.344, Y: 3.578, Z: 102.969 },
		{ X: 159.429, Y: 3.603, Z: 102.973 },
		{ X: 171.737, Y: 3.715, Z: 102.97 },
		{ X: 183.849, Y: 3.878, Z: 102.977 },
		{ X: 68.402, Y: 2.688, Z: 102.91 },
		{ X: 132.839, Y: 3.399, Z: 103.008 },
		{ X: 14.405, Y: 2.087, Z: 102.861 },
		{ X: 28.235, Y: 2.256, Z: 102.885 },
		{ X: 40.358, Y: 2.38, Z: 102.767 },
		{ X: 52.791, Y: 2.553, Z: 102.956 },
		{ X: 81.693, Y: 2.802, Z: 102.913 },
		{ X: 93.925, Y: 2.957, Z: 102.918 },
		{ X: 106.091, Y: 3.059, Z: 102.924 },
		{ X: 118.17, Y: 3.243, Z: 102.95 },
		{ X: 147.313, Y: 3.534, Z: 102.94 }
	],
	// Motorsport Arena Oschersleben
	7755: [
		{ X: 28.234, Y: 2.324, Z: 102.905 },
		{ X: 40.339, Y: 2.43, Z: 102.787 },
		{ X: 52.765, Y: 2.506, Z: 102.928 },
		{ X: 81.69, Y: 2.846, Z: 102.912 },
		{ X: 93.884, Y: 2.974, Z: 102.884 },
		{ X: 106.038, Y: 3.121, Z: 102.926 },
		{ X: 118.13, Y: 3.248, Z: 102.955 },
		{ X: 147.352, Y: 3.559, Z: 102.97 },
		{ X: 159.433, Y: 3.608, Z: 102.974 },
		{ X: 171.787, Y: 3.765, Z: 102.97 },
		{ X: 183.827, Y: 3.824, Z: 102.986 },
		{ X: 68.393, Y: 2.657, Z: 102.89 },
		{ X: 132.908, Y: 3.384, Z: 103.012 },
		{ X: 14.439, Y: 2.087, Z: 102.876 },
		{ X: 28.189, Y: 2.284, Z: 102.889 },
		{ X: 40.359, Y: 2.402, Z: 102.768 },
		{ X: 52.744, Y: 2.549, Z: 102.939 },
		{ X: 81.672, Y: 2.792, Z: 102.887 },
		{ X: 93.876, Y: 2.937, Z: 102.901 },
		{ X: 106.033, Y: 3.07, Z: 102.926 },
		{ X: 118.151, Y: 3.254, Z: 102.961 },
		{ X: 147.344, Y: 3.578, Z: 102.969 },
		{ X: 159.429, Y: 3.603, Z: 102.973 },
		{ X: 171.737, Y: 3.715, Z: 102.97 },
		{ X: 183.849, Y: 3.878, Z: 102.977 },
		{ X: 68.402, Y: 2.688, Z: 102.91 },
		{ X: 132.839, Y: 3.399, Z: 103.008 },
		{ X: 14.405, Y: 2.087, Z: 102.861 },
		{ X: 28.235, Y: 2.256, Z: 102.885 },
		{ X: 40.358, Y: 2.38, Z: 102.767 },
		{ X: 52.791, Y: 2.553, Z: 102.956 },
		{ X: 81.693, Y: 2.802, Z: 102.913 },
		{ X: 93.925, Y: 2.957, Z: 102.918 },
		{ X: 106.091, Y: 3.059, Z: 102.924 },
		{ X: 118.17, Y: 3.243, Z: 102.95 },
		{ X: 147.313, Y: 3.534, Z: 102.94 }
	],
	// DEKRA Lausitzring
	2468: [
		{ X: -302.939, Y: 0.242, Z: 259.443 },
		{ X: -283.498, Y: 0.158, Z: 265.245 },
		{ X: -263.668, Y: 0.167, Z: 271.091 },
		{ X: -178.719, Y: 0.143, Z: 296.586 },
		{ X: -163.244, Y: 0.152, Z: 301.225 },
		{ X: -148.018, Y: 0.161, Z: 305.716 },
		{ X: -133.163, Y: 0.184, Z: 310.203 },
		{ X: -246.809, Y: 0.166, Z: 276.175 },
		{ X: -229.75, Y: 0.187, Z: 281.222 },
		{ X: -213.011, Y: 0.172, Z: 286.134 },
		{ X: -194.24, Y: 0.207, Z: 291.781 },
		{ X: -321.81, Y: 0.154, Z: 253.907 },
		{ X: -338.142, Y: 0.129, Z: 249.165 },
		{ X: -118.799, Y: 0.146, Z: 314.448 },
		{ X: -94.618, Y: 0.202, Z: 321.688 },
		{ X: -82.128, Y: 0.141, Z: 325.257 },
		{ X: -72.219, Y: 0.145, Z: 328.403 },
		{ X: -57.347, Y: 0.138, Z: 332.892 },
		{ X: -302.887, Y: 0.153, Z: 259.454 },
		{ X: -283.477, Y: 0.158, Z: 265.257 },
		{ X: -263.647, Y: 0.18, Z: 271.101 },
		{ X: -178.719, Y: 0.191, Z: 296.585 },
		{ X: -163.222, Y: 0.143, Z: 301.219 },
		{ X: -148.047, Y: 0.155, Z: 305.721 },
		{ X: -133.169, Y: 0.183, Z: 310.198 },
		{ X: -246.844, Y: 0.152, Z: 276.134 },
		{ X: -229.717, Y: 0.156, Z: 281.245 },
		{ X: -212.97, Y: 0.153, Z: 286.162 },
		{ X: -194.198, Y: 0.155, Z: 291.785 },
		{ X: -321.835, Y: 0.181, Z: 253.893 },
		{ X: -338.175, Y: 0.169, Z: 249.142 },
		{ X: -118.794, Y: 0.189, Z: 314.451 },
		{ X: -94.606, Y: 0.174, Z: 321.695 },
		{ X: -82.116, Y: 0.162, Z: 325.282 },
		{ X: -72.224, Y: 0.16, Z: 328.379 },
		{ X: -57.406, Y: 0.163, Z: 332.903 }
	],
	// DEKRA Lausitzring
	3291: [
		{ X: -302.939, Y: 0.242, Z: 259.443 },
		{ X: -283.498, Y: 0.158, Z: 265.245 },
		{ X: -263.668, Y: 0.167, Z: 271.091 },
		{ X: -178.719, Y: 0.143, Z: 296.586 },
		{ X: -163.244, Y: 0.152, Z: 301.225 },
		{ X: -148.018, Y: 0.161, Z: 305.716 },
		{ X: -133.163, Y: 0.184, Z: 310.203 },
		{ X: -246.809, Y: 0.166, Z: 276.175 },
		{ X: -229.75, Y: 0.187, Z: 281.222 },
		{ X: -213.011, Y: 0.172, Z: 286.134 },
		{ X: -194.24, Y: 0.207, Z: 291.781 },
		{ X: -321.81, Y: 0.154, Z: 253.907 },
		{ X: -338.142, Y: 0.129, Z: 249.165 },
		{ X: -118.799, Y: 0.146, Z: 314.448 },
		{ X: -94.618, Y: 0.202, Z: 321.688 },
		{ X: -82.128, Y: 0.141, Z: 325.257 },
		{ X: -72.219, Y: 0.145, Z: 328.403 },
		{ X: -57.347, Y: 0.138, Z: 332.892 },
		{ X: -302.887, Y: 0.153, Z: 259.454 },
		{ X: -283.477, Y: 0.158, Z: 265.257 },
		{ X: -263.647, Y: 0.18, Z: 271.101 },
		{ X: -178.719, Y: 0.191, Z: 296.585 },
		{ X: -163.222, Y: 0.143, Z: 301.219 },
		{ X: -148.047, Y: 0.155, Z: 305.721 },
		{ X: -133.169, Y: 0.183, Z: 310.198 },
		{ X: -246.844, Y: 0.152, Z: 276.134 },
		{ X: -229.717, Y: 0.156, Z: 281.245 },
		{ X: -212.97, Y: 0.153, Z: 286.162 },
		{ X: -194.198, Y: 0.155, Z: 291.785 },
		{ X: -321.835, Y: 0.181, Z: 253.893 },
		{ X: -338.175, Y: 0.169, Z: 249.142 },
		{ X: -118.794, Y: 0.189, Z: 314.451 },
		{ X: -94.606, Y: 0.174, Z: 321.695 },
		{ X: -82.116, Y: 0.162, Z: 325.282 },
		{ X: -72.224, Y: 0.16, Z: 328.379 },
		{ X: -57.406, Y: 0.163, Z: 332.903 }
	],
	// DEKRA Lausitzring
	6166: [
		{ X: -302.939, Y: 0.242, Z: 259.443 },
		{ X: -283.498, Y: 0.158, Z: 265.245 },
		{ X: -263.668, Y: 0.167, Z: 271.091 },
		{ X: -178.719, Y: 0.143, Z: 296.586 },
		{ X: -163.244, Y: 0.152, Z: 301.225 },
		{ X: -148.018, Y: 0.161, Z: 305.716 },
		{ X: -133.163, Y: 0.184, Z: 310.203 },
		{ X: -246.809, Y: 0.166, Z: 276.175 },
		{ X: -229.75, Y: 0.187, Z: 281.222 },
		{ X: -213.011, Y: 0.172, Z: 286.134 },
		{ X: -194.24, Y: 0.207, Z: 291.781 },
		{ X: -321.81, Y: 0.154, Z: 253.907 },
		{ X: -338.142, Y: 0.129, Z: 249.165 },
		{ X: -118.799, Y: 0.146, Z: 314.448 },
		{ X: -94.618, Y: 0.202, Z: 321.688 },
		{ X: -82.128, Y: 0.141, Z: 325.257 },
		{ X: -72.219, Y: 0.145, Z: 328.403 },
		{ X: -57.347, Y: 0.138, Z: 332.892 },
		{ X: -302.887, Y: 0.153, Z: 259.454 },
		{ X: -283.477, Y: 0.158, Z: 265.257 },
		{ X: -263.647, Y: 0.18, Z: 271.101 },
		{ X: -178.719, Y: 0.191, Z: 296.585 },
		{ X: -163.222, Y: 0.143, Z: 301.219 },
		{ X: -148.047, Y: 0.155, Z: 305.721 },
		{ X: -133.169, Y: 0.183, Z: 310.198 },
		{ X: -246.844, Y: 0.152, Z: 276.134 },
		{ X: -229.717, Y: 0.156, Z: 281.245 },
		{ X: -212.97, Y: 0.153, Z: 286.162 },
		{ X: -194.198, Y: 0.155, Z: 291.785 },
		{ X: -321.835, Y: 0.181, Z: 253.893 },
		{ X: -338.175, Y: 0.169, Z: 249.142 },
		{ X: -118.794, Y: 0.189, Z: 314.451 },
		{ X: -94.606, Y: 0.174, Z: 321.695 },
		{ X: -82.116, Y: 0.162, Z: 325.282 },
		{ X: -72.224, Y: 0.16, Z: 328.379 },
		{ X: -57.406, Y: 0.163, Z: 332.903 }
	],
	// DEKRA Lausitzring
	9055: [
		{ X: -302.939, Y: 0.242, Z: 259.443 },
		{ X: -283.498, Y: 0.158, Z: 265.245 },
		{ X: -263.668, Y: 0.167, Z: 271.091 },
		{ X: -178.719, Y: 0.143, Z: 296.586 },
		{ X: -163.244, Y: 0.152, Z: 301.225 },
		{ X: -148.018, Y: 0.161, Z: 305.716 },
		{ X: -133.163, Y: 0.184, Z: 310.203 },
		{ X: -246.809, Y: 0.166, Z: 276.175 },
		{ X: -229.75, Y: 0.187, Z: 281.222 },
		{ X: -213.011, Y: 0.172, Z: 286.134 },
		{ X: -194.24, Y: 0.207, Z: 291.781 },
		{ X: -321.81, Y: 0.154, Z: 253.907 },
		{ X: -338.142, Y: 0.129, Z: 249.165 },
		{ X: -118.799, Y: 0.146, Z: 314.448 },
		{ X: -94.618, Y: 0.202, Z: 321.688 },
		{ X: -82.128, Y: 0.141, Z: 325.257 },
		{ X: -72.219, Y: 0.145, Z: 328.403 },
		{ X: -57.347, Y: 0.138, Z: 332.892 },
		{ X: -302.887, Y: 0.153, Z: 259.454 },
		{ X: -283.477, Y: 0.158, Z: 265.257 },
		{ X: -263.647, Y: 0.18, Z: 271.101 },
		{ X: -178.719, Y: 0.191, Z: 296.585 },
		{ X: -163.222, Y: 0.143, Z: 301.219 },
		{ X: -148.047, Y: 0.155, Z: 305.721 },
		{ X: -133.169, Y: 0.183, Z: 310.198 },
		{ X: -246.844, Y: 0.152, Z: 276.134 },
		{ X: -229.717, Y: 0.156, Z: 281.245 },
		{ X: -212.97, Y: 0.153, Z: 286.162 },
		{ X: -194.198, Y: 0.155, Z: 291.785 },
		{ X: -321.835, Y: 0.181, Z: 253.893 },
		{ X: -338.175, Y: 0.169, Z: 249.142 },
		{ X: -118.794, Y: 0.189, Z: 314.451 },
		{ X: -94.606, Y: 0.174, Z: 321.695 },
		{ X: -82.116, Y: 0.162, Z: 325.282 },
		{ X: -72.224, Y: 0.16, Z: 328.379 },
		{ X: -57.406, Y: 0.163, Z: 332.903 }
	],
	// Moscow Raceway
	2473: [
		{ X: 571.491, Y: 0.579, Z: -5.89 },
		{ X: 570.675, Y: 0.508, Z: -20.143 },
		{ X: 569.898, Y: 0.494, Z: -33.226 },
		{ X: 561.087, Y: 0.293, Z: -168.972 },
		{ X: 559.087, Y: 0.314, Z: -197.238 },
		{ X: 558.403, Y: 0.328, Z: -210.434 },
		{ X: 557.448, Y: 0.269, Z: -223.839 },
		{ X: 566.787, Y: 0.457, Z: -80.942 },
		{ X: 565.2, Y: 0.41, Z: -102.032 },
		{ X: 564.462, Y: 0.339, Z: -115.898 },
		{ X: 563.62, Y: 0.359, Z: -128.988 },
		{ X: 556.101, Y: 0.281, Z: -239.359 },
		{ X: 568.506, Y: 0.463, Z: -53.838 },
		{ X: 567.705, Y: 0.467, Z: -67.183 },
		{ X: 566.17, Y: 0.404, Z: -90.687 },
		{ X: 562.816, Y: 0.317, Z: -142.07 },
		{ X: 562.045, Y: 0.369, Z: -155.393 },
		{ X: 560.337, Y: 0.271, Z: -182.15 },
		{ X: 571.49, Y: 0.469, Z: -5.904 },
		{ X: 570.674, Y: 0.476, Z: -20.138 },
		{ X: 569.88, Y: 0.501, Z: -33.222 },
		{ X: 561.087, Y: 0.352, Z: -168.982 },
		{ X: 559.093, Y: 0.329, Z: -197.272 },
		{ X: 558.406, Y: 0.335, Z: -210.436 },
		{ X: 557.447, Y: 0.28, Z: -223.845 },
		{ X: 566.786, Y: 0.379, Z: -80.937 },
		{ X: 565.208, Y: 0.406, Z: -101.99 },
		{ X: 564.444, Y: 0.411, Z: -115.895 },
		{ X: 563.605, Y: 0.339, Z: -129.02 },
		{ X: 556.134, Y: 0.288, Z: -239.377 },
		{ X: 568.496, Y: 0.425, Z: -53.878 },
		{ X: 567.715, Y: 0.421, Z: -67.235 },
		{ X: 566.156, Y: 0.429, Z: -90.729 },
		{ X: 562.816, Y: 0.378, Z: -142.079 },
		{ X: 562.025, Y: 0.326, Z: -155.413 },
		{ X: 560.334, Y: 0.314, Z: -182.143 }
	],
	// Moscow Raceway
	3383: [
		{ X: 571.491, Y: 0.579, Z: -5.89 },
		{ X: 570.675, Y: 0.508, Z: -20.143 },
		{ X: 569.898, Y: 0.494, Z: -33.226 },
		{ X: 561.087, Y: 0.293, Z: -168.972 },
		{ X: 559.087, Y: 0.314, Z: -197.238 },
		{ X: 558.403, Y: 0.328, Z: -210.434 },
		{ X: 557.448, Y: 0.269, Z: -223.839 },
		{ X: 566.787, Y: 0.457, Z: -80.942 },
		{ X: 565.2, Y: 0.41, Z: -102.032 },
		{ X: 564.462, Y: 0.339, Z: -115.898 },
		{ X: 563.62, Y: 0.359, Z: -128.988 },
		{ X: 556.101, Y: 0.281, Z: -239.359 },
		{ X: 568.506, Y: 0.463, Z: -53.838 },
		{ X: 567.705, Y: 0.467, Z: -67.183 },
		{ X: 566.17, Y: 0.404, Z: -90.687 },
		{ X: 562.816, Y: 0.317, Z: -142.07 },
		{ X: 562.045, Y: 0.369, Z: -155.393 },
		{ X: 560.337, Y: 0.271, Z: -182.15 },
		{ X: 571.49, Y: 0.469, Z: -5.904 },
		{ X: 570.674, Y: 0.476, Z: -20.138 },
		{ X: 569.88, Y: 0.501, Z: -33.222 },
		{ X: 561.087, Y: 0.352, Z: -168.982 },
		{ X: 559.093, Y: 0.329, Z: -197.272 },
		{ X: 558.406, Y: 0.335, Z: -210.436 },
		{ X: 557.447, Y: 0.28, Z: -223.845 },
		{ X: 566.786, Y: 0.379, Z: -80.937 },
		{ X: 565.208, Y: 0.406, Z: -101.99 },
		{ X: 564.444, Y: 0.411, Z: -115.895 },
		{ X: 563.605, Y: 0.339, Z: -129.02 },
		{ X: 556.134, Y: 0.288, Z: -239.377 },
		{ X: 568.496, Y: 0.425, Z: -53.878 },
		{ X: 567.715, Y: 0.421, Z: -67.235 },
		{ X: 566.156, Y: 0.429, Z: -90.729 },
		{ X: 562.816, Y: 0.378, Z: -142.079 },
		{ X: 562.025, Y: 0.326, Z: -155.413 },
		{ X: 560.334, Y: 0.314, Z: -182.143 }
	],
	// Moscow Raceway
	3683: [
		{ X: 571.491, Y: 0.579, Z: -5.89 },
		{ X: 570.675, Y: 0.508, Z: -20.143 },
		{ X: 569.898, Y: 0.494, Z: -33.226 },
		{ X: 561.087, Y: 0.293, Z: -168.972 },
		{ X: 559.087, Y: 0.314, Z: -197.238 },
		{ X: 558.403, Y: 0.328, Z: -210.434 },
		{ X: 557.448, Y: 0.269, Z: -223.839 },
		{ X: 566.787, Y: 0.457, Z: -80.942 },
		{ X: 565.2, Y: 0.41, Z: -102.032 },
		{ X: 564.462, Y: 0.339, Z: -115.898 },
		{ X: 563.62, Y: 0.359, Z: -128.988 },
		{ X: 556.101, Y: 0.281, Z: -239.359 },
		{ X: 568.506, Y: 0.463, Z: -53.838 },
		{ X: 567.705, Y: 0.467, Z: -67.183 },
		{ X: 566.17, Y: 0.404, Z: -90.687 },
		{ X: 562.816, Y: 0.317, Z: -142.07 },
		{ X: 562.045, Y: 0.369, Z: -155.393 },
		{ X: 560.337, Y: 0.271, Z: -182.15 },
		{ X: 571.49, Y: 0.469, Z: -5.904 },
		{ X: 570.674, Y: 0.476, Z: -20.138 },
		{ X: 569.88, Y: 0.501, Z: -33.222 },
		{ X: 561.087, Y: 0.352, Z: -168.982 },
		{ X: 559.093, Y: 0.329, Z: -197.272 },
		{ X: 558.406, Y: 0.335, Z: -210.436 },
		{ X: 557.447, Y: 0.28, Z: -223.845 },
		{ X: 566.786, Y: 0.379, Z: -80.937 },
		{ X: 565.208, Y: 0.406, Z: -101.99 },
		{ X: 564.444, Y: 0.411, Z: -115.895 },
		{ X: 563.605, Y: 0.339, Z: -129.02 },
		{ X: 556.134, Y: 0.288, Z: -239.377 },
		{ X: 568.496, Y: 0.425, Z: -53.878 },
		{ X: 567.715, Y: 0.421, Z: -67.235 },
		{ X: 566.156, Y: 0.429, Z: -90.729 },
		{ X: 562.816, Y: 0.378, Z: -142.079 },
		{ X: 562.025, Y: 0.326, Z: -155.413 },
		{ X: 560.334, Y: 0.314, Z: -182.143 }
	],
	// Norisring
	2518: [
		{ X: 13.253, Y: 0.356, Z: -86.682 },
		{ X: 3.458, Y: 0.279, Z: -80.532 },
		{ X: -6.278, Y: 0.271, Z: -74.525 },
		{ X: -16.125, Y: 0.295, Z: -68.408 },
		{ X: -26.079, Y: 0.328, Z: -62.319 },
		{ X: -35.726, Y: 0.295, Z: -56.348 },
		{ X: -45.949, Y: 0.295, Z: -50.106 },
		{ X: -55.436, Y: 0.293, Z: -44.17 },
		{ X: -65.456, Y: 0.323, Z: -38.01 },
		{ X: -75.083, Y: 0.282, Z: -31.924 },
		{ X: -84.929, Y: 0.321, Z: -25.762 },
		{ X: -94.196, Y: 0.322, Z: -19.448 },
		{ X: 13.158, Y: 0.328, Z: -86.592 },
		{ X: 3.461, Y: 0.285, Z: -80.534 },
		{ X: -6.255, Y: 0.317, Z: -74.523 },
		{ X: -16.131, Y: 0.322, Z: -68.405 },
		{ X: -26.13, Y: 0.299, Z: -62.293 },
		{ X: -35.704, Y: 0.295, Z: -56.343 },
		{ X: -45.95, Y: 0.259, Z: -50.105 },
		{ X: -55.449, Y: 0.267, Z: -44.156 },
		{ X: -65.501, Y: 0.304, Z: -37.99 },
		{ X: -75.077, Y: 0.3, Z: -31.929 },
		{ X: -84.949, Y: 0.249, Z: -25.741 },
		{ X: -94.206, Y: 0.317, Z: -19.439 },
		{ X: 13.223, Y: 0.286, Z: -86.629 },
		{ X: 3.457, Y: 0.285, Z: -80.531 },
		{ X: -6.218, Y: 0.328, Z: -74.562 },
		{ X: -16.103, Y: 0.315, Z: -68.404 },
		{ X: -26.087, Y: 0.323, Z: -62.312 },
		{ X: -35.689, Y: 0.327, Z: -56.361 },
		{ X: -45.899, Y: 0.262, Z: -50.128 },
		{ X: -55.445, Y: 0.263, Z: -44.161 },
		{ X: -65.497, Y: 0.247, Z: -37.996 },
		{ X: -75.072, Y: 0.321, Z: -31.935 },
		{ X: -84.93, Y: 0.29, Z: -25.763 },
		{ X: -94.209, Y: 0.318, Z: -19.435 }
	],
	// Brands Hatch Indy
	2520: [
		{ X: 224.844, Y: 10.341, Z: 191.29 },
		{ X: 213.736, Y: 10.608, Z: 185.38 },
		{ X: 202.871, Y: 10.923, Z: 179.448 },
		{ X: 192.079, Y: 11.216, Z: 173.625 },
		{ X: 181.391, Y: 11.531, Z: 167.574 },
		{ X: 170.533, Y: 11.824, Z: 161.322 },
		{ X: 159.583, Y: 12.115, Z: 154.866 },
		{ X: 149.028, Y: 12.419, Z: 148.729 },
		{ X: 138.172, Y: 12.713, Z: 142.101 },
		{ X: 127.868, Y: 12.951, Z: 135.649 },
		{ X: 117.569, Y: 13.218, Z: 128.969 },
		{ X: 236.471, Y: 9.966, Z: 197.417 },
		{ X: 247.343, Y: 9.583, Z: 202.971 },
		{ X: 258.252, Y: 9.386, Z: 208.545 },
		{ X: 269.385, Y: 9.166, Z: 214.127 },
		{ X: 280.863, Y: 9.03, Z: 219.845 },
		{ X: 291.726, Y: 8.993, Z: 225.379 },
		{ X: 224.781, Y: 10.311, Z: 191.277 },
		{ X: 213.77, Y: 10.58, Z: 185.405 },
		{ X: 202.876, Y: 10.895, Z: 179.461 },
		{ X: 192.076, Y: 11.176, Z: 173.62 },
		{ X: 181.376, Y: 11.535, Z: 167.585 },
		{ X: 170.577, Y: 11.848, Z: 161.359 },
		{ X: 159.642, Y: 12.157, Z: 154.899 },
		{ X: 148.98, Y: 12.403, Z: 148.703 },
		{ X: 138.167, Y: 12.682, Z: 142.09 },
		{ X: 127.814, Y: 12.978, Z: 135.598 },
		{ X: 117.579, Y: 13.236, Z: 128.97 },
		{ X: 236.459, Y: 9.975, Z: 197.414 },
		{ X: 247.366, Y: 9.61, Z: 202.974 },
		{ X: 258.239, Y: 9.362, Z: 208.542 },
		{ X: 269.387, Y: 9.156, Z: 214.127 },
		{ X: 280.885, Y: 9.09, Z: 219.865 },
		{ X: 291.777, Y: 9.039, Z: 225.387 }
	],
	// Red Bull Ring Spielberg
	2556: [
		{ X: 132.849, Y: -32.87, Z: -316.811 },
		{ X: 151.377, Y: -32.965, Z: -311.583 },
		{ X: 161.116, Y: -32.998, Z: -309.11 },
		{ X: 179.657, Y: -33.014, Z: -304.079 },
		{ X: 198.745, Y: -32.979, Z: -298.908 },
		{ X: 207.994, Y: -32.946, Z: -296.345 },
		{ X: 226.984, Y: -32.95, Z: -291.091 },
		{ X: 245.753, Y: -32.941, Z: -285.947 },
		{ X: 255.127, Y: -32.992, Z: -283.396 },
		{ X: 273.783, Y: -32.96, Z: -278.44 },
		{ X: 283.387, Y: -32.943, Z: -275.761 },
		{ X: 296.707, Y: -32.929, Z: -272.039 },
		{ X: 112.175, Y: -32.913, Z: -322.44 },
		{ X: 308.924, Y: -32.977, Z: -268.767 },
		{ X: 323.007, Y: -32.926, Z: -265.255 },
		{ X: 336.271, Y: -32.954, Z: -261.716 },
		{ X: 348.469, Y: -32.845, Z: -258.279 },
		{ X: 360.726, Y: -32.838, Z: -255.06 },
		{ X: 132.886, Y: -32.936, Z: -316.816 },
		{ X: 151.434, Y: -32.981, Z: -311.581 },
		{ X: 161.124, Y: -32.951, Z: -309.109 },
		{ X: 179.697, Y: -32.984, Z: -304.061 },
		{ X: 198.734, Y: -32.95, Z: -298.909 },
		{ X: 208.052, Y: -32.953, Z: -296.326 },
		{ X: 226.934, Y: -32.941, Z: -291.109 },
		{ X: 245.793, Y: -32.936, Z: -285.948 },
		{ X: 255.184, Y: -32.938, Z: -283.379 },
		{ X: 273.738, Y: -32.991, Z: -278.458 },
		{ X: 283.322, Y: -32.936, Z: -275.762 },
		{ X: 296.704, Y: -32.949, Z: -272.039 },
		{ X: 112.234, Y: -32.871, Z: -322.437 },
		{ X: 308.904, Y: -32.946, Z: -268.787 },
		{ X: 322.988, Y: -32.924, Z: -265.253 },
		{ X: 336.216, Y: -32.915, Z: -261.718 },
		{ X: 348.545, Y: -32.851, Z: -258.261 },
		{ X: 360.73, Y: -32.782, Z: -255.061 }
	],
	// Red Bull Ring Spielberg
	5794: [
		{ X: 132.94, Y: -32.874, Z: -316.585 },
		{ X: 142.468, Y: -32.967, Z: -314.081 },
		{ X: 151.591, Y: -32.987, Z: -311.618 },
		{ X: 160.894, Y: -32.949, Z: -309.068 },
		{ X: 170.471, Y: -33.023, Z: -306.507 },
		{ X: 179.783, Y: -32.988, Z: -303.94 },
		{ X: 189.191, Y: -32.95, Z: -301.329 },
		{ X: 198.569, Y: -32.965, Z: -298.735 },
		{ X: 207.901, Y: -32.954, Z: -296.137 },
		{ X: 217.335, Y: -32.942, Z: -293.711 },
		{ X: 226.808, Y: -32.958, Z: -291.106 },
		{ X: 236.222, Y: -32.991, Z: -288.56 },
		{ X: 245.622, Y: -32.94, Z: -286.017 },
		{ X: 255.084, Y: -32.982, Z: -283.316 },
		{ X: 264.433, Y: -32.953, Z: -280.742 },
		{ X: 273.753, Y: -32.954, Z: -278.285 },
		{ X: 283.36, Y: -32.979, Z: -275.637 },
		{ X: 292.66, Y: -32.948, Z: -273.201 },
		{ X: 304.82, Y: -32.948, Z: -269.819 },
		{ X: 315.527, Y: -32.995, Z: -267.029 },
		{ X: 325.455, Y: -32.934, Z: -264.438 },
		{ X: 335.541, Y: -32.925, Z: -261.829 },
		{ X: 345.392, Y: -32.931, Z: -259.286 },
		{ X: 354.834, Y: -32.862, Z: -256.759 },
		{ X: 364.594, Y: -32.808, Z: -254.25 },
		{ X: 132.995, Y: -32.95, Z: -316.595 },
		{ X: 142.458, Y: -32.989, Z: -314.095 },
		{ X: 151.574, Y: -32.985, Z: -311.621 },
		{ X: 160.893, Y: -32.971, Z: -309.066 },
		{ X: 170.437, Y: -32.959, Z: -306.53 },
		{ X: 179.782, Y: -32.955, Z: -303.934 },
		{ X: 189.154, Y: -32.979, Z: -301.348 },
		{ X: 198.605, Y: -32.983, Z: -298.735 },
		{ X: 207.931, Y: -32.972, Z: -296.134 },
		{ X: 217.347, Y: -32.949, Z: -293.707 },
		{ X: 226.862, Y: -33.002, Z: -291.085 },
		{ X: 236.259, Y: -32.94, Z: -288.546 },
		{ X: 245.609, Y: -32.962, Z: -286.018 },
		{ X: 255.058, Y: -32.946, Z: -283.321 },
		{ X: 264.415, Y: -32.931, Z: -280.742 },
		{ X: 273.828, Y: -32.95, Z: -278.266 },
		{ X: 283.274, Y: -32.93, Z: -275.661 },
		{ X: 292.668, Y: -32.939, Z: -273.199 },
		{ X: 304.836, Y: -32.925, Z: -269.815 },
		{ X: 315.515, Y: -32.934, Z: -267.03 },
		{ X: 325.435, Y: -32.967, Z: -264.436 },
		{ X: 335.497, Y: -32.929, Z: -261.832 },
		{ X: 345.437, Y: -32.871, Z: -259.283 },
		{ X: 354.761, Y: -32.838, Z: -256.761 },
		{ X: 364.611, Y: -32.828, Z: -254.25 }
	],
	// Nordschleife
	2813: [
		{ X: -753.974, Y: 120.943, Z: -1486.459 },
		{ X: -748.738, Y: 120.536, Z: -1491.803 },
		{ X: -743.433, Y: 120.247, Z: -1497.198 },
		{ X: -738.106, Y: 119.891, Z: -1502.183 },
		{ X: -732.557, Y: 119.486, Z: -1507.289 },
		{ X: -727.128, Y: 119.176, Z: -1512.067 },
		{ X: -721.827, Y: 118.802, Z: -1516.624 },
		{ X: -715.977, Y: 118.427, Z: -1521.559 },
		{ X: -710.433, Y: 118.032, Z: -1526.23 },
		{ X: -704.558, Y: 117.658, Z: -1530.903 },
		{ X: -699.075, Y: 117.293, Z: -1535.238 },
		{ X: -694.053, Y: 116.945, Z: -1539.082 },
		{ X: -689.166, Y: 116.631, Z: -1542.732 },
		{ X: -684.807, Y: 116.326, Z: -1546.291 },
		{ X: -680.47, Y: 116.072, Z: -1549.529 },
		{ X: -675.938, Y: 115.748, Z: -1552.65 },
		{ X: -754.02, Y: 120.876, Z: -1486.656 },
		{ X: -748.7, Y: 120.569, Z: -1491.837 },
		{ X: -743.435, Y: 120.199, Z: -1497.197 },
		{ X: -738.12, Y: 119.868, Z: -1502.162 },
		{ X: -732.56, Y: 119.483, Z: -1507.29 },
		{ X: -727.123, Y: 119.17, Z: -1512.075 },
		{ X: -721.832, Y: 118.785, Z: -1516.615 },
		{ X: -715.997, Y: 118.396, Z: -1521.558 },
		{ X: -710.426, Y: 118.024, Z: -1526.234 },
		{ X: -704.546, Y: 117.66, Z: -1530.916 },
		{ X: -699.03, Y: 117.292, Z: -1535.265 },
		{ X: -694.007, Y: 116.965, Z: -1539.111 },
		{ X: -689.222, Y: 116.622, Z: -1542.693 },
		{ X: -684.818, Y: 116.318, Z: -1546.276 },
		{ X: -680.446, Y: 116.037, Z: -1549.552 },
		{ X: -675.975, Y: 115.749, Z: -1552.118 },
		{ X: -753.996, Y: 120.859, Z: -1486.67 },
		{ X: -748.72, Y: 120.54, Z: -1491.805 },
		{ X: -743.44, Y: 120.209, Z: -1497.188 },
		{ X: -738.133, Y: 119.868, Z: -1502.142 },
		{ X: -732.531, Y: 119.495, Z: -1507.329 },
		{ X: -727.151, Y: 119.142, Z: -1512.061 },
		{ X: -721.821, Y: 118.824, Z: -1516.631 },
		{ X: -715.992, Y: 118.414, Z: -1521.567 },
		{ X: -710.41, Y: 118.036, Z: -1526.257 },
		{ X: -704.578, Y: 117.647, Z: -1530.873 },
		{ X: -699.082, Y: 117.268, Z: -1535.226 },
		{ X: -694.004, Y: 116.936, Z: -1539.114 },
		{ X: -689.179, Y: 116.604, Z: -1542.719 },
		{ X: -684.823, Y: 116.306, Z: -1546.27 },
		{ X: -680.437, Y: 116.021, Z: -1549.539 },
		{ X: -675.965, Y: 115.745, Z: -1552.633 }
	],
	// Nordschleife
	4975: [
		{ X: -1124.179, Y: 111.507, Z: -2031.632 },
		{ X: -1119.461, Y: 111.459, Z: -2026.559 },
		{ X: -1115.304, Y: 111.507, Z: -2022.124 },
		{ X: -1111.073, Y: 111.593, Z: -2017.682 },
		{ X: -1106.923, Y: 111.642, Z: -2013.156 },
		{ X: -1102.803, Y: 111.722, Z: -2008.951 },
		{ X: -1098.276, Y: 111.772, Z: -2004.187 },
		{ X: -1094.511, Y: 111.883, Z: -2000.011 },
		{ X: -1089.942, Y: 111.955, Z: -1995.476 },
		{ X: -1085.714, Y: 111.987, Z: -1991.005 },
		{ X: -1081.416, Y: 112.049, Z: -1986.511 },
		{ X: -1077.286, Y: 112.108, Z: -1982.169 },
		{ X: -1073.042, Y: 112.148, Z: -1977.816 },
		{ X: -1068.74, Y: 112.249, Z: -1973.35 },
		{ X: -1064.566, Y: 112.301, Z: -1969.02 },
		{ X: -1060.288, Y: 112.319, Z: -1964.554 },
		{ X: -1056.03, Y: 112.408, Z: -1960.141 },
		{ X: -1051.838, Y: 112.457, Z: -1955.664 },
		{ X: -1047.749, Y: 112.499, Z: -1951.276 },
		{ X: -1043.417, Y: 112.563, Z: -1946.748 },
		{ X: -1039.046, Y: 112.613, Z: -1942.194 },
		{ X: -1034.852, Y: 112.645, Z: -1937.966 },
		{ X: -1030.54, Y: 112.724, Z: -1933.417 },
		{ X: -1026.414, Y: 112.774, Z: -1929.073 },
		{ X: -1022.131, Y: 112.827, Z: -1924.641 },
		{ X: -1017.83, Y: 112.932, Z: -1920.22 },
		{ X: -1013.762, Y: 112.985, Z: -1915.756 },
		{ X: -1009.465, Y: 113.045, Z: -1911.333 },
		{ X: -1005.109, Y: 113.054, Z: -1907.019 },
		{ X: -1000.99, Y: 113.119, Z: -1902.471 },
		{ X: -996.679, Y: 113.186, Z: -1898.059 },
		{ X: -992.486, Y: 113.193, Z: -1893.548 },
		{ X: -988.369, Y: 113.246, Z: -1889.202 },
		{ X: -984.223, Y: 113.323, Z: -1884.785 },
		{ X: -979.957, Y: 113.353, Z: -1880.427 },
		{ X: -975.724, Y: 113.413, Z: -1875.941 },
		{ X: -971.47, Y: 113.502, Z: -1871.416 },
		{ X: -967.237, Y: 113.512, Z: -1867.016 },
		{ X: -963.065, Y: 113.563, Z: -1862.562 },
		{ X: -958.855, Y: 113.64, Z: -1858.227 },
		{ X: -954.487, Y: 113.695, Z: -1853.654 },
		{ X: -950.256, Y: 113.759, Z: -1849.343 },
		{ X: -946.094, Y: 113.835, Z: -1844.921 },
		{ X: -941.875, Y: 113.866, Z: -1840.43 },
		{ X: -937.732, Y: 113.913, Z: -1835.991 },
		{ X: -933.578, Y: 113.984, Z: -1831.684 },
		{ X: -929.443, Y: 114.045, Z: -1827.316 },
		{ X: -925.198, Y: 114.119, Z: -1823.004 },
		{ X: -920.687, Y: 114.169, Z: -1818.708 },
		{ X: -915.913, Y: 114.219, Z: -1814.588 },
		{ X: -1124.113, Y: 111.427, Z: -2031.632 },
		{ X: -1119.458, Y: 111.453, Z: -2026.572 },
		{ X: -1115.309, Y: 111.529, Z: -2022.123 },
		{ X: -1111.113, Y: 111.604, Z: -2017.708 },
		{ X: -1106.933, Y: 111.631, Z: -2013.162 },
		{ X: -1102.806, Y: 111.728, Z: -2008.953 },
		{ X: -1098.286, Y: 111.804, Z: -2004.213 },
		{ X: -1094.51, Y: 111.865, Z: -1999.993 },
		{ X: -1089.929, Y: 111.939, Z: -1995.431 },
		{ X: -1085.684, Y: 111.987, Z: -1990.967 },
		{ X: -1081.405, Y: 112.044, Z: -1986.498 },
		{ X: -1077.199, Y: 112.134, Z: -1982.112 },
		{ X: -1073.001, Y: 112.178, Z: -1977.784 },
		{ X: -1068.781, Y: 112.226, Z: -1973.379 },
		{ X: -1064.615, Y: 112.272, Z: -1969.071 },
		{ X: -1060.293, Y: 112.341, Z: -1964.558 },
		{ X: -1056.023, Y: 112.392, Z: -1960.139 },
		{ X: -1051.855, Y: 112.447, Z: -1955.675 },
		{ X: -1047.699, Y: 112.511, Z: -1951.223 },
		{ X: -1043.433, Y: 112.549, Z: -1946.759 },
		{ X: -1039.019, Y: 112.616, Z: -1942.195 },
		{ X: -1034.828, Y: 112.69, Z: -1937.946 },
		{ X: -1030.583, Y: 112.723, Z: -1933.444 },
		{ X: -1026.419, Y: 112.815, Z: -1929.074 },
		{ X: -1022.146, Y: 112.836, Z: -1924.65 },
		{ X: -1017.796, Y: 112.939, Z: -1920.198 },
		{ X: -1013.814, Y: 112.997, Z: -1915.811 },
		{ X: -1009.438, Y: 113.026, Z: -1911.311 },
		{ X: -1005.095, Y: 113.096, Z: -1907.011 },
		{ X: -1000.972, Y: 113.106, Z: -1902.46 },
		{ X: -996.705, Y: 113.162, Z: -1898.082 },
		{ X: -992.527, Y: 113.223, Z: -1893.589 },
		{ X: -988.347, Y: 113.267, Z: -1889.189 },
		{ X: -984.252, Y: 113.316, Z: -1884.808 },
		{ X: -979.975, Y: 113.399, Z: -1880.442 },
		{ X: -975.726, Y: 113.41, Z: -1875.92 },
		{ X: -971.5, Y: 113.498, Z: -1871.457 },
		{ X: -967.241, Y: 113.52, Z: -1867.018 },
		{ X: -963.107, Y: 113.549, Z: -1862.588 },
		{ X: -958.876, Y: 113.608, Z: -1858.241 },
		{ X: -954.484, Y: 113.713, Z: -1853.65 },
		{ X: -950.229, Y: 113.718, Z: -1849.323 },
		{ X: -946.082, Y: 113.829, Z: -1844.892 },
		{ X: -941.896, Y: 113.877, Z: -1840.443 },
		{ X: -937.742, Y: 113.927, Z: -1835.998 },
		{ X: -933.556, Y: 113.978, Z: -1831.67 },
		{ X: -929.488, Y: 114.041, Z: -1827.342 },
		{ X: -925.2, Y: 114.146, Z: -1823.005 },
		{ X: -920.691, Y: 114.163, Z: -1818.71 },
		{ X: -915.878, Y: 114.261, Z: -1814.547 }
	],
	// Nordschleife
	5095: [
		{ X: -1124.179, Y: 111.507, Z: -2031.632 },
		{ X: -1119.461, Y: 111.459, Z: -2026.559 },
		{ X: -1115.304, Y: 111.507, Z: -2022.124 },
		{ X: -1111.073, Y: 111.593, Z: -2017.682 },
		{ X: -1106.923, Y: 111.642, Z: -2013.156 },
		{ X: -1102.803, Y: 111.722, Z: -2008.951 },
		{ X: -1098.276, Y: 111.772, Z: -2004.187 },
		{ X: -1094.511, Y: 111.883, Z: -2000.011 },
		{ X: -1089.942, Y: 111.955, Z: -1995.476 },
		{ X: -1085.714, Y: 111.987, Z: -1991.005 },
		{ X: -1081.416, Y: 112.049, Z: -1986.511 },
		{ X: -1077.286, Y: 112.108, Z: -1982.169 },
		{ X: -1073.042, Y: 112.148, Z: -1977.816 },
		{ X: -1068.74, Y: 112.249, Z: -1973.35 },
		{ X: -1064.566, Y: 112.301, Z: -1969.02 },
		{ X: -1060.288, Y: 112.319, Z: -1964.554 },
		{ X: -1056.03, Y: 112.408, Z: -1960.141 },
		{ X: -1051.838, Y: 112.457, Z: -1955.664 },
		{ X: -1047.749, Y: 112.499, Z: -1951.276 },
		{ X: -1043.417, Y: 112.563, Z: -1946.748 },
		{ X: -1039.046, Y: 112.613, Z: -1942.194 },
		{ X: -1034.852, Y: 112.645, Z: -1937.966 },
		{ X: -1030.54, Y: 112.724, Z: -1933.417 },
		{ X: -1026.414, Y: 112.774, Z: -1929.073 },
		{ X: -1022.131, Y: 112.827, Z: -1924.641 },
		{ X: -1017.83, Y: 112.932, Z: -1920.22 },
		{ X: -1013.762, Y: 112.985, Z: -1915.756 },
		{ X: -1009.465, Y: 113.045, Z: -1911.333 },
		{ X: -1005.109, Y: 113.054, Z: -1907.019 },
		{ X: -1000.99, Y: 113.119, Z: -1902.471 },
		{ X: -996.679, Y: 113.186, Z: -1898.059 },
		{ X: -992.486, Y: 113.193, Z: -1893.548 },
		{ X: -988.369, Y: 113.246, Z: -1889.202 },
		{ X: -984.223, Y: 113.323, Z: -1884.785 },
		{ X: -979.957, Y: 113.353, Z: -1880.427 },
		{ X: -975.724, Y: 113.413, Z: -1875.941 },
		{ X: -971.47, Y: 113.502, Z: -1871.416 },
		{ X: -967.237, Y: 113.512, Z: -1867.016 },
		{ X: -963.065, Y: 113.563, Z: -1862.562 },
		{ X: -958.855, Y: 113.64, Z: -1858.227 },
		{ X: -954.487, Y: 113.695, Z: -1853.654 },
		{ X: -950.256, Y: 113.759, Z: -1849.343 },
		{ X: -946.094, Y: 113.835, Z: -1844.921 },
		{ X: -941.875, Y: 113.866, Z: -1840.43 },
		{ X: -937.732, Y: 113.913, Z: -1835.991 },
		{ X: -933.578, Y: 113.984, Z: -1831.684 },
		{ X: -929.443, Y: 114.045, Z: -1827.316 },
		{ X: -925.198, Y: 114.119, Z: -1823.004 },
		{ X: -920.687, Y: 114.169, Z: -1818.708 },
		{ X: -915.913, Y: 114.219, Z: -1814.588 },
		{ X: -1124.113, Y: 111.427, Z: -2031.632 },
		{ X: -1119.458, Y: 111.453, Z: -2026.572 },
		{ X: -1115.309, Y: 111.529, Z: -2022.123 },
		{ X: -1111.113, Y: 111.604, Z: -2017.708 },
		{ X: -1106.933, Y: 111.631, Z: -2013.162 },
		{ X: -1102.806, Y: 111.728, Z: -2008.953 },
		{ X: -1098.286, Y: 111.804, Z: -2004.213 },
		{ X: -1094.51, Y: 111.865, Z: -1999.993 },
		{ X: -1089.929, Y: 111.939, Z: -1995.431 },
		{ X: -1085.684, Y: 111.987, Z: -1990.967 },
		{ X: -1081.405, Y: 112.044, Z: -1986.498 },
		{ X: -1077.199, Y: 112.134, Z: -1982.112 },
		{ X: -1073.001, Y: 112.178, Z: -1977.784 },
		{ X: -1068.781, Y: 112.226, Z: -1973.379 },
		{ X: -1064.615, Y: 112.272, Z: -1969.071 },
		{ X: -1060.293, Y: 112.341, Z: -1964.558 },
		{ X: -1056.023, Y: 112.392, Z: -1960.139 },
		{ X: -1051.855, Y: 112.447, Z: -1955.675 },
		{ X: -1047.699, Y: 112.511, Z: -1951.223 },
		{ X: -1043.433, Y: 112.549, Z: -1946.759 },
		{ X: -1039.019, Y: 112.616, Z: -1942.195 },
		{ X: -1034.828, Y: 112.69, Z: -1937.946 },
		{ X: -1030.583, Y: 112.723, Z: -1933.444 },
		{ X: -1026.419, Y: 112.815, Z: -1929.074 },
		{ X: -1022.146, Y: 112.836, Z: -1924.65 },
		{ X: -1017.796, Y: 112.939, Z: -1920.198 },
		{ X: -1013.814, Y: 112.997, Z: -1915.811 },
		{ X: -1009.438, Y: 113.026, Z: -1911.311 },
		{ X: -1005.095, Y: 113.096, Z: -1907.011 },
		{ X: -1000.972, Y: 113.106, Z: -1902.46 },
		{ X: -996.705, Y: 113.162, Z: -1898.082 },
		{ X: -992.527, Y: 113.223, Z: -1893.589 },
		{ X: -988.347, Y: 113.267, Z: -1889.189 },
		{ X: -984.252, Y: 113.316, Z: -1884.808 },
		{ X: -979.975, Y: 113.399, Z: -1880.442 },
		{ X: -975.726, Y: 113.41, Z: -1875.92 },
		{ X: -971.5, Y: 113.498, Z: -1871.457 },
		{ X: -967.241, Y: 113.52, Z: -1867.018 },
		{ X: -963.107, Y: 113.549, Z: -1862.588 },
		{ X: -958.876, Y: 113.608, Z: -1858.241 },
		{ X: -954.484, Y: 113.713, Z: -1853.65 },
		{ X: -950.229, Y: 113.718, Z: -1849.323 },
		{ X: -946.082, Y: 113.829, Z: -1844.892 },
		{ X: -941.896, Y: 113.877, Z: -1840.443 },
		{ X: -937.742, Y: 113.927, Z: -1835.998 },
		{ X: -933.556, Y: 113.978, Z: -1831.67 },
		{ X: -929.488, Y: 114.041, Z: -1827.342 },
		{ X: -925.2, Y: 114.146, Z: -1823.005 },
		{ X: -920.691, Y: 114.163, Z: -1818.71 },
		{ X: -915.878, Y: 114.261, Z: -1814.547 }
	],
	// Paul Ricard
	2867: [
		{ X: -95.336, Y: -42.303, Z: 61.529 },
		{ X: -81.831, Y: -42.557, Z: 51.565 },
		{ X: -67.744, Y: -42.735, Z: 41.009 },
		{ X: -53.735, Y: -42.965, Z: 30.37 },
		{ X: -39.844, Y: -43.141, Z: 19.949 },
		{ X: -24.124, Y: -43.296, Z: 7.764 },
		{ X: -10.105, Y: -42.427, Z: -4.075 },
		{ X: 2.069, Y: -42.073, Z: -12.855 },
		{ X: 15.388, Y: -42.174, Z: -22.873 },
		{ X: 29.352, Y: -42.383, Z: -32.435 },
		{ X: 44.81, Y: -42.618, Z: -42.521 },
		{ X: 59.144, Y: -42.788, Z: -51.717 },
		{ X: 72.667, Y: -42.944, Z: -60.153 },
		{ X: 83.161, Y: -43.074, Z: -66.684 },
		{ X: 96.77, Y: -43.266, Z: -74.865 },
		{ X: 109.486, Y: -43.417, Z: -82.759 },
		{ X: 125.965, Y: -43.645, Z: -92.729 },
		{ X: 140.849, Y: -43.898, Z: -101.948 },
		{ X: 163.128, Y: -44.162, Z: -115.823 },
		{ X: 184.066, Y: -44.456, Z: -128.675 },
		{ X: 199.544, Y: -44.657, Z: -138.236 },
		{ X: 215.23, Y: -44.82, Z: -148.019 },
		{ X: -95.272, Y: -42.341, Z: 61.37 },
		{ X: -81.819, Y: -42.563, Z: 51.55 },
		{ X: -67.732, Y: -42.722, Z: 40.994 },
		{ X: -53.77, Y: -42.931, Z: 30.39 },
		{ X: -39.822, Y: -43.111, Z: 19.92 },
		{ X: -24.164, Y: -43.272, Z: 7.812 },
		{ X: -10.074, Y: -42.427, Z: -4.124 },
		{ X: 2.069, Y: -42.08, Z: -12.855 },
		{ X: 15.433, Y: -42.213, Z: -22.898 },
		{ X: 29.37, Y: -42.463, Z: -32.454 },
		{ X: 44.774, Y: -42.56, Z: -42.513 },
		{ X: 59.148, Y: -42.758, Z: -51.721 },
		{ X: 72.652, Y: -42.954, Z: -60.135 },
		{ X: 83.158, Y: -43.121, Z: -66.68 },
		{ X: 96.759, Y: -43.311, Z: -74.852 },
		{ X: 109.472, Y: -43.469, Z: -82.75 },
		{ X: 126.001, Y: -43.65, Z: -92.767 },
		{ X: 140.844, Y: -43.897, Z: -101.945 },
		{ X: 163.137, Y: -44.171, Z: -115.827 },
		{ X: 184.065, Y: -44.47, Z: -128.674 },
		{ X: 199.552, Y: -44.635, Z: -138.243 },
		{ X: 215.24, Y: -44.902, Z: -148.042 }
	],
	// Paul Ricard
	4246: [
		{ X: -95.336, Y: -42.303, Z: 61.529 },
		{ X: -81.831, Y: -42.557, Z: 51.565 },
		{ X: -67.744, Y: -42.735, Z: 41.009 },
		{ X: -53.735, Y: -42.965, Z: 30.37 },
		{ X: -39.844, Y: -43.141, Z: 19.949 },
		{ X: -24.124, Y: -43.296, Z: 7.764 },
		{ X: -10.105, Y: -42.427, Z: -4.075 },
		{ X: 2.069, Y: -42.073, Z: -12.855 },
		{ X: 15.388, Y: -42.174, Z: -22.873 },
		{ X: 29.352, Y: -42.383, Z: -32.435 },
		{ X: 44.81, Y: -42.618, Z: -42.521 },
		{ X: 59.144, Y: -42.788, Z: -51.717 },
		{ X: 72.667, Y: -42.944, Z: -60.153 },
		{ X: 83.161, Y: -43.074, Z: -66.684 },
		{ X: 96.77, Y: -43.266, Z: -74.865 },
		{ X: 109.486, Y: -43.417, Z: -82.759 },
		{ X: 125.965, Y: -43.645, Z: -92.729 },
		{ X: 140.849, Y: -43.898, Z: -101.948 },
		{ X: 163.128, Y: -44.162, Z: -115.823 },
		{ X: 184.066, Y: -44.456, Z: -128.675 },
		{ X: 199.544, Y: -44.657, Z: -138.236 },
		{ X: 215.23, Y: -44.82, Z: -148.019 },
		{ X: -95.272, Y: -42.341, Z: 61.37 },
		{ X: -81.819, Y: -42.563, Z: 51.55 },
		{ X: -67.732, Y: -42.722, Z: 40.994 },
		{ X: -53.77, Y: -42.931, Z: 30.39 },
		{ X: -39.822, Y: -43.111, Z: 19.92 },
		{ X: -24.164, Y: -43.272, Z: 7.812 },
		{ X: -10.074, Y: -42.427, Z: -4.124 },
		{ X: 2.069, Y: -42.08, Z: -12.855 },
		{ X: 15.433, Y: -42.213, Z: -22.898 },
		{ X: 29.37, Y: -42.463, Z: -32.454 },
		{ X: 44.774, Y: -42.56, Z: -42.513 },
		{ X: 59.148, Y: -42.758, Z: -51.721 },
		{ X: 72.652, Y: -42.954, Z: -60.135 },
		{ X: 83.158, Y: -43.121, Z: -66.68 },
		{ X: 96.759, Y: -43.311, Z: -74.852 },
		{ X: 109.472, Y: -43.469, Z: -82.75 },
		{ X: 126.001, Y: -43.65, Z: -92.767 },
		{ X: 140.844, Y: -43.897, Z: -101.945 },
		{ X: 163.137, Y: -44.171, Z: -115.827 },
		{ X: 184.065, Y: -44.47, Z: -128.674 },
		{ X: 199.552, Y: -44.635, Z: -138.243 },
		{ X: 215.24, Y: -44.902, Z: -148.042 }
	],
	// Paul Ricard
	4247: [
		{ X: -95.336, Y: -42.303, Z: 61.529 },
		{ X: -81.831, Y: -42.557, Z: 51.565 },
		{ X: -67.744, Y: -42.735, Z: 41.009 },
		{ X: -53.735, Y: -42.965, Z: 30.37 },
		{ X: -39.844, Y: -43.141, Z: 19.949 },
		{ X: -24.124, Y: -43.296, Z: 7.764 },
		{ X: -10.105, Y: -42.427, Z: -4.075 },
		{ X: 2.069, Y: -42.073, Z: -12.855 },
		{ X: 15.388, Y: -42.174, Z: -22.873 },
		{ X: 29.352, Y: -42.383, Z: -32.435 },
		{ X: 44.81, Y: -42.618, Z: -42.521 },
		{ X: 59.144, Y: -42.788, Z: -51.717 },
		{ X: 72.667, Y: -42.944, Z: -60.153 },
		{ X: 83.161, Y: -43.074, Z: -66.684 },
		{ X: 96.77, Y: -43.266, Z: -74.865 },
		{ X: 109.486, Y: -43.417, Z: -82.759 },
		{ X: 125.965, Y: -43.645, Z: -92.729 },
		{ X: 140.849, Y: -43.898, Z: -101.948 },
		{ X: 163.128, Y: -44.162, Z: -115.823 },
		{ X: 184.066, Y: -44.456, Z: -128.675 },
		{ X: 199.544, Y: -44.657, Z: -138.236 },
		{ X: 215.23, Y: -44.82, Z: -148.019 },
		{ X: -95.272, Y: -42.341, Z: 61.37 },
		{ X: -81.819, Y: -42.563, Z: 51.55 },
		{ X: -67.732, Y: -42.722, Z: 40.994 },
		{ X: -53.77, Y: -42.931, Z: 30.39 },
		{ X: -39.822, Y: -43.111, Z: 19.92 },
		{ X: -24.164, Y: -43.272, Z: 7.812 },
		{ X: -10.074, Y: -42.427, Z: -4.124 },
		{ X: 2.069, Y: -42.08, Z: -12.855 },
		{ X: 15.433, Y: -42.213, Z: -22.898 },
		{ X: 29.37, Y: -42.463, Z: -32.454 },
		{ X: 44.774, Y: -42.56, Z: -42.513 },
		{ X: 59.148, Y: -42.758, Z: -51.721 },
		{ X: 72.652, Y: -42.954, Z: -60.135 },
		{ X: 83.158, Y: -43.121, Z: -66.68 },
		{ X: 96.759, Y: -43.311, Z: -74.852 },
		{ X: 109.472, Y: -43.469, Z: -82.75 },
		{ X: 126.001, Y: -43.65, Z: -92.767 },
		{ X: 140.844, Y: -43.897, Z: -101.945 },
		{ X: 163.137, Y: -44.171, Z: -115.827 },
		{ X: 184.065, Y: -44.47, Z: -128.674 },
		{ X: 199.552, Y: -44.635, Z: -138.243 },
		{ X: 215.24, Y: -44.902, Z: -148.042 }
	],
	// Paul Ricard
	4248: [
		{ X: -95.336, Y: -42.303, Z: 61.529 },
		{ X: -81.831, Y: -42.557, Z: 51.565 },
		{ X: -67.744, Y: -42.735, Z: 41.009 },
		{ X: -53.735, Y: -42.965, Z: 30.37 },
		{ X: -39.844, Y: -43.141, Z: 19.949 },
		{ X: -24.124, Y: -43.296, Z: 7.764 },
		{ X: -10.105, Y: -42.427, Z: -4.075 },
		{ X: 2.069, Y: -42.073, Z: -12.855 },
		{ X: 15.388, Y: -42.174, Z: -22.873 },
		{ X: 29.352, Y: -42.383, Z: -32.435 },
		{ X: 44.81, Y: -42.618, Z: -42.521 },
		{ X: 59.144, Y: -42.788, Z: -51.717 },
		{ X: 72.667, Y: -42.944, Z: -60.153 },
		{ X: 83.161, Y: -43.074, Z: -66.684 },
		{ X: 96.77, Y: -43.266, Z: -74.865 },
		{ X: 109.486, Y: -43.417, Z: -82.759 },
		{ X: 125.965, Y: -43.645, Z: -92.729 },
		{ X: 140.849, Y: -43.898, Z: -101.948 },
		{ X: 163.128, Y: -44.162, Z: -115.823 },
		{ X: 184.066, Y: -44.456, Z: -128.675 },
		{ X: 199.544, Y: -44.657, Z: -138.236 },
		{ X: 215.23, Y: -44.82, Z: -148.019 },
		{ X: -95.272, Y: -42.341, Z: 61.37 },
		{ X: -81.819, Y: -42.563, Z: 51.55 },
		{ X: -67.732, Y: -42.722, Z: 40.994 },
		{ X: -53.77, Y: -42.931, Z: 30.39 },
		{ X: -39.822, Y: -43.111, Z: 19.92 },
		{ X: -24.164, Y: -43.272, Z: 7.812 },
		{ X: -10.074, Y: -42.427, Z: -4.124 },
		{ X: 2.069, Y: -42.08, Z: -12.855 },
		{ X: 15.433, Y: -42.213, Z: -22.898 },
		{ X: 29.37, Y: -42.463, Z: -32.454 },
		{ X: 44.774, Y: -42.56, Z: -42.513 },
		{ X: 59.148, Y: -42.758, Z: -51.721 },
		{ X: 72.652, Y: -42.954, Z: -60.135 },
		{ X: 83.158, Y: -43.121, Z: -66.68 },
		{ X: 96.759, Y: -43.311, Z: -74.852 },
		{ X: 109.472, Y: -43.469, Z: -82.75 },
		{ X: 126.001, Y: -43.65, Z: -92.767 },
		{ X: 140.844, Y: -43.897, Z: -101.945 },
		{ X: 163.137, Y: -44.171, Z: -115.827 },
		{ X: 184.065, Y: -44.47, Z: -128.674 },
		{ X: 199.552, Y: -44.635, Z: -138.243 },
		{ X: 215.24, Y: -44.902, Z: -148.042 }
	],
	// Zhuhai Circuit
	3464: [
		{ X: -115.909, Y: -51.971, Z: -69.683 },
		{ X: -119.733, Y: -51.963, Z: -59.109 },
		{ X: -123.82, Y: -52.021, Z: -47.956 },
		{ X: -127.777, Y: -51.921, Z: -37.641 },
		{ X: -131.973, Y: -51.935, Z: -26.686 },
		{ X: -135.97, Y: -51.888, Z: -16.159 },
		{ X: -139.904, Y: -51.89, Z: -5.352 },
		{ X: -144.088, Y: -51.872, Z: 5.6 },
		{ X: -147.986, Y: -51.844, Z: 16.126 },
		{ X: -152.085, Y: -51.774, Z: 26.681 },
		{ X: -155.999, Y: -51.752, Z: 37.349 },
		{ X: -159.936, Y: -51.745, Z: 48.24 },
		{ X: -164.026, Y: -51.694, Z: 58.903 },
		{ X: -168.011, Y: -51.7, Z: 69.651 },
		{ X: -171.77, Y: -51.601, Z: 80.076 },
		{ X: -176.126, Y: -51.609, Z: 91.044 },
		{ X: -180.132, Y: -51.642, Z: 101.561 },
		{ X: -184.264, Y: -51.56, Z: 112.569 },
		{ X: -188.53, Y: -51.578, Z: 123.071 },
		{ X: -192.565, Y: -51.532, Z: 133.651 },
		{ X: -115.882, Y: -52.004, Z: -69.638 },
		{ X: -119.75, Y: -51.964, Z: -59.102 },
		{ X: -123.843, Y: -51.968, Z: -47.916 },
		{ X: -127.772, Y: -51.982, Z: -37.676 },
		{ X: -131.97, Y: -51.913, Z: -26.725 },
		{ X: -135.978, Y: -51.95, Z: -16.109 },
		{ X: -139.879, Y: -51.883, Z: -5.393 },
		{ X: -144.065, Y: -51.853, Z: 5.549 },
		{ X: -147.981, Y: -51.808, Z: 16.082 },
		{ X: -152.089, Y: -51.786, Z: 26.71 },
		{ X: -155.996, Y: -51.79, Z: 37.343 },
		{ X: -159.938, Y: -51.725, Z: 48.264 },
		{ X: -164.027, Y: -51.685, Z: 58.902 },
		{ X: -168.013, Y: -51.696, Z: 69.674 },
		{ X: -171.75, Y: -51.632, Z: 80.035 },
		{ X: -176.151, Y: -51.607, Z: 91.114 },
		{ X: -180.131, Y: -51.659, Z: 101.552 },
		{ X: -184.262, Y: -51.56, Z: 112.549 },
		{ X: -188.527, Y: -51.583, Z: 123.053 },
		{ X: -192.564, Y: -51.481, Z: 133.645 }
	],
	// Sachsenring
	3538: [
		{ X: -18.996, Y: 0.507, Z: 111.921 },
		{ X: -28.713, Y: 0.3, Z: 105.845 },
		{ X: -38.708, Y: 0.31, Z: 99.161 },
		{ X: -49.312, Y: 0.308, Z: 91.695 },
		{ X: -59.54, Y: 0.334, Z: 84.64 },
		{ X: -69.893, Y: 0.266, Z: 77.493 },
		{ X: -80.121, Y: 0.331, Z: 70.444 },
		{ X: -90.361, Y: 0.331, Z: 63.388 },
		{ X: -100.755, Y: 0.318, Z: 56.288 },
		{ X: -111.029, Y: 0.327, Z: 49.184 },
		{ X: -121.305, Y: 0.334, Z: 42.031 },
		{ X: -131.365, Y: 0.29, Z: 35.08 },
		{ X: -141.616, Y: 0.33, Z: 27.986 },
		{ X: -151.725, Y: 0.327, Z: 21.01 },
		{ X: -162.382, Y: 0.287, Z: 13.587 },
		{ X: -172.361, Y: 0.285, Z: 6.694 },
		{ X: -182.85, Y: 0.309, Z: -0.445 },
		{ X: -193.153, Y: 0.305, Z: -7.677 },
		{ X: -203.698, Y: 0.289, Z: -14.89 },
		{ X: -214.023, Y: 0.173, Z: -20.966 },
		{ X: -214.966, Y: 0.224, Z: -17.799 },
		{ X: -19.019, Y: 0.466, Z: 111.878 },
		{ X: -28.7, Y: 0.291, Z: 105.859 },
		{ X: -38.746, Y: 0.337, Z: 99.148 },
		{ X: -49.308, Y: 0.332, Z: 91.699 },
		{ X: -59.504, Y: 0.323, Z: 84.652 },
		{ X: -69.907, Y: 0.322, Z: 77.475 },
		{ X: -80.092, Y: 0.308, Z: 70.448 },
		{ X: -90.369, Y: 0.319, Z: 63.379 },
		{ X: -100.768, Y: 0.332, Z: 56.275 },
		{ X: -111.082, Y: 0.322, Z: 49.153 },
		{ X: -121.284, Y: 0.26, Z: 42.057 },
		{ X: -131.425, Y: 0.335, Z: 35.04 },
		{ X: -141.594, Y: 0.323, Z: 28.009 },
		{ X: -151.724, Y: 0.324, Z: 21.009 },
		{ X: -162.384, Y: 0.3, Z: 13.587 },
		{ X: -172.405, Y: 0.264, Z: 6.674 },
		{ X: -182.865, Y: 0.271, Z: -0.463 },
		{ X: -193.174, Y: 0.299, Z: -7.671 },
		{ X: -203.677, Y: 0.232, Z: -14.864 },
		{ X: -214.049, Y: 0.174, Z: -20.926 },
		{ X: -214.764, Y: 0.225, Z: -17.648 }
	],
	// Spa-Francorchamps
	3870: [
		{ X: -211.611, Y: -13.077, Z: -121.085 },
		{ X: -207.261, Y: -13.243, Z: -127.857 },
		{ X: -203.357, Y: -13.294, Z: -134.142 },
		{ X: -198.933, Y: -13.39, Z: -141.356 },
		{ X: -194.03, Y: -13.466, Z: -149.139 },
		{ X: -188.337, Y: -13.567, Z: -158.139 },
		{ X: -183.601, Y: -13.624, Z: -165.592 },
		{ X: -178.955, Y: -13.731, Z: -173.103 },
		{ X: -172.481, Y: -13.81, Z: -183.538 },
		{ X: -165.636, Y: -13.819, Z: -194.579 },
		{ X: -157.471, Y: -13.93, Z: -207.709 },
		{ X: -149.8, Y: -14.063, Z: -220.002 },
		{ X: -142.621, Y: -14.139, Z: -231.014 },
		{ X: -135.48, Y: -14.252, Z: -242.369 },
		{ X: -129.569, Y: -14.287, Z: -251.939 },
		{ X: -123.207, Y: -14.395, Z: -262.408 },
		{ X: -116.296, Y: -14.482, Z: -273.257 },
		{ X: -111.166, Y: -14.595, Z: -281.42 },
		{ X: -105.981, Y: -14.698, Z: -289.483 },
		{ X: -101.393, Y: -14.745, Z: -296.621 },
		{ X: -96.205, Y: -14.844, Z: -304.866 },
		{ X: -90.545, Y: -14.944, Z: -314.123 },
		{ X: -85.066, Y: -15.049, Z: -322.735 },
		{ X: -79.894, Y: -15.114, Z: -331.09 },
		{ X: -74.943, Y: -15.206, Z: -338.657 },
		{ X: -70.106, Y: -15.271, Z: -346.536 },
		{ X: -65.493, Y: -15.332, Z: -354.007 },
		{ X: -59.721, Y: -15.48, Z: -362.421 },
		{ X: -211.603, Y: -13.145, Z: -121.077 },
		{ X: -207.262, Y: -13.213, Z: -127.868 },
		{ X: -203.36, Y: -13.302, Z: -134.115 },
		{ X: -198.939, Y: -13.344, Z: -141.321 },
		{ X: -194.028, Y: -13.439, Z: -149.144 },
		{ X: -188.35, Y: -13.565, Z: -158.111 },
		{ X: -183.634, Y: -13.609, Z: -165.538 },
		{ X: -179, Y: -13.739, Z: -173.034 },
		{ X: -172.48, Y: -13.802, Z: -183.528 },
		{ X: -165.629, Y: -13.876, Z: -194.558 },
		{ X: -157.43, Y: -13.937, Z: -207.776 },
		{ X: -149.838, Y: -14.069, Z: -219.943 },
		{ X: -142.617, Y: -14.145, Z: -231.012 },
		{ X: -135.505, Y: -14.221, Z: -242.335 },
		{ X: -129.576, Y: -14.301, Z: -251.92 },
		{ X: -123.196, Y: -14.42, Z: -262.423 },
		{ X: -116.276, Y: -14.492, Z: -273.275 },
		{ X: -111.168, Y: -14.609, Z: -281.419 },
		{ X: -105.964, Y: -14.665, Z: -289.535 },
		{ X: -101.423, Y: -14.744, Z: -296.59 },
		{ X: -96.187, Y: -14.822, Z: -304.909 },
		{ X: -90.565, Y: -14.911, Z: -314.054 },
		{ X: -85.035, Y: -15.06, Z: -322.776 },
		{ X: -79.91, Y: -15.093, Z: -331.057 },
		{ X: -74.977, Y: -15.174, Z: -338.622 },
		{ X: -70.095, Y: -15.258, Z: -346.615 },
		{ X: -65.525, Y: -15.36, Z: -353.96 },
		{ X: -59.722, Y: -15.478, Z: -362.97 }
	],
	// Spa-Francorchamps
	4542: [
		{ X: 172.045, Y: -38.908, Z: -368.684 },
		{ X: 164.522, Y: -38.399, Z: -360.667 },
		{ X: 157.19, Y: -37.935, Z: -352.708 },
		{ X: 149.727, Y: -37.437, Z: -344.556 },
		{ X: 142.26, Y: -36.883, Z: -336.417 },
		{ X: 134.905, Y: -36.43, Z: -328.41 },
		{ X: 127.4, Y: -35.945, Z: -320.26 },
		{ X: 119.951, Y: -35.432, Z: -312.387 },
		{ X: 112.31, Y: -34.872, Z: -304.355 },
		{ X: 105.012, Y: -34.288, Z: -296.123 },
		{ X: 97.754, Y: -33.731, Z: -288.22 },
		{ X: 91.096, Y: -33.167, Z: -280.653 },
		{ X: 82.781, Y: -32.653, Z: -271.798 },
		{ X: 74.4, Y: -32.142, Z: -262.864 },
		{ X: 66.66, Y: -31.674, Z: -254.499 },
		{ X: 59.27, Y: -31.199, Z: -246.458 },
		{ X: 51.813, Y: -30.606, Z: -238.436 },
		{ X: 44.318, Y: -30.108, Z: -230.28 },
		{ X: 36.971, Y: -29.66, Z: -222.318 },
		{ X: 29.514, Y: -29.072, Z: -214.535 },
		{ X: 21.781, Y: -28.547, Z: -206.391 },
		{ X: 14.107, Y: -28.009, Z: -198.366 },
		{ X: 6.507, Y: -27.584, Z: -190.605 },
		{ X: -1.633, Y: -26.96, Z: -182.258 },
		{ X: 171.996, Y: -38.94, Z: -368.638 },
		{ X: 164.517, Y: -38.404, Z: -360.658 },
		{ X: 157.203, Y: -37.97, Z: -352.727 },
		{ X: 149.707, Y: -37.453, Z: -344.524 },
		{ X: 142.295, Y: -36.925, Z: -336.445 },
		{ X: 134.899, Y: -36.433, Z: -328.403 },
		{ X: 127.398, Y: -35.934, Z: -320.261 },
		{ X: 119.971, Y: -35.367, Z: -312.419 },
		{ X: 112.316, Y: -34.845, Z: -304.365 },
		{ X: 104.971, Y: -34.294, Z: -296.086 },
		{ X: 97.759, Y: -33.775, Z: -288.225 },
		{ X: 91.058, Y: -33.22, Z: -280.592 },
		{ X: 82.759, Y: -32.656, Z: -271.793 },
		{ X: 74.444, Y: -32.14, Z: -262.91 },
		{ X: 66.7, Y: -31.689, Z: -254.535 },
		{ X: 59.342, Y: -31.165, Z: -246.542 },
		{ X: 51.835, Y: -30.667, Z: -238.472 },
		{ X: 44.297, Y: -30.106, Z: -230.267 },
		{ X: 36.973, Y: -29.589, Z: -222.325 },
		{ X: 29.535, Y: -29.074, Z: -214.565 },
		{ X: 21.756, Y: -28.576, Z: -206.351 },
		{ X: 14.061, Y: -28.035, Z: -198.302 },
		{ X: 6.548, Y: -27.551, Z: -190.66 },
		{ X: -1.633, Y: -26.949, Z: -182.259 }
	],
	// Spa-Francorchamps
	4543: [
		{ X: 172.07, Y: -38.91, Z: -368.677 },
		{ X: 164.577, Y: -38.478, Z: -360.718 },
		{ X: 157.222, Y: -37.976, Z: -352.731 },
		{ X: 149.719, Y: -37.453, Z: -344.521 },
		{ X: 142.3, Y: -36.884, Z: -336.467 },
		{ X: 134.872, Y: -36.451, Z: -328.384 },
		{ X: 127.397, Y: -35.877, Z: -320.284 },
		{ X: 119.947, Y: -35.391, Z: -312.41 },
		{ X: 112.281, Y: -34.906, Z: -304.309 },
		{ X: 104.989, Y: -34.29, Z: -296.098 },
		{ X: 97.773, Y: -33.741, Z: -288.216 },
		{ X: 91.07, Y: -33.191, Z: -280.624 },
		{ X: 82.759, Y: -32.627, Z: -271.796 },
		{ X: 74.389, Y: -32.113, Z: -262.859 },
		{ X: 66.693, Y: -31.712, Z: -254.542 },
		{ X: 59.285, Y: -31.124, Z: -246.493 },
		{ X: 51.829, Y: -30.69, Z: -238.475 },
		{ X: 44.275, Y: -30.099, Z: -230.245 },
		{ X: 37.009, Y: -29.59, Z: -222.358 },
		{ X: 29.499, Y: -29.072, Z: -214.51 },
		{ X: 21.757, Y: -28.557, Z: -206.35 },
		{ X: 14.03, Y: -28.006, Z: -198.294 },
		{ X: 6.512, Y: -27.515, Z: -190.665 },
		{ X: -1.611, Y: -26.949, Z: -182.2 },
		{ X: -215.468, Y: -13.046, Z: -116.35 },
		{ X: -208.533, Y: -13.161, Z: -127.225 },
		{ X: -201.544, Y: -13.28, Z: -138.171 },
		{ X: -195.061, Y: -13.378, Z: -148.397 },
		{ X: -188.611, Y: -13.538, Z: -158.583 },
		{ X: -182.597, Y: -13.67, Z: -168.143 },
		{ X: -175.92, Y: -13.757, Z: -178.483 },
		{ X: -169.065, Y: -13.771, Z: -189.481 },
		{ X: -163.103, Y: -13.863, Z: -198.838 },
		{ X: -157.673, Y: -13.892, Z: -207.558 },
		{ X: -151.689, Y: -14.037, Z: -217.478 },
		{ X: -145.347, Y: -14.092, Z: -227.85 },
		{ X: -138.942, Y: -14.222, Z: -238.205 },
		{ X: -132.352, Y: -14.296, Z: -248.471 },
		{ X: -126.104, Y: -14.383, Z: -258.672 },
		{ X: -119.561, Y: -14.464, Z: -269.312 },
		{ X: -113.345, Y: -14.578, Z: -279.197 },
		{ X: -107.006, Y: -14.706, Z: -289.22 },
		{ X: -100.877, Y: -14.752, Z: -299.069 },
		{ X: -94.525, Y: -14.84, Z: -309.106 },
		{ X: -88.199, Y: -14.987, Z: -319.222 },
		{ X: -81.362, Y: -15.1, Z: -330.321 },
		{ X: -74.805, Y: -15.217, Z: -340.757 },
		{ X: -68.897, Y: -15.298, Z: -350.018 },
		{ X: -62.153, Y: -15.375, Z: -360.74 },
		{ X: -62.536, Y: -15.439, Z: -357.727 },
		{ X: 172.045, Y: -38.982, Z: -368.655 },
		{ X: 164.564, Y: -38.423, Z: -360.697 },
		{ X: 157.22, Y: -37.909, Z: -352.727 },
		{ X: 149.731, Y: -37.412, Z: -344.538 },
		{ X: 142.256, Y: -36.959, Z: -336.423 },
		{ X: 134.866, Y: -36.378, Z: -328.375 },
		{ X: 127.364, Y: -35.868, Z: -320.233 },
		{ X: 119.914, Y: -35.433, Z: -312.354 },
		{ X: 112.275, Y: -34.831, Z: -304.297 },
		{ X: 105.006, Y: -34.358, Z: -296.134 },
		{ X: 97.767, Y: -33.782, Z: -288.211 },
		{ X: 91.056, Y: -33.164, Z: -280.607 },
		{ X: 82.754, Y: -32.612, Z: -271.787 },
		{ X: 74.453, Y: -32.18, Z: -262.934 },
		{ X: 66.66, Y: -31.674, Z: -254.517 },
		{ X: 59.271, Y: -31.129, Z: -246.475 },
		{ X: 51.842, Y: -30.609, Z: -238.5 },
		{ X: 44.295, Y: -30.166, Z: -230.247 },
		{ X: 37.013, Y: -29.669, Z: -222.364 },
		{ X: 29.52, Y: -29.088, Z: -214.537 },
		{ X: 21.768, Y: -28.55, Z: -206.373 },
		{ X: 14.033, Y: -28.015, Z: -198.299 },
		{ X: 6.535, Y: -27.523, Z: -190.678 },
		{ X: -1.614, Y: -26.971, Z: -182.197 },
		{ X: -215.474, Y: -13.039, Z: -116.326 },
		{ X: -208.555, Y: -13.218, Z: -127.214 },
		{ X: -201.519, Y: -13.31, Z: -138.189 },
		{ X: -195.064, Y: -13.408, Z: -148.387 },
		{ X: -188.623, Y: -13.546, Z: -158.546 },
		{ X: -182.599, Y: -13.678, Z: -168.123 },
		{ X: -175.938, Y: -13.758, Z: -178.475 },
		{ X: -169.106, Y: -13.787, Z: -189.416 },
		{ X: -163.114, Y: -13.898, Z: -198.81 },
		{ X: -157.67, Y: -13.963, Z: -207.567 },
		{ X: -151.679, Y: -13.984, Z: -217.498 },
		{ X: -145.327, Y: -14.119, Z: -227.896 },
		{ X: -138.925, Y: -14.186, Z: -238.205 },
		{ X: -132.32, Y: -14.276, Z: -248.521 },
		{ X: -126.075, Y: -14.371, Z: -258.715 },
		{ X: -119.591, Y: -14.445, Z: -269.278 },
		{ X: -113.352, Y: -14.58, Z: -279.176 },
		{ X: -107.032, Y: -14.691, Z: -289.155 },
		{ X: -100.884, Y: -14.751, Z: -299.054 },
		{ X: -94.546, Y: -14.868, Z: -309.081 },
		{ X: -88.199, Y: -14.971, Z: -319.218 },
		{ X: -81.357, Y: -15.088, Z: -330.337 },
		{ X: -74.81, Y: -15.17, Z: -340.743 },
		{ X: -68.902, Y: -15.278, Z: -350.003 },
		{ X: -62.142, Y: -15.424, Z: -360.76 },
		{ X: -59.527, Y: -15.493, Z: -360.494 }
	],
	// Silverstone Circuit
	4039: [
		{ X: -215.516, Y: -3.459, Z: -56.353 },
		{ X: -223.931, Y: -3.544, Z: -68.172 },
		{ X: -232.297, Y: -3.516, Z: -79.903 },
		{ X: -240.298, Y: -3.51, Z: -91.114 },
		{ X: -248.405, Y: -3.515, Z: -102.535 },
		{ X: -255.787, Y: -3.503, Z: -112.711 },
		{ X: -263.486, Y: -3.547, Z: -123.565 },
		{ X: -271.721, Y: -3.539, Z: -135.121 },
		{ X: -280.42, Y: -3.525, Z: -147.326 },
		{ X: -288.076, Y: -3.548, Z: -157.776 },
		{ X: -295.388, Y: -3.551, Z: -168.145 },
		{ X: -302.923, Y: -3.583, Z: -178.766 },
		{ X: -311.005, Y: -3.553, Z: -190.037 },
		{ X: -319.33, Y: -3.561, Z: -201.665 },
		{ X: -327.358, Y: -3.573, Z: -212.934 },
		{ X: -335.584, Y: -3.545, Z: -224.359 },
		{ X: -342.035, Y: -3.519, Z: -233.565 },
		{ X: -347.483, Y: -3.504, Z: -240.85 },
		{ X: -353.223, Y: -3.508, Z: -249.002 },
		{ X: -361.202, Y: -3.522, Z: -260.153 },
		{ X: -369.274, Y: -3.569, Z: -271.449 },
		{ X: -377.49, Y: -3.559, Z: -282.909 },
		{ X: -385.833, Y: -3.56, Z: -294.436 },
		{ X: -393.85, Y: -3.528, Z: -305.709 },
		{ X: -402.118, Y: -3.528, Z: -317.181 },
		{ X: -409.332, Y: -3.538, Z: -326.98 },
		{ X: -215.573, Y: -3.544, Z: -56.4 },
		{ X: -223.928, Y: -3.509, Z: -68.17 },
		{ X: -232.313, Y: -3.529, Z: -79.94 },
		{ X: -240.272, Y: -3.516, Z: -91.091 },
		{ X: -248.375, Y: -3.511, Z: -102.513 },
		{ X: -255.751, Y: -3.507, Z: -112.657 },
		{ X: -263.481, Y: -3.545, Z: -123.562 },
		{ X: -271.694, Y: -3.527, Z: -135.074 },
		{ X: -280.395, Y: -3.514, Z: -147.304 },
		{ X: -288.078, Y: -3.507, Z: -157.777 },
		{ X: -295.389, Y: -3.578, Z: -168.149 },
		{ X: -302.885, Y: -3.579, Z: -178.708 },
		{ X: -311.034, Y: -3.57, Z: -190.059 },
		{ X: -319.338, Y: -3.549, Z: -201.674 },
		{ X: -327.345, Y: -3.556, Z: -212.925 },
		{ X: -335.562, Y: -3.545, Z: -224.317 },
		{ X: -342.054, Y: -3.555, Z: -233.581 },
		{ X: -347.486, Y: -3.577, Z: -240.852 },
		{ X: -353.201, Y: -3.525, Z: -248.958 },
		{ X: -361.204, Y: -3.524, Z: -260.179 },
		{ X: -369.297, Y: -3.572, Z: -271.494 },
		{ X: -377.512, Y: -3.527, Z: -282.924 },
		{ X: -385.848, Y: -3.577, Z: -294.447 },
		{ X: -393.809, Y: -3.527, Z: -305.677 },
		{ X: -402.098, Y: -3.552, Z: -317.165 },
		{ X: -409.348, Y: -3.561, Z: -326.994 }
	],
	// Silverstone Circuit
	5816: [
		{ X: -215.516, Y: -3.459, Z: -56.353 },
		{ X: -223.931, Y: -3.544, Z: -68.172 },
		{ X: -232.297, Y: -3.516, Z: -79.903 },
		{ X: -240.298, Y: -3.51, Z: -91.114 },
		{ X: -248.405, Y: -3.515, Z: -102.535 },
		{ X: -255.787, Y: -3.503, Z: -112.711 },
		{ X: -263.486, Y: -3.547, Z: -123.565 },
		{ X: -271.721, Y: -3.539, Z: -135.121 },
		{ X: -280.42, Y: -3.525, Z: -147.326 },
		{ X: -288.076, Y: -3.548, Z: -157.776 },
		{ X: -295.388, Y: -3.551, Z: -168.145 },
		{ X: -302.923, Y: -3.583, Z: -178.766 },
		{ X: -311.005, Y: -3.553, Z: -190.037 },
		{ X: -319.33, Y: -3.561, Z: -201.665 },
		{ X: -327.358, Y: -3.573, Z: -212.934 },
		{ X: -335.584, Y: -3.545, Z: -224.359 },
		{ X: -342.035, Y: -3.519, Z: -233.565 },
		{ X: -347.483, Y: -3.504, Z: -240.85 },
		{ X: -353.223, Y: -3.508, Z: -249.002 },
		{ X: -361.202, Y: -3.522, Z: -260.153 },
		{ X: -369.274, Y: -3.569, Z: -271.449 },
		{ X: -377.49, Y: -3.559, Z: -282.909 },
		{ X: -385.833, Y: -3.56, Z: -294.436 },
		{ X: -393.85, Y: -3.528, Z: -305.709 },
		{ X: -402.118, Y: -3.528, Z: -317.181 },
		{ X: -409.332, Y: -3.538, Z: -326.98 },
		{ X: -215.573, Y: -3.544, Z: -56.4 },
		{ X: -223.928, Y: -3.509, Z: -68.17 },
		{ X: -232.313, Y: -3.529, Z: -79.94 },
		{ X: -240.272, Y: -3.516, Z: -91.091 },
		{ X: -248.375, Y: -3.511, Z: -102.513 },
		{ X: -255.751, Y: -3.507, Z: -112.657 },
		{ X: -263.481, Y: -3.545, Z: -123.562 },
		{ X: -271.694, Y: -3.527, Z: -135.074 },
		{ X: -280.395, Y: -3.514, Z: -147.304 },
		{ X: -288.078, Y: -3.507, Z: -157.777 },
		{ X: -295.389, Y: -3.578, Z: -168.149 },
		{ X: -302.885, Y: -3.579, Z: -178.708 },
		{ X: -311.034, Y: -3.57, Z: -190.059 },
		{ X: -319.338, Y: -3.549, Z: -201.674 },
		{ X: -327.345, Y: -3.556, Z: -212.925 },
		{ X: -335.562, Y: -3.545, Z: -224.317 },
		{ X: -342.054, Y: -3.555, Z: -233.581 },
		{ X: -347.486, Y: -3.577, Z: -240.852 },
		{ X: -353.201, Y: -3.525, Z: -248.958 },
		{ X: -361.204, Y: -3.524, Z: -260.179 },
		{ X: -369.297, Y: -3.572, Z: -271.494 },
		{ X: -377.512, Y: -3.527, Z: -282.924 },
		{ X: -385.848, Y: -3.577, Z: -294.447 },
		{ X: -393.809, Y: -3.527, Z: -305.677 },
		{ X: -402.098, Y: -3.552, Z: -317.165 },
		{ X: -409.348, Y: -3.561, Z: -326.994 }
	],
	// Silverstone Circuit
	5817: [
		{ X: 262.743, Y: 3.314, Z: 833.016 },
		{ X: 250.818, Y: 3.153, Z: 831.99 },
		{ X: 238.776, Y: 3.135, Z: 831.084 },
		{ X: 226.724, Y: 3.02, Z: 830.116 },
		{ X: 214.338, Y: 2.971, Z: 829.144 },
		{ X: 201.931, Y: 2.877, Z: 828.197 },
		{ X: 189.6, Y: 2.823, Z: 827.281 },
		{ X: 177.673, Y: 2.774, Z: 826.33 },
		{ X: 165.396, Y: 2.628, Z: 825.445 },
		{ X: 153.243, Y: 2.597, Z: 824.519 },
		{ X: 141.604, Y: 2.535, Z: 823.675 },
		{ X: 124.458, Y: 2.384, Z: 822.282 },
		{ X: 111.411, Y: 2.26, Z: 820.984 },
		{ X: 99.07, Y: 2.157, Z: 819.946 },
		{ X: 87.131, Y: 2.123, Z: 818.813 },
		{ X: 74.672, Y: 2.023, Z: 817.598 },
		{ X: 62.849, Y: 1.924, Z: 816.504 },
		{ X: 50.405, Y: 1.832, Z: 815.383 },
		{ X: 38.259, Y: 1.771, Z: 814.319 },
		{ X: 26.006, Y: 1.685, Z: 813.256 },
		{ X: 13.773, Y: 1.58, Z: 812.018 },
		{ X: 2.749, Y: 1.458, Z: 811.111 },
		{ X: -7.303, Y: 1.438, Z: 810.215 },
		{ X: -17.529, Y: 1.338, Z: 809.61 },
		{ X: -27.558, Y: 1.25, Z: 808.782 },
		{ X: 262.726, Y: 3.262, Z: 833.035 },
		{ X: 250.805, Y: 3.194, Z: 831.99 },
		{ X: 238.728, Y: 3.079, Z: 831.072 },
		{ X: 226.646, Y: 3.041, Z: 830.114 },
		{ X: 214.321, Y: 2.976, Z: 829.148 },
		{ X: 201.927, Y: 2.9, Z: 828.199 },
		{ X: 189.671, Y: 2.795, Z: 827.266 },
		{ X: 177.714, Y: 2.707, Z: 826.318 },
		{ X: 165.39, Y: 2.624, Z: 825.447 },
		{ X: 153.25, Y: 2.57, Z: 824.52 },
		{ X: 141.617, Y: 2.487, Z: 823.673 },
		{ X: 124.522, Y: 2.37, Z: 822.269 },
		{ X: 111.363, Y: 2.276, Z: 820.995 },
		{ X: 99.075, Y: 2.194, Z: 819.942 },
		{ X: 87.14, Y: 2.078, Z: 818.807 },
		{ X: 74.685, Y: 1.997, Z: 817.598 },
		{ X: 62.837, Y: 1.944, Z: 816.507 },
		{ X: 50.425, Y: 1.837, Z: 815.402 },
		{ X: 38.221, Y: 1.747, Z: 814.328 },
		{ X: 26.048, Y: 1.692, Z: 813.243 },
		{ X: 13.729, Y: 1.573, Z: 812.028 },
		{ X: 2.716, Y: 1.447, Z: 811.118 },
		{ X: -7.321, Y: 1.396, Z: 810.215 },
		{ X: -17.525, Y: 1.355, Z: 809.604 },
		{ X: -27.539, Y: 1.235, Z: 808.778 }
	],
	// Silverstone Circuit
	5862: [
		{ X: 262.743, Y: 3.314, Z: 833.016 },
		{ X: 250.818, Y: 3.153, Z: 831.99 },
		{ X: 238.776, Y: 3.135, Z: 831.084 },
		{ X: 226.724, Y: 3.02, Z: 830.116 },
		{ X: 214.338, Y: 2.971, Z: 829.144 },
		{ X: 201.931, Y: 2.877, Z: 828.197 },
		{ X: 189.6, Y: 2.823, Z: 827.281 },
		{ X: 177.673, Y: 2.774, Z: 826.33 },
		{ X: 165.396, Y: 2.628, Z: 825.445 },
		{ X: 153.243, Y: 2.597, Z: 824.519 },
		{ X: 141.604, Y: 2.535, Z: 823.675 },
		{ X: 124.458, Y: 2.384, Z: 822.282 },
		{ X: 111.411, Y: 2.26, Z: 820.984 },
		{ X: 99.07, Y: 2.157, Z: 819.946 },
		{ X: 87.131, Y: 2.123, Z: 818.813 },
		{ X: 74.672, Y: 2.023, Z: 817.598 },
		{ X: 62.849, Y: 1.924, Z: 816.504 },
		{ X: 50.405, Y: 1.832, Z: 815.383 },
		{ X: 38.259, Y: 1.771, Z: 814.319 },
		{ X: 26.006, Y: 1.685, Z: 813.256 },
		{ X: 13.773, Y: 1.58, Z: 812.018 },
		{ X: 2.749, Y: 1.458, Z: 811.111 },
		{ X: -7.303, Y: 1.438, Z: 810.215 },
		{ X: -17.529, Y: 1.338, Z: 809.61 },
		{ X: -27.558, Y: 1.25, Z: 808.782 },
		{ X: 262.726, Y: 3.262, Z: 833.035 },
		{ X: 250.805, Y: 3.194, Z: 831.99 },
		{ X: 238.728, Y: 3.079, Z: 831.072 },
		{ X: 226.646, Y: 3.041, Z: 830.114 },
		{ X: 214.321, Y: 2.976, Z: 829.148 },
		{ X: 201.927, Y: 2.9, Z: 828.199 },
		{ X: 189.671, Y: 2.795, Z: 827.266 },
		{ X: 177.714, Y: 2.707, Z: 826.318 },
		{ X: 165.39, Y: 2.624, Z: 825.447 },
		{ X: 153.25, Y: 2.57, Z: 824.52 },
		{ X: 141.617, Y: 2.487, Z: 823.673 },
		{ X: 124.522, Y: 2.37, Z: 822.269 },
		{ X: 111.363, Y: 2.276, Z: 820.995 },
		{ X: 99.075, Y: 2.194, Z: 819.942 },
		{ X: 87.14, Y: 2.078, Z: 818.807 },
		{ X: 74.685, Y: 1.997, Z: 817.598 },
		{ X: 62.837, Y: 1.944, Z: 816.507 },
		{ X: 50.425, Y: 1.837, Z: 815.402 },
		{ X: 38.221, Y: 1.747, Z: 814.328 },
		{ X: 26.048, Y: 1.692, Z: 813.243 },
		{ X: 13.729, Y: 1.573, Z: 812.028 },
		{ X: 2.716, Y: 1.447, Z: 811.118 },
		{ X: -7.321, Y: 1.396, Z: 810.215 },
		{ X: -17.525, Y: 1.355, Z: 809.604 },
		{ X: -27.539, Y: 1.235, Z: 808.778 }
	],
	// Chang International Circuit
	4253: [
		{ X: 50.952, Y: -16.575, Z: -318.209 },
		{ X: 59.78, Y: -16.642, Z: -325.99 },
		{ X: 68.839, Y: -16.63, Z: -333.849 },
		{ X: 77.747, Y: -16.63, Z: -341.614 },
		{ X: 86.897, Y: -16.666, Z: -349.602 },
		{ X: 95.555, Y: -16.685, Z: -357.145 },
		{ X: 104.651, Y: -16.69, Z: -365.158 },
		{ X: 113.452, Y: -16.713, Z: -372.848 },
		{ X: 132.711, Y: -16.788, Z: -389.927 },
		{ X: 141.471, Y: -16.809, Z: -397.707 },
		{ X: 150.276, Y: -16.802, Z: -405.723 },
		{ X: 158.921, Y: -16.752, Z: -413.71 },
		{ X: 167.59, Y: -16.801, Z: -421.896 },
		{ X: 176.094, Y: -16.832, Z: -429.897 },
		{ X: 184.872, Y: -16.864, Z: -438.29 },
		{ X: -24.718, Y: -16.358, Z: -252.771 },
		{ X: -16.037, Y: -16.387, Z: -260.55 },
		{ X: -7.036, Y: -16.394, Z: -268.396 },
		{ X: 1.608, Y: -16.438, Z: -276.194 },
		{ X: 10.919, Y: -16.436, Z: -284.331 },
		{ X: 51.009, Y: -16.603, Z: -318.223 },
		{ X: 59.802, Y: -16.683, Z: -326.024 },
		{ X: 68.851, Y: -16.649, Z: -333.855 },
		{ X: 77.707, Y: -16.678, Z: -341.592 },
		{ X: 86.889, Y: -16.655, Z: -349.6 },
		{ X: 95.561, Y: -16.665, Z: -357.171 },
		{ X: 104.615, Y: -16.691, Z: -365.116 },
		{ X: 113.423, Y: -16.753, Z: -372.837 },
		{ X: 132.663, Y: -16.727, Z: -389.903 },
		{ X: 141.429, Y: -16.772, Z: -397.685 },
		{ X: 150.297, Y: -16.811, Z: -405.733 },
		{ X: 158.96, Y: -16.763, Z: -413.754 },
		{ X: 167.573, Y: -16.759, Z: -421.886 },
		{ X: 176.16, Y: -16.825, Z: -429.942 },
		{ X: 184.904, Y: -16.843, Z: -438.314 },
		{ X: -24.723, Y: -16.339, Z: -252.772 },
		{ X: -16.036, Y: -16.377, Z: -260.55 },
		{ X: -7.036, Y: -16.419, Z: -268.391 },
		{ X: 1.634, Y: -16.419, Z: -276.229 },
		{ X: 10.933, Y: -16.467, Z: -284.339 }
	],
	// Chang International Circuit
	4944: [
		{ X: 50.951, Y: -16.575, Z: -318.182 },
		{ X: 59.806, Y: -16.658, Z: -325.995 },
		{ X: 68.846, Y: -16.636, Z: -333.859 },
		{ X: 77.748, Y: -16.636, Z: -341.608 },
		{ X: 86.846, Y: -16.683, Z: -349.568 },
		{ X: 95.506, Y: -16.677, Z: -357.111 },
		{ X: 104.639, Y: -16.711, Z: -365.142 },
		{ X: 113.458, Y: -16.747, Z: -372.863 },
		{ X: 132.685, Y: -16.751, Z: -389.923 },
		{ X: 141.461, Y: -16.738, Z: -397.711 },
		{ X: 150.296, Y: -16.758, Z: -405.708 },
		{ X: 158.981, Y: -16.802, Z: -413.755 },
		{ X: 167.592, Y: -16.824, Z: -421.883 },
		{ X: 176.142, Y: -16.812, Z: -429.945 },
		{ X: 184.856, Y: -16.843, Z: -438.288 },
		{ X: -24.728, Y: -16.321, Z: -252.745 },
		{ X: -16.052, Y: -16.335, Z: -260.518 },
		{ X: -6.993, Y: -16.377, Z: -268.421 },
		{ X: 1.615, Y: -16.396, Z: -276.219 },
		{ X: 10.865, Y: -16.465, Z: -284.308 },
		{ X: 51.018, Y: -16.616, Z: -318.209 },
		{ X: 59.778, Y: -16.615, Z: -325.98 },
		{ X: 68.813, Y: -16.671, Z: -333.842 },
		{ X: 77.756, Y: -16.634, Z: -341.61 },
		{ X: 86.868, Y: -16.664, Z: -349.601 }
	],
	// Road America
	5276: [
		{ X: 415.38, Y: -0.882, Z: -291.988 },
		{ X: 414.634, Y: -0.83, Z: -274.765 },
		{ X: 414.111, Y: -0.576, Z: -255.761 },
		{ X: 413.424, Y: -0.273, Z: -238.783 },
		{ X: 412.838, Y: 0.047, Z: -220.402 },
		{ X: 412.194, Y: 0.2, Z: -200.17 },
		{ X: 411.922, Y: 0.256, Z: -188.641 },
		{ X: 411.38, Y: 0.245, Z: -176.953 },
		{ X: 410.888, Y: 0.168, Z: -165.559 },
		{ X: 410.605, Y: 0.153, Z: -155.023 },
		{ X: 409.762, Y: 0.01, Z: -132.407 },
		{ X: 409.2, Y: 0.07, Z: -120.15 },
		{ X: 408.987, Y: 0.142, Z: -110.042 },
		{ X: 408.357, Y: 0.206, Z: -96.617 },
		{ X: 407.779, Y: 0.246, Z: -82.251 },
		{ X: 407.365, Y: 0.224, Z: -69.889 },
		{ X: 406.796, Y: 0.282, Z: -55.776 },
		{ X: 406.343, Y: 0.18, Z: -43.178 },
		{ X: 405.924, Y: 0.054, Z: -30.716 },
		{ X: 405.552, Y: -0.058, Z: -19.199 },
		{ X: 405.24, Y: -0.211, Z: -8.399 },
		{ X: 404.676, Y: -0.395, Z: 4.019 },
		{ X: 404.021, Y: -0.644, Z: 17.945 },
		{ X: 403.317, Y: -0.946, Z: 33.307 },
		{ X: 402.844, Y: -1.269, Z: 50.471 },
		{ X: 402.457, Y: -1.495, Z: 65.193 },
		{ X: 401.868, Y: -1.647, Z: 78.078 },
		{ X: 401.539, Y: -1.716, Z: 89.098 },
		{ X: 401.047, Y: -1.848, Z: 99.835 },
		{ X: 400.509, Y: -1.96, Z: 113.455 },
		{ X: 399.963, Y: -2.108, Z: 125.653 },
		{ X: 399.505, Y: -2.208, Z: 135.467 },
		{ X: 415.386, Y: -0.932, Z: -291.947 },
		{ X: 414.636, Y: -0.819, Z: -274.73 },
		{ X: 414.106, Y: -0.548, Z: -255.774 },
		{ X: 413.413, Y: -0.269, Z: -238.736 },
		{ X: 412.853, Y: 0.008, Z: -220.34 },
		{ X: 412.199, Y: 0.23, Z: -200.14 },
		{ X: 411.927, Y: 0.287, Z: -188.714 },
		{ X: 411.37, Y: 0.212, Z: -176.985 },
		{ X: 410.892, Y: 0.147, Z: -165.536 },
		{ X: 410.606, Y: 0.11, Z: -155.012 },
		{ X: 409.768, Y: 0.062, Z: -132.387 },
		{ X: 409.211, Y: 0.108, Z: -120.196 },
		{ X: 408.984, Y: 0.162, Z: -110.058 },
		{ X: 408.371, Y: 0.189, Z: -96.651 },
		{ X: 407.785, Y: 0.182, Z: -82.301 },
		{ X: 407.357, Y: 0.281, Z: -69.909 },
		{ X: 406.798, Y: 0.272, Z: -55.762 },
		{ X: 406.333, Y: 0.203, Z: -43.14 },
		{ X: 405.925, Y: 0.104, Z: -30.712 },
		{ X: 405.549, Y: -0.08, Z: -19.23 },
		{ X: 405.233, Y: -0.2, Z: -8.427 },
		{ X: 404.663, Y: -0.399, Z: 4.063 },
		{ X: 404.041, Y: -0.661, Z: 17.929 },
		{ X: 403.326, Y: -0.924, Z: 33.257 },
		{ X: 402.846, Y: -1.242, Z: 50.476 },
		{ X: 402.446, Y: -1.479, Z: 65.24 },
		{ X: 401.865, Y: -1.619, Z: 78.071 },
		{ X: 401.54, Y: -1.73, Z: 89.108 },
		{ X: 401.039, Y: -1.831, Z: 99.894 },
		{ X: 400.503, Y: -1.907, Z: 113.491 },
		{ X: 399.946, Y: -2.112, Z: 125.676 },
		{ X: 399.512, Y: -2.19, Z: 135.428 }
	],
	// Brno
	5298: [
		{ X: -1122.74133, Y: 28.1806774, Z: -564.863953 },
		{ X: -1108.03479, Y: 28.15886, Z: -571.272156 },
		{ X: -1093.35352, Y: 28.2253456, Z: -577.6634 },
		{ X: -1078.4751, Y: 28.3419933, Z: -584.1385 },
		{ X: -1063.65955, Y: 28.3855553, Z: -590.582031 },
		{ X: -1049.03882, Y: 28.521122, Z: -596.9852 },
		{ X: -1034.1604, Y: 28.60591, Z: -603.4281 },
		{ X: -1019.33746, Y: 28.6991119, Z: -609.917847 },
		{ X: -1004.58228, Y: 28.8552837, Z: -616.371765 },
		{ X: -989.682739, Y: 28.96084, Z: -622.863159 },
		{ X: -974.9934, Y: 29.0678463, Z: -629.268 },
		{ X: -960.238159, Y: 29.1384315, Z: -635.730957 },
		{ X: -945.3948, Y: 29.2609138, Z: -642.2194 },
		{ X: -1115.57874, Y: 28.1596928, Z: -568.0233 },
		{ X: -1100.84851, Y: 28.2043819, Z: -574.5149 },
		{ X: -1086.36975, Y: 28.29764, Z: -580.7711 },
		{ X: -1122.65247, Y: 28.1017685, Z: -564.902954 },
		{ X: -1108.0387, Y: 28.1782322, Z: -571.266 },
		{ X: -1093.38086, Y: 28.2424831, Z: -577.6524 },
		{ X: -1078.4845, Y: 28.3086414, Z: -584.1294 },
		{ X: -1063.71765, Y: 28.3951759, Z: -590.5391 },
		{ X: -1049.06946, Y: 28.5043125, Z: -596.9873 },
		{ X: -1034.12524, Y: 28.6135921, Z: -603.4646 },
		{ X: -1019.28253, Y: 28.7245979, Z: -609.9602 },
		{ X: -1004.62677, Y: 28.8422813, Z: -616.3333 },
		{ X: -989.6424, Y: 28.93084, Z: -622.8909 },
		{ X: -974.9372, Y: 29.0378342, Z: -629.269653 },
		{ X: -960.279541, Y: 29.1764889, Z: -635.722168 },
		{ X: -945.4294, Y: 29.2279434, Z: -642.1907 },
		{ X: -1115.62512, Y: 28.1387558, Z: -567.989258 },
		{ X: -1100.85925, Y: 28.2002945, Z: -574.5133 },
		{ X: -1086.35632, Y: 28.2858372, Z: -580.782654 },
		{ X: -1122.72632, Y: 28.09398, Z: -564.850769 },
		{ X: -1108.01428, Y: 28.1598816, Z: -571.28064 },
		{ X: -1093.39709, Y: 28.2387142, Z: -577.6266 },
		{ X: -1078.44177, Y: 28.3120346, Z: -584.170044 },
		{ X: -1063.71387, Y: 28.422884, Z: -590.5416 },
		{ X: -1049.04785, Y: 28.4924736, Z: -596.9972 },
		{ X: -1034.10791, Y: 28.6013451, Z: -603.4757 },
		{ X: -1019.32867, Y: 28.7221146, Z: -609.90686 },
		{ X: -1004.61072, Y: 28.8600578, Z: -616.3349 },
		{ X: -989.685364, Y: 28.94608, Z: -622.8788 },
		{ X: -974.9347, Y: 29.0758553, Z: -629.2872 },
		{ X: -960.2791, Y: 29.18244, Z: -635.702942 },
		{ X: -945.3927, Y: 29.2350521, Z: -642.2191 },
		{ X: -1115.61707, Y: 28.1319141, Z: -567.969666 },
		{ X: -1100.80432, Y: 28.1907711, Z: -574.55365 },
		{ X: -1086.35327, Y: 28.28109, Z: -580.8057 }
	],
	// Scandinavian Raceway
	5301: [
		{ X: -242.154, Y: 15.04, Z: -448.591 },
		{ X: -236.867, Y: 14.944, Z: -440.05 },
		{ X: -231.682, Y: 14.864, Z: -431.739 },
		{ X: -226.157, Y: 14.805, Z: -422.855 },
		{ X: -221.099, Y: 14.719, Z: -414.12 },
		{ X: -208.352, Y: 14.642, Z: -392.999 },
		{ X: -202.104, Y: 14.65, Z: -382.896 },
		{ X: -196.034, Y: 14.616, Z: -372.931 },
		{ X: -190.131, Y: 14.618, Z: -363.254 },
		{ X: -183.985, Y: 14.615, Z: -353.507 },
		{ X: -178.045, Y: 14.596, Z: -343.811 },
		{ X: -171.654, Y: 14.604, Z: -333.39 },
		{ X: -165.5, Y: 14.578, Z: -323.551 },
		{ X: -159.121, Y: 14.561, Z: -313.465 },
		{ X: -153.047, Y: 14.558, Z: -303.616 },
		{ X: -146.701, Y: 14.542, Z: -294.039 },
		{ X: -242.191, Y: 14.939, Z: -448.537 },
		{ X: -236.849, Y: 14.887, Z: -440.033 },
		{ X: -231.639, Y: 14.851, Z: -431.703 },
		{ X: -226.151, Y: 14.822, Z: -422.853 },
		{ X: -221.118, Y: 14.726, Z: -414.217 },
		{ X: -208.365, Y: 14.708, Z: -392.998 },
		{ X: -202.089, Y: 14.652, Z: -382.896 },
		{ X: -196.075, Y: 14.629, Z: -372.993 },
		{ X: -190.15, Y: 14.672, Z: -363.261 },
		{ X: -183.964, Y: 14.6, Z: -353.451 },
		{ X: -178.027, Y: 14.586, Z: -343.808 },
		{ X: -171.632, Y: 14.582, Z: -333.393 },
		{ X: -165.531, Y: 14.555, Z: -323.598 },
		{ X: -159.126, Y: 14.545, Z: -313.488 },
		{ X: -153.07, Y: 14.586, Z: -303.643 },
		{ X: -146.668, Y: 14.584, Z: -294.005 }
	],
	// Scandinavian Raceway
	6164: [
		{ X: -242.18, Y: 15.04, Z: -448.63 },
		{ X: -236.86, Y: 14.892, Z: -440.115 },
		{ X: -231.66, Y: 14.833, Z: -431.77 },
		{ X: -226.185, Y: 14.781, Z: -422.955 },
		{ X: -221.096, Y: 14.728, Z: -414.176 },
		{ X: -208.343, Y: 14.668, Z: -393.022 },
		{ X: -202.065, Y: 14.691, Z: -382.921 },
		{ X: -196.048, Y: 14.688, Z: -372.947 },
		{ X: -190.107, Y: 14.652, Z: -363.259 },
		{ X: -183.997, Y: 14.597, Z: -353.515 },
		{ X: -178.085, Y: 14.655, Z: -343.856 },
		{ X: -171.561, Y: 14.604, Z: -333.374 },
		{ X: -165.486, Y: 14.594, Z: -323.621 },
		{ X: -159.113, Y: 14.582, Z: -313.521 },
		{ X: -153.103, Y: 14.583, Z: -303.646 },
		{ X: -146.846, Y: 14.566, Z: -293.859 },
		{ X: -242.149, Y: 14.947, Z: -448.531 },
		{ X: -236.884, Y: 14.87, Z: -440.125 },
		{ X: -231.642, Y: 14.82, Z: -431.759 },
		{ X: -226.181, Y: 14.805, Z: -422.942 },
		{ X: -221.087, Y: 14.722, Z: -414.198 },
		{ X: -208.365, Y: 14.684, Z: -393.02 },
		{ X: -202.079, Y: 14.685, Z: -382.908 },
		{ X: -196.058, Y: 14.693, Z: -372.985 },
		{ X: -190.142, Y: 14.673, Z: -363.311 },
		{ X: -183.984, Y: 14.605, Z: -353.507 },
		{ X: -178.068, Y: 14.618, Z: -343.858 },
		{ X: -171.573, Y: 14.562, Z: -333.412 },
		{ X: -165.509, Y: 14.586, Z: -323.646 },
		{ X: -159.116, Y: 14.573, Z: -313.536 },
		{ X: -153.1, Y: 14.581, Z: -303.633 },
		{ X: -146.856, Y: 14.545, Z: -293.882 }
	],
	// Gelleråsen Arena
	5925: [
		{ X: 11.847, Y: -2.239, Z: 28.017 },
		{ X: 1.88, Y: -2.275, Z: 27.211 },
		{ X: -8.41, Y: -2.345, Z: 26.347 },
		{ X: -18.458, Y: -2.318, Z: 25.56 },
		{ X: -28.542, Y: -2.287, Z: 24.693 },
		{ X: -38.384, Y: -2.346, Z: 23.882 },
		{ X: -48.11, Y: -2.303, Z: 23.111 },
		{ X: -58.199, Y: -2.332, Z: 22.423 },
		{ X: -69.236, Y: -2.344, Z: 21.531 },
		{ X: -80.814, Y: -2.292, Z: 20.555 },
		{ X: -92.366, Y: -2.309, Z: 19.628 },
		{ X: -104.048, Y: -2.293, Z: 18.653 },
		{ X: -115.313, Y: -2.302, Z: 17.708 },
		{ X: -127.245, Y: -2.321, Z: 16.737 },
		{ X: -138.456, Y: -2.328, Z: 15.662 },
		{ X: -150.116, Y: -2.294, Z: 14.637 },
		{ X: -161.54, Y: -2.362, Z: 13.781 },
		{ X: -173.179, Y: -2.306, Z: 12.706 },
		{ X: 11.819, Y: -2.296, Z: 28.005 },
		{ X: 1.861, Y: -2.275, Z: 27.202 },
		{ X: -8.414, Y: -2.353, Z: 26.344 },
		{ X: -18.461, Y: -2.343, Z: 25.556 },
		{ X: -28.558, Y: -2.281, Z: 24.727 },
		{ X: -38.405, Y: -2.301, Z: 23.913 },
		{ X: -48.114, Y: -2.282, Z: 23.092 },
		{ X: -58.229, Y: -2.363, Z: 22.399 },
		{ X: -69.234, Y: -2.323, Z: 21.536 },
		{ X: -80.799, Y: -2.325, Z: 20.555 },
		{ X: -92.37, Y: -2.325, Z: 19.629 },
		{ X: -104.087, Y: -2.282, Z: 18.66 },
		{ X: -115.296, Y: -2.32, Z: 17.726 },
		{ X: -127.279, Y: -2.342, Z: 16.725 },
		{ X: -138.419, Y: -2.315, Z: 15.652 },
		{ X: -150.103, Y: -2.314, Z: 14.637 },
		{ X: -161.518, Y: -2.342, Z: 13.783 },
		{ X: -173.154, Y: -2.282, Z: 12.717 }
	],
	// Gelleråsen Arena
	6138: [
		{ X: 11.847, Y: -2.239, Z: 28.017 },
		{ X: 1.88, Y: -2.275, Z: 27.211 },
		{ X: -8.41, Y: -2.345, Z: 26.347 },
		{ X: -18.458, Y: -2.318, Z: 25.56 },
		{ X: -28.542, Y: -2.287, Z: 24.693 },
		{ X: -38.384, Y: -2.346, Z: 23.882 },
		{ X: -48.11, Y: -2.303, Z: 23.111 },
		{ X: -58.199, Y: -2.332, Z: 22.423 },
		{ X: -69.236, Y: -2.344, Z: 21.531 },
		{ X: -80.814, Y: -2.292, Z: 20.555 },
		{ X: -92.366, Y: -2.309, Z: 19.628 },
		{ X: -104.048, Y: -2.293, Z: 18.653 },
		{ X: -115.313, Y: -2.302, Z: 17.708 },
		{ X: -127.245, Y: -2.321, Z: 16.737 },
		{ X: -138.456, Y: -2.328, Z: 15.662 },
		{ X: -150.116, Y: -2.294, Z: 14.637 },
		{ X: -161.54, Y: -2.362, Z: 13.781 },
		{ X: -173.179, Y: -2.306, Z: 12.706 },
		{ X: 11.819, Y: -2.296, Z: 28.005 },
		{ X: 1.861, Y: -2.275, Z: 27.202 },
		{ X: -8.414, Y: -2.353, Z: 26.344 },
		{ X: -18.461, Y: -2.343, Z: 25.556 },
		{ X: -28.558, Y: -2.281, Z: 24.727 },
		{ X: -38.405, Y: -2.301, Z: 23.913 },
		{ X: -48.114, Y: -2.282, Z: 23.092 },
		{ X: -58.229, Y: -2.363, Z: 22.399 },
		{ X: -69.234, Y: -2.323, Z: 21.536 },
		{ X: -80.799, Y: -2.325, Z: 20.555 },
		{ X: -92.37, Y: -2.325, Z: 19.629 },
		{ X: -104.087, Y: -2.282, Z: 18.66 },
		{ X: -115.296, Y: -2.32, Z: 17.726 },
		{ X: -127.279, Y: -2.342, Z: 16.725 },
		{ X: -138.419, Y: -2.315, Z: 15.652 },
		{ X: -150.103, Y: -2.314, Z: 14.637 },
		{ X: -161.518, Y: -2.342, Z: 13.783 },
		{ X: -173.154, Y: -2.282, Z: 12.717 }
	],
	// Mantorp Park
	6010: [
		{ X: 109.905, Y: 2.794, Z: -13.469 },
		{ X: 100.966, Y: 2.768, Z: -17.675 },
		{ X: 91.765, Y: 2.875, Z: -22.108 },
		{ X: 82.575, Y: 2.946, Z: -26.35 },
		{ X: 73.531, Y: 3.005, Z: -30.604 },
		{ X: 64.386, Y: 3.07, Z: -34.947 },
		{ X: 55.313, Y: 3.089, Z: -39.36 },
		{ X: 46.186, Y: 3.205, Z: -43.717 },
		{ X: 37.097, Y: 3.262, Z: -48.098 },
		{ X: 27.869, Y: 3.331, Z: -52.277 },
		{ X: 18.647, Y: 3.391, Z: -56.699 },
		{ X: 9.489, Y: 3.406, Z: -61.027 },
		{ X: 0.199, Y: 3.491, Z: -65.528 },
		{ X: -8.864, Y: 3.578, Z: -69.659 },
		{ X: -17.985, Y: 3.629, Z: -73.947 },
		{ X: -27.07, Y: 3.683, Z: -78.231 },
		{ X: -36.305, Y: 3.669, Z: -82.492 },
		{ X: -44.457, Y: 3.629, Z: -85.335 },
		{ X: 109.888, Y: 2.748, Z: -13.473 },
		{ X: 101.047, Y: 2.76, Z: -17.633 },
		{ X: 91.781, Y: 2.88, Z: -22.095 },
		{ X: 82.544, Y: 2.922, Z: -26.375 },
		{ X: 73.529, Y: 3.018, Z: -30.604 },
		{ X: 64.384, Y: 3.059, Z: -34.948 },
		{ X: 55.31, Y: 3.127, Z: -39.365 },
		{ X: 46.229, Y: 3.199, Z: -43.679 },
		{ X: 37.104, Y: 3.264, Z: -48.093 },
		{ X: 27.875, Y: 3.33, Z: -52.275 },
		{ X: 18.622, Y: 3.35, Z: -56.718 },
		{ X: 9.451, Y: 3.393, Z: -61.059 },
		{ X: 0.173, Y: 3.513, Z: -65.525 },
		{ X: -8.861, Y: 3.569, Z: -69.659 },
		{ X: -17.988, Y: 3.586, Z: -73.947 },
		{ X: -27.094, Y: 3.638, Z: -78.248 },
		{ X: -36.255, Y: 3.698, Z: -82.442 },
		{ X: -43.212, Y: 3.635, Z: -85.879 }
	],
	// Mantorp Park
	6167: [
		{ X: 109.981, Y: 2.793, Z: -13.436 },
		{ X: 101.018, Y: 2.827, Z: -17.671 },
		{ X: 91.792, Y: 2.897, Z: -22.097 },
		{ X: 82.59, Y: 2.953, Z: -26.337 },
		{ X: 73.471, Y: 3.011, Z: -30.62 },
		{ X: 64.354, Y: 3.087, Z: -34.961 },
		{ X: 55.28, Y: 3.143, Z: -39.349 },
		{ X: 46.217, Y: 3.212, Z: -43.668 },
		{ X: 37.069, Y: 3.244, Z: -48.108 },
		{ X: 27.89, Y: 3.278, Z: -52.283 },
		{ X: 18.6, Y: 3.392, Z: -56.741 },
		{ X: 9.446, Y: 3.457, Z: -61.062 },
		{ X: 0.217, Y: 3.469, Z: -65.458 },
		{ X: -8.919, Y: 3.573, Z: -69.649 },
		{ X: -17.936, Y: 3.608, Z: -73.903 },
		{ X: -27.068, Y: 3.608, Z: -78.248 },
		{ X: -36.309, Y: 3.67, Z: -82.52 },
		{ X: -44.597, Y: 3.631, Z: -85.647 },
		{ X: 109.93, Y: 2.766, Z: -13.475 },
		{ X: 100.983, Y: 2.805, Z: -17.674 },
		{ X: 91.761, Y: 2.84, Z: -22.12 },
		{ X: 82.557, Y: 2.955, Z: -26.362 },
		{ X: 73.479, Y: 3.025, Z: -30.617 },
		{ X: 64.371, Y: 3.008, Z: -34.945 },
		{ X: 55.355, Y: 3.145, Z: -39.311 },
		{ X: 46.213, Y: 3.173, Z: -43.673 },
		{ X: 37.05, Y: 3.267, Z: -48.123 },
		{ X: 27.92, Y: 3.328, Z: -52.258 },
		{ X: 18.58, Y: 3.354, Z: -56.736 },
		{ X: 9.454, Y: 3.441, Z: -61.058 },
		{ X: 0.21, Y: 3.515, Z: -65.462 },
		{ X: -8.891, Y: 3.563, Z: -69.626 },
		{ X: -17.962, Y: 3.567, Z: -73.922 },
		{ X: -27.075, Y: 3.676, Z: -78.244 },
		{ X: -36.255, Y: 3.698, Z: -82.442 },
		{ X: -43.212, Y: 3.635, Z: -85.879 }
	],
	// Stowe Circuit
	6055: [
		{ X: 83.705, Y: 0.328, Z: -284.827 },
		{ X: 78.745, Y: 0.176, Z: -291.075 },
		{ X: 74.997, Y: 0.091, Z: -296.398 },
		{ X: 71.154, Y: 0.027, Z: -302.384 },
		{ X: 68.911, Y: -0.026, Z: -308.938 },
		{ X: 66.902, Y: 0, Z: -316.07 },
		{ X: 64.841, Y: -0.007, Z: -322.821 },
		{ X: 63.37, Y: -0.027, Z: -329.465 },
		{ X: 61.348, Y: -0.049, Z: -337.007 },
		{ X: 59.431, Y: -0.063, Z: -343.979 },
		{ X: 57.503, Y: -0.122, Z: -351.033 },
		{ X: 55.561, Y: -0.175, Z: -358.335 },
		{ X: 53.173, Y: -0.263, Z: -365.559 },
		{ X: 51.031, Y: -0.405, Z: -373.169 },
		{ X: 48.96, Y: -0.577, Z: -380.22 },
		{ X: 83.679, Y: 0.26, Z: -284.85 },
		{ X: 83.664, Y: 0.276, Z: -284.864 },
		{ X: 83.673, Y: 0.256, Z: -284.856 },
		{ X: 83.647, Y: 0.256, Z: -284.879 },
		{ X: 83.655, Y: 0.236, Z: -284.872 },
		{ X: 83.678, Y: 0.267, Z: -284.835 },
		{ X: 83.679, Y: 0.234, Z: -284.837 },
		{ X: 83.662, Y: 0.281, Z: -284.866 },
		{ X: 83.667, Y: 0.248, Z: -284.861 },
		{ X: 83.666, Y: 0.262, Z: -284.862 },
		{ X: 78.739, Y: 0.134, Z: -291.082 },
		{ X: 75.002, Y: 0.111, Z: -296.41 },
		{ X: 71.143, Y: 0.034, Z: -302.376 },
		{ X: 68.929, Y: 0.01, Z: -308.909 },
		{ X: 66.875, Y: -0.012, Z: -316.117 }
	],
	// Stowe Circuit
	6056: [
		{ X: 83.705, Y: 0.328, Z: -284.827 },
		{ X: 78.745, Y: 0.176, Z: -291.075 },
		{ X: 74.997, Y: 0.091, Z: -296.398 },
		{ X: 71.154, Y: 0.027, Z: -302.384 },
		{ X: 68.911, Y: -0.026, Z: -308.938 },
		{ X: 66.902, Y: 0, Z: -316.07 },
		{ X: 64.841, Y: -0.007, Z: -322.821 },
		{ X: 63.37, Y: -0.027, Z: -329.465 },
		{ X: 61.348, Y: -0.049, Z: -337.007 },
		{ X: 59.431, Y: -0.063, Z: -343.979 },
		{ X: 57.503, Y: -0.122, Z: -351.033 },
		{ X: 55.561, Y: -0.175, Z: -358.335 },
		{ X: 53.173, Y: -0.263, Z: -365.559 },
		{ X: 51.031, Y: -0.405, Z: -373.169 },
		{ X: 48.96, Y: -0.577, Z: -380.22 },
		{ X: 83.679, Y: 0.26, Z: -284.85 },
		{ X: 83.664, Y: 0.276, Z: -284.864 },
		{ X: 83.673, Y: 0.256, Z: -284.856 },
		{ X: 83.647, Y: 0.256, Z: -284.879 },
		{ X: 83.655, Y: 0.236, Z: -284.872 },
		{ X: 83.678, Y: 0.267, Z: -284.835 },
		{ X: 83.679, Y: 0.234, Z: -284.837 },
		{ X: 83.662, Y: 0.281, Z: -284.866 },
		{ X: 83.667, Y: 0.248, Z: -284.861 },
		{ X: 83.666, Y: 0.262, Z: -284.862 },
		{ X: 78.739, Y: 0.134, Z: -291.082 },
		{ X: 75.002, Y: 0.111, Z: -296.41 },
		{ X: 71.143, Y: 0.034, Z: -302.376 },
		{ X: 68.929, Y: 0.01, Z: -308.909 },
		{ X: 66.875, Y: -0.012, Z: -316.117 }
	],
	// Knutsorp Ring
	6137: [
		{ X: -353.293, Y: -5.733, Z: -24.665 },
		{ X: -344.942, Y: -5.71, Z: -29.94 },
		{ X: -336.491, Y: -5.704, Z: -35.425 },
		{ X: -327.944, Y: -5.602, Z: -41.126 },
		{ X: -319.469, Y: -5.533, Z: -46.649 },
		{ X: -311.269, Y: -5.524, Z: -52.118 },
		{ X: -302.911, Y: -5.525, Z: -57.884 },
		{ X: -294.723, Y: -5.427, Z: -63.44 },
		{ X: -286.335, Y: -5.369, Z: -69.132 },
		{ X: -278.067, Y: -5.211, Z: -74.666 },
		{ X: -269.529, Y: -5.151, Z: -80.209 },
		{ X: -261.301, Y: -5.098, Z: -85.727 },
		{ X: -252.298, Y: -5.069, Z: -91.766 },
		{ X: -244.646, Y: -5.059, Z: -96.979 },
		{ X: -236.296, Y: -5.026, Z: -102.608 },
		{ X: -227.877, Y: -5.016, Z: -108.122 },
		{ X: -353.284, Y: -5.841, Z: -24.687 },
		{ X: -344.972, Y: -5.789, Z: -29.929 },
		{ X: -336.465, Y: -5.712, Z: -35.45 },
		{ X: -327.971, Y: -5.637, Z: -41.095 },
		{ X: -319.464, Y: -5.589, Z: -46.656 },
		{ X: -311.255, Y: -5.561, Z: -52.133 },
		{ X: -302.887, Y: -5.483, Z: -57.907 },
		{ X: -294.684, Y: -5.451, Z: -63.457 },
		{ X: -286.339, Y: -5.302, Z: -69.13 },
		{ X: -278.113, Y: -5.213, Z: -74.621 },
		{ X: -269.584, Y: -5.13, Z: -80.178 },
		{ X: -261.284, Y: -5.077, Z: -85.746 },
		{ X: -252.3, Y: -5.055, Z: -91.763 },
		{ X: -244.605, Y: -5.091, Z: -96.998 },
		{ X: -236.304, Y: -5.03, Z: -102.598 },
		{ X: -227.92, Y: -5.011, Z: -108.075 }
	],
	// Falkenberg Motorbana
	6140: [
		{ X: -81.395, Y: -4.131, Z: -40.854 },
		{ X: -87.917, Y: -4.129, Z: -45.746 },
		{ X: -93.694, Y: -4.055, Z: -50.163 },
		{ X: -99.878, Y: -3.976, Z: -54.661 },
		{ X: -105.743, Y: -3.894, Z: -58.962 },
		{ X: -111.903, Y: -3.83, Z: -63.467 },
		{ X: -118.2, Y: -3.706, Z: -67.918 },
		{ X: -124.198, Y: -3.638, Z: -72.471 },
		{ X: -130.53, Y: -3.62, Z: -77.066 },
		{ X: -136.339, Y: -3.607, Z: -81.404 },
		{ X: -142.323, Y: -3.525, Z: -85.781 },
		{ X: -148.567, Y: -3.486, Z: -90.312 },
		{ X: -154.356, Y: -3.408, Z: -94.73 },
		{ X: -160.577, Y: -3.317, Z: -99.315 },
		{ X: -166.723, Y: -3.322, Z: -103.707 },
		{ X: -172.735, Y: -3.27, Z: -108.365 },
		{ X: -178.506, Y: -3.267, Z: -112.617 },
		{ X: -184.885, Y: -3.262, Z: -117.272 },
		{ X: -81.397, Y: -4.167, Z: -40.881 },
		{ X: -87.941, Y: -4.098, Z: -45.758 },
		{ X: -93.738, Y: -4.079, Z: -50.195 },
		{ X: -99.874, Y: -3.982, Z: -54.659 },
		{ X: -105.747, Y: -3.882, Z: -58.966 },
		{ X: -111.934, Y: -3.802, Z: -63.496 },
		{ X: -118.172, Y: -3.703, Z: -67.924 },
		{ X: -124.243, Y: -3.641, Z: -72.488 },
		{ X: -130.488, Y: -3.61, Z: -77.051 },
		{ X: -136.34, Y: -3.558, Z: -81.404 },
		{ X: -142.35, Y: -3.568, Z: -85.772 },
		{ X: -148.599, Y: -3.472, Z: -90.323 },
		{ X: -154.398, Y: -3.403, Z: -94.728 },
		{ X: -160.616, Y: -3.331, Z: -99.353 },
		{ X: -166.716, Y: -3.328, Z: -103.71 },
		{ X: -172.754, Y: -3.275, Z: -108.357 },
		{ X: -178.542, Y: -3.276, Z: -112.649 },
		{ X: -184.861, Y: -3.225, Z: -117.28 }
	],
	// Sepang
	6341: [
		{ X: -103.251, Y: -1.094, Z: 24.815 },
		{ X: -86.455, Y: -1.109, Z: 24.775 },
		{ X: -70.598, Y: -1.082, Z: 24.726 },
		{ X: -58.731, Y: -1.025, Z: 24.875 },
		{ X: -46.763, Y: -0.969, Z: 24.731 },
		{ X: -30.712, Y: -0.963, Z: 24.843 },
		{ X: -14.782, Y: -0.934, Z: 24.854 },
		{ X: -3.25, Y: -0.909, Z: 24.917 },
		{ X: 9.373, Y: -0.894, Z: 24.878 },
		{ X: 25.125, Y: -0.864, Z: 24.843 },
		{ X: 41.122, Y: -0.829, Z: 24.709 },
		{ X: 52.943, Y: -0.805, Z: 24.856 },
		{ X: 65.39, Y: -0.784, Z: 24.725 },
		{ X: 81.256, Y: -0.708, Z: 24.803 },
		{ X: 97.132, Y: -0.711, Z: 24.806 },
		{ X: 109.277, Y: -0.684, Z: 24.912 },
		{ X: 121.403, Y: -0.651, Z: 24.843 },
		{ X: 137.079, Y: -0.627, Z: 24.801 },
		{ X: 153.267, Y: -0.608, Z: 24.827 },
		{ X: -103.218, Y: -1.122, Z: 24.793 },
		{ X: -86.451, Y: -1.104, Z: 24.79 },
		{ X: -70.623, Y: -1.087, Z: 24.733 },
		{ X: -58.673, Y: -1.019, Z: 24.841 },
		{ X: -46.783, Y: -0.985, Z: 24.729 },
		{ X: -30.716, Y: -0.969, Z: 24.843 },
		{ X: -14.788, Y: -0.89, Z: 24.856 },
		{ X: -3.218, Y: -0.902, Z: 24.931 },
		{ X: 9.38, Y: -0.835, Z: 24.877 },
		{ X: 25.099, Y: -0.873, Z: 24.827 },
		{ X: 41.153, Y: -0.814, Z: 24.699 },
		{ X: 52.951, Y: -0.785, Z: 24.836 },
		{ X: 65.353, Y: -0.785, Z: 24.713 },
		{ X: 81.236, Y: -0.696, Z: 24.786 },
		{ X: 97.142, Y: -0.679, Z: 24.803 },
		{ X: 109.269, Y: -0.709, Z: 24.915 },
		{ X: 121.333, Y: -0.678, Z: 24.856 },
		{ X: 137.079, Y: -0.638, Z: 24.801 },
		{ X: 153.302, Y: -0.603, Z: 24.816 }
	],
	// Sepang
	6578: [
		{ X: -103.251, Y: -1.094, Z: 24.815 },
		{ X: -86.455, Y: -1.109, Z: 24.775 },
		{ X: -70.598, Y: -1.082, Z: 24.726 },
		{ X: -58.731, Y: -1.025, Z: 24.875 },
		{ X: -46.763, Y: -0.969, Z: 24.731 },
		{ X: -30.712, Y: -0.963, Z: 24.843 },
		{ X: -14.782, Y: -0.934, Z: 24.854 },
		{ X: -3.25, Y: -0.909, Z: 24.917 },
		{ X: 9.373, Y: -0.894, Z: 24.878 },
		{ X: 25.125, Y: -0.864, Z: 24.843 },
		{ X: 41.122, Y: -0.829, Z: 24.709 },
		{ X: 52.943, Y: -0.805, Z: 24.856 },
		{ X: 65.39, Y: -0.784, Z: 24.725 },
		{ X: 81.256, Y: -0.708, Z: 24.803 },
		{ X: 97.132, Y: -0.711, Z: 24.806 },
		{ X: 109.277, Y: -0.684, Z: 24.912 },
		{ X: 121.403, Y: -0.651, Z: 24.843 },
		{ X: 137.079, Y: -0.627, Z: 24.801 },
		{ X: 153.267, Y: -0.608, Z: 24.827 },
		{ X: -103.218, Y: -1.122, Z: 24.793 },
		{ X: -86.451, Y: -1.104, Z: 24.79 },
		{ X: -70.623, Y: -1.087, Z: 24.733 },
		{ X: -58.673, Y: -1.019, Z: 24.841 },
		{ X: -46.783, Y: -0.985, Z: 24.729 },
		{ X: -30.716, Y: -0.969, Z: 24.843 },
		{ X: -14.788, Y: -0.89, Z: 24.856 },
		{ X: -3.218, Y: -0.902, Z: 24.931 },
		{ X: 9.38, Y: -0.835, Z: 24.877 },
		{ X: 25.099, Y: -0.873, Z: 24.827 },
		{ X: 41.153, Y: -0.814, Z: 24.699 },
		{ X: 52.951, Y: -0.785, Z: 24.836 },
		{ X: 65.353, Y: -0.785, Z: 24.713 },
		{ X: 81.236, Y: -0.696, Z: 24.786 },
		{ X: 97.142, Y: -0.679, Z: 24.803 },
		{ X: 109.269, Y: -0.709, Z: 24.915 },
		{ X: 121.333, Y: -0.678, Z: 24.856 },
		{ X: 137.079, Y: -0.638, Z: 24.801 },
		{ X: 153.302, Y: -0.603, Z: 24.816 }
	],
	// Sepang
	6579: [
		{ X: 76.384, Y: -1.109, Z: -145.426 },
		{ X: 68.668, Y: -1.157, Z: -146.149 },
		{ X: 61.328, Y: -1.142, Z: -146.992 },
		{ X: 53.754, Y: -1.163, Z: -147.858 },
		{ X: 46.076, Y: -1.105, Z: -148.509 },
		{ X: 36.357, Y: -1.128, Z: -149.49 },
		{ X: 27.339, Y: -1.136, Z: -150.425 },
		{ X: 19.949, Y: -1.113, Z: -151.256 },
		{ X: 12.658, Y: -1.086, Z: -151.991 },
		{ X: 4.919, Y: -1.088, Z: -152.797 },
		{ X: -2.711, Y: -1.073, Z: -153.571 },
		{ X: -13.013, Y: -1.023, Z: -154.587 },
		{ X: -21.679, Y: -1.05, Z: -155.532 },
		{ X: -29.267, Y: -0.996, Z: -156.347 },
		{ X: -36.769, Y: -0.983, Z: -157.131 },
		{ X: -44.789, Y: -0.997, Z: -157.988 },
		{ X: -51.763, Y: -1.013, Z: -158.736 },
		{ X: 76.322, Y: -1.176, Z: -145.397 },
		{ X: 68.628, Y: -1.171, Z: -146.166 },
		{ X: 61.358, Y: -1.148, Z: -147 },
		{ X: 53.793, Y: -1.114, Z: -147.865 },
		{ X: 46.125, Y: -1.129, Z: -148.475 },
		{ X: 36.302, Y: -1.148, Z: -149.501 },
		{ X: 27.358, Y: -1.091, Z: -150.419 },
		{ X: 19.969, Y: -1.122, Z: -151.276 },
		{ X: 12.702, Y: -1.101, Z: -151.976 },
		{ X: 4.916, Y: -1.095, Z: -152.797 },
		{ X: -2.785, Y: -1.111, Z: -153.565 },
		{ X: -13.012, Y: -1.023, Z: -154.588 },
		{ X: -21.704, Y: -1.04, Z: -155.527 },
		{ X: -29.266, Y: -1.061, Z: -156.346 },
		{ X: -36.702, Y: -1.014, Z: -157.144 },
		{ X: -44.804, Y: -0.997, Z: -157.998 },
		{ X: -51.819, Y: -0.973, Z: -158.732 }
	],
	// Dubai Autodrome
	6587: [
		{ X: 285.206, Y: -0.706, Z: -256.311 },
		{ X: 295.214, Y: -0.761, Z: -256.86 },
		{ X: 305.128, Y: -0.776, Z: -257.423 },
		{ X: 315.171, Y: -0.798, Z: -257.971 },
		{ X: 325.115, Y: -0.754, Z: -258.513 },
		{ X: 335.022, Y: -0.792, Z: -259.059 },
		{ X: 345.019, Y: -0.784, Z: -259.571 },
		{ X: 354.884, Y: -0.787, Z: -260.168 },
		{ X: 364.878, Y: -0.731, Z: -260.707 },
		{ X: 374.872, Y: -0.749, Z: -261.254 },
		{ X: 384.814, Y: -0.74, Z: -261.8 },
		{ X: 394.674, Y: -0.71, Z: -262.357 },
		{ X: 404.71, Y: -0.723, Z: -262.942 },
		{ X: 414.658, Y: -0.761, Z: -263.478 },
		{ X: 424.604, Y: -0.748, Z: -264.017 },
		{ X: 434.57, Y: -0.721, Z: -264.59 },
		{ X: 443.433, Y: -0.74, Z: -265.069 },
		{ X: 453.349, Y: -0.695, Z: -265.608 },
		{ X: 464.389, Y: -0.734, Z: -266.197 },
		{ X: 473, Y: -0.695, Z: -266.705 },
		{ X: 483.009, Y: -0.693, Z: -267.232 },
		{ X: 492.911, Y: -0.731, Z: -267.777 },
		{ X: 503.183, Y: -0.673, Z: -268.338 },
		{ X: 513.136, Y: -0.706, Z: -268.895 },
		{ X: 285.263, Y: -0.772, Z: -256.274 },
		{ X: 295.215, Y: -0.774, Z: -256.898 },
		{ X: 305.148, Y: -0.749, Z: -257.413 },
		{ X: 315.149, Y: -0.752, Z: -257.978 },
		{ X: 325.057, Y: -0.794, Z: -258.526 },
		{ X: 335.062, Y: -0.755, Z: -259.068 },
		{ X: 344.962, Y: -0.76, Z: -259.597 },
		{ X: 354.931, Y: -0.77, Z: -260.158 },
		{ X: 364.867, Y: -0.737, Z: -260.719 },
		{ X: 374.808, Y: -0.768, Z: -261.26 },
		{ X: 384.734, Y: -0.755, Z: -261.78 },
		{ X: 394.735, Y: -0.743, Z: -262.363 },
		{ X: 404.682, Y: -0.716, Z: -262.927 },
		{ X: 285.25, Y: -0.806, Z: -256.321 },
		{ X: 295.214, Y: -0.762, Z: -256.854 },
		{ X: 305.174, Y: -0.78, Z: -257.437 },
		{ X: 315.159, Y: -0.759, Z: -257.985 },
		{ X: 325.113, Y: -0.754, Z: -258.512 },
		{ X: 335.094, Y: -0.78, Z: -259.062 },
		{ X: 345.005, Y: -0.743, Z: -259.602 },
		{ X: 354.918, Y: -0.761, Z: -260.178 },
		{ X: 364.892, Y: -0.759, Z: -260.712 },
		{ X: 374.854, Y: -0.744, Z: -261.248 },
		{ X: 384.775, Y: -0.738, Z: -261.787 },
		{ X: 394.677, Y: -0.764, Z: -262.36 },
		{ X: 404.658, Y: -0.765, Z: -262.926 },
		{ X: 414.61, Y: -0.708, Z: -263.459 },
		{ X: 424.571, Y: -0.752, Z: -264 },
		{ X: 434.527, Y: -0.707, Z: -264.557 },
		{ X: 443.381, Y: -0.703, Z: -265.054 },
		{ X: 453.392, Y: -0.748, Z: -265.596 },
		{ X: 464.396, Y: -0.692, Z: -266.199 },
		{ X: 472.995, Y: -0.679, Z: -266.708 },
		{ X: 483.025, Y: -0.727, Z: -267.237 },
		{ X: 492.944, Y: -0.696, Z: -267.769 },
		{ X: 503.104, Y: -0.683, Z: -268.334 },
		{ X: 513.068, Y: -0.674, Z: -268.899 },
		{ X: 285.244, Y: -0.779, Z: -256.263 },
		{ X: 295.247, Y: -0.8, Z: -256.87 },
		{ X: 305.199, Y: -0.777, Z: -257.39 },
		{ X: 315.151, Y: -0.798, Z: -257.978 },
		{ X: 325.065, Y: -0.774, Z: -258.508 },
		{ X: 335.069, Y: -0.774, Z: -259.071 },
		{ X: 344.939, Y: -0.759, Z: -259.59 },
		{ X: 354.915, Y: -0.767, Z: -260.156 },
		{ X: 364.913, Y: -0.745, Z: -260.715 },
		{ X: 374.813, Y: -0.726, Z: -261.262 },
		{ X: 384.741, Y: -0.765, Z: -261.777 },
		{ X: 394.726, Y: -0.763, Z: -262.349 },
		{ X: 404.705, Y: -0.732, Z: -262.935 },
		{ X: 285.29, Y: -0.782, Z: -256.33 },
		{ X: 295.183, Y: -0.782, Z: -256.849 },
		{ X: 305.139, Y: -0.802, Z: -257.424 },
		{ X: 315.182, Y: -0.754, Z: -257.974 },
		{ X: 325.094, Y: -0.781, Z: -258.526 },
		{ X: 335.051, Y: -0.741, Z: -259.05 },
		{ X: 344.99, Y: -0.769, Z: -259.596 },
		{ X: 354.907, Y: -0.771, Z: -260.178 },
		{ X: 364.883, Y: -0.769, Z: -260.708 },
		{ X: 374.862, Y: -0.78, Z: -261.251 },
		{ X: 384.763, Y: -0.735, Z: -261.787 },
		{ X: 394.697, Y: -0.739, Z: -262.363 },
		{ X: 404.679, Y: -0.737, Z: -262.933 },
		{ X: 414.662, Y: -0.723, Z: -263.459 },
		{ X: 424.601, Y: -0.745, Z: -263.999 },
		{ X: 434.573, Y: -0.697, Z: -264.59 }
	],
	// Dubai Autodrome
	7976: [
		{ X: 285.19, Y: -0.706, Z: -256.29 },
		{ X: 295.258, Y: -0.798, Z: -256.843 },
		{ X: 305.168, Y: -0.778, Z: -257.433 },
		{ X: 315.149, Y: -0.792, Z: -257.979 },
		{ X: 325.115, Y: -0.777, Z: -258.532 },
		{ X: 335.039, Y: -0.747, Z: -259.065 },
		{ X: 345.007, Y: -0.778, Z: -259.605 },
		{ X: 354.91, Y: -0.747, Z: -260.179 },
		{ X: 364.895, Y: -0.726, Z: -260.713 },
		{ X: 374.878, Y: -0.764, Z: -261.26 },
		{ X: 384.762, Y: -0.741, Z: -261.786 },
		{ X: 394.743, Y: -0.722, Z: -262.383 },
		{ X: 404.677, Y: -0.718, Z: -262.933 },
		{ X: 414.653, Y: -0.738, Z: -263.472 },
		{ X: 424.619, Y: -0.714, Z: -264.014 },
		{ X: 434.577, Y: -0.713, Z: -264.592 },
		{ X: 443.444, Y: -0.707, Z: -265.068 },
		{ X: 453.386, Y: -0.72, Z: -265.612 },
		{ X: 285.303, Y: -0.791, Z: -256.302 },
		{ X: 295.189, Y: -0.81, Z: -256.837 },
		{ X: 305.155, Y: -0.786, Z: -257.427 },
		{ X: 315.198, Y: -0.784, Z: -257.973 },
		{ X: 325.082, Y: -0.746, Z: -258.524 },
		{ X: 335.025, Y: -0.788, Z: -259.065 },
		{ X: 344.97, Y: -0.764, Z: -259.596 },
		{ X: 354.913, Y: -0.763, Z: -260.181 },
		{ X: 364.889, Y: -0.737, Z: -260.712 },
		{ X: 374.9, Y: -0.765, Z: -261.267 },
		{ X: 384.858, Y: -0.717, Z: -261.812 },
		{ X: 394.758, Y: -0.743, Z: -262.387 },
		{ X: 404.714, Y: -0.76, Z: -262.937 },
		{ X: 414.673, Y: -0.726, Z: -263.477 },
		{ X: 424.598, Y: -0.703, Z: -264.011 },
		{ X: 434.568, Y: -0.742, Z: -264.584 },
		{ X: 443.4, Y: -0.72, Z: -265.053 },
		{ X: 453.342, Y: -0.684, Z: -265.602 }
	],
	// Dubai Autodrome
	7977: [
		{ X: 285.228, Y: -0.706, Z: -256.302 },
		{ X: 295.251, Y: -0.756, Z: -256.841 },
		{ X: 305.154, Y: -0.766, Z: -257.429 },
		{ X: 315.11, Y: -0.776, Z: -257.972 },
		{ X: 325.096, Y: -0.765, Z: -258.526 },
		{ X: 335.056, Y: -0.768, Z: -259.072 },
		{ X: 344.951, Y: -0.735, Z: -259.588 },
		{ X: 354.893, Y: -0.741, Z: -260.174 },
		{ X: 364.883, Y: -0.74, Z: -260.712 },
		{ X: 374.847, Y: -0.776, Z: -261.249 },
		{ X: 384.806, Y: -0.716, Z: -261.807 },
		{ X: 394.709, Y: -0.734, Z: -262.376 },
		{ X: 404.693, Y: -0.724, Z: -262.941 },
		{ X: 414.666, Y: -0.726, Z: -263.442 },
		{ X: 424.59, Y: -0.726, Z: -264.011 },
		{ X: 434.583, Y: -0.703, Z: -264.592 },
		{ X: 443.438, Y: -0.701, Z: -265.049 },
		{ X: 453.365, Y: -0.727, Z: -265.608 },
		{ X: 285.277, Y: -0.809, Z: -256.314 },
		{ X: 285.275, Y: -0.763, Z: -256.313 },
		{ X: 285.266, Y: -0.757, Z: -256.315 },
		{ X: 285.318, Y: -0.807, Z: -256.305 },
		{ X: 285.291, Y: -0.767, Z: -256.319 },
		{ X: 285.26, Y: -0.768, Z: -256.312 },
		{ X: 285.263, Y: -0.807, Z: -256.309 },
		{ X: 285.295, Y: -0.762, Z: -256.321 },
		{ X: 285.26, Y: -0.806, Z: -256.308 },
		{ X: 285.26, Y: -0.755, Z: -256.312 },
		{ X: 285.26, Y: -0.802, Z: -256.308 },
		{ X: 285.247, Y: -0.792, Z: -256.304 },
		{ X: 285.288, Y: -0.78, Z: -256.318 },
		{ X: 285.283, Y: -0.763, Z: -256.316 },
		{ X: 285.296, Y: -0.772, Z: -256.32 },
		{ X: 285.29, Y: -0.781, Z: -256.318 },
		{ X: 285.25, Y: -0.803, Z: -256.309 },
		{ X: 285.276, Y: -0.791, Z: -256.318 }
	],
	// Dubai Autodrome
	7978: [
		{ X: 285.204, Y: -0.706, Z: -256.281 },
		{ X: 295.258, Y: -0.772, Z: -256.849 },
		{ X: 305.183, Y: -0.788, Z: -257.446 },
		{ X: 315.17, Y: -0.797, Z: -257.971 },
		{ X: 325.049, Y: -0.767, Z: -258.514 },
		{ X: 335.02, Y: -0.791, Z: -259.061 },
		{ X: 344.981, Y: -0.783, Z: -259.594 },
		{ X: 354.95, Y: -0.734, Z: -260.172 },
		{ X: 364.854, Y: -0.747, Z: -260.701 },
		{ X: 374.892, Y: -0.766, Z: -261.245 },
		{ X: 384.826, Y: -0.743, Z: -261.788 },
		{ X: 394.717, Y: -0.769, Z: -262.358 },
		{ X: 404.714, Y: -0.747, Z: -262.913 },
		{ X: 414.631, Y: -0.733, Z: -263.459 },
		{ X: 424.645, Y: -0.702, Z: -263.999 },
		{ X: 434.536, Y: -0.716, Z: -264.555 },
		{ X: 443.448, Y: -0.714, Z: -265.045 },
		{ X: 453.344, Y: -0.687, Z: -265.597 },
		{ X: 285.219, Y: -0.794, Z: -256.302 },
		{ X: 285.229, Y: -0.751, Z: -256.305 },
		{ X: 285.246, Y: -0.772, Z: -256.311 },
		{ X: 285.244, Y: -0.785, Z: -256.306 },
		{ X: 285.234, Y: -0.761, Z: -256.302 },
		{ X: 285.302, Y: -0.766, Z: -256.309 },
		{ X: 285.273, Y: -0.764, Z: -256.317 },
		{ X: 285.289, Y: -0.776, Z: -256.306 },
		{ X: 285.264, Y: -0.8, Z: -256.318 },
		{ X: 285.254, Y: -0.789, Z: -256.31 },
		{ X: 285.219, Y: -0.761, Z: -256.301 },
		{ X: 285.242, Y: -0.768, Z: -256.306 },
		{ X: 285.225, Y: -0.802, Z: -256.303 },
		{ X: 285.239, Y: -0.761, Z: -256.304 },
		{ X: 285.255, Y: -0.801, Z: -256.295 },
		{ X: 285.322, Y: -0.791, Z: -256.311 },
		{ X: 285.251, Y: -0.791, Z: -256.309 },
		{ X: 285.297, Y: -0.758, Z: -256.309 }
	],
	// Twin Ring Motegi
	6658: [
		{ X: -412.975, Y: -24.743, Z: -123.929 },
		{ X: -408.241, Y: -24.773, Z: -132.356 },
		{ X: -403.559, Y: -24.804, Z: -140.916 },
		{ X: -398.575, Y: -24.724, Z: -149.878 },
		{ X: -393.872, Y: -24.754, Z: -158.314 },
		{ X: -389.21, Y: -24.715, Z: -166.735 },
		{ X: -384.371, Y: -24.702, Z: -175.825 },
		{ X: -374.682, Y: -24.657, Z: -193.208 },
		{ X: -369.947, Y: -24.604, Z: -201.799 },
		{ X: -365.279, Y: -24.604, Z: -210.208 },
		{ X: -360.449, Y: -24.553, Z: -219.175 },
		{ X: -355.862, Y: -24.563, Z: -227.659 },
		{ X: -351.059, Y: -24.523, Z: -236.244 },
		{ X: -346.22, Y: -24.555, Z: -245.331 },
		{ X: -336.655, Y: -24.529, Z: -262.674 },
		{ X: -331.995, Y: -24.495, Z: -271.219 },
		{ X: -327.249, Y: -24.483, Z: -279.777 },
		{ X: -322.363, Y: -24.415, Z: -288.656 },
		{ X: -317.644, Y: -24.433, Z: -297.211 },
		{ X: -312.966, Y: -24.407, Z: -305.691 },
		{ X: -308.226, Y: -24.396, Z: -314.644 },
		{ X: -413.026, Y: -24.842, Z: -123.96 },
		{ X: -408.245, Y: -24.765, Z: -132.413 },
		{ X: -403.57, Y: -24.756, Z: -140.893 },
		{ X: -398.562, Y: -24.72, Z: -149.906 },
		{ X: -393.893, Y: -24.7, Z: -158.304 },
		{ X: -389.203, Y: -24.709, Z: -166.685 },
		{ X: -384.382, Y: -24.652, Z: -175.838 },
		{ X: -374.682, Y: -24.619, Z: -193.212 },
		{ X: -369.971, Y: -24.622, Z: -201.727 },
		{ X: -365.276, Y: -24.589, Z: -210.221 },
		{ X: -360.451, Y: -24.542, Z: -219.169 },
		{ X: -355.826, Y: -24.544, Z: -227.72 },
		{ X: -351.062, Y: -24.556, Z: -236.296 },
		{ X: -346.246, Y: -24.55, Z: -245.326 },
		{ X: -336.638, Y: -24.515, Z: -262.678 },
		{ X: -331.937, Y: -24.47, Z: -271.295 },
		{ X: -327.236, Y: -24.452, Z: -279.84 },
		{ X: -322.392, Y: -24.413, Z: -288.629 },
		{ X: -317.637, Y: -24.429, Z: -297.241 },
		{ X: -312.958, Y: -24.382, Z: -305.734 },
		{ X: -308.224, Y: -24.4, Z: -314.654 }
	],
	// Twin Ring Motegi
	7026: [
		{ X: -412.975, Y: -24.743, Z: -123.929 },
		{ X: -408.241, Y: -24.773, Z: -132.356 },
		{ X: -403.559, Y: -24.804, Z: -140.916 },
		{ X: -398.575, Y: -24.724, Z: -149.878 },
		{ X: -393.872, Y: -24.754, Z: -158.314 },
		{ X: -389.21, Y: -24.715, Z: -166.735 },
		{ X: -384.371, Y: -24.702, Z: -175.825 },
		{ X: -374.682, Y: -24.657, Z: -193.208 },
		{ X: -369.947, Y: -24.604, Z: -201.799 },
		{ X: -365.279, Y: -24.604, Z: -210.208 },
		{ X: -360.449, Y: -24.553, Z: -219.175 },
		{ X: -355.862, Y: -24.563, Z: -227.659 },
		{ X: -351.059, Y: -24.523, Z: -236.244 },
		{ X: -346.22, Y: -24.555, Z: -245.331 },
		{ X: -336.655, Y: -24.529, Z: -262.674 },
		{ X: -331.995, Y: -24.495, Z: -271.219 },
		{ X: -327.249, Y: -24.483, Z: -279.777 },
		{ X: -322.363, Y: -24.415, Z: -288.656 },
		{ X: -317.644, Y: -24.433, Z: -297.211 },
		{ X: -312.966, Y: -24.407, Z: -305.691 },
		{ X: -308.226, Y: -24.396, Z: -314.644 },
		{ X: -413.026, Y: -24.842, Z: -123.96 },
		{ X: -408.245, Y: -24.765, Z: -132.413 },
		{ X: -403.57, Y: -24.756, Z: -140.893 },
		{ X: -398.562, Y: -24.72, Z: -149.906 },
		{ X: -393.893, Y: -24.7, Z: -158.304 },
		{ X: -389.203, Y: -24.709, Z: -166.685 },
		{ X: -384.382, Y: -24.652, Z: -175.838 },
		{ X: -374.682, Y: -24.619, Z: -193.212 },
		{ X: -369.971, Y: -24.622, Z: -201.727 },
		{ X: -365.276, Y: -24.589, Z: -210.221 },
		{ X: -360.451, Y: -24.542, Z: -219.169 },
		{ X: -355.826, Y: -24.544, Z: -227.72 },
		{ X: -351.062, Y: -24.556, Z: -236.296 },
		{ X: -346.246, Y: -24.55, Z: -245.326 },
		{ X: -336.638, Y: -24.515, Z: -262.678 },
		{ X: -331.937, Y: -24.47, Z: -271.295 },
		{ X: -327.236, Y: -24.452, Z: -279.84 },
		{ X: -322.392, Y: -24.413, Z: -288.629 },
		{ X: -317.637, Y: -24.429, Z: -297.241 },
		{ X: -312.958, Y: -24.382, Z: -305.734 },
		{ X: -308.224, Y: -24.4, Z: -314.654 }
	],
	// Twin Ring Motegi
	7027: [
		{ X: 430.103, Y: -4.494, Z: -224.196 },
		{ X: 437.253, Y: -4.54, Z: -217.664 },
		{ X: 444.648, Y: -4.554, Z: -210.867 },
		{ X: 451.799, Y: -4.563, Z: -204.297 },
		{ X: 459.155, Y: -4.541, Z: -197.478 },
		{ X: 466.535, Y: -4.52, Z: -190.889 },
		{ X: 473.791, Y: -4.529, Z: -184.293 },
		{ X: 481.039, Y: -4.507, Z: -177.529 },
		{ X: 488.322, Y: -4.49, Z: -170.944 },
		{ X: 495.732, Y: -4.489, Z: -164.192 },
		{ X: 502.801, Y: -4.471, Z: -157.561 },
		{ X: 510.199, Y: -4.411, Z: -150.826 },
		{ X: 517.441, Y: -4.451, Z: -144.14 },
		{ X: 524.831, Y: -4.416, Z: -137.409 },
		{ X: 532.137, Y: -4.414, Z: -130.821 },
		{ X: 539.518, Y: -4.374, Z: -124.082 },
		{ X: 547.645, Y: -4.387, Z: -116.763 },
		{ X: 430.159, Y: -4.603, Z: -224.234 },
		{ X: 437.291, Y: -4.559, Z: -217.622 },
		{ X: 444.67, Y: -4.55, Z: -210.855 },
		{ X: 451.834, Y: -4.494, Z: -204.279 },
		{ X: 459.162, Y: -4.555, Z: -197.473 },
		{ X: 466.489, Y: -4.522, Z: -190.933 },
		{ X: 473.82, Y: -4.49, Z: -184.261 },
		{ X: 481.016, Y: -4.49, Z: -177.54 },
		{ X: 488.305, Y: -4.462, Z: -170.951 },
		{ X: 495.71, Y: -4.432, Z: -164.228 },
		{ X: 502.811, Y: -4.492, Z: -157.535 },
		{ X: 510.221, Y: -4.446, Z: -150.79 },
		{ X: 517.472, Y: -4.464, Z: -144.119 },
		{ X: 524.826, Y: -4.457, Z: -137.413 },
		{ X: 532.139, Y: -4.394, Z: -130.8 }
	],
	// Autodrom Most
	7112: [
		{ X: -219.433, Y: 0.157, Z: 55.869 },
		{ X: -209.662, Y: -0.022, Z: 54.159 },
		{ X: -200.186, Y: -0.1, Z: 52.503 },
		{ X: -190.535, Y: -0.203, Z: 50.817 },
		{ X: -175.466, Y: -0.33, Z: 48.182 },
		{ X: -165.985, Y: -0.462, Z: 46.506 },
		{ X: -156.256, Y: -0.592, Z: 44.829 },
		{ X: -146.735, Y: -0.679, Z: 43.124 },
		{ X: -136.946, Y: -0.796, Z: 41.408 },
		{ X: -127.33, Y: -0.786, Z: 39.735 },
		{ X: -117.142, Y: -0.829, Z: 37.952 },
		{ X: -107.571, Y: -0.834, Z: 36.283 },
		{ X: -97.955, Y: -0.784, Z: 34.598 },
		{ X: -88.2, Y: -0.753, Z: 32.888 },
		{ X: -78.609, Y: -0.65, Z: 31.195 },
		{ X: -69.025, Y: -0.578, Z: 29.566 },
		{ X: -58.816, Y: -0.505, Z: 27.753 },
		{ X: -49.268, Y: -0.405, Z: 26.088 },
		{ X: -39.525, Y: -0.324, Z: 24.38 },
		{ X: -29.901, Y: -0.251, Z: 22.688 },
		{ X: -183.425, Y: -0.264, Z: 49.542 },
		{ X: -219.336, Y: 0.106, Z: 55.841 },
		{ X: -209.708, Y: -0.016, Z: 54.16 },
		{ X: -200.182, Y: -0.061, Z: 52.502 },
		{ X: -190.582, Y: -0.187, Z: 50.815 },
		{ X: -175.378, Y: -0.344, Z: 48.163 },
		{ X: -165.933, Y: -0.487, Z: 46.505 },
		{ X: -156.252, Y: -0.599, Z: 44.825 },
		{ X: -146.683, Y: -0.672, Z: 43.118 },
		{ X: -136.883, Y: -0.782, Z: 41.402 },
		{ X: -127.351, Y: -0.836, Z: 39.743 },
		{ X: -117.149, Y: -0.87, Z: 37.955  }
	],
	// Ningbo International Speedpark
	7273: [
		{ X: -192.726, Y: -60.162, Z: -142.017 },
		{ X: -191.104, Y: -60.205, Z: -127.919 },
		{ X: -189.559, Y: -60.217, Z: -113.837 },
		{ X: -187.153, Y: -60.264, Z: -92.174 },
		{ X: -185.548, Y: -60.245, Z: -77.825 },
		{ X: -183.929, Y: -60.264, Z: -63.804 },
		{ X: -181.507, Y: -60.248, Z: -42.299 },
		{ X: -179.833, Y: -60.216, Z: -27.602 },
		{ X: -178.213, Y: -60.246, Z: -13.379 },
		{ X: -175.809, Y: -60.19, Z: 7.738 },
		{ X: -174.177, Y: -60.19, Z: 22.704 },
		{ X: -172.568, Y: -60.227, Z: 36.671 },
		{ X: -170.179, Y: -60.253, Z: 57.937 },
		{ X: -168.599, Y: -60.24, Z: 72.206 },
		{ X: -166.935, Y: -60.206, Z: 86.819 },
		{ X: -192.764, Y: -60.243, Z: -141.927 },
		{ X: -191.082, Y: -60.249, Z: -127.903 },
		{ X: -189.565, Y: -60.252, Z: -113.793 },
		{ X: -187.159, Y: -60.211, Z: -92.114 },
		{ X: -185.527, Y: -60.219, Z: -77.849 },
		{ X: -183.935, Y: -60.213, Z: -63.787 },
		{ X: -181.511, Y: -60.241, Z: -42.33 },
		{ X: -179.814, Y: -60.213, Z: -27.573 },
		{ X: -178.222, Y: -60.252, Z: -13.329 },
		{ X: -175.796, Y: -60.252, Z: 7.803 },
		{ X: -174.178, Y: -60.257, Z: 22.697 },
		{ X: -172.568, Y: -60.258, Z: 36.663 },
		{ X: -170.175, Y: -60.248, Z: 57.929 },
		{ X: -168.598, Y: -60.192, Z: 72.195 },
		{ X: -166.939, Y: -60.204, Z: 86.825 },
		{ X: -192.727, Y: -60.245, Z: -141.908 },
		{ X: -191.098, Y: -60.22, Z: -127.942 }
	],
	// Ningbo International Speedpark
	8309: [
		{ X: -192.726, Y: -60.162, Z: -142.017 },
		{ X: -191.104, Y: -60.205, Z: -127.919 },
		{ X: -189.559, Y: -60.217, Z: -113.837 },
		{ X: -187.153, Y: -60.264, Z: -92.174 },
		{ X: -185.548, Y: -60.245, Z: -77.825 },
		{ X: -183.929, Y: -60.264, Z: -63.804 },
		{ X: -181.507, Y: -60.248, Z: -42.299 },
		{ X: -179.833, Y: -60.216, Z: -27.602 },
		{ X: -178.213, Y: -60.246, Z: -13.379 },
		{ X: -175.809, Y: -60.19, Z: 7.738 },
		{ X: -174.177, Y: -60.19, Z: 22.704 },
		{ X: -172.568, Y: -60.227, Z: 36.671 },
		{ X: -170.179, Y: -60.253, Z: 57.937 },
		{ X: -168.599, Y: -60.24, Z: 72.206 },
		{ X: -166.935, Y: -60.206, Z: 86.819 },
		{ X: -192.764, Y: -60.243, Z: -141.927 },
		{ X: -191.082, Y: -60.249, Z: -127.903 },
		{ X: -189.565, Y: -60.252, Z: -113.793 },
		{ X: -187.159, Y: -60.211, Z: -92.114 },
		{ X: -185.527, Y: -60.219, Z: -77.849 },
		{ X: -183.935, Y: -60.213, Z: -63.787 },
		{ X: -181.511, Y: -60.241, Z: -42.33 },
		{ X: -179.814, Y: -60.213, Z: -27.573 },
		{ X: -178.222, Y: -60.252, Z: -13.329 },
		{ X: -175.796, Y: -60.252, Z: 7.803 },
		{ X: -174.178, Y: -60.257, Z: 22.697 },
		{ X: -172.568, Y: -60.258, Z: 36.663 },
		{ X: -170.175, Y: -60.248, Z: 57.929 },
		{ X: -168.598, Y: -60.192, Z: 72.195 },
		{ X: -166.939, Y: -60.204, Z: 86.825 },
		{ X: -192.727, Y: -60.245, Z: -141.908 },
		{ X: -191.098, Y: -60.22, Z: -127.942 }
	],
	// Ningbo International Speedpark
	8310: [
		{ X: -192.726, Y: -60.162, Z: -142.017 },
		{ X: -191.104, Y: -60.205, Z: -127.919 },
		{ X: -189.559, Y: -60.217, Z: -113.837 },
		{ X: -187.153, Y: -60.264, Z: -92.174 },
		{ X: -185.548, Y: -60.245, Z: -77.825 },
		{ X: -183.929, Y: -60.264, Z: -63.804 },
		{ X: -181.507, Y: -60.248, Z: -42.299 },
		{ X: -179.833, Y: -60.216, Z: -27.602 },
		{ X: -178.213, Y: -60.246, Z: -13.379 },
		{ X: -175.809, Y: -60.19, Z: 7.738 },
		{ X: -174.177, Y: -60.19, Z: 22.704 },
		{ X: -172.568, Y: -60.227, Z: 36.671 },
		{ X: -170.179, Y: -60.253, Z: 57.937 },
		{ X: -168.599, Y: -60.24, Z: 72.206 },
		{ X: -166.935, Y: -60.206, Z: 86.819 },
		{ X: -192.764, Y: -60.243, Z: -141.927 },
		{ X: -191.082, Y: -60.249, Z: -127.903 },
		{ X: -189.565, Y: -60.252, Z: -113.793 },
		{ X: -187.159, Y: -60.211, Z: -92.114 },
		{ X: -185.527, Y: -60.219, Z: -77.849 },
		{ X: -183.935, Y: -60.213, Z: -63.787 },
		{ X: -181.511, Y: -60.241, Z: -42.33 },
		{ X: -179.814, Y: -60.213, Z: -27.573 },
		{ X: -178.222, Y: -60.252, Z: -13.329 },
		{ X: -175.796, Y: -60.252, Z: 7.803 },
		{ X: -174.178, Y: -60.257, Z: 22.697 },
		{ X: -172.568, Y: -60.258, Z: 36.663 },
		{ X: -170.175, Y: -60.248, Z: 57.929 },
		{ X: -168.598, Y: -60.192, Z: 72.195 },
		{ X: -166.939, Y: -60.204, Z: 86.825 },
		{ X: -192.727, Y: -60.245, Z: -141.908 },
		{ X: -191.098, Y: -60.22, Z: -127.942 }
	],
	// Ningbo International Speedpark
	8311: [
		{ X: -192.726, Y: -60.162, Z: -142.017 },
		{ X: -191.104, Y: -60.205, Z: -127.919 },
		{ X: -189.559, Y: -60.217, Z: -113.837 },
		{ X: -187.153, Y: -60.264, Z: -92.174 },
		{ X: -185.548, Y: -60.245, Z: -77.825 },
		{ X: -183.929, Y: -60.264, Z: -63.804 },
		{ X: -181.507, Y: -60.248, Z: -42.299 },
		{ X: -179.833, Y: -60.216, Z: -27.602 },
		{ X: -178.213, Y: -60.246, Z: -13.379 },
		{ X: -175.809, Y: -60.19, Z: 7.738 },
		{ X: -174.177, Y: -60.19, Z: 22.704 },
		{ X: -172.568, Y: -60.227, Z: 36.671 },
		{ X: -170.179, Y: -60.253, Z: 57.937 },
		{ X: -168.599, Y: -60.24, Z: 72.206 },
		{ X: -166.935, Y: -60.206, Z: 86.819 },
		{ X: -192.764, Y: -60.243, Z: -141.927 },
		{ X: -191.082, Y: -60.249, Z: -127.903 },
		{ X: -189.565, Y: -60.252, Z: -113.793 },
		{ X: -187.159, Y: -60.211, Z: -92.114 },
		{ X: -185.527, Y: -60.219, Z: -77.849 },
		{ X: -183.935, Y: -60.213, Z: -63.787 },
		{ X: -181.511, Y: -60.241, Z: -42.33 },
		{ X: -179.814, Y: -60.213, Z: -27.573 },
		{ X: -178.222, Y: -60.252, Z: -13.329 },
		{ X: -175.796, Y: -60.252, Z: 7.803 },
		{ X: -174.178, Y: -60.257, Z: 22.697 },
		{ X: -172.568, Y: -60.258, Z: 36.663 },
		{ X: -170.175, Y: -60.248, Z: 57.929 },
		{ X: -168.598, Y: -60.192, Z: 72.195 },
		{ X: -166.939, Y: -60.204, Z: 86.825 },
		{ X: -192.727, Y: -60.245, Z: -141.908 },
		{ X: -191.098, Y: -60.22, Z: -127.942 }
	],
	// Ningbo International Speedpark
	8314: [
		{ X: -192.726, Y: -60.162, Z: -142.017 },
		{ X: -191.104, Y: -60.205, Z: -127.919 },
		{ X: -189.559, Y: -60.217, Z: -113.837 },
		{ X: -187.153, Y: -60.264, Z: -92.174 },
		{ X: -185.548, Y: -60.245, Z: -77.825 },
		{ X: -183.929, Y: -60.264, Z: -63.804 },
		{ X: -181.507, Y: -60.248, Z: -42.299 },
		{ X: -179.833, Y: -60.216, Z: -27.602 },
		{ X: -178.213, Y: -60.246, Z: -13.379 },
		{ X: -175.809, Y: -60.19, Z: 7.738 },
		{ X: -174.177, Y: -60.19, Z: 22.704 },
		{ X: -172.568, Y: -60.227, Z: 36.671 },
		{ X: -170.179, Y: -60.253, Z: 57.937 },
		{ X: -168.599, Y: -60.24, Z: 72.206 },
		{ X: -166.935, Y: -60.206, Z: 86.819 },
		{ X: -192.764, Y: -60.243, Z: -141.927 },
		{ X: -191.082, Y: -60.249, Z: -127.903 },
		{ X: -189.565, Y: -60.252, Z: -113.793 },
		{ X: -187.159, Y: -60.211, Z: -92.114 },
		{ X: -185.527, Y: -60.219, Z: -77.849 },
		{ X: -183.935, Y: -60.213, Z: -63.787 },
		{ X: -181.511, Y: -60.241, Z: -42.33 },
		{ X: -179.814, Y: -60.213, Z: -27.573 },
		{ X: -178.222, Y: -60.252, Z: -13.329 },
		{ X: -175.796, Y: -60.252, Z: 7.803 },
		{ X: -174.178, Y: -60.257, Z: 22.697 },
		{ X: -172.568, Y: -60.258, Z: 36.663 },
		{ X: -170.175, Y: -60.248, Z: 57.929 },
		{ X: -168.598, Y: -60.192, Z: 72.195 },
		{ X: -166.939, Y: -60.204, Z: 86.825 },
		{ X: -192.727, Y: -60.245, Z: -141.908 },
		{ X: -191.098, Y: -60.22, Z: -127.942 }
	],
	// Bilster Berg
	7819: [
		{ X: 503.755, Y: 37.413, Z: -47.601 },
		{ X: 489.896, Y: 37.293, Z: -41.815 },
		{ X: 474.2, Y: 37.246, Z: -34.799 },
		{ X: 434.883, Y: 35.287, Z: -8.615 },
		{ X: 422.579, Y: 35.223, Z: 1.845 },
		{ X: 411.529, Y: 35.218, Z: 12.191 },
		{ X: 380.465, Y: 33.309, Z: 48.323 },
		{ X: 371.431, Y: 33.321, Z: 61.166 },
		{ X: 361.874, Y: 33.323, Z: 75.358 },
		{ X: 340.268, Y: 32.975, Z: 107.909 },
		{ X: 334, Y: 32.969, Z: 117.296 },
		{ X: 327.808, Y: 32.948, Z: 126.424 },
		{ X: 321.457, Y: 32.906, Z: 136.136 },
		{ X: 315.747, Y: 32.918, Z: 144.771 },
		{ X: 310.24, Y: 32.851, Z: 153.047 },
		{ X: 292.443, Y: 31.293, Z: 179.871 },
		{ X: 287.892, Y: 31.026, Z: 186.566 },
		{ X: 283.403, Y: 30.845, Z: 193.294 },
		{ X: 278.088, Y: 30.76, Z: 201.16 },
		{ X: 272.895, Y: 30.654, Z: 208.996 },
		{ X: 503.753, Y: 37.326, Z: -47.615 },
		{ X: 489.903, Y: 37.239, Z: -41.814 },
		{ X: 474.224, Y: 37.219, Z: -34.809 },
		{ X: 434.892, Y: 35.287, Z: -8.617 },
		{ X: 422.571, Y: 35.281, Z: 1.866 },
		{ X: 411.49, Y: 35.243, Z: 12.236 },
		{ X: 380.502, Y: 33.348, Z: 48.269 },
		{ X: 371.401, Y: 33.274, Z: 61.188 },
		{ X: 361.892, Y: 33.264, Z: 75.341 },
		{ X: 340.302, Y: 32.955, Z: 107.854 },
		{ X: 334.018, Y: 32.907, Z: 117.281 },
		{ X: 327.783, Y: 32.947, Z: 126.471 },
		{ X: 321.442, Y: 32.922, Z: 136.171 },
		{ X: 315.748, Y: 32.903, Z: 144.776 },
		{ X: 310.197, Y: 32.859, Z: 153.081 },
		{ X: 292.471, Y: 31.28, Z: 179.84 },
		{ X: 287.855, Y: 31.031, Z: 186.6 },
		{ X: 283.415, Y: 30.883, Z: 193.283 },
		{ X: 278.121, Y: 30.74, Z: 201.133 },
		{ X: 272.915, Y: 30.681, Z: 208.982 }
	],
	// Bilster Berg
	8069: [
		{ X: 501.182, Y: 37.372, Z: -46.515 },
		{ X: 494.016, Y: 37.252, Z: -43.539 },
		{ X: 486.659, Y: 37.231, Z: -40.464 },
		{ X: 431.795, Y: 35.246, Z: -6.047 },
		{ X: 425.155, Y: 35.217, Z: -0.428 },
		{ X: 418.24, Y: 35.219, Z: 5.761 },
		{ X: 377.742, Y: 33.276, Z: 52.205 },
		{ X: 372.935, Y: 33.307, Z: 58.999 },
		{ X: 368.117, Y: 33.321, Z: 65.969 },
		{ X: 335.416, Y: 32.948, Z: 115.142 },
		{ X: 331.109, Y: 32.954, Z: 121.602 },
		{ X: 326.654, Y: 32.897, Z: 128.22 },
		{ X: 322.334, Y: 32.896, Z: 134.802 },
		{ X: 317.876, Y: 32.918, Z: 141.596 },
		{ X: 313.377, Y: 32.947, Z: 148.349 },
		{ X: 291.526, Y: 31.22, Z: 181.14 },
		{ X: 287.217, Y: 31.007, Z: 187.568 },
		{ X: 283.014, Y: 30.882, Z: 193.884 },
		{ X: 278.662, Y: 30.795, Z: 200.286 },
		{ X: 274.362, Y: 30.713, Z: 206.769 },
		{ X: 501.08, Y: 37.264, Z: -46.497 },
		{ X: 494.055, Y: 37.281, Z: -43.546 },
		{ X: 486.634, Y: 37.248, Z: -40.459 },
		{ X: 431.8, Y: 35.241, Z: -6.049 },
		{ X: 425.128, Y: 35.214, Z: -0.415 },
		{ X: 418.29, Y: 35.234, Z: 5.731 },
		{ X: 377.708, Y: 33.275, Z: 52.239 },
		{ X: 372.932, Y: 33.323, Z: 59.006 },
		{ X: 368.118, Y: 33.263, Z: 65.991 },
		{ X: 335.447, Y: 32.903, Z: 115.117 },
		{ X: 331.093, Y: 32.903, Z: 121.642 },
		{ X: 326.634, Y: 32.92, Z: 128.235 },
		{ X: 322.306, Y: 32.916, Z: 134.853 },
		{ X: 317.873, Y: 32.924, Z: 141.598 },
		{ X: 313.375, Y: 32.941, Z: 148.351 },
		{ X: 291.543, Y: 31.238, Z: 181.124 },
		{ X: 287.23, Y: 31.002, Z: 187.558 },
		{ X: 283.039, Y: 30.85, Z: 193.835 },
		{ X: 278.631, Y: 30.767, Z: 200.339 },
		{ X: 274.38, Y: 30.689, Z: 206.754 }
	],
	// Bilster Berg
	8070: [
		{ X: 378.413, Y: 35.753, Z: -4.915 },
		{ X: 373.345, Y: 35.64, Z: 0.763 },
		{ X: 368.699, Y: 35.507, Z: 6.279 },
		{ X: 364.119, Y: 35.472, Z: 11.825 },
		{ X: 333.782, Y: 35.732, Z: 54.362 },
		{ X: 329.367, Y: 35.794, Z: 60.881 },
		{ X: 324.979, Y: 35.821, Z: 67.518 },
		{ X: 320.725, Y: 35.823, Z: 73.896 },
		{ X: 292.179, Y: 34.277, Z: 116.978 },
		{ X: 287.852, Y: 34.203, Z: 123.444 },
		{ X: 283.764, Y: 34.069, Z: 129.635 },
		{ X: 279.609, Y: 34.039, Z: 135.956 },
		{ X: 251.276, Y: 31.701, Z: 178.718 },
		{ X: 248.19, Y: 31.653, Z: 183.412 },
		{ X: 244.958, Y: 31.685, Z: 188.242 },
		{ X: 241.418, Y: 31.734, Z: 191.823 },
		{ X: 378.377, Y: 35.721, Z: -4.836 },
		{ X: 373.351, Y: 35.64, Z: 0.754 },
		{ X: 368.666, Y: 35.511, Z: 6.298 },
		{ X: 364.118, Y: 35.494, Z: 11.824 },
		{ X: 333.802, Y: 35.708, Z: 54.307 },
		{ X: 329.366, Y: 35.731, Z: 60.88 },
		{ X: 324.995, Y: 35.792, Z: 67.482 },
		{ X: 320.724, Y: 35.811, Z: 73.9 },
		{ X: 292.188, Y: 34.289, Z: 116.959 },
		{ X: 287.852, Y: 34.176, Z: 123.442 },
		{ X: 283.774, Y: 34.13, Z: 129.607 },
		{ X: 279.597, Y: 34.016, Z: 135.993 },
		{ X: 251.281, Y: 31.708, Z: 178.684 },
		{ X: 248.184, Y: 31.662, Z: 183.425 },
		{ X: 244.962, Y: 31.648, Z: 188.233 },
		{ X: 241.402, Y: 31.687, Z: 191.723 }
	],
	// Bilster Berg
	8071: [
		{ X: 377.575, Y: 35.735, Z: -3.908 },
		{ X: 372.49, Y: 35.626, Z: 1.819 },
		{ X: 367.859, Y: 35.535, Z: 7.277 },
		{ X: 363.299, Y: 35.482, Z: 12.846 },
		{ X: 333.756, Y: 35.749, Z: 54.382 },
		{ X: 329.343, Y: 35.768, Z: 60.897 },
		{ X: 324.971, Y: 35.768, Z: 67.527 },
		{ X: 320.738, Y: 35.751, Z: 73.874 },
		{ X: 292.159, Y: 34.259, Z: 117.004 },
		{ X: 287.822, Y: 34.235, Z: 123.498 },
		{ X: 283.807, Y: 34.126, Z: 129.579 },
		{ X: 279.635, Y: 34.045, Z: 135.941 },
		{ X: 251.395, Y: 31.703, Z: 178.501 },
		{ X: 248.389, Y: 31.593, Z: 183.109 },
		{ X: 245.218, Y: 31.678, Z: 187.915 },
		{ X: 241.787, Y: 31.743, Z: 191.57 },
		{ X: 377.566, Y: 35.636, Z: -3.873 },
		{ X: 372.516, Y: 35.617, Z: 1.763 },
		{ X: 367.865, Y: 35.512, Z: 7.267 },
		{ X: 363.348, Y: 35.487, Z: 12.781 },
		{ X: 333.792, Y: 35.738, Z: 54.332 },
		{ X: 329.36, Y: 35.751, Z: 60.893 },
		{ X: 325.006, Y: 35.754, Z: 67.478 },
		{ X: 320.728, Y: 35.749, Z: 73.893 },
		{ X: 292.187, Y: 34.27, Z: 116.974 },
		{ X: 287.828, Y: 34.229, Z: 123.472 },
		{ X: 283.813, Y: 34.14, Z: 129.571 },
		{ X: 279.603, Y: 34.035, Z: 135.977 },
		{ X: 251.392, Y: 31.662, Z: 178.511 },
		{ X: 248.415, Y: 31.662, Z: 183.085 },
		{ X: 245.186, Y: 31.67, Z: 187.964 },
		{ X: 242.001, Y: 31.714, Z: 191.658 }
	],
	// Bilster Berg
	8095: [
		{ X: -252.242, Y: 82.62, Z: -457.38 },
		{ X: -255.272, Y: 82.557, Z: -452.821 },
		{ X: -258.093, Y: 82.667, Z: -448.457 },
		{ X: -260.737, Y: 83.068, Z: -443.999 },
		{ X: -263.323, Y: 83.461, Z: -439.632 },
		{ X: -266.048, Y: 83.879, Z: -435.2 },
		{ X: -268.762, Y: 84.337, Z: -430.747 },
		{ X: -271.528, Y: 84.621, Z: -426.267 },
		{ X: -274.326, Y: 84.762, Z: -421.697 },
		{ X: -277.072, Y: 84.77, Z: -417.117 },
		{ X: -279.739, Y: 84.812, Z: -412.651 },
		{ X: -282.542, Y: 84.805, Z: -408.206 },
		{ X: -252.265, Y: 82.512, Z: -457.345 },
		{ X: -255.249, Y: 82.576, Z: -452.848 },
		{ X: -258.08, Y: 82.664, Z: -448.465 },
		{ X: -260.719, Y: 82.997, Z: -444.052 },
		{ X: -263.361, Y: 83.482, Z: -439.566 },
		{ X: -266.05, Y: 83.853, Z: -435.191 },
		{ X: -268.804, Y: 84.307, Z: -430.678 },
		{ X: -271.54, Y: 84.641, Z: -426.233 },
		{ X: -274.352, Y: 84.78, Z: -421.653 },
		{ X: -277.066, Y: 84.774, Z: -417.139 },
		{ X: -279.773, Y: 84.797, Z: -412.64 },
		{ X: -282.508, Y: 84.804, Z: -408.259 }
	],
	// Zhejiang Circuit
	8075: [
		{ X: 406.847, Y: 0.284, Z: -251.213 },
		{ X: 399.733, Y: 0.196, Z: -261.402 },
		{ X: 390.146, Y: 0.192, Z: -275.031 },
		{ X: 383.026, Y: 0.205, Z: -285.224 },
		{ X: 369.678, Y: 0.223, Z: -304.32 },
		{ X: 362.845, Y: 0.277, Z: -314.283 },
		{ X: 349.678, Y: 0.276, Z: -333.268 },
		{ X: 342.619, Y: 0.234, Z: -343.409 },
		{ X: 337.282, Y: 0.234, Z: -351.107 },
		{ X: 329.419, Y: 0.273, Z: -362.325 },
		{ X: 322.527, Y: 0.239, Z: -372.211 },
		{ X: 309.168, Y: 0.254, Z: -391.326 },
		{ X: 302.083, Y: 0.268, Z: -401.446 },
		{ X: 293.759, Y: 0.251, Z: -414.32 },
		{ X: 287.438, Y: 0.243, Z: -429.379 },
		{ X: 282.895, Y: 0.263, Z: -441.044 },
		{ X: 406.824, Y: 0.185, Z: -251.245 },
		{ X: 399.754, Y: 0.217, Z: -261.383 },
		{ X: 390.112, Y: 0.193, Z: -275.087 },
		{ X: 383.056, Y: 0.195, Z: -285.176 },
		{ X: 369.699, Y: 0.254, Z: -304.305 },
		{ X: 362.835, Y: 0.23, Z: -314.291 },
		{ X: 349.653, Y: 0.262, Z: -333.312 },
		{ X: 342.613, Y: 0.277, Z: -343.441 },
		{ X: 337.269, Y: 0.228, Z: -351.144 },
		{ X: 329.417, Y: 0.228, Z: -362.328 },
		{ X: 322.516, Y: 0.276, Z: -372.245 },
		{ X: 309.141, Y: 0.255, Z: -391.347 },
		{ X: 302.06, Y: 0.282, Z: -401.488 },
		{ X: 293.72, Y: 0.239, Z: -414.369 }
	],
	// Zhejiang Circuit
	8327: [
		{ X: 406.847, Y: 0.284, Z: -251.213 },
		{ X: 399.733, Y: 0.196, Z: -261.402 },
		{ X: 390.146, Y: 0.192, Z: -275.031 },
		{ X: 383.026, Y: 0.205, Z: -285.224 },
		{ X: 369.678, Y: 0.223, Z: -304.32 },
		{ X: 362.845, Y: 0.277, Z: -314.283 },
		{ X: 349.678, Y: 0.276, Z: -333.268 },
		{ X: 342.619, Y: 0.234, Z: -343.409 },
		{ X: 337.282, Y: 0.234, Z: -351.107 },
		{ X: 329.419, Y: 0.273, Z: -362.325 },
		{ X: 322.527, Y: 0.239, Z: -372.211 },
		{ X: 309.168, Y: 0.254, Z: -391.326 },
		{ X: 302.083, Y: 0.268, Z: -401.446 },
		{ X: 293.759, Y: 0.251, Z: -414.32 },
		{ X: 287.438, Y: 0.243, Z: -429.379 },
		{ X: 282.895, Y: 0.263, Z: -441.044 },
		{ X: 406.824, Y: 0.185, Z: -251.245 },
		{ X: 399.754, Y: 0.217, Z: -261.383 },
		{ X: 390.112, Y: 0.193, Z: -275.087 },
		{ X: 383.056, Y: 0.195, Z: -285.176 },
		{ X: 369.699, Y: 0.254, Z: -304.305 },
		{ X: 362.835, Y: 0.23, Z: -314.291 },
		{ X: 349.653, Y: 0.262, Z: -333.312 },
		{ X: 342.613, Y: 0.277, Z: -343.441 },
		{ X: 337.269, Y: 0.228, Z: -351.144 },
		{ X: 329.417, Y: 0.228, Z: -362.328 },
		{ X: 322.516, Y: 0.276, Z: -372.245 },
		{ X: 309.141, Y: 0.255, Z: -391.347 },
		{ X: 302.06, Y: 0.282, Z: -401.488 },
		{ X: 293.72, Y: 0.239, Z: -414.369 }
	],
	// Daytona International Speedway
	8367: [
		{ X: -6.016, Y: 6.745, Z: -523.108 },
		{ X: -0.394, Y: 6.689, Z: -515.312 },
		{ X: 5.239, Y: 6.643, Z: -507.498 },
		{ X: 11.143, Y: 6.698, Z: -499.45 },
		{ X: 16.452, Y: 6.706, Z: -492.028 },
		{ X: 22.033, Y: 6.659, Z: -484.359 },
		{ X: 27.695, Y: 6.661, Z: -476.528 },
		{ X: 33.28, Y: 6.656, Z: -468.81 },
		{ X: 38.933, Y: 6.691, Z: -461.126 },
		{ X: 44.557, Y: 6.649, Z: -453.419 },
		{ X: 50.154, Y: 6.691, Z: -445.633 },
		{ X: 55.769, Y: 6.686, Z: -437.869 },
		{ X: 61.325, Y: 6.652, Z: -430.161 },
		{ X: 66.972, Y: 6.655, Z: -422.394 },
		{ X: 72.57, Y: 6.672, Z: -414.672 },
		{ X: 78.202, Y: 6.693, Z: -406.938 },
		{ X: 83.833, Y: 6.706, Z: -399.186 },
		{ X: 89.395, Y: 6.659, Z: -391.488 },
		{ X: 95.02, Y: 6.672, Z: -383.72 },
		{ X: 100.659, Y: 6.677, Z: -375.922 },
		{ X: 106.263, Y: 6.688, Z: -368.15 },
		{ X: 111.9, Y: 6.691, Z: -360.438 },
		{ X: 117.473, Y: 6.695, Z: -352.735 },
		{ X: 123.072, Y: 6.643, Z: -345.021 },
		{ X: 128.77, Y: 6.687, Z: -337.216 },
		{ X: 134.319, Y: 6.693, Z: -329.543 },
		{ X: 139.953, Y: 6.677, Z: -321.723 },
		{ X: 145.527, Y: 6.699, Z: -314.076 },
		{ X: 151.144, Y: 6.704, Z: -306.348 },
		{ X: 156.778, Y: 6.661, Z: -298.504 },
		{ X: 162.375, Y: 6.682, Z: -290.816 },
		{ X: 167.992, Y: 6.695, Z: -283.101 },
		{ X: 173.565, Y: 6.684, Z: -275.326 },
		{ X: 179.254, Y: 6.688, Z: -267.598 },
		{ X: 184.864, Y: 6.654, Z: -259.811 },
		{ X: 190.486, Y: 6.696, Z: -252.005 },
		{ X: 196.164, Y: 6.692, Z: -244.336 },
		{ X: 201.728, Y: 6.682, Z: -236.615 },
		{ X: 207.331, Y: 6.659, Z: -228.897 },
		{ X: 212.937, Y: 6.685, Z: -221.153 },
		{ X: -6.012, Y: 6.661, Z: -523.062 },
		{ X: -0.365, Y: 6.708, Z: -515.283 },
		{ X: 5.243, Y: 6.702, Z: -507.541 },
		{ X: 11.152, Y: 6.69, Z: -499.373 },
		{ X: 16.457, Y: 6.694, Z: -492.015 },
		{ X: 22.075, Y: 6.672, Z: -484.313 },
		{ X: 27.698, Y: 6.66, Z: -476.576 },
		{ X: 33.295, Y: 6.653, Z: -468.771 },
		{ X: 38.948, Y: 6.698, Z: -461.079 },
		{ X: 44.558, Y: 6.675, Z: -453.372 },
		{ X: 50.154, Y: 6.675, Z: -445.636 },
		{ X: 55.758, Y: 6.688, Z: -437.896 },
		{ X: 61.337, Y: 6.675, Z: -430.169 },
		{ X: 66.984, Y: 6.673, Z: -422.396 },
		{ X: 72.563, Y: 6.654, Z: -414.692 },
		{ X: 78.177, Y: 6.696, Z: -406.946 },
		{ X: 83.825, Y: 6.697, Z: -399.206 },
		{ X: 89.422, Y: 6.674, Z: -391.466 },
		{ X: 95.017, Y: 6.678, Z: -383.684 },
		{ X: 100.607, Y: 6.672, Z: -375.993 },
		{ X: 106.246, Y: 6.692, Z: -368.204 },
		{ X: 111.878, Y: 6.687, Z: -360.457 },
		{ X: 117.491, Y: 6.687, Z: -352.677 },
		{ X: 123.064, Y: 6.672, Z: -345.047 },
		{ X: 128.737, Y: 6.7, Z: -337.264 },
		{ X: 134.34, Y: 6.686, Z: -329.529 },
		{ X: 139.938, Y: 6.667, Z: -321.726 },
		{ X: 145.539, Y: 6.649, Z: -314.096 },
		{ X: 151.148, Y: 6.702, Z: -306.347 },
		{ X: 156.781, Y: 6.647, Z: -298.554 },
		{ X: 162.367, Y: 6.69, Z: -290.787 },
		{ X: 167.999, Y: 6.701, Z: -283.079 },
		{ X: 173.577, Y: 6.675, Z: -275.336 },
		{ X: 179.271, Y: 6.672, Z: -267.551 },
		{ X: 184.87, Y: 6.655, Z: -259.797 },
		{ X: 190.466, Y: 6.666, Z: -252.058 },
		{ X: 196.165, Y: 6.687, Z: -244.331 },
		{ X: 201.737, Y: 6.653, Z: -236.644 },
		{ X: 207.331, Y: 6.677, Z: -228.889 },
		{ X: 212.921, Y: 6.668, Z: -221.15 }
	],
	// Daytona International Speedway
	8648: [
		{ X: -6.016, Y: 6.745, Z: -523.108 },
		{ X: -0.394, Y: 6.689, Z: -515.312 },
		{ X: 5.239, Y: 6.643, Z: -507.498 },
		{ X: 11.143, Y: 6.698, Z: -499.45 },
		{ X: 16.452, Y: 6.706, Z: -492.028 },
		{ X: 22.033, Y: 6.659, Z: -484.359 },
		{ X: 27.695, Y: 6.661, Z: -476.528 },
		{ X: 33.28, Y: 6.656, Z: -468.81 },
		{ X: 38.933, Y: 6.691, Z: -461.126 },
		{ X: 44.557, Y: 6.649, Z: -453.419 },
		{ X: 50.154, Y: 6.691, Z: -445.633 },
		{ X: 55.769, Y: 6.686, Z: -437.869 },
		{ X: 61.325, Y: 6.652, Z: -430.161 },
		{ X: 66.972, Y: 6.655, Z: -422.394 },
		{ X: 72.57, Y: 6.672, Z: -414.672 },
		{ X: 78.202, Y: 6.693, Z: -406.938 },
		{ X: 83.833, Y: 6.706, Z: -399.186 },
		{ X: 89.395, Y: 6.659, Z: -391.488 },
		{ X: 95.02, Y: 6.672, Z: -383.72 },
		{ X: 100.659, Y: 6.677, Z: -375.922 },
		{ X: 106.263, Y: 6.688, Z: -368.15 },
		{ X: 111.9, Y: 6.691, Z: -360.438 },
		{ X: 117.473, Y: 6.695, Z: -352.735 },
		{ X: 123.072, Y: 6.643, Z: -345.021 },
		{ X: 128.77, Y: 6.687, Z: -337.216 },
		{ X: 134.319, Y: 6.693, Z: -329.543 },
		{ X: 139.953, Y: 6.677, Z: -321.723 },
		{ X: 145.527, Y: 6.699, Z: -314.076 },
		{ X: 151.144, Y: 6.704, Z: -306.348 },
		{ X: 156.778, Y: 6.661, Z: -298.504 },
		{ X: 162.375, Y: 6.682, Z: -290.816 },
		{ X: 167.992, Y: 6.695, Z: -283.101 },
		{ X: 173.565, Y: 6.684, Z: -275.326 },
		{ X: 179.254, Y: 6.688, Z: -267.598 },
		{ X: 184.864, Y: 6.654, Z: -259.811 },
		{ X: 190.486, Y: 6.696, Z: -252.005 },
		{ X: 196.164, Y: 6.692, Z: -244.336 },
		{ X: 201.728, Y: 6.682, Z: -236.615 },
		{ X: 207.331, Y: 6.659, Z: -228.897 },
		{ X: 212.937, Y: 6.685, Z: -221.153 },
		{ X: -6.012, Y: 6.661, Z: -523.062 },
		{ X: -0.365, Y: 6.708, Z: -515.283 },
		{ X: 5.243, Y: 6.702, Z: -507.541 },
		{ X: 11.152, Y: 6.69, Z: -499.373 },
		{ X: 16.457, Y: 6.694, Z: -492.015 },
		{ X: 22.075, Y: 6.672, Z: -484.313 },
		{ X: 27.698, Y: 6.66, Z: -476.576 },
		{ X: 33.295, Y: 6.653, Z: -468.771 },
		{ X: 38.948, Y: 6.698, Z: -461.079 },
		{ X: 44.558, Y: 6.675, Z: -453.372 },
		{ X: 50.154, Y: 6.675, Z: -445.636 },
		{ X: 55.758, Y: 6.688, Z: -437.896 },
		{ X: 61.337, Y: 6.675, Z: -430.169 },
		{ X: 66.984, Y: 6.673, Z: -422.396 },
		{ X: 72.563, Y: 6.654, Z: -414.692 },
		{ X: 78.177, Y: 6.696, Z: -406.946 },
		{ X: 83.825, Y: 6.697, Z: -399.206 },
		{ X: 89.422, Y: 6.674, Z: -391.466 },
		{ X: 95.017, Y: 6.678, Z: -383.684 },
		{ X: 100.607, Y: 6.672, Z: -375.993 },
		{ X: 106.246, Y: 6.692, Z: -368.204 },
		{ X: 111.878, Y: 6.687, Z: -360.457 },
		{ X: 117.491, Y: 6.687, Z: -352.677 },
		{ X: 123.064, Y: 6.672, Z: -345.047 },
		{ X: 128.737, Y: 6.7, Z: -337.264 },
		{ X: 134.34, Y: 6.686, Z: -329.529 },
		{ X: 139.938, Y: 6.667, Z: -321.726 },
		{ X: 145.539, Y: 6.649, Z: -314.096 },
		{ X: 151.148, Y: 6.702, Z: -306.347 },
		{ X: 156.781, Y: 6.647, Z: -298.554 },
		{ X: 162.367, Y: 6.69, Z: -290.787 },
		{ X: 167.999, Y: 6.701, Z: -283.079 },
		{ X: 173.577, Y: 6.675, Z: -275.336 },
		{ X: 179.271, Y: 6.672, Z: -267.551 },
		{ X: 184.87, Y: 6.655, Z: -259.797 },
		{ X: 190.466, Y: 6.666, Z: -252.058 },
		{ X: 196.165, Y: 6.687, Z: -244.331 },
		{ X: 201.737, Y: 6.653, Z: -236.644 },
		{ X: 207.331, Y: 6.677, Z: -228.889 },
		{ X: 212.921, Y: 6.668, Z: -221.15 }
	],
	// Daytona International Speedway
	8655: [
		{ X: -6.016, Y: 6.745, Z: -523.108 },
		{ X: -0.394, Y: 6.689, Z: -515.312 },
		{ X: 5.239, Y: 6.643, Z: -507.498 },
		{ X: 11.143, Y: 6.698, Z: -499.45 },
		{ X: 16.452, Y: 6.706, Z: -492.028 },
		{ X: 22.033, Y: 6.659, Z: -484.359 },
		{ X: 27.695, Y: 6.661, Z: -476.528 },
		{ X: 33.28, Y: 6.656, Z: -468.81 },
		{ X: 38.933, Y: 6.691, Z: -461.126 },
		{ X: 44.557, Y: 6.649, Z: -453.419 },
		{ X: 50.154, Y: 6.691, Z: -445.633 },
		{ X: 55.769, Y: 6.686, Z: -437.869 },
		{ X: 61.325, Y: 6.652, Z: -430.161 },
		{ X: 66.972, Y: 6.655, Z: -422.394 },
		{ X: 72.57, Y: 6.672, Z: -414.672 },
		{ X: 78.202, Y: 6.693, Z: -406.938 },
		{ X: 83.833, Y: 6.706, Z: -399.186 },
		{ X: 89.395, Y: 6.659, Z: -391.488 },
		{ X: 95.02, Y: 6.672, Z: -383.72 },
		{ X: 100.659, Y: 6.677, Z: -375.922 },
		{ X: 106.263, Y: 6.688, Z: -368.15 },
		{ X: 111.9, Y: 6.691, Z: -360.438 },
		{ X: 117.473, Y: 6.695, Z: -352.735 },
		{ X: 123.072, Y: 6.643, Z: -345.021 },
		{ X: 128.77, Y: 6.687, Z: -337.216 },
		{ X: 134.319, Y: 6.693, Z: -329.543 },
		{ X: 139.953, Y: 6.677, Z: -321.723 },
		{ X: 145.527, Y: 6.699, Z: -314.076 },
		{ X: 151.144, Y: 6.704, Z: -306.348 },
		{ X: 156.778, Y: 6.661, Z: -298.504 },
		{ X: 162.375, Y: 6.682, Z: -290.816 },
		{ X: 167.992, Y: 6.695, Z: -283.101 },
		{ X: 173.565, Y: 6.684, Z: -275.326 },
		{ X: 179.254, Y: 6.688, Z: -267.598 },
		{ X: 184.864, Y: 6.654, Z: -259.811 },
		{ X: 190.486, Y: 6.696, Z: -252.005 },
		{ X: 196.164, Y: 6.692, Z: -244.336 },
		{ X: 201.728, Y: 6.682, Z: -236.615 },
		{ X: 207.331, Y: 6.659, Z: -228.897 },
		{ X: 212.937, Y: 6.685, Z: -221.153 },
		{ X: -6.012, Y: 6.661, Z: -523.062 },
		{ X: -0.365, Y: 6.708, Z: -515.283 },
		{ X: 5.243, Y: 6.702, Z: -507.541 },
		{ X: 11.152, Y: 6.69, Z: -499.373 },
		{ X: 16.457, Y: 6.694, Z: -492.015 },
		{ X: 22.075, Y: 6.672, Z: -484.313 },
		{ X: 27.698, Y: 6.66, Z: -476.576 },
		{ X: 33.295, Y: 6.653, Z: -468.771 },
		{ X: 38.948, Y: 6.698, Z: -461.079 },
		{ X: 44.558, Y: 6.675, Z: -453.372 },
		{ X: 50.154, Y: 6.675, Z: -445.636 },
		{ X: 55.758, Y: 6.688, Z: -437.896 },
		{ X: 61.337, Y: 6.675, Z: -430.169 },
		{ X: 66.984, Y: 6.673, Z: -422.396 },
		{ X: 72.563, Y: 6.654, Z: -414.692 },
		{ X: 78.177, Y: 6.696, Z: -406.946 },
		{ X: 83.825, Y: 6.697, Z: -399.206 },
		{ X: 89.422, Y: 6.674, Z: -391.466 },
		{ X: 95.017, Y: 6.678, Z: -383.684 },
		{ X: 100.607, Y: 6.672, Z: -375.993 },
		{ X: 106.246, Y: 6.692, Z: -368.204 },
		{ X: 111.878, Y: 6.687, Z: -360.457 },
		{ X: 117.491, Y: 6.687, Z: -352.677 },
		{ X: 123.064, Y: 6.672, Z: -345.047 },
		{ X: 128.737, Y: 6.7, Z: -337.264 },
		{ X: 134.34, Y: 6.686, Z: -329.529 },
		{ X: 139.938, Y: 6.667, Z: -321.726 },
		{ X: 145.539, Y: 6.649, Z: -314.096 },
		{ X: 151.148, Y: 6.702, Z: -306.347 },
		{ X: 156.781, Y: 6.647, Z: -298.554 },
		{ X: 162.367, Y: 6.69, Z: -290.787 },
		{ X: 167.999, Y: 6.701, Z: -283.079 },
		{ X: 173.577, Y: 6.675, Z: -275.336 },
		{ X: 179.271, Y: 6.672, Z: -267.551 },
		{ X: 184.87, Y: 6.655, Z: -259.797 },
		{ X: 190.466, Y: 6.666, Z: -252.058 },
		{ X: 196.165, Y: 6.687, Z: -244.331 },
		{ X: 201.737, Y: 6.653, Z: -236.644 },
		{ X: 207.331, Y: 6.677, Z: -228.889 },
		{ X: 212.921, Y: 6.668, Z: -221.15 }
	],
	// Motorland Aragón
	8704: [
		{ X: 246.697, Y: 14.993, Z: -59.213 },
		{ X: 256.865, Y: 14.934, Z: -67.582 },
		{ X: 266.822, Y: 14.924, Z: -75.879 },
		{ X: 276.758, Y: 14.931, Z: -84.04 },
		{ X: 286.76, Y: 14.888, Z: -92.242 },
		{ X: 296.753, Y: 14.872, Z: -100.494 },
		{ X: 306.72, Y: 14.863, Z: -108.653 },
		{ X: 329.331, Y: 14.867, Z: -127.205 },
		{ X: 340.38, Y: 14.856, Z: -136.308 },
		{ X: 351.486, Y: 14.87, Z: -145.446 },
		{ X: 369.31, Y: 14.857, Z: -160.15 },
		{ X: 380.313, Y: 14.884, Z: -169.148 },
		{ X: 391.362, Y: 14.855, Z: -178.258 },
		{ X: 408.941, Y: 14.829, Z: -192.7 },
		{ X: 420.086, Y: 14.873, Z: -201.9 },
		{ X: 431.268, Y: 14.836, Z: -211.07 },
		{ X: 448.851, Y: 14.843, Z: -225.537 },
		{ X: 459.964, Y: 14.885, Z: -234.651 },
		{ X: 471.252, Y: 14.87, Z: -243.933 },
		{ X: 490.437, Y: 14.876, Z: -259.79 },
		{ X: 501.044, Y: 14.853, Z: -268.498 },
		{ X: 246.7, Y: 14.936, Z: -59.227 },
		{ X: 256.803, Y: 14.93, Z: -67.549 },
		{ X: 266.827, Y: 14.901, Z: -75.859 },
		{ X: 276.772, Y: 14.928, Z: -84.046 },
		{ X: 286.775, Y: 14.939, Z: -92.25 },
		{ X: 296.782, Y: 14.917, Z: -100.505 },
		{ X: 306.706, Y: 14.897, Z: -108.648 },
		{ X: 329.257, Y: 14.871, Z: -127.193 },
		{ X: 340.337, Y: 14.891, Z: -136.272 },
		{ X: 351.488, Y: 14.857, Z: -145.466 },
		{ X: 369.261, Y: 14.875, Z: -160.133 }
	],
	// Motorland Aragón
	9040: [
		{ X: 246.697, Y: 14.993, Z: -59.213 },
		{ X: 256.865, Y: 14.934, Z: -67.582 },
		{ X: 266.822, Y: 14.924, Z: -75.879 },
		{ X: 276.758, Y: 14.931, Z: -84.04 },
		{ X: 286.76, Y: 14.888, Z: -92.242 },
		{ X: 296.753, Y: 14.872, Z: -100.494 },
		{ X: 306.72, Y: 14.863, Z: -108.653 },
		{ X: 329.331, Y: 14.867, Z: -127.205 },
		{ X: 340.38, Y: 14.856, Z: -136.308 },
		{ X: 351.486, Y: 14.87, Z: -145.446 },
		{ X: 369.31, Y: 14.857, Z: -160.15 },
		{ X: 380.313, Y: 14.884, Z: -169.148 },
		{ X: 391.362, Y: 14.855, Z: -178.258 },
		{ X: 408.941, Y: 14.829, Z: -192.7 },
		{ X: 420.086, Y: 14.873, Z: -201.9 },
		{ X: 431.268, Y: 14.836, Z: -211.07 },
		{ X: 448.851, Y: 14.843, Z: -225.537 },
		{ X: 459.964, Y: 14.885, Z: -234.651 },
		{ X: 471.252, Y: 14.87, Z: -243.933 },
		{ X: 490.437, Y: 14.876, Z: -259.79 },
		{ X: 501.044, Y: 14.853, Z: -268.498 },
		{ X: 246.7, Y: 14.936, Z: -59.227 },
		{ X: 256.803, Y: 14.93, Z: -67.549 },
		{ X: 266.827, Y: 14.901, Z: -75.859 },
		{ X: 276.772, Y: 14.928, Z: -84.046 },
		{ X: 286.775, Y: 14.939, Z: -92.25 },
		{ X: 296.782, Y: 14.917, Z: -100.505 },
		{ X: 306.706, Y: 14.897, Z: -108.648 },
		{ X: 329.257, Y: 14.871, Z: -127.193 },
		{ X: 340.337, Y: 14.891, Z: -136.272 },
		{ X: 351.488, Y: 14.857, Z: -145.466 },
		{ X: 369.261, Y: 14.875, Z: -160.133 }
	],
	// Motorland Aragón
	9041: [
		{ X: 246.697, Y: 14.993, Z: -59.213 },
		{ X: 256.865, Y: 14.934, Z: -67.582 },
		{ X: 266.822, Y: 14.924, Z: -75.879 },
		{ X: 276.758, Y: 14.931, Z: -84.04 },
		{ X: 286.76, Y: 14.888, Z: -92.242 },
		{ X: 296.753, Y: 14.872, Z: -100.494 },
		{ X: 306.72, Y: 14.863, Z: -108.653 },
		{ X: 329.331, Y: 14.867, Z: -127.205 },
		{ X: 340.38, Y: 14.856, Z: -136.308 },
		{ X: 351.486, Y: 14.87, Z: -145.446 },
		{ X: 369.31, Y: 14.857, Z: -160.15 },
		{ X: 380.313, Y: 14.884, Z: -169.148 },
		{ X: 391.362, Y: 14.855, Z: -178.258 },
		{ X: 408.941, Y: 14.829, Z: -192.7 },
		{ X: 420.086, Y: 14.873, Z: -201.9 },
		{ X: 431.268, Y: 14.836, Z: -211.07 },
		{ X: 448.851, Y: 14.843, Z: -225.537 },
		{ X: 459.964, Y: 14.885, Z: -234.651 },
		{ X: 471.252, Y: 14.87, Z: -243.933 },
		{ X: 490.437, Y: 14.876, Z: -259.79 },
		{ X: 501.044, Y: 14.853, Z: -268.498 },
		{ X: 246.7, Y: 14.936, Z: -59.227 },
		{ X: 256.803, Y: 14.93, Z: -67.549 },
		{ X: 266.827, Y: 14.901, Z: -75.859 },
		{ X: 276.772, Y: 14.928, Z: -84.046 },
		{ X: 286.775, Y: 14.939, Z: -92.25 },
		{ X: 296.782, Y: 14.917, Z: -100.505 },
		{ X: 306.706, Y: 14.897, Z: -108.648 },
		{ X: 329.257, Y: 14.871, Z: -127.193 },
		{ X: 340.337, Y: 14.891, Z: -136.272 },
		{ X: 351.488, Y: 14.857, Z: -145.466 },
		{ X: 369.261, Y: 14.875, Z: -160.133 }
	],
	// Motorland Aragón
	9042: [
		{ X: 246.697, Y: 14.993, Z: -59.213 },
		{ X: 256.865, Y: 14.934, Z: -67.582 },
		{ X: 266.822, Y: 14.924, Z: -75.879 },
		{ X: 276.758, Y: 14.931, Z: -84.04 },
		{ X: 286.76, Y: 14.888, Z: -92.242 },
		{ X: 296.753, Y: 14.872, Z: -100.494 },
		{ X: 306.72, Y: 14.863, Z: -108.653 },
		{ X: 329.331, Y: 14.867, Z: -127.205 },
		{ X: 340.38, Y: 14.856, Z: -136.308 },
		{ X: 351.486, Y: 14.87, Z: -145.446 },
		{ X: 369.31, Y: 14.857, Z: -160.15 },
		{ X: 380.313, Y: 14.884, Z: -169.148 },
		{ X: 391.362, Y: 14.855, Z: -178.258 },
		{ X: 408.941, Y: 14.829, Z: -192.7 },
		{ X: 420.086, Y: 14.873, Z: -201.9 },
		{ X: 431.268, Y: 14.836, Z: -211.07 },
		{ X: 448.851, Y: 14.843, Z: -225.537 },
		{ X: 459.964, Y: 14.885, Z: -234.651 },
		{ X: 471.252, Y: 14.87, Z: -243.933 },
		{ X: 490.437, Y: 14.876, Z: -259.79 },
		{ X: 501.044, Y: 14.853, Z: -268.498 },
		{ X: 246.7, Y: 14.936, Z: -59.227 },
		{ X: 256.803, Y: 14.93, Z: -67.549 },
		{ X: 266.827, Y: 14.901, Z: -75.859 },
		{ X: 276.772, Y: 14.928, Z: -84.046 },
		{ X: 286.775, Y: 14.939, Z: -92.25 },
		{ X: 296.782, Y: 14.917, Z: -100.505 },
		{ X: 306.706, Y: 14.897, Z: -108.648 },
		{ X: 329.257, Y: 14.871, Z: -127.193 },
		{ X: 340.337, Y: 14.891, Z: -136.272 },
		{ X: 351.488, Y: 14.857, Z: -145.466 },
		{ X: 369.261, Y: 14.875, Z: -160.133 }
	],
	// Motorland Aragón
	9043: [
		{ X: 246.697, Y: 14.993, Z: -59.213 },
		{ X: 256.865, Y: 14.934, Z: -67.582 },
		{ X: 266.822, Y: 14.924, Z: -75.879 },
		{ X: 276.758, Y: 14.931, Z: -84.04 },
		{ X: 286.76, Y: 14.888, Z: -92.242 },
		{ X: 296.753, Y: 14.872, Z: -100.494 },
		{ X: 306.72, Y: 14.863, Z: -108.653 },
		{ X: 329.331, Y: 14.867, Z: -127.205 },
		{ X: 340.38, Y: 14.856, Z: -136.308 },
		{ X: 351.486, Y: 14.87, Z: -145.446 },
		{ X: 369.31, Y: 14.857, Z: -160.15 },
		{ X: 380.313, Y: 14.884, Z: -169.148 },
		{ X: 391.362, Y: 14.855, Z: -178.258 },
		{ X: 408.941, Y: 14.829, Z: -192.7 },
		{ X: 420.086, Y: 14.873, Z: -201.9 },
		{ X: 431.268, Y: 14.836, Z: -211.07 },
		{ X: 448.851, Y: 14.843, Z: -225.537 },
		{ X: 459.964, Y: 14.885, Z: -234.651 },
		{ X: 471.252, Y: 14.87, Z: -243.933 },
		{ X: 490.437, Y: 14.876, Z: -259.79 },
		{ X: 501.044, Y: 14.853, Z: -268.498 },
		{ X: 246.7, Y: 14.936, Z: -59.227 },
		{ X: 256.803, Y: 14.93, Z: -67.549 },
		{ X: 266.827, Y: 14.901, Z: -75.859 },
		{ X: 276.772, Y: 14.928, Z: -84.046 },
		{ X: 286.775, Y: 14.939, Z: -92.25 },
		{ X: 296.782, Y: 14.917, Z: -100.505 },
		{ X: 306.706, Y: 14.897, Z: -108.648 },
		{ X: 329.257, Y: 14.871, Z: -127.193 },
		{ X: 340.337, Y: 14.891, Z: -136.272 },
		{ X: 351.488, Y: 14.857, Z: -145.466 },
		{ X: 369.261, Y: 14.875, Z: -160.133 }
	],
	// Watkins Glen International
	9177: [
		{ X: -390.457, Y: 1.387, Z: 826.476 },
		{ X: -391.194, Y: 1.656, Z: 817.592 },
		{ X: -391.96, Y: 1.902, Z: 808.614 },
		{ X: -392.744, Y: 2.191, Z: 799.387 },
		{ X: -393.481, Y: 2.494, Z: 790.554 },
		{ X: -394.259, Y: 2.786, Z: 781.504 },
		{ X: -395.035, Y: 3.031, Z: 772.574 },
		{ X: -395.805, Y: 3.254, Z: 763.51 },
		{ X: -396.554, Y: 3.51, Z: 754.656 },
		{ X: -397.311, Y: 3.732, Z: 745.729 },
		{ X: -398.102, Y: 3.999, Z: 736.717 },
		{ X: -398.894, Y: 4.188, Z: 727.559 },
		{ X: -399.633, Y: 4.421, Z: 718.845 },
		{ X: -400.413, Y: 4.586, Z: 709.761 },
		{ X: -401.172, Y: 4.788, Z: 700.703 },
		{ X: -401.925, Y: 4.937, Z: 691.821 },
		{ X: -402.668, Y: 5.102, Z: 682.909 },
		{ X: -403.426, Y: 5.238, Z: 673.812 },
		{ X: -404.185, Y: 5.431, Z: 664.994 },
		{ X: -404.954, Y: 5.558, Z: 655.797 },
		{ X: -405.7, Y: 5.672, Z: 646.797 },
		{ X: -406.475, Y: 5.816, Z: 637.765 },
		{ X: -407.21, Y: 5.946, Z: 628.967 },
		{ X: -408.266, Y: 6.082, Z: 616.612 },
		{ X: -409.001, Y: 6.186, Z: 607.724 },
		{ X: -409.791, Y: 6.311, Z: 598.618 },
		{ X: -410.528, Y: 6.411, Z: 590.009 },
		{ X: -411.28, Y: 6.507, Z: 581.007 },
		{ X: -412.016, Y: 6.612, Z: 572.035 },
		{ X: -412.766, Y: 6.721, Z: 563.231 },
		{ X: -413.498, Y: 6.805, Z: 554.43 },
		{ X: -414.264, Y: 6.882, Z: 545.52 },
		{ X: -415.009, Y: 7.013, Z: 536.734 },
		{ X: -415.769, Y: 7.118, Z: 527.679 },
		{ X: -416.508, Y: 7.238, Z: 518.747 },
		{ X: -417.264, Y: 7.356, Z: 509.889 },
		{ X: -417.982, Y: 7.452, Z: 501.182 },
		{ X: -419.963, Y: 7.65, Z: 477.408 },
		{ X: -420.723, Y: 7.726, Z: 468.237 },
		{ X: -421.443, Y: 7.764, Z: 459.411 },
		{ X: -422.178, Y: 7.794, Z: 450.554 },
		{ X: -422.921, Y: 7.914, Z: 441.617 },
		{ X: -423.654, Y: 8.078, Z: 432.512 }
	],
	// Watkins Glen International
	9324: [
		{ X: -390.457, Y: 1.387, Z: 826.476 },
		{ X: -391.194, Y: 1.656, Z: 817.592 },
		{ X: -391.96, Y: 1.902, Z: 808.614 },
		{ X: -392.744, Y: 2.191, Z: 799.387 },
		{ X: -393.481, Y: 2.494, Z: 790.554 },
		{ X: -394.259, Y: 2.786, Z: 781.504 },
		{ X: -395.035, Y: 3.031, Z: 772.574 },
		{ X: -395.805, Y: 3.254, Z: 763.51 },
		{ X: -396.554, Y: 3.51, Z: 754.656 },
		{ X: -397.311, Y: 3.732, Z: 745.729 },
		{ X: -398.102, Y: 3.999, Z: 736.717 },
		{ X: -398.894, Y: 4.188, Z: 727.559 },
		{ X: -399.633, Y: 4.421, Z: 718.845 },
		{ X: -400.413, Y: 4.586, Z: 709.761 },
		{ X: -401.172, Y: 4.788, Z: 700.703 },
		{ X: -401.925, Y: 4.937, Z: 691.821 },
		{ X: -402.668, Y: 5.102, Z: 682.909 },
		{ X: -403.426, Y: 5.238, Z: 673.812 },
		{ X: -404.185, Y: 5.431, Z: 664.994 },
		{ X: -404.954, Y: 5.558, Z: 655.797 },
		{ X: -405.7, Y: 5.672, Z: 646.797 },
		{ X: -406.475, Y: 5.816, Z: 637.765 },
		{ X: -407.21, Y: 5.946, Z: 628.967 },
		{ X: -408.266, Y: 6.082, Z: 616.612 },
		{ X: -409.001, Y: 6.186, Z: 607.724 },
		{ X: -409.791, Y: 6.311, Z: 598.618 },
		{ X: -410.528, Y: 6.411, Z: 590.009 },
		{ X: -411.28, Y: 6.507, Z: 581.007 },
		{ X: -412.016, Y: 6.612, Z: 572.035 },
		{ X: -412.766, Y: 6.721, Z: 563.231 },
		{ X: -413.498, Y: 6.805, Z: 554.43 },
		{ X: -414.264, Y: 6.882, Z: 545.52 },
		{ X: -415.009, Y: 7.013, Z: 536.734 },
		{ X: -415.769, Y: 7.118, Z: 527.679 },
		{ X: -416.508, Y: 7.238, Z: 518.747 },
		{ X: -417.264, Y: 7.356, Z: 509.889 },
		{ X: -417.982, Y: 7.452, Z: 501.182 },
		{ X: -419.963, Y: 7.65, Z: 477.408 },
		{ X: -420.723, Y: 7.726, Z: 468.237 },
		{ X: -421.443, Y: 7.764, Z: 459.411 },
		{ X: -422.178, Y: 7.794, Z: 450.554 },
		{ X: -422.921, Y: 7.914, Z: 441.617 },
		{ X: -423.654, Y: 8.078, Z: 432.512 }
	],
	// Watkins Glen International
	9343: [
		{ X: -390.457, Y: 1.387, Z: 826.476 },
		{ X: -391.194, Y: 1.656, Z: 817.592 },
		{ X: -391.96, Y: 1.902, Z: 808.614 },
		{ X: -392.744, Y: 2.191, Z: 799.387 },
		{ X: -393.481, Y: 2.494, Z: 790.554 },
		{ X: -394.259, Y: 2.786, Z: 781.504 },
		{ X: -395.035, Y: 3.031, Z: 772.574 },
		{ X: -395.805, Y: 3.254, Z: 763.51 },
		{ X: -396.554, Y: 3.51, Z: 754.656 },
		{ X: -397.311, Y: 3.732, Z: 745.729 },
		{ X: -398.102, Y: 3.999, Z: 736.717 },
		{ X: -398.894, Y: 4.188, Z: 727.559 },
		{ X: -399.633, Y: 4.421, Z: 718.845 },
		{ X: -400.413, Y: 4.586, Z: 709.761 },
		{ X: -401.172, Y: 4.788, Z: 700.703 },
		{ X: -401.925, Y: 4.937, Z: 691.821 },
		{ X: -402.668, Y: 5.102, Z: 682.909 },
		{ X: -403.426, Y: 5.238, Z: 673.812 },
		{ X: -404.185, Y: 5.431, Z: 664.994 },
		{ X: -404.954, Y: 5.558, Z: 655.797 },
		{ X: -405.7, Y: 5.672, Z: 646.797 },
		{ X: -406.475, Y: 5.816, Z: 637.765 },
		{ X: -407.21, Y: 5.946, Z: 628.967 },
		{ X: -408.266, Y: 6.082, Z: 616.612 },
		{ X: -409.001, Y: 6.186, Z: 607.724 },
		{ X: -409.791, Y: 6.311, Z: 598.618 },
		{ X: -410.528, Y: 6.411, Z: 590.009 },
		{ X: -411.28, Y: 6.507, Z: 581.007 },
		{ X: -412.016, Y: 6.612, Z: 572.035 },
		{ X: -412.766, Y: 6.721, Z: 563.231 },
		{ X: -413.498, Y: 6.805, Z: 554.43 },
		{ X: -414.264, Y: 6.882, Z: 545.52 },
		{ X: -415.009, Y: 7.013, Z: 536.734 },
		{ X: -415.769, Y: 7.118, Z: 527.679 },
		{ X: -416.508, Y: 7.238, Z: 518.747 },
		{ X: -417.264, Y: 7.356, Z: 509.889 },
		{ X: -417.982, Y: 7.452, Z: 501.182 },
		{ X: -419.963, Y: 7.65, Z: 477.408 },
		{ X: -420.723, Y: 7.726, Z: 468.237 },
		{ X: -421.443, Y: 7.764, Z: 459.411 },
		{ X: -422.178, Y: 7.794, Z: 450.554 },
		{ X: -422.921, Y: 7.914, Z: 441.617 },
		{ X: -423.654, Y: 8.078, Z: 432.512 }
	],
	// Watkins Glen International
	9344: [
		{ X: -390.457, Y: 1.387, Z: 826.476 },
		{ X: -391.194, Y: 1.656, Z: 817.592 },
		{ X: -391.96, Y: 1.902, Z: 808.614 },
		{ X: -392.744, Y: 2.191, Z: 799.387 },
		{ X: -393.481, Y: 2.494, Z: 790.554 },
		{ X: -394.259, Y: 2.786, Z: 781.504 },
		{ X: -395.035, Y: 3.031, Z: 772.574 },
		{ X: -395.805, Y: 3.254, Z: 763.51 },
		{ X: -396.554, Y: 3.51, Z: 754.656 },
		{ X: -397.311, Y: 3.732, Z: 745.729 },
		{ X: -398.102, Y: 3.999, Z: 736.717 },
		{ X: -398.894, Y: 4.188, Z: 727.559 },
		{ X: -399.633, Y: 4.421, Z: 718.845 },
		{ X: -400.413, Y: 4.586, Z: 709.761 },
		{ X: -401.172, Y: 4.788, Z: 700.703 },
		{ X: -401.925, Y: 4.937, Z: 691.821 },
		{ X: -402.668, Y: 5.102, Z: 682.909 },
		{ X: -403.426, Y: 5.238, Z: 673.812 },
		{ X: -404.185, Y: 5.431, Z: 664.994 },
		{ X: -404.954, Y: 5.558, Z: 655.797 },
		{ X: -405.7, Y: 5.672, Z: 646.797 },
		{ X: -406.475, Y: 5.816, Z: 637.765 },
		{ X: -407.21, Y: 5.946, Z: 628.967 },
		{ X: -408.266, Y: 6.082, Z: 616.612 },
		{ X: -409.001, Y: 6.186, Z: 607.724 },
		{ X: -409.791, Y: 6.311, Z: 598.618 },
		{ X: -410.528, Y: 6.411, Z: 590.009 },
		{ X: -411.28, Y: 6.507, Z: 581.007 },
		{ X: -412.016, Y: 6.612, Z: 572.035 },
		{ X: -412.766, Y: 6.721, Z: 563.231 },
		{ X: -413.498, Y: 6.805, Z: 554.43 },
		{ X: -414.264, Y: 6.882, Z: 545.52 },
		{ X: -415.009, Y: 7.013, Z: 536.734 },
		{ X: -415.769, Y: 7.118, Z: 527.679 },
		{ X: -416.508, Y: 7.238, Z: 518.747 },
		{ X: -417.264, Y: 7.356, Z: 509.889 },
		{ X: -417.982, Y: 7.452, Z: 501.182 },
		{ X: -419.963, Y: 7.65, Z: 477.408 },
		{ X: -420.723, Y: 7.726, Z: 468.237 },
		{ X: -421.443, Y: 7.764, Z: 459.411 },
		{ X: -422.178, Y: 7.794, Z: 450.554 },
		{ X: -422.921, Y: 7.914, Z: 441.617 },
		{ X: -423.654, Y: 8.078, Z: 432.512 }
	],
	// Valerbanen
	9465: [
		{ X: -50.136, Y: 0.365, Z: 41.186 },
		{ X: -41.44, Y: 0.285, Z: 27.201 },
		{ X: -34.21, Y: 0.308, Z: 15.496 },
		{ X: -29.207, Y: 0.27, Z: 7.059 },
		{ X: -22.878, Y: 0.303, Z: -3.42 },
		{ X: -16.511, Y: 0.283, Z: -13.728 },
		{ X: -8.185, Y: 0.27, Z: -27.398 },
		{ X: -1.048, Y: 0.281, Z: -38.938 },
		{ X: 21.542, Y: 0.311, Z: -77.914 },
		{ X: 30.07, Y: 0.256, Z: -91.662 },
		{ X: 37.145, Y: 0.258, Z: -103.746 },
		{ X: 44.357, Y: 0.312, Z: -116.153 },
		{ X: -50.095, Y: 0.279, Z: 41.148 },
		{ X: -41.471, Y: 0.273, Z: 27.257 },
		{ X: -34.241, Y: 0.282, Z: 15.523 },
		{ X: -29.176, Y: 0.283, Z: 7.028 },
		{ X: -22.862, Y: 0.297, Z: -3.435 },
		{ X: -16.487, Y: 0.302, Z: -13.755 },
		{ X: -8.16, Y: 0.278, Z: -27.425 },
		{ X: -1.027, Y: 0.29, Z: -38.983 },
		{ X: 21.558, Y: 0.294, Z: -77.953 },
		{ X: 30.064, Y: 0.28, Z: -91.655 },
		{ X: 37.121, Y: 0.307, Z: -103.701 },
		{ X: 44.328, Y: 0.296, Z: -116.1 },
		{ X: -50.087, Y: 0.277, Z: 41.139 },
		{ X: -41.439, Y: 0.298, Z: 27.201 },
		{ X: -34.232, Y: 0.277, Z: 15.517 },
		{ X: -29.171, Y: 0.278, Z: 6.997 },
		{ X: -22.829, Y: 0.262, Z: -3.47 },
		{ X: -16.497, Y: 0.266, Z: -13.742 },
		{ X: -8.181, Y: 0.27, Z: -27.379 },
		{ X: -1.05, Y: 0.306, Z: -38.961 },
		{ X: 21.57, Y: 0.285, Z: -77.94 },
		{ X: 30.051, Y: 0.308, Z: -91.645 },
		{ X: 37.128, Y: 0.269, Z: -103.708 },
		{ X: 44.344, Y: 0.256, Z: -116.135 }
	],
	// Brands Hatch Grand Prix
	9473: [
		{ X: 290.932, Y: 9.091, Z: 224.541 },
		{ X: 279.876, Y: 9.094, Z: 218.978 },
		{ X: 268.769, Y: 9.202, Z: 213.366 },
		{ X: 257.747, Y: 9.381, Z: 207.767 },
		{ X: 246.757, Y: 9.626, Z: 202.161 },
		{ X: 235.854, Y: 9.965, Z: 196.381 },
		{ X: 225.08, Y: 10.256, Z: 190.583 },
		{ X: 214.112, Y: 10.585, Z: 184.823 },
		{ X: 203.201, Y: 10.853, Z: 178.893 },
		{ X: 192.264, Y: 11.218, Z: 173.077 },
		{ X: 181.4, Y: 11.527, Z: 166.892 },
		{ X: 170.65, Y: 11.806, Z: 160.817 },
		{ X: 159.641, Y: 12.149, Z: 154.469 },
		{ X: 149.291, Y: 12.414, Z: 148.285 },
		{ X: 138.565, Y: 12.7, Z: 141.626 },
		{ X: 128.077, Y: 12.963, Z: 135.118 },
		{ X: 290.965, Y: 9.018, Z: 224.572 },
		{ X: 279.906, Y: 9.1, Z: 218.984 },
		{ X: 268.787, Y: 9.172, Z: 213.369 },
		{ X: 257.791, Y: 9.363, Z: 207.778 },
		{ X: 246.736, Y: 9.656, Z: 202.156 },
		{ X: 235.904, Y: 9.978, Z: 196.409 },
		{ X: 225.104, Y: 10.286, Z: 190.589 },
		{ X: 214.087, Y: 10.562, Z: 184.816 },
		{ X: 203.224, Y: 10.868, Z: 178.899 },
		{ X: 192.297, Y: 11.173, Z: 173.103 },
		{ X: 181.477, Y: 11.545, Z: 166.948 },
		{ X: 170.631, Y: 11.789, Z: 160.799 },
		{ X: 159.639, Y: 12.128, Z: 154.463 },
		{ X: 149.293, Y: 12.417, Z: 148.286 },
		{ X: 138.596, Y: 12.682, Z: 141.635 },
		{ X: 128.097, Y: 12.97, Z: 135.14 },
		{ X: 290.962, Y: 8.984, Z: 224.571 },
		{ X: 279.934, Y: 9.082, Z: 218.993 }
	],
	9796: [
		{ X: -1122.74133, Y: 28.1806774, Z: -564.863953 },
		{ X: -1108.03479, Y: 28.15886, Z: -571.272156 },
		{ X: -1093.35352, Y: 28.2253456, Z: -577.6634 },
		{ X: -1078.4751, Y: 28.3419933, Z: -584.1385 },
		{ X: -1063.65955, Y: 28.3855553, Z: -590.582031 },
		{ X: -1049.03882, Y: 28.521122, Z: -596.9852 },
		{ X: -1034.1604, Y: 28.60591, Z: -603.4281 },
		{ X: -1019.33746, Y: 28.6991119, Z: -609.917847 },
		{ X: -1004.58228, Y: 28.8552837, Z: -616.371765 },
		{ X: -989.682739, Y: 28.96084, Z: -622.863159 },
		{ X: -974.9934, Y: 29.0678463, Z: -629.268 },
		{ X: -960.238159, Y: 29.1384315, Z: -635.730957 },
		{ X: -945.3948, Y: 29.2609138, Z: -642.2194 },
		{ X: -1115.57874, Y: 28.1596928, Z: -568.0233 },
		{ X: -1100.84851, Y: 28.2043819, Z: -574.5149 },
		{ X: -1086.36975, Y: 28.29764, Z: -580.7711 },
		{ X: -1122.65247, Y: 28.1017685, Z: -564.902954 },
		{ X: -1108.0387, Y: 28.1782322, Z: -571.266 },
		{ X: -1093.38086, Y: 28.2424831, Z: -577.6524 },
		{ X: -1078.4845, Y: 28.3086414, Z: -584.1294 },
		{ X: -1063.71765, Y: 28.3951759, Z: -590.5391 },
		{ X: -1049.06946, Y: 28.5043125, Z: -596.9873 },
		{ X: -1034.12524, Y: 28.6135921, Z: -603.4646 },
		{ X: -1019.28253, Y: 28.7245979, Z: -609.9602 },
		{ X: -1004.62677, Y: 28.8422813, Z: -616.3333 },
		{ X: -989.6424, Y: 28.93084, Z: -622.8909 },
		{ X: -974.9372, Y: 29.0378342, Z: -629.269653 },
		{ X: -960.279541, Y: 29.1764889, Z: -635.722168 },
		{ X: -945.4294, Y: 29.2279434, Z: -642.1907 },
		{ X: -1115.62512, Y: 28.1387558, Z: -567.989258 },
		{ X: -1100.85925, Y: 28.2002945, Z: -574.5133 },
		{ X: -1086.35632, Y: 28.2858372, Z: -580.782654 },
		{ X: -1122.72632, Y: 28.09398, Z: -564.850769 },
		{ X: -1108.01428, Y: 28.1598816, Z: -571.28064 },
		{ X: -1093.39709, Y: 28.2387142, Z: -577.6266 },
		{ X: -1078.44177, Y: 28.3120346, Z: -584.170044 },
		{ X: -1063.71387, Y: 28.422884, Z: -590.5416 },
		{ X: -1049.04785, Y: 28.4924736, Z: -596.9972 },
		{ X: -1034.10791, Y: 28.6013451, Z: -603.4757 },
		{ X: -1019.32867, Y: 28.7221146, Z: -609.90686 },
		{ X: -1004.61072, Y: 28.8600578, Z: -616.3349 },
		{ X: -989.685364, Y: 28.94608, Z: -622.8788 },
		{ X: -974.9347, Y: 29.0758553, Z: -629.2872 },
		{ X: -960.2791, Y: 29.18244, Z: -635.702942 },
		{ X: -945.3927, Y: 29.2350521, Z: -642.2191 },
		{ X: -1115.61707, Y: 28.1319141, Z: -567.969666 },
		{ X: -1100.80432, Y: 28.1907711, Z: -574.55365 },
		{ X: -1086.35327, Y: 28.28109, Z: -580.8057 }
	]
};

export default function getPitBoxSpot(layoutId: number, slotId: number) {
	if (pitBoxSpots[layoutId] !== undefined) {
		return pitBoxSpots[layoutId][slotId];
	}
	return {X: -1, Y: -1, Z: -1};
}

export interface IRecordedLapsData {
	[index: string]: number[][];
}

const recordedLapsData: IRecordedLapsData = {
	'7111_7112_7982': [
		[
			96.237
		],
		[
			0
		],
		[
			0,
			1.606,
			3.089,
			6.071,
			7.571,
			10.573,
			15.074,
			18.083,
			21.099,
			24.122,
			28.666,
			33.223,
			34.744,
			36.267,
			39.318,
			40.845,
			43.906,
			46.972,
			51.583,
			54.664,
			59.297,
			60.843,
			62.391,
			67.039,
			70.143,
			74.811,
			77.929,
			81.049,
			82.606,
			85.723,
			87.282,
			90.405,
			93.546,
			95.119,
			98.276,
			101.438,
			106.19,
			109.366,
			114.138,
			115.731,
			117.325,
			122.114,
			125.315,
			128.521,
			133.335,
			136.548,
			141.372,
			142.981,
			144.59,
			149.424,
			152.652,
			157.502,
			160.74,
			163.982,
			165.604,
			168.857,
			172.116,
			173.748,
			177.015,
			178.649,
			181.918,
			185.189,
			190.101,
			191.74,
			193.379,
			198.302,
			199.945,
			201.59,
			206.53,
			209.829,
			214.785,
			218.093,
			223.062,
			226.381,
			228.043,
			231.371,
			234.709,
			236.379,
			238.051,
			243.072,
			246.42,
			249.769,
			251.444,
			254.798,
			258.156,
			259.836,
			263.198,
			264.881,
			268.244,
			271.607,
			276.645,
			280.01,
			285.076,
			286.77,
			288.466,
			293.559,
			296.961,
			302.079,
			305.496,
			308.912,
			310.621,
			314.039,
			315.749,
			319.173,
			324.319,
			326.037,
			331.203,
			336.371,
			339.791,
			344.844,
			346.506,
			348.155,
			351.425,
			353.046,
			356.258,
			359.431,
			364.118,
			367.194,
			371.739,
			373.236,
			374.724,
			379.133,
			382.024,
			386.286,
			389.078,
			391.831,
			393.191,
			395.881,
			397.211,
			399.842,
			403.721,
			406.262,
			408.768,
			412.458,
			413.671,
			414.875,
			418.433,
			419.602,
			420.762,
			424.186,
			426.421,
			429.701,
			431.84,
			433.942,
			437.025,
			438.034,
			439.034,
			441.978,
			442.941,
			443.894,
			446.701,
			448.527,
			450.32,
			451.203,
			452.944,
			454.649,
			455.487,
			457.137,
			457.951,
			459.552,
			461.117,
			463.405,
			464.892,
			467.053,
			467.764,
			468.473,
			470.544,
			471.882,
			473.874,
			475.152,
			476.392,
			478.195,
			478.78,
			479.928,
			481.049,
			481.6,
			482.682,
			483.725,
			484.749,
			485.759,
			486.26,
			487.257,
			488.256,
			488.754,
			489.745,
			490.239,
			491.226,
			492.213,
			493.788,
			494.846,
			496.434,
			496.965,
			497.503,
			499.379,
			500.633,
			502.521,
			503.963,
			505.411,
			506.137,
			507.604,
			508.416,
			510.042,
			511.671,
			512.488,
			514.222,
			515.952,
			518.317,
			518.992,
			519.668,
			521.697,
			522.378,
			522.972,
			524.118,
			524.691,
			525.841,
			526.996,
			528.705,
			529.839,
			531.541,
			532.107,
			532.669,
			534.312,
			535.397,
			537.002,
			538.052,
			539.599,
			540.623,
			541.642,
			542.15,
			543.153,
			543.648,
			544.635,
			545.617,
			547.081,
			547.567,
			548.04,
			549.445,
			549.909,
			550.372,
			551.752,
			552.674,
			554.095,
			555.035,
			555.971,
			557.375,
			557.919,
			558.521,
			560.323,
			560.924,
			561.525,
			563.632,
			565.32,
			567.013,
			567.864,
			569.572,
			571.284,
			572.143,
			573.603,
			574.284,
			575.65,
			577.023,
			578.867,
			580.037,
			581.804,
			582.398,
			582.958,
			584.562,
			585.639,
			587.275,
			588.326,
			589.876,
			590.922,
			591.449,
			592.513,
			593.577,
			594.112,
			594.649,
			596.282,
			597.388,
			599.173,
			600.393,
			601.626,
			602.248,
			603.551,
			604.219,
			605.564,
			606.923,
			608.995,
			610.393,
			612.506,
			613.214,
			613.929,
			616.095,
			617.555,
			619.772,
			621.265,
			622.758,
			623.517,
			625.046,
			625.816,
			627.368,
			629.722,
			631.309,
			632.923,
			634.534,
			636.154,
			636.968,
			639.413,
			640.239,
			641.068,
			642.737,
			643.575,
			645.25,
			646.925,
			649.468,
			651.182,
			653.781,
			654.656,
			655.533,
			658.186,
			659.967,
			662.663,
			664.476,
			667.219,
			669.067,
			669.997,
			671.866,
			673.755,
			674.71,
			676.631,
			678.565,
			681.488,
			682.469,
			683.451,
			686.389,
			687.375,
			688.363,
			691.342,
			693.343,
			695.359,
			698.406,
			700.461,
			703.566,
			704.61,
			705.657,
			708.815,
			710.937,
			714.13,
			716.266,
			718.409,
			719.494,
			721.671,
			723.862,
			724.968,
			728.302,
			730.53,
			732.767,
			736.089,
			738.306,
			741.606,
			742.711,
			743.817,
			747.149,
			749.383,
			752.753,
			755.012,
			757.28,
			758.417,
			760.7,
			761.846,
			762.993,
			766.449,
			767.606,
			768.765,
			772.254,
			774.592,
			776.938,
			778.114,
			780.477,
			782.849,
			784.04,
			786.434,
			787.634,
			790.044,
			792.463,
			796.12,
			798.574,
			802.293,
			803.538,
			804.782,
			808.524,
			811.018,
			814.771,
			817.284,
			819.807,
			821.074,
			823.615,
			826.173,
			827.457,
			830.034,
			831.328,
			833.946,
			836.531,
			840.456,
			843.08,
			845.727,
			848.353,
			849.665,
			852.267,
			853.581,
			856.221,
			858.87,
			862.855,
			865.515,
			869.533,
			870.866,
			872.201,
			876.203,
			878.901,
			883.004,
			885.739,
			888.486,
			889.862,
			892.623,
			894.007,
			896.773,
			899.545,
			900.927,
			903.696,
			906.462,
			910.621,
			913.406,
			917.628,
			919.042,
			920.459,
			924.723,
			927.545,
			930.369,
			934.597,
			937.434,
			941.71,
			943.142,
			944.575,
			948.899,
			950.324,
			951.78,
			956.166,
			959.133,
			963.543,
			966.512,
			968.001,
			970.983,
			973.973,
			975.494,
			978.49,
			981.451,
			985.907,
			987.378,
			988.85,
			993.31,
			994.793,
			996.29,
			1000.76,
			1003.744,
			1008.195,
			1011.165,
			1014.148,
			1015.646,
			1018.658,
			1020.166,
			1021.675,
			1027.731,
			1029.249,
			1033.83,
			1036.888,
			1039.948,
			1041.461,
			1044.486,
			1047.502,
			1049.011,
			1052.032,
			1053.544,
			1056.573,
			1059.608,
			1064.173,
			1067.224,
			1071.811,
			1073.343,
			1074.876,
			1079.484,
			1082.562,
			1087.189,
			1090.281,
			1093.376,
			1094.926,
			1098.029,
			1099.582,
			1102.692,
			1105.804,
			1107.36,
			1108.915,
			1113.567,
			1116.65,
			1119.715,
			1121.241,
			1124.284,
			1127.292,
			1128.784,
			1131.729,
			1133.18,
			1136.053,
			1138.888,
			1143.09,
			1145.855,
			1149.951,
			1151.299,
			1152.64,
			1156.602,
			1159.197,
			1163.016,
			1165.515,
			1167.978,
			1169.197,
			1171.607,
			1172.798,
			1175.152,
			1177.467,
			1178.61,
			1180.867,
			1183.083,
			1186.331,
			1187.388,
			1188.436,
			1190.503,
			1192.507,
			1193.493,
			1196.389,
			1198.26,
			1200.096,
			1202.785,
			1204.535,
			1207.092,
			1207.926,
			1208.751,
			1211.184,
			1212.75,
			1215.034,
			1216.514,
			1218.668,
			1220.072,
			1220.766,
			1222.136,
			1223.485,
			1224.153,
			1225.478,
			1226.843,
			1228.938,
			1230.329,
			1232.535,
			1233.292,
			1234.05,
			1236.324,
			1237.831,
			1240.093,
			1241.596,
			1243.074,
			1245.296,
			1246.04,
			1246.802,
			1249.1,
			1249.867,
			1250.636,
			1253.031,
			1254.648,
			1256.271,
			1257.118,
			1258.812,
			1260.508,
			1261.357,
			1263.061,
			1263.914,
			1265.623,
			1267.244,
			1269.642,
			1271.259,
			1273.489,
			1274.24,
			1274.994,
			1277.248,
			1278.738,
			1281.002,
			1282.517,
			1284.812,
			1286.36,
			1287.125,
			1288.664,
			1290.217,
			1290.999,
			1291.782,
			1294.146,
			1296.533,
			1297.371,
			1298.212,
			1299.903,
			1301.633,
			1302.603,
			1304.569,
			1305.549,
			1307.634,
			1309.826,
			1313.05,
			1315.156,
			1318.252,
			1319.269,
			1320.286,
			1323.38,
			1325.448,
			1328.455,
			1330.403,
			1333.213,
			1335.029,
			1335.917,
			1337.614,
			1339.254,
			1340.067,
			1341.674,
			1343.187,
			1345.427,
			1346.168,
			1346.906,
			1349.103,
			1349.831,
			1350.559,
			1352.02,
			1352.761,
			1354.242,
			1355.723,
			1357.966,
			1359.472,
			1361.732,
			1362.492,
			1363.251,
			1365.532,
			1366.293,
			1367.057,
			1369.354,
			1370.889,
			1372.429,
			1373.2,
			1374.746,
			1375.521,
			1377.076,
			1378.632,
			1379.413,
			1380.98,
			1382.547,
			1384.899,
			1386.479,
			1388.846,
			1389.639,
			1390.434,
			1392.852,
			1394.49,
			1396.977,
			1398.721,
			1400.478,
			1403.202,
			1404.14,
			1406.026,
			1407.966,
			1408.956,
			1409.951,
			1412.957,
			1415.011,
			1418.118,
			1420.205,
			1422.296,
			1423.326,
			1425.396,
			1426.437,
			1428.414,
			1430.368,
			1433.26,
			1435.154,
			1438.009,
			1438.963,
			1439.919,
			1442.806,
			1444.742,
			1447.67,
			1449.641,
			1452.63,
			1454.685,
			1455.717,
			1457.815,
			1460.006,
			1461.102,
			1463.323,
			1465.587,
			1467.867,
			1470.196,
			1471.35,
			1473.661,
			1475.968,
			1477.116,
			1479.392,
			1480.532,
			1482.789,
			1485.03,
			1488.279,
			1490.371,
			1493.405,
			1494.371,
			1495.33,
			1498.155,
			1499.972,
			1502.662,
			1504.421,
			1506.138,
			1506.992,
			1508.676,
			1509.504,
			1511.133,
			1512.724,
			1513.506,
			1515.042,
			1516.542,
			1518.723,
			1519.433,
			1520.133,
			1521.509,
			1522.857,
			1523.524,
			1524.847,
			1525.504,
			1526.811,
			1528.112,
			1530.057,
			1531.346,
			1533.269,
			1533.916,
			1534.549,
			1536.436,
			1537.061,
			1537.685,
			1539.544,
			1540.77,
			1542.592,
			1543.82,
			1545.039,
			1545.644,
			1546.85,
			1547.451,
			1548.712,
			1549.984,
			1551.878,
			1553.154,
			1555.127,
			1555.781,
			1556.434,
			1558.4,
			1559.715,
			1561.023,
			1562.986,
			1564.303,
			1566.264,
			1566.915,
			1567.566,
			1569.554,
			1570.215,
			1570.874,
			1572.849,
			1574.239,
			1575.626,
			1576.319,
			1577.705,
			1578.422,
			1579.871,
			1581.32,
			1582.044,
			1583.505,
			1584.976,
			1587.187,
			1588.704,
			1591.045,
			1591.826,
			1592.608,
			1595.06,
			1596.711,
			1599.073,
			1600.562,
			1602.054,
			1602.803,
			1604.194,
			1604.882,
			1606.26,
			1607.647,
			1608.342,
			1609.036,
			1611.129,
			1612.534,
			1613.922,
			1614.61,
			1615.994,
			1617.387,
			1618.088,
			1619.428,
			1620.101,
			1621.454,
			1622.82,
			1624.812,
			1626.144,
			1628.166,
			1628.852,
			1629.541,
			1631.628,
			1633.038,
			1635.234,
			1636.718,
			1638.218,
			1638.978,
			1640.51,
			1641.281,
			1642.835,
			1644.414,
			1645.21,
			1646.814,
			1648.434,
			1650.924,
			1652.601,
			1654.291,
			1655.982,
			1656.829,
			1658.532,
			1659.388,
			1661.106,
			1662.833,
			1665.44,
			1667.193,
			1669.837,
			1670.708,
			1671.612,
			1674.279,
			1676.093,
			1678.817,
			1680.635,
			1683.41,
			1685.277,
			1686.215,
			1688.1,
			1689.994,
			1690.946,
			1692.858,
			1694.781,
			1697.687,
			1699.637,
			1702.583,
			1703.571,
			1704.56,
			1707.545,
			1709.549,
			1711.565,
			1714.609,
			1716.652,
			1719.738,
			1720.773,
			1721.807,
			1724.916,
			1725.96,
			1727.005,
			1730.173,
			1732.312,
			1734.475,
			1735.579,
			1737.796,
			1738.908,
			1741.155,
			1743.412,
			1744.539,
			1746.787,
			1749.048,
			1752.502,
			1754.821,
			1758.329,
			1759.496,
			1760.651,
			1764.131,
			1766.452,
			1769.949,
			1772.297,
			1774.658,
			1775.849,
			1778.237,
			1779.434,
			1780.635,
			1784.251,
			1785.447,
			1786.644,
			1790.245,
			1792.648,
			1795.063,
			1796.275,
			1798.707,
			1801.134,
			1802.348,
			1804.78,
			1805.988,
			1808.412,
			1810.846,
			1814.515,
			1816.981,
			1820.698,
			1821.942,
			1823.188,
			1826.942,
			1829.455,
			1833.245,
			1835.784,
			1839.611,
			1842.174,
			1843.458,
			1846.033,
			1848.616,
			1849.911,
			1851.208,
			1855.11,
			1857.718,
			1860.327,
			1861.634,
			1864.252,
			1866.88,
			1868.197,
			1870.838,
			1872.161,
			1874.814,
			1877.473,
			1881.477,
			1884.155,
			1888.182,
			1889.527,
			1890.873,
			1894.941,
			1897.648,
			1901.74,
			1904.496,
			1907.295,
			1908.704,
			1911.533,
			1912.954,
			1915.809,
			1920.159,
			1923.152,
			1926.156,
			1930.659,
			1933.551,
			1936.421,
			1939.244,
			1940.658,
			1943.493,
			1944.913,
			1947.766,
			1950.629,
			1954.94,
			1957.82,
			1962.148,
			1963.592,
			1965.037,
			1969.38,
			1972.285,
			1976.656,
			1979.557,
			1983.96,
			1986.906,
			1989.86,
			1991.339,
			1994.304,
			1995.788,
			1998.762,
			2001.743,
			2006.226,
			2009.222,
			2013.727,
			2015.232,
			2016.737,
			2021.263,
			2024.285,
			2028.832,
			2031.871,
			2034.918,
			2036.444,
			2039.5,
			2041.029,
			2044.087,
			2047.14,
			2048.661,
			2050.176,
			2054.681,
			2057.642,
			2060.565,
			2062.012,
			2064.876,
			2067.701,
			2069.098,
			2071.865,
			2073.234,
			2075.946,
			2078.621,
			2082.564,
			2085.148,
			2088.957,
			2090.208,
			2091.451,
			2095.144,
			2097.527,
			2101.069,
			2103.391,
			2106.807,
			2109.057,
			2110.159,
			2112.335,
			2115.533,
			2117.622,
			2119.678,
			2121.706,
			2123.707,
			2124.698,
			2127.635,
			2128.6,
			2129.56,
			2132.413,
			2134.285,
			2136.146,
			2138.929,
			2140.779,
			2143.567,
			2144.502,
			2145.436,
			2149.203,
			2150.152,
			2153.012,
			2154.947,
			2156.884,
			2157.863,
			2159.848,
			2160.841,
			2162.846,
			2165.946,
			2168.037,
			2170.194,
			2173.437,
			2175.601,
			2178.837,
			2179.906,
			2180.975,
			2183.101,
			2184.114,
			2186.146,
			2189.142,
			2191.099,
			2193.063,
			2196.003,
			2196.987,
			2197.973,
			2200.937,
			2202.924,
			2205.917,
			2207.924,
			2209.95,
			2210.966,
			2213.006,
			2214.037,
			2216.108,
			2219.246,
			2221.367,
			2223.503,
			2226.76,
			2228.943,
			2232.234,
			2233.334,
			2234.428,
			2237.718,
			2239.92,
			2243.237,
			2245.439,
			2247.687,
			2248.802,
			2251.034,
			2252.154,
			2253.275,
			2256.638,
			2258.888,
			2262.243,
			2264.519,
			2267.93,
			2270.217,
			2272.498,
			2273.651,
			2275.963,
			2277.123,
			2279.447,
			2281.779,
			2285.292,
			2287.644,
			2291.19,
			2292.377,
			2293.566,
			2297.15,
			2299.55,
			2303.169,
			2305.593,
			2308.025,
			2309.244,
			2311.69,
			2312.916,
			2315.375,
			2317.842,
			2319.079,
			2321.559,
			2324.048,
			2327.797,
			2330.307,
			2332.826,
			2335.353,
			2336.62,
			2339.159,
			2340.432,
			2342.985,
			2345.545,
			2349.4,
			2351.98,
			2355.864,
			2357.163,
			2358.462,
			2362.371,
			2364.977,
			2368.896,
			2371.517,
			2374.145,
			2375.461,
			2378.097,
			2379.418,
			2382.063,
			2384.716,
			2386.044,
			2388.706,
			2391.374,
			2395.387,
			2396.729,
			2398.073,
			2402.12,
			2403.476,
			2404.833,
			2408.939,
			2411.695,
			2414.466,
			2418.654,
			2421.465,
			2425.719,
			2427.146,
			2428.573,
			2432.857,
			2434.285,
			2435.715,
			2439.99,
			2442.826,
			2445.659,
			2447.069,
			2449.889,
			2451.303,
			2454.14,
			2458.406,
			2461.256,
			2464.104,
			2468.385,
			2471.246,
			2475.548,
			2476.986,
			2478.425,
			2482.753,
			2485.649,
			2490.008,
			2492.921,
			2495.842,
			2500.228,
			2501.687,
			2503.144,
			2508.986,
			2510.453,
			2514.855,
			2517.774,
			2520.666,
			2522.1,
			2524.937,
			2527.737,
			2529.126,
			2531.875,
			2533.236,
			2535.929,
			2538.58,
			2542.476,
			2545.024,
			2548.776,
			2550.008,
			2551.231,
			2554.847,
			2556.036,
			2557.216,
			2560.704,
			2562.982,
			2565.222,
			2566.328,
			2568.512,
			2569.591,
			2571.721,
			2573.813,
			2574.845,
			2576.882,
			2578.881,
			2580.843,
			2582.767,
			2583.717,
			2585.588,
			2587.424,
			2588.332,
			2591.034,
			2592.798,
			2594.54,
			2597.117,
			2598.811,
			2601.324,
			2602.158,
			2602.99,
			2605.47,
			2607.116,
			2609.573,
			2611.202,
			2612.828,
			2613.642,
			2615.266,
			2616.077,
			2617.7,
			2619.341,
			2620.165,
			2621.812,
			2623.466,
			2626.003,
			2626.849,
			2627.696,
			2630.268,
			2631.128,
			2631.99,
			2633.719,
			2634.589,
			2636.33,
			2638.958,
			2640.742,
			2642.528,
			2645.265,
			2646.189,
			2647.113,
			2649.895,
			2651.756,
			2654.536,
			2656.368,
			2658.199,
			2659.115,
			2660.939,
			2662.762,
			2663.674,
			2666.41,
			2668.23,
			2670.054,
			2672.787,
			2674.609,
			2677.342,
			2678.25,
			2679.159,
			2681.892,
			2683.709,
			2685.542,
			2688.298,
			2690.142,
			2692.918,
			2693.843,
			2694.768,
			2697.542,
			2699.393,
			2702.136,
			2703.975,
			2705.772,
			2706.661,
			2708.431,
			2710.213,
			2711.09,
			2712.855,
			2713.743,
			2715.526,
			2717.321,
			2720.037,
			2721.863,
			2724.628,
			2725.555,
			2726.484,
			2729.271,
			2731.117,
			2733.88,
			2735.702,
			2737.517,
			2738.422,
			2740.228,
			2741.13,
			2742.929,
			2744.72,
			2745.616,
			2747.404,
			2749.185,
			2750.965,
			2752.747,
			2753.635,
			2755.406,
			2757.177,
			2758.059,
			2760.695,
			2762.446,
			2764.191,
			2766.805,
			2768.546,
			2771.157,
			2772.034,
			2772.91,
			2775.533,
			2777.3,
			2779.948,
			2781.713,
			2783.479,
			2784.361,
			2786.134,
			2787.03,
			2788.822,
			2790.616,
			2791.533,
			2793.373,
			2795.216,
			2797.993,
			2798.92,
			2799.85,
			2801.712,
			2803.576,
			2804.51,
			2807.325,
			2809.211,
			2811.1,
			2813.912,
			2815.789,
			2818.597,
			2819.532,
			2820.468,
			2823.272,
			2825.142,
			2827.963,
			2829.853,
			2832.713,
			2834.638,
			2835.602,
			2837.528,
			2839.452,
			2840.415,
			2842.346,
			2844.281,
			2847.199,
			2849.173,
			2852.14,
			2853.128,
			2854.118,
			2857.089,
			2859.039,
			2861.977,
			2863.908,
			2866.823,
			2868.783,
			2869.767,
			2870.753,
			2873.741,
			2874.746,
			2875.753,
			2878.774,
			2880.809,
			2882.843,
			2883.852,
			2885.866,
			2887.862,
			2888.846,
			2890.799,
			2891.768,
			2893.683,
			2895.583,
			2898.368,
			2900.203,
			2902.935,
			2903.842,
			2904.747,
			2907.45,
			2909.236,
			2911.901,
			2913.661,
			2915.407,
			2916.278,
			2918.016,
			2918.876,
			2919.735,
			2922.311,
			2923.168,
			2924.025,
			2926.593,
			2928.319,
			2930.099,
			2930.989,
			2932.77,
			2934.622,
			2935.553,
			2937.419,
			2938.352,
			2940.217,
			2942.084,
			2944.903,
			2946.79,
			2949.651,
			2950.613,
			2951.577,
			2954.48,
			2956.424,
			2959.36,
			2961.329,
			2963.309,
			2964.303,
			2966.299,
			2967.301,
			2969.316,
			2971.346,
			2972.365,
			2974.409,
			2976.455,
			2978.514,
			2980.552,
			2981.572,
			2983.623,
			2985.642,
			2986.651,
			2988.679,
			2989.68,
			2991.673,
			2993.676,
			2996.704,
			2998.735,
			3001.814,
			3002.841,
			3003.87,
			3006.985,
			3009.076,
			3012.243,
			3014.366,
			3017.583,
			3019.74,
			3020.825,
			3023.003,
			3025.192,
			3026.294,
			3028.505,
			3030.709,
			3032.943,
			3036.329,
			3039.717,
			3040.85,
			3041.965,
			3045.384,
			3047.679,
			3051.148,
			3053.485,
			3055.83,
			3059.36,
			3060.537,
			3061.709,
			3065.238,
			3066.414,
			3067.591,
			3071.138,
			3073.515,
			3075.901,
			3077.099,
			3079.5,
			3081.911,
			3083.121,
			3086.764,
			3089.204,
			3091.655,
			3095.37,
			3097.851,
			3101.594,
			3102.853,
			3104.114,
			3107.921,
			3110.478,
			3114.334,
			3116.917,
			3119.512,
			3123.433,
			3124.749,
			3127.393,
			3130.062,
			3131.399,
			3132.745,
			3136.799,
			3139.501,
			3142.208,
			3143.554,
			3146.246,
			3148.948,
			3150.303,
			3153.03,
			3154.41,
			3157.175,
			3159.95,
			3164.107,
			3166.872,
			3170.956,
			3172.317,
			3173.646,
			3177.731,
			3180.437,
			3184.543,
			3187.289,
			3190.045,
			3191.412,
			3194.133,
			3195.486,
			3198.197,
			3200.899,
			3202.253,
			3203.609,
			3207.67,
			3210.406,
			3213.15,
			3214.527,
			3217.31,
			3220.079,
			3221.467,
			3224.248,
			3225.642,
			3228.413,
			3231.215,
			3235.431,
			3238.251,
			3242.493,
			3243.91,
			3245.329,
			3249.594,
			3252.446,
			3256.734,
			3259.598,
			3262.458,
			3263.891,
			3266.751,
			3268.182,
			3271.054,
			3273.937,
			3275.383,
			3278.286,
			3281.194,
			3285.564,
			3288.484,
			3291.413,
			3294.351,
			3295.823,
			3298.774,
			3300.251,
			3303.209,
			3306.174,
			3310.629,
			3313.606,
			3318.08,
			3319.574,
			3321.068,
			3325.559,
			3328.557,
			3333.065,
			3336.078,
			3339.107,
			3340.623,
			3343.673,
			3346.735,
			3348.275,
			3351.36,
			3352.905,
			3355.999,
			3359.081,
			3363.701,
			3366.784,
			3371.418,
			3372.966,
			3374.516,
			3379.172,
			3382.283,
			3386.958,
			3390.082,
			3393.211,
			3394.777,
			3397.914,
			3399.484,
			3401.056,
			3405.778,
			3407.355,
			3408.933,
			3413.674,
			3416.841,
			3420.012,
			3421.6,
			3424.777,
			3427.96,
			3429.553,
			3432.738,
			3434.331,
			3437.522,
			3440.717,
			3445.518,
			3448.724,
			3453.542,
			3455.15,
			3456.758,
			3461.586,
			3464.802,
			3469.614,
			3472.802,
			3477.535,
			3480.649,
			3482.192,
			3483.726,
			3488.271,
			3489.768,
			3492.735,
			3495.666,
			3498.557,
			3501.41,
			3502.821,
			3505.616,
			3508.375,
			3509.74,
			3512.441,
			3513.778,
			3516.421,
			3519.026,
			3522.864,
			3525.375,
			3529.075,
			3530.289,
			3531.493,
			3535.054,
			3537.386,
			3540.813,
			3543.051,
			3545.25,
			3546.336,
			3548.481,
			3549.54,
			3551.63,
			3553.687,
			3554.701,
			3556.72,
			3558.689,
			3560.621,
			3563.457,
			3565.307,
			3567.126,
			3568.023,
			3569.797,
			3570.675,
			3572.398,
			3574.927,
			3576.59,
			3578.223,
			3580.652,
			3581.459,
			3582.27,
			3585.512,
			3586.321,
			3588.782,
			3590.425,
			3592.072,
			3592.906,
			3594.574,
			3596.243,
			3597.082,
			3599.64,
			3601.348,
			3603.096,
			3605.753,
			3607.55,
			3610.324,
			3611.252,
			3612.181,
			3614.978,
			3616.852,
			3619.647,
			3621.516,
			3623.39,
			3624.327,
			3626.205,
			3627.146,
			3628.098,
			3631.94,
			3632.9,
			3635.752,
			3637.655,
			3639.541,
			3640.482,
			3642.364,
			3644.262,
			3645.213,
			3647.115,
			3648.065,
			3649.954,
			3651.839,
			3654.638,
			3656.487,
			3659.255,
			3660.173,
			3661.092,
			3663.849,
			3665.687,
			3668.446,
			3670.275,
			3672.104,
			3673.02,
			3674.837,
			3675.741,
			3677.552,
			3679.353,
			3680.243,
			3682.024,
			3683.81,
			3685.595,
			3687.384,
			3688.281,
			3690.088,
			3691.912,
			3692.826,
			3695.579,
			3697.422,
			3699.274,
			3702.076,
			3703.956,
			3706.813,
			3707.772,
			3708.733,
			3711.613,
			3713.541,
			3716.45,
			3718.403,
			3720.369,
			3721.367,
			3723.353,
			3725.382,
			3726.395,
			3728.428,
			3729.449,
			3731.485,
			3733.55,
			3736.681,
			3738.769,
			3740.85,
			3742.967,
			3744.015,
			3746.112,
			3747.162,
			3749.248,
			3751.373,
			3754.534,
			3756.631,
			3759.74,
			3760.767,
			3761.786,
			3764.801,
			3766.772,
			3769.667,
			3771.569,
			3773.449,
			3774.384,
			3776.248,
			3777.177,
			3779.031,
			3780.878,
			3781.801,
			3783.643,
			3785.479,
			3788.242,
			3790.084,
			3792.869,
			3793.803,
			3794.738,
			3797.562,
			3799.466,
			3801.37,
			3804.27,
			3806.204,
			3807.179,
			3809.146,
			3810.128,
			3811.11,
			3814.089,
			3815.083,
			3816.078,
			3819.097,
			3821.117,
			3824.134,
			3826.14,
			3827.14,
			3829.12,
			3832.086,
			3834.054,
			3836.018,
			3838.953,
			3840.903,
			3843.808,
			3844.771,
			3845.732,
			3848.616,
			3850.534,
			3853.412,
			3855.332,
			3857.255,
			3858.219,
			3860.15,
			3861.117,
			3862.085,
			3865,
			3865.974,
			3867.926,
			3869.884,
			3871.849,
			3873.818,
			3874.803,
			3876.778,
			3878.757,
			3879.743,
			3881.719,
			3882.708,
			3884.684,
			3886.659,
			3889.62,
			3891.589,
			3894.542,
			3895.522,
			3896.502,
			3899.446,
			3901.401,
			3904.339,
			3906.288,
			3909.215,
			3911.153,
			3912.122,
			3914.063,
			3915.989,
			3916.952,
			3918.882,
			3920.818,
			3923.734,
			3924.712,
			3925.7,
			3927.682,
			3929.675,
			3930.678,
			3932.691,
			3933.701,
			3935.733,
			3938.807,
			3940.876,
			3942.961,
			3946.111,
			3947.167,
			3948.225,
			3951.417,
			3953.541,
			3956.768,
			3958.943,
			3961.109,
			3962.195,
			3964.354,
			3965.445,
			3967.641,
			3969.848,
			3970.955,
			3973.172,
			3975.399,
			3978.759,
			3979.884,
			3981.011,
			3984.414,
			3985.554,
			3986.695,
			3990.133,
			3992.435,
			3995.919,
			3998.231,
			4000.529,
			4004.034,
			4005.181,
			4006.349,
			4009.851,
			4012.197,
			4015.73,
			4018.105,
			4021.695,
			4024.11,
			4026.535,
			4027.752,
			4030.193,
			4031.417,
			4033.873,
			4036.338,
			4040.054,
			4042.543,
			4046.29,
			4047.543,
			4048.8,
			4052.585,
			4055.131,
			4058.967,
			4061.535,
			4064.107,
			4065.393,
			4067.971,
			4069.263,
			4070.556,
			4074.448,
			4075.75,
			4078.355,
			4080.96,
			4083.573,
			4086.194,
			4087.507,
			4090.14,
			4092.78,
			4094.102,
			4098.076,
			4100.734,
			4103.399,
			4107.412,
			4110.097,
			4114.138,
			4115.489,
			4116.842,
			4120.906,
			4123.619,
			4127.699,
			4130.431,
			4134.553,
			4137.311,
			4138.693,
			4141.463,
			4144.24,
			4145.63,
			4148.418,
			4151.206,
			4154.003,
			4158.231,
			4161.059,
			4163.893,
			4165.312,
			4168.153,
			4169.577,
			4172.427,
			4175.284,
			4179.585,
			4182.461,
			4186.829,
			4188.293,
			4189.735,
			4194.043,
			4196.925,
			4201.328,
			4204.298,
			4207.243,
			4208.718,
			4211.668,
			4213.146,
			4216.111,
			4219.083,
			4220.562,
			4223.522,
			4224.095
		],
		[
			0,
			0.028,
			0.046,
			0.107,
			0.138,
			0.169,
			0.247,
			0.292,
			0.355,
			0.415,
			0.478,
			0.541,
			0.57,
			0.603,
			0.666,
			0.686,
			0.726,
			0.789,
			0.851,
			0.897,
			0.973,
			1.005,
			1.038,
			1.099,
			1.16,
			1.225,
			1.286,
			1.33,
			1.347,
			1.41,
			1.439,
			1.471,
			1.532,
			1.547,
			1.594,
			1.656,
			1.719,
			1.766,
			1.844,
			1.875,
			1.905,
			1.982,
			2.029,
			2.09,
			2.154,
			2.2,
			2.277,
			2.308,
			2.339,
			2.399,
			2.464,
			2.525,
			2.587,
			2.633,
			2.648,
			2.71,
			2.748,
			2.773,
			2.836,
			2.852,
			2.898,
			2.959,
			3.021,
			3.065,
			3.083,
			3.145,
			3.176,
			3.207,
			3.269,
			3.331,
			3.395,
			3.456,
			3.518,
			3.579,
			3.609,
			3.641,
			3.704,
			3.719,
			3.764,
			3.827,
			3.89,
			3.936,
			3.952,
			4.013,
			4.044,
			4.074,
			4.137,
			4.153,
			4.2,
			4.26,
			4.323,
			4.371,
			4.446,
			4.479,
			4.509,
			4.573,
			4.633,
			4.697,
			4.759,
			4.809,
			4.821,
			4.882,
			4.913,
			4.944,
			5.022,
			5.066,
			5.131,
			5.192,
			5.254,
			5.316,
			5.346,
			5.377,
			5.439,
			5.456,
			5.502,
			5.565,
			5.624,
			5.672,
			5.75,
			5.78,
			5.813,
			5.889,
			5.934,
			5.999,
			6.06,
			6.106,
			6.12,
			6.183,
			6.215,
			6.246,
			6.324,
			6.37,
			6.431,
			6.493,
			6.54,
			6.556,
			6.616,
			6.647,
			6.679,
			6.743,
			6.805,
			6.871,
			6.927,
			6.975,
			7.05,
			7.084,
			7.113,
			7.175,
			7.192,
			7.239,
			7.298,
			7.363,
			7.409,
			7.424,
			7.487,
			7.517,
			7.548,
			7.609,
			7.625,
			7.671,
			7.734,
			7.795,
			7.843,
			7.921,
			7.95,
			7.981,
			8.043,
			8.107,
			8.169,
			8.231,
			8.291,
			8.354,
			8.386,
			8.417,
			8.478,
			8.493,
			8.541,
			8.6,
			8.663,
			8.71,
			8.726,
			8.789,
			8.82,
			8.85,
			8.913,
			8.93,
			8.973,
			9.037,
			9.097,
			9.16,
			9.222,
			9.251,
			9.285,
			9.347,
			9.409,
			9.471,
			9.532,
			9.578,
			9.594,
			9.655,
			9.687,
			9.719,
			9.781,
			9.794,
			9.843,
			9.904,
			9.966,
			10.014,
			10.027,
			10.091,
			10.121,
			10.154,
			10.213,
			10.23,
			10.275,
			10.34,
			10.401,
			10.448,
			10.525,
			10.557,
			10.587,
			10.664,
			10.709,
			10.774,
			10.834,
			10.898,
			10.959,
			10.993,
			11.021,
			11.084,
			11.096,
			11.145,
			11.208,
			11.267,
			11.315,
			11.33,
			11.392,
			11.422,
			11.454,
			11.516,
			11.579,
			11.641,
			11.704,
			11.75,
			11.826,
			11.859,
			11.889,
			11.949,
			11.967,
			12.012,
			12.075,
			12.137,
			12.182,
			12.198,
			12.262,
			12.292,
			12.324,
			12.382,
			12.399,
			12.446,
			12.51,
			12.571,
			12.617,
			12.695,
			12.725,
			12.756,
			12.833,
			12.881,
			12.943,
			13.004,
			13.066,
			13.129,
			13.161,
			13.192,
			13.254,
			13.269,
			13.315,
			13.376,
			13.439,
			13.501,
			13.562,
			13.594,
			13.626,
			13.688,
			13.702,
			13.748,
			13.81,
			13.874,
			13.921,
			13.998,
			14.028,
			14.06,
			14.121,
			14.181,
			14.246,
			14.308,
			14.354,
			14.37,
			14.43,
			14.461,
			14.493,
			14.57,
			14.617,
			14.679,
			14.74,
			14.789,
			14.804,
			14.866,
			14.897,
			14.928,
			14.989,
			15.004,
			15.052,
			15.112,
			15.176,
			15.237,
			15.3,
			15.33,
			15.362,
			15.424,
			15.486,
			15.548,
			15.61,
			15.67,
			15.733,
			15.764,
			15.794,
			15.857,
			15.874,
			15.917,
			15.98,
			16.044,
			16.089,
			16.106,
			16.166,
			16.199,
			16.228,
			16.308,
			16.353,
			16.415,
			16.479,
			16.525,
			16.602,
			16.631,
			16.663,
			16.741,
			16.789,
			16.853,
			16.913,
			16.958,
			16.975,
			17.037,
			17.067,
			17.097,
			17.179,
			17.223,
			17.283,
			17.346,
			17.392,
			17.467,
			17.5,
			17.531,
			17.593,
			17.656,
			17.717,
			17.78,
			17.826,
			17.842,
			17.903,
			17.935,
			17.965,
			18.028,
			18.044,
			18.089,
			18.15,
			18.213,
			18.259,
			18.275,
			18.339,
			18.37,
			18.398,
			18.46,
			18.476,
			18.525,
			18.587,
			18.648,
			18.694,
			18.772,
			18.803,
			18.833,
			18.896,
			18.957,
			19.02,
			19.081,
			19.128,
			19.145,
			19.205,
			19.241,
			19.269,
			19.33,
			19.345,
			19.392,
			19.455,
			19.516,
			19.577,
			19.639,
			19.671,
			19.702,
			19.763,
			19.779,
			19.827,
			19.887,
			19.95,
			19.996,
			20.074,
			20.106,
			20.137,
			20.198,
			20.261,
			20.322,
			20.385,
			20.431,
			20.446,
			20.508,
			20.539,
			20.57,
			20.632,
			20.648,
			20.694,
			20.757,
			20.818,
			20.881,
			20.943,
			20.973,
			21.006,
			21.081,
			21.128,
			21.19,
			21.25,
			21.314,
			21.375,
			21.407,
			21.438,
			21.499,
			21.516,
			21.562,
			21.624,
			21.687,
			21.747,
			21.812,
			21.84,
			21.873,
			21.933,
			21.949,
			21.995,
			22.061,
			22.12,
			22.165,
			22.182,
			22.243,
			22.275,
			22.306,
			22.369,
			22.431,
			22.492,
			22.556,
			22.601,
			22.617,
			22.679,
			22.71,
			22.739,
			22.817,
			22.865,
			22.927,
			22.987,
			23.033,
			23.05,
			23.113,
			23.143,
			23.174,
			23.237,
			23.252,
			23.299,
			23.366,
			23.423,
			23.47,
			23.547,
			23.578,
			23.608,
			23.687,
			23.731,
			23.796,
			23.856,
			23.904,
			23.917,
			23.981,
			24.012,
			24.043,
			24.106,
			24.12,
			24.165,
			24.23,
			24.29,
			24.339,
			24.353,
			24.413,
			24.446,
			24.475,
			24.538,
			24.554,
			24.599,
			24.663,
			24.724,
			24.786,
			24.848,
			24.88,
			24.91,
			24.974,
			25.036,
			25.097,
			25.16,
			25.206,
			25.221,
			25.281,
			25.313,
			25.344,
			25.407,
			25.425,
			25.469,
			25.532,
			25.593,
			25.638,
			25.654,
			25.715,
			25.749,
			25.779,
			25.856,
			25.903,
			25.965,
			26.027,
			26.074,
			26.152,
			26.182,
			26.214,
			26.276,
			26.337,
			26.398,
			26.462,
			26.524,
			26.585,
			26.616,
			26.648,
			26.708,
			26.725,
			26.771,
			26.831,
			26.896,
			26.943,
			27.019,
			27.048,
			27.082,
			27.142,
			27.207,
			27.268,
			27.329,
			27.377,
			27.454,
			27.49,
			27.514,
			27.577,
			27.593,
			27.641,
			27.703,
			27.764,
			27.809,
			27.827,
			27.888,
			27.918,
			27.948,
			28.011,
			28.028,
			28.074,
			28.137,
			28.199,
			28.243,
			28.322,
			28.354,
			28.384,
			28.46,
			28.509,
			28.571,
			28.631,
			28.693,
			28.754,
			28.787,
			28.817,
			28.88,
			28.896,
			28.94,
			29.005,
			29.066,
			29.111,
			29.126,
			29.19,
			29.221,
			29.252,
			29.313,
			29.33,
			29.374,
			29.438,
			29.498,
			29.55,
			29.624,
			29.655,
			29.686,
			29.762,
			29.809,
			29.872,
			29.932,
			29.996,
			30.057,
			30.089,
			30.121,
			30.181,
			30.196,
			30.243,
			30.308,
			30.366,
			30.415,
			30.43,
			30.493,
			30.522,
			30.554,
			30.615,
			30.633,
			30.677,
			30.739,
			30.802,
			30.848,
			30.927,
			30.956,
			30.989,
			31.051,
			31.067,
			31.112,
			31.176,
			31.236,
			31.282,
			31.299,
			31.359,
			31.39,
			31.421,
			31.483,
			31.498,
			31.546,
			31.607,
			31.67,
			31.716,
			31.793,
			31.824,
			31.857,
			31.917,
			31.981,
			32.042,
			32.105,
			32.152,
			32.227,
			32.258,
			32.291,
			32.35,
			32.369,
			32.415,
			32.478,
			32.539,
			32.6,
			32.661,
			32.694,
			32.723,
			32.787,
			32.803,
			32.849,
			32.91,
			32.971,
			33.033,
			33.095,
			33.128,
			33.159,
			33.22,
			33.282,
			33.346,
			33.407,
			33.467,
			33.532,
			33.561,
			33.594,
			33.655,
			33.674,
			33.718,
			33.78,
			33.84,
			33.887,
			33.903,
			33.964,
			33.996,
			34.027,
			34.089,
			34.104,
			34.152,
			34.214,
			34.274,
			34.322,
			34.399,
			34.43,
			34.46,
			34.538,
			34.585,
			34.646,
			34.71,
			34.754,
			34.771,
			34.833,
			34.865,
			34.893,
			34.955,
			34.972,
			35.019,
			35.08,
			35.142,
			35.189,
			35.206,
			35.265,
			35.298,
			35.327,
			35.389,
			35.406,
			35.453,
			35.514,
			35.578,
			35.622,
			35.702,
			35.736,
			35.762,
			35.823,
			35.841,
			35.888,
			35.949,
			36.01,
			36.073,
			36.133,
			36.166,
			36.196,
			36.258,
			36.275,
			36.321,
			36.383,
			36.444,
			36.491,
			36.569,
			36.599,
			36.63,
			36.693,
			36.754,
			36.816,
			36.878,
			36.926,
			37.003,
			37.032,
			37.065,
			37.125,
			37.142,
			37.187,
			37.249,
			37.313,
			37.359,
			37.373,
			37.438,
			37.465,
			37.498,
			37.561,
			37.577,
			37.622,
			37.684,
			37.746,
			37.797,
			37.871,
			37.901,
			37.933,
			38.01,
			38.057,
			38.119,
			38.18,
			38.226,
			38.243,
			38.304,
			38.333,
			38.367,
			38.428,
			38.444,
			38.49,
			38.553,
			38.613,
			38.66,
			38.677,
			38.738,
			38.769,
			38.798,
			38.863,
			38.876,
			38.926,
			38.988,
			39.05,
			39.096,
			39.172,
			39.204,
			39.236,
			39.312,
			39.36,
			39.425,
			39.482,
			39.528,
			39.545,
			39.608,
			39.638,
			39.668,
			39.731,
			39.745,
			39.793,
			39.858,
			39.916,
			39.978,
			40.041,
			40.07,
			40.103,
			40.165,
			40.18,
			40.226,
			40.288,
			40.349,
			40.397,
			40.474,
			40.507,
			40.537,
			40.599,
			40.661,
			40.722,
			40.782,
			40.848,
			40.91,
			40.939,
			40.971,
			41.033,
			41.047,
			41.095,
			41.157,
			41.219,
			41.28,
			41.342,
			41.374,
			41.405,
			41.466,
			41.527,
			41.591,
			41.654,
			41.713,
			41.777,
			41.809,
			41.839,
			41.899,
			41.921,
			41.962,
			42.024,
			42.087,
			42.133,
			42.149,
			42.211,
			42.241,
			42.271,
			42.334,
			42.351,
			42.398,
			42.458,
			42.521,
			42.582,
			42.644,
			42.674,
			42.708,
			42.77,
			42.832,
			42.894,
			42.955,
			42.999,
			43.017,
			43.079,
			43.11,
			43.14,
			43.203,
			43.219,
			43.264,
			43.328,
			43.389,
			43.433,
			43.449,
			43.514,
			43.543,
			43.574,
			43.637,
			43.652,
			43.698,
			43.762,
			43.822,
			43.869,
			43.948,
			43.982,
			44.008,
			44.071,
			44.132,
			44.194,
			44.257,
			44.317,
			44.381,
			44.412,
			44.443,
			44.504,
			44.522,
			44.565,
			44.631,
			44.691,
			44.738,
			44.754,
			44.815,
			44.846,
			44.877,
			44.939,
			44.953,
			44.999,
			45.064,
			45.124,
			45.171,
			45.25,
			45.28,
			45.31,
			45.374,
			45.433,
			45.498,
			45.56,
			45.606,
			45.622,
			45.681,
			45.715,
			45.744,
			45.822,
			45.87,
			45.93,
			45.993,
			46.054,
			46.116,
			46.147,
			46.179,
			46.24,
			46.256,
			46.304,
			46.365,
			46.427,
			46.474,
			46.551,
			46.582,
			46.613,
			46.676,
			46.738,
			46.799,
			46.862,
			46.923,
			46.986,
			47.016,
			47.048,
			47.11,
			47.124,
			47.17,
			47.233,
			47.296,
			47.343,
			47.417,
			47.45,
			47.48,
			47.543,
			47.606,
			47.667,
			47.729,
			47.776,
			47.792,
			47.854,
			47.885,
			47.916,
			47.979,
			47.993,
			48.04,
			48.106,
			48.163,
			48.21,
			48.226,
			48.286,
			48.319,
			48.348,
			48.413,
			48.427,
			48.474,
			48.537,
			48.598,
			48.659,
			48.721,
			48.753,
			48.782,
			48.845,
			48.909,
			48.971,
			49.033,
			49.094,
			49.156,
			49.187,
			49.217,
			49.296,
			49.342,
			49.404,
			49.464,
			49.513,
			49.529,
			49.591,
			49.62,
			49.649,
			49.73,
			49.775,
			49.839,
			49.899,
			49.96,
			50.023,
			50.056,
			50.087,
			50.167,
			50.209,
			50.272,
			50.334,
			50.382,
			50.396,
			50.459,
			50.488,
			50.52,
			50.599,
			50.643,
			50.706,
			50.769,
			50.83,
			50.891,
			50.924,
			50.954,
			51.015,
			51.032,
			51.077,
			51.141,
			51.202,
			51.263,
			51.325,
			51.356,
			51.389,
			51.451,
			51.513,
			51.575,
			51.634,
			51.682,
			51.698,
			51.759,
			51.79,
			51.823,
			51.899,
			51.946,
			52.008,
			52.07,
			52.118,
			52.193,
			52.23,
			52.256,
			52.334,
			52.381,
			52.443,
			52.505,
			52.548,
			52.566,
			52.627,
			52.658,
			52.689,
			52.751,
			52.816,
			52.878,
			52.939,
			53.002,
			53.062,
			53.093,
			53.124,
			53.184,
			53.202,
			53.248,
			53.31,
			53.374,
			53.433,
			53.496,
			53.528,
			53.56,
			53.621,
			53.684,
			53.745,
			53.807,
			53.853,
			53.867,
			53.93,
			53.961,
			53.994,
			54.055,
			54.071,
			54.117,
			54.179,
			54.242,
			54.303,
			54.365,
			54.395,
			54.426,
			54.489,
			54.504,
			54.549,
			54.614,
			54.676,
			54.738,
			54.799,
			54.83,
			54.861,
			54.923,
			54.982,
			55.048,
			55.107,
			55.154,
			55.171,
			55.232,
			55.263,
			55.294,
			55.358,
			55.373,
			55.42,
			55.48,
			55.543,
			55.588,
			55.605,
			55.666,
			55.698,
			55.728,
			55.791,
			55.85,
			55.915,
			55.975,
			56.023,
			56.099,
			56.133,
			56.162,
			56.224,
			56.242,
			56.287,
			56.35,
			56.411,
			56.456,
			56.474,
			56.534,
			56.565,
			56.596,
			56.675,
			56.721,
			56.782,
			56.846,
			56.893,
			56.97,
			57,
			57.032,
			57.107,
			57.154,
			57.216,
			57.28,
			57.326,
			57.404,
			57.433,
			57.464,
			57.543,
			57.589,
			57.649,
			57.713,
			57.758,
			57.774,
			57.837,
			57.869,
			57.899,
			57.961,
			57.977,
			58.024,
			58.083,
			58.148,
			58.193,
			58.27,
			58.303,
			58.332,
			58.396,
			58.417,
			58.456,
			58.519,
			58.581,
			58.628,
			58.644,
			58.704,
			58.734,
			58.768,
			58.83,
			58.844,
			58.892,
			58.955,
			59.014,
			59.062,
			59.078,
			59.139,
			59.17,
			59.202,
			59.28,
			59.326,
			59.387,
			59.45,
			59.512,
			59.574,
			59.606,
			59.634,
			59.698,
			59.76,
			59.823,
			59.882,
			59.93,
			59.947,
			60.007,
			60.039,
			60.067,
			60.132,
			60.148,
			60.194,
			60.256,
			60.317,
			60.363,
			60.379,
			60.441,
			60.477,
			60.503,
			60.564,
			60.581,
			60.628,
			60.693,
			60.749,
			60.797,
			60.875,
			60.908,
			60.937,
			61.014,
			61.06,
			61.124,
			61.185,
			61.23,
			61.248,
			61.31,
			61.341,
			61.372,
			61.448,
			61.495,
			61.557,
			61.621,
			61.68,
			61.744,
			61.773,
			61.805,
			61.884,
			61.93,
			61.99,
			62.054,
			62.114,
			62.178,
			62.209,
			62.24,
			62.302,
			62.362,
			62.426,
			62.489,
			62.539,
			62.55,
			62.612,
			62.642,
			62.673,
			62.735,
			62.752,
			62.799,
			62.859,
			62.922,
			62.968,
			63.046,
			63.076,
			63.108,
			63.167,
			63.231,
			63.295,
			63.355,
			63.403,
			63.417,
			63.48,
			63.51,
			63.541,
			63.604,
			63.62,
			63.666,
			63.727,
			63.789,
			63.837,
			63.853,
			63.914,
			63.944,
			63.975,
			64.053,
			64.098,
			64.161,
			64.225,
			64.27,
			64.346,
			64.38,
			64.41,
			64.472,
			64.535,
			64.601,
			64.659,
			64.706,
			64.721,
			64.781,
			64.813,
			64.843,
			64.906,
			64.923,
			64.967,
			65.03,
			65.092,
			65.139,
			65.155,
			65.215,
			65.248,
			65.277,
			65.341,
			65.403,
			65.464,
			65.527,
			65.573,
			65.65,
			65.681,
			65.713,
			65.788,
			65.837,
			65.898,
			65.961,
			66.023,
			66.085,
			66.115,
			66.146,
			66.207,
			66.223,
			66.27,
			66.333,
			66.394,
			66.441,
			66.519,
			66.549,
			66.58,
			66.663,
			66.705,
			66.767,
			66.828,
			66.891,
			66.95,
			66.982,
			67.014,
			67.077,
			67.091,
			67.139,
			67.199,
			67.262,
			67.308,
			67.326,
			67.388,
			67.417,
			67.449,
			67.511,
			67.525,
			67.573,
			67.634,
			67.697,
			67.744,
			67.821,
			67.852,
			67.881,
			67.943,
			68.007,
			68.068,
			68.131,
			68.178,
			68.193,
			68.254,
			68.285,
			68.315,
			68.378,
			68.393,
			68.44,
			68.504,
			68.564,
			68.61,
			68.626,
			68.688,
			68.724,
			68.749,
			68.812,
			68.826,
			68.874,
			68.937,
			68.997,
			69.046,
			69.122,
			69.154,
			69.185,
			69.247,
			69.308,
			69.37,
			69.432,
			69.48,
			69.494,
			69.555,
			69.587,
			69.616,
			69.68,
			69.694,
			69.742,
			69.808,
			69.864,
			69.913,
			69.927,
			69.988,
			70.021,
			70.049,
			70.115,
			70.129,
			70.177,
			70.239,
			70.299,
			70.362,
			70.424,
			70.454,
			70.487,
			70.547,
			70.61,
			70.672,
			70.733,
			70.796,
			70.859,
			70.889,
			70.92,
			70.983,
			70.996,
			71.043,
			71.107,
			71.166,
			71.231,
			71.292,
			71.322,
			71.354,
			71.431,
			71.477,
			71.541,
			71.602,
			71.65,
			71.726,
			71.758,
			71.789,
			71.85,
			71.866,
			71.913,
			71.973,
			72.037,
			72.082,
			72.097,
			72.159,
			72.191,
			72.221,
			72.298,
			72.346,
			72.409,
			72.47,
			72.516,
			72.595,
			72.624,
			72.656,
			72.718,
			72.779,
			72.846,
			72.905,
			72.949,
			73.03,
			73.06,
			73.092,
			73.152,
			73.169,
			73.215,
			73.278,
			73.339,
			73.384,
			73.4,
			73.461,
			73.493,
			73.524,
			73.587,
			73.6,
			73.647,
			73.712,
			73.773,
			73.817,
			73.895,
			73.928,
			73.959,
			74.02,
			74.083,
			74.143,
			74.206,
			74.253,
			74.267,
			74.331,
			74.36,
			74.392,
			74.454,
			74.471,
			74.515,
			74.578,
			74.639,
			74.687,
			74.702,
			74.763,
			74.796,
			74.827,
			74.887,
			74.91,
			74.948,
			75.013,
			75.074,
			75.121,
			75.199,
			75.229,
			75.26,
			75.323,
			75.384,
			75.445,
			75.509,
			75.553,
			75.571,
			75.632,
			75.663,
			75.694,
			75.758,
			75.772,
			75.819,
			75.88,
			75.943,
			76.004,
			76.065,
			76.096,
			76.128,
			76.189,
			76.206,
			76.253,
			76.316,
			76.376,
			76.438,
			76.499,
			76.53,
			76.562,
			76.625,
			76.687,
			76.749,
			76.811,
			76.858,
			76.873,
			76.933,
			76.971,
			76.996,
			77.057,
			77.074,
			77.12,
			77.183,
			77.245,
			77.292,
			77.37,
			77.4,
			77.432,
			77.492,
			77.554,
			77.617,
			77.678,
			77.725,
			77.741,
			77.8,
			77.832,
			77.865,
			77.926,
			77.943,
			77.988,
			78.05,
			78.113,
			78.16,
			78.174,
			78.237,
			78.269,
			78.298,
			78.361,
			78.375,
			78.422,
			78.484,
			78.546,
			78.593,
			78.67,
			78.701,
			78.731,
			78.81,
			78.858,
			78.919,
			78.981,
			79.043,
			79.105,
			79.136,
			79.165,
			79.23,
			79.246,
			79.292,
			79.354,
			79.415,
			79.46,
			79.476,
			79.538,
			79.571,
			79.601,
			79.663,
			79.677,
			79.725,
			79.787,
			79.85,
			79.894,
			79.974,
			80.003,
			80.033,
			80.113,
			80.159,
			80.222,
			80.282,
			80.33,
			80.344,
			80.407,
			80.438,
			80.469,
			80.53,
			80.547,
			80.594,
			80.656,
			80.716,
			80.78,
			80.841,
			80.874,
			80.904,
			80.964,
			80.98,
			81.026,
			81.095,
			81.149,
			81.196,
			81.276,
			81.307,
			81.338,
			81.416,
			81.461,
			81.525,
			81.583,
			81.63,
			81.648,
			81.708,
			81.741,
			81.772,
			81.85,
			81.897,
			81.959,
			82.02,
			82.081,
			82.144,
			82.174,
			82.205,
			82.281,
			82.329,
			82.393,
			82.453,
			82.5,
			82.516,
			82.579,
			82.607,
			82.639,
			82.717,
			82.764,
			82.826,
			82.887,
			82.933,
			82.95,
			83.012,
			83.044,
			83.072,
			83.136,
			83.157,
			83.199,
			83.261,
			83.321,
			83.381,
			83.447,
			83.477,
			83.508,
			83.57,
			83.631,
			83.694,
			83.755,
			83.8,
			83.817,
			83.88,
			83.911,
			83.942,
			84.004,
			84.021,
			84.066,
			84.129,
			84.189,
			84.236,
			84.252,
			84.313,
			84.343,
			84.375,
			84.454,
			84.499,
			84.562,
			84.624,
			84.686,
			84.749,
			84.78,
			84.81,
			84.871,
			84.932,
			84.996,
			85.058,
			85.104,
			85.12,
			85.182,
			85.219,
			85.243,
			85.306,
			85.32,
			85.367,
			85.43,
			85.493,
			85.554,
			85.615,
			85.648,
			85.679,
			85.741,
			85.756,
			85.8,
			85.866,
			85.927,
			85.974,
			86.05,
			86.082,
			86.113,
			86.174,
			86.237,
			86.299,
			86.361,
			86.405,
			86.422,
			86.482,
			86.516,
			86.547,
			86.607,
			86.623,
			86.672,
			86.733,
			86.793,
			86.841,
			86.919,
			86.949,
			86.98,
			87.043,
			87.104,
			87.165,
			87.228,
			87.279,
			87.292,
			87.353,
			87.382,
			87.415,
			87.475,
			87.492,
			87.538,
			87.599,
			87.662,
			87.724,
			87.787,
			87.815,
			87.848,
			87.927,
			87.974,
			88.034,
			88.096,
			88.143,
			88.221,
			88.251,
			88.284,
			88.344,
			88.406,
			88.467,
			88.531,
			88.577,
			88.593,
			88.655,
			88.684,
			88.714,
			88.78,
			88.794,
			88.841,
			88.904,
			88.963,
			89.011,
			89.026,
			89.089,
			89.12,
			89.151,
			89.213,
			89.227,
			89.274,
			89.339,
			89.398,
			89.445,
			89.524,
			89.554,
			89.584,
			89.646,
			89.708,
			89.77,
			89.832,
			89.894,
			89.958,
			89.987,
			90.019,
			90.08,
			90.097,
			90.143,
			90.206,
			90.267,
			90.313,
			90.328,
			90.39,
			90.423,
			90.454,
			90.514,
			90.53,
			90.576,
			90.642,
			90.701,
			90.748,
			90.826,
			90.857,
			90.887,
			90.948,
			91.01,
			91.074,
			91.135,
			91.183,
			91.196,
			91.259,
			91.289,
			91.322,
			91.384,
			91.403,
			91.445,
			91.508,
			91.57,
			91.614,
			91.631,
			91.692,
			91.724,
			91.755,
			91.834,
			91.88,
			91.942,
			92.004,
			92.065,
			92.128,
			92.159,
			92.189,
			92.252,
			92.314,
			92.376,
			92.438,
			92.498,
			92.562,
			92.592,
			92.622,
			92.686,
			92.702,
			92.747,
			92.809,
			92.872,
			92.919,
			92.996,
			93.026,
			93.057,
			93.121,
			93.181,
			93.244,
			93.304,
			93.352,
			93.366,
			93.429,
			93.465,
			93.49,
			93.553,
			93.569,
			93.616,
			93.678,
			93.74,
			93.783,
			93.8,
			93.864,
			93.894,
			93.926,
			94.004,
			94.05,
			94.111,
			94.174,
			94.236,
			94.298,
			94.329,
			94.36,
			94.421,
			94.481,
			94.545,
			94.608,
			94.669,
			94.731,
			94.761,
			94.793,
			94.856,
			94.871,
			94.917,
			94.981,
			95.04,
			95.104,
			95.165,
			95.197,
			95.228,
			95.288,
			95.304,
			95.352,
			95.413,
			95.474,
			95.526,
			95.598,
			95.63,
			95.66,
			95.739,
			95.785,
			95.846,
			95.911,
			95.957,
			95.971,
			96.032,
			96.064,
			96.096,
			96.158,
			96.173,
			96.219,
			96.237
		]
	],
	'1845_1846_7982': [
		[
			126.09
		],
		[
			0
		],
		[
			0,
			3.885,
			6.525,
			10.499,
			13.157,
			15.822,
			17.156,
			19.831,
			21.171,
			23.856,
			26.547,
			27.896,
			30.597,
			33.305,
			37.379,
			38.74,
			40.103,
			44.202,
			45.571,
			46.943,
			51.066,
			53.823,
			56.586,
			60.743,
			63.522,
			67.702,
			69.098,
			70.496,
			74.698,
			77.508,
			81.734,
			84.559,
			87.389,
			88.807,
			91.647,
			94.493,
			95.918,
			98.773,
			100.203,
			103.067,
			105.932,
			110.221,
			113.059,
			117.265,
			118.651,
			120.026,
			124.095,
			126.759,
			130.684,
			133.253,
			135.784,
			139.51,
			140.733,
			141.946,
			145.531,
			146.709,
			147.877,
			151.328,
			153.58,
			155.795,
			156.888,
			159.045,
			161.164,
			162.21,
			164.273,
			165.291,
			167.298,
			169.267,
			172.152,
			174.029,
			176.78,
			177.681,
			178.573,
			181.216,
			182.937,
			185.476,
			187.148,
			188.803,
			189.625,
			191.265,
			192.078,
			193.694,
			196.107,
			196.901,
			199.249,
			200.808,
			203.156,
			204.72,
			206.281,
			207.084,
			209.547,
			211.186,
			212.852,
			215.39,
			217.078,
			219.588,
			220.422,
			221.255,
			223.789,
			225.506,
			228.084,
			229.848,
			231.605,
			232.482,
			234.238,
			235.117,
			236.874,
			238.631,
			239.505,
			241.224,
			242.945,
			245.482,
			246.31,
			247.138,
			248.799,
			250.427,
			251.229,
			252.837,
			253.643,
			255.253,
			256.854,
			259.266,
			260.892,
			263.353,
			264.177,
			265.003,
			267.493,
			269.161,
			271.679,
			273.374,
			275.074,
			275.926,
			277.65,
			278.515,
			280.235,
			281.985,
			282.866,
			284.64,
			286.425,
			289.13,
			290.037,
			290.948,
			293.698,
			294.62,
			295.544,
			298.338,
			300.212,
			302.097,
			304.947,
			306.861,
			309.769,
			310.74,
			311.713,
			314.634,
			315.618,
			316.606,
			319.584,
			321.582,
			323.59,
			324.598,
			326.621,
			328.649,
			329.668,
			331.712,
			332.738,
			334.798,
			336.868,
			339.997,
			342.09,
			345.23,
			346.28,
			347.332,
			350.503,
			352.628,
			355.833,
			357.981,
			360.139,
			361.221,
			363.392,
			364.482,
			366.666,
			368.86,
			369.96,
			372.167,
			374.384,
			376.61,
			378.845,
			379.965,
			382.213,
			384.47,
			385.602,
			387.872,
			389.011,
			391.295,
			393.587,
			397.042,
			399.355,
			402.842,
			404.008,
			405.176,
			408.693,
			411.049,
			414.601,
			416.982,
			419.37,
			420.568,
			422.968,
			424.171,
			426.581,
			430.211,
			432.64,
			435.077,
			437.519,
			439.97,
			441.199,
			443.663,
			446.134,
			447.373,
			449.856,
			451.1,
			453.593,
			456.095,
			459.86,
			462.38,
			466.173,
			467.441,
			468.71,
			472.53,
			475.084,
			478.929,
			481.501,
			484.081,
			485.373,
			487.965,
			489.263,
			491.863,
			494.469,
			495.771,
			498.374,
			500.982,
			504.906,
			507.529,
			511.475,
			512.794,
			514.114,
			518.083,
			520.736,
			524.727,
			527.395,
			530.069,
			534.091,
			535.434,
			536.779,
			540.823,
			542.174,
			543.526,
			547.592,
			550.309,
			553.032,
			554.396,
			557.127,
			559.864,
			561.235,
			565.356,
			568.11,
			570.869,
			575.019,
			577.792,
			581.963,
			583.356,
			584.751,
			588.943,
			591.744,
			595.956,
			598.77,
			601.591,
			603.003,
			605.831,
			607.247,
			608.665,
			612.926,
			614.349,
			615.773,
			620.055,
			622.915,
			625.781,
			627.216,
			630.089,
			632.968,
			634.409,
			637.295,
			638.741,
			641.634,
			644.533,
			648.889,
			651.825,
			656.173,
			657.635,
			659.098,
			663.494,
			666.431,
			670.846,
			673.796,
			678.229,
			681.19,
			682.673,
			685.643,
			690.107,
			693.09,
			696.077,
			699.069,
			702.065,
			703.566,
			706.57,
			709.579,
			711.085,
			714.101,
			715.611,
			718.634,
			721.663,
			726.214,
			729.254,
			733.822,
			735.347,
			736.873,
			742.99,
			744.521,
			749.124,
			752.198,
			756.816,
			759.9,
			761.444,
			764.534,
			767.629,
			769.178,
			772.279,
			775.385,
			780.05,
			781.607,
			783.165,
			786.284,
			789.408,
			790.971,
			795.666,
			798.801,
			803.508,
			806.651,
			809.797,
			814.522,
			816.099,
			817.678,
			822.418,
			824,
			825.584,
			830.339,
			833.514,
			836.693,
			838.284,
			841.469,
			844.654,
			846.246,
			849.433,
			851.027,
			854.219,
			857.412,
			862.209,
			865.409,
			870.215,
			871.818,
			873.422,
			878.237,
			881.451,
			886.276,
			889.497,
			892.719,
			894.332,
			897.559,
			899.174,
			902.406,
			905.641,
			907.26,
			908.881,
			913.746,
			916.994,
			920.245,
			921.872,
			925.129,
			928.388,
			930.019,
			934.917,
			938.184,
			941.456,
			946.372,
			949.655,
			954.588,
			956.235,
			957.883,
			962.833,
			966.139,
			971.107,
			974.425,
			977.748,
			979.412,
			982.742,
			984.41,
			987.748,
			992.765,
			994.44,
			999.47,
			1002.829,
			1006.191,
			1007.874,
			1011.242,
			1014.614,
			1016.301,
			1019.679,
			1021.369,
			1024.752,
			1028.139,
			1033.226,
			1036.622,
			1041.722,
			1043.424,
			1045.127,
			1050.24,
			1053.652,
			1058.778,
			1062.2,
			1065.625,
			1067.338,
			1070.768,
			1072.484,
			1075.919,
			1079.356,
			1081.076,
			1084.517,
			1087.961,
			1093.129,
			1094.853,
			1096.577,
			1100.028,
			1103.481,
			1105.208,
			1108.665,
			1110.395,
			1113.856,
			1117.32,
			1122.52,
			1125.991,
			1131.202,
			1132.94,
			1134.678,
			1139.896,
			1143.378,
			1148.603,
			1152.087,
			1155.574,
			1157.318,
			1160.808,
			1164.3,
			1166.045,
			1169.541,
			1171.289,
			1174.787,
			1178.287,
			1183.544,
			1185.297,
			1187.051,
			1192.311,
			1194.063,
			1195.813,
			1201.038,
			1204.489,
			1209.597,
			1212.951,
			1217.905,
			1221.153,
			1222.762,
			1225.95,
			1229.096,
			1230.654,
			1232.201,
			1236.781,
			1239.786,
			1244.224,
			1247.133,
			1250.001,
			1251.419,
			1255.615,
			1258.363,
			1261.069,
			1265.052,
			1267.657,
			1271.491,
			1272.75,
			1273.998,
			1277.683,
			1280.091,
			1283.629,
			1285.941,
			1288.213,
			1289.353,
			1291.563,
			1292.652,
			1294.803,
			1296.914,
			1297.956,
			1300.012,
			1302.037,
			1304.031,
			1306.002,
			1306.981,
			1308.924,
			1310.849,
			1311.808,
			1313.715,
			1314.665,
			1316.56,
			1318.449,
			1321.283,
			1323.178,
			1326.022,
			1326.982,
			1327.943,
			1330.83,
			1332.782,
			1335.719,
			1337.696,
			1339.68,
			1340.673,
			1342.68,
			1343.689,
			1345.709,
			1347.759,
			1348.796,
			1350.87,
			1352.952,
			1356.081,
			1357.124,
			1358.163,
			1360.242,
			1363.342,
			1366.417,
			1368.471,
			1370.524,
			1373.607,
			1375.665,
			1378.745,
			1379.77,
			1380.792,
			1383.843,
			1384.852,
			1385.859,
			1388.857,
			1390.831,
			1393.763,
			1395.686,
			1397.601,
			1398.551,
			1400.434,
			1401.371,
			1403.24,
			1405.094,
			1407.86,
			1409.698,
			1412.446,
			1413.36,
			1414.272,
			1417.001,
			1418.816,
			1421.523,
			1423.325,
			1425.12,
			1427.809,
			1428.706,
			1430.498,
			1433.186,
			1434.084,
			1436.787,
			1438.594,
			1441.323,
			1443.152,
			1444.986,
			1445.903,
			1447.741,
			1448.664,
			1450.512,
			1452.364,
			1455.158,
			1457.042,
			1459.88,
			1460.82,
			1461.776,
			1464.657,
			1466.618,
			1469.557,
			1471.533,
			1473.517,
			1474.511,
			1476.506,
			1477.505,
			1479.506,
			1482.501,
			1483.501,
			1486.508,
			1489.512,
			1490.523,
			1491.535,
			1493.581,
			1495.617,
			1496.639,
			1498.688,
			1499.715,
			1501.758,
			1503.827,
			1506.946,
			1509.037,
			1512.191,
			1513.248,
			1514.306,
			1517.494,
			1519.63,
			1522.85,
			1525.006,
			1528.255,
			1530.432,
			1531.523,
			1533.711,
			1535.907,
			1537.008,
			1539.215,
			1541.429,
			1544.764,
			1546.996,
			1550.358,
			1551.483,
			1552.609,
			1555.997,
			1558.266,
			1561.68,
			1563.967,
			1566.259,
			1569.712,
			1570.867,
			1572.023,
			1575.502,
			1577.83,
			1581.335,
			1583.68,
			1586.031,
			1587.21,
			1589.572,
			1590.756,
			1593.128,
			1596.701,
			1599.092,
			1601.49,
			1605.099,
			1606.306,
			1607.514,
			1611.149,
			1612.363,
			1613.579,
			1617.238,
			1619.685,
			1623.368,
			1625.831,
			1628.3,
			1632.016,
			1633.258,
			1634.501,
			1638.24,
			1640.74,
			1644.501,
			1647.017,
			1650.801,
			1653.333,
			1655.87,
			1657.142,
			1660.962,
			1663.517,
			1666.078,
			1669.929,
			1672.505,
			1676.378,
			1677.671,
			1678.966,
			1682.855,
			1685.451,
			1689.366,
			1691.963,
			1694.551,
			1695.845,
			1698.423,
			1699.708,
			1702.274,
			1704.836,
			1706.115,
			1707.392,
			1711.236,
			1713.795,
			1716.354,
			1717.632,
			1720.184,
			1722.735,
			1724.014,
			1726.573,
			1727.854,
			1730.429,
			1733.005,
			1736.87,
			1739.445,
			1743.301,
			1744.585,
			1745.865,
			1749.667,
			1752.142,
			1755.813,
			1758.224,
			1760.567,
			1761.703,
			1763.981,
			1765.1,
			1767.306,
			1769.47,
			1770.532,
			1772.625,
			1774.691,
			1777.672,
			1778.646,
			1779.609,
			1781.497,
			1783.348,
			1784.262,
			1786.06,
			1786.945,
			1788.691,
			1790.405,
			1792.912,
			1794.545,
			1796.957,
			1797.75,
			1798.539,
			1800.896,
			1802.467,
			1804.822,
			1806.393,
			1807.962,
			1808.746,
			1810.312,
			1811.091,
			1812.646,
			1814.198,
			1814.974,
			1816.518,
			1818.053,
			1820.348,
			1821.867,
			1824.125,
			1824.874,
			1825.622,
			1827.862,
			1829.337,
			1831.554,
			1833.048,
			1834.527,
			1836.768,
			1837.533,
			1838.294,
			1840.564,
			1841.33,
			1842.114,
			1844.448,
			1845.992,
			1847.586,
			1848.38,
			1849.959,
			1851.54,
			1852.344,
			1853.941,
			1854.735,
			1856.315,
			1857.843,
			1860.116,
			1861.619,
			1863.814,
			1864.544,
			1865.273,
			1867.447,
			1868.883,
			1871.031,
			1872.458,
			1874.592,
			1876.015,
			1876.727,
			1878.137,
			1880.258,
			1881.679,
			1883.087,
			1884.497,
			1885.914,
			1886.626,
			1888.051,
			1889.48,
			1890.198,
			1891.641,
			1892.365,
			1893.818,
			1895.28,
			1897.493,
			1898.976,
			1901.219,
			1901.972,
			1902.727,
			1905.008,
			1906.543,
			1908.869,
			1910.434,
			1912.01,
			1912.803,
			1914.388,
			1915.191,
			1916.804,
			1918.433,
			1919.257,
			1920.911,
			1922.575,
			1924.265,
			1925.97,
			1926.827,
			1928.549,
			1930.284,
			1931.155,
			1932.906,
			1933.781,
			1935.54,
			1937.309,
			1939.982,
			1941.791,
			1944.499,
			1945.407,
			1946.317,
			1949.069,
			1950.93,
			1953.745,
			1955.638,
			1957.543,
			1958.5,
			1960.428,
			1962.364,
			1963.335,
			1965.281,
			1966.257,
			1968.215,
			1970.183,
			1973.148,
			1975.136,
			1978.136,
			1979.139,
			1980.144,
			1983.163,
			1985.189,
			1988.24,
			1990.282,
			1992.331,
			1995.422,
			1996.463,
			1997.505,
			2001.691,
			2002.742,
			2005.902,
			2008.016,
			2010.119,
			2011.174,
			2013.309,
			2015.435,
			2016.491,
			2018.611,
			2019.674,
			2021.783,
			2023.882,
			2027.038,
			2029.145,
			2032.318,
			2033.38,
			2034.443,
			2037.648,
			2039.796,
			2043.038,
			2045.212,
			2048.492,
			2050.689,
			2051.791,
			2052.897,
			2056.207,
			2057.32,
			2059.555,
			2061.796,
			2064.043,
			2066.297,
			2067.426,
			2069.686,
			2071.953,
			2073.09,
			2076.51,
			2078.8,
			2081.098,
			2084.559,
			2086.875,
			2090.362,
			2091.529,
			2092.697,
			2096.232,
			2098.586,
			2102.129,
			2104.498,
			2106.873,
			2108.061,
			2110.44,
			2111.631,
			2114.009,
			2117.557,
			2119.905,
			2122.233,
			2125.709,
			2126.864,
			2128.017,
			2131.47,
			2132.621,
			2133.771,
			2137.221,
			2139.522,
			2141.83,
			2145.296,
			2147.616,
			2151.097,
			2152.262,
			2153.426,
			2156.917,
			2159.248,
			2162.74,
			2165.068,
			2168.552,
			2170.868,
			2172.025,
			2174.34,
			2176.645,
			2177.801,
			2180.105,
			2182.4,
			2185.823,
			2188.096,
			2191.498,
			2192.625,
			2193.747,
			2195.988,
			2197.107,
			2199.327,
			2202.645,
			2204.827,
			2207.006,
			2210.242,
			2211.318,
			2212.395,
			2216.696,
			2217.774,
			2221.05,
			2223.245,
			2225.471,
			2226.569,
			2228.81,
			2231.067,
			2232.199,
			2234.469,
			2235.606,
			2237.887,
			2240.17,
			2243.607,
			2245.904,
			2249.366,
			2250.526,
			2251.688,
			2255.193,
			2257.549,
			2261.104,
			2263.488,
			2265.887,
			2267.092,
			2269.509,
			2270.722,
			2273.154,
			2275.592,
			2276.831,
			2278.053,
			2281.72,
			2284.168,
			2286.592,
			2287.813,
			2290.262,
			2292.705,
			2293.929,
			2296.382,
			2297.608,
			2300.067,
			2302.531,
			2306.239,
			2308.717,
			2312.448,
			2313.696,
			2314.945,
			2318.703,
			2321.219,
			2325.008,
			2327.545,
			2330.091,
			2331.367,
			2333.926,
			2335.208,
			2337.798,
			2341.655,
			2344.233,
			2346.797,
			2350.687,
			2351.987,
			2353.289,
			2355.897,
			2358.534,
			2359.845,
			2363.785,
			2366.418,
			2369.059,
			2373.036,
			2375.699,
			2379.681,
			2381.04,
			2382.379,
			2386.397,
			2389.083,
			2393.117,
			2395.812,
			2399.836,
			2402.54,
			2406.603,
			2410.701,
			2413.428,
			2416.134,
			2420.234,
			2422.993,
			2427.102,
			2428.473,
			2429.845,
			2433.971,
			2436.729,
			2439.493,
			2443.649,
			2446.426,
			2450.604,
			2452,
			2453.401,
			2457.617,
			2460.444,
			2464.708,
			2467.565,
			2470.439,
			2471.879,
			2474.771,
			2477.673,
			2479.127,
			2482.041,
			2483.499,
			2486.408,
			2489.305,
			2493.606,
			2496.436,
			2500.626,
			2502.006,
			2503.38,
			2507.463,
			2510.136,
			2514.071,
			2516.648,
			2519.229,
			2520.525,
			2523.133,
			2524.424,
			2527.028,
			2529.628,
			2530.927,
			2532.224,
			2536.104,
			2538.679,
			2541.251,
			2542.537,
			2545.104,
			2547.649,
			2548.93,
			2551.488,
			2552.767,
			2555.321,
			2557.904,
			2561.752,
			2564.31,
			2568.141,
			2569.416,
			2570.692,
			2574.523,
			2577.084,
			2580.94,
			2583.516,
			2587.386,
			2589.961,
			2591.25,
			2593.823,
			2596.398,
			2597.686,
			2600.257,
			2602.833,
			2606.694,
			2607.985,
			2609.275,
			2613.154,
			2614.451,
			2615.749,
			2619.657,
			2622.25,
			2626.19,
			2628.827,
			2631.481,
			2635.476,
			2636.811,
			2638.147,
			2642.167,
			2644.856,
			2648.894,
			2651.593,
			2654.298,
			2655.652,
			2658.363,
			2659.72,
			2662.436,
			2666.521,
			2669.251,
			2671.989,
			2676.111,
			2677.491,
			2678.872,
			2683.027,
			2684.416,
			2685.807,
			2689.99,
			2692.788,
			2697,
			2699.822,
			2702.651,
			2706.916,
			2708.34,
			2709.765,
			2714.047,
			2715.476,
			2716.907,
			2721.199,
			2724.062,
			2726.923,
			2728.353,
			2731.207,
			2734.054,
			2735.474,
			2738.31,
			2739.724,
			2742.55,
			2745.372,
			2749.595,
			2752.404,
			2756.631,
			2758.029,
			2759.427,
			2763.616,
			2766.404,
			2770.569,
			2773.334,
			2776.086,
			2780.194,
			2781.554,
			2782.912,
			2786.948,
			2788.296,
			2789.643,
			2793.694,
			2796.398,
			2799.137,
			2800.495,
			2803.228,
			2805.963,
			2807.338,
			2811.461,
			2814.218,
			2816.974,
			2821.111,
			2823.867,
			2827.999,
			2829.375,
			2830.752,
			2834.857,
			2837.613,
			2841.784,
			2844.566,
			2847.355,
			2848.765,
			2851.591,
			2853.008,
			2855.86,
			2858.711,
			2860.142,
			2861.571,
			2865.845,
			2868.683,
			2872.884,
			2875.666,
			2878.431,
			2879.808,
			2883.879,
			2886.571,
			2889.227,
			2893.218,
			2895.869,
			2899.827,
			2901.146,
			2902.466,
			2906.449,
			2909.094,
			2913.066,
			2915.716,
			2919.692,
			2922.345,
			2923.651,
			2926.309,
			2928.97,
			2930.302,
			2932.974,
			2935.676,
			2939.708,
			2942.404,
			2946.437,
			2947.792,
			2949.149,
			2951.869,
			2953.231,
			2955.963,
			2958.703,
			2962.832,
			2965.597,
			2969.761,
			2971.152,
			2972.545,
			2976.732,
			2979.531,
			2983.74,
			2986.561,
			2989.39,
			2990.803,
			2993.635,
			2996.475,
			2997.897,
			3000.749,
			3002.175,
			3005.034,
			3007.898,
			3012.205,
			3013.644,
			3015.085,
			3019.416,
			3020.863,
			3022.312,
			3026.67,
			3029.584,
			3033.967,
			3036.897,
			3039.831,
			3041.296,
			3044.219,
			3045.673,
			3047.122,
			3051.429,
			3052.848,
			3054.258,
			3058.43,
			3061.165,
			3063.863,
			3065.198,
			3067.84,
			3070.446,
			3071.735,
			3074.287,
			3075.551,
			3078.07,
			3080.533,
			3084.145,
			3086.527,
			3090.041,
			3091.198,
			3092.348,
			3095.773,
			3098.061,
			3101.452,
			3103.704,
			3107.054,
			3109.294,
			3110.415,
			3112.654,
			3116.009,
			3117.13,
			3120.509,
			3123.873,
			3124.995,
			3126.116,
			3128.365,
			3130.615,
			3131.74,
			3133.993,
			3135.12,
			3137.375,
			3139.633,
			3143.003,
			3145.278,
			3148.714,
			3149.88,
			3151.067,
			3154.604,
			3156.999,
			3160.618,
			3163.048,
			3165.471,
			3166.679,
			3169.098,
			3170.295,
			3172.666,
			3175.043,
			3176.221,
			3178.56,
			3180.862,
			3184.242,
			3185.348,
			3186.445,
			3188.613,
			3190.745,
			3191.799,
			3193.88,
			3194.908,
			3196.947,
			3198.98,
			3201.996,
			3204.003,
			3207.034,
			3208.052,
			3209.07,
			3212.143,
			3213.181,
			3214.22,
			3217.35,
			3219.449,
			3221.558,
			3222.614,
			3224.731,
			3225.794,
			3227.929,
			3230.076,
			3231.154,
			3233.311,
			3235.471,
			3238.712,
			3239.788,
			3240.864,
			3244.071,
			3245.13,
			3246.183,
			3249.279,
			3251.28,
			3254.219,
			3256.122,
			3257.992,
			3258.905,
			3260.703,
			3262.471,
			3265.049,
			3265.904,
			3266.736,
			3269.177,
			3270.794,
			3272.365,
			3273.138,
			3274.684,
			3276.227,
			3276.992,
			3278.515,
			3279.276,
			3280.835,
			3282.396,
			3284.758,
			3286.363,
			3288.781,
			3289.591,
			3290.412,
			3292.884,
			3294.535,
			3297.045,
			3298.721,
			3300.399,
			3301.236,
			3302.904,
			3303.737,
			3305.403,
			3307.888,
			3309.539,
			3311.182,
			3312.81,
			3314.437,
			3315.25,
			3316.894,
			3318.541,
			3319.362,
			3321,
			3321.826,
			3323.474,
			3325.119,
			3327.581,
			3329.215,
			3331.65,
			3332.452,
			3333.25,
			3335.62,
			3337.144,
			3339.375,
			3340.822,
			3342.904,
			3344.233,
			3344.879,
			3346.181,
			3347.48,
			3348.117,
			3349.387,
			3350.651,
			3352.55,
			3353.194,
			3353.836,
			3355.119,
			3356.401,
			3357.041,
			3358.369,
			3359.042,
			3360.392,
			3361.752,
			3363.86,
			3365.296,
			3367.465,
			3368.204,
			3368.959,
			3371.237,
			3372.774,
			3375.163,
			3376.779,
			3379.177,
			3380.745,
			3381.532,
			3383.116,
			3384.614,
			3385.359,
			3386.85,
			3388.371,
			3390.625,
			3392.145,
			3394.473,
			3395.267,
			3396.065,
			3398.489,
			3400.157,
			3402.675,
			3404.38,
			3406.107,
			3408.709,
			3409.598,
			3410.508,
			3414.188,
			3415.125,
			3417.971,
			3419.891,
			3422.76,
			3424.691,
			3426.598,
			3427.551,
			3429.47,
			3430.435,
			3432.335,
			3434.25,
			3437.146,
			3439.093,
			3442.037,
			3443.025,
			3444.016,
			3447.006,
			3449.014,
			3452.051,
			3454.091,
			3456.146,
			3457.181,
			3459.263,
			3460.309,
			3462.408,
			3464.514,
			3465.552,
			3466.609,
			3469.801,
			3471.943,
			3475.174,
			3477.341,
			3479.517,
			3480.609,
			3482.802,
			3483.904,
			3486.118,
			3488.345,
			3491.716,
			3493.98,
			3497.406,
			3498.555,
			3499.709,
			3503.219,
			3505.566,
			3509.116,
			3511.5,
			3513.897,
			3515.099,
			3517.483,
			3518.69,
			3521.11,
			3523.536,
			3524.774,
			3527.214,
			3529.665,
			3532.129,
			3534.606,
			3535.849,
			3539.588,
			3540.847,
			3542.115,
			3544.666,
			3545.945,
			3548.508,
			3551.082,
			3554.939,
			3557.516,
			3561.363,
			3562.64,
			3563.906,
			3567.67,
			3570.135,
			3573.773,
			3576.162,
			3578.515,
			3579.682,
			3581.992,
			3583.134,
			3585.415,
			3587.644,
			3588.746,
			3590.929,
			3593.08,
			3596.253,
			3597.298,
			3598.334,
			3601.406,
			3602.418,
			3603.421,
			3606.393,
			3608.345,
			3611.21,
			3613.084,
			3614.925,
			3615.832,
			3617.616,
			3618.499,
			3619.377,
			3621.983,
			3623.698,
			3626.26,
			3627.946,
			3629.635,
			3630.482,
			3632.179,
			3633.879,
			3634.734,
			3636.45,
			3637.315,
			3639.055,
			3640.807,
			3643.462,
			3645.251,
			3647.954,
			3648.863,
			3649.774,
			3652.513,
			3654.346,
			3657.101,
			3658.935,
			3660.764,
			3663.489,
			3664.392,
			3666.188,
			3667.969,
			3668.853,
			3669.717,
			3672.318,
			3674.016,
			3675.682,
			3676.503,
			3678.12,
			3679.703,
			3680.484,
			3682.018,
			3682.772,
			3684.256,
			3685.727,
			3687.852,
			3689.229,
			3691.251,
			3691.911,
			3692.565,
			3694.485,
			3695.731,
			3697.545,
			3698.725,
			3699.882,
			3700.455,
			3701.581,
			3702.137,
			3703.24,
			3704.333,
			3704.877,
			3705.967,
			3707.068,
			3708.717,
			3709.267,
			3709.817,
			3710.921,
			3712.067,
			3712.644,
			3714.377,
			3715.538,
			3716.73,
			3718.567,
			3719.796,
			3721.667,
			3722.32,
			3722.972,
			3724.93,
			3726.24,
			3728.321,
			3729.719,
			3731.121,
			3731.83,
			3733.279,
			3734.004,
			3735.458,
			3736.919,
			3737.681,
			3739.217,
			3740.758,
			3743.071,
			3743.835,
			3744.602,
			3746.924,
			3747.702,
			3748.474,
			3750.027,
			3750.81,
			3752.388,
			3754.826,
			3756.473,
			3758.137,
			3760.633,
			3761.474,
			3762.319,
			3764.765,
			3765.559,
			3766.358,
			3768.761,
			3770.253,
			3772.52,
			3774.053,
			3775.578,
			3776.346,
			3777.893,
			3778.672,
			3780.254,
			3781.858,
			3784.294,
			3785.119,
			3785.953,
			3788.469,
			3789.309,
			3790.149,
			3792.703,
			3794.41,
			3797.022,
			3798.783,
			3800.56,
			3801.455,
			3803.255,
			3804.161,
			3805.07,
			3807.821,
			3808.746,
			3809.673,
			3812.479,
			3814.367,
			3816.27,
			3817.227,
			3819.152,
			3821.093,
			3822.068,
			3824.031,
			3825.018,
			3827.003,
			3829.003,
			3832.03,
			3834.066,
			3837.146,
			3838.18,
			3839.217,
			3842.348,
			3844.445,
			3847.595,
			3849.705,
			3851.827,
			3852.892,
			3855.031,
			3856.105,
			3858.26,
			3860.428,
			3861.517,
			3863.703,
			3865.9,
			3869.221,
			3870.334,
			3871.45,
			3873.693,
			3875.948,
			3877.082,
			3879.357,
			3880.5,
			3882.794,
			3885.102,
			3888.587,
			3890.926,
			3894.458,
			3895.642,
			3896.829,
			3900.406,
			3902.806,
			3906.428,
			3908.856,
			3911.297,
			3912.521,
			3914.978,
			3916.21,
			3918.684,
			3921.169,
			3922.415,
			3924.918,
			3927.431,
			3931.224,
			3933.767,
			3936.324,
			3938.893,
			3940.183,
			3944.069,
			3946.677,
			3949.296,
			3953.254,
			3955.907,
			3959.908,
			3961.248,
			3962.591,
			3966.635,
			3969.365,
			3973.409,
			3976.128,
			3978.854,
			3980.22,
			3982.953,
			3984.323,
			3987.071,
			3991.209,
			3993.977,
			3996.754,
			4000.932,
			4003.727,
			4007.932,
			4009.336,
			4010.743,
			4014.972,
			4017.798,
			4022.052,
			4024.896,
			4027.749,
			4029.178,
			4032.043,
			4033.479,
			4034.917,
			4040.703,
			4042.157,
			4046.537,
			4049.471,
			4052.417,
			4053.894,
			4056.854,
			4059.822,
			4061.308,
			4065.78,
			4068.767,
			4071.763,
			4076.268,
			4079.279,
			4083.808,
			4085.321,
			4086.836,
			4091.394,
			4094.443,
			4099.031,
			4102.101,
			4105.179,
			4106.723,
			4109.815,
			4111.364,
			4114.47,
			4117.585,
			4119.145,
			4122.272,
			4125.405,
			4130.119,
			4131.693,
			4133.271,
			4136.426,
			4139.59,
			4141.173,
			4144.346,
			4145.935,
			4149.117,
			4152.305,
			4157.091,
			4160.291,
			4165.102,
			4166.708,
			4168.317,
			4173.153,
			4174.768,
			4176.385,
			4181.244,
			4184.49,
			4187.742,
			4189.37,
			4192.63,
			4194.262,
			4197.531,
			4202.443,
			4205.725,
			4209.012,
			4213.952,
			4215.602,
			4217.252,
			4222.212,
			4223.869,
			4225.526,
			4230.506,
			4233.832,
			4238.831,
			4242.17,
			4245.514,
			4250.541,
			4252.219,
			4253.899,
			4258.945,
			4262.315,
			4267.382,
			4270.766,
			4274.155,
			4275.852,
			4279.248,
			4282.65,
			4284.352,
			4287.761,
			4289.467,
			4292.884,
			4296.306,
			4301.448,
			4303.165,
			4304.882,
			4310.043,
			4311.766,
			4313.489,
			4318.668,
			4322.127,
			4327.323,
			4330.793,
			4336.007,
			4339.488,
			4341.23,
			4342.974,
			4348.21,
			4349.958,
			4351.706,
			4356.958,
			4360.465,
			4363.975,
			4365.731,
			4369.248,
			4372.768,
			4374.529,
			4378.056,
			4379.821,
			4383.354,
			4386.889,
			4392.202,
			4395.748,
			4401.073,
			4402.85,
			4404.628,
			4409.966,
			4413.53,
			4418.882,
			4422.453,
			4426.027,
			4427.815,
			4431.393,
			4433.183,
			4436.764,
			4440.349,
			4442.143,
			4443.937,
			4449.323,
			4454.715,
			4458.313,
			4461.913,
			4465.515,
			4467.316,
			4470.921,
			4472.726,
			4476.334,
			4479.946,
			4485.365,
			4488.979,
			4494.403,
			4496.212,
			4498.021,
			4503.453,
			4507.076,
			4512.514,
			4516.142,
			4519.77,
			4521.584,
			4525.215,
			4527.031,
			4530.665,
			4534.3,
			4536.119,
			4539.757,
			4543.397,
			4548.863,
			4550.686,
			4552.509,
			4556.158,
			4559.809,
			4561.633,
			4567.104,
			4570.749,
			4574.39,
			4579.851,
			4583.495,
			4588.965,
			4590.791,
			4592.617,
			4598.099,
			4601.758,
			4607.25,
			4610.916,
			4614.585,
			4616.42,
			4620.095,
			4621.933,
			4625.611,
			4631.136,
			4634.822,
			4638.512,
			4644.052,
			4647.749,
			4653.301,
			4655.152,
			4657.005,
			4662.569,
			4666.283,
			4671.859,
			4675.581,
			4679.306,
			4684.9,
			4686.766,
			4688.634,
			4696.112,
			4697.983,
			4703.604,
			4707.356,
			4711.111,
			4712.99,
			4716.75,
			4720.513,
			4722.396,
			4726.164,
			4728.049,
			4731.821,
			4735.598,
			4741.268,
			4745.052,
			4750.735,
			4752.631,
			4754.528,
			4760.225,
			4764.025,
			4769.732,
			4773.541,
			4777.354,
			4779.262,
			4783.08,
			4784.991,
			4788.814,
			4792.641,
			4794.556,
			4798.388,
			4802.223,
			4807.982,
			4809.903,
			4811.825,
			4815.671,
			4819.521,
			4821.446,
			4825.299,
			4827.227,
			4831.084,
			4834.946,
			4840.743,
			4844.611,
			4850.419,
			4852.356,
			4854.294,
			4860.114,
			4863.997,
			4869.827,
			4873.717,
			4877.609,
			4879.557,
			4883.454,
			4885.403,
			4889.304,
			4893.207,
			4895.159,
			4899.066,
			4902.976,
			4908.846,
			4912.763,
			4916.682,
			4920.604,
			4922.566,
			4928.458,
			4932.386,
			4936.318,
			4942.221,
			4946.159,
			4952.069,
			4954.041,
			4956.012,
			4961.931,
			4965.878,
			4971.806,
			4975.761,
			4981.697,
			4985.657,
			4987.638,
			4991.602,
			4995.601,
			4997.584,
			5001.55,
			5005.513,
			5011.421,
			5013.399,
			5015.378,
			5021.345,
			5023.321,
			5025.298,
			5029.218,
			5031.194,
			5035.149,
			5039.106,
			5045.054,
			5049.059,
			5054.992,
			5056.984,
			5058.977,
			5064.961,
			5068.956,
			5074.953,
			5078.954,
			5084.959,
			5088.964,
			5092.97,
			5094.973,
			5100.981,
			5104.986,
			5108.989,
			5114.992,
			5118.994,
			5125.003,
			5127.006,
			5129.01,
			5135.06,
			5139.042,
			5145.07,
			5149.089,
			5153.102,
			5155.135,
			5159.089,
			5161.072,
			5165.016,
			5168.922,
			5170.86,
			5174.705,
			5178.508,
			5182.27,
			5187.864,
			5191.493,
			5195.116,
			5196.914,
			5200.477,
			5202.244,
			5205.749,
			5209.214,
			5214.337,
			5217.705,
			5222.685,
			5224.324,
			5225.954,
			5230.787,
			5233.962,
			5238.653,
			5241.734,
			5244.777,
			5246.286,
			5249.279,
			5250.762,
			5253.702,
			5256.603,
			5258.039,
			5260.883,
			5263.689,
			5267.825,
			5270.533,
			5274.523,
			5275.834,
			5277.135,
			5279.708,
			5280.979,
			5283.495,
			5285.973,
			5289.62,
			5292.008,
			5295.524,
			5296.679,
			5297.826,
			5301.206,
			5303.411,
			5306.664,
			5308.771,
			5310.839,
			5311.859,
			5313.868,
			5314.856,
			5316.803,
			5318.715,
			5319.656,
			5321.51,
			5323.33,
			5326.005,
			5327.751,
			5330.318,
			5331.166,
			5332.006,
			5334.486,
			5336.124,
			5337.745,
			5340.146,
			5341.744,
			5342.541,
			5344.128,
			5344.919,
			5345.71,
			5348.112,
			5348.911,
			5349.709,
			5352.121,
			5353.738,
			5355.352,
			5356.158,
			5357.772,
			5359.387,
			5360.194,
			5361.808,
			5362.63,
			5364.275,
			5365.926,
			5368.398,
			5370.045,
			5372.521,
			5373.346,
			5374.171,
			5376.648,
			5378.301,
			5380.743,
			5382.375,
			5383.999,
			5384.801,
			5386.409,
			5387.216,
			5388.024,
			5390.452,
			5391.265,
			5392.081,
			5394.537,
			5396.208,
			5397.892,
			5398.739,
			5400.45,
			5402.184,
			5403.055,
			5404.808,
			5405.693,
			5407.479,
			5409.279,
			5412.018,
			5413.883,
			5416.687,
			5417.63,
			5418.577,
			5421.438,
			5423.362,
			5426.273,
			5428.235,
			5430.217,
			5431.212,
			5433.212,
			5434.215,
			5436.231,
			5438.259,
			5439.277,
			5441.321,
			5443.379,
			5445.45,
			5447.533,
			5448.579,
			5450.677,
			5452.787,
			5453.846,
			5455.964,
			5457.027,
			5459.16,
			5461.289,
			5464.482,
			5466.605,
			5469.797,
			5470.866,
			5471.932,
			5475.14,
			5477.288,
			5480.51,
			5482.669,
			5485.91,
			5488.078,
			5489.167,
			5491.351,
			5493.541,
			5494.638,
			5496.841,
			5499.05,
			5502.377,
			5503.489,
			5504.603,
			5507.961,
			5509.085,
			5510.211,
			5513.592,
			5515.871,
			5518.163,
			5521.621,
			5523.961,
			5527.463,
			5528.637,
			5529.813,
			5533.339,
			5534.527,
			5535.717,
			5539.303,
			5541.707,
			5545.332,
			5547.762,
			5548.98,
			5551.425,
			5553.879,
			5555.11,
			5557.579,
			5560.057,
			5563.791,
			5566.292,
			5570.059,
			5571.319,
			5572.603,
			5576.403,
			5578.925,
			5582.755,
			5585.313,
			5587.88,
			5589.166,
			5591.741,
			5593.032,
			5594.326,
			5598.221,
			5599.523,
			5600.825,
			5604.737,
			5607.356,
			5611.3,
			5613.939,
			5616.586,
			5617.913,
			5621.904,
			5624.574,
			5627.252,
			5631.283,
			5633.98,
			5638.041,
			5639.399,
			5640.758,
			5644.85,
			5647.587,
			5651.708,
			5654.466,
			5657.231,
			5658.617,
			5661.394,
			5662.786,
			5665.576,
			5668.373,
			5669.775,
			5672.583,
			5675.401,
			5679.642,
			5682.479,
			5685.324,
			5688.178,
			5689.607,
			5692.473,
			5693.908,
			5696.784,
			5699.668,
			5704.009,
			5706.913,
			5711.283,
			5712.743,
			5714.206,
			5718.604,
			5721.545,
			5725.972,
			5728.933,
			5733.389,
			5736.37,
			5737.863,
			5740.855,
			5743.854,
			5745.358,
			5748.37,
			5751.389,
			5755.931,
			5757.448,
			5758.967,
			5763.535,
			5765.061,
			5766.588,
			5771.18,
			5774.25,
			5777.326,
			5781.942,
			5785.01,
			5789.571,
			5791.076,
			5792.571,
			5797.002,
			5798.461,
			5799.912,
			5804.211,
			5807.031,
			5809.813,
			5811.19,
			5813.917,
			5816.608,
			5817.94,
			5820.576,
			5821.88,
			5824.461,
			5827.003,
			5830.748,
			5833.199,
			5836.808,
			5837.994,
			5839.172,
			5842.654,
			5844.928,
			5848.273,
			5850.457,
			5852.605,
			5853.666,
			5855.761,
			5856.794,
			5858.835,
			5860.84,
			5861.829,
			5862.809,
			5865.693,
			5867.571,
			5869.415,
			5870.324,
			5872.118,
			5873.878,
			5874.747,
			5876.457,
			5877.3,
			5878.966,
			5880.613,
			5883.069,
			5884.684,
			5887.098,
			5887.9,
			5888.702,
			5891.091,
			5892.666,
			5895.023,
			5896.578,
			5898.123,
			5898.894,
			5900.431,
			5901.215,
			5902.777,
			5904.333,
			5905.108,
			5906.697,
			5908.303,
			5910.703,
			5911.555,
			5912.421,
			5915.007,
			5915.868,
			5916.786,
			5919.555,
			5921.396,
			5923.214,
			5925.932,
			5927.705,
			5930.276,
			5931.131,
			5931.985,
			5934.423,
			5936.016,
			5938.368,
			5939.865,
			5941.359,
			5942.107,
			5943.598,
			5944.333,
			5945.805,
			5948.019,
			5949.517,
			5951.035,
			5953.319,
			5954.087,
			5954.875,
			5957.247,
			5958.041,
			5958.836,
			5961.259,
			5962.887,
			5965.34,
			5966.98,
			5968.632,
			5971.13,
			5971.968,
			5972.81,
			5975.351,
			5976.203,
			5977.057,
			5979.617,
			5981.345,
			5983.081,
			5983.954,
			5985.708,
			5987.471,
			5988.356,
			5990.137,
			5991.031,
			5992.834,
			5994.65,
			5997.399,
			5999.253,
			6002.055,
			6003.011,
			6003.955,
			6006.806,
			6008.717,
			6011.588,
			6013.526,
			6015.474,
			6016.452,
			6018.417,
			6019.403,
			6021.382,
			6023.371,
			6024.37,
			6025.371,
			6028.391,
			6030.419,
			6033.483,
			6035.54,
			6037.61,
			6038.649,
			6040.737,
			6041.785,
			6043.878,
			6045.973,
			6049.131,
			6051.248,
			6054.442,
			6055.511,
			6056.583,
			6059.81,
			6061.973,
			6065.234,
			6067.42,
			6069.615,
			6070.716,
			6072.924,
			6074.032,
			6076.254,
			6078.487,
			6079.607,
			6080.729,
			6084.11,
			6087.511,
			6088.648,
			6089.789,
			6092.077,
			6094.374,
			6095.527,
			6097.839,
			6099,
			6101.327,
			6103.665,
			6107.189,
			6109.551,
			6113.109,
			6114.3,
			6115.493,
			6119.085,
			6121.491,
			6125.115,
			6127.543,
			6131.203,
			6133.655,
			6136.118,
			6137.354,
			6141.073,
			6143.565,
			6146.066,
			6149.83,
			6151.089,
			6152.35,
			6156.142,
			6157.41,
			6158.679,
			6162.495,
			6165.048,
			6167.609,
			6171.465,
			6174.044,
			6177.929,
			6179.228,
			6180.528,
			6184.433,
			6185.736,
			6187.041,
			6190.965,
			6191.826
		],
		[
			0,
			0.063,
			0.126,
			0.189,
			0.252,
			0.296,
			0.313,
			0.375,
			0.406,
			0.435,
			0.499,
			0.513,
			0.559,
			0.622,
			0.685,
			0.73,
			0.747,
			0.809,
			0.839,
			0.871,
			0.934,
			0.995,
			1.057,
			1.119,
			1.165,
			1.241,
			1.278,
			1.304,
			1.366,
			1.429,
			1.49,
			1.552,
			1.599,
			1.615,
			1.675,
			1.709,
			1.739,
			1.802,
			1.815,
			1.863,
			1.924,
			1.987,
			2.048,
			2.111,
			2.142,
			2.172,
			2.235,
			2.295,
			2.359,
			2.42,
			2.468,
			2.545,
			2.576,
			2.608,
			2.669,
			2.685,
			2.731,
			2.793,
			2.853,
			2.902,
			2.916,
			2.978,
			3.011,
			3.039,
			3.103,
			3.119,
			3.165,
			3.226,
			3.289,
			3.339,
			3.413,
			3.442,
			3.475,
			3.552,
			3.598,
			3.661,
			3.722,
			3.769,
			3.786,
			3.848,
			3.879,
			3.91,
			3.986,
			4.032,
			4.096,
			4.156,
			4.218,
			4.28,
			4.312,
			4.344,
			4.42,
			4.468,
			4.531,
			4.592,
			4.637,
			4.716,
			4.745,
			4.778,
			4.839,
			4.901,
			4.968,
			5.025,
			5.072,
			5.086,
			5.148,
			5.181,
			5.21,
			5.274,
			5.288,
			5.335,
			5.397,
			5.46,
			5.506,
			5.52,
			5.581,
			5.613,
			5.645,
			5.707,
			5.723,
			5.769,
			5.831,
			5.892,
			5.939,
			6.019,
			6.048,
			6.078,
			6.14,
			6.204,
			6.265,
			6.327,
			6.373,
			6.39,
			6.452,
			6.48,
			6.514,
			6.576,
			6.59,
			6.638,
			6.701,
			6.762,
			6.807,
			6.822,
			6.886,
			6.915,
			6.947,
			7.009,
			7.072,
			7.132,
			7.196,
			7.243,
			7.319,
			7.351,
			7.382,
			7.444,
			7.464,
			7.506,
			7.566,
			7.63,
			7.677,
			7.691,
			7.754,
			7.786,
			7.816,
			7.878,
			7.892,
			7.94,
			8.002,
			8.064,
			8.127,
			8.186,
			8.219,
			8.25,
			8.311,
			8.375,
			8.44,
			8.498,
			8.545,
			8.56,
			8.621,
			8.653,
			8.685,
			8.745,
			8.762,
			8.809,
			8.87,
			8.931,
			8.978,
			8.993,
			9.055,
			9.087,
			9.119,
			9.181,
			9.194,
			9.242,
			9.305,
			9.367,
			9.413,
			9.489,
			9.524,
			9.552,
			9.614,
			9.675,
			9.737,
			9.801,
			9.845,
			9.861,
			9.924,
			9.955,
			9.986,
			10.064,
			10.11,
			10.172,
			10.233,
			10.281,
			10.296,
			10.358,
			10.39,
			10.42,
			10.481,
			10.499,
			10.543,
			10.606,
			10.669,
			10.729,
			10.792,
			10.824,
			10.855,
			10.915,
			10.978,
			11.039,
			11.103,
			11.147,
			11.165,
			11.227,
			11.256,
			11.287,
			11.348,
			11.365,
			11.411,
			11.479,
			11.537,
			11.586,
			11.66,
			11.69,
			11.721,
			11.785,
			11.846,
			11.909,
			11.97,
			12.015,
			12.095,
			12.126,
			12.155,
			12.219,
			12.234,
			12.28,
			12.342,
			12.403,
			12.451,
			12.465,
			12.527,
			12.559,
			12.589,
			12.668,
			12.713,
			12.777,
			12.837,
			12.886,
			12.962,
			12.993,
			13.024,
			13.086,
			13.149,
			13.214,
			13.271,
			13.319,
			13.334,
			13.396,
			13.426,
			13.458,
			13.521,
			13.536,
			13.581,
			13.649,
			13.707,
			13.753,
			13.768,
			13.829,
			13.861,
			13.893,
			13.954,
			13.971,
			14.017,
			14.077,
			14.139,
			14.186,
			14.263,
			14.294,
			14.326,
			14.388,
			14.451,
			14.513,
			14.575,
			14.635,
			14.698,
			14.729,
			14.761,
			14.839,
			14.885,
			14.948,
			15.008,
			15.055,
			15.071,
			15.131,
			15.165,
			15.194,
			15.256,
			15.273,
			15.318,
			15.381,
			15.442,
			15.489,
			15.567,
			15.597,
			15.628,
			15.711,
			15.752,
			15.815,
			15.877,
			15.939,
			16.001,
			16.03,
			16.063,
			16.123,
			16.141,
			16.185,
			16.248,
			16.311,
			16.358,
			16.372,
			16.433,
			16.465,
			16.497,
			16.56,
			16.62,
			16.684,
			16.745,
			16.791,
			16.869,
			16.898,
			16.93,
			16.993,
			17.009,
			17.056,
			17.116,
			17.179,
			17.224,
			17.241,
			17.302,
			17.335,
			17.365,
			17.428,
			17.442,
			17.488,
			17.552,
			17.614,
			17.66,
			17.736,
			17.772,
			17.8,
			17.859,
			17.922,
			17.986,
			18.046,
			18.092,
			18.11,
			18.17,
			18.202,
			18.234,
			18.296,
			18.311,
			18.358,
			18.42,
			18.48,
			18.526,
			18.543,
			18.605,
			18.637,
			18.665,
			18.745,
			18.792,
			18.853,
			18.914,
			18.962,
			19.038,
			19.072,
			19.102,
			19.178,
			19.224,
			19.288,
			19.348,
			19.396,
			19.412,
			19.472,
			19.504,
			19.536,
			19.612,
			19.658,
			19.726,
			19.781,
			19.833,
			19.844,
			19.907,
			19.938,
			19.969,
			20.03,
			20.047,
			20.094,
			20.157,
			20.218,
			20.265,
			20.341,
			20.372,
			20.404,
			20.464,
			20.527,
			20.589,
			20.652,
			20.698,
			20.715,
			20.775,
			20.806,
			20.838,
			20.899,
			20.914,
			20.963,
			21.025,
			21.085,
			21.133,
			21.148,
			21.208,
			21.24,
			21.272,
			21.332,
			21.349,
			21.395,
			21.458,
			21.52,
			21.58,
			21.642,
			21.674,
			21.707,
			21.769,
			21.831,
			21.896,
			21.953,
			21.999,
			22.017,
			22.079,
			22.109,
			22.139,
			22.202,
			22.218,
			22.265,
			22.325,
			22.389,
			22.432,
			22.45,
			22.512,
			22.542,
			22.572,
			22.636,
			22.699,
			22.759,
			22.821,
			22.884,
			22.947,
			22.975,
			23.008,
			23.07,
			23.086,
			23.132,
			23.194,
			23.255,
			23.319,
			23.38,
			23.41,
			23.442,
			23.52,
			23.565,
			23.63,
			23.69,
			23.752,
			23.815,
			23.846,
			23.877,
			23.939,
			23.999,
			24.064,
			24.124,
			24.17,
			24.186,
			24.247,
			24.28,
			24.309,
			24.372,
			24.387,
			24.434,
			24.497,
			24.557,
			24.606,
			24.619,
			24.681,
			24.714,
			24.745,
			24.805,
			24.822,
			24.869,
			24.93,
			24.991,
			25.055,
			25.114,
			25.146,
			25.178,
			25.24,
			25.303,
			25.365,
			25.425,
			25.472,
			25.488,
			25.551,
			25.581,
			25.611,
			25.674,
			25.689,
			25.736,
			25.797,
			25.859,
			25.908,
			25.921,
			25.982,
			26.045,
			26.109,
			26.17,
			26.231,
			26.293,
			26.342,
			26.42,
			26.45,
			26.481,
			26.543,
			26.559,
			26.605,
			26.669,
			26.728,
			26.791,
			26.853,
			26.885,
			26.914,
			26.977,
			26.992,
			27.04,
			27.102,
			27.163,
			27.209,
			27.285,
			27.319,
			27.348,
			27.411,
			27.473,
			27.536,
			27.598,
			27.644,
			27.72,
			27.753,
			27.784,
			27.86,
			27.906,
			27.97,
			28.03,
			28.094,
			28.154,
			28.187,
			28.216,
			28.278,
			28.293,
			28.342,
			28.403,
			28.465,
			28.526,
			28.588,
			28.621,
			28.651,
			28.712,
			28.775,
			28.839,
			28.898,
			28.945,
			28.959,
			29.023,
			29.054,
			29.085,
			29.163,
			29.207,
			29.271,
			29.334,
			29.378,
			29.394,
			29.456,
			29.489,
			29.52,
			29.582,
			29.596,
			29.643,
			29.705,
			29.768,
			29.814,
			29.891,
			29.923,
			29.953,
			30.029,
			30.078,
			30.144,
			30.201,
			30.264,
			30.326,
			30.356,
			30.388,
			30.449,
			30.463,
			30.512,
			30.574,
			30.636,
			30.686,
			30.759,
			30.791,
			30.822,
			30.883,
			30.946,
			31.009,
			31.069,
			31.13,
			31.193,
			31.224,
			31.256,
			31.317,
			31.38,
			31.446,
			31.503,
			31.551,
			31.565,
			31.627,
			31.657,
			31.688,
			31.768,
			31.813,
			31.875,
			31.938,
			31.982,
			32,
			32.06,
			32.091,
			32.122,
			32.186,
			32.248,
			32.309,
			32.372,
			32.418,
			32.495,
			32.525,
			32.557,
			32.619,
			32.68,
			32.744,
			32.805,
			32.865,
			32.929,
			32.96,
			32.99,
			33.069,
			33.116,
			33.182,
			33.238,
			33.301,
			33.363,
			33.395,
			33.426,
			33.488,
			33.55,
			33.61,
			33.672,
			33.719,
			33.736,
			33.797,
			33.827,
			33.86,
			33.922,
			33.936,
			33.982,
			34.045,
			34.108,
			34.152,
			34.17,
			34.231,
			34.266,
			34.294,
			34.355,
			34.369,
			34.415,
			34.48,
			34.541,
			34.604,
			34.664,
			34.695,
			34.728,
			34.805,
			34.852,
			34.914,
			34.975,
			35.023,
			35.037,
			35.098,
			35.13,
			35.16,
			35.223,
			35.24,
			35.285,
			35.349,
			35.409,
			35.455,
			35.472,
			35.532,
			35.566,
			35.595,
			35.656,
			35.673,
			35.721,
			35.78,
			35.842,
			35.889,
			35.969,
			35.998,
			36.03,
			36.108,
			36.155,
			36.215,
			36.279,
			36.329,
			36.34,
			36.403,
			36.431,
			36.462,
			36.526,
			36.54,
			36.588,
			36.65,
			36.712,
			36.774,
			36.836,
			36.867,
			36.897,
			36.958,
			37.02,
			37.085,
			37.146,
			37.191,
			37.269,
			37.299,
			37.33,
			37.394,
			37.41,
			37.457,
			37.519,
			37.58,
			37.627,
			37.642,
			37.703,
			37.736,
			37.766,
			37.829,
			37.843,
			37.889,
			37.955,
			38.015,
			38.061,
			38.137,
			38.17,
			38.2,
			38.262,
			38.323,
			38.387,
			38.447,
			38.509,
			38.572,
			38.603,
			38.634,
			38.711,
			38.759,
			38.819,
			38.881,
			38.928,
			38.945,
			39.006,
			39.037,
			39.069,
			39.131,
			39.145,
			39.193,
			39.255,
			39.315,
			39.364,
			39.439,
			39.471,
			39.503,
			39.565,
			39.626,
			39.689,
			39.75,
			39.797,
			39.813,
			39.873,
			39.906,
			39.936,
			39.997,
			40.013,
			40.061,
			40.123,
			40.182,
			40.23,
			40.245,
			40.307,
			40.339,
			40.369,
			40.432,
			40.451,
			40.493,
			40.556,
			40.619,
			40.665,
			40.744,
			40.774,
			40.805,
			40.867,
			40.927,
			40.992,
			41.054,
			41.098,
			41.113,
			41.178,
			41.209,
			41.238,
			41.302,
			41.316,
			41.364,
			41.425,
			41.486,
			41.548,
			41.611,
			41.641,
			41.672,
			41.736,
			41.797,
			41.86,
			41.921,
			41.967,
			42.046,
			42.074,
			42.108,
			42.184,
			42.231,
			42.294,
			42.353,
			42.402,
			42.418,
			42.48,
			42.514,
			42.541,
			42.603,
			42.619,
			42.663,
			42.728,
			42.788,
			42.835,
			42.913,
			42.945,
			42.976,
			43.037,
			43.098,
			43.16,
			43.223,
			43.286,
			43.348,
			43.378,
			43.408,
			43.472,
			43.485,
			43.534,
			43.595,
			43.658,
			43.704,
			43.719,
			43.782,
			43.811,
			43.842,
			43.92,
			43.968,
			44.029,
			44.091,
			44.137,
			44.215,
			44.245,
			44.278,
			44.355,
			44.401,
			44.464,
			44.525,
			44.577,
			44.588,
			44.648,
			44.679,
			44.715,
			44.789,
			44.836,
			44.897,
			44.959,
			45.005,
			45.021,
			45.083,
			45.113,
			45.145,
			45.223,
			45.269,
			45.332,
			45.394,
			45.439,
			45.515,
			45.547,
			45.578,
			45.642,
			45.702,
			45.763,
			45.826,
			45.889,
			45.951,
			45.982,
			46.014,
			46.075,
			46.09,
			46.137,
			46.199,
			46.26,
			46.324,
			46.385,
			46.416,
			46.446,
			46.508,
			46.525,
			46.572,
			46.639,
			46.696,
			46.742,
			46.819,
			46.852,
			46.881,
			46.959,
			47.006,
			47.069,
			47.128,
			47.177,
			47.193,
			47.254,
			47.284,
			47.314,
			47.378,
			47.393,
			47.44,
			47.503,
			47.563,
			47.609,
			47.688,
			47.719,
			47.751,
			47.811,
			47.874,
			47.939,
			47.999,
			48.044,
			48.059,
			48.121,
			48.153,
			48.184,
			48.245,
			48.261,
			48.307,
			48.369,
			48.433,
			48.477,
			48.495,
			48.556,
			48.586,
			48.619,
			48.679,
			48.699,
			48.741,
			48.805,
			48.866,
			48.914,
			48.989,
			49.02,
			49.052,
			49.114,
			49.175,
			49.239,
			49.299,
			49.347,
			49.361,
			49.423,
			49.455,
			49.486,
			49.564,
			49.611,
			49.671,
			49.734,
			49.781,
			49.797,
			49.857,
			49.89,
			49.92,
			49.998,
			50.043,
			50.105,
			50.169,
			50.214,
			50.292,
			50.322,
			50.355,
			50.416,
			50.478,
			50.542,
			50.602,
			50.663,
			50.725,
			50.787,
			50.867,
			50.911,
			50.973,
			51.036,
			51.084,
			51.16,
			51.191,
			51.222,
			51.285,
			51.347,
			51.408,
			51.469,
			51.518,
			51.594,
			51.624,
			51.657,
			51.717,
			51.781,
			51.841,
			51.905,
			51.951,
			51.966,
			52.028,
			52.059,
			52.089,
			52.152,
			52.168,
			52.214,
			52.278,
			52.337,
			52.385,
			52.464,
			52.493,
			52.523,
			52.587,
			52.648,
			52.711,
			52.772,
			52.822,
			52.834,
			52.896,
			52.927,
			52.959,
			53.021,
			53.036,
			53.081,
			53.144,
			53.205,
			53.254,
			53.269,
			53.332,
			53.362,
			53.393,
			53.455,
			53.471,
			53.515,
			53.579,
			53.639,
			53.687,
			53.763,
			53.797,
			53.827,
			53.888,
			53.952,
			54.013,
			54.073,
			54.136,
			54.198,
			54.229,
			54.259,
			54.322,
			54.339,
			54.386,
			54.448,
			54.508,
			54.555,
			54.572,
			54.634,
			54.663,
			54.695,
			54.773,
			54.82,
			54.885,
			54.941,
			55.005,
			55.068,
			55.099,
			55.13,
			55.206,
			55.252,
			55.314,
			55.378,
			55.423,
			55.44,
			55.502,
			55.532,
			55.564,
			55.64,
			55.686,
			55.748,
			55.812,
			55.858,
			55.874,
			55.936,
			55.966,
			55.998,
			56.06,
			56.121,
			56.184,
			56.245,
			56.291,
			56.37,
			56.401,
			56.431,
			56.494,
			56.509,
			56.556,
			56.615,
			56.678,
			56.727,
			56.741,
			56.804,
			56.835,
			56.866,
			56.928,
			56.947,
			56.991,
			57.049,
			57.114,
			57.16,
			57.236,
			57.266,
			57.299,
			57.361,
			57.421,
			57.485,
			57.545,
			57.592,
			57.672,
			57.702,
			57.732,
			57.796,
			57.811,
			57.855,
			57.919,
			57.979,
			58.026,
			58.043,
			58.105,
			58.136,
			58.166,
			58.245,
			58.29,
			58.352,
			58.416,
			58.46,
			58.538,
			58.57,
			58.601,
			58.679,
			58.724,
			58.788,
			58.849,
			58.895,
			58.911,
			58.974,
			59.006,
			59.036,
			59.097,
			59.111,
			59.157,
			59.222,
			59.281,
			59.345,
			59.406,
			59.439,
			59.468,
			59.546,
			59.593,
			59.654,
			59.718,
			59.762,
			59.84,
			59.872,
			59.902,
			59.964,
			60.028,
			60.089,
			60.151,
			60.211,
			60.274,
			60.306,
			60.336,
			60.399,
			60.414,
			60.461,
			60.523,
			60.586,
			60.647,
			60.71,
			60.742,
			60.772,
			60.831,
			60.849,
			60.894,
			60.956,
			61.02,
			61.07,
			61.142,
			61.172,
			61.205,
			61.267,
			61.33,
			61.391,
			61.453,
			61.499,
			61.516,
			61.577,
			61.609,
			61.639,
			61.701,
			61.716,
			61.764,
			61.827,
			61.886,
			61.933,
			61.949,
			62.011,
			62.041,
			62.072,
			62.136,
			62.196,
			62.259,
			62.32,
			62.368,
			62.384,
			62.445,
			62.475,
			62.507,
			62.569,
			62.585,
			62.63,
			62.695,
			62.755,
			62.803,
			62.817,
			62.879,
			62.91,
			62.941,
			63.002,
			63.019,
			63.065,
			63.131,
			63.19,
			63.236,
			63.314,
			63.344,
			63.375,
			63.454,
			63.499,
			63.561,
			63.623,
			63.685,
			63.748,
			63.778,
			63.81,
			63.887,
			63.932,
			63.996,
			64.059,
			64.105,
			64.118,
			64.181,
			64.212,
			64.243,
			64.305,
			64.321,
			64.368,
			64.431,
			64.492,
			64.554,
			64.616,
			64.647,
			64.676,
			64.74,
			64.802,
			64.865,
			64.924,
			64.973,
			64.987,
			65.049,
			65.079,
			65.111,
			65.173,
			65.193,
			65.237,
			65.297,
			65.36,
			65.405,
			65.422,
			65.481,
			65.514,
			65.545,
			65.607,
			65.623,
			65.671,
			65.73,
			65.793,
			65.839,
			65.918,
			65.947,
			65.98,
			66.042,
			66.06,
			66.103,
			66.171,
			66.229,
			66.276,
			66.29,
			66.353,
			66.381,
			66.413,
			66.476,
			66.492,
			66.539,
			66.599,
			66.662,
			66.708,
			66.725,
			66.786,
			66.815,
			66.849,
			66.925,
			66.973,
			67.034,
			67.095,
			67.141,
			67.159,
			67.219,
			67.281,
			67.344,
			67.361,
			67.407,
			67.469,
			67.53,
			67.575,
			67.592,
			67.655,
			67.685,
			67.716,
			67.777,
			67.795,
			67.839,
			67.902,
			67.963,
			68.01,
			68.087,
			68.119,
			68.151,
			68.228,
			68.275,
			68.338,
			68.398,
			68.445,
			68.459,
			68.522,
			68.554,
			68.585,
			68.66,
			68.708,
			68.77,
			68.832,
			68.877,
			68.895,
			68.956,
			68.986,
			69.018,
			69.079,
			69.096,
			69.142,
			69.204,
			69.267,
			69.316,
			69.389,
			69.422,
			69.451,
			69.514,
			69.574,
			69.638,
			69.701,
			69.763,
			69.824,
			69.855,
			69.885,
			69.947,
			69.964,
			70.009,
			70.074,
			70.135,
			70.181,
			70.195,
			70.257,
			70.289,
			70.321,
			70.38,
			70.397,
			70.445,
			70.507,
			70.569,
			70.629,
			70.692,
			70.722,
			70.755,
			70.816,
			70.877,
			70.939,
			71.002,
			71.065,
			71.126,
			71.156,
			71.187,
			71.25,
			71.264,
			71.313,
			71.374,
			71.435,
			71.484,
			71.561,
			71.591,
			71.623,
			71.7,
			71.745,
			71.808,
			71.869,
			71.918,
			71.994,
			72.024,
			72.057,
			72.135,
			72.182,
			72.243,
			72.305,
			72.366,
			72.429,
			72.46,
			72.489,
			72.554,
			72.569,
			72.616,
			72.677,
			72.737,
			72.799,
			72.863,
			72.893,
			72.924,
			72.987,
			73.048,
			73.11,
			73.173,
			73.219,
			73.234,
			73.295,
			73.327,
			73.359,
			73.421,
			73.441,
			73.482,
			73.545,
			73.607,
			73.669,
			73.731,
			73.762,
			73.791,
			73.854,
			73.871,
			73.917,
			73.978,
			74.042,
			74.088,
			74.166,
			74.197,
			74.227,
			74.304,
			74.352,
			74.411,
			74.475,
			74.521,
			74.536,
			74.599,
			74.629,
			74.659,
			74.723,
			74.74,
			74.786,
			74.848,
			74.908,
			74.955,
			74.972,
			75.034,
			75.065,
			75.095,
			75.158,
			75.172,
			75.22,
			75.28,
			75.342,
			75.405,
			75.468,
			75.503,
			75.53,
			75.606,
			75.653,
			75.715,
			75.776,
			75.823,
			75.84,
			75.902,
			75.93,
			75.963,
			76.024,
			76.041,
			76.088,
			76.151,
			76.211,
			76.257,
			76.272,
			76.336,
			76.364,
			76.398,
			76.458,
			76.521,
			76.583,
			76.646,
			76.692,
			76.708,
			76.77,
			76.801,
			76.831,
			76.907,
			76.956,
			77.018,
			77.08,
			77.126,
			77.141,
			77.202,
			77.235,
			77.265,
			77.326,
			77.342,
			77.388,
			77.452,
			77.513,
			77.563,
			77.637,
			77.668,
			77.698,
			77.761,
			77.823,
			77.888,
			77.948,
			77.994,
			78.072,
			78.102,
			78.134,
			78.196,
			78.211,
			78.257,
			78.318,
			78.381,
			78.427,
			78.444,
			78.505,
			78.537,
			78.568,
			78.628,
			78.645,
			78.691,
			78.754,
			78.815,
			78.878,
			78.938,
			78.969,
			79.001,
			79.078,
			79.126,
			79.187,
			79.25,
			79.297,
			79.311,
			79.372,
			79.405,
			79.435,
			79.497,
			79.513,
			79.559,
			79.621,
			79.684,
			79.729,
			79.744,
			79.807,
			79.838,
			79.87,
			79.947,
			79.992,
			80.056,
			80.117,
			80.164,
			80.242,
			80.272,
			80.302,
			80.382,
			80.426,
			80.49,
			80.552,
			80.599,
			80.613,
			80.675,
			80.707,
			80.738,
			80.799,
			80.816,
			80.86,
			80.924,
			80.985,
			81.031,
			81.048,
			81.111,
			81.142,
			81.172,
			81.232,
			81.248,
			81.297,
			81.359,
			81.419,
			81.466,
			81.545,
			81.574,
			81.607,
			81.667,
			81.688,
			81.73,
			81.791,
			81.854,
			81.915,
			81.976,
			82.01,
			82.038,
			82.102,
			82.118,
			82.162,
			82.227,
			82.287,
			82.332,
			82.351,
			82.412,
			82.442,
			82.472,
			82.552,
			82.596,
			82.66,
			82.722,
			82.769,
			82.784,
			82.845,
			82.877,
			82.908,
			82.971,
			82.985,
			83.03,
			83.094,
			83.157,
			83.203,
			83.219,
			83.28,
			83.311,
			83.341,
			83.405,
			83.419,
			83.466,
			83.529,
			83.59,
			83.636,
			83.715,
			83.751,
			83.775,
			83.838,
			83.9,
			83.961,
			84.023,
			84.07,
			84.087,
			84.148,
			84.179,
			84.21,
			84.272,
			84.286,
			84.334,
			84.395,
			84.459,
			84.505,
			84.519,
			84.58,
			84.614,
			84.643,
			84.706,
			84.72,
			84.769,
			84.831,
			84.893,
			84.938,
			85.014,
			85.048,
			85.077,
			85.157,
			85.202,
			85.265,
			85.327,
			85.372,
			85.389,
			85.448,
			85.48,
			85.511,
			85.574,
			85.591,
			85.636,
			85.697,
			85.759,
			85.821,
			85.882,
			85.915,
			85.946,
			86.008,
			86.071,
			86.131,
			86.196,
			86.257,
			86.32,
			86.349,
			86.38,
			86.443,
			86.503,
			86.568,
			86.629,
			86.675,
			86.691,
			86.753,
			86.782,
			86.813,
			86.891,
			86.938,
			87.001,
			87.062,
			87.109,
			87.186,
			87.219,
			87.248,
			87.309,
			87.373,
			87.435,
			87.497,
			87.542,
			87.559,
			87.619,
			87.651,
			87.683,
			87.76,
			87.808,
			87.875,
			87.931,
			87.977,
			87.993,
			88.055,
			88.085,
			88.116,
			88.194,
			88.239,
			88.304,
			88.366,
			88.412,
			88.489,
			88.52,
			88.551,
			88.614,
			88.675,
			88.737,
			88.797,
			88.844,
			88.86,
			88.922,
			88.955,
			88.986,
			89.046,
			89.061,
			89.11,
			89.171,
			89.234,
			89.278,
			89.294,
			89.358,
			89.388,
			89.42,
			89.481,
			89.497,
			89.544,
			89.605,
			89.666,
			89.715,
			89.79,
			89.821,
			89.854,
			89.915,
			89.936,
			89.977,
			90.04,
			90.102,
			90.148,
			90.163,
			90.224,
			90.256,
			90.288,
			90.365,
			90.412,
			90.474,
			90.536,
			90.581,
			90.598,
			90.659,
			90.691,
			90.722,
			90.783,
			90.845,
			90.909,
			90.97,
			91.015,
			91.094,
			91.124,
			91.155,
			91.217,
			91.278,
			91.341,
			91.403,
			91.449,
			91.464,
			91.528,
			91.559,
			91.591,
			91.651,
			91.667,
			91.715,
			91.774,
			91.837,
			91.883,
			91.898,
			91.962,
			91.997,
			92.022,
			92.086,
			92.147,
			92.209,
			92.273,
			92.334,
			92.396,
			92.426,
			92.457,
			92.519,
			92.536,
			92.581,
			92.645,
			92.706,
			92.753,
			92.766,
			92.83,
			92.86,
			92.891,
			92.954,
			92.969,
			93.016,
			93.077,
			93.141,
			93.186,
			93.263,
			93.296,
			93.325,
			93.388,
			93.451,
			93.515,
			93.575,
			93.62,
			93.635,
			93.697,
			93.729,
			93.76,
			93.823,
			93.837,
			93.882,
			93.945,
			94.008,
			94.069,
			94.131,
			94.161,
			94.194,
			94.256,
			94.27,
			94.319,
			94.381,
			94.441,
			94.489,
			94.565,
			94.597,
			94.627,
			94.69,
			94.751,
			94.815,
			94.877,
			94.924,
			94.939,
			94.999,
			95.031,
			95.061,
			95.123,
			95.14,
			95.187,
			95.249,
			95.311,
			95.356,
			95.372,
			95.433,
			95.465,
			95.497,
			95.559,
			95.62,
			95.681,
			95.744,
			95.807,
			95.869,
			95.9,
			95.93,
			95.99,
			96.053,
			96.114,
			96.177,
			96.226,
			96.24,
			96.302,
			96.331,
			96.363,
			96.441,
			96.487,
			96.552,
			96.613,
			96.659,
			96.737,
			96.766,
			96.797,
			96.86,
			96.923,
			96.986,
			97.047,
			97.094,
			97.17,
			97.202,
			97.232,
			97.311,
			97.357,
			97.419,
			97.481,
			97.527,
			97.541,
			97.605,
			97.636,
			97.666,
			97.73,
			97.744,
			97.789,
			97.854,
			97.914,
			97.96,
			98.04,
			98.07,
			98.101,
			98.163,
			98.225,
			98.287,
			98.348,
			98.395,
			98.41,
			98.473,
			98.503,
			98.534,
			98.598,
			98.612,
			98.66,
			98.725,
			98.784,
			98.828,
			98.845,
			98.907,
			98.937,
			98.968,
			99.031,
			99.045,
			99.093,
			99.154,
			99.218,
			99.263,
			99.341,
			99.37,
			99.403,
			99.464,
			99.528,
			99.59,
			99.649,
			99.696,
			99.711,
			99.775,
			99.806,
			99.838,
			99.899,
			99.913,
			99.959,
			100.022,
			100.084,
			100.145,
			100.207,
			100.244,
			100.269,
			100.348,
			100.395,
			100.456,
			100.519,
			100.582,
			100.642,
			100.674,
			100.705,
			100.78,
			100.828,
			100.89,
			100.952,
			101.016,
			101.078,
			101.106,
			101.14,
			101.201,
			101.217,
			101.261,
			101.324,
			101.387,
			101.431,
			101.449,
			101.51,
			101.541,
			101.572,
			101.633,
			101.651,
			101.697,
			101.757,
			101.822,
			101.867,
			101.944,
			101.974,
			102.007,
			102.082,
			102.129,
			102.192,
			102.256,
			102.317,
			102.377,
			102.411,
			102.439,
			102.519,
			102.563,
			102.628,
			102.688,
			102.735,
			102.811,
			102.843,
			102.873,
			102.937,
			102.999,
			103.06,
			103.121,
			103.17,
			103.185,
			103.248,
			103.276,
			103.309,
			103.371,
			103.386,
			103.434,
			103.495,
			103.557,
			103.619,
			103.682,
			103.713,
			103.742,
			103.804,
			103.822,
			103.867,
			103.928,
			103.991,
			104.039,
			104.116,
			104.147,
			104.177,
			104.238,
			104.301,
			104.369,
			104.426,
			104.47,
			104.487,
			104.548,
			104.581,
			104.611,
			104.672,
			104.688,
			104.736,
			104.798,
			104.859,
			104.921,
			104.984,
			105.014,
			105.044,
			105.106,
			105.123,
			105.169,
			105.233,
			105.294,
			105.355,
			105.417,
			105.447,
			105.478,
			105.54,
			105.602,
			105.665,
			105.727,
			105.775,
			105.788,
			105.852,
			105.881,
			105.914,
			105.974,
			105.992,
			106.038,
			106.101,
			106.16,
			106.208,
			106.286,
			106.318,
			106.349,
			106.409,
			106.471,
			106.533,
			106.596,
			106.641,
			106.659,
			106.72,
			106.751,
			106.783,
			106.845,
			106.863,
			106.905,
			106.97,
			107.03,
			107.075,
			107.091,
			107.154,
			107.185,
			107.214,
			107.278,
			107.294,
			107.339,
			107.401,
			107.463,
			107.51,
			107.588,
			107.617,
			107.648,
			107.713,
			107.774,
			107.836,
			107.896,
			107.946,
			107.959,
			108.022,
			108.052,
			108.081,
			108.146,
			108.16,
			108.206,
			108.269,
			108.332,
			108.378,
			108.393,
			108.455,
			108.493,
			108.516,
			108.581,
			108.595,
			108.642,
			108.704,
			108.766,
			108.827,
			108.89,
			108.921,
			108.952,
			109.014,
			109.075,
			109.139,
			109.2,
			109.248,
			109.261,
			109.323,
			109.356,
			109.386,
			109.449,
			109.464,
			109.511,
			109.571,
			109.632,
			109.68,
			109.697,
			109.758,
			109.79,
			109.819,
			109.882,
			109.898,
			109.945,
			110.008,
			110.069,
			110.114,
			110.191,
			110.222,
			110.255,
			110.315,
			110.377,
			110.439,
			110.503,
			110.563,
			110.626,
			110.656,
			110.689,
			110.749,
			110.767,
			110.812,
			110.875,
			110.936,
			110.981,
			110.998,
			111.061,
			111.09,
			111.122,
			111.199,
			111.247,
			111.308,
			111.37,
			111.415,
			111.495,
			111.526,
			111.556,
			111.618,
			111.634,
			111.681,
			111.742,
			111.804,
			111.865,
			111.929,
			111.958,
			111.99,
			112.052,
			112.069,
			112.114,
			112.177,
			112.237,
			112.286,
			112.362,
			112.392,
			112.426,
			112.502,
			112.549,
			112.614,
			112.672,
			112.719,
			112.734,
			112.796,
			112.828,
			112.857,
			112.922,
			112.936,
			112.982,
			113.043,
			113.106,
			113.168,
			113.231,
			113.26,
			113.292,
			113.369,
			113.418,
			113.479,
			113.542,
			113.602,
			113.664,
			113.696,
			113.725,
			113.789,
			113.852,
			113.912,
			113.974,
			114.02,
			114.037,
			114.099,
			114.13,
			114.16,
			114.223,
			114.237,
			114.285,
			114.346,
			114.409,
			114.47,
			114.531,
			114.563,
			114.595,
			114.656,
			114.678,
			114.717,
			114.782,
			114.842,
			114.89,
			114.966,
			114.997,
			115.03,
			115.09,
			115.153,
			115.215,
			115.276,
			115.339,
			115.402,
			115.43,
			115.464,
			115.526,
			115.539,
			115.586,
			115.648,
			115.712,
			115.758,
			115.772,
			115.835,
			115.865,
			115.898,
			115.975,
			116.021,
			116.082,
			116.145,
			116.192,
			116.27,
			116.299,
			116.331,
			116.392,
			116.409,
			116.454,
			116.515,
			116.578,
			116.625,
			116.64,
			116.703,
			116.739,
			116.763,
			116.828,
			116.841,
			116.889,
			116.952,
			117.014,
			117.06,
			117.136,
			117.169,
			117.2,
			117.26,
			117.322,
			117.387,
			117.448,
			117.493,
			117.51,
			117.572,
			117.604,
			117.634,
			117.695,
			117.712,
			117.758,
			117.819,
			117.882,
			117.928,
			117.943,
			118.005,
			118.035,
			118.066,
			118.128,
			118.146,
			118.192,
			118.253,
			118.315,
			118.361,
			118.439,
			118.471,
			118.503,
			118.563,
			118.627,
			118.689,
			118.75,
			118.802,
			118.812,
			118.875,
			118.905,
			118.935,
			118.997,
			119.013,
			119.061,
			119.122,
			119.183,
			119.23,
			119.245,
			119.309,
			119.34,
			119.371,
			119.448,
			119.493,
			119.555,
			119.617,
			119.663,
			119.743,
			119.772,
			119.802,
			119.865,
			119.928,
			119.989,
			120.053,
			120.099,
			120.113,
			120.177,
			120.207,
			120.239,
			120.316,
			120.363,
			120.424,
			120.487,
			120.533,
			120.547,
			120.61,
			120.642,
			120.672,
			120.75,
			120.796,
			120.864,
			120.921,
			120.964,
			121.045,
			121.075,
			121.106,
			121.169,
			121.184,
			121.229,
			121.291,
			121.354,
			121.4,
			121.417,
			121.477,
			121.51,
			121.541,
			121.603,
			121.618,
			121.665,
			121.727,
			121.788,
			121.848,
			121.912,
			121.943,
			121.973,
			122.037,
			122.097,
			122.161,
			122.221,
			122.269,
			122.284,
			122.346,
			122.376,
			122.409,
			122.471,
			122.486,
			122.53,
			122.595,
			122.658,
			122.719,
			122.779,
			122.811,
			122.843,
			122.903,
			122.924,
			122.965,
			123.028,
			123.089,
			123.136,
			123.214,
			123.246,
			123.275,
			123.339,
			123.402,
			123.463,
			123.524,
			123.57,
			123.587,
			123.648,
			123.681,
			123.71,
			123.773,
			123.787,
			123.833,
			123.895,
			123.96,
			124.004,
			124.021,
			124.08,
			124.114,
			124.145,
			124.206,
			124.221,
			124.266,
			124.33,
			124.393,
			124.455,
			124.518,
			124.548,
			124.578,
			124.642,
			124.704,
			124.763,
			124.825,
			124.887,
			124.951,
			124.987,
			125.013,
			125.09,
			125.138,
			125.203,
			125.261,
			125.307,
			125.322,
			125.385,
			125.416,
			125.448,
			125.509,
			125.57,
			125.632,
			125.694,
			125.74,
			125.819,
			125.848,
			125.882,
			125.943,
			125.959,
			126.005,
			126.068,
			126.09
		]
	],
	'7818_7819_7982': [
		[
			103.895
		],
		[
			0
		],
		[
			0,
			0.747,
			3.61,
			7.868,
			10.733,
			15.045,
			16.48,
			17.918,
			22.237,
			25.122,
			29.462,
			32.373,
			35.296,
			36.768,
			39.718,
			41.205,
			44.184,
			47.179,
			48.678,
			51.673,
			54.67,
			59.138,
			60.625,
			62.109,
			66.565,
			68.033,
			69.496,
			72.375,
			73.815,
			76.667,
			80.879,
			83.645,
			86.378,
			90.415,
			91.745,
			93.066,
			96.972,
			99.531,
			103.304,
			105.772,
			108.205,
			109.408,
			111.788,
			114.133,
			115.29,
			118.707,
			120.92,
			123.083,
			126.236,
			128.3,
			131.326,
			132.317,
			133.298,
			136.194,
			138.096,
			140.885,
			142.72,
			144.517,
			145.402,
			147.12,
			147.964,
			148.798,
			151.23,
			152.013,
			153.551,
			155.05,
			156.508,
			157.928,
			158.624,
			159.989,
			161.321,
			161.975,
			163.885,
			165.116,
			166.312,
			168.048,
			169.156,
			170.765,
			171.285,
			171.799,
			173.317,
			174.313,
			175.79,
			176.758,
			178.202,
			179.166,
			179.649,
			180.622,
			181.648,
			182.161,
			183.19,
			184.224,
			185.268,
			186.34,
			186.882,
			187.972,
			189.069,
			189.622,
			190.739,
			191.323,
			192.497,
			193.68,
			195.472,
			196.749,
			198.693,
			199.343,
			199.992,
			201.957,
			203.262,
			205.199,
			206.491,
			207.772,
			208.404,
			209.658,
			210.28,
			211.53,
			212.774,
			213.391,
			214.62,
			215.844,
			217.677,
			218.886,
			220.688,
			221.289,
			221.888,
			223.076,
			223.666,
			224.838,
			226.586,
			227.741,
			228.885,
			230.586,
			231.154,
			231.72,
			233.403,
			234.515,
			236.164,
			237.225,
			238.279,
			238.805,
			239.856,
			240.382,
			241.378,
			242.323,
			242.794,
			243.736,
			244.679,
			246.086,
			246.986,
			248.339,
			248.791,
			249.244,
			250.61,
			251.552,
			252.991,
			253.959,
			254.933,
			255.423,
			256.425,
			256.94,
			257.458,
			259.559,
			260.094,
			261.773,
			262.958,
			264.766,
			265.992,
			267.34,
			268.023,
			269.39,
			270.086,
			271.504,
			272.977,
			275.219,
			276.725,
			278.989,
			279.753,
			280.521,
			282.774,
			284.277,
			286.542,
			288.001,
			290.231,
			291.704,
			292.424,
			293.875,
			295.34,
			296.079,
			297.543,
			299.018,
			301.256,
			302.012,
			302.771,
			305.067,
			305.84,
			306.618,
			308.985,
			310.58,
			312.192,
			314.64,
			316.287,
			318.788,
			319.623,
			320.457,
			322.969,
			324.659,
			327.215,
			328.932,
			330.66,
			331.529,
			333.276,
			334.153,
			335.917,
			337.696,
			338.59,
			340.388,
			342.198,
			344.923,
			345.841,
			346.779,
			348.616,
			350.481,
			351.417,
			354.242,
			356.139,
			359.004,
			360.927,
			362.86,
			365.778,
			366.757,
			367.737,
			371.687,
			372.681,
			375.68,
			377.694,
			380.739,
			382.782,
			383.809,
			385.867,
			388.962,
			391.02,
			393.084,
			396.118,
			398.108,
			401.031,
			401.989,
			402.939,
			405.75,
			407.599,
			410.346,
			412.17,
			414.885,
			416.695,
			417.596,
			419.392,
			422.087,
			422.989,
			425.697,
			427.506,
			429.322,
			430.23,
			432.045,
			433.877,
			434.792,
			436.622,
			437.547,
			439.432,
			441.317,
			444.233,
			446.205,
			449.213,
			450.224,
			451.236,
			454.254,
			456.26,
			459.233,
			461.201,
			463.165,
			464.145,
			466.102,
			467.08,
			469.038,
			470.994,
			471.971,
			473.929,
			475.887,
			478.818,
			479.792,
			480.766,
			483.66,
			484.61,
			485.561,
			487.465,
			488.398,
			490.268,
			492.144,
			494.97,
			496.865,
			499.718,
			500.679,
			501.644,
			504.552,
			506.503,
			509.447,
			511.423,
			513.409,
			514.406,
			516.408,
			517.413,
			519.43,
			521.458,
			522.475,
			524.517,
			526.564,
			529.669,
			530.701,
			531.733,
			534.85,
			535.892,
			536.935,
			540.083,
			542.182,
			545.328,
			547.418,
			549.508,
			552.632,
			553.683,
			554.736,
			557.882,
			559.999,
			563.168,
			565.278,
			567.39,
			568.434,
			570.497,
			572.562,
			573.574,
			575.574,
			576.574,
			578.554,
			580.492,
			583.396,
			585.327,
			588.225,
			589.202,
			590.18,
			593.11,
			595.075,
			598.016,
			599.981,
			601.941,
			604.88,
			605.859,
			606.837,
			610.746,
			611.723,
			614.661,
			616.625,
			618.592,
			619.577,
			621.55,
			623.529,
			624.519,
			626.504,
			627.5,
			629.503,
			631.518,
			634.544,
			636.587,
			639.686,
			640.719,
			641.754,
			644.848,
			646.927,
			650.04,
			652.134,
			655.259,
			657.37,
			658.429,
			660.564,
			662.708,
			663.783,
			664.86,
			668.107,
			670.282,
			672.466,
			673.561,
			675.758,
			677.963,
			679.069,
			681.286,
			682.398,
			684.63,
			686.872,
			690.253,
			692.52,
			695.937,
			697.081,
			698.227,
			701.677,
			703.989,
			707.472,
			709.806,
			713.323,
			715.68,
			716.861,
			719.23,
			722.789,
			725.148,
			727.485,
			730.929,
			733.177,
			736.488,
			737.574,
			738.65,
			741.838,
			743.918,
			745.951,
			746.952,
			748.927,
			750.852,
			753.667,
			754.586,
			755.494,
			758.168,
			759.906,
			762.445,
			764.09,
			766.473,
			768.014,
			768.77,
			770.257,
			771.708,
			772.419,
			773.819,
			775.203,
			777.257,
			778.618,
			780.668,
			781.345,
			782.02,
			784.03,
			785.359,
			786.676,
			788.632,
			789.92,
			790.554,
			791.816,
			792.444,
			793.07,
			794.944,
			795.566,
			796.186,
			798.037,
			799.267,
			800.527,
			801.158,
			802.418,
			803.676,
			804.305,
			805.622,
			806.284,
			807.607,
			808.931,
			811.033,
			812.464,
			814.623,
			815.402,
			816.181,
			818.518,
			820.089,
			822.472,
			824.061,
			826.338,
			827.837,
			828.588,
			830.054,
			832.129,
			833.516,
			834.896,
			836.232,
			838.245,
			839.593,
			840.938,
			841.601,
			842.956,
			843.638,
			845.011,
			846.396,
			848.502,
			849.925,
			852.092,
			852.821,
			853.555,
			855.79,
			857.306,
			859.609,
			861.181,
			863.57,
			865.186,
			866.832,
			867.674,
			869.342,
			870.187,
			871.903,
			873.633,
			876.264,
			877.147,
			878.028,
			880.671,
			881.554,
			882.44,
			884.22,
			885.112,
			886.896,
			888.691,
			891.417,
			893.254,
			896.05,
			896.997,
			897.946,
			900.826,
			902.78,
			905.734,
			907.725,
			909.724,
			910.726,
			912.735,
			913.742,
			915.755,
			917.766,
			918.773,
			920.783,
			922.782,
			925.782,
			926.778,
			927.774,
			930.767,
			931.766,
			932.765,
			935.761,
			937.749,
			940.729,
			942.691,
			944.678,
			945.672,
			947.665,
			948.665,
			949.668,
			952.667,
			953.667,
			954.667,
			957.645,
			959.63,
			961.577,
			962.542,
			964.454,
			966.33,
			967.269,
			969.141,
			970.09,
			971.941,
			973.783,
			976.527,
			978.344,
			981.069,
			981.987,
			982.903,
			985.653,
			987.542,
			990.367,
			992.274,
			994.178,
			995.13,
			996.982,
			997.905,
			999.751,
			1001.588,
			1002.504,
			1004.337,
			1006.203,
			1008.111,
			1010.024,
			1011.005,
			1013,
			1015.001,
			1016.02,
			1018.088,
			1019.123,
			1021.196,
			1024.309,
			1026.348,
			1028.335,
			1031.269,
			1032.203,
			1033.138,
			1035.919,
			1037.686,
			1040.342,
			1042.108,
			1043.879,
			1044.766,
			1046.578,
			1047.499,
			1049.35,
			1052.206,
			1054.156,
			1056.129,
			1059.159,
			1061.201,
			1064.291,
			1065.333,
			1066.379,
			1069.552,
			1071.65,
			1073.73,
			1076.84,
			1078.891,
			1081.956,
			1082.983,
			1084.027,
			1087.102,
			1089.139,
			1092.161,
			1094.147,
			1097.085,
			1099.01,
			1099.978,
			1101.917,
			1103.829,
			1104.783,
			1106.683,
			1108.571,
			1111.391,
			1113.269,
			1116.077,
			1117.026,
			1117.974,
			1120.816,
			1122.744,
			1125.649,
			1127.618,
			1129.588,
			1132.636,
			1133.663,
			1135.724,
			1138.82,
			1139.857,
			1142.959,
			1145.029,
			1147.111,
			1148.156,
			1150.25,
			1152.343,
			1153.37,
			1155.473,
			1156.509,
			1158.551,
			1160.586,
			1163.626,
			1165.65,
			1168.687,
			1169.71,
			1170.734,
			1173.814,
			1175.897,
			1179.003,
			1181.085,
			1183.167,
			1184.208,
			1186.296,
			1187.347,
			1189.44,
			1191.563,
			1192.629,
			1193.698,
			1196.939,
			1199.097,
			1201.266,
			1202.357,
			1204.528,
			1206.728,
			1207.834,
			1210.052,
			1211.163,
			1213.382,
			1215.598,
			1218.898,
			1221.07,
			1224.272,
			1225.322,
			1226.363,
			1229.424,
			1231.419,
			1234.343,
			1236.248,
			1238.119,
			1239.041,
			1240.861,
			1241.759,
			1243.531,
			1245.269,
			1246.125,
			1247.806,
			1249.448,
			1251.846,
			1252.625,
			1253.397,
			1255.687,
			1256.443,
			1257.195,
			1258.702,
			1259.448,
			1260.938,
			1262.432,
			1264.686,
			1266.192,
			1268.478,
			1269.246,
			1270.015,
			1272.333,
			1273.885,
			1276.226,
			1277.797,
			1279.376,
			1280.17,
			1281.767,
			1282.573,
			1284.196,
			1286.652,
			1288.317,
			1289.994,
			1292.536,
			1294.254,
			1296.85,
			1297.726,
			1298.607,
			1301.262,
			1303.057,
			1305.786,
			1307.618,
			1309.49,
			1312.306,
			1313.266,
			1314.228,
			1317.116,
			1318.084,
			1319.052,
			1321.945,
			1323.817,
			1325.662,
			1326.581,
			1328.372,
			1330.115,
			1330.984,
			1332.716,
			1333.564,
			1335.254,
			1336.94,
			1339.44,
			1341.096,
			1343.595,
			1344.436,
			1345.277,
			1347.812,
			1349.551,
			1352.165,
			1353.925,
			1355.693,
			1356.579,
			1358.358,
			1359.252,
			1361.044,
			1362.844,
			1363.748,
			1364.654,
			1367.383,
			1369.213,
			1371.052,
			1371.975,
			1373.812,
			1375.671,
			1376.603,
			1379.398,
			1381.252,
			1383.089,
			1385.795,
			1387.574,
			1390.17,
			1391.023,
			1391.867,
			1394.373,
			1396.021,
			1398.455,
			1400.057,
			1402.45,
			1404.038,
			1404.843,
			1406.43,
			1408.018,
			1408.814,
			1410.406,
			1412,
			1414.388,
			1415.979,
			1417.569,
			1419.165,
			1419.964,
			1422.363,
			1423.974,
			1425.595,
			1428.035,
			1429.67,
			1432.133,
			1432.958,
			1433.786,
			1436.281,
			1437.955,
			1440.481,
			1442.177,
			1443.885,
			1444.743,
			1446.468,
			1448.206,
			1449.08,
			1451.724,
			1453.503,
			1455.286,
			1457.984,
			1459.788,
			1462.484,
			1463.387,
			1464.285,
			1466.957,
			1468.707,
			1471.263,
			1472.939,
			1475.357,
			1476.923,
			1477.68,
			1478.441,
			1481.407,
			1482.123,
			1484.214,
			1485.566,
			1486.883,
			1487.531,
			1488.808,
			1490.057,
			1490.673,
			1491.894,
			1492.5,
			1493.704,
			1494.898,
			1496.678,
			1497.862,
			1499.647,
			1500.242,
			1500.837,
			1502.622,
			1503.817,
			1505.624,
			1506.828,
			1508.036,
			1508.643,
			1509.869,
			1510.482,
			1511.709,
			1512.941,
			1513.558,
			1514.188,
			1516.076,
			1517.337,
			1518.601,
			1519.249,
			1520.545,
			1521.841,
			1522.489,
			1523.79,
			1524.455,
			1525.785,
			1527.114,
			1529.118,
			1530.488,
			1532.537,
			1533.22,
			1533.909,
			1536.022,
			1537.43,
			1539.559,
			1540.992,
			1542.428,
			1543.148,
			1544.595,
			1545.32,
			1546.773,
			1548.228,
			1548.956,
			1550.408,
			1551.863,
			1554.048,
			1554.773,
			1555.499,
			1557.678,
			1558.405,
			1559.133,
			1560.588,
			1561.315,
			1562.769,
			1564.225,
			1566.419,
			1567.89,
			1570.095,
			1570.832,
			1571.574,
			1573.835,
			1575.326,
			1577.6,
			1579.139,
			1581.43,
			1582.979,
			1583.76,
			1585.3,
			1586.851,
			1587.632,
			1589.207,
			1590.773,
			1593.145,
			1593.941,
			1594.738,
			1597.135,
			1597.939,
			1598.745,
			1601.151,
			1602.751,
			1605.147,
			1606.741,
			1608.34,
			1610.777,
			1611.594,
			1612.413,
			1614.89,
			1615.731,
			1616.572,
			1619.098,
			1620.777,
			1623.295,
			1624.968,
			1626.63,
			1627.461,
			1629.95,
			1631.6,
			1633.248,
			1635.72,
			1637.367,
			1639.841,
			1640.67,
			1641.5,
			1643.975,
			1645.664,
			1648.2,
			1649.904,
			1651.61,
			1652.467,
			1654.191,
			1655.058,
			1656.802,
			1658.558,
			1659.441,
			1660.338,
			1663.052,
			1665.795,
			1667.64,
			1669.497,
			1671.361,
			1672.297,
			1675.128,
			1677.04,
			1678.962,
			1681.879,
			1683.84,
			1686.808,
			1687.803,
			1688.8,
			1691.803,
			1693.815,
			1696.859,
			1698.896,
			1700.938,
			1701.967,
			1704.02,
			1705.046,
			1707.129,
			1710.252,
			1712.357,
			1714.463,
			1717.621,
			1718.684,
			1719.748,
			1722.952,
			1724.013,
			1725.072,
			1728.242,
			1730.329,
			1732.426,
			1735.567,
			1737.653,
			1740.813,
			1741.861,
			1742.912,
			1746.077,
			1748.193,
			1751.38,
			1753.493,
			1755.631,
			1756.701,
			1758.847,
			1759.922,
			1762.079,
			1764.242,
			1765.326,
			1767.5,
			1769.682,
			1772.97,
			1775.173,
			1778.493,
			1779.603,
			1780.715,
			1784.089,
			1786.33,
			1789.711,
			1791.953,
			1794.191,
			1797.497,
			1798.588,
			1799.667,
			1803.865,
			1804.894,
			1807.913,
			1809.882,
			1811.823,
			1812.798,
			1814.699,
			1816.581,
			1817.514,
			1819.375,
			1820.289,
			1822.144,
			1823.988,
			1826.756,
			1828.6,
			1831.353,
			1832.272,
			1833.191,
			1835.959,
			1837.817,
			1840.621,
			1842.505,
			1844.401,
			1845.353,
			1847.267,
			1848.234,
			1850.16,
			1852.129,
			1853.113,
			1855.088,
			1857.073,
			1859.067,
			1862.074,
			1864.098,
			1866.125,
			1867.138,
			1869.165,
			1870.18,
			1872.217,
			1874.265,
			1877.346,
			1879.402,
			1882.496,
			1883.518,
			1884.525,
			1887.605,
			1889.655,
			1892.745,
			1894.805,
			1896.876,
			1897.914,
			1899.997,
			1901.06,
			1903.154,
			1905.237,
			1906.292,
			1908.405,
			1910.526,
			1912.656,
			1915.86,
			1919.079,
			1920.155,
			1921.234,
			1923.4,
			1924.487,
			1926.668,
			1928.86,
			1932.184,
			1934.393,
			1937.72,
			1938.835,
			1939.953,
			1943.307,
			1944.441,
			1945.579,
			1949.008,
			1951.329,
			1953.642,
			1954.802,
			1957.131,
			1958.299,
			1960.634,
			1962.961,
			1964.136,
			1966.492,
			1968.861,
			1972.439,
			1974.837,
			1978.452,
			1979.661,
			1980.871,
			1984.516,
			1986.956,
			1990.632,
			1993.093,
			1995.564,
			1999.287,
			2000.534,
			2001.782,
			2005.539,
			2006.794,
			2008.052,
			2011.838,
			2014.373,
			2016.92,
			2018.198,
			2020.758,
			2023.32,
			2024.598,
			2027.142,
			2028.406,
			2030.91,
			2033.38,
			2037.012,
			2039.385,
			2042.872,
			2044.016,
			2045.15,
			2048.493,
			2050.676,
			2053.879,
			2055.969,
			2058.021,
			2059.033,
			2061.03,
			2062.016,
			2063.959,
			2065.868,
			2066.81,
			2067.744,
			2070.495,
			2072.284,
			2074.041,
			2074.907,
			2076.603,
			2078.26,
			2079.078,
			2080.706,
			2081.506,
			2083.092,
			2084.661,
			2087.011,
			2088.578,
			2090.926,
			2091.712,
			2092.497,
			2094.853,
			2096.416,
			2098.742,
			2100.292,
			2101.833,
			2102.599,
			2104.13,
			2104.896,
			2106.431,
			2108.743,
			2110.283,
			2111.835,
			2113.396,
			2114.957,
			2115.737,
			2117.308,
			2118.881,
			2119.667,
			2121.24,
			2122.031,
			2123.611,
			2125.191,
			2127.572,
			2129.165,
			2131.559,
			2132.368,
			2133.176,
			2135.604,
			2137.248,
			2139.739,
			2141.409,
			2143.13,
			2143.992,
			2145.722,
			2146.594,
			2148.374,
			2150.162,
			2151.059,
			2152.868,
			2154.688,
			2157.426,
			2159.25,
			2162.001,
			2162.91,
			2163.821,
			2166.575,
			2168.402,
			2170.241,
			2173.015,
			2174.875,
			2177.683,
			2178.621,
			2179.562,
			2182.398,
			2183.343,
			2184.291,
			2187.157,
			2189.063,
			2190.999,
			2191.972,
			2193.923,
			2194.901,
			2196.86,
			2198.811,
			2199.784,
			2201.722,
			2203.638,
			2206.46,
			2208.299,
			2210.99,
			2211.869,
			2212.741,
			2215.306,
			2216.973,
			2219.45,
			2221.078,
			2222.704,
			2223.531,
			2225.193,
			2226.015,
			2227.659,
			2229.32,
			2230.149,
			2230.977,
			2233.473,
			2235.147,
			2237.662,
			2239.346,
			2241.031,
			2241.875,
			2243.547,
			2244.379,
			2246.048,
			2247.719,
			2250.186,
			2251.844,
			2254.299,
			2255.101,
			2255.92,
			2258.389,
			2260.037,
			2262.516,
			2264.199,
			2265.883,
			2266.722,
			2268.415,
			2269.279,
			2271.002,
			2272.719,
			2273.591,
			2275.351,
			2277.089,
			2278.823,
			2281.413,
			2283.975,
			2284.819,
			2285.661,
			2288.178,
			2289.813,
			2292.248,
			2293.874,
			2295.464,
			2297.852,
			2298.644,
			2299.434,
			2302.594,
			2303.385,
			2305.765,
			2307.354,
			2308.95,
			2309.752,
			2311.358,
			2312.163,
			2313.782,
			2316.261,
			2317.924,
			2319.597,
			2322.128,
			2323.83,
			2326.4,
			2327.264,
			2328.132,
			2330.778,
			2332.567,
			2334.374,
			2337.123,
			2338.977,
			2341.788,
			2342.733,
			2343.683,
			2346.558,
			2348.497,
			2351.411,
			2353.382,
			2355.364,
			2356.359,
			2358.359,
			2360.37,
			2361.38,
			2363.41,
			2364.429,
			2366.473,
			2368.53,
			2371.635,
			2373.72,
			2376.861,
			2377.907,
			2378.954,
			2382.106,
			2384.221,
			2387.413,
			2389.555,
			2391.708,
			2394.96,
			2396.05,
			2397.142,
			2401.539,
			2402.645,
			2405.979,
			2408.215,
			2410.462,
			2411.589,
			2413.852,
			2416.126,
			2417.267,
			2419.557,
			2420.706,
			2423.012,
			2425.328,
			2428.822,
			2431.164,
			2434.697,
			2435.88,
			2437.067,
			2440.641,
			2443.037,
			2446.65,
			2449.072,
			2451.503,
			2452.722,
			2455.168,
			2456.395,
			2458.856,
			2461.327,
			2462.566,
			2465.05,
			2467.544,
			2470.048,
			2473.821,
			2476.348,
			2478.885,
			2480.156,
			2482.706,
			2483.984,
			2486.548,
			2489.12,
			2492.994,
			2495.588,
			2499.493,
			2500.794,
			2502.098,
			2506.033,
			2508.668,
			2512.637,
			2515.286,
			2517.943,
			2519.272,
			2521.938,
			2523.273,
			2525.95,
			2528.634,
			2529.979,
			2532.676,
			2535.379,
			2539.449,
			2542.171,
			2544.902,
			2547.641,
			2549.013,
			2553.142,
			2555.915,
			2558.698,
			2562.896,
			2565.701,
			2569.913,
			2571.317,
			2572.723,
			2576.951,
			2579.778,
			2584.033,
			2586.879,
			2591.161,
			2594.026,
			2595.46,
			2598.334,
			2601.216,
			2602.66,
			2605.552,
			2608.45,
			2612.811,
			2615.728,
			2620.127,
			2621.598,
			2623.071,
			2627.497,
			2630.45,
			2634.887,
			2637.852,
			2640.823,
			2645.291,
			2646.783,
			2648.278,
			2652.772,
			2654.273,
			2655.777,
			2660.298,
			2663.321,
			2667.869,
			2670.909,
			2673.957,
			2675.484,
			2678.544,
			2680.077,
			2683.148,
			2686.229,
			2690.869,
			2694,
			2698.668,
			2700.197,
			2701.753,
			2706.428,
			2709.55,
			2714.243,
			2717.378,
			2720.518,
			2722.09,
			2725.238,
			2726.814,
			2728.391,
			2733.132,
			2734.714,
			2736.298,
			2741.057,
			2744.236,
			2747.421,
			2749.014,
			2752.214,
			2755.422,
			2757.032,
			2761.868,
			2765.097,
			2768.326,
			2773.167,
			2776.395,
			2781.243,
			2782.862,
			2784.481,
			2789.349,
			2792.601,
			2797.487,
			2800.751,
			2805.657,
			2808.933,
			2810.574,
			2813.858,
			2817.148,
			2818.795,
			2822.092,
			2825.391,
			2830.323,
			2831.958,
			2833.587,
			2838.427,
			2840.021,
			2841.607,
			2846.307,
			2849.392,
			2853.952,
			2856.948,
			2859.908,
			2864.279,
			2865.717,
			2867.145,
			2871.374,
			2874.146,
			2878.235,
			2880.915,
			2883.559,
			2884.867,
			2887.455,
			2890.008,
			2891.271,
			2893.77,
			2895.006,
			2897.451,
			2899.86,
			2903.412,
			2905.752,
			2909.167,
			2910.286,
			2911.395,
			2914.674,
			2916.82,
			2918.936,
			2922.07,
			2924.14,
			2927.248,
			2928.287,
			2929.329,
			2932.474,
			2933.528,
			2934.585,
			2937.774,
			2939.91,
			2942.054,
			2943.126,
			2945.269,
			2947.41,
			2948.476,
			2950.597,
			2951.653,
			2953.745,
			2955.81,
			2958.851,
			2960.839,
			2963.769,
			2964.734,
			2965.696,
			2968.572,
			2970.487,
			2973.375,
			2975.307,
			2977.248,
			2978.22,
			2980.167,
			2981.141,
			2983.096,
			2985.051,
			2986.027,
			2987.986,
			2989.938,
			2991.891,
			2994.82,
			2996.769,
			2998.728,
			2999.705,
			3002.63,
			3004.576,
			3006.515,
			3009.397,
			3011.316,
			3014.193,
			3015.152,
			3016.112,
			3018.985,
			3020.901,
			3023.783,
			3025.711,
			3027.645,
			3028.614,
			3030.557,
			3031.53,
			3033.479,
			3035.43,
			3036.406,
			3038.36,
			3040.317,
			3043.267,
			3044.257,
			3045.249,
			3048.237,
			3049.239,
			3050.243,
			3052.259,
			3053.271,
			3055.302,
			3057.345,
			3060.431,
			3062.502,
			3065.638,
			3066.69,
			3067.745,
			3070.936,
			3073.078,
			3076.303,
			3078.467,
			3080.636,
			3081.724,
			3083.908,
			3086.099,
			3087.198,
			3090.51,
			3092.729,
			3094.957,
			3098.317,
			3100.565,
			3103.954,
			3105.082,
			3106.212,
			3109.616,
			3111.88,
			3115.288,
			3117.554,
			3119.831,
			3120.961,
			3123.229,
			3124.367,
			3125.51,
			3128.956,
			3130.109,
			3131.281,
			3134.814,
			3137.203,
			3140.817,
			3143.255,
			3145.702,
			3146.934,
			3149.405,
			3150.644,
			3153.131,
			3155.626,
			3159.363,
			3161.85,
			3165.571,
			3166.804,
			3168.033,
			3171.741,
			3174.237,
			3178.001,
			3180.55,
			3183.146,
			3184.437,
			3187.024,
			3188.326,
			3190.934,
			3193.55,
			3194.862,
			3196.155,
			3200.095,
			3202.729,
			3205.366,
			3206.689,
			3209.335,
			3211.989,
			3213.316,
			3215.974,
			3217.307,
			3219.972,
			3222.644,
			3226.67,
			3229.367,
			3233.434,
			3234.794,
			3236.157,
			3240.259,
			3243.005,
			3247.141,
			3249.912,
			3252.703,
			3254.102,
			3256.917,
			3258.328,
			3261.16,
			3264.002,
			3265.426,
			3268.28,
			3271.143,
			3275.449,
			3276.889,
			3278.331,
			3282.666,
			3284.115,
			3285.565,
			3288.47,
			3289.926,
			3292.841,
			3295.764,
			3300.16,
			3303.1,
			3307.521,
			3308.998,
			3310.477,
			3314.924,
			3316.409,
			3317.896,
			3322.367,
			3325.357,
			3328.355,
			3329.861,
			3332.877,
			3334.394,
			3337.438,
			3340.485,
			3342.011,
			3345.055,
			3348.097,
			3352.673,
			3355.731,
			3360.326,
			3361.857,
			3363.39,
			3367.99,
			3371.062,
			3375.68,
			3378.766,
			3381.858,
			3386.507,
			3388.06,
			3391.167,
			3394.276,
			3395.832,
			3397.388,
			3402.064,
			3405.187,
			3409.879,
			3413.013,
			3416.151,
			3417.721,
			3420.866,
			3422.439,
			3425.59,
			3428.745,
			3433.484,
			3436.649,
			3441.404,
			3442.991,
			3444.579,
			3449.347,
			3452.528,
			3457.306,
			3460.506,
			3463.718,
			3465.341,
			3468.587,
			3470.211,
			3473.458,
			3476.698,
			3478.312,
			3479.918,
			3484.69,
			3487.818,
			3492.446,
			3495.491,
			3498.503,
			3499.997,
			3504.439,
			3507.37,
			3510.269,
			3514.509,
			3517.269,
			3521.33,
			3522.663,
			3523.988,
			3527.915,
			3530.488,
			3534.295,
			3536.794,
			3539.266,
			3540.489,
			3542.918,
			3544.122,
			3546.528,
			3548.896,
			3550.069,
			3552.418,
			3554.771,
			3558.28,
			3560.613,
			3564.075,
			3565.234,
			3566.393,
			3568.698,
			3569.839,
			3572.12,
			3574.395,
			3577.808,
			3580.081,
			3583.484,
			3584.616,
			3585.748,
			3589.142,
			3590.273,
			3591.406,
			3594.817,
			3597.102,
			3599.39,
			3600.536,
			3602.835,
			3603.984,
			3606.285,
			3608.575,
			3609.727,
			3612.037,
			3614.356,
			3617.845,
			3620.162,
			3623.597,
			3624.747,
			3625.869,
			3629.183,
			3631.364,
			3634.572,
			3636.683,
			3638.776,
			3641.935,
			3642.971,
			3644,
			3648.087,
			3649.093,
			3652.077,
			3654.025,
			3656.907,
			3658.805,
			3660.694,
			3661.638,
			3663.53,
			3664.479,
			3666.387,
			3668.304,
			3671.178,
			3673.086,
			3675.932,
			3676.867,
			3677.801,
			3680.589,
			3682.417,
			3685.143,
			3686.931,
			3688.703,
			3689.584,
			3691.322,
			3692.181,
			3693.035,
			3695.569,
			3696.396,
			3698.034,
			3699.647,
			3701.229,
			3702.775,
			3703.536,
			3705.031,
			3706.497,
			3707.218,
			3709.338,
			3710.721,
			3712.09,
			3714.113,
			3715.448,
			3717.464,
			3718.139,
			3718.813,
			3720.841,
			3722.2,
			3724.245,
			3725.612,
			3727.661,
			3729.023,
			3729.705,
			3731.069,
			3732.427,
			3733.103,
			3734.453,
			3735.802,
			3737.819,
			3738.487,
			3739.154,
			3741.15,
			3741.814,
			3742.479,
			3744.468,
			3745.787,
			3747.102,
			3749.069,
			3750.371,
			3752.31,
			3752.959,
			3753.607,
			3755.543,
			3756.83,
			3758.756,
			3760.032,
			3761.3,
			3761.931,
			3763.197,
			3763.837,
			3765.109,
			3766.997,
			3768.257,
			3769.548,
			3771.463,
			3772.731,
			3774.675,
			3775.323,
			3775.97,
			3777.903,
			3779.217,
			3781.198,
			3782.515,
			3783.848,
			3785.889,
			3786.569,
			3787.249,
			3789.315,
			3790.712,
			3792.812,
			3794.233,
			3795.672,
			3796.393,
			3797.838,
			3799.29,
			3800.018,
			3801.474,
			3802.204,
			3803.67,
			3805.117,
			3807.292,
			3808.75,
			3810.853,
			3811.556,
			3812.26,
			3814.357,
			3815.71,
			3817.75,
			3819.116,
			3821.123,
			3822.471,
			3823.149,
			3824.513,
			3826.582,
			3827.277,
			3829.385,
			3830.836,
			3833.037,
			3834.523,
			3836.028,
			3836.786,
			3838.314,
			3839.084,
			3840.643,
			3842.219,
			3844.611,
			3846.238,
			3848.709,
			3849.54,
			3850.376,
			3852.894,
			3854.574,
			3857.11,
			3858.827,
			3861.432,
			3863.188,
			3864.071,
			3865.847,
			3867.637,
			3868.537,
			3870.347,
			3872.168,
			3874.925,
			3875.851,
			3876.779,
			3878.647,
			3880.528,
			3881.474,
			3883.375,
			3884.331,
			3886.251,
			3888.183,
			3891.106,
			3893.071,
			3896.042,
			3897.039,
			3898.04,
			3901.06,
			3903.091,
			3906.16,
			3908.223,
			3910.297,
			3911.339,
			3913.432,
			3915.532,
			3916.581,
			3919.736,
			3921.852,
			3923.979,
			3927.191,
			3929.347,
			3932.605,
			3933.696,
			3934.79,
			3938.086,
			3940.296,
			3942.515,
			3945.861,
			3948.105,
			3949.23,
			3951.489,
			3952.622,
			3953.758,
			3957.18,
			3958.326,
			3959.474,
			3962.935,
			3965.254,
			3967.582,
			3968.75,
			3971.094,
			3973.447,
			3974.628,
			3976.995,
			3978.183,
			3980.564,
			3982.955,
			3986.558,
			3988.972,
			3992.61,
			3993.827,
			3995.046,
			3998.717,
			4001.174,
			4004.878,
			4007.357,
			4011.092,
			4013.592,
			4014.845,
			4017.358,
			4019.879,
			4021.142,
			4022.407,
			4026.215,
			4028.764,
			4031.321,
			4032.602,
			4035.17,
			4037.747,
			4039.038,
			4042.922,
			4045.521,
			4048.126,
			4052.034,
			4054.646,
			4058.577,
			4059.89,
			4061.206,
			4065.162,
			4067.809,
			4071.791,
			4074.454,
			4077.123,
			4078.461,
			4081.141,
			4082.484,
			4085.174,
			4087.871,
			4089.222,
			4091.929,
			4094.643,
			4098.727,
			4100.092,
			4101.458,
			4105.581,
			4106.96,
			4108.337,
			4111.079,
			4112.452,
			4115.209,
			4117.978,
			4119.364,
			4122.142,
			4124.925,
			4129.119,
			4130.521,
			4131.919,
			4136.123,
			4138.95,
			4143.201,
			4146.039,
			4148.887,
			4150.298,
			4153.118,
			4154.526,
			4157.31,
			4161.503,
			4164.291,
			4167.092,
			4171.32,
			4171.676
		],
		[
			0,
			0.012,
			0.073,
			0.135,
			0.182,
			0.259,
			0.292,
			0.322,
			0.385,
			0.447,
			0.508,
			0.57,
			0.616,
			0.632,
			0.694,
			0.724,
			0.757,
			0.817,
			0.833,
			0.881,
			0.94,
			1.005,
			1.049,
			1.067,
			1.128,
			1.158,
			1.191,
			1.25,
			1.266,
			1.314,
			1.377,
			1.437,
			1.483,
			1.562,
			1.594,
			1.623,
			1.687,
			1.747,
			1.808,
			1.872,
			1.921,
			1.935,
			1.996,
			2.028,
			2.057,
			2.135,
			2.182,
			2.246,
			2.306,
			2.368,
			2.43,
			2.461,
			2.491,
			2.554,
			2.617,
			2.678,
			2.739,
			2.786,
			2.803,
			2.865,
			2.896,
			2.925,
			2.989,
			3.005,
			3.051,
			3.113,
			3.174,
			3.221,
			3.235,
			3.297,
			3.329,
			3.358,
			3.437,
			3.484,
			3.547,
			3.609,
			3.654,
			3.731,
			3.762,
			3.792,
			3.857,
			3.919,
			3.982,
			4.041,
			4.105,
			4.167,
			4.196,
			4.229,
			4.291,
			4.304,
			4.352,
			4.414,
			4.474,
			4.524,
			4.537,
			4.6,
			4.633,
			4.662,
			4.725,
			4.74,
			4.785,
			4.848,
			4.912,
			4.958,
			5.034,
			5.066,
			5.096,
			5.175,
			5.222,
			5.283,
			5.345,
			5.391,
			5.407,
			5.469,
			5.499,
			5.53,
			5.594,
			5.607,
			5.656,
			5.716,
			5.778,
			5.842,
			5.902,
			5.933,
			5.963,
			6.025,
			6.046,
			6.088,
			6.152,
			6.212,
			6.257,
			6.338,
			6.368,
			6.398,
			6.475,
			6.522,
			6.584,
			6.647,
			6.694,
			6.71,
			6.77,
			6.801,
			6.833,
			6.895,
			6.91,
			6.957,
			7.018,
			7.08,
			7.143,
			7.205,
			7.237,
			7.267,
			7.346,
			7.39,
			7.454,
			7.514,
			7.562,
			7.577,
			7.64,
			7.67,
			7.701,
			7.779,
			7.824,
			7.886,
			7.949,
			8.011,
			8.072,
			8.107,
			8.135,
			8.196,
			8.212,
			8.258,
			8.32,
			8.384,
			8.431,
			8.507,
			8.537,
			8.569,
			8.63,
			8.693,
			8.756,
			8.816,
			8.879,
			8.94,
			8.973,
			9.003,
			9.066,
			9.081,
			9.128,
			9.189,
			9.252,
			9.298,
			9.314,
			9.376,
			9.405,
			9.438,
			9.515,
			9.563,
			9.624,
			9.685,
			9.731,
			9.808,
			9.839,
			9.87,
			9.934,
			9.994,
			10.062,
			10.12,
			10.172,
			10.18,
			10.245,
			10.274,
			10.306,
			10.367,
			10.384,
			10.429,
			10.491,
			10.555,
			10.6,
			10.616,
			10.676,
			10.709,
			10.739,
			10.802,
			10.865,
			10.927,
			10.988,
			11.048,
			11.111,
			11.144,
			11.173,
			11.251,
			11.299,
			11.359,
			11.422,
			11.485,
			11.546,
			11.575,
			11.608,
			11.686,
			11.731,
			11.795,
			11.855,
			11.903,
			11.98,
			12.01,
			12.04,
			12.103,
			12.166,
			12.233,
			12.289,
			12.352,
			12.414,
			12.445,
			12.477,
			12.554,
			12.599,
			12.663,
			12.722,
			12.769,
			12.786,
			12.846,
			12.879,
			12.909,
			12.971,
			12.988,
			13.033,
			13.097,
			13.159,
			13.22,
			13.283,
			13.312,
			13.342,
			13.423,
			13.469,
			13.531,
			13.59,
			13.639,
			13.655,
			13.717,
			13.747,
			13.779,
			13.84,
			13.856,
			13.902,
			13.963,
			14.027,
			14.071,
			14.088,
			14.151,
			14.181,
			14.212,
			14.274,
			14.294,
			14.335,
			14.397,
			14.458,
			14.506,
			14.584,
			14.615,
			14.645,
			14.723,
			14.77,
			14.831,
			14.895,
			14.939,
			14.956,
			15.017,
			15.05,
			15.08,
			15.141,
			15.158,
			15.205,
			15.267,
			15.328,
			15.375,
			15.389,
			15.453,
			15.483,
			15.513,
			15.577,
			15.639,
			15.702,
			15.762,
			15.824,
			15.887,
			15.919,
			15.947,
			16.011,
			16.071,
			16.136,
			16.198,
			16.244,
			16.257,
			16.32,
			16.357,
			16.381,
			16.445,
			16.461,
			16.507,
			16.569,
			16.629,
			16.678,
			16.754,
			16.785,
			16.817,
			16.879,
			16.94,
			17.003,
			17.066,
			17.111,
			17.188,
			17.219,
			17.25,
			17.329,
			17.375,
			17.44,
			17.498,
			17.545,
			17.562,
			17.623,
			17.654,
			17.685,
			17.745,
			17.764,
			17.808,
			17.872,
			17.932,
			17.978,
			18.055,
			18.086,
			18.119,
			18.195,
			18.243,
			18.306,
			18.366,
			18.428,
			18.491,
			18.521,
			18.552,
			18.614,
			18.631,
			18.675,
			18.738,
			18.801,
			18.847,
			18.863,
			18.925,
			18.957,
			18.987,
			19.05,
			19.063,
			19.111,
			19.172,
			19.236,
			19.282,
			19.358,
			19.39,
			19.422,
			19.484,
			19.546,
			19.606,
			19.669,
			19.73,
			19.792,
			19.823,
			19.855,
			19.933,
			19.979,
			20.04,
			20.104,
			20.165,
			20.228,
			20.257,
			20.29,
			20.351,
			20.414,
			20.474,
			20.479,
			20.538,
			20.584,
			20.662,
			20.692,
			20.722,
			20.786,
			20.847,
			20.908,
			20.971,
			21.032,
			21.095,
			21.125,
			21.157,
			21.22,
			21.234,
			21.28,
			21.342,
			21.406,
			21.452,
			21.529,
			21.561,
			21.591,
			21.654,
			21.716,
			21.776,
			21.839,
			21.886,
			21.902,
			21.963,
			21.996,
			22.026,
			22.088,
			22.104,
			22.15,
			22.211,
			22.273,
			22.321,
			22.335,
			22.399,
			22.43,
			22.458,
			22.523,
			22.542,
			22.583,
			22.647,
			22.706,
			22.755,
			22.831,
			22.862,
			22.892,
			22.955,
			23.016,
			23.084,
			23.141,
			23.205,
			23.266,
			23.297,
			23.328,
			23.406,
			23.453,
			23.513,
			23.575,
			23.638,
			23.701,
			23.73,
			23.762,
			23.824,
			23.838,
			23.886,
			23.947,
			24.008,
			24.055,
			24.133,
			24.163,
			24.197,
			24.258,
			24.32,
			24.384,
			24.442,
			24.505,
			24.569,
			24.603,
			24.63,
			24.692,
			24.708,
			24.754,
			24.82,
			24.879,
			24.925,
			24.939,
			25.003,
			25.034,
			25.063,
			25.126,
			25.14,
			25.188,
			25.25,
			25.313,
			25.36,
			25.435,
			25.466,
			25.498,
			25.561,
			25.622,
			25.685,
			25.747,
			25.793,
			25.808,
			25.87,
			25.9,
			25.931,
			25.995,
			26.01,
			26.055,
			26.12,
			26.18,
			26.225,
			26.242,
			26.304,
			26.334,
			26.367,
			26.445,
			26.489,
			26.552,
			26.613,
			26.668,
			26.678,
			26.739,
			26.769,
			26.799,
			26.863,
			26.878,
			26.924,
			26.986,
			27.048,
			27.095,
			27.111,
			27.171,
			27.202,
			27.235,
			27.297,
			27.312,
			27.359,
			27.419,
			27.482,
			27.529,
			27.606,
			27.636,
			27.669,
			27.73,
			27.794,
			27.855,
			27.918,
			27.964,
			27.98,
			28.041,
			28.073,
			28.101,
			28.163,
			28.18,
			28.227,
			28.289,
			28.349,
			28.397,
			28.413,
			28.475,
			28.506,
			28.538,
			28.598,
			28.614,
			28.661,
			28.728,
			28.785,
			28.832,
			28.908,
			28.938,
			28.971,
			29.033,
			29.096,
			29.156,
			29.218,
			29.266,
			29.279,
			29.342,
			29.375,
			29.405,
			29.482,
			29.529,
			29.591,
			29.652,
			29.713,
			29.777,
			29.806,
			29.838,
			29.917,
			29.963,
			30.025,
			30.087,
			30.132,
			30.212,
			30.24,
			30.272,
			30.35,
			30.396,
			30.459,
			30.521,
			30.582,
			30.645,
			30.675,
			30.707,
			30.768,
			30.79,
			30.831,
			30.892,
			30.955,
			31.001,
			31.079,
			31.108,
			31.141,
			31.203,
			31.266,
			31.328,
			31.388,
			31.435,
			31.513,
			31.545,
			31.576,
			31.652,
			31.7,
			31.761,
			31.822,
			31.868,
			31.885,
			31.948,
			31.979,
			32.008,
			32.072,
			32.087,
			32.132,
			32.194,
			32.256,
			32.304,
			32.382,
			32.413,
			32.445,
			32.506,
			32.568,
			32.631,
			32.693,
			32.739,
			32.753,
			32.816,
			32.851,
			32.878,
			32.94,
			32.953,
			33.001,
			33.063,
			33.124,
			33.173,
			33.188,
			33.25,
			33.279,
			33.312,
			33.372,
			33.389,
			33.435,
			33.499,
			33.56,
			33.606,
			33.685,
			33.714,
			33.747,
			33.808,
			33.869,
			33.936,
			33.992,
			34.041,
			34.054,
			34.117,
			34.148,
			34.18,
			34.24,
			34.256,
			34.302,
			34.365,
			34.428,
			34.474,
			34.488,
			34.552,
			34.582,
			34.613,
			34.674,
			34.692,
			34.737,
			34.8,
			34.862,
			34.913,
			34.986,
			35.015,
			35.046,
			35.11,
			35.172,
			35.233,
			35.296,
			35.341,
			35.357,
			35.42,
			35.449,
			35.482,
			35.558,
			35.605,
			35.667,
			35.729,
			35.778,
			35.853,
			35.885,
			35.914,
			35.979,
			36.039,
			36.103,
			36.164,
			36.209,
			36.289,
			36.319,
			36.349,
			36.412,
			36.429,
			36.475,
			36.536,
			36.597,
			36.645,
			36.661,
			36.723,
			36.753,
			36.785,
			36.845,
			36.862,
			36.908,
			36.974,
			37.031,
			37.078,
			37.155,
			37.188,
			37.22,
			37.28,
			37.341,
			37.405,
			37.466,
			37.512,
			37.528,
			37.589,
			37.622,
			37.653,
			37.714,
			37.73,
			37.774,
			37.838,
			37.899,
			37.947,
			37.963,
			38.025,
			38.056,
			38.086,
			38.163,
			38.212,
			38.272,
			38.335,
			38.396,
			38.458,
			38.488,
			38.521,
			38.581,
			38.644,
			38.706,
			38.77,
			38.831,
			38.892,
			38.922,
			38.955,
			39.016,
			39.036,
			39.079,
			39.141,
			39.202,
			39.266,
			39.325,
			39.356,
			39.388,
			39.465,
			39.514,
			39.575,
			39.637,
			39.699,
			39.761,
			39.79,
			39.823,
			39.884,
			39.948,
			40.008,
			40.069,
			40.117,
			40.131,
			40.195,
			40.226,
			40.256,
			40.335,
			40.38,
			40.441,
			40.505,
			40.552,
			40.63,
			40.66,
			40.691,
			40.768,
			40.815,
			40.878,
			40.939,
			41.002,
			41.063,
			41.096,
			41.125,
			41.202,
			41.249,
			41.312,
			41.372,
			41.419,
			41.435,
			41.495,
			41.528,
			41.56,
			41.62,
			41.637,
			41.682,
			41.746,
			41.807,
			41.855,
			41.931,
			41.963,
			41.991,
			42.069,
			42.117,
			42.179,
			42.242,
			42.288,
			42.302,
			42.366,
			42.395,
			42.428,
			42.488,
			42.505,
			42.551,
			42.614,
			42.675,
			42.722,
			42.736,
			42.798,
			42.831,
			42.861,
			42.924,
			42.937,
			42.985,
			43.047,
			43.108,
			43.158,
			43.232,
			43.264,
			43.295,
			43.358,
			43.42,
			43.481,
			43.542,
			43.588,
			43.604,
			43.668,
			43.697,
			43.73,
			43.792,
			43.808,
			43.854,
			43.914,
			43.979,
			44.024,
			44.04,
			44.102,
			44.133,
			44.163,
			44.224,
			44.241,
			44.288,
			44.349,
			44.411,
			44.473,
			44.536,
			44.566,
			44.598,
			44.658,
			44.722,
			44.782,
			44.846,
			44.907,
			44.97,
			45,
			45.032,
			45.094,
			45.108,
			45.156,
			45.223,
			45.28,
			45.325,
			45.342,
			45.402,
			45.435,
			45.466,
			45.528,
			45.589,
			45.654,
			45.713,
			45.76,
			45.838,
			45.868,
			45.901,
			45.962,
			45.978,
			46.023,
			46.089,
			46.147,
			46.209,
			46.272,
			46.302,
			46.333,
			46.412,
			46.457,
			46.521,
			46.581,
			46.645,
			46.705,
			46.736,
			46.768,
			46.828,
			46.891,
			46.953,
			47.015,
			47.063,
			47.078,
			47.14,
			47.17,
			47.203,
			47.262,
			47.283,
			47.325,
			47.388,
			47.451,
			47.513,
			47.575,
			47.605,
			47.636,
			47.712,
			47.759,
			47.823,
			47.885,
			47.931,
			48.008,
			48.039,
			48.069,
			48.149,
			48.192,
			48.256,
			48.318,
			48.364,
			48.38,
			48.441,
			48.474,
			48.504,
			48.582,
			48.628,
			48.689,
			48.753,
			48.799,
			48.814,
			48.877,
			48.906,
			48.938,
			49.015,
			49.063,
			49.124,
			49.185,
			49.233,
			49.311,
			49.346,
			49.371,
			49.433,
			49.497,
			49.557,
			49.62,
			49.666,
			49.682,
			49.745,
			49.775,
			49.807,
			49.869,
			49.884,
			49.931,
			49.992,
			50.056,
			50.116,
			50.179,
			50.208,
			50.242,
			50.302,
			50.364,
			50.428,
			50.488,
			50.536,
			50.613,
			50.642,
			50.675,
			50.753,
			50.799,
			50.859,
			50.922,
			50.97,
			50.984,
			51.048,
			51.078,
			51.11,
			51.169,
			51.186,
			51.231,
			51.294,
			51.356,
			51.418,
			51.48,
			51.513,
			51.541,
			51.606,
			51.667,
			51.729,
			51.791,
			51.838,
			51.852,
			51.914,
			51.947,
			51.977,
			52.039,
			52.053,
			52.105,
			52.162,
			52.223,
			52.288,
			52.35,
			52.379,
			52.411,
			52.471,
			52.488,
			52.535,
			52.596,
			52.659,
			52.706,
			52.782,
			52.813,
			52.845,
			52.905,
			52.969,
			53.031,
			53.094,
			53.139,
			53.155,
			53.217,
			53.248,
			53.279,
			53.341,
			53.355,
			53.402,
			53.465,
			53.525,
			53.589,
			53.652,
			53.682,
			53.712,
			53.775,
			53.792,
			53.838,
			53.9,
			53.962,
			54.024,
			54.085,
			54.115,
			54.148,
			54.208,
			54.226,
			54.271,
			54.332,
			54.396,
			54.441,
			54.458,
			54.519,
			54.549,
			54.58,
			54.643,
			54.66,
			54.706,
			54.768,
			54.83,
			54.892,
			54.952,
			54.985,
			55.014,
			55.091,
			55.138,
			55.202,
			55.263,
			55.325,
			55.386,
			55.419,
			55.45,
			55.512,
			55.532,
			55.574,
			55.638,
			55.696,
			55.745,
			55.761,
			55.823,
			55.852,
			55.882,
			55.946,
			55.962,
			56.008,
			56.071,
			56.131,
			56.178,
			56.256,
			56.286,
			56.316,
			56.38,
			56.442,
			56.505,
			56.567,
			56.612,
			56.628,
			56.689,
			56.721,
			56.752,
			56.815,
			56.829,
			56.876,
			56.938,
			57,
			57.046,
			57.062,
			57.122,
			57.155,
			57.185,
			57.246,
			57.262,
			57.309,
			57.373,
			57.434,
			57.497,
			57.558,
			57.591,
			57.619,
			57.682,
			57.742,
			57.806,
			57.869,
			57.914,
			57.93,
			57.991,
			58.023,
			58.054,
			58.132,
			58.179,
			58.241,
			58.301,
			58.347,
			58.363,
			58.426,
			58.455,
			58.489,
			58.549,
			58.566,
			58.612,
			58.673,
			58.737,
			58.782,
			58.859,
			58.892,
			58.922,
			58.983,
			59.047,
			59.108,
			59.171,
			59.216,
			59.233,
			59.295,
			59.326,
			59.355,
			59.418,
			59.433,
			59.481,
			59.543,
			59.604,
			59.667,
			59.728,
			59.76,
			59.79,
			59.853,
			59.914,
			59.975,
			60.038,
			60.086,
			60.161,
			60.191,
			60.224,
			60.285,
			60.302,
			60.347,
			60.408,
			60.472,
			60.517,
			60.534,
			60.595,
			60.625,
			60.656,
			60.72,
			60.735,
			60.78,
			60.845,
			60.905,
			60.952,
			61.031,
			61.062,
			61.091,
			61.152,
			61.217,
			61.279,
			61.34,
			61.385,
			61.402,
			61.463,
			61.496,
			61.527,
			61.588,
			61.604,
			61.65,
			61.715,
			61.775,
			61.835,
			61.899,
			61.93,
			61.961,
			62.021,
			62.038,
			62.083,
			62.147,
			62.209,
			62.271,
			62.331,
			62.363,
			62.395,
			62.455,
			62.519,
			62.581,
			62.642,
			62.688,
			62.704,
			62.765,
			62.796,
			62.828,
			62.891,
			62.907,
			62.953,
			63.015,
			63.075,
			63.138,
			63.201,
			63.23,
			63.262,
			63.339,
			63.386,
			63.453,
			63.509,
			63.557,
			63.634,
			63.666,
			63.697,
			63.779,
			63.819,
			63.883,
			63.945,
			63.989,
			64.007,
			64.069,
			64.099,
			64.129,
			64.208,
			64.255,
			64.316,
			64.379,
			64.439,
			64.503,
			64.532,
			64.563,
			64.641,
			64.689,
			64.751,
			64.814,
			64.858,
			64.938,
			64.969,
			64.998,
			65.062,
			65.122,
			65.187,
			65.245,
			65.294,
			65.307,
			65.369,
			65.402,
			65.432,
			65.496,
			65.511,
			65.556,
			65.62,
			65.68,
			65.728,
			65.803,
			65.838,
			65.866,
			65.944,
			65.991,
			66.053,
			66.114,
			66.161,
			66.238,
			66.269,
			66.3,
			66.379,
			66.424,
			66.485,
			66.549,
			66.595,
			66.611,
			66.672,
			66.703,
			66.736,
			66.796,
			66.813,
			66.86,
			66.924,
			66.982,
			67.029,
			67.106,
			67.136,
			67.169,
			67.231,
			67.292,
			67.355,
			67.418,
			67.463,
			67.479,
			67.542,
			67.572,
			67.601,
			67.665,
			67.681,
			67.728,
			67.789,
			67.85,
			67.901,
			67.974,
			68.005,
			68.036,
			68.1,
			68.113,
			68.162,
			68.223,
			68.286,
			68.333,
			68.408,
			68.441,
			68.471,
			68.549,
			68.596,
			68.656,
			68.718,
			68.765,
			68.782,
			68.841,
			68.873,
			68.906,
			68.967,
			68.982,
			69.03,
			69.09,
			69.152,
			69.214,
			69.275,
			69.308,
			69.34,
			69.401,
			69.463,
			69.523,
			69.588,
			69.649,
			69.712,
			69.741,
			69.774,
			69.85,
			69.897,
			69.963,
			70.022,
			70.085,
			70.146,
			70.175,
			70.209,
			70.269,
			70.285,
			70.333,
			70.394,
			70.455,
			70.519,
			70.58,
			70.608,
			70.642,
			70.703,
			70.764,
			70.829,
			70.891,
			70.936,
			71.012,
			71.045,
			71.075,
			71.138,
			71.152,
			71.2,
			71.262,
			71.325,
			71.385,
			71.449,
			71.479,
			71.508,
			71.57,
			71.588,
			71.634,
			71.697,
			71.757,
			71.805,
			71.881,
			71.913,
			71.944,
			72.025,
			72.066,
			72.132,
			72.192,
			72.238,
			72.255,
			72.315,
			72.346,
			72.375,
			72.439,
			72.455,
			72.501,
			72.565,
			72.625,
			72.672,
			72.688,
			72.75,
			72.78,
			72.812,
			72.889,
			72.936,
			72.997,
			73.059,
			73.105,
			73.184,
			73.215,
			73.245,
			73.308,
			73.37,
			73.431,
			73.495,
			73.555,
			73.618,
			73.65,
			73.679,
			73.741,
			73.758,
			73.803,
			73.867,
			73.929,
			73.975,
			73.989,
			74.052,
			74.088,
			74.114,
			74.192,
			74.239,
			74.301,
			74.363,
			74.408,
			74.486,
			74.518,
			74.549,
			74.608,
			74.673,
			74.734,
			74.796,
			74.842,
			74.858,
			74.921,
			74.951,
			74.982,
			75.045,
			75.059,
			75.105,
			75.169,
			75.23,
			75.277,
			75.355,
			75.385,
			75.417,
			75.479,
			75.54,
			75.601,
			75.663,
			75.725,
			75.788,
			75.821,
			75.849,
			75.913,
			75.929,
			75.975,
			76.035,
			76.1,
			76.149,
			76.161,
			76.223,
			76.254,
			76.286,
			76.346,
			76.362,
			76.41,
			76.471,
			76.531,
			76.581,
			76.656,
			76.687,
			76.718,
			76.781,
			76.843,
			76.906,
			76.966,
			77.014,
			77.03,
			77.091,
			77.12,
			77.152,
			77.215,
			77.229,
			77.277,
			77.34,
			77.4,
			77.462,
			77.523,
			77.555,
			77.585,
			77.663,
			77.712,
			77.772,
			77.836,
			77.882,
			77.959,
			77.989,
			78.022,
			78.084,
			78.146,
			78.212,
			78.269,
			78.315,
			78.331,
			78.392,
			78.423,
			78.453,
			78.517,
			78.533,
			78.579,
			78.646,
			78.704,
			78.75,
			78.765,
			78.828,
			78.859,
			78.89,
			78.951,
			78.967,
			79.014,
			79.075,
			79.137,
			79.182,
			79.262,
			79.292,
			79.322,
			79.386,
			79.447,
			79.51,
			79.572,
			79.616,
			79.634,
			79.696,
			79.727,
			79.756,
			79.835,
			79.882,
			79.941,
			80.006,
			80.052,
			80.129,
			80.161,
			80.191,
			80.271,
			80.314,
			80.379,
			80.439,
			80.484,
			80.501,
			80.564,
			80.595,
			80.624,
			80.688,
			80.704,
			80.748,
			80.812,
			80.874,
			80.935,
			80.998,
			81.028,
			81.059,
			81.122,
			81.136,
			81.184,
			81.245,
			81.308,
			81.354,
			81.431,
			81.463,
			81.494,
			81.571,
			81.618,
			81.681,
			81.742,
			81.788,
			81.804,
			81.865,
			81.898,
			81.928,
			81.99,
			82.005,
			82.05,
			82.115,
			82.175,
			82.222,
			82.239,
			82.299,
			82.336,
			82.363,
			82.424,
			82.438,
			82.486,
			82.549,
			82.61,
			82.671,
			82.733,
			82.763,
			82.797,
			82.873,
			82.921,
			82.982,
			83.045,
			83.091,
			83.107,
			83.168,
			83.2,
			83.228,
			83.292,
			83.308,
			83.352,
			83.415,
			83.478,
			83.524,
			83.54,
			83.603,
			83.633,
			83.664,
			83.724,
			83.741,
			83.789,
			83.849,
			83.912,
			83.958,
			84.036,
			84.066,
			84.097,
			84.159,
			84.177,
			84.222,
			84.289,
			84.345,
			84.397,
			84.407,
			84.469,
			84.5,
			84.533,
			84.594,
			84.61,
			84.656,
			84.717,
			84.779,
			84.84,
			84.904,
			84.935,
			84.965,
			85.029,
			85.09,
			85.153,
			85.213,
			85.261,
			85.338,
			85.369,
			85.401,
			85.462,
			85.478,
			85.524,
			85.586,
			85.649,
			85.708,
			85.773,
			85.803,
			85.834,
			85.896,
			85.912,
			85.956,
			86.019,
			86.082,
			86.145,
			86.206,
			86.236,
			86.269,
			86.346,
			86.391,
			86.458,
			86.517,
			86.563,
			86.578,
			86.64,
			86.671,
			86.701,
			86.764,
			86.779,
			86.825,
			86.889,
			86.949,
			87.012,
			87.073,
			87.106,
			87.135,
			87.213,
			87.261,
			87.322,
			87.385,
			87.448,
			87.509,
			87.538,
			87.569,
			87.632,
			87.695,
			87.758,
			87.817,
			87.866,
			87.881,
			87.941,
			87.973,
			88.005,
			88.065,
			88.081,
			88.13,
			88.192,
			88.253,
			88.314,
			88.378,
			88.407,
			88.437,
			88.5,
			88.521,
			88.564,
			88.623,
			88.686,
			88.733,
			88.809,
			88.84,
			88.872,
			88.935,
			88.951,
			88.997,
			89.057,
			89.119,
			89.167,
			89.183,
			89.246,
			89.274,
			89.307,
			89.367,
			89.384,
			89.43,
			89.492,
			89.554,
			89.617,
			89.679,
			89.711,
			89.741,
			89.803,
			89.863,
			89.928,
			89.989,
			90.034,
			90.113,
			90.145,
			90.175,
			90.254,
			90.298,
			90.362,
			90.422,
			90.485,
			90.548,
			90.583,
			90.608,
			90.672,
			90.686,
			90.733,
			90.796,
			90.858,
			90.92,
			90.982,
			91.013,
			91.041,
			91.106,
			91.166,
			91.229,
			91.291,
			91.339,
			91.353,
			91.415,
			91.447,
			91.475,
			91.539,
			91.555,
			91.602,
			91.663,
			91.724,
			91.772,
			91.787,
			91.848,
			91.88,
			91.911,
			91.988,
			92.036,
			92.096,
			92.16,
			92.205,
			92.282,
			92.315,
			92.344,
			92.408,
			92.468,
			92.532,
			92.592,
			92.655,
			92.716,
			92.749,
			92.779,
			92.842,
			92.857,
			92.904,
			92.965,
			93.028,
			93.075,
			93.091,
			93.153,
			93.182,
			93.214,
			93.291,
			93.336,
			93.4,
			93.461,
			93.506,
			93.585,
			93.615,
			93.646,
			93.71,
			93.772,
			93.834,
			93.896,
			93.943,
			93.959,
			94.02,
			94.05,
			94.083,
			94.158,
			94.205,
			94.269,
			94.331,
			94.391,
			94.453,
			94.484,
			94.515,
			94.594,
			94.638,
			94.708,
			94.763,
			94.811,
			94.889,
			94.92,
			94.948,
			95.011,
			95.074,
			95.136,
			95.197,
			95.245,
			95.259,
			95.321,
			95.351,
			95.385,
			95.446,
			95.462,
			95.507,
			95.57,
			95.632,
			95.692,
			95.757,
			95.787,
			95.817,
			95.897,
			95.943,
			96.005,
			96.065,
			96.129,
			96.19,
			96.222,
			96.253,
			96.33,
			96.375,
			96.438,
			96.499,
			96.562,
			96.625,
			96.655,
			96.687,
			96.748,
			96.769,
			96.809,
			96.872,
			96.935,
			96.979,
			97.058,
			97.089,
			97.121,
			97.183,
			97.245,
			97.307,
			97.368,
			97.43,
			97.49,
			97.522,
			97.555,
			97.617,
			97.632,
			97.679,
			97.741,
			97.802,
			97.849,
			97.865,
			97.925,
			97.956,
			97.988,
			98.049,
			98.065,
			98.113,
			98.175,
			98.236,
			98.298,
			98.358,
			98.391,
			98.421,
			98.499,
			98.547,
			98.608,
			98.672,
			98.718,
			98.731,
			98.795,
			98.831,
			98.858,
			98.934,
			98.981,
			99.042,
			99.105,
			99.152,
			99.229,
			99.26,
			99.29,
			99.368,
			99.416,
			99.475,
			99.538,
			99.586,
			99.601,
			99.663,
			99.695,
			99.724,
			99.786,
			99.803,
			99.849,
			99.911,
			99.974,
			100.018,
			100.035,
			100.097,
			100.128,
			100.16,
			100.221,
			100.235,
			100.282,
			100.345,
			100.408,
			100.47,
			100.532,
			100.562,
			100.592,
			100.655,
			100.717,
			100.779,
			100.839,
			100.902,
			100.966,
			100.996,
			101.028,
			101.09,
			101.105,
			101.149,
			101.212,
			101.273,
			101.32,
			101.336,
			101.398,
			101.429,
			101.462,
			101.539,
			101.586,
			101.647,
			101.709,
			101.754,
			101.832,
			101.864,
			101.895,
			101.956,
			102.02,
			102.085,
			102.143,
			102.189,
			102.205,
			102.268,
			102.298,
			102.329,
			102.391,
			102.408,
			102.453,
			102.516,
			102.577,
			102.622,
			102.638,
			102.702,
			102.731,
			102.762,
			102.825,
			102.842,
			102.888,
			102.949,
			102.954,
			103.012,
			103.059,
			103.136,
			103.165,
			103.195,
			103.26,
			103.321,
			103.384,
			103.445,
			103.49,
			103.507,
			103.57,
			103.6,
			103.632,
			103.708,
			103.754,
			103.816,
			103.879,
			103.895
		]
	],
	'7818_8069_7982': [
		[
			105.796
		],
		[
			0
		],
		[
			0,
			2.765,
			7.1,
			8.55,
			10.003,
			14.37,
			15.829,
			17.29,
			20.217,
			21.682,
			24.621,
			27.566,
			32,
			34.967,
			39.438,
			40.936,
			42.435,
			46.961,
			49.991,
			54.553,
			57.603,
			60.655,
			62.178,
			65.217,
			66.729,
			69.734,
			72.706,
			74.174,
			77.076,
			79.93,
			84.129,
			86.879,
			90.952,
			92.293,
			93.627,
			97.593,
			100.218,
			102.787,
			106.559,
			109.044,
			112.71,
			113.915,
			115.111,
			119.788,
			120.928,
			124.305,
			126.472,
			128.62,
			129.685,
			131.787,
			133.85,
			134.869,
			136.877,
			137.867,
			139.815,
			141.723,
			144.504,
			146.302,
			148.927,
			149.776,
			150.615,
			153.077,
			154.669,
			156.987,
			158.486,
			159.948,
			160.666,
			162.073,
			162.764,
			164.121,
			165.446,
			166.095,
			166.737,
			168.62,
			169.82,
			170.983,
			171.551,
			172.663,
			173.746,
			174.267,
			175.791,
			176.779,
			178.23,
			179.179,
			180.112,
			181.494,
			181.953,
			182.411,
			183.787,
			184.739,
			186.161,
			187.109,
			188.059,
			188.536,
			189.566,
			190.092,
			191.147,
			192.735,
			193.804,
			194.95,
			196.673,
			197.248,
			197.825,
			199.577,
			200.166,
			200.754,
			201.93,
			202.518,
			203.696,
			204.888,
			206.665,
			207.844,
			209.625,
			210.221,
			210.815,
			212.587,
			213.766,
			215.54,
			216.715,
			217.889,
			218.477,
			219.682,
			220.288,
			221.497,
			222.705,
			223.311,
			224.558,
			225.827,
			227.729,
			228.364,
			229.006,
			230.98,
			231.639,
			232.298,
			234.256,
			235.501,
			237.375,
			238.633,
			239.802,
			241.529,
			242.107,
			242.687,
			244.407,
			244.96,
			245.514,
			247.181,
			248.298,
			249.405,
			249.95,
			251.044,
			252.142,
			252.692,
			253.795,
			254.36,
			255.5,
			256.644,
			258.361,
			259.552,
			261.4,
			262.024,
			262.652,
			264.599,
			265.97,
			268.058,
			269.486,
			271.694,
			273.187,
			273.939,
			275.456,
			276.989,
			277.762,
			279.317,
			280.879,
			282.47,
			284.858,
			286.456,
			288.069,
			288.882,
			290.487,
			291.293,
			292.917,
			294.547,
			296.988,
			298.633,
			301.119,
			301.953,
			302.791,
			305.328,
			307.036,
			309.624,
			311.359,
			313.093,
			313.964,
			315.713,
			316.592,
			318.358,
			320.13,
			321.018,
			322.803,
			324.595,
			327.283,
			328.185,
			329.09,
			331.782,
			332.691,
			333.602,
			335.431,
			336.35,
			338.195,
			340.054,
			342.864,
			344.753,
			347.626,
			348.584,
			349.545,
			352.446,
			354.394,
			357.337,
			359.313,
			361.301,
			362.298,
			364.302,
			365.31,
			367.334,
			370.374,
			372.428,
			374.49,
			377.589,
			378.624,
			379.656,
			382.73,
			383.748,
			384.759,
			387.75,
			389.705,
			391.628,
			394.443,
			396.29,
			399.017,
			399.921,
			400.838,
			403.531,
			404.426,
			405.318,
			407.99,
			409.77,
			412.425,
			414.193,
			415.949,
			416.825,
			418.575,
			419.45,
			421.174,
			422.895,
			425.467,
			427.169,
			429.727,
			430.594,
			431.461,
			434.064,
			435.86,
			438.593,
			440.437,
			442.304,
			443.239,
			445.122,
			446.074,
			447.028,
			449.912,
			450.912,
			451.914,
			454.944,
			457.033,
			459.129,
			460.169,
			462.231,
			464.292,
			465.292,
			467.258,
			468.239,
			470.195,
			472.135,
			475.043,
			477.004,
			479.939,
			480.915,
			481.89,
			484.807,
			486.698,
			489.573,
			491.445,
			494.267,
			496.152,
			497.096,
			498.993,
			500.905,
			501.867,
			503.798,
			505.725,
			508.661,
			510.631,
			512.609,
			514.593,
			515.584,
			517.564,
			518.552,
			520.538,
			522.499,
			525.427,
			527.372,
			530.276,
			531.242,
			532.206,
			535.086,
			537.008,
			539.885,
			541.813,
			544.703,
			546.645,
			547.617,
			549.559,
			552.504,
			554.467,
			556.447,
			559.42,
			561.41,
			564.397,
			565.394,
			566.392,
			569.383,
			571.342,
			573.285,
			576.16,
			578.043,
			580.859,
			581.788,
			582.719,
			586.462,
			587.403,
			590.239,
			592.156,
			594.083,
			595.051,
			597.003,
			598.966,
			599.951,
			601.937,
			602.935,
			604.938,
			606.955,
			610.001,
			612.047,
			615.137,
			616.173,
			617.211,
			620.326,
			622.421,
			625.578,
			627.661,
			629.771,
			630.83,
			632.956,
			634.022,
			636.162,
			638.311,
			639.407,
			640.486,
			643.711,
			645.882,
			649.147,
			651.334,
			653.528,
			654.628,
			657.948,
			660.173,
			662.411,
			665.786,
			668.046,
			671.453,
			672.593,
			673.734,
			677.172,
			679.475,
			682.945,
			685.27,
			687.603,
			688.773,
			691.119,
			692.295,
			694.655,
			697.024,
			698.212,
			700.595,
			702.987,
			705.387,
			707.798,
			709.007,
			711.434,
			713.876,
			715.1,
			717.568,
			718.806,
			721.287,
			723.771,
			727.464,
			729.889,
			733.451,
			734.618,
			735.776,
			739.188,
			741.415,
			744.682,
			746.812,
			749.936,
			751.971,
			752.974,
			754.952,
			756.891,
			757.847,
			759.73,
			761.576,
			764.279,
			765.163,
			766.038,
			768.607,
			769.444,
			770.272,
			771.901,
			772.701,
			774.274,
			775.81,
			778.062,
			779.538,
			781.724,
			782.459,
			783.181,
			785.335,
			786.049,
			786.755,
			788.86,
			790.253,
			791.637,
			792.325,
			793.692,
			794.373,
			795.729,
			797.084,
			797.758,
			799.104,
			800.445,
			802.496,
			803.864,
			805.906,
			806.606,
			807.307,
			809.399,
			810.791,
			812.989,
			814.458,
			815.928,
			816.682,
			818.194,
			818.951,
			819.707,
			822.007,
			822.786,
			823.566,
			825.913,
			827.487,
			829.068,
			829.861,
			831.428,
			832.927,
			833.678,
			835.942,
			837.34,
			838.735,
			840.845,
			842.254,
			844.382,
			845.096,
			845.814,
			848.02,
			849.51,
			851.778,
			853.32,
			854.864,
			855.647,
			857.238,
			858.042,
			859.662,
			862.133,
			862.973,
			865.513,
			867.239,
			869.88,
			871.643,
			873.434,
			874.332,
			876.141,
			877.071,
			878.944,
			880.83,
			883.696,
			885.623,
			888.524,
			889.495,
			890.47,
			893.363,
			895.294,
			898.174,
			900.094,
			902.032,
			903.008,
			904.966,
			905.949,
			907.947,
			910.969,
			913.018,
			915.079,
			917.148,
			919.222,
			920.263,
			923.405,
			924.457,
			925.511,
			928.705,
			930.849,
			932.98,
			936.178,
			938.297,
			941.482,
			942.535,
			943.588,
			946.758,
			948.821,
			951.954,
			954.022,
			956.088,
			957.123,
			959.198,
			961.272,
			962.304,
			964.354,
			965.368,
			967.373,
			969.36,
			972.253,
			974.156,
			976.955,
			977.902,
			978.847,
			981.699,
			983.627,
			986.527,
			988.491,
			990.459,
			993.42,
			994.41,
			995.397,
			998.358,
			1000.333,
			1003.297,
			1005.276,
			1007.251,
			1008.249,
			1010.235,
			1012.211,
			1013.196,
			1016.123,
			1018.033,
			1019.911,
			1022.659,
			1024.438,
			1027.071,
			1027.921,
			1028.769,
			1031.302,
			1032.967,
			1035.428,
			1037.047,
			1039.445,
			1041.03,
			1041.823,
			1043.411,
			1045.002,
			1045.798,
			1046.596,
			1049,
			1050.608,
			1053.042,
			1054.686,
			1056.335,
			1057.164,
			1058.86,
			1059.711,
			1061.422,
			1063.196,
			1065.936,
			1067.821,
			1070.803,
			1071.803,
			1072.789,
			1075.725,
			1077.664,
			1080.418,
			1082.278,
			1084.102,
			1085.018,
			1086.86,
			1087.785,
			1089.641,
			1091.509,
			1092.448,
			1093.386,
			1096.22,
			1099.067,
			1100.019,
			1100.973,
			1102.881,
			1104.784,
			1105.734,
			1107.632,
			1108.585,
			1110.488,
			1112.384,
			1115.374,
			1117.344,
			1120.503,
			1121.552,
			1122.594,
			1125.652,
			1126.641,
			1127.629,
			1130.422,
			1132.236,
			1134.968,
			1136.788,
			1137.703,
			1139.582,
			1141.457,
			1142.399,
			1144.291,
			1146.196,
			1149.074,
			1150.038,
			1151.005,
			1152.949,
			1154.894,
			1155.87,
			1158.797,
			1160.747,
			1163.668,
			1165.603,
			1167.549,
			1170.46,
			1171.434,
			1172.409,
			1175.354,
			1177.328,
			1180.311,
			1182.313,
			1184.327,
			1185.338,
			1187.366,
			1189.41,
			1190.437,
			1192.497,
			1193.532,
			1195.612,
			1197.703,
			1200.858,
			1202.965,
			1206.113,
			1207.172,
			1208.234,
			1211.43,
			1213.574,
			1216.805,
			1218.963,
			1221.118,
			1224.33,
			1225.392,
			1226.447,
			1230.6,
			1231.617,
			1234.614,
			1236.566,
			1238.48,
			1239.422,
			1241.278,
			1243.1,
			1244.012,
			1245.78,
			1246.652,
			1248.357,
			1250.043,
			1252.508,
			1254.113,
			1256.481,
			1257.263,
			1258.043,
			1260.372,
			1261.912,
			1264.216,
			1265.751,
			1268.066,
			1269.626,
			1270.409,
			1271.982,
			1274.373,
			1275.986,
			1277.611,
			1280.097,
			1281.772,
			1283.459,
			1285.172,
			1286.032,
			1287.757,
			1288.622,
			1290.356,
			1292.097,
			1294.736,
			1296.509,
			1299.214,
			1300.137,
			1301.061,
			1303.87,
			1305.815,
			1308.748,
			1310.735,
			1313.712,
			1315.691,
			1316.677,
			1318.648,
			1320.629,
			1321.612,
			1323.566,
			1325.488,
			1328.343,
			1330.15,
			1332.842,
			1333.727,
			1334.584,
			1337.15,
			1338.851,
			1340.53,
			1343.04,
			1344.711,
			1347.211,
			1348.043,
			1348.873,
			1351.355,
			1352.992,
			1355.476,
			1357.129,
			1358.792,
			1359.628,
			1361.309,
			1362.989,
			1363.843,
			1365.571,
			1366.439,
			1368.201,
			1369.966,
			1372.64,
			1374.426,
			1377.155,
			1378.071,
			1378.991,
			1381.771,
			1383.63,
			1386.416,
			1388.257,
			1390.073,
			1392.741,
			1393.614,
			1394.492,
			1397.026,
			1397.839,
			1398.657,
			1401.072,
			1402.653,
			1404.224,
			1405.011,
			1406.583,
			1408.154,
			1408.959,
			1411.39,
			1413.011,
			1414.655,
			1417.15,
			1418.814,
			1421.32,
			1422.156,
			1422.991,
			1426.298,
			1427.123,
			1429.586,
			1431.207,
			1432.832,
			1433.646,
			1435.274,
			1436.089,
			1437.722,
			1440.189,
			1441.848,
			1443.516,
			1445.2,
			1447.752,
			1449.471,
			1451.207,
			1452.081,
			1453.839,
			1454.724,
			1456.506,
			1458.304,
			1461.025,
			1462.857,
			1465.612,
			1466.54,
			1467.471,
			1470.269,
			1472.131,
			1474.889,
			1476.682,
			1478.463,
			1479.329,
			1481.014,
			1481.843,
			1483.461,
			1485.063,
			1485.841,
			1487.369,
			1488.859,
			1491.018,
			1492.41,
			1494.444,
			1495.112,
			1495.774,
			1497.731,
			1499.016,
			1500.296,
			1502.189,
			1503.438,
			1505.309,
			1505.932,
			1506.553,
			1508.411,
			1509.029,
			1509.653,
			1511.528,
			1512.774,
			1514.67,
			1515.994,
			1516.656,
			1517.979,
			1519.307,
			1519.993,
			1521.365,
			1522.74,
			1524.8,
			1526.158,
			1528.204,
			1528.889,
			1529.573,
			1531.624,
			1532.996,
			1535.066,
			1536.454,
			1538.537,
			1539.934,
			1540.638,
			1541.34,
			1544.146,
			1544.86,
			1547.014,
			1548.448,
			1549.89,
			1550.618,
			1552.069,
			1553.516,
			1554.238,
			1555.677,
			1556.393,
			1557.821,
			1559.245,
			1561.369,
			1562.776,
			1564.882,
			1565.586,
			1566.288,
			1568.386,
			1569.784,
			1571.903,
			1573.321,
			1574.745,
			1575.461,
			1576.899,
			1577.623,
			1578.351,
			1580.551,
			1581.287,
			1582.028,
			1584.276,
			1585.763,
			1587.253,
			1588.002,
			1589.509,
			1591,
			1591.749,
			1594.016,
			1595.537,
			1597.066,
			1599.379,
			1600.923,
			1603.254,
			1604.037,
			1604.821,
			1607.173,
			1608.754,
			1611.141,
			1612.744,
			1614.359,
			1615.171,
			1616.8,
			1617.62,
			1619.271,
			1620.932,
			1621.766,
			1623.446,
			1625.135,
			1627.662,
			1628.51,
			1629.361,
			1631.938,
			1632.803,
			1633.671,
			1635.419,
			1636.297,
			1638.062,
			1639.841,
			1642.532,
			1644.343,
			1647.088,
			1648.009,
			1648.934,
			1651.729,
			1653.608,
			1656.452,
			1658.364,
			1661.25,
			1663.183,
			1664.154,
			1666.097,
			1668.045,
			1669.022,
			1670.982,
			1672.948,
			1675.916,
			1677.901,
			1680.902,
			1681.912,
			1682.924,
			1684.953,
			1685.982,
			1688.045,
			1691.152,
			1693.226,
			1695.298,
			1698.421,
			1699.465,
			1700.511,
			1703.653,
			1704.703,
			1705.757,
			1708.917,
			1711.033,
			1714.215,
			1716.354,
			1718.507,
			1719.583,
			1721.763,
			1722.86,
			1725.054,
			1727.24,
			1730.57,
			1732.774,
			1736.068,
			1737.161,
			1738.253,
			1741.495,
			1743.66,
			1746.924,
			1749.092,
			1751.245,
			1754.533,
			1755.609,
			1757.799,
			1761.097,
			1762.198,
			1765.515,
			1767.734,
			1769.96,
			1771.076,
			1773.314,
			1775.56,
			1776.686,
			1780.074,
			1782.343,
			1784.621,
			1788.057,
			1790.375,
			1793.85,
			1795.007,
			1796.171,
			1799.657,
			1801.978,
			1805.422,
			1807.694,
			1809.924,
			1811.024,
			1813.197,
			1814.27,
			1816.391,
			1818.487,
			1819.529,
			1821.599,
			1823.656,
			1826.727,
			1827.729,
			1828.748,
			1830.787,
			1832.809,
			1833.823,
			1835.857,
			1836.868,
			1838.901,
			1840.961,
			1844.029,
			1846.089,
			1849.211,
			1850.253,
			1851.316,
			1854.536,
			1856.686,
			1859.904,
			1862.019,
			1865.128,
			1867.152,
			1868.164,
			1870.17,
			1872.132,
			1873.099,
			1875.036,
			1876.94,
			1879.768,
			1881.671,
			1884.489,
			1885.431,
			1886.361,
			1889.137,
			1891.001,
			1893.789,
			1895.672,
			1897.565,
			1900.446,
			1901.406,
			1902.369,
			1905.277,
			1906.252,
			1907.229,
			1910.177,
			1912.157,
			1914.131,
			1915.131,
			1917.135,
			1918.14,
			1920.175,
			1922.198,
			1923.21,
			1925.245,
			1927.285,
			1930.364,
			1932.432,
			1935.556,
			1936.6,
			1937.645,
			1940.783,
			1942.89,
			1946.074,
			1948.195,
			1951.424,
			1953.589,
			1954.675,
			1956.874,
			1960.164,
			1962.369,
			1964.582,
			1966.786,
			1969.015,
			1970.134,
			1972.379,
			1974.633,
			1975.765,
			1978.034,
			1979.172,
			1981.457,
			1983.749,
			1987.207,
			1989.526,
			1993.026,
			1994.199,
			1995.374,
			1998.919,
			2001.296,
			2004.875,
			2007.274,
			2009.686,
			2010.895,
			2013.325,
			2014.545,
			2016.992,
			2019.453,
			2020.687,
			2023.161,
			2025.643,
			2029.376,
			2030.624,
			2031.873,
			2034.374,
			2036.878,
			2038.129,
			2040.625,
			2041.867,
			2044.333,
			2046.771,
			2050.362,
			2052.708,
			2056.156,
			2057.286,
			2058.407,
			2061.713,
			2063.869,
			2067.034,
			2069.097,
			2071.124,
			2072.124,
			2074.103,
			2075.079,
			2077.009,
			2078.907,
			2079.843,
			2081.689,
			2083.504,
			2086.168,
			2087.904,
			2090.46,
			2091.294,
			2092.123,
			2094.576,
			2096.192,
			2098.591,
			2100.182,
			2101.773,
			2104.138,
			2104.925,
			2105.713,
			2108.081,
			2108.871,
			2109.661,
			2112.033,
			2113.654,
			2115.275,
			2116.085,
			2117.717,
			2119.361,
			2120.183,
			2121.826,
			2122.649,
			2124.293,
			2125.937,
			2128.412,
			2130.067,
			2132.555,
			2133.392,
			2134.228,
			2136.736,
			2138.417,
			2140.947,
			2142.642,
			2144.352,
			2145.208,
			2146.925,
			2147.799,
			2149.562,
			2151.332,
			2152.221,
			2154.031,
			2155.854,
			2157.682,
			2159.506,
			2160.422,
			2162.266,
			2164.117,
			2165.048,
			2166.92,
			2167.874,
			2169.805,
			2171.752,
			2174.696,
			2176.675,
			2179.628,
			2180.614,
			2181.603,
			2184.579,
			2186.574,
			2189.599,
			2191.632,
			2193.671,
			2194.692,
			2196.745,
			2197.767,
			2199.785,
			2201.796,
			2202.806,
			2204.8,
			2206.792,
			2209.739,
			2210.709,
			2211.671,
			2213.569,
			2215.43,
			2216.349,
			2218.179,
			2219.077,
			2220.861,
			2222.634,
			2225.264,
			2227.012,
			2229.608,
			2230.468,
			2231.327,
			2233.923,
			2235.663,
			2238.332,
			2240.232,
			2242.134,
			2243.129,
			2245.219,
			2246.266,
			2248.357,
			2250.427,
			2251.465,
			2253.459,
			2255.338,
			2258.152,
			2259.045,
			2259.938,
			2262.608,
			2263.479,
			2264.348,
			2266.927,
			2268.64,
			2271.203,
			2272.894,
			2274.582,
			2277.101,
			2277.945,
			2278.815,
			2281.402,
			2282.265,
			2283.171,
			2286.066,
			2287.986,
			2289.982,
			2290.976,
			2292.951,
			2294.808,
			2295.735,
			2297.586,
			2298.491,
			2300.283,
			2302.069,
			2304.716,
			2306.467,
			2309.006,
			2309.831,
			2310.655,
			2313.132,
			2314.729,
			2317.127,
			2318.737,
			2321.166,
			2322.787,
			2323.607,
			2325.253,
			2327.73,
			2329.409,
			2331.101,
			2332.794,
			2334.526,
			2335.399,
			2337.17,
			2338.944,
			2339.837,
			2342.527,
			2344.346,
			2346.182,
			2348.964,
			2350.838,
			2353.678,
			2354.631,
			2355.588,
			2358.484,
			2360.43,
			2363.377,
			2365.357,
			2368.355,
			2370.368,
			2371.379,
			2373.412,
			2375.457,
			2376.484,
			2378.547,
			2380.62,
			2382.705,
			2384.801,
			2385.85,
			2387.946,
			2390.047,
			2391.102,
			2393.219,
			2394.28,
			2396.409,
			2398.549,
			2401.777,
			2403.943,
			2407.218,
			2408.314,
			2409.415,
			2412.732,
			2414.958,
			2418.316,
			2420.569,
			2422.833,
			2423.969,
			2426.249,
			2428.539,
			2429.688,
			2431.994,
			2433.15,
			2435.468,
			2437.794,
			2441.303,
			2443.654,
			2447.208,
			2448.398,
			2449.591,
			2453.187,
			2455.596,
			2458.015,
			2461.662,
			2464.105,
			2467.789,
			2469.022,
			2470.258,
			2473.978,
			2475.222,
			2476.469,
			2480.225,
			2482.74,
			2485.265,
			2486.531,
			2489.07,
			2491.618,
			2492.895,
			2496.74,
			2499.314,
			2501.898,
			2505.792,
			2507.094,
			2508.397,
			2512.312,
			2513.62,
			2514.931,
			2518.887,
			2521.54,
			2525.537,
			2528.208,
			2530.882,
			2534.895,
			2536.234,
			2538.92,
			2541.615,
			2542.967,
			2544.321,
			2548.396,
			2551.123,
			2553.856,
			2555.225,
			2557.969,
			2560.722,
			2562.102,
			2566.255,
			2569.032,
			2571.815,
			2576,
			2578.798,
			2583.009,
			2584.416,
			2585.825,
			2590.066,
			2592.902,
			2597.171,
			2600.027,
			2602.891,
			2604.326,
			2607.201,
			2608.641,
			2611.527,
			2615.871,
			2618.78,
			2621.694,
			2626.076,
			2627.541,
			2629.007,
			2633.415,
			2634.888,
			2636.364,
			2639.323,
			2640.804,
			2643.772,
			2646.743,
			2651.202,
			2654.186,
			2658.684,
			2660.188,
			2661.693,
			2666.218,
			2669.248,
			2673.803,
			2676.844,
			2679.882,
			2681.403,
			2684.453,
			2685.981,
			2689.042,
			2692.112,
			2693.648,
			2696.728,
			2699.812,
			2704.481,
			2706.032,
			2707.592,
			2712.236,
			2713.783,
			2715.325,
			2719.871,
			2722.857,
			2727.272,
			2730.174,
			2733.038,
			2737.264,
			2738.653,
			2740.032,
			2745.456,
			2746.79,
			2750.736,
			2753.324,
			2755.879,
			2757.145,
			2759.674,
			2762.167,
			2763.414,
			2765.902,
			2767.17,
			2769.722,
			2772.311,
			2776.227,
			2778.815,
			2782.615,
			2783.865,
			2785.108,
			2788.758,
			2791.16,
			2794.672,
			2796.963,
			2800.312,
			2802.499,
			2803.58,
			2805.712,
			2807.808,
			2808.842,
			2809.868,
			2812.889,
			2814.858,
			2816.791,
			2817.743,
			2819.62,
			2821.463,
			2822.373,
			2825.051,
			2826.795,
			2828.509,
			2831.015,
			2832.643,
			2835.022,
			2835.797,
			2836.564,
			2838.801,
			2840.248,
			2842.35,
			2843.707,
			2845.032,
			2845.682,
			2846.959,
			2847.588,
			2848.827,
			2850.646,
			2851.845,
			2853.049,
			2854.825,
			2855.413,
			2856,
			2857.17,
			2858.325,
			2858.9,
			2860.044,
			2860.616,
			2861.758,
			2862.876,
			2864.537,
			2865.642,
			2867.313,
			2867.883,
			2868.452,
			2870.157,
			2871.295,
			2873.168,
			2874.49,
			2875.813,
			2876.476,
			2877.913,
			2878.668,
			2880.178,
			2882.42,
			2883.826,
			2885.236,
			2887.324,
			2887.944,
			2888.565,
			2890.44,
			2891.069,
			2891.7,
			2892.932,
			2893.552,
			2894.802,
			2896.706,
			2898.009,
			2899.333,
			2901.362,
			2902.043,
			2902.728,
			2904.803,
			2905.503,
			2906.207,
			2908.375,
			2909.854,
			2911.35,
			2912.104,
			2913.741,
			2915.398,
			2916.233,
			2917.987,
			2918.919,
			2920.793,
			2922.715,
			2925.762,
			2927.782,
			2930.715,
			2931.697,
			2932.639,
			2935.245,
			2936.991,
			2939.522,
			2941.204,
			2942.897,
			2943.752,
			2945.464,
			2946.321,
			2947.179,
			2949.77,
			2950.635,
			2951.5,
			2954.105,
			2955.844,
			2957.588,
			2958.465,
			2960.223,
			2961.988,
			2962.875,
			2964.658,
			2965.553,
			2967.35,
			2969.164,
			2971.904,
			2973.756,
			2976.561,
			2977.502,
			2978.449,
			2981.303,
			2983.219,
			2986.113,
			2988.053,
			2990.968,
			2992.925,
			2993.898,
			2995.854,
			2998.809,
			3000.791,
			3002.787,
			3005.814,
			3006.831,
			3007.85,
			3009.912,
			3011.984,
			3013.023,
			3015.123,
			3016.175,
			3018.28,
			3020.391,
			3023.551,
			3025.665,
			3028.833,
			3029.901,
			3030.968,
			3034.16,
			3036.288,
			3039.456,
			3041.552,
			3043.644,
			3044.675,
			3046.74,
			3047.773,
			3049.828,
			3051.877,
			3052.904,
			3054.959,
			3057.02,
			3060.121,
			3061.156,
			3062.193,
			3065.311,
			3066.351,
			3067.393,
			3070.534,
			3072.642,
			3075.829,
			3077.964,
			3080.098,
			3083.314,
			3084.392,
			3085.474,
			3088.736,
			3089.831,
			3090.93,
			3094.243,
			3096.465,
			3098.698,
			3099.815,
			3102.053,
			3104.299,
			3105.419,
			3108.785,
			3111.027,
			3113.272,
			3116.647,
			3118.904,
			3122.302,
			3123.438,
			3124.575,
			3127.99,
			3130.272,
			3133.698,
			3135.986,
			3138.274,
			3139.42,
			3141.709,
			3142.854,
			3143.999,
			3147.427,
			3148.569,
			3149.711,
			3153.12,
			3155.392,
			3157.661,
			3158.797,
			3161.073,
			3163.356,
			3164.5,
			3167.955,
			3170.273,
			3172.617,
			3176.162,
			3178.552,
			3182.166,
			3183.378,
			3184.592,
			3188.253,
			3190.705,
			3194.401,
			3196.874,
			3199.353,
			3200.596,
			3203.087,
			3204.336,
			3206.837,
			3209.345,
			3210.602,
			3213.113,
			3215.612,
			3218.137,
			3220.671,
			3221.944,
			3224.497,
			3227.063,
			3228.351,
			3232.23,
			3234.824,
			3237.429,
			3241.36,
			3243.994,
			3247.964,
			3249.292,
			3250.623,
			3254.63,
			3257.314,
			3261.357,
			3264.065,
			3268.146,
			3270.88,
			3272.25,
			3274.998,
			3277.754,
			3279.134,
			3281.902,
			3284.673,
			3288.841,
			3290.233,
			3291.627,
			3295.812,
			3297.212,
			3298.612,
			3301.419,
			3302.825,
			3305.643,
			3308.469,
			3312.723,
			3315.568,
			3319.851,
			3321.281,
			3322.714,
			3327.023,
			3329.904,
			3334.242,
			3337.147,
			3340.06,
			3341.52,
			3344.441,
			3347.368,
			3348.833,
			3353.237,
			3356.185,
			3359.144,
			3363.625,
			3366.627,
			3371.124,
			3372.621,
			3374.118,
			3378.605,
			3381.603,
			3386.109,
			3389.121,
			3392.139,
			3393.65,
			3396.683,
			3398.202,
			3399.723,
			3404.307,
			3405.84,
			3407.375,
			3411.982,
			3415.057,
			3418.127,
			3419.664,
			3422.732,
			3425.802,
			3427.337,
			3430.411,
			3431.949,
			3435.029,
			3438.114,
			3442.75,
			3445.847,
			3450.501,
			3452.055,
			3453.61,
			3458.281,
			3461.401,
			3466.091,
			3469.221,
			3472.356,
			3473.924,
			3477.066,
			3478.638,
			3481.786,
			3484.937,
			3486.515,
			3489.676,
			3492.853,
			3496.044,
			3499.297,
			3500.928,
			3504.205,
			3507.476,
			3509.099,
			3512.336,
			3513.944,
			3517.144,
			3520.349,
			3525.025,
			3528.112,
			3532.676,
			3534.178,
			3535.673,
			3540.113,
			3543.02,
			3547.275,
			3550.028,
			3552.746,
			3554.092,
			3556.76,
			3558.082,
			3560.697,
			3563.275,
			3564.551,
			3567.075,
			3569.569,
			3573.262,
			3574.5,
			3575.729,
			3579.427,
			3580.662,
			3581.889,
			3584.318,
			3585.538,
			3587.912,
			3590.236,
			3593.66,
			3595.907,
			3599.233,
			3600.334,
			3601.431,
			3604.664,
			3606.829,
			3610.034,
			3612.168,
			3614.314,
			3615.391,
			3617.549,
			3619.698,
			3620.771,
			3622.924,
			3623.999,
			3626.148,
			3628.308,
			3631.583,
			3633.776,
			3637.071,
			3638.173,
			3639.271,
			3642.544,
			3644.738,
			3648.016,
			3650.18,
			3653.382,
			3655.478,
			3656.513,
			3657.556,
			3661.572,
			3662.555,
			3665.467,
			3667.381,
			3669.283,
			3670.254,
			3672.193,
			3674.134,
			3675.148,
			3678.194,
			3680.274,
			3682.397,
			3685.58,
			3687.696,
			3690.802,
			3691.812,
			3692.822,
			3695.821,
			3697.796,
			3700.773,
			3702.765,
			3704.762,
			3705.765,
			3707.771,
			3708.774,
			3710.785,
			3712.795,
			3713.799,
			3715.805,
			3717.804,
			3720.775,
			3721.755,
			3722.73,
			3724.662,
			3726.541,
			3727.468,
			3729.299,
			3730.198,
			3731.958,
			3733.683,
			3736.193,
			3737.814,
			3740.175,
			3740.944,
			3741.704,
			3743.93,
			3745.378,
			3747.505,
			3748.903,
			3750.289,
			3750.977,
			3752.346,
			3753.027,
			3754.383,
			3756.41,
			3757.753,
			3759.092,
			3761.099,
			3761.766,
			3762.432,
			3764.424,
			3765.086,
			3765.75,
			3767.734,
			3769.054,
			3770.373,
			3772.347,
			3773.661,
			3775.634,
			3776.293,
			3776.951,
			3779.58,
			3780.238,
			3782.221,
			3783.54,
			3784.858,
			3785.519,
			3786.848,
			3787.511,
			3788.835,
			3790.159,
			3790.829,
			3792.175,
			3793.517,
			3795.532,
			3796.923,
			3799.002,
			3799.694,
			3800.386,
			3802.535,
			3803.966,
			3806.111,
			3807.54,
			3808.964,
			3809.675,
			3811.108,
			3811.839,
			3812.569,
			3814.754,
			3815.482,
			3816.214,
			3818.413,
			3819.875,
			3821.337,
			3822.067,
			3823.522,
			3824.973,
			3825.699,
			3827.142,
			3827.86,
			3829.296,
			3830.732,
			3832.852,
			3834.258,
			3836.363,
			3837.05,
			3837.737,
			3839.8,
			3841.178,
			3843.149,
			3844.466,
			3846.448,
			3847.758,
			3848.415,
			3849.732,
			3851.731,
			3852.408,
			3854.451,
			3855.823,
			3857.231,
			3857.943,
			3859.375,
			3860.818,
			3861.545,
			3863.745,
			3865.226,
			3866.721,
			3868.99,
			3870.521,
			3872.871,
			3874.467,
			3876.896,
			3878.539,
			3881.051,
			3882.737,
			3885.266,
			3886.966,
			3887.821,
			3889.542,
			3891.278,
			3892.151,
			3893.911,
			3895.684,
			3898.37,
			3899.271,
			3900.177,
			3902.913,
			3903.832,
			3904.754,
			3907.541,
			3909.415,
			3911.302,
			3914.158,
			3916.078,
			3918.978,
			3919.95,
			3920.927,
			3923.871,
			3925.849,
			3928.842,
			3930.854,
			3932.88,
			3933.898,
			3935.944,
			3936.971,
			3939.037,
			3942.157,
			3944.252,
			3946.348,
			3949.498,
			3951.611,
			3954.801,
			3955.87,
			3956.941,
			3960.171,
			3962.338,
			3965.609,
			3967.803,
			3970.007,
			3971.113,
			3973.332,
			3974.446,
			3975.562,
			3980.053,
			3981.181,
			3984.584,
			3986.865,
			3989.155,
			3990.305,
			3992.61,
			3994.926,
			3996.088,
			3998.418,
			3999.587,
			4001.932,
			4004.286,
			4007.836,
			4010.214,
			4013.797,
			4014.993,
			4016.191,
			4019.799,
			4022.213,
			4025.856,
			4028.3,
			4030.754,
			4031.984,
			4034.451,
			4035.688,
			4038.168,
			4040.656,
			4041.904,
			4044.406,
			4046.915,
			4050.695,
			4051.958,
			4053.225,
			4055.763,
			4058.312,
			4059.589,
			4062.149,
			4063.433,
			4066.004,
			4068.583,
			4072.472,
			4075.079,
			4079.002,
			4080.31,
			4081.613,
			4085.532,
			4088.152,
			4092.094,
			4094.73,
			4097.375,
			4098.699,
			4101.353,
			4102.683,
			4105.346,
			4109.354,
			4112.035,
			4114.723,
			4118.768,
			4120.12,
			4121.473,
			4125.541,
			4126.9,
			4128.262,
			4130.99,
			4132.356,
			4135.095,
			4137.839,
			4141.967,
			4144.725,
			4148.873,
			4150.259,
			4151.646,
			4155.824,
			4157.219,
			4158.615,
			4162.812,
			4165.618,
			4168.43,
			4169.839,
			4172.658,
			4174.068,
			4176.895,
			4181.145,
			4184.012,
			4186.889,
			4191.191,
			4192.629,
			4194.066,
			4198.38,
			4199.821,
			4201.261,
			4202.603
		],
		[
			0,
			0.048,
			0.111,
			0.157,
			0.174,
			0.235,
			0.266,
			0.297,
			0.36,
			0.373,
			0.422,
			0.485,
			0.546,
			0.593,
			0.67,
			0.699,
			0.73,
			0.807,
			0.857,
			0.919,
			0.979,
			1.027,
			1.041,
			1.104,
			1.135,
			1.166,
			1.228,
			1.241,
			1.29,
			1.352,
			1.413,
			1.461,
			1.537,
			1.568,
			1.598,
			1.677,
			1.723,
			1.784,
			1.848,
			1.909,
			1.971,
			2.003,
			2.033,
			2.112,
			2.157,
			2.219,
			2.281,
			2.329,
			2.345,
			2.406,
			2.436,
			2.467,
			2.529,
			2.545,
			2.591,
			2.654,
			2.716,
			2.762,
			2.839,
			2.871,
			2.9,
			2.964,
			3.027,
			3.089,
			3.149,
			3.195,
			3.213,
			3.272,
			3.304,
			3.337,
			3.397,
			3.413,
			3.458,
			3.523,
			3.583,
			3.629,
			3.646,
			3.706,
			3.738,
			3.769,
			3.848,
			3.895,
			3.961,
			4.019,
			4.08,
			4.141,
			4.172,
			4.203,
			4.266,
			4.328,
			4.389,
			4.453,
			4.5,
			4.513,
			4.577,
			4.605,
			4.638,
			4.716,
			4.761,
			4.824,
			4.887,
			4.932,
			4.947,
			5.011,
			5.042,
			5.072,
			5.133,
			5.151,
			5.197,
			5.257,
			5.32,
			5.368,
			5.445,
			5.476,
			5.506,
			5.569,
			5.631,
			5.692,
			5.753,
			5.799,
			5.816,
			5.879,
			5.909,
			5.938,
			6.003,
			6.021,
			6.063,
			6.124,
			6.187,
			6.233,
			6.25,
			6.313,
			6.345,
			6.376,
			6.437,
			6.497,
			6.561,
			6.623,
			6.669,
			6.746,
			6.778,
			6.809,
			6.87,
			6.886,
			6.933,
			6.994,
			7.056,
			7.102,
			7.119,
			7.181,
			7.213,
			7.241,
			7.306,
			7.32,
			7.367,
			7.429,
			7.491,
			7.538,
			7.613,
			7.644,
			7.677,
			7.753,
			7.802,
			7.862,
			7.923,
			7.987,
			8.048,
			8.082,
			8.111,
			8.173,
			8.188,
			8.236,
			8.297,
			8.36,
			8.421,
			8.482,
			8.513,
			8.544,
			8.606,
			8.623,
			8.67,
			8.731,
			8.793,
			8.84,
			8.918,
			8.949,
			8.98,
			9.039,
			9.103,
			9.169,
			9.227,
			9.272,
			9.288,
			9.351,
			9.383,
			9.414,
			9.476,
			9.489,
			9.536,
			9.6,
			9.662,
			9.707,
			9.722,
			9.786,
			9.818,
			9.847,
			9.909,
			9.926,
			9.972,
			10.031,
			10.095,
			10.155,
			10.217,
			10.248,
			10.282,
			10.359,
			10.404,
			10.468,
			10.529,
			10.574,
			10.589,
			10.653,
			10.684,
			10.715,
			10.793,
			10.838,
			10.906,
			10.963,
			11.01,
			11.024,
			11.086,
			11.116,
			11.15,
			11.212,
			11.272,
			11.335,
			11.396,
			11.444,
			11.522,
			11.552,
			11.583,
			11.645,
			11.661,
			11.708,
			11.769,
			11.831,
			11.893,
			11.955,
			11.987,
			12.016,
			12.08,
			12.094,
			12.139,
			12.207,
			12.263,
			12.312,
			12.388,
			12.419,
			12.45,
			12.513,
			12.573,
			12.636,
			12.698,
			12.746,
			12.761,
			12.823,
			12.853,
			12.885,
			12.948,
			12.964,
			13.009,
			13.071,
			13.134,
			13.178,
			13.196,
			13.256,
			13.288,
			13.318,
			13.381,
			13.396,
			13.443,
			13.505,
			13.567,
			13.613,
			13.691,
			13.722,
			13.754,
			13.83,
			13.878,
			13.939,
			14,
			14.063,
			14.126,
			14.156,
			14.186,
			14.249,
			14.268,
			14.312,
			14.373,
			14.436,
			14.496,
			14.559,
			14.59,
			14.62,
			14.683,
			14.699,
			14.745,
			14.806,
			14.869,
			14.914,
			14.994,
			15.026,
			15.054,
			15.133,
			15.179,
			15.243,
			15.304,
			15.367,
			15.427,
			15.458,
			15.491,
			15.567,
			15.615,
			15.676,
			15.739,
			15.799,
			15.861,
			15.893,
			15.924,
			15.986,
			16.048,
			16.108,
			16.171,
			16.217,
			16.297,
			16.329,
			16.356,
			16.436,
			16.481,
			16.545,
			16.607,
			16.651,
			16.669,
			16.73,
			16.762,
			16.793,
			16.854,
			16.869,
			16.914,
			16.979,
			17.038,
			17.102,
			17.164,
			17.194,
			17.226,
			17.288,
			17.35,
			17.413,
			17.472,
			17.52,
			17.537,
			17.597,
			17.629,
			17.661,
			17.721,
			17.736,
			17.785,
			17.846,
			17.906,
			17.97,
			18.032,
			18.064,
			18.095,
			18.17,
			18.219,
			18.282,
			18.343,
			18.391,
			18.466,
			18.496,
			18.529,
			18.59,
			18.652,
			18.715,
			18.776,
			18.822,
			18.838,
			18.899,
			18.931,
			18.961,
			19.022,
			19.039,
			19.087,
			19.147,
			19.208,
			19.257,
			19.271,
			19.333,
			19.364,
			19.396,
			19.457,
			19.474,
			19.52,
			19.583,
			19.645,
			19.69,
			19.767,
			19.799,
			19.831,
			19.89,
			19.952,
			20.014,
			20.077,
			20.138,
			20.202,
			20.231,
			20.265,
			20.326,
			20.342,
			20.387,
			20.454,
			20.512,
			20.56,
			20.575,
			20.636,
			20.667,
			20.697,
			20.759,
			20.776,
			20.822,
			20.884,
			20.947,
			20.993,
			21.07,
			21.102,
			21.133,
			21.194,
			21.211,
			21.257,
			21.32,
			21.38,
			21.426,
			21.44,
			21.505,
			21.535,
			21.565,
			21.628,
			21.645,
			21.691,
			21.752,
			21.814,
			21.861,
			21.938,
			21.969,
			22,
			22.061,
			22.124,
			22.187,
			22.248,
			22.296,
			22.311,
			22.373,
			22.402,
			22.434,
			22.495,
			22.514,
			22.558,
			22.622,
			22.682,
			22.728,
			22.743,
			22.806,
			22.838,
			22.869,
			22.947,
			22.993,
			23.056,
			23.116,
			23.163,
			23.238,
			23.271,
			23.301,
			23.364,
			23.426,
			23.489,
			23.55,
			23.597,
			23.612,
			23.674,
			23.706,
			23.736,
			23.813,
			23.86,
			23.922,
			23.985,
			24.047,
			24.107,
			24.138,
			24.169,
			24.232,
			24.249,
			24.295,
			24.355,
			24.417,
			24.464,
			24.543,
			24.578,
			24.606,
			24.665,
			24.729,
			24.79,
			24.851,
			24.9,
			24.913,
			24.977,
			25.009,
			25.04,
			25.117,
			25.164,
			25.226,
			25.285,
			25.333,
			25.349,
			25.411,
			25.441,
			25.472,
			25.549,
			25.597,
			25.66,
			25.719,
			25.768,
			25.846,
			25.876,
			25.908,
			25.97,
			26.031,
			26.094,
			26.155,
			26.202,
			26.216,
			26.28,
			26.311,
			26.339,
			26.402,
			26.42,
			26.466,
			26.528,
			26.589,
			26.641,
			26.714,
			26.744,
			26.776,
			26.853,
			26.898,
			26.961,
			27.022,
			27.069,
			27.147,
			27.178,
			27.209,
			27.271,
			27.332,
			27.395,
			27.455,
			27.503,
			27.517,
			27.58,
			27.611,
			27.641,
			27.72,
			27.766,
			27.829,
			27.889,
			27.938,
			28.016,
			28.046,
			28.077,
			28.154,
			28.201,
			28.263,
			28.326,
			28.387,
			28.449,
			28.481,
			28.511,
			28.572,
			28.587,
			28.635,
			28.701,
			28.759,
			28.821,
			28.883,
			28.913,
			28.945,
			29.007,
			29.022,
			29.069,
			29.131,
			29.194,
			29.239,
			29.315,
			29.35,
			29.379,
			29.442,
			29.503,
			29.566,
			29.628,
			29.674,
			29.688,
			29.75,
			29.781,
			29.813,
			29.873,
			29.89,
			29.935,
			29.999,
			30.061,
			30.106,
			30.122,
			30.185,
			30.214,
			30.246,
			30.309,
			30.323,
			30.369,
			30.433,
			30.494,
			30.555,
			30.617,
			30.649,
			30.679,
			30.741,
			30.761,
			30.805,
			30.866,
			30.928,
			30.991,
			31.052,
			31.084,
			31.114,
			31.177,
			31.191,
			31.239,
			31.299,
			31.362,
			31.408,
			31.422,
			31.485,
			31.516,
			31.549,
			31.627,
			31.672,
			31.738,
			31.796,
			31.844,
			31.92,
			31.952,
			31.981,
			32.043,
			32.107,
			32.167,
			32.232,
			32.278,
			32.291,
			32.355,
			32.387,
			32.416,
			32.478,
			32.494,
			32.539,
			32.603,
			32.664,
			32.728,
			32.79,
			32.824,
			32.85,
			32.928,
			32.974,
			33.038,
			33.1,
			33.16,
			33.223,
			33.255,
			33.284,
			33.361,
			33.408,
			33.47,
			33.533,
			33.58,
			33.595,
			33.655,
			33.688,
			33.719,
			33.78,
			33.797,
			33.843,
			33.906,
			33.966,
			34.013,
			34.091,
			34.121,
			34.151,
			34.215,
			34.277,
			34.338,
			34.4,
			34.461,
			34.523,
			34.555,
			34.586,
			34.663,
			34.711,
			34.772,
			34.836,
			34.897,
			34.957,
			34.99,
			35.022,
			35.083,
			35.098,
			35.144,
			35.206,
			35.268,
			35.33,
			35.394,
			35.425,
			35.456,
			35.516,
			35.58,
			35.64,
			35.702,
			35.766,
			35.827,
			35.859,
			35.89,
			35.952,
			35.967,
			36.014,
			36.075,
			36.138,
			36.2,
			36.261,
			36.292,
			36.322,
			36.386,
			36.447,
			36.51,
			36.572,
			36.618,
			36.695,
			36.725,
			36.756,
			36.819,
			36.88,
			36.943,
			37.005,
			37.052,
			37.067,
			37.128,
			37.161,
			37.189,
			37.254,
			37.269,
			37.316,
			37.377,
			37.44,
			37.486,
			37.564,
			37.595,
			37.626,
			37.687,
			37.749,
			37.81,
			37.872,
			37.919,
			37.998,
			38.028,
			38.059,
			38.122,
			38.137,
			38.183,
			38.246,
			38.309,
			38.354,
			38.368,
			38.431,
			38.464,
			38.494,
			38.572,
			38.616,
			38.681,
			38.741,
			38.787,
			38.864,
			38.897,
			38.928,
			39.011,
			39.053,
			39.115,
			39.177,
			39.222,
			39.236,
			39.299,
			39.332,
			39.362,
			39.439,
			39.487,
			39.549,
			39.609,
			39.672,
			39.733,
			39.764,
			39.794,
			39.858,
			39.872,
			39.919,
			39.981,
			40.044,
			40.089,
			40.169,
			40.198,
			40.23,
			40.293,
			40.352,
			40.415,
			40.478,
			40.523,
			40.54,
			40.601,
			40.634,
			40.663,
			40.726,
			40.74,
			40.789,
			40.85,
			40.913,
			40.973,
			41.036,
			41.073,
			41.099,
			41.161,
			41.222,
			41.284,
			41.347,
			41.391,
			41.471,
			41.5,
			41.533,
			41.595,
			41.611,
			41.656,
			41.719,
			41.781,
			41.841,
			41.905,
			41.935,
			41.965,
			42.03,
			42.044,
			42.091,
			42.152,
			42.214,
			42.276,
			42.337,
			42.369,
			42.399,
			42.478,
			42.523,
			42.586,
			42.649,
			42.71,
			42.771,
			42.802,
			42.834,
			42.912,
			42.957,
			43.02,
			43.082,
			43.133,
			43.144,
			43.205,
			43.238,
			43.269,
			43.33,
			43.347,
			43.394,
			43.456,
			43.515,
			43.563,
			43.641,
			43.672,
			43.701,
			43.764,
			43.825,
			43.89,
			43.951,
			43.996,
			44.013,
			44.074,
			44.106,
			44.135,
			44.198,
			44.213,
			44.259,
			44.321,
			44.384,
			44.43,
			44.447,
			44.509,
			44.54,
			44.569,
			44.649,
			44.695,
			44.758,
			44.818,
			44.881,
			44.942,
			44.973,
			45.005,
			45.081,
			45.127,
			45.195,
			45.252,
			45.3,
			45.314,
			45.376,
			45.407,
			45.437,
			45.5,
			45.515,
			45.562,
			45.622,
			45.687,
			45.733,
			45.749,
			45.811,
			45.842,
			45.872,
			45.935,
			45.95,
			45.997,
			46.058,
			46.119,
			46.167,
			46.244,
			46.276,
			46.305,
			46.369,
			46.431,
			46.493,
			46.554,
			46.616,
			46.678,
			46.709,
			46.74,
			46.801,
			46.819,
			46.863,
			46.928,
			46.99,
			47.051,
			47.113,
			47.144,
			47.174,
			47.235,
			47.258,
			47.298,
			47.361,
			47.422,
			47.469,
			47.546,
			47.579,
			47.608,
			47.67,
			47.686,
			47.734,
			47.795,
			47.858,
			47.919,
			47.981,
			48.011,
			48.041,
			48.106,
			48.12,
			48.166,
			48.229,
			48.291,
			48.353,
			48.413,
			48.446,
			48.477,
			48.54,
			48.6,
			48.663,
			48.723,
			48.771,
			48.849,
			48.881,
			48.911,
			48.989,
			49.034,
			49.102,
			49.157,
			49.204,
			49.221,
			49.284,
			49.319,
			49.345,
			49.422,
			49.47,
			49.53,
			49.594,
			49.64,
			49.716,
			49.747,
			49.778,
			49.856,
			49.902,
			49.964,
			50.028,
			50.075,
			50.088,
			50.151,
			50.183,
			50.213,
			50.273,
			50.291,
			50.338,
			50.4,
			50.461,
			50.506,
			50.523,
			50.584,
			50.617,
			50.648,
			50.709,
			50.723,
			50.772,
			50.832,
			50.895,
			50.956,
			51.02,
			51.051,
			51.082,
			51.143,
			51.204,
			51.267,
			51.328,
			51.39,
			51.452,
			51.483,
			51.515,
			51.578,
			51.594,
			51.639,
			51.702,
			51.763,
			51.824,
			51.887,
			51.919,
			51.947,
			52.012,
			52.073,
			52.136,
			52.199,
			52.245,
			52.323,
			52.352,
			52.385,
			52.445,
			52.461,
			52.509,
			52.571,
			52.631,
			52.678,
			52.693,
			52.755,
			52.785,
			52.817,
			52.878,
			52.896,
			52.941,
			53.005,
			53.066,
			53.113,
			53.19,
			53.221,
			53.252,
			53.328,
			53.376,
			53.441,
			53.5,
			53.561,
			53.623,
			53.655,
			53.687,
			53.764,
			53.811,
			53.873,
			53.933,
			53.981,
			53.996,
			54.056,
			54.089,
			54.119,
			54.183,
			54.196,
			54.244,
			54.305,
			54.369,
			54.413,
			54.493,
			54.522,
			54.553,
			54.617,
			54.678,
			54.739,
			54.802,
			54.85,
			54.864,
			54.927,
			54.957,
			54.987,
			55.049,
			55.066,
			55.113,
			55.172,
			55.237,
			55.283,
			55.297,
			55.36,
			55.39,
			55.423,
			55.485,
			55.505,
			55.547,
			55.607,
			55.67,
			55.733,
			55.795,
			55.823,
			55.855,
			55.933,
			55.98,
			56.041,
			56.104,
			56.15,
			56.167,
			56.229,
			56.26,
			56.289,
			56.352,
			56.369,
			56.414,
			56.476,
			56.538,
			56.601,
			56.662,
			56.691,
			56.725,
			56.802,
			56.847,
			56.911,
			56.971,
			57.019,
			57.096,
			57.127,
			57.157,
			57.219,
			57.236,
			57.281,
			57.346,
			57.407,
			57.452,
			57.468,
			57.53,
			57.566,
			57.593,
			57.655,
			57.669,
			57.715,
			57.778,
			57.841,
			57.901,
			57.964,
			57.996,
			58.026,
			58.088,
			58.152,
			58.212,
			58.275,
			58.322,
			58.336,
			58.397,
			58.428,
			58.461,
			58.521,
			58.539,
			58.586,
			58.651,
			58.708,
			58.756,
			58.77,
			58.832,
			58.863,
			58.896,
			58.955,
			58.972,
			59.017,
			59.08,
			59.144,
			59.188,
			59.267,
			59.296,
			59.329,
			59.391,
			59.451,
			59.514,
			59.578,
			59.627,
			59.639,
			59.699,
			59.731,
			59.763,
			59.825,
			59.838,
			59.887,
			59.949,
			60.011,
			60.058,
			60.072,
			60.135,
			60.166,
			60.196,
			60.258,
			60.272,
			60.322,
			60.383,
			60.445,
			60.492,
			60.57,
			60.599,
			60.63,
			60.694,
			60.756,
			60.816,
			60.878,
			60.924,
			60.942,
			61.003,
			61.035,
			61.065,
			61.128,
			61.144,
			61.188,
			61.25,
			61.313,
			61.36,
			61.376,
			61.436,
			61.469,
			61.5,
			61.561,
			61.623,
			61.688,
			61.747,
			61.809,
			61.87,
			61.902,
			61.933,
			61.996,
			62.011,
			62.058,
			62.124,
			62.18,
			62.228,
			62.241,
			62.305,
			62.336,
			62.366,
			62.429,
			62.444,
			62.492,
			62.553,
			62.616,
			62.662,
			62.739,
			62.771,
			62.802,
			62.879,
			62.926,
			62.988,
			63.05,
			63.112,
			63.172,
			63.204,
			63.236,
			63.314,
			63.36,
			63.42,
			63.484,
			63.53,
			63.544,
			63.606,
			63.638,
			63.669,
			63.75,
			63.793,
			63.855,
			63.919,
			63.965,
			64.041,
			64.073,
			64.102,
			64.166,
			64.229,
			64.291,
			64.351,
			64.413,
			64.473,
			64.506,
			64.538,
			64.599,
			64.616,
			64.661,
			64.722,
			64.784,
			64.833,
			64.847,
			64.91,
			64.939,
			64.972,
			65.034,
			65.048,
			65.096,
			65.157,
			65.219,
			65.266,
			65.345,
			65.373,
			65.405,
			65.469,
			65.531,
			65.591,
			65.652,
			65.699,
			65.715,
			65.779,
			65.813,
			65.838,
			65.9,
			65.919,
			65.963,
			66.024,
			66.086,
			66.135,
			66.213,
			66.243,
			66.272,
			66.352,
			66.397,
			66.46,
			66.523,
			66.569,
			66.646,
			66.678,
			66.706,
			66.769,
			66.786,
			66.832,
			66.895,
			66.956,
			67.002,
			67.019,
			67.081,
			67.111,
			67.141,
			67.22,
			67.267,
			67.328,
			67.391,
			67.435,
			67.452,
			67.515,
			67.546,
			67.576,
			67.637,
			67.699,
			67.762,
			67.822,
			67.875,
			67.948,
			67.98,
			68.011,
			68.072,
			68.087,
			68.134,
			68.196,
			68.257,
			68.305,
			68.319,
			68.381,
			68.414,
			68.443,
			68.522,
			68.569,
			68.629,
			68.693,
			68.753,
			68.816,
			68.847,
			68.877,
			68.942,
			69.002,
			69.064,
			69.126,
			69.171,
			69.188,
			69.251,
			69.282,
			69.314,
			69.389,
			69.436,
			69.499,
			69.561,
			69.605,
			69.622,
			69.686,
			69.716,
			69.747,
			69.81,
			69.824,
			69.87,
			69.932,
			69.995,
			70.041,
			70.118,
			70.15,
			70.181,
			70.257,
			70.304,
			70.367,
			70.428,
			70.473,
			70.49,
			70.553,
			70.585,
			70.613,
			70.677,
			70.69,
			70.739,
			70.802,
			70.862,
			70.908,
			70.926,
			70.986,
			71.018,
			71.049,
			71.127,
			71.173,
			71.236,
			71.296,
			71.343,
			71.42,
			71.452,
			71.483,
			71.561,
			71.608,
			71.669,
			71.731,
			71.778,
			71.791,
			71.854,
			71.885,
			71.916,
			71.978,
			71.998,
			72.04,
			72.104,
			72.164,
			72.228,
			72.29,
			72.32,
			72.35,
			72.414,
			72.476,
			72.538,
			72.598,
			72.661,
			72.724,
			72.754,
			72.786,
			72.846,
			72.861,
			72.909,
			72.972,
			73.034,
			73.08,
			73.095,
			73.157,
			73.188,
			73.219,
			73.297,
			73.343,
			73.407,
			73.466,
			73.513,
			73.59,
			73.621,
			73.654,
			73.731,
			73.778,
			73.839,
			73.901,
			73.946,
			73.963,
			74.026,
			74.06,
			74.088,
			74.166,
			74.211,
			74.272,
			74.336,
			74.38,
			74.396,
			74.459,
			74.489,
			74.521,
			74.583,
			74.599,
			74.647,
			74.707,
			74.769,
			74.83,
			74.895,
			74.926,
			74.955,
			75.019,
			75.079,
			75.14,
			75.205,
			75.25,
			75.266,
			75.328,
			75.359,
			75.39,
			75.468,
			75.513,
			75.576,
			75.638,
			75.685,
			75.7,
			75.762,
			75.791,
			75.822,
			75.885,
			75.9,
			75.948,
			76.014,
			76.072,
			76.122,
			76.196,
			76.227,
			76.256,
			76.319,
			76.336,
			76.382,
			76.445,
			76.506,
			76.552,
			76.566,
			76.63,
			76.662,
			76.69,
			76.752,
			76.768,
			76.814,
			76.878,
			76.939,
			76.986,
			77.063,
			77.095,
			77.124,
			77.187,
			77.249,
			77.312,
			77.373,
			77.419,
			77.437,
			77.498,
			77.53,
			77.559,
			77.621,
			77.638,
			77.683,
			77.746,
			77.808,
			77.855,
			77.869,
			77.933,
			77.962,
			77.995,
			78.055,
			78.071,
			78.119,
			78.184,
			78.241,
			78.305,
			78.367,
			78.397,
			78.428,
			78.505,
			78.551,
			78.614,
			78.676,
			78.738,
			78.8,
			78.832,
			78.862,
			78.941,
			78.987,
			79.048,
			79.111,
			79.156,
			79.173,
			79.234,
			79.264,
			79.296,
			79.356,
			79.374,
			79.419,
			79.483,
			79.544,
			79.605,
			79.668,
			79.699,
			79.73,
			79.793,
			79.853,
			79.916,
			79.977,
			80.023,
			80.041,
			80.103,
			80.134,
			80.165,
			80.226,
			80.247,
			80.287,
			80.351,
			80.413,
			80.46,
			80.473,
			80.537,
			80.569,
			80.598,
			80.661,
			80.722,
			80.786,
			80.846,
			80.891,
			80.97,
			81.003,
			81.033,
			81.095,
			81.111,
			81.158,
			81.218,
			81.28,
			81.328,
			81.341,
			81.404,
			81.436,
			81.466,
			81.544,
			81.591,
			81.653,
			81.715,
			81.762,
			81.839,
			81.87,
			81.899,
			81.963,
			82.024,
			82.088,
			82.148,
			82.194,
			82.211,
			82.272,
			82.307,
			82.335,
			82.397,
			82.411,
			82.459,
			82.52,
			82.581,
			82.63,
			82.644,
			82.705,
			82.737,
			82.768,
			82.846,
			82.894,
			82.954,
			83.016,
			83.063,
			83.142,
			83.173,
			83.203,
			83.263,
			83.327,
			83.39,
			83.451,
			83.497,
			83.513,
			83.573,
			83.605,
			83.638,
			83.7,
			83.715,
			83.761,
			83.822,
			83.884,
			83.931,
			83.948,
			84.009,
			84.039,
			84.071,
			84.148,
			84.196,
			84.256,
			84.319,
			84.381,
			84.443,
			84.473,
			84.504,
			84.568,
			84.628,
			84.69,
			84.754,
			84.816,
			84.878,
			84.907,
			84.94,
			85.002,
			85.016,
			85.063,
			85.124,
			85.188,
			85.233,
			85.25,
			85.312,
			85.342,
			85.372,
			85.434,
			85.452,
			85.498,
			85.56,
			85.622,
			85.668,
			85.746,
			85.776,
			85.805,
			85.869,
			85.931,
			85.995,
			86.054,
			86.103,
			86.117,
			86.18,
			86.211,
			86.24,
			86.32,
			86.366,
			86.431,
			86.489,
			86.553,
			86.613,
			86.645,
			86.676,
			86.754,
			86.8,
			86.863,
			86.924,
			86.969,
			86.987,
			87.048,
			87.078,
			87.109,
			87.172,
			87.188,
			87.234,
			87.295,
			87.356,
			87.404,
			87.418,
			87.483,
			87.514,
			87.543,
			87.606,
			87.62,
			87.666,
			87.731,
			87.791,
			87.839,
			87.917,
			87.946,
			87.977,
			88.038,
			88.103,
			88.163,
			88.227,
			88.272,
			88.286,
			88.35,
			88.381,
			88.413,
			88.473,
			88.495,
			88.537,
			88.598,
			88.658,
			88.705,
			88.722,
			88.784,
			88.815,
			88.847,
			88.907,
			88.922,
			88.969,
			89.033,
			89.094,
			89.139,
			89.218,
			89.25,
			89.281,
			89.342,
			89.404,
			89.467,
			89.529,
			89.573,
			89.59,
			89.652,
			89.684,
			89.714,
			89.776,
			89.79,
			89.839,
			89.899,
			89.961,
			90.009,
			90.025,
			90.087,
			90.117,
			90.148,
			90.21,
			90.226,
			90.272,
			90.333,
			90.396,
			90.441,
			90.521,
			90.555,
			90.583,
			90.644,
			90.705,
			90.769,
			90.829,
			90.877,
			90.891,
			90.953,
			90.986,
			91.017,
			91.077,
			91.093,
			91.14,
			91.202,
			91.263,
			91.327,
			91.387,
			91.42,
			91.451,
			91.512,
			91.576,
			91.636,
			91.698,
			91.761,
			91.822,
			91.854,
			91.885,
			91.962,
			92.009,
			92.072,
			92.133,
			92.18,
			92.193,
			92.258,
			92.288,
			92.32,
			92.396,
			92.444,
			92.505,
			92.566,
			92.617,
			92.691,
			92.723,
			92.751,
			92.814,
			92.875,
			92.938,
			93,
			93.048,
			93.063,
			93.123,
			93.155,
			93.187,
			93.25,
			93.264,
			93.311,
			93.372,
			93.436,
			93.482,
			93.497,
			93.559,
			93.589,
			93.621,
			93.683,
			93.697,
			93.746,
			93.807,
			93.869,
			93.931,
			93.993,
			94.024,
			94.053,
			94.116,
			94.179,
			94.239,
			94.302,
			94.348,
			94.365,
			94.429,
			94.459,
			94.487,
			94.568,
			94.613,
			94.681,
			94.736,
			94.783,
			94.799,
			94.861,
			94.891,
			94.923,
			95.003,
			95.047,
			95.109,
			95.17,
			95.219,
			95.295,
			95.327,
			95.356,
			95.436,
			95.48,
			95.544,
			95.604,
			95.652,
			95.666,
			95.73,
			95.759,
			95.79,
			95.853,
			95.867,
			95.914,
			95.978,
			96.04,
			96.102,
			96.162,
			96.194,
			96.225,
			96.286,
			96.35,
			96.412,
			96.473,
			96.519,
			96.536,
			96.598,
			96.627,
			96.66,
			96.721,
			96.739,
			96.782,
			96.849,
			96.906,
			96.952,
			96.969,
			97.031,
			97.062,
			97.094,
			97.154,
			97.171,
			97.217,
			97.28,
			97.343,
			97.387,
			97.465,
			97.497,
			97.527,
			97.588,
			97.652,
			97.714,
			97.774,
			97.837,
			97.9,
			97.93,
			97.963,
			98.038,
			98.085,
			98.147,
			98.21,
			98.257,
			98.271,
			98.334,
			98.366,
			98.395,
			98.473,
			98.52,
			98.581,
			98.645,
			98.705,
			98.767,
			98.83,
			98.891,
			98.955,
			99.014,
			99.078,
			99.141,
			99.203,
			99.232,
			99.265,
			99.327,
			99.341,
			99.388,
			99.451,
			99.511,
			99.556,
			99.574,
			99.637,
			99.665,
			99.696,
			99.761,
			99.822,
			99.884,
			99.944,
			99.993,
			100.07,
			100.1,
			100.131,
			100.21,
			100.255,
			100.319,
			100.38,
			100.424,
			100.441,
			100.504,
			100.534,
			100.566,
			100.644,
			100.689,
			100.753,
			100.814,
			100.877,
			100.939,
			100.969,
			101.001,
			101.062,
			101.122,
			101.187,
			101.248,
			101.294,
			101.311,
			101.372,
			101.404,
			101.433,
			101.512,
			101.558,
			101.621,
			101.681,
			101.73,
			101.744,
			101.805,
			101.837,
			101.867,
			101.93,
			101.946,
			101.992,
			102.054,
			102.116,
			102.161,
			102.239,
			102.27,
			102.302,
			102.38,
			102.427,
			102.49,
			102.549,
			102.597,
			102.611,
			102.673,
			102.705,
			102.736,
			102.797,
			102.814,
			102.861,
			102.927,
			102.985,
			103.031,
			103.046,
			103.107,
			103.139,
			103.169,
			103.233,
			103.247,
			103.294,
			103.355,
			103.419,
			103.466,
			103.542,
			103.572,
			103.606,
			103.666,
			103.728,
			103.791,
			103.854,
			103.898,
			103.914,
			103.978,
			104.006,
			104.037,
			104.117,
			104.163,
			104.229,
			104.286,
			104.332,
			104.349,
			104.411,
			104.443,
			104.472,
			104.535,
			104.549,
			104.597,
			104.658,
			104.719,
			104.767,
			104.844,
			104.875,
			104.906,
			104.97,
			104.987,
			105.031,
			105.092,
			105.156,
			105.201,
			105.217,
			105.277,
			105.309,
			105.342,
			105.419,
			105.464,
			105.527,
			105.59,
			105.635,
			105.65,
			105.713,
			105.744,
			105.773,
			105.796
		]
	],
	'7818_8070_7982': [
		[
			64.574
		],
		[
			0
		],
		[
			0,
			0.896,
			3.756,
			5.201,
			6.647,
			10.997,
			13.901,
			16.811,
			18.272,
			21.196,
			24.121,
			25.585,
			29.984,
			32.925,
			35.875,
			40.331,
			43.325,
			47.844,
			49.359,
			50.875,
			55.463,
			58.538,
			63.144,
			66.193,
			69.214,
			70.71,
			73.665,
			75.125,
			78.001,
			82.215,
			84.949,
			87.637,
			91.578,
			92.871,
			94.155,
			96.693,
			99.196,
			100.434,
			102.904,
			104.117,
			106.518,
			108.897,
			112.391,
			114.702,
			118.107,
			119.226,
			120.336,
			123.62,
			125.766,
			128.934,
			131.004,
			134.053,
			136.04,
			137.02,
			138.959,
			141.799,
			143.649,
			145.461,
			148.08,
			148.923,
			149.756,
			152.19,
			152.965,
			153.73,
			155.971,
			157.415,
			158.817,
			160.855,
			162.171,
			164.086,
			164.708,
			165.32,
			167.102,
			167.69,
			168.259,
			169.917,
			170.991,
			172.562,
			173.581,
			175.085,
			176.077,
			176.571,
			177.545,
			178.503,
			179.939,
			180.419,
			180.9,
			182.342,
			182.811,
			183.279,
			184.688,
			185.634,
			187.07,
			188.141,
			189.215,
			189.754,
			190.836,
			191.379,
			191.923,
			193.868,
			194.526,
			195.186,
			197.17,
			198.564,
			199.948,
			200.636,
			202.009,
			203.373,
			204.05,
			206.066,
			207.395,
			208.686,
			210.6,
			211.867,
			213.72,
			214.33,
			214.938,
			216.749,
			217.998,
			219.888,
			221.136,
			223.021,
			224.286,
			224.913,
			226.158,
			228.037,
			228.668,
			230.543,
			231.782,
			233.007,
			233.612,
			234.816,
			236.012,
			236.609,
			237.749,
			238.29,
			239.369,
			240.443,
			242.052,
			243.048,
			244.511,
			244.998,
			245.484,
			246.946,
			247.877,
			249.24,
			250.152,
			251.066,
			251.526,
			252.448,
			252.908,
			253.832,
			254.761,
			255.228,
			256.166,
			257.112,
			258.602,
			259.612,
			261.145,
			261.661,
			262.18,
			263.8,
			264.908,
			266.599,
			267.772,
			269.016,
			270.913,
			271.554,
			272.199,
			274.289,
			275.713,
			277.882,
			279.344,
			280.824,
			281.571,
			283.03,
			284.457,
			285.171,
			287.337,
			288.743,
			290.16,
			292.314,
			293.042,
			293.773,
			295.99,
			296.737,
			297.487,
			299.774,
			301.317,
			303.667,
			305.257,
			306.862,
			307.671,
			309.309,
			310.133,
			310.96,
			314.295,
			315.134,
			317.672,
			319.342,
			321.026,
			321.872,
			323.56,
			325.242,
			326.081,
			327.761,
			328.604,
			330.298,
			332.003,
			334.574,
			336.327,
			338.961,
			339.847,
			340.735,
			343.42,
			345.227,
			347.958,
			349.792,
			352.563,
			354.424,
			355.358,
			357.235,
			360.07,
			361.973,
			363.886,
			365.807,
			367.724,
			368.691,
			370.633,
			372.586,
			373.564,
			375.526,
			376.511,
			378.487,
			380.471,
			383.465,
			385.472,
			388.49,
			389.496,
			390.501,
			393.502,
			395.511,
			398.462,
			400.431,
			402.384,
			403.356,
			405.299,
			407.233,
			408.195,
			410.091,
			411.036,
			412.919,
			414.777,
			417.545,
			418.461,
			419.371,
			422.091,
			422.995,
			423.891,
			426.573,
			428.359,
			430.13,
			432.788,
			434.533,
			437.14,
			438.009,
			438.871,
			441.453,
			443.174,
			445.909,
			447.728,
			449.742,
			450.777,
			452.843,
			455.142,
			456.31,
			458.632,
			459.766,
			462.03,
			464.175,
			467.18,
			469.155,
			472.065,
			473.034,
			474.016,
			476.968,
			478.929,
			481.856,
			483.794,
			485.707,
			488.56,
			489.477,
			490.392,
			494.021,
			494.905,
			497.566,
			499.343,
			501.11,
			502.005,
			503.803,
			505.613,
			506.523,
			509.268,
			511.11,
			512.965,
			515.767,
			517.649,
			520.492,
			521.445,
			522.401,
			525.306,
			527.247,
			530.185,
			532.159,
			535.14,
			537.14,
			538.144,
			540.163,
			542.192,
			543.21,
			545.271,
			547.344,
			550.495,
			551.553,
			552.612,
			554.755,
			556.917,
			557.996,
			560.175,
			561.268,
			563.444,
			566.748,
			568.934,
			571.153,
			574.427,
			575.501,
			576.569,
			579.677,
			581.646,
			584.521,
			586.355,
			588.194,
			589.13,
			590.964,
			591.885,
			593.735,
			596.569,
			598.474,
			600.407,
			603.334,
			604.316,
			605.302,
			608.278,
			609.278,
			610.283,
			612.303,
			613.317,
			615.358,
			617.411,
			620.508,
			622.586,
			625.704,
			626.753,
			627.802,
			630.945,
			633.047,
			636.214,
			638.335,
			640.458,
			641.521,
			643.653,
			645.789,
			646.859,
			650.088,
			652.262,
			654.445,
			657.753,
			659.97,
			663.312,
			664.43,
			665.551,
			668.926,
			671.183,
			674.568,
			676.809,
			679.022,
			680.118,
			682.281,
			683.348,
			684.406,
			687.523,
			688.542,
			689.552,
			692.526,
			694.462,
			697.295,
			699.138,
			700.945,
			701.835,
			704.453,
			706.152,
			707.813,
			710.234,
			711.801,
			714.081,
			714.822,
			715.554,
			717.695,
			719.078,
			721.088,
			722.386,
			723.65,
			724.268,
			725.477,
			726.067,
			726.649,
			728.885,
			729.423,
			731,
			732.016,
			733.488,
			734.45,
			735.408,
			735.886,
			736.838,
			737.313,
			738.257,
			739.194,
			740.592,
			741.504,
			742.85,
			743.296,
			743.742,
			745.076,
			745.959,
			747.262,
			748.112,
			748.954,
			749.374,
			750.202,
			750.609,
			751.413,
			752.205,
			752.599,
			753.381,
			754.155,
			755.363,
			755.777,
			756.187,
			756.998,
			757.799,
			758.197,
			759.385,
			760.283,
			761.233,
			762.643,
			763.578,
			764.996,
			765.486,
			765.974,
			767.915,
			768.4,
			769.871,
			770.893,
			771.913,
			772.423,
			773.444,
			773.955,
			774.984,
			776.537,
			777.565,
			778.594,
			780.135,
			780.64,
			781.142,
			782.634,
			783.129,
			783.624,
			785.112,
			786.087,
			787.542,
			788.513,
			789.492,
			789.985,
			790.968,
			791.458,
			791.949,
			793.43,
			793.928,
			794.428,
			795.863,
			796.81,
			797.765,
			798.245,
			799.218,
			800.185,
			800.655,
			801.605,
			802.085,
			803.057,
			804.044,
			805.554,
			806.582,
			808.148,
			808.683,
			809.224,
			810.887,
			812.017,
			813.75,
			814.932,
			816.161,
			816.783,
			818.039,
			818.674,
			819.961,
			821.452,
			822.198,
			822.948,
			825.237,
			826.864,
			829.337,
			830.867,
			832.337,
			833.077,
			834.572,
			835.305,
			836.742,
			838.189,
			840.356,
			841.782,
			843.935,
			844.647,
			845.352,
			847.437,
			848.82,
			850.924,
			852.347,
			854.469,
			856.033,
			856.857,
			858.498,
			860.159,
			861.148,
			863.12,
			865.073,
			867.463,
			868.255,
			869.048,
			871.195,
			871.855,
			872.514,
			874.488,
			875.799,
			877.105,
			879.061,
			880.369,
			882.358,
			883.023,
			883.688,
			885.699,
			887.057,
			889.095,
			890.477,
			891.881,
			892.589,
			894.019,
			894.752,
			896.216,
			897.699,
			898.447,
			899.956,
			901.488,
			903.817,
			904.602,
			905.392,
			907.788,
			908.595,
			909.406,
			911.87,
			913.533,
			916.043,
			917.744,
			919.446,
			921.993,
			922.847,
			923.705,
			926.292,
			927.158,
			928.028,
			930.66,
			932.433,
			934.22,
			935.119,
			936.931,
			938.756,
			939.675,
			941.522,
			942.45,
			944.318,
			946.199,
			949.046,
			950.96,
			953.856,
			954.828,
			955.803,
			958.749,
			960.729,
			963.723,
			965.734,
			967.758,
			968.775,
			970.818,
			971.844,
			973.906,
			975.98,
			977.021,
			978.066,
			981.216,
			983.32,
			985.426,
			986.483,
			988.607,
			990.74,
			991.818,
			993.98,
			995.066,
			997.244,
			999.433,
			1002.72,
			1004.918,
			1008.23,
			1009.339,
			1010.449,
			1013.798,
			1016.044,
			1019.44,
			1021.718,
			1025.157,
			1027.458,
			1028.611,
			1030.925,
			1033.247,
			1034.412,
			1036.749,
			1039.097,
			1042.64,
			1043.826,
			1045.015,
			1047.401,
			1049.799,
			1051.001,
			1054.643,
			1057.085,
			1059.54,
			1063.231,
			1065.7,
			1069.421,
			1070.667,
			1071.915,
			1075.671,
			1078.186,
			1081.974,
			1084.509,
			1087.053,
			1088.328,
			1090.884,
			1092.165,
			1094.732,
			1098.598,
			1101.186,
			1103.791,
			1107.715,
			1109.026,
			1110.336,
			1114.267,
			1115.58,
			1116.894,
			1120.841,
			1123.483,
			1127.458,
			1130.118,
			1132.786,
			1136.807,
			1138.157,
			1139.509,
			1143.577,
			1144.936,
			1146.298,
			1150.384,
			1153.119,
			1157.242,
			1160.003,
			1162.772,
			1164.16,
			1168.331,
			1171.119,
			1173.913,
			1178.119,
			1180.933,
			1185.161,
			1186.573,
			1187.987,
			1192.238,
			1195.082,
			1199.362,
			1202.226,
			1205.097,
			1209.418,
			1210.862,
			1213.756,
			1218.114,
			1221.035,
			1223.97,
			1226.916,
			1229.863,
			1231.339,
			1234.293,
			1237.251,
			1238.732,
			1241.699,
			1243.181,
			1246.135,
			1249.068,
			1253.41,
			1256.26,
			1260.462,
			1261.845,
			1263.218,
			1267.282,
			1269.948,
			1273.904,
			1276.459,
			1280.25,
			1282.729,
			1283.951,
			1286.363,
			1288.742,
			1289.922,
			1292.275,
			1294.573,
			1298.017,
			1299.148,
			1300.274,
			1303.626,
			1304.738,
			1305.849,
			1308.07,
			1309.182,
			1311.413,
			1313.654,
			1317.053,
			1319.346,
			1322.829,
			1324.009,
			1325.206,
			1328.801,
			1331.222,
			1334.811,
			1337.156,
			1339.449,
			1340.564,
			1342.77,
			1343.859,
			1345.992,
			1348.092,
			1349.13,
			1351.182,
			1353.202,
			1356.187,
			1358.149,
			1361.073,
			1362.042,
			1363.008,
			1365.907,
			1367.835,
			1369.767,
			1372.674,
			1374.625,
			1377.581,
			1378.567,
			1379.563,
			1383.571,
			1384.573,
			1387.578,
			1389.566,
			1391.524,
			1392.503,
			1394.451,
			1395.411,
			1397.33,
			1399.248,
			1400.206,
			1402.123,
			1404.041,
			1406.934,
			1408.865,
			1411.781,
			1412.753,
			1413.727,
			1416.653,
			1418.604,
			1421.539,
			1423.496,
			1426.442,
			1428.41,
			1429.395,
			1430.382,
			1433.35,
			1434.342,
			1435.337,
			1438.332,
			1440.351,
			1443.413,
			1445.48,
			1447.568,
			1448.617,
			1450.723,
			1451.78,
			1453.903,
			1456.026,
			1459.224,
			1461.363,
			1464.59,
			1465.67,
			1466.753,
			1470.02,
			1472.212,
			1475.523,
			1477.748,
			1479.985,
			1481.113,
			1483.379,
			1484.516,
			1486.805,
			1489.107,
			1490.261,
			1492.575,
			1494.902,
			1498.402,
			1499.574,
			1500.741,
			1504.248,
			1505.417,
			1506.58,
			1508.914,
			1510.084,
			1512.422,
			1514.77,
			1518.318,
			1520.697,
			1524.288,
			1525.491,
			1526.696,
			1530.327,
			1532.76,
			1536.429,
			1538.886,
			1541.335,
			1542.573,
			1545.078,
			1546.304,
			1548.803,
			1551.312,
			1552.571,
			1555.094,
			1557.625,
			1561.441,
			1563.996,
			1566.561,
			1569.135,
			1570.426,
			1574.316,
			1576.922,
			1579.529,
			1583.452,
			1586.079,
			1590.036,
			1591.36,
			1592.686,
			1596.676,
			1598.009,
			1599.345,
			1603.364,
			1606.053,
			1608.751,
			1610.104,
			1612.817,
			1614.177,
			1616.905,
			1619.639,
			1621.01,
			1623.753,
			1626.505,
			1630.644,
			1633.414,
			1637.585,
			1638.98,
			1640.376,
			1644.578,
			1647.389,
			1651.62,
			1654.449,
			1657.285,
			1658.705,
			1661.55,
			1662.973,
			1664.399,
			1668.687,
			1670.12,
			1671.556,
			1675.876,
			1678.766,
			1683.115,
			1686.023,
			1688.938,
			1690.397,
			1693.322,
			1694.787,
			1697.722,
			1700.664,
			1705.089,
			1708.047,
			1712.496,
			1713.983,
			1715.471,
			1719.944,
			1722.934,
			1727.434,
			1730.448,
			1734.991,
			1738.034,
			1739.561,
			1742.62,
			1745.687,
			1747.223,
			1750.297,
			1753.373,
			1756.449,
			1761.055,
			1764.128,
			1767.206,
			1768.75,
			1771.844,
			1773.391,
			1776.489,
			1779.594,
			1784.258,
			1787.37,
			1792.026,
			1793.577,
			1795.128,
			1799.787,
			1802.897,
			1807.571,
			1810.692,
			1815.38,
			1818.51,
			1820.077,
			1823.212,
			1826.351,
			1827.922,
			1831.068,
			1834.217,
			1838.947,
			1842.106,
			1846.86,
			1848.453,
			1850.059,
			1854.915,
			1858.191,
			1863.139,
			1866.446,
			1869.745,
			1874.663,
			1876.284,
			1877.896,
			1882.573,
			1885.57,
			1889.945,
			1892.791,
			1895.605,
			1897.001,
			1899.773,
			1901.15,
			1903.879,
			1906.578,
			1907.915,
			1910.565,
			1913.18,
			1917.035,
			1918.302,
			1919.563,
			1923.293,
			1924.531,
			1925.764,
			1929.43,
			1931.886,
			1934.341,
			1937.994,
			1940.404,
			1943.986,
			1945.166,
			1946.343,
			1949.814,
			1950.952,
			1952.09,
			1955.475,
			1957.724,
			1959.96,
			1961.084,
			1963.33,
			1965.607,
			1966.752,
			1970.211,
			1972.547,
			1974.895,
			1978.447,
			1980.807,
			1984.348,
			1985.504,
			1986.662,
			1990.112,
			1992.406,
			1995.852,
			1998.145,
			2000.419,
			2001.546,
			2003.779,
			2004.883,
			2007.059,
			2009.199,
			2010.257,
			2011.323,
			2014.413,
			2016.446,
			2018.441,
			2019.424,
			2021.408,
			2023.374,
			2024.36,
			2027.35,
			2029.321,
			2031.343,
			2034.357,
			2036.325,
			2039.266,
			2040.217,
			2041.15,
			2043.958,
			2044.897,
			2045.831,
			2048.647,
			2050.55,
			2053.444,
			2055.386,
			2056.362,
			2058.317,
			2060.271,
			2061.242,
			2063.182,
			2065.114,
			2067.968,
			2068.915,
			2069.858,
			2072.654,
			2073.579,
			2074.5,
			2076.331,
			2077.24,
			2079.048,
			2080.835,
			2083.474,
			2085.201,
			2087.732,
			2088.56,
			2089.379,
			2091.779,
			2093.331,
			2095.593,
			2097.062,
			2098.491,
			2099.202,
			2100.624,
			2101.324,
			2102.718,
			2104.102,
			2104.792,
			2106.161,
			2107.521,
			2109.552,
			2110.228,
			2110.901,
			2112.907,
			2113.571,
			2114.233,
			2115.551,
			2116.207,
			2117.513,
			2119.457,
			2120.747,
			2122.031,
			2123.946,
			2124.583,
			2125.218,
			2127.76,
			2128.394,
			2130.292,
			2131.562,
			2132.829,
			2133.463,
			2134.731,
			2136.005,
			2136.643,
			2138.559,
			2139.84,
			2141.13,
			2143.075,
			2144.372,
			2146.337,
			2146.999,
			2147.659,
			2149.64,
			2150.976,
			2153.011,
			2154.365,
			2155.731,
			2156.443,
			2157.864,
			2158.573,
			2159.281,
			2162.224,
			2162.979,
			2165.244,
			2166.821,
			2168.409,
			2169.203,
			2170.795,
			2172.413,
			2173.219,
			2174.83,
			2175.633,
			2177.186,
			2178.731,
			2181.026,
			2182.508,
			2184.724,
			2185.461,
			2186.194,
			2188.382,
			2189.838,
			2191.997,
			2193.423,
			2194.85,
			2195.564,
			2196.941,
			2197.627,
			2199.002,
			2200.382,
			2201.055,
			2202.353,
			2203.656,
			2205.623,
			2206.267,
			2206.907,
			2208.839,
			2209.486,
			2210.136,
			2211.463,
			2212.139,
			2213.5,
			2214.871,
			2216.974,
			2218.403,
			2220.57,
			2221.305,
			2222.047,
			2224.296,
			2225.814,
			2228.158,
			2229.742,
			2231.344,
			2232.157,
			2233.795,
			2234.62,
			2236.279,
			2238.757,
			2240.437,
			2242.103,
			2244.581,
			2246.247,
			2248.78,
			2249.631,
			2250.485,
			2253.107,
			2254.88,
			2256.666,
			2259.372,
			2261.192,
			2263.946,
			2264.871,
			2265.799,
			2268.6,
			2269.541,
			2270.484,
			2273.336,
			2275.255,
			2277.187,
			2278.158,
			2280.111,
			2282.077,
			2283.064,
			2285.05,
			2286.048,
			2288.052,
			2290.07,
			2293.119,
			2295.167,
			2298.262,
			2299.3,
			2300.34,
			2303.478,
			2305.573,
			2308.722,
			2310.834,
			2314.023,
			2316.162,
			2317.236,
			2319.391,
			2321.557,
			2322.644,
			2323.733,
			2327.017,
			2329.219,
			2331.432,
			2332.542,
			2334.771,
			2337.009,
			2338.132,
			2340.386,
			2341.517,
			2343.785,
			2346.063,
			2349.5,
			2351.804,
			2355.279,
			2356.442,
			2357.607,
			2361.117,
			2363.47,
			2367.017,
			2369.393,
			2372.975,
			2375.374,
			2376.577,
			2378.991,
			2381.415,
			2382.63,
			2385.067,
			2387.513,
			2391.192,
			2393.654,
			2396.123,
			2398.602,
			2399.844,
			2403.586,
			2406.094,
			2408.608,
			2412.395,
			2414.93,
			2418.748,
			2420.025,
			2421.303,
			2425.151,
			2427.725,
			2431.6,
			2434.194,
			2436.794,
			2438.098,
			2440.706,
			2442.009,
			2444.62,
			2447.237,
			2448.548,
			2451.176,
			2453.811,
			2457.776,
			2459.101,
			2460.427,
			2464.417,
			2465.751,
			2467.086,
			2469.762,
			2471.102,
			2473.789,
			2477.832,
			2480.539,
			2483.253,
			2487.339,
			2488.705,
			2490.07,
			2494.174,
			2495.544,
			2496.916,
			2501.04,
			2503.798,
			2506.564,
			2507.949,
			2510.724,
			2512.114,
			2514.897,
			2519.086,
			2521.889,
			2524.698,
			2528.922,
			2531.742,
			2535.987,
			2537.406,
			2538.827,
			2543.117,
			2545.961,
			2550.23,
			2553.083,
			2557.39,
			2557.833
		],
		[
			0,
			0.004,
			0.063,
			0.081,
			0.128,
			0.189,
			0.252,
			0.298,
			0.314,
			0.375,
			0.406,
			0.437,
			0.515,
			0.56,
			0.623,
			0.685,
			0.732,
			0.81,
			0.84,
			0.871,
			0.947,
			0.995,
			1.058,
			1.119,
			1.165,
			1.182,
			1.243,
			1.275,
			1.306,
			1.383,
			1.429,
			1.49,
			1.553,
			1.601,
			1.614,
			1.678,
			1.709,
			1.738,
			1.801,
			1.822,
			1.864,
			1.925,
			1.987,
			2.048,
			2.111,
			2.143,
			2.173,
			2.236,
			2.297,
			2.358,
			2.421,
			2.485,
			2.546,
			2.575,
			2.607,
			2.685,
			2.731,
			2.797,
			2.856,
			2.902,
			2.917,
			2.981,
			3.011,
			3.042,
			3.105,
			3.165,
			3.228,
			3.291,
			3.336,
			3.415,
			3.445,
			3.474,
			3.537,
			3.554,
			3.6,
			3.663,
			3.723,
			3.785,
			3.847,
			3.909,
			3.972,
			3.986,
			4.034,
			4.096,
			4.158,
			4.203,
			4.219,
			4.28,
			4.313,
			4.343,
			4.405,
			4.467,
			4.531,
			4.593,
			4.639,
			4.653,
			4.714,
			4.745,
			4.777,
			4.839,
			4.854,
			4.902,
			4.964,
			5.026,
			5.072,
			5.088,
			5.151,
			5.179,
			5.211,
			5.289,
			5.335,
			5.397,
			5.461,
			5.506,
			5.585,
			5.614,
			5.644,
			5.706,
			5.769,
			5.831,
			5.893,
			5.955,
			6.018,
			6.05,
			6.081,
			6.157,
			6.203,
			6.264,
			6.328,
			6.375,
			6.39,
			6.452,
			6.482,
			6.514,
			6.577,
			6.591,
			6.637,
			6.701,
			6.761,
			6.809,
			6.887,
			6.918,
			6.947,
			7.01,
			7.073,
			7.135,
			7.196,
			7.244,
			7.258,
			7.32,
			7.352,
			7.383,
			7.443,
			7.461,
			7.505,
			7.568,
			7.63,
			7.693,
			7.753,
			7.785,
			7.817,
			7.892,
			7.939,
			8.008,
			8.062,
			8.111,
			8.188,
			8.219,
			8.251,
			8.311,
			8.374,
			8.437,
			8.498,
			8.545,
			8.56,
			8.622,
			8.654,
			8.685,
			8.763,
			8.808,
			8.87,
			8.933,
			8.978,
			8.995,
			9.056,
			9.086,
			9.12,
			9.18,
			9.242,
			9.303,
			9.367,
			9.412,
			9.429,
			9.492,
			9.521,
			9.551,
			9.631,
			9.678,
			9.74,
			9.802,
			9.848,
			9.863,
			9.925,
			9.955,
			9.987,
			10.047,
			10.071,
			10.111,
			10.172,
			10.235,
			10.281,
			10.358,
			10.389,
			10.42,
			10.497,
			10.545,
			10.608,
			10.669,
			10.73,
			10.794,
			10.825,
			10.853,
			10.934,
			10.978,
			11.039,
			11.103,
			11.15,
			11.163,
			11.226,
			11.257,
			11.289,
			11.351,
			11.366,
			11.413,
			11.476,
			11.535,
			11.585,
			11.66,
			11.693,
			11.723,
			11.785,
			11.847,
			11.91,
			11.971,
			12.018,
			12.032,
			12.095,
			12.131,
			12.157,
			12.218,
			12.236,
			12.279,
			12.343,
			12.406,
			12.452,
			12.468,
			12.529,
			12.559,
			12.592,
			12.669,
			12.716,
			12.777,
			12.838,
			12.887,
			12.964,
			12.994,
			13.026,
			13.103,
			13.148,
			13.212,
			13.273,
			13.321,
			13.334,
			13.396,
			13.429,
			13.46,
			13.522,
			13.536,
			13.585,
			13.644,
			13.708,
			13.769,
			13.832,
			13.863,
			13.892,
			13.954,
			14.018,
			14.08,
			14.142,
			14.203,
			14.266,
			14.298,
			14.327,
			14.404,
			14.451,
			14.513,
			14.576,
			14.621,
			14.636,
			14.7,
			14.729,
			14.761,
			14.84,
			14.887,
			14.947,
			15.008,
			15.057,
			15.134,
			15.163,
			15.196,
			15.256,
			15.318,
			15.38,
			15.445,
			15.506,
			15.568,
			15.6,
			15.628,
			15.691,
			15.706,
			15.753,
			15.814,
			15.878,
			15.924,
			15.938,
			16.003,
			16.031,
			16.063,
			16.124,
			16.142,
			16.186,
			16.254,
			16.313,
			16.374,
			16.434,
			16.466,
			16.498,
			16.575,
			16.62,
			16.685,
			16.745,
			16.793,
			16.807,
			16.87,
			16.9,
			16.931,
			17.01,
			17.056,
			17.119,
			17.178,
			17.228,
			17.242,
			17.305,
			17.335,
			17.367,
			17.428,
			17.445,
			17.491,
			17.551,
			17.614,
			17.66,
			17.737,
			17.769,
			17.801,
			17.863,
			17.924,
			17.987,
			18.046,
			18.095,
			18.11,
			18.172,
			18.204,
			18.234,
			18.317,
			18.357,
			18.422,
			18.483,
			18.543,
			18.605,
			18.638,
			18.669,
			18.73,
			18.791,
			18.854,
			18.914,
			18.963,
			18.979,
			19.04,
			19.071,
			19.103,
			19.163,
			19.18,
			19.227,
			19.288,
			19.348,
			19.411,
			19.474,
			19.505,
			19.535,
			19.615,
			19.661,
			19.722,
			19.786,
			19.829,
			19.909,
			19.938,
			19.969,
			20.034,
			20.096,
			20.158,
			20.219,
			20.264,
			20.281,
			20.342,
			20.377,
			20.403,
			20.483,
			20.528,
			20.59,
			20.651,
			20.713,
			20.776,
			20.808,
			20.838,
			20.9,
			20.917,
			20.962,
			21.026,
			21.087,
			21.134,
			21.21,
			21.243,
			21.273,
			21.335,
			21.395,
			21.458,
			21.521,
			21.567,
			21.583,
			21.646,
			21.675,
			21.705,
			21.768,
			21.784,
			21.831,
			21.893,
			21.955,
			22.001,
			22.017,
			22.078,
			22.111,
			22.139,
			22.218,
			22.263,
			22.325,
			22.389,
			22.452,
			22.513,
			22.544,
			22.575,
			22.654,
			22.697,
			22.762,
			22.823,
			22.869,
			22.885,
			22.946,
			22.978,
			23.009,
			23.087,
			23.132,
			23.196,
			23.258,
			23.302,
			23.32,
			23.382,
			23.413,
			23.442,
			23.505,
			23.566,
			23.63,
			23.692,
			23.737,
			23.754,
			23.815,
			23.846,
			23.878,
			23.939,
			23.953,
			24.001,
			24.063,
			24.124,
			24.173,
			24.187,
			24.249,
			24.279,
			24.312,
			24.372,
			24.388,
			24.436,
			24.501,
			24.56,
			24.619,
			24.681,
			24.715,
			24.744,
			24.807,
			24.87,
			24.935,
			24.993,
			25.04,
			25.056,
			25.118,
			25.147,
			25.179,
			25.239,
			25.256,
			25.303,
			25.363,
			25.427,
			25.489,
			25.551,
			25.583,
			25.611,
			25.675,
			25.69,
			25.736,
			25.801,
			25.861,
			25.923,
			25.985,
			26.017,
			26.048,
			26.109,
			26.171,
			26.234,
			26.296,
			26.356,
			26.419,
			26.448,
			26.481,
			26.545,
			26.562,
			26.606,
			26.672,
			26.729,
			26.777,
			26.791,
			26.854,
			26.883,
			26.915,
			26.978,
			27.038,
			27.102,
			27.162,
			27.21,
			27.286,
			27.318,
			27.349,
			27.41,
			27.472,
			27.537,
			27.595,
			27.644,
			27.658,
			27.722,
			27.751,
			27.785,
			27.844,
			27.862,
			27.908,
			27.969,
			28.033,
			28.078,
			28.092,
			28.154,
			28.186,
			28.218,
			28.279,
			28.341,
			28.403,
			28.465,
			28.51,
			28.589,
			28.624,
			28.652,
			28.715,
			28.729,
			28.775,
			28.838,
			28.898,
			28.945,
			28.962,
			29.023,
			29.056,
			29.084,
			29.146,
			29.163,
			29.208,
			29.271,
			29.334,
			29.381,
			29.461,
			29.487,
			29.52,
			29.581,
			29.645,
			29.707,
			29.768,
			29.814,
			29.829,
			29.893,
			29.923,
			29.954,
			30.016,
			30.029,
			30.077,
			30.139,
			30.203,
			30.247,
			30.265,
			30.325,
			30.355,
			30.386,
			30.451,
			30.465,
			30.51,
			30.575,
			30.637,
			30.687,
			30.761,
			30.791,
			30.822,
			30.885,
			30.945,
			31.009,
			31.07,
			31.131,
			31.194,
			31.225,
			31.257,
			31.318,
			31.334,
			31.38,
			31.442,
			31.504,
			31.552,
			31.567,
			31.628,
			31.659,
			31.69,
			31.768,
			31.814,
			31.877,
			31.939,
			31.985,
			32.063,
			32.092,
			32.125,
			32.202,
			32.248,
			32.315,
			32.372,
			32.418,
			32.435,
			32.495,
			32.527,
			32.558,
			32.636,
			32.681,
			32.744,
			32.807,
			32.853,
			32.869,
			32.93,
			32.962,
			32.993,
			33.055,
			33.115,
			33.18,
			33.24,
			33.302,
			33.363,
			33.394,
			33.425,
			33.489,
			33.503,
			33.551,
			33.612,
			33.675,
			33.735,
			33.8,
			33.829,
			33.861,
			33.937,
			33.984,
			34.048,
			34.109,
			34.156,
			34.233,
			34.263,
			34.296,
			34.355,
			34.418,
			34.482,
			34.542,
			34.603,
			34.668,
			34.696,
			34.729,
			34.809,
			34.854,
			34.914,
			34.976,
			35.024,
			35.038,
			35.102,
			35.13,
			35.164,
			35.224,
			35.241,
			35.286,
			35.348,
			35.41,
			35.456,
			35.536,
			35.567,
			35.596,
			35.658,
			35.721,
			35.787,
			35.846,
			35.906,
			35.968,
			36,
			36.031,
			36.093,
			36.11,
			36.156,
			36.215,
			36.279,
			36.325,
			36.341,
			36.403,
			36.434,
			36.464,
			36.528,
			36.542,
			36.588,
			36.651,
			36.714,
			36.761,
			36.836,
			36.871,
			36.9,
			36.962,
			37.024,
			37.086,
			37.146,
			37.193,
			37.209,
			37.27,
			37.301,
			37.333,
			37.396,
			37.411,
			37.458,
			37.52,
			37.582,
			37.644,
			37.706,
			37.737,
			37.766,
			37.845,
			37.891,
			37.953,
			38.014,
			38.061,
			38.14,
			38.169,
			38.201,
			38.278,
			38.324,
			38.388,
			38.449,
			38.495,
			38.51,
			38.571,
			38.602,
			38.635,
			38.697,
			38.712,
			38.76,
			38.819,
			38.881,
			38.946,
			39.008,
			39.036,
			39.069,
			39.131,
			39.193,
			39.255,
			39.314,
			39.38,
			39.442,
			39.471,
			39.503,
			39.564,
			39.581,
			39.627,
			39.688,
			39.75,
			39.814,
			39.876,
			39.906,
			39.937,
			39.998,
			40.012,
			40.062,
			40.122,
			40.185,
			40.233,
			40.31,
			40.341,
			40.37,
			40.432,
			40.494,
			40.558,
			40.62,
			40.664,
			40.682,
			40.742,
			40.774,
			40.805,
			40.867,
			40.882,
			40.929,
			40.995,
			41.053,
			41.101,
			41.115,
			41.178,
			41.207,
			41.239,
			41.301,
			41.317,
			41.362,
			41.427,
			41.486,
			41.533,
			41.61,
			41.641,
			41.673,
			41.736,
			41.797,
			41.859,
			41.921,
			41.968,
			41.984,
			42.044,
			42.077,
			42.108,
			42.169,
			42.185,
			42.23,
			42.293,
			42.356,
			42.418,
			42.478,
			42.511,
			42.54,
			42.604,
			42.663,
			42.728,
			42.788,
			42.851,
			42.913,
			42.946,
			42.977,
			43.039,
			43.059,
			43.1,
			43.161,
			43.223,
			43.27,
			43.286,
			43.346,
			43.378,
			43.41,
			43.473,
			43.487,
			43.533,
			43.596,
			43.657,
			43.703,
			43.781,
			43.812,
			43.844,
			43.906,
			43.968,
			44.035,
			44.09,
			44.137,
			44.154,
			44.217,
			44.245,
			44.277,
			44.34,
			44.354,
			44.402,
			44.463,
			44.526,
			44.587,
			44.648,
			44.679,
			44.711,
			44.775,
			44.789,
			44.836,
			44.896,
			44.959,
			45.005,
			45.085,
			45.121,
			45.145,
			45.208,
			45.269,
			45.33,
			45.394,
			45.454,
			45.518,
			45.549,
			45.581,
			45.643,
			45.658,
			45.704,
			45.767,
			45.828,
			45.891,
			45.951,
			45.982,
			46.015,
			46.077,
			46.091,
			46.138,
			46.201,
			46.261,
			46.307,
			46.387,
			46.418,
			46.448,
			46.51,
			46.573,
			46.634,
			46.695,
			46.758,
			46.82,
			46.852,
			46.881,
			46.944,
			46.959,
			47.006,
			47.07,
			47.128,
			47.192,
			47.254,
			47.286,
			47.316,
			47.394,
			47.44,
			47.506,
			47.565,
			47.61,
			47.687,
			47.718,
			47.751,
			47.826,
			47.875,
			47.936,
			47.997,
			48.043,
			48.06,
			48.121,
			48.152,
			48.184,
			48.244,
			48.262,
			48.307,
			48.37,
			48.43,
			48.478,
			48.492,
			48.556,
			48.585,
			48.618,
			48.68,
			48.742,
			48.803,
			48.865,
			48.914,
			48.989,
			49.022,
			49.053,
			49.113,
			49.13,
			49.175,
			49.237,
			49.301,
			49.347,
			49.361,
			49.423,
			49.455,
			49.485,
			49.564,
			49.609,
			49.673,
			49.734,
			49.78,
			49.857,
			49.889,
			49.919,
			49.981,
			50.045,
			50.105,
			50.168,
			50.214,
			50.229,
			50.291,
			50.323,
			50.354,
			50.416,
			50.43,
			50.478,
			50.539,
			50.601,
			50.648,
			50.662,
			50.726,
			50.758,
			50.789,
			50.866,
			50.912,
			50.975,
			51.036,
			51.084,
			51.159,
			51.192,
			51.222,
			51.284,
			51.304,
			51.347,
			51.408,
			51.47,
			51.534,
			51.596,
			51.625,
			51.657,
			51.719,
			51.734,
			51.781,
			51.842,
			51.904,
			51.951,
			51.965,
			52.029,
			52.06,
			52.092,
			52.153,
			52.168,
			52.213,
			52.278,
			52.338,
			52.386,
			52.463,
			52.492,
			52.524,
			52.587,
			52.646,
			52.71,
			52.773,
			52.819,
			52.834,
			52.895,
			52.927,
			52.959,
			53.019,
			53.036,
			53.081,
			53.145,
			53.207,
			53.251,
			53.269,
			53.329,
			53.367,
			53.392,
			53.453,
			53.469,
			53.517,
			53.578,
			53.641,
			53.685,
			53.763,
			53.796,
			53.825,
			53.905,
			53.952,
			54.014,
			54.074,
			54.12,
			54.137,
			54.196,
			54.229,
			54.26,
			54.338,
			54.385,
			54.448,
			54.508,
			54.57,
			54.634,
			54.662,
			54.694,
			54.771,
			54.819,
			54.88,
			54.942,
			54.988,
			55.005,
			55.065,
			55.098,
			55.127,
			55.206,
			55.251,
			55.316,
			55.376,
			55.428,
			55.439,
			55.501,
			55.53,
			55.562,
			55.624,
			55.639,
			55.686,
			55.749,
			55.811,
			55.856,
			55.936,
			55.966,
			55.995,
			56.074,
			56.12,
			56.184,
			56.246,
			56.292,
			56.306,
			56.369,
			56.4,
			56.431,
			56.493,
			56.507,
			56.554,
			56.618,
			56.679,
			56.725,
			56.739,
			56.804,
			56.834,
			56.866,
			56.928,
			56.942,
			56.989,
			57.051,
			57.114,
			57.16,
			57.236,
			57.269,
			57.298,
			57.361,
			57.422,
			57.49,
			57.546,
			57.593,
			57.61,
			57.671,
			57.702,
			57.732,
			57.81,
			57.856,
			57.92,
			57.982,
			58.028,
			58.104,
			58.137,
			58.167,
			58.229,
			58.29,
			58.352,
			58.413,
			58.46,
			58.539,
			58.571,
			58.6,
			58.662,
			58.679,
			58.725,
			58.786,
			58.849,
			58.895,
			58.91,
			58.973,
			59.005,
			59.035,
			59.097,
			59.111,
			59.158,
			59.221,
			59.283,
			59.329,
			59.408,
			59.437,
			59.469,
			59.53,
			59.594,
			59.656,
			59.717,
			59.78,
			59.841,
			59.872,
			59.905,
			59.964,
			59.981,
			60.026,
			60.091,
			60.151,
			60.199,
			60.213,
			60.274,
			60.306,
			60.338,
			60.399,
			60.413,
			60.463,
			60.524,
			60.587,
			60.647,
			60.708,
			60.739,
			60.773,
			60.835,
			60.896,
			60.959,
			61.018,
			61.08,
			61.142,
			61.174,
			61.205,
			61.269,
			61.284,
			61.33,
			61.392,
			61.453,
			61.514,
			61.578,
			61.614,
			61.638,
			61.715,
			61.764,
			61.825,
			61.887,
			61.935,
			62.011,
			62.044,
			62.073,
			62.136,
			62.196,
			62.259,
			62.323,
			62.369,
			62.384,
			62.446,
			62.478,
			62.508,
			62.569,
			62.586,
			62.631,
			62.693,
			62.757,
			62.803,
			62.818,
			62.881,
			62.91,
			62.942,
			63.003,
			63.02,
			63.067,
			63.129,
			63.189,
			63.237,
			63.314,
			63.346,
			63.376,
			63.438,
			63.454,
			63.5,
			63.562,
			63.625,
			63.675,
			63.687,
			63.747,
			63.778,
			63.809,
			63.886,
			63.933,
			63.996,
			64.058,
			64.105,
			64.183,
			64.214,
			64.245,
			64.306,
			64.369,
			64.429,
			64.492,
			64.555,
			64.574
		]
	],
	'7818_8071_7982': [
		[
			66.956
		],
		[
			0
		],
		[
			0,
			2.172,
			6.515,
			7.966,
			9.418,
			13.782,
			15.24,
			16.698,
			21.081,
			24.017,
			26.966,
			28.454,
			31.435,
			34.42,
			35.915,
			38.889,
			40.376,
			43.357,
			46.347,
			50.909,
			53.999,
			58.623,
			60.151,
			61.676,
			66.227,
			69.227,
			73.704,
			76.635,
			79.489,
			80.902,
			83.692,
			86.442,
			87.813,
			90.529,
			91.876,
			94.562,
			97.212,
			99.83,
			102.425,
			103.69,
			106.193,
			108.662,
			109.882,
			112.28,
			113.483,
			115.86,
			118.187,
			121.599,
			123.822,
			127.094,
			128.175,
			129.246,
			132.406,
			134.469,
			137.492,
			139.458,
			141.385,
			142.334,
			144.205,
			145.127,
			146.944,
			148.721,
			149.596,
			151.315,
			152.991,
			155.429,
			156.223,
			157.006,
			159.296,
			160.041,
			160.776,
			162.923,
			164.307,
			165.656,
			167.622,
			168.877,
			170.689,
			171.277,
			171.857,
			173.529,
			174.071,
			174.606,
			176.178,
			177.192,
			178.16,
			178.638,
			179.584,
			180.52,
			180.986,
			182.378,
			183.3,
			184.218,
			185.59,
			186.505,
			187.994,
			188.506,
			189.016,
			190.55,
			191.577,
			193.226,
			194.345,
			195.467,
			196.03,
			197.159,
			197.718,
			198.835,
			200.515,
			201.077,
			202.809,
			203.989,
			205.167,
			205.757,
			206.938,
			208.226,
			208.87,
			210.155,
			210.796,
			212.084,
			213.438,
			215.457,
			216.8,
			218.853,
			219.535,
			220.215,
			222.256,
			223.617,
			225.65,
			227.004,
			228.993,
			230.316,
			230.978,
			232.29,
			234.169,
			234.796,
			236.683,
			238.545,
			239.164,
			239.784,
			241.654,
			242.272,
			242.872,
			244.678,
			245.889,
			247.103,
			248.767,
			249.88,
			251.556,
			252.116,
			252.676,
			254.355,
			255.476,
			257.167,
			258.341,
			259.517,
			260.106,
			261.285,
			261.865,
			263.065,
			264.276,
			264.887,
			266.118,
			267.378,
			269.379,
			270.054,
			270.733,
			272.856,
			273.618,
			274.384,
			275.929,
			276.708,
			278.306,
			279.931,
			282.395,
			284.026,
			286.484,
			287.307,
			288.108,
			291.351,
			292.172,
			294.544,
			296.143,
			297.75,
			298.552,
			300.165,
			300.977,
			302.616,
			304.284,
			305.124,
			306.813,
			308.52,
			311.105,
			312.843,
			315.449,
			316.316,
			317.185,
			319.783,
			321.529,
			324.142,
			325.892,
			327.651,
			330.302,
			331.192,
			332.084,
			334.785,
			335.691,
			337.512,
			339.348,
			341.196,
			343.055,
			343.989,
			345.866,
			347.754,
			348.703,
			350.609,
			351.567,
			353.49,
			355.423,
			358.344,
			360.305,
			363.267,
			364.26,
			365.255,
			368.24,
			370.251,
			373.28,
			375.304,
			377.338,
			378.355,
			380.394,
			381.417,
			383.473,
			385.539,
			386.573,
			388.644,
			390.728,
			393.804,
			394.821,
			395.83,
			397.827,
			399.81,
			400.793,
			403.714,
			405.631,
			407.57,
			410.409,
			412.292,
			415.1,
			416.034,
			416.969,
			419.759,
			421.616,
			424.394,
			426.244,
			428.095,
			429.014,
			430.852,
			431.771,
			433.608,
			435.44,
			436.356,
			438.202,
			440.1,
			442.96,
			443.991,
			445.022,
			448.133,
			449.209,
			450.285,
			452.437,
			453.519,
			455.691,
			457.862,
			461.211,
			463.408,
			466.571,
			467.621,
			468.622,
			471.56,
			472.534,
			473.508,
			476.418,
			478.329,
			480.265,
			481.223,
			483.13,
			484.982,
			485.906,
			487.754,
			488.648,
			490.419,
			492.188,
			494.833,
			496.596,
			499.278,
			500.185,
			501.094,
			503.862,
			505.759,
			508.637,
			510.597,
			512.551,
			515.493,
			516.477,
			518.446,
			520.398,
			521.377,
			522.357,
			525.283,
			527.24,
			530.142,
			532.068,
			533.991,
			534.953,
			536.875,
			537.836,
			539.785,
			541.738,
			544.7,
			546.691,
			549.673,
			550.664,
			551.655,
			554.627,
			556.604,
			559.626,
			561.68,
			563.754,
			564.827,
			566.965,
			568.03,
			570.128,
			572.213,
			573.251,
			575.245,
			577.232,
			579.159,
			581.962,
			583.811,
			585.611,
			586.514,
			588.326,
			589.227,
			591.034,
			592.848,
			595.599,
			597.442,
			600.236,
			601.173,
			602.112,
			604.955,
			606.868,
			609.765,
			611.717,
			613.682,
			614.671,
			616.657,
			617.654,
			619.66,
			621.678,
			622.691,
			624.732,
			626.786,
			629.871,
			630.911,
			631.953,
			635.085,
			636.131,
			637.179,
			640.32,
			642.41,
			644.51,
			647.679,
			649.805,
			653.012,
			654.086,
			655.163,
			658.408,
			659.494,
			660.582,
			663.857,
			666.04,
			668.232,
			669.324,
			671.509,
			672.605,
			674.815,
			677.035,
			678.142,
			680.362,
			682.558,
			685.779,
			687.876,
			690.949,
			691.955,
			692.951,
			695.883,
			697.791,
			700.585,
			702.404,
			704.187,
			706.796,
			707.648,
			708.489,
			710.954,
			711.757,
			712.551,
			714.875,
			716.378,
			717.844,
			718.563,
			719.974,
			721.348,
			722.022,
			723.346,
			723.992,
			725.256,
			726.486,
			728.26,
			729.393,
			731.021,
			731.545,
			732.061,
			733.557,
			734.525,
			735.922,
			736.816,
			737.69,
			738.121,
			738.979,
			739.409,
			740.268,
			741.551,
			742.407,
			743.25,
			744.091,
			744.927,
			745.343,
			746.172,
			746.999,
			747.412,
			748.24,
			748.655,
			749.469,
			750.271,
			751.462,
			752.25,
			753.43,
			753.825,
			754.228,
			755.421,
			756.206,
			757.377,
			758.159,
			759.485,
			760.47,
			760.961,
			761.939,
			763.404,
			764.446,
			765.52,
			767.115,
			767.643,
			768.17,
			769.732,
			770.246,
			770.758,
			771.776,
			772.283,
			773.293,
			774.306,
			775.815,
			776.81,
			778.29,
			778.782,
			779.261,
			780.675,
			781.606,
			782.993,
			783.918,
			785.302,
			786.215,
			787.126,
			787.581,
			788.495,
			788.958,
			789.906,
			790.849,
			792.268,
			792.743,
			793.221,
			794.619,
			795.074,
			795.53,
			796.906,
			797.835,
			799.227,
			800.121,
			801.026,
			802.408,
			802.876,
			803.347,
			805.258,
			805.743,
			807.215,
			808.223,
			809.252,
			809.773,
			810.831,
			811.919,
			812.467,
			813.579,
			814.144,
			815.292,
			816.459,
			818.246,
			819.516,
			821.568,
			822.255,
			822.941,
			825.227,
			826.907,
			829.453,
			831.157,
			832.882,
			833.753,
			835.236,
			835.961,
			836.69,
			838.888,
			839.578,
			840.261,
			842.341,
			843.734,
			845.124,
			845.821,
			847.219,
			848.626,
			849.34,
			850.8,
			851.532,
			852.999,
			854.528,
			857.057,
			858.745,
			861.762,
			862.791,
			863.818,
			866.902,
			868.953,
			871.321,
			872.873,
			875.099,
			876.503,
			877.203,
			878.601,
			880.025,
			880.745,
			882.182,
			883.617,
			885.806,
			886.538,
			887.269,
			889.47,
			890.223,
			890.975,
			893.215,
			894.729,
			897.05,
			898.596,
			900.13,
			902.428,
			903.199,
			903.975,
			906.265,
			907.816,
			910.175,
			911.768,
			913.376,
			914.185,
			915.814,
			916.633,
			918.284,
			919.948,
			920.784,
			922.459,
			924.147,
			926.742,
			928.48,
			931.105,
			932.007,
			932.898,
			935.575,
			937.385,
			940.124,
			941.964,
			944.75,
			946.624,
			947.566,
			948.511,
			952.327,
			953.289,
			956.198,
			958.154,
			961.126,
			963.132,
			965.149,
			966.162,
			968.198,
			969.221,
			971.261,
			973.309,
			976.403,
			978.48,
			981.617,
			982.667,
			983.717,
			986.866,
			988.975,
			992.164,
			994.304,
			996.466,
			997.553,
			999.733,
			1000.826,
			1003.019,
			1005.219,
			1006.317,
			1008.52,
			1010.733,
			1012.954,
			1015.185,
			1016.304,
			1018.551,
			1020.808,
			1021.94,
			1024.213,
			1025.353,
			1027.643,
			1029.942,
			1033.413,
			1035.74,
			1039.254,
			1040.431,
			1041.614,
			1045.176,
			1047.569,
			1051.173,
			1053.589,
			1056.016,
			1057.233,
			1059.677,
			1060.901,
			1063.357,
			1065.824,
			1067.06,
			1069.54,
			1072.028,
			1075.778,
			1077.032,
			1078.289,
			1082.075,
			1083.342,
			1084.612,
			1087.154,
			1088.427,
			1090.979,
			1093.535,
			1097.384,
			1099.96,
			1103.837,
			1105.134,
			1106.431,
			1110.33,
			1111.629,
			1112.927,
			1116.819,
			1119.432,
			1123.306,
			1125.882,
			1127.144,
			1129.708,
			1132.265,
			1133.543,
			1136.121,
			1138.656,
			1142.535,
			1145.109,
			1149.049,
			1150.354,
			1151.687,
			1155.629,
			1158.26,
			1162.072,
			1164.506,
			1166.849,
			1170.243,
			1171.355,
			1172.458,
			1176.787,
			1177.853,
			1180.997,
			1183.049,
			1185.066,
			1186.061,
			1188.025,
			1189.954,
			1190.905,
			1192.78,
			1193.704,
			1195.529,
			1197.32,
			1199.943,
			1201.649,
			1204.141,
			1204.954,
			1205.758,
			1208.117,
			1209.646,
			1211.873,
			1213.314,
			1214.72,
			1215.411,
			1216.767,
			1217.434,
			1218.742,
			1220.644,
			1221.262,
			1223.068,
			1224.223,
			1225.346,
			1225.898,
			1226.984,
			1228.046,
			1228.569,
			1230.115,
			1231.13,
			1232.133,
			1233.622,
			1234.61,
			1236.112,
			1236.639,
			1237.166,
			1238.744,
			1239.797,
			1241.453,
			1242.653,
			1243.856,
			1244.46,
			1245.672,
			1246.339,
			1247.727,
			1249.115,
			1249.811,
			1251.198,
			1252.581,
			1254.662,
			1256.024,
			1257.69,
			1258.249,
			1258.81,
			1259.938,
			1260.507,
			1261.611,
			1262.702,
			1264.357,
			1265.463,
			1267.192,
			1267.78,
			1268.372,
			1270.185,
			1271.413,
			1273.301,
			1274.58,
			1276.54,
			1277.879,
			1278.554,
			1279.918,
			1281.313,
			1282.04,
			1283.505,
			1284.986,
			1287.299,
			1288.891,
			1291.317,
			1292.149,
			1292.984,
			1295.517,
			1297.228,
			1298.952,
			1301.549,
			1303.256,
			1305.839,
			1306.682,
			1307.516,
			1310.03,
			1310.873,
			1311.712,
			1314.226,
			1315.91,
			1317.616,
			1318.473,
			1320.192,
			1321.919,
			1322.786,
			1325.396,
			1327.126,
			1328.848,
			1331.438,
			1333.157,
			1335.771,
			1336.654,
			1337.548,
			1340.266,
			1342.096,
			1344.89,
			1346.773,
			1348.68,
			1349.638,
			1351.565,
			1352.528,
			1353.493,
			1357.384,
			1359.338,
			1361.306,
			1363.292,
			1365.291,
			1366.295,
			1368.305,
			1370.327,
			1371.343,
			1373.391,
			1374.419,
			1376.484,
			1378.604,
			1381.799,
			1383.925,
			1387.1,
			1388.132,
			1389.161,
			1392.231,
			1393.246,
			1394.256,
			1397.267,
			1399.281,
			1402.288,
			1404.307,
			1405.312,
			1407.33,
			1410.379,
			1412.408,
			1414.437,
			1417.451,
			1418.439,
			1419.426,
			1422.369,
			1423.33,
			1424.292,
			1427.179,
			1429.093,
			1431.012,
			1433.917,
			1435.867,
			1438.846,
			1439.851,
			1440.858,
			1443.933,
			1446.01,
			1449.177,
			1451.312,
			1453.472,
			1454.558,
			1456.74,
			1457.829,
			1460.011,
			1462.201,
			1463.286,
			1465.453,
			1467.62,
			1470.876,
			1471.967,
			1473.051,
			1476.316,
			1477.409,
			1478.499,
			1481.784,
			1483.997,
			1487.34,
			1489.599,
			1491.87,
			1495.323,
			1496.481,
			1497.643,
			1502.33,
			1503.5,
			1507.028,
			1509.365,
			1511.709,
			1512.88,
			1515.219,
			1517.569,
			1518.747,
			1521.11,
			1522.295,
			1524.665,
			1527.044,
			1530.616,
			1533.012,
			1536.633,
			1537.847,
			1539.066,
			1542.738,
			1545.192,
			1548.894,
			1551.377,
			1553.874,
			1555.127,
			1557.641,
			1558.898,
			1561.421,
			1563.954,
			1565.224,
			1566.496,
			1570.322,
			1572.882,
			1575.454,
			1576.743,
			1579.342,
			1581.949,
			1583.255,
			1587.182,
			1589.787,
			1592.421,
			1596.385,
			1599.033,
			1603.011,
			1604.336,
			1605.665,
			1609.661,
			1612.337,
			1616.374,
			1619.08,
			1623.162,
			1625.897,
			1627.268,
			1630.018,
			1632.777,
			1634.16,
			1636.934,
			1639.718,
			1643.911,
			1645.312,
			1646.715,
			1650.927,
			1652.334,
			1653.742,
			1657.974,
			1660.811,
			1663.656,
			1667.947,
			1670.816,
			1675.134,
			1676.576,
			1678.021,
			1682.363,
			1685.264,
			1689.628,
			1692.547,
			1696.943,
			1699.883,
			1701.355,
			1704.306,
			1708.742,
			1711.708,
			1714.682,
			1719.179,
			1720.711,
			1722.244,
			1726.823,
			1728.349,
			1729.826,
			1734.269,
			1737.257,
			1740.27,
			1744.817,
			1747.853,
			1752.45,
			1753.991,
			1755.542,
			1760.196,
			1761.746,
			1763.297,
			1767.917,
			1770.997,
			1774.084,
			1775.638,
			1778.749,
			1781.869,
			1783.43,
			1788.093,
			1791.203,
			1794.319,
			1799.005,
			1802.141,
			1806.85,
			1808.42,
			1809.99,
			1814.701,
			1817.844,
			1822.56,
			1825.707,
			1828.857,
			1830.434,
			1833.592,
			1835.172,
			1838.335,
			1841.504,
			1843.089,
			1844.676,
			1849.436,
			1852.611,
			1857.382,
			1860.584,
			1863.804,
			1865.442,
			1868.748,
			1870.387,
			1873.668,
			1876.949,
			1881.979,
			1885.312,
			1890.159,
			1891.721,
			1893.273,
			1897.853,
			1900.871,
			1905.349,
			1908.302,
			1912.671,
			1915.533,
			1916.948,
			1919.746,
			1922.496,
			1923.859,
			1926.55,
			1929.205,
			1933.106,
			1934.388,
			1935.657,
			1938.17,
			1940.644,
			1941.867,
			1944.29,
			1945.499,
			1947.893,
			1950.306,
			1953.933,
			1956.406,
			1960.014,
			1961.186,
			1962.349,
			1965.75,
			1967.953,
			1971.213,
			1973.365,
			1975.525,
			1976.609,
			1978.779,
			1979.863,
			1982.031,
			1984.202,
			1985.286,
			1987.456,
			1989.631,
			1992.893,
			1993.985,
			1995.072,
			1998.334,
			1999.426,
			2000.513,
			2003.786,
			2005.959,
			2008.158,
			2011.462,
			2013.664,
			2014.762,
			2016.942,
			2018.025,
			2019.102,
			2023.342,
			2024.376,
			2027.428,
			2029.424,
			2031.409,
			2032.394,
			2034.351,
			2036.351,
			2037.337,
			2039.349,
			2040.407,
			2042.518,
			2044.638,
			2047.825,
			2049.956,
			2053.136,
			2054.199,
			2055.26,
			2058.438,
			2060.543,
			2063.662,
			2065.741,
			2067.818,
			2068.853,
			2070.93,
			2071.97,
			2074.036,
			2077.084,
			2078.088,
			2081.039,
			2082.953,
			2084.843,
			2085.77,
			2087.604,
			2089.41,
			2090.305,
			2092.077,
			2092.956,
			2094.698,
			2096.415,
			2098.956,
			2100.625,
			2103.091,
			2103.902,
			2104.709,
			2107.09,
			2108.646,
			2110.928,
			2112.411,
			2114.583,
			2116.001,
			2116.704,
			2118.095,
			2119.474,
			2120.161,
			2121.527,
			2122.889,
			2124.932,
			2126.285,
			2128.312,
			2128.988,
			2129.665,
			2131.023,
			2131.703,
			2133.062,
			2134.419,
			2136.475,
			2137.85,
			2139.906,
			2140.593,
			2141.278,
			2143.326,
			2144.684,
			2146.703,
			2148.04,
			2149.374,
			2150.039,
			2151.364,
			2152.022,
			2153.331,
			2155.281,
			2156.581,
			2157.871,
			2159.796,
			2161.085,
			2163.012,
			2163.652,
			2164.292,
			2166.229,
			2167.528,
			2169.472,
			2170.787,
			2172.134,
			2174.154,
			2174.829,
			2175.514,
			2177.674,
			2178.396,
			2179.118,
			2181.364,
			2182.934,
			2184.504,
			2185.291,
			2187.003,
			2188.729,
			2189.592,
			2191.325,
			2192.193,
			2193.926,
			2195.65,
			2198.066,
			2199.67,
			2202.007,
			2202.769,
			2203.531,
			2205.811,
			2207.317,
			2209.568,
			2211.057,
			2212.52,
			2213.25,
			2214.706,
			2215.434,
			2216.136,
			2218.216,
			2218.909,
			2219.602,
			2221.637,
			2222.962,
			2224.289,
			2224.954,
			2226.279,
			2227.599,
			2228.26,
			2230.251,
			2231.596,
			2232.943,
			2235.006,
			2236.406,
			2238.555,
			2239.276,
			2240.001,
			2242.206,
			2243.686,
			2245.953,
			2247.479,
			2249.02,
			2249.796,
			2251.362,
			2252.151,
			2253.755,
			2255.347,
			2256.159,
			2257.806,
			2259.468,
			2261.145,
			2263.677,
			2265.368,
			2267.072,
			2267.93,
			2270.524,
			2272.271,
			2274.032,
			2276.698,
			2278.491,
			2281.207,
			2282.118,
			2283.032,
			2285.796,
			2287.655,
			2290.468,
			2292.359,
			2294.265,
			2295.222,
			2297.148,
			2298.116,
			2300.062,
			2303.007,
			2304.987,
			2306.979,
			2309.986,
			2312.005,
			2315.053,
			2316.076,
			2317.103,
			2320.202,
			2322.28,
			2324.366,
			2327.495,
			2329.583,
			2332.719,
			2333.763,
			2334.81,
			2337.961,
			2340.073,
			2343.289,
			2345.456,
			2348.744,
			2350.953,
			2353.198,
			2354.326,
			2357.74,
			2360.041,
			2362.342,
			2365.792,
			2368.088,
			2371.542,
			2372.696,
			2373.853,
			2377.336,
			2379.667,
			2383.18,
			2385.533,
			2387.898,
			2391.464,
			2392.66,
			2395.06,
			2397.467,
			2398.674,
			2399.882,
			2403.517,
			2405.949,
			2409.614,
			2412.068,
			2414.53,
			2415.765,
			2419.48,
			2421.968,
			2424.463,
			2428.219,
			2430.732,
			2434.505,
			2435.765,
			2437.026,
			2440.823,
			2443.372,
			2447.22,
			2449.798,
			2452.383,
			2453.677,
			2456.272,
			2457.573,
			2460.177,
			2462.78,
			2464.084,
			2465.39,
			2469.32,
			2473.268,
			2474.588,
			2475.91,
			2478.558,
			2481.211,
			2482.541,
			2485.203,
			2486.536,
			2489.206,
			2491.882,
			2495.908,
			2498.601,
			2502.653,
			2504.007,
			2505.362,
			2509.438,
			2512.164,
			2516.265,
			2519.008,
			2523.135,
			2525.893,
			2527.275,
			2530.043,
			2532.817,
			2534.206,
			2536.989,
			2539.781,
			2543.98,
			2546.787,
			2551.007,
			2552.416,
			2553.825,
			2556.647,
			2558.061,
			2560.896,
			2565.161,
			2568.011,
			2570.869,
			2574.806
		],
		[
			0,
			0.037,
			0.115,
			0.145,
			0.175,
			0.237,
			0.254,
			0.299,
			0.362,
			0.425,
			0.471,
			0.486,
			0.547,
			0.579,
			0.609,
			0.672,
			0.686,
			0.733,
			0.796,
			0.857,
			0.905,
			0.98,
			1.011,
			1.043,
			1.12,
			1.168,
			1.23,
			1.291,
			1.339,
			1.353,
			1.416,
			1.452,
			1.477,
			1.539,
			1.556,
			1.602,
			1.663,
			1.725,
			1.773,
			1.787,
			1.848,
			1.88,
			1.911,
			1.974,
			1.988,
			2.036,
			2.099,
			2.158,
			2.221,
			2.282,
			2.314,
			2.346,
			2.423,
			2.469,
			2.532,
			2.595,
			2.641,
			2.657,
			2.718,
			2.749,
			2.779,
			2.842,
			2.858,
			2.905,
			2.967,
			3.028,
			3.074,
			3.09,
			3.153,
			3.184,
			3.214,
			3.276,
			3.339,
			3.399,
			3.463,
			3.523,
			3.586,
			3.617,
			3.649,
			3.71,
			3.726,
			3.771,
			3.835,
			3.896,
			3.943,
			3.958,
			4.02,
			4.052,
			4.082,
			4.16,
			4.206,
			4.269,
			4.329,
			4.376,
			4.453,
			4.485,
			4.514,
			4.579,
			4.641,
			4.702,
			4.765,
			4.809,
			4.825,
			4.889,
			4.919,
			4.951,
			5.028,
			5.073,
			5.136,
			5.198,
			5.246,
			5.262,
			5.323,
			5.354,
			5.384,
			5.447,
			5.463,
			5.51,
			5.574,
			5.632,
			5.678,
			5.757,
			5.789,
			5.82,
			5.896,
			5.943,
			6.006,
			6.065,
			6.129,
			6.192,
			6.221,
			6.253,
			6.329,
			6.375,
			6.438,
			6.502,
			6.548,
			6.562,
			6.626,
			6.655,
			6.688,
			6.765,
			6.812,
			6.872,
			6.935,
			6.982,
			7.058,
			7.088,
			7.121,
			7.199,
			7.244,
			7.308,
			7.37,
			7.414,
			7.43,
			7.494,
			7.525,
			7.555,
			7.615,
			7.637,
			7.68,
			7.742,
			7.803,
			7.847,
			7.865,
			7.925,
			7.957,
			7.988,
			8.049,
			8.067,
			8.113,
			8.174,
			8.236,
			8.282,
			8.362,
			8.391,
			8.422,
			8.5,
			8.548,
			8.609,
			8.672,
			8.718,
			8.734,
			8.794,
			8.825,
			8.858,
			8.92,
			8.934,
			8.981,
			9.043,
			9.105,
			9.153,
			9.229,
			9.259,
			9.291,
			9.353,
			9.415,
			9.476,
			9.539,
			9.599,
			9.664,
			9.697,
			9.725,
			9.787,
			9.803,
			9.85,
			9.911,
			9.972,
			10.02,
			10.035,
			10.097,
			10.128,
			10.159,
			10.222,
			10.237,
			10.281,
			10.344,
			10.407,
			10.453,
			10.532,
			10.563,
			10.594,
			10.67,
			10.715,
			10.778,
			10.84,
			10.888,
			10.902,
			10.965,
			10.998,
			11.028,
			11.089,
			11.106,
			11.151,
			11.215,
			11.276,
			11.322,
			11.339,
			11.399,
			11.429,
			11.461,
			11.54,
			11.586,
			11.648,
			11.71,
			11.771,
			11.834,
			11.864,
			11.897,
			11.956,
			12.02,
			12.081,
			12.144,
			12.191,
			12.205,
			12.268,
			12.299,
			12.329,
			12.39,
			12.407,
			12.453,
			12.516,
			12.579,
			12.623,
			12.64,
			12.703,
			12.732,
			12.762,
			12.824,
			12.84,
			12.887,
			12.948,
			13.011,
			13.062,
			13.135,
			13.164,
			13.199,
			13.259,
			13.275,
			13.321,
			13.382,
			13.444,
			13.493,
			13.507,
			13.568,
			13.601,
			13.631,
			13.693,
			13.708,
			13.757,
			13.819,
			13.879,
			13.927,
			14.004,
			14.036,
			14.067,
			14.144,
			14.188,
			14.253,
			14.314,
			14.361,
			14.438,
			14.469,
			14.501,
			14.561,
			14.578,
			14.624,
			14.685,
			14.748,
			14.811,
			14.872,
			14.903,
			14.934,
			14.997,
			15.012,
			15.058,
			15.12,
			15.182,
			15.228,
			15.307,
			15.338,
			15.368,
			15.43,
			15.493,
			15.555,
			15.614,
			15.662,
			15.678,
			15.74,
			15.772,
			15.802,
			15.863,
			15.886,
			15.926,
			15.987,
			16.049,
			16.111,
			16.173,
			16.205,
			16.237,
			16.296,
			16.314,
			16.361,
			16.422,
			16.485,
			16.532,
			16.609,
			16.641,
			16.672,
			16.734,
			16.794,
			16.855,
			16.919,
			16.965,
			16.982,
			17.042,
			17.075,
			17.104,
			17.167,
			17.18,
			17.227,
			17.292,
			17.353,
			17.398,
			17.416,
			17.476,
			17.508,
			17.538,
			17.615,
			17.663,
			17.724,
			17.787,
			17.849,
			17.911,
			17.945,
			17.971,
			18.035,
			18.051,
			18.096,
			18.164,
			18.221,
			18.268,
			18.281,
			18.344,
			18.374,
			18.408,
			18.47,
			18.482,
			18.53,
			18.593,
			18.656,
			18.701,
			18.778,
			18.81,
			18.842,
			18.903,
			18.965,
			19.025,
			19.089,
			19.135,
			19.214,
			19.244,
			19.274,
			19.335,
			19.353,
			19.397,
			19.459,
			19.523,
			19.569,
			19.586,
			19.648,
			19.679,
			19.708,
			19.772,
			19.788,
			19.832,
			19.895,
			19.957,
			20.02,
			20.082,
			20.114,
			20.143,
			20.206,
			20.268,
			20.329,
			20.391,
			20.437,
			20.453,
			20.515,
			20.548,
			20.576,
			20.655,
			20.702,
			20.762,
			20.825,
			20.872,
			20.886,
			20.947,
			20.981,
			21.01,
			21.073,
			21.09,
			21.135,
			21.197,
			21.258,
			21.305,
			21.383,
			21.414,
			21.446,
			21.523,
			21.568,
			21.631,
			21.694,
			21.755,
			21.818,
			21.847,
			21.879,
			21.957,
			22.003,
			22.071,
			22.126,
			22.172,
			22.19,
			22.251,
			22.283,
			22.312,
			22.374,
			22.392,
			22.44,
			22.498,
			22.563,
			22.624,
			22.687,
			22.716,
			22.747,
			22.808,
			22.872,
			22.935,
			22.995,
			23.059,
			23.12,
			23.15,
			23.182,
			23.244,
			23.259,
			23.306,
			23.369,
			23.429,
			23.475,
			23.491,
			23.553,
			23.586,
			23.614,
			23.678,
			23.738,
			23.806,
			23.864,
			23.91,
			23.986,
			24.019,
			24.049,
			24.132,
			24.175,
			24.237,
			24.296,
			24.344,
			24.359,
			24.421,
			24.453,
			24.485,
			24.545,
			24.56,
			24.608,
			24.669,
			24.73,
			24.779,
			24.854,
			24.888,
			24.918,
			24.981,
			25.041,
			25.103,
			25.166,
			25.211,
			25.228,
			25.291,
			25.321,
			25.349,
			25.415,
			25.429,
			25.475,
			25.536,
			25.599,
			25.646,
			25.661,
			25.723,
			25.754,
			25.786,
			25.848,
			25.863,
			25.909,
			25.973,
			26.034,
			26.079,
			26.158,
			26.191,
			26.221,
			26.28,
			26.345,
			26.406,
			26.468,
			26.53,
			26.59,
			26.624,
			26.653,
			26.714,
			26.732,
			26.778,
			26.841,
			26.902,
			26.947,
			26.964,
			27.026,
			27.058,
			27.087,
			27.151,
			27.211,
			27.278,
			27.337,
			27.397,
			27.461,
			27.49,
			27.521,
			27.582,
			27.644,
			27.708,
			27.769,
			27.818,
			27.832,
			27.895,
			27.925,
			27.955,
			28.019,
			28.035,
			28.081,
			28.141,
			28.205,
			28.265,
			28.328,
			28.359,
			28.388,
			28.453,
			28.515,
			28.576,
			28.639,
			28.701,
			28.762,
			28.793,
			28.824,
			28.903,
			28.948,
			29.014,
			29.073,
			29.135,
			29.197,
			29.226,
			29.257,
			29.32,
			29.336,
			29.382,
			29.443,
			29.505,
			29.553,
			29.629,
			29.661,
			29.693,
			29.755,
			29.814,
			29.878,
			29.939,
			29.985,
			30.003,
			30.064,
			30.096,
			30.126,
			30.187,
			30.205,
			30.251,
			30.314,
			30.373,
			30.42,
			30.436,
			30.496,
			30.53,
			30.561,
			30.622,
			30.638,
			30.686,
			30.746,
			30.809,
			30.855,
			30.932,
			30.963,
			30.995,
			31.057,
			31.119,
			31.181,
			31.242,
			31.288,
			31.303,
			31.367,
			31.397,
			31.428,
			31.491,
			31.506,
			31.552,
			31.614,
			31.678,
			31.723,
			31.739,
			31.801,
			31.832,
			31.864,
			31.925,
			31.941,
			31.986,
			32.048,
			32.112,
			32.157,
			32.236,
			32.265,
			32.298,
			32.359,
			32.378,
			32.422,
			32.483,
			32.546,
			32.605,
			32.669,
			32.699,
			32.729,
			32.793,
			32.807,
			32.855,
			32.918,
			32.978,
			33.025,
			33.102,
			33.134,
			33.165,
			33.225,
			33.287,
			33.351,
			33.411,
			33.459,
			33.537,
			33.568,
			33.6,
			33.676,
			33.721,
			33.786,
			33.846,
			33.895,
			33.909,
			33.972,
			34.003,
			34.033,
			34.094,
			34.112,
			34.157,
			34.22,
			34.28,
			34.328,
			34.405,
			34.441,
			34.467,
			34.53,
			34.59,
			34.653,
			34.714,
			34.761,
			34.776,
			34.839,
			34.87,
			34.901,
			34.98,
			35.025,
			35.088,
			35.147,
			35.194,
			35.212,
			35.273,
			35.303,
			35.336,
			35.411,
			35.458,
			35.522,
			35.583,
			35.629,
			35.708,
			35.739,
			35.77,
			35.831,
			35.893,
			35.956,
			36.018,
			36.064,
			36.079,
			36.141,
			36.172,
			36.202,
			36.265,
			36.282,
			36.328,
			36.391,
			36.453,
			36.503,
			36.576,
			36.608,
			36.638,
			36.699,
			36.715,
			36.762,
			36.824,
			36.886,
			36.93,
			37.009,
			37.042,
			37.07,
			37.134,
			37.196,
			37.258,
			37.321,
			37.382,
			37.443,
			37.475,
			37.505,
			37.568,
			37.582,
			37.63,
			37.692,
			37.753,
			37.801,
			37.878,
			37.909,
			37.94,
			38.003,
			38.065,
			38.125,
			38.188,
			38.235,
			38.312,
			38.344,
			38.372,
			38.436,
			38.452,
			38.497,
			38.564,
			38.621,
			38.669,
			38.684,
			38.746,
			38.775,
			38.806,
			38.885,
			38.93,
			38.995,
			39.056,
			39.102,
			39.178,
			39.209,
			39.243,
			39.303,
			39.367,
			39.428,
			39.49,
			39.536,
			39.553,
			39.614,
			39.645,
			39.675,
			39.753,
			39.801,
			39.864,
			39.923,
			39.97,
			39.987,
			40.049,
			40.078,
			40.109,
			40.173,
			40.188,
			40.235,
			40.295,
			40.358,
			40.403,
			40.481,
			40.512,
			40.545,
			40.607,
			40.627,
			40.669,
			40.73,
			40.791,
			40.855,
			40.914,
			40.946,
			40.979,
			41.056,
			41.102,
			41.164,
			41.226,
			41.271,
			41.288,
			41.352,
			41.381,
			41.411,
			41.475,
			41.537,
			41.599,
			41.661,
			41.721,
			41.786,
			41.814,
			41.847,
			41.909,
			41.971,
			42.031,
			42.093,
			42.142,
			42.156,
			42.22,
			42.248,
			42.28,
			42.343,
			42.358,
			42.403,
			42.468,
			42.528,
			42.575,
			42.591,
			42.653,
			42.688,
			42.715,
			42.793,
			42.837,
			42.901,
			42.964,
			43.009,
			43.085,
			43.119,
			43.147,
			43.226,
			43.272,
			43.336,
			43.396,
			43.444,
			43.458,
			43.52,
			43.553,
			43.581,
			43.644,
			43.659,
			43.706,
			43.769,
			43.829,
			43.878,
			43.955,
			43.985,
			44.014,
			44.078,
			44.139,
			44.203,
			44.265,
			44.311,
			44.328,
			44.388,
			44.421,
			44.451,
			44.513,
			44.528,
			44.575,
			44.637,
			44.696,
			44.747,
			44.761,
			44.822,
			44.855,
			44.885,
			44.963,
			45.009,
			45.071,
			45.132,
			45.179,
			45.256,
			45.288,
			45.319,
			45.382,
			45.444,
			45.506,
			45.567,
			45.63,
			45.692,
			45.722,
			45.753,
			45.815,
			45.832,
			45.878,
			45.938,
			46.002,
			46.048,
			46.064,
			46.126,
			46.158,
			46.188,
			46.264,
			46.311,
			46.372,
			46.436,
			46.482,
			46.558,
			46.589,
			46.621,
			46.699,
			46.745,
			46.811,
			46.869,
			46.932,
			46.994,
			47.023,
			47.054,
			47.132,
			47.18,
			47.241,
			47.303,
			47.348,
			47.366,
			47.428,
			47.457,
			47.488,
			47.551,
			47.614,
			47.675,
			47.736,
			47.785,
			47.86,
			47.891,
			47.924,
			47.986,
			48.001,
			48.048,
			48.109,
			48.172,
			48.219,
			48.235,
			48.296,
			48.326,
			48.357,
			48.435,
			48.482,
			48.545,
			48.606,
			48.652,
			48.73,
			48.762,
			48.792,
			48.853,
			48.915,
			48.978,
			49.041,
			49.086,
			49.102,
			49.165,
			49.193,
			49.227,
			49.287,
			49.303,
			49.348,
			49.413,
			49.473,
			49.535,
			49.599,
			49.629,
			49.659,
			49.723,
			49.736,
			49.783,
			49.847,
			49.909,
			49.955,
			50.031,
			50.062,
			50.094,
			50.155,
			50.219,
			50.279,
			50.341,
			50.405,
			50.467,
			50.496,
			50.528,
			50.589,
			50.605,
			50.652,
			50.713,
			50.777,
			50.823,
			50.839,
			50.899,
			50.935,
			50.961,
			51.022,
			51.039,
			51.086,
			51.147,
			51.209,
			51.256,
			51.335,
			51.364,
			51.397,
			51.475,
			51.521,
			51.58,
			51.644,
			51.691,
			51.705,
			51.769,
			51.798,
			51.829,
			51.891,
			51.909,
			51.955,
			52.015,
			52.078,
			52.123,
			52.139,
			52.203,
			52.234,
			52.265,
			52.326,
			52.389,
			52.449,
			52.513,
			52.561,
			52.575,
			52.637,
			52.668,
			52.699,
			52.775,
			52.823,
			52.885,
			52.946,
			52.997,
			53.008,
			53.069,
			53.102,
			53.13,
			53.194,
			53.208,
			53.255,
			53.319,
			53.38,
			53.426,
			53.505,
			53.536,
			53.567,
			53.629,
			53.691,
			53.754,
			53.814,
			53.862,
			53.877,
			53.939,
			53.969,
			54.001,
			54.078,
			54.125,
			54.187,
			54.249,
			54.295,
			54.309,
			54.373,
			54.403,
			54.436,
			54.497,
			54.511,
			54.558,
			54.623,
			54.684,
			54.729,
			54.806,
			54.836,
			54.868,
			54.93,
			54.993,
			55.059,
			55.114,
			55.18,
			55.241,
			55.271,
			55.302,
			55.364,
			55.381,
			55.428,
			55.49,
			55.551,
			55.612,
			55.676,
			55.705,
			55.737,
			55.799,
			55.815,
			55.861,
			55.924,
			55.985,
			56.032,
			56.108,
			56.139,
			56.17,
			56.233,
			56.293,
			56.356,
			56.419,
			56.467,
			56.48,
			56.543,
			56.575,
			56.605,
			56.682,
			56.728,
			56.79,
			56.853,
			56.901,
			56.978,
			57.009,
			57.038,
			57.102,
			57.164,
			57.226,
			57.287,
			57.334,
			57.411,
			57.441,
			57.473,
			57.535,
			57.55,
			57.597,
			57.659,
			57.72,
			57.766,
			57.784,
			57.844,
			57.877,
			57.908,
			57.969,
			57.984,
			58.031,
			58.093,
			58.155,
			58.216,
			58.28,
			58.311,
			58.341,
			58.402,
			58.464,
			58.528,
			58.59,
			58.635,
			58.652,
			58.714,
			58.744,
			58.775,
			58.837,
			58.853,
			58.899,
			58.962,
			59.023,
			59.071,
			59.085,
			59.148,
			59.182,
			59.209,
			59.288,
			59.332,
			59.395,
			59.457,
			59.521,
			59.58,
			59.613,
			59.644,
			59.706,
			59.767,
			59.83,
			59.893,
			59.939,
			59.953,
			60.015,
			60.046,
			60.078,
			60.14,
			60.154,
			60.201,
			60.263,
			60.324,
			60.388,
			60.449,
			60.48,
			60.512,
			60.589,
			60.635,
			60.696,
			60.759,
			60.822,
			60.882,
			60.914,
			60.944,
			61.007,
			61.069,
			61.131,
			61.194,
			61.244,
			61.255,
			61.318,
			61.347,
			61.379,
			61.458,
			61.505,
			61.564,
			61.629,
			61.675,
			61.753,
			61.784,
			61.814,
			61.89,
			61.938,
			61.999,
			62.063,
			62.108,
			62.186,
			62.218,
			62.247,
			62.311,
			62.372,
			62.436,
			62.495,
			62.558,
			62.62,
			62.651,
			62.683,
			62.759,
			62.806,
			62.87,
			62.931,
			62.978,
			63.053,
			63.086,
			63.115,
			63.178,
			63.239,
			63.306,
			63.365,
			63.411,
			63.488,
			63.519,
			63.551,
			63.613,
			63.629,
			63.675,
			63.735,
			63.797,
			63.861,
			63.922,
			63.953,
			63.982,
			64.061,
			64.108,
			64.17,
			64.23,
			64.294,
			64.355,
			64.388,
			64.418,
			64.479,
			64.541,
			64.606,
			64.667,
			64.712,
			64.729,
			64.789,
			64.821,
			64.852,
			64.914,
			64.93,
			64.975,
			65.039,
			65.101,
			65.146,
			65.164,
			65.223,
			65.255,
			65.287,
			65.347,
			65.369,
			65.411,
			65.473,
			65.535,
			65.58,
			65.658,
			65.69,
			65.721,
			65.781,
			65.843,
			65.908,
			65.969,
			66.029,
			66.093,
			66.124,
			66.155,
			66.214,
			66.232,
			66.279,
			66.342,
			66.403,
			66.465,
			66.528,
			66.558,
			66.587,
			66.649,
			66.668,
			66.714,
			66.776,
			66.835,
			66.896,
			66.956
		]
	],
	'7818_8095_7982': [
		[
			47.589
		],
		[
			0
		],
		[
			0,
			2.735,
			4.969,
			8.247,
			10.384,
			12.489,
			13.534,
			15.596,
			16.613,
			18.624,
			20.598,
			21.572,
			22.534,
			25.352,
			27.19,
			29.886,
			31.641,
			33.362,
			34.21,
			35.895,
			36.72,
			38.348,
			39.952,
			42.327,
			43.891,
			46.231,
			47.012,
			47.796,
			50.151,
			51.724,
			54.084,
			55.657,
			57.232,
			58.019,
			59.592,
			60.378,
			61.952,
			63.546,
			64.345,
			65.942,
			67.552,
			70.049,
			70.881,
			71.712,
			74.239,
			75.084,
			75.928,
			78.434,
			80.083,
			81.73,
			84.187,
			85.822,
			88.293,
			89.126,
			89.96,
			92.474,
			93.34,
			94.206,
			96.819,
			98.607,
			100.42,
			101.33,
			103.155,
			104.986,
			105.907,
			107.754,
			108.673,
			110.52,
			112.381,
			115.198,
			117.093,
			119.96,
			120.922,
			121.887,
			124.801,
			126.757,
			129.712,
			131.693,
			133.684,
			134.682,
			136.684,
			137.689,
			138.693,
			142.732,
			143.734,
			146.753,
			148.74,
			151.733,
			153.718,
			155.679,
			156.651,
			158.573,
			159.521,
			161.407,
			163.242,
			165.922,
			167.677,
			170.272,
			171.124,
			171.973,
			174.492,
			176.158,
			178.637,
			180.263,
			181.883,
			182.692,
			184.353,
			185.186,
			186.849,
			188.584,
			189.522,
			191.401,
			193.307,
			196.327,
			197.34,
			198.341,
			200.297,
			202.256,
			203.223,
			206.019,
			207.889,
			209.733,
			212.51,
			214.309,
			216.964,
			217.85,
			218.692,
			221.164,
			222.766,
			225.069,
			226.569,
			228.042,
			228.841,
			230.451,
			231.26,
			232.881,
			234.802,
			235.776,
			237.729,
			239.743,
			242.791,
			243.795,
			244.792,
			247.782,
			248.696,
			249.556,
			252.128,
			253.768,
			255.332,
			257.646,
			259.185,
			261.485,
			262.248,
			263.01,
			265.309,
			266.074,
			266.839,
			269.147,
			270.705,
			272.269,
			273.053,
			274.643,
			275.443,
			277.05,
			278.672,
			279.49,
			281.135,
			282.79,
			285.284,
			286.117,
			286.95,
			289.453,
			290.288,
			291.121,
			293.592,
			295.233,
			297.64,
			299.206,
			300.738,
			301.493,
			302.978,
			303.708,
			304.426,
			306.532,
			307.218,
			307.897,
			309.883,
			311.167,
			312.418,
			313.031,
			314.231,
			315.397,
			315.966,
			317.078,
			317.622,
			318.693,
			319.722,
			321.195,
			322.134,
			323.48,
			323.913,
			324.336,
			325.552,
			326.326,
			327.443,
			328.155,
			329.168,
			329.807,
			330.12,
			330.733,
			331.635,
			332.23,
			332.821,
			333.706,
			333.982,
			334.256,
			334.803,
			335.349,
			335.621,
			336.168,
			336.442,
			336.992,
			337.545,
			338.381,
			339.02,
			340.154,
			340.531,
			340.908,
			342.037,
			342.79,
			344.1,
			345.693,
			348.062,
			349.758,
			350.65,
			352.423,
			354.05,
			354.617,
			355.745,
			356.861,
			358.521,
			359.263,
			360.188,
			360.494,
			360.801,
			361.413,
			361.719,
			362.332,
			362.948,
			363.877,
			364.475,
			365.38,
			365.683,
			365.988,
			366.909,
			367.53,
			368.475,
			369.133,
			369.819,
			370.166,
			370.866,
			371.576,
			371.935,
			372.661,
			373.029,
			373.775,
			374.554,
			375.752,
			376.568,
			377.825,
			378.252,
			378.685,
			380.036,
			380.962,
			382.387,
			383.362,
			384.361,
			385.903,
			386.426,
			386.954,
			388.567,
			389.667,
			391.352,
			392.5,
			393.655,
			394.244,
			395.436,
			396.647,
			397.258,
			399.117,
			400.372,
			401.625,
			403.518,
			404.797,
			406.748,
			407.407,
			408.069,
			410.077,
			411.436,
			413.501,
			414.896,
			416.306,
			417.016,
			418.449,
			419.171,
			420.623,
			422.091,
			422.831,
			424.322,
			425.826,
			427.346,
			428.881,
			429.653,
			431.206,
			432.774,
			433.563,
			435.149,
			435.947,
			437.552,
			439.171,
			441.617,
			443.264,
			445.762,
			446.597,
			447.431,
			449.94,
			451.631,
			454.189,
			455.907,
			457.65,
			458.519,
			460.266,
			461.143,
			462.906,
			464.684,
			465.584,
			467.391,
			469.211,
			471.993,
			473.864,
			475.775,
			477.709,
			478.681,
			481.658,
			483.668,
			486.675,
			488.686,
			490.69,
			493.697,
			494.707,
			495.729,
			498.811,
			500.898,
			504.062,
			506.213,
			508.381,
			509.467,
			511.662,
			512.762,
			514.96,
			517.138,
			518.228,
			520.411,
			522.599,
			525.899,
			527.004,
			528.109,
			531.41,
			532.505,
			533.599,
			536.853,
			539.009,
			542.214,
			544.351,
			546.464,
			549.665,
			550.728,
			551.79,
			556.016,
			557.061,
			560.155,
			562.194,
			564.178,
			565.156,
			567.068,
			568.964,
			569.917,
			571.765,
			572.683,
			574.51,
			576.327,
			579.051,
			580.882,
			583.646,
			584.567,
			585.507,
			588.361,
			590.275,
			593.233,
			595.207,
			597.196,
			598.19,
			600.172,
			601.141,
			603.077,
			605.011,
			605.986,
			606.961,
			609.881,
			611.878,
			614.868,
			616.836,
			618.795,
			619.771,
			621.668,
			622.609,
			624.485,
			626.321,
			629.037,
			630.818,
			633.431,
			634.298,
			635.162,
			637.657,
			639.325,
			641.824,
			643.479,
			645.142,
			645.989,
			647.69,
			649.401,
			650.261,
			652.891,
			654.657,
			656.45,
			659.172,
			660.086,
			661.037,
			662.955,
			664.883,
			665.894,
			667.97,
			669.012,
			671.12,
			673.257,
			676.416,
			678.466,
			681.467,
			682.44,
			683.417,
			686.36,
			688.282,
			691.205,
			693.157,
			695.117,
			696.095,
			698.053,
			699.034,
			700.994,
			702.951,
			703.926,
			705.883,
			707.864,
			710.843,
			711.876,
			712.907,
			716.018,
			717.074,
			718.124,
			721.24,
			723.275,
			726.265,
			728.183,
			730.125,
			732.991,
			733.965,
			734.923,
			737.825,
			738.795,
			739.764,
			742.669,
			744.602,
			746.521,
			747.473,
			749.376,
			751.275,
			752.22,
			754.111,
			755.056,
			756.948,
			758.843,
			761.676,
			763.554,
			766.367,
			767.295,
			768.225,
			771.024,
			772.891,
			775.714,
			777.614,
			780.489,
			782.425,
			783.398,
			784.375,
			788.292,
			790.278,
			792.274,
			794.282,
			797.322,
			799.369,
			801.437,
			802.49,
			804.606,
			805.667,
			807.802,
			809.943,
			813.12,
			815.233,
			818.401,
			819.454,
			820.503,
			823.632,
			825.693,
			828.734,
			830.718,
			833.63,
			835.528,
			836.463,
			838.302,
			841.001,
			842.75,
			844.462,
			846.965,
			848.592,
			850.971,
			851.753,
			852.531,
			854.076,
			854.845,
			856.378,
			857.904,
			860.203,
			861.726,
			863.999,
			864.758,
			865.519,
			867.785,
			869.291,
			871.564,
			873.091,
			875.389,
			876.937,
			877.722,
			879.293,
			880.868,
			881.659,
			883.252,
			884.847,
			887.255,
			888.067,
			888.879,
			891.319,
			892.148,
			892.982,
			895.492,
			897.191,
			898.929,
			901.55,
			903.33,
			906.016,
			906.914,
			907.811,
			910.508,
			911.41,
			912.34,
			915.148,
			917.055,
			919.049,
			920.046,
			922.06,
			924.124,
			925.157,
			927.232,
			928.278,
			930.369,
			932.402,
			935.334,
			937.225,
			939.874,
			940.757,
			941.64,
			944.23,
			945.957,
			948.571,
			950.32,
			952.076,
			954.729,
			955.615,
			957.394,
			960.078,
			960.977,
			963.692,
			965.516,
			967.354,
			968.262,
			970.118,
			971.985,
			972.921,
			974.802,
			975.747,
			977.646,
			979.556,
			982.433,
			984.368,
			987.229,
			988.176,
			989.117,
			991.891,
			993.713,
			996.382,
			998.109,
			999.796,
			1000.628,
			1002.271,
			1003.081,
			1004.689,
			1007.083,
			1008.692,
			1010.315,
			1011.926,
			1013.618,
			1014.468,
			1016.169,
			1017.905,
			1018.794,
			1021.46,
			1023.198,
			1024.915,
			1027.49,
			1029.18,
			1031.728,
			1032.577,
			1033.424,
			1035.973,
			1037.675,
			1040.229,
			1041.942,
			1043.686,
			1044.564,
			1046.331,
			1047.219,
			1049.007,
			1050.825,
			1051.739,
			1053.561,
			1055.39,
			1058.156,
			1059.08,
			1060.007,
			1062.819,
			1063.74,
			1064.676,
			1067.476,
			1069.32,
			1071.142,
			1073.816,
			1075.552,
			1078.105,
			1078.931,
			1079.749,
			1082.922,
			1083.704,
			1086.009,
			1087.488,
			1089.654,
			1091.051,
			1091.737,
			1093.065,
			1094.326,
			1094.96,
			1096.188,
			1097.391,
			1099.105,
			1100.223,
			1101.881,
			1102.431,
			1102.99,
			1104.682,
			1105.812,
			1107.514,
			1108.708,
			1109.913,
			1110.517,
			1111.728,
			1112.334,
			1112.942,
			1114.764,
			1115.372,
			1115.98,
			1117.808,
			1119.044,
			1120.278,
			1120.894,
			1122.126,
			1123.375,
			1124.007,
			1125.267,
			1125.896,
			1127.154,
			1128.432,
			1130.366,
			1131.65,
			1133.57,
			1134.207,
			1134.842,
			1136.741,
			1138,
			1139.853,
			1141.083,
			1142.312,
			1142.927,
			1144.153,
			1144.766,
			1145.99,
			1147.211,
			1147.822,
			1149.09,
			1150.36,
			1151.634,
			1152.915,
			1153.599,
			1154.97,
			1156.346,
			1157.036,
			1158.428,
			1159.132,
			1160.545,
			1161.967,
			1164.085,
			1165.489,
			1167.612,
			1168.327,
			1169.047,
			1171.225,
			1172.694,
			1174.968,
			1176.513,
			1178.086,
			1178.892,
			1180.522,
			1181.349,
			1183.028,
			1184.745,
			1185.611,
			1187.361,
			1189.118,
			1191.772,
			1193.544,
			1196.119,
			1196.987,
			1197.859,
			1199.566,
			1200.42,
			1202.14,
			1203.881,
			1206.517,
			1208.279,
			1210.952,
			1211.85,
			1212.751,
			1215.459,
			1217.28,
			1220.014,
			1221.846,
			1223.689,
			1224.609,
			1226.461,
			1227.392,
			1229.265,
			1231.149,
			1232.095,
			1233.998,
			1235.912,
			1238.804,
			1240.747,
			1243.685,
			1244.67,
			1245.657,
			1248.64,
			1250.642,
			1253.667,
			1255.69,
			1257.722,
			1260.776,
			1261.795,
			1262.815,
			1266.873,
			1267.884,
			1270.889,
			1272.882,
			1274.875,
			1275.873,
			1277.875,
			1279.893,
			1280.906,
			1282.939,
			1283.964,
			1286.03,
			1288.103,
			1291.204,
			1293.27,
			1296.348,
			1297.373,
			1298.399,
			1301.493,
			1303.559,
			1306.706,
			1308.805,
			1310.916,
			1311.97,
			1314.075,
			1315.126,
			1317.222,
			1319.324,
			1320.385,
			1322.506,
			1324.636,
			1327.861,
			1328.939,
			1330.021,
			1332.185,
			1334.345,
			1335.425,
			1337.577,
			1338.65,
			1340.757,
			1342.879,
			1346.048,
			1348.166,
			1351.349,
			1352.415,
			1353.485,
			1356.695,
			1358.845,
			1362.085,
			1364.254,
			1366.431,
			1367.522,
			1369.711,
			1370.808,
			1373.007,
			1375.214,
			1376.321,
			1378.538,
			1380.766,
			1384.141,
			1386.392,
			1389.783,
			1390.921,
			1392.059,
			1395.487,
			1397.779,
			1400.071,
			1403.49,
			1405.753,
			1409.089,
			1410.191,
			1411.264,
			1414.474,
			1416.581,
			1419.649,
			1421.636,
			1423.629,
			1424.605,
			1426.535,
			1428.451,
			1429.403,
			1431.275,
			1432.216,
			1434.095,
			1435.956,
			1438.754,
			1440.633,
			1443.442,
			1444.383,
			1445.324,
			1448.161,
			1450.069,
			1452.953,
			1454.876,
			1456.838,
			1459.809,
			1460.822,
			1461.853,
			1464.926,
			1465.976,
			1467.028,
			1470.208,
			1472.36,
			1474.509,
			1475.565,
			1477.658,
			1479.751,
			1480.759,
			1483.808,
			1485.8,
			1487.785,
			1490.777,
			1492.785,
			1495.799,
			1496.816,
			1497.836,
			1500.921,
			1503.016,
			1506.154,
			1508.259,
			1510.376,
			1511.441,
			1513.58,
			1514.635,
			1516.779,
			1518.932,
			1520.005,
			1521.088,
			1524.282,
			1526.425,
			1528.575,
			1529.654,
			1531.828,
			1534.01,
			1535.104,
			1537.301,
			1538.402,
			1540.612,
			1542.849,
			1546.197,
			1548.443,
			1551.816,
			1552.953,
			1554.091,
			1557.523,
			1559.844,
			1563.288,
			1565.629,
			1567.939,
			1569.107,
			1571.457,
			1572.637,
			1575.005,
			1577.389,
			1578.584,
			1580.983,
			1583.39,
			1587.016,
			1588.229,
			1589.444,
			1591.88,
			1594.325,
			1595.55,
			1598.006,
			1599.237,
			1601.705,
			1604.181,
			1607.913,
			1610.413,
			1614.188,
			1615.45,
			1616.713,
			1620.514,
			1623.056,
			1626.886,
			1629.448,
			1632.012,
			1633.293,
			1635.845,
			1638.379,
			1639.635,
			1642.123,
			1643.352,
			1645.783,
			1648.177,
			1651.7,
			1653.058
		],
		[
			0,
			0.045,
			0.109,
			0.174,
			0.233,
			0.279,
			0.293,
			0.356,
			0.387,
			0.419,
			0.479,
			0.496,
			0.541,
			0.603,
			0.666,
			0.727,
			0.791,
			0.821,
			0.851,
			0.914,
			0.928,
			0.976,
			1.037,
			1.099,
			1.145,
			1.223,
			1.255,
			1.286,
			1.349,
			1.41,
			1.473,
			1.535,
			1.58,
			1.595,
			1.659,
			1.69,
			1.721,
			1.784,
			1.798,
			1.845,
			1.908,
			1.967,
			2.016,
			2.031,
			2.093,
			2.125,
			2.153,
			2.217,
			2.28,
			2.34,
			2.403,
			2.45,
			2.528,
			2.558,
			2.59,
			2.651,
			2.667,
			2.712,
			2.775,
			2.838,
			2.883,
			2.9,
			2.96,
			2.992,
			3.023,
			3.084,
			3.1,
			3.145,
			3.21,
			3.271,
			3.317,
			3.394,
			3.425,
			3.457,
			3.519,
			3.582,
			3.643,
			3.705,
			3.751,
			3.767,
			3.829,
			3.859,
			3.89,
			3.969,
			4.016,
			4.078,
			4.14,
			4.201,
			4.262,
			4.3,
			4.326,
			4.388,
			4.401,
			4.449,
			4.51,
			4.572,
			4.636,
			4.697,
			4.728,
			4.758,
			4.837,
			4.884,
			4.945,
			5.007,
			5.052,
			5.069,
			5.129,
			5.162,
			5.193,
			5.253,
			5.27,
			5.317,
			5.379,
			5.442,
			5.489,
			5.502,
			5.563,
			5.594,
			5.626,
			5.705,
			5.75,
			5.812,
			5.874,
			5.921,
			5.998,
			6.029,
			6.06,
			6.123,
			6.184,
			6.246,
			6.309,
			6.36,
			6.372,
			6.433,
			6.462,
			6.493,
			6.557,
			6.571,
			6.62,
			6.682,
			6.743,
			6.789,
			6.803,
			6.867,
			6.899,
			6.928,
			7.005,
			7.052,
			7.115,
			7.176,
			7.24,
			7.302,
			7.333,
			7.363,
			7.424,
			7.442,
			7.487,
			7.549,
			7.61,
			7.657,
			7.672,
			7.735,
			7.766,
			7.795,
			7.858,
			7.875,
			7.92,
			7.984,
			8.046,
			8.09,
			8.107,
			8.168,
			8.2,
			8.229,
			8.308,
			8.354,
			8.422,
			8.478,
			8.524,
			8.542,
			8.602,
			8.633,
			8.665,
			8.726,
			8.743,
			8.788,
			8.85,
			8.912,
			8.96,
			8.975,
			9.037,
			9.068,
			9.099,
			9.161,
			9.176,
			9.222,
			9.287,
			9.345,
			9.393,
			9.47,
			9.501,
			9.533,
			9.612,
			9.657,
			9.72,
			9.783,
			9.842,
			9.906,
			9.937,
			9.967,
			10.046,
			10.093,
			10.153,
			10.217,
			10.261,
			10.278,
			10.339,
			10.371,
			10.401,
			10.465,
			10.484,
			10.524,
			10.587,
			10.65,
			10.696,
			10.773,
			10.803,
			10.835,
			10.898,
			10.96,
			11.022,
			11.082,
			11.146,
			11.207,
			11.237,
			11.269,
			11.332,
			11.346,
			11.395,
			11.457,
			11.518,
			11.579,
			11.642,
			11.673,
			11.703,
			11.766,
			11.781,
			11.828,
			11.89,
			11.951,
			11.999,
			12.077,
			12.106,
			12.137,
			12.2,
			12.26,
			12.324,
			12.385,
			12.434,
			12.446,
			12.51,
			12.545,
			12.571,
			12.634,
			12.65,
			12.695,
			12.758,
			12.82,
			12.867,
			12.944,
			12.976,
			13.007,
			13.067,
			13.129,
			13.193,
			13.254,
			13.316,
			13.378,
			13.409,
			13.439,
			13.516,
			13.562,
			13.625,
			13.688,
			13.734,
			13.751,
			13.813,
			13.842,
			13.873,
			13.952,
			13.999,
			14.061,
			14.122,
			14.167,
			14.246,
			14.277,
			14.307,
			14.369,
			14.433,
			14.495,
			14.554,
			14.607,
			14.618,
			14.68,
			14.711,
			14.743,
			14.804,
			14.82,
			14.867,
			14.929,
			14.989,
			15.035,
			15.052,
			15.112,
			15.144,
			15.176,
			15.237,
			15.252,
			15.3,
			15.361,
			15.424,
			15.469,
			15.546,
			15.578,
			15.609,
			15.673,
			15.734,
			15.796,
			15.858,
			15.905,
			15.921,
			15.983,
			16.012,
			16.045,
			16.105,
			16.122,
			16.167,
			16.231,
			16.292,
			16.355,
			16.416,
			16.447,
			16.478,
			16.555,
			16.601,
			16.67,
			16.726,
			16.788,
			16.85,
			16.882,
			16.913,
			16.973,
			17.037,
			17.099,
			17.16,
			17.206,
			17.223,
			17.285,
			17.313,
			17.345,
			17.409,
			17.424,
			17.469,
			17.534,
			17.594,
			17.64,
			17.655,
			17.718,
			17.751,
			17.778,
			17.842,
			17.903,
			17.967,
			18.029,
			18.075,
			18.153,
			18.184,
			18.212,
			18.292,
			18.339,
			18.4,
			18.461,
			18.509,
			18.526,
			18.585,
			18.619,
			18.648,
			18.712,
			18.731,
			18.772,
			18.834,
			18.895,
			18.96,
			19.021,
			19.052,
			19.083,
			19.16,
			19.208,
			19.268,
			19.329,
			19.378,
			19.393,
			19.453,
			19.487,
			19.517,
			19.578,
			19.595,
			19.64,
			19.703,
			19.763,
			19.825,
			19.887,
			19.921,
			19.95,
			20.011,
			20.028,
			20.074,
			20.138,
			20.2,
			20.244,
			20.323,
			20.353,
			20.386,
			20.461,
			20.508,
			20.57,
			20.633,
			20.678,
			20.695,
			20.757,
			20.794,
			20.82,
			20.895,
			20.943,
			21.004,
			21.067,
			21.115,
			21.128,
			21.19,
			21.221,
			21.252,
			21.313,
			21.331,
			21.376,
			21.439,
			21.501,
			21.564,
			21.624,
			21.657,
			21.688,
			21.75,
			21.81,
			21.873,
			21.934,
			21.983,
			22,
			22.059,
			22.09,
			22.12,
			22.183,
			22.198,
			22.246,
			22.307,
			22.369,
			22.416,
			22.429,
			22.492,
			22.524,
			22.555,
			22.617,
			22.68,
			22.742,
			22.805,
			22.854,
			22.926,
			22.957,
			22.988,
			23.051,
			23.067,
			23.112,
			23.175,
			23.237,
			23.284,
			23.299,
			23.362,
			23.393,
			23.422,
			23.484,
			23.502,
			23.545,
			23.61,
			23.672,
			23.717,
			23.794,
			23.825,
			23.857,
			23.92,
			23.98,
			24.043,
			24.106,
			24.167,
			24.23,
			24.261,
			24.291,
			24.369,
			24.417,
			24.477,
			24.54,
			24.602,
			24.663,
			24.694,
			24.724,
			24.787,
			24.804,
			24.85,
			24.916,
			24.973,
			25.034,
			25.097,
			25.13,
			25.159,
			25.236,
			25.285,
			25.345,
			25.407,
			25.471,
			25.531,
			25.562,
			25.593,
			25.671,
			25.717,
			25.781,
			25.843,
			25.904,
			25.967,
			25.996,
			26.029,
			26.09,
			26.106,
			26.151,
			26.215,
			26.275,
			26.323,
			26.4,
			26.429,
			26.461,
			26.524,
			26.587,
			26.649,
			26.708,
			26.773,
			26.833,
			26.865,
			26.896,
			26.959,
			26.976,
			27.019,
			27.083,
			27.145,
			27.19,
			27.207,
			27.269,
			27.3,
			27.329,
			27.407,
			27.454,
			27.516,
			27.576,
			27.641,
			27.702,
			27.732,
			27.764,
			27.826,
			27.842,
			27.888,
			27.95,
			28.012,
			28.057,
			28.074,
			28.135,
			28.167,
			28.196,
			28.261,
			28.275,
			28.323,
			28.383,
			28.444,
			28.492,
			28.571,
			28.602,
			28.631,
			28.693,
			28.755,
			28.817,
			28.878,
			28.926,
			29.005,
			29.039,
			29.067,
			29.143,
			29.19,
			29.251,
			29.315,
			29.36,
			29.375,
			29.437,
			29.469,
			29.5,
			29.563,
			29.577,
			29.624,
			29.687,
			29.745,
			29.809,
			29.873,
			29.902,
			29.935,
			29.995,
			30.058,
			30.121,
			30.183,
			30.229,
			30.245,
			30.307,
			30.336,
			30.369,
			30.446,
			30.493,
			30.556,
			30.616,
			30.663,
			30.678,
			30.74,
			30.772,
			30.802,
			30.879,
			30.926,
			30.989,
			31.05,
			31.111,
			31.173,
			31.206,
			31.237,
			31.298,
			31.36,
			31.423,
			31.485,
			31.53,
			31.545,
			31.608,
			31.639,
			31.671,
			31.732,
			31.746,
			31.794,
			31.857,
			31.918,
			31.966,
			31.979,
			32.043,
			32.073,
			32.104,
			32.167,
			32.227,
			32.29,
			32.351,
			32.399,
			32.476,
			32.507,
			32.537,
			32.617,
			32.661,
			32.723,
			32.787,
			32.849,
			32.91,
			32.94,
			32.974,
			33.035,
			33.05,
			33.095,
			33.162,
			33.22,
			33.268,
			33.345,
			33.376,
			33.406,
			33.483,
			33.532,
			33.593,
			33.654,
			33.701,
			33.717,
			33.78,
			33.809,
			33.84,
			33.904,
			33.918,
			33.963,
			34.028,
			34.087,
			34.136,
			34.15,
			34.212,
			34.243,
			34.275,
			34.337,
			34.351,
			34.399,
			34.461,
			34.524,
			34.57,
			34.648,
			34.678,
			34.709,
			34.787,
			34.833,
			34.895,
			34.956,
			35.002,
			35.019,
			35.081,
			35.112,
			35.143,
			35.204,
			35.224,
			35.267,
			35.33,
			35.39,
			35.436,
			35.454,
			35.515,
			35.546,
			35.577,
			35.64,
			35.653,
			35.701,
			35.762,
			35.826,
			35.872,
			35.95,
			35.979,
			36.01,
			36.089,
			36.134,
			36.195,
			36.259,
			36.304,
			36.321,
			36.383,
			36.415,
			36.446,
			36.506,
			36.524,
			36.568,
			36.632,
			36.694,
			36.756,
			36.818,
			36.848,
			36.877,
			36.94,
			36.956,
			37.004,
			37.066,
			37.127,
			37.173,
			37.25,
			37.287,
			37.312,
			37.376,
			37.438,
			37.5,
			37.56,
			37.606,
			37.623,
			37.685,
			37.716,
			37.745,
			37.81,
			37.824,
			37.87,
			37.935,
			37.996,
			38.056,
			38.12,
			38.15,
			38.181,
			38.243,
			38.304,
			38.368,
			38.43,
			38.476,
			38.555,
			38.583,
			38.615,
			38.693,
			38.74,
			38.802,
			38.864,
			38.91,
			38.924,
			38.986,
			39.018,
			39.049,
			39.112,
			39.127,
			39.174,
			39.235,
			39.297,
			39.346,
			39.422,
			39.453,
			39.484,
			39.56,
			39.609,
			39.67,
			39.733,
			39.778,
			39.795,
			39.857,
			39.888,
			39.918,
			39.978,
			39.995,
			40.043,
			40.104,
			40.167,
			40.211,
			40.226,
			40.29,
			40.321,
			40.351,
			40.415,
			40.428,
			40.477,
			40.539,
			40.6,
			40.661,
			40.724,
			40.755,
			40.786,
			40.849,
			40.909,
			40.974,
			41.034,
			41.08,
			41.094,
			41.158,
			41.189,
			41.219,
			41.283,
			41.298,
			41.343,
			41.41,
			41.468,
			41.531,
			41.593,
			41.624,
			41.653,
			41.717,
			41.779,
			41.84,
			41.901,
			41.949,
			42.025,
			42.057,
			42.088,
			42.166,
			42.211,
			42.274,
			42.337,
			42.384,
			42.399,
			42.461,
			42.492,
			42.521,
			42.585,
			42.598,
			42.644,
			42.709,
			42.769,
			42.817,
			42.894,
			42.924,
			42.957,
			43.017,
			43.08,
			43.142,
			43.203,
			43.251,
			43.329,
			43.358,
			43.389,
			43.453,
			43.474,
			43.515,
			43.576,
			43.637,
			43.685,
			43.701,
			43.762,
			43.793,
			43.823,
			43.902,
			43.946,
			44.009,
			44.071,
			44.134,
			44.195,
			44.227,
			44.258,
			44.32,
			44.383,
			44.446,
			44.507,
			44.553,
			44.567,
			44.631,
			44.661,
			44.692,
			44.753,
			44.771,
			44.814,
			44.877,
			44.94,
			44.987,
			45.001,
			45.065,
			45.095,
			45.127,
			45.187,
			45.203,
			45.251,
			45.311,
			45.374,
			45.437,
			45.499,
			45.534,
			45.559,
			45.622,
			45.684,
			45.744,
			45.807,
			45.856,
			45.869,
			45.933,
			45.964,
			45.994,
			46.058,
			46.073,
			46.117,
			46.184,
			46.243,
			46.288,
			46.305,
			46.366,
			46.398,
			46.428,
			46.491,
			46.506,
			46.551,
			46.615,
			46.676,
			46.739,
			46.8,
			46.832,
			46.86,
			46.925,
			46.987,
			47.05,
			47.11,
			47.157,
			47.172,
			47.233,
			47.267,
			47.295,
			47.36,
			47.374,
			47.419,
			47.483,
			47.545,
			47.589
		]
	],
	'2519_2520_7982': [
		[
			47.088
		],
		[
			0
		],
		[
			0,
			2.614,
			7.257,
			8.808,
			10.36,
			15.024,
			16.582,
			18.14,
			22.822,
			25.949,
			30.649,
			33.789,
			36.933,
			38.507,
			43.238,
			46.399,
			49.564,
			54.319,
			57.494,
			62.264,
			63.857,
			65.449,
			70.234,
			73.423,
			78.212,
			81.409,
			86.213,
			89.419,
			91.023,
			92.628,
			97.451,
			99.06,
			100.67,
			105.504,
			108.723,
			111.928,
			113.523,
			116.692,
			119.826,
			121.379,
			124.455,
			125.979,
			129,
			131.985,
			136.391,
			139.279,
			143.54,
			144.939,
			146.329,
			150.439,
			153.126,
			157.076,
			159.66,
			162.202,
			163.46,
			165.945,
			167.173,
			169.623,
			173.2,
			174.377,
			177.858,
			180.136,
			182.379,
			183.488,
			185.68,
			187.838,
			188.905,
			191.02,
			192.065,
			194.132,
			196.178,
			199.191,
			201.171,
			204.09,
			205.053,
			206.014,
			208.886,
			210.806,
			213.69,
			215.613,
			218.497,
			220.413,
			221.369,
			223.278,
			225.18,
			226.127,
			228.017,
			229.903,
			232.717,
			233.653,
			234.588,
			237.386,
			238.316,
			239.244,
			242.031,
			243.886,
			246.678,
			248.543,
			250.415,
			253.242,
			254.184,
			255.129,
			257.973,
			259.867,
			262.722,
			264.621,
			266.526,
			267.477,
			269.376,
			271.28,
			272.232,
			274.133,
			275.082,
			276.988,
			278.887,
			281.739,
			283.643,
			286.512,
			287.473,
			288.434,
			291.33,
			293.27,
			296.189,
			298.142,
			300.097,
			303.04,
			304.021,
			305.002,
			307.943,
			308.922,
			309.902,
			312.832,
			314.784,
			316.73,
			317.702,
			319.648,
			321.59,
			322.561,
			324.506,
			325.481,
			327.419,
			329.362,
			332.278,
			334.228,
			337.165,
			338.148,
			339.133,
			342.101,
			344.092,
			347.098,
			349.116,
			351.146,
			352.165,
			354.212,
			355.241,
			356.271,
			359.376,
			360.421,
			361.465,
			364.617,
			366.734,
			368.86,
			369.929,
			372.075,
			374.233,
			375.317,
			377.497,
			378.591,
			380.789,
			383.005,
			386.349,
			388.593,
			391.976,
			393.111,
			394.248,
			397.673,
			399.97,
			403.429,
			405.738,
			409.215,
			411.541,
			412.713,
			415.04,
			417.412,
			418.572,
			420.949,
			423.29,
			426.852,
			428.033,
			429.214,
			432.765,
			433.949,
			435.113,
			437.479,
			438.662,
			441.033,
			443.407,
			446.977,
			449.362,
			452.95,
			454.149,
			455.349,
			458.956,
			460.162,
			461.369,
			465,
			467.431,
			469.869,
			471.091,
			473.541,
			474.769,
			477.229,
			480.938,
			483.421,
			485.912,
			489.663,
			492.173,
			495.954,
			497.217,
			498.481,
			502.284,
			504.827,
			508.638,
			511.161,
			513.655,
			514.888,
			517.326,
			518.53,
			519.724,
			523.247,
			525.546,
			528.919,
			531.117,
			533.275,
			534.338,
			536.437,
			538.496,
			539.51,
			541.511,
			542.497,
			544.439,
			546.343,
			549.123,
			550.93,
			553.572,
			554.435,
			555.287,
			557.787,
			559.405,
			561.76,
			563.282,
			564.765,
			565.492,
			566.919,
			567.618,
			568.986,
			570.319,
			570.984,
			571.629,
			573.512,
			575.308,
			575.893,
			576.472,
			577.613,
			578.723,
			579.267,
			580.344,
			580.878,
			581.937,
			582.991,
			584.546,
			585.579,
			587.129,
			587.646,
			588.165,
			589.738,
			590.788,
			592.368,
			593.436,
			594.527,
			595.074,
			596.17,
			596.72,
			597.826,
			598.956,
			599.526,
			600.67,
			601.82,
			603.561,
			604.15,
			604.74,
			606.513,
			607.105,
			607.699,
			608.902,
			609.508,
			610.718,
			611.928,
			613.759,
			615.002,
			616.857,
			617.474,
			618.091,
			619.981,
			621.232,
			623.095,
			624.364,
			625.628,
			626.257,
			627.513,
			628.781,
			629.423,
			630.701,
			631.338,
			632.607,
			633.893,
			635.841,
			637.134,
			639.096,
			639.759,
			640.422,
			642.406,
			643.737,
			645.755,
			647.102,
			648.454,
			649.131,
			650.484,
			651.162,
			651.841,
			653.888,
			654.572,
			655.257,
			657.316,
			658.694,
			660.077,
			660.768,
			662.154,
			663.543,
			664.224,
			665.585,
			666.267,
			667.635,
			668.991,
			670.968,
			672.293,
			674.274,
			674.923,
			675.574,
			677.54,
			678.861,
			680.828,
			682.153,
			684.157,
			685.492,
			686.164,
			687.518,
			689.571,
			690.26,
			692.348,
			693.76,
			695.204,
			695.932,
			697.4,
			698.887,
			699.651,
			701.191,
			701.967,
			703.531,
			705.133,
			707.57,
			709.216,
			711.718,
			712.559,
			713.399,
			715.922,
			717.617,
			720.183,
			721.912,
			723.658,
			724.538,
			726.311,
			727.187,
			728.996,
			730.814,
			731.728,
			733.55,
			735.425,
			738.231,
			739.179,
			740.127,
			742.033,
			743.968,
			744.92,
			746.871,
			747.851,
			749.822,
			752.799,
			754.798,
			756.784,
			759.79,
			760.799,
			761.811,
			764.868,
			766.925,
			770.038,
			772.138,
			774.24,
			775.294,
			777.412,
			778.476,
			780.616,
			783.854,
			786.031,
			788.24,
			791.551,
			793.775,
			797.127,
			798.249,
			799.375,
			802.747,
			804.995,
			807.217,
			810.517,
			812.674,
			815.855,
			816.898,
			817.933,
			820.997,
			823.008,
			825.97,
			827.911,
			829.818,
			830.765,
			832.634,
			834.47,
			835.379,
			837.175,
			838.061,
			839.811,
			841.531,
			844.06,
			845.715,
			848.154,
			848.959,
			849.759,
			852.127,
			853.691,
			856.021,
			857.564,
			859.874,
			861.41,
			862.177,
			863.708,
			866.019,
			867.57,
			869.121,
			870.699,
			872.315,
			873.123,
			874.742,
			876.438,
			877.296,
			879.878,
			881.657,
			883.447,
			886.139,
			887.933,
			890.624,
			891.516,
			892.408,
			895.084,
			896.853,
			899.499,
			901.242,
			902.968,
			903.831,
			905.548,
			907.225,
			908.064,
			909.742,
			910.574,
			912.205,
			913.838,
			916.281,
			917.089,
			917.899,
			919.525,
			921.157,
			921.976,
			923.622,
			924.45,
			926.118,
			927.802,
			930.353,
			932.081,
			934.695,
			935.574,
			936.457,
			939.127,
			940.921,
			943.631,
			945.454,
			947.287,
			948.208,
			950.06,
			950.99,
			952.861,
			954.741,
			955.685,
			957.585,
			959.493,
			962.36,
			964.305,
			967.21,
			968.187,
			969.164,
			972.089,
			974.03,
			975.98,
			978.862,
			980.796,
			983.709,
			984.685,
			985.665,
			988.617,
			990.6,
			993.591,
			995.599,
			997.618,
			998.631,
			1000.667,
			1002.715,
			1003.743,
			1005.807,
			1006.844,
			1008.925,
			1011.015,
			1014.148,
			1016.24,
			1019.391,
			1020.446,
			1021.502,
			1024.681,
			1026.809,
			1030.009,
			1032.15,
			1034.295,
			1035.372,
			1037.53,
			1038.613,
			1040.785,
			1042.968,
			1044.063,
			1046.261,
			1048.472,
			1050.691,
			1052.923,
			1054.043,
			1056.29,
			1058.55,
			1059.684,
			1061.958,
			1063.099,
			1065.389,
			1068.844,
			1071.159,
			1073.487,
			1076.998,
			1078.174,
			1079.352,
			1082.901,
			1085.279,
			1088.863,
			1091.262,
			1093.67,
			1094.877,
			1097.296,
			1098.507,
			1100.937,
			1104.597,
			1107.047,
			1109.504,
			1111.97,
			1114.444,
			1115.684,
			1118.17,
			1120.665,
			1121.916,
			1124.423,
			1125.68,
			1128.2,
			1130.728,
			1134.534,
			1137.081,
			1140.916,
			1142.197,
			1143.481,
			1147.338,
			1149.934,
			1153.79,
			1156.354,
			1160.185,
			1162.73,
			1163.998,
			1166.529,
			1169.049,
			1170.305,
			1172.808,
			1175.298,
			1179.023,
			1181.5,
			1185.217,
			1186.456,
			1187.695,
			1191.417,
			1193.905,
			1197.644,
			1200.165,
			1202.688,
			1206.515,
			1207.794,
			1209.083,
			1212.947,
			1214.241,
			1215.535,
			1219.413,
			1221.997,
			1225.846,
			1228.407,
			1230.966,
			1232.246,
			1234.829,
			1236.123,
			1238.725,
			1241.349,
			1245.311,
			1246.637,
			1247.965,
			1251.964,
			1253.294,
			1254.62,
			1258.588,
			1261.209,
			1265.104,
			1267.663,
			1270.227,
			1274.023,
			1275.275,
			1276.527,
			1280.257,
			1281.494,
			1283.96,
			1286.411,
			1288.827,
			1291.248,
			1292.446,
			1294.829,
			1297.185,
			1298.359,
			1301.919,
			1304.29,
			1306.642,
			1310.206,
			1312.59,
			1316.192,
			1317.398,
			1318.606,
			1322.243,
			1324.669,
			1328.296,
			1330.71,
			1334.231,
			1336.55,
			1337.695,
			1339.97,
			1343.262,
			1345.414,
			1347.53,
			1349.617,
			1352.643,
			1355.566,
			1356.519,
			1357.462,
			1359.312,
			1360.219,
			1362.004,
			1363.752,
			1366.294,
			1367.939,
			1370.334,
			1371.113,
			1371.883,
			1374.146,
			1375.621,
			1377.792,
			1379.22,
			1380.638,
			1381.342,
			1382.746,
			1383.446,
			1384.847,
			1386.946,
			1388.345,
			1389.747,
			1391.849,
			1392.551,
			1393.253,
			1395.368,
			1396.074,
			1396.781,
			1398.906,
			1400.321,
			1401.737,
			1403.864,
			1405.274,
			1407.388,
			1408.093,
			1408.799,
			1411.616,
			1412.319,
			1414.436,
			1415.854,
			1417.27,
			1417.977,
			1419.404,
			1420.124,
			1421.562,
			1423.717,
			1425.178,
			1426.636,
			1428.822,
			1430.299,
			1432.508,
			1433.244,
			1433.983,
			1436.223,
			1437.715,
			1439.974,
			1441.493,
			1443.012,
			1443.772,
			1445.326,
			1446.103,
			1447.657,
			1449.219,
			1450.016,
			1450.812,
			1453.194,
			1454.796,
			1456.407,
			1457.21,
			1458.815,
			1460.411,
			1461.207,
			1463.593,
			1465.156,
			1466.709,
			1469.037,
			1470.574,
			1472.885,
			1473.658,
			1474.429,
			1476.742,
			1478.293,
			1480.631,
			1482.198,
			1483.775,
			1484.569,
			1486.164,
			1486.966,
			1488.578,
			1490.999,
			1492.622,
			1494.251,
			1495.868,
			1497.493,
			1498.308,
			1499.936,
			1501.562,
			1502.378,
			1504.014,
			1504.83,
			1506.466,
			1508.105,
			1510.568,
			1512.21,
			1514.676,
			1515.495,
			1516.314,
			1518.773,
			1520.411,
			1522.871,
			1524.52,
			1526.182,
			1527.013,
			1528.68,
			1529.516,
			1531.193,
			1533.717,
			1535.399,
			1537.082,
			1539.613,
			1540.453,
			1541.294,
			1543.828,
			1544.674,
			1545.519,
			1548.064,
			1549.771,
			1551.481,
			1554.066,
			1555.816,
			1558.463,
			1560.248,
			1562.955,
			1563.864,
			1564.777,
			1567.538,
			1569.397,
			1572.214,
			1574.109,
			1575.069,
			1577.002,
			1579.93,
			1581.912,
			1583.907,
			1586.939,
			1587.957,
			1588.977,
			1592.069,
			1593.107,
			1594.147,
			1596.232,
			1597.277,
			1599.367,
			1602.491,
			1604.584,
			1606.683,
			1609.851,
			1610.912,
			1611.977,
			1615.185,
			1616.261,
			1617.339,
			1620.588,
			1622.765,
			1626.049,
			1628.25,
			1630.463,
			1631.575,
			1634.927,
			1637.181,
			1639.444,
			1642.867,
			1645.162,
			1648.629,
			1649.79,
			1650.956,
			1654.466,
			1656.823,
			1660.376,
			1662.757,
			1665.147,
			1666.347,
			1668.752,
			1669.958,
			1672.378,
			1676.027,
			1678.472,
			1680.929,
			1683.399,
			1685.88,
			1687.126,
			1689.625,
			1692.113,
			1693.371,
			1697.172,
			1699.699,
			1702.231,
			1706.045,
			1708.6,
			1712.452,
			1713.742,
			1715.034,
			1718.907,
			1721.515,
			1725.465,
			1728.095,
			1732.04,
			1734.697,
			1736.027,
			1738.71,
			1741.368,
			1742.696,
			1745.337,
			1748.004,
			1752.02,
			1753.363,
			1754.708,
			1757.427,
			1760.131,
			1761.484,
			1764.195,
			1765.531,
			1768.248,
			1770.97,
			1775.062,
			1777.795,
			1781.902,
			1783.272,
			1784.645,
			1788.765,
			1791.518,
			1795.655,
			1798.419,
			1801.19,
			1802.578,
			1805.36,
			1806.755,
			1809.553,
			1812.362,
			1813.771,
			1816.596,
			1819.437,
			1823.718,
			1826.582,
			1830.895,
			1832.335,
			1833.776,
			1838.107,
			1841,
			1845.339,
			1848.237,
			1851.14,
			1852.595,
			1855.511,
			1856.972,
			1858.436,
			1862.84,
			1864.312,
			1865.787,
			1870.22,
			1873.184,
			1876.156,
			1877.644,
			1880.625,
			1882.117,
			1885.104,
			1888.095,
			1889.591,
			1892.585,
			1895.582,
			1900.088,
			1903.101,
			1907.636,
			1909.152,
			1910.669,
			1915.235,
			1918.287,
			1922.874,
			1925.94,
			1929.01,
			1930.548,
			1933.624,
			1935.164,
			1938.246,
			1939.54
		],
		[
			0,
			0.039,
			0.118,
			0.148,
			0.179,
			0.242,
			0.257,
			0.304,
			0.368,
			0.425,
			0.49,
			0.55,
			0.582,
			0.614,
			0.69,
			0.736,
			0.799,
			0.861,
			0.907,
			0.986,
			1.017,
			1.048,
			1.108,
			1.171,
			1.234,
			1.295,
			1.357,
			1.418,
			1.449,
			1.48,
			1.543,
			1.559,
			1.606,
			1.667,
			1.729,
			1.777,
			1.79,
			1.852,
			1.885,
			1.916,
			1.979,
			1.991,
			2.038,
			2.101,
			2.163,
			2.21,
			2.289,
			2.319,
			2.349,
			2.412,
			2.472,
			2.539,
			2.597,
			2.645,
			2.661,
			2.722,
			2.752,
			2.783,
			2.861,
			2.907,
			2.97,
			3.031,
			3.079,
			3.094,
			3.155,
			3.186,
			3.217,
			3.28,
			3.295,
			3.342,
			3.403,
			3.467,
			3.513,
			3.59,
			3.62,
			3.651,
			3.713,
			3.777,
			3.836,
			3.901,
			3.962,
			4.023,
			4.056,
			4.086,
			4.148,
			4.164,
			4.21,
			4.272,
			4.333,
			4.381,
			4.395,
			4.457,
			4.489,
			4.519,
			4.583,
			4.645,
			4.707,
			4.767,
			4.831,
			4.89,
			4.922,
			4.955,
			5.017,
			5.078,
			5.141,
			5.203,
			5.249,
			5.263,
			5.325,
			5.358,
			5.387,
			5.45,
			5.467,
			5.512,
			5.575,
			5.637,
			5.682,
			5.761,
			5.792,
			5.823,
			5.883,
			5.946,
			6.008,
			6.07,
			6.131,
			6.194,
			6.225,
			6.255,
			6.317,
			6.334,
			6.381,
			6.442,
			6.503,
			6.55,
			6.567,
			6.628,
			6.662,
			6.69,
			6.753,
			6.767,
			6.814,
			6.877,
			6.937,
			6.983,
			7.066,
			7.091,
			7.125,
			7.186,
			7.247,
			7.31,
			7.371,
			7.418,
			7.434,
			7.495,
			7.525,
			7.556,
			7.62,
			7.637,
			7.682,
			7.745,
			7.806,
			7.853,
			7.867,
			7.931,
			7.961,
			7.992,
			8.053,
			8.07,
			8.115,
			8.179,
			8.239,
			8.287,
			8.364,
			8.396,
			8.427,
			8.503,
			8.55,
			8.612,
			8.674,
			8.736,
			8.799,
			8.831,
			8.859,
			8.922,
			8.938,
			8.986,
			9.047,
			9.107,
			9.156,
			9.17,
			9.233,
			9.264,
			9.295,
			9.356,
			9.371,
			9.419,
			9.481,
			9.541,
			9.59,
			9.667,
			9.699,
			9.73,
			9.789,
			9.807,
			9.853,
			9.916,
			9.977,
			10.024,
			10.038,
			10.1,
			10.131,
			10.164,
			10.239,
			10.286,
			10.35,
			10.409,
			10.473,
			10.534,
			10.567,
			10.597,
			10.675,
			10.722,
			10.786,
			10.845,
			10.89,
			10.907,
			10.97,
			11,
			11.031,
			11.107,
			11.155,
			11.217,
			11.28,
			11.326,
			11.34,
			11.403,
			11.434,
			11.464,
			11.528,
			11.541,
			11.59,
			11.65,
			11.714,
			11.758,
			11.836,
			11.867,
			11.899,
			11.962,
			12.022,
			12.086,
			12.147,
			12.193,
			12.207,
			12.27,
			12.303,
			12.334,
			12.395,
			12.412,
			12.456,
			12.52,
			12.582,
			12.629,
			12.642,
			12.704,
			12.736,
			12.767,
			12.83,
			12.847,
			12.889,
			12.953,
			13.015,
			13.063,
			13.139,
			13.169,
			13.202,
			13.262,
			13.324,
			13.386,
			13.449,
			13.495,
			13.512,
			13.573,
			13.603,
			13.637,
			13.698,
			13.712,
			13.76,
			13.821,
			13.884,
			13.931,
			13.946,
			14.008,
			14.037,
			14.071,
			14.131,
			14.147,
			14.194,
			14.257,
			14.318,
			14.365,
			14.442,
			14.473,
			14.504,
			14.581,
			14.628,
			14.69,
			14.752,
			14.796,
			14.814,
			14.873,
			14.911,
			14.937,
			14.999,
			15.013,
			15.062,
			15.124,
			15.184,
			15.232,
			15.309,
			15.341,
			15.37,
			15.434,
			15.495,
			15.558,
			15.62,
			15.664,
			15.682,
			15.742,
			15.775,
			15.804,
			15.867,
			15.884,
			15.93,
			15.991,
			16.053,
			16.101,
			16.115,
			16.179,
			16.208,
			16.239,
			16.301,
			16.317,
			16.364,
			16.427,
			16.489,
			16.534,
			16.612,
			16.642,
			16.674,
			16.736,
			16.796,
			16.86,
			16.921,
			16.983,
			17.046,
			17.076,
			17.107,
			17.186,
			17.231,
			17.294,
			17.356,
			17.401,
			17.417,
			17.48,
			17.512,
			17.542,
			17.603,
			17.62,
			17.666,
			17.728,
			17.789,
			17.851,
			17.913,
			17.946,
			17.974,
			18.039,
			18.1,
			18.163,
			18.223,
			18.27,
			18.286,
			18.347,
			18.379,
			18.409,
			18.473,
			18.488,
			18.533,
			18.597,
			18.658,
			18.705,
			18.719,
			18.781,
			18.813,
			18.845,
			18.905,
			18.921,
			18.967,
			19.036,
			19.092,
			19.139,
			19.217,
			19.248,
			19.279,
			19.34,
			19.402,
			19.465,
			19.527,
			19.574,
			19.588,
			19.651,
			19.681,
			19.712,
			19.789,
			19.837,
			19.898,
			19.961,
			20.007,
			20.084,
			20.116,
			20.146,
			20.208,
			20.27,
			20.331,
			20.392,
			20.439,
			20.517,
			20.548,
			20.581,
			20.642,
			20.704,
			20.767,
			20.828,
			20.875,
			20.889,
			20.953,
			20.984,
			21.014,
			21.075,
			21.098,
			21.139,
			21.2,
			21.262,
			21.309,
			21.386,
			21.417,
			21.447,
			21.526,
			21.571,
			21.636,
			21.697,
			21.759,
			21.819,
			21.853,
			21.884,
			21.959,
			22.007,
			22.069,
			22.131,
			22.177,
			22.193,
			22.253,
			22.286,
			22.315,
			22.395,
			22.44,
			22.504,
			22.565,
			22.627,
			22.687,
			22.719,
			22.749,
			22.812,
			22.874,
			22.937,
			22.998,
			23.045,
			23.061,
			23.122,
			23.157,
			23.184,
			23.247,
			23.262,
			23.307,
			23.374,
			23.434,
			23.479,
			23.495,
			23.556,
			23.586,
			23.619,
			23.681,
			23.696,
			23.744,
			23.804,
			23.866,
			23.912,
			23.992,
			24.023,
			24.053,
			24.129,
			24.177,
			24.238,
			24.3,
			24.348,
			24.364,
			24.425,
			24.455,
			24.486,
			24.549,
			24.564,
			24.611,
			24.674,
			24.734,
			24.782,
			24.858,
			24.889,
			24.92,
			24.984,
			25.046,
			25.106,
			25.169,
			25.219,
			25.292,
			25.324,
			25.355,
			25.417,
			25.48,
			25.54,
			25.602,
			25.65,
			25.667,
			25.726,
			25.758,
			25.789,
			25.852,
			25.867,
			25.914,
			25.973,
			26.036,
			26.099,
			26.161,
			26.191,
			26.223,
			26.286,
			26.348,
			26.41,
			26.471,
			26.517,
			26.534,
			26.596,
			26.626,
			26.657,
			26.72,
			26.736,
			26.782,
			26.845,
			26.904,
			26.95,
			26.967,
			27.029,
			27.062,
			27.092,
			27.153,
			27.168,
			27.216,
			27.282,
			27.34,
			27.386,
			27.463,
			27.495,
			27.524,
			27.586,
			27.65,
			27.712,
			27.773,
			27.82,
			27.834,
			27.898,
			27.929,
			27.958,
			28.036,
			28.083,
			28.146,
			28.206,
			28.253,
			28.27,
			28.33,
			28.364,
			28.395,
			28.456,
			28.471,
			28.516,
			28.58,
			28.642,
			28.689,
			28.764,
			28.797,
			28.828,
			28.889,
			28.953,
			29.014,
			29.077,
			29.136,
			29.199,
			29.231,
			29.261,
			29.322,
			29.343,
			29.386,
			29.446,
			29.51,
			29.572,
			29.634,
			29.664,
			29.695,
			29.759,
			29.82,
			29.886,
			29.944,
			29.99,
			30.067,
			30.099,
			30.131,
			30.191,
			30.208,
			30.255,
			30.317,
			30.379,
			30.44,
			30.502,
			30.533,
			30.565,
			30.627,
			30.64,
			30.686,
			30.751,
			30.812,
			30.857,
			30.874,
			30.936,
			30.966,
			30.998,
			31.075,
			31.122,
			31.186,
			31.245,
			31.292,
			31.369,
			31.406,
			31.431,
			31.494,
			31.508,
			31.557,
			31.622,
			31.68,
			31.725,
			31.742,
			31.804,
			31.834,
			31.867,
			31.942,
			31.99,
			32.053,
			32.114,
			32.161,
			32.237,
			32.268,
			32.3,
			32.362,
			32.423,
			32.486,
			32.548,
			32.609,
			32.673,
			32.704,
			32.733,
			32.812,
			32.857,
			32.919,
			32.981,
			33.045,
			33.107,
			33.136,
			33.169,
			33.229,
			33.247,
			33.293,
			33.355,
			33.415,
			33.467,
			33.539,
			33.572,
			33.603,
			33.664,
			33.725,
			33.79,
			33.85,
			33.898,
			33.912,
			33.973,
			34.005,
			34.036,
			34.115,
			34.162,
			34.224,
			34.286,
			34.331,
			34.348,
			34.408,
			34.438,
			34.472,
			34.533,
			34.594,
			34.655,
			34.718,
			34.764,
			34.842,
			34.874,
			34.905,
			34.983,
			35.028,
			35.09,
			35.152,
			35.2,
			35.214,
			35.275,
			35.306,
			35.338,
			35.415,
			35.462,
			35.529,
			35.587,
			35.647,
			35.708,
			35.739,
			35.773,
			35.834,
			35.895,
			35.959,
			36.02,
			36.065,
			36.083,
			36.145,
			36.174,
			36.207,
			36.268,
			36.284,
			36.33,
			36.393,
			36.454,
			36.5,
			36.516,
			36.579,
			36.611,
			36.641,
			36.719,
			36.765,
			36.825,
			36.889,
			36.952,
			37.012,
			37.044,
			37.076,
			37.138,
			37.2,
			37.259,
			37.321,
			37.369,
			37.383,
			37.446,
			37.478,
			37.509,
			37.591,
			37.634,
			37.695,
			37.755,
			37.802,
			37.82,
			37.879,
			37.912,
			37.942,
			38.004,
			38.02,
			38.067,
			38.129,
			38.191,
			38.236,
			38.315,
			38.347,
			38.375,
			38.455,
			38.499,
			38.564,
			38.625,
			38.672,
			38.688,
			38.749,
			38.781,
			38.812,
			38.889,
			38.936,
			38.997,
			39.057,
			39.106,
			39.12,
			39.184,
			39.213,
			39.245,
			39.307,
			39.368,
			39.43,
			39.491,
			39.54,
			39.616,
			39.679,
			39.742,
			39.758,
			39.804,
			39.864,
			39.925,
			39.988,
			40.05,
			40.081,
			40.112,
			40.191,
			40.236,
			40.299,
			40.362,
			40.406,
			40.422,
			40.486,
			40.516,
			40.548,
			40.606,
			40.625,
			40.671,
			40.732,
			40.796,
			40.842,
			40.92,
			40.95,
			40.981,
			41.043,
			41.059,
			41.105,
			41.167,
			41.229,
			41.292,
			41.353,
			41.383,
			41.415,
			41.492,
			41.538,
			41.6,
			41.662,
			41.723,
			41.787,
			41.817,
			41.848,
			41.909,
			41.973,
			42.035,
			42.097,
			42.142,
			42.16,
			42.22,
			42.251,
			42.283,
			42.361,
			42.407,
			42.468,
			42.532,
			42.576,
			42.593,
			42.656,
			42.687,
			42.716,
			42.795,
			42.842,
			42.904,
			42.964,
			43.012,
			43.089,
			43.119,
			43.15,
			43.213,
			43.275,
			43.336,
			43.398,
			43.461,
			43.524,
			43.553,
			43.584,
			43.648,
			43.664,
			43.708,
			43.774,
			43.833,
			43.879,
			43.895,
			43.956,
			43.989,
			44.02,
			44.08,
			44.096,
			44.142,
			44.206,
			44.267,
			44.33,
			44.391,
			44.421,
			44.452,
			44.515,
			44.578,
			44.639,
			44.701,
			44.747,
			44.763,
			44.825,
			44.855,
			44.888,
			44.951,
			44.964,
			45.012,
			45.075,
			45.136,
			45.183,
			45.258,
			45.292,
			45.322,
			45.384,
			45.445,
			45.509,
			45.568,
			45.616,
			45.633,
			45.691,
			45.724,
			45.756,
			45.818,
			45.836,
			45.879,
			45.941,
			46.005,
			46.049,
			46.066,
			46.127,
			46.156,
			46.189,
			46.25,
			46.268,
			46.315,
			46.374,
			46.437,
			46.483,
			46.562,
			46.592,
			46.623,
			46.702,
			46.748,
			46.809,
			46.87,
			46.919,
			46.934,
			46.995,
			47.028,
			47.058,
			47.088
		]
	],
	'5297_5298_7982': [
		[
			128.5217
		],
		[
			0
		],
		[
			0,
			1.25141346,
			2.65659857,
			4.063682,
			5.472433,
			6.88274765,
			8.294754,
			9.708373,
			11.1238317,
			12.5406971,
			13.9593182,
			15.3795195,
			16.8014221,
			18.22506,
			19.650528,
			21.0776443,
			22.5065,
			23.9373531,
			25.36999,
			26.8042831,
			28.2403088,
			29.67743,
			31.1164169,
			32.5569534,
			33.98948,
			35.3961945,
			38.2140732,
			39.6530952,
			41.128788,
			42.60624,
			44.085556,
			45.5664558,
			47.04903,
			48.53347,
			50.0196075,
			51.4900055,
			52.9539757,
			55.8834763,
			57.3405075,
			60.2592926,
			61.7279472,
			64.6730042,
			66.14902,
			67.62786,
			69.10838,
			70.59033,
			72.07381,
			73.55888,
			75.04542,
			76.53362,
			78.02342,
			79.51471,
			81.00748,
			82.50165,
			83.99732,
			85.49441,
			86.99341,
			88.49385,
			89.9958344,
			91.499176,
			93.0038452,
			94.50997,
			96.0177155,
			97.52709,
			99.03783,
			100.550179,
			102.063591,
			103.578506,
			105.09491,
			106.61293,
			108.132339,
			109.653336,
			111.175743,
			112.6998,
			114.225143,
			115.7521,
			117.280396,
			118.810181,
			120.34153,
			121.874023,
			123.408119,
			126.480637,
			129.559891,
			131.101685,
			132.645081,
			134.18988,
			135.736084,
			137.2832,
			138.8318,
			140.38176,
			141.932953,
			143.485443,
			145.039291,
			148.1511,
			149.709061,
			151.268127,
			152.828552,
			154.390259,
			155.953384,
			157.51799,
			159.084045,
			160.651413,
			162.219849,
			163.789383,
			166.932739,
			168.50647,
			170.08139,
			171.65773,
			173.235855,
			174.81517,
			176.395767,
			177.977509,
			179.560654,
			181.144913,
			182.730911,
			184.318176,
			185.906525,
			187.495728,
			189.085968,
			190.677322,
			192.2677,
			193.8586,
			195.450943,
			197.044235,
			198.638474,
			200.233948,
			201.8307,
			203.428986,
			205.028381,
			206.62915,
			209.837036,
			211.442642,
			213.051346,
			214.6612,
			216.272125,
			217.884644,
			219.498383,
			221.113129,
			222.7289,
			224.345749,
			225.963745,
			227.582962,
			229.203186,
			230.824554,
			234.070282,
			235.69458,
			237.320221,
			238.946808,
			240.574524,
			242.203461,
			245.464722,
			247.097031,
			248.730408,
			250.365021,
			252.000763,
			253.637054,
			255.2747,
			256.913116,
			258.552429,
			260.192749,
			261.8342,
			263.4762,
			265.119263,
			266.763275,
			268.406738,
			270.050537,
			271.6955,
			273.3411,
			274.987244,
			276.634277,
			278.2824,
			279.931152,
			281.580872,
			283.232178,
			284.8849,
			286.538574,
			288.1936,
			289.8498,
			291.507263,
			293.165436,
			294.824463,
			296.4848,
			298.146179,
			299.808655,
			301.471954,
			303.136322,
			304.801575,
			306.468079,
			308.135437,
			309.803528,
			311.472839,
			314.81427,
			318.1603,
			321.5089,
			323.181763,
			326.51767,
			328.178,
			329.8312,
			331.4769,
			333.113739,
			334.742126,
			336.361084,
			337.9707,
			339.5707,
			341.1606,
			342.740967,
			344.311523,
			345.872681,
			347.424133,
			348.966034,
			350.498932,
			352.0227,
			353.537354,
			356.538635,
			358.02597,
			359.503174,
			360.970673,
			362.4284,
			363.876221,
			365.314545,
			366.743164,
			368.1623,
			369.5716,
			370.971436,
			372.361877,
			373.74292,
			375.114746,
			376.476624,
			377.829071,
			379.172577,
			380.507233,
			381.83313,
			383.14978,
			384.4571,
			385.754883,
			387.043243,
			388.322266,
			389.592346,
			390.853638,
			392.1064,
			393.350739,
			394.5869,
			395.81488,
			397.0344,
			398.245636,
			399.468262,
			400.6626,
			401.848633,
			403.0268,
			404.19693,
			405.3589,
			406.493225,
			407.638336,
			408.775269,
			411.0423,
			412.153229,
			413.255432,
			414.346558,
			415.42688,
			416.4986,
			417.561737,
			418.6166,
			419.642273,
			420.657043,
			421.66507,
			423.6614,
			424.637817,
			425.6048,
			426.565643,
			428.469849,
			429.411438,
			430.345642,
			431.2734,
			433.109955,
			434.019928,
			434.925262,
			435.826263,
			436.72467,
			437.6213,
			438.517548,
			439.415863,
			440.3166,
			441.219055,
			442.123932,
			443.942261,
			445.788055,
			446.7145,
			447.643433,
			448.574768,
			449.512329,
			450.456177,
			451.4022,
			452.3501,
			453.299927,
			454.251251,
			455.2039,
			456.157928,
			457.1129,
			458.068481,
			459.024567,
			459.978241,
			460.9325,
			461.887756,
			462.843567,
			463.799744,
			464.7569,
			465.714539,
			466.67157,
			467.6279,
			468.58313,
			469.537476,
			470.489777,
			471.43927,
			472.386353,
			473.331543,
			474.2758,
			475.220245,
			476.164063,
			477.107422,
			478.050385,
			478.993225,
			479.9369,
			480.879883,
			481.821869,
			482.763,
			483.703522,
			484.644562,
			485.5859,
			486.526581,
			487.467072,
			488.407562,
			489.3256,
			490.2893,
			491.2301,
			492.1711,
			493.112549,
			494.0548,
			495.943268,
			496.888,
			497.832977,
			498.7785,
			499.72644,
			500.677856,
			501.629,
			502.580078,
			503.5312,
			504.3748,
			505.4336,
			506.383728,
			507.3329,
			508.2813,
			509.2294,
			510.177063,
			511.123962,
			512.0697,
			513.014832,
			514.903564,
			515.8484,
			517.7302,
			518.6675,
			519.4615,
			520.5339,
			522.3884,
			523.311768,
			524.2328,
			525.14,
			526.039368,
			526.937256,
			527.8336,
			528.728638,
			529.5641,
			530.5198,
			531.415466,
			532.309448,
			533.2018,
			534.0926,
			534.9832,
			535.874,
			536.762756,
			537.6495,
			538.534241,
			539.417236,
			540.30304,
			541.1885,
			542.0724,
			543.8363,
			544.6337,
			545.6,
			546.481567,
			547.3625,
			548.242859,
			549.1233,
			550.0038,
			551.76416,
			552.644348,
			553.525,
			554.406067,
			555.2871,
			556.1677,
			557.048645,
			557.93,
			558.8121,
			559.6949,
			560.5754,
			561.455933,
			562.3374,
			563.220032,
			564.104065,
			564.9896,
			565.8773,
			566.766357,
			567.657043,
			568.5491,
			569.4426,
			570.3414,
			572.149231,
			573.0545,
			573.9607,
			574.868,
			575.774536,
			576.681335,
			577.5886,
			578.49646,
			579.4049,
			581.229736,
			582.144043,
			583.058533,
			583.9733,
			584.8887,
			585.805,
			586.7214,
			587.637939,
			589.4716,
			590.389648,
			591.3086,
			592.2275,
			594.0655,
			594.984863,
			595.904,
			596.8226,
			598.659668,
			599.578369,
			600.498047,
			601.42,
			602.3415,
			603.2627,
			604.18396,
			605.1054,
			606.024658,
			606.9432,
			607.8611,
			609.6972,
			610.615845,
			611.535767,
			612.4553,
			613.3746,
			614.293457,
			615.212341,
			617.0591,
			617.9823,
			618.9056,
			620.7517,
			621.6736,
			622.595032,
			623.515869,
			624.436646,
			625.3574,
			626.279663,
			627.2018,
			628.123657,
			629.0455,
			629.967041,
			630.889648,
			631.813232,
			632.7367,
			633.659241,
			634.581665,
			635.503845,
			637.3508,
			638.274048,
			639.1971,
			640.1202,
			641.0457,
			641.9751,
			642.9042,
			643.8334,
			644.7632,
			645.6939,
			646.620544,
			648.4749,
			649.403442,
			650.3331,
			651.2671,
			652.2041,
			653.142334,
			654.0815,
			655.0221,
			655.7731,
			656.908936,
			657.854248,
			658.801,
			659.7494,
			660.6996,
			661.654053,
			662.610352,
			663.5675,
			664.5256,
			665.484863,
			666.4431,
			667.4002,
			668.3579,
			669.31665,
			671.238037,
			672.2016,
			673.165833,
			674.130859,
			675.0974,
			676.06604,
			677.0393,
			678.0137,
			678.9892,
			679.9666,
			680.9343,
			681.929932,
			682.9154,
			684.891357,
			685.8827,
			686.8706,
			687.8601,
			688.8512,
			689.8445,
			690.839539,
			691.844238,
			692.8513,
			693.858643,
			694.8667,
			695.8754,
			696.8819,
			697.8873,
			699.8987,
			700.904968,
			701.915649,
			702.927063,
			704.950745,
			705.9635,
			706.9759,
			707.9885,
			710.017334,
			711.033264,
			713.0724,
			715.111145,
			716.1309,
			717.15094,
			718.171265,
			720.213867,
			721.2362,
			722.2599,
			723.2842,
			724.3087,
			725.3334,
			726.2587,
			727.3805,
			728.4036,
			729.4276,
			730.4528,
			731.4808,
			732.516663,
			733.5553,
			734.5967,
			735.6412,
			736.6873,
			737.7328,
			738.7804,
			739.8302,
			740.882263,
			741.936951,
			742.9941,
			744.0534,
			745.115,
			746.179138,
			747.2424,
			748.3064,
			749.3721,
			750.439941,
			751.5096,
			752.588745,
			753.6713,
			754.756,
			755.842834,
			756.935364,
			758.0382,
			759.125366,
			760.233765,
			761.344849,
			762.462158,
			764.7079,
			765.8349,
			766.9648,
			768.0988,
			769.2355,
			770.3748,
			771.5167,
			772.66394,
			773.834167,
			774.9686,
			776.1248,
			777.284851,
			778.4502,
			779.6178,
			780.7875,
			781.9787,
			783.1624,
			784.3288,
			785.536,
			786.706543,
			787.9073,
			789.113342,
			790.3218,
			791.5332,
			792.749451,
			793.9701,
			795.1932,
			796.419067,
			798.876648,
			800.108765,
			801.3431,
			802.5817,
			803.8273,
			805.074463,
			806.3238,
			807.5978,
			808.858154,
			810.120239,
			811.3846,
			813.893066,
			816.425842,
			817.7151,
			820.255,
			822.7755,
			824.057739,
			826.5643,
			827.8463,
			829.075,
			830.3264,
			831.579956,
			832.8336,
			834.104736,
			835.336,
			837.8486,
			839.1126,
			840.37854,
			841.6462,
			842.9391,
			844.1985,
			845.4809,
			846.764954,
			848.052,
			850.637451,
			851.932556,
			853.229065,
			854.54834,
			855.8473,
			857.1483,
			859.7593,
			861.0438,
			862.3715,
			863.675,
			864.9768,
			866.2798,
			867.582764,
			868.857056,
			870.1498,
			871.4434,
			872.7389,
			874.0355,
			876.634033,
			877.9364,
			879.2427,
			880.549438,
			881.8557,
			883.163269,
			884.4751,
			885.7885,
			887.10376,
			888.4205,
			889.7383,
			891.057739,
			892.378662,
			893.701355,
			895.0263,
			896.3524,
			899.0091,
			900.340149,
			901.672363,
			903.006042,
			904.339661,
			905.6742,
			908.346863,
			909.6855,
			911.025635,
			912.3669,
			913.6985,
			915.0536,
			916.3989,
			917.7452,
			919.0927,
			920.4407,
			921.790039,
			924.491638,
			925.8433,
			927.1964,
			928.551147,
			929.906738,
			931.2639,
			932.622742,
			933.983337,
			935.3452,
			936.70874,
			938.0742,
			939.441345,
			940.810669,
			942.181641,
			943.554138,
			944.9285,
			946.3042,
			947.6816,
			949.060547,
			950.4407,
			951.8224,
			953.2056,
			954.5902,
			955.9763,
			958.752869,
			960.144,
			961.5371,
			962.931763,
			964.328,
			965.726563,
			967.1269,
			968.528564,
			969.932,
			971.337036,
			972.743652,
			974.152,
			975.562134,
			976.9739,
			978.387268,
			979.8024,
			981.2189,
			982.636841,
			984.0565,
			985.478,
			986.901245,
			989.7525,
			991.1802,
			992.6094,
			994.0401,
			995.472351,
			996.906,
			998.3412,
			1001.21631,
			1002.65631,
			1004.09778,
			1005.54083,
			1006.9856,
			1008.43176,
			1009.87946,
			1011.32855,
			1012.77917,
			1015.68475,
			1017.13989,
			1018.59656,
			1020.05457,
			1021.51416,
			1022.9751,
			1024.43762,
			1025.90137,
			1027.36658,
			1028.83325,
			1030.30139,
			1031.77112,
			1033.24231,
			1034.715,
			1036.18872,
			1037.66333,
			1040.61719,
			1042.09656,
			1043.57727,
			1045.05933,
			1046.543,
			1048.02808,
			1049.5144,
			1051.0022,
			1052.4917,
			1055.47473,
			1056.96826,
			1058.46313,
			1059.95923,
			1061.45691,
			1062.95569,
			1064.45581,
			1065.95728,
			1067.46021,
			1068.96436,
			1070.47,
			1071.97668,
			1073.48486,
			1074.99414,
			1076.50464,
			1078.0166,
			1079.52991,
			1081.04443,
			1082.55981,
			1084.07654,
			1085.59448,
			1087.11365,
			1088.634,
			1090.15576,
			1091.67883,
			1093.20325,
			1094.72876,
			1096.25562,
			1097.78381,
			1099.31335,
			1100.84412,
			1102.37634,
			1103.90979,
			1105.44446,
			1106.98035,
			1108.51733,
			1110.05554,
			1111.595,
			1113.13574,
			1114.67773,
			1116.221,
			1117.76526,
			1119.31079,
			1120.85754,
			1122.40564,
			1123.95483,
			1125.50549,
			1127.05713,
			1128.60962,
			1130.16333,
			1131.71826,
			1133.27454,
			1134.83215,
			1136.39087,
			1137.95093,
			1139.512,
			1141.0741,
			1142.63745,
			1144.20166,
			1145.76709,
			1147.3335,
			1148.901,
			1150.46948,
			1152.03931,
			1155.17834,
			1156.74939,
			1158.31787,
			1159.88513,
			1161.45361,
			1163.02441,
			1164.59778,
			1166.17224,
			1167.74854,
			1169.32849,
			1170.90942,
			1172.4917,
			1174.0769,
			1175.663,
			1177.25037,
			1178.83936,
			1180.42944,
			1182.02075,
			1183.61218,
			1185.20288,
			1186.79358,
			1188.38538,
			1189.97815,
			1191.57178,
			1193.16638,
			1194.76172,
			1196.35815,
			1197.95544,
			1199.554,
			1201.15344,
			1202.75378,
			1205.95642,
			1207.55933,
			1209.16321,
			1210.76831,
			1212.37427,
			1213.98132,
			1215.58948,
			1217.19849,
			1218.80835,
			1220.41919,
			1222.03113,
			1225.25916,
			1226.87524,
			1228.49573,
			1230.12769,
			1231.761,
			1233.39539,
			1236.66711,
			1238.30457,
			1239.93909,
			1241.57458,
			1243.2113,
			1244.84253,
			1246.47437,
			1248.107,
			1249.738,
			1251.369,
			1253.00085,
			1254.62964,
			1256.25708,
			1257.8855,
			1259.5166,
			1261.15063,
			1262.78564,
			1264.42212,
			1266.06055,
			1267.69873,
			1269.33545,
			1270.97046,
			1272.6012,
			1274.22681,
			1275.84534,
			1277.456,
			1279.05835,
			1280.652,
			1282.23682,
			1283.81213,
			1285.3772,
			1286.93225,
			1288.47754,
			1290.01331,
			1291.54,
			1293.058,
			1294.56738,
			1296.068,
			1297.55957,
			1299.04224,
			1300.5155,
			1301.97949,
			1303.4342,
			1304.87988,
			1306.315,
			1307.74146,
			1309.15918,
			1310.56787,
			1311.96729,
			1314.73755,
			1316.1095,
			1318.82483,
			1321.50146,
			1322.82642,
			1325.44788,
			1326.73523,
			1328.01392,
			1329.28357,
			1330.54517,
			1331.80054,
			1333.04639,
			1334.28308,
			1335.51147,
			1336.73572,
			1337.95166,
			1339.15955,
			1340.35938,
			1341.55249,
			1342.73743,
			1343.91455,
			1345.084,
			1346.24609,
			1347.4,
			1348.54517,
			1350.808,
			1351.925,
			1354.1333,
			1355.225,
			1356.30823,
			1357.3822,
			1358.44727,
			1359.50366,
			1360.55127,
			1361.59082,
			1362.62244,
			1364.66077,
			1366.66345,
			1367.65039,
			1369.5968,
			1370.55579,
			1371.50073,
			1372.43335,
			1373.357,
			1374.27234,
			1375.17981,
			1376.08044,
			1376.97534,
			1377.86316,
			1378.74365,
			1379.61719,
			1380.497,
			1381.35474,
			1382.20544,
			1383.04785,
			1383.88208,
			1384.70825,
			1385.52637,
			1386.33032,
			1387.11108,
			1387.88562,
			1388.65442,
			1389.41748,
			1390.1748,
			1391.65967,
			1392.38391,
			1393.105,
			1393.82373,
			1394.54114,
			1395.25854,
			1395.97668,
			1396.6969,
			1397.419,
			1398.14307,
			1398.86951,
			1399.59814,
			1400.32922,
			1401.06274,
			1401.79834,
			1402.53516,
			1403.27368,
			1404.0144,
			1404.75671,
			1406.2478,
			1406.99731,
			1407.74854,
			1408.501,
			1409.25488,
			1410.0105,
			1410.76782,
			1411.52722,
			1412.29114,
			1413.05615,
			1413.822,
			1414.58887,
			1416.12659,
			1416.89856,
			1417.67273,
			1418.44763,
			1419.22351,
			1420.00061,
			1420.77893,
			1421.559,
			1423.125,
			1423.909,
			1425.47925,
			1427.05518,
			1428.6377,
			1429.42969,
			1430.22241,
			1431.01624,
			1431.811,
			1432.61011,
			1433.4093,
			1434.20886,
			1435.009,
			1435.81,
			1436.61218,
			1437.41663,
			1438.22229,
			1439.028,
			1439.834,
			1440.64087,
			1441.44873,
			1443.06921,
			1443.88,
			1444.69116,
			1445.50293,
			1446.31543,
			1447.13037,
			1447.95178,
			1448.77319,
			1449.59473,
			1450.41663,
			1451.23926,
			1451.94446,
			1452.89063,
			1453.71777,
			1454.54492,
			1455.37207,
			1457.02844,
			1457.86584,
			1458.70325,
			1460.37659,
			1461.21326,
			1462.05017,
			1462.88477,
			1463.7179,
			1464.55029,
			1465.38184,
			1466.21313,
			1467.04431,
			1467.88062,
			1468.717,
			1469.55225,
			1470.38647,
			1471.22046,
			1472.05444,
			1472.89124,
			1473.72778,
			1474.56335,
			1475.398,
			1476.23193,
			1477.06543,
			1477.89819,
			1478.729,
			1479.55884,
			1481.21741,
			1482.04712,
			1482.88232,
			1483.72009,
			1484.557,
			1485.39331,
			1486.22949,
			1487.0658,
			1487.90149,
			1488.73511,
			1489.56824,
			1490.40088,
			1491.2334,
			1492.06592,
			1492.89941,
			1493.7334,
			1495.39893,
			1496.23132,
			1497.06372,
			1500.388,
			1501.21838,
			1502.04932,
			1502.716,
			1503.71289,
			1504.545,
			1505.37769,
			1506.211,
			1507.04529,
			1507.73547,
			1508.70972,
			1510.377,
			1512.05481,
			1512.898,
			1514.59167,
			1515.4447,
			1516.30188,
			1517.16309,
			1518.02832,
			1518.898,
			1519.77173,
			1520.64941,
			1521.53149,
			1522.41724,
			1523.30664,
			1524.19849,
			1525.09375,
			1525.99243,
			1527.79968,
			1528.70911,
			1529.62268,
			1530.53955,
			1531.46021,
			1532.38416,
			1534.24487,
			1535.181,
			1536.12061,
			1537.06323,
			1538.958,
			1540.84839,
			1541.80615,
			1542.76709,
			1543.73071,
			1544.697,
			1545.66626,
			1546.63867,
			1547.614,
			1548.59229,
			1550.55713,
			1551.544,
			1552.53357,
			1553.526,
			1554.52246,
			1555.52173,
			1557.52832,
			1558.53552,
			1559.54419,
			1560.55408,
			1561.56348,
			1562.571,
			1563.57532,
			1564.57434,
			1565.56763,
			1566.55371,
			1567.53235,
			1568.50269,
			1569.46411,
			1570.41626,
			1572.29346,
			1573.21851,
			1574.135,
			1575.04272,
			1575.94189,
			1576.83276,
			1577.71509,
			1578.58911,
			1579.45459,
			1580.31189,
			1581.17468,
			1582.84692,
			1583.672,
			1584.491,
			1585.30518,
			1586.11548,
			1586.9231,
			1587.72876,
			1588.53369,
			1589.33875,
			1590.14478,
			1590.95264,
			1592.57568,
			1593.39111,
			1594.20947,
			1595.03369,
			1595.862,
			1596.69385,
			1597.52893,
			1598.36731,
			1599.20874,
			1600.0575,
			1600.91235,
			1601.76978,
			1602.62988,
			1603.49243,
			1604.35718,
			1605.22229,
			1606.08679,
			1606.952,
			1607.81763,
			1608.68335,
			1609.549,
			1610.41992,
			1611.29333,
			1612.16577,
			1613.03711,
			1613.90723,
			1615.64709,
			1616.51648,
			1617.38257,
			1618.24536,
			1619.10449,
			1619.96008,
			1620.81323,
			1621.66223,
			1622.50732,
			1623.34851,
			1624.1864,
			1625.02136,
			1625.85522,
			1626.68591,
			1627.51318,
			1628.3374,
			1629.1593,
			1629.97913,
			1631.62,
			1632.438,
			1633.25488,
			1634.071,
			1634.88733,
			1635.70435,
			1636.52148,
			1637.33887,
			1638.15649,
			1638.97461,
			1639.79346,
			1640.614,
			1641.43579,
			1642.25757,
			1643.07935,
			1643.90161,
			1644.72412,
			1645.5481,
			1646.37317,
			1647.198,
			1648.02271,
			1648.84753,
			1649.67261,
			1650.18726,
			1651.32263,
			1652.14685,
			1652.97083,
			1653.79492,
			1654.61914,
			1655.44385,
			1656.27954,
			1657.11536,
			1657.95093,
			1658.78687,
			1659.62341,
			1660.46082,
			1661.30115,
			1662.1416,
			1662.9823,
			1663.82324,
			1664.66467,
			1665.50732,
			1666.35413,
			1667.20166,
			1668.04944,
			1668.89783,
			1669.74719,
			1670.59766,
			1671.45483,
			1672.31348,
			1673.17236,
			1674.03174,
			1674.89209,
			1675.75366,
			1676.61816,
			1677.48279,
			1678.34778,
			1679.21362,
			1680.08069,
			1680.91479,
			1681.81335,
			1682.6781,
			1683.54382,
			1684.41077,
			1685.279,
			1687.03613,
			1687.92151,
			1688.808,
			1689.69568,
			1690.58508,
			1691.47937,
			1692.37732,
			1693.27563,
			1694.1748,
			1695.07507,
			1695.97693,
			1696.88721,
			1697.799,
			1698.71094,
			1699.62317,
			1700.53625,
			1702.35889,
			1703.26807,
			1705.08716,
			1706.9082,
			1708.72754,
			1709.6366,
			1710.54565,
			1711.362,
			1712.35864,
			1713.26221,
			1714.165,
			1715.06775,
			1715.97,
			1716.86279,
			1717.73975,
			1718.61792,
			1720.37866,
			1721.26135,
			1722.14148,
			1723.02051,
			1723.90161,
			1724.78467,
			1726.55664,
			1727.4397,
			1728.32507,
			1729.213,
			1730.10376,
			1730.99731,
			1731.68457,
			1732.78931,
			1733.6897,
			1734.59375,
			1735.50183,
			1736.41406,
			1737.33,
			1738.25,
			1739.17407,
			1740.10229,
			1741.955,
			1742.8949,
			1743.83887,
			1744.78662,
			1745.73828,
			1746.6936,
			1747.65271,
			1748.61572,
			1749.58252,
			1750.55286,
			1751.52686,
			1752.50476,
			1753.48608,
			1754.471,
			1755.45947,
			1756.45166,
			1757.44739,
			1758.44653,
			1759.44873,
			1760.45361,
			1761.46069,
			1762.46667,
			1763.47913,
			1764.48975,
			1765.5,
			1766.50891,
			1767.51563,
			1768.519,
			1769.51807,
			1770.51208,
			1771.50049,
			1772.4823,
			1773.45728,
			1774.4248,
			1775.38428,
			1776.33508,
			1778.2135,
			1779.15735,
			1780.08008,
			1780.99744,
			1782.81091,
			1783.70593,
			1784.57629,
			1785.45325,
			1786.32227,
			1787.18311,
			1788.03589,
			1788.88013,
			1789.71606,
			1790.54346,
			1791.36255,
			1792.17334,
			1792.976,
			1793.77112,
			1795.346,
			1796.12915,
			1796.9115,
			1797.69482,
			1799.26941,
			1800.06177,
			1800.85742,
			1801.65686,
			1802.45959,
			1803.266,
			1804.0769,
			1804.89172,
			1805.71,
			1807.357,
			1808.18579,
			1809.01831,
			1809.85461,
			1810.69409,
			1811.53735,
			1812.384,
			1813.23413,
			1814.94482,
			1815.80542,
			1816.66943,
			1817.537,
			1818.408,
			1819.28247,
			1820.1604,
			1821.04175,
			1821.94116,
			1822.82959,
			1823.72119,
			1824.61548,
			1825.5127,
			1826.39722,
			1828.21826,
			1829.12439,
			1830.017,
			1830.94153,
			1831.8374,
			1832.75061,
			1833.66711,
			1835.51,
			1836.436,
			1837.36475,
			1838.29639,
			1839.23083,
			1840.16748,
			1842.04932,
			1842.99512,
			1843.94385,
			1844.89551,
			1845.85,
			1846.80774,
			1847.769,
			1848.73364,
			1849.70166,
			1850.67261,
			1851.64673,
			1852.624,
			1853.60449,
			1854.58826,
			1855.57556,
			1856.56616,
			1857.55994,
			1858.55688,
			1859.557,
			1860.5603,
			1861.56641,
			1862.57568,
			1863.58813,
			1864.60352,
			1866.64392,
			1867.66882,
			1868.69666,
			1869.72754,
			1871.79822,
			1872.83789,
			1873.88062,
			1874.92615,
			1877.02148,
			1878.0686,
			1879.11584,
			1880.165,
			1881.21631,
			1882.27026,
			1883.32739,
			1884.38721,
			1885.44946,
			1886.5144,
			1887.5824,
			1888.653,
			1889.7262,
			1890.80225,
			1891.881,
			1892.9624,
			1895.1333,
			1896.2229,
			1897.31519,
			1898.40991,
			1900.60742,
			1901.71021,
			1903.92407,
			1905.035,
			1906.14856,
			1907.26477,
			1908.38367,
			1909.50525,
			1910.62939,
			1911.75623,
			1912.886,
			1914.01843,
			1915.15369,
			1916.29163,
			1917.43225,
			1918.5752,
			1919.72058,
			1920.8689,
			1922.01978,
			1923.17346,
			1924.32983,
			1926.65112,
			1928.98242,
			1930.15186,
			1931.32373,
			1932.49841,
			1933.67566,
			1934.85522,
			1937.22253,
			1938.40991,
			1939.59985,
			1940.79224,
			1941.9873,
			1943.18481,
			1944.38489,
			1945.58765,
			1946.79272,
			1948.00037,
			1949.21057,
			1950.42334,
			1951.63867,
			1952.85632,
			1954.07642,
			1955.29919,
			1956.52441,
			1957.75208,
			1958.982,
			1960.21423,
			1961.44873,
			1962.68591,
			1963.92529,
			1965.16687,
			1966.41077,
			1967.6571,
			1968.90588,
			1970.157,
			1971.41028,
			1972.666,
			1973.92419,
			1975.18469,
			1976.44727,
			1977.71228,
			1978.97974,
			1981.521,
			1982.795,
			1984.07141,
			1985.35022,
			1986.63135,
			1989.20044,
			1990.48816,
			1991.77808,
			1993.07007,
			1994.36426,
			1995.66052,
			1998.26025,
			1999.5625,
			2000.86438,
			2002.16626,
			2003.47,
			2004.77539,
			2006.08289,
			2007.39233,
			2008.70386,
			2011.33313,
			2012.65063,
			2013.97034,
			2016.61536,
			2017.941,
			2020.59839,
			2021.93018,
			2023.26392,
			2024.59961,
			2025.93738,
			2027.27722,
			2028.61865,
			2029.96216,
			2031.30786,
			2032.6554,
			2034.00537,
			2036.71118,
			2038.06689,
			2039.42456,
			2040.78394,
			2043.50867,
			2044.87427,
			2047.61121,
			2048.98242,
			2050.35547,
			2051.73022,
			2053.10718,
			2054.48633,
			2055.86768,
			2057.25073,
			2060.023,
			2061.412,
			2062.80322,
			2064.19629,
			2065.59082,
			2066.98755,
			2068.38623,
			2069.78735,
			2071.19,
			2072.59375,
			2073.99854,
			2075.40356,
			2076.808,
			2078.211,
			2079.61133,
			2081.00757,
			2083.78418,
			2085.1626,
			2086.5332,
			2087.89551,
			2089.24854,
			2090.59253,
			2091.92725,
			2093.25244,
			2094.56836,
			2095.875,
			2097.17188,
			2099.738,
			2101.00757,
			2102.26782,
			2103.519,
			2104.76172,
			2105.99536,
			2107.2207,
			2108.437,
			2109.64551,
			2110.84546,
			2112.03687,
			2114.39453,
			2115.561,
			2116.71777,
			2117.86475,
			2120.13257,
			2121.25366,
			2123.469,
			2124.563,
			2125.648,
			2127.794,
			2128.854,
			2129.90527,
			2130.94824,
			2133.00586,
			2134.021,
			2135.02661,
			2136.02319,
			2137.98926,
			2138.959,
			2139.92,
			2140.872,
			2141.81567,
			2142.75049,
			2143.67676,
			2144.59473,
			2145.51953,
			2146.42114,
			2147.3147,
			2148.2002,
			2149.07764,
			2149.94678,
			2150.80737,
			2151.65942,
			2152.50317,
			2153.33838,
			2154.16626,
			2154.98657,
			2155.7998,
			2156.606,
			2157.40527,
			2158.198,
			2158.98438,
			2159.76465,
			2160.53955,
			2161.30933,
			2162.836,
			2163.58887,
			2165.09033,
			2165.84033,
			2166.591,
			2167.34326,
			2168.097,
			2168.852,
			2169.61084,
			2170.37354,
			2171.14063,
			2171.9126,
			2172.68921,
			2174.26172,
			2175.85547,
			2176.65967,
			2177.469,
			2178.285,
			2179.11,
			2179.94,
			2180.77441,
			2181.61377,
			2182.45776,
			2183.30615,
			2184.15576,
			2185.0083,
			2185.86279,
			2186.71973,
			2187.57886,
			2188.441,
			2189.312,
			2190.18384,
			2191.056,
			2192.80249,
			2194.55054,
			2195.42334,
			2196.296,
			2197.16846,
			2198.91455,
			2199.789,
			2200.6626,
			2201.53516,
			2202.40747,
			2203.27954,
			2204.15356,
			2205.02832,
			2205.90186,
			2206.77441,
			2207.647,
			2208.5188,
			2209.38721,
			2210.25317,
			2211.98022,
			2212.842,
			2213.70264,
			2214.56372,
			2215.42383,
			2216.282,
			2217.99438,
			2218.84937,
			2219.28052,
			2220.581,
			2221.45166,
			2222.32153,
			2223.19043,
			2224.05859,
			2224.928,
			2225.797,
			2226.66455,
			2227.53125,
			2228.397,
			2229.26245,
			2230.13037,
			2230.99878,
			2231.866,
			2232.73218,
			2233.59766,
			2234.463,
			2235.33032,
			2236.19727,
			2237.063,
			2237.92773,
			2238.792,
			2239.656,
			2240.52368,
			2241.39063,
			2242.25635,
			2243.12061,
			2243.984,
			2244.847,
			2245.70068,
			2246.55127,
			2247.40137,
			2248.25073,
			2249.10034,
			2249.95,
			2251.68652,
			2252.55566,
			2253.42383,
			2254.29126,
			2255.15869,
			2256.01855,
			2256.87354,
			2257.72754,
			2258.581,
			2259.434,
			2260.287,
			2261.14478,
			2262.00562,
			2262.865,
			2263.72314,
			2264.581,
			2265.43848,
			2265.94165,
			2267.154,
			2268.01123,
			2268.868,
			2269.72461,
			2270.581,
			2271.438,
			2272.296,
			2274.00781,
			2274.8623,
			2275.71582,
			2276.56836,
			2278.272,
			2279.121,
			2279.96875,
			2280.81567,
			2281.66187,
			2282.50537,
			2283.34814,
			2284.19165,
			2285.03638,
			2285.88232,
			2286.72974,
			2287.57935,
			2288.43262,
			2289.28979,
			2290.15063,
			2291.01514,
			2291.8833,
			2292.75537,
			2293.63281,
			2294.51343,
			2295.398,
			2297.17554,
			2298.06836,
			2299.85938,
			2300.7583,
			2301.65967,
			2302.56348,
			2303.471,
			2304.38257,
			2305.29639,
			2306.2124,
			2307.13086,
			2308.97559,
			2309.90259,
			2310.832,
			2311.764,
			2312.69873,
			2313.636,
			2314.56323,
			2315.49268,
			2316.42529,
			2317.36084,
			2318.29883,
			2319.24438,
			2320.20117,
			2321.16113,
			2322.12378,
			2323.08936,
			2324.05786,
			2325.03247,
			2326.0105,
			2326.99023,
			2327.97119,
			2328.95361,
			2329.93555,
			2330.91479,
			2332.871,
			2333.84961,
			2334.82837,
			2336.75171,
			2337.71484,
			2338.672,
			2339.62256,
			2340.56519,
			2341.51123,
			2342.46558,
			2344.3186,
			2345.233,
			2346.14,
			2347.04053,
			2347.93286,
			2348.817,
			2349.69287,
			2350.56128,
			2351.42285,
			2352.27881,
			2353.13037,
			2376.55737,
			2411.10547,
			2448.12158,
			2484.49878,
			2520.51,
			2550.93921,
			2583.33936,
			2615.187,
			2646.516,
			2678.977,
			2713.94824,
			2753.98828,
			2797.68262,
			2839.97461,
			2870.52832,
			2900.35425,
			2933.75244,
			2967.80786,
			3000.89185,
			3032.84277,
			3065.46021,
			3100.28369,
			3133.07251,
			3170.23584,
			3212.40771,
			3259.04736,
			3309.238,
			3362.62256,
			3419.076,
			3478.44873,
			3540.49023,
			3597.20215,
			3640.54688,
			3671.00122,
			3705.09863,
			3737.271,
			3770.48169,
			3806.58154,
			3842.8855,
			3881.71582,
			3925.04785,
			3971.7915,
			4021.09351,
			4060.77417,
			4089.55737,
			4119.39063,
			4148.80762,
			4177.555,
			4209.09,
			4243.924,
			4279.494,
			4314.337,
			4352.255,
			4393.95361,
			4438.751,
			4486.737,
			4537.573,
			4590.59863,
			4645.809,
			4703.078,
			4759.894,
			4803.5293,
			4835.911,
			4869.23633,
			4903.127,
			4937.09033,
			4974.765,
			5011.38965,
			5050.633,
			5089.26563,
			5128.149,
			5169.31934,
			5214.369,
			5264.786,
			5315.5,
			5370.155,
			5370.31836
		],
		[
			0,
			0.0151519775,
			0.0487060547,
			0.07823181,
			0.09423828,
			0.126754761,
			0.141311646,
			0.158874512,
			0.205841064,
			0.219680786,
			0.252761841,
			0.268356323,
			0.297897339,
			0.3294525,
			0.344955444,
			0.3614502,
			0.393981934,
			0.409515381,
			0.4405365,
			0.469650269,
			0.487792969,
			0.517608643,
			0.550231934,
			0.5637665,
			0.598770142,
			0.61227417,
			0.6608429,
			0.6910553,
			0.7237549,
			0.7394562,
			0.7696228,
			0.7849579,
			0.832473755,
			0.8476105,
			0.8625183,
			0.8965149,
			0.9115448,
			0.959625244,
			0.991134644,
			1.0383606,
			1.06967163,
			1.11528015,
			1.14811707,
			1.16264343,
			1.19567871,
			1.22720337,
			1.24259949,
			1.27313232,
			1.29101563,
			1.32189941,
			1.35046387,
			1.38258362,
			1.39903259,
			1.41349792,
			1.4445343,
			1.46260071,
			1.49415588,
			1.50958252,
			1.53964233,
			1.57154846,
			1.58592224,
			1.61997986,
			1.65122986,
			1.66726685,
			1.69839478,
			1.713913,
			1.74642944,
			1.76098633,
			1.793045,
			1.809021,
			1.85638428,
			1.87159729,
			1.88601685,
			1.91944885,
			1.95010376,
			1.96514893,
			1.99613953,
			2.030182,
			2.04367065,
			2.061203,
			2.0932312,
			2.13937378,
			2.18611145,
			2.23234558,
			2.24836731,
			2.26586914,
			2.2964325,
			2.31044,
			2.34147644,
			2.3744812,
			2.38999939,
			2.422989,
			2.43969727,
			2.48631287,
			2.519333,
			2.549591,
			2.567627,
			2.5977478,
			2.61366272,
			2.643692,
			2.6600647,
			2.69259644,
			2.7230835,
			2.73979187,
			2.78692627,
			2.817398,
			2.834549,
			2.8649292,
			2.89590454,
			2.91044617,
			2.94299316,
			2.96099854,
			3.00759888,
			3.02130127,
			3.0521698,
			3.06922913,
			3.09918213,
			3.115799,
			3.14666748,
			3.16117859,
			3.19477844,
			3.21124268,
			3.25830078,
			3.274109,
			3.29042053,
			3.32284546,
			3.350357,
			3.36799622,
			3.38391113,
			3.41439819,
			3.463501,
			3.4934845,
			3.50999451,
			3.54083252,
			3.5723114,
			3.58876038,
			3.62037659,
			3.634735,
			3.66773987,
			3.70028687,
			3.71333313,
			3.746811,
			3.76307678,
			3.79359436,
			3.84095764,
			3.87232971,
			3.88748169,
			3.9335022,
			3.95002747,
			3.964569,
			4.014099,
			4.044113,
			4.06063843,
			4.0927124,
			4.12348938,
			4.140274,
			4.17012024,
			4.1862793,
			4.21653748,
			4.24827576,
			4.263672,
			4.30863953,
			4.32467651,
			4.357193,
			4.371292,
			4.388443,
			4.41772461,
			4.45027161,
			4.48175049,
			4.49881,
			4.51382446,
			4.547821,
			4.577347,
			4.593399,
			4.60894775,
			4.64077759,
			4.67276,
			4.68701172,
			4.71795654,
			4.73606873,
			4.767502,
			4.79702759,
			4.814514,
			4.84608459,
			4.8605957,
			4.89160156,
			4.92514038,
			4.939865,
			4.971298,
			4.985901,
			5.018799,
			5.06672668,
			5.11381531,
			5.158844,
			5.19187927,
			5.23699951,
			5.269409,
			5.284439,
			5.3175354,
			5.333954,
			5.36422729,
			5.39677429,
			5.411804,
			5.444809,
			5.47373962,
			5.488617,
			5.51965332,
			5.53636169,
			5.567917,
			5.59890747,
			5.61543274,
			5.64855957,
			5.66346741,
			5.710968,
			5.74160767,
			5.77354431,
			5.79058838,
			5.818161,
			5.83581543,
			5.868103,
			5.89962769,
			5.931656,
			5.945175,
			5.96118164,
			5.993225,
			6.02450562,
			6.056015,
			6.07090759,
			6.101822,
			6.119278,
			6.135803,
			6.16540527,
			6.195938,
			6.21287537,
			6.243408,
			6.260437,
			6.29248047,
			6.32321167,
			6.35508728,
			6.36965942,
			6.38404846,
			6.4160614,
			6.449127,
			6.47911072,
			6.49667358,
			6.526184,
			6.544174,
			6.57472229,
			6.59124756,
			6.62208557,
			6.636917,
			6.66778564,
			6.69779968,
			6.728409,
			6.76235962,
			6.793457,
			6.80892944,
			6.840103,
			6.86894226,
			6.88497925,
			6.917053,
			6.950012,
			6.966675,
			6.996231,
			7.0287323,
			7.060272,
			7.091324,
			7.12409973,
			7.139511,
			7.18573,
			7.218216,
			7.23422241,
			7.265732,
			7.31407166,
			7.345047,
			7.35958862,
			7.39012146,
			7.40869141,
			7.441147,
			7.47149658,
			7.4853363,
			7.519165,
			7.54920959,
			7.56517029,
			7.61169434,
			7.66230774,
			7.691696,
			7.709305,
			7.740326,
			7.77047729,
			7.787079,
			7.819275,
			7.83476257,
			7.881729,
			7.89732361,
			7.92832947,
			7.945801,
			7.96128845,
			7.99342346,
			8.024185,
			8.040588,
			8.070007,
			8.10197449,
			8.116379,
			8.147873,
			8.164978,
			8.195969,
			8.211472,
			8.243439,
			8.258987,
			8.291,
			8.323853,
			8.339386,
			8.370178,
			8.384552,
			8.418625,
			8.448639,
			8.480652,
			8.494659,
			8.510681,
			8.543701,
			8.560211,
			8.590271,
			8.620651,
			8.653885,
			8.669235,
			8.687683,
			8.71843,
			8.749268,
			8.763596,
			8.797195,
			8.828644,
			8.844666,
			8.858719,
			8.90625,
			8.920944,
			8.9357605,
			8.984299,
			9.015335,
			9.045868,
			9.07988,
			9.093887,
			9.110428,
			9.14151,
			9.173477,
			9.189209,
			9.219742,
			9.236633,
			9.265503,
			9.297043,
			9.314606,
			9.344574,
			9.375656,
			9.391144,
			9.408661,
			9.436768,
			9.484711,
			9.531738,
			9.565323,
			9.594421,
			9.626709,
			9.658005,
			9.689438,
			9.720245,
			9.73587,
			9.767807,
			9.7842865,
			9.814316,
			9.845856,
			9.877884,
			9.894882,
			9.924942,
			9.941498,
			9.959457,
			9.987747,
			10.0195923,
			10.0355225,
			10.0655823,
			10.0970764,
			10.1136169,
			10.1446075,
			10.1626282,
			10.1937256,
			10.2091827,
			10.2404022,
			10.2865448,
			10.31749,
			10.33403,
			10.3640442,
			10.39563,
			10.4290924,
			10.4441071,
			10.4596558,
			10.5086823,
			10.5399628,
			10.570282,
			10.5862579,
			10.6321564,
			10.6506805,
			10.6656647,
			10.6972046,
			10.7262421,
			10.7427673,
			10.7607574,
			10.7913361,
			10.8215332,
			10.8391266,
			10.8684082,
			10.8855286,
			10.9144287,
			10.9469147,
			10.964447,
			10.993927,
			11.0112457,
			11.0570526,
			11.0873566,
			11.1191406,
			11.14946,
			11.1659546,
			11.1964264,
			11.2129211,
			11.2416534,
			11.2756958,
			11.2913971,
			11.3363037,
			11.3704071,
			11.3867035,
			11.41748,
			11.434021,
			11.4647675,
			11.4968262,
			11.5143585,
			11.560379,
			11.5908966,
			11.6237335,
			11.6398315,
			11.684967,
			11.7169952,
			11.7475128,
			11.7805939,
			11.8116455,
			11.8586426,
			11.8748322,
			11.8912811,
			11.9234009,
			11.9518738,
			11.9694977,
			11.9838409,
			12.0163727,
			12.0484467,
			12.0619965,
			12.111969,
			12.1430054,
			12.1590424,
			12.1905518,
			12.2210388,
			12.25061,
			12.266098,
			12.3146667,
			12.34668,
			12.363205,
			12.4092712,
			12.4417725,
			12.4732208,
			12.4878387,
			12.5196991,
			12.5343933,
			12.5814667,
			12.5989838,
			12.6124573,
			12.644989,
			12.6600189,
			12.6925507,
			12.7245941,
			12.7405243,
			12.7724457,
			12.7866516,
			12.8182373,
			12.8651886,
			12.896698,
			12.9132385,
			12.9432831,
			12.9597778,
			12.99231,
			13.0241089,
			13.0532532,
			13.06839,
			13.0847321,
			13.1173248,
			13.16481,
			13.1958618,
			13.2273712,
			13.2424927,
			13.2733917,
			13.2884369,
			13.3193359,
			13.3361664,
			13.3686066,
			13.3976135,
			13.43013,
			13.4457092,
			13.461731,
			13.4951935,
			13.5087128,
			13.5564575,
			13.5731354,
			13.5854492,
			13.6194305,
			13.6353607,
			13.6661987,
			13.7002716,
			13.7153015,
			13.76181,
			13.7938538,
			13.809906,
			13.8418884,
			13.87352,
			13.8888855,
			13.9200287,
			13.9364471,
			13.9661865,
			13.9976959,
			14.0287323,
			14.04512,
			14.0761566,
			14.1086731,
			14.1402283,
			14.1703339,
			14.187561,
			14.2187805,
			14.2498322,
			14.2817993,
			14.2968292,
			14.3274078,
			14.3448639,
			14.3618774,
			14.3929138,
			14.4254761,
			14.4402924,
			14.4842834,
			14.5319672,
			14.5494843,
			14.5630493,
			14.6095734,
			14.6405945,
			14.6730042,
			14.7032318,
			14.7351532,
			14.7657318,
			14.81514,
			14.86174,
			14.8946838,
			14.9102325,
			14.9412842,
			14.989624,
			15.0198059,
			15.03537,
			15.0678406,
			15.0983887,
			15.1159363,
			15.147934,
			15.1794586,
			15.1930237,
			15.210968,
			15.2410278,
			15.2715912,
			15.288147,
			15.3173981,
			15.3482361,
			15.3807831,
			15.396347,
			15.4118347,
			15.4580078,
			15.4750671,
			15.4909668,
			15.5192261,
			15.5360413,
			15.56897,
			15.5851135,
			15.6144714,
			15.6480865,
			15.677536,
			15.694046,
			15.7241058,
			15.7400513,
			15.7732239,
			15.8027954,
			15.8162079,
			15.8505249,
			15.86496,
			15.8980408,
			15.9105072,
			15.9445343,
			15.9590454,
			15.9906921,
			16.04065,
			16.070343,
			16.0852356,
			16.1158142,
			16.1352844,
			16.1657867,
			16.1968689,
			16.2128143,
			16.244873,
			16.2614288,
			16.2924042,
			16.30989,
			16.3410034,
			16.3701324,
			16.3887329,
			16.4187775,
			16.4362946,
			16.4683228,
			16.4998016,
			16.5308685,
			16.5466,
			16.5769043,
			16.5939941,
			16.6094513,
			16.6407471,
			16.6720123,
			16.6885071,
			16.719162,
			16.766571,
			16.79808,
			16.8135834,
			16.8421326,
			16.8740387,
			16.9062042,
			16.9229584,
			16.9368134,
			16.9683685,
			16.9987335,
			17.01567,
			17.0632019,
			17.1112366,
			17.1413422,
			17.18982,
			17.2348633,
			17.267868,
			17.315506,
			17.3454132,
			17.359436,
			17.3916931,
			17.4252167,
			17.4412079,
			17.4715576,
			17.48796,
			17.5347748,
			17.5648041,
			17.5988464,
			17.629364,
			17.64389,
			17.6604156,
			17.70816,
			17.7239838,
			17.7400055,
			17.7857361,
			17.81668,
			17.84871,
			17.86618,
			17.896225,
			17.9137726,
			17.9608,
			17.9908447,
			18.024353,
			18.0398712,
			18.0695038,
			18.10147,
			18.11699,
			18.1469269,
			18.1635132,
			18.1960754,
			18.21112,
			18.2440338,
			18.2913055,
			18.3203278,
			18.3536224,
			18.3694763,
			18.3854218,
			18.4143829,
			18.447464,
			18.4629669,
			18.492981,
			18.5265045,
			18.5425262,
			18.5731964,
			18.5894318,
			18.6180725,
			18.6495972,
			18.6656189,
			18.7131653,
			18.7451935,
			18.7626953,
			18.79277,
			18.8251038,
			18.8405151,
			18.88591,
			18.9191437,
			18.9488525,
			18.96489,
			18.99643,
			19.0104523,
			19.043,
			19.0745544,
			19.1080933,
			19.1235352,
			19.1393738,
			19.1853027,
			19.2174988,
			19.2460327,
			19.262558,
			19.2935486,
			19.3105774,
			19.3416748,
			19.35881,
			19.387085,
			19.42131,
			19.4369659,
			19.4828339,
			19.4968567,
			19.5123138,
			19.5448,
			19.5618134,
			19.592865,
			19.6089325,
			19.6398926,
			19.6834717,
			19.7025757,
			19.71701,
			19.7484741,
			19.76503,
			19.8115387,
			19.84462,
			19.8756714,
			19.8914337,
			19.9239655,
			19.952774,
			19.9686737,
			19.98584,
			20.0318146,
			20.0467834,
			20.0642853,
			20.0948029,
			20.11084,
			20.1409454,
			20.1734161,
			20.1899414,
			20.2201233,
			20.23555,
			20.2693787,
			20.2847443,
			20.3163757,
			20.36203,
			20.4066162,
			20.4208679,
			20.4411316,
			20.4703522,
			20.4847412,
			20.5173187,
			20.563797,
			20.5958252,
			20.609848,
			20.641861,
			20.67395,
			20.6882477,
			20.7191315,
			20.7360077,
			20.7680054,
			20.8145142,
			20.8444977,
			20.859024,
			20.89302,
			20.9095459,
			20.9404449,
			20.9699249,
			20.9862823,
			21.0178223,
			21.03534,
			21.0818024,
			21.0973358,
			21.1113739,
			21.1443787,
			21.1608887,
			21.1899414,
			21.23999,
			21.2696686,
			21.2854614,
			21.3154144,
			21.3473663,
			21.3639069,
			21.3954773,
			21.4109344,
			21.4432068,
			21.4887848,
			21.52005,
			21.5496826,
			21.58345,
			21.5999451,
			21.6144562,
			21.64502,
			21.6600189,
			21.69403,
			21.7095642,
			21.7416534,
			21.7727814,
			21.7888641,
			21.8216248,
			21.8521271,
			21.866684,
			21.8982239,
			21.91426,
			21.9467468,
			21.96228,
			21.9938354,
			22.0263367,
			22.04184,
			22.0726929,
			22.0865631,
			22.1190948,
			22.1364136,
			22.167984,
			22.2014618,
			22.2154846,
			22.2475128,
			22.2630157,
			22.29567,
			22.3246,
			22.3401489,
			22.3723145,
			22.3870544,
			22.41977,
			22.4379883,
			22.4822083,
			22.5008545,
			22.51529,
			22.5482788,
			22.56427,
			22.5948334,
			22.6104736,
			22.64238,
			22.6726074,
			22.688324,
			22.73294,
			22.7509155,
			22.779953,
			22.7970123,
			22.8129883,
			22.8455811,
			22.8600769,
			22.8888855,
			22.9246674,
			22.9549866,
			22.9697418,
			23.0016327,
			23.0331726,
			23.0487213,
			23.0641785,
			23.1107788,
			23.1453247,
			23.15921,
			23.1905975,
			23.2218323,
			23.2535858,
			23.26828,
			23.2837982,
			23.3168182,
			23.3473358,
			23.3643646,
			23.3947449,
			23.4252777,
			23.4425964,
			23.4741364,
			23.5021515,
			23.5215912,
			23.5353546,
			23.5672,
			23.59871,
			23.6292267,
			23.6462555,
			23.66275,
			23.7068176,
			23.7253571,
			23.7414246,
			23.773407,
			23.7866669,
			23.8181458,
			23.8356476,
			23.8661346,
			23.9137115,
			23.9447021,
			23.9617157,
			24.0083,
			24.0253,
			24.0402985,
			24.0735779,
			24.102356,
			24.1203918,
			24.1348572,
			24.16745,
			24.2139282,
			24.24498,
			24.2629852,
			24.2939911,
			24.3246613,
			24.3420868,
			24.3874054,
			24.4191589,
			24.44963,
			24.4666443,
			24.4974823,
			24.514679,
			24.5445251,
			24.5600586,
			24.5942078,
			24.6086884,
			24.6401215,
			24.6727753,
			24.6873932,
			24.718811,
			24.7362823,
			24.7682343,
			24.7993011,
			24.8157654,
			24.84793,
			24.8633118,
			24.89534,
			24.9259186,
			24.9429169,
			24.97293,
			24.9866486,
			25.0175781,
			25.0519714,
			25.0684814,
			25.0995178,
			25.1151276,
			25.14505,
			25.1625824,
			25.2086334,
			25.2249451,
			25.253952,
			25.2687988,
			25.28746,
			25.31845,
			25.3494263,
			25.365448,
			25.39737,
			25.4138947,
			25.4434814,
			25.4599762,
			25.4924774,
			25.5235138,
			25.5367584,
			25.5714722,
			25.5871887,
			25.6337738,
			25.6655121,
			25.7135773,
			25.7589111,
			25.793,
			25.8366089,
			25.8696136,
			25.8990479,
			25.9315338,
			25.9481049,
			25.978302,
			25.9938354,
			26.0263367,
			26.0408783,
			26.0705566,
			26.0883942,
			26.11914,
			26.1363525,
			26.16742,
			26.1989746,
			26.2138977,
			26.24849,
			26.2624664,
			26.2944641,
			26.3099823,
			26.35904,
			26.39035,
			26.4359436,
			26.4673157,
			26.497345,
			26.5318756,
			26.5453949,
			26.56189,
			26.5926361,
			26.6237335,
			26.65538,
			26.6846771,
			26.7337036,
			26.7797546,
			26.81128,
			26.85785,
			26.8747253,
			26.88852,
			26.923172,
			26.9370422,
			26.9700623,
			27.000946,
			27.0335083,
			27.0460052,
			27.0640411,
			27.0965271,
			27.1116486,
			27.1571045,
			27.17511,
			27.2064819,
			27.2206268,
			27.2540283,
			27.2698212,
			27.29921,
			27.3167267,
			27.34874,
			27.3637543,
			27.3943481,
			27.4113312,
			27.4588776,
			27.4884033,
			27.52118,
			27.53572,
			27.56717,
			27.5986176,
			27.6321411,
			27.6451569,
			27.6616974,
			27.6946716,
			27.7117,
			27.7418213,
			27.7731171,
			27.80371,
			27.8204651,
			27.8496552,
			27.8821411,
			27.8992767,
			27.913681,
			27.9606934,
			27.99176,
			28.00879,
			28.0517883,
			28.0682983,
			28.0848236,
			28.1183472,
			28.1478729,
			28.1654053,
			28.1959229,
			28.2119446,
			28.2424622,
			28.2900085,
			28.3220978,
			28.3371582,
			28.3686218,
			28.3840637,
			28.4181213,
			28.4476624,
			28.4806519,
			28.5106812,
			28.557251,
			28.5917969,
			28.6364136,
			28.684845,
			28.7168884,
			28.747879,
			28.7644043,
			28.7944946,
			28.8099365,
			28.8434753,
			28.8595123,
			28.8890228,
			28.9222565,
			28.9361725,
			28.96762,
			28.9840851,
			29.0141144,
			29.0446472,
			29.07521,
			29.0921936,
			29.1394653,
			29.1693726,
			29.2039032,
			29.23346,
			29.2508087,
			29.2818451,
			29.2978821,
			29.313385,
			29.3454132,
			29.3601379,
			29.39148,
			29.4245,
			29.45543,
			29.4711456,
			29.5020142,
			29.5186615,
			29.5637054,
			29.59822,
			29.6117249,
			29.6603088,
			29.6928253,
			29.72052,
			29.7518768,
			29.76889,
			29.786026,
			29.8151855,
			29.84822,
			29.8786926,
			29.8959045,
			29.9099121,
			29.94104,
			29.97348,
			29.9900055,
			30.0207825,
			30.0520325,
			30.0695343,
			30.0845642,
			30.1156158,
			30.1461182,
			30.1630249,
			30.1941681,
			30.2096558,
			30.2415924,
			30.28952,
			30.319809,
			30.350708,
			30.3807526,
			30.3988342,
			30.4282227,
			30.458252,
			30.475708,
			30.4914551,
			30.5230255,
			30.5356445,
			30.569809,
			30.5841827,
			30.6162262,
			30.6433716,
			30.6688232,
			30.710556,
			30.7403564,
			30.7696075,
			30.8669281,
			30.8989716,
			30.9140167,
			30.9434967,
			30.9610443,
			30.9926,
			31.02385,
			31.0543213,
			31.0686035,
			31.1027069,
			31.1322021,
			31.163681,
			31.2112427,
			31.240799,
			31.2883453,
			31.3208618,
			31.3369751,
			31.3618469,
			31.40091,
			31.4148865,
			31.4450226,
			31.476944,
			31.4924622,
			31.5240021,
			31.5371246,
			31.5701752,
			31.5850983,
			31.6180725,
			31.6656342,
			31.69867,
			31.7146912,
			31.7456665,
			31.77472,
			31.79274,
			31.8397675,
			31.8694916,
			31.8847961,
			31.91681,
			31.9656525,
			32.0093842,
			32.04251,
			32.0586548,
			32.08943,
			32.1219025,
			32.1358032,
			32.1687164,
			32.1992035,
			32.216217,
			32.26207,
			32.294632,
			32.3086548,
			32.3571777,
			32.3712921,
			32.4033356,
			32.4352722,
			32.4803162,
			32.4967957,
			32.5293427,
			32.5458221,
			32.5608368,
			32.5914,
			32.62178,
			32.6397247,
			32.6703949,
			32.7023468,
			32.71852,
			32.74881,
			32.76581,
			32.8123169,
			32.8426361,
			32.86017,
			32.9068756,
			32.9234924,
			32.9363556,
			32.96985,
			33.0007629,
			33.0173035,
			33.04785,
			33.06334,
			33.109375,
			33.1424255,
			33.17444,
			33.18817,
			33.21849,
			33.23549,
			33.26851,
			33.2975464,
			33.32956,
			33.34607,
			33.3635864,
			33.4111176,
			33.4572,
			33.47293,
			33.5044556,
			33.5191345,
			33.5357361,
			33.56877,
			33.5852966,
			33.616806,
			33.64586,
			33.6614075,
			33.6953735,
			33.72702,
			33.74341,
			33.7749634,
			33.7897034,
			33.8208771,
			33.8371277,
			33.86853,
			33.90068,
			33.9151154,
			33.9475861,
			33.97867,
			33.9941559,
			34.0092926,
			34.0411835,
			34.08635,
			34.11891,
			34.1337433,
			34.1647949,
			34.1963043,
			34.2133179,
			34.2428436,
			34.2749023,
			34.29236,
			34.3088837,
			34.34015,
			34.37117,
			34.38521,
			34.4169769,
			34.4485321,
			34.466095,
			34.49762,
			34.51323,
			34.5606842,
			34.590744,
			34.6244659,
			34.65358,
			34.6681824,
			34.685318,
			34.7317352,
			34.7462769,
			34.76326,
			34.7958374,
			34.8103333,
			34.8574066,
			34.87494,
			34.88864,
			34.9232178,
			34.9360657,
			34.9684753,
			34.985,
			35.017,
			35.0340576,
			35.0644836,
			35.0950165,
			35.1124573,
			35.1430664,
			35.1606,
			35.1914978,
			35.2223969,
			35.2378845,
			35.268158,
			35.283783,
			35.3328247,
			35.34723,
			35.36328,
			35.3957825,
			35.4123077,
			35.44133,
			35.4750671,
			35.4902039,
			35.5213623,
			35.5370636,
			35.5684662,
			35.583725,
			35.6147461,
			35.64833,
			35.66185,
			35.6938324,
			35.7249,
			35.74095,
			35.77394,
			35.7873535,
			35.820282,
			35.851944,
			35.8835,
			35.8985138,
			35.9140778,
			35.9449158,
			35.960968,
			35.99202,
			36.02162,
			36.0371246,
			36.06967,
			36.08577,
			36.1156464,
			36.1490936,
			36.16513,
			36.2116547,
			36.2441864,
			36.2737732,
			36.30681,
			36.3215027,
			36.33864,
			36.36885,
			36.3858948,
			36.41736,
			36.4498444,
			36.4633636,
			36.49698,
			36.5114,
			36.5449829,
			36.55896,
			36.59102,
			36.6395264,
			36.67192,
			36.7166443,
			36.7646179,
			36.81357,
			36.84236,
			36.85942,
			36.90744,
			36.92012,
			36.93611,
			36.969574,
			36.9994965,
			37.03209,
			37.0466,
			37.0650635,
			37.1086121,
			37.1411743,
			37.1733551,
			37.1886749,
			37.2182,
			37.2524261,
			37.267746,
			37.31534,
			37.3453064,
			37.3623352,
			37.3934021,
			37.40889,
			37.4401855,
			37.47026,
			37.4851074,
			37.5176239,
			37.5496674,
			37.564682,
			37.5962524,
			37.6112061,
			37.6422577,
			37.6597748,
			37.6900635,
			37.73584,
			37.76947,
			37.78386,
			37.8143921,
			37.845932,
			37.8784943,
			37.8949432,
			37.92552,
			37.9406128,
			37.97014,
			37.98929,
			38.0201874,
			38.0361938,
			38.06662,
			38.0996857,
			38.1312561,
			38.1467743,
			38.1752319,
			38.1907654,
			38.2088165,
			38.2404327,
			38.2714081,
			38.2875061,
			38.33342,
			38.35086,
			38.365448,
			38.3974762,
			38.4134064,
			38.4459534,
			38.461,
			38.4909973,
			38.5221252,
			38.53714,
			38.568573,
			38.6005554,
			38.6140747,
			38.66211,
			38.6941376,
			38.7106628,
			38.7417145,
			38.7889862,
			38.8182678,
			38.8506,
			38.8831177,
			38.89763,
			38.9131622,
			38.9437866,
			38.97484,
			38.9913635,
			39.0213928,
			39.0551147,
			39.07103,
			39.0862122,
			39.1165,
			39.1639862,
			39.1940765,
			39.2260742,
			39.24254,
			39.2875824,
			39.3191071,
			39.33528,
			39.3674,
			39.3838654,
			39.4298553,
			39.44438,
			39.460907,
			39.4926758,
			39.53714,
			39.5685272,
			39.58499,
			39.6176147,
			39.65007,
			39.6805878,
			39.6956024,
			39.725708,
			39.7586823,
			39.7912445,
			39.8212128,
			39.8513336,
			39.8683167,
			39.8993073,
			39.93132,
			39.94635,
			39.9623871,
			39.9949036,
			40.010376,
			40.04042,
			40.07219,
			40.0877838,
			40.1339874,
			40.1656342,
			40.1982727,
			40.2262878,
			40.2433167,
			40.276123,
			40.29248,
			40.3356934,
			40.36934,
			40.40074,
			40.4172821,
			40.449295,
			40.46231,
			40.51117,
			40.54225,
			40.570343,
			40.58763,
			40.61728,
			40.6364,
			40.6680145,
			40.6975555,
			40.7130737,
			40.74759,
			40.7615967,
			40.7946,
			40.8243256,
			40.841095,
			40.87152,
			40.9006958,
			40.916214,
			40.94818,
			40.96219,
			40.99623,
			41.0272522,
			41.04277,
			41.075943,
			41.091568,
			41.1363525,
			41.1695251,
			41.1989,
			41.2154236,
			41.2635345,
			41.29457,
			41.32518,
			41.340683,
			41.38765,
			41.41931,
			41.4363861,
			41.48175,
			41.4972534,
			41.5123138,
			41.5458527,
			41.56038,
			41.60817,
			41.624176,
			41.64067,
			41.6730652,
			41.7022247,
			41.7322235,
			41.74884,
			41.76477,
			41.81256,
			41.84407,
			41.85962,
			41.8909149,
			41.9367828,
			41.96869,
			42.01523,
			42.04538,
			42.0627747,
			42.09433,
			42.1088257,
			42.1578827,
			42.1726379,
			42.1854553,
			42.22061,
			42.2354736,
			42.26494,
			42.29648,
			42.3114929,
			42.34552,
			42.3605652,
			42.3916,
			42.42383,
			42.45613,
			42.4836273,
			42.51715,
			42.5627,
			42.59474,
			42.61122,
			42.64229,
			42.6758881,
			42.6904144,
			42.7363434,
			42.7684479,
			42.7998962,
			42.8144073,
			42.84697,
			42.8619843,
			42.894577,
			42.92656,
			42.9425354,
			42.97406,
			43.0020752,
			43.0196228,
			43.0506134,
			43.06613,
			43.0994568,
			43.12947,
			43.1459656,
			43.1614838,
			43.1935272,
			43.20952,
			43.2425537,
			43.275116,
			43.3037262,
			43.3210754,
			43.3367767,
			43.36772,
			43.39821,
			43.4147034,
			43.4450073,
			43.46248,
			43.49301,
			43.5240479,
			43.5550232,
			43.5695648,
			43.6010742,
			43.6341553,
			43.66414,
			43.6946564,
			43.7127228,
			43.7442169,
			43.78766,
			43.8194733,
			43.852356,
			43.8676758,
			43.8987579,
			43.9155426,
			43.962265,
			43.9943542,
			44.0238342,
			44.04036,
			44.072937,
			44.08853,
			44.12094,
			44.1503143,
			44.1657562,
			44.2147827,
			44.2447968,
			44.26184,
			44.3093567,
			44.34146,
			44.3881836,
			44.41844,
			44.4485,
			44.4650879,
			44.4973755,
			44.5131836,
			44.5441742,
			44.561203,
			44.5932465,
			44.62477,
			44.6395569,
			44.6843262,
			44.71637,
			44.747345,
			44.763855,
			44.8134155,
			44.84453,
			44.8907166,
			44.92383,
			44.9536438,
			44.9685974,
			44.98738,
			45.0177155,
			45.0491028,
			45.06581,
			45.1141357,
			45.14406,
			45.17531,
			45.1908264,
			45.2093353,
			45.24086,
			45.2731476,
			45.28923,
			45.32048,
			45.3359222,
			45.3670044,
			45.3989868,
			45.4125061,
			45.44452,
			45.4611359,
			45.4930878,
			45.53958,
			45.5697327,
			45.585907,
			45.616684,
			45.633728,
			45.666214,
			45.69725,
			45.712265,
			45.7427979,
			45.761795,
			45.7917328,
			45.8399048,
			45.87004,
			45.8989258,
			45.9164429,
			45.9480743,
			45.9773865,
			45.9949646,
			46.00894,
			46.0414429,
			46.0728455,
			46.08841,
			46.13452,
			46.16559,
			46.1841125,
			46.22911,
			46.2636566,
			46.30818,
			46.34079,
			46.37239,
			46.3878937,
			46.434845,
			46.468338,
			46.4983673,
			46.5294037,
			46.5604248,
			46.59247,
			46.6087646,
			46.64128,
			46.6875763,
			46.71907,
			46.737,
			46.767746,
			46.8002472,
			46.8157654,
			46.8448029,
			46.87816,
			46.8941345,
			46.90921,
			46.94171,
			46.9732361,
			46.9893036,
			47.0167847,
			47.0353851,
			47.08113,
			47.10025,
			47.131134,
			47.1461945,
			47.1631622,
			47.19217,
			47.2097,
			47.2417,
			47.271347,
			47.3044128,
			47.3200531,
			47.3347473,
			47.38115,
			47.41391,
			47.4450073,
			47.4907227,
			47.5218048,
			47.5366821,
			47.56772,
			47.5986938,
			47.61522,
			47.64563,
			47.66211,
			47.69261,
			47.7246552,
			47.74022,
			47.78534,
			47.83528,
			47.8807526,
			47.8952637,
			47.92929,
			47.9428253,
			47.9598236,
			47.9919128,
			48.02382,
			48.05374,
			48.08342,
			48.1025543,
			48.1169434,
			48.13446,
			48.1656647,
			48.1941376,
			48.226593,
			48.24362,
			48.2756958,
			48.29138,
			48.33972,
			48.3847046,
			48.41629,
			48.44783,
			48.46237,
			48.51082,
			48.5571747,
			48.57289,
			48.5871277,
			48.6174164,
			48.6499634,
			48.66652,
			48.6974945,
			48.7135,
			48.7445068,
			48.76155,
			48.79306,
			48.82379,
			48.8553467,
			48.8876038,
			48.9177246,
			48.95021,
			48.9647064,
			48.9992371,
			49.0132446,
			49.0608826,
			49.09137,
			49.109375,
			49.1398926,
			49.17343,
			49.18863,
			49.21643,
			49.23497,
			49.26596,
			49.29599,
			49.3141174,
			49.3445435,
			49.36061,
			49.3931122,
			49.4091339,
			49.4396362,
			49.4715576,
			49.503067,
			49.51657,
			49.54854,
			49.5640564,
			49.59709,
			49.6131,
			49.645813,
			49.67479,
			49.69316,
			49.72055,
			49.7397766,
			49.76985,
			49.80339,
			49.81778,
			49.850296,
			49.8818359,
			49.8973541,
			49.91339,
			49.94539,
			49.95987,
			49.99083,
			50.04016,
			50.07126,
			50.10292,
			50.1161652,
			50.1481934,
			50.179718,
			50.1957855,
			50.2137451,
			50.2437744,
			50.2593231,
			50.30635,
			50.32315,
			50.33873,
			50.38324,
			50.4002838,
			50.41533,
			50.4514465,
			50.46855,
			50.5023956,
			50.5175476,
			50.535965,
			50.57715,
			50.6083527,
			50.6245422,
			50.64044,
			50.68651,
			50.7172241,
			50.734726,
			50.76529,
			50.81093,
			50.8433838,
			50.8741455,
			50.891098,
			50.9224243,
			50.9364,
			50.9690552,
			50.99945,
			51.01599,
			51.0556335,
			51.072876,
			51.09761,
			51.1195374,
			51.15863,
			51.1616058,
			51.2026367,
			51.217804,
			51.2367859,
			51.2832031,
			51.2987366,
			51.31224,
			51.3587952,
			51.3913269,
			51.43695,
			51.4688721,
			51.5014343,
			51.5169067,
			51.5479431,
			51.5650177,
			51.59355,
			51.6105652,
			51.6421051,
			51.6900635,
			51.7198944,
			51.73616,
			51.76825,
			51.783783,
			51.82924,
			51.8463135,
			51.8603363,
			51.8928833,
			51.9243774,
			51.951355,
			51.9689178,
			51.983902,
			52.03244,
			52.0484619,
			52.063446,
			52.09549,
			52.1120148,
			52.1577,
			52.1734619,
			52.1895447,
			52.2173,
			52.23529,
			52.26828,
			52.3135834,
			52.3461151,
			52.35962,
			52.4087372,
			52.4404,
			52.4702759,
			52.5033264,
			52.518692,
			52.54921,
			52.5632324,
			52.61113,
			52.6566772,
			52.6736145,
			52.69124,
			52.71956,
			52.73636,
			52.7688,
			52.7843323,
			52.8153076,
			52.84494,
			52.8639374,
			53.59633,
			54.5980377,
			55.6069641,
			56.5969238,
			57.61119,
			58.61122,
			59.6081543,
			60.59604,
			61.6076965,
			62.6030121,
			63.5975952,
			64.59883,
			65.60333,
			66.59947,
			67.59567,
			68.6017456,
			69.5976257,
			70.60516,
			71.5969543,
			72.60991,
			73.60753,
			74.6087646,
			75.59805,
			76.6084,
			77.59871,
			78.5958252,
			79.5984039,
			80.5978851,
			81.59721,
			82.59569,
			83.60208,
			84.5966,
			85.611145,
			86.59674,
			87.6123047,
			88.60803,
			89.60693,
			90.59656,
			91.60739,
			92.60741,
			93.60591,
			94.59592,
			95.60857,
			96.60222,
			97.5961761,
			98.5966,
			99.61139,
			100.613815,
			101.597763,
			102.5961,
			103.603851,
			104.598907,
			105.598587,
			106.602112,
			107.599976,
			108.603134,
			109.598694,
			110.608185,
			111.605408,
			112.601517,
			113.598755,
			114.60614,
			115.6024,
			116.60199,
			117.598694,
			118.598694,
			119.606934,
			120.597275,
			121.60881,
			122.598755,
			123.597717,
			124.596054,
			125.601852,
			126.609329,
			127.597061,
			128.597672,
			128.5217
		]
	],
	'5297_9796_7982': [
		[
			128.284286
		],
		[
			0
		],
		[
			0,
			0.330981761,
			1.74141967,
			3.15353036,
			4.567517,
			5.982976,
			7.400109,
			8.818895,
			10.2393284,
			11.6614695,
			13.0851231,
			14.5104046,
			15.9374495,
			17.3659821,
			18.7964249,
			20.2285938,
			21.66237,
			23.0980186,
			24.5355682,
			25.97488,
			27.41576,
			28.8582,
			30.3019867,
			31.747488,
			33.19464,
			34.61785,
			36.0319061,
			37.44754,
			40.33865,
			41.82022,
			43.3034668,
			44.7884026,
			47.76358,
			49.25382,
			50.7415,
			52.2100945,
			53.6804276,
			55.15219,
			56.616848,
			58.080452,
			59.54563,
			62.4915771,
			63.969696,
			65.44931,
			66.93268,
			68.41773,
			69.90437,
			71.3925858,
			72.88208,
			74.3732147,
			75.86589,
			77.36022,
			78.85603,
			80.35335,
			81.85203,
			83.35204,
			84.85366,
			87.86177,
			89.36812,
			90.8759155,
			92.38496,
			95.4075851,
			96.9212,
			98.43642,
			99.95309,
			101.470932,
			102.990204,
			106.033043,
			107.556808,
			109.081924,
			110.608673,
			112.136917,
			113.666458,
			115.197624,
			116.7302,
			118.264023,
			119.799393,
			121.336159,
			122.8743,
			124.413712,
			125.954681,
			127.497818,
			129.042145,
			130.587875,
			132.135269,
			133.684036,
			135.234177,
			136.785431,
			138.337967,
			139.891754,
			141.447052,
			143.003464,
			144.561188,
			146.120392,
			147.680878,
			149.242584,
			152.37,
			153.9356,
			155.502411,
			157.07074,
			158.6406,
			160.211746,
			161.784058,
			163.357391,
			164.931931,
			166.507935,
			168.08522,
			169.663849,
			171.243866,
			174.408508,
			175.99263,
			177.578049,
			179.164642,
			180.752457,
			182.341949,
			183.9326,
			185.523819,
			187.114685,
			188.705261,
			190.297211,
			191.890366,
			193.484634,
			195.079941,
			196.676529,
			198.27417,
			201.472458,
			203.073853,
			204.676331,
			206.280182,
			207.886444,
			209.49379,
			211.102463,
			212.71373,
			214.326477,
			215.940231,
			217.55542,
			219.171936,
			220.789719,
			222.408539,
			224.028412,
			225.649246,
			228.894226,
			230.518372,
			232.1436,
			233.769684,
			235.396881,
			237.025223,
			238.654617,
			240.285126,
			241.916672,
			243.549545,
			245.1839,
			246.819168,
			248.455261,
			250.092453,
			251.730865,
			253.37001,
			255.010315,
			256.65155,
			258.293732,
			259.936829,
			261.581,
			263.225861,
			264.8719,
			266.518555,
			268.165222,
			269.811829,
			271.4597,
			273.108154,
			274.757233,
			276.4074,
			278.058228,
			279.70993,
			281.36264,
			283.016632,
			284.672028,
			286.3286,
			287.98642,
			289.645416,
			291.305725,
			292.9668,
			294.628571,
			296.291534,
			297.9557,
			301.28717,
			302.954254,
			304.6224,
			306.291443,
			307.96167,
			309.6326,
			311.304565,
			312.977356,
			314.6515,
			316.32663,
			318.002869,
			319.68,
			321.357056,
			323.0325,
			326.373535,
			328.0363,
			329.692322,
			331.340271,
			332.979645,
			334.6105,
			336.231842,
			337.843781,
			339.4463,
			342.6214,
			344.194244,
			345.7577,
			347.311554,
			348.8559,
			350.391174,
			351.917358,
			353.43454,
			354.9421,
			356.440643,
			357.9305,
			360.880371,
			362.3407,
			363.791229,
			365.231842,
			366.663239,
			368.084717,
			369.496674,
			372.2924,
			373.676117,
			375.050568,
			376.415131,
			377.7704,
			379.1161,
			380.453247,
			381.78125,
			383.099945,
			384.409058,
			385.708832,
			388.280182,
			389.552246,
			392.0702,
			394.5546,
			395.78418,
			397.0054,
			398.2183,
			399.442932,
			400.638672,
			401.82666,
			403.006165,
			404.178253,
			405.341919,
			406.477539,
			408.763275,
			409.893555,
			411.033966,
			412.146759,
			413.2507,
			414.343781,
			415.426,
			416.499756,
			417.564941,
			418.6216,
			419.65033,
			420.668549,
			421.679932,
			422.684845,
			423.682953,
			424.6631,
			425.6344,
			426.599426,
			428.511932,
			429.457977,
			430.396667,
			431.328857,
			432.254578,
			433.174255,
			434.088318,
			434.998,
			435.9033,
			436.8058,
			437.706757,
			438.607269,
			439.509857,
			440.4145,
			441.320862,
			442.229584,
			443.141052,
			444.9807,
			445.907623,
			446.8368,
			447.768341,
			448.702484,
			449.642883,
			450.5882,
			451.5358,
			452.485138,
			453.4363,
			454.38916,
			455.343323,
			456.298828,
			457.255,
			458.2119,
			459.169434,
			460.125641,
			461.082855,
			463.000061,
			463.9593,
			465.8819,
			467.803162,
			468.762482,
			469.720673,
			470.676453,
			471.629578,
			472.5802,
			473.528961,
			474.4769,
			475.424744,
			476.371857,
			477.318726,
			478.2651,
			479.2114,
			480.1584,
			481.10437,
			482.049377,
			482.9934,
			483.937,
			484.881378,
			485.8255,
			486.768982,
			487.7121,
			488.655273,
			489.5987,
			490.5417,
			491.48465,
			492.427734,
			493.3714,
			494.315948,
			495.262573,
			496.2094,
			497.156677,
			498.1043,
			499.05246,
			500.004852,
			500.959229,
			501.913544,
			502.867767,
			503.8221,
			504.776855,
			505.73114,
			506.684662,
			507.6372,
			508.58902,
			509.397858,
			510.4901,
			511.4391,
			512.3871,
			513.3345,
			514.2812,
			515.230835,
			516.1801,
			517.126831,
			518.0711,
			519.0131,
			519.9522,
			520.8877,
			521.820557,
			522.750854,
			523.6787,
			524.489,
			526.410645,
			527.311768,
			528.2113,
			529.109131,
			530.008362,
			530.908936,
			531.807861,
			532.7051,
			533.6008,
			534.4949,
			535.3905,
			536.284668,
			537.1768,
			538.067,
			538.9552,
			539.8431,
			540.734131,
			541.6233,
			542.510864,
			543.397156,
			544.2824,
			545.1686,
			546.055,
			546.9403,
			547.8249,
			548.7091,
			549.5931,
			550.477,
			551.3605,
			552.2438,
			553.1273,
			554.0113,
			554.8957,
			555.7793,
			556.6632,
			557.547546,
			558.432556,
			559.3185,
			560.2038,
			561.0873,
			561.971741,
			562.8574,
			563.7443,
			564.632751,
			565.5234,
			566.415649,
			567.3093,
			568.204468,
			569.100952,
			569.999939,
			570.9069,
			571.814758,
			572.7233,
			573.6329,
			574.543457,
			575.454,
			576.364136,
			577.2746,
			578.185547,
			579.097168,
			580.0094,
			580.9269,
			581.844543,
			582.7623,
			583.6804,
			584.5988,
			585.518066,
			586.4378,
			587.3574,
			588.2774,
			589.197632,
			590.1182,
			591.0404,
			591.9625,
			592.8845,
			593.806763,
			594.729248,
			595.6517,
			597.4951,
			598.4166,
			599.338257,
			600.220032,
			601.1852,
			602.1098,
			603.0342,
			603.9584,
			605.805664,
			606.7269,
			607.647949,
			608.5688,
			609.4896,
			610.401367,
			611.3335,
			613.1782,
			614.1002,
			615.022034,
			615.946838,
			616.873535,
			617.799561,
			618.725342,
			619.6512,
			620.576965,
			622.425659,
			623.349365,
			624.2727,
			625.195862,
			626.120361,
			627.0452,
			628.8938,
			629.818054,
			630.7426,
			631.669067,
			632.595154,
			633.521,
			634.4468,
			635.3727,
			636.3002,
			637.2278,
			638.155151,
			639.082764,
			640.0103,
			641.873962,
			642.808,
			643.7422,
			644.676941,
			645.6124,
			647.476135,
			649.341553,
			650.276245,
			651.2146,
			652.15686,
			653.0996,
			654.0435,
			654.9888,
			656.8845,
			657.8342,
			658.785461,
			659.7379,
			660.6924,
			661.65094,
			662.6112,
			663.572266,
			664.5344,
			665.497437,
			666.4593,
			667.4202,
			668.3814,
			669.3436,
			670.3069,
			671.272034,
			672.2391,
			673.206848,
			674.1755,
			675.1456,
			676.1181,
			677.094849,
			678.0727,
			679.051636,
			680.0324,
			680.867065,
			682.002869,
			682.9917,
			683.9822,
			684.9745,
			685.969238,
			686.9601,
			687.9525,
			688.947,
			689.9434,
			690.9419,
			692.960632,
			693.9713,
			694.9822,
			697.0034,
			698.0121,
			699.0209,
			700.029846,
			701.0398,
			702.0541,
			703.0686,
			704.0831,
			705.0982,
			706.114136,
			707.12915,
			708.145,
			709.162,
			710.179932,
			711.199,
			712.221558,
			713.243835,
			714.266,
			715.2882,
			716.3108,
			717.3333,
			718.3563,
			719.379761,
			720.404053,
			721.4292,
			722.455566,
			723.482239,
			724.5059,
			725.528137,
			726.5492,
			727.5734,
			728.5985,
			729.625366,
			730.654236,
			731.686951,
			732.7262,
			733.768555,
			734.8139,
			735.8621,
			736.911133,
			737.960144,
			739.0112,
			741.120361,
			742.17865,
			743.2394,
			744.302551,
			745.3676,
			746.435059,
			748.5679,
			749.6372,
			750.708069,
			751.7823,
			753.951843,
			755.0398,
			756.130432,
			757.2292,
			758.33606,
			759.44574,
			760.5393,
			761.654358,
			762.7771,
			765.030762,
			766.1617,
			767.296143,
			768.434,
			769.5748,
			770.717957,
			771.864,
			773.0357,
			774.1715,
			775.329,
			776.488831,
			777.6539,
			778.8224,
			779.9933,
			781.1866,
			782.3653,
			784.739868,
			785.9104,
			787.1031,
			788.3081,
			789.5156,
			790.7257,
			791.9385,
			794.379761,
			795.6248,
			796.8523,
			798.060547,
			799.291748,
			800.5249,
			803.001831,
			804.2478,
			805.4957,
			806.7456,
			809.2647,
			810.5283,
			811.7941,
			813.0594,
			814.3251,
			815.5919,
			816.8599,
			818.15,
			819.3983,
			820.6907,
			821.9631,
			823.228,
			824.489,
			825.7521,
			827.016968,
			828.2716,
			830.7523,
			832.0055,
			833.2568,
			834.5271,
			835.7579,
			837.0124,
			838.2713,
			840.800049,
			842.0667,
			843.340454,
			844.6204,
			845.902161,
			847.1853,
			848.472656,
			849.7638,
			852.3718,
			853.6671,
			854.9641,
			856.2407,
			857.5407,
			858.845947,
			860.1542,
			861.4855,
			862.796,
			864.105347,
			865.415039,
			866.7029,
			868.0342,
			869.33136,
			870.6283,
			871.9282,
			873.1418,
			874.506,
			875.827942,
			877.129456,
			878.433533,
			879.742859,
			881.031,
			882.3405,
			884.9644,
			886.279053,
			887.5952,
			888.913,
			890.232056,
			891.5525,
			892.874451,
			894.1986,
			895.524353,
			896.851868,
			898.1805,
			900.8434,
			902.1769,
			903.5116,
			904.845764,
			906.181152,
			907.518066,
			908.856,
			910.1961,
			911.537,
			912.8794,
			914.2229,
			915.568054,
			916.914246,
			918.2618,
			919.609863,
			920.958862,
			922.309,
			923.6605,
			925.0116,
			926.3638,
			927.717834,
			929.073,
			930.4293,
			931.787231,
			933.146851,
			934.507935,
			935.8704,
			937.234863,
			939.9687,
			941.3386,
			942.710144,
			944.0834,
			945.4582,
			946.8348,
			948.2129,
			950.973267,
			952.3557,
			953.739563,
			955.1249,
			956.511841,
			957.9,
			959.2897,
			960.681763,
			962.0754,
			963.4708,
			964.8678,
			966.2671,
			967.667847,
			969.0702,
			970.4741,
			971.8795,
			973.2866,
			974.695435,
			977.5183,
			978.932251,
			980.3477,
			981.7646,
			984.6034,
			986.0254,
			987.4492,
			988.8745,
			990.3013,
			991.7293,
			993.158936,
			994.590149,
			996.0226,
			997.4568,
			998.892456,
			1000.32977,
			1001.76849,
			1003.20892,
			1004.65076,
			1006.09436,
			1008.98596,
			1010.43396,
			1011.88342,
			1013.33435,
			1014.7868,
			1016.24091,
			1017.69635,
			1019.15332,
			1020.61169,
			1022.07166,
			1023.53296,
			1026.46,
			1027.92542,
			1029.39246,
			1030.86108,
			1032.331,
			1033.80261,
			1035.27563,
			1036.74927,
			1038.22449,
			1039.70093,
			1041.17908,
			1042.65869,
			1044.13977,
			1045.62231,
			1047.10632,
			1048.59167,
			1050.07837,
			1051.56665,
			1054.54761,
			1056.04,
			1057.53381,
			1059.02893,
			1060.52539,
			1062.02307,
			1063.52209,
			1065.02246,
			1066.52429,
			1068.02747,
			1069.532,
			1071.03772,
			1072.54456,
			1074.05286,
			1077.07324,
			1078.58545,
			1080.099,
			1081.61365,
			1083.12927,
			1084.64624,
			1086.16431,
			1087.68384,
			1089.20447,
			1090.72644,
			1092.24963,
			1093.77417,
			1095.30017,
			1098.35571,
			1099.8855,
			1101.41638,
			1102.94873,
			1104.4823,
			1106.01733,
			1107.55322,
			1109.09033,
			1110.62878,
			1113.70923,
			1115.25134,
			1116.7948,
			1118.33923,
			1119.885,
			1121.432,
			1122.98022,
			1124.52979,
			1126.08032,
			1127.632,
			1129.18469,
			1130.73853,
			1132.29382,
			1133.85034,
			1135.40808,
			1136.967,
			1138.5271,
			1140.08813,
			1143.21375,
			1144.77808,
			1146.34363,
			1147.91016,
			1149.47766,
			1152.61572,
			1154.18481,
			1155.755,
			1157.32629,
			1158.893,
			1160.46069,
			1163.60181,
			1165.17542,
			1166.75024,
			1168.32813,
			1169.9082,
			1171.48914,
			1173.07227,
			1174.65747,
			1176.2439,
			1177.83142,
			1179.42065,
			1181.01086,
			1182.60217,
			1184.1936,
			1187.37512,
			1188.96692,
			1190.55981,
			1192.15356,
			1193.74817,
			1195.34351,
			1196.94006,
			1198.5376,
			1200.136,
			1201.7356,
			1203.33582,
			1204.93677,
			1206.53857,
			1208.1416,
			1209.74561,
			1211.35059,
			1214.56384,
			1216.17188,
			1217.781,
			1219.39087,
			1221.00183,
			1222.61377,
			1224.22742,
			1225.84229,
			1227.45825,
			1229.0835,
			1230.71521,
			1232.348,
			1233.98193,
			1235.617,
			1237.25293,
			1238.88843,
			1240.52271,
			1242.158,
			1243.79236,
			1245.423,
			1247.05469,
			1248.68726,
			1250.31714,
			1251.94812,
			1253.58,
			1255.20679,
			1256.83447,
			1258.463,
			1260.0957,
			1261.72974,
			1263.36475,
			1266.64014,
			1268.27832,
			1269.91553,
			1271.55042,
			1273.18091,
			1274.806,
			1276.42444,
			1278.03491,
			1279.63684,
			1281.23022,
			1282.81433,
			1284.38892,
			1285.953,
			1287.5072,
			1289.05164,
			1290.58691,
			1292.11279,
			1293.63,
			1295.13867,
			1296.63843,
			1298.129,
			1299.611,
			1301.08374,
			1302.54688,
			1304.0011,
			1305.44556,
			1306.88037,
			1308.3064,
			1309.72351,
			1311.13184,
			1312.53052,
			1313.91956,
			1315.29956,
			1316.6709,
			1318.03271,
			1319.38464,
			1320.727,
			1322.05969,
			1323.38354,
			1324.699,
			1325.998,
			1327.28516,
			1328.56348,
			1329.83264,
			1331.09509,
			1332.34912,
			1333.59387,
			1334.829,
			1336.05859,
			1337.28162,
			1338.496,
			1339.702,
			1340.90051,
			1342.09119,
			1343.27368,
			1345.61487,
			1346.77368,
			1347.92383,
			1349.06421,
			1350.19543,
			1351.31763,
			1352.43079,
			1353.5354,
			1354.63232,
			1355.72058,
			1356.8,
			1357.87109,
			1358.93359,
			1359.98718,
			1361.03162,
			1362.067,
			1363.09338,
			1364.11108,
			1365.12024,
			1366.12012,
			1367.11035,
			1368.09155,
			1369.06372,
			1370.02661,
			1370.98,
			1371.9165,
			1372.84375,
			1373.76233,
			1374.67261,
			1375.57544,
			1376.47217,
			1377.3623,
			1378.24524,
			1379.121,
			1379.9895,
			1380.86438,
			1381.7179,
			1382.56323,
			1383.40076,
			1384.22974,
			1385.05042,
			1385.86353,
			1386.65332,
			1387.42969,
			1388.2002,
			1388.96472,
			1389.72351,
			1390.47644,
			1391.22119,
			1391.946,
			1392.66675,
			1393.38428,
			1394.09937,
			1394.81323,
			1395.52686,
			1396.24084,
			1396.95667,
			1397.67383,
			1398.39282,
			1399.11414,
			1399.83752,
			1401.29138,
			1402.021,
			1402.75232,
			1403.48511,
			1404.21973,
			1404.9563,
			1405.69482,
			1406.43542,
			1407.179,
			1407.92358,
			1408.66943,
			1409.41687,
			1410.16577,
			1410.91663,
			1411.6698,
			1412.42688,
			1413.18481,
			1413.9436,
			1414.70337,
			1415.46448,
			1416.99207,
			1417.759,
			1418.52686,
			1419.29565,
			1420.06567,
			1420.83691,
			1421.60986,
			1422.38538,
			1423.1615,
			1423.938,
			1424.71533,
			1425.49341,
			1426.27271,
			1427.05444,
			1427.838,
			1428.62207,
			1429.40637,
			1430.19153,
			1430.97754,
			1431.76465,
			1432.55615,
			1433.34766,
			1434.13977,
			1434.93237,
			1436.52,
			1437.31665,
			1438.11438,
			1438.91235,
			1439.71094,
			1440.50989,
			1441.30981,
			1442.11121,
			1442.91431,
			1443.71729,
			1444.52051,
			1445.32446,
			1446.12878,
			1446.9342,
			1448.56091,
			1449.37451,
			1450.18848,
			1451.003,
			1451.81873,
			1452.6377,
			1453.457,
			1454.27625,
			1455.09546,
			1455.915,
			1456.73535,
			1457.56152,
			1458.39087,
			1459.21973,
			1460.04822,
			1460.87646,
			1461.70508,
			1462.53271,
			1463.35828,
			1464.18286,
			1465.00708,
			1465.831,
			1466.65527,
			1467.4823,
			1468.31348,
			1469.14368,
			1469.97339,
			1470.80273,
			1471.63232,
			1472.2063,
			1473.29639,
			1474.12866,
			1474.95984,
			1475.79065,
			1476.62134,
			1477.23645,
			1478.28162,
			1479.10986,
			1479.93774,
			1480.76538,
			1481.59338,
			1482.37817,
			1483.25964,
			1484.93188,
			1485.76733,
			1486.60315,
			1487.43921,
			1488.27344,
			1489.10681,
			1489.9397,
			1490.77234,
			1491.6051,
			1492.43835,
			1493.27319,
			1494.10754,
			1494.94141,
			1495.77478,
			1496.6084,
			1497.44238,
			1499.1084,
			1499.94092,
			1500.77356,
			1501.60657,
			1502.44019,
			1503.27466,
			1504.10962,
			1504.94482,
			1505.78076,
			1506.6178,
			1507.45581,
			1508.29114,
			1509.126,
			1509.96362,
			1510.804,
			1511.647,
			1512.49292,
			1513.34119,
			1514.1925,
			1515.04785,
			1515.90747,
			1516.771,
			1517.63879,
			1518.51062,
			1519.38684,
			1520.26721,
			1521.15161,
			1522.04,
			1522.93237,
			1523.82715,
			1524.7251,
			1526.53113,
			1527.43921,
			1528.35083,
			1529.26709,
			1530.18652,
			1531.10962,
			1532.0365,
			1532.9668,
			1533.90186,
			1534.84082,
			1535.78308,
			1536.72852,
			1537.67725,
			1538.62915,
			1539.5835,
			1540.525,
			1541.4856,
			1542.4491,
			1543.41553,
			1544.38464,
			1545.35669,
			1546.33179,
			1547.30969,
			1548.29053,
			1549.27429,
			1550.26074,
			1551.25024,
			1552.24243,
			1554.23633,
			1555.238,
			1556.24268,
			1557.25012,
			1558.26025,
			1559.273,
			1560.28821,
			1561.30481,
			1562.32117,
			1563.33582,
			1564.34717,
			1565.35364,
			1566.354,
			1567.34692,
			1568.33154,
			1569.30713,
			1570.27368,
			1571.23071,
			1572.179,
			1573.11829,
			1574.04883,
			1574.9707,
			1575.884,
			1576.789,
			1577.68555,
			1578.57385,
			1579.45361,
			1580.32507,
			1581.20227,
			1582.05652,
			1582.90222,
			1583.74,
			1584.57019,
			1585.39392,
			1586.21216,
			1587.02637,
			1587.83716,
			1588.64575,
			1590.25952,
			1591.87488,
			1592.68481,
			1593.497,
			1594.31128,
			1595.13184,
			1595.95618,
			1596.78345,
			1597.61426,
			1598.44824,
			1599.28552,
			1600.13147,
			1600.9834,
			1601.83887,
			1602.69763,
			1603.55957,
			1604.42444,
			1605.29016,
			1606.15625,
			1607.02356,
			1607.89185,
			1608.76123,
			1609.631,
			1610.50793,
			1611.38721,
			1613.14478,
			1614.023,
			1614.90039,
			1615.78223,
			1616.66211,
			1617.53955,
			1618.41418,
			1619.28589,
			1620.15515,
			1621.02222,
			1621.88525,
			1622.74487,
			1623.60132,
			1624.455,
			1625.30688,
			1626.15759,
			1627.00513,
			1627.85034,
			1628.69336,
			1629.53479,
			1630.37573,
			1631.21863,
			1632.05981,
			1632.8999,
			1633.73914,
			1634.57813,
			1635.41711,
			1636.25574,
			1637.09326,
			1638.76514,
			1639.59985,
			1640.43445,
			1641.26929,
			1642.10278,
			1642.9353,
			1644.59753,
			1645.42847,
			1646.26,
			1647.09058,
			1648.74951,
			1649.57849,
			1650.27039,
			1651.235,
			1652.88843,
			1653.71472,
			1654.54126,
			1655.36829,
			1656.20532,
			1657.044,
			1657.88245,
			1658.72131,
			1659.561,
			1660.40149,
			1661.24524,
			1662.08972,
			1662.93457,
			1663.78,
			1664.62659,
			1665.47449,
			1667.18079,
			1668.03516,
			1668.89063,
			1669.74731,
			1670.606,
			1671.47229,
			1672.341,
			1673.21045,
			1674.08154,
			1675.806,
			1676.709,
			1677.58887,
			1678.46973,
			1679.352,
			1680.236,
			1682.00256,
			1682.88525,
			1683.76941,
			1684.65552,
			1685.54407,
			1686.44165,
			1687.34753,
			1688.25427,
			1689.16235,
			1690.07214,
			1690.984,
			1691.90381,
			1692.82422,
			1693.74561,
			1695.59277,
			1695.84131,
			1697.45862,
			1698.39453,
			1699.331,
			1700.26819,
			1701.20667,
			1702.14124,
			1703.07544,
			1704.01,
			1704.94507,
			1705.88074,
			1706.81726,
			1707.75293,
			1708.688,
			1709.62268,
			1710.55725,
			1711.32654,
			1712.42078,
			1713.34888,
			1714.27661,
			1715.20337,
			1716.12964,
			1717.94019,
			1718.84106,
			1719.74341,
			1720.64685,
			1721.55176,
			1722.45154,
			1723.35229,
			1724.255,
			1725.15967,
			1726.06616,
			1726.97192,
			1727.87573,
			1728.78223,
			1729.69141,
			1730.60327,
			1731.51782,
			1733.3501,
			1734.27148,
			1735.197,
			1736.12646,
			1736.96118,
			1737.9967,
			1738.93774,
			1739.883,
			1740.83215,
			1741.76953,
			1742.72656,
			1743.6875,
			1744.6521,
			1745.62061,
			1746.59265,
			1747.5686,
			1748.54822,
			1749.53149,
			1751.509,
			1752.50317,
			1753.501,
			1754.5022,
			1756.51538,
			1757.5271,
			1759.5603,
			1760.581,
			1761.60388,
			1762.62842,
			1763.65381,
			1764.67969,
			1765.70459,
			1766.72778,
			1767.74817,
			1768.765,
			1769.77686,
			1771.78259,
			1772.77539,
			1773.76038,
			1774.73779,
			1775.7063,
			1776.66626,
			1777.61768,
			1778.56213,
			1779.49939,
			1780.42847,
			1781.34961,
			1782.26331,
			1783.1698,
			1784.06946,
			1784.96313,
			1785.86658,
			1786.74963,
			1787.62451,
			1788.47485,
			1789.33154,
			1790.18115,
			1791.023,
			1791.85718,
			1792.68311,
			1793.501,
			1794.31189,
			1795.11719,
			1795.91846,
			1796.71729,
			1797.51526,
			1798.31409,
			1799.11536,
			1800.72742,
			1801.53857,
			1802.35339,
			1803.17151,
			1803.99426,
			1804.821,
			1805.65112,
			1806.48486,
			1807.322,
			1808.1626,
			1809.007,
			1809.855,
			1810.70654,
			1811.56152,
			1812.4198,
			1813.28174,
			1814.14722,
			1815.01587,
			1815.88831,
			1816.76416,
			1817.64331,
			1818.52588,
			1819.42676,
			1820.31677,
			1821.20972,
			1822.10559,
			1822.98779,
			1823.90137,
			1824.80322,
			1825.69348,
			1826.601,
			1827.5105,
			1828.42261,
			1829.33777,
			1830.25623,
			1831.17871,
			1832.10425,
			1833.03308,
			1833.96509,
			1834.90027,
			1835.83838,
			1836.77954,
			1838.67175,
			1839.6228,
			1840.5769,
			1842.49475,
			1843.45776,
			1845.39111,
			1846.36133,
			1847.33472,
			1848.31128,
			1849.29126,
			1850.27393,
			1852.249,
			1853.24133,
			1854.23682,
			1855.23535,
			1856.23755,
			1857.24268,
			1858.251,
			1859.26245,
			1860.277,
			1861.29443,
			1862.315,
			1863.33826,
			1864.36462,
			1865.394,
			1866.42676,
			1867.4624,
			1868.501,
			1869.54248,
			1870.58679,
			1872.67957,
			1873.72546,
			1874.77136,
			1875.81921,
			1876.86938,
			1877.92236,
			1878.97827,
			1880.037,
			1881.09814,
			1882.16211,
			1883.22864,
			1884.2981,
			1885.37012,
			1886.44482,
			1887.52234,
			1888.60254,
			1890.771,
			1891.85925,
			1892.95032,
			1894.044,
			1895.14038,
			1896.2395,
			1897.34119,
			1898.44556,
			1899.55249,
			1900.66211,
			1901.77441,
			1902.8894,
			1904.00708,
			1905.12744,
			1906.25061,
			1907.37622,
			1908.50439,
			1909.63538,
			1910.769,
			1911.90527,
			1913.04443,
			1914.18628,
			1915.33081,
			1916.47815,
			1917.628,
			1918.78027,
			1919.93494,
			1921.09229,
			1922.25244,
			1923.41553,
			1924.58118,
			1925.74951,
			1926.92065,
			1928.09412,
			1929.27,
			1930.44849,
			1931.62964,
			1932.81323,
			1933.99939,
			1935.18823,
			1936.37952,
			1937.57324,
			1938.76953,
			1939.96851,
			1941.1698,
			1942.37354,
			1943.58,
			1944.78882,
			1946.00024,
			1947.21411,
			1948.43042,
			1949.64941,
			1950.87085,
			1952.0946,
			1953.3208,
			1954.54968,
			1955.781,
			1957.01465,
			1958.25049,
			1959.48877,
			1960.72937,
			1961.97241,
			1963.21777,
			1964.46545,
			1965.71509,
			1966.96729,
			1968.22192,
			1969.47888,
			1970.738,
			1971.99951,
			1973.26343,
			1974.52966,
			1975.79785,
			1977.06848,
			1978.34155,
			1979.6167,
			1980.894,
			1982.17358,
			1983.45532,
			1984.7395,
			1986.026,
			1987.315,
			1988.606,
			1989.899,
			1991.19446,
			1992.4917,
			1995.092,
			1996.3927,
			1997.69336,
			1998.99573,
			2000.2998,
			2001.60583,
			2002.91418,
			2004.22461,
			2005.537,
			2008.16785,
			2009.48633,
			2010.80676,
			2012.12915,
			2013.45337,
			2014.77979,
			2016.10815,
			2017.4386,
			2018.771,
			2020.10547,
			2021.44189,
			2022.7804,
			2024.121,
			2025.4635,
			2026.808,
			2028.1543,
			2029.50244,
			2030.85291,
			2032.20532,
			2033.55969,
			2034.91626,
			2036.2749,
			2037.6355,
			2038.99768,
			2040.36182,
			2041.72791,
			2043.09583,
			2044.46606,
			2045.83813,
			2047.21216,
			2048.58813,
			2049.96582,
			2051.34546,
			2052.72681,
			2054.11035,
			2055.49634,
			2056.884,
			2058.27368,
			2059.66528,
			2061.059,
			2062.45459,
			2063.85229,
			2065.25146,
			2066.65283,
			2068.056,
			2069.46143,
			2070.86865,
			2072.27783,
			2073.688,
			2075.099,
			2076.51025,
			2077.92017,
			2079.328,
			2080.732,
			2082.13135,
			2083.52466,
			2084.91113,
			2086.28955,
			2087.65967,
			2089.02075,
			2090.37256,
			2091.71533,
			2093.04858,
			2094.37256,
			2095.687,
			2096.992,
			2098.2876,
			2099.574,
			2100.85132,
			2102.11963,
			2103.379,
			2104.62939,
			2105.87085,
			2107.104,
			2108.32813,
			2109.54346,
			2110.75049,
			2111.94922,
			2113.13916,
			2114.3208,
			2115.49414,
			2116.659,
			2117.81445,
			2118.96045,
			2120.09766,
			2121.22656,
			2122.34668,
			2123.45752,
			2124.55884,
			2125.65161,
			2126.73657,
			2127.813,
			2128.88062,
			2129.9397,
			2130.99,
			2132.031,
			2133.06274,
			2134.08521,
			2135.09863,
			2136.10254,
			2137.09766,
			2138.08374,
			2139.061,
			2140.02979,
			2140.98975,
			2141.94116,
			2142.884,
			2143.818,
			2144.74341,
			2145.67578,
			2146.58472,
			2148.37817,
			2150.14038,
			2151.00928,
			2152.72144,
			2153.56543,
			2154.40137,
			2155.23022,
			2156.05176,
			2156.86646,
			2157.67456,
			2158.476,
			2159.27148,
			2160.061,
			2160.84546,
			2161.625,
			2162.40039,
			2163.16968,
			2163.933,
			2164.69482,
			2165.456,
			2166.2168,
			2166.97827,
			2167.74121,
			2168.505,
			2169.271,
			2170.04126,
			2171.594,
			2172.37744,
			2173.166,
			2173.963,
			2174.7644,
			2175.5708,
			2176.38257,
			2178.022,
			2178.85474,
			2179.69287,
			2180.53564,
			2182.23633,
			2183.09375,
			2183.954,
			2184.81641,
			2185.68164,
			2186.54956,
			2187.42,
			2188.29272,
			2189.17578,
			2190.06,
			2190.94556,
			2191.832,
			2192.71973,
			2193.60864,
			2194.49756,
			2195.386,
			2196.274,
			2197.16235,
			2198.05078,
			2198.941,
			2199.832,
			2200.72266,
			2201.61279,
			2202.50317,
			2203.39355,
			2204.28662,
			2205.17969,
			2206.07178,
			2206.96313,
			2207.85376,
			2208.74438,
			2209.63,
			2210.51367,
			2211.396,
			2212.27686,
			2213.15649,
			2214.0354,
			2214.91553,
			2215.79346,
			2216.67,
			2217.54517,
			2218.41943,
			2219.29321,
			2220.18164,
			2221.07251,
			2222.851,
			2223.7395,
			2224.39038,
			2225.51733,
			2226.40527,
			2227.29175,
			2228.17725,
			2229.06274,
			2229.9502,
			2230.83936,
			2231.72754,
			2232.61475,
			2233.50146,
			2234.3877,
			2235.27612,
			2236.16455,
			2237.05176,
			2237.93774,
			2238.82324,
			2239.709,
			2240.59888,
			2241.48828,
			2243.2627,
			2244.14868,
			2245.03442,
			2245.90869,
			2246.78223,
			2247.65527,
			2248.52734,
			2249.39941,
			2250.12817,
			2251.168,
			2252.06,
			2252.9502,
			2253.83984,
			2254.72876,
			2255.28833,
			2256.49243,
			2257.36816,
			2258.243,
			2259.11719,
			2259.99121,
			2260.58057,
			2261.749,
			2262.62939,
			2263.5083,
			2264.38623,
			2265.26367,
			2266.08545,
			2267.0188,
			2267.895,
			2268.77,
			2269.64429,
			2270.51782,
			2271.39063,
			2272.26318,
			2273.134,
			2274.003,
			2274.86987,
			2275.735,
			2277.46118,
			2278.32129,
			2279.17871,
			2280.03418,
			2280.888,
			2281.74048,
			2282.58887,
			2284.28467,
			2285.13281,
			2285.98145,
			2286.831,
			2287.68262,
			2288.53735,
			2289.39526,
			2290.25635,
			2291.12061,
			2291.988,
			2292.85962,
			2293.73584,
			2294.615,
			2295.49756,
			2296.3833,
			2297.27222,
			2298.163,
			2299.05518,
			2299.95,
			2300.84717,
			2301.74634,
			2302.64771,
			2303.55347,
			2304.46265,
			2305.374,
			2306.28784,
			2307.20361,
			2308.12183,
			2309.96777,
			2310.89453,
			2311.824,
			2312.75586,
			2313.68945,
			2314.61377,
			2315.54028,
			2316.46973,
			2317.40234,
			2318.338,
			2319.28125,
			2320.236,
			2321.19385,
			2322.155,
			2323.119,
			2324.08545,
			2325.05786,
			2326.03369,
			2327.01074,
			2327.98926,
			2328.96875,
			2329.94727,
			2330.92358,
			2331.89941,
			2332.87646,
			2333.854,
			2334.832,
			2335.78662,
			2336.753,
			2338.66748,
			2339.614,
			2340.55249,
			2341.49414,
			2342.44434,
			2343.37061,
			2344.289,
			2345.19946,
			2346.99951,
			2347.88843,
			2348.76929,
			2349.642,
			2350.50757,
			2351.36719,
			2352.221,
			2353.06982,
			2353.91357,
			2354.73926,
			2355.589,
			2356.42041,
			2357.23413,
			2358.07275,
			2358.89526,
			2359.716,
			2360.53516,
			2361.35278,
			2362.16187,
			2362.96362,
			2363.76465,
			2364.56519,
			2365.36523,
			2366.16528,
			2366.965,
			2367.76172,
			2368.55835,
			2369.35571,
			2370.15381,
			2370.95239,
			2371.75146,
			2372.55054,
			2373.34863,
			2374.147,
			2374.94629,
			2375.746,
			2376.547,
			2377.34863,
			2378.151,
			2378.95435,
			2379.7583,
			2380.56348,
			2381.36914,
			2382.17627,
			2382.98633,
			2383.80273,
			2384.622,
			2385.44482,
			2386.271,
			2387.101,
			2387.9353,
			2388.78,
			2389.628,
			2390.47876,
			2391.33252,
			2392.18945,
			2393.05,
			2393.91846,
			2394.7915,
			2395.6665,
			2396.54419,
			2397.425,
			2399.19922,
			2400.09131,
			2400.98535,
			2401.88159,
			2402.78052,
			2403.68262,
			2405.50024,
			2406.41162,
			2407.3252,
			2408.241,
			2408.92944,
			2410.09277,
			2411.025,
			2411.95825,
			2412.89331,
			2413.83057,
			2414.76831,
			2415.70532,
			2417.57959,
			2418.51831,
			2419.4585,
			2420.40015,
			2421.34131,
			2422.28174,
			2424.16162,
			2424.713,
			2426.046,
			2426.98682,
			2427.92578,
			2428.863,
			2429.79883,
			2430.73315,
			2431.66455,
			2432.59326,
			2433.519,
			2434.44263,
			2435.12158,
			2436.279,
			2437.19116,
			2438.1,
			2439.00659,
			2439.911,
			2440.8147,
			2441.71875,
			2442.61938,
			2443.5166,
			2444.41138,
			2445.30371,
			2446.18481,
			2447.05322,
			2448.791,
			2449.6604,
			2450.53076,
			2451.39526,
			2452.25757,
			2453.12256,
			2453.99,
			2454.85986,
			2455.73242,
			2456.60767,
			2457.48535,
			2458.36572,
			2459.24829,
			2460.13379,
			2461.022,
			2461.91357,
			2463.705,
			2464.605,
			2465.50781,
			2466.414,
			2467.32373,
			2468.2373,
			2469.15479,
			2470.076,
			2471.00049,
			2471.929,
			2472.86133,
			2473.79736,
			2474.7373,
			2475.68066,
			2476.62744,
			2477.578,
			2478.52979,
			2479.48486,
			2480.44336,
			2481.40527,
			2482.37036,
			2483.339,
			2484.311,
			2485.28613,
			2486.264,
			2487.244,
			2488.20972,
			2489.1936,
			2490.17822,
			2491.1626,
			2493.12744,
			2494.10645,
			2495.081,
			2496.04956,
			2497.01147,
			2497.966,
			2498.9126,
			2499.85059,
			2500.7793,
			2501.69873,
			2502.60913,
			2503.5105,
			2504.40234,
			2505.28564,
			2507.02466,
			2507.88062,
			2508.72729,
			2509.56543,
			2510.39453,
			2511.21436,
			2512.02563,
			2512.828,
			2513.62158,
			2514.40674,
			2515.18384,
			2515.95386,
			2516.73,
			2517.48755,
			2518.23975,
			2518.987,
			2519.732,
			2520.475,
			2521.215,
			2521.95264,
			2522.688,
			2523.42236,
			2524.156,
			2524.89014,
			2525.62646,
			2526.36279,
			2527.09961,
			2527.83667,
			2528.57446,
			2529.313,
			2530.05273,
			2530.799,
			2531.546,
			2532.29346,
			2533.04248,
			2533.792,
			2534.543,
			2535.297,
			2536.06,
			2536.82373,
			2537.58887,
			2538.35474,
			2539.12256,
			2539.89185,
			2540.66382,
			2541.43677,
			2542.21069,
			2542.9856,
			2543.76172,
			2544.53931,
			2545.31836,
			2546.11133,
			2546.90479,
			2547.69922,
			2548.495,
			2549.29224,
			2550.091,
			2550.8938,
			2551.69775,
			2552.50244,
			2553.30762,
			2554.11328,
			2554.92041,
			2555.548,
			2556.548,
			2557.366,
			2558.184,
			2559.003,
			2559.82275,
			2560.6438,
			2561.47217,
			2562.301,
			2563.13,
			2563.95923,
			2564.789,
			2565.62,
			2566.46,
			2567.30176,
			2568.14258,
			2568.983,
			2569.82373,
			2570.66455,
			2571.51221,
			2572.36475,
			2573.21533,
			2574.0647,
			2574.91357,
			2575.76221,
			2575.94727,
			2577.45,
			2578.289,
			2579.12646,
			2579.96265,
			2580.798,
			2581.26587,
			2582.47046,
			2583.30469,
			2584.13672,
			2584.96753,
			2585.79663,
			2586.36914,
			2587.449,
			2588.27026,
			2589.0896,
			2589.90723,
			2590.72363,
			2592.35669,
			2593.172,
			2593.98535,
			2594.79663,
			2595.60669,
			2596.41528,
			2597.21924,
			2598.01416,
			2598.808,
			2599.60059,
			2600.39233,
			2601.18359,
			2601.97437,
			2602.757,
			2603.537,
			2604.31641,
			2605.09521,
			2605.87354,
			2606.652,
			2607.168,
			2608.217,
			2609.00146,
			2609.7854,
			2610.56885,
			2611.35181,
			2612.13525,
			2612.92188,
			2613.71338,
			2614.50415,
			2615.294,
			2616.08325,
			2616.87183,
			2617.66016,
			2619.2395,
			2620.028,
			2620.816,
			2621.60352,
			2622.391,
			2623.1792,
			2623.97388,
			2624.76855,
			2625.562,
			2626.35449,
			2627.14648,
			2627.938,
			2628.43213,
			2629.51563,
			2630.30225,
			2631.08887,
			2631.87549,
			2632.6626,
			2633.4502,
			2634.24072,
			2635.03174,
			2635.823,
			2636.61426,
			2637.40576,
			2638.96631,
			2639.78564,
			2640.58,
			2641.37476,
			2642.17017,
			2642.96655,
			2643.76416,
			2644.5647,
			2645.36865,
			2646.17261,
			2646.97656,
			2647.78149,
			2648.58765,
			2649.39429,
			2650.201,
			2651.0083,
			2651.81641,
			2652.62549,
			2653.43579,
			2654.24731,
			2655.06226,
			2655.878,
			2656.69482,
			2657.51245,
			2658.33154,
			2659.15234,
			2659.97681,
			2660.80371,
			2662.45972,
			2663.28955,
			2664.12061,
			2664.95264,
			2665.78564,
			2666.61938,
			2667.45361,
			2669.125,
			2670.80225,
			2671.642,
			2672.48242,
			2673.32275,
			2675.00366,
			2675.84668,
			2676.68945,
			2677.532,
			2678.37427,
			2679.21655,
			2680.05884,
			2680.903,
			2681.74829,
			2682.59326,
			2683.438,
			2684.28271,
			2685.1272,
			2685.97437,
			2686.82861,
			2687.68335,
			2689.39551,
			2690.253,
			2691.11255,
			2691.977,
			2692.84253,
			2693.70947,
			2694.578,
			2695.44775,
			2696.3208,
			2697.20313,
			2698.08765,
			2698.97461,
			2699.86377,
			2700.75537,
			2701.65039,
			2702.55469,
			2703.46216,
			2704.373,
			2705.28735,
			2706.205,
			2707.127,
			2708.02881,
			2708.94922,
			2709.87256,
			2710.79932,
			2711.72949,
			2712.65869,
			2713.584,
			2714.51172,
			2716.37646,
			2717.31348,
			2718.25537,
			2719.218,
			2720.15186,
			2721.104,
			2722.059,
			2723.016,
			2723.987,
			2724.966,
			2725.94824,
			2726.934,
			2727.93945,
			2728.91528,
			2729.91553,
			2730.919,
			2731.942,
			2732.949,
			2733.95728,
			2734.97119,
			2736.99487,
			2738.01831,
			2739.04321,
			2740.07764,
			2741.115,
			2742.15479,
			2743.19727,
			2744.24268,
			2745.28857,
			2746.33618,
			2747.38672,
			2748.439,
			2749.49146,
			2750.5415,
			2751.59082,
			2752.64185,
			2753.69482,
			2754.751,
			2755.80981,
			2756.8877,
			2757.95068,
			2759.01563,
			2760.083,
			2761.15161,
			2762.20215,
			2763.289,
			2764.359,
			2765.43,
			2766.50586,
			2767.58545,
			2768.66577,
			2769.73022,
			2770.83228,
			2771.91943,
			2773.00586,
			2774.09424,
			2775.16675,
			2776.25977,
			2777.355,
			2778.46875,
			2779.56616,
			2780.64722,
			2782.84985,
			2783.95215,
			2785.05566,
			2786.16113,
			2787.26855,
			2789.49243,
			2790.62744,
			2791.72778,
			2792.84961,
			2793.97534,
			2795.1062,
			2796.23975,
			2797.37549,
			2798.51367,
			2799.65479,
			2800.799,
			2801.94531,
			2803.09326,
			2805.3938,
			2806.54541,
			2808.8457,
			2809.991,
			2811.13452,
			2812.27344,
			2813.40527,
			2814.529,
			2815.64453,
			2816.755,
			2817.85718,
			2818.95117,
			2820.036,
			2821.11279,
			2822.17676,
			2823.22925,
			2824.27246,
			2825.30664,
			2826.332,
			2827.34473,
			2828.34424,
			2829.33472,
			2830.31616,
			2831.28833,
			2832.25024,
			2833.19971,
			2834.14014,
			2835.07129,
			2835.99365,
			2836.90723,
			2837.81445,
			2838.71582,
			2839.609,
			2840.49365,
			2841.36987,
			2842.23755,
			2843.09814,
			2843.9502,
			2844.793,
			2845.62622,
			2846.45068,
			2847.26587,
			2848.072,
			2848.86914,
			2849.65771,
			2850.43945,
			2851.21436,
			2851.98364,
			2852.74756,
			2853.50732,
			2854.26245,
			2855.02661,
			2855.77539,
			2856.52246,
			2857.267,
			2858.01025,
			2858.75171,
			2860.21069,
			2860.93652,
			2861.66162,
			2862.386,
			2863.10962,
			2863.833,
			2864.55249,
			2865.97241,
			2866.68262,
			2867.393,
			2868.10425,
			2868.81567,
			2869.52661,
			2870.23169,
			2870.93774,
			2872.352,
			2873.06055,
			2873.77,
			2874.48071,
			2875.19165,
			2875.902,
			2876.61328,
			2877.32617,
			2878.04053,
			2878.75684,
			2879.47485,
			2880.91968,
			2881.64551,
			2882.37354,
			2883.10352,
			2883.83667,
			2884.57275,
			2885.31152,
			2886.05835,
			2886.80859,
			2887.56128,
			2888.316,
			2889.07324,
			2889.8335,
			2890.597,
			2891.36768,
			2892.14063,
			2892.91553,
			2893.69238,
			2894.47241,
			2895.25537,
			2896.0437,
			2896.84131,
			2897.64014,
			2898.441,
			2899.24414,
			2900.05029,
			2900.86035,
			2901.66943,
			2902.47949,
			2903.29053,
			2904.10352,
			2904.91846,
			2905.736,
			2907.37476,
			2908.19434,
			2909.01514,
			2909.8374,
			2910.662,
			2911.48877,
			2912.32178,
			2913.1604,
			2913.99951,
			2914.8396,
			2915.68042,
			2916.52246,
			2917.3667,
			2918.2124,
			2919.058,
			2919.903,
			2921.59424,
			2922.37378,
			2923.28662,
			2924.13184,
			2924.976,
			2925.82031,
			2926.66431,
			2927.50879,
			2928.36035,
			2929.21313,
			2930.0647,
			2930.91577,
			2931.76685,
			2932.61865,
			2933.46777,
			2934.312,
			2935.15625,
			2936.00024,
			2936.84424,
			2937.689,
			2938.22827,
			2939.39966,
			2940.26025,
			2941.11987,
			2941.97925,
			2942.83887,
			2943.699,
			2944.55542,
			2945.4104,
			2947.11938,
			2947.974,
			2948.829,
			2949.67725,
			2950.52051,
			2951.36475,
			2952.20947,
			2953.05566,
			2953.90234,
			2954.74219,
			2955.57715,
			2956.41382,
			2957.253,
			2958.09375,
			2958.9375,
			2959.78516,
			2960.63745,
			2961.49121,
			2963.205,
			2964.06519,
			2964.92725,
			2965.79,
			2966.65552,
			2967.52344,
			2968.394,
			2970.1438,
			2971.018,
			2971.89526,
			2972.776,
			2973.65918,
			2974.545,
			2975.41821,
			2976.309,
			2977.20166,
			2978.09424,
			2978.986,
			2979.876,
			2980.7627,
			2981.645,
			2982.522,
			2983.39233,
			2984.25537,
			2985.11035,
			2985.95654,
			2986.794,
			2987.62158,
			2988.44043,
			2989.25049,
			2990.05176,
			2990.845,
			2992.40967,
			2993.18262,
			2993.9502,
			2994.713,
			2995.47168,
			2996.22754,
			2996.9812,
			2997.73535,
			2998.48828,
			2999.24,
			3000.003,
			3000.75171,
			3001.49927,
			3002.24561,
			3002.99487,
			3003.74658,
			3004.49756,
			3005.248,
			3005.99829,
			3006.74854,
			3007.49927,
			3008.2522,
			3009.00684,
			3009.76123,
			3010.5166,
			3011.27246,
			3012.02881,
			3012.78638,
			3013.54932,
			3014.315,
			3015.08154,
			3015.84888,
			3016.61768,
			3017.3877,
			3018.15942,
			3018.943,
			3019.729,
			3020.516,
			3021.30542,
			3022.09668,
			3022.89038,
			3023.688,
			3024.488,
			3025.28931,
			3026.0918,
			3026.89551,
			3027.70166,
			3028.50952,
			3029.32031,
			3030.13159,
			3030.94336,
			3031.75659,
			3032.5708,
			3033.38672,
			3034.08521,
			3035.85547,
			3036.682,
			3037.509,
			3038.3374,
			3039.16724,
			3040.00366,
			3040.83984,
			3041.67651,
			3042.51367,
			3043.35156,
			3044.19043,
			3045.8938,
			3047.60425,
			3048.45972,
			3049.316,
			3049.713,
			3051.03076,
			3051.88672,
			3052.74219,
			3053.59766,
			3054.45264,
			3054.926,
			3056.163,
			3057.01587,
			3057.86768,
			3058.71875,
			3059.56982,
			3060.42065,
			3062.11182,
			3062.95435,
			3063.796,
			3064.63647,
			3065.477,
			3066.32227,
			3067.16675,
			3068.01,
			3068.85254,
			3069.69482,
			3070.537,
			3071.392,
			3072.24878,
			3073.10449,
			3073.95947,
			3074.814,
			3075.6687,
			3076.519,
			3077.366,
			3079.05762,
			3079.90356,
			3080.74976,
			3081.01123,
			3083.30225,
			3084.15283,
			3085.00244,
			3086.65845,
			3087.5437,
			3088.385,
			3089.226,
			3090.067,
			3090.90747,
			3091.748,
			3092.59082,
			3093.43457,
			3094.27686,
			3095.11841,
			3095.95923,
			3096.7998,
			3097.64014,
			3098.477,
			3099.3125,
			3100.147,
			3100.981,
			3101.81445,
			3102.648,
			3103.31274,
			3104.32544,
			3105.1665,
			3106.00635,
			3106.8457,
			3107.685,
			3108.4978,
			3109.36548,
			3110.20557,
			3111.04541,
			3111.88452,
			3112.72363,
			3113.56323,
			3114.40015,
			3115.23633,
			3116.07275,
			3116.90967,
			3117.747,
			3118.58569,
			3119.42725,
			3120.26929,
			3121.95313,
			3123.64,
			3124.48022,
			3125.32153,
			3126.16553,
			3127.01172,
			3127.8606,
			3129.56543,
			3131.281,
			3132.14282,
			3133.00732,
			3133.87427,
			3134.74438,
			3135.61865,
			3136.496,
			3137.377,
			3138.261,
			3139.14844,
			3140.03931,
			3140.931,
			3142.7207,
			3143.61914,
			3144.52051,
			3145.424,
			3146.33252,
			3147.244,
			3148.15747,
			3149.07373,
			3149.99268,
			3150.914,
			3151.839,
			3153.69775,
			3154.63086,
			3155.567,
			3156.508,
			3157.464,
			3158.424,
			3159.3877,
			3160.35449,
			3161.3252,
			3163.285,
			3164.272,
			3165.2627,
			3166.257,
			3167.23828,
			3168.229,
			3169.21948,
			3170.213,
			3171.20923,
			3172.2085,
			3173.21069,
			3174.209,
			3175.20947,
			3176.2124,
			3177.218,
			3178.22583,
			3179.245,
			3180.273,
			3181.30322,
			3182.33667,
			3183.37134,
			3184.41284,
			3185.474,
			3186.51855,
			3187.565,
			3188.61353,
			3189.66626,
			3190.72217,
			3191.78027,
			3192.84082,
			3194.95483,
			3196.02539,
			3197.09863,
			3199.25366,
			3200.33521,
			3201.41943,
			3202.50635,
			3203.59644,
			3204.6897,
			3205.78613,
			3206.88525,
			3207.9873,
			3209.092,
			3210.20313,
			3211.317,
			3212.43359,
			3213.55322,
			3214.67578,
			3215.80225,
			3218.0625,
			3219.19678,
			3220.34155,
			3221.49365,
			3222.64844,
			3223.80542,
			3224.965,
			3226.12061,
			3227.27783,
			3228.4375,
			3229.6,
			3230.76318,
			3231.92139,
			3233.08252,
			3234.24585,
			3235.412,
			3236.57935,
			3237.76758,
			3238.93945,
			3240.11353,
			3241.28955,
			3242.4668,
			3243.62524,
			3246.00269,
			3247.18555,
			3248.3728,
			3249.5625,
			3250.73413,
			3251.947,
			3253.14282,
			3254.3208,
			3255.52,
			3256.72021,
			3257.92114,
			3259.12354,
			3260.32764,
			3262.7417,
			3263.95264,
			3265.166,
			3266.38135,
			3267.599,
			3268.81958,
			3270.04224,
			3271.26733,
			3272.49463,
			3273.72461,
			3274.95679,
			3276.192,
			3277.4292,
			3278.66919,
			3279.91138,
			3281.156,
			3282.40283,
			3283.65186,
			3284.903,
			3286.157,
			3287.41357,
			3288.67236,
			3289.93359,
			3291.197,
			3292.4624,
			3293.73,
			3294.99951,
			3296.27124,
			3297.54565,
			3298.822,
			3300.10059,
			3301.38135,
			3302.66455,
			3303.9502,
			3305.23828,
			3306.52856,
			3307.8208,
			3309.11523,
			3310.41162,
			3311.71045,
			3313.01123,
			3314.31372,
			3315.616,
			3316.918,
			3318.22217,
			3319.52759,
			3320.836,
			3322.14624,
			3323.45874,
			3324.77319,
			3326.0896,
			3327.4082,
			3328.72852,
			3330.051,
			3331.37549,
			3332.70215,
			3334.03027,
			3335.36035,
			3336.69141,
			3338.02441,
			3339.35938,
			3342.03418,
			3343.37451,
			3344.7168,
			3346.061,
			3348.75464,
			3350.10449,
			3351.45654,
			3352.80981,
			3354.16553,
			3355.523,
			3356.88232,
			3358.24365,
			3359.60669,
			3360.97168,
			3362.33887,
			3363.70825,
			3365.079,
			3366.45239,
			3367.82861,
			3370.58667,
			3371.96851,
			3373.352,
			3374.7373,
			3376.12476,
			3377.51367,
			3378.9043,
			3380.29639,
			3381.69067,
			3383.08716,
			3384.48535,
			3385.88525,
			3388.69116,
			3390.09741,
			3391.50513,
			3392.91479,
			3394.327,
			3395.74072,
			3397.15649,
			3398.57422,
			3399.99365,
			3402.83838,
			3404.26367,
			3405.691,
			3407.12036,
			3408.55151,
			3409.98486,
			3411.42041,
			3412.85742,
			3414.29663,
			3417.18,
			3418.62451,
			3420.07056,
			3421.5188,
			3422.96875,
			3424.42065,
			3425.87451,
			3427.33032,
			3428.78784,
			3430.24683,
			3431.70752,
			3433.17017,
			3434.63452,
			3436.1,
			3437.56738,
			3439.03662,
			3440.50732,
			3441.97949,
			3443.45337,
			3444.929,
			3446.40649,
			3447.88574,
			3449.3667,
			3450.84961,
			3452.334,
			3453.82031,
			3455.30835,
			3456.79785,
			3458.28955,
			3459.783,
			3461.278,
			3462.775,
			3464.27344,
			3465.77368,
			3467.276,
			3470.28516,
			3471.79224,
			3473.301,
			3474.812,
			3476.32422,
			3477.83838,
			3479.35425,
			3480.87183,
			3482.39063,
			3483.91162,
			3485.434,
			3486.958,
			3488.4834,
			3490.011,
			3491.54,
			3493.0708,
			3494.60327,
			3496.13745,
			3497.67285,
			3499.21,
			3500.749,
			3502.28955,
			3503.83179,
			3505.37549,
			3506.92114,
			3508.46826,
			3510.0166,
			3511.567,
			3513.11865,
			3514.67163,
			3516.22656,
			3517.78271,
			3519.34082,
			3520.901,
			3522.462,
			3524.025,
			3525.58936,
			3527.15527,
			3528.72266,
			3530.2915,
			3531.86182,
			3533.43359,
			3535.007,
			3536.582,
			3538.1582,
			3539.73462,
			3541.31,
			3542.8833,
			3544.45264,
			3546.017,
			3547.575,
			3549.12476,
			3550.66577,
			3552.19751,
			3553.72,
			3555.233,
			3558.23047,
			3559.71533,
			3561.19141,
			3562.65845,
			3564.11621,
			3565.56445,
			3567.00317,
			3568.43213,
			3569.852,
			3571.26221,
			3572.66333,
			3574.05518,
			3575.43823,
			3576.812,
			3578.17651,
			3579.53174,
			3580.878,
			3582.2146,
			3583.54175,
			3584.85962,
			3586.169,
			3587.469,
			3588.76,
			3590.04224,
			3591.31519,
			3592.579,
			3593.8335,
			3595.079,
			3596.31543,
			3597.54248,
			3598.76074,
			3599.97046,
			3601.17236,
			3602.3667,
			3603.55225,
			3604.73047,
			3605.90015,
			3607.06177,
			3608.2146,
			3609.358,
			3610.492,
			3611.61743,
			3612.73413,
			3613.84229,
			3614.942,
			3616.033,
			3618.18823,
			3620.30786,
			3621.35327,
			3622.39,
			3623.418,
			3624.437,
			3625.447,
			3626.44873,
			3627.44238,
			3628.42725,
			3629.40381,
			3630.37256,
			3631.333,
			3632.28418,
			3633.22583,
			3634.159,
			3635.09863,
			3636.0144,
			3636.92163,
			3637.80859,
			3638.68652,
			3639.55664,
			3640.41943,
			3641.2605,
			3642.109,
			3642.95239,
			3643.78955,
			3644.63379,
			3645.45752,
			3646.275,
			3647.08569,
			3647.88818,
			3648.68384,
			3649.473,
			3650.25586,
			3651.03271,
			3651.80371,
			3652.56934,
			3654.08936,
			3654.84229,
			3655.59082,
			3656.33545,
			3657.07764,
			3657.81738,
			3658.55273,
			3659.287,
			3660.02246,
			3660.75928,
			3661.4978,
			3662.239,
			3662.98242,
			3663.73022,
			3664.48145,
			3665.23584,
			3665.994,
			3666.75586,
			3667.52173,
			3668.292,
			3669.06958,
			3669.852,
			3670.63867,
			3671.42871,
			3672.22339,
			3673.022,
			3673.82544,
			3674.635,
			3675.447,
			3676.26221,
			3677.08081,
			3677.90234,
			3678.72778,
			3679.55615,
			3680.387,
			3681.21973,
			3682.05469,
			3682.892,
			3683.732,
			3684.575,
			3686.26758,
			3687.11621,
			3687.96582,
			3688.81738,
			3689.67017,
			3690.52344,
			3691.37671,
			3692.23047,
			3693.085,
			3694.79736,
			3695.65869,
			3696.51953,
			3697.38,
			3698.24023,
			3699.10083,
			3699.7876,
			3700.82275,
			3701.683,
			3703.39941,
			3704.25659,
			3705.11255,
			3705.972,
			3706.83,
			3707.686,
			3708.54,
			3709.393,
			3710.24487,
			3711.09277,
			3711.93848,
			3712.78247,
			3713.62451,
			3714.46484,
			3715.30371,
			3716.142,
			3716.979,
			3717.81372,
			3718.64648,
			3719.477,
			3720.306,
			3721.1333,
			3721.96021,
			3722.78467,
			3723.60718,
			3724.428,
			3725.24731,
			3726.06543,
			3726.88379,
			3727.70313,
			3728.521,
			3729.3374,
			3730.15283,
			3730.96729,
			3731.781,
			3732.5957,
			3733.40918,
			3734.22217,
			3735.03418,
			3735.8457,
			3736.65674,
			3737.46729,
			3738.27637,
			3739.08521,
			3739.89355,
			3740.70215,
			3741.51123,
			3742.32031,
			3743.12964,
			3743.939,
			3744.74829,
			3745.55786,
			3746.368,
			3747.14722,
			3747.99316,
			3748.80762,
			3749.62231,
			3750.438,
			3752.07251,
			3752.89331,
			3753.71582,
			3754.53931,
			3755.36377,
			3756.18945,
			3757.017,
			3757.84619,
			3758.67578,
			3759.50635,
			3760.33862,
			3761.17236,
			3762.008,
			3762.846,
			3763.68823,
			3764.53247,
			3765.378,
			3766.22559,
			3767.07446,
			3767.92554,
			3768.78125,
			3769.641,
			3770.50244,
			3771.36548,
			3772.23,
			3773.09619,
			3773.96484,
			3774.83569,
			3775.70752,
			3776.58057,
			3777.45459,
			3778.33,
			3778.953,
			3780.09082,
			3780.974,
			3781.85767,
			3782.742,
			3783.62671,
			3784.49731,
			3785.40576,
			3786.29883,
			3787.19238,
			3788.08667,
			3788.98218,
			3789.81738,
			3790.78149,
			3791.68457,
			3792.5874,
			3793.4917,
			3794.90015,
			3796.21484,
			3797.12549,
			3798.03638,
			3798.94727,
			3799.85938,
			3800.7688,
			3801.67627,
			3802.583,
			3803.48975,
			3804.397,
			3805.30444,
			3806.22266,
			3807.141,
			3808.05933,
			3808.97754,
			3809.89575,
			3810.81665,
			3811.74,
			3812.66235,
			3813.58447,
			3814.50684,
			3815.42944,
			3816.34888,
			3817.26758,
			3818.186,
			3819.10474,
			3820.024,
			3820.94873,
			3821.878,
			3822.80566,
			3823.733,
			3824.65967,
			3825.58618,
			3826.505,
			3827.42261,
			3828.34,
			3829.25732,
			3830.1748,
			3831.09277,
			3832.01416,
			3832.93457,
			3833.85449,
			3834.774,
			3835.693,
			3836.60864,
			3837.51855,
			3838.43066,
			3839.34473,
			3840.26074,
			3841.17822,
			3842.09814,
			3843.02,
			3843.94336,
			3844.86816,
			3845.79443,
			3846.72266,
			3847.65283,
			3848.58618,
			3849.52246,
			3850.46118,
			3851.403,
			3852.34766,
			3853.29834,
			3854.25415,
			3855.21387,
			3856.17773,
			3857.146,
			3858.11816,
			3859.09644,
			3860.07861,
			3861.065,
			3862.05542,
			3863.05,
			3864.044,
			3865.038,
			3866.036,
			3867.037,
			3868.041,
			3869.048,
			3870.05859,
			3871.07153,
			3872.0874,
			3874.12842,
			3875.161,
			3876.19678,
			3878.274,
			3879.31274,
			3880.35449,
			3881.39819,
			3882.44434,
			3883.47559,
			3884.52734,
			3885.5813,
			3886.63818,
			3887.71533,
			3888.77734,
			3889.84229,
			3890.91016,
			3891.98047,
			3893.03516,
			3894.11084,
			3895.188,
			3896.268,
			3897.35034,
			3898.45361,
			3899.5415,
			3900.61353,
			3901.70581,
			3902.80029,
			3903.897,
			3905.01416,
			3906.11572,
			3907.21973,
			3908.32568,
			3909.41553,
			3910.52637,
			3911.63867,
			3912.75317,
			3913.87,
			3914.98828,
			3916.128,
			3918.358,
			3919.48535,
			3920.61475,
			3921.74634,
			3922.88037,
			3924.01685,
			3925.15527,
			3926.29541,
			3927.4375,
			3928.58252,
			3929.72949,
			3930.878,
			3932.02881,
			3933.181,
			3934.33545,
			3935.49219,
			3936.65137,
			3937.81152,
			3938.974,
			3940.139,
			3941.30566,
			3942.474,
			3943.64429,
			3944.817,
			3945.9917,
			3947.16821,
			3948.34668,
			3949.52686,
			3950.70947,
			3951.894,
			3953.08,
			3954.268,
			3955.45825,
			3956.651,
			3957.845,
			3959.041,
			3960.239,
			3961.43848,
			3962.64014,
			3963.84326,
			3965.04858,
			3966.25586,
			3967.46533,
			3968.67651,
			3969.88916,
			3971.104,
			3973.54053,
			3974.76123,
			3975.9834,
			3977.20752,
			3978.433,
			3979.66064,
			3980.88916,
			3982.11938,
			3983.352,
			3984.586,
			3985.82129,
			3988.29736,
			3989.538,
			3990.78076,
			3992.02515,
			3993.27124,
			3994.51929,
			3995.769,
			3997.021,
			3998.27417,
			3999.5293,
			4000.78638,
			4003.30566,
			4004.568,
			4005.83228,
			4007.09766,
			4008.36279,
			4009.627,
			4010.889,
			4012.147,
			4013.40015,
			4014.647,
			4015.88672,
			4017.11865,
			4018.3418,
			4019.55518,
			4020.759,
			4021.953,
			4023.13721,
			4024.31152,
			4025.476,
			4026.631,
			4027.77661,
			4028.91235,
			4030.03857,
			4031.15479,
			4032.261,
			4033.35815,
			4034.44531,
			4035.52222,
			4036.5896,
			4037.64722,
			4038.69531,
			4039.73364,
			4040.76221,
			4041.78125,
			4042.79077,
			4044.78125,
			4045.762,
			4046.733,
			4047.69434,
			4048.64624,
			4049.58838,
			4050.52148,
			4051.44458,
			4052.35938,
			4053.26465,
			4054.16138,
			4055.92773,
			4056.79736,
			4057.65845,
			4058.5105,
			4059.353,
			4060.182,
			4060.99316,
			4061.79565,
			4062.60254,
			4063.38721,
			4064.16333,
			4064.93115,
			4065.68359,
			4066.42822,
			4067.898,
			4068.62378,
			4069.344,
			4070.05981,
			4070.76953,
			4071.47144,
			4072.171,
			4072.86768,
			4073.563,
			4074.25684,
			4074.94971,
			4075.642,
			4076.336,
			4077.02979,
			4077.72461,
			4078.42,
			4079.11646,
			4079.81348,
			4081.21143,
			4081.9126,
			4082.61475,
			4083.31763,
			4084.022,
			4084.72729,
			4085.43384,
			4086.142,
			4086.84546,
			4088.25684,
			4088.96558,
			4089.67578,
			4090.3877,
			4091.10181,
			4091.81519,
			4092.528,
			4093.243,
			4093.95947,
			4094.67871,
			4095.39941,
			4096.84863,
			4097.57666,
			4098.306,
			4099.038,
			4099.77148,
			4100.507,
			4101.24463,
			4101.985,
			4102.73047,
			4103.47754,
			4104.225,
			4104.973,
			4105.72266,
			4106.47363,
			4107.22559,
			4108.7373,
			4109.49268,
			4110.247,
			4111.002,
			4111.75635,
			4112.511,
			4113.268,
			4114.024,
			4114.77832,
			4115.53125,
			4116.28271,
			4117.033,
			4117.782,
			4118.533,
			4119.28125,
			4120.02734,
			4120.771,
			4121.5127,
			4122.252,
			4122.99,
			4123.729,
			4124.46631,
			4125.20068,
			4125.93262,
			4126.662,
			4127.38965,
			4128.115,
			4128.72656,
			4129.56152,
			4130.281,
			4130.99756,
			4131.712,
			4132.425,
			4133.137,
			4133.849,
			4134.56348,
			4135.27832,
			4135.992,
			4136.704,
			4137.41553,
			4138.126,
			4139.492,
			4140.254,
			4140.962,
			4141.66846,
			4142.37354,
			4143.078,
			4143.782,
			4144.486,
			4145.18945,
			4145.893,
			4146.595,
			4147.297,
			4147.998,
			4148.699,
			4149.40039,
			4150.102,
			4150.80859,
			4151.5166,
			4152.22363,
			4152.93,
			4153.6377,
			4154.34473,
			4155.05273,
			4155.761,
			4156.468,
			4157.17432,
			4157.881,
			4158.5874,
			4159.295,
			4160.003,
			4160.7124,
			4161.428,
			4162.149,
			4162.869,
			4163.58936,
			4164.30957,
			4165.031,
			4165.753,
			4166.47461,
			4167.191,
			4167.908,
			4168.627,
			4169.34668,
			4170.06836,
			4170.7915,
			4171.5166,
			4172.2417,
			4172.96729,
			4173.696,
			4174.42627,
			4175.15967,
			4175.89551,
			4177.374,
			4178.116,
			4178.8623,
			4179.613,
			4180.368,
			4181.127,
			4181.89,
			4182.65771,
			4183.42871,
			4184.204,
			4184.984,
			4185.7666,
			4186.541,
			4188.12646,
			4188.925,
			4189.72656,
			4190.532,
			4191.34131,
			4192.1543,
			4192.9707,
			4193.79053,
			4194.6123,
			4196.262,
			4197.08838,
			4197.91553,
			4198.743,
			4199.57129,
			4200.39941,
			4201.22656,
			4202.05273,
			4202.87646,
			4203.701,
			4204.5293,
			4205.369,
			4206.193,
			4207.01465,
			4207.835,
			4208.655,
			4209.484,
			4210.31348,
			4211.14258,
			4211.973,
			4212.80469,
			4213.63672,
			4214.47168,
			4215.32,
			4216.16748,
			4217.0166,
			4217.866,
			4218.717,
			4219.56836,
			4220.42139,
			4221.274,
			4222.12646,
			4222.979,
			4224.686,
			4225.54053,
			4227.258,
			4228.116,
			4228.97656,
			4229.8374,
			4230.69971,
			4231.56445,
			4232.43066,
			4233.297,
			4234.163,
			4235.03125,
			4235.9,
			4236.77,
			4237.641,
			4238.5127,
			4239.38428,
			4240.256,
			4242.00146,
			4242.881,
			4243.76,
			4244.6377,
			4245.516,
			4246.395,
			4247.279,
			4248.168,
			4249.056,
			4250.83,
			4251.717,
			4252.61035,
			4253.506,
			4254.39941,
			4255.29248,
			4256.18457,
			4256.97656,
			4257.969,
			4258.86,
			4259.74951,
			4260.6377,
			4261.525,
			4262.4126,
			4263.306,
			4264.2,
			4265.984,
			4266.87451,
			4267.766,
			4268.65527,
			4269.542,
			4270.42725,
			4271.3125,
			4272.198,
			4273.084,
			4273.976,
			4274.87451,
			4275.77148,
			4276.66748,
			4277.56348,
			4278.459,
			4279.35547,
			4280.25146,
			4281.146,
			4282.03662,
			4282.92432,
			4283.80957,
			4284.695,
			4285.583,
			4286.46826,
			4288.234,
			4289.983,
			4290.875,
			4291.75342,
			4292.629,
			4293.50146,
			4294.37158,
			4295.239,
			4296.09961,
			4296.959,
			4297.817,
			4298.674,
			4300.383,
			4301.2334,
			4302.083,
			4302.93359,
			4303.784,
			4304.63574,
			4305.488,
			4306.34,
			4307.19336,
			4308.049,
			4308.906,
			4309.76465,
			4310.60938,
			4311.48828,
			4312.35352,
			4313.2207,
			4314.09033,
			4314.962,
			4315.836,
			4316.715,
			4317.599,
			4318.486,
			4319.37549,
			4320.26855,
			4321.16357,
			4322.06543,
			4322.97363,
			4323.88525,
			4324.80029,
			4329.43164,
			4331.30859,
			4332.252,
			4333.19873,
			4334.14941,
			4335.103,
			4336.06,
			4337.02,
			4337.983,
			4338.949,
			4339.919,
			4340.874,
			4341.849,
			4342.84326,
			4343.823,
			4344.80566,
			4345.789,
			4346.77441,
			4347.7627,
			4348.75439,
			4349.73242,
			4350.72949,
			4351.729,
			4352.73,
			4353.749,
			4354.753,
			4355.75928,
			4356.76758,
			4357.7793,
			4358.77637,
			4359.79248,
			4360.81152,
			4361.83252,
			4362.85645,
			4364.92773,
			4365.958,
			4366.98975,
			4368.02344,
			4369.0415,
			4370.09473,
			4371.13037,
			4372.165,
			4373.19873,
			4374.23145,
			4375.248,
			4376.29932,
			4377.336,
			4378.35742,
			4379.398,
			4380.44,
			4381.4834,
			4382.5293,
			4383.57764,
			4384.62842,
			4385.67969,
			4386.75,
			4387.78857,
			4388.84668,
			4389.90674,
			4390.96875,
			4392.03271,
			4393.09863,
			4394.167,
			4395.238,
			4396.31,
			4397.38428,
			4398.461,
			4399.53955,
			4400.62,
			4401.703,
			4402.7876,
			4403.87451,
			4404.96338,
			4406.054,
			4407.14746,
			4409.34,
			4410.43945,
			4411.54053,
			4412.644,
			4413.75,
			4414.85742,
			4415.96729,
			4417.079,
			4418.193,
			4419.30859,
			4420.427,
			4421.547,
			4422.669,
			4423.793,
			4426.048,
			4427.178,
			4428.31055,
			4429.44434,
			4430.581,
			4431.719,
			4432.86,
			4434.00244,
			4435.14746,
			4436.294,
			4437.443,
			4438.59326,
			4439.746,
			4440.90137,
			4442.058,
			4443.217,
			4444.378,
			4445.541,
			4446.706,
			4447.87256,
			4449.042,
			4450.2124,
			4451.38525,
			4452.55957,
			4453.73633,
			4454.915,
			4456.0957,
			4457.279,
			4458.46338,
			4459.65,
			4460.83838,
			4462.029,
			4463.221,
			4464.415,
			4465.61133,
			4466.809,
			4468.009,
			4469.21,
			4470.41357,
			4471.61865,
			4472.82568,
			4474.03467,
			4475.246,
			4476.459,
			4477.674,
			4478.89063,
			4480.10938,
			4481.33,
			4482.55225,
			4483.77637,
			4485.00244,
			4486.23047,
			4487.46,
			4488.691,
			4489.92432,
			4491.15869,
			4492.39551,
			4493.63428,
			4494.87451,
			4496.116,
			4498.606,
			4499.85352,
			4501.103,
			4502.354,
			4503.60645,
			4504.861,
			4506.117,
			4507.375,
			4508.63428,
			4509.89551,
			4511.158,
			4512.423,
			4513.689,
			4516.226,
			4517.497,
			4518.77051,
			4520.04443,
			4521.32031,
			4522.59766,
			4523.87744,
			4525.158,
			4526.441,
			4527.725,
			4529.011,
			4530.299,
			4531.588,
			4532.879,
			4534.17139,
			4535.465,
			4536.76074,
			4538.05762,
			4539.356,
			4540.656,
			4541.957,
			4543.257,
			4544.556,
			4545.85645,
			4548.461,
			4549.76563,
			4551.072,
			4552.379,
			4553.6875,
			4554.998,
			4556.31,
			4557.623,
			4558.9375,
			4561.57031,
			4562.888,
			4564.2085,
			4565.5293,
			4566.85254,
			4568.177,
			4569.502,
			4570.829,
			4572.157,
			4573.4873,
			4574.81836,
			4576.15137,
			4577.48535,
			4578.82031,
			4580.15771,
			4581.49561,
			4582.835,
			4584.176,
			4585.518,
			4586.86133,
			4588.20654,
			4589.55273,
			4590.901,
			4592.25,
			4593.60059,
			4594.952,
			4596.305,
			4597.65967,
			4599.015,
			4600.37256,
			4601.731,
			4603.091,
			4604.452,
			4605.81543,
			4607.179,
			4608.545,
			4609.91162,
			4611.2793,
			4612.649,
			4614.01953,
			4615.391,
			4616.76465,
			4618.13965,
			4620.89355,
			4622.272,
			4623.65234,
			4625.033,
			4626.416,
			4627.80029,
			4629.18555,
			4630.57227,
			4631.96,
			4633.34961,
			4634.74,
			4636.133,
			4637.52637,
			4638.921,
			4640.317,
			4641.71338,
			4643.1123,
			4644.512,
			4645.913,
			4647.315,
			4650.12256,
			4651.52832,
			4652.935,
			4654.34326,
			4655.75244,
			4657.1626,
			4658.574,
			4659.98828,
			4661.40234,
			4662.819,
			4664.23633,
			4665.655,
			4667.075,
			4668.497,
			4669.92,
			4671.344,
			4672.76953,
			4674.19629,
			4675.62451,
			4678.48438,
			4679.9165,
			4681.34961,
			4682.784,
			4684.21973,
			4685.65674,
			4687.09473,
			4688.534,
			4689.97461,
			4691.4165,
			4692.85938,
			4694.30371,
			4695.749,
			4697.19531,
			4698.643,
			4700.093,
			4701.543,
			4702.99463,
			4705.902,
			4707.35645,
			4708.813,
			4710.271,
			4711.72949,
			4713.18945,
			4714.651,
			4716.113,
			4717.576,
			4719.04,
			4720.506,
			4721.972,
			4723.44043,
			4724.909,
			4726.37842,
			4727.848,
			4729.31738,
			4730.78467,
			4732.24854,
			4733.707,
			4735.15967,
			4736.605,
			4738.04248,
			4739.4707,
			4740.88965,
			4742.3,
			4743.7,
			4745.09,
			4746.4707,
			4747.841,
			4749.201,
			4750.55225,
			4751.89258,
			4753.22363,
			4754.545,
			4755.85742,
			4757.16,
			4758.453,
			4759.73633,
			4761.01,
			4762.27344,
			4763.527,
			4764.771,
			4766.00537,
			4767.23047,
			4768.44629,
			4769.65332,
			4770.85156,
			4772.0415,
			4773.223,
			4774.39551,
			4776.71338,
			4777.8584,
			4778.99268,
			4780.117,
			4781.232,
			4782.337,
			4783.431,
			4784.5166,
			4785.593,
			4786.66,
			4787.719,
			4788.769,
			4789.81055,
			4790.84229,
			4791.865,
			4792.879,
			4793.8833,
			4794.878,
			4795.863,
			4796.83838,
			4797.80469,
			4798.7627,
			4799.711,
			4801.581,
			4802.50342,
			4803.417,
			4804.322,
			4806.10547,
			4806.98438,
			4807.854,
			4808.715,
			4809.56641,
			4810.41064,
			4811.247,
			4812.076,
			4812.9126,
			4813.729,
			4814.53857,
			4815.343,
			4816.142,
			4816.9375,
			4817.73,
			4818.519,
			4819.306,
			4820.0835,
			4820.85547,
			4821.62646,
			4822.39746,
			4823.16943,
			4823.942,
			4824.715,
			4825.48535,
			4826.25244,
			4827.021,
			4827.79053,
			4828.56152,
			4829.333,
			4830.10547,
			4830.879,
			4831.65234,
			4832.42725,
			4833.203,
			4833.98,
			4834.758,
			4835.53857,
			4836.321,
			4837.105,
			4837.89063,
			4838.67871,
			4840.26172,
			4841.05859,
			4841.86,
			4842.663,
			4843.468,
			4844.27441,
			4845.084,
			4845.89648,
			4846.71436,
			4847.533,
			4848.35254,
			4849.175,
			4849.99756,
			4850.823,
			4851.653,
			4852.4834,
			4853.31445,
			4854.14746,
			4854.98047,
			4855.81543,
			4856.6543,
			4857.49463,
			4858.334,
			4859.175,
			4860.01563,
			4860.857,
			4861.701,
			4862.546,
			4863.39,
			4864.23438,
			4865.078,
			4865.92236,
			4866.7666,
			4867.609,
			4868.45,
			4869.29053,
			4870.131,
			4870.9707,
			4871.60156,
			4872.65674,
			4873.501,
			4874.34375,
			4875.18652,
			4876.0293,
			4876.872,
			4877.71338,
			4878.55371,
			4879.39258,
			4880.23047,
			4881.068,
			4881.90527,
			4882.74561,
			4883.58838,
			4884.42969,
			4885.27051,
			4886.11035,
			4886.951,
			4887.64,
			4888.635,
			4889.47754,
			4890.31934,
			4891.16,
			4892.00244,
			4892.845,
			4893.687,
			4894.528,
			4895.36865,
			4896.209,
			4897.05,
			4897.891,
			4898.73535,
			4899.5835,
			4900.43164,
			4901.28027,
			4902.12842,
			4902.978,
			4903.82861,
			4904.682,
			4905.535,
			4906.38867,
			4907.24268,
			4908.09766,
			4908.953,
			4909.811,
			4910.67041,
			4911.5293,
			4912.388,
			4914.107,
			4914.96533,
			4915.816,
			4916.66748,
			4917.52051,
			4918.374,
			4919.22949,
			4920.935,
			4921.78125,
			4922.62939,
			4923.48047,
			4924.334,
			4925.191,
			4926.04736,
			4926.90625,
			4927.76758,
			4929.50244,
			4930.375,
			4931.25146,
			4932.132,
			4933.016,
			4933.90332,
			4934.794,
			4935.68848,
			4937.489,
			4938.397,
			4939.30762,
			4940.22168,
			4941.13867,
			4942.044,
			4942.96875,
			4943.89648,
			4944.826,
			4946.696,
			4947.635,
			4948.576,
			4949.51953,
			4950.46631,
			4951.415,
			4952.3667,
			4953.319,
			4954.274,
			4955.232,
			4956.19238,
			4957.155,
			4958.11963,
			4959.0874,
			4960.05762,
			4961.03027,
			4962.00635,
			4962.98438,
			4963.96533,
			4964.948,
			4965.932,
			4966.916,
			4967.898,
			4968.88232,
			4969.868,
			4970.852,
			4971.84961,
			4972.82666,
			4973.8,
			4974.767,
			4975.729,
			4976.68359,
			4977.632,
			4979.50928,
			4980.41553,
			4981.32959,
			4982.23926,
			4983.14551,
			4984.04932,
			4984.95068,
			4985.85352,
			4986.756,
			4987.67139,
			4988.57031,
			4989.467,
			4990.362,
			4991.27441,
			4992.18945,
			4993.10352,
			4994.93359,
			4995.85,
			4996.767,
			4997.68262,
			4999.51563,
			5000.43359,
			5002.272,
			5003.207,
			5004.14258,
			5005.079,
			5006.0166,
			5006.95557,
			5007.89551,
			5008.836,
			5009.776,
			5010.71631,
			5011.657,
			5012.59863,
			5013.541,
			5014.48633,
			5015.43359,
			5016.38135,
			5017.32959,
			5018.279,
			5019.22852,
			5020.18359,
			5021.14258,
			5022.10254,
			5023.0625,
			5024.02344,
			5024.98633,
			5025.94434,
			5026.90234,
			5027.86035,
			5028.82,
			5029.78,
			5030.7417,
			5031.703,
			5032.666,
			5033.629,
			5034.59375,
			5035.55957,
			5036.53125,
			5037.50635,
			5038.48242,
			5039.45947,
			5040.438,
			5041.214,
			5042.40332,
			5043.389,
			5044.376,
			5046.354,
			5047.34668,
			5049.337,
			5050.3335,
			5051.33057,
			5051.869,
			5053.33545,
			5054.341,
			5055.345,
			5056.35059,
			5057.357,
			5058.35938,
			5059.362,
			5061.365,
			5062.366,
			5063.37451,
			5064.371,
			5065.40137,
			5066.41357,
			5067.426,
			5067.86035,
			5069.42725,
			5070.421,
			5071.413,
			5072.404,
			5073.39355,
			5074.39258,
			5075.392,
			5076.3916,
			5077.391,
			5078.39,
			5079.39,
			5080.38867,
			5081.386,
			5082.383,
			5083.379,
			5084.374,
			5085.36865,
			5086.36133,
			5087.35254,
			5088.343,
			5089.29346,
			5090.32568,
			5091.31738,
			5092.30762,
			5093.29736,
			5094.28564,
			5095.27051,
			5096.251,
			5097.231,
			5098.211,
			5099.191,
			5100.171,
			5101.14063,
			5103.06445,
			5104.02734,
			5104.991,
			5105.955,
			5106.9126,
			5107.86475,
			5108.818,
			5109.77246,
			5110.72852,
			5111.68652,
			5112.65674,
			5113.63135,
			5114.607,
			5115.5835,
			5117.5376,
			5118.51367,
			5119.492,
			5120.47266,
			5121.45557,
			5123.43164,
			5124.423,
			5125.417,
			5126.412,
			5127.40771,
			5128.39746,
			5129.39063,
			5130.387,
			5131.38672,
			5132.389,
			5133.39453,
			5134.403,
			5136.42773,
			5137.445,
			5138.46436,
			5139.487,
			5140.5127,
			5141.541,
			5142.57227,
			5143.607,
			5144.644,
			5146.706,
			5147.763,
			5148.709,
			5149.838,
			5150.874,
			5151.91064,
			5152.94775,
			5153.98633,
			5155.027,
			5156.052,
			5157.09473,
			5158.138,
			5159.18262,
			5160.22852,
			5161.27832,
			5162.32861,
			5163.38,
			5164.45,
			5166.57,
			5167.63574,
			5168.704,
			5169.77441,
			5170.8457,
			5171.918,
			5172.97363,
			5174.04932,
			5175.12646,
			5177.296,
			5178.38525,
			5179.47656,
			5180.57031,
			5182.77734,
			5183.8877,
			5185.00049,
			5186.116,
			5187.237,
			5188.3623,
			5189.49,
			5190.62158,
			5191.75537,
			5192.892,
			5194.03125,
			5196.318,
			5197.46533,
			5198.61572,
			5199.76855,
			5202.08252,
			5203.243,
			5204.40625,
			5205.57227,
			5206.74072,
			5207.91162,
			5209.08545,
			5210.26172,
			5211.44043,
			5213.80664,
			5214.995,
			5216.18652,
			5217.38,
			5219.774,
			5220.97461,
			5222.17773,
			5223.383,
			5224.59033,
			5225.798,
			5228.21973,
			5229.434,
			5230.65039,
			5231.868,
			5233.08838,
			5234.311,
			5235.536,
			5237.992,
			5240.47656,
			5241.712,
			5242.949,
			5244.18848,
			5245.40967,
			5246.655,
			5247.90332,
			5249.17432,
			5250.40625,
			5251.66162,
			5252.942,
			5254.18164,
			5255.445,
			5256.708,
			5257.9917,
			5260.50146,
			5261.79053,
			5263.03955,
			5264.31152,
			5266.863,
			5268.1416,
			5269.422,
			5270.704,
			5271.989,
			5273.274,
			5274.56152,
			5275.85059,
			5277.141,
			5278.43555,
			5279.732,
			5281.03,
			5283.631,
			5284.93848,
			5286.247,
			5287.55566,
			5288.86328,
			5290.169,
			5291.474,
			5292.78125,
			5294.09033,
			5295.401,
			5296.712,
			5298.02441,
			5299.33936,
			5300.656,
			5301.97363,
			5303.293,
			5304.61426,
			5305.9375,
			5308.58838,
			5309.917,
			5311.247,
			5312.579,
			5315.249,
			5316.587,
			5317.926,
			5319.26758,
			5320.61035,
			5321.955,
			5324.65,
			5326,
			5327.35156,
			5328.705,
			5330.06055,
			5331.41748,
			5332.776,
			5334.137,
			5335.499,
			5336.86328,
			5338.229,
			5339.59668,
			5340.966,
			5342.337,
			5343.71,
			5345.085,
			5347.83936,
			5349.219,
			5350.60156,
			5351.985,
			5353.37,
			5354.75732,
			5356.146,
			5357.536,
			5358.928,
			5361.718,
			5363.11475,
			5364.51367,
			5365.91455,
			5367.31738,
			5368.721,
			5370.127,
			5370.31836
		],
		[
			0,
			0.00756835938,
			0.0225372314,
			0.054107666,
			0.07072449,
			0.101104736,
			0.133483887,
			0.162628174,
			0.17741394,
			0.19581604,
			0.2260437,
			0.243469238,
			0.274261475,
			0.305679321,
			0.321044922,
			0.351074219,
			0.383132935,
			0.399490356,
			0.429916382,
			0.444137573,
			0.476257324,
			0.508712769,
			0.5242157,
			0.554245,
			0.5709076,
			0.6028137,
			0.633834839,
			0.663162231,
			0.6935425,
			0.72706604,
			0.758163452,
			0.774154663,
			0.8206482,
			0.850662231,
			0.8822174,
			0.8969269,
			0.93006897,
			0.961990356,
			0.977279663,
			1.01034546,
			1.02432251,
			1.071991,
			1.10191345,
			1.11943054,
			1.1499176,
			1.18199158,
			1.19578552,
			1.22958374,
			1.24447632,
			1.29057312,
			1.30722046,
			1.32156372,
			1.35409546,
			1.37176514,
			1.40264893,
			1.4178009,
			1.447876,
			1.49356079,
			1.52401733,
			1.55706787,
			1.57368469,
			1.61961365,
			1.65066528,
			1.66819763,
			1.69865417,
			1.73002625,
			1.7462616,
			1.79429626,
			1.82432556,
			1.85435486,
			1.88771057,
			1.90316772,
			1.933548,
			1.94815063,
			1.98097229,
			1.996109,
			2.02955627,
			2.04621887,
			2.076355,
			2.10687256,
			2.12277222,
			2.156311,
			2.17147827,
			2.20237732,
			2.218872,
			2.25193787,
			2.28141785,
			2.29724121,
			2.32749939,
			2.345337,
			2.37510681,
			2.39253235,
			2.42204285,
			2.4520874,
			2.46908569,
			2.5146637,
			2.547165,
			2.58073425,
			2.59657288,
			2.62625122,
			2.64324951,
			2.67379761,
			2.70703125,
			2.7227478,
			2.75331116,
			2.769043,
			2.81726074,
			2.83346558,
			2.848343,
			2.89605713,
			2.92536926,
			2.95747375,
			2.97389221,
			3.00563049,
			3.01940918,
			3.05093384,
			3.0680542,
			3.098938,
			3.131485,
			3.14683533,
			3.177597,
			3.19255066,
			3.22610474,
			3.2556,
			3.27325439,
			3.318451,
			3.35205078,
			3.3815155,
			3.39701843,
			3.4302063,
			3.44348145,
			3.47505188,
			3.50856018,
			3.52407837,
			3.55659485,
			3.587143,
			3.60232544,
			3.61831665,
			3.65086365,
			3.68229675,
			3.696228,
			3.74307251,
			3.77607727,
			3.80715942,
			3.82366943,
			3.85318,
			3.8686676,
			3.9017334,
			3.932846,
			3.948761,
			3.98127747,
			4.0124054,
			4.02633667,
			4.0443573,
			4.0743866,
			4.106537,
			4.121933,
			4.153412,
			4.168442,
			4.19967651,
			4.230179,
			4.25999451,
			4.278763,
			4.292557,
			4.325287,
			4.356842,
			4.386841,
			4.403839,
			4.420868,
			4.449936,
			4.46841431,
			4.498337,
			4.529251,
			4.546234,
			4.576004,
			4.593521,
			4.62232971,
			4.654846,
			4.671997,
			4.701889,
			4.717926,
			4.74842834,
			4.781479,
			4.79599,
			4.84436035,
			4.87684631,
			4.907486,
			4.93901062,
			4.95291138,
			4.96894836,
			5.001938,
			5.017441,
			5.06359863,
			5.08004761,
			5.09472656,
			5.12704468,
			5.156769,
			5.174286,
			5.220825,
			5.25090027,
			5.28239441,
			5.29707336,
			5.33081055,
			5.345459,
			5.37644958,
			5.39349365,
			5.42301941,
			5.47265625,
			5.504654,
			5.519638,
			5.549591,
			5.582199,
			5.59608459,
			5.62620544,
			5.660202,
			5.67536926,
			5.706421,
			5.72224426,
			5.76889038,
			5.80015564,
			5.83058167,
			5.84796143,
			5.87937927,
			5.893448,
			5.925888,
			5.9725647,
			6.003433,
			6.0344696,
			6.048477,
			6.082016,
			6.095764,
			6.12602234,
			6.14254761,
			6.17373657,
			6.204712,
			6.22013855,
			6.26965332,
			6.300247,
			6.349716,
			6.396866,
			6.428009,
			6.45881653,
			6.474365,
			6.506012,
			6.535568,
			6.55308533,
			6.56912231,
			6.61613464,
			6.63168335,
			6.64711,
			6.69313049,
			6.72828674,
			6.74307251,
			6.773041,
			6.806656,
			6.822006,
			6.853485,
			6.86849976,
			6.915634,
			6.930313,
			6.94908142,
			6.979645,
			6.995514,
			7.02468872,
			7.04277039,
			7.07337952,
			7.104477,
			7.121689,
			7.167862,
			7.19796753,
			7.23103333,
			7.24731445,
			7.276352,
			7.30935669,
			7.323349,
			7.35591125,
			7.388397,
			7.403427,
			7.419937,
			7.45108032,
			7.48349,
			7.49649048,
			7.53027344,
			7.54455566,
			7.577057,
			7.621872,
			7.652893,
			7.667816,
			7.7008667,
			7.732605,
			7.747345,
			7.78006,
			7.795746,
			7.8278656,
			7.84294128,
			7.872101,
			7.90467834,
			7.921997,
			7.953018,
			7.97006226,
			8.01565552,
			8.030243,
			8.049133,
			8.09584,
			8.127686,
			8.175201,
			8.222275,
			8.253769,
			8.269806,
			8.301056,
			8.33223,
			8.346619,
			8.377518,
			8.394608,
			8.427887,
			8.45657349,
			8.474258,
			8.50415,
			8.521225,
			8.554199,
			8.568695,
			8.599136,
			8.617737,
			8.647278,
			8.677139,
			8.695404,
			8.72467,
			8.758179,
			8.772339,
			8.80484,
			8.821243,
			8.85231,
			8.86853,
			8.899307,
			8.930725,
			8.964676,
			8.979645,
			8.994553,
			9.02507,
			9.057053,
			9.072235,
			9.106628,
			9.120102,
			9.152924,
			9.170029,
			9.200943,
			9.231979,
			9.247528,
			9.277557,
			9.295853,
			9.325897,
			9.342453,
			9.373428,
			9.402969,
			9.420486,
			9.453003,
			9.468536,
			9.501068,
			9.53241,
			9.547089,
			9.5793,
			9.594177,
			9.62529,
			9.656662,
			9.672852,
			9.705826,
			9.720215,
			9.769287,
			9.798889,
			9.82782,
			9.846512,
			9.877396,
			9.909912,
			9.9254,
			9.956955,
			9.987946,
			10.0030212,
			10.0174713,
			10.049469,
			10.0809784,
			10.0976715,
			10.1266632,
			10.1427917,
			10.17421,
			10.2073822,
			10.2227936,
			10.2554016,
			10.2714233,
			10.3004,
			10.3183746,
			10.3483887,
			10.3819427,
			10.3957672,
			10.4264221,
			10.4434662,
			10.4760132,
			10.509491,
			10.5235138,
			10.5543213,
			10.5703583,
			10.6028442,
			10.6183929,
			10.6499176,
			10.6823578,
			10.697052,
			10.7283936,
			10.7433929,
			10.7734222,
			10.8066254,
			10.82196,
			10.85202,
			10.8685608,
			10.9170685,
			10.9290009,
			10.946106,
			10.97728,
			11.008316,
			11.02681,
			11.0573273,
			11.0743713,
			11.1050568,
			11.1204376,
			11.1521912,
			11.1702423,
			11.2018585,
			11.2310638,
			11.2466888,
			11.2791138,
			11.2928162,
			11.3258362,
			11.3569183,
			11.3724823,
			11.4039,
			11.4369354,
			11.4529419,
			11.4679565,
			11.4983063,
			11.5292511,
			11.5457764,
			11.5910645,
			11.6070862,
			11.6225891,
			11.6546021,
			11.6696167,
			11.70166,
			11.7491913,
			11.7807465,
			11.7954712,
			11.8279877,
			11.8437805,
			11.87442,
			11.9054413,
			11.92186,
			11.9674835,
			11.9958954,
			12.0303192,
			12.0471954,
			12.0783081,
			12.1084137,
			12.1239319,
			12.1724548,
			12.20546,
			12.236908,
			12.25238,
			12.26741,
			12.2984314,
			12.3296356,
			12.3461456,
			12.375473,
			12.4220123,
			12.4540558,
			12.4690247,
			12.5010529,
			12.5175781,
			12.5630035,
			12.5967255,
			12.6418762,
			12.6595,
			12.6725464,
			12.7055511,
			12.7219391,
			12.7530823,
			12.7684937,
			12.8015137,
			12.8326569,
			12.8473663,
			12.8800659,
			12.8928375,
			12.9424133,
			12.9725189,
			13.0056915,
			13.0354614,
			13.0503845,
			13.0965576,
			13.1439362,
			13.1744232,
			13.207016,
			13.2204742,
			13.2537537,
			13.2687683,
			13.3154907,
			13.3473816,
			13.3778076,
			13.4098816,
			13.4248047,
			13.4558563,
			13.4733734,
			13.5034485,
			13.5199127,
			13.55127,
			13.56752,
			13.5965729,
			13.6292572,
			13.6428528,
			13.6769867,
			13.6924133,
			13.7239075,
			13.756012,
			13.7740936,
			13.8019867,
			13.8195038,
			13.8486023,
			13.881073,
			13.8959351,
			13.9272308,
			13.9459534,
			13.9906616,
			14.0077362,
			14.0227509,
			14.0527191,
			14.0697937,
			14.1012421,
			14.1177826,
			14.1477661,
			14.1772766,
			14.1961365,
			14.2273254,
			14.2743835,
			14.3044128,
			14.3209076,
			14.36821,
			14.3993835,
			14.43222,
			14.4460907,
			14.4772644,
			14.4927521,
			14.5243073,
			14.5564423,
			14.5868378,
			14.6043549,
			14.6198883,
			14.6502533,
			14.6807861,
			14.6962891,
			14.7258,
			14.7573547,
			14.7739563,
			14.806,
			14.8203888,
			14.8539124,
			14.8694916,
			14.9140015,
			14.9296265,
			14.94751,
			14.97702,
			14.9931488,
			15.0236206,
			15.0571136,
			15.0876465,
			15.10347,
			15.1184845,
			15.1489563,
			15.1815186,
			15.213028,
			15.2278595,
			15.243866,
			15.2742462,
			15.3068695,
			15.3212585,
			15.3538055,
			15.3838654,
			15.4008789,
			15.4471283,
			15.4794617,
			15.4949646,
			15.524292,
			15.5543365,
			15.5730133,
			15.6194916,
			15.65094,
			15.682373,
			15.6970825,
			15.7434845,
			15.7764435,
			15.80661,
			15.8220062,
			15.8550415,
			15.8710327,
			15.9026489,
			15.9334717,
			15.949585,
			15.996994,
			16.0266571,
			16.058197,
			16.0743561,
			16.1053467,
			16.1357727,
			16.16681,
			16.1833954,
			16.1996613,
			16.2301331,
			16.259552,
			16.275589,
			16.3082733,
			16.3253021,
			16.355835,
			16.3733368,
			16.4183655,
			16.4509277,
			16.4799347,
			16.5126953,
			16.5254822,
			16.559845,
			16.5758667,
			16.62291,
			16.65242,
			16.6704254,
			16.7015381,
			16.7333374,
			16.7499847,
			16.7971039,
			16.8282776,
			16.8430786,
			16.8717651,
			16.9211426,
			16.9526978,
			16.9682159,
			16.9965668,
			17.0297089,
			17.04425,
			17.0753174,
			17.1078644,
			17.13948,
			17.1573944,
			17.1725159,
			17.2021484,
			17.2181549,
			17.2492981,
			17.2813568,
			17.29741,
			17.3452759,
			17.3759155,
			17.4066315,
			17.4219513,
			17.4524689,
			17.47049,
			17.5015411,
			17.54808,
			17.5793,
			17.5962524,
			17.62709,
			17.6426086,
			17.6746674,
			17.7061615,
			17.722168,
			17.7683411,
			17.81456,
			17.82994,
			17.8464355,
			17.87796,
			17.89244,
			17.9239655,
			17.9570618,
			17.972702,
			18.004715,
			18.0355835,
			18.0521545,
			18.0818634,
			18.09848,
			18.1295624,
			18.14479,
			18.1760254,
			18.2077026,
			18.2236176,
			18.2552185,
			18.2719116,
			18.3012543,
			18.3314972,
			18.3477631,
			18.3960724,
			18.4268188,
			18.44284,
			18.4904327,
			18.5060425,
			18.5218964,
			18.5534668,
			18.57051,
			18.60199,
			18.6174774,
			18.6490631,
			18.6968536,
			18.7253876,
			18.7435,
			18.7734222,
			18.8044128,
			18.8222961,
			18.8518372,
			18.8698578,
			18.8992462,
			18.933197,
			18.9636078,
			18.98027,
			18.9974976,
			19.02916,
			19.04393,
			19.0765076,
			19.1074982,
			19.1230011,
			19.1525726,
			19.1696625,
			19.2146,
			19.2303314,
			19.2468719,
			19.2784424,
			19.310257,
			19.32573,
			19.357254,
			19.37323,
			19.42131,
			19.4498749,
			19.4813385,
			19.5144043,
			19.52974,
			19.5461731,
			19.5764,
			19.6227417,
			19.65532,
			19.6702576,
			19.7037964,
			19.7330475,
			19.7488251,
			19.7809143,
			19.8131714,
			19.8273926,
			19.842392,
			19.87593,
			19.9077148,
			19.9217072,
			19.9532471,
			19.968277,
			19.9999084,
			20.0323181,
			20.047348,
			20.0938416,
			20.1409,
			20.1574554,
			20.1715851,
			20.2203827,
			20.2504425,
			20.282959,
			20.30046,
			20.3303528,
			20.3463745,
			20.3789825,
			20.3946228,
			20.4258881,
			20.458313,
			20.4739685,
			20.5055237,
			20.5202942,
			20.5507813,
			20.5848389,
			20.6169281,
			20.647522,
			20.6792,
			20.695755,
			20.7254944,
			20.7584839,
			20.7726288,
			20.80455,
			20.8220367,
			20.85402,
			20.86856,
			20.9146271,
			20.9474182,
			20.979126,
			21.010025,
			21.02504,
			21.05754,
			21.0870819,
			21.1020966,
			21.1190948,
			21.1501,
			21.1675262,
			21.19841,
			21.2299957,
			21.2459412,
			21.2763367,
			21.30925,
			21.3227386,
			21.3553314,
			21.37143,
			21.4193268,
			21.4509277,
			21.4824066,
			21.4981384,
			21.5292511,
			21.5595245,
			21.5755,
			21.60669,
			21.6235657,
			21.6536865,
			21.6697083,
			21.7033386,
			21.718277,
			21.7518463,
			21.79892,
			21.8272858,
			21.8455811,
			21.8773956,
			21.90738,
			21.9243927,
			21.9549713,
			21.9705353,
			22.0173187,
			22.03421,
			22.0500946,
			22.0810852,
			22.0965729,
			22.1439667,
			22.175354,
			22.2050171,
			22.221405,
			22.25241,
			22.2689362,
			22.3016663,
			22.3330383,
			22.3477173,
			22.3942413,
			22.4242859,
			22.4567871,
			22.47293,
			22.5033417,
			22.5198364,
			22.5528564,
			22.5682678,
			22.6019135,
			22.6326752,
			22.6467743,
			22.6797638,
			22.6952515,
			22.72763,
			22.743103,
			22.7722015,
			22.80629,
			22.82164,
			22.86766,
			22.8987274,
			22.9302979,
			22.9460449,
			22.9752655,
			23.0223389,
			23.05333,
			23.0848389,
			23.1013336,
			23.1174622,
			23.1504364,
			23.1962738,
			23.2290039,
			23.25853,
			23.2745514,
			23.3067169,
			23.32309,
			23.3526154,
			23.3674011,
			23.41716,
			23.4324951,
			23.4472351,
			23.4807434,
			23.4945221,
			23.5394135,
			23.5724182,
			23.6043243,
			23.6323547,
			23.6661835,
			23.6829224,
			23.6956635,
			23.7301331,
			23.7457275,
			23.7774353,
			23.8085022,
			23.8240051,
			23.8555145,
			23.8705139,
			23.9021,
			23.91858,
			23.9476013,
			23.9963989,
			24.0251617,
			24.05719,
			24.0743713,
			24.1053772,
			24.1207428,
			24.1537781,
			24.1678,
			24.2000885,
			24.2310333,
			24.2481689,
			24.2769165,
			24.2939,
			24.3247833,
			24.3559265,
			24.3715057,
			24.40503,
			24.4364014,
			24.451416,
			24.480957,
			24.495697,
			24.5296173,
			24.55867,
			24.574234,
			24.6047516,
			24.6237183,
			24.6533966,
			24.6699219,
			24.7016144,
			24.7339783,
			24.7475128,
			24.7935181,
			24.82605,
			24.858078,
			24.8746185,
			24.9061279,
			24.9375458,
			24.95285,
			24.9698639,
			25.0163879,
			25.03093,
			25.0478973,
			25.0785828,
			25.0928955,
			25.12503,
			25.1575012,
			25.1740875,
			25.2056732,
			25.2220154,
			25.2525787,
			25.282074,
			25.2984161,
			25.32988,
			25.3462067,
			25.37613,
			25.3931885,
			25.4247131,
			25.4427338,
			25.471344,
			25.5022583,
			25.52089,
			25.5528564,
			25.5854034,
			25.6001282,
			25.6302338,
			25.6469574,
			25.6778259,
			25.6934662,
			25.74057,
			25.7540283,
			25.7717285,
			25.8036041,
			25.8341064,
			25.8506165,
			25.88263,
			25.8963623,
			25.942215,
			25.95874,
			25.9732971,
			26.0063629,
			26.021759,
			26.0538635,
			26.0693665,
			26.0995789,
			26.1322479,
			26.1638641,
			26.1971436,
			26.2276764,
			26.2569275,
			26.2725983,
			26.3061066,
			26.32138,
			26.3519135,
			26.38498,
			26.4014587,
			26.43251,
			26.4465179,
			26.48024,
			26.4957581,
			26.5275269,
			26.54248,
			26.5754089,
			26.6039276,
			26.6214447,
			26.6524963,
			26.66748,
			26.7015533,
			26.7327576,
			26.7481079,
			26.7796173,
			26.7957764,
			26.8256378,
			26.8561554,
			26.8890381,
			26.9040985,
			26.91893,
			26.9519653,
			26.96753,
			26.9963226,
			27.0302277,
			27.0458679,
			27.0920563,
			27.1080933,
			27.1236115,
			27.153656,
			27.1701965,
			27.1986542,
			27.23227,
			27.2469635,
			27.279953,
			27.29396,
			27.3269958,
			27.3570557,
			27.3731384,
			27.4046783,
			27.43663,
			27.4520874,
			27.482605,
			27.4972076,
			27.5283661,
			27.5436249,
			27.57611,
			27.59256,
			27.62265,
			27.6540985,
			27.6706085,
			27.70166,
			27.749176,
			27.7768555,
			27.80896,
			27.8254547,
			27.842926,
			27.8744659,
			27.90512,
			27.9224854,
			27.95102,
			27.9675446,
			28.0000763,
			28.030304,
			28.0483856,
			28.07965,
			28.0953522,
			28.1261749,
			28.1577148,
			28.1728516,
			28.2058716,
			28.2187347,
			28.2682037,
			28.2968445,
			28.3300781,
			28.3470764,
			28.3769226,
			28.392395,
			28.4253845,
			28.4574127,
			28.48845,
			28.5025024,
			28.5199738,
			28.5494843,
			28.582077,
			28.5959473,
			28.6275635,
			28.6460266,
			28.6776581,
			28.7086334,
			28.72371,
			28.754715,
			28.77185,
			28.8032227,
			28.8315582,
			28.8478088,
			28.8959351,
			28.92723,
			28.9432831,
			28.9732513,
			29.00557,
			29.0223083,
			29.054306,
			29.0694122,
			29.0991821,
			29.1290741,
			29.1479187,
			29.17691,
			29.20906,
			29.2244568,
			29.272644,
			29.302536,
			29.3180389,
			29.3656921,
			29.38321,
			29.3991241,
			29.4277039,
			29.4442139,
			29.4777832,
			29.5084076,
			29.53952,
			29.5558777,
			29.5730133,
			29.6043854,
			29.6199036,
			29.6504669,
			29.68158,
			29.6964874,
			29.7293854,
			29.7590179,
			29.7739716,
			29.8070068,
			29.8226776,
			29.85205,
			29.8695374,
			29.900589,
			29.9325714,
			29.947113,
			29.9803314,
			29.9959717,
			30.0252228,
			30.0567017,
			30.0712128,
			30.10492,
			30.1182861,
			30.1502838,
			30.1678314,
			30.1991425,
			30.2305756,
			30.2463226,
			30.2769012,
			30.31076,
			30.323761,
			30.369812,
			30.402832,
			30.4184265,
			30.4489441,
			30.4817047,
			30.49672,
			30.5294952,
			30.54544,
			30.5762787,
			30.6075287,
			30.6223,
			30.653244,
			30.6698151,
			30.7147827,
			30.7321777,
			30.76355,
			30.7946777,
			30.82396,
			30.8564148,
			30.8720856,
			30.9046173,
			30.9180145,
			30.9515076,
			30.9810944,
			30.9959564,
			31.02707,
			31.0462952,
			31.0921936,
			31.10765,
			31.1221619,
			31.1542358,
			31.1708069,
			31.2027283,
			31.23291,
			31.26387,
			31.2813263,
			31.2933044,
			31.3418579,
			31.3583527,
			31.38942,
			31.4076385,
			31.4219513,
			31.4545135,
			31.4706116,
			31.5020142,
			31.5175323,
			31.54953,
			31.5953979,
			31.6284637,
			31.6443481,
			31.6746826,
			31.70485,
			31.7211761,
			31.7552338,
			31.77031,
			31.80075,
			31.8322,
			31.8499146,
			31.8813324,
			31.89708,
			31.9268951,
			31.9434357,
			31.9739075,
			32.004425,
			32.0234833,
			32.05249,
			32.0704956,
			32.1165924,
			32.1312866,
			32.14705,
			32.1765747,
			32.1936035,
			32.2251434,
			32.25615,
			32.27188,
			32.31871,
			32.3527222,
			32.3697968,
			32.39908,
			32.4324341,
			32.4471741,
			32.4799042,
			32.51001,
			32.5264435,
			32.55699,
			32.5720978,
			32.60518,
			32.635437,
			32.65045,
			32.6679535,
			32.7005,
			32.7297668,
			32.74672,
			32.7778625,
			32.7928772,
			32.8244324,
			32.855957,
			32.87004,
			32.901413,
			32.9330444,
			32.94757,
			32.980835,
			32.9965057,
			33.02661,
			33.06015,
			33.0743256,
			33.107193,
			33.1212,
			33.1527252,
			33.16983,
			33.2007751,
			33.2182922,
			33.2488861,
			33.295578,
			33.342926,
			33.37642,
			33.40709,
			33.4225,
			33.453476,
			33.48552,
			33.5146179,
			33.53032,
			33.5486145,
			33.57759,
			33.59262,
			33.6251526,
			33.6556549,
			33.6724243,
			33.704834,
			33.72032,
			33.7527466,
			33.78392,
			33.7985535,
			33.8307343,
			33.84523,
			33.8760529,
			33.8935547,
			33.9233246,
			33.970932,
			34.0051575,
			34.0205231,
			34.0499878,
			34.0814819,
			34.097,
			34.1295929,
			34.14467,
			34.17662,
			34.2060547,
			34.22505,
			34.2534637,
			34.2704773,
			34.30313,
			34.3183441,
			34.3509369,
			34.38144,
			34.3969421,
			34.4274445,
			34.44522,
			34.474,
			34.5052338,
			34.5229034,
			34.5670624,
			34.5827942,
			34.59871,
			34.6268,
			34.64331,
			34.67691,
			34.7228851,
			34.7534332,
			34.7695,
			34.8019562,
			34.8345032,
			34.8491058,
			34.8970642,
			34.9275665,
			34.9427032,
			34.9737549,
			35.0196533,
			35.0497742,
			35.08075,
			35.0959778,
			35.1449738,
			35.1778259,
			35.20694,
			35.23848,
			35.25537,
			35.2889252,
			35.30452,
			35.32039,
			35.3514252,
			35.3834839,
			35.39888,
			35.43019,
			35.44635,
			35.47879,
			35.4937,
			35.5237122,
			35.5733948,
			35.6049347,
			35.6343231,
			35.6508636,
			35.6678467,
			35.6970825,
			35.7301331,
			35.74614,
			35.77762,
			35.8248,
			35.8562927,
			35.8698273,
			35.9013519,
			35.9189,
			35.9499054,
			35.9960632,
			36.02748,
			36.043457,
			36.07466,
			36.1059875,
			36.121933,
			36.15248,
			36.1684875,
			36.2020569,
			36.23134,
			36.246582,
			36.27661,
			36.2935944,
			36.32463,
			36.3722839,
			36.40219,
			36.4176941,
			36.4497833,
			36.4677429,
			36.513443,
			36.5298157,
			36.5466,
			36.5778046,
			36.6073151,
			36.64084,
			36.6568451,
			36.68788,
			36.70189,
			36.72046,
			36.7512054,
			36.767746,
			36.79709,
			36.8303833,
			36.84314,
			36.878418,
			36.893158,
			36.9393463,
			36.97287,
			37.0037537,
			37.03531,
			37.0507965,
			37.0818329,
			37.0955658,
			37.1278839,
			37.1430054,
			37.17343,
			37.206665,
			37.2209778,
			37.25511,
			37.2689972,
			37.3026733,
			37.3345642,
			37.3500671,
			37.3946228,
			37.4261322,
			37.4596863,
			37.4746857,
			37.50345,
			37.5359344,
			37.5514374,
			37.5831451,
			37.5988159,
			37.6303558,
			37.64351,
			37.6761169,
			37.69255,
			37.72586,
			37.75786,
			37.7719727,
			37.80388,
			37.82141,
			37.86841,
			37.89775,
			37.9300537,
			37.9465637,
			37.9928436,
			38.02539,
			38.0715179,
			38.10292,
			38.1204376,
			38.15251,
			38.18254,
			38.1972656,
			38.2300568,
			38.2463226,
			38.2787628,
			38.309082,
			38.32515,
			38.3712463,
			38.4039459,
			38.4193726,
			38.4504242,
			38.4819336,
			38.4989777,
			38.5274963,
			38.5457153,
			38.5790253,
			38.59404,
			38.62555,
			38.65703,
			38.6876373,
			38.7047424,
			38.7205658,
			38.7521057,
			38.7822876,
			38.79689,
			38.82968,
			38.84268,
			38.8922119,
			38.90924,
			38.92476,
			38.95529,
			38.9718933,
			39.0013275,
			39.01883,
			39.04901,
			39.0814362,
			39.0969238,
			39.12941,
			39.14534,
			39.19272,
			39.2408,
			39.25531,
			39.2878876,
			39.3034058,
			39.3213348,
			39.3533936,
			39.3832245,
			39.39975,
			39.4305725,
			39.4465637,
			39.4767761,
			39.5077667,
			39.524765,
			39.554306,
			39.5719757,
			39.60402,
			39.6200256,
			39.6493073,
			39.68329,
			39.7130432,
			39.7296,
			39.7610474,
			39.78984,
			39.8065033,
			39.83647,
			39.85446,
			39.86949,
			39.9165955,
			39.93062,
			39.94806,
			39.9770966,
			39.9926,
			40.026123,
			40.0576935,
			40.0723267,
			40.104538,
			40.1180267,
			40.150528,
			40.1831055,
			40.19687,
			40.2288361,
			40.2741852,
			40.3071747,
			40.3236847,
			40.3677521,
			40.4007721,
			40.44751,
			40.48053,
			40.4964,
			40.5266724,
			40.55858,
			40.57274,
			40.618103,
			40.65117,
			40.6676636,
			40.6973877,
			40.7301941,
			40.7444763,
			40.77498,
			40.7924347,
			40.8254547,
			40.85756,
			40.8870544,
			40.903717,
			40.9210663,
			40.949585,
			40.9674835,
			40.99623,
			41.030365,
			41.04367,
			41.07628,
			41.1239166,
			41.1559753,
			41.1726532,
			41.2014923,
			41.2317657,
			41.24904,
			41.2805939,
			41.29657,
			41.3291321,
			41.3584137,
			41.3753967,
			41.40767,
			41.42299,
			41.4529572,
			41.4700165,
			41.5003052,
			41.5471649,
			41.58046,
			41.5958862,
			41.6246338,
			41.6551666,
			41.6893158,
			41.7044373,
			41.7197266,
			41.75029,
			41.7843781,
			41.7990265,
			41.830658,
			41.8456573,
			41.8777,
			41.894455,
			41.92299,
			41.956543,
			41.9712219,
			42.0035553,
			42.02008,
			42.0496521,
			42.0835876,
			42.0966339,
			42.1299133,
			42.1466675,
			42.17517,
			42.2074127,
			42.2390747,
			42.256424,
			42.272644,
			42.30522,
			42.3204956,
			42.35106,
			42.3685455,
			42.39688,
			42.4295959,
			42.4445953,
			42.4922028,
			42.50966,
			42.5227661,
			42.5563049,
			42.57036,
			42.60182,
			42.6193542,
			42.6497345,
			42.68222,
			42.7092133,
			42.7279968,
			42.75876,
			42.7752075,
			42.8058777,
			42.8222656,
			42.8552551,
			42.8687744,
			42.901825,
			42.91835,
			42.9503479,
			42.9820251,
			42.9957733,
			43.026413,
			43.04341,
			43.0760345,
			43.1061249,
			43.1229858,
			43.15251,
			43.1842957,
			43.2007751,
			43.2316437,
			43.26355,
			43.276825,
			43.30983,
			43.32425,
			43.3582764,
			43.3743,
			43.4043274,
			43.4343719,
			43.4515381,
			43.4680176,
			43.4993439,
			43.5307465,
			43.54651,
			43.5765228,
			43.5935059,
			43.62549,
			43.65747,
			43.673172,
			43.7185059,
			43.7515259,
			43.782135,
			43.79683,
			43.82979,
			43.84323,
			43.8773041,
			43.892807,
			43.9394531,
			43.9724731,
			44.00238,
			44.0359039,
			44.049942,
			44.082016,
			44.0986328,
			44.129776,
			44.14583,
			44.1914978,
			44.20703,
			44.22261,
			44.25406,
			44.269577,
			44.3011627,
			44.3176727,
			44.3486633,
			44.3774719,
			44.395813,
			44.42781,
			44.45854,
			44.47371,
			44.5042267,
			44.5361176,
			44.55162,
			44.5827332,
			44.5994263,
			44.6310272,
			44.6463623,
			44.6777954,
			44.6932373,
			44.7257843,
			44.7577972,
			44.7734375,
			44.8043365,
			44.8208771,
			44.8528748,
			44.86789,
			44.8983765,
			44.9320374,
			44.94748,
			44.97902,
			44.9957428,
			45.0268555,
			45.05638,
			45.0736542,
			45.1065521,
			45.1204376,
			45.1519623,
			45.16751,
			45.2150574,
			45.23076,
			45.246582,
			45.2801361,
			45.2961121,
			45.3269653,
			45.3424835,
			45.3735962,
			45.40349,
			45.434494,
			45.4510345,
			45.4825134,
			45.49672,
			45.530304,
			45.54709,
			45.5756226,
			45.5927124,
			45.64122,
			45.65677,
			45.671463,
			45.70085,
			45.7348175,
			45.75032,
			45.7809,
			45.79567,
			45.8290863,
			45.85939,
			45.8738861,
			45.9075775,
			45.9224243,
			45.9544525,
			45.97142,
			46.00334,
			46.03389,
			46.0496368,
			46.0810852,
			46.09581,
			46.12886,
			46.1602173,
			46.17517,
			46.20732,
			46.2227631,
			46.2527466,
			46.2702637,
			46.30069,
			46.33232,
			46.3628235,
			46.37686,
			46.3946228,
			46.4268951,
			46.457962,
			46.474472,
			46.50418,
			46.5215454,
			46.55258,
			46.58264,
			46.59932,
			46.63083,
			46.6464081,
			46.67627,
			46.6936951,
			46.7247162,
			46.7709045,
			46.8188171,
			46.8499,
			46.8972321,
			46.927124,
			46.9427032,
			46.9741974,
			47.0073242,
			47.0206757,
			47.0552826,
			47.07019,
			47.1173553,
			47.1310425,
			47.14679,
			47.1800537,
			47.1950836,
			47.22534,
			47.2424622,
			47.2738953,
			47.3044434,
			47.3194122,
			47.35251,
			47.3830872,
			47.39705,
			47.4268951,
			47.4444122,
			47.4925537,
			47.5224762,
			47.55252,
			47.57013,
			47.6026154,
			47.63478,
			47.64987,
			47.6965332,
			47.7276764,
			47.75772,
			47.7742,
			47.8215332,
			47.8540649,
			47.870575,
			47.9011078,
			47.93254,
			47.94725,
			47.9789124,
			47.9955444,
			48.0277557,
			48.0582428,
			48.0734558,
			48.1038055,
			48.1373138,
			48.15233,
			48.1834869,
			48.19931,
			48.23015,
			48.245697,
			48.27748,
			48.2934875,
			48.32292,
			48.3559875,
			48.3721161,
			48.4045563,
			48.4337,
			48.450592,
			48.4815063,
			48.49437,
			48.5278778,
			48.54245,
			48.5750122,
			48.60614,
			48.6215057,
			48.653,
			48.66902,
			48.7022247,
			48.73346,
			48.7625732,
			48.77736,
			48.7958527,
			48.82637,
			48.8574066,
			48.87445,
			48.9040222,
			48.9204559,
			48.9685974,
			48.99922,
			49.03026,
			49.0458832,
			49.077652,
			49.0926056,
			49.12558,
			49.1546173,
			49.17177,
			49.2036438,
			49.2182159,
			49.2507,
			49.2813873,
			49.29596,
			49.3296051,
			49.34546,
			49.37729,
			49.40834,
			49.43895,
			49.4533539,
			49.4683838,
			49.500412,
			49.5487671,
			49.57953,
			49.5933228,
			49.625824,
			49.6563568,
			49.6714935,
			49.7034454,
			49.7194061,
			49.750412,
			49.78351,
			49.797226,
			49.8294525,
			49.843277,
			49.8898621,
			49.9064331,
			49.92485,
			49.9543762,
			49.9684,
			50.0014343,
			50.0340576,
			50.0498962,
			50.0814056,
			50.10991,
			50.1274567,
			50.1430054,
			50.1754,
			50.2069244,
			50.2204437,
			50.25345,
			50.2699738,
			50.3145447,
			50.33008,
			50.3475647,
			50.37607,
			50.3931427,
			50.42511,
			50.45613,
			50.4743042,
			50.5216827,
			50.5666046,
			50.58232,
			50.597,
			50.6298065,
			50.6588,
			50.6769257,
			50.72435,
			50.7543945,
			50.7683868,
			50.8014069,
			50.83406,
			50.85045,
			50.88115,
			50.9127045,
			50.9282837,
			50.94406,
			50.97554,
			51.00569,
			51.02057,
			51.0540924,
			51.0691071,
			51.1016541,
			51.1302338,
			51.14769,
			51.1766968,
			51.1937256,
			51.22426,
			51.2427521,
			51.2743073,
			51.30594,
			51.32077,
			51.3508,
			51.3848419,
			51.4013672,
			51.4438934,
			51.4765167,
			51.4945068,
			51.5258331,
			51.5568542,
			51.5729065,
			51.6038055,
			51.61931,
			51.65036,
			51.6808777,
			51.6956024,
			51.7297058,
			51.76085,
			51.7744141,
			51.79242,
			51.8214569,
			51.85396,
			51.8704834,
			51.89987,
			51.9328461,
			51.94757,
			51.97757,
			51.9953766,
			52.0256348,
			52.05815,
			52.07332,
			52.1053162,
			52.1369,
			52.1651154,
			52.1991577,
			52.230545,
			52.2433167,
			52.2769775,
			52.30963,
			52.325058,
			52.3571167,
			52.3741,
			52.4176331,
			52.45215,
			52.4811859,
			52.4963379,
			52.5301056,
			52.5461273,
			52.5778656,
			52.592514,
			52.62378,
			52.6552734,
			52.6702881,
			52.7024536,
			52.7197723,
			52.74977,
			52.7815247,
			52.7961121,
			52.8418579,
			52.8603973,
			52.89145,
			52.90692,
			52.92343,
			52.95352,
			52.97003,
			53.00151,
			53.0312347,
			53.0475922,
			53.08008,
			53.09407,
			53.1261139,
			53.1611328,
			53.1742859,
			53.2077179,
			53.2242279,
			53.2562256,
			53.2719421,
			53.30127,
			53.3197632,
			53.3513031,
			53.38086,
			53.39644,
			53.4418945,
			53.4604034,
			53.4759827,
			53.5059357,
			53.52243,
			53.55452,
			53.5696564,
			53.59944,
			53.6321869,
			53.64995,
			53.6797028,
			53.695755,
			53.72548,
			53.7440338,
			53.7760162,
			53.7926178,
			53.8226166,
			53.8545837,
			53.8696,
			53.9012,
			53.9324646,
			53.9471741,
			53.9932556,
			54.0267334,
			54.0567474,
			54.0903473,
			54.1067963,
			54.12082,
			54.16777,
			54.21541,
			54.2290649,
			54.24695,
			54.27545,
			54.30905,
			54.3224945,
			54.354538,
			54.37169,
			54.4021149,
			54.4181061,
			54.4491577,
			54.4960022,
			54.5409851,
			54.5574036,
			54.5749664,
			54.60765,
			54.61992,
			54.66745,
			54.6998444,
			54.7324371,
			54.74713,
			54.77957,
			54.7958832,
			54.82515,
			54.8588562,
			54.8725128,
			54.9065247,
			54.9209,
			54.9529724,
			54.96901,
			54.9998474,
			55.032074,
			55.0470734,
			55.080246,
			55.0958252,
			55.125946,
			55.14348,
			55.1754,
			55.2069244,
			55.2229767,
			55.25345,
			55.26996,
			55.29889,
			55.3473663,
			55.37738,
			55.39441,
			55.42531,
			55.455307,
			55.48674,
			55.5037842,
			55.52127,
			55.55075,
			55.58275,
			55.5995026,
			55.62909,
			55.64618,
			55.69084,
			55.7078247,
			55.74002,
			55.77124,
			55.8027649,
			55.81929,
			55.85034,
			55.88185,
			55.8971558,
			55.9279022,
			55.9456635,
			55.977417,
			55.993454,
			56.0239868,
			56.05626,
			56.0712738,
			56.1054535,
			56.11931,
			56.15004,
			56.16815,
			56.1972961,
			56.23079,
			56.24588,
			56.29045,
			56.3074341,
			56.32495,
			56.35347,
			56.3716278,
			56.4020081,
			56.41803,
			56.4481049,
			56.4821472,
			56.4971161,
			56.54367,
			56.5742035,
			56.60475,
			56.622757,
			56.65335,
			56.6693726,
			56.7154541,
			56.7301941,
			56.74745,
			56.77971,
			56.7934875,
			56.8269958,
			56.8424835,
			56.87349,
			56.9197845,
			56.9507751,
			56.98146,
			56.9967041,
			57.02742,
			57.0434265,
			57.0919647,
			57.1059875,
			57.123,
			57.1546326,
			57.17009,
			57.2010651,
			57.2333069,
			57.2634277,
			57.28038,
			57.2965546,
			57.3274231,
			57.34297,
			57.37343,
			57.40445,
			57.43419,
			57.45198,
			57.4675751,
			57.4987335,
			57.5293579,
			57.5468445,
			57.5767975,
			57.6073456,
			57.62393,
			57.6573639,
			57.6708832,
			57.70549,
			57.7223663,
			57.7518921,
			57.77008,
			57.7990875,
			57.8315125,
			57.8472443,
			57.8801422,
			57.9084625,
			57.92598,
			57.9580536,
			57.9722,
			58.0028839,
			58.02037,
			58.0524,
			58.0829468,
			58.1000824,
			58.1298981,
			58.14421,
			58.17772,
			58.1927338,
			58.22429,
			58.2557831,
			58.2893372,
			58.30446,
			58.33725,
			58.3527527,
			58.3698425,
			58.3990326,
			58.4305267,
			58.462616,
			58.4796143,
			58.4926147,
			58.52565,
			58.55768,
			58.5727844,
			58.60318,
			58.62169,
			58.6532135,
			58.68242,
			58.6982422,
			58.7303,
			58.7479858,
			58.77748,
			58.79474,
			58.82405,
			58.8550568,
			58.88893,
			58.90506,
			58.91942,
			58.95044,
			58.98401,
			58.99933,
			59.02893,
			59.04564,
			59.0773163,
			59.10681,
			59.12332,
			59.15558,
			59.17267,
			59.20459,
			59.220108,
			59.2501526,
			59.2836456,
			59.29901,
			59.329422,
			59.34543,
			59.377243,
			59.40686,
			59.4243622,
			59.4538879,
			59.46991,
			59.5010529,
			59.5175,
			59.5499725,
			59.5962372,
			59.6270447,
			59.6575623,
			59.6761932,
			59.7067261,
			59.7221069,
			59.754715,
			59.7701874,
			59.7995758,
			59.83345,
			59.8479767,
			59.87999,
			59.89572,
			59.9255066,
			59.9570923,
			59.98848,
			60.00618,
			60.0222321,
			60.052536,
			60.0850677,
			60.1005859,
			60.1328735,
			60.14804,
			60.1797333,
			60.1952972,
			60.2265625,
			60.2565765,
			60.2727356,
			60.3042755,
			60.3356628,
			60.3531952,
			60.3686371,
			60.39966,
			60.4472351,
			60.47676,
			60.50685,
			60.52234,
			60.55391,
			60.5699158,
			60.5997162,
			60.6175079,
			60.6479645,
			60.6799927,
			60.6967,
			60.7255249,
			60.7435455,
			60.77513,
			60.80307,
			60.81909,
			60.85327,
			60.882782,
			60.8985138,
			60.92993,
			60.947403,
			60.9768677,
			60.9926147,
			61.0254059,
			61.07309,
			61.10402,
			61.11949,
			61.1485443,
			61.18242,
			61.19905,
			61.2305145,
			61.246582,
			61.27591,
			61.293396,
			61.3254242,
			61.35794,
			61.3894653,
			61.40399,
			61.42003,
			61.4520264,
			61.46855,
			61.4987946,
			61.53006,
			61.54654,
			61.5760651,
			61.6091461,
			61.63974,
			61.6546173,
			61.6718,
			61.70218,
			61.71866,
			61.74875,
			61.7960968,
			61.8280029,
			61.8433533,
			61.874176,
			61.90619,
			61.92279,
			61.9532776,
			62.0009,
			62.0477448,
			62.0784454,
			62.094223,
			62.1257629,
			62.17035,
			62.2023163,
			62.2178345,
			62.25186,
			62.28241,
			62.31041,
			62.34198,
			62.3594666,
			62.375473,
			62.4071045,
			62.42256,
			62.45404,
			62.4705658,
			62.5010834,
			62.51761,
			62.55011,
			62.5962219,
			62.6266632,
			62.65619,
			62.6733856,
			62.7058563,
			62.71974,
			62.7529449,
			62.7679,
			62.79837,
			62.8307648,
			62.84697,
			62.8787079,
			62.89447,
			62.923996,
			62.9560547,
			62.9721527,
			63.004425,
			63.0184479,
			63.0520325,
			63.08249,
			63.09735,
			63.1297,
			63.15901,
			63.17549,
			63.2085724,
			63.22406,
			63.2551727,
			63.27008,
			63.30121,
			63.3490448,
			63.3765564,
			63.3956757,
			63.4276123,
			63.4571381,
			63.4742279,
			63.5069122,
			63.52324,
			63.5533142,
			63.56859,
			63.6000977,
			63.63121,
			63.6630554,
			63.68022,
			63.69583,
			63.72757,
			63.743576,
			63.77513,
			63.80728,
			63.822113,
			63.8678741,
			63.9026642,
			63.9326324,
			63.94716,
			63.98044,
			63.9961853,
			64.0267,
			64.04549,
			64.07478,
			64.10841,
			64.12297,
			64.15401,
			64.16846,
			64.20346,
			64.21898,
			64.26665,
			64.28273,
			64.2986,
			64.3290558,
			64.3460846,
			64.37787,
			64.39285,
			64.4254761,
			64.4574,
			64.47264,
			64.5056,
			64.52095,
			64.56682,
			64.58356,
			64.59975,
			64.629776,
			64.6464844,
			64.67731,
			64.69281,
			64.7228241,
			64.75598,
			64.77104,
			64.80339,
			64.8190155,
			64.8504944,
			64.88196,
			64.89659,
			64.94449,
			64.97438,
			65.00595,
			65.0198059,
			65.06651,
			65.10039,
			65.13049,
			65.14743,
			65.17934,
			65.19409,
			65.22414,
			65.256134,
			65.27281,
			65.30324,
			65.31969,
			65.35022,
			65.3828,
			65.39859,
			65.44615,
			65.47485,
			65.52336,
			65.555954,
			65.57045,
			65.60167,
			65.61795,
			65.647,
			65.67752,
			65.6967,
			65.7275848,
			65.75806,
			65.77319,
			65.80713,
			65.82063,
			65.85364,
			65.8681641,
			65.9006958,
			65.93242,
			65.94899,
			65.97699,
			65.99475,
			66.02455,
			66.05705,
			66.0742645,
			66.10602,
			66.1350861,
			66.15262,
			66.1846,
			66.20111,
			66.2303,
			66.2464,
			66.2789154,
			66.2953949,
			66.3263245,
			66.35823,
			66.37276,
			66.40494,
			66.4203339,
			66.45137,
			66.480896,
			66.4964142,
			66.52692,
			66.545166,
			66.5769653,
			66.60701,
			66.6245,
			66.65352,
			66.66855,
			66.69934,
			66.73262,
			66.76338,
			66.78021,
			66.7962646,
			66.84189,
			66.8584,
			66.87155,
			66.92096,
			66.95,
			66.9820862,
			66.9972839,
			67.02704,
			67.04576,
			67.07762,
			67.12262,
			67.15578,
			67.1703339,
			67.20276,
			67.21826,
			67.250824,
			67.27986,
			67.3133545,
			67.34442,
			67.37401,
			67.40849,
			67.42198,
			67.453476,
			67.46948,
			67.5020447,
			67.51802,
			67.54808,
			67.58217,
			67.59683,
			67.64462,
			67.69101,
			67.7066345,
			67.72301,
			67.75253,
			67.76965,
			67.8004,
			67.83128,
			67.86267,
			67.87953,
			67.89607,
			67.941925,
			67.95792,
			67.97307,
			68.00453,
			68.02208,
			68.054245,
			68.06827,
			68.1005859,
			68.13008,
			68.14786,
			68.17908,
			68.19289,
			68.22447,
			68.2555542,
			68.28752,
			68.30353,
			68.318985,
			68.35152,
			68.3826,
			68.39839,
			68.4303,
			68.4460754,
			68.49263,
			68.52417,
			68.55475,
			68.5692139,
			68.60274,
			68.6303558,
			68.6503,
			68.68236,
			68.70932,
			68.72809,
			68.74391,
			68.77638,
			68.80644,
			68.82196,
			68.852356,
			68.86989,
			68.9174347,
			68.96347,
			68.9808044,
			69.01276,
			69.0265045,
			69.05702,
			69.07306,
			69.1061859,
			69.12059,
			69.15361,
			69.18216,
			69.19917,
			69.22969,
			69.2467041,
			69.27605,
			69.293,
			69.32306,
			69.3571,
			69.37323,
			69.40373,
			69.41962,
			69.45119,
			69.48216,
			69.49902,
			69.52977,
			69.56023,
			69.57625,
			69.59386,
			69.62482,
			69.67197,
			69.7016449,
			69.71739,
			69.7493744,
			69.7809448,
			69.79646,
			69.82971,
			69.85878,
			69.87785,
			69.90744,
			69.921936,
			69.9555054,
			69.97264,
			70.0020752,
			70.01953,
			70.0486,
			70.0831,
			70.11244,
			70.1441956,
			70.17627,
			70.20827,
			70.2237549,
			70.25375,
			70.27089,
			70.30234,
			70.3473358,
			70.38063,
			70.39691,
			70.4274139,
			70.44298,
			70.47447,
			70.50751,
			70.5220642,
			70.552536,
			70.56857,
			70.60161,
			70.63092,
			70.64719,
			70.68034,
			70.69586,
			70.7267151,
			70.75722,
			70.77493,
			70.8078,
			70.83809,
			70.85193,
			70.86746,
			70.901,
			70.91756,
			70.9490356,
			70.99655,
			71.0259247,
			71.05646,
			71.07448,
			71.10564,
			71.12103,
			71.15456,
			71.17076,
			71.21718,
			71.23093,
			71.2638855,
			71.28015,
			71.29314,
			71.32843,
			71.36023,
			71.3753357,
			71.40675,
			71.41937,
			71.45296,
			71.48247,
			71.4999847,
			71.53119,
			71.54701,
			71.57922,
			71.59483,
			71.62456,
			71.65718,
			71.68713,
			71.70474,
			71.72014,
			71.7527,
			71.76819,
			71.7992554,
			71.82945,
			71.8473358,
			71.87778,
			71.9093,
			71.92436,
			71.95688,
			71.97397,
			72.0034,
			72.03699,
			72.0666962,
			72.08244,
			72.09718,
			72.13051,
			72.14656,
			72.17738,
			72.2098541,
			72.23988,
			72.2569,
			72.2709045,
			72.3039856,
			72.31847,
			72.34781,
			72.39659,
			72.42903,
			72.4584045,
			72.4749756,
			72.50659,
			72.522934,
			72.5530853,
			72.568985,
			72.60252,
			72.63406,
			72.64758,
			72.69679,
			72.74539,
			72.7921753,
			72.80734,
			72.8222351,
			72.85422,
			72.86873,
			72.89899,
			72.9324646,
			72.963,
			72.97742,
			72.99576,
			73.02551,
			73.05699,
			73.07301,
			73.1049957,
			73.12137,
			73.16843,
			73.19928,
			73.23259,
			73.24902,
			73.27954,
			73.29706,
			73.3265839,
			73.3426361,
			73.3723,
			73.40729,
			73.42168,
			73.4532,
			73.46869,
			73.49899,
			73.532486,
			73.54727,
			73.57677,
			73.60988,
			73.62431,
			73.6709,
			73.70172,
			73.71942,
			73.7499847,
			73.79898,
			73.82924,
			73.84531,
			73.89337,
			73.92189,
			73.9554749,
			73.97064,
			74.00247,
			74.01901,
			74.0495758,
			74.08208,
			74.09677,
			74.13034,
			74.14368,
			74.1756744,
			74.20853,
			74.22499,
			74.2565,
			74.27002,
			74.30255,
			74.3180847,
			74.35008,
			74.38092,
			74.39577,
			74.42676,
			74.44444,
			74.4758453,
			74.50722,
			74.52188,
			74.55287,
			74.57109,
			74.60191,
			74.61742,
			74.64804,
			74.6809845,
			74.69685,
			74.72592,
			74.75792,
			74.7738647,
			74.80362,
			74.8216858,
			74.8540649,
			74.86859,
			74.90025,
			74.94867,
			74.996994,
			75.02573,
			75.04375,
			75.07483,
			75.10681,
			75.12181,
			75.1703949,
			75.21791,
			75.26289,
			75.27736,
			75.3097839,
			75.32692,
			75.35838,
			75.37387,
			75.40599,
			75.4369049,
			75.45233,
			75.4693451,
			75.49997,
			75.5464,
			75.5771,
			75.59314,
			75.63933,
			75.65663,
			75.6695557,
			75.70317,
			75.7196045,
			75.74913,
			75.78099,
			75.79669,
			75.84422,
			75.875824,
			75.9073944,
			75.9232941,
			75.95236,
			75.97035,
			76.00287,
			76.0188751,
			76.06555,
			76.0944061,
			76.12758,
			76.15704,
			76.1750946,
			76.2057343,
			76.2217255,
			76.25061,
			76.28314,
			76.2995,
			76.3304138,
			76.34691,
			76.37572,
			76.3932343,
			76.4248047,
			76.45332,
			76.46991,
			76.49809,
			76.52907,
			76.5460052,
			76.57727,
			76.60832,
			76.6403656,
			76.65585,
			76.67439,
			76.70505,
			76.73491,
			76.750946,
			76.76793,
			76.79671,
			76.82747,
			76.87654,
			76.90704,
			76.92305,
			76.97113,
			77.00313,
			77.01865,
			77.04924,
			77.08121,
			77.11412,
			77.1299744,
			77.14615,
			77.17592,
			77.20711,
			77.23999,
			77.25551,
			77.2695,
			77.2993,
			77.3180542,
			77.3508148,
			77.39694,
			77.4301,
			77.4444,
			77.49194,
			77.5079651,
			77.52246,
			77.556076,
			77.56908,
			77.6021,
			77.61961,
			77.65013,
			77.6826,
			77.69716,
			77.73044,
			77.74658,
			77.77571,
			77.80928,
			77.83992,
			77.85478,
			77.87254,
			77.90436,
			77.91991,
			77.94934,
			77.99904,
			78.02913,
			78.04529,
			78.076355,
			78.10751,
			78.1217346,
			78.15227,
			78.16878,
			78.2163849,
			78.23006,
			78.2469,
			78.28009,
			78.2957153,
			78.342926,
			78.3744659,
			78.40619,
			78.42052,
			78.4530945,
			78.46759,
			78.50061,
			78.52986,
			78.54614,
			78.57942,
			78.59558,
			78.6277,
			78.65672,
			78.673996,
			78.70439,
			78.72189,
			78.74982,
			78.78334,
			78.7971649,
			78.8292542,
			78.84515,
			78.87517,
			78.90895,
			78.92296,
			78.9560242,
			78.9710541,
			79.00313,
			79.01955,
			79.05063,
			79.08266,
			79.096405,
			79.1297455,
			79.16026,
			79.17668,
			79.19273,
			79.22322,
			79.25427,
			79.26926,
			79.3018646,
			79.33432,
			79.3484039,
			79.3809052,
			79.3956451,
			79.425354,
			79.4584045,
			79.47504,
			79.5055847,
			79.5214539,
			79.55147,
			79.58443,
			79.59888,
			79.629776,
			79.64348,
			79.67656,
			79.694,
			79.723526,
			79.75607,
			79.77109,
			79.80528,
			79.82063,
			79.86737,
			79.8982849,
			79.93059,
			79.9463043,
			79.9762,
			80.02319,
			80.05527,
			80.0692,
			80.10376,
			80.11774,
			80.15163,
			80.18121,
			80.19676,
			80.22719,
			80.25862,
			80.27568,
			80.30771,
			80.3235,
			80.35396,
			80.3711243,
			80.41754,
			80.44798,
			80.4802551,
			80.51056,
			80.52576,
			80.557785,
			80.57289,
			80.60503,
			80.61987,
			80.65184,
			80.6819153,
			80.6976,
			80.745224,
			80.7738953,
			80.80661,
			80.82089,
			80.85428,
			80.86778,
			80.90076,
			80.93385,
			80.96411,
			80.99533,
			81.02643,
			81.04468,
			81.07349,
			81.10753,
			81.1199951,
			81.1515656,
			81.1701355,
			81.19946,
			81.24823,
			81.27951,
			81.29541,
			81.32669,
			81.35921,
			81.37433,
			81.40546,
			81.41977,
			81.4512939,
			81.46782,
			81.49713,
			81.52739,
			81.54613,
			81.57648,
			81.59248,
			81.62201,
			81.6555,
			81.67224,
			81.70415,
			81.71803,
			81.75006,
			81.76755,
			81.79697,
			81.83037,
			81.84314,
			81.8757,
			81.90723,
			81.9237061,
			81.95325,
			81.96875,
			82.00238,
			82.0336151,
			82.0639,
			82.08237,
			82.09573,
			82.14526,
			82.17502,
			82.20764,
			82.22395,
			82.2544861,
			82.27173,
			82.30177,
			82.33118,
			82.36307,
			82.37782,
			82.39325,
			82.42529,
			82.45636,
			82.48891,
			82.503006,
			82.5199,
			82.5504761,
			82.58208,
			82.59863,
			82.62979,
			82.64656,
			82.6763153,
			82.6945648,
			82.72435,
			82.75543,
			82.7879,
			82.80296,
			82.83351,
			82.84999,
			82.879,
			82.89674,
			82.9283142,
			82.9430542,
			82.97356,
			83.00459,
			83.0372,
			83.05313,
			83.06865,
			83.09799,
			83.1309357,
			83.14673,
			83.17799,
			83.19275,
			83.22444,
			83.25597,
			83.27164,
			83.30304,
			83.3205261,
			83.3490753,
			83.38182,
			83.41281,
			83.4277954,
			83.46037,
			83.4743958,
			83.50389,
			83.52138,
			83.5687561,
			83.60112,
			83.6324158,
			83.6482239,
			83.67903,
			83.69556,
			83.72539,
			83.75838,
			83.7738953,
			83.80661,
			83.81998,
			83.85397,
			83.86951,
			83.899704,
			83.93404,
			83.9495544,
			83.9775848,
			83.99632,
			84.02513,
			84.05664,
			84.07283,
			84.1042,
			84.1181946,
			84.1492157,
			84.18179,
			84.19658,
			84.22981,
			84.24562,
			84.27681,
			84.3069458,
			84.3213348,
			84.35188,
			84.36894,
			84.40234,
			84.4337,
			84.46513,
			84.48093,
			84.495636,
			84.52872,
			84.54346,
			84.57448,
			84.5925,
			84.62407,
			84.65404,
			84.66907,
			84.7022552,
			84.74913,
			84.79422,
			84.82526,
			84.85722,
			84.88739,
			84.9043,
			84.91777,
			84.95073,
			84.9808044,
			84.995575,
			85.0263062,
			85.0578461,
			85.07497,
			85.10452,
			85.12187,
			85.15091,
			85.18298,
			85.20146,
			85.23192,
			85.2475739,
			85.27652,
			85.29381,
			85.3263855,
			85.35687,
			85.3743744,
			85.40175,
			85.4195862,
			85.45206,
			85.4841,
			85.49907,
			85.52983,
			85.5466461,
			85.57716,
			85.60785,
			85.62428,
			85.65622,
			85.67134,
			85.7038,
			85.74982,
			85.78085,
			85.79645,
			85.8274841,
			85.85841,
			85.87395,
			85.9065552,
			85.91995,
			85.95491,
			85.96887,
			85.9996,
			86.03044,
			86.0475,
			86.0755,
			86.09401,
			86.1402,
			86.15651,
			86.17116,
			86.20155,
			86.23459,
			86.2511,
			86.28165,
			86.2971649,
			86.3272552,
			86.34325,
			86.39139,
			86.4044,
			86.4204254,
			86.45346,
			86.48346,
			86.5002,
			86.53009,
			86.5480347,
			86.57704,
			86.5930939,
			86.62457,
			86.6561,
			86.67468,
			86.7042542,
			86.72066,
			86.76831,
			86.8007355,
			86.83003,
			86.84668,
			86.87915,
			86.8938,
			86.92639,
			86.95737,
			86.98889,
			87.0189362,
			87.05095,
			87.06746,
			87.09767,
			87.13002,
			87.14755,
			87.1765442,
			87.19408,
			87.22522,
			87.27287,
			87.30484,
			87.3202057,
			87.35069,
			87.38324,
			87.39731,
			87.4297943,
			87.44437,
			87.47688,
			87.50935,
			87.52286,
			87.55594,
			87.5869,
			87.60396,
			87.63507,
			87.65103,
			87.681,
			87.71298,
			87.72612,
			87.74361,
			87.7744446,
			87.8039246,
			87.83601,
			87.8534546,
			87.88199,
			87.90102,
			87.93048,
			87.94701,
			87.97684,
			88.00737,
			88.04001,
			88.0554047,
			88.08949,
			88.10509,
			88.12045,
			88.14998,
			88.18152,
			88.19615,
			88.2260742,
			88.24408,
			88.27519,
			88.30858,
			88.32329,
			88.355835,
			88.37045,
			88.40201,
			88.41753,
			88.45055,
			88.48207,
			88.4967957,
			88.54167,
			88.55914,
			88.5742645,
			88.60634,
			88.62172,
			88.6557159,
			88.66875,
			88.7012,
			88.74982,
			88.78053,
			88.79707,
			88.82684,
			88.84241,
			88.87494,
			88.90752,
			88.92192,
			88.95549,
			88.969574,
			89.00159,
			89.0341,
			89.04959,
			89.08081,
			89.09631,
			89.12729,
			89.14317,
			89.175705,
			89.20726,
			89.22279,
			89.25676,
			89.27028,
			89.30333,
			89.3178253,
			89.3493347,
			89.38287,
			89.39659,
			89.42691,
			89.44348,
			89.4764557,
			89.5070953,
			89.52399,
			89.55554,
			89.57214,
			89.6040649,
			89.61958,
			89.6500244,
			89.68303,
			89.69583,
			89.7292938,
			89.76039,
			89.77638,
			89.8080444,
			89.82443,
			89.85297,
			89.8700256,
			89.90062,
			89.93062,
			89.96341,
			89.99394,
			90.0244446,
			90.0563049,
			90.07298,
			90.1049652,
			90.11934,
			90.15138,
			90.16951,
			90.19934,
			90.23018,
			90.24594,
			90.27844,
			90.29492,
			90.32422,
			90.342804,
			90.37428,
			90.40431,
			90.42236,
			90.45334,
			90.4698639,
			90.515564,
			90.5329,
			90.54831,
			90.5801239,
			90.59436,
			90.62634,
			90.64432,
			90.6768341,
			90.70747,
			90.72238,
			90.75339,
			90.77048,
			90.80191,
			90.81741,
			90.8469849,
			90.8794861,
			90.8944855,
			90.92662,
			90.94408,
			90.97278,
			91.0065155,
			91.02147,
			91.05437,
			91.06789,
			91.11597,
			91.13072,
			91.14897,
			91.17769,
			91.19678,
			91.22621,
			91.25874,
			91.2767944,
			91.30638,
			91.32327,
			91.3543549,
			91.37244,
			91.40137,
			91.43378,
			91.44896,
			91.48193,
			91.49393,
			91.52646,
			91.55847,
			91.57359,
			91.6038361,
			91.62138,
			91.6523743,
			91.66992,
			91.70076,
			91.71745,
			91.74899,
			91.78093,
			91.79654,
			91.82654,
			91.8441,
			91.87613,
			91.90573,
			91.92166,
			91.95468,
			91.97035,
			92.0027161,
			92.0182343,
			92.05075,
			92.0970154,
			92.12787,
			92.1598358,
			92.1925049,
			92.22345,
			92.2544556,
			92.27098,
			92.3145752,
			92.33008,
			92.36371,
			92.3770142,
			92.41058,
			92.42607,
			92.45659,
			92.4881744,
			92.50316,
			92.52069,
			92.55167,
			92.58325,
			92.59958,
			92.6300659,
			92.6453857,
			92.69069,
			92.70732,
			92.72171,
			92.75432,
			92.76984,
			92.80191,
			92.8179,
			92.85092,
			92.87996,
			92.8958,
			92.92821,
			92.94347,
			92.9754639,
			93.00574,
			93.02104,
			93.05141,
			93.06792,
			93.11746,
			93.14847,
			93.18062,
			93.19356,
			93.2270355,
			93.24356,
			93.27144,
			93.30513,
			93.33598,
			93.3515,
			93.38286,
			93.39868,
			93.43028,
			93.4466553,
			93.47595,
			93.49243,
			93.5244446,
			93.55698,
			93.5740051,
			93.60666,
			93.6205444,
			93.65456,
			93.6695862,
			93.716156,
			93.73268,
			93.74716,
			93.77893,
			93.79497,
			93.82422,
			93.85625,
			93.87302,
			93.9033051,
			93.92084,
			93.9519348,
			93.96788,
			93.9971161,
			94.02911,
			94.0584259,
			94.07553,
			94.10657,
			94.12146,
			94.15253,
			94.16951,
			94.2015839,
			94.23323,
			94.24713,
			94.29735,
			94.3251648,
			94.35867,
			94.3737,
			94.40727,
			94.42177,
			94.45326,
			94.46979,
			94.5024261,
			94.53279,
			94.56329,
			94.59581,
			94.62642,
			94.65947,
			94.673996,
			94.70659,
			94.724,
			94.75455,
			94.76958,
			94.80206,
			94.81763,
			94.84866,
			94.8970642,
			94.92961,
			94.960434,
			94.9743958,
			95.00694,
			95.02246,
			95.05591,
			95.07208,
			95.1019745,
			95.11746,
			95.1495056,
			95.1830139,
			95.19661,
			95.2275543,
			95.2453156,
			95.2765961,
			95.30725,
			95.32164,
			95.35248,
			95.3700256,
			95.40201,
			95.41753,
			95.4504242,
			95.47995,
			95.49765,
			95.52777,
			95.54442,
			95.57376,
			95.6042938,
			95.62236,
			95.6533356,
			95.67035,
			95.70099,
			95.71745,
			95.74747,
			95.79349,
			95.8275757,
			95.8585052,
			95.87366,
			95.9062347,
			95.9215546,
			95.9541,
			95.96922,
			96.00169,
			96.03415,
			96.0637054,
			96.09419,
			96.1279449,
			96.14378,
			96.17288,
			96.2063751,
			96.22179,
			96.25012,
			96.26848,
			96.3171539,
			96.33237,
			96.3472,
			96.37692,
			96.41002,
			96.42445,
			96.47371,
			96.50392,
			96.53537,
			96.56682,
			96.58383,
			96.59909,
			96.62967,
			96.64615,
			96.67508,
			96.70891,
			96.72241,
			96.75549,
			96.7715759,
			96.80199,
			96.8175049,
			96.8490753,
			96.8829651,
			96.89668,
			96.94357,
			96.97606,
			97.0067749,
			97.0241547,
			97.05261,
			97.06813,
			97.10045,
			97.1323853,
			97.1492157,
			97.19556,
			97.228,
			97.2428,
			97.2758942,
			97.30586,
			97.3233643,
			97.35443,
			97.38385,
			97.39926,
			97.4299,
			97.4465942,
			97.49388,
			97.52643,
			97.55937,
			97.58989,
			97.60693,
			97.62344,
			97.6539459,
			97.6704559,
			97.70351,
			97.73218,
			97.74805,
			97.78082,
			97.79726,
			97.83011,
			97.84534,
			97.89264,
			97.9236755,
			97.95476,
			97.97087,
			98.0038147,
			98.01936,
			98.05179,
			98.081955,
			98.0984955,
			98.1300659,
			98.15938,
			98.17732,
			98.20729,
			98.2238,
			98.2543945,
			98.2848358,
			98.30084,
			98.31883,
			98.34889,
			98.38243,
			98.39873,
			98.43027,
			98.444046,
			98.47554,
			98.49301,
			98.53819,
			98.55655,
			98.57123,
			98.60269,
			98.61812,
			98.6476,
			98.68036,
			98.69417,
			98.72682,
			98.74376,
			98.77574,
			98.80771,
			98.82117,
			98.85315,
			98.87068,
			98.90108,
			98.94974,
			98.9805145,
			98.99654,
			99.02733,
			99.0433044,
			99.07492,
			99.10698,
			99.11987,
			99.15192,
			99.1824646,
			99.20096,
			99.2299957,
			99.24725,
			99.27608,
			99.30859,
			99.34068,
			99.3561249,
			99.3717651,
			99.40213,
			99.41969,
			99.45317,
			99.46878,
			99.50008,
			99.5323,
			99.54785,
			99.57732,
			99.59433,
			99.625824,
			99.64339,
			99.6738739,
			99.70651,
			99.72241,
			99.75447,
			99.7682953,
			99.80063,
			99.83139,
			99.84596,
			99.87656,
			99.91008,
			99.9392548,
			99.95468,
			99.97275,
			100.00267,
			100.019196,
			100.0502,
			100.081741,
			100.09729,
			100.129013,
			100.143829,
			100.177322,
			100.207352,
			100.241867,
			100.269882,
			100.303452,
			100.317963,
			100.347977,
			100.380325,
			100.397232,
			100.426514,
			100.460083,
			100.473709,
			100.506851,
			100.520844,
			100.552414,
			100.570419,
			100.617813,
			100.66391,
			100.6819,
			100.710922,
			100.725433,
			100.757446,
			100.772583,
			100.805649,
			100.822037,
			100.869049,
			100.900269,
			100.932114,
			100.947632,
			100.97937,
			101.010666,
			101.02417,
			101.057205,
			101.073868,
			101.105377,
			101.137283,
			101.150787,
			101.167923,
			101.198715,
			101.229355,
			101.24614,
			101.276382,
			101.308411,
			101.339584,
			101.355972,
			101.371,
			101.404007,
			101.417511,
			101.450531,
			101.483063,
			101.498962,
			101.529816,
			101.546646,
			101.576645,
			101.607819,
			101.624176,
			101.656204,
			101.668228,
			101.718277,
			101.763031,
			101.79567,
			101.826355,
			101.8569,
			101.873947,
			101.904449,
			101.920456,
			101.952988,
			101.971115,
			102.001373,
			102.0184,
			102.063263,
			102.08046,
			102.094269,
			102.124283,
			102.157822,
			102.18985,
			102.22142,
			102.253387,
			102.269913,
			102.315048,
			102.330688,
			102.347488,
			102.378708,
			102.410065,
			102.424545,
			102.472244,
			102.501877,
			102.517624,
			102.550705,
			102.582687,
			102.596222,
			102.629532,
			102.661285,
			102.67627,
			102.706787,
			102.722549,
			102.753571,
			102.770096,
			102.802628,
			102.834106,
			102.849228,
			102.89595,
			102.9258,
			102.943268,
			102.972336,
			103.00325,
			103.035355,
			103.051346,
			103.0822,
			103.099243,
			103.128967,
			103.14325,
			103.175781,
			103.192825,
			103.224838,
			103.256866,
			103.273544,
			103.305573,
			103.335938,
			103.352463,
			103.38327,
			103.396774,
			103.42778,
			103.447174,
			103.477692,
			103.525162,
			103.572296,
			103.605377,
			103.62085,
			103.652756,
			103.6828,
			103.699524,
			103.7301,
			103.7469,
			103.777405,
			103.792877,
			103.824921,
			103.871582,
			103.90303,
			103.9205,
			103.949509,
			103.981384,
			103.997269,
			104.029236,
			104.0426,
			104.074753,
			104.106262,
			104.121674,
			104.151718,
			104.169754,
			104.202255,
			104.231552,
			104.248352,
			104.276855,
			104.29332,
			104.325958,
			104.343353,
			104.373886,
			104.403931,
			104.435471,
			104.466965,
			104.485504,
			104.498474,
			104.532333,
			104.54805,
			104.5788,
			104.715729,
			104.746277,
			104.775238,
			104.792755,
			104.839386,
			104.856308,
			104.871506,
			104.9019,
			104.918869,
			104.947952,
			104.980774,
			105.012772,
			105.026566,
			105.043137,
			105.076141,
			105.106323,
			105.121155,
			105.153168,
			105.16925,
			105.200714,
			105.233765,
			105.264969,
			105.280273,
			105.296005,
			105.326324,
			105.357834,
			105.37294,
			105.404221,
			105.437775,
			105.451263,
			105.469284,
			105.50032,
			105.5311,
			105.548874,
			105.594437,
			105.624405,
			105.657944,
			105.674545,
			105.705154,
			105.721008,
			105.751022,
			105.768051,
			105.799408,
			105.829468,
			105.846558,
			105.891357,
			105.906479,
			105.938522,
			105.954453,
			105.986923,
			106.000931,
			106.031448,
			106.048538,
			106.079529,
			106.097046,
			106.127136,
			106.142639,
			106.172409,
			106.205963,
			106.221375,
			106.266632,
			106.283386,
			106.314941,
			106.330475,
			106.346176,
			106.3768,
			106.409378,
			106.42485,
			106.456223,
			106.487274,
			106.502274,
			106.519272,
			106.551315,
			106.582886,
			106.599014,
			106.646713,
			106.679138,
			106.692459,
			106.723282,
			106.754379,
			106.786407,
			106.803513,
			106.819992,
			106.851593,
			106.881073,
			106.899078,
			106.93013,
			106.946365,
			106.976395,
			107.023376,
			107.055908,
			107.071091,
			107.102509,
			107.11795,
			107.151077,
			107.181259,
			107.198547,
			107.2296,
			107.259094,
			107.276596,
			107.306137,
			107.322647,
			107.355225,
			107.370239,
			107.402206,
			107.41774,
			107.448334,
			107.479462,
			107.496811,
			107.526505,
			107.557541,
			107.572174,
			107.6071,
			107.621613,
			107.65303,
			107.682541,
			107.700623,
			107.730362,
			107.747375,
			107.775894,
			107.792923,
			107.825928,
			107.857483,
			107.874542,
			107.9035,
			107.936142,
			107.951553,
			107.981628,
			107.997391,
			108.029892,
			108.059158,
			108.076752,
			108.107239,
			108.1228,
			108.155304,
			108.186249,
			108.203262,
			108.233841,
			108.250015,
			108.281464,
			108.311188,
			108.326904,
			108.343918,
			108.375427,
			108.406967,
			108.4225,
			108.454437,
			108.469467,
			108.5011,
			108.548538,
			108.579956,
			108.59584,
			108.626358,
			108.658264,
			108.67485,
			108.704346,
			108.721313,
			108.753342,
			108.768372,
			108.801392,
			108.831055,
			108.863815,
			108.894318,
			108.926361,
			108.957367,
			108.987915,
			109.001923,
			109.019531,
			109.050995,
			109.080994,
			109.0959,
			109.130051,
			109.146042,
			109.17836,
			109.1936,
			109.223145,
			109.254654,
			109.270813,
			109.301224,
			109.332535,
			109.362946,
			109.37677,
			109.393784,
			109.425537,
			109.456039,
			109.472717,
			109.521606,
			109.552658,
			109.582886,
			109.596878,
			109.630051,
			109.646057,
			109.677963,
			109.708771,
			109.724289,
			109.772995,
			109.80455,
			109.83374,
			109.850952,
			109.881943,
			109.899231,
			109.929489,
			109.945175,
			109.9776,
			110.007568,
			110.0231,
			110.053055,
			110.0701,
			110.101944,
			110.117722,
			110.1483,
			110.181778,
			110.195709,
			110.225891,
			110.259689,
			110.275848,
			110.308289,
			110.32431,
			110.356354,
			110.387344,
			110.403366,
			110.435013,
			110.452469,
			110.481934,
			110.496628,
			110.530487,
			110.5457,
			110.592041,
			110.609085,
			110.640732,
			110.656082,
			110.669617,
			110.704178,
			110.71814,
			110.751205,
			110.782227,
			110.796112,
			110.827759,
			110.874374,
			110.907364,
			110.9234,
			110.955383,
			110.969421,
			111.0034,
			111.0184,
			111.050339,
			111.080994,
			111.1124,
			111.128296,
			111.142609,
			111.1761,
			111.208176,
			111.222672,
			111.253647,
			111.270218,
			111.300385,
			111.334229,
			111.347748,
			111.39328,
			111.424789,
			111.456696,
			111.487228,
			111.504349,
			111.535782,
			111.552277,
			111.569366,
			111.599106,
			111.63092,
			111.64563,
			111.677368,
			111.6929,
			111.722916,
			111.753967,
			111.77002,
			111.801392,
			111.817657,
			111.850525,
			111.895935,
			111.927124,
			111.943161,
			111.97374,
			112.003174,
			112.021713,
			112.053192,
			112.068741,
			112.098846,
			112.129868,
			112.162811,
			112.177307,
			112.192459,
			112.226349,
			112.256882,
			112.273911,
			112.305527,
			112.318924,
			112.367569,
			112.397293,
			112.430344,
			112.445953,
			112.476807,
			112.493286,
			112.525345,
			112.557526,
			112.573273,
			112.602585,
			112.620087,
			112.650681,
			112.681152,
			112.6969,
			112.727692,
			112.745956,
			112.77803,
			112.79277,
			112.82576,
			112.854782,
			112.888,
			112.904007,
			112.919968,
			112.949493,
			112.98259,
			112.997147,
			113.029266,
			113.044113,
			113.074112,
			113.108719,
			113.122147,
			113.153183,
			113.1862,
			113.201721,
			113.231277,
			113.249786,
			113.2818,
			113.295563,
			113.326843,
			113.342896,
			113.371979,
			113.406174,
			113.420929,
			113.452881,
			113.48497,
			113.515,
			113.529205,
			113.546478,
			113.578751,
			113.608566,
			113.626038,
			113.671722,
			113.702118,
			113.718613,
			113.74913,
			113.782715,
			113.796448,
			113.82991,
			113.858917,
			113.874268,
			113.90686,
			113.923752,
			113.9523,
			113.971329,
			114.017334,
			114.034378,
			114.0499,
			114.079941,
			114.112106,
			114.1277,
			114.143448,
			114.172638,
			114.205688,
			114.221054,
			114.269562,
			114.301468,
			114.3181,
			114.350113,
			114.39624,
			114.427185,
			114.460266,
			114.4758,
			114.50676,
			114.521759,
			114.554825,
			114.568817,
			114.601334,
			114.617844,
			114.648239,
			114.679947,
			114.695663,
			114.728241,
			114.743011,
			114.775009,
			114.806168,
			114.821564,
			114.852066,
			114.868576,
			114.901108,
			114.917618,
			114.948639,
			114.980377,
			114.997055,
			115.02771,
			115.060272,
			115.07637,
			115.107819,
			115.12439,
			115.154449,
			115.184845,
			115.201355,
			115.230591,
			115.248077,
			115.277451,
			115.292923,
			115.326019,
			115.3575,
			115.373138,
			115.406677,
			115.421585,
			115.46759,
			115.499878,
			115.532852,
			115.547638,
			115.579346,
			115.597061,
			115.626556,
			115.656616,
			115.674713,
			115.705276,
			115.73468,
			115.750183,
			115.782257,
			115.796967,
			115.82724,
			115.845474,
			115.876862,
			115.908325,
			115.926834,
			115.957413,
			115.9899,
			116.004608,
			116.019943,
			116.052505,
			116.068024,
			116.0965,
			116.127518,
			116.144577,
			116.177567,
			116.193069,
			116.240616,
			116.256622,
			116.288147,
			116.305328,
			116.3358,
			116.349274,
			116.3815,
			116.39566,
			116.426865,
			116.445129,
			116.475189,
			116.505859,
			116.523651,
			116.553467,
			116.569992,
			116.600479,
			116.630859,
			116.649551,
			116.679565,
			116.6974,
			116.726608,
			116.743134,
			116.774628,
			116.80777,
			116.821274,
			116.853775,
			116.8698,
			116.902344,
			116.931015,
			116.946625,
			116.978912,
			116.99469,
			117.026672,
			117.0562,
			117.073761,
			117.104324,
			117.118759,
			117.151886,
			117.184311,
			117.215424,
			117.230072,
			117.246246,
			117.275986,
			117.306961,
			117.3255,
			117.356018,
			117.372604,
			117.402008,
			117.41954,
			117.450546,
			117.480591,
			117.496109,
			117.527847,
			117.544205,
			117.573166,
			117.6042,
			117.619705,
			117.66774,
			117.715836,
			117.729568,
			117.747833,
			117.776855,
			117.793365,
			117.825363,
			117.872055,
			117.90448,
			117.91954,
			117.951477,
			117.981552,
			117.996826,
			118.0293,
			118.046341,
			118.091644,
			118.125168,
			118.153931,
			118.171082,
			118.202469,
			118.218964,
			118.2505,
			118.282028,
			118.297791,
			118.343567,
			118.375595,
			118.407379,
			118.421677,
			118.453156,
			118.470688,
			118.502731,
			118.533737,
			118.5643,
			118.5961,
			118.627808,
			118.657852,
			118.674377,
			118.706985,
			118.720886,
			118.752945,
			118.768951,
			118.815491,
			118.8315,
			118.847748,
			118.87851,
			118.909821,
			118.925339,
			118.9588,
			118.972931,
			119.006866,
			119.02243,
			119.05275,
			119.085815,
			119.116867,
			119.130676,
			119.146362,
			119.179626,
			119.195206,
			119.224442,
			119.254944,
			119.271729,
			119.303116,
			119.31749,
			119.349518,
			119.381638,
			119.396118,
			119.4436,
			119.476151,
			119.49263,
			119.522171,
			119.555725,
			119.57074,
			119.600739,
			119.630661,
			119.663086,
			119.680054,
			119.695557,
			119.725388,
			119.758392,
			119.772018,
			119.805237,
			119.819977,
			119.869507,
			119.898956,
			119.930084,
			119.946075,
			119.994354,
			120.025635,
			120.072311,
			120.104843,
			120.119278,
			120.150757,
			120.182846,
			120.199265,
			120.230576,
			120.246216,
			120.276932,
			120.293381,
			120.323883,
			120.355469,
			120.371536,
			120.401978,
			120.417465,
			120.451996,
			120.48204,
			120.498779,
			120.529541,
			120.558838,
			120.57402,
			120.607559,
			120.62027,
			120.653778,
			120.685333,
			120.700256,
			120.71788,
			120.747391,
			120.779129,
			120.809006,
			120.826447,
			120.855545,
			120.871048,
			120.901932,
			120.918442,
			120.950974,
			120.983078,
			120.995926,
			121.030319,
			121.045959,
			121.0771,
			121.107605,
			121.124619,
			121.156174,
			121.169693,
			121.217712,
			121.24881,
			121.297,
			121.3286,
			121.343338,
			121.37384,
			121.40654,
			121.4364,
			121.45491,
			121.469421,
			121.501953,
			121.517731,
			121.548737,
			121.5957,
			121.627304,
			121.642807,
			121.674347,
			121.705963,
			121.739166,
			121.753036,
			121.769165,
			121.801559,
			121.832809,
			121.847672,
			121.880508,
			121.895981,
			121.927246,
			121.944244,
			121.975342,
			122.005936,
			122.020844,
			122.05278,
			122.068787,
			122.099228,
			122.129547,
			122.1472,
			122.177643,
			122.192657,
			122.240219,
			122.255707,
			122.287277,
			122.301758,
			122.317749,
			122.349777,
			122.380356,
			122.412857,
			122.441895,
			122.458908,
			122.475418,
			122.506973,
			122.522476,
			122.551971,
			122.569489,
			122.602005,
			122.647659,
			122.680267,
			122.694077,
			122.725616,
			122.742706,
			122.775208,
			122.807205,
			122.822067,
			122.85321,
			122.883789,
			122.91684,
			122.930557,
			122.962372,
			122.977692,
			123.025223,
			123.057236,
			123.070755,
			123.103882,
			123.120789,
			123.167938,
			123.197266,
			123.229996,
			123.258942,
			123.275269,
			123.307159,
			123.323181,
			123.3517,
			123.370255,
			123.40213,
			123.430573,
			123.449707,
			123.49556,
			123.529144,
			123.543304,
			123.576355,
			123.606354,
			123.622879,
			123.654343,
			123.669907,
			123.700089,
			123.748947,
			123.779739,
			123.793533,
			123.840927,
			123.856369,
			123.872513,
			123.905136,
			123.920944,
			123.951981,
			123.983566,
			123.997391,
			124.029282,
			124.04454,
			124.076645,
			124.109085,
			124.125717,
			124.156693,
			124.172531,
			124.220657,
			124.251755,
			124.283218,
			124.31337,
			124.3297,
			124.345383,
			124.377151,
			124.408218,
			124.425278,
			124.469223,
			124.515793,
			124.534286,
			124.549789,
			124.595749,
			124.626877,
			124.657883,
			124.673416,
			124.706955,
			124.722946,
			124.753983,
			124.769,
			124.802261,
			124.832718,
			124.863907,
			124.896408,
			124.927139,
			124.942673,
			124.973679,
			125.020828,
			125.05275,
			125.0818,
			125.100815,
			125.1299,
			125.147354,
			125.177353,
			125.2094,
			125.223953,
			125.273361,
			125.302719,
			125.33577,
			125.36647,
			125.399857,
			125.430771,
			125.447372,
			125.475876,
			125.495132,
			125.525436,
			125.572647,
			125.604477,
			125.620468,
			125.651535,
			125.683029,
			125.696579,
			125.7289,
			125.777206,
			125.8242,
			125.855453,
			125.868973,
			125.901505,
			125.932724,
			125.951065,
			125.980362,
			125.99736,
			126.026596,
			126.059128,
			126.076279,
			126.107285,
			126.140244,
			126.154221,
			126.186752,
			126.218796,
			126.248337,
			126.281723,
			126.295761,
			126.345169,
			126.374924,
			126.406113,
			126.421951,
			126.453476,
			126.468491,
			126.502029,
			126.517593,
			126.547073,
			126.580673,
			126.610184,
			126.624619,
			126.672287,
			126.704147,
			126.721909,
			126.753922,
			126.767929,
			126.802444,
			126.817947,
			126.848618,
			126.880234,
			126.89653,
			126.926559,
			126.942612,
			126.975632,
			127.004745,
			127.019608,
			127.0547,
			127.085678,
			127.11525,
			127.146042,
			127.191269,
			127.208328,
			127.224808,
			127.27037,
			127.304184,
			127.320175,
			127.351822,
			127.367874,
			127.397415,
			127.447189,
			127.476425,
			127.492935,
			127.541122,
			127.556107,
			127.5703,
			127.617294,
			127.632492,
			127.649338,
			127.682388,
			127.697128,
			127.730179,
			127.745712,
			127.777634,
			127.809982,
			127.82399,
			127.87114,
			127.903091,
			127.919083,
			127.950729,
			127.981277,
			128.014359,
			128.029831,
			128.04567,
			128.092361,
			128.123917,
			128.154953,
			128.170517,
			128.2025,
			128.219986,
			128.24968,
			128.28273,
			128.284286
		]
	],
	'4252_4944_7982': [
		[
			66.796
		],
		[
			0
		],
		[
			0,
			3.182,
			5.815,
			7.135,
			9.779,
			11.103,
			13.757,
			17.752,
			20.424,
			23.103,
			27.134,
			29.83,
			33.887,
			35.243,
			36.6,
			39.32,
			40.682,
			43.412,
			46.148,
			50.266,
			53.018,
			57.16,
			58.544,
			59.93,
			64.098,
			66.885,
			71.078,
			73.882,
			76.693,
			78.101,
			80.923,
			82.336,
			85.168,
			88.006,
			89.428,
			92.275,
			95.129,
			99.422,
			100.856,
			102.292,
			106.609,
			108.051,
			109.495,
			113.836,
			116.738,
			119.646,
			124.022,
			126.95,
			131.353,
			132.824,
			134.294,
			138.692,
			140.148,
			141.597,
			145.894,
			148.713,
			151.495,
			152.874,
			155.602,
			158.297,
			159.63,
			163.58,
			166.17,
			168.727,
			172.494,
			174.96,
			178.592,
			179.785,
			180.97,
			184.467,
			186.746,
			190.089,
			192.265,
			194.419,
			195.456,
			197.532,
			198.573,
			199.571,
			202.569,
			203.543,
			205.464,
			207.344,
			209.189,
			211.004,
			211.898,
			213.667,
			215.415,
			216.283,
			218.009,
			218.866,
			220.577,
			222.283,
			224.84,
			226.545,
			229.094,
			229.946,
			230.799,
			233.339,
			235.036,
			237.59,
			239.295,
			241.003,
			241.883,
			243.647,
			244.529,
			246.306,
			248.164,
			249.093,
			250.951,
			252.88,
			254.812,
			257.789,
			259.812,
			261.88,
			262.955,
			265.102,
			266.175,
			268.339,
			270.503,
			273.543,
			275.538,
			278.352,
			279.274,
			280.198,
			282.893,
			284.666,
			287.313,
			289.064,
			290.823,
			291.705,
			293.474,
			294.363,
			296.149,
			297.952,
			298.858,
			300.679,
			302.52,
			305.307,
			306.243,
			307.19,
			310.047,
			311.005,
			311.974,
			314.906,
			316.881,
			318.881,
			321.906,
			323.95,
			327.04,
			328.08,
			329.122,
			332.261,
			333.311,
			334.361,
			337.508,
			339.605,
			341.708,
			342.747,
			344.863,
			346.986,
			348.05,
			350.184,
			351.255,
			353.403,
			355.56,
			358.813,
			360.994,
			364.284,
			365.385,
			366.489,
			369.819,
			372.051,
			375.418,
			377.674,
			379.94,
			381.076,
			383.357,
			384.501,
			386.797,
			389.102,
			390.258,
			391.417,
			394.906,
			397.245,
			399.593,
			400.771,
			403.133,
			405.524,
			406.713,
			410.284,
			412.669,
			415.058,
			418.668,
			421.089,
			424.712,
			425.952,
			427.154,
			430.851,
			433.289,
			436.989,
			439.485,
			443.189,
			445.681,
			446.931,
			449.435,
			451.947,
			453.207,
			455.731,
			458.264,
			460.805,
			464.631,
			468.476,
			469.761,
			471.048,
			473.629,
			474.922,
			477.514,
			481.417,
			484.024,
			486.632,
			490.555,
			491.866,
			493.179,
			497.128,
			499.769,
			503.743,
			506.4,
			509.065,
			510.4,
			513.076,
			514.417,
			517.104,
			521.148,
			523.852,
			526.563,
			530.644,
			532.007,
			533.372,
			537.478,
			538.85,
			540.224,
			542.977,
			544.356,
			547.12,
			551.281,
			554.064,
			556.854,
			561.054,
			562.458,
			563.864,
			568.092,
			569.505,
			570.919,
			575.173,
			578.017,
			582.297,
			585.158,
			588.026,
			589.462,
			592.34,
			593.782,
			596.669,
			599.563,
			603.917,
			605.371,
			606.827,
			611.205,
			612.667,
			614.131,
			618.534,
			621.476,
			625.903,
			628.862,
			633.311,
			636.286,
			637.776,
			639.268,
			643.75,
			645.247,
			646.746,
			651.252,
			654.264,
			658.793,
			661.82,
			664.853,
			666.371,
			669.413,
			670.936,
			673.988,
			677.044,
			681.64,
			684.711,
			689.33,
			690.872,
			692.415,
			697.055,
			700.155,
			704.815,
			707.929,
			711.048,
			712.61,
			715.738,
			717.304,
			720.439,
			725.154,
			728.304,
			731.46,
			736.204,
			737.788,
			739.373,
			742.546,
			745.725,
			747.317,
			752.097,
			755.285,
			758.478,
			763.276,
			766.48,
			771.295,
			772.903,
			774.511,
			779.343,
			782.569,
			787.418,
			790.656,
			793.899,
			795.522,
			798.771,
			802.025,
			803.654,
			808.547,
			811.815,
			815.089,
			820.01,
			821.653,
			823.298,
			828.243,
			829.895,
			831.549,
			836.521,
			839.845,
			843.175,
			848.182,
			851.529,
			856.561,
			858.241,
			859.923,
			864.977,
			868.352,
			873.421,
			876.805,
			880.19,
			881.884,
			885.272,
			886.966,
			890.353,
			895.433,
			898.82,
			902.208,
			907.294,
			910.687,
			915.782,
			917.482,
			919.182,
			924.29,
			927.7,
			932.824,
			936.246,
			939.673,
			944.824,
			946.542,
			948.262,
			953.428,
			955.153,
			956.878,
			962.06,
			965.519,
			968.982,
			970.715,
			974.183,
			977.656,
			979.393,
			982.871,
			984.611,
			988.093,
			991.578,
			996.812,
			1000.306,
			1005.554,
			1007.304,
			1009.055,
			1014.312,
			1017.821,
			1023.093,
			1026.612,
			1030.135,
			1031.898,
			1035.427,
			1037.194,
			1040.73,
			1044.269,
			1046.041,
			1049.587,
			1053.136,
			1056.688,
			1060.243,
			1062.023,
			1065.585,
			1069.15,
			1070.935,
			1074.505,
			1076.291,
			1079.864,
			1085.207,
			1088.741,
			1092.243,
			1097.423,
			1099.13,
			1100.827,
			1105.859,
			1109.164,
			1114.048,
			1117.256,
			1120.426,
			1121.997,
			1125.108,
			1126.65,
			1129.705,
			1132.723,
			1134.219,
			1137.184,
			1140.113,
			1144.434,
			1145.855,
			1147.266,
			1151.446,
			1152.822,
			1154.189,
			1156.893,
			1158.231,
			1160.879,
			1164.782,
			1167.338,
			1169.86,
			1173.573,
			1174.792,
			1176.001,
			1179.578,
			1181.92,
			1185.365,
			1187.613,
			1189.823,
			1190.913,
			1193.066,
			1195.182,
			1196.226,
			1198.286,
			1199.301,
			1201.304,
			1203.271,
			1206.153,
			1207.096,
			1208.028,
			1210.773,
			1211.671,
			1212.561,
			1215.195,
			1216.898,
			1219.375,
			1220.979,
			1222.549,
			1224.8,
			1225.533,
			1226.26,
			1229.056,
			1229.728,
			1231.714,
			1233.007,
			1234.256,
			1234.877,
			1236.11,
			1237.338,
			1237.948,
			1239.151,
			1239.751,
			1240.948,
			1242.142,
			1243.933,
			1245.124,
			1246.908,
			1247.503,
			1248.105,
			1249.931,
			1251.148,
			1252.984,
			1254.249,
			1256.149,
			1257.421,
			1258.063,
			1259.37,
			1260.681,
			1261.338,
			1262.659,
			1264,
			1265.349,
			1266.703,
			1267.382,
			1268.767,
			1270.169,
			1270.871,
			1272.276,
			1272.98,
			1274.446,
			1275.913,
			1278.113,
			1279.636,
			1281.914,
			1282.672,
			1283.44,
			1285.77,
			1287.318,
			1289.658,
			1291.219,
			1292.774,
			1293.557,
			1295.137,
			1295.924,
			1297.495,
			1299.082,
			1299.881,
			1301.474,
			1303.064,
			1305.445,
			1307.026,
			1309.383,
			1310.164,
			1310.943,
			1312.498,
			1313.275,
			1314.828,
			1317.157,
			1318.72,
			1320.301,
			1322.677,
			1323.472,
			1324.273,
			1326.679,
			1328.287,
			1330.724,
			1332.357,
			1333.997,
			1334.818,
			1336.467,
			1337.295,
			1338.952,
			1340.596,
			1341.42,
			1343.077,
			1344.728,
			1347.205,
			1348.868,
			1351.334,
			1352.161,
			1352.992,
			1355.471,
			1357.127,
			1359.617,
			1361.266,
			1362.927,
			1365.4,
			1366.216,
			1367.031,
			1370.296,
			1371.111,
			1373.573,
			1375.229,
			1376.893,
			1377.729,
			1379.412,
			1381.122,
			1381.98,
			1384.577,
			1386.347,
			1388.125,
			1390.839,
			1392.673,
			1395.459,
			1396.399,
			1397.34,
			1400.18,
			1402.084,
			1404.958,
			1406.902,
			1408.856,
			1409.835,
			1411.797,
			1412.783,
			1413.755,
			1416.74,
			1417.716,
			1418.709,
			1421.708,
			1424.705,
			1425.69,
			1426.694,
			1428.71,
			1430.739,
			1431.758,
			1433.804,
			1434.831,
			1436.889,
			1438.958,
			1442.086,
			1444.157,
			1447.265,
			1448.302,
			1449.341,
			1452.462,
			1454.574,
			1457.758,
			1459.894,
			1462.038,
			1463.114,
			1465.272,
			1466.356,
			1468.529,
			1471.81,
			1474.01,
			1476.22,
			1479.554,
			1480.671,
			1481.791,
			1485.165,
			1486.295,
			1487.428,
			1490.84,
			1493.127,
			1495.423,
			1498.887,
			1501.208,
			1504.705,
			1505.876,
			1507.049,
			1510.581,
			1512.947,
			1516.514,
			1518.903,
			1521.302,
			1522.504,
			1524.916,
			1526.125,
			1528.55,
			1530.983,
			1532.203,
			1534.651,
			1537.106,
			1540.806,
			1542.043,
			1543.283,
			1547.016,
			1548.264,
			1549.515,
			1553.278,
			1555.798,
			1558.326,
			1562.132,
			1564.679,
			1568.515,
			1569.798,
			1571.083,
			1574.948,
			1576.241,
			1577.535,
			1581.429,
			1584.034,
			1586.638,
			1587.941,
			1590.553,
			1593.172,
			1594.484,
			1598.429,
			1601.069,
			1603.715,
			1607.697,
			1610.36,
			1614.367,
			1615.707,
			1617.048,
			1621.081,
			1623.777,
			1627.834,
			1630.548,
			1633.268,
			1634.63,
			1637.36,
			1638.728,
			1640.097,
			1644.214,
			1645.59,
			1646.967,
			1651.108,
			1653.878,
			1658.043,
			1660.828,
			1663.621,
			1665.019,
			1667.821,
			1669.224,
			1672.036,
			1674.854,
			1679.092,
			1681.926,
			1686.189,
			1687.613,
			1689.039,
			1693.324,
			1696.19,
			1700.499,
			1703.379,
			1706.266,
			1707.712,
			1710.608,
			1712.058,
			1714.963,
			1717.874,
			1719.332,
			1722.252,
			1725.178,
			1729.579,
			1731.049,
			1732.52,
			1735.467,
			1738.42,
			1739.898,
			1742.859,
			1744.342,
			1747.313,
			1750.289,
			1754.763,
			1757.753,
			1762.248,
			1763.75,
			1765.252,
			1769.768,
			1772.786,
			1777.321,
			1780.353,
			1783.39,
			1784.91,
			1787.954,
			1789.478,
			1792.531,
			1795.589,
			1797.12,
			1800.186,
			1803.257,
			1807.873,
			1810.957,
			1815.592,
			1817.14,
			1818.69,
			1823.344,
			1826.454,
			1831.128,
			1834.25,
			1837.377,
			1842.076,
			1843.645,
			1845.215,
			1849.932,
			1853.082,
			1857.817,
			1860.98,
			1865.732,
			1868.906,
			1870.494,
			1873.675,
			1876.86,
			1878.454,
			1881.64,
			1884.83,
			1889.624,
			1892.824,
			1897.632,
			1899.237,
			1900.843,
			1905.666,
			1908.886,
			1913.724,
			1916.953,
			1920.187,
			1921.805,
			1925.044,
			1926.666,
			1929.911,
			1934.786,
			1936.414,
			1941.301,
			1944.564,
			1947.832,
			1949.468,
			1952.742,
			1956.02,
			1957.661,
			1960.946,
			1962.59,
			1965.883,
			1969.18,
			1974.135,
			1977.443,
			1982.41,
			1984.067,
			1985.726,
			1990.708,
			1994.032,
			1999.025,
			2002.359,
			2007.366,
			2010.708,
			2012.381,
			2015.73,
			2019.082,
			2020.759,
			2024.117,
			2027.479,
			2032.528,
			2034.213,
			2035.899,
			2039.274,
			2042.653,
			2044.344,
			2047.727,
			2049.419,
			2052.805,
			2056.192,
			2061.277,
			2064.672,
			2069.771,
			2071.5,
			2073.172,
			2078.252,
			2081.611,
			2086.589,
			2089.858,
			2093.088,
			2094.689,
			2097.863,
			2099.437,
			2102.557,
			2105.665,
			2107.192,
			2110.223,
			2113.222,
			2117.631,
			2120.569,
			2124.86,
			2126.287,
			2127.734,
			2130.568,
			2131.983,
			2134.782,
			2138.992,
			2141.796,
			2144.557,
			2148.668,
			2150.039,
			2151.385,
			2155.499,
			2158.27,
			2162.419,
			2165.189,
			2167.962,
			2169.344,
			2172.119,
			2174.886,
			2176.268,
			2179.004,
			2180.386,
			2183.176,
			2185.939,
			2190.08,
			2192.849,
			2197.012,
			2198.403,
			2199.793,
			2203.985,
			2206.778,
			2210.926,
			2213.694,
			2216.426,
			2220.476,
			2221.8,
			2223.113,
			2227.01,
			2228.283,
			2229.554,
			2233.327,
			2235.826,
			2238.311,
			2239.551,
			2242.029,
			2244.504,
			2245.74,
			2248.212,
			2249.447,
			2251.917,
			2254.386,
			2258.088,
			2260.555,
			2264.259,
			2265.496,
			2266.734,
			2270.47,
			2272.976,
			2276.735,
			2279.275,
			2281.823,
			2283.104,
			2285.672,
			2286.958,
			2289.544,
			2293.462,
			2296.051,
			2298.691,
			2302.607,
			2303.945,
			2305.262,
			2307.902,
			2310.542,
			2311.864,
			2315.828,
			2318.472,
			2321.096,
			2325.074,
			2327.733,
			2331.731,
			2333.068,
			2334.405,
			2338.448,
			2341.137,
			2345.157,
			2347.883,
			2350.588,
			2351.919,
			2354.626,
			2356.002,
			2358.71,
			2361.42,
			2362.776,
			2365.47,
			2368.215,
			2372.307,
			2375.017,
			2379.126,
			2380.496,
			2381.864,
			2384.595,
			2385.979,
			2388.661,
			2392.661,
			2395.285,
			2397.874,
			2401.686,
			2402.939,
			2404.183,
			2407.858,
			2409.065,
			2410.263,
			2413.803,
			2416.12,
			2418.402,
			2419.528,
			2421.753,
			2423.94,
			2425.02,
			2427.151,
			2428.203,
			2430.28,
			2432.319,
			2435.31,
			2437.259,
			2440.118,
			2441.053,
			2441.994,
			2444.717,
			2446.49,
			2449.084,
			2450.771,
			2453.249,
			2454.866,
			2455.666,
			2456.462,
			2459.586,
			2460.356,
			2462.655,
			2464.165,
			2465.672,
			2466.425,
			2467.927,
			2469.416,
			2470.159,
			2471.646,
			2472.389,
			2473.906,
			2475.425,
			2477.707,
			2479.335,
			2481.78,
			2482.597,
			2483.465,
			2486.115,
			2487.887,
			2490.692,
			2492.56,
			2494.469,
			2495.427,
			2497.34,
			2498.294,
			2500.183,
			2502.068,
			2503.01,
			2503.919,
			2506.625,
			2509.275,
			2510.136,
			2510.996,
			2512.712,
			2514.367,
			2515.182,
			2516.797,
			2517.612,
			2519.205,
			2520.783,
			2523.174,
			2524.771,
			2527.189,
			2528.002,
			2528.821,
			2531.319,
			2533,
			2535.576,
			2537.309,
			2539.051,
			2539.931,
			2541.702,
			2542.576,
			2544.305,
			2546.042,
			2546.906,
			2548.619,
			2550.318,
			2552.812,
			2554.44,
			2556.845,
			2557.624,
			2558.394,
			2559.92,
			2560.675,
			2562.162,
			2563.626,
			2565.781,
			2567.198,
			2569.309,
			2570.005,
			2570.699,
			2572.787,
			2574.183,
			2576.275,
			2577.672,
			2579.073,
			2579.777,
			2581.183,
			2582.591,
			2583.296,
			2584.711,
			2585.422,
			2586.844,
			2588.267,
			2590.416,
			2591.135,
			2591.854,
			2594.015,
			2594.738,
			2595.464,
			2597.64,
			2599.091,
			2601.277,
			2602.734,
			2604.191,
			2606.387,
			2607.119,
			2607.852,
			2610.056,
			2610.795,
			2611.534,
			2613.756,
			2615.245,
			2616.746,
			2617.498,
			2619.005,
			2620.525,
			2621.29,
			2623.595,
			2625.144,
			2626.706,
			2629.066,
			2630.652,
			2633.048,
			2633.853,
			2634.661,
			2637.099,
			2638.741,
			2641.224,
			2642.888,
			2645.409,
			2647.091,
			2647.932,
			2649.613,
			2652.141,
			2653.825,
			2655.536,
			2657.274,
			2659.01,
			2659.882,
			2661.64,
			2663.412,
			2664.303,
			2666.095,
			2666.998,
			2668.813,
			2670.641,
			2673.413,
			2675.277,
			2678.096,
			2679.042,
			2679.976,
			2682.837,
			2684.757,
			2687.657,
			2689.604,
			2692.546,
			2694.522,
			2695.514,
			2697.5,
			2699.493,
			2700.493,
			2702.498,
			2704.51,
			2707.549,
			2709.589,
			2711.629,
			2713.651,
			2714.657,
			2717.631,
			2719.567,
			2721.47,
			2724.231,
			2726.042,
			2728.691,
			2729.566,
			2730.431,
			2733.795,
			2734.605,
			2736.997,
			2738.538,
			2740.029,
			2740.755,
			2742.182,
			2742.883,
			2744.265,
			2745.691,
			2746.392,
			2747.769,
			2749.12,
			2751.109,
			2752.412,
			2754.337,
			2754.972,
			2755.604,
			2757.484,
			2758.731,
			2760.594,
			2761.831,
			2763.065,
			2763.681,
			2764.909,
			2765.52,
			2766.129,
			2767.949,
			2768.554,
			2769.158,
			2770.917,
			2772.078,
			2773.234,
			2773.81,
			2774.933,
			2776.028,
			2776.573,
			2777.658,
			2778.198,
			2779.274,
			2780.365,
			2781.982,
			2783.052,
			2784.693,
			2785.292,
			2785.889,
			2787.668,
			2788.85,
			2790.964,
			2792.445,
			2793.925,
			2794.711,
			2796.387,
			2797.223,
			2798.059,
			2801.136,
			2802.589,
			2804.043,
			2805.344,
			2806.598,
			2807.225,
			2808.481,
			2809.719,
			2810.31,
			2812.085,
			2813.273,
			2814.465,
			2816.165,
			2817.303,
			2819.025,
			2819.598,
			2820.158,
			2821.847,
			2822.983,
			2824.72,
			2825.934,
			2827.775,
			2829.018,
			2829.656,
			2830.99,
			2832.339,
			2833.019,
			2834.39,
			2835.793,
			2837.211,
			2839.361,
			2840.803,
			2842.247,
			2842.971,
			2844.437,
			2845.18,
			2846.676,
			2848.182,
			2850.469,
			2852.013,
			2854.347,
			2855.134,
			2855.925,
			2858.313,
			2859.931,
			2862.39,
			2864.047,
			2866.564,
			2868.254,
			2869.099,
			2870.79,
			2872.493,
			2873.35,
			2875.084,
			2876.806,
			2879.417,
			2880.291,
			2881.164,
			2883.784,
			2884.648,
			2885.546,
			2888.186,
			2889.964,
			2891.752,
			2894.456,
			2896.276,
			2899.031,
			2899.956,
			2900.886,
			2903.697,
			2904.656,
			2905.601,
			2908.454,
			2910.348,
			2912.275,
			2913.232,
			2915.153,
			2917.078,
			2918.052,
			2920.01,
			2920.993,
			2922.968,
			2924.955,
			2927.957,
			2929.973,
			2933.02,
			2934.041,
			2935.065,
			2938.156,
			2940.23,
			2943.364,
			2945.462,
			2947.56,
			2948.61,
			2950.718,
			2951.776,
			2953.899,
			2957.104,
			2959.253,
			2961.413,
			2963.582,
			2965.761,
			2966.855,
			2969.051,
			2971.256,
			2972.363,
			2974.583,
			2975.697,
			2977.932,
			2980.177,
			2983.561,
			2985.83,
			2989.251,
			2990.396,
			2991.544,
			2995,
			2997.317,
			3000.811,
			3003.152,
			3005.502,
			3006.681,
			3009.045,
			3010.231,
			3012.611,
			3015,
			3016.198,
			3018.601,
			3021.014,
			3023.435,
			3025.865,
			3027.083,
			3029.526,
			3031.977,
			3033.205,
			3035.668,
			3036.902,
			3039.377,
			3041.861,
			3045.602,
			3048.106,
			3051.88,
			3053.142,
			3054.407,
			3058.212,
			3060.76,
			3064.6,
			3067.169,
			3071.04,
			3073.631,
			3074.929,
			3077.531,
			3080.14,
			3081.445,
			3084.055,
			3086.671,
			3090.61,
			3091.926,
			3093.244,
			3097.209,
			3098.534,
			3099.86,
			3102.52,
			3103.852,
			3106.523,
			3107.123
		],
		[
			0,
			0.063,
			0.109,
			0.125,
			0.187,
			0.217,
			0.248,
			0.326,
			0.374,
			0.437,
			0.497,
			0.559,
			0.623,
			0.651,
			0.682,
			0.744,
			0.762,
			0.808,
			0.87,
			0.931,
			0.978,
			1.054,
			1.085,
			1.116,
			1.196,
			1.243,
			1.304,
			1.365,
			1.415,
			1.427,
			1.49,
			1.522,
			1.55,
			1.613,
			1.628,
			1.677,
			1.738,
			1.801,
			1.845,
			1.862,
			1.924,
			1.955,
			1.985,
			2.047,
			2.11,
			2.171,
			2.232,
			2.281,
			2.358,
			2.389,
			2.421,
			2.48,
			2.497,
			2.543,
			2.607,
			2.666,
			2.713,
			2.729,
			2.791,
			2.823,
			2.855,
			2.932,
			2.977,
			3.04,
			3.103,
			3.164,
			3.226,
			3.258,
			3.288,
			3.351,
			3.412,
			3.477,
			3.538,
			3.584,
			3.597,
			3.659,
			3.69,
			3.721,
			3.783,
			3.799,
			3.847,
			3.91,
			3.971,
			4.016,
			4.031,
			4.093,
			4.125,
			4.157,
			4.217,
			4.234,
			4.281,
			4.344,
			4.405,
			4.451,
			4.529,
			4.558,
			4.589,
			4.666,
			4.713,
			4.775,
			4.837,
			4.883,
			4.901,
			4.963,
			4.994,
			5.023,
			5.087,
			5.104,
			5.149,
			5.209,
			5.271,
			5.332,
			5.395,
			5.427,
			5.459,
			5.52,
			5.541,
			5.582,
			5.643,
			5.706,
			5.769,
			5.829,
			5.861,
			5.892,
			5.955,
			6.016,
			6.08,
			6.141,
			6.188,
			6.204,
			6.265,
			6.295,
			6.326,
			6.388,
			6.404,
			6.451,
			6.512,
			6.574,
			6.621,
			6.638,
			6.699,
			6.73,
			6.762,
			6.823,
			6.883,
			6.947,
			7.01,
			7.07,
			7.133,
			7.164,
			7.193,
			7.257,
			7.273,
			7.317,
			7.381,
			7.444,
			7.489,
			7.505,
			7.565,
			7.603,
			7.63,
			7.689,
			7.707,
			7.753,
			7.814,
			7.876,
			7.923,
			7.999,
			8.032,
			8.062,
			8.125,
			8.187,
			8.25,
			8.31,
			8.356,
			8.373,
			8.433,
			8.464,
			8.497,
			8.558,
			8.573,
			8.621,
			8.683,
			8.744,
			8.791,
			8.808,
			8.87,
			8.9,
			8.929,
			9.009,
			9.056,
			9.116,
			9.18,
			9.227,
			9.303,
			9.333,
			9.365,
			9.443,
			9.488,
			9.55,
			9.613,
			9.676,
			9.738,
			9.769,
			9.799,
			9.862,
			9.876,
			9.923,
			9.984,
			10.047,
			10.109,
			10.172,
			10.201,
			10.233,
			10.294,
			10.31,
			10.358,
			10.424,
			10.481,
			10.528,
			10.604,
			10.636,
			10.665,
			10.729,
			10.79,
			10.853,
			10.914,
			10.962,
			10.978,
			11.038,
			11.071,
			11.101,
			11.178,
			11.226,
			11.287,
			11.35,
			11.395,
			11.412,
			11.473,
			11.504,
			11.536,
			11.596,
			11.612,
			11.659,
			11.725,
			11.783,
			11.847,
			11.906,
			11.938,
			11.968,
			12.032,
			12.048,
			12.094,
			12.16,
			12.217,
			12.28,
			12.343,
			12.373,
			12.405,
			12.466,
			12.481,
			12.529,
			12.59,
			12.651,
			12.697,
			12.713,
			12.775,
			12.806,
			12.839,
			12.9,
			12.963,
			13.023,
			13.087,
			13.148,
			13.209,
			13.24,
			13.272,
			13.333,
			13.349,
			13.397,
			13.459,
			13.521,
			13.582,
			13.642,
			13.676,
			13.705,
			13.766,
			13.787,
			13.83,
			13.893,
			13.954,
			13.999,
			14.078,
			14.11,
			14.139,
			14.202,
			14.265,
			14.326,
			14.387,
			14.432,
			14.449,
			14.511,
			14.542,
			14.574,
			14.65,
			14.698,
			14.759,
			14.823,
			14.868,
			14.882,
			14.946,
			14.976,
			15.008,
			15.087,
			15.132,
			15.196,
			15.255,
			15.304,
			15.38,
			15.411,
			15.442,
			15.517,
			15.565,
			15.627,
			15.689,
			15.738,
			15.753,
			15.813,
			15.848,
			15.876,
			15.955,
			16,
			16.063,
			16.125,
			16.171,
			16.187,
			16.248,
			16.278,
			16.31,
			16.372,
			16.435,
			16.496,
			16.558,
			16.62,
			16.683,
			16.714,
			16.743,
			16.807,
			16.868,
			16.929,
			16.993,
			17.038,
			17.056,
			17.116,
			17.146,
			17.177,
			17.257,
			17.303,
			17.364,
			17.426,
			17.473,
			17.549,
			17.581,
			17.613,
			17.69,
			17.736,
			17.799,
			17.859,
			17.91,
			17.983,
			18.015,
			18.045,
			18.11,
			18.124,
			18.17,
			18.232,
			18.295,
			18.34,
			18.358,
			18.418,
			18.449,
			18.481,
			18.543,
			18.559,
			18.604,
			18.666,
			18.728,
			18.776,
			18.852,
			18.883,
			18.914,
			18.991,
			19.038,
			19.099,
			19.162,
			19.208,
			19.225,
			19.287,
			19.319,
			19.348,
			19.41,
			19.427,
			19.473,
			19.536,
			19.596,
			19.642,
			19.66,
			19.721,
			19.749,
			19.784,
			19.844,
			19.861,
			19.907,
			19.972,
			20.03,
			20.078,
			20.155,
			20.183,
			20.215,
			20.277,
			20.341,
			20.404,
			20.464,
			20.512,
			20.526,
			20.59,
			20.618,
			20.649,
			20.714,
			20.729,
			20.776,
			20.838,
			20.898,
			20.947,
			20.961,
			21.023,
			21.054,
			21.085,
			21.146,
			21.162,
			21.21,
			21.276,
			21.332,
			21.381,
			21.457,
			21.489,
			21.519,
			21.582,
			21.644,
			21.704,
			21.765,
			21.813,
			21.828,
			21.892,
			21.923,
			21.954,
			22.014,
			22.035,
			22.076,
			22.14,
			22.201,
			22.246,
			22.264,
			22.326,
			22.355,
			22.388,
			22.465,
			22.512,
			22.575,
			22.636,
			22.681,
			22.759,
			22.789,
			22.821,
			22.899,
			22.945,
			23.007,
			23.068,
			23.116,
			23.132,
			23.192,
			23.225,
			23.257,
			23.317,
			23.331,
			23.381,
			23.443,
			23.505,
			23.55,
			23.626,
			23.658,
			23.688,
			23.75,
			23.814,
			23.874,
			23.937,
			23.999,
			24.062,
			24.095,
			24.124,
			24.186,
			24.2,
			24.248,
			24.311,
			24.371,
			24.419,
			24.434,
			24.494,
			24.527,
			24.556,
			24.619,
			24.632,
			24.68,
			24.743,
			24.806,
			24.866,
			24.928,
			24.96,
			24.991,
			25.054,
			25.116,
			25.178,
			25.239,
			25.285,
			25.302,
			25.362,
			25.393,
			25.425,
			25.488,
			25.504,
			25.548,
			25.612,
			25.674,
			25.736,
			25.797,
			25.828,
			25.86,
			25.921,
			25.938,
			25.983,
			26.048,
			26.107,
			26.157,
			26.232,
			26.262,
			26.293,
			26.355,
			26.416,
			26.48,
			26.54,
			26.588,
			26.604,
			26.667,
			26.695,
			26.73,
			26.789,
			26.806,
			26.849,
			26.913,
			26.974,
			27.023,
			27.099,
			27.13,
			27.162,
			27.238,
			27.284,
			27.349,
			27.411,
			27.472,
			27.532,
			27.565,
			27.595,
			27.674,
			27.717,
			27.784,
			27.845,
			27.891,
			27.905,
			27.966,
			27.999,
			28.03,
			28.107,
			28.155,
			28.22,
			28.276,
			28.34,
			28.402,
			28.432,
			28.462,
			28.526,
			28.588,
			28.649,
			28.71,
			28.758,
			28.773,
			28.837,
			28.868,
			28.896,
			28.961,
			28.977,
			29.022,
			29.084,
			29.147,
			29.193,
			29.208,
			29.271,
			29.3,
			29.332,
			29.393,
			29.411,
			29.455,
			29.516,
			29.578,
			29.627,
			29.704,
			29.734,
			29.766,
			29.828,
			29.891,
			29.953,
			30.015,
			30.061,
			30.076,
			30.138,
			30.167,
			30.199,
			30.281,
			30.323,
			30.384,
			30.448,
			30.495,
			30.51,
			30.573,
			30.603,
			30.634,
			30.697,
			30.759,
			30.822,
			30.881,
			30.929,
			31.007,
			31.038,
			31.067,
			31.129,
			31.192,
			31.257,
			31.315,
			31.363,
			31.377,
			31.439,
			31.472,
			31.502,
			31.565,
			31.579,
			31.626,
			31.69,
			31.75,
			31.795,
			31.813,
			31.873,
			31.906,
			31.938,
			31.998,
			32.061,
			32.122,
			32.183,
			32.232,
			32.308,
			32.345,
			32.37,
			32.433,
			32.447,
			32.496,
			32.558,
			32.616,
			32.666,
			32.682,
			32.742,
			32.773,
			32.805,
			32.881,
			32.929,
			32.992,
			33.051,
			33.1,
			33.178,
			33.207,
			33.239,
			33.316,
			33.364,
			33.426,
			33.488,
			33.532,
			33.548,
			33.61,
			33.641,
			33.671,
			33.735,
			33.751,
			33.795,
			33.859,
			33.921,
			33.983,
			34.044,
			34.076,
			34.108,
			34.169,
			34.183,
			34.231,
			34.292,
			34.355,
			34.406,
			34.478,
			34.51,
			34.541,
			34.604,
			34.665,
			34.727,
			34.79,
			34.836,
			34.849,
			34.912,
			34.944,
			34.976,
			35.036,
			35.052,
			35.099,
			35.16,
			35.223,
			35.27,
			35.284,
			35.345,
			35.377,
			35.409,
			35.471,
			35.487,
			35.532,
			35.596,
			35.658,
			35.716,
			35.78,
			35.813,
			35.844,
			35.905,
			35.967,
			36.029,
			36.09,
			36.138,
			36.153,
			36.215,
			36.245,
			36.277,
			36.338,
			36.355,
			36.399,
			36.464,
			36.523,
			36.572,
			36.648,
			36.678,
			36.712,
			36.774,
			36.833,
			36.898,
			36.958,
			37.021,
			37.083,
			37.115,
			37.145,
			37.222,
			37.266,
			37.331,
			37.392,
			37.454,
			37.518,
			37.546,
			37.58,
			37.641,
			37.656,
			37.704,
			37.765,
			37.827,
			37.873,
			37.951,
			37.983,
			38.012,
			38.09,
			38.138,
			38.2,
			38.262,
			38.308,
			38.324,
			38.384,
			38.417,
			38.448,
			38.529,
			38.571,
			38.633,
			38.695,
			38.743,
			38.757,
			38.819,
			38.849,
			38.881,
			38.943,
			38.96,
			39.005,
			39.066,
			39.129,
			39.175,
			39.251,
			39.283,
			39.315,
			39.377,
			39.438,
			39.501,
			39.563,
			39.626,
			39.687,
			39.717,
			39.75,
			39.812,
			39.826,
			39.874,
			39.933,
			39.999,
			40.044,
			40.058,
			40.122,
			40.152,
			40.182,
			40.244,
			40.261,
			40.307,
			40.368,
			40.43,
			40.477,
			40.554,
			40.591,
			40.617,
			40.696,
			40.742,
			40.805,
			40.865,
			40.913,
			40.929,
			40.99,
			41.022,
			41.05,
			41.112,
			41.128,
			41.176,
			41.238,
			41.298,
			41.362,
			41.424,
			41.456,
			41.485,
			41.546,
			41.563,
			41.61,
			41.673,
			41.732,
			41.781,
			41.858,
			41.89,
			41.919,
			41.981,
			42.043,
			42.107,
			42.169,
			42.215,
			42.23,
			42.292,
			42.323,
			42.355,
			42.415,
			42.432,
			42.477,
			42.539,
			42.6,
			42.665,
			42.726,
			42.757,
			42.788,
			42.85,
			42.914,
			42.974,
			43.036,
			43.083,
			43.159,
			43.191,
			43.222,
			43.282,
			43.299,
			43.345,
			43.408,
			43.471,
			43.516,
			43.533,
			43.592,
			43.626,
			43.655,
			43.718,
			43.732,
			43.781,
			43.843,
			43.904,
			43.951,
			44.028,
			44.06,
			44.089,
			44.153,
			44.213,
			44.276,
			44.338,
			44.385,
			44.399,
			44.463,
			44.492,
			44.523,
			44.601,
			44.649,
			44.716,
			44.773,
			44.817,
			44.834,
			44.896,
			44.926,
			44.958,
			45.034,
			45.082,
			45.145,
			45.207,
			45.254,
			45.329,
			45.361,
			45.393,
			45.455,
			45.517,
			45.577,
			45.643,
			45.688,
			45.702,
			45.764,
			45.796,
			45.826,
			45.89,
			45.904,
			45.951,
			46.013,
			46.073,
			46.14,
			46.199,
			46.229,
			46.261,
			46.322,
			46.338,
			46.386,
			46.448,
			46.507,
			46.555,
			46.632,
			46.662,
			46.693,
			46.757,
			46.776,
			46.816,
			46.882,
			46.943,
			46.988,
			47.004,
			47.067,
			47.098,
			47.129,
			47.189,
			47.205,
			47.251,
			47.313,
			47.376,
			47.439,
			47.499,
			47.532,
			47.562,
			47.641,
			47.687,
			47.747,
			47.81,
			47.873,
			47.932,
			47.966,
			47.996,
			48.073,
			48.121,
			48.182,
			48.245,
			48.29,
			48.307,
			48.366,
			48.398,
			48.429,
			48.492,
			48.508,
			48.555,
			48.616,
			48.678,
			48.725,
			48.8,
			48.838,
			48.863,
			48.927,
			48.988,
			49.05,
			49.111,
			49.159,
			49.174,
			49.237,
			49.268,
			49.3,
			49.361,
			49.377,
			49.421,
			49.483,
			49.547,
			49.593,
			49.608,
			49.672,
			49.7,
			49.732,
			49.795,
			49.811,
			49.858,
			49.919,
			49.98,
			50.026,
			50.104,
			50.134,
			50.165,
			50.244,
			50.289,
			50.353,
			50.413,
			50.462,
			50.476,
			50.539,
			50.571,
			50.599,
			50.662,
			50.678,
			50.723,
			50.788,
			50.849,
			50.898,
			50.972,
			51.001,
			51.035,
			51.096,
			51.111,
			51.159,
			51.222,
			51.284,
			51.345,
			51.407,
			51.438,
			51.468,
			51.53,
			51.593,
			51.654,
			51.717,
			51.764,
			51.778,
			51.842,
			51.873,
			51.904,
			51.966,
			51.98,
			52.026,
			52.09,
			52.152,
			52.196,
			52.213,
			52.276,
			52.307,
			52.338,
			52.4,
			52.462,
			52.523,
			52.585,
			52.632,
			52.71,
			52.739,
			52.771,
			52.833,
			52.847,
			52.896,
			52.96,
			53.017,
			53.066,
			53.08,
			53.143,
			53.173,
			53.205,
			53.283,
			53.329,
			53.396,
			53.451,
			53.501,
			53.578,
			53.607,
			53.638,
			53.701,
			53.764,
			53.825,
			53.887,
			53.948,
			54.01,
			54.041,
			54.073,
			54.15,
			54.197,
			54.259,
			54.322,
			54.368,
			54.382,
			54.445,
			54.476,
			54.508,
			54.567,
			54.585,
			54.632,
			54.693,
			54.757,
			54.802,
			54.88,
			54.911,
			54.943,
			55.004,
			55.065,
			55.129,
			55.19,
			55.251,
			55.312,
			55.343,
			55.375,
			55.438,
			55.453,
			55.499,
			55.562,
			55.625,
			55.683,
			55.747,
			55.78,
			55.81,
			55.873,
			55.932,
			55.995,
			56.058,
			56.119,
			56.182,
			56.213,
			56.243,
			56.322,
			56.366,
			56.431,
			56.493,
			56.538,
			56.554,
			56.616,
			56.646,
			56.678,
			56.741,
			56.756,
			56.799,
			56.864,
			56.927,
			56.973,
			57.05,
			57.084,
			57.113,
			57.173,
			57.236,
			57.297,
			57.359,
			57.407,
			57.422,
			57.484,
			57.514,
			57.545,
			57.608,
			57.624,
			57.668,
			57.732,
			57.793,
			57.841,
			57.855,
			57.916,
			57.948,
			57.98,
			58.04,
			58.058,
			58.104,
			58.166,
			58.226,
			58.273,
			58.349,
			58.383,
			58.413,
			58.476,
			58.537,
			58.601,
			58.663,
			58.709,
			58.724,
			58.786,
			58.816,
			58.846,
			58.925,
			58.973,
			59.032,
			59.096,
			59.146,
			59.156,
			59.219,
			59.25,
			59.281,
			59.36,
			59.406,
			59.466,
			59.529,
			59.577,
			59.655,
			59.684,
			59.716,
			59.794,
			59.839,
			59.902,
			59.965,
			60.025,
			60.087,
			60.117,
			60.149,
			60.211,
			60.227,
			60.273,
			60.336,
			60.396,
			60.46,
			60.522,
			60.554,
			60.582,
			60.645,
			60.66,
			60.707,
			60.771,
			60.831,
			60.879,
			60.957,
			60.987,
			61.016,
			61.08,
			61.141,
			61.207,
			61.265,
			61.329,
			61.389,
			61.421,
			61.451,
			61.513,
			61.529,
			61.575,
			61.64,
			61.701,
			61.745,
			61.763,
			61.824,
			61.856,
			61.886,
			61.963,
			62.01,
			62.071,
			62.133,
			62.18,
			62.259,
			62.29,
			62.318,
			62.381,
			62.398,
			62.445,
			62.507,
			62.568,
			62.616,
			62.629,
			62.692,
			62.724,
			62.755,
			62.817,
			62.831,
			62.879,
			62.941,
			63.003,
			63.047,
			63.127,
			63.157,
			63.19,
			63.268,
			63.311,
			63.376,
			63.436,
			63.482,
			63.499,
			63.561,
			63.592,
			63.623,
			63.699,
			63.748,
			63.808,
			63.872,
			63.916,
			63.933,
			63.996,
			64.025,
			64.056,
			64.116,
			64.133,
			64.181,
			64.243,
			64.306,
			64.349,
			64.428,
			64.458,
			64.491,
			64.567,
			64.614,
			64.676,
			64.74,
			64.784,
			64.8,
			64.864,
			64.894,
			64.925,
			64.984,
			65.002,
			65.048,
			65.111,
			65.171,
			65.217,
			65.242,
			65.297,
			65.333,
			65.36,
			65.421,
			65.434,
			65.481,
			65.544,
			65.606,
			65.654,
			65.73,
			65.762,
			65.792,
			65.871,
			65.917,
			65.981,
			66.041,
			66.101,
			66.165,
			66.196,
			66.226,
			66.29,
			66.304,
			66.352,
			66.414,
			66.474,
			66.521,
			66.537,
			66.599,
			66.629,
			66.662,
			66.722,
			66.738,
			66.785,
			66.796
		]
	],
	'4252_4253_7982': [
		[
			98.115
		],
		[
			0
		],
		[
			0,
			3.181,
			5.86,
			9.893,
			11.24,
			12.589,
			16.646,
			19.358,
			23.441,
			26.171,
			28.907,
			30.277,
			33.023,
			34.398,
			37.154,
			39.916,
			41.3,
			44.073,
			46.854,
			51.037,
			52.435,
			53.835,
			56.639,
			59.451,
			60.859,
			63.681,
			65.095,
			67.927,
			70.765,
			75.036,
			77.89,
			82.185,
			83.62,
			85.057,
			89.376,
			92.264,
			96.608,
			99.512,
			103.881,
			106.801,
			108.263,
			111.193,
			115.598,
			118.543,
			121.495,
			125.933,
			128.895,
			133.327,
			134.798,
			136.262,
			140.615,
			143.473,
			146.293,
			150.451,
			153.175,
			157.189,
			158.509,
			159.818,
			163.691,
			166.225,
			169.957,
			172.398,
			174.802,
			175.991,
			178.345,
			180.664,
			181.81,
			184.076,
			185.194,
			187.404,
			189.58,
			192.773,
			194.854,
			197.906,
			198.905,
			199.91,
			202.822,
			204.715,
			207.493,
			209.308,
			211.098,
			213.746,
			214.615,
			215.48,
			218.058,
			218.914,
			219.76,
			222.295,
			223.987,
			225.669,
			226.511,
			228.195,
			229.878,
			230.714,
			233.221,
			234.893,
			236.562,
			239.064,
			240.752,
			243.314,
			244.168,
			245.021,
			247.776,
			249.622,
			252.662,
			254.738,
			256.929,
			258.057,
			260.314,
			261.446,
			263.714,
			267.006,
			268.037,
			271.128,
			273.974,
			274.921,
			275.869,
			277.625,
			279.35,
			280.214,
			281.932,
			282.765,
			284.434,
			286.111,
			288.643,
			290.342,
			292.922,
			293.793,
			294.666,
			297.306,
			299.101,
			301.813,
			303.655,
			305.519,
			306.456,
			308.348,
			309.309,
			311.237,
			313.179,
			314.163,
			316.139,
			318.123,
			321.139,
			322.148,
			323.158,
			326.195,
			327.206,
			328.215,
			330.219,
			331.21,
			333.173,
			335.106,
			337.951,
			339.824,
			342.617,
			343.53,
			344.475,
			347.254,
			348.188,
			349.123,
			351.942,
			353.852,
			355.753,
			356.689,
			358.597,
			359.555,
			361.48,
			363.417,
			364.39,
			366.345,
			368.31,
			371.277,
			372.269,
			373.264,
			376.265,
			377.271,
			378.281,
			381.327,
			383.377,
			386.474,
			388.554,
			391.693,
			393.789,
			394.841,
			395.911,
			400.129,
			401.191,
			404.421,
			406.604,
			409.895,
			412.098,
			414.236,
			415.3,
			417.435,
			418.505,
			420.667,
			422.8,
			426.063,
			428.252,
			431.559,
			432.665,
			433.782,
			437.147,
			439.404,
			442.807,
			445.087,
			447.376,
			448.525,
			450.829,
			451.985,
			454.304,
			456.633,
			457.801,
			458.972,
			462.497,
			464.86,
			467.233,
			468.422,
			470.808,
			473.203,
			474.405,
			476.815,
			478.023,
			480.447,
			482.881,
			486.547,
			489.003,
			492.703,
			493.941,
			495.181,
			498.915,
			501.415,
			505.181,
			507.702,
			510.233,
			511.502,
			514.046,
			515.321,
			517.879,
			520.446,
			521.733,
			524.313,
			526.901,
			530.798,
			533.404,
			537.313,
			538.618,
			539.926,
			543.858,
			546.488,
			549.125,
			553.093,
			555.746,
			559.74,
			561.075,
			562.412,
			566.433,
			569.122,
			573.17,
			575.877,
			578.591,
			579.951,
			582.676,
			585.408,
			586.777,
			589.52,
			590.894,
			593.648,
			596.409,
			600.563,
			601.952,
			603.342,
			607.521,
			608.918,
			610.316,
			614.52,
			617.332,
			621.563,
			624.392,
			627.227,
			631.493,
			632.919,
			634.346,
			638.638,
			640.072,
			641.508,
			645.826,
			648.714,
			651.608,
			653.058,
			655.962,
			658.873,
			660.331,
			664.715,
			667.647,
			670.585,
			675.004,
			677.958,
			682.401,
			683.885,
			685.371,
			689.839,
			692.825,
			697.316,
			700.318,
			703.325,
			707.849,
			709.36,
			710.872,
			715.418,
			716.937,
			718.457,
			723.026,
			726.079,
			729.139,
			730.671,
			733.74,
			736.815,
			738.354,
			741.437,
			742.981,
			746.072,
			749.17,
			753.826,
			756.936,
			761.612,
			763.174,
			764.737,
			769.434,
			772.571,
			777.288,
			780.439,
			783.595,
			785.175,
			788.339,
			789.924,
			793.097,
			797.869,
			801.056,
			804.245,
			809.04,
			810.641,
			812.245,
			817.066,
			818.676,
			820.287,
			825.13,
			828.365,
			831.605,
			833.227,
			836.474,
			839.726,
			844.614,
			846.245,
			847.878,
			852.785,
			856.062,
			860.988,
			864.276,
			869.217,
			872.515,
			874.166,
			877.47,
			880.779,
			882.434,
			885.749,
			889.066,
			894.05,
			897.376,
			902.369,
			904.034,
			905.7,
			910.7,
			914.035,
			917.375,
			922.392,
			925.741,
			930.773,
			932.453,
			934.133,
			939.181,
			940.866,
			942.552,
			947.614,
			950.995,
			954.38,
			956.074,
			959.465,
			962.86,
			964.559,
			967.96,
			969.662,
			973.069,
			976.481,
			981.605,
			985.028,
			990.168,
			991.884,
			993.6,
			998.755,
			1002.197,
			1007.367,
			1010.818,
			1014.273,
			1016.002,
			1019.463,
			1021.195,
			1024.662,
			1028.133,
			1029.87,
			1031.607,
			1036.824,
			1040.308,
			1043.794,
			1045.538,
			1049.027,
			1052.517,
			1054.262,
			1057.753,
			1059.499,
			1062.992,
			1066.489,
			1071.743,
			1075.251,
			1080.524,
			1082.283,
			1084.044,
			1089.32,
			1092.82,
			1098.02,
			1101.443,
			1104.827,
			1106.504,
			1109.83,
			1113.117,
			1114.745,
			1117.975,
			1119.575,
			1122.747,
			1125.879,
			1130.509,
			1132.035,
			1133.552,
			1136.562,
			1139.536,
			1141.01,
			1143.928,
			1145.374,
			1148.235,
			1151.059,
			1155.227,
			1157.957,
			1161.977,
			1163.298,
			1164.608,
			1168.484,
			1171.02,
			1174.755,
			1177.2,
			1179.608,
			1180.799,
			1183.157,
			1184.323,
			1186.63,
			1188.899,
			1190.019,
			1192.231,
			1194.406,
			1197.6,
			1199.682,
			1202.735,
			1203.734,
			1204.722,
			1207.63,
			1209.519,
			1212.276,
			1214.072,
			1215.828,
			1218.398,
			1219.236,
			1220.063,
			1222.502,
			1224.075,
			1226.347,
			1227.821,
			1229.262,
			1229.956,
			1231.323,
			1232.668,
			1233.333,
			1235.255,
			1236.494,
			1237.72,
			1239.54,
			1240.135,
			1240.73,
			1242.508,
			1243.1,
			1243.692,
			1245.48,
			1246.673,
			1248.461,
			1249.669,
			1251.514,
			1252.745,
			1253.361,
			1253.979,
			1255.876,
			1256.511,
			1257.147,
			1259.062,
			1260.371,
			1262.347,
			1263.67,
			1265.017,
			1265.699,
			1267.745,
			1269.112,
			1270.511,
			1272.611,
			1274.011,
			1276.151,
			1276.864,
			1277.576,
			1279.719,
			1281.174,
			1283.349,
			1284.81,
			1286.298,
			1287.041,
			1288.525,
			1289.268,
			1290.79,
			1292.313,
			1293.075,
			1293.838,
			1296.167,
			1297.733,
			1299.303,
			1300.106,
			1301.725,
			1303.349,
			1304.163,
			1306.648,
			1308.313,
			1309.988,
			1312.519,
			1314.216,
			1316.782,
			1317.642,
			1318.503,
			1321.107,
			1322.856,
			1325.5,
			1327.277,
			1329.062,
			1329.96,
			1331.766,
			1332.672,
			1334.492,
			1337.246,
			1339.094,
			1340.948,
			1343.738,
			1345.59,
			1348.348,
			1349.271,
			1350.19,
			1352.925,
			1354.756,
			1357.45,
			1359.25,
			1361.024,
			1363.667,
			1364.549,
			1365.42,
			1368.01,
			1369.739,
			1372.287,
			1373.984,
			1376.516,
			1378.198,
			1379.04,
			1380.727,
			1382.42,
			1383.268,
			1384.966,
			1386.687,
			1389.276,
			1391.022,
			1393.665,
			1394.55,
			1395.439,
			1398.133,
			1399.938,
			1402.672,
			1404.503,
			1406.341,
			1407.267,
			1409.136,
			1410.075,
			1411.02,
			1413.867,
			1414.822,
			1415.782,
			1418.67,
			1420.623,
			1423.58,
			1425.566,
			1427.564,
			1428.567,
			1430.583,
			1431.593,
			1433.621,
			1435.661,
			1438.731,
			1440.791,
			1443.895,
			1444.935,
			1445.977,
			1449.133,
			1451.226,
			1454.344,
			1456.451,
			1459.602,
			1461.696,
			1462.752,
			1464.884,
			1466.99,
			1468.055,
			1470.192,
			1472.339,
			1474.514,
			1476.663,
			1477.75,
			1479.93,
			1482.12,
			1483.218,
			1485.422,
			1486.528,
			1488.747,
			1490.975,
			1494.335,
			1496.588,
			1499.984,
			1501.121,
			1502.261,
			1505.694,
			1507.995,
			1511.464,
			1513.789,
			1517.296,
			1519.645,
			1520.824,
			1523.188,
			1526.751,
			1529.138,
			1531.534,
			1533.939,
			1537.564,
			1541.208,
			1542.428,
			1543.65,
			1546.1,
			1547.328,
			1549.792,
			1553.504,
			1555.989,
			1558.483,
			1562.24,
			1563.497,
			1564.755,
			1568.543,
			1571.078,
			1574.895,
			1577.45,
			1580.012,
			1581.296,
			1583.871,
			1585.161,
			1587.747,
			1590.34,
			1591.64,
			1594.244,
			1596.853,
			1600.768,
			1603.386,
			1607.326,
			1608.642,
			1609.96,
			1613.926,
			1616.578,
			1620.568,
			1623.236,
			1625.911,
			1629.937,
			1631.282,
			1632.629,
			1638.032,
			1639.387,
			1643.463,
			1646.187,
			1648.918,
			1650.287,
			1653.029,
			1655.776,
			1657.153,
			1661.292,
			1664.06,
			1666.835,
			1671.009,
			1673.8,
			1677.998,
			1679.401,
			1680.805,
			1685.028,
			1687.852,
			1692.099,
			1694.938,
			1697.783,
			1699.208,
			1702.063,
			1703.492,
			1706.356,
			1709.227,
			1710.664,
			1713.544,
			1716.429,
			1719.321,
			1722.218,
			1723.67,
			1726.577,
			1729.49,
			1730.948,
			1733.87,
			1735.333,
			1738.264,
			1741.201,
			1745.617,
			1748.569,
			1753.008,
			1754.49,
			1755.974,
			1760.435,
			1763.416,
			1767.899,
			1770.894,
			1775.397,
			1778.406,
			1779.913,
			1782.931,
			1787.468,
			1790.499,
			1793.536,
			1798.102,
			1799.626,
			1801.152,
			1805.738,
			1807.269,
			1808.802,
			1811.871,
			1813.407,
			1816.484,
			1819.567,
			1824.201,
			1827.297,
			1831.951,
			1833.504,
			1835.059,
			1839.732,
			1841.292,
			1842.853,
			1847.545,
			1850.68,
			1853.818,
			1855.39,
			1858.536,
			1860.111,
			1863.265,
			1866.423,
			1868.004,
			1871.169,
			1874.338,
			1879.099,
			1882.278,
			1887.055,
			1888.65,
			1890.245,
			1895.028,
			1898.222,
			1903.021,
			1906.224,
			1909.431,
			1911.036,
			1914.249,
			1915.858,
			1917.466,
			1923.912,
			1925.526,
			1930.375,
			1933.612,
			1936.854,
			1938.475,
			1941.722,
			1944.974,
			1946.601,
			1949.858,
			1951.489,
			1954.752,
			1958.02,
			1962.929,
			1966.206,
			1971.131,
			1972.774,
			1974.418,
			1979.356,
			1982.653,
			1987.605,
			1990.911,
			1995.879,
			1999.197,
			2000.857,
			2004.181,
			2009.175,
			2012.509,
			2015.847,
			2019.189,
			2022.535,
			2024.209,
			2027.561,
			2030.917,
			2032.596,
			2035.958,
			2037.64,
			2041.008,
			2044.379,
			2049.444,
			2052.825,
			2057.904,
			2059.598,
			2061.294,
			2066.387,
			2069.785,
			2074.872,
			2078.242,
			2081.584,
			2083.242,
			2086.53,
			2088.159,
			2091.388,
			2094.578,
			2096.158,
			2099.29,
			2102.386,
			2105.446,
			2109.994,
			2114.421,
			2115.887,
			2117.369,
			2121.691,
			2124.535,
			2127.334,
			2131.567,
			2134.347,
			2138.548,
			2139.947,
			2141.368,
			2146.938,
			2148.322,
			2152.449,
			2155.157,
			2157.872,
			2159.219,
			2161.949,
			2163.297,
			2166.01,
			2170.087,
			2172.823,
			2175.564,
			2179.686,
			2182.436,
			2186.56,
			2187.934,
			2189.308,
			2193.407,
			2196.135,
			2200.232,
			2202.985,
			2205.747,
			2207.134,
			2209.913,
			2211.308,
			2212.705,
			2216.911,
			2218.317,
			2219.726,
			2223.952,
			2226.749,
			2229.534,
			2230.912,
			2233.649,
			2236.373,
			2237.725,
			2241.773,
			2244.461,
			2247.146,
			2251.172,
			2253.855,
			2257.888,
			2259.234,
			2260.581,
			2264.637,
			2267.353,
			2271.42,
			2274.181,
			2276.929,
			2278.306,
			2281.043,
			2282.426,
			2283.811,
			2288.003,
			2289.373,
			2290.79,
			2294.983,
			2297.758,
			2300.583,
			2301.986,
			2304.792,
			2307.604,
			2308.989,
			2313.226,
			2316.058,
			2318.898,
			2323.172,
			2326.029,
			2330.326,
			2331.761,
			2333.199,
			2337.521,
			2340.41,
			2344.756,
			2347.662,
			2350.577,
			2352.061,
			2354.987,
			2356.452,
			2359.359,
			2363.784,
			2366.728,
			2369.643,
			2374.062,
			2375.491,
			2376.961,
			2379.837,
			2382.659,
			2384.087,
			2386.849,
			2388.253,
			2390.967,
			2393.66,
			2397.634,
			2400.237,
			2404.074,
			2405.337,
			2406.593,
			2410.299,
			2412.724,
			2416.293,
			2418.63,
			2420.93,
			2422.068,
			2424.341,
			2425.456,
			2427.638,
			2429.803,
			2430.871,
			2432.98,
			2435.057,
			2438.104,
			2440.09,
			2443.003,
			2443.955,
			2444.898,
			2446.758,
			2447.675,
			2449.497,
			2452.144,
			2453.864,
			2456.372,
			2458.001,
			2458.799,
			2459.585,
			2461.892,
			2463.389,
			2465.535,
			2466.929,
			2468.298,
			2468.97,
			2470.288,
			2471.588,
			2472.233,
			2474.156,
			2475.434,
			2476.705,
			2478.605,
			2479.891,
			2481.818,
			2482.459,
			2483.1,
			2485.055,
			2486.368,
			2488.338,
			2489.683,
			2491.047,
			2491.73,
			2493.098,
			2493.784,
			2494.495,
			2497.385,
			2498.839,
			2500.364,
			2501.906,
			2504.237,
			2505.856,
			2507.48,
			2508.295,
			2509.945,
			2510.78,
			2512.453,
			2514.132,
			2516.661,
			2518.35,
			2520.845,
			2521.668,
			2522.492,
			2524.948,
			2526.544,
			2528.948,
			2530.52,
			2532.081,
			2532.864,
			2534.435,
			2535.206,
			2536.752,
			2538.307,
			2539.088,
			2539.865,
			2542.19,
			2543.754,
			2546.097,
			2547.667,
			2549.251,
			2550.045,
			2552.445,
			2554.062,
			2555.698,
			2558.156,
			2559.812,
			2562.348,
			2563.198,
			2564.049,
			2566.652,
			2567.525,
			2568.4,
			2571.049,
			2572.831,
			2574.62,
			2575.514,
			2577.304,
			2578.2,
			2579.995,
			2581.77,
			2582.659,
			2584.444,
			2586.215,
			2588.876,
			2589.767,
			2590.655,
			2593.329,
			2594.225,
			2595.124,
			2596.935,
			2597.843,
			2599.667,
			2601.516,
			2604.313,
			2606.208,
			2609.088,
			2610.052,
			2611.027,
			2613.967,
			2615.937,
			2618.921,
			2620.916,
			2622.924,
			2623.929,
			2625.943,
			2627.972,
			2628.986,
			2631.017,
			2632.042,
			2634.088,
			2636.135,
			2639.231,
			2640.261,
			2641.293,
			2644.413,
			2645.449,
			2646.488,
			2649.616,
			2651.692,
			2654.792,
			2656.843,
			2658.873,
			2661.898,
			2662.898,
			2663.896,
			2666.874,
			2667.859,
			2668.842,
			2671.779,
			2673.726,
			2675.668,
			2676.639,
			2678.581,
			2680.521,
			2681.493,
			2684.413,
			2686.366,
			2688.321,
			2691.261,
			2693.227,
			2696.186,
			2697.176,
			2698.168,
			2701.159,
			2703.16,
			2706.177,
			2708.193,
			2711.229,
			2713.255,
			2714.269,
			2716.305,
			2718.341,
			2719.359,
			2720.38,
			2723.448,
			2725.496,
			2728.582,
			2730.647,
			2732.72,
			2733.763,
			2735.852,
			2736.899,
			2739.009,
			2741.124,
			2744.295,
			2746.418,
			2749.594,
			2750.663,
			2751.733,
			2754.949,
			2757.104,
			2760.342,
			2762.508,
			2764.673,
			2765.754,
			2767.912,
			2769.004,
			2771.142,
			2773.272,
			2774.336,
			2776.46,
			2778.582,
			2781.765,
			2783.887,
			2786.01,
			2788.13,
			2789.188,
			2791.298,
			2792.351,
			2794.449,
			2796.541,
			2799.668,
			2801.746,
			2804.859,
			2805.895,
			2806.93,
			2810.041,
			2812.116,
			2815.234,
			2817.31,
			2819.389,
			2820.431,
			2822.512,
			2824.604,
			2825.654,
			2827.751,
			2828.803,
			2830.929,
			2833.052,
			2836.244,
			2838.376,
			2841.551,
			2842.612,
			2843.674,
			2846.832,
			2848.944,
			2852.106,
			2854.224,
			2856.345,
			2859.541,
			2860.604,
			2861.668,
			2865.944,
			2867.016,
			2870.241,
			2872.397,
			2875.646,
			2877.822,
			2880.007,
			2881.103,
			2883.299,
			2884.401,
			2886.605,
			2888.819,
			2892.141,
			2894.365,
			2897.693,
			2898.806,
			2899.92,
			2903.261,
			2904.379,
			2905.5,
			2908.872,
			2911.137,
			2913.414,
			2914.557,
			2916.861,
			2918.018,
			2920.339,
			2922.68,
			2923.854,
			2926.209,
			2928.576,
			2930.951,
			2933.338,
			2934.535,
			2936.937,
			2939.347,
			2940.533,
			2942.972,
			2944.183,
			2946.606,
			2949.032,
			2952.669,
			2955.115,
			2958.796,
			2960.027,
			2961.261,
			2964.998,
			2967.466,
			2971.217,
			2973.728,
			2976.268,
			2977.509,
			2980.038,
			2981.303,
			2983.836,
			2986.376,
			2987.65,
			2990.205,
			2992.769,
			2995.344,
			2997.927,
			2999.222,
			3001.817,
			3004.416,
			3005.718,
			3008.323,
			3009.625,
			3012.234,
			3014.871,
			3018.801,
			3021.402,
			3025.363,
			3026.678,
			3027.977,
			3031.946,
			3034.617,
			3038.584,
			3041.273,
			3043.925,
			3045.263,
			3047.942,
			3049.305,
			3051.967,
			3054.679,
			3056.003,
			3058.701,
			3061.407,
			3065.477,
			3068.197,
			3072.308,
			3073.651,
			3075.019,
			3079.132,
			3081.882,
			3086.042,
			3088.783,
			3092.94,
			3095.716,
			3097.104,
			3098.514,
			3103.995,
			3105.356,
			3109.39,
			3112.033,
			3115.958,
			3118.494,
			3121.016,
			3122.264,
			3124.734,
			3125.955,
			3128.37,
			3130.75,
			3134.257,
			3136.55,
			3139.925,
			3141.032,
			3142.13,
			3145.376,
			3147.501,
			3150.645,
			3152.686,
			3154.693,
			3155.684,
			3157.643,
			3158.612,
			3160.534,
			3162.437,
			3163.378,
			3164.316,
			3167.117,
			3168.96,
			3171.719,
			3173.546,
			3175.366,
			3176.275,
			3178.097,
			3179.009,
			3180.833,
			3182.664,
			3185.456,
			3187.317,
			3190.18,
			3191.135,
			3192.09,
			3195.012,
			3196.966,
			3199.94,
			3201.93,
			3203.937,
			3204.945,
			3206.96,
			3207.968,
			3209.994,
			3212.017,
			3213.029,
			3215.057,
			3217.083,
			3220.103,
			3221.106,
			3222.11,
			3225.092,
			3226.08,
			3227.068,
			3230.006,
			3231.954,
			3233.896,
			3236.773,
			3238.7,
			3241.545,
			3242.496,
			3243.449,
			3246.289,
			3248.185,
			3251.036,
			3252.941,
			3254.858,
			3255.823,
			3257.76,
			3258.731,
			3260.697,
			3262.677,
			3263.671,
			3265.679,
			3267.703,
			3270.764,
			3272.822,
			3275.927,
			3276.967,
			3278.01,
			3281.153,
			3283.26,
			3285.375,
			3288.555,
			3290.67,
			3291.737,
			3293.877,
			3294.951,
			3296.027,
			3299.284,
			3300.367,
			3301.454,
			3304.709,
			3306.906,
			3310.219,
			3312.441,
			3314.688,
			3315.785,
			3318.016,
			3319.148,
			3321.365,
			3323.538,
			3326.769,
			3328.88,
			3331.977,
			3332.99,
			3333.994,
			3336.953,
			3338.897,
			3341.723,
			3343.546,
			3345.355,
			3346.249,
			3348.028,
			3348.9,
			3350.631,
			3352.346,
			3353.195,
			3354.892,
			3356.591,
			3358.28,
			3359.966,
			3360.811,
			3362.5,
			3364.185,
			3365.027,
			3366.713,
			3367.557,
			3369.246,
			3370.933,
			3373.475,
			3375.174,
			3377.725,
			3378.582,
			3379.439,
			3382.002,
			3383.73,
			3386.315,
			3388.047,
			3389.789,
			3390.657,
			3392.388,
			3393.264,
			3395.01,
			3396.749,
			3397.616,
			3399.363,
			3401.103,
			3403.71,
			3404.582,
			3405.451,
			3408.052,
			3408.922,
			3409.79,
			3411.522,
			3412.388,
			3414.128,
			3415.87,
			3418.484,
			3420.245,
			3422.884,
			3423.773,
			3424.667,
			3427.341,
			3429.129,
			3431.816,
			3433.604,
			3435.404,
			3436.301,
			3438.093,
			3438.991,
			3440.787,
			3443.473,
			3445.267,
			3447.057,
			3449.748,
			3451.548,
			3454.247,
			3455.147,
			3456.047,
			3458.743,
			3460.541,
			3463.228,
			3465.012,
			3467.665,
			3469.426,
			3470.301,
			3471.172,
			3473.776,
			3474.641,
			3475.502,
			3478.073,
			3479.781,
			3481.482,
			3482.331,
			3484.025,
			3485.709,
			3486.546,
			3489.053,
			3490.711,
			3492.358,
			3494.821,
			3496.432,
			3498.863,
			3499.687,
			3500.495,
			3502.918,
			3504.54,
			3506.971,
			3508.583,
			3510.219,
			3511.04,
			3512.689,
			3513.519,
			3515.189,
			3516.871,
			3517.717,
			3519.419,
			3521.135,
			3523.734,
			3524.608,
			3525.484,
			3527.253,
			3529.035,
			3529.931,
			3531.738,
			3532.647,
			3534.478,
			3536.323,
			3539.138,
			3541.021,
			3543.88,
			3544.84,
			3545.804,
			3548.73,
			3550.7,
			3553.694,
			3555.708,
			3558.76,
			3560.811,
			3561.841,
			3563.921,
			3566.014,
			3567.065,
			3569.18,
			3571.307,
			3574.534,
			3576.702,
			3579.986,
			3581.083,
			3582.179,
			3584.373,
			3585.472,
			3587.677,
			3589.896,
			3593.239,
			3595.494,
			3598.882,
			3600.01,
			3601.139,
			3604.532,
			3606.796,
			3610.185,
			3612.438,
			3615.809,
			3618.052,
			3620.283,
			3621.396,
			3623.622,
			3624.731,
			3626.947,
			3629.162,
			3632.504,
			3633.617,
			3634.735,
			3638.104,
			3639.228,
			3640.356,
			3643.744,
			3646.009,
			3649.415,
			3651.693,
			3653.977,
			3655.123,
			3657.419,
			3658.571,
			3659.726,
			3663.209,
			3664.375,
			3665.543,
			3669.059,
			3671.41,
			3673.767,
			3674.949,
			3677.315,
			3679.689,
			3680.877,
			3683.255,
			3684.448,
			3686.812,
			3689.201,
			3692.788,
			3695.186,
			3698.78,
			3699.981,
			3701.182,
			3704.786,
			3707.194,
			3710.816,
			3713.239,
			3715.671,
			3716.891,
			3719.338,
			3720.564,
			3723.025,
			3726.733,
			3729.218,
			3731.712,
			3734.216,
			3736.729,
			3737.993,
			3740.527,
			3743.075,
			3744.357,
			3746.927,
			3748.222,
			3750.826,
			3753.443,
			3757.41,
			3760.084,
			3764.118,
			3765.464,
			3766.813,
			3770.856,
			3773.575,
			3777.608,
			3780.264,
			3784.272,
			3786.93,
			3788.261,
			3790.92,
			3794.929,
			3797.586,
			3800.227,
			3802.89,
			3805.559,
			3806.893,
			3810.929,
			3812.269,
			3813.61,
			3816.297,
			3817.621,
			3820.317,
			3823.044,
			3827.115,
			3829.843,
			3833.929,
			3835.304,
			3836.703,
			3840.835,
			3843.618,
			3847.839,
			3850.649,
			3853.465,
			3854.875,
			3857.701,
			3859.092,
			3861.946,
			3864.786,
			3866.184,
			3869.03,
			3871.882,
			3876.185,
			3879.044,
			3883.317,
			3884.751,
			3886.186,
			3889.061,
			3890.501,
			3893.386,
			3897.727,
			3900.629,
			3903.538,
			3907.915,
			3909.401,
			3910.865,
			3915.263,
			3918.198,
			3922.618,
			3925.574,
			3928.531,
			3930.012,
			3932.976,
			3935.941,
			3937.426,
			3941.885,
			3944.839,
			3947.848,
			3952.328,
			3955.314,
			3959.771,
			3961.267,
			3962.765,
			3967.261,
			3970.264,
			3974.802,
			3977.792,
			3982.325,
			3985.354,
			3986.87,
			3989.906,
			3994.472,
			3997.521,
			4000.576,
			4003.636,
			4006.702,
			4008.236,
			4011.309,
			4014.387,
			4015.928,
			4019.014,
			4020.56,
			4023.654,
			4026.754,
			4031.415,
			4034.528,
			4039.207,
			4040.77,
			4042.333,
			4047.033,
			4050.172,
			4054.89,
			4058.042,
			4061.199,
			4062.78,
			4065.944,
			4067.529,
			4070.701,
			4073.874,
			4075.458,
			4077.04,
			4081.755,
			4086.4,
			4087.929,
			4089.448,
			4093.947,
			4095.427,
			4096.899,
			4099.812,
			4101.253,
			4104.106,
			4106.922,
			4111.073,
			4113.794,
			4117.803,
			4119.121,
			4120.43,
			4124.301,
			4126.835,
			4130.568,
			4133.011,
			4135.418,
			4136.608,
			4138.963,
			4141.285,
			4142.434,
			4144.7,
			4145.82,
			4148.033,
			4150.208,
			4153.402,
			4155.487,
			4158.547,
			4159.549,
			4160.542,
			4163.469,
			4165.375,
			4168.167,
			4169.989,
			4172.672,
			4174.397,
			4175.266,
			4176.098,
			4178.586,
			4179.398,
			4180.201,
			4182.554,
			4184.091,
			4185.582,
			4186.313,
			4187.751,
			4189.154,
			4189.843,
			4191.192,
			4191.853,
			4193.149,
			4194.415,
			4196.254,
			4197.436,
			4199.162,
			4199.727,
			4200.286,
			4201.922,
			4202.99,
			4204.571,
			4205.619,
			4206.656,
			4207.171,
			4208.198,
			4208.711,
			4209.736,
			4210.761,
			4211.278,
			4211.803,
			4213.373,
			4214.419,
			4215.469,
			4215.997,
			4217.124,
			4218.269,
			4218.843,
			4219.994,
			4220.572,
			4221.824,
			4223.898,
			4225.291,
			4226.75,
			4229.133,
			4229.931,
			4230.731,
			4233.06,
			4234.599,
			4236.864,
			4238.203,
			4239.546,
			4240.221,
			4241.578,
			4242.213,
			4243.462,
			4244.715,
			4245.343,
			4246.603,
			4247.832,
			4249.657,
			4250.876,
			4252.099,
			4253.312,
			4253.918,
			4255.131,
			4255.737,
			4256.953,
			4258.8,
			4260.033,
			4261.265,
			4263.153,
			4263.799,
			4264.444,
			4266.383,
			4267.689,
			4269.715,
			4271.07,
			4272.431,
			4273.126,
			4274.519,
			4275.215,
			4276.62,
			4278.028,
			4278.741,
			4280.172,
			4281.627,
			4283.804,
			4284.538,
			4285.287,
			4287.507,
			4288.271,
			4289.025,
			4291.298,
			4292.838,
			4294.39,
			4296.741,
			4298.327,
			4300.729,
			4301.538,
			4302.349,
			4304.803,
			4306.455,
			4308.959,
			4310.641,
			4312.323,
			4313.164,
			4314.853,
			4316.555,
			4317.424,
			4319.145,
			4320.009,
			4321.729,
			4323.479,
			4326.087,
			4327.85,
			4330.512,
			4331.404,
			4332.299,
			4335,
			4336.816,
			4339.56,
			4341.405,
			4343.264,
			4344.197,
			4346.076,
			4347.02,
			4348.918,
			4351.789,
			4352.753,
			4355.663,
			4357.619,
			4359.587,
			4360.575,
			4362.562,
			4364.561,
			4365.564,
			4368.593,
			4370.627,
			4372.672,
			4375.762,
			4377.836,
			4380.967,
			4382.016,
			4383.063,
			4386.207,
			4388.312,
			4391.489,
			4393.62,
			4395.761,
			4396.835,
			4398.991,
			4401.157,
			4402.244,
			4404.424,
			4405.519,
			4406.615,
			4409.919,
			4413.247,
			4414.361,
			4415.477,
			4418.84,
			4419.966,
			4421.094,
			4424.494,
			4426.773,
			4429.061,
			4432.51,
			4434.821,
			4438.307,
			4439.474,
			4440.643,
			4444.164,
			4446.522,
			4450.08,
			4452.464,
			4454.856,
			4456.057,
			4458.464,
			4459.672,
			4462.094,
			4464.525,
			4465.745,
			4468.19,
			4470.644,
			4474.341,
			4475.579,
			4476.818,
			4480.549,
			4481.797,
			4483.047,
			4486.812,
			4489.333,
			4493.13,
			4495.673,
			4498.223,
			4502.063,
			4503.347,
			4504.634,
			4508.504,
			4509.798,
			4511.093,
			4514.992,
			4517.601,
			4520.208,
			4521.514,
			4524.13,
			4526.754,
			4528.068,
			4532.02,
			4534.664,
			4537.315,
			4538.934
		],
		[
			0,
			0.053,
			0.1,
			0.175,
			0.208,
			0.237,
			0.301,
			0.361,
			0.429,
			0.485,
			0.533,
			0.549,
			0.609,
			0.642,
			0.673,
			0.734,
			0.75,
			0.796,
			0.859,
			0.921,
			0.968,
			0.981,
			1.045,
			1.075,
			1.107,
			1.169,
			1.185,
			1.229,
			1.295,
			1.354,
			1.401,
			1.478,
			1.511,
			1.542,
			1.602,
			1.665,
			1.727,
			1.788,
			1.851,
			1.912,
			1.944,
			1.974,
			2.053,
			2.1,
			2.162,
			2.223,
			2.284,
			2.347,
			2.379,
			2.408,
			2.471,
			2.533,
			2.596,
			2.656,
			2.718,
			2.78,
			2.811,
			2.841,
			2.903,
			2.967,
			3.029,
			3.092,
			3.137,
			3.152,
			3.216,
			3.247,
			3.277,
			3.338,
			3.354,
			3.401,
			3.464,
			3.524,
			3.586,
			3.648,
			3.679,
			3.711,
			3.774,
			3.835,
			3.897,
			3.958,
			4.019,
			4.084,
			4.115,
			4.146,
			4.208,
			4.225,
			4.269,
			4.333,
			4.392,
			4.441,
			4.456,
			4.518,
			4.554,
			4.58,
			4.657,
			4.704,
			4.766,
			4.828,
			4.874,
			4.952,
			4.982,
			5.014,
			5.09,
			5.137,
			5.201,
			5.261,
			5.308,
			5.323,
			5.385,
			5.415,
			5.447,
			5.525,
			5.569,
			5.635,
			5.696,
			5.742,
			5.758,
			5.82,
			5.849,
			5.881,
			5.943,
			5.959,
			6.006,
			6.071,
			6.128,
			6.175,
			6.252,
			6.284,
			6.314,
			6.392,
			6.441,
			6.501,
			6.565,
			6.614,
			6.624,
			6.688,
			6.72,
			6.749,
			6.811,
			6.827,
			6.874,
			6.937,
			6.998,
			7.045,
			7.06,
			7.122,
			7.151,
			7.184,
			7.246,
			7.26,
			7.307,
			7.371,
			7.431,
			7.478,
			7.556,
			7.587,
			7.618,
			7.679,
			7.696,
			7.742,
			7.803,
			7.865,
			7.911,
			7.927,
			7.991,
			8.02,
			8.053,
			8.113,
			8.128,
			8.176,
			8.241,
			8.298,
			8.345,
			8.362,
			8.423,
			8.452,
			8.485,
			8.549,
			8.611,
			8.678,
			8.735,
			8.796,
			8.859,
			8.889,
			8.92,
			8.997,
			9.043,
			9.105,
			9.169,
			9.229,
			9.292,
			9.321,
			9.353,
			9.417,
			9.431,
			9.477,
			9.54,
			9.603,
			9.648,
			9.725,
			9.758,
			9.787,
			9.85,
			9.912,
			9.975,
			10.035,
			10.083,
			10.097,
			10.159,
			10.191,
			10.223,
			10.283,
			10.299,
			10.345,
			10.408,
			10.468,
			10.517,
			10.532,
			10.592,
			10.624,
			10.654,
			10.717,
			10.741,
			10.779,
			10.843,
			10.903,
			10.949,
			11.029,
			11.058,
			11.087,
			11.167,
			11.213,
			11.277,
			11.338,
			11.384,
			11.401,
			11.463,
			11.493,
			11.524,
			11.584,
			11.6,
			11.647,
			11.711,
			11.772,
			11.833,
			11.896,
			11.927,
			11.956,
			12.036,
			12.081,
			12.145,
			12.207,
			12.252,
			12.331,
			12.361,
			12.391,
			12.452,
			12.516,
			12.579,
			12.641,
			12.687,
			12.702,
			12.763,
			12.799,
			12.827,
			12.888,
			12.902,
			12.95,
			13.012,
			13.075,
			13.119,
			13.136,
			13.198,
			13.23,
			13.261,
			13.322,
			13.384,
			13.446,
			13.509,
			13.555,
			13.631,
			13.664,
			13.694,
			13.756,
			13.772,
			13.818,
			13.879,
			13.942,
			13.988,
			14.002,
			14.065,
			14.097,
			14.129,
			14.206,
			14.256,
			14.314,
			14.376,
			14.423,
			14.501,
			14.53,
			14.562,
			14.625,
			14.685,
			14.749,
			14.811,
			14.862,
			14.933,
			14.964,
			14.995,
			15.058,
			15.074,
			15.121,
			15.182,
			15.245,
			15.29,
			15.308,
			15.367,
			15.4,
			15.43,
			15.492,
			15.509,
			15.554,
			15.617,
			15.679,
			15.726,
			15.802,
			15.834,
			15.865,
			15.926,
			15.989,
			16.051,
			16.112,
			16.159,
			16.175,
			16.237,
			16.268,
			16.298,
			16.375,
			16.423,
			16.484,
			16.548,
			16.593,
			16.608,
			16.671,
			16.7,
			16.734,
			16.809,
			16.855,
			16.92,
			16.922,
			16.981,
			17.026,
			17.105,
			17.137,
			17.165,
			17.229,
			17.29,
			17.352,
			17.415,
			17.478,
			17.54,
			17.57,
			17.599,
			17.663,
			17.677,
			17.725,
			17.786,
			17.848,
			17.897,
			17.973,
			18.002,
			18.034,
			18.114,
			18.16,
			18.221,
			18.282,
			18.329,
			18.407,
			18.437,
			18.468,
			18.529,
			18.546,
			18.591,
			18.656,
			18.716,
			18.762,
			18.779,
			18.841,
			18.871,
			18.904,
			18.963,
			18.985,
			19.026,
			19.088,
			19.151,
			19.198,
			19.276,
			19.304,
			19.336,
			19.399,
			19.46,
			19.523,
			19.585,
			19.631,
			19.647,
			19.709,
			19.741,
			19.771,
			19.833,
			19.847,
			19.895,
			19.957,
			20.019,
			20.066,
			20.08,
			20.143,
			20.173,
			20.204,
			20.266,
			20.283,
			20.329,
			20.392,
			20.453,
			20.499,
			20.576,
			20.608,
			20.637,
			20.701,
			20.764,
			20.825,
			20.886,
			20.935,
			20.948,
			21.012,
			21.048,
			21.072,
			21.134,
			21.151,
			21.197,
			21.259,
			21.321,
			21.369,
			21.382,
			21.445,
			21.476,
			21.508,
			21.569,
			21.584,
			21.63,
			21.693,
			21.754,
			21.801,
			21.879,
			21.911,
			21.94,
			22.002,
			22.066,
			22.126,
			22.188,
			22.235,
			22.252,
			22.313,
			22.343,
			22.376,
			22.436,
			22.453,
			22.499,
			22.561,
			22.623,
			22.685,
			22.748,
			22.777,
			22.808,
			22.888,
			22.934,
			22.996,
			23.057,
			23.108,
			23.182,
			23.211,
			23.243,
			23.307,
			23.368,
			23.431,
			23.492,
			23.539,
			23.552,
			23.616,
			23.647,
			23.677,
			23.755,
			23.802,
			23.863,
			23.926,
			23.97,
			23.988,
			24.051,
			24.08,
			24.111,
			24.174,
			24.236,
			24.297,
			24.358,
			24.423,
			24.485,
			24.516,
			24.546,
			24.608,
			24.623,
			24.671,
			24.732,
			24.794,
			24.854,
			24.916,
			24.95,
			24.98,
			25.057,
			25.103,
			25.169,
			25.226,
			25.275,
			25.35,
			25.383,
			25.413,
			25.475,
			25.536,
			25.598,
			25.662,
			25.709,
			25.724,
			25.784,
			25.815,
			25.848,
			25.911,
			25.925,
			25.971,
			26.034,
			26.094,
			26.141,
			26.159,
			26.22,
			26.252,
			26.283,
			26.359,
			26.405,
			26.466,
			26.531,
			26.576,
			26.654,
			26.685,
			26.716,
			26.779,
			26.841,
			26.901,
			26.963,
			27.011,
			27.027,
			27.087,
			27.118,
			27.15,
			27.232,
			27.273,
			27.338,
			27.399,
			27.46,
			27.521,
			27.554,
			27.585,
			27.662,
			27.708,
			27.774,
			27.831,
			27.895,
			27.956,
			27.986,
			28.019,
			28.094,
			28.142,
			28.202,
			28.267,
			28.328,
			28.39,
			28.42,
			28.452,
			28.515,
			28.529,
			28.575,
			28.64,
			28.701,
			28.747,
			28.825,
			28.855,
			28.885,
			28.949,
			29.011,
			29.071,
			29.135,
			29.182,
			29.197,
			29.257,
			29.294,
			29.321,
			29.381,
			29.397,
			29.444,
			29.51,
			29.568,
			29.632,
			29.693,
			29.725,
			29.756,
			29.815,
			29.831,
			29.88,
			29.94,
			30.003,
			30.048,
			30.126,
			30.158,
			30.189,
			30.25,
			30.312,
			30.374,
			30.437,
			30.497,
			30.562,
			30.592,
			30.623,
			30.684,
			30.7,
			30.747,
			30.808,
			30.871,
			30.917,
			30.932,
			30.996,
			31.026,
			31.058,
			31.12,
			31.134,
			31.179,
			31.242,
			31.305,
			31.368,
			31.429,
			31.458,
			31.491,
			31.568,
			31.615,
			31.678,
			31.737,
			31.802,
			31.862,
			31.893,
			31.923,
			32.002,
			32.049,
			32.112,
			32.17,
			32.236,
			32.297,
			32.328,
			32.36,
			32.42,
			32.437,
			32.482,
			32.547,
			32.608,
			32.654,
			32.732,
			32.763,
			32.794,
			32.855,
			32.918,
			32.979,
			33.041,
			33.086,
			33.104,
			33.165,
			33.196,
			33.228,
			33.29,
			33.304,
			33.351,
			33.417,
			33.476,
			33.538,
			33.599,
			33.631,
			33.661,
			33.723,
			33.786,
			33.848,
			33.909,
			33.956,
			34.033,
			34.065,
			34.096,
			34.173,
			34.218,
			34.28,
			34.344,
			34.39,
			34.406,
			34.466,
			34.498,
			34.53,
			34.607,
			34.654,
			34.715,
			34.778,
			34.824,
			34.902,
			34.931,
			34.963,
			35.042,
			35.088,
			35.149,
			35.211,
			35.259,
			35.274,
			35.335,
			35.366,
			35.397,
			35.46,
			35.478,
			35.522,
			35.583,
			35.645,
			35.691,
			35.708,
			35.77,
			35.8,
			35.831,
			35.894,
			35.91,
			35.955,
			36.018,
			36.081,
			36.142,
			36.204,
			36.234,
			36.265,
			36.343,
			36.39,
			36.451,
			36.513,
			36.574,
			36.637,
			36.668,
			36.698,
			36.776,
			36.824,
			36.884,
			36.947,
			36.993,
			37.011,
			37.072,
			37.102,
			37.133,
			37.194,
			37.211,
			37.257,
			37.318,
			37.381,
			37.428,
			37.505,
			37.541,
			37.567,
			37.629,
			37.646,
			37.693,
			37.754,
			37.816,
			37.862,
			37.879,
			37.941,
			37.97,
			38.003,
			38.065,
			38.079,
			38.126,
			38.188,
			38.25,
			38.297,
			38.374,
			38.405,
			38.437,
			38.497,
			38.56,
			38.625,
			38.683,
			38.73,
			38.746,
			38.808,
			38.84,
			38.87,
			38.948,
			38.995,
			39.057,
			39.118,
			39.164,
			39.18,
			39.243,
			39.274,
			39.303,
			39.367,
			39.382,
			39.429,
			39.49,
			39.553,
			39.603,
			39.675,
			39.708,
			39.739,
			39.799,
			39.862,
			39.925,
			39.985,
			40.047,
			40.111,
			40.142,
			40.173,
			40.249,
			40.296,
			40.358,
			40.419,
			40.467,
			40.481,
			40.543,
			40.577,
			40.607,
			40.668,
			40.685,
			40.731,
			40.792,
			40.853,
			40.9,
			40.979,
			41.011,
			41.041,
			41.103,
			41.166,
			41.228,
			41.287,
			41.336,
			41.351,
			41.412,
			41.444,
			41.476,
			41.538,
			41.552,
			41.598,
			41.665,
			41.721,
			41.784,
			41.847,
			41.876,
			41.908,
			41.986,
			42.033,
			42.095,
			42.157,
			42.218,
			42.282,
			42.31,
			42.344,
			42.421,
			42.467,
			42.531,
			42.591,
			42.637,
			42.651,
			42.714,
			42.745,
			42.777,
			42.856,
			42.9,
			42.966,
			43.023,
			43.087,
			43.149,
			43.18,
			43.212,
			43.273,
			43.333,
			43.397,
			43.459,
			43.504,
			43.521,
			43.584,
			43.614,
			43.644,
			43.708,
			43.727,
			43.769,
			43.832,
			43.894,
			43.941,
			43.954,
			44.018,
			44.048,
			44.079,
			44.158,
			44.203,
			44.266,
			44.325,
			44.387,
			44.45,
			44.48,
			44.511,
			44.576,
			44.637,
			44.699,
			44.761,
			44.807,
			44.823,
			44.884,
			44.915,
			44.946,
			45.008,
			45.025,
			45.069,
			45.134,
			45.195,
			45.241,
			45.255,
			45.318,
			45.35,
			45.38,
			45.458,
			45.506,
			45.568,
			45.629,
			45.675,
			45.753,
			45.79,
			45.813,
			45.893,
			45.94,
			46,
			46.063,
			46.11,
			46.125,
			46.187,
			46.218,
			46.249,
			46.325,
			46.374,
			46.439,
			46.498,
			46.542,
			46.56,
			46.619,
			46.653,
			46.682,
			46.744,
			46.762,
			46.807,
			46.87,
			46.93,
			46.978,
			47.056,
			47.087,
			47.118,
			47.18,
			47.24,
			47.303,
			47.365,
			47.413,
			47.428,
			47.49,
			47.519,
			47.551,
			47.613,
			47.628,
			47.675,
			47.736,
			47.798,
			47.862,
			47.923,
			47.954,
			47.986,
			48.046,
			48.064,
			48.109,
			48.172,
			48.232,
			48.296,
			48.358,
			48.386,
			48.419,
			48.482,
			48.543,
			48.606,
			48.668,
			48.714,
			48.73,
			48.791,
			48.823,
			48.852,
			48.931,
			48.977,
			49.04,
			49.101,
			49.149,
			49.225,
			49.257,
			49.288,
			49.348,
			49.41,
			49.474,
			49.535,
			49.581,
			49.598,
			49.659,
			49.691,
			49.72,
			49.798,
			49.847,
			49.911,
			49.968,
			50.031,
			50.092,
			50.124,
			50.155,
			50.218,
			50.231,
			50.279,
			50.341,
			50.404,
			50.464,
			50.527,
			50.558,
			50.589,
			50.666,
			50.714,
			50.775,
			50.838,
			50.884,
			50.9,
			50.962,
			50.992,
			51.024,
			51.084,
			51.101,
			51.146,
			51.208,
			51.27,
			51.333,
			51.394,
			51.426,
			51.457,
			51.535,
			51.58,
			51.643,
			51.706,
			51.752,
			51.829,
			51.859,
			51.89,
			51.954,
			51.975,
			52.015,
			52.077,
			52.138,
			52.185,
			52.2,
			52.262,
			52.293,
			52.326,
			52.386,
			52.403,
			52.448,
			52.511,
			52.575,
			52.621,
			52.636,
			52.697,
			52.729,
			52.76,
			52.819,
			52.837,
			52.882,
			52.945,
			53.008,
			53.069,
			53.131,
			53.163,
			53.194,
			53.27,
			53.317,
			53.381,
			53.44,
			53.488,
			53.502,
			53.566,
			53.597,
			53.627,
			53.687,
			53.704,
			53.751,
			53.815,
			53.874,
			53.92,
			53.938,
			53.999,
			54.036,
			54.061,
			54.124,
			54.184,
			54.247,
			54.308,
			54.37,
			54.432,
			54.465,
			54.495,
			54.558,
			54.574,
			54.62,
			54.681,
			54.743,
			54.79,
			54.803,
			54.869,
			54.899,
			54.93,
			55.008,
			55.054,
			55.114,
			55.177,
			55.225,
			55.302,
			55.333,
			55.363,
			55.425,
			55.487,
			55.551,
			55.611,
			55.674,
			55.736,
			55.768,
			55.798,
			55.86,
			55.874,
			55.921,
			55.984,
			56.046,
			56.107,
			56.168,
			56.201,
			56.231,
			56.294,
			56.308,
			56.355,
			56.419,
			56.478,
			56.526,
			56.603,
			56.634,
			56.666,
			56.729,
			56.788,
			56.851,
			56.913,
			56.959,
			56.975,
			57.038,
			57.067,
			57.101,
			57.161,
			57.177,
			57.224,
			57.284,
			57.346,
			57.409,
			57.47,
			57.501,
			57.534,
			57.594,
			57.611,
			57.659,
			57.719,
			57.781,
			57.844,
			57.905,
			57.938,
			57.969,
			58.044,
			58.092,
			58.159,
			58.215,
			58.263,
			58.278,
			58.34,
			58.372,
			58.403,
			58.465,
			58.479,
			58.525,
			58.588,
			58.651,
			58.698,
			58.775,
			58.806,
			58.836,
			58.897,
			58.961,
			59.022,
			59.084,
			59.129,
			59.208,
			59.24,
			59.27,
			59.346,
			59.394,
			59.455,
			59.517,
			59.581,
			59.643,
			59.674,
			59.704,
			59.766,
			59.782,
			59.829,
			59.89,
			59.952,
			59.999,
			60.075,
			60.108,
			60.137,
			60.201,
			60.223,
			60.261,
			60.324,
			60.385,
			60.432,
			60.448,
			60.509,
			60.542,
			60.573,
			60.635,
			60.649,
			60.697,
			60.764,
			60.821,
			60.868,
			60.882,
			60.945,
			60.976,
			61.008,
			61.068,
			61.085,
			61.131,
			61.192,
			61.256,
			61.301,
			61.38,
			61.41,
			61.44,
			61.503,
			61.563,
			61.626,
			61.688,
			61.734,
			61.751,
			61.811,
			61.842,
			61.875,
			61.937,
			61.952,
			61.999,
			62.062,
			62.121,
			62.17,
			62.184,
			62.245,
			62.282,
			62.309,
			62.37,
			62.387,
			62.432,
			62.494,
			62.558,
			62.617,
			62.682,
			62.712,
			62.742,
			62.805,
			62.868,
			62.93,
			62.991,
			63.036,
			63.053,
			63.114,
			63.145,
			63.176,
			63.237,
			63.255,
			63.3,
			63.365,
			63.426,
			63.473,
			63.548,
			63.58,
			63.611,
			63.673,
			63.736,
			63.798,
			63.859,
			63.921,
			63.982,
			64.015,
			64.045,
			64.124,
			64.168,
			64.231,
			64.292,
			64.356,
			64.416,
			64.448,
			64.478,
			64.54,
			64.557,
			64.602,
			64.668,
			64.728,
			64.787,
			64.851,
			64.881,
			64.914,
			64.976,
			65.038,
			65.098,
			65.161,
			65.209,
			65.223,
			65.286,
			65.317,
			65.347,
			65.409,
			65.425,
			65.47,
			65.533,
			65.594,
			65.658,
			65.719,
			65.75,
			65.782,
			65.844,
			65.86,
			65.906,
			65.966,
			66.029,
			66.076,
			66.153,
			66.185,
			66.216,
			66.293,
			66.341,
			66.408,
			66.463,
			66.511,
			66.526,
			66.587,
			66.619,
			66.649,
			66.713,
			66.729,
			66.774,
			66.837,
			66.898,
			66.943,
			66.959,
			67.021,
			67.052,
			67.083,
			67.146,
			67.207,
			67.271,
			67.333,
			67.393,
			67.457,
			67.488,
			67.517,
			67.594,
			67.641,
			67.704,
			67.765,
			67.811,
			67.828,
			67.89,
			67.92,
			67.951,
			68.014,
			68.03,
			68.075,
			68.138,
			68.199,
			68.247,
			68.324,
			68.354,
			68.384,
			68.447,
			68.51,
			68.57,
			68.633,
			68.679,
			68.696,
			68.758,
			68.788,
			68.82,
			68.882,
			68.898,
			68.945,
			69.007,
			69.066,
			69.131,
			69.191,
			69.224,
			69.254,
			69.314,
			69.331,
			69.379,
			69.441,
			69.502,
			69.548,
			69.627,
			69.658,
			69.687,
			69.749,
			69.813,
			69.875,
			69.936,
			69.982,
			69.997,
			70.061,
			70.091,
			70.123,
			70.184,
			70.201,
			70.246,
			70.309,
			70.371,
			70.418,
			70.432,
			70.494,
			70.53,
			70.557,
			70.617,
			70.634,
			70.679,
			70.742,
			70.805,
			70.851,
			70.929,
			70.958,
			70.99,
			71.052,
			71.114,
			71.176,
			71.237,
			71.285,
			71.3,
			71.363,
			71.392,
			71.425,
			71.487,
			71.503,
			71.547,
			71.612,
			71.674,
			71.719,
			71.734,
			71.796,
			71.826,
			71.857,
			71.921,
			71.934,
			71.982,
			72.046,
			72.107,
			72.154,
			72.229,
			72.263,
			72.292,
			72.357,
			72.418,
			72.479,
			72.542,
			72.591,
			72.601,
			72.663,
			72.696,
			72.727,
			72.804,
			72.85,
			72.915,
			72.975,
			73.037,
			73.099,
			73.129,
			73.161,
			73.223,
			73.284,
			73.346,
			73.409,
			73.471,
			73.534,
			73.565,
			73.594,
			73.657,
			73.673,
			73.717,
			73.782,
			73.842,
			73.888,
			73.904,
			73.966,
			73.996,
			74.027,
			74.106,
			74.154,
			74.215,
			74.278,
			74.324,
			74.4,
			74.431,
			74.461,
			74.526,
			74.586,
			74.652,
			74.711,
			74.757,
			74.774,
			74.835,
			74.866,
			74.898,
			74.959,
			74.975,
			75.022,
			75.084,
			75.146,
			75.192,
			75.208,
			75.269,
			75.301,
			75.332,
			75.393,
			75.41,
			75.453,
			75.516,
			75.579,
			75.625,
			75.702,
			75.735,
			75.765,
			75.842,
			75.888,
			75.954,
			76.012,
			76.076,
			76.137,
			76.167,
			76.198,
			76.26,
			76.278,
			76.323,
			76.385,
			76.448,
			76.508,
			76.572,
			76.601,
			76.634,
			76.695,
			76.716,
			76.757,
			76.821,
			76.881,
			76.929,
			77.006,
			77.036,
			77.067,
			77.129,
			77.191,
			77.254,
			77.314,
			77.378,
			77.439,
			77.471,
			77.501,
			77.564,
			77.577,
			77.626,
			77.687,
			77.748,
			77.795,
			77.812,
			77.875,
			77.904,
			77.937,
			77.996,
			78.06,
			78.122,
			78.184,
			78.229,
			78.247,
			78.308,
			78.338,
			78.37,
			78.433,
			78.447,
			78.494,
			78.554,
			78.618,
			78.663,
			78.68,
			78.743,
			78.778,
			78.803,
			78.866,
			78.881,
			78.928,
			78.991,
			79.051,
			79.098,
			79.177,
			79.207,
			79.239,
			79.299,
			79.361,
			79.424,
			79.486,
			79.531,
			79.548,
			79.611,
			79.64,
			79.673,
			79.748,
			79.796,
			79.858,
			79.92,
			79.967,
			79.98,
			80.042,
			80.076,
			80.103,
			80.169,
			80.183,
			80.23,
			80.293,
			80.355,
			80.401,
			80.478,
			80.509,
			80.54,
			80.601,
			80.663,
			80.725,
			80.789,
			80.851,
			80.913,
			80.942,
			80.975,
			81.052,
			81.097,
			81.159,
			81.22,
			81.27,
			81.284,
			81.347,
			81.376,
			81.409,
			81.469,
			81.487,
			81.531,
			81.593,
			81.657,
			81.703,
			81.781,
			81.81,
			81.843,
			81.905,
			81.967,
			82.029,
			82.091,
			82.137,
			82.151,
			82.215,
			82.245,
			82.276,
			82.336,
			82.353,
			82.401,
			82.464,
			82.524,
			82.587,
			82.648,
			82.679,
			82.709,
			82.771,
			82.789,
			82.833,
			82.9,
			82.957,
			83.003,
			83.081,
			83.113,
			83.145,
			83.22,
			83.267,
			83.332,
			83.391,
			83.44,
			83.456,
			83.517,
			83.547,
			83.577,
			83.655,
			83.704,
			83.763,
			83.827,
			83.887,
			83.95,
			83.981,
			84.012,
			84.091,
			84.135,
			84.199,
			84.261,
			84.324,
			84.384,
			84.415,
			84.447,
			84.525,
			84.571,
			84.632,
			84.696,
			84.741,
			84.756,
			84.82,
			84.848,
			84.881,
			84.943,
			84.961,
			85.003,
			85.066,
			85.128,
			85.191,
			85.251,
			85.283,
			85.313,
			85.378,
			85.437,
			85.5,
			85.563,
			85.609,
			85.625,
			85.687,
			85.718,
			85.749,
			85.81,
			85.825,
			85.871,
			85.934,
			85.997,
			86.044,
			86.058,
			86.121,
			86.152,
			86.182,
			86.245,
			86.261,
			86.307,
			86.368,
			86.43,
			86.477,
			86.554,
			86.587,
			86.618,
			86.678,
			86.741,
			86.803,
			86.866,
			86.911,
			86.926,
			86.988,
			87.025,
			87.052,
			87.114,
			87.128,
			87.174,
			87.236,
			87.3,
			87.347,
			87.424,
			87.454,
			87.486,
			87.547,
			87.61,
			87.671,
			87.734,
			87.796,
			87.857,
			87.888,
			87.92,
			87.98,
			87.998,
			88.042,
			88.103,
			88.166,
			88.214,
			88.229,
			88.292,
			88.323,
			88.353,
			88.416,
			88.431,
			88.477,
			88.54,
			88.601,
			88.663,
			88.725,
			88.757,
			88.787,
			88.849,
			88.911,
			88.974,
			89.034,
			89.084,
			89.098,
			89.16,
			89.19,
			89.222,
			89.284,
			89.299,
			89.344,
			89.408,
			89.471,
			89.516,
			89.533,
			89.595,
			89.625,
			89.654,
			89.717,
			89.732,
			89.779,
			89.846,
			89.904,
			89.966,
			90.028,
			90.058,
			90.091,
			90.151,
			90.213,
			90.276,
			90.336,
			90.384,
			90.401,
			90.461,
			90.491,
			90.524,
			90.585,
			90.602,
			90.646,
			90.711,
			90.772,
			90.834,
			90.895,
			90.926,
			90.958,
			91.02,
			91.034,
			91.08,
			91.146,
			91.206,
			91.252,
			91.331,
			91.361,
			91.392,
			91.469,
			91.514,
			91.578,
			91.64,
			91.686,
			91.702,
			91.763,
			91.793,
			91.826,
			91.887,
			91.903,
			91.948,
			92.011,
			92.074,
			92.12,
			92.137,
			92.199,
			92.228,
			92.261,
			92.337,
			92.384,
			92.445,
			92.509,
			92.569,
			92.631,
			92.663,
			92.694,
			92.756,
			92.818,
			92.882,
			92.943,
			92.987,
			93.004,
			93.066,
			93.098,
			93.129,
			93.19,
			93.208,
			93.253,
			93.314,
			93.377,
			93.423,
			93.5,
			93.533,
			93.563,
			93.64,
			93.687,
			93.749,
			93.809,
			93.857,
			93.873,
			93.934,
			93.964,
			93.997,
			94.075,
			94.121,
			94.181,
			94.244,
			94.291,
			94.305,
			94.367,
			94.4,
			94.429,
			94.509,
			94.553,
			94.617,
			94.679,
			94.74,
			94.803,
			94.833,
			94.865,
			94.926,
			94.988,
			95.052,
			95.112,
			95.159,
			95.174,
			95.237,
			95.273,
			95.299,
			95.359,
			95.375,
			95.42,
			95.485,
			95.547,
			95.593,
			95.609,
			95.671,
			95.702,
			95.733,
			95.809,
			95.857,
			95.919,
			95.979,
			96.026,
			96.103,
			96.136,
			96.166,
			96.244,
			96.291,
			96.352,
			96.415,
			96.46,
			96.478,
			96.54,
			96.568,
			96.601,
			96.662,
			96.678,
			96.725,
			96.787,
			96.848,
			96.894,
			96.911,
			96.974,
			97.003,
			97.034,
			97.112,
			97.159,
			97.221,
			97.284,
			97.346,
			97.408,
			97.436,
			97.469,
			97.532,
			97.547,
			97.593,
			97.653,
			97.717,
			97.763,
			97.779,
			97.842,
			97.872,
			97.904,
			97.981,
			98.028,
			98.087,
			98.115
		]
	],
	'1677_1679_7982': [
		[
			62.521
		],
		[
			0
		],
		[
			0,
			0.609,
			2.222,
			7.069,
			8.688,
			10.307,
			15.167,
			18.412,
			21.662,
			26.544,
			29.804,
			34.701,
			36.336,
			37.971,
			44.524,
			46.164,
			51.092,
			54.382,
			57.676,
			59.325,
			62.625,
			65.93,
			67.584,
			70.894,
			72.551,
			75.867,
			79.188,
			84.176,
			87.506,
			92.509,
			94.179,
			95.85,
			100.868,
			104.217,
			109.25,
			112.61,
			115.974,
			121.027,
			122.714,
			124.402,
			129.475,
			131.169,
			132.864,
			137.955,
			141.352,
			144.752,
			146.453,
			149.857,
			153.265,
			154.97,
			158.382,
			160.09,
			163.507,
			166.929,
			172.069,
			175.501,
			180.656,
			182.376,
			184.097,
			189.265,
			192.714,
			197.893,
			201.346,
			206.5,
			209.904,
			211.594,
			214.944,
			219.897,
			221.529,
			226.366,
			229.542,
			234.233,
			237.313,
			240.355,
			241.862,
			244.851,
			246.331,
			249.264,
			252.158,
			256.427,
			259.224,
			263.347,
			264.702,
			266.048,
			270.026,
			272.632,
			276.469,
			278.98,
			281.454,
			282.677,
			285.094,
			286.29,
			288.654,
			290.984,
			292.136,
			294.411,
			296.647,
			299.931,
			301.007,
			302.073,
			305.216,
			306.245,
			307.264,
			309.275,
			310.266,
			312.221,
			314.139,
			316.945,
			318.77,
			321.46,
			322.335,
			323.202,
			326.612,
			327.448,
			329.93,
			331.566,
			333.993,
			335.602,
			336.405,
			338.012,
			340.414,
			342.015,
			343.627,
			346.038,
			347.657,
			350.093,
			350.905,
			351.716,
			354.168,
			355.799,
			358.256,
			359.899,
			361.539,
			364.028,
			364.859,
			365.689,
			368.19,
			369.881,
			372.411,
			374.11,
			375.811,
			376.659,
			378.356,
			380.054,
			380.9,
			383.434,
			385.151,
			386.867,
			389.468,
			391.255,
			393.93,
			394.851,
			395.769,
			398.518,
			400.354,
			403.099,
			404.937,
			406.788,
			407.712,
			409.562,
			410.489,
			412.335,
			414.179,
			415.095,
			416.906,
			418.712,
			420.523,
			422.342,
			423.251,
			425.072,
			426.873,
			427.774,
			429.58,
			430.482,
			432.27,
			434.059,
			436.739,
			438.518,
			441.194,
			442.076,
			442.959,
			445.618,
			447.378,
			450.017,
			451.78,
			453.53,
			454.407,
			456.164,
			457.037,
			458.772,
			460.509,
			461.378,
			463.104,
			464.823,
			467.408,
			468.304,
			469.201,
			471.89,
			472.794,
			473.732,
			475.609,
			476.547,
			478.413,
			480.258,
			483.016,
			484.819,
			487.511,
			488.404,
			489.287,
			491.932,
			493.692,
			496.312,
			498.079,
			500.815,
			502.657,
			503.598,
			505.459,
			508.283,
			510.194,
			512.116,
			515.028,
			516.006,
			516.987,
			519.935,
			520.921,
			521.91,
			524.896,
			526.902,
			528.92,
			531.968,
			534.014,
			537.105,
			538.14,
			539.178,
			542.308,
			543.356,
			544.404,
			547.546,
			549.65,
			551.765,
			552.826,
			554.957,
			557.101,
			558.176,
			560.336,
			561.421,
			563.598,
			565.786,
			569.089,
			571.323,
			574.664,
			575.783,
			576.904,
			580.28,
			582.542,
			585.954,
			588.242,
			590.539,
			591.694,
			594.011,
			595.172,
			597.504,
			599.844,
			601.018,
			603.375,
			605.742,
			608.12,
			610.51,
			611.708,
			614.123,
			616.548,
			617.771,
			620.23,
			621.463,
			623.959,
			626.477,
			630.233,
			632.778,
			636.492,
			637.739,
			638.973,
			642.659,
			645.099,
			648.714,
			651.09,
			654.587,
			656.878,
			658.01,
			660.249,
			663.543,
			665.689,
			667.798,
			670.889,
			672.903,
			675.872,
			676.837,
			677.793,
			680.606,
			682.437,
			684.238,
			686.893,
			688.631,
			691.198,
			692.042,
			692.882,
			695.383,
			697.06,
			699.566,
			701.247,
			702.964,
			703.819,
			705.528,
			706.389,
			708.118,
			709.844,
			710.705,
			712.426,
			714.143,
			716.731,
			718.491,
			721.143,
			722.047,
			722.962,
			725.717,
			727.562,
			730.341,
			732.184,
			733.977,
			736.687,
			737.574,
			738.461,
			741.141,
			742.041,
			742.94,
			745.652,
			747.472,
			749.3,
			750.219,
			752.063,
			753.924,
			754.858,
			756.732,
			757.674,
			759.572,
			761.479,
			764.362,
			766.3,
			769.228,
			770.209,
			771.194,
			774.169,
			777.172,
			779.189,
			782.217,
			783.236,
			784.253,
			787.3,
			789.313,
			792.284,
			794.225,
			796.13,
			797.068,
			798.917,
			799.83,
			801.629,
			803.392,
			804.26,
			805.969,
			807.64,
			810.079,
			810.873,
			811.658,
			813.2,
			814.719,
			815.462,
			817.651,
			819.085,
			820.504,
			822.612,
			824.007,
			826.09,
			826.78,
			827.469,
			829.524,
			830.898,
			832.953,
			834.319,
			835.688,
			836.374,
			837.738,
			838.418,
			839.776,
			841.147,
			841.831,
			843.195,
			844.557,
			846.638,
			847.335,
			848.031,
			849.422,
			850.827,
			851.538,
			852.957,
			853.666,
			855.084,
			856.504,
			858.625,
			860.038,
			862.172,
			862.882,
			863.59,
			866.442,
			867.171,
			869.346,
			870.792,
			872.302,
			873.057,
			874.561,
			876.062,
			876.835,
			878.375,
			879.143,
			880.676,
			882.21,
			884.499,
			885.26,
			886.021,
			888.278,
			889.025,
			889.772,
			892.018,
			893.523,
			895.021,
			897.277,
			898.797,
			901.079,
			901.843,
			902.61,
			904.916,
			905.686,
			906.458,
			908.808,
			910.386,
			911.966,
			912.763,
			914.371,
			915.978,
			916.783,
			918.401,
			919.214,
			920.84,
			922.468,
			924.843,
			926.421,
			928.746,
			929.494,
			930.242,
			932.497,
			933.998,
			936.244,
			937.753,
			939.265,
			940.023,
			941.545,
			942.309,
			943.848,
			946.18,
			946.963,
			949.337,
			950.946,
			952.569,
			953.385,
			955.024,
			956.672,
			957.5,
			959.981,
			961.64,
			963.312,
			965.844,
			967.565,
			970.189,
			971.072,
			971.959,
			974.623,
			976.43,
			979.166,
			980.997,
			982.838,
			983.763,
			985.62,
			986.552,
			988.425,
			990.31,
			991.256,
			993.158,
			995.071,
			996.995,
			999.902,
			1002.828,
			1003.809,
			1004.792,
			1007.757,
			1009.747,
			1011.749,
			1014.771,
			1016.8,
			1019.863,
			1020.889,
			1021.918,
			1025.021,
			1027.103,
			1030.24,
			1032.33,
			1034.425,
			1035.476,
			1037.587,
			1038.645,
			1040.77,
			1043.977,
			1046.128,
			1048.287,
			1051.544,
			1053.725,
			1057.005,
			1058.097,
			1059.186,
			1062.428,
			1064.558,
			1067.695,
			1069.739,
			1071.745,
			1074.687,
			1075.649,
			1076.602,
			1079.406,
			1081.231,
			1083.903,
			1085.64,
			1087.341,
			1088.176,
			1089.817,
			1091.422,
			1092.211,
			1093.761,
			1094.523,
			1096.021,
			1097.49,
			1099.654,
			1101.078,
			1103.21,
			1103.911,
			1104.611,
			1106.698,
			1108.082,
			1110.134,
			1111.492,
			1112.846,
			1113.527,
			1114.894,
			1115.575,
			1116.257,
			1118.977,
			1119.654,
			1121.675,
			1123.019,
			1124.352,
			1125.017,
			1126.343,
			1127.668,
			1128.331,
			1130.336,
			1131.672,
			1133.009,
			1135.039,
			1136.396,
			1138.44,
			1139.128,
			1139.817,
			1141.888,
			1143.273,
			1145.373,
			1146.776,
			1148.18,
			1148.887,
			1150.306,
			1151.015,
			1152.43,
			1153.844,
			1154.555,
			1155.265,
			1157.389,
			1159.518,
			1160.239,
			1160.959,
			1162.398,
			1163.838,
			1164.566,
			1166.03,
			1166.762,
			1168.226,
			1169.693,
			1171.896,
			1173.369,
			1175.597,
			1176.345,
			1177.094,
			1179.353,
			1180.894,
			1183.222,
			1184.788,
			1186.359,
			1187.148,
			1188.733,
			1189.529,
			1191.129,
			1192.736,
			1193.543,
			1195.171,
			1196.823,
			1199.316,
			1200.155,
			1201.002,
			1203.548,
			1204.4,
			1205.255,
			1207.828,
			1209.544,
			1211.254,
			1213.817,
			1215.53,
			1218.083,
			1218.934,
			1219.784,
			1222.333,
			1223.181,
			1224.029,
			1226.573,
			1228.267,
			1229.957,
			1230.801,
			1232.492,
			1234.174,
			1235.014,
			1236.685,
			1237.516,
			1239.173,
			1240.826,
			1243.282,
			1244.91,
			1247.339,
			1248.143,
			1248.946,
			1251.35,
			1252.928,
			1255.31,
			1256.912,
			1258.498,
			1259.294,
			1260.89,
			1261.691,
			1262.492,
			1264.885,
			1265.692,
			1266.502,
			1268.966,
			1270.609,
			1272.252,
			1273.072,
			1274.715,
			1276.371,
			1277.204,
			1278.876,
			1279.715,
			1281.403,
			1283.104,
			1285.704,
			1287.453,
			1290.143,
			1291.055,
			1291.97,
			1294.746,
			1296.611,
			1299.456,
			1301.365,
			1303.287,
			1304.253,
			1306.19,
			1307.16,
			1309.099,
			1311.027,
			1311.985,
			1313.879,
			1315.742,
			1318.469,
			1320.24,
			1321.975,
			1323.673,
			1324.508,
			1326.151,
			1326.959,
			1328.548,
			1330.1,
			1332.371,
			1333.844,
			1335.998,
			1336.705,
			1337.407,
			1339.496,
			1340.892,
			1342.964,
			1344.341,
			1345.716,
			1346.398,
			1347.753,
			1348.427,
			1349.769,
			1351.094,
			1351.741,
			1353.027,
			1354.306,
			1356.205,
			1356.823,
			1357.438,
			1359.281,
			1359.894,
			1360.507,
			1362.46,
			1363.778,
			1365.096,
			1367.145,
			1368.544,
			1370.647,
			1371.384,
			1372.15,
			1374.447,
			1375.986,
			1378.483,
			1380.159,
			1381.77,
			1382.49,
			1383.924,
			1385.362,
			1386.084,
			1387.468,
			1388.143,
			1389.494,
			1390.846,
			1392.943,
			1394.371,
			1396.516,
			1397.238,
			1397.963,
			1400.134,
			1401.572,
			1403.736,
			1405.179,
			1406.585,
			1408.693,
			1409.389,
			1410.755,
			1412.088,
			1412.749,
			1413.41,
			1415.359,
			1416.641,
			1417.915,
			1418.547,
			1419.805,
			1421.059,
			1421.686,
			1422.941,
			1423.57,
			1424.82,
			1426.061,
			1427.913,
			1429.168,
			1431.029,
			1431.646,
			1432.26,
			1434.142,
			1435.396,
			1437.259,
			1438.509,
			1439.784,
			1440.416,
			1441.673,
			1442.298,
			1443.548,
			1445.412,
			1446.638,
			1447.859,
			1449.629,
			1450.78,
			1452.496,
			1453.068,
			1453.638,
			1454.761,
			1455.319,
			1456.432,
			1457.545,
			1459.23,
			1460.379,
			1462.101,
			1462.676,
			1463.253,
			1465.049,
			1466.27,
			1468.107,
			1469.357,
			1470.64,
			1471.283,
			1472.574,
			1473.223,
			1474.537,
			1475.875,
			1476.547,
			1477.899,
			1479.266,
			1481.404,
			1482.122,
			1482.844,
			1485.037,
			1485.778,
			1486.522,
			1488.777,
			1490.265,
			1492.496,
			1494,
			1495.456,
			1497.62,
			1498.35,
			1499.084,
			1501.222,
			1502.645,
			1504.806,
			1506.24,
			1508.42,
			1509.892,
			1510.63,
			1512.117,
			1513.62,
			1514.377,
			1515.903,
			1517.446,
			1519.775,
			1521.351,
			1523.739,
			1524.543,
			1525.35,
			1527.791,
			1529.437,
			1531.098,
			1533.607,
			1535.281,
			1536.123,
			1537.823,
			1538.678,
			1539.536,
			1543.009,
			1543.886,
			1546.541,
			1548.332,
			1550.135,
			1551.042,
			1552.866,
			1554.704,
			1555.627,
			1557.483,
			1558.416,
			1560.29,
			1562.176,
			1565.022,
			1566.935,
			1569.823,
			1570.792,
			1571.764,
			1574.698,
			1576.669,
			1579.65,
			1581.652,
			1583.666,
			1584.678,
			1586.712,
			1587.733,
			1589.785,
			1591.849,
			1592.885,
			1593.924,
			1597.06,
			1599.159,
			1602.305,
			1604.411,
			1606.526,
			1607.587,
			1609.717,
			1610.785,
			1612.929,
			1615.082,
			1618.328,
			1620.505,
			1623.788,
			1624.887,
			1625.989,
			1629.308,
			1631.533,
			1634.888,
			1637.138,
			1639.397,
			1640.531,
			1642.805,
			1643.945,
			1646.234,
			1649.686,
			1651.999,
			1654.321,
			1657.824,
			1658.996,
			1660.171,
			1663.709,
			1664.892,
			1666.078,
			1668.457,
			1669.65,
			1672.042,
			1674.443,
			1678.061,
			1680.484,
			1684.132,
			1685.35,
			1686.568,
			1690.208,
			1692.62,
			1696.203,
			1698.56,
			1700.883,
			1702.03,
			1704.296,
			1705.415,
			1707.625,
			1709.798,
			1710.871,
			1712.988,
			1715.067,
			1718.117,
			1720.104,
			1723.017,
			1723.97,
			1724.913,
			1727.71,
			1729.529,
			1732.222,
			1733.996,
			1735.757,
			1736.632,
			1738.372,
			1739.241,
			1740.109,
			1742.692,
			1743.547,
			1744.401,
			1746.961,
			1748.663,
			1751.214,
			1752.925,
			1754.636,
			1755.491,
			1757.213,
			1758.081,
			1759.817,
			1761.551,
			1764.18,
			1765.931,
			1768.585,
			1769.473,
			1770.361,
			1773.054,
			1774.88,
			1777.619,
			1779.464,
			1781.307,
			1782.228,
			1784.072,
			1784.993,
			1786.836,
			1788.691,
			1789.621,
			1791.479,
			1793.348,
			1795.237,
			1798.07,
			1799.958,
			1801.844,
			1802.787,
			1804.67,
			1805.611,
			1807.49,
			1809.379,
			1812.217,
			1814.105,
			1816.923,
			1817.861,
			1818.79,
			1821.501,
			1823.304,
			1825.944,
			1827.694,
			1829.436,
			1830.3,
			1832.028,
			1832.892,
			1834.612,
			1836.318,
			1837.172,
			1838.881,
			1840.588,
			1843.152,
			1844.01,
			1844.876,
			1847.499,
			1848.38,
			1849.264,
			1851.96,
			1853.776,
			1856.533,
			1858.391,
			1860.261,
			1863.089,
			1864.037,
			1864.989,
			1867.843,
			1869.761,
			1872.649,
			1874.586,
			1876.523,
			1877.507,
			1879.482,
			1880.474,
			1882.474,
			1885.496,
			1887.526,
			1889.567,
			1892.648,
			1894.714,
			1897.833,
			1898.876,
			1899.919,
			1903.058,
			1905.142,
			1908.282,
			1910.384,
			1912.495,
			1915.676,
			1916.741,
			1917.809,
			1922.106,
			1923.186,
			1926.433,
			1928.601,
			1930.772,
			1931.858,
			1934.038,
			1936.225,
			1937.321,
			1939.522,
			1940.626,
			1942.841,
			1945.065,
			1948.42,
			1950.672,
			1954.068,
			1955.206,
			1956.347,
			1959.784,
			1962.091,
			1965.57,
			1967.901,
			1970.244,
			1971.419,
			1973.773,
			1974.954,
			1977.322,
			1980.878,
			1983.249,
			1985.617,
			1987.979,
			1990.336,
			1991.514,
			1993.864,
			1996.214,
			1997.39,
			1999.735,
			2000.91,
			2003.257,
			2005.597,
			2009.088,
			2011.406,
			2014.869,
			2016.022,
			2017.176,
			2020.634,
			2022.951,
			2026.431,
			2028.756,
			2031.082,
			2032.244,
			2034.568,
			2035.729,
			2038.049,
			2040.365,
			2041.522,
			2043.822,
			2046.119,
			2049.561,
			2051.854,
			2054.149,
			2056.443,
			2057.589,
			2059.896,
			2061.049,
			2063.361,
			2065.69,
			2069.195,
			2071.544,
			2075.068,
			2076.242,
			2077.418,
			2080.936,
			2083.283,
			2086.794,
			2089.141,
			2091.497,
			2092.678,
			2095.047,
			2098.63,
			2101.032,
			2102.24,
			2104.655,
			2107.081,
			2110.723,
			2111.935,
			2113.146,
			2116.771,
			2117.973,
			2119.174,
			2122.764,
			2125.15,
			2128.73,
			2131.121,
			2133.517,
			2134.717,
			2137.124,
			2138.329,
			2139.536,
			2143.17,
			2144.384,
			2145.601,
			2149.262,
			2151.712,
			2154.173,
			2155.407,
			2157.882,
			2160.368,
			2161.615,
			2165.369,
			2167.882,
			2170.4,
			2174.191,
			2176.717,
			2180.494,
			2181.779,
			2183.046,
			2186.853,
			2189.398,
			2193.24,
			2195.792,
			2199.679,
			2202.281,
			2203.585,
			2204.888,
			2210.11,
			2211.42,
			2215.359,
			2217.994,
			2220.637,
			2221.961,
			2224.614,
			2227.273,
			2228.606,
			2231.276,
			2232.614,
			2235.295,
			2237.983,
			2242.027,
			2244.732,
			2248.804,
			2250.165,
			2251.527,
			2255.626,
			2258.367,
			2262.494,
			2265.254,
			2268.021,
			2269.408,
			2272.186,
			2273.578,
			2276.366,
			2279.162,
			2280.562,
			2283.367,
			2286.179,
			2290.409,
			2291.822,
			2293.236,
			2297.49,
			2298.912,
			2300.335,
			2303.186,
			2304.614,
			2307.476,
			2311.78,
			2314.657,
			2317.541,
			2321.88,
			2323.33,
			2324.781,
			2329.144,
			2332.061,
			2336.449,
			2339.383,
			2342.322,
			2343.794,
			2346.743,
			2349.698,
			2351.178,
			2354.144,
			2355.628,
			2358.603,
			2361.583,
			2366.065,
			2367.562,
			2369.06,
			2373.565,
			2375.069,
			2376.575,
			2381.102,
			2384.127,
			2388.677,
			2391.718,
			2394.764,
			2396.289,
			2399.343,
			2400.872,
			2402.403,
			2407.004,
			2410.078,
			2414.7,
			2417.787,
			2420.88,
			2422.428,
			2425.529,
			2428.635,
			2430.19,
			2433.304,
			2434.864,
			2437.985,
			2441.112,
			2445.812,
			2448.952,
			2453.671,
			2455.247,
			2456.823,
			2461.56,
			2464.725,
			2469.48,
			2472.656,
			2477.43,
			2480.615,
			2482.208,
			2485.396,
			2488.59,
			2490.188,
			2493.387,
			2496.594,
			2499.805,
			2503.017,
			2504.624,
			2507.836,
			2510.18
		],
		[
			0,
			0.024,
			0.039,
			0.101,
			0.131,
			0.164,
			0.226,
			0.288,
			0.349,
			0.412,
			0.458,
			0.535,
			0.568,
			0.596,
			0.676,
			0.722,
			0.783,
			0.844,
			0.892,
			0.906,
			0.97,
			1,
			1.031,
			1.094,
			1.107,
			1.154,
			1.217,
			1.278,
			1.326,
			1.402,
			1.434,
			1.465,
			1.526,
			1.59,
			1.651,
			1.713,
			1.759,
			1.838,
			1.868,
			1.899,
			1.961,
			1.978,
			2.023,
			2.085,
			2.148,
			2.193,
			2.21,
			2.271,
			2.303,
			2.333,
			2.395,
			2.412,
			2.458,
			2.518,
			2.581,
			2.628,
			2.707,
			2.735,
			2.767,
			2.828,
			2.891,
			2.955,
			3.016,
			3.077,
			3.139,
			3.171,
			3.201,
			3.28,
			3.325,
			3.386,
			3.45,
			3.511,
			3.574,
			3.61,
			3.635,
			3.696,
			3.714,
			3.76,
			3.823,
			3.884,
			3.929,
			4.007,
			4.039,
			4.071,
			4.133,
			4.192,
			4.257,
			4.317,
			4.365,
			4.38,
			4.441,
			4.473,
			4.504,
			4.565,
			4.581,
			4.627,
			4.69,
			4.753,
			4.798,
			4.814,
			4.877,
			4.907,
			4.938,
			5,
			5.014,
			5.061,
			5.123,
			5.184,
			5.232,
			5.31,
			5.342,
			5.373,
			5.451,
			5.497,
			5.558,
			5.619,
			5.683,
			5.745,
			5.774,
			5.806,
			5.883,
			5.931,
			5.992,
			6.055,
			6.101,
			6.178,
			6.208,
			6.24,
			6.303,
			6.364,
			6.427,
			6.489,
			6.533,
			6.612,
			6.644,
			6.674,
			6.736,
			6.799,
			6.86,
			6.923,
			6.968,
			6.982,
			7.045,
			7.077,
			7.106,
			7.185,
			7.231,
			7.293,
			7.356,
			7.404,
			7.479,
			7.511,
			7.542,
			7.605,
			7.667,
			7.728,
			7.79,
			7.835,
			7.853,
			7.914,
			7.945,
			7.976,
			8.038,
			8.052,
			8.101,
			8.161,
			8.223,
			8.27,
			8.284,
			8.348,
			8.38,
			8.409,
			8.473,
			8.487,
			8.534,
			8.596,
			8.659,
			8.703,
			8.783,
			8.814,
			8.843,
			8.92,
			8.969,
			9.031,
			9.093,
			9.138,
			9.155,
			9.217,
			9.248,
			9.279,
			9.34,
			9.356,
			9.402,
			9.465,
			9.525,
			9.573,
			9.588,
			9.651,
			9.681,
			9.712,
			9.775,
			9.794,
			9.836,
			9.898,
			9.959,
			10.007,
			10.083,
			10.116,
			10.145,
			10.208,
			10.271,
			10.334,
			10.396,
			10.456,
			10.519,
			10.548,
			10.581,
			10.659,
			10.705,
			10.766,
			10.828,
			10.874,
			10.89,
			10.952,
			10.984,
			11.015,
			11.092,
			11.14,
			11.2,
			11.261,
			11.308,
			11.388,
			11.417,
			11.447,
			11.511,
			11.527,
			11.574,
			11.636,
			11.698,
			11.744,
			11.758,
			11.823,
			11.856,
			11.881,
			11.945,
			11.959,
			12.006,
			12.069,
			12.13,
			12.178,
			12.256,
			12.284,
			12.317,
			12.379,
			12.442,
			12.502,
			12.564,
			12.612,
			12.628,
			12.688,
			12.72,
			12.751,
			12.811,
			12.829,
			12.876,
			12.938,
			13,
			13.046,
			13.06,
			13.123,
			13.154,
			13.184,
			13.246,
			13.263,
			13.308,
			13.37,
			13.433,
			13.496,
			13.557,
			13.588,
			13.62,
			13.68,
			13.744,
			13.805,
			13.866,
			13.928,
			13.99,
			14.022,
			14.053,
			14.13,
			14.177,
			14.241,
			14.301,
			14.364,
			14.426,
			14.457,
			14.486,
			14.564,
			14.611,
			14.673,
			14.735,
			14.78,
			14.858,
			14.89,
			14.92,
			14.983,
			15.044,
			15.108,
			15.168,
			15.217,
			15.23,
			15.292,
			15.324,
			15.356,
			15.418,
			15.432,
			15.48,
			15.54,
			15.603,
			15.651,
			15.728,
			15.757,
			15.79,
			15.865,
			15.914,
			15.979,
			16.038,
			16.099,
			16.161,
			16.191,
			16.223,
			16.285,
			16.302,
			16.349,
			16.411,
			16.472,
			16.518,
			16.532,
			16.594,
			16.626,
			16.658,
			16.718,
			16.735,
			16.78,
			16.845,
			16.907,
			16.953,
			17.031,
			17.06,
			17.091,
			17.152,
			17.232,
			17.292,
			17.355,
			17.387,
			17.416,
			17.478,
			17.541,
			17.604,
			17.664,
			17.711,
			17.728,
			17.789,
			17.822,
			17.851,
			17.914,
			17.928,
			17.976,
			18.042,
			18.1,
			18.146,
			18.16,
			18.224,
			18.254,
			18.285,
			18.362,
			18.41,
			18.473,
			18.534,
			18.594,
			18.657,
			18.688,
			18.718,
			18.782,
			18.843,
			18.906,
			18.968,
			19.013,
			19.031,
			19.092,
			19.122,
			19.155,
			19.216,
			19.23,
			19.277,
			19.341,
			19.402,
			19.448,
			19.463,
			19.525,
			19.558,
			19.586,
			19.649,
			19.664,
			19.712,
			19.774,
			19.836,
			19.881,
			19.959,
			19.992,
			20.021,
			20.103,
			20.144,
			20.213,
			20.271,
			20.315,
			20.333,
			20.393,
			20.426,
			20.456,
			20.517,
			20.533,
			20.581,
			20.641,
			20.704,
			20.75,
			20.764,
			20.827,
			20.858,
			20.889,
			20.951,
			21.014,
			21.075,
			21.138,
			21.185,
			21.261,
			21.293,
			21.325,
			21.386,
			21.402,
			21.447,
			21.511,
			21.573,
			21.618,
			21.634,
			21.697,
			21.727,
			21.757,
			21.82,
			21.835,
			21.881,
			21.944,
			22.007,
			22.053,
			22.131,
			22.165,
			22.193,
			22.254,
			22.317,
			22.379,
			22.441,
			22.485,
			22.503,
			22.564,
			22.594,
			22.626,
			22.702,
			22.75,
			22.812,
			22.873,
			22.921,
			22.935,
			22.999,
			23.028,
			23.06,
			23.136,
			23.184,
			23.246,
			23.309,
			23.355,
			23.433,
			23.463,
			23.495,
			23.556,
			23.617,
			23.681,
			23.741,
			23.789,
			23.804,
			23.867,
			23.897,
			23.927,
			23.99,
			24.006,
			24.052,
			24.113,
			24.174,
			24.227,
			24.301,
			24.33,
			24.362,
			24.439,
			24.486,
			24.547,
			24.611,
			24.656,
			24.734,
			24.765,
			24.795,
			24.858,
			24.92,
			24.982,
			25.043,
			25.09,
			25.106,
			25.169,
			25.199,
			25.231,
			25.308,
			25.353,
			25.416,
			25.477,
			25.526,
			25.602,
			25.632,
			25.664,
			25.727,
			25.788,
			25.851,
			25.912,
			25.959,
			26.036,
			26.067,
			26.097,
			26.159,
			26.223,
			26.289,
			26.347,
			26.393,
			26.408,
			26.468,
			26.501,
			26.533,
			26.595,
			26.611,
			26.657,
			26.717,
			26.779,
			26.843,
			26.902,
			26.934,
			26.965,
			27.029,
			27.091,
			27.153,
			27.213,
			27.259,
			27.277,
			27.339,
			27.371,
			27.399,
			27.478,
			27.524,
			27.586,
			27.649,
			27.694,
			27.711,
			27.773,
			27.803,
			27.836,
			27.912,
			27.959,
			28.021,
			28.081,
			28.128,
			28.207,
			28.236,
			28.27,
			28.33,
			28.392,
			28.458,
			28.516,
			28.564,
			28.58,
			28.642,
			28.671,
			28.701,
			28.764,
			28.78,
			28.825,
			28.89,
			28.951,
			28.998,
			29.014,
			29.075,
			29.106,
			29.135,
			29.197,
			29.213,
			29.26,
			29.324,
			29.385,
			29.431,
			29.508,
			29.541,
			29.571,
			29.633,
			29.695,
			29.757,
			29.82,
			29.864,
			29.882,
			29.942,
			29.974,
			30.004,
			30.066,
			30.082,
			30.128,
			30.192,
			30.252,
			30.298,
			30.314,
			30.378,
			30.411,
			30.44,
			30.501,
			30.563,
			30.623,
			30.686,
			30.733,
			30.811,
			30.84,
			30.873,
			30.934,
			30.951,
			30.998,
			31.059,
			31.122,
			31.169,
			31.182,
			31.245,
			31.277,
			31.308,
			31.369,
			31.385,
			31.431,
			31.495,
			31.553,
			31.617,
			31.68,
			31.711,
			31.741,
			31.817,
			31.864,
			31.928,
			31.99,
			32.034,
			32.052,
			32.112,
			32.145,
			32.175,
			32.236,
			32.253,
			32.298,
			32.36,
			32.424,
			32.473,
			32.484,
			32.548,
			32.579,
			32.608,
			32.673,
			32.686,
			32.735,
			32.797,
			32.857,
			32.905,
			32.98,
			33.012,
			33.043,
			33.107,
			33.167,
			33.229,
			33.293,
			33.338,
			33.352,
			33.414,
			33.447,
			33.477,
			33.539,
			33.556,
			33.601,
			33.665,
			33.726,
			33.786,
			33.85,
			33.881,
			33.912,
			33.973,
			33.99,
			34.034,
			34.098,
			34.161,
			34.207,
			34.284,
			34.314,
			34.345,
			34.406,
			34.471,
			34.534,
			34.594,
			34.64,
			34.656,
			34.718,
			34.749,
			34.779,
			34.841,
			34.858,
			34.902,
			34.964,
			35.027,
			35.075,
			35.09,
			35.151,
			35.182,
			35.214,
			35.276,
			35.339,
			35.4,
			35.462,
			35.508,
			35.584,
			35.616,
			35.648,
			35.71,
			35.771,
			35.835,
			35.896,
			35.942,
			35.957,
			36.019,
			36.051,
			36.083,
			36.144,
			36.158,
			36.207,
			36.268,
			36.331,
			36.392,
			36.454,
			36.484,
			36.515,
			36.579,
			36.639,
			36.702,
			36.763,
			36.811,
			36.887,
			36.918,
			36.951,
			37.011,
			37.027,
			37.075,
			37.136,
			37.198,
			37.244,
			37.261,
			37.323,
			37.351,
			37.385,
			37.445,
			37.462,
			37.508,
			37.575,
			37.633,
			37.679,
			37.756,
			37.785,
			37.817,
			37.88,
			37.942,
			38.005,
			38.065,
			38.113,
			38.128,
			38.19,
			38.22,
			38.251,
			38.33,
			38.376,
			38.439,
			38.501,
			38.562,
			38.626,
			38.661,
			38.684,
			38.749,
			38.762,
			38.811,
			38.874,
			38.935,
			38.997,
			39.059,
			39.091,
			39.119,
			39.181,
			39.244,
			39.307,
			39.37,
			39.416,
			39.43,
			39.492,
			39.524,
			39.553,
			39.615,
			39.633,
			39.677,
			39.74,
			39.803,
			39.849,
			39.864,
			39.928,
			39.956,
			39.989,
			40.052,
			40.112,
			40.176,
			40.235,
			40.297,
			40.36,
			40.391,
			40.423,
			40.5,
			40.547,
			40.609,
			40.67,
			40.733,
			40.794,
			40.824,
			40.856,
			40.92,
			40.934,
			40.979,
			41.042,
			41.106,
			41.165,
			41.229,
			41.258,
			41.289,
			41.352,
			41.415,
			41.475,
			41.539,
			41.583,
			41.601,
			41.661,
			41.692,
			41.725,
			41.803,
			41.848,
			41.909,
			41.973,
			42.018,
			42.035,
			42.097,
			42.127,
			42.159,
			42.22,
			42.234,
			42.283,
			42.346,
			42.408,
			42.454,
			42.53,
			42.561,
			42.591,
			42.653,
			42.717,
			42.783,
			42.84,
			42.885,
			42.903,
			42.965,
			42.995,
			43.026,
			43.09,
			43.103,
			43.15,
			43.212,
			43.274,
			43.336,
			43.4,
			43.431,
			43.461,
			43.523,
			43.539,
			43.586,
			43.647,
			43.707,
			43.756,
			43.833,
			43.864,
			43.894,
			43.958,
			44.019,
			44.08,
			44.143,
			44.19,
			44.205,
			44.268,
			44.298,
			44.329,
			44.408,
			44.451,
			44.516,
			44.577,
			44.624,
			44.639,
			44.701,
			44.731,
			44.764,
			44.825,
			44.846,
			44.888,
			44.95,
			45.012,
			45.059,
			45.134,
			45.165,
			45.196,
			45.274,
			45.321,
			45.383,
			45.445,
			45.491,
			45.508,
			45.568,
			45.6,
			45.632,
			45.694,
			45.71,
			45.753,
			45.817,
			45.879,
			45.927,
			46.003,
			46.035,
			46.064,
			46.127,
			46.19,
			46.25,
			46.312,
			46.359,
			46.376,
			46.437,
			46.469,
			46.5,
			46.56,
			46.576,
			46.623,
			46.685,
			46.748,
			46.808,
			46.873,
			46.908,
			46.933,
			46.995,
			47.009,
			47.057,
			47.118,
			47.181,
			47.228,
			47.306,
			47.338,
			47.367,
			47.431,
			47.492,
			47.553,
			47.615,
			47.663,
			47.678,
			47.741,
			47.771,
			47.802,
			47.862,
			47.878,
			47.926,
			47.987,
			48.05,
			48.113,
			48.173,
			48.203,
			48.234,
			48.297,
			48.314,
			48.359,
			48.42,
			48.483,
			48.531,
			48.607,
			48.639,
			48.67,
			48.748,
			48.795,
			48.856,
			48.917,
			48.968,
			48.979,
			49.04,
			49.073,
			49.102,
			49.167,
			49.181,
			49.227,
			49.29,
			49.353,
			49.397,
			49.413,
			49.475,
			49.507,
			49.539,
			49.6,
			49.66,
			49.725,
			49.785,
			49.831,
			49.909,
			49.94,
			49.972,
			50.035,
			50.095,
			50.16,
			50.218,
			50.266,
			50.28,
			50.344,
			50.375,
			50.405,
			50.484,
			50.53,
			50.592,
			50.653,
			50.701,
			50.777,
			50.809,
			50.84,
			50.918,
			50.963,
			51.031,
			51.087,
			51.135,
			51.211,
			51.242,
			51.274,
			51.352,
			51.399,
			51.464,
			51.523,
			51.567,
			51.583,
			51.645,
			51.678,
			51.709,
			51.771,
			51.784,
			51.832,
			51.894,
			51.957,
			52.003,
			52.081,
			52.111,
			52.142,
			52.203,
			52.266,
			52.328,
			52.391,
			52.435,
			52.453,
			52.513,
			52.545,
			52.577,
			52.654,
			52.701,
			52.764,
			52.825,
			52.872,
			52.888,
			52.947,
			52.98,
			53.011,
			53.072,
			53.092,
			53.135,
			53.198,
			53.259,
			53.306,
			53.381,
			53.414,
			53.443,
			53.507,
			53.569,
			53.631,
			53.692,
			53.739,
			53.755,
			53.816,
			53.846,
			53.878,
			53.94,
			53.957,
			54.002,
			54.064,
			54.127,
			54.189,
			54.249,
			54.281,
			54.311,
			54.373,
			54.39,
			54.435,
			54.498,
			54.561,
			54.623,
			54.684,
			54.715,
			54.747,
			54.809,
			54.872,
			54.934,
			54.994,
			55.042,
			55.056,
			55.119,
			55.182,
			55.241,
			55.258,
			55.305,
			55.365,
			55.429,
			55.475,
			55.492,
			55.551,
			55.582,
			55.614,
			55.678,
			55.74,
			55.805,
			55.863,
			55.909,
			55.926,
			55.985,
			56.017,
			56.048,
			56.11,
			56.127,
			56.173,
			56.234,
			56.297,
			56.342,
			56.359,
			56.421,
			56.453,
			56.482,
			56.559,
			56.608,
			56.669,
			56.731,
			56.794,
			56.856,
			56.884,
			56.917,
			56.98,
			57.042,
			57.103,
			57.165,
			57.228,
			57.289,
			57.321,
			57.35,
			57.429,
			57.474,
			57.536,
			57.598,
			57.646,
			57.662,
			57.723,
			57.754,
			57.786,
			57.847,
			57.861,
			57.91,
			57.973,
			58.034,
			58.079,
			58.157,
			58.188,
			58.218,
			58.281,
			58.344,
			58.405,
			58.466,
			58.515,
			58.529,
			58.592,
			58.622,
			58.652,
			58.715,
			58.731,
			58.777,
			58.84,
			58.903,
			58.948,
			58.963,
			59.027,
			59.056,
			59.086,
			59.149,
			59.166,
			59.212,
			59.278,
			59.334,
			59.381,
			59.458,
			59.49,
			59.52,
			59.582,
			59.646,
			59.707,
			59.77,
			59.815,
			59.832,
			59.893,
			59.926,
			59.957,
			60.017,
			60.034,
			60.079,
			60.142,
			60.203,
			60.25,
			60.267,
			60.326,
			60.359,
			60.391,
			60.453,
			60.514,
			60.576,
			60.639,
			60.684,
			60.701,
			60.762,
			60.794,
			60.824,
			60.884,
			60.948,
			61.009,
			61.073,
			61.119,
			61.134,
			61.196,
			61.227,
			61.258,
			61.321,
			61.34,
			61.382,
			61.444,
			61.506,
			61.553,
			61.631,
			61.661,
			61.692,
			61.755,
			61.815,
			61.879,
			61.941,
			62.003,
			62.065,
			62.094,
			62.126,
			62.188,
			62.202,
			62.251,
			62.312,
			62.374,
			62.42,
			62.437,
			62.498,
			62.521
		]
	],
	'1677_1678_7982': [
		[
			101.241
		],
		[
			0
		],
		[
			0,
			0.981,
			2.581,
			4.182,
			8.99,
			10.595,
			12.2,
			17.024,
			20.244,
			23.47,
			25.084,
			28.316,
			31.552,
			33.172,
			36.413,
			38.036,
			41.284,
			44.536,
			49.422,
			51.053,
			52.685,
			57.587,
			59.223,
			60.86,
			65.776,
			69.06,
			73.992,
			77.285,
			80.583,
			82.233,
			85.536,
			87.189,
			88.844,
			93.812,
			95.471,
			97.13,
			102.113,
			105.441,
			108.772,
			110.439,
			113.777,
			118.791,
			122.138,
			123.814,
			127.167,
			130.524,
			135.567,
			138.934,
			143.991,
			145.68,
			147.369,
			152.442,
			155.829,
			160.916,
			164.312,
			167.713,
			169.414,
			172.821,
			174.525,
			177.938,
			181.355,
			183.064,
			186.487,
			189.913,
			193.343,
			198.496,
			201.936,
			205.377,
			207.098,
			210.532,
			212.243,
			215.647,
			219.022,
			224.016,
			227.296,
			232.143,
			233.739,
			235.325,
			240.027,
			243.113,
			247.671,
			250.665,
			255.088,
			257.988,
			259.422,
			262.259,
			266.44,
			269.178,
			271.88,
			275.864,
			277.173,
			278.474,
			282.32,
			283.583,
			284.836,
			288.542,
			290.965,
			294.532,
			296.868,
			299.168,
			302.547,
			303.655,
			304.755,
			308.002,
			309.066,
			310.122,
			313.235,
			315.266,
			317.261,
			318.245,
			320.204,
			322.111,
			323.051,
			325.821,
			327.625,
			329.392,
			331.985,
			333.682,
			336.18,
			337.001,
			337.823,
			340.275,
			341.892,
			344.311,
			345.917,
			347.52,
			348.323,
			349.932,
			350.735,
			351.536,
			353.946,
			354.755,
			355.563,
			357.982,
			359.605,
			361.235,
			362.05,
			363.68,
			365.325,
			366.147,
			367.789,
			368.611,
			370.264,
			371.915,
			374.393,
			376.06,
			378.55,
			379.379,
			380.212,
			382.723,
			384.386,
			386.889,
			388.548,
			390.199,
			391.028,
			392.691,
			393.517,
			395.162,
			396.816,
			397.65,
			399.307,
			400.958,
			402.622,
			404.278,
			405.104,
			406.754,
			408.423,
			409.255,
			410.913,
			411.741,
			413.413,
			415.082,
			417.58,
			419.266,
			421.78,
			422.615,
			423.446,
			425.922,
			427.564,
			429.996,
			431.604,
			433.209,
			434.007,
			435.595,
			436.387,
			437.968,
			439.545,
			440.318,
			441.902,
			443.478,
			445.854,
			447.443,
			449.827,
			450.607,
			451.39,
			452.96,
			453.749,
			455.31,
			457.626,
			459.183,
			460.756,
			463.137,
			463.936,
			464.738,
			467.23,
			468.917,
			471.478,
			473.218,
			475.85,
			477.621,
			479.402,
			480.297,
			482.098,
			483.006,
			484.83,
			486.666,
			489.449,
			491.322,
			494.137,
			495.087,
			496.039,
			498.911,
			500.831,
			503.734,
			505.677,
			507.632,
			508.615,
			510.603,
			511.604,
			513.615,
			515.644,
			516.666,
			517.691,
			520.781,
			522.856,
			524.942,
			525.989,
			528.092,
			530.2,
			531.253,
			534.42,
			536.544,
			538.678,
			541.896,
			544.053,
			547.305,
			548.393,
			549.484,
			552.771,
			554.974,
			558.298,
			560.528,
			562.769,
			563.893,
			566.152,
			567.288,
			569.566,
			571.853,
			572.999,
			575.299,
			577.607,
			581.088,
			582.253,
			583.421,
			585.784,
			588.139,
			589.32,
			592.881,
			595.274,
			597.677,
			601.309,
			603.743,
			607.413,
			608.641,
			609.872,
			613.578,
			616.058,
			619.79,
			622.293,
			624.783,
			626.042,
			628.568,
			629.834,
			632.372,
			634.915,
			636.185,
			638.714,
			641.223,
			644.91,
			646.119,
			647.317,
			650.837,
			651.991,
			653.134,
			656.501,
			658.697,
			660.854,
			661.918,
			664.018,
			666.08,
			669.105,
			670.096,
			671.078,
			673.988,
			675.88,
			678.665,
			680.492,
			682.297,
			683.193,
			684.979,
			686.756,
			687.641,
			690.282,
			692.045,
			693.825,
			696.482,
			697.385,
			698.289,
			700.999,
			701.909,
			702.831,
			705.591,
			707.442,
			710.234,
			712.094,
			713.936,
			714.856,
			716.698,
			717.619,
			718.521,
			721.207,
			722.101,
			722.997,
			725.68,
			727.469,
			729.262,
			730.163,
			731.962,
			733.762,
			734.662,
			736.464,
			737.365,
			739.168,
			740.957,
			743.629,
			745.416,
			748.091,
			748.984,
			749.88,
			752.58,
			754.39,
			757.123,
			758.959,
			761.728,
			763.589,
			764.523,
			766.4,
			768.291,
			769.241,
			770.179,
			773.058,
			775.964,
			777.917,
			779.882,
			781.859,
			782.852,
			784.846,
			785.844,
			787.836,
			789.811,
			792.72,
			794.614,
			797.387,
			798.293,
			799.19,
			801.825,
			803.535,
			806.036,
			807.657,
			809.245,
			810.026,
			811.561,
			813.072,
			813.809,
			815.267,
			815.984,
			817.399,
			818.793,
			820.864,
			821.548,
			822.23,
			824.261,
			824.935,
			825.61,
			827.621,
			828.953,
			830.283,
			832.33,
			833.688,
			835.735,
			836.431,
			837.126,
			839.207,
			840.594,
			842.684,
			844.073,
			845.465,
			846.162,
			847.55,
			848.243,
			849.63,
			851.754,
			853.224,
			854.691,
			856.922,
			858.439,
			860.707,
			861.464,
			862.221,
			864.484,
			865.989,
			868.232,
			869.716,
			871.197,
			873.43,
			874.17,
			874.909,
			877.13,
			877.872,
			878.612,
			880.823,
			882.295,
			884.472,
			885.915,
			887.359,
			888.065,
			889.465,
			890.163,
			891.559,
			892.961,
			895.091,
			896.507,
			898.651,
			899.395,
			900.138,
			902.357,
			903.835,
			906.089,
			907.588,
			909.087,
			909.836,
			911.331,
			912.077,
			913.571,
			915.032,
			915.751,
			916.47,
			918.629,
			920.069,
			921.501,
			922.217,
			923.654,
			925.083,
			925.782,
			927.184,
			927.888,
			929.3,
			930.713,
			932.836,
			934.264,
			936.436,
			937.166,
			937.898,
			940.114,
			941.612,
			943.881,
			945.409,
			946.959,
			947.738,
			949.306,
			950.095,
			951.683,
			953.282,
			954.086,
			955.705,
			957.335,
			959.804,
			961.465,
			963.115,
			964.779,
			965.616,
			968.151,
			969.865,
			971.591,
			974.211,
			975.977,
			978.649,
			979.547,
			980.448,
			983.168,
			984.994,
			987.755,
			989.609,
			991.473,
			992.41,
			994.292,
			996.187,
			997.139,
			999.051,
			1000.012,
			1001.942,
			1003.884,
			1006.819,
			1008.79,
			1011.768,
			1012.767,
			1013.768,
			1016.789,
			1018.816,
			1020.855,
			1023.932,
			1025.996,
			1029.111,
			1030.155,
			1031.201,
			1034.341,
			1036.435,
			1039.592,
			1041.71,
			1043.841,
			1044.91,
			1047.057,
			1048.137,
			1050.304,
			1052.486,
			1053.585,
			1055.79,
			1058.008,
			1061.356,
			1063.593,
			1066.961,
			1068.085,
			1069.228,
			1072.617,
			1074.888,
			1078.314,
			1080.616,
			1082.929,
			1084.071,
			1086.404,
			1087.573,
			1088.746,
			1093.49,
			1094.677,
			1098.252,
			1100.629,
			1103.038,
			1104.246,
			1106.664,
			1109.089,
			1110.306,
			1113.973,
			1116.429,
			1118.898,
			1122.64,
			1125.137,
			1128.903,
			1130.164,
			1131.426,
			1135.195,
			1137.729,
			1141.544,
			1144.115,
			1146.675,
			1147.957,
			1150.538,
			1151.836,
			1154.416,
			1157.026,
			1158.335,
			1160.95,
			1163.565,
			1167.493,
			1168.801,
			1170.112,
			1172.741,
			1175.381,
			1176.704,
			1179.359,
			1180.691,
			1183.358,
			1186.035,
			1190.055,
			1192.744,
			1196.793,
			1198.146,
			1199.499,
			1203.572,
			1206.295,
			1210.39,
			1213.129,
			1215.875,
			1217.25,
			1220.007,
			1221.388,
			1224.155,
			1228.319,
			1231.104,
			1233.896,
			1238.088,
			1239.485,
			1240.883,
			1245.089,
			1246.477,
			1247.861,
			1251.999,
			1254.729,
			1257.484,
			1261.643,
			1264.406,
			1268.553,
			1269.937,
			1271.321,
			1275.479,
			1278.253,
			1282.431,
			1285.237,
			1288.048,
			1289.464,
			1292.304,
			1295.139,
			1296.555,
			1299.384,
			1300.797,
			1303.622,
			1306.443,
			1310.678,
			1313.518,
			1317.77,
			1319.186,
			1320.601,
			1324.823,
			1327.61,
			1331.781,
			1334.564,
			1338.743,
			1341.531,
			1342.928,
			1345.723,
			1349.918,
			1352.727,
			1355.55,
			1358.381,
			1361.219,
			1362.635,
			1365.462,
			1368.288,
			1369.698,
			1373.906,
			1376.726,
			1379.552,
			1383.803,
			1386.647,
			1390.915,
			1392.338,
			1393.762,
			1398.034,
			1400.886,
			1405.174,
			1408.039,
			1412.346,
			1415.225,
			1416.666,
			1419.553,
			1422.446,
			1423.895,
			1426.797,
			1429.704,
			1432.618,
			1435.56,
			1437.021,
			1439.942,
			1442.865,
			1444.326,
			1448.707,
			1451.62,
			1454.521,
			1458.844,
			1461.698,
			1465.903,
			1467.294,
			1468.677,
			1472.807,
			1475.522,
			1479.577,
			1482.269,
			1484.953,
			1486.297,
			1488.971,
			1490.297,
			1492.919,
			1495.52,
			1496.782,
			1499.343,
			1501.85,
			1505.625,
			1508.16,
			1511.913,
			1513.166,
			1514.417,
			1518.179,
			1520.69,
			1524.47,
			1526.998,
			1529.534,
			1533.359,
			1534.638,
			1535.922,
			1539.784,
			1542.372,
			1546.266,
			1548.87,
			1551.457,
			1552.762,
			1555.378,
			1556.688,
			1559.312,
			1561.947,
			1563.269,
			1565.917,
			1568.577,
			1572.581,
			1575.26,
			1579.29,
			1580.636,
			1581.984,
			1586.038,
			1588.749,
			1592.832,
			1595.562,
			1598.301,
			1599.673,
			1602.422,
			1603.798,
			1605.175,
			1609.297,
			1610.665,
			1612.027,
			1616.078,
			1618.764,
			1621.395,
			1622.698,
			1625.279,
			1627.827,
			1629.089,
			1632.825,
			1635.271,
			1637.676,
			1641.193,
			1643.503,
			1646.909,
			1648.047,
			1649.157,
			1652.439,
			1654.586,
			1657.74,
			1659.819,
			1661.886,
			1662.903,
			1664.917,
			1665.917,
			1667.909,
			1669.89,
			1670.877,
			1672.85,
			1674.82,
			1677.778,
			1679.755,
			1681.733,
			1683.725,
			1684.72,
			1687.707,
			1689.701,
			1691.691,
			1694.659,
			1696.632,
			1699.575,
			1700.552,
			1701.529,
			1704.461,
			1706.414,
			1709.346,
			1711.301,
			1713.252,
			1714.23,
			1716.186,
			1717.163,
			1719.12,
			1721.094,
			1722.08,
			1724.055,
			1726.064,
			1729.09,
			1730.109,
			1731.132,
			1734.203,
			1735.229,
			1736.256,
			1739.339,
			1741.391,
			1744.469,
			1746.496,
			1748.513,
			1751.543,
			1752.552,
			1753.562,
			1756.604,
			1758.654,
			1761.742,
			1763.817,
			1766.944,
			1769.051,
			1771.166,
			1772.228,
			1774.369,
			1775.443,
			1777.599,
			1779.766,
			1783.033,
			1785.212,
			1788.483,
			1789.565,
			1790.648,
			1793.901,
			1796.067,
			1799.321,
			1801.491,
			1803.664,
			1806.932,
			1808.023,
			1810.215,
			1813.517,
			1814.623,
			1817.966,
			1820.205,
			1822.456,
			1823.584,
			1825.842,
			1828.103,
			1829.235,
			1831.499,
			1832.63,
			1834.899,
			1837.161,
			1840.558,
			1842.823,
			1846.23,
			1847.368,
			1848.507,
			1851.936,
			1854.229,
			1857.684,
			1859.998,
			1862.321,
			1863.488,
			1865.827,
			1867.001,
			1869.36,
			1871.707,
			1872.895,
			1875.277,
			1877.667,
			1880.064,
			1882.468,
			1883.672,
			1886.085,
			1888.503,
			1889.715,
			1893.362,
			1895.803,
			1898.255,
			1901.95,
			1904.426,
			1908.155,
			1909.403,
			1910.653,
			1914.418,
			1916.938,
			1920.735,
			1923.276,
			1925.825,
			1927.103,
			1929.664,
			1930.949,
			1933.524,
			1937.402,
			1939.998,
			1942.603,
			1946.513,
			1947.822,
			1949.133,
			1953.113,
			1954.44,
			1955.768,
			1959.759,
			1962.448,
			1966.51,
			1969.228,
			1971.953,
			1976.046,
			1977.413,
			1978.772,
			1982.835,
			1984.181,
			1985.52,
			1989.497,
			1992.107,
			1994.679,
			1995.952,
			1998.468,
			2000.949,
			2002.175,
			2004.6,
			2005.799,
			2008.173,
			2010.511,
			2013.95,
			2016.194,
			2019.49,
			2020.57,
			2021.641,
			2024.796,
			2026.851,
			2029.879,
			2031.842,
			2033.768,
			2034.718,
			2036.596,
			2037.523,
			2038.444,
			2041.178,
			2042.081,
			2042.98,
			2045.666,
			2047.448,
			2049.223,
			2050.11,
			2051.885,
			2053.668,
			2054.56,
			2057.24,
			2059.044,
			2060.847,
			2063.559,
			2065.367,
			2068.093,
			2069.012,
			2069.93,
			2072.683,
			2074.6,
			2077.476,
			2079.406,
			2082.308,
			2084.227,
			2085.172,
			2087.061,
			2089.846,
			2091.665,
			2093.484,
			2095.3,
			2097.115,
			2098.022,
			2100.735,
			2101.636,
			2102.539,
			2104.346,
			2105.238,
			2107.025,
			2108.817,
			2111.5,
			2113.292,
			2115.997,
			2116.905,
			2117.814,
			2120.553,
			2122.394,
			2125.167,
			2127.025,
			2128.893,
			2129.828,
			2131.703,
			2132.651,
			2134.555,
			2136.467,
			2137.425,
			2139.34,
			2141.264,
			2144.151,
			2145.109,
			2146.071,
			2148.971,
			2149.942,
			2150.916,
			2153.853,
			2155.834,
			2157.826,
			2160.858,
			2162.906,
			2165.998,
			2167.03,
			2168.062,
			2171.162,
			2172.203,
			2173.247,
			2176.39,
			2178.499,
			2180.617,
			2181.68,
			2183.814,
			2185.958,
			2187.032,
			2190.255,
			2192.407,
			2194.554,
			2197.794,
			2199.969,
			2203.248,
			2204.351,
			2205.455,
			2208.762,
			2210.956,
			2214.22,
			2216.377,
			2218.505,
			2221.633,
			2222.657,
			2224.677,
			2227.627,
			2228.6,
			2231.465,
			2233.334,
			2235.167,
			2236.071,
			2237.857,
			2239.612,
			2240.478,
			2243.023,
			2244.677,
			2246.294,
			2248.66,
			2250.195,
			2252.436,
			2253.17,
			2253.898,
			2256.069,
			2257.493,
			2259.622,
			2261.036,
			2262.442,
			2263.144,
			2264.545,
			2265.245,
			2266.642,
			2268.037,
			2268.734,
			2270.134,
			2271.533,
			2272.931,
			2274.33,
			2275.032,
			2276.434,
			2277.834,
			2278.533,
			2279.935,
			2280.639,
			2282.043,
			2283.444,
			2285.57,
			2287.047,
			2289.255,
			2289.99,
			2290.76,
			2293.116,
			2294.678,
			2296.989,
			2298.511,
			2300.029,
			2300.788,
			2302.31,
			2303.068,
			2304.58,
			2306.092,
			2306.898,
			2308.501,
			2310.095,
			2312.47,
			2314.037,
			2315.597,
			2317.148,
			2317.899,
			2319.398,
			2320.146,
			2321.643,
			2323.096,
			2325.234,
			2326.655,
			2328.74,
			2329.425,
			2330.11,
			2332.165,
			2332.843,
			2333.515,
			2335.524,
			2336.861,
			2338.198,
			2338.867,
			2340.201,
			2340.868,
			2342.2,
			2343.535,
			2344.205,
			2345.547,
			2346.897,
			2348.935,
			2349.617,
			2350.301,
			2352.368,
			2353.063,
			2353.76,
			2355.867,
			2357.287,
			2358.722,
			2360.921,
			2362.404,
			2364.649,
			2365.402,
			2366.157,
			2369.217,
			2369.981,
			2372.293,
			2373.852,
			2375.438,
			2376.24,
			2377.854,
			2379.482,
			2380.302,
			2382.774,
			2384.429,
			2386.097,
			2388.626,
			2390.33,
			2392.925,
			2393.798,
			2394.674,
			2397.337,
			2399.139,
			2401.854,
			2403.697,
			2406.487,
			2408.362,
			2409.304,
			2411.199,
			2414.062,
			2415.022,
			2417.922,
			2420.846,
			2421.827,
			2422.812,
			2424.789,
			2426.777,
			2427.776,
			2429.783,
			2430.791,
			2432.815,
			2434.852,
			2437.931,
			2440,
			2443.125,
			2444.167,
			2445.207,
			2448.305,
			2449.326,
			2450.34,
			2453.327,
			2455.272,
			2457.179,
			2458.119,
			2459.97,
			2460.883,
			2462.682,
			2465.325,
			2467.034,
			2468.711,
			2471.184,
			2472.001,
			2472.815,
			2475.242,
			2476.049,
			2476.857,
			2478.476,
			2479.286,
			2480.904,
			2482.524,
			2484.953,
			2486.567,
			2488.985,
			2489.789,
			2490.592,
			2493.802,
			2494.603,
			2497.003,
			2498.611,
			2501.023,
			2502.633,
			2503.453,
			2505.089,
			2506.724,
			2507.541,
			2509.207,
			2510.87,
			2513.374,
			2515.062,
			2517.588,
			2518.443,
			2519.322,
			2521.954,
			2523.719,
			2526.423,
			2528.222,
			2530.058,
			2532.835,
			2533.76,
			2535.535,
			2537.288,
			2538.163,
			2539.037,
			2541.619,
			2543.326,
			2545.025,
			2545.864,
			2547.538,
			2549.209,
			2550.048,
			2551.732,
			2552.573,
			2554.251,
			2555.977,
			2558.585,
			2560.335,
			2563.043,
			2563.942,
			2564.84,
			2567.582,
			2569.409,
			2572.125,
			2573.917,
			2576.608,
			2578.407,
			2579.303,
			2581.091,
			2582.907,
			2583.813,
			2584.717,
			2587.406,
			2589.148,
			2591.755,
			2593.458,
			2595.132,
			2595.969,
			2598.489,
			2600.208,
			2601.93,
			2604.509,
			2606.224,
			2608.801,
			2609.639,
			2610.478,
			2612.995,
			2614.669,
			2617.065,
			2618.662,
			2620.257,
			2621.034,
			2622.579,
			2623.35,
			2624.893,
			2626.434,
			2627.204,
			2628.731,
			2630.279,
			2632.62,
			2633.407,
			2634.197,
			2635.802,
			2637.408,
			2638.216,
			2639.841,
			2640.659,
			2642.305,
			2643.965,
			2646.478,
			2648.169,
			2650.716,
			2651.576,
			2652.439,
			2655.044,
			2656.797,
			2659.448,
			2661.23,
			2663.929,
			2665.749,
			2666.664,
			2668.503,
			2670.362,
			2671.3,
			2673.184,
			2675.081,
			2677.961,
			2679.897,
			2682.82,
			2683.8,
			2684.783,
			2687.747,
			2689.731,
			2692.73,
			2694.735,
			2696.751,
			2699.797,
			2700.817,
			2701.84,
			2704.924,
			2705.956,
			2706.99,
			2710.11,
			2712.196,
			2715.327,
			2717.424,
			2719.531,
			2720.587,
			2723.77,
			2725.907,
			2728.054,
			2731.298,
			2733.475,
			2736.762,
			2737.864,
			2738.968,
			2742.291,
			2744.519,
			2747.877,
			2750.13,
			2752.394,
			2753.53,
			2755.811,
			2756.955,
			2759.251,
			2761.557,
			2762.714,
			2765.034,
			2767.365,
			2769.705,
			2772.052,
			2773.229,
			2775.589,
			2777.958,
			2779.146,
			2781.527,
			2782.721,
			2785.115,
			2787.519,
			2791.142,
			2793.571,
			2797.23,
			2798.454,
			2799.68,
			2803.373,
			2805.847,
			2809.574,
			2812.071,
			2814.576,
			2815.831,
			2818.35,
			2819.613,
			2822.145,
			2824.685,
			2825.958,
			2828.51,
			2831.071,
			2834.926,
			2836.215,
			2837.507,
			2841.39,
			2842.688,
			2843.988,
			2846.594,
			2847.896,
			2850.503,
			2853.115,
			2857.048,
			2859.679,
			2863.64,
			2864.964,
			2866.29,
			2870.278,
			2872.945,
			2876.959,
			2879.646,
			2883.687,
			2886.39,
			2887.744,
			2890.456,
			2893.174,
			2894.536,
			2897.263,
			2899.997,
			2904.11,
			2905.483,
			2906.859,
			2910.995,
			2912.377,
			2913.761,
			2917.921,
			2920.704,
			2924.888,
			2927.685,
			2930.489,
			2934.706,
			2936.115,
			2937.526,
			2941.77,
			2944.607,
			2948.874,
			2951.727,
			2954.587,
			2956.019,
			2958.892,
			2961.772,
			2963.216,
			2966.11,
			2967.559,
			2970.464,
			2973.375,
			2977.755,
			2980.683,
			2985.088,
			2986.56,
			2988.033,
			2992.466,
			2995.432,
			2999.906,
			3002.902,
			3007.407,
			3010.396,
			3011.885,
			3014.84,
			3017.762,
			3019.209,
			3020.644,
			3024.893,
			3027.674,
			3030.418,
			3031.775,
			3034.461,
			3037.109,
			3038.419,
			3041.011,
			3042.293,
			3044.829,
			3047.328,
			3051.008,
			3053.417,
			3056.967,
			3058.135,
			3059.295,
			3062.719,
			3064.959,
			3068.251,
			3070.401,
			3072.514,
			3073.556,
			3075.612,
			3076.627,
			3078.627,
			3081.558,
			3083.466,
			3085.335,
			3088.074,
			3088.97,
			3089.855,
			3091.599,
			3093.307,
			3094.147,
			3095.802,
			3096.616,
			3098.216,
			3100.558,
			3102.079,
			3103.562,
			3105.722,
			3106.425,
			3107.119,
			3109.151,
			3110.456,
			3112.336,
			3113.555,
			3114.753,
			3115.356,
			3116.528,
			3117.107,
			3118.258,
			3119.404,
			3119.975,
			3121.116,
			3122.257,
			3123.954,
			3125.083,
			3126.813,
			3127.418,
			3128.022,
			3129.826,
			3131.031,
			3132.284,
			3134.239,
			3135.542,
			3137.564,
			3138.29,
			3139.015,
			3141.189,
			3142.724,
			3145.224,
			3146.877,
			3149.048,
			3150.447,
			3151.839,
			3152.523,
			3153.72,
			3154.318,
			3155.515,
			3156.712,
			3158.495,
			3159.667,
			3161.421,
			3161.996,
			3162.581,
			3164.355,
			3165.564,
			3167.392,
			3168.631,
			3170.538,
			3171.828,
			3172.477,
			3173.13,
			3175.83,
			3176.52,
			3178.605,
			3180.023,
			3181.517,
			3182.265,
			3183.768,
			3185.287,
			3186.075,
			3187.651,
			3188.44,
			3190.023,
			3191.635,
			3194.048,
			3195.657,
			3198.091,
			3198.896,
			3199.698,
			3202.12,
			3203.723,
			3206.132,
			3207.791,
			3209.44,
			3210.263,
			3211.831,
			3212.595,
			3214.115,
			3215.623,
			3216.374,
			3217.808,
			3219.227,
			3220.636,
			3222.04,
			3222.765,
			3224.204,
			3225.63,
			3226.34,
			3227.79,
			3228.554,
			3230.073,
			3231.584,
			3233.804,
			3235.259,
			3237.43,
			3238.15,
			3238.855,
			3240.954,
			3242.348,
			3244.41,
			3245.749,
			3247.085,
			3247.753,
			3249.092,
			3249.762,
			3251.099,
			3252.437,
			3253.106,
			3254.451,
			3255.777,
			3257.775,
			3259.115,
			3261.112,
			3261.776,
			3262.443,
			3264.456,
			3265.807,
			3267.846,
			3269.219,
			3271.302,
			3272.706,
			3273.413,
			3274.123,
			3276.275,
			3277,
			3277.728,
			3279.936,
			3281.429,
			3282.937,
			3283.697,
			3285.228,
			3286.777,
			3287.557,
			3289.128,
			3289.92,
			3291.515,
			3293.127,
			3295.573,
			3296.382,
			3297.209,
			3299.724,
			3300.565,
			3301.406,
			3303.943,
			3305.658,
			3308.255,
			3310.005,
			3311.767,
			3312.653,
			3314.433,
			3315.328,
			3317.126,
			3318.937,
			3319.847,
			3320.759,
			3323.517,
			3325.375,
			3327.245,
			3328.185,
			3330.074,
			3331.977,
			3332.933,
			3335.819,
			3337.756,
			3339.705,
			3342.649,
			3344.626,
			3347.613,
			3348.614,
			3349.618,
			3352.648,
			3354.682,
			3357.754,
			3359.816,
			3362.929,
			3365.018,
			3366.062,
			3368.151,
			3370.246,
			3371.296,
			3373.406,
			3375.526,
			3378.724,
			3379.795,
			3380.868,
			3383.023,
			3385.188,
			3386.274,
			3389.547,
			3391.742,
			3393.946,
			3397.271,
			3399.5,
			3402.862,
			3403.988,
			3405.116,
			3408.515,
			3410.792,
			3414.228,
			3416.531,
			3418.843,
			3420.003,
			3422.331,
			3423.499,
			3425.841,
			3429.373,
			3431.74,
			3434.116,
			3437.696,
			3438.894,
			3440.094,
			3443.708,
			3444.918,
			3446.129,
			3449.778,
			3452.219,
			3455.872,
			3458.287,
			3460.678,
			3464.204,
			3465.36,
			3466.507,
			3469.889,
			3470.998,
			3472.096,
			3475.338,
			3477.452,
			3479.529,
			3480.554,
			3482.574,
			3484.558,
			3485.537,
			3487.466,
			3488.417,
			3490.293,
			3492.134,
			3494.842,
			3496.621,
			3499.281,
			3500.157,
			3501.031,
			3503.642,
			3505.376,
			3507.968,
			3509.694,
			3512.276,
			3513.995,
			3514.855,
			3515.719,
			3518.319,
			3519.185,
			3520.051,
			3522.667,
			3524.416,
			3526.163,
			3527.041,
			3528.807,
			3530.569,
			3531.449,
			3533.212,
			3534.091,
			3535.847,
			3537.603,
			3540.251,
			3542.012,
			3544.672,
			3545.559,
			3546.445,
			3549.115,
			3550.907,
			3553.596,
			3555.418,
			3558.143,
			3560.003,
			3560.935,
			3562.799,
			3564.683,
			3565.628,
			3567.52,
			3569.411,
			3572.244,
			3573.187,
			3574.13,
			3576.936,
			3577.87,
			3578.804,
			3581.622,
			3583.498,
			3585.361,
			3588.089,
			3589.9,
			3592.503,
			3593.359,
			3594.215,
			3596.76,
			3598.42,
			3600.908,
			3602.562,
			3604.216,
			3605.043,
			3606.7,
			3607.542,
			3609.227,
			3610.914,
			3611.759,
			3613.467,
			3615.188,
			3617.783,
			3618.653,
			3619.524,
			3622.152,
			3623.033,
			3623.916,
			3626.57,
			3628.365,
			3631.076,
			3632.914,
			3634.734,
			3637.512,
			3638.444,
			3639.379,
			3642.206,
			3644.105,
			3646.973,
			3648.899,
			3650.841,
			3651.816,
			3653.792,
			3655.762,
			3656.751,
			3658.739,
			3659.737,
			3661.743,
			3663.759,
			3666.79,
			3668.835,
			3671.92,
			3672.953,
			3674.006,
			3677.119,
			3679.193,
			3682.31,
			3684.381,
			3686.479,
			3689.645,
			3690.705,
			3692.854,
			3694.995,
			3696.069,
			3698.211,
			3700.38,
			3702.574,
			3704.755,
			3705.846,
			3708.031,
			3710.216,
			3711.308,
			3713.491,
			3714.582,
			3716.761,
			3718.938,
			3722.199,
			3724.372,
			3727.625,
			3728.707,
			3729.791,
			3733.035,
			3735.197,
			3738.44,
			3740.604,
			3742.773,
			3743.857,
			3746.03,
			3747.119,
			3749.299,
			3752.58,
			3753.677,
			3756.981,
			3760.318,
			3761.435,
			3762.554,
			3764.812,
			3767.078,
			3768.215,
			3770.494,
			3771.635,
			3773.92,
			3776.213,
			3779.652,
			3781.949,
			3785.391,
			3786.537,
			3787.681,
			3791.114,
			3793.4,
			3796.828,
			3799.117,
			3801.404,
			3802.547,
			3804.835,
			3807.119,
			3808.257,
			3810.533,
			3811.667,
			3813.93,
			3816.198,
			3819.595,
			3820.726,
			3821.855,
			3825.242,
			3826.367,
			3827.493,
			3830.869,
			3833.119,
			3836.5,
			3838.758,
			3841.02,
			3844.418,
			3845.553,
			3846.689,
			3850.106,
			3851.248,
			3852.39,
			3855.839,
			3858.145,
			3860.464,
			3861.625,
			3863.95,
			3865.115,
			3867.446,
			3869.78,
			3870.947,
			3873.28,
			3875.617,
			3879.13,
			3881.479,
			3885.017,
			3886.2,
			3887.385,
			3890.947,
			3893.329,
			3896.899,
			3899.285,
			3902.864,
			3905.255,
			3906.45,
			3907.645,
			3912.431,
			3913.627,
			3917.22,
			3919.62,
			3923.223,
			3925.628,
			3928.036,
			3929.239,
			3931.648,
			3932.855,
			3935.269,
			3937.69,
			3941.334,
			3943.774,
			3947.434,
			3948.657,
			3949.885,
			3953.571,
			3956.052,
			3959.785,
			3962.284,
			3964.785,
			3966.035,
			3968.541,
			3969.815,
			3972.377,
			3976.254,
			3978.856,
			3981.46,
			3985.365,
			3986.663,
			3987.962,
			3990.561,
			3993.156,
			3994.455,
			3997.062,
			3998.368,
			4000.989,
			4003.619,
			4007.581,
			4010.232,
			4014.22,
			4015.553,
			4016.888,
			4020.901,
			4023.584,
			4027.624,
			4030.326,
			4033.035,
			4034.392,
			4037.112,
			4038.474,
			4041.205,
			4043.942,
			4045.313,
			4048.061,
			4050.816,
			4054.962,
			4057.735,
			4061.907,
			4063.301,
			4064.697,
			4068.895,
			4071.702,
			4075.927,
			4078.751,
			4081.583,
			4085.843,
			4087.266,
			4088.691,
			4092.976,
			4095.841,
			4100.151,
			4103.032,
			4105.92,
			4107.367,
			4110.265,
			4111.716,
			4114.624,
			4118.993,
			4121.912,
			4124.837,
			4129.24,
			4130.71,
			4132.184,
			4136.612,
			4138.093,
			4139.574,
			4144.032,
			4147.013,
			4150.003,
			4154.498,
			4157.501,
			4162.018,
			4163.525,
			4165.034,
			4169.567,
			4171.082,
			4172.597,
			4177.152,
			4180.195,
			4183.244,
			4184.771,
			4187.828,
			4190.89,
			4192.423,
			4197.032,
			4200.111,
			4203.195,
			4207.832,
			4210.93,
			4215.587,
			4217.142,
			4218.698,
			4223.374,
			4226.499,
			4231.194,
			4234.331,
			4239.046,
			4242.197,
			4243.774,
			4246.932,
			4250.095,
			4251.679,
			4254.849,
			4258.022,
			4261.202,
			4264.386,
			4265.979,
			4269.164,
			4272.352,
			4273.948,
			4275.6
		],
		[
			0,
			0.017,
			0.049,
			0.08,
			0.141,
			0.158,
			0.204,
			0.265,
			0.327,
			0.374,
			0.388,
			0.452,
			0.483,
			0.514,
			0.576,
			0.591,
			0.637,
			0.7,
			0.76,
			0.805,
			0.824,
			0.884,
			0.915,
			0.946,
			1.01,
			1.071,
			1.133,
			1.196,
			1.242,
			1.258,
			1.317,
			1.349,
			1.38,
			1.442,
			1.458,
			1.503,
			1.566,
			1.628,
			1.676,
			1.692,
			1.754,
			1.814,
			1.875,
			1.893,
			1.938,
			2.001,
			2.063,
			2.126,
			2.188,
			2.219,
			2.248,
			2.31,
			2.372,
			2.435,
			2.498,
			2.543,
			2.559,
			2.621,
			2.653,
			2.682,
			2.746,
			2.759,
			2.807,
			2.869,
			2.93,
			2.994,
			3.055,
			3.087,
			3.116,
			3.179,
			3.193,
			3.241,
			3.304,
			3.366,
			3.428,
			3.489,
			3.521,
			3.552,
			3.613,
			3.675,
			3.739,
			3.799,
			3.86,
			3.924,
			3.954,
			3.986,
			4.062,
			4.108,
			4.171,
			4.232,
			4.279,
			4.294,
			4.358,
			4.389,
			4.42,
			4.481,
			4.544,
			4.606,
			4.668,
			4.713,
			4.792,
			4.821,
			4.852,
			4.915,
			4.931,
			4.979,
			5.041,
			5.101,
			5.148,
			5.162,
			5.226,
			5.258,
			5.288,
			5.366,
			5.413,
			5.474,
			5.536,
			5.583,
			5.661,
			5.692,
			5.722,
			5.784,
			5.845,
			5.912,
			5.969,
			6.016,
			6.031,
			6.094,
			6.124,
			6.155,
			6.218,
			6.234,
			6.28,
			6.341,
			6.402,
			6.449,
			6.465,
			6.526,
			6.559,
			6.591,
			6.652,
			6.666,
			6.713,
			6.778,
			6.837,
			6.885,
			6.961,
			6.993,
			7.025,
			7.086,
			7.148,
			7.211,
			7.273,
			7.319,
			7.334,
			7.396,
			7.428,
			7.458,
			7.519,
			7.536,
			7.583,
			7.645,
			7.705,
			7.751,
			7.769,
			7.829,
			7.86,
			7.893,
			7.954,
			7.975,
			8.016,
			8.079,
			8.139,
			8.202,
			8.263,
			8.296,
			8.327,
			8.387,
			8.45,
			8.514,
			8.575,
			8.622,
			8.636,
			8.697,
			8.73,
			8.761,
			8.822,
			8.837,
			8.884,
			8.946,
			9.008,
			9.071,
			9.133,
			9.163,
			9.193,
			9.255,
			9.272,
			9.319,
			9.383,
			9.442,
			9.489,
			9.567,
			9.596,
			9.627,
			9.705,
			9.753,
			9.815,
			9.876,
			9.938,
			10,
			10.038,
			10.063,
			10.122,
			10.141,
			10.185,
			10.25,
			10.311,
			10.358,
			10.435,
			10.466,
			10.495,
			10.559,
			10.62,
			10.684,
			10.745,
			10.791,
			10.808,
			10.868,
			10.899,
			10.931,
			10.991,
			11.009,
			11.054,
			11.118,
			11.177,
			11.225,
			11.24,
			11.303,
			11.333,
			11.363,
			11.442,
			11.488,
			11.55,
			11.613,
			11.66,
			11.737,
			11.769,
			11.8,
			11.877,
			11.922,
			11.986,
			12.047,
			12.099,
			12.109,
			12.171,
			12.201,
			12.233,
			12.295,
			12.311,
			12.357,
			12.419,
			12.482,
			12.527,
			12.543,
			12.604,
			12.634,
			12.666,
			12.745,
			12.791,
			12.851,
			12.915,
			12.976,
			13.038,
			13.068,
			13.101,
			13.177,
			13.225,
			13.286,
			13.348,
			13.396,
			13.41,
			13.471,
			13.504,
			13.534,
			13.597,
			13.613,
			13.658,
			13.721,
			13.783,
			13.83,
			13.846,
			13.908,
			13.938,
			13.969,
			14.032,
			14.094,
			14.154,
			14.16,
			14.217,
			14.279,
			14.341,
			14.371,
			14.401,
			14.466,
			14.526,
			14.587,
			14.652,
			14.697,
			14.713,
			14.776,
			14.806,
			14.837,
			14.914,
			14.962,
			15.024,
			15.086,
			15.13,
			15.147,
			15.209,
			15.241,
			15.271,
			15.348,
			15.395,
			15.458,
			15.517,
			15.566,
			15.582,
			15.643,
			15.675,
			15.703,
			15.767,
			15.782,
			15.828,
			15.891,
			15.951,
			15.998,
			16.016,
			16.076,
			16.108,
			16.139,
			16.2,
			16.22,
			16.262,
			16.325,
			16.386,
			16.449,
			16.512,
			16.543,
			16.574,
			16.636,
			16.696,
			16.759,
			16.822,
			16.883,
			16.945,
			16.975,
			17.008,
			17.07,
			17.086,
			17.13,
			17.192,
			17.256,
			17.316,
			17.378,
			17.409,
			17.441,
			17.502,
			17.518,
			17.565,
			17.626,
			17.688,
			17.737,
			17.814,
			17.844,
			17.876,
			17.937,
			18,
			18.061,
			18.124,
			18.171,
			18.184,
			18.247,
			18.282,
			18.308,
			18.371,
			18.387,
			18.434,
			18.496,
			18.558,
			18.602,
			18.618,
			18.681,
			18.713,
			18.743,
			18.82,
			18.868,
			18.929,
			18.991,
			19.037,
			19.116,
			19.146,
			19.177,
			19.255,
			19.3,
			19.363,
			19.424,
			19.472,
			19.487,
			19.548,
			19.58,
			19.612,
			19.689,
			19.734,
			19.798,
			19.859,
			19.907,
			19.983,
			20.015,
			20.044,
			20.108,
			20.17,
			20.231,
			20.293,
			20.355,
			20.416,
			20.447,
			20.48,
			20.542,
			20.556,
			20.603,
			20.666,
			20.728,
			20.79,
			20.852,
			20.881,
			20.912,
			20.976,
			20.988,
			21.037,
			21.101,
			21.162,
			21.208,
			21.285,
			21.316,
			21.346,
			21.41,
			21.47,
			21.534,
			21.595,
			21.642,
			21.658,
			21.718,
			21.754,
			21.782,
			21.843,
			21.859,
			21.905,
			21.966,
			22.03,
			22.075,
			22.092,
			22.155,
			22.183,
			22.215,
			22.277,
			22.294,
			22.34,
			22.401,
			22.465,
			22.525,
			22.588,
			22.62,
			22.651,
			22.71,
			22.772,
			22.837,
			22.896,
			22.944,
			22.961,
			23.02,
			23.051,
			23.085,
			23.146,
			23.161,
			23.209,
			23.27,
			23.332,
			23.393,
			23.454,
			23.486,
			23.519,
			23.581,
			23.643,
			23.705,
			23.767,
			23.826,
			23.891,
			23.922,
			23.951,
			24.013,
			24.076,
			24.136,
			24.2,
			24.246,
			24.262,
			24.325,
			24.356,
			24.386,
			24.447,
			24.466,
			24.511,
			24.572,
			24.634,
			24.696,
			24.759,
			24.788,
			24.818,
			24.882,
			24.944,
			25.005,
			25.067,
			25.115,
			25.192,
			25.221,
			25.253,
			25.316,
			25.378,
			25.442,
			25.5,
			25.549,
			25.565,
			25.625,
			25.655,
			25.689,
			25.749,
			25.766,
			25.812,
			25.875,
			25.936,
			25.982,
			26.059,
			26.091,
			26.12,
			26.185,
			26.245,
			26.309,
			26.371,
			26.416,
			26.432,
			26.493,
			26.529,
			26.555,
			26.633,
			26.679,
			26.743,
			26.805,
			26.852,
			26.866,
			26.929,
			26.959,
			26.991,
			27.068,
			27.113,
			27.176,
			27.236,
			27.284,
			27.363,
			27.394,
			27.425,
			27.502,
			27.547,
			27.609,
			27.672,
			27.719,
			27.735,
			27.797,
			27.827,
			27.859,
			27.919,
			27.935,
			27.983,
			28.044,
			28.106,
			28.152,
			28.168,
			28.229,
			28.263,
			28.292,
			28.354,
			28.37,
			28.417,
			28.479,
			28.542,
			28.593,
			28.666,
			28.695,
			28.728,
			28.804,
			28.851,
			28.913,
			28.975,
			29.02,
			29.037,
			29.1,
			29.128,
			29.161,
			29.238,
			29.285,
			29.351,
			29.41,
			29.454,
			29.47,
			29.533,
			29.564,
			29.596,
			29.658,
			29.72,
			29.78,
			29.843,
			29.888,
			29.967,
			29.999,
			30.028,
			30.091,
			30.152,
			30.215,
			30.275,
			30.325,
			30.338,
			30.402,
			30.432,
			30.463,
			30.525,
			30.54,
			30.587,
			30.65,
			30.712,
			30.772,
			30.833,
			30.867,
			30.897,
			30.958,
			31.02,
			31.085,
			31.145,
			31.208,
			31.268,
			31.3,
			31.331,
			31.408,
			31.456,
			31.517,
			31.58,
			31.625,
			31.641,
			31.702,
			31.733,
			31.765,
			31.842,
			31.89,
			31.949,
			32.014,
			32.076,
			32.136,
			32.168,
			32.198,
			32.261,
			32.325,
			32.386,
			32.448,
			32.51,
			32.571,
			32.602,
			32.633,
			32.696,
			32.715,
			32.759,
			32.819,
			32.88,
			32.929,
			32.944,
			33.004,
			33.037,
			33.066,
			33.13,
			33.192,
			33.252,
			33.314,
			33.36,
			33.441,
			33.471,
			33.501,
			33.563,
			33.625,
			33.687,
			33.748,
			33.796,
			33.81,
			33.875,
			33.904,
			33.934,
			33.996,
			34.014,
			34.06,
			34.122,
			34.183,
			34.231,
			34.308,
			34.337,
			34.369,
			34.432,
			34.493,
			34.556,
			34.617,
			34.663,
			34.741,
			34.777,
			34.804,
			34.867,
			34.927,
			34.99,
			35.05,
			35.097,
			35.113,
			35.176,
			35.205,
			35.238,
			35.299,
			35.316,
			35.363,
			35.425,
			35.485,
			35.531,
			35.609,
			35.641,
			35.673,
			35.734,
			35.796,
			35.859,
			35.92,
			35.966,
			35.983,
			36.045,
			36.075,
			36.104,
			36.168,
			36.185,
			36.229,
			36.293,
			36.353,
			36.401,
			36.416,
			36.479,
			36.509,
			36.541,
			36.617,
			36.663,
			36.727,
			36.789,
			36.838,
			36.912,
			36.944,
			36.975,
			37.037,
			37.098,
			37.159,
			37.223,
			37.269,
			37.284,
			37.345,
			37.376,
			37.409,
			37.47,
			37.486,
			37.533,
			37.594,
			37.656,
			37.717,
			37.78,
			37.811,
			37.842,
			37.919,
			37.966,
			38.028,
			38.088,
			38.135,
			38.214,
			38.245,
			38.276,
			38.354,
			38.399,
			38.462,
			38.524,
			38.57,
			38.585,
			38.647,
			38.679,
			38.709,
			38.771,
			38.788,
			38.834,
			38.899,
			38.958,
			39.005,
			39.021,
			39.082,
			39.111,
			39.145,
			39.222,
			39.267,
			39.331,
			39.391,
			39.44,
			39.516,
			39.546,
			39.578,
			39.641,
			39.703,
			39.765,
			39.825,
			39.888,
			39.95,
			39.982,
			40.012,
			40.074,
			40.088,
			40.137,
			40.198,
			40.26,
			40.322,
			40.385,
			40.415,
			40.445,
			40.508,
			40.571,
			40.632,
			40.695,
			40.741,
			40.819,
			40.849,
			40.881,
			40.963,
			41.003,
			41.071,
			41.128,
			41.176,
			41.192,
			41.252,
			41.284,
			41.313,
			41.377,
			41.393,
			41.438,
			41.501,
			41.562,
			41.607,
			41.687,
			41.718,
			41.748,
			41.826,
			41.873,
			41.933,
			41.996,
			42.044,
			42.058,
			42.121,
			42.151,
			42.182,
			42.246,
			42.26,
			42.306,
			42.367,
			42.429,
			42.478,
			42.492,
			42.554,
			42.587,
			42.617,
			42.693,
			42.741,
			42.802,
			42.864,
			42.911,
			42.988,
			43.024,
			43.051,
			43.127,
			43.176,
			43.236,
			43.3,
			43.345,
			43.361,
			43.422,
			43.455,
			43.485,
			43.561,
			43.609,
			43.67,
			43.733,
			43.779,
			43.795,
			43.858,
			43.886,
			43.919,
			43.996,
			44.043,
			44.106,
			44.166,
			44.213,
			44.292,
			44.321,
			44.352,
			44.415,
			44.431,
			44.477,
			44.538,
			44.601,
			44.646,
			44.663,
			44.724,
			44.757,
			44.785,
			44.848,
			44.865,
			44.912,
			44.971,
			45.033,
			45.085,
			45.159,
			45.191,
			45.221,
			45.299,
			45.345,
			45.408,
			45.469,
			45.516,
			45.532,
			45.593,
			45.624,
			45.655,
			45.716,
			45.732,
			45.779,
			45.842,
			45.904,
			45.951,
			45.964,
			46.026,
			46.058,
			46.088,
			46.167,
			46.214,
			46.277,
			46.338,
			46.383,
			46.46,
			46.493,
			46.524,
			46.584,
			46.648,
			46.709,
			46.77,
			46.833,
			46.895,
			46.926,
			46.958,
			47.034,
			47.081,
			47.147,
			47.203,
			47.25,
			47.267,
			47.33,
			47.361,
			47.392,
			47.454,
			47.468,
			47.515,
			47.579,
			47.638,
			47.702,
			47.763,
			47.795,
			47.826,
			47.887,
			47.949,
			48.012,
			48.074,
			48.119,
			48.135,
			48.196,
			48.229,
			48.259,
			48.321,
			48.338,
			48.384,
			48.447,
			48.507,
			48.555,
			48.57,
			48.632,
			48.663,
			48.692,
			48.756,
			48.819,
			48.88,
			48.942,
			48.987,
			49.066,
			49.095,
			49.128,
			49.188,
			49.209,
			49.252,
			49.315,
			49.377,
			49.423,
			49.437,
			49.498,
			49.532,
			49.562,
			49.638,
			49.685,
			49.748,
			49.811,
			49.858,
			49.934,
			49.966,
			49.997,
			50.059,
			50.12,
			50.183,
			50.245,
			50.29,
			50.369,
			50.4,
			50.43,
			50.508,
			50.553,
			50.615,
			50.678,
			50.725,
			50.739,
			50.801,
			50.833,
			50.863,
			50.941,
			50.988,
			51.05,
			51.111,
			51.159,
			51.237,
			51.271,
			51.299,
			51.374,
			51.422,
			51.484,
			51.547,
			51.593,
			51.609,
			51.67,
			51.702,
			51.732,
			51.795,
			51.809,
			51.856,
			51.918,
			51.979,
			52.027,
			52.042,
			52.104,
			52.134,
			52.166,
			52.229,
			52.243,
			52.29,
			52.352,
			52.413,
			52.46,
			52.538,
			52.568,
			52.599,
			52.678,
			52.725,
			52.785,
			52.849,
			52.895,
			52.909,
			52.971,
			53.002,
			53.035,
			53.095,
			53.112,
			53.159,
			53.219,
			53.282,
			53.332,
			53.405,
			53.437,
			53.469,
			53.53,
			53.547,
			53.593,
			53.655,
			53.716,
			53.763,
			53.841,
			53.871,
			53.901,
			53.966,
			53.981,
			54.028,
			54.09,
			54.15,
			54.198,
			54.212,
			54.274,
			54.304,
			54.337,
			54.399,
			54.415,
			54.462,
			54.524,
			54.583,
			54.63,
			54.646,
			54.71,
			54.741,
			54.771,
			54.832,
			54.894,
			54.955,
			55.02,
			55.066,
			55.143,
			55.175,
			55.204,
			55.282,
			55.329,
			55.391,
			55.453,
			55.499,
			55.515,
			55.576,
			55.608,
			55.637,
			55.715,
			55.762,
			55.829,
			55.888,
			55.932,
			56.01,
			56.041,
			56.073,
			56.134,
			56.196,
			56.26,
			56.322,
			56.382,
			56.444,
			56.475,
			56.508,
			56.584,
			56.63,
			56.694,
			56.756,
			56.802,
			56.816,
			56.878,
			56.911,
			56.941,
			57.003,
			57.02,
			57.066,
			57.129,
			57.188,
			57.236,
			57.312,
			57.345,
			57.375,
			57.438,
			57.458,
			57.499,
			57.562,
			57.624,
			57.67,
			57.685,
			57.748,
			57.777,
			57.809,
			57.888,
			57.934,
			57.996,
			58.058,
			58.104,
			58.12,
			58.181,
			58.213,
			58.243,
			58.304,
			58.32,
			58.367,
			58.43,
			58.492,
			58.537,
			58.616,
			58.646,
			58.676,
			58.756,
			58.8,
			58.865,
			58.924,
			58.986,
			59.049,
			59.079,
			59.112,
			59.172,
			59.189,
			59.234,
			59.298,
			59.36,
			59.421,
			59.485,
			59.52,
			59.545,
			59.607,
			59.668,
			59.733,
			59.792,
			59.841,
			59.916,
			59.949,
			59.981,
			60.042,
			60.058,
			60.105,
			60.169,
			60.227,
			60.274,
			60.291,
			60.35,
			60.383,
			60.413,
			60.474,
			60.492,
			60.538,
			60.6,
			60.661,
			60.709,
			60.787,
			60.816,
			60.848,
			60.909,
			60.973,
			61.032,
			61.096,
			61.158,
			61.22,
			61.249,
			61.281,
			61.342,
			61.358,
			61.404,
			61.467,
			61.529,
			61.592,
			61.655,
			61.685,
			61.716,
			61.793,
			61.841,
			61.901,
			61.963,
			62.012,
			62.087,
			62.118,
			62.15,
			62.212,
			62.275,
			62.336,
			62.399,
			62.443,
			62.459,
			62.521,
			62.553,
			62.584,
			62.645,
			62.662,
			62.708,
			62.774,
			62.833,
			62.88,
			62.893,
			62.955,
			62.987,
			63.017,
			63.079,
			63.096,
			63.141,
			63.203,
			63.267,
			63.329,
			63.388,
			63.419,
			63.451,
			63.529,
			63.576,
			63.643,
			63.701,
			63.761,
			63.824,
			63.854,
			63.885,
			63.949,
			63.965,
			64.009,
			64.072,
			64.135,
			64.182,
			64.259,
			64.289,
			64.321,
			64.381,
			64.443,
			64.505,
			64.568,
			64.615,
			64.693,
			64.724,
			64.755,
			64.816,
			64.831,
			64.88,
			64.941,
			65.002,
			65.066,
			65.128,
			65.158,
			65.187,
			65.266,
			65.314,
			65.374,
			65.437,
			65.482,
			65.56,
			65.593,
			65.623,
			65.704,
			65.746,
			65.81,
			65.87,
			65.916,
			65.933,
			65.993,
			66.026,
			66.057,
			66.119,
			66.133,
			66.182,
			66.245,
			66.304,
			66.352,
			66.366,
			66.429,
			66.459,
			66.492,
			66.553,
			66.568,
			66.613,
			66.676,
			66.74,
			66.785,
			66.863,
			66.893,
			66.926,
			66.985,
			67.049,
			67.111,
			67.171,
			67.219,
			67.235,
			67.296,
			67.326,
			67.36,
			67.421,
			67.435,
			67.482,
			67.545,
			67.608,
			67.653,
			67.668,
			67.732,
			67.767,
			67.792,
			67.854,
			67.87,
			67.918,
			67.979,
			68.042,
			68.088,
			68.166,
			68.196,
			68.228,
			68.304,
			68.352,
			68.413,
			68.475,
			68.538,
			68.599,
			68.63,
			68.66,
			68.724,
			68.738,
			68.785,
			68.849,
			68.909,
			68.955,
			68.971,
			69.032,
			69.065,
			69.096,
			69.158,
			69.22,
			69.285,
			69.342,
			69.405,
			69.468,
			69.499,
			69.529,
			69.591,
			69.652,
			69.715,
			69.778,
			69.827,
			69.839,
			69.901,
			69.932,
			69.963,
			70.026,
			70.041,
			70.088,
			70.148,
			70.209,
			70.272,
			70.336,
			70.367,
			70.397,
			70.475,
			70.521,
			70.585,
			70.646,
			70.708,
			70.769,
			70.801,
			70.832,
			70.893,
			70.908,
			70.955,
			71.018,
			71.08,
			71.127,
			71.142,
			71.202,
			71.233,
			71.265,
			71.327,
			71.342,
			71.388,
			71.451,
			71.512,
			71.575,
			71.637,
			71.667,
			71.7,
			71.776,
			71.823,
			71.891,
			71.948,
			71.992,
			72.01,
			72.073,
			72.102,
			72.133,
			72.21,
			72.258,
			72.319,
			72.381,
			72.429,
			72.443,
			72.505,
			72.536,
			72.566,
			72.628,
			72.644,
			72.691,
			72.758,
			72.816,
			72.862,
			72.941,
			72.97,
			73,
			73.062,
			73.124,
			73.187,
			73.25,
			73.297,
			73.31,
			73.375,
			73.404,
			73.436,
			73.496,
			73.512,
			73.558,
			73.62,
			73.682,
			73.745,
			73.808,
			73.839,
			73.869,
			73.951,
			73.993,
			74.055,
			74.116,
			74.163,
			74.242,
			74.271,
			74.305,
			74.367,
			74.429,
			74.488,
			74.551,
			74.612,
			74.676,
			74.707,
			74.737,
			74.8,
			74.816,
			74.863,
			74.925,
			74.985,
			75.032,
			75.111,
			75.141,
			75.172,
			75.25,
			75.295,
			75.358,
			75.421,
			75.482,
			75.542,
			75.576,
			75.604,
			75.683,
			75.728,
			75.791,
			75.855,
			75.902,
			75.915,
			75.976,
			76.013,
			76.04,
			76.101,
			76.118,
			76.163,
			76.225,
			76.288,
			76.349,
			76.413,
			76.442,
			76.475,
			76.537,
			76.599,
			76.659,
			76.722,
			76.768,
			76.785,
			76.846,
			76.876,
			76.909,
			76.971,
			76.986,
			77.034,
			77.093,
			77.155,
			77.203,
			77.219,
			77.279,
			77.312,
			77.343,
			77.404,
			77.418,
			77.465,
			77.528,
			77.591,
			77.637,
			77.715,
			77.746,
			77.775,
			77.838,
			77.899,
			77.964,
			78.025,
			78.076,
			78.085,
			78.149,
			78.179,
			78.209,
			78.27,
			78.287,
			78.333,
			78.398,
			78.459,
			78.505,
			78.582,
			78.613,
			78.643,
			78.706,
			78.767,
			78.831,
			78.893,
			78.956,
			79.016,
			79.047,
			79.078,
			79.139,
			79.157,
			79.202,
			79.266,
			79.327,
			79.371,
			79.388,
			79.452,
			79.482,
			79.513,
			79.575,
			79.591,
			79.637,
			79.699,
			79.76,
			79.805,
			79.822,
			79.884,
			79.915,
			79.948,
			80.009,
			80.071,
			80.137,
			80.193,
			80.242,
			80.256,
			80.318,
			80.349,
			80.381,
			80.443,
			80.458,
			80.504,
			80.567,
			80.63,
			80.675,
			80.69,
			80.753,
			80.783,
			80.815,
			80.892,
			80.937,
			81.001,
			81.062,
			81.126,
			81.185,
			81.218,
			81.248,
			81.326,
			81.374,
			81.433,
			81.498,
			81.558,
			81.619,
			81.653,
			81.683,
			81.744,
			81.76,
			81.807,
			81.87,
			81.932,
			81.978,
			81.994,
			82.054,
			82.085,
			82.117,
			82.2,
			82.242,
			82.304,
			82.364,
			82.411,
			82.487,
			82.52,
			82.551,
			82.63,
			82.674,
			82.738,
			82.799,
			82.846,
			82.862,
			82.923,
			82.953,
			82.985,
			83.062,
			83.11,
			83.17,
			83.233,
			83.28,
			83.296,
			83.358,
			83.386,
			83.42,
			83.482,
			83.543,
			83.608,
			83.668,
			83.712,
			83.792,
			83.822,
			83.853,
			83.916,
			83.931,
			83.978,
			84.04,
			84.101,
			84.148,
			84.162,
			84.226,
			84.259,
			84.288,
			84.349,
			84.365,
			84.412,
			84.474,
			84.534,
			84.58,
			84.659,
			84.691,
			84.72,
			84.799,
			84.846,
			84.908,
			84.968,
			85.032,
			85.095,
			85.124,
			85.155,
			85.218,
			85.236,
			85.279,
			85.343,
			85.404,
			85.45,
			85.466,
			85.529,
			85.558,
			85.589,
			85.651,
			85.666,
			85.713,
			85.776,
			85.837,
			85.885,
			85.962,
			85.992,
			86.025,
			86.085,
			86.148,
			86.211,
			86.27,
			86.332,
			86.396,
			86.426,
			86.458,
			86.52,
			86.535,
			86.583,
			86.643,
			86.707,
			86.753,
			86.768,
			86.831,
			86.861,
			86.891,
			86.969,
			87.015,
			87.077,
			87.14,
			87.201,
			87.263,
			87.296,
			87.325,
			87.388,
			87.449,
			87.513,
			87.575,
			87.62,
			87.637,
			87.699,
			87.729,
			87.761,
			87.822,
			87.838,
			87.884,
			87.945,
			88.009,
			88.053,
			88.068,
			88.131,
			88.163,
			88.195,
			88.256,
			88.317,
			88.384,
			88.443,
			88.489,
			88.567,
			88.597,
			88.627,
			88.691,
			88.752,
			88.816,
			88.875,
			88.922,
			88.939,
			88.999,
			89.032,
			89.063,
			89.124,
			89.14,
			89.187,
			89.248,
			89.311,
			89.371,
			89.434,
			89.465,
			89.496,
			89.558,
			89.621,
			89.681,
			89.745,
			89.791,
			89.869,
			89.899,
			89.931,
			89.993,
			90.008,
			90.056,
			90.117,
			90.179,
			90.224,
			90.241,
			90.304,
			90.334,
			90.364,
			90.426,
			90.445,
			90.489,
			90.55,
			90.612,
			90.66,
			90.737,
			90.767,
			90.799,
			90.861,
			90.922,
			90.984,
			91.047,
			91.093,
			91.109,
			91.171,
			91.201,
			91.233,
			91.311,
			91.354,
			91.418,
			91.482,
			91.526,
			91.543,
			91.604,
			91.634,
			91.667,
			91.727,
			91.743,
			91.791,
			91.855,
			91.917,
			91.962,
			92.039,
			92.07,
			92.099,
			92.164,
			92.225,
			92.288,
			92.349,
			92.393,
			92.41,
			92.472,
			92.508,
			92.533,
			92.596,
			92.61,
			92.658,
			92.719,
			92.781,
			92.827,
			92.843,
			92.907,
			92.936,
			92.967,
			93.045,
			93.091,
			93.156,
			93.215,
			93.278,
			93.342,
			93.371,
			93.404,
			93.463,
			93.481,
			93.528,
			93.588,
			93.651,
			93.696,
			93.714,
			93.774,
			93.805,
			93.836,
			93.899,
			93.914,
			93.959,
			94.021,
			94.083,
			94.132,
			94.208,
			94.238,
			94.27,
			94.334,
			94.396,
			94.458,
			94.519,
			94.581,
			94.642,
			94.674,
			94.705,
			94.782,
			94.83,
			94.892,
			94.953,
			95.014,
			95.077,
			95.108,
			95.138,
			95.202,
			95.216,
			95.262,
			95.325,
			95.386,
			95.449,
			95.511,
			95.542,
			95.574,
			95.635,
			95.696,
			95.759,
			95.821,
			95.868,
			95.881,
			95.944,
			95.976,
			96.008,
			96.084,
			96.13,
			96.194,
			96.256,
			96.301,
			96.316,
			96.38,
			96.41,
			96.441,
			96.503,
			96.518,
			96.564,
			96.63,
			96.689,
			96.736,
			96.812,
			96.843,
			96.876,
			96.937,
			97,
			97.06,
			97.122,
			97.17,
			97.185,
			97.246,
			97.277,
			97.309,
			97.372,
			97.386,
			97.433,
			97.496,
			97.558,
			97.619,
			97.682,
			97.713,
			97.742,
			97.82,
			97.866,
			97.932,
			97.991,
			98.039,
			98.114,
			98.146,
			98.176,
			98.255,
			98.302,
			98.364,
			98.426,
			98.471,
			98.487,
			98.549,
			98.579,
			98.609,
			98.692,
			98.736,
			98.798,
			98.859,
			98.905,
			98.922,
			98.982,
			99.012,
			99.045,
			99.123,
			99.168,
			99.23,
			99.292,
			99.338,
			99.416,
			99.448,
			99.479,
			99.542,
			99.558,
			99.601,
			99.667,
			99.727,
			99.775,
			99.79,
			99.852,
			99.881,
			99.913,
			99.988,
			100.038,
			100.1,
			100.162,
			100.221,
			100.283,
			100.317,
			100.347,
			100.425,
			100.471,
			100.533,
			100.596,
			100.658,
			100.719,
			100.755,
			100.781,
			100.842,
			100.86,
			100.906,
			100.968,
			101.03,
			101.075,
			101.091,
			101.154,
			101.183,
			101.216,
			101.241
		]
	],
	'1677_1680_7982': [
		[
			72.522
		],
		[
			0
		],
		[
			0,
			0.702,
			3.888,
			7.079,
			8.676,
			11.873,
			15.074,
			16.676,
			19.884,
			21.489,
			24.703,
			27.922,
			32.757,
			35.986,
			40.837,
			42.456,
			44.077,
			48.944,
			50.569,
			52.195,
			57.078,
			60.338,
			63.603,
			65.237,
			68.508,
			70.145,
			73.423,
			76.705,
			78.347,
			81.635,
			84.927,
			89.874,
			91.525,
			93.176,
			96.483,
			99.793,
			101.45,
			104.766,
			106.425,
			109.747,
			113.073,
			118.068,
			121.403,
			126.412,
			128.083,
			129.755,
			134.779,
			138.132,
			143.169,
			146.532,
			151.583,
			154.956,
			158.333,
			160.023,
			163.407,
			165.1,
			168.488,
			171.881,
			176.979,
			178.681,
			180.383,
			185.495,
			187.202,
			188.909,
			194.037,
			197.461,
			200.889,
			206.036,
			209.46,
			214.561,
			216.247,
			217.924,
			222.896,
			226.164,
			230.992,
			234.162,
			237.294,
			238.845,
			241.919,
			244.955,
			246.46,
			250.921,
			253.848,
			256.736,
			260.995,
			263.785,
			267.898,
			269.249,
			270.591,
			274.562,
			277.162,
			280.989,
			283.494,
			287.18,
			289.59,
			290.782,
			291.965,
			295.463,
			296.611,
			298.879,
			301.109,
			303.302,
			306.521,
			308.62,
			310.683,
			311.7,
			313.706,
			314.695,
			316.644,
			318.557,
			321.368,
			323.189,
			325.848,
			326.717,
			327.577,
			330.094,
			331.724,
			334.118,
			335.674,
			337.209,
			337.97,
			339.484,
			340.24,
			341.744,
			343.239,
			343.984,
			345.486,
			346.983,
			349.225,
			349.982,
			350.737,
			352.998,
			353.752,
			354.508,
			356.032,
			356.792,
			358.314,
			359.846,
			362.162,
			363.708,
			366.056,
			366.841,
			367.626,
			369.995,
			371.592,
			373.987,
			375.596,
			377.218,
			378.027,
			379.647,
			380.469,
			382.128,
			383.783,
			384.61,
			386.294,
			387.99,
			390.53,
			391.396,
			392.265,
			394.866,
			395.733,
			396.613,
			399.279,
			401.058,
			403.728,
			405.507,
			407.294,
			408.192,
			409.986,
			410.883,
			411.781,
			415.339,
			416.228,
			418.914,
			420.709,
			422.505,
			423.402,
			425.19,
			426.975,
			427.867,
			430.521,
			432.281,
			434.038,
			436.667,
			438.415,
			441.018,
			441.884,
			442.749,
			445.339,
			447.062,
			449.639,
			451.346,
			453.034,
			453.885,
			455.568,
			456.421,
			457.263,
			459.796,
			460.635,
			461.474,
			464.004,
			465.7,
			467.404,
			468.26,
			469.981,
			471.718,
			472.591,
			474.346,
			475.229,
			477.013,
			478.809,
			481.532,
			483.379,
			486.17,
			487.113,
			488.06,
			490.919,
			492.836,
			495.733,
			497.679,
			499.62,
			500.603,
			502.578,
			503.576,
			505.58,
			508.607,
			510.639,
			512.683,
			515.778,
			516.824,
			517.873,
			519.979,
			522.097,
			523.167,
			525.314,
			526.386,
			528.509,
			530.614,
			533.788,
			535.914,
			539.125,
			540.202,
			541.281,
			544.538,
			546.726,
			550.031,
			552.248,
			554.475,
			555.593,
			557.836,
			558.961,
			561.216,
			564.62,
			566.904,
			569.198,
			572.68,
			575,
			578.5,
			579.672,
			580.846,
			583.203,
			584.385,
			586.755,
			590.328,
			592.723,
			595.127,
			598.755,
			599.969,
			601.186,
			604.852,
			607.309,
			611.011,
			613.49,
			617.224,
			619.722,
			622.228,
			623.462,
			625.979,
			627.243,
			629.778,
			632.32,
			636.144,
			637.416,
			638.685,
			642.459,
			643.707,
			644.946,
			648.602,
			650.984,
			654.48,
			656.758,
			658.995,
			660.096,
			662.271,
			663.343,
			664.417,
			667.531,
			668.551,
			669.559,
			672.534,
			674.478,
			676.388,
			677.334,
			679.21,
			681.047,
			681.96,
			683.778,
			684.682,
			686.482,
			688.275,
			690.981,
			692.787,
			695.5,
			696.41,
			697.318,
			700.04,
			701.861,
			704.577,
			706.407,
			708.234,
			709.146,
			710.993,
			711.923,
			713.779,
			715.636,
			716.572,
			717.49,
			720.302,
			723.079,
			724.005,
			724.933,
			726.782,
			728.63,
			729.554,
			731.404,
			732.328,
			734.179,
			736.021,
			738.748,
			740.57,
			743.274,
			744.177,
			745.08,
			747.804,
			749.625,
			752.367,
			754.202,
			756.043,
			756.967,
			758.821,
			759.75,
			761.615,
			763.492,
			764.434,
			766.326,
			768.229,
			771.107,
			773.04,
			775.961,
			776.94,
			777.906,
			780.868,
			782.845,
			784.813,
			787.724,
			789.627,
			792.411,
			793.322,
			794.223,
			796.872,
			797.737,
			798.593,
			801.108,
			802.739,
			805.121,
			806.663,
			807.421,
			808.91,
			811.077,
			812.494,
			813.876,
			815.917,
			817.267,
			819.287,
			819.957,
			820.625,
			822.616,
			823.937,
			825.911,
			827.213,
			828.508,
			829.156,
			830.446,
			831.087,
			831.727,
			834.277,
			834.917,
			836.826,
			838.093,
			839.365,
			840.008,
			841.289,
			842.567,
			843.206,
			845.14,
			846.435,
			847.73,
			849.683,
			850.999,
			852.972,
			853.631,
			854.291,
			856.308,
			857.651,
			859.669,
			861.053,
			862.431,
			863.119,
			864.496,
			865.196,
			866.592,
			867.98,
			868.673,
			869.366,
			871.438,
			872.808,
			874.173,
			874.855,
			876.22,
			877.578,
			878.256,
			879.612,
			880.296,
			881.677,
			883.053,
			885.12,
			886.534,
			888.653,
			889.361,
			890.071,
			892.21,
			893.64,
			895.806,
			897.279,
			899.499,
			900.999,
			901.766,
			903.306,
			904.855,
			905.634,
			907.217,
			908.812,
			911.224,
			912.023,
			912.824,
			915.245,
			916.059,
			916.871,
			918.492,
			919.307,
			920.948,
			922.576,
			925.022,
			926.662,
			929.066,
			929.871,
			930.677,
			933.092,
			934.699,
			937.123,
			938.754,
			941.207,
			942.851,
			943.677,
			945.327,
			947.802,
			949.462,
			951.125,
			953.621,
			955.287,
			957.789,
			958.613,
			959.451,
			961.979,
			963.682,
			966.267,
			968.007,
			969.768,
			972.432,
			973.327,
			974.228,
			976.947,
			977.86,
			978.777,
			981.553,
			983.435,
			985.313,
			986.256,
			988.151,
			990.055,
			991.011,
			992.914,
			993.878,
			995.807,
			997.747,
			1000.677,
			1002.643,
			1005.615,
			1006.611,
			1007.61,
			1010.625,
			1012.649,
			1015.705,
			1017.756,
			1019.818,
			1022.931,
			1023.974,
			1026.068,
			1028.168,
			1029.217,
			1030.266,
			1033.426,
			1035.545,
			1037.675,
			1038.744,
			1040.888,
			1043.04,
			1044.12,
			1046.287,
			1047.374,
			1049.554,
			1051.744,
			1055.047,
			1057.263,
			1060.606,
			1061.728,
			1062.852,
			1066.242,
			1068.533,
			1071.958,
			1074.23,
			1076.532,
			1077.688,
			1080.007,
			1081.17,
			1083.505,
			1085.869,
			1087.047,
			1089.391,
			1091.765,
			1095.371,
			1096.572,
			1097.777,
			1100.194,
			1102.619,
			1103.836,
			1106.275,
			1107.495,
			1109.916,
			1113.612,
			1116.071,
			1118.542,
			1122.265,
			1123.513,
			1124.764,
			1128.529,
			1131.052,
			1134.829,
			1137.372,
			1139.927,
			1141.208,
			1143.798,
			1146.378,
			1147.672,
			1150.27,
			1151.572,
			1154.184,
			1156.784,
			1160.724,
			1162.042,
			1163.361,
			1167.337,
			1168.668,
			1170,
			1174.011,
			1176.694,
			1179.383,
			1183.42,
			1186.116,
			1190.174,
			1191.53,
			1192.889,
			1196.976,
			1198.343,
			1199.712,
			1203.827,
			1206.57,
			1210.669,
			1213.38,
			1214.729,
			1217.404,
			1220.049,
			1221.357,
			1223.946,
			1226.496,
			1230.251,
			1232.708,
			1236.326,
			1237.535,
			1238.716,
			1242.211,
			1244.501,
			1247.882,
			1250.109,
			1252.314,
			1253.412,
			1255.6,
			1256.691,
			1258.869,
			1262.136,
			1263.225,
			1266.503,
			1268.698,
			1270.902,
			1272.01,
			1274.233,
			1276.47,
			1277.597,
			1280.99,
			1283.278,
			1285.566,
			1288.994,
			1291.26,
			1294.619,
			1295.724,
			1296.823,
			1300.06,
			1302.188,
			1305.326,
			1307.368,
			1309.369,
			1310.355,
			1312.303,
			1313.265,
			1315.161,
			1317.022,
			1317.937,
			1319.741,
			1321.511,
			1323.237,
			1324.923,
			1325.752,
			1327.382,
			1328.978,
			1329.775,
			1332.059,
			1333.548,
			1335.714,
			1337.113,
			1338.462,
			1340.421,
			1341.058,
			1341.686,
			1343.503,
			1344.665,
			1346.341,
			1347.414,
			1348.446,
			1348.948,
			1349.924,
			1350.398,
			1351.322,
			1352.219,
			1352.654,
			1353.508,
			1354.336,
			1355.539,
			1355.93,
			1356.315,
			1357.441,
			1357.815,
			1358.198,
			1358.956,
			1359.329,
			1360.067,
			1360.8,
			1361.166,
			1361.897,
			1362.625,
			1363.766,
			1364.146,
			1364.525,
			1365.659,
			1366.414,
			1367.555,
			1368.356,
			1369.169,
			1369.574,
			1370.386,
			1371.204,
			1371.615,
			1372.445,
			1372.864,
			1373.744,
			1374.622,
			1375.942,
			1376.829,
			1378.194,
			1378.67,
			1379.145,
			1380.568,
			1381.519,
			1382.964,
			1383.936,
			1384.906,
			1386.361,
			1386.848,
			1387.337,
			1388.808,
			1389.295,
			1389.781,
			1391.239,
			1392.214,
			1393.195,
			1393.691,
			1394.676,
			1395.655,
			1396.143,
			1397.119,
			1397.608,
			1398.59,
			1399.57,
			1401.033,
			1402.009,
			1403.487,
			1403.98,
			1404.473,
			1405.957,
			1406.952,
			1408.458,
			1409.459,
			1410.467,
			1410.974,
			1411.995,
			1413.547,
			1414.578,
			1415.097,
			1416.14,
			1417.196,
			1418.265,
			1419.349,
			1419.893,
			1420.989,
			1422.095,
			1422.652,
			1423.776,
			1424.345,
			1425.488,
			1426.637,
			1428.376,
			1429.548,
			1431.322,
			1431.916,
			1432.511,
			1434.308,
			1435.536,
			1437.374,
			1438.608,
			1439.849,
			1440.476,
			1441.74,
			1442.377,
			1443.656,
			1445.609,
			1446.933,
			1448.272,
			1450.308,
			1451.711,
			1453.846,
			1454.566,
			1455.293,
			1457.513,
			1459.012,
			1460.527,
			1462.827,
			1464.379,
			1466.73,
			1467.52,
			1468.313,
			1471.525,
			1472.338,
			1474.798,
			1476.459,
			1478.139,
			1478.985,
			1480.685,
			1481.536,
			1483.241,
			1484.952,
			1485.812,
			1487.542,
			1489.283,
			1491.916,
			1493.682,
			1496.354,
			1497.248,
			1498.145,
			1500.854,
			1502.676,
			1505.43,
			1507.281,
			1509.146,
			1510.083,
			1511.965,
			1512.911,
			1513.861,
			1516.726,
			1517.687,
			1518.651,
			1521.562,
			1523.517,
			1526.47,
			1528.454,
			1530.45,
			1531.453,
			1534.479,
			1536.511,
			1538.556,
			1541.648,
			1543.722,
			1546.855,
			1547.904,
			1548.956,
			1552.108,
			1554.216,
			1557.401,
			1559.538,
			1561.685,
			1562.762,
			1564.926,
			1566.011,
			1568.19,
			1570.377,
			1571.473,
			1573.673,
			1575.88,
			1578.095,
			1580.32,
			1581.436,
			1583.674,
			1585.923,
			1587.051,
			1589.315,
			1590.451,
			1592.729,
			1595.019,
			1598.471,
			1600.786,
			1604.275,
			1605.443,
			1606.613,
			1610.139,
			1612.501,
			1616.064,
			1618.449,
			1620.844,
			1622.045,
			1624.454,
			1625.662,
			1628.088,
			1631.748,
			1634.202,
			1636.666,
			1640.376,
			1641.617,
			1642.86,
			1646.607,
			1647.86,
			1649.115,
			1651.624,
			1652.875,
			1655.36,
			1657.819,
			1661.446,
			1663.818,
			1667.308,
			1668.453,
			1669.597,
			1672.972,
			1675.187,
			1678.442,
			1680.558,
			1682.637,
			1683.662,
			1685.668,
			1687.636,
			1688.607,
			1690.512,
			1691.451,
			1693.301,
			1695.116,
			1697.774,
			1698.643,
			1699.505,
			1702.044,
			1702.873,
			1703.692,
			1706.115,
			1707.692,
			1709.988,
			1711.472,
			1712.919,
			1715.023,
			1715.7,
			1716.369,
			1718.325,
			1718.961,
			1719.589,
			1721.438,
			1722.636,
			1723.816,
			1724.4,
			1725.557,
			1726.7,
			1727.268,
			1728.4,
			1728.965,
			1730.093,
			1731.189,
			1732.821,
			1733.906,
			1735.534,
			1736.073,
			1736.611,
			1738.225,
			1739.304,
			1740.978,
			1742.162,
			1743.933,
			1745.115,
			1745.722,
			1747.042,
			1748.356,
			1749.013,
			1749.669,
			1751.721,
			1753.16,
			1754.598,
			1755.317,
			1756.764,
			1758.206,
			1758.924,
			1760.362,
			1761.081,
			1762.443,
			1763.798,
			1765.842,
			1767.169,
			1769.121,
			1769.773,
			1770.428,
			1772.333,
			1773.561,
			1775.401,
			1776.646,
			1777.909,
			1778.541,
			1779.818,
			1780.462,
			1781.766,
			1783.107,
			1783.781,
			1785.138,
			1786.501,
			1788.591,
			1789.288,
			1789.985,
			1792.077,
			1792.791,
			1793.501,
			1795.612,
			1797.006,
			1798.479,
			1800.665,
			1802.105,
			1804.329,
			1805.063,
			1805.792,
			1807.938,
			1809.32,
			1811.348,
			1812.675,
			1813.967,
			1814.606,
			1815.876,
			1816.508,
			1817.771,
			1819.039,
			1819.669,
			1820.923,
			1822.174,
			1824.076,
			1825.35,
			1827.256,
			1827.892,
			1828.537,
			1830.479,
			1831.772,
			1833.068,
			1835.041,
			1836.358,
			1838.342,
			1839.014,
			1839.687,
			1841.709,
			1842.387,
			1843.066,
			1845.127,
			1846.509,
			1847.9,
			1848.6,
			1850.016,
			1851.438,
			1852.153,
			1853.59,
			1854.314,
			1855.772,
			1857.236,
			1859.451,
			1860.933,
			1863.17,
			1863.92,
			1864.667,
			1866.907,
			1868.41,
			1870.652,
			1872.14,
			1873.637,
			1874.39,
			1875.848,
			1876.579,
			1878.049,
			1879.53,
			1880.259,
			1881.724,
			1883.2,
			1884.69,
			1886.18,
			1886.93,
			1888.443,
			1889.969,
			1890.734,
			1892.275,
			1893.051,
			1894.615,
			1896.193,
			1898.586,
			1900.202,
			1902.669,
			1903.498,
			1904.33,
			1906.836,
			1908.518,
			1911.071,
			1912.794,
			1915.406,
			1917.163,
			1918.046,
			1919.823,
			1921.612,
			1922.511,
			1924.304,
			1926.124,
			1928.879,
			1929.803,
			1930.731,
			1933.533,
			1934.473,
			1935.417,
			1938.266,
			1940.182,
			1942.109,
			1945.023,
			1946.981,
			1949.936,
			1950.927,
			1951.921,
			1954.917,
			1956.929,
			1959.969,
			1962.009,
			1964.061,
			1965.092,
			1967.161,
			1968.2,
			1970.286,
			1972.378,
			1973.423,
			1975.515,
			1977.614,
			1980.785,
			1981.847,
			1982.911,
			1986.12,
			1987.194,
			1988.271,
			1991.518,
			1993.695,
			1996.979,
			1999.181,
			2001.393,
			2004.729,
			2005.846,
			2006.966,
			2010.339,
			2012.601,
			2016.011,
			2018.297,
			2020.593,
			2021.745,
			2024.055,
			2026.375,
			2027.539,
			2029.874,
			2031.045,
			2033.394,
			2035.753,
			2039.309,
			2041.691,
			2045.281,
			2046.482,
			2047.684,
			2051.304,
			2053.728,
			2057.379,
			2059.823,
			2063.495,
			2065.939,
			2067.156,
			2069.577,
			2071.972,
			2073.158,
			2074.336,
			2077.812,
			2080.083,
			2082.314,
			2083.416,
			2085.592,
			2087.73,
			2088.785,
			2091.894,
			2093.919,
			2095.907,
			2098.821,
			2100.719,
			2103.498,
			2104.407,
			2105.308,
			2107.977,
			2109.71,
			2112.274,
			2113.969,
			2115.654,
			2116.494,
			2118.175,
			2119.018,
			2120.701,
			2122.38,
			2123.221,
			2124.907,
			2126.594,
			2129.129,
			2129.975,
			2130.825,
			2132.526,
			2134.227,
			2135.079,
			2136.807,
			2137.671,
			2139.398,
			2141.149,
			2143.795,
			2145.565,
			2148.257,
			2149.153,
			2150.048,
			2152.778,
			2154.595,
			2157.348,
			2159.187,
			2161.028,
			2161.954,
			2163.802,
			2164.723,
			2166.566,
			2168.401,
			2169.317,
			2171.141,
			2172.947,
			2175.643,
			2176.536,
			2177.425,
			2180.084,
			2180.969,
			2181.847,
			2184.471,
			2186.218,
			2187.969,
			2190.592,
			2192.34,
			2194.957,
			2195.828,
			2196.699,
			2199.292,
			2200.155,
			2201.017,
			2203.574,
			2205.264,
			2206.951,
			2207.786,
			2209.449,
			2211.11,
			2211.94,
			2213.593,
			2214.418,
			2216.068,
			2217.72,
			2220.195,
			2221.849,
			2224.342,
			2225.177,
			2226.014,
			2228.544,
			2230.245,
			2232.822,
			2234.556,
			2236.304,
			2237.182,
			2238.95,
			2239.84,
			2241.629,
			2243.415,
			2244.323,
			2245.235,
			2248.006,
			2249.861,
			2251.731,
			2252.67,
			2254.558,
			2256.442,
			2257.396,
			2259.313,
			2260.276,
			2262.211,
			2264.159,
			2267.101,
			2269.077,
			2272.073,
			2273.071,
			2274.072,
			2277.087,
			2279.109,
			2282.162,
			2284.21,
			2287.296,
			2289.357,
			2290.387,
			2292.443,
			2294.504,
			2295.539,
			2297.617,
			2299.704,
			2302.856,
			2303.912,
			2304.971,
			2308.175,
			2309.25,
			2310.327,
			2312.496,
			2313.586,
			2315.774,
			2317.975,
			2321.296,
			2323.521,
			2326.874,
			2327.995,
			2329.117,
			2332.499,
			2334.761,
			2338.172,
			2340.456,
			2342.75,
			2343.903,
			2346.214,
			2347.373,
			2349.704,
			2353.214,
			2355.568,
			2357.922,
			2361.466,
			2363.82,
			2367.34,
			2368.507,
			2369.674,
			2373.162,
			2375.478,
			2377.788,
			2381.239,
			2383.533,
			2386.967,
			2388.109,
			2389.25,
			2393.802,
			2394.937,
			2398.349,
			2400.626,
			2402.908,
			2404.051,
			2406.343,
			2408.647,
			2409.802,
			2413.279,
			2415.607,
			2417.943,
			2421.457,
			2423.811,
			2427.353,
			2428.539,
			2429.725,
			2433.296,
			2435.685,
			2439.281,
			2441.686,
			2444.094,
			2445.297,
			2447.705,
			2448.909,
			2450.115,
			2453.731,
			2454.938,
			2456.145,
			2459.766,
			2462.185,
			2464.606,
			2465.82,
			2468.254,
			2470.695,
			2471.916,
			2474.363,
			2475.589,
			2478.043,
			2480.501,
			2484.184,
			2486.637,
			2490.309,
			2491.53,
			2492.75,
			2496.395,
			2498.815,
			2502.429,
			2504.825,
			2507.21,
			2508.4,
			2510.77,
			2511.95,
			2514.304,
			2516.65,
			2517.82,
			2520.156,
			2522.487,
			2525.982,
			2527.148,
			2528.315,
			2530.654,
			2533,
			2534.176,
			2536.534,
			2537.714,
			2540.082,
			2542.458,
			2546.032,
			2548.425,
			2552.021,
			2553.224,
			2554.429,
			2558.052,
			2559.264,
			2560.479,
			2564.136,
			2566.59,
			2569.053,
			2570.288,
			2572.77,
			2574.014,
			2576.51,
			2579.019,
			2580.276,
			2582.777,
			2585.308,
			2589.12,
			2590.395,
			2591.672,
			2595.538,
			2596.823,
			2598.11,
			2600.665,
			2601.954,
			2604.536,
			2607.128,
			2611.031,
			2613.646,
			2617.583,
			2618.9,
			2620.219,
			2624.211,
			2625.517,
			2626.846,
			2630.844,
			2633.518,
			2636.199,
			2637.541,
			2640.229,
			2642.924,
			2644.274,
			2648.335,
			2651.049,
			2653.77,
			2657.862,
			2660.6,
			2664.719,
			2666.095,
			2667.473,
			2671.617,
			2674.389,
			2678.557,
			2681.344,
			2684.138,
			2685.537,
			2688.341,
			2689.746,
			2692.56,
			2695.38,
			2696.792,
			2698.207,
			2702.461,
			2705.305,
			2709.585,
			2712.446,
			2715.314,
			2716.751,
			2719.63,
			2721.072,
			2723.96,
			2726.856,
			2731.212,
			2734.124,
			2738.505,
			2739.969,
			2741.434,
			2745.838,
			2748.783,
			2753.211,
			2756.17,
			2759.137,
			2760.623,
			2763.599,
			2765.09,
			2768.077,
			2771.07,
			2772.57,
			2774.071,
			2778.583,
			2781.6,
			2786.135,
			2789.166,
			2792.203,
			2793.722,
			2796.766,
			2798.29,
			2801.342,
			2804.399,
			2808.995,
			2812.066,
			2816.681,
			2818.222,
			2819.764,
			2824.399,
			2827.495,
			2832.15,
			2835.259,
			2839.933,
			2843.055,
			2844.618,
			2847.747,
			2850.88,
			2852.449,
			2855.59,
			2858.737,
			2863.464,
			2865.042,
			2866.622,
			2871.368,
			2872.952,
			2874.538,
			2877.712,
			2879.302,
			2882.485,
			2885.672,
			2887.941
		],
		[
			0,
			0.013,
			0.076,
			0.122,
			0.138,
			0.199,
			0.231,
			0.26,
			0.323,
			0.339,
			0.386,
			0.448,
			0.508,
			0.571,
			0.633,
			0.665,
			0.696,
			0.759,
			0.78,
			0.821,
			0.882,
			0.944,
			0.99,
			1.007,
			1.068,
			1.099,
			1.13,
			1.192,
			1.208,
			1.255,
			1.317,
			1.378,
			1.424,
			1.44,
			1.501,
			1.532,
			1.565,
			1.626,
			1.643,
			1.688,
			1.749,
			1.81,
			1.859,
			1.937,
			1.968,
			1.997,
			2.076,
			2.122,
			2.184,
			2.246,
			2.308,
			2.371,
			2.402,
			2.432,
			2.493,
			2.509,
			2.556,
			2.618,
			2.681,
			2.725,
			2.741,
			2.803,
			2.839,
			2.865,
			2.927,
			2.989,
			3.051,
			3.114,
			3.159,
			3.237,
			3.27,
			3.299,
			3.364,
			3.425,
			3.487,
			3.547,
			3.595,
			3.609,
			3.672,
			3.704,
			3.733,
			3.813,
			3.859,
			3.921,
			3.984,
			4.028,
			4.108,
			4.136,
			4.169,
			4.231,
			4.292,
			4.359,
			4.418,
			4.477,
			4.54,
			4.571,
			4.601,
			4.664,
			4.68,
			4.728,
			4.788,
			4.85,
			4.913,
			4.974,
			5.005,
			5.037,
			5.098,
			5.114,
			5.159,
			5.222,
			5.283,
			5.332,
			5.409,
			5.44,
			5.472,
			5.531,
			5.593,
			5.656,
			5.719,
			5.765,
			5.78,
			5.842,
			5.873,
			5.905,
			5.965,
			5.982,
			6.027,
			6.092,
			6.153,
			6.2,
			6.213,
			6.278,
			6.308,
			6.339,
			6.401,
			6.417,
			6.463,
			6.526,
			6.587,
			6.634,
			6.712,
			6.742,
			6.772,
			6.851,
			6.896,
			6.965,
			7.021,
			7.067,
			7.084,
			7.144,
			7.176,
			7.207,
			7.268,
			7.284,
			7.333,
			7.392,
			7.456,
			7.502,
			7.518,
			7.579,
			7.61,
			7.642,
			7.703,
			7.764,
			7.828,
			7.888,
			7.936,
			7.952,
			8.013,
			8.043,
			8.076,
			8.154,
			8.198,
			8.263,
			8.323,
			8.368,
			8.385,
			8.446,
			8.479,
			8.51,
			8.586,
			8.632,
			8.697,
			8.758,
			8.804,
			8.882,
			8.913,
			8.943,
			9.006,
			9.067,
			9.129,
			9.191,
			9.237,
			9.254,
			9.316,
			9.347,
			9.375,
			9.439,
			9.454,
			9.501,
			9.563,
			9.624,
			9.672,
			9.689,
			9.751,
			9.781,
			9.813,
			9.872,
			9.889,
			9.936,
			9.999,
			10.058,
			10.107,
			10.184,
			10.213,
			10.247,
			10.309,
			10.371,
			10.43,
			10.495,
			10.54,
			10.556,
			10.617,
			10.652,
			10.679,
			10.756,
			10.803,
			10.864,
			10.928,
			10.974,
			10.989,
			11.051,
			11.088,
			11.113,
			11.176,
			11.189,
			11.236,
			11.301,
			11.362,
			11.408,
			11.485,
			11.515,
			11.549,
			11.61,
			11.671,
			11.732,
			11.796,
			11.841,
			11.856,
			11.92,
			11.951,
			11.98,
			12.057,
			12.104,
			12.168,
			12.23,
			12.277,
			12.353,
			12.383,
			12.416,
			12.476,
			12.493,
			12.54,
			12.607,
			12.664,
			12.71,
			12.788,
			12.818,
			12.851,
			12.926,
			12.973,
			13.035,
			13.097,
			13.16,
			13.221,
			13.253,
			13.283,
			13.346,
			13.361,
			13.407,
			13.471,
			13.53,
			13.577,
			13.593,
			13.657,
			13.688,
			13.719,
			13.795,
			13.84,
			13.903,
			13.965,
			14.013,
			14.028,
			14.09,
			14.121,
			14.151,
			14.215,
			14.229,
			14.275,
			14.338,
			14.398,
			14.447,
			14.46,
			14.523,
			14.554,
			14.584,
			14.649,
			14.663,
			14.71,
			14.773,
			14.833,
			14.881,
			14.958,
			14.99,
			15.019,
			15.082,
			15.145,
			15.212,
			15.267,
			15.313,
			15.331,
			15.393,
			15.423,
			15.453,
			15.517,
			15.532,
			15.576,
			15.64,
			15.703,
			15.749,
			15.764,
			15.826,
			15.856,
			15.888,
			15.951,
			15.965,
			16.013,
			16.075,
			16.137,
			16.181,
			16.261,
			16.291,
			16.321,
			16.383,
			16.447,
			16.508,
			16.569,
			16.616,
			16.63,
			16.693,
			16.724,
			16.756,
			16.818,
			16.832,
			16.88,
			16.943,
			17.004,
			17.065,
			17.127,
			17.16,
			17.189,
			17.253,
			17.313,
			17.376,
			17.438,
			17.485,
			17.563,
			17.591,
			17.623,
			17.686,
			17.703,
			17.747,
			17.815,
			17.873,
			17.934,
			17.996,
			18.026,
			18.057,
			18.136,
			18.182,
			18.245,
			18.305,
			18.352,
			18.43,
			18.461,
			18.493,
			18.57,
			18.616,
			18.679,
			18.74,
			18.786,
			18.803,
			18.864,
			18.896,
			18.925,
			19.004,
			19.05,
			19.112,
			19.175,
			19.221,
			19.237,
			19.298,
			19.333,
			19.36,
			19.437,
			19.484,
			19.547,
			19.607,
			19.654,
			19.731,
			19.763,
			19.795,
			19.856,
			19.917,
			19.981,
			20.041,
			20.089,
			20.103,
			20.165,
			20.197,
			20.229,
			20.289,
			20.305,
			20.351,
			20.415,
			20.476,
			20.523,
			20.537,
			20.6,
			20.632,
			20.66,
			20.723,
			20.739,
			20.787,
			20.849,
			20.91,
			20.973,
			21.035,
			21.066,
			21.096,
			21.159,
			21.219,
			21.283,
			21.343,
			21.405,
			21.468,
			21.498,
			21.531,
			21.593,
			21.606,
			21.655,
			21.716,
			21.779,
			21.825,
			21.841,
			21.902,
			21.932,
			21.964,
			22.025,
			22.042,
			22.087,
			22.15,
			22.213,
			22.274,
			22.337,
			22.365,
			22.399,
			22.476,
			22.522,
			22.584,
			22.645,
			22.708,
			22.771,
			22.8,
			22.832,
			22.91,
			22.954,
			23.017,
			23.08,
			23.141,
			23.205,
			23.234,
			23.266,
			23.343,
			23.389,
			23.458,
			23.513,
			23.576,
			23.637,
			23.669,
			23.701,
			23.763,
			23.777,
			23.824,
			23.887,
			23.947,
			23.995,
			24.01,
			24.072,
			24.103,
			24.135,
			24.197,
			24.211,
			24.259,
			24.321,
			24.383,
			24.427,
			24.506,
			24.536,
			24.569,
			24.63,
			24.693,
			24.755,
			24.817,
			24.863,
			24.941,
			24.97,
			25.002,
			25.065,
			25.08,
			25.126,
			25.187,
			25.249,
			25.297,
			25.312,
			25.374,
			25.405,
			25.436,
			25.498,
			25.519,
			25.561,
			25.621,
			25.685,
			25.731,
			25.808,
			25.839,
			25.871,
			25.932,
			25.995,
			26.057,
			26.118,
			26.165,
			26.18,
			26.243,
			26.273,
			26.304,
			26.364,
			26.381,
			26.427,
			26.489,
			26.553,
			26.598,
			26.614,
			26.675,
			26.707,
			26.738,
			26.799,
			26.815,
			26.863,
			26.929,
			26.986,
			27.033,
			27.109,
			27.141,
			27.171,
			27.235,
			27.296,
			27.359,
			27.421,
			27.468,
			27.482,
			27.543,
			27.58,
			27.606,
			27.669,
			27.682,
			27.73,
			27.791,
			27.855,
			27.901,
			27.915,
			27.979,
			28.008,
			28.039,
			28.103,
			28.165,
			28.226,
			28.287,
			28.336,
			28.413,
			28.443,
			28.473,
			28.536,
			28.552,
			28.597,
			28.66,
			28.723,
			28.784,
			28.847,
			28.875,
			28.908,
			28.969,
			28.986,
			29.032,
			29.096,
			29.155,
			29.219,
			29.28,
			29.311,
			29.342,
			29.404,
			29.465,
			29.529,
			29.59,
			29.641,
			29.654,
			29.715,
			29.745,
			29.777,
			29.855,
			29.9,
			29.964,
			30.024,
			30.071,
			30.087,
			30.148,
			30.179,
			30.21,
			30.287,
			30.336,
			30.397,
			30.459,
			30.505,
			30.584,
			30.613,
			30.643,
			30.705,
			30.767,
			30.831,
			30.894,
			30.94,
			30.955,
			31.016,
			31.048,
			31.08,
			31.139,
			31.156,
			31.203,
			31.265,
			31.326,
			31.373,
			31.389,
			31.449,
			31.481,
			31.512,
			31.589,
			31.636,
			31.703,
			31.762,
			31.807,
			31.885,
			31.914,
			31.947,
			32.025,
			32.07,
			32.134,
			32.196,
			32.241,
			32.257,
			32.32,
			32.35,
			32.381,
			32.443,
			32.459,
			32.505,
			32.567,
			32.629,
			32.675,
			32.692,
			32.753,
			32.784,
			32.815,
			32.876,
			32.893,
			32.938,
			32.999,
			33.002,
			33.063,
			33.11,
			33.186,
			33.219,
			33.248,
			33.309,
			33.373,
			33.436,
			33.498,
			33.542,
			33.558,
			33.62,
			33.653,
			33.683,
			33.746,
			33.765,
			33.807,
			33.87,
			33.932,
			33.978,
			34.054,
			34.086,
			34.118,
			34.179,
			34.24,
			34.304,
			34.366,
			34.413,
			34.489,
			34.52,
			34.551,
			34.613,
			34.63,
			34.676,
			34.737,
			34.798,
			34.846,
			34.862,
			34.922,
			34.955,
			34.986,
			35.047,
			35.063,
			35.109,
			35.171,
			35.232,
			35.296,
			35.357,
			35.388,
			35.421,
			35.482,
			35.542,
			35.606,
			35.668,
			35.715,
			35.73,
			35.793,
			35.854,
			35.915,
			35.932,
			35.978,
			36.04,
			36.101,
			36.148,
			36.162,
			36.224,
			36.256,
			36.287,
			36.351,
			36.364,
			36.412,
			36.473,
			36.536,
			36.583,
			36.66,
			36.691,
			36.721,
			36.783,
			36.847,
			36.907,
			36.971,
			37.015,
			37.032,
			37.092,
			37.124,
			37.156,
			37.233,
			37.28,
			37.343,
			37.405,
			37.466,
			37.528,
			37.559,
			37.59,
			37.652,
			37.715,
			37.776,
			37.837,
			37.901,
			37.963,
			37.993,
			38.023,
			38.103,
			38.147,
			38.212,
			38.273,
			38.317,
			38.334,
			38.397,
			38.427,
			38.457,
			38.521,
			38.536,
			38.582,
			38.643,
			38.707,
			38.752,
			38.83,
			38.86,
			38.891,
			38.955,
			39.017,
			39.079,
			39.139,
			39.186,
			39.202,
			39.265,
			39.296,
			39.324,
			39.387,
			39.403,
			39.45,
			39.51,
			39.575,
			39.637,
			39.699,
			39.729,
			39.758,
			39.838,
			39.885,
			39.95,
			40.008,
			40.07,
			40.132,
			40.163,
			40.192,
			40.257,
			40.319,
			40.38,
			40.443,
			40.489,
			40.504,
			40.565,
			40.599,
			40.629,
			40.69,
			40.706,
			40.753,
			40.814,
			40.876,
			40.924,
			40.94,
			40.999,
			41.032,
			41.063,
			41.124,
			41.14,
			41.187,
			41.25,
			41.309,
			41.356,
			41.435,
			41.465,
			41.497,
			41.558,
			41.62,
			41.684,
			41.745,
			41.791,
			41.806,
			41.869,
			41.899,
			41.932,
			42.014,
			42.053,
			42.117,
			42.179,
			42.226,
			42.239,
			42.304,
			42.333,
			42.366,
			42.425,
			42.441,
			42.49,
			42.549,
			42.613,
			42.659,
			42.737,
			42.767,
			42.799,
			42.876,
			42.921,
			42.984,
			43.048,
			43.092,
			43.109,
			43.172,
			43.203,
			43.232,
			43.295,
			43.31,
			43.357,
			43.417,
			43.482,
			43.526,
			43.543,
			43.606,
			43.634,
			43.667,
			43.727,
			43.79,
			43.854,
			43.915,
			43.962,
			44.039,
			44.075,
			44.099,
			44.163,
			44.18,
			44.226,
			44.288,
			44.348,
			44.395,
			44.412,
			44.473,
			44.503,
			44.536,
			44.598,
			44.614,
			44.659,
			44.722,
			44.783,
			44.83,
			44.906,
			44.939,
			44.969,
			45.031,
			45.093,
			45.156,
			45.217,
			45.28,
			45.34,
			45.373,
			45.403,
			45.465,
			45.481,
			45.526,
			45.588,
			45.651,
			45.699,
			45.713,
			45.776,
			45.806,
			45.837,
			45.898,
			45.915,
			45.962,
			46.024,
			46.085,
			46.135,
			46.208,
			46.241,
			46.271,
			46.332,
			46.396,
			46.458,
			46.52,
			46.567,
			46.581,
			46.643,
			46.675,
			46.707,
			46.768,
			46.782,
			46.83,
			46.896,
			46.953,
			47,
			47.016,
			47.078,
			47.107,
			47.139,
			47.218,
			47.263,
			47.327,
			47.387,
			47.448,
			47.513,
			47.541,
			47.574,
			47.635,
			47.698,
			47.759,
			47.822,
			47.869,
			47.882,
			47.943,
			47.975,
			48.007,
			48.07,
			48.085,
			48.132,
			48.194,
			48.254,
			48.302,
			48.38,
			48.408,
			48.44,
			48.503,
			48.565,
			48.626,
			48.689,
			48.734,
			48.813,
			48.845,
			48.875,
			48.939,
			48.953,
			49,
			49.06,
			49.123,
			49.17,
			49.186,
			49.248,
			49.28,
			49.308,
			49.373,
			49.387,
			49.432,
			49.496,
			49.556,
			49.605,
			49.681,
			49.713,
			49.744,
			49.822,
			49.866,
			49.93,
			49.991,
			50.037,
			50.054,
			50.114,
			50.148,
			50.178,
			50.24,
			50.258,
			50.303,
			50.365,
			50.426,
			50.471,
			50.487,
			50.551,
			50.58,
			50.61,
			50.674,
			50.689,
			50.738,
			50.798,
			50.859,
			50.906,
			50.985,
			51.016,
			51.045,
			51.108,
			51.169,
			51.231,
			51.292,
			51.357,
			51.417,
			51.448,
			51.479,
			51.541,
			51.558,
			51.604,
			51.665,
			51.729,
			51.773,
			51.789,
			51.853,
			51.882,
			51.915,
			51.977,
			52.039,
			52.101,
			52.163,
			52.225,
			52.286,
			52.321,
			52.348,
			52.409,
			52.472,
			52.534,
			52.596,
			52.643,
			52.659,
			52.721,
			52.75,
			52.781,
			52.845,
			52.858,
			52.907,
			52.969,
			53.029,
			53.075,
			53.093,
			53.153,
			53.186,
			53.215,
			53.279,
			53.339,
			53.403,
			53.465,
			53.509,
			53.588,
			53.619,
			53.65,
			53.713,
			53.774,
			53.837,
			53.897,
			53.946,
			53.958,
			54.021,
			54.052,
			54.083,
			54.147,
			54.16,
			54.209,
			54.269,
			54.332,
			54.384,
			54.455,
			54.488,
			54.519,
			54.596,
			54.642,
			54.705,
			54.767,
			54.829,
			54.89,
			54.921,
			54.953,
			55.015,
			55.032,
			55.075,
			55.138,
			55.199,
			55.247,
			55.263,
			55.325,
			55.356,
			55.386,
			55.463,
			55.51,
			55.573,
			55.635,
			55.696,
			55.757,
			55.791,
			55.82,
			55.899,
			55.943,
			56.007,
			56.069,
			56.114,
			56.131,
			56.191,
			56.225,
			56.255,
			56.317,
			56.333,
			56.378,
			56.442,
			56.502,
			56.548,
			56.565,
			56.626,
			56.657,
			56.689,
			56.75,
			56.764,
			56.813,
			56.875,
			56.936,
			56.983,
			57.059,
			57.091,
			57.121,
			57.185,
			57.248,
			57.309,
			57.372,
			57.416,
			57.432,
			57.495,
			57.525,
			57.556,
			57.62,
			57.634,
			57.68,
			57.743,
			57.805,
			57.851,
			57.865,
			57.929,
			57.96,
			57.99,
			58.052,
			58.115,
			58.175,
			58.239,
			58.287,
			58.363,
			58.396,
			58.425,
			58.487,
			58.506,
			58.548,
			58.61,
			58.674,
			58.718,
			58.735,
			58.797,
			58.829,
			58.859,
			58.921,
			58.936,
			58.983,
			59.046,
			59.107,
			59.154,
			59.231,
			59.262,
			59.293,
			59.356,
			59.417,
			59.484,
			59.54,
			59.587,
			59.603,
			59.664,
			59.695,
			59.728,
			59.789,
			59.806,
			59.851,
			59.914,
			59.974,
			60.021,
			60.038,
			60.098,
			60.131,
			60.161,
			60.224,
			60.238,
			60.285,
			60.347,
			60.41,
			60.456,
			60.533,
			60.569,
			60.595,
			60.656,
			60.719,
			60.781,
			60.842,
			60.905,
			60.967,
			60.999,
			61.03,
			61.091,
			61.108,
			61.155,
			61.217,
			61.279,
			61.324,
			61.338,
			61.402,
			61.432,
			61.465,
			61.524,
			61.54,
			61.587,
			61.65,
			61.71,
			61.758,
			61.837,
			61.867,
			61.897,
			61.96,
			62.025,
			62.083,
			62.146,
			62.191,
			62.208,
			62.269,
			62.3,
			62.33,
			62.41,
			62.457,
			62.518,
			62.58,
			62.63,
			62.704,
			62.734,
			62.769,
			62.841,
			62.889,
			62.95,
			63.013,
			63.059,
			63.138,
			63.169,
			63.2,
			63.277,
			63.323,
			63.385,
			63.449,
			63.495,
			63.511,
			63.571,
			63.603,
			63.633,
			63.711,
			63.759,
			63.821,
			63.881,
			63.943,
			64.007,
			64.037,
			64.069,
			64.131,
			64.191,
			64.255,
			64.317,
			64.364,
			64.378,
			64.441,
			64.472,
			64.502,
			64.565,
			64.58,
			64.626,
			64.689,
			64.751,
			64.797,
			64.81,
			64.875,
			64.905,
			64.937,
			64.999,
			65.015,
			65.06,
			65.122,
			65.185,
			65.23,
			65.307,
			65.338,
			65.369,
			65.432,
			65.493,
			65.556,
			65.619,
			65.665,
			65.681,
			65.743,
			65.773,
			65.805,
			65.867,
			65.881,
			65.929,
			65.989,
			66.052,
			66.098,
			66.114,
			66.176,
			66.207,
			66.238,
			66.3,
			66.315,
			66.362,
			66.426,
			66.486,
			66.548,
			66.609,
			66.643,
			66.674,
			66.735,
			66.756,
			66.797,
			66.859,
			66.921,
			66.967,
			66.982,
			67.043,
			67.074,
			67.106,
			67.169,
			67.185,
			67.231,
			67.293,
			67.354,
			67.401,
			67.417,
			67.479,
			67.509,
			67.539,
			67.601,
			67.618,
			67.665,
			67.726,
			67.79,
			67.849,
			67.913,
			67.943,
			67.976,
			68.037,
			68.053,
			68.099,
			68.161,
			68.221,
			68.269,
			68.283,
			68.347,
			68.378,
			68.407,
			68.487,
			68.533,
			68.596,
			68.657,
			68.705,
			68.78,
			68.818,
			68.843,
			68.906,
			68.966,
			69.03,
			69.091,
			69.137,
			69.153,
			69.215,
			69.245,
			69.277,
			69.34,
			69.354,
			69.401,
			69.464,
			69.526,
			69.587,
			69.648,
			69.681,
			69.709,
			69.772,
			69.79,
			69.834,
			69.897,
			69.96,
			70.006,
			70.083,
			70.114,
			70.146,
			70.206,
			70.27,
			70.33,
			70.394,
			70.44,
			70.456,
			70.517,
			70.549,
			70.58,
			70.64,
			70.656,
			70.702,
			70.765,
			70.826,
			70.889,
			70.951,
			70.982,
			71.013,
			71.075,
			71.091,
			71.137,
			71.199,
			71.26,
			71.309,
			71.385,
			71.416,
			71.446,
			71.509,
			71.571,
			71.632,
			71.696,
			71.756,
			71.818,
			71.849,
			71.881,
			71.943,
			71.959,
			72.006,
			72.069,
			72.13,
			72.176,
			72.191,
			72.254,
			72.285,
			72.316,
			72.376,
			72.393,
			72.441,
			72.501,
			72.522
		]
	],
	'1683_1684_7982': [
		[
			94.033
		],
		[
			0
		],
		[
			0,
			2.06,
			3.447,
			6.227,
			9.016,
			13.213,
			16.019,
			20.241,
			21.652,
			23.065,
			27.313,
			30.154,
			34.429,
			37.287,
			40.152,
			44.463,
			45.903,
			48.787,
			51.677,
			53.124,
			54.573,
			58.928,
			61.84,
			64.757,
			66.218,
			69.145,
			72.079,
			73.549,
			76.494,
			77.969,
			80.925,
			83.887,
			88.344,
			91.324,
			95.807,
			97.305,
			98.804,
			103.311,
			106.323,
			110.854,
			113.884,
			118.444,
			121.493,
			123.02,
			126.078,
			129.145,
			130.68,
			133.756,
			136.838,
			139.925,
			144.552,
			147.617,
			150.653,
			152.157,
			155.135,
			156.608,
			159.526,
			162.403,
			166.645,
			169.424,
			173.521,
			174.868,
			176.205,
			180.16,
			182.751,
			186.566,
			189.061,
			191.519,
			192.732,
			195.133,
			196.321,
			198.67,
			200.986,
			202.129,
			204.381,
			206.597,
			209.831,
			210.89,
			211.94,
			215.038,
			216.046,
			217.046,
			219.024,
			220.001,
			221.928,
			223.825,
			226.609,
			228.431,
			231.127,
			232.016,
			232.901,
			235.546,
			237.307,
			239.946,
			241.712,
			244.378,
			246.165,
			247.062,
			248.865,
			250.68,
			251.59,
			253.417,
			255.263,
			258.044,
			259.907,
			262.717,
			263.651,
			264.583,
			267.382,
			269.23,
			271.977,
			273.789,
			275.594,
			276.496,
			278.291,
			279.184,
			280.078,
			282.792,
			283.693,
			284.592,
			287.346,
			289.182,
			291.044,
			291.988,
			293.875,
			295.766,
			296.719,
			298.625,
			299.579,
			301.511,
			303.465,
			306.419,
			308.41,
			311.405,
			312.401,
			313.399,
			316.402,
			318.395,
			321.391,
			323.342,
			325.306,
			326.278,
			328.157,
			329.101,
			330.998,
			332.849,
			333.777,
			335.64,
			337.505,
			339.376,
			341.259,
			342.21,
			344.123,
			346.048,
			347.016,
			348.98,
			349.965,
			351.945,
			354.96,
			356.986,
			359.031,
			362.123,
			363.161,
			364.202,
			367.329,
			369.411,
			372.548,
			374.653,
			377.829,
			379.964,
			381.034,
			383.183,
			385.349,
			386.435,
			388.615,
			390.807,
			394.115,
			395.222,
			396.332,
			398.56,
			400.789,
			401.907,
			405.255,
			407.508,
			409.771,
			413.18,
			415.465,
			418.909,
			420.063,
			421.22,
			424.706,
			427.044,
			430.57,
			432.935,
			436.501,
			438.892,
			441.292,
			442.497,
			444.912,
			446.123,
			448.553,
			450.992,
			454.668,
			457.13,
			460.838,
			462.079,
			463.322,
			467.064,
			469.569,
			473.341,
			475.867,
			478.4,
			479.67,
			482.216,
			483.492,
			486.044,
			488.586,
			489.851,
			491.109,
			494.838,
			497.278,
			499.681,
			500.868,
			503.214,
			505.518,
			506.656,
			508.904,
			510.012,
			512.219,
			514.372,
			517.538,
			519.606,
			522.634,
			523.627,
			524.612,
			527.524,
			529.425,
			532.234,
			534.083,
			535.915,
			536.827,
			538.644,
			539.55,
			541.341,
			544.016,
			545.795,
			547.566,
			549.336,
			551.11,
			552.001,
			553.782,
			555.562,
			556.467,
			558.277,
			559.181,
			560.991,
			562.804,
			565.522,
			567.327,
			570.031,
			570.933,
			571.839,
			574.563,
			576.38,
			579.128,
			580.96,
			582.793,
			583.708,
			585.538,
			586.453,
			588.296,
			590.136,
			591.056,
			592.938,
			594.848,
			597.712,
			599.622,
			602.483,
			603.435,
			604.388,
			607.402,
			609.542,
			611.643,
			614.651,
			616.643,
			619.533,
			620.496,
			621.46,
			624.33,
			626.244,
			629.146,
			631.083,
			633.011,
			633.973,
			635.897,
			636.857,
			638.76,
			640.664,
			641.617,
			643.507,
			645.398,
			648.224,
			649.161,
			650.1,
			652.909,
			653.837,
			654.766,
			657.555,
			659.403,
			662.185,
			664.042,
			665.904,
			668.73,
			669.685,
			670.641,
			673.534,
			674.512,
			675.491,
			678.438,
			680.411,
			683.378,
			685.359,
			687.346,
			688.344,
			690.331,
			691.325,
			693.318,
			695.307,
			698.296,
			700.314,
			703.362,
			704.384,
			705.42,
			708.537,
			710.638,
			713.813,
			715.924,
			719.082,
			721.178,
			722.221,
			723.263,
			726.394,
			727.443,
			728.49,
			731.637,
			733.744,
			735.854,
			736.914,
			739.039,
			741.171,
			742.233,
			744.365,
			745.434,
			747.57,
			749.709,
			752.934,
			755.095,
			758.365,
			759.463,
			760.564,
			763.905,
			766.158,
			769.581,
			771.882,
			774.211,
			775.382,
			777.733,
			778.914,
			781.285,
			783.668,
			784.868,
			787.275,
			789.709,
			793.406,
			794.649,
			795.896,
			798.396,
			800.921,
			802.189,
			806.007,
			808.561,
			811.121,
			814.975,
			817.566,
			821.465,
			822.763,
			824.062,
			827.946,
			830.526,
			834.391,
			836.975,
			839.583,
			840.89,
			843.513,
			846.147,
			847.466,
			850.1,
			851.42,
			854.053,
			856.676,
			860.596,
			861.9,
			863.206,
			867.1,
			868.398,
			869.694,
			873.593,
			876.195,
			880.112,
			882.734,
			885.364,
			889.32,
			890.638,
			891.956,
			895.907,
			897.221,
			898.535,
			902.489,
			905.135,
			909.134,
			911.811,
			914.494,
			915.843,
			918.55,
			919.906,
			922.617,
			925.332,
			929.419,
			932.189,
			936.453,
			937.88,
			939.315,
			943.59,
			946.38,
			950.586,
			953.403,
			956.232,
			957.649,
			960.489,
			961.912,
			964.761,
			969.047,
			970.479,
			974.784,
			979.095,
			980.531,
			981.967,
			984.865,
			987.714,
			989.153,
			992.023,
			993.452,
			996.295,
			999.109,
			1003.29,
			1005.993,
			1010.024,
			1011.353,
			1012.673,
			1016.578,
			1019.137,
			1022.827,
			1025.243,
			1027.624,
			1028.806,
			1031.146,
			1032.304,
			1034.589,
			1036.834,
			1037.962,
			1040.153,
			1042.309,
			1045.491,
			1046.538,
			1047.579,
			1050.659,
			1051.676,
			1052.689,
			1055.698,
			1057.699,
			1059.694,
			1062.687,
			1064.683,
			1067.677,
			1068.675,
			1069.673,
			1072.667,
			1074.678,
			1077.699,
			1079.733,
			1082.791,
			1084.85,
			1085.881,
			1087.944,
			1090.026,
			1091.069,
			1093.154,
			1095.239,
			1098.364,
			1100.445,
			1103.572,
			1104.625,
			1105.678,
			1108.844,
			1110.97,
			1114.155,
			1116.271,
			1118.387,
			1121.562,
			1122.62,
			1123.682,
			1126.887,
			1127.955,
			1129.03,
			1132.263,
			1134.417,
			1136.568,
			1137.644,
			1139.789,
			1141.93,
			1143.002,
			1146.216,
			1148.363,
			1150.495,
			1153.698,
			1155.819,
			1159.013,
			1160.076,
			1161.142,
			1164.352,
			1166.498,
			1169.735,
			1171.902,
			1175.167,
			1177.352,
			1178.447,
			1180.634,
			1182.824,
			1183.922,
			1186.105,
			1188.29,
			1190.468,
			1192.64,
			1193.726,
			1195.889,
			1198.05,
			1199.13,
			1201.297,
			1202.381,
			1204.554,
			1206.763,
			1210.104,
			1212.381,
			1215.828,
			1216.989,
			1218.152,
			1221.609,
			1223.926,
			1227.375,
			1229.678,
			1231.98,
			1233.134,
			1235.453,
			1236.619,
			1238.958,
			1241.313,
			1242.496,
			1244.87,
			1247.258,
			1249.656,
			1252.065,
			1253.272,
			1255.695,
			1258.126,
			1259.345,
			1261.79,
			1263.016,
			1265.473,
			1267.94,
			1271.656,
			1274.143,
			1277.891,
			1279.144,
			1280.399,
			1284.179,
			1286.708,
			1290.518,
			1293.068,
			1295.626,
			1296.908,
			1299.477,
			1302.054,
			1303.346,
			1307.233,
			1309.833,
			1312.44,
			1316.35,
			1318.964,
			1322.898,
			1324.212,
			1325.529,
			1329.488,
			1332.136,
			1334.791,
			1336.121,
			1338.786,
			1341.458,
			1345.478,
			1346.821,
			1348.166,
			1352.212,
			1353.564,
			1354.917,
			1358.988,
			1361.71,
			1364.438,
			1365.805,
			1368.543,
			1371.288,
			1372.663,
			1376.798,
			1379.563,
			1382.336,
			1386.508,
			1389.298,
			1393.494,
			1394.896,
			1396.299,
			1400.518,
			1403.339,
			1407.581,
			1410.417,
			1413.259,
			1414.682,
			1417.534,
			1418.962,
			1421.823,
			1426.125,
			1429.001,
			1431.884,
			1434.774,
			1439.118,
			1442.023,
			1444.935,
			1446.393,
			1449.314,
			1450.777,
			1453.707,
			1456.643,
			1461.059,
			1464.01,
			1468.449,
			1469.932,
			1471.416,
			1475.878,
			1478.86,
			1483.344,
			1486.34,
			1490.846,
			1493.857,
			1495.365,
			1498.384,
			1501.41,
			1502.924,
			1505.958,
			1508.998,
			1513.568,
			1515.094,
			1516.621,
			1521.212,
			1522.745,
			1524.279,
			1528.89,
			1531.971,
			1535.058,
			1536.603,
			1539.698,
			1542.799,
			1547.46,
			1549.016,
			1550.573,
			1555.252,
			1558.378,
			1563.077,
			1566.217,
			1570.935,
			1574.086,
			1575.664,
			1578.823,
			1583.569,
			1586.741,
			1589.917,
			1594.69,
			1597.875,
			1602.655,
			1604.248,
			1605.839,
			1609.012,
			1610.591,
			1613.728,
			1616.833,
			1621.42,
			1624.434,
			1628.886,
			1630.351,
			1631.807,
			1637.532,
			1638.939,
			1643.103,
			1645.831,
			1649.851,
			1652.483,
			1655.078,
			1656.36,
			1660.152,
			1662.634,
			1665.078,
			1668.677,
			1671.041,
			1674.523,
			1675.667,
			1676.8,
			1680.142,
			1682.319,
			1685.507,
			1687.581,
			1689.616,
			1690.619,
			1692.597,
			1693.572,
			1694.538,
			1697.384,
			1698.315,
			1699.236,
			1701.951,
			1703.719,
			1705.453,
			1706.306,
			1707.986,
			1709.628,
			1710.436,
			1712.024,
			1712.804,
			1714.337,
			1715.835,
			1718.037,
			1719.469,
			1721.583,
			1722.28,
			1722.975,
			1725.044,
			1726.412,
			1728.454,
			1729.802,
			1731.13,
			1731.792,
			1733.114,
			1734.435,
			1735.092,
			1736.403,
			1737.056,
			1738.364,
			1739.732,
			1741.973,
			1742.719,
			1743.465,
			1745.108,
			1746.956,
			1747.88,
			1750.748,
			1752.733,
			1754.647,
			1757.056,
			1758.665,
			1760.714,
			1761.35,
			1761.977,
			1763.898,
			1765.225,
			1767.274,
			1768.66,
			1770.149,
			1770.944,
			1772.546,
			1773.353,
			1775.084,
			1777.974,
			1779.978,
			1782.07,
			1785.112,
			1785.979,
			1786.85,
			1789.486,
			1790.271,
			1791.068,
			1793.43,
			1795.031,
			1797.45,
			1799.06,
			1800.697,
			1803.184,
			1804.02,
			1804.861,
			1807.447,
			1809.187,
			1811.86,
			1813.666,
			1816.428,
			1818.301,
			1820.188,
			1821.142,
			1823.059,
			1824.022,
			1825.949,
			1827.878,
			1830.784,
			1832.716,
			1835.616,
			1836.587,
			1837.561,
			1840.501,
			1842.48,
			1845.472,
			1847.49,
			1849.518,
			1852.586,
			1853.615,
			1855.682,
			1857.767,
			1858.813,
			1860.915,
			1863.024,
			1865.131,
			1867.242,
			1868.3,
			1870.424,
			1872.555,
			1873.624,
			1876.841,
			1878.995,
			1881.157,
			1882.241,
			1884.415,
			1886.597,
			1889.886,
			1890.986,
			1892.089,
			1895.411,
			1897.639,
			1900.996,
			1903.252,
			1906.651,
			1908.929,
			1910.071,
			1912.359,
			1914.654,
			1915.805,
			1918.108,
			1920.421,
			1922.739,
			1926.248,
			1928.559,
			1930.898,
			1932.069,
			1934.414,
			1935.589,
			1937.942,
			1940.304,
			1943.862,
			1946.245,
			1949.835,
			1951.036,
			1952.239,
			1955.861,
			1958.288,
			1961.948,
			1964.4,
			1966.862,
			1968.097,
			1970.573,
			1971.815,
			1974.307,
			1976.807,
			1978.062,
			1980.577,
			1983.1,
			1986.897,
			1989.443,
			1993.282,
			1994.567,
			1995.853,
			1999.728,
			2002.322,
			2004.923,
			2008.828,
			2011.442,
			2015.402,
			2016.698,
			2018.016,
			2023.303,
			2024.629,
			2028.64,
			2031.309,
			2033.987,
			2035.329,
			2038.02,
			2039.368,
			2042.073,
			2044.786,
			2046.145,
			2048.869,
			2051.601,
			2055.711,
			2058.463,
			2062.609,
			2064.001,
			2065.396,
			2069.603,
			2072.426,
			2076.683,
			2079.533,
			2082.394,
			2083.826,
			2086.697,
			2091.011,
			2093.895,
			2095.339,
			2096.785,
			2101.129,
			2104.032,
			2106.936,
			2108.39,
			2111.309,
			2114.235,
			2115.704,
			2118.647,
			2120.123,
			2123.082,
			2126.053,
			2130.527,
			2133.522,
			2138.027,
			2139.532,
			2141.01,
			2145.526,
			2148.539,
			2153.06,
			2156.098,
			2159.108,
			2160.611,
			2163.61,
			2165.107,
			2168.096,
			2171.066,
			2172.542,
			2175.447,
			2178.342,
			2181.199,
			2185.416,
			2188.202,
			2190.922,
			2192.267,
			2194.931,
			2196.249,
			2198.86,
			2201.439,
			2205.229,
			2207.73,
			2211.438,
			2212.649,
			2213.851,
			2217.411,
			2219.743,
			2223.157,
			2225.404,
			2227.617,
			2228.71,
			2230.871,
			2231.939,
			2234.052,
			2236.131,
			2237.157,
			2239.181,
			2241.167,
			2244.075,
			2245.966,
			2248.731,
			2249.636,
			2250.532,
			2253.168,
			2254.882,
			2257.384,
			2259.006,
			2260.593,
			2262.909,
			2263.663,
			2264.407,
			2266.594,
			2267.307,
			2268.01,
			2270.068,
			2271.405,
			2272.709,
			2273.349,
			2274.613,
			2275.831,
			2276.427,
			2278.162,
			2279.272,
			2280.351,
			2281.914,
			2282.922,
			2284.39,
			2284.87,
			2285.346,
			2286.735,
			2287.645,
			2288.993,
			2289.887,
			2290.775,
			2292.092,
			2292.529,
			2293.404,
			2294.279,
			2294.717,
			2295.156,
			2296.575,
			2297.532,
			2298.97,
			2299.936,
			2300.982,
			2301.539,
			2303.214,
			2304.34,
			2305.481,
			2307.249,
			2308.437,
			2310.238,
			2310.821,
			2311.393,
			2313.124,
			2314.292,
			2316.031,
			2317.153,
			2318.288,
			2318.861,
			2320.01,
			2320.587,
			2321.672,
			2322.782,
			2323.337,
			2323.897,
			2325.598,
			2327.347,
			2327.935,
			2328.525,
			2329.714,
			2331,
			2331.729,
			2333.191,
			2333.925,
			2335.399,
			2337.429,
			2340.551,
			2342.99,
			2346.29,
			2347.111,
			2347.929,
			2350.366,
			2351.644,
			2353.479,
			2354.697,
			2356.538,
			2357.775,
			2358.393,
			2359.629,
			2361.507,
			2362.778,
			2364.051,
			2365.969,
			2367.264,
			2369.198,
			2369.848,
			2370.51,
			2372.504,
			2373.847,
			2375.202,
			2377.282,
			2378.692,
			2380.838,
			2381.58,
			2382.326,
			2384.583,
			2385.343,
			2386.105,
			2388.396,
			2389.938,
			2391.66,
			2392.582,
			2394.436,
			2396.285,
			2397.172,
			2399.84,
			2401.641,
			2403.478,
			2406.236,
			2407.132,
			2408.029,
			2410.721,
			2411.59,
			2412.432,
			2414.958,
			2416.622,
			2419.033,
			2420.638,
			2422.222,
			2423.006,
			2424.573,
			2425.356,
			2426.141,
			2428.494,
			2429.278,
			2430.063,
			2432.425,
			2434.004,
			2435.587,
			2436.381,
			2437.978,
			2439.58,
			2440.383,
			2441.998,
			2442.81,
			2444.44,
			2446.077,
			2448.55,
			2450.21,
			2452.715,
			2453.55,
			2454.384,
			2456.888,
			2458.584,
			2461.149,
			2462.885,
			2464.636,
			2465.517,
			2467.291,
			2468.186,
			2469.984,
			2472.708,
			2474.542,
			2476.389,
			2478.25,
			2481.062,
			2482.937,
			2484.811,
			2485.748,
			2488.561,
			2490.415,
			2492.262,
			2495.023,
			2496.858,
			2499.599,
			2500.511,
			2501.423,
			2504.146,
			2505.961,
			2508.678,
			2510.489,
			2512.304,
			2513.215,
			2515.038,
			2515.951,
			2517.79,
			2519.641,
			2520.569,
			2522.433,
			2524.315,
			2527.157,
			2529.108,
			2532.055,
			2533.069,
			2534.087,
			2537.158,
			2539.237,
			2542.375,
			2544.47,
			2546.577,
			2549.727,
			2550.78,
			2551.836,
			2555.01,
			2557.138,
			2560.353,
			2562.507,
			2564.662,
			2565.738,
			2567.893,
			2568.971,
			2571.134,
			2573.303,
			2574.391,
			2576.572,
			2578.758,
			2582.048,
			2584.238,
			2587.536,
			2588.636,
			2589.737,
			2593.051,
			2595.265,
			2597.487,
			2600.825,
			2603.057,
			2606.4,
			2607.519,
			2608.636,
			2611.996,
			2613.123,
			2614.254,
			2617.659,
			2619.95,
			2622.248,
			2623.398,
			2625.697,
			2628.005,
			2629.159,
			2631.473,
			2632.634,
			2634.953,
			2637.302,
			2640.859,
			2643.245,
			2646.853,
			2648.061,
			2649.271,
			2652.916,
			2655.358,
			2659.038,
			2661.502,
			2663.974,
			2665.213,
			2667.697,
			2668.942,
			2671.437,
			2673.94,
			2675.194,
			2677.708,
			2680.23,
			2684.03,
			2686.578,
			2689.134,
			2691.7,
			2692.985,
			2695.561,
			2696.851,
			2699.436,
			2702.027,
			2705.928,
			2708.537,
			2712.464,
			2713.774,
			2715.085,
			2719.017,
			2721.643,
			2725.577,
			2728.198,
			2732.134,
			2734.759,
			2736.096,
			2738.728,
			2741.337,
			2742.654,
			2745.289,
			2747.915,
			2751.852,
			2754.48,
			2758.428,
			2759.748,
			2761.068,
			2763.711,
			2765.036,
			2767.718,
			2770.384,
			2774.389,
			2777.083,
			2781.14,
			2782.499,
			2783.859,
			2787.98,
			2790.701,
			2794.824,
			2797.578,
			2800.338,
			2801.718,
			2804.48,
			2805.865,
			2808.629,
			2812.808,
			2815.584,
			2818.345,
			2822.534,
			2825.337,
			2829.556,
			2830.966,
			2832.378,
			2836.628,
			2839.474,
			2842.326,
			2846.618,
			2849.488,
			2850.926,
			2853.804,
			2855.247,
			2856.69,
			2861.031,
			2862.481,
			2863.932,
			2868.294,
			2871.212,
			2874.135,
			2875.599,
			2878.532,
			2881.471,
			2882.943,
			2885.89,
			2887.363,
			2890.299,
			2893.215,
			2897.531,
			2900.362,
			2904.534,
			2905.906,
			2907.268,
			2911.297,
			2913.936,
			2917.823,
			2920.366,
			2924.11,
			2926.559,
			2927.77,
			2930.164,
			2932.523,
			2933.69,
			2934.848,
			2938.269,
			2940.501,
			2942.696,
			2943.779,
			2945.916,
			2948.016,
			2949.052,
			2951.096,
			2952.104,
			2954.093,
			2956.043,
			2958.9,
			2960.76,
			2963.482,
			2964.373,
			2965.255,
			2967.85,
			2969.535,
			2971.993,
			2973.586,
			2975.142,
			2975.907,
			2977.408,
			2978.146,
			2979.593,
			2981.003,
			2981.696,
			2983.055,
			2984.38,
			2986.304,
			2986.929,
			2987.545,
			2989.338,
			2989.918,
			2990.489,
			2991.609,
			2992.159,
			2993.242,
			2994.32,
			2995.897,
			2996.936,
			2998.484,
			2998.996,
			2999.505,
			3001.02,
			3001.522,
			3002.024,
			3003.529,
			3004.512,
			3005.97,
			3006.94,
			3007.425,
			3008.398,
			3009.392,
			3009.893,
			3010.894,
			3011.897,
			3013.411,
			3014.51,
			3016.22,
			3016.79,
			3017.361,
			3018.505,
			3019.097,
			3020.332,
			3021.563,
			3023.408,
			3024.625,
			3026.427,
			3027.026,
			3027.624,
			3030.031,
			3030.634,
			3032.439,
			3033.643,
			3034.893,
			3035.524,
			3036.784,
			3038.041,
			3038.67,
			3039.916,
			3040.534,
			3041.77,
			3043.008,
			3044.779,
			3045.874,
			3047.516,
			3048.064,
			3048.614,
			3050.204,
			3051.237,
			3052.79,
			3053.832,
			3054.905,
			3055.452,
			3056.55,
			3057.101,
			3058.208,
			3059.324,
			3059.906,
			3061.074,
			3062.249,
			3063.434,
			3064.632,
			3065.244,
			3066.474,
			3067.715,
			3068.34,
			3070.249,
			3071.55,
			3072.865,
			3074.867,
			3076.225,
			3078.292,
			3078.988,
			3079.689,
			3081.803,
			3083.239,
			3085.424,
			3086.9,
			3088.393,
			3089.145,
			3090.666,
			3091.435,
			3092.984,
			3094.55,
			3095.341,
			3096.938,
			3098.55,
			3101.001,
			3102.658,
			3104.343,
			3106.042,
			3106.904,
			3109.504,
			3111.245,
			3113.002,
			3115.665,
			3117.456,
			3120.167,
			3121.075,
			3121.984,
			3124.729,
			3126.567,
			3129.334,
			3131.189,
			3133.027,
			3133.958,
			3135.83,
			3136.76,
			3138.63,
			3140.511,
			3141.452,
			3143.337,
			3145.235,
			3148.095,
			3150.015,
			3152.923,
			3153.899,
			3154.878,
			3157.85,
			3159.854,
			3162.9,
			3164.954,
			3167.026,
			3170.182,
			3171.24,
			3172.302,
			3175.515,
			3176.588,
			3177.672,
			3180.852,
			3182.996,
			3186.229,
			3188.398,
			3190.579,
			3191.674,
			3193.869,
			3194.97,
			3197.18,
			3199.389,
			3202.718,
			3204.932,
			3208.269,
			3209.378,
			3210.489,
			3213.834,
			3216.069,
			3219.44,
			3221.699,
			3223.969,
			3225.11,
			3227.4,
			3228.548,
			3230.858,
			3233.177,
			3234.34,
			3235.507,
			3239.022,
			3241.375,
			3244.922,
			3247.296,
			3249.68,
			3250.875,
			3253.271,
			3254.473,
			3256.882,
			3259.301,
			3262.949,
			3265.393,
			3269.076,
			3270.309,
			3271.544,
			3275.261,
			3276.506,
			3277.752,
			3281.505,
			3284.018,
			3287.806,
			3290.342,
			3291.612,
			3294.16,
			3297.996,
			3300.564,
			3303.139,
			3307.017,
			3308.312,
			3309.61,
			3313.515,
			3314.819,
			3316.123,
			3318.731,
			3320.037,
			3322.656,
			3325.282,
			3329.234,
			3331.878,
			3335.856,
			3337.186,
			3338.517,
			3342.521,
			3345.2,
			3349.23,
			3351.925,
			3355.98,
			3358.693,
			3360.051,
			3362.773,
			3365.503,
			3366.87,
			3369.61,
			3372.356,
			3376.489,
			3377.87,
			3379.252,
			3383.41,
			3386.19,
			3388.977,
			3390.373,
			3393.17,
			3397.379,
			3400.193,
			3403.013,
			3407.255,
			3408.673,
			3410.092,
			3414.36,
			3417.214,
			3421.507,
			3424.377,
			3427.254,
			3428.695,
			3431.581,
			3434.473,
			3435.921,
			3440.276,
			3443.188,
			3446.105,
			3450.493,
			3453.426,
			3457.837,
			3459.31,
			3460.785,
			3465.219,
			3468.183,
			3472.633,
			3475.59,
			3478.527,
			3479.986,
			3482.877,
			3484.309,
			3485.731,
			3489.942,
			3491.327,
			3494.068,
			3496.771,
			3499.436,
			3503.364,
			3505.934,
			3508.465,
			3509.717,
			3512.192,
			3513.416,
			3515.834,
			3518.217,
			3521.725,
			3524.019,
			3527.387,
			3528.491,
			3529.585,
			3532.812,
			3534.916,
			3538.001,
			3540.011,
			3541.985,
			3542.958,
			3544.875,
			3545.821,
			3547.684,
			3550.413,
			3552.19,
			3553.933,
			3556.48,
			3558.132,
			3560.541,
			3561.325,
			3562.101,
			3563.624,
			3564.372,
			3565.841,
			3567.273,
			3569.354,
			3570.7,
			3572.655,
			3573.29,
			3573.917,
			3575.741,
			3576.913,
			3578.615,
			3579.698,
			3580.747,
			3581.261,
			3582.268,
			3582.762,
			3583.729,
			3584.673,
			3585.139,
			3586.059,
			3586.967,
			3588.311,
			3589.192,
			3590.508,
			3590.946,
			3591.384,
			3592.702,
			3593.624,
			3594.562,
			3595.971,
			3596.915,
			3598.378,
			3598.919,
			3599.461,
			3601.088,
			3601.632,
			3602.178,
			3603.898,
			3605.11,
			3606.322,
			3606.93,
			3608.152,
			3609.532,
			3610.23,
			3611.631,
			3612.334,
			3613.743,
			3615.139,
			3617.251,
			3618.612,
			3620.235,
			3620.781,
			3621.329,
			3622.99,
			3624.06,
			3625.632,
			3626.695,
			3627.762,
			3628.305,
			3629.441,
			3630.018,
			3631.185,
			3632.367,
			3632.964,
			3634.194,
			3635.46,
			3636.743,
			3638.705,
			3640.102,
			3641.504,
			3642.211,
			3644.41,
			3645.961,
			3647.52,
			3649.919,
			3651.552,
			3653.988,
			3654.771,
			3655.552,
			3657.894,
			3659.427,
			3661.675,
			3663.167,
			3664.641,
			3665.37,
			3666.821,
			3667.543,
			3668.975,
			3670.332,
			3671.006,
			3672.35,
			3673.688,
			3674.984,
			3676.259,
			3676.895,
			3678.164,
			3679.425,
			3680.047,
			3681.287,
			3681.906,
			3683.144,
			3684.383,
			3686.239,
			3687.475,
			3689.335,
			3689.964,
			3690.595,
			3692.49,
			3693.758,
			3695.669,
			3696.948,
			3698.232,
			3698.877,
			3700.171,
			3700.819,
			3702.121,
			3703.431,
			3704.09,
			3705.435,
			3706.811,
			3708.894,
			3709.594,
			3710.314,
			3712.522,
			3713.264,
			3714.01,
			3716.283,
			3717.824,
			3720.163,
			3721.722,
			3723.283,
			3725.669,
			3726.465,
			3727.265,
			3729.686,
			3730.5,
			3731.318,
			3733.795,
			3735.463,
			3737.143,
			3737.984,
			3739.667,
			3740.512,
			3742.215,
			3743.932,
			3744.795,
			3746.531,
			3748.28,
			3750.925,
			3752.704,
			3755.396,
			3756.299,
			3757.205,
			3759.943,
			3761.783,
			3764.572,
			3766.446,
			3768.334,
			3769.283,
			3771.19,
			3772.149,
			3773.109,
			3776.01,
			3776.983,
			3777.958,
			3780.901,
			3782.878,
			3784.865,
			3785.863,
			3787.867,
			3789.882,
			3790.895,
			3792.927,
			3793.948,
			3795.999,
			3798.061,
			3801.177,
			3803.268,
			3806.417,
			3807.466,
			3808.516,
			3811.674,
			3813.793,
			3816.987,
			3819.129,
			3821.28,
			3822.359,
			3824.525,
			3825.612,
			3827.792,
			3829.982,
			3831.081,
			3833.286,
			3835.5,
			3838.84,
			3839.958,
			3841.079,
			3843.328,
			3845.586,
			3846.719,
			3850.132,
			3852.42,
			3854.718,
			3858.182,
			3860.504,
			3864.004,
			3865.175,
			3866.349,
			3869.884,
			3872.253,
			3875.823,
			3878.215,
			3880.615,
			3881.819,
			3884.234,
			3885.446,
			3887.875,
			3890.314,
			3891.537,
			3893.99,
			3896.452,
			3900.162,
			3902.647,
			3906.391,
			3907.643,
			3908.897,
			3911.413,
			3912.674,
			3915.202,
			3917.74,
			3921.562,
			3924.12,
			3927.973,
			3929.262,
			3930.552,
			3935.734,
			3937.035,
			3940.945,
			3943.553,
			3947.477,
			3950.103,
			3951.419,
			3954.055,
			3958.023,
			3960.679,
			3963.342,
			3967.349,
			3970.03,
			3974.065,
			3975.414,
			3976.764,
			3980.826,
			3983.544,
			3987.635,
			3990.37,
			3992.85
		],
		[
			0,
			0.041,
			0.058,
			0.105,
			0.166,
			0.229,
			0.274,
			0.351,
			0.384,
			0.415,
			0.476,
			0.539,
			0.601,
			0.662,
			0.709,
			0.786,
			0.817,
			0.849,
			0.909,
			0.926,
			0.972,
			1.035,
			1.096,
			1.143,
			1.159,
			1.221,
			1.251,
			1.283,
			1.343,
			1.359,
			1.406,
			1.468,
			1.529,
			1.576,
			1.655,
			1.684,
			1.715,
			1.778,
			1.84,
			1.901,
			1.965,
			2.026,
			2.089,
			2.117,
			2.152,
			2.213,
			2.229,
			2.275,
			2.335,
			2.398,
			2.461,
			2.521,
			2.555,
			2.585,
			2.645,
			2.662,
			2.707,
			2.771,
			2.832,
			2.896,
			2.957,
			2.988,
			3.017,
			3.082,
			3.144,
			3.204,
			3.266,
			3.312,
			3.329,
			3.389,
			3.421,
			3.452,
			3.514,
			3.531,
			3.575,
			3.639,
			3.7,
			3.746,
			3.763,
			3.825,
			3.859,
			3.888,
			3.948,
			3.965,
			4.012,
			4.071,
			4.134,
			4.18,
			4.258,
			4.29,
			4.321,
			4.382,
			4.445,
			4.508,
			4.57,
			4.63,
			4.692,
			4.723,
			4.754,
			4.818,
			4.832,
			4.878,
			4.942,
			5.004,
			5.066,
			5.126,
			5.159,
			5.19,
			5.267,
			5.314,
			5.376,
			5.437,
			5.483,
			5.5,
			5.56,
			5.592,
			5.624,
			5.685,
			5.701,
			5.746,
			5.812,
			5.871,
			5.923,
			5.934,
			5.995,
			6.026,
			6.058,
			6.118,
			6.135,
			6.18,
			6.245,
			6.305,
			6.351,
			6.429,
			6.459,
			6.491,
			6.554,
			6.616,
			6.678,
			6.739,
			6.784,
			6.801,
			6.864,
			6.895,
			6.925,
			6.987,
			7.003,
			7.05,
			7.112,
			7.174,
			7.218,
			7.234,
			7.298,
			7.327,
			7.358,
			7.422,
			7.438,
			7.484,
			7.55,
			7.606,
			7.671,
			7.733,
			7.762,
			7.794,
			7.856,
			7.918,
			7.98,
			8.04,
			8.105,
			8.165,
			8.198,
			8.229,
			8.29,
			8.305,
			8.35,
			8.415,
			8.475,
			8.523,
			8.539,
			8.598,
			8.632,
			8.662,
			8.739,
			8.784,
			8.848,
			8.911,
			8.957,
			9.034,
			9.064,
			9.096,
			9.158,
			9.22,
			9.283,
			9.343,
			9.404,
			9.467,
			9.499,
			9.529,
			9.591,
			9.608,
			9.655,
			9.716,
			9.777,
			9.825,
			9.903,
			9.932,
			9.963,
			10.027,
			10.088,
			10.151,
			10.213,
			10.258,
			10.275,
			10.334,
			10.368,
			10.399,
			10.459,
			10.477,
			10.522,
			10.585,
			10.646,
			10.692,
			10.707,
			10.77,
			10.8,
			10.833,
			10.895,
			10.908,
			10.957,
			11.016,
			11.08,
			11.127,
			11.205,
			11.235,
			11.266,
			11.329,
			11.39,
			11.453,
			11.515,
			11.562,
			11.576,
			11.638,
			11.667,
			11.7,
			11.778,
			11.824,
			11.887,
			11.947,
			11.996,
			12.009,
			12.071,
			12.108,
			12.133,
			12.196,
			12.211,
			12.257,
			12.321,
			12.382,
			12.428,
			12.506,
			12.538,
			12.566,
			12.629,
			12.692,
			12.758,
			12.816,
			12.863,
			12.877,
			12.94,
			12.971,
			13.001,
			13.063,
			13.08,
			13.128,
			13.188,
			13.25,
			13.313,
			13.375,
			13.407,
			13.438,
			13.514,
			13.56,
			13.621,
			13.685,
			13.747,
			13.808,
			13.841,
			13.871,
			13.932,
			13.996,
			14.061,
			14.117,
			14.17,
			14.181,
			14.243,
			14.274,
			14.304,
			14.366,
			14.383,
			14.43,
			14.49,
			14.551,
			14.599,
			14.616,
			14.675,
			14.707,
			14.737,
			14.802,
			14.863,
			14.926,
			14.987,
			15.034,
			15.11,
			15.141,
			15.171,
			15.234,
			15.249,
			15.296,
			15.359,
			15.421,
			15.482,
			15.543,
			15.577,
			15.605,
			15.67,
			15.685,
			15.73,
			15.794,
			15.855,
			15.916,
			15.98,
			16.009,
			16.042,
			16.103,
			16.164,
			16.226,
			16.289,
			16.35,
			16.413,
			16.445,
			16.474,
			16.538,
			16.554,
			16.598,
			16.661,
			16.724,
			16.768,
			16.786,
			16.847,
			16.879,
			16.909,
			16.972,
			16.987,
			17.032,
			17.096,
			17.157,
			17.204,
			17.281,
			17.313,
			17.342,
			17.406,
			17.468,
			17.53,
			17.591,
			17.637,
			17.654,
			17.716,
			17.747,
			17.776,
			17.84,
			17.854,
			17.899,
			17.963,
			18.025,
			18.072,
			18.087,
			18.148,
			18.179,
			18.21,
			18.274,
			18.334,
			18.398,
			18.459,
			18.506,
			18.582,
			18.614,
			18.645,
			18.707,
			18.77,
			18.832,
			18.892,
			18.939,
			18.957,
			19.016,
			19.05,
			19.081,
			19.14,
			19.157,
			19.202,
			19.267,
			19.327,
			19.374,
			19.391,
			19.453,
			19.482,
			19.514,
			19.577,
			19.635,
			19.699,
			19.762,
			19.809,
			19.884,
			19.916,
			19.946,
			20.01,
			20.026,
			20.071,
			20.133,
			20.197,
			20.256,
			20.321,
			20.354,
			20.383,
			20.444,
			20.459,
			20.505,
			20.568,
			20.629,
			20.675,
			20.754,
			20.785,
			20.815,
			20.879,
			20.94,
			21,
			21.065,
			21.111,
			21.125,
			21.187,
			21.22,
			21.249,
			21.328,
			21.373,
			21.438,
			21.499,
			21.543,
			21.559,
			21.622,
			21.654,
			21.683,
			21.747,
			21.761,
			21.808,
			21.872,
			21.931,
			21.993,
			22.057,
			22.085,
			22.118,
			22.181,
			22.242,
			22.305,
			22.366,
			22.416,
			22.427,
			22.491,
			22.522,
			22.552,
			22.613,
			22.629,
			22.676,
			22.738,
			22.801,
			22.847,
			22.862,
			22.925,
			22.955,
			22.984,
			23.063,
			23.11,
			23.173,
			23.235,
			23.282,
			23.357,
			23.39,
			23.421,
			23.497,
			23.544,
			23.607,
			23.668,
			23.731,
			23.793,
			23.823,
			23.855,
			23.917,
			23.931,
			23.978,
			24.04,
			24.101,
			24.149,
			24.226,
			24.257,
			24.289,
			24.35,
			24.413,
			24.475,
			24.537,
			24.584,
			24.661,
			24.692,
			24.722,
			24.784,
			24.801,
			24.846,
			24.909,
			24.971,
			25.017,
			25.032,
			25.095,
			25.126,
			25.158,
			25.234,
			25.28,
			25.344,
			25.405,
			25.467,
			25.529,
			25.559,
			25.59,
			25.652,
			25.716,
			25.777,
			25.838,
			25.9,
			25.962,
			25.992,
			26.025,
			26.087,
			26.101,
			26.149,
			26.21,
			26.271,
			26.318,
			26.336,
			26.396,
			26.428,
			26.459,
			26.522,
			26.54,
			26.582,
			26.646,
			26.706,
			26.754,
			26.83,
			26.861,
			26.893,
			26.971,
			27.017,
			27.08,
			27.14,
			27.187,
			27.201,
			27.265,
			27.294,
			27.326,
			27.39,
			27.404,
			27.451,
			27.514,
			27.574,
			27.621,
			27.635,
			27.698,
			27.729,
			27.76,
			27.822,
			27.838,
			27.885,
			27.948,
			28.009,
			28.055,
			28.133,
			28.164,
			28.194,
			28.258,
			28.317,
			28.383,
			28.444,
			28.49,
			28.506,
			28.567,
			28.603,
			28.63,
			28.707,
			28.754,
			28.815,
			28.877,
			28.924,
			29.001,
			29.033,
			29.062,
			29.142,
			29.187,
			29.248,
			29.254,
			29.312,
			29.356,
			29.435,
			29.466,
			29.497,
			29.559,
			29.576,
			29.622,
			29.683,
			29.744,
			29.791,
			29.808,
			29.871,
			29.899,
			29.932,
			30.009,
			30.057,
			30.116,
			30.179,
			30.227,
			30.305,
			30.332,
			30.364,
			30.444,
			30.489,
			30.552,
			30.613,
			30.664,
			30.675,
			30.739,
			30.768,
			30.8,
			30.877,
			30.925,
			30.985,
			31.046,
			31.111,
			31.171,
			31.201,
			31.235,
			31.294,
			31.312,
			31.359,
			31.418,
			31.482,
			31.527,
			31.607,
			31.638,
			31.668,
			31.729,
			31.792,
			31.855,
			31.915,
			31.976,
			32.04,
			32.072,
			32.101,
			32.165,
			32.179,
			32.226,
			32.288,
			32.349,
			32.396,
			32.411,
			32.474,
			32.505,
			32.535,
			32.612,
			32.659,
			32.721,
			32.728,
			32.784,
			32.831,
			32.907,
			32.94,
			32.971,
			33.031,
			33.095,
			33.156,
			33.216,
			33.28,
			33.342,
			33.372,
			33.405,
			33.481,
			33.528,
			33.591,
			33.653,
			33.713,
			33.776,
			33.807,
			33.839,
			33.899,
			33.915,
			33.962,
			34.023,
			34.085,
			34.132,
			34.209,
			34.241,
			34.273,
			34.349,
			34.397,
			34.458,
			34.518,
			34.582,
			34.643,
			34.676,
			34.705,
			34.79,
			34.83,
			34.892,
			34.954,
			35.001,
			35.078,
			35.109,
			35.141,
			35.217,
			35.264,
			35.326,
			35.388,
			35.435,
			35.449,
			35.511,
			35.542,
			35.573,
			35.634,
			35.653,
			35.698,
			35.761,
			35.823,
			35.87,
			35.882,
			35.945,
			35.977,
			36.009,
			36.071,
			36.084,
			36.133,
			36.196,
			36.256,
			36.301,
			36.38,
			36.412,
			36.442,
			36.521,
			36.568,
			36.628,
			36.69,
			36.735,
			36.751,
			36.813,
			36.85,
			36.877,
			36.938,
			36.954,
			37.001,
			37.062,
			37.125,
			37.171,
			37.185,
			37.248,
			37.278,
			37.312,
			37.389,
			37.433,
			37.496,
			37.559,
			37.606,
			37.684,
			37.715,
			37.744,
			37.807,
			37.867,
			37.932,
			37.992,
			38.039,
			38.055,
			38.115,
			38.147,
			38.179,
			38.257,
			38.301,
			38.366,
			38.426,
			38.473,
			38.487,
			38.551,
			38.582,
			38.613,
			38.674,
			38.737,
			38.8,
			38.862,
			38.923,
			38.986,
			39.016,
			39.047,
			39.109,
			39.171,
			39.234,
			39.295,
			39.357,
			39.419,
			39.45,
			39.481,
			39.543,
			39.557,
			39.605,
			39.666,
			39.729,
			39.777,
			39.854,
			39.883,
			39.916,
			39.978,
			40.04,
			40.102,
			40.163,
			40.21,
			40.288,
			40.319,
			40.349,
			40.412,
			40.426,
			40.474,
			40.534,
			40.596,
			40.643,
			40.66,
			40.722,
			40.752,
			40.782,
			40.86,
			40.907,
			40.967,
			40.974,
			41.031,
			41.092,
			41.155,
			41.184,
			41.216,
			41.278,
			41.342,
			41.404,
			41.466,
			41.529,
			41.589,
			41.621,
			41.652,
			41.714,
			41.728,
			41.777,
			41.837,
			41.898,
			41.961,
			42.024,
			42.055,
			42.085,
			42.147,
			42.163,
			42.21,
			42.271,
			42.335,
			42.38,
			42.457,
			42.488,
			42.518,
			42.582,
			42.645,
			42.706,
			42.767,
			42.813,
			42.829,
			42.891,
			42.922,
			42.955,
			43.016,
			43.035,
			43.077,
			43.14,
			43.2,
			43.249,
			43.325,
			43.357,
			43.388,
			43.466,
			43.512,
			43.573,
			43.635,
			43.683,
			43.758,
			43.79,
			43.821,
			43.9,
			43.945,
			44.008,
			44.07,
			44.117,
			44.131,
			44.193,
			44.223,
			44.255,
			44.317,
			44.332,
			44.38,
			44.443,
			44.504,
			44.55,
			44.629,
			44.659,
			44.691,
			44.75,
			44.813,
			44.877,
			44.938,
			44.983,
			45.001,
			45.061,
			45.124,
			45.186,
			45.2,
			45.247,
			45.31,
			45.371,
			45.419,
			45.435,
			45.496,
			45.528,
			45.557,
			45.62,
			45.634,
			45.682,
			45.743,
			45.807,
			45.853,
			45.93,
			45.961,
			45.992,
			46.054,
			46.116,
			46.183,
			46.24,
			46.287,
			46.301,
			46.363,
			46.394,
			46.426,
			46.489,
			46.505,
			46.549,
			46.613,
			46.673,
			46.736,
			46.797,
			46.828,
			46.859,
			46.922,
			46.937,
			46.982,
			47.045,
			47.107,
			47.171,
			47.232,
			47.263,
			47.294,
			47.371,
			47.419,
			47.479,
			47.543,
			47.588,
			47.605,
			47.666,
			47.697,
			47.727,
			47.789,
			47.806,
			47.85,
			47.916,
			47.975,
			48.038,
			48.099,
			48.131,
			48.162,
			48.225,
			48.285,
			48.35,
			48.41,
			48.457,
			48.534,
			48.566,
			48.596,
			48.658,
			48.674,
			48.721,
			48.782,
			48.843,
			48.891,
			48.905,
			48.966,
			49,
			49.029,
			49.109,
			49.154,
			49.221,
			49.279,
			49.341,
			49.404,
			49.432,
			49.463,
			49.527,
			49.589,
			49.649,
			49.712,
			49.758,
			49.836,
			49.866,
			49.899,
			49.959,
			49.976,
			50.024,
			50.082,
			50.146,
			50.208,
			50.271,
			50.302,
			50.333,
			50.411,
			50.457,
			50.52,
			50.581,
			50.644,
			50.705,
			50.737,
			50.767,
			50.827,
			50.892,
			50.954,
			51.013,
			51.06,
			51.076,
			51.138,
			51.168,
			51.199,
			51.261,
			51.282,
			51.324,
			51.387,
			51.45,
			51.494,
			51.511,
			51.573,
			51.604,
			51.635,
			51.696,
			51.713,
			51.758,
			51.822,
			51.883,
			51.93,
			52.007,
			52.039,
			52.068,
			52.129,
			52.193,
			52.255,
			52.316,
			52.379,
			52.442,
			52.473,
			52.501,
			52.579,
			52.626,
			52.689,
			52.749,
			52.812,
			52.876,
			52.907,
			52.937,
			52.999,
			53.06,
			53.122,
			53.186,
			53.232,
			53.308,
			53.344,
			53.372,
			53.433,
			53.449,
			53.496,
			53.556,
			53.62,
			53.667,
			53.68,
			53.744,
			53.775,
			53.805,
			53.884,
			53.929,
			53.991,
			54.054,
			54.098,
			54.116,
			54.176,
			54.208,
			54.239,
			54.3,
			54.362,
			54.424,
			54.488,
			54.532,
			54.55,
			54.612,
			54.641,
			54.672,
			54.736,
			54.75,
			54.796,
			54.859,
			54.921,
			54.966,
			54.982,
			55.044,
			55.077,
			55.108,
			55.17,
			55.183,
			55.232,
			55.295,
			55.355,
			55.406,
			55.479,
			55.509,
			55.54,
			55.618,
			55.665,
			55.727,
			55.789,
			55.836,
			55.85,
			55.913,
			55.943,
			55.975,
			56.054,
			56.099,
			56.162,
			56.223,
			56.284,
			56.347,
			56.378,
			56.409,
			56.488,
			56.535,
			56.595,
			56.658,
			56.705,
			56.782,
			56.813,
			56.842,
			56.905,
			56.967,
			57.029,
			57.092,
			57.138,
			57.154,
			57.215,
			57.246,
			57.279,
			57.338,
			57.354,
			57.401,
			57.466,
			57.525,
			57.588,
			57.649,
			57.679,
			57.71,
			57.775,
			57.837,
			57.901,
			57.958,
			58.02,
			58.083,
			58.114,
			58.145,
			58.223,
			58.27,
			58.332,
			58.394,
			58.44,
			58.457,
			58.517,
			58.548,
			58.579,
			58.64,
			58.657,
			58.705,
			58.765,
			58.829,
			58.89,
			58.953,
			58.983,
			59.013,
			59.091,
			59.138,
			59.199,
			59.262,
			59.309,
			59.387,
			59.418,
			59.447,
			59.51,
			59.531,
			59.573,
			59.634,
			59.697,
			59.743,
			59.757,
			59.82,
			59.851,
			59.883,
			59.945,
			59.96,
			60.007,
			60.066,
			60.131,
			60.177,
			60.254,
			60.284,
			60.315,
			60.377,
			60.439,
			60.502,
			60.565,
			60.611,
			60.625,
			60.687,
			60.72,
			60.749,
			60.813,
			60.829,
			60.874,
			60.935,
			60.999,
			61.059,
			61.122,
			61.151,
			61.184,
			61.247,
			61.262,
			61.309,
			61.371,
			61.432,
			61.478,
			61.557,
			61.593,
			61.619,
			61.68,
			61.741,
			61.804,
			61.867,
			61.927,
			61.992,
			62.022,
			62.054,
			62.114,
			62.13,
			62.177,
			62.238,
			62.3,
			62.364,
			62.425,
			62.455,
			62.488,
			62.549,
			62.563,
			62.61,
			62.672,
			62.733,
			62.782,
			62.859,
			62.891,
			62.921,
			62.981,
			63.045,
			63.107,
			63.17,
			63.215,
			63.229,
			63.291,
			63.323,
			63.355,
			63.432,
			63.479,
			63.54,
			63.604,
			63.654,
			63.726,
			63.758,
			63.79,
			63.851,
			63.912,
			63.973,
			64.036,
			64.081,
			64.099,
			64.159,
			64.191,
			64.223,
			64.284,
			64.301,
			64.347,
			64.41,
			64.472,
			64.516,
			64.531,
			64.594,
			64.625,
			64.656,
			64.717,
			64.735,
			64.78,
			64.844,
			64.906,
			64.952,
			65.03,
			65.058,
			65.091,
			65.151,
			65.214,
			65.277,
			65.338,
			65.402,
			65.462,
			65.494,
			65.525,
			65.588,
			65.603,
			65.648,
			65.714,
			65.773,
			65.82,
			65.835,
			65.898,
			65.927,
			65.958,
			66.022,
			66.037,
			66.082,
			66.144,
			66.206,
			66.254,
			66.331,
			66.361,
			66.393,
			66.454,
			66.516,
			66.58,
			66.642,
			66.688,
			66.702,
			66.763,
			66.795,
			66.826,
			66.889,
			66.905,
			66.952,
			67.013,
			67.074,
			67.122,
			67.138,
			67.2,
			67.229,
			67.261,
			67.322,
			67.339,
			67.383,
			67.448,
			67.508,
			67.556,
			67.634,
			67.663,
			67.694,
			67.758,
			67.777,
			67.82,
			67.881,
			67.943,
			68.005,
			68.068,
			68.097,
			68.128,
			68.192,
			68.206,
			68.254,
			68.316,
			68.377,
			68.438,
			68.5,
			68.532,
			68.563,
			68.624,
			68.64,
			68.687,
			68.748,
			68.81,
			68.859,
			68.936,
			68.967,
			68.996,
			69.074,
			69.122,
			69.184,
			69.245,
			69.29,
			69.307,
			69.368,
			69.401,
			69.432,
			69.492,
			69.509,
			69.554,
			69.618,
			69.679,
			69.726,
			69.804,
			69.84,
			69.864,
			69.943,
			69.99,
			70.051,
			70.114,
			70.159,
			70.176,
			70.238,
			70.27,
			70.3,
			70.361,
			70.377,
			70.425,
			70.485,
			70.549,
			70.594,
			70.611,
			70.671,
			70.702,
			70.733,
			70.812,
			70.857,
			70.918,
			70.982,
			71.028,
			71.105,
			71.137,
			71.168,
			71.229,
			71.293,
			71.354,
			71.416,
			71.461,
			71.478,
			71.54,
			71.571,
			71.602,
			71.663,
			71.68,
			71.725,
			71.787,
			71.849,
			71.91,
			71.972,
			72.005,
			72.034,
			72.113,
			72.159,
			72.223,
			72.283,
			72.33,
			72.407,
			72.439,
			72.468,
			72.531,
			72.595,
			72.654,
			72.718,
			72.764,
			72.778,
			72.842,
			72.873,
			72.905,
			72.965,
			72.982,
			73.027,
			73.091,
			73.151,
			73.213,
			73.275,
			73.307,
			73.338,
			73.401,
			73.463,
			73.529,
			73.586,
			73.648,
			73.71,
			73.742,
			73.773,
			73.834,
			73.85,
			73.895,
			73.959,
			74.021,
			74.082,
			74.145,
			74.176,
			74.207,
			74.268,
			74.283,
			74.329,
			74.393,
			74.454,
			74.501,
			74.579,
			74.608,
			74.639,
			74.701,
			74.764,
			74.826,
			74.888,
			74.938,
			74.95,
			75.012,
			75.044,
			75.075,
			75.137,
			75.15,
			75.198,
			75.262,
			75.322,
			75.383,
			75.447,
			75.477,
			75.509,
			75.572,
			75.585,
			75.631,
			75.694,
			75.757,
			75.801,
			75.879,
			75.911,
			75.941,
			76.005,
			76.025,
			76.067,
			76.129,
			76.19,
			76.254,
			76.314,
			76.347,
			76.377,
			76.455,
			76.5,
			76.563,
			76.624,
			76.672,
			76.688,
			76.749,
			76.779,
			76.81,
			76.873,
			76.888,
			76.935,
			76.997,
			77.057,
			77.105,
			77.182,
			77.213,
			77.243,
			77.307,
			77.367,
			77.43,
			77.493,
			77.554,
			77.616,
			77.648,
			77.679,
			77.74,
			77.755,
			77.801,
			77.865,
			77.925,
			77.973,
			77.989,
			78.049,
			78.111,
			78.173,
			78.19,
			78.238,
			78.299,
			78.362,
			78.407,
			78.485,
			78.517,
			78.548,
			78.609,
			78.672,
			78.733,
			78.796,
			78.843,
			78.857,
			78.917,
			78.949,
			78.981,
			79.059,
			79.105,
			79.168,
			79.23,
			79.292,
			79.354,
			79.383,
			79.416,
			79.476,
			79.54,
			79.601,
			79.664,
			79.711,
			79.725,
			79.788,
			79.817,
			79.849,
			79.912,
			79.927,
			79.974,
			80.036,
			80.098,
			80.15,
			80.22,
			80.253,
			80.283,
			80.345,
			80.359,
			80.408,
			80.47,
			80.532,
			80.577,
			80.656,
			80.688,
			80.718,
			80.779,
			80.841,
			80.904,
			80.965,
			81.012,
			81.026,
			81.09,
			81.12,
			81.152,
			81.229,
			81.276,
			81.337,
			81.4,
			81.462,
			81.524,
			81.556,
			81.587,
			81.647,
			81.664,
			81.711,
			81.773,
			81.834,
			81.879,
			81.958,
			81.988,
			82.018,
			82.081,
			82.143,
			82.207,
			82.267,
			82.313,
			82.329,
			82.393,
			82.423,
			82.454,
			82.515,
			82.532,
			82.577,
			82.638,
			82.7,
			82.749,
			82.826,
			82.858,
			82.888,
			82.949,
			83.012,
			83.073,
			83.134,
			83.183,
			83.26,
			83.292,
			83.322,
			83.382,
			83.399,
			83.445,
			83.508,
			83.568,
			83.615,
			83.631,
			83.695,
			83.725,
			83.755,
			83.819,
			83.834,
			83.879,
			83.943,
			84.005,
			84.051,
			84.128,
			84.158,
			84.189,
			84.252,
			84.313,
			84.377,
			84.438,
			84.483,
			84.5,
			84.563,
			84.592,
			84.625,
			84.686,
			84.7,
			84.749,
			84.809,
			84.872,
			84.933,
			84.995,
			85.026,
			85.059,
			85.134,
			85.183,
			85.243,
			85.307,
			85.351,
			85.429,
			85.46,
			85.492,
			85.57,
			85.617,
			85.68,
			85.74,
			85.785,
			85.801,
			85.863,
			85.895,
			85.927,
			85.988,
			86.005,
			86.05,
			86.112,
			86.173,
			86.221,
			86.237,
			86.298,
			86.334,
			86.36,
			86.422,
			86.438,
			86.484,
			86.547,
			86.609,
			86.655,
			86.732,
			86.764,
			86.796,
			86.856,
			86.918,
			86.982,
			87.043,
			87.088,
			87.105,
			87.165,
			87.198,
			87.229,
			87.29,
			87.307,
			87.351,
			87.415,
			87.477,
			87.523,
			87.54,
			87.601,
			87.63,
			87.662,
			87.725,
			87.787,
			87.85,
			87.91,
			87.974,
			88.035,
			88.065,
			88.095,
			88.16,
			88.174,
			88.221,
			88.283,
			88.343,
			88.397,
			88.407,
			88.468,
			88.498,
			88.529,
			88.593,
			88.609,
			88.656,
			88.716,
			88.779,
			88.826,
			88.901,
			88.935,
			88.965,
			89.026,
			89.089,
			89.149,
			89.213,
			89.259,
			89.276,
			89.336,
			89.368,
			89.398,
			89.459,
			89.476,
			89.523,
			89.584,
			89.646,
			89.693,
			89.707,
			89.772,
			89.801,
			89.832,
			89.896,
			89.909,
			89.956,
			90.017,
			90.082,
			90.129,
			90.205,
			90.234,
			90.267,
			90.328,
			90.392,
			90.459,
			90.516,
			90.562,
			90.578,
			90.64,
			90.667,
			90.7,
			90.764,
			90.78,
			90.826,
			90.885,
			90.949,
			90.996,
			91.012,
			91.073,
			91.105,
			91.135,
			91.212,
			91.259,
			91.321,
			91.383,
			91.446,
			91.507,
			91.537,
			91.567,
			91.632,
			91.692,
			91.755,
			91.816,
			91.862,
			91.879,
			91.942,
			91.972,
			92.002,
			92.064,
			92.082,
			92.128,
			92.19,
			92.251,
			92.312,
			92.375,
			92.406,
			92.438,
			92.498,
			92.518,
			92.562,
			92.622,
			92.684,
			92.731,
			92.81,
			92.841,
			92.872,
			92.95,
			92.995,
			93.058,
			93.12,
			93.183,
			93.243,
			93.273,
			93.305,
			93.383,
			93.428,
			93.492,
			93.554,
			93.6,
			93.677,
			93.708,
			93.74,
			93.801,
			93.865,
			93.926,
			93.989,
			94.033
		]
	],
	'2467_9055_7982': [
		[
			108.727
		],
		[
			0
		],
		[
			0,
			3.652,
			6.985,
			10.314,
			11.979,
			15.301,
			18.626,
			20.288,
			23.618,
			25.284,
			28.618,
			31.955,
			36.973,
			40.327,
			45.365,
			47.046,
			48.728,
			53.785,
			57.167,
			62.256,
			65.651,
			69.048,
			70.747,
			74.147,
			75.848,
			79.256,
			82.668,
			84.375,
			87.791,
			91.212,
			94.637,
			98.063,
			99.776,
			103.204,
			106.633,
			108.348,
			111.782,
			113.5,
			116.94,
			120.382,
			125.551,
			128.998,
			134.174,
			135.901,
			137.63,
			142.826,
			144.563,
			146.3,
			151.524,
			155.008,
			158.492,
			160.234,
			163.723,
			165.47,
			168.968,
			172.472,
			174.226,
			177.736,
			181.247,
			184.762,
			188.279,
			190.039,
			193.56,
			197.085,
			198.849,
			202.378,
			204.144,
			207.677,
			211.203,
			216.456,
			219.915,
			225.025,
			226.707,
			228.381,
			233.353,
			236.629,
			241.466,
			244.639,
			247.773,
			249.326,
			252.404,
			253.93,
			256.956,
			261.435,
			264.382,
			267.295,
			271.588,
			274.405,
			278.555,
			279.919,
			281.273,
			285.271,
			287.884,
			290.458,
			294.248,
			296.732,
			300.39,
			301.591,
			302.784,
			306.311,
			307.47,
			308.619,
			312.02,
			314.242,
			316.427,
			317.506,
			319.634,
			320.682,
			322.751,
			324.781,
			325.78,
			327.753,
			329.687,
			332.518,
			334.359,
			337.057,
			337.94,
			338.815,
			341.384,
			343.051,
			345.483,
			347.058,
			348.596,
			349.354,
			350.842,
			351.573,
			352.295,
			354.405,
			355.102,
			355.778,
			357.761,
			359.043,
			360.895,
			362.085,
			363.241,
			363.807,
			364.906,
			365.442,
			366.485,
			367.494,
			368.949,
			369.866,
			371.188,
			371.617,
			372.041,
			373.291,
			374.102,
			375.24,
			375.989,
			377.102,
			377.843,
			378.215,
			378.961,
			379.688,
			380.051,
			380.778,
			381.508,
			382.608,
			382.977,
			383.347,
			384.676,
			385.211,
			385.746,
			386.819,
			387.357,
			388.437,
			390.166,
			393.721,
			396.522,
			399.985,
			400.767,
			401.55,
			403.918,
			404.963,
			406.499,
			407.533,
			408.577,
			409.085,
			409.952,
			410.389,
			411.27,
			412.16,
			412.61,
			413.517,
			414.437,
			415.843,
			416.32,
			416.801,
			418.27,
			418.769,
			419.291,
			420.942,
			422.065,
			423.197,
			425.051,
			426.367,
			428.341,
			429.004,
			429.746,
			432.009,
			433.546,
			435.937,
			437.564,
			439.207,
			440.008,
			441.623,
			442.438,
			444.081,
			445.63,
			446.411,
			447.987,
			449.563,
			451.897,
			453.475,
			455.892,
			456.703,
			457.517,
			459.984,
			461.647,
			464.171,
			465.886,
			467.615,
			468.485,
			470.249,
			471.137,
			472.026,
			474.699,
			475.606,
			476.516,
			479.268,
			481.151,
			483.048,
			484.002,
			485.935,
			487.883,
			488.863,
			491.853,
			493.866,
			495.906,
			498.993,
			501.073,
			504.214,
			505.257,
			506.304,
			509.465,
			511.552,
			514.701,
			516.819,
			518.952,
			520.015,
			522.134,
			523.195,
			525.307,
			527.387,
			528.41,
			530.475,
			532.479,
			535.501,
			536.495,
			537.492,
			539.491,
			541.478,
			542.47,
			544.445,
			545.432,
			547.407,
			549.341,
			552.24,
			554.133,
			556.982,
			557.923,
			558.861,
			561.681,
			563.555,
			566.355,
			568.211,
			570.064,
			570.993,
			572.844,
			573.767,
			575.615,
			577.459,
			578.378,
			580.219,
			582.06,
			584.808,
			585.723,
			586.636,
			589.359,
			590.262,
			591.162,
			593.847,
			595.632,
			597.412,
			600.073,
			601.854,
			604.516,
			605.407,
			606.3,
			609.866,
			610.765,
			613.458,
			615.256,
			617.971,
			619.776,
			621.577,
			622.475,
			624.271,
			625.168,
			626.958,
			628.745,
			631.417,
			632.291,
			633.195,
			635.868,
			636.76,
			637.654,
			640.35,
			642.16,
			644.895,
			646.723,
			648.562,
			649.486,
			651.325,
			652.246,
			653.17,
			655.939,
			656.856,
			657.775,
			660.535,
			662.362,
			664.195,
			665.113,
			666.945,
			668.781,
			669.702,
			671.554,
			672.482,
			674.343,
			676.215,
			679.039,
			680.934,
			683.792,
			684.749,
			685.708,
			688.6,
			690.515,
			693.406,
			695.312,
			697.191,
			698.117,
			699.941,
			700.838,
			702.605,
			704.335,
			705.186,
			706.028,
			708.5,
			710.889,
			711.668,
			712.438,
			713.95,
			715.427,
			716.153,
			718.278,
			719.662,
			720.994,
			722.961,
			724.223,
			726.065,
			726.669,
			727.267,
			729.029,
			730.187,
			731.925,
			733.074,
			734.22,
			734.794,
			735.955,
			736.541,
			737.711,
			738.883,
			739.47,
			740.658,
			741.871,
			743.688,
			744.294,
			744.9,
			746.144,
			747.399,
			748.025,
			749.277,
			749.903,
			751.183,
			753.114,
			754.398,
			755.692,
			757.651,
			758.301,
			758.95,
			760.893,
			761.536,
			762.177,
			764.094,
			765.372,
			766.646,
			767.281,
			768.546,
			769.809,
			770.443,
			772.359,
			773.631,
			774.9,
			776.831,
			778.116,
			780.04,
			780.688,
			781.341,
			783.295,
			784.6,
			786.57,
			787.885,
			789.202,
			789.863,
			791.185,
			791.845,
			792.506,
			794.496,
			795.164,
			795.83,
			797.835,
			799.183,
			800.541,
			801.219,
			802.584,
			803.961,
			804.653,
			806.034,
			806.719,
			808.089,
			809.487,
			811.555,
			812.914,
			814.943,
			815.614,
			816.242,
			818.076,
			819.268,
			821.012,
			822.151,
			823.285,
			823.844,
			824.958,
			825.515,
			826.121,
			827.926,
			828.524,
			829.119,
			830.952,
			832.967,
			833.634,
			834.3,
			835.637,
			837.079,
			837.797,
			839.229,
			839.944,
			841.408,
			842.893,
			845.111,
			846.6,
			848.829,
			849.571,
			850.314,
			852.527,
			853.994,
			856.227,
			857.758,
			859.288,
			860.055,
			861.636,
			862.438,
			864.043,
			865.649,
			866.422,
			867.968,
			869.518,
			871.778,
			872.511,
			873.246,
			874.723,
			876.2,
			876.934,
			879.144,
			880.627,
			882.105,
			884.336,
			885.833,
			888.055,
			888.8,
			889.547,
			891.759,
			893.216,
			895.422,
			896.877,
			898.337,
			899.073,
			900.556,
			901.292,
			902.767,
			905.013,
			906.517,
			908.019,
			910.303,
			911.828,
			914.132,
			914.908,
			915.688,
			918.028,
			919.606,
			921.993,
			923.595,
			925.213,
			927.649,
			928.466,
			929.286,
			931.761,
			933.416,
			935.925,
			937.6,
			939.273,
			940.111,
			941.796,
			942.644,
			944.334,
			946.05,
			946.928,
			948.663,
			950.412,
			953.058,
			954.838,
			957.536,
			958.428,
			959.338,
			962.089,
			963.943,
			966.749,
			968.637,
			971.493,
			973.413,
			974.378,
			976.317,
			978.27,
			979.251,
			981.222,
			983.209,
			985.207,
			987.218,
			988.229,
			990.258,
			992.298,
			993.322,
			995.378,
			996.411,
			998.49,
			1000.58,
			1003.729,
			1005.827,
			1008.977,
			1010.032,
			1011.089,
			1014.276,
			1016.414,
			1019.646,
			1021.814,
			1023.996,
			1025.09,
			1027.288,
			1028.391,
			1030.604,
			1032.825,
			1033.939,
			1036.175,
			1038.418,
			1041.803,
			1042.933,
			1044.065,
			1047.475,
			1048.616,
			1049.76,
			1052.054,
			1053.207,
			1055.518,
			1057.839,
			1061.337,
			1063.683,
			1067.22,
			1068.407,
			1069.596,
			1073.177,
			1075.576,
			1079.19,
			1081.609,
			1084.038,
			1085.255,
			1087.697,
			1090.149,
			1091.377,
			1093.841,
			1095.075,
			1097.551,
			1100.035,
			1103.775,
			1106.277,
			1110.047,
			1111.308,
			1112.571,
			1116.373,
			1118.917,
			1121.471,
			1125.317,
			1127.891,
			1131.769,
			1133.066,
			1134.364,
			1138.269,
			1140.875,
			1144.791,
			1147.409,
			1150.034,
			1151.349,
			1153.985,
			1156.629,
			1157.953,
			1160.608,
			1161.938,
			1164.603,
			1167.276,
			1171.299,
			1173.99,
			1178.038,
			1179.39,
			1180.745,
			1184.821,
			1187.547,
			1191.651,
			1194.396,
			1198.528,
			1201.292,
			1202.675,
			1204.061,
			1208.23,
			1209.623,
			1211.018,
			1215.212,
			1218.018,
			1220.832,
			1222.241,
			1225.064,
			1227.893,
			1229.31,
			1232.146,
			1233.566,
			1236.411,
			1239.26,
			1243.546,
			1246.411,
			1250.721,
			1252.161,
			1253.603,
			1257.937,
			1260.835,
			1265.194,
			1268.107,
			1271.027,
			1272.49,
			1275.419,
			1276.887,
			1279.828,
			1284.25,
			1287.207,
			1290.17,
			1294.625,
			1296.112,
			1297.602,
			1300.585,
			1303.574,
			1305.071,
			1308.07,
			1309.571,
			1312.579,
			1317.102,
			1320.124,
			1323.153,
			1327.706,
			1329.226,
			1330.748,
			1335.322,
			1338.378,
			1342.972,
			1346.042,
			1349.118,
			1350.658,
			1353.741,
			1355.285,
			1358.377,
			1363.025,
			1366.131,
			1369.241,
			1373.917,
			1377.039,
			1381.735,
			1383.302,
			1384.872,
			1389.586,
			1392.735,
			1395.888,
			1397.467,
			1400.627,
			1403.792,
			1408.547,
			1410.135,
			1411.724,
			1416.496,
			1418.086,
			1419.678,
			1424.459,
			1427.652,
			1430.846,
			1432.442,
			1435.622,
			1438.781,
			1440.35,
			1443.462,
			1445.003,
			1448.058,
			1451.077,
			1455.539,
			1458.468,
			1462.791,
			1464.212,
			1465.625,
			1469.804,
			1472.542,
			1476.578,
			1479.22,
			1483.107,
			1485.65,
			1486.906,
			1488.153,
			1491.839,
			1493.049,
			1494.25,
			1497.803,
			1500.128,
			1502.42,
			1503.551,
			1505.787,
			1507.987,
			1509.073,
			1511.215,
			1512.271,
			1514.356,
			1516.401,
			1519.398,
			1521.351,
			1524.212,
			1525.148,
			1526.076,
			1528.805,
			1530.585,
			1533.19,
			1534.897,
			1536.556,
			1537.372,
			1538.977,
			1540.55,
			1541.324,
			1543.602,
			1545.086,
			1546.542,
			1548.688,
			1549.395,
			1550.088,
			1551.454,
			1552.811,
			1553.486,
			1555.488,
			1556.804,
			1558.12,
			1560.094,
			1561.397,
			1563.357,
			1564.013,
			1564.67,
			1566.685,
			1568.035,
			1570.113,
			1571.65,
			1573.969,
			1575.608,
			1576.471,
			1578.204,
			1580.865,
			1582.689,
			1584.522,
			1587.409,
			1588.378,
			1589.348,
			1592.34,
			1593.342,
			1594.346,
			1597.404,
			1599.447,
			1602.416,
			1604.382,
			1606.2,
			1608.859,
			1609.746,
			1610.565,
			1612.99,
			1614.609,
			1616.952,
			1618.511,
			1620.843,
			1622.392,
			1623.168,
			1624.714,
			1626.284,
			1627.074,
			1628.664,
			1630.268,
			1632.709,
			1633.53,
			1634.353,
			1636.851,
			1637.692,
			1638.536,
			1641.092,
			1642.82,
			1645.438,
			1647.21,
			1648.991,
			1651.696,
			1652.605,
			1653.511,
			1656.255,
			1657.175,
			1658.089,
			1660.812,
			1662.649,
			1664.474,
			1665.385,
			1667.251,
			1669.112,
			1670.04,
			1671.935,
			1672.883,
			1674.775,
			1676.683,
			1679.541,
			1681.426,
			1684.216,
			1685.143,
			1686.06,
			1688.766,
			1690.557,
			1693.175,
			1694.901,
			1697.47,
			1699.17,
			1700.017,
			1701.71,
			1704.242,
			1705.083,
			1707.605,
			1709.282,
			1710.953,
			1711.78,
			1713.43,
			1715.078,
			1715.898,
			1717.515,
			1718.324,
			1719.945,
			1721.552,
			1723.934,
			1725.54,
			1727.927,
			1728.729,
			1729.534,
			1731.968,
			1733.59,
			1736.047,
			1737.698,
			1739.362,
			1740.199,
			1741.886,
			1742.736,
			1744.443,
			1746.166,
			1747.034,
			1748.78,
			1750.538,
			1753.189,
			1754.084,
			1754.982,
			1757.696,
			1758.607,
			1759.521,
			1762.283,
			1764.14,
			1766.01,
			1768.836,
			1770.736,
			1773.605,
			1774.566,
			1775.528,
			1778.413,
			1780.33,
			1783.193,
			1785.092,
			1786.999,
			1787.942,
			1789.822,
			1791.695,
			1792.63,
			1794.492,
			1795.42,
			1797.273,
			1799.118,
			1801.872,
			1803.703,
			1806.433,
			1807.346,
			1808.256,
			1810.974,
			1812.786,
			1815.491,
			1817.293,
			1819.092,
			1821.784,
			1822.684,
			1823.582,
			1826.272,
			1827.174,
			1828.076,
			1830.778,
			1832.588,
			1835.306,
			1837.125,
			1838.958,
			1839.874,
			1841.709,
			1842.636,
			1844.489,
			1846.344,
			1849.147,
			1851.015,
			1853.836,
			1854.777,
			1855.717,
			1858.546,
			1860.429,
			1863.251,
			1865.128,
			1867.949,
			1869.83,
			1870.771,
			1872.656,
			1874.544,
			1875.487,
			1877.372,
			1879.251,
			1881.129,
			1883.008,
			1883.947,
			1885.826,
			1887.71,
			1888.653,
			1891.485,
			1893.382,
			1895.279,
			1898.134,
			1900.04,
			1902.907,
			1903.867,
			1904.825,
			1907.708,
			1909.639,
			1912.532,
			1914.462,
			1916.388,
			1917.352,
			1919.276,
			1920.237,
			1922.157,
			1924.067,
			1925.021,
			1926.927,
			1928.825,
			1931.665,
			1933.555,
			1935.44,
			1937.323,
			1938.265,
			1941.085,
			1942.969,
			1944.853,
			1947.677,
			1949.556,
			1952.37,
			1953.301,
			1954.231,
			1957.016,
			1958.855,
			1961.608,
			1963.433,
			1965.251,
			1966.16,
			1967.973,
			1968.875,
			1970.681,
			1973.384,
			1975.18,
			1976.979,
			1979.663,
			1981.455,
			1984.132,
			1985.024,
			1985.918,
			1988.597,
			1990.385,
			1992.181,
			1994.877,
			1996.689,
			1999.428,
			2000.346,
			2001.266,
			2004.061,
			2005.001,
			2005.943,
			2008.802,
			2010.729,
			2012.668,
			2013.643,
			2015.603,
			2017.575,
			2018.569,
			2020.563,
			2021.564,
			2023.585,
			2025.622,
			2028.706,
			2030.783,
			2033.9,
			2034.946,
			2035.991,
			2039.129,
			2041.231,
			2044.402,
			2046.528,
			2048.662,
			2051.879,
			2052.958,
			2054.04,
			2057.302,
			2058.396,
			2059.493,
			2062.799,
			2065.016,
			2068.359,
			2070.6,
			2072.85,
			2073.981,
			2076.25,
			2077.388,
			2079.675,
			2081.974,
			2085.439,
			2087.761,
			2091.0254,
			2092.423,
			2093.592,
			2097.11,
			2099.465,
			2103.012,
			2105.388,
			2108.967,
			2111.365,
			2112.567,
			2114.979,
			2117.401,
			2118.615,
			2121.053,
			2123.5,
			2125.956,
			2129.657,
			2132.136,
			2134.624,
			2135.872,
			2138.374,
			2139.628,
			2142.144,
			2144.669,
			2148.473,
			2151.019,
			2154.853,
			2156.135,
			2157.419,
			2161.282,
			2163.867,
			2167.758,
			2170.362,
			2174.269,
			2176.88,
			2178.188,
			2180.81,
			2184.756,
			2187.396,
			2190.044,
			2194.028,
			2196.694,
			2200.706,
			2202.047,
			2203.39,
			2207.43,
			2210.132,
			2212.841,
			2216.919,
			2219.647,
			2223.751,
			2225.123,
			2226.497,
			2230.627,
			2233.391,
			2237.549,
			2240.33,
			2244.514,
			2247.313,
			2248.716,
			2251.525,
			2254.341,
			2255.752,
			2258.579,
			2261.413,
			2265.676,
			2268.528,
			2272.818,
			2274.251,
			2275.686,
			2280,
			2282.885,
			2287.225,
			2290.126,
			2294.491,
			2297.41,
			2298.871,
			2300.334,
			2304.734,
			2306.204,
			2307.676,
			2312.099,
			2315.056,
			2318.019,
			2319.503,
			2322.476,
			2325.454,
			2326.947,
			2329.936,
			2331.432,
			2334.429,
			2337.432,
			2341.949,
			2344.968,
			2349.506,
			2351.022,
			2352.539,
			2357.101,
			2360.147,
			2364.728,
			2367.788,
			2370.854,
			2372.389,
			2375.464,
			2377.004,
			2380.088,
			2383.178,
			2384.726,
			2386.274,
			2390.929,
			2394.039,
			2398.715,
			2401.839,
			2404.969,
			2406.535,
			2409.673,
			2411.243,
			2414.389,
			2417.539,
			2422.275,
			2425.44,
			2430.197,
			2431.785,
			2433.374,
			2438.148,
			2441.335,
			2446.121,
			2449.314,
			2454.098,
			2457.275,
			2458.857,
			2461.999,
			2465.11,
			2466.652,
			2469.708,
			2472.728,
			2477.188,
			2480.112,
			2484.427,
			2485.846,
			2487.255,
			2491.427,
			2494.16,
			2498.19,
			2500.827,
			2503.427,
			2507.255,
			2508.513,
			2509.761,
			2513.45,
			2514.661,
			2515.864,
			2519.421,
			2521.754,
			2524.057,
			2525.194,
			2527.443,
			2529.653,
			2530.744,
			2532.898,
			2533.961,
			2536.057,
			2538.133,
			2541.143,
			2543.106,
			2545.988,
			2546.936,
			2547.878,
			2550.664,
			2552.503,
			2555.231,
			2557.038,
			2559.747,
			2561.551,
			2562.454,
			2563.357,
			2566.994,
			2567.905,
			2570.667,
			2572.517,
			2574.382,
			2575.322,
			2577.204,
			2579.097,
			2580.05,
			2582.916,
			2584.84,
			2586.77,
			2589.679,
			2591.628,
			2594.56,
			2595.541,
			2596.521,
			2599.469,
			2601.441,
			2604.402,
			2606.381,
			2609.347,
			2611.322,
			2612.308,
			2614.281,
			2617.239,
			2619.207,
			2621.174,
			2623.134,
			2625.098,
			2626.08,
			2628.041,
			2630.009,
			2630.994,
			2632.964,
			2633.95,
			2635.921,
			2638.878,
			2640.85,
			2642.82,
			2645.783,
			2646.771,
			2647.759,
			2650.721,
			2652.692,
			2655.651,
			2657.622,
			2659.594,
			2660.58,
			2662.549,
			2663.531,
			2665.496,
			2667.455,
			2668.432,
			2670.388,
			2672.337,
			2675.25,
			2677.184,
			2680.07,
			2681.026,
			2681.982,
			2683.892,
			2684.844,
			2686.743,
			2688.637,
			2691.466,
			2693.347,
			2696.172,
			2697.114,
			2698.055,
			2700.891,
			2702.785,
			2705.639,
			2707.549,
			2709.467,
			2710.429,
			2712.357,
			2714.291,
			2715.258,
			2717.196,
			2718.167,
			2720.113,
			2722.062,
			2724.997,
			2725.978,
			2726.959,
			2729.911,
			2730.897,
			2731.883,
			2734.849,
			2736.834,
			2739.815,
			2741.806,
			2743.795,
			2744.788,
			2746.771,
			2747.762,
			2748.753,
			2752.704,
			2753.692,
			2756.653,
			2758.629,
			2760.611,
			2761.603,
			2763.591,
			2765.589,
			2766.592,
			2768.602,
			2769.612,
			2771.642,
			2773.682,
			2776.754,
			2778.816,
			2781.915,
			2782.952,
			2783.992,
			2787.113,
			2789.205,
			2792.342,
			2794.444,
			2796.545,
			2797.598,
			2799.71,
			2800.766,
			2801.823,
			2804.986,
			2806.038,
			2808.143,
			2810.252,
			2812.362,
			2814.48,
			2815.538,
			2817.662,
			2819.793,
			2820.859,
			2822.997,
			2824.069,
			2826.212,
			2828.363,
			2831.59,
			2833.748,
			2836.984,
			2838.065,
			2839.148,
			2842.409,
			2844.594,
			2847.914,
			2850.148,
			2852.418,
			2853.557,
			2855.857,
			2857.017,
			2859.343,
			2862.845,
			2865.205,
			2867.577,
			2871.144,
			2872.334,
			2873.527,
			2877.106,
			2878.302,
			2879.5,
			2881.904,
			2883.108,
			2885.527,
			2887.958,
			2891.622,
			2894.077,
			2897.77,
			2899.004,
			2900.241,
			2903.965,
			2906.463,
			2910.229,
			2912.755,
			2915.29,
			2916.561,
			2919.107,
			2921.658,
			2922.936,
			2925.496,
			2926.776,
			2929.342,
			2931.913,
			2935.78,
			2938.366,
			2942.255,
			2943.552,
			2944.851,
			2948.76,
			2951.379,
			2955.326,
			2957.969,
			2960.619,
			2961.948,
			2964.611,
			2965.945,
			2967.28,
			2971.296,
			2972.636,
			2973.979,
			2978.013,
			2980.71,
			2983.412,
			2984.766,
			2987.479,
			2990.199,
			2991.563,
			2994.299,
			2995.668,
			2998.417,
			3001.171,
			3005.318,
			3008.09,
			3012.26,
			3013.653,
			3015.048,
			3019.245,
			3022.051,
			3026.272,
			3029.095,
			3031.924,
			3033.341,
			3036.179,
			3037.602,
			3040.45,
			3043.306,
			3044.736,
			3046.168,
			3050.475,
			3053.354,
			3057.685,
			3060.58,
			3063.481,
			3064.935,
			3069.305,
			3072.226,
			3075.154,
			3079.557,
			3082.501,
			3086.929,
			3088.408,
			3089.889,
			3094.34,
			3097.315,
			3101.791,
			3104.783,
			3107.781,
			3109.282,
			3112.289,
			3113.795,
			3116.812,
			3119.836,
			3121.35,
			3124.384,
			3127.423,
			3130.468,
			3133.52,
			3135.049,
			3138.111,
			3141.178,
			3142.714,
			3145.791,
			3147.331,
			3150.416,
			3153.506,
			3158.155,
			3161.26,
			3165.926,
			3167.484,
			3169.043,
			3173.724,
			3176.85,
			3181.549,
			3184.687,
			3187.833,
			3189.408,
			3192.561,
			3194.135,
			3197.275,
			3200.391,
			3201.938,
			3205.003,
			3208.03,
			3212.494,
			3213.963,
			3215.424,
			3219.749,
			3221.172,
			3222.585,
			3226.768,
			3229.511,
			3233.556,
			3236.205,
			3238.818,
			3240.111,
			3242.666,
			3243.928,
			3245.182,
			3248.886,
			3250.103,
			3251.31,
			3254.881,
			3257.219,
			3259.523,
			3260.66,
			3262.907,
			3265.117,
			3266.208,
			3268.361,
			3269.423,
			3271.519,
			3273.579,
			3276.6,
			3278.568,
			3281.449,
			3282.391,
			3283.324,
			3286.072,
			3287.862,
			3290.486,
			3292.19,
			3294.691,
			3296.302,
			3297.08,
			3297.862,
			3300.17,
			3300.917,
			3301.653,
			3303.809,
			3305.21,
			3306.586,
			3307.266,
			3308.612,
			3309.946,
			3310.609,
			3311.925,
			3312.58,
			3313.892,
			3315.208,
			3317.208,
			3318.555,
			3320.586,
			3321.278,
			3321.984,
			3324.114,
			3325.541,
			3327.744,
			3329.226,
			3330.711,
			3331.467,
			3332.996,
			3333.76,
			3335.289,
			3336.835,
			3337.614,
			3339.17,
			3340.725,
			3342.297,
			3343.868,
			3344.651,
			3346.219,
			3347.799,
			3348.586,
			3350.153,
			3350.934,
			3352.498,
			3354.052,
			3356.366,
			3357.904,
			3360.193,
			3360.952,
			3361.712,
			3363.974,
			3365.469,
			3367.722,
			3369.216,
			3370.699,
			3371.441,
			3372.922,
			3373.658,
			3375.124,
			3376.584,
			3377.312,
			3378.762,
			3380.21,
			3382.375,
			3383.093,
			3383.81,
			3385.959,
			3386.674,
			3387.387,
			3389.522,
			3390.948,
			3392.363,
			3394.485,
			3395.906,
			3398.004,
			3398.704,
			3399.405,
			3401.508,
			3402.881,
			3404.954,
			3406.344,
			3408.384,
			3409.756,
			3411.828,
			3413.203,
			3413.895,
			3415.288,
			3416.694,
			3418.823,
			3420.259,
			3422.447,
			3423.186,
			3423.927,
			3426.173,
			3427.71,
			3430.047,
			3431.626,
			3433.238,
			3434.048,
			3435.68,
			3436.504,
			3438.177,
			3440.685,
			3442.37,
			3444.073,
			3445.789,
			3447.52,
			3448.391,
			3450.142,
			3451.908,
			3452.8,
			3455.493,
			3457.292,
			3459.124,
			3461.895,
			3463.752,
			3466.561,
			3467.5,
			3468.442,
			3471.286,
			3473.196,
			3476.082,
			3478.02,
			3479.97,
			3480.949,
			3482.915,
			3483.902,
			3485.886,
			3487.882,
			3488.885,
			3489.892,
			3492.931,
			3494.977,
			3497.034,
			3498.087,
			3500.168,
			3502.261,
			3503.314,
			3506.471,
			3508.582,
			3510.686,
			3513.906,
			3516.055,
			3519.305,
			3520.394,
			3521.485,
			3524.789,
			3527.007,
			3530.358,
			3532.605,
			3534.866,
			3536,
			3538.28,
			3539.429,
			3541.734,
			3544.058,
			3545.225,
			3547.568,
			3549.946,
			3553.541,
			3554.753,
			3555.967,
			3559.631,
			3560.858,
			3562.086,
			3564.535,
			3565.761,
			3568.214,
			3571.877,
			3574.318,
			3576.76,
			3580.428,
			3581.655,
			3582.883,
			3586.579,
			3589.049,
			3592.761,
			3595.235,
			3597.717,
			3598.955,
			3601.435,
			3602.678,
			3605.158,
			3607.646,
			3608.888,
			3611.376,
			3613.87,
			3617.615,
			3620.111,
			3623.865,
			3625.116,
			3626.37,
			3630.15,
			3632.683,
			3635.237,
			3639.07,
			3641.658,
			3642.955,
			3645.589,
			3646.895,
			3648.201,
			3652.13,
			3654.718,
			3658.602,
			3661.156,
			3663.675,
			3664.922,
			3667.386,
			3669.812,
			3671.011,
			3673.383,
			3674.551,
			3676.861,
			3679.132,
			3682.464,
			3684.636,
			3687.821,
			3688.864,
			3689.897,
			3692.941,
			3694.925,
			3697.834,
			3699.731,
			3701.593,
			3702.51,
			3704.321,
			3705.214,
			3706.975,
			3708.699,
			3709.547,
			3710.386,
			3712.849,
			3714.448,
			3716.011,
			3716.779,
			3718.289,
			3719.764,
			3720.488,
			3722.617,
			3723.986,
			3725.319,
			3727.257,
			3728.509,
			3730.31,
			3730.895,
			3731.473,
			3733.172,
			3734.276,
			3735.892,
			3736.952,
			3738.002,
			3738.525,
			3739.583,
			3740.111,
			3741.165,
			3742.219,
			3742.746,
			3743.808,
			3744.913,
			3746.571,
			3747.125,
			3747.68,
			3748.802,
			3750.004,
			3750.606,
			3752.418,
			3753.634,
			3754.916,
			3756.855,
			3758.157,
			3760.139,
			3760.805,
			3761.472,
			3763.484,
			3764.825,
			3766.837,
			3768.184,
			3770.194,
			3771.529,
			3772.196,
			3773.531,
			3775.524,
			3776.846,
			3778.167,
			3780.173,
			3780.841,
			3781.508,
			3783.5,
			3784.165,
			3784.835,
			3786.828,
			3788.148,
			3790.132,
			3791.444,
			3792.75,
			3794.721,
			3795.386,
			3796.05,
			3798.712,
			3799.381,
			3801.389,
			3802.729,
			3804.07,
			3804.732,
			3806.056,
			3807.384,
			3808.049,
			3809.385,
			3810.054,
			3811.392,
			3812.733,
			3814.741,
			3816.066,
			3818.07,
			3818.742,
			3819.41,
			3821.396,
			3822.734,
			3824.744,
			3826.074,
			3828.077,
			3829.419,
			3830.075,
			3831.383,
			3832.677,
			3833.317,
			3833.952,
			3835.885,
			3837.165,
			3838.438,
			3839.082,
			3840.433,
			3841.781,
			3842.453,
			3843.789,
			3844.474,
			3845.887,
			3847.289,
			3849.384,
			3850.828,
			3852.974,
			3853.685,
			3854.396,
			3856.542,
			3857.962,
			3860.079,
			3861.476,
			3863.563,
			3864.948,
			3865.636,
			3867.007,
			3868.376,
			3869.061,
			3870.419,
			3871.771,
			3873.799,
			3875.155,
			3876.51,
			3877.868,
			3878.547,
			3879.909,
			3880.588,
			3881.943,
			3883.296,
			3885.331,
			3886.683,
			3888.705,
			3889.379,
			3890.056,
			3892.076,
			3893.42,
			3895.445,
			3896.793,
			3898.816,
			3900.165,
			3901.512,
			3902.186,
			3903.539,
			3904.219,
			3905.571,
			3906.923,
			3908.962,
			3909.645,
			3910.324,
			3912.369,
			3913.055,
			3913.743,
			3915.815,
			3917.197,
			3919.282,
			3920.676,
			3922.069,
			3924.164,
			3924.864,
			3925.558,
			3927.64,
			3928.334,
			3929.028,
			3931.104,
			3932.484,
			3933.865,
			3934.555,
			3935.93,
			3937.304,
			3937.991,
			3940.048,
			3941.41,
			3942.772,
			3944.807,
			3945.489,
			3946.171,
			3948.243,
			3948.937,
			3949.633,
			3951.754,
			3953.187,
			3955.369,
			3956.849,
			3959.098,
			3960.615,
			3961.38,
			3962.147,
			3965.255,
			3966.037,
			3968.406,
			3970.003,
			3971.611,
			3972.42,
			3974.049,
			3975.692,
			3976.518,
			3978.992,
			3980.649,
			3982.321,
			3984.851,
			3986.548,
			3989.115,
			3989.977,
			3990.838,
			3993.437,
			3995.184,
			3997.826,
			3999.602,
			4001.39,
			4002.288,
			4004.093,
			4004.999,
			4006.821,
			4008.654,
			4009.574,
			4011.43,
			4013.298,
			4016.129,
			4017.081,
			4018.036,
			4019.955,
			4021.887,
			4022.857,
			4025.786,
			4027.753,
			4030.726,
			4032.725,
			4034.734,
			4037.781,
			4038.803,
			4039.827,
			4042.926,
			4044.993,
			4048.107,
			4050.196,
			4052.292,
			4053.343,
			4055.453,
			4056.513,
			4058.639,
			4061.848,
			4064.001,
			4066.164,
			4069.427,
			4070.519,
			4071.615,
			4074.917,
			4076.024,
			4077.136,
			4080.486,
			4082.74,
			4086.141,
			4088.43,
			4090.729,
			4094.212,
			4095.378,
			4096.548,
			4100.079,
			4101.261,
			4102.448,
			4106.021,
			4108.423,
			4110.834,
			4112.046,
			4114.478,
			4116.922,
			4118.149,
			4120.612,
			4121.849,
			4124.332,
			4126.825,
			4130.593,
			4133.119,
			4136.929,
			4138.21,
			4139.494,
			4143.369,
			4145.971,
			4149.906,
			4152.544,
			4155.198,
			4159.202,
			4160.542,
			4161.884,
			4165.924,
			4167.278,
			4168.642,
			4172.748,
			4175.508,
			4178.271,
			4179.65,
			4182.413,
			4185.178,
			4186.563,
			4190.735,
			4193.507,
			4196.337,
			4200.574,
			4203.402,
			4207.643,
			4209.06,
			4210.478,
			4214.735,
			4217.574,
			4221.838,
			4224.673,
			4228.907,
			4231.749,
			4234.607,
			4236.001,
			4238.834,
			4240.249,
			4243.083,
			4245.939,
			4250.165,
			4251.58,
			4252.997,
			4255.814,
			4258.659,
			4260.069,
			4262.871,
			4264.282,
			4267.108,
			4269.94,
			4274.195,
			4277.032,
			4281.288,
			4282.708,
			4284.128,
			4288.396,
			4291.243,
			4295.52,
			4298.375,
			4301.233,
			4302.665,
			4305.529,
			4306.963,
			4309.835,
			4312.713,
			4314.154,
			4317.041,
			4319.933,
			4324.281,
			4325.733,
			4327.187,
			4331.557,
			4333.018,
			4334.48,
			4337.41,
			4338.877,
			4341.816,
			4344.764,
			4349.204,
			4352.174,
			4356.639,
			4358.13,
			4359.622,
			4364.104,
			4367.101,
			4371.613,
			4374.632,
			4377.662,
			4379.179,
			4382.215,
			4386.777,
			4389.822,
			4391.347,
			4394.399,
			4397.457,
			4402.053,
			4405.125,
			4409.751,
			4411.299,
			4412.849,
			4417.499,
			4420.604,
			4425.264,
			4428.375,
			4431.493,
			4436.183,
			4437.75,
			4439.319,
			4444.035,
			4445.609,
			4447.184,
			4451.916,
			4455.077,
			4458.242,
			4459.827,
			4463,
			4466.177,
			4467.768,
			4470.953,
			4472.548,
			4475.737,
			4478.928,
			4483.716,
			4486.91,
			4491.711,
			4493.315,
			4494.921,
			4499.75,
			4502.975,
			4507.82,
			4511.055,
			4514.292,
			4515.913,
			4519.156,
			4520.78,
			4524.033,
			4528.923,
			4532.189,
			4535.458,
			4538.727,
			4541.999,
			4543.637,
			4546.915,
			4550.199,
			4551.841,
			4556.782,
			4560.101,
			4563.401,
			4568.351,
			4571.653,
			4574.742
		],
		[
			0,
			0.057,
			0.117,
			0.164,
			0.18,
			0.242,
			0.273,
			0.305,
			0.368,
			0.381,
			0.43,
			0.49,
			0.553,
			0.598,
			0.678,
			0.707,
			0.739,
			0.814,
			0.864,
			0.925,
			0.988,
			1.034,
			1.05,
			1.111,
			1.142,
			1.173,
			1.236,
			1.25,
			1.297,
			1.36,
			1.422,
			1.467,
			1.481,
			1.545,
			1.574,
			1.607,
			1.669,
			1.685,
			1.731,
			1.794,
			1.854,
			1.9,
			1.979,
			2.008,
			2.042,
			2.103,
			2.124,
			2.165,
			2.228,
			2.289,
			2.336,
			2.35,
			2.411,
			2.443,
			2.474,
			2.538,
			2.553,
			2.598,
			2.66,
			2.722,
			2.769,
			2.786,
			2.845,
			2.878,
			2.908,
			2.97,
			2.986,
			3.031,
			3.094,
			3.157,
			3.203,
			3.28,
			3.313,
			3.342,
			3.42,
			3.466,
			3.528,
			3.59,
			3.636,
			3.653,
			3.713,
			3.745,
			3.778,
			3.855,
			3.899,
			3.962,
			4.023,
			4.072,
			4.149,
			4.183,
			4.211,
			4.274,
			4.336,
			4.396,
			4.458,
			4.522,
			4.583,
			4.612,
			4.645,
			4.707,
			4.723,
			4.769,
			4.831,
			4.893,
			4.941,
			4.955,
			5.016,
			5.047,
			5.078,
			5.142,
			5.156,
			5.204,
			5.265,
			5.328,
			5.374,
			5.452,
			5.483,
			5.513,
			5.576,
			5.637,
			5.699,
			5.762,
			5.808,
			5.823,
			5.885,
			5.916,
			5.946,
			6.008,
			6.023,
			6.07,
			6.134,
			6.194,
			6.258,
			6.319,
			6.349,
			6.382,
			6.442,
			6.459,
			6.504,
			6.568,
			6.63,
			6.675,
			6.754,
			6.783,
			6.816,
			6.876,
			6.939,
			7,
			7.064,
			7.125,
			7.186,
			7.218,
			7.247,
			7.311,
			7.327,
			7.375,
			7.437,
			7.497,
			7.544,
			7.559,
			7.623,
			7.653,
			7.682,
			7.746,
			7.762,
			7.807,
			7.87,
			7.931,
			7.993,
			8.055,
			8.086,
			8.118,
			8.181,
			8.241,
			8.309,
			8.366,
			8.411,
			8.428,
			8.489,
			8.522,
			8.553,
			8.614,
			8.63,
			8.676,
			8.737,
			8.8,
			8.845,
			8.862,
			8.924,
			8.954,
			8.986,
			9.062,
			9.111,
			9.17,
			9.232,
			9.279,
			9.359,
			9.389,
			9.421,
			9.482,
			9.544,
			9.607,
			9.666,
			9.714,
			9.73,
			9.79,
			9.822,
			9.854,
			9.917,
			9.93,
			9.979,
			10.041,
			10.102,
			10.149,
			10.225,
			10.257,
			10.289,
			10.348,
			10.412,
			10.475,
			10.537,
			10.583,
			10.599,
			10.661,
			10.692,
			10.721,
			10.785,
			10.798,
			10.846,
			10.908,
			10.97,
			11.015,
			11.032,
			11.095,
			11.126,
			11.157,
			11.232,
			11.281,
			11.342,
			11.404,
			11.449,
			11.528,
			11.558,
			11.59,
			11.667,
			11.713,
			11.778,
			11.839,
			11.885,
			11.9,
			11.961,
			11.994,
			12.023,
			12.086,
			12.1,
			12.148,
			12.21,
			12.273,
			12.32,
			12.334,
			12.396,
			12.431,
			12.457,
			12.521,
			12.533,
			12.583,
			12.643,
			12.707,
			12.753,
			12.829,
			12.861,
			12.893,
			12.953,
			13.015,
			13.078,
			13.141,
			13.187,
			13.201,
			13.263,
			13.295,
			13.325,
			13.389,
			13.405,
			13.449,
			13.513,
			13.575,
			13.62,
			13.637,
			13.699,
			13.729,
			13.759,
			13.823,
			13.883,
			13.947,
			14.009,
			14.072,
			14.131,
			14.163,
			14.195,
			14.273,
			14.32,
			14.381,
			14.443,
			14.503,
			14.566,
			14.598,
			14.63,
			14.69,
			14.706,
			14.753,
			14.816,
			14.878,
			14.922,
			14.939,
			14.999,
			15.032,
			15.063,
			15.125,
			15.187,
			15.248,
			15.31,
			15.357,
			15.373,
			15.435,
			15.467,
			15.496,
			15.56,
			15.573,
			15.622,
			15.681,
			15.744,
			15.79,
			15.807,
			15.87,
			15.898,
			15.931,
			15.993,
			16.008,
			16.054,
			16.116,
			16.178,
			16.225,
			16.303,
			16.333,
			16.364,
			16.428,
			16.489,
			16.555,
			16.614,
			16.661,
			16.674,
			16.737,
			16.769,
			16.8,
			16.861,
			16.875,
			16.922,
			16.987,
			17.048,
			17.092,
			17.109,
			17.172,
			17.203,
			17.234,
			17.311,
			17.357,
			17.42,
			17.481,
			17.528,
			17.606,
			17.636,
			17.666,
			17.73,
			17.792,
			17.853,
			17.916,
			17.96,
			17.978,
			18.039,
			18.07,
			18.1,
			18.163,
			18.179,
			18.226,
			18.286,
			18.348,
			18.396,
			18.412,
			18.472,
			18.505,
			18.534,
			18.597,
			18.616,
			18.66,
			18.722,
			18.785,
			18.831,
			18.907,
			18.939,
			18.971,
			19.031,
			19.048,
			19.093,
			19.157,
			19.216,
			19.265,
			19.28,
			19.342,
			19.374,
			19.404,
			19.481,
			19.526,
			19.59,
			19.652,
			19.713,
			19.775,
			19.806,
			19.836,
			19.899,
			19.96,
			20.023,
			20.083,
			20.131,
			20.148,
			20.21,
			20.239,
			20.271,
			20.333,
			20.349,
			20.395,
			20.457,
			20.52,
			20.567,
			20.58,
			20.644,
			20.679,
			20.704,
			20.766,
			20.781,
			20.828,
			20.891,
			20.952,
			20.998,
			21.076,
			21.109,
			21.14,
			21.217,
			21.262,
			21.325,
			21.386,
			21.432,
			21.448,
			21.51,
			21.543,
			21.572,
			21.637,
			21.649,
			21.696,
			21.76,
			21.822,
			21.868,
			21.883,
			21.945,
			21.977,
			22.007,
			22.069,
			22.086,
			22.131,
			22.195,
			22.256,
			22.302,
			22.38,
			22.41,
			22.443,
			22.503,
			22.567,
			22.631,
			22.691,
			22.74,
			22.751,
			22.815,
			22.845,
			22.874,
			22.937,
			22.954,
			23,
			23.062,
			23.124,
			23.17,
			23.186,
			23.246,
			23.278,
			23.309,
			23.386,
			23.432,
			23.496,
			23.558,
			23.604,
			23.68,
			23.714,
			23.744,
			23.807,
			23.869,
			23.929,
			23.991,
			24.039,
			24.054,
			24.116,
			24.147,
			24.176,
			24.255,
			24.301,
			24.363,
			24.427,
			24.473,
			24.548,
			24.581,
			24.612,
			24.674,
			24.737,
			24.802,
			24.861,
			24.908,
			24.984,
			25.015,
			25.046,
			25.108,
			25.171,
			25.232,
			25.292,
			25.341,
			25.355,
			25.416,
			25.447,
			25.478,
			25.541,
			25.558,
			25.604,
			25.666,
			25.726,
			25.775,
			25.85,
			25.881,
			25.913,
			25.975,
			26.037,
			26.1,
			26.163,
			26.223,
			26.287,
			26.316,
			26.347,
			26.41,
			26.425,
			26.473,
			26.534,
			26.594,
			26.642,
			26.657,
			26.72,
			26.752,
			26.78,
			26.844,
			26.864,
			26.907,
			26.966,
			27.031,
			27.077,
			27.154,
			27.185,
			27.214,
			27.279,
			27.34,
			27.403,
			27.464,
			27.51,
			27.525,
			27.588,
			27.62,
			27.648,
			27.712,
			27.726,
			27.774,
			27.837,
			27.899,
			27.943,
			27.961,
			28.023,
			28.054,
			28.084,
			28.147,
			28.16,
			28.209,
			28.27,
			28.331,
			28.378,
			28.457,
			28.488,
			28.516,
			28.581,
			28.642,
			28.705,
			28.766,
			28.812,
			28.828,
			28.889,
			28.925,
			28.952,
			29.013,
			29.031,
			29.076,
			29.139,
			29.199,
			29.248,
			29.325,
			29.356,
			29.387,
			29.448,
			29.51,
			29.572,
			29.634,
			29.681,
			29.759,
			29.79,
			29.82,
			29.897,
			29.943,
			30.007,
			30.067,
			30.113,
			30.131,
			30.193,
			30.223,
			30.255,
			30.316,
			30.331,
			30.378,
			30.442,
			30.503,
			30.549,
			30.625,
			30.656,
			30.69,
			30.752,
			30.813,
			30.876,
			30.937,
			30.998,
			31.062,
			31.091,
			31.122,
			31.185,
			31.198,
			31.245,
			31.309,
			31.369,
			31.416,
			31.433,
			31.495,
			31.526,
			31.557,
			31.618,
			31.632,
			31.681,
			31.742,
			31.804,
			31.85,
			31.928,
			31.96,
			31.991,
			32.053,
			32.114,
			32.178,
			32.239,
			32.283,
			32.301,
			32.364,
			32.393,
			32.423,
			32.503,
			32.549,
			32.609,
			32.673,
			32.72,
			32.735,
			32.796,
			32.827,
			32.86,
			32.921,
			32.937,
			32.981,
			33.048,
			33.107,
			33.153,
			33.232,
			33.26,
			33.293,
			33.37,
			33.416,
			33.48,
			33.542,
			33.587,
			33.604,
			33.666,
			33.695,
			33.728,
			33.803,
			33.85,
			33.915,
			33.975,
			34.036,
			34.098,
			34.129,
			34.162,
			34.224,
			34.286,
			34.346,
			34.349,
			34.41,
			34.456,
			34.534,
			34.563,
			34.594,
			34.657,
			34.674,
			34.72,
			34.782,
			34.844,
			34.891,
			34.905,
			34.966,
			34.999,
			35.029,
			35.09,
			35.11,
			35.154,
			35.215,
			35.277,
			35.323,
			35.402,
			35.432,
			35.463,
			35.525,
			35.587,
			35.648,
			35.71,
			35.773,
			35.836,
			35.866,
			35.896,
			35.96,
			35.975,
			36.021,
			36.083,
			36.145,
			36.192,
			36.207,
			36.271,
			36.301,
			36.331,
			36.395,
			36.408,
			36.455,
			36.516,
			36.579,
			36.642,
			36.705,
			36.735,
			36.765,
			36.829,
			36.89,
			36.953,
			37.015,
			37.059,
			37.075,
			37.139,
			37.173,
			37.199,
			37.278,
			37.325,
			37.387,
			37.448,
			37.493,
			37.51,
			37.571,
			37.604,
			37.633,
			37.71,
			37.757,
			37.82,
			37.882,
			37.929,
			38.006,
			38.037,
			38.068,
			38.129,
			38.192,
			38.253,
			38.315,
			38.376,
			38.439,
			38.472,
			38.499,
			38.579,
			38.626,
			38.687,
			38.748,
			38.796,
			38.813,
			38.875,
			38.905,
			38.936,
			38.999,
			39.06,
			39.125,
			39.185,
			39.235,
			39.307,
			39.339,
			39.371,
			39.433,
			39.492,
			39.555,
			39.617,
			39.678,
			39.74,
			39.771,
			39.803,
			39.866,
			39.882,
			39.928,
			39.991,
			40.049,
			40.097,
			40.114,
			40.176,
			40.206,
			40.238,
			40.299,
			40.36,
			40.423,
			40.486,
			40.531,
			40.609,
			40.641,
			40.671,
			40.733,
			40.749,
			40.796,
			40.86,
			40.92,
			40.965,
			40.981,
			41.043,
			41.074,
			41.104,
			41.166,
			41.182,
			41.229,
			41.297,
			41.353,
			41.416,
			41.477,
			41.507,
			41.54,
			41.6,
			41.664,
			41.727,
			41.787,
			41.849,
			41.91,
			41.943,
			41.974,
			42.052,
			42.097,
			42.16,
			42.221,
			42.267,
			42.283,
			42.343,
			42.377,
			42.407,
			42.469,
			42.485,
			42.531,
			42.597,
			42.654,
			42.716,
			42.778,
			42.81,
			42.842,
			42.92,
			42.964,
			43.028,
			43.089,
			43.135,
			43.152,
			43.213,
			43.245,
			43.276,
			43.337,
			43.358,
			43.399,
			43.463,
			43.523,
			43.57,
			43.583,
			43.648,
			43.678,
			43.708,
			43.772,
			43.834,
			43.896,
			43.956,
			44.004,
			44.082,
			44.112,
			44.144,
			44.221,
			44.267,
			44.332,
			44.393,
			44.439,
			44.453,
			44.514,
			44.547,
			44.578,
			44.64,
			44.654,
			44.701,
			44.764,
			44.825,
			44.873,
			44.949,
			44.981,
			45.01,
			45.089,
			45.135,
			45.198,
			45.26,
			45.307,
			45.383,
			45.42,
			45.446,
			45.506,
			45.523,
			45.57,
			45.631,
			45.694,
			45.757,
			45.817,
			45.85,
			45.878,
			45.943,
			45.957,
			46.004,
			46.065,
			46.128,
			46.19,
			46.252,
			46.281,
			46.314,
			46.375,
			46.437,
			46.498,
			46.562,
			46.625,
			46.687,
			46.716,
			46.749,
			46.809,
			46.826,
			46.873,
			46.933,
			46.995,
			47.041,
			47.058,
			47.12,
			47.15,
			47.182,
			47.258,
			47.304,
			47.369,
			47.43,
			47.482,
			47.554,
			47.585,
			47.617,
			47.693,
			47.741,
			47.802,
			47.863,
			47.911,
			47.925,
			47.988,
			48.019,
			48.05,
			48.112,
			48.128,
			48.175,
			48.237,
			48.298,
			48.36,
			48.42,
			48.454,
			48.485,
			48.56,
			48.608,
			48.67,
			48.73,
			48.794,
			48.856,
			48.887,
			48.918,
			48.996,
			49.042,
			49.103,
			49.164,
			49.211,
			49.228,
			49.29,
			49.322,
			49.352,
			49.428,
			49.476,
			49.543,
			49.601,
			49.647,
			49.725,
			49.754,
			49.785,
			49.848,
			49.909,
			49.971,
			50.033,
			50.079,
			50.158,
			50.189,
			50.219,
			50.282,
			50.298,
			50.342,
			50.41,
			50.466,
			50.513,
			50.531,
			50.592,
			50.624,
			50.653,
			50.717,
			50.733,
			50.777,
			50.841,
			50.903,
			50.949,
			51.025,
			51.057,
			51.087,
			51.167,
			51.213,
			51.276,
			51.337,
			51.397,
			51.46,
			51.492,
			51.52,
			51.583,
			51.605,
			51.646,
			51.707,
			51.77,
			51.831,
			51.894,
			51.926,
			51.956,
			52.016,
			52.033,
			52.081,
			52.146,
			52.203,
			52.25,
			52.329,
			52.36,
			52.39,
			52.453,
			52.513,
			52.576,
			52.639,
			52.701,
			52.763,
			52.794,
			52.826,
			52.887,
			52.9,
			52.949,
			53.01,
			53.072,
			53.135,
			53.195,
			53.228,
			53.258,
			53.322,
			53.336,
			53.382,
			53.444,
			53.508,
			53.57,
			53.63,
			53.666,
			53.693,
			53.771,
			53.817,
			53.878,
			53.94,
			54.002,
			54.063,
			54.096,
			54.125,
			54.204,
			54.25,
			54.314,
			54.373,
			54.422,
			54.498,
			54.53,
			54.56,
			54.622,
			54.685,
			54.746,
			54.81,
			54.872,
			54.932,
			54.963,
			54.996,
			55.057,
			55.12,
			55.181,
			55.244,
			55.303,
			55.366,
			55.397,
			55.428,
			55.49,
			55.507,
			55.553,
			55.614,
			55.677,
			55.727,
			55.802,
			55.83,
			55.863,
			55.94,
			55.985,
			56.048,
			56.11,
			56.173,
			56.236,
			56.266,
			56.297,
			56.358,
			56.374,
			56.422,
			56.482,
			56.545,
			56.591,
			56.607,
			56.67,
			56.701,
			56.731,
			56.793,
			56.81,
			56.855,
			56.918,
			56.98,
			57.025,
			57.103,
			57.134,
			57.166,
			57.228,
			57.289,
			57.352,
			57.412,
			57.46,
			57.475,
			57.537,
			57.569,
			57.598,
			57.66,
			57.676,
			57.722,
			57.786,
			57.846,
			57.91,
			57.971,
			58.003,
			58.035,
			58.096,
			58.11,
			58.157,
			58.22,
			58.282,
			58.342,
			58.406,
			58.435,
			58.466,
			58.528,
			58.592,
			58.653,
			58.714,
			58.778,
			58.84,
			58.87,
			58.901,
			58.963,
			58.98,
			59.025,
			59.089,
			59.148,
			59.211,
			59.273,
			59.303,
			59.333,
			59.412,
			59.461,
			59.526,
			59.583,
			59.629,
			59.708,
			59.739,
			59.769,
			59.832,
			59.852,
			59.893,
			59.956,
			60.016,
			60.065,
			60.078,
			60.14,
			60.174,
			60.203,
			60.266,
			60.282,
			60.328,
			60.39,
			60.452,
			60.499,
			60.575,
			60.608,
			60.638,
			60.698,
			60.763,
			60.823,
			60.886,
			60.947,
			61.01,
			61.041,
			61.071,
			61.148,
			61.195,
			61.256,
			61.321,
			61.365,
			61.381,
			61.443,
			61.475,
			61.505,
			61.582,
			61.63,
			61.692,
			61.754,
			61.801,
			61.879,
			61.914,
			61.941,
			62.003,
			62.065,
			62.127,
			62.189,
			62.249,
			62.312,
			62.344,
			62.373,
			62.452,
			62.499,
			62.559,
			62.62,
			62.667,
			62.681,
			62.745,
			62.777,
			62.808,
			62.87,
			62.884,
			62.931,
			62.999,
			63.056,
			63.103,
			63.178,
			63.21,
			63.241,
			63.304,
			63.366,
			63.428,
			63.49,
			63.537,
			63.55,
			63.613,
			63.644,
			63.676,
			63.739,
			63.754,
			63.799,
			63.863,
			63.923,
			63.987,
			64.047,
			64.079,
			64.11,
			64.17,
			64.187,
			64.233,
			64.294,
			64.357,
			64.405,
			64.48,
			64.513,
			64.542,
			64.607,
			64.669,
			64.733,
			64.791,
			64.84,
			64.854,
			64.915,
			64.948,
			64.978,
			65.041,
			65.055,
			65.103,
			65.163,
			65.225,
			65.272,
			65.289,
			65.35,
			65.381,
			65.412,
			65.491,
			65.537,
			65.6,
			65.66,
			65.706,
			65.723,
			65.783,
			65.814,
			65.845,
			65.923,
			65.97,
			66.037,
			66.095,
			66.14,
			66.156,
			66.216,
			66.25,
			66.281,
			66.343,
			66.357,
			66.404,
			66.466,
			66.528,
			66.574,
			66.653,
			66.683,
			66.715,
			66.775,
			66.838,
			66.9,
			66.963,
			67.007,
			67.025,
			67.087,
			67.118,
			67.147,
			67.21,
			67.227,
			67.273,
			67.335,
			67.396,
			67.444,
			67.459,
			67.522,
			67.552,
			67.582,
			67.644,
			67.66,
			67.707,
			67.77,
			67.83,
			67.878,
			67.953,
			67.983,
			68.016,
			68.079,
			68.14,
			68.204,
			68.264,
			68.312,
			68.325,
			68.389,
			68.42,
			68.449,
			68.528,
			68.576,
			68.637,
			68.7,
			68.745,
			68.76,
			68.823,
			68.855,
			68.886,
			68.945,
			68.963,
			69.007,
			69.071,
			69.131,
			69.179,
			69.257,
			69.286,
			69.32,
			69.379,
			69.442,
			69.504,
			69.565,
			69.612,
			69.629,
			69.691,
			69.722,
			69.753,
			69.814,
			69.831,
			69.875,
			69.939,
			69.999,
			70.047,
			70.125,
			70.161,
			70.187,
			70.263,
			70.31,
			70.374,
			70.434,
			70.48,
			70.498,
			70.558,
			70.59,
			70.62,
			70.683,
			70.699,
			70.744,
			70.808,
			70.869,
			70.914,
			70.931,
			70.994,
			71.025,
			71.054,
			71.116,
			71.131,
			71.178,
			71.242,
			71.303,
			71.364,
			71.426,
			71.457,
			71.49,
			71.551,
			71.613,
			71.676,
			71.737,
			71.783,
			71.799,
			71.86,
			71.892,
			71.924,
			71.986,
			71.999,
			72.046,
			72.11,
			72.17,
			72.223,
			72.296,
			72.325,
			72.356,
			72.436,
			72.482,
			72.542,
			72.604,
			72.653,
			72.728,
			72.761,
			72.79,
			72.869,
			72.916,
			72.978,
			73.039,
			73.086,
			73.102,
			73.162,
			73.193,
			73.225,
			73.288,
			73.304,
			73.349,
			73.411,
			73.472,
			73.521,
			73.535,
			73.596,
			73.627,
			73.658,
			73.722,
			73.736,
			73.784,
			73.847,
			73.908,
			73.953,
			74.03,
			74.061,
			74.092,
			74.156,
			74.218,
			74.281,
			74.341,
			74.386,
			74.403,
			74.466,
			74.496,
			74.528,
			74.59,
			74.603,
			74.65,
			74.713,
			74.775,
			74.822,
			74.836,
			74.899,
			74.929,
			74.962,
			75.024,
			75.086,
			75.148,
			75.21,
			75.256,
			75.272,
			75.332,
			75.365,
			75.394,
			75.457,
			75.473,
			75.519,
			75.583,
			75.644,
			75.69,
			75.704,
			75.767,
			75.799,
			75.828,
			75.892,
			75.908,
			75.955,
			76.015,
			76.078,
			76.125,
			76.202,
			76.231,
			76.262,
			76.325,
			76.387,
			76.45,
			76.511,
			76.575,
			76.636,
			76.667,
			76.697,
			76.758,
			76.774,
			76.822,
			76.883,
			76.946,
			76.991,
			77.008,
			77.069,
			77.099,
			77.131,
			77.192,
			77.208,
			77.256,
			77.319,
			77.379,
			77.426,
			77.504,
			77.534,
			77.565,
			77.628,
			77.69,
			77.751,
			77.813,
			77.861,
			77.875,
			77.939,
			77.968,
			77.999,
			78.061,
			78.077,
			78.123,
			78.186,
			78.247,
			78.295,
			78.31,
			78.37,
			78.408,
			78.433,
			78.496,
			78.51,
			78.557,
			78.62,
			78.681,
			78.727,
			78.807,
			78.837,
			78.869,
			78.946,
			78.993,
			79.055,
			79.115,
			79.163,
			79.179,
			79.241,
			79.27,
			79.302,
			79.365,
			79.379,
			79.425,
			79.489,
			79.55,
			79.595,
			79.611,
			79.675,
			79.705,
			79.737,
			79.813,
			79.861,
			79.922,
			79.983,
			80.03,
			80.109,
			80.139,
			80.171,
			80.231,
			80.295,
			80.357,
			80.417,
			80.48,
			80.542,
			80.605,
			80.666,
			80.683,
			80.729,
			80.792,
			80.853,
			80.898,
			80.975,
			81.007,
			81.039,
			81.099,
			81.162,
			81.223,
			81.288,
			81.331,
			81.347,
			81.411,
			81.44,
			81.473,
			81.548,
			81.597,
			81.658,
			81.72,
			81.766,
			81.782,
			81.844,
			81.874,
			81.908,
			81.983,
			82.031,
			82.094,
			82.154,
			82.202,
			82.278,
			82.308,
			82.339,
			82.4,
			82.465,
			82.529,
			82.588,
			82.635,
			82.648,
			82.71,
			82.744,
			82.773,
			82.836,
			82.85,
			82.897,
			82.96,
			83.02,
			83.068,
			83.084,
			83.146,
			83.176,
			83.207,
			83.283,
			83.333,
			83.393,
			83.457,
			83.503,
			83.579,
			83.61,
			83.643,
			83.704,
			83.766,
			83.829,
			83.89,
			83.937,
			83.953,
			84.013,
			84.045,
			84.075,
			84.138,
			84.153,
			84.201,
			84.261,
			84.323,
			84.372,
			84.386,
			84.448,
			84.479,
			84.508,
			84.57,
			84.592,
			84.633,
			84.697,
			84.759,
			84.805,
			84.881,
			84.912,
			84.944,
			85.021,
			85.068,
			85.131,
			85.192,
			85.239,
			85.254,
			85.315,
			85.347,
			85.379,
			85.441,
			85.455,
			85.502,
			85.568,
			85.624,
			85.688,
			85.75,
			85.779,
			85.813,
			85.875,
			85.935,
			85.997,
			86.06,
			86.106,
			86.123,
			86.182,
			86.215,
			86.246,
			86.307,
			86.369,
			86.431,
			86.493,
			86.54,
			86.554,
			86.618,
			86.655,
			86.679,
			86.741,
			86.757,
			86.803,
			86.865,
			86.928,
			86.974,
			87.052,
			87.083,
			87.113,
			87.19,
			87.239,
			87.3,
			87.363,
			87.409,
			87.424,
			87.486,
			87.518,
			87.549,
			87.611,
			87.625,
			87.671,
			87.735,
			87.797,
			87.842,
			87.857,
			87.92,
			87.949,
			87.983,
			88.06,
			88.106,
			88.166,
			88.229,
			88.275,
			88.355,
			88.386,
			88.415,
			88.477,
			88.541,
			88.604,
			88.665,
			88.717,
			88.727,
			88.789,
			88.82,
			88.85,
			88.912,
			88.928,
			88.974,
			89.037,
			89.098,
			89.145,
			89.159,
			89.222,
			89.253,
			89.284,
			89.362,
			89.407,
			89.47,
			89.531,
			89.578,
			89.655,
			89.688,
			89.719,
			89.779,
			89.842,
			89.904,
			89.965,
			90.027,
			90.09,
			90.121,
			90.154,
			90.229,
			90.276,
			90.34,
			90.401,
			90.447,
			90.463,
			90.525,
			90.555,
			90.587,
			90.65,
			90.712,
			90.776,
			90.835,
			90.881,
			90.958,
			90.989,
			91.02,
			91.099,
			91.144,
			91.208,
			91.269,
			91.315,
			91.331,
			91.392,
			91.424,
			91.453,
			91.518,
			91.531,
			91.577,
			91.644,
			91.703,
			91.764,
			91.826,
			91.859,
			91.889,
			91.967,
			92.012,
			92.076,
			92.136,
			92.199,
			92.261,
			92.292,
			92.322,
			92.385,
			92.399,
			92.445,
			92.51,
			92.571,
			92.615,
			92.632,
			92.696,
			92.725,
			92.757,
			92.819,
			92.84,
			92.88,
			92.942,
			93.006,
			93.049,
			93.128,
			93.16,
			93.192,
			93.253,
			93.315,
			93.378,
			93.44,
			93.502,
			93.562,
			93.594,
			93.626,
			93.687,
			93.703,
			93.75,
			93.811,
			93.873,
			93.936,
			93.996,
			94.029,
			94.06,
			94.121,
			94.138,
			94.183,
			94.245,
			94.308,
			94.355,
			94.432,
			94.461,
			94.494,
			94.555,
			94.616,
			94.681,
			94.74,
			94.804,
			94.865,
			94.901,
			94.927,
			94.99,
			95.004,
			95.052,
			95.114,
			95.176,
			95.222,
			95.237,
			95.297,
			95.33,
			95.36,
			95.423,
			95.485,
			95.548,
			95.608,
			95.655,
			95.733,
			95.764,
			95.795,
			95.856,
			95.873,
			95.92,
			95.983,
			96.042,
			96.09,
			96.105,
			96.168,
			96.198,
			96.229,
			96.307,
			96.354,
			96.417,
			96.478,
			96.522,
			96.54,
			96.599,
			96.632,
			96.662,
			96.739,
			96.786,
			96.849,
			96.912,
			96.973,
			97.036,
			97.067,
			97.097,
			97.176,
			97.222,
			97.283,
			97.344,
			97.39,
			97.406,
			97.47,
			97.5,
			97.53,
			97.61,
			97.654,
			97.717,
			97.778,
			97.842,
			97.903,
			97.933,
			97.966,
			98.027,
			98.09,
			98.152,
			98.213,
			98.259,
			98.276,
			98.337,
			98.366,
			98.398,
			98.46,
			98.476,
			98.523,
			98.587,
			98.648,
			98.693,
			98.709,
			98.772,
			98.804,
			98.833,
			98.912,
			98.956,
			99.026,
			99.082,
			99.145,
			99.205,
			99.236,
			99.267,
			99.331,
			99.391,
			99.453,
			99.514,
			99.562,
			99.578,
			99.64,
			99.67,
			99.7,
			99.779,
			99.825,
			99.888,
			99.949,
			99.995,
			100.011,
			100.074,
			100.105,
			100.134,
			100.198,
			100.258,
			100.323,
			100.383,
			100.446,
			100.509,
			100.54,
			100.571,
			100.632,
			100.648,
			100.693,
			100.756,
			100.819,
			100.865,
			100.879,
			100.942,
			100.974,
			101.003,
			101.066,
			101.087,
			101.126,
			101.189,
			101.253,
			101.299,
			101.376,
			101.408,
			101.437,
			101.516,
			101.561,
			101.623,
			101.686,
			101.746,
			101.809,
			101.842,
			101.87,
			101.934,
			101.95,
			101.997,
			102.059,
			102.121,
			102.165,
			102.181,
			102.243,
			102.275,
			102.306,
			102.382,
			102.431,
			102.492,
			102.554,
			102.617,
			102.678,
			102.71,
			102.74,
			102.801,
			102.862,
			102.93,
			102.989,
			103.049,
			103.111,
			103.148,
			103.175,
			103.236,
			103.249,
			103.298,
			103.358,
			103.423,
			103.469,
			103.482,
			103.545,
			103.576,
			103.609,
			103.67,
			103.686,
			103.731,
			103.793,
			103.857,
			103.904,
			103.978,
			104.011,
			104.04,
			104.103,
			104.165,
			104.227,
			104.29,
			104.337,
			104.35,
			104.415,
			104.443,
			104.476,
			104.539,
			104.554,
			104.599,
			104.664,
			104.723,
			104.77,
			104.787,
			104.849,
			104.879,
			104.911,
			104.971,
			104.987,
			105.032,
			105.097,
			105.157,
			105.22,
			105.281,
			105.313,
			105.343,
			105.42,
			105.468,
			105.531,
			105.593,
			105.638,
			105.654,
			105.717,
			105.779,
			105.84,
			105.854,
			105.902,
			105.964,
			106.025,
			106.074,
			106.15,
			106.181,
			106.211,
			106.275,
			106.336,
			106.398,
			106.461,
			106.505,
			106.585,
			106.614,
			106.646,
			106.709,
			106.724,
			106.771,
			106.831,
			106.894,
			106.94,
			106.957,
			107.019,
			107.048,
			107.08,
			107.142,
			107.158,
			107.204,
			107.266,
			107.328,
			107.39,
			107.453,
			107.482,
			107.514,
			107.575,
			107.64,
			107.701,
			107.763,
			107.808,
			107.825,
			107.887,
			107.917,
			107.948,
			108.026,
			108.074,
			108.135,
			108.195,
			108.242,
			108.26,
			108.32,
			108.35,
			108.382,
			108.46,
			108.507,
			108.57,
			108.631,
			108.678,
			108.727
		]
	],
	'2467_2468_7982': [
		[
			84.64
		],
		[
			0
		],
		[
			0,
			4.202,
			7.53,
			10.862,
			12.53,
			15.87,
			17.542,
			20.89,
			24.242,
			25.92,
			29.279,
			32.643,
			37.7,
			39.389,
			41.079,
			44.465,
			47.858,
			49.556,
			54.659,
			58.065,
			61.475,
			66.597,
			70.017,
			75.152,
			76.865,
			78.578,
			83.72,
			87.148,
			92.294,
			95.727,
			99.162,
			100.88,
			104.321,
			106.045,
			109.494,
			112.946,
			114.674,
			118.134,
			121.596,
			126.797,
			130.268,
			135.481,
			137.22,
			138.96,
			142.444,
			144.187,
			147.676,
			152.914,
			156.41,
			159.91,
			161.661,
			165.165,
			166.918,
			168.671,
			173.935,
			175.691,
			177.449,
			182.726,
			186.248,
			189.773,
			191.538,
			195.07,
			198.608,
			200.378,
			203.921,
			205.692,
			209.235,
			212.771,
			218.041,
			221.517,
			226.66,
			228.355,
			230.042,
			235.04,
			238.323,
			243.171,
			246.358,
			249.508,
			251.068,
			254.159,
			255.689,
			258.722,
			261.721,
			263.209,
			266.162,
			269.077,
			271.956,
			274.797,
			276.203,
			278.984,
			281.727,
			283.084,
			285.77,
			287.099,
			289.729,
			292.322,
			296.139,
			298.638,
			302.316,
			303.523,
			304.722,
			308.264,
			310.582,
			313.991,
			316.217,
			318.405,
			319.486,
			321.62,
			322.673,
			324.75,
			326.791,
			327.797,
			329.783,
			331.731,
			334.583,
			335.516,
			336.44,
			339.16,
			340.05,
			340.932,
			343.527,
			345.213,
			346.862,
			349.266,
			350.824,
			353.094,
			353.832,
			354.561,
			356.705,
			358.082,
			360.089,
			361.383,
			362.643,
			363.259,
			364.467,
			365.641,
			366.212,
			367.332,
			367.883,
			368.967,
			370.034,
			371.559,
			372.055,
			372.548,
			374.012,
			374.498,
			374.984,
			376.382,
			377.29,
			378.193,
			379.549,
			380.458,
			381.846,
			382.309,
			382.771,
			384.162,
			384.628,
			385.096,
			386.847,
			388.113,
			390.027,
			391.794,
			393.967,
			395.059,
			399.073,
			401.699,
			404.095,
			406.828,
			408.153,
			410.156,
			410.828,
			411.315,
			412.787,
			413.779,
			415.289,
			416.347,
			418.037,
			419.182,
			419.76,
			420.342,
			422.265,
			422.951,
			423.64,
			425.735,
			427.319,
			428.985,
			429.825,
			431.561,
			433.462,
			434.42,
			436.354,
			437.347,
			439.35,
			441.364,
			444.183,
			446.087,
			448.718,
			449.595,
			450.476,
			453.061,
			454.784,
			457.367,
			459.079,
			460.794,
			461.656,
			463.385,
			464.255,
			466.002,
			467.766,
			468.653,
			470.437,
			472.236,
			474.97,
			475.887,
			476.809,
			478.69,
			480.591,
			481.546,
			484.495,
			486.487,
			489.541,
			491.599,
			493.682,
			496.832,
			497.886,
			498.941,
			502.115,
			504.24,
			507.429,
			509.558,
			511.688,
			512.754,
			514.895,
			515.965,
			518.106,
			520.239,
			521.304,
			523.431,
			525.545,
			528.695,
			529.722,
			530.748,
			533.783,
			534.767,
			535.75,
			538.667,
			540.555,
			543.337,
			545.164,
			546.984,
			547.891,
			549.713,
			550.623,
			551.532,
			554.258,
			556.087,
			558.821,
			560.659,
			562.488,
			563.399,
			565.233,
			567.059,
			567.968,
			569.789,
			570.697,
			572.508,
			574.318,
			577.038,
			578.845,
			581.574,
			582.481,
			583.388,
			586.123,
			587.945,
			590.679,
			592.499,
			594.32,
			597.053,
			597.964,
			599.79,
			601.623,
			602.54,
			603.456,
			606.233,
			608.09,
			609.958,
			610.901,
			612.789,
			614.681,
			615.631,
			617.535,
			618.489,
			620.394,
			622.295,
			625.156,
			627.063,
			629.935,
			630.888,
			631.842,
			634.713,
			636.62,
			639.485,
			641.387,
			643.285,
			644.234,
			646.125,
			648.007,
			648.948,
			650.82,
			651.743,
			653.588,
			655.431,
			658.127,
			659.923,
			662.62,
			663.518,
			664.417,
			667.153,
			668.996,
			670.842,
			673.645,
			675.517,
			678.452,
			679.436,
			680.422,
			683.367,
			685.334,
			688.172,
			690.049,
			691.906,
			692.839,
			694.69,
			695.606,
			697.423,
			699.206,
			700.083,
			701.814,
			703.512,
			705.99,
			706.8,
			707.601,
			709.95,
			710.714,
			711.473,
			713.697,
			715.133,
			717.243,
			718.602,
			719.928,
			721.869,
			722.505,
			723.133,
			724.982,
			725.59,
			726.197,
			728.009,
			729.206,
			730.993,
			732.21,
			733.427,
			734.035,
			735.254,
			735.865,
			737.122,
			738.382,
			740.273,
			741.551,
			743.491,
			744.137,
			744.783,
			746.744,
			748.067,
			750.043,
			751.372,
			753.399,
			754.742,
			755.413,
			756.085,
			758.127,
			758.804,
			759.481,
			761.515,
			762.884,
			764.934,
			766.308,
			767.709,
			768.409,
			769.807,
			770.508,
			771.945,
			773.402,
			775.594,
			777.084,
			779.339,
			780.088,
			780.839,
			783.015,
			784.454,
			786.61,
			788.016,
			790.112,
			791.516,
			792.229,
			793.656,
			795.081,
			795.793,
			797.238,
			798.693,
			800.154,
			801.609,
			802.323,
			803.754,
			805.189,
			805.907,
			807.278,
			807.958,
			809.321,
			810.69,
			812.668,
			813.98,
			815.937,
			816.575,
			817.196,
			819.047,
			820.266,
			822.086,
			823.289,
			824.474,
			825.061,
			826.227,
			826.853,
			828.114,
			829.364,
			829.985,
			831.226,
			832.649,
			834.794,
			835.506,
			836.216,
			838.74,
			839.583,
			840.425,
			843.102,
			844.934,
			846.75,
			849.418,
			851.188,
			853.595,
			854.387,
			855.178,
			857.469,
			858.93,
			861.12,
			862.573,
			864.023,
			864.747,
			866.2,
			867.667,
			868.402,
			869.876,
			870.615,
			872.09,
			873.557,
			875.771,
			877.243,
			879.439,
			880.173,
			880.91,
			883.115,
			884.588,
			886.794,
			888.187,
			889.583,
			890.283,
			891.679,
			892.327,
			893.628,
			894.932,
			895.587,
			896.243,
			898.171,
			899.482,
			900.797,
			901.461,
			902.799,
			904.153,
			904.836,
			906.214,
			906.908,
			908.304,
			909.714,
			911.86,
			913.3,
			915.489,
			916.227,
			916.967,
			919.203,
			920.713,
			923.003,
			924.546,
			926.104,
			926.889,
			928.468,
			929.263,
			930.863,
			932.478,
			933.29,
			934.925,
			936.566,
			939.031,
			939.856,
			940.685,
			942.355,
			944.038,
			944.885,
			947.445,
			949.171,
			950.909,
			953.549,
			955.313,
			958.013,
			958.924,
			959.853,
			962.621,
			964.48,
			967.318,
			969.231,
			972.124,
			974.066,
			975.041,
			977.001,
			978.969,
			979.957,
			981.943,
			983.94,
			986.956,
			988.979,
			992.034,
			993.054,
			994.077,
			997.163,
			999.229,
			1001.305,
			1004.431,
			1006.516,
			1009.669,
			1010.725,
			1011.784,
			1014.98,
			1017.123,
			1020.353,
			1022.518,
			1024.694,
			1025.785,
			1027.974,
			1030.173,
			1031.276,
			1033.49,
			1034.601,
			1036.83,
			1039.07,
			1042.448,
			1044.714,
			1048.135,
			1049.283,
			1050.433,
			1053.896,
			1056.217,
			1059.711,
			1062.051,
			1064.401,
			1065.58,
			1067.945,
			1069.133,
			1070.322,
			1073.905,
			1075.104,
			1076.306,
			1079.924,
			1082.346,
			1084.777,
			1085.996,
			1088.441,
			1090.896,
			1092.127,
			1094.594,
			1095.83,
			1098.31,
			1100.798,
			1104.545,
			1107.054,
			1110.834,
			1112.098,
			1113.365,
			1117.18,
			1119.732,
			1123.573,
			1126.14,
			1129.973,
			1132.504,
			1133.757,
			1136.234,
			1138.673,
			1139.877,
			1142.257,
			1144.596,
			1148.051,
			1150.295,
			1152.505,
			1154.681,
			1155.758,
			1157.894,
			1158.949,
			1161.046,
			1163.132,
			1166.244,
			1168.323,
			1171.445,
			1172.489,
			1173.532,
			1176.657,
			1178.737,
			1181.844,
			1183.903,
			1185.95,
			1186.965,
			1188.978,
			1189.975,
			1191.945,
			1193.884,
			1194.844,
			1196.74,
			1198.606,
			1201.354,
			1202.252,
			1203.143,
			1205.777,
			1206.638,
			1207.491,
			1210.013,
			1211.656,
			1213.266,
			1215.64,
			1217.181,
			1219.45,
			1220.196,
			1220.935,
			1223.115,
			1223.837,
			1224.556,
			1226.689,
			1228.099,
			1229.519,
			1230.229,
			1231.646,
			1232.353,
			1233.772,
			1235.924,
			1237.354,
			1238.79,
			1240.967,
			1242.416,
			1244.61,
			1245.345,
			1246.081,
			1248.289,
			1249.786,
			1252.031,
			1253.533,
			1255.122,
			1255.915,
			1257.497,
			1258.288,
			1259.173,
			1261.893,
			1262.796,
			1263.757,
			1267.195,
			1269.752,
			1272.677,
			1274.162,
			1277.201,
			1279.899,
			1281.114,
			1283.531,
			1284.541,
			1286.546,
			1288.541,
			1291.57,
			1293.579,
			1296.492,
			1297.458,
			1298.422,
			1301,
			1302.688,
			1305.005,
			1306.454,
			1307.905,
			1308.634,
			1309.94,
			1310.59,
			1311.892,
			1313.199,
			1313.847,
			1315.095,
			1316.343,
			1318.219,
			1318.846,
			1319.475,
			1320.733,
			1321.992,
			1322.622,
			1324.539,
			1325.837,
			1327.142,
			1329.12,
			1330.469,
			1332.507,
			1333.191,
			1333.879,
			1335.977,
			1337.388,
			1339.529,
			1340.972,
			1342.428,
			1343.162,
			1344.636,
			1345.376,
			1346.867,
			1348.374,
			1349.132,
			1350.656,
			1352.195,
			1354.534,
			1355.322,
			1356.114,
			1358.51,
			1359.317,
			1360.131,
			1361.767,
			1362.591,
			1364.248,
			1366.744,
			1368.415,
			1370.102,
			1372.657,
			1373.516,
			1374.378,
			1376.985,
			1378.738,
			1381.388,
			1383.169,
			1384.962,
			1385.86,
			1387.667,
			1389.486,
			1390.397,
			1392.229,
			1393.149,
			1394.999,
			1396.862,
			1399.679,
			1401.56,
			1404.427,
			1405.393,
			1406.361,
			1409.284,
			1411.255,
			1414.228,
			1416.224,
			1419.201,
			1421.184,
			1422.172,
			1423.155,
			1426.084,
			1427.052,
			1428.013,
			1430.89,
			1432.783,
			1434.668,
			1435.608,
			1437.48,
			1439.345,
			1440.276,
			1442.13,
			1443.054,
			1444.897,
			1446.735,
			1449.474,
			1451.295,
			1454.01,
			1454.913,
			1455.814,
			1458.507,
			1460.297,
			1462.973,
			1464.752,
			1466.533,
			1467.422,
			1469.198,
			1470.088,
			1471.868,
			1473.646,
			1474.535,
			1475.429,
			1478.11,
			1479.902,
			1481.71,
			1482.615,
			1484.427,
			1486.258,
			1487.176,
			1489.017,
			1489.941,
			1491.796,
			1493.659,
			1496.463,
			1498.343,
			1501.183,
			1502.135,
			1503.089,
			1505.971,
			1507.904,
			1510.828,
			1512.795,
			1514.773,
			1515.77,
			1517.772,
			1518.777,
			1520.797,
			1523.844,
			1525.877,
			1527.91,
			1530.949,
			1531.956,
			1532.961,
			1535.955,
			1536.941,
			1537.921,
			1539.864,
			1540.83,
			1542.747,
			1544.649,
			1547.49,
			1549.373,
			1552.202,
			1553.143,
			1554.081,
			1556.911,
			1557.855,
			1558.798,
			1561.634,
			1563.526,
			1566.358,
			1568.241,
			1569.183,
			1571.059,
			1572.927,
			1573.862,
			1575.725,
			1577.575,
			1580.354,
			1582.2,
			1584.979,
			1585.91,
			1586.841,
			1589.646,
			1591.525,
			1594.356,
			1596.254,
			1598.157,
			1601.025,
			1601.983,
			1602.942,
			1605.828,
			1606.794,
			1607.76,
			1610.67,
			1612.621,
			1614.579,
			1615.561,
			1617.536,
			1619.519,
			1620.514,
			1623.522,
			1625.54,
			1627.575,
			1630.649,
			1632.727,
			1635.865,
			1636.917,
			1637.971,
			1641.144,
			1643.262,
			1646.45,
			1648.576,
			1650.71,
			1651.771,
			1653.902,
			1654.971,
			1657.1,
			1659.224,
			1660.286,
			1662.397,
			1664.515,
			1666.633,
			1668.75,
			1669.812,
			1671.933,
			1674.057,
			1675.122,
			1677.249,
			1678.313,
			1680.45,
			1682.583,
			1685.793,
			1687.928,
			1691.141,
			1692.21,
			1693.281,
			1696.5,
			1698.649,
			1701.883,
			1704.048,
			1706.221,
			1707.318,
			1709.515,
			1710.614,
			1712.838,
			1716.186,
			1718.447,
			1720.693,
			1724.145,
			1725.302,
			1726.462,
			1729.964,
			1731.136,
			1732.307,
			1735.835,
			1738.214,
			1740.562,
			1744.126,
			1746.511,
			1750.1,
			1751.3,
			1752.5,
			1756.115,
			1757.325,
			1758.538,
			1762.188,
			1764.634,
			1768.319,
			1770.807,
			1773.284,
			1774.506,
			1778.247,
			1780.753,
			1783.268,
			1787.057,
			1789.592,
			1793.407,
			1794.683,
			1795.96,
			1799.804,
			1802.376,
			1806.25,
			1808.843,
			1811.444,
			1815.35,
			1816.653,
			1817.957,
			1821.882,
			1823.194,
			1824.508,
			1828.461,
			1831.106,
			1833.758,
			1835.086,
			1837.748,
			1840.417,
			1841.755,
			1845.778,
			1848.47,
			1851.169,
			1855.23,
			1857.945,
			1862.031,
			1863.397,
			1864.765,
			1868.879,
			1871.632,
			1875.774,
			1878.544,
			1881.321,
			1882.713,
			1885.5,
			1886.896,
			1889.694,
			1893.904,
			1896.719,
			1899.54,
			1902.369,
			1905.204,
			1906.624,
			1909.47,
			1912.323,
			1913.751,
			1916.614,
			1918.048,
			1920.922,
			1923.801,
			1928.133,
			1931.028,
			1935.384,
			1936.839,
			1938.296,
			1942.675,
			1945.603,
			1950.005,
			1952.948,
			1955.897,
			1957.374,
			1960.333,
			1961.814,
			1964.783,
			1967.759,
			1969.25,
			1972.237,
			1975.23,
			1979.734,
			1982.745,
			1985.763,
			1988.788,
			1990.303,
			1993.338,
			1994.856,
			1997.897,
			2000.942,
			2005.519,
			2008.577,
			2013.176,
			2014.711,
			2016.247,
			2020.864,
			2023.949,
			2028.586,
			2031.686,
			2034.791,
			2036.346,
			2039.46,
			2042.581,
			2044.144,
			2047.275,
			2048.843,
			2051.984,
			2055.131,
			2059.862,
			2063.012,
			2067.713,
			2069.268,
			2070.814,
			2075.399,
			2078.413,
			2082.862,
			2085.779,
			2088.66,
			2092.912,
			2094.31,
			2095.699,
			2099.812,
			2101.165,
			2102.508,
			2106.479,
			2109.079,
			2111.642,
			2112.911,
			2115.421,
			2117.892,
			2119.114,
			2121.526,
			2122.719,
			2125.079,
			2127.404,
			2130.846,
			2133.061,
			2136.349,
			2137.427,
			2138.496,
			2141.647,
			2143.701,
			2146.713,
			2148.674,
			2150.6,
			2151.55,
			2153.422,
			2154.36,
			2156.179,
			2158.846,
			2160.57,
			2162.285,
			2164.77,
			2166.378,
			2167.953,
			2169.495,
			2170.253,
			2172.48,
			2173.934,
			2175.362,
			2177.458,
			2178.836,
			2180.889,
			2181.569,
			2182.247,
			2184.278,
			2184.962,
			2185.653,
			2187.721,
			2189.097,
			2190.525,
			2191.252,
			2192.707,
			2193.434,
			2194.909,
			2196.481,
			2197.267,
			2198.84,
			2200.437,
			2202.873,
			2204.5,
			2206.874,
			2207.664,
			2208.454,
			2210.038,
			2210.821,
			2212.388,
			2213.958,
			2216.369,
			2217.993,
			2220.444,
			2221.271,
			2222.097,
			2224.575,
			2226.192,
			2228.602,
			2230.202,
			2231.776,
			2232.562,
			2234.134,
			2234.921,
			2236.516,
			2238.107,
			2238.903,
			2240.507,
			2242.127,
			2244.554,
			2245.356,
			2246.152,
			2248.533,
			2249.326,
			2250.117,
			2252.442,
			2253.991,
			2256.298,
			2257.824,
			2259.352,
			2260.115,
			2261.606,
			2262.353,
			2263.099,
			2266.07,
			2266.802,
			2269.003,
			2270.475,
			2271.941,
			2272.676,
			2274.15,
			2275.632,
			2276.375,
			2278.614,
			2280.116,
			2281.634,
			2283.927,
			2285.47,
			2287.817,
			2288.604,
			2289.395,
			2291.796,
			2293.415,
			2295.874,
			2297.543,
			2299.226,
			2300.072,
			2301.795,
			2302.663,
			2304.409,
			2306.181,
			2307.081,
			2307.983,
			2310.712,
			2312.555,
			2315.329,
			2317.199,
			2319.082,
			2320.029,
			2321.925,
			2322.876,
			2324.788,
			2326.71,
			2329.611,
			2331.559,
			2334.5,
			2335.486,
			2336.474,
			2339.457,
			2341.459,
			2344.483,
			2346.515,
			2349.584,
			2351.645,
			2352.681,
			2354.762,
			2356.856,
			2357.905,
			2360.002,
			2362.108,
			2364.227,
			2367.428,
			2370.655,
			2371.755,
			2372.822,
			2376.112,
			2378.308,
			2380.514,
			2383.845,
			2386.078,
			2389.448,
			2390.576,
			2391.707,
			2395.115,
			2397.402,
			2400.853,
			2403.18,
			2406.698,
			2409.079,
			2410.272,
			2412.683,
			2416.334,
			2418.807,
			2421.287,
			2425.069,
			2427.599,
			2431.408,
			2432.665,
			2433.924,
			2437.701,
			2440.213,
			2443.977,
			2446.489,
			2449,
			2452.778,
			2454.038,
			2455.301,
			2459.091,
			2460.357,
			2461.624,
			2465.405,
			2467.924,
			2470.438,
			2471.698,
			2474.212,
			2476.733,
			2477.996,
			2480.526,
			2481.794,
			2484.337,
			2486.887,
			2490.734,
			2493.311,
			2497.192,
			2498.49,
			2499.769,
			2503.671,
			2506.282,
			2510.216,
			2512.86,
			2515.473,
			2516.752,
			2519.337,
			2520.619,
			2523.159,
			2526.906,
			2529.359,
			2531.776,
			2534.158,
			2536.506,
			2537.666,
			2539.954,
			2542.204,
			2543.313,
			2546.588,
			2548.725,
			2550.825,
			2553.905,
			2555.912,
			2558.852,
			2559.813,
			2560.766,
			2563.569,
			2565.394,
			2568.069,
			2569.809,
			2571.513,
			2572.351,
			2574.002,
			2574.813,
			2576.409,
			2577.969,
			2578.736,
			2580.242,
			2581.712,
			2583.851,
			2584.546,
			2585.244,
			2587.254,
			2587.909,
			2588.552,
			2590.446,
			2591.677,
			2592.886,
			2594.64,
			2595.79,
			2597.498,
			2598.062,
			2598.624,
			2600.3,
			2601.412,
			2603.085,
			2604.213,
			2605.337,
			2605.9,
			2607.025,
			2607.588,
			2608.746,
			2610.492,
			2611.659,
			2612.83,
			2614.667,
			2615.28,
			2615.894,
			2617.745,
			2618.384,
			2619.026,
			2620.959,
			2622.256,
			2623.579,
			2625.592,
			2626.938,
			2628.985,
			2629.674,
			2630.363,
			2633.131,
			2633.841,
			2635.964,
			2637.377,
			2638.814,
			2639.54,
			2640.987,
			2642.428,
			2643.148,
			2644.613,
			2645.34,
			2646.79,
			2648.235,
			2650.393,
			2651.82,
			2653.941,
			2654.636,
			2655.33,
			2657.406,
			2658.787,
			2660.836,
			2662.202,
			2663.574,
			2664.265,
			2665.646,
			2666.338,
			2667.725,
			2669.12,
			2669.819,
			2671.218,
			2672.622,
			2674.024,
			2675.416,
			2676.114,
			2677.518,
			2678.922,
			2679.615,
			2681.008,
			2681.708,
			2683.115,
			2684.512,
			2686.613,
			2688.021,
			2690.109,
			2690.79,
			2691.481,
			2693.537,
			2694.876,
			2696.834,
			2698.089,
			2699.33,
			2699.945,
			2701.15,
			2701.742,
			2702.906,
			2704.074,
			2704.677,
			2705.864,
			2707.031,
			2708.76,
			2709.384,
			2710.005,
			2711.238,
			2712.465,
			2713.078,
			2715.004,
			2716.318,
			2717.628,
			2719.631,
			2720.995,
			2723.043,
			2723.728,
			2724.402,
			2726.417,
			2727.763,
			2729.744,
			2731.034,
			2732.331,
			2732.982,
			2734.291,
			2734.948,
			2736.264,
			2738.252,
			2739.597,
			2740.96,
			2743.021,
			2744.398,
			2746.449,
			2747.135,
			2747.823,
			2749.884,
			2751.249,
			2753.302,
			2754.671,
			2756.033,
			2756.715,
			2758.079,
			2758.762,
			2759.442,
			2761.466,
			2762.138,
			2762.809,
			2764.829,
			2766.183,
			2768.219,
			2769.591,
			2770.983,
			2771.679,
			2773.074,
			2773.773,
			2775.161,
			2776.544,
			2778.62,
			2779.981,
			2781.988,
			2782.657,
			2783.325,
			2785.295,
			2786.581,
			2788.51,
			2789.786,
			2791.663,
			2792.916,
			2793.543,
			2794.172,
			2796.04,
			2796.663,
			2797.287,
			2799.167,
			2800.423,
			2801.682,
			2802.314,
			2803.584,
			2804.862,
			2805.504,
			2806.794,
			2807.443,
			2808.75,
			2810.069,
			2812.072,
			2813.425,
			2815.483,
			2816.176,
			2816.874,
			2818.987,
			2820.418,
			2822.596,
			2824.067,
			2825.559,
			2826.312,
			2827.831,
			2828.595,
			2830.14,
			2831.713,
			2832.505,
			2834.099,
			2835.717,
			2838.177,
			2839.004,
			2839.834,
			2842.339,
			2843.174,
			2844.01,
			2845.691,
			2846.536,
			2848.234,
			2849.945,
			2852.522,
			2854.254,
			2856.857,
			2857.728,
			2858.602,
			2861.237,
			2863.003,
			2865.673,
			2867.466,
			2869.27,
			2870.176,
			2872.001,
			2872.918,
			2874.761,
			2876.617,
			2877.55,
			2879.423,
			2881.311,
			2884.165,
			2886.083,
			2888.985,
			2889.958,
			2890.936,
			2893.891,
			2895.876,
			2898.882,
			2900.9,
			2902.934,
			2906.007,
			2907.04,
			2908.071,
			2911.175,
			2912.22,
			2913.268,
			2916.412,
			2918.542,
			2921.778,
			2923.942,
			2926.118,
			2927.215,
			2929.415,
			2930.519,
			2932.742,
			2934.977,
			2938.355,
			2940.623,
			2944.047,
			2945.194,
			2946.344,
			2949.815,
			2952.145,
			2955.664,
			2958.028,
			2960.404,
			2961.596,
			2963.994,
			2965.196,
			2967.609,
			2970.035,
			2971.251,
			2973.694,
			2976.146,
			2978.608,
			2982.319,
			2984.809,
			2987.309,
			2988.563,
			2991.079,
			2992.341,
			2994.875,
			2997.419,
			3001.273,
			3003.854,
			3007.746,
			3009.049,
			3010.354,
			3014.287,
			3016.927,
			3020.915,
			3023.585,
			3026.264,
			3027.605,
			3030.301,
			3031.651,
			3034.364,
			3037.091,
			3038.459,
			3041.21,
			3043.963,
			3048.095,
			3049.466,
			3050.838,
			3053.563,
			3056.336,
			3057.714,
			3060.481,
			3061.867,
			3064.646,
			3067.434,
			3071.62,
			3074.439,
			3078.707,
			3080.112,
			3081.541,
			3085.871,
			3088.732,
			3093.073,
			3095.964,
			3098.858,
			3100.295,
			3103.172,
			3104.631,
			3107.466,
			3110.322,
			3111.744,
			3114.591,
			3117.422,
			3121.662,
			3123.074,
			3124.485,
			3128.698,
			3130.101,
			3131.502,
			3135.71,
			3138.518,
			3141.329,
			3145.554,
			3148.377,
			3152.622,
			3154.04,
			3155.46,
			3159.728,
			3162.583,
			3166.881,
			3169.755,
			3174.084,
			3176.981,
			3179.885,
			3181.342,
			3185.721,
			3188.651,
			3191.587,
			3196.004,
			3198.955,
			3203.391,
			3204.871,
			3206.353,
			3210.802,
			3213.777,
			3218.255,
			3221.252,
			3224.264,
			3228.798,
			3230.313,
			3233.353,
			3237.928,
			3240.989,
			3244.057,
			3247.129,
			3250.205,
			3251.745,
			3254.825,
			3257.907,
			3259.448,
			3262.534,
			3264.077,
			3267.166,
			3270.258,
			3274.908,
			3278.018,
			3282.697,
			3284.26,
			3285.824,
			3290.528,
			3293.67,
			3298.39,
			3301.541,
			3306.274,
			3309.437,
			3311.021,
			3314.19,
			3317.366,
			3318.955,
			3322.138,
			3325.319,
			3328.505,
			3331.695,
			3333.292,
			3336.488,
			3339.688,
			3341.29,
			3346.102,
			3349.317,
			3352.535,
			3357.371,
			3360.6,
			3365.452,
			3367.071,
			3368.69,
			3373.557,
			3376.806,
			3381.689,
			3384.95,
			3388.216,
			3389.85,
			3393.125,
			3394.763,
			3398.046,
			3401.333,
			3402.978,
			3406.269,
			3409.563,
			3414.508,
			3416.159,
			3417.81,
			3422.765,
			3424.418,
			3426.072,
			3431.038,
			3434.483
		],
		[
			0,
			0.06,
			0.123,
			0.173,
			0.185,
			0.247,
			0.276,
			0.309,
			0.37,
			0.386,
			0.432,
			0.495,
			0.556,
			0.604,
			0.618,
			0.68,
			0.712,
			0.742,
			0.821,
			0.866,
			0.929,
			0.991,
			1.037,
			1.114,
			1.147,
			1.175,
			1.239,
			1.301,
			1.365,
			1.423,
			1.472,
			1.487,
			1.547,
			1.58,
			1.609,
			1.673,
			1.688,
			1.736,
			1.795,
			1.858,
			1.922,
			1.982,
			2.015,
			2.046,
			2.105,
			2.123,
			2.169,
			2.236,
			2.293,
			2.339,
			2.356,
			2.415,
			2.448,
			2.479,
			2.54,
			2.556,
			2.604,
			2.667,
			2.726,
			2.773,
			2.788,
			2.85,
			2.883,
			2.914,
			2.976,
			2.991,
			3.038,
			3.103,
			3.162,
			3.206,
			3.284,
			3.314,
			3.348,
			3.408,
			3.472,
			3.533,
			3.596,
			3.642,
			3.658,
			3.72,
			3.751,
			3.781,
			3.843,
			3.858,
			3.906,
			3.967,
			4.029,
			4.075,
			4.091,
			4.154,
			4.185,
			4.216,
			4.278,
			4.299,
			4.34,
			4.402,
			4.463,
			4.509,
			4.587,
			4.616,
			4.648,
			4.711,
			4.773,
			4.835,
			4.898,
			4.942,
			4.959,
			5.021,
			5.053,
			5.083,
			5.146,
			5.161,
			5.208,
			5.267,
			5.332,
			5.379,
			5.393,
			5.457,
			5.486,
			5.519,
			5.595,
			5.642,
			5.704,
			5.766,
			5.813,
			5.889,
			5.92,
			5.951,
			6.013,
			6.077,
			6.138,
			6.201,
			6.245,
			6.261,
			6.324,
			6.36,
			6.384,
			6.446,
			6.464,
			6.509,
			6.573,
			6.632,
			6.679,
			6.695,
			6.759,
			6.788,
			6.818,
			6.882,
			6.944,
			7.005,
			7.069,
			7.115,
			7.193,
			7.224,
			7.254,
			7.315,
			7.332,
			7.376,
			7.441,
			7.503,
			7.563,
			7.626,
			7.658,
			7.688,
			7.765,
			7.811,
			7.876,
			7.937,
			7.981,
			8.058,
			8.091,
			8.122,
			8.198,
			8.245,
			8.309,
			8.371,
			8.432,
			8.494,
			8.524,
			8.555,
			8.618,
			8.632,
			8.679,
			8.741,
			8.805,
			8.852,
			8.867,
			8.928,
			8.959,
			8.991,
			9.052,
			9.067,
			9.113,
			9.176,
			9.238,
			9.286,
			9.362,
			9.394,
			9.423,
			9.484,
			9.549,
			9.609,
			9.673,
			9.717,
			9.735,
			9.796,
			9.826,
			9.859,
			9.92,
			9.936,
			9.983,
			10.044,
			10.106,
			10.153,
			10.169,
			10.23,
			10.262,
			10.293,
			10.37,
			10.415,
			10.484,
			10.54,
			10.588,
			10.665,
			10.694,
			10.726,
			10.79,
			10.852,
			10.912,
			10.973,
			11.02,
			11.038,
			11.098,
			11.129,
			11.161,
			11.224,
			11.238,
			11.283,
			11.346,
			11.409,
			11.455,
			11.471,
			11.533,
			11.564,
			11.593,
			11.657,
			11.716,
			11.782,
			11.844,
			11.89,
			11.906,
			11.965,
			11.997,
			12.029,
			12.105,
			12.15,
			12.216,
			12.277,
			12.323,
			12.34,
			12.402,
			12.432,
			12.464,
			12.525,
			12.545,
			12.586,
			12.648,
			12.71,
			12.756,
			12.834,
			12.864,
			12.896,
			12.959,
			13.022,
			13.082,
			13.146,
			13.205,
			13.269,
			13.3,
			13.331,
			13.395,
			13.409,
			13.455,
			13.515,
			13.579,
			13.629,
			13.64,
			13.703,
			13.733,
			13.764,
			13.827,
			13.841,
			13.89,
			13.953,
			14.012,
			14.06,
			14.137,
			14.169,
			14.197,
			14.26,
			14.322,
			14.386,
			14.446,
			14.493,
			14.51,
			14.571,
			14.606,
			14.633,
			14.695,
			14.709,
			14.756,
			14.819,
			14.882,
			14.942,
			15.006,
			15.037,
			15.065,
			15.144,
			15.191,
			15.254,
			15.315,
			15.362,
			15.438,
			15.471,
			15.502,
			15.578,
			15.626,
			15.686,
			15.75,
			15.796,
			15.812,
			15.873,
			15.904,
			15.936,
			15.998,
			16.012,
			16.059,
			16.123,
			16.182,
			16.228,
			16.244,
			16.306,
			16.338,
			16.37,
			16.432,
			16.493,
			16.557,
			16.615,
			16.667,
			16.741,
			16.772,
			16.804,
			16.865,
			16.881,
			16.926,
			16.99,
			17.052,
			17.113,
			17.177,
			17.207,
			17.238,
			17.299,
			17.315,
			17.362,
			17.426,
			17.486,
			17.532,
			17.609,
			17.641,
			17.671,
			17.733,
			17.795,
			17.857,
			17.919,
			17.983,
			18.044,
			18.074,
			18.105,
			18.168,
			18.182,
			18.23,
			18.291,
			18.354,
			18.415,
			18.478,
			18.508,
			18.54,
			18.602,
			18.617,
			18.664,
			18.729,
			18.788,
			18.836,
			18.912,
			18.942,
			18.973,
			19.037,
			19.099,
			19.159,
			19.22,
			19.282,
			19.346,
			19.378,
			19.406,
			19.47,
			19.485,
			19.531,
			19.595,
			19.656,
			19.704,
			19.719,
			19.779,
			19.81,
			19.843,
			19.904,
			19.919,
			19.965,
			20.028,
			20.09,
			20.134,
			20.214,
			20.245,
			20.276,
			20.338,
			20.401,
			20.463,
			20.523,
			20.571,
			20.587,
			20.647,
			20.679,
			20.71,
			20.773,
			20.793,
			20.835,
			20.897,
			20.959,
			21.003,
			21.02,
			21.083,
			21.114,
			21.143,
			21.222,
			21.269,
			21.33,
			21.392,
			21.438,
			21.517,
			21.546,
			21.579,
			21.64,
			21.7,
			21.765,
			21.827,
			21.873,
			21.888,
			21.948,
			21.981,
			22.011,
			22.074,
			22.088,
			22.137,
			22.198,
			22.26,
			22.323,
			22.384,
			22.415,
			22.447,
			22.51,
			22.571,
			22.633,
			22.695,
			22.74,
			22.756,
			22.819,
			22.851,
			22.881,
			22.944,
			22.958,
			23.004,
			23.066,
			23.128,
			23.174,
			23.19,
			23.253,
			23.282,
			23.314,
			23.377,
			23.393,
			23.437,
			23.505,
			23.563,
			23.61,
			23.688,
			23.718,
			23.748,
			23.826,
			23.872,
			23.933,
			23.996,
			24.043,
			24.06,
			24.122,
			24.151,
			24.183,
			24.246,
			24.26,
			24.307,
			24.369,
			24.432,
			24.476,
			24.493,
			24.555,
			24.584,
			24.615,
			24.694,
			24.742,
			24.804,
			24.865,
			24.917,
			24.987,
			25.02,
			25.049,
			25.129,
			25.175,
			25.238,
			25.299,
			25.362,
			25.423,
			25.453,
			25.484,
			25.547,
			25.564,
			25.61,
			25.671,
			25.732,
			25.794,
			25.857,
			25.888,
			25.919,
			25.998,
			26.043,
			26.104,
			26.166,
			26.214,
			26.292,
			26.323,
			26.353,
			26.429,
			26.476,
			26.538,
			26.602,
			26.648,
			26.662,
			26.726,
			26.757,
			26.787,
			26.85,
			26.864,
			26.912,
			26.979,
			27.033,
			27.081,
			27.159,
			27.191,
			27.222,
			27.282,
			27.344,
			27.407,
			27.47,
			27.514,
			27.532,
			27.593,
			27.623,
			27.654,
			27.717,
			27.732,
			27.78,
			27.841,
			27.904,
			27.948,
			27.964,
			28.027,
			28.058,
			28.09,
			28.153,
			28.165,
			28.213,
			28.275,
			28.338,
			28.384,
			28.462,
			28.493,
			28.524,
			28.584,
			28.648,
			28.71,
			28.771,
			28.832,
			28.896,
			28.926,
			28.957,
			29.019,
			29.042,
			29.081,
			29.144,
			29.206,
			29.267,
			29.33,
			29.362,
			29.393,
			29.454,
			29.469,
			29.515,
			29.577,
			29.641,
			29.687,
			29.765,
			29.795,
			29.827,
			29.887,
			29.949,
			30.012,
			30.073,
			30.121,
			30.136,
			30.196,
			30.23,
			30.26,
			30.322,
			30.338,
			30.382,
			30.447,
			30.509,
			30.554,
			30.571,
			30.631,
			30.664,
			30.695,
			30.757,
			30.817,
			30.879,
			30.941,
			30.989,
			31.065,
			31.101,
			31.129,
			31.19,
			31.207,
			31.25,
			31.314,
			31.376,
			31.424,
			31.438,
			31.5,
			31.53,
			31.563,
			31.639,
			31.684,
			31.749,
			31.81,
			31.856,
			31.932,
			31.966,
			31.995,
			32.057,
			32.118,
			32.181,
			32.243,
			32.29,
			32.307,
			32.367,
			32.398,
			32.429,
			32.492,
			32.506,
			32.555,
			32.616,
			32.678,
			32.726,
			32.74,
			32.803,
			32.834,
			32.863,
			32.926,
			32.94,
			32.988,
			33.049,
			33.112,
			33.164,
			33.237,
			33.266,
			33.298,
			33.359,
			33.422,
			33.486,
			33.546,
			33.593,
			33.607,
			33.671,
			33.702,
			33.733,
			33.794,
			33.809,
			33.858,
			33.92,
			33.982,
			34.027,
			34.042,
			34.103,
			34.133,
			34.168,
			34.245,
			34.29,
			34.351,
			34.415,
			34.46,
			34.538,
			34.569,
			34.6,
			34.661,
			34.724,
			34.787,
			34.849,
			34.895,
			34.909,
			34.972,
			35.004,
			35.035,
			35.096,
			35.113,
			35.159,
			35.226,
			35.284,
			35.329,
			35.344,
			35.407,
			35.438,
			35.469,
			35.53,
			35.547,
			35.591,
			35.656,
			35.717,
			35.762,
			35.84,
			35.87,
			35.903,
			35.966,
			36.028,
			36.089,
			36.152,
			36.198,
			36.212,
			36.275,
			36.306,
			36.338,
			36.399,
			36.413,
			36.46,
			36.522,
			36.586,
			36.631,
			36.708,
			36.74,
			36.771,
			36.834,
			36.896,
			36.958,
			37.019,
			37.082,
			37.142,
			37.173,
			37.204,
			37.267,
			37.287,
			37.33,
			37.392,
			37.452,
			37.501,
			37.515,
			37.576,
			37.607,
			37.64,
			37.7,
			37.716,
			37.762,
			37.824,
			37.888,
			37.933,
			38.01,
			38.043,
			38.074,
			38.151,
			38.196,
			38.259,
			38.321,
			38.369,
			38.382,
			38.444,
			38.476,
			38.506,
			38.57,
			38.586,
			38.63,
			38.693,
			38.755,
			38.802,
			38.816,
			38.88,
			38.91,
			38.942,
			39.004,
			39.019,
			39.064,
			39.128,
			39.191,
			39.237,
			39.314,
			39.348,
			39.374,
			39.437,
			39.5,
			39.562,
			39.623,
			39.67,
			39.686,
			39.748,
			39.778,
			39.808,
			39.887,
			39.934,
			39.996,
			40.059,
			40.103,
			40.117,
			40.181,
			40.213,
			40.243,
			40.305,
			40.322,
			40.366,
			40.429,
			40.492,
			40.537,
			40.617,
			40.646,
			40.677,
			40.739,
			40.756,
			40.803,
			40.863,
			40.924,
			40.987,
			41.049,
			41.08,
			41.111,
			41.173,
			41.19,
			41.236,
			41.298,
			41.358,
			41.41,
			41.484,
			41.515,
			41.547,
			41.607,
			41.67,
			41.731,
			41.794,
			41.841,
			41.919,
			41.95,
			41.979,
			42.042,
			42.057,
			42.104,
			42.166,
			42.227,
			42.275,
			42.289,
			42.351,
			42.381,
			42.414,
			42.491,
			42.538,
			42.599,
			42.663,
			42.709,
			42.786,
			42.816,
			42.848,
			42.909,
			42.971,
			43.033,
			43.096,
			43.144,
			43.158,
			43.22,
			43.251,
			43.281,
			43.345,
			43.359,
			43.407,
			43.472,
			43.53,
			43.577,
			43.593,
			43.653,
			43.684,
			43.718,
			43.777,
			43.793,
			43.841,
			43.901,
			43.965,
			44.01,
			44.089,
			44.119,
			44.149,
			44.227,
			44.275,
			44.338,
			44.399,
			44.444,
			44.46,
			44.522,
			44.554,
			44.586,
			44.662,
			44.707,
			44.771,
			44.833,
			44.879,
			44.893,
			44.956,
			44.988,
			45.019,
			45.081,
			45.141,
			45.203,
			45.265,
			45.314,
			45.392,
			45.421,
			45.453,
			45.514,
			45.534,
			45.577,
			45.64,
			45.699,
			45.762,
			45.824,
			45.856,
			45.884,
			45.964,
			46.009,
			46.072,
			46.134,
			46.197,
			46.259,
			46.29,
			46.321,
			46.382,
			46.443,
			46.506,
			46.57,
			46.614,
			46.693,
			46.724,
			46.753,
			46.815,
			46.833,
			46.879,
			46.942,
			47.002,
			47.049,
			47.065,
			47.127,
			47.157,
			47.189,
			47.265,
			47.312,
			47.374,
			47.437,
			47.482,
			47.559,
			47.596,
			47.623,
			47.684,
			47.747,
			47.809,
			47.871,
			47.917,
			47.932,
			47.995,
			48.025,
			48.056,
			48.136,
			48.181,
			48.244,
			48.303,
			48.352,
			48.368,
			48.43,
			48.46,
			48.49,
			48.554,
			48.569,
			48.614,
			48.677,
			48.738,
			48.8,
			48.863,
			48.894,
			48.926,
			48.987,
			49.05,
			49.112,
			49.173,
			49.221,
			49.236,
			49.298,
			49.329,
			49.36,
			49.421,
			49.437,
			49.481,
			49.546,
			49.608,
			49.657,
			49.73,
			49.762,
			49.792,
			49.855,
			49.871,
			49.918,
			49.979,
			50.042,
			50.086,
			50.166,
			50.198,
			50.226,
			50.29,
			50.35,
			50.412,
			50.475,
			50.523,
			50.537,
			50.599,
			50.631,
			50.662,
			50.722,
			50.739,
			50.786,
			50.849,
			50.908,
			50.972,
			51.034,
			51.064,
			51.094,
			51.157,
			51.22,
			51.281,
			51.344,
			51.406,
			51.469,
			51.499,
			51.529,
			51.592,
			51.606,
			51.654,
			51.721,
			51.776,
			51.825,
			51.839,
			51.901,
			51.933,
			51.965,
			52.026,
			52.04,
			52.089,
			52.149,
			52.212,
			52.273,
			52.336,
			52.367,
			52.398,
			52.459,
			52.523,
			52.582,
			52.645,
			52.692,
			52.706,
			52.771,
			52.799,
			52.833,
			52.909,
			52.956,
			53.019,
			53.081,
			53.141,
			53.203,
			53.234,
			53.264,
			53.344,
			53.389,
			53.45,
			53.514,
			53.561,
			53.638,
			53.669,
			53.698,
			53.762,
			53.782,
			53.823,
			53.887,
			53.949,
			53.995,
			54.009,
			54.071,
			54.104,
			54.135,
			54.195,
			54.211,
			54.257,
			54.321,
			54.381,
			54.445,
			54.506,
			54.538,
			54.568,
			54.629,
			54.646,
			54.693,
			54.755,
			54.816,
			54.862,
			54.94,
			54.972,
			55.001,
			55.079,
			55.127,
			55.188,
			55.248,
			55.295,
			55.312,
			55.374,
			55.405,
			55.437,
			55.498,
			55.512,
			55.559,
			55.623,
			55.686,
			55.729,
			55.748,
			55.809,
			55.842,
			55.871,
			55.933,
			55.993,
			56.057,
			56.119,
			56.164,
			56.181,
			56.241,
			56.272,
			56.305,
			56.381,
			56.429,
			56.491,
			56.553,
			56.599,
			56.615,
			56.676,
			56.708,
			56.74,
			56.815,
			56.864,
			56.925,
			56.987,
			57.033,
			57.11,
			57.14,
			57.174,
			57.234,
			57.295,
			57.36,
			57.42,
			57.467,
			57.484,
			57.546,
			57.576,
			57.607,
			57.669,
			57.682,
			57.729,
			57.793,
			57.854,
			57.906,
			57.977,
			58.01,
			58.04,
			58.103,
			58.117,
			58.165,
			58.226,
			58.289,
			58.334,
			58.411,
			58.444,
			58.474,
			58.536,
			58.598,
			58.662,
			58.723,
			58.786,
			58.846,
			58.876,
			58.909,
			58.971,
			58.986,
			59.034,
			59.095,
			59.156,
			59.219,
			59.282,
			59.311,
			59.342,
			59.406,
			59.467,
			59.528,
			59.591,
			59.637,
			59.715,
			59.747,
			59.777,
			59.84,
			59.902,
			59.966,
			60.025,
			60.088,
			60.148,
			60.18,
			60.21,
			60.289,
			60.336,
			60.397,
			60.46,
			60.507,
			60.582,
			60.615,
			60.644,
			60.723,
			60.767,
			60.832,
			60.893,
			60.954,
			61.017,
			61.049,
			61.08,
			61.141,
			61.156,
			61.204,
			61.264,
			61.327,
			61.373,
			61.389,
			61.449,
			61.482,
			61.513,
			61.576,
			61.591,
			61.637,
			61.699,
			61.762,
			61.823,
			61.884,
			61.915,
			61.948,
			62.009,
			62.07,
			62.132,
			62.195,
			62.242,
			62.258,
			62.32,
			62.349,
			62.381,
			62.46,
			62.507,
			62.567,
			62.629,
			62.677,
			62.691,
			62.754,
			62.785,
			62.816,
			62.893,
			62.94,
			63.003,
			63.063,
			63.109,
			63.187,
			63.217,
			63.251,
			63.326,
			63.373,
			63.437,
			63.496,
			63.543,
			63.559,
			63.623,
			63.654,
			63.683,
			63.746,
			63.76,
			63.809,
			63.871,
			63.931,
			63.979,
			63.994,
			64.057,
			64.09,
			64.118,
			64.181,
			64.241,
			64.305,
			64.365,
			64.412,
			64.489,
			64.52,
			64.552,
			64.614,
			64.675,
			64.741,
			64.8,
			64.847,
			64.862,
			64.925,
			64.955,
			64.983,
			65.064,
			65.111,
			65.174,
			65.232,
			65.279,
			65.297,
			65.358,
			65.39,
			65.421,
			65.483,
			65.543,
			65.606,
			65.667,
			65.729,
			65.792,
			65.823,
			65.855,
			65.931,
			65.979,
			66.04,
			66.103,
			66.152,
			66.165,
			66.225,
			66.256,
			66.289,
			66.349,
			66.365,
			66.412,
			66.475,
			66.536,
			66.584,
			66.659,
			66.692,
			66.722,
			66.782,
			66.846,
			66.907,
			66.971,
			67.016,
			67.032,
			67.093,
			67.126,
			67.156,
			67.217,
			67.232,
			67.281,
			67.343,
			67.404,
			67.452,
			67.467,
			67.529,
			67.56,
			67.589,
			67.652,
			67.667,
			67.714,
			67.775,
			67.838,
			67.886,
			67.962,
			67.993,
			68.025,
			68.088,
			68.149,
			68.214,
			68.274,
			68.317,
			68.336,
			68.397,
			68.426,
			68.459,
			68.521,
			68.537,
			68.583,
			68.646,
			68.706,
			68.751,
			68.769,
			68.83,
			68.862,
			68.893,
			68.971,
			69.015,
			69.079,
			69.142,
			69.187,
			69.265,
			69.295,
			69.325,
			69.389,
			69.452,
			69.514,
			69.575,
			69.621,
			69.637,
			69.7,
			69.73,
			69.762,
			69.838,
			69.883,
			69.947,
			70.009,
			70.056,
			70.133,
			70.164,
			70.193,
			70.256,
			70.317,
			70.381,
			70.443,
			70.488,
			70.506,
			70.565,
			70.597,
			70.629,
			70.69,
			70.706,
			70.754,
			70.814,
			70.876,
			70.939,
			70.999,
			71.031,
			71.064,
			71.124,
			71.141,
			71.187,
			71.249,
			71.312,
			71.373,
			71.435,
			71.465,
			71.497,
			71.559,
			71.622,
			71.684,
			71.746,
			71.806,
			71.869,
			71.901,
			71.93,
			71.992,
			72.009,
			72.055,
			72.117,
			72.179,
			72.226,
			72.242,
			72.304,
			72.338,
			72.366,
			72.426,
			72.444,
			72.488,
			72.551,
			72.612,
			72.676,
			72.737,
			72.766,
			72.798,
			72.86,
			72.923,
			72.986,
			73.047,
			73.092,
			73.108,
			73.171,
			73.201,
			73.232,
			73.294,
			73.309,
			73.358,
			73.419,
			73.481,
			73.528,
			73.542,
			73.606,
			73.636,
			73.669,
			73.729,
			73.743,
			73.79,
			73.854,
			73.914,
			73.963,
			74.04,
			74.071,
			74.102,
			74.179,
			74.227,
			74.288,
			74.35,
			74.399,
			74.412,
			74.473,
			74.505,
			74.537,
			74.597,
			74.614,
			74.658,
			74.72,
			74.782,
			74.831,
			74.906,
			74.939,
			74.97,
			75.048,
			75.092,
			75.156,
			75.219,
			75.279,
			75.341,
			75.373,
			75.404,
			75.467,
			75.481,
			75.528,
			75.589,
			75.653,
			75.715,
			75.775,
			75.806,
			75.839,
			75.898,
			75.914,
			75.96,
			76.023,
			76.083,
			76.133,
			76.209,
			76.239,
			76.273,
			76.333,
			76.397,
			76.462,
			76.52,
			76.566,
			76.581,
			76.643,
			76.674,
			76.707,
			76.769,
			76.782,
			76.831,
			76.891,
			76.954,
			77.017,
			77.077,
			77.109,
			77.14,
			77.202,
			77.219,
			77.264,
			77.326,
			77.388,
			77.436,
			77.512,
			77.542,
			77.575,
			77.637,
			77.699,
			77.761,
			77.823,
			77.867,
			77.883,
			77.947,
			77.978,
			78.009,
			78.07,
			78.086,
			78.133,
			78.197,
			78.256,
			78.303,
			78.319,
			78.379,
			78.412,
			78.442,
			78.504,
			78.525,
			78.567,
			78.627,
			78.691,
			78.737,
			78.815,
			78.844,
			78.875,
			78.937,
			79,
			79.064,
			79.126,
			79.171,
			79.186,
			79.248,
			79.279,
			79.309,
			79.373,
			79.389,
			79.434,
			79.497,
			79.559,
			79.605,
			79.62,
			79.682,
			79.713,
			79.745,
			79.809,
			79.867,
			79.93,
			79.994,
			80.039,
			80.117,
			80.147,
			80.179,
			80.241,
			80.303,
			80.364,
			80.427,
			80.487,
			80.548,
			80.586,
			80.612,
			80.69,
			80.737,
			80.797,
			80.859,
			80.924,
			80.985,
			81.016,
			81.047,
			81.123,
			81.171,
			81.232,
			81.295,
			81.341,
			81.417,
			81.451,
			81.481,
			81.558,
			81.606,
			81.667,
			81.729,
			81.775,
			81.789,
			81.853,
			81.884,
			81.914,
			81.975,
			81.993,
			82.038,
			82.103,
			82.164,
			82.21,
			82.287,
			82.317,
			82.347,
			82.411,
			82.472,
			82.536,
			82.596,
			82.658,
			82.721,
			82.751,
			82.784,
			82.846,
			82.86,
			82.906,
			82.967,
			83.03,
			83.077,
			83.094,
			83.155,
			83.184,
			83.217,
			83.295,
			83.34,
			83.403,
			83.465,
			83.511,
			83.589,
			83.62,
			83.65,
			83.712,
			83.776,
			83.839,
			83.9,
			83.944,
			83.96,
			84.022,
			84.054,
			84.085,
			84.148,
			84.162,
			84.209,
			84.271,
			84.331,
			84.379,
			84.396,
			84.456,
			84.488,
			84.517,
			84.581,
			84.64
		]
	],
	'2467_6166_7982': [
		[
			106.369
		],
		[
			0
		],
		[
			0,
			0.103,
			5.114,
			6.787,
			8.461,
			13.49,
			15.169,
			16.854,
			21.915,
			25.293,
			30.351,
			33.726,
			37.106,
			42.182,
			43.877,
			45.572,
			50.668,
			52.369,
			54.071,
			59.179,
			62.582,
			65.982,
			67.678,
			71.074,
			74.469,
			76.168,
			81.278,
			84.7,
			88.127,
			93.281,
			96.723,
			101.898,
			103.625,
			105.353,
			110.537,
			113.997,
			119.196,
			122.668,
			126.143,
			127.882,
			131.362,
			133.103,
			136.588,
			140.077,
			141.823,
			143.57,
			148.817,
			152.318,
			157.577,
			161.088,
			164.602,
			166.361,
			169.88,
			171.64,
			175.164,
			178.691,
			183.983,
			187.508,
			192.769,
			194.511,
			196.246,
			201.389,
			204.769,
			209.767,
			213.052,
			216.301,
			217.911,
			221.1,
			222.681,
			225.811,
			228.903,
			230.434,
			233.469,
			236.468,
			240.894,
			243.796,
			248.079,
			249.488,
			250.887,
			255.029,
			257.743,
			261.745,
			264.371,
			266.961,
			270.775,
			272.03,
			273.275,
			276.954,
			279.362,
			282.912,
			285.237,
			287.528,
			288.659,
			290.896,
			292,
			294.181,
			297.375,
			299.458,
			301.502,
			304.497,
			306.449,
			309.311,
			310.261,
			311.187,
			313.913,
			315.685,
			318.275,
			319.958,
			321.602,
			322.413,
			324.015,
			324.805,
			325.581,
			327.872,
			328.627,
			329.378,
			331.56,
			332.991,
			335.12,
			336.526,
			337.227,
			338.627,
			340.766,
			342.221,
			343.677,
			345.918,
			347.453,
			349.754,
			350.557,
			351.366,
			353.791,
			355.448,
			358.07,
			359.819,
			361.737,
			362.702,
			364.639,
			365.679,
			367.83,
			371.07,
			373.232,
			375.366,
			378.336,
			379.333,
			380.302,
			382.127,
			383.965,
			384.888,
			387.501,
			389.254,
			390.988,
			393.559,
			395.296,
			397.888,
			398.759,
			399.633,
			403.158,
			404.047,
			406.732,
			408.538,
			411.265,
			413.094,
			414.014,
			415.862,
			417.719,
			418.652,
			420.529,
			422.416,
			424.316,
			427.19,
			429.122,
			431.066,
			432.042,
			434.005,
			434.991,
			436.973,
			438.967,
			441.987,
			444.018,
			447.116,
			448.153,
			449.192,
			453.39,
			454.443,
			457.626,
			459.767,
			463.039,
			465.242,
			466.364,
			468.628,
			470.909,
			472.07,
			474.401,
			476.747,
			480.299,
			482.684,
			486.274,
			487.476,
			488.679,
			492.294,
			494.703,
			497.045,
			500.527,
			502.711,
			505.955,
			506.988,
			508.013,
			511.042,
			512.021,
			512.992,
			515.872,
			517.752,
			519.61,
			520.531,
			522.388,
			524.244,
			525.17,
			527.036,
			527.991,
			529.915,
			531.838,
			534.749,
			536.685,
			539.593,
			540.56,
			541.531,
			544.447,
			546.382,
			549.275,
			551.194,
			553.104,
			554.054,
			555.948,
			556.896,
			557.844,
			560.67,
			561.612,
			563.507,
			565.394,
			567.282,
			570.1,
			571.967,
			573.821,
			574.742,
			577.47,
			579.261,
			581.046,
			583.707,
			585.476,
			588.131,
			589.015,
			589.898,
			592.545,
			594.306,
			596.935,
			598.681,
			600.422,
			601.292,
			603.016,
			603.876,
			605.594,
			607.299,
			608.144,
			609.832,
			611.503,
			613.992,
			615.666,
			618.152,
			618.98,
			619.811,
			621.481,
			622.313,
			623.979,
			625.651,
			628.166,
			629.843,
			632.37,
			633.216,
			634.062,
			636.604,
			638.313,
			640.881,
			642.601,
			644.328,
			645.193,
			646.927,
			647.796,
			649.54,
			651.29,
			652.168,
			653.917,
			655.69,
			658.377,
			660.185,
			662.921,
			663.839,
			664.758,
			667.501,
			669.306,
			671.95,
			673.669,
			675.351,
			677.805,
			678.606,
			679.397,
			681.715,
			682.471,
			683.218,
			685.405,
			686.817,
			688.198,
			688.876,
			690.217,
			691.514,
			692.152,
			693.414,
			694.036,
			695.27,
			696.495,
			698.336,
			699.566,
			701.415,
			702.032,
			702.656,
			704.533,
			705.779,
			707.645,
			708.908,
			710.161,
			710.786,
			712.031,
			712.662,
			713.936,
			715.202,
			715.832,
			717.086,
			718.345,
			719.599,
			720.844,
			721.464,
			722.703,
			723.947,
			724.564,
			725.792,
			726.404,
			727.624,
			728.857,
			730.682,
			731.888,
			733.708,
			734.313,
			734.916,
			736.715,
			737.915,
			739.712,
			740.901,
			742.089,
			742.684,
			743.894,
			744.496,
			745.699,
			747.505,
			748.736,
			749.969,
			751.821,
			753.066,
			754.322,
			756.209,
			757.475,
			758.113,
			759.391,
			760.673,
			762.613,
			763.929,
			765.915,
			766.581,
			767.25,
			769.266,
			770.62,
			772.674,
			774.023,
			776.056,
			777.427,
			778.095,
			779.404,
			780.728,
			781.396,
			782.746,
			784.07,
			786.053,
			787.391,
			789.412,
			790.088,
			790.764,
			792.797,
			794.205,
			796.315,
			797.709,
			799.187,
			801.432,
			802.177,
			802.921,
			805.286,
			806.862,
			809.268,
			810.899,
			813.341,
			815.025,
			816.695,
			817.527,
			819.236,
			820.102,
			821.817,
			823.517,
			826.045,
			827.709,
			830.178,
			830.995,
			831.81,
			834.246,
			835.852,
			838.248,
			839.799,
			841.34,
			842.108,
			843.629,
			844.362,
			845.82,
			847.271,
			847.995,
			848.704,
			850.763,
			852.131,
			854.157,
			855.472,
			856.786,
			857.443,
			859.385,
			860.658,
			861.931,
			863.839,
			865.094,
			866.982,
			867.614,
			868.248,
			870.153,
			871.434,
			873.377,
			874.684,
			876.003,
			876.667,
			878.006,
			878.682,
			880.039,
			881.413,
			882.106,
			882.803,
			884.913,
			887.054,
			887.776,
			888.502,
			889.96,
			891.433,
			892.176,
			893.673,
			894.425,
			895.941,
			897.473,
			899.798,
			901.365,
			903.745,
			904.545,
			905.349,
			907.769,
			909.408,
			911.889,
			913.553,
			915.217,
			916.05,
			917.727,
			918.57,
			920.267,
			921.975,
			922.835,
			924.564,
			926.309,
			928.95,
			930.733,
			933.433,
			934.34,
			935.251,
			938.002,
			939.853,
			941.72,
			944.544,
			947.402,
			949.323,
			950.289,
			951.259,
			954.187,
			955.171,
			956.158,
			959.138,
			961.141,
			964.169,
			966.202,
			968.247,
			969.273,
			971.333,
			972.367,
			974.443,
			976.53,
			979.675,
			981.77,
			984.925,
			985.981,
			987.039,
			990.229,
			992.367,
			995.593,
			997.753,
			999.924,
			1001.014,
			1003.202,
			1004.299,
			1006.503,
			1008.718,
			1009.829,
			1010.943,
			1014.3,
			1016.551,
			1018.812,
			1019.947,
			1022.223,
			1024.51,
			1025.657,
			1027.958,
			1029.113,
			1031.428,
			1033.754,
			1037.258,
			1039.605,
			1043.144,
			1044.329,
			1045.515,
			1049.09,
			1051.486,
			1055.096,
			1057.515,
			1059.942,
			1061.16,
			1063.601,
			1064.825,
			1067.279,
			1069.742,
			1070.976,
			1073.452,
			1075.936,
			1079.679,
			1082.185,
			1084.699,
			1087.22,
			1088.485,
			1092.29,
			1094.837,
			1097.392,
			1101.241,
			1103.816,
			1107.694,
			1108.991,
			1110.29,
			1114.194,
			1116.798,
			1120.717,
			1123.339,
			1125.967,
			1127.284,
			1129.922,
			1131.243,
			1133.892,
			1136.547,
			1137.876,
			1140.541,
			1143.211,
			1147.229,
			1149.916,
			1153.959,
			1155.311,
			1156.664,
			1160.733,
			1163.457,
			1167.557,
			1170.3,
			1173.051,
			1177.191,
			1178.574,
			1179.959,
			1184.126,
			1185.518,
			1186.913,
			1191.107,
			1193.912,
			1196.723,
			1198.131,
			1200.952,
			1203.78,
			1205.197,
			1208.035,
			1209.457,
			1212.305,
			1215.161,
			1219.456,
			1220.891,
			1222.327,
			1226.647,
			1228.09,
			1229.535,
			1233.88,
			1236.785,
			1241.153,
			1244.072,
			1246.998,
			1248.463,
			1251.396,
			1252.866,
			1255.811,
			1258.762,
			1260.238,
			1261.717,
			1266.16,
			1269.129,
			1272.105,
			1273.595,
			1276.578,
			1279.567,
			1281.064,
			1284.062,
			1285.562,
			1288.567,
			1291.578,
			1296.105,
			1299.13,
			1303.68,
			1305.199,
			1306.72,
			1311.292,
			1314.348,
			1318.943,
			1322.012,
			1325.088,
			1326.628,
			1329.713,
			1331.258,
			1334.352,
			1339.003,
			1342.111,
			1345.224,
			1348.341,
			1351.464,
			1353.027,
			1356.157,
			1359.292,
			1360.861,
			1364.004,
			1365.577,
			1368.726,
			1371.88,
			1376.619,
			1379.784,
			1384.542,
			1386.13,
			1387.719,
			1392.493,
			1395.677,
			1400.437,
			1403.585,
			1406.703,
			1408.248,
			1411.309,
			1412.825,
			1415.828,
			1418.791,
			1420.259,
			1423.165,
			1426.033,
			1430.263,
			1433.035,
			1437.122,
			1438.465,
			1439.799,
			1443.743,
			1446.325,
			1448.869,
			1452.614,
			1455.063,
			1458.668,
			1459.853,
			1461.029,
			1464.505,
			1466.775,
			1470.109,
			1472.286,
			1474.427,
			1475.483,
			1477.571,
			1479.622,
			1480.633,
			1482.632,
			1483.617,
			1485.561,
			1487.469,
			1490.263,
			1492.083,
			1494.748,
			1495.619,
			1496.495,
			1499.022,
			1500.663,
			1503.065,
			1504.632,
			1506.177,
			1508.451,
			1509.202,
			1509.952,
			1512.925,
			1513.661,
			1515.874,
			1517.35,
			1518.811,
			1519.542,
			1521.009,
			1522.482,
			1523.219,
			1524.695,
			1525.434,
			1526.914,
			1528.428,
			1530.703,
			1532.229,
			1534.605,
			1535.397,
			1536.188,
			1538.632,
			1540.286,
			1542.796,
			1544.53,
			1547.121,
			1548.925,
			1549.827,
			1551.625,
			1553.451,
			1554.37,
			1556.201,
			1558.047,
			1559.916,
			1561.774,
			1562.702,
			1564.551,
			1566.392,
			1567.312,
			1570.017,
			1571.814,
			1573.561,
			1576.145,
			1577.831,
			1580.239,
			1581.04,
			1581.841,
			1584.133,
			1585.642,
			1587.898,
			1589.372,
			1591.593,
			1593.066,
			1593.81,
			1595.307,
			1596.816,
			1597.578,
			1599.128,
			1600.692,
			1603.07,
			1604.689,
			1607.144,
			1607.971,
			1608.804,
			1611.324,
			1613.024,
			1615.607,
			1617.353,
			1619.118,
			1621.772,
			1622.656,
			1623.555,
			1626.248,
			1628.042,
			1630.747,
			1632.545,
			1634.363,
			1635.273,
			1637.088,
			1638.928,
			1639.862,
			1642.653,
			1644.528,
			1646.402,
			1649.197,
			1651.044,
			1653.802,
			1654.717,
			1655.63,
			1658.354,
			1660.161,
			1662.853,
			1664.635,
			1666.406,
			1667.29,
			1669.044,
			1669.908,
			1671.635,
			1674.207,
			1675.045,
			1677.563,
			1679.231,
			1680.879,
			1681.703,
			1683.355,
			1684.992,
			1685.809,
			1687.45,
			1688.273,
			1689.921,
			1691.576,
			1694.083,
			1695.758,
			1698.294,
			1699.145,
			1699.999,
			1702.574,
			1704.306,
			1706.926,
			1708.689,
			1710.464,
			1711.356,
			1713.151,
			1714.038,
			1715.85,
			1717.676,
			1718.594,
			1720.443,
			1722.307,
			1725.127,
			1726.074,
			1727.025,
			1729.896,
			1730.859,
			1731.826,
			1733.768,
			1734.742,
			1736.697,
			1738.656,
			1741.585,
			1743.522,
			1746.391,
			1747.338,
			1748.282,
			1751.12,
			1752.991,
			1755.786,
			1757.64,
			1760.407,
			1762.245,
			1764.074,
			1764.986,
			1767.711,
			1769.515,
			1771.316,
			1774.007,
			1775.803,
			1778.501,
			1779.401,
			1780.305,
			1783.019,
			1784.829,
			1786.649,
			1789.375,
			1791.201,
			1793.94,
			1794.853,
			1795.773,
			1799.445,
			1800.368,
			1803.147,
			1804.998,
			1807.8,
			1809.664,
			1811.548,
			1812.49,
			1815.316,
			1817.23,
			1819.138,
			1822.024,
			1823.951,
			1826.829,
			1827.784,
			1828.739,
			1831.593,
			1833.488,
			1836.332,
			1838.227,
			1840.122,
			1841.072,
			1842.969,
			1843.917,
			1844.864,
			1847.682,
			1848.619,
			1849.555,
			1852.346,
			1854.201,
			1856.061,
			1856.994,
			1858.86,
			1860.73,
			1861.673,
			1863.557,
			1864.499,
			1866.389,
			1868.283,
			1871.128,
			1873.031,
			1875.886,
			1876.845,
			1877.803,
			1880.668,
			1882.58,
			1885.438,
			1887.341,
			1889.24,
			1890.189,
			1892.087,
			1893.035,
			1894.928,
			1896.815,
			1897.755,
			1899.632,
			1901.507,
			1903.38,
			1905.251,
			1906.187,
			1908.06,
			1909.936,
			1910.876,
			1912.742,
			1913.675,
			1915.544,
			1918.322,
			1920.173,
			1922.025,
			1924.803,
			1925.729,
			1926.656,
			1929.43,
			1931.28,
			1934.036,
			1935.874,
			1937.7,
			1938.612,
			1940.438,
			1941.351,
			1943.164,
			1944.98,
			1945.889,
			1947.697,
			1949.505,
			1952.216,
			1953.116,
			1954.018,
			1955.825,
			1957.625,
			1958.524,
			1960.326,
			1961.229,
			1963.03,
			1965.736,
			1967.55,
			1969.37,
			1972.112,
			1973.032,
			1973.954,
			1976.729,
			1978.598,
			1981.418,
			1983.318,
			1985.23,
			1986.19,
			1988.124,
			1990.07,
			1991.048,
			1993.019,
			1994.01,
			1995.985,
			1997.993,
			2001.03,
			2003.071,
			2006.156,
			2007.19,
			2008.229,
			2011.365,
			2013.469,
			2016.628,
			2018.738,
			2021.92,
			2024.053,
			2025.123,
			2027.27,
			2030.502,
			2031.584,
			2034.838,
			2037.017,
			2039.204,
			2040.3,
			2042.499,
			2044.709,
			2045.818,
			2048.042,
			2049.159,
			2051.4,
			2053.652,
			2057.049,
			2059.326,
			2062.761,
			2063.911,
			2065.063,
			2068.534,
			2070.861,
			2074.368,
			2076.72,
			2080.265,
			2082.641,
			2086.221,
			2088.619,
			2089.822,
			2092.231,
			2094.65,
			2097.076,
			2099.511,
			2100.732,
			2103.179,
			2105.635,
			2106.867,
			2109.336,
			2110.574,
			2113.057,
			2115.549,
			2119.305,
			2121.819,
			2125.608,
			2126.875,
			2128.145,
			2131.967,
			2134.526,
			2138.378,
			2140.958,
			2143.545,
			2144.842,
			2147.442,
			2148.744,
			2151.347,
			2153.954,
			2155.261,
			2157.88,
			2160.505,
			2164.458,
			2167.102,
			2171.081,
			2172.411,
			2173.742,
			2176.411,
			2177.748,
			2180.427,
			2183.113,
			2187.156,
			2189.861,
			2193.93,
			2195.29,
			2196.652,
			2200.749,
			2202.118,
			2203.489,
			2207.613,
			2210.371,
			2213.136,
			2214.522,
			2217.298,
			2220.081,
			2221.475,
			2224.268,
			2225.667,
			2228.471,
			2231.281,
			2235.511,
			2236.924,
			2238.339,
			2242.593,
			2244.015,
			2245.439,
			2249.72,
			2252.583,
			2256.889,
			2259.769,
			2264.099,
			2266.996,
			2268.448,
			2269.9,
			2274.267,
			2275.726,
			2277.187,
			2281.579,
			2284.515,
			2287.458,
			2288.932,
			2291.885,
			2294.844,
			2296.326,
			2300.781,
			2303.759,
			2308.237,
			2311.231,
			2314.23,
			2318.74,
			2320.247,
			2321.755,
			2326.289,
			2329.318,
			2333.875,
			2336.919,
			2341.497,
			2344.557,
			2346.088,
			2347.622,
			2353.77,
			2356.853,
			2359.94,
			2363.034,
			2366.134,
			2367.686,
			2370.793,
			2373.906,
			2375.465,
			2378.587,
			2380.149,
			2383.278,
			2386.413,
			2391.124,
			2394.271,
			2399,
			2400.579,
			2402.159,
			2406.907,
			2410.078,
			2414.844,
			2418.023,
			2421.197,
			2422.777,
			2425.921,
			2427.48,
			2430.574,
			2433.631,
			2435.146,
			2438.144,
			2441.102,
			2444.02,
			2446.899,
			2448.324,
			2452.542,
			2453.929,
			2455.307,
			2459.384,
			2462.055,
			2464.688,
			2468.568,
			2471.107,
			2474.845,
			2476.073,
			2477.291,
			2480.891,
			2483.247,
			2486.717,
			2488.988,
			2491.224,
			2492.327,
			2494.525,
			2496.668,
			2497.708,
			2500.844,
			2502.876,
			2504.868,
			2507.778,
			2509.686,
			2512.493,
			2513.419,
			2514.341,
			2517.082,
			2518.895,
			2521.606,
			2523.408,
			2525.211,
			2526.117,
			2527.929,
			2529.742,
			2532.475,
			2533.387,
			2534.299,
			2537.045,
			2538.879,
			2541.643,
			2543.492,
			2545.343,
			2546.277,
			2548.15,
			2549.086,
			2550.964,
			2552.87,
			2555.727,
			2557.653,
			2560.542,
			2561.509,
			2562.482,
			2565.4,
			2567.346,
			2570.269,
			2572.219,
			2575.146,
			2577.099,
			2578.074,
			2580.027,
			2581.986,
			2582.967,
			2584.93,
			2586.897,
			2589.85,
			2590.834,
			2591.818,
			2593.789,
			2595.757,
			2596.74,
			2598.707,
			2599.689,
			2601.647,
			2603.606,
			2606.531,
			2608.481,
			2611.399,
			2612.368,
			2613.342,
			2616.251,
			2618.193,
			2621.117,
			2623.068,
			2625.031,
			2626.012,
			2627.971,
			2628.946,
			2630.892,
			2633.79,
			2635.704,
			2637.61,
			2640.458,
			2641.404,
			2642.348,
			2644.237,
			2646.119,
			2647.059,
			2649.893,
			2651.785,
			2653.678,
			2656.526,
			2658.424,
			2659.377,
			2661.284,
			2662.238,
			2663.192,
			2666.035,
			2667.926,
			2670.756,
			2672.646,
			2674.551,
			2675.513,
			2677.447,
			2678.418,
			2680.365,
			2682.313,
			2683.292,
			2685.266,
			2687.246,
			2690.219,
			2692.194,
			2695.163,
			2696.152,
			2697.142,
			2700.111,
			2702.076,
			2705.028,
			2706.994,
			2708.964,
			2709.95,
			2711.919,
			2712.904,
			2713.889,
			2717.857,
			2718.853,
			2721.841,
			2723.833,
			2725.826,
			2726.819,
			2728.805,
			2730.793,
			2731.787,
			2733.776,
			2734.772,
			2736.769,
			2738.771,
			2741.777,
			2743.781,
			2746.794,
			2747.797,
			2748.803,
			2751.827,
			2753.844,
			2756.884,
			2758.914,
			2760.952,
			2761.972,
			2764.014,
			2765.039,
			2766.067,
			2770.186,
			2772.256,
			2774.328,
			2776.398,
			2778.462,
			2779.494,
			2781.566,
			2783.642,
			2784.681,
			2786.766,
			2787.812,
			2789.908,
			2792.013,
			2795.185,
			2797.309,
			2800.519,
			2801.593,
			2802.67,
			2805.92,
			2808.099,
			2811.386,
			2813.588,
			2815.801,
			2816.91,
			2819.137,
			2820.259,
			2822.49,
			2824.752,
			2825.911,
			2828.197,
			2830.479,
			2832.799,
			2836.305,
			2838.662,
			2841.033,
			2842.223,
			2845.811,
			2848.216,
			2850.629,
			2854.256,
			2856.682,
			2860.334,
			2861.558,
			2862.784,
			2866.474,
			2868.95,
			2872.68,
			2875.198,
			2878.938,
			2881.455,
			2883.977,
			2885.24,
			2887.772,
			2889.04,
			2891.58,
			2894.126,
			2897.958,
			2900.522,
			2904.386,
			2905.679,
			2906.974,
			2910.874,
			2913.481,
			2917.397,
			2920.015,
			2922.64,
			2926.588,
			2927.908,
			2929.229,
			2933.202,
			2934.53,
			2935.86,
			2939.859,
			2942.535,
			2945.219,
			2946.563,
			2949.256,
			2951.957,
			2953.309,
			2956.02,
			2957.378,
			2960.101,
			2962.83,
			2966.94,
			2969.689,
			2973.826,
			2975.209,
			2976.594,
			2980.76,
			2983.544,
			2987.736,
			2990.539,
			2993.349,
			2997.576,
			2998.988,
			3001.818,
			3006.073,
			3008.919,
			3011.772,
			3014.63,
			3018.931,
			3021.806,
			3024.688,
			3026.132,
			3029.024,
			3030.473,
			3033.376,
			3036.285,
			3040.662,
			3043.589,
			3047.99,
			3049.461,
			3050.933,
			3055.358,
			3058.316,
			3062.764,
			3065.738,
			3068.718,
			3070.21,
			3074.696,
			3077.695,
			3080.698,
			3082.203,
			3085.216,
			3088.235,
			3092.775,
			3095.81,
			3100.373,
			3101.896,
			3103.422,
			3108.008,
			3111.072,
			3114.142,
			3118.758,
			3121.842,
			3126.481,
			3128.03,
			3129.581,
			3134.24,
			3137.353,
			3142.033,
			3145.16,
			3149.859,
			3152.999,
			3154.571,
			3157.718,
			3160.87,
			3162.447,
			3165.599,
			3168.737,
			3173.396,
			3176.458,
			3180.979,
			3182.468,
			3183.949,
			3188.33,
			3191.202,
			3195.439,
			3198.217,
			3200.956,
			3204.995,
			3206.322,
			3207.639,
			3211.533,
			3212.812,
			3214.081,
			3217.833,
			3220.289,
			3222.708,
			3223.904,
			3226.271,
			3228.603,
			3229.756,
			3232.032,
			3233.157,
			3235.377,
			3237.559,
			3240.764,
			3242.855,
			3245.922,
			3246.926,
			3247.92,
			3250.85,
			3252.758,
			3255.552,
			3257.372,
			3260.054,
			3261.79,
			3262.645,
			3263.491,
			3265.977,
			3266.785,
			3267.586,
			3269.937,
			3271.458,
			3272.942,
			3273.672,
			3275.111,
			3276.522,
			3277.219,
			3278.597,
			3279.28,
			3280.634,
			3281.989,
			3284,
			3285.33,
			3287.369,
			3288.056,
			3288.743,
			3290.819,
			3292.244,
			3294.402,
			3295.852,
			3297.328,
			3298.069,
			3299.555,
			3300.299,
			3301.796,
			3303.297,
			3304.048,
			3305.553,
			3307.071,
			3308.598,
			3310.121,
			3310.883,
			3313.205,
			3313.98,
			3314.754,
			3317.079,
			3318.643,
			3320.2,
			3322.539,
			3324.103,
			3326.44,
			3327.224,
			3328.014,
			3330.373,
			3331.941,
			3334.333,
			3335.923,
			3338.313,
			3339.909,
			3340.706,
			3342.3,
			3343.9,
			3344.697,
			3346.287,
			3347.875,
			3350.239,
			3351.808,
			3354.154,
			3354.933,
			3355.712,
			3358.04,
			3359.558,
			3361.836,
			3363.345,
			3364.826,
			3365.568,
			3367.053,
			3367.798,
			3368.53,
			3370.711,
			3371.44,
			3372.17,
			3374.352,
			3375.802,
			3377.259,
			3377.991,
			3379.456,
			3380.927,
			3381.665,
			3383.892,
			3385.384,
			3386.885,
			3389.16,
			3390.69,
			3393.009,
			3393.789,
			3394.574,
			3396.95,
			3398.551,
			3400.987,
			3402.629,
			3404.273,
			3405.103,
			3406.768,
			3407.605,
			3409.29,
			3411.008,
			3411.872,
			3413.609,
			3415.365,
			3417.142,
			3418.931,
			3419.831,
			3421.632,
			3423.462,
			3424.381,
			3426.231,
			3427.161,
			3429.031,
			3430.913,
			3433.754,
			3435.664,
			3438.549,
			3439.516,
			3440.487,
			3443.418,
			3445.387,
			3448.361,
			3450.359,
			3452.368,
			3453.376,
			3455.402,
			3456.419,
			3458.464,
			3460.52,
			3461.553,
			3462.588,
			3465.711,
			3468.86,
			3470.962,
			3473.067,
			3475.181,
			3476.242,
			3478.372,
			3479.442,
			3481.607,
			3483.766,
			3487.027,
			3489.214,
			3492.518,
			3493.624,
			3494.735,
			3498.081,
			3499.201,
			3500.325,
			3503.708,
			3505.983,
			3508.267,
			3509.416,
			3511.727,
			3512.887,
			3515.225,
			3517.589,
			3518.775,
			3521.167,
			3523.572,
			3527.199,
			3529.627,
			3533.315,
			3534.548,
			3535.786,
			3539.518,
			3541.988,
			3544.502,
			3548.23,
			3550.717,
			3554.436,
			3555.677,
			3556.911,
			3561.833,
			3563.064,
			3566.785,
			3569.241,
			3571.701,
			3572.924,
			3575.373,
			3577.82,
			3579.042,
			3582.707,
			3585.15,
			3587.594,
			3591.267,
			3593.719,
			3597.399,
			3598.626,
			3599.853,
			3603.533,
			3605.991,
			3609.67,
			3612.147,
			3615.879,
			3618.378,
			3619.631,
			3620.886,
			3624.664,
			3625.927,
			3627.19,
			3630.966,
			3633.457,
			3635.919,
			3637.137,
			3639.545,
			3641.915,
			3643.086,
			3645.399,
			3646.542,
			3648.799,
			3651.02,
			3654.279,
			3656.404,
			3659.521,
			3660.54,
			3661.551,
			3664.528,
			3666.468,
			3669.311,
			3671.161,
			3673.872,
			3675.637,
			3676.508,
			3678.223,
			3679.9,
			3680.725,
			3682.347,
			3683.945,
			3686.252,
			3687.739,
			3689.184,
			3690.593,
			3691.287,
			3693.32,
			3694.646,
			3695.959,
			3697.901,
			3699.175,
			3701.072,
			3701.703,
			3702.334,
			3704.235,
			3705.498,
			3707.391,
			3708.686,
			3709.978,
			3710.623,
			3711.915,
			3712.568,
			3713.896,
			3715.223,
			3715.886,
			3717.213,
			3718.578,
			3720.636,
			3721.322,
			3722.008,
			3724.106,
			3724.807,
			3725.508,
			3727.606,
			3729.023,
			3731.133,
			3732.536,
			3733.949,
			3736.06,
			3736.76,
			3737.46,
			3739.58,
			3740.286,
			3740.99,
			3743.104,
			3744.542,
			3745.971,
			3746.685,
			3748.108,
			3749.554,
			3750.274,
			3751.708,
			3752.423,
			3753.86,
			3755.298,
			3757.44,
			3758.153,
			3758.866,
			3760.994,
			3761.7,
			3762.404,
			3764.522,
			3765.934,
			3768.051,
			3769.461,
			3770.865,
			3771.567,
			3772.972,
			3773.676,
			3775.049,
			3776.42,
			3777.106,
			3777.792,
			3779.822,
			3781.159,
			3783.172,
			3784.503,
			3785.823,
			3786.485,
			3788.482,
			3789.806,
			3791.132,
			3793.131,
			3794.459,
			3796.471,
			3797.138,
			3797.8,
			3799.772,
			3801.085,
			3803.026,
			3804.31,
			3805.641,
			3806.312,
			3807.625,
			3808.278,
			3809.586,
			3810.917,
			3811.577,
			3812.89,
			3814.195,
			3816.169,
			3816.822,
			3817.472,
			3818.766,
			3820.061,
			3820.712,
			3822.007,
			3822.651,
			3823.936,
			3825.222,
			3827.149,
			3828.428,
			3830.347,
			3830.989,
			3831.628,
			3833.545,
			3834.826,
			3836.787,
			3838.093,
			3840.06,
			3841.398,
			3842.066,
			3843.401,
			3845.412,
			3846.791,
			3848.166,
			3850.229,
			3851.608,
			3853.666,
			3854.35,
			3855.034,
			3857.051,
			3858.387,
			3859.723,
			3861.714,
			3863.031,
			3865.003,
			3865.663,
			3866.326,
			3868.31,
			3868.971,
			3869.633,
			3871.618,
			3872.937,
			3874.917,
			3876.239,
			3877.558,
			3878.218,
			3880.2,
			3881.515,
			3882.817,
			3884.774,
			3886.085,
			3888.042,
			3888.695,
			3889.349,
			3891.325,
			3892.641,
			3894.631,
			3895.97,
			3897.306,
			3897.976,
			3899.323,
			3900.001,
			3901.367,
			3902.729,
			3903.414,
			3904.102,
			3906.188,
			3907.59,
			3909.002,
			3909.714,
			3911.149,
			3912.596,
			3913.324,
			3914.791,
			3915.53,
			3917.019,
			3918.521,
			3920.799,
			3922.337,
			3924.675,
			3925.461,
			3926.251,
			3928.647,
			3930.263,
			3932.71,
			3934.352,
			3935.99,
			3936.811,
			3938.464,
			3939.295,
			3940.966,
			3942.648,
			3943.491,
			3945.185,
			3946.891,
			3949.473,
			3951.209,
			3953.834,
			3954.714,
			3955.597,
			3957.372,
			3958.263,
			3960.053,
			3961.854,
			3964.58,
			3966.412,
			3969.181,
			3970.109,
			3971.04,
			3973.85,
			3975.744,
			3978.606,
			3980.537,
			3982.479,
			3983.453,
			3985.413,
			3986.396,
			3988.372,
			3990.365,
			3991.365,
			3993.374,
			3995.396,
			3998.443,
			3999.458,
			4000.473,
			4003.527,
			4004.551,
			4005.579,
			4008.676,
			4010.756,
			4013.895,
			4016.003,
			4018.124,
			4021.328,
			4022.404,
			4023.481,
			4026.736,
			4028.923,
			4032.231,
			4034.453,
			4037.812,
			4040.067,
			4042.334,
			4043.472,
			4046.905,
			4049.21,
			4051.527,
			4055.032,
			4056.206,
			4057.384,
			4060.939,
			4062.128,
			4063.326,
			4066.934,
			4069.358,
			4073.015,
			4075.478,
			4077.951,
			4081.686,
			4082.935,
			4085.456,
			4087.986,
			4089.259,
			4090.537,
			4094.385,
			4096.96,
			4099.548,
			4100.848,
			4103.453,
			4106.078,
			4107.394,
			4110.036,
			4111.361,
			4114.018,
			4116.686,
			4120.701,
			4123.384,
			4127.43,
			4128.783,
			4130.139,
			4134.212,
			4136.941,
			4141.063,
			4143.82,
			4146.6,
			4148.011,
			4150.795,
			4152.196,
			4155.004,
			4157.784,
			4159.209,
			4160.613,
			4164.828,
			4167.639,
			4170.453,
			4171.858,
			4174.667,
			4177.455,
			4178.885,
			4181.7,
			4183.115,
			4185.949,
			4188.771,
			4193.054,
			4195.912,
			4200.197,
			4201.625,
			4203.054,
			4207.352,
			4210.175,
			4214.444,
			4217.291,
			4220.161,
			4221.585,
			4224.412,
			4225.835,
			4228.688,
			4232.973,
			4235.833,
			4238.696,
			4242.993,
			4245.859,
			4250.155,
			4251.584,
			4253.014,
			4255.875,
			4257.303,
			4260.164,
			4264.456,
			4267.322,
			4270.194,
			4274.513,
			4275.955,
			4277.399,
			4283.195,
			4284.647,
			4289.017,
			4291.938,
			4294.871,
			4296.34,
			4299.288,
			4302.245,
			4303.728,
			4306.702,
			4308.191,
			4311.178,
			4314.17,
			4318.674,
			4321.681,
			4326.202,
			4327.713,
			4329.226,
			4333.778,
			4336.818,
			4341.387,
			4344.432,
			4347.481,
			4349.007,
			4352.063,
			4353.595,
			4356.662,
			4359.734,
			4361.273,
			4362.814,
			4367.451,
			4370.551,
			4375.21,
			4378.324,
			4381.447,
			4383.011,
			4387.721,
			4390.874,
			4394.035,
			4398.784,
			4401.954,
			4406.71,
			4408.295,
			4409.882,
			4414.638,
			4417.811,
			4422.58,
			4425.764,
			4428.952,
			4430.548,
			4433.746,
			4435.346,
			4436.948,
			4441.766,
			4443.374,
			4446.592,
			4449.809,
			4453.029,
			4456.251,
			4457.864,
			4461.092,
			4464.327,
			4465.946,
			4470.818,
			4474.078,
			4477.348,
			4482.257,
			4485.561,
			4490.487,
			4492.121,
			4493.757,
			4498.67,
			4501.931,
			4506.783,
			4510.057,
			4513.34,
			4514.983,
			4518.278,
			4519.927,
			4523.234,
			4526.547,
			4528.208,
			4531.532,
			4534.859,
			4536.034
		],
		[
			0,
			0.01,
			0.072,
			0.118,
			0.134,
			0.197,
			0.226,
			0.26,
			0.321,
			0.383,
			0.446,
			0.506,
			0.552,
			0.631,
			0.661,
			0.693,
			0.756,
			0.769,
			0.818,
			0.879,
			0.94,
			0.987,
			1.002,
			1.064,
			1.096,
			1.127,
			1.205,
			1.252,
			1.312,
			1.375,
			1.422,
			1.499,
			1.535,
			1.559,
			1.621,
			1.684,
			1.748,
			1.81,
			1.856,
			1.871,
			1.932,
			1.962,
			1.996,
			2.057,
			2.072,
			2.118,
			2.184,
			2.243,
			2.305,
			2.366,
			2.399,
			2.429,
			2.492,
			2.507,
			2.551,
			2.614,
			2.677,
			2.724,
			2.802,
			2.833,
			2.863,
			2.925,
			2.988,
			3.051,
			3.11,
			3.157,
			3.173,
			3.233,
			3.266,
			3.297,
			3.359,
			3.375,
			3.421,
			3.483,
			3.546,
			3.598,
			3.669,
			3.699,
			3.732,
			3.809,
			3.855,
			3.922,
			3.98,
			4.026,
			4.101,
			4.134,
			4.164,
			4.227,
			4.291,
			4.35,
			4.412,
			4.461,
			4.474,
			4.538,
			4.568,
			4.598,
			4.676,
			4.722,
			4.784,
			4.846,
			4.894,
			4.972,
			5,
			5.034,
			5.096,
			5.158,
			5.221,
			5.282,
			5.327,
			5.344,
			5.406,
			5.435,
			5.468,
			5.53,
			5.545,
			5.592,
			5.66,
			5.716,
			5.779,
			5.84,
			5.869,
			5.9,
			5.979,
			6.027,
			6.088,
			6.149,
			6.195,
			6.274,
			6.305,
			6.335,
			6.396,
			6.459,
			6.523,
			6.583,
			6.63,
			6.645,
			6.708,
			6.738,
			6.77,
			6.846,
			6.894,
			6.957,
			7.019,
			7.065,
			7.079,
			7.141,
			7.174,
			7.205,
			7.281,
			7.329,
			7.39,
			7.451,
			7.497,
			7.576,
			7.608,
			7.639,
			7.722,
			7.762,
			7.825,
			7.887,
			7.948,
			8.009,
			8.04,
			8.072,
			8.134,
			8.149,
			8.196,
			8.258,
			8.318,
			8.382,
			8.444,
			8.474,
			8.507,
			8.568,
			8.585,
			8.629,
			8.691,
			8.754,
			8.802,
			8.878,
			8.908,
			8.941,
			9.019,
			9.065,
			9.127,
			9.189,
			9.249,
			9.311,
			9.343,
			9.373,
			9.435,
			9.451,
			9.498,
			9.562,
			9.622,
			9.67,
			9.746,
			9.781,
			9.809,
			9.87,
			9.931,
			9.993,
			10.056,
			10.102,
			10.18,
			10.211,
			10.241,
			10.305,
			10.319,
			10.366,
			10.429,
			10.491,
			10.535,
			10.552,
			10.615,
			10.646,
			10.677,
			10.739,
			10.752,
			10.8,
			10.863,
			10.925,
			10.971,
			11.048,
			11.08,
			11.11,
			11.173,
			11.236,
			11.297,
			11.358,
			11.405,
			11.421,
			11.483,
			11.514,
			11.544,
			11.607,
			11.622,
			11.67,
			11.731,
			11.793,
			11.856,
			11.916,
			11.949,
			11.98,
			12.056,
			12.102,
			12.164,
			12.227,
			12.273,
			12.351,
			12.382,
			12.413,
			12.476,
			12.537,
			12.599,
			12.659,
			12.707,
			12.724,
			12.783,
			12.816,
			12.845,
			12.91,
			12.925,
			12.972,
			13.032,
			13.095,
			13.156,
			13.219,
			13.249,
			13.28,
			13.341,
			13.359,
			13.405,
			13.466,
			13.529,
			13.591,
			13.653,
			13.684,
			13.713,
			13.776,
			13.839,
			13.905,
			13.964,
			14.01,
			14.026,
			14.087,
			14.117,
			14.15,
			14.211,
			14.227,
			14.272,
			14.335,
			14.397,
			14.444,
			14.521,
			14.552,
			14.582,
			14.646,
			14.708,
			14.771,
			14.83,
			14.877,
			14.955,
			14.985,
			15.016,
			15.08,
			15.094,
			15.141,
			15.204,
			15.264,
			15.31,
			15.327,
			15.389,
			15.421,
			15.452,
			15.513,
			15.529,
			15.574,
			15.638,
			15.7,
			15.76,
			15.824,
			15.854,
			15.884,
			15.946,
			16.009,
			16.072,
			16.132,
			16.179,
			16.194,
			16.258,
			16.288,
			16.32,
			16.381,
			16.397,
			16.444,
			16.505,
			16.566,
			16.613,
			16.629,
			16.69,
			16.722,
			16.751,
			16.814,
			16.831,
			16.878,
			16.939,
			17.002,
			17.049,
			17.127,
			17.157,
			17.187,
			17.249,
			17.313,
			17.373,
			17.434,
			17.481,
			17.497,
			17.561,
			17.592,
			17.622,
			17.698,
			17.744,
			17.807,
			17.87,
			17.93,
			17.992,
			18.055,
			18.117,
			18.134,
			18.18,
			18.241,
			18.305,
			18.349,
			18.429,
			18.459,
			18.489,
			18.566,
			18.613,
			18.677,
			18.737,
			18.8,
			18.862,
			18.892,
			18.922,
			18.985,
			19,
			19.046,
			19.11,
			19.172,
			19.233,
			19.294,
			19.326,
			19.357,
			19.42,
			19.483,
			19.544,
			19.605,
			19.654,
			19.729,
			19.762,
			19.791,
			19.855,
			19.917,
			19.978,
			20.041,
			20.101,
			20.165,
			20.196,
			20.226,
			20.289,
			20.304,
			20.349,
			20.413,
			20.474,
			20.522,
			20.597,
			20.629,
			20.661,
			20.722,
			20.784,
			20.847,
			20.907,
			20.955,
			20.97,
			21.032,
			21.062,
			21.094,
			21.155,
			21.172,
			21.217,
			21.283,
			21.341,
			21.405,
			21.466,
			21.496,
			21.528,
			21.605,
			21.653,
			21.714,
			21.776,
			21.824,
			21.9,
			21.932,
			21.961,
			22.024,
			22.087,
			22.149,
			22.209,
			22.258,
			22.273,
			22.334,
			22.364,
			22.396,
			22.46,
			22.474,
			22.518,
			22.581,
			22.645,
			22.691,
			22.706,
			22.767,
			22.799,
			22.829,
			22.891,
			22.908,
			22.955,
			23.017,
			23.079,
			23.126,
			23.202,
			23.234,
			23.264,
			23.328,
			23.388,
			23.452,
			23.513,
			23.558,
			23.575,
			23.637,
			23.668,
			23.698,
			23.761,
			23.776,
			23.821,
			23.883,
			23.946,
			23.994,
			24.071,
			24.102,
			24.132,
			24.194,
			24.256,
			24.318,
			24.38,
			24.444,
			24.505,
			24.535,
			24.565,
			24.628,
			24.645,
			24.69,
			24.752,
			24.815,
			24.877,
			24.939,
			24.97,
			25.001,
			25.062,
			25.077,
			25.124,
			25.188,
			25.249,
			25.294,
			25.376,
			25.405,
			25.436,
			25.496,
			25.558,
			25.622,
			25.683,
			25.73,
			25.746,
			25.807,
			25.838,
			25.87,
			25.931,
			25.946,
			25.992,
			26.056,
			26.117,
			26.164,
			26.178,
			26.242,
			26.276,
			26.301,
			26.364,
			26.381,
			26.427,
			26.489,
			26.551,
			26.597,
			26.675,
			26.706,
			26.738,
			26.8,
			26.861,
			26.925,
			26.984,
			27.033,
			27.049,
			27.109,
			27.141,
			27.171,
			27.234,
			27.248,
			27.296,
			27.357,
			27.42,
			27.48,
			27.543,
			27.574,
			27.605,
			27.683,
			27.728,
			27.792,
			27.854,
			27.916,
			27.977,
			28.009,
			28.038,
			28.099,
			28.164,
			28.227,
			28.285,
			28.34,
			28.349,
			28.413,
			28.442,
			28.474,
			28.536,
			28.552,
			28.596,
			28.66,
			28.722,
			28.783,
			28.846,
			28.877,
			28.907,
			28.97,
			29.03,
			29.094,
			29.155,
			29.202,
			29.28,
			29.31,
			29.34,
			29.402,
			29.42,
			29.466,
			29.529,
			29.589,
			29.637,
			29.651,
			29.714,
			29.746,
			29.776,
			29.838,
			29.851,
			29.9,
			29.961,
			30.024,
			30.068,
			30.086,
			30.147,
			30.177,
			30.21,
			30.288,
			30.333,
			30.398,
			30.458,
			30.505,
			30.521,
			30.58,
			30.613,
			30.644,
			30.706,
			30.722,
			30.766,
			30.83,
			30.891,
			30.939,
			30.955,
			31.015,
			31.047,
			31.078,
			31.141,
			31.155,
			31.202,
			31.263,
			31.327,
			31.372,
			31.45,
			31.481,
			31.511,
			31.574,
			31.635,
			31.699,
			31.758,
			31.807,
			31.821,
			31.883,
			31.914,
			31.946,
			32.024,
			32.071,
			32.137,
			32.193,
			32.241,
			32.255,
			32.317,
			32.348,
			32.379,
			32.443,
			32.463,
			32.505,
			32.565,
			32.629,
			32.69,
			32.753,
			32.783,
			32.813,
			32.877,
			32.938,
			33.001,
			33.062,
			33.107,
			33.124,
			33.187,
			33.218,
			33.247,
			33.31,
			33.324,
			33.373,
			33.433,
			33.496,
			33.544,
			33.621,
			33.651,
			33.682,
			33.76,
			33.806,
			33.867,
			33.93,
			33.977,
			34.055,
			34.085,
			34.117,
			34.179,
			34.241,
			34.302,
			34.365,
			34.411,
			34.427,
			34.488,
			34.525,
			34.551,
			34.613,
			34.627,
			34.674,
			34.735,
			34.798,
			34.844,
			34.921,
			34.955,
			34.983,
			35.047,
			35.109,
			35.172,
			35.231,
			35.293,
			35.357,
			35.385,
			35.417,
			35.495,
			35.543,
			35.605,
			35.665,
			35.713,
			35.727,
			35.791,
			35.821,
			35.852,
			35.914,
			35.93,
			35.977,
			36.039,
			36.099,
			36.146,
			36.225,
			36.256,
			36.288,
			36.347,
			36.41,
			36.473,
			36.535,
			36.596,
			36.658,
			36.688,
			36.722,
			36.782,
			36.799,
			36.846,
			36.908,
			36.969,
			37.015,
			37.03,
			37.093,
			37.124,
			37.155,
			37.233,
			37.28,
			37.341,
			37.404,
			37.451,
			37.528,
			37.559,
			37.588,
			37.65,
			37.714,
			37.776,
			37.838,
			37.898,
			37.961,
			37.991,
			38.022,
			38.084,
			38.1,
			38.148,
			38.209,
			38.272,
			38.333,
			38.396,
			38.425,
			38.458,
			38.52,
			38.58,
			38.646,
			38.705,
			38.753,
			38.828,
			38.859,
			38.892,
			38.952,
			39.016,
			39.077,
			39.14,
			39.187,
			39.2,
			39.262,
			39.295,
			39.327,
			39.402,
			39.45,
			39.512,
			39.573,
			39.637,
			39.699,
			39.728,
			39.759,
			39.821,
			39.885,
			39.947,
			40.006,
			40.055,
			40.07,
			40.132,
			40.162,
			40.195,
			40.272,
			40.318,
			40.379,
			40.441,
			40.489,
			40.504,
			40.566,
			40.597,
			40.627,
			40.688,
			40.711,
			40.752,
			40.813,
			40.876,
			40.938,
			40.999,
			41.03,
			41.06,
			41.123,
			41.185,
			41.249,
			41.31,
			41.357,
			41.372,
			41.434,
			41.465,
			41.494,
			41.558,
			41.574,
			41.619,
			41.682,
			41.744,
			41.791,
			41.804,
			41.869,
			41.897,
			41.929,
			41.992,
			42.006,
			42.055,
			42.117,
			42.176,
			42.238,
			42.301,
			42.333,
			42.363,
			42.426,
			42.489,
			42.551,
			42.613,
			42.674,
			42.736,
			42.772,
			42.797,
			42.874,
			42.922,
			42.986,
			43.045,
			43.094,
			43.171,
			43.2,
			43.233,
			43.295,
			43.356,
			43.417,
			43.48,
			43.542,
			43.605,
			43.633,
			43.667,
			43.744,
			43.79,
			43.853,
			43.915,
			43.975,
			44.038,
			44.07,
			44.099,
			44.177,
			44.224,
			44.285,
			44.349,
			44.41,
			44.472,
			44.504,
			44.535,
			44.595,
			44.658,
			44.721,
			44.781,
			44.832,
			44.844,
			44.907,
			44.939,
			44.968,
			45.03,
			45.046,
			45.092,
			45.154,
			45.216,
			45.263,
			45.28,
			45.341,
			45.372,
			45.402,
			45.464,
			45.48,
			45.526,
			45.588,
			45.65,
			45.711,
			45.774,
			45.806,
			45.837,
			45.9,
			45.961,
			46.023,
			46.083,
			46.131,
			46.146,
			46.208,
			46.238,
			46.27,
			46.334,
			46.349,
			46.395,
			46.457,
			46.517,
			46.566,
			46.58,
			46.641,
			46.674,
			46.705,
			46.766,
			46.783,
			46.827,
			46.895,
			46.954,
			46.999,
			47.077,
			47.107,
			47.138,
			47.2,
			47.263,
			47.324,
			47.388,
			47.433,
			47.449,
			47.51,
			47.543,
			47.573,
			47.635,
			47.649,
			47.695,
			47.76,
			47.821,
			47.868,
			47.881,
			47.943,
			47.975,
			48.008,
			48.068,
			48.085,
			48.131,
			48.198,
			48.255,
			48.3,
			48.38,
			48.411,
			48.442,
			48.504,
			48.566,
			48.626,
			48.689,
			48.735,
			48.752,
			48.813,
			48.845,
			48.874,
			48.937,
			48.955,
			48.999,
			49.063,
			49.122,
			49.183,
			49.248,
			49.279,
			49.309,
			49.384,
			49.433,
			49.496,
			49.557,
			49.619,
			49.682,
			49.713,
			49.744,
			49.821,
			49.866,
			49.928,
			49.991,
			50.038,
			50.054,
			50.114,
			50.146,
			50.177,
			50.24,
			50.255,
			50.3,
			50.363,
			50.424,
			50.472,
			50.548,
			50.581,
			50.612,
			50.673,
			50.735,
			50.797,
			50.86,
			50.922,
			50.982,
			51.045,
			51.108,
			51.122,
			51.17,
			51.231,
			51.293,
			51.341,
			51.355,
			51.417,
			51.448,
			51.479,
			51.541,
			51.558,
			51.604,
			51.667,
			51.728,
			51.774,
			51.852,
			51.883,
			51.913,
			51.974,
			52.038,
			52.1,
			52.161,
			52.207,
			52.224,
			52.284,
			52.317,
			52.346,
			52.411,
			52.425,
			52.471,
			52.533,
			52.596,
			52.657,
			52.72,
			52.749,
			52.78,
			52.843,
			52.859,
			52.906,
			52.968,
			53.028,
			53.079,
			53.152,
			53.185,
			53.214,
			53.277,
			53.294,
			53.341,
			53.401,
			53.462,
			53.51,
			53.527,
			53.589,
			53.62,
			53.648,
			53.711,
			53.728,
			53.774,
			53.837,
			53.898,
			53.943,
			53.961,
			54.021,
			54.052,
			54.083,
			54.147,
			54.207,
			54.27,
			54.331,
			54.394,
			54.456,
			54.488,
			54.517,
			54.579,
			54.596,
			54.641,
			54.705,
			54.766,
			54.814,
			54.829,
			54.889,
			54.921,
			54.95,
			55.03,
			55.077,
			55.144,
			55.201,
			55.247,
			55.324,
			55.355,
			55.386,
			55.447,
			55.51,
			55.571,
			55.634,
			55.696,
			55.759,
			55.788,
			55.818,
			55.898,
			55.944,
			56.007,
			56.066,
			56.114,
			56.131,
			56.193,
			56.222,
			56.255,
			56.316,
			56.33,
			56.377,
			56.441,
			56.501,
			56.547,
			56.628,
			56.657,
			56.689,
			56.749,
			56.813,
			56.873,
			56.937,
			56.984,
			56.998,
			57.06,
			57.092,
			57.122,
			57.183,
			57.206,
			57.247,
			57.31,
			57.369,
			57.417,
			57.431,
			57.496,
			57.523,
			57.558,
			57.62,
			57.681,
			57.742,
			57.806,
			57.852,
			57.929,
			57.959,
			57.99,
			58.052,
			58.114,
			58.178,
			58.238,
			58.283,
			58.302,
			58.361,
			58.394,
			58.425,
			58.503,
			58.547,
			58.61,
			58.674,
			58.719,
			58.796,
			58.828,
			58.858,
			58.921,
			58.984,
			59.048,
			59.107,
			59.155,
			59.17,
			59.231,
			59.294,
			59.355,
			59.372,
			59.415,
			59.48,
			59.541,
			59.603,
			59.666,
			59.696,
			59.727,
			59.789,
			59.805,
			59.852,
			59.914,
			59.974,
			60.035,
			60.099,
			60.131,
			60.161,
			60.239,
			60.285,
			60.349,
			60.408,
			60.471,
			60.532,
			60.563,
			60.596,
			60.657,
			60.672,
			60.72,
			60.78,
			60.844,
			60.891,
			60.905,
			60.966,
			60.999,
			61.029,
			61.092,
			61.107,
			61.154,
			61.215,
			61.276,
			61.34,
			61.401,
			61.431,
			61.464,
			61.526,
			61.588,
			61.649,
			61.712,
			61.758,
			61.773,
			61.835,
			61.867,
			61.898,
			61.975,
			62.022,
			62.083,
			62.146,
			62.192,
			62.207,
			62.268,
			62.299,
			62.33,
			62.407,
			62.456,
			62.517,
			62.58,
			62.625,
			62.647,
			62.702,
			62.735,
			62.766,
			62.828,
			62.891,
			62.952,
			63.012,
			63.061,
			63.074,
			63.139,
			63.167,
			63.198,
			63.263,
			63.277,
			63.324,
			63.387,
			63.447,
			63.494,
			63.572,
			63.602,
			63.634,
			63.696,
			63.758,
			63.82,
			63.882,
			63.929,
			63.944,
			64.007,
			64.035,
			64.067,
			64.146,
			64.192,
			64.255,
			64.316,
			64.362,
			64.377,
			64.441,
			64.471,
			64.502,
			64.564,
			64.58,
			64.625,
			64.688,
			64.75,
			64.81,
			64.874,
			64.905,
			64.935,
			64.999,
			65.058,
			65.121,
			65.182,
			65.232,
			65.246,
			65.307,
			65.339,
			65.368,
			65.452,
			65.495,
			65.555,
			65.617,
			65.666,
			65.68,
			65.742,
			65.774,
			65.802,
			65.866,
			65.882,
			65.929,
			65.99,
			66.052,
			66.114,
			66.177,
			66.208,
			66.239,
			66.299,
			66.363,
			66.423,
			66.486,
			66.532,
			66.549,
			66.61,
			66.641,
			66.672,
			66.735,
			66.749,
			66.796,
			66.858,
			66.918,
			66.983,
			67.043,
			67.075,
			67.105,
			67.183,
			67.231,
			67.293,
			67.356,
			67.399,
			67.481,
			67.513,
			67.541,
			67.602,
			67.663,
			67.727,
			67.789,
			67.85,
			67.912,
			67.944,
			67.973,
			68.037,
			68.053,
			68.098,
			68.161,
			68.222,
			68.286,
			68.347,
			68.378,
			68.41,
			68.487,
			68.531,
			68.595,
			68.657,
			68.718,
			68.78,
			68.812,
			68.841,
			68.906,
			68.92,
			68.966,
			69.027,
			69.091,
			69.138,
			69.152,
			69.213,
			69.245,
			69.276,
			69.338,
			69.352,
			69.401,
			69.463,
			69.525,
			69.574,
			69.65,
			69.68,
			69.709,
			69.772,
			69.835,
			69.896,
			69.957,
			70.004,
			70.083,
			70.113,
			70.144,
			70.222,
			70.269,
			70.331,
			70.392,
			70.455,
			70.516,
			70.549,
			70.579,
			70.641,
			70.655,
			70.704,
			70.766,
			70.828,
			70.874,
			70.95,
			70.981,
			71.014,
			71.074,
			71.135,
			71.2,
			71.26,
			71.308,
			71.322,
			71.416,
			71.448,
			71.508,
			71.525,
			71.571,
			71.635,
			71.696,
			71.757,
			71.82,
			71.849,
			71.882,
			71.944,
			72.006,
			72.067,
			72.129,
			72.191,
			72.252,
			72.283,
			72.314,
			72.393,
			72.44,
			72.502,
			72.564,
			72.626,
			72.685,
			72.717,
			72.749,
			72.811,
			72.826,
			72.873,
			72.934,
			72.997,
			73.044,
			73.122,
			73.154,
			73.183,
			73.246,
			73.307,
			73.37,
			73.43,
			73.492,
			73.555,
			73.587,
			73.617,
			73.68,
			73.7,
			73.74,
			73.804,
			73.865,
			73.912,
			73.928,
			73.988,
			74.02,
			74.051,
			74.114,
			74.13,
			74.174,
			74.239,
			74.301,
			74.362,
			74.425,
			74.455,
			74.485,
			74.562,
			74.608,
			74.672,
			74.732,
			74.795,
			74.857,
			74.889,
			74.918,
			74.981,
			74.997,
			75.043,
			75.106,
			75.166,
			75.213,
			75.229,
			75.293,
			75.323,
			75.354,
			75.415,
			75.432,
			75.477,
			75.541,
			75.601,
			75.649,
			75.725,
			75.761,
			75.788,
			75.866,
			75.913,
			75.975,
			76.037,
			76.082,
			76.099,
			76.16,
			76.19,
			76.222,
			76.282,
			76.299,
			76.346,
			76.408,
			76.468,
			76.517,
			76.532,
			76.594,
			76.625,
			76.655,
			76.733,
			76.78,
			76.842,
			76.904,
			76.949,
			77.027,
			77.06,
			77.091,
			77.153,
			77.214,
			77.277,
			77.338,
			77.399,
			77.461,
			77.492,
			77.524,
			77.587,
			77.602,
			77.649,
			77.71,
			77.771,
			77.821,
			77.896,
			77.928,
			77.958,
			78.019,
			78.084,
			78.146,
			78.207,
			78.251,
			78.269,
			78.33,
			78.36,
			78.391,
			78.455,
			78.47,
			78.515,
			78.579,
			78.64,
			78.685,
			78.701,
			78.763,
			78.796,
			78.827,
			78.905,
			78.95,
			79.013,
			79.075,
			79.122,
			79.197,
			79.23,
			79.259,
			79.322,
			79.385,
			79.447,
			79.513,
			79.555,
			79.572,
			79.632,
			79.664,
			79.694,
			79.758,
			79.772,
			79.82,
			79.88,
			79.943,
			79.99,
			80.006,
			80.066,
			80.096,
			80.13,
			80.191,
			80.206,
			80.252,
			80.316,
			80.377,
			80.439,
			80.5,
			80.53,
			80.563,
			80.626,
			80.684,
			80.749,
			80.811,
			80.858,
			80.873,
			80.935,
			80.965,
			80.996,
			81.059,
			81.074,
			81.118,
			81.184,
			81.244,
			81.307,
			81.367,
			81.4,
			81.431,
			81.493,
			81.507,
			81.556,
			81.616,
			81.679,
			81.742,
			81.804,
			81.833,
			81.866,
			81.927,
			81.946,
			81.988,
			82.05,
			82.112,
			82.16,
			82.174,
			82.235,
			82.267,
			82.299,
			82.362,
			82.378,
			82.422,
			82.486,
			82.547,
			82.594,
			82.672,
			82.703,
			82.734,
			82.81,
			82.857,
			82.919,
			82.98,
			83.029,
			83.105,
			83.137,
			83.166,
			83.245,
			83.292,
			83.352,
			83.414,
			83.463,
			83.477,
			83.539,
			83.57,
			83.602,
			83.679,
			83.726,
			83.788,
			83.849,
			83.894,
			83.974,
			84.01,
			84.033,
			84.098,
			84.16,
			84.221,
			84.284,
			84.345,
			84.407,
			84.439,
			84.467,
			84.53,
			84.547,
			84.594,
			84.656,
			84.716,
			84.764,
			84.78,
			84.841,
			84.873,
			84.902,
			84.964,
			84.98,
			85.026,
			85.091,
			85.15,
			85.198,
			85.274,
			85.306,
			85.336,
			85.4,
			85.461,
			85.524,
			85.585,
			85.646,
			85.71,
			85.741,
			85.772,
			85.833,
			85.85,
			85.895,
			85.958,
			86.019,
			86.082,
			86.143,
			86.174,
			86.205,
			86.269,
			86.331,
			86.393,
			86.454,
			86.5,
			86.577,
			86.608,
			86.641,
			86.702,
			86.763,
			86.824,
			86.885,
			86.933,
			86.949,
			87.011,
			87.043,
			87.075,
			87.137,
			87.149,
			87.197,
			87.259,
			87.321,
			87.368,
			87.382,
			87.446,
			87.476,
			87.507,
			87.587,
			87.63,
			87.694,
			87.755,
			87.8,
			87.879,
			87.911,
			87.941,
			88.005,
			88.02,
			88.066,
			88.132,
			88.189,
			88.235,
			88.252,
			88.313,
			88.345,
			88.376,
			88.439,
			88.455,
			88.5,
			88.563,
			88.624,
			88.669,
			88.687,
			88.749,
			88.778,
			88.811,
			88.872,
			88.933,
			88.996,
			89.058,
			89.105,
			89.119,
			89.183,
			89.212,
			89.244,
			89.306,
			89.322,
			89.367,
			89.431,
			89.493,
			89.552,
			89.617,
			89.648,
			89.678,
			89.755,
			89.803,
			89.864,
			89.927,
			89.973,
			90.051,
			90.082,
			90.111,
			90.193,
			90.235,
			90.299,
			90.359,
			90.407,
			90.423,
			90.483,
			90.515,
			90.545,
			90.607,
			90.625,
			90.671,
			90.732,
			90.794,
			90.84,
			90.858,
			90.916,
			90.951,
			90.981,
			91.042,
			91.058,
			91.105,
			91.168,
			91.23,
			91.291,
			91.354,
			91.383,
			91.414,
			91.476,
			91.538,
			91.602,
			91.663,
			91.726,
			91.785,
			91.816,
			91.849,
			91.926,
			91.973,
			92.034,
			92.096,
			92.159,
			92.221,
			92.255,
			92.283,
			92.344,
			92.407,
			92.468,
			92.53,
			92.577,
			92.655,
			92.687,
			92.716,
			92.779,
			92.794,
			92.84,
			92.901,
			92.966,
			93.027,
			93.088,
			93.122,
			93.149,
			93.227,
			93.274,
			93.335,
			93.399,
			93.46,
			93.521,
			93.555,
			93.584,
			93.661,
			93.71,
			93.771,
			93.832,
			93.879,
			93.896,
			93.958,
			93.988,
			94.02,
			94.08,
			94.098,
			94.142,
			94.206,
			94.267,
			94.316,
			94.329,
			94.392,
			94.422,
			94.452,
			94.514,
			94.531,
			94.578,
			94.641,
			94.701,
			94.749,
			94.824,
			94.857,
			94.888,
			94.948,
			95.012,
			95.074,
			95.133,
			95.183,
			95.198,
			95.26,
			95.29,
			95.321,
			95.383,
			95.399,
			95.446,
			95.511,
			95.569,
			95.631,
			95.694,
			95.724,
			95.756,
			95.817,
			95.834,
			95.88,
			95.941,
			96.002,
			96.066,
			96.126,
			96.158,
			96.189,
			96.249,
			96.312,
			96.381,
			96.438,
			96.482,
			96.499,
			96.561,
			96.591,
			96.623,
			96.685,
			96.702,
			96.747,
			96.811,
			96.871,
			96.919,
			96.933,
			96.997,
			97.027,
			97.058,
			97.12,
			97.183,
			97.244,
			97.305,
			97.352,
			97.43,
			97.461,
			97.493,
			97.553,
			97.615,
			97.677,
			97.741,
			97.801,
			97.863,
			97.896,
			97.926,
			98.004,
			98.052,
			98.113,
			98.174,
			98.218,
			98.237,
			98.299,
			98.33,
			98.359,
			98.441,
			98.485,
			98.547,
			98.608,
			98.655,
			98.732,
			98.763,
			98.794,
			98.856,
			98.871,
			98.917,
			98.98,
			99.043,
			99.088,
			99.102,
			99.166,
			99.197,
			99.228,
			99.288,
			99.305,
			99.35,
			99.413,
			99.477,
			99.539,
			99.6,
			99.63,
			99.663,
			99.741,
			99.785,
			99.85,
			99.911,
			99.956,
			99.972,
			100.033,
			100.064,
			100.097,
			100.158,
			100.174,
			100.218,
			100.283,
			100.343,
			100.391,
			100.407,
			100.467,
			100.503,
			100.53,
			100.593,
			100.607,
			100.655,
			100.717,
			100.778,
			100.825,
			100.901,
			100.934,
			100.963,
			101.026,
			101.088,
			101.152,
			101.212,
			101.258,
			101.274,
			101.337,
			101.366,
			101.398,
			101.476,
			101.523,
			101.583,
			101.646,
			101.707,
			101.771,
			101.8,
			101.833,
			101.893,
			101.91,
			101.956,
			102.019,
			102.079,
			102.127,
			102.205,
			102.235,
			102.265,
			102.344,
			102.391,
			102.454,
			102.513,
			102.567,
			102.577,
			102.639,
			102.67,
			102.7,
			102.762,
			102.777,
			102.824,
			102.885,
			102.949,
			102.996,
			103.072,
			103.105,
			103.134,
			103.196,
			103.26,
			103.321,
			103.383,
			103.43,
			103.446,
			103.507,
			103.539,
			103.569,
			103.629,
			103.646,
			103.692,
			103.756,
			103.815,
			103.878,
			103.941,
			103.971,
			104.004,
			104.08,
			104.127,
			104.189,
			104.252,
			104.296,
			104.373,
			104.404,
			104.437,
			104.499,
			104.56,
			104.626,
			104.684,
			104.73,
			104.746,
			104.81,
			104.841,
			104.868,
			104.934,
			104.949,
			104.995,
			105.057,
			105.118,
			105.164,
			105.181,
			105.242,
			105.273,
			105.306,
			105.383,
			105.43,
			105.491,
			105.553,
			105.6,
			105.677,
			105.707,
			105.74,
			105.817,
			105.863,
			105.924,
			105.987,
			106.033,
			106.05,
			106.11,
			106.143,
			106.173,
			106.236,
			106.251,
			106.297,
			106.361,
			106.369
		]
	],
	'2467_3291_7982': [
		[
			83.606
		],
		[
			0
		],
		[
			0,
			2.978,
			6.305,
			11.304,
			12.972,
			14.642,
			19.66,
			23.011,
			28.046,
			31.409,
			34.778,
			36.464,
			39.842,
			41.533,
			44.921,
			48.315,
			50.015,
			53.417,
			56.823,
			60.233,
			63.646,
			65.355,
			70.486,
			72.198,
			73.911,
			77.337,
			79.05,
			82.479,
			85.908,
			91.055,
			94.488,
			99.641,
			101.361,
			103.081,
			108.252,
			111.704,
			116.89,
			120.353,
			123.819,
			125.553,
			129.024,
			132.498,
			134.236,
			137.715,
			139.456,
			142.942,
			146.431,
			151.669,
			153.416,
			155.165,
			160.416,
			162.167,
			163.92,
			167.426,
			169.18,
			172.689,
			177.961,
			181.479,
			185,
			190.279,
			192.035,
			193.788,
			199.009,
			200.734,
			202.45,
			207.537,
			210.882,
			215.829,
			219.078,
			222.287,
			223.877,
			227.027,
			228.589,
			231.684,
			234.74,
			239.256,
			242.22,
			246.599,
			248.04,
			249.471,
			253.708,
			256.481,
			260.568,
			263.25,
			265.9,
			267.212,
			269.808,
			273.637,
			276.141,
			277.378,
			279.825,
			282.235,
			284.609,
			286.951,
			288.109,
			290.401,
			292.656,
			293.769,
			295.968,
			297.054,
			299.2,
			301.327,
			304.405,
			306.424,
			309.403,
			310.372,
			311.332,
			314.166,
			316.018,
			318.754,
			320.549,
			322.322,
			323.205,
			324.956,
			325.817,
			327.533,
			329.24,
			330.083,
			331.742,
			333.395,
			335.86,
			336.676,
			337.492,
			339.125,
			340.831,
			341.716,
			344.398,
			346.231,
			348.299,
			351.444,
			353.475,
			356.618,
			357.681,
			358.738,
			361.817,
			363.959,
			367.434,
			369.529,
			371.623,
			372.595,
			374.541,
			375.514,
			377.464,
			379.411,
			380.386,
			382.348,
			384.372,
			387.368,
			389.324,
			392.136,
			393.073,
			394.016,
			396.92,
			398.856,
			401.738,
			403.637,
			405.518,
			408.396,
			409.371,
			410.348,
			413.306,
			414.302,
			415.301,
			418.312,
			420.328,
			422.356,
			423.376,
			425.427,
			427.49,
			428.526,
			430.608,
			431.652,
			433.734,
			435.811,
			438.938,
			441.026,
			444.185,
			445.247,
			446.313,
			449.542,
			451.728,
			455.065,
			457.325,
			459.637,
			460.797,
			463.115,
			464.287,
			466.643,
			470.178,
			471.357,
			474.897,
			477.259,
			479.619,
			480.798,
			483.155,
			485.517,
			486.699,
			489.059,
			490.234,
			492.578,
			494.91,
			498.374,
			500.642,
			503.986,
			505.074,
			506.141,
			509.3,
			511.341,
			514.375,
			516.357,
			519.306,
			521.283,
			522.271,
			524.242,
			526.237,
			527.236,
			529.226,
			531.234,
			533.239,
			535.242,
			536.249,
			539.255,
			540.257,
			541.259,
			544.257,
			546.258,
			548.257,
			551.258,
			553.262,
			556.273,
			557.28,
			558.286,
			561.3,
			563.301,
			566.294,
			568.28,
			571.25,
			573.226,
			574.21,
			576.184,
			578.16,
			579.145,
			581.116,
			583.098,
			586.054,
			588.03,
			590.979,
			591.957,
			592.934,
			595.858,
			597.81,
			600.736,
			602.676,
			604.615,
			605.585,
			607.514,
			609.442,
			612.326,
			613.282,
			614.239,
			617.111,
			619.017,
			620.929,
			621.883,
			623.783,
			625.688,
			626.64,
			628.499,
			629.429,
			631.293,
			633.148,
			635.928,
			637.801,
			640.63,
			641.574,
			642.524,
			645.381,
			647.298,
			650.262,
			652.239,
			654.196,
			655.182,
			657.145,
			658.093,
			659.982,
			661.855,
			662.786,
			663.711,
			666.442,
			668.226,
			670.84,
			672.541,
			674.207,
			675.027,
			676.638,
			677.43,
			678.989,
			680.512,
			682.729,
			684.164,
			686.251,
			686.929,
			687.604,
			689.592,
			690.903,
			692.829,
			694.103,
			695.37,
			696.002,
			697.265,
			697.903,
			699.176,
			701.077,
			702.349,
			703.645,
			705.585,
			706.232,
			706.88,
			708.875,
			709.542,
			710.208,
			711.541,
			712.209,
			713.584,
			714.955,
			717.008,
			718.414,
			720.522,
			721.224,
			721.926,
			724.089,
			725.531,
			727.699,
			729.16,
			730.615,
			731.342,
			732.798,
			733.527,
			734.979,
			737.149,
			738.609,
			740.063,
			742.231,
			742.971,
			743.709,
			745.912,
			746.645,
			747.38,
			749.604,
			751.075,
			753.237,
			754.635,
			756.024,
			758.082,
			758.753,
			759.422,
			761.413,
			762.074,
			762.743,
			764.76,
			766.098,
			767.434,
			768.12,
			769.49,
			770.862,
			771.549,
			772.916,
			773.592,
			774.947,
			776.302,
			778.301,
			779.601,
			781.547,
			782.195,
			782.823,
			784.656,
			785.877,
			787.702,
			788.871,
			790.626,
			791.801,
			792.391,
			792.991,
			794.804,
			795.411,
			796.629,
			797.884,
			799.22,
			800.561,
			801.234,
			802.595,
			804.176,
			804.968,
			806.553,
			807.348,
			809.223,
			811.134,
			814.144,
			816.237,
			819.335,
			820.354,
			821.37,
			824.237,
			826.055,
			828.681,
			830.338,
			831.981,
			832.799,
			834.424,
			835.228,
			836.825,
			839.231,
			840.827,
			842.411,
			843.987,
			845.551,
			846.329,
			847.877,
			849.393,
			850.147,
			852.393,
			853.903,
			855.413,
			857.667,
			859.074,
			861.164,
			861.858,
			862.551,
			864.467,
			865.727,
			867.61,
			868.831,
			870.645,
			871.85,
			872.452,
			873.66,
			874.86,
			875.467,
			876.692,
			877.91,
			879.735,
			880.343,
			880.951,
			882.784,
			883.393,
			884.001,
			885.208,
			885.818,
			887.044,
			888.899,
			890.153,
			891.424,
			893.36,
			894.012,
			894.668,
			896.66,
			897.333,
			898.009,
			900.059,
			901.445,
			902.847,
			903.554,
			904.978,
			906.417,
			907.143,
			908.606,
			909.343,
			910.828,
			912.328,
			914.607,
			916.145,
			918.48,
			919.267,
			920.057,
			922.45,
			924.068,
			926.523,
			928.176,
			929.843,
			930.675,
			932.347,
			933.189,
			934.041,
			937.479,
			938.348,
			940.98,
			942.752,
			944.536,
			945.433,
			947.237,
			949.052,
			949.964,
			951.798,
			952.719,
			954.572,
			956.437,
			959.257,
			961.153,
			964.016,
			964.975,
			965.936,
			968.837,
			970.783,
			973.723,
			975.701,
			977.69,
			978.69,
			980.703,
			981.713,
			983.744,
			985.788,
			986.814,
			988.874,
			990.945,
			993.026,
			995.116,
			996.162,
			998.253,
			1000.348,
			1001.399,
			1004.568,
			1006.694,
			1008.831,
			1012.055,
			1014.217,
			1017.481,
			1018.574,
			1019.672,
			1022.981,
			1025.199,
			1028.545,
			1030.783,
			1033.031,
			1034.159,
			1036.423,
			1037.559,
			1039.839,
			1043.278,
			1045.583,
			1047.898,
			1051.389,
			1052.557,
			1053.728,
			1057.253,
			1058.432,
			1059.615,
			1061.986,
			1063.175,
			1065.561,
			1067.956,
			1071.564,
			1073.981,
			1077.62,
			1078.838,
			1080.059,
			1083.736,
			1084.967,
			1086.2,
			1089.912,
			1092.397,
			1094.89,
			1096.14,
			1098.645,
			1101.156,
			1102.413,
			1104.924,
			1106.175,
			1108.668,
			1111.138,
			1114.786,
			1117.169,
			1120.691,
			1121.84,
			1122.979,
			1126.327,
			1128.53,
			1131.782,
			1133.894,
			1135.97,
			1136.996,
			1139.024,
			1140.026,
			1141.021,
			1144.944,
			1145.913,
			1148.811,
			1150.746,
			1152.687,
			1153.66,
			1155.612,
			1157.567,
			1158.544,
			1160.499,
			1161.476,
			1163.424,
			1165.365,
			1168.25,
			1170.13,
			1172.924,
			1173.839,
			1174.747,
			1177.431,
			1179.18,
			1181.773,
			1183.449,
			1185.097,
			1185.914,
			1187.521,
			1188.312,
			1189.871,
			1191.412,
			1192.169,
			1193.657,
			1195.117,
			1197.261,
			1198.652,
			1200.024,
			1201.382,
			1202.05,
			1203.369,
			1204.02,
			1205.323,
			1206.616,
			1208.532,
			1209.805,
			1211.741,
			1212.385,
			1213.029,
			1214.967,
			1216.278,
			1218.244,
			1219.559,
			1221.563,
			1222.903,
			1223.574,
			1224.939,
			1227.095,
			1228.537,
			1230.042,
			1232.602,
			1234.313,
			1237.568,
			1238.693,
			1239.849,
			1244.27,
			1247.291,
			1251.359,
			1253.692,
			1255.795,
			1258.653,
			1259.602,
			1260.569,
			1263.481,
			1265.409,
			1268.247,
			1270.105,
			1271.778,
			1272.611,
			1274.276,
			1275.795,
			1276.51,
			1278.649,
			1280.057,
			1281.325,
			1283.231,
			1284.505,
			1286.34,
			1286.945,
			1287.552,
			1289.377,
			1290.596,
			1292.425,
			1293.651,
			1294.885,
			1295.514,
			1296.777,
			1297.412,
			1298.048,
			1299.973,
			1300.631,
			1301.954,
			1303.288,
			1304.634,
			1306.004,
			1306.695,
			1308.087,
			1309.493,
			1310.202,
			1311.633,
			1312.354,
			1313.808,
			1315.277,
			1317.502,
			1319.004,
			1321.285,
			1322.051,
			1322.822,
			1325.155,
			1326.733,
			1329.125,
			1330.74,
			1332.372,
			1333.194,
			1334.846,
			1335.675,
			1337.335,
			1339.002,
			1339.841,
			1340.684,
			1343.237,
			1344.955,
			1346.688,
			1347.559,
			1350.191,
			1351.074,
			1351.959,
			1353.739,
			1354.634,
			1356.429,
			1358.233,
			1360.961,
			1362.79,
			1365.556,
			1366.485,
			1367.418,
			1370.232,
			1371.179,
			1372.113,
			1374.98,
			1376.913,
			1379.836,
			1381.802,
			1382.788,
			1384.763,
			1387.728,
			1389.691,
			1391.64,
			1394.523,
			1395.469,
			1396.43,
			1399.238,
			1400.166,
			1401.093,
			1403.864,
			1405.701,
			1408.446,
			1410.266,
			1412.081,
			1414.79,
			1415.69,
			1416.592,
			1419.286,
			1420.181,
			1421.078,
			1423.768,
			1425.557,
			1427.349,
			1428.245,
			1430.034,
			1431.824,
			1432.719,
			1434.508,
			1435.403,
			1437.199,
			1438.996,
			1441.701,
			1443.511,
			1446.236,
			1447.151,
			1448.066,
			1450.822,
			1452.687,
			1455.501,
			1457.403,
			1459.317,
			1460.278,
			1462.208,
			1463.177,
			1464.147,
			1467.064,
			1468.036,
			1469.009,
			1471.937,
			1473.89,
			1475.849,
			1476.83,
			1478.794,
			1480.762,
			1481.75,
			1483.727,
			1484.716,
			1486.697,
			1488.682,
			1491.657,
			1493.638,
			1496.602,
			1497.588,
			1498.572,
			1501.514,
			1503.465,
			1506.378,
			1508.315,
			1510.246,
			1511.209,
			1513.136,
			1514.097,
			1516.013,
			1517.933,
			1518.889,
			1520.798,
			1522.718,
			1524.632,
			1526.545,
			1527.512,
			1529.441,
			1531.37,
			1532.345,
			1534.295,
			1535.271,
			1537.225,
			1539.183,
			1542.115,
			1544.057,
			1546.961,
			1547.919,
			1548.876,
			1551.748,
			1553.654,
			1556.516,
			1558.426,
			1560.338,
			1561.296,
			1563.212,
			1564.172,
			1566.094,
			1568.98,
			1570.909,
			1572.837,
			1575.729,
			1577.661,
			1580.563,
			1581.532,
			1582.504,
			1585.424,
			1587.377,
			1589.336,
			1592.287,
			1594.266,
			1597.249,
			1598.249,
			1599.25,
			1602.269,
			1604.299,
			1607.358,
			1609.406,
			1611.46,
			1612.485,
			1614.539,
			1615.568,
			1617.626,
			1619.684,
			1620.716,
			1622.778,
			1624.842,
			1627.949,
			1630.025,
			1633.149,
			1634.191,
			1635.235,
			1638.372,
			1640.467,
			1643.604,
			1645.689,
			1647.775,
			1648.818,
			1650.911,
			1651.961,
			1653.007,
			1656.158,
			1657.21,
			1658.26,
			1661.421,
			1663.528,
			1665.637,
			1666.693,
			1668.804,
			1670.916,
			1671.973,
			1674.09,
			1675.149,
			1677.273,
			1679.414,
			1682.64,
			1684.815,
			1688.103,
			1689.211,
			1690.322,
			1693.688,
			1695.94,
			1699.372,
			1701.694,
			1703.982,
			1705.139,
			1707.458,
			1708.62,
			1710.949,
			1713.287,
			1714.459,
			1716.808,
			1719.164,
			1722.713,
			1723.899,
			1725.087,
			1727.471,
			1729.863,
			1731.063,
			1733.469,
			1734.675,
			1737.096,
			1739.526,
			1743.206,
			1745.659,
			1749.351,
			1750.583,
			1751.818,
			1755.513,
			1757.994,
			1761.75,
			1764.226,
			1766.734,
			1767.99,
			1770.53,
			1771.772,
			1774.303,
			1776.843,
			1778.116,
			1780.67,
			1783.231,
			1787.088,
			1788.377,
			1789.668,
			1792.256,
			1794.852,
			1796.154,
			1798.758,
			1800.059,
			1802.667,
			1806.591,
			1809.216,
			1811.847,
			1815.808,
			1817.133,
			1818.459,
			1822.449,
			1825.117,
			1829.13,
			1831.813,
			1834.504,
			1835.853,
			1838.555,
			1841.266,
			1842.625,
			1846.712,
			1849.445,
			1852.184,
			1856.305,
			1859.062,
			1863.209,
			1864.595,
			1865.982,
			1870.155,
			1872.945,
			1877.144,
			1879.952,
			1882.767,
			1887.003,
			1888.419,
			1891.256,
			1895.524,
			1896.95,
			1901.239,
			1904.106,
			1906.98,
			1908.42,
			1911.303,
			1914.194,
			1915.641,
			1918.541,
			1919.993,
			1922.903,
			1925.819,
			1930.204,
			1933.137,
			1937.549,
			1939.024,
			1940.5,
			1944.939,
			1947.907,
			1952.373,
			1955.359,
			1958.352,
			1959.85,
			1962.853,
			1964.357,
			1967.368,
			1970.384,
			1971.894,
			1974.916,
			1977.944,
			1980.979,
			1984.019,
			1985.541,
			1988.589,
			1991.643,
			1993.172,
			1997.765,
			2000.835,
			2003.912,
			2008.54,
			2011.633,
			2016.284,
			2017.837,
			2019.393,
			2024.07,
			2027.196,
			2031.886,
			2034.999,
			2039.62,
			2042.654,
			2044.156,
			2047.131,
			2051.529,
			2054.413,
			2057.257,
			2061.455,
			2062.835,
			2064.207,
			2068.264,
			2069.599,
			2070.925,
			2074.844,
			2077.41,
			2079.938,
			2083.662,
			2086.101,
			2089.693,
			2090.874,
			2092.044,
			2095.502,
			2097.759,
			2101.072,
			2103.232,
			2105.355,
			2106.403,
			2108.473,
			2109.494,
			2111.507,
			2113.481,
			2114.455,
			2116.375,
			2118.257,
			2121.013,
			2122.809,
			2125.44,
			2126.301,
			2127.165,
			2129.649,
			2131.271,
			2133.649,
			2135.181,
			2136.676,
			2138.853,
			2139.564,
			2140.269,
			2142.346,
			2143.029,
			2143.707,
			2145.725,
			2147.049,
			2148.365,
			2149.021,
			2150.333,
			2151.65,
			2152.308,
			2153.626,
			2154.285,
			2155.605,
			2156.958,
			2158.998,
			2160.364,
			2162.52,
			2163.25,
			2163.98,
			2166.209,
			2167.795,
			2170.18,
			2171.799,
			2174.257,
			2175.896,
			2176.692,
			2178.277,
			2179.86,
			2180.652,
			2182.214,
			2183.768,
			2185.319,
			2186.896,
			2187.692,
			2189.283,
			2190.874,
			2191.68,
			2193.292,
			2194.097,
			2195.706,
			2197.283,
			2199.626,
			2201.183,
			2203.478,
			2204.241,
			2205.004,
			2207.315,
			2208.863,
			2211.186,
			2212.767,
			2214.343,
			2215.129,
			2216.698,
			2217.475,
			2219.024,
			2221.338,
			2222.849,
			2224.352,
			2226.596,
			2227.337,
			2228.077,
			2230.286,
			2231.021,
			2231.746,
			2233.903,
			2235.336,
			2237.444,
			2238.827,
			2240.209,
			2242.272,
			2242.956,
			2243.641,
			2245.708,
			2247.091,
			2249.173,
			2250.573,
			2252.693,
			2254.12,
			2255.561,
			2256.286,
			2257.748,
			2258.484,
			2259.968,
			2261.467,
			2263.75,
			2265.289,
			2267.637,
			2268.429,
			2269.225,
			2271.638,
			2273.286,
			2275.783,
			2277.466,
			2279.164,
			2280.016,
			2281.734,
			2282.587,
			2283.458,
			2286.09,
			2286.974,
			2287.86,
			2290.537,
			2292.336,
			2294.145,
			2295.055,
			2296.883,
			2298.72,
			2299.644,
			2301.5,
			2302.433,
			2304.308,
			2306.195,
			2309.045,
			2310.961,
			2313.854,
			2314.824,
			2315.796,
			2318.73,
			2320.7,
			2323.677,
			2325.677,
			2327.69,
			2328.701,
			2330.734,
			2331.755,
			2333.81,
			2336.914,
			2337.956,
			2341.102,
			2344.266,
			2345.321,
			2346.378,
			2348.519,
			2350.639,
			2351.711,
			2354.965,
			2357.135,
			2359.318,
			2362.609,
			2364.814,
			2368.14,
			2369.255,
			2370.373,
			2373.75,
			2376.024,
			2379.474,
			2381.797,
			2384.147,
			2385.329,
			2387.702,
			2388.903,
			2391.311,
			2393.741,
			2394.966,
			2397.419,
			2399.879,
			2403.566,
			2404.788,
			2406.01,
			2409.664,
			2410.878,
			2412.093,
			2415.728,
			2418.152,
			2420.576,
			2421.789,
			2424.212,
			2426.634,
			2430.262,
			2431.471,
			2432.68,
			2436.278,
			2437.459,
			2438.653,
			2442.236,
			2444.645,
			2447.038,
			2448.237,
			2450.636,
			2451.838,
			2454.25,
			2456.67,
			2457.884,
			2460.318,
			2462.762,
			2466.448,
			2468.92,
			2472.629,
			2473.879,
			2475.133,
			2478.909,
			2481.441,
			2484.003,
			2487.803,
			2490.347,
			2491.613,
			2494.13,
			2495.378,
			2497.847,
			2500.278,
			2502.67,
			2506.187,
			2508.483,
			2510.741,
			2511.856,
			2514.057,
			2516.221,
			2517.29,
			2520.44,
			2522.493,
			2524.509,
			2527.462,
			2529.384,
			2532.198,
			2533.118,
			2534.029,
			2536.713,
			2538.459,
			2541.012,
			2542.667,
			2544.288,
			2545.084,
			2546.651,
			2547.42,
			2548.932,
			2550.41,
			2551.135,
			2551.852,
			2553.959,
			2555.316,
			2557.291,
			2558.573,
			2559.82,
			2560.431,
			2561.638,
			2562.234,
			2563.412,
			2564.565,
			2566.255,
			2567.371,
			2569.038,
			2569.591,
			2570.144,
			2571.797,
			2572.898,
			2574.566,
			2575.695,
			2576.823,
			2577.388,
			2578.52,
			2579.091,
			2580.274,
			2581.459,
			2582.053,
			2583.244,
			2584.459,
			2586.341,
			2586.971,
			2587.603,
			2589.526,
			2590.186,
			2590.846,
			2592.171,
			2592.836,
			2594.172,
			2595.539,
			2597.59,
			2598.961,
			2601.048,
			2601.744,
			2602.441,
			2604.54,
			2605.962,
			2608.084,
			2609.503,
			2611.665,
			2613.095,
			2613.81,
			2615.253,
			2617.411,
			2618.842,
			2620.267,
			2622.385,
			2623.788,
			2625.856,
			2626.538,
			2627.219,
			2629.263,
			2630.612,
			2632.619,
			2633.954,
			2635.961,
			2637.292,
			2637.956,
			2639.284,
			2640.619,
			2641.283,
			2641.946,
			2643.927,
			2645.242,
			2647.204,
			2648.514,
			2649.825,
			2650.47,
			2651.761,
			2652.407,
			2653.703,
			2654.996,
			2656.908,
			2658.187,
			2660.089,
			2660.714,
			2661.337,
			2663.182,
			2664.385,
			2666.126,
			2667.251,
			2668.355,
			2668.902,
			2670.001,
			2670.556,
			2671.664,
			2673.332,
			2673.889,
			2675.618,
			2676.822,
			2678.024,
			2678.625,
			2679.828,
			2681.148,
			2681.811,
			2683.132,
			2683.791,
			2685.12,
			2686.542,
			2688.66,
			2690.067,
			2692.245,
			2692.965,
			2693.683,
			2695.803,
			2697.181,
			2699.229,
			2700.554,
			2701.827,
			2702.46,
			2703.722,
			2704.351,
			2705.604,
			2707.466,
			2708.703,
			2709.939,
			2711.806,
			2712.427,
			2713.047,
			2714.906,
			2715.518,
			2716.125,
			2717.333,
			2717.936,
			2719.142,
			2720.347,
			2722.126,
			2723.307,
			2725.078,
			2725.67,
			2726.261,
			2728.029,
			2729.207,
			2730.975,
			2732.149,
			2733.913,
			2735.096,
			2736.299,
			2736.903,
			2738.722,
			2739.944,
			2741.194,
			2743.095,
			2744.374,
			2746.304,
			2746.948,
			2747.594,
			2749.548,
			2750.855,
			2752.79,
			2754.094,
			2755.414,
			2757.357,
			2758.01,
			2758.666,
			2760.656,
			2761.311,
			2761.969,
			2763.962,
			2765.308,
			2766.648,
			2767.321,
			2768.675,
			2770.041,
			2770.729,
			2772.106,
			2772.8,
			2774.198,
			2775.612,
			2777.753,
			2779.199,
			2781.394,
			2782.131,
			2782.872,
			2785.117,
			2786.631,
			2788.924,
			2790.47,
			2792.82,
			2794.404,
			2795.2,
			2796.811,
			2798.432,
			2799.242,
			2800.863,
			2802.518,
			2804.185,
			2805.865,
			2806.718,
			2808.435,
			2810.163,
			2811.032,
			2812.787,
			2813.669,
			2815.441,
			2817.225,
			2819.921,
			2821.729,
			2824.452,
			2825.365,
			2826.281,
			2829.025,
			2830.868,
			2833.645,
			2835.509,
			2837.382,
			2838.322,
			2840.209,
			2841.157,
			2843.065,
			2845.948,
			2847.885,
			2849.833,
			2852.778,
			2853.766,
			2854.756,
			2856.747,
			2858.757,
			2859.766,
			2862.812,
			2864.851,
			2867.917,
			2869.979,
			2872.033,
			2875.176,
			2876.228,
			2877.286,
			2880.506,
			2882.658,
			2885.919,
			2888.112,
			2891.428,
			2893.658,
			2894.779,
			2897.028,
			2900.417,
			2902.722,
			2905.024,
			2908.48,
			2910.833,
			2914.355,
			2915.535,
			2916.717,
			2920.285,
			2922.678,
			2926.292,
			2928.717,
			2931.156,
			2934.837,
			2936.07,
			2937.306,
			2941.03,
			2942.276,
			2943.525,
			2947.284,
			2949.802,
			2952.328,
			2953.595,
			2956.14,
			2958.693,
			2959.975,
			2962.545,
			2963.834,
			2966.422,
			2969.021,
			2972.952,
			2975.586,
			2979.555,
			2980.884,
			2982.214,
			2986.228,
			2988.919,
			2992.987,
			2995.709,
			2998.438,
			2999.803,
			3002.538,
			3003.907,
			3006.664,
			3009.429,
			3010.821,
			3012.214,
			3016.403,
			3019.203,
			3021.989,
			3023.384,
			3026.176,
			3028.973,
			3030.376,
			3033.19,
			3034.599,
			3037.427,
			3040.266,
			3044.531,
			3047.427,
			3051.767,
			3053.222,
			3054.679,
			3059.058,
			3062.006,
			3066.463,
			3069.392,
			3072.336,
			3073.831,
			3076.765,
			3079.668,
			3081.128,
			3084.043,
			3085.502,
			3088.402,
			3091.326,
			3095.637,
			3098.508,
			3102.811,
			3104.244,
			3105.678,
			3108.54,
			3109.973,
			3112.838,
			3115.708,
			3120.016,
			3122.893,
			3127.217,
			3128.66,
			3130.105,
			3134.447,
			3137.349,
			3141.71,
			3144.625,
			3147.548,
			3149.013,
			3151.946,
			3153.416,
			3156.358,
			3160.789,
			3163.75,
			3166.719,
			3171.183,
			3174.165,
			3178.647,
			3180.144,
			3181.642,
			3186.146,
			3189.158,
			3193.692,
			3196.726,
			3199.771,
			3204.354,
			3205.885,
			3207.418,
			3213.573,
			3215.117,
			3219.754,
			3222.852,
			3225.952,
			3227.504,
			3230.608,
			3233.717,
			3235.271,
			3238.383,
			3239.941,
			3243.061,
			3246.188,
			3250.893,
			3254.037,
			3258.766,
			3260.346,
			3261.927,
			3266.675,
			3269.846,
			3274.608,
			3277.786,
			3280.965,
			3282.556,
			3285.741,
			3287.336,
			3288.931,
			3293.725,
			3295.325,
			3296.926,
			3301.736,
			3304.948,
			3309.774,
			3312.996,
			3316.223,
			3317.839,
			3321.073,
			3322.691,
			3325.931,
			3329.176,
			3330.8,
			3334.05,
			3337.305,
			3342.194,
			3343.826,
			3345.459,
			3350.364,
			3353.64,
			3358.563,
			3361.85,
			3366.793,
			3370.094,
			3371.747,
			3375.053,
			3378.363,
			3380.019,
			3383.333,
			3386.65,
			3391.63,
			3393.291,
			3394.953,
			3398.279,
			3401.606,
			3403.272,
			3405.684
		],
		[
			0,
			0.046,
			0.091,
			0.169,
			0.2,
			0.232,
			0.309,
			0.354,
			0.418,
			0.479,
			0.526,
			0.541,
			0.602,
			0.633,
			0.666,
			0.727,
			0.742,
			0.789,
			0.852,
			0.911,
			0.96,
			0.977,
			1.039,
			1.069,
			1.099,
			1.161,
			1.177,
			1.225,
			1.286,
			1.349,
			1.395,
			1.471,
			1.501,
			1.533,
			1.612,
			1.659,
			1.72,
			1.782,
			1.829,
			1.843,
			1.905,
			1.938,
			1.968,
			2.031,
			2.049,
			2.092,
			2.153,
			2.217,
			2.262,
			2.279,
			2.34,
			2.371,
			2.402,
			2.462,
			2.479,
			2.527,
			2.59,
			2.65,
			2.711,
			2.775,
			2.805,
			2.835,
			2.899,
			2.913,
			2.961,
			3.021,
			3.084,
			3.145,
			3.209,
			3.239,
			3.271,
			3.333,
			3.348,
			3.395,
			3.455,
			3.517,
			3.581,
			3.643,
			3.672,
			3.705,
			3.766,
			3.829,
			3.889,
			3.953,
			3.999,
			4.016,
			4.077,
			4.138,
			4.2,
			4.216,
			4.262,
			4.323,
			4.385,
			4.433,
			4.448,
			4.511,
			4.54,
			4.572,
			4.635,
			4.649,
			4.695,
			4.759,
			4.82,
			4.865,
			4.944,
			4.975,
			5.007,
			5.068,
			5.131,
			5.193,
			5.253,
			5.301,
			5.316,
			5.38,
			5.41,
			5.441,
			5.502,
			5.517,
			5.565,
			5.625,
			5.688,
			5.736,
			5.751,
			5.812,
			5.844,
			5.874,
			5.938,
			5.999,
			6.06,
			6.123,
			6.185,
			6.247,
			6.277,
			6.309,
			6.37,
			6.432,
			6.495,
			6.555,
			6.605,
			6.618,
			6.679,
			6.711,
			6.743,
			6.805,
			6.819,
			6.868,
			6.93,
			6.989,
			7.052,
			7.116,
			7.147,
			7.177,
			7.238,
			7.301,
			7.363,
			7.423,
			7.471,
			7.549,
			7.58,
			7.612,
			7.672,
			7.689,
			7.736,
			7.798,
			7.86,
			7.905,
			7.922,
			7.984,
			8.014,
			8.044,
			8.108,
			8.122,
			8.17,
			8.235,
			8.292,
			8.34,
			8.416,
			8.449,
			8.48,
			8.541,
			8.603,
			8.666,
			8.727,
			8.775,
			8.788,
			8.851,
			8.883,
			8.914,
			8.992,
			9.037,
			9.099,
			9.16,
			9.207,
			9.223,
			9.285,
			9.316,
			9.347,
			9.41,
			9.423,
			9.47,
			9.534,
			9.595,
			9.643,
			9.719,
			9.751,
			9.782,
			9.844,
			9.906,
			9.967,
			10.029,
			10.09,
			10.152,
			10.184,
			10.216,
			10.278,
			10.298,
			10.338,
			10.401,
			10.462,
			10.511,
			10.524,
			10.587,
			10.619,
			10.651,
			10.727,
			10.773,
			10.835,
			10.896,
			10.944,
			11.023,
			11.052,
			11.083,
			11.146,
			11.206,
			11.271,
			11.332,
			11.394,
			11.455,
			11.486,
			11.518,
			11.579,
			11.595,
			11.641,
			11.705,
			11.766,
			11.829,
			11.889,
			11.921,
			11.952,
			12.013,
			12.075,
			12.143,
			12.201,
			12.247,
			12.263,
			12.323,
			12.386,
			12.449,
			12.463,
			12.508,
			12.571,
			12.633,
			12.682,
			12.695,
			12.757,
			12.789,
			12.819,
			12.883,
			12.898,
			12.943,
			13.005,
			13.066,
			13.112,
			13.191,
			13.224,
			13.253,
			13.316,
			13.378,
			13.44,
			13.501,
			13.549,
			13.565,
			13.627,
			13.658,
			13.688,
			13.751,
			13.767,
			13.812,
			13.879,
			13.937,
			13.999,
			14.061,
			14.092,
			14.122,
			14.184,
			14.2,
			14.246,
			14.307,
			14.369,
			14.433,
			14.493,
			14.525,
			14.557,
			14.634,
			14.681,
			14.741,
			14.805,
			14.851,
			14.867,
			14.929,
			14.958,
			14.989,
			15.068,
			15.115,
			15.175,
			15.239,
			15.285,
			15.301,
			15.363,
			15.392,
			15.425,
			15.485,
			15.501,
			15.549,
			15.609,
			15.671,
			15.719,
			15.797,
			15.826,
			15.858,
			15.919,
			15.983,
			16.046,
			16.107,
			16.153,
			16.169,
			16.229,
			16.26,
			16.291,
			16.371,
			16.418,
			16.479,
			16.54,
			16.587,
			16.601,
			16.666,
			16.696,
			16.725,
			16.788,
			16.849,
			16.913,
			16.975,
			17.021,
			17.099,
			17.129,
			17.16,
			17.222,
			17.238,
			17.286,
			17.346,
			17.408,
			17.456,
			17.47,
			17.533,
			17.564,
			17.596,
			17.657,
			17.672,
			17.717,
			17.781,
			17.843,
			17.89,
			17.967,
			17.996,
			18.029,
			18.091,
			18.151,
			18.216,
			18.277,
			18.339,
			18.401,
			18.433,
			18.462,
			18.526,
			18.545,
			18.588,
			18.65,
			18.71,
			18.758,
			18.771,
			18.835,
			18.867,
			18.896,
			18.958,
			18.974,
			19.019,
			19.083,
			19.144,
			19.206,
			19.269,
			19.299,
			19.329,
			19.392,
			19.456,
			19.517,
			19.578,
			19.625,
			19.64,
			19.702,
			19.733,
			19.766,
			19.843,
			19.89,
			19.952,
			20.012,
			20.058,
			20.073,
			20.137,
			20.167,
			20.199,
			20.276,
			20.323,
			20.385,
			20.446,
			20.493,
			20.57,
			20.607,
			20.636,
			20.696,
			20.757,
			20.82,
			20.882,
			20.943,
			21.006,
			21.035,
			21.067,
			21.129,
			21.144,
			21.192,
			21.255,
			21.316,
			21.361,
			21.377,
			21.44,
			21.47,
			21.501,
			21.561,
			21.579,
			21.625,
			21.687,
			21.749,
			21.797,
			21.873,
			21.904,
			21.934,
			21.997,
			22.013,
			22.058,
			22.127,
			22.185,
			22.231,
			22.245,
			22.307,
			22.339,
			22.371,
			22.432,
			22.448,
			22.495,
			22.557,
			22.619,
			22.679,
			22.74,
			22.773,
			22.803,
			22.866,
			22.928,
			22.991,
			23.05,
			23.099,
			23.114,
			23.176,
			23.207,
			23.237,
			23.315,
			23.361,
			23.425,
			23.486,
			23.532,
			23.549,
			23.611,
			23.64,
			23.673,
			23.733,
			23.749,
			23.795,
			23.857,
			23.919,
			23.967,
			24.044,
			24.075,
			24.106,
			24.167,
			24.229,
			24.292,
			24.355,
			24.4,
			24.416,
			24.478,
			24.509,
			24.538,
			24.602,
			24.618,
			24.662,
			24.725,
			24.786,
			24.834,
			24.85,
			24.911,
			24.943,
			24.973,
			25.053,
			25.097,
			25.159,
			25.222,
			25.284,
			25.345,
			25.378,
			25.408,
			25.471,
			25.533,
			25.599,
			25.655,
			25.702,
			25.718,
			25.779,
			25.812,
			25.841,
			25.92,
			25.966,
			26.028,
			26.089,
			26.136,
			26.151,
			26.215,
			26.245,
			26.276,
			26.337,
			26.354,
			26.399,
			26.461,
			26.523,
			26.571,
			26.649,
			26.679,
			26.711,
			26.773,
			26.792,
			26.835,
			26.897,
			26.959,
			27.005,
			27.021,
			27.083,
			27.114,
			27.145,
			27.206,
			27.222,
			27.267,
			27.331,
			27.391,
			27.454,
			27.517,
			27.548,
			27.58,
			27.641,
			27.701,
			27.765,
			27.825,
			27.872,
			27.888,
			27.949,
			27.982,
			28.012,
			28.091,
			28.137,
			28.2,
			28.26,
			28.307,
			28.324,
			28.386,
			28.416,
			28.446,
			28.507,
			28.524,
			28.571,
			28.635,
			28.693,
			28.742,
			28.819,
			28.856,
			28.882,
			28.943,
			29.004,
			29.068,
			29.129,
			29.176,
			29.19,
			29.252,
			29.282,
			29.315,
			29.376,
			29.392,
			29.439,
			29.501,
			29.562,
			29.626,
			29.685,
			29.717,
			29.75,
			29.811,
			29.825,
			29.871,
			29.936,
			29.996,
			30.059,
			30.119,
			30.152,
			30.183,
			30.245,
			30.306,
			30.37,
			30.432,
			30.492,
			30.554,
			30.586,
			30.618,
			30.694,
			30.742,
			30.803,
			30.865,
			30.926,
			30.989,
			31.019,
			31.05,
			31.129,
			31.175,
			31.238,
			31.299,
			31.347,
			31.424,
			31.453,
			31.486,
			31.547,
			31.609,
			31.671,
			31.732,
			31.78,
			31.794,
			31.856,
			31.888,
			31.919,
			31.997,
			32.044,
			32.105,
			32.167,
			32.214,
			32.291,
			32.322,
			32.352,
			32.416,
			32.477,
			32.541,
			32.602,
			32.646,
			32.662,
			32.725,
			32.756,
			32.786,
			32.85,
			32.865,
			32.912,
			32.979,
			33.035,
			33.079,
			33.096,
			33.159,
			33.191,
			33.22,
			33.283,
			33.297,
			33.347,
			33.408,
			33.469,
			33.517,
			33.593,
			33.624,
			33.655,
			33.717,
			33.779,
			33.843,
			33.905,
			33.951,
			33.967,
			34.028,
			34.057,
			34.09,
			34.152,
			34.168,
			34.212,
			34.276,
			34.337,
			34.385,
			34.4,
			34.463,
			34.493,
			34.523,
			34.585,
			34.602,
			34.647,
			34.711,
			34.772,
			34.817,
			34.897,
			34.928,
			34.957,
			35.019,
			35.04,
			35.082,
			35.144,
			35.207,
			35.269,
			35.329,
			35.362,
			35.391,
			35.47,
			35.516,
			35.577,
			35.638,
			35.685,
			35.702,
			35.765,
			35.796,
			35.827,
			35.888,
			35.95,
			36.016,
			36.075,
			36.12,
			36.198,
			36.229,
			36.261,
			36.323,
			36.338,
			36.385,
			36.446,
			36.509,
			36.553,
			36.569,
			36.632,
			36.664,
			36.695,
			36.756,
			36.771,
			36.819,
			36.881,
			36.941,
			37.004,
			37.067,
			37.102,
			37.129,
			37.189,
			37.252,
			37.314,
			37.377,
			37.423,
			37.438,
			37.5,
			37.531,
			37.561,
			37.625,
			37.638,
			37.687,
			37.752,
			37.81,
			37.855,
			37.871,
			37.934,
			37.966,
			37.996,
			38.057,
			38.072,
			38.119,
			38.183,
			38.243,
			38.289,
			38.368,
			38.4,
			38.431,
			38.508,
			38.553,
			38.617,
			38.679,
			38.726,
			38.739,
			38.802,
			38.833,
			38.863,
			38.925,
			38.942,
			38.989,
			39.052,
			39.111,
			39.162,
			39.175,
			39.235,
			39.268,
			39.299,
			39.359,
			39.376,
			39.422,
			39.485,
			39.547,
			39.608,
			39.671,
			39.701,
			39.734,
			39.794,
			39.858,
			39.919,
			39.979,
			40.027,
			40.043,
			40.104,
			40.137,
			40.166,
			40.243,
			40.291,
			40.352,
			40.413,
			40.462,
			40.538,
			40.569,
			40.602,
			40.662,
			40.723,
			40.787,
			40.849,
			40.896,
			40.973,
			41.005,
			41.033,
			41.095,
			41.157,
			41.221,
			41.284,
			41.329,
			41.343,
			41.407,
			41.437,
			41.469,
			41.532,
			41.544,
			41.592,
			41.654,
			41.717,
			41.778,
			41.839,
			41.872,
			41.903,
			41.965,
			42.026,
			42.089,
			42.151,
			42.199,
			42.213,
			42.274,
			42.306,
			42.337,
			42.4,
			42.414,
			42.46,
			42.522,
			42.585,
			42.63,
			42.646,
			42.709,
			42.74,
			42.771,
			42.834,
			42.848,
			42.896,
			42.956,
			43.02,
			43.067,
			43.143,
			43.174,
			43.205,
			43.285,
			43.329,
			43.39,
			43.454,
			43.5,
			43.516,
			43.578,
			43.609,
			43.638,
			43.702,
			43.717,
			43.763,
			43.824,
			43.888,
			43.933,
			43.95,
			44.011,
			44.042,
			44.074,
			44.135,
			44.151,
			44.196,
			44.258,
			44.321,
			44.368,
			44.444,
			44.476,
			44.508,
			44.568,
			44.632,
			44.692,
			44.754,
			44.801,
			44.817,
			44.881,
			44.91,
			44.941,
			45.002,
			45.018,
			45.066,
			45.126,
			45.19,
			45.235,
			45.25,
			45.312,
			45.346,
			45.376,
			45.437,
			45.454,
			45.5,
			45.563,
			45.624,
			45.67,
			45.749,
			45.78,
			45.809,
			45.887,
			45.934,
			45.995,
			46.058,
			46.105,
			46.121,
			46.182,
			46.213,
			46.244,
			46.322,
			46.368,
			46.43,
			46.491,
			46.538,
			46.616,
			46.646,
			46.677,
			46.741,
			46.801,
			46.862,
			46.926,
			46.973,
			47.051,
			47.081,
			47.112,
			47.189,
			47.237,
			47.296,
			47.361,
			47.41,
			47.42,
			47.482,
			47.515,
			47.545,
			47.608,
			47.622,
			47.671,
			47.734,
			47.793,
			47.839,
			47.917,
			47.949,
			47.979,
			48.043,
			48.104,
			48.166,
			48.229,
			48.273,
			48.289,
			48.352,
			48.383,
			48.415,
			48.476,
			48.491,
			48.539,
			48.599,
			48.662,
			48.71,
			48.723,
			48.786,
			48.817,
			48.848,
			48.926,
			48.973,
			49.035,
			49.097,
			49.142,
			49.22,
			49.251,
			49.282,
			49.343,
			49.407,
			49.472,
			49.531,
			49.591,
			49.655,
			49.685,
			49.717,
			49.793,
			49.841,
			49.904,
			49.965,
			50.011,
			50.025,
			50.088,
			50.119,
			50.15,
			50.213,
			50.274,
			50.337,
			50.399,
			50.445,
			50.522,
			50.555,
			50.585,
			50.646,
			50.71,
			50.77,
			50.834,
			50.88,
			50.896,
			50.958,
			50.987,
			51.02,
			51.08,
			51.096,
			51.141,
			51.205,
			51.268,
			51.328,
			51.392,
			51.421,
			51.452,
			51.515,
			51.575,
			51.643,
			51.701,
			51.746,
			51.823,
			51.856,
			51.887,
			51.95,
			51.966,
			52.01,
			52.073,
			52.135,
			52.179,
			52.196,
			52.257,
			52.288,
			52.319,
			52.383,
			52.399,
			52.446,
			52.507,
			52.568,
			52.613,
			52.694,
			52.723,
			52.755,
			52.833,
			52.879,
			52.942,
			53.002,
			53.066,
			53.127,
			53.158,
			53.187,
			53.251,
			53.267,
			53.314,
			53.375,
			53.436,
			53.484,
			53.5,
			53.561,
			53.599,
			53.624,
			53.684,
			53.701,
			53.747,
			53.809,
			53.871,
			53.918,
			53.996,
			54.027,
			54.056,
			54.133,
			54.182,
			54.244,
			54.305,
			54.353,
			54.366,
			54.43,
			54.459,
			54.49,
			54.57,
			54.616,
			54.678,
			54.738,
			54.785,
			54.801,
			54.863,
			54.895,
			54.924,
			54.988,
			55.049,
			55.112,
			55.174,
			55.219,
			55.297,
			55.327,
			55.359,
			55.436,
			55.483,
			55.544,
			55.608,
			55.67,
			55.732,
			55.762,
			55.794,
			55.854,
			55.872,
			55.917,
			55.979,
			56.04,
			56.088,
			56.166,
			56.196,
			56.228,
			56.288,
			56.35,
			56.413,
			56.475,
			56.523,
			56.538,
			56.6,
			56.631,
			56.662,
			56.722,
			56.74,
			56.785,
			56.846,
			56.91,
			56.955,
			56.971,
			57.032,
			57.066,
			57.095,
			57.157,
			57.173,
			57.221,
			57.282,
			57.342,
			57.39,
			57.468,
			57.5,
			57.529,
			57.591,
			57.653,
			57.72,
			57.779,
			57.823,
			57.838,
			57.901,
			57.933,
			57.964,
			58.041,
			58.087,
			58.15,
			58.212,
			58.259,
			58.275,
			58.337,
			58.367,
			58.399,
			58.476,
			58.523,
			58.583,
			58.645,
			58.692,
			58.769,
			58.801,
			58.832,
			58.895,
			58.955,
			59.018,
			59.081,
			59.127,
			59.142,
			59.203,
			59.235,
			59.267,
			59.329,
			59.343,
			59.389,
			59.455,
			59.515,
			59.56,
			59.576,
			59.638,
			59.669,
			59.699,
			59.763,
			59.825,
			59.886,
			59.889,
			59.946,
			59.994,
			60.073,
			60.102,
			60.134,
			60.196,
			60.213,
			60.258,
			60.32,
			60.382,
			60.428,
			60.443,
			60.507,
			60.537,
			60.569,
			60.631,
			60.645,
			60.693,
			60.755,
			60.816,
			60.879,
			60.939,
			60.972,
			61.001,
			61.065,
			61.127,
			61.187,
			61.25,
			61.296,
			61.312,
			61.373,
			61.405,
			61.438,
			61.512,
			61.559,
			61.623,
			61.683,
			61.732,
			61.748,
			61.807,
			61.845,
			61.87,
			61.946,
			61.995,
			62.058,
			62.118,
			62.166,
			62.242,
			62.275,
			62.304,
			62.368,
			62.43,
			62.49,
			62.552,
			62.599,
			62.615,
			62.677,
			62.708,
			62.738,
			62.801,
			62.817,
			62.861,
			62.924,
			62.985,
			63.049,
			63.111,
			63.143,
			63.172,
			63.235,
			63.251,
			63.296,
			63.358,
			63.422,
			63.467,
			63.545,
			63.575,
			63.608,
			63.684,
			63.731,
			63.792,
			63.856,
			63.905,
			63.917,
			63.979,
			64.008,
			64.04,
			64.102,
			64.119,
			64.163,
			64.225,
			64.287,
			64.335,
			64.351,
			64.412,
			64.444,
			64.474,
			64.537,
			64.552,
			64.599,
			64.659,
			64.723,
			64.768,
			64.846,
			64.876,
			64.907,
			64.987,
			65.033,
			65.096,
			65.158,
			65.219,
			65.282,
			65.311,
			65.344,
			65.419,
			65.466,
			65.529,
			65.592,
			65.638,
			65.716,
			65.745,
			65.777,
			65.839,
			65.902,
			65.968,
			66.024,
			66.088,
			66.149,
			66.181,
			66.212,
			66.272,
			66.288,
			66.334,
			66.399,
			66.46,
			66.521,
			66.584,
			66.615,
			66.644,
			66.707,
			66.723,
			66.769,
			66.832,
			66.895,
			66.941,
			67.016,
			67.048,
			67.079,
			67.14,
			67.202,
			67.266,
			67.328,
			67.373,
			67.388,
			67.452,
			67.483,
			67.514,
			67.591,
			67.636,
			67.703,
			67.762,
			67.807,
			67.823,
			67.887,
			67.917,
			67.948,
			68.008,
			68.03,
			68.072,
			68.134,
			68.196,
			68.243,
			68.32,
			68.35,
			68.38,
			68.458,
			68.505,
			68.573,
			68.629,
			68.675,
			68.692,
			68.752,
			68.783,
			68.817,
			68.892,
			68.938,
			69.002,
			69.064,
			69.11,
			69.126,
			69.187,
			69.217,
			69.251,
			69.312,
			69.328,
			69.373,
			69.437,
			69.496,
			69.559,
			69.62,
			69.652,
			69.682,
			69.745,
			69.807,
			69.872,
			69.932,
			69.994,
			70.054,
			70.09,
			70.117,
			70.196,
			70.242,
			70.304,
			70.366,
			70.428,
			70.49,
			70.52,
			70.551,
			70.615,
			70.675,
			70.738,
			70.801,
			70.846,
			70.925,
			70.956,
			70.987,
			71.049,
			71.063,
			71.11,
			71.173,
			71.233,
			71.279,
			71.296,
			71.358,
			71.388,
			71.421,
			71.482,
			71.499,
			71.544,
			71.607,
			71.668,
			71.713,
			71.791,
			71.822,
			71.854,
			71.932,
			71.978,
			72.042,
			72.103,
			72.164,
			72.227,
			72.256,
			72.288,
			72.35,
			72.366,
			72.413,
			72.475,
			72.536,
			72.583,
			72.599,
			72.66,
			72.692,
			72.723,
			72.785,
			72.8,
			72.845,
			72.908,
			72.971,
			73.016,
			73.095,
			73.125,
			73.157,
			73.219,
			73.282,
			73.343,
			73.404,
			73.451,
			73.466,
			73.53,
			73.56,
			73.59,
			73.668,
			73.715,
			73.777,
			73.838,
			73.885,
			73.9,
			73.962,
			73.994,
			74.024,
			74.103,
			74.15,
			74.216,
			74.272,
			74.334,
			74.395,
			74.428,
			74.457,
			74.52,
			74.583,
			74.645,
			74.707,
			74.768,
			74.831,
			74.86,
			74.892,
			74.97,
			75.017,
			75.079,
			75.141,
			75.202,
			75.266,
			75.297,
			75.325,
			75.388,
			75.451,
			75.512,
			75.575,
			75.62,
			75.699,
			75.731,
			75.762,
			75.823,
			75.839,
			75.884,
			75.946,
			76.008,
			76.054,
			76.07,
			76.133,
			76.165,
			76.196,
			76.257,
			76.278,
			76.32,
			76.38,
			76.443,
			76.489,
			76.567,
			76.598,
			76.629,
			76.692,
			76.753,
			76.816,
			76.878,
			76.922,
			76.938,
			77.002,
			77.033,
			77.063,
			77.125,
			77.141,
			77.187,
			77.247,
			77.311,
			77.356,
			77.372,
			77.434,
			77.466,
			77.496,
			77.558,
			77.574,
			77.622,
			77.683,
			77.744,
			77.791,
			77.87,
			77.9,
			77.931,
			77.994,
			78.054,
			78.123,
			78.179,
			78.225,
			78.241,
			78.303,
			78.34,
			78.366,
			78.427,
			78.444,
			78.488,
			78.55,
			78.614,
			78.675,
			78.738,
			78.769,
			78.799,
			78.862,
			78.876,
			78.924,
			78.984,
			79.048,
			79.095,
			79.173,
			79.203,
			79.233,
			79.295,
			79.358,
			79.42,
			79.483,
			79.528,
			79.545,
			79.606,
			79.637,
			79.667,
			79.746,
			79.793,
			79.855,
			79.916,
			79.977,
			80.041,
			80.07,
			80.102,
			80.165,
			80.226,
			80.289,
			80.351,
			80.4,
			80.472,
			80.505,
			80.535,
			80.613,
			80.661,
			80.721,
			80.784,
			80.831,
			80.846,
			80.908,
			80.94,
			80.969,
			81.033,
			81.046,
			81.095,
			81.155,
			81.219,
			81.265,
			81.341,
			81.373,
			81.404,
			81.467,
			81.527,
			81.589,
			81.651,
			81.7,
			81.715,
			81.776,
			81.807,
			81.837,
			81.9,
			81.915,
			81.962,
			82.024,
			82.086,
			82.149,
			82.211,
			82.242,
			82.272,
			82.333,
			82.349,
			82.396,
			82.458,
			82.464,
			82.521,
			82.58,
			82.645,
			82.676,
			82.705,
			82.768,
			82.83,
			82.892,
			82.955,
			83.016,
			83.078,
			83.108,
			83.14,
			83.202,
			83.217,
			83.264,
			83.327,
			83.389,
			83.434,
			83.45,
			83.512,
			83.544,
			83.574,
			83.606
		]
	],
	'6139_6140_7982': [
		[
			44.48
		],
		[
			0
		],
		[
			0,
			0.442,
			1.916,
			4.868,
			7.827,
			9.308,
			12.276,
			13.763,
			16.742,
			19.726,
			24.215,
			27.214,
			31.725,
			33.232,
			34.739,
			39.271,
			42.3,
			46.851,
			49.892,
			52.94,
			54.466,
			57.524,
			59.055,
			62.123,
			66.738,
			69.823,
			72.918,
			76.019,
			79.127,
			80.681,
			83.793,
			86.909,
			88.469,
			91.593,
			93.158,
			96.292,
			99.434,
			104.158,
			107.315,
			112.059,
			113.639,
			115.216,
			119.918,
			123.016,
			127.594,
			130.597,
			133.561,
			135.028,
			137.933,
			139.371,
			142.219,
			146.418,
			149.172,
			151.888,
			155.892,
			157.208,
			158.514,
			162.376,
			163.645,
			164.905,
			168.628,
			171.064,
			174.653,
			177.004,
			180.464,
			182.721,
			183.834,
			184.938,
			189.259,
			190.316,
			193.432,
			195.464,
			198.442,
			200.382,
			202.285,
			203.224,
			205.986,
			207.788,
			209.562,
			212.159,
			213.841,
			216.295,
			217.094,
			217.88,
			220.18,
			221.668,
			223.836,
			225.237,
			226.604,
			227.275,
			228.593,
			229.24,
			230.517,
			231.752,
			232.357,
			232.956,
			234.709,
			235.845,
			237.508,
			238.596,
			239.665,
			240.194,
			241.246,
			241.769,
			242.816,
			243.893,
			245.499,
			246.567,
			248.195,
			248.757,
			249.319,
			251.002,
			252.127,
			253.898,
			255.114,
			256.934,
			258.151,
			258.762,
			259.974,
			261.176,
			261.775,
			262.973,
			264.106,
			265.242,
			266.381,
			266.951,
			268.094,
			269.239,
			269.814,
			271.543,
			272.696,
			273.851,
			275.573,
			276.706,
			278.434,
			279.009,
			279.583,
			281.305,
			282.456,
			284.186,
			285.334,
			286.479,
			287.052,
			288.2,
			289.383,
			289.972,
			291.739,
			292.915,
			294.208,
			296.187,
			296.846,
			297.505,
			299.653,
			300.398,
			301.142,
			303.381,
			305.044,
			307.522,
			309.017,
			310.375,
			312.408,
			313.086,
			313.692,
			315.335,
			315.883,
			316.431,
			318.082,
			319.229,
			320.394,
			320.978,
			322.147,
			323.324,
			323.931,
			325.792,
			327.042,
			328.303,
			330.227,
			331.526,
			333.493,
			334.142,
			334.804,
			336.814,
			338.173,
			340.24,
			341.634,
			343.032,
			343.731,
			345.143,
			345.849,
			346.554,
			348.662,
			349.382,
			350.103,
			352.255,
			353.681,
			355.123,
			355.845,
			357.283,
			358.713,
			359.427,
			361.562,
			362.969,
			364.366,
			366.459,
			367.842,
			369.915,
			370.61,
			371.302,
			373.368,
			374.73,
			376.734,
			378.062,
			379.388,
			380.042,
			381.341,
			381.99,
			383.29,
			384.595,
			385.239,
			386.526,
			387.815,
			389.755,
			391.043,
			392.335,
			393.632,
			394.283,
			395.587,
			396.241,
			397.556,
			399.547,
			400.89,
			402.243,
			404.297,
			404.989,
			405.685,
			407.795,
			409.222,
			411.395,
			412.865,
			414.351,
			415.099,
			416.606,
			417.366,
			418.896,
			420.441,
			421.219,
			422.786,
			424.354,
			426.754,
			427.561,
			428.372,
			430.826,
			431.652,
			432.482,
			435.003,
			436.689,
			438.376,
			440.908,
			442.617,
			445.198,
			446.063,
			446.937,
			449.568,
			450.449,
			451.335,
			454.024,
			455.826,
			458.563,
			460.398,
			462.248,
			463.18,
			465.045,
			465.978,
			467.864,
			469.759,
			472.602,
			474.519,
			477.369,
			478.337,
			479.301,
			482.162,
			484.052,
			486.846,
			488.653,
			491.295,
			493.022,
			493.863,
			494.7,
			497.999,
			498.81,
			501.218,
			502.807,
			504.4,
			505.194,
			506.773,
			508.345,
			509.13,
			510.694,
			511.476,
			513.039,
			514.584,
			516.899,
			518.445,
			520.76,
			521.533,
			522.307,
			524.633,
			526.186,
			528.515,
			530.071,
			531.627,
			532.406,
			533.964,
			534.75,
			536.323,
			537.894,
			538.682,
			539.471,
			541.846,
			543.435,
			545.028,
			545.823,
			547.417,
			549.017,
			549.821,
			551.431,
			552.238,
			553.858,
			555.49,
			557.951,
			559.604,
			562.103,
			562.941,
			563.782,
			566.317,
			568.017,
			570.596,
			572.33,
			574.076,
			574.956,
			576.729,
			577.619,
			579.411,
			582.131,
			583.963,
			585.811,
			588.611,
			590.496,
			593.339,
			594.299,
			595.261,
			598.175,
			600.135,
			602.1,
			605.067,
			607.045,
			610.018,
			611.011,
			612.003,
			614.971,
			616.946,
			619.904,
			621.889,
			623.858,
			624.84,
			626.803,
			628.769,
			629.75,
			631.709,
			632.692,
			634.653,
			636.606,
			639.531,
			641.476,
			644.402,
			645.375,
			646.346,
			649.304,
			651.27,
			654.251,
			656.232,
			659.201,
			661.171,
			662.151,
			664.092,
			666.026,
			666.993,
			667.962,
			670.866,
			672.798,
			674.728,
			675.694,
			677.623,
			679.537,
			680.494,
			682.411,
			683.365,
			685.272,
			688.143,
			690.067,
			691.999,
			694.925,
			695.908,
			696.894,
			699.86,
			701.849,
			704.827,
			706.816,
			708.807,
			709.8,
			711.779,
			712.78,
			714.784,
			716.798,
			717.809,
			718.817,
			721.87,
			724.929,
			725.954,
			726.982,
			730.089,
			731.131,
			732.172,
			734.261,
			735.292,
			737.397,
			739.536,
			742.747,
			744.917,
			748.209,
			749.314,
			750.424,
			753.74,
			755.986,
			759.338,
			761.588,
			763.847,
			764.983,
			767.263,
			768.407,
			770.685,
			774.108,
			776.363,
			778.646,
			782.098,
			783.253,
			784.411,
			787.885,
			789.047,
			790.207,
			793.683,
			796.024,
			799.515,
			801.835,
			804.182,
			807.721,
			808.905,
			810.091,
			813.665,
			816.058,
			819.665,
			822.08,
			824.503,
			825.718,
			828.155,
			830.6,
			831.826,
			834.284,
			835.516,
			837.985,
			840.463,
			844.195,
			845.443,
			846.693,
			850.454,
			851.711,
			852.97,
			856.756,
			859.287,
			863.096,
			865.645,
			868.201,
			869.483,
			872.052,
			873.34,
			874.629,
			878.508,
			879.805,
			881.104,
			885.012,
			887.617,
			891.534,
			894.153,
			896.777,
			898.111,
			900.714,
			902.026,
			904.651,
			907.271,
			911.169,
			913.735,
			917.514,
			918.755,
			919.987,
			923.626,
			926.006,
			929.533,
			931.827,
			934.087,
			935.205,
			937.417,
			938.511,
			940.681,
			942.828,
			943.895,
			946.023,
			948.143,
			951.327,
			952.39,
			953.454,
			956.658,
			957.729,
			958.802,
			960.953,
			962.031,
			964.195,
			966.371,
			969.652,
			971.863,
			975.199,
			976.326,
			977.454,
			980.855,
			983.14,
			986.595,
			988.919,
			991.253,
			992.424,
			994.771,
			995.948,
			998.309,
			1001.89,
			1004.311,
			1006.74,
			1010.399,
			1011.601,
			1012.794,
			1015.188,
			1017.564,
			1018.748,
			1021.123,
			1022.314,
			1024.7,
			1027.1,
			1030.723,
			1033.155,
			1036.822,
			1038.049,
			1039.278,
			1042.974,
			1044.207,
			1045.443,
			1049.165,
			1051.659,
			1055.411,
			1057.919,
			1059.172,
			1061.684,
			1064.199,
			1065.459,
			1067.984,
			1070.516,
			1074.331,
			1075.607,
			1076.885,
			1080.713,
			1082,
			1083.29,
			1087.172,
			1089.77,
			1093.683,
			1096.299,
			1098.916,
			1102.855,
			1104.17,
			1105.486,
			1109.421,
			1110.724,
			1112.022,
			1115.872,
			1118.417,
			1122.113,
			1124.55,
			1126.967,
			1128.17,
			1130.57,
			1131.771,
			1134.167,
			1136.561,
			1140.142,
			1142.552,
			1146.134,
			1147.331,
			1148.529,
			1152.132,
			1154.545,
			1158.155,
			1160.605,
			1164.25,
			1166.689,
			1167.911,
			1170.362,
			1172.802,
			1174.036,
			1176.51,
			1178.992,
			1182.729,
			1183.977,
			1185.228,
			1187.733,
			1190.242,
			1191.5,
			1194.018,
			1195.28,
			1197.81,
			1200.346,
			1204.164,
			1206.72,
			1210.568,
			1211.854,
			1213.143,
			1217.02,
			1219.615,
			1223.519,
			1226.122,
			1228.731,
			1230.039,
			1232.66,
			1233.974,
			1236.605,
			1239.245,
			1240.568,
			1243.219,
			1245.879,
			1249.882,
			1251.22,
			1252.56,
			1256.617,
			1257.966,
			1259.317,
			1262.003,
			1263.36,
			1266.081,
			1268.81,
			1272.916,
			1275.66,
			1279.79,
			1281.169,
			1282.551,
			1288.094,
			1289.483,
			1293.664,
			1296.459,
			1300.666,
			1303.479,
			1304.888,
			1307.71,
			1310.531,
			1311.937,
			1314.738,
			1317.513,
			1321.613,
			1324.298,
			1328.253,
			1329.553,
			1330.844,
			1334.662,
			1337.162,
			1340.865,
			1343.276,
			1345.655,
			1346.833,
			1349.167,
			1350.321,
			1351.466,
			1355.96,
			1357.061,
			1360.312,
			1362.439,
			1364.537,
			1365.578,
			1367.648,
			1369.709,
			1370.739,
			1372.809,
			1373.848,
			1375.926,
			1378.035,
			1381.204,
			1383.344,
			1386.548,
			1387.619,
			1388.687,
			1391.865,
			1393.956,
			1397.056,
			1399.093,
			1401.117,
			1402.126,
			1404.163,
			1405.178,
			1407.204,
			1409.264,
			1410.291,
			1412.34,
			1414.395,
			1416.443,
			1419.516,
			1421.56,
			1423.595,
			1424.608,
			1426.634,
			1427.648,
			1429.642,
			1431.634,
			1434.606,
			1436.576,
			1439.526,
			1440.506,
			1441.484,
			1444.418,
			1446.367,
			1449.286,
			1451.233,
			1454.13,
			1456.04,
			1456.99,
			1458.885,
			1460.768,
			1461.706,
			1463.577,
			1465.44,
			1468.217,
			1470.057,
			1471.881,
			1473.699,
			1474.607,
			1476.41,
			1477.309,
			1479.1,
			1480.88,
			1483.525,
			1485.277,
			1487.889,
			1488.755,
			1489.618,
			1492.198,
			1493.908,
			1496.463,
			1498.16,
			1499.85,
			1500.694,
			1502.38,
			1504.057,
			1504.894,
			1506.59,
			1507.427,
			1509.127,
			1510.819,
			1513.351,
			1515.025,
			1517.541,
			1518.387,
			1519.233,
			1521.779,
			1523.485,
			1525.205,
			1527.808,
			1529.558,
			1530.438,
			1532.207,
			1533.095,
			1534.882,
			1536.68,
			1537.582,
			1538.486,
			1541.22,
			1543.062,
			1545.848,
			1547.725,
			1549.615,
			1550.565,
			1553.438,
			1555.37,
			1557.314,
			1560.248,
			1562.219,
			1565.196,
			1566.194,
			1567.195,
			1570.236,
			1572.268,
			1575.321,
			1577.381,
			1579.469,
			1580.508,
			1582.592,
			1583.635,
			1585.718,
			1587.801,
			1588.844,
			1589.889,
			1593.04,
			1595.155,
			1597.281,
			1598.349,
			1600.496,
			1602.654,
			1603.739,
			1607.013,
			1609.213,
			1611.427,
			1614.771,
			1617.016,
			1620.405,
			1622.677,
			1626.117,
			1628.429,
			1631.944,
			1634.305,
			1637.892,
			1640.287,
			1641.482,
			1643.88,
			1646.267,
			1647.461,
			1649.852,
			1652.231,
			1654.6,
			1656.989,
			1658.187,
			1660.588,
			1662.999,
			1664.208,
			1667.851,
			1670.291,
			1672.741,
			1676.433,
			1678.903,
			1682.625,
			1683.869,
			1685.116,
			1688.868,
			1690.123,
			1691.381,
			1695.167,
			1697.702,
			1700.245,
			1701.52,
			1704.077,
			1705.358,
			1707.926,
			1710.502,
			1711.793,
			1714.381,
			1716.977,
			1720.881,
			1723.484,
			1727.401,
			1728.711,
			1730.022,
			1733.968,
			1736.608,
			1740.581,
			1743.239,
			1745.903,
			1749.914,
			1751.254,
			1752.596,
			1756.633,
			1759.333,
			1763.398,
			1766.116,
			1770.207,
			1772.943,
			1775.686,
			1777.06,
			1781.193,
			1783.956,
			1786.727,
			1790.897,
			1793.686,
			1797.883,
			1799.285,
			1800.689,
			1804.913,
			1807.737,
			1811.989,
			1814.832,
			1817.683,
			1819.112,
			1821.975,
			1823.409,
			1824.845,
			1829.161,
			1830.604,
			1833.492,
			1836.386,
			1839.287,
			1842.195,
			1843.651,
			1846.567,
			1849.491,
			1850.956,
			1853.718
		],
		[
			0,
			0.016,
			0.033,
			0.094,
			0.126,
			0.156,
			0.217,
			0.234,
			0.281,
			0.343,
			0.404,
			0.452,
			0.527,
			0.56,
			0.591,
			0.652,
			0.715,
			0.777,
			0.838,
			0.888,
			0.899,
			0.961,
			0.993,
			1.025,
			1.101,
			1.148,
			1.209,
			1.271,
			1.318,
			1.335,
			1.396,
			1.427,
			1.458,
			1.52,
			1.534,
			1.582,
			1.645,
			1.704,
			1.767,
			1.83,
			1.862,
			1.892,
			1.969,
			2.015,
			2.079,
			2.139,
			2.187,
			2.203,
			2.265,
			2.295,
			2.326,
			2.404,
			2.449,
			2.514,
			2.574,
			2.621,
			2.638,
			2.699,
			2.73,
			2.761,
			2.837,
			2.885,
			2.953,
			3.009,
			3.072,
			3.131,
			3.162,
			3.194,
			3.273,
			3.318,
			3.382,
			3.444,
			3.504,
			3.566,
			3.599,
			3.628,
			3.704,
			3.753,
			3.815,
			3.876,
			3.939,
			3.999,
			4.031,
			4.062,
			4.123,
			4.188,
			4.249,
			4.31,
			4.355,
			4.372,
			4.434,
			4.467,
			4.498,
			4.559,
			4.573,
			4.62,
			4.683,
			4.744,
			4.805,
			4.87,
			4.9,
			4.93,
			4.994,
			5.012,
			5.055,
			5.118,
			5.178,
			5.241,
			5.304,
			5.333,
			5.365,
			5.427,
			5.489,
			5.551,
			5.612,
			5.676,
			5.738,
			5.769,
			5.8,
			5.862,
			5.877,
			5.922,
			5.984,
			6.046,
			6.093,
			6.11,
			6.171,
			6.203,
			6.234,
			6.31,
			6.355,
			6.421,
			6.482,
			6.528,
			6.604,
			6.635,
			6.666,
			6.729,
			6.79,
			6.854,
			6.914,
			6.962,
			6.976,
			7.039,
			7.074,
			7.101,
			7.179,
			7.226,
			7.287,
			7.349,
			7.395,
			7.412,
			7.475,
			7.505,
			7.535,
			7.598,
			7.66,
			7.722,
			7.782,
			7.829,
			7.908,
			7.938,
			7.968,
			8.032,
			8.047,
			8.095,
			8.16,
			8.217,
			8.263,
			8.279,
			8.34,
			8.372,
			8.404,
			8.482,
			8.528,
			8.59,
			8.651,
			8.699,
			8.776,
			8.806,
			8.839,
			8.899,
			8.962,
			9.024,
			9.086,
			9.137,
			9.147,
			9.21,
			9.242,
			9.271,
			9.333,
			9.349,
			9.396,
			9.458,
			9.519,
			9.566,
			9.58,
			9.645,
			9.676,
			9.704,
			9.783,
			9.83,
			9.893,
			9.954,
			9.999,
			10.078,
			10.108,
			10.141,
			10.201,
			10.262,
			10.326,
			10.386,
			10.435,
			10.451,
			10.513,
			10.542,
			10.575,
			10.636,
			10.652,
			10.698,
			10.76,
			10.822,
			10.884,
			10.946,
			10.976,
			11.008,
			11.069,
			11.087,
			11.132,
			11.198,
			11.255,
			11.318,
			11.38,
			11.412,
			11.443,
			11.503,
			11.565,
			11.629,
			11.691,
			11.738,
			11.751,
			11.814,
			11.845,
			11.876,
			11.937,
			11.954,
			12.001,
			12.062,
			12.124,
			12.17,
			12.185,
			12.249,
			12.28,
			12.309,
			12.388,
			12.434,
			12.496,
			12.559,
			12.62,
			12.681,
			12.713,
			12.745,
			12.804,
			12.823,
			12.869,
			12.929,
			12.993,
			13.055,
			13.115,
			13.149,
			13.178,
			13.24,
			13.26,
			13.302,
			13.365,
			13.427,
			13.489,
			13.55,
			13.58,
			13.613,
			13.675,
			13.737,
			13.8,
			13.86,
			13.922,
			13.984,
			14.017,
			14.045,
			14.123,
			14.17,
			14.232,
			14.295,
			14.341,
			14.357,
			14.42,
			14.449,
			14.48,
			14.542,
			14.559,
			14.605,
			14.665,
			14.727,
			14.776,
			14.852,
			14.883,
			14.914,
			14.975,
			15.038,
			15.101,
			15.162,
			15.209,
			15.225,
			15.287,
			15.321,
			15.349,
			15.411,
			15.426,
			15.471,
			15.534,
			15.596,
			15.644,
			15.658,
			15.721,
			15.751,
			15.783,
			15.846,
			15.861,
			15.907,
			15.971,
			16.031,
			16.077,
			16.154,
			16.186,
			16.216,
			16.295,
			16.34,
			16.402,
			16.465,
			16.512,
			16.527,
			16.589,
			16.621,
			16.652,
			16.73,
			16.776,
			16.839,
			16.899,
			16.962,
			17.024,
			17.055,
			17.084,
			17.148,
			17.21,
			17.271,
			17.333,
			17.395,
			17.458,
			17.489,
			17.519,
			17.58,
			17.643,
			17.709,
			17.768,
			17.813,
			17.83,
			17.892,
			17.923,
			17.953,
			18.015,
			18.03,
			18.078,
			18.139,
			18.2,
			18.263,
			18.325,
			18.356,
			18.386,
			18.449,
			18.51,
			18.574,
			18.634,
			18.697,
			18.761,
			18.789,
			18.822,
			18.883,
			18.899,
			18.946,
			19.009,
			19.068,
			19.116,
			19.13,
			19.195,
			19.225,
			19.255,
			19.318,
			19.334,
			19.381,
			19.448,
			19.503,
			19.551,
			19.627,
			19.66,
			19.691,
			19.752,
			19.815,
			19.877,
			19.939,
			19.984,
			20,
			20.062,
			20.093,
			20.122,
			20.184,
			20.202,
			20.246,
			20.31,
			20.372,
			20.418,
			20.433,
			20.497,
			20.526,
			20.558,
			20.619,
			20.634,
			20.681,
			20.745,
			20.805,
			20.853,
			20.93,
			20.96,
			20.99,
			21.053,
			21.116,
			21.177,
			21.239,
			21.286,
			21.302,
			21.365,
			21.396,
			21.426,
			21.508,
			21.551,
			21.613,
			21.675,
			21.719,
			21.737,
			21.798,
			21.829,
			21.86,
			21.922,
			21.985,
			22.049,
			22.108,
			22.155,
			22.232,
			22.264,
			22.295,
			22.37,
			22.419,
			22.48,
			22.543,
			22.59,
			22.604,
			22.665,
			22.697,
			22.729,
			22.791,
			22.804,
			22.851,
			22.914,
			22.977,
			23.021,
			23.037,
			23.101,
			23.132,
			23.162,
			23.239,
			23.285,
			23.349,
			23.41,
			23.455,
			23.472,
			23.534,
			23.568,
			23.595,
			23.656,
			23.674,
			23.721,
			23.781,
			23.844,
			23.906,
			23.969,
			23.999,
			24.03,
			24.093,
			24.112,
			24.155,
			24.215,
			24.277,
			24.339,
			24.401,
			24.432,
			24.463,
			24.542,
			24.589,
			24.65,
			24.713,
			24.76,
			24.773,
			24.837,
			24.869,
			24.898,
			24.961,
			24.977,
			25.023,
			25.084,
			25.147,
			25.193,
			25.209,
			25.272,
			25.303,
			25.333,
			25.394,
			25.411,
			25.455,
			25.518,
			25.582,
			25.632,
			25.705,
			25.734,
			25.768,
			25.827,
			25.889,
			25.953,
			26.015,
			26.062,
			26.076,
			26.137,
			26.169,
			26.201,
			26.277,
			26.325,
			26.386,
			26.449,
			26.496,
			26.511,
			26.571,
			26.605,
			26.636,
			26.695,
			26.712,
			26.759,
			26.821,
			26.883,
			26.929,
			27.006,
			27.038,
			27.068,
			27.13,
			27.147,
			27.194,
			27.255,
			27.317,
			27.379,
			27.442,
			27.47,
			27.501,
			27.566,
			27.579,
			27.628,
			27.693,
			27.751,
			27.796,
			27.812,
			27.875,
			27.905,
			27.936,
			27.999,
			28.062,
			28.127,
			28.184,
			28.232,
			28.308,
			28.338,
			28.371,
			28.434,
			28.449,
			28.495,
			28.558,
			28.618,
			28.682,
			28.743,
			28.773,
			28.804,
			28.866,
			28.883,
			28.929,
			28.991,
			29.054,
			29.101,
			29.179,
			29.209,
			29.24,
			29.302,
			29.362,
			29.426,
			29.487,
			29.549,
			29.61,
			29.642,
			29.672,
			29.736,
			29.755,
			29.798,
			29.859,
			29.922,
			29.968,
			29.982,
			30.046,
			30.077,
			30.106,
			30.17,
			30.185,
			30.232,
			30.294,
			30.354,
			30.401,
			30.479,
			30.51,
			30.542,
			30.618,
			30.665,
			30.727,
			30.789,
			30.837,
			30.852,
			30.914,
			30.944,
			30.975,
			31.038,
			31.052,
			31.1,
			31.161,
			31.224,
			31.269,
			31.285,
			31.348,
			31.379,
			31.41,
			31.471,
			31.488,
			31.534,
			31.596,
			31.659,
			31.703,
			31.78,
			31.815,
			31.844,
			31.922,
			31.969,
			32.031,
			32.09,
			32.154,
			32.216,
			32.246,
			32.278,
			32.339,
			32.356,
			32.401,
			32.464,
			32.526,
			32.572,
			32.649,
			32.68,
			32.712,
			32.774,
			32.834,
			32.898,
			32.96,
			33.004,
			33.022,
			33.083,
			33.115,
			33.146,
			33.222,
			33.269,
			33.331,
			33.393,
			33.441,
			33.454,
			33.518,
			33.55,
			33.581,
			33.642,
			33.656,
			33.705,
			33.766,
			33.829,
			33.891,
			33.951,
			33.983,
			34.013,
			34.077,
			34.138,
			34.202,
			34.263,
			34.309,
			34.323,
			34.386,
			34.416,
			34.447,
			34.509,
			34.525,
			34.573,
			34.635,
			34.695,
			34.759,
			34.821,
			34.85,
			34.881,
			34.943,
			34.96,
			35.005,
			35.07,
			35.131,
			35.176,
			35.253,
			35.285,
			35.315,
			35.378,
			35.441,
			35.504,
			35.565,
			35.626,
			35.688,
			35.719,
			35.75,
			35.812,
			35.828,
			35.875,
			35.941,
			35.997,
			36.06,
			36.121,
			36.154,
			36.185,
			36.245,
			36.263,
			36.31,
			36.371,
			36.432,
			36.478,
			36.558,
			36.587,
			36.618,
			36.695,
			36.743,
			36.805,
			36.865,
			36.912,
			36.929,
			36.989,
			37.023,
			37.052,
			37.115,
			37.129,
			37.176,
			37.239,
			37.302,
			37.347,
			37.425,
			37.454,
			37.487,
			37.55,
			37.61,
			37.671,
			37.734,
			37.78,
			37.798,
			37.86,
			37.89,
			37.922,
			37.983,
			38.003,
			38.045,
			38.106,
			38.168,
			38.231,
			38.294,
			38.322,
			38.356,
			38.431,
			38.478,
			38.542,
			38.603,
			38.65,
			38.727,
			38.758,
			38.789,
			38.852,
			38.912,
			38.976,
			39.038,
			39.084,
			39.098,
			39.162,
			39.193,
			39.222,
			39.285,
			39.302,
			39.346,
			39.409,
			39.471,
			39.517,
			39.533,
			39.595,
			39.627,
			39.657,
			39.734,
			39.782,
			39.843,
			39.904,
			39.967,
			40.029,
			40.091,
			40.169,
			40.216,
			40.278,
			40.34,
			40.402,
			40.462,
			40.493,
			40.525,
			40.587,
			40.601,
			40.65,
			40.711,
			40.771,
			40.819,
			40.835,
			40.896,
			40.928,
			40.959,
			41.022,
			41.083,
			41.144,
			41.207,
			41.255,
			41.33,
			41.362,
			41.394,
			41.455,
			41.472,
			41.516,
			41.579,
			41.642,
			41.689,
			41.702,
			41.765,
			41.796,
			41.828,
			41.888,
			41.905,
			41.95,
			42.014,
			42.075,
			42.136,
			42.199,
			42.231,
			42.262,
			42.339,
			42.386,
			42.449,
			42.508,
			42.554,
			42.633,
			42.664,
			42.696,
			42.771,
			42.819,
			42.883,
			42.943,
			43.005,
			43.068,
			43.099,
			43.129,
			43.208,
			43.254,
			43.319,
			43.378,
			43.425,
			43.501,
			43.531,
			43.564,
			43.642,
			43.687,
			43.75,
			43.81,
			43.858,
			43.874,
			43.934,
			43.965,
			43.996,
			44.061,
			44.077,
			44.122,
			44.185,
			44.244,
			44.293,
			44.309,
			44.371,
			44.399,
			44.431,
			44.48
		]
	],
	'5924_5925_7982': [
		[
			65.035
		],
		[
			0
		],
		[
			0,
			3.091,
			6.012,
			7.475,
			10.416,
			11.89,
			14.845,
			17.809,
			22.246,
			25.212,
			29.67,
			31.159,
			32.649,
			37.129,
			40.125,
			44.632,
			47.644,
			50.662,
			52.173,
			55.2,
			56.715,
			58.232,
			64.314,
			65.837,
			70.417,
			73.478,
			78.078,
			81.152,
			84.231,
			85.772,
			88.859,
			90.404,
			93.497,
			96.594,
			101.251,
			104.363,
			109.043,
			110.606,
			112.169,
			116.865,
			120.002,
			124.716,
			127.866,
			132.6,
			135.763,
			137.346,
			140.516,
			145.28,
			146.871,
			151.648,
			154.834,
			158.024,
			159.62,
			162.816,
			166.016,
			167.618,
			170.826,
			172.431,
			175.644,
			178.861,
			183.696,
			186.925,
			191.777,
			193.397,
			195.016,
			199.88,
			203.128,
			207.998,
			211.233,
			214.447,
			216.044,
			219.211,
			220.781,
			223.892,
			228.487,
			231.504,
			234.486,
			238.886,
			241.771,
			246.027,
			247.426,
			248.817,
			252.932,
			255.628,
			258.287,
			262.203,
			264.766,
			268.541,
			269.78,
			271.01,
			274.645,
			277.023,
			280.525,
			282.817,
			285.07,
			286.183,
			288.381,
			289.465,
			291.605,
			293.707,
			294.744,
			296.79,
			298.8,
			301.746,
			303.665,
			306.475,
			307.393,
			308.303,
			310.984,
			312.729,
			315.28,
			316.935,
			318.553,
			320.911,
			321.679,
			322.438,
			324.661,
			325.384,
			326.098,
			328.187,
			329.538,
			331.506,
			332.777,
			334.012,
			334.617,
			336.382,
			337.514,
			338.619,
			340.202,
			341.216,
			342.681,
			343.156,
			343.625,
			344.997,
			345.883,
			347.182,
			348.034,
			348.881,
			349.303,
			350.126,
			350.532,
			351.344,
			352.155,
			352.561,
			352.966,
			354.183,
			355.43,
			355.847,
			356.265,
			357.103,
			357.944,
			358.366,
			359.217,
			359.684,
			360.735,
			361.79,
			363.385,
			364.459,
			366.561,
			367.263,
			367.966,
			370.13,
			371.647,
			373.918,
			375.295,
			376.58,
			377.223,
			378.516,
			379.165,
			380.374,
			381.549,
			382.139,
			383.325,
			384.52,
			385.695,
			386.876,
			387.47,
			389.268,
			389.855,
			390.428,
			391.584,
			392.166,
			393.341,
			394.531,
			396.205,
			397.336,
			399.063,
			399.647,
			400.225,
			401.982,
			403.175,
			405.003,
			406.25,
			407.495,
			408.118,
			409.378,
			410.653,
			411.297,
			413.256,
			414.581,
			415.924,
			417.971,
			419.343,
			421.44,
			422.147,
			422.857,
			424.29,
			425.014,
			426.477,
			428.702,
			430.209,
			431.74,
			434.066,
			434.849,
			435.634,
			438.012,
			439.617,
			442.052,
			443.694,
			445.351,
			446.186,
			447.866,
			449.559,
			450.407,
			452.102,
			452.951,
			454.66,
			456.382,
			458.987,
			460.738,
			463.385,
			464.273,
			465.166,
			467.871,
			469.69,
			472.449,
			474.294,
			476.13,
			477.042,
			478.849,
			479.757,
			481.519,
			483.245,
			484.094,
			484.935,
			487.419,
			489.048,
			491.47,
			493.077,
			494.684,
			495.484,
			497.077,
			497.872,
			499.461,
			501.037,
			503.38,
			504.935,
			507.247,
			508.013,
			508.777,
			511.077,
			512.622,
			514.931,
			516.525,
			518.128,
			518.929,
			520.55,
			521.398,
			523.092,
			525.656,
			527.454,
			529.247,
			532.024,
			532.966,
			533.906,
			535.783,
			537.657,
			538.591,
			541.282,
			542.951,
			545.439,
			546.947,
			548.448,
			550.685,
			551.423,
			552.158,
			554.35,
			555.811,
			558.003,
			559.469,
			561.694,
			563.179,
			563.922,
			565.412,
			567.66,
			569.161,
			570.666,
			572.928,
			573.683,
			574.439,
			576.72,
			577.483,
			578.246,
			580.541,
			582.072,
			584.371,
			585.913,
			587.464,
			589.796,
			590.576,
			591.389,
			593.826,
			595.453,
			598.179,
			600.009,
			601.905,
			602.866,
			604.788,
			606.647,
			607.552,
			609.361,
			610.266,
			612.018,
			613.753,
			616.345,
			618.055,
			620.614,
			621.46,
			622.306,
			624.835,
			626.474,
			628.848,
			630.437,
			632.009,
			632.787,
			634.346,
			635.127,
			636.698,
			638.275,
			639.064,
			639.854,
			642.218,
			643.801,
			645.37,
			646.149,
			647.701,
			649.235,
			649.994,
			652.253,
			653.74,
			655.214,
			657.496,
			659.019,
			661.335,
			662.166,
			662.995,
			665.47,
			667.135,
			669.628,
			671.25,
			672.751,
			673.498,
			674.986,
			676.462,
			677.19,
			678.639,
			679.361,
			680.806,
			682.317,
			684.587,
			686.093,
			687.589,
			689.076,
			689.819,
			692.023,
			693.467,
			694.905,
			697.089,
			698.558,
			700.763,
			701.512,
			702.27,
			704.543,
			706.063,
			708.348,
			709.874,
			712.168,
			713.697,
			714.463,
			716,
			718.303,
			719.848,
			721.399,
			723.712,
			724.487,
			725.266,
			727.582,
			728.353,
			729.126,
			731.457,
			732.984,
			735.297,
			736.858,
			738.432,
			740.818,
			741.626,
			742.441,
			744.907,
			745.736,
			746.571,
			749.094,
			750.775,
			752.458,
			753.304,
			755.004,
			756.709,
			757.556,
			759.257,
			760.112,
			761.834,
			763.571,
			766.2,
			767.999,
			770.723,
			771.637,
			772.552,
			775.316,
			777.179,
			780.003,
			781.905,
			783.829,
			786.738,
			787.711,
			789.666,
			791.617,
			792.603,
			794.586,
			796.58,
			798.581,
			801.603,
			803.628,
			805.663,
			806.685,
			808.734,
			809.763,
			811.829,
			814.946,
			817.025,
			819.11,
			822.259,
			823.314,
			824.373,
			827.566,
			829.73,
			832.972,
			835.15,
			838.438,
			840.644,
			841.751,
			843.97,
			847.315,
			848.434,
			851.803,
			855.191,
			856.325,
			857.461,
			859.74,
			862.027,
			863.172,
			865.466,
			866.615,
			868.914,
			871.215,
			874.661,
			876.947,
			880.35,
			881.473,
			882.593,
			885.912,
			888.129,
			891.459,
			893.707,
			895.943,
			897.063,
			899.311,
			900.438,
			902.699,
			904.972,
			906.11,
			908.404,
			910.706,
			914.142,
			915.28,
			916.412,
			919.737,
			920.853,
			921.925,
			925.129,
			927.238,
			930.412,
			932.517,
			934.632,
			937.813,
			938.875,
			939.939,
			943.137,
			944.201,
			945.248,
			948.442,
			950.572,
			953.799,
			955.947,
			958.103,
			959.183,
			962.437,
			964.604,
			966.798,
			970.112,
			972.334,
			975.685,
			976.807,
			977.949,
			981.332,
			983.599,
			986.998,
			989.292,
			991.596,
			992.753,
			995.078,
			998.584,
			1000.936,
			1002.116,
			1003.298,
			1006.854,
			1009.214,
			1012.722,
			1015.022,
			1017.304,
			1018.424,
			1020.634,
			1021.726,
			1023.864,
			1025.984,
			1029.094,
			1031.121,
			1034.093,
			1035.065,
			1036.027,
			1038.86,
			1040.701,
			1043.396,
			1045.151,
			1046.868,
			1047.712,
			1049.372,
			1050.188,
			1051.791,
			1054.125,
			1055.636,
			1057.11,
			1059.252,
			1059.95,
			1060.641,
			1062,
			1063.328,
			1063.981,
			1065.892,
			1067.122,
			1068.328,
			1070.089,
			1071.221,
			1072.876,
			1073.422,
			1073.964,
			1075.556,
			1076.587,
			1078.105,
			1079.11,
			1080.102,
			1080.591,
			1081.555,
			1082.503,
			1082.981,
			1083.913,
			1084.376,
			1085.297,
			1086.212,
			1087.581,
			1088.032,
			1088.479,
			1089.813,
			1090.257,
			1090.699,
			1092.022,
			1092.9,
			1093.77,
			1095.062,
			1095.918,
			1097.2,
			1097.627,
			1098.066,
			1099.386,
			1099.817,
			1100.247,
			1101.527,
			1102.37,
			1103.233,
			1103.676,
			1104.553,
			1105.417,
			1105.844,
			1107.115,
			1107.965,
			1108.848,
			1110.14,
			1110.99,
			1112.255,
			1112.676,
			1113.11,
			1114.418,
			1115.276,
			1116.556,
			1117.411,
			1118.297,
			1119.657,
			1120.108,
			1121.01,
			1121.915,
			1122.369,
			1122.825,
			1124.276,
			1125.246,
			1126.709,
			1127.693,
			1128.666,
			1129.151,
			1130.617,
			1131.605,
			1132.604,
			1134.021,
			1134.961,
			1136.393,
			1136.877,
			1137.365,
			1138.834,
			1139.823,
			1141.332,
			1142.36,
			1143.411,
			1143.945,
			1145.026,
			1145.573,
			1146.68,
			1147.809,
			1148.383,
			1148.962,
			1150.725,
			1151.922,
			1153.142,
			1153.763,
			1155.016,
			1156.279,
			1156.91,
			1158.176,
			1158.82,
			1160.117,
			1161.427,
			1163.434,
			1164.805,
			1166.883,
			1167.583,
			1168.296,
			1170.493,
			1171.975,
			1174.268,
			1175.831,
			1177.411,
			1178.208,
			1179.829,
			1180.644,
			1182.283,
			1184.759,
			1186.42,
			1188.092,
			1190.556,
			1192.213,
			1194.664,
			1195.477,
			1196.293,
			1197.933,
			1198.739,
			1200.349,
			1201.972,
			1204.414,
			1206.046,
			1208.523,
			1209.356,
			1210.189,
			1212.672,
			1213.509,
			1214.351,
			1216.877,
			1218.574,
			1220.26,
			1221.101,
			1222.77,
			1224.454,
			1225.296,
			1226.98,
			1227.824,
			1229.552,
			1231.28,
			1233.901,
			1235.678,
			1238.367,
			1239.278,
			1240.191,
			1242.948,
			1244.809,
			1247.625,
			1249.514,
			1251.408,
			1252.357,
			1254.262,
			1255.214,
			1256.17,
			1259.05,
			1260.01,
			1260.973,
			1263.881,
			1265.813,
			1267.758,
			1268.732,
			1270.66,
			1272.601,
			1273.575,
			1275.522,
			1276.5,
			1278.464,
			1280.446,
			1283.435,
			1285.446,
			1288.476,
			1289.492,
			1290.511,
			1293.585,
			1295.651,
			1298.771,
			1300.861,
			1303.994,
			1306.092,
			1307.126,
			1309.238,
			1311.359,
			1312.422,
			1314.553,
			1316.69,
			1319.915,
			1320.995,
			1322.077,
			1325.342,
			1326.437,
			1327.533,
			1329.735,
			1330.84,
			1333.058,
			1335.283,
			1338.638,
			1340.886,
			1344.275,
			1345.409,
			1346.546,
			1349.97,
			1352.265,
			1355.725,
			1358.038,
			1360.344,
			1361.49,
			1363.763,
			1364.886,
			1367.106,
			1369.288,
			1370.365,
			1372.49,
			1374.578,
			1377.639,
			1378.641,
			1379.634,
			1382.555,
			1383.511,
			1384.457,
			1387.237,
			1389.048,
			1391.701,
			1393.427,
			1395.117,
			1397.583,
			1398.387,
			1399.182,
			1401.511,
			1403.019,
			1405.213,
			1406.631,
			1408.014,
			1408.693,
			1410.027,
			1410.681,
			1411.965,
			1413.215,
			1413.826,
			1415.022,
			1416.183,
			1417.87,
			1418.958,
			1420.554,
			1421.08,
			1421.605,
			1423.178,
			1424.231,
			1425.826,
			1426.898,
			1427.981,
			1429.633,
			1430.201,
			1431.339,
			1432.48,
			1433.051,
			1433.623,
			1435.369,
			1436.543,
			1437.707,
			1438.286,
			1439.441,
			1440.637,
			1441.228,
			1442.396,
			1442.973,
			1444.119,
			1445.288,
			1447.029,
			1448.174,
			1449.893,
			1450.475,
			1451.054,
			1452.781,
			1453.927,
			1455.705,
			1456.895,
			1458.075,
			1458.663,
			1459.831,
			1460.406,
			1461.546,
			1463.241,
			1464.367,
			1465.463,
			1466.547,
			1467.625,
			1468.163,
			1469.24,
			1470.344,
			1470.896,
			1472.544,
			1473.645,
			1474.76,
			1476.483,
			1477.63,
			1479.359,
			1479.919,
			1480.474,
			1482.145,
			1483.265,
			1484.924,
			1485.972,
			1487.027,
			1487.558,
			1488.625,
			1489.163,
			1490.225,
			1491.819,
			1492.895,
			1493.983,
			1495.647,
			1496.209,
			1496.774,
			1498.491,
			1499.07,
			1499.656,
			1501.457,
			1502.677,
			1503.913,
			1505.811,
			1507.1,
			1509.063,
			1509.727,
			1510.397,
			1513.119,
			1513.809,
			1515.907,
			1517.325,
			1519.483,
			1520.95,
			1522.422,
			1523.17,
			1524.678,
			1525.439,
			1526.975,
			1528.525,
			1530.89,
			1532.477,
			1534.869,
			1535.672,
			1536.477,
			1538.894,
			1540.544,
			1543.015,
			1544.689,
			1547.22,
			1548.935,
			1549.786,
			1550.623,
			1554.045,
			1554.904,
			1557.483,
			1559.231,
			1560.984,
			1561.862,
			1563.63,
			1565.41,
			1566.306,
			1568.105,
			1569.009,
			1570.833,
			1572.676,
			1575.466,
			1577.349,
			1580.199,
			1581.156,
			1582.117,
			1585.018,
			1586.96,
			1589.897,
			1591.862,
			1594.831,
			1596.825,
			1597.826,
			1598.831,
			1601.862,
			1602.878,
			1604.919,
			1606.971,
			1609.035,
			1611.11,
			1612.152,
			1614.244,
			1616.342,
			1617.39,
			1619.488,
			1620.54,
			1622.652,
			1624.774,
			1627.974,
			1630.135,
			1633.353,
			1634.425,
			1635.495,
			1638.685,
			1640.79,
			1643.897,
			1645.94,
			1648.964,
			1650.963,
			1651.956,
			1653.935,
			1655.909,
			1656.894,
			1658.859,
			1660.823,
			1663.801,
			1664.79,
			1665.786,
			1668.821,
			1669.829,
			1670.841,
			1672.881,
			1673.898,
			1675.936,
			1679.014,
			1681.072,
			1683.152,
			1686.273,
			1687.313,
			1688.353,
			1692.517,
			1693.558,
			1696.661,
			1698.699,
			1700.741,
			1701.741,
			1703.745,
			1704.75,
			1706.754,
			1709.76,
			1711.77,
			1713.785,
			1716.83,
			1718.875,
			1721.966,
			1723.004,
			1724.044,
			1727.182,
			1729.288,
			1732.468,
			1734.583,
			1736.724,
			1739.95,
			1741.028,
			1742.104,
			1745.324,
			1746.39,
			1747.45,
			1750.59,
			1752.644,
			1754.662,
			1755.657,
			1757.624,
			1759.554,
			1760.506,
			1762.386,
			1763.315,
			1765.162,
			1766.972,
			1769.653,
			1771.422,
			1774.065,
			1774.943,
			1775.821,
			1778.453,
			1780.201,
			1782.83,
			1784.582,
			1787.219,
			1788.991,
			1789.876,
			1791.646,
			1793.423,
			1794.311,
			1796.09,
			1797.869,
			1799.65,
			1801.441,
			1802.351,
			1804.172,
			1805.998,
			1806.925,
			1808.791,
			1809.725,
			1811.6,
			1813.483,
			1816.315,
			1818.201,
			1821.033,
			1821.973,
			1822.911,
			1825.724,
			1827.567,
			1830.308,
			1832.123,
			1833.922,
			1834.823,
			1836.626,
			1837.531,
			1839.342,
			1842.07,
			1843.901,
			1845.737,
			1848.506,
			1850.362,
			1852.227,
			1854.1,
			1855.039,
			1857.867,
			1859.755,
			1861.643,
			1864.466,
			1866.336,
			1869.109,
			1870.023,
			1870.93,
			1873.604,
			1874.478,
			1875.344,
			1877.891,
			1879.547,
			1881.169,
			1881.969,
			1883.546,
			1884.323,
			1885.852,
			1887.361,
			1888.109,
			1889.588,
			1891.047,
			1893.229,
			1894.675,
			1896.834,
			1897.564,
			1898.294,
			1900.483,
			1901.947,
			1903.439,
			1905.679,
			1907.942,
			1909.463,
			1910.225,
			1910.988,
			1913.276,
			1914.038,
			1914.8,
			1917.089,
			1918.607,
			1920.124,
			1920.883,
			1922.402,
			1923.922,
			1924.682,
			1926.199,
			1926.959,
			1928.475,
			1929.987,
			1932.252,
			1933.75,
			1935.99,
			1936.737,
			1937.48,
			1939.7,
			1941.176,
			1943.385,
			1944.85,
			1946.313,
			1947.045,
			1948.51,
			1949.24,
			1950.697,
			1952.154,
			1952.885,
			1953.614,
			1955.799,
			1957.257,
			1958.721,
			1959.452,
			1960.913,
			1962.376,
			1963.11,
			1964.575,
			1965.306,
			1966.767,
			1968.233,
			1970.427,
			1971.888,
			1974.095,
			1974.829,
			1975.564,
			1977.778,
			1978.524,
			1979.27,
			1981.515,
			1983.025,
			1984.544,
			1985.306,
			1986.835,
			1987.606,
			1989.156,
			1990.714,
			1991.497,
			1993.072,
			1994.659,
			1997.057,
			1997.857,
			1998.658,
			2001.078,
			2001.891,
			2002.709,
			2004.353,
			2005.18,
			2006.844,
			2008.532,
			2011.091,
			2012.818,
			2015.442,
			2016.324,
			2017.209,
			2019.903,
			2021.715,
			2024.482,
			2026.347,
			2028.226,
			2029.172,
			2031.072,
			2032.977,
			2033.925,
			2035.832,
			2036.791,
			2038.685,
			2040.581,
			2043.439,
			2045.35,
			2048.24,
			2049.209,
			2050.181,
			2052.135,
			2053.115,
			2055.083,
			2057.062,
			2060.033,
			2062.027,
			2065.011,
			2066.009,
			2067.01,
			2070.039,
			2071.053,
			2072.071,
			2075.162,
			2077.237,
			2079.326,
			2080.373,
			2082.467,
			2084.555,
			2085.602,
			2087.702,
			2088.752,
			2090.86,
			2092.978,
			2096.161,
			2098.33,
			2101.568,
			2102.659,
			2103.755,
			2107.056,
			2109.267,
			2112.601,
			2114.833,
			2117.077,
			2118.202,
			2120.459,
			2121.592,
			2123.864,
			2126.147,
			2127.292,
			2128.44,
			2131.898,
			2134.215,
			2137.709,
			2140.051,
			2142.401,
			2143.581,
			2147.131,
			2149.51,
			2151.899,
			2155.5,
			2157.911,
			2161.546,
			2162.762,
			2163.98,
			2167.649,
			2170.106,
			2173.807,
			2176.286,
			2178.774,
			2180.021,
			2182.522,
			2183.776,
			2186.291,
			2188.813,
			2190.077,
			2192.612,
			2195.155,
			2198.985,
			2200.265,
			2201.548,
			2204.119,
			2206.699,
			2207.991,
			2210.583,
			2211.881,
			2214.484,
			2218.394,
			2221.004,
			2223.622,
			2227.559,
			2228.874,
			2230.191,
			2234.153,
			2236.804,
			2240.796,
			2243.468,
			2247.487,
			2250.175,
			2251.521,
			2254.219,
			2256.922,
			2258.277,
			2260.992,
			2263.714,
			2267.807,
			2270.544,
			2274.66,
			2276.033,
			2277.409,
			2281.547,
			2284.315,
			2287.092,
			2291.272,
			2294.069,
			2298.277,
			2299.682,
			2301.089,
			2305.32,
			2306.733,
			2308.147,
			2312.399,
			2315.24,
			2318.087,
			2319.513,
			2322.37,
			2325.235,
			2326.67,
			2329.546,
			2330.988,
			2333.877,
			2336.772,
			2340.096
		],
		[
			0,
			0.06,
			0.093,
			0.122,
			0.184,
			0.201,
			0.248,
			0.308,
			0.371,
			0.432,
			0.495,
			0.525,
			0.557,
			0.633,
			0.681,
			0.743,
			0.806,
			0.852,
			0.868,
			0.93,
			0.961,
			0.992,
			1.07,
			1.116,
			1.179,
			1.239,
			1.299,
			1.364,
			1.397,
			1.426,
			1.488,
			1.504,
			1.548,
			1.611,
			1.674,
			1.733,
			1.796,
			1.827,
			1.858,
			1.921,
			1.984,
			2.047,
			2.106,
			2.168,
			2.231,
			2.261,
			2.293,
			2.37,
			2.417,
			2.479,
			2.54,
			2.588,
			2.601,
			2.664,
			2.696,
			2.728,
			2.789,
			2.805,
			2.851,
			2.913,
			2.976,
			3.021,
			3.099,
			3.13,
			3.16,
			3.224,
			3.284,
			3.347,
			3.409,
			3.459,
			3.473,
			3.533,
			3.564,
			3.596,
			3.673,
			3.718,
			3.781,
			3.843,
			3.907,
			3.969,
			3.999,
			4.029,
			4.092,
			4.154,
			4.215,
			4.276,
			4.325,
			4.403,
			4.433,
			4.465,
			4.524,
			4.588,
			4.65,
			4.712,
			4.758,
			4.775,
			4.836,
			4.865,
			4.897,
			4.958,
			4.976,
			5.021,
			5.082,
			5.145,
			5.193,
			5.27,
			5.3,
			5.331,
			5.392,
			5.456,
			5.523,
			5.579,
			5.641,
			5.705,
			5.736,
			5.766,
			5.827,
			5.844,
			5.889,
			5.953,
			6.015,
			6.076,
			6.139,
			6.168,
			6.2,
			6.276,
			6.325,
			6.387,
			6.447,
			6.511,
			6.573,
			6.603,
			6.632,
			6.712,
			6.757,
			6.82,
			6.882,
			6.928,
			6.945,
			7.006,
			7.037,
			7.068,
			7.131,
			7.146,
			7.192,
			7.255,
			7.317,
			7.363,
			7.377,
			7.439,
			7.472,
			7.501,
			7.565,
			7.584,
			7.626,
			7.688,
			7.749,
			7.796,
			7.873,
			7.905,
			7.934,
			7.998,
			8.061,
			8.121,
			8.184,
			8.231,
			8.245,
			8.307,
			8.339,
			8.37,
			8.432,
			8.446,
			8.495,
			8.557,
			8.616,
			8.665,
			8.681,
			8.742,
			8.774,
			8.805,
			8.865,
			8.883,
			8.929,
			8.99,
			9.054,
			9.114,
			9.177,
			9.209,
			9.238,
			9.302,
			9.362,
			9.425,
			9.487,
			9.532,
			9.549,
			9.611,
			9.645,
			9.673,
			9.749,
			9.797,
			9.86,
			9.92,
			9.983,
			10.045,
			10.075,
			10.107,
			10.166,
			10.183,
			10.231,
			10.294,
			10.355,
			10.402,
			10.477,
			10.509,
			10.54,
			10.603,
			10.665,
			10.725,
			10.788,
			10.834,
			10.851,
			10.913,
			10.944,
			10.976,
			11.037,
			11.05,
			11.098,
			11.161,
			11.223,
			11.269,
			11.348,
			11.379,
			11.409,
			11.471,
			11.532,
			11.594,
			11.658,
			11.706,
			11.719,
			11.781,
			11.812,
			11.843,
			11.905,
			11.921,
			11.966,
			12.029,
			12.091,
			12.153,
			12.216,
			12.247,
			12.277,
			12.339,
			12.356,
			12.399,
			12.463,
			12.526,
			12.588,
			12.649,
			12.681,
			12.71,
			12.774,
			12.836,
			12.899,
			12.959,
			13.005,
			13.021,
			13.083,
			13.115,
			13.146,
			13.222,
			13.27,
			13.331,
			13.392,
			13.44,
			13.454,
			13.516,
			13.548,
			13.578,
			13.642,
			13.701,
			13.772,
			13.828,
			13.873,
			13.95,
			13.983,
			14.013,
			14.091,
			14.137,
			14.2,
			14.262,
			14.322,
			14.386,
			14.415,
			14.447,
			14.524,
			14.572,
			14.632,
			14.696,
			14.741,
			14.759,
			14.82,
			14.849,
			14.882,
			14.945,
			15.005,
			15.068,
			15.13,
			15.176,
			15.252,
			15.283,
			15.314,
			15.377,
			15.44,
			15.502,
			15.563,
			15.609,
			15.625,
			15.688,
			15.719,
			15.751,
			15.813,
			15.83,
			15.873,
			15.935,
			15.997,
			16.061,
			16.122,
			16.151,
			16.183,
			16.245,
			16.309,
			16.369,
			16.431,
			16.479,
			16.494,
			16.556,
			16.587,
			16.617,
			16.681,
			16.694,
			16.741,
			16.805,
			16.866,
			16.911,
			16.927,
			16.99,
			17.021,
			17.053,
			17.129,
			17.176,
			17.238,
			17.299,
			17.346,
			17.423,
			17.456,
			17.484,
			17.562,
			17.61,
			17.673,
			17.732,
			17.781,
			17.797,
			17.857,
			17.894,
			17.92,
			17.983,
			17.999,
			18.043,
			18.106,
			18.168,
			18.229,
			18.291,
			18.324,
			18.354,
			18.432,
			18.477,
			18.539,
			18.603,
			18.647,
			18.725,
			18.757,
			18.788,
			18.85,
			18.913,
			18.973,
			19.036,
			19.098,
			19.161,
			19.191,
			19.222,
			19.301,
			19.347,
			19.408,
			19.472,
			19.516,
			19.533,
			19.593,
			19.626,
			19.655,
			19.734,
			19.779,
			19.842,
			19.905,
			19.965,
			20.027,
			20.06,
			20.091,
			20.151,
			20.168,
			20.214,
			20.28,
			20.339,
			20.386,
			20.399,
			20.463,
			20.493,
			20.524,
			20.586,
			20.603,
			20.649,
			20.712,
			20.772,
			20.819,
			20.898,
			20.927,
			20.96,
			21.021,
			21.084,
			21.146,
			21.206,
			21.253,
			21.332,
			21.361,
			21.393,
			21.455,
			21.47,
			21.517,
			21.58,
			21.641,
			21.703,
			21.765,
			21.796,
			21.827,
			21.888,
			21.905,
			21.949,
			22.018,
			22.075,
			22.122,
			22.198,
			22.23,
			22.259,
			22.323,
			22.386,
			22.446,
			22.507,
			22.572,
			22.633,
			22.663,
			22.693,
			22.773,
			22.816,
			22.88,
			22.944,
			22.989,
			23.005,
			23.066,
			23.098,
			23.129,
			23.191,
			23.205,
			23.254,
			23.314,
			23.377,
			23.423,
			23.501,
			23.531,
			23.563,
			23.626,
			23.688,
			23.749,
			23.811,
			23.857,
			23.873,
			23.934,
			23.965,
			23.997,
			24.058,
			24.078,
			24.121,
			24.184,
			24.246,
			24.292,
			24.306,
			24.368,
			24.4,
			24.43,
			24.494,
			24.555,
			24.622,
			24.68,
			24.741,
			24.804,
			24.832,
			24.865,
			24.926,
			24.943,
			24.989,
			25.049,
			25.114,
			25.176,
			25.238,
			25.268,
			25.299,
			25.378,
			25.423,
			25.487,
			25.547,
			25.592,
			25.672,
			25.701,
			25.733,
			25.794,
			25.858,
			25.92,
			25.981,
			26.027,
			26.043,
			26.105,
			26.168,
			26.23,
			26.246,
			26.291,
			26.354,
			26.415,
			26.478,
			26.539,
			26.57,
			26.6,
			26.664,
			26.678,
			26.725,
			26.788,
			26.85,
			26.895,
			26.974,
			27.005,
			27.034,
			27.097,
			27.159,
			27.221,
			27.283,
			27.331,
			27.345,
			27.407,
			27.438,
			27.468,
			27.546,
			27.594,
			27.656,
			27.718,
			27.764,
			27.781,
			27.842,
			27.871,
			27.903,
			27.982,
			28.027,
			28.09,
			28.152,
			28.205,
			28.277,
			28.308,
			28.338,
			28.4,
			28.463,
			28.525,
			28.586,
			28.633,
			28.648,
			28.71,
			28.742,
			28.772,
			28.834,
			28.848,
			28.896,
			28.957,
			29.018,
			29.065,
			29.081,
			29.145,
			29.176,
			29.206,
			29.284,
			29.329,
			29.391,
			29.454,
			29.516,
			29.577,
			29.609,
			29.639,
			29.699,
			29.717,
			29.764,
			29.827,
			29.888,
			29.934,
			29.95,
			30.011,
			30.043,
			30.073,
			30.153,
			30.198,
			30.265,
			30.323,
			30.369,
			30.447,
			30.476,
			30.507,
			30.586,
			30.632,
			30.694,
			30.756,
			30.816,
			30.879,
			30.91,
			30.942,
			31.005,
			31.019,
			31.065,
			31.128,
			31.191,
			31.251,
			31.313,
			31.344,
			31.377,
			31.455,
			31.5,
			31.563,
			31.623,
			31.671,
			31.748,
			31.779,
			31.811,
			31.872,
			31.934,
			32.001,
			32.057,
			32.106,
			32.12,
			32.184,
			32.215,
			32.244,
			32.305,
			32.326,
			32.366,
			32.431,
			32.492,
			32.54,
			32.555,
			32.616,
			32.646,
			32.679,
			32.74,
			32.755,
			32.802,
			32.866,
			32.927,
			32.972,
			33.049,
			33.082,
			33.114,
			33.175,
			33.234,
			33.298,
			33.361,
			33.408,
			33.422,
			33.483,
			33.514,
			33.546,
			33.624,
			33.671,
			33.732,
			33.796,
			33.842,
			33.92,
			33.95,
			33.982,
			34.041,
			34.058,
			34.105,
			34.166,
			34.23,
			34.276,
			34.353,
			34.389,
			34.414,
			34.477,
			34.492,
			34.54,
			34.601,
			34.661,
			34.709,
			34.726,
			34.785,
			34.818,
			34.849,
			34.91,
			34.927,
			34.973,
			35.036,
			35.097,
			35.145,
			35.222,
			35.251,
			35.283,
			35.36,
			35.408,
			35.469,
			35.53,
			35.578,
			35.593,
			35.656,
			35.685,
			35.716,
			35.779,
			35.796,
			35.84,
			35.905,
			35.965,
			36.013,
			36.027,
			36.09,
			36.118,
			36.149,
			36.212,
			36.229,
			36.276,
			36.338,
			36.399,
			36.46,
			36.524,
			36.554,
			36.584,
			36.664,
			36.71,
			36.772,
			36.833,
			36.895,
			36.958,
			36.988,
			37.019,
			37.081,
			37.096,
			37.142,
			37.204,
			37.268,
			37.314,
			37.329,
			37.393,
			37.424,
			37.454,
			37.516,
			37.53,
			37.579,
			37.639,
			37.701,
			37.747,
			37.824,
			37.86,
			37.888,
			37.949,
			38.013,
			38.074,
			38.136,
			38.181,
			38.197,
			38.259,
			38.29,
			38.322,
			38.384,
			38.398,
			38.446,
			38.507,
			38.57,
			38.616,
			38.63,
			38.694,
			38.725,
			38.756,
			38.818,
			38.88,
			38.942,
			39.001,
			39.049,
			39.126,
			39.158,
			39.189,
			39.266,
			39.312,
			39.376,
			39.438,
			39.485,
			39.501,
			39.561,
			39.591,
			39.624,
			39.686,
			39.7,
			39.747,
			39.813,
			39.871,
			39.917,
			39.996,
			40.028,
			40.059,
			40.121,
			40.182,
			40.244,
			40.305,
			40.366,
			40.431,
			40.46,
			40.492,
			40.555,
			40.573,
			40.616,
			40.678,
			40.741,
			40.785,
			40.8,
			40.864,
			40.895,
			40.927,
			40.989,
			41.004,
			41.05,
			41.113,
			41.175,
			41.222,
			41.298,
			41.33,
			41.359,
			41.421,
			41.484,
			41.547,
			41.609,
			41.654,
			41.671,
			41.732,
			41.763,
			41.794,
			41.871,
			41.918,
			41.982,
			42.042,
			42.088,
			42.105,
			42.166,
			42.198,
			42.228,
			42.304,
			42.35,
			42.415,
			42.477,
			42.522,
			42.599,
			42.636,
			42.661,
			42.724,
			42.786,
			42.847,
			42.91,
			42.957,
			42.971,
			43.034,
			43.066,
			43.096,
			43.174,
			43.221,
			43.282,
			43.344,
			43.391,
			43.407,
			43.468,
			43.498,
			43.531,
			43.592,
			43.655,
			43.715,
			43.78,
			43.826,
			43.903,
			43.932,
			43.964,
			44.043,
			44.089,
			44.152,
			44.212,
			44.275,
			44.337,
			44.367,
			44.4,
			44.461,
			44.477,
			44.522,
			44.584,
			44.646,
			44.697,
			44.77,
			44.801,
			44.832,
			44.91,
			44.958,
			45.024,
			45.08,
			45.144,
			45.204,
			45.234,
			45.265,
			45.344,
			45.39,
			45.454,
			45.515,
			45.561,
			45.578,
			45.638,
			45.671,
			45.699,
			45.762,
			45.778,
			45.825,
			45.885,
			45.947,
			45.996,
			46.074,
			46.105,
			46.134,
			46.197,
			46.258,
			46.321,
			46.383,
			46.444,
			46.508,
			46.538,
			46.567,
			46.631,
			46.647,
			46.694,
			46.759,
			46.816,
			46.863,
			46.88,
			46.942,
			46.971,
			47.004,
			47.065,
			47.079,
			47.126,
			47.188,
			47.252,
			47.297,
			47.375,
			47.407,
			47.437,
			47.5,
			47.562,
			47.628,
			47.683,
			47.747,
			47.808,
			47.84,
			47.872,
			47.934,
			47.95,
			47.994,
			48.058,
			48.12,
			48.165,
			48.182,
			48.244,
			48.274,
			48.305,
			48.366,
			48.383,
			48.43,
			48.492,
			48.555,
			48.6,
			48.676,
			48.708,
			48.739,
			48.824,
			48.863,
			48.926,
			48.989,
			49.035,
			49.049,
			49.11,
			49.142,
			49.174,
			49.252,
			49.298,
			49.361,
			49.423,
			49.468,
			49.544,
			49.577,
			49.608,
			49.67,
			49.731,
			49.795,
			49.855,
			49.901,
			49.98,
			50.011,
			50.041,
			50.104,
			50.118,
			50.165,
			50.227,
			50.289,
			50.337,
			50.35,
			50.414,
			50.445,
			50.475,
			50.538,
			50.553,
			50.6,
			50.663,
			50.723,
			50.771,
			50.848,
			50.882,
			50.91,
			50.971,
			51.033,
			51.1,
			51.158,
			51.22,
			51.282,
			51.313,
			51.344,
			51.407,
			51.422,
			51.467,
			51.53,
			51.591,
			51.638,
			51.654,
			51.715,
			51.746,
			51.777,
			51.839,
			51.857,
			51.901,
			51.965,
			52.027,
			52.071,
			52.149,
			52.181,
			52.211,
			52.29,
			52.336,
			52.398,
			52.459,
			52.506,
			52.522,
			52.582,
			52.614,
			52.647,
			52.723,
			52.771,
			52.833,
			52.895,
			52.955,
			53.016,
			53.049,
			53.079,
			53.143,
			53.204,
			53.267,
			53.329,
			53.375,
			53.451,
			53.482,
			53.514,
			53.577,
			53.592,
			53.638,
			53.7,
			53.761,
			53.809,
			53.825,
			53.885,
			53.916,
			53.949,
			54.011,
			54.025,
			54.073,
			54.135,
			54.195,
			54.243,
			54.322,
			54.353,
			54.381,
			54.445,
			54.506,
			54.566,
			54.631,
			54.692,
			54.754,
			54.787,
			54.815,
			54.88,
			54.895,
			54.94,
			55.006,
			55.064,
			55.112,
			55.126,
			55.188,
			55.221,
			55.25,
			55.312,
			55.328,
			55.376,
			55.435,
			55.498,
			55.544,
			55.624,
			55.654,
			55.683,
			55.747,
			55.809,
			55.871,
			55.933,
			55.98,
			55.995,
			56.056,
			56.088,
			56.118,
			56.18,
			56.198,
			56.241,
			56.306,
			56.367,
			56.412,
			56.428,
			56.491,
			56.523,
			56.553,
			56.614,
			56.63,
			56.676,
			56.739,
			56.799,
			56.848,
			56.924,
			56.955,
			56.987,
			57.05,
			57.07,
			57.111,
			57.172,
			57.236,
			57.282,
			57.296,
			57.357,
			57.39,
			57.422,
			57.484,
			57.5,
			57.544,
			57.607,
			57.67,
			57.715,
			57.731,
			57.794,
			57.825,
			57.856,
			57.916,
			57.932,
			57.98,
			58.04,
			58.104,
			58.149,
			58.227,
			58.257,
			58.29,
			58.352,
			58.413,
			58.476,
			58.538,
			58.583,
			58.6,
			58.66,
			58.692,
			58.722,
			58.783,
			58.801,
			58.848,
			58.91,
			58.971,
			59.034,
			59.095,
			59.129,
			59.158,
			59.217,
			59.233,
			59.28,
			59.342,
			59.406,
			59.452,
			59.528,
			59.56,
			59.591,
			59.654,
			59.669,
			59.716,
			59.779,
			59.839,
			59.884,
			59.901,
			59.963,
			59.993,
			60.026,
			60.088,
			60.103,
			60.149,
			60.211,
			60.274,
			60.321,
			60.398,
			60.429,
			60.46,
			60.522,
			60.585,
			60.646,
			60.708,
			60.755,
			60.77,
			60.831,
			60.862,
			60.893,
			60.955,
			60.972,
			61.016,
			61.081,
			61.141,
			61.195,
			61.266,
			61.298,
			61.329,
			61.406,
			61.451,
			61.513,
			61.575,
			61.622,
			61.7,
			61.731,
			61.762,
			61.824,
			61.886,
			61.949,
			62.01,
			62.055,
			62.071,
			62.134,
			62.165,
			62.196,
			62.257,
			62.274,
			62.319,
			62.383,
			62.442,
			62.491,
			62.507,
			62.566,
			62.599,
			62.629,
			62.691,
			62.707,
			62.755,
			62.817,
			62.876,
			62.924,
			63.003,
			63.033,
			63.063,
			63.141,
			63.189,
			63.255,
			63.311,
			63.374,
			63.436,
			63.465,
			63.499,
			63.561,
			63.574,
			63.622,
			63.684,
			63.747,
			63.793,
			63.871,
			63.901,
			63.932,
			63.994,
			64.056,
			64.116,
			64.18,
			64.226,
			64.305,
			64.335,
			64.365,
			64.428,
			64.444,
			64.491,
			64.553,
			64.615,
			64.659,
			64.677,
			64.738,
			64.768,
			64.801,
			64.861,
			64.878,
			64.924,
			64.985,
			65.035
		]
	],
	'5924_6138_7982': [
		[
			31.695
		],
		[
			0
		],
		[
			0,
			1.085,
			2.531,
			3.977,
			8.3,
			11.155,
			13.979,
			18.144,
			20.873,
			24.895,
			26.217,
			27.53,
			31.411,
			32.685,
			33.951,
			37.691,
			40.137,
			43.741,
			46.1,
			48.425,
			49.574,
			51.843,
			52.963,
			55.176,
			57.352,
			60.544,
			62.626,
			65.679,
			66.679,
			67.669,
			70.585,
			72.483,
			75.262,
			77.073,
			78.85,
			79.726,
			81.45,
			82.298,
			83.967,
			86.401,
			87.194,
			89.519,
			91.024,
			92.492,
			93.213,
			94.627,
			96.006,
			96.683,
			98.013,
			98.665,
			99.946,
			101.192,
			102.996,
			104.154,
			105.825,
			106.364,
			106.897,
			108.445,
			109.433,
			110.87,
			111.798,
			113.162,
			114.055,
			114.497,
			115.375,
			116.248,
			116.681,
			117.539,
			118.396,
			119.68,
			120.107,
			120.534,
			121.81,
			122.227,
			122.643,
			123.878,
			124.694,
			125.507,
			126.744,
			127.639,
			128.967,
			129.407,
			129.847,
			131.165,
			132.148,
			133.718,
			134.757,
			135.793,
			136.311,
			137.38,
			137.917,
			138.988,
			140.604,
			141.692,
			142.777,
			144.405,
			144.949,
			145.495,
			147.123,
			147.652,
			148.183,
			149.785,
			150.865,
			151.941,
			153.516,
			154.579,
			156.199,
			156.743,
			157.279,
			159.456,
			160.01,
			161.697,
			162.821,
			164.536,
			165.702,
			166.885,
			167.479,
			168.68,
			169.288,
			170.516,
			171.76,
			173.631,
			174.882,
			176.787,
			177.428,
			178.074,
			180.035,
			181.361,
			183.383,
			184.749,
			186.132,
			188.24,
			188.951,
			189.665,
			192.565,
			193.3,
			195.53,
			197.036,
			198.559,
			199.327,
			200.873,
			202.439,
			203.231,
			204.827,
			205.63,
			207.25,
			208.892,
			211.38,
			213.058,
			215.585,
			216.427,
			217.269,
			219.804,
			221.509,
			224.069,
			225.788,
			228.385,
			230.129,
			231.006,
			232.79,
			234.609,
			235.523,
			237.377,
			239.301,
			242.219,
			243.225,
			244.232,
			246.262,
			248.322,
			249.359,
			252.479,
			254.569,
			256.665,
			259.788,
			261.878,
			264.923,
			265.942,
			266.964,
			269.952,
			271.952,
			274.954,
			276.961,
			278.978,
			279.986,
			282.002,
			283.012,
			285.029,
			287.046,
			288.056,
			290.078,
			292.104,
			295.166,
			297.22,
			300.319,
			301.358,
			302.4,
			305.541,
			307.638,
			309.738,
			312.896,
			314.988,
			318.162,
			319.221,
			320.298,
			323.469,
			324.523,
			325.575,
			328.731,
			330.832,
			332.947,
			334.021,
			336.161,
			338.321,
			339.411,
			342.666,
			344.827,
			346.972,
			350.159,
			352.277,
			355.354,
			356.379,
			357.401,
			360.408,
			362.413,
			365.425,
			367.435,
			370.461,
			372.469,
			373.503,
			375.543,
			378.622,
			379.654,
			382.762,
			384.847,
			386.925,
			387.976,
			390.086,
			392.203,
			393.26,
			395.367,
			396.414,
			398.49,
			400.533,
			403.532,
			405.485,
			408.346,
			409.282,
			410.209,
			412.941,
			414.72,
			417.337,
			419.014,
			421.482,
			423.083,
			423.871,
			425.422,
			427.685,
			429.158,
			430.598,
			432.011,
			433.405,
			434.097,
			436.166,
			436.854,
			437.539,
			438.902,
			439.581,
			440.943,
			442.288,
			444.287,
			445.606,
			447.569,
			448.211,
			448.848,
			450.742,
			452,
			453.912,
			455.187,
			457.094,
			458.486,
			459.228,
			460.709,
			462.189,
			462.928,
			464.399,
			465.867,
			468.05,
			469.445,
			471.533,
			472.232,
			472.944,
			474.403,
			475.133,
			476.598,
			478.077,
			480.329,
			481.839,
			484.074,
			484.813,
			485.553,
			487.784,
			489.205,
			491.353,
			492.796,
			494.209,
			494.919,
			496.347,
			497.788,
			498.516,
			499.98,
			500.717,
			502.201,
			503.709,
			506.004,
			506.777,
			507.554,
			509.916,
			510.711,
			511.51,
			513.93,
			515.562,
			518.037,
			519.7,
			521.377,
			523.904,
			524.744,
			525.583,
			528.949,
			529.802,
			532.376,
			534.112,
			535.863,
			536.743,
			538.515,
			540.302,
			541.2,
			543.022,
			543.931,
			545.755,
			547.582,
			550.328,
			552.15,
			554.861,
			555.756,
			556.649,
			559.309,
			561.07,
			563.702,
			565.447,
			567.188,
			568.056,
			569.778,
			570.636,
			572.35,
			574.9,
			576.589,
			578.277,
			579.981,
			581.677,
			582.522,
			584.229,
			585.937,
			586.789,
			588.496,
			589.359,
			591.082,
			592.802,
			595.438,
			597.197,
			599.852,
			600.739,
			601.627,
			604.297,
			605.19,
			606.084,
			608.767,
			610.541,
			613.214,
			614.987,
			616.764,
			617.658,
			619.468,
			620.379,
			622.211,
			624.055,
			624.98,
			626.84,
			628.706,
			630.564,
			632.433,
			633.372,
			636.2,
			637.148,
			638.1,
			640.011,
			640.97,
			642.896,
			644.829,
			647.729,
			649.662,
			652.552,
			653.511,
			654.471,
			657.324,
			659.199,
			661.955,
			663.75,
			665.498,
			666.359,
			668.056,
			668.892,
			670.537,
			672.148,
			672.942,
			674.514,
			676.06,
			678.337,
			679.843,
			682.08,
			682.822,
			683.564,
			685.803,
			687.296,
			689.564,
			691.094,
			692.629,
			694.974,
			695.764,
			696.555,
			699.739,
			700.542,
			702.952,
			704.554,
			706.15,
			706.947,
			708.541,
			710.118,
			710.906,
			712.479,
			713.266,
			714.841,
			716.413,
			718.77,
			720.351,
			722.718,
			723.506,
			724.299,
			726.676,
			728.258,
			730.626,
			732.199,
			734.551,
			736.108,
			736.885,
			737.661,
			740.743,
			741.508,
			743.802,
			745.317,
			746.826,
			747.579,
			749.083,
			750.577,
			751.321,
			752.808,
			753.55,
			755.034,
			756.511,
			758.719,
			760.193,
			762.395,
			763.128,
			763.862,
			766.064,
			767.529,
			769.726,
			771.19,
			772.651,
			773.381,
			774.843,
			775.573,
			777.03,
			778.485,
			779.213,
			780.672,
			782.128,
			784.31,
			785.039,
			785.768,
			787.957,
			788.69,
			789.427,
			790.909,
			791.653,
			793.148,
			794.657,
			796.947,
			798.486,
			800.823,
			801.608,
			802.396,
			805.59,
			806.399,
			808.846,
			810.511,
			812.193,
			813.039,
			814.741,
			815.597,
			817.318,
			819.053,
			819.926,
			821.68,
			823.448,
			826.13,
			827.935,
			830.665,
			831.581,
			832.499,
			835.27,
			837.128,
			839.937,
			841.823,
			843.721,
			846.579,
			847.537,
			848.498,
			852.355,
			853.325,
			856.241,
			858.195,
			860.155,
			861.135,
			863.103,
			865.081,
			866.071,
			868.061,
			869.06,
			871.072,
			873.097,
			876.165,
			878.229,
			881.352,
			882.4,
			883.446,
			886.59,
			888.696,
			891.861,
			893.995,
			896.138,
			897.214,
			899.372,
			900.453,
			902.621,
			904.799,
			905.891,
			906.984,
			910.28,
			912.486,
			914.702,
			915.812,
			918.039,
			920.275,
			921.394,
			923.64,
			924.766,
			927.027,
			929.297,
			932.729,
			935.031,
			938.509,
			939.673,
			940.84,
			944.356,
			946.712,
			950.264,
			952.643,
			955.032,
			956.231,
			958.636,
			959.841,
			962.26,
			964.687,
			965.905,
			968.348,
			970.8,
			973.263,
			976.97,
			980.693,
			981.938,
			983.185,
			986.939,
			989.452,
			991.972,
			995.769,
			998.311,
			1002.141,
			1003.422,
			1004.704,
			1008.562,
			1011.143,
			1015.027,
			1017.629,
			1021.537,
			1024.146,
			1025.453,
			1028.073,
			1030.701,
			1032.017,
			1034.656,
			1037.301,
			1041.281,
			1042.612,
			1043.944,
			1047.949,
			1049.288,
			1050.628,
			1054.659,
			1057.355,
			1061.413,
			1064.126,
			1066.847,
			1068.21,
			1070.941,
			1072.308,
			1073.678,
			1077.795,
			1079.17,
			1080.547,
			1084.683,
			1087.447,
			1090.215,
			1091.601,
			1094.377,
			1097.161,
			1098.555,
			1101.35,
			1102.751,
			1105.557,
			1108.37,
			1112.6,
			1115.426,
			1119.659,
			1121.077,
			1122.504,
			1126.793,
			1129.661,
			1133.979,
			1136.876,
			1136.958
		],
		[
			0,
			0.01,
			0.041,
			0.071,
			0.134,
			0.195,
			0.256,
			0.321,
			0.365,
			0.443,
			0.474,
			0.505,
			0.567,
			0.583,
			0.63,
			0.691,
			0.752,
			0.817,
			0.878,
			0.908,
			0.941,
			1.002,
			1.018,
			1.063,
			1.126,
			1.189,
			1.234,
			1.312,
			1.341,
			1.372,
			1.435,
			1.499,
			1.561,
			1.621,
			1.667,
			1.684,
			1.747,
			1.777,
			1.808,
			1.89,
			1.931,
			1.993,
			2.055,
			2.103,
			2.117,
			2.181,
			2.212,
			2.243,
			2.304,
			2.321,
			2.367,
			2.428,
			2.489,
			2.537,
			2.614,
			2.646,
			2.677,
			2.737,
			2.799,
			2.861,
			2.923,
			2.987,
			3.047,
			3.078,
			3.111,
			3.172,
			3.188,
			3.235,
			3.296,
			3.359,
			3.404,
			3.419,
			3.483,
			3.514,
			3.543,
			3.623,
			3.667,
			3.73,
			3.793,
			3.839,
			3.915,
			3.95,
			3.979,
			4.056,
			4.101,
			4.164,
			4.226,
			4.272,
			4.288,
			4.35,
			4.381,
			4.412,
			4.489,
			4.535,
			4.599,
			4.661,
			4.706,
			4.721,
			4.785,
			4.814,
			4.847,
			4.922,
			4.97,
			5.031,
			5.095,
			5.14,
			5.219,
			5.25,
			5.282,
			5.358,
			5.406,
			5.47,
			5.528,
			5.589,
			5.653,
			5.684,
			5.714,
			5.777,
			5.791,
			5.838,
			5.901,
			5.963,
			6.015,
			6.086,
			6.117,
			6.15,
			6.227,
			6.273,
			6.335,
			6.397,
			6.443,
			6.52,
			6.551,
			6.582,
			6.661,
			6.706,
			6.769,
			6.831,
			6.877,
			6.892,
			6.956,
			6.986,
			7.017,
			7.077,
			7.094,
			7.142,
			7.203,
			7.265,
			7.327,
			7.388,
			7.42,
			7.45,
			7.529,
			7.574,
			7.636,
			7.699,
			7.761,
			7.823,
			7.853,
			7.885,
			7.948,
			7.963,
			8.01,
			8.077,
			8.133,
			8.179,
			8.195,
			8.256,
			8.288,
			8.32,
			8.395,
			8.441,
			8.504,
			8.567,
			8.63,
			8.691,
			8.723,
			8.752,
			8.815,
			8.877,
			8.94,
			9.002,
			9.046,
			9.063,
			9.123,
			9.155,
			9.188,
			9.25,
			9.264,
			9.311,
			9.372,
			9.434,
			9.483,
			9.56,
			9.591,
			9.622,
			9.683,
			9.745,
			9.807,
			9.87,
			9.932,
			9.994,
			10.024,
			10.056,
			10.118,
			10.138,
			10.178,
			10.243,
			10.304,
			10.349,
			10.366,
			10.428,
			10.459,
			10.489,
			10.566,
			10.613,
			10.677,
			10.738,
			10.799,
			10.861,
			10.893,
			10.924,
			10.985,
			11.048,
			11.111,
			11.173,
			11.233,
			11.296,
			11.327,
			11.358,
			11.435,
			11.482,
			11.544,
			11.605,
			11.652,
			11.668,
			11.731,
			11.761,
			11.791,
			11.853,
			11.87,
			11.916,
			11.978,
			12.039,
			12.087,
			12.164,
			12.198,
			12.227,
			12.286,
			12.349,
			12.417,
			12.473,
			12.536,
			12.598,
			12.628,
			12.661,
			12.737,
			12.783,
			12.847,
			12.907,
			12.953,
			12.969,
			13.033,
			13.064,
			13.094,
			13.157,
			13.171,
			13.218,
			13.281,
			13.34,
			13.404,
			13.465,
			13.498,
			13.528,
			13.605,
			13.652,
			13.714,
			13.777,
			13.837,
			13.899,
			13.931,
			13.961,
			14.023,
			14.04,
			14.085,
			14.147,
			14.21,
			14.261,
			14.335,
			14.364,
			14.396,
			14.456,
			14.472,
			14.521,
			14.581,
			14.644,
			14.691,
			14.767,
			14.798,
			14.83,
			14.89,
			14.954,
			15.015,
			15.078,
			15.124,
			15.14,
			15.201,
			15.234,
			15.264,
			15.327,
			15.341,
			15.388,
			15.45,
			15.511,
			15.557,
			15.576,
			15.637,
			15.668,
			15.699,
			15.76,
			15.823,
			15.885,
			15.947,
			15.993,
			16.071,
			16.1,
			16.131,
			16.213,
			16.256,
			16.323,
			16.38,
			16.427,
			16.443,
			16.506,
			16.537,
			16.567,
			16.627,
			16.645,
			16.691,
			16.753,
			16.813,
			16.861,
			16.937,
			16.97,
			17,
			17.078,
			17.126,
			17.188,
			17.249,
			17.295,
			17.31,
			17.373,
			17.403,
			17.434,
			17.512,
			17.559,
			17.62,
			17.682,
			17.73,
			17.746,
			17.807,
			17.837,
			17.87,
			17.93,
			17.946,
			17.994,
			18.055,
			18.118,
			18.162,
			18.239,
			18.273,
			18.304,
			18.364,
			18.392,
			18.427,
			18.489,
			18.55,
			18.613,
			18.672,
			18.72,
			18.737,
			18.799,
			18.83,
			18.861,
			18.922,
			18.939,
			18.985,
			19.048,
			19.107,
			19.154,
			19.172,
			19.234,
			19.265,
			19.296,
			19.355,
			19.373,
			19.419,
			19.482,
			19.543,
			19.59,
			19.667,
			19.699,
			19.73,
			19.79,
			19.853,
			19.915,
			19.977,
			20.023,
			20.039,
			20.1,
			20.131,
			20.164,
			20.225,
			20.241,
			20.288,
			20.35,
			20.412,
			20.472,
			20.535,
			20.566,
			20.596,
			20.659,
			20.722,
			20.783,
			20.846,
			20.892,
			20.97,
			21.001,
			21.031,
			21.109,
			21.156,
			21.216,
			21.279,
			21.327,
			21.341,
			21.403,
			21.435,
			21.466,
			21.527,
			21.542,
			21.588,
			21.653,
			21.714,
			21.759,
			21.836,
			21.867,
			21.9,
			21.961,
			22.023,
			22.086,
			22.147,
			22.21,
			22.273,
			22.302,
			22.332,
			22.41,
			22.455,
			22.523,
			22.58,
			22.628,
			22.643,
			22.706,
			22.737,
			22.767,
			22.829,
			22.845,
			22.892,
			22.953,
			23.015,
			23.062,
			23.141,
			23.172,
			23.2,
			23.28,
			23.326,
			23.388,
			23.45,
			23.497,
			23.511,
			23.572,
			23.604,
			23.637,
			23.699,
			23.712,
			23.761,
			23.826,
			23.885,
			23.929,
			23.944,
			24.008,
			24.038,
			24.069,
			24.13,
			24.147,
			24.194,
			24.254,
			24.319,
			24.363,
			24.443,
			24.472,
			24.505,
			24.585,
			24.629,
			24.688,
			24.753,
			24.799,
			24.813,
			24.874,
			24.906,
			24.937,
			25.001,
			25.016,
			25.062,
			25.123,
			25.185,
			25.234,
			25.31,
			25.341,
			25.373,
			25.45,
			25.497,
			25.56,
			25.62,
			25.665,
			25.744,
			25.776,
			25.806,
			25.883,
			25.931,
			25.991,
			26.055,
			26.099,
			26.116,
			26.178,
			26.21,
			26.24,
			26.301,
			26.319,
			26.363,
			26.428,
			26.488,
			26.535,
			26.612,
			26.647,
			26.675,
			26.737,
			26.799,
			26.861,
			26.923,
			26.969,
			26.985,
			27.045,
			27.077,
			27.109,
			27.17,
			27.185,
			27.231,
			27.294,
			27.356,
			27.402,
			27.419,
			27.481,
			27.51,
			27.542,
			27.604,
			27.621,
			27.667,
			27.729,
			27.789,
			27.838,
			27.915,
			27.946,
			27.978,
			28.04,
			28.099,
			28.164,
			28.223,
			28.27,
			28.287,
			28.348,
			28.379,
			28.411,
			28.472,
			28.488,
			28.534,
			28.597,
			28.657,
			28.71,
			28.783,
			28.815,
			28.844,
			28.921,
			28.967,
			29.03,
			29.091,
			29.14,
			29.217,
			29.249,
			29.278,
			29.34,
			29.403,
			29.464,
			29.527,
			29.588,
			29.65,
			29.68,
			29.714,
			29.775,
			29.79,
			29.837,
			29.898,
			29.961,
			30.006,
			30.023,
			30.085,
			30.115,
			30.147,
			30.21,
			30.27,
			30.333,
			30.396,
			30.441,
			30.458,
			30.518,
			30.549,
			30.58,
			30.643,
			30.659,
			30.706,
			30.767,
			30.828,
			30.875,
			30.891,
			30.953,
			30.983,
			31.015,
			31.078,
			31.091,
			31.138,
			31.2,
			31.262,
			31.31,
			31.388,
			31.417,
			31.448,
			31.527,
			31.573,
			31.637,
			31.696,
			31.695
		]
	],
	'1692_1693_7982': [
		[
			101.81
		],
		[
			0
		],
		[
			0,
			1.179,
			5.355,
			8.148,
			10.948,
			12.35,
			15.16,
			16.567,
			19.388,
			22.215,
			23.631,
			26.468,
			29.312,
			33.59,
			35.019,
			36.45,
			40.753,
			42.191,
			43.63,
			47.957,
			50.851,
			53.75,
			58.111,
			61.027,
			65.412,
			66.877,
			68.343,
			72.752,
			75.7,
			80.133,
			83.096,
			86.065,
			87.551,
			90.53,
			93.515,
			95.01,
			99.502,
			102.504,
			105.512,
			110.036,
			113.059,
			117.603,
			119.121,
			120.64,
			125.207,
			128.259,
			132.847,
			135.912,
			138.983,
			140.52,
			143.599,
			145.139,
			146.68,
			152.82,
			154.343,
			158.873,
			161.846,
			164.779,
			166.23,
			169.102,
			171.937,
			173.34,
			176.119,
			177.496,
			180.222,
			182.923,
			186.918,
			189.546,
			193.417,
			194.688,
			195.947,
			199.662,
			202.091,
			205.665,
			207.995,
			211.417,
			213.663,
			214.763,
			216.935,
			219.077,
			220.14,
			222.247,
			224.337,
			226.411,
			229.497,
			231.54,
			233.563,
			234.573,
			236.582,
			237.579,
			239.57,
			241.569,
			244.585,
			246.617,
			249.748,
			250.792,
			251.881,
			255.186,
			257.41,
			260.767,
			263.01,
			266.378,
			268.586,
			269.69,
			271.879,
			275.1,
			277.248,
			279.39,
			282.589,
			283.645,
			284.701,
			287.825,
			288.859,
			289.893,
			293.021,
			295.15,
			297.281,
			300.532,
			302.71,
			305.989,
			307.085,
			308.178,
			311.464,
			313.663,
			316.971,
			319.199,
			322.558,
			324.812,
			327.072,
			328.206,
			330.479,
			331.619,
			333.903,
			336.177,
			339.631,
			341.945,
			345.425,
			346.589,
			347.755,
			351.261,
			353.604,
			357.128,
			359.481,
			361.841,
			363.026,
			365.405,
			366.598,
			368.992,
			371.396,
			372.601,
			375.019,
			377.446,
			379.884,
			382.331,
			383.557,
			386.011,
			388.474,
			389.708,
			393.422,
			395.907,
			398.401,
			402.155,
			404.673,
			408.471,
			409.744,
			411.02,
			414.858,
			417.426,
			421.285,
			423.866,
			426.452,
			427.747,
			430.331,
			431.622,
			434.209,
			436.805,
			438.105,
			439.408,
			443.338,
			445.972,
			448.615,
			449.938,
			452.584,
			455.236,
			456.563,
			459.221,
			460.555,
			463.228,
			465.91,
			469.953,
			472.66,
			476.732,
			478.093,
			479.456,
			483.549,
			486.285,
			490.401,
			493.155,
			497.3,
			500.074,
			501.464,
			504.25,
			507.043,
			508.443,
			511.247,
			514.058,
			518.288,
			519.702,
			521.117,
			525.366,
			526.786,
			528.206,
			532.477,
			535.333,
			538.196,
			542.504,
			545.385,
			549.72,
			551.169,
			552.619,
			556.978,
			559.892,
			564.275,
			567.204,
			570.142,
			571.613,
			574.563,
			577.52,
			579.001,
			581.967,
			583.452,
			586.425,
			589.403,
			593.879,
			596.869,
			601.366,
			602.867,
			604.371,
			608.889,
			611.908,
			616.448,
			619.482,
			622.521,
			624.042,
			627.09,
			628.616,
			630.143,
			634.734,
			636.267,
			637.801,
			642.411,
			645.492,
			648.577,
			650.121,
			653.215,
			656.314,
			657.865,
			662.525,
			665.639,
			668.758,
			673.447,
			676.58,
			681.294,
			682.869,
			684.442,
			689.142,
			692.245,
			696.83,
			699.837,
			702.806,
			704.276,
			707.188,
			708.63,
			711.485,
			715.692,
			718.448,
			721.164,
			723.844,
			726.486,
			727.792,
			730.377,
			732.922,
			734.18,
			737.895,
			740.324,
			742.717,
			746.24,
			748.543,
			751.923,
			753.031,
			754.129,
			757.368,
			759.479,
			762.575,
			764.587,
			766.561,
			767.535,
			769.453,
			770.399,
			772.263,
			774.095,
			774.999,
			776.782,
			778.533,
			781.093,
			781.928,
			782.754,
			785.178,
			785.968,
			786.749,
			788.283,
			789.038,
			790.519,
			791.976,
			794.079,
			795.44,
			797.429,
			798.08,
			798.724,
			800.618,
			801.24,
			801.857,
			803.683,
			804.875,
			806.059,
			806.65,
			807.83,
			809.564,
			810.71,
			811.283,
			812.429,
			813.587,
			815.351,
			816.526,
			818.303,
			818.932,
			819.56,
			821.445,
			822.704,
			824.692,
			826.044,
			827.396,
			828.073,
			829.503,
			830.228,
			830.953,
			833.126,
			833.871,
			834.629,
			836.897,
			838.409,
			840.012,
			840.817,
			842.423,
			844.026,
			844.82,
			846.404,
			847.196,
			848.769,
			850.211,
			852.375,
			853.823,
			855.837,
			856.51,
			857.183,
			859.207,
			860.527,
			862.517,
			863.854,
			865.189,
			865.858,
			867.199,
			867.872,
			869.225,
			870.583,
			871.264,
			871.947,
			874.011,
			875.414,
			877.544,
			878.974,
			880.417,
			881.143,
			882.598,
			883.327,
			884.792,
			886.257,
			888.467,
			889.951,
			892.19,
			892.941,
			893.694,
			895.972,
			897.506,
			899.83,
			901.401,
			902.974,
			903.773,
			905.383,
			906.195,
			907.831,
			909.481,
			910.312,
			911.98,
			913.648,
			916.168,
			917.018,
			917.871,
			920.451,
			921.319,
			922.191,
			924.827,
			926.602,
			929.291,
			931.102,
			932.948,
			935.72,
			936.659,
			937.608,
			940.473,
			942.427,
			945.408,
			947.436,
			949.497,
			950.531,
			952.649,
			954.818,
			955.906,
			958.108,
			959.219,
			961.453,
			963.693,
			967.064,
			969.344,
			972.775,
			973.917,
			975.06,
			978.463,
			980.702,
			984.004,
			986.174,
			988.319,
			991.493,
			992.552,
			993.596,
			996.726,
			998.808,
			1001.935,
			1004.026,
			1006.122,
			1007.173,
			1009.292,
			1011.422,
			1012.491,
			1015.729,
			1017.899,
			1020.078,
			1023.362,
			1025.555,
			1028.863,
			1029.972,
			1031.083,
			1034.438,
			1036.698,
			1040.116,
			1042.416,
			1044.728,
			1045.889,
			1048.217,
			1049.384,
			1051.725,
			1055.253,
			1056.433,
			1059.966,
			1062.342,
			1064.727,
			1065.921,
			1068.316,
			1070.719,
			1071.944,
			1074.34,
			1075.552,
			1077.985,
			1081.649,
			1084.102,
			1086.562,
			1090.263,
			1091.498,
			1092.734,
			1096.45,
			1098.928,
			1102.66,
			1105.158,
			1107.664,
			1108.92,
			1111.438,
			1113.966,
			1115.233,
			1117.776,
			1119.052,
			1121.61,
			1124.178,
			1128.046,
			1129.341,
			1130.637,
			1134.529,
			1135.83,
			1137.133,
			1139.748,
			1141.058,
			1143.686,
			1146.322,
			1150.292,
			1152.948,
			1156.948,
			1158.285,
			1159.623,
			1163.648,
			1166.341,
			1170.393,
			1173.102,
			1175.818,
			1177.177,
			1179.902,
			1181.267,
			1184.002,
			1186.744,
			1188.117,
			1190.869,
			1193.627,
			1197.777,
			1199.163,
			1200.552,
			1204.726,
			1206.121,
			1207.518,
			1211.718,
			1214.528,
			1218.755,
			1221.582,
			1224.417,
			1228.683,
			1230.109,
			1231.536,
			1237.261,
			1238.697,
			1243.015,
			1245.903,
			1248.798,
			1250.248,
			1253.153,
			1256.064,
			1257.522,
			1260.444,
			1261.907,
			1264.838,
			1267.776,
			1272.195,
			1275.149,
			1279.59,
			1281.073,
			1282.558,
			1287.021,
			1290.004,
			1294.489,
			1297.486,
			1300.488,
			1301.992,
			1305.004,
			1306.512,
			1309.533,
			1312.56,
			1314.075,
			1315.592,
			1320.152,
			1323.199,
			1327.779,
			1330.838,
			1333.903,
			1335.438,
			1338.511,
			1340.051,
			1343.136,
			1347.777,
			1350.881,
			1353.992,
			1358.67,
			1360.232,
			1361.796,
			1366.495,
			1369.641,
			1374.37,
			1377.53,
			1380.702,
			1382.29,
			1385.469,
			1387.06,
			1390.249,
			1393.445,
			1395.044,
			1398.247,
			1401.455,
			1406.275,
			1407.885,
			1409.494,
			1414.311,
			1415.917,
			1417.524,
			1422.345,
			1425.56,
			1428.777,
			1433.608,
			1436.833,
			1441.679,
			1443.297,
			1444.915,
			1449.782,
			1453.031,
			1457.915,
			1461.176,
			1464.444,
			1466.08,
			1469.357,
			1470.998,
			1474.285,
			1479.225,
			1482.525,
			1485.83,
			1490.791,
			1494.1,
			1499.068,
			1500.724,
			1502.381,
			1507.359,
			1510.682,
			1515.677,
			1519.013,
			1522.355,
			1527.375,
			1529.05,
			1530.727,
			1535.761,
			1537.441,
			1539.121,
			1544.173,
			1547.545,
			1552.609,
			1555.99,
			1559.376,
			1561.069,
			1566.152,
			1569.544,
			1572.938,
			1578.036,
			1581.439,
			1586.55,
			1588.256,
			1589.962,
			1595.085,
			1598.505,
			1603.639,
			1607.067,
			1610.498,
			1612.215,
			1615.653,
			1617.374,
			1620.819,
			1624.269,
			1625.996,
			1629.453,
			1632.914,
			1638.115,
			1639.85,
			1641.587,
			1645.062,
			1648.541,
			1650.28,
			1655.5,
			1658.981,
			1662.463,
			1667.694,
			1671.185,
			1676.427,
			1678.176,
			1679.926,
			1685.179,
			1688.686,
			1693.954,
			1697.47,
			1700.99,
			1702.75,
			1706.273,
			1708.035,
			1711.56,
			1715.089,
			1716.854,
			1720.386,
			1723.923,
			1729.233,
			1732.778,
			1738.1,
			1739.875,
			1741.651,
			1746.982,
			1750.541,
			1754.102,
			1759.452,
			1763.023,
			1768.385,
			1770.174,
			1771.964,
			1777.341,
			1780.931,
			1786.325,
			1789.924,
			1793.524,
			1795.325,
			1798.927,
			1800.729,
			1804.334,
			1807.943,
			1809.747,
			1813.359,
			1816.974,
			1822.403,
			1824.214,
			1826.025,
			1831.464,
			1833.278,
			1835.093,
			1840.542,
			1844.177,
			1849.636,
			1853.279,
			1856.925,
			1862.397,
			1864.223,
			1866.049,
			1871.532,
			1873.361,
			1875.19,
			1880.683,
			1884.347,
			1889.846,
			1893.512,
			1897.17,
			1898.993,
			1902.621,
			1904.424,
			1908.003,
			1911.543,
			1916.779,
			1920.219,
			1925.307,
			1926.984,
			1928.651,
			1933.592,
			1936.836,
			1941.631,
			1944.779,
			1947.889,
			1949.428,
			1952.479,
			1953.991,
			1956.988,
			1959.948,
			1961.414,
			1962.871,
			1967.182,
			1970.007,
			1972.794,
			1974.174,
			1976.903,
			1979.594,
			1980.926,
			1983.559,
			1984.862,
			1987.439,
			1989.977,
			1993.713,
			1996.157,
			1999.755,
			2000.937,
			2002.11,
			2005.575,
			2007.834,
			2011.152,
			2013.315,
			2015.441,
			2016.49,
			2018.559,
			2019.579,
			2021.592,
			2023.567,
			2024.54,
			2026.46,
			2028.345,
			2031.104,
			2032.008,
			2032.902,
			2035.535,
			2036.394,
			2037.245,
			2038.917,
			2039.74,
			2041.355,
			2042.926,
			2045.214,
			2046.688,
			2048.821,
			2049.514,
			2050.198,
			2052.205,
			2053.503,
			2055.388,
			2056.606,
			2057.795,
			2058.376,
			2059.511,
			2060.066,
			2061.157,
			2062.211,
			2062.725,
			2063.725,
			2064.696,
			2066.102,
			2067.003,
			2068.303,
			2068.726,
			2069.146,
			2070.388,
			2071.209,
			2072.386,
			2073.143,
			2073.893,
			2074.267,
			2075.013,
			2075.387,
			2075.761,
			2076.902,
			2077.297,
			2077.69,
			2078.86,
			2079.638,
			2080.419,
			2080.812,
			2081.604,
			2082.46,
			2082.925,
			2083.85,
			2084.311,
			2085.235,
			2086.162,
			2087.611,
			2088.665,
			2090.238,
			2090.761,
			2091.286,
			2093.038,
			2094.456,
			2096.575,
			2098.161,
			2100.98,
			2102.788,
			2103.525,
			2104.988,
			2106.443,
			2107.168,
			2108.265,
			2109.179,
			2110.537,
			2110.987,
			2111.438,
			2112.342,
			2113.211,
			2113.62,
			2114.432,
			2114.838,
			2115.649,
			2116.462,
			2117.695,
			2118.569,
			2119.919,
			2120.37,
			2120.823,
			2122.194,
			2123.123,
			2124.63,
			2125.648,
			2126.679,
			2127.199,
			2128.252,
			2128.803,
			2129.926,
			2131.639,
			2132.799,
			2133.981,
			2135.786,
			2136.391,
			2136.998,
			2138.848,
			2139.474,
			2140.103,
			2141.993,
			2143.252,
			2144.523,
			2146.454,
			2147.753,
			2149.749,
			2150.421,
			2151.098,
			2153.154,
			2154.544,
			2156.667,
			2158.093,
			2159.531,
			2160.243,
			2161.698,
			2162.43,
			2163.906,
			2165.397,
			2166.148,
			2167.662,
			2169.191,
			2171.524,
			2172.304,
			2173.087,
			2175.441,
			2176.246,
			2177.039,
			2179.437,
			2181.037,
			2183.495,
			2185.148,
			2186.816,
			2189.347,
			2190.193,
			2191.039,
			2193.591,
			2194.449,
			2195.31,
			2197.916,
			2199.672,
			2202.329,
			2204.117,
			2205.917,
			2206.823,
			2208.642,
			2209.556,
			2211.395,
			2213.246,
			2216.048,
			2217.932,
			2220.782,
			2221.739,
			2222.699,
			2225.597,
			2227.545,
			2230.49,
			2232.469,
			2234.459,
			2235.459,
			2237.467,
			2238.476,
			2239.487,
			2242.539,
			2243.562,
			2244.588,
			2247.682,
			2249.759,
			2251.847,
			2252.896,
			2254.995,
			2257.093,
			2258.143,
			2260.251,
			2261.31,
			2263.433,
			2265.566,
			2268.787,
			2270.948,
			2274.211,
			2275.304,
			2276.399,
			2279.702,
			2281.916,
			2285.255,
			2287.493,
			2290.868,
			2293.129,
			2294.263,
			2296.539,
			2298.824,
			2299.97,
			2302.269,
			2304.579,
			2308.06,
			2309.226,
			2310.394,
			2313.911,
			2315.088,
			2316.268,
			2318.635,
			2319.822,
			2322.203,
			2324.593,
			2328.195,
			2330.609,
			2334.247,
			2335.464,
			2336.684,
			2340.356,
			2342.815,
			2346.521,
			2349.002,
			2351.493,
			2352.742,
			2355.246,
			2356.501,
			2359.018,
			2362.809,
			2365.347,
			2367.894,
			2371.73,
			2374.297,
			2378.163,
			2379.455,
			2380.749,
			2384.644,
			2387.25,
			2391.16,
			2393.773,
			2396.394,
			2400.339,
			2401.657,
			2402.977,
			2406.949,
			2408.276,
			2409.605,
			2413.605,
			2416.281,
			2418.962,
			2420.306,
			2423,
			2425.7,
			2427.053,
			2429.764,
			2431.122,
			2433.843,
			2436.572,
			2440.679,
			2443.426,
			2447.558,
			2448.938,
			2450.318,
			2454.481,
			2457.238,
			2461.369,
			2464.12,
			2466.865,
			2468.237,
			2470.953,
			2472.32,
			2475.051,
			2477.776,
			2479.162,
			2480.52,
			2484.597,
			2487.312,
			2490.034,
			2491.396,
			2494.124,
			2496.868,
			2498.245,
			2501.011,
			2502.407,
			2505.203,
			2508.016,
			2512.229,
			2515.087,
			2519.359,
			2520.785,
			2522.231,
			2526.583,
			2529.54,
			2534.003,
			2536.994,
			2541.491,
			2544.5,
			2546.008,
			2549.024,
			2552.012,
			2553.517,
			2556.487,
			2559.467,
			2563.829,
			2566.746,
			2569.639,
			2572.49,
			2573.93,
			2578.235,
			2581.112,
			2584.021,
			2588.39,
			2591.331,
			2595.789,
			2597.284,
			2598.78,
			2603.271,
			2606.266,
			2610.746,
			2613.744,
			2616.748,
			2618.257,
			2621.279,
			2622.793,
			2625.827,
			2628.863,
			2630.382,
			2633.419,
			2636.457,
			2641.013,
			2642.507,
			2644.052,
			2648.599,
			2650.127,
			2651.682,
			2656.269,
			2659.327,
			2663.882,
			2666.939,
			2669.999,
			2674.594,
			2676.125,
			2677.655,
			2682.216,
			2683.723,
			2685.223,
			2689.669,
			2692.586,
			2695.464,
			2696.888,
			2699.704,
			2702.481,
			2703.854,
			2706.571,
			2707.915,
			2710.577,
			2713.202,
			2717.067,
			2719.596,
			2723.322,
			2724.548,
			2725.765,
			2729.368,
			2731.73,
			2735.208,
			2737.479,
			2740.819,
			2743,
			2744.077,
			2745.145,
			2749.321,
			2750.343,
			2753.354,
			2755.316,
			2757.242,
			2758.191,
			2760.062,
			2761.899,
			2762.805,
			2764.591,
			2765.472,
			2767.208,
			2768.907,
			2771.389,
			2772.999,
			2775.357,
			2776.123,
			2776.88,
			2779.098,
			2780.537,
			2782.644,
			2784.017,
			2785.369,
			2786.037,
			2787.355,
			2788.008,
			2789.302,
			2790.584,
			2791.219,
			2792.474,
			2793.722,
			2794.965,
			2796.82,
			2798.639,
			2799.245,
			2799.851,
			2801.677,
			2802.95,
			2804.221,
			2806.134,
			2807.514,
			2809.706,
			2810.439,
			2811.175,
			2813.66,
			2815.392,
			2818.058,
			2819.883,
			2821.713,
			2822.531,
			2824.048,
			2824.807,
			2826.325,
			2827.772,
			2828.436,
			2829.763,
			2831.091,
			2833.088,
			2834.422,
			2836.425,
			2837.094,
			2837.764,
			2839.789,
			2841.139,
			2843.172,
			2844.539,
			2845.907,
			2847.966,
			2848.651,
			2849.332,
			2851.38,
			2852.065,
			2852.752,
			2854.826,
			2856.21,
			2857.594,
			2858.285,
			2859.688,
			2861.108,
			2861.821,
			2863.25,
			2863.968,
			2865.41,
			2866.854,
			2869.027,
			2870.482,
			2872.672,
			2873.404,
			2874.137,
			2876.366,
			2877.868,
			2880.137,
			2881.669,
			2883.208,
			2883.981,
			2885.535,
			2886.318,
			2887.101,
			2890.257,
			2891.052,
			2893.448,
			2895.055,
			2897.472,
			2899.092,
			2900.711,
			2901.52,
			2903.143,
			2903.958,
			2905.586,
			2907.242,
			2909.755,
			2911.447,
			2914.036,
			2914.908,
			2915.783,
			2918.451,
			2920.264,
			2923.017,
			2924.887,
			2926.768,
			2927.714,
			2929.617,
			2930.569,
			2932.475,
			2934.373,
			2935.319,
			2937.223,
			2939.099,
			2941.889,
			2942.813,
			2943.731,
			2945.56,
			2947.382,
			2948.29,
			2950.104,
			2951.007,
			2952.809,
			2954.618,
			2957.329,
			2959.135,
			2961.864,
			2962.77,
			2963.676,
			2966.419,
			2967.331,
			2968.242,
			2971.004,
			2972.85,
			2974.704,
			2975.645,
			2977.527,
			2978.469,
			2980.365,
			2982.275,
			2983.23,
			2985.14,
			2987.043,
			2989.898,
			2990.851,
			2991.802,
			2993.705,
			2995.608,
			2996.568,
			2998.489,
			2999.45,
			3001.377,
			3004.288,
			3006.232,
			3008.158,
			3011.048,
			3012.013,
			3012.977,
			3015.869,
			3017.797,
			3020.672,
			3022.591,
			3024.471,
			3025.408,
			3027.286,
			3029.159,
			3030.09,
			3031.959,
			3032.898,
			3034.779,
			3036.669,
			3039.524,
			3041.439,
			3044.334,
			3045.303,
			3046.274,
			3049.207,
			3051.175,
			3054.147,
			3056.14,
			3059.147,
			3061.166,
			3062.179,
			3063.195,
			3066.24,
			3067.264,
			3068.29,
			3071.397,
			3073.459,
			3075.526,
			3076.562,
			3078.644,
			3080.735,
			3081.768,
			3083.874,
			3084.947,
			3087.069,
			3089.202,
			3092.42,
			3094.58,
			3097.839,
			3098.932,
			3100.027,
			3103.31,
			3105.531,
			3108.881,
			3111.12,
			3113.368,
			3114.495,
			3116.758,
			3117.893,
			3120.186,
			3122.467,
			3123.611,
			3125.885,
			3128.202,
			3130.511,
			3132.809,
			3133.99,
			3137.478,
			3138.65,
			3139.841,
			3143.374,
			3145.722,
			3148.097,
			3151.673,
			3154.07,
			3157.686,
			3158.896,
			3160.108,
			3163.76,
			3166.206,
			3169.892,
			3172.381,
			3174.854,
			3176.091,
			3178.567,
			3179.784,
			3182.277,
			3184.753,
			3185.997,
			3188.487,
			3190.976,
			3194.706,
			3197.192,
			3200.919,
			3202.159,
			3203.399,
			3207.115,
			3209.588,
			3213.294,
			3215.761,
			3218.223,
			3221.911,
			3223.119,
			3224.347,
			3229.294,
			3230.509,
			3234.23,
			3236.722,
			3240.475,
			3242.987,
			3245.508,
			3246.772,
			3249.305,
			3250.575,
			3253.122,
			3255.677,
			3259.531,
			3262.111,
			3265.999,
			3267.299,
			3268.601,
			3272.517,
			3275.126,
			3279.048,
			3281.67,
			3285.617,
			3288.257,
			3289.58,
			3290.905,
			3296.221,
			3297.554,
			3301.565,
			3304.248,
			3308.285,
			3310.985,
			3313.692,
			3315.048,
			3317.766,
			3319.126,
			3321.851,
			3324.581,
			3328.673,
			3331.39,
			3335.432,
			3336.766,
			3338.092,
			3342.016,
			3343.304,
			3344.584,
			3348.368,
			3350.843,
			3353.281,
			3354.485,
			3356.868,
			3358.047,
			3360.378,
			3362.673,
			3363.807,
			3366.032,
			3368.239,
			3370.376,
			3373.5,
			3375.538,
			3377.532,
			3378.523,
			3380.498,
			3381.481,
			3383.439,
			3385.393,
			3388.34,
			3390.302,
			3393.259,
			3394.245,
			3395.232,
			3398.18,
			3400.146,
			3403.098,
			3405.069,
			3407.049,
			3408.044,
			3410.037,
			3411.035,
			3413.046,
			3415.067,
			3416.08,
			3418.115,
			3420.164,
			3423.245,
			3425.318,
			3428.425,
			3429.479,
			3430.533,
			3432.637,
			3433.692,
			3435.827,
			3439.028,
			3441.179,
			3443.33,
			3446.539,
			3447.608,
			3448.679,
			3452.99,
			3454.071,
			3457.336,
			3459.518,
			3462.807,
			3465,
			3467.189,
			3468.282,
			3470.465,
			3471.562,
			3473.751,
			3475.935,
			3479.218,
			3481.4,
			3484.63,
			3485.703,
			3486.776,
			3489.918,
			3492.009,
			3495.106,
			3497.175,
			3499.24,
			3502.351,
			3503.395,
			3504.442,
			3507.597,
			3508.654,
			3509.715,
			3512.91,
			3515.049,
			3518.273,
			3520.437,
			3522.612,
			3523.703,
			3525.907,
			3527.014,
			3529.235,
			3531.474,
			3534.845,
			3537.103,
			3540.491,
			3541.624,
			3542.759,
			3546.166,
			3548.441,
			3551.854,
			3554.13,
			3556.39,
			3557.531,
			3559.817,
			3560.964,
			3563.261,
			3565.567,
			3566.743,
			3569.04,
			3571.381,
			3573.706,
			3576.01,
			3577.171,
			3579.511,
			3581.834,
			3582.98,
			3585.318,
			3586.508,
			3588.857,
			3591.195,
			3594.749,
			3597.132,
			3600.724,
			3601.926,
			3603.131,
			3606.759,
			3609.19,
			3612.854,
			3615.308,
			3617.771,
			3619.006,
			3621.483,
			3622.725,
			3625.215,
			3628.967,
			3631.479,
			3634,
			3637.796,
			3640.338,
			3644.167,
			3645.447,
			3646.729,
			3649.3,
			3650.589,
			3653.172,
			3655.76,
			3659.639,
			3662.214,
			3666.034,
			3667.292,
			3668.542,
			3672.237,
			3674.651,
			3678.201,
			3680.521,
			3682.803,
			3683.93,
			3686.156,
			3687.255,
			3689.43,
			3691.574,
			3692.633,
			3694.724,
			3696.779,
			3699.796,
			3701.763,
			3704.644,
			3705.586,
			3706.519,
			3709.264,
			3711.054,
			3713.674,
			3715.377,
			3717.045,
			3719.492,
			3720.285,
			3721.068,
			3723.365,
			3724.116,
			3724.861,
			3727.032,
			3728.447,
			3729.844,
			3730.536,
			3731.904,
			3733.259,
			3733.933,
			3735.276,
			3735.954,
			3737.309,
			3738.659,
			3740.68,
			3742.041,
			3744.079,
			3744.758,
			3745.438,
			3747.513,
			3748.9,
			3750.986,
			3752.4,
			3754.524,
			3755.945,
			3756.659,
			3758.094,
			3759.53,
			3760.249,
			3761.693,
			3763.159,
			3764.628,
			3766.104,
			3766.844,
			3768.373,
			3769.909,
			3770.68,
			3772.234,
			3773.059,
			3774.71,
			3776.37,
			3778.916,
			3780.633,
			3783.195,
			3784.032,
			3784.87,
			3787.392,
			3789.114,
			3791.706,
			3793.449,
			3795.202,
			3796.078,
			3797.83,
			3798.714,
			3800.485,
			3802.253,
			3803.136,
			3804.88,
			3806.612,
			3809.206,
			3810.075,
			3810.942,
			3813.532,
			3814.396,
			3815.258,
			3816.978,
			3817.836,
			3819.548,
			3822.08,
			3823.763,
			3825.413,
			3827.812,
			3828.611,
			3829.41,
			3831.784,
			3833.354,
			3835.705,
			3837.252,
			3839.566,
			3841.109,
			3842.645,
			3843.412,
			3844.948,
			3845.716,
			3847.26,
			3848.808,
			3851.136,
			3852.704,
			3855.063,
			3855.852,
			3856.651,
			3859.073,
			3860.7,
			3863.175,
			3864.845,
			3866.528,
			3867.374,
			3869.069,
			3869.916,
			3870.763,
			3873.311,
			3874.166,
			3875.01,
			3877.6,
			3879.35,
			3881.098,
			3881.977,
			3883.742,
			3885.518,
			3886.41,
			3888.205,
			3889.105,
			3890.9,
			3892.722,
			3895.492,
			3897.344,
			3900.151,
			3901.093,
			3902.039,
			3904.9,
			3906.828,
			3909.747,
			3911.714,
			3913.691,
			3914.69,
			3916.695,
			3917.701,
			3919.74,
			3921.806,
			3922.843,
			3924.943,
			3927.065,
			3929.2,
			3931.38,
			3932.473,
			3935.76,
			3936.857,
			3937.954,
			3941.254,
			3943.466,
			3945.684,
			3949.052,
			3951.296,
			3954.677,
			3955.807,
			3956.942,
			3960.359,
			3962.62,
			3965.944,
			3968.167,
			3970.337,
			3971.423,
			3973.574,
			3974.679,
			3976.842,
			3980.126,
			3982.327,
			3984.537,
			3987.87,
			3988.992,
			3990.118,
			3993.512,
			3994.665,
			3995.82,
			3999.299,
			4001.626,
			4003.956,
			4007.443,
			4009.756,
			4013.191,
			4014.323,
			4015.439,
			4018.738,
			4020.883,
			4024.016,
			4026.053,
			4028.046,
			4029.029,
			4030.954,
			4032.818,
			4033.737,
			4036.442,
			4038.219,
			4039.959,
			4042.542,
			4044.246,
			4046.785,
			4047.629,
			4048.504,
			4051.127,
			4052.872,
			4055.486,
			4057.225,
			4058.963,
			4061.568,
			4062.436,
			4064.166,
			4065.893,
			4066.757,
			4067.622,
			4070.2,
			4071.921,
			4073.645,
			4074.508,
			4076.238,
			4077.978,
			4078.847,
			4080.591,
			4081.467,
			4083.222,
			4084.979,
			4087.622,
			4089.412,
			4092.113,
			4093.015,
			4093.928,
			4096.674,
			4098.506,
			4101.261,
			4103.095,
			4104.979,
			4105.922,
			4107.806,
			4108.757,
			4110.694,
			4112.624,
			4113.587,
			4114.554,
			4117.439,
			4120.316,
			4122.225,
			4124.132,
			4126.04,
			4126.99,
			4128.891,
			4129.852,
			4131.765,
			4133.672,
			4136.592,
			4138.534,
			4141.386,
			4142.335,
			4143.286,
			4145.972,
			4147.746,
			4150.315,
			4151.993,
			4153.671,
			4154.508,
			4156.179,
			4157.014,
			4158.685,
			4161.227,
			4162.924,
			4164.623,
			4167.171,
			4168.872,
			4171.426,
			4172.278,
			4173.131,
			4174.843,
			4175.704,
			4177.429,
			4179.158,
			4181.761,
			4183.5,
			4186.126,
			4187.004,
			4187.881,
			4191.414,
			4192.302,
			4194.964,
			4196.727,
			4198.49,
			4199.358,
			4201.119,
			4202,
			4203.762,
			4205.54,
			4206.422,
			4208.182,
			4209.936,
			4212.553,
			4213.422,
			4214.29,
			4216.873,
			4217.731,
			4218.589,
			4221.145,
			4222.828,
			4225.354,
			4227.047,
			4228.736,
			4231.287,
			4232.143,
			4232.998,
			4235.561,
			4236.415,
			4237.267,
			4239.822,
			4241.527,
			4244.081,
			4245.801,
			4247.558,
			4248.437,
			4251.112,
			4252.964,
			4254.817,
			4257.677,
			4259.603,
			4262.477,
			4263.433,
			4264.39,
			4267.23,
			4269.105,
			4271.93,
			4273.815,
			4275.705,
			4276.679,
			4278.633,
			4279.612,
			4280.591,
			4283.593,
			4284.596,
			4286.591,
			4288.564,
			4290.538,
			4293.457,
			4295.398,
			4297.323,
			4298.28,
			4301.151,
			4303.049,
			4304.948,
			4307.789,
			4309.681,
			4312.516,
			4313.46,
			4314.405,
			4317.246,
			4319.145,
			4322.009,
			4323.925,
			4325.854,
			4326.822,
			4328.762,
			4329.736,
			4331.692,
			4333.651,
			4334.635,
			4336.611,
			4338.583,
			4341.562,
			4342.559,
			4343.558,
			4346.571,
			4347.584,
			4348.602,
			4351.669,
			4353.726,
			4355.785,
			4358.88,
			4360.971,
			4364.133,
			4365.211,
			4366.274,
			4369.49,
			4370.569,
			4371.651,
			4374.924,
			4377.121,
			4379.311,
			4380.419,
			4382.66,
			4384.894,
			4386.014,
			4388.261,
			4389.389,
			4391.632,
			4393.904,
			4397.328,
			4398.473,
			4399.64,
			4403.078,
			4404.233,
			4405.39,
			4408.874,
			4411.205,
			4414.716,
			4417.067,
			4419.429,
			4422.991,
			4424.184,
			4425.379,
			4430.184,
			4431.392,
			4435.029,
			4437.466,
			4439.913,
			4441.14,
			4443.601,
			4446.07,
			4447.309,
			4449.792,
			4451.037,
			4453.533,
			4456.038,
			4459.812,
			4462.338,
			4466.144,
			4467.416,
			4468.691,
			4472.525,
			4475.091,
			4478.955,
			4481.541,
			4484.136,
			4485.436,
			4488.041,
			4489.343,
			4491.95,
			4494.563,
			4495.873,
			4498.498,
			4501.13,
			4503.77,
			4506.417,
			4507.743,
			4510.4,
			4513.065,
			4514.401,
			4517.076,
			4518.416,
			4521.102,
			4523.793,
			4527.846,
			4530.556,
			4534.637,
			4536.001,
			4537.367,
			4541.474,
			4544.22,
			4548.352,
			4551.114,
			4553.885,
			4555.272,
			4556.031
		],
		[
			0,
			0.028,
			0.09,
			0.153,
			0.198,
			0.214,
			0.276,
			0.307,
			0.337,
			0.398,
			0.416,
			0.461,
			0.528,
			0.587,
			0.63,
			0.647,
			0.71,
			0.741,
			0.771,
			0.833,
			0.894,
			0.957,
			1.02,
			1.067,
			1.145,
			1.175,
			1.204,
			1.281,
			1.331,
			1.392,
			1.455,
			1.504,
			1.517,
			1.577,
			1.608,
			1.641,
			1.717,
			1.763,
			1.827,
			1.888,
			1.935,
			2.011,
			2.042,
			2.074,
			2.152,
			2.198,
			2.26,
			2.322,
			2.37,
			2.384,
			2.445,
			2.476,
			2.507,
			2.587,
			2.631,
			2.695,
			2.756,
			2.803,
			2.82,
			2.881,
			2.911,
			2.941,
			3.003,
			3.02,
			3.065,
			3.128,
			3.191,
			3.237,
			3.315,
			3.346,
			3.375,
			3.439,
			3.498,
			3.565,
			3.624,
			3.686,
			3.75,
			3.78,
			3.809,
			3.871,
			3.888,
			3.934,
			3.996,
			4.058,
			4.121,
			4.18,
			4.212,
			4.245,
			4.307,
			4.321,
			4.369,
			4.43,
			4.491,
			4.539,
			4.618,
			4.647,
			4.678,
			4.741,
			4.802,
			4.864,
			4.927,
			4.987,
			5.051,
			5.082,
			5.112,
			5.191,
			5.236,
			5.299,
			5.36,
			5.407,
			5.422,
			5.486,
			5.514,
			5.547,
			5.608,
			5.671,
			5.73,
			5.793,
			5.842,
			5.919,
			5.95,
			5.982,
			6.057,
			6.106,
			6.165,
			6.228,
			6.292,
			6.353,
			6.383,
			6.413,
			6.475,
			6.493,
			6.539,
			6.602,
			6.664,
			6.726,
			6.785,
			6.818,
			6.847,
			6.909,
			6.971,
			7.036,
			7.098,
			7.142,
			7.159,
			7.219,
			7.252,
			7.284,
			7.344,
			7.36,
			7.408,
			7.47,
			7.53,
			7.577,
			7.594,
			7.654,
			7.69,
			7.717,
			7.795,
			7.841,
			7.903,
			7.965,
			8.027,
			8.088,
			8.119,
			8.151,
			8.213,
			8.275,
			8.337,
			8.397,
			8.447,
			8.461,
			8.522,
			8.553,
			8.586,
			8.648,
			8.664,
			8.708,
			8.77,
			8.832,
			8.88,
			8.896,
			8.956,
			8.988,
			9.019,
			9.08,
			9.097,
			9.144,
			9.206,
			9.265,
			9.314,
			9.39,
			9.422,
			9.454,
			9.529,
			9.577,
			9.641,
			9.702,
			9.764,
			9.826,
			9.857,
			9.888,
			9.95,
			9.963,
			10.011,
			10.074,
			10.136,
			10.18,
			10.198,
			10.259,
			10.289,
			10.321,
			10.397,
			10.445,
			10.507,
			10.568,
			10.614,
			10.693,
			10.724,
			10.756,
			10.819,
			10.879,
			10.944,
			11.004,
			11.051,
			11.064,
			11.128,
			11.158,
			11.19,
			11.252,
			11.265,
			11.315,
			11.375,
			11.438,
			11.485,
			11.563,
			11.594,
			11.624,
			11.702,
			11.746,
			11.809,
			11.871,
			11.919,
			11.935,
			11.994,
			12.027,
			12.058,
			12.119,
			12.136,
			12.18,
			12.245,
			12.305,
			12.353,
			12.367,
			12.428,
			12.46,
			12.491,
			12.569,
			12.616,
			12.677,
			12.74,
			12.803,
			12.862,
			12.895,
			12.924,
			12.989,
			13.051,
			13.111,
			13.174,
			13.219,
			13.236,
			13.298,
			13.328,
			13.361,
			13.437,
			13.485,
			13.547,
			13.607,
			13.655,
			13.669,
			13.731,
			13.764,
			13.795,
			13.876,
			13.919,
			13.981,
			14.041,
			14.088,
			14.164,
			14.198,
			14.227,
			14.305,
			14.353,
			14.419,
			14.476,
			14.523,
			14.539,
			14.601,
			14.63,
			14.663,
			14.725,
			14.741,
			14.787,
			14.848,
			14.911,
			14.957,
			14.971,
			15.035,
			15.063,
			15.097,
			15.157,
			15.174,
			15.219,
			15.282,
			15.344,
			15.39,
			15.468,
			15.5,
			15.529,
			15.593,
			15.609,
			15.655,
			15.714,
			15.778,
			15.824,
			15.84,
			15.903,
			15.963,
			16.026,
			16.042,
			16.087,
			16.152,
			16.212,
			16.26,
			16.336,
			16.368,
			16.397,
			16.461,
			16.522,
			16.586,
			16.648,
			16.692,
			16.708,
			16.771,
			16.802,
			16.83,
			16.896,
			16.91,
			16.957,
			17.02,
			17.081,
			17.128,
			17.143,
			17.204,
			17.237,
			17.267,
			17.327,
			17.344,
			17.391,
			17.453,
			17.515,
			17.562,
			17.638,
			17.671,
			17.701,
			17.777,
			17.826,
			17.886,
			17.949,
			17.998,
			18.011,
			18.073,
			18.104,
			18.135,
			18.196,
			18.212,
			18.258,
			18.321,
			18.38,
			18.445,
			18.507,
			18.536,
			18.569,
			18.63,
			18.647,
			18.691,
			18.754,
			18.818,
			18.862,
			18.94,
			18.972,
			19.002,
			19.065,
			19.128,
			19.189,
			19.251,
			19.299,
			19.312,
			19.374,
			19.404,
			19.436,
			19.498,
			19.516,
			19.56,
			19.624,
			19.686,
			19.73,
			19.747,
			19.81,
			19.839,
			19.872,
			19.934,
			19.994,
			20.061,
			20.119,
			20.165,
			20.243,
			20.274,
			20.306,
			20.366,
			20.431,
			20.492,
			20.554,
			20.597,
			20.614,
			20.676,
			20.708,
			20.738,
			20.801,
			20.817,
			20.862,
			20.926,
			20.986,
			21.033,
			21.111,
			21.142,
			21.173,
			21.251,
			21.297,
			21.361,
			21.422,
			21.482,
			21.546,
			21.577,
			21.607,
			21.67,
			21.731,
			21.793,
			21.854,
			21.902,
			21.916,
			21.98,
			22.01,
			22.04,
			22.124,
			22.164,
			22.228,
			22.288,
			22.336,
			22.413,
			22.443,
			22.475,
			22.537,
			22.6,
			22.661,
			22.724,
			22.769,
			22.786,
			22.848,
			22.879,
			22.909,
			22.986,
			23.031,
			23.095,
			23.157,
			23.204,
			23.219,
			23.28,
			23.313,
			23.343,
			23.404,
			23.424,
			23.466,
			23.534,
			23.592,
			23.638,
			23.717,
			23.746,
			23.777,
			23.841,
			23.902,
			23.965,
			24.026,
			24.072,
			24.088,
			24.151,
			24.187,
			24.212,
			24.274,
			24.289,
			24.335,
			24.397,
			24.459,
			24.506,
			24.52,
			24.585,
			24.615,
			24.646,
			24.706,
			24.724,
			24.768,
			24.832,
			24.895,
			24.941,
			25.019,
			25.047,
			25.08,
			25.142,
			25.202,
			25.267,
			25.328,
			25.374,
			25.391,
			25.452,
			25.481,
			25.513,
			25.575,
			25.593,
			25.637,
			25.699,
			25.763,
			25.808,
			25.824,
			25.885,
			25.916,
			25.948,
			26.027,
			26.071,
			26.135,
			26.196,
			26.246,
			26.32,
			26.352,
			26.382,
			26.46,
			26.507,
			26.569,
			26.631,
			26.677,
			26.691,
			26.754,
			26.785,
			26.814,
			26.877,
			26.894,
			26.939,
			27.003,
			27.063,
			27.11,
			27.189,
			27.22,
			27.252,
			27.328,
			27.375,
			27.438,
			27.497,
			27.543,
			27.56,
			27.621,
			27.652,
			27.684,
			27.747,
			27.762,
			27.807,
			27.87,
			27.932,
			27.993,
			28.055,
			28.087,
			28.119,
			28.18,
			28.196,
			28.243,
			28.308,
			28.368,
			28.414,
			28.49,
			28.521,
			28.553,
			28.614,
			28.677,
			28.742,
			28.8,
			28.846,
			28.862,
			28.926,
			28.957,
			28.988,
			29.048,
			29.064,
			29.111,
			29.173,
			29.236,
			29.28,
			29.297,
			29.358,
			29.389,
			29.419,
			29.484,
			29.545,
			29.607,
			29.668,
			29.713,
			29.791,
			29.825,
			29.855,
			29.917,
			29.98,
			30.042,
			30.103,
			30.147,
			30.166,
			30.227,
			30.258,
			30.289,
			30.37,
			30.414,
			30.475,
			30.537,
			30.597,
			30.662,
			30.692,
			30.722,
			30.785,
			30.848,
			30.909,
			30.972,
			31.031,
			31.095,
			31.125,
			31.156,
			31.22,
			31.235,
			31.281,
			31.345,
			31.404,
			31.467,
			31.529,
			31.559,
			31.592,
			31.669,
			31.714,
			31.778,
			31.84,
			31.887,
			31.962,
			31.993,
			32.024,
			32.103,
			32.147,
			32.211,
			32.274,
			32.319,
			32.335,
			32.398,
			32.43,
			32.46,
			32.522,
			32.535,
			32.583,
			32.645,
			32.708,
			32.753,
			32.769,
			32.831,
			32.861,
			32.894,
			32.969,
			33.017,
			33.08,
			33.141,
			33.203,
			33.264,
			33.297,
			33.326,
			33.389,
			33.452,
			33.515,
			33.575,
			33.623,
			33.636,
			33.701,
			33.73,
			33.76,
			33.824,
			33.839,
			33.886,
			33.947,
			34.011,
			34.072,
			34.134,
			34.165,
			34.196,
			34.259,
			34.319,
			34.38,
			34.442,
			34.505,
			34.569,
			34.599,
			34.631,
			34.69,
			34.753,
			34.814,
			34.877,
			34.925,
			34.939,
			35.003,
			35.03,
			35.063,
			35.127,
			35.141,
			35.188,
			35.248,
			35.31,
			35.357,
			35.374,
			35.436,
			35.467,
			35.497,
			35.561,
			35.622,
			35.684,
			35.745,
			35.807,
			35.87,
			35.901,
			35.931,
			35.993,
			36.01,
			36.055,
			36.118,
			36.179,
			36.243,
			36.303,
			36.335,
			36.364,
			36.428,
			36.444,
			36.491,
			36.556,
			36.613,
			36.676,
			36.739,
			36.77,
			36.801,
			36.862,
			36.925,
			36.986,
			37.047,
			37.094,
			37.11,
			37.173,
			37.203,
			37.235,
			37.297,
			37.31,
			37.357,
			37.42,
			37.482,
			37.53,
			37.543,
			37.606,
			37.638,
			37.669,
			37.731,
			37.745,
			37.791,
			37.855,
			37.914,
			37.962,
			38.041,
			38.07,
			38.102,
			38.164,
			38.227,
			38.289,
			38.35,
			38.398,
			38.413,
			38.475,
			38.504,
			38.535,
			38.597,
			38.619,
			38.662,
			38.721,
			38.784,
			38.83,
			38.847,
			38.909,
			38.939,
			38.969,
			39.032,
			39.048,
			39.095,
			39.157,
			39.219,
			39.266,
			39.343,
			39.372,
			39.404,
			39.481,
			39.528,
			39.592,
			39.652,
			39.698,
			39.713,
			39.777,
			39.807,
			39.84,
			39.901,
			39.914,
			39.961,
			40.024,
			40.086,
			40.134,
			40.211,
			40.241,
			40.273,
			40.334,
			40.397,
			40.458,
			40.522,
			40.568,
			40.583,
			40.644,
			40.681,
			40.705,
			40.769,
			40.784,
			40.831,
			40.893,
			40.954,
			41.002,
			41.016,
			41.078,
			41.108,
			41.142,
			41.201,
			41.219,
			41.265,
			41.328,
			41.389,
			41.451,
			41.511,
			41.544,
			41.574,
			41.637,
			41.698,
			41.76,
			41.823,
			41.886,
			41.946,
			41.979,
			42.009,
			42.071,
			42.087,
			42.134,
			42.195,
			42.258,
			42.304,
			42.319,
			42.382,
			42.411,
			42.443,
			42.506,
			42.519,
			42.565,
			42.628,
			42.69,
			42.753,
			42.814,
			42.845,
			42.877,
			42.94,
			43.002,
			43.062,
			43.126,
			43.17,
			43.187,
			43.247,
			43.281,
			43.311,
			43.389,
			43.435,
			43.498,
			43.56,
			43.606,
			43.622,
			43.684,
			43.713,
			43.746,
			43.822,
			43.869,
			43.931,
			43.994,
			44.039,
			44.118,
			44.148,
			44.178,
			44.257,
			44.304,
			44.365,
			44.427,
			44.475,
			44.489,
			44.552,
			44.581,
			44.614,
			44.676,
			44.69,
			44.737,
			44.805,
			44.862,
			44.907,
			44.924,
			44.986,
			45.014,
			45.046,
			45.125,
			45.171,
			45.235,
			45.294,
			45.343,
			45.419,
			45.452,
			45.481,
			45.544,
			45.561,
			45.606,
			45.666,
			45.728,
			45.792,
			45.854,
			45.885,
			45.914,
			45.979,
			45.992,
			46.04,
			46.101,
			46.164,
			46.209,
			46.287,
			46.319,
			46.349,
			46.411,
			46.474,
			46.541,
			46.599,
			46.644,
			46.66,
			46.723,
			46.753,
			46.782,
			46.846,
			46.865,
			46.907,
			46.97,
			47.031,
			47.079,
			47.093,
			47.156,
			47.185,
			47.219,
			47.279,
			47.295,
			47.341,
			47.402,
			47.465,
			47.528,
			47.59,
			47.62,
			47.652,
			47.714,
			47.776,
			47.836,
			47.897,
			47.962,
			48.022,
			48.055,
			48.086,
			48.149,
			48.163,
			48.209,
			48.274,
			48.334,
			48.381,
			48.396,
			48.458,
			48.488,
			48.52,
			48.58,
			48.598,
			48.643,
			48.707,
			48.768,
			48.814,
			48.891,
			48.927,
			48.953,
			49.017,
			49.077,
			49.141,
			49.203,
			49.247,
			49.265,
			49.328,
			49.357,
			49.389,
			49.466,
			49.513,
			49.575,
			49.636,
			49.685,
			49.759,
			49.79,
			49.823,
			49.885,
			49.945,
			50.01,
			50.07,
			50.131,
			50.195,
			50.225,
			50.256,
			50.319,
			50.335,
			50.381,
			50.442,
			50.503,
			50.551,
			50.564,
			50.627,
			50.66,
			50.691,
			50.752,
			50.768,
			50.816,
			50.876,
			50.938,
			51.001,
			51.063,
			51.094,
			51.125,
			51.186,
			51.248,
			51.311,
			51.374,
			51.42,
			51.434,
			51.497,
			51.527,
			51.56,
			51.622,
			51.636,
			51.681,
			51.749,
			51.807,
			51.853,
			51.869,
			51.932,
			51.963,
			51.992,
			52.054,
			52.071,
			52.117,
			52.177,
			52.24,
			52.286,
			52.366,
			52.394,
			52.428,
			52.504,
			52.55,
			52.612,
			52.674,
			52.737,
			52.797,
			52.828,
			52.861,
			52.923,
			52.938,
			52.985,
			53.051,
			53.108,
			53.17,
			53.232,
			53.264,
			53.294,
			53.372,
			53.419,
			53.481,
			53.542,
			53.59,
			53.667,
			53.698,
			53.728,
			53.792,
			53.854,
			53.914,
			53.976,
			54.024,
			54.038,
			54.102,
			54.13,
			54.162,
			54.224,
			54.239,
			54.288,
			54.349,
			54.41,
			54.457,
			54.474,
			54.536,
			54.567,
			54.597,
			54.659,
			54.721,
			54.783,
			54.846,
			54.891,
			54.97,
			55.001,
			55.032,
			55.094,
			55.114,
			55.155,
			55.222,
			55.279,
			55.327,
			55.341,
			55.403,
			55.434,
			55.465,
			55.527,
			55.543,
			55.59,
			55.652,
			55.712,
			55.76,
			55.837,
			55.869,
			55.897,
			55.977,
			56.022,
			56.086,
			56.148,
			56.208,
			56.271,
			56.303,
			56.332,
			56.412,
			56.457,
			56.52,
			56.581,
			56.628,
			56.642,
			56.706,
			56.736,
			56.767,
			56.83,
			56.844,
			56.893,
			56.953,
			57.014,
			57.079,
			57.139,
			57.175,
			57.202,
			57.28,
			57.326,
			57.387,
			57.451,
			57.495,
			57.512,
			57.574,
			57.605,
			57.637,
			57.697,
			57.713,
			57.76,
			57.826,
			57.881,
			57.946,
			58.008,
			58.039,
			58.07,
			58.147,
			58.195,
			58.257,
			58.319,
			58.365,
			58.441,
			58.473,
			58.505,
			58.58,
			58.627,
			58.689,
			58.752,
			58.797,
			58.813,
			58.875,
			58.906,
			58.936,
			59.001,
			59.013,
			59.063,
			59.123,
			59.186,
			59.236,
			59.31,
			59.341,
			59.373,
			59.447,
			59.495,
			59.559,
			59.62,
			59.666,
			59.744,
			59.776,
			59.807,
			59.869,
			59.884,
			59.931,
			59.993,
			60.055,
			60.1,
			60.114,
			60.177,
			60.209,
			60.24,
			60.302,
			60.318,
			60.363,
			60.427,
			60.489,
			60.535,
			60.611,
			60.642,
			60.675,
			60.737,
			60.799,
			60.86,
			60.922,
			60.969,
			60.985,
			61.045,
			61.077,
			61.107,
			61.186,
			61.23,
			61.301,
			61.357,
			61.419,
			61.48,
			61.51,
			61.541,
			61.603,
			61.62,
			61.667,
			61.729,
			61.79,
			61.838,
			61.913,
			61.944,
			61.976,
			62.038,
			62.101,
			62.163,
			62.224,
			62.271,
			62.288,
			62.348,
			62.381,
			62.41,
			62.472,
			62.489,
			62.536,
			62.596,
			62.659,
			62.706,
			62.719,
			62.781,
			62.813,
			62.845,
			62.908,
			62.922,
			62.969,
			63.031,
			63.094,
			63.14,
			63.217,
			63.249,
			63.277,
			63.341,
			63.362,
			63.403,
			63.465,
			63.526,
			63.574,
			63.587,
			63.65,
			63.682,
			63.713,
			63.774,
			63.79,
			63.836,
			63.897,
			63.961,
			64.007,
			64.024,
			64.082,
			64.115,
			64.145,
			64.207,
			64.224,
			64.271,
			64.336,
			64.394,
			64.441,
			64.519,
			64.551,
			64.58,
			64.644,
			64.706,
			64.765,
			64.829,
			64.876,
			64.89,
			64.953,
			64.985,
			65.014,
			65.077,
			65.091,
			65.138,
			65.202,
			65.264,
			65.308,
			65.386,
			65.424,
			65.448,
			65.51,
			65.574,
			65.636,
			65.697,
			65.759,
			65.822,
			65.853,
			65.881,
			65.946,
			65.96,
			66.008,
			66.069,
			66.13,
			66.177,
			66.194,
			66.255,
			66.286,
			66.315,
			66.38,
			66.394,
			66.441,
			66.503,
			66.564,
			66.611,
			66.69,
			66.721,
			66.752,
			66.829,
			66.874,
			66.938,
			66.997,
			67.045,
			67.06,
			67.122,
			67.153,
			67.186,
			67.248,
			67.263,
			67.31,
			67.372,
			67.431,
			67.482,
			67.496,
			67.558,
			67.588,
			67.619,
			67.697,
			67.744,
			67.804,
			67.868,
			67.93,
			67.992,
			68.021,
			68.054,
			68.114,
			68.177,
			68.239,
			68.303,
			68.348,
			68.364,
			68.424,
			68.457,
			68.488,
			68.548,
			68.565,
			68.611,
			68.674,
			68.735,
			68.797,
			68.861,
			68.891,
			68.922,
			68.983,
			69.047,
			69.109,
			69.169,
			69.232,
			69.294,
			69.324,
			69.354,
			69.434,
			69.481,
			69.548,
			69.605,
			69.664,
			69.726,
			69.758,
			69.789,
			69.853,
			69.869,
			69.912,
			69.976,
			70.037,
			70.085,
			70.162,
			70.194,
			70.224,
			70.286,
			70.349,
			70.409,
			70.473,
			70.535,
			70.595,
			70.626,
			70.657,
			70.736,
			70.783,
			70.842,
			70.905,
			70.969,
			71.031,
			71.06,
			71.092,
			71.153,
			71.171,
			71.214,
			71.279,
			71.34,
			71.386,
			71.463,
			71.494,
			71.527,
			71.587,
			71.609,
			71.651,
			71.713,
			71.774,
			71.821,
			71.838,
			71.897,
			71.928,
			71.961,
			72.022,
			72.039,
			72.084,
			72.148,
			72.207,
			72.269,
			72.331,
			72.362,
			72.395,
			72.457,
			72.472,
			72.519,
			72.58,
			72.642,
			72.688,
			72.764,
			72.797,
			72.827,
			72.906,
			72.951,
			73.015,
			73.076,
			73.124,
			73.138,
			73.201,
			73.23,
			73.263,
			73.323,
			73.341,
			73.386,
			73.449,
			73.51,
			73.572,
			73.634,
			73.669,
			73.697,
			73.758,
			73.774,
			73.819,
			73.883,
			73.945,
			73.992,
			74.068,
			74.097,
			74.13,
			74.208,
			74.254,
			74.317,
			74.377,
			74.441,
			74.502,
			74.533,
			74.564,
			74.626,
			74.642,
			74.689,
			74.751,
			74.812,
			74.859,
			74.935,
			74.966,
			74.998,
			75.061,
			75.124,
			75.186,
			75.247,
			75.292,
			75.371,
			75.402,
			75.432,
			75.496,
			75.509,
			75.556,
			75.619,
			75.68,
			75.744,
			75.804,
			75.836,
			75.864,
			75.93,
			75.943,
			75.99,
			76.052,
			76.115,
			76.162,
			76.239,
			76.269,
			76.301,
			76.361,
			76.426,
			76.486,
			76.548,
			76.595,
			76.611,
			76.674,
			76.703,
			76.736,
			76.797,
			76.813,
			76.86,
			76.924,
			76.983,
			77.03,
			77.044,
			77.107,
			77.137,
			77.169,
			77.23,
			77.245,
			77.293,
			77.356,
			77.417,
			77.464,
			77.54,
			77.573,
			77.602,
			77.68,
			77.727,
			77.795,
			77.852,
			77.899,
			77.912,
			77.974,
			78.007,
			78.037,
			78.113,
			78.162,
			78.224,
			78.285,
			78.347,
			78.408,
			78.44,
			78.472,
			78.531,
			78.548,
			78.595,
			78.657,
			78.72,
			78.763,
			78.843,
			78.875,
			78.905,
			78.982,
			79.028,
			79.092,
			79.154,
			79.2,
			79.217,
			79.277,
			79.307,
			79.34,
			79.403,
			79.417,
			79.465,
			79.524,
			79.589,
			79.635,
			79.71,
			79.742,
			79.775,
			79.836,
			79.898,
			79.959,
			80.021,
			80.069,
			80.147,
			80.178,
			80.207,
			80.269,
			80.286,
			80.33,
			80.392,
			80.455,
			80.502,
			80.517,
			80.579,
			80.61,
			80.643,
			80.703,
			80.719,
			80.766,
			80.829,
			80.89,
			80.952,
			81.014,
			81.043,
			81.075,
			81.137,
			81.199,
			81.261,
			81.323,
			81.387,
			81.449,
			81.48,
			81.51,
			81.571,
			81.587,
			81.634,
			81.697,
			81.757,
			81.803,
			81.821,
			81.881,
			81.917,
			81.943,
			82.007,
			82.021,
			82.069,
			82.13,
			82.191,
			82.237,
			82.314,
			82.347,
			82.377,
			82.441,
			82.502,
			82.563,
			82.627,
			82.673,
			82.688,
			82.748,
			82.781,
			82.813,
			82.875,
			82.889,
			82.936,
			82.997,
			83.06,
			83.108,
			83.122,
			83.185,
			83.213,
			83.247,
			83.307,
			83.324,
			83.369,
			83.433,
			83.492,
			83.541,
			83.619,
			83.649,
			83.681,
			83.758,
			83.803,
			83.868,
			83.927,
			83.99,
			84.052,
			84.085,
			84.114,
			84.176,
			84.193,
			84.238,
			84.298,
			84.363,
			84.409,
			84.485,
			84.517,
			84.55,
			84.611,
			84.672,
			84.739,
			84.795,
			84.843,
			84.86,
			84.92,
			84.951,
			84.982,
			85.044,
			85.061,
			85.107,
			85.17,
			85.23,
			85.276,
			85.293,
			85.356,
			85.386,
			85.415,
			85.48,
			85.493,
			85.544,
			85.604,
			85.665,
			85.711,
			85.787,
			85.819,
			85.852,
			85.927,
			85.974,
			86.042,
			86.099,
			86.144,
			86.16,
			86.224,
			86.253,
			86.286,
			86.346,
			86.363,
			86.408,
			86.473,
			86.53,
			86.58,
			86.594,
			86.658,
			86.689,
			86.719,
			86.797,
			86.842,
			86.904,
			86.968,
			87.03,
			87.091,
			87.123,
			87.153,
			87.23,
			87.275,
			87.34,
			87.402,
			87.448,
			87.462,
			87.524,
			87.554,
			87.587,
			87.665,
			87.71,
			87.777,
			87.836,
			87.881,
			87.898,
			87.958,
			87.99,
			88.021,
			88.102,
			88.146,
			88.208,
			88.269,
			88.314,
			88.393,
			88.424,
			88.455,
			88.517,
			88.58,
			88.641,
			88.702,
			88.747,
			88.763,
			88.828,
			88.858,
			88.889,
			88.967,
			89.012,
			89.077,
			89.136,
			89.2,
			89.26,
			89.292,
			89.322,
			89.402,
			89.448,
			89.509,
			89.57,
			89.618,
			89.694,
			89.726,
			89.758,
			89.819,
			89.835,
			89.881,
			89.944,
			90.005,
			90.052,
			90.065,
			90.128,
			90.164,
			90.192,
			90.254,
			90.269,
			90.315,
			90.376,
			90.439,
			90.485,
			90.563,
			90.595,
			90.624,
			90.687,
			90.75,
			90.811,
			90.875,
			90.921,
			90.937,
			90.997,
			91.027,
			91.059,
			91.122,
			91.136,
			91.182,
			91.247,
			91.308,
			91.37,
			91.43,
			91.463,
			91.494,
			91.556,
			91.571,
			91.617,
			91.68,
			91.74,
			91.803,
			91.866,
			91.897,
			91.927,
			91.989,
			92.052,
			92.112,
			92.175,
			92.227,
			92.238,
			92.3,
			92.33,
			92.361,
			92.439,
			92.487,
			92.547,
			92.61,
			92.672,
			92.734,
			92.765,
			92.795,
			92.857,
			92.873,
			92.919,
			92.98,
			93.043,
			93.091,
			93.169,
			93.198,
			93.231,
			93.308,
			93.354,
			93.417,
			93.477,
			93.525,
			93.541,
			93.602,
			93.631,
			93.663,
			93.727,
			93.741,
			93.789,
			93.851,
			93.911,
			93.957,
			93.974,
			94.036,
			94.068,
			94.099,
			94.161,
			94.222,
			94.286,
			94.346,
			94.392,
			94.47,
			94.502,
			94.532,
			94.595,
			94.61,
			94.657,
			94.719,
			94.781,
			94.841,
			94.903,
			94.936,
			94.966,
			95.042,
			95.091,
			95.152,
			95.214,
			95.262,
			95.337,
			95.37,
			95.401,
			95.463,
			95.523,
			95.589,
			95.647,
			95.695,
			95.709,
			95.772,
			95.802,
			95.832,
			95.897,
			95.91,
			95.958,
			96.021,
			96.082,
			96.143,
			96.207,
			96.237,
			96.268,
			96.347,
			96.391,
			96.455,
			96.515,
			96.58,
			96.641,
			96.67,
			96.703,
			96.78,
			96.827,
			96.889,
			96.951,
			96.997,
			97.012,
			97.075,
			97.106,
			97.137,
			97.197,
			97.213,
			97.262,
			97.324,
			97.386,
			97.43,
			97.446,
			97.508,
			97.539,
			97.569,
			97.649,
			97.695,
			97.757,
			97.818,
			97.866,
			97.941,
			97.973,
			98.006,
			98.068,
			98.083,
			98.129,
			98.191,
			98.252,
			98.297,
			98.314,
			98.375,
			98.41,
			98.438,
			98.501,
			98.517,
			98.564,
			98.624,
			98.687,
			98.731,
			98.748,
			98.811,
			98.84,
			98.873,
			98.936,
			98.995,
			99.058,
			99.12,
			99.168,
			99.244,
			99.277,
			99.307,
			99.386,
			99.431,
			99.495,
			99.554,
			99.603,
			99.618,
			99.678,
			99.711,
			99.741,
			99.802,
			99.819,
			99.865,
			99.929,
			99.99,
			100.037,
			100.114,
			100.143,
			100.176,
			100.236,
			100.299,
			100.363,
			100.422,
			100.475,
			100.485,
			100.548,
			100.579,
			100.608,
			100.67,
			100.687,
			100.733,
			100.794,
			100.856,
			100.904,
			100.919,
			100.98,
			101.012,
			101.043,
			101.105,
			101.122,
			101.165,
			101.23,
			101.292,
			101.339,
			101.413,
			101.445,
			101.476,
			101.54,
			101.603,
			101.663,
			101.724,
			101.771,
			101.787,
			101.81
		]
	],
	'1692_1763_7982': [
		[
			89.301
		],
		[
			0
		],
		[
			0,
			4.079,
			6.884,
			9.696,
			11.105,
			13.926,
			15.339,
			18.17,
			21.008,
			25.276,
			28.13,
			32.425,
			33.86,
			35.296,
			39.618,
			42.508,
			46.855,
			49.762,
			52.676,
			54.136,
			57.06,
			58.525,
			61.461,
			64.403,
			65.876,
			68.828,
			71.786,
			76.232,
			77.717,
			79.203,
			82.18,
			85.163,
			86.656,
			89.647,
			91.145,
			94.144,
			97.149,
			101.668,
			104.688,
			109.228,
			110.744,
			112.262,
			116.823,
			119.872,
			124.455,
			127.518,
			130.587,
			132.124,
			135.201,
			136.741,
			139.827,
			142.916,
			144.461,
			147.545,
			150.613,
			155.167,
			156.668,
			158.159,
			162.578,
			164.031,
			165.475,
			168.332,
			169.747,
			172.548,
			176.677,
			179.383,
			182.05,
			185.98,
			187.272,
			188.554,
			192.343,
			193.587,
			194.822,
			198.471,
			200.859,
			203.21,
			204.373,
			206.671,
			207.804,
			210.043,
			212.245,
			213.331,
			215.476,
			217.584,
			220.677,
			222.711,
			225.673,
			226.646,
			227.614,
			230.477,
			232.362,
			234.225,
			237.001,
			238.85,
			239.773,
			241.62,
			242.543,
			243.466,
			246.264,
			247.197,
			248.129,
			250.979,
			252.889,
			254.814,
			255.796,
			257.762,
			260.731,
			262.731,
			263.73,
			265.716,
			267.683,
			270.613,
			272.517,
			275.371,
			276.311,
			277.251,
			280.073,
			281.012,
			281.948,
			284.757,
			286.632,
			288.505,
			289.443,
			291.326,
			292.275,
			294.178,
			297.057,
			298.029,
			300.956,
			303.92,
			304.913,
			305.909,
			307.907,
			309.914,
			310.921,
			313.94,
			315.976,
			318.022,
			321.108,
			323.177,
			326.3,
			327.345,
			328.394,
			331.553,
			333.672,
			336.866,
			339.009,
			341.16,
			342.24,
			344.405,
			345.491,
			347.669,
			349.858,
			350.956,
			353.159,
			355.371,
			358.707,
			360.943,
			364.315,
			366.572,
			369.975,
			372.255,
			375.692,
			377.996,
			380.309,
			383.798,
			384.965,
			386.135,
			389.658,
			392.019,
			395.579,
			397.965,
			401.562,
			403.972,
			405.18,
			407.603,
			410.036,
			411.255,
			413.701,
			416.156,
			419.853,
			422.328,
			426.056,
			427.303,
			428.552,
			432.309,
			434.823,
			438.609,
			441.142,
			443.681,
			444.954,
			447.507,
			448.785,
			450.066,
			455.207,
			456.497,
			460.379,
			462.977,
			466.875,
			469.481,
			472.095,
			473.404,
			476.028,
			477.342,
			479.976,
			482.617,
			486.591,
			489.249,
			493.247,
			494.583,
			495.92,
			499.941,
			502.63,
			506.681,
			509.391,
			512.112,
			513.475,
			516.209,
			517.579,
			520.324,
			523.08,
			524.461,
			525.843,
			529.999,
			534.168,
			535.56,
			536.954,
			539.742,
			542.536,
			543.934,
			546.735,
			548.138,
			550.949,
			553.767,
			558.008,
			560.843,
			565.11,
			566.536,
			567.964,
			572.257,
			575.126,
			579.443,
			582.33,
			585.223,
			586.672,
			589.574,
			591.027,
			593.939,
			596.856,
			598.317,
			601.243,
			604.176,
			608.587,
			610.059,
			611.534,
			614.488,
			617.448,
			618.93,
			623.385,
			626.362,
			630.84,
			633.832,
			636.829,
			641.337,
			642.842,
			644.348,
			650.389,
			651.902,
			656.451,
			659.491,
			664.06,
			667.113,
			668.641,
			671.702,
			676.304,
			679.379,
			682.459,
			687.088,
			688.63,
			690.168,
			694.754,
			696.267,
			697.771,
			702.225,
			705.145,
			709.454,
			712.281,
			715.071,
			716.453,
			719.19,
			720.544,
			723.224,
			725.865,
			727.169,
			728.464,
			732.291,
			734.793,
			737.259,
			738.477,
			740.887,
			743.263,
			744.438,
			746.761,
			747.907,
			750.172,
			752.397,
			755.659,
			757.786,
			760.9,
			761.919,
			762.928,
			765.896,
			767.829,
			770.665,
			772.51,
			774.326,
			775.223,
			776.99,
			777.861,
			779.579,
			781.26,
			782.087,
			783.713,
			785.302,
			786.853,
			789.112,
			790.572,
			791.996,
			792.694,
			794.065,
			794.736,
			796.054,
			797.351,
			799.227,
			800.443,
			802.238,
			802.83,
			803.419,
			805.17,
			806.327,
			808.052,
			809.195,
			810.329,
			810.893,
			812.015,
			812.574,
			813.686,
			814.807,
			815.37,
			816.489,
			817.6,
			818.704,
			819.834,
			820.409,
			822.123,
			822.692,
			823.26,
			824.396,
			825.001,
			826.211,
			827.415,
			829.223,
			830.485,
			832.399,
			833.037,
			833.674,
			835.63,
			836.966,
			838.973,
			840.325,
			842.369,
			843.731,
			845.095,
			845.76,
			847.093,
			847.76,
			849.098,
			850.439,
			852.407,
			853.723,
			855.704,
			856.346,
			856.988,
			858.922,
			860.218,
			862.138,
			863.411,
			864.691,
			865.334,
			866.622,
			867.263,
			868.548,
			869.842,
			870.493,
			871.148,
			873.129,
			874.463,
			875.81,
			876.488,
			877.856,
			879.236,
			879.931,
			881.334,
			882.041,
			883.466,
			884.906,
			887.097,
			888.579,
			890.829,
			891.588,
			892.35,
			894.663,
			896.223,
			898.594,
			900.193,
			901.807,
			902.619,
			904.256,
			905.08,
			906.736,
			908.396,
			909.229,
			910.887,
			912.574,
			914.281,
			916.005,
			916.873,
			918.618,
			920.386,
			921.275,
			923.965,
			925.783,
			927.612,
			930.403,
			932.272,
			935.106,
			936.063,
			937.02,
			939.915,
			941.875,
			944.832,
			946.836,
			948.84,
			949.853,
			951.917,
			952.949,
			955.015,
			957.102,
			958.145,
			960.231,
			962.289,
			965.371,
			966.384,
			967.397,
			970.434,
			971.448,
			972.463,
			974.49,
			975.503,
			977.542,
			979.58,
			982.627,
			984.656,
			987.681,
			988.68,
			989.681,
			992.672,
			994.643,
			997.609,
			999.571,
			1001.53,
			1002.517,
			1004.509,
			1006.495,
			1007.493,
			1010.5,
			1012.519,
			1014.549,
			1017.615,
			1019.674,
			1022.783,
			1023.824,
			1024.865,
			1027.987,
			1030.062,
			1033.22,
			1035.339,
			1037.47,
			1038.538,
			1040.703,
			1041.78,
			1042.86,
			1046.096,
			1047.187,
			1048.279,
			1051.572,
			1053.778,
			1055.994,
			1057.105,
			1059.335,
			1061.574,
			1062.696,
			1064.947,
			1066.077,
			1068.34,
			1070.614,
			1074.038,
			1076.33,
			1079.789,
			1080.947,
			1082.107,
			1085.6,
			1087.94,
			1091.469,
			1093.836,
			1096.216,
			1097.409,
			1099.805,
			1101.007,
			1102.212,
			1107.062,
			1109.505,
			1111.958,
			1114.422,
			1116.894,
			1118.134,
			1120.618,
			1123.109,
			1124.357,
			1128.111,
			1130.623,
			1133.142,
			1136.934,
			1139.47,
			1143.286,
			1144.561,
			1145.838,
			1149.684,
			1152.258,
			1156.135,
			1158.728,
			1161.321,
			1162.619,
			1165.22,
			1166.523,
			1169.136,
			1171.756,
			1173.068,
			1175.699,
			1178.338,
			1180.985,
			1183.64,
			1184.97,
			1188.964,
			1190.298,
			1191.635,
			1194.313,
			1195.655,
			1198.343,
			1201.037,
			1205.091,
			1207.802,
			1211.886,
			1213.25,
			1214.617,
			1218.726,
			1221.477,
			1225.615,
			1228.383,
			1231.158,
			1232.549,
			1235.334,
			1236.729,
			1239.525,
			1242.326,
			1243.729,
			1246.54,
			1249.357,
			1253.593,
			1255.007,
			1256.423,
			1260.683,
			1262.106,
			1263.531,
			1267.813,
			1270.675,
			1274.984,
			1277.867,
			1280.758,
			1282.206,
			1285.108,
			1286.561,
			1288.017,
			1292.395,
			1295.322,
			1299.726,
			1302.67,
			1305.621,
			1307.098,
			1310.057,
			1313.022,
			1314.507,
			1318.971,
			1321.955,
			1324.944,
			1329.441,
			1332.448,
			1336.971,
			1338.482,
			1339.995,
			1344.55,
			1347.595,
			1352.175,
			1355.238,
			1358.307,
			1362.923,
			1364.465,
			1367.552,
			1370.644,
			1372.192,
			1373.741,
			1378.392,
			1381.496,
			1384.605,
			1386.161,
			1389.277,
			1392.4,
			1393.964,
			1397.097,
			1398.666,
			1401.81,
			1404.962,
			1409.702,
			1412.869,
			1417.633,
			1419.222,
			1420.813,
			1425.593,
			1428.785,
			1433.578,
			1436.777,
			1439.975,
			1441.575,
			1444.777,
			1446.378,
			1449.583,
			1452.789,
			1454.393,
			1457.603,
			1460.816,
			1465.643,
			1467.255,
			1468.867,
			1473.712,
			1475.33,
			1476.949,
			1481.816,
			1485.068,
			1488.324,
			1493.216,
			1496.468,
			1501.32,
			1502.925,
			1504.522,
			1509.254,
			1512.361,
			1516.95,
			1519.963,
			1522.939,
			1524.412,
			1527.328,
			1530.202,
			1531.625,
			1535.833,
			1538.591,
			1541.311,
			1545.319,
			1547.943,
			1551.808,
			1553.077,
			1554.337,
			1558.063,
			1560.5,
			1562.901,
			1566.439,
			1568.756,
			1572.161,
			1573.277,
			1574.383,
			1577.646,
			1578.715,
			1579.774,
			1582.897,
			1584.931,
			1587.912,
			1589.854,
			1590.812,
			1592.7,
			1594.554,
			1595.468,
			1597.271,
			1599.04,
			1601.626,
			1603.303,
			1605.751,
			1606.549,
			1607.338,
			1609.649,
			1611.145,
			1613.32,
			1614.725,
			1616.096,
			1616.769,
			1618.089,
			1618.738,
			1620.019,
			1621.249,
			1621.85,
			1622.442,
			1624.175,
			1625.291,
			1626.378,
			1626.915,
			1627.98,
			1629.035,
			1629.56,
			1630.6,
			1631.115,
			1632.144,
			1633.174,
			1634.728,
			1635.784,
			1637.387,
			1637.924,
			1638.464,
			1640.094,
			1641.222,
			1642.948,
			1644.112,
			1645.286,
			1645.895,
			1647.141,
			1647.766,
			1649.021,
			1650.281,
			1650.912,
			1652.231,
			1653.545,
			1655.503,
			1656.817,
			1658.804,
			1659.457,
			1660.106,
			1661.387,
			1662.035,
			1663.336,
			1665.243,
			1666.489,
			1667.706,
			1669.49,
			1670.077,
			1670.661,
			1672.386,
			1673.487,
			1675.134,
			1676.232,
			1677.331,
			1677.862,
			1678.923,
			1679.454,
			1680.518,
			1681.585,
			1682.12,
			1683.198,
			1684.274,
			1685.886,
			1686.424,
			1686.962,
			1688.595,
			1689.143,
			1689.69,
			1690.781,
			1691.327,
			1692.42,
			1693.523,
			1695.196,
			1696.87,
			1697.992,
			1698.557,
			1699.137,
			1701.465,
			1702.051,
			1703.822,
			1705.084,
			1706.356,
			1706.995,
			1708.282,
			1709.608,
			1710.286,
			1711.649,
			1712.336,
			1713.719,
			1715.157,
			1717.366,
			1718.109,
			1718.856,
			1721.075,
			1721.816,
			1722.56,
			1724.802,
			1726.287,
			1728.529,
			1730.034,
			1731.543,
			1732.299,
			1733.816,
			1734.577,
			1736.098,
			1737.622,
			1738.385,
			1739.914,
			1741.41,
			1742.902,
			1744.397,
			1745.146,
			1746.597,
			1748.035,
			1748.754,
			1750.911,
			1752.331,
			1753.752,
			1755.877,
			1757.302,
			1759.458,
			1760.184,
			1760.915,
			1763.117,
			1764.591,
			1766.831,
			1768.344,
			1769.871,
			1770.641,
			1772.198,
			1773.803,
			1774.611,
			1777.058,
			1777.889,
			1780.403,
			1782.94,
			1783.784,
			1784.628,
			1787.165,
			1788.023,
			1788.871,
			1790.604,
			1791.475,
			1793.226,
			1794.988,
			1797.668,
			1799.429,
			1802.118,
			1803.021,
			1803.928,
			1806.668,
			1808.51,
			1811.297,
			1813.171,
			1815.055,
			1816.002,
			1817.905,
			1818.861,
			1820.783,
			1822.717,
			1823.689,
			1825.642,
			1827.607,
			1830.578,
			1832.573,
			1835.589,
			1836.6,
			1837.614,
			1840.673,
			1842.727,
			1845.829,
			1847.911,
			1850.003,
			1853.141,
			1854.188,
			1855.238,
			1859.464,
			1860.527,
			1863.715,
			1865.826,
			1867.913,
			1868.944,
			1870.98,
			1871.983,
			1873.962,
			1875.902,
			1876.859,
			1878.744,
			1880.593,
			1883.302,
			1885.065,
			1887.649,
			1888.492,
			1889.325,
			1891.772,
			1893.359,
			1895.671,
			1897.169,
			1899.349,
			1900.758,
			1901.451,
			1902.138,
			1904.175,
			1904.842,
			1906.168,
			1907.487,
			1908.801,
			1910.772,
			1912.085,
			1913.399,
			1914.057,
			1915.378,
			1916.038,
			1917.353,
			1918.669,
			1919.328,
			1920.65,
			1921.958,
			1923.913,
			1924.564,
			1925.216,
			1927.176,
			1928.482,
			1930.436,
			1931.775,
			1933.182,
			1933.883,
			1935.282,
			1935.98,
			1937.551,
			1939.167,
			1939.97,
			1941.62,
			1943.593,
			1945.547,
			1948.708,
			1951.957,
			1952.927,
			1953.892,
			1955.81,
			1956.766,
			1958.176,
			1959.536,
			1961.561,
			1962.792,
			1964.51,
			1965.077,
			1965.643,
			1967.334,
			1968.49,
			1970.207,
			1971.347,
			1973.082,
			1974.27,
			1974.863,
			1976.05,
			1977.239,
			1977.837,
			1979.046,
			1980.256,
			1982.072,
			1982.679,
			1983.287,
			1985.115,
			1985.727,
			1986.339,
			1987.567,
			1988.183,
			1989.415,
			1990.653,
			1992.526,
			1993.783,
			1995.676,
			1996.312,
			1996.951,
			1998.885,
			2000.188,
			2002.166,
			2003.502,
			2005.541,
			2006.919,
			2008.313,
			2009.02,
			2010.448,
			2011.168,
			2012.619,
			2014.09,
			2016.332,
			2017.087,
			2017.846,
			2020.151,
			2020.928,
			2021.709,
			2024.074,
			2025.674,
			2028.1,
			2029.736,
			2032.219,
			2033.891,
			2034.734,
			2036.42,
			2038.106,
			2038.953,
			2039.807,
			2042.375,
			2044.113,
			2046.76,
			2048.546,
			2050.345,
			2051.255,
			2054.006,
			2055.857,
			2057.727,
			2060.535,
			2062.407,
			2065.219,
			2066.145,
			2067.065,
			2069.812,
			2071.624,
			2074.286,
			2076.053,
			2078.633,
			2080.343,
			2081.198,
			2082.894,
			2085.44,
			2087.164,
			2088.923,
			2090.683,
			2092.48,
			2093.397,
			2095.23,
			2097.07,
			2098.013,
			2099.898,
			2100.839,
			2102.734,
			2104.642,
			2107.506,
			2109.43,
			2112.312,
			2113.274,
			2114.237,
			2117.12,
			2119.053,
			2121.951,
			2123.877,
			2126.76,
			2128.682,
			2129.643,
			2131.565,
			2134.413,
			2136.297,
			2138.182,
			2140.982,
			2141.918,
			2142.855,
			2145.625,
			2146.55,
			2147.477,
			2150.273,
			2152.148,
			2154.983,
			2156.889,
			2158.804,
			2161.702,
			2162.674,
			2163.649,
			2166.573,
			2168.537,
			2171.478,
			2173.449,
			2176.413,
			2178.398,
			2179.395,
			2181.387,
			2183.389,
			2184.395,
			2186.405,
			2188.426,
			2191.472,
			2193.514,
			2196.58,
			2197.613,
			2198.647,
			2201.767,
			2203.841,
			2206.952,
			2209.049,
			2211.155,
			2212.21,
			2214.329,
			2215.392,
			2216.457,
			2219.668,
			2220.743,
			2221.816,
			2225.039,
			2227.199,
			2229.367,
			2230.454,
			2232.654,
			2234.843,
			2235.941,
			2239.263,
			2241.474,
			2243.732,
			2247.144,
			2249.446,
			2252.917,
			2254.088,
			2255.261,
			2258.794,
			2261.164,
			2264.738,
			2267.133,
			2269.537,
			2270.742,
			2273.158,
			2274.369,
			2276.796,
			2279.232,
			2280.451,
			2282.897,
			2285.352,
			2287.816,
			2290.289,
			2291.527,
			2294.012,
			2296.502,
			2297.749,
			2300.248,
			2301.5,
			2304.01,
			2306.529,
			2310.322,
			2312.858,
			2316.677,
			2317.953,
			2319.231,
			2323.079,
			2325.654,
			2329.532,
			2332.127,
			2334.726,
			2336.024,
			2338.625,
			2339.928,
			2342.541,
			2345.161,
			2346.475,
			2349.108,
			2351.748,
			2355.72,
			2358.378,
			2362.381,
			2363.719,
			2365.058,
			2367.743,
			2369.087,
			2371.782,
			2374.487,
			2378.556,
			2381.277,
			2385.373,
			2386.742,
			2388.113,
			2392.238,
			2393.618,
			2395,
			2399.157,
			2401.937,
			2404.722,
			2406.118,
			2408.914,
			2411.717,
			2413.121,
			2415.932,
			2417.339,
			2420.159,
			2422.983,
			2427.233,
			2430.075,
			2434.349,
			2435.777,
			2437.208,
			2441.513,
			2444.392,
			2448.725,
			2451.618,
			2454.506,
			2455.943,
			2458.799,
			2460.215,
			2461.622,
			2467.151,
			2468.509,
			2472.522,
			2475.148,
			2479.01,
			2481.531,
			2484.01,
			2485.235,
			2487.659,
			2488.856,
			2491.245,
			2493.582,
			2497.023,
			2499.273,
			2502.564,
			2503.65,
			2504.726,
			2507.922,
			2509.994,
			2513.037,
			2515.019,
			2516.965,
			2517.925,
			2519.824,
			2520.762,
			2522.621,
			2524.462,
			2525.381,
			2527.217,
			2529.052,
			2531.829,
			2532.756,
			2533.682,
			2535.544,
			2537.412,
			2538.346,
			2541.155,
			2543.03,
			2544.902,
			2547.709,
			2549.574,
			2552.368,
			2553.296,
			2554.224,
			2557.008,
			2558.861,
			2561.638,
			2563.487,
			2566.25,
			2568.098,
			2569.021,
			2570.865,
			2572.723,
			2573.654,
			2575.517,
			2577.383,
			2580.187,
			2582.063,
			2584.889,
			2585.834,
			2586.781,
			2589.633,
			2591.543,
			2593.464,
			2596.36,
			2598.302,
			2601.229,
			2602.207,
			2603.187,
			2606.131,
			2608.099,
			2611.059,
			2613.04,
			2615.017,
			2616.007,
			2617.996,
			2619.984,
			2620.982,
			2622.987,
			2623.99,
			2626.001,
			2628.024,
			2631.072,
			2633.116,
			2636.196,
			2637.226,
			2638.26,
			2641.369,
			2643.454,
			2646.583,
			2648.668,
			2650.759,
			2651.808,
			2653.913,
			2654.97,
			2657.094,
			2659.227,
			2660.299,
			2662.453,
			2664.617,
			2666.795,
			2668.983,
			2670.081,
			2672.269,
			2674.486,
			2675.599,
			2677.834,
			2678.955,
			2681.206,
			2683.466,
			2686.873,
			2689.154,
			2692.594,
			2693.744,
			2694.897,
			2698.369,
			2700.695,
			2704.201,
			2706.551,
			2708.912,
			2710.095,
			2712.47,
			2713.662,
			2716.052,
			2718.451,
			2719.653,
			2722.066,
			2724.488,
			2728.138,
			2729.358,
			2730.582,
			2734.265,
			2735.497,
			2736.731,
			2739.206,
			2740.447,
			2742.935,
			2745.432,
			2749.194,
			2751.712,
			2755.505,
			2758.045,
			2761.87,
			2764.431,
			2768.287,
			2770.868,
			2773.457,
			2774.754,
			2777.354,
			2778.657,
			2781.264,
			2783.868,
			2785.166,
			2787.749,
			2790.306,
			2794.083,
			2795.323,
			2796.552,
			2800.189,
			2801.383,
			2802.567,
			2806.06,
			2808.342,
			2811.695,
			2813.884,
			2816.035,
			2819.198,
			2820.237,
			2821.268,
			2824.306,
			2826.289,
			2829.197,
			2831.091,
			2833.865,
			2835.671,
			2837.443,
			2838.317,
			2840.037,
			2840.883,
			2842.547,
			2844.174,
			2846.546,
			2848.089,
			2850.376,
			2851.125,
			2851.872,
			2854.096,
			2855.572,
			2857.781,
			2859.248,
			2860.715,
			2861.458,
			2862.942,
			2863.683,
			2865.163,
			2866.673,
			2867.434,
			2868.194,
			2870.473,
			2872.033,
			2873.594,
			2874.374,
			2875.934,
			2877.515,
			2878.305,
			2879.883,
			2880.671,
			2882.254,
			2883.836,
			2886.208,
			2887.787,
			2890.147,
			2890.933,
			2891.72,
			2894.1,
			2895.682,
			2898.073,
			2899.671,
			2901.265,
			2902.062,
			2903.673,
			2904.475,
			2906.075,
			2908.463,
			2910.041,
			2911.615,
			2913.189,
			2914.76,
			2915.543,
			2917.109,
			2918.68,
			2919.466,
			2921.817,
			2923.387,
			2924.97,
			2927.336,
			2928.923,
			2931.304,
			2932.096,
			2932.887,
			2935.27,
			2936.851,
			2939.225,
			2940.806,
			2942.384,
			2943.173,
			2944.715,
			2945.48,
			2947.01,
			2948.541,
			2949.294,
			2950.793,
			2952.293,
			2954.546,
			2955.294,
			2956.042,
			2957.541,
			2959.045,
			2959.798,
			2961.307,
			2962.063,
			2963.58,
			2965.864,
			2967.389,
			2968.918,
			2971.212,
			2971.976,
			2972.741,
			2975.039,
			2975.801,
			2976.563,
			2978.853,
			2980.385,
			2981.928,
			2982.701,
			2984.238,
			2985.016,
			2986.6,
			2989.024,
			2990.648,
			2992.302,
			2994.813,
			2996.504,
			2999.061,
			2999.924,
			3000.791,
			3003.395,
			3005.141,
			3007.782,
			3009.546,
			3011.323,
			3014.01,
			3014.911,
			3016.722,
			3019.461,
			3020.381,
			3023.157,
			3025.041,
			3027.865,
			3029.764,
			3031.674,
			3032.634,
			3034.566,
			3035.537,
			3037.488,
			3039.455,
			3042.442,
			3044.454,
			3047.535,
			3048.567,
			3049.607,
			3052.789,
			3054.927,
			3058.21,
			3060.411,
			3062.623,
			3063.733,
			3065.943,
			3067.04,
			3069.229,
			3071.403,
			3072.482,
			3074.634,
			3076.766,
			3079.916,
			3080.984,
			3082.032,
			3084.113,
			3086.142,
			3087.143,
			3089.133,
			3090.124,
			3092.087,
			3094.029,
			3096.914,
			3098.806,
			3101.617,
			3102.544,
			3103.469,
			3106.235,
			3108.076,
			3110.854,
			3112.718,
			3115.548,
			3117.45,
			3118.402,
			3120.334,
			3123.228,
			3125.15,
			3127.052,
			3129.906,
			3131.801,
			3134.629,
			3135.562,
			3136.495,
			3139.278,
			3141.123,
			3142.96,
			3145.689,
			3147.492,
			3150.23,
			3151.141,
			3152.055,
			3155.726,
			3156.651,
			3159.441,
			3161.315,
			3163.202,
			3164.148,
			3166.049,
			3167.003,
			3168.921,
			3171.816,
			3173.761,
			3175.695,
			3178.636,
			3179.616,
			3180.612,
			3183.547,
			3184.521,
			3185.491,
			3188.373,
			3190.269,
			3193.067,
			3194.892,
			3197.581,
			3199.34,
			3200.212,
			3201.943,
			3204.521,
			3205.377,
			3207.939,
			3209.645,
			3212.193,
			3213.889,
			3215.587,
			3216.441,
			3218.145,
			3218.997,
			3220.702,
			3222.417,
			3224.985,
			3226.713,
			3229.314,
			3230.182,
			3231.056,
			3233.717,
			3235.492,
			3238.225,
			3240.054,
			3241.914,
			3242.861,
			3244.753,
			3245.699,
			3247.634,
			3249.583,
			3250.556,
			3252.488,
			3254.407,
			3256.323,
			3258.229,
			3259.177,
			3261.067,
			3262.929,
			3263.842,
			3265.661,
			3266.567,
			3268.381,
			3270.199,
			3272.908,
			3274.693,
			3277.36,
			3278.247,
			3279.135,
			3281.797,
			3282.683,
			3283.568,
			3286.134,
			3287.841,
			3289.535,
			3290.365,
			3292.02,
			3292.846,
			3294.493,
			3296.119,
			3296.93,
			3298.551,
			3300.169,
			3302.595,
			3303.402,
			3304.196,
			3306.628,
			3307.438,
			3308.249,
			3309.873,
			3310.688,
			3312.341,
			3314.821,
			3316.492,
			3318.163,
			3320.716,
			3321.573,
			3322.433,
			3325.045,
			3326.793,
			3329.433,
			3331.201,
			3332.974,
			3333.861,
			3335.637,
			3337.414,
			3338.304,
			3340.081,
			3340.968,
			3342.74,
			3344.51,
			3347.151,
			3348.899,
			3351.503,
			3352.367,
			3353.228,
			3355.792,
			3357.493,
			3360.038,
			3361.728,
			3363.416,
			3365.966,
			3366.814,
			3368.51,
			3370.233,
			3371.096,
			3371.959,
			3374.56,
			3376.34,
			3378.12,
			3379.011,
			3380.823,
			3382.641,
			3383.552,
			3385.385,
			3386.308,
			3388.156,
			3390.009,
			3392.789,
			3394.65,
			3397.454,
			3398.392,
			3399.33,
			3402.159,
			3404.05,
			3406.907,
			3408.819,
			3410.736,
			3411.701,
			3413.631,
			3414.597,
			3416.53,
			3419.433,
			3421.375,
			3423.324,
			3426.254,
			3428.188,
			3430.123,
			3432.057,
			3433.014,
			3434.93,
			3435.891,
			3437.797,
			3439.692,
			3442.55,
			3444.462,
			3447.351,
			3448.321,
			3449.293,
			3452.227,
			3454.196,
			3457.171,
			3459.163,
			3462.171,
			3464.185,
			3465.195,
			3467.225,
			3469.263,
			3470.285,
			3472.338,
			3474.401,
			3477.51,
			3478.547,
			3479.583,
			3481.663,
			3483.734,
			3484.783,
			3487.946,
			3490.068,
			3492.201,
			3495.42,
			3497.578,
			3500.834,
			3501.925,
			3503.018,
			3506.311,
			3507.413,
			3508.517,
			3511.846,
			3514.078,
			3516.321,
			3517.446,
			3519.703,
			3520.835,
			3523.11,
			3525.395,
			3526.541,
			3528.844,
			3531.156,
			3534.646,
			3536.985,
			3540.515,
			3541.696,
			3542.88,
			3546.449,
			3548.841,
			3552.45,
			3554.867,
			3557.293,
			3558.509,
			3560.947,
			3562.169,
			3563.394,
			3567.076,
			3569.541,
			3573.253,
			3575.736,
			3578.227,
			3579.476,
			3581.98,
			3584.493,
			3585.753,
			3589.544,
			3592.084,
			3594.633,
			3598.473,
			3601.043,
			3604.916,
			3606.21,
			3607.508,
			3611.411,
			3614.02,
			3617.936,
			3620.556,
			3624.498,
			3627.134,
			3628.455,
			3631.101,
			3633.754,
			3635.083,
			3637.745,
			3640.415,
			3643.093,
			3647.122,
			3649.818,
			3652.521,
			3653.875,
			3656.59,
			3657.95,
			3660.675,
			3663.407,
			3667.52,
			3670.27,
			3674.408,
			3675.791,
			3677.175,
			3681.338,
			3684.121,
			3684.312
		],
		[
			0,
			0.067,
			0.13,
			0.161,
			0.193,
			0.255,
			0.269,
			0.317,
			0.378,
			0.439,
			0.487,
			0.563,
			0.595,
			0.626,
			0.689,
			0.751,
			0.812,
			0.874,
			0.921,
			0.938,
			0.998,
			1.031,
			1.062,
			1.123,
			1.14,
			1.186,
			1.247,
			1.31,
			1.358,
			1.371,
			1.433,
			1.465,
			1.496,
			1.557,
			1.573,
			1.618,
			1.681,
			1.742,
			1.789,
			1.868,
			1.899,
			1.93,
			1.99,
			2.051,
			2.116,
			2.177,
			2.224,
			2.239,
			2.301,
			2.332,
			2.364,
			2.424,
			2.442,
			2.489,
			2.548,
			2.612,
			2.658,
			2.674,
			2.736,
			2.767,
			2.796,
			2.858,
			2.874,
			2.921,
			2.984,
			3.044,
			3.092,
			3.17,
			3.201,
			3.232,
			3.294,
			3.309,
			3.356,
			3.423,
			3.48,
			3.527,
			3.54,
			3.601,
			3.633,
			3.667,
			3.728,
			3.742,
			3.791,
			3.851,
			3.914,
			3.96,
			4.039,
			4.069,
			4.099,
			4.177,
			4.222,
			4.284,
			4.348,
			4.395,
			4.411,
			4.471,
			4.503,
			4.533,
			4.595,
			4.612,
			4.656,
			4.718,
			4.78,
			4.829,
			4.844,
			4.906,
			4.967,
			5.03,
			5.045,
			5.092,
			5.154,
			5.216,
			5.261,
			5.34,
			5.369,
			5.401,
			5.463,
			5.484,
			5.526,
			5.589,
			5.651,
			5.695,
			5.711,
			5.774,
			5.805,
			5.837,
			5.913,
			5.958,
			6.021,
			6.084,
			6.131,
			6.145,
			6.208,
			6.239,
			6.268,
			6.348,
			6.395,
			6.456,
			6.519,
			6.564,
			6.643,
			6.674,
			6.703,
			6.782,
			6.827,
			6.89,
			6.95,
			6.998,
			7.015,
			7.075,
			7.107,
			7.139,
			7.201,
			7.217,
			7.263,
			7.329,
			7.386,
			7.449,
			7.511,
			7.573,
			7.635,
			7.696,
			7.759,
			7.821,
			7.881,
			7.943,
			7.974,
			8.007,
			8.084,
			8.129,
			8.192,
			8.254,
			8.316,
			8.378,
			8.408,
			8.441,
			8.501,
			8.516,
			8.564,
			8.625,
			8.688,
			8.735,
			8.813,
			8.842,
			8.875,
			8.935,
			8.997,
			9.064,
			9.123,
			9.167,
			9.185,
			9.247,
			9.278,
			9.308,
			9.387,
			9.432,
			9.494,
			9.556,
			9.619,
			9.68,
			9.712,
			9.742,
			9.805,
			9.821,
			9.868,
			9.929,
			9.99,
			10.053,
			10.113,
			10.147,
			10.175,
			10.24,
			10.302,
			10.364,
			10.425,
			10.471,
			10.487,
			10.548,
			10.581,
			10.61,
			10.672,
			10.688,
			10.733,
			10.798,
			10.86,
			10.905,
			10.919,
			10.984,
			11.013,
			11.045,
			11.105,
			11.123,
			11.167,
			11.231,
			11.292,
			11.356,
			11.417,
			11.446,
			11.477,
			11.555,
			11.604,
			11.665,
			11.728,
			11.774,
			11.789,
			11.85,
			11.881,
			11.914,
			11.976,
			11.989,
			12.035,
			12.098,
			12.16,
			12.208,
			12.223,
			12.284,
			12.316,
			12.347,
			12.424,
			12.47,
			12.534,
			12.595,
			12.642,
			12.718,
			12.749,
			12.78,
			12.86,
			12.906,
			12.967,
			13.03,
			13.092,
			13.153,
			13.184,
			13.214,
			13.292,
			13.341,
			13.403,
			13.464,
			13.508,
			13.525,
			13.587,
			13.618,
			13.649,
			13.711,
			13.773,
			13.835,
			13.898,
			13.943,
			13.959,
			14.022,
			14.053,
			14.084,
			14.144,
			14.161,
			14.207,
			14.273,
			14.331,
			14.379,
			14.394,
			14.455,
			14.487,
			14.519,
			14.58,
			14.595,
			14.641,
			14.705,
			14.764,
			14.811,
			14.889,
			14.92,
			14.952,
			15.028,
			15.076,
			15.139,
			15.198,
			15.246,
			15.262,
			15.324,
			15.354,
			15.386,
			15.446,
			15.462,
			15.51,
			15.572,
			15.634,
			15.694,
			15.757,
			15.792,
			15.82,
			15.881,
			15.896,
			15.944,
			16.005,
			16.066,
			16.114,
			16.19,
			16.222,
			16.254,
			16.33,
			16.377,
			16.44,
			16.501,
			16.549,
			16.564,
			16.626,
			16.657,
			16.688,
			16.749,
			16.766,
			16.812,
			16.875,
			16.934,
			16.983,
			16.999,
			17.059,
			17.091,
			17.123,
			17.183,
			17.198,
			17.245,
			17.307,
			17.371,
			17.417,
			17.495,
			17.525,
			17.555,
			17.619,
			17.679,
			17.741,
			17.805,
			17.866,
			17.928,
			17.96,
			17.989,
			18.051,
			18.068,
			18.113,
			18.177,
			18.239,
			18.284,
			18.363,
			18.393,
			18.424,
			18.485,
			18.549,
			18.613,
			18.673,
			18.717,
			18.735,
			18.796,
			18.828,
			18.86,
			18.918,
			18.936,
			18.981,
			19.043,
			19.107,
			19.154,
			19.167,
			19.23,
			19.262,
			19.291,
			19.355,
			19.37,
			19.417,
			19.479,
			19.541,
			19.587,
			19.663,
			19.696,
			19.726,
			19.788,
			19.849,
			19.916,
			19.973,
			20.022,
			20.035,
			20.097,
			20.128,
			20.16,
			20.222,
			20.239,
			20.285,
			20.346,
			20.407,
			20.455,
			20.468,
			20.532,
			20.564,
			20.595,
			20.672,
			20.72,
			20.782,
			20.844,
			20.89,
			20.966,
			20.996,
			21.029,
			21.09,
			21.154,
			21.216,
			21.276,
			21.323,
			21.339,
			21.401,
			21.432,
			21.464,
			21.524,
			21.539,
			21.587,
			21.648,
			21.711,
			21.757,
			21.772,
			21.836,
			21.866,
			21.898,
			21.957,
			21.978,
			22.022,
			22.081,
			22.144,
			22.191,
			22.267,
			22.3,
			22.33,
			22.392,
			22.455,
			22.516,
			22.578,
			22.625,
			22.642,
			22.702,
			22.734,
			22.764,
			22.841,
			22.888,
			22.952,
			23.012,
			23.073,
			23.137,
			23.169,
			23.198,
			23.259,
			23.322,
			23.384,
			23.447,
			23.492,
			23.509,
			23.57,
			23.603,
			23.632,
			23.696,
			23.71,
			23.756,
			23.817,
			23.882,
			23.928,
			23.944,
			24.006,
			24.041,
			24.066,
			24.13,
			24.145,
			24.192,
			24.254,
			24.314,
			24.363,
			24.439,
			24.47,
			24.501,
			24.564,
			24.626,
			24.688,
			24.749,
			24.797,
			24.812,
			24.872,
			24.905,
			24.934,
			25.014,
			25.059,
			25.122,
			25.183,
			25.231,
			25.245,
			25.308,
			25.339,
			25.369,
			25.445,
			25.492,
			25.556,
			25.616,
			25.663,
			25.741,
			25.772,
			25.804,
			25.866,
			25.928,
			25.988,
			26.05,
			26.1,
			26.114,
			26.176,
			26.207,
			26.237,
			26.298,
			26.316,
			26.361,
			26.425,
			26.484,
			26.532,
			26.548,
			26.611,
			26.641,
			26.673,
			26.734,
			26.748,
			26.795,
			26.857,
			26.92,
			26.967,
			27.044,
			27.074,
			27.106,
			27.183,
			27.229,
			27.292,
			27.355,
			27.4,
			27.417,
			27.478,
			27.507,
			27.54,
			27.601,
			27.616,
			27.663,
			27.725,
			27.788,
			27.834,
			27.85,
			27.912,
			27.942,
			27.975,
			28.051,
			28.097,
			28.164,
			28.223,
			28.267,
			28.284,
			28.346,
			28.377,
			28.409,
			28.483,
			28.531,
			28.595,
			28.656,
			28.7,
			28.717,
			28.778,
			28.811,
			28.842,
			28.919,
			28.967,
			29.029,
			29.089,
			29.137,
			29.214,
			29.243,
			29.275,
			29.339,
			29.399,
			29.465,
			29.525,
			29.571,
			29.648,
			29.679,
			29.71,
			29.772,
			29.787,
			29.833,
			29.896,
			29.957,
			30.003,
			30.02,
			30.083,
			30.114,
			30.144,
			30.206,
			30.225,
			30.267,
			30.331,
			30.393,
			30.455,
			30.515,
			30.549,
			30.578,
			30.64,
			30.7,
			30.764,
			30.825,
			30.872,
			30.888,
			30.95,
			30.981,
			31.013,
			31.075,
			31.089,
			31.137,
			31.198,
			31.261,
			31.307,
			31.322,
			31.385,
			31.415,
			31.446,
			31.509,
			31.571,
			31.631,
			31.695,
			31.74,
			31.819,
			31.85,
			31.881,
			31.958,
			32.004,
			32.067,
			32.129,
			32.175,
			32.191,
			32.253,
			32.286,
			32.314,
			32.392,
			32.439,
			32.501,
			32.564,
			32.624,
			32.687,
			32.718,
			32.747,
			32.811,
			32.873,
			32.934,
			32.998,
			33.042,
			33.119,
			33.153,
			33.183,
			33.245,
			33.261,
			33.306,
			33.369,
			33.432,
			33.492,
			33.556,
			33.584,
			33.616,
			33.679,
			33.695,
			33.741,
			33.804,
			33.864,
			33.926,
			33.989,
			34.021,
			34.05,
			34.112,
			34.173,
			34.235,
			34.297,
			34.349,
			34.359,
			34.422,
			34.454,
			34.485,
			34.547,
			34.561,
			34.607,
			34.672,
			34.731,
			34.779,
			34.796,
			34.855,
			34.886,
			34.919,
			34.981,
			34.995,
			35.044,
			35.107,
			35.167,
			35.23,
			35.292,
			35.321,
			35.352,
			35.431,
			35.477,
			35.54,
			35.6,
			35.649,
			35.664,
			35.725,
			35.756,
			35.787,
			35.849,
			35.864,
			35.912,
			35.974,
			36.034,
			36.098,
			36.16,
			36.189,
			36.219,
			36.282,
			36.299,
			36.344,
			36.411,
			36.47,
			36.53,
			36.593,
			36.625,
			36.657,
			36.717,
			36.779,
			36.842,
			36.904,
			36.949,
			36.966,
			37.026,
			37.059,
			37.088,
			37.15,
			37.166,
			37.213,
			37.275,
			37.339,
			37.383,
			37.398,
			37.461,
			37.491,
			37.523,
			37.583,
			37.601,
			37.649,
			37.708,
			37.772,
			37.835,
			37.895,
			37.925,
			37.958,
			38.035,
			38.08,
			38.143,
			38.207,
			38.251,
			38.269,
			38.33,
			38.361,
			38.39,
			38.452,
			38.475,
			38.517,
			38.578,
			38.639,
			38.684,
			38.701,
			38.763,
			38.793,
			38.824,
			38.889,
			38.95,
			39.012,
			39.075,
			39.119,
			39.136,
			39.198,
			39.227,
			39.259,
			39.322,
			39.338,
			39.385,
			39.445,
			39.507,
			39.552,
			39.57,
			39.633,
			39.662,
			39.693,
			39.771,
			39.817,
			39.879,
			39.941,
			40.005,
			40.067,
			40.096,
			40.128,
			40.19,
			40.252,
			40.313,
			40.375,
			40.422,
			40.436,
			40.5,
			40.534,
			40.561,
			40.639,
			40.684,
			40.748,
			40.811,
			40.856,
			40.873,
			40.935,
			40.966,
			40.996,
			41.059,
			41.074,
			41.119,
			41.181,
			41.245,
			41.291,
			41.367,
			41.398,
			41.43,
			41.492,
			41.553,
			41.618,
			41.678,
			41.724,
			41.739,
			41.801,
			41.832,
			41.864,
			41.927,
			41.942,
			41.989,
			42.05,
			42.113,
			42.175,
			42.237,
			42.267,
			42.297,
			42.361,
			42.422,
			42.485,
			42.547,
			42.607,
			42.671,
			42.702,
			42.733,
			42.809,
			42.856,
			42.917,
			42.979,
			43.026,
			43.041,
			43.105,
			43.133,
			43.165,
			43.227,
			43.244,
			43.289,
			43.352,
			43.416,
			43.461,
			43.539,
			43.569,
			43.6,
			43.677,
			43.724,
			43.787,
			43.848,
			43.912,
			43.972,
			44.004,
			44.033,
			44.095,
			44.112,
			44.159,
			44.219,
			44.283,
			44.343,
			44.408,
			44.439,
			44.469,
			44.531,
			44.545,
			44.593,
			44.655,
			44.66,
			44.716,
			44.764,
			44.841,
			44.872,
			44.901,
			44.964,
			45.027,
			45.093,
			45.152,
			45.196,
			45.212,
			45.274,
			45.305,
			45.337,
			45.398,
			45.414,
			45.46,
			45.524,
			45.583,
			45.646,
			45.71,
			45.741,
			45.768,
			45.832,
			45.849,
			45.895,
			45.957,
			46.019,
			46.066,
			46.144,
			46.174,
			46.205,
			46.282,
			46.327,
			46.39,
			46.451,
			46.516,
			46.576,
			46.608,
			46.639,
			46.701,
			46.722,
			46.763,
			46.826,
			46.887,
			46.932,
			46.948,
			47.012,
			47.042,
			47.074,
			47.134,
			47.151,
			47.196,
			47.259,
			47.321,
			47.384,
			47.444,
			47.475,
			47.507,
			47.567,
			47.631,
			47.692,
			47.755,
			47.816,
			47.878,
			47.91,
			47.94,
			48.001,
			48.017,
			48.064,
			48.128,
			48.189,
			48.234,
			48.251,
			48.313,
			48.345,
			48.375,
			48.438,
			48.5,
			48.56,
			48.623,
			48.684,
			48.746,
			48.782,
			48.81,
			48.871,
			48.888,
			48.932,
			48.994,
			49.057,
			49.121,
			49.181,
			49.214,
			49.244,
			49.321,
			49.367,
			49.43,
			49.49,
			49.539,
			49.615,
			49.645,
			49.676,
			49.739,
			49.801,
			49.862,
			49.927,
			49.987,
			50.049,
			50.079,
			50.112,
			50.189,
			50.234,
			50.297,
			50.358,
			50.407,
			50.421,
			50.483,
			50.516,
			50.546,
			50.607,
			50.622,
			50.668,
			50.733,
			50.794,
			50.856,
			50.919,
			50.949,
			50.979,
			51.041,
			51.104,
			51.165,
			51.227,
			51.291,
			51.353,
			51.383,
			51.413,
			51.491,
			51.538,
			51.601,
			51.661,
			51.707,
			51.722,
			51.785,
			51.816,
			51.849,
			51.924,
			51.972,
			52.035,
			52.095,
			52.141,
			52.221,
			52.251,
			52.282,
			52.344,
			52.406,
			52.467,
			52.53,
			52.592,
			52.655,
			52.684,
			52.717,
			52.778,
			52.794,
			52.839,
			52.907,
			52.965,
			53.012,
			53.089,
			53.117,
			53.149,
			53.213,
			53.275,
			53.335,
			53.398,
			53.445,
			53.461,
			53.522,
			53.554,
			53.584,
			53.645,
			53.662,
			53.707,
			53.771,
			53.832,
			53.88,
			53.894,
			53.956,
			53.988,
			54.019,
			54.094,
			54.143,
			54.206,
			54.267,
			54.312,
			54.391,
			54.422,
			54.451,
			54.514,
			54.575,
			54.639,
			54.699,
			54.748,
			54.763,
			54.823,
			54.855,
			54.886,
			54.947,
			54.967,
			55.01,
			55.073,
			55.133,
			55.182,
			55.195,
			55.257,
			55.29,
			55.321,
			55.383,
			55.398,
			55.446,
			55.507,
			55.568,
			55.614,
			55.693,
			55.724,
			55.754,
			55.817,
			55.878,
			55.94,
			56.001,
			56.049,
			56.064,
			56.125,
			56.158,
			56.19,
			56.252,
			56.267,
			56.312,
			56.374,
			56.437,
			56.498,
			56.562,
			56.592,
			56.622,
			56.684,
			56.701,
			56.746,
			56.809,
			56.869,
			56.917,
			56.995,
			57.03,
			57.057,
			57.118,
			57.135,
			57.18,
			57.245,
			57.306,
			57.351,
			57.366,
			57.427,
			57.461,
			57.492,
			57.552,
			57.569,
			57.614,
			57.677,
			57.739,
			57.785,
			57.864,
			57.895,
			57.924,
			57.988,
			58.05,
			58.111,
			58.172,
			58.218,
			58.235,
			58.297,
			58.328,
			58.358,
			58.436,
			58.484,
			58.546,
			58.608,
			58.669,
			58.731,
			58.763,
			58.794,
			58.856,
			58.871,
			58.918,
			58.979,
			59.041,
			59.093,
			59.164,
			59.197,
			59.228,
			59.289,
			59.352,
			59.418,
			59.476,
			59.522,
			59.538,
			59.6,
			59.629,
			59.662,
			59.723,
			59.739,
			59.785,
			59.848,
			59.91,
			59.956,
			59.971,
			60.032,
			60.064,
			60.095,
			60.173,
			60.219,
			60.282,
			60.343,
			60.389,
			60.468,
			60.499,
			60.528,
			60.606,
			60.651,
			60.717,
			60.778,
			60.84,
			60.901,
			60.932,
			60.962,
			61.025,
			61.042,
			61.089,
			61.156,
			61.211,
			61.259,
			61.336,
			61.368,
			61.396,
			61.461,
			61.522,
			61.584,
			61.646,
			61.708,
			61.77,
			61.801,
			61.833,
			61.908,
			61.956,
			62.016,
			62.08,
			62.125,
			62.142,
			62.204,
			62.235,
			62.266,
			62.326,
			62.342,
			62.389,
			62.452,
			62.514,
			62.575,
			62.637,
			62.669,
			62.699,
			62.776,
			62.824,
			62.887,
			62.949,
			62.995,
			63.009,
			63.073,
			63.101,
			63.135,
			63.196,
			63.217,
			63.259,
			63.32,
			63.381,
			63.427,
			63.444,
			63.506,
			63.537,
			63.569,
			63.63,
			63.646,
			63.693,
			63.757,
			63.815,
			63.862,
			63.941,
			63.971,
			64.001,
			64.064,
			64.127,
			64.189,
			64.25,
			64.295,
			64.313,
			64.375,
			64.405,
			64.435,
			64.497,
			64.513,
			64.561,
			64.623,
			64.685,
			64.731,
			64.745,
			64.809,
			64.84,
			64.87,
			64.932,
			64.948,
			64.995,
			65.057,
			65.117,
			65.179,
			65.241,
			65.303,
			65.381,
			65.428,
			65.492,
			65.551,
			65.598,
			65.614,
			65.677,
			65.707,
			65.739,
			65.801,
			65.816,
			65.863,
			65.923,
			65.987,
			66.032,
			66.048,
			66.11,
			66.142,
			66.171,
			66.235,
			66.296,
			66.359,
			66.42,
			66.482,
			66.545,
			66.577,
			66.607,
			66.668,
			66.732,
			66.792,
			66.855,
			66.917,
			66.979,
			67.01,
			67.041,
			67.101,
			67.118,
			67.165,
			67.225,
			67.289,
			67.351,
			67.411,
			67.445,
			67.475,
			67.537,
			67.6,
			67.662,
			67.723,
			67.769,
			67.786,
			67.846,
			67.878,
			67.91,
			67.971,
			67.984,
			68.032,
			68.095,
			68.157,
			68.205,
			68.219,
			68.279,
			68.312,
			68.343,
			68.405,
			68.421,
			68.467,
			68.528,
			68.59,
			68.638,
			68.715,
			68.746,
			68.777,
			68.855,
			68.901,
			68.962,
			69.025,
			69.071,
			69.088,
			69.149,
			69.178,
			69.211,
			69.288,
			69.335,
			69.401,
			69.459,
			69.505,
			69.522,
			69.583,
			69.614,
			69.646,
			69.724,
			69.768,
			69.83,
			69.894,
			69.956,
			70.016,
			70.048,
			70.08,
			70.156,
			70.204,
			70.267,
			70.328,
			70.374,
			70.39,
			70.45,
			70.483,
			70.512,
			70.576,
			70.591,
			70.637,
			70.699,
			70.761,
			70.807,
			70.822,
			70.883,
			70.917,
			70.946,
			71.009,
			71.024,
			71.071,
			71.134,
			71.196,
			71.243,
			71.319,
			71.349,
			71.382,
			71.444,
			71.464,
			71.506,
			71.566,
			71.629,
			71.675,
			71.691,
			71.754,
			71.783,
			71.815,
			71.891,
			71.939,
			72.002,
			72.066,
			72.11,
			72.189,
			72.22,
			72.251,
			72.312,
			72.374,
			72.437,
			72.498,
			72.558,
			72.622,
			72.652,
			72.684,
			72.761,
			72.808,
			72.869,
			72.932,
			72.995,
			73.057,
			73.088,
			73.117,
			73.181,
			73.194,
			73.242,
			73.304,
			73.366,
			73.412,
			73.488,
			73.524,
			73.553,
			73.613,
			73.675,
			73.736,
			73.8,
			73.845,
			73.86,
			73.922,
			73.954,
			73.985,
			74.048,
			74.063,
			74.109,
			74.173,
			74.234,
			74.28,
			74.296,
			74.358,
			74.39,
			74.419,
			74.483,
			74.496,
			74.544,
			74.606,
			74.669,
			74.715,
			74.791,
			74.823,
			74.855,
			74.916,
			74.978,
			75.039,
			75.101,
			75.164,
			75.225,
			75.257,
			75.289,
			75.366,
			75.413,
			75.474,
			75.537,
			75.587,
			75.661,
			75.692,
			75.722,
			75.801,
			75.846,
			75.907,
			75.97,
			76.031,
			76.095,
			76.124,
			76.156,
			76.234,
			76.281,
			76.34,
			76.405,
			76.452,
			76.465,
			76.527,
			76.558,
			76.589,
			76.667,
			76.715,
			76.777,
			76.838,
			76.884,
			76.9,
			76.963,
			76.992,
			77.025,
			77.087,
			77.148,
			77.21,
			77.272,
			77.334,
			77.396,
			77.428,
			77.459,
			77.535,
			77.584,
			77.648,
			77.707,
			77.768,
			77.831,
			77.862,
			77.894,
			77.955,
			77.971,
			78.016,
			78.078,
			78.139,
			78.186,
			78.263,
			78.295,
			78.326,
			78.39,
			78.45,
			78.513,
			78.576,
			78.621,
			78.636,
			78.699,
			78.73,
			78.762,
			78.822,
			78.836,
			78.885,
			78.946,
			79.007,
			79.054,
			79.071,
			79.134,
			79.164,
			79.195,
			79.257,
			79.273,
			79.319,
			79.379,
			79.442,
			79.489,
			79.565,
			79.596,
			79.63,
			79.691,
			79.712,
			79.754,
			79.815,
			79.878,
			79.923,
			79.94,
			80.001,
			80.03,
			80.063,
			80.125,
			80.141,
			80.188,
			80.249,
			80.311,
			80.358,
			80.374,
			80.434,
			80.465,
			80.496,
			80.558,
			80.574,
			80.622,
			80.684,
			80.745,
			80.791,
			80.87,
			80.901,
			80.932,
			80.992,
			81.056,
			81.116,
			81.178,
			81.225,
			81.241,
			81.303,
			81.335,
			81.366,
			81.427,
			81.443,
			81.49,
			81.551,
			81.614,
			81.66,
			81.737,
			81.773,
			81.798,
			81.877,
			81.922,
			81.984,
			82.046,
			82.095,
			82.171,
			82.203,
			82.234,
			82.295,
			82.311,
			82.356,
			82.419,
			82.48,
			82.526,
			82.544,
			82.605,
			82.637,
			82.668,
			82.73,
			82.743,
			82.791,
			82.852,
			82.914,
			82.961,
			83.039,
			83.069,
			83.1,
			83.164,
			83.226,
			83.288,
			83.348,
			83.397,
			83.411,
			83.475,
			83.505,
			83.535,
			83.613,
			83.66,
			83.722,
			83.784,
			83.846,
			83.907,
			83.939,
			83.968,
			84.031,
			84.048,
			84.092,
			84.157,
			84.218,
			84.265,
			84.34,
			84.373,
			84.402,
			84.465,
			84.527,
			84.591,
			84.653,
			84.715,
			84.775,
			84.807,
			84.839,
			84.898,
			84.915,
			84.963,
			85.025,
			85.084,
			85.132,
			85.148,
			85.208,
			85.242,
			85.272,
			85.335,
			85.396,
			85.458,
			85.519,
			85.58,
			85.643,
			85.674,
			85.705,
			85.766,
			85.784,
			85.829,
			85.895,
			85.954,
			86.001,
			86.014,
			86.079,
			86.108,
			86.141,
			86.201,
			86.216,
			86.264,
			86.329,
			86.389,
			86.451,
			86.512,
			86.543,
			86.574,
			86.651,
			86.697,
			86.762,
			86.822,
			86.868,
			86.885,
			86.946,
			86.977,
			87.008,
			87.071,
			87.131,
			87.195,
			87.256,
			87.304,
			87.318,
			87.38,
			87.412,
			87.44,
			87.518,
			87.565,
			87.629,
			87.69,
			87.737,
			87.814,
			87.844,
			87.877,
			87.958,
			87.999,
			88.065,
			88.124,
			88.187,
			88.248,
			88.278,
			88.31,
			88.371,
			88.389,
			88.435,
			88.495,
			88.557,
			88.621,
			88.681,
			88.713,
			88.746,
			88.806,
			88.822,
			88.867,
			88.93,
			88.993,
			89.054,
			89.116,
			89.149,
			89.177,
			89.24,
			89.301,
			89.301
		]
	],
	'1692_1764_7982': [
		[
			65.216
		],
		[
			0
		],
		[
			0,
			3.043,
			5.824,
			10.008,
			12.806,
			17.014,
			18.42,
			19.828,
			24.061,
			26.892,
			31.15,
			33.998,
			36.852,
			41.145,
			42.58,
			44.016,
			48.333,
			51.219,
			55.561,
			58.462,
			62.827,
			65.745,
			68.669,
			70.133,
			73.067,
			74.536,
			77.479,
			80.427,
			84.861,
			87.824,
			92.278,
			93.765,
			95.255,
			99.731,
			102.723,
			107.223,
			110.231,
			114.755,
			117.779,
			119.294,
			122.33,
			126.895,
			129.946,
			133.002,
			137.59,
			139.117,
			140.643,
			143.689,
			146.719,
			148.227,
			151.223,
			152.709,
			155.656,
			158.563,
			162.852,
			165.665,
			169.812,
			171.175,
			172.529,
			176.535,
			179.158,
			183.022,
			185.552,
			188.043,
			189.275,
			191.711,
			192.916,
			195.301,
			197.651,
			198.814,
			201.112,
			203.373,
			206.695,
			208.865,
			212.051,
			213.095,
			214.13,
			216.171,
			217.195,
			219.181,
			221.129,
			223.995,
			225.865,
			228.633,
			229.541,
			230.442,
			233.138,
			234.93,
			237.607,
			239.394,
			242.104,
			243.91,
			246.65,
			248.484,
			249.402,
			251.258,
			253.122,
			255.943,
			256.898,
			257.853,
			260.747,
			261.735,
			262.724,
			265.7,
			267.695,
			269.695,
			272.652,
			274.624,
			277.529,
			278.495,
			279.463,
			282.349,
			283.309,
			284.271,
			287.16,
			289.091,
			291.031,
			292.007,
			293.965,
			295.933,
			296.924,
			298.918,
			299.919,
			301.929,
			303.954,
			307.011,
			309.043,
			312.134,
			313.167,
			314.203,
			317.324,
			319.416,
			322.572,
			324.687,
			326.812,
			327.88,
			330.025,
			331.101,
			332.179,
			336.525,
			337.617,
			340.914,
			343.125,
			345.346,
			346.46,
			348.696,
			350.939,
			352.065,
			354.322,
			355.454,
			357.724,
			360.002,
			363.436,
			365.735,
			369.201,
			370.36,
			371.522,
			375.019,
			377.36,
			380.887,
			383.248,
			385.618,
			386.805,
			389.187,
			390.382,
			392.778,
			395.183,
			396.388,
			398.806,
			401.233,
			404.89,
			406.113,
			407.339,
			411.028,
			412.261,
			413.496,
			415.972,
			417.213,
			419.7,
			423.447,
			425.954,
			428.469,
			432.254,
			433.52,
			434.788,
			438.603,
			441.157,
			445.007,
			447.585,
			451.475,
			454.077,
			455.379,
			457.987,
			460.605,
			461.917,
			464.543,
			467.177,
			471.134,
			473.779,
			477.754,
			479.082,
			480.41,
			484.396,
			487.05,
			491.002,
			493.608,
			496.185,
			499.983,
			501.231,
			502.469,
			506.127,
			508.519,
			512.044,
			514.352,
			516.623,
			517.745,
			519.962,
			522.141,
			523.217,
			526.387,
			528.453,
			530.482,
			533.455,
			535.39,
			538.223,
			539.149,
			540.066,
			542.767,
			544.524,
			547.092,
			548.759,
			551.189,
			552.765,
			553.539,
			554.305,
			556.55,
			557.284,
			558.025,
			560.178,
			561.592,
			563.694,
			565.084,
			566.469,
			567.161,
			568.54,
			569.227,
			570.595,
			571.962,
			574.044,
			575.434,
			577.512,
			578.234,
			578.969,
			581.164,
			582.622,
			584.953,
			586.511,
			588.873,
			590.469,
			592.062,
			592.858,
			595.232,
			596.811,
			598.368,
			599.895,
			601.435,
			602.201,
			603.729,
			605.224,
			605.973,
			607.476,
			608.23,
			609.735,
			611.24,
			613.508,
			615.037,
			617.336,
			618.104,
			618.872,
			621.17,
			622.703,
			624.976,
			626.473,
			627.97,
			628.718,
			630.185,
			630.913,
			632.368,
			633.822,
			634.545,
			635.981,
			637.414,
			639.566,
			641.001,
			643.154,
			643.873,
			644.596,
			646.054,
			646.784,
			648.246,
			649.716,
			651.949,
			653.443,
			655.697,
			656.451,
			657.208,
			659.486,
			661.011,
			663.313,
			664.861,
			666.415,
			667.196,
			668.768,
			669.559,
			671.151,
			672.756,
			673.563,
			675.188,
			676.826,
			679.308,
			680.979,
			683.513,
			684.363,
			685.217,
			687.803,
			689.542,
			692.18,
			693.956,
			695.744,
			696.644,
			698.455,
			699.364,
			700.277,
			703.042,
			703.971,
			704.887,
			707.704,
			709.599,
			712.466,
			714.395,
			716.336,
			717.314,
			719.284,
			720.273,
			722.26,
			725.268,
			727.288,
			729.321,
			732.391,
			733.422,
			734.456,
			737.574,
			739.669,
			742.822,
			744.927,
			747.041,
			748.102,
			750.235,
			751.307,
			753.457,
			755.622,
			756.709,
			758.892,
			761.089,
			763.299,
			766.659,
			770.033,
			771.164,
			772.299,
			774.576,
			775.719,
			778.011,
			780.313,
			783.791,
			786.122,
			789.642,
			790.821,
			792.002,
			795.56,
			797.945,
			801.543,
			803.948,
			806.362,
			807.571,
			809.993,
			811.208,
			813.638,
			816.074,
			817.296,
			819.736,
			822.185,
			825.846,
			828.308,
			832.007,
			833.244,
			834.482,
			838.206,
			840.699,
			843.201,
			846.971,
			849.495,
			853.297,
			854.569,
			855.842,
			859.675,
			860.956,
			862.239,
			866.099,
			868.682,
			871.273,
			872.571,
			875.172,
			876.473,
			879.077,
			882.997,
			885.62,
			888.254,
			892.219,
			894.872,
			898.866,
			900.201,
			901.538,
			905.559,
			908.251,
			912.302,
			915.011,
			919.086,
			921.811,
			924.542,
			928.651,
			930.025,
			931.399,
			935.533,
			938.298,
			941.068,
			942.456,
			945.237,
			948.025,
			949.422,
			952.222,
			953.624,
			956.433,
			959.25,
			963.486,
			966.319,
			970.581,
			972.005,
			973.431,
			977.72,
			980.587,
			984.9,
			987.783,
			990.674,
			992.121,
			995.022,
			996.474,
			999.383,
			1002.299,
			1003.759,
			1006.683,
			1009.614,
			1012.551,
			1015.494,
			1016.968,
			1019.92,
			1022.868,
			1024.338,
			1028.718,
			1031.603,
			1034.452,
			1038.657,
			1041.414,
			1045.481,
			1046.819,
			1048.147,
			1052.104,
			1054.685,
			1058.495,
			1060.995,
			1063.463,
			1064.685,
			1067.109,
			1068.312,
			1070.704,
			1074.273,
			1076.647,
			1079.026,
			1082.606,
			1084.997,
			1088.582,
			1089.775,
			1090.965,
			1094.528,
			1096.892,
			1100.417,
			1102.755,
			1106.245,
			1108.561,
			1109.717,
			1110.87,
			1114.317,
			1116.602,
			1120.015,
			1122.277,
			1124.532,
			1125.656,
			1127.897,
			1130.131,
			1131.243,
			1133.46,
			1134.566,
			1136.774,
			1138.973,
			1142.26,
			1144.442,
			1147.711,
			1148.799,
			1149.888,
			1153.159,
			1155.343,
			1158.627,
			1160.825,
			1163.029,
			1164.133,
			1166.349,
			1167.46,
			1168.572,
			1171.894,
			1173.006,
			1174.115,
			1177.42,
			1179.591,
			1182.782,
			1184.865,
			1186.909,
			1187.918,
			1189.908,
			1190.889,
			1192.824,
			1194.725,
			1197.509,
			1199.326,
			1201.987,
			1202.857,
			1203.72,
			1206.252,
			1207.894,
			1210.284,
			1211.832,
			1214.079,
			1215.527,
			1216.238,
			1217.631,
			1218.987,
			1219.652,
			1220.956,
			1222.227,
			1223.465,
			1224.665,
			1225.252,
			1226.961,
			1227.513,
			1228.055,
			1229.633,
			1230.641,
			1231.614,
			1233.015,
			1233.925,
			1235.276,
			1235.727,
			1236.179,
			1237.555,
			1238.492,
			1239.906,
			1240.848,
			1241.792,
			1242.265,
			1243.214,
			1243.691,
			1244.644,
			1245.591,
			1246.062,
			1247.002,
			1247.941,
			1249.363,
			1250.317,
			1251.734,
			1252.203,
			1252.672,
			1254.083,
			1255.086,
			1256.57,
			1257.551,
			1258.528,
			1260.107,
			1260.645,
			1261.179,
			1262.772,
			1263.828,
			1265.56,
			1266.718,
			1267.875,
			1268.454,
			1269.628,
			1270.803,
			1271.382,
			1272.53,
			1273.103,
			1274.241,
			1275.288,
			1276.825,
			1277.842,
			1279.362,
			1279.833,
			1280.303,
			1281.712,
			1282.65,
			1284.057,
			1284.965,
			1285.852,
			1286.296,
			1287.186,
			1287.633,
			1288.53,
			1289.878,
			1290.76,
			1291.648,
			1292.544,
			1293.448,
			1293.904,
			1294.822,
			1295.75,
			1296.219,
			1297.165,
			1297.635,
			1298.604,
			1299.592,
			1301.135,
			1302.193,
			1303.817,
			1304.368,
			1304.925,
			1306.651,
			1307.827,
			1309.628,
			1310.868,
			1312.137,
			1312.778,
			1314.073,
			1314.722,
			1316.064,
			1318.144,
			1318.842,
			1321.003,
			1323.309,
			1324.867,
			1326.474,
			1328.116,
			1328.943,
			1330.614,
			1331.408,
			1333.002,
			1334.614,
			1336.963,
			1338.479,
			1340.784,
			1341.526,
			1342.269,
			1344.52,
			1346.038,
			1348.327,
			1349.876,
			1352.233,
			1353.825,
			1354.622,
			1356.218,
			1358.723,
			1360.389,
			1362.087,
			1364.743,
			1365.628,
			1366.512,
			1369.291,
			1370.216,
			1371.142,
			1373.018,
			1373.959,
			1375.837,
			1378.602,
			1380.423,
			1382.211,
			1384.776,
			1385.628,
			1386.479,
			1388.966,
			1390.61,
			1393.05,
			1394.652,
			1396.25,
			1397.047,
			1398.611,
			1400.167,
			1400.943,
			1402.495,
			1403.263,
			1404.798,
			1406.336,
			1408.654,
			1410.21,
			1412.547,
			1413.33,
			1414.116,
			1416.478,
			1418.057,
			1420.433,
			1422.026,
			1424.433,
			1426.048,
			1426.86,
			1428.491,
			1430.133,
			1430.958,
			1431.785,
			1434.28,
			1435.945,
			1437.615,
			1438.455,
			1440.147,
			1441.852,
			1442.71,
			1444.434,
			1445.302,
			1447.047,
			1448.803,
			1451.464,
			1453.252,
			1455.956,
			1456.864,
			1457.774,
			1460.519,
			1462.372,
			1465.161,
			1467.044,
			1468.936,
			1469.882,
			1471.784,
			1472.737,
			1474.646,
			1476.562,
			1477.522,
			1479.446,
			1481.378,
			1483.318,
			1485.264,
			1486.239,
			1488.199,
			1490.164,
			1491.15,
			1493.13,
			1494.122,
			1496.112,
			1498.113,
			1501.125,
			1503.142,
			1506.175,
			1507.186,
			1508.199,
			1511.238,
			1513.264,
			1516.303,
			1518.332,
			1520.36,
			1521.376,
			1523.412,
			1524.428,
			1526.464,
			1528.509,
			1529.531,
			1531.582,
			1533.645,
			1536.753,
			1537.794,
			1538.838,
			1541.983,
			1543.035,
			1544.087,
			1547.238,
			1549.347,
			1551.465,
			1554.642,
			1556.782,
			1560.01,
			1561.091,
			1562.173,
			1565.437,
			1566.53,
			1567.626,
			1570.929,
			1573.146,
			1575.373,
			1576.49,
			1578.733,
			1580.986,
			1582.116,
			1584.383,
			1585.52,
			1587.801,
			1590.091,
			1593.545,
			1595.859,
			1599.348,
			1600.516,
			1601.687,
			1605.213,
			1607.576,
			1611.138,
			1613.525,
			1615.921,
			1619.532,
			1620.741,
			1621.952,
			1625.597,
			1626.817,
			1628.039,
			1631.718,
			1634.181,
			1637.892,
			1640.377,
			1642.871,
			1644.121,
			1647.886,
			1650.407,
			1652.937,
			1656.748,
			1659.299,
			1663.141,
			1664.426,
			1665.713,
			1669.586,
			1672.179,
			1676.082,
			1678.69,
			1681.298,
			1682.604,
			1685.219,
			1686.526,
			1689.133,
			1693.014,
			1695.569,
			1698.089,
			1700.572,
			1703.019,
			1704.229,
			1706.621,
			1708.978,
			1710.144,
			1712.45,
			1713.589,
			1715.838,
			1718.05,
			1721.301,
			1723.429,
			1726.554,
			1727.577,
			1728.592,
			1731.584,
			1733.534,
			1736.39,
			1738.248,
			1740.073,
			1740.973,
			1742.747,
			1743.622,
			1745.344,
			1747.028,
			1747.857,
			1749.487,
			1751.081,
			1753.418,
			1754.177,
			1754.929,
			1756.414,
			1757.878,
			1758.604,
			1760.767,
			1762.202,
			1764.351,
			1765.791,
			1767.231,
			1769.391,
			1770.116,
			1770.845,
			1773.027,
			1773.754,
			1774.481,
			1776.674,
			1778.132,
			1779.588,
			1780.319,
			1781.782,
			1782.51,
			1783.963,
			1785.417,
			1786.147,
			1787.604,
			1789.057,
			1791.253,
			1792.724,
			1794.929,
			1795.67,
			1796.418,
			1798.655,
			1800.144,
			1802.4,
			1803.904,
			1805.41,
			1807.693,
			1808.455,
			1809.217,
			1811.516,
			1812.287,
			1813.059,
			1815.377,
			1816.941,
			1819.303,
			1820.882,
			1822.479,
			1823.279,
			1824.878,
			1825.678,
			1827.29,
			1828.905,
			1831.324,
			1832.943,
			1835.363,
			1836.169,
			1836.976,
			1839.403,
			1841.016,
			1843.448,
			1845.069,
			1846.688,
			1847.499,
			1849.121,
			1849.929,
			1851.546,
			1853.985,
			1855.612,
			1857.238,
			1859.675,
			1861.298,
			1862.919,
			1864.522,
			1865.323,
			1866.924,
			1867.724,
			1869.284,
			1870.839,
			1873.168,
			1874.685,
			1876.99,
			1877.777,
			1878.554,
			1880.905,
			1882.488,
			1884.896,
			1886.518,
			1888.154,
			1888.979,
			1890.636,
			1891.47,
			1893.147,
			1894.839,
			1895.69,
			1897.401,
			1899.126,
			1901.739,
			1903.496,
			1906.157,
			1907.051,
			1907.948,
			1910.657,
			1912.479,
			1914.313,
			1917.087,
			1918.952,
			1921.772,
			1922.718,
			1923.667,
			1926.533,
			1928.46,
			1931.375,
			1933.335,
			1936.298,
			1938.291,
			1940.296,
			1941.304,
			1943.334,
			1944.353,
			1946.402,
			1948.48,
			1951.625,
			1953.777,
			1957.037,
			1958.15,
			1959.266,
			1962.625,
			1964.861,
			1968.163,
			1970.32,
			1972.467,
			1975.647,
			1976.693,
			1977.74,
			1981.86,
			1982.874,
			1985.882,
			1987.84,
			1989.753,
			1990.701,
			1992.585,
			1994.434,
			1995.353,
			1997.182,
			1998.083,
			1999.874,
			2001.65,
			2004.346,
			2006.168,
			2008.896,
			2009.811,
			2010.714,
			2013.49,
			2015.362,
			2018.192,
			2020.099,
			2022.019,
			2022.984,
			2024.939,
			2025.922,
			2027.896,
			2029.903,
			2030.913,
			2032.942,
			2034.989,
			2037.048,
			2039.134,
			2040.17,
			2042.25,
			2044.338,
			2045.379,
			2048.514,
			2050.59,
			2052.659,
			2055.727,
			2057.743,
			2060.714,
			2061.686,
			2062.651,
			2065.512,
			2066.454,
			2067.393,
			2070.203,
			2072.085,
			2074.92,
			2076.845,
			2077.811,
			2079.746,
			2081.698,
			2082.675,
			2084.629,
			2086.581,
			2089.497,
			2090.464,
			2091.424,
			2094.276,
			2095.216,
			2096.149,
			2097.996,
			2098.907,
			2100.705,
			2103.345,
			2105.063,
			2106.748,
			2109.217,
			2110.028,
			2110.834,
			2114.005,
			2114.792,
			2117.138,
			2118.694,
			2120.251,
			2121.03,
			2122.584,
			2124.137,
			2124.913,
			2126.465,
			2127.249,
			2128.817,
			2130.383,
			2132.761,
			2134.363,
			2136.764,
			2137.587,
			2138.409,
			2140.868,
			2142.522,
			2145.033,
			2146.703,
			2148.398,
			2150.947,
			2151.796,
			2152.649,
			2155.235,
			2156.094,
			2156.953,
			2159.566,
			2161.311,
			2163.054,
			2163.926,
			2165.666,
			2167.404,
			2168.271,
			2170.862,
			2172.588,
			2174.299,
			2176.847,
			2178.546,
			2181.052,
			2181.889,
			2182.729,
			2185.208,
			2186.85,
			2189.315,
			2190.938,
			2193.385,
			2195.031,
			2195.869,
			2197.527,
			2199.198,
			2200.04,
			2200.886,
			2203.442,
			2205.163,
			2207.77,
			2209.523,
			2211.284,
			2212.168,
			2213.945,
			2214.838,
			2216.631,
			2218.433,
			2221.16,
			2222.992,
			2225.747,
			2226.671,
			2227.594,
			2230.358,
			2232.198,
			2234.943,
			2236.759,
			2239.462,
			2241.251,
			2242.142,
			2243.916,
			2246.563,
			2248.315,
			2250.06,
			2252.657,
			2253.515,
			2254.371,
			2256.929,
			2257.776,
			2258.621,
			2260.303,
			2261.141,
			2262.815,
			2264.484,
			2266.975,
			2268.644,
			2271.14,
			2271.969,
			2272.806,
			2275.325,
			2277,
			2279.539,
			2281.233,
			2283.792,
			2285.513,
			2287.234,
			2288.102,
			2289.854,
			2290.729,
			2292.483,
			2294.262,
			2296.948,
			2298.746,
			2301.453,
			2302.359,
			2303.265,
			2305.966,
			2307.775,
			2310.483,
			2312.292,
			2314.106,
			2315.012,
			2316.826,
			2317.735,
			2318.645,
			2321.384,
			2322.297,
			2323.212,
			2325.973,
			2327.819,
			2329.668,
			2330.592,
			2332.443,
			2334.297,
			2335.22,
			2337.998,
			2339.85,
			2341.697,
			2344.478,
			2346.33,
			2349.124,
			2350.06,
			2350.998,
			2353.829,
			2355.733,
			2358.61,
			2360.543,
			2362.484,
			2363.459,
			2365.419,
			2366.398,
			2368.367,
			2370.346,
			2371.32,
			2373.309,
			2375.325,
			2378.341,
			2379.352,
			2380.366,
			2382.385,
			2384.43,
			2385.457,
			2387.521,
			2388.553,
			2390.634,
			2392.707,
			2395.818,
			2397.925,
			2401.106,
			2402.174,
			2403.247,
			2406.478,
			2408.651,
			2411.934,
			2414.138,
			2416.354,
			2417.466,
			2419.698,
			2420.818,
			2423.064,
			2425.321,
			2426.452,
			2428.723,
			2431.002,
			2434.439,
			2435.589,
			2436.742,
			2440.214,
			2441.377,
			2442.542,
			2446.051,
			2448.4,
			2450.76,
			2454.317,
			2456.7,
			2460.289,
			2461.49,
			2462.694,
			2466.317,
			2468.743,
			2472.397,
			2474.846,
			2477.303,
			2478.534,
			2481.004,
			2483.483,
			2484.726,
			2487.219,
			2488.469,
			2490.977,
			2493.494,
			2497.29,
			2498.56,
			2499.832,
			2503.667,
			2504.949,
			2506.235,
			2510.107,
			2512.7,
			2516.604,
			2519.215,
			2521.833,
			2525.762,
			2527.073,
			2528.384,
			2533.643,
			2534.961,
			2538.922,
			2541.569,
			2545.546,
			2548.204,
			2550.869,
			2552.203,
			2556.215,
			2558.897,
			2561.588,
			2565.638,
			2568.348,
			2572.431,
			2573.795,
			2575.164,
			2579.279,
			2582.034,
			2585.413
		],
		[
			0,
			0.047,
			0.109,
			0.172,
			0.217,
			0.294,
			0.325,
			0.358,
			0.434,
			0.481,
			0.545,
			0.605,
			0.666,
			0.728,
			0.76,
			0.791,
			0.867,
			0.915,
			0.977,
			1.039,
			1.1,
			1.162,
			1.194,
			1.225,
			1.287,
			1.303,
			1.349,
			1.409,
			1.472,
			1.52,
			1.598,
			1.628,
			1.659,
			1.721,
			1.782,
			1.844,
			1.906,
			1.97,
			2.032,
			2.062,
			2.094,
			2.171,
			2.217,
			2.279,
			2.342,
			2.388,
			2.402,
			2.466,
			2.495,
			2.528,
			2.587,
			2.61,
			2.65,
			2.714,
			2.774,
			2.837,
			2.899,
			2.931,
			2.962,
			3.038,
			3.085,
			3.148,
			3.21,
			3.257,
			3.272,
			3.333,
			3.364,
			3.394,
			3.455,
			3.474,
			3.52,
			3.582,
			3.644,
			3.705,
			3.767,
			3.798,
			3.829,
			3.89,
			3.908,
			3.954,
			4.016,
			4.078,
			4.139,
			4.201,
			4.232,
			4.262,
			4.325,
			4.388,
			4.45,
			4.51,
			4.574,
			4.636,
			4.696,
			4.76,
			4.773,
			4.821,
			4.885,
			4.944,
			4.991,
			5.008,
			5.069,
			5.099,
			5.131,
			5.21,
			5.255,
			5.316,
			5.379,
			5.425,
			5.503,
			5.535,
			5.567,
			5.628,
			5.643,
			5.689,
			5.751,
			5.812,
			5.859,
			5.877,
			5.938,
			5.968,
			6,
			6.062,
			6.077,
			6.125,
			6.185,
			6.248,
			6.294,
			6.372,
			6.402,
			6.433,
			6.496,
			6.557,
			6.62,
			6.683,
			6.733,
			6.743,
			6.806,
			6.836,
			6.866,
			6.944,
			6.992,
			7.054,
			7.114,
			7.161,
			7.178,
			7.238,
			7.27,
			7.303,
			7.365,
			7.378,
			7.425,
			7.487,
			7.551,
			7.595,
			7.673,
			7.706,
			7.736,
			7.799,
			7.861,
			7.924,
			7.984,
			8.032,
			8.045,
			8.107,
			8.138,
			8.169,
			8.232,
			8.249,
			8.295,
			8.357,
			8.418,
			8.466,
			8.479,
			8.542,
			8.573,
			8.603,
			8.666,
			8.682,
			8.728,
			8.794,
			8.852,
			8.899,
			8.976,
			9.006,
			9.038,
			9.1,
			9.162,
			9.224,
			9.285,
			9.348,
			9.411,
			9.441,
			9.472,
			9.534,
			9.549,
			9.595,
			9.659,
			9.72,
			9.782,
			9.845,
			9.874,
			9.907,
			9.967,
			10.031,
			10.092,
			10.153,
			10.202,
			10.279,
			10.309,
			10.341,
			10.402,
			10.464,
			10.526,
			10.588,
			10.633,
			10.65,
			10.711,
			10.743,
			10.774,
			10.857,
			10.898,
			10.961,
			11.023,
			11.068,
			11.147,
			11.179,
			11.209,
			11.287,
			11.333,
			11.395,
			11.456,
			11.518,
			11.579,
			11.612,
			11.641,
			11.704,
			11.72,
			11.767,
			11.83,
			11.89,
			11.953,
			12.014,
			12.046,
			12.076,
			12.14,
			12.153,
			12.202,
			12.261,
			12.325,
			12.371,
			12.449,
			12.481,
			12.509,
			12.573,
			12.634,
			12.696,
			12.76,
			12.82,
			12.883,
			12.919,
			12.945,
			13.022,
			13.069,
			13.131,
			13.191,
			13.24,
			13.253,
			13.316,
			13.348,
			13.379,
			13.441,
			13.457,
			13.503,
			13.565,
			13.627,
			13.673,
			13.75,
			13.782,
			13.811,
			13.876,
			13.936,
			14.001,
			14.062,
			14.107,
			14.122,
			14.186,
			14.216,
			14.248,
			14.31,
			14.325,
			14.371,
			14.434,
			14.495,
			14.558,
			14.619,
			14.65,
			14.68,
			14.742,
			14.76,
			14.804,
			14.867,
			14.93,
			14.992,
			15.052,
			15.084,
			15.115,
			15.177,
			15.24,
			15.302,
			15.362,
			15.41,
			15.424,
			15.487,
			15.517,
			15.55,
			15.612,
			15.628,
			15.674,
			15.736,
			15.799,
			15.843,
			15.92,
			15.953,
			15.982,
			16.046,
			16.108,
			16.17,
			16.232,
			16.279,
			16.293,
			16.356,
			16.387,
			16.416,
			16.479,
			16.493,
			16.541,
			16.604,
			16.666,
			16.728,
			16.788,
			16.82,
			16.851,
			16.914,
			16.929,
			16.976,
			17.042,
			17.1,
			17.144,
			17.223,
			17.253,
			17.285,
			17.349,
			17.41,
			17.473,
			17.535,
			17.581,
			17.597,
			17.659,
			17.687,
			17.72,
			17.782,
			17.798,
			17.844,
			17.905,
			17.966,
			18.029,
			18.093,
			18.121,
			18.153,
			18.216,
			18.23,
			18.279,
			18.34,
			18.403,
			18.465,
			18.525,
			18.557,
			18.587,
			18.65,
			18.71,
			18.774,
			18.835,
			18.883,
			18.898,
			18.959,
			18.991,
			19.022,
			19.083,
			19.105,
			19.145,
			19.207,
			19.269,
			19.317,
			19.394,
			19.425,
			19.454,
			19.517,
			19.58,
			19.642,
			19.704,
			19.767,
			19.828,
			19.86,
			19.891,
			19.952,
			19.967,
			20.015,
			20.075,
			20.137,
			20.183,
			20.199,
			20.261,
			20.292,
			20.325,
			20.402,
			20.449,
			20.512,
			20.571,
			20.62,
			20.695,
			20.728,
			20.759,
			20.821,
			20.882,
			20.944,
			21.005,
			21.069,
			21.13,
			21.168,
			21.254,
			21.27,
			21.316,
			21.379,
			21.441,
			21.487,
			21.503,
			21.565,
			21.595,
			21.627,
			21.689,
			21.703,
			21.751,
			21.811,
			21.875,
			21.92,
			21.999,
			22.031,
			22.061,
			22.122,
			22.184,
			22.248,
			22.308,
			22.354,
			22.37,
			22.433,
			22.462,
			22.495,
			22.557,
			22.573,
			22.618,
			22.679,
			22.741,
			22.788,
			22.804,
			22.866,
			22.898,
			22.928,
			23.005,
			23.052,
			23.115,
			23.176,
			23.239,
			23.3,
			23.333,
			23.361,
			23.425,
			23.487,
			23.549,
			23.61,
			23.658,
			23.673,
			23.734,
			23.765,
			23.798,
			23.875,
			23.921,
			23.982,
			24.045,
			24.108,
			24.17,
			24.2,
			24.231,
			24.307,
			24.355,
			24.422,
			24.48,
			24.542,
			24.601,
			24.635,
			24.665,
			24.728,
			24.789,
			24.851,
			24.914,
			24.96,
			24.973,
			25.037,
			25.067,
			25.1,
			25.161,
			25.175,
			25.223,
			25.284,
			25.345,
			25.394,
			25.47,
			25.502,
			25.534,
			25.594,
			25.657,
			25.72,
			25.779,
			25.828,
			25.844,
			25.905,
			25.936,
			25.967,
			26.029,
			26.044,
			26.091,
			26.157,
			26.216,
			26.276,
			26.34,
			26.369,
			26.402,
			26.463,
			26.479,
			26.525,
			26.588,
			26.65,
			26.711,
			26.773,
			26.804,
			26.835,
			26.911,
			26.958,
			27.022,
			27.083,
			27.144,
			27.207,
			27.238,
			27.27,
			27.329,
			27.352,
			27.394,
			27.454,
			27.516,
			27.564,
			27.581,
			27.642,
			27.673,
			27.703,
			27.782,
			27.828,
			27.89,
			27.952,
			27.998,
			28.076,
			28.106,
			28.137,
			28.2,
			28.262,
			28.325,
			28.386,
			28.433,
			28.448,
			28.51,
			28.541,
			28.572,
			28.635,
			28.65,
			28.695,
			28.758,
			28.819,
			28.881,
			28.944,
			28.973,
			29.005,
			29.069,
			29.131,
			29.192,
			29.253,
			29.3,
			29.377,
			29.414,
			29.438,
			29.503,
			29.564,
			29.628,
			29.688,
			29.735,
			29.749,
			29.811,
			29.843,
			29.874,
			29.936,
			29.951,
			29.998,
			30.061,
			30.123,
			30.168,
			30.245,
			30.276,
			30.309,
			30.37,
			30.433,
			30.494,
			30.555,
			30.602,
			30.618,
			30.678,
			30.709,
			30.742,
			30.818,
			30.867,
			30.927,
			30.991,
			31.037,
			31.052,
			31.115,
			31.145,
			31.175,
			31.236,
			31.253,
			31.3,
			31.363,
			31.424,
			31.477,
			31.548,
			31.578,
			31.609,
			31.687,
			31.734,
			31.798,
			31.857,
			31.904,
			31.92,
			31.983,
			32.014,
			32.044,
			32.122,
			32.167,
			32.23,
			32.292,
			32.355,
			32.416,
			32.448,
			32.477,
			32.539,
			32.555,
			32.602,
			32.662,
			32.725,
			32.788,
			32.849,
			32.879,
			32.912,
			32.973,
			33.037,
			33.099,
			33.159,
			33.223,
			33.283,
			33.316,
			33.348,
			33.423,
			33.47,
			33.538,
			33.594,
			33.64,
			33.657,
			33.717,
			33.749,
			33.781,
			33.841,
			33.859,
			33.903,
			33.967,
			34.028,
			34.089,
			34.153,
			34.184,
			34.215,
			34.277,
			34.338,
			34.401,
			34.462,
			34.509,
			34.523,
			34.587,
			34.619,
			34.65,
			34.712,
			34.725,
			34.771,
			34.835,
			34.898,
			34.959,
			35.022,
			35.053,
			35.083,
			35.161,
			35.206,
			35.269,
			35.332,
			35.394,
			35.455,
			35.485,
			35.517,
			35.578,
			35.598,
			35.639,
			35.704,
			35.765,
			35.812,
			35.825,
			35.887,
			35.918,
			35.949,
			36.012,
			36.028,
			36.074,
			36.136,
			36.199,
			36.261,
			36.323,
			36.352,
			36.385,
			36.461,
			36.508,
			36.57,
			36.634,
			36.679,
			36.695,
			36.756,
			36.786,
			36.818,
			36.881,
			36.895,
			36.943,
			37.005,
			37.066,
			37.112,
			37.129,
			37.191,
			37.223,
			37.252,
			37.315,
			37.329,
			37.377,
			37.44,
			37.501,
			37.562,
			37.624,
			37.66,
			37.687,
			37.749,
			37.812,
			37.875,
			37.934,
			37.981,
			37.995,
			38.06,
			38.089,
			38.12,
			38.183,
			38.198,
			38.245,
			38.308,
			38.368,
			38.415,
			38.432,
			38.494,
			38.523,
			38.555,
			38.618,
			38.68,
			38.741,
			38.804,
			38.864,
			38.927,
			38.958,
			38.989,
			39.051,
			39.067,
			39.112,
			39.176,
			39.238,
			39.283,
			39.3,
			39.362,
			39.393,
			39.424,
			39.484,
			39.502,
			39.549,
			39.609,
			39.672,
			39.724,
			39.795,
			39.825,
			39.858,
			39.921,
			39.982,
			40.044,
			40.107,
			40.152,
			40.228,
			40.26,
			40.291,
			40.353,
			40.369,
			40.417,
			40.478,
			40.538,
			40.602,
			40.662,
			40.694,
			40.726,
			40.804,
			40.849,
			40.911,
			40.972,
			41.02,
			41.098,
			41.128,
			41.16,
			41.237,
			41.284,
			41.344,
			41.408,
			41.454,
			41.469,
			41.532,
			41.564,
			41.594,
			41.671,
			41.718,
			41.783,
			41.841,
			41.889,
			41.904,
			41.967,
			41.997,
			42.026,
			42.091,
			42.105,
			42.152,
			42.215,
			42.276,
			42.337,
			42.399,
			42.432,
			42.462,
			42.54,
			42.587,
			42.65,
			42.711,
			42.758,
			42.773,
			42.833,
			42.866,
			42.897,
			42.958,
			42.974,
			43.019,
			43.082,
			43.145,
			43.19,
			43.207,
			43.267,
			43.299,
			43.33,
			43.407,
			43.455,
			43.517,
			43.579,
			43.625,
			43.702,
			43.733,
			43.765,
			43.825,
			43.845,
			43.889,
			43.949,
			44.013,
			44.058,
			44.074,
			44.137,
			44.167,
			44.199,
			44.261,
			44.276,
			44.322,
			44.385,
			44.448,
			44.493,
			44.569,
			44.601,
			44.632,
			44.709,
			44.756,
			44.817,
			44.882,
			44.927,
			45.004,
			45.036,
			45.067,
			45.129,
			45.144,
			45.19,
			45.253,
			45.314,
			45.377,
			45.439,
			45.471,
			45.501,
			45.562,
			45.579,
			45.626,
			45.686,
			45.749,
			45.795,
			45.871,
			45.908,
			45.934,
			45.995,
			46.059,
			46.121,
			46.182,
			46.227,
			46.244,
			46.306,
			46.337,
			46.369,
			46.444,
			46.493,
			46.555,
			46.617,
			46.678,
			46.74,
			46.771,
			46.801,
			46.865,
			46.881,
			46.926,
			46.988,
			47.05,
			47.111,
			47.174,
			47.205,
			47.236,
			47.298,
			47.361,
			47.424,
			47.484,
			47.532,
			47.548,
			47.608,
			47.639,
			47.671,
			47.732,
			47.749,
			47.794,
			47.857,
			47.918,
			47.97,
			48.043,
			48.076,
			48.106,
			48.167,
			48.228,
			48.292,
			48.354,
			48.4,
			48.478,
			48.508,
			48.54,
			48.6,
			48.663,
			48.725,
			48.788,
			48.85,
			48.912,
			48.942,
			48.972,
			49.035,
			49.049,
			49.097,
			49.16,
			49.221,
			49.267,
			49.344,
			49.376,
			49.407,
			49.469,
			49.532,
			49.593,
			49.655,
			49.716,
			49.779,
			49.811,
			49.842,
			49.918,
			49.966,
			50.032,
			50.088,
			50.135,
			50.152,
			50.212,
			50.244,
			50.276,
			50.337,
			50.352,
			50.4,
			50.46,
			50.522,
			50.57,
			50.648,
			50.678,
			50.71,
			50.771,
			50.832,
			50.894,
			50.957,
			51.004,
			51.019,
			51.082,
			51.112,
			51.144,
			51.206,
			51.221,
			51.267,
			51.331,
			51.391,
			51.436,
			51.453,
			51.515,
			51.547,
			51.578,
			51.655,
			51.703,
			51.762,
			51.824,
			51.871,
			51.949,
			51.979,
			52.013,
			52.072,
			52.094,
			52.135,
			52.199,
			52.26,
			52.323,
			52.385,
			52.415,
			52.445,
			52.508,
			52.524,
			52.57,
			52.633,
			52.694,
			52.739,
			52.754,
			52.819,
			52.85,
			52.878,
			52.941,
			52.957,
			53.005,
			53.067,
			53.127,
			53.19,
			53.252,
			53.283,
			53.312,
			53.392,
			53.438,
			53.499,
			53.562,
			53.609,
			53.624,
			53.685,
			53.717,
			53.746,
			53.81,
			53.826,
			53.872,
			53.934,
			53.995,
			54.057,
			54.12,
			54.155,
			54.183,
			54.245,
			54.306,
			54.368,
			54.428,
			54.476,
			54.555,
			54.585,
			54.616,
			54.678,
			54.694,
			54.741,
			54.802,
			54.865,
			54.91,
			54.926,
			54.989,
			55.02,
			55.05,
			55.126,
			55.175,
			55.235,
			55.299,
			55.344,
			55.423,
			55.453,
			55.484,
			55.545,
			55.609,
			55.67,
			55.733,
			55.794,
			55.855,
			55.887,
			55.918,
			55.979,
			55.995,
			56.041,
			56.105,
			56.165,
			56.228,
			56.29,
			56.322,
			56.352,
			56.412,
			56.429,
			56.477,
			56.54,
			56.601,
			56.661,
			56.724,
			56.756,
			56.787,
			56.849,
			56.91,
			56.972,
			57.033,
			57.097,
			57.16,
			57.189,
			57.22,
			57.298,
			57.344,
			57.406,
			57.469,
			57.516,
			57.529,
			57.592,
			57.624,
			57.655,
			57.716,
			57.732,
			57.779,
			57.841,
			57.903,
			57.949,
			58.026,
			58.059,
			58.089,
			58.152,
			58.211,
			58.28,
			58.337,
			58.398,
			58.461,
			58.492,
			58.521,
			58.586,
			58.6,
			58.647,
			58.709,
			58.772,
			58.833,
			58.895,
			58.926,
			58.957,
			59.035,
			59.082,
			59.142,
			59.204,
			59.252,
			59.267,
			59.327,
			59.358,
			59.39,
			59.454,
			59.469,
			59.515,
			59.578,
			59.638,
			59.686,
			59.7,
			59.762,
			59.794,
			59.824,
			59.903,
			59.949,
			60.012,
			60.072,
			60.12,
			60.198,
			60.229,
			60.258,
			60.322,
			60.383,
			60.447,
			60.508,
			60.554,
			60.569,
			60.632,
			60.662,
			60.694,
			60.755,
			60.771,
			60.817,
			60.878,
			60.942,
			60.988,
			61.003,
			61.066,
			61.095,
			61.128,
			61.189,
			61.203,
			61.252,
			61.314,
			61.376,
			61.438,
			61.5,
			61.53,
			61.561,
			61.623,
			61.685,
			61.745,
			61.81,
			61.857,
			61.872,
			61.934,
			61.965,
			61.994,
			62.057,
			62.072,
			62.12,
			62.182,
			62.244,
			62.29,
			62.306,
			62.368,
			62.403,
			62.429,
			62.492,
			62.554,
			62.616,
			62.679,
			62.724,
			62.801,
			62.832,
			62.862,
			62.924,
			62.988,
			63.051,
			63.113,
			63.159,
			63.174,
			63.236,
			63.267,
			63.296,
			63.361,
			63.376,
			63.423,
			63.482,
			63.546,
			63.592,
			63.609,
			63.669,
			63.702,
			63.733,
			63.808,
			63.855,
			63.917,
			63.98,
			64.025,
			64.106,
			64.133,
			64.166,
			64.244,
			64.288,
			64.35,
			64.413,
			64.475,
			64.539,
			64.569,
			64.599,
			64.678,
			64.725,
			64.785,
			64.848,
			64.895,
			64.971,
			65.002,
			65.033,
			65.111,
			65.158,
			65.216
		]
	],
	'1865_1866_7982': [
		[
			108.176
		],
		[
			0
		],
		[
			0,
			1.661,
			4.248,
			5.544,
			8.142,
			10.748,
			14.657,
			17.27,
			21.203,
			22.517,
			23.833,
			27.79,
			30.43,
			34.403,
			37.056,
			41.057,
			43.735,
			45.079,
			47.774,
			50.476,
			51.831,
			54.544,
			57.264,
			61.357,
			62.725,
			64.094,
			66.838,
			69.59,
			70.968,
			73.731,
			75.114,
			77.889,
			80.67,
			84.854,
			87.651,
			91.859,
			93.264,
			94.671,
			98.902,
			101.73,
			105.985,
			108.83,
			111.681,
			113.109,
			115.97,
			117.404,
			120.275,
			123.153,
			124.594,
			127.482,
			130.376,
			134.729,
			136.183,
			137.639,
			142.016,
			143.478,
			144.942,
			149.344,
			152.286,
			156.711,
			159.667,
			162.631,
			167.089,
			168.578,
			170.068,
			174.549,
			176.045,
			177.543,
			182.045,
			185.054,
			188.069,
			189.578,
			192.602,
			195.632,
			197.148,
			201.707,
			204.754,
			207.807,
			212.397,
			215.466,
			220.083,
			221.627,
			223.172,
			227.817,
			230.918,
			235.578,
			238.69,
			241.805,
			243.365,
			246.487,
			248.05,
			251.179,
			254.313,
			255.881,
			257.451,
			262.168,
			265.319,
			268.476,
			270.056,
			273.221,
			276.391,
			277.978,
			281.155,
			282.746,
			285.927,
			289.111,
			293.894,
			297.09,
			301.892,
			303.495,
			305.099,
			309.917,
			313.134,
			317.968,
			321.197,
			324.431,
			326.05,
			329.29,
			330.913,
			334.163,
			337.419,
			339.049,
			342.314,
			345.584,
			348.858,
			352.135,
			353.775,
			357.056,
			360.341,
			361.985,
			366.924,
			370.221,
			373.522,
			378.482,
			381.794,
			386.767,
			388.426,
			390.085,
			395.072,
			398.403,
			403.413,
			406.763,
			411.799,
			415.163,
			416.846,
			420.213,
			423.577,
			425.255,
			428.596,
			431.912,
			436.823,
			440.05,
			444.82,
			446.391,
			447.952,
			452.579,
			455.618,
			458.623,
			463.063,
			465.976,
			470.273,
			471.687,
			473.091,
			477.245,
			479.968,
			483.982,
			486.611,
			489.203,
			490.485,
			493.021,
			495.519,
			496.754,
			499.197,
			500.404,
			502.794,
			505.148,
			508.617,
			510.882,
			514.211,
			515.301,
			516.383,
			519.57,
			521.647,
			524.693,
			526.674,
			528.619,
			529.577,
			531.459,
			532.387,
			533.306,
			536.012,
			536.897,
			537.775,
			540.357,
			542.042,
			543.691,
			544.501,
			546.098,
			547.664,
			548.434,
			549.946,
			550.689,
			552.145,
			553.574,
			555.637,
			556.972,
			558.922,
			559.562,
			560.198,
			562.065,
			563.273,
			565.059,
			566.24,
			567.415,
			568,
			569.165,
			569.746,
			570.907,
			572.074,
			572.661,
			573.832,
			575.002,
			576.76,
			577.355,
			577.949,
			579.136,
			580.324,
			580.919,
			582.13,
			582.753,
			583.998,
			585.246,
			587.133,
			588.477,
			590.5,
			591.177,
			591.857,
			594.046,
			595.525,
			597.79,
			599.377,
			600.964,
			601.758,
			603.366,
			604.174,
			605.789,
			607.405,
			608.194,
			609.752,
			611.307,
			613.614,
			614.359,
			615.103,
			617.329,
			618.07,
			618.801,
			620.235,
			620.951,
			622.382,
			623.798,
			625.849,
			627.213,
			629.222,
			629.868,
			630.513,
			632.445,
			633.09,
			633.735,
			635.596,
			636.832,
			638.691,
			639.932,
			640.551,
			641.789,
			643.646,
			644.937,
			646.261,
			648.246,
			649.584,
			651.691,
			652.392,
			653.094,
			655.192,
			656.581,
			658.658,
			660.054,
			661.466,
			663.583,
			664.292,
			665.004,
			667.174,
			667.903,
			668.634,
			670.84,
			672.319,
			673.809,
			674.559,
			676.073,
			677.607,
			678.379,
			679.935,
			680.704,
			682.276,
			683.847,
			686.229,
			687.849,
			690.266,
			691.055,
			691.858,
			694.284,
			695.92,
			698.393,
			700.039,
			701.689,
			702.52,
			704.191,
			705.038,
			706.745,
			708.466,
			709.331,
			711.089,
			712.861,
			714.647,
			716.448,
			717.354,
			719.176,
			721.002,
			721.918,
			723.762,
			724.688,
			726.543,
			728.41,
			731.23,
			733.14,
			736.006,
			736.969,
			737.934,
			740.843,
			742.819,
			745.809,
			747.822,
			750.863,
			752.905,
			753.93,
			755.988,
			758.058,
			759.096,
			761.196,
			763.277,
			766.398,
			767.45,
			768.505,
			771.69,
			772.757,
			773.828,
			777.074,
			779.239,
			781.401,
			784.692,
			786.925,
			790.25,
			791.37,
			792.493,
			795.875,
			797.007,
			798.141,
			801.555,
			803.843,
			807.289,
			809.597,
			811.917,
			813.081,
			816.588,
			818.942,
			821.307,
			824.876,
			827.271,
			830.883,
			832.089,
			833.298,
			836.934,
			839.366,
			843.034,
			845.491,
			847.96,
			851.679,
			852.922,
			854.168,
			857.917,
			859.171,
			860.427,
			864.221,
			866.763,
			869.317,
			870.597,
			873.155,
			875.721,
			877.004,
			880.866,
			883.447,
			886.037,
			889.948,
			892.567,
			896.502,
			897.817,
			899.134,
			903.092,
			905.737,
			909.714,
			912.373,
			915.038,
			916.373,
			919.048,
			920.389,
			923.074,
			925.767,
			927.115,
			928.466,
			932.527,
			935.245,
			937.968,
			939.332,
			942.064,
			944.802,
			946.173,
			948.922,
			950.3,
			953.061,
			955.827,
			959.975,
			962.728,
			966.817,
			968.166,
			969.506,
			973.472,
			976.071,
			979.9,
			982.406,
			984.874,
			986.094,
			988.505,
			989.698,
			992.056,
			994.38,
			995.529,
			997.798,
			1000.029,
			1003.307,
			1005.446,
			1008.586,
			1009.614,
			1010.633,
			1012.644,
			1013.635,
			1015.591,
			1017.51,
			1020.321,
			1022.151,
			1024.836,
			1025.714,
			1026.584,
			1029.154,
			1030.812,
			1033.224,
			1034.78,
			1036.302,
			1037.053,
			1038.503,
			1039.926,
			1040.63,
			1042.03,
			1042.721,
			1044.088,
			1045.45,
			1047.495,
			1048.878,
			1050.952,
			1051.645,
			1052.338,
			1054.456,
			1055.868,
			1057.996,
			1059.424,
			1060.85,
			1061.564,
			1062.999,
			1063.722,
			1064.443,
			1066.605,
			1067.325,
			1068.057,
			1070.254,
			1071.716,
			1073.198,
			1073.949,
			1075.45,
			1076.95,
			1077.707,
			1080.049,
			1081.61,
			1083.188,
			1085.594,
			1087.201,
			1089.599,
			1090.397,
			1091.196,
			1093.583,
			1095.159,
			1097.527,
			1099.113,
			1100.698,
			1101.491,
			1103.097,
			1103.927,
			1105.588,
			1107.25,
			1108.101,
			1108.977,
			1111.601,
			1113.358,
			1115.126,
			1116.01,
			1117.778,
			1119.539,
			1120.417,
			1122.172,
			1123.044,
			1124.749,
			1126.449,
			1128.972,
			1130.626,
			1133.107,
			1133.944,
			1134.781,
			1137.29,
			1138.129,
			1138.987,
			1141.558,
			1143.278,
			1145.019,
			1145.891,
			1147.639,
			1148.521,
			1150.303,
			1152.99,
			1154.792,
			1156.603,
			1159.331,
			1160.241,
			1161.153,
			1163.878,
			1164.764,
			1165.653,
			1168.333,
			1170.042,
			1171.76,
			1174.338,
			1176.048,
			1178.636,
			1179.505,
			1180.377,
			1183.008,
			1184.782,
			1187.467,
			1189.277,
			1192.021,
			1193.868,
			1194.797,
			1196.665,
			1199.489,
			1201.388,
			1203.3,
			1206.197,
			1208.145,
			1211.098,
			1212.089,
			1213.083,
			1216.086,
			1218.087,
			1221.132,
			1223.177,
			1225.23,
			1228.332,
			1229.372,
			1230.414,
			1233.547,
			1234.593,
			1235.641,
			1238.799,
			1240.915,
			1243.05,
			1244.109,
			1246.228,
			1248.342,
			1249.398,
			1252.561,
			1254.665,
			1256.763,
			1259.9,
			1261.981,
			1265.098,
			1266.135,
			1267.172,
			1270.283,
			1272.355,
			1275.475,
			1277.561,
			1280.74,
			1282.888,
			1283.962,
			1286.207,
			1289.594,
			1291.903,
			1294.217,
			1296.554,
			1300.049,
			1302.353,
			1304.644,
			1305.759,
			1307.992,
			1309.112,
			1311.297,
			1313.489,
			1316.73,
			1318.892,
			1322.125,
			1323.207,
			1324.292,
			1327.54,
			1329.716,
			1332.982,
			1335.173,
			1338.476,
			1340.692,
			1341.803,
			1344.033,
			1346.282,
			1347.411,
			1349.679,
			1351.968,
			1355.428,
			1356.593,
			1357.758,
			1361.275,
			1362.455,
			1363.637,
			1367.198,
			1369.581,
			1371.973,
			1375.552,
			1377.978,
			1381.584,
			1382.765,
			1383.968,
			1387.581,
			1388.789,
			1390,
			1393.657,
			1396.111,
			1398.578,
			1399.815,
			1402.301,
			1403.547,
			1406.051,
			1408.57,
			1409.833,
			1412.365,
			1414.907,
			1418.726,
			1421.256,
			1425.044,
			1426.313,
			1427.582,
			1431.409,
			1433.95,
			1437.805,
			1440.383,
			1444.261,
			1446.857,
			1448.157,
			1449.46,
			1453.376,
			1454.681,
			1455.988,
			1459.92,
			1462.555,
			1466.526,
			1469.185,
			1471.849,
			1473.183,
			1475.855,
			1477.191,
			1479.869,
			1482.551,
			1486.585,
			1489.284,
			1493.345,
			1494.703,
			1496.063,
			1500.153,
			1502.887,
			1507.001,
			1509.752,
			1512.509,
			1513.891,
			1516.659,
			1518.046,
			1519.434,
			1525.009,
			1527.807,
			1530.612,
			1533.423,
			1536.241,
			1537.652,
			1540.479,
			1543.312,
			1544.73,
			1548.995,
			1551.845,
			1554.702,
			1558.999,
			1561.872,
			1566.193,
			1567.637,
			1569.082,
			1573.426,
			1576.33,
			1580.696,
			1583.613,
			1587.998,
			1590.928,
			1592.394,
			1595.331,
			1599.747,
			1602.698,
			1605.655,
			1610.102,
			1611.586,
			1613.072,
			1617.537,
			1619.029,
			1620.521,
			1625.009,
			1628.007,
			1631.011,
			1635.527,
			1638.543,
			1643.078,
			1644.592,
			1646.107,
			1650.66,
			1653.7,
			1658.274,
			1661.332,
			1664.392,
			1665.917,
			1668.961,
			1671.979,
			1673.477,
			1676.443,
			1677.908,
			1680.805,
			1683.656,
			1687.856,
			1690.612,
			1694.688,
			1696.03,
			1697.363,
			1701.312,
			1703.893,
			1707.685,
			1710.154,
			1712.589,
			1713.8,
			1716.196,
			1717.381,
			1718.565,
			1722.06,
			1723.195,
			1724.319,
			1727.629,
			1729.766,
			1731.866,
			1732.9,
			1734.936,
			1736.935,
			1737.92,
			1739.877,
			1740.835,
			1742.731,
			1744.599,
			1747.369,
			1749.196,
			1751.934,
			1752.845,
			1753.753,
			1756.482,
			1758.308,
			1761.052,
			1762.895,
			1764.774,
			1767.6,
			1768.591,
			1770.571,
			1772.552,
			1773.594,
			1775.673,
			1777.75,
			1779.844,
			1781.928,
			1782.956,
			1784.976,
			1786.989,
			1787.966,
			1789.858,
			1790.801,
			1792.676,
			1794.482,
			1797.184,
			1798.956,
			1801.6,
			1802.481,
			1803.358,
			1805.99,
			1807.752,
			1810.424,
			1812.215,
			1814.932,
			1816.759,
			1817.677,
			1819.526,
			1821.387,
			1822.322,
			1824.205,
			1826.099,
			1828.962,
			1829.921,
			1830.866,
			1832.799,
			1834.742,
			1835.719,
			1837.697,
			1838.688,
			1840.681,
			1842.669,
			1845.693,
			1847.722,
			1850.775,
			1851.797,
			1852.839,
			1855.943,
			1858.024,
			1861.188,
			1863.282,
			1865.399,
			1866.459,
			1868.605,
			1869.67,
			1871.788,
			1873.948,
			1875.021,
			1877.169,
			1879.322,
			1882.564,
			1884.717,
			1887.99,
			1889.084,
			1890.179,
			1893.479,
			1895.689,
			1899.022,
			1901.256,
			1903.499,
			1906.881,
			1908.01,
			1909.138,
			1912.516,
			1913.636,
			1914.753,
			1918.065,
			1920.236,
			1922.372,
			1923.427,
			1925.51,
			1927.555,
			1928.566,
			1930.569,
			1931.56,
			1933.533,
			1935.46,
			1938.293,
			1940.146,
			1942.875,
			1943.773,
			1944.669,
			1947.327,
			1949.083,
			1951.698,
			1953.431,
			1956.019,
			1957.736,
			1958.593,
			1960.31,
			1962.88,
			1963.735,
			1966.314,
			1968.031,
			1969.757,
			1970.625,
			1972.358,
			1974.093,
			1974.968,
			1976.716,
			1977.589,
			1979.336,
			1981.084,
			1983.701,
			1985.442,
			1988.045,
			1988.911,
			1989.784,
			1992.398,
			1994.138,
			1996.768,
			1998.515,
			2000.271,
			2001.15,
			2002.903,
			2003.778,
			2005.547,
			2007.316,
			2008.198,
			2009.96,
			2011.715,
			2014.337,
			2015.203,
			2016.067,
			2017.791,
			2019.513,
			2020.37,
			2022.933,
			2024.641,
			2026.345,
			2028.894,
			2030.604,
			2033.168,
			2034.022,
			2034.886,
			2037.497,
			2039.235,
			2041.875,
			2043.634,
			2045.408,
			2046.3,
			2048.086,
			2048.979,
			2050.777,
			2053.481,
			2055.282,
			2057.071,
			2059.76,
			2060.632,
			2061.506,
			2064.133,
			2065.01,
			2065.88,
			2068.5,
			2070.255,
			2072.902,
			2074.671,
			2076.434,
			2079.08,
			2079.963,
			2080.843,
			2083.482,
			2084.364,
			2085.246,
			2087.893,
			2089.661,
			2092.325,
			2094.107,
			2095.889,
			2096.78,
			2098.566,
			2099.462,
			2101.236,
			2103.009,
			2105.677,
			2107.454,
			2110.14,
			2111.062,
			2111.986,
			2114.776,
			2116.704,
			2119.632,
			2121.599,
			2123.578,
			2124.572,
			2126.541,
			2127.524,
			2129.48,
			2132.46,
			2133.452,
			2136.449,
			2138.466,
			2141.507,
			2143.524,
			2145.577,
			2146.591,
			2149.643,
			2151.682,
			2153.717,
			2156.777,
			2158.831,
			2161.929,
			2162.949,
			2163.987,
			2167.109,
			2169.2,
			2172.375,
			2174.493,
			2176.604,
			2177.674,
			2179.818,
			2180.893,
			2183.045,
			2185.205,
			2186.287,
			2188.457,
			2190.636,
			2193.928,
			2195.029,
			2196.133,
			2199.458,
			2200.571,
			2201.691,
			2205.068,
			2207.337,
			2209.618,
			2213.06,
			2215.367,
			2218.845,
			2220.009,
			2221.175,
			2224.683,
			2227.031,
			2230.568,
			2232.937,
			2235.314,
			2236.507,
			2238.903,
			2240.104,
			2242.515,
			2244.936,
			2246.15,
			2248.584,
			2251.02,
			2254.649,
			2257.036,
			2260.545,
			2261.697,
			2262.838,
			2266.208,
			2268.407,
			2270.569,
			2273.741,
			2275.808,
			2278.84,
			2279.832,
			2280.815,
			2283.704,
			2284.647,
			2285.582,
			2288.33,
			2290.117,
			2292.734,
			2294.431,
			2296.091,
			2296.907,
			2298.512,
			2299.301,
			2300.851,
			2302.364,
			2304.567,
			2305.99,
			2308.058,
			2308.73,
			2309.395,
			2311.337,
			2312.589,
			2314.397,
			2315.558,
			2316.684,
			2317.236,
			2318.323,
			2318.858,
			2319.397,
			2320.967,
			2321.487,
			2322.005,
			2323.56,
			2324.594,
			2325.628,
			2326.145,
			2327.18,
			2328.191,
			2328.695,
			2329.698,
			2330.199,
			2331.197,
			2332.193,
			2333.67,
			2334.644,
			2336.095,
			2336.577,
			2337.059,
			2338.598,
			2339.627,
			2341.165,
			2342.189,
			2343.917,
			2345.076,
			2345.655,
			2346.815,
			2348.035,
			2348.668,
			2349.935,
			2351.204,
			2352.478,
			2353.752,
			2354.391,
			2355.673,
			2356.962,
			2357.594,
			2358.805,
			2359.413,
			2360.634,
			2361.864,
			2363.566,
			2364.671,
			2366.342,
			2366.909,
			2367.476,
			2369.102,
			2370.205,
			2371.888,
			2373.055,
			2374.262,
			2374.872,
			2376.104,
			2376.726,
			2378.051,
			2379.492,
			2380.219,
			2381.701,
			2383.272,
			2385.81,
			2386.667,
			2387.529,
			2390.119,
			2390.987,
			2391.859,
			2394.243,
			2395.777,
			2398.08,
			2399.54,
			2401.012,
			2403.242,
			2403.984,
			2404.73,
			2406.989,
			2408.524,
			2410.873,
			2412.456,
			2414.07,
			2414.885,
			2416.523,
			2418.177,
			2419.012,
			2421.522,
			2423.195,
			2424.88,
			2427.431,
			2429.15,
			2431.754,
			2432.629,
			2433.509,
			2436.171,
			2437.96,
			2440.67,
			2442.478,
			2444.312,
			2445.233,
			2447.083,
			2448.013,
			2448.945,
			2451.758,
			2452.7,
			2453.645,
			2456.49,
			2458.398,
			2460.311,
			2461.27,
			2463.211,
			2465.129,
			2466.116,
			2468.063,
			2469.023,
			2470.984,
			2472.955,
			2475.925,
			2477.909,
			2480.891,
			2481.904,
			2482.885,
			2485.883,
			2487.89,
			2490.91,
			2492.951,
			2495.981,
			2498.013,
			2499.025,
			2501.042,
			2503.044,
			2504.038,
			2506.004,
			2507.941,
			2510.78,
			2511.708,
			2512.628,
			2514.438,
			2516.213,
			2517.087,
			2518.823,
			2519.67,
			2521.342,
			2522.988,
			2525.417,
			2527.012,
			2529.384,
			2530.165,
			2530.945,
			2533.279,
			2534.828,
			2537.132,
			2538.666,
			2540.991,
			2542.544,
			2544.097,
			2544.896,
			2547.313,
			2548.921,
			2550.569,
			2553.065,
			2553.897,
			2554.735,
			2557.294,
			2558.145,
			2558.996,
			2561.544,
			2563.24,
			2564.932,
			2567.443,
			2569.143,
			2571.666,
			2572.51,
			2573.356,
			2575.889,
			2576.731,
			2577.575,
			2580.117,
			2581.804,
			2583.502,
			2584.355,
			2586.057,
			2587.763,
			2588.621,
			2590.343,
			2591.207,
			2592.943,
			2594.691,
			2597.338,
			2599.117,
			2601.812,
			2602.717,
			2603.625,
			2606.368,
			2608.212,
			2611.001,
			2612.875,
			2615.692,
			2617.595,
			2618.55,
			2619.509,
			2622.399,
			2623.366,
			2624.335,
			2627.241,
			2629.175,
			2631.102,
			2632.062,
			2633.975,
			2635.876,
			2636.823,
			2639.668,
			2641.545,
			2643.415,
			2646.204,
			2648.053,
			2650.817,
			2651.739,
			2652.659,
			2655.407,
			2657.243,
			2659.982,
			2661.811,
			2663.637,
			2664.546,
			2666.375,
			2667.301,
			2669.145,
			2670.987,
			2671.928,
			2673.804,
			2675.677,
			2677.565,
			2679.451,
			2680.393,
			2682.281,
			2684.166,
			2685.108,
			2686.987,
			2687.923,
			2689.793,
			2692.584,
			2694.435,
			2696.283,
			2699.034,
			2699.95,
			2700.866,
			2703.604,
			2705.424,
			2708.117,
			2709.897,
			2711.667,
			2712.536,
			2714.27,
			2715.136,
			2716.862,
			2718.57,
			2719.423,
			2721.128,
			2722.817,
			2725.34,
			2726.181,
			2727.019,
			2729.518,
			2730.351,
			2731.185,
			2732.846,
			2733.676,
			2735.336,
			2737.001,
			2739.507,
			2741.182,
			2743.752,
			2744.614,
			2745.475,
			2748.083,
			2748.959,
			2749.835,
			2752.467,
			2754.217,
			2755.985,
			2756.872,
			2758.657,
			2760.455,
			2761.358,
			2763.191,
			2764.104,
			2765.94,
			2767.789,
			2770.585,
			2772.465,
			2775.305,
			2776.258,
			2777.214,
			2780.092,
			2782.023,
			2784.93,
			2786.862,
			2789.797,
			2791.766,
			2792.755,
			2793.744,
			2796.73,
			2797.732,
			2798.736,
			2801.766,
			2803.804,
			2805.855,
			2806.885,
			2808.956,
			2811.038,
			2812.079,
			2815.206,
			2817.3,
			2819.406,
			2822.584,
			2824.719,
			2827.938,
			2829.018,
			2830.1,
			2833.379,
			2835.567,
			2838.869,
			2841.083,
			2843.308,
			2844.424,
			2846.663,
			2848.911,
			2850.038,
			2852.3,
			2853.434,
			2855.708,
			2857.992,
			2860.284,
			2863.736,
			2866.052,
			2868.376,
			2869.546,
			2871.894,
			2873.071,
			2875.45,
			2877.845,
			2881.502,
			2883.964,
			2887.712,
			2888.965,
			2890.221,
			2893.999,
			2896.501,
			2900.272,
			2902.801,
			2906.62,
			2909.179,
			2910.452,
			2913,
			2915.556,
			2916.838,
			2919.407,
			2921.975,
			2925.83,
			2927.113,
			2928.396,
			2932.258,
			2933.548,
			2934.84,
			2938.741,
			2941.355,
			2945.298,
			2947.938,
			2950.582,
			2954.55,
			2957.172,
			2961.141,
			2962.459,
			2963.779,
			2967.72,
			2970.342,
			2974.239,
			2976.82,
			2979.377,
			2980.646,
			2983.174,
			2984.425,
			2986.901,
			2990.55,
			2992.938,
			2995.292,
			2998.757,
			3001.019,
			3004.343,
			3005.432,
			3006.511,
			3008.641,
			3009.709,
			3011.781,
			3014.809,
			3016.789,
			3018.742,
			3021.633,
			3022.593,
			3023.547,
			3026.392,
			3028.27,
			3031.071,
			3032.918,
			3034.756,
			3035.674,
			3037.509,
			3039.342,
			3040.258,
			3042.098,
			3043.033,
			3044.902,
			3046.772,
			3049.623,
			3051.526,
			3054.401,
			3055.362,
			3056.324,
			3059.236,
			3061.188,
			3064.139,
			3066.114,
			3068.105,
			3069.107,
			3071.109,
			3072.11,
			3074.12,
			3076.125,
			3077.126,
			3079.143,
			3081.155,
			3083.167,
			3085.176,
			3086.177,
			3088.164,
			3090.129,
			3091.111,
			3094.037,
			3095.976,
			3097.909,
			3100.771,
			3102.685,
			3105.564,
			3106.521,
			3107.48,
			3110.34,
			3112.254,
			3115.102,
			3117.005,
			3118.909,
			3119.861,
			3121.771,
			3123.684,
			3124.641,
			3126.56,
			3127.523,
			3129.45,
			3131.381,
			3134.308,
			3135.294,
			3136.284,
			3139.279,
			3140.275,
			3141.291,
			3144.354,
			3146.415,
			3148.502,
			3151.608,
			3153.691,
			3156.822,
			3157.87,
			3158.921,
			3162.102,
			3164.233,
			3167.463,
			3169.612,
			3171.808,
			3172.9,
			3175.092,
			3176.191,
			3178.399,
			3180.598,
			3181.729,
			3183.942,
			3186.18,
			3189.571,
			3191.808,
			3195.208,
			3196.346,
			3197.485,
			3200.938,
			3203.217,
			3206.691,
			3209.004,
			3211.306,
			3214.804,
			3215.976,
			3217.151,
			3221.873,
			3223.06,
			3226.635,
			3229.032,
			3231.438,
			3232.646,
			3235.067,
			3236.282,
			3238.72,
			3241.167,
			3242.394,
			3244.855,
			3247.323,
			3251.035,
			3253.519,
			3257.261,
			3258.514,
			3259.769,
			3263.549,
			3266.081,
			3269.897,
			3272.453,
			3276.302,
			3278.878,
			3280.169,
			3282.757,
			3286.651,
			3287.953,
			3291.862,
			3294.472,
			3297.09,
			3298.401,
			3301.028,
			3303.662,
			3304.98,
			3307.623,
			3308.946,
			3311.599,
			3315.593,
			3318.265,
			3320.947,
			3324.984,
			3326.334,
			3327.686,
			3331.753,
			3334.473,
			3338.568,
			3341.307,
			3344.053,
			3345.428,
			3348.185,
			3349.566,
			3352.334,
			3355.11,
			3356.5,
			3359.287,
			3362.082,
			3366.285,
			3367.688,
			3369.091,
			3371.886,
			3374.659,
			3376.035,
			3378.76,
			3380.109,
			3382.777,
			3385.408,
			3389.283,
			3391.82,
			3395.555,
			3396.782,
			3397.999,
			3401.597,
			3403.952,
			3407.426,
			3409.697,
			3411.934,
			3413.04,
			3415.222,
			3416.3,
			3418.424,
			3420.511,
			3421.538,
			3423.557,
			3425.54,
			3428.44,
			3430.326,
			3433.094,
			3434,
			3434.898,
			3437.544,
			3439.268,
			3441.784,
			3443.417,
			3445.015,
			3447.343,
			3448.101,
			3448.85,
			3451.044,
			3451.757,
			3452.464,
			3454.534,
			3455.881,
			3457.208,
			3457.878,
			3459.185,
			3460.478,
			3461.121,
			3462.4,
			3463.036,
			3464.303,
			3465.563,
			3467.445,
			3468.682,
			3470.526,
			3471.141,
			3471.755,
			3473.542,
			3474.717,
			3476.48,
			3477.671,
			3478.878,
			3479.481,
			3480.686,
			3481.288,
			3482.53,
			3483.898,
			3484.581,
			3485.264,
			3487.313,
			3488.779,
			3490.245,
			3490.978,
			3492.447,
			3493.886,
			3494.606,
			3496.047,
			3496.77,
			3498.24,
			3499.729,
			3501.974,
			3503.469,
			3505.714,
			3506.466,
			3507.22,
			3509.386,
			3510.822,
			3512.979,
			3514.342,
			3516.405,
			3517.795,
			3518.474,
			3519.834,
			3521.894,
			3523.289,
			3524.709,
			3526.862,
			3527.587,
			3528.322,
			3530.566,
			3531.322,
			3532.081,
			3533.619,
			3534.402,
			3535.979,
			3537.571,
			3540.025,
			3541.687,
			3544.197,
			3545.041,
			3545.887,
			3548.434,
			3550.136,
			3552.689,
			3554.399,
			3556.117,
			3556.98,
			3558.701,
			3559.572,
			3561.326,
			3563.092,
			3563.98,
			3565.766,
			3567.565,
			3570.288,
			3571.202,
			3572.119,
			3574.879,
			3575.802,
			3576.743,
			3579.528,
			3581.394,
			3583.251,
			3586.079,
			3587.98,
			3590.858,
			3591.825,
			3592.794,
			3595.728,
			3596.717,
			3597.709,
			3600.707,
			3602.725,
			3604.756,
			3605.772,
			3607.811,
			3609.863,
			3610.89,
			3612.95,
			3613.984,
			3616.061,
			3618.146,
			3621.291,
			3623.387,
			3626.544,
			3627.602,
			3628.662,
			3631.859,
			3634.002,
			3637.236,
			3639.398,
			3641.561,
			3642.638,
			3644.78,
			3645.844,
			3646.9,
			3650.019,
			3651.04,
			3652.052,
			3655.036,
			3656.979,
			3658.888,
			3659.829,
			3661.684,
			3663.502,
			3664.398,
			3666.164,
			3667.035,
			3668.748,
			3670.422,
			3672.866,
			3674.455,
			3676.773,
			3677.528,
			3678.276,
			3680.502,
			3681.952,
			3684.107,
			3685.539,
			3687.674,
			3689.093,
			3689.799,
			3691.207,
			3692.608,
			3693.307,
			3694.68,
			3696.042,
			3698.078,
			3698.753,
			3699.422,
			3700.756,
			3702.084,
			3702.746,
			3704.067,
			3704.727,
			3706.041,
			3707.349,
			3709.319,
			3710.65,
			3712.638,
			3713.299,
			3713.969,
			3716.013,
			3717.372,
			3719.422,
			3720.8,
			3722.176,
			3722.864,
			3724.245,
			3724.937,
			3726.319,
			3727.701,
			3728.392,
			3729.785,
			3731.182,
			3733.278,
			3734.69,
			3736.831,
			3737.543,
			3738.256,
			3740.418,
			3741.867,
			3744.037,
			3745.509,
			3746.985,
			3749.195,
			3749.938,
			3750.688,
			3752.927,
			3753.672,
			3754.417,
			3756.664,
			3758.157,
			3760.393,
			3761.876,
			3763.356,
			3764.095,
			3765.58,
			3766.323,
			3767.806,
			3769.285,
			3771.521,
			3773.01,
			3775.233,
			3775.983,
			3776.731,
			3778.967,
			3780.454,
			3782.673,
			3784.147,
			3786.351,
			3787.811,
			3788.541,
			3789.271,
			3791.448,
			3792.165,
			3792.881,
			3795.033,
			3796.462,
			3797.882,
			3798.593,
			3800.018,
			3801.443,
			3802.153,
			3803.576,
			3804.289,
			3805.72,
			3807.145,
			3809.291,
			3810.732,
			3812.879,
			3813.595,
			3814.312,
			3816.474,
			3817.892,
			3820.03,
			3821.468,
			3823.626,
			3825.076,
			3825.804,
			3827.284,
			3828.805,
			3829.57,
			3831.108,
			3832.683,
			3835.108,
			3835.923,
			3836.742,
			3838.392,
			3840.056,
			3840.893,
			3843.421,
			3845.114,
			3846.805,
			3849.355,
			3851.056,
			3853.649,
			3854.519,
			3855.407,
			3858.043,
			3859.813,
			3862.49,
			3864.263,
			3866.062,
			3866.966,
			3868.778,
			3869.687,
			3871.513,
			3873.349,
			3874.269,
			3876.117,
			3877.978,
			3880.792,
			3881.736,
			3882.683,
			3885.554,
			3886.517,
			3887.484,
			3890.405,
			3892.367,
			3894.339,
			3897.317,
			3899.316,
			3902.333,
			3903.345,
			3904.359,
			3907.416,
			3908.438,
			3909.462,
			3912.533,
			3914.565,
			3917.567,
			3919.529,
			3921.455,
			3922.404,
			3924.273,
			3925.195,
			3927.013,
			3928.792,
			3931.401,
			3933.106,
			3935.639,
			3936.472,
			3937.302,
			3939.78,
			3941.418,
			3943.859,
			3945.477,
			3947.888,
			3949.485,
			3950.281,
			3951.868,
			3953.449,
			3954.237,
			3955.023,
			3957.375,
			3958.936,
			3960.494,
			3961.271,
			3962.821,
			3964.368,
			3965.141,
			3967.487,
			3969.049,
			3970.614,
			3973,
			3974.591,
			3976.995,
			3977.8,
			3978.605,
			3981.024,
			3982.647,
			3985.081,
			3986.708,
			3989.149,
			3990.778,
			3991.598,
			3993.244,
			3994.889,
			3995.713,
			3997.366,
			3999.021,
			4000.678,
			4002.33,
			4003.155,
			4004.805,
			4006.459,
			4007.286,
			4008.943,
			4009.772,
			4011.434,
			4013.113,
			4015.639,
			4017.334,
			4019.891,
			4020.744,
			4021.598,
			4024.19,
			4025.918,
			4028.523,
			4030.26,
			4032.872,
			4034.619,
			4035.491,
			4037.232,
			4038.961,
			4039.823,
			4041.544,
			4043.264,
			4045.835,
			4047.546,
			4050.123,
			4050.98,
			4051.837,
			4054.413,
			4056.128,
			4058.715,
			4060.456,
			4062.199,
			4064.843,
			4065.729,
			4066.615,
			4069.282,
			4070.175,
			4071.068,
			4073.749,
			4075.543,
			4078.232,
			4080.013,
			4081.792,
			4082.681,
			4084.467,
			4085.364,
			4087.158,
			4088.953,
			4091.634,
			4093.425,
			4096.093,
			4096.981,
			4097.871,
			4100.541,
			4102.322,
			4105.007,
			4106.809,
			4108.616,
			4111.346,
			4112.259,
			4114.092,
			4115.934,
			4116.857,
			4117.781,
			4120.563,
			4122.413,
			4124.269,
			4125.199,
			4127.036,
			4128.873,
			4129.793,
			4131.619,
			4132.521,
			4134.332,
			4136.144,
			4138.841,
			4140.649,
			4143.395,
			4144.315,
			4145.239,
			4148.036,
			4149.919,
			4152.766,
			4154.678,
			4157.576,
			4159.532,
			4160.515,
			4162.497,
			4164.501,
			4165.507,
			4167.532,
			4169.575,
			4172.643,
			4173.676,
			4174.711,
			4176.786,
			4178.855,
			4179.89,
			4181.976,
			4183.006,
			4185.062,
			4187.108,
			4190.162,
			4192.227,
			4195.313,
			4196.347,
			4197.381,
			4200.48,
			4202.57,
			4205.705,
			4207.794,
			4210.944,
			4213.057,
			4214.117,
			4216.248,
			4219.461,
			4221.616,
			4223.791,
			4227.028,
			4228.119,
			4229.23,
			4232.502,
			4233.603,
			4234.705,
			4238.027,
			4240.254,
			4243.613,
			4245.865,
			4248.128,
			4251.54,
			4252.682,
			4253.826,
			4257.275,
			4258.429,
			4259.586,
			4263.072,
			4265.408,
			4268.93,
			4271.29,
			4272.473,
			4274.846,
			4277.229,
			4278.424,
			4280.822,
			4283.229,
			4286.857,
			4289.286,
			4292.946,
			4294.17,
			4295.397,
			4299.089,
			4301.562,
			4305.286,
			4307.78,
			4310.282,
			4311.537,
			4314.052,
			4315.312,
			4316.575,
			4320.375,
			4321.646,
			4322.918,
			4326.75,
			4329.314,
			4333.176,
			4335.762,
			4338.354,
			4339.654,
			4342.26,
			4343.565,
			4346.173,
			4348.785,
			4352.716,
			4355.346,
			4359.004
		],
		[
			0,
			0.025,
			0.086,
			0.103,
			0.149,
			0.212,
			0.272,
			0.334,
			0.399,
			0.428,
			0.46,
			0.521,
			0.585,
			0.646,
			0.708,
			0.77,
			0.831,
			0.863,
			0.895,
			0.954,
			0.977,
			1.017,
			1.08,
			1.143,
			1.187,
			1.203,
			1.265,
			1.295,
			1.329,
			1.388,
			1.404,
			1.451,
			1.512,
			1.577,
			1.621,
			1.701,
			1.732,
			1.762,
			1.822,
			1.885,
			1.949,
			2.01,
			2.056,
			2.073,
			2.135,
			2.165,
			2.195,
			2.259,
			2.271,
			2.32,
			2.38,
			2.443,
			2.491,
			2.504,
			2.569,
			2.599,
			2.629,
			2.706,
			2.753,
			2.817,
			2.877,
			2.925,
			3.001,
			3.036,
			3.064,
			3.126,
			3.143,
			3.189,
			3.249,
			3.311,
			3.358,
			3.375,
			3.436,
			3.468,
			3.499,
			3.575,
			3.622,
			3.683,
			3.745,
			3.808,
			3.871,
			3.902,
			3.933,
			4.011,
			4.054,
			4.118,
			4.18,
			4.227,
			4.242,
			4.303,
			4.336,
			4.367,
			4.428,
			4.444,
			4.489,
			4.553,
			4.613,
			4.662,
			4.676,
			4.739,
			4.77,
			4.801,
			4.862,
			4.878,
			4.925,
			4.986,
			5.048,
			5.098,
			5.173,
			5.204,
			5.236,
			5.295,
			5.359,
			5.421,
			5.481,
			5.53,
			5.543,
			5.606,
			5.637,
			5.67,
			5.732,
			5.745,
			5.793,
			5.855,
			5.916,
			5.964,
			5.978,
			6.039,
			6.07,
			6.103,
			6.181,
			6.227,
			6.288,
			6.349,
			6.397,
			6.473,
			6.504,
			6.537,
			6.598,
			6.66,
			6.722,
			6.785,
			6.847,
			6.909,
			6.941,
			6.971,
			7.034,
			7.048,
			7.094,
			7.163,
			7.217,
			7.281,
			7.343,
			7.375,
			7.404,
			7.467,
			7.528,
			7.59,
			7.653,
			7.698,
			7.777,
			7.806,
			7.837,
			7.901,
			7.964,
			8.026,
			8.086,
			8.133,
			8.148,
			8.21,
			8.242,
			8.271,
			8.336,
			8.351,
			8.395,
			8.46,
			8.522,
			8.569,
			8.644,
			8.676,
			8.708,
			8.769,
			8.831,
			8.893,
			8.954,
			9.002,
			9.017,
			9.079,
			9.11,
			9.142,
			9.203,
			9.225,
			9.266,
			9.326,
			9.39,
			9.436,
			9.452,
			9.512,
			9.545,
			9.575,
			9.636,
			9.653,
			9.699,
			9.761,
			9.822,
			9.869,
			9.947,
			9.977,
			10.009,
			10.086,
			10.133,
			10.195,
			10.256,
			10.303,
			10.319,
			10.381,
			10.413,
			10.444,
			10.504,
			10.52,
			10.567,
			10.628,
			10.692,
			10.738,
			10.752,
			10.816,
			10.845,
			10.877,
			10.937,
			10.954,
			11.002,
			11.064,
			11.126,
			11.172,
			11.251,
			11.285,
			11.31,
			11.39,
			11.434,
			11.496,
			11.559,
			11.606,
			11.622,
			11.684,
			11.714,
			11.744,
			11.809,
			11.821,
			11.871,
			11.931,
			11.992,
			12.039,
			12.054,
			12.117,
			12.148,
			12.179,
			12.24,
			12.258,
			12.304,
			12.366,
			12.427,
			12.491,
			12.55,
			12.581,
			12.614,
			12.676,
			12.692,
			12.738,
			12.8,
			12.86,
			12.923,
			12.986,
			13.016,
			13.047,
			13.124,
			13.171,
			13.234,
			13.295,
			13.357,
			13.421,
			13.451,
			13.482,
			13.543,
			13.605,
			13.671,
			13.73,
			13.79,
			13.854,
			13.884,
			13.916,
			13.979,
			13.992,
			14.039,
			14.102,
			14.165,
			14.212,
			14.226,
			14.289,
			14.32,
			14.351,
			14.413,
			14.429,
			14.472,
			14.537,
			14.597,
			14.66,
			14.723,
			14.753,
			14.783,
			14.845,
			14.908,
			14.969,
			15.032,
			15.078,
			15.094,
			15.155,
			15.188,
			15.219,
			15.28,
			15.296,
			15.343,
			15.404,
			15.465,
			15.511,
			15.527,
			15.591,
			15.62,
			15.651,
			15.713,
			15.729,
			15.776,
			15.838,
			15.901,
			15.946,
			16.025,
			16.054,
			16.085,
			16.149,
			16.209,
			16.272,
			16.336,
			16.395,
			16.459,
			16.488,
			16.519,
			16.582,
			16.598,
			16.644,
			16.705,
			16.769,
			16.815,
			16.829,
			16.892,
			16.921,
			16.954,
			17.033,
			17.077,
			17.141,
			17.204,
			17.25,
			17.327,
			17.357,
			17.389,
			17.451,
			17.47,
			17.513,
			17.576,
			17.636,
			17.698,
			17.76,
			17.793,
			17.821,
			17.901,
			17.948,
			18.009,
			18.071,
			18.132,
			18.195,
			18.225,
			18.255,
			18.318,
			18.379,
			18.442,
			18.505,
			18.551,
			18.627,
			18.661,
			18.689,
			18.754,
			18.769,
			18.813,
			18.876,
			18.939,
			18.984,
			19,
			19.061,
			19.095,
			19.125,
			19.203,
			19.249,
			19.312,
			19.372,
			19.42,
			19.497,
			19.532,
			19.559,
			19.622,
			19.681,
			19.746,
			19.806,
			19.852,
			19.869,
			19.93,
			19.963,
			19.993,
			20.054,
			20.07,
			20.117,
			20.18,
			20.241,
			20.287,
			20.304,
			20.366,
			20.396,
			20.426,
			20.489,
			20.504,
			20.551,
			20.613,
			20.675,
			20.721,
			20.799,
			20.83,
			20.861,
			20.924,
			20.986,
			21.047,
			21.11,
			21.156,
			21.172,
			21.233,
			21.264,
			21.296,
			21.357,
			21.373,
			21.42,
			21.48,
			21.544,
			21.604,
			21.667,
			21.698,
			21.729,
			21.79,
			21.805,
			21.853,
			21.914,
			21.976,
			22.038,
			22.102,
			22.133,
			22.164,
			22.225,
			22.287,
			22.35,
			22.412,
			22.458,
			22.475,
			22.536,
			22.567,
			22.596,
			22.66,
			22.676,
			22.722,
			22.784,
			22.845,
			22.893,
			22.969,
			23.002,
			23.03,
			23.095,
			23.154,
			23.218,
			23.28,
			23.326,
			23.342,
			23.403,
			23.434,
			23.464,
			23.526,
			23.543,
			23.588,
			23.652,
			23.713,
			23.76,
			23.776,
			23.837,
			23.868,
			23.9,
			23.977,
			24.025,
			24.085,
			24.147,
			24.195,
			24.272,
			24.303,
			24.334,
			24.412,
			24.459,
			24.519,
			24.582,
			24.627,
			24.643,
			24.706,
			24.738,
			24.768,
			24.829,
			24.845,
			24.891,
			24.954,
			25.016,
			25.063,
			25.077,
			25.141,
			25.169,
			25.201,
			25.264,
			25.28,
			25.327,
			25.388,
			25.449,
			25.496,
			25.573,
			25.605,
			25.635,
			25.698,
			25.717,
			25.76,
			25.822,
			25.884,
			25.93,
			25.945,
			26.008,
			26.039,
			26.07,
			26.148,
			26.195,
			26.26,
			26.318,
			26.365,
			26.381,
			26.443,
			26.472,
			26.504,
			26.581,
			26.628,
			26.689,
			26.753,
			26.798,
			26.877,
			26.906,
			26.937,
			27,
			27.063,
			27.123,
			27.187,
			27.248,
			27.309,
			27.341,
			27.372,
			27.451,
			27.496,
			27.557,
			27.62,
			27.667,
			27.744,
			27.779,
			27.806,
			27.883,
			27.931,
			27.992,
			28.053,
			28.101,
			28.179,
			28.21,
			28.239,
			28.302,
			28.319,
			28.365,
			28.427,
			28.489,
			28.535,
			28.551,
			28.613,
			28.644,
			28.673,
			28.752,
			28.798,
			28.86,
			28.922,
			28.97,
			29.046,
			29.078,
			29.109,
			29.187,
			29.234,
			29.295,
			29.357,
			29.419,
			29.48,
			29.513,
			29.543,
			29.62,
			29.667,
			29.73,
			29.791,
			29.852,
			29.915,
			29.944,
			29.976,
			30.04,
			30.053,
			30.102,
			30.162,
			30.226,
			30.286,
			30.35,
			30.38,
			30.41,
			30.472,
			30.535,
			30.595,
			30.659,
			30.721,
			30.784,
			30.813,
			30.845,
			30.906,
			30.922,
			30.97,
			31.033,
			31.094,
			31.138,
			31.155,
			31.218,
			31.247,
			31.277,
			31.342,
			31.404,
			31.465,
			31.528,
			31.587,
			31.652,
			31.682,
			31.712,
			31.775,
			31.792,
			31.836,
			31.899,
			31.96,
			32.007,
			32.023,
			32.084,
			32.116,
			32.146,
			32.209,
			32.225,
			32.272,
			32.334,
			32.394,
			32.455,
			32.519,
			32.55,
			32.581,
			32.643,
			32.704,
			32.768,
			32.828,
			32.893,
			32.953,
			32.985,
			33.016,
			33.078,
			33.092,
			33.139,
			33.201,
			33.262,
			33.326,
			33.387,
			33.419,
			33.449,
			33.511,
			33.527,
			33.574,
			33.634,
			33.699,
			33.744,
			33.822,
			33.853,
			33.883,
			33.946,
			34.008,
			34.071,
			34.133,
			34.178,
			34.193,
			34.254,
			34.288,
			34.317,
			34.397,
			34.442,
			34.505,
			34.567,
			34.613,
			34.627,
			34.691,
			34.722,
			34.751,
			34.83,
			34.876,
			34.939,
			35.001,
			35.045,
			35.122,
			35.154,
			35.185,
			35.247,
			35.31,
			35.373,
			35.433,
			35.496,
			35.556,
			35.59,
			35.618,
			35.698,
			35.745,
			35.804,
			35.867,
			35.914,
			35.929,
			35.993,
			36.026,
			36.054,
			36.117,
			36.178,
			36.241,
			36.301,
			36.347,
			36.426,
			36.456,
			36.487,
			36.549,
			36.612,
			36.675,
			36.736,
			36.783,
			36.798,
			36.86,
			36.892,
			36.92,
			36.984,
			36.998,
			37.045,
			37.109,
			37.17,
			37.217,
			37.295,
			37.326,
			37.354,
			37.419,
			37.48,
			37.543,
			37.605,
			37.65,
			37.667,
			37.728,
			37.76,
			37.791,
			37.851,
			37.869,
			37.914,
			37.976,
			38.039,
			38.087,
			38.099,
			38.163,
			38.193,
			38.225,
			38.287,
			38.3,
			38.348,
			38.41,
			38.472,
			38.534,
			38.597,
			38.628,
			38.657,
			38.719,
			38.783,
			38.844,
			38.907,
			38.952,
			39.029,
			39.062,
			39.093,
			39.153,
			39.17,
			39.217,
			39.279,
			39.34,
			39.386,
			39.403,
			39.465,
			39.494,
			39.527,
			39.588,
			39.605,
			39.65,
			39.716,
			39.776,
			39.822,
			39.897,
			39.929,
			39.962,
			40.023,
			40.084,
			40.151,
			40.21,
			40.271,
			40.332,
			40.363,
			40.395,
			40.457,
			40.473,
			40.517,
			40.582,
			40.643,
			40.689,
			40.706,
			40.767,
			40.798,
			40.828,
			40.891,
			40.906,
			40.953,
			41.014,
			41.077,
			41.122,
			41.201,
			41.232,
			41.262,
			41.323,
			41.385,
			41.451,
			41.512,
			41.557,
			41.573,
			41.634,
			41.666,
			41.695,
			41.76,
			41.774,
			41.82,
			41.882,
			41.944,
			41.992,
			42.067,
			42.1,
			42.132,
			42.192,
			42.254,
			42.317,
			42.38,
			42.426,
			42.503,
			42.535,
			42.566,
			42.628,
			42.643,
			42.687,
			42.751,
			42.812,
			42.86,
			42.876,
			42.937,
			42.968,
			42.998,
			43.062,
			43.076,
			43.122,
			43.189,
			43.247,
			43.295,
			43.37,
			43.401,
			43.433,
			43.495,
			43.555,
			43.62,
			43.68,
			43.743,
			43.804,
			43.837,
			43.867,
			43.944,
			43.989,
			44.054,
			44.116,
			44.162,
			44.178,
			44.239,
			44.275,
			44.301,
			44.362,
			44.378,
			44.426,
			44.488,
			44.549,
			44.594,
			44.672,
			44.704,
			44.736,
			44.812,
			44.86,
			44.921,
			44.984,
			45.031,
			45.044,
			45.105,
			45.137,
			45.169,
			45.23,
			45.247,
			45.295,
			45.356,
			45.418,
			45.462,
			45.478,
			45.54,
			45.572,
			45.604,
			45.681,
			45.728,
			45.79,
			45.851,
			45.914,
			45.976,
			46.007,
			46.036,
			46.114,
			46.162,
			46.223,
			46.287,
			46.337,
			46.348,
			46.41,
			46.442,
			46.471,
			46.549,
			46.594,
			46.661,
			46.719,
			46.766,
			46.782,
			46.842,
			46.873,
			46.904,
			46.983,
			47.028,
			47.093,
			47.153,
			47.2,
			47.278,
			47.31,
			47.34,
			47.401,
			47.417,
			47.462,
			47.527,
			47.589,
			47.649,
			47.711,
			47.743,
			47.774,
			47.835,
			47.851,
			47.898,
			47.964,
			48.021,
			48.069,
			48.146,
			48.177,
			48.209,
			48.27,
			48.332,
			48.397,
			48.454,
			48.502,
			48.518,
			48.581,
			48.612,
			48.643,
			48.719,
			48.766,
			48.827,
			48.89,
			48.951,
			49.013,
			49.045,
			49.076,
			49.155,
			49.199,
			49.262,
			49.322,
			49.386,
			49.447,
			49.478,
			49.511,
			49.571,
			49.635,
			49.695,
			49.759,
			49.805,
			49.821,
			49.883,
			49.913,
			49.944,
			50.004,
			50.021,
			50.067,
			50.13,
			50.192,
			50.238,
			50.255,
			50.317,
			50.347,
			50.379,
			50.462,
			50.502,
			50.565,
			50.627,
			50.671,
			50.751,
			50.782,
			50.811,
			50.891,
			50.937,
			50.999,
			51.059,
			51.106,
			51.122,
			51.185,
			51.215,
			51.247,
			51.308,
			51.325,
			51.37,
			51.433,
			51.495,
			51.556,
			51.618,
			51.65,
			51.682,
			51.759,
			51.805,
			51.867,
			51.929,
			51.989,
			52.051,
			52.083,
			52.116,
			52.178,
			52.192,
			52.24,
			52.299,
			52.362,
			52.423,
			52.488,
			52.521,
			52.548,
			52.61,
			52.627,
			52.673,
			52.735,
			52.795,
			52.844,
			52.921,
			52.952,
			52.983,
			53.044,
			53.105,
			53.169,
			53.232,
			53.278,
			53.294,
			53.354,
			53.384,
			53.415,
			53.478,
			53.495,
			53.542,
			53.603,
			53.665,
			53.71,
			53.727,
			53.789,
			53.82,
			53.851,
			53.913,
			53.927,
			53.975,
			54.038,
			54.099,
			54.145,
			54.224,
			54.253,
			54.284,
			54.362,
			54.41,
			54.471,
			54.532,
			54.596,
			54.657,
			54.688,
			54.718,
			54.78,
			54.797,
			54.842,
			54.904,
			54.967,
			55.014,
			55.028,
			55.091,
			55.122,
			55.152,
			55.215,
			55.232,
			55.279,
			55.338,
			55.401,
			55.447,
			55.526,
			55.554,
			55.587,
			55.648,
			55.711,
			55.774,
			55.834,
			55.881,
			55.896,
			55.96,
			55.989,
			56.02,
			56.083,
			56.097,
			56.146,
			56.206,
			56.268,
			56.315,
			56.331,
			56.394,
			56.425,
			56.454,
			56.533,
			56.579,
			56.648,
			56.704,
			56.767,
			56.826,
			56.858,
			56.887,
			56.951,
			57.013,
			57.076,
			57.137,
			57.185,
			57.199,
			57.262,
			57.292,
			57.323,
			57.402,
			57.447,
			57.509,
			57.572,
			57.617,
			57.694,
			57.727,
			57.758,
			57.835,
			57.882,
			57.944,
			58.005,
			58.051,
			58.068,
			58.131,
			58.161,
			58.189,
			58.254,
			58.268,
			58.314,
			58.382,
			58.441,
			58.486,
			58.501,
			58.563,
			58.595,
			58.626,
			58.686,
			58.706,
			58.75,
			58.813,
			58.874,
			58.921,
			58.998,
			59.029,
			59.059,
			59.138,
			59.183,
			59.247,
			59.309,
			59.369,
			59.433,
			59.463,
			59.493,
			59.556,
			59.572,
			59.618,
			59.682,
			59.743,
			59.787,
			59.804,
			59.866,
			59.896,
			59.928,
			59.991,
			60.006,
			60.051,
			60.113,
			60.177,
			60.221,
			60.301,
			60.332,
			60.361,
			60.44,
			60.486,
			60.548,
			60.609,
			60.671,
			60.734,
			60.77,
			60.797,
			60.872,
			60.92,
			60.983,
			61.044,
			61.091,
			61.106,
			61.167,
			61.2,
			61.231,
			61.307,
			61.355,
			61.416,
			61.477,
			61.526,
			61.601,
			61.633,
			61.664,
			61.726,
			61.743,
			61.787,
			61.85,
			61.912,
			61.959,
			61.976,
			62.036,
			62.068,
			62.099,
			62.16,
			62.176,
			62.221,
			62.286,
			62.346,
			62.409,
			62.469,
			62.5,
			62.531,
			62.595,
			62.656,
			62.72,
			62.78,
			62.842,
			62.903,
			62.934,
			62.967,
			63.028,
			63.045,
			63.089,
			63.152,
			63.215,
			63.262,
			63.278,
			63.338,
			63.369,
			63.4,
			63.478,
			63.523,
			63.586,
			63.65,
			63.694,
			63.773,
			63.804,
			63.834,
			63.911,
			63.959,
			64.023,
			64.083,
			64.13,
			64.144,
			64.206,
			64.236,
			64.268,
			64.331,
			64.347,
			64.392,
			64.456,
			64.516,
			64.562,
			64.579,
			64.639,
			64.671,
			64.703,
			64.764,
			64.781,
			64.826,
			64.894,
			64.95,
			65.014,
			65.075,
			65.106,
			65.136,
			65.215,
			65.261,
			65.322,
			65.385,
			65.43,
			65.447,
			65.509,
			65.539,
			65.57,
			65.634,
			65.648,
			65.694,
			65.757,
			65.818,
			65.866,
			65.882,
			65.943,
			65.973,
			66.004,
			66.067,
			66.084,
			66.13,
			66.19,
			66.254,
			66.301,
			66.378,
			66.409,
			66.438,
			66.5,
			66.517,
			66.562,
			66.623,
			66.687,
			66.735,
			66.748,
			66.811,
			66.843,
			66.872,
			66.936,
			66.954,
			66.996,
			67.06,
			67.121,
			67.169,
			67.244,
			67.276,
			67.308,
			67.37,
			67.43,
			67.492,
			67.555,
			67.617,
			67.68,
			67.711,
			67.739,
			67.803,
			67.82,
			67.865,
			67.927,
			67.988,
			68.036,
			68.051,
			68.112,
			68.146,
			68.176,
			68.252,
			68.301,
			68.361,
			68.423,
			68.487,
			68.546,
			68.578,
			68.609,
			68.671,
			68.733,
			68.795,
			68.858,
			68.905,
			68.919,
			68.981,
			69.018,
			69.044,
			69.104,
			69.121,
			69.168,
			69.23,
			69.291,
			69.354,
			69.416,
			69.448,
			69.478,
			69.539,
			69.554,
			69.602,
			69.664,
			69.725,
			69.772,
			69.851,
			69.88,
			69.911,
			69.971,
			70.037,
			70.099,
			70.159,
			70.222,
			70.285,
			70.316,
			70.344,
			70.406,
			70.422,
			70.471,
			70.533,
			70.594,
			70.642,
			70.655,
			70.718,
			70.747,
			70.78,
			70.843,
			70.904,
			70.967,
			71.028,
			71.088,
			71.152,
			71.197,
			71.277,
			71.306,
			71.339,
			71.417,
			71.462,
			71.525,
			71.586,
			71.633,
			71.647,
			71.71,
			71.74,
			71.772,
			71.85,
			71.895,
			71.958,
			72.02,
			72.067,
			72.143,
			72.176,
			72.207,
			72.267,
			72.283,
			72.331,
			72.393,
			72.455,
			72.5,
			72.579,
			72.61,
			72.639,
			72.703,
			72.765,
			72.827,
			72.889,
			72.936,
			72.951,
			73.012,
			73.044,
			73.073,
			73.137,
			73.157,
			73.199,
			73.259,
			73.323,
			73.37,
			73.447,
			73.477,
			73.506,
			73.57,
			73.633,
			73.693,
			73.756,
			73.803,
			73.819,
			73.88,
			73.913,
			73.943,
			74.004,
			74.02,
			74.068,
			74.128,
			74.189,
			74.238,
			74.252,
			74.316,
			74.346,
			74.378,
			74.454,
			74.5,
			74.563,
			74.623,
			74.672,
			74.749,
			74.78,
			74.81,
			74.873,
			74.934,
			74.996,
			75.059,
			75.106,
			75.12,
			75.183,
			75.218,
			75.244,
			75.308,
			75.323,
			75.368,
			75.431,
			75.493,
			75.54,
			75.554,
			75.617,
			75.648,
			75.679,
			75.757,
			75.803,
			75.865,
			75.928,
			75.973,
			76.05,
			76.082,
			76.111,
			76.188,
			76.238,
			76.298,
			76.36,
			76.408,
			76.421,
			76.485,
			76.515,
			76.548,
			76.609,
			76.622,
			76.671,
			76.735,
			76.795,
			76.843,
			76.919,
			76.95,
			76.98,
			77.043,
			77.104,
			77.167,
			77.229,
			77.28,
			77.352,
			77.384,
			77.416,
			77.492,
			77.54,
			77.601,
			77.662,
			77.71,
			77.725,
			77.788,
			77.817,
			77.85,
			77.912,
			77.928,
			77.971,
			78.035,
			78.097,
			78.143,
			78.222,
			78.252,
			78.284,
			78.345,
			78.405,
			78.469,
			78.53,
			78.594,
			78.655,
			78.685,
			78.717,
			78.794,
			78.841,
			78.904,
			78.966,
			79.013,
			79.028,
			79.087,
			79.121,
			79.151,
			79.214,
			79.227,
			79.276,
			79.343,
			79.398,
			79.445,
			79.522,
			79.553,
			79.586,
			79.647,
			79.71,
			79.772,
			79.835,
			79.881,
			79.895,
			79.957,
			79.989,
			80.02,
			80.083,
			80.097,
			80.143,
			80.207,
			80.268,
			80.313,
			80.33,
			80.391,
			80.421,
			80.454,
			80.514,
			80.531,
			80.578,
			80.639,
			80.701,
			80.748,
			80.826,
			80.858,
			80.886,
			80.949,
			81.011,
			81.072,
			81.135,
			81.182,
			81.197,
			81.259,
			81.291,
			81.322,
			81.384,
			81.401,
			81.445,
			81.506,
			81.57,
			81.617,
			81.694,
			81.726,
			81.754,
			81.819,
			81.88,
			81.942,
			82.004,
			82.051,
			82.128,
			82.16,
			82.19,
			82.253,
			82.267,
			82.314,
			82.377,
			82.439,
			82.485,
			82.5,
			82.562,
			82.593,
			82.622,
			82.687,
			82.701,
			82.747,
			82.81,
			82.872,
			82.934,
			82.997,
			83.026,
			83.058,
			83.119,
			83.181,
			83.245,
			83.304,
			83.354,
			83.368,
			83.43,
			83.466,
			83.493,
			83.554,
			83.569,
			83.617,
			83.677,
			83.739,
			83.786,
			83.803,
			83.863,
			83.894,
			83.926,
			83.987,
			84.004,
			84.05,
			84.112,
			84.174,
			84.22,
			84.297,
			84.328,
			84.361,
			84.422,
			84.485,
			84.546,
			84.609,
			84.669,
			84.733,
			84.763,
			84.793,
			84.872,
			84.919,
			84.981,
			85.043,
			85.088,
			85.106,
			85.167,
			85.197,
			85.227,
			85.289,
			85.305,
			85.351,
			85.416,
			85.477,
			85.539,
			85.6,
			85.63,
			85.663,
			85.725,
			85.786,
			85.85,
			85.91,
			85.958,
			85.971,
			86.034,
			86.065,
			86.097,
			86.159,
			86.173,
			86.22,
			86.284,
			86.345,
			86.389,
			86.407,
			86.469,
			86.498,
			86.531,
			86.593,
			86.654,
			86.715,
			86.779,
			86.825,
			86.902,
			86.935,
			86.966,
			87.026,
			87.042,
			87.087,
			87.151,
			87.212,
			87.259,
			87.274,
			87.338,
			87.367,
			87.399,
			87.459,
			87.475,
			87.524,
			87.588,
			87.646,
			87.693,
			87.769,
			87.801,
			87.832,
			87.894,
			87.958,
			88.019,
			88.079,
			88.126,
			88.142,
			88.206,
			88.237,
			88.267,
			88.33,
			88.343,
			88.39,
			88.454,
			88.515,
			88.563,
			88.577,
			88.639,
			88.67,
			88.701,
			88.764,
			88.778,
			88.825,
			88.887,
			88.95,
			88.996,
			89.072,
			89.105,
			89.135,
			89.213,
			89.259,
			89.323,
			89.383,
			89.446,
			89.508,
			89.539,
			89.569,
			89.632,
			89.654,
			89.694,
			89.754,
			89.817,
			89.864,
			89.878,
			89.939,
			89.971,
			90.004,
			90.065,
			90.081,
			90.128,
			90.188,
			90.25,
			90.298,
			90.376,
			90.406,
			90.436,
			90.513,
			90.56,
			90.622,
			90.684,
			90.731,
			90.747,
			90.81,
			90.841,
			90.871,
			90.935,
			90.95,
			90.996,
			91.059,
			91.12,
			91.167,
			91.243,
			91.275,
			91.306,
			91.384,
			91.428,
			91.493,
			91.552,
			91.616,
			91.677,
			91.714,
			91.738,
			91.801,
			91.818,
			91.863,
			91.927,
			91.988,
			92.05,
			92.111,
			92.143,
			92.173,
			92.235,
			92.251,
			92.297,
			92.36,
			92.421,
			92.483,
			92.545,
			92.576,
			92.606,
			92.669,
			92.73,
			92.793,
			92.854,
			92.918,
			92.979,
			93.01,
			93.041,
			93.103,
			93.12,
			93.165,
			93.228,
			93.288,
			93.336,
			93.353,
			93.414,
			93.444,
			93.476,
			93.537,
			93.553,
			93.601,
			93.663,
			93.725,
			93.776,
			93.846,
			93.88,
			93.91,
			93.973,
			94.034,
			94.097,
			94.158,
			94.22,
			94.282,
			94.314,
			94.344,
			94.407,
			94.423,
			94.469,
			94.531,
			94.593,
			94.637,
			94.655,
			94.715,
			94.748,
			94.777,
			94.855,
			94.903,
			94.964,
			95.027,
			95.089,
			95.151,
			95.181,
			95.213,
			95.29,
			95.337,
			95.397,
			95.459,
			95.507,
			95.523,
			95.583,
			95.616,
			95.647,
			95.709,
			95.722,
			95.77,
			95.836,
			95.893,
			95.94,
			95.957,
			96.019,
			96.05,
			96.08,
			96.156,
			96.203,
			96.266,
			96.328,
			96.376,
			96.451,
			96.482,
			96.514,
			96.577,
			96.593,
			96.639,
			96.7,
			96.761,
			96.822,
			96.886,
			96.918,
			96.947,
			97.012,
			97.026,
			97.073,
			97.136,
			97.197,
			97.243,
			97.321,
			97.352,
			97.383,
			97.445,
			97.508,
			97.569,
			97.631,
			97.693,
			97.753,
			97.786,
			97.818,
			97.878,
			97.899,
			97.939,
			98.002,
			98.065,
			98.11,
			98.126,
			98.189,
			98.22,
			98.25,
			98.328,
			98.375,
			98.437,
			98.499,
			98.545,
			98.623,
			98.654,
			98.684,
			98.747,
			98.81,
			98.871,
			98.932,
			98.995,
			99.058,
			99.087,
			99.117,
			99.181,
			99.196,
			99.244,
			99.304,
			99.366,
			99.415,
			99.427,
			99.491,
			99.523,
			99.553,
			99.615,
			99.631,
			99.677,
			99.739,
			99.801,
			99.848,
			99.926,
			99.96,
			99.986,
			100.05,
			100.111,
			100.172,
			100.236,
			100.298,
			100.36,
			100.39,
			100.422,
			100.483,
			100.499,
			100.545,
			100.609,
			100.668,
			100.731,
			100.793,
			100.823,
			100.856,
			100.932,
			100.978,
			101.045,
			101.104,
			101.148,
			101.228,
			101.259,
			101.287,
			101.351,
			101.367,
			101.414,
			101.475,
			101.536,
			101.6,
			101.66,
			101.693,
			101.722,
			101.784,
			101.8,
			101.848,
			101.909,
			101.971,
			102.02,
			102.094,
			102.127,
			102.158,
			102.236,
			102.282,
			102.344,
			102.406,
			102.453,
			102.53,
			102.562,
			102.592,
			102.654,
			102.669,
			102.716,
			102.779,
			102.838,
			102.885,
			102.901,
			102.963,
			102.995,
			103.026,
			103.087,
			103.104,
			103.15,
			103.213,
			103.275,
			103.334,
			103.397,
			103.43,
			103.46,
			103.538,
			103.584,
			103.644,
			103.708,
			103.768,
			103.831,
			103.861,
			103.894,
			103.956,
			103.97,
			104.018,
			104.083,
			104.143,
			104.187,
			104.202,
			104.265,
			104.295,
			104.329,
			104.389,
			104.406,
			104.453,
			104.515,
			104.577,
			104.62,
			104.7,
			104.731,
			104.762,
			104.825,
			104.885,
			104.948,
			105.01,
			105.071,
			105.133,
			105.163,
			105.196,
			105.272,
			105.319,
			105.381,
			105.443,
			105.492,
			105.506,
			105.569,
			105.599,
			105.629,
			105.693,
			105.754,
			105.817,
			105.878,
			105.94,
			106.002,
			106.034,
			106.062,
			106.127,
			106.146,
			106.189,
			106.255,
			106.313,
			106.373,
			106.436,
			106.467,
			106.499,
			106.56,
			106.576,
			106.622,
			106.684,
			106.745,
			106.794,
			106.871,
			106.901,
			106.932,
			106.993,
			107.056,
			107.118,
			107.179,
			107.227,
			107.243,
			107.304,
			107.334,
			107.366,
			107.429,
			107.443,
			107.49,
			107.554,
			107.615,
			107.676,
			107.739,
			107.77,
			107.799,
			107.861,
			107.877,
			107.923,
			107.985,
			108.048,
			108.094,
			108.176
		]
	],
	'1849_1850_7982': [
		[
			106.086
		],
		[
			0
		],
		[
			0,
			3.234,
			6.566,
			11.579,
			14.937,
			18.309,
			23.378,
			25.069,
			28.451,
			31.837,
			33.54,
			35.243,
			40.372,
			43.798,
			47.226,
			48.934,
			52.353,
			55.756,
			57.458,
			62.555,
			65.956,
			69.361,
			74.481,
			77.899,
			83.025,
			84.734,
			86.443,
			91.575,
			95,
			100.144,
			103.579,
			108.738,
			112.181,
			113.905,
			115.629,
			122.535,
			125.994,
			129.456,
			134.657,
			136.392,
			138.129,
			141.604,
			145.084,
			146.825,
			152.054,
			155.543,
			159.036,
			164.281,
			167.782,
			173.04,
			174.794,
			176.549,
			181.819,
			185.336,
			190.618,
			194.143,
			197.672,
			199.438,
			202.971,
			204.74,
			208.279,
			211.822,
			213.595,
			217.142,
			220.693,
			226.024,
			227.804,
			229.585,
			234.943,
			236.733,
			238.526,
			243.91,
			247.507,
			251.112,
			256.526,
			260.129,
			265.526,
			267.324,
			269.127,
			274.545,
			278.17,
			283.627,
			287.273,
			290.913,
			292.731,
			296.358,
			299.982,
			301.792,
			305.418,
			307.233,
			310.869,
			314.514,
			320.024,
			323.654,
			329.148,
			330.981,
			332.817,
			338.329,
			342.034,
			347.5,
			351.161,
			354.824,
			360.337,
			362.177,
			364.05,
			369.574,
			371.384,
			375.064,
			378.743,
			382.423,
			386.105,
			387.947,
			391.63,
			395.314,
			397.157,
			402.688,
			406.378,
			410.072,
			415.616,
			419.315,
			424.87,
			426.724,
			428.579,
			434.148,
			437.864,
			443.441,
			447.161,
			450.884,
			452.747,
			456.473,
			458.337,
			462.067,
			465.8,
			467.668,
			471.404,
			475.143,
			478.885,
			482.629,
			484.502,
			488.249,
			491.999,
			493.874,
			497.627,
			499.503,
			503.254,
			506.995,
			512.571,
			516.253,
			521.705,
			523.502,
			525.29,
			530.594,
			534.08,
			539.235,
			542.622,
			545.969,
			547.628,
			550.917,
			552.548,
			555.779,
			558.972,
			560.554,
			563.687,
			566.781,
			571.35,
			572.856,
			574.352,
			578.786,
			580.245,
			581.693,
			585.981,
			588.792,
			591.565,
			595.656,
			598.334,
			602.281,
			603.578,
			604.866,
			608.674,
			609.924,
			611.165,
			614.832,
			617.232,
			619.598,
			620.768,
			623.078,
			625.348,
			626.468,
			628.68,
			629.772,
			631.93,
			634.053,
			637.17,
			639.203,
			642.184,
			643.16,
			644.126,
			646.97,
			648.823,
			651.537,
			653.322,
			655.057,
			657.596,
			658.424,
			659.243,
			661.652,
			662.437,
			663.214,
			665.505,
			667.006,
			668.483,
			669.216,
			670.673,
			672.125,
			672.851,
			674.301,
			675.024,
			676.472,
			677.939,
			680.158,
			681.641,
			683.916,
			684.681,
			685.448,
			687.776,
			689.466,
			692.003,
			693.756,
			695.543,
			696.436,
			698.216,
			699.091,
			700.841,
			703.457,
			704.316,
			706.895,
			708.591,
			710.242,
			711.068,
			712.72,
			714.29,
			715.053,
			716.607,
			717.388,
			718.943,
			720.488,
			722.831,
			724.411,
			726.807,
			727.612,
			728.42,
			730.889,
			732.552,
			735.12,
			736.854,
			738.602,
			739.51,
			741.345,
			742.268,
			744.126,
			746.022,
			746.976,
			748.892,
			750.821,
			753.742,
			754.722,
			755.739,
			758.803,
			759.834,
			760.898,
			763.03,
			764.1,
			766.21,
			768.309,
			771.366,
			773.336,
			776.262,
			777.216,
			778.172,
			781.058,
			782.997,
			785.929,
			787.914,
			789.91,
			790.913,
			792.932,
			794.962,
			795.981,
			798.038,
			799.072,
			801.146,
			803.24,
			806.397,
			808.501,
			811.666,
			812.725,
			813.768,
			816.962,
			819.096,
			822.314,
			824.464,
			826.62,
			827.698,
			829.856,
			830.933,
			832.009,
			835.231,
			836.303,
			837.375,
			840.623,
			842.774,
			845.999,
			848.138,
			850.266,
			851.327,
			853.45,
			854.511,
			856.63,
			858.748,
			861.921,
			864.028,
			867.177,
			868.228,
			869.278,
			872.419,
			874.548,
			877.74,
			879.922,
			883.197,
			885.402,
			886.502,
			888.718,
			892.053,
			894.3,
			896.533,
			898.789,
			901.071,
			902.206,
			904.457,
			906.711,
			907.827,
			910.001,
			911.092,
			913.23,
			915.328,
			918.476,
			920.559,
			923.724,
			924.781,
			925.84,
			929.043,
			931.182,
			934.443,
			936.632,
			938.833,
			939.939,
			942.157,
			943.271,
			945.506,
			947.771,
			948.899,
			951.162,
			953.437,
			956.854,
			958.005,
			959.159,
			962.638,
			963.802,
			964.969,
			968.483,
			970.857,
			974.407,
			976.785,
			979.154,
			982.754,
			983.959,
			985.167,
			988.803,
			991.236,
			994.898,
			997.349,
			999.805,
			1001.056,
			1003.502,
			1004.739,
			1007.218,
			1010.954,
			1013.454,
			1015.963,
			1019.742,
			1022.272,
			1026.083,
			1027.357,
			1028.634,
			1032.475,
			1035.047,
			1038.921,
			1041.513,
			1044.113,
			1048.02,
			1049.323,
			1050.628,
			1055.866,
			1057.18,
			1061.133,
			1063.776,
			1066.427,
			1067.755,
			1070.416,
			1073.084,
			1074.421,
			1077.1,
			1078.442,
			1081.132,
			1083.828,
			1087.886,
			1090.599,
			1094.682,
			1096.046,
			1097.412,
			1101.52,
			1104.268,
			1108.401,
			1111.166,
			1113.937,
			1115.326,
			1118.108,
			1119.502,
			1122.295,
			1125.095,
			1126.497,
			1129.307,
			1132.123,
			1134.946,
			1137.776,
			1139.193,
			1142.033,
			1144.88,
			1146.305,
			1149.161,
			1150.592,
			1153.458,
			1156.331,
			1160.651,
			1163.539,
			1167.884,
			1169.336,
			1170.789,
			1175.157,
			1178.078,
			1182.47,
			1185.407,
			1188.354,
			1189.831,
			1192.796,
			1195.765,
			1197.251,
			1200.224,
			1201.708,
			1204.679,
			1207.651,
			1212.124,
			1213.619,
			1215.117,
			1219.621,
			1221.125,
			1222.63,
			1227.147,
			1230.151,
			1233.135,
			1237.563,
			1240.474,
			1244.767,
			1246.18,
			1247.582,
			1251.731,
			1254.449,
			1258.455,
			1261.079,
			1263.666,
			1264.946,
			1267.476,
			1269.968,
			1271.201,
			1274.842,
			1277.226,
			1279.576,
			1283.059,
			1285.323,
			1288.652,
			1289.746,
			1290.83,
			1294.039,
			1296.145,
			1299.268,
			1301.331,
			1303.387,
			1306.469,
			1307.496,
			1308.522,
			1311.622,
			1312.664,
			1313.706,
			1316.887,
			1319.046,
			1321.245,
			1322.376,
			1324.64,
			1326.94,
			1328.1,
			1330.429,
			1331.574,
			1333.87,
			1336.154,
			1339.528,
			1341.766,
			1345.113,
			1346.226,
			1347.338,
			1350.679,
			1352.882,
			1356.16,
			1358.334,
			1360.496,
			1361.571,
			1363.706,
			1364.762,
			1366.849,
			1369.908,
			1371.892,
			1373.833,
			1376.676,
			1377.606,
			1378.529,
			1380.361,
			1382.193,
			1383.098,
			1384.906,
			1385.809,
			1387.608,
			1390.295,
			1392.081,
			1393.864,
			1396.552,
			1397.468,
			1398.382,
			1401.138,
			1403.089,
			1406.013,
			1408.094,
			1410.172,
			1411.214,
			1413.326,
			1414.382,
			1416.469,
			1418.465,
			1419.463,
			1421.437,
			1423.319,
			1426.139,
			1427.062,
			1427.985,
			1430.756,
			1431.68,
			1432.603,
			1435.375,
			1437.208,
			1439.031,
			1441.759,
			1443.558,
			1446.268,
			1447.169,
			1448.073,
			1450.801,
			1451.719,
			1452.642,
			1455.426,
			1457.306,
			1459.205,
			1460.159,
			1462.079,
			1463.045,
			1464.98,
			1466.926,
			1467.905,
			1469.868,
			1471.839,
			1474.814,
			1476.8,
			1479.794,
			1480.798,
			1481.786,
			1484.822,
			1486.845,
			1489.894,
			1491.914,
			1493.969,
			1497.034,
			1498.057,
			1500.108,
			1502.165,
			1503.179,
			1504.227,
			1507.325,
			1509.396,
			1511.469,
			1512.508,
			1514.571,
			1516.665,
			1517.706,
			1519.776,
			1520.824,
			1522.943,
			1525.05,
			1528.202,
			1530.324,
			1533.522,
			1534.592,
			1535.664,
			1538.893,
			1541.056,
			1544.317,
			1546.503,
			1549.796,
			1552.004,
			1553.11,
			1554.22,
			1558.68,
			1559.8,
			1563.177,
			1566.574,
			1567.711,
			1568.851,
			1571.137,
			1573.433,
			1574.584,
			1576.894,
			1578.052,
			1580.375,
			1582.708,
			1586.225,
			1588.581,
			1592.133,
			1593.322,
			1594.512,
			1598.084,
			1600.455,
			1603.966,
			1606.265,
			1608.525,
			1609.641,
			1611.844,
			1612.931,
			1615.078,
			1618.229,
			1620.283,
			1622.299,
			1625.254,
			1627.176,
			1629.989,
			1630.908,
			1631.818,
			1633.612,
			1634.496,
			1636.238,
			1637.943,
			1640.428,
			1642.04,
			1644.399,
			1645.15,
			1645.904,
			1648.127,
			1649.555,
			1651.635,
			1652.989,
			1654.312,
			1654.965,
			1656.264,
			1657.559,
			1658.206,
			1659.5,
			1660.149,
			1661.452,
			1662.765,
			1664.769,
			1666.119,
			1668.164,
			1668.859,
			1669.555,
			1671.654,
			1673.062,
			1675.183,
			1676.596,
			1678.007,
			1680.153,
			1680.865,
			1681.574,
			1683.712,
			1684.453,
			1685.189,
			1687.378,
			1688.873,
			1690.437,
			1691.215,
			1692.767,
			1694.401,
			1695.238,
			1696.907,
			1697.739,
			1699.463,
			1701.213,
			1703.819,
			1705.535,
			1708.083,
			1708.91,
			1709.722,
			1712.142,
			1713.744,
			1716.096,
			1717.655,
			1719.193,
			1719.945,
			1721.445,
			1722.192,
			1723.68,
			1725.098,
			1725.805,
			1727.216,
			1728.625,
			1729.982,
			1732.015,
			1733.375,
			1734.731,
			1735.409,
			1736.767,
			1737.448,
			1738.818,
			1740.219,
			1742.33,
			1743.749,
			1745.908,
			1746.632,
			1747.358,
			1749.514,
			1750.925,
			1753.061,
			1754.464,
			1755.848,
			1756.544,
			1757.946,
			1758.652,
			1760.064,
			1761.487,
			1762.204,
			1763.65,
			1765.11,
			1767.325,
			1768.07,
			1768.819,
			1770.341,
			1771.875,
			1772.647,
			1775.012,
			1776.623,
			1778.248,
			1780.735,
			1782.412,
			1784.94,
			1785.785,
			1786.63,
			1789.171,
			1790.87,
			1793.427,
			1795.152,
			1797.772,
			1799.523,
			1801.282,
			1802.166,
			1803.924,
			1804.805,
			1806.586,
			1808.349,
			1811.033,
			1812.834,
			1815.553,
			1816.464,
			1817.378,
			1820.138,
			1821.994,
			1824.799,
			1826.686,
			1828.584,
			1831.454,
			1832.417,
			1833.383,
			1836.297,
			1837.274,
			1838.254,
			1841.208,
			1843.192,
			1845.185,
			1846.184,
			1848.192,
			1849.199,
			1851.223,
			1853.258,
			1854.279,
			1856.332,
			1858.395,
			1861.515,
			1863.608,
			1866.758,
			1867.807,
			1868.858,
			1872.019,
			1874.138,
			1877.328,
			1879.466,
			1881.612,
			1882.688,
			1884.847,
			1885.93,
			1888.102,
			1891.378,
			1893.572,
			1895.777,
			1897.99,
			1900.214,
			1901.329,
			1903.567,
			1905.814,
			1906.941,
			1909.202,
			1910.335,
			1912.609,
			1914.893,
			1918.336,
			1920.642,
			1924.12,
			1925.283,
			1926.45,
			1929.961,
			1932.315,
			1935.861,
			1938.236,
			1940.62,
			1941.816,
			1944.214,
			1945.416,
			1947.825,
			1950.244,
			1951.457,
			1953.889,
			1956.33,
			1960.005,
			1961.235,
			1962.467,
			1966.175,
			1967.415,
			1968.657,
			1971.147,
			1972.395,
			1974.897,
			1977.404,
			1981.173,
			1983.69,
			1987.479,
			1988.745,
			1990.013,
			1993.835,
			1996.394,
			2000.25,
			2002.829,
			2005.416,
			2006.711,
			2009.306,
			2010.606,
			2013.211,
			2015.822,
			2017.125,
			2019.731,
			2022.341,
			2026.266,
			2027.578,
			2028.891,
			2032.841,
			2034.161,
			2035.483,
			2039.458,
			2042.113,
			2044.773,
			2048.768,
			2051.438,
			2055.453,
			2056.795,
			2058.138,
			2062.175,
			2063.523,
			2064.873,
			2068.948,
			2071.634,
			2074.346,
			2075.705,
			2078.428,
			2081.158,
			2082.526,
			2086.642,
			2089.391,
			2092.146,
			2096.285,
			2099.053,
			2103.222,
			2104.617,
			2106.014,
			2110.218,
			2113.03,
			2117.266,
			2120.107,
			2124.382,
			2127.242,
			2128.672,
			2131.537,
			2134.4,
			2135.833,
			2137.267,
			2141.566,
			2144.435,
			2147.308,
			2148.745,
			2151.624,
			2154.509,
			2155.953,
			2158.846,
			2160.295,
			2163.198,
			2166.108,
			2170.487,
			2173.416,
			2177.827,
			2179.302,
			2180.779,
			2185.215,
			2188.178,
			2192.635,
			2195.615,
			2200.095,
			2203.087,
			2204.581,
			2207.563,
			2210.529,
			2212.003,
			2214.93,
			2217.825,
			2222.095,
			2223.499,
			2224.893,
			2229.016,
			2230.372,
			2231.717,
			2235.699,
			2238.307,
			2240.877,
			2244.662,
			2247.136,
			2250.779,
			2251.976,
			2253.163,
			2256.676,
			2258.975,
			2262.349,
			2264.552,
			2266.717,
			2267.786,
			2269.894,
			2271.981,
			2273.003,
			2276.011,
			2277.978,
			2279.92,
			2282.799,
			2284.705,
			2287.547,
			2288.491,
			2289.435,
			2292.26,
			2294.143,
			2296.968,
			2298.853,
			2301.711,
			2303.615,
			2304.583,
			2305.553,
			2308.462,
			2309.445,
			2310.435,
			2313.401,
			2315.398,
			2318.399,
			2320.393,
			2322.384,
			2323.379,
			2325.363,
			2326.353,
			2328.332,
			2330.309,
			2333.269,
			2335.241,
			2338.196,
			2339.183,
			2340.176,
			2343.154,
			2345.136,
			2348.106,
			2350.063,
			2351.998,
			2352.966,
			2354.883,
			2355.825,
			2356.768,
			2359.598,
			2360.535,
			2361.473,
			2364.295,
			2366.187,
			2368.086,
			2369.04,
			2370.957,
			2372.884,
			2373.851,
			2376.773,
			2378.732,
			2380.703,
			2383.679,
			2385.687,
			2388.727,
			2389.748,
			2390.774,
			2393.87,
			2395.939,
			2399.059,
			2401.145,
			2403.238,
			2404.289,
			2406.395,
			2407.451,
			2409.554,
			2412.771,
			2414.917,
			2417.086,
			2420.359,
			2421.454,
			2422.552,
			2424.755,
			2426.967,
			2428.076,
			2430.303,
			2431.42,
			2433.661,
			2435.909,
			2439.297,
			2441.567,
			2444.991,
			2446.135,
			2447.28,
			2450.731,
			2453.042,
			2456.532,
			2458.877,
			2461.233,
			2462.415,
			2464.786,
			2465.977,
			2468.365,
			2470.766,
			2471.974,
			2474.397,
			2476.831,
			2480.499,
			2482.957,
			2486.668,
			2487.913,
			2489.159,
			2492.924,
			2495.449,
			2499.268,
			2501.827,
			2504.397,
			2508.268,
			2509.562,
			2510.858,
			2514.762,
			2516.066,
			2517.372,
			2521.297,
			2523.916,
			2527.83,
			2530.476,
			2533.106,
			2534.423,
			2537.061,
			2538.384,
			2541.037,
			2543.7,
			2547.692,
			2550.38,
			2554.439,
			2555.797,
			2557.182,
			2561.26,
			2564.003,
			2568.131,
			2570.893,
			2575.04,
			2577.811,
			2579.198,
			2581.977,
			2586.158,
			2587.555,
			2591.758,
			2594.57,
			2598.803,
			2601.635,
			2604.476,
			2605.898,
			2608.751,
			2610.18,
			2613.043,
			2615.915,
			2620.236,
			2623.127,
			2627.479,
			2628.933,
			2630.389,
			2634.751,
			2637.649,
			2641.966,
			2644.819,
			2647.644,
			2649.045,
			2651.821,
			2653.196,
			2655.918,
			2659.933,
			2662.563,
			2665.156,
			2667.713,
			2671.482,
			2675.192,
			2676.405,
			2677.609,
			2681.177,
			2683.516,
			2685.819,
			2689.209,
			2691.438,
			2694.745,
			2695.84,
			2696.932,
			2700.189,
			2702.354,
			2705.594,
			2707.754,
			2709.917,
			2710.999,
			2713.168,
			2714.259,
			2716.441,
			2718.641,
			2719.75,
			2721.973,
			2724.223,
			2727.624,
			2729.926,
			2733.398,
			2734.54,
			2735.724,
			2739.224,
			2741.56,
			2745.058,
			2747.39,
			2749.716,
			2753.213,
			2754.373,
			2755.534,
			2759.014,
			2760.166,
			2761.317,
			2764.746,
			2767.008,
			2769.247,
			2770.355,
			2772.554,
			2774.734,
			2775.813,
			2777.948,
			2779.011,
			2781.114,
			2783.18,
			2786.259,
			2788.269,
			2791.225,
			2792.199,
			2793.164,
			2795.998,
			2797.861,
			2800.561,
			2802.333,
			2804.086,
			2806.625,
			2807.454,
			2809.07,
			2810.676,
			2811.46,
			2812.237,
			2814.534,
			2816.037,
			2818.254,
			2819.707,
			2821.143,
			2821.857,
			2823.282,
			2823.979,
			2825.348,
			2826.71,
			2828.747,
			2830.082,
			2832.076,
			2832.74,
			2833.404,
			2835.476,
			2836.86,
			2838.959,
			2840.428,
			2842.631,
			2844.151,
			2844.971,
			2846.61,
			2848.251,
			2849.075,
			2850.733,
			2852.394,
			2854.822,
			2856.378,
			2857.941,
			2859.489,
			2860.25,
			2861.776,
			2862.542,
			2864.073,
			2866.333,
			2867.849,
			2869.369,
			2871.65,
			2872.415,
			2873.181,
			2875.512,
			2876.297,
			2877.084,
			2879.467,
			2881.068,
			2882.693,
			2883.51,
			2885.155,
			2885.983,
			2887.647,
			2890.186,
			2891.876,
			2893.606,
			2896.235,
			2897.118,
			2898.004,
			2900.718,
			2901.645,
			2902.572,
			2905.411,
			2907.321,
			2909.258,
			2912.187,
			2914.156,
			2917.069,
			2918.045,
			2919.022,
			2921.869,
			2923.769,
			2926.574,
			2928.422,
			2930.286,
			2931.23,
			2933.095,
			2934.962,
			2935.906,
			2937.804,
			2938.757,
			2940.673,
			2942.6,
			2945.512,
			2947.466,
			2950.418,
			2951.407,
			2952.4,
			2955.394,
			2957.403,
			2960.437,
			2962.472,
			2964.518,
			2965.544,
			2967.604,
			2968.637,
			2969.673,
			2972.792,
			2973.836,
			2974.879,
			2978.006,
			2980.098,
			2982.201,
			2983.256,
			2985.374,
			2987.503,
			2988.571,
			2990.714,
			2991.79,
			2993.948,
			2996.115,
			2999.383,
			3001.572,
			3004.874,
			3005.979,
			3007.087,
			3010.425,
			3012.663,
			3016.038,
			3018.301,
			3020.573,
			3021.714,
			3024,
			3025.146,
			3027.444,
			3030.908,
			3033.23,
			3035.563,
			3039.079,
			3041.433,
			3043.793,
			3046.162,
			3047.348,
			3049.728,
			3050.921,
			3053.312,
			3055.712,
			3059.327,
			3061.748,
			3065.394,
			3066.614,
			3067.836,
			3071.513,
			3073.975,
			3077.683,
			3080.165,
			3082.655,
			3083.903,
			3086.405,
			3087.658,
			3090.172,
			3092.693,
			3093.957,
			3096.489,
			3099.03,
			3102.854,
			3104.132,
			3105.412,
			3109.264,
			3110.551,
			3111.841,
			3115.72,
			3118.314,
			3120.916,
			3124.817,
			3127.424,
			3131.348,
			3132.659,
			3133.972,
			3137.919,
			3140.558,
			3144.53,
			3147.186,
			3149.848,
			3151.182,
			3153.855,
			3156.534,
			3157.876,
			3160.566,
			3161.914,
			3164.613,
			3167.32,
			3171.393,
			3174.116,
			3178.215,
			3179.585,
			3180.956,
			3185.08,
			3187.838,
			3191.986,
			3194.761,
			3197.542,
			3201.728,
			3203.127,
			3204.528,
			3208.738,
			3210.146,
			3211.554,
			3215.79,
			3218.621,
			3221.458,
			3222.879,
			3225.725,
			3228.578,
			3230.007,
			3232.866,
			3234.295,
			3237.148,
			3239.989,
			3244.208,
			3246.983,
			3251.07,
			3252.413,
			3253.746,
			3257.682,
			3260.261,
			3264.061,
			3266.55,
			3269.001,
			3270.214,
			3272.612,
			3273.797,
			3276.142,
			3278.453,
			3279.594,
			3281.848,
			3284.063,
			3287.316,
			3288.382,
			3289.438,
			3292.55,
			3293.569,
			3294.579,
			3296.57,
			3297.552,
			3299.487,
			3301.386,
			3304.167,
			3305.978,
			3308.63,
			3309.497,
			3310.354,
			3312.871,
			3314.503,
			3316.882,
			3318.434,
			3319.94,
			3320.683,
			3322.147,
			3322.871,
			3324.308,
			3325.724,
			3326.427,
			3327.825,
			3329.219,
			3331.276,
			3331.958,
			3332.641,
			3334.006,
			3335.385,
			3336.077,
			3338.153,
			3339.537,
			3341.052,
			3343.327,
			3344.864,
			3347.324,
			3348.143,
			3348.96,
			3351.351,
			3352.924,
			3355.214,
			3356.628,
			3358.009,
			3358.696,
			3360.025,
			3360.648,
			3361.884,
			3363.672,
			3364.831,
			3365.958,
			3367.611,
			3368.661,
			3370.318,
			3370.936,
			3371.55,
			3373.378,
			3374.597,
			3377.062,
			3378.748,
			3380.429,
			3381.27,
			3382.941,
			3383.771,
			3385.288,
			3387.123,
			3387.731,
			3389.547,
			3390.626,
			3392.152,
			3393.168,
			3394.186,
			3394.696,
			3395.689,
			3396.181,
			3397.167,
			3398.155,
			3399.644,
			3400.645,
			3402.154,
			3402.66,
			3403.167,
			3404.696,
			3405.738,
			3407.324,
			3408.392,
			3410.011,
			3411.114,
			3411.669,
			3412.789,
			3413.92,
			3414.49,
			3415.643,
			3416.812,
			3417.998,
			3419.2,
			3419.797,
			3421.023,
			3422.266,
			3422.894,
			3424.162,
			3424.803,
			3426.095,
			3427.404,
			3429.398,
			3430.75,
			3432.821,
			3433.52,
			3434.223,
			3436.359,
			3437.808,
			3440.012,
			3441.502,
			3443.008,
			3443.767,
			3445.297,
			3446.067,
			3447.619,
			3449.975,
			3451.564,
			3453.168,
			3455.603,
			3456.421,
			3457.243,
			3459.733,
			3460.57,
			3461.411,
			3463.949,
			3465.642,
			3468.199,
			3469.921,
			3471.656,
			3474.285,
			3475.167,
			3476.054,
			3478.744,
			3479.628,
			3480.53,
			3483.254,
			3485.086,
			3486.931,
			3487.857,
			3489.721,
			3491.597,
			3492.54,
			3494.436,
			3495.388,
			3497.305,
			3499.234,
			3502.153,
			3504.115,
			3507.08,
			3508.073,
			3509.07,
			3512.077,
			3514.095,
			3517.144,
			3519.189,
			3521.245,
			3522.278,
			3524.348,
			3525.387,
			3526.429,
			3529.558,
			3530.601,
			3531.645,
			3534.794,
			3536.908,
			3539.032,
			3540.098,
			3542.237,
			3544.386,
			3545.465,
			3547.63,
			3548.715,
			3550.895,
			3553.083,
			3556.387,
			3558.603,
			3561.951,
			3563.072,
			3564.198,
			3567.593,
			3569.871,
			3573.312,
			3575.622,
			3579.106,
			3581.442,
			3582.614,
			3584.962,
			3587.32,
			3588.502,
			3590.872,
			3593.251,
			3595.64,
			3598.038,
			3599.241,
			3601.653,
			3604.074,
			3605.288,
			3607.724,
			3608.944,
			3611.394,
			3613.852,
			3617.558,
			3620.041,
			3623.78,
			3625.032,
			3626.285,
			3630.061,
			3632.592,
			3636.411,
			3638.971,
			3641.544,
			3642.835,
			3645.422,
			3646.72,
			3649.323,
			3653.245,
			3655.871,
			3658.502,
			3662.45,
			3663.77,
			3665.091,
			3669.063,
			3670.391,
			3671.72,
			3675.717,
			3678.387,
			3681.066,
			3685.089,
			3687.779,
			3691.827,
			3693.181,
			3694.536,
			3698.618,
			3701.35,
			3705.463,
			3708.213,
			3712.35,
			3715.119,
			3717.895,
			3719.287,
			3723.469,
			3726.265,
			3729.065,
			3733.277,
			3736.092,
			3740.33,
			3741.746,
			3743.163,
			3747.427,
			3750.278,
			3754.57,
			3757.442,
			3760.322,
			3761.765,
			3764.654,
			3766.1,
			3767.548,
			3771.899,
			3773.353,
			3774.809,
			3779.188,
			3782.121,
			3786.535,
			3789.486,
			3792.441,
			3793.921,
			3796.883,
			3798.366,
			3801.337,
			3804.314,
			3808.793,
			3811.787,
			3816.292,
			3817.798,
			3819.305,
			3823.836,
			3826.865,
			3831.418,
			3834.461,
			3837.507,
			3839.033,
			3842.087,
			3843.617,
			3846.682,
			3849.755,
			3851.294,
			3854.379,
			3857.472,
			3860.571,
			3863.679,
			3865.235,
			3868.353,
			3871.478,
			3873.043,
			3876.177,
			3877.748,
			3880.893,
			3884.041,
			3888.792,
			3891.944,
			3896.654,
			3898.236,
			3899.82,
			3904.577,
			3907.78,
			3912.56,
			3915.754,
			3918.956,
			3920.558,
			3923.742,
			3925.351,
			3928.6,
			3931.833,
			3933.457,
			3936.72,
			3940.01,
			3944.976,
			3946.61,
			3948.272,
			3953.241,
			3954.893,
			3956.547,
			3961.533,
			3964.834,
			3968.101,
			3973.038,
			3976.328,
			3981.239,
			3982.894,
			3984.511,
			3989.276,
			3992.427,
			3997.091,
			4000.154,
			4003.177,
			4004.675,
			4007.629,
			4010.545,
			4011.986,
			4014.84,
			4016.255,
			4019.057,
			4021.823,
			4025.904,
			4028.58,
			4032.525,
			4033.822,
			4035.11,
			4038.918,
			4041.412,
			4045.083,
			4047.486,
			4049.856,
			4053.35,
			4054.498,
			4056.765,
			4058.998,
			4060.1,
			4061.193,
			4064.419,
			4066.526,
			4068.596,
			4069.618,
			4071.635,
			4073.616,
			4074.593,
			4077.472,
			4079.346,
			4081.187,
			4083.887,
			4085.647,
			4088.238,
			4089.079,
			4089.911,
			4092.354,
			4093.937,
			4096.25,
			4097.749,
			4099.939,
			4101.371,
			4102.079,
			4103.469,
			4105.523,
			4106.201,
			4108.236,
			4109.586,
			4110.933,
			4111.606,
			4113.001,
			4114.424,
			4115.135,
			4116.558,
			4117.283,
			4118.854,
			4120.425,
			4122.814,
			4124.477,
			4126.974,
			4127.784,
			4128.575,
			4130.955,
			4132.544,
			4134.808,
			4136.322,
			4137.842,
			4138.599,
			4140.116,
			4140.877,
			4142.407,
			4143.932,
			4144.695,
			4146.229,
			4147.766,
			4149.968,
			4151.447,
			4152.929,
			4154.381,
			4155.11,
			4156.576,
			4157.314,
			4158.806,
			4160.311,
			4162.59,
			4164.16,
			4166.547,
			4167.348,
			4168.154,
			4170.659,
			4172.345,
			4174.913,
			4176.64,
			4178.37,
			4179.241,
			4180.978,
			4182.734,
			4183.612,
			4185.37,
			4186.252,
			4188.026,
			4189.814,
			4192.513,
			4193.43,
			4194.333,
			4197.022,
			4197.922,
			4198.821,
			4200.612,
			4201.506,
			4203.305,
			4205.967,
			4207.734,
			4209.496,
			4212.121,
			4212.993,
			4213.863,
			4216.44,
			4217.293,
			4218.145,
			4220.661,
			4222.315,
			4223.964,
			4224.77,
			4226.357,
			4227.934,
			4228.72,
			4231.083,
			4232.653,
			4234.218,
			4236.674,
			4238.309,
			4240.848,
			4241.711,
			4242.575,
			4245.181,
			4246.931,
			4249.555,
			4251.34,
			4253.122,
			4254.013,
			4255.866,
			4256.804,
			4257.741,
			4260.592,
			4261.566,
			4263.516,
			4265.453,
			4267.366,
			4269.288,
			4270.236,
			4272.067,
			4273.902,
			4274.823,
			4276.595,
			4277.475,
			4279.239,
			4280.979,
			4283.546,
			4285.267,
			4287.818,
			4288.671,
			4289.526,
			4292.102,
			4293.825,
			4296.431,
			4298.188,
			4300.834,
			4302.633,
			4303.534,
			4305.342,
			4307.169,
			4308.087,
			4309.929,
			4311.775,
			4314.555,
			4315.486,
			4316.418,
			4318.288,
			4320.166,
			4321.108,
			4323.01,
			4323.965,
			4325.876,
			4327.807,
			4330.709,
			4332.689,
			4335.664,
			4336.662,
			4337.666,
			4341.693,
			4342.707,
			4345.763,
			4347.817,
			4349.84,
			4350.862,
			4352.922,
			4355.994,
			4358.047,
			4359.074,
			4361.11,
			4363.179,
			4366.25,
			4368.321,
			4371.447,
			4372.495,
			4373.568,
			4376.75,
			4378.87,
			4382.112,
			4384.249,
			4387.492,
			4389.661,
			4390.749,
			4391.839,
			4395.122,
			4396.221,
			4397.322,
			4400.64,
			4402.863,
			4406.217,
			4408.464,
			4410.723,
			4411.855,
			4414.127,
			4415.268,
			4417.554,
			4419.85,
			4423.31,
			4425.63,
			4429.129,
			4430.303,
			4431.479,
			4435.021,
			4437.395,
			4440.971,
			4443.365,
			4446.973,
			4449.391,
			4450.602,
			4451.815,
			4456.694,
			4459.147,
			4461.608,
			4464.08,
			4466.56,
			4467.803,
			4470.296,
			4472.8,
			4474.054,
			4477.832,
			4480.359,
			4482.897,
			4486.718,
			4489.275,
			4493.127,
			4494.415,
			4495.705,
			4499.585,
			4502.182,
			4506.091,
			4508.698,
			4511.31,
			4512.618,
			4515.24,
			4516.553,
			4519.184,
			4521.822,
			4523.144,
			4525.792,
			4528.448,
			4532.446,
			4533.783,
			4535.122,
			4539.149,
			4540.495,
			4541.842,
			4545.895,
			4548.607,
			4552.696,
			4555.437,
			4558.186,
			4562.329,
			4563.715,
			4565.104,
			4569.281,
			4572.067,
			4576.268,
			4579.081,
			4581.915,
			4583.336,
			4586.183,
			4587.607,
			4590.462,
			4593.323,
			4594.756,
			4597.612,
			4600.472,
			4604.75,
			4607.608,
			4611.906,
			4613.343,
			4614.781,
			4619.104,
			4621.993,
			4626.337,
			4629.237,
			4632.142,
			4636.505,
			4637.963,
			4639.422,
			4643.806,
			4645.27,
			4646.735,
			4651.138,
			4654.081,
			4657.029,
			4658.504,
			4661.458,
			4664.417,
			4665.897,
			4670.342,
			4673.31,
			4676.283,
			4680.76,
			4683.757,
			4688.279,
			4689.794,
			4691.312,
			4695.877,
			4698.929,
			4703.508,
			4706.565,
			4709.627,
			4711.16,
			4714.229,
			4715.765,
			4718.842,
			4721.924,
			4723.467,
			4725.011,
			4729.652,
			4732.753,
			4737.414,
			4740.528,
			4743.645,
			4745.205,
			4748.325,
			4749.886,
			4753.01,
			4756.138,
			4760.845,
			4763.993,
			4768.726,
			4770.307,
			4771.889,
			4776.643,
			4779.818,
			4784.591,
			4787.777,
			4790.963,
			4792.557,
			4795.749,
			4798.945,
			4800.544,
			4803.746,
			4805.348,
			4808.556,
			4811.767,
			4816.592,
			4818.202,
			4819.813,
			4824.653,
			4826.268,
			4827.884,
			4832.737,
			4835.978,
			4839.222,
			4844.094,
			4847.347,
			4852.231,
			4853.859,
			4855.489,
			4860.384,
			4863.652,
			4868.562,
			4871.84,
			4876.765,
			4880.05,
			4881.694,
			4884.988,
			4889.94,
			4893.244,
			4896.55,
			4897.376
		],
		[
			0,
			0.042,
			0.103,
			0.167,
			0.229,
			0.289,
			0.352,
			0.383,
			0.415,
			0.475,
			0.492,
			0.539,
			0.602,
			0.663,
			0.707,
			0.724,
			0.787,
			0.816,
			0.849,
			0.926,
			0.973,
			1.039,
			1.095,
			1.144,
			1.22,
			1.25,
			1.282,
			1.359,
			1.407,
			1.473,
			1.531,
			1.592,
			1.654,
			1.685,
			1.714,
			1.794,
			1.842,
			1.902,
			1.965,
			2.011,
			2.028,
			2.087,
			2.121,
			2.152,
			2.227,
			2.276,
			2.337,
			2.399,
			2.446,
			2.523,
			2.554,
			2.585,
			2.662,
			2.708,
			2.77,
			2.833,
			2.879,
			2.895,
			2.958,
			2.986,
			3.018,
			3.079,
			3.096,
			3.144,
			3.205,
			3.268,
			3.311,
			3.329,
			3.392,
			3.423,
			3.453,
			3.515,
			3.577,
			3.638,
			3.701,
			3.747,
			3.825,
			3.857,
			3.887,
			3.949,
			4.01,
			4.074,
			4.133,
			4.183,
			4.196,
			4.26,
			4.291,
			4.32,
			4.383,
			4.399,
			4.446,
			4.506,
			4.57,
			4.615,
			4.694,
			4.723,
			4.755,
			4.818,
			4.88,
			4.94,
			5.004,
			5.05,
			5.126,
			5.158,
			5.189,
			5.252,
			5.268,
			5.314,
			5.375,
			5.436,
			5.484,
			5.499,
			5.561,
			5.598,
			5.622,
			5.701,
			5.747,
			5.808,
			5.87,
			5.918,
			5.994,
			6.027,
			6.056,
			6.12,
			6.18,
			6.243,
			6.305,
			6.353,
			6.367,
			6.429,
			6.46,
			6.49,
			6.554,
			6.569,
			6.616,
			6.679,
			6.738,
			6.786,
			6.8,
			6.864,
			6.894,
			6.925,
			6.988,
			7.002,
			7.05,
			7.11,
			7.173,
			7.22,
			7.296,
			7.33,
			7.36,
			7.422,
			7.483,
			7.546,
			7.607,
			7.657,
			7.668,
			7.732,
			7.762,
			7.794,
			7.856,
			7.87,
			7.918,
			7.979,
			8.041,
			8.087,
			8.105,
			8.167,
			8.195,
			8.226,
			8.29,
			8.351,
			8.413,
			8.475,
			8.523,
			8.601,
			8.632,
			8.663,
			8.722,
			8.74,
			8.785,
			8.848,
			8.91,
			8.956,
			8.973,
			9.034,
			9.065,
			9.096,
			9.158,
			9.174,
			9.22,
			9.283,
			9.344,
			9.391,
			9.468,
			9.5,
			9.529,
			9.606,
			9.653,
			9.717,
			9.777,
			9.824,
			9.902,
			9.934,
			9.963,
			10.026,
			10.04,
			10.088,
			10.151,
			10.211,
			10.258,
			10.275,
			10.335,
			10.368,
			10.397,
			10.46,
			10.475,
			10.522,
			10.584,
			10.647,
			10.693,
			10.771,
			10.801,
			10.832,
			10.894,
			10.955,
			11.018,
			11.078,
			11.127,
			11.143,
			11.204,
			11.236,
			11.266,
			11.344,
			11.389,
			11.454,
			11.513,
			11.56,
			11.577,
			11.638,
			11.669,
			11.7,
			11.763,
			11.783,
			11.823,
			11.886,
			11.949,
			12.009,
			12.073,
			12.103,
			12.134,
			12.196,
			12.259,
			12.32,
			12.383,
			12.428,
			12.446,
			12.506,
			12.538,
			12.567,
			12.629,
			12.647,
			12.691,
			12.754,
			12.817,
			12.862,
			12.879,
			12.94,
			12.971,
			13.001,
			13.063,
			13.079,
			13.127,
			13.189,
			13.251,
			13.296,
			13.374,
			13.405,
			13.437,
			13.513,
			13.56,
			13.624,
			13.685,
			13.729,
			13.747,
			13.809,
			13.845,
			13.87,
			13.934,
			13.948,
			13.993,
			14.057,
			14.12,
			14.166,
			14.243,
			14.274,
			14.304,
			14.367,
			14.428,
			14.49,
			14.553,
			14.6,
			14.615,
			14.677,
			14.707,
			14.738,
			14.801,
			14.817,
			14.863,
			14.926,
			14.986,
			15.047,
			15.109,
			15.142,
			15.173,
			15.234,
			15.251,
			15.297,
			15.359,
			15.42,
			15.467,
			15.546,
			15.577,
			15.606,
			15.67,
			15.73,
			15.794,
			15.855,
			15.918,
			15.98,
			16.01,
			16.04,
			16.117,
			16.166,
			16.227,
			16.289,
			16.334,
			16.351,
			16.413,
			16.444,
			16.474,
			16.537,
			16.553,
			16.6,
			16.663,
			16.722,
			16.784,
			16.848,
			16.879,
			16.909,
			16.988,
			17.034,
			17.095,
			17.157,
			17.204,
			17.219,
			17.28,
			17.311,
			17.342,
			17.404,
			17.421,
			17.468,
			17.53,
			17.591,
			17.637,
			17.654,
			17.716,
			17.747,
			17.777,
			17.84,
			17.901,
			17.967,
			18.024,
			18.087,
			18.147,
			18.179,
			18.211,
			18.272,
			18.335,
			18.399,
			18.46,
			18.506,
			18.521,
			18.585,
			18.613,
			18.646,
			18.722,
			18.768,
			18.833,
			18.893,
			18.94,
			19.017,
			19.05,
			19.079,
			19.143,
			19.202,
			19.267,
			19.326,
			19.375,
			19.451,
			19.483,
			19.513,
			19.59,
			19.638,
			19.701,
			19.761,
			19.809,
			19.823,
			19.887,
			19.916,
			19.949,
			20.008,
			20.029,
			20.071,
			20.134,
			20.195,
			20.241,
			20.321,
			20.35,
			20.382,
			20.443,
			20.507,
			20.571,
			20.629,
			20.677,
			20.691,
			20.754,
			20.785,
			20.816,
			20.876,
			20.892,
			20.94,
			21.001,
			21.062,
			21.11,
			21.127,
			21.189,
			21.22,
			21.248,
			21.313,
			21.327,
			21.373,
			21.436,
			21.498,
			21.546,
			21.621,
			21.654,
			21.685,
			21.747,
			21.808,
			21.87,
			21.933,
			21.979,
			21.993,
			22.056,
			22.091,
			22.117,
			22.178,
			22.195,
			22.243,
			22.303,
			22.367,
			22.412,
			22.429,
			22.49,
			22.52,
			22.551,
			22.628,
			22.676,
			22.737,
			22.8,
			22.848,
			22.923,
			22.956,
			22.986,
			23.049,
			23.109,
			23.174,
			23.233,
			23.281,
			23.295,
			23.357,
			23.39,
			23.42,
			23.497,
			23.545,
			23.606,
			23.667,
			23.716,
			23.791,
			23.824,
			23.855,
			23.917,
			23.977,
			24.04,
			24.103,
			24.163,
			24.228,
			24.257,
			24.289,
			24.351,
			24.366,
			24.413,
			24.474,
			24.536,
			24.583,
			24.599,
			24.66,
			24.693,
			24.722,
			24.786,
			24.8,
			24.849,
			24.908,
			24.97,
			25.032,
			25.093,
			25.127,
			25.157,
			25.219,
			25.279,
			25.343,
			25.405,
			25.451,
			25.467,
			25.529,
			25.559,
			25.592,
			25.667,
			25.715,
			25.776,
			25.84,
			25.886,
			25.9,
			25.962,
			25.993,
			26.023,
			26.085,
			26.102,
			26.149,
			26.215,
			26.274,
			26.32,
			26.396,
			26.427,
			26.458,
			26.52,
			26.583,
			26.645,
			26.707,
			26.753,
			26.768,
			26.83,
			26.86,
			26.893,
			26.955,
			26.969,
			27.017,
			27.079,
			27.141,
			27.187,
			27.204,
			27.266,
			27.296,
			27.328,
			27.404,
			27.451,
			27.513,
			27.576,
			27.637,
			27.7,
			27.73,
			27.762,
			27.823,
			27.84,
			27.884,
			27.949,
			28.01,
			28.055,
			28.072,
			28.134,
			28.163,
			28.194,
			28.257,
			28.277,
			28.319,
			28.382,
			28.443,
			28.505,
			28.567,
			28.599,
			28.63,
			28.706,
			28.753,
			28.816,
			28.879,
			28.938,
			29,
			29.033,
			29.064,
			29.124,
			29.14,
			29.187,
			29.251,
			29.313,
			29.357,
			29.373,
			29.436,
			29.467,
			29.496,
			29.56,
			29.576,
			29.621,
			29.684,
			29.745,
			29.79,
			29.868,
			29.901,
			29.931,
			30.01,
			30.055,
			30.118,
			30.179,
			30.243,
			30.303,
			30.339,
			30.364,
			30.443,
			30.489,
			30.551,
			30.615,
			30.659,
			30.676,
			30.737,
			30.769,
			30.8,
			30.861,
			30.878,
			30.923,
			30.986,
			31.048,
			31.095,
			31.17,
			31.202,
			31.234,
			31.297,
			31.357,
			31.422,
			31.482,
			31.529,
			31.544,
			31.607,
			31.637,
			31.667,
			31.746,
			31.792,
			31.853,
			31.917,
			31.978,
			32.04,
			32.07,
			32.1,
			32.164,
			32.179,
			32.227,
			32.287,
			32.35,
			32.411,
			32.475,
			32.504,
			32.536,
			32.599,
			32.659,
			32.723,
			32.785,
			32.83,
			32.845,
			32.907,
			32.94,
			32.971,
			33.03,
			33.046,
			33.094,
			33.156,
			33.217,
			33.279,
			33.342,
			33.374,
			33.404,
			33.483,
			33.529,
			33.59,
			33.652,
			33.698,
			33.775,
			33.806,
			33.837,
			33.901,
			33.916,
			33.962,
			34.025,
			34.086,
			34.133,
			34.148,
			34.21,
			34.242,
			34.273,
			34.333,
			34.35,
			34.397,
			34.459,
			34.52,
			34.567,
			34.644,
			34.674,
			34.706,
			34.767,
			34.832,
			34.892,
			34.953,
			35,
			35.016,
			35.08,
			35.11,
			35.14,
			35.203,
			35.216,
			35.265,
			35.326,
			35.388,
			35.45,
			35.514,
			35.545,
			35.575,
			35.636,
			35.652,
			35.699,
			35.76,
			35.823,
			35.869,
			35.948,
			35.978,
			36.008,
			36.07,
			36.133,
			36.195,
			36.258,
			36.303,
			36.319,
			36.379,
			36.41,
			36.443,
			36.504,
			36.523,
			36.568,
			36.631,
			36.691,
			36.736,
			36.754,
			36.813,
			36.846,
			36.877,
			36.94,
			37.001,
			37.063,
			37.123,
			37.17,
			37.25,
			37.279,
			37.31,
			37.374,
			37.435,
			37.496,
			37.559,
			37.621,
			37.684,
			37.715,
			37.745,
			37.805,
			37.822,
			37.868,
			37.93,
			37.992,
			38.039,
			38.116,
			38.149,
			38.18,
			38.242,
			38.304,
			38.367,
			38.427,
			38.472,
			38.551,
			38.587,
			38.613,
			38.676,
			38.69,
			38.737,
			38.801,
			38.862,
			38.907,
			38.924,
			38.984,
			39.014,
			39.048,
			39.11,
			39.123,
			39.172,
			39.234,
			39.296,
			39.342,
			39.418,
			39.45,
			39.482,
			39.558,
			39.606,
			39.668,
			39.729,
			39.775,
			39.792,
			39.854,
			39.884,
			39.916,
			39.991,
			40.04,
			40.102,
			40.163,
			40.21,
			40.225,
			40.287,
			40.317,
			40.35,
			40.41,
			40.427,
			40.473,
			40.536,
			40.597,
			40.649,
			40.72,
			40.753,
			40.783,
			40.846,
			40.907,
			40.971,
			41.03,
			41.076,
			41.092,
			41.154,
			41.185,
			41.216,
			41.28,
			41.296,
			41.34,
			41.404,
			41.466,
			41.513,
			41.526,
			41.59,
			41.621,
			41.651,
			41.713,
			41.73,
			41.775,
			41.837,
			41.9,
			41.945,
			42.024,
			42.054,
			42.084,
			42.146,
			42.21,
			42.273,
			42.334,
			42.378,
			42.396,
			42.457,
			42.487,
			42.521,
			42.582,
			42.596,
			42.644,
			42.709,
			42.767,
			42.813,
			42.83,
			42.89,
			42.923,
			42.956,
			43.032,
			43.076,
			43.139,
			43.201,
			43.248,
			43.325,
			43.357,
			43.387,
			43.449,
			43.466,
			43.512,
			43.575,
			43.634,
			43.683,
			43.699,
			43.759,
			43.79,
			43.82,
			43.9,
			43.945,
			44.009,
			44.07,
			44.116,
			44.193,
			44.226,
			44.257,
			44.334,
			44.379,
			44.442,
			44.503,
			44.566,
			44.629,
			44.659,
			44.69,
			44.753,
			44.773,
			44.813,
			44.876,
			44.937,
			44.984,
			45,
			45.063,
			45.092,
			45.125,
			45.187,
			45.2,
			45.248,
			45.309,
			45.373,
			45.417,
			45.496,
			45.526,
			45.558,
			45.621,
			45.683,
			45.743,
			45.806,
			45.869,
			45.928,
			45.96,
			45.992,
			46.054,
			46.07,
			46.117,
			46.178,
			46.241,
			46.287,
			46.303,
			46.366,
			46.395,
			46.426,
			46.505,
			46.55,
			46.614,
			46.675,
			46.719,
			46.799,
			46.833,
			46.861,
			46.937,
			46.985,
			47.046,
			47.108,
			47.154,
			47.171,
			47.234,
			47.265,
			47.295,
			47.372,
			47.418,
			47.481,
			47.543,
			47.603,
			47.667,
			47.697,
			47.729,
			47.791,
			47.853,
			47.916,
			47.976,
			48.04,
			48.1,
			48.132,
			48.162,
			48.226,
			48.24,
			48.287,
			48.349,
			48.411,
			48.473,
			48.534,
			48.566,
			48.597,
			48.66,
			48.674,
			48.72,
			48.784,
			48.845,
			48.896,
			48.97,
			49.001,
			49.032,
			49.094,
			49.155,
			49.218,
			49.278,
			49.325,
			49.34,
			49.403,
			49.433,
			49.463,
			49.525,
			49.543,
			49.587,
			49.65,
			49.713,
			49.758,
			49.775,
			49.836,
			49.867,
			49.899,
			49.977,
			50.023,
			50.085,
			50.147,
			50.21,
			50.27,
			50.303,
			50.333,
			50.411,
			50.456,
			50.518,
			50.58,
			50.629,
			50.643,
			50.706,
			50.735,
			50.768,
			50.845,
			50.89,
			50.959,
			51.015,
			51.06,
			51.078,
			51.138,
			51.171,
			51.202,
			51.262,
			51.279,
			51.326,
			51.387,
			51.449,
			51.495,
			51.573,
			51.603,
			51.636,
			51.698,
			51.759,
			51.822,
			51.884,
			51.929,
			51.945,
			52.006,
			52.038,
			52.07,
			52.132,
			52.146,
			52.194,
			52.254,
			52.316,
			52.365,
			52.442,
			52.472,
			52.504,
			52.566,
			52.629,
			52.689,
			52.751,
			52.797,
			52.875,
			52.906,
			52.936,
			53,
			53.02,
			53.062,
			53.124,
			53.187,
			53.246,
			53.31,
			53.34,
			53.371,
			53.434,
			53.449,
			53.497,
			53.559,
			53.621,
			53.666,
			53.743,
			53.773,
			53.806,
			53.868,
			53.931,
			53.993,
			54.054,
			54.115,
			54.177,
			54.209,
			54.24,
			54.318,
			54.362,
			54.426,
			54.486,
			54.55,
			54.613,
			54.642,
			54.673,
			54.737,
			54.751,
			54.799,
			54.861,
			54.92,
			54.967,
			55.046,
			55.082,
			55.107,
			55.17,
			55.232,
			55.294,
			55.355,
			55.403,
			55.417,
			55.479,
			55.513,
			55.542,
			55.619,
			55.666,
			55.728,
			55.789,
			55.852,
			55.915,
			55.946,
			55.976,
			56.052,
			56.1,
			56.162,
			56.223,
			56.286,
			56.349,
			56.379,
			56.411,
			56.473,
			56.534,
			56.596,
			56.657,
			56.704,
			56.721,
			56.782,
			56.812,
			56.844,
			56.906,
			56.921,
			56.968,
			57.031,
			57.093,
			57.154,
			57.217,
			57.248,
			57.276,
			57.341,
			57.402,
			57.465,
			57.525,
			57.587,
			57.651,
			57.68,
			57.713,
			57.774,
			57.79,
			57.836,
			57.9,
			57.961,
			58.007,
			58.021,
			58.085,
			58.116,
			58.146,
			58.208,
			58.223,
			58.27,
			58.334,
			58.394,
			58.44,
			58.518,
			58.551,
			58.579,
			58.659,
			58.703,
			58.768,
			58.828,
			58.889,
			58.953,
			58.984,
			59.015,
			59.075,
			59.092,
			59.139,
			59.205,
			59.262,
			59.326,
			59.387,
			59.416,
			59.45,
			59.51,
			59.526,
			59.573,
			59.636,
			59.696,
			59.743,
			59.82,
			59.851,
			59.882,
			59.945,
			60.005,
			60.069,
			60.132,
			60.194,
			60.253,
			60.285,
			60.316,
			60.379,
			60.393,
			60.44,
			60.503,
			60.566,
			60.627,
			60.688,
			60.718,
			60.751,
			60.813,
			60.829,
			60.874,
			60.94,
			60.999,
			61.044,
			61.123,
			61.154,
			61.185,
			61.246,
			61.267,
			61.309,
			61.37,
			61.434,
			61.481,
			61.494,
			61.558,
			61.587,
			61.62,
			61.696,
			61.743,
			61.805,
			61.867,
			61.913,
			61.93,
			61.99,
			62.021,
			62.053,
			62.116,
			62.176,
			62.238,
			62.301,
			62.347,
			62.424,
			62.457,
			62.487,
			62.55,
			62.61,
			62.673,
			62.734,
			62.782,
			62.797,
			62.859,
			62.891,
			62.921,
			62.984,
			62.999,
			63.046,
			63.11,
			63.168,
			63.217,
			63.293,
			63.33,
			63.356,
			63.418,
			63.48,
			63.543,
			63.604,
			63.65,
			63.665,
			63.726,
			63.757,
			63.788,
			63.852,
			63.868,
			63.913,
			63.975,
			64.036,
			64.084,
			64.101,
			64.16,
			64.191,
			64.223,
			64.285,
			64.301,
			64.347,
			64.41,
			64.473,
			64.534,
			64.597,
			64.628,
			64.657,
			64.72,
			64.783,
			64.844,
			64.905,
			64.953,
			64.967,
			65.03,
			65.06,
			65.092,
			65.168,
			65.216,
			65.277,
			65.34,
			65.401,
			65.463,
			65.494,
			65.524,
			65.586,
			65.603,
			65.65,
			65.713,
			65.775,
			65.836,
			65.898,
			65.929,
			65.96,
			66.023,
			66.083,
			66.145,
			66.206,
			66.255,
			66.27,
			66.333,
			66.363,
			66.393,
			66.456,
			66.472,
			66.517,
			66.579,
			66.641,
			66.688,
			66.702,
			66.766,
			66.796,
			66.827,
			66.89,
			66.951,
			67.013,
			67.076,
			67.138,
			67.2,
			67.232,
			67.263,
			67.325,
			67.387,
			67.447,
			67.511,
			67.558,
			67.572,
			67.634,
			67.665,
			67.695,
			67.757,
			67.775,
			67.82,
			67.883,
			67.944,
			68.007,
			68.069,
			68.097,
			68.129,
			68.192,
			68.254,
			68.317,
			68.379,
			68.425,
			68.502,
			68.533,
			68.564,
			68.626,
			68.641,
			68.689,
			68.75,
			68.813,
			68.86,
			68.874,
			68.935,
			68.968,
			68.996,
			69.06,
			69.074,
			69.123,
			69.183,
			69.245,
			69.307,
			69.371,
			69.4,
			69.434,
			69.494,
			69.557,
			69.62,
			69.679,
			69.728,
			69.741,
			69.804,
			69.836,
			69.868,
			69.927,
			69.943,
			69.991,
			70.058,
			70.115,
			70.16,
			70.177,
			70.24,
			70.27,
			70.3,
			70.361,
			70.379,
			70.424,
			70.487,
			70.548,
			70.611,
			70.672,
			70.703,
			70.734,
			70.797,
			70.86,
			70.922,
			70.984,
			71.029,
			71.046,
			71.106,
			71.136,
			71.17,
			71.23,
			71.247,
			71.294,
			71.354,
			71.417,
			71.461,
			71.479,
			71.539,
			71.575,
			71.603,
			71.678,
			71.726,
			71.789,
			71.851,
			71.898,
			71.973,
			72.004,
			72.038,
			72.114,
			72.162,
			72.224,
			72.285,
			72.33,
			72.347,
			72.41,
			72.438,
			72.471,
			72.549,
			72.593,
			72.656,
			72.719,
			72.78,
			72.842,
			72.873,
			72.906,
			72.968,
			73.029,
			73.09,
			73.154,
			73.2,
			73.215,
			73.276,
			73.309,
			73.34,
			73.418,
			73.463,
			73.526,
			73.587,
			73.65,
			73.711,
			73.743,
			73.774,
			73.836,
			73.852,
			73.895,
			73.96,
			74.022,
			74.067,
			74.144,
			74.175,
			74.206,
			74.268,
			74.332,
			74.393,
			74.454,
			74.518,
			74.578,
			74.609,
			74.642,
			74.704,
			74.718,
			74.767,
			74.827,
			74.889,
			74.937,
			74.951,
			75.013,
			75.043,
			75.076,
			75.136,
			75.154,
			75.2,
			75.26,
			75.322,
			75.37,
			75.449,
			75.479,
			75.508,
			75.57,
			75.633,
			75.701,
			75.758,
			75.805,
			75.82,
			75.882,
			75.913,
			75.943,
			76.021,
			76.069,
			76.129,
			76.191,
			76.239,
			76.252,
			76.317,
			76.346,
			76.379,
			76.441,
			76.5,
			76.565,
			76.626,
			76.673,
			76.75,
			76.782,
			76.813,
			76.874,
			76.89,
			76.936,
			76.999,
			77.059,
			77.106,
			77.121,
			77.184,
			77.215,
			77.246,
			77.308,
			77.322,
			77.371,
			77.43,
			77.492,
			77.54,
			77.618,
			77.65,
			77.68,
			77.74,
			77.804,
			77.866,
			77.926,
			77.973,
			77.99,
			78.053,
			78.083,
			78.113,
			78.177,
			78.191,
			78.237,
			78.3,
			78.362,
			78.407,
			78.424,
			78.485,
			78.517,
			78.548,
			78.609,
			78.626,
			78.673,
			78.736,
			78.796,
			78.857,
			78.921,
			78.951,
			78.983,
			79.043,
			79.107,
			79.168,
			79.231,
			79.293,
			79.354,
			79.385,
			79.416,
			79.477,
			79.495,
			79.54,
			79.601,
			79.663,
			79.711,
			79.725,
			79.787,
			79.824,
			79.849,
			79.912,
			79.929,
			79.974,
			80.036,
			80.096,
			80.144,
			80.223,
			80.254,
			80.284,
			80.363,
			80.408,
			80.469,
			80.531,
			80.579,
			80.595,
			80.657,
			80.687,
			80.717,
			80.797,
			80.841,
			80.903,
			80.967,
			81.013,
			81.028,
			81.09,
			81.122,
			81.152,
			81.229,
			81.276,
			81.339,
			81.401,
			81.448,
			81.523,
			81.556,
			81.588,
			81.664,
			81.711,
			81.773,
			81.834,
			81.896,
			81.959,
			81.99,
			82.02,
			82.099,
			82.145,
			82.206,
			82.267,
			82.331,
			82.392,
			82.422,
			82.456,
			82.516,
			82.58,
			82.64,
			82.702,
			82.75,
			82.765,
			82.826,
			82.859,
			82.887,
			82.949,
			82.967,
			83.013,
			83.076,
			83.137,
			83.2,
			83.261,
			83.293,
			83.321,
			83.385,
			83.4,
			83.447,
			83.508,
			83.572,
			83.616,
			83.695,
			83.726,
			83.757,
			83.82,
			83.881,
			83.946,
			84.006,
			84.052,
			84.068,
			84.13,
			84.161,
			84.19,
			84.253,
			84.268,
			84.316,
			84.376,
			84.439,
			84.486,
			84.5,
			84.563,
			84.595,
			84.625,
			84.687,
			84.703,
			84.749,
			84.812,
			84.872,
			84.921,
			84.997,
			85.027,
			85.06,
			85.12,
			85.183,
			85.246,
			85.306,
			85.354,
			85.368,
			85.432,
			85.463,
			85.493,
			85.556,
			85.57,
			85.617,
			85.679,
			85.741,
			85.786,
			85.804,
			85.865,
			85.895,
			85.926,
			86.009,
			86.05,
			86.113,
			86.176,
			86.236,
			86.3,
			86.329,
			86.361,
			86.422,
			86.484,
			86.546,
			86.609,
			86.656,
			86.67,
			86.734,
			86.765,
			86.797,
			86.856,
			86.873,
			86.92,
			86.983,
			87.045,
			87.09,
			87.167,
			87.199,
			87.23,
			87.291,
			87.354,
			87.416,
			87.478,
			87.523,
			87.601,
			87.633,
			87.665,
			87.726,
			87.742,
			87.786,
			87.851,
			87.912,
			87.959,
			87.973,
			88.037,
			88.071,
			88.097,
			88.175,
			88.222,
			88.283,
			88.345,
			88.393,
			88.47,
			88.5,
			88.532,
			88.593,
			88.657,
			88.718,
			88.779,
			88.843,
			88.903,
			88.936,
			88.966,
			89.043,
			89.088,
			89.153,
			89.213,
			89.259,
			89.277,
			89.337,
			89.37,
			89.4,
			89.461,
			89.476,
			89.524,
			89.586,
			89.649,
			89.696,
			89.773,
			89.803,
			89.834,
			89.91,
			89.959,
			90.02,
			90.082,
			90.133,
			90.143,
			90.205,
			90.237,
			90.267,
			90.329,
			90.346,
			90.393,
			90.454,
			90.516,
			90.577,
			90.639,
			90.67,
			90.703,
			90.763,
			90.78,
			90.826,
			90.888,
			90.95,
			90.996,
			91.074,
			91.105,
			91.135,
			91.198,
			91.26,
			91.323,
			91.384,
			91.429,
			91.446,
			91.509,
			91.54,
			91.571,
			91.633,
			91.646,
			91.693,
			91.755,
			91.819,
			91.863,
			91.882,
			91.941,
			91.974,
			92.004,
			92.064,
			92.082,
			92.129,
			92.193,
			92.251,
			92.299,
			92.376,
			92.407,
			92.438,
			92.501,
			92.516,
			92.563,
			92.623,
			92.686,
			92.733,
			92.749,
			92.81,
			92.842,
			92.873,
			92.949,
			92.997,
			93.06,
			93.119,
			93.184,
			93.246,
			93.277,
			93.305,
			93.385,
			93.429,
			93.493,
			93.554,
			93.602,
			93.616,
			93.679,
			93.711,
			93.739,
			93.802,
			93.818,
			93.866,
			93.927,
			93.988,
			94.035,
			94.051,
			94.111,
			94.144,
			94.175,
			94.237,
			94.257,
			94.299,
			94.359,
			94.424,
			94.484,
			94.546,
			94.579,
			94.61,
			94.671,
			94.731,
			94.794,
			94.855,
			94.919,
			94.982,
			95.013,
			95.042,
			95.103,
			95.12,
			95.166,
			95.229,
			95.291,
			95.338,
			95.354,
			95.413,
			95.447,
			95.476,
			95.539,
			95.556,
			95.601,
			95.663,
			95.725,
			95.771,
			95.85,
			95.88,
			95.911,
			95.99,
			96.036,
			96.097,
			96.16,
			96.205,
			96.222,
			96.283,
			96.344,
			96.408,
			96.421,
			96.468,
			96.531,
			96.594,
			96.64,
			96.717,
			96.749,
			96.78,
			96.857,
			96.904,
			96.97,
			97.029,
			97.09,
			97.151,
			97.183,
			97.213,
			97.276,
			97.292,
			97.337,
			97.401,
			97.46,
			97.522,
			97.586,
			97.617,
			97.646,
			97.708,
			97.726,
			97.772,
			97.834,
			97.894,
			97.957,
			98.02,
			98.05,
			98.08,
			98.145,
			98.205,
			98.269,
			98.33,
			98.392,
			98.453,
			98.485,
			98.514,
			98.593,
			98.64,
			98.701,
			98.763,
			98.811,
			98.825,
			98.889,
			98.92,
			98.95,
			99.027,
			99.072,
			99.135,
			99.197,
			99.243,
			99.321,
			99.353,
			99.385,
			99.445,
			99.507,
			99.569,
			99.631,
			99.678,
			99.693,
			99.757,
			99.788,
			99.818,
			99.879,
			99.895,
			99.941,
			100.002,
			100.067,
			100.112,
			100.126,
			100.191,
			100.221,
			100.252,
			100.329,
			100.376,
			100.443,
			100.501,
			100.547,
			100.624,
			100.655,
			100.687,
			100.746,
			100.809,
			100.871,
			100.934,
			100.979,
			100.996,
			101.057,
			101.088,
			101.12,
			101.183,
			101.197,
			101.243,
			101.307,
			101.368,
			101.416,
			101.491,
			101.524,
			101.553,
			101.616,
			101.678,
			101.74,
			101.802,
			101.849,
			101.927,
			101.957,
			101.989,
			102.051,
			102.066,
			102.111,
			102.174,
			102.237,
			102.283,
			102.297,
			102.359,
			102.39,
			102.421,
			102.504,
			102.546,
			102.607,
			102.671,
			102.717,
			102.793,
			102.824,
			102.857,
			102.918,
			102.978,
			103.041,
			103.104,
			103.151,
			103.166,
			103.229,
			103.26,
			103.29,
			103.352,
			103.369,
			103.413,
			103.475,
			103.537,
			103.6,
			103.662,
			103.693,
			103.724,
			103.787,
			103.801,
			103.849,
			103.909,
			103.972,
			104.019,
			104.096,
			104.127,
			104.159,
			104.222,
			104.282,
			104.346,
			104.408,
			104.454,
			104.467,
			104.529,
			104.567,
			104.592,
			104.655,
			104.67,
			104.716,
			104.78,
			104.841,
			104.887,
			104.904,
			104.965,
			104.996,
			105.027,
			105.09,
			105.152,
			105.214,
			105.274,
			105.337,
			105.4,
			105.429,
			105.462,
			105.524,
			105.584,
			105.647,
			105.709,
			105.77,
			105.834,
			105.864,
			105.895,
			105.971,
			106.019,
			106.079,
			106.086
		]
	],
	'1851_1852_7982': [
		[
			85.891
		],
		[
			0
		],
		[
			0,
			1.194,
			6.971,
			10.817,
			16.582,
			20.425,
			24.268,
			26.19,
			30.035,
			31.959,
			35.809,
			39.662,
			41.59,
			45.448,
			49.309,
			55.104,
			57.038,
			58.971,
			64.775,
			66.71,
			68.646,
			74.458,
			78.335,
			82.213,
			88.034,
			91.918,
			97.751,
			99.698,
			101.646,
			107.498,
			111.403,
			117.263,
			121.167,
			127.024,
			130.927,
			132.879,
			136.782,
			140.683,
			142.634,
			146.53,
			150.425,
			156.264,
			160.157,
			166.002,
			167.952,
			169.902,
			175.759,
			179.668,
			185.536,
			189.453,
			193.375,
			195.338,
			199.266,
			201.231,
			203.197,
			209.094,
			211.061,
			213.028,
			218.927,
			222.859,
			228.758,
			232.692,
			236.627,
			238.594,
			242.53,
			244.498,
			248.435,
			252.373,
			258.281,
			262.222,
			268.136,
			270.107,
			272.079,
			277.995,
			281.942,
			287.863,
			291.812,
			295.762,
			297.737,
			301.688,
			303.664,
			307.617,
			311.571,
			313.549,
			317.505,
			321.462,
			325.42,
			331.359,
			335.319,
			339.28,
			341.261,
			345.224,
			347.206,
			351.17,
			355.136,
			361.086,
			365.054,
			371.009,
			372.995,
			374.981,
			380.944,
			384.922,
			390.89,
			394.871,
			398.851,
			400.841,
			404.821,
			406.811,
			410.788,
			414.764,
			416.75,
			420.724,
			424.693,
			430.618,
			432.581,
			434.535,
			440.349,
			442.269,
			444.179,
			449.849,
			453.579,
			457.27,
			462.735,
			466.328,
			471.64,
			473.392,
			475.134,
			480.306,
			483.705,
			488.731,
			492.033,
			495.296,
			496.912,
			500.114,
			501.7,
			504.845,
			507.953,
			509.493,
			512.546,
			515.563,
			520.021,
			521.488,
			522.946,
			527.261,
			528.681,
			530.09,
			534.263,
			537.012,
			541.073,
			543.74,
			546.361,
			550.211,
			551.469,
			553.957,
			556.402,
			557.606,
			558.801,
			562.323,
			564.621,
			567.981,
			570.169,
			572.327,
			573.395,
			575.504,
			576.552,
			578.638,
			580.686,
			583.701,
			585.683,
			588.552,
			589.49,
			590.418,
			593.148,
			594.924,
			597.527,
			599.216,
			600.873,
			601.69,
			603.292,
			604.079,
			605.633,
			607.16,
			607.904,
			608.64,
			610.827,
			612.272,
			613.711,
			614.429,
			615.863,
			617.299,
			618.028,
			619.492,
			620.224,
			621.69,
			623.165,
			625.389,
			626.872,
			629.14,
			629.901,
			630.662,
			632.949,
			634.503,
			636.829,
			638.391,
			639.979,
			640.772,
			642.356,
			643.148,
			644.804,
			646.461,
			647.287,
			648.966,
			650.678,
			653.24,
			654.113,
			654.985,
			657.598,
			658.468,
			659.36,
			662.035,
			663.827,
			665.666,
			668.423,
			670.359,
			673.294,
			674.227,
			675.112,
			677.768,
			679.466,
			681.859,
			683.459,
			685.015,
			685.782,
			687.308,
			688.856,
			689.627,
			691.163,
			691.934,
			693.479,
			695.025,
			697.341,
			698.111,
			698.879,
			701.177,
			701.952,
			702.713,
			704.999,
			706.545,
			708.853,
			710.412,
			711.985,
			714.341,
			715.137,
			716.742,
			718.346,
			719.148,
			719.951,
			722.431,
			724.084,
			725.758,
			726.609,
			728.313,
			730.018,
			730.875,
			732.585,
			733.44,
			735.151,
			736.866,
			739.432,
			741.152,
			743.748,
			744.612,
			745.474,
			748.039,
			749.742,
			752.276,
			753.952,
			755.628,
			756.474,
			758.186,
			759.042,
			760.753,
			762.465,
			763.32,
			765.03,
			766.729,
			769.201,
			770.029,
			770.858,
			772.485,
			774.084,
			774.886,
			777.296,
			778.872,
			780.457,
			782.84,
			784.413,
			786.794,
			787.595,
			788.411,
			790.878,
			792.539,
			795.122,
			796.86,
			798.607,
			799.497,
			801.286,
			802.185,
			804.007,
			806.771,
			808.643,
			810.541,
			813.41,
			814.37,
			815.333,
			817.27,
			819.221,
			820.203,
			822.176,
			823.168,
			825.168,
			827.181,
			830.234,
			832.272,
			835.378,
			836.423,
			837.471,
			840.607,
			842.704,
			845.836,
			847.927,
			851.206,
			853.433,
			855.736,
			856.896,
			860.326,
			862.569,
			864.781,
			868.032,
			870.231,
			873.553,
			874.668,
			875.786,
			879.121,
			881.276,
			884.489,
			886.595,
			888.699,
			889.745,
			891.835,
			892.88,
			894.968,
			897.054,
			898.1,
			899.148,
			902.32,
			904.447,
			906.584,
			907.651,
			909.793,
			911.945,
			913.025,
			915.195,
			916.285,
			918.472,
			920.665,
			923.968,
			926.186,
			929.531,
			930.652,
			931.775,
			935.158,
			937.421,
			940.812,
			943.067,
			945.307,
			946.418,
			948.635,
			949.733,
			950.821,
			954.052,
			955.11,
			957.198,
			959.235,
			962.24,
			963.224,
			964.2,
			966.128,
			968.037,
			968.971,
			970.827,
			971.745,
			973.555,
			975.347,
			977.994,
			979.721,
			982.28,
			983.121,
			983.958,
			986.447,
			988.09,
			990.545,
			992.181,
			993.818,
			994.637,
			996.275,
			997.094,
			998.733,
			1000.369,
			1001.185,
			1002.815,
			1004.447,
			1006.874,
			1007.681,
			1008.489,
			1010.887,
			1011.678,
			1012.469,
			1014.836,
			1016.383,
			1017.928,
			1018.7,
			1020.255,
			1021.826,
			1024.179,
			1024.975,
			1025.794,
			1029.064,
			1029.893,
			1032.516,
			1034.264,
			1036.098,
			1037.03,
			1038.894,
			1039.829,
			1041.773,
			1044.692,
			1046.651,
			1048.612,
			1051.495,
			1053.388,
			1056.199,
			1057.124,
			1058.05,
			1060.84,
			1062.715,
			1065.536,
			1067.431,
			1069.328,
			1070.266,
			1072.093,
			1073.007,
			1073.922,
			1076.656,
			1077.565,
			1078.476,
			1081.237,
			1083.103,
			1084.976,
			1085.91,
			1087.778,
			1089.653,
			1090.583,
			1092.42,
			1093.342,
			1095.191,
			1097.031,
			1099.803,
			1101.67,
			1104.49,
			1105.434,
			1106.376,
			1109.216,
			1111.113,
			1113.956,
			1115.862,
			1117.76,
			1118.714,
			1120.629,
			1121.576,
			1123.478,
			1126.351,
			1127.312,
			1130.213,
			1132.172,
			1135.138,
			1137.132,
			1139.136,
			1140.142,
			1142.166,
			1143.184,
			1145.226,
			1147.286,
			1150.385,
			1152.46,
			1155.578,
			1156.627,
			1157.678,
			1160.847,
			1162.975,
			1166.204,
			1168.363,
			1170.533,
			1171.621,
			1173.787,
			1175.98,
			1177.079,
			1179.283,
			1180.389,
			1182.609,
			1184.84,
			1188.228,
			1189.357,
			1190.488,
			1193.896,
			1195.036,
			1196.177,
			1199.608,
			1201.897,
			1205.323,
			1207.6,
			1209.868,
			1213.249,
			1214.369,
			1215.487,
			1218.816,
			1221.019,
			1224.292,
			1226.461,
			1228.617,
			1229.692,
			1231.835,
			1232.916,
			1235.073,
			1237.227,
			1238.321,
			1240.505,
			1242.702,
			1246.047,
			1248.309,
			1251.741,
			1252.915,
			1254.103,
			1257.674,
			1260.093,
			1263.723,
			1266.146,
			1268.562,
			1272.177,
			1273.364,
			1274.549,
			1278.115,
			1279.302,
			1280.492,
			1284.033,
			1286.374,
			1288.679,
			1289.815,
			1292.094,
			1294.355,
			1295.486,
			1297.756,
			1298.898,
			1301.188,
			1303.493,
			1306.979,
			1309.322,
			1312.86,
			1314.048,
			1315.238,
			1318.824,
			1321.227,
			1324.843,
			1327.263,
			1329.694,
			1330.914,
			1333.361,
			1334.591,
			1335.823,
			1339.536,
			1340.779,
			1342.024,
			1345.769,
			1348.276,
			1352.026,
			1354.547,
			1357.07,
			1358.331,
			1360.842,
			1362.092,
			1364.574,
			1367.024,
			1370.63,
			1372.987,
			1376.448,
			1377.584,
			1378.709,
			1382.027,
			1384.192,
			1387.368,
			1389.439,
			1391.472,
			1392.475,
			1394.453,
			1395.428,
			1397.351,
			1399.24,
			1400.17,
			1402.007,
			1403.811,
			1406.456,
			1407.321,
			1408.191,
			1409.878,
			1411.53,
			1412.342,
			1413.945,
			1414.736,
			1416.298,
			1417.839,
			1420.114,
			1421.616,
			1423.863,
			1424.612,
			1425.36,
			1427.605,
			1429.102,
			1431.344,
			1432.838,
			1435.07,
			1436.554,
			1437.296,
			1438.786,
			1440.285,
			1441.034,
			1442.53,
			1444.047,
			1446.347,
			1447.878,
			1450.249,
			1451.043,
			1451.836,
			1454.249,
			1455.89,
			1458.347,
			1459.982,
			1461.615,
			1464.052,
			1464.855,
			1465.657,
			1468.062,
			1468.863,
			1469.662,
			1472.055,
			1473.654,
			1475.271,
			1476.079,
			1477.694,
			1479.326,
			1480.148,
			1481.79,
			1482.61,
			1484.254,
			1485.9,
			1488.363,
			1489.999,
			1492.442,
			1493.254,
			1494.069,
			1496.513,
			1498.135,
			1500.572,
			1502.19,
			1504.614,
			1506.23,
			1507.036,
			1508.647,
			1511.072,
			1512.686,
			1514.296,
			1515.896,
			1517.496,
			1518.296,
			1519.884,
			1521.463,
			1522.252,
			1523.832,
			1524.623,
			1526.204,
			1527.782,
			1530.177,
			1531.789,
			1534.22,
			1535.051,
			1535.883,
			1538.383,
			1540.063,
			1542.597,
			1544.292,
			1545.975,
			1546.819,
			1548.512,
			1549.357,
			1551.035,
			1552.721,
			1553.568,
			1555.271,
			1556.984,
			1559.585,
			1560.479,
			1561.376,
			1564.084,
			1565.011,
			1565.943,
			1568.751,
			1570.664,
			1572.592,
			1575.53,
			1577.521,
			1580.516,
			1581.515,
			1582.514,
			1585.48,
			1587.425,
			1590.307,
			1592.183,
			1594.988,
			1596.837,
			1597.762,
			1599.614,
			1602.406,
			1604.266,
			1606.138,
			1608.944,
			1609.876,
			1610.806,
			1613.592,
			1614.518,
			1615.43,
			1618.165,
			1619.969,
			1621.774,
			1624.479,
			1626.251,
			1628.886,
			1629.753,
			1630.609,
			1633.134,
			1634.777,
			1637.177,
			1638.732,
			1640.257,
			1641.01,
			1642.498,
			1643.967,
			1644.708,
			1646.883,
			1648.326,
			1649.768,
			1651.934,
			1653.375,
			1655.548,
			1656.276,
			1657.004,
			1659.184,
			1660.673,
			1662.929,
			1664.431,
			1666.026,
			1666.838,
			1668.459,
			1669.268,
			1670.086,
			1672.621,
			1673.464,
			1675.145,
			1676.79,
			1678.431,
			1680.072,
			1680.897,
			1682.542,
			1684.184,
			1685.005,
			1686.64,
			1687.455,
			1689.085,
			1690.717,
			1693.163,
			1694.795,
			1697.283,
			1698.115,
			1698.947,
			1701.449,
			1703.12,
			1705.618,
			1707.251,
			1708.888,
			1709.71,
			1711.298,
			1712.087,
			1713.669,
			1715.255,
			1716.038,
			1717.61,
			1719.19,
			1721.567,
			1722.361,
			1723.158,
			1724.759,
			1726.363,
			1727.166,
			1728.782,
			1729.594,
			1731.224,
			1732.862,
			1735.344,
			1737.002,
			1739.511,
			1740.354,
			1741.196,
			1743.734,
			1745.442,
			1748.008,
			1749.733,
			1751.46,
			1752.324,
			1754.059,
			1754.931,
			1756.681,
			1758.439,
			1759.323,
			1761.102,
			1762.902,
			1765.625,
			1766.539,
			1767.456,
			1770.222,
			1771.151,
			1772.09,
			1774.917,
			1776.823,
			1779.719,
			1781.649,
			1783.635,
			1784.624,
			1786.613,
			1788.617,
			1791.644,
			1793.673,
			1796.719,
			1798.772,
			1801.872,
			1803.951,
			1806.04,
			1807.088,
			1809.188,
			1810.236,
			1812.335,
			1814.441,
			1817.621,
			1819.754,
			1822.979,
			1824.061,
			1825.146,
			1828.421,
			1830.618,
			1833.926,
			1836.142,
			1838.362,
			1839.475,
			1841.709,
			1842.829,
			1843.951,
			1847.333,
			1848.466,
			1849.602,
			1853.023,
			1855.315,
			1857.617,
			1858.77,
			1861.083,
			1863.406,
			1864.571,
			1866.907,
			1868.079,
			1870.428,
			1872.788,
			1876.345,
			1878.728,
			1882.32,
			1883.521,
			1884.725,
			1888.351,
			1890.78,
			1894.44,
			1896.891,
			1899.351,
			1900.584,
			1903.057,
			1904.297,
			1906.783,
			1910.529,
			1913.037,
			1915.553,
			1918.078,
			1920.611,
			1921.881,
			1925.703,
			1926.982,
			1928.262,
			1930.828,
			1932.115,
			1934.693,
			1937.279,
			1941.174,
			1943.78,
			1947.688,
			1948.994,
			1950.301,
			1954.233,
			1956.863,
			1960.823,
			1963.473,
			1966.128,
			1967.459,
			1970.126,
			1971.462,
			1974.139,
			1976.823,
			1978.168,
			1980.863,
			1983.565,
			1987.632,
			1990.352,
			1994.444,
			1995.812,
			1997.181,
			2001.3,
			2004.054,
			2006.816,
			2010.971,
			2013.749,
			2017.93,
			2019.326,
			2020.725,
			2024.93,
			2026.335,
			2027.742,
			2031.972,
			2034.801,
			2039.056,
			2041.902,
			2044.755,
			2046.183,
			2049.046,
			2050.479,
			2053.351,
			2056.23,
			2060.561,
			2063.456,
			2067.81,
			2069.265,
			2070.722,
			2075.102,
			2078.03,
			2082.44,
			2085.391,
			2088.349,
			2089.83,
			2092.796,
			2094.281,
			2097.252,
			2100.229,
			2101.717,
			2103.206,
			2107.679,
			2110.667,
			2113.661,
			2115.161,
			2118.166,
			2121.177,
			2122.685,
			2125.707,
			2127.22,
			2130.252,
			2133.29,
			2137.857,
			2140.909,
			2145.498,
			2147.03,
			2148.563,
			2153.174,
			2156.255,
			2160.883,
			2163.967,
			2167.041,
			2168.57,
			2171.608,
			2173.116,
			2176.105,
			2179.057,
			2180.519,
			2183.414,
			2186.27,
			2189.088,
			2191.868,
			2193.244,
			2195.966,
			2198.649,
			2199.977,
			2202.604,
			2203.904,
			2206.474,
			2209.005,
			2212.732,
			2215.169,
			2218.756,
			2219.934,
			2221.105,
			2224.563,
			2226.822,
			2230.135,
			2232.298,
			2234.425,
			2235.474,
			2237.548,
			2238.571,
			2240.59,
			2243.553,
			2245.483,
			2247.378,
			2250.153,
			2251.963,
			2254.64,
			2255.515,
			2256.384,
			2258.962,
			2260.661,
			2262.346,
			2264.855,
			2266.522,
			2268.989,
			2269.81,
			2270.631,
			2273.066,
			2274.679,
			2277.11,
			2278.745,
			2280.38,
			2281.198,
			2282.888,
			2283.736,
			2285.432,
			2287.161,
			2288.052,
			2289.833,
			2291.616,
			2294.335,
			2296.146,
			2298.9,
			2299.82,
			2300.739,
			2303.555,
			2305.449,
			2308.294,
			2310.194,
			2312.087,
			2314.871,
			2315.8,
			2317.657,
			2319.511,
			2320.438,
			2321.365,
			2324.178,
			2326.056,
			2328.863,
			2330.73,
			2332.591,
			2333.516,
			2335.365,
			2336.289,
			2338.158,
			2340.032,
			2342.857,
			2344.757,
			2347.607,
			2348.559,
			2349.51,
			2352.372,
			2354.306,
			2357.208,
			2359.149,
			2361.091,
			2362.061,
			2363.961,
			2364.912,
			2366.817,
			2369.593,
			2371.447,
			2373.271,
			2375.989,
			2376.906,
			2377.809,
			2379.621,
			2381.445,
			2382.359,
			2385.096,
			2386.936,
			2388.781,
			2391.57,
			2393.451,
			2396.286,
			2397.227,
			2398.166,
			2400.946,
			2402.758,
			2405.399,
			2407.114,
			2408.79,
			2409.616,
			2411.268,
			2412.081,
			2413.7,
			2415.313,
			2416.118,
			2417.724,
			2419.329,
			2421.727,
			2422.527,
			2423.34,
			2425.776,
			2426.588,
			2427.401,
			2429.929,
			2431.617,
			2434.205,
			2435.95,
			2437.694,
			2440.328,
			2441.204,
			2442.08,
			2445.537,
			2446.396,
			2448.969,
			2450.679,
			2452.385,
			2453.244,
			2454.99,
			2455.861,
			2457.604,
			2459.355,
			2460.23,
			2461.979,
			2463.732,
			2466.37,
			2467.249,
			2468.13,
			2470.875,
			2471.79,
			2472.705,
			2475.499,
			2477.367,
			2480.217,
			2482.13,
			2484.047,
			2486.922,
			2487.879,
			2488.819,
			2491.594,
			2492.512,
			2493.42,
			2495.958,
			2497.63,
			2499.967,
			2501.45,
			2502.898,
			2503.605,
			2504.973,
			2505.644,
			2506.962,
			2508.249,
			2510.087,
			2511.257,
			2512.954,
			2513.506,
			2514.038,
			2515.584,
			2516.589,
			2518.069,
			2519.081,
			2520.656,
			2521.699,
			2522.22,
			2523.261,
			2525.023,
			2526.252,
			2527.482,
			2529.444,
			2530.18,
			2530.915,
			2532.383,
			2533.869,
			2534.826,
			2536.745,
			2537.706,
			2539.557,
			2541.306,
			2543.907,
			2545.092,
			2546.875,
			2547.472,
			2548.07,
			2549.747,
			2550.796,
			2552.381,
			2553.45,
			2554.529,
			2555.073,
			2556.169,
			2556.723,
			2557.841,
			2558.975,
			2559.56,
			2560.74,
			2561.934,
			2563.756,
			2565.017,
			2566.943,
			2567.594,
			2568.248,
			2570.268,
			2571.644,
			2573.737,
			2575.161,
			2576.603,
			2578.797,
			2579.541,
			2580.291,
			2582.564,
			2583.33,
			2584.1,
			2586.424,
			2587.979,
			2589.562,
			2590.356,
			2591.957,
			2592.762,
			2594.385,
			2596.844,
			2598.501,
			2600.167,
			2602.68,
			2604.359,
			2606.854,
			2607.691,
			2608.528,
			2611.037,
			2612.708,
			2615.212,
			2616.881,
			2619.395,
			2621.071,
			2621.909,
			2622.746,
			2625.259,
			2626.1,
			2626.939,
			2629.452,
			2631.13,
			2632.803,
			2633.638,
			2635.305,
			2636.963,
			2637.791,
			2640.262,
			2641.894,
			2643.519,
			2645.951,
			2647.565,
			2649.983,
			2650.814,
			2651.644,
			2654.12,
			2655.823,
			2658.419,
			2660.155,
			2662.837,
			2664.614,
			2665.533,
			2667.39,
			2669.241,
			2670.182,
			2672.141,
			2674.094,
			2676.996,
			2678.92,
			2680.737,
			2682.427,
			2683.272,
			2684.961,
			2685.742,
			2687.29,
			2688.838,
			2691.153,
			2692.689,
			2694.998,
			2695.771,
			2696.544,
			2698.869,
			2699.646,
			2700.426,
			2702.771,
			2704.341,
			2706.714,
			2708.305,
			2709.104,
			2710.709,
			2712.323,
			2713.133,
			2714.76,
			2716.395,
			2718.861,
			2719.688,
			2720.519,
			2722.192,
			2723.877,
			2724.724,
			2726.428,
			2727.285,
			2729.008,
			2730.743,
			2733.365,
			2735.127,
			2737.782,
			2738.672,
			2739.565,
			2742.251,
			2744.05,
			2746.775,
			2748.611,
			2750.458,
			2751.391,
			2753.274,
			2755.168,
			2756.121,
			2759.016,
			2760.961,
			2762.921,
			2765.867,
			2767.853,
			2770.853,
			2771.858,
			2772.866,
			2775.908,
			2777.96,
			2781.06,
			2783.139,
			2786.265,
			2788.37,
			2789.427,
			2790.486,
			2793.681,
			2794.751,
			2795.824,
			2799.042,
			2801.196,
			2803.353,
			2804.433,
			2806.6,
			2808.78,
			2809.873,
			2812.068,
			2813.172,
			2815.388,
			2817.616,
			2820.989,
			2823.255,
			2826.679,
			2827.826,
			2828.977,
			2832.443,
			2834.766,
			2838.267,
			2840.612,
			2842.967,
			2844.146,
			2846.513,
			2847.7,
			2850.081,
			2852.471,
			2853.669,
			2856.073,
			2858.486,
			2862.144,
			2864.58,
			2867.024,
			2869.478,
			2870.708,
			2873.155,
			2874.39,
			2876.867,
			2879.372,
			2883.111,
			2885.611,
			2889.372,
			2890.627,
			2891.885,
			2895.653,
			2898.17,
			2901.943,
			2904.463,
			2908.244,
			2910.783,
			2912.055,
			2914.616,
			2917.195,
			2918.485,
			2921.084,
			2923.692,
			2927.629,
			2930.267,
			2934.243,
			2935.579,
			2936.916,
			2940.952,
			2943.664,
			2946.417,
			2950.584,
			2953.39,
			2957.596,
			2959,
			2960.392,
			2964.554,
			2965.928,
			2967.303,
			2971.435,
			2974.192,
			2976.969,
			2978.359,
			2981.132,
			2983.945,
			2985.382,
			2989.649,
			2992.49,
			2995.331,
			2999.573,
			3002.412,
			3006.68,
			3008.107,
			3009.533,
			3013.819,
			3016.674,
			3020.955,
			3023.826,
			3026.696,
			3028.134,
			3031.009,
			3032.457,
			3033.905,
			3038.259,
			3039.714,
			3041.17,
			3045.549,
			3048.462,
			3051.378,
			3052.832,
			3055.745,
			3058.653,
			3060.109,
			3063.027,
			3064.488,
			3067.409,
			3070.303,
			3074.657,
			3077.588,
			3081.98,
			3083.466,
			3084.938,
			3090.851,
			3092.356,
			3096.849,
			3099.821,
			3102.791,
			3104.271,
			3107.234,
			3108.712,
			3111.673,
			3114.634,
			3116.116,
			3119.081,
			3122.048,
			3126.503,
			3127.984,
			3129.461,
			3132.415,
			3135.362,
			3136.837,
			3139.787,
			3141.263,
			3144.221,
			3147.161,
			3151.626,
			3154.61,
			3159.096,
			3160.591,
			3162.089,
			3166.587,
			3169.592,
			3174.11,
			3177.131,
			3180.156,
			3181.67,
			3184.701,
			3186.218,
			3189.255,
			3193.82,
			3196.868,
			3199.921,
			3204.511,
			3207.58,
			3212.196,
			3213.738,
			3215.282,
			3219.926,
			3223.029,
			3227.691,
			3230.803,
			3233.914,
			3238.588,
			3240.148,
			3241.711,
			3246.407,
			3249.548,
			3254.272,
			3257.43,
			3260.594,
			3262.179,
			3265.352,
			3266.941,
			3270.124,
			3274.906,
			3278.095,
			3281.288,
			3286.08,
			3289.277,
			3294.08,
			3295.682,
			3297.286,
			3302.105,
			3305.325,
			3310.164,
			3313.396,
			3316.634,
			3318.254,
			3321.499,
			3323.122,
			3326.374,
			3329.629,
			3331.258,
			3332.888,
			3337.785,
			3341.054,
			3344.327,
			3345.963,
			3349.239,
			3352.513,
			3354.152,
			3357.43,
			3359.098,
			3362.381,
			3365.665,
			3370.592,
			3373.854,
			3378.827,
			3380.477,
			3382.128,
			3387.087,
			3390.4,
			3395.391,
			3398.732,
			3403.759,
			3407.12,
			3408.802,
			3412.172,
			3415.547,
			3417.237,
			3420.62,
			3424.013,
			3427.414,
			3430.826,
			3432.537,
			3435.963,
			3439.389,
			3441.103,
			3446.241,
			3449.659,
			3453.077,
			3458.204,
			3461.625,
			3466.768,
			3468.485,
			3470.203,
			3475.36,
			3478.804,
			3483.981,
			3487.445,
			3490.916,
			3492.652,
			3496.127,
			3497.865,
			3501.345,
			3504.831,
			3506.576,
			3510.068,
			3513.564,
			3518.824,
			3520.584,
			3522.345,
			3527.627,
			3529.388,
			3531.146,
			3536.422,
			3539.938,
			3543.455,
			3548.74,
			3552.273,
			3557.585,
			3559.359,
			3561.141,
			3566.502,
			3568.292,
			3570.083,
			3575.479,
			3579.083,
			3582.696,
			3584.506,
			3588.125,
			3591.74,
			3593.54,
			3597.139,
			3598.937,
			3602.535,
			3606.135,
			3611.531,
			3615.126,
			3620.508,
			3622.301,
			3624.089,
			3629.457,
			3633.041,
			3638.437,
			3642.045,
			3645.665,
			3647.477,
			3651.105,
			3652.92,
			3656.547,
			3660.179,
			3661.998,
			3663.817,
			3669.266,
			3672.893,
			3676.515,
			3678.325,
			3681.951,
			3685.581,
			3687.398,
			3692.856,
			3696.501,
			3700.148,
			3705.616,
			3709.262,
			3714.725,
			3716.544,
			3718.362,
			3723.809,
			3727.433,
			3732.861,
			3736.478,
			3741.901,
			3745.518,
			3747.328,
			3750.951,
			3754.581,
			3756.398,
			3760.038,
			3763.682,
			3769.151,
			3770.973,
			3772.796,
			3776.442,
			3780.086,
			3781.904,
			3785.542,
			3787.36,
			3790.97,
			3794.615,
			3800.1,
			3803.77,
			3809.29,
			3811.133,
			3812.979,
			3818.519,
			3822.217,
			3827.769,
			3831.474,
			3835.179,
			3837.032,
			3840.732,
			3842.582,
			3846.278,
			3851.821,
			3855.518,
			3859.219,
			3864.783,
			3866.644,
			3868.505,
			3875.967,
			3877.836,
			3881.576,
			3883.447,
			3887.192,
			3890.939,
			3896.563,
			3900.315,
			3905.953,
			3907.835,
			3909.718,
			3915.371,
			3917.257,
			3919.143,
			3924.801,
			3928.565,
			3932.329,
			3934.211,
			3937.976,
			3939.858,
			3943.625,
			3947.393,
			3949.279,
			3953.053,
			3956.83,
			3962.501,
			3964.394,
			3966.285,
			3971.966,
			3973.861,
			3975.757,
			3981.447,
			3985.243,
			3990.941,
			3994.742,
			3998.545,
			4004.254,
			4006.158,
			4008.062,
			4013.779,
			4015.686,
			4017.593,
			4023.316,
			4027.134,
			4030.955,
			4032.866,
			4036.689,
			4040.514,
			4042.428,
			4048.173,
			4052.006,
			4055.842,
			4061.603,
			4065.449,
			4069.368
		],
		[
			0,
			0.028,
			0.093,
			0.153,
			0.217,
			0.278,
			0.325,
			0.341,
			0.401,
			0.431,
			0.465,
			0.527,
			0.54,
			0.586,
			0.651,
			0.712,
			0.759,
			0.774,
			0.837,
			0.868,
			0.899,
			0.977,
			1.023,
			1.085,
			1.147,
			1.194,
			1.271,
			1.302,
			1.331,
			1.409,
			1.455,
			1.517,
			1.58,
			1.642,
			1.702,
			1.734,
			1.765,
			1.828,
			1.842,
			1.891,
			1.954,
			2.014,
			2.075,
			2.138,
			2.17,
			2.2,
			2.263,
			2.325,
			2.391,
			2.447,
			2.493,
			2.51,
			2.573,
			2.602,
			2.633,
			2.697,
			2.712,
			2.759,
			2.82,
			2.883,
			2.945,
			3.007,
			3.036,
			3.068,
			3.13,
			3.146,
			3.193,
			3.253,
			3.317,
			3.363,
			3.44,
			3.47,
			3.502,
			3.565,
			3.626,
			3.692,
			3.75,
			3.798,
			3.812,
			3.874,
			3.903,
			3.937,
			3.998,
			4.014,
			4.062,
			4.127,
			4.185,
			4.246,
			4.309,
			4.341,
			4.371,
			4.431,
			4.449,
			4.496,
			4.555,
			4.619,
			4.665,
			4.743,
			4.771,
			4.803,
			4.866,
			4.929,
			4.99,
			5.053,
			5.098,
			5.114,
			5.175,
			5.208,
			5.238,
			5.301,
			5.317,
			5.363,
			5.426,
			5.487,
			5.532,
			5.547,
			5.612,
			5.641,
			5.67,
			5.736,
			5.796,
			5.858,
			5.92,
			5.968,
			6.045,
			6.077,
			6.108,
			6.168,
			6.231,
			6.294,
			6.354,
			6.402,
			6.418,
			6.48,
			6.509,
			6.542,
			6.603,
			6.618,
			6.664,
			6.727,
			6.788,
			6.835,
			6.85,
			6.914,
			6.943,
			6.976,
			7.038,
			7.099,
			7.16,
			7.224,
			7.271,
			7.346,
			7.379,
			7.41,
			7.471,
			7.486,
			7.532,
			7.596,
			7.657,
			7.72,
			7.782,
			7.817,
			7.842,
			7.903,
			7.92,
			7.968,
			8.028,
			8.092,
			8.137,
			8.215,
			8.245,
			8.276,
			8.337,
			8.4,
			8.463,
			8.526,
			8.57,
			8.586,
			8.648,
			8.679,
			8.712,
			8.771,
			8.787,
			8.834,
			8.898,
			8.959,
			9.004,
			9.02,
			9.083,
			9.115,
			9.145,
			9.208,
			9.223,
			9.27,
			9.333,
			9.393,
			9.441,
			9.518,
			9.549,
			9.578,
			9.641,
			9.703,
			9.766,
			9.828,
			9.882,
			9.887,
			9.953,
			9.982,
			10.015,
			10.075,
			10.091,
			10.137,
			10.201,
			10.263,
			10.308,
			10.324,
			10.386,
			10.415,
			10.449,
			10.51,
			10.57,
			10.634,
			10.696,
			10.758,
			10.818,
			10.852,
			10.883,
			10.959,
			11.004,
			11.069,
			11.128,
			11.177,
			11.192,
			11.254,
			11.285,
			11.316,
			11.378,
			11.393,
			11.437,
			11.504,
			11.563,
			11.61,
			11.625,
			11.686,
			11.72,
			11.751,
			11.827,
			11.875,
			11.94,
			11.999,
			12.046,
			12.12,
			12.153,
			12.185,
			12.247,
			12.262,
			12.308,
			12.369,
			12.432,
			12.479,
			12.494,
			12.553,
			12.586,
			12.618,
			12.68,
			12.697,
			12.743,
			12.802,
			12.866,
			12.912,
			12.991,
			13.022,
			13.052,
			13.115,
			13.177,
			13.238,
			13.3,
			13.348,
			13.362,
			13.425,
			13.454,
			13.487,
			13.547,
			13.564,
			13.61,
			13.672,
			13.735,
			13.781,
			13.796,
			13.858,
			13.89,
			13.921,
			14.001,
			14.043,
			14.108,
			14.169,
			14.215,
			14.292,
			14.321,
			14.354,
			14.432,
			14.479,
			14.542,
			14.601,
			14.649,
			14.663,
			14.728,
			14.758,
			14.787,
			14.866,
			14.912,
			14.974,
			15.037,
			15.084,
			15.098,
			15.16,
			15.193,
			15.223,
			15.284,
			15.301,
			15.346,
			15.409,
			15.47,
			15.518,
			15.594,
			15.625,
			15.657,
			15.733,
			15.78,
			15.844,
			15.903,
			15.968,
			16.028,
			16.064,
			16.091,
			16.169,
			16.215,
			16.275,
			16.339,
			16.401,
			16.462,
			16.495,
			16.525,
			16.602,
			16.649,
			16.711,
			16.773,
			16.82,
			16.835,
			16.896,
			16.928,
			16.96,
			17.021,
			17.035,
			17.082,
			17.146,
			17.207,
			17.254,
			17.268,
			17.332,
			17.362,
			17.393,
			17.453,
			17.47,
			17.516,
			17.578,
			17.642,
			17.702,
			17.766,
			17.796,
			17.826,
			17.903,
			17.951,
			18.014,
			18.075,
			18.127,
			18.136,
			18.2,
			18.231,
			18.26,
			18.323,
			18.337,
			18.386,
			18.447,
			18.51,
			18.556,
			18.57,
			18.632,
			18.663,
			18.696,
			18.758,
			18.774,
			18.818,
			18.882,
			18.942,
			19.004,
			19.066,
			19.097,
			19.129,
			19.19,
			19.254,
			19.319,
			19.378,
			19.425,
			19.439,
			19.502,
			19.533,
			19.562,
			19.626,
			19.642,
			19.688,
			19.749,
			19.81,
			19.858,
			19.875,
			19.935,
			19.966,
			19.998,
			20.06,
			20.12,
			20.182,
			20.187,
			20.246,
			20.308,
			20.368,
			20.399,
			20.431,
			20.51,
			20.557,
			20.619,
			20.68,
			20.725,
			20.742,
			20.805,
			20.835,
			20.866,
			20.943,
			20.991,
			21.053,
			21.114,
			21.176,
			21.238,
			21.269,
			21.299,
			21.362,
			21.424,
			21.486,
			21.546,
			21.593,
			21.611,
			21.672,
			21.702,
			21.733,
			21.796,
			21.812,
			21.858,
			21.92,
			21.982,
			22.028,
			22.043,
			22.104,
			22.135,
			22.169,
			22.23,
			22.25,
			22.293,
			22.354,
			22.416,
			22.477,
			22.54,
			22.572,
			22.602,
			22.664,
			22.726,
			22.789,
			22.85,
			22.897,
			22.91,
			22.974,
			23.005,
			23.036,
			23.112,
			23.159,
			23.221,
			23.283,
			23.347,
			23.408,
			23.439,
			23.469,
			23.532,
			23.547,
			23.593,
			23.657,
			23.718,
			23.78,
			23.842,
			23.871,
			23.902,
			23.965,
			24.027,
			24.091,
			24.153,
			24.198,
			24.213,
			24.276,
			24.312,
			24.338,
			24.399,
			24.415,
			24.463,
			24.524,
			24.587,
			24.632,
			24.649,
			24.711,
			24.74,
			24.771,
			24.835,
			24.897,
			24.96,
			25.02,
			25.065,
			25.145,
			25.175,
			25.207,
			25.268,
			25.331,
			25.392,
			25.452,
			25.502,
			25.515,
			25.577,
			25.608,
			25.64,
			25.702,
			25.718,
			25.765,
			25.826,
			25.889,
			25.949,
			26.013,
			26.044,
			26.075,
			26.151,
			26.197,
			26.262,
			26.323,
			26.385,
			26.446,
			26.479,
			26.508,
			26.569,
			26.587,
			26.632,
			26.694,
			26.757,
			26.804,
			26.819,
			26.88,
			26.912,
			26.943,
			27.006,
			27.02,
			27.065,
			27.129,
			27.191,
			27.235,
			27.314,
			27.345,
			27.375,
			27.439,
			27.501,
			27.562,
			27.625,
			27.671,
			27.688,
			27.748,
			27.779,
			27.809,
			27.871,
			27.888,
			27.934,
			27.996,
			28.059,
			28.119,
			28.182,
			28.213,
			28.245,
			28.307,
			28.321,
			28.368,
			28.434,
			28.493,
			28.537,
			28.617,
			28.647,
			28.679,
			28.741,
			28.802,
			28.866,
			28.928,
			28.971,
			28.987,
			29.051,
			29.081,
			29.112,
			29.175,
			29.192,
			29.237,
			29.299,
			29.362,
			29.408,
			29.424,
			29.485,
			29.517,
			29.548,
			29.608,
			29.623,
			29.672,
			29.732,
			29.794,
			29.841,
			29.92,
			29.949,
			29.982,
			30.058,
			30.105,
			30.168,
			30.229,
			30.291,
			30.352,
			30.384,
			30.415,
			30.476,
			30.496,
			30.54,
			30.601,
			30.662,
			30.71,
			30.786,
			30.82,
			30.849,
			30.912,
			30.974,
			31.035,
			31.097,
			31.144,
			31.22,
			31.252,
			31.284,
			31.346,
			31.362,
			31.408,
			31.469,
			31.532,
			31.578,
			31.593,
			31.656,
			31.685,
			31.717,
			31.779,
			31.796,
			31.841,
			31.904,
			31.966,
			32.012,
			32.088,
			32.12,
			32.152,
			32.212,
			32.276,
			32.338,
			32.4,
			32.462,
			32.524,
			32.557,
			32.586,
			32.663,
			32.71,
			32.772,
			32.833,
			32.881,
			32.897,
			32.958,
			32.989,
			33.019,
			33.083,
			33.097,
			33.142,
			33.204,
			33.268,
			33.313,
			33.392,
			33.42,
			33.454,
			33.515,
			33.579,
			33.638,
			33.703,
			33.748,
			33.765,
			33.826,
			33.857,
			33.887,
			33.949,
			33.964,
			34.01,
			34.075,
			34.136,
			34.181,
			34.199,
			34.26,
			34.29,
			34.32,
			34.399,
			34.446,
			34.507,
			34.571,
			34.632,
			34.694,
			34.725,
			34.753,
			34.817,
			34.88,
			34.942,
			35.004,
			35.066,
			35.128,
			35.158,
			35.191,
			35.266,
			35.313,
			35.376,
			35.437,
			35.485,
			35.499,
			35.563,
			35.593,
			35.624,
			35.701,
			35.749,
			35.809,
			35.871,
			35.92,
			35.997,
			36.026,
			36.059,
			36.119,
			36.183,
			36.243,
			36.306,
			36.352,
			36.368,
			36.431,
			36.46,
			36.493,
			36.569,
			36.616,
			36.682,
			36.74,
			36.786,
			36.864,
			36.894,
			36.926,
			37.005,
			37.049,
			37.112,
			37.175,
			37.222,
			37.237,
			37.298,
			37.328,
			37.359,
			37.42,
			37.437,
			37.485,
			37.547,
			37.608,
			37.654,
			37.671,
			37.732,
			37.764,
			37.794,
			37.857,
			37.869,
			37.918,
			37.982,
			38.044,
			38.088,
			38.167,
			38.199,
			38.229,
			38.291,
			38.353,
			38.415,
			38.477,
			38.521,
			38.539,
			38.601,
			38.632,
			38.662,
			38.725,
			38.744,
			38.786,
			38.847,
			38.912,
			38.957,
			38.971,
			39.034,
			39.065,
			39.096,
			39.159,
			39.175,
			39.22,
			39.283,
			39.345,
			39.391,
			39.469,
			39.5,
			39.531,
			39.592,
			39.655,
			39.718,
			39.778,
			39.826,
			39.842,
			39.901,
			39.933,
			39.965,
			40.027,
			40.043,
			40.087,
			40.152,
			40.212,
			40.259,
			40.275,
			40.337,
			40.368,
			40.399,
			40.462,
			40.52,
			40.587,
			40.647,
			40.693,
			40.71,
			40.771,
			40.832,
			40.895,
			40.957,
			41.019,
			41.082,
			41.143,
			41.206,
			41.237,
			41.268,
			41.328,
			41.346,
			41.391,
			41.453,
			41.516,
			41.562,
			41.637,
			41.671,
			41.702,
			41.764,
			41.826,
			41.888,
			41.948,
			41.994,
			42.012,
			42.071,
			42.103,
			42.134,
			42.198,
			42.212,
			42.259,
			42.321,
			42.383,
			42.43,
			42.445,
			42.508,
			42.539,
			42.57,
			42.631,
			42.648,
			42.692,
			42.754,
			42.817,
			42.879,
			42.941,
			42.971,
			43.004,
			43.08,
			43.127,
			43.191,
			43.253,
			43.299,
			43.312,
			43.376,
			43.404,
			43.437,
			43.515,
			43.56,
			43.625,
			43.684,
			43.733,
			43.746,
			43.811,
			43.841,
			43.873,
			43.934,
			43.949,
			43.996,
			44.058,
			44.12,
			44.165,
			44.243,
			44.274,
			44.304,
			44.382,
			44.429,
			44.491,
			44.553,
			44.599,
			44.615,
			44.677,
			44.708,
			44.74,
			44.802,
			44.818,
			44.865,
			44.929,
			44.988,
			45.035,
			45.112,
			45.143,
			45.174,
			45.236,
			45.299,
			45.359,
			45.421,
			45.484,
			45.545,
			45.576,
			45.608,
			45.67,
			45.685,
			45.732,
			45.793,
			45.857,
			45.918,
			45.979,
			46.012,
			46.043,
			46.104,
			46.12,
			46.165,
			46.227,
			46.288,
			46.337,
			46.415,
			46.446,
			46.475,
			46.553,
			46.601,
			46.663,
			46.724,
			46.77,
			46.787,
			46.847,
			46.88,
			46.91,
			46.97,
			46.991,
			47.034,
			47.096,
			47.158,
			47.204,
			47.221,
			47.283,
			47.312,
			47.345,
			47.405,
			47.422,
			47.469,
			47.532,
			47.593,
			47.637,
			47.715,
			47.748,
			47.779,
			47.854,
			47.902,
			47.964,
			48.026,
			48.07,
			48.086,
			48.151,
			48.182,
			48.212,
			48.274,
			48.291,
			48.337,
			48.399,
			48.459,
			48.508,
			48.523,
			48.584,
			48.615,
			48.645,
			48.708,
			48.724,
			48.771,
			48.832,
			48.895,
			48.957,
			49.017,
			49.055,
			49.081,
			49.143,
			49.206,
			49.267,
			49.33,
			49.375,
			49.391,
			49.452,
			49.482,
			49.515,
			49.593,
			49.638,
			49.702,
			49.762,
			49.824,
			49.886,
			49.918,
			49.948,
			50.011,
			50.071,
			50.135,
			50.197,
			50.258,
			50.321,
			50.353,
			50.382,
			50.446,
			50.504,
			50.569,
			50.629,
			50.678,
			50.692,
			50.756,
			50.785,
			50.816,
			50.879,
			50.893,
			50.942,
			51.004,
			51.063,
			51.114,
			51.189,
			51.22,
			51.251,
			51.312,
			51.376,
			51.437,
			51.498,
			51.546,
			51.623,
			51.652,
			51.685,
			51.746,
			51.762,
			51.81,
			51.869,
			51.932,
			51.996,
			52.057,
			52.09,
			52.12,
			52.182,
			52.195,
			52.243,
			52.304,
			52.366,
			52.414,
			52.491,
			52.523,
			52.554,
			52.614,
			52.678,
			52.738,
			52.802,
			52.849,
			52.863,
			52.925,
			52.954,
			52.986,
			53.066,
			53.112,
			53.177,
			53.235,
			53.281,
			53.298,
			53.36,
			53.39,
			53.42,
			53.499,
			53.546,
			53.607,
			53.668,
			53.715,
			53.792,
			53.824,
			53.856,
			53.918,
			53.98,
			54.041,
			54.102,
			54.151,
			54.165,
			54.226,
			54.26,
			54.289,
			54.351,
			54.366,
			54.412,
			54.475,
			54.539,
			54.583,
			54.6,
			54.662,
			54.693,
			54.721,
			54.785,
			54.847,
			54.91,
			54.972,
			55.019,
			55.096,
			55.128,
			55.157,
			55.24,
			55.283,
			55.343,
			55.405,
			55.452,
			55.467,
			55.528,
			55.56,
			55.592,
			55.652,
			55.668,
			55.717,
			55.777,
			55.839,
			55.885,
			55.902,
			55.962,
			55.993,
			56.026,
			56.086,
			56.15,
			56.213,
			56.274,
			56.335,
			56.398,
			56.429,
			56.459,
			56.521,
			56.535,
			56.584,
			56.645,
			56.707,
			56.769,
			56.832,
			56.861,
			56.893,
			56.957,
			56.971,
			57.018,
			57.083,
			57.142,
			57.188,
			57.264,
			57.301,
			57.327,
			57.406,
			57.452,
			57.515,
			57.577,
			57.636,
			57.701,
			57.732,
			57.763,
			57.84,
			57.886,
			57.949,
			58.01,
			58.057,
			58.071,
			58.134,
			58.166,
			58.196,
			58.258,
			58.275,
			58.32,
			58.383,
			58.443,
			58.495,
			58.569,
			58.599,
			58.631,
			58.692,
			58.755,
			58.815,
			58.879,
			58.926,
			58.941,
			59.001,
			59.034,
			59.063,
			59.127,
			59.142,
			59.187,
			59.249,
			59.313,
			59.362,
			59.437,
			59.466,
			59.498,
			59.56,
			59.623,
			59.686,
			59.747,
			59.809,
			59.871,
			59.901,
			59.932,
			59.993,
			60.01,
			60.056,
			60.119,
			60.18,
			60.225,
			60.243,
			60.304,
			60.334,
			60.365,
			60.443,
			60.493,
			60.554,
			60.615,
			60.678,
			60.738,
			60.769,
			60.8,
			60.862,
			60.926,
			60.988,
			61.047,
			61.11,
			61.171,
			61.202,
			61.235,
			61.296,
			61.313,
			61.358,
			61.425,
			61.482,
			61.529,
			61.543,
			61.605,
			61.638,
			61.668,
			61.747,
			61.793,
			61.854,
			61.917,
			61.963,
			62.041,
			62.07,
			62.102,
			62.165,
			62.226,
			62.29,
			62.352,
			62.414,
			62.476,
			62.505,
			62.537,
			62.599,
			62.613,
			62.662,
			62.721,
			62.786,
			62.846,
			62.908,
			62.941,
			62.97,
			63.034,
			63.048,
			63.094,
			63.158,
			63.219,
			63.282,
			63.344,
			63.375,
			63.407,
			63.466,
			63.486,
			63.53,
			63.592,
			63.652,
			63.715,
			63.778,
			63.808,
			63.837,
			63.901,
			63.916,
			63.962,
			64.025,
			64.086,
			64.132,
			64.15,
			64.21,
			64.242,
			64.271,
			64.334,
			64.352,
			64.397,
			64.46,
			64.519,
			64.568,
			64.644,
			64.677,
			64.707,
			64.77,
			64.83,
			64.893,
			64.955,
			65.002,
			65.017,
			65.08,
			65.111,
			65.14,
			65.218,
			65.264,
			65.327,
			65.39,
			65.437,
			65.513,
			65.549,
			65.575,
			65.654,
			65.699,
			65.762,
			65.824,
			65.885,
			65.948,
			65.978,
			66.009,
			66.071,
			66.085,
			66.132,
			66.197,
			66.259,
			66.302,
			66.32,
			66.381,
			66.413,
			66.443,
			66.504,
			66.521,
			66.567,
			66.629,
			66.692,
			66.737,
			66.816,
			66.846,
			66.878,
			66.937,
			67.002,
			67.064,
			67.126,
			67.171,
			67.186,
			67.25,
			67.28,
			67.313,
			67.374,
			67.39,
			67.436,
			67.498,
			67.56,
			67.621,
			67.683,
			67.713,
			67.746,
			67.808,
			67.824,
			67.868,
			67.931,
			67.993,
			68.04,
			68.118,
			68.147,
			68.18,
			68.258,
			68.303,
			68.367,
			68.429,
			68.49,
			68.551,
			68.581,
			68.615,
			68.677,
			68.691,
			68.736,
			68.799,
			68.861,
			68.924,
			68.985,
			69.018,
			69.048,
			69.126,
			69.173,
			69.235,
			69.296,
			69.342,
			69.421,
			69.451,
			69.482,
			69.543,
			69.56,
			69.607,
			69.673,
			69.731,
			69.776,
			69.793,
			69.854,
			69.886,
			69.917,
			69.993,
			70.04,
			70.104,
			70.165,
			70.21,
			70.286,
			70.32,
			70.351,
			70.412,
			70.475,
			70.538,
			70.599,
			70.643,
			70.66,
			70.72,
			70.752,
			70.783,
			70.846,
			70.862,
			70.908,
			70.971,
			71.032,
			71.077,
			71.093,
			71.157,
			71.187,
			71.218,
			71.281,
			71.296,
			71.343,
			71.406,
			71.465,
			71.514,
			71.59,
			71.62,
			71.653,
			71.735,
			71.777,
			71.839,
			71.902,
			71.948,
			71.961,
			72.024,
			72.057,
			72.087,
			72.147,
			72.165,
			72.211,
			72.273,
			72.335,
			72.381,
			72.396,
			72.458,
			72.491,
			72.521,
			72.583,
			72.599,
			72.646,
			72.708,
			72.768,
			72.815,
			72.892,
			72.924,
			72.954,
			73.017,
			73.08,
			73.14,
			73.202,
			73.249,
			73.266,
			73.326,
			73.357,
			73.386,
			73.465,
			73.513,
			73.574,
			73.636,
			73.7,
			73.76,
			73.794,
			73.82,
			73.885,
			73.946,
			74.01,
			74.069,
			74.117,
			74.195,
			74.226,
			74.258,
			74.32,
			74.381,
			74.444,
			74.506,
			74.552,
			74.566,
			74.629,
			74.659,
			74.69,
			74.768,
			74.814,
			74.877,
			74.937,
			74.986,
			75.062,
			75.095,
			75.125,
			75.187,
			75.248,
			75.312,
			75.371,
			75.418,
			75.436,
			75.496,
			75.529,
			75.56,
			75.622,
			75.638,
			75.682,
			75.746,
			75.805,
			75.858,
			75.869,
			75.932,
			75.962,
			75.993,
			76.055,
			76.071,
			76.118,
			76.18,
			76.242,
			76.287,
			76.366,
			76.396,
			76.428,
			76.486,
			76.552,
			76.615,
			76.674,
			76.738,
			76.799,
			76.831,
			76.862,
			76.924,
			76.937,
			76.985,
			77.047,
			77.108,
			77.157,
			77.17,
			77.234,
			77.264,
			77.296,
			77.371,
			77.42,
			77.482,
			77.543,
			77.591,
			77.669,
			77.697,
			77.731,
			77.808,
			77.853,
			77.921,
			77.979,
			78.024,
			78.038,
			78.102,
			78.132,
			78.163,
			78.226,
			78.241,
			78.289,
			78.351,
			78.412,
			78.459,
			78.474,
			78.535,
			78.565,
			78.599,
			78.675,
			78.72,
			78.783,
			78.846,
			78.893,
			78.969,
			79.001,
			79.031,
			79.093,
			79.11,
			79.154,
			79.217,
			79.28,
			79.326,
			79.341,
			79.403,
			79.436,
			79.465,
			79.528,
			79.542,
			79.591,
			79.651,
			79.715,
			79.76,
			79.839,
			79.869,
			79.899,
			79.962,
			80.024,
			80.085,
			80.147,
			80.194,
			80.21,
			80.273,
			80.302,
			80.335,
			80.396,
			80.413,
			80.459,
			80.52,
			80.581,
			80.629,
			80.643,
			80.705,
			80.737,
			80.769,
			80.846,
			80.891,
			80.955,
			81.017,
			81.078,
			81.141,
			81.17,
			81.202,
			81.28,
			81.326,
			81.387,
			81.451,
			81.512,
			81.575,
			81.606,
			81.635,
			81.697,
			81.714,
			81.76,
			81.821,
			81.885,
			81.93,
			81.946,
			82.008,
			82.044,
			82.071,
			82.132,
			82.148,
			82.193,
			82.255,
			82.319,
			82.364,
			82.442,
			82.474,
			82.506,
			82.565,
			82.629,
			82.692,
			82.751,
			82.798,
			82.815,
			82.876,
			82.908,
			82.939,
			83.014,
			83.061,
			83.124,
			83.186,
			83.232,
			83.247,
			83.342,
			83.371,
			83.433,
			83.449,
			83.497,
			83.559,
			83.62,
			83.668,
			83.744,
			83.776,
			83.807,
			83.868,
			83.885,
			83.932,
			83.992,
			84.055,
			84.106,
			84.115,
			84.178,
			84.209,
			84.241,
			84.304,
			84.317,
			84.366,
			84.427,
			84.49,
			84.534,
			84.55,
			84.613,
			84.642,
			84.675,
			84.751,
			84.798,
			84.862,
			84.924,
			84.968,
			85.048,
			85.077,
			85.109,
			85.171,
			85.187,
			85.232,
			85.295,
			85.357,
			85.404,
			85.419,
			85.482,
			85.512,
			85.542,
			85.62,
			85.667,
			85.73,
			85.791,
			85.837,
			85.891
		]
	],
	'1851_2014_7982': [
		[
			103.244
		],
		[
			0
		],
		[
			0,
			1.764,
			6.611,
			9.847,
			13.087,
			14.709,
			17.955,
			19.579,
			22.832,
			26.09,
			27.72,
			30.985,
			34.255,
			39.168,
			42.449,
			47.377,
			49.022,
			50.668,
			55.613,
			58.915,
			63.874,
			67.184,
			70.498,
			75.477,
			77.138,
			78.801,
			83.794,
			85.46,
			87.127,
			92.134,
			95.477,
			98.824,
			100.499,
			103.852,
			107.209,
			108.889,
			112.251,
			113.934,
			117.303,
			120.676,
			125.742,
			129.124,
			134.204,
			135.9,
			137.596,
			142.691,
			146.092,
			151.201,
			154.612,
			158.026,
			159.735,
			163.156,
			164.868,
			168.294,
			171.723,
			173.438,
			176.87,
			180.304,
			185.461,
			188.904,
			192.349,
			195.8,
			197.526,
			200.982,
			202.712,
			206.176,
			209.646,
			214.859,
			218.339,
			223.559,
			225.295,
			227.029,
			232.199,
			235.638,
			240.653,
			243.954,
			247.214,
			248.828,
			252.028,
			253.611,
			256.776,
			259.844,
			261.378,
			264.44,
			267.44,
			271.858,
			274.782,
			279.138,
			280.568,
			281.99,
			286.212,
			288.989,
			291.72,
			295.826,
			298.579,
			302.668,
			304.042,
			305.439,
			309.562,
			310.935,
			312.305,
			316.408,
			319.132,
			323.165,
			325.835,
			327.159,
			329.806,
			332.396,
			333.681,
			336.228,
			338.746,
			342.478,
			344.926,
			348.621,
			349.855,
			351.088,
			354.804,
			357.265,
			360.947,
			363.39,
			365.797,
			369.393,
			370.598,
			371.776,
			376.42,
			377.566,
			380.968,
			383.227,
			385.474,
			386.597,
			388.833,
			391.087,
			392.235,
			394.509,
			395.644,
			397.923,
			400.215,
			403.656,
			405.955,
			409.395,
			410.538,
			411.681,
			415.107,
			417.387,
			420.773,
			423.022,
			426.37,
			428.596,
			429.713,
			431.945,
			434.172,
			435.29,
			437.52,
			439.748,
			441.978,
			445.319,
			447.553,
			449.782,
			450.897,
			453.122,
			454.232,
			456.445,
			458.648,
			459.748,
			461.955,
			464.17,
			467.494,
			468.604,
			469.714,
			473.043,
			475.263,
			478.59,
			480.812,
			483.035,
			484.146,
			486.375,
			487.492,
			489.755,
			492.025,
			493.164,
			495.449,
			497.737,
			501.167,
			502.308,
			503.448,
			506.819,
			507.939,
			509.056,
			511.265,
			512.366,
			514.563,
			517.821,
			519.983,
			522.128,
			525.33,
			526.389,
			527.447,
			530.608,
			532.703,
			535.829,
			537.899,
			539.959,
			540.986,
			543.035,
			544.057,
			546.094,
			548.129,
			549.146,
			551.175,
			553.208,
			556.26,
			557.279,
			558.3,
			561.368,
			562.393,
			563.421,
			566.512,
			568.581,
			571.687,
			573.752,
			575.807,
			578.854,
			579.84,
			581.824,
			584.733,
			585.683,
			588.485,
			590.316,
			592.116,
			593.006,
			594.762,
			596.485,
			597.333,
			599.001,
			599.822,
			601.435,
			603.788,
			605.314,
			606.804,
			608.972,
			609.678,
			610.375,
			612.416,
			613.735,
			615.657,
			616.896,
			618.109,
			618.699,
			619.862,
			620.437,
			621.569,
			623.234,
			623.782,
			625.414,
			626.492,
			627.566,
			628.101,
			629.172,
			630.246,
			630.786,
			631.866,
			632.404,
			633.482,
			634.562,
			636.24,
			637.383,
			639.101,
			639.675,
			640.252,
			642.142,
			643.409,
			645.319,
			646.672,
			648.708,
			650.07,
			650.758,
			652.166,
			653.578,
			654.285,
			655.706,
			657.237,
			659.551,
			660.328,
			661.117,
			662.769,
			664.428,
			665.261,
			666.824,
			667.547,
			668.999,
			670.46,
			672.481,
			673.748,
			675.662,
			676.304,
			676.936,
			678.83,
			680.103,
			682.041,
			683.359,
			685.357,
			686.706,
			687.394,
			688.78,
			690.181,
			690.886,
			692.312,
			693.761,
			695.964,
			697.454,
			699.723,
			700.486,
			701.251,
			703.556,
			705.093,
			707.383,
			708.894,
			710.381,
			712.554,
			713.262,
			713.959,
			716.66,
			717.312,
			719.21,
			720.428,
			721.611,
			722.189,
			723.326,
			724.435,
			724.98,
			726.053,
			726.582,
			727.627,
			728.657,
			730.176,
			731.177,
			732.672,
			733.17,
			733.68,
			735.207,
			736.223,
			737.752,
			738.806,
			739.918,
			740.474,
			741.59,
			742.149,
			743.271,
			745.098,
			746.343,
			747.588,
			748.837,
			750.1,
			750.73,
			751.987,
			753.243,
			753.873,
			755.153,
			755.791,
			757.062,
			758.33,
			760.299,
			761.634,
			763.632,
			764.305,
			765.028,
			767.187,
			768.627,
			770.694,
			772.047,
			773.404,
			774.085,
			775.235,
			775.789,
			776.898,
			778.004,
			778.555,
			779.625,
			780.629,
			781.612,
			782.57,
			783.038,
			783.956,
			784.872,
			785.362,
			786.335,
			786.811,
			787.754,
			788.688,
			790.108,
			791.155,
			792.715,
			793.234,
			793.751,
			795.316,
			796.423,
			798.072,
			799.163,
			800.253,
			800.812,
			801.925,
			802.479,
			803.584,
			804.688,
			805.241,
			806.336,
			807.423,
			809.049,
			809.592,
			810.136,
			811.826,
			812.394,
			812.961,
			814.658,
			815.793,
			817.578,
			818.76,
			819.943,
			820.537,
			821.744,
			822.35,
			822.955,
			824.774,
			825.384,
			825.99,
			827.74,
			828.912,
			830.685,
			831.824,
			832.948,
			833.513,
			834.651,
			835.224,
			836.377,
			837.479,
			839.15,
			840.281,
			841.993,
			842.554,
			843.119,
			844.831,
			845.992,
			847.75,
			848.931,
			850.73,
			851.95,
			852.563,
			853.791,
			855.031,
			855.655,
			856.281,
			858.149,
			859.387,
			860.633,
			861.259,
			862.521,
			863.787,
			864.423,
			865.704,
			866.349,
			867.651,
			868.987,
			871.018,
			872.392,
			874.473,
			875.172,
			875.875,
			878.007,
			879.44,
			881.608,
			883.072,
			885.314,
			886.834,
			887.599,
			889.141,
			890.704,
			891.491,
			893.074,
			894.665,
			897.047,
			897.84,
			898.636,
			900.246,
			901.902,
			902.735,
			904.41,
			905.278,
			907.027,
			908.787,
			911.463,
			913.288,
			916.049,
			916.976,
			917.916,
			920.779,
			922.692,
			925.508,
			927.4,
			929.303,
			930.259,
			932.181,
			933.147,
			935.088,
			938.022,
			939.994,
			941.978,
			944.976,
			945.981,
			946.989,
			950.04,
			951.063,
			952.089,
			955.196,
			957.282,
			960.433,
			962.546,
			964.651,
			967.811,
			968.867,
			969.925,
			973.113,
			974.178,
			975.245,
			978.461,
			980.613,
			982.774,
			983.859,
			986.035,
			988.221,
			989.317,
			991.517,
			992.621,
			994.818,
			997.023,
			1000.328,
			1002.538,
			1005.873,
			1006.989,
			1008.108,
			1011.484,
			1013.75,
			1017.18,
			1019.484,
			1022.96,
			1025.278,
			1026.44,
			1028.766,
			1031.092,
			1032.259,
			1033.429,
			1036.958,
			1039.325,
			1041.705,
			1042.898,
			1045.293,
			1047.674,
			1048.864,
			1051.243,
			1052.435,
			1054.834,
			1057.246,
			1060.9,
			1063.352,
			1067.074,
			1068.32,
			1069.569,
			1073.328,
			1075.838,
			1079.61,
			1082.126,
			1085.87,
			1088.359,
			1089.589,
			1092.048,
			1094.51,
			1095.74,
			1098.202,
			1100.669,
			1104.388,
			1105.639,
			1106.892,
			1109.406,
			1111.937,
			1113.205,
			1115.752,
			1117.028,
			1119.587,
			1122.155,
			1126.018,
			1128.599,
			1132.487,
			1133.786,
			1135.093,
			1139.025,
			1141.658,
			1145.625,
			1148.282,
			1152.292,
			1154.979,
			1156.329,
			1159.033,
			1161.753,
			1163.115,
			1165.848,
			1168.589,
			1172.716,
			1174.095,
			1175.477,
			1179.63,
			1181.02,
			1182.41,
			1185.195,
			1186.592,
			1189.387,
			1193.592,
			1196.403,
			1199.222,
			1203.464,
			1204.882,
			1206.304,
			1210.583,
			1212.014,
			1213.447,
			1217.76,
			1220.644,
			1223.536,
			1224.984,
			1227.883,
			1230.786,
			1232.239,
			1235.149,
			1236.604,
			1239.52,
			1242.441,
			1246.837,
			1249.778,
			1254.203,
			1255.68,
			1257.156,
			1261.588,
			1264.543,
			1268.975,
			1271.931,
			1274.891,
			1276.37,
			1279.332,
			1280.814,
			1282.295,
			1286.744,
			1288.227,
			1289.712,
			1294.171,
			1297.155,
			1301.647,
			1304.653,
			1307.666,
			1309.174,
			1312.195,
			1313.707,
			1316.735,
			1319.767,
			1324.306,
			1327.361,
			1331.961,
			1333.499,
			1335.038,
			1339.664,
			1342.748,
			1347.377,
			1350.466,
			1355.112,
			1358.211,
			1359.757,
			1362.838,
			1365.894,
			1367.41,
			1370.415,
			1373.383,
			1377.763,
			1379.203,
			1380.634,
			1383.464,
			1386.256,
			1387.637,
			1390.371,
			1391.724,
			1394.4,
			1397.038,
			1400.928,
			1403.473,
			1407.224,
			1408.456,
			1409.679,
			1413.289,
			1415.65,
			1419.112,
			1421.371,
			1424.68,
			1426.838,
			1427.901,
			1430.001,
			1432.063,
			1433.078,
			1435.081,
			1437.047,
			1439.937,
			1441.811,
			1444.555,
			1445.453,
			1446.344,
			1448.119,
			1448.995,
			1450.726,
			1453.282,
			1454.955,
			1456.605,
			1459.05,
			1459.857,
			1460.661,
			1463.058,
			1464.65,
			1467.043,
			1468.638,
			1470.237,
			1471.039,
			1472.645,
			1474.255,
			1475.061,
			1477.487,
			1479.109,
			1480.734,
			1483.181,
			1484.816,
			1487.27,
			1488.092,
			1488.913,
			1491.369,
			1493.008,
			1495.458,
			1497.085,
			1498.715,
			1499.526,
			1501.143,
			1501.949,
			1502.772,
			1505.231,
			1506.048,
			1506.864,
			1509.386,
			1511.063,
			1512.808,
			1513.737,
			1515.595,
			1517.494,
			1518.543,
			1520.64,
			1521.688,
			1523.782,
			1525.871,
			1528.844,
			1530.744,
			1533.466,
			1534.322,
			1535.177,
			1537.721,
			1539.342,
			1541.768,
			1543.374,
			1545.767,
			1547.359,
			1548.157,
			1549.748,
			1552.127,
			1553.72,
			1555.312,
			1556.898,
			1559.281,
			1560.867,
			1562.448,
			1563.237,
			1564.814,
			1565.6,
			1567.17,
			1568.737,
			1571.07,
			1572.645,
			1574.971,
			1575.751,
			1576.531,
			1578.883,
			1579.672,
			1580.464,
			1582.859,
			1584.475,
			1586.104,
			1586.923,
			1588.573,
			1589.403,
			1591.073,
			1592.754,
			1593.596,
			1595.282,
			1596.958,
			1599.431,
			1600.24,
			1601.042,
			1603.392,
			1604.157,
			1604.914,
			1607.129,
			1608.561,
			1610.645,
			1611.993,
			1613.308,
			1615.221,
			1615.842,
			1616.453,
			1618.238,
			1619.406,
			1621.127,
			1622.275,
			1623.993,
			1625.144,
			1626.296,
			1626.872,
			1628.025,
			1628.601,
			1629.75,
			1630.895,
			1632.607,
			1633.176,
			1633.739,
			1635.405,
			1636.51,
			1638.164,
			1639.24,
			1640.834,
			1641.893,
			1642.951,
			1643.48,
			1644.553,
			1645.087,
			1646.153,
			1647.216,
			1647.747,
			1648.278,
			1650.1,
			1651.325,
			1652.545,
			1653.155,
			1654.68,
			1656.319,
			1657.136,
			1658.828,
			1659.865,
			1661.931,
			1663.938,
			1666.611,
			1668.384,
			1670.231,
			1670.82,
			1671.409,
			1673.174,
			1674.251,
			1675.78,
			1676.802,
			1677.828,
			1678.344,
			1679.41,
			1679.952,
			1681.04,
			1682.132,
			1682.68,
			1683.23,
			1684.931,
			1686.69,
			1687.882,
			1689.099,
			1690.357,
			1690.992,
			1692.275,
			1692.923,
			1694.252,
			1695.652,
			1697.768,
			1699.222,
			1701.535,
			1702.315,
			1703.1,
			1705.526,
			1707.183,
			1709.683,
			1711.334,
			1713.842,
			1715.491,
			1716.317,
			1717.98,
			1719.661,
			1720.508,
			1722.212,
			1723.93,
			1726.575,
			1727.463,
			1728.354,
			1731.053,
			1731.958,
			1732.865,
			1735.616,
			1737.469,
			1740.287,
			1742.201,
			1744.117,
			1747.001,
			1747.96,
			1748.92,
			1752.696,
			1753.635,
			1756.358,
			1758.14,
			1759.91,
			1760.775,
			1762.502,
			1764.224,
			1765.087,
			1766.812,
			1767.672,
			1769.388,
			1771.096,
			1773.645,
			1775.329,
			1777.823,
			1778.651,
			1779.478,
			1781.942,
			1783.576,
			1786.023,
			1787.649,
			1789.269,
			1790.081,
			1791.71,
			1792.523,
			1793.334,
			1795.765,
			1796.585,
			1797.418,
			1799.867,
			1801.498,
			1803.123,
			1803.935,
			1805.555,
			1807.167,
			1807.97,
			1810.368,
			1811.942,
			1813.508,
			1815.834,
			1817.365,
			1819.646,
			1820.405,
			1821.163,
			1824.174,
			1824.923,
			1827.195,
			1828.705,
			1830.98,
			1832.516,
			1833.283,
			1834.819,
			1836.372,
			1837.153,
			1837.936,
			1840.289,
			1841.866,
			1843.448,
			1844.23,
			1845.832,
			1847.455,
			1848.272,
			1850.777,
			1852.509,
			1854.255,
			1856.955,
			1858.796,
			1861.587,
			1862.525,
			1863.468,
			1866.303,
			1868.194,
			1871.049,
			1872.953,
			1874.867,
			1875.826,
			1877.744,
			1878.706,
			1880.638,
			1882.6,
			1883.584,
			1885.557,
			1887.574,
			1890.612,
			1891.629,
			1892.648,
			1895.71,
			1896.716,
			1897.723,
			1900.751,
			1902.734,
			1904.723,
			1907.688,
			1909.66,
			1912.597,
			1913.572,
			1914.549,
			1917.465,
			1918.434,
			1919.405,
			1922.322,
			1924.271,
			1927.213,
			1929.187,
			1931.172,
			1932.173,
			1935.192,
			1937.225,
			1939.275,
			1942.374,
			1943.414,
			1944.456,
			1947.581,
			1948.625,
			1949.67,
			1952.825,
			1954.923,
			1958.116,
			1960.256,
			1963.489,
			1965.656,
			1966.744,
			1967.835,
			1971.123,
			1972.224,
			1973.329,
			1976.657,
			1978.889,
			1981.13,
			1982.255,
			1984.511,
			1986.777,
			1987.914,
			1990.195,
			1991.339,
			1993.634,
			1995.938,
			1999.413,
			2001.742,
			2005.252,
			2006.427,
			2007.604,
			2011.15,
			2013.525,
			2017.105,
			2019.504,
			2021.911,
			2023.119,
			2025.541,
			2026.755,
			2029.19,
			2031.634,
			2032.859,
			2035.317,
			2037.783,
			2041.498,
			2042.741,
			2043.986,
			2046.484,
			2048.99,
			2050.245,
			2052.763,
			2054.025,
			2056.556,
			2059.095,
			2062.919,
			2065.479,
			2069.335,
			2070.624,
			2071.915,
			2075.8,
			2078.399,
			2082.31,
			2084.918,
			2087.533,
			2088.844,
			2091.469,
			2092.785,
			2095.422,
			2098.066,
			2099.391,
			2102.045,
			2104.706,
			2108.709,
			2111.387,
			2115.416,
			2116.762,
			2118.11,
			2122.163,
			2124.874,
			2127.592,
			2131.683,
			2134.419,
			2138.536,
			2139.912,
			2141.29,
			2146.818,
			2148.205,
			2152.375,
			2155.164,
			2157.96,
			2159.36,
			2162.166,
			2164.979,
			2166.388,
			2169.21,
			2170.624,
			2173.457,
			2176.297,
			2180.569,
			2183.425,
			2187.721,
			2189.157,
			2190.594,
			2194.915,
			2197.803,
			2202.148,
			2205.054,
			2207.966,
			2209.424,
			2212.345,
			2213.808,
			2215.272,
			2219.676,
			2221.147,
			2222.619,
			2227.045,
			2230.005,
			2232.97,
			2234.456,
			2237.431,
			2240.413,
			2241.907,
			2244.897,
			2246.395,
			2249.394,
			2252.4,
			2256.922,
			2259.943,
			2264.485,
			2266.002,
			2267.521,
			2272.084,
			2275.133,
			2279.717,
			2282.781,
			2285.849,
			2287.385,
			2290.462,
			2292.003,
			2295.088,
			2299.726,
			2302.824,
			2305.928,
			2309.037,
			2312.152,
			2313.711,
			2316.833,
			2319.961,
			2321.526,
			2324.662,
			2326.232,
			2329.375,
			2332.521,
			2337.226,
			2340.337,
			2344.94,
			2346.456,
			2347.962,
			2352.42,
			2355.344,
			2359.66,
			2362.489,
			2365.279,
			2366.659,
			2369.392,
			2370.744,
			2373.419,
			2376.055,
			2377.358,
			2379.937,
			2382.48,
			2386.226,
			2387.456,
			2388.677,
			2392.284,
			2393.469,
			2394.645,
			2398.124,
			2400.397,
			2402.634,
			2405.919,
			2408.062,
			2411.207,
			2412.236,
			2413.256,
			2416.256,
			2417.234,
			2418.203,
			2421.05,
			2422.884,
			2425.572,
			2427.297,
			2428.988,
			2429.835,
			2431.471,
			2432.273,
			2433.852,
			2435.396,
			2437.663,
			2439.133,
			2441.283,
			2441.992,
			2442.694,
			2444.763,
			2446.119,
			2448.123,
			2449.444,
			2450.757,
			2452.702,
			2453.351,
			2454.652,
			2456.615,
			2457.271,
			2459.247,
			2460.568,
			2461.901,
			2462.569,
			2463.906,
			2465.244,
			2465.913,
			2467.942,
			2469.292,
			2470.64,
			2472.674,
			2474.026,
			2476.045,
			2476.723,
			2477.399,
			2479.415,
			2480.752,
			2482.774,
			2484.119,
			2486.127,
			2487.485,
			2488.162,
			2489.512,
			2490.861,
			2491.542,
			2492.918,
			2494.29,
			2496.349,
			2497.048,
			2497.745,
			2499.135,
			2500.524,
			2501.219,
			2502.616,
			2503.316,
			2504.713,
			2506.111,
			2508.261,
			2509.7,
			2511.867,
			2512.62,
			2513.373,
			2515.628,
			2517.146,
			2519.463,
			2521.01,
			2522.55,
			2523.315,
			2524.849,
			2525.617,
			2527.138,
			2528.619,
			2529.362,
			2530.851,
			2532.32,
			2534.479,
			2535.202,
			2535.927,
			2537.373,
			2538.81,
			2539.531,
			2541.712,
			2543.169,
			2544.637,
			2546.862,
			2548.356,
			2550.62,
			2551.382,
			2552.146,
			2554.45,
			2556.013,
			2558.394,
			2560.003,
			2561.626,
			2562.445,
			2564.091,
			2564.918,
			2566.573,
			2568.232,
			2569.059,
			2570.708,
			2572.349,
			2574.83,
			2576.464,
			2578.912,
			2579.725,
			2580.536,
			2582.961,
			2584.577,
			2586.992,
			2588.603,
			2590.213,
			2592.626,
			2593.435,
			2594.244,
			2597.479,
			2598.297,
			2600.756,
			2602.397,
			2604.882,
			2606.54,
			2608.201,
			2609.034,
			2611.535,
			2613.208,
			2614.906,
			2617.457,
			2619.167,
			2621.74,
			2622.599,
			2623.461,
			2626.051,
			2627.778,
			2630.374,
			2632.102,
			2633.829,
			2634.692,
			2636.415,
			2637.275,
			2638.135,
			2641.576,
			2642.436,
			2645.02,
			2646.742,
			2648.466,
			2649.333,
			2651.066,
			2652.798,
			2653.664,
			2655.395,
			2656.259,
			2657.987,
			2659.715,
			2662.302,
			2664.022,
			2666.581,
			2667.432,
			2668.283,
			2670.829,
			2672.521,
			2675.067,
			2676.769,
			2678.467,
			2679.323,
			2681.044,
			2681.902,
			2683.615,
			2685.346,
			2686.211,
			2687.938,
			2689.675,
			2692.305,
			2693.18,
			2694.056,
			2695.816,
			2697.571,
			2698.448,
			2701.074,
			2702.82,
			2705.441,
			2707.187,
			2708.93,
			2711.539,
			2712.405,
			2713.271,
			2715.867,
			2716.73,
			2717.592,
			2720.18,
			2721.91,
			2723.637,
			2724.501,
			2726.235,
			2727.965,
			2728.831,
			2730.561,
			2731.426,
			2733.155,
			2734.88,
			2737.447,
			2739.154,
			2741.657,
			2742.487,
			2743.317,
			2745.783,
			2747.408,
			2749.847,
			2751.444,
			2753.844,
			2755.434,
			2756.22,
			2757.007,
			2759.375,
			2760.165,
			2760.951,
			2763.32,
			2764.911,
			2766.517,
			2767.323,
			2768.944,
			2770.582,
			2771.409,
			2773.072,
			2773.906,
			2775.578,
			2777.26,
			2779.806,
			2781.526,
			2784.134,
			2785.01,
			2785.89,
			2788.552,
			2790.343,
			2793.051,
			2794.872,
			2797.621,
			2799.467,
			2800.395,
			2802.25,
			2804.101,
			2805.039,
			2806.917,
			2808.804,
			2810.704,
			2812.612,
			2813.57,
			2815.496,
			2817.435,
			2818.409,
			2821.35,
			2823.329,
			2825.319,
			2828.333,
			2830.357,
			2833.421,
			2834.448,
			2835.478,
			2838.591,
			2840.68,
			2843.836,
			2845.933,
			2848.041,
			2849.099,
			2851.218,
			2852.285,
			2854.424,
			2856.571,
			2857.644,
			2859.78,
			2861.96,
			2865.219,
			2866.307,
			2867.395,
			2870.658,
			2871.746,
			2872.833,
			2875.005,
			2876.09,
			2878.248,
			2880.392,
			2883.596,
			2885.724,
			2888.884,
			2889.928,
			2890.971,
			2894.074,
			2896.126,
			2899.198,
			2901.238,
			2903.297,
			2904.34,
			2906.421,
			2908.525,
			2909.589,
			2911.714,
			2912.783,
			2914.978,
			2917.171,
			2920.542,
			2922.8,
			2926.177,
			2927.307,
			2928.439,
			2931.851,
			2934.149,
			2937.627,
			2939.988,
			2942.363,
			2943.55,
			2945.926,
			2947.117,
			2948.302,
			2951.804,
			2952.978,
			2954.12,
			2957.557,
			2959.796,
			2962.026,
			2963.144,
			2965.352,
			2967.566,
			2968.675,
			2970.889,
			2972,
			2974.231,
			2976.474,
			2979.863,
			2982.12,
			2985.583,
			2986.738,
			2987.895,
			2991.389,
			2993.729,
			2997.271,
			2999.622,
			3002,
			3003.192,
			3005.583,
			3006.783,
			3009.209,
			3011.625,
			3012.837,
			3014.05,
			3017.685,
			3020.129,
			3023.798,
			3026.232,
			3028.647,
			3029.845,
			3032.218,
			3033.392,
			3035.711,
			3037.992,
			3041.341,
			3043.527,
			3046.735,
			3047.785,
			3048.825,
			3051.897,
			3053.911,
			3056.884,
			3058.812,
			3061.64,
			3063.476,
			3064.381,
			3066.167,
			3067.916,
			3068.78,
			3070.483,
			3072.157,
			3074.612,
			3075.417,
			3076.219,
			3077.809,
			3079.377,
			3080.153,
			3081.701,
			3082.474,
			3084.014,
			3086.324,
			3087.88,
			3089.439,
			3091.789,
			3092.577,
			3093.365,
			3095.733,
			3097.318,
			3099.698,
			3101.286,
			3102.88,
			3103.676,
			3105.267,
			3106.861,
			3107.659,
			3110.049,
			3111.644,
			3113.242,
			3115.634,
			3117.232,
			3119.628,
			3120.425,
			3121.222,
			3123.628,
			3125.226,
			3127.63,
			3129.234,
			3130.833,
			3133.249,
			3134.055,
			3134.859,
			3137.278,
			3138.092,
			3138.905,
			3141.336,
			3142.969,
			3144.601,
			3145.415,
			3147.04,
			3148.686,
			3149.506,
			3151.141,
			3151.956,
			3153.608,
			3155.254,
			3157.728,
			3159.399,
			3161.899,
			3162.735,
			3163.571,
			3166.074,
			3167.732,
			3170.189,
			3171.81,
			3173.386,
			3174.164,
			3175.72,
			3176.498,
			3178.028,
			3179.518,
			3180.266,
			3181.017,
			3183.264,
			3184.775,
			3186.299,
			3187.066,
			3188.628,
			3190.209,
			3191.019,
			3192.622,
			3193.44,
			3195.087,
			3196.747,
			3199.271,
			3200.972,
			3203.539,
			3204.401,
			3205.265,
			3207.876,
			3209.633,
			3212.292,
			3214.081,
			3215.883,
			3216.789,
			3218.61,
			3220.444,
			3221.366,
			3223.218,
			3224.145,
			3226.006,
			3227.88,
			3230.691,
			3231.633,
			3232.577,
			3235.42,
			3236.372,
			3237.326,
			3240.21,
			3242.146,
			3244.097,
			3247.05,
			3249.035,
			3252.049,
			3253.059,
			3254.074,
			3257.148,
			3259.21,
			3262.332,
			3264.421,
			3267.576,
			3269.686,
			3270.749,
			3272.872,
			3274.999,
			3276.07,
			3278.217,
			3280.374,
			3283.639,
			3285.83,
			3289.145,
			3290.253,
			3291.365,
			3294.715,
			3296.96,
			3300.354,
			3302.624,
			3304.898,
			3308.318,
			3309.461,
			3310.605,
			3314.042,
			3315.19,
			3316.34,
			3319.826,
			3322.165,
			3325.694,
			3328.064,
			3329.258,
			3331.653,
			3334.054,
			3335.256,
			3337.663,
			3340.052,
			3343.636,
			3346.017,
			3349.575,
			3350.753,
			3351.932,
			3355.465,
			3357.818,
			3361.349,
			3363.701,
			3367.206,
			3369.53,
			3370.684,
			3372.961,
			3375.234,
			3376.358,
			3378.582,
			3380.765,
			3382.903,
			3385.002,
			3386.035,
			3388.071,
			3390.071,
			3391.054,
			3392.993,
			3393.949,
			3395.833,
			3398.59,
			3400.409,
			3402.182,
			3404.798,
			3405.661,
			3406.519,
			3409.069,
			3410.758,
			3413.311,
			3415.014,
			3416.725,
			3417.592,
			3419.325,
			3420.192,
			3421.936,
			3424.592,
			3426.364,
			3428.141,
			3430.805,
			3431.693,
			3432.583,
			3435.249,
			3436.136,
			3437.023,
			3438.813,
			3439.705,
			3441.488,
			3443.3,
			3446.031,
			3447.856,
			3450.615,
			3451.532,
			3452.448,
			3455.198,
			3457.021,
			3459.749,
			3461.555,
			3463.35,
			3464.242,
			3466.017,
			3466.901,
			3468.648,
			3470.372,
			3471.23,
			3472.944,
			3474.657,
			3477.215,
			3478.066,
			3478.921,
			3481.479,
			3482.331,
			3483.182,
			3485.782,
			3487.52,
			3490.115,
			3491.84,
			3493.554,
			3496.039,
			3496.86,
			3497.676,
			3500.116,
			3500.915,
			3501.703,
			3503.989,
			3505.475,
			3506.921,
			3507.637,
			3509.078,
			3510.564,
			3511.296,
			3512.745,
			3513.462,
			3514.946,
			3516.444,
			3518.653,
			3520.251,
			3522.704,
			3523.513,
			3524.363,
			3527.342,
			3529.307,
			3532.728,
			3534.923,
			3537.961,
			3539.876,
			3540.716,
			3542.384,
			3544.039,
			3544.837,
			3545.59,
			3547.834,
			3550.07,
			3550.828,
			3551.584,
			3553.094,
			3554.599,
			3555.33,
			3556.774,
			3557.494,
			3558.932,
			3560.336,
			3562.354,
			3563.696,
			3565.653,
			3566.282,
			3566.911,
			3568.793,
			3570.074,
			3571.979,
			3573.257,
			3574.544,
			3575.181,
			3576.431,
			3577.057,
			3578.305,
			3579.578,
			3580.222,
			3581.517,
			3582.83,
			3584.834,
			3586.192,
			3587.566,
			3588.958,
			3589.659,
			3591.792,
			3593.235,
			3594.694,
			3596.924,
			3598.421,
			3600.696,
			3601.464,
			3602.236,
			3604.577,
			3606.157,
			3608.578,
			3610.21,
			3611.859,
			3612.694,
			3614.375,
			3615.205,
			3616.893,
			3618.584,
			3619.433,
			3621.144,
			3622.865,
			3625.471,
			3626.347,
			3627.225,
			3629.876,
			3630.767,
			3631.66,
			3634.359,
			3636.173,
			3638.001,
			3640.766,
			3642.625,
			3643.56,
			3645.438,
			3646.381,
			3647.329,
			3650.189,
			3651.148,
			3652.111,
			3655.018,
			3656.971,
			3659.923,
			3661.905,
			3663.899,
			3664.902,
			3667.925,
			3669.955,
			3671.996,
			3675.079,
			3677.148,
			3680.273,
			3681.32,
			3682.369,
			3685.513,
			3687.613,
			3690.782,
			3692.908,
			3695.045,
			3696.117,
			3698.269,
			3699.348,
			3701.514,
			3704.782,
			3706.973,
			3709.173,
			3711.383,
			3713.603,
			3714.716,
			3716.951,
			3719.196,
			3720.322,
			3723.715,
			3725.99,
			3728.274,
			3729.42,
			3731.718,
			3734.027,
			3737.507,
			3738.672,
			3739.839,
			3743.354,
			3745.71,
			3749.261,
			3751.641,
			3754.03,
			3755.227,
			3757.63,
			3758.835,
			3761.252,
			3763.678,
			3764.895,
			3767.334,
			3769.782,
			3772.24,
			3774.706,
			3775.942,
			3779.665,
			3780.91,
			3782.157,
			3785.912,
			3788.425,
			3790.948,
			3794.747,
			3797.29,
			3801.12,
			3802.401,
			3803.684,
			3807.545,
			3810.128,
			3814.019,
			3816.622,
			3819.229,
			3820.532,
			3823.142,
			3824.45,
			3827.072,
			3829.7,
			3831.017,
			3833.656,
			3836.302,
			3840.284,
			3841.615,
			3842.948,
			3846.957,
			3848.297,
			3849.639,
			3853.675,
			3856.374,
			3859.08,
			3863.152,
			3865.876,
			3869.975,
			3871.345,
			3872.716,
			3876.84,
			3879.599,
			3883.75,
			3886.526,
			3890.702,
			3893.494,
			3896.293,
			3897.696,
			3901.913,
			3904.733,
			3907.559,
			3911.812,
			3913.233,
			3914.656,
			3918.933,
			3920.362,
			3921.792,
			3926.095,
			3928.971,
			3933.297,
			3936.189,
			3939.088,
			3943.45,
			3944.906,
			3946.365,
			3950.75,
			3952.216,
			3953.683,
			3958.095,
			3961.044,
			3963.999,
			3965.479,
			3968.444,
			3971.415,
			3972.903,
			3975.882,
			3977.374,
			3980.363,
			3983.357,
			3987.86,
			3990.869,
			3995.393,
			3996.904,
			3998.417,
			4002.962,
			4006,
			4010.566,
			4013.618,
			4016.675,
			4018.206,
			4021.271,
			4022.806,
			4025.879,
			4028.958,
			4030.5,
			4033.587,
			4036.68,
			4041.328,
			4042.879,
			4044.433,
			4049.101,
			4050.66,
			4052.22,
			4055.344,
			4056.908,
			4060.04,
			4063.177,
			4067.892,
			4071.042,
			4075.777,
			4077.357,
			4078.938,
			4083.691,
			4086.866,
			4091.636,
			4094.817,
			4098.002,
			4099.596,
			4102.788,
			4104.385,
			4107.583,
			4110.785,
			4112.388,
			4115.598,
			4118.811,
			4123.638,
			4126.861,
			4131.704,
			4133.321,
			4134.938,
			4139.798,
			4143.042,
			4147.918,
			4151.173,
			4154.433,
			4156.064,
			4159.329,
			4160.964,
			4162.6,
			4167.512,
			4169.151,
			4170.792,
			4175.72,
			4179.01,
			4182.305,
			4183.955,
			4187.256,
			4190.56,
			4192.212,
			4193.337
		],
		[
			0,
			0.04,
			0.103,
			0.165,
			0.209,
			0.227,
			0.287,
			0.318,
			0.351,
			0.413,
			0.425,
			0.474,
			0.537,
			0.598,
			0.645,
			0.723,
			0.752,
			0.784,
			0.846,
			0.909,
			0.97,
			1.033,
			1.093,
			1.155,
			1.188,
			1.217,
			1.281,
			1.296,
			1.342,
			1.404,
			1.466,
			1.513,
			1.529,
			1.59,
			1.621,
			1.652,
			1.713,
			1.731,
			1.775,
			1.838,
			1.9,
			1.946,
			2.023,
			2.054,
			2.086,
			2.164,
			2.209,
			2.272,
			2.334,
			2.38,
			2.397,
			2.458,
			2.49,
			2.52,
			2.581,
			2.598,
			2.645,
			2.707,
			2.769,
			2.831,
			2.894,
			2.922,
			2.955,
			3.016,
			3.031,
			3.079,
			3.142,
			3.202,
			3.25,
			3.327,
			3.356,
			3.388,
			3.451,
			3.514,
			3.576,
			3.635,
			3.683,
			3.697,
			3.762,
			3.792,
			3.822,
			3.884,
			3.9,
			3.946,
			4.01,
			4.071,
			4.134,
			4.195,
			4.226,
			4.257,
			4.333,
			4.382,
			4.443,
			4.505,
			4.568,
			4.626,
			4.661,
			4.69,
			4.753,
			4.769,
			4.814,
			4.876,
			4.939,
			5,
			5.064,
			5.093,
			5.125,
			5.187,
			5.207,
			5.25,
			5.312,
			5.372,
			5.42,
			5.497,
			5.529,
			5.559,
			5.635,
			5.684,
			5.746,
			5.807,
			5.854,
			5.931,
			5.962,
			5.992,
			6.072,
			6.118,
			6.184,
			6.24,
			6.287,
			6.304,
			6.365,
			6.396,
			6.427,
			6.49,
			6.504,
			6.551,
			6.613,
			6.674,
			6.723,
			6.8,
			6.831,
			6.862,
			6.94,
			6.985,
			7.048,
			7.108,
			7.171,
			7.232,
			7.268,
			7.296,
			7.356,
			7.374,
			7.419,
			7.48,
			7.542,
			7.605,
			7.668,
			7.697,
			7.73,
			7.792,
			7.807,
			7.854,
			7.916,
			7.919,
			7.976,
			8.024,
			8.1,
			8.131,
			8.164,
			8.224,
			8.286,
			8.351,
			8.411,
			8.456,
			8.474,
			8.535,
			8.568,
			8.597,
			8.66,
			8.674,
			8.722,
			8.783,
			8.847,
			8.892,
			8.907,
			8.971,
			9.001,
			9.032,
			9.093,
			9.11,
			9.154,
			9.219,
			9.281,
			9.33,
			9.405,
			9.433,
			9.465,
			9.543,
			9.588,
			9.653,
			9.713,
			9.759,
			9.777,
			9.838,
			9.868,
			9.9,
			9.96,
			9.978,
			10.023,
			10.086,
			10.146,
			10.193,
			10.208,
			10.271,
			10.301,
			10.334,
			10.396,
			10.457,
			10.519,
			10.58,
			10.643,
			10.706,
			10.737,
			10.769,
			10.846,
			10.89,
			10.955,
			11.016,
			11.062,
			11.076,
			11.14,
			11.17,
			11.201,
			11.263,
			11.279,
			11.326,
			11.395,
			11.451,
			11.496,
			11.574,
			11.604,
			11.637,
			11.714,
			11.759,
			11.822,
			11.885,
			11.931,
			11.946,
			12.009,
			12.039,
			12.071,
			12.149,
			12.192,
			12.258,
			12.318,
			12.364,
			12.381,
			12.441,
			12.473,
			12.504,
			12.567,
			12.582,
			12.628,
			12.69,
			12.752,
			12.799,
			12.877,
			12.907,
			12.939,
			13,
			13.06,
			13.125,
			13.186,
			13.248,
			13.309,
			13.341,
			13.372,
			13.435,
			13.453,
			13.497,
			13.558,
			13.621,
			13.667,
			13.683,
			13.743,
			13.774,
			13.807,
			13.867,
			13.885,
			13.93,
			13.993,
			14.053,
			14.1,
			14.18,
			14.207,
			14.24,
			14.301,
			14.364,
			14.426,
			14.489,
			14.55,
			14.614,
			14.642,
			14.676,
			14.737,
			14.753,
			14.798,
			14.86,
			14.923,
			14.984,
			15.047,
			15.077,
			15.108,
			15.17,
			15.233,
			15.295,
			15.355,
			15.404,
			15.48,
			15.515,
			15.542,
			15.621,
			15.666,
			15.729,
			15.792,
			15.836,
			15.854,
			15.914,
			15.945,
			15.977,
			16.04,
			16.053,
			16.1,
			16.162,
			16.226,
			16.271,
			16.349,
			16.38,
			16.412,
			16.473,
			16.533,
			16.597,
			16.66,
			16.706,
			16.719,
			16.784,
			16.813,
			16.845,
			16.922,
			16.97,
			17.03,
			17.092,
			17.139,
			17.155,
			17.215,
			17.249,
			17.279,
			17.342,
			17.357,
			17.404,
			17.466,
			17.526,
			17.579,
			17.651,
			17.682,
			17.713,
			17.775,
			17.837,
			17.901,
			17.962,
			18.008,
			18.022,
			18.084,
			18.116,
			18.146,
			18.21,
			18.225,
			18.27,
			18.333,
			18.393,
			18.441,
			18.456,
			18.518,
			18.549,
			18.581,
			18.642,
			18.659,
			18.706,
			18.766,
			18.829,
			18.874,
			18.952,
			18.984,
			19.015,
			19.091,
			19.14,
			19.203,
			19.263,
			19.309,
			19.325,
			19.386,
			19.418,
			19.45,
			19.512,
			19.525,
			19.573,
			19.639,
			19.697,
			19.743,
			19.758,
			19.82,
			19.851,
			19.883,
			19.946,
			20.007,
			20.07,
			20.13,
			20.179,
			20.197,
			20.255,
			20.286,
			20.319,
			20.381,
			20.395,
			20.441,
			20.504,
			20.565,
			20.629,
			20.689,
			20.72,
			20.752,
			20.813,
			20.83,
			20.874,
			20.938,
			20.998,
			21.047,
			21.122,
			21.153,
			21.186,
			21.264,
			21.309,
			21.373,
			21.433,
			21.497,
			21.559,
			21.588,
			21.619,
			21.68,
			21.701,
			21.743,
			21.806,
			21.866,
			21.913,
			21.93,
			21.991,
			22.023,
			22.053,
			22.116,
			22.131,
			22.178,
			22.242,
			22.303,
			22.363,
			22.427,
			22.456,
			22.489,
			22.567,
			22.613,
			22.674,
			22.736,
			22.796,
			22.859,
			22.892,
			22.923,
			22.984,
			23.001,
			23.046,
			23.107,
			23.171,
			23.216,
			23.233,
			23.293,
			23.326,
			23.356,
			23.418,
			23.434,
			23.48,
			23.543,
			23.603,
			23.65,
			23.729,
			23.763,
			23.79,
			23.867,
			23.914,
			23.976,
			24.039,
			24.084,
			24.1,
			24.163,
			24.192,
			24.225,
			24.302,
			24.348,
			24.409,
			24.473,
			24.518,
			24.534,
			24.597,
			24.628,
			24.659,
			24.736,
			24.781,
			24.845,
			24.905,
			24.952,
			25.031,
			25.06,
			25.093,
			25.155,
			25.17,
			25.216,
			25.28,
			25.34,
			25.385,
			25.403,
			25.464,
			25.496,
			25.525,
			25.588,
			25.602,
			25.649,
			25.714,
			25.773,
			25.835,
			25.897,
			25.932,
			25.962,
			26.023,
			26.086,
			26.148,
			26.208,
			26.272,
			26.334,
			26.364,
			26.396,
			26.456,
			26.471,
			26.517,
			26.581,
			26.643,
			26.689,
			26.703,
			26.766,
			26.797,
			26.829,
			26.89,
			26.907,
			26.952,
			27.016,
			27.076,
			27.124,
			27.2,
			27.232,
			27.263,
			27.34,
			27.386,
			27.45,
			27.509,
			27.573,
			27.634,
			27.665,
			27.697,
			27.76,
			27.774,
			27.822,
			27.886,
			27.945,
			27.99,
			28.007,
			28.068,
			28.098,
			28.132,
			28.192,
			28.209,
			28.255,
			28.317,
			28.379,
			28.426,
			28.501,
			28.535,
			28.564,
			28.628,
			28.688,
			28.752,
			28.813,
			28.874,
			28.937,
			28.968,
			28.999,
			29.06,
			29.075,
			29.123,
			29.19,
			29.246,
			29.292,
			29.309,
			29.37,
			29.401,
			29.434,
			29.494,
			29.512,
			29.556,
			29.62,
			29.68,
			29.728,
			29.804,
			29.837,
			29.868,
			29.929,
			29.95,
			29.99,
			30.054,
			30.116,
			30.162,
			30.176,
			30.239,
			30.27,
			30.301,
			30.364,
			30.379,
			30.424,
			30.487,
			30.55,
			30.596,
			30.673,
			30.705,
			30.734,
			30.798,
			30.859,
			30.925,
			30.983,
			31.03,
			31.046,
			31.108,
			31.137,
			31.168,
			31.231,
			31.248,
			31.293,
			31.354,
			31.418,
			31.48,
			31.542,
			31.574,
			31.604,
			31.664,
			31.682,
			31.728,
			31.791,
			31.851,
			31.914,
			31.976,
			32.011,
			32.037,
			32.099,
			32.163,
			32.223,
			32.287,
			32.348,
			32.409,
			32.44,
			32.47,
			32.534,
			32.55,
			32.596,
			32.659,
			32.719,
			32.765,
			32.782,
			32.842,
			32.874,
			32.904,
			32.967,
			32.983,
			33.03,
			33.093,
			33.152,
			33.201,
			33.276,
			33.307,
			33.339,
			33.4,
			33.464,
			33.525,
			33.586,
			33.651,
			33.713,
			33.742,
			33.775,
			33.837,
			33.851,
			33.897,
			33.96,
			34.022,
			34.082,
			34.146,
			34.177,
			34.207,
			34.268,
			34.286,
			34.331,
			34.395,
			34.456,
			34.501,
			34.581,
			34.611,
			34.643,
			34.704,
			34.765,
			34.829,
			34.89,
			34.936,
			34.953,
			35.014,
			35.046,
			35.076,
			35.153,
			35.199,
			35.263,
			35.323,
			35.385,
			35.447,
			35.48,
			35.51,
			35.588,
			35.635,
			35.697,
			35.759,
			35.806,
			35.819,
			35.881,
			35.914,
			35.943,
			36.006,
			36.022,
			36.068,
			36.13,
			36.191,
			36.24,
			36.255,
			36.315,
			36.346,
			36.379,
			36.441,
			36.455,
			36.501,
			36.564,
			36.624,
			36.672,
			36.749,
			36.782,
			36.812,
			36.875,
			36.936,
			36.997,
			37.059,
			37.122,
			37.184,
			37.215,
			37.246,
			37.324,
			37.371,
			37.434,
			37.494,
			37.558,
			37.617,
			37.651,
			37.68,
			37.743,
			37.757,
			37.804,
			37.867,
			37.929,
			37.974,
			38.053,
			38.084,
			38.113,
			38.178,
			38.198,
			38.238,
			38.3,
			38.364,
			38.408,
			38.425,
			38.488,
			38.517,
			38.55,
			38.608,
			38.626,
			38.673,
			38.734,
			38.798,
			38.841,
			38.859,
			38.921,
			38.952,
			38.981,
			39.058,
			39.106,
			39.169,
			39.23,
			39.277,
			39.356,
			39.386,
			39.417,
			39.479,
			39.543,
			39.603,
			39.666,
			39.726,
			39.789,
			39.821,
			39.851,
			39.913,
			39.929,
			39.974,
			40.037,
			40.098,
			40.144,
			40.162,
			40.222,
			40.286,
			40.347,
			40.41,
			40.47,
			40.534,
			40.58,
			40.595,
			40.657,
			40.688,
			40.719,
			40.78,
			40.798,
			40.843,
			40.905,
			40.968,
			41.013,
			41.028,
			41.091,
			41.121,
			41.152,
			41.214,
			41.231,
			41.278,
			41.34,
			41.401,
			41.447,
			41.524,
			41.555,
			41.586,
			41.664,
			41.71,
			41.773,
			41.836,
			41.881,
			41.898,
			41.959,
			41.989,
			42.021,
			42.082,
			42.1,
			42.143,
			42.208,
			42.269,
			42.33,
			42.391,
			42.423,
			42.456,
			42.517,
			42.534,
			42.58,
			42.642,
			42.702,
			42.751,
			42.826,
			42.859,
			42.891,
			42.951,
			43.013,
			43.077,
			43.136,
			43.198,
			43.26,
			43.292,
			43.325,
			43.385,
			43.401,
			43.447,
			43.509,
			43.571,
			43.617,
			43.634,
			43.696,
			43.725,
			43.758,
			43.819,
			43.881,
			43.944,
			44.007,
			44.051,
			44.129,
			44.16,
			44.191,
			44.27,
			44.316,
			44.379,
			44.439,
			44.485,
			44.502,
			44.564,
			44.594,
			44.626,
			44.687,
			44.702,
			44.751,
			44.813,
			44.872,
			44.935,
			44.997,
			45.026,
			45.058,
			45.137,
			45.183,
			45.246,
			45.306,
			45.354,
			45.369,
			45.433,
			45.464,
			45.492,
			45.555,
			45.572,
			45.618,
			45.68,
			45.741,
			45.79,
			45.804,
			45.865,
			45.897,
			45.929,
			46.005,
			46.051,
			46.113,
			46.175,
			46.223,
			46.301,
			46.332,
			46.362,
			46.444,
			46.486,
			46.548,
			46.608,
			46.672,
			46.734,
			46.764,
			46.796,
			46.859,
			46.874,
			46.919,
			46.983,
			47.043,
			47.09,
			47.107,
			47.167,
			47.199,
			47.231,
			47.308,
			47.353,
			47.415,
			47.479,
			47.525,
			47.603,
			47.634,
			47.663,
			47.727,
			47.788,
			47.851,
			47.913,
			47.959,
			47.975,
			48.036,
			48.067,
			48.099,
			48.159,
			48.177,
			48.221,
			48.284,
			48.347,
			48.393,
			48.408,
			48.47,
			48.504,
			48.532,
			48.61,
			48.655,
			48.719,
			48.781,
			48.828,
			48.905,
			48.936,
			48.967,
			49.03,
			49.045,
			49.092,
			49.154,
			49.214,
			49.276,
			49.338,
			49.37,
			49.402,
			49.479,
			49.524,
			49.587,
			49.649,
			49.693,
			49.71,
			49.773,
			49.802,
			49.833,
			49.898,
			49.959,
			50.02,
			50.082,
			50.145,
			50.207,
			50.237,
			50.267,
			50.332,
			50.346,
			50.391,
			50.454,
			50.517,
			50.568,
			50.58,
			50.64,
			50.671,
			50.704,
			50.764,
			50.781,
			50.825,
			50.887,
			50.95,
			51.013,
			51.074,
			51.107,
			51.136,
			51.198,
			51.261,
			51.322,
			51.384,
			51.432,
			51.448,
			51.508,
			51.541,
			51.57,
			51.632,
			51.647,
			51.696,
			51.757,
			51.82,
			51.865,
			51.88,
			51.944,
			51.974,
			52.005,
			52.067,
			52.082,
			52.129,
			52.19,
			52.252,
			52.3,
			52.378,
			52.409,
			52.44,
			52.501,
			52.564,
			52.625,
			52.686,
			52.735,
			52.75,
			52.811,
			52.843,
			52.874,
			52.934,
			52.951,
			52.997,
			53.06,
			53.12,
			53.183,
			53.245,
			53.275,
			53.308,
			53.37,
			53.431,
			53.493,
			53.554,
			53.617,
			53.679,
			53.709,
			53.741,
			53.819,
			53.864,
			53.927,
			53.989,
			54.037,
			54.052,
			54.113,
			54.144,
			54.177,
			54.236,
			54.252,
			54.299,
			54.362,
			54.423,
			54.471,
			54.548,
			54.578,
			54.609,
			54.673,
			54.734,
			54.796,
			54.856,
			54.905,
			54.92,
			54.98,
			55.013,
			55.044,
			55.104,
			55.12,
			55.168,
			55.231,
			55.292,
			55.339,
			55.354,
			55.417,
			55.447,
			55.477,
			55.539,
			55.556,
			55.601,
			55.664,
			55.726,
			55.772,
			55.849,
			55.881,
			55.913,
			55.974,
			56.037,
			56.098,
			56.159,
			56.205,
			56.223,
			56.282,
			56.314,
			56.347,
			56.423,
			56.471,
			56.53,
			56.593,
			56.64,
			56.656,
			56.717,
			56.752,
			56.78,
			56.84,
			56.857,
			56.903,
			56.967,
			57.027,
			57.074,
			57.153,
			57.182,
			57.214,
			57.276,
			57.34,
			57.4,
			57.463,
			57.509,
			57.525,
			57.587,
			57.618,
			57.648,
			57.71,
			57.727,
			57.773,
			57.833,
			57.896,
			57.942,
			57.959,
			58.02,
			58.051,
			58.081,
			58.16,
			58.206,
			58.267,
			58.33,
			58.376,
			58.453,
			58.486,
			58.517,
			58.578,
			58.595,
			58.639,
			58.704,
			58.765,
			58.825,
			58.887,
			58.92,
			58.949,
			59.013,
			59.028,
			59.074,
			59.136,
			59.198,
			59.245,
			59.323,
			59.354,
			59.384,
			59.447,
			59.509,
			59.571,
			59.633,
			59.68,
			59.757,
			59.786,
			59.819,
			59.896,
			59.943,
			60.004,
			60.067,
			60.113,
			60.129,
			60.19,
			60.221,
			60.252,
			60.33,
			60.375,
			60.438,
			60.5,
			60.548,
			60.624,
			60.655,
			60.686,
			60.748,
			60.811,
			60.877,
			60.934,
			60.996,
			61.06,
			61.088,
			61.12,
			61.183,
			61.199,
			61.245,
			61.306,
			61.369,
			61.415,
			61.432,
			61.491,
			61.523,
			61.554,
			61.618,
			61.632,
			61.679,
			61.742,
			61.804,
			61.849,
			61.925,
			61.957,
			61.99,
			62.065,
			62.114,
			62.175,
			62.236,
			62.283,
			62.297,
			62.359,
			62.391,
			62.423,
			62.484,
			62.501,
			62.546,
			62.61,
			62.671,
			62.716,
			62.734,
			62.794,
			62.827,
			62.856,
			62.919,
			62.982,
			63.043,
			63.104,
			63.151,
			63.23,
			63.26,
			63.292,
			63.353,
			63.414,
			63.482,
			63.539,
			63.587,
			63.601,
			63.664,
			63.693,
			63.726,
			63.788,
			63.802,
			63.849,
			63.912,
			63.974,
			64.021,
			64.098,
			64.128,
			64.159,
			64.222,
			64.284,
			64.346,
			64.406,
			64.468,
			64.531,
			64.563,
			64.593,
			64.669,
			64.718,
			64.78,
			64.84,
			64.904,
			64.964,
			65.001,
			65.029,
			65.105,
			65.15,
			65.213,
			65.274,
			65.322,
			65.401,
			65.431,
			65.462,
			65.522,
			65.586,
			65.646,
			65.708,
			65.755,
			65.77,
			65.833,
			65.865,
			65.894,
			65.972,
			66.018,
			66.083,
			66.143,
			66.189,
			66.205,
			66.267,
			66.298,
			66.331,
			66.393,
			66.407,
			66.453,
			66.517,
			66.579,
			66.623,
			66.701,
			66.734,
			66.764,
			66.826,
			66.887,
			66.949,
			67.013,
			67.063,
			67.074,
			67.136,
			67.168,
			67.197,
			67.259,
			67.274,
			67.321,
			67.384,
			67.447,
			67.492,
			67.506,
			67.568,
			67.6,
			67.63,
			67.709,
			67.756,
			67.819,
			67.88,
			67.925,
			68.003,
			68.034,
			68.065,
			68.129,
			68.144,
			68.189,
			68.251,
			68.313,
			68.36,
			68.376,
			68.437,
			68.47,
			68.5,
			68.562,
			68.579,
			68.623,
			68.685,
			68.748,
			68.796,
			68.871,
			68.904,
			68.934,
			68.997,
			69.057,
			69.119,
			69.183,
			69.245,
			69.306,
			69.336,
			69.367,
			69.431,
			69.445,
			69.491,
			69.554,
			69.616,
			69.664,
			69.678,
			69.739,
			69.772,
			69.801,
			69.864,
			69.88,
			69.924,
			69.989,
			70.05,
			70.098,
			70.174,
			70.206,
			70.237,
			70.313,
			70.359,
			70.423,
			70.484,
			70.547,
			70.609,
			70.64,
			70.669,
			70.733,
			70.746,
			70.795,
			70.856,
			70.918,
			70.964,
			70.98,
			71.041,
			71.072,
			71.104,
			71.186,
			71.227,
			71.289,
			71.353,
			71.398,
			71.476,
			71.509,
			71.537,
			71.601,
			71.662,
			71.726,
			71.786,
			71.833,
			71.848,
			71.912,
			71.943,
			71.973,
			72.034,
			72.05,
			72.097,
			72.16,
			72.219,
			72.268,
			72.283,
			72.345,
			72.375,
			72.407,
			72.467,
			72.483,
			72.53,
			72.592,
			72.653,
			72.701,
			72.78,
			72.809,
			72.839,
			72.901,
			72.964,
			73.026,
			73.09,
			73.136,
			73.15,
			73.213,
			73.248,
			73.275,
			73.336,
			73.353,
			73.398,
			73.462,
			73.524,
			73.569,
			73.647,
			73.679,
			73.71,
			73.771,
			73.833,
			73.895,
			73.957,
			74.003,
			74.019,
			74.081,
			74.113,
			74.143,
			74.206,
			74.219,
			74.266,
			74.33,
			74.392,
			74.436,
			74.454,
			74.514,
			74.546,
			74.578,
			74.64,
			74.656,
			74.7,
			74.765,
			74.826,
			74.873,
			74.949,
			74.98,
			75.012,
			75.072,
			75.136,
			75.197,
			75.259,
			75.309,
			75.322,
			75.383,
			75.414,
			75.446,
			75.507,
			75.524,
			75.568,
			75.63,
			75.691,
			75.755,
			75.816,
			75.848,
			75.88,
			75.94,
			75.958,
			76.004,
			76.066,
			76.128,
			76.173,
			76.252,
			76.283,
			76.313,
			76.392,
			76.436,
			76.5,
			76.56,
			76.624,
			76.686,
			76.715,
			76.747,
			76.809,
			76.825,
			76.87,
			76.935,
			76.996,
			77.041,
			77.059,
			77.118,
			77.151,
			77.18,
			77.243,
			77.259,
			77.307,
			77.372,
			77.43,
			77.475,
			77.554,
			77.585,
			77.616,
			77.691,
			77.739,
			77.803,
			77.864,
			77.909,
			77.927,
			77.987,
			78.019,
			78.049,
			78.128,
			78.172,
			78.236,
			78.298,
			78.345,
			78.423,
			78.452,
			78.483,
			78.547,
			78.608,
			78.669,
			78.731,
			78.793,
			78.856,
			78.886,
			78.918,
			78.98,
			78.995,
			79.04,
			79.103,
			79.167,
			79.213,
			79.229,
			79.29,
			79.319,
			79.352,
			79.413,
			79.436,
			79.477,
			79.539,
			79.6,
			79.662,
			79.725,
			79.755,
			79.786,
			79.848,
			79.909,
			79.973,
			80.034,
			80.08,
			80.097,
			80.158,
			80.19,
			80.219,
			80.283,
			80.297,
			80.343,
			80.406,
			80.467,
			80.513,
			80.531,
			80.591,
			80.622,
			80.654,
			80.717,
			80.731,
			80.778,
			80.84,
			80.903,
			80.965,
			81.026,
			81.056,
			81.089,
			81.151,
			81.212,
			81.276,
			81.335,
			81.381,
			81.399,
			81.46,
			81.497,
			81.522,
			81.584,
			81.599,
			81.646,
			81.707,
			81.769,
			81.818,
			81.832,
			81.896,
			81.924,
			81.955,
			82.019,
			82.081,
			82.142,
			82.203,
			82.266,
			82.329,
			82.358,
			82.39,
			82.452,
			82.515,
			82.576,
			82.639,
			82.7,
			82.763,
			82.792,
			82.825,
			82.887,
			82.902,
			82.948,
			83.011,
			83.071,
			83.12,
			83.197,
			83.226,
			83.26,
			83.337,
			83.383,
			83.445,
			83.508,
			83.567,
			83.631,
			83.66,
			83.693,
			83.753,
			83.771,
			83.815,
			83.88,
			83.942,
			84.004,
			84.064,
			84.093,
			84.126,
			84.188,
			84.206,
			84.252,
			84.313,
			84.374,
			84.438,
			84.499,
			84.53,
			84.559,
			84.623,
			84.685,
			84.75,
			84.809,
			84.87,
			84.934,
			84.964,
			84.995,
			85.057,
			85.072,
			85.119,
			85.182,
			85.241,
			85.289,
			85.306,
			85.368,
			85.399,
			85.429,
			85.49,
			85.505,
			85.553,
			85.619,
			85.678,
			85.723,
			85.802,
			85.832,
			85.863,
			85.925,
			85.987,
			86.05,
			86.11,
			86.157,
			86.174,
			86.236,
			86.267,
			86.297,
			86.374,
			86.422,
			86.484,
			86.545,
			86.591,
			86.606,
			86.669,
			86.701,
			86.73,
			86.791,
			86.808,
			86.855,
			86.916,
			86.979,
			87.025,
			87.103,
			87.133,
			87.165,
			87.228,
			87.289,
			87.351,
			87.414,
			87.46,
			87.474,
			87.537,
			87.567,
			87.6,
			87.662,
			87.681,
			87.724,
			87.784,
			87.847,
			87.893,
			87.907,
			87.97,
			88.001,
			88.033,
			88.109,
			88.156,
			88.219,
			88.28,
			88.329,
			88.407,
			88.437,
			88.467,
			88.53,
			88.545,
			88.592,
			88.654,
			88.717,
			88.76,
			88.778,
			88.841,
			88.871,
			88.901,
			88.964,
			88.98,
			89.025,
			89.087,
			89.151,
			89.196,
			89.273,
			89.304,
			89.335,
			89.398,
			89.46,
			89.521,
			89.584,
			89.645,
			89.707,
			89.742,
			89.77,
			89.833,
			89.847,
			89.893,
			89.956,
			90.019,
			90.064,
			90.08,
			90.142,
			90.174,
			90.204,
			90.265,
			90.282,
			90.327,
			90.391,
			90.452,
			90.499,
			90.576,
			90.607,
			90.638,
			90.699,
			90.761,
			90.824,
			90.887,
			90.931,
			90.948,
			91.008,
			91.04,
			91.071,
			91.135,
			91.149,
			91.197,
			91.257,
			91.319,
			91.382,
			91.443,
			91.475,
			91.507,
			91.569,
			91.63,
			91.694,
			91.755,
			91.817,
			91.878,
			91.91,
			91.94,
			92.018,
			92.065,
			92.126,
			92.189,
			92.235,
			92.249,
			92.312,
			92.343,
			92.374,
			92.437,
			92.451,
			92.498,
			92.563,
			92.623,
			92.667,
			92.685,
			92.746,
			92.778,
			92.807,
			92.885,
			92.933,
			92.993,
			93.057,
			93.101,
			93.119,
			93.18,
			93.211,
			93.242,
			93.304,
			93.32,
			93.365,
			93.426,
			93.49,
			93.551,
			93.615,
			93.645,
			93.675,
			93.754,
			93.8,
			93.867,
			93.925,
			93.972,
			94.049,
			94.079,
			94.109,
			94.186,
			94.235,
			94.296,
			94.359,
			94.405,
			94.422,
			94.483,
			94.513,
			94.545,
			94.623,
			94.669,
			94.732,
			94.794,
			94.84,
			94.854,
			94.917,
			94.947,
			94.976,
			95.055,
			95.103,
			95.164,
			95.17,
			95.226,
			95.29,
			95.351,
			95.382,
			95.413,
			95.473,
			95.537,
			95.598,
			95.66,
			95.708,
			95.724,
			95.784,
			95.814,
			95.847,
			95.908,
			95.929,
			95.97,
			96.032,
			96.093,
			96.141,
			96.156,
			96.22,
			96.248,
			96.28,
			96.359,
			96.404,
			96.468,
			96.53,
			96.576,
			96.652,
			96.684,
			96.715,
			96.793,
			96.838,
			96.906,
			96.963,
			97.01,
			97.024,
			97.086,
			97.118,
			97.15,
			97.209,
			97.226,
			97.272,
			97.336,
			97.398,
			97.443,
			97.458,
			97.52,
			97.552,
			97.584,
			97.66,
			97.708,
			97.769,
			97.832,
			97.875,
			97.954,
			97.99,
			98.017,
			98.079,
			98.141,
			98.204,
			98.265,
			98.327,
			98.39,
			98.421,
			98.451,
			98.529,
			98.576,
			98.64,
			98.698,
			98.743,
			98.762,
			98.822,
			98.855,
			98.884,
			98.963,
			99.008,
			99.073,
			99.134,
			99.18,
			99.258,
			99.288,
			99.318,
			99.381,
			99.397,
			99.442,
			99.506,
			99.566,
			99.613,
			99.63,
			99.69,
			99.723,
			99.753,
			99.816,
			99.83,
			99.876,
			99.939,
			100.002,
			100.065,
			100.125,
			100.156,
			100.186,
			100.249,
			100.312,
			100.379,
			100.435,
			100.481,
			100.498,
			100.56,
			100.591,
			100.622,
			100.684,
			100.7,
			100.746,
			100.81,
			100.87,
			100.917,
			100.931,
			100.994,
			101.026,
			101.056,
			101.116,
			101.133,
			101.181,
			101.243,
			101.303,
			101.364,
			101.429,
			101.458,
			101.489,
			101.551,
			101.613,
			101.676,
			101.738,
			101.783,
			101.8,
			101.863,
			101.891,
			101.925,
			101.984,
			102.001,
			102.048,
			102.116,
			102.171,
			102.219,
			102.296,
			102.327,
			102.358,
			102.421,
			102.481,
			102.545,
			102.605,
			102.652,
			102.669,
			102.731,
			102.762,
			102.792,
			102.854,
			102.87,
			102.915,
			102.979,
			103.041,
			103.087,
			103.102,
			103.165,
			103.195,
			103.226,
			103.244
		]
	],
	'6136_6137_7982': [
		[
			57.94
		],
		[
			0
		],
		[
			0,
			1.202,
			2.552,
			5.256,
			6.611,
			7.966,
			12.046,
			13.41,
			14.776,
			18.885,
			21.633,
			25.768,
			28.532,
			31.307,
			32.697,
			36.875,
			39.668,
			42.468,
			46.68,
			49.497,
			53.735,
			55.151,
			56.568,
			60.831,
			63.681,
			67.969,
			70.836,
			75.148,
			78.031,
			79.476,
			82.369,
			86.72,
			88.174,
			92.545,
			96.931,
			98.396,
			99.863,
			102.801,
			105.745,
			107.219,
			110.173,
			111.652,
			114.615,
			119.071,
			122.05,
			125.035,
			129.523,
			131.023,
			132.524,
			137.035,
			140.049,
			144.583,
			147.613,
			152.17,
			155.214,
			156.739,
			159.793,
			164.385,
			167.454,
			170.528,
			175.15,
			176.693,
			178.238,
			181.332,
			184.43,
			185.98,
			189.075,
			190.616,
			193.682,
			198.218,
			201.193,
			204.13,
			208.463,
			209.889,
			211.304,
			215.493,
			216.87,
			218.237,
			222.283,
			224.932,
			227.544,
			228.835,
			231.39,
			232.654,
			235.152,
			237.613,
			238.83,
			241.237,
			243.61,
			247.104,
			249.386,
			252.736,
			253.834,
			254.921,
			258.129,
			260.223,
			262.28,
			265.296,
			267.262,
			270.14,
			271.081,
			272.013,
			275.652,
			276.54,
			279.155,
			280.868,
			282.531,
			283.349,
			284.959,
			286.532,
			287.305,
			289.579,
			291.067,
			292.532,
			294.701,
			296.138,
			298.268,
			298.973,
			299.681,
			301.803,
			303.21,
			305.322,
			306.732,
			308.837,
			310.242,
			312.347,
			313.745,
			314.444,
			315.839,
			317.229,
			318.619,
			320.01,
			320.71,
			322.108,
			323.506,
			324.206,
			325.614,
			326.32,
			327.733,
			329.146,
			331.301,
			332.746,
			334.911,
			335.665,
			336.424,
			338.693,
			340.205,
			342.577,
			344.148,
			345.704,
			346.463,
			347.97,
			348.72,
			350.216,
			351.63,
			352.331,
			353.731,
			355.129,
			357.163,
			357.832,
			358.5,
			360.498,
			361.16,
			361.818,
			363.776,
			365.072,
			366.351,
			368.241,
			369.494,
			371.411,
			372.068,
			372.724,
			374.688,
			376.008,
			378.045,
			379.405,
			380.768,
			381.451,
			382.818,
			383.502,
			384.871,
			386.241,
			386.927,
			388.303,
			389.684,
			391.75,
			393.144,
			395.262,
			395.976,
			396.691,
			398.859,
			400.335,
			402.562,
			404.065,
			406.349,
			407.897,
			408.664,
			410.233,
			411.818,
			412.615,
			413.416,
			415.84,
			417.496,
			419.157,
			419.992,
			421.675,
			423.4,
			424.261,
			425.992,
			426.866,
			428.641,
			430.427,
			433.117,
			434.917,
			437.633,
			438.539,
			439.448,
			442.195,
			444.05,
			446.853,
			448.74,
			451.597,
			453.501,
			454.465,
			456.403,
			458.351,
			459.329,
			460.327,
			463.288,
			465.274,
			467.274,
			468.279,
			470.297,
			472.328,
			473.343,
			475.382,
			476.406,
			478.463,
			480.531,
			483.655,
			485.755,
			488.932,
			489.996,
			491.059,
			494.252,
			496.414,
			499.702,
			501.918,
			504.188,
			505.326,
			507.609,
			508.75,
			511.037,
			513.328,
			514.464,
			516.743,
			519.019,
			522.429,
			523.567,
			524.702,
			528.121,
			529.254,
			530.39,
			532.669,
			533.809,
			536.092,
			538.366,
			541.826,
			544.146,
			547.648,
			548.82,
			549.995,
			553.556,
			555.934,
			559.527,
			561.945,
			564.38,
			565.61,
			568.078,
			569.322,
			571.848,
			574.388,
			575.677,
			578.261,
			580.875,
			584.828,
			587.476,
			591.459,
			592.789,
			594.121,
			598.096,
			600.744,
			604.708,
			607.326,
			609.943,
			613.86,
			615.165,
			617.774,
			621.675,
			622.968,
			626.815,
			629.348,
			631.834,
			633.061,
			635.463,
			637.822,
			638.988,
			641.284,
			642.414,
			644.647,
			646.843,
			650.068,
			652.174,
			655.266,
			656.279,
			657.284,
			660.257,
			662.189,
			665.02,
			666.867,
			668.684,
			669.58,
			671.346,
			672.221,
			673.94,
			675.623,
			676.454,
			678.098,
			679.716,
			681.314,
			682.899,
			683.685,
			685.248,
			686.804,
			687.582,
			689.914,
			691.472,
			693.036,
			695.386,
			696.961,
			699.344,
			700.14,
			700.936,
			703.344,
			704.96,
			707.403,
			709.045,
			710.7,
			711.532,
			713.213,
			714.058,
			715.758,
			717.47,
			718.33,
			720.058,
			721.797,
			724.417,
			725.296,
			726.178,
			728.844,
			729.737,
			730.633,
			732.436,
			733.334,
			735.138,
			736.957,
			739.717,
			741.574,
			744.388,
			745.334,
			746.282,
			749.148,
			751.073,
			753.991,
			755.958,
			757.937,
			758.93,
			760.925,
			762.915,
			763.911,
			765.914,
			766.92,
			768.928,
			770.939,
			773.977,
			776.015,
			779.096,
			780.13,
			781.168,
			783.252,
			784.297,
			786.386,
			789.531,
			791.645,
			793.772,
			797.023,
			798.107,
			799.203,
			802.541,
			803.66,
			804.796,
			808.221,
			810.513,
			813.968,
			816.276,
			818.595,
			819.768,
			822.133,
			823.319,
			825.733,
			828.175,
			831.86,
			834.327,
			837.992,
			839.217,
			840.426,
			844.039,
			846.425,
			849.989,
			852.353,
			854.718,
			855.898,
			858.256,
			859.436,
			861.791,
			865.32,
			867.673,
			870.03,
			873.582,
			874.751,
			875.941,
			878.325,
			880.717,
			881.92,
			884.336,
			885.546,
			887.974,
			890.402,
			894.011,
			896.383,
			899.889,
			901.041,
			902.182,
			905.541,
			907.732,
			910.937,
			913.026,
			915.078,
			916.089,
			918.084,
			919.068,
			921.006,
			922.908,
			923.844,
			925.689,
			927.498,
			930.148,
			931.871,
			934.385,
			935.204,
			936.014,
			938.388,
			939.925,
			941.424,
			943.61,
			945.038,
			947.146,
			947.855,
			948.55,
			950.625,
			952.001,
			954.057,
			955.415,
			957.436,
			958.777,
			960.114,
			960.779,
			962.104,
			962.765,
			964.111,
			965.488,
			967.542,
			968.933,
			971.152,
			971.89,
			972.628,
			974.895,
			976.438,
			977.982,
			980.284,
			981.81,
			984.104,
			984.868,
			985.631,
			987.918,
			988.682,
			989.444,
			991.708,
			993.213,
			994.709,
			995.449,
			996.926,
			998.404,
			999.145,
			1000.627,
			1001.368,
			1002.851,
			1004.343,
			1006.596,
			1008.099,
			1010.354,
			1011.103,
			1011.851,
			1014.098,
			1015.57,
			1017.767,
			1019.232,
			1021.42,
			1022.877,
			1023.605,
			1025.078,
			1027.326,
			1028.076,
			1030.342,
			1031.867,
			1033.396,
			1034.163,
			1035.69,
			1037.219,
			1037.986,
			1039.527,
			1040.297,
			1041.831,
			1043.394,
			1045.777,
			1047.369,
			1049.78,
			1050.587,
			1051.398,
			1053.832,
			1055.481,
			1057.975,
			1059.642,
			1061.31,
			1062.147,
			1063.834,
			1064.683,
			1066.39,
			1068.111,
			1068.976,
			1070.717,
			1072.464,
			1075.071,
			1075.934,
			1076.793,
			1078.495,
			1080.17,
			1080.995,
			1082.618,
			1083.416,
			1084.984,
			1086.516,
			1088.745,
			1090.187,
			1092.282,
			1092.963,
			1093.636,
			1095.606,
			1096.882,
			1098.73,
			1099.922,
			1101.089,
			1101.664,
			1102.791,
			1103.353,
			1104.441,
			1105.506,
			1106.029,
			1107.062,
			1108.082,
			1109.594,
			1110.598,
			1112.098,
			1112.595,
			1113.092,
			1114.578,
			1115.573,
			1117.075,
			1118.071,
			1119.065,
			1120.561,
			1121.067,
			1121.571,
			1123.075,
			1123.575,
			1124.074,
			1125.572,
			1126.606,
			1128.143,
			1129.159,
			1130.175,
			1130.684,
			1132.244,
			1133.272,
			1134.292,
			1135.814,
			1136.863,
			1138.421,
			1138.935,
			1139.447,
			1140.978,
			1142.072,
			1143.669,
			1144.714,
			1145.757,
			1146.281,
			1147.358,
			1147.893,
			1148.959,
			1150.022,
			1150.553,
			1151.083,
			1152.723,
			1153.79,
			1154.846,
			1155.374,
			1156.432,
			1157.477,
			1157.996,
			1159.543,
			1160.574,
			1161.612,
			1163.177,
			1164.21,
			1165.752,
			1166.265,
			1166.779,
			1168.312,
			1169.335,
			1170.876,
			1171.911,
			1172.946,
			1173.462,
			1174.493,
			1175.009,
			1176.046,
			1177.08,
			1177.594,
			1178.627,
			1179.667,
			1181.238,
			1181.767,
			1182.304,
			1183.379,
			1184.462,
			1185.007,
			1186.105,
			1186.659,
			1187.793,
			1188.939,
			1190.68,
			1191.859,
			1193.657,
			1194.263,
			1194.874,
			1196.729,
			1197.993,
			1199.92,
			1201.224,
			1202.545,
			1203.199,
			1204.543,
			1205.902,
			1206.588,
			1208.666,
			1210.07,
			1211.491,
			1213.649,
			1215.107,
			1217.326,
			1218.076,
			1218.83,
			1221.116,
			1222.675,
			1224.242,
			1226.613,
			1228.207,
			1229.006,
			1230.608,
			1231.409,
			1232.211,
			1234.621,
			1235.423,
			1236.225,
			1238.626,
			1240.22,
			1241.808,
			1242.601,
			1244.194,
			1244.988,
			1246.572,
			1248.974,
			1250.6,
			1252.219,
			1254.745,
			1256.45,
			1259.038,
			1259.918,
			1260.796,
			1263.425,
			1265.198,
			1267.85,
			1269.622,
			1272.275,
			1274.035,
			1274.908,
			1275.781,
			1278.402,
			1279.245,
			1280.089,
			1282.623,
			1284.272,
			1285.888,
			1286.697,
			1288.321,
			1289.9,
			1290.688,
			1292.27,
			1293.064,
			1294.641,
			1296.216,
			1298.595,
			1300.203,
			1302.631,
			1303.446,
			1304.27,
			1306.761,
			1308.435,
			1310.96,
			1312.644,
			1314.343,
			1315.2,
			1316.923,
			1317.79,
			1319.538,
			1322.192,
			1323.976,
			1325.755,
			1327.546,
			1329.35,
			1330.256,
			1332.079,
			1333.899,
			1334.821,
			1336.675,
			1337.604,
			1339.463,
			1341.31,
			1344.036,
			1345.808,
			1348.397,
			1349.242,
			1350.081,
			1353.346,
			1354.141,
			1356.501,
			1358.04,
			1359.561,
			1360.319,
			1361.827,
			1363.327,
			1364.076,
			1365.57,
			1366.314,
			1367.798,
			1369.275,
			1371.47,
			1372.196,
			1372.92,
			1375.082,
			1375.799,
			1376.514,
			1378.65,
			1380.07,
			1382.219,
			1383.644,
			1385.073,
			1387.297,
			1388.037,
			1388.778,
			1391.082,
			1391.882,
			1392.68,
			1395.079,
			1396.71,
			1399.148,
			1400.76,
			1402.349,
			1403.144,
			1404.739,
			1405.521,
			1407.051,
			1408.581,
			1410.88,
			1412.405,
			1414.695,
			1415.462,
			1416.226,
			1418.511,
			1420.037,
			1422.294,
			1423.793,
			1425.295,
			1427.532,
			1428.277,
			1429.023,
			1431.267,
			1432.012,
			1432.759,
			1435.007,
			1436.515,
			1438.791,
			1440.319,
			1441.872,
			1442.66,
			1444.245,
			1445.042,
			1446.656,
			1448.303,
			1450.797,
			1452.482,
			1455.04,
			1455.899,
			1456.76,
			1459.356,
			1461.104,
			1463.699,
			1465.454,
			1467.217,
			1468.101,
			1469.879,
			1470.773,
			1472.57,
			1475.286,
			1477.102,
			1478.917,
			1481.622,
			1483.393,
			1485.139,
			1486.853,
			1487.697,
			1490.17,
			1491.773,
			1493.344,
			1495.632,
			1497.114,
			1499.297,
			1500.032,
			1500.754,
			1502.917,
			1504.382,
			1506.608,
			1508.094,
			1509.583,
			1510.327,
			1511.815,
			1512.561,
			1514.057,
			1515.549,
			1516.294,
			1517.792,
			1519.301,
			1521.566,
			1523.075,
			1525.338,
			1526.092,
			1526.846,
			1528.358,
			1529.114,
			1530.623,
			1532.128,
			1534.385,
			1535.885,
			1538.132,
			1538.876,
			1539.618,
			1541.841,
			1543.331,
			1545.573,
			1547.07,
			1549.344,
			1550.86,
			1552.372,
			1553.143,
			1554.671,
			1555.429,
			1556.935,
			1558.485,
			1560.82,
			1562.344,
			1564.712,
			1565.489,
			1566.256,
			1568.538,
			1570.037,
			1572.208,
			1573.588,
			1574.922,
			1575.578,
			1576.875,
			1577.516,
			1578.719,
			1579.895,
			1580.478,
			1581.635,
			1582.783,
			1583.905,
			1585.577,
			1586.688,
			1587.8,
			1588.354,
			1589.46,
			1590.012,
			1591.115,
			1592.219,
			1593.895,
			1595.019,
			1596.71,
			1597.277,
			1597.844,
			1599.651,
			1600.258,
			1600.867,
			1602.705,
			1603.964,
			1605.885,
			1607.176,
			1607.825,
			1609.128,
			1610.434,
			1611.089,
			1612.406,
			1613.707,
			1615.596,
			1616.23,
			1616.868,
			1618.776,
			1619.397,
			1620.022,
			1621.279,
			1621.913,
			1623.189,
			1624.462,
			1626.389,
			1627.691,
			1629.688,
			1630.363,
			1631.041,
			1633.098,
			1634.52,
			1636.686,
			1638.149,
			1639.627,
			1640.371,
			1641.871,
			1642.627,
			1644.146,
			1645.674,
			1646.444,
			1647.995,
			1649.565,
			1651.95,
			1652.753,
			1653.56,
			1656.004,
			1656.826,
			1657.651,
			1660.122,
			1661.787,
			1663.458,
			1665.948,
			1667.594,
			1670.053,
			1670.86,
			1671.662,
			1674.843,
			1675.633,
			1677.989,
			1679.556,
			1681.116,
			1681.892,
			1683.435,
			1684.967,
			1685.727,
			1687.241,
			1687.995,
			1689.501,
			1690.998,
			1693.229,
			1694.73,
			1696.995,
			1697.746,
			1698.497,
			1700.823,
			1702.375,
			1704.703,
			1706.282,
			1707.839,
			1710.153,
			1710.913,
			1711.668,
			1714.661,
			1715.4,
			1717.599,
			1719.054,
			1721.201,
			1722.611,
			1724.009,
			1724.704,
			1726.081,
			1726.764,
			1728.122,
			1729.469,
			1731.478,
			1732.801,
			1734.765,
			1735.416,
			1736.064,
			1737.995,
			1739.269,
			1741.164,
			1742.416,
			1743.66,
			1744.282,
			1745.537,
			1746.167,
			1747.419,
			1749.33,
			1750.596,
			1751.854,
			1753.104,
			1754.986,
			1756.246,
			1757.504,
			1758.132,
			1760.024,
			1761.306,
			1762.588,
			1764.511,
			1765.817,
			1767.804,
			1768.474,
			1769.149,
			1771.203,
			1772.599,
			1774.728,
			1776.166,
			1777.622,
			1778.357,
			1779.842,
			1780.592,
			1782.107,
			1783.632,
			1784.397,
			1785.945,
			1787.515,
			1789.887,
			1790.695,
			1791.512,
			1793.989,
			1794.836,
			1795.677,
			1798.228,
			1799.949,
			1802.529,
			1804.264,
			1806.001,
			1808.602,
			1809.475,
			1810.347,
			1812.922,
			1813.784,
			1814.65,
			1817.237,
			1818.981,
			1821.638,
			1823.417,
			1825.209,
			1826.112,
			1828.85,
			1830.692,
			1832.555,
			1835.375,
			1837.27,
			1840.118,
			1841.094,
			1842.055,
			1844.956,
			1846.904,
			1849.844,
			1851.82,
			1853.808,
			1856.794,
			1857.799,
			1858.808,
			1862.868,
			1863.889,
			1866.983,
			1869.027,
			1871.098,
			1872.137,
			1874.224,
			1876.32,
			1877.369,
			1880.514,
			1882.618,
			1884.732,
			1887.922,
			1890.062,
			1893.293,
			1894.376,
			1895.462,
			1898.736,
			1900.932,
			1904.24,
			1906.457,
			1909.796,
			1912.034,
			1913.157,
			1915.41,
			1917.672,
			1918.808,
			1921.086,
			1923.374,
			1926.828,
			1927.984,
			1929.142,
			1931.467,
			1933.802,
			1934.972,
			1937.322,
			1938.499,
			1940.862,
			1943.234,
			1946.809,
			1949.204,
			1952.814,
			1954.022,
			1955.231,
			1958.875,
			1961.316,
			1964.994,
			1967.457,
			1971.169,
			1973.655,
			1974.901,
			1977.399,
			1981.163,
			1983.683,
			1986.213,
			1990.022,
			1991.296,
			1992.572,
			1996.412,
			1997.697,
			1998.983,
			2002.854,
			2005.445,
			2008.043,
			2011.955,
			2014.565,
			2018.487,
			2019.799,
			2021.112,
			2025.062,
			2027.704,
			2031.681,
			2034.342,
			2037.009,
			2038.345,
			2041.023,
			2043.708,
			2045.053,
			2047.371
		],
		[
			0,
			0.022,
			0.033,
			0.096,
			0.126,
			0.156,
			0.221,
			0.236,
			0.281,
			0.343,
			0.407,
			0.466,
			0.53,
			0.56,
			0.593,
			0.668,
			0.715,
			0.779,
			0.84,
			0.903,
			0.962,
			0.996,
			1.027,
			1.101,
			1.149,
			1.213,
			1.274,
			1.335,
			1.396,
			1.428,
			1.459,
			1.539,
			1.582,
			1.647,
			1.709,
			1.755,
			1.771,
			1.833,
			1.863,
			1.894,
			1.956,
			1.973,
			2.018,
			2.085,
			2.141,
			2.189,
			2.267,
			2.296,
			2.329,
			2.39,
			2.452,
			2.513,
			2.575,
			2.638,
			2.701,
			2.73,
			2.762,
			2.839,
			2.887,
			2.95,
			3.011,
			3.058,
			3.072,
			3.133,
			3.164,
			3.195,
			3.257,
			3.274,
			3.321,
			3.384,
			3.445,
			3.507,
			3.568,
			3.6,
			3.629,
			3.691,
			3.709,
			3.755,
			3.816,
			3.877,
			3.924,
			3.941,
			4,
			4.032,
			4.063,
			4.127,
			4.147,
			4.188,
			4.251,
			4.313,
			4.359,
			4.437,
			4.468,
			4.498,
			4.56,
			4.623,
			4.683,
			4.746,
			4.807,
			4.871,
			4.902,
			4.932,
			5.01,
			5.057,
			5.118,
			5.179,
			5.228,
			5.243,
			5.306,
			5.336,
			5.367,
			5.444,
			5.491,
			5.552,
			5.615,
			5.662,
			5.738,
			5.769,
			5.8,
			5.877,
			5.923,
			5.988,
			6.049,
			6.111,
			6.173,
			6.235,
			6.296,
			6.313,
			6.358,
			6.421,
			6.482,
			6.53,
			6.546,
			6.607,
			6.638,
			6.666,
			6.73,
			6.747,
			6.791,
			6.856,
			6.916,
			6.979,
			7.041,
			7.071,
			7.104,
			7.18,
			7.225,
			7.288,
			7.351,
			7.398,
			7.412,
			7.476,
			7.506,
			7.537,
			7.597,
			7.613,
			7.661,
			7.725,
			7.786,
			7.831,
			7.847,
			7.91,
			7.939,
			7.972,
			8.048,
			8.094,
			8.156,
			8.218,
			8.282,
			8.342,
			8.373,
			8.405,
			8.468,
			8.529,
			8.592,
			8.654,
			8.7,
			8.716,
			8.778,
			8.808,
			8.839,
			8.901,
			8.918,
			8.962,
			9.026,
			9.088,
			9.149,
			9.212,
			9.242,
			9.273,
			9.351,
			9.397,
			9.459,
			9.522,
			9.584,
			9.644,
			9.675,
			9.708,
			9.768,
			9.785,
			9.832,
			9.892,
			9.955,
			10.003,
			10.016,
			10.079,
			10.109,
			10.142,
			10.204,
			10.217,
			10.265,
			10.331,
			10.39,
			10.453,
			10.512,
			10.545,
			10.574,
			10.637,
			10.7,
			10.765,
			10.825,
			10.885,
			10.948,
			10.98,
			11.01,
			11.072,
			11.088,
			11.132,
			11.196,
			11.257,
			11.304,
			11.318,
			11.382,
			11.413,
			11.445,
			11.506,
			11.521,
			11.567,
			11.629,
			11.691,
			11.754,
			11.815,
			11.846,
			11.878,
			11.941,
			12,
			12.064,
			12.126,
			12.172,
			12.188,
			12.251,
			12.281,
			12.312,
			12.373,
			12.393,
			12.436,
			12.498,
			12.559,
			12.606,
			12.621,
			12.684,
			12.715,
			12.745,
			12.807,
			12.823,
			12.868,
			12.933,
			12.992,
			13.041,
			13.117,
			13.147,
			13.18,
			13.24,
			13.305,
			13.365,
			13.429,
			13.474,
			13.491,
			13.552,
			13.582,
			13.615,
			13.676,
			13.692,
			13.738,
			13.799,
			13.863,
			13.91,
			13.988,
			14.018,
			14.046,
			14.127,
			14.172,
			14.234,
			14.297,
			14.357,
			14.421,
			14.456,
			14.483,
			14.56,
			14.605,
			14.667,
			14.73,
			14.778,
			14.792,
			14.854,
			14.883,
			14.915,
			14.978,
			14.993,
			15.039,
			15.101,
			15.163,
			15.226,
			15.289,
			15.318,
			15.351,
			15.411,
			15.473,
			15.538,
			15.598,
			15.645,
			15.659,
			15.721,
			15.751,
			15.784,
			15.845,
			15.861,
			15.908,
			15.971,
			16.031,
			16.078,
			16.095,
			16.156,
			16.189,
			16.219,
			16.297,
			16.342,
			16.405,
			16.465,
			16.529,
			16.591,
			16.621,
			16.651,
			16.715,
			16.775,
			16.838,
			16.9,
			16.946,
			16.962,
			17.023,
			17.057,
			17.088,
			17.148,
			17.165,
			17.212,
			17.272,
			17.335,
			17.382,
			17.398,
			17.459,
			17.489,
			17.521,
			17.582,
			17.598,
			17.646,
			17.706,
			17.77,
			17.815,
			17.893,
			17.924,
			17.954,
			18.018,
			18.078,
			18.142,
			18.203,
			18.248,
			18.263,
			18.327,
			18.358,
			18.389,
			18.45,
			18.466,
			18.512,
			18.576,
			18.635,
			18.684,
			18.76,
			18.792,
			18.824,
			18.883,
			18.899,
			18.947,
			19.009,
			19.072,
			19.132,
			19.196,
			19.227,
			19.256,
			19.317,
			19.335,
			19.381,
			19.442,
			19.505,
			19.566,
			19.63,
			19.66,
			19.691,
			19.753,
			19.77,
			19.815,
			19.878,
			19.939,
			19.985,
			20.062,
			20.095,
			20.126,
			20.187,
			20.248,
			20.312,
			20.372,
			20.42,
			20.436,
			20.498,
			20.528,
			20.559,
			20.643,
			20.684,
			20.745,
			20.808,
			20.854,
			20.868,
			20.93,
			20.963,
			20.992,
			21.054,
			21.072,
			21.116,
			21.179,
			21.24,
			21.289,
			21.365,
			21.397,
			21.428,
			21.488,
			21.55,
			21.613,
			21.676,
			21.721,
			21.738,
			21.798,
			21.829,
			21.861,
			21.923,
			21.939,
			21.983,
			22.048,
			22.111,
			22.172,
			22.233,
			22.266,
			22.297,
			22.358,
			22.42,
			22.483,
			22.543,
			22.606,
			22.667,
			22.701,
			22.731,
			22.792,
			22.854,
			22.918,
			22.978,
			23.039,
			23.102,
			23.134,
			23.165,
			23.225,
			23.241,
			23.289,
			23.35,
			23.412,
			23.459,
			23.534,
			23.566,
			23.598,
			23.661,
			23.722,
			23.782,
			23.846,
			23.892,
			23.968,
			24.001,
			24.032,
			24.092,
			24.109,
			24.155,
			24.218,
			24.279,
			24.326,
			24.343,
			24.405,
			24.434,
			24.465,
			24.527,
			24.543,
			24.59,
			24.651,
			24.715,
			24.764,
			24.838,
			24.867,
			24.901,
			24.979,
			25.024,
			25.085,
			25.148,
			25.21,
			25.273,
			25.304,
			25.335,
			25.412,
			25.457,
			25.521,
			25.581,
			25.63,
			25.644,
			25.706,
			25.737,
			25.769,
			25.831,
			25.845,
			25.891,
			25.955,
			26.017,
			26.061,
			26.141,
			26.171,
			26.203,
			26.264,
			26.327,
			26.39,
			26.45,
			26.495,
			26.512,
			26.574,
			26.605,
			26.636,
			26.699,
			26.713,
			26.76,
			26.827,
			26.884,
			26.931,
			26.945,
			27.008,
			27.04,
			27.071,
			27.133,
			27.146,
			27.195,
			27.256,
			27.318,
			27.363,
			27.442,
			27.473,
			27.505,
			27.581,
			27.629,
			27.692,
			27.754,
			27.799,
			27.816,
			27.878,
			27.907,
			27.938,
			28.001,
			28.015,
			28.064,
			28.123,
			28.187,
			28.248,
			28.313,
			28.343,
			28.374,
			28.434,
			28.498,
			28.561,
			28.622,
			28.668,
			28.744,
			28.776,
			28.806,
			28.869,
			28.888,
			28.932,
			28.994,
			29.055,
			29.115,
			29.18,
			29.21,
			29.241,
			29.318,
			29.365,
			29.427,
			29.488,
			29.534,
			29.612,
			29.644,
			29.674,
			29.736,
			29.8,
			29.861,
			29.922,
			29.967,
			29.985,
			30.045,
			30.077,
			30.108,
			30.171,
			30.185,
			30.231,
			30.294,
			30.358,
			30.403,
			30.42,
			30.482,
			30.513,
			30.544,
			30.621,
			30.667,
			30.728,
			30.792,
			30.853,
			30.914,
			30.949,
			30.976,
			31.038,
			31.1,
			31.163,
			31.226,
			31.272,
			31.288,
			31.349,
			31.381,
			31.41,
			31.474,
			31.488,
			31.536,
			31.598,
			31.66,
			31.705,
			31.721,
			31.782,
			31.813,
			31.844,
			31.907,
			31.924,
			31.968,
			32.032,
			32.092,
			32.138,
			32.218,
			32.248,
			32.279,
			32.355,
			32.403,
			32.465,
			32.527,
			32.574,
			32.589,
			32.65,
			32.683,
			32.714,
			32.791,
			32.838,
			32.899,
			32.96,
			33.023,
			33.084,
			33.115,
			33.148,
			33.21,
			33.271,
			33.332,
			33.396,
			33.441,
			33.458,
			33.518,
			33.549,
			33.581,
			33.644,
			33.658,
			33.707,
			33.767,
			33.831,
			33.877,
			33.891,
			33.952,
			33.983,
			34.014,
			34.093,
			34.141,
			34.2,
			34.264,
			34.324,
			34.388,
			34.418,
			34.451,
			34.511,
			34.573,
			34.636,
			34.697,
			34.759,
			34.822,
			34.851,
			34.882,
			34.946,
			34.962,
			35.008,
			35.074,
			35.133,
			35.177,
			35.195,
			35.257,
			35.288,
			35.316,
			35.378,
			35.394,
			35.442,
			35.505,
			35.566,
			35.612,
			35.689,
			35.722,
			35.75,
			35.812,
			35.877,
			35.938,
			35.999,
			36.046,
			36.063,
			36.124,
			36.156,
			36.186,
			36.264,
			36.309,
			36.371,
			36.433,
			36.482,
			36.495,
			36.557,
			36.59,
			36.621,
			36.682,
			36.698,
			36.744,
			36.805,
			36.869,
			36.915,
			36.991,
			37.024,
			37.055,
			37.136,
			37.177,
			37.245,
			37.301,
			37.35,
			37.364,
			37.427,
			37.458,
			37.489,
			37.55,
			37.566,
			37.612,
			37.675,
			37.734,
			37.782,
			37.799,
			37.861,
			37.89,
			37.922,
			37.999,
			38.047,
			38.109,
			38.171,
			38.216,
			38.293,
			38.326,
			38.357,
			38.416,
			38.434,
			38.481,
			38.541,
			38.604,
			38.667,
			38.729,
			38.76,
			38.791,
			38.85,
			38.868,
			38.913,
			38.975,
			39.038,
			39.084,
			39.161,
			39.198,
			39.223,
			39.285,
			39.349,
			39.41,
			39.473,
			39.519,
			39.596,
			39.627,
			39.658,
			39.721,
			39.736,
			39.782,
			39.844,
			39.908,
			39.969,
			40.029,
			40.061,
			40.093,
			40.155,
			40.171,
			40.216,
			40.278,
			40.34,
			40.401,
			40.463,
			40.496,
			40.527,
			40.588,
			40.65,
			40.713,
			40.775,
			40.821,
			40.838,
			40.899,
			40.93,
			40.96,
			41.038,
			41.085,
			41.146,
			41.21,
			41.271,
			41.333,
			41.364,
			41.396,
			41.473,
			41.518,
			41.58,
			41.642,
			41.691,
			41.765,
			41.797,
			41.828,
			41.891,
			41.954,
			42.014,
			42.076,
			42.124,
			42.139,
			42.2,
			42.232,
			42.263,
			42.326,
			42.341,
			42.388,
			42.449,
			42.511,
			42.558,
			42.636,
			42.666,
			42.696,
			42.758,
			42.774,
			42.821,
			42.882,
			42.946,
			42.99,
			43.068,
			43.1,
			43.13,
			43.194,
			43.255,
			43.323,
			43.378,
			43.441,
			43.504,
			43.535,
			43.565,
			43.626,
			43.643,
			43.688,
			43.752,
			43.813,
			43.859,
			43.939,
			43.967,
			43.999,
			44.062,
			44.123,
			44.187,
			44.248,
			44.293,
			44.309,
			44.371,
			44.4,
			44.434,
			44.495,
			44.511,
			44.558,
			44.617,
			44.682,
			44.743,
			44.806,
			44.838,
			44.866,
			44.93,
			44.946,
			44.993,
			45.054,
			45.115,
			45.177,
			45.241,
			45.27,
			45.3,
			45.364,
			45.383,
			45.426,
			45.488,
			45.549,
			45.611,
			45.674,
			45.705,
			45.736,
			45.798,
			45.812,
			45.859,
			45.921,
			45.983,
			46.03,
			46.046,
			46.108,
			46.138,
			46.171,
			46.232,
			46.246,
			46.294,
			46.357,
			46.416,
			46.463,
			46.541,
			46.574,
			46.603,
			46.665,
			46.727,
			46.791,
			46.851,
			46.897,
			46.913,
			46.974,
			47.007,
			47.038,
			47.099,
			47.115,
			47.161,
			47.225,
			47.284,
			47.332,
			47.348,
			47.41,
			47.447,
			47.471,
			47.533,
			47.595,
			47.657,
			47.721,
			47.767,
			47.845,
			47.875,
			47.906,
			47.984,
			48.031,
			48.093,
			48.154,
			48.201,
			48.215,
			48.279,
			48.309,
			48.341,
			48.402,
			48.418,
			48.465,
			48.526,
			48.589,
			48.634,
			48.712,
			48.743,
			48.773,
			48.837,
			48.897,
			48.961,
			49.023,
			49.068,
			49.147,
			49.178,
			49.208,
			49.287,
			49.333,
			49.394,
			49.456,
			49.509,
			49.581,
			49.611,
			49.642,
			49.704,
			49.718,
			49.766,
			49.828,
			49.89,
			49.938,
			50.014,
			50.045,
			50.075,
			50.155,
			50.202,
			50.262,
			50.325,
			50.373,
			50.387,
			50.448,
			50.48,
			50.511,
			50.588,
			50.635,
			50.696,
			50.757,
			50.821,
			50.882,
			50.912,
			50.945,
			51.021,
			51.068,
			51.132,
			51.194,
			51.238,
			51.317,
			51.349,
			51.378,
			51.44,
			51.502,
			51.57,
			51.626,
			51.672,
			51.688,
			51.751,
			51.783,
			51.813,
			51.874,
			51.891,
			51.938,
			51.999,
			52.062,
			52.108,
			52.124,
			52.184,
			52.216,
			52.246,
			52.324,
			52.371,
			52.434,
			52.495,
			52.556,
			52.617,
			52.649,
			52.679,
			52.742,
			52.759,
			52.806,
			52.866,
			52.929,
			52.99,
			53.051,
			53.084,
			53.114,
			53.192,
			53.239,
			53.302,
			53.362,
			53.409,
			53.487,
			53.518,
			53.548,
			53.611,
			53.673,
			53.734,
			53.796,
			53.858,
			53.922,
			53.952,
			53.982,
			54.062,
			54.107,
			54.168,
			54.23,
			54.278,
			54.294,
			54.355,
			54.386,
			54.416,
			54.495,
			54.541,
			54.604,
			54.664,
			54.712,
			54.79,
			54.821,
			54.851,
			54.914,
			54.975,
			55.038,
			55.1,
			55.162,
			55.223,
			55.254,
			55.284,
			55.348,
			55.362,
			55.409,
			55.473,
			55.534,
			55.578,
			55.595,
			55.657,
			55.692,
			55.722,
			55.782,
			55.798,
			55.842,
			55.904,
			55.966,
			56.03,
			56.091,
			56.121,
			56.155,
			56.229,
			56.276,
			56.339,
			56.401,
			56.462,
			56.526,
			56.556,
			56.589,
			56.665,
			56.712,
			56.773,
			56.836,
			56.881,
			56.898,
			56.959,
			56.99,
			57.023,
			57.084,
			57.144,
			57.206,
			57.267,
			57.315,
			57.394,
			57.425,
			57.456,
			57.518,
			57.579,
			57.642,
			57.705,
			57.755,
			57.765,
			57.828,
			57.859,
			57.889,
			57.94
		]
	],
	'2122_2123_7982': [
		[
			148.645
		],
		[
			0
		],
		[
			0,
			2.091,
			4.876,
			9.066,
			11.868,
			16.084,
			17.493,
			18.904,
			23.148,
			25.987,
			30.258,
			33.114,
			37.411,
			40.282,
			41.72,
			44.6,
			48.933,
			51.828,
			54.729,
			59.092,
			62.007,
			66.391,
			67.856,
			69.323,
			73.733,
			76.683,
			81.119,
			84.084,
			87.056,
			91.525,
			93.018,
			94.513,
			99.008,
			100.509,
			102.012,
			106.528,
			109.546,
			114.083,
			117.114,
			120.151,
			121.672,
			124.717,
			126.242,
			129.297,
			132.357,
			136.957,
			140.03,
			144.651,
			146.194,
			147.737,
			152.365,
			155.441,
			160.051,
			163.077,
			166.075,
			167.561,
			170.483,
			171.942,
			174.833,
			177.682,
			179.093,
			180.492,
			184.632,
			187.362,
			190.034,
			191.356,
			193.974,
			196.564,
			197.85,
			200.406,
			201.683,
			204.225,
			206.766,
			210.582,
			213.165,
			217.051,
			218.376,
			219.724,
			223.714,
			226.384,
			230.404,
			233.087,
			235.75,
			237.101,
			239.82,
			241.182,
			243.95,
			246.736,
			248.128,
			250.914,
			253.676,
			257.79,
			259.138,
			260.489,
			263.187,
			265.85,
			267.185,
			271.165,
			273.844,
			276.504,
			280.482,
			283.155,
			287.184,
			288.53,
			289.88,
			293.946,
			296.668,
			300.79,
			303.534,
			307.667,
			310.432,
			313.185,
			314.575,
			318.759,
			321.557,
			324.365,
			328.593,
			331.423,
			335.679,
			337.101,
			338.524,
			342.803,
			345.662,
			349.962,
			352.837,
			355.723,
			360.067,
			361.52,
			362.975,
			367.349,
			368.81,
			370.272,
			374.666,
			377.6,
			380.541,
			382.013,
			384.962,
			387.918,
			389.398,
			393.846,
			396.82,
			399.8,
			404.285,
			405.783,
			407.282,
			411.789,
			413.293,
			414.798,
			419.321,
			422.342,
			426.882,
			429.916,
			434.478,
			437.526,
			439.052,
			440.58,
			445.17,
			446.703,
			448.238,
			452.855,
			455.94,
			460.578,
			463.676,
			466.779,
			468.333,
			471.444,
			473.002,
			476.122,
			479.247,
			483.945,
			487.084,
			491.802,
			493.377,
			494.953,
			499.688,
			502.849,
			507.601,
			510.775,
			515.545,
			518.729,
			520.321,
			523.508,
			526.699,
			528.297,
			531.494,
			534.696,
			539.507,
			541.113,
			542.72,
			545.938,
			549.159,
			550.771,
			553.999,
			555.615,
			558.85,
			562.088,
			563.709,
			566.954,
			570.203,
			575.083,
			576.712,
			578.342,
			583.237,
			586.507,
			591.418,
			594.698,
			597.982,
			599.626,
			602.917,
			604.564,
			607.861,
			611.163,
			612.815,
			616.123,
			619.435,
			624.411,
			626.072,
			627.733,
			632.724,
			634.389,
			636.056,
			641.06,
			644.402,
			647.747,
			652.773,
			656.127,
			661.165,
			662.846,
			664.527,
			669.579,
			672.952,
			678.019,
			681.403,
			684.792,
			686.487,
			689.881,
			691.579,
			694.979,
			698.383,
			700.087,
			703.497,
			706.91,
			712.036,
			715.46,
			720.601,
			722.316,
			724.032,
			729.186,
			732.626,
			737.794,
			741.244,
			744.699,
			746.427,
			749.887,
			751.618,
			753.351,
			758.553,
			760.289,
			762.026,
			767.243,
			770.725,
			775.955,
			779.447,
			782.944,
			784.693,
			789.947,
			793.453,
			796.964,
			802.235,
			805.753,
			811.036,
			812.798,
			814.561,
			819.855,
			823.389,
			828.695,
			832.237,
			835.782,
			837.555,
			841.104,
			842.879,
			846.431,
			849.986,
			851.764,
			855.323,
			858.885,
			862.452,
			866.023,
			867.81,
			873.177,
			874.968,
			876.759,
			880.345,
			882.139,
			885.73,
			889.323,
			894.72,
			898.321,
			903.729,
			905.534,
			907.339,
			912.758,
			916.377,
			921.812,
			925.44,
			929.069,
			930.882,
			934.495,
			936.292,
			939.865,
			943.4,
			945.153,
			948.626,
			952.061,
			957.141,
			958.814,
			960.478,
			965.411,
			967.036,
			968.651,
			973.442,
			976.592,
			981.252,
			984.314,
			987.341,
			991.848,
			993.33,
			994.807,
			1000.669,
			1002.14,
			1006.551,
			1009.514,
			1013.91,
			1016.831,
			1019.747,
			1021.202,
			1025.569,
			1028.484,
			1031.425,
			1035.869,
			1038.868,
			1043.377,
			1044.891,
			1046.417,
			1050.996,
			1054.068,
			1058.642,
			1061.687,
			1064.723,
			1066.237,
			1069.245,
			1070.726,
			1073.689,
			1076.676,
			1078.146,
			1079.618,
			1084.045,
			1087.005,
			1089.951,
			1091.439,
			1094.421,
			1097.412,
			1098.909,
			1101.934,
			1103.437,
			1106.449,
			1109.442,
			1113.983,
			1117.021,
			1121.592,
			1123.12,
			1124.65,
			1129.261,
			1132.343,
			1136.974,
			1140.067,
			1143.162,
			1144.711,
			1147.816,
			1149.371,
			1152.487,
			1157.175,
			1160.307,
			1163.44,
			1166.576,
			1169.711,
			1171.28,
			1174.423,
			1177.57,
			1179.146,
			1182.303,
			1183.883,
			1187.048,
			1190.218,
			1194.981,
			1198.162,
			1202.94,
			1204.533,
			1206.128,
			1210.915,
			1214.11,
			1218.909,
			1222.115,
			1225.325,
			1226.933,
			1230.151,
			1231.763,
			1234.989,
			1238.22,
			1239.837,
			1243.077,
			1246.322,
			1251.199,
			1252.825,
			1254.453,
			1259.339,
			1260.97,
			1262.602,
			1265.869,
			1267.505,
			1270.781,
			1274.059,
			1278.981,
			1282.266,
			1287.203,
			1288.851,
			1290.5,
			1295.451,
			1298.757,
			1303.723,
			1307.04,
			1312.023,
			1315.349,
			1317.014,
			1320.346,
			1323.682,
			1325.352,
			1328.694,
			1332.04,
			1337.064,
			1340.418,
			1345.457,
			1347.138,
			1348.82,
			1353.875,
			1357.25,
			1362.324,
			1365.712,
			1370.8,
			1374.197,
			1377.599,
			1382.709,
			1384.414,
			1386.121,
			1391.246,
			1394.668,
			1399.809,
			1403.241,
			1406.677,
			1408.396,
			1411.836,
			1413.556,
			1417,
			1420.448,
			1425.625,
			1429.08,
			1434.268,
			1435.999,
			1437.73,
			1442.931,
			1446.402,
			1451.615,
			1455.096,
			1458.582,
			1460.327,
			1463.819,
			1465.567,
			1469.065,
			1472.566,
			1474.318,
			1477.824,
			1481.333,
			1484.846,
			1488.364,
			1490.124,
			1493.646,
			1497.172,
			1498.936,
			1502.467,
			1504.233,
			1507.769,
			1511.308,
			1516.622,
			1520.168,
			1525.494,
			1527.271,
			1529.049,
			1534.387,
			1537.95,
			1543.302,
			1546.875,
			1550.452,
			1552.241,
			1555.822,
			1557.614,
			1561.201,
			1564.794,
			1566.592,
			1570.193,
			1573.797,
			1579.204,
			1581.007,
			1582.808,
			1588.216,
			1590.019,
			1591.823,
			1595.435,
			1597.243,
			1600.861,
			1604.482,
			1609.916,
			1613.541,
			1618.982,
			1620.797,
			1622.613,
			1629.882,
			1631.701,
			1637.164,
			1640.811,
			1644.462,
			1646.289,
			1649.946,
			1651.776,
			1655.439,
			1660.938,
			1664.607,
			1668.279,
			1673.779,
			1677.427,
			1682.844,
			1684.631,
			1686.409,
			1691.682,
			1695.148,
			1700.274,
			1703.644,
			1706.974,
			1708.624,
			1711.895,
			1713.514,
			1716.724,
			1719.894,
			1721.464,
			1723.024,
			1727.646,
			1730.682,
			1733.682,
			1735.169,
			1738.115,
			1741.021,
			1742.46,
			1745.308,
			1746.718,
			1749.509,
			1752.262,
			1756.318,
			1758.975,
			1762.89,
			1764.176,
			1765.453,
			1769.227,
			1771.695,
			1775.33,
			1777.708,
			1780.052,
			1781.211,
			1783.504,
			1784.636,
			1786.873,
			1789.075,
			1790.163,
			1791.241,
			1794.425,
			1796.503,
			1798.544,
			1799.552,
			1801.539,
			1803.491,
			1804.453,
			1806.35,
			1807.285,
			1809.129,
			1810.94,
			1813.594,
			1815.319,
			1817.836,
			1818.656,
			1819.467,
			1821.844,
			1823.378,
			1825.608,
			1827.049,
			1828.45,
			1829.138,
			1830.501,
			1831.165,
			1832.469,
			1833.736,
			1834.357,
			1835.574,
			1836.76,
			1838.458,
			1839.004,
			1839.543,
			1841.118,
			1841.63,
			1842.137,
			1843.597,
			1844.549,
			1845.49,
			1846.889,
			1847.821,
			1849.219,
			1849.686,
			1850.153,
			1851.56,
			1852.52,
			1854.029,
			1855.039,
			1856.052,
			1856.561,
			1857.629,
			1858.216,
			1859.39,
			1860.564,
			1861.154,
			1862.342,
			1863.652,
			1865.618,
			1866.934,
			1868.861,
			1869.5,
			1870.14,
			1872.075,
			1873.286,
			1875.083,
			1876.292,
			1877.5,
			1879.211,
			1879.786,
			1880.364,
			1882.7,
			1883.284,
			1885.055,
			1886.252,
			1887.464,
			1888.08,
			1889.323,
			1890.582,
			1891.218,
			1892.502,
			1893.152,
			1894.466,
			1895.795,
			1897.822,
			1899.2,
			1901.298,
			1902.005,
			1902.718,
			1904.871,
			1906.334,
			1908.56,
			1910.064,
			1911.584,
			1912.35,
			1913.892,
			1914.669,
			1916.235,
			1917.816,
			1918.612,
			1920.215,
			1921.833,
			1924.289,
			1925.945,
			1927.614,
			1929.297,
			1930.14,
			1931.824,
			1932.667,
			1934.365,
			1936.077,
			1938.669,
			1940.413,
			1943.054,
			1943.94,
			1944.83,
			1947.516,
			1949.324,
			1952.06,
			1953.901,
			1955.754,
			1956.686,
			1958.559,
			1959.5,
			1961.391,
			1963.294,
			1964.249,
			1966.164,
			1968.082,
			1970.945,
			1971.891,
			1972.833,
			1975.616,
			1976.527,
			1977.429,
			1980.08,
			1981.801,
			1983.486,
			1985.962,
			1987.589,
			1990.025,
			1990.83,
			1991.634,
			1994.039,
			1994.837,
			1995.634,
			1998.015,
			1999.583,
			2001.143,
			2001.921,
			2003.465,
			2004.229,
			2005.75,
			2008.02,
			2009.544,
			2011.06,
			2013.355,
			2014.155,
			2014.953,
			2017.337,
			2018.142,
			2018.969,
			2021.442,
			2023.083,
			2025.482,
			2027.08,
			2028.648,
			2030.957,
			2031.727,
			2032.498,
			2034.786,
			2035.548,
			2036.311,
			2038.61,
			2040.15,
			2041.699,
			2042.478,
			2044.041,
			2045.613,
			2046.404,
			2047.995,
			2048.794,
			2050.402,
			2052.023,
			2054.484,
			2056.142,
			2058.657,
			2059.503,
			2060.353,
			2062.918,
			2064.643,
			2067.252,
			2068.989,
			2070.751,
			2071.636,
			2073.414,
			2074.306,
			2076.1,
			2077.904,
			2078.812,
			2079.723,
			2082.472,
			2084.325,
			2087.13,
			2089.018,
			2090.922,
			2091.878,
			2093.799,
			2094.763,
			2096.701,
			2098.649,
			2101.589,
			2103.562,
			2106.542,
			2107.542,
			2108.544,
			2111.567,
			2113.595,
			2116.657,
			2118.71,
			2120.774,
			2121.809,
			2123.888,
			2124.931,
			2127.024,
			2129.122,
			2130.17,
			2132.266,
			2134.368,
			2137.536,
			2139.658,
			2142.855,
			2143.925,
			2144.997,
			2147.146,
			2148.222,
			2150.383,
			2152.551,
			2155.82,
			2158.007,
			2161.303,
			2162.404,
			2163.506,
			2166.848,
			2169.067,
			2172.408,
			2174.62,
			2176.857,
			2177.979,
			2180.226,
			2181.353,
			2183.631,
			2185.9,
			2187.037,
			2189.319,
			2191.61,
			2195.041,
			2197.35,
			2200.83,
			2201.994,
			2203.178,
			2206.676,
			2209.03,
			2211.394,
			2214.955,
			2217.338,
			2220.922,
			2222.12,
			2223.319,
			2228.131,
			2229.338,
			2232.968,
			2235.397,
			2239.054,
			2241.502,
			2243.957,
			2245.186,
			2247.651,
			2248.886,
			2251.36,
			2253.841,
			2257.571,
			2260.066,
			2263.819,
			2265.072,
			2266.326,
			2270.1,
			2272.623,
			2276.418,
			2278.957,
			2281.501,
			2282.776,
			2285.331,
			2286.61,
			2289.175,
			2291.747,
			2293.035,
			2295.617,
			2298.206,
			2302.101,
			2303.399,
			2304.697,
			2307.296,
			2309.901,
			2311.206,
			2315.13,
			2317.754,
			2320.384,
			2324.34,
			2326.986,
			2330.965,
			2332.294,
			2333.624,
			2337.625,
			2340.3,
			2344.322,
			2347.012,
			2351.059,
			2353.765,
			2355.12,
			2357.834,
			2360.554,
			2361.917,
			2364.646,
			2367.38,
			2371.495,
			2372.869,
			2374.245,
			2378.38,
			2379.761,
			2381.143,
			2385.297,
			2388.072,
			2390.853,
			2395.033,
			2397.828,
			2402.03,
			2403.435,
			2404.84,
			2409.065,
			2410.478,
			2411.892,
			2416.144,
			2418.987,
			2421.834,
			2423.261,
			2426.117,
			2427.547,
			2430.411,
			2433.28,
			2434.716,
			2437.592,
			2440.468,
			2444.771,
			2446.198,
			2447.621,
			2451.85,
			2453.245,
			2454.633,
			2458.742,
			2461.433,
			2465.394,
			2467.985,
			2470.537,
			2474.291,
			2475.523,
			2476.745,
			2480.354,
			2482.717,
			2486.201,
			2488.482,
			2490.749,
			2491.863,
			2494.063,
			2496.239,
			2497.317,
			2499.447,
			2500.502,
			2502.596,
			2504.65,
			2507.722,
			2509.755,
			2512.744,
			2513.736,
			2514.727,
			2517.682,
			2519.633,
			2522.605,
			2524.58,
			2526.567,
			2527.564,
			2529.566,
			2530.57,
			2532.583,
			2535.616,
			2536.625,
			2539.664,
			2541.689,
			2543.716,
			2544.733,
			2546.769,
			2548.812,
			2549.838,
			2551.897,
			2552.93,
			2555.003,
			2557.087,
			2560.21,
			2562.308,
			2565.448,
			2566.489,
			2567.524,
			2570.592,
			2572.595,
			2575.531,
			2577.443,
			2579.317,
			2580.241,
			2582.063,
			2582.962,
			2584.734,
			2587.329,
			2589.013,
			2590.661,
			2593.065,
			2594.623,
			2596.144,
			2597.63,
			2598.359,
			2600.495,
			2601.885,
			2603.243,
			2605.235,
			2606.555,
			2608.49,
			2609.13,
			2609.767,
			2611.675,
			2612.94,
			2614.828,
			2616.09,
			2617.353,
			2617.982,
			2619.238,
			2619.866,
			2621.132,
			2622.402,
			2623.035,
			2624.302,
			2625.574,
			2627.53,
			2628.831,
			2630.798,
			2631.474,
			2632.151,
			2634.184,
			2635.543,
			2636.921,
			2638.988,
			2640.37,
			2642.403,
			2643.081,
			2643.76,
			2645.795,
			2646.454,
			2647.114,
			2649.103,
			2650.436,
			2651.752,
			2652.41,
			2653.733,
			2655.064,
			2655.734,
			2657.085,
			2657.765,
			2659.131,
			2660.508,
			2662.624,
			2664.042,
			2666.217,
			2666.954,
			2667.691,
			2669.891,
			2671.339,
			2673.463,
			2674.835,
			2676.839,
			2678.136,
			2678.77,
			2679.395,
			2681.225,
			2681.825,
			2682.417,
			2684.146,
			2685.263,
			2686.348,
			2686.879,
			2687.936,
			2688.986,
			2689.508,
			2690.556,
			2691.065,
			2692.07,
			2693.066,
			2694.55,
			2695.535,
			2697.037,
			2697.535,
			2698.031,
			2699.518,
			2700.014,
			2700.512,
			2702.04,
			2703.055,
			2704.067,
			2704.574,
			2705.59,
			2706.098,
			2707.112,
			2708.629,
			2709.64,
			2710.652,
			2711.708,
			2713.292,
			2714.869,
			2715.395,
			2715.931,
			2717.62,
			2718.742,
			2719.866,
			2721.593,
			2722.779,
			2724.563,
			2725.161,
			2725.76,
			2727.521,
			2728.701,
			2730.486,
			2731.612,
			2732.714,
			2733.268,
			2734.382,
			2734.942,
			2736.062,
			2737.158,
			2737.708,
			2738.814,
			2739.931,
			2741.658,
			2742.84,
			2744.63,
			2745.839,
			2747.705,
			2748.967,
			2750.884,
			2752.178,
			2754.139,
			2755.45,
			2756.116,
			2756.785,
			2758.818,
			2760.195,
			2762.288,
			2763.714,
			2765.867,
			2767.316,
			2768.783,
			2769.511,
			2771.004,
			2771.753,
			2773.264,
			2774.791,
			2777.113,
			2778.683,
			2781.066,
			2781.871,
			2782.679,
			2785.125,
			2786.777,
			2789.283,
			2790.965,
			2792.649,
			2793.492,
			2795.189,
			2796.044,
			2797.763,
			2799.495,
			2800.367,
			2801.241,
			2803.89,
			2805.672,
			2808.376,
			2810.197,
			2812.031,
			2812.955,
			2814.811,
			2815.745,
			2817.622,
			2819.513,
			2822.375,
			2824.3,
			2827.213,
			2828.191,
			2829.171,
			2832.133,
			2834.123,
			2837.13,
			2839.149,
			2841.182,
			2842.204,
			2844.255,
			2845.285,
			2847.355,
			2849.436,
			2850.481,
			2852.578,
			2854.677,
			2857.83,
			2858.885,
			2859.944,
			2863.135,
			2864.204,
			2865.275,
			2868.507,
			2870.673,
			2872.84,
			2876.071,
			2878.198,
			2881.331,
			2882.358,
			2883.376,
			2886.377,
			2888.333,
			2891.2,
			2893.068,
			2895.807,
			2897.593,
			2898.475,
			2900.226,
			2901.929,
			2902.767,
			2904.422,
			2906.053,
			2908.451,
			2909.241,
			2910.029,
			2912.378,
			2913.153,
			2913.928,
			2916.254,
			2917.804,
			2920.125,
			2921.673,
			2923.238,
			2925.585,
			2926.367,
			2927.154,
			2929.53,
			2930.321,
			2931.111,
			2933.503,
			2935.101,
			2937.504,
			2939.123,
			2939.93,
			2941.544,
			2943.174,
			2943.99,
			2945.621,
			2947.256,
			2949.723,
			2951.365,
			2953.848,
			2954.676,
			2955.504,
			2958,
			2958.835,
			2959.67,
			2962.182,
			2963.875,
			2965.568,
			2966.416,
			2968.125,
			2968.983,
			2969.841,
			2972.424,
			2973.29,
			2974.155,
			2976.756,
			2978.498,
			2981.121,
			2982.873,
			2984.626,
			2985.505,
			2987.267,
			2988.138,
			2989.884,
			2991.637,
			2994.24,
			2995.973,
			2998.558,
			2999.414,
			3000.271,
			3002.842,
			3004.55,
			3007.122,
			3008.842,
			3010.568,
			3011.434,
			3013.177,
			3014.053,
			3015.813,
			3017.572,
			3018.467,
			3020.282,
			3022.096,
			3023.927,
			3026.698,
			3029.5,
			3030.44,
			3031.384,
			3033.265,
			3034.234,
			3036.133,
			3038.061,
			3040.989,
			3042.945,
			3045.883,
			3046.873,
			3047.866,
			3050.861,
			3052.871,
			3055.907,
			3057.945,
			3061.02,
			3063.074,
			3064.101,
			3066.146,
			3069.177,
			3071.163,
			3073.114,
			3075.974,
			3077.839,
			3080.573,
			3081.466,
			3082.349,
			3084.953,
			3086.645,
			3089.143,
			3090.77,
			3092.378,
			3094.772,
			3095.566,
			3096.359,
			3098.733,
			3099.524,
			3100.314,
			3102.685,
			3104.261,
			3105.834,
			3106.621,
			3108.195,
			3109.772,
			3110.56,
			3112.134,
			3112.921,
			3114.518,
			3116.11,
			3118.504,
			3120.118,
			3122.531,
			3123.339,
			3124.156,
			3126.6,
			3128.228,
			3130.682,
			3132.316,
			3134.768,
			3136.402,
			3137.219,
			3138.857,
			3140.496,
			3141.315,
			3142.135,
			3144.599,
			3146.244,
			3147.896,
			3148.718,
			3150.358,
			3152.005,
			3152.832,
			3155.302,
			3156.96,
			3158.628,
			3161.141,
			3162.833,
			3165.388,
			3166.246,
			3167.107,
			3169.717,
			3171.477,
			3174.146,
			3175.95,
			3177.766,
			3178.679,
			3180.517,
			3181.44,
			3183.297,
			3185.162,
			3186.099,
			3187.966,
			3189.857,
			3192.714,
			3193.673,
			3194.634,
			3196.562,
			3198.494,
			3199.46,
			3201.386,
			3202.344,
			3204.244,
			3206.119,
			3208.876,
			3210.692,
			3213.341,
			3214.214,
			3215.084,
			3217.668,
			3219.381,
			3221.95,
			3223.657,
			3226.274,
			3228.022,
			3228.899,
			3230.667,
			3233.329,
			3235.123,
			3236.927,
			3239.646,
			3240.559,
			3241.472,
			3244.207,
			3245.12,
			3246.032,
			3248.755,
			3250.578,
			3252.399,
			3255.139,
			3256.972,
			3259.717,
			3260.632,
			3261.546,
			3264.279,
			3265.181,
			3266.082,
			3268.775,
			3270.543,
			3272.3,
			3273.176,
			3274.918,
			3276.645,
			3277.507,
			3280.084,
			3281.794,
			3283.502,
			3286.062,
			3287.771,
			3290.331,
			3291.194,
			3292.06,
			3294.678,
			3296.44,
			3299.095,
			3300.862,
			3302.62,
			3303.494,
			3305.223,
			3306.079,
			3306.927,
			3309.427,
			3310.245,
			3311.056,
			3313.458,
			3315.047,
			3317.437,
			3319.013,
			3320.599,
			3321.392,
			3323.771,
			3325.362,
			3326.955,
			3329.346,
			3330.95,
			3333.359,
			3334.164,
			3334.97,
			3337.392,
			3339.015,
			3341.456,
			3343.092,
			3344.736,
			3345.56,
			3347.213,
			3348.044,
			3349.713,
			3351.385,
			3352.227,
			3353.918,
			3355.618,
			3358.187,
			3359.035,
			3359.901,
			3361.641,
			3363.393,
			3364.274,
			3366.047,
			3366.939,
			3368.734,
			3370.542,
			3373.283,
			3375.128,
			3377.924,
			3378.863,
			3379.805,
			3382.657,
			3384.575,
			3387.487,
			3389.445,
			3391.425,
			3392.42,
			3394.422,
			3395.428,
			3397.452,
			3399.489,
			3400.509,
			3402.555,
			3404.614,
			3407.708,
			3408.745,
			3409.785,
			3412.914,
			3413.961,
			3415.008,
			3417.103,
			3418.151,
			3420.257,
			3423.44,
			3425.577,
			3427.724,
			3430.966,
			3432.051,
			3433.139,
			3436.418,
			3438.617,
			3441.933,
			3444.156,
			3446.389,
			3447.51,
			3449.756,
			3452.005,
			3453.129,
			3455.368,
			3456.481,
			3458.688,
			3460.864,
			3464.062,
			3466.149,
			3469.216,
			3470.22,
			3471.215,
			3474.143,
			3476.048,
			3478.837,
			3480.654,
			3482.432,
			3483.308,
			3485.034,
			3485.883,
			3486.719,
			3489.185,
			3489.97,
			3490.76,
			3493.082,
			3494.576,
			3496.04,
			3496.763,
			3498.188,
			3499.591,
			3500.286,
			3501.67,
			3502.359,
			3503.728,
			3505.089,
			3507.13,
			3508.49,
			3510.532,
			3511.216,
			3511.902,
			3513.959,
			3515.331,
			3517.409,
			3518.797,
			3520.186,
			3520.881,
			3522.274,
			3522.969,
			3524.358,
			3526.444,
			3527.833,
			3529.223,
			3530.613,
			3532.74,
			3534.163,
			3535.586,
			3536.302,
			3537.779,
			3538.515,
			3539.985,
			3541.462,
			3543.731,
			3545.241,
			3547.507,
			3548.261,
			3549.015,
			3551.273,
			3552.758,
			3554.979,
			3556.455,
			3558.645,
			3560.106,
			3560.838,
			3562.281,
			3564.435,
			3565.876,
			3567.305,
			3569.429,
			3570.142,
			3570.856,
			3572.997,
			3573.711,
			3574.426,
			3576.586,
			3578.034,
			3579.481,
			3581.658,
			3583.134,
			3585.346,
			3586.085,
			3586.83,
			3589.858,
			3590.616,
			3592.928,
			3594.488,
			3596.053,
			3596.839,
			3598.422,
			3600.01,
			3600.805,
			3602.389,
			3603.176,
			3604.755,
			3606.344,
			3608.694,
			3610.268,
			3612.635,
			3613.42,
			3614.208,
			3616.589,
			3618.179,
			3620.585,
			3622.201,
			3623.83,
			3624.65,
			3626.3,
			3627.131,
			3628.804,
			3630.475,
			3631.323,
			3632.175,
			3634.754,
			3636.491,
			3638.244,
			3639.126,
			3640.901,
			3642.689,
			3643.589,
			3646.309,
			3648.14,
			3649.986,
			3652.785,
			3654.676,
			3657.542,
			3658.516,
			3659.493,
			3662.446,
			3664.463,
			3667.514,
			3669.575,
			3672.691,
			3674.779,
			3675.829,
			3677.932,
			3681.095,
			3683.21,
			3685.323,
			3688.498,
			3690.609,
			3692.728,
			3694.854,
			3695.922,
			3699.136,
			3701.308,
			3703.455,
			3706.737,
			3708.929,
			3712.242,
			3713.353,
			3714.469,
			3717.832,
			3720.075,
			3723.49,
			3725.798,
			3728.1,
			3729.255,
			3731.574,
			3732.738,
			3735.073,
			3737.419,
			3738.595,
			3740.954,
			3743.304,
			3746.864,
			3749.269,
			3752.863,
			3754.067,
			3755.254,
			3757.677,
			3758.894,
			3761.338,
			3765.045,
			3767.512,
			3769.972,
			3773.636,
			3774.844,
			3776.045,
			3779.603,
			3781.929,
			3785.333,
			3787.588,
			3790.876,
			3793.022,
			3795.113,
			3796.154,
			3798.224,
			3799.24,
			3801.248,
			3803.223,
			3806.146,
			3808.064,
			3810.901,
			3811.837,
			3812.769,
			3815.542,
			3817.385,
			3820.156,
			3822.01,
			3824.798,
			3826.658,
			3827.588,
			3828.518,
			3831.306,
			3832.232,
			3833.155,
			3835.897,
			3837.711,
			3839.5,
			3840.389,
			3842.14,
			3843.852,
			3844.698,
			3846.365,
			3847.184,
			3848.796,
			3850.378,
			3852.648,
			3854.125,
			3856.254,
			3856.946,
			3857.629,
			3859.626,
			3860.912,
			3862.796,
			3864.016,
			3865.804,
			3866.974,
			3867.553,
			3868.707,
			3870.43,
			3871.002,
			3872.713,
			3874.422,
			3875.005,
			3875.587,
			3876.749,
			3877.91,
			3878.491,
			3879.663,
			3880.266,
			3881.468,
			3882.67,
			3884.478,
			3885.711,
			3887.559,
			3888.176,
			3888.795,
			3890.67,
			3891.929,
			3893.829,
			3895.102,
			3897.02,
			3898.307,
			3899.599,
			3900.235,
			3901.512,
			3902.152,
			3903.439,
			3904.732,
			3906.657,
			3907.303,
			3907.951,
			3909.91,
			3910.563,
			3911.219,
			3913.205,
			3914.545,
			3915.896,
			3917.948,
			3919.335,
			3921.462,
			3922.18,
			3922.902,
			3925.093,
			3925.833,
			3926.577,
			3928.832,
			3930.354,
			3931.889,
			3932.662,
			3934.207,
			3934.99,
			3936.567,
			3938.959,
			3940.573,
			3942.201,
			3944.669,
			3946.335,
			3948.858,
			3949.701,
			3950.545,
			3953.081,
			3954.788,
			3957.37,
			3959.107,
			3961.737,
			3963.506,
			3964.396,
			3965.288,
			3967.985,
			3968.89,
			3969.798,
			3972.542,
			3974.386,
			3977.176,
			3979.051,
			3980.939,
			3981.889,
			3983.79,
			3984.738,
			3986.623,
			3988.481,
			3991.201,
			3992.967,
			3995.544,
			3996.384,
			3997.214,
			3999.645,
			4001.219,
			4003.509,
			4004.987,
			4006.43,
			4007.138,
			4008.525,
			4009.206,
			4010.539,
			4011.839,
			4012.476,
			4013.721,
			4014.927,
			4016.093,
			4017.22,
			4017.769,
			4018.838,
			4019.871,
			4020.374,
			4021.355,
			4021.834,
			4022.765,
			4023.665,
			4024.106,
			4024.974,
			4025.831,
			4027.105,
			4027.527,
			4027.953,
			4029.188,
			4030.001,
			4031.218,
			4032.016,
			4033.199,
			4033.986,
			4034.38,
			4035.17,
			4035.969,
			4036.372,
			4037.177,
			4037.98,
			4039.182,
			4039.584,
			4039.986,
			4041.251,
			4041.693,
			4042.134,
			4043.455,
			4044.339,
			4045.675,
			4046.55,
			4047.429,
			4048.76,
			4049.208,
			4049.657,
			4051.002,
			4051.871,
			4053.19,
			4054.082,
			4055.44,
			4056.367,
			4056.836,
			4057.783,
			4058.743,
			4059.228,
			4060.209,
			4061.213,
			4062.764,
			4063.818,
			4065.434,
			4065.984,
			4066.542,
			4068.245,
			4069.404,
			4071.18,
			4072.39,
			4073.617,
			4075.492,
			4076.12,
			4076.749,
			4078.629,
			4079.267,
			4079.907,
			4081.848,
			4083.164,
			4084.494,
			4085.164,
			4086.517,
			4087.885,
			4088.574,
			4089.965,
			4090.666,
			4092.081,
			4093.509,
			4095.682,
			4097.15,
			4099.382,
			4100.134,
			4100.889,
			4103.179,
			4104.724,
			4107.069,
			4108.652,
			4111.053,
			4112.67,
			4113.484,
			4115.123,
			4116.775,
			4117.605,
			4119.278,
			4120.957,
			4123.474,
			4124.316,
			4125.163,
			4126.866,
			4128.582,
			4129.444,
			4131.178,
			4132.051,
			4133.805,
			4135.572,
			4138.247,
			4140.046,
			4142.769,
			4143.683,
			4144.6,
			4147.371,
			4149.235,
			4152.054,
			4153.949,
			4155.856,
			4156.814,
			4158.756,
			4159.723,
			4161.667,
			4163.604,
			4164.602,
			4166.572,
			4168.55,
			4171.53,
			4172.527,
			4173.526,
			4176.529,
			4177.532,
			4178.536,
			4180.545,
			4181.551,
			4183.569,
			4185.586,
			4188.62,
			4190.646,
			4193.689,
			4194.706,
			4195.721,
			4198.762,
			4199.773,
			4200.784,
			4203.807,
			4205.803,
			4207.837,
			4208.848,
			4210.876,
			4211.895,
			4213.944,
			4217.039,
			4219.111,
			4221.194,
			4224.313,
			4226.413,
			4229.586,
			4230.64,
			4231.692,
			4234.824,
			4236.927,
			4240.089,
			4242.19,
			4244.295,
			4245.332,
			4247.387,
			4248.404,
			4249.412,
			4252.372,
			4253.345,
			4254.31,
			4257.146,
			4258.995,
			4260.811,
			4261.706,
			4263.474,
			4265.21,
			4266.064,
			4268.575,
			4270.207,
			4271.802,
			4274.125,
			4275.629,
			4277.815,
			4278.525,
			4279.226,
			4281.276,
			4282.601,
			4284.525,
			4285.777,
			4286.984,
			4287.576,
			4288.738,
			4289.308,
			4290.425,
			4291.515,
			4292.051,
			4293.109,
			4294.15,
			4295.179,
			4296.197,
			4296.704,
			4297.712,
			4298.716,
			4299.218,
			4300.214,
			4300.708,
			4301.69,
			4302.664,
			4304.124,
			4305.097,
			4306.54,
			4307.019,
			4307.497,
			4308.947,
			4309.943,
			4311.425,
			4312.41,
			4313.395,
			4313.902,
			4314.931,
			4315.443,
			4316.467,
			4318.001,
			4319.04,
			4320.095,
			4321.673,
			4322.199,
			4322.725,
			4323.783,
			4324.861,
			4325.399,
			4326.475,
			4327.013,
			4328.095,
			4329.187,
			4330.837,
			4331.942,
			4333.615,
			4334.181,
			4334.75,
			4336.466,
			4337.621,
			4339.377,
			4340.56,
			4341.75,
			4342.347,
			4343.55,
			4344.154,
			4345.36,
			4347.175,
			4348.395,
			4349.614,
			4351.438,
			4352.662,
			4354.51,
			4355.122,
			4355.735,
			4357.584,
			4358.826,
			4360.688,
			4361.934,
			4363.188,
			4365.089,
			4365.727,
			4366.366,
			4368.297,
			4368.946,
			4369.6,
			4371.579,
			4372.911,
			4374.256,
			4374.933,
			4376.294,
			4377.668,
			4378.36,
			4379.754,
			4380.454,
			4381.863,
			4383.288,
			4385.459,
			4386.929,
			4389.162,
			4389.917,
			4390.677,
			4392.98,
			4394.521,
			4396.893,
			4398.492,
			4400.107,
			4400.921,
			4402.56,
			4403.384,
			4405.044,
			4407.56,
			4408.401,
			4410.929,
			4412.629,
			4414.344,
			4415.207,
			4416.944,
			4418.696,
			4419.576,
			4422.237,
			4424.028,
			4425.832,
			4428.562,
			4430.398,
			4433.177,
			4434.109,
			4435.045,
			4437.872,
			4439.772,
			4442.646,
			4444.579,
			4446.535,
			4447.492,
			4449.446,
			4450.428,
			4452.4,
			4454.384,
			4455.378,
			4457.374,
			4459.394,
			4462.409,
			4463.417,
			4464.429,
			4466.458,
			4468.494,
			4469.498,
			4472.573,
			4474.632,
			4476.7,
			4479.815,
			4481.905,
			4485.055,
			4486.108,
			4487.161,
			4490.324,
			4492.438,
			4495.622,
			4497.749,
			4499.882,
			4500.952,
			4503.095,
			4505.246,
			4506.325,
			4508.487,
			4509.571,
			4511.747,
			4513.93,
			4517.193,
			4518.272,
			4519.346,
			4522.535,
			4523.576,
			4524.609,
			4527.654,
			4529.626,
			4532.542,
			4534.439,
			4536.302,
			4539.028,
			4539.92,
			4540.804,
			4543.401,
			4544.249,
			4545.087,
			4547.548,
			4549.144,
			4551.468,
			4552.97,
			4554.437,
			4555.156,
			4557.263,
			4558.625,
			4559.954,
			4561.888,
			4563.135,
			4564.938,
			4565.521,
			4566.097,
			4567.767,
			4568.836,
			4570.375,
			4571.358,
			4572.307,
			4572.768,
			4573.665,
			4574.102,
			4574.95,
			4575.767,
			4576.163,
			4576.933,
			4577.673,
			4578.376,
			4579.043,
			4579.367,
			4580.006,
			4580.637,
			4580.952,
			4581.589,
			4581.914,
			4582.568,
			4583.226,
			4584.197,
			4584.826,
			4585.728,
			4586.018,
			4586.306,
			4587.165,
			4587.743,
			4588.611,
			4589.191,
			4589.743,
			4590.011,
			4590.544,
			4590.81,
			4591.339,
			4591.867,
			4592.144,
			4592.69,
			4593.228,
			4594.022,
			4594.288,
			4594.544,
			4595.246,
			4595.474,
			4595.699,
			4596.143,
			4596.364,
			4596.807,
			4597.277,
			4597.987,
			4598.445,
			4599.122,
			4599.348,
			4599.573,
			4600.286,
			4600.752,
			4601.443,
			4601.9,
			4602.406,
			4602.667,
			4603.183,
			4603.439,
			4603.949,
			4604.456,
			4604.722,
			4605.335,
			4605.938,
			4606.833,
			4607.132,
			4607.459,
			4608.42,
			4608.735,
			4609.05,
			4610.075,
			4610.878,
			4612.08,
			4612.888,
			4613.702,
			4614.932,
			4615.299,
			4615.667,
			4616.75,
			4617.1,
			4617.345,
			4617.996,
			4618.423,
			4618.856,
			4619.072,
			4619.51,
			4619.937,
			4620.13,
			4620.523,
			4620.722,
			4621.125,
			4621.536,
			4622.162,
			4622.581,
			4623.208,
			4623.421,
			4623.635,
			4624.29,
			4624.738,
			4625.451,
			4625.943,
			4626.701,
			4627.223,
			4627.491,
			4628.047,
			4628.617,
			4628.906,
			4629.2,
			4630.103,
			4630.723,
			4631.681,
			4632.339,
			4633.024,
			4633.375,
			4634.089,
			4634.453,
			4635.194,
			4635.955,
			4637.129,
			4637.936,
			4639.175,
			4639.599,
			4640.029,
			4641.352,
			4642.261,
			4643.653,
			4644.606,
			4645.583,
			4646.079,
			4647.089,
			4647.602,
			4648.656,
			4649.722,
			4650.263,
			4651.361,
			4652.482,
			4654.217,
			4654.805,
			4655.397,
			4657.208,
			4657.824,
			4658.447,
			4659.703,
			4660.331,
			4661.591,
			4662.86,
			4664.807,
			4666.124,
			4668.136,
			4668.815,
			4669.498,
			4671.568,
			4672.966,
			4675.076,
			4676.501,
			4677.941,
			4678.667,
			4680.127,
			4681.6,
			4682.341,
			4683.838,
			4684.593,
			4686.102,
			4687.64,
			4689.986,
			4691.582,
			4693.98,
			4694.786,
			4695.595,
			4698.023,
			4699.688,
			4701.349,
			4703.828,
			4705.498,
			4706.339,
			4708.034,
			4708.886,
			4709.742,
			4713.208,
			4714.085,
			4716.736,
			4718.526,
			4720.328,
			4721.235,
			4723.062,
			4723.982,
			4725.831,
			4727.695,
			4728.634,
			4730.521,
			4732.423,
			4735.302,
			4737.238,
			4740.166,
			4741.147,
			4742.133,
			4745.11,
			4747.113,
			4750.146,
			4752.189,
			4755.279,
			4757.358,
			4758.418,
			4759.466,
			4763.705,
			4765.836,
			4767.967,
			4770.099,
			4773.3,
			4775.441,
			4777.567,
			4778.643,
			4780.795,
			4781.89,
			4784.049,
			4786.21,
			4789.458,
			4791.635,
			4794.916,
			4796.015,
			4797.117,
			4800.421,
			4802.65,
			4806.012,
			4808.266,
			4810.531,
			4811.666,
			4813.945,
			4815.088,
			4817.382,
			4819.687,
			4820.842,
			4823.159,
			4825.485,
			4827.821,
			4830.166,
			4831.342,
			4833.701,
			4836.07,
			4837.258,
			4840.838,
			4843.237,
			4845.646,
			4849.28,
			4851.715,
			4855.387,
			4856.617,
			4857.848,
			4861.557,
			4864.041,
			4867.784,
			4870.291,
			4872.805,
			4874.064,
			4876.59,
			4877.856,
			4880.395,
			4882.944,
			4884.224,
			4886.788,
			4889.364,
			4893.244,
			4895.842,
			4899.759,
			4901.068,
			4902.38,
			4906.321,
			4908.951,
			4912.906,
			4915.545,
			4918.191,
			4922.169,
			4923.496,
			4924.824,
			4928.818,
			4931.488,
			4935.503,
			4938.188,
			4940.881,
			4942.23,
			4944.933,
			4947.643,
			4949.001,
			4951.722,
			4953.085,
			4955.816,
			4958.554,
			4962.675,
			4965.43,
			4969.574,
			4970.959,
			4972.346,
			4976.516,
			4979.304,
			4983.498,
			4986.302,
			4989.113,
			4990.522,
			4993.346,
			4994.761,
			4997.595,
			5000.437,
			5001.859,
			5003.285,
			5007.569,
			5010.434,
			5014.745,
			5017.626,
			5020.514,
			5021.96,
			5024.857,
			5026.309,
			5029.215,
			5032.129,
			5036.512,
			5039.44,
			5043.846,
			5045.317,
			5046.791,
			5051.22,
			5054.18,
			5058.631,
			5061.606,
			5064.587,
			5066.079,
			5069.069,
			5070.566,
			5073.565,
			5076.569,
			5078.073,
			5081.086,
			5084.104,
			5088.641,
			5090.157,
			5091.674,
			5094.713,
			5097.757,
			5099.282,
			5103.864,
			5106.927,
			5109.994,
			5114.607,
			5117.689,
			5122.324,
			5123.87,
			5125.417,
			5130.06,
			5131.605,
			5133.146,
			5137.739,
			5140.766,
			5145.237,
			5148.171,
			5149.624,
			5152.5,
			5155.338,
			5156.742,
			5159.522,
			5162.267,
			5166.311,
			5167.64,
			5168.96,
			5172.862,
			5174.143,
			5175.416,
			5179.178,
			5181.643,
			5184.07,
			5187.648,
			5189.991,
			5191.15,
			5193.44,
			5194.57,
			5195.691,
			5198.997,
			5200.08,
			5201.151,
			5204.31,
			5206.366,
			5208.382,
			5209.376,
			5211.335,
			5213.256,
			5214.203,
			5216.068,
			5216.987,
			5218.799,
			5220.579,
			5223.183,
			5224.892,
			5227.38,
			5228.198,
			5229.011,
			5231.417,
			5232.994,
			5235.336,
			5236.891,
			5238.438,
			5239.209,
			5240.75,
			5241.529,
			5243.085,
			5244.637,
			5245.413,
			5246.198,
			5248.585,
			5250.175,
			5251.789,
			5252.608,
			5254.246,
			5255.886,
			5256.712,
			5258.369,
			5259.198,
			5260.861,
			5262.53,
			5265.042,
			5266.726,
			5269.264,
			5270.113,
			5270.964,
			5273.543,
			5275.271,
			5277.882,
			5279.634,
			5282.271,
			5284.034,
			5284.917,
			5286.686,
			5288.469,
			5289.361,
			5291.149,
			5292.942,
			5295.64,
			5297.44,
			5299.233,
			5301.02,
			5301.924,
			5303.728,
			5304.624,
			5306.428,
			5308.229,
			5310.936,
			5312.755,
			5315.496,
			5316.416,
			5317.339,
			5320.126,
			5322,
			5324.836,
			5326.743,
			5328.646,
			5329.61,
			5331.545,
			5332.517,
			5334.471,
			5336.453,
			5337.44,
			5339.427,
			5341.412,
			5344.438,
			5345.455,
			5346.474,
			5349.55,
			5350.583,
			5351.619,
			5354.743,
			5356.836,
			5358.928,
			5362.079,
			5364.192,
			5367.382,
			5368.451,
			5369.522,
			5372.75,
			5374.915,
			5378.18,
			5380.37,
			5382.568,
			5383.671,
			5385.885,
			5388.108,
			5389.224,
			5391.461,
			5392.584,
			5394.837,
			5397.099,
			5400.51,
			5402.797,
			5406.245,
			5407.399,
			5408.556,
			5412.041,
			5414.376,
			5417.896,
			5420.252,
			5422.619,
			5423.806,
			5426.187,
			5427.382,
			5429.778,
			5432.185,
			5433.393,
			5434.602,
			5438.246,
			5440.687,
			5443.137,
			5444.365,
			5446.829,
			5449.301,
			5450.54,
			5453.023,
			5454.268,
			5456.764,
			5459.268,
			5463.041,
			5465.565,
			5469.368,
			5470.641,
			5471.915,
			5475.748,
			5477.029,
			5478.313,
			5482.178,
			5484.766,
			5487.361,
			5488.663,
			5491.271,
			5492.575,
			5495.184,
			5497.799,
			5499.109,
			5501.735,
			5504.369,
			5508.333,
			5510.985,
			5513.644,
			5516.309,
			5517.644,
			5520.317,
			5521.657,
			5524.339,
			5527.025,
			5531.067,
			5533.77,
			5537.839,
			5539.198,
			5540.562,
			5544.66,
			5547.403,
			5551.529,
			5554.29,
			5557.058,
			5558.444,
			5561.222,
			5562.613,
			5565.401,
			5568.196,
			5569.596,
			5572.401,
			5575.213,
			5579.447,
			5580.862,
			5582.278,
			5586.537,
			5587.959,
			5589.383,
			5593.662,
			5596.521,
			5599.386,
			5603.697,
			5606.58,
			5610.919,
			5612.369,
			5613.82,
			5619.642,
			5621.101,
			5625.488,
			5628.421,
			5631.361,
			5632.833,
			5635.781,
			5638.736,
			5640.215,
			5644.662,
			5647.635,
			5650.613,
			5655.093,
			5658.088,
			5662.591,
			5664.095,
			5665.601,
			5670.126,
			5673.15,
			5677.698,
			5680.736,
			5683.781,
			5688.358,
			5689.888,
			5691.418,
			5696.01,
			5697.537,
			5699.06,
			5703.59,
			5706.566,
			5709.504,
			5710.959,
			5713.84,
			5716.684,
			5718.093,
			5720.883,
			5722.263,
			5724.995,
			5727.689,
			5731.657,
			5734.254,
			5738.076,
			5739.331,
			5740.577,
			5744.258,
			5746.667,
			5750.22,
			5752.546,
			5754.837,
			5755.968,
			5758.202,
			5759.305,
			5761.48,
			5764.673,
			5766.754,
			5768.816,
			5770.822,
			5773.771,
			5775.691,
			5777.578,
			5778.51,
			5780.34,
			5781.243,
			5783.034,
			5784.805,
			5787.433,
			5789.18,
			5791.786,
			5792.653,
			5793.521,
			5796.126,
			5797.863,
			5800.475,
			5802.226,
			5803.974,
			5804.848,
			5806.614,
			5807.496,
			5809.258,
			5811.929,
			5813.714,
			5815.504,
			5818.223,
			5819.127,
			5820.03,
			5822.791,
			5823.709,
			5824.628,
			5826.479,
			5827.407,
			5829.261,
			5832.057,
			5833.925,
			5835.797,
			5838.636,
			5839.583,
			5840.531,
			5843.423,
			5844.39,
			5845.358,
			5848.249,
			5850.18,
			5852.097,
			5853.05,
			5854.958,
			5856.866,
			5857.819,
			5859.727,
			5860.682,
			5862.618,
			5864.57,
			5867.514,
			5869.493,
			5872.462,
			5873.442,
			5874.424,
			5877.371,
			5879.298,
			5882.205,
			5884.113,
			5886.033,
			5886.997,
			5888.918,
			5889.883,
			5891.821,
			5894.764,
			5895.751,
			5898.737,
			5900.746,
			5902.766,
			5903.78,
			5905.817,
			5907.865,
			5908.894,
			5911.996,
			5914.069,
			5916.146,
			5919.281,
			5921.388,
			5924.554,
			5925.621,
			5926.708,
			5929.917,
			5932.081,
			5935.348,
			5937.539,
			5940.848,
			5943.068,
			5944.182,
			5946.419,
			5949.793,
			5952.055,
			5954.328,
			5956.609,
			5958.901,
			5960.049,
			5962.352,
			5964.663,
			5965.822,
			5969.313,
			5971.653,
			5974.002,
			5977.546,
			5979.92,
			5983.498,
			5984.695,
			5985.894,
			5989.5,
			5991.916,
			5995.555,
			5997.992,
			6000.438,
			6001.664,
			6004.123,
			6005.355,
			6007.827,
			6011.551,
			6014.044,
			6016.545,
			6020.312,
			6021.573,
			6022.835,
			6026.634,
			6027.904,
			6029.177,
			6033.007,
			6035.571,
			6039.431,
			6042.014,
			6044.604,
			6048.504,
			6049.805,
			6051.105,
			6056.325,
			6057.635,
			6061.574,
			6064.209,
			6066.852,
			6068.175,
			6070.828,
			6073.488,
			6074.82,
			6077.49,
			6078.828,
			6081.509,
			6084.197,
			6088.241,
			6090.946,
			6095.018,
			6096.378,
			6097.74,
			6101.837,
			6104.577,
			6108.7,
			6111.458,
			6113.267
		],
		[
			0,
			0.035,
			0.098,
			0.159,
			0.206,
			0.282,
			0.314,
			0.345,
			0.421,
			0.469,
			0.53,
			0.593,
			0.654,
			0.716,
			0.749,
			0.78,
			0.856,
			0.903,
			0.967,
			1.027,
			1.089,
			1.152,
			1.183,
			1.215,
			1.276,
			1.337,
			1.403,
			1.462,
			1.525,
			1.586,
			1.615,
			1.648,
			1.711,
			1.725,
			1.773,
			1.832,
			1.895,
			1.958,
			2.021,
			2.051,
			2.081,
			2.143,
			2.16,
			2.205,
			2.268,
			2.328,
			2.376,
			2.453,
			2.486,
			2.515,
			2.593,
			2.641,
			2.705,
			2.764,
			2.809,
			2.826,
			2.887,
			2.919,
			2.951,
			3.012,
			3.028,
			3.074,
			3.137,
			3.197,
			3.244,
			3.26,
			3.321,
			3.354,
			3.384,
			3.446,
			3.466,
			3.506,
			3.571,
			3.633,
			3.693,
			3.755,
			3.786,
			3.819,
			3.896,
			3.942,
			4.003,
			4.066,
			4.111,
			4.127,
			4.189,
			4.221,
			4.252,
			4.315,
			4.331,
			4.376,
			4.44,
			4.501,
			4.546,
			4.563,
			4.625,
			4.656,
			4.686,
			4.762,
			4.809,
			4.871,
			4.934,
			4.98,
			5.059,
			5.088,
			5.121,
			5.198,
			5.245,
			5.307,
			5.367,
			5.431,
			5.493,
			5.526,
			5.554,
			5.632,
			5.678,
			5.742,
			5.803,
			5.865,
			5.926,
			5.958,
			5.987,
			6.066,
			6.112,
			6.178,
			6.237,
			6.281,
			6.361,
			6.389,
			6.421,
			6.485,
			6.501,
			6.545,
			6.609,
			6.671,
			6.718,
			6.731,
			6.795,
			6.826,
			6.856,
			6.935,
			6.979,
			7.043,
			7.103,
			7.15,
			7.168,
			7.229,
			7.259,
			7.289,
			7.352,
			7.415,
			7.477,
			7.539,
			7.602,
			7.662,
			7.694,
			7.724,
			7.785,
			7.801,
			7.848,
			7.91,
			7.973,
			8.036,
			8.096,
			8.128,
			8.159,
			8.219,
			8.236,
			8.284,
			8.344,
			8.405,
			8.452,
			8.53,
			8.563,
			8.593,
			8.671,
			8.717,
			8.778,
			8.841,
			8.904,
			8.965,
			8.994,
			9.027,
			9.088,
			9.104,
			9.151,
			9.213,
			9.275,
			9.322,
			9.336,
			9.4,
			9.428,
			9.46,
			9.523,
			9.539,
			9.584,
			9.647,
			9.652,
			9.71,
			9.755,
			9.833,
			9.862,
			9.896,
			9.958,
			10.02,
			10.081,
			10.144,
			10.19,
			10.206,
			10.267,
			10.297,
			10.33,
			10.389,
			10.406,
			10.453,
			10.516,
			10.578,
			10.623,
			10.639,
			10.701,
			10.733,
			10.763,
			10.826,
			10.887,
			10.95,
			11.012,
			11.058,
			11.134,
			11.167,
			11.197,
			11.259,
			11.322,
			11.387,
			11.444,
			11.492,
			11.508,
			11.57,
			11.599,
			11.631,
			11.694,
			11.712,
			11.755,
			11.816,
			11.879,
			11.927,
			12.002,
			12.033,
			12.065,
			12.142,
			12.189,
			12.25,
			12.312,
			12.36,
			12.376,
			12.438,
			12.469,
			12.5,
			12.56,
			12.577,
			12.621,
			12.684,
			12.747,
			12.81,
			12.872,
			12.903,
			12.934,
			13.011,
			13.056,
			13.119,
			13.181,
			13.245,
			13.305,
			13.337,
			13.367,
			13.43,
			13.493,
			13.554,
			13.616,
			13.661,
			13.676,
			13.739,
			13.773,
			13.801,
			13.865,
			13.881,
			13.927,
			13.989,
			14.049,
			14.097,
			14.113,
			14.175,
			14.204,
			14.235,
			14.299,
			14.312,
			14.359,
			14.423,
			14.483,
			14.53,
			14.609,
			14.638,
			14.668,
			14.747,
			14.794,
			14.856,
			14.919,
			14.964,
			14.981,
			15.043,
			15.071,
			15.105,
			15.167,
			15.18,
			15.227,
			15.289,
			15.351,
			15.398,
			15.415,
			15.476,
			15.507,
			15.536,
			15.601,
			15.663,
			15.726,
			15.787,
			15.835,
			15.911,
			15.942,
			15.971,
			16.05,
			16.095,
			16.16,
			16.22,
			16.281,
			16.343,
			16.375,
			16.406,
			16.483,
			16.53,
			16.593,
			16.655,
			16.702,
			16.779,
			16.809,
			16.839,
			16.917,
			16.964,
			17.028,
			17.089,
			17.136,
			17.15,
			17.212,
			17.244,
			17.275,
			17.336,
			17.35,
			17.397,
			17.463,
			17.522,
			17.569,
			17.583,
			17.647,
			17.678,
			17.709,
			17.771,
			17.785,
			17.833,
			17.899,
			17.955,
			18.004,
			18.081,
			18.112,
			18.143,
			18.204,
			18.267,
			18.327,
			18.389,
			18.436,
			18.453,
			18.514,
			18.547,
			18.577,
			18.655,
			18.701,
			18.762,
			18.823,
			18.872,
			18.886,
			18.949,
			18.981,
			19.01,
			19.071,
			19.087,
			19.135,
			19.198,
			19.259,
			19.306,
			19.383,
			19.413,
			19.444,
			19.506,
			19.569,
			19.63,
			19.693,
			19.737,
			19.754,
			19.818,
			19.847,
			19.878,
			19.939,
			19.96,
			20.004,
			20.065,
			20.127,
			20.173,
			20.189,
			20.252,
			20.282,
			20.311,
			20.373,
			20.391,
			20.436,
			20.497,
			20.56,
			20.606,
			20.685,
			20.716,
			20.747,
			20.81,
			20.87,
			20.933,
			20.993,
			21.056,
			21.12,
			21.149,
			21.18,
			21.242,
			21.26,
			21.305,
			21.368,
			21.428,
			21.475,
			21.553,
			21.584,
			21.616,
			21.676,
			21.739,
			21.802,
			21.864,
			21.925,
			21.987,
			22.049,
			22.112,
			22.125,
			22.172,
			22.235,
			22.296,
			22.359,
			22.421,
			22.452,
			22.482,
			22.544,
			22.56,
			22.607,
			22.669,
			22.732,
			22.777,
			22.855,
			22.886,
			22.916,
			22.978,
			23.039,
			23.102,
			23.164,
			23.212,
			23.228,
			23.288,
			23.319,
			23.352,
			23.412,
			23.429,
			23.477,
			23.539,
			23.598,
			23.646,
			23.66,
			23.725,
			23.754,
			23.786,
			23.848,
			23.862,
			23.91,
			23.973,
			24.032,
			24.096,
			24.158,
			24.189,
			24.219,
			24.28,
			24.343,
			24.404,
			24.468,
			24.513,
			24.529,
			24.591,
			24.62,
			24.653,
			24.715,
			24.731,
			24.778,
			24.84,
			24.902,
			24.947,
			24.963,
			25.026,
			25.056,
			25.088,
			25.148,
			25.165,
			25.213,
			25.274,
			25.336,
			25.382,
			25.46,
			25.489,
			25.522,
			25.6,
			25.646,
			25.709,
			25.768,
			25.815,
			25.832,
			25.894,
			25.925,
			25.954,
			26.034,
			26.081,
			26.147,
			26.204,
			26.251,
			26.329,
			26.359,
			26.389,
			26.467,
			26.514,
			26.577,
			26.637,
			26.685,
			26.701,
			26.762,
			26.793,
			26.825,
			26.885,
			26.902,
			26.949,
			27.01,
			27.072,
			27.118,
			27.135,
			27.196,
			27.228,
			27.258,
			27.32,
			27.337,
			27.382,
			27.444,
			27.506,
			27.553,
			27.629,
			27.661,
			27.693,
			27.755,
			27.817,
			27.877,
			27.94,
			27.986,
			28.002,
			28.064,
			28.096,
			28.127,
			28.189,
			28.209,
			28.249,
			28.313,
			28.373,
			28.421,
			28.435,
			28.499,
			28.528,
			28.56,
			28.623,
			28.636,
			28.683,
			28.746,
			28.809,
			28.854,
			28.933,
			28.965,
			28.996,
			29.056,
			29.12,
			29.181,
			29.243,
			29.288,
			29.304,
			29.367,
			29.396,
			29.427,
			29.489,
			29.504,
			29.552,
			29.613,
			29.676,
			29.722,
			29.739,
			29.801,
			29.831,
			29.863,
			29.926,
			29.987,
			30.049,
			30.111,
			30.171,
			30.234,
			30.27,
			30.296,
			30.36,
			30.42,
			30.483,
			30.545,
			30.592,
			30.605,
			30.669,
			30.7,
			30.731,
			30.793,
			30.808,
			30.854,
			30.917,
			30.978,
			31.041,
			31.102,
			31.135,
			31.166,
			31.243,
			31.287,
			31.352,
			31.414,
			31.46,
			31.535,
			31.567,
			31.599,
			31.677,
			31.722,
			31.786,
			31.847,
			31.894,
			31.909,
			31.969,
			32.001,
			32.033,
			32.095,
			32.11,
			32.156,
			32.219,
			32.282,
			32.343,
			32.405,
			32.436,
			32.468,
			32.528,
			32.59,
			32.652,
			32.715,
			32.762,
			32.778,
			32.839,
			32.869,
			32.901,
			32.963,
			32.978,
			33.025,
			33.089,
			33.15,
			33.211,
			33.274,
			33.305,
			33.334,
			33.396,
			33.412,
			33.459,
			33.522,
			33.582,
			33.629,
			33.705,
			33.738,
			33.769,
			33.846,
			33.893,
			33.955,
			34.016,
			34.064,
			34.078,
			34.139,
			34.172,
			34.202,
			34.266,
			34.281,
			34.327,
			34.393,
			34.452,
			34.497,
			34.514,
			34.576,
			34.604,
			34.637,
			34.7,
			34.76,
			34.823,
			34.884,
			34.946,
			35.01,
			35.04,
			35.072,
			35.133,
			35.15,
			35.196,
			35.258,
			35.32,
			35.366,
			35.38,
			35.443,
			35.475,
			35.505,
			35.582,
			35.628,
			35.69,
			35.753,
			35.799,
			35.815,
			35.878,
			35.909,
			35.939,
			36.002,
			36.064,
			36.126,
			36.187,
			36.25,
			36.311,
			36.343,
			36.372,
			36.434,
			36.456,
			36.496,
			36.559,
			36.622,
			36.668,
			36.684,
			36.746,
			36.775,
			36.806,
			36.868,
			36.886,
			36.932,
			36.995,
			37.055,
			37.102,
			37.178,
			37.21,
			37.243,
			37.32,
			37.364,
			37.427,
			37.491,
			37.536,
			37.55,
			37.613,
			37.643,
			37.677,
			37.739,
			37.753,
			37.799,
			37.861,
			37.925,
			37.986,
			38.049,
			38.078,
			38.111,
			38.171,
			38.187,
			38.234,
			38.294,
			38.356,
			38.405,
			38.483,
			38.517,
			38.544,
			38.607,
			38.667,
			38.729,
			38.793,
			38.839,
			38.855,
			38.917,
			38.947,
			38.978,
			39.04,
			39.056,
			39.103,
			39.163,
			39.226,
			39.288,
			39.351,
			39.381,
			39.412,
			39.472,
			39.49,
			39.535,
			39.599,
			39.661,
			39.708,
			39.784,
			39.815,
			39.847,
			39.908,
			39.969,
			40.033,
			40.094,
			40.14,
			40.158,
			40.219,
			40.249,
			40.28,
			40.343,
			40.359,
			40.405,
			40.466,
			40.527,
			40.578,
			40.652,
			40.685,
			40.714,
			40.777,
			40.838,
			40.9,
			40.963,
			41.009,
			41.086,
			41.116,
			41.149,
			41.226,
			41.272,
			41.334,
			41.398,
			41.458,
			41.521,
			41.55,
			41.584,
			41.645,
			41.659,
			41.706,
			41.769,
			41.831,
			41.878,
			41.954,
			41.986,
			42.015,
			42.078,
			42.141,
			42.204,
			42.263,
			42.312,
			42.328,
			42.389,
			42.421,
			42.452,
			42.512,
			42.528,
			42.575,
			42.639,
			42.703,
			42.746,
			42.76,
			42.823,
			42.853,
			42.886,
			42.963,
			43.01,
			43.072,
			43.133,
			43.179,
			43.255,
			43.287,
			43.32,
			43.382,
			43.444,
			43.505,
			43.566,
			43.627,
			43.692,
			43.722,
			43.752,
			43.815,
			43.83,
			43.877,
			43.937,
			44.001,
			44.049,
			44.061,
			44.126,
			44.156,
			44.187,
			44.263,
			44.312,
			44.373,
			44.435,
			44.481,
			44.559,
			44.59,
			44.622,
			44.682,
			44.703,
			44.744,
			44.806,
			44.87,
			44.916,
			44.931,
			44.993,
			45.022,
			45.056,
			45.117,
			45.134,
			45.178,
			45.239,
			45.302,
			45.349,
			45.365,
			45.428,
			45.459,
			45.488,
			45.566,
			45.613,
			45.676,
			45.738,
			45.785,
			45.86,
			45.893,
			45.921,
			45.999,
			46.048,
			46.11,
			46.172,
			46.217,
			46.233,
			46.296,
			46.326,
			46.356,
			46.42,
			46.435,
			46.482,
			46.542,
			46.604,
			46.667,
			46.729,
			46.765,
			46.792,
			46.852,
			46.915,
			46.978,
			47.038,
			47.087,
			47.102,
			47.163,
			47.194,
			47.225,
			47.302,
			47.349,
			47.41,
			47.473,
			47.519,
			47.537,
			47.598,
			47.629,
			47.66,
			47.723,
			47.737,
			47.783,
			47.847,
			47.908,
			47.954,
			48.031,
			48.064,
			48.093,
			48.154,
			48.218,
			48.283,
			48.34,
			48.388,
			48.405,
			48.465,
			48.497,
			48.526,
			48.605,
			48.652,
			48.715,
			48.776,
			48.828,
			48.899,
			48.932,
			48.963,
			49.039,
			49.087,
			49.15,
			49.21,
			49.27,
			49.334,
			49.363,
			49.396,
			49.471,
			49.519,
			49.583,
			49.645,
			49.689,
			49.705,
			49.769,
			49.798,
			49.829,
			49.892,
			49.908,
			49.954,
			50.019,
			50.077,
			50.14,
			50.203,
			50.232,
			50.263,
			50.326,
			50.388,
			50.449,
			50.512,
			50.558,
			50.636,
			50.667,
			50.699,
			50.761,
			50.775,
			50.821,
			50.885,
			50.946,
			50.993,
			51.009,
			51.07,
			51.102,
			51.132,
			51.193,
			51.209,
			51.255,
			51.319,
			51.38,
			51.427,
			51.505,
			51.536,
			51.567,
			51.627,
			51.69,
			51.752,
			51.815,
			51.876,
			51.937,
			51.97,
			51.999,
			52.062,
			52.076,
			52.123,
			52.187,
			52.247,
			52.293,
			52.309,
			52.372,
			52.402,
			52.433,
			52.496,
			52.513,
			52.559,
			52.622,
			52.683,
			52.728,
			52.805,
			52.836,
			52.868,
			52.931,
			52.951,
			52.992,
			53.054,
			53.116,
			53.162,
			53.178,
			53.242,
			53.271,
			53.304,
			53.381,
			53.427,
			53.488,
			53.549,
			53.612,
			53.676,
			53.706,
			53.737,
			53.813,
			53.86,
			53.921,
			53.983,
			54.03,
			54.11,
			54.141,
			54.171,
			54.247,
			54.295,
			54.361,
			54.419,
			54.466,
			54.48,
			54.542,
			54.573,
			54.603,
			54.667,
			54.681,
			54.728,
			54.792,
			54.854,
			54.9,
			54.977,
			55.037,
			55.102,
			55.163,
			55.225,
			55.288,
			55.35,
			55.411,
			55.443,
			55.472,
			55.549,
			55.596,
			55.66,
			55.72,
			55.784,
			55.846,
			55.877,
			55.907,
			55.969,
			55.984,
			56.032,
			56.095,
			56.154,
			56.218,
			56.278,
			56.311,
			56.339,
			56.402,
			56.466,
			56.527,
			56.587,
			56.635,
			56.652,
			56.713,
			56.743,
			56.775,
			56.836,
			56.852,
			56.898,
			56.96,
			57.022,
			57.086,
			57.146,
			57.18,
			57.21,
			57.271,
			57.286,
			57.334,
			57.395,
			57.459,
			57.504,
			57.582,
			57.613,
			57.643,
			57.706,
			57.767,
			57.83,
			57.892,
			57.937,
			57.954,
			58.015,
			58.045,
			58.077,
			58.138,
			58.155,
			58.202,
			58.264,
			58.327,
			58.372,
			58.388,
			58.451,
			58.481,
			58.511,
			58.59,
			58.637,
			58.699,
			58.76,
			58.806,
			58.882,
			58.916,
			58.946,
			59.023,
			59.07,
			59.136,
			59.195,
			59.254,
			59.318,
			59.348,
			59.378,
			59.443,
			59.458,
			59.504,
			59.566,
			59.629,
			59.672,
			59.689,
			59.751,
			59.781,
			59.814,
			59.875,
			59.937,
			60,
			60.062,
			60.122,
			60.186,
			60.216,
			60.246,
			60.31,
			60.326,
			60.37,
			60.434,
			60.496,
			60.556,
			60.619,
			60.649,
			60.682,
			60.744,
			60.76,
			60.806,
			60.87,
			60.93,
			60.977,
			61.053,
			61.083,
			61.115,
			61.178,
			61.201,
			61.239,
			61.301,
			61.365,
			61.41,
			61.427,
			61.488,
			61.519,
			61.549,
			61.611,
			61.628,
			61.672,
			61.737,
			61.799,
			61.86,
			61.923,
			61.953,
			61.984,
			62.046,
			62.063,
			62.106,
			62.171,
			62.231,
			62.278,
			62.357,
			62.388,
			62.419,
			62.481,
			62.542,
			62.604,
			62.666,
			62.712,
			62.728,
			62.791,
			62.821,
			62.852,
			62.915,
			62.929,
			62.977,
			63.038,
			63.099,
			63.163,
			63.225,
			63.26,
			63.286,
			63.349,
			63.364,
			63.411,
			63.472,
			63.534,
			63.58,
			63.659,
			63.689,
			63.723,
			63.783,
			63.844,
			63.908,
			63.968,
			64.029,
			64.093,
			64.123,
			64.153,
			64.231,
			64.278,
			64.341,
			64.403,
			64.465,
			64.526,
			64.558,
			64.587,
			64.652,
			64.713,
			64.775,
			64.835,
			64.882,
			64.959,
			64.992,
			65.021,
			65.085,
			65.101,
			65.148,
			65.209,
			65.27,
			65.322,
			65.332,
			65.394,
			65.425,
			65.454,
			65.52,
			65.533,
			65.58,
			65.644,
			65.705,
			65.752,
			65.83,
			65.86,
			65.891,
			65.968,
			66.015,
			66.078,
			66.139,
			66.202,
			66.262,
			66.294,
			66.325,
			66.388,
			66.402,
			66.449,
			66.51,
			66.572,
			66.619,
			66.635,
			66.697,
			66.727,
			66.759,
			66.835,
			66.882,
			66.944,
			67.008,
			67.053,
			67.13,
			67.163,
			67.194,
			67.255,
			67.316,
			67.384,
			67.442,
			67.489,
			67.503,
			67.566,
			67.595,
			67.627,
			67.689,
			67.706,
			67.751,
			67.814,
			67.875,
			67.922,
			67.937,
			67.997,
			68.029,
			68.062,
			68.124,
			68.137,
			68.185,
			68.246,
			68.309,
			68.355,
			68.433,
			68.465,
			68.496,
			68.558,
			68.619,
			68.681,
			68.743,
			68.805,
			68.867,
			68.897,
			68.93,
			69.005,
			69.053,
			69.116,
			69.176,
			69.223,
			69.239,
			69.302,
			69.332,
			69.365,
			69.426,
			69.487,
			69.549,
			69.61,
			69.674,
			69.735,
			69.768,
			69.799,
			69.86,
			69.877,
			69.92,
			69.985,
			70.046,
			70.093,
			70.109,
			70.169,
			70.201,
			70.23,
			70.309,
			70.354,
			70.419,
			70.48,
			70.525,
			70.604,
			70.635,
			70.666,
			70.727,
			70.791,
			70.853,
			70.912,
			70.959,
			70.976,
			71.038,
			71.068,
			71.099,
			71.163,
			71.176,
			71.222,
			71.286,
			71.347,
			71.41,
			71.47,
			71.507,
			71.535,
			71.61,
			71.659,
			71.72,
			71.781,
			71.827,
			71.904,
			71.936,
			71.967,
			72.029,
			72.093,
			72.154,
			72.214,
			72.261,
			72.277,
			72.338,
			72.369,
			72.403,
			72.464,
			72.48,
			72.526,
			72.589,
			72.651,
			72.698,
			72.711,
			72.774,
			72.805,
			72.837,
			72.899,
			72.912,
			72.959,
			73.023,
			73.084,
			73.131,
			73.209,
			73.24,
			73.27,
			73.331,
			73.394,
			73.456,
			73.517,
			73.57,
			73.581,
			73.643,
			73.672,
			73.704,
			73.765,
			73.781,
			73.829,
			73.891,
			73.952,
			73.998,
			74.013,
			74.076,
			74.108,
			74.138,
			74.199,
			74.217,
			74.261,
			74.325,
			74.385,
			74.433,
			74.511,
			74.542,
			74.573,
			74.634,
			74.695,
			74.759,
			74.819,
			74.868,
			74.882,
			74.943,
			74.975,
			75.008,
			75.069,
			75.083,
			75.13,
			75.193,
			75.253,
			75.301,
			75.377,
			75.41,
			75.442,
			75.503,
			75.564,
			75.628,
			75.69,
			75.734,
			75.751,
			75.811,
			75.843,
			75.873,
			75.937,
			75.953,
			75.999,
			76.062,
			76.122,
			76.168,
			76.186,
			76.246,
			76.278,
			76.309,
			76.37,
			76.386,
			76.433,
			76.496,
			76.558,
			76.603,
			76.682,
			76.711,
			76.743,
			76.82,
			76.865,
			76.928,
			76.992,
			77.036,
			77.052,
			77.115,
			77.144,
			77.177,
			77.255,
			77.301,
			77.363,
			77.423,
			77.486,
			77.548,
			77.579,
			77.612,
			77.672,
			77.692,
			77.735,
			77.796,
			77.86,
			77.904,
			77.982,
			78.012,
			78.044,
			78.107,
			78.169,
			78.23,
			78.293,
			78.355,
			78.417,
			78.448,
			78.479,
			78.556,
			78.603,
			78.665,
			78.726,
			78.772,
			78.788,
			78.851,
			78.882,
			78.912,
			78.975,
			79.038,
			79.099,
			79.162,
			79.208,
			79.285,
			79.315,
			79.346,
			79.426,
			79.47,
			79.535,
			79.594,
			79.642,
			79.658,
			79.718,
			79.754,
			79.782,
			79.843,
			79.859,
			79.904,
			79.968,
			80.029,
			80.091,
			80.152,
			80.185,
			80.216,
			80.278,
			80.338,
			80.401,
			80.464,
			80.509,
			80.526,
			80.586,
			80.618,
			80.65,
			80.71,
			80.726,
			80.774,
			80.837,
			80.898,
			80.943,
			80.959,
			81.02,
			81.052,
			81.082,
			81.16,
			81.206,
			81.27,
			81.331,
			81.378,
			81.454,
			81.487,
			81.519,
			81.579,
			81.643,
			81.704,
			81.765,
			81.828,
			81.891,
			81.92,
			81.953,
			82.028,
			82.075,
			82.138,
			82.201,
			82.263,
			82.323,
			82.354,
			82.387,
			82.462,
			82.51,
			82.571,
			82.634,
			82.696,
			82.758,
			82.789,
			82.819,
			82.88,
			82.942,
			83.004,
			83.068,
			83.115,
			83.129,
			83.193,
			83.223,
			83.254,
			83.314,
			83.331,
			83.378,
			83.441,
			83.501,
			83.563,
			83.626,
			83.655,
			83.687,
			83.748,
			83.766,
			83.813,
			83.879,
			83.937,
			83.982,
			84.06,
			84.092,
			84.121,
			84.185,
			84.247,
			84.309,
			84.369,
			84.431,
			84.493,
			84.526,
			84.555,
			84.618,
			84.634,
			84.681,
			84.743,
			84.805,
			84.865,
			84.927,
			84.959,
			84.99,
			85.069,
			85.113,
			85.178,
			85.238,
			85.301,
			85.361,
			85.393,
			85.423,
			85.486,
			85.502,
			85.549,
			85.61,
			85.671,
			85.72,
			85.734,
			85.796,
			85.827,
			85.856,
			85.919,
			85.938,
			85.982,
			86.045,
			86.104,
			86.152,
			86.229,
			86.262,
			86.293,
			86.37,
			86.416,
			86.478,
			86.539,
			86.601,
			86.663,
			86.695,
			86.727,
			86.802,
			86.85,
			86.914,
			86.976,
			87.021,
			87.036,
			87.098,
			87.13,
			87.162,
			87.223,
			87.238,
			87.285,
			87.346,
			87.41,
			87.471,
			87.534,
			87.563,
			87.594,
			87.673,
			87.719,
			87.779,
			87.843,
			87.905,
			87.966,
			88.002,
			88.027,
			88.091,
			88.104,
			88.153,
			88.216,
			88.276,
			88.324,
			88.34,
			88.401,
			88.433,
			88.463,
			88.526,
			88.586,
			88.648,
			88.709,
			88.772,
			88.836,
			88.866,
			88.897,
			88.959,
			88.975,
			89.021,
			89.082,
			89.143,
			89.193,
			89.205,
			89.268,
			89.3,
			89.33,
			89.409,
			89.455,
			89.518,
			89.578,
			89.627,
			89.704,
			89.735,
			89.764,
			89.826,
			89.888,
			89.953,
			90.013,
			90.076,
			90.136,
			90.168,
			90.199,
			90.262,
			90.276,
			90.321,
			90.385,
			90.448,
			90.509,
			90.571,
			90.602,
			90.632,
			90.696,
			90.709,
			90.755,
			90.82,
			90.882,
			90.927,
			91.004,
			91.036,
			91.066,
			91.13,
			91.191,
			91.253,
			91.314,
			91.361,
			91.377,
			91.438,
			91.47,
			91.501,
			91.564,
			91.578,
			91.626,
			91.688,
			91.749,
			91.796,
			91.812,
			91.871,
			91.904,
			91.934,
			91.998,
			92.011,
			92.06,
			92.121,
			92.125,
			92.182,
			92.23,
			92.308,
			92.338,
			92.369,
			92.445,
			92.495,
			92.555,
			92.617,
			92.68,
			92.743,
			92.773,
			92.803,
			92.866,
			92.882,
			92.928,
			92.988,
			93.051,
			93.098,
			93.114,
			93.177,
			93.206,
			93.237,
			93.314,
			93.362,
			93.427,
			93.485,
			93.532,
			93.609,
			93.641,
			93.673,
			93.733,
			93.796,
			93.856,
			93.919,
			93.98,
			94.043,
			94.073,
			94.104,
			94.167,
			94.188,
			94.229,
			94.292,
			94.354,
			94.401,
			94.477,
			94.508,
			94.539,
			94.602,
			94.663,
			94.727,
			94.787,
			94.833,
			94.913,
			94.943,
			94.974,
			95.036,
			95.052,
			95.099,
			95.16,
			95.221,
			95.268,
			95.284,
			95.345,
			95.376,
			95.408,
			95.47,
			95.487,
			95.531,
			95.593,
			95.655,
			95.703,
			95.779,
			95.811,
			95.843,
			95.903,
			95.966,
			96.028,
			96.089,
			96.153,
			96.215,
			96.25,
			96.276,
			96.339,
			96.353,
			96.4,
			96.463,
			96.525,
			96.572,
			96.586,
			96.648,
			96.68,
			96.71,
			96.77,
			96.788,
			96.833,
			96.896,
			96.956,
			97.004,
			97.082,
			97.113,
			97.145,
			97.204,
			97.268,
			97.331,
			97.393,
			97.438,
			97.455,
			97.516,
			97.546,
			97.579,
			97.641,
			97.654,
			97.702,
			97.766,
			97.826,
			97.874,
			97.887,
			97.951,
			97.981,
			98.012,
			98.073,
			98.09,
			98.135,
			98.198,
			98.262,
			98.311,
			98.385,
			98.417,
			98.447,
			98.509,
			98.525,
			98.569,
			98.633,
			98.696,
			98.741,
			98.755,
			98.819,
			98.849,
			98.88,
			98.959,
			99.004,
			99.068,
			99.128,
			99.176,
			99.252,
			99.285,
			99.315,
			99.378,
			99.438,
			99.501,
			99.563,
			99.61,
			99.626,
			99.688,
			99.717,
			99.75,
			99.812,
			99.826,
			99.871,
			99.935,
			99.997,
			100.043,
			100.059,
			100.122,
			100.151,
			100.183,
			100.26,
			100.305,
			100.372,
			100.431,
			100.477,
			100.553,
			100.586,
			100.616,
			100.695,
			100.739,
			100.803,
			100.866,
			100.912,
			100.927,
			100.988,
			101.02,
			101.052,
			101.112,
			101.128,
			101.176,
			101.237,
			101.298,
			101.346,
			101.36,
			101.424,
			101.454,
			101.485,
			101.548,
			101.561,
			101.609,
			101.673,
			101.734,
			101.779,
			101.855,
			101.888,
			101.92,
			101.982,
			102.043,
			102.105,
			102.168,
			102.212,
			102.228,
			102.291,
			102.32,
			102.354,
			102.436,
			102.478,
			102.539,
			102.6,
			102.648,
			102.664,
			102.725,
			102.755,
			102.788,
			102.849,
			102.866,
			102.912,
			102.972,
			103.034,
			103.08,
			103.159,
			103.191,
			103.22,
			103.284,
			103.344,
			103.409,
			103.468,
			103.516,
			103.53,
			103.594,
			103.623,
			103.655,
			103.734,
			103.778,
			103.843,
			103.902,
			103.951,
			104.029,
			104.057,
			104.09,
			104.151,
			104.214,
			104.276,
			104.337,
			104.384,
			104.462,
			104.497,
			104.521,
			104.587,
			104.601,
			104.649,
			104.709,
			104.771,
			104.821,
			104.834,
			104.896,
			104.927,
			104.958,
			105.02,
			105.036,
			105.08,
			105.145,
			105.205,
			105.253,
			105.328,
			105.36,
			105.392,
			105.468,
			105.516,
			105.578,
			105.639,
			105.687,
			105.702,
			105.763,
			105.794,
			105.825,
			105.903,
			105.949,
			106.012,
			106.072,
			106.12,
			106.136,
			106.198,
			106.229,
			106.26,
			106.338,
			106.384,
			106.444,
			106.506,
			106.559,
			106.63,
			106.663,
			106.693,
			106.772,
			106.817,
			106.879,
			106.943,
			106.988,
			107.004,
			107.066,
			107.096,
			107.128,
			107.189,
			107.204,
			107.253,
			107.319,
			107.376,
			107.423,
			107.437,
			107.499,
			107.531,
			107.563,
			107.639,
			107.686,
			107.749,
			107.811,
			107.856,
			107.934,
			107.966,
			107.995,
			108.073,
			108.119,
			108.183,
			108.243,
			108.289,
			108.305,
			108.367,
			108.4,
			108.43,
			108.493,
			108.506,
			108.554,
			108.615,
			108.677,
			108.723,
			108.739,
			108.802,
			108.833,
			108.864,
			108.943,
			108.988,
			109.05,
			109.113,
			109.16,
			109.236,
			109.266,
			109.299,
			109.359,
			109.375,
			109.423,
			109.485,
			109.546,
			109.609,
			109.671,
			109.702,
			109.733,
			109.81,
			109.857,
			109.919,
			109.98,
			110.043,
			110.104,
			110.135,
			110.167,
			110.228,
			110.289,
			110.353,
			110.415,
			110.461,
			110.475,
			110.538,
			110.571,
			110.601,
			110.663,
			110.682,
			110.726,
			110.787,
			110.847,
			110.895,
			110.912,
			110.973,
			111.005,
			111.035,
			111.096,
			111.113,
			111.159,
			111.22,
			111.282,
			111.33,
			111.405,
			111.438,
			111.468,
			111.532,
			111.594,
			111.654,
			111.716,
			111.763,
			111.779,
			111.841,
			111.873,
			111.904,
			111.965,
			111.981,
			112.027,
			112.087,
			112.15,
			112.196,
			112.212,
			112.276,
			112.305,
			112.338,
			112.399,
			112.415,
			112.46,
			112.524,
			112.584,
			112.633,
			112.709,
			112.744,
			112.772,
			112.832,
			112.895,
			112.958,
			113.019,
			113.065,
			113.082,
			113.144,
			113.175,
			113.204,
			113.266,
			113.283,
			113.329,
			113.392,
			113.452,
			113.499,
			113.515,
			113.577,
			113.609,
			113.639,
			113.717,
			113.764,
			113.827,
			113.888,
			113.949,
			114.01,
			114.043,
			114.074,
			114.134,
			114.152,
			114.196,
			114.26,
			114.322,
			114.367,
			114.383,
			114.445,
			114.477,
			114.509,
			114.569,
			114.583,
			114.63,
			114.692,
			114.755,
			114.805,
			114.88,
			114.91,
			114.942,
			115.004,
			115.067,
			115.126,
			115.189,
			115.252,
			115.312,
			115.344,
			115.376,
			115.438,
			115.452,
			115.499,
			115.563,
			115.625,
			115.686,
			115.747,
			115.777,
			115.81,
			115.87,
			115.887,
			115.934,
			115.994,
			116.058,
			116.103,
			116.18,
			116.213,
			116.243,
			116.304,
			116.368,
			116.428,
			116.493,
			116.539,
			116.553,
			116.616,
			116.647,
			116.677,
			116.738,
			116.755,
			116.801,
			116.866,
			116.927,
			116.97,
			116.987,
			117.05,
			117.082,
			117.111,
			117.173,
			117.187,
			117.236,
			117.299,
			117.36,
			117.406,
			117.485,
			117.515,
			117.546,
			117.608,
			117.669,
			117.73,
			117.793,
			117.838,
			117.855,
			117.918,
			117.95,
			117.981,
			118.043,
			118.059,
			118.105,
			118.166,
			118.229,
			118.275,
			118.351,
			118.382,
			118.415,
			118.476,
			118.539,
			118.599,
			118.663,
			118.71,
			118.725,
			118.785,
			118.816,
			118.849,
			118.932,
			118.971,
			119.033,
			119.096,
			119.143,
			119.159,
			119.219,
			119.25,
			119.281,
			119.345,
			119.359,
			119.405,
			119.469,
			119.53,
			119.577,
			119.655,
			119.685,
			119.716,
			119.777,
			119.839,
			119.903,
			119.963,
			120.025,
			120.089,
			120.119,
			120.149,
			120.228,
			120.275,
			120.336,
			120.4,
			120.459,
			120.522,
			120.552,
			120.584,
			120.645,
			120.662,
			120.71,
			120.77,
			120.832,
			120.88,
			120.955,
			120.992,
			121.019,
			121.08,
			121.142,
			121.205,
			121.268,
			121.314,
			121.328,
			121.391,
			121.421,
			121.452,
			121.514,
			121.531,
			121.577,
			121.638,
			121.699,
			121.747,
			121.763,
			121.824,
			121.855,
			121.887,
			121.965,
			122.01,
			122.074,
			122.135,
			122.182,
			122.26,
			122.29,
			122.319,
			122.398,
			122.443,
			122.505,
			122.57,
			122.616,
			122.631,
			122.693,
			122.725,
			122.754,
			122.815,
			122.832,
			122.88,
			122.942,
			123.001,
			123.054,
			123.127,
			123.159,
			123.188,
			123.252,
			123.311,
			123.376,
			123.438,
			123.482,
			123.56,
			123.593,
			123.621,
			123.685,
			123.746,
			123.81,
			123.871,
			123.916,
			123.934,
			123.995,
			124.026,
			124.058,
			124.12,
			124.134,
			124.182,
			124.242,
			124.304,
			124.352,
			124.429,
			124.459,
			124.491,
			124.554,
			124.615,
			124.677,
			124.738,
			124.786,
			124.801,
			124.863,
			124.893,
			124.926,
			124.988,
			125.004,
			125.049,
			125.115,
			125.173,
			125.236,
			125.296,
			125.329,
			125.359,
			125.421,
			125.437,
			125.483,
			125.544,
			125.608,
			125.669,
			125.732,
			125.763,
			125.793,
			125.855,
			125.916,
			125.98,
			126.042,
			126.089,
			126.102,
			126.167,
			126.197,
			126.226,
			126.287,
			126.304,
			126.352,
			126.413,
			126.476,
			126.522,
			126.536,
			126.599,
			126.63,
			126.661,
			126.737,
			126.785,
			126.846,
			126.909,
			126.954,
			127.034,
			127.066,
			127.095,
			127.159,
			127.176,
			127.22,
			127.282,
			127.343,
			127.404,
			127.467,
			127.499,
			127.531,
			127.59,
			127.606,
			127.654,
			127.717,
			127.778,
			127.822,
			127.84,
			127.902,
			127.933,
			127.965,
			128.042,
			128.087,
			128.149,
			128.213,
			128.258,
			128.275,
			128.335,
			128.368,
			128.399,
			128.461,
			128.476,
			128.521,
			128.584,
			128.645,
			128.693,
			128.708,
			128.769,
			128.801,
			128.831,
			128.895,
			128.909,
			128.955,
			129.019,
			129.08,
			129.126,
			129.204,
			129.239,
			129.267,
			129.328,
			129.389,
			129.453,
			129.515,
			129.56,
			129.576,
			129.638,
			129.668,
			129.701,
			129.763,
			129.777,
			129.825,
			129.886,
			129.949,
			129.996,
			130.009,
			130.071,
			130.104,
			130.135,
			130.197,
			130.213,
			130.258,
			130.32,
			130.382,
			130.429,
			130.507,
			130.538,
			130.569,
			130.631,
			130.693,
			130.755,
			130.816,
			130.879,
			130.939,
			130.972,
			131.001,
			131.064,
			131.08,
			131.127,
			131.19,
			131.251,
			131.303,
			131.373,
			131.404,
			131.437,
			131.497,
			131.515,
			131.56,
			131.622,
			131.683,
			131.731,
			131.806,
			131.841,
			131.87,
			131.932,
			131.995,
			132.056,
			132.117,
			132.164,
			132.181,
			132.242,
			132.275,
			132.305,
			132.366,
			132.383,
			132.428,
			132.49,
			132.552,
			132.599,
			132.615,
			132.678,
			132.706,
			132.738,
			132.817,
			132.864,
			132.923,
			132.987,
			133.032,
			133.112,
			133.143,
			133.171,
			133.25,
			133.296,
			133.363,
			133.421,
			133.466,
			133.484,
			133.546,
			133.576,
			133.605,
			133.668,
			133.683,
			133.73,
			133.793,
			133.854,
			133.902,
			133.979,
			134.01,
			134.041,
			134.103,
			134.164,
			134.227,
			134.287,
			134.336,
			134.35,
			134.412,
			134.442,
			134.475,
			134.538,
			134.552,
			134.598,
			134.659,
			134.721,
			134.768,
			134.785,
			134.846,
			134.878,
			134.91,
			134.972,
			134.986,
			135.032,
			135.096,
			135.158,
			135.202,
			135.282,
			135.312,
			135.342,
			135.406,
			135.425,
			135.468,
			135.529,
			135.591,
			135.637,
			135.654,
			135.716,
			135.746,
			135.776,
			135.838,
			135.855,
			135.901,
			135.962,
			136.026,
			136.087,
			136.148,
			136.181,
			136.211,
			136.274,
			136.287,
			136.335,
			136.397,
			136.46,
			136.521,
			136.582,
			136.614,
			136.645,
			136.721,
			136.768,
			136.831,
			136.893,
			136.938,
			136.955,
			137.018,
			137.049,
			137.08,
			137.141,
			137.155,
			137.202,
			137.266,
			137.327,
			137.375,
			137.387,
			137.452,
			137.487,
			137.514,
			137.591,
			137.638,
			137.7,
			137.76,
			137.809,
			137.884,
			137.916,
			137.946,
			138.026,
			138.072,
			138.134,
			138.194,
			138.243,
			138.258,
			138.32,
			138.351,
			138.382,
			138.46,
			138.504,
			138.567,
			138.629,
			138.676,
			138.755,
			138.785,
			138.815,
			138.88,
			138.941,
			139.001,
			139.065,
			139.11,
			139.189,
			139.219,
			139.249,
			139.31,
			139.327,
			139.374,
			139.436,
			139.498,
			139.548,
			139.56,
			139.622,
			139.653,
			139.684,
			139.746,
			139.76,
			139.806,
			139.871,
			139.932,
			139.978,
			140.054,
			140.087,
			140.118,
			140.18,
			140.243,
			140.304,
			140.366,
			140.412,
			140.429,
			140.49,
			140.521,
			140.552,
			140.628,
			140.676,
			140.737,
			140.798,
			140.862,
			140.923,
			140.954,
			140.987,
			141.049,
			141.063,
			141.11,
			141.172,
			141.234,
			141.279,
			141.358,
			141.39,
			141.42,
			141.482,
			141.543,
			141.612,
			141.668,
			141.715,
			141.729,
			141.793,
			141.823,
			141.854,
			141.931,
			141.978,
			142.041,
			142.103,
			142.149,
			142.163,
			142.227,
			142.258,
			142.288,
			142.349,
			142.365,
			142.411,
			142.475,
			142.537,
			142.582,
			142.66,
			142.692,
			142.723,
			142.784,
			142.801,
			142.845,
			142.909,
			142.97,
			143.018,
			143.031,
			143.093,
			143.126,
			143.154,
			143.219,
			143.234,
			143.28,
			143.341,
			143.404,
			143.452,
			143.527,
			143.56,
			143.59,
			143.652,
			143.714,
			143.777,
			143.839,
			143.886,
			143.9,
			143.962,
			143.994,
			144.026,
			144.103,
			144.15,
			144.212,
			144.272,
			144.319,
			144.335,
			144.396,
			144.427,
			144.46,
			144.537,
			144.584,
			144.644,
			144.706,
			144.769,
			144.832,
			144.862,
			144.893,
			144.97,
			145.016,
			145.078,
			145.139,
			145.202,
			145.265,
			145.296,
			145.325,
			145.405,
			145.451,
			145.514,
			145.575,
			145.62,
			145.636,
			145.699,
			145.736,
			145.761,
			145.838,
			145.884,
			145.947,
			146.01,
			146.054,
			146.134,
			146.163,
			146.195,
			146.272,
			146.318,
			146.38,
			146.442,
			146.491,
			146.504,
			146.568,
			146.599,
			146.628,
			146.707,
			146.754,
			146.815,
			146.876,
			146.923,
			146.94,
			147.001,
			147.031,
			147.063,
			147.14,
			147.186,
			147.25,
			147.311,
			147.359,
			147.434,
			147.465,
			147.498,
			147.576,
			147.621,
			147.684,
			147.745,
			147.797,
			147.807,
			147.869,
			147.901,
			147.931,
			147.993,
			148.01,
			148.056,
			148.117,
			148.178,
			148.225,
			148.302,
			148.333,
			148.365,
			148.427,
			148.489,
			148.551,
			148.613,
			148.645
		]
	],
	'6009_6010_7982': [
		[
			76.911
		],
		[
			0
		],
		[
			0,
			1.104,
			3.581,
			4.823,
			7.315,
			8.564,
			11.071,
			13.588,
			14.849,
			17.378,
			19.915,
			23.738,
			26.295,
			28.86,
			31.433,
			32.723,
			35.307,
			36.602,
			39.198,
			41.802,
			45.713,
			48.324,
			52.255,
			53.569,
			54.885,
			58.844,
			61.492,
			65.478,
			68.144,
			70.817,
			72.157,
			74.841,
			76.186,
			78.881,
			81.583,
			82.936,
			85.649,
			88.37,
			92.463,
			95.201,
			99.321,
			100.698,
			102.077,
			106.223,
			108.997,
			111.776,
			115.958,
			118.754,
			122.96,
			124.365,
			125.772,
			130.003,
			132.831,
			137.082,
			139.914,
			142.734,
			144.137,
			146.927,
			149.711,
			151.08,
			153.79,
			155.11,
			157.766,
			160.388,
			164.273,
			165.543,
			166.802,
			170.505,
			171.729,
			172.945,
			176.541,
			178.898,
			182.399,
			184.681,
			186.935,
			190.26,
			191.358,
			192.45,
			195.675,
			197.793,
			200.905,
			202.943,
			204.946,
			205.937,
			207.897,
			209.824,
			210.776,
			212.67,
			213.61,
			215.478,
			217.348,
			220.142,
			222.003,
			224.79,
			225.717,
			226.649,
			229.439,
			231.3,
			234.095,
			235.958,
			238.759,
			240.625,
			241.56,
			243.428,
			246.229,
			248.1,
			249.969,
			251.843,
			253.72,
			254.659,
			256.539,
			258.426,
			259.37,
			262.212,
			264.116,
			266.023,
			268.888,
			270.803,
			273.684,
			274.647,
			275.612,
			278.526,
			280.481,
			283.44,
			285.425,
			287.42,
			288.42,
			290.425,
			291.43,
			293.445,
			295.462,
			296.472,
			298.496,
			300.518,
			303.539,
			305.541,
			307.532,
			309.514,
			310.503,
			312.478,
			313.462,
			315.429,
			317.392,
			320.327,
			322.279,
			325.199,
			326.171,
			327.14,
			330.039,
			331.004,
			331.966,
			334.839,
			336.75,
			338.653,
			339.603,
			341.5,
			342.448,
			344.338,
			346.224,
			347.167,
			349.052,
			350.931,
			353.736,
			355.597,
			358.358,
			359.271,
			360.182,
			362.893,
			364.679,
			367.349,
			369.124,
			370.897,
			371.784,
			373.571,
			374.465,
			375.36,
			378.052,
			378.951,
			379.852,
			382.565,
			384.382,
			387.129,
			388.979,
			390.841,
			391.777,
			393.662,
			394.61,
			396.515,
			398.432,
			401.331,
			403.277,
			406.199,
			407.185,
			408.171,
			411.146,
			413.141,
			416.152,
			418.173,
			420.205,
			421.225,
			423.276,
			424.306,
			425.339,
			428.446,
			429.483,
			430.517,
			433.599,
			435.625,
			437.622,
			438.608,
			440.553,
			442.46,
			443.401,
			445.254,
			446.168,
			447.985,
			449.76,
			452.386,
			454.12,
			456.705,
			457.563,
			458.419,
			460.976,
			462.671,
			465.197,
			466.871,
			468.54,
			469.371,
			471.028,
			471.854,
			473.505,
			475.152,
			475.973,
			477.612,
			479.257,
			480.897,
			482.532,
			483.355,
			484.996,
			486.633,
			487.45,
			489.946,
			491.609,
			493.286,
			495.827,
			497.52,
			500.083,
			500.938,
			501.794,
			504.356,
			506.062,
			508.624,
			510.333,
			512.895,
			514.607,
			516.316,
			517.17,
			518.872,
			519.72,
			521.414,
			523.106,
			525.619,
			526.455,
			527.291,
			529.786,
			530.615,
			531.443,
			533.924,
			535.57,
			538.037,
			539.672,
			541.302,
			543.745,
			544.556,
			545.366,
			547.791,
			549.408,
			551.822,
			553.43,
			555.038,
			555.841,
			557.444,
			559.052,
			559.855,
			562.261,
			563.868,
			565.473,
			567.874,
			569.479,
			571.886,
			572.688,
			573.496,
			575.935,
			577.562,
			580.033,
			581.683,
			584.179,
			585.859,
			586.699,
			588.382,
			590.941,
			592.644,
			594.354,
			596.064,
			598.625,
			600.328,
			602.028,
			602.878,
			605.438,
			607.143,
			608.85,
			611.417,
			613.126,
			615.687,
			616.539,
			617.391,
			619.928,
			621.607,
			624.117,
			625.769,
			628.245,
			629.881,
			630.697,
			632.328,
			633.96,
			634.772,
			636.397,
			638.026,
			640.472,
			641.289,
			642.107,
			644.569,
			645.379,
			646.204,
			648.699,
			650.379,
			652.072,
			654.637,
			656.366,
			658.984,
			659.866,
			660.751,
			663.425,
			665.226,
			667.952,
			669.786,
			671.632,
			672.561,
			674.427,
			676.306,
			677.251,
			679.149,
			680.103,
			682.02,
			683.949,
			686.866,
			687.844,
			688.826,
			691.787,
			692.781,
			693.777,
			696.783,
			698.801,
			701.857,
			703.909,
			705.973,
			707.009,
			709.086,
			710.123,
			711.159,
			714.277,
			715.299,
			716.317,
			719.338,
			721.316,
			723.266,
			724.23,
			726.141,
			728.978,
			730.855,
			731.79,
			733.656,
			735.518,
			738.299,
			740.147,
			742.907,
			743.824,
			744.739,
			747.483,
			749.308,
			752.059,
			753.896,
			756.675,
			758.536,
			759.469,
			761.349,
			763.237,
			764.185,
			765.145,
			768.056,
			770.021,
			773.034,
			775.062,
			777.123,
			778.158,
			780.236,
			781.277,
			783.368,
			785.461,
			788.593,
			790.686,
			793.826,
			794.879,
			795.929,
			799.096,
			801.211,
			804.375,
			806.492,
			809.675,
			811.817,
			812.882,
			815.019,
			818.224,
			820.37,
			822.517,
			825.735,
			826.802,
			827.849,
			829.965,
			832.065,
			833.121,
			835.175,
			836.191,
			838.199,
			840.18,
			843.13,
			845.072,
			847.966,
			848.925,
			849.868,
			852.739,
			854.675,
			857.576,
			859.523,
			861.476,
			862.454,
			864.401,
			865.386,
			867.357,
			869.337,
			870.329,
			872.322,
			874.314,
			877.284,
			878.269,
			879.25,
			882.159,
			883.115,
			884.065,
			886.869,
			888.697,
			890.492,
			891.378,
			893.13,
			894.858,
			897.437,
			898.285,
			899.131,
			901.66,
			902.501,
			903.34,
			905.851,
			907.518,
			909.184,
			910.017,
			911.684,
			913.35,
			914.183,
			915.849,
			916.673,
			918.32,
			919.971,
			922.454,
			924.113,
			926.627,
			927.485,
			928.341,
			930.896,
			932.883,
			935.792,
			938.124,
			940.409,
			941.478,
			943.471,
			944.479,
			946.309,
			947.749,
			948.459,
			949.161,
			951.191,
			952.381,
			953.542,
			954.111,
			955.227,
			956.328,
			956.886,
			957.983,
			958.514,
			959.556,
			960.576,
			962.211,
			963.308,
			964.945,
			965.499,
			966.045,
			968.061,
			969.406,
			971.432,
			972.762,
			974.096,
			974.766,
			976.09,
			976.712,
			977.957,
			979.206,
			979.834,
			981.097,
			982.32,
			984.165,
			985.406,
			987.201,
			987.785,
			988.372,
			989.551,
			990.143,
			991.327,
			992.474,
			994.21,
			995.382,
			997.172,
			997.776,
			998.382,
			1000.222,
			1001.475,
			1003.41,
			1004.719,
			1006.721,
			1008.088,
			1009.471,
			1010.169,
			1011.579,
			1012.293,
			1013.734,
			1015.191,
			1017.411,
			1018.914,
			1021.197,
			1021.953,
			1022.726,
			1025.069,
			1026.649,
			1029.044,
			1030.66,
			1032.29,
			1034.762,
			1035.593,
			1036.427,
			1038.946,
			1039.786,
			1040.625,
			1043.159,
			1044.865,
			1047.448,
			1049.185,
			1050.058,
			1051.815,
			1053.584,
			1054.473,
			1056.261,
			1058.061,
			1060.786,
			1062.619,
			1065.392,
			1066.323,
			1067.257,
			1070.078,
			1071.974,
			1074.84,
			1076.765,
			1079.673,
			1081.627,
			1082.609,
			1083.593,
			1086.563,
			1087.559,
			1088.558,
			1091.571,
			1093.594,
			1095.628,
			1096.649,
			1098.7,
			1100.762,
			1101.797,
			1103.875,
			1104.917,
			1107,
			1109.084,
			1112.228,
			1114.337,
			1117.519,
			1118.585,
			1119.653,
			1122.873,
			1125.032,
			1128.287,
			1130.468,
			1132.658,
			1133.756,
			1135.959,
			1137.064,
			1139.281,
			1141.505,
			1142.622,
			1144.86,
			1147.108,
			1150.502,
			1151.638,
			1152.777,
			1155.065,
			1157.363,
			1158.517,
			1161.993,
			1164.323,
			1167.836,
			1170.19,
			1172.552,
			1176.114,
			1177.306,
			1178.5,
			1182.094,
			1184.502,
			1188.132,
			1190.564,
			1194.229,
			1196.685,
			1197.916,
			1200.389,
			1202.871,
			1204.116,
			1206.614,
			1209.119,
			1212.892,
			1215.416,
			1219.215,
			1220.484,
			1221.755,
			1225.581,
			1228.141,
			1231.997,
			1234.58,
			1237.173,
			1241.08,
			1242.386,
			1243.692,
			1247.615,
			1248.926,
			1250.238,
			1254.183,
			1256.82,
			1259.465,
			1260.791,
			1263.448,
			1266.112,
			1267.446,
			1270.119,
			1271.458,
			1274.142,
			1276.833,
			1280.882,
			1283.59,
			1287.662,
			1289.022,
			1290.384,
			1294.48,
			1297.219,
			1301.34,
			1304.096,
			1308.241,
			1311.014,
			1312.403,
			1313.793,
			1317.975,
			1319.372,
			1320.771,
			1324.977,
			1327.788,
			1332.018,
			1334.845,
			1337.678,
			1339.097,
			1341.94,
			1343.365,
			1346.218,
			1349.079,
			1353.384,
			1356.262,
			1360.59,
			1362.036,
			1363.484,
			1367.837,
			1370.747,
			1375.123,
			1378.048,
			1380.98,
			1382.448,
			1385.389,
			1386.862,
			1389.812,
			1394.249,
			1397.214,
			1400.185,
			1404.652,
			1406.144,
			1407.636,
			1410.625,
			1413.619,
			1415.119,
			1418.121,
			1419.625,
			1422.637,
			1425.656,
			1430.194,
			1433.228,
			1437.79,
			1439.314,
			1440.84,
			1445.424,
			1448.487,
			1453.093,
			1456.17,
			1460.796,
			1463.889,
			1465.438,
			1468.541,
			1471.65,
			1473.206,
			1476.322,
			1479.442,
			1484.131,
			1487.262,
			1491.965,
			1493.536,
			1495.108,
			1499.83,
			1502.985,
			1506.144,
			1507.725,
			1510.891,
			1514.062,
			1518.828,
			1520.418,
			1522.01,
			1526.79,
			1528.383,
			1529.978,
			1534.768,
			1537.966,
			1541.169,
			1542.772,
			1545.982,
			1549.195,
			1550.803,
			1554.023,
			1555.635,
			1558.863,
			1562.095,
			1566.951,
			1570.192,
			1575.06,
			1576.685,
			1578.31,
			1583.193,
			1586.453,
			1591.351,
			1594.621,
			1597.895,
			1599.533,
			1602.813,
			1604.454,
			1606.097,
			1611.03,
			1612.676,
			1614.324,
			1619.272,
			1622.575,
			1625.882,
			1627.538,
			1630.851,
			1634.169,
			1635.829,
			1639.152,
			1640.816,
			1644.145,
			1649.145,
			1652.483,
			1655.824,
			1660.842,
			1662.517,
			1664.194,
			1669.229,
			1672.591,
			1677.641,
			1681.012,
			1684.386,
			1686.075,
			1689.456,
			1691.148,
			1694.534,
			1697.925,
			1699.622,
			1703.018,
			1706.418,
			1709.824,
			1713.232,
			1714.938,
			1718.353,
			1721.772,
			1723.483,
			1726.908,
			1728.649,
			1732.043,
			1735.468,
			1740.606,
			1744.035,
			1749.186,
			1750.906,
			1752.628,
			1757.797,
			1761.249,
			1766.435,
			1769.897,
			1773.364,
			1775.099,
			1778.573,
			1780.311,
			1783.782,
			1787.242,
			1788.963,
			1792.384,
			1795.772,
			1800.782,
			1802.432,
			1804.073,
			1808.937,
			1810.539,
			1812.133,
			1815.292,
			1816.856,
			1819.957,
			1823.024,
			1827.558,
			1830.536,
			1834.936,
			1836.384,
			1837.821,
			1842.076,
			1844.863,
			1848.971,
			1851.664,
			1855.632,
			1858.231,
			1860.792,
			1862.059,
			1865.801,
			1868.25,
			1870.663,
			1874.219,
			1876.548,
			1879.975,
			1881.1,
			1882.213,
			1885.496,
			1887.633,
			1890.764,
			1892.801,
			1894.798,
			1895.783,
			1897.724,
			1898.682,
			1900.572,
			1902.429,
			1903.36,
			1904.268,
			1906.929,
			1908.673,
			1910.38,
			1911.22,
			1912.878,
			1914.491,
			1915.287,
			1916.862,
			1917.64,
			1919.18,
			1920.708,
			1922.995,
			1924.517,
			1926.805,
			1927.576,
			1928.346,
			1930.66,
			1932.214,
			1934.563,
			1936.13,
			1937.715,
			1938.511,
			1940.102,
			1940.897,
			1942.501,
			1944.113,
			1944.918,
			1946.528,
			1948.158,
			1950.599,
			1951.412,
			1952.235,
			1953.888,
			1955.536,
			1956.36,
			1958.025,
			1958.858,
			1960.52,
			1962.185,
			1964.695,
			1966.364,
			1968.882,
			1969.719,
			1970.555,
			1973.063,
			1974.731,
			1977.233,
			1978.898,
			1980.56,
			1981.39,
			1983.046,
			1983.871,
			1985.519,
			1987.986,
			1989.622,
			1991.257,
			1993.703,
			1994.516,
			1995.327,
			1997.755,
			1998.56,
			1999.364,
			2001.771,
			2003.359,
			2005.73,
			2007.308,
			2008.871,
			2011.213,
			2011.995,
			2012.775,
			2015.89,
			2016.669,
			2019.011,
			2020.572,
			2022.133,
			2022.915,
			2024.477,
			2025.258,
			2026.821,
			2029.169,
			2030.735,
			2032.305,
			2034.666,
			2036.244,
			2038.623,
			2039.418,
			2040.215,
			2042.615,
			2044.223,
			2046.648,
			2048.278,
			2049.918,
			2050.742,
			2052.4,
			2053.235,
			2054.073,
			2056.607,
			2057.458,
			2058.313,
			2060.896,
			2062.634,
			2064.384,
			2065.264,
			2067.033,
			2068.817,
			2069.714,
			2071.516,
			2072.421,
			2074.247,
			2076.084,
			2078.867,
			2080.739,
			2083.569,
			2084.519,
			2085.471,
			2088.345,
			2090.273,
			2093.188,
			2095.146,
			2097.116,
			2098.105,
			2100.092,
			2101.09,
			2103.094,
			2105.094,
			2106.106,
			2108.139,
			2110.186,
			2112.243,
			2114.312,
			2115.351,
			2117.437,
			2119.533,
			2120.584,
			2122.684,
			2123.734,
			2125.837,
			2129.01,
			2131.137,
			2133.272,
			2136.492,
			2137.569,
			2138.649,
			2141.903,
			2144.084,
			2147.373,
			2149.576,
			2152.9,
			2155.127,
			2156.244,
			2158.486,
			2160.736,
			2161.864,
			2164.128,
			2166.401,
			2169.829,
			2172.125,
			2175.587,
			2176.746,
			2177.907,
			2180.236,
			2181.404,
			2183.747,
			2186.098,
			2189.641,
			2192.016,
			2195.593,
			2197.989,
			2201.599,
			2204.017,
			2207.661,
			2210.101,
			2213.778,
			2216.24,
			2218.71,
			2219.948,
			2222.43,
			2223.675,
			2226.169,
			2228.672,
			2232.441,
			2233.702,
			2234.965,
			2238.765,
			2240.036,
			2241.309,
			2245.142,
			2247.709,
			2251.575,
			2254.163,
			2256.759,
			2260.664,
			2261.966,
			2263.27,
			2267.191,
			2268.502,
			2269.814,
			2273.763,
			2276.405,
			2279.054,
			2280.382,
			2283.041,
			2285.706,
			2287.039,
			2289.708,
			2291.043,
			2293.707,
			2296.358,
			2300.294,
			2302.882,
			2306.697,
			2307.95,
			2309.193,
			2312.868,
			2315.273,
			2318.82,
			2321.144,
			2323.453,
			2324.584,
			2326.821,
			2327.926,
			2330.11,
			2332.24,
			2333.3,
			2335.399,
			2337.472,
			2339.528,
			2342.591,
			2344.642,
			2346.674,
			2347.689,
			2349.722,
			2350.74,
			2352.781,
			2354.827,
			2357.907,
			2359.968,
			2363.08,
			2364.123,
			2365.169,
			2368.332,
			2370.457,
			2373.676,
			2375.838,
			2378.013,
			2379.104,
			2381.294,
			2382.396,
			2384.607,
			2386.83,
			2387.95,
			2390.198,
			2392.463,
			2395.888,
			2397.039,
			2398.196,
			2401.681,
			2402.859,
			2404.039,
			2407.597,
			2409.989,
			2412.389,
			2416.001,
			2418.406,
			2422.009,
			2423.203,
			2424.395,
			2427.963,
			2430.326,
			2433.843,
			2436.161,
			2439.589,
			2441.835,
			2442.949,
			2445.16,
			2447.349,
			2448.418,
			2450.588,
			2452.751,
			2456.004,
			2458.161,
			2461.4,
			2462.483,
			2463.566,
			2466.829,
			2468.997,
			2472.292,
			2474.498,
			2476.714,
			2477.824,
			2480.052,
			2481.169,
			2482.287,
			2485.637,
			2486.749,
			2487.855,
			2491.139,
			2493.288,
			2495.4,
			2496.443,
			2498.5,
			2500.52,
			2501.516,
			2504.451,
			2506.362,
			2508.237,
			2510.983,
			2512.772,
			2515.389,
			2516.244,
			2517.089,
			2519.568,
			2521.175,
			2523.516,
			2525.03,
			2526.509,
			2527.234,
			2528.657,
			2529.354,
			2530.724,
			2532.061,
			2532.717,
			2534.004,
			2535.258,
			2537.073,
			2537.66,
			2538.241,
			2539.383,
			2540.504,
			2541.058,
			2542.712,
			2543.798,
			2544.875,
			2546.479,
			2547.544,
			2549.142,
			2549.67,
			2550.198,
			2551.778,
			2552.832,
			2554.392,
			2555.427,
			2556.459,
			2556.975,
			2558.037,
			2558.601,
			2559.72,
			2560.834,
			2561.39,
			2562.504,
			2563.786,
			2565.755,
			2566.41,
			2567.064,
			2569.081,
			2569.759,
			2570.435,
			2572.459,
			2573.7,
			2575.499,
			2576.692,
			2577.88,
			2579.545,
			2580.099,
			2580.654,
			2582.324,
			2583.41,
			2584.999,
			2586.06,
			2587.126,
			2587.663,
			2588.751,
			2589.845,
			2590.395,
			2591.5,
			2592.056,
			2593.184,
			2594.343,
			2596.1,
			2597.285,
			2599.106,
			2599.722,
			2600.341,
			2602.216,
			2603.481,
			2605.398,
			2606.694,
			2608.005,
			2609.971,
			2610.633,
			2611.3,
			2613.998,
			2614.678,
			2616.74,
			2618.135,
			2619.551,
			2620.263,
			2621.699,
			2623.153,
			2623.895,
			2625.388,
			2626.14,
			2627.654,
			2629.196,
			2631.538,
			2633.117,
			2635.526,
			2636.334,
			2637.146,
			2639.609,
			2641.264,
			2643.751,
			2645.431,
			2647.969,
			2649.689,
			2650.555,
			2652.296,
			2654.055,
			2654.946,
			2656.735,
			2658.537,
			2660.362,
			2663.12,
			2664.975,
			2666.84,
			2667.776,
			2669.653,
			2670.593,
			2672.479,
			2674.371,
			2677.219,
			2679.128,
			2682.014,
			2682.98,
			2683.949,
			2686.893,
			2688.867,
			2691.864,
			2693.875,
			2695.891,
			2696.904,
			2698.938,
			2699.949,
			2701.981,
			2704.024,
			2705.04,
			2707.078,
			2709.128,
			2712.207,
			2713.238,
			2714.272,
			2717.385,
			2718.427,
			2719.473,
			2721.563,
			2722.608,
			2724.703,
			2726.812,
			2729.992,
			2732.126,
			2735.344,
			2736.421,
			2737.5,
			2740.753,
			2741.842,
			2742.933,
			2746.221,
			2748.424,
			2750.619,
			2751.728,
			2753.954,
			2755.071,
			2757.311,
			2759.56,
			2760.687,
			2762.949,
			2765.22,
			2768.638,
			2770.912,
			2774.289,
			2775.4,
			2776.503,
			2779.756,
			2781.879,
			2784.994,
			2787.025,
			2789.02,
			2791.943,
			2792.899,
			2793.846,
			2797.544,
			2798.448,
			2801.106,
			2802.835,
			2804.528,
			2805.361,
			2806.998,
			2808.599,
			2809.385,
			2810.931,
			2811.69,
			2813.181,
			2814.635,
			2816.749,
			2818.114,
			2820.1,
			2820.745,
			2821.382,
			2823.238,
			2824.431,
			2826.17,
			2827.304,
			2828.423,
			2828.979,
			2830.098,
			2830.651,
			2831.754,
			2832.853,
			2833.4,
			2834.491,
			2835.574,
			2836.642,
			2837.701,
			2838.228,
			2839.277,
			2840.323,
			2840.842,
			2841.873,
			2842.386,
			2843.409,
			2844.939,
			2845.977,
			2847.017,
			2848.571,
			2849.089,
			2849.608,
			2851.255,
			2852.394,
			2854.102,
			2855.243,
			2856.546,
			2857.2,
			2858.504,
			2859.156,
			2860.475,
			2861.916,
			2862.633,
			2864.066,
			2865.513,
			2867.862,
			2869.418,
			2871.856,
			2872.689,
			2873.519,
			2875.177,
			2875.998,
			2877.622,
			2879.237,
			2881.51,
			2882.927,
			2885.044,
			2885.688,
			2886.26,
			2887.969,
			2889.104,
			2890.77,
			2891.79,
			2892.808,
			2893.317,
			2894.335,
			2894.845,
			2895.877,
			2896.922,
			2897.446,
			2898.495,
			2899.55,
			2901.166,
			2902.271,
			2903.939,
			2904.499,
			2905.059,
			2906.765,
			2907.906,
			2909.652,
			2910.834,
			2912.033,
			2912.639,
			2913.863,
			2914.481,
			2915.104,
			2917,
			2917.641,
			2918.286,
			2920.245,
			2921.573,
			2923.596,
			2924.965,
			2926.351,
			2927.049,
			2929.17,
			2930.604,
			2932.055,
			2934.261,
			2935.752,
			2938.021,
			2938.785,
			2939.553,
			2941.882,
			2943.453,
			2945.84,
			2947.452,
			2949.079,
			2949.898,
			2951.55,
			2952.383,
			2954.059,
			2956.597,
			2957.446,
			2959.997,
			2961.715,
			2963.445,
			2964.315,
			2966.066,
			2967.826,
			2968.711,
			2970.491,
			2971.385,
			2973.179,
			2974.987,
			2977.719,
			2979.555,
			2982.334,
			2983.267,
			2984.204,
			2987.031,
			2987.98,
			2988.932,
			2991.806,
			2993.737,
			2995.68,
			2996.656,
			2998.617,
			2999.601,
			3001.58,
			3003.572,
			3004.572,
			3006.581,
			3008.603,
			3011.658,
			3012.682,
			3013.709,
			3015.771,
			3017.846,
			3018.888,
			3020.98,
			3022.03,
			3024.131,
			3026.233,
			3029.404,
			3031.532,
			3034.742,
			3035.818,
			3036.896,
			3040.147,
			3042.327,
			3045.613,
			3047.816,
			3050.028,
			3051.137,
			3053.364,
			3055.6,
			3056.721,
			3060.1,
			3062.364,
			3064.638,
			3068.067,
			3070.365,
			3073.831,
			3074.991,
			3076.154,
			3079.654,
			3082,
			3085.523,
			3087.883,
			3090.257,
			3093.836,
			3095.039,
			3096.249,
			3101.11,
			3102.336,
			3106.028,
			3108.48,
			3111.63
		],
		[
			0,
			0.015,
			0.06,
			0.078,
			0.138,
			0.171,
			0.202,
			0.265,
			0.279,
			0.326,
			0.389,
			0.451,
			0.511,
			0.575,
			0.604,
			0.636,
			0.699,
			0.714,
			0.76,
			0.821,
			0.883,
			0.931,
			1.005,
			1.039,
			1.07,
			1.13,
			1.193,
			1.255,
			1.318,
			1.365,
			1.38,
			1.443,
			1.473,
			1.504,
			1.565,
			1.582,
			1.628,
			1.688,
			1.752,
			1.814,
			1.876,
			1.91,
			1.938,
			2,
			2.061,
			2.122,
			2.185,
			2.247,
			2.309,
			2.342,
			2.371,
			2.434,
			2.496,
			2.559,
			2.618,
			2.665,
			2.681,
			2.743,
			2.776,
			2.805,
			2.866,
			2.883,
			2.93,
			2.993,
			3.055,
			3.099,
			3.114,
			3.178,
			3.21,
			3.241,
			3.3,
			3.365,
			3.426,
			3.488,
			3.536,
			3.612,
			3.643,
			3.673,
			3.737,
			3.797,
			3.861,
			3.92,
			3.975,
			3.985,
			4.046,
			4.078,
			4.109,
			4.171,
			4.185,
			4.233,
			4.295,
			4.356,
			4.402,
			4.481,
			4.512,
			4.543,
			4.605,
			4.665,
			4.729,
			4.789,
			4.853,
			4.915,
			4.945,
			4.977,
			5.052,
			5.1,
			5.163,
			5.225,
			5.271,
			5.287,
			5.349,
			5.379,
			5.411,
			5.488,
			5.535,
			5.597,
			5.659,
			5.706,
			5.781,
			5.813,
			5.845,
			5.92,
			5.969,
			6.034,
			6.093,
			6.137,
			6.155,
			6.215,
			6.247,
			6.277,
			6.339,
			6.355,
			6.402,
			6.464,
			6.527,
			6.589,
			6.65,
			6.681,
			6.713,
			6.774,
			6.791,
			6.836,
			6.897,
			6.961,
			7.02,
			7.085,
			7.117,
			7.145,
			7.209,
			7.225,
			7.272,
			7.334,
			7.395,
			7.44,
			7.458,
			7.519,
			7.548,
			7.581,
			7.643,
			7.659,
			7.705,
			7.766,
			7.828,
			7.89,
			7.952,
			7.984,
			8.015,
			8.076,
			8.138,
			8.202,
			8.262,
			8.309,
			8.326,
			8.385,
			8.417,
			8.448,
			8.511,
			8.529,
			8.571,
			8.635,
			8.698,
			8.76,
			8.821,
			8.853,
			8.884,
			8.944,
			8.961,
			9.007,
			9.069,
			9.13,
			9.178,
			9.255,
			9.287,
			9.316,
			9.378,
			9.44,
			9.505,
			9.564,
			9.61,
			9.627,
			9.687,
			9.721,
			9.75,
			9.814,
			9.829,
			9.873,
			9.938,
			10,
			10.046,
			10.06,
			10.124,
			10.16,
			10.185,
			10.248,
			10.262,
			10.309,
			10.37,
			10.434,
			10.496,
			10.556,
			10.587,
			10.618,
			10.681,
			10.743,
			10.806,
			10.868,
			10.914,
			10.928,
			10.992,
			11.023,
			11.052,
			11.116,
			11.13,
			11.178,
			11.238,
			11.3,
			11.349,
			11.362,
			11.425,
			11.456,
			11.487,
			11.563,
			11.612,
			11.675,
			11.735,
			11.783,
			11.86,
			11.891,
			11.921,
			11.983,
			12.046,
			12.109,
			12.17,
			12.231,
			12.293,
			12.326,
			12.356,
			12.417,
			12.434,
			12.481,
			12.542,
			12.603,
			12.65,
			12.665,
			12.728,
			12.759,
			12.789,
			12.853,
			12.914,
			12.976,
			13.037,
			13.099,
			13.162,
			13.194,
			13.222,
			13.285,
			13.347,
			13.409,
			13.471,
			13.517,
			13.534,
			13.596,
			13.628,
			13.659,
			13.735,
			13.782,
			13.842,
			13.905,
			13.952,
			14.029,
			14.061,
			14.092,
			14.169,
			14.216,
			14.282,
			14.339,
			14.403,
			14.463,
			14.493,
			14.526,
			14.602,
			14.651,
			14.712,
			14.772,
			14.837,
			14.896,
			14.93,
			14.959,
			15.037,
			15.084,
			15.145,
			15.208,
			15.271,
			15.331,
			15.362,
			15.394,
			15.455,
			15.518,
			15.579,
			15.643,
			15.704,
			15.765,
			15.797,
			15.828,
			15.889,
			15.906,
			15.952,
			16.014,
			16.076,
			16.121,
			16.138,
			16.201,
			16.231,
			16.263,
			16.343,
			16.385,
			16.448,
			16.511,
			16.556,
			16.633,
			16.664,
			16.697,
			16.758,
			16.819,
			16.881,
			16.943,
			16.991,
			17.004,
			17.069,
			17.1,
			17.13,
			17.192,
			17.209,
			17.253,
			17.315,
			17.378,
			17.425,
			17.442,
			17.502,
			17.534,
			17.564,
			17.627,
			17.69,
			17.752,
			17.811,
			17.859,
			17.875,
			17.935,
			17.969,
			17.999,
			18.06,
			18.077,
			18.123,
			18.186,
			18.247,
			18.293,
			18.31,
			18.37,
			18.433,
			18.494,
			18.511,
			18.556,
			18.619,
			18.681,
			18.742,
			18.806,
			18.835,
			18.866,
			18.928,
			18.991,
			19.055,
			19.115,
			19.176,
			19.239,
			19.27,
			19.301,
			19.364,
			19.378,
			19.424,
			19.487,
			19.547,
			19.61,
			19.674,
			19.705,
			19.734,
			19.796,
			19.811,
			19.859,
			19.921,
			19.984,
			20.029,
			20.108,
			20.138,
			20.168,
			20.232,
			20.293,
			20.354,
			20.418,
			20.478,
			20.539,
			20.57,
			20.602,
			20.681,
			20.727,
			20.789,
			20.852,
			20.897,
			20.913,
			20.975,
			21.007,
			21.036,
			21.098,
			21.115,
			21.162,
			21.222,
			21.284,
			21.347,
			21.41,
			21.439,
			21.471,
			21.534,
			21.595,
			21.658,
			21.72,
			21.767,
			21.781,
			21.844,
			21.872,
			21.906,
			21.966,
			21.983,
			22.03,
			22.092,
			22.154,
			22.198,
			22.216,
			22.278,
			22.309,
			22.338,
			22.415,
			22.464,
			22.525,
			22.531,
			22.587,
			22.649,
			22.71,
			22.743,
			22.772,
			22.835,
			22.851,
			22.897,
			22.96,
			23.021,
			23.067,
			23.083,
			23.146,
			23.177,
			23.206,
			23.269,
			23.284,
			23.332,
			23.394,
			23.456,
			23.503,
			23.581,
			23.61,
			23.643,
			23.718,
			23.767,
			23.827,
			23.891,
			23.936,
			23.951,
			24.014,
			24.043,
			24.076,
			24.139,
			24.152,
			24.2,
			24.26,
			24.325,
			24.37,
			24.384,
			24.446,
			24.48,
			24.51,
			24.571,
			24.594,
			24.634,
			24.697,
			24.756,
			24.82,
			24.882,
			24.913,
			24.942,
			25.004,
			25.068,
			25.131,
			25.192,
			25.237,
			25.252,
			25.316,
			25.347,
			25.377,
			25.441,
			25.455,
			25.503,
			25.565,
			25.626,
			25.687,
			25.75,
			25.78,
			25.812,
			25.872,
			25.891,
			25.935,
			25.997,
			26.06,
			26.106,
			26.184,
			26.215,
			26.247,
			26.306,
			26.371,
			26.433,
			26.493,
			26.556,
			26.619,
			26.653,
			26.681,
			26.743,
			26.757,
			26.803,
			26.866,
			26.927,
			26.975,
			27.053,
			27.084,
			27.115,
			27.193,
			27.238,
			27.3,
			27.363,
			27.422,
			27.486,
			27.518,
			27.547,
			27.61,
			27.626,
			27.673,
			27.734,
			27.796,
			27.859,
			27.921,
			27.95,
			27.983,
			28.045,
			28.059,
			28.106,
			28.168,
			28.23,
			28.277,
			28.355,
			28.385,
			28.415,
			28.493,
			28.54,
			28.603,
			28.664,
			28.727,
			28.789,
			28.818,
			28.85,
			28.911,
			28.927,
			28.975,
			29.036,
			29.099,
			29.146,
			29.161,
			29.221,
			29.254,
			29.286,
			29.346,
			29.362,
			29.409,
			29.471,
			29.532,
			29.579,
			29.655,
			29.688,
			29.718,
			29.78,
			29.843,
			29.905,
			29.966,
			30.013,
			30.027,
			30.089,
			30.121,
			30.152,
			30.215,
			30.229,
			30.278,
			30.338,
			30.4,
			30.447,
			30.463,
			30.525,
			30.555,
			30.586,
			30.665,
			30.71,
			30.777,
			30.835,
			30.881,
			30.959,
			30.99,
			31.019,
			31.083,
			31.143,
			31.206,
			31.268,
			31.331,
			31.393,
			31.425,
			31.455,
			31.516,
			31.533,
			31.58,
			31.643,
			31.703,
			31.751,
			31.827,
			31.859,
			31.888,
			31.951,
			32.014,
			32.076,
			32.138,
			32.183,
			32.26,
			32.293,
			32.324,
			32.386,
			32.401,
			32.448,
			32.511,
			32.571,
			32.618,
			32.633,
			32.696,
			32.727,
			32.758,
			32.819,
			32.839,
			32.881,
			32.948,
			33.005,
			33.052,
			33.13,
			33.16,
			33.192,
			33.269,
			33.316,
			33.376,
			33.44,
			33.502,
			33.564,
			33.595,
			33.625,
			33.688,
			33.702,
			33.748,
			33.812,
			33.872,
			33.934,
			33.996,
			34.028,
			34.059,
			34.122,
			34.135,
			34.182,
			34.245,
			34.308,
			34.352,
			34.431,
			34.461,
			34.494,
			34.556,
			34.618,
			34.679,
			34.742,
			34.79,
			34.802,
			34.866,
			34.9,
			34.927,
			35.004,
			35.051,
			35.115,
			35.177,
			35.223,
			35.237,
			35.3,
			35.331,
			35.362,
			35.424,
			35.437,
			35.487,
			35.547,
			35.609,
			35.671,
			35.733,
			35.765,
			35.796,
			35.858,
			35.92,
			35.981,
			36.044,
			36.106,
			36.166,
			36.199,
			36.23,
			36.292,
			36.305,
			36.352,
			36.415,
			36.477,
			36.539,
			36.602,
			36.634,
			36.664,
			36.726,
			36.786,
			36.848,
			36.855,
			36.912,
			36.961,
			37.037,
			37.066,
			37.098,
			37.159,
			37.176,
			37.221,
			37.284,
			37.345,
			37.393,
			37.408,
			37.469,
			37.502,
			37.531,
			37.594,
			37.61,
			37.655,
			37.717,
			37.781,
			37.826,
			37.903,
			37.934,
			37.965,
			38.044,
			38.089,
			38.152,
			38.215,
			38.26,
			38.277,
			38.336,
			38.369,
			38.4,
			38.463,
			38.476,
			38.524,
			38.586,
			38.649,
			38.693,
			38.71,
			38.773,
			38.803,
			38.833,
			38.896,
			38.91,
			38.958,
			39.026,
			39.082,
			39.128,
			39.205,
			39.238,
			39.269,
			39.33,
			39.392,
			39.455,
			39.515,
			39.564,
			39.578,
			39.639,
			39.669,
			39.703,
			39.764,
			39.779,
			39.826,
			39.887,
			39.949,
			39.996,
			40.012,
			40.072,
			40.105,
			40.135,
			40.197,
			40.214,
			40.259,
			40.323,
			40.385,
			40.429,
			40.509,
			40.539,
			40.57,
			40.634,
			40.694,
			40.756,
			40.819,
			40.864,
			40.879,
			40.943,
			40.973,
			41.003,
			41.066,
			41.087,
			41.13,
			41.192,
			41.253,
			41.298,
			41.315,
			41.376,
			41.407,
			41.438,
			41.499,
			41.515,
			41.564,
			41.624,
			41.686,
			41.749,
			41.81,
			41.841,
			41.872,
			41.936,
			41.997,
			42.06,
			42.121,
			42.182,
			42.244,
			42.275,
			42.306,
			42.384,
			42.432,
			42.493,
			42.555,
			42.602,
			42.677,
			42.71,
			42.741,
			42.803,
			42.863,
			42.928,
			42.988,
			43.035,
			43.052,
			43.112,
			43.15,
			43.176,
			43.236,
			43.252,
			43.299,
			43.361,
			43.424,
			43.469,
			43.485,
			43.547,
			43.577,
			43.61,
			43.669,
			43.686,
			43.734,
			43.794,
			43.856,
			43.918,
			43.982,
			44.013,
			44.044,
			44.12,
			44.167,
			44.229,
			44.29,
			44.338,
			44.352,
			44.416,
			44.446,
			44.478,
			44.538,
			44.555,
			44.601,
			44.664,
			44.726,
			44.771,
			44.788,
			44.85,
			44.88,
			44.91,
			44.972,
			44.987,
			45.036,
			45.099,
			45.159,
			45.221,
			45.283,
			45.315,
			45.346,
			45.408,
			45.47,
			45.532,
			45.594,
			45.641,
			45.655,
			45.718,
			45.749,
			45.779,
			45.855,
			45.903,
			45.964,
			46.026,
			46.072,
			46.091,
			46.152,
			46.182,
			46.213,
			46.277,
			46.338,
			46.403,
			46.461,
			46.508,
			46.585,
			46.617,
			46.648,
			46.726,
			46.772,
			46.834,
			46.896,
			46.942,
			46.956,
			47.02,
			47.049,
			47.081,
			47.159,
			47.206,
			47.274,
			47.329,
			47.376,
			47.453,
			47.483,
			47.516,
			47.579,
			47.639,
			47.701,
			47.764,
			47.809,
			47.826,
			47.889,
			47.92,
			47.949,
			48.011,
			48.027,
			48.072,
			48.139,
			48.199,
			48.243,
			48.261,
			48.32,
			48.352,
			48.384,
			48.446,
			48.46,
			48.508,
			48.57,
			48.631,
			48.677,
			48.755,
			48.785,
			48.818,
			48.895,
			48.943,
			49.003,
			49.067,
			49.113,
			49.127,
			49.189,
			49.221,
			49.252,
			49.313,
			49.333,
			49.376,
			49.439,
			49.499,
			49.547,
			49.562,
			49.622,
			49.654,
			49.686,
			49.749,
			49.764,
			49.81,
			49.876,
			49.935,
			49.996,
			50.059,
			50.09,
			50.121,
			50.183,
			50.245,
			50.306,
			50.369,
			50.429,
			50.492,
			50.521,
			50.555,
			50.615,
			50.633,
			50.679,
			50.741,
			50.803,
			50.864,
			50.927,
			50.959,
			50.988,
			51.05,
			51.064,
			51.112,
			51.173,
			51.236,
			51.298,
			51.36,
			51.42,
			51.484,
			51.546,
			51.61,
			51.672,
			51.731,
			51.794,
			51.826,
			51.855,
			51.917,
			51.935,
			51.979,
			52.044,
			52.104,
			52.15,
			52.167,
			52.229,
			52.26,
			52.289,
			52.367,
			52.415,
			52.476,
			52.538,
			52.6,
			52.664,
			52.694,
			52.724,
			52.785,
			52.803,
			52.849,
			52.912,
			52.971,
			53.019,
			53.033,
			53.096,
			53.128,
			53.159,
			53.221,
			53.235,
			53.284,
			53.344,
			53.407,
			53.47,
			53.531,
			53.56,
			53.594,
			53.656,
			53.717,
			53.778,
			53.842,
			53.887,
			53.904,
			53.963,
			53.996,
			54.027,
			54.087,
			54.104,
			54.15,
			54.211,
			54.275,
			54.338,
			54.399,
			54.431,
			54.459,
			54.524,
			54.538,
			54.584,
			54.646,
			54.71,
			54.77,
			54.832,
			54.865,
			54.894,
			54.971,
			55.02,
			55.082,
			55.143,
			55.189,
			55.206,
			55.266,
			55.298,
			55.33,
			55.392,
			55.406,
			55.453,
			55.518,
			55.578,
			55.622,
			55.639,
			55.701,
			55.731,
			55.762,
			55.826,
			55.887,
			55.95,
			56.011,
			56.055,
			56.136,
			56.166,
			56.197,
			56.26,
			56.32,
			56.385,
			56.444,
			56.505,
			56.569,
			56.6,
			56.632,
			56.692,
			56.71,
			56.754,
			56.817,
			56.878,
			56.942,
			57.004,
			57.035,
			57.064,
			57.127,
			57.188,
			57.251,
			57.313,
			57.36,
			57.376,
			57.436,
			57.467,
			57.5,
			57.56,
			57.582,
			57.623,
			57.686,
			57.748,
			57.793,
			57.81,
			57.872,
			57.903,
			57.933,
			58.012,
			58.056,
			58.121,
			58.181,
			58.244,
			58.304,
			58.336,
			58.368,
			58.429,
			58.493,
			58.552,
			58.617,
			58.661,
			58.678,
			58.739,
			58.769,
			58.803,
			58.863,
			58.881,
			58.926,
			58.989,
			59.051,
			59.097,
			59.113,
			59.175,
			59.206,
			59.235,
			59.314,
			59.36,
			59.422,
			59.485,
			59.544,
			59.609,
			59.643,
			59.669,
			59.732,
			59.794,
			59.855,
			59.916,
			59.965,
			59.979,
			60.042,
			60.072,
			60.104,
			60.167,
			60.181,
			60.227,
			60.292,
			60.352,
			60.397,
			60.415,
			60.477,
			60.506,
			60.537,
			60.617,
			60.661,
			60.725,
			60.786,
			60.834,
			60.91,
			60.941,
			60.973,
			61.033,
			61.096,
			61.159,
			61.22,
			61.266,
			61.281,
			61.345,
			61.376,
			61.405,
			61.469,
			61.482,
			61.53,
			61.593,
			61.653,
			61.706,
			61.778,
			61.809,
			61.841,
			61.917,
			61.964,
			62.028,
			62.088,
			62.135,
			62.212,
			62.244,
			62.272,
			62.352,
			62.399,
			62.461,
			62.52,
			62.568,
			62.583,
			62.645,
			62.678,
			62.708,
			62.77,
			62.784,
			62.833,
			62.893,
			62.956,
			63.003,
			63.081,
			63.111,
			63.143,
			63.203,
			63.267,
			63.328,
			63.391,
			63.454,
			63.515,
			63.544,
			63.577,
			63.638,
			63.655,
			63.702,
			63.767,
			63.823,
			63.886,
			63.95,
			63.981,
			64.011,
			64.073,
			64.086,
			64.136,
			64.195,
			64.259,
			64.306,
			64.381,
			64.415,
			64.444,
			64.523,
			64.569,
			64.63,
			64.694,
			64.74,
			64.755,
			64.817,
			64.848,
			64.877,
			64.941,
			64.955,
			65.002,
			65.064,
			65.126,
			65.175,
			65.188,
			65.252,
			65.282,
			65.312,
			65.375,
			65.391,
			65.438,
			65.5,
			65.56,
			65.606,
			65.684,
			65.715,
			65.747,
			65.809,
			65.828,
			65.869,
			65.934,
			65.993,
			66.042,
			66.055,
			66.118,
			66.149,
			66.181,
			66.243,
			66.259,
			66.304,
			66.369,
			66.43,
			66.476,
			66.552,
			66.585,
			66.616,
			66.676,
			66.739,
			66.802,
			66.863,
			66.909,
			66.986,
			67.017,
			67.049,
			67.127,
			67.173,
			67.238,
			67.298,
			67.343,
			67.36,
			67.421,
			67.452,
			67.483,
			67.546,
			67.561,
			67.607,
			67.669,
			67.731,
			67.779,
			67.856,
			67.892,
			67.916,
			67.979,
			68.042,
			68.103,
			68.165,
			68.212,
			68.227,
			68.287,
			68.321,
			68.352,
			68.415,
			68.429,
			68.476,
			68.537,
			68.598,
			68.645,
			68.66,
			68.721,
			68.753,
			68.784,
			68.847,
			68.863,
			68.91,
			68.976,
			69.032,
			69.081,
			69.159,
			69.189,
			69.218,
			69.281,
			69.344,
			69.405,
			69.468,
			69.515,
			69.529,
			69.59,
			69.624,
			69.652,
			69.717,
			69.731,
			69.777,
			69.838,
			69.902,
			69.953,
			70.027,
			70.056,
			70.087,
			70.148,
			70.165,
			70.213,
			70.272,
			70.334,
			70.383,
			70.46,
			70.489,
			70.52,
			70.599,
			70.645,
			70.706,
			70.769,
			70.815,
			70.831,
			70.894,
			70.923,
			70.955,
			71.018,
			71.033,
			71.078,
			71.143,
			71.203,
			71.264,
			71.326,
			71.36,
			71.391,
			71.467,
			71.514,
			71.576,
			71.637,
			71.684,
			71.7,
			71.761,
			71.794,
			71.823,
			71.887,
			71.901,
			71.949,
			72.014,
			72.073,
			72.135,
			72.196,
			72.228,
			72.258,
			72.335,
			72.381,
			72.444,
			72.505,
			72.552,
			72.63,
			72.661,
			72.692,
			72.769,
			72.816,
			72.878,
			72.939,
			72.987,
			73.003,
			73.065,
			73.096,
			73.126,
			73.204,
			73.25,
			73.313,
			73.374,
			73.419,
			73.436,
			73.497,
			73.53,
			73.561,
			73.622,
			73.637,
			73.684,
			73.747,
			73.809,
			73.87,
			73.933,
			73.964,
			73.993,
			74.055,
			74.077,
			74.118,
			74.181,
			74.243,
			74.289,
			74.305,
			74.366,
			74.396,
			74.428,
			74.491,
			74.505,
			74.552,
			74.615,
			74.678,
			74.723,
			74.74,
			74.799,
			74.833,
			74.863,
			74.922,
			74.939,
			74.987,
			75.049,
			75.111,
			75.155,
			75.236,
			75.264,
			75.297,
			75.359,
			75.422,
			75.483,
			75.545,
			75.592,
			75.605,
			75.668,
			75.7,
			75.73,
			75.805,
			75.855,
			75.918,
			75.978,
			76.04,
			76.101,
			76.136,
			76.165,
			76.228,
			76.287,
			76.352,
			76.413,
			76.459,
			76.536,
			76.568,
			76.597,
			76.676,
			76.722,
			76.785,
			76.847,
			76.911
		]
	],
	'6009_6167_7982': [
		[
			47.446
		],
		[
			0
		],
		[
			0,
			2.588,
			5.199,
			6.508,
			9.131,
			11.761,
			15.719,
			17.043,
			18.368,
			22.353,
			23.685,
			25.019,
			27.692,
			29.032,
			31.718,
			34.411,
			38.457,
			41.164,
			45.243,
			46.606,
			47.971,
			52.076,
			54.823,
			58.953,
			61.715,
			64.486,
			65.873,
			68.654,
			70.047,
			72.838,
			75.635,
			77.037,
			79.844,
			82.661,
			86.898,
			89.729,
			93.989,
			95.413,
			96.838,
			101.124,
			103.989,
			108.301,
			111.184,
			114.073,
			115.521,
			118.42,
			119.872,
			121.322,
			125.654,
			127.089,
			129.938,
			132.753,
			135.531,
			138.27,
			139.625,
			142.308,
			144.953,
			146.261,
			148.849,
			150.13,
			152.661,
			155.154,
			158.82,
			161.219,
			164.748,
			165.908,
			167.077,
			170.452,
			172.668,
			175.939,
			178.06,
			181.176,
			183.215,
			184.221,
			186.209,
			189.138,
			191.052,
			192.948,
			194.823,
			197.614,
			199.465,
			201.312,
			202.238,
			204.09,
			205.016,
			206.872,
			208.736,
			211.537,
			213.417,
			216.237,
			217.185,
			218.133,
			220.977,
			222.879,
			225.729,
			227.628,
			229.523,
			230.468,
			232.358,
			233.302,
			235.187,
			237.069,
			238.008,
			239.88,
			241.75,
			244.554,
			245.487,
			246.421,
			249.23,
			250.167,
			251.103,
			253.914,
			255.789,
			258.605,
			260.486,
			262.373,
			265.218,
			266.168,
			267.122,
			270.002,
			270.964,
			271.929,
			274.832,
			276.777,
			278.727,
			279.705,
			281.67,
			283.651,
			284.645,
			286.642,
			287.647,
			289.665,
			291.69,
			294.753,
			296.803,
			299.9,
			300.934,
			301.969,
			305.082,
			307.157,
			310.249,
			312.298,
			315.354,
			317.385,
			318.401,
			319.415,
			322.453,
			323.463,
			324.472,
			327.487,
			329.492,
			331.487,
			332.483,
			334.474,
			336.457,
			337.447,
			339.422,
			340.406,
			342.37,
			344.323,
			347.238,
			349.163,
			352.033,
			352.982,
			353.928,
			356.757,
			358.631,
			361.433,
			363.295,
			365.152,
			366.082,
			367.94,
			368.867,
			370.724,
			373.519,
			375.387,
			377.265,
			380.095,
			381.995,
			383.908,
			385.833,
			386.801,
			388.747,
			389.725,
			391.691,
			393.673,
			396.674,
			398.691,
			401.741,
			402.763,
			403.788,
			406.878,
			408.948,
			412.052,
			414.141,
			416.233,
			417.277,
			419.363,
			420.405,
			422.483,
			424.541,
			425.56,
			427.575,
			429.554,
			432.455,
			433.404,
			434.346,
			437.115,
			438.021,
			438.921,
			440.697,
			441.573,
			443.315,
			445.865,
			447.539,
			449.2,
			451.673,
			452.493,
			453.312,
			455.762,
			457.391,
			459.831,
			461.455,
			463.892,
			465.512,
			466.322,
			467.942,
			469.56,
			470.369,
			471.983,
			473.599,
			476.031,
			477.65,
			480.098,
			480.916,
			481.734,
			484.193,
			485.844,
			488.317,
			489.993,
			491.672,
			492.511,
			494.201,
			495.061,
			495.92,
			498.493,
			499.376,
			500.268,
			502.94,
			504.751,
			507.503,
			509.337,
			511.173,
			512.09,
			513.922,
			514.821,
			516.615,
			518.409,
			521.067,
			522.831,
			525.462,
			526.334,
			527.205,
			529.799,
			531.502,
			534.05,
			535.708,
			538.188,
			539.834,
			540.653,
			542.285,
			543.913,
			544.725,
			546.341,
			547.953,
			549.561,
			551.986,
			553.605,
			555.22,
			556.027,
			557.642,
			558.454,
			560.073,
			561.69,
			564.13,
			565.759,
			568.209,
			569.031,
			569.853,
			572.32,
			573.974,
			576.455,
			578.114,
			580.608,
			582.269,
			583.939,
			584.775,
			586.445,
			587.281,
			588.969,
			590.663,
			593.218,
			594.086,
			594.954,
			597.561,
			598.447,
			599.338,
			601.123,
			602.017,
			603.831,
			605.666,
			608.435,
			610.296,
			613.09,
			614.011,
			614.932,
			617.7,
			619.495,
			622.18,
			623.938,
			625.67,
			626.536,
			628.264,
			629.118,
			630.825,
			632.532,
			633.384,
			635.083,
			636.783,
			639.331,
			641.03,
			643.585,
			644.439,
			645.293,
			647.864,
			649.597,
			652.212,
			653.973,
			655.751,
			658.428,
			659.336,
			660.246,
			662.998,
			663.927,
			664.86,
			667.679,
			669.58,
			671.497,
			672.46,
			674.393,
			676.337,
			677.314,
			679.272,
			680.254,
			682.227,
			684.207,
			687.193,
			689.195,
			692.217,
			693.23,
			694.245,
			697.307,
			699.36,
			702.458,
			704.535,
			706.635,
			707.676,
			709.757,
			710.801,
			712.895,
			714.98,
			716.035,
			718.152,
			720.301,
			723.516,
			724.595,
			725.676,
			727.843,
			730.013,
			731.1,
			733.276,
			734.363,
			736.539,
			739.798,
			741.96,
			744.111,
			747.331,
			748.403,
			749.482,
			752.716,
			754.885,
			758.153,
			760.35,
			762.549,
			763.649,
			765.877,
			766.991,
			769.229,
			771.493,
			772.628,
			774.901,
			777.18,
			780.599,
			782.879,
			786.297,
			787.437,
			788.578,
			791.994,
			794.268,
			796.531,
			799.899,
			802.119,
			805.423,
			806.518,
			807.612,
			810.881,
			813.053,
			816.307,
			818.476,
			820.648,
			821.734,
			823.91,
			825,
			827.186,
			830.48,
			832.69,
			834.909,
			838.255,
			840.498,
			843.881,
			845.013,
			846.148,
			849.547,
			851.837,
			855.289,
			857.602,
			861.088,
			863.423,
			864.594,
			866.94,
			869.281,
			870.447,
			871.606,
			875.041,
			877.287,
			879.495,
			880.586,
			882.739,
			884.855,
			885.9,
			888.978,
			890.984,
			892.954,
			895.84,
			897.719,
			900.474,
			901.379,
			902.292,
			904.962,
			906.728,
			909.375,
			911.143,
			913.804,
			915.588,
			917.377,
			918.272,
			921.01,
			922.865,
			924.734,
			926.72,
			928.703,
			929.699,
			931.759,
			933.806,
			934.818,
			936.786,
			937.763,
			939.685,
			941.439,
			943.998,
			945.62,
			947.959,
			948.726,
			949.486,
			951.716,
			953.161,
			955.284,
			956.659,
			958.004,
			958.667,
			959.987,
			960.651,
			961.96,
			963.244,
			963.879,
			965.184,
			966.535,
			968.533,
			969.195,
			969.87,
			971.983,
			972.683,
			973.382,
			975.496,
			976.916,
			978.332,
			980.434,
			981.803,
			983.853,
			984.536,
			985.183,
			987.086,
			988.352,
			990.23,
			991.435,
			992.639,
			993.242,
			994.449,
			995.049,
			996.239,
			997.431,
			998.027,
			999.22,
			1000.415,
			1002.212,
			1002.803,
			1003.406,
			1005.242,
			1005.863,
			1006.488,
			1008.384,
			1009.668,
			1011.652,
			1012.996,
			1014.356,
			1016.441,
			1017.144,
			1017.852,
			1020.726,
			1021.455,
			1023.667,
			1025.161,
			1026.673,
			1027.434,
			1028.969,
			1030.519,
			1031.299,
			1032.857,
			1033.648,
			1035.242,
			1036.85,
			1039.289,
			1040.943,
			1043.415,
			1044.251,
			1045.09,
			1047.602,
			1049.277,
			1051.807,
			1053.509,
			1055.224,
			1056.087,
			1057.821,
			1058.693,
			1060.447,
			1062.214,
			1063.103,
			1064.889,
			1066.688,
			1068.5,
			1070.325,
			1071.242,
			1073.086,
			1074.942,
			1075.874,
			1078.691,
			1080.585,
			1083.453,
			1085.381,
			1087.325,
			1090.264,
			1091.251,
			1092.24,
			1095.227,
			1097.232,
			1100.26,
			1102.291,
			1105.36,
			1107.419,
			1108.452,
			1110.527,
			1112.613,
			1113.656,
			1115.741,
			1117.831,
			1120.984,
			1122.041,
			1123.099,
			1125.224,
			1127.358,
			1128.429,
			1130.579,
			1131.658,
			1133.823,
			1135.999,
			1139.281,
			1141.482,
			1144.801,
			1145.913,
			1147.027,
			1150.384,
			1151.507,
			1152.634,
			1156.027,
			1158.301,
			1161.732,
			1164.032,
			1165.186,
			1167.503,
			1170.998,
			1173.342,
			1175.695,
			1179.244,
			1180.431,
			1181.62,
			1185.202,
			1186.4,
			1187.6,
			1191.213,
			1193.63,
			1197.271,
			1199.708,
			1202.155,
			1205.84,
			1208.308,
			1212.025,
			1213.268,
			1214.513,
			1218.261,
			1220.769,
			1223.285,
			1224.546,
			1227.073,
			1229.61,
			1230.881,
			1233.431,
			1234.709,
			1237.274,
			1239.849,
			1243.729,
			1246.325,
			1250.233,
			1251.537,
			1252.84,
			1256.76,
			1259.381,
			1263.326,
			1265.965,
			1268.611,
			1269.936,
			1272.592,
			1273.923,
			1276.589,
			1279.262,
			1280.602,
			1281.943,
			1285.976,
			1288.674,
			1291.378,
			1292.733,
			1295.447,
			1298.168,
			1299.531,
			1302.262,
			1303.63,
			1306.371,
			1309.117,
			1313.248,
			1316.011,
			1320.171,
			1321.561,
			1322.953,
			1327.14,
			1329.94,
			1334.153,
			1336.97,
			1339.794,
			1341.208,
			1344.042,
			1345.462,
			1348.307,
			1352.585,
			1355.447,
			1358.314,
			1361.187,
			1364.067,
			1365.509,
			1369.846,
			1371.295,
			1372.745,
			1377.105,
			1380.021,
			1382.942,
			1387.335,
			1390.271,
			1394.687,
			1396.162,
			1397.639,
			1402.077,
			1403.56,
			1405.044,
			1409.506,
			1412.488,
			1415.475,
			1416.971,
			1419.968,
			1421.469,
			1424.474,
			1427.48,
			1428.979,
			1431.964,
			1434.924,
			1439.295,
			1442.16,
			1446.388,
			1447.779,
			1449.159,
			1453.244,
			1455.92,
			1459.862,
			1462.442,
			1464.984,
			1466.241,
			1468.725,
			1469.953,
			1471.171,
			1474.767,
			1475.948,
			1477.12,
			1480.585,
			1482.848,
			1485.076,
			1486.176,
			1488.347,
			1490.48,
			1491.533,
			1494.635,
			1496.656,
			1498.641,
			1501.55,
			1503.444,
			1506.216,
			1507.123,
			1508.021,
			1510.66,
			1512.376,
			1514.871,
			1516.501,
			1518.081,
			1518.854,
			1520.374,
			1521.121,
			1522.588,
			1524.716,
			1525.407,
			1527.433,
			1528.743,
			1530.024,
			1530.654,
			1531.897,
			1533.117,
			1533.718,
			1534.91,
			1535.502,
			1536.679,
			1537.847,
			1539.573,
			1540.714,
			1542.408,
			1542.969,
			1543.534,
			1545.21,
			1546.315,
			1547.959,
			1549.097,
			1550.226,
			1550.787,
			1551.903,
			1552.459,
			1553.618,
			1554.813,
			1555.407,
			1556.59,
			1557.768,
			1559.667,
			1560.302,
			1560.935,
			1562.196,
			1563.475,
			1564.134,
			1565.443,
			1566.093,
			1567.388,
			1568.659,
			1570.525,
			1571.759,
			1573.573,
			1574.154,
			1574.733,
			1576.463,
			1577.612,
			1579.291,
			1580.394,
			1581.495,
			1582.045,
			1583.144,
			1584.209,
			1584.74,
			1585.8,
			1586.33,
			1587.391,
			1588.447,
			1590.001,
			1591.033,
			1592.58,
			1593.097,
			1593.616,
			1595.162,
			1596.189,
			1597.215,
			1598.767,
			1599.806,
			1600.325,
			1601.363,
			1601.883,
			1602.405,
			1603.985,
			1604.515,
			1605.046,
			1606.644,
			1607.719,
			1608.807,
			1609.354,
			1610.453,
			1611.56,
			1612.116,
			1613.239,
			1613.808,
			1614.953,
			1616.107,
			1617.855,
			1619.042,
			1620.844,
			1621.451,
			1622.061,
			1623.915,
			1625.171,
			1627.079,
			1628.372,
			1629.685,
			1630.346,
			1631.679,
			1632.35,
			1633.024,
			1635.063,
			1635.747,
			1637.123,
			1638.513,
			1639.916,
			1641.332,
			1642.046,
			1643.485,
			1644.933,
			1645.661,
			1647.866,
			1649.36,
			1650.867,
			1653.15,
			1654.719,
			1657.099,
			1657.9,
			1658.711,
			1661.192,
			1662.863,
			1665.401,
			1667.095,
			1669.65,
			1671.369,
			1672.219,
			1673.956,
			1675.702,
			1676.58,
			1678.345,
			1680.117,
			1681.9,
			1683.697,
			1684.601,
			1686.419,
			1688.249,
			1689.17,
			1691.021,
			1691.952,
			1693.822,
			1695.705,
			1698.553,
			1700.468,
			1703.364,
			1704.337,
			1705.314,
			1708.261,
			1710.242,
			1713.235,
			1715.246,
			1717.27,
			1718.286,
			1720.327,
			1722.378,
			1723.409,
			1725.479,
			1726.519,
			1728.607,
			1730.705,
			1733.852,
			1734.903,
			1735.956,
			1739.13,
			1740.193,
			1741.258,
			1744.47,
			1746.624,
			1749.873,
			1752.052,
			1754.241,
			1757.543,
			1758.649,
			1759.757,
			1763.097,
			1764.215,
			1765.336,
			1768.712,
			1770.976,
			1773.25,
			1774.39,
			1776.679,
			1778.977,
			1780.13,
			1782.443,
			1783.604,
			1785.931,
			1788.268,
			1791.792,
			1794.153,
			1797.713,
			1798.905,
			1800.098,
			1803.693,
			1806.102,
			1809.732,
			1812.164,
			1815.829,
			1818.283,
			1819.514,
			1820.747,
			1824.46,
			1825.702,
			1826.946,
			1830.691,
			1833.199,
			1836.977,
			1839.506,
			1842.044,
			1843.316,
			1845.866,
			1847.145,
			1849.708,
			1852.279,
			1856.15,
			1858.741,
			1862.643,
			1863.946,
			1865.25,
			1869.163,
			1870.31
		],
		[
			0,
			0.039,
			0.101,
			0.114,
			0.163,
			0.225,
			0.285,
			0.332,
			0.348,
			0.41,
			0.441,
			0.471,
			0.533,
			0.548,
			0.596,
			0.658,
			0.72,
			0.766,
			0.844,
			0.875,
			0.905,
			0.982,
			1.03,
			1.093,
			1.153,
			1.201,
			1.215,
			1.279,
			1.309,
			1.341,
			1.402,
			1.419,
			1.465,
			1.526,
			1.588,
			1.635,
			1.711,
			1.744,
			1.775,
			1.836,
			1.899,
			1.965,
			2.023,
			2.069,
			2.085,
			2.147,
			2.178,
			2.208,
			2.272,
			2.287,
			2.334,
			2.394,
			2.457,
			2.502,
			2.519,
			2.58,
			2.611,
			2.643,
			2.706,
			2.719,
			2.768,
			2.828,
			2.891,
			2.953,
			3.014,
			3.046,
			3.076,
			3.139,
			3.201,
			3.264,
			3.323,
			3.386,
			3.449,
			3.479,
			3.511,
			3.588,
			3.636,
			3.698,
			3.757,
			3.82,
			3.882,
			3.913,
			3.943,
			4.007,
			4.027,
			4.068,
			4.129,
			4.194,
			4.241,
			4.318,
			4.348,
			4.378,
			4.442,
			4.502,
			4.564,
			4.627,
			4.673,
			4.688,
			4.75,
			4.783,
			4.816,
			4.875,
			4.891,
			4.936,
			5,
			5.061,
			5.108,
			5.124,
			5.186,
			5.216,
			5.247,
			5.31,
			5.371,
			5.435,
			5.496,
			5.542,
			5.62,
			5.651,
			5.68,
			5.744,
			5.76,
			5.803,
			5.867,
			5.929,
			5.976,
			5.991,
			6.054,
			6.086,
			6.114,
			6.178,
			6.193,
			6.239,
			6.3,
			6.364,
			6.41,
			6.487,
			6.517,
			6.55,
			6.627,
			6.674,
			6.736,
			6.798,
			6.861,
			6.92,
			6.953,
			6.983,
			7.044,
			7.062,
			7.107,
			7.17,
			7.233,
			7.277,
			7.294,
			7.356,
			7.387,
			7.419,
			7.479,
			7.496,
			7.542,
			7.604,
			7.664,
			7.711,
			7.788,
			7.821,
			7.852,
			7.915,
			7.976,
			8.038,
			8.1,
			8.151,
			8.161,
			8.224,
			8.255,
			8.285,
			8.364,
			8.41,
			8.471,
			8.534,
			8.594,
			8.657,
			8.689,
			8.719,
			8.781,
			8.796,
			8.844,
			8.907,
			8.967,
			9.014,
			9.092,
			9.124,
			9.154,
			9.217,
			9.278,
			9.341,
			9.403,
			9.447,
			9.464,
			9.527,
			9.557,
			9.586,
			9.651,
			9.664,
			9.711,
			9.774,
			9.837,
			9.883,
			9.897,
			9.961,
			9.991,
			10.022,
			10.084,
			10.099,
			10.146,
			10.211,
			10.271,
			10.317,
			10.394,
			10.425,
			10.456,
			10.519,
			10.58,
			10.641,
			10.703,
			10.765,
			10.829,
			10.859,
			10.89,
			10.953,
			10.968,
			11.013,
			11.077,
			11.138,
			11.201,
			11.261,
			11.294,
			11.325,
			11.403,
			11.449,
			11.511,
			11.571,
			11.62,
			11.634,
			11.697,
			11.726,
			11.758,
			11.82,
			11.836,
			11.883,
			11.944,
			12.006,
			12.067,
			12.13,
			12.161,
			12.193,
			12.252,
			12.274,
			12.317,
			12.378,
			12.441,
			12.502,
			12.564,
			12.594,
			12.627,
			12.688,
			12.751,
			12.812,
			12.874,
			12.935,
			12.999,
			13.03,
			13.061,
			13.121,
			13.138,
			13.185,
			13.248,
			13.308,
			13.37,
			13.432,
			13.463,
			13.495,
			13.555,
			13.571,
			13.618,
			13.679,
			13.743,
			13.788,
			13.866,
			13.897,
			13.927,
			14.007,
			14.054,
			14.114,
			14.175,
			14.239,
			14.3,
			14.336,
			14.364,
			14.424,
			14.441,
			14.486,
			14.549,
			14.611,
			14.658,
			14.672,
			14.734,
			14.764,
			14.796,
			14.858,
			14.874,
			14.92,
			14.981,
			15.045,
			15.092,
			15.168,
			15.201,
			15.232,
			15.294,
			15.356,
			15.417,
			15.477,
			15.526,
			15.541,
			15.603,
			15.633,
			15.663,
			15.725,
			15.743,
			15.79,
			15.852,
			15.914,
			15.96,
			16.036,
			16.069,
			16.099,
			16.176,
			16.223,
			16.286,
			16.346,
			16.408,
			16.469,
			16.501,
			16.533,
			16.596,
			16.61,
			16.657,
			16.72,
			16.781,
			16.828,
			16.844,
			16.904,
			16.935,
			16.967,
			17.028,
			17.045,
			17.092,
			17.153,
			17.214,
			17.261,
			17.337,
			17.37,
			17.401,
			17.48,
			17.525,
			17.586,
			17.649,
			17.697,
			17.711,
			17.771,
			17.805,
			17.835,
			17.897,
			17.914,
			17.96,
			18.022,
			18.084,
			18.129,
			18.146,
			18.208,
			18.239,
			18.27,
			18.33,
			18.346,
			18.392,
			18.462,
			18.518,
			18.563,
			18.641,
			18.671,
			18.704,
			18.767,
			18.828,
			18.89,
			18.951,
			18.998,
			19.015,
			19.076,
			19.107,
			19.136,
			19.2,
			19.215,
			19.262,
			19.324,
			19.386,
			19.446,
			19.51,
			19.539,
			19.57,
			19.634,
			19.697,
			19.758,
			19.818,
			19.867,
			19.943,
			19.975,
			20.005,
			20.068,
			20.13,
			20.192,
			20.252,
			20.3,
			20.314,
			20.377,
			20.408,
			20.44,
			20.523,
			20.562,
			20.627,
			20.688,
			20.735,
			20.812,
			20.841,
			20.874,
			20.935,
			20.997,
			21.06,
			21.123,
			21.184,
			21.247,
			21.278,
			21.309,
			21.37,
			21.386,
			21.433,
			21.493,
			21.554,
			21.604,
			21.618,
			21.681,
			21.711,
			21.741,
			21.818,
			21.866,
			21.927,
			21.991,
			22.036,
			22.115,
			22.145,
			22.175,
			22.238,
			22.299,
			22.363,
			22.425,
			22.486,
			22.548,
			22.584,
			22.611,
			22.687,
			22.734,
			22.797,
			22.857,
			22.905,
			22.919,
			22.982,
			23.013,
			23.045,
			23.108,
			23.122,
			23.167,
			23.23,
			23.291,
			23.337,
			23.417,
			23.446,
			23.478,
			23.541,
			23.603,
			23.665,
			23.727,
			23.773,
			23.789,
			23.85,
			23.882,
			23.912,
			23.975,
			23.991,
			24.035,
			24.1,
			24.16,
			24.207,
			24.221,
			24.286,
			24.317,
			24.347,
			24.409,
			24.47,
			24.533,
			24.595,
			24.644,
			24.717,
			24.75,
			24.781,
			24.842,
			24.903,
			24.966,
			25.029,
			25.075,
			25.091,
			25.152,
			25.183,
			25.213,
			25.275,
			25.292,
			25.339,
			25.401,
			25.461,
			25.508,
			25.525,
			25.586,
			25.617,
			25.649,
			25.712,
			25.774,
			25.835,
			25.898,
			25.958,
			26.021,
			26.052,
			26.083,
			26.161,
			26.207,
			26.268,
			26.329,
			26.377,
			26.394,
			26.455,
			26.487,
			26.518,
			26.58,
			26.593,
			26.641,
			26.703,
			26.764,
			26.812,
			26.89,
			26.919,
			26.952,
			27.011,
			27.074,
			27.136,
			27.198,
			27.245,
			27.261,
			27.323,
			27.354,
			27.386,
			27.447,
			27.461,
			27.51,
			27.571,
			27.633,
			27.68,
			27.695,
			27.758,
			27.788,
			27.82,
			27.897,
			27.943,
			28.01,
			28.066,
			28.114,
			28.192,
			28.223,
			28.254,
			28.314,
			28.377,
			28.44,
			28.501,
			28.564,
			28.626,
			28.656,
			28.686,
			28.748,
			28.768,
			28.811,
			28.874,
			28.936,
			28.98,
			28.997,
			29.058,
			29.091,
			29.12,
			29.183,
			29.198,
			29.244,
			29.307,
			29.368,
			29.417,
			29.494,
			29.524,
			29.556,
			29.617,
			29.634,
			29.68,
			29.74,
			29.803,
			29.865,
			29.927,
			29.958,
			29.991,
			30.067,
			30.113,
			30.177,
			30.236,
			30.284,
			30.3,
			30.362,
			30.392,
			30.425,
			30.485,
			30.549,
			30.611,
			30.672,
			30.718,
			30.797,
			30.858,
			30.921,
			30.935,
			30.982,
			31.044,
			31.106,
			31.153,
			31.169,
			31.231,
			31.262,
			31.292,
			31.355,
			31.368,
			31.414,
			31.478,
			31.538,
			31.601,
			31.663,
			31.694,
			31.725,
			31.789,
			31.85,
			31.911,
			31.974,
			32.02,
			32.036,
			32.096,
			32.129,
			32.161,
			32.222,
			32.236,
			32.283,
			32.346,
			32.408,
			32.455,
			32.469,
			32.531,
			32.563,
			32.593,
			32.656,
			32.671,
			32.719,
			32.781,
			32.841,
			32.904,
			32.965,
			32.996,
			33.027,
			33.091,
			33.151,
			33.214,
			33.277,
			33.324,
			33.339,
			33.4,
			33.43,
			33.462,
			33.54,
			33.586,
			33.65,
			33.709,
			33.757,
			33.771,
			33.835,
			33.864,
			33.895,
			33.959,
			34.02,
			34.083,
			34.143,
			34.205,
			34.269,
			34.3,
			34.33,
			34.391,
			34.409,
			34.453,
			34.517,
			34.577,
			34.626,
			34.641,
			34.701,
			34.733,
			34.763,
			34.827,
			34.843,
			34.889,
			34.955,
			35.013,
			35.059,
			35.136,
			35.166,
			35.197,
			35.276,
			35.323,
			35.387,
			35.447,
			35.494,
			35.51,
			35.569,
			35.601,
			35.631,
			35.694,
			35.71,
			35.756,
			35.819,
			35.882,
			35.926,
			35.942,
			36.004,
			36.037,
			36.067,
			36.145,
			36.191,
			36.253,
			36.314,
			36.36,
			36.438,
			36.47,
			36.501,
			36.563,
			36.625,
			36.686,
			36.749,
			36.796,
			36.811,
			36.871,
			36.903,
			36.934,
			37.017,
			37.058,
			37.124,
			37.182,
			37.23,
			37.245,
			37.306,
			37.338,
			37.368,
			37.431,
			37.447,
			37.492,
			37.554,
			37.617,
			37.664,
			37.741,
			37.77,
			37.804,
			37.865,
			37.925,
			37.99,
			38.05,
			38.096,
			38.114,
			38.174,
			38.207,
			38.237,
			38.298,
			38.314,
			38.361,
			38.422,
			38.485,
			38.531,
			38.548,
			38.609,
			38.641,
			38.67,
			38.733,
			38.747,
			38.794,
			38.857,
			38.92,
			38.966,
			39.043,
			39.078,
			39.103,
			39.166,
			39.228,
			39.291,
			39.352,
			39.399,
			39.416,
			39.477,
			39.509,
			39.54,
			39.6,
			39.617,
			39.664,
			39.724,
			39.786,
			39.834,
			39.91,
			39.941,
			39.975,
			40.034,
			40.096,
			40.158,
			40.22,
			40.267,
			40.284,
			40.346,
			40.377,
			40.407,
			40.469,
			40.485,
			40.531,
			40.594,
			40.655,
			40.702,
			40.717,
			40.778,
			40.81,
			40.841,
			40.904,
			40.919,
			40.966,
			41.028,
			41.09,
			41.14,
			41.214,
			41.243,
			41.274,
			41.336,
			41.4,
			41.46,
			41.524,
			41.57,
			41.586,
			41.649,
			41.678,
			41.708,
			41.77,
			41.787,
			41.834,
			41.897,
			41.958,
			42.005,
			42.019,
			42.081,
			42.115,
			42.144,
			42.221,
			42.267,
			42.331,
			42.392,
			42.439,
			42.517,
			42.547,
			42.578,
			42.64,
			42.701,
			42.763,
			42.827,
			42.886,
			42.95,
			42.981,
			43.012,
			43.074,
			43.09,
			43.136,
			43.202,
			43.258,
			43.307,
			43.321,
			43.383,
			43.413,
			43.446,
			43.508,
			43.524,
			43.57,
			43.632,
			43.693,
			43.741,
			43.819,
			43.847,
			43.879,
			43.957,
			44.004,
			44.067,
			44.129,
			44.174,
			44.19,
			44.252,
			44.284,
			44.315,
			44.375,
			44.391,
			44.438,
			44.501,
			44.562,
			44.608,
			44.624,
			44.686,
			44.717,
			44.747,
			44.811,
			44.873,
			44.935,
			44.997,
			45.041,
			45.12,
			45.151,
			45.182,
			45.245,
			45.264,
			45.307,
			45.37,
			45.43,
			45.476,
			45.493,
			45.553,
			45.586,
			45.617,
			45.677,
			45.693,
			45.741,
			45.803,
			45.863,
			45.912,
			45.989,
			46.02,
			46.051,
			46.112,
			46.174,
			46.236,
			46.298,
			46.36,
			46.423,
			46.453,
			46.483,
			46.547,
			46.562,
			46.608,
			46.67,
			46.732,
			46.795,
			46.857,
			46.887,
			46.919,
			46.979,
			46.996,
			47.043,
			47.103,
			47.165,
			47.213,
			47.291,
			47.327,
			47.353,
			47.416,
			47.446
		]
	],
	'1673_1676_7982': [
		[
			88.823
		],
		[
			0
		],
		[
			0,
			2.183,
			3.467,
			6.042,
			8.625,
			12.515,
			15.12,
			19.032,
			20.338,
			21.645,
			25.578,
			28.209,
			32.169,
			34.817,
			37.472,
			38.803,
			41.467,
			42.801,
			45.476,
			48.157,
			49.501,
			52.192,
			54.89,
			57.594,
			60.304,
			61.662,
			64.383,
			67.11,
			68.477,
			71.214,
			72.585,
			75.331,
			78.084,
			82.224,
			84.992,
			89.156,
			90.547,
			91.94,
			96.127,
			98.926,
			103.138,
			105.953,
			108.776,
			110.19,
			113.023,
			114.442,
			117.284,
			121.559,
			124.417,
			127.281,
			131.588,
			133.026,
			134.466,
			138.794,
			140.239,
			141.685,
			146.032,
			148.937,
			153.304,
			156.223,
			159.147,
			163.544,
			165.012,
			166.483,
			170.902,
			173.854,
			178.291,
			181.247,
			185.664,
			188.585,
			190.035,
			192.912,
			195.756,
			197.166,
			199.959,
			202.717,
			206.785,
			209.447,
			213.352,
			214.633,
			215.897,
			219.634,
			222.077,
			225.692,
			228.111,
			230.493,
			233.981,
			235.126,
			236.262,
			240.71,
			241.806,
			245.075,
			247.214,
			249.333,
			250.366,
			252.425,
			254.514,
			255.605,
			257.78,
			258.839,
			260.86,
			262.871,
			265.836,
			267.796,
			270.696,
			271.656,
			272.614,
			275.536,
			277.498,
			280.504,
			282.533,
			284.563,
			285.58,
			287.61,
			288.623,
			289.634,
			292.658,
			293.664,
			295.683,
			297.698,
			299.715,
			301.736,
			302.745,
			304.749,
			306.775,
			307.788,
			309.839,
			310.86,
			312.911,
			314.975,
			318.095,
			320.19,
			323.353,
			324.412,
			325.475,
			328.678,
			330.827,
			334.063,
			336.23,
			338.412,
			339.506,
			341.698,
			343.893,
			344.995,
			347.206,
			348.315,
			350.532,
			352.748,
			356.079,
			357.189,
			358.3,
			361.627,
			362.732,
			363.838,
			366.045,
			367.147,
			369.354,
			371.572,
			374.91,
			377.153,
			380.544,
			381.741,
			382.939,
			386.412,
			388.689,
			392.115,
			394.409,
			396.71,
			397.864,
			400.177,
			401.335,
			403.657,
			407.154,
			409.494,
			411.844,
			415.385,
			417.766,
			421.354,
			422.557,
			423.761,
			427.382,
			429.802,
			433.449,
			435.889,
			439.569,
			442.034,
			443.27,
			444.508,
			448.235,
			449.482,
			450.709,
			454.484,
			456.997,
			459.517,
			460.759,
			463.291,
			465.831,
			467.105,
			469.657,
			470.936,
			473.501,
			476.073,
			479.945,
			482.535,
			486.426,
			487.724,
			489.023,
			492.931,
			495.544,
			499.476,
			502.105,
			504.74,
			508.705,
			510.03,
			512.685,
			515.346,
			516.68,
			518.014,
			522.029,
			524.715,
			527.407,
			528.756,
			531.458,
			534.169,
			535.526,
			538.246,
			539.609,
			542.339,
			546.445,
			549.19,
			551.941,
			556.079,
			557.461,
			558.845,
			563.006,
			565.788,
			569.974,
			572.773,
			575.58,
			576.986,
			579.803,
			581.215,
			584.042,
			586.876,
			588.296,
			591.14,
			593.991,
			598.278,
			599.709,
			601.143,
			605.451,
			606.89,
			608.33,
			611.214,
			612.658,
			615.551,
			618.448,
			622.803,
			625.713,
			630.088,
			631.548,
			633.011,
			637.405,
			640.34,
			644.75,
			647.688,
			652.109,
			655.021,
			656.475,
			659.362,
			662.228,
			663.649,
			666.443,
			669.226,
			673.335,
			676.028,
			680.022,
			681.325,
			682.62,
			686.436,
			688.965,
			691.455,
			695.136,
			698.721,
			701.079,
			702.256,
			703.426,
			708.06,
			709.211,
			712.655,
			714.946,
			718.378,
			720.66,
			722.924,
			724.052,
			726.309,
			727.433,
			729.665,
			731.921,
			735.309,
			737.576,
			740.988,
			742.13,
			743.273,
			746.716,
			749.022,
			752.492,
			754.809,
			757.121,
			758.277,
			760.572,
			761.711,
			763.979,
			766.215,
			767.338,
			769.534,
			771.696,
			773.841,
			775.96,
			777.009,
			779.096,
			781.152,
			782.185,
			785.271,
			787.342,
			789.377,
			792.422,
			794.436,
			797.452,
			798.459,
			799.466,
			802.484,
			804.501,
			807.522,
			809.546,
			812.576,
			814.608,
			815.624,
			817.656,
			819.709,
			820.737,
			822.794,
			824.863,
			826.935,
			829.007,
			830.043,
			833.15,
			834.185,
			835.219,
			837.288,
			838.322,
			840.386,
			842.449,
			845.536,
			847.59,
			850.661,
			851.684,
			852.706,
			855.76,
			857.792,
			860.828,
			862.85,
			865.874,
			867.89,
			868.899,
			870.914,
			872.929,
			873.937,
			875.95,
			877.964,
			880.975,
			882.984,
			885.996,
			887.001,
			888.006,
			890.018,
			891.023,
			893.033,
			895.016,
			897.985,
			899.934,
			902.795,
			903.729,
			904.653,
			907.37,
			908.259,
			909.139,
			911.73,
			913.409,
			915.052,
			915.86,
			917.445,
			919.006,
			919.764,
			921.252,
			921.982,
			923.414,
			924.813,
			926.849,
			927.512,
			928.167,
			930.1,
			930.732,
			931.357,
			933.198,
			934.403,
			936.185,
			937.363,
			938.539,
			940.313,
			940.906,
			942.096,
			943.891,
			945.087,
			946.283,
			947.481,
			948.683,
			949.283,
			950.471,
			951.658,
			952.252,
			954.033,
			955.216,
			956.395,
			958.158,
			959.334,
			961.085,
			961.666,
			962.246,
			963.987,
			965.143,
			966.865,
			968.01,
			969.158,
			969.734,
			970.884,
			971.459,
			972.608,
			973.761,
			974.341,
			975.52,
			976.702,
			978.482,
			979.079,
			979.687,
			980.914,
			982.145,
			982.762,
			984,
			984.623,
			985.872,
			987.122,
			989,
			990.262,
			992.153,
			992.783,
			993.414,
			995.324,
			996.607,
			998.538,
			999.837,
			1001.157,
			1001.818,
			1003.141,
			1004.469,
			1005.147,
			1007.188,
			1008.551,
			1009.924,
			1012.017,
			1012.717,
			1013.418,
			1015.544,
			1016.261,
			1016.98,
			1019.149,
			1020.62,
			1022.106,
			1024.35,
			1025.874,
			1028.177,
			1028.947,
			1029.72,
			1032.064,
			1032.846,
			1033.629,
			1035.98,
			1037.543,
			1039.106,
			1039.887,
			1041.433,
			1042.975,
			1043.744,
			1045.281,
			1046.047,
			1047.573,
			1049.095,
			1051.375,
			1052.891,
			1055.16,
			1055.917,
			1056.672,
			1058.937,
			1060.447,
			1062.7,
			1064.204,
			1065.707,
			1066.455,
			1067.952,
			1068.701,
			1070.205,
			1071.7,
			1072.449,
			1073.199,
			1075.458,
			1076.962,
			1078.472,
			1079.23,
			1080.752,
			1082.28,
			1083.047,
			1084.589,
			1085.366,
			1086.941,
			1088.526,
			1090.933,
			1092.563,
			1095.03,
			1095.85,
			1096.666,
			1099.119,
			1100.77,
			1103.258,
			1104.932,
			1106.635,
			1107.496,
			1109.228,
			1110.099,
			1111.862,
			1113.643,
			1114.538,
			1116.34,
			1118.159,
			1120.91,
			1121.833,
			1122.743,
			1124.603,
			1126.477,
			1127.418,
			1129.311,
			1130.261,
			1132.176,
			1134.105,
			1137.023,
			1138.986,
			1141.955,
			1142.953,
			1143.953,
			1146.975,
			1149.01,
			1152.087,
			1154.157,
			1157.284,
			1159.381,
			1161.476,
			1162.527,
			1164.635,
			1165.693,
			1167.818,
			1169.953,
			1173.175,
			1175.336,
			1178.598,
			1179.69,
			1180.785,
			1182.983,
			1184.085,
			1186.298,
			1188.522,
			1191.877,
			1194.126,
			1195.255,
			1197.519,
			1198.655,
			1199.793,
			1203.224,
			1205.524,
			1208.993,
			1211.317,
			1213.652,
			1214.824,
			1217.174,
			1219.534,
			1220.718,
			1223.093,
			1224.285,
			1226.675,
			1229.076,
			1232.696,
			1235.122,
			1238.78,
			1240.005,
			1241.232,
			1244.928,
			1247.405,
			1251.14,
			1253.643,
			1256.157,
			1257.417,
			1259.946,
			1261.214,
			1263.758,
			1267.591,
			1268.873,
			1272.734,
			1275.32,
			1277.914,
			1279.214,
			1281.82,
			1284.433,
			1285.74,
			1288.355,
			1289.665,
			1292.29,
			1294.923,
			1298.885,
			1301.537,
			1305.527,
			1306.861,
			1308.197,
			1312.216,
			1314.903,
			1318.949,
			1321.655,
			1324.366,
			1325.725,
			1328.446,
			1329.808,
			1332.538,
			1335.275,
			1336.646,
			1338.019,
			1342.148,
			1344.91,
			1347.679,
			1349.067,
			1353.243,
			1354.639,
			1356.038,
			1358.839,
			1360.243,
			1363.055,
			1365.875,
			1370.118,
			1372.956,
			1377.225,
			1378.652,
			1380.08,
			1384.376,
			1387.249,
			1391.571,
			1394.462,
			1397.36,
			1398.812,
			1401.721,
			1403.178,
			1406.098,
			1409.024,
			1410.49,
			1413.426,
			1416.37,
			1420.798,
			1422.278,
			1423.759,
			1428.213,
			1429.701,
			1431.191,
			1435.671,
			1438.666,
			1441.667,
			1446.182,
			1449.201,
			1453.741,
			1455.257,
			1456.776,
			1461.341,
			1462.865,
			1464.392,
			1468.98,
			1472.047,
			1475.121,
			1476.66,
			1479.743,
			1482.832,
			1484.379,
			1487.478,
			1489.03,
			1492.137,
			1495.251,
			1499.934,
			1503.062,
			1507.766,
			1509.337,
			1510.909,
			1515.636,
			1518.797,
			1523.552,
			1526.73,
			1529.917,
			1534.707,
			1536.306,
			1537.908,
			1542.726,
			1544.337,
			1545.949,
			1550.799,
			1554.041,
			1557.289,
			1558.916,
			1562.174,
			1565.437,
			1567.07,
			1570.339,
			1571.975,
			1575.247,
			1578.522,
			1583.437,
			1586.714,
			1591.638,
			1593.281,
			1594.927,
			1599.871,
			1603.177,
			1608.149,
			1611.473,
			1614.803,
			1616.47,
			1619.807,
			1621.478,
			1624.822,
			1628.168,
			1629.842,
			1633.19,
			1636.54,
			1639.893,
			1643.25,
			1644.929,
			1649.972,
			1651.655,
			1653.339,
			1656.71,
			1658.398,
			1661.776,
			1665.159,
			1670.241,
			1673.635,
			1678.734,
			1680.436,
			1682.139,
			1687.255,
			1690.671,
			1695.802,
			1699.228,
			1702.658,
			1704.375,
			1707.811,
			1709.53,
			1712.973,
			1716.42,
			1718.145,
			1721.599,
			1725.057,
			1730.254,
			1731.989,
			1733.726,
			1738.943,
			1740.684,
			1742.426,
			1745.914,
			1747.659,
			1751.151,
			1754.646,
			1759.893,
			1763.394,
			1768.651,
			1770.404,
			1772.159,
			1777.427,
			1779.185,
			1780.945,
			1786.23,
			1789.761,
			1793.296,
			1795.065,
			1798.607,
			1802.153,
			1803.927,
			1809.254,
			1812.809,
			1816.367,
			1821.71,
			1825.276,
			1830.632,
			1832.418,
			1834.206,
			1839.574,
			1843.157,
			1848.537,
			1852.128,
			1855.721,
			1861.118,
			1862.919,
			1866.522,
			1871.932,
			1875.542,
			1879.156,
			1882.774,
			1886.397,
			1888.21,
			1891.839,
			1895.473,
			1897.291,
			1902.754,
			1906.4,
			1910.051,
			1915.52,
			1919.147,
			1924.531,
			1926.307,
			1928.074,
			1933.318,
			1936.765,
			1941.862,
			1945.211,
			1948.521,
			1950.162,
			1953.415,
			1955.027,
			1958.221,
			1962.94,
			1966.04,
			1969.103,
			1973.631,
			1976.606,
			1979.544,
			1982.445,
			1983.881,
			1986.725,
			1988.132,
			1990.918,
			1993.666,
			1997.715,
			2000.368,
			2004.277,
			2005.561,
			2006.836,
			2010.605,
			2013.069,
			2016.699,
			2019.077,
			2021.423,
			2022.584,
			2024.901,
			2026.039,
			2028.289,
			2031.607,
			2033.781,
			2035.921,
			2039.062,
			2041.112,
			2044.115,
			2045.1,
			2046.079,
			2048.972,
			2050.863,
			2052.735,
			2055.484,
			2057.286,
			2059.944,
			2060.822,
			2061.707,
			2064.341,
			2065.213,
			2066.086,
			2068.703,
			2070.438,
			2072.162,
			2073.02,
			2074.73,
			2075.583,
			2077.285,
			2078.979,
			2079.822,
			2081.519,
			2083.227,
			2085.774,
			2087.535,
			2090.17,
			2091.051,
			2091.972,
			2094.72,
			2096.551,
			2099.302,
			2101.131,
			2102.855,
			2103.715,
			2105.433,
			2106.284,
			2107.098,
			2109.534,
			2110.346,
			2111.157,
			2113.574,
			2115.184,
			2117.603,
			2119.215,
			2120.831,
			2121.639,
			2124.062,
			2125.68,
			2127.299,
			2129.727,
			2131.349,
			2133.78,
			2134.591,
			2135.403,
			2137.845,
			2139.475,
			2141.932,
			2143.586,
			2146.083,
			2147.776,
			2148.629,
			2150.347,
			2152.081,
			2152.956,
			2154.718,
			2156.492,
			2159.18,
			2160.988,
			2163.704,
			2164.62,
			2165.539,
			2167.385,
			2168.311,
			2170.167,
			2172.029,
			2174.809,
			2176.643,
			2179.348,
			2180.234,
			2181.112,
			2183.694,
			2184.537,
			2185.37,
			2187.821,
			2189.422,
			2191.012,
			2191.793,
			2193.351,
			2194.125,
			2195.666,
			2197.198,
			2197.965,
			2199.492,
			2201.01,
			2203.278,
			2204.032,
			2204.785,
			2207.031,
			2207.778,
			2208.525,
			2210.753,
			2212.233,
			2213.718,
			2215.938,
			2217.415,
			2219.671,
			2220.424,
			2221.176,
			2223.454,
			2224.229,
			2225.003,
			2227.328,
			2228.905,
			2230.492,
			2231.285,
			2232.872,
			2234.473,
			2235.272,
			2236.869,
			2237.668,
			2239.259,
			2240.844,
			2243.218,
			2244.792,
			2247.147,
			2247.932,
			2248.719,
			2251.075,
			2252.643,
			2254.991,
			2256.551,
			2258.111,
			2260.425,
			2261.196,
			2262.738,
			2264.268,
			2265.03,
			2265.791,
			2268.072,
			2269.579,
			2271.08,
			2271.83,
			2273.326,
			2274.812,
			2275.554,
			2277.035,
			2277.774,
			2279.246,
			2280.712,
			2282.911,
			2284.377,
			2286.575,
			2287.309,
			2288.044,
			2290.271,
			2291.766,
			2294.024,
			2295.547,
			2297.078,
			2297.847,
			2299.392,
			2300.168,
			2301.726,
			2304.083,
			2304.872,
			2307.256,
			2309.666,
			2311.287,
			2312.92,
			2314.569,
			2315.399,
			2317.07,
			2317.911,
			2319.607,
			2321.32,
			2323.919,
			2325.677,
			2328.343,
			2329.24,
			2330.127,
			2332.857,
			2334.696,
			2337.486,
			2339.365,
			2341.256,
			2342.207,
			2344.122,
			2345.082,
			2347.012,
			2348.958,
			2349.933,
			2351.886,
			2353.848,
			2356.771,
			2357.739,
			2358.703,
			2360.61,
			2362.487,
			2363.415,
			2366.166,
			2367.946,
			2369.693,
			2372.281,
			2373.977,
			2376.504,
			2377.342,
			2378.177,
			2380.679,
			2382.35,
			2384.853,
			2386.524,
			2388.192,
			2389.026,
			2390.686,
			2392.327,
			2393.146,
			2394.787,
			2395.6,
			2397.202,
			2398.804,
			2401.22,
			2402.838,
			2405.263,
			2406.088,
			2406.914,
			2409.387,
			2411.042,
			2413.532,
			2415.19,
			2416.893,
			2419.447,
			2420.298,
			2422.038,
			2423.779,
			2424.648,
			2425.517,
			2428.13,
			2429.867,
			2432.505,
			2434.266,
			2436.026,
			2436.905,
			2438.659,
			2439.535,
			2441.27,
			2442.976,
			2445.531,
			2447.21,
			2449.717,
			2450.552,
			2451.38,
			2453.851,
			2455.493,
			2457.936,
			2459.558,
			2461.177,
			2461.982,
			2463.592,
			2464.396,
			2466.007,
			2468.433,
			2470.061,
			2471.701,
			2474.187,
			2475.021,
			2475.858,
			2477.557,
			2479.27,
			2480.131,
			2482.751,
			2484.521,
			2486.305,
			2489.014,
			2490.845,
			2493.632,
			2494.568,
			2495.508,
			2498.348,
			2500.258,
			2503.153,
			2505.101,
			2507.067,
			2508.059,
			2510.054,
			2511.056,
			2513.088,
			2515.113,
			2516.128,
			2518.162,
			2520.2,
			2523.257,
			2524.276,
			2525.294,
			2528.344,
			2529.358,
			2530.372,
			2532.396,
			2533.405,
			2535.419,
			2537.426,
			2540.416,
			2542.399,
			2545.354,
			2546.335,
			2547.314,
			2550.231,
			2551.199,
			2552.165,
			2555.042,
			2556.948,
			2558.845,
			2559.79,
			2561.675,
			2562.616,
			2564.494,
			2566.368,
			2567.305,
			2569.177,
			2571.046,
			2573.849,
			2575.718,
			2578.528,
			2579.468,
			2580.408,
			2583.242,
			2585.143,
			2587.054,
			2589.944,
			2591.886,
			2594.809,
			2595.795,
			2596.785,
			2600.79,
			2601.8,
			2604.851,
			2606.9,
			2608.951,
			2609.979,
			2612.043,
			2614.108,
			2615.143,
			2617.22,
			2618.259,
			2620.332,
			2622.411,
			2625.55,
			2627.655,
			2630.836,
			2631.901,
			2632.97,
			2636.192,
			2638.355,
			2641.619,
			2643.81,
			2646.01,
			2647.132,
			2649.344,
			2650.453,
			2652.671,
			2654.901,
			2656.017,
			2658.249,
			2660.498,
			2662.743,
			2665.012,
			2666.147,
			2668.416,
			2670.71,
			2671.852,
			2675.265,
			2677.529,
			2679.789,
			2683.173,
			2685.406,
			2688.741,
			2689.841,
			2690.939,
			2694.219,
			2696.376,
			2699.592,
			2701.712,
			2704.887,
			2706.998,
			2708.055,
			2710.172,
			2712.295,
			2713.361,
			2715.497,
			2717.643,
			2720.879,
			2723.048,
			2725.225,
			2727.405,
			2728.495,
			2731.753,
			2733.909,
			2736.048,
			2739.207,
			2741.261,
			2744.302,
			2745.299,
			2746.303,
			2749.211,
			2750.162,
			2751.101,
			2753.865,
			2755.66,
			2757.416,
			2758.282,
			2759.992,
			2761.668,
			2762.496,
			2764.137,
			2764.95,
			2766.56,
			2768.155,
			2770.533,
			2772.113,
			2774.481,
			2775.271,
			2776.063,
			2778.437,
			2780.022,
			2782.411,
			2784.005,
			2785.603,
			2788.008,
			2788.81,
			2789.612,
			2792.028,
			2792.834,
			2793.64,
			2796.061,
			2797.679,
			2799.294,
			2800.102,
			2801.724,
			2803.344,
			2804.153,
			2805.774,
			2806.59,
			2808.219,
			2809.845,
			2812.309,
			2813.954,
			2816.433,
			2817.266,
			2818.098,
			2820.594,
			2822.266,
			2824.774,
			2826.445,
			2828.114,
			2828.949,
			2830.621,
			2831.452,
			2832.283,
			2834.779,
			2835.613,
			2837.267,
			2838.919,
			2841.397,
			2842.216,
			2843.036,
			2844.677,
			2846.323,
			2847.146,
			2848.796,
			2849.625,
			2851.29,
			2852.957,
			2855.47,
			2857.149,
			2859.669,
			2860.513,
			2861.358,
			2863.894,
			2865.596,
			2868.159,
			2869.877,
			2871.607,
			2872.48,
			2874.236,
			2875.118,
			2876.893,
			2879.606,
			2881.43,
			2883.29,
			2886.11,
			2887.058,
			2888.016,
			2889.941,
			2891.879,
			2892.856,
			2895.808,
			2897.777,
			2899.775,
			2902.795,
			2904.826,
			2907.893,
			2908.922,
			2909.955,
			2913.068,
			2914.113,
			2915.159,
			2918.305,
			2920.405,
			2923.573,
			2925.699,
			2927.836,
			2928.909,
			2931.062,
			2932.142,
			2934.31,
			2936.489,
			2939.777,
			2940.877,
			2941.981,
			2945.304,
			2946.417,
			2947.531,
			2950.889,
			2953.14,
			2956.532,
			2958.804,
			2962.23,
			2964.526,
			2965.678,
			2966.831,
			2970.305,
			2971.468,
			2972.632,
			2976.14,
			2978.489,
			2980.847,
			2982.029,
			2984.4,
			2986.78,
			2987.973,
			2990.367,
			2991.567,
			2993.975,
			2996.392,
			3000.032,
			3002.471,
			3006.141,
			3007.366,
			3008.594,
			3012.278,
			3014.742,
			3018.448,
			3020.931,
			3023.425,
			3024.675,
			3027.184,
			3028.442,
			3030.965,
			3034.77,
			3036.042,
			3039.873,
			3042.437,
			3045.009,
			3046.298,
			3048.882,
			3051.473,
			3052.772,
			3055.374,
			3056.675,
			3059.278,
			3061.888,
			3065.839,
			3068.469,
			3072.428,
			3073.752,
			3075.078,
			3079.067,
			3081.735,
			3085.73,
			3088.438,
			3091.13,
			3092.478,
			3095.155,
			3096.506,
			3099.236,
			3103.31,
			3106.034,
			3108.765,
			3112.876,
			3114.25,
			3115.627,
			3119.743,
			3121.127,
			3122.511,
			3125.311,
			3126.701,
			3129.491,
			3132.289,
			3136.481,
			3139.302,
			3143.57,
			3144.985,
			3146.401,
			3150.652,
			3152.069,
			3153.487,
			3157.738,
			3160.551,
			3164.823,
			3167.643,
			3169.048,
			3171.844,
			3174.594,
			3175.97,
			3178.698,
			3181.387,
			3185.345,
			3187.955,
			3191.737,
			3192.985,
			3194.223,
			3197.882,
			3200.274,
			3203.797,
			3206.105,
			3208.38,
			3209.505,
			3211.745,
			3212.842,
			3213.93,
			3217.145,
			3219.244,
			3222.336,
			3224.348,
			3226.364,
			3227.368,
			3229.374,
			3231.397,
			3232.402,
			3234.413,
			3235.419,
			3237.432,
			3239.446,
			3242.468,
			3244.484,
			3247.516,
			3248.535,
			3249.552,
			3252.622,
			3254.695,
			3257.813,
			3259.906,
			3261.992,
			3263.051,
			3265.163,
			3266.215,
			3267.268,
			3270.44,
			3271.493,
			3273.579,
			3275.65,
			3277.705,
			3279.721,
			3280.728,
			3282.736,
			3284.715,
			3285.704,
			3288.659,
			3290.613,
			3292.567,
			3295.473,
			3297.41,
			3300.297,
			3301.258,
			3302.219,
			3305.074,
			3306.975,
			3309.805,
			3311.69,
			3314.51,
			3316.386,
			3317.326,
			3319.206,
			3321.088,
			3322.031,
			3323.926,
			3325.831,
			3328.708,
			3329.677,
			3330.648,
			3333.576,
			3334.565,
			3335.557,
			3337.547,
			3338.545,
			3340.568,
			3342.601,
			3345.691,
			3347.771,
			3350.924,
			3351.983,
			3353.044,
			3356.217,
			3358.355,
			3361.56,
			3363.705,
			3365.849,
			3366.923,
			3369.079,
			3370.158,
			3372.32,
			3374.492,
			3375.581,
			3377.765,
			3379.958,
			3383.262,
			3384.362,
			3385.461,
			3388.73,
			3389.805,
			3390.875,
			3394.028,
			3396.085,
			3398.109,
			3399.107,
			3401.079,
			3403.989,
			3405.874,
			3406.804,
			3407.725,
			3411.321,
			3412.2,
			3414.786,
			3416.466,
			3418.919,
			3420.516,
			3422.091,
			3422.873,
			3424.429,
			3425.206,
			3426.763,
			3428.324,
			3430.677,
			3432.252,
			3434.622,
			3435.413,
			3436.205,
			3438.584,
			3440.172,
			3442.56,
			3444.155,
			3445.75,
			3446.55,
			3448.157,
			3448.959,
			3450.562,
			3452.17,
			3452.978,
			3453.784,
			3456.205,
			3457.824,
			3460.25,
			3461.863,
			3463.468,
			3464.27,
			3465.872,
			3466.672,
			3468.266,
			3469.853,
			3472.216,
			3473.784,
			3476.122,
			3476.899,
			3477.676,
			3479.996,
			3481.537,
			3483.838,
			3485.364,
			3486.889,
			3487.648,
			3489.165,
			3489.922,
			3491.438,
			3492.959,
			3493.72,
			3495.243,
			3496.766,
			3499.047,
			3499.806,
			3500.564,
			3502.075,
			3503.581,
			3504.33,
			3505.816,
			3506.553,
			3508.013,
			3509.456,
			3511.581,
			3512.981,
			3515.072,
			3515.764,
			3516.456,
			3518.539,
			3519.926,
			3521.99,
			3523.369,
			3525.432,
			3526.794,
			3528.154,
			3528.837,
			3530.193,
			3530.868,
			3532.21,
			3533.553,
			3535.562,
			3536.891,
			3538.884,
			3539.547,
			3540.209,
			3542.188,
			3543.508,
			3544.835,
			3546.82,
			3548.142,
			3548.808,
			3550.146,
			3550.813,
			3551.481,
			3553.489,
			3554.164,
			3554.84,
			3556.866,
			3558.221,
			3559.584,
			3560.267,
			3561.634,
			3563.004,
			3563.691,
			3565.056,
			3565.739,
			3567.105,
			3568.473,
			3570.524,
			3571.889,
			3573.938,
			3574.622,
			3575.307,
			3577.359,
			3578.728,
			3580.79,
			3582.166,
			3584.234,
			3585.622,
			3586.317,
			3587.013,
			3589.809,
			3590.51,
			3592.62,
			3594.035,
			3596.159,
			3597.58,
			3599.01,
			3599.729,
			3601.16,
			3601.878,
			3603.326,
			3604.789,
			3606.984,
			3608.475,
			3610.742,
			3611.507,
			3612.276,
			3614.605,
			3616.195,
			3618.624,
			3620.261,
			3621.947,
			3622.796,
			3624.507,
			3625.368,
			3627.102,
			3628.845,
			3629.715,
			3631.466,
			3633.198,
			3635.824,
			3636.702,
			3637.581,
			3639.349,
			3641.13,
			3642.028,
			3643.833,
			3644.74,
			3646.566,
			3649.342,
			3651.209,
			3653.103,
			3655.971,
			3656.936,
			3657.908,
			3660.842,
			3662.819,
			3665.812,
			3667.823,
			3669.843,
			3670.858,
			3672.89,
			3673.906,
			3675.946,
			3677.994,
			3679.02,
			3681.079,
			3683.149,
			3686.273,
			3687.32,
			3688.37,
			3691.535,
			3692.594,
			3693.655,
			3695.775,
			3696.837,
			3698.972,
			3702.203,
			3704.378,
			3706.568,
			3709.873,
			3710.979,
			3712.088,
			3715.414,
			3716.525,
			3717.639,
			3720.949,
			3723.176,
			3725.402,
			3726.518,
			3728.756,
			3731,
			3732.125,
			3734.384,
			3736.651,
			3737.789,
			3740.071,
			3741.216,
			3743.513,
			3745.821,
			3749.3,
			3751.632,
			3755.148,
			3756.324,
			3757.502,
			3761.05,
			3763.426,
			3767.006,
			3769.404,
			3771.81,
			3773.017,
			3775.436,
			3776.649,
			3777.864,
			3782.746,
			3783.972,
			3787.663,
			3790.134,
			3792.615,
			3793.858,
			3796.351,
			3798.852,
			3800.106,
			3803.881,
			3806.408,
			3807.913
		],
		[
			0,
			0.049,
			0.065,
			0.11,
			0.172,
			0.234,
			0.297,
			0.357,
			0.389,
			0.42,
			0.482,
			0.546,
			0.607,
			0.669,
			0.72,
			0.731,
			0.792,
			0.824,
			0.856,
			0.916,
			0.932,
			0.98,
			1.04,
			1.102,
			1.149,
			1.164,
			1.228,
			1.258,
			1.287,
			1.352,
			1.366,
			1.412,
			1.476,
			1.537,
			1.584,
			1.66,
			1.693,
			1.723,
			1.784,
			1.847,
			1.908,
			1.971,
			2.018,
			2.034,
			2.093,
			2.126,
			2.157,
			2.234,
			2.282,
			2.344,
			2.405,
			2.452,
			2.467,
			2.53,
			2.561,
			2.592,
			2.668,
			2.715,
			2.784,
			2.839,
			2.884,
			2.962,
			2.993,
			3.026,
			3.102,
			3.149,
			3.212,
			3.274,
			3.336,
			3.396,
			3.426,
			3.459,
			3.521,
			3.538,
			3.584,
			3.643,
			3.708,
			3.769,
			3.832,
			3.861,
			3.894,
			3.955,
			4.018,
			4.08,
			4.14,
			4.202,
			4.266,
			4.296,
			4.326,
			4.404,
			4.451,
			4.514,
			4.576,
			4.623,
			4.637,
			4.7,
			4.73,
			4.76,
			4.823,
			4.844,
			4.886,
			4.948,
			5.008,
			5.057,
			5.134,
			5.165,
			5.195,
			5.258,
			5.319,
			5.383,
			5.443,
			5.49,
			5.505,
			5.567,
			5.598,
			5.628,
			5.691,
			5.708,
			5.754,
			5.816,
			5.877,
			5.924,
			5.939,
			6.001,
			6.033,
			6.065,
			6.125,
			6.142,
			6.187,
			6.25,
			6.31,
			6.358,
			6.435,
			6.465,
			6.497,
			6.574,
			6.622,
			6.685,
			6.747,
			6.792,
			6.808,
			6.87,
			6.904,
			6.933,
			6.992,
			7.009,
			7.056,
			7.118,
			7.179,
			7.226,
			7.24,
			7.304,
			7.335,
			7.367,
			7.428,
			7.443,
			7.49,
			7.553,
			7.615,
			7.659,
			7.739,
			7.77,
			7.8,
			7.862,
			7.922,
			7.988,
			8.048,
			8.095,
			8.109,
			8.171,
			8.202,
			8.235,
			8.312,
			8.357,
			8.421,
			8.482,
			8.529,
			8.607,
			8.638,
			8.669,
			8.744,
			8.791,
			8.855,
			8.915,
			8.979,
			9.039,
			9.07,
			9.101,
			9.163,
			9.18,
			9.227,
			9.289,
			9.349,
			9.397,
			9.412,
			9.474,
			9.506,
			9.535,
			9.599,
			9.614,
			9.66,
			9.723,
			9.783,
			9.832,
			9.908,
			9.939,
			9.971,
			10.033,
			10.093,
			10.156,
			10.219,
			10.265,
			10.341,
			10.373,
			10.405,
			10.466,
			10.482,
			10.528,
			10.589,
			10.651,
			10.699,
			10.715,
			10.777,
			10.808,
			10.838,
			10.902,
			10.916,
			10.962,
			11.029,
			11.087,
			11.132,
			11.211,
			11.241,
			11.273,
			11.335,
			11.396,
			11.46,
			11.521,
			11.567,
			11.584,
			11.643,
			11.677,
			11.708,
			11.769,
			11.785,
			11.831,
			11.894,
			11.955,
			12.002,
			12.016,
			12.079,
			12.11,
			12.142,
			12.202,
			12.219,
			12.265,
			12.326,
			12.388,
			12.436,
			12.512,
			12.543,
			12.574,
			12.652,
			12.699,
			12.76,
			12.823,
			12.885,
			12.945,
			12.977,
			13.009,
			13.069,
			13.089,
			13.132,
			13.195,
			13.256,
			13.304,
			13.379,
			13.412,
			13.442,
			13.504,
			13.566,
			13.627,
			13.689,
			13.754,
			13.816,
			13.845,
			13.876,
			13.954,
			14.001,
			14.062,
			14.125,
			14.186,
			14.249,
			14.28,
			14.31,
			14.373,
			14.389,
			14.435,
			14.499,
			14.56,
			14.606,
			14.682,
			14.715,
			14.745,
			14.807,
			14.87,
			14.932,
			14.993,
			15.039,
			15.056,
			15.118,
			15.153,
			15.18,
			15.242,
			15.257,
			15.303,
			15.365,
			15.427,
			15.473,
			15.49,
			15.552,
			15.582,
			15.612,
			15.689,
			15.737,
			15.8,
			15.86,
			15.924,
			15.984,
			16.017,
			16.048,
			16.109,
			16.172,
			16.234,
			16.297,
			16.356,
			16.418,
			16.449,
			16.482,
			16.544,
			16.558,
			16.605,
			16.667,
			16.728,
			16.775,
			16.791,
			16.855,
			16.885,
			16.916,
			16.977,
			16.992,
			17.039,
			17.102,
			17.164,
			17.227,
			17.286,
			17.319,
			17.351,
			17.41,
			17.475,
			17.535,
			17.597,
			17.66,
			17.722,
			17.753,
			17.783,
			17.845,
			17.862,
			17.906,
			17.971,
			18.033,
			18.079,
			18.154,
			18.187,
			18.217,
			18.278,
			18.295,
			18.34,
			18.403,
			18.465,
			18.511,
			18.59,
			18.621,
			18.652,
			18.712,
			18.73,
			18.776,
			18.838,
			18.899,
			18.944,
			18.962,
			19.023,
			19.054,
			19.087,
			19.148,
			19.162,
			19.21,
			19.275,
			19.334,
			19.378,
			19.394,
			19.458,
			19.489,
			19.52,
			19.582,
			19.644,
			19.708,
			19.769,
			19.815,
			19.892,
			19.923,
			19.954,
			20.032,
			20.079,
			20.14,
			20.201,
			20.248,
			20.265,
			20.325,
			20.356,
			20.389,
			20.465,
			20.512,
			20.576,
			20.637,
			20.699,
			20.76,
			20.79,
			20.823,
			20.899,
			20.947,
			21.008,
			21.069,
			21.118,
			21.133,
			21.194,
			21.226,
			21.256,
			21.319,
			21.338,
			21.379,
			21.443,
			21.505,
			21.551,
			21.566,
			21.626,
			21.659,
			21.689,
			21.751,
			21.768,
			21.814,
			21.877,
			21.937,
			21.987,
			22.062,
			22.093,
			22.125,
			22.185,
			22.249,
			22.311,
			22.373,
			22.419,
			22.435,
			22.497,
			22.529,
			22.559,
			22.637,
			22.683,
			22.744,
			22.807,
			22.852,
			22.869,
			22.932,
			22.96,
			22.994,
			23.055,
			23.117,
			23.178,
			23.241,
			23.287,
			23.365,
			23.401,
			23.426,
			23.489,
			23.504,
			23.551,
			23.613,
			23.676,
			23.721,
			23.736,
			23.798,
			23.83,
			23.86,
			23.923,
			23.937,
			23.985,
			24.047,
			24.108,
			24.171,
			24.231,
			24.265,
			24.294,
			24.355,
			24.417,
			24.48,
			24.543,
			24.59,
			24.606,
			24.666,
			24.698,
			24.73,
			24.791,
			24.806,
			24.852,
			24.916,
			24.976,
			25.022,
			25.039,
			25.099,
			25.133,
			25.164,
			25.223,
			25.241,
			25.286,
			25.349,
			25.412,
			25.462,
			25.536,
			25.567,
			25.596,
			25.659,
			25.722,
			25.782,
			25.846,
			25.892,
			25.906,
			25.969,
			26.001,
			26.032,
			26.093,
			26.108,
			26.156,
			26.218,
			26.28,
			26.325,
			26.341,
			26.403,
			26.434,
			26.466,
			26.526,
			26.543,
			26.59,
			26.651,
			26.712,
			26.76,
			26.838,
			26.867,
			26.9,
			26.96,
			27.023,
			27.086,
			27.145,
			27.209,
			27.272,
			27.304,
			27.334,
			27.393,
			27.41,
			27.458,
			27.523,
			27.581,
			27.643,
			27.704,
			27.736,
			27.768,
			27.827,
			27.844,
			27.892,
			27.953,
			28.016,
			28.062,
			28.078,
			28.139,
			28.172,
			28.201,
			28.262,
			28.325,
			28.388,
			28.449,
			28.496,
			28.51,
			28.575,
			28.605,
			28.635,
			28.699,
			28.712,
			28.76,
			28.821,
			28.885,
			28.929,
			29.009,
			29.038,
			29.068,
			29.133,
			29.195,
			29.255,
			29.317,
			29.365,
			29.379,
			29.441,
			29.473,
			29.504,
			29.587,
			29.626,
			29.691,
			29.751,
			29.798,
			29.815,
			29.876,
			29.906,
			29.938,
			29.999,
			30.015,
			30.061,
			30.128,
			30.187,
			30.247,
			30.308,
			30.34,
			30.371,
			30.434,
			30.497,
			30.559,
			30.621,
			30.667,
			30.682,
			30.743,
			30.774,
			30.805,
			30.868,
			30.885,
			30.928,
			30.993,
			31.053,
			31.1,
			31.116,
			31.179,
			31.208,
			31.24,
			31.303,
			31.318,
			31.364,
			31.427,
			31.488,
			31.534,
			31.611,
			31.649,
			31.674,
			31.735,
			31.798,
			31.861,
			31.923,
			31.968,
			31.984,
			32.047,
			32.076,
			32.107,
			32.171,
			32.185,
			32.233,
			32.295,
			32.357,
			32.402,
			32.418,
			32.48,
			32.511,
			32.542,
			32.619,
			32.665,
			32.727,
			32.789,
			32.852,
			32.914,
			32.944,
			32.976,
			33.038,
			33.054,
			33.1,
			33.163,
			33.225,
			33.271,
			33.285,
			33.349,
			33.381,
			33.409,
			33.473,
			33.487,
			33.535,
			33.595,
			33.657,
			33.719,
			33.782,
			33.812,
			33.844,
			33.906,
			33.969,
			34.031,
			34.093,
			34.153,
			34.217,
			34.248,
			34.278,
			34.34,
			34.357,
			34.403,
			34.464,
			34.526,
			34.573,
			34.587,
			34.649,
			34.682,
			34.712,
			34.774,
			34.791,
			34.838,
			34.899,
			34.96,
			35.023,
			35.084,
			35.117,
			35.145,
			35.21,
			35.271,
			35.334,
			35.394,
			35.442,
			35.456,
			35.518,
			35.549,
			35.581,
			35.642,
			35.659,
			35.705,
			35.771,
			35.827,
			35.876,
			35.89,
			35.954,
			35.983,
			36.015,
			36.077,
			36.092,
			36.138,
			36.201,
			36.261,
			36.309,
			36.388,
			36.418,
			36.449,
			36.511,
			36.574,
			36.636,
			36.696,
			36.743,
			36.76,
			36.82,
			36.851,
			36.883,
			36.944,
			36.96,
			37.007,
			37.069,
			37.13,
			37.177,
			37.193,
			37.254,
			37.288,
			37.316,
			37.378,
			37.394,
			37.441,
			37.502,
			37.566,
			37.612,
			37.69,
			37.719,
			37.752,
			37.812,
			37.834,
			37.876,
			37.937,
			37.999,
			38.044,
			38.061,
			38.124,
			38.154,
			38.185,
			38.264,
			38.309,
			38.37,
			38.432,
			38.479,
			38.556,
			38.587,
			38.62,
			38.681,
			38.742,
			38.805,
			38.867,
			38.915,
			38.991,
			39.023,
			39.054,
			39.132,
			39.178,
			39.239,
			39.301,
			39.348,
			39.361,
			39.426,
			39.456,
			39.487,
			39.565,
			39.61,
			39.674,
			39.734,
			39.783,
			39.859,
			39.894,
			39.921,
			39.983,
			40.046,
			40.106,
			40.169,
			40.217,
			40.232,
			40.295,
			40.325,
			40.356,
			40.432,
			40.479,
			40.54,
			40.605,
			40.667,
			40.727,
			40.76,
			40.79,
			40.851,
			40.866,
			40.914,
			40.977,
			41.039,
			41.099,
			41.161,
			41.191,
			41.223,
			41.286,
			41.348,
			41.409,
			41.471,
			41.519,
			41.534,
			41.596,
			41.626,
			41.656,
			41.735,
			41.782,
			41.845,
			41.905,
			41.955,
			42.03,
			42.061,
			42.091,
			42.168,
			42.216,
			42.276,
			42.34,
			42.401,
			42.463,
			42.493,
			42.526,
			42.589,
			42.604,
			42.65,
			42.713,
			42.774,
			42.819,
			42.837,
			42.898,
			42.928,
			42.959,
			43.022,
			43.039,
			43.085,
			43.146,
			43.207,
			43.256,
			43.331,
			43.362,
			43.395,
			43.456,
			43.519,
			43.58,
			43.643,
			43.689,
			43.705,
			43.767,
			43.796,
			43.828,
			43.892,
			43.907,
			43.952,
			44.021,
			44.076,
			44.139,
			44.201,
			44.232,
			44.26,
			44.34,
			44.386,
			44.449,
			44.51,
			44.573,
			44.634,
			44.666,
			44.695,
			44.759,
			44.819,
			44.882,
			44.943,
			45.006,
			45.069,
			45.098,
			45.131,
			45.192,
			45.208,
			45.255,
			45.318,
			45.379,
			45.441,
			45.504,
			45.534,
			45.564,
			45.627,
			45.641,
			45.689,
			45.75,
			45.813,
			45.86,
			45.937,
			45.968,
			46,
			46.06,
			46.082,
			46.123,
			46.185,
			46.246,
			46.293,
			46.307,
			46.371,
			46.401,
			46.432,
			46.495,
			46.511,
			46.556,
			46.619,
			46.682,
			46.728,
			46.742,
			46.805,
			46.836,
			46.866,
			46.93,
			46.991,
			47.053,
			47.115,
			47.175,
			47.239,
			47.27,
			47.3,
			47.362,
			47.379,
			47.425,
			47.488,
			47.549,
			47.593,
			47.612,
			47.674,
			47.704,
			47.736,
			47.797,
			47.81,
			47.86,
			47.923,
			47.981,
			48.029,
			48.108,
			48.142,
			48.169,
			48.246,
			48.293,
			48.355,
			48.417,
			48.462,
			48.54,
			48.571,
			48.604,
			48.665,
			48.681,
			48.726,
			48.788,
			48.852,
			48.899,
			48.912,
			48.976,
			49.006,
			49.037,
			49.099,
			49.115,
			49.16,
			49.223,
			49.286,
			49.332,
			49.408,
			49.44,
			49.471,
			49.533,
			49.595,
			49.657,
			49.72,
			49.765,
			49.781,
			49.843,
			49.875,
			49.905,
			49.982,
			50.028,
			50.093,
			50.154,
			50.216,
			50.277,
			50.309,
			50.339,
			50.401,
			50.418,
			50.462,
			50.526,
			50.588,
			50.65,
			50.71,
			50.743,
			50.773,
			50.837,
			50.898,
			50.959,
			51.022,
			51.068,
			51.084,
			51.146,
			51.176,
			51.208,
			51.269,
			51.286,
			51.333,
			51.395,
			51.455,
			51.502,
			51.518,
			51.578,
			51.612,
			51.64,
			51.705,
			51.766,
			51.828,
			51.889,
			51.937,
			52.015,
			52.044,
			52.077,
			52.137,
			52.2,
			52.268,
			52.324,
			52.369,
			52.385,
			52.448,
			52.48,
			52.51,
			52.574,
			52.588,
			52.634,
			52.694,
			52.757,
			52.818,
			52.882,
			52.911,
			52.943,
			53.007,
			53.067,
			53.134,
			53.191,
			53.239,
			53.317,
			53.345,
			53.379,
			53.439,
			53.454,
			53.503,
			53.565,
			53.625,
			53.687,
			53.751,
			53.783,
			53.812,
			53.873,
			53.891,
			53.937,
			53.999,
			54.06,
			54.107,
			54.184,
			54.215,
			54.245,
			54.309,
			54.371,
			54.433,
			54.495,
			54.54,
			54.557,
			54.618,
			54.649,
			54.68,
			54.758,
			54.805,
			54.866,
			54.929,
			54.973,
			54.991,
			55.051,
			55.082,
			55.112,
			55.19,
			55.238,
			55.301,
			55.36,
			55.41,
			55.487,
			55.516,
			55.549,
			55.611,
			55.673,
			55.734,
			55.797,
			55.841,
			55.858,
			55.919,
			55.952,
			55.983,
			56.045,
			56.059,
			56.106,
			56.168,
			56.229,
			56.277,
			56.291,
			56.353,
			56.39,
			56.416,
			56.476,
			56.494,
			56.539,
			56.603,
			56.665,
			56.71,
			56.79,
			56.82,
			56.851,
			56.914,
			56.929,
			56.975,
			57.038,
			57.099,
			57.146,
			57.16,
			57.222,
			57.253,
			57.283,
			57.348,
			57.362,
			57.409,
			57.471,
			57.533,
			57.579,
			57.658,
			57.688,
			57.717,
			57.797,
			57.843,
			57.903,
			57.966,
			58.011,
			58.091,
			58.122,
			58.153,
			58.229,
			58.278,
			58.34,
			58.399,
			58.451,
			58.464,
			58.525,
			58.557,
			58.587,
			58.649,
			58.664,
			58.711,
			58.772,
			58.834,
			58.882,
			58.96,
			58.99,
			59.02,
			59.099,
			59.143,
			59.207,
			59.269,
			59.316,
			59.331,
			59.392,
			59.422,
			59.454,
			59.517,
			59.532,
			59.579,
			59.641,
			59.703,
			59.749,
			59.766,
			59.827,
			59.857,
			59.89,
			59.967,
			60.012,
			60.076,
			60.137,
			60.185,
			60.26,
			60.293,
			60.324,
			60.385,
			60.447,
			60.508,
			60.57,
			60.634,
			60.695,
			60.727,
			60.756,
			60.818,
			60.835,
			60.881,
			60.943,
			61.006,
			61.068,
			61.128,
			61.16,
			61.191,
			61.254,
			61.315,
			61.377,
			61.438,
			61.487,
			61.563,
			61.595,
			61.625,
			61.686,
			61.704,
			61.748,
			61.812,
			61.874,
			61.92,
			61.934,
			61.998,
			62.029,
			62.06,
			62.121,
			62.135,
			62.185,
			62.247,
			62.308,
			62.354,
			62.432,
			62.462,
			62.493,
			62.555,
			62.618,
			62.679,
			62.74,
			62.787,
			62.866,
			62.897,
			62.927,
			62.989,
			63.006,
			63.051,
			63.114,
			63.175,
			63.221,
			63.238,
			63.301,
			63.332,
			63.36,
			63.423,
			63.44,
			63.485,
			63.549,
			63.609,
			63.656,
			63.735,
			63.766,
			63.797,
			63.873,
			63.921,
			63.982,
			64.044,
			64.09,
			64.107,
			64.169,
			64.199,
			64.228,
			64.292,
			64.306,
			64.354,
			64.415,
			64.479,
			64.524,
			64.539,
			64.603,
			64.639,
			64.662,
			64.725,
			64.741,
			64.788,
			64.848,
			64.91,
			64.959,
			65.036,
			65.066,
			65.095,
			65.159,
			65.223,
			65.285,
			65.346,
			65.392,
			65.409,
			65.47,
			65.502,
			65.532,
			65.609,
			65.656,
			65.719,
			65.78,
			65.827,
			65.842,
			65.903,
			65.934,
			65.967,
			66.043,
			66.09,
			66.151,
			66.212,
			66.275,
			66.337,
			66.369,
			66.399,
			66.463,
			66.479,
			66.525,
			66.585,
			66.649,
			66.709,
			66.773,
			66.804,
			66.835,
			66.896,
			66.912,
			66.959,
			67.024,
			67.082,
			67.128,
			67.145,
			67.207,
			67.238,
			67.268,
			67.347,
			67.391,
			67.455,
			67.516,
			67.579,
			67.639,
			67.671,
			67.702,
			67.765,
			67.781,
			67.825,
			67.889,
			67.951,
			67.997,
			68.011,
			68.074,
			68.105,
			68.135,
			68.199,
			68.215,
			68.259,
			68.324,
			68.385,
			68.447,
			68.509,
			68.54,
			68.571,
			68.649,
			68.694,
			68.76,
			68.82,
			68.865,
			68.881,
			68.944,
			68.974,
			69.005,
			69.082,
			69.127,
			69.19,
			69.252,
			69.299,
			69.316,
			69.377,
			69.407,
			69.437,
			69.501,
			69.516,
			69.562,
			69.624,
			69.686,
			69.732,
			69.809,
			69.843,
			69.872,
			69.935,
			69.998,
			70.057,
			70.122,
			70.167,
			70.183,
			70.243,
			70.276,
			70.306,
			70.385,
			70.43,
			70.493,
			70.555,
			70.601,
			70.616,
			70.678,
			70.708,
			70.741,
			70.803,
			70.823,
			70.865,
			70.927,
			70.988,
			71.037,
			71.112,
			71.145,
			71.176,
			71.237,
			71.254,
			71.299,
			71.36,
			71.424,
			71.486,
			71.548,
			71.578,
			71.608,
			71.671,
			71.687,
			71.734,
			71.795,
			71.856,
			71.918,
			71.982,
			72.012,
			72.042,
			72.106,
			72.168,
			72.23,
			72.29,
			72.338,
			72.354,
			72.416,
			72.448,
			72.477,
			72.54,
			72.6,
			72.662,
			72.726,
			72.772,
			72.786,
			72.85,
			72.884,
			72.91,
			72.974,
			72.988,
			73.036,
			73.098,
			73.16,
			73.206,
			73.282,
			73.314,
			73.344,
			73.408,
			73.468,
			73.532,
			73.593,
			73.64,
			73.656,
			73.719,
			73.75,
			73.778,
			73.843,
			73.857,
			73.905,
			73.966,
			74.026,
			74.073,
			74.091,
			74.152,
			74.183,
			74.212,
			74.291,
			74.339,
			74.4,
			74.461,
			74.508,
			74.587,
			74.616,
			74.649,
			74.711,
			74.772,
			74.835,
			74.895,
			74.958,
			75.019,
			75.052,
			75.081,
			75.143,
			75.16,
			75.206,
			75.266,
			75.329,
			75.377,
			75.391,
			75.454,
			75.483,
			75.516,
			75.576,
			75.594,
			75.639,
			75.702,
			75.763,
			75.81,
			75.887,
			75.92,
			75.95,
			76.012,
			76.072,
			76.134,
			76.197,
			76.244,
			76.258,
			76.322,
			76.353,
			76.384,
			76.445,
			76.462,
			76.509,
			76.57,
			76.633,
			76.677,
			76.695,
			76.757,
			76.788,
			76.818,
			76.879,
			76.943,
			77.003,
			77.009,
			77.066,
			77.129,
			77.189,
			77.222,
			77.252,
			77.329,
			77.377,
			77.439,
			77.499,
			77.562,
			77.623,
			77.654,
			77.686,
			77.748,
			77.764,
			77.81,
			77.874,
			77.934,
			77.981,
			78.057,
			78.089,
			78.121,
			78.182,
			78.245,
			78.307,
			78.368,
			78.416,
			78.43,
			78.492,
			78.523,
			78.554,
			78.616,
			78.632,
			78.678,
			78.74,
			78.802,
			78.865,
			78.925,
			78.957,
			78.988,
			79.049,
			79.071,
			79.114,
			79.176,
			79.237,
			79.284,
			79.359,
			79.391,
			79.422,
			79.486,
			79.545,
			79.608,
			79.671,
			79.717,
			79.733,
			79.794,
			79.825,
			79.858,
			79.919,
			79.934,
			79.982,
			80.044,
			80.105,
			80.15,
			80.168,
			80.228,
			80.26,
			80.291,
			80.352,
			80.368,
			80.415,
			80.477,
			80.54,
			80.6,
			80.664,
			80.694,
			80.723,
			80.787,
			80.849,
			80.912,
			80.973,
			81.035,
			81.097,
			81.132,
			81.159,
			81.22,
			81.236,
			81.282,
			81.345,
			81.405,
			81.454,
			81.53,
			81.56,
			81.593,
			81.655,
			81.717,
			81.777,
			81.84,
			81.886,
			81.904,
			81.965,
			81.995,
			82.027,
			82.088,
			82.105,
			82.151,
			82.212,
			82.274,
			82.321,
			82.336,
			82.399,
			82.431,
			82.461,
			82.522,
			82.54,
			82.584,
			82.647,
			82.71,
			82.755,
			82.833,
			82.864,
			82.896,
			82.957,
			83.02,
			83.082,
			83.143,
			83.205,
			83.268,
			83.298,
			83.328,
			83.406,
			83.452,
			83.515,
			83.576,
			83.638,
			83.702,
			83.732,
			83.762,
			83.825,
			83.841,
			83.887,
			83.949,
			84.01,
			84.056,
			84.134,
			84.165,
			84.197,
			84.259,
			84.322,
			84.382,
			84.444,
			84.491,
			84.506,
			84.568,
			84.601,
			84.631,
			84.693,
			84.709,
			84.756,
			84.816,
			84.881,
			84.926,
			84.94,
			85.003,
			85.034,
			85.066,
			85.128,
			85.142,
			85.19,
			85.256,
			85.314,
			85.359,
			85.437,
			85.469,
			85.5,
			85.562,
			85.623,
			85.685,
			85.746,
			85.794,
			85.809,
			85.871,
			85.902,
			85.934,
			85.997,
			86.011,
			86.059,
			86.12,
			86.182,
			86.226,
			86.242,
			86.306,
			86.338,
			86.368,
			86.428,
			86.445,
			86.49,
			86.554,
			86.616,
			86.661,
			86.74,
			86.771,
			86.801,
			86.863,
			86.88,
			86.925,
			86.987,
			87.05,
			87.097,
			87.112,
			87.173,
			87.203,
			87.236,
			87.297,
			87.33,
			87.36,
			87.421,
			87.439,
			87.484,
			87.547,
			87.608,
			87.655,
			87.732,
			87.762,
			87.792,
			87.871,
			87.919,
			87.979,
			88.042,
			88.09,
			88.104,
			88.165,
			88.197,
			88.227,
			88.306,
			88.352,
			88.415,
			88.475,
			88.522,
			88.537,
			88.601,
			88.632,
			88.663,
			88.738,
			88.785,
			88.823
		]
	],
	'1673_1674_7982': [
		[
			86.992
		],
		[
			0
		],
		[
			0,
			0.29,
			1.575,
			4.15,
			6.733,
			10.623,
			13.227,
			17.139,
			18.444,
			19.751,
			23.681,
			26.311,
			30.269,
			32.916,
			36.895,
			39.555,
			40.887,
			43.557,
			46.236,
			47.578,
			50.268,
			52.964,
			57.021,
			58.377,
			59.735,
			63.817,
			65.181,
			66.546,
			70.652,
			73.399,
			76.153,
			80.294,
			83.062,
			87.226,
			88.617,
			90.01,
			94.198,
			96.998,
			101.212,
			104.029,
			106.852,
			108.266,
			111.097,
			112.509,
			115.338,
			119.586,
			122.432,
			125.29,
			129.603,
			131.045,
			132.489,
			136.828,
			138.274,
			139.721,
			144.07,
			146.974,
			151.342,
			154.261,
			157.185,
			158.65,
			161.584,
			163.053,
			164.524,
			170.421,
			171.899,
			176.339,
			179.301,
			182.263,
			183.743,
			186.697,
			189.645,
			191.116,
			195.493,
			198.373,
			201.203,
			205.361,
			208.071,
			212.054,
			213.361,
			214.661,
			218.518,
			221.047,
			224.776,
			227.217,
			229.623,
			230.812,
			233.168,
			234.334,
			236.639,
			238.93,
			240.057,
			241.177,
			244.51,
			246.708,
			248.891,
			249.98,
			252.145,
			254.302,
			255.38,
			258.628,
			260.793,
			262.939,
			266.154,
			268.296,
			271.508,
			272.589,
			273.671,
			276.894,
			278.975,
			282.171,
			284.343,
			286.526,
			287.619,
			289.801,
			290.89,
			293.066,
			295.234,
			296.304,
			298.442,
			300.601,
			303.868,
			304.954,
			306.027,
			309.238,
			310.307,
			311.375,
			314.572,
			316.703,
			318.83,
			322.016,
			324.136,
			327.318,
			328.379,
			329.439,
			332.638,
			334.782,
			338.029,
			340.209,
			342.402,
			343.507,
			345.719,
			346.827,
			349.042,
			351.285,
			352.409,
			354.676,
			356.935,
			360.317,
			362.581,
			366.003,
			367.148,
			368.302,
			371.792,
			374.128,
			376.471,
			379.978,
			382.308,
			385.84,
			387.025,
			388.213,
			391.767,
			394.144,
			397.667,
			400.019,
			402.371,
			403.55,
			405.907,
			408.268,
			409.451,
			411.817,
			413.003,
			415.378,
			417.757,
			421.343,
			422.543,
			423.744,
			427.377,
			428.594,
			429.813,
			433.501,
			435.973,
			439.705,
			442.205,
			444.713,
			445.969,
			448.489,
			449.752,
			452.281,
			454.796,
			456.066,
			458.606,
			461.153,
			463.703,
			466.259,
			467.535,
			470.084,
			472.636,
			473.905,
			476.45,
			477.728,
			480.299,
			482.875,
			486.762,
			489.369,
			493.3,
			494.617,
			495.936,
			499.903,
			502.556,
			506.539,
			509.199,
			511.865,
			513.2,
			515.877,
			517.218,
			519.905,
			523.949,
			526.651,
			529.358,
			533.427,
			534.788,
			536.15,
			540.247,
			541.617,
			542.988,
			547.107,
			549.86,
			552.618,
			556.765,
			559.537,
			563.709,
			565.103,
			566.498,
			570.693,
			573.498,
			577.717,
			580.538,
			583.365,
			584.781,
			587.618,
			589.039,
			591.886,
			594.739,
			596.168,
			599.031,
			601.9,
			606.214,
			607.655,
			609.098,
			613.435,
			614.883,
			616.333,
			620.691,
			623.603,
			627.983,
			630.909,
			633.842,
			638.252,
			639.725,
			641.2,
			645.634,
			647.115,
			648.597,
			653.055,
			656.035,
			659.021,
			660.516,
			663.511,
			666.51,
			668.011,
			671.018,
			672.523,
			675.536,
			678.553,
			683.088,
			686.118,
			690.671,
			692.192,
			693.713,
			698.285,
			701.339,
			705.928,
			708.994,
			712.065,
			713.604,
			716.688,
			718.233,
			719.784,
			724.467,
			726.023,
			729.107,
			732.205,
			735.298,
			739.932,
			743.023,
			746.12,
			747.67,
			750.772,
			752.325,
			755.433,
			758.545,
			763.222,
			766.345,
			771.036,
			772.602,
			774.169,
			778.877,
			782.021,
			786.744,
			789.898,
			793.055,
			794.636,
			797.8,
			799.384,
			802.555,
			807.318,
			810.498,
			813.681,
			816.857,
			820.018,
			821.59,
			824.707,
			827.786,
			829.311,
			832.332,
			833.83,
			836.795,
			839.722,
			844.033,
			846.856,
			851.009,
			852.373,
			853.726,
			857.726,
			860.341,
			864.19,
			866.707,
			870.41,
			872.83,
			874.026,
			876.392,
			878.723,
			879.875,
			882.151,
			884.389,
			887.679,
			889.825,
			892.977,
			894.009,
			895.03,
			898.033,
			899.987,
			901.9,
			904.696,
			906.512,
			909.172,
			910.041,
			910.899,
			913.44,
			914.266,
			915.083,
			917.479,
			919.032,
			920.548,
			921.288,
			922.744,
			924.167,
			924.867,
			926.924,
			928.262,
			929.58,
			931.542,
			932.837,
			934.765,
			935.406,
			936.052,
			937.996,
			939.295,
			941.263,
			942.595,
			944.605,
			945.96,
			946.659,
			947.359,
			949.473,
			950.182,
			950.895,
			953.076,
			954.539,
			956.007,
			956.745,
			958.224,
			959.706,
			960.449,
			961.937,
			962.681,
			964.171,
			965.661,
			967.953,
			969.494,
			971.811,
			972.604,
			973.396,
			975.765,
			977.309,
			979.529,
			981,
			982.452,
			983.166,
			984.586,
			985.293,
			986.701,
			988.859,
			990.293,
			991.719,
			993.838,
			995.239,
			996.634,
			998.033,
			998.735,
			1000.832,
			1002.228,
			1003.662,
			1005.82,
			1007.261,
			1009.441,
			1010.169,
			1010.898,
			1013.09,
			1014.552,
			1016.75,
			1018.227,
			1019.723,
			1020.47,
			1021.964,
			1022.713,
			1024.201,
			1025.682,
			1026.421,
			1027.899,
			1029.394,
			1031.637,
			1033.129,
			1035.373,
			1036.12,
			1036.866,
			1039.097,
			1040.551,
			1042.729,
			1044.184,
			1045.634,
			1047.813,
			1048.542,
			1049.272,
			1052.237,
			1052.982,
			1055.233,
			1056.786,
			1058.346,
			1059.13,
			1060.705,
			1062.292,
			1063.089,
			1064.692,
			1065.498,
			1067.108,
			1068.729,
			1071.175,
			1072.785,
			1075.227,
			1076.043,
			1076.849,
			1079.289,
			1080.935,
			1083.421,
			1085.097,
			1087.632,
			1089.337,
			1090.194,
			1091.051,
			1093.59,
			1094.44,
			1095.294,
			1097.856,
			1099.569,
			1101.297,
			1102.165,
			1103.909,
			1105.667,
			1106.551,
			1108.34,
			1109.241,
			1111.053,
			1112.881,
			1115.645,
			1117.513,
			1120.344,
			1121.293,
			1122.246,
			1125.125,
			1127.061,
			1129.99,
			1131.959,
			1134.936,
			1136.937,
			1137.942,
			1139.961,
			1141.993,
			1143.013,
			1145.062,
			1147.122,
			1149.194,
			1151.276,
			1152.322,
			1155.465,
			1156.509,
			1157.554,
			1159.648,
			1160.696,
			1162.798,
			1164.911,
			1168.106,
			1170.25,
			1173.495,
			1174.582,
			1175.672,
			1180.066,
			1181.172,
			1184.504,
			1186.742,
			1188.992,
			1190.121,
			1192.391,
			1193.53,
			1195.816,
			1198.113,
			1199.265,
			1201.577,
			1203.895,
			1207.388,
			1208.555,
			1209.724,
			1213.244,
			1214.42,
			1215.599,
			1219.151,
			1221.531,
			1225.123,
			1227.531,
			1229.954,
			1233.61,
			1234.834,
			1236.062,
			1239.76,
			1242.237,
			1245.971,
			1248.471,
			1250.98,
			1252.238,
			1254.76,
			1257.291,
			1258.56,
			1262.38,
			1264.938,
			1267.504,
			1271.369,
			1273.957,
			1277.855,
			1279.158,
			1280.463,
			1284.378,
			1286.996,
			1290.937,
			1293.574,
			1296.219,
			1300.2,
			1301.531,
			1302.863,
			1306.872,
			1308.213,
			1309.555,
			1313.592,
			1316.293,
			1319.002,
			1320.359,
			1323.078,
			1325.805,
			1327.172,
			1331.283,
			1334.033,
			1336.79,
			1340.94,
			1343.716,
			1347.893,
			1349.288,
			1350.686,
			1354.888,
			1357.698,
			1361.925,
			1364.749,
			1367.58,
			1368.998,
			1371.839,
			1373.262,
			1376.113,
			1378.971,
			1380.403,
			1383.272,
			1386.147,
			1389.03,
			1393.37,
			1396.273,
			1399.184,
			1400.642,
			1403.565,
			1405.028,
			1407.961,
			1410.9,
			1415.323,
			1418.281,
			1422.73,
			1424.217,
			1425.706,
			1430.182,
			1433.174,
			1437.675,
			1440.684,
			1445.21,
			1448.236,
			1449.751,
			1452.786,
			1455.829,
			1457.353,
			1460.405,
			1463.463,
			1468.062,
			1471.136,
			1475.759,
			1477.302,
			1478.848,
			1483.492,
			1486.596,
			1491.264,
			1494.384,
			1497.509,
			1502.208,
			1503.778,
			1505.35,
			1510.079,
			1511.659,
			1513.241,
			1517.996,
			1521.173,
			1524.354,
			1525.947,
			1529.137,
			1532.329,
			1533.927,
			1538.731,
			1541.945,
			1545.164,
			1550.005,
			1553.266,
			1558.115,
			1559.745,
			1561.377,
			1566.281,
			1569.556,
			1574.468,
			1577.742,
			1582.656,
			1585.934,
			1587.574,
			1589.215,
			1595.801,
			1597.452,
			1602.424,
			1605.748,
			1609.078,
			1610.746,
			1614.087,
			1617.434,
			1619.11,
			1622.459,
			1624.135,
			1627.489,
			1630.849,
			1635.912,
			1639.297,
			1644.374,
			1646.067,
			1647.758,
			1652.828,
			1654.517,
			1656.206,
			1661.277,
			1664.661,
			1669.746,
			1673.141,
			1674.841,
			1678.246,
			1681.657,
			1683.365,
			1686.785,
			1690.21,
			1695.351,
			1697.065,
			1698.78,
			1703.93,
			1705.649,
			1707.369,
			1712.535,
			1715.985,
			1719.439,
			1724.628,
			1728.093,
			1733.298,
			1735.035,
			1736.773,
			1741.992,
			1745.476,
			1750.708,
			1754.201,
			1759.448,
			1762.951,
			1764.704,
			1768.213,
			1771.726,
			1773.483,
			1777.001,
			1780.523,
			1785.813,
			1787.579,
			1789.345,
			1794.65,
			1796.42,
			1798.192,
			1801.737,
			1803.51,
			1807.061,
			1810.615,
			1815.954,
			1819.517,
			1824.868,
			1826.654,
			1828.441,
			1833.806,
			1835.595,
			1837.386,
			1842.764,
			1846.354,
			1849.947,
			1851.745,
			1855.344,
			1857.145,
			1860.749,
			1864.356,
			1866.162,
			1869.777,
			1873.397,
			1878.834,
			1880.648,
			1882.463,
			1887.913,
			1889.732,
			1891.552,
			1897.021,
			1900.67,
			1906.118,
			1909.718,
			1913.285,
			1915.053,
			1918.562,
			1920.301,
			1923.75,
			1927.161,
			1928.851,
			1930.532,
			1935.515,
			1938.789,
			1943.626,
			1946.804,
			1949.942,
			1951.494,
			1954.569,
			1956.09,
			1959.104,
			1962.087,
			1966.498,
			1969.394,
			1973.673,
			1975.08,
			1976.479,
			1980.617,
			1983.329,
			1987.326,
			1989.943,
			1992.524,
			1993.8,
			1996.325,
			1997.572,
			2000.038,
			2002.466,
			2003.666,
			2006.04,
			2008.383,
			2011.835,
			2014.093,
			2016.315,
			2018.503,
			2019.584,
			2022.775,
			2024.881,
			2026.935,
			2029.953,
			2031.907,
			2034.816,
			2035.763,
			2036.702,
			2039.468,
			2041.273,
			2043.931,
			2045.671,
			2047.383,
			2048.229,
			2049.918,
			2050.755,
			2052.414,
			2054.886,
			2056.526,
			2058.159,
			2060.632,
			2061.459,
			2062.287,
			2064.798,
			2065.652,
			2066.506,
			2069.076,
			2070.849,
			2072.633,
			2075.32,
			2077.115,
			2079.817,
			2080.718,
			2081.619,
			2084.323,
			2086.141,
			2088.875,
			2090.696,
			2092.504,
			2093.407,
			2095.214,
			2096.095,
			2097.837,
			2099.577,
			2100.447,
			2102.19,
			2103.934,
			2106.551,
			2108.294,
			2110.915,
			2111.774,
			2112.631,
			2115.208,
			2116.916,
			2119.458,
			2121.158,
			2122.851,
			2123.697,
			2125.392,
			2126.242,
			2127.097,
			2130.548,
			2131.415,
			2134.059,
			2135.835,
			2137.62,
			2138.515,
			2140.309,
			2142.113,
			2143.019,
			2144.838,
			2145.752,
			2147.587,
			2149.456,
			2152.284,
			2154.196,
			2157.094,
			2158.064,
			2159.034,
			2161.925,
			2163.851,
			2166.685,
			2168.533,
			2170.34,
			2171.23,
			2172.981,
			2173.843,
			2175.542,
			2178.022,
			2179.646,
			2181.231,
			2183.538,
			2184.29,
			2185.043,
			2187.257,
			2187.981,
			2188.699,
			2190.834,
			2192.242,
			2193.635,
			2195.713,
			2197.1,
			2199.168,
			2199.856,
			2200.544,
			2202.615,
			2203.992,
			2206.059,
			2207.461,
			2208.862,
			2209.563,
			2210.967,
			2211.696,
			2213.17,
			2215.387,
			2216.898,
			2218.447,
			2220.779,
			2221.567,
			2222.368,
			2224.775,
			2225.579,
			2226.386,
			2228.069,
			2228.915,
			2230.608,
			2232.296,
			2234.795,
			2236.46,
			2238.865,
			2239.653,
			2240.44,
			2242.796,
			2244.347,
			2246.665,
			2248.219,
			2250.574,
			2252.139,
			2253.707,
			2254.493,
			2256.06,
			2256.842,
			2258.404,
			2259.945,
			2262.251,
			2263.02,
			2263.794,
			2266.124,
			2266.901,
			2267.679,
			2270.027,
			2271.6,
			2273.956,
			2275.5,
			2277.047,
			2279.362,
			2280.12,
			2281.64,
			2283.165,
			2283.931,
			2284.688,
			2286.943,
			2288.457,
			2290.728,
			2292.245,
			2293.775,
			2294.545,
			2296.09,
			2296.867,
			2298.432,
			2300.012,
			2302.413,
			2304.032,
			2306.499,
			2307.333,
			2308.168,
			2310.678,
			2312.38,
			2314.961,
			2316.702,
			2319.345,
			2321.125,
			2322.017,
			2323.811,
			2325.62,
			2326.531,
			2328.359,
			2330.202,
			2332.98,
			2333.919,
			2334.861,
			2336.758,
			2338.662,
			2339.619,
			2342.513,
			2344.447,
			2346.39,
			2349.302,
			2351.231,
			2354.092,
			2355.034,
			2355.969,
			2358.726,
			2360.525,
			2363.157,
			2364.89,
			2366.573,
			2367.402,
			2369.042,
			2369.855,
			2371.464,
			2373.053,
			2373.841,
			2375.404,
			2376.956,
			2379.27,
			2380.037,
			2380.803,
			2383.099,
			2383.865,
			2384.628,
			2386.916,
			2388.442,
			2389.963,
			2392.238,
			2393.758,
			2396.036,
			2396.795,
			2397.554,
			2399.851,
			2401.409,
			2403.744,
			2405.33,
			2406.934,
			2407.735,
			2409.337,
			2410.144,
			2411.77,
			2413.392,
			2414.202,
			2415.818,
			2417.425,
			2419.828,
			2421.432,
			2423.83,
			2424.626,
			2425.429,
			2427.886,
			2429.518,
			2431.998,
			2433.658,
			2435.315,
			2436.139,
			2437.779,
			2438.597,
			2439.416,
			2441.869,
			2442.684,
			2443.498,
			2445.941,
			2447.57,
			2449.202,
			2450.021,
			2451.662,
			2453.284,
			2454.098,
			2455.731,
			2456.551,
			2458.186,
			2459.831,
			2462.313,
			2463.963,
			2466.462,
			2467.301,
			2468.134,
			2470.65,
			2472.343,
			2474.907,
			2476.631,
			2479.257,
			2481.029,
			2481.919,
			2483.718,
			2485.542,
			2486.458,
			2487.378,
			2490.14,
			2492.008,
			2493.883,
			2494.818,
			2496.697,
			2498.587,
			2499.535,
			2501.441,
			2502.399,
			2504.33,
			2506.293,
			2509.256,
			2511.264,
			2514.296,
			2515.308,
			2516.321,
			2519.358,
			2521.408,
			2524.489,
			2526.584,
			2529.743,
			2531.895,
			2532.979,
			2535.146,
			2538.376,
			2540.52,
			2542.631,
			2544.729,
			2546.804,
			2547.827,
			2550.881,
			2551.889,
			2552.889,
			2555.878,
			2557.842,
			2559.789,
			2562.692,
			2564.611,
			2567.483,
			2568.436,
			2569.388,
			2572.244,
			2574.14,
			2576.985,
			2578.884,
			2580.784,
			2581.734,
			2583.637,
			2584.59,
			2586.495,
			2588.402,
			2589.354,
			2591.261,
			2593.17,
			2596.037,
			2597.955,
			2600.84,
			2601.805,
			2602.772,
			2605.674,
			2607.633,
			2610.594,
			2612.582,
			2614.583,
			2617.604,
			2618.617,
			2619.632,
			2622.693,
			2623.719,
			2624.748,
			2627.847,
			2629.927,
			2632.008,
			2633.048,
			2635.135,
			2637.232,
			2638.285,
			2640.402,
			2641.47,
			2643.614,
			2645.779,
			2649.134,
			2651.374,
			2654.762,
			2655.895,
			2657.025,
			2660.423,
			2662.722,
			2666.192,
			2668.564,
			2670.933,
			2672.108,
			2674.442,
			2675.604,
			2677.945,
			2681.436,
			2682.589,
			2685.998,
			2688.197,
			2690.317,
			2691.346,
			2693.381,
			2695.343,
			2696.329,
			2698.239,
			2699.173,
			2701.03,
			2702.875,
			2705.605,
			2707.42,
			2710.125,
			2711.023,
			2711.921,
			2714.603,
			2716.39,
			2719.087,
			2720.906,
			2722.729,
			2723.628,
			2725.492,
			2726.429,
			2728.331,
			2731.156,
			2733.052,
			2734.952,
			2737.808,
			2738.749,
			2739.709,
			2741.638,
			2743.563,
			2744.521,
			2747.393,
			2749.275,
			2751.128,
			2753.839,
			2755.601,
			2758.198,
			2759.049,
			2759.897,
			2762.427,
			2763.266,
			2764.102,
			2766.599,
			2768.253,
			2770.724,
			2772.356,
			2774.797,
			2777.224,
			2778.836,
			2780.447,
			2782.876,
			2783.686,
			2784.495,
			2786.952,
			2787.777,
			2788.602,
			2791.083,
			2792.745,
			2795.236,
			2796.908,
			2798.581,
			2801.093,
			2801.938,
			2802.784,
			2806.169,
			2807.043,
			2809.657,
			2811.417,
			2814.125,
			2815.929,
			2817.741,
			2818.646,
			2821.362,
			2823.225,
			2825.086,
			2827.852,
			2829.676,
			2832.373,
			2833.247,
			2834.119,
			2836.734,
			2838.474,
			2841.081,
			2842.81,
			2845.373,
			2847.08,
			2847.925,
			2848.757,
			2851.252,
			2852.085,
			2852.917,
			2855.411,
			2857.08,
			2858.756,
			2859.596,
			2861.282,
			2862.975,
			2863.811,
			2865.482,
			2866.32,
			2868.004,
			2869.689,
			2872.239,
			2873.954,
			2876.55,
			2877.422,
			2878.298,
			2880.953,
			2882.74,
			2885.467,
			2887.308,
			2889.164,
			2890.099,
			2891.979,
			2892.925,
			2894.822,
			2896.727,
			2897.684,
			2899.609,
			2901.544,
			2903.477,
			2905.435,
			2906.417,
			2908.39,
			2910.375,
			2911.372,
			2913.376,
			2914.382,
			2916.424,
			2919.519,
			2921.606,
			2923.712,
			2926.884,
			2927.938,
			2928.994,
			2932.163,
			2934.281,
			2937.472,
			2939.609,
			2941.757,
			2942.836,
			2945.003,
			2947.18,
			2948.275,
			2951.573,
			2953.785,
			2956.007,
			2959.359,
			2961.606,
			2964.998,
			2966.134,
			2967.272,
			2970.706,
			2973.007,
			2976.473,
			2978.794,
			2981.122,
			2984.629,
			2985.802,
			2988.154,
			2990.515,
			2991.699,
			2992.885,
			2996.455,
			2998.846,
			3001.246,
			3002.449,
			3004.862,
			3007.284,
			3008.498,
			3010.932,
			3012.153,
			3014.6,
			3017.055,
			3020.753,
			3023.229,
			3026.958,
			3028.205,
			3029.453,
			3033.21,
			3035.723,
			3039.51,
			3042.045,
			3044.591,
			3048.428,
			3049.713,
			3052.29,
			3054.877,
			3056.176,
			3057.476,
			3061.398,
			3064.027,
			3066.691,
			3068.012,
			3070.656,
			3073.3,
			3074.625,
			3077.275,
			3078.601,
			3081.257,
			3083.914,
			3087.916,
			3090.573,
			3094.653,
			3096.01,
			3097.373,
			3101.454,
			3104.202,
			3108.356,
			3111.114,
			3113.877,
			3115.26,
			3118.029,
			3120.796,
			3122.181,
			3124.95,
			3126.334,
			3129.107,
			3131.901,
			3136.112,
			3137.524,
			3138.938,
			3143.179,
			3144.593,
			3146.009,
			3150.254,
			3153.09,
			3155.93,
			3160.2,
			3163.047,
			3167.302,
			3168.715,
			3170.13,
			3174.336,
			3177.124,
			3181.203,
			3183.88,
			3186.508,
			3187.806,
			3190.364,
			3192.863,
			3194.117,
			3196.542,
			3197.735,
			3200.075,
			3202.385,
			3205.775,
			3206.88,
			3207.974,
			3211.199,
			3212.255,
			3213.301,
			3216.39,
			3218.439,
			3221.426,
			3223.385,
			3226.278,
			3228.18,
			3229.124,
			3230.065,
			3233.816,
			3234.749,
			3237.552,
			3239.41,
			3241.27,
			3242.201,
			3244.067,
			3245.937,
			3246.874,
			3248.761,
			3249.72,
			3251.642,
			3253.568,
			3256.544,
			3258.531,
			3261.567,
			3262.58,
			3263.592,
			3266.654,
			3268.695,
			3271.729,
			3273.747,
			3276.776,
			3278.797,
			3279.822,
			3281.895,
			3283.97,
			3285.02,
			3287.132,
			3289.251,
			3291.347,
			3293.448,
			3294.5,
			3296.56,
			3298.623,
			3299.656,
			3301.698,
			3302.719,
			3304.762,
			3306.799,
			3307.817,
			3309.853,
			3311.883,
			3314.928,
			3315.946,
			3316.968,
			3320.039,
			3322.081,
			3325.137,
			3327.16,
			3329.163,
			3330.164,
			3332.17,
			3333.174,
			3335.184,
			3338.21,
			3340.233,
			3342.252,
			3344.26,
			3347.27,
			3349.266,
			3351.267,
			3352.276,
			3355.34,
			3357.397,
			3359.488,
			3362.634,
			3364.796,
			3368.052,
			3369.136,
			3370.223,
			3373.502,
			3375.705,
			3379.013,
			3381.219,
			3384.546,
			3386.779,
			3387.898,
			3390.145,
			3392.408,
			3393.558,
			3395.859,
			3399.247,
			3401.487,
			3403.686,
			3406.927,
			3409.041,
			3412.146,
			3413.162,
			3414.168,
			3417.134,
			3419.071,
			3420.972,
			3423.762,
			3425.584,
			3428.255,
			3429.13,
			3429.995,
			3432.534,
			3433.366,
			3434.193,
			3436.637,
			3438.245,
			3439.844,
			3440.642,
			3442.238,
			3443.837,
			3444.637,
			3446.235,
			3447.035,
			3448.632,
			3450.228,
			3452.623,
			3454.222,
			3456.623,
			3457.423,
			3458.223,
			3460.656,
			3462.278,
			3464.716,
			3466.343,
			3467.97,
			3470.404,
			3471.214,
			3472.024,
			3475.279,
			3476.096,
			3478.553,
			3480.208,
			3482.706,
			3484.381,
			3486.067,
			3486.911,
			3488.601,
			3489.447,
			3491.138,
			3492.822,
			3495.335,
			3496.992,
			3499.452,
			3500.266,
			3501.075,
			3503.469,
			3505.039,
			3507.376,
			3508.894,
			3510.385,
			3511.133,
			3512.604,
			3513.328,
			3514.757,
			3516.152,
			3516.839,
			3518.2,
			3519.545,
			3521.534,
			3522.19,
			3522.844,
			3524.149,
			3525.453,
			3526.117,
			3528.104,
			3529.43,
			3531.455,
			3532.836,
			3534.219,
			3536.313,
			3537.026,
			3537.739,
			3539.875,
			3541.295,
			3543.371,
			3544.752,
			3546.134,
			3546.813,
			3548.168,
			3548.844,
			3550.194,
			3551.554,
			3552.245,
			3553.624,
			3554.999,
			3557.069,
			3557.762,
			3558.454,
			3560.524,
			3561.213,
			3561.899,
			3563.258,
			3563.935,
			3565.288,
			3566.641,
			3568.691,
			3570.056,
			3572.11,
			3572.799,
			3573.489,
			3575.564,
			3576.258,
			3576.955,
			3579.069,
			3580.486,
			3581.912,
			3582.634,
			3584.084,
			3584.813,
			3586.277,
			3587.75,
			3588.488,
			3589.97,
			3591.464,
			3593.734,
			3594.498,
			3595.264,
			3597.584,
			3598.36,
			3599.139,
			3601.499,
			3603.08,
			3605.44,
			3607.033,
			3608.635,
			3611.057,
			3611.872,
			3613.509,
			3615.155,
			3616.816,
			3619.313,
			3620.998,
			3622.683,
			3623.524,
			3625.209,
			3626.909,
			3627.764,
			3629.483,
			3630.347,
			3632.082,
			3633.823,
			3636.459,
			3638.212,
			3640.883,
			3641.778,
			3642.674,
			3645.437,
			3647.319,
			3650.192,
			3652.142,
			3654.104,
			3655.083,
			3657.049,
			3658.037,
			3660.016,
			3661.996,
			3662.99,
			3663.988,
			3667.085,
			3669.171,
			3671.398,
			3672.521,
			3674.774,
			3677.021,
			3678.151,
			3681.384,
			3683.477,
			3685.564,
			3688.675,
			3690.779,
			3693.981,
			3695.054,
			3696.134,
			3699.39,
			3701.542,
			3704.754,
			3706.878,
			3709.002,
			3710.067,
			3712.199,
			3713.267,
			3715.411,
			3717.567,
			3718.649,
			3720.818,
			3722.99,
			3726.265,
			3728.461,
			3731.756,
			3732.867,
			3733.98,
			3736.214,
			3737.336,
			3739.588,
			3741.85,
			3745.267,
			3747.56,
			3751.024,
			3752.183,
			3753.345,
			3756.842,
			3759.177,
			3762.694,
			3765.046,
			3768.586,
			3770.953,
			3773.329,
			3774.52,
			3776.908,
			3778.108,
			3780.519,
			3782.938,
			3786.586,
			3789.029,
			3792.71,
			3793.942,
			3795.175,
			3798.888,
			3801.375,
			3805.122,
			3807.63,
			3810.146,
			3811.407,
			3813.937,
			3815.205,
			3816.475,
			3821.575,
			3822.159
		],
		[
			0,
			0.012,
			0.029,
			0.073,
			0.136,
			0.199,
			0.245,
			0.323,
			0.354,
			0.385,
			0.446,
			0.509,
			0.57,
			0.631,
			0.693,
			0.757,
			0.788,
			0.817,
			0.881,
			0.901,
			0.943,
			1.004,
			1.067,
			1.113,
			1.13,
			1.191,
			1.221,
			1.254,
			1.315,
			1.376,
			1.44,
			1.5,
			1.548,
			1.625,
			1.654,
			1.688,
			1.749,
			1.812,
			1.873,
			1.935,
			1.982,
			1.996,
			2.06,
			2.09,
			2.122,
			2.198,
			2.245,
			2.307,
			2.37,
			2.415,
			2.431,
			2.493,
			2.525,
			2.554,
			2.633,
			2.678,
			2.741,
			2.802,
			2.851,
			2.865,
			2.928,
			2.961,
			2.989,
			3.067,
			3.114,
			3.176,
			3.237,
			3.284,
			3.298,
			3.361,
			3.391,
			3.422,
			3.501,
			3.547,
			3.613,
			3.67,
			3.718,
			3.795,
			3.826,
			3.858,
			3.92,
			3.981,
			4.044,
			4.104,
			4.151,
			4.168,
			4.229,
			4.26,
			4.291,
			4.352,
			4.368,
			4.415,
			4.477,
			4.54,
			4.585,
			4.602,
			4.662,
			4.694,
			4.727,
			4.803,
			4.85,
			4.911,
			4.972,
			5.034,
			5.097,
			5.129,
			5.16,
			5.223,
			5.282,
			5.346,
			5.406,
			5.455,
			5.471,
			5.531,
			5.563,
			5.594,
			5.654,
			5.671,
			5.718,
			5.781,
			5.842,
			5.889,
			5.903,
			5.966,
			5.995,
			6.027,
			6.105,
			6.151,
			6.213,
			6.276,
			6.322,
			6.398,
			6.432,
			6.46,
			6.523,
			6.584,
			6.648,
			6.71,
			6.756,
			6.773,
			6.833,
			6.865,
			6.896,
			6.956,
			6.973,
			7.021,
			7.086,
			7.143,
			7.191,
			7.267,
			7.3,
			7.33,
			7.392,
			7.453,
			7.515,
			7.577,
			7.623,
			7.703,
			7.733,
			7.765,
			7.826,
			7.889,
			7.949,
			8.013,
			8.058,
			8.075,
			8.135,
			8.167,
			8.199,
			8.26,
			8.276,
			8.323,
			8.384,
			8.447,
			8.49,
			8.509,
			8.57,
			8.6,
			8.633,
			8.693,
			8.757,
			8.818,
			8.881,
			8.926,
			8.943,
			9.005,
			9.034,
			9.067,
			9.127,
			9.148,
			9.191,
			9.253,
			9.315,
			9.361,
			9.376,
			9.439,
			9.47,
			9.5,
			9.562,
			9.578,
			9.622,
			9.687,
			9.748,
			9.793,
			9.873,
			9.902,
			9.933,
			9.995,
			10.06,
			10.12,
			10.182,
			10.229,
			10.243,
			10.306,
			10.336,
			10.368,
			10.446,
			10.492,
			10.553,
			10.617,
			10.662,
			10.679,
			10.741,
			10.772,
			10.803,
			10.881,
			10.926,
			10.989,
			11.05,
			11.114,
			11.175,
			11.211,
			11.237,
			11.299,
			11.361,
			11.421,
			11.484,
			11.532,
			11.547,
			11.61,
			11.64,
			11.671,
			11.733,
			11.748,
			11.794,
			11.855,
			11.918,
			11.964,
			11.98,
			12.043,
			12.075,
			12.104,
			12.167,
			12.229,
			12.292,
			12.351,
			12.399,
			12.477,
			12.508,
			12.538,
			12.601,
			12.617,
			12.663,
			12.726,
			12.788,
			12.832,
			12.849,
			12.91,
			12.942,
			12.972,
			13.035,
			13.05,
			13.096,
			13.159,
			13.22,
			13.282,
			13.344,
			13.377,
			13.406,
			13.47,
			13.531,
			13.594,
			13.655,
			13.702,
			13.718,
			13.779,
			13.811,
			13.84,
			13.902,
			13.918,
			13.966,
			14.028,
			14.089,
			14.15,
			14.213,
			14.245,
			14.276,
			14.336,
			14.353,
			14.399,
			14.462,
			14.524,
			14.585,
			14.648,
			14.679,
			14.709,
			14.772,
			14.832,
			14.896,
			14.957,
			15.005,
			15.019,
			15.081,
			15.112,
			15.143,
			15.221,
			15.27,
			15.332,
			15.39,
			15.437,
			15.452,
			15.514,
			15.547,
			15.577,
			15.638,
			15.655,
			15.702,
			15.763,
			15.826,
			15.887,
			15.949,
			15.98,
			16.011,
			16.072,
			16.136,
			16.197,
			16.26,
			16.32,
			16.383,
			16.414,
			16.444,
			16.506,
			16.523,
			16.568,
			16.632,
			16.692,
			16.754,
			16.818,
			16.849,
			16.879,
			16.941,
			17.005,
			17.065,
			17.126,
			17.173,
			17.252,
			17.281,
			17.312,
			17.377,
			17.396,
			17.438,
			17.501,
			17.56,
			17.608,
			17.624,
			17.684,
			17.715,
			17.749,
			17.823,
			17.87,
			17.935,
			17.995,
			18.043,
			18.121,
			18.151,
			18.181,
			18.243,
			18.306,
			18.368,
			18.43,
			18.491,
			18.554,
			18.584,
			18.614,
			18.679,
			18.694,
			18.738,
			18.8,
			18.862,
			18.91,
			18.927,
			18.988,
			19.018,
			19.051,
			19.112,
			19.126,
			19.174,
			19.235,
			19.299,
			19.346,
			19.421,
			19.456,
			19.485,
			19.546,
			19.606,
			19.671,
			19.732,
			19.778,
			19.793,
			19.854,
			19.888,
			19.918,
			19.996,
			20.042,
			20.106,
			20.167,
			20.227,
			20.29,
			20.32,
			20.351,
			20.429,
			20.476,
			20.539,
			20.601,
			20.647,
			20.723,
			20.755,
			20.785,
			20.863,
			20.911,
			20.973,
			21.035,
			21.081,
			21.097,
			21.159,
			21.19,
			21.221,
			21.282,
			21.296,
			21.345,
			21.41,
			21.469,
			21.529,
			21.594,
			21.623,
			21.654,
			21.716,
			21.778,
			21.842,
			21.901,
			21.963,
			22.026,
			22.058,
			22.088,
			22.167,
			22.211,
			22.276,
			22.338,
			22.383,
			22.397,
			22.461,
			22.492,
			22.522,
			22.583,
			22.6,
			22.647,
			22.709,
			22.771,
			22.816,
			22.894,
			22.925,
			22.956,
			23.018,
			23.081,
			23.143,
			23.203,
			23.266,
			23.329,
			23.36,
			23.39,
			23.453,
			23.467,
			23.515,
			23.576,
			23.639,
			23.684,
			23.702,
			23.763,
			23.793,
			23.826,
			23.886,
			23.903,
			23.948,
			24.012,
			24.073,
			24.119,
			24.196,
			24.229,
			24.26,
			24.321,
			24.383,
			24.444,
			24.505,
			24.569,
			24.632,
			24.66,
			24.693,
			24.754,
			24.772,
			24.817,
			24.883,
			24.939,
			24.986,
			25.004,
			25.065,
			25.095,
			25.127,
			25.189,
			25.206,
			25.251,
			25.315,
			25.376,
			25.421,
			25.498,
			25.53,
			25.56,
			25.643,
			25.684,
			25.748,
			25.809,
			25.857,
			25.87,
			25.933,
			25.965,
			25.995,
			26.058,
			26.073,
			26.118,
			26.181,
			26.243,
			26.289,
			26.306,
			26.368,
			26.398,
			26.429,
			26.492,
			26.553,
			26.616,
			26.677,
			26.725,
			26.8,
			26.833,
			26.863,
			26.926,
			26.988,
			27.05,
			27.112,
			27.156,
			27.173,
			27.234,
			27.267,
			27.296,
			27.373,
			27.421,
			27.485,
			27.546,
			27.606,
			27.67,
			27.705,
			27.73,
			27.81,
			27.856,
			27.918,
			27.981,
			28.04,
			28.103,
			28.134,
			28.165,
			28.227,
			28.243,
			28.288,
			28.353,
			28.412,
			28.461,
			28.476,
			28.537,
			28.567,
			28.6,
			28.677,
			28.723,
			28.785,
			28.846,
			28.893,
			28.972,
			29.003,
			29.034,
			29.095,
			29.158,
			29.219,
			29.28,
			29.327,
			29.344,
			29.407,
			29.435,
			29.467,
			29.529,
			29.546,
			29.592,
			29.654,
			29.715,
			29.769,
			29.84,
			29.871,
			29.901,
			29.962,
			29.979,
			30.027,
			30.087,
			30.148,
			30.196,
			30.273,
			30.305,
			30.335,
			30.399,
			30.459,
			30.521,
			30.585,
			30.646,
			30.709,
			30.739,
			30.769,
			30.831,
			30.849,
			30.894,
			30.956,
			31.019,
			31.081,
			31.142,
			31.173,
			31.204,
			31.267,
			31.328,
			31.391,
			31.451,
			31.514,
			31.577,
			31.605,
			31.639,
			31.701,
			31.715,
			31.763,
			31.828,
			31.887,
			31.933,
			31.948,
			32.011,
			32.043,
			32.072,
			32.15,
			32.196,
			32.259,
			32.321,
			32.381,
			32.445,
			32.473,
			32.505,
			32.568,
			32.63,
			32.693,
			32.753,
			32.816,
			32.879,
			32.91,
			32.94,
			33.017,
			33.063,
			33.126,
			33.188,
			33.235,
			33.25,
			33.312,
			33.343,
			33.373,
			33.437,
			33.452,
			33.499,
			33.56,
			33.623,
			33.67,
			33.745,
			33.779,
			33.808,
			33.871,
			33.891,
			33.932,
			33.993,
			34.057,
			34.118,
			34.181,
			34.211,
			34.243,
			34.305,
			34.321,
			34.367,
			34.429,
			34.492,
			34.537,
			34.553,
			34.615,
			34.646,
			34.676,
			34.754,
			34.799,
			34.863,
			34.924,
			34.987,
			35.047,
			35.079,
			35.11,
			35.171,
			35.233,
			35.298,
			35.359,
			35.42,
			35.482,
			35.515,
			35.544,
			35.606,
			35.623,
			35.669,
			35.732,
			35.794,
			35.84,
			35.854,
			35.917,
			35.952,
			35.979,
			36.04,
			36.054,
			36.101,
			36.165,
			36.228,
			36.271,
			36.351,
			36.383,
			36.414,
			36.475,
			36.492,
			36.538,
			36.599,
			36.66,
			36.706,
			36.724,
			36.784,
			36.815,
			36.847,
			36.91,
			36.925,
			36.97,
			37.034,
			37.096,
			37.14,
			37.158,
			37.218,
			37.249,
			37.282,
			37.343,
			37.406,
			37.466,
			37.53,
			37.576,
			37.591,
			37.652,
			37.684,
			37.716,
			37.778,
			37.793,
			37.84,
			37.901,
			37.963,
			38.023,
			38.086,
			38.117,
			38.15,
			38.21,
			38.226,
			38.274,
			38.335,
			38.398,
			38.46,
			38.521,
			38.552,
			38.583,
			38.646,
			38.705,
			38.77,
			38.83,
			38.877,
			38.894,
			38.956,
			38.987,
			39.017,
			39.08,
			39.095,
			39.142,
			39.203,
			39.266,
			39.327,
			39.388,
			39.421,
			39.452,
			39.53,
			39.577,
			39.637,
			39.7,
			39.763,
			39.823,
			39.855,
			39.887,
			39.964,
			40.01,
			40.077,
			40.133,
			40.181,
			40.196,
			40.258,
			40.288,
			40.321,
			40.398,
			40.444,
			40.504,
			40.566,
			40.613,
			40.63,
			40.692,
			40.722,
			40.754,
			40.815,
			40.877,
			40.938,
			41.002,
			41.047,
			41.127,
			41.155,
			41.186,
			41.248,
			41.312,
			41.376,
			41.435,
			41.483,
			41.498,
			41.56,
			41.59,
			41.621,
			41.682,
			41.699,
			41.744,
			41.809,
			41.868,
			41.917,
			41.994,
			42.026,
			42.054,
			42.138,
			42.181,
			42.243,
			42.305,
			42.352,
			42.366,
			42.429,
			42.46,
			42.49,
			42.567,
			42.613,
			42.676,
			42.737,
			42.784,
			42.8,
			42.862,
			42.892,
			42.925,
			42.987,
			43,
			43.049,
			43.111,
			43.171,
			43.234,
			43.295,
			43.329,
			43.358,
			43.421,
			43.483,
			43.543,
			43.606,
			43.653,
			43.669,
			43.731,
			43.76,
			43.793,
			43.871,
			43.917,
			43.979,
			44.041,
			44.088,
			44.101,
			44.165,
			44.199,
			44.226,
			44.304,
			44.351,
			44.413,
			44.475,
			44.537,
			44.599,
			44.63,
			44.66,
			44.723,
			44.785,
			44.847,
			44.91,
			44.956,
			44.971,
			45.033,
			45.063,
			45.095,
			45.173,
			45.218,
			45.279,
			45.343,
			45.39,
			45.405,
			45.468,
			45.496,
			45.529,
			45.59,
			45.605,
			45.651,
			45.714,
			45.776,
			45.839,
			45.901,
			45.933,
			45.964,
			46.023,
			46.085,
			46.149,
			46.209,
			46.272,
			46.335,
			46.366,
			46.397,
			46.459,
			46.473,
			46.521,
			46.582,
			46.643,
			46.69,
			46.708,
			46.767,
			46.798,
			46.832,
			46.909,
			46.954,
			47.016,
			47.077,
			47.125,
			47.201,
			47.232,
			47.266,
			47.327,
			47.344,
			47.388,
			47.451,
			47.511,
			47.576,
			47.636,
			47.667,
			47.699,
			47.761,
			47.776,
			47.823,
			47.885,
			47.947,
			48.009,
			48.07,
			48.102,
			48.132,
			48.21,
			48.255,
			48.325,
			48.382,
			48.444,
			48.505,
			48.536,
			48.566,
			48.63,
			48.645,
			48.692,
			48.753,
			48.816,
			48.862,
			48.878,
			48.938,
			48.971,
			49.002,
			49.079,
			49.125,
			49.189,
			49.248,
			49.296,
			49.371,
			49.404,
			49.435,
			49.496,
			49.56,
			49.621,
			49.682,
			49.729,
			49.746,
			49.805,
			49.837,
			49.868,
			49.932,
			49.946,
			49.994,
			50.054,
			50.118,
			50.162,
			50.179,
			50.243,
			50.273,
			50.303,
			50.366,
			50.427,
			50.488,
			50.55,
			50.599,
			50.676,
			50.705,
			50.736,
			50.799,
			50.863,
			50.922,
			50.986,
			51.031,
			51.049,
			51.11,
			51.14,
			51.171,
			51.233,
			51.25,
			51.296,
			51.358,
			51.42,
			51.467,
			51.544,
			51.576,
			51.604,
			51.668,
			51.729,
			51.793,
			51.854,
			51.901,
			51.915,
			51.977,
			52.008,
			52.038,
			52.102,
			52.118,
			52.162,
			52.226,
			52.287,
			52.335,
			52.348,
			52.41,
			52.446,
			52.472,
			52.534,
			52.55,
			52.599,
			52.66,
			52.721,
			52.767,
			52.845,
			52.876,
			52.906,
			52.968,
			53.032,
			53.093,
			53.155,
			53.216,
			53.281,
			53.312,
			53.343,
			53.404,
			53.418,
			53.465,
			53.527,
			53.588,
			53.636,
			53.653,
			53.713,
			53.745,
			53.777,
			53.838,
			53.852,
			53.9,
			53.963,
			54.025,
			54.087,
			54.148,
			54.179,
			54.211,
			54.271,
			54.335,
			54.395,
			54.457,
			54.521,
			54.583,
			54.613,
			54.644,
			54.721,
			54.769,
			54.83,
			54.89,
			54.938,
			54.954,
			55.017,
			55.048,
			55.077,
			55.155,
			55.203,
			55.265,
			55.327,
			55.387,
			55.451,
			55.482,
			55.512,
			55.573,
			55.635,
			55.698,
			55.76,
			55.808,
			55.823,
			55.885,
			55.914,
			55.946,
			56.008,
			56.025,
			56.071,
			56.132,
			56.196,
			56.242,
			56.319,
			56.349,
			56.381,
			56.443,
			56.503,
			56.571,
			56.629,
			56.673,
			56.751,
			56.785,
			56.815,
			56.877,
			56.893,
			56.939,
			57.001,
			57.063,
			57.11,
			57.123,
			57.187,
			57.219,
			57.248,
			57.309,
			57.327,
			57.371,
			57.434,
			57.498,
			57.543,
			57.621,
			57.651,
			57.681,
			57.744,
			57.807,
			57.87,
			57.929,
			57.977,
			57.994,
			58.055,
			58.084,
			58.115,
			58.193,
			58.239,
			58.303,
			58.365,
			58.412,
			58.427,
			58.487,
			58.52,
			58.55,
			58.613,
			58.633,
			58.676,
			58.737,
			58.798,
			58.846,
			58.923,
			58.953,
			58.985,
			59.046,
			59.109,
			59.17,
			59.233,
			59.28,
			59.295,
			59.357,
			59.388,
			59.418,
			59.495,
			59.544,
			59.607,
			59.668,
			59.713,
			59.729,
			59.79,
			59.821,
			59.854,
			59.916,
			59.976,
			60.037,
			60.101,
			60.147,
			60.225,
			60.254,
			60.288,
			60.348,
			60.365,
			60.411,
			60.473,
			60.534,
			60.598,
			60.66,
			60.721,
			60.799,
			60.844,
			60.908,
			60.968,
			61.015,
			61.031,
			61.093,
			61.123,
			61.154,
			61.234,
			61.278,
			61.341,
			61.403,
			61.465,
			61.526,
			61.56,
			61.588,
			61.667,
			61.712,
			61.773,
			61.837,
			61.899,
			61.961,
			61.992,
			62.024,
			62.102,
			62.148,
			62.209,
			62.271,
			62.334,
			62.396,
			62.427,
			62.458,
			62.52,
			62.582,
			62.648,
			62.705,
			62.768,
			62.829,
			62.861,
			62.89,
			62.954,
			62.968,
			63.015,
			63.079,
			63.139,
			63.185,
			63.202,
			63.263,
			63.294,
			63.325,
			63.387,
			63.404,
			63.451,
			63.513,
			63.575,
			63.636,
			63.698,
			63.729,
			63.759,
			63.836,
			63.884,
			63.946,
			64.009,
			64.055,
			64.069,
			64.132,
			64.162,
			64.194,
			64.254,
			64.27,
			64.317,
			64.384,
			64.44,
			64.487,
			64.504,
			64.565,
			64.597,
			64.629,
			64.689,
			64.706,
			64.751,
			64.818,
			64.876,
			64.937,
			64.999,
			65.031,
			65.063,
			65.139,
			65.185,
			65.249,
			65.31,
			65.356,
			65.371,
			65.434,
			65.466,
			65.496,
			65.573,
			65.62,
			65.683,
			65.745,
			65.792,
			65.868,
			65.898,
			65.93,
			65.993,
			66.053,
			66.12,
			66.179,
			66.223,
			66.303,
			66.333,
			66.365,
			66.426,
			66.442,
			66.487,
			66.551,
			66.613,
			66.66,
			66.675,
			66.735,
			66.767,
			66.798,
			66.86,
			66.881,
			66.921,
			66.983,
			67.046,
			67.108,
			67.171,
			67.2,
			67.233,
			67.295,
			67.358,
			67.419,
			67.481,
			67.54,
			67.604,
			67.634,
			67.666,
			67.729,
			67.744,
			67.79,
			67.852,
			67.915,
			67.961,
			67.977,
			68.039,
			68.071,
			68.1,
			68.162,
			68.178,
			68.223,
			68.286,
			68.349,
			68.395,
			68.472,
			68.502,
			68.535,
			68.596,
			68.66,
			68.72,
			68.784,
			68.829,
			68.845,
			68.906,
			68.943,
			68.967,
			69.031,
			69.046,
			69.093,
			69.156,
			69.218,
			69.263,
			69.279,
			69.342,
			69.371,
			69.403,
			69.465,
			69.526,
			69.589,
			69.649,
			69.696,
			69.773,
			69.806,
			69.837,
			69.915,
			69.961,
			70.021,
			70.084,
			70.132,
			70.148,
			70.209,
			70.241,
			70.275,
			70.334,
			70.347,
			70.393,
			70.458,
			70.517,
			70.565,
			70.582,
			70.643,
			70.673,
			70.706,
			70.768,
			70.829,
			70.891,
			70.954,
			71.016,
			71.077,
			71.106,
			71.139,
			71.218,
			71.264,
			71.326,
			71.388,
			71.433,
			71.45,
			71.511,
			71.542,
			71.574,
			71.636,
			71.65,
			71.697,
			71.759,
			71.82,
			71.883,
			71.945,
			71.977,
			72.006,
			72.068,
			72.131,
			72.193,
			72.255,
			72.316,
			72.379,
			72.41,
			72.441,
			72.502,
			72.518,
			72.566,
			72.627,
			72.69,
			72.737,
			72.751,
			72.813,
			72.844,
			72.877,
			72.936,
			72.951,
			73,
			73.06,
			73.066,
			73.121,
			73.171,
			73.248,
			73.279,
			73.31,
			73.372,
			73.435,
			73.496,
			73.559,
			73.604,
			73.62,
			73.682,
			73.713,
			73.745,
			73.822,
			73.867,
			73.93,
			73.99,
			74.052,
			74.114,
			74.148,
			74.177,
			74.254,
			74.302,
			74.364,
			74.427,
			74.488,
			74.549,
			74.581,
			74.612,
			74.673,
			74.736,
			74.803,
			74.861,
			74.921,
			74.982,
			75.014,
			75.045,
			75.106,
			75.137,
			75.171,
			75.247,
			75.293,
			75.354,
			75.418,
			75.48,
			75.541,
			75.572,
			75.605,
			75.667,
			75.728,
			75.788,
			75.852,
			75.898,
			75.976,
			76.008,
			76.036,
			76.1,
			76.116,
			76.163,
			76.225,
			76.284,
			76.334,
			76.348,
			76.41,
			76.443,
			76.471,
			76.535,
			76.551,
			76.597,
			76.658,
			76.719,
			76.781,
			76.844,
			76.876,
			76.906,
			76.985,
			77.031,
			77.093,
			77.155,
			77.203,
			77.278,
			77.309,
			77.338,
			77.417,
			77.463,
			77.526,
			77.588,
			77.651,
			77.713,
			77.743,
			77.772,
			77.836,
			77.851,
			77.898,
			77.96,
			78.022,
			78.068,
			78.147,
			78.176,
			78.208,
			78.269,
			78.332,
			78.394,
			78.454,
			78.502,
			78.519,
			78.579,
			78.61,
			78.643,
			78.704,
			78.721,
			78.767,
			78.829,
			78.891,
			78.936,
			78.952,
			79.013,
			79.046,
			79.076,
			79.153,
			79.2,
			79.266,
			79.325,
			79.372,
			79.447,
			79.481,
			79.51,
			79.571,
			79.634,
			79.697,
			79.76,
			79.806,
			79.822,
			79.881,
			79.914,
			79.945,
			80.007,
			80.021,
			80.069,
			80.132,
			80.193,
			80.239,
			80.254,
			80.317,
			80.349,
			80.379,
			80.439,
			80.457,
			80.503,
			80.564,
			80.627,
			80.673,
			80.751,
			80.782,
			80.811,
			80.875,
			80.891,
			80.937,
			80.999,
			81.061,
			81.106,
			81.122,
			81.185,
			81.215,
			81.248,
			81.31,
			81.329,
			81.37,
			81.433,
			81.495,
			81.539,
			81.556,
			81.618,
			81.648,
			81.682,
			81.759,
			81.803,
			81.868,
			81.93,
			81.99,
			82.052,
			82.085,
			82.116,
			82.176,
			82.24,
			82.301,
			82.362,
			82.409,
			82.425,
			82.486,
			82.519,
			82.55,
			82.61,
			82.626,
			82.674,
			82.736,
			82.796,
			82.842,
			82.92,
			82.951,
			82.984,
			83.06,
			83.105,
			83.17,
			83.23,
			83.278,
			83.293,
			83.354,
			83.39,
			83.417,
			83.479,
			83.495,
			83.54,
			83.607,
			83.664,
			83.711,
			83.726,
			83.787,
			83.82,
			83.85,
			83.928,
			83.975,
			84.038,
			84.098,
			84.145,
			84.223,
			84.255,
			84.285,
			84.362,
			84.41,
			84.473,
			84.533,
			84.581,
			84.595,
			84.656,
			84.69,
			84.72,
			84.781,
			84.796,
			84.844,
			84.905,
			84.967,
			85.029,
			85.092,
			85.122,
			85.155,
			85.215,
			85.231,
			85.279,
			85.338,
			85.4,
			85.463,
			85.527,
			85.555,
			85.588,
			85.65,
			85.712,
			85.772,
			85.834,
			85.898,
			85.96,
			85.991,
			86.021,
			86.084,
			86.098,
			86.146,
			86.206,
			86.271,
			86.316,
			86.394,
			86.424,
			86.454,
			86.518,
			86.581,
			86.642,
			86.703,
			86.751,
			86.766,
			86.829,
			86.86,
			86.888,
			86.967,
			86.992
		]
	],
	'1673_1675_7982': [
		[
			72.465
		],
		[
			0
		],
		[
			0,
			3.109,
			5.652,
			8.204,
			9.483,
			12.049,
			13.335,
			15.912,
			18.499,
			19.795,
			22.394,
			25,
			28.914,
			31.528,
			35.461,
			36.775,
			38.091,
			40.729,
			42.05,
			44.698,
			47.352,
			51.346,
			54.016,
			58.032,
			59.373,
			60.717,
			64.757,
			67.459,
			71.526,
			74.246,
			76.973,
			78.339,
			81.076,
			83.818,
			85.192,
			87.944,
			89.322,
			92.084,
			94.852,
			99.016,
			101.801,
			105.991,
			107.391,
			108.792,
			113.005,
			115.821,
			120.059,
			122.893,
			125.73,
			127.151,
			129.999,
			131.425,
			132.853,
			137.143,
			138.575,
			140.009,
			144.319,
			147.199,
			150.086,
			151.531,
			154.426,
			157.327,
			158.779,
			161.69,
			163.147,
			166.067,
			168.993,
			173.391,
			176.33,
			180.735,
			182.197,
			183.654,
			187.984,
			190.83,
			195.037,
			197.802,
			200.533,
			201.886,
			204.564,
			205.889,
			208.509,
			211.091,
			212.366,
			214.89,
			217.375,
			219.823,
			222.234,
			223.424,
			225.779,
			228.097,
			229.242,
			231.505,
			232.623,
			234.832,
			237.005,
			240.192,
			242.293,
			245.373,
			246.393,
			247.412,
			250.47,
			252.515,
			255.603,
			257.673,
			260.786,
			262.866,
			263.906,
			265.982,
			268.052,
			269.083,
			271.145,
			273.19,
			276.228,
			277.23,
			278.226,
			280.206,
			282.177,
			283.158,
			285.116,
			286.096,
			288.05,
			290.975,
			292.919,
			294.856,
			297.742,
			298.7,
			299.656,
			302.504,
			304.392,
			307.214,
			309.086,
			310.955,
			311.89,
			313.755,
			315.616,
			316.549,
			319.343,
			321.206,
			323.071,
			325.862,
			327.709,
			330.473,
			331.395,
			332.319,
			335.088,
			336.933,
			339.708,
			341.558,
			343.416,
			346.208,
			347.139,
			348.077,
			350.881,
			351.823,
			352.791,
			355.694,
			357.647,
			359.637,
			360.636,
			362.643,
			364.673,
			365.691,
			367.736,
			368.77,
			370.845,
			372.93,
			376.054,
			378.149,
			381.292,
			382.345,
			383.399,
			386.531,
			388.631,
			391.821,
			393.942,
			396.123,
			397.219,
			399.418,
			400.533,
			402.77,
			406.129,
			407.235,
			410.541,
			412.7,
			414.868,
			415.946,
			418.101,
			420.256,
			421.335,
			423.485,
			424.556,
			426.687,
			428.802,
			431.941,
			433.992,
			437.035,
			438.032,
			439.018,
			441.937,
			443.825,
			446.571,
			448.368,
			450.13,
			450.998,
			452.707,
			453.547,
			455.198,
			456.811,
			457.604,
			459.161,
			460.68,
			462.888,
			463.606,
			464.315,
			466.389,
			467.063,
			467.73,
			469.695,
			470.972,
			472.219,
			474.051,
			475.257,
			477.051,
			477.645,
			478.238,
			480.021,
			481.192,
			482.934,
			484.087,
			485.232,
			485.8,
			486.929,
			488.05,
			488.608,
			489.744,
			490.309,
			491.433,
			492.551,
			494.258,
			495.445,
			497.216,
			497.805,
			498.395,
			500.17,
			501.35,
			503.119,
			504.283,
			505.417,
			505.984,
			507.116,
			507.683,
			508.82,
			510.517,
			511.081,
			512.77,
			513.895,
			515.025,
			515.587,
			516.704,
			517.819,
			518.376,
			519.492,
			520.052,
			521.166,
			522.274,
			523.934,
			525.042,
			526.696,
			527.247,
			527.797,
			529.451,
			530.569,
			532.251,
			533.371,
			535.064,
			536.224,
			536.804,
			537.964,
			539.125,
			539.707,
			540.868,
			542.03,
			543.193,
			544.362,
			544.948,
			546.121,
			547.297,
			547.888,
			549.668,
			550.873,
			552.095,
			553.944,
			555.189,
			557.118,
			557.768,
			558.421,
			560.398,
			561.756,
			563.828,
			565.226,
			566.642,
			567.355,
			568.793,
			569.519,
			570.982,
			572.456,
			573.199,
			574.697,
			576.221,
			578.585,
			579.381,
			580.181,
			581.794,
			583.423,
			584.242,
			586.72,
			588.354,
			589.998,
			592.486,
			594.156,
			596.677,
			597.532,
			598.388,
			600.967,
			602.699,
			605.314,
			607.062,
			608.826,
			609.709,
			611.476,
			613.249,
			614.136,
			615.908,
			616.794,
			618.575,
			620.36,
			623.038,
			624.813,
			627.48,
			628.364,
			629.239,
			631.868,
			633.622,
			636.235,
			637.984,
			639.735,
			640.611,
			642.368,
			643.249,
			644.132,
			646.788,
			647.677,
			648.569,
			651.259,
			653.063,
			654.877,
			655.787,
			657.617,
			659.46,
			660.388,
			662.252,
			663.174,
			665.056,
			666.949,
			669.813,
			671.739,
			674.652,
			675.63,
			676.613,
			679.58,
			681.578,
			684.602,
			686.634,
			688.68,
			689.707,
			691.77,
			692.807,
			694.888,
			696.982,
			698.032,
			700.131,
			702.233,
			704.344,
			706.466,
			707.532,
			709.671,
			711.822,
			712.901,
			715.069,
			716.156,
			718.341,
			720.537,
			723.852,
			726.075,
			729.43,
			730.553,
			731.678,
			735.069,
			737.343,
			740.77,
			743.068,
			745.369,
			746.522,
			748.837,
			749.994,
			752.316,
			754.648,
			755.818,
			758.167,
			760.529,
			764.097,
			766.491,
			770.102,
			771.312,
			772.524,
			774.955,
			776.174,
			778.62,
			781.076,
			784.777,
			787.255,
			790.988,
			792.237,
			793.487,
			797.253,
			798.512,
			799.774,
			803.574,
			806.119,
			808.669,
			809.947,
			812.503,
			813.781,
			816.344,
			818.91,
			820.195,
			822.775,
			825.367,
			829.263,
			831.872,
			835.804,
			837.119,
			838.436,
			842.4,
			845.059,
			849.067,
			851.753,
			854.446,
			858.498,
			859.85,
			861.203,
			865.272,
			867.992,
			872.083,
			874.819,
			877.562,
			878.935,
			881.689,
			884.449,
			885.832,
			888.603,
			889.991,
			892.772,
			895.561,
			899.757,
			902.564,
			906.786,
			908.198,
			909.61,
			913.86,
			916.702,
			920.977,
			923.836,
			926.702,
			928.139,
			931.017,
			932.458,
			933.905,
			938.253,
			939.707,
			942.618,
			945.536,
			948.461,
			951.391,
			952.859,
			955.798,
			958.744,
			960.218,
			963.173,
			964.652,
			967.616,
			970.587,
			975.055,
			978.042,
			982.535,
			984.036,
			985.539,
			990.058,
			993.078,
			997.619,
			1000.654,
			1005.22,
			1008.271,
			1009.799,
			1012.855,
			1015.911,
			1017.436,
			1020.474,
			1023.487,
			1026.469,
			1030.867,
			1033.749,
			1036.589,
			1037.994,
			1042.156,
			1044.884,
			1047.575,
			1051.536,
			1054.129,
			1057.94,
			1059.192,
			1060.435,
			1064.109,
			1066.517,
			1070.07,
			1072.405,
			1074.707,
			1075.846,
			1078.097,
			1080.314,
			1081.409,
			1083.573,
			1084.641,
			1086.75,
			1088.821,
			1091.857,
			1093.849,
			1096.743,
			1097.688,
			1098.625,
			1101.381,
			1103.164,
			1105.798,
			1107.517,
			1109.214,
			1111.74,
			1112.583,
			1113.424,
			1115.933,
			1116.773,
			1117.616,
			1120.14,
			1121.822,
			1123.514,
			1124.358,
			1126.047,
			1127.75,
			1128.605,
			1130.315,
			1131.171,
			1132.89,
			1134.611,
			1137.2,
			1138.946,
			1141.568,
			1142.454,
			1143.369,
			1146.116,
			1147.967,
			1150.784,
			1152.665,
			1154.541,
			1155.479,
			1157.357,
			1158.302,
			1159.248,
			1163.027,
			1164.93,
			1166.83,
			1169.679,
			1170.627,
			1171.574,
			1173.468,
			1175.356,
			1176.298,
			1178.181,
			1179.102,
			1180.941,
			1182.776,
			1185.499,
			1187.303,
			1190.013,
			1190.916,
			1191.818,
			1194.518,
			1195.415,
			1196.311,
			1198.994,
			1200.748,
			1202.512,
			1203.394,
			1205.164,
			1206.041,
			1207.8,
			1209.568,
			1210.441,
			1212.225,
			1214.022,
			1216.749,
			1218.585,
			1220.431,
			1222.288,
			1223.221,
			1225.09,
			1226.026,
			1227.894,
			1229.748,
			1232.479,
			1234.259,
			1236.859,
			1237.708,
			1238.547,
			1241.012,
			1242.622,
			1245.018,
			1246.597,
			1248.166,
			1248.947,
			1250.505,
			1251.285,
			1252.835,
			1255.144,
			1256.684,
			1258.214,
			1260.493,
			1261.256,
			1262.022,
			1264.305,
			1265.062,
			1265.819,
			1268.127,
			1269.661,
			1271.966,
			1273.51,
			1275.048,
			1277.354,
			1278.122,
			1278.888,
			1281.186,
			1281.954,
			1282.725,
			1285.032,
			1286.57,
			1288.156,
			1288.956,
			1290.553,
			1292.153,
			1292.996,
			1294.686,
			1295.53,
			1297.221,
			1298.948,
			1301.543,
			1303.25,
			1305.763,
			1306.6,
			1307.438,
			1309.92,
			1311.57,
			1314.044,
			1315.69,
			1318.153,
			1319.779,
			1320.591,
			1321.402,
			1323.833,
			1324.64,
			1326.25,
			1327.858,
			1329.45,
			1331.827,
			1333.41,
			1334.975,
			1335.757,
			1337.325,
			1338.11,
			1339.66,
			1341.207,
			1343.538,
			1345.076,
			1347.389,
			1348.164,
			1348.941,
			1351.283,
			1352.855,
			1355.243,
			1356.857,
			1358.482,
			1359.3,
			1360.951,
			1361.781,
			1363.452,
			1365.138,
			1365.99,
			1367.703,
			1369.432,
			1371.177,
			1372.937,
			1373.823,
			1376.502,
			1377.403,
			1378.307,
			1380.127,
			1381.043,
			1382.885,
			1384.742,
			1387.542,
			1389.438,
			1392.31,
			1393.274,
			1394.242,
			1397.168,
			1399.132,
			1402.107,
			1404.097,
			1406.098,
			1407.1,
			1409.103,
			1410.103,
			1412.092,
			1415.055,
			1417.004,
			1418.924,
			1421.741,
			1422.662,
			1423.572,
			1426.245,
			1427.118,
			1427.982,
			1430.51,
			1432.159,
			1434.608,
			1436.216,
			1437.816,
			1440.221,
			1441.021,
			1441.819,
			1444.205,
			1444.999,
			1445.791,
			1448.152,
			1449.714,
			1452.031,
			1453.568,
			1455.099,
			1455.861,
			1457.381,
			1458.139,
			1459.666,
			1461.225,
			1463.554,
			1465.133,
			1467.542,
			1468.344,
			1469.146,
			1471.603,
			1473.247,
			1475.719,
			1477.37,
			1479.022,
			1479.85,
			1481.478,
			1482.293,
			1483.924,
			1485.558,
			1486.373,
			1487.189,
			1489.641,
			1491.288,
			1493.76,
			1495.413,
			1497.078,
			1497.91,
			1499.574,
			1500.406,
			1502.089,
			1503.772,
			1506.297,
			1507.983,
			1510.508,
			1511.348,
			1512.187,
			1514.699,
			1516.37,
			1518.847,
			1520.498,
			1522.142,
			1522.959,
			1524.597,
			1525.42,
			1527.072,
			1528.726,
			1529.557,
			1531.23,
			1532.914,
			1534.608,
			1537.173,
			1538.893,
			1540.624,
			1541.495,
			1543.247,
			1544.129,
			1545.887,
			1547.673,
			1550.404,
			1552.241,
			1555.022,
			1555.956,
			1556.893,
			1559.714,
			1561.608,
			1564.471,
			1566.394,
			1568.33,
			1569.306,
			1571.267,
			1573.256,
			1574.258,
			1576.276,
			1577.29,
			1579.334,
			1581.411,
			1584.547,
			1586.65,
			1589.853,
			1590.949,
			1592.048,
			1595.407,
			1597.703,
			1599.999,
			1603.438,
			1605.676,
			1608.972,
			1610.056,
			1611.131,
			1614.33,
			1615.387,
			1616.44,
			1619.566,
			1621.632,
			1624.702,
			1626.726,
			1628.737,
			1629.738,
			1632.665,
			1634.603,
			1636.51,
			1639.354,
			1641.245,
			1644.08,
			1645.027,
			1645.976,
			1648.831,
			1650.743,
			1653.626,
			1655.558,
			1657.496,
			1658.466,
			1660.412,
			1661.386,
			1663.337,
			1665.294,
			1666.276,
			1668.231,
			1670.214,
			1672.207,
			1674.21,
			1675.216,
			1677.236,
			1679.265,
			1680.283,
			1682.329,
			1683.358,
			1685.424,
			1687.503,
			1690.635,
			1692.726,
			1695.882,
			1696.937,
			1697.994,
			1701.169,
			1703.291,
			1706.476,
			1708.636,
			1710.794,
			1711.873,
			1714.052,
			1715.145,
			1717.333,
			1720.605,
			1722.787,
			1725.033,
			1728.438,
			1729.614,
			1730.791,
			1733.151,
			1735.504,
			1736.682,
			1740.168,
			1742.461,
			1744.732,
			1748.11,
			1750.354,
			1753.705,
			1754.814,
			1755.919,
			1759.221,
			1761.36,
			1764.556,
			1766.662,
			1769.817,
			1771.91,
			1774.007,
			1775.054,
			1778.192,
			1780.294,
			1782.409,
			1785.601,
			1787.755,
			1791.004,
			1792.095,
			1793.187,
			1795.376,
			1796.473,
			1798.668,
			1800.864,
			1804.121,
			1806.275,
			1809.442,
			1810.49,
			1811.531,
			1814.63,
			1815.646,
			1816.652,
			1819.626,
			1821.563,
			1823.465,
			1824.402,
			1826.265,
			1828.081,
			1828.979,
			1831.62,
			1833.338,
			1835.016,
			1837.487,
			1839.099,
			1841.476,
			1842.261,
			1843.044,
			1845.376,
			1846.921,
			1849.238,
			1850.783,
			1852.325,
			1853.095,
			1854.643,
			1855.42,
			1856.972,
			1858.524,
			1859.301,
			1860.109,
			1862.534,
			1864.153,
			1865.836,
			1866.69,
			1868.399,
			1870.117,
			1870.989,
			1872.735,
			1873.608,
			1875.356,
			1877.096,
			1879.708,
			1881.464,
			1884.104,
			1884.984,
			1885.862,
			1888.471,
			1890.21,
			1892.761,
			1894.449,
			1896.136,
			1896.978,
			1898.661,
			1899.502,
			1901.185,
			1902.893,
			1903.749,
			1905.46,
			1907.174,
			1909.755,
			1910.617,
			1911.481,
			1914.055,
			1914.915,
			1915.778,
			1918.359,
			1920.069,
			1922.65,
			1924.35,
			1926.056,
			1928.627,
			1929.487,
			1930.349,
			1932.954,
			1934.706,
			1937.356,
			1939.142,
			1941.848,
			1943.668,
			1944.588,
			1946.436,
			1948.297,
			1949.236,
			1951.13,
			1953.038,
			1955.929,
			1956.899,
			1957.873,
			1960.781,
			1961.759,
			1962.739,
			1964.708,
			1965.691,
			1967.666,
			1969.654,
			1972.674,
			1974.703,
			1977.807,
			1978.848,
			1979.891,
			1983.061,
			1985.183,
			1988.352,
			1990.473,
			1992.6,
			1993.668,
			1995.811,
			1997.962,
			1999.042,
			2002.297,
			2004.479,
			2006.672,
			2009.978,
			2012.194,
			2015.535,
			2016.654,
			2017.774,
			2021.152,
			2023.415,
			2026.827,
			2029.113,
			2031.408,
			2032.559,
			2034.868,
			2036.026,
			2037.186,
			2040.679,
			2041.848,
			2043.019,
			2046.546,
			2048.908,
			2052.468,
			2054.853,
			2057.247,
			2058.448,
			2060.855,
			2062.062,
			2064.482,
			2066.911,
			2070.57,
			2073.019,
			2076.708,
			2077.941,
			2079.176,
			2082.894,
			2085.382,
			2089.125,
			2091.627,
			2095.394,
			2097.913,
			2099.176,
			2101.708,
			2104.25,
			2105.523,
			2108.086,
			2110.656,
			2113.243,
			2115.837,
			2117.139,
			2121.06,
			2122.371,
			2123.683,
			2126.305,
			2127.621,
			2130.278,
			2132.921,
			2136.897,
			2139.551,
			2143.542,
			2144.875,
			2146.208,
			2150.221,
			2152.905,
			2156.954,
			2159.663,
			2162.356,
			2163.739,
			2166.461,
			2167.825,
			2170.556,
			2174.663,
			2177.415,
			2180.179,
			2184.35,
			2185.742,
			2187.111,
			2191.312,
			2192.702,
			2194.093,
			2198.268,
			2201.056,
			2205.239,
			2208.055,
			2210.878,
			2215.136,
			2216.55,
			2217.936,
			2222.174,
			2223.588,
			2225.003,
			2229.257,
			2232.094,
			2234.964,
			2236.381,
			2239.205,
			2242.005,
			2243.371,
			2246.123,
			2247.483,
			2250.194,
			2252.839,
			2256.688,
			2259.212,
			2262.912,
			2264.126,
			2265.326,
			2268.873,
			2271.184,
			2274.588,
			2276.811,
			2279,
			2280.082,
			2282.217,
			2283.29,
			2285.373,
			2287.42,
			2288.43,
			2289.431,
			2292.361,
			2294.283,
			2297.122,
			2298.978,
			2300.825,
			2301.749,
			2303.594,
			2304.517,
			2306.383,
			2308.27,
			2311.115,
			2313.05,
			2315.951,
			2316.925,
			2317.897,
			2320.8,
			2322.75,
			2325.671,
			2327.624,
			2329.577,
			2330.551,
			2332.506,
			2333.491,
			2335.452,
			2337.397,
			2338.357,
			2340.271,
			2342.175,
			2344.071,
			2345.958,
			2346.897,
			2348.768,
			2350.632,
			2351.548,
			2353.4,
			2354.337,
			2356.183,
			2358.025,
			2360.76,
			2362.582,
			2365.297,
			2366.201,
			2367.106,
			2369.819,
			2371.628,
			2374.343,
			2376.155,
			2377.976,
			2378.888,
			2380.716,
			2381.635,
			2383.483,
			2385.332,
			2386.261,
			2388.129,
			2389.998,
			2392.817,
			2394.706,
			2397.549,
			2398.502,
			2399.458,
			2402.343,
			2404.279,
			2406.228,
			2407.206,
			2409.172,
			2411.131,
			2414.129,
			2415.128,
			2416.13,
			2419.153,
			2420.166,
			2421.181,
			2424.224,
			2426.277,
			2428.339,
			2429.375,
			2431.455,
			2432.498,
			2434.584,
			2436.676,
			2437.726,
			2439.835,
			2441.957,
			2445.162,
			2447.308,
			2450.543,
			2451.626,
			2452.712,
			2456.004,
			2458.202,
			2461.512,
			2463.728,
			2467.037,
			2469.242,
			2470.336,
			2471.424,
			2474.635,
			2475.688,
			2476.73,
			2479.786,
			2481.787,
			2483.752,
			2484.721,
			2486.629,
			2488.498,
			2489.415,
			2491.222,
			2492.113,
			2493.865,
			2495.577,
			2498.088,
			2499.703,
			2502.058,
			2502.828,
			2503.591,
			2505.857,
			2507.356,
			2509.602,
			2511.141,
			2513.453,
			2515.021,
			2515.812,
			2517.398,
			2518.987,
			2519.784,
			2521.377,
			2522.964,
			2524.543,
			2526.104,
			2526.88,
			2529.194,
			2529.962,
			2530.727,
			2532.258,
			2533.024,
			2534.561,
			2536.111,
			2538.443,
			2540.006,
			2542.364,
			2543.148,
			2543.931,
			2546.3,
			2547.878,
			2550.238,
			2551.823,
			2553.405,
			2554.197,
			2555.771,
			2557.334,
			2558.116,
			2559.681,
			2560.463,
			2562.02,
			2563.574,
			2565.904,
			2566.68,
			2567.454,
			2569.769,
			2570.537,
			2571.306,
			2573.602,
			2575.125,
			2576.645,
			2578.909,
			2580.406,
			2582.669,
			2583.421,
			2584.171,
			2587.177,
			2587.932,
			2590.185,
			2591.687,
			2593.955,
			2595.456,
			2596.205,
			2597.703,
			2599.193,
			2599.934,
			2601.412,
			2602.899,
			2605.128,
			2606.609,
			2608.812,
			2609.54,
			2610.265,
			2612.429,
			2613.884,
			2616.046,
			2617.481,
			2618.933,
			2621.095,
			2621.814,
			2622.532,
			2625.454,
			2626.182,
			2628.358,
			2629.794,
			2631.944,
			2633.376,
			2634.799,
			2635.51,
			2636.936,
			2637.65,
			2639.073,
			2640.496,
			2642.64,
			2644.075,
			2646.23,
			2646.952,
			2647.677,
			2649.854,
			2651.307,
			2653.506,
			2654.979,
			2656.46,
			2657.205,
			2658.707,
			2659.462,
			2660.98,
			2662.513,
			2663.285,
			2664.835,
			2666.393,
			2667.966,
			2669.55,
			2670.335,
			2671.917,
			2673.515,
			2674.32,
			2675.941,
			2676.756,
			2678.384,
			2680.039,
			2680.872,
			2682.547,
			2684.224,
			2686.746,
			2687.592,
			2688.443,
			2691.016,
			2692.758,
			2695.395,
			2697.185,
			2698.992,
			2699.899,
			2701.746,
			2702.677,
			2704.545,
			2707.383,
			2709.32,
			2711.262,
			2714.213,
			2715.199,
			2716.187,
			2719.115,
			2720.09,
			2721.066,
			2723.01,
			2723.981,
			2725.928,
			2727.904,
			2730.919,
			2732.958,
			2736.065,
			2737.105,
			2738.159,
			2741.339,
			2743.463,
			2746.658,
			2748.772,
			2750.877,
			2751.935,
			2754.01,
			2755.044,
			2757.12,
			2759.186,
			2760.217,
			2762.287,
			2764.361,
			2767.481,
			2769.572,
			2772.708,
			2773.753,
			2774.794,
			2777.931,
			2780.035,
			2783.209,
			2785.341,
			2787.486,
			2788.562,
			2790.725,
			2791.811,
			2792.9,
			2796.164,
			2797.264,
			2798.367,
			2801.69,
			2803.919,
			2807.28,
			2809.533,
			2811.795,
			2812.93,
			2816.346,
			2818.635,
			2820.933,
			2824.395,
			2826.713,
			2830.207,
			2831.376,
			2832.546,
			2836.073,
			2838.435,
			2841.995,
			2844.383,
			2846.779,
			2847.982,
			2850.394,
			2851.603,
			2854.026,
			2857.675,
			2860.118,
			2862.57,
			2865.029,
			2867.497,
			2868.734,
			2871.212,
			2873.699,
			2874.945,
			2878.697,
			2880.114
		],
		[
			0,
			0.051,
			0.114,
			0.16,
			0.175,
			0.237,
			0.269,
			0.3,
			0.362,
			0.378,
			0.425,
			0.49,
			0.548,
			0.61,
			0.671,
			0.704,
			0.734,
			0.795,
			0.812,
			0.858,
			0.92,
			0.982,
			1.027,
			1.104,
			1.137,
			1.169,
			1.23,
			1.293,
			1.354,
			1.417,
			1.462,
			1.478,
			1.538,
			1.57,
			1.601,
			1.665,
			1.678,
			1.725,
			1.79,
			1.849,
			1.895,
			1.972,
			2.004,
			2.037,
			2.097,
			2.16,
			2.224,
			2.282,
			2.331,
			2.346,
			2.409,
			2.438,
			2.468,
			2.531,
			2.547,
			2.593,
			2.655,
			2.718,
			2.763,
			2.779,
			2.842,
			2.873,
			2.905,
			2.964,
			2.98,
			3.029,
			3.089,
			3.153,
			3.197,
			3.276,
			3.308,
			3.338,
			3.401,
			3.463,
			3.524,
			3.587,
			3.633,
			3.648,
			3.71,
			3.74,
			3.773,
			3.835,
			3.854,
			3.898,
			3.958,
			4.02,
			4.067,
			4.081,
			4.145,
			4.173,
			4.206,
			4.269,
			4.282,
			4.331,
			4.394,
			4.455,
			4.5,
			4.577,
			4.608,
			4.641,
			4.703,
			4.765,
			4.826,
			4.888,
			4.949,
			5.011,
			5.042,
			5.075,
			5.137,
			5.151,
			5.199,
			5.261,
			5.322,
			5.367,
			5.383,
			5.445,
			5.477,
			5.509,
			5.57,
			5.587,
			5.633,
			5.695,
			5.756,
			5.801,
			5.881,
			5.916,
			5.944,
			6.004,
			6.066,
			6.129,
			6.19,
			6.237,
			6.254,
			6.314,
			6.346,
			6.376,
			6.455,
			6.501,
			6.562,
			6.626,
			6.688,
			6.748,
			6.781,
			6.811,
			6.872,
			6.935,
			6.998,
			7.058,
			7.105,
			7.183,
			7.214,
			7.243,
			7.305,
			7.323,
			7.368,
			7.432,
			7.493,
			7.537,
			7.554,
			7.618,
			7.648,
			7.678,
			7.742,
			7.755,
			7.803,
			7.864,
			7.927,
			7.989,
			8.05,
			8.082,
			8.113,
			8.175,
			8.236,
			8.298,
			8.362,
			8.406,
			8.424,
			8.484,
			8.516,
			8.546,
			8.622,
			8.67,
			8.733,
			8.794,
			8.843,
			8.856,
			8.919,
			8.95,
			8.981,
			9.044,
			9.06,
			9.106,
			9.167,
			9.229,
			9.277,
			9.352,
			9.385,
			9.416,
			9.478,
			9.538,
			9.6,
			9.662,
			9.71,
			9.726,
			9.786,
			9.819,
			9.848,
			9.911,
			9.927,
			9.972,
			10.038,
			10.096,
			10.143,
			10.159,
			10.221,
			10.251,
			10.284,
			10.361,
			10.407,
			10.47,
			10.531,
			10.576,
			10.654,
			10.687,
			10.716,
			10.794,
			10.84,
			10.904,
			10.966,
			11.012,
			11.026,
			11.088,
			11.121,
			11.151,
			11.212,
			11.229,
			11.275,
			11.337,
			11.399,
			11.46,
			11.523,
			11.554,
			11.584,
			11.647,
			11.71,
			11.771,
			11.834,
			11.879,
			11.896,
			11.958,
			11.988,
			12.021,
			12.1,
			12.144,
			12.205,
			12.268,
			12.314,
			12.329,
			12.392,
			12.421,
			12.454,
			12.517,
			12.53,
			12.579,
			12.639,
			12.701,
			12.747,
			12.826,
			12.858,
			12.887,
			12.948,
			13.013,
			13.075,
			13.137,
			13.198,
			13.259,
			13.29,
			13.322,
			13.385,
			13.398,
			13.445,
			13.509,
			13.568,
			13.617,
			13.631,
			13.692,
			13.726,
			13.754,
			13.833,
			13.88,
			13.943,
			14.005,
			14.049,
			14.129,
			14.162,
			14.191,
			14.25,
			14.313,
			14.376,
			14.438,
			14.483,
			14.499,
			14.562,
			14.593,
			14.625,
			14.687,
			14.701,
			14.749,
			14.81,
			14.871,
			14.92,
			14.933,
			14.995,
			15.027,
			15.059,
			15.136,
			15.181,
			15.243,
			15.304,
			15.353,
			15.431,
			15.46,
			15.49,
			15.569,
			15.617,
			15.677,
			15.74,
			15.787,
			15.801,
			15.865,
			15.896,
			15.926,
			15.989,
			16.003,
			16.049,
			16.111,
			16.175,
			16.222,
			16.299,
			16.328,
			16.361,
			16.423,
			16.483,
			16.547,
			16.606,
			16.654,
			16.67,
			16.731,
			16.764,
			16.794,
			16.856,
			16.873,
			16.918,
			16.979,
			17.042,
			17.088,
			17.105,
			17.168,
			17.199,
			17.228,
			17.291,
			17.305,
			17.354,
			17.413,
			17.477,
			17.521,
			17.601,
			17.632,
			17.663,
			17.738,
			17.786,
			17.852,
			17.91,
			17.955,
			17.971,
			18.035,
			18.066,
			18.097,
			18.157,
			18.175,
			18.222,
			18.286,
			18.345,
			18.39,
			18.408,
			18.468,
			18.5,
			18.532,
			18.593,
			18.609,
			18.655,
			18.717,
			18.778,
			18.825,
			18.903,
			18.935,
			18.964,
			19.027,
			19.088,
			19.151,
			19.212,
			19.26,
			19.272,
			19.337,
			19.367,
			19.399,
			19.461,
			19.476,
			19.524,
			19.59,
			19.647,
			19.71,
			19.772,
			19.8,
			19.832,
			19.894,
			19.91,
			19.956,
			20.019,
			20.081,
			20.143,
			20.204,
			20.237,
			20.267,
			20.328,
			20.351,
			20.392,
			20.454,
			20.515,
			20.562,
			20.576,
			20.638,
			20.669,
			20.702,
			20.762,
			20.778,
			20.825,
			20.889,
			20.948,
			21.011,
			21.072,
			21.104,
			21.134,
			21.197,
			21.26,
			21.321,
			21.384,
			21.429,
			21.506,
			21.537,
			21.57,
			21.632,
			21.692,
			21.756,
			21.818,
			21.863,
			21.879,
			21.941,
			21.971,
			22.003,
			22.065,
			22.081,
			22.126,
			22.19,
			22.252,
			22.297,
			22.377,
			22.412,
			22.437,
			22.516,
			22.562,
			22.624,
			22.686,
			22.733,
			22.748,
			22.81,
			22.842,
			22.87,
			22.934,
			22.948,
			22.996,
			23.059,
			23.118,
			23.165,
			23.181,
			23.243,
			23.275,
			23.304,
			23.367,
			23.383,
			23.431,
			23.492,
			23.553,
			23.6,
			23.678,
			23.708,
			23.74,
			23.803,
			23.864,
			23.926,
			23.987,
			24.051,
			24.111,
			24.143,
			24.173,
			24.235,
			24.251,
			24.298,
			24.361,
			24.42,
			24.472,
			24.545,
			24.576,
			24.606,
			24.67,
			24.733,
			24.794,
			24.855,
			24.901,
			24.981,
			25.01,
			25.042,
			25.104,
			25.166,
			25.228,
			25.291,
			25.335,
			25.351,
			25.414,
			25.445,
			25.475,
			25.538,
			25.554,
			25.6,
			25.665,
			25.723,
			25.772,
			25.847,
			25.879,
			25.911,
			25.973,
			26.035,
			26.096,
			26.159,
			26.206,
			26.281,
			26.314,
			26.345,
			26.406,
			26.423,
			26.468,
			26.531,
			26.593,
			26.64,
			26.654,
			26.717,
			26.748,
			26.779,
			26.841,
			26.856,
			26.902,
			26.965,
			27.027,
			27.073,
			27.149,
			27.181,
			27.211,
			27.29,
			27.336,
			27.397,
			27.46,
			27.507,
			27.521,
			27.583,
			27.616,
			27.645,
			27.721,
			27.771,
			27.832,
			27.895,
			27.94,
			27.957,
			28.018,
			28.05,
			28.081,
			28.144,
			28.158,
			28.204,
			28.265,
			28.328,
			28.376,
			28.452,
			28.484,
			28.514,
			28.576,
			28.595,
			28.638,
			28.701,
			28.761,
			28.809,
			28.826,
			28.887,
			28.917,
			28.947,
			29.011,
			29.026,
			29.073,
			29.135,
			29.198,
			29.259,
			29.32,
			29.352,
			29.381,
			29.445,
			29.461,
			29.505,
			29.57,
			29.63,
			29.677,
			29.754,
			29.785,
			29.816,
			29.877,
			29.941,
			30.003,
			30.065,
			30.11,
			30.126,
			30.189,
			30.218,
			30.251,
			30.328,
			30.373,
			30.436,
			30.5,
			30.544,
			30.56,
			30.621,
			30.656,
			30.685,
			30.746,
			30.81,
			30.872,
			30.933,
			30.978,
			31.058,
			31.086,
			31.118,
			31.181,
			31.196,
			31.243,
			31.307,
			31.368,
			31.413,
			31.427,
			31.491,
			31.521,
			31.554,
			31.615,
			31.631,
			31.678,
			31.737,
			31.801,
			31.864,
			31.923,
			31.957,
			31.988,
			32.05,
			32.111,
			32.171,
			32.233,
			32.296,
			32.359,
			32.388,
			32.42,
			32.484,
			32.498,
			32.546,
			32.609,
			32.669,
			32.731,
			32.793,
			32.823,
			32.856,
			32.918,
			32.932,
			32.98,
			33.043,
			33.104,
			33.167,
			33.226,
			33.256,
			33.288,
			33.351,
			33.414,
			33.475,
			33.537,
			33.584,
			33.6,
			33.662,
			33.692,
			33.723,
			33.784,
			33.801,
			33.847,
			33.91,
			33.97,
			34.018,
			34.034,
			34.095,
			34.127,
			34.158,
			34.219,
			34.235,
			34.281,
			34.344,
			34.405,
			34.451,
			34.531,
			34.56,
			34.592,
			34.654,
			34.714,
			34.779,
			34.839,
			34.887,
			34.901,
			34.964,
			34.993,
			35.027,
			35.103,
			35.148,
			35.211,
			35.274,
			35.32,
			35.334,
			35.398,
			35.428,
			35.46,
			35.521,
			35.584,
			35.646,
			35.709,
			35.768,
			35.831,
			35.863,
			35.895,
			35.956,
			35.972,
			36.018,
			36.079,
			36.142,
			36.203,
			36.264,
			36.297,
			36.327,
			36.39,
			36.404,
			36.451,
			36.514,
			36.577,
			36.637,
			36.7,
			36.73,
			36.762,
			36.825,
			36.886,
			36.951,
			37.009,
			37.055,
			37.072,
			37.133,
			37.164,
			37.195,
			37.256,
			37.274,
			37.32,
			37.383,
			37.444,
			37.507,
			37.568,
			37.599,
			37.63,
			37.692,
			37.707,
			37.754,
			37.816,
			37.877,
			37.924,
			38.003,
			38.034,
			38.063,
			38.126,
			38.188,
			38.251,
			38.311,
			38.36,
			38.374,
			38.437,
			38.467,
			38.497,
			38.559,
			38.576,
			38.621,
			38.684,
			38.745,
			38.809,
			38.87,
			38.904,
			38.931,
			38.995,
			39.011,
			39.057,
			39.12,
			39.181,
			39.226,
			39.303,
			39.335,
			39.365,
			39.429,
			39.489,
			39.553,
			39.615,
			39.662,
			39.676,
			39.738,
			39.77,
			39.8,
			39.863,
			39.879,
			39.924,
			39.986,
			40.047,
			40.11,
			40.174,
			40.204,
			40.234,
			40.298,
			40.358,
			40.419,
			40.481,
			40.529,
			40.607,
			40.637,
			40.668,
			40.731,
			40.746,
			40.793,
			40.857,
			40.916,
			40.978,
			41.042,
			41.071,
			41.101,
			41.181,
			41.226,
			41.288,
			41.349,
			41.412,
			41.476,
			41.504,
			41.536,
			41.599,
			41.66,
			41.723,
			41.783,
			41.83,
			41.846,
			41.908,
			41.94,
			41.97,
			42.032,
			42.048,
			42.096,
			42.155,
			42.219,
			42.265,
			42.281,
			42.342,
			42.375,
			42.405,
			42.468,
			42.482,
			42.527,
			42.592,
			42.651,
			42.7,
			42.775,
			42.806,
			42.839,
			42.9,
			42.962,
			43.023,
			43.087,
			43.134,
			43.149,
			43.212,
			43.242,
			43.273,
			43.35,
			43.396,
			43.46,
			43.521,
			43.569,
			43.583,
			43.644,
			43.677,
			43.706,
			43.784,
			43.831,
			43.893,
			43.954,
			44.003,
			44.079,
			44.109,
			44.14,
			44.204,
			44.264,
			44.328,
			44.389,
			44.452,
			44.514,
			44.545,
			44.575,
			44.652,
			44.698,
			44.763,
			44.823,
			44.885,
			44.946,
			44.977,
			45.01,
			45.07,
			45.093,
			45.132,
			45.195,
			45.256,
			45.304,
			45.38,
			45.411,
			45.444,
			45.506,
			45.521,
			45.568,
			45.628,
			45.69,
			45.737,
			45.754,
			45.815,
			45.847,
			45.878,
			45.955,
			46.003,
			46.063,
			46.127,
			46.187,
			46.249,
			46.28,
			46.313,
			46.388,
			46.435,
			46.498,
			46.559,
			46.606,
			46.621,
			46.682,
			46.716,
			46.746,
			46.806,
			46.824,
			46.87,
			46.932,
			46.993,
			47.039,
			47.055,
			47.117,
			47.154,
			47.178,
			47.243,
			47.257,
			47.304,
			47.369,
			47.426,
			47.473,
			47.552,
			47.581,
			47.615,
			47.676,
			47.737,
			47.801,
			47.863,
			47.909,
			47.923,
			47.986,
			48.016,
			48.047,
			48.11,
			48.126,
			48.171,
			48.234,
			48.295,
			48.344,
			48.358,
			48.421,
			48.451,
			48.48,
			48.56,
			48.605,
			48.672,
			48.73,
			48.793,
			48.854,
			48.885,
			48.916,
			48.994,
			49.041,
			49.104,
			49.165,
			49.227,
			49.287,
			49.318,
			49.351,
			49.411,
			49.429,
			49.474,
			49.536,
			49.598,
			49.645,
			49.661,
			49.723,
			49.754,
			49.784,
			49.845,
			49.863,
			49.908,
			49.97,
			50.032,
			50.079,
			50.155,
			50.186,
			50.217,
			50.294,
			50.343,
			50.404,
			50.465,
			50.513,
			50.527,
			50.589,
			50.622,
			50.653,
			50.729,
			50.776,
			50.839,
			50.9,
			50.947,
			51.025,
			51.055,
			51.086,
			51.148,
			51.21,
			51.277,
			51.334,
			51.382,
			51.398,
			51.459,
			51.488,
			51.52,
			51.584,
			51.597,
			51.645,
			51.706,
			51.768,
			51.831,
			51.893,
			51.924,
			51.953,
			52.017,
			52.031,
			52.078,
			52.139,
			52.202,
			52.249,
			52.326,
			52.358,
			52.389,
			52.466,
			52.514,
			52.576,
			52.635,
			52.698,
			52.761,
			52.792,
			52.823,
			52.884,
			52.9,
			52.947,
			53.009,
			53.069,
			53.117,
			53.133,
			53.195,
			53.227,
			53.256,
			53.319,
			53.337,
			53.38,
			53.444,
			53.503,
			53.55,
			53.629,
			53.66,
			53.689,
			53.754,
			53.816,
			53.878,
			53.939,
			53.984,
			54.001,
			54.063,
			54.093,
			54.124,
			54.204,
			54.25,
			54.312,
			54.373,
			54.419,
			54.435,
			54.498,
			54.528,
			54.557,
			54.622,
			54.682,
			54.75,
			54.807,
			54.855,
			54.932,
			54.961,
			54.993,
			55.054,
			55.071,
			55.117,
			55.18,
			55.239,
			55.288,
			55.303,
			55.365,
			55.401,
			55.428,
			55.487,
			55.505,
			55.55,
			55.614,
			55.676,
			55.721,
			55.798,
			55.831,
			55.861,
			55.921,
			55.984,
			56.048,
			56.11,
			56.155,
			56.172,
			56.234,
			56.264,
			56.295,
			56.356,
			56.373,
			56.418,
			56.481,
			56.543,
			56.604,
			56.668,
			56.698,
			56.728,
			56.791,
			56.808,
			56.853,
			56.914,
			56.977,
			57.025,
			57.101,
			57.131,
			57.164,
			57.226,
			57.288,
			57.348,
			57.412,
			57.461,
			57.474,
			57.535,
			57.568,
			57.598,
			57.661,
			57.676,
			57.723,
			57.785,
			57.845,
			57.893,
			57.907,
			57.97,
			58.001,
			58.031,
			58.093,
			58.109,
			58.155,
			58.217,
			58.28,
			58.325,
			58.404,
			58.434,
			58.467,
			58.543,
			58.591,
			58.651,
			58.714,
			58.761,
			58.776,
			58.838,
			58.87,
			58.901,
			58.96,
			58.978,
			59.024,
			59.087,
			59.148,
			59.209,
			59.272,
			59.303,
			59.334,
			59.395,
			59.456,
			59.519,
			59.522,
			59.581,
			59.628,
			59.704,
			59.737,
			59.767,
			59.829,
			59.846,
			59.891,
			59.954,
			60.017,
			60.062,
			60.077,
			60.141,
			60.17,
			60.203,
			60.263,
			60.279,
			60.325,
			60.387,
			60.45,
			60.498,
			60.575,
			60.605,
			60.635,
			60.698,
			60.759,
			60.828,
			60.884,
			60.947,
			61.006,
			61.04,
			61.069,
			61.133,
			61.147,
			61.193,
			61.257,
			61.317,
			61.365,
			61.381,
			61.443,
			61.472,
			61.503,
			61.567,
			61.589,
			61.628,
			61.69,
			61.751,
			61.814,
			61.877,
			61.906,
			61.938,
			62.014,
			62.063,
			62.123,
			62.186,
			62.249,
			62.309,
			62.342,
			62.37,
			62.435,
			62.45,
			62.495,
			62.559,
			62.62,
			62.667,
			62.683,
			62.745,
			62.775,
			62.806,
			62.869,
			62.883,
			62.931,
			62.994,
			63.054,
			63.1,
			63.179,
			63.21,
			63.241,
			63.303,
			63.365,
			63.428,
			63.488,
			63.536,
			63.55,
			63.611,
			63.65,
			63.675,
			63.737,
			63.751,
			63.798,
			63.86,
			63.923,
			63.968,
			63.984,
			64.046,
			64.076,
			64.109,
			64.186,
			64.231,
			64.295,
			64.356,
			64.403,
			64.482,
			64.513,
			64.543,
			64.621,
			64.666,
			64.728,
			64.789,
			64.854,
			64.914,
			64.945,
			64.977,
			65.039,
			65.055,
			65.101,
			65.163,
			65.225,
			65.27,
			65.35,
			65.38,
			65.41,
			65.471,
			65.533,
			65.596,
			65.659,
			65.72,
			65.784,
			65.814,
			65.844,
			65.922,
			65.969,
			66.032,
			66.093,
			66.155,
			66.218,
			66.248,
			66.28,
			66.341,
			66.357,
			66.403,
			66.464,
			66.527,
			66.572,
			66.651,
			66.682,
			66.713,
			66.775,
			66.838,
			66.899,
			66.96,
			67.006,
			67.023,
			67.086,
			67.116,
			67.147,
			67.208,
			67.226,
			67.271,
			67.334,
			67.394,
			67.442,
			67.458,
			67.519,
			67.551,
			67.582,
			67.644,
			67.66,
			67.704,
			67.766,
			67.771,
			67.83,
			67.876,
			67.954,
			67.984,
			68.015,
			68.093,
			68.137,
			68.2,
			68.263,
			68.31,
			68.326,
			68.386,
			68.418,
			68.45,
			68.528,
			68.574,
			68.634,
			68.699,
			68.743,
			68.758,
			68.821,
			68.853,
			68.883,
			68.944,
			68.962,
			69.008,
			69.07,
			69.131,
			69.177,
			69.257,
			69.285,
			69.317,
			69.395,
			69.442,
			69.504,
			69.566,
			69.612,
			69.628,
			69.689,
			69.72,
			69.751,
			69.814,
			69.832,
			69.876,
			69.942,
			70.001,
			70.047,
			70.122,
			70.155,
			70.185,
			70.246,
			70.31,
			70.373,
			70.435,
			70.481,
			70.497,
			70.558,
			70.587,
			70.619,
			70.682,
			70.698,
			70.743,
			70.805,
			70.869,
			70.931,
			70.993,
			71.021,
			71.055,
			71.132,
			71.178,
			71.241,
			71.301,
			71.347,
			71.427,
			71.455,
			71.487,
			71.551,
			71.611,
			71.674,
			71.737,
			71.782,
			71.799,
			71.861,
			71.895,
			71.921,
			72.001,
			72.046,
			72.107,
			72.17,
			72.218,
			72.231,
			72.293,
			72.326,
			72.357,
			72.435,
			72.465
		]
	],
	'1670_1671_7982': [
		[
			111.458
		],
		[
			0
		],
		[
			0,
			1.776,
			5.424,
			9.07,
			12.712,
			14.53,
			18.167,
			21.808,
			23.63,
			29.104,
			32.76,
			36.42,
			41.922,
			45.596,
			51.111,
			52.951,
			54.79,
			60.309,
			63.992,
			69.519,
			73.206,
			78.739,
			82.431,
			84.277,
			87.973,
			91.673,
			93.524,
			97.229,
			100.936,
			106.502,
			110.216,
			115.791,
			117.65,
			119.511,
			125.097,
			128.826,
			132.559,
			138.163,
			141.902,
			147.516,
			149.388,
			151.262,
			156.884,
			160.633,
			166.258,
			170.009,
			175.64,
			179.397,
			183.158,
			185.04,
			190.691,
			194.463,
			198.236,
			203.897,
			207.672,
			213.336,
			215.225,
			217.114,
			222.785,
			226.568,
			232.246,
			236.034,
			239.825,
			241.721,
			245.515,
			247.413,
			249.312,
			255.01,
			258.811,
			264.516,
			268.322,
			274.032,
			277.842,
			281.653,
			283.56,
			287.375,
			289.283,
			293.1,
			296.919,
			302.652,
			306.477,
			312.218,
			314.133,
			316.048,
			321.796,
			325.629,
			331.383,
			335.222,
			339.064,
			340.986,
			344.829,
			346.751,
			350.596,
			354.443,
			356.367,
			358.292,
			364.067,
			369.848,
			371.775,
			373.703,
			377.559,
			381.416,
			383.346,
			387.205,
			389.136,
			392.998,
			396.862,
			402.659,
			406.526,
			412.326,
			414.257,
			416.184,
			421.933,
			425.73,
			431.353,
			435.05,
			438.707,
			440.52,
			444.117,
			445.901,
			449.438,
			454.668,
			458.104,
			461.501,
			466.523,
			468.177,
			469.822,
			473.083,
			476.304,
			477.901,
			482.633,
			485.74,
			490.327,
			493.339,
			496.315,
			500.706,
			502.151,
			503.586,
			507.833,
			509.23,
			510.617,
			514.722,
			517.411,
			520.062,
			521.373,
			523.966,
			526.522,
			527.785,
			530.284,
			531.52,
			533.963,
			536.37,
			539.915,
			542.235,
			545.645,
			546.762,
			547.87,
			551.138,
			553.269,
			556.396,
			558.434,
			560.435,
			561.421,
			563.365,
			564.324,
			565.273,
			568.065,
			568.978,
			569.883,
			572.546,
			574.278,
			576.809,
			578.45,
			580.054,
			580.842,
			582.391,
			583.151,
			584.645,
			586.102,
			588.221,
			589.592,
			591.584,
			592.231,
			592.871,
			594.738,
			595.935,
			597.677,
			598.8,
			599.894,
			600.432,
			601.488,
			602.009,
			603.039,
			604.041,
			604.534,
			605.509,
			606.475,
			607.919,
			608.4,
			608.88,
			610.302,
			610.774,
			611.244,
			612.184,
			612.655,
			613.6,
			614.744,
			616.463,
			617.608,
			619.451,
			620.138,
			620.824,
			622.883,
			624.251,
			626.196,
			627.495,
			628.799,
			629.443,
			630.575,
			631.142,
			632.279,
			633.418,
			633.988,
			635.104,
			636.167,
			637.76,
			638.283,
			638.801,
			640.33,
			640.827,
			641.322,
			642.801,
			643.782,
			645.261,
			646.31,
			647.348,
			648.892,
			649.405,
			649.917,
			651.715,
			652.965,
			654.829,
			656.125,
			658.276,
			659.702,
			661.138,
			661.87,
			663.327,
			664.052,
			665.501,
			666.848,
			668.781,
			670.068,
			671.86,
			672.366,
			672.872,
			674.391,
			675.407,
			676.922,
			677.894,
			679.356,
			680.336,
			680.828,
			681.321,
			682.828,
			683.339,
			683.85,
			685.393,
			686.432,
			688.028,
			689.111,
			690.203,
			690.753,
			691.86,
			692.419,
			693.558,
			694.712,
			696.468,
			697.655,
			699.503,
			700.128,
			700.757,
			702.667,
			703.956,
			705.905,
			707.227,
			708.563,
			709.245,
			710.586,
			711.267,
			712.637,
			714.015,
			714.709,
			716.107,
			717.519,
			718.944,
			721.117,
			722.556,
			724.033,
			724.777,
			726.264,
			727.018,
			728.546,
			730.08,
			732.395,
			733.957,
			736.299,
			737.873,
			740.238,
			741.829,
			744.243,
			745.87,
			747.51,
			748.336,
			750.015,
			751.684,
			752.529,
			754.216,
			755.062,
			756.757,
			758.463,
			761.045,
			761.911,
			762.794,
			765.408,
			766.284,
			767.162,
			769.802,
			771.58,
			773.369,
			776.093,
			777.905,
			780.672,
			781.58,
			782.504,
			786.213,
			787.139,
			789.926,
			791.791,
			793.664,
			794.606,
			796.498,
			798.404,
			799.362,
			802.259,
			804.21,
			806.174,
			809.168,
			811.179,
			814.234,
			815.258,
			816.286,
			819.348,
			821.404,
			823.443,
			826.522,
			828.584,
			829.619,
			831.698,
			832.742,
			833.789,
			836.932,
			837.98,
			839.03,
			842.192,
			844.313,
			847.514,
			849.659,
			851.814,
			852.895,
			855.063,
			856.151,
			858.334,
			860.527,
			863.834,
			866.053,
			869.399,
			870.519,
			871.642,
			875.025,
			877.292,
			880.711,
			883.001,
			886.453,
			888.766,
			889.926,
			892.255,
			894.593,
			895.766,
			896.942,
			900.485,
			902.856,
			905.237,
			906.43,
			908.823,
			911.226,
			912.432,
			914.851,
			916.065,
			918.5,
			920.944,
			922.17,
			924.629,
			927.097,
			930.815,
			932.059,
			933.305,
			937.056,
			939.567,
			943.35,
			945.882,
			948.422,
			949.695,
			952.247,
			953.526,
			956.09,
			959.95,
			962.531,
			965.118,
			969.008,
			970.307,
			971.605,
			975.492,
			976.79,
			978.092,
			980.7,
			982.007,
			984.63,
			988.581,
			991.227,
			993.881,
			997.878,
			999.213,
			1000.551,
			1004.574,
			1007.267,
			1011.319,
			1014.053,
			1016.772,
			1018.112,
			1020.844,
			1023.583,
			1024.955,
			1027.705,
			1029.083,
			1031.867,
			1034.635,
			1038.776,
			1040.168,
			1041.562,
			1045.755,
			1047.156,
			1048.559,
			1052.801,
			1055.621,
			1059.838,
			1062.674,
			1065.517,
			1066.941,
			1069.819,
			1071.249,
			1072.68,
			1076.986,
			1078.426,
			1079.867,
			1084.203,
			1087.08,
			1089.99,
			1091.473,
			1094.395,
			1097.323,
			1098.79,
			1101.727,
			1103.195,
			1106.134,
			1109.078,
			1113.505,
			1116.463,
			1120.913,
			1122.4,
			1123.889,
			1128.371,
			1131.365,
			1135.865,
			1138.869,
			1141.876,
			1143.381,
			1146.396,
			1149.418,
			1150.906,
			1153.941,
			1155.46,
			1157.007,
			1161.576,
			1166.152,
			1169.208,
			1172.245,
			1175.313,
			1176.85,
			1179.953,
			1181.494,
			1184.581,
			1187.677,
			1192.337,
			1195.455,
			1200.136,
			1201.698,
			1203.261,
			1207.932,
			1211.074,
			1215.82,
			1218.969,
			1222.119,
			1223.695,
			1226.823,
			1228.402,
			1231.562,
			1234.726,
			1236.31,
			1239.507,
			1242.683,
			1247.43,
			1249.023,
			1250.62,
			1253.843,
			1257.044,
			1258.617,
			1261.821,
			1263.424,
			1266.633,
			1269.846,
			1274.676,
			1277.903,
			1282.745,
			1284.387,
			1285.975,
			1290.821,
			1294.057,
			1298.924,
			1302.177,
			1305.465,
			1307.098,
			1310.343,
			1311.98,
			1315.255,
			1320.164,
			1323.438,
			1326.716,
			1331.67,
			1333.317,
			1334.967,
			1339.897,
			1341.553,
			1343.209,
			1348.18,
			1351.496,
			1356.475,
			1359.799,
			1363.127,
			1364.791,
			1368.119,
			1369.783,
			1371.447,
			1378.109,
			1379.776,
			1384.786,
			1388.13,
			1391.477,
			1393.152,
			1396.505,
			1399.861,
			1401.541,
			1404.901,
			1406.583,
			1409.948,
			1413.317,
			1418.375,
			1421.752,
			1426.821,
			1428.511,
			1430.202,
			1435.279,
			1438.666,
			1443.755,
			1447.155,
			1450.559,
			1452.264,
			1455.676,
			1457.385,
			1460.804,
			1464.227,
			1465.937,
			1469.358,
			1472.78,
			1476.205,
			1479.636,
			1481.355,
			1484.796,
			1488.242,
			1489.967,
			1493.419,
			1495.147,
			1498.605,
			1502.066,
			1507.265,
			1510.735,
			1515.948,
			1517.688,
			1519.428,
			1524.653,
			1528.139,
			1533.373,
			1536.865,
			1540.36,
			1542.109,
			1545.609,
			1547.361,
			1550.865,
			1556.127,
			1559.641,
			1563.157,
			1568.437,
			1571.962,
			1577.254,
			1579.02,
			1580.786,
			1584.322,
			1586.09,
			1589.631,
			1593.174,
			1598.496,
			1602.047,
			1607.379,
			1609.159,
			1610.939,
			1616.283,
			1619.849,
			1625.205,
			1628.779,
			1632.355,
			1634.145,
			1637.726,
			1639.518,
			1643.104,
			1648.487,
			1652.078,
			1655.667,
			1661.027,
			1662.805,
			1664.576,
			1669.836,
			1671.57,
			1673.292,
			1678.398,
			1681.75,
			1686.704,
			1689.957,
			1693.171,
			1694.764,
			1697.92,
			1699.483,
			1701.037,
			1705.64,
			1707.156,
			1708.662,
			1713.13,
			1716.062,
			1718.956,
			1720.388,
			1723.224,
			1726.02,
			1727.404,
			1730.144,
			1731.499,
			1734.181,
			1736.825,
			1740.721,
			1743.272,
			1747.029,
			1748.263,
			1749.488,
			1753.109,
			1755.479,
			1758.966,
			1761.244,
			1764.59,
			1766.773,
			1767.851,
			1768.92,
			1772.069,
			1773.101,
			1774.123,
			1777.137,
			1779.103,
			1781.031,
			1781.991,
			1783.882,
			1785.738,
			1786.651,
			1788.448,
			1789.334,
			1791.08,
			1792.772,
			1795.237,
			1796.835,
			1799.168,
			1799.928,
			1800.678,
			1802.894,
			1804.318,
			1806.392,
			1807.732,
			1809.039,
			1809.683,
			1810.95,
			1811.573,
			1812.789,
			1813.977,
			1814.563,
			1815.723,
			1816.869,
			1818.563,
			1819.122,
			1819.679,
			1821.347,
			1821.902,
			1822.487,
			1823.663,
			1824.25,
			1825.421,
			1826.593,
			1828.415,
			1829.642,
			1831.488,
			1832.105,
			1832.746,
			1834.679,
			1835.971,
			1837.958,
			1839.327,
			1840.702,
			1841.394,
			1842.753,
			1843.401,
			1844.702,
			1846.013,
			1846.672,
			1847.968,
			1849.237,
			1851.151,
			1852.453,
			1854.405,
			1855.064,
			1855.727,
			1857.738,
			1859.095,
			1861.149,
			1862.54,
			1864.012,
			1866.237,
			1866.983,
			1867.732,
			1871.051,
			1871.887,
			1874.463,
			1876.212,
			1877.959,
			1878.769,
			1880.389,
			1881.201,
			1882.829,
			1884.32,
			1885.062,
			1886.55,
			1888.042,
			1890.223,
			1891.681,
			1893.892,
			1894.643,
			1895.395,
			1897.66,
			1899.206,
			1901.563,
			1903.146,
			1904.751,
			1905.559,
			1907.184,
			1908,
			1909.642,
			1911.293,
			1912.121,
			1913.78,
			1915.441,
			1917.095,
			1919.622,
			1921.32,
			1923.027,
			1923.884,
			1925.609,
			1926.476,
			1928.229,
			1929.96,
			1932.594,
			1934.358,
			1936.998,
			1937.896,
			1938.767,
			1941.436,
			1943.222,
			1945.913,
			1947.723,
			1949.547,
			1950.465,
			1952.312,
			1953.237,
			1955.094,
			1956.952,
			1957.885,
			1959.758,
			1961.638,
			1964.475,
			1966.382,
			1968.299,
			1970.229,
			1971.198,
			1974.125,
			1976.092,
			1978.072,
			1981.065,
			1983.076,
			1986.115,
			1988.156,
			1991.24,
			1993.311,
			1996.438,
			1998.539,
			2000.643,
			2001.694,
			2003.805,
			2004.865,
			2006.993,
			2009.127,
			2010.198,
			2012.348,
			2014.507,
			2017.764,
			2018.857,
			2019.953,
			2023.258,
			2024.363,
			2025.47,
			2028.805,
			2031.026,
			2034.371,
			2036.606,
			2038.851,
			2039.979,
			2042.245,
			2043.382,
			2044.522,
			2047.969,
			2050.28,
			2053.768,
			2056.104,
			2059.624,
			2061.982,
			2064.349,
			2065.537,
			2067.918,
			2069.113,
			2071.508,
			2073.912,
			2077.537,
			2079.965,
			2083.623,
			2084.847,
			2086.073,
			2089.766,
			2092.238,
			2095.965,
			2098.46,
			2100.965,
			2102.22,
			2104.738,
			2106,
			2108.533,
			2111.076,
			2112.351,
			2113.627,
			2117.469,
			2120.038,
			2122.608,
			2123.889,
			2126.438,
			2128.96,
			2130.208,
			2132.675,
			2133.895,
			2136.307,
			2138.681,
			2142.175,
			2144.456,
			2147.807,
			2148.904,
			2149.989,
			2153.188,
			2155.293,
			2158.358,
			2160.367,
			2162.346,
			2163.324,
			2165.262,
			2166.222,
			2168.129,
			2170.02,
			2170.96,
			2172.836,
			2174.704,
			2176.557,
			2178.406,
			2179.33,
			2181.179,
			2183.027,
			2183.951,
			2185.811,
			2186.744,
			2188.61,
			2190.482,
			2193.315,
			2195.203,
			2198.052,
			2199.001,
			2199.951,
			2202.813,
			2204.724,
			2207.63,
			2209.576,
			2211.542,
			2212.531,
			2214.512,
			2215.505,
			2217.476,
			2219.451,
			2220.443,
			2222.431,
			2224.428,
			2227.464,
			2228.485,
			2229.509,
			2232.602,
			2233.64,
			2234.68,
			2237.822,
			2239.929,
			2243.101,
			2245.221,
			2248.406,
			2250.532,
			2251.597,
			2252.658,
			2255.843,
			2256.906,
			2257.969,
			2261.159,
			2263.291,
			2266.49,
			2268.625,
			2269.692,
			2271.827,
			2273.957,
			2275.021,
			2277.149,
			2279.278,
			2282.47,
			2284.583,
			2287.747,
			2288.794,
			2289.839,
			2292.973,
			2295.059,
			2298.183,
			2300.26,
			2302.334,
			2303.371,
			2305.437,
			2306.468,
			2308.528,
			2310.587,
			2311.616,
			2313.672,
			2315.727,
			2317.78,
			2319.834,
			2320.862,
			2322.918,
			2324.963,
			2325.998,
			2328.073,
			2329.113,
			2331.219,
			2333.336,
			2336.562,
			2338.715,
			2341.953,
			2343.043,
			2344.136,
			2347.416,
			2349.633,
			2352.914,
			2355.138,
			2357.344,
			2358.431,
			2360.663,
			2361.757,
			2363.986,
			2367.344,
			2369.589,
			2371.84,
			2374.113,
			2377.49,
			2379.761,
			2382.056,
			2383.177,
			2386.624,
			2388.915,
			2391.19,
			2394.665,
			2396.961,
			2400.451,
			2401.619,
			2402.789,
			2406.314,
			2408.677,
			2412.24,
			2414.627,
			2417.024,
			2418.225,
			2420.635,
			2421.843,
			2424.267,
			2426.699,
			2427.919,
			2430.365,
			2432.82,
			2436.52,
			2438.997,
			2442.729,
			2443.978,
			2445.229,
			2447.738,
			2448.995,
			2451.515,
			2455.311,
			2457.852,
			2460.4,
			2461.677,
			2464.238,
			2465.522,
			2466.808,
			2470.677,
			2471.971,
			2473.267,
			2477.163,
			2479.756,
			2482.332,
			2483.609,
			2486.139,
			2488.63,
			2489.862,
			2492.294,
			2493.497,
			2495.872,
			2498.209,
			2501.652,
			2503.907,
			2507.23,
			2508.322,
			2509.404,
			2512.584,
			2514.655,
			2517.672,
			2519.633,
			2521.574,
			2522.524,
			2524.411,
			2525.351,
			2527.225,
			2529.097,
			2530.033,
			2531.907,
			2533.787,
			2535.673,
			2537.557,
			2538.498,
			2540.385,
			2542.278,
			2543.226,
			2545.125,
			2546.076,
			2548.015,
			2549.968,
			2552.951,
			2554.983,
			2558.092,
			2559.154,
			2560.214,
			2563.401,
			2565.527,
			2568.666,
			2570.716,
			2572.728,
			2573.695,
			2575.612,
			2576.561,
			2578.412,
			2580.217,
			2581.112,
			2582.887,
			2584.628,
			2586.354,
			2588.069,
			2588.921,
			2590.619,
			2592.312,
			2593.158,
			2594.845,
			2595.687,
			2597.369,
			2599.05,
			2601.569,
			2603.25,
			2605.798,
			2606.648,
			2607.498,
			2610.077,
			2610.942,
			2611.809,
			2614.408,
			2616.168,
			2617.939,
			2618.83,
			2620.618,
			2621.532,
			2623.338,
			2625.141,
			2626.055,
			2627.892,
			2629.742,
			2632.551,
			2634.405,
			2637.236,
			2638.165,
			2639.111,
			2641.965,
			2643.877,
			2645.796,
			2648.69,
			2650.628,
			2653.565,
			2654.527,
			2655.509,
			2658.466,
			2660.449,
			2663.436,
			2665.44,
			2667.451,
			2668.46,
			2670.488,
			2672.529,
			2673.554,
			2675.612,
			2676.648,
			2678.73,
			2680.822,
			2683.972,
			2686.072,
			2689.239,
			2690.3,
			2691.363,
			2694.568,
			2696.717,
			2699.958,
			2702.132,
			2704.315,
			2705.41,
			2707.609,
			2708.711,
			2710.924,
			2713.147,
			2714.262,
			2715.38,
			2718.748,
			2721.005,
			2723.272,
			2724.409,
			2726.691,
			2728.983,
			2730.132,
			2732.438,
			2733.595,
			2735.917,
			2738.248,
			2741.764,
			2744.12,
			2747.673,
			2748.861,
			2750.053,
			2753.642,
			2756.046,
			2759.67,
			2762.096,
			2764.53,
			2765.75,
			2768.194,
			2769.42,
			2771.877,
			2774.343,
			2775.579,
			2778.059,
			2780.548,
			2783.048,
			2786.813,
			2789.334,
			2791.864,
			2793.133,
			2796.95,
			2799.505,
			2802.068,
			2805.927,
			2808.51,
			2812.401,
			2813.701,
			2815.004,
			2818.915,
			2821.525,
			2825.455,
			2828.084,
			2832.042,
			2834.69,
			2836.017,
			2838.675,
			2841.34,
			2842.676,
			2845.351,
			2848.034,
			2852.071,
			2853.42,
			2854.771,
			2858.834,
			2860.192,
			2861.552,
			2865.641,
			2868.375,
			2872.491,
			2875.243,
			2878.001,
			2882.153,
			2883.54,
			2884.929,
			2889.104,
			2891.897,
			2896.098,
			2898.907,
			2901.722,
			2903.132,
			2905.958,
			2908.793,
			2910.213,
			2913.064,
			2914.494,
			2917.36,
			2920.242,
			2924.582,
			2927.486,
			2931.865,
			2933.325,
			2934.787,
			2939.18,
			2942.114,
			2946.51,
			2949.442,
			2952.377,
			2953.846,
			2956.787,
			2958.26,
			2961.209,
			2964.165,
			2965.646,
			2967.129,
			2971.591,
			2974.577,
			2977.571,
			2979.071,
			2982.072,
			2985.078,
			2986.583,
			2991.108,
			2994.133,
			2997.162,
			3001.711,
			3004.749,
			3009.315,
			3010.841,
			3012.368,
			3016.957,
			3018.489,
			3020.022,
			3024.629,
			3027.708,
			3032.335,
			3035.428,
			3036.977,
			3040.081,
			3043.191,
			3044.748,
			3047.867,
			3050.991,
			3055.685,
			3057.252,
			3058.82,
			3061.958,
			3065.101,
			3066.674,
			3071.403,
			3074.562,
			3077.728,
			3082.486,
			3085.665,
			3090.444,
			3092.037,
			3093.631,
			3098.421,
			3101.62,
			3106.426,
			3109.636,
			3112.85,
			3114.458,
			3117.679,
			3119.292,
			3122.522,
			3125.757,
			3127.377,
			3130.619,
			3133.867,
			3138.747,
			3142.006,
			3145.271,
			3148.539,
			3150.175,
			3155.09,
			3158.372,
			3161.658,
			3166.591,
			3169.883,
			3174.832,
			3176.484,
			3178.138,
			3184.766,
			3186.427,
			3191.418,
			3194.754,
			3198.094,
			3199.766,
			3203.111,
			3204.784,
			3208.135,
			3211.489,
			3213.167,
			3216.526,
			3219.889,
			3224.942,
			3228.317,
			3233.385,
			3235.077,
			3236.771,
			3241.855,
			3245.251,
			3250.352,
			3253.758,
			3257.168,
			3262.291,
			3264,
			3265.711,
			3270.847,
			3272.562,
			3274.277,
			3279.428,
			3282.867,
			3288.034,
			3291.483,
			3294.936,
			3296.664,
			3300.122,
			3301.852,
			3305.315,
			3308.782,
			3313.989,
			3317.466,
			3322.688,
			3324.43,
			3326.173,
			3331.408,
			3334.903,
			3340.154,
			3343.659,
			3347.169,
			3348.925,
			3352.446,
			3354.208,
			3357.738,
			3361.266,
			3363.03,
			3366.556,
			3370.082,
			3375.376,
			3378.91,
			3382.447,
			3385.986,
			3387.757,
			3391.301,
			3393.075,
			3396.626,
			3400.18,
			3405.513,
			3409.07,
			3414.41,
			3416.193,
			3417.977,
			3423.34,
			3426.921,
			3432.296,
			3435.882,
			3439.469,
			3441.265,
			3444.857,
			3446.655,
			3450.252,
			3453.851,
			3455.651,
			3459.256,
			3462.866,
			3468.286,
			3470.094,
			3471.9,
			3477.304,
			3479.097,
			3480.884,
			3486.2,
			3489.7,
			3493.16,
			3498.277,
			3501.639,
			3506.608,
			3508.244,
			3509.872,
			3514.694,
			3516.282,
			3517.86,
			3522.536,
			3525.605,
			3530.14,
			3533.119,
			3536.059,
			3537.515,
			3540.396,
			3541.823,
			3544.646,
			3547.431,
			3551.537,
			3554.226,
			3558.188,
			3559.489,
			3560.781,
			3564.6,
			3567.097,
			3570.773,
			3573.178,
			3576.719,
			3579.037,
			3580.181,
			3582.441,
			3584.665,
			3585.761,
			3586.85,
			3590.059,
			3592.153,
			3594.232,
			3595.249,
			3597.258,
			3599.236,
			3600.215,
			3602.155,
			3603.114,
			3605.015,
			3606.894,
			3609.662,
			3611.489,
			3614.196,
			3615.093,
			3615.989,
			3618.661,
			3620.432,
			3623.112,
			3624.944,
			3627.708,
			3629.662,
			3630.639,
			3632.597,
			3634.659,
			3635.69,
			3636.723,
			3639.809,
			3642.888,
			3643.871,
			3644.855,
			3647.812,
			3648.756,
			3649.7,
			3651.592,
			3652.541,
			3654.404,
			3656.255,
			3659.042,
			3660.906,
			3663.713,
			3664.655,
			3665.598,
			3668.443,
			3670.365,
			3673.271,
			3675.241,
			3677.236,
			3678.238,
			3680.246,
			3681.276,
			3683.341,
			3685.412,
			3686.444,
			3688.515,
			3690.592,
			3693.719,
			3694.76,
			3695.813,
			3698.991,
			3700.043,
			3701.101,
			3703.213,
			3704.279,
			3706.361,
			3708.419,
			3711.487,
			3713.487,
			3716.479,
			3717.489,
			3718.497,
			3721.512,
			3722.521,
			3723.528,
			3726.541,
			3728.519,
			3730.491,
			3731.476,
			3733.451,
			3734.436,
			3736.406,
			3739.377,
			3741.36,
			3743.346,
			3746.328,
			3748.317,
			3751.3,
			3752.295,
			3753.281,
			3756.237,
			3758.209,
			3761.168,
			3763.146,
			3765.135,
			3766.13,
			3768.121,
			3769.124,
			3771.137,
			3773.141,
			3774.157,
			3775.182,
			3778.273,
			3780.376,
			3782.463,
			3783.52,
			3785.65,
			3787.769,
			3788.832,
			3790.931,
			3791.98,
			3794.07,
			3796.14,
			3799.246,
			3801.318,
			3804.394,
			3805.414,
			3806.431,
			3809.472,
			3811.487,
			3814.494,
			3816.531,
			3818.582,
			3819.605,
			3821.816,
			3822.955,
			3825.233,
			3827.739,
			3828.994,
			3831.429,
			3833.772,
			3836.054,
			3838.157,
			3839.208,
			3841.278,
			3843.274,
			3844.271,
			3847.262,
			3849.256,
			3851.256,
			3854.291,
			3856.325,
			3859.432,
			3860.472,
			3861.515,
			3864.681,
			3866.804,
			3870.003,
			3872.148,
			3874.307,
			3875.39,
			3877.564,
			3878.656,
			3880.848,
			3884.136,
			3886.367,
			3888.584,
			3891.925,
			3894.163,
			3896.391,
			3898.665,
			3899.779,
			3902.052,
			3903.192,
			3905.48,
			3908.93,
			3911.24,
			3913.559,
			3917.056,
			3918.226,
			3919.398,
			3922.928,
			3924.109,
			3925.294,
			3928.861,
			3931.25,
			3933.667,
			3934.869,
			3937.254,
			3938.478,
			3940.888,
			3943.314,
			3944.53,
			3946.963,
			3949.405,
			3953.078,
			3954.303,
			3955.51,
			3959.202,
			3960.456,
			3961.69,
			3965.386,
			3967.873,
			3971.619,
			3974.128,
			3976.647,
			3980.434,
			3981.7,
			3982.968,
			3988.055,
			3989.333,
			3993.177,
			3995.755,
			3999.638,
			4002.238,
			4004.846,
			4006.15,
			4008.76,
			4010.07,
			4012.696,
			4015.331,
			4019.299,
			4021.948,
			4025.933,
			4027.264,
			4028.597,
			4032.604,
			4035.283,
			4039.312,
			4042.005,
			4044.704,
			4046.057,
			4048.765,
			4050.121,
			4052.84,
			4055.568,
			4056.934,
			4059.676,
			4062.426,
			4065.183,
			4067.948,
			4069.334,
			4072.11,
			4074.895,
			4076.289,
			4080.484,
			4083.288,
			4086.101,
			4090.332,
			4093.16,
			4097.415,
			4098.837,
			4100.261,
			4104.541,
			4107.403,
			4111.709,
			4114.588,
			4117.474,
			4118.919,
			4121.815,
			4123.266,
			4126.171,
			4129.084,
			4130.543,
			4133.465,
			4136.394,
			4140.799,
			4142.271,
			4143.744,
			4148.173,
			4149.654,
			4151.136,
			4154.106,
			4155.593,
			4158.572,
			4161.556,
			4166.042,
			4169.039,
			4173.546,
			4175.052,
			4176.56,
			4181.09,
			4184.118,
			4188.67,
			4191.713,
			4194.762,
			4196.288,
			4199.345,
			4200.875,
			4203.94,
			4207.011,
			4208.549,
			4211.629,
			4214.715,
			4219.354,
			4222.453,
			4227.113,
			4228.669,
			4230.226,
			4234.906,
			4238.033,
			4242.732,
			4245.872,
			4249.017,
			4250.591,
			4253.744,
			4255.322,
			4256.902,
			4261.649,
			4263.234,
			4264.82,
			4269.585,
			4272.769,
			4277.548,
			4280.737,
			4282.333,
			4285.529,
			4290.331,
			4293.538,
			4296.75,
			4301.574,
			4304.796,
			4309.638,
			4311.254,
			4312.872,
			4317.73,
			4320.975,
			4325.849,
			4329.104,
			4333.995,
			4337.261,
			4338.896,
			4342.168,
			4345.445,
			4347.085,
			4350.368,
			4353.655,
			4358.594,
			4360.242,
			4361.892,
			4365.193,
			4368.5,
			4370.155,
			4373.468,
			4375.126,
			4378.445,
			4381.769,
			4386.761,
			4390.094,
			4395.103,
			4396.774,
			4398.447,
			4403.471,
			4406.825,
			4411.863,
			4415.227,
			4418.594,
			4420.28,
			4423.653,
			4425.342,
			4428.723,
			4432.107,
			4433.801,
			4437.191,
			4440.586,
			4445.685,
			4447.386,
			4449.089,
			4452.499,
			4455.911,
			4457.619,
			4462.748,
			4466.172,
			4469.6,
			4474.747,
			4478.185,
			4483.348,
			4485.071,
			4486.795,
			4491.973,
			4495.43,
			4500.622,
			4504.087,
			4507.556,
			4509.292,
			4512.767,
			4516.245,
			4517.985,
			4523.213,
			4526.703,
			4530.196,
			4535.443,
			4538.947,
			4544.211,
			4545.968,
			4547.725,
			4553.002,
			4556.522,
			4561.809,
			4565.337,
			4568.869,
			4570.637,
			4574.174,
			4575.945,
			4577.716,
			4583.035,
			4586.588,
			4591.927,
			4595.489,
			4599.054,
			4600.836,
			4604.405,
			4607.975,
			4609.761,
			4613.335,
			4615.124,
			4618.703,
			4622.29,
			4627.681,
			4631.279,
			4636.68,
			4638.481,
			4640.283,
			4645.693,
			4649.309,
			4654.74,
			4658.358,
			4663.79,
			4667.413,
			4669.225,
			4671.037,
			4676.466,
			4678.269,
			4680.066,
			4685.413,
			4688.933,
			4694.14,
			4697.56,
			4700.939,
			4702.613,
			4705.932,
			4707.576,
			4710.836,
			4714.06,
			4718.825,
			4721.954,
			4726.576,
			4728.098,
			4729.61,
			4734.096,
			4737.042,
			4741.39,
			4744.239,
			4747.051,
			4748.442,
			4751.193,
			4752.553,
			4755.244,
			4757.895,
			4759.206,
			4761.802,
			4764.362,
			4768.136,
			4770.605,
			4774.244,
			4775.438,
			4776.625,
			4778.974,
			4780.136,
			4782.435,
			4785.835,
			4788.044,
			4790.224,
			4793.442,
			4794.5,
			4795.552,
			4798.676,
			4800.73,
			4803.805,
			4805.855,
			4808.938,
			4811.005,
			4812.04,
			4814.112,
			4816.206,
			4817.252,
			4819.351,
			4821.465,
			4824.639,
			4826.764,
			4829.955,
			4831.025,
			4832.094,
			4835.307,
			4837.456,
			4840.68,
			4842.83,
			4844.978,
			4846.054,
			4848.201,
			4849.273,
			4851.424,
			4853.578,
			4854.653,
			4855.729,
			4858.958,
			4861.111,
			4864.339,
			4866.493,
			4868.65,
			4869.729,
			4871.888,
			4872.968,
			4875.127,
			4877.292,
			4880.553,
			4882.723,
			4885.969,
			4887.052,
			4888.139,
			4891.399,
			4893.571,
			4896.823,
			4898.99,
			4901.152,
			4902.232,
			4904.396,
			4905.479,
			4907.641,
			4909.813,
			4910.901,
			4913.076,
			4915.261,
			4917.449,
			4919.63,
			4920.71,
			4922.869,
			4925.028,
			4926.094,
			4929.299,
			4931.442,
			4933.589,
			4936.828,
			4938.999,
			4942.264,
			4943.353,
			4944.445,
			4947.732,
			4949.93,
			4953.241,
			4955.456,
			4957.675,
			4958.786,
			4961.012,
			4963.245,
			4964.364,
			4966.608,
			4967.732,
			4969.985,
			4972.246,
			4975.636,
			4976.769,
			4977.903,
			4980.156,
			4982.411,
			4983.543,
			4986.953,
			4989.236,
			4991.526,
			4994.968,
			4997.267,
			5000.706,
			5001.851,
			5002.998,
			5006.439,
			5008.738,
			5012.23,
			5014.577,
			5016.941,
			5018.128,
			5020.506,
			5022.893,
			5024.089,
			5026.487,
			5027.686,
			5030.089,
			5032.482,
			5036.07,
			5038.447,
			5042.021,
			5043.231,
			5044.442,
			5048.094,
			5050.546,
			5054.248,
			5056.736,
			5059.234,
			5063.013,
			5064.277,
			5065.541,
			5069.341,
			5070.607,
			5071.87,
			5075.668,
			5078.2,
			5080.737,
			5082.007,
			5084.546,
			5087.094,
			5088.373,
			5090.935,
			5092.22,
			5094.809,
			5097.408,
			5101.361,
			5103.986,
			5107.991,
			5109.309,
			5110.628,
			5114.59,
			5117.231,
			5121.203,
			5123.882,
			5127.913,
			5130.629,
			5131.977,
			5134.677,
			5137.383,
			5138.738,
			5140.072,
			5144.155,
			5146.886,
			5150.995,
			5153.764,
			5156.517,
			5157.896,
			5160.639,
			5162.025,
			5164.801,
			5167.581,
			5171.778,
			5174.562,
			5178.727,
			5180.125,
			5181.525,
			5185.777,
			5188.629,
			5192.948,
			5195.837,
			5198.732,
			5200.177,
			5203.06,
			5204.503,
			5207.409,
			5210.269,
			5211.72,
			5214.629,
			5217.556,
			5221.964,
			5223.438,
			5224.912,
			5229.333,
			5230.808,
			5232.281,
			5235.218,
			5236.689,
			5239.639,
			5242.597,
			5247.067,
			5250.068,
			5254.578,
			5256.083,
			5257.59,
			5262.114,
			5265.134,
			5269.674,
			5272.707,
			5275.743,
			5277.263,
			5280.307,
			5283.357,
			5284.886,
			5287.947,
			5289.479,
			5292.549,
			5295.625,
			5300.25,
			5301.794,
			5303.34,
			5307.984,
			5309.536,
			5311.087,
			5315.75,
			5318.866,
			5321.986,
			5326.677,
			5329.81,
			5334.519,
			5336.09,
			5337.664,
			5342.391,
			5343.97,
			5345.549,
			5350.298,
			5353.471,
			5356.651,
			5358.243,
			5361.435,
			5364.629,
			5366.23,
			5369.435,
			5371.038,
			5374.247,
			5377.458,
			5382.279,
			5385.494,
			5390.326,
			5391.939,
			5393.553,
			5398.414,
			5401.665,
			5406.556,
			5409.821,
			5413.088,
			5414.72,
			5417.986,
			5419.616,
			5421.247,
			5426.143,
			5427.775,
			5429.412,
			5434.331,
			5437.621,
			5440.914,
			5442.562,
			5445.86,
			5449.16,
			5450.811,
			5455.77,
			5459.085,
			5464.074,
			5467.4,
			5470.73,
			5475.725,
			5477.392,
			5479.058,
			5484.062,
			5487.4,
			5492.421,
			5495.776,
			5500.822,
			5504.188,
			5505.873,
			5509.241,
			5512.61,
			5514.294,
			5517.664,
			5521.036,
			5524.413,
			5529.488,
			5534.575,
			5536.272,
			5537.971,
			5541.372,
			5543.073,
			5546.479,
			5549.89,
			5555.013,
			5558.433,
			5563.569,
			5565.284,
			5566.999,
			5572.15,
			5575.588,
			5580.752,
			5584.2,
			5587.651,
			5589.378,
			5592.834,
			5596.294,
			5598.026,
			5601.492,
			5603.227,
			5606.698,
			5610.173,
			5615.393,
			5617.134,
			5618.878,
			5624.114,
			5625.861,
			5627.609,
			5632.854,
			5636.351,
			5641.604,
			5645.11,
			5650.38,
			5653.899,
			5655.66,
			5657.421,
			5664.476,
			5666.241,
			5671.542,
			5675.081,
			5678.623,
			5680.396,
			5683.942,
			5687.492,
			5689.268,
			5694.602,
			5698.162,
			5701.726,
			5707.077,
			5710.649,
			5716.021,
			5717.813,
			5719.608,
			5724.996,
			5728.587,
			5733.969,
			5737.558,
			5741.148,
			5746.545,
			5748.346,
			5751.95,
			5755.559,
			5757.364,
			5759.171,
			5764.595,
			5768.215,
			5771.839,
			5773.651,
			5777.283,
			5780.919,
			5782.74,
			5783.34
		],
		[
			0,
			0.018,
			0.08,
			0.142,
			0.188,
			0.205,
			0.266,
			0.297,
			0.329,
			0.407,
			0.451,
			0.513,
			0.578,
			0.638,
			0.699,
			0.732,
			0.763,
			0.824,
			0.885,
			0.949,
			1.012,
			1.071,
			1.135,
			1.166,
			1.197,
			1.259,
			1.273,
			1.321,
			1.383,
			1.446,
			1.505,
			1.57,
			1.599,
			1.632,
			1.692,
			1.753,
			1.816,
			1.879,
			1.926,
			2.003,
			2.034,
			2.066,
			2.128,
			2.188,
			2.252,
			2.313,
			2.373,
			2.437,
			2.468,
			2.499,
			2.577,
			2.622,
			2.684,
			2.746,
			2.793,
			2.871,
			2.902,
			2.933,
			2.995,
			3.057,
			3.124,
			3.18,
			3.226,
			3.243,
			3.305,
			3.336,
			3.367,
			3.43,
			3.491,
			3.554,
			3.616,
			3.676,
			3.738,
			3.771,
			3.801,
			3.863,
			3.88,
			3.925,
			3.988,
			4.049,
			4.095,
			4.172,
			4.205,
			4.234,
			4.296,
			4.359,
			4.421,
			4.483,
			4.53,
			4.546,
			4.608,
			4.638,
			4.668,
			4.732,
			4.746,
			4.791,
			4.855,
			4.918,
			4.963,
			4.98,
			5.04,
			5.073,
			5.104,
			5.166,
			5.184,
			5.227,
			5.291,
			5.352,
			5.413,
			5.474,
			5.505,
			5.537,
			5.614,
			5.661,
			5.723,
			5.784,
			5.832,
			5.847,
			5.91,
			5.94,
			5.971,
			6.049,
			6.097,
			6.157,
			6.22,
			6.265,
			6.28,
			6.341,
			6.373,
			6.405,
			6.468,
			6.53,
			6.593,
			6.654,
			6.701,
			6.778,
			6.808,
			6.839,
			6.901,
			6.918,
			6.963,
			7.025,
			7.088,
			7.134,
			7.151,
			7.21,
			7.248,
			7.273,
			7.335,
			7.352,
			7.399,
			7.459,
			7.521,
			7.568,
			7.647,
			7.678,
			7.707,
			7.786,
			7.832,
			7.893,
			7.957,
			8.002,
			8.018,
			8.08,
			8.111,
			8.141,
			8.202,
			8.219,
			8.266,
			8.328,
			8.389,
			8.451,
			8.515,
			8.544,
			8.574,
			8.638,
			8.652,
			8.698,
			8.763,
			8.825,
			8.884,
			8.948,
			8.98,
			9.008,
			9.071,
			9.134,
			9.197,
			9.257,
			9.308,
			9.32,
			9.382,
			9.413,
			9.442,
			9.505,
			9.521,
			9.569,
			9.63,
			9.693,
			9.739,
			9.753,
			9.817,
			9.847,
			9.877,
			9.94,
			9.954,
			10.001,
			10.063,
			10.125,
			10.173,
			10.25,
			10.281,
			10.313,
			10.374,
			10.437,
			10.498,
			10.561,
			10.607,
			10.621,
			10.684,
			10.714,
			10.746,
			10.807,
			10.823,
			10.871,
			10.932,
			10.994,
			11.04,
			11.055,
			11.118,
			11.149,
			11.18,
			11.257,
			11.304,
			11.37,
			11.429,
			11.474,
			11.551,
			11.584,
			11.615,
			11.674,
			11.738,
			11.802,
			11.863,
			11.925,
			11.987,
			12.017,
			12.047,
			12.108,
			12.126,
			12.172,
			12.235,
			12.298,
			12.357,
			12.421,
			12.45,
			12.483,
			12.56,
			12.608,
			12.669,
			12.731,
			12.793,
			12.855,
			12.884,
			12.915,
			12.979,
			12.995,
			13.041,
			13.102,
			13.165,
			13.227,
			13.289,
			13.321,
			13.351,
			13.412,
			13.432,
			13.474,
			13.537,
			13.599,
			13.661,
			13.724,
			13.755,
			13.785,
			13.847,
			13.907,
			13.972,
			14.033,
			14.079,
			14.095,
			14.156,
			14.187,
			14.219,
			14.28,
			14.297,
			14.342,
			14.405,
			14.466,
			14.53,
			14.59,
			14.622,
			14.653,
			14.716,
			14.732,
			14.776,
			14.84,
			14.901,
			14.947,
			15.023,
			15.086,
			15.149,
			15.211,
			15.274,
			15.335,
			15.382,
			15.396,
			15.458,
			15.494,
			15.521,
			15.583,
			15.599,
			15.645,
			15.706,
			15.77,
			15.816,
			15.831,
			15.893,
			15.925,
			15.955,
			16.033,
			16.08,
			16.142,
			16.203,
			16.249,
			16.326,
			16.357,
			16.389,
			16.468,
			16.514,
			16.577,
			16.637,
			16.684,
			16.699,
			16.761,
			16.792,
			16.822,
			16.9,
			16.947,
			17.008,
			17.071,
			17.134,
			17.193,
			17.228,
			17.258,
			17.335,
			17.38,
			17.442,
			17.504,
			17.555,
			17.568,
			17.63,
			17.66,
			17.692,
			17.753,
			17.769,
			17.816,
			17.875,
			17.938,
			18.001,
			18.064,
			18.095,
			18.125,
			18.189,
			18.202,
			18.249,
			18.313,
			18.374,
			18.42,
			18.497,
			18.527,
			18.558,
			18.621,
			18.684,
			18.747,
			18.807,
			18.869,
			18.932,
			18.964,
			18.994,
			19.054,
			19.07,
			19.117,
			19.18,
			19.241,
			19.289,
			19.302,
			19.367,
			19.397,
			19.429,
			19.489,
			19.506,
			19.552,
			19.613,
			19.619,
			19.675,
			19.723,
			19.799,
			19.83,
			19.863,
			19.924,
			19.984,
			20.048,
			20.108,
			20.157,
			20.17,
			20.234,
			20.266,
			20.296,
			20.374,
			20.421,
			20.482,
			20.542,
			20.59,
			20.604,
			20.668,
			20.697,
			20.73,
			20.791,
			20.808,
			20.853,
			20.917,
			20.979,
			21.024,
			21.101,
			21.133,
			21.163,
			21.227,
			21.289,
			21.351,
			21.41,
			21.458,
			21.474,
			21.535,
			21.568,
			21.599,
			21.66,
			21.68,
			21.721,
			21.784,
			21.847,
			21.891,
			21.908,
			21.969,
			22.002,
			22.033,
			22.094,
			22.155,
			22.218,
			22.281,
			22.326,
			22.343,
			22.405,
			22.436,
			22.467,
			22.527,
			22.545,
			22.589,
			22.652,
			22.714,
			22.762,
			22.775,
			22.839,
			22.87,
			22.901,
			22.963,
			22.979,
			23.024,
			23.086,
			23.148,
			23.195,
			23.272,
			23.302,
			23.334,
			23.397,
			23.457,
			23.521,
			23.582,
			23.63,
			23.646,
			23.705,
			23.743,
			23.768,
			23.83,
			23.847,
			23.891,
			23.956,
			24.017,
			24.079,
			24.139,
			24.171,
			24.202,
			24.264,
			24.281,
			24.325,
			24.389,
			24.45,
			24.498,
			24.574,
			24.605,
			24.638,
			24.699,
			24.759,
			24.821,
			24.885,
			24.93,
			24.946,
			25.009,
			25.039,
			25.069,
			25.132,
			25.149,
			25.193,
			25.256,
			25.317,
			25.366,
			25.38,
			25.441,
			25.472,
			25.506,
			25.567,
			25.583,
			25.63,
			25.69,
			25.753,
			25.814,
			25.875,
			25.907,
			25.94,
			26.001,
			26.063,
			26.126,
			26.186,
			26.233,
			26.248,
			26.31,
			26.34,
			26.372,
			26.45,
			26.497,
			26.558,
			26.622,
			26.667,
			26.684,
			26.746,
			26.776,
			26.806,
			26.868,
			26.931,
			26.993,
			27.056,
			27.103,
			27.117,
			27.18,
			27.21,
			27.24,
			27.32,
			27.364,
			27.427,
			27.489,
			27.536,
			27.55,
			27.614,
			27.643,
			27.674,
			27.738,
			27.753,
			27.8,
			27.867,
			27.923,
			27.97,
			28.047,
			28.076,
			28.108,
			28.188,
			28.234,
			28.295,
			28.358,
			28.403,
			28.419,
			28.482,
			28.512,
			28.543,
			28.605,
			28.621,
			28.667,
			28.73,
			28.79,
			28.837,
			28.852,
			28.914,
			28.947,
			28.976,
			29.038,
			29.056,
			29.1,
			29.165,
			29.226,
			29.272,
			29.349,
			29.38,
			29.41,
			29.473,
			29.535,
			29.596,
			29.659,
			29.705,
			29.722,
			29.784,
			29.815,
			29.847,
			29.927,
			29.968,
			30.033,
			30.092,
			30.154,
			30.219,
			30.25,
			30.28,
			30.34,
			30.357,
			30.404,
			30.465,
			30.528,
			30.575,
			30.651,
			30.683,
			30.715,
			30.79,
			30.837,
			30.9,
			30.963,
			31.007,
			31.025,
			31.084,
			31.116,
			31.147,
			31.224,
			31.27,
			31.333,
			31.397,
			31.441,
			31.457,
			31.52,
			31.55,
			31.583,
			31.645,
			31.704,
			31.767,
			31.83,
			31.875,
			31.892,
			31.954,
			31.988,
			32.017,
			32.078,
			32.096,
			32.14,
			32.201,
			32.263,
			32.311,
			32.326,
			32.389,
			32.418,
			32.451,
			32.513,
			32.528,
			32.574,
			32.637,
			32.699,
			32.744,
			32.822,
			32.852,
			32.883,
			32.946,
			33.009,
			33.069,
			33.132,
			33.195,
			33.257,
			33.287,
			33.317,
			33.381,
			33.397,
			33.441,
			33.503,
			33.565,
			33.613,
			33.628,
			33.691,
			33.721,
			33.753,
			33.813,
			33.831,
			33.875,
			33.938,
			34.001,
			34.051,
			34.125,
			34.155,
			34.186,
			34.248,
			34.309,
			34.372,
			34.434,
			34.48,
			34.498,
			34.558,
			34.588,
			34.62,
			34.682,
			34.699,
			34.742,
			34.806,
			34.868,
			34.915,
			34.932,
			34.993,
			35.024,
			35.055,
			35.116,
			35.132,
			35.179,
			35.24,
			35.303,
			35.348,
			35.427,
			35.458,
			35.488,
			35.55,
			35.613,
			35.68,
			35.737,
			35.782,
			35.8,
			35.861,
			35.891,
			35.923,
			35.983,
			36,
			36.046,
			36.114,
			36.171,
			36.232,
			36.295,
			36.326,
			36.355,
			36.417,
			36.481,
			36.543,
			36.606,
			36.652,
			36.728,
			36.758,
			36.789,
			36.869,
			36.915,
			36.976,
			37.038,
			37.086,
			37.1,
			37.163,
			37.192,
			37.225,
			37.285,
			37.303,
			37.35,
			37.411,
			37.473,
			37.519,
			37.597,
			37.628,
			37.659,
			37.722,
			37.783,
			37.846,
			37.908,
			37.953,
			37.969,
			38.032,
			38.063,
			38.092,
			38.155,
			38.174,
			38.218,
			38.28,
			38.342,
			38.402,
			38.466,
			38.497,
			38.525,
			38.589,
			38.606,
			38.651,
			38.715,
			38.774,
			38.823,
			38.9,
			38.931,
			38.96,
			39.038,
			39.084,
			39.146,
			39.21,
			39.257,
			39.271,
			39.333,
			39.364,
			39.396,
			39.458,
			39.472,
			39.519,
			39.583,
			39.644,
			39.705,
			39.766,
			39.797,
			39.829,
			39.892,
			39.954,
			40.015,
			40.076,
			40.123,
			40.202,
			40.264,
			40.325,
			40.387,
			40.45,
			40.511,
			40.556,
			40.573,
			40.634,
			40.666,
			40.696,
			40.76,
			40.775,
			40.823,
			40.884,
			40.947,
			40.991,
			41.008,
			41.068,
			41.101,
			41.13,
			41.195,
			41.254,
			41.319,
			41.38,
			41.426,
			41.443,
			41.502,
			41.534,
			41.566,
			41.641,
			41.688,
			41.751,
			41.815,
			41.876,
			41.938,
			41.969,
			42,
			42.06,
			42.078,
			42.123,
			42.186,
			42.246,
			42.3,
			42.371,
			42.402,
			42.433,
			42.497,
			42.559,
			42.62,
			42.68,
			42.729,
			42.745,
			42.806,
			42.837,
			42.869,
			42.93,
			42.943,
			42.991,
			43.053,
			43.117,
			43.163,
			43.178,
			43.238,
			43.271,
			43.302,
			43.363,
			43.38,
			43.425,
			43.487,
			43.549,
			43.597,
			43.673,
			43.706,
			43.736,
			43.814,
			43.859,
			43.922,
			43.983,
			44.032,
			44.047,
			44.108,
			44.138,
			44.168,
			44.232,
			44.248,
			44.295,
			44.359,
			44.417,
			44.465,
			44.48,
			44.542,
			44.573,
			44.604,
			44.665,
			44.683,
			44.73,
			44.792,
			44.853,
			44.9,
			44.976,
			45.008,
			45.038,
			45.099,
			45.162,
			45.226,
			45.286,
			45.333,
			45.347,
			45.409,
			45.44,
			45.474,
			45.535,
			45.55,
			45.597,
			45.658,
			45.719,
			45.766,
			45.783,
			45.843,
			45.875,
			45.906,
			45.969,
			46.03,
			46.093,
			46.153,
			46.217,
			46.276,
			46.309,
			46.341,
			46.402,
			46.422,
			46.465,
			46.524,
			46.59,
			46.651,
			46.714,
			46.741,
			46.774,
			46.836,
			46.853,
			46.9,
			46.961,
			47.022,
			47.068,
			47.147,
			47.179,
			47.21,
			47.286,
			47.333,
			47.395,
			47.455,
			47.503,
			47.518,
			47.58,
			47.612,
			47.642,
			47.705,
			47.72,
			47.767,
			47.833,
			47.889,
			47.938,
			47.953,
			48.015,
			48.047,
			48.078,
			48.139,
			48.155,
			48.2,
			48.263,
			48.325,
			48.37,
			48.45,
			48.484,
			48.512,
			48.573,
			48.635,
			48.698,
			48.759,
			48.805,
			48.819,
			48.881,
			48.913,
			48.945,
			49.021,
			49.07,
			49.132,
			49.192,
			49.253,
			49.316,
			49.347,
			49.38,
			49.456,
			49.501,
			49.566,
			49.627,
			49.675,
			49.751,
			49.783,
			49.814,
			49.875,
			49.937,
			50,
			50.062,
			50.106,
			50.122,
			50.185,
			50.216,
			50.246,
			50.308,
			50.325,
			50.37,
			50.433,
			50.493,
			50.556,
			50.62,
			50.651,
			50.682,
			50.741,
			50.757,
			50.805,
			50.868,
			50.929,
			50.975,
			50.992,
			51.052,
			51.083,
			51.115,
			51.175,
			51.192,
			51.24,
			51.302,
			51.364,
			51.407,
			51.425,
			51.488,
			51.519,
			51.55,
			51.61,
			51.626,
			51.672,
			51.735,
			51.798,
			51.843,
			51.922,
			51.952,
			51.983,
			52.046,
			52.108,
			52.169,
			52.231,
			52.276,
			52.294,
			52.356,
			52.385,
			52.419,
			52.479,
			52.495,
			52.542,
			52.605,
			52.666,
			52.712,
			52.727,
			52.788,
			52.822,
			52.851,
			52.914,
			52.93,
			52.974,
			53.038,
			53.099,
			53.146,
			53.223,
			53.254,
			53.286,
			53.364,
			53.411,
			53.473,
			53.534,
			53.581,
			53.597,
			53.657,
			53.687,
			53.719,
			53.782,
			53.797,
			53.843,
			53.906,
			53.967,
			54.014,
			54.03,
			54.09,
			54.122,
			54.153,
			54.215,
			54.232,
			54.276,
			54.34,
			54.401,
			54.447,
			54.525,
			54.556,
			54.586,
			54.65,
			54.669,
			54.71,
			54.774,
			54.836,
			54.883,
			54.898,
			54.959,
			54.991,
			55.021,
			55.083,
			55.099,
			55.146,
			55.208,
			55.271,
			55.318,
			55.394,
			55.424,
			55.455,
			55.519,
			55.58,
			55.641,
			55.702,
			55.75,
			55.826,
			55.86,
			55.889,
			55.967,
			56.013,
			56.076,
			56.138,
			56.186,
			56.2,
			56.263,
			56.292,
			56.323,
			56.387,
			56.402,
			56.449,
			56.516,
			56.573,
			56.617,
			56.697,
			56.732,
			56.757,
			56.819,
			56.882,
			56.943,
			57.006,
			57.052,
			57.069,
			57.13,
			57.163,
			57.193,
			57.254,
			57.269,
			57.316,
			57.379,
			57.44,
			57.486,
			57.503,
			57.565,
			57.593,
			57.628,
			57.687,
			57.704,
			57.751,
			57.814,
			57.875,
			57.92,
			57.999,
			58.03,
			58.06,
			58.138,
			58.184,
			58.247,
			58.31,
			58.355,
			58.371,
			58.434,
			58.464,
			58.494,
			58.557,
			58.571,
			58.619,
			58.68,
			58.742,
			58.794,
			58.865,
			58.897,
			58.928,
			59.005,
			59.053,
			59.116,
			59.177,
			59.224,
			59.301,
			59.332,
			59.363,
			59.425,
			59.486,
			59.549,
			59.609,
			59.671,
			59.735,
			59.767,
			59.798,
			59.859,
			59.874,
			59.92,
			59.984,
			60.043,
			60.09,
			60.107,
			60.17,
			60.2,
			60.229,
			60.293,
			60.355,
			60.418,
			60.479,
			60.542,
			60.602,
			60.633,
			60.665,
			60.725,
			60.79,
			60.855,
			60.914,
			60.96,
			60.974,
			61.038,
			61.068,
			61.099,
			61.16,
			61.175,
			61.222,
			61.285,
			61.348,
			61.393,
			61.471,
			61.5,
			61.534,
			61.596,
			61.657,
			61.719,
			61.781,
			61.826,
			61.843,
			61.905,
			61.935,
			61.968,
			62.03,
			62.044,
			62.09,
			62.152,
			62.214,
			62.263,
			62.276,
			62.34,
			62.37,
			62.402,
			62.48,
			62.524,
			62.586,
			62.648,
			62.697,
			62.772,
			62.805,
			62.835,
			62.898,
			62.918,
			62.96,
			63.021,
			63.084,
			63.146,
			63.206,
			63.237,
			63.268,
			63.331,
			63.347,
			63.392,
			63.455,
			63.518,
			63.565,
			63.58,
			63.64,
			63.671,
			63.702,
			63.782,
			63.827,
			63.889,
			63.95,
			63.999,
			64.077,
			64.108,
			64.138,
			64.199,
			64.262,
			64.323,
			64.386,
			64.433,
			64.447,
			64.508,
			64.542,
			64.571,
			64.633,
			64.65,
			64.696,
			64.758,
			64.821,
			64.867,
			64.941,
			64.978,
			65.005,
			65.067,
			65.13,
			65.19,
			65.255,
			65.3,
			65.377,
			65.408,
			65.438,
			65.518,
			65.564,
			65.626,
			65.688,
			65.734,
			65.748,
			65.81,
			65.841,
			65.875,
			65.936,
			65.953,
			65.997,
			66.059,
			66.121,
			66.184,
			66.246,
			66.278,
			66.307,
			66.369,
			66.432,
			66.494,
			66.556,
			66.602,
			66.681,
			66.71,
			66.741,
			66.805,
			66.821,
			66.867,
			66.929,
			66.991,
			67.043,
			67.114,
			67.146,
			67.175,
			67.238,
			67.254,
			67.301,
			67.367,
			67.424,
			67.47,
			67.547,
			67.579,
			67.609,
			67.671,
			67.734,
			67.797,
			67.858,
			67.906,
			67.92,
			67.983,
			68.014,
			68.043,
			68.106,
			68.122,
			68.169,
			68.231,
			68.292,
			68.355,
			68.416,
			68.446,
			68.48,
			68.54,
			68.556,
			68.601,
			68.664,
			68.725,
			68.787,
			68.85,
			68.882,
			68.913,
			68.974,
			69.037,
			69.104,
			69.159,
			69.207,
			69.223,
			69.284,
			69.317,
			69.347,
			69.41,
			69.424,
			69.469,
			69.533,
			69.596,
			69.642,
			69.656,
			69.718,
			69.749,
			69.78,
			69.842,
			69.904,
			69.966,
			70.03,
			70.089,
			70.153,
			70.184,
			70.215,
			70.275,
			70.293,
			70.337,
			70.4,
			70.464,
			70.524,
			70.587,
			70.618,
			70.649,
			70.711,
			70.727,
			70.772,
			70.835,
			70.896,
			70.957,
			71.021,
			71.052,
			71.083,
			71.145,
			71.207,
			71.268,
			71.329,
			71.393,
			71.455,
			71.485,
			71.518,
			71.579,
			71.593,
			71.641,
			71.704,
			71.765,
			71.812,
			71.828,
			71.889,
			71.919,
			71.951,
			72.012,
			72.026,
			72.075,
			72.138,
			72.199,
			72.246,
			72.324,
			72.354,
			72.385,
			72.463,
			72.508,
			72.572,
			72.634,
			72.696,
			72.758,
			72.788,
			72.819,
			72.88,
			72.897,
			72.941,
			73.006,
			73.068,
			73.113,
			73.13,
			73.192,
			73.226,
			73.254,
			73.314,
			73.33,
			73.377,
			73.438,
			73.5,
			73.548,
			73.624,
			73.656,
			73.687,
			73.765,
			73.811,
			73.874,
			73.936,
			73.982,
			73.996,
			74.057,
			74.09,
			74.121,
			74.182,
			74.199,
			74.243,
			74.307,
			74.369,
			74.416,
			74.432,
			74.494,
			74.524,
			74.554,
			74.616,
			74.632,
			74.68,
			74.74,
			74.804,
			74.85,
			74.926,
			74.957,
			74.989,
			75.051,
			75.068,
			75.114,
			75.174,
			75.238,
			75.289,
			75.3,
			75.363,
			75.392,
			75.422,
			75.502,
			75.548,
			75.61,
			75.672,
			75.732,
			75.796,
			75.824,
			75.858,
			75.92,
			75.98,
			76.043,
			76.105,
			76.151,
			76.168,
			76.23,
			76.261,
			76.293,
			76.355,
			76.368,
			76.414,
			76.479,
			76.541,
			76.586,
			76.603,
			76.665,
			76.695,
			76.727,
			76.788,
			76.804,
			76.849,
			76.91,
			76.973,
			77.019,
			77.099,
			77.129,
			77.158,
			77.221,
			77.283,
			77.349,
			77.408,
			77.454,
			77.469,
			77.533,
			77.564,
			77.593,
			77.655,
			77.672,
			77.718,
			77.78,
			77.84,
			77.888,
			77.905,
			77.966,
			77.997,
			78.027,
			78.105,
			78.151,
			78.215,
			78.277,
			78.338,
			78.4,
			78.43,
			78.463,
			78.524,
			78.586,
			78.649,
			78.709,
			78.758,
			78.773,
			78.834,
			78.865,
			78.897,
			78.973,
			79.019,
			79.084,
			79.144,
			79.206,
			79.267,
			79.298,
			79.33,
			79.392,
			79.409,
			79.454,
			79.517,
			79.58,
			79.625,
			79.703,
			79.733,
			79.765,
			79.826,
			79.842,
			79.887,
			79.949,
			80.013,
			80.057,
			80.075,
			80.136,
			80.167,
			80.198,
			80.261,
			80.276,
			80.323,
			80.385,
			80.446,
			80.491,
			80.507,
			80.57,
			80.601,
			80.632,
			80.695,
			80.756,
			80.819,
			80.883,
			80.927,
			81.005,
			81.035,
			81.066,
			81.145,
			81.191,
			81.254,
			81.313,
			81.376,
			81.437,
			81.473,
			81.501,
			81.564,
			81.578,
			81.624,
			81.686,
			81.748,
			81.795,
			81.874,
			81.903,
			81.936,
			81.998,
			82.059,
			82.122,
			82.181,
			82.23,
			82.244,
			82.308,
			82.337,
			82.37,
			82.432,
			82.446,
			82.493,
			82.554,
			82.617,
			82.664,
			82.68,
			82.741,
			82.77,
			82.802,
			82.88,
			82.925,
			82.99,
			83.051,
			83.113,
			83.174,
			83.205,
			83.236,
			83.298,
			83.362,
			83.424,
			83.485,
			83.539,
			83.548,
			83.608,
			83.639,
			83.67,
			83.734,
			83.748,
			83.793,
			83.857,
			83.918,
			83.966,
			83.98,
			84.043,
			84.074,
			84.107,
			84.166,
			84.183,
			84.23,
			84.291,
			84.354,
			84.398,
			84.477,
			84.508,
			84.538,
			84.602,
			84.663,
			84.727,
			84.786,
			84.833,
			84.848,
			84.911,
			84.941,
			84.973,
			85.034,
			85.052,
			85.098,
			85.16,
			85.22,
			85.268,
			85.345,
			85.377,
			85.408,
			85.47,
			85.532,
			85.599,
			85.654,
			85.701,
			85.717,
			85.78,
			85.809,
			85.84,
			85.902,
			85.919,
			85.965,
			86.025,
			86.088,
			86.15,
			86.213,
			86.242,
			86.275,
			86.353,
			86.4,
			86.46,
			86.522,
			86.57,
			86.649,
			86.679,
			86.709,
			86.771,
			86.833,
			86.896,
			86.958,
			87.021,
			87.082,
			87.113,
			87.143,
			87.205,
			87.221,
			87.268,
			87.331,
			87.392,
			87.437,
			87.453,
			87.515,
			87.546,
			87.576,
			87.638,
			87.658,
			87.702,
			87.762,
			87.826,
			87.871,
			87.949,
			87.98,
			88.012,
			88.075,
			88.136,
			88.199,
			88.259,
			88.305,
			88.322,
			88.384,
			88.415,
			88.447,
			88.507,
			88.522,
			88.569,
			88.633,
			88.692,
			88.741,
			88.755,
			88.817,
			88.849,
			88.88,
			88.956,
			89.004,
			89.065,
			89.129,
			89.175,
			89.252,
			89.283,
			89.314,
			89.374,
			89.437,
			89.501,
			89.56,
			89.608,
			89.623,
			89.685,
			89.722,
			89.747,
			89.826,
			89.874,
			89.934,
			89.997,
			90.057,
			90.12,
			90.152,
			90.182,
			90.243,
			90.305,
			90.368,
			90.43,
			90.476,
			90.492,
			90.555,
			90.586,
			90.615,
			90.679,
			90.74,
			90.801,
			90.863,
			90.912,
			90.925,
			90.989,
			91.019,
			91.05,
			91.113,
			91.127,
			91.173,
			91.237,
			91.299,
			91.345,
			91.422,
			91.452,
			91.484,
			91.547,
			91.608,
			91.669,
			91.733,
			91.795,
			91.855,
			91.888,
			91.917,
			91.979,
			91.997,
			92.042,
			92.105,
			92.166,
			92.23,
			92.291,
			92.322,
			92.351,
			92.414,
			92.431,
			92.475,
			92.539,
			92.601,
			92.648,
			92.725,
			92.755,
			92.787,
			92.849,
			92.909,
			92.974,
			93.034,
			93.081,
			93.096,
			93.159,
			93.19,
			93.219,
			93.283,
			93.299,
			93.345,
			93.408,
			93.468,
			93.531,
			93.593,
			93.623,
			93.654,
			93.717,
			93.733,
			93.779,
			93.846,
			93.902,
			93.95,
			94.026,
			94.056,
			94.087,
			94.15,
			94.213,
			94.276,
			94.336,
			94.4,
			94.459,
			94.49,
			94.522,
			94.585,
			94.599,
			94.648,
			94.708,
			94.769,
			94.818,
			94.896,
			94.926,
			94.955,
			95.035,
			95.081,
			95.143,
			95.206,
			95.252,
			95.268,
			95.33,
			95.359,
			95.392,
			95.453,
			95.47,
			95.515,
			95.582,
			95.64,
			95.702,
			95.764,
			95.793,
			95.826,
			95.886,
			95.905,
			95.95,
			96.012,
			96.073,
			96.134,
			96.197,
			96.227,
			96.26,
			96.32,
			96.384,
			96.446,
			96.507,
			96.552,
			96.57,
			96.632,
			96.663,
			96.692,
			96.755,
			96.771,
			96.817,
			96.88,
			96.942,
			96.986,
			97.003,
			97.065,
			97.096,
			97.126,
			97.206,
			97.252,
			97.314,
			97.374,
			97.422,
			97.5,
			97.53,
			97.563,
			97.624,
			97.684,
			97.749,
			97.809,
			97.854,
			97.872,
			97.933,
			97.969,
			97.995,
			98.056,
			98.073,
			98.119,
			98.183,
			98.244,
			98.291,
			98.307,
			98.367,
			98.4,
			98.43,
			98.493,
			98.554,
			98.614,
			98.678,
			98.725,
			98.8,
			98.833,
			98.865,
			98.941,
			98.986,
			99.05,
			99.113,
			99.159,
			99.175,
			99.234,
			99.267,
			99.298,
			99.359,
			99.375,
			99.42,
			99.484,
			99.546,
			99.593,
			99.67,
			99.702,
			99.732,
			99.793,
			99.857,
			99.918,
			99.98,
			100.04,
			100.105,
			100.135,
			100.166,
			100.229,
			100.245,
			100.291,
			100.354,
			100.413,
			100.462,
			100.476,
			100.538,
			100.57,
			100.599,
			100.663,
			100.676,
			100.723,
			100.791,
			100.847,
			100.895,
			100.973,
			101.004,
			101.034,
			101.097,
			101.157,
			101.222,
			101.283,
			101.344,
			101.406,
			101.437,
			101.469,
			101.531,
			101.547,
			101.591,
			101.654,
			101.717,
			101.777,
			101.839,
			101.87,
			101.902,
			101.963,
			101.979,
			102.026,
			102.092,
			102.15,
			102.196,
			102.274,
			102.306,
			102.335,
			102.397,
			102.461,
			102.522,
			102.585,
			102.629,
			102.647,
			102.708,
			102.738,
			102.769,
			102.833,
			102.846,
			102.893,
			102.957,
			103.018,
			103.065,
			103.08,
			103.143,
			103.173,
			103.203,
			103.265,
			103.282,
			103.328,
			103.391,
			103.452,
			103.515,
			103.576,
			103.607,
			103.637,
			103.701,
			103.763,
			103.826,
			103.888,
			103.932,
			103.948,
			104.01,
			104.043,
			104.073,
			104.135,
			104.153,
			104.197,
			104.259,
			104.322,
			104.366,
			104.383,
			104.443,
			104.476,
			104.506,
			104.585,
			104.632,
			104.691,
			104.755,
			104.802,
			104.88,
			104.911,
			104.94,
			105.003,
			105.018,
			105.066,
			105.132,
			105.189,
			105.236,
			105.251,
			105.313,
			105.344,
			105.376,
			105.437,
			105.451,
			105.5,
			105.561,
			105.622,
			105.669,
			105.747,
			105.776,
			105.81,
			105.885,
			105.932,
			105.997,
			106.057,
			106.102,
			106.119,
			106.183,
			106.216,
			106.241,
			106.305,
			106.321,
			106.366,
			106.43,
			106.492,
			106.539,
			106.553,
			106.616,
			106.647,
			106.675,
			106.754,
			106.802,
			106.868,
			106.924,
			106.971,
			107.049,
			107.08,
			107.112,
			107.173,
			107.236,
			107.297,
			107.357,
			107.421,
			107.482,
			107.513,
			107.546,
			107.608,
			107.621,
			107.668,
			107.732,
			107.791,
			107.855,
			107.917,
			107.948,
			107.98,
			108.041,
			108.057,
			108.102,
			108.166,
			108.228,
			108.277,
			108.352,
			108.383,
			108.413,
			108.474,
			108.537,
			108.599,
			108.662,
			108.707,
			108.723,
			108.784,
			108.817,
			108.846,
			108.907,
			108.924,
			108.971,
			109.034,
			109.095,
			109.141,
			109.156,
			109.22,
			109.251,
			109.282,
			109.343,
			109.405,
			109.467,
			109.531,
			109.592,
			109.655,
			109.685,
			109.715,
			109.793,
			109.84,
			109.903,
			109.965,
			110.01,
			110.025,
			110.087,
			110.118,
			110.148,
			110.228,
			110.274,
			110.341,
			110.399,
			110.461,
			110.521,
			110.552,
			110.585,
			110.646,
			110.709,
			110.771,
			110.832,
			110.879,
			110.954,
			110.987,
			111.018,
			111.08,
			111.095,
			111.14,
			111.204,
			111.265,
			111.313,
			111.327,
			111.39,
			111.421,
			111.452,
			111.458
		]
	],
	'1670_1672_7982': [
		[
			50.27
		],
		[
			0
		],
		[
			0,
			4.346,
			6.139,
			7.933,
			13.32,
			16.915,
			20.513,
			22.313,
			25.916,
			29.521,
			31.325,
			36.742,
			40.359,
			43.977,
			49.409,
			53.033,
			58.472,
			60.287,
			62.102,
			67.552,
			71.189,
			76.648,
			80.285,
			83.91,
			85.715,
			89.302,
			91.082,
			94.614,
			98.108,
			99.841,
			103.277,
			106.674,
			110.031,
			113.349,
			114.993,
			118.252,
			121.471,
			123.066,
			126.226,
			127.792,
			130.896,
			133.96,
			138.489,
			141.463,
			145.855,
			147.299,
			148.734,
			152.978,
			155.759,
			159.857,
			162.542,
			166.498,
			169.087,
			170.368,
			172.901,
			175.397,
			176.63,
			179.069,
			181.472,
			185.011,
			187.327,
			190.73,
			191.846,
			192.952,
			195.137,
			196.215,
			198.344,
			201.469,
			203.505,
			205.504,
			208.433,
			209.39,
			210.338,
			213.128,
			214.941,
			217.597,
			219.342,
			221.035,
			221.868,
			223.508,
			224.313,
			225.896,
			228.206,
			229.693,
			231.143,
			233.255,
			233.934,
			234.604,
			236.568,
			237.208,
			237.843,
			239.693,
			240.89,
			242.651,
			243.804,
			244.93,
			246.608,
			247.166,
			247.724,
			249.427,
			250.011,
			250.594,
			252.345,
			253.512,
			254.995,
			255.815,
			257.455,
			259.191,
			260.587,
			263.379,
			264.752,
			267.465,
			270.091,
			273.861,
			275.951,
			279.026,
			279.709,
			280.353,
			282.279,
			283.561,
			285.31,
			286.421,
			287.531,
			288.085,
			289.195,
			289.774,
			290.936,
			292.093,
			292.67,
			293.825,
			295.007,
			296.202,
			297.394,
			297.99,
			299.181,
			300.405,
			301.019,
			302.242,
			302.852,
			304.072,
			305.315,
			307.195,
			308.445,
			310.329,
			310.959,
			311.588,
			313.471,
			314.724,
			316.587,
			317.824,
			319.06,
			319.678,
			320.905,
			321.515,
			322.734,
			324.559,
			325.773,
			326.982,
			328.792,
			329.396,
			330,
			331.815,
			332.42,
			333.025,
			334.239,
			334.848,
			336.072,
			337.3,
			339.15,
			340.393,
			342.276,
			342.907,
			343.539,
			345.451,
			346.74,
			348.691,
			350.005,
			351.334,
			352.003,
			353.349,
			354.027,
			355.392,
			356.759,
			357.453,
			358.855,
			360.273,
			362.431,
			363.159,
			363.891,
			366.113,
			366.862,
			367.616,
			369.135,
			369.9,
			371.443,
			373.785,
			375.366,
			376.96,
			379.378,
			380.191,
			381.008,
			383.479,
			384.309,
			385.143,
			387.664,
			389.349,
			391.034,
			391.881,
			393.586,
			395.303,
			396.166,
			398.787,
			400.567,
			402.35,
			405.065,
			406.892,
			409.666,
			410.595,
			411.525,
			414.318,
			416.174,
			418.947,
			420.778,
			422.589,
			423.497,
			425.299,
			426.193,
			427.978,
			429.76,
			430.647,
			431.533,
			434.214,
			436.011,
			438.707,
			440.517,
			442.319,
			443.218,
			445.026,
			445.929,
			447.736,
			449.494,
			452.11,
			453.85,
			456.399,
			457.25,
			458.102,
			460.632,
			462.315,
			464.848,
			466.541,
			468.237,
			469.086,
			470.794,
			471.65,
			473.363,
			475.083,
			475.945,
			476.808,
			479.408,
			482.024,
			482.899,
			483.762,
			485.517,
			487.269,
			488.141,
			490.724,
			492.411,
			494.065,
			496.475,
			498.036,
			500.314,
			501.061,
			501.801,
			504.008,
			505.458,
			507.625,
			509.069,
			510.514,
			511.236,
			512.679,
			513.401,
			514.844,
			516.287,
			517.008,
			518.448,
			519.888,
			522.061,
			523.511,
			525.683,
			526.42,
			527.159,
			529.37,
			530.843,
			533.096,
			534.599,
			536.102,
			538.4,
			539.167,
			539.935,
			542.254,
			543.035,
			543.816,
			546.161,
			547.718,
			549.271,
			550.047,
			551.599,
			553.118,
			553.873,
			555.382,
			556.137,
			557.634,
			559.106,
			561.312,
			562.78,
			564.973,
			565.703,
			566.433,
			568.627,
			570.088,
			572.278,
			573.733,
			575.907,
			577.352,
			578.072,
			578.79,
			580.936,
			581.65,
			582.364,
			584.499,
			585.918,
			587.331,
			588.037,
			589.456,
			590.872,
			591.58,
			592.995,
			593.705,
			595.136,
			596.568,
			598.724,
			600.177,
			602.377,
			603.117,
			603.86,
			606.094,
			607.607,
			609.902,
			611.45,
			613.011,
			613.797,
			615.377,
			616.172,
			617.771,
			620.197,
			621.831,
			623.479,
			625.14,
			627.66,
			630.21,
			631.068,
			631.929,
			633.661,
			634.532,
			636.286,
			638.053,
			640.73,
			642.533,
			645.262,
			646.179,
			647.1,
			649.88,
			651.749,
			654.575,
			656.474,
			659.346,
			661.277,
			662.246,
			664.195,
			666.155,
			667.141,
			669.121,
			671.114,
			674.128,
			676.153,
			679.213,
			680.238,
			681.267,
			683.335,
			684.373,
			686.458,
			688.551,
			691.698,
			693.791,
			696.947,
			698.004,
			699.064,
			702.259,
			704.406,
			707.647,
			709.821,
			713.099,
			715.295,
			717.502,
			718.612,
			720.84,
			721.958,
			724.203,
			726.458,
			729.853,
			732.127,
			735.557,
			736.705,
			737.856,
			741.323,
			743.643,
			747.142,
			749.487,
			751.842,
			755.396,
			756.585,
			758.971,
			761.366,
			762.567,
			763.77,
			767.394,
			769.823,
			772.261,
			773.485,
			775.938,
			778.4,
			779.635,
			782.11,
			783.35,
			785.837,
			788.332,
			792.09,
			794.605,
			798.394,
			799.661,
			800.93,
			804.751,
			807.309,
			811.161,
			813.739,
			817.622,
			820.222,
			821.525,
			824.132,
			826.741,
			828.047,
			830.666,
			833.293,
			837.246,
			838.567,
			839.891,
			842.545,
			845.206,
			846.539,
			849.21,
			850.548,
			853.23,
			855.919,
			859.964,
			862.67,
			866.741,
			868.101,
			869.463,
			873.561,
			876.302,
			880.425,
			883.182,
			885.947,
			887.331,
			890.106,
			891.496,
			894.282,
			897.075,
			898.474,
			901.277,
			904.087,
			908.314,
			911.139,
			915.391,
			916.811,
			918.234,
			922.511,
			925.372,
			928.24,
			932.554,
			935.44,
			939.781,
			941.231,
			942.683,
			948.508,
			949.968,
			954.357,
			957.291,
			960.23,
			961.701,
			964.65,
			966.126,
			969.084,
			973.534,
			976.509,
			979.49,
			983.974,
			986.97,
			991.476,
			992.981,
			994.487,
			999.014,
			1002.039,
			1006.59,
			1009.63,
			1012.676,
			1014.201,
			1017.256,
			1018.786,
			1021.85,
			1024.921,
			1026.458,
			1029.538,
			1032.623,
			1035.715,
			1038.812,
			1040.362,
			1043.467,
			1046.577,
			1048.134,
			1051.253,
			1052.814,
			1055.94,
			1059.071,
			1063.779,
			1066.924,
			1071.651,
			1073.23,
			1074.81,
			1079.556,
			1082.726,
			1087.49,
			1090.672,
			1093.859,
			1095.454,
			1098.643,
			1100.239,
			1103.433,
			1106.632,
			1108.233,
			1111.438,
			1114.649,
			1117.863,
			1121.082,
			1122.693,
			1127.533,
			1129.148,
			1130.765,
			1134.002,
			1135.622,
			1138.865,
			1142.112,
			1146.99,
			1150.248,
			1155.142,
			1156.776,
			1158.41,
			1163.32,
			1166.6,
			1171.527,
			1174.817,
			1179.76,
			1183.059,
			1184.711,
			1188.018,
			1191.329,
			1192.985,
			1196.303,
			1199.624,
			1204.614,
			1207.946,
			1212.952,
			1214.623,
			1216.295,
			1221.317,
			1224.671,
			1228.029,
			1233.073,
			1236.44,
			1241.498,
			1243.186,
			1244.875,
			1249.947,
			1253.334,
			1258.422,
			1261.819,
			1265.22,
			1266.922,
			1270.329,
			1273.74,
			1275.448,
			1280.575,
			1283.999,
			1287.427,
			1292.574,
			1296.01,
			1301.171,
			1302.894,
			1304.617,
			1309.79,
			1313.244,
			1318.433,
			1321.897,
			1325.36,
			1330.53,
			1332.241,
			1333.943,
			1338.995,
			1340.659,
			1342.312,
			1347.215,
			1350.435,
			1353.616,
			1355.192,
			1358.316,
			1361.402,
			1362.931,
			1365.963,
			1367.467,
			1370.448,
			1373.394,
			1377.741,
			1380.593,
			1384.796,
			1386.178,
			1387.55,
			1391.609,
			1394.266,
			1398.179,
			1400.74,
			1403.262,
			1404.509,
			1406.973,
			1408.192,
			1410.6,
			1414.146,
			1416.465,
			1418.747,
			1421.01,
			1423.218,
			1424.309,
			1426.463,
			1428.583,
			1429.63,
			1431.698,
			1432.719,
			1434.742,
			1436.743,
			1439.718,
			1441.697,
			1444.667,
			1445.662,
			1446.659,
			1449.654,
			1450.655,
			1451.656,
			1454.65,
			1456.643,
			1458.622,
			1459.605,
			1461.567,
			1462.542,
			1464.479,
			1466.407,
			1467.367,
			1469.276,
			1471.177,
			1474.017,
			1474.96,
			1475.903,
			1478.722,
			1479.658,
			1480.592,
			1483.388,
			1485.244,
			1487.098,
			1489.871,
			1491.716,
			1494.467,
			1495.379,
			1496.29,
			1499.013,
			1499.917,
			1500.818,
			1503.515,
			1505.304,
			1507.088,
			1507.978,
			1509.754,
			1510.639,
			1512.41,
			1514.177,
			1515.059,
			1516.822,
			1518.584,
			1521.222,
			1522.981,
			1525.617,
			1526.497,
			1527.377,
			1530.022,
			1531.789,
			1534.445,
			1536.219,
			1537.996,
			1538.884,
			1540.661,
			1541.551,
			1542.44,
			1545.996,
			1546.886,
			1549.557,
			1551.34,
			1553.126,
			1554.02,
			1555.81,
			1557.604,
			1558.502,
			1561.197,
			1562.994,
			1565.689,
			1567.487,
			1569.29,
			1571.999,
			1572.905,
			1573.812,
			1576.547,
			1578.382,
			1581.154,
			1583.017,
			1585.833,
			1587.725,
			1588.676,
			1590.585,
			1593.47,
			1595.406,
			1597.352,
			1600.292,
			1601.277,
			1602.264,
			1604.247,
			1606.243,
			1607.246,
			1609.259,
			1610.271,
			1612.303,
			1614.345,
			1617.427,
			1619.494,
			1622.602,
			1623.637,
			1624.674,
			1627.794,
			1629.886,
			1633.038,
			1635.151,
			1637.272,
			1638.336,
			1640.47,
			1641.54,
			1643.685,
			1646.913,
			1649.07,
			1651.23,
			1654.476,
			1656.647,
			1659.917,
			1661.01,
			1662.107,
			1665.411,
			1667.629,
			1670.971,
			1673.215,
			1675.464,
			1678.839,
			1679.964,
			1681.09,
			1684.475,
			1685.606,
			1686.739,
			1690.152,
			1692.438,
			1695.885,
			1698.176,
			1700.494,
			1701.656,
			1704.007,
			1705.178,
			1707.525,
			1709.863,
			1713.416,
			1715.798,
			1719.408,
			1720.61,
			1721.814,
			1725.442,
			1727.875,
			1731.547,
			1733.988,
			1736.461,
			1740.187,
			1741.454,
			1743.952,
			1746.456,
			1747.709,
			1748.965,
			1752.719,
			1755.242,
			1757.772,
			1759.039,
			1761.579,
			1764.125,
			1765.4,
			1767.977,
			1769.259,
			1771.827,
			1774.384,
			1778.259,
			1780.869,
			1784.745,
			1786.05,
			1787.356,
			1791.291,
			1793.924,
			1797.887,
			1800.537,
			1803.193,
			1804.545,
			1807.209,
			1808.544,
			1811.196,
			1815.218,
			1817.905,
			1820.6,
			1824.658,
			1826.014,
			1827.372,
			1830.093,
			1832.822,
			1834.189,
			1836.93,
			1838.303,
			1841.057,
			1843.821,
			1847.98,
			1850.761,
			1854.948,
			1856.346,
			1857.747,
			1861.958,
			1864.774,
			1869.01,
			1871.843,
			1874.683,
			1876.105,
			1878.954,
			1880.382,
			1883.242,
			1887.547,
			1890.427,
			1893.313,
			1897.656,
			1899.107,
			1900.559,
			1904.926,
			1906.385,
			1907.846,
			1910.772,
			1912.237,
			1915.172,
			1918.112,
			1922.533,
			1925.489,
			1929.933,
			1931.418,
			1932.905,
			1938.867,
			1940.362,
			1944.855,
			1947.859,
			1952.375,
			1955.393,
			1956.904,
			1959.93,
			1964.477,
			1967.515,
			1970.555,
			1975.123,
			1978.173,
			1982.756,
			1984.286,
			1985.817,
			1990.419,
			1993.495,
			1998.121,
			2001.212,
			2004.311,
			2005.863,
			2008.971,
			2010.527,
			2012.085,
			2016.768,
			2018.332,
			2019.897,
			2024.599,
			2027.739,
			2032.459,
			2035.612,
			2038.77,
			2040.35,
			2043.513,
			2045.097,
			2048.268,
			2051.445,
			2056.215,
			2059.396,
			2064.177,
			2065.772,
			2067.37,
			2072.168,
			2075.371,
			2080.183,
			2083.396,
			2086.613,
			2088.223,
			2091.446,
			2093.06,
			2096.291,
			2099.526,
			2101.146,
			2104.389,
			2107.637,
			2112.519,
			2114.149,
			2115.78,
			2119.045,
			2122.316,
			2123.953,
			2128.871,
			2132.155,
			2135.444,
			2140.384,
			2143.682,
			2148.637,
			2150.29,
			2151.943,
			2156.908,
			2160.221,
			2165.199,
			2168.523,
			2171.851,
			2173.518,
			2176.854,
			2178.524,
			2181.867,
			2186.891,
			2190.245,
			2193.603,
			2198.646,
			2202.012,
			2207.068,
			2208.755,
			2210.443,
			2213.823,
			2215.512,
			2218.895,
			2222.281,
			2227.368,
			2230.765,
			2235.87,
			2237.574,
			2239.279,
			2244.401,
			2247.821,
			2252.956,
			2256.383,
			2259.813,
			2261.529,
			2264.963,
			2266.682,
			2270.121,
			2273.564,
			2275.288,
			2278.737,
			2282.19,
			2287.376,
			2290.838,
			2296.038,
			2297.773,
			2299.508,
			2304.719,
			2308.196,
			2313.416,
			2316.9,
			2322.133,
			2325.625,
			2327.374,
			2330.873,
			2334.376,
			2336.128,
			2337.883,
			2343.152,
			2346.668,
			2351.951,
			2355.478,
			2359.009,
			2360.776,
			2364.314,
			2366.085,
			2369.631,
			2373.181,
			2378.51,
			2382.065,
			2387.4,
			2389.18,
			2390.959,
			2396.304,
			2399.871,
			2405.229,
			2408.804,
			2412.385,
			2414.177,
			2416.635
		],
		[
			0,
			0.065,
			0.081,
			0.125,
			0.189,
			0.251,
			0.298,
			0.314,
			0.373,
			0.406,
			0.438,
			0.514,
			0.559,
			0.624,
			0.683,
			0.732,
			0.81,
			0.839,
			0.871,
			0.948,
			0.993,
			1.06,
			1.118,
			1.165,
			1.181,
			1.242,
			1.277,
			1.305,
			1.367,
			1.383,
			1.429,
			1.491,
			1.552,
			1.6,
			1.616,
			1.676,
			1.706,
			1.739,
			1.8,
			1.818,
			1.862,
			1.925,
			1.987,
			2.033,
			2.11,
			2.143,
			2.173,
			2.235,
			2.297,
			2.36,
			2.421,
			2.484,
			2.544,
			2.575,
			2.607,
			2.669,
			2.685,
			2.732,
			2.793,
			2.856,
			2.918,
			2.98,
			3.01,
			3.04,
			3.102,
			3.118,
			3.165,
			3.229,
			3.29,
			3.339,
			3.412,
			3.443,
			3.476,
			3.538,
			3.598,
			3.662,
			3.724,
			3.769,
			3.784,
			3.848,
			3.878,
			3.91,
			3.986,
			4.032,
			4.094,
			4.158,
			4.202,
			4.219,
			4.283,
			4.311,
			4.342,
			4.404,
			4.468,
			4.528,
			4.59,
			4.653,
			4.715,
			4.744,
			4.778,
			4.84,
			4.854,
			4.901,
			4.965,
			5.026,
			5.072,
			5.087,
			5.149,
			5.182,
			5.21,
			5.274,
			5.288,
			5.335,
			5.403,
			5.459,
			5.521,
			5.583,
			5.616,
			5.644,
			5.708,
			5.769,
			5.835,
			5.893,
			5.939,
			5.955,
			6.018,
			6.049,
			6.08,
			6.143,
			6.156,
			6.204,
			6.265,
			6.327,
			6.373,
			6.39,
			6.452,
			6.483,
			6.514,
			6.575,
			6.592,
			6.639,
			6.698,
			6.762,
			6.824,
			6.886,
			6.917,
			6.948,
			7.009,
			7.071,
			7.134,
			7.195,
			7.243,
			7.256,
			7.32,
			7.351,
			7.383,
			7.461,
			7.506,
			7.568,
			7.63,
			7.676,
			7.691,
			7.754,
			7.786,
			7.815,
			7.878,
			7.894,
			7.941,
			8.002,
			8.064,
			8.111,
			8.187,
			8.22,
			8.251,
			8.313,
			8.374,
			8.435,
			8.499,
			8.543,
			8.56,
			8.621,
			8.653,
			8.684,
			8.746,
			8.76,
			8.807,
			8.87,
			8.932,
			8.977,
			8.993,
			9.057,
			9.088,
			9.118,
			9.178,
			9.197,
			9.243,
			9.304,
			9.366,
			9.412,
			9.491,
			9.524,
			9.552,
			9.616,
			9.63,
			9.676,
			9.738,
			9.801,
			9.845,
			9.862,
			9.924,
			9.954,
			9.986,
			10.062,
			10.11,
			10.173,
			10.236,
			10.298,
			10.358,
			10.389,
			10.42,
			10.482,
			10.543,
			10.606,
			10.668,
			10.716,
			10.73,
			10.794,
			10.823,
			10.855,
			10.917,
			10.932,
			10.978,
			11.04,
			11.103,
			11.165,
			11.226,
			11.257,
			11.288,
			11.352,
			11.365,
			11.413,
			11.474,
			11.538,
			11.59,
			11.662,
			11.692,
			11.723,
			11.784,
			11.845,
			11.908,
			11.97,
			12.018,
			12.034,
			12.093,
			12.126,
			12.158,
			12.218,
			12.234,
			12.278,
			12.343,
			12.404,
			12.452,
			12.466,
			12.528,
			12.559,
			12.59,
			12.669,
			12.716,
			12.778,
			12.839,
			12.884,
			12.964,
			12.993,
			13.024,
			13.102,
			13.149,
			13.21,
			13.271,
			13.319,
			13.336,
			13.395,
			13.426,
			13.457,
			13.521,
			13.535,
			13.582,
			13.652,
			13.706,
			13.754,
			13.832,
			13.862,
			13.893,
			13.955,
			14.016,
			14.079,
			14.14,
			14.187,
			14.266,
			14.297,
			14.327,
			14.388,
			14.404,
			14.451,
			14.512,
			14.576,
			14.621,
			14.64,
			14.698,
			14.73,
			14.762,
			14.824,
			14.838,
			14.885,
			14.945,
			15.01,
			15.071,
			15.132,
			15.165,
			15.196,
			15.273,
			15.318,
			15.383,
			15.444,
			15.505,
			15.567,
			15.599,
			15.627,
			15.691,
			15.711,
			15.752,
			15.816,
			15.877,
			15.923,
			15.94,
			16.002,
			16.032,
			16.062,
			16.124,
			16.14,
			16.187,
			16.249,
			16.31,
			16.356,
			16.436,
			16.466,
			16.498,
			16.56,
			16.621,
			16.685,
			16.746,
			16.791,
			16.807,
			16.871,
			16.902,
			16.933,
			17.009,
			17.056,
			17.117,
			17.178,
			17.243,
			17.304,
			17.335,
			17.366,
			17.427,
			17.444,
			17.488,
			17.553,
			17.614,
			17.659,
			17.738,
			17.772,
			17.799,
			17.877,
			17.924,
			17.986,
			18.047,
			18.11,
			18.173,
			18.202,
			18.232,
			18.294,
			18.312,
			18.359,
			18.421,
			18.482,
			18.545,
			18.605,
			18.637,
			18.669,
			18.728,
			18.745,
			18.79,
			18.852,
			18.916,
			18.976,
			19.04,
			19.071,
			19.102,
			19.165,
			19.227,
			19.288,
			19.349,
			19.413,
			19.475,
			19.505,
			19.535,
			19.599,
			19.614,
			19.659,
			19.721,
			19.785,
			19.844,
			19.908,
			19.939,
			19.969,
			20.033,
			20.093,
			20.158,
			20.218,
			20.264,
			20.341,
			20.372,
			20.404,
			20.466,
			20.482,
			20.527,
			20.59,
			20.653,
			20.699,
			20.714,
			20.775,
			20.807,
			20.838,
			20.901,
			20.916,
			20.961,
			21.025,
			21.086,
			21.133,
			21.21,
			21.24,
			21.271,
			21.335,
			21.397,
			21.457,
			21.519,
			21.583,
			21.644,
			21.676,
			21.706,
			21.768,
			21.785,
			21.831,
			21.894,
			21.954,
			22.002,
			22.015,
			22.077,
			22.11,
			22.139,
			22.203,
			22.219,
			22.264,
			22.327,
			22.389,
			22.435,
			22.511,
			22.544,
			22.575,
			22.636,
			22.699,
			22.76,
			22.823,
			22.869,
			22.883,
			22.948,
			22.977,
			23.008,
			23.07,
			23.086,
			23.132,
			23.193,
			23.258,
			23.304,
			23.38,
			23.412,
			23.441,
			23.505,
			23.568,
			23.627,
			23.691,
			23.738,
			23.816,
			23.847,
			23.876,
			23.957,
			23.999,
			24.064,
			24.124,
			24.171,
			24.185,
			24.249,
			24.278,
			24.312,
			24.387,
			24.435,
			24.498,
			24.559,
			24.605,
			24.682,
			24.714,
			24.743,
			24.806,
			24.87,
			24.932,
			24.993,
			25.041,
			25.055,
			25.118,
			25.149,
			25.18,
			25.241,
			25.257,
			25.304,
			25.366,
			25.427,
			25.474,
			25.488,
			25.55,
			25.583,
			25.614,
			25.675,
			25.691,
			25.738,
			25.799,
			25.862,
			25.924,
			25.985,
			26.022,
			26.045,
			26.11,
			26.171,
			26.232,
			26.294,
			26.342,
			26.356,
			26.418,
			26.451,
			26.482,
			26.543,
			26.56,
			26.604,
			26.668,
			26.727,
			26.777,
			26.79,
			26.854,
			26.883,
			26.916,
			26.978,
			26.992,
			27.039,
			27.101,
			27.164,
			27.21,
			27.288,
			27.318,
			27.35,
			27.411,
			27.472,
			27.535,
			27.598,
			27.66,
			27.721,
			27.751,
			27.783,
			27.847,
			27.862,
			27.908,
			27.969,
			28.032,
			28.084,
			28.156,
			28.186,
			28.218,
			28.28,
			28.342,
			28.403,
			28.466,
			28.526,
			28.589,
			28.622,
			28.653,
			28.715,
			28.775,
			28.838,
			28.9,
			28.945,
			28.961,
			29.023,
			29.056,
			29.085,
			29.164,
			29.211,
			29.273,
			29.333,
			29.395,
			29.458,
			29.489,
			29.519,
			29.582,
			29.643,
			29.706,
			29.767,
			29.816,
			29.891,
			29.923,
			29.953,
			30.016,
			30.032,
			30.077,
			30.143,
			30.202,
			30.249,
			30.264,
			30.326,
			30.357,
			30.388,
			30.449,
			30.465,
			30.511,
			30.576,
			30.637,
			30.683,
			30.759,
			30.791,
			30.823,
			30.884,
			30.945,
			31.009,
			31.071,
			31.118,
			31.133,
			31.195,
			31.224,
			31.256,
			31.334,
			31.381,
			31.442,
			31.503,
			31.552,
			31.565,
			31.627,
			31.659,
			31.691,
			31.751,
			31.769,
			31.812,
			31.876,
			31.938,
			31.999,
			32.062,
			32.092,
			32.124,
			32.186,
			32.205,
			32.249,
			32.311,
			32.372,
			32.42,
			32.434,
			32.495,
			32.527,
			32.559,
			32.62,
			32.637,
			32.682,
			32.744,
			32.806,
			32.853,
			32.869,
			32.932,
			32.96,
			32.993,
			33.056,
			33.116,
			33.177,
			33.241,
			33.288,
			33.366,
			33.397,
			33.426,
			33.49,
			33.504,
			33.552,
			33.613,
			33.675,
			33.72,
			33.737,
			33.799,
			33.828,
			33.861,
			33.924,
			33.938,
			33.983,
			34.047,
			34.108,
			34.155,
			34.232,
			34.268,
			34.293,
			34.356,
			34.418,
			34.48,
			34.543,
			34.59,
			34.604,
			34.666,
			34.699,
			34.727,
			34.806,
			34.853,
			34.915,
			34.976,
			35.022,
			35.039,
			35.099,
			35.133,
			35.162,
			35.241,
			35.287,
			35.354,
			35.411,
			35.456,
			35.535,
			35.567,
			35.597,
			35.659,
			35.72,
			35.784,
			35.843,
			35.907,
			35.968,
			36.001,
			36.032,
			36.107,
			36.155,
			36.217,
			36.28,
			36.327,
			36.341,
			36.402,
			36.435,
			36.466,
			36.527,
			36.543,
			36.59,
			36.652,
			36.711,
			36.759,
			36.836,
			36.869,
			36.899,
			36.976,
			37.024,
			37.087,
			37.148,
			37.193,
			37.209,
			37.27,
			37.303,
			37.333,
			37.41,
			37.457,
			37.521,
			37.582,
			37.643,
			37.704,
			37.735,
			37.768,
			37.83,
			37.89,
			37.957,
			38.015,
			38.062,
			38.14,
			38.171,
			38.202,
			38.263,
			38.28,
			38.326,
			38.391,
			38.449,
			38.511,
			38.573,
			38.605,
			38.636,
			38.698,
			38.714,
			38.76,
			38.82,
			38.883,
			38.93,
			39.008,
			39.039,
			39.07,
			39.147,
			39.194,
			39.255,
			39.317,
			39.378,
			39.441,
			39.473,
			39.504,
			39.566,
			39.581,
			39.627,
			39.691,
			39.751,
			39.798,
			39.814,
			39.874,
			39.905,
			39.939,
			39.999,
			40.015,
			40.061,
			40.123,
			40.186,
			40.232,
			40.31,
			40.341,
			40.372,
			40.432,
			40.495,
			40.559,
			40.62,
			40.667,
			40.682,
			40.743,
			40.773,
			40.806,
			40.883,
			40.929,
			40.993,
			41.054,
			41.099,
			41.116,
			41.177,
			41.209,
			41.239,
			41.302,
			41.318,
			41.364,
			41.426,
			41.489,
			41.533,
			41.61,
			41.642,
			41.674,
			41.735,
			41.798,
			41.861,
			41.923,
			41.97,
			41.984,
			42.046,
			42.076,
			42.106,
			42.185,
			42.232,
			42.293,
			42.356,
			42.402,
			42.419,
			42.481,
			42.516,
			42.542,
			42.603,
			42.619,
			42.665,
			42.726,
			42.789,
			42.853,
			42.914,
			42.946,
			42.976,
			43.054,
			43.099,
			43.162,
			43.224,
			43.287,
			43.348,
			43.377,
			43.41,
			43.489,
			43.534,
			43.596,
			43.659,
			43.706,
			43.782,
			43.815,
			43.845,
			43.907,
			43.968,
			44.029,
			44.093,
			44.139,
			44.154,
			44.216,
			44.245,
			44.277,
			44.339,
			44.355,
			44.403,
			44.465,
			44.525,
			44.589,
			44.652,
			44.683,
			44.711,
			44.775,
			44.789,
			44.836,
			44.899,
			44.961,
			45.006,
			45.085,
			45.115,
			45.146,
			45.208,
			45.27,
			45.333,
			45.396,
			45.439,
			45.456,
			45.52,
			45.551,
			45.581,
			45.644,
			45.658,
			45.704,
			45.766,
			45.829,
			45.876,
			45.89,
			45.953,
			45.984,
			46.015,
			46.093,
			46.138,
			46.201,
			46.262,
			46.31,
			46.387,
			46.419,
			46.45,
			46.51,
			46.572,
			46.635,
			46.695,
			46.744,
			46.759,
			46.82,
			46.851,
			46.883,
			46.961,
			47.007,
			47.069,
			47.131,
			47.193,
			47.255,
			47.286,
			47.316,
			47.378,
			47.394,
			47.44,
			47.502,
			47.565,
			47.61,
			47.689,
			47.721,
			47.751,
			47.811,
			47.876,
			47.937,
			47.999,
			48.047,
			48.061,
			48.124,
			48.153,
			48.184,
			48.248,
			48.261,
			48.31,
			48.372,
			48.432,
			48.494,
			48.556,
			48.589,
			48.62,
			48.681,
			48.743,
			48.804,
			48.867,
			48.93,
			48.992,
			49.023,
			49.054,
			49.116,
			49.132,
			49.178,
			49.241,
			49.302,
			49.363,
			49.426,
			49.457,
			49.488,
			49.549,
			49.565,
			49.612,
			49.673,
			49.735,
			49.782,
			49.86,
			49.891,
			49.922,
			49.982,
			50.044,
			50.107,
			50.169,
			50.215,
			50.233,
			50.27
		]
	],
	'2472_3683_7982': [
		[
			98.034
		],
		[
			0
		],
		[
			0,
			1.959,
			5.641,
			6.872,
			8.105,
			11.818,
			14.305,
			18.051,
			20.56,
			23.076,
			24.338,
			26.868,
			28.136,
			29.406,
			34.506,
			35.786,
			39.639,
			42.218,
			44.805,
			46.102,
			48.702,
			51.308,
			52.612,
			55.22,
			56.526,
			59.144,
			61.769,
			65.722,
			68.366,
			72.346,
			73.676,
			75.008,
			79.015,
			81.694,
			85.725,
			88.419,
			91.12,
			92.473,
			95.182,
			96.539,
			99.259,
			101.986,
			103.351,
			104.719,
			108.832,
			111.579,
			114.326,
			115.697,
			118.429,
			121.138,
			122.482,
			125.145,
			126.463,
			129.071,
			131.639,
			135.422,
			137.898,
			141.541,
			142.737,
			143.925,
			147.44,
			149.739,
			153.118,
			155.323,
			157.492,
			158.563,
			160.676,
			161.719,
			163.777,
			166.794,
			168.776,
			170.707,
			173.539,
			174.469,
			175.392,
			178.13,
			179.035,
			179.932,
			181.716,
			182.605,
			184.381,
			186.143,
			188.792,
			190.552,
			193.188,
			194.07,
			194.949,
			197.582,
			199.345,
			202.028,
			203.824,
			206.645,
			208.58,
			209.548,
			211.661,
			213.808,
			214.88,
			217.233,
			219.589,
			223.102,
			225.402,
			228.523,
			229.562,
			230.557,
			233.382,
			235.264,
			238.011,
			239.843,
			241.662,
			242.568,
			244.384,
			245.293,
			247.105,
			248.922,
			249.834,
			250.746,
			253.483,
			255.317,
			257.167,
			258.096,
			259.961,
			261.849,
			262.803,
			264.718,
			265.68,
			267.633,
			269.601,
			272.583,
			274.59,
			277.623,
			278.641,
			279.661,
			282.72,
			284.785,
			287.9,
			289.989,
			292.088,
			293.139,
			295.258,
			296.291,
			298.395,
			300.508,
			301.568,
			302.629,
			305.824,
			309.041,
			310.118,
			311.197,
			313.361,
			315.533,
			316.623,
			319.905,
			322.104,
			324.313,
			327.644,
			329.878,
			333.245,
			334.372,
			335.502,
			338.903,
			341.177,
			344.587,
			346.85,
			349.094,
			350.207,
			352.411,
			353.5,
			355.652,
			357.765,
			358.808,
			360.864,
			362.883,
			365.84,
			367.764,
			370.581,
			371.501,
			372.413,
			375.091,
			376.832,
			378.554,
			381.063,
			382.71,
			385.156,
			385.967,
			386.777,
			389.203,
			390.013,
			390.822,
			393.246,
			394.874,
			397.316,
			398.944,
			399.784,
			401.46,
			403.969,
			405.71,
			407.446,
			410.071,
			411.838,
			414.483,
			415.361,
			416.238,
			418.868,
			420.593,
			423.165,
			424.878,
			426.579,
			429.129,
			429.982,
			431.69,
			433.394,
			434.246,
			435.099,
			437.659,
			439.361,
			441.904,
			443.59,
			445.268,
			446.097,
			448.573,
			450.214,
			451.834,
			454.253,
			455.868,
			458.288,
			459.092,
			459.896,
			462.42,
			464.103,
			466.743,
			468.559,
			470.371,
			471.316,
			473.202,
			474.144,
			476.01,
			477.831,
			478.742,
			480.559,
			482.217,
			483.878,
			486.313,
			487.858,
			489.408,
			490.186,
			491.733,
			492.505,
			494.057,
			495.618,
			498.01,
			499.619,
			502.065,
			502.89,
			503.719,
			506.224,
			507.927,
			510.504,
			512.239,
			513.993,
			514.876,
			516.651,
			517.544,
			519.338,
			521.134,
			522.037,
			523.853,
			525.697,
			528.493,
			529.433,
			530.381,
			533.227,
			534.188,
			535.154,
			537.095,
			538.071,
			540.031,
			542.996,
			544.989,
			546.994,
			550.024,
			551.04,
			552.059,
			556.165,
			557.199,
			560.319,
			562.414,
			565.567,
			567.671,
			568.726,
			570.844,
			572.972,
			574.04,
			576.184,
			578.339,
			581.593,
			583.775,
			587.073,
			588.178,
			589.285,
			592.624,
			594.86,
			598.218,
			600.447,
			603.75,
			605.94,
			607.015,
			609.146,
			611.286,
			613.411,
			616.569,
			618.661,
			620.768,
			621.83,
			623.958,
			626.106,
			627.185,
			629.346,
			630.433,
			632.613,
			634.796,
			638.06,
			640.233,
			643.474,
			644.557,
			645.637,
			648.882,
			651.056,
			654.331,
			656.532,
			658.74,
			659.848,
			662.082,
			663.201,
			665.448,
			667.711,
			668.844,
			671.116,
			673.393,
			675.672,
			679.086,
			681.354,
			683.612,
			684.738,
			686.985,
			688.105,
			690.342,
			692.585,
			695.954,
			698.21,
			701.587,
			702.708,
			703.825,
			707.147,
			709.334,
			712.548,
			714.628,
			717.682,
			719.668,
			720.642,
			722.552,
			724.428,
			725.368,
			727.172,
			728.943,
			731.513,
			733.168,
			735.582,
			736.36,
			737.129,
			738.643,
			739.387,
			740.852,
			742.286,
			744.391,
			745.793,
			747.851,
			748.531,
			749.206,
			751.205,
			752.516,
			754.45,
			755.715,
			756.957,
			757.568,
			758.776,
			759.373,
			760.554,
			762.294,
			763.437,
			764.571,
			766.254,
			767.362,
			769.009,
			769.556,
			770.101,
			771.732,
			772.811,
			774.421,
			775.493,
			776.568,
			778.166,
			778.697,
			779.228,
			780.825,
			781.357,
			781.888,
			783.477,
			784.537,
			785.603,
			786.135,
			787.198,
			788.262,
			788.795,
			789.866,
			790.404,
			791.49,
			792.578,
			794.217,
			795.318,
			797.032,
			797.607,
			798.182,
			799.91,
			801.095,
			802.916,
			804.127,
			805.34,
			805.968,
			807.244,
			807.881,
			809.154,
			811.111,
			812.496,
			813.881,
			816.004,
			816.775,
			817.545,
			819.086,
			820.631,
			821.46,
			823.116,
			823.943,
			825.6,
			828.114,
			829.79,
			831.415,
			833.77,
			834.557,
			835.345,
			837.523,
			838.96,
			841.089,
			842.411,
			844.404,
			845.743,
			846.4,
			847.717,
			849.04,
			849.705,
			851.059,
			852.468,
			854.599,
			856.047,
			857.558,
			859.081,
			859.847,
			861.4,
			862.189,
			863.776,
			865.378,
			867.806,
			869.443,
			871.912,
			872.737,
			873.565,
			876.052,
			877.71,
			880.223,
			881.897,
			883.561,
			884.395,
			886.072,
			887.758,
			888.605,
			890.304,
			891.155,
			892.856,
			894.546,
			897.046,
			898.683,
			901.078,
			901.875,
			902.648,
			904.915,
			906.387,
			908.55,
			909.965,
			911.369,
			913.464,
			914.161,
			914.858,
			917.661,
			918.366,
			920.485,
			921.904,
			924.036,
			925.459,
			926.887,
			927.601,
			929.027,
			929.741,
			931.168,
			932.608,
			934.761,
			936.195,
			938.398,
			939.13,
			939.861,
			942.095,
			943.625,
			945.9,
			947.476,
			949.053,
			949.835,
			951.392,
			952.191,
			952.983,
			955.32,
			956.085,
			957.625,
			959.141,
			960.635,
			962.85,
			964.308,
			965.755,
			966.47,
			967.852,
			968.538,
			969.906,
			971.268,
			973.161,
			974.404,
			976.259,
			976.859,
			977.454,
			979.232,
			980.416,
			982.199,
			983.388,
			984.577,
			985.172,
			986.366,
			986.968,
			988.173,
			989.378,
			989.982,
			991.194,
			992.418,
			994.264,
			995.503,
			997.394,
			998.034,
			998.675,
			1000.614,
			1001.947,
			1003.335,
			1005.438,
			1006.89,
			1009.212,
			1009.993,
			1010.779,
			1013.282,
			1014.142,
			1015.007,
			1017.629,
			1019.397,
			1021.181,
			1022.021,
			1023.639,
			1025.271,
			1026.092,
			1027.625,
			1028.373,
			1029.881,
			1031.402,
			1033.661,
			1034.416,
			1035.174,
			1037.478,
			1038.258,
			1039.04,
			1041.4,
			1043.007,
			1044.622,
			1047.062,
			1048.709,
			1051.191,
			1052.023,
			1052.857,
			1055.367,
			1056.206,
			1057.046,
			1059.57,
			1061.256,
			1063.78,
			1065.489,
			1067.187,
			1068.042,
			1069.761,
			1070.626,
			1072.353,
			1074.112,
			1076.789,
			1078.587,
			1081.307,
			1082.222,
			1083.142,
			1085.919,
			1086.853,
			1087.793,
			1090.63,
			1092.548,
			1094.493,
			1095.47,
			1097.454,
			1098.466,
			1100.5,
			1102.563,
			1103.615,
			1104.669,
			1107.883,
			1110.079,
			1113.378,
			1115.58,
			1117.738,
			1118.785,
			1120.884,
			1121.934,
			1123.966,
			1126.005,
			1129.073,
			1131.128,
			1134.241,
			1135.28,
			1136.32,
			1139.46,
			1141.568,
			1144.737,
			1146.874,
			1149.026,
			1150.105,
			1152.273,
			1153.362,
			1155.547,
			1157.744,
			1158.846,
			1161.059,
			1163.282,
			1165.517,
			1168.89,
			1172.287,
			1174.566,
			1178.006,
			1180.313,
			1182.63,
			1186.126,
			1188.471,
			1192.009,
			1193.195,
			1194.384,
			1197.967,
			1200.378,
			1204.025,
			1206.477,
			1210.187,
			1212.675,
			1215.175,
			1216.428,
			1220.194,
			1222.708,
			1225.224,
			1229.003,
			1231.544,
			1235.311,
			1236.576,
			1237.842,
			1241.64,
			1244.176,
			1247.987,
			1250.523,
			1253.065,
			1256.867,
			1258.135,
			1259.4,
			1263.202,
			1265.736,
			1269.568,
			1272.114,
			1274.675,
			1275.958,
			1278.53,
			1281.111,
			1282.402,
			1284.998,
			1286.298,
			1288.91,
			1291.534,
			1295.492,
			1298.147,
			1302.169,
			1303.52,
			1304.88,
			1308.983,
			1311.747,
			1315.931,
			1318.739,
			1321.563,
			1322.978,
			1325.771,
			1327.169,
			1329.938,
			1332.698,
			1334.07,
			1336.813,
			1339.549,
			1342.279,
			1345.008,
			1346.372,
			1349.105,
			1351.839,
			1353.209,
			1355.945,
			1357.316,
			1360.056,
			1362.801,
			1366.926,
			1369.688,
			1373.826,
			1375.22,
			1376.637,
			1380.828,
			1383.628,
			1387.816,
			1390.633,
			1393.456,
			1394.87,
			1397.703,
			1399.146,
			1401.986,
			1406.231,
			1409.083,
			1411.941,
			1416.238,
			1419.11,
			1421.989,
			1424.876,
			1426.323,
			1429.224,
			1430.682,
			1433.601,
			1436.536,
			1440.956,
			1443.912,
			1448.362,
			1449.848,
			1451.335,
			1455.804,
			1458.789,
			1463.274,
			1466.272,
			1469.276,
			1470.781,
			1473.795,
			1475.305,
			1478.328,
			1481.356,
			1482.872,
			1485.905,
			1488.941,
			1493.496,
			1496.537,
			1501.106,
			1502.632,
			1504.159,
			1508.748,
			1511.813,
			1514.884,
			1519.5,
			1522.585,
			1527.222,
			1528.77,
			1530.319,
			1534.974,
			1536.528,
			1538.084,
			1542.757,
			1545.878,
			1549.005,
			1550.57,
			1553.703,
			1556.842,
			1558.413,
			1561.56,
			1563.135,
			1566.287,
			1569.444,
			1574.179,
			1577.346,
			1582.113,
			1583.706,
			1585.291,
			1590.052,
			1593.218,
			1597.896,
			1600.998,
			1604.092,
			1605.636,
			1608.691,
			1610.196,
			1613.164,
			1616.08,
			1617.556,
			1620.472,
			1623.282,
			1626.064,
			1630.199,
			1632.929,
			1635.572,
			1636.879,
			1639.469,
			1640.75,
			1643.274,
			1645.751,
			1649.389,
			1651.764,
			1655.289,
			1656.449,
			1657.6,
			1661.107,
			1663.315,
			1666.548,
			1668.669,
			1670.803,
			1671.861,
			1673.97,
			1675.009,
			1677.006,
			1678.963,
			1679.937,
			1681.877,
			1683.876,
			1685.912,
			1687.879,
			1688.855,
			1691.814,
			1692.803,
			1693.803,
			1695.808,
			1696.808,
			1698.787,
			1700.753,
			1703.635,
			1705.54,
			1708.395,
			1709.345,
			1710.295,
			1713.144,
			1715.04,
			1717.884,
			1719.785,
			1722.634,
			1724.55,
			1726.477,
			1727.44,
			1729.375,
			1730.39,
			1732.42,
			1734.464,
			1737.715,
			1739.916,
			1743.346,
			1744.488,
			1745.621,
			1749.016,
			1751.149,
			1754.316,
			1756.312,
			1758.305,
			1761.246,
			1762.218,
			1763.19,
			1766.094,
			1768.025,
			1770.921,
			1772.851,
			1775.754,
			1777.694,
			1779.641,
			1780.62,
			1782.583,
			1783.568,
			1785.554,
			1787.557,
			1790.592,
			1792.642,
			1795.748,
			1796.794,
			1797.843,
			1801.007,
			1803.133,
			1806.338,
			1808.469,
			1810.627,
			1813.882,
			1814.972,
			1816.065,
			1819.358,
			1820.46,
			1821.564,
			1824.88,
			1827.099,
			1829.257,
			1830.334,
			1832.461,
			1834.553,
			1835.586,
			1837.627,
			1838.634,
			1840.621,
			1843.534,
			1845.431,
			1847.292,
			1850.02,
			1850.912,
			1851.796,
			1854.397,
			1856.084,
			1858.549,
			1860.146,
			1861.72,
			1862.487,
			1863.994,
			1864.735,
			1866.186,
			1868.296,
			1868.984,
			1871.001,
			1872.311,
			1873.601,
			1874.241,
			1875.501,
			1876.739,
			1877.354,
			1878.578,
			1879.189,
			1880.41,
			1881.63,
			1883.461,
			1884.684,
			1886.586,
			1887.224,
			1887.861,
			1889.772,
			1891.121,
			1893.162,
			1894.52,
			1895.929,
			1896.646,
			1898.072,
			1898.783,
			1900.21,
			1901.685,
			1902.419,
			1903.882,
			1905.349,
			1907.575,
			1909.055,
			1911.278,
			1912.019,
			1912.759,
			1914.981,
			1916.422,
			1918.577,
			1920.018,
			1922.072,
			1923.441,
			1924.128,
			1924.817,
			1926.808,
			1927.469,
			1928.132,
			1930.134,
			1931.465,
			1932.804,
			1933.477,
			1934.831,
			1936.207,
			1936.901,
			1938.297,
			1939,
			1940.418,
			1941.86,
			1944.049,
			1945.529,
			1947.78,
			1948.526,
			1949.288,
			1951.597,
			1953.155,
			1955.521,
			1957.116,
			1958.726,
			1959.536,
			1961.168,
			1961.989,
			1963.641,
			1965.308,
			1966.146,
			1967.833,
			1969.52,
			1971.208,
			1972.906,
			1973.759,
			1975.467,
			1977.182,
			1978.04,
			1979.753,
			1980.609,
			1982.331,
			1984.031,
			1986.574,
			1988.263,
			1990.784,
			1991.623,
			1992.458,
			1994.953,
			1996.623,
			1999.117,
			2000.782,
			2003.299,
			2004.971,
			2005.813,
			2007.505,
			2009.192,
			2010.035,
			2011.742,
			2013.452,
			2016.027,
			2017.77,
			2020.38,
			2021.257,
			2022.133,
			2024.757,
			2026.5,
			2028.234,
			2030.832,
			2032.556,
			2035.139,
			2036.004,
			2036.872,
			2039.473,
			2041.221,
			2043.876,
			2045.649,
			2048.344,
			2050.143,
			2051.04,
			2052.83,
			2054.622,
			2055.519,
			2057.272,
			2059.022,
			2061.625,
			2063.333,
			2065.894,
			2066.737,
			2067.58,
			2070.111,
			2071.792,
			2074.306,
			2075.983,
			2077.656,
			2078.492,
			2080.165,
			2081.003,
			2081.841,
			2084.359,
			2086.042,
			2088.577,
			2090.272,
			2091.979,
			2092.836,
			2094.555,
			2096.284,
			2097.155,
			2098.904,
			2099.783,
			2101.551,
			2103.333,
			2106.031,
			2107.844,
			2110.59,
			2111.511,
			2112.435,
			2115.218,
			2117.061,
			2119.835,
			2121.664,
			2123.464,
			2126.098,
			2126.957,
			2127.807,
			2130.302,
			2131.115,
			2131.92,
			2134.282,
			2135.812,
			2137.31,
			2138.047,
			2139.493,
			2140.904,
			2141.598,
			2142.966,
			2143.637,
			2144.965,
			2146.25,
			2148.126,
			2149.33,
			2151.078,
			2151.647,
			2152.209,
			2153.859,
			2154.934,
			2156.517,
			2157.55,
			2158.572,
			2159.08,
			2160.093,
			2160.599,
			2161.612,
			2162.619,
			2163.122,
			2164.13,
			2165.141,
			2166.157,
			2167.199,
			2167.724,
			2168.778,
			2169.836,
			2170.368,
			2172.042,
			2173.25,
			2174.467,
			2176.306,
			2177.721,
			2179.884,
			2180.606,
			2181.328,
			2183.666,
			2185.223,
			2187.556,
			2189.106,
			2190.639,
			2191.401,
			2192.896,
			2194.373,
			2195.107,
			2197.279,
			2198.682,
			2200.073,
			2202.128,
			2203.439,
			2205.383,
			2206.028,
			2206.671,
			2208.531,
			2209.753,
			2210.969,
			2212.762,
			2213.933,
			2215.674,
			2216.254,
			2216.83,
			2218.514,
			2219.629,
			2221.3,
			2222.383,
			2223.447,
			2223.978,
			2225.04,
			2226.103,
			2226.636,
			2227.64,
			2228.141,
			2229.143,
			2230.149,
			2231.667,
			2232.656,
			2234.143,
			2234.642,
			2235.143,
			2236.656,
			2237.69,
			2239.259,
			2240.316,
			2241.384,
			2241.926,
			2243.039,
			2243.6,
			2244.731,
			2245.875,
			2246.452,
			2247.633,
			2248.834,
			2250.051,
			2251.284,
			2251.908,
			2253.177,
			2254.461,
			2255.11,
			2256.418,
			2257.079,
			2258.412,
			2259.76,
			2261.811,
			2263.196,
			2265.302,
			2266,
			2266.725,
			2268.875,
			2270.335,
			2272.553,
			2274.05,
			2276.323,
			2277.855,
			2278.625,
			2280.175,
			2281.741,
			2282.542,
			2284.114,
			2285.716,
			2288.147,
			2289.788,
			2292.275,
			2293.112,
			2293.952,
			2295.637,
			2296.479,
			2298.164,
			2299.861,
			2302.431,
			2304.16,
			2306.777,
			2307.656,
			2308.537,
			2311.201,
			2312.993,
			2315.705,
			2317.529,
			2319.366,
			2320.289,
			2322.146,
			2323.079,
			2324.956,
			2326.845,
			2327.795,
			2329.705,
			2331.627,
			2334.535,
			2335.51,
			2336.489,
			2339.444,
			2340.436,
			2341.43,
			2344.432,
			2346.448,
			2348.476,
			2351.522,
			2353.538,
			2356.524,
			2357.496,
			2358.443,
			2362.222,
			2363.145,
			2365.859,
			2367.627,
			2369.375,
			2370.227,
			2371.905,
			2372.719,
			2374.363,
			2375.963,
			2376.753,
			2378.318,
			2379.863,
			2382.154,
			2382.911,
			2383.661,
			2385.908,
			2386.658,
			2387.409,
			2389.644,
			2391.137,
			2393.381,
			2394.868,
			2396.352,
			2398.578,
			2399.321,
			2400.8,
			2402.271,
			2403.008,
			2403.759,
			2406.005,
			2407.5,
			2409.024,
			2409.791,
			2411.322,
			2412.851,
			2413.636,
			2415.206,
			2415.989,
			2417.551,
			2419.141,
			2421.528,
			2423.117,
			2425.547,
			2426.353,
			2427.158,
			2429.601,
			2431.233,
			2433.681,
			2435.327,
			2436.964,
			2437.779,
			2439.454,
			2440.291,
			2441.958,
			2443.635,
			2444.49,
			2445.342,
			2447.883,
			2449.562,
			2451.23,
			2452.062,
			2453.691,
			2455.252,
			2456.031,
			2457.588,
			2458.357,
			2459.833,
			2462.043,
			2463.516,
			2464.974,
			2467.161,
			2467.891,
			2468.621,
			2470.814,
			2472.28,
			2474.476,
			2475.937,
			2478.138,
			2479.595,
			2480.325,
			2481.789,
			2483.261,
			2483.992,
			2485.456,
			2486.927,
			2489.142,
			2489.879,
			2490.619,
			2492.851,
			2493.6,
			2494.351,
			2496.615,
			2498.139,
			2500.46,
			2502.024,
			2503.602,
			2506.006,
			2506.8,
			2507.611,
			2510.061,
			2511.702,
			2514.148,
			2515.753,
			2517.327,
			2518.1,
			2519.62,
			2521.105,
			2521.835,
			2523.273,
			2523.985,
			2525.395,
			2526.791,
			2528.891,
			2529.59,
			2530.287,
			2532.372,
			2533.063,
			2533.754,
			2535.832,
			2537.204,
			2539.26,
			2540.636,
			2542.005,
			2542.688,
			2544.056,
			2544.744,
			2545.43,
			2548.166,
			2548.851,
			2550.92,
			2552.295,
			2554.373,
			2555.778,
			2557.182,
			2557.886,
			2560.042,
			2561.501,
			2562.962,
			2565.184,
			2566.676,
			2568.916,
			2569.646,
			2570.374,
			2572.56,
			2574.018,
			2576.17,
			2577.6,
			2579.032,
			2579.764,
			2581.222,
			2581.949,
			2583.399,
			2585.643,
			2587.147,
			2588.645,
			2590.173,
			2591.705,
			2592.468,
			2593.994,
			2595.509,
			2596.264,
			2597.771,
			2598.524,
			2600.017,
			2601.497,
			2603.71,
			2605.171,
			2607.349,
			2608.076,
			2608.805,
			2610.965,
			2612.404,
			2614.562,
			2615.976,
			2618.107,
			2619.525,
			2620.221,
			2621.62,
			2623.029,
			2623.738,
			2625.146,
			2626.554,
			2628.691,
			2629.411,
			2630.126,
			2631.568,
			2633.025,
			2633.76,
			2635.988,
			2637.491,
			2639.779,
			2641.328,
			2642.892,
			2645.274,
			2646.079,
			2646.888,
			2649.336,
			2650.995,
			2653.485,
			2655.153,
			2657.681,
			2659.368,
			2661.084,
			2661.962,
			2663.698,
			2664.572,
			2666.321,
			2668.097,
			2670.792,
			2672.609,
			2675.364,
			2676.291,
			2677.221,
			2680.048,
			2681.926,
			2684.805,
			2686.729,
			2688.65,
			2689.623,
			2691.591,
			2693.54,
			2696.51,
			2697.506,
			2698.506,
			2701.521,
			2703.546,
			2705.583,
			2706.606,
			2708.66,
			2710.726,
			2711.762,
			2713.844,
			2714.889,
			2716.987,
			2719.086,
			2722.238,
			2724.351,
			2727.541,
			2728.61,
			2729.681,
			2732.91,
			2735.075,
			2738.341,
			2740.531,
			2742.731,
			2743.835,
			2746.05,
			2747.161,
			2749.392,
			2751.633,
			2752.757,
			2753.883,
			2757.278,
			2760.697,
			2761.841,
			2762.988,
			2765.29,
			2767.601,
			2768.761,
			2772.255,
			2774.598,
			2776.95,
			2780.496,
			2782.871,
			2786.452,
			2787.65,
			2788.851,
			2792.466,
			2794.888,
			2798.537,
			2800.981,
			2803.433,
			2804.662,
			2807.126,
			2808.362,
			2810.84,
			2814.572,
			2817.071,
			2819.578,
			2823.354,
			2824.617,
			2825.882,
			2829.691,
			2830.964,
			2832.24,
			2836.081,
			2838.651,
			2841.229,
			2845.112,
			2847.71,
			2851.613,
			2852.915,
			2854.218,
			2858.137,
			2859.447,
			2860.759,
			2864.704,
			2867.344,
			2871.317,
			2873.975,
			2875.306,
			2877.974,
			2880.649,
			2881.989,
			2884.675,
			2887.369,
			2891.423,
			2894.134,
			2898.214,
			2899.578,
			2900.943,
			2905.05,
			2907.797,
			2911.93,
			2914.694,
			2917.465,
			2921.634,
			2923.027,
			2924.422,
			2928.616,
			2930.018,
			2931.421,
			2935.64,
			2938.461,
			2941.29,
			2942.707,
			2945.544,
			2948.388,
			2949.813,
			2954.097,
			2956.96,
			2959.831,
			2964.149,
			2967.035,
			2971.376,
			2972.826,
			2974.277,
			2978.641,
			2981.559,
			2985.946,
			2988.878,
			2991.818,
			2993.29,
			2996.238,
			2997.714,
			3000.672,
			3003.636,
			3005.12,
			3006.606,
			3011.073,
			3014.058,
			3017.048,
			3018.546,
			3021.546,
			3024.552,
			3026.058,
			3030.583,
			3033.607,
			3036.636,
			3041.192,
			3044.236,
			3048.811,
			3050.339,
			3051.869,
			3056.466,
			3059.538,
			3064.156,
			3067.241,
			3070.332,
			3071.88,
			3074.979,
			3076.531,
			3079.638,
			3084.309,
			3087.429,
			3090.554,
			3095.252,
			3096.821,
			3098.391,
			3101.534,
			3104.682,
			3106.258,
			3110.994,
			3114.157,
			3117.325,
			3122.086,
			3125.266,
			3130.044,
			3131.636,
			3133.229,
			3138.016,
			3139.614,
			3141.212,
			3146.015,
			3149.222,
			3152.433,
			3154.041,
			3157.259,
			3158.869,
			3162.094,
			3165.323,
			3166.939,
			3170.174,
			3173.414,
			3178.28,
			3181.53,
			3186.413,
			3188.042,
			3189.672,
			3192.936,
			3194.569,
			3197.838,
			3202.749,
			3206.029,
			3209.312,
			3214.246,
			3215.893,
			3219.189,
			3224.141,
			3225.794,
			3230.759,
			3234.073,
			3237.392,
			3239.053,
			3242.377,
			3245.706,
			3247.372,
			3252.374,
			3255.714,
			3259.059,
			3264.083,
			3267.438,
			3272.477,
			3274.159,
			3275.841,
			3280.895,
			3284.27,
			3289.338,
			3292.723,
			3297.806,
			3301.2,
			3302.898,
			3306.296,
			3309.7,
			3311.403,
			3313.107,
			3318.224,
			3321.642,
			3325.062,
			3326.774,
			3330.201,
			3333.631,
			3335.348,
			3340.501,
			3343.942,
			3347.387,
			3352.561,
			3356.015,
			3361.203,
			3362.934,
			3364.666,
			3369.865,
			3373.333,
			3378.54,
			3382.017,
			3387.241,
			3390.728,
			3392.474,
			3395.967,
			3399.465,
			3401.214,
			3404.716,
			3408.22,
			3413.485,
			3415.241,
			3416.998,
			3420.515,
			3424.035,
			3425.797,
			3431.086,
			3434.618,
			3438.153,
			3443.461,
			3447.003,
			3452.323,
			3454.098,
			3455.874,
			3461.207,
			3462.987,
			3464.767,
			3470.111,
			3473.668,
			3478.968,
			3482.463,
			3485.921,
			3487.635,
			3491.034,
			3492.719,
			3496.06,
			3499.361,
			3504.241,
			3505.848,
			3507.445,
			3512.179,
			3513.738,
			3515.287,
			3519.878,
			3522.893,
			3527.348,
			3530.27,
			3533.153,
			3534.58,
			3537.405,
			3538.802,
			3540.19,
			3545.644,
			3546.984,
			3550.946,
			3553.538,
			3557.354,
			3559.851,
			3562.31,
			3563.525,
			3567.117,
			3569.469,
			3571.787,
			3575.193,
			3577.417,
			3580.681,
			3581.751,
			3582.812,
			3585.936,
			3587.973,
			3590.958,
			3592.903,
			3594.81,
			3595.75,
			3597.602,
			3598.515,
			3600.317,
			3602.086,
			3602.958,
			3604.674,
			3606.354,
			3607.997,
			3609.605,
			3610.395,
			3611.961,
			3613.478,
			3614.224,
			3616.407,
			3617.818,
			3619.194,
			3621.196,
			3622.491,
			3624.369,
			3624.977,
			3625.577,
			3627.339,
			3628.481,
			3630.129,
			3631.207,
			3632.802,
			3633.844,
			3634.358,
			3635.383,
			3636.405,
			3636.916,
			3637.938,
			3638.976,
			3640.542,
			3641.063,
			3641.586,
			3643.158,
			3643.742,
			3644.353,
			3646.186,
			3647.414,
			3648.73,
			3651.109,
			3652.705,
			3655.425,
			3656.38,
			3657.337,
			3660.123,
			3661.938,
			3664.523,
			3666.111,
			3668.487,
			3669.766,
			3670.401,
			3671.676,
			3672.959,
			3673.604,
			3674.708,
			3675.809,
			3677.469,
			3678.583,
			3680.223,
			3680.771,
			3681.319,
			3682.974,
			3684.099,
			3685.866,
			3687.051,
			3688.246,
			3690.117,
			3690.756,
			3691.396,
			3693.328,
			3693.977,
			3694.649,
			3696.675,
			3698.036,
			3699.412,
			3700.109,
			3701.505,
			3702.9,
			3703.599,
			3705.006,
			3705.714,
			3707.133,
			3708.561,
			3710.713,
			3712.154,
			3714.326,
			3715.052,
			3715.77,
			3717.987,
			3719.457,
			3721.698,
			3723.211,
			3724.742,
			3725.52,
			3727.084,
			3727.873,
			3729.46,
			3731.881,
			3732.696,
			3735.162,
			3736.826,
			3738.504,
			3739.348,
			3741.036,
			3742.725,
			3743.574,
			3745.283,
			3746.143,
			3747.872,
			3749.614,
			3752.251,
			3754.026,
			3756.711,
			3757.613,
			3758.518,
			3761.252,
			3763.09,
			3765.872,
			3767.742,
			3769.625,
			3770.571,
			3772.473,
			3773.429,
			3775.349,
			3777.283,
			3778.254,
			3780.205,
			3782.169,
			3785.136,
			3786.132,
			3787.131,
			3790.144,
			3791.154,
			3792.167,
			3794.203,
			3795.225,
			3797.276,
			3799.339,
			3802.454,
			3804.544,
			3807.687,
			3808.734,
			3809.783,
			3812.945,
			3814.004,
			3815.065,
			3818.264,
			3820.408,
			3823.644,
			3825.813,
			3827.993,
			3829.087,
			3831.282,
			3832.383,
			3834.592,
			3836.811,
			3840.158,
			3842.401,
			3845.785,
			3846.917,
			3848.052,
			3851.472,
			3853.764,
			3857.22,
			3859.537,
			3861.862,
			3865.37,
			3866.544,
			3867.72,
			3871.262,
			3872.448,
			3873.636,
			3877.215,
			3879.612,
			3882.018,
			3883.224,
			3885.644,
			3888.073,
			3889.292,
			3891.734,
			3892.959,
			3895.416,
			3897.881,
			3898.816
		],
		[
			0,
			0.025,
			0.103,
			0.133,
			0.166,
			0.228,
			0.289,
			0.351,
			0.414,
			0.46,
			0.476,
			0.537,
			0.569,
			0.599,
			0.678,
			0.722,
			0.784,
			0.848,
			0.894,
			0.91,
			0.97,
			1.003,
			1.034,
			1.096,
			1.111,
			1.157,
			1.221,
			1.281,
			1.333,
			1.404,
			1.436,
			1.465,
			1.529,
			1.59,
			1.654,
			1.714,
			1.761,
			1.778,
			1.839,
			1.872,
			1.901,
			1.965,
			1.977,
			2.024,
			2.089,
			2.148,
			2.196,
			2.212,
			2.274,
			2.305,
			2.336,
			2.398,
			2.414,
			2.46,
			2.521,
			2.584,
			2.631,
			2.708,
			2.738,
			2.768,
			2.846,
			2.895,
			2.955,
			3.017,
			3.064,
			3.08,
			3.143,
			3.174,
			3.204,
			3.282,
			3.328,
			3.395,
			3.452,
			3.498,
			3.514,
			3.575,
			3.606,
			3.638,
			3.699,
			3.715,
			3.761,
			3.822,
			3.887,
			3.933,
			4.01,
			4.041,
			4.071,
			4.133,
			4.194,
			4.259,
			4.318,
			4.382,
			4.443,
			4.473,
			4.504,
			4.568,
			4.583,
			4.631,
			4.691,
			4.754,
			4.801,
			4.879,
			4.908,
			4.939,
			5.002,
			5.065,
			5.126,
			5.188,
			5.233,
			5.25,
			5.311,
			5.344,
			5.375,
			5.437,
			5.455,
			5.497,
			5.562,
			5.622,
			5.668,
			5.683,
			5.747,
			5.777,
			5.807,
			5.87,
			5.887,
			5.932,
			5.993,
			6.055,
			6.116,
			6.181,
			6.211,
			6.243,
			6.304,
			6.367,
			6.429,
			6.491,
			6.538,
			6.552,
			6.615,
			6.646,
			6.677,
			6.738,
			6.754,
			6.799,
			6.862,
			6.925,
			6.969,
			6.987,
			7.047,
			7.08,
			7.109,
			7.188,
			7.233,
			7.297,
			7.359,
			7.404,
			7.483,
			7.517,
			7.543,
			7.607,
			7.669,
			7.731,
			7.792,
			7.839,
			7.855,
			7.917,
			7.946,
			7.978,
			8.04,
			8.055,
			8.104,
			8.165,
			8.225,
			8.288,
			8.35,
			8.38,
			8.411,
			8.476,
			8.538,
			8.598,
			8.662,
			8.707,
			8.784,
			8.817,
			8.846,
			8.908,
			8.925,
			8.972,
			9.031,
			9.094,
			9.158,
			9.22,
			9.248,
			9.281,
			9.357,
			9.405,
			9.467,
			9.528,
			9.58,
			9.653,
			9.682,
			9.716,
			9.791,
			9.838,
			9.9,
			9.962,
			10.008,
			10.087,
			10.117,
			10.149,
			10.21,
			10.226,
			10.273,
			10.333,
			10.396,
			10.458,
			10.521,
			10.552,
			10.584,
			10.66,
			10.707,
			10.769,
			10.831,
			10.877,
			10.956,
			10.987,
			11.016,
			11.094,
			11.141,
			11.203,
			11.266,
			11.312,
			11.327,
			11.39,
			11.421,
			11.451,
			11.512,
			11.528,
			11.575,
			11.641,
			11.699,
			11.762,
			11.824,
			11.855,
			11.883,
			11.947,
			11.962,
			12.008,
			12.071,
			12.134,
			12.196,
			12.256,
			12.289,
			12.319,
			12.38,
			12.443,
			12.504,
			12.566,
			12.613,
			12.628,
			12.69,
			12.721,
			12.753,
			12.815,
			12.832,
			12.876,
			12.944,
			13.002,
			13.049,
			13.064,
			13.126,
			13.156,
			13.188,
			13.248,
			13.265,
			13.31,
			13.374,
			13.436,
			13.481,
			13.559,
			13.591,
			13.622,
			13.704,
			13.745,
			13.808,
			13.872,
			13.931,
			13.994,
			14.024,
			14.055,
			14.117,
			14.134,
			14.181,
			14.241,
			14.305,
			14.351,
			14.427,
			14.458,
			14.49,
			14.551,
			14.613,
			14.676,
			14.738,
			14.8,
			14.862,
			14.892,
			14.925,
			14.986,
			15.046,
			15.11,
			15.171,
			15.218,
			15.234,
			15.296,
			15.328,
			15.358,
			15.421,
			15.436,
			15.481,
			15.543,
			15.605,
			15.653,
			15.729,
			15.764,
			15.792,
			15.855,
			15.916,
			15.977,
			16.04,
			16.087,
			16.1,
			16.165,
			16.193,
			16.227,
			16.288,
			16.305,
			16.351,
			16.411,
			16.474,
			16.536,
			16.598,
			16.629,
			16.661,
			16.721,
			16.737,
			16.783,
			16.845,
			16.909,
			16.956,
			17.032,
			17.063,
			17.095,
			17.172,
			17.219,
			17.28,
			17.341,
			17.405,
			17.465,
			17.497,
			17.529,
			17.589,
			17.605,
			17.652,
			17.714,
			17.777,
			17.825,
			17.9,
			17.93,
			17.963,
			18.023,
			18.041,
			18.086,
			18.148,
			18.21,
			18.257,
			18.333,
			18.366,
			18.395,
			18.457,
			18.521,
			18.582,
			18.645,
			18.691,
			18.706,
			18.769,
			18.799,
			18.831,
			18.907,
			18.954,
			19.018,
			19.078,
			19.139,
			19.203,
			19.235,
			19.264,
			19.327,
			19.389,
			19.45,
			19.514,
			19.574,
			19.638,
			19.667,
			19.699,
			19.76,
			19.776,
			19.823,
			19.885,
			19.946,
			19.994,
			20.01,
			20.071,
			20.102,
			20.134,
			20.195,
			20.21,
			20.258,
			20.319,
			20.379,
			20.444,
			20.504,
			20.536,
			20.566,
			20.628,
			20.69,
			20.754,
			20.813,
			20.861,
			20.877,
			20.939,
			20.97,
			21.002,
			21.078,
			21.125,
			21.188,
			21.25,
			21.294,
			21.31,
			21.373,
			21.405,
			21.434,
			21.498,
			21.511,
			21.56,
			21.625,
			21.682,
			21.728,
			21.807,
			21.838,
			21.868,
			21.931,
			21.992,
			22.054,
			22.115,
			22.18,
			22.242,
			22.272,
			22.304,
			22.366,
			22.379,
			22.426,
			22.489,
			22.551,
			22.613,
			22.674,
			22.705,
			22.737,
			22.799,
			22.815,
			22.86,
			22.923,
			22.985,
			23.046,
			23.11,
			23.141,
			23.172,
			23.234,
			23.296,
			23.357,
			23.419,
			23.467,
			23.48,
			23.542,
			23.575,
			23.604,
			23.668,
			23.683,
			23.729,
			23.791,
			23.854,
			23.901,
			23.978,
			24.012,
			24.038,
			24.1,
			24.162,
			24.225,
			24.288,
			24.333,
			24.411,
			24.441,
			24.474,
			24.552,
			24.598,
			24.66,
			24.72,
			24.784,
			24.846,
			24.878,
			24.907,
			24.97,
			24.986,
			25.032,
			25.094,
			25.156,
			25.217,
			25.278,
			25.312,
			25.341,
			25.403,
			25.465,
			25.527,
			25.59,
			25.637,
			25.653,
			25.714,
			25.744,
			25.774,
			25.838,
			25.853,
			25.901,
			25.963,
			26.022,
			26.075,
			26.149,
			26.178,
			26.208,
			26.271,
			26.288,
			26.334,
			26.394,
			26.457,
			26.505,
			26.583,
			26.613,
			26.644,
			26.721,
			26.768,
			26.83,
			26.89,
			26.938,
			26.954,
			27.015,
			27.047,
			27.077,
			27.139,
			27.156,
			27.202,
			27.265,
			27.327,
			27.388,
			27.451,
			27.48,
			27.511,
			27.59,
			27.637,
			27.699,
			27.761,
			27.808,
			27.884,
			27.916,
			27.945,
			28.009,
			28.025,
			28.071,
			28.138,
			28.195,
			28.241,
			28.257,
			28.317,
			28.35,
			28.379,
			28.441,
			28.457,
			28.504,
			28.566,
			28.628,
			28.673,
			28.69,
			28.753,
			28.783,
			28.812,
			28.891,
			28.938,
			28.999,
			29.061,
			29.11,
			29.187,
			29.217,
			29.246,
			29.309,
			29.325,
			29.373,
			29.435,
			29.497,
			29.557,
			29.621,
			29.65,
			29.683,
			29.743,
			29.76,
			29.807,
			29.869,
			29.929,
			29.975,
			30.054,
			30.084,
			30.116,
			30.177,
			30.2,
			30.241,
			30.304,
			30.365,
			30.411,
			30.427,
			30.489,
			30.521,
			30.551,
			30.613,
			30.628,
			30.674,
			30.737,
			30.799,
			30.862,
			30.923,
			30.955,
			30.984,
			31.047,
			31.062,
			31.108,
			31.172,
			31.234,
			31.279,
			31.357,
			31.386,
			31.42,
			31.495,
			31.543,
			31.606,
			31.668,
			31.712,
			31.728,
			31.79,
			31.822,
			31.853,
			31.915,
			31.931,
			31.978,
			32.04,
			32.098,
			32.162,
			32.225,
			32.287,
			32.35,
			32.411,
			32.473,
			32.533,
			32.581,
			32.658,
			32.69,
			32.721,
			32.782,
			32.844,
			32.908,
			32.969,
			33.031,
			33.092,
			33.125,
			33.154,
			33.233,
			33.277,
			33.34,
			33.404,
			33.451,
			33.528,
			33.557,
			33.588,
			33.652,
			33.712,
			33.778,
			33.838,
			33.899,
			33.962,
			33.992,
			34.022,
			34.084,
			34.148,
			34.208,
			34.271,
			34.323,
			34.334,
			34.395,
			34.426,
			34.457,
			34.518,
			34.533,
			34.58,
			34.642,
			34.704,
			34.75,
			34.828,
			34.86,
			34.891,
			34.954,
			35.013,
			35.078,
			35.14,
			35.187,
			35.201,
			35.262,
			35.295,
			35.326,
			35.387,
			35.403,
			35.45,
			35.512,
			35.573,
			35.62,
			35.636,
			35.697,
			35.728,
			35.759,
			35.821,
			35.838,
			35.882,
			35.944,
			36.008,
			36.053,
			36.133,
			36.163,
			36.192,
			36.257,
			36.317,
			36.379,
			36.44,
			36.489,
			36.505,
			36.565,
			36.595,
			36.628,
			36.706,
			36.753,
			36.814,
			36.876,
			36.938,
			36.999,
			37.03,
			37.062,
			37.124,
			37.14,
			37.186,
			37.247,
			37.311,
			37.355,
			37.432,
			37.466,
			37.494,
			37.557,
			37.62,
			37.681,
			37.743,
			37.789,
			37.805,
			37.867,
			37.899,
			37.929,
			37.991,
			38.007,
			38.054,
			38.116,
			38.178,
			38.24,
			38.303,
			38.333,
			38.364,
			38.425,
			38.488,
			38.549,
			38.611,
			38.658,
			38.737,
			38.765,
			38.797,
			38.859,
			38.875,
			38.92,
			38.982,
			39.045,
			39.093,
			39.107,
			39.17,
			39.2,
			39.233,
			39.295,
			39.308,
			39.356,
			39.419,
			39.48,
			39.528,
			39.604,
			39.635,
			39.666,
			39.743,
			39.79,
			39.853,
			39.914,
			39.96,
			39.976,
			40.038,
			40.069,
			40.1,
			40.162,
			40.178,
			40.225,
			40.288,
			40.347,
			40.411,
			40.471,
			40.508,
			40.532,
			40.595,
			40.612,
			40.659,
			40.721,
			40.782,
			40.829,
			40.906,
			40.937,
			40.968,
			41.031,
			41.092,
			41.155,
			41.216,
			41.263,
			41.279,
			41.341,
			41.371,
			41.4,
			41.465,
			41.481,
			41.527,
			41.59,
			41.648,
			41.697,
			41.713,
			41.775,
			41.804,
			41.837,
			41.899,
			41.914,
			41.961,
			42.023,
			42.086,
			42.13,
			42.208,
			42.238,
			42.27,
			42.333,
			42.395,
			42.458,
			42.517,
			42.582,
			42.643,
			42.675,
			42.705,
			42.766,
			42.781,
			42.829,
			42.89,
			42.953,
			43.016,
			43.076,
			43.107,
			43.14,
			43.202,
			43.264,
			43.325,
			43.388,
			43.449,
			43.511,
			43.542,
			43.574,
			43.636,
			43.696,
			43.758,
			43.821,
			43.882,
			43.944,
			43.976,
			44.007,
			44.07,
			44.082,
			44.132,
			44.193,
			44.254,
			44.301,
			44.38,
			44.408,
			44.44,
			44.504,
			44.566,
			44.628,
			44.687,
			44.736,
			44.813,
			44.845,
			44.874,
			44.937,
			44.953,
			44.999,
			45.062,
			45.122,
			45.17,
			45.184,
			45.247,
			45.279,
			45.31,
			45.371,
			45.387,
			45.432,
			45.5,
			45.557,
			45.605,
			45.68,
			45.713,
			45.743,
			45.806,
			45.867,
			45.93,
			45.99,
			46.037,
			46.054,
			46.116,
			46.147,
			46.177,
			46.256,
			46.299,
			46.364,
			46.424,
			46.471,
			46.488,
			46.549,
			46.581,
			46.611,
			46.672,
			46.691,
			46.736,
			46.798,
			46.86,
			46.905,
			46.982,
			47.013,
			47.045,
			47.121,
			47.17,
			47.233,
			47.294,
			47.339,
			47.355,
			47.416,
			47.447,
			47.479,
			47.54,
			47.557,
			47.604,
			47.666,
			47.727,
			47.775,
			47.852,
			47.884,
			47.914,
			47.977,
			48.038,
			48.101,
			48.162,
			48.225,
			48.285,
			48.316,
			48.348,
			48.41,
			48.425,
			48.473,
			48.534,
			48.595,
			48.643,
			48.657,
			48.72,
			48.754,
			48.781,
			48.844,
			48.86,
			48.904,
			48.966,
			49.031,
			49.077,
			49.154,
			49.184,
			49.216,
			49.277,
			49.34,
			49.4,
			49.464,
			49.509,
			49.525,
			49.589,
			49.62,
			49.651,
			49.712,
			49.727,
			49.775,
			49.837,
			49.898,
			49.943,
			49.959,
			50.021,
			50.054,
			50.083,
			50.145,
			50.161,
			50.207,
			50.271,
			50.331,
			50.38,
			50.456,
			50.488,
			50.519,
			50.58,
			50.642,
			50.704,
			50.767,
			50.829,
			50.89,
			50.921,
			50.953,
			51.014,
			51.029,
			51.075,
			51.138,
			51.2,
			51.262,
			51.325,
			51.356,
			51.387,
			51.464,
			51.511,
			51.571,
			51.633,
			51.68,
			51.759,
			51.79,
			51.82,
			51.88,
			51.943,
			52.008,
			52.068,
			52.131,
			52.191,
			52.222,
			52.254,
			52.315,
			52.332,
			52.377,
			52.44,
			52.502,
			52.55,
			52.626,
			52.657,
			52.687,
			52.751,
			52.812,
			52.88,
			52.937,
			52.983,
			52.999,
			53.062,
			53.092,
			53.121,
			53.199,
			53.247,
			53.308,
			53.371,
			53.418,
			53.433,
			53.494,
			53.527,
			53.557,
			53.619,
			53.635,
			53.679,
			53.743,
			53.805,
			53.85,
			53.927,
			53.96,
			53.99,
			54.053,
			54.114,
			54.178,
			54.238,
			54.299,
			54.364,
			54.392,
			54.424,
			54.487,
			54.501,
			54.548,
			54.612,
			54.671,
			54.719,
			54.734,
			54.797,
			54.827,
			54.857,
			54.921,
			54.94,
			54.983,
			55.045,
			55.107,
			55.169,
			55.229,
			55.263,
			55.292,
			55.355,
			55.416,
			55.481,
			55.541,
			55.587,
			55.604,
			55.666,
			55.697,
			55.726,
			55.789,
			55.804,
			55.852,
			55.913,
			55.973,
			56.02,
			56.036,
			56.099,
			56.129,
			56.162,
			56.238,
			56.284,
			56.347,
			56.41,
			56.471,
			56.533,
			56.565,
			56.596,
			56.673,
			56.718,
			56.781,
			56.842,
			56.891,
			56.904,
			56.967,
			57.002,
			57.029,
			57.107,
			57.154,
			57.214,
			57.278,
			57.34,
			57.402,
			57.432,
			57.463,
			57.526,
			57.588,
			57.649,
			57.711,
			57.774,
			57.836,
			57.866,
			57.897,
			57.958,
			58.022,
			58.085,
			58.146,
			58.191,
			58.207,
			58.27,
			58.3,
			58.331,
			58.394,
			58.409,
			58.455,
			58.516,
			58.579,
			58.626,
			58.703,
			58.734,
			58.765,
			58.829,
			58.89,
			58.952,
			59.014,
			59.067,
			59.075,
			59.137,
			59.168,
			59.2,
			59.262,
			59.278,
			59.325,
			59.386,
			59.448,
			59.495,
			59.509,
			59.574,
			59.601,
			59.632,
			59.696,
			59.711,
			59.758,
			59.82,
			59.882,
			59.943,
			60.006,
			60.038,
			60.067,
			60.131,
			60.191,
			60.254,
			60.316,
			60.378,
			60.44,
			60.472,
			60.502,
			60.564,
			60.579,
			60.627,
			60.688,
			60.75,
			60.813,
			60.875,
			60.904,
			60.936,
			60.997,
			61.014,
			61.06,
			61.124,
			61.182,
			61.231,
			61.307,
			61.338,
			61.37,
			61.433,
			61.494,
			61.557,
			61.616,
			61.665,
			61.679,
			61.74,
			61.771,
			61.804,
			61.866,
			61.882,
			61.927,
			61.991,
			62.052,
			62.098,
			62.114,
			62.175,
			62.206,
			62.238,
			62.301,
			62.362,
			62.423,
			62.487,
			62.533,
			62.609,
			62.64,
			62.672,
			62.75,
			62.796,
			62.857,
			62.921,
			62.966,
			62.983,
			63.043,
			63.074,
			63.107,
			63.169,
			63.188,
			63.231,
			63.291,
			63.354,
			63.398,
			63.415,
			63.478,
			63.51,
			63.54,
			63.617,
			63.664,
			63.727,
			63.787,
			63.834,
			63.913,
			63.942,
			63.975,
			64.036,
			64.053,
			64.098,
			64.161,
			64.221,
			64.269,
			64.282,
			64.347,
			64.378,
			64.408,
			64.47,
			64.487,
			64.533,
			64.596,
			64.655,
			64.704,
			64.781,
			64.811,
			64.844,
			64.905,
			64.966,
			65.028,
			65.09,
			65.137,
			65.151,
			65.215,
			65.249,
			65.275,
			65.339,
			65.355,
			65.399,
			65.463,
			65.524,
			65.57,
			65.586,
			65.649,
			65.679,
			65.71,
			65.772,
			65.788,
			65.833,
			65.902,
			65.957,
			66.005,
			66.084,
			66.113,
			66.145,
			66.206,
			66.267,
			66.331,
			66.393,
			66.455,
			66.517,
			66.548,
			66.578,
			66.641,
			66.655,
			66.703,
			66.766,
			66.827,
			66.873,
			66.887,
			66.95,
			66.982,
			67.013,
			67.075,
			67.137,
			67.2,
			67.26,
			67.324,
			67.384,
			67.415,
			67.447,
			67.51,
			67.57,
			67.632,
			67.696,
			67.743,
			67.757,
			67.819,
			67.85,
			67.882,
			67.944,
			67.958,
			68.004,
			68.067,
			68.129,
			68.174,
			68.191,
			68.253,
			68.285,
			68.313,
			68.392,
			68.439,
			68.503,
			68.562,
			68.61,
			68.625,
			68.688,
			68.717,
			68.749,
			68.827,
			68.872,
			68.936,
			68.996,
			69.058,
			69.122,
			69.153,
			69.183,
			69.261,
			69.306,
			69.373,
			69.431,
			69.478,
			69.554,
			69.584,
			69.615,
			69.696,
			69.742,
			69.805,
			69.865,
			69.91,
			69.927,
			69.988,
			70.021,
			70.051,
			70.129,
			70.176,
			70.241,
			70.299,
			70.347,
			70.361,
			70.423,
			70.454,
			70.486,
			70.548,
			70.562,
			70.609,
			70.672,
			70.734,
			70.779,
			70.858,
			70.887,
			70.921,
			70.982,
			71.043,
			71.105,
			71.169,
			71.229,
			71.293,
			71.321,
			71.354,
			71.417,
			71.437,
			71.478,
			71.541,
			71.602,
			71.647,
			71.663,
			71.724,
			71.757,
			71.787,
			71.865,
			71.913,
			71.975,
			72.036,
			72.083,
			72.161,
			72.19,
			72.22,
			72.282,
			72.346,
			72.407,
			72.468,
			72.532,
			72.593,
			72.626,
			72.654,
			72.719,
			72.734,
			72.779,
			72.84,
			72.905,
			72.967,
			73.029,
			73.059,
			73.088,
			73.151,
			73.213,
			73.275,
			73.339,
			73.385,
			73.401,
			73.463,
			73.523,
			73.584,
			73.601,
			73.646,
			73.711,
			73.771,
			73.819,
			73.834,
			73.897,
			73.927,
			73.959,
			74.021,
			74.038,
			74.081,
			74.145,
			74.207,
			74.253,
			74.329,
			74.362,
			74.392,
			74.454,
			74.517,
			74.579,
			74.64,
			74.688,
			74.701,
			74.765,
			74.795,
			74.825,
			74.888,
			74.905,
			74.949,
			75.011,
			75.075,
			75.121,
			75.137,
			75.198,
			75.229,
			75.26,
			75.338,
			75.386,
			75.446,
			75.508,
			75.571,
			75.631,
			75.662,
			75.695,
			75.757,
			75.816,
			75.882,
			75.943,
			75.989,
			76.005,
			76.066,
			76.099,
			76.128,
			76.206,
			76.253,
			76.314,
			76.377,
			76.422,
			76.438,
			76.501,
			76.531,
			76.561,
			76.625,
			76.687,
			76.749,
			76.812,
			76.858,
			76.935,
			76.967,
			76.997,
			77.059,
			77.075,
			77.12,
			77.181,
			77.244,
			77.307,
			77.368,
			77.398,
			77.431,
			77.492,
			77.507,
			77.555,
			77.621,
			77.677,
			77.726,
			77.803,
			77.834,
			77.865,
			77.926,
			77.988,
			78.051,
			78.113,
			78.16,
			78.237,
			78.269,
			78.299,
			78.361,
			78.376,
			78.421,
			78.483,
			78.548,
			78.594,
			78.607,
			78.671,
			78.701,
			78.732,
			78.81,
			78.858,
			78.921,
			78.982,
			79.043,
			79.104,
			79.134,
			79.168,
			79.229,
			79.291,
			79.355,
			79.416,
			79.462,
			79.476,
			79.539,
			79.571,
			79.601,
			79.663,
			79.684,
			79.724,
			79.788,
			79.849,
			79.896,
			79.912,
			79.972,
			80.005,
			80.035,
			80.112,
			80.159,
			80.221,
			80.284,
			80.33,
			80.407,
			80.438,
			80.468,
			80.53,
			80.594,
			80.655,
			80.716,
			80.763,
			80.779,
			80.84,
			80.872,
			80.904,
			80.981,
			81.028,
			81.089,
			81.15,
			81.199,
			81.214,
			81.274,
			81.306,
			81.338,
			81.4,
			81.46,
			81.524,
			81.584,
			81.631,
			81.71,
			81.745,
			81.772,
			81.834,
			81.85,
			81.897,
			81.958,
			82.02,
			82.065,
			82.083,
			82.144,
			82.174,
			82.207,
			82.268,
			82.284,
			82.329,
			82.393,
			82.455,
			82.501,
			82.577,
			82.609,
			82.639,
			82.699,
			82.718,
			82.765,
			82.827,
			82.887,
			82.933,
			83.013,
			83.042,
			83.075,
			83.152,
			83.198,
			83.26,
			83.323,
			83.367,
			83.384,
			83.444,
			83.477,
			83.509,
			83.586,
			83.631,
			83.693,
			83.755,
			83.805,
			83.878,
			83.912,
			83.94,
			84.005,
			84.066,
			84.128,
			84.189,
			84.253,
			84.314,
			84.344,
			84.376,
			84.437,
			84.454,
			84.499,
			84.562,
			84.623,
			84.671,
			84.687,
			84.748,
			84.78,
			84.811,
			84.888,
			84.934,
			84.995,
			85.057,
			85.106,
			85.183,
			85.212,
			85.243,
			85.305,
			85.369,
			85.432,
			85.492,
			85.554,
			85.616,
			85.646,
			85.678,
			85.74,
			85.755,
			85.803,
			85.869,
			85.927,
			85.974,
			85.987,
			86.048,
			86.081,
			86.113,
			86.175,
			86.237,
			86.298,
			86.361,
			86.422,
			86.485,
			86.515,
			86.546,
			86.608,
			86.625,
			86.671,
			86.732,
			86.795,
			86.857,
			86.919,
			86.95,
			86.981,
			87.041,
			87.057,
			87.105,
			87.165,
			87.229,
			87.273,
			87.289,
			87.353,
			87.384,
			87.414,
			87.477,
			87.538,
			87.6,
			87.662,
			87.71,
			87.726,
			87.786,
			87.816,
			87.849,
			87.93,
			87.972,
			88.033,
			88.096,
			88.16,
			88.221,
			88.253,
			88.281,
			88.36,
			88.406,
			88.469,
			88.532,
			88.591,
			88.653,
			88.684,
			88.716,
			88.779,
			88.841,
			88.903,
			88.964,
			89.011,
			89.028,
			89.089,
			89.12,
			89.15,
			89.214,
			89.228,
			89.275,
			89.337,
			89.399,
			89.445,
			89.46,
			89.523,
			89.553,
			89.583,
			89.663,
			89.71,
			89.772,
			89.833,
			89.879,
			89.956,
			89.993,
			90.019,
			90.096,
			90.143,
			90.204,
			90.265,
			90.33,
			90.392,
			90.422,
			90.453,
			90.516,
			90.532,
			90.577,
			90.638,
			90.702,
			90.747,
			90.763,
			90.825,
			90.857,
			90.888,
			90.95,
			91.01,
			91.074,
			91.136,
			91.18,
			91.26,
			91.29,
			91.32,
			91.399,
			91.444,
			91.506,
			91.57,
			91.632,
			91.693,
			91.723,
			91.756,
			91.817,
			91.831,
			91.878,
			91.942,
			92.003,
			92.054,
			92.128,
			92.157,
			92.188,
			92.252,
			92.314,
			92.376,
			92.437,
			92.483,
			92.562,
			92.593,
			92.624,
			92.686,
			92.702,
			92.746,
			92.81,
			92.87,
			92.919,
			92.934,
			92.994,
			93.027,
			93.056,
			93.12,
			93.136,
			93.181,
			93.243,
			93.304,
			93.351,
			93.43,
			93.461,
			93.491,
			93.553,
			93.616,
			93.679,
			93.739,
			93.787,
			93.802,
			93.863,
			93.895,
			93.927,
			94.003,
			94.049,
			94.116,
			94.174,
			94.221,
			94.236,
			94.296,
			94.328,
			94.359,
			94.42,
			94.438,
			94.482,
			94.546,
			94.608,
			94.67,
			94.732,
			94.763,
			94.793,
			94.854,
			94.919,
			94.98,
			95.04,
			95.088,
			95.104,
			95.166,
			95.197,
			95.229,
			95.29,
			95.304,
			95.353,
			95.413,
			95.475,
			95.522,
			95.537,
			95.6,
			95.63,
			95.66,
			95.723,
			95.739,
			95.786,
			95.849,
			95.909,
			95.957,
			96.035,
			96.064,
			96.097,
			96.157,
			96.178,
			96.221,
			96.283,
			96.345,
			96.405,
			96.467,
			96.5,
			96.531,
			96.593,
			96.608,
			96.654,
			96.717,
			96.778,
			96.825,
			96.903,
			96.934,
			96.965,
			97.025,
			97.087,
			97.15,
			97.212,
			97.257,
			97.336,
			97.367,
			97.398,
			97.46,
			97.475,
			97.522,
			97.584,
			97.646,
			97.694,
			97.707,
			97.77,
			97.801,
			97.831,
			97.895,
			97.908,
			97.957,
			98.019,
			98.034
		]
	],
	'2472_3383_7982': [
		[
			103.486
		],
		[
			0
		],
		[
			0,
			1.973,
			4.427,
			5.657,
			8.125,
			10.601,
			14.332,
			15.58,
			16.831,
			20.595,
			23.115,
			25.643,
			26.91,
			29.451,
			32,
			35.839,
			38.408,
			42.278,
			43.572,
			44.868,
			48.769,
			51.379,
			55.295,
			57.912,
			60.536,
			61.851,
			64.487,
			67.131,
			68.455,
			71.109,
			72.439,
			75.104,
			77.776,
			81.797,
			84.485,
			88.53,
			89.881,
			91.234,
			95.3,
			98.02,
			102.111,
			104.847,
			107.586,
			111.684,
			113.043,
			114.397,
			118.415,
			119.738,
			121.051,
			124.934,
			127.476,
			131.215,
			133.662,
			136.072,
			137.264,
			139.622,
			140.789,
			143.095,
			145.363,
			148.697,
			150.874,
			154.07,
			155.117,
			156.154,
			159.212,
			161.206,
			164.126,
			166.028,
			167.91,
			168.831,
			170.654,
			171.557,
			173.351,
			175.127,
			176.01,
			176.89,
			179.516,
			181.25,
			182.978,
			183.841,
			185.556,
			187.266,
			188.121,
			189.829,
			190.675,
			192.366,
			194.057,
			196.572,
			198.245,
			200.774,
			201.626,
			202.478,
			205.046,
			206.884,
			209.635,
			211.653,
			213.695,
			214.714,
			216.967,
			218.11,
			220.393,
			222.678,
			223.817,
			225.977,
			227.976,
			230.872,
			231.758,
			232.642,
			235.29,
			236.14,
			236.99,
			238.688,
			239.538,
			241.226,
			242.907,
			245.446,
			247.134,
			249.688,
			250.546,
			251.399,
			253.974,
			255.706,
			258.33,
			260.094,
			261.883,
			262.786,
			264.6,
			266.434,
			267.365,
			269.236,
			270.176,
			272.072,
			273.988,
			276.884,
			277.856,
			278.831,
			281.775,
			282.762,
			283.751,
			286.738,
			288.728,
			291.758,
			293.788,
			295.829,
			298.91,
			299.942,
			302.014,
			305.14,
			306.187,
			309.337,
			311.435,
			313.537,
			314.591,
			316.708,
			318.834,
			319.9,
			322.04,
			323.114,
			325.268,
			327.431,
			330.696,
			332.884,
			336.183,
			337.284,
			338.385,
			341.675,
			343.847,
			347.054,
			349.149,
			351.206,
			352.221,
			354.221,
			355.208,
			356.185,
			359.059,
			359.998,
			361.848,
			363.664,
			365.444,
			368.049,
			369.74,
			372.211,
			373.838,
			374.636,
			376.22,
			377.794,
			380.152,
			381.725,
			384.087,
			384.873,
			385.659,
			388.017,
			389.588,
			391.935,
			393.5,
			395.082,
			395.874,
			397.455,
			398.245,
			399.876,
			401.538,
			402.368,
			404.031,
			405.794,
			408.432,
			409.319,
			410.221,
			412.022,
			413.821,
			414.717,
			416.499,
			417.388,
			419.164,
			420.891,
			423.468,
			425.173,
			427.706,
			428.549,
			429.391,
			431.933,
			433.624,
			436.17,
			437.872,
			439.574,
			440.423,
			442.114,
			442.96,
			444.649,
			446.302,
			447.123,
			448.764,
			450.396,
			452.801,
			454.402,
			456.829,
			457.639,
			458.447,
			460.922,
			462.636,
			465.198,
			467.063,
			468.921,
			471.766,
			472.734,
			473.701,
			476.563,
			477.496,
			478.429,
			481.153,
			482.837,
			484.521,
			485.362,
			486.917,
			488.453,
			489.22,
			491.492,
			492.974,
			494.454,
			496.676,
			498.165,
			500.408,
			501.16,
			501.917,
			504.186,
			505.719,
			508.057,
			509.634,
			511.224,
			512.024,
			513.652,
			514.465,
			516.102,
			518.569,
			520.234,
			521.909,
			523.611,
			526.19,
			527.929,
			529.686,
			530.574,
			532.345,
			533.243,
			535.052,
			536.878,
			539.641,
			541.502,
			544.318,
			545.264,
			546.213,
			549.081,
			551.01,
			553.927,
			555.889,
			558.855,
			560.849,
			561.851,
			563.864,
			565.888,
			566.904,
			568.946,
			570.996,
			574.093,
			575.13,
			576.168,
			579.303,
			580.352,
			581.401,
			584.548,
			586.658,
			588.779,
			591.984,
			594.135,
			597.387,
			598.474,
			599.561,
			602.84,
			605.002,
			608.238,
			610.39,
			612.546,
			613.621,
			615.777,
			616.857,
			619.012,
			621.173,
			622.253,
			624.407,
			626.572,
			629.807,
			631.966,
			635.188,
			636.254,
			637.317,
			640.49,
			642.581,
			645.702,
			647.768,
			649.826,
			652.906,
			653.929,
			654.952,
			658.027,
			659.051,
			660.077,
			663.177,
			665.249,
			667.333,
			668.375,
			670.462,
			673.601,
			675.697,
			676.746,
			678.849,
			680.957,
			684.132,
			686.261,
			689.473,
			690.551,
			691.633,
			694.891,
			697.082,
			700.373,
			702.567,
			704.752,
			705.838,
			707.996,
			709.066,
			710.129,
			713.252,
			714.275,
			715.288,
			718.25,
			720.164,
			722.041,
			722.966,
			724.807,
			726.586,
			727.461,
			729.187,
			730.038,
			731.696,
			733.318,
			735.687,
			737.212,
			739.438,
			740.165,
			740.879,
			742.967,
			744.318,
			746.284,
			747.558,
			749.419,
			750.63,
			751.229,
			752.414,
			754.169,
			755.333,
			756.499,
			758.246,
			758.828,
			759.409,
			761.151,
			761.731,
			762.31,
			764.04,
			765.188,
			766.336,
			768.043,
			769.172,
			770.862,
			771.424,
			771.984,
			773.652,
			774.757,
			776.402,
			777.486,
			779.097,
			780.165,
			780.698,
			781.757,
			782.808,
			783.331,
			784.372,
			785.408,
			786.956,
			787.467,
			787.977,
			789.497,
			790.001,
			790.505,
			792.031,
			793.041,
			794.546,
			795.548,
			796.584,
			798.128,
			798.641,
			799.155,
			800.705,
			801.779,
			803.384,
			804.455,
			805.533,
			806.094,
			807.219,
			808.345,
			808.91,
			810.045,
			810.619,
			811.85,
			813.086,
			814.953,
			816.271,
			818.354,
			819.053,
			819.755,
			821.964,
			823.487,
			825.801,
			827.38,
			828.975,
			829.779,
			831.35,
			832.112,
			833.648,
			835.202,
			835.953,
			836.662,
			838.809,
			840.261,
			842.296,
			843.641,
			844.999,
			845.683,
			847.034,
			847.712,
			849.075,
			850.452,
			852.645,
			854.128,
			856.405,
			857.197,
			857.991,
			860.383,
			862.01,
			864.472,
			866.119,
			867.769,
			868.595,
			870.25,
			871.075,
			872.714,
			874.344,
			875.167,
			876.803,
			878.439,
			880.918,
			881.741,
			882.568,
			885.067,
			885.906,
			886.74,
			889.242,
			890.922,
			892.614,
			895.154,
			896.842,
			899.355,
			900.174,
			900.986,
			903.38,
			904.931,
			907.195,
			908.651,
			910.095,
			910.798,
			912.187,
			913.551,
			914.225,
			916.222,
			917.541,
			918.85,
			920.798,
			921.445,
			922.09,
			924.015,
			924.653,
			925.29,
			926.562,
			927.196,
			928.461,
			929.72,
			931.608,
			932.87,
			934.756,
			935.384,
			936.012,
			937.935,
			938.576,
			939.218,
			941.143,
			942.485,
			943.828,
			944.5,
			945.846,
			947.244,
			947.951,
			950.068,
			951.485,
			952.941,
			955.11,
			956.557,
			958.759,
			959.487,
			960.214,
			962.404,
			963.863,
			966.032,
			967.451,
			969.548,
			970.936,
			971.612,
			972.906,
			974.184,
			974.818,
			975.45,
			977.297,
			978.496,
			979.693,
			980.291,
			981.489,
			982.679,
			983.273,
			984.46,
			985.054,
			986.24,
			987.431,
			989.214,
			990.401,
			992.191,
			992.781,
			993.382,
			995.204,
			996.424,
			998.322,
			999.604,
			1000.901,
			1001.562,
			1002.957,
			1003.66,
			1005.078,
			1006.519,
			1007.296,
			1008.861,
			1010.442,
			1012.928,
			1014.644,
			1017.239,
			1018.105,
			1018.973,
			1020.718,
			1021.589,
			1023.179,
			1025.578,
			1027.131,
			1028.609,
			1030.842,
			1031.589,
			1032.315,
			1034.498,
			1035.956,
			1038.165,
			1039.645,
			1041.128,
			1041.873,
			1043.375,
			1044.127,
			1045.634,
			1047.914,
			1049.433,
			1050.975,
			1053.32,
			1054.905,
			1057.313,
			1058.124,
			1058.939,
			1061.405,
			1063.069,
			1064.747,
			1065.59,
			1067.277,
			1068.967,
			1071.528,
			1072.391,
			1073.257,
			1075.878,
			1077.648,
			1080.348,
			1082.158,
			1084.906,
			1086.755,
			1088.629,
			1089.571,
			1091.465,
			1092.429,
			1094.378,
			1096.339,
			1099.383,
			1101.436,
			1104.598,
			1105.661,
			1106.727,
			1109.999,
			1112.19,
			1115.431,
			1117.555,
			1119.6,
			1120.625,
			1122.652,
			1123.645,
			1125.638,
			1127.641,
			1128.648,
			1130.667,
			1132.704,
			1134.764,
			1136.836,
			1137.878,
			1139.963,
			1142.054,
			1143.108,
			1146.284,
			1148.421,
			1150.571,
			1153.821,
			1156.004,
			1159.28,
			1160.383,
			1161.489,
			1164.819,
			1167.05,
			1170.414,
			1172.67,
			1176.074,
			1178.358,
			1180.654,
			1181.806,
			1184.118,
			1185.278,
			1187.607,
			1189.949,
			1193.483,
			1194.667,
			1195.853,
			1198.237,
			1200.637,
			1201.84,
			1204.268,
			1205.488,
			1207.956,
			1210.399,
			1214.113,
			1216.602,
			1220.349,
			1221.603,
			1222.879,
			1226.634,
			1229.159,
			1232.959,
			1235.496,
			1238.04,
			1239.312,
			1241.861,
			1243.136,
			1245.687,
			1248.24,
			1249.514,
			1252.068,
			1254.614,
			1258.437,
			1260.985,
			1264.82,
			1266.101,
			1267.385,
			1271.259,
			1273.852,
			1277.792,
			1280.425,
			1283.068,
			1287.067,
			1288.41,
			1289.76,
			1293.83,
			1296.567,
			1300.701,
			1303.479,
			1307.668,
			1310.538,
			1313.395,
			1314.835,
			1317.72,
			1319.17,
			1322.073,
			1324.939,
			1329.188,
			1331.977,
			1336.129,
			1337.511,
			1338.885,
			1343,
			1345.715,
			1349.852,
			1352.599,
			1356.721,
			1359.473,
			1360.849,
			1362.227,
			1366.346,
			1367.753,
			1369.141,
			1373.315,
			1376.11,
			1378.91,
			1380.313,
			1383.123,
			1385.939,
			1387.326,
			1390.154,
			1391.57,
			1394.408,
			1397.252,
			1401.553,
			1404.41,
			1408.681,
			1410.115,
			1411.551,
			1415.865,
			1418.749,
			1423.087,
			1425.99,
			1428.902,
			1430.364,
			1433.292,
			1434.762,
			1437.709,
			1442.148,
			1445.117,
			1448.093,
			1451.073,
			1454.058,
			1455.55,
			1458.539,
			1461.53,
			1463.028,
			1466.027,
			1467.528,
			1470.535,
			1473.547,
			1478.074,
			1481.094,
			1485.611,
			1487.107,
			1488.597,
			1493.016,
			1495.914,
			1500.188,
			1502.989,
			1507.118,
			1509.822,
			1511.16,
			1513.808,
			1516.418,
			1517.709,
			1520.263,
			1522.781,
			1526.51,
			1528.937,
			1532.52,
			1533.699,
			1534.871,
			1538.329,
			1540.623,
			1542.867,
			1546.189,
			1548.386,
			1551.677,
			1552.775,
			1553.875,
			1557.186,
			1559.402,
			1562.723,
			1564.941,
			1567.151,
			1568.257,
			1570.467,
			1572.673,
			1573.776,
			1577.082,
			1579.283,
			1581.518,
			1584.881,
			1587.217,
			1590.744,
			1591.948,
			1593.15,
			1596.76,
			1599.159,
			1602.708,
			1605.01,
			1607.267,
			1608.371,
			1610.52,
			1611.545,
			1612.562,
			1615.549,
			1616.506,
			1617.454,
			1620.235,
			1622.048,
			1623.826,
			1624.701,
			1626.428,
			1628.12,
			1628.954,
			1631.404,
			1632.994,
			1634.547,
			1636.81,
			1638.273,
			1640.4,
			1641.093,
			1641.776,
			1643.786,
			1645.083,
			1646.995,
			1648.241,
			1649.47,
			1650.08,
			1651.316,
			1651.93,
			1653.152,
			1654.372,
			1654.981,
			1656.284,
			1657.592,
			1659.551,
			1660.218,
			1660.994,
			1662.545,
			1664.094,
			1664.87,
			1666.588,
			1667.465,
			1669.219,
			1670.924,
			1673.397,
			1675.054,
			1677.126,
			1677.813,
			1678.5,
			1680.513,
			1681.697,
			1683.482,
			1684.68,
			1685.88,
			1686.479,
			1687.682,
			1688.286,
			1689.498,
			1691.374,
			1692.647,
			1693.926,
			1695.872,
			1696.534,
			1697.196,
			1699.189,
			1699.856,
			1700.528,
			1701.882,
			1702.561,
			1703.922,
			1705.289,
			1707.363,
			1708.739,
			1710.82,
			1711.514,
			1712.205,
			1714.254,
			1715.598,
			1717.584,
			1718.863,
			1720.11,
			1720.742,
			1722,
			1723.238,
			1723.852,
			1725.712,
			1726.983,
			1728.25,
			1730.152,
			1731.446,
			1733.386,
			1734.033,
			1734.679,
			1736.63,
			1737.928,
			1739.872,
			1741.19,
			1742.513,
			1743.173,
			1744.49,
			1745.149,
			1745.817,
			1748.485,
			1749.816,
			1751.146,
			1752.47,
			1753.795,
			1754.458,
			1755.787,
			1757.11,
			1757.771,
			1759.098,
			1759.764,
			1761.114,
			1762.478,
			1764.537,
			1765.934,
			1768.072,
			1768.79,
			1769.51,
			1771.655,
			1773.083,
			1775.25,
			1776.629,
			1778.009,
			1778.703,
			1780.105,
			1780.796,
			1782.156,
			1783.529,
			1784.222,
			1785.618,
			1787.011,
			1788.419,
			1789.844,
			1790.563,
			1792.012,
			1793.475,
			1794.212,
			1795.698,
			1796.449,
			1797.961,
			1799.487,
			1801.808,
			1803.373,
			1805.75,
			1806.552,
			1807.358,
			1809.795,
			1811.445,
			1813.941,
			1815.606,
			1817.301,
			1818.14,
			1819.855,
			1820.718,
			1822.481,
			1824.227,
			1825.126,
			1826.904,
			1828.695,
			1831.394,
			1832.305,
			1833.219,
			1835.982,
			1836.909,
			1837.855,
			1839.71,
			1840.65,
			1842.54,
			1844.44,
			1847.313,
			1849.245,
			1852.179,
			1853.156,
			1854.118,
			1857.065,
			1859.036,
			1862,
			1863.921,
			1865.822,
			1866.761,
			1868.616,
			1869.53,
			1871.33,
			1873.092,
			1873.96,
			1875.669,
			1877.34,
			1879.778,
			1881.359,
			1883.659,
			1884.408,
			1885.148,
			1887.316,
			1888.721,
			1890.779,
			1892.124,
			1893.46,
			1894.117,
			1895.417,
			1896.062,
			1896.701,
			1898.607,
			1899.239,
			1899.87,
			1901.751,
			1902.99,
			1904.833,
			1906.054,
			1907.266,
			1907.867,
			1909.063,
			1909.658,
			1910.843,
			1912.055,
			1913.872,
			1915.073,
			1916.922,
			1917.559,
			1918.195,
			1920.087,
			1921.354,
			1923.346,
			1924.663,
			1925.975,
			1926.655,
			1928.026,
			1928.708,
			1930.07,
			1931.437,
			1932.138,
			1932.836,
			1934.923,
			1937.021,
			1938.431,
			1940.544,
			1941.249,
			1941.944,
			1943.332,
			1944.027,
			1945.42,
			1946.788,
			1948.78,
			1950.115,
			1952.071,
			1952.699,
			1953.33,
			1955.234,
			1956.505,
			1958.374,
			1959.631,
			1960.9,
			1961.538,
			1962.821,
			1963.467,
			1964.768,
			1966.083,
			1966.749,
			1968.096,
			1969.458,
			1971.532,
			1972.235,
			1972.942,
			1975.085,
			1975.808,
			1976.535,
			1978.727,
			1980.217,
			1981.722,
			1984.006,
			1985.548,
			1987.889,
			1988.677,
			1989.468,
			1991.864,
			1993.48,
			1995.931,
			1997.584,
			1999.249,
			2000.083,
			2001.756,
			2003.43,
			2004.279,
			2005.943,
			2006.773,
			2008.431,
			2010.083,
			2012.559,
			2014.209,
			2016.686,
			2017.51,
			2018.334,
			2020.805,
			2022.458,
			2024.938,
			2026.591,
			2029.103,
			2030.772,
			2031.608,
			2032.458,
			2034.998,
			2035.842,
			2036.688,
			2039.265,
			2040.975,
			2042.704,
			2043.573,
			2045.306,
			2047.041,
			2047.912,
			2049.651,
			2050.52,
			2052.252,
			2053.975,
			2056.558,
			2058.27,
			2060.835,
			2061.691,
			2062.551,
			2065.129,
			2066.85,
			2069.468,
			2071.211,
			2072.97,
			2073.851,
			2075.61,
			2076.489,
			2078.242,
			2080.857,
			2081.728,
			2084.294,
			2086.853,
			2087.69,
			2088.527,
			2091.034,
			2091.868,
			2092.693,
			2094.34,
			2095.162,
			2096.805,
			2099.244,
			2100.867,
			2102.487,
			2104.906,
			2105.713,
			2106.521,
			2108.946,
			2110.571,
			2113.032,
			2114.69,
			2117.203,
			2118.897,
			2119.746,
			2121.441,
			2123.146,
			2124.003,
			2125.728,
			2127.466,
			2130.095,
			2131.864,
			2134.538,
			2135.435,
			2136.333,
			2139.017,
			2140.812,
			2142.597,
			2145.226,
			2146.938,
			2149.43,
			2150.243,
			2151.046,
			2153.403,
			2154.929,
			2157.152,
			2158.596,
			2160.016,
			2160.707,
			2162.065,
			2163.401,
			2164.059,
			2165.354,
			2165.993,
			2167.253,
			2168.493,
			2170.319,
			2171.519,
			2173.293,
			2173.879,
			2174.463,
			2176.213,
			2177.383,
			2179.125,
			2180.288,
			2181.455,
			2183.205,
			2183.784,
			2184.946,
			2186.697,
			2187.283,
			2189.032,
			2190.199,
			2191.367,
			2191.951,
			2193.146,
			2194.362,
			2194.967,
			2196.172,
			2196.77,
			2198.019,
			2199.369,
			2201.356,
			2202.666,
			2204.908,
			2205.636,
			2206.356,
			2208.539,
			2210.021,
			2212.192,
			2213.623,
			2215.037,
			2215.737,
			2217.129,
			2217.821,
			2219.174,
			2220.518,
			2221.188,
			2222.528,
			2223.826,
			2225.75,
			2226.391,
			2227.031,
			2228.29,
			2229.513,
			2230.123,
			2231.343,
			2231.954,
			2233.167,
			2234.352,
			2236.128,
			2237.318,
			2239.077,
			2239.658,
			2240.24,
			2241.995,
			2243.165,
			2244.885,
			2246.04,
			2247.205,
			2247.792,
			2248.906,
			2249.466,
			2250.593,
			2252.302,
			2253.407,
			2254.478,
			2256.102,
			2256.65,
			2257.201,
			2258.857,
			2259.409,
			2259.964,
			2261.646,
			2262.784,
			2263.99,
			2265.821,
			2267.053,
			2268.967,
			2269.624,
			2270.282,
			2272.274,
			2272.944,
			2273.628,
			2275.692,
			2277.077,
			2279.165,
			2280.563,
			2281.263,
			2282.668,
			2284.081,
			2284.78,
			2286.211,
			2287.656,
			2289.853,
			2291.337,
			2293.606,
			2294.366,
			2295.129,
			2297.431,
			2298.975,
			2301.311,
			2302.873,
			2304.424,
			2306.791,
			2307.587,
			2308.386,
			2310.807,
			2311.622,
			2312.44,
			2314.918,
			2316.588,
			2318.271,
			2319.114,
			2320.798,
			2322.49,
			2323.341,
			2325.054,
			2325.914,
			2327.646,
			2329.391,
			2332.031,
			2333.807,
			2336.494,
			2337.396,
			2338.302,
			2341.036,
			2342.876,
			2345.659,
			2347.53,
			2350.362,
			2352.266,
			2353.223,
			2355.147,
			2357.084,
			2358.057,
			2359.034,
			2361.981,
			2363.962,
			2365.955,
			2366.956,
			2368.967,
			2370.99,
			2372.006,
			2375.072,
			2377.123,
			2379.166,
			2382.188,
			2384.16,
			2387.046,
			2387.99,
			2388.924,
			2391.67,
			2393.455,
			2396.066,
			2397.775,
			2399.421,
			2400.238,
			2401.844,
			2402.633,
			2404.184,
			2405.713,
			2406.458,
			2407.927,
			2409.372,
			2411.509,
			2412.926,
			2415.044,
			2415.749,
			2416.454,
			2418.576,
			2419.984,
			2421.39,
			2423.5,
			2424.912,
			2427.03,
			2427.736,
			2428.441,
			2430.587,
			2432.021,
			2434.169,
			2435.634,
			2437.092,
			2437.82,
			2439.277,
			2440.768,
			2441.51,
			2442.988,
			2443.725,
			2445.226,
			2446.728,
			2448.967,
			2450.492,
			2452.766,
			2453.52,
			2454.276,
			2456.591,
			2458.129,
			2460.463,
			2462.023,
			2463.573,
			2464.352,
			2465.95,
			2466.744,
			2467.535,
			2469.917,
			2470.728,
			2471.535,
			2473.942,
			2475.539,
			2477.124,
			2477.913,
			2479.478,
			2480.965,
			2481.705,
			2483.18,
			2483.917,
			2485.339,
			2486.737,
			2488.828,
			2490.21,
			2492.271,
			2492.959,
			2493.647,
			2495.718,
			2497.104,
			2499.189,
			2500.572,
			2501.956,
			2502.651,
			2504.047,
			2504.744,
			2506.131,
			2508.231,
			2508.936,
			2511.044,
			2512.458,
			2513.885,
			2514.602,
			2516.027,
			2517.466,
			2518.191,
			2519.652,
			2520.382,
			2521.852,
			2523.338,
			2525.598,
			2527.126,
			2529.447,
			2530.234,
			2531.027,
			2533.425,
			2534.231,
			2535.029,
			2537.481,
			2539.117,
			2540.742,
			2541.547,
			2543.138,
			2543.922,
			2545.46,
			2547.694,
			2549.138,
			2550.546,
			2552.604,
			2553.95,
			2555.295,
			2556.622,
			2557.284,
			2559.265,
			2560.594,
			2561.927,
			2563.924,
			2565.262,
			2567.282,
			2567.955,
			2568.627,
			2570.654,
			2572.009,
			2574.034,
			2575.39,
			2576.752,
			2577.432,
			2578.789,
			2579.468,
			2580.844,
			2582.223,
			2582.912,
			2584.29,
			2585.688,
			2587.802,
			2589.209,
			2591.335,
			2592.045,
			2592.752,
			2594.869,
			2596.252,
			2597.624,
			2599.675,
			2601.028,
			2601.7,
			2603.041,
			2603.71,
			2604.379,
			2606.417,
			2607.099,
			2607.779,
			2609.816,
			2611.226,
			2613.353,
			2614.769,
			2616.222,
			2616.956,
			2618.421,
			2619.153,
			2620.621,
			2622.089,
			2624.288,
			2625.753,
			2627.941,
			2628.671,
			2629.401,
			2631.579,
			2633.027,
			2635.212,
			2636.644,
			2638.083,
			2638.807,
			2640.263,
			2640.972,
			2642.398,
			2643.834,
			2644.558,
			2645.284,
			2647.413,
			2648.846,
			2651.009,
			2652.445,
			2653.896,
			2654.627,
			2656.097,
			2656.834,
			2658.319,
			2659.819,
			2662.103,
			2663.644,
			2665.987,
			2666.778,
			2667.573,
			2669.979,
			2671.61,
			2674.082,
			2675.731,
			2677.398,
			2678.236,
			2679.922,
			2680.77,
			2682.478,
			2685.065,
			2686.809,
			2688.567,
			2691.229,
			2692.126,
			2693.025,
			2695.74,
			2696.656,
			2697.574,
			2699.419,
			2700.348,
			2702.205,
			2704.092,
			2706.963,
			2708.88,
			2711.769,
			2712.733,
			2713.683,
			2716.602,
			2718.554,
			2721.512,
			2723.493,
			2725.464,
			2726.462,
			2728.484,
			2729.491,
			2731.515,
			2734.555,
			2736.607,
			2738.67,
			2741.787,
			2743.877,
			2747.022,
			2748.07,
			2749.12,
			2752.283,
			2754.405,
			2756.538,
			2759.755,
			2761.913,
			2765.169,
			2766.26,
			2767.353,
			2770.648,
			2771.751,
			2772.857,
			2776.19,
			2778.424,
			2780.669,
			2781.794,
			2784.054,
			2786.324,
			2787.463,
			2789.748,
			2790.895,
			2793.195,
			2795.506,
			2798.991,
			2801.327,
			2804.85,
			2806.028,
			2807.209,
			2810.767,
			2813.151,
			2816.743,
			2819.15,
			2821.566,
			2822.778,
			2825.207,
			2826.425,
			2828.867,
			2831.317,
			2832.546,
			2835.01,
			2837.482,
			2839.963,
			2842.453,
			2843.7,
			2846.201,
			2848.711,
			2849.97,
			2852.493,
			2853.758,
			2856.294,
			2858.838,
			2862.67,
			2865.235,
			2869.098,
			2870.389,
			2871.683,
			2875.576,
			2878.177,
			2882.081,
			2884.691,
			2887.308,
			2888.619,
			2891.247,
			2892.564,
			2895.203,
			2899.175,
			2901.832,
			2904.496,
			2907.167,
			2909.845,
			2911.187,
			2915.225,
			2916.574,
			2917.926,
			2921.99,
			2924.707,
			2927.432,
			2931.533,
			2934.275,
			2938.402,
			2939.781,
			2941.162,
			2945.316,
			2948.093,
			2952.272,
			2955.066,
			2957.867,
			2959.27,
			2962.082,
			2964.9,
			2966.311,
			2969.139,
			2970.556,
			2973.394,
			2976.239,
			2980.517,
			2983.378,
			2987.681,
			2989.119,
			2990.558,
			2994.885,
			2997.777,
			3002.128,
			3005.036,
			3007.951,
			3012.334,
			3013.798,
			3015.264,
			3019.671,
			3022.617,
			3027.048,
			3030.01,
			3034.463,
			3037.439,
			3040.421,
			3041.915,
			3046.405,
			3049.406,
			3052.413,
			3056.934,
			3059.955,
			3064.498,
			3066.015,
			3067.534,
			3072.098,
			3075.147,
			3079.731,
			3082.796,
			3085.865,
			3087.402,
			3090.48,
			3092.021,
			3093.563,
			3099.747,
			3102.846,
			3105.95,
			3109.061,
			3113.736,
			3116.86,
			3119.988,
			3121.554,
			3124.69,
			3126.26,
			3129.404,
			3132.553,
			3137.285,
			3140.447,
			3145.197,
			3146.782,
			3148.37,
			3153.139,
			3156.323,
			3161.101,
			3164.291,
			3169.084,
			3172.285,
			3173.888,
			3177.095,
			3181.914,
			3185.132,
			3188.355,
			3193.197,
			3196.431,
			3199.668,
			3202.91,
			3204.532,
			3209.407,
			3212.662,
			3215.92,
			3220.814,
			3224.081,
			3228.99,
			3230.628,
			3232.268,
			3237.194,
			3240.483,
			3245.424,
			3248.723,
			3252.027,
			3253.68,
			3256.989,
			3258.645,
			3261.96,
			3266.941,
			3270.266,
			3273.595,
			3278.597,
			3281.936,
			3286.953,
			3288.626,
			3290.302,
			3293.654,
			3295.333,
			3298.692,
			3303.738,
			3307.106,
			3310.479,
			3315.547,
			3317.238,
			3318.93,
			3324.01,
			3325.706,
			3327.403,
			3332.499,
			3335.901,
			3339.308,
			3341.012,
			3344.423,
			3347.839,
			3349.548,
			3352.97,
			3354.683,
			3358.111,
			3361.542,
			3366.694,
			3370.134,
			3375.301,
			3377.026,
			3378.751,
			3383.933,
			3387.392,
			3392.585,
			3396.05,
			3401.251,
			3404.722,
			3406.459,
			3408.198,
			3413.418,
			3415.161,
			3416.905,
			3422.142,
			3425.638,
			3429.138,
			3430.889,
			3434.393,
			3437.9,
			3439.656,
			3444.926,
			3448.444,
			3451.966,
			3457.255,
			3460.787,
			3466.09,
			3467.859,
			3469.629,
			3474.944,
			3478.491,
			3483.818,
			3487.375,
			3490.935,
			3492.716,
			3496.278,
			3498.058,
			3501.607,
			3505.134,
			3506.886,
			3510.362,
			3513.799,
			3518.882,
			3520.556,
			3522.221,
			3527.158,
			3528.784,
			3530.4,
			3533.603,
			3535.19,
			3538.335,
			3541.441,
			3546.03,
			3549.044,
			3553.496,
			3554.961,
			3556.416,
			3560.723,
			3563.545,
			3567.705,
			3570.429,
			3573.115,
			3574.443,
			3577.072,
			3578.372,
			3580.942,
			3583.474,
			3584.725,
			3587.2,
			3589.636,
			3593.223,
			3595.571,
			3599.028,
			3600.161,
			3601.284,
			3604.599,
			3606.762,
			3609.936,
			3612.006,
			3614.037,
			3617.016,
			3617.991,
			3618.956,
			3621.797,
			3622.725,
			3623.645,
			3626.354,
			3628.117,
			3629.846,
			3630.698,
			3632.373,
			3634.011,
			3634.817,
			3636.402,
			3637.181,
			3638.711,
			3640.206,
			3642.394,
			3643.798,
			3645.845,
			3646.511,
			3647.168,
			3649.096,
			3650.337,
			3652.14,
			3653.312,
			3654.463,
			3655.021,
			3656.122,
			3656.667,
			3657.748,
			3659.354,
			3659.874,
			3661.419,
			3662.446,
			3663.472,
			3663.985,
			3665.017,
			3666.051,
			3666.568,
			3667.602,
			3668.119,
			3669.156,
			3670.299,
			3672.076,
			3673.266,
			3675.222,
			3675.989,
			3676.757,
			3679.074,
			3680.851,
			3683.699,
			3685.59,
			3687.467,
			3688.41,
			3690.227,
			3691.06,
			3692.729,
			3694.405,
			3695.118,
			3696.429,
			3697.75,
			3699.08,
			3700.904,
			3702.053,
			3703.203,
			3703.779,
			3705.526,
			3706.704,
			3707.883,
			3709.654,
			3710.879,
			3712.735,
			3713.354,
			3713.972,
			3715.859,
			3717.143,
			3719.057,
			3720.339,
			3721.65,
			3722.303,
			3723.605,
			3724.254,
			3725.556,
			3726.865,
			3727.519,
			3728.824,
			3730.127,
			3732.083,
			3732.733,
			3733.383,
			3735.32,
			3735.979,
			3736.627,
			3738.578,
			3739.888,
			3741.871,
			3743.191,
			3744.532,
			3746.575,
			3747.265,
			3747.959,
			3750.066,
			3750.777,
			3751.495,
			3753.675,
			3755.147,
			3756.64,
			3757.394,
			3758.913,
			3760.447,
			3761.221,
			3762.781,
			3763.566,
			3765.149,
			3766.746,
			3769.171,
			3770.806,
			3773.285,
			3774.118,
			3774.956,
			3777.481,
			3779.165,
			3781.706,
			3783.418,
			3786.009,
			3787.753,
			3788.629,
			3790.393,
			3792.169,
			3793.062,
			3793.958,
			3796.666,
			3798.487,
			3800.321,
			3801.243,
			3803.096,
			3804.962,
			3805.9,
			3807.784,
			3808.731,
			3810.636,
			3812.552,
			3815.451,
			3817.398,
			3820.344,
			3821.332,
			3822.322,
			3825.312,
			3827.319,
			3830.352,
			3832.388,
			3834.437,
			3835.465,
			3837.531,
			3838.568,
			3840.65,
			3843.792,
			3845.889,
			3847.988,
			3851.151,
			3852.211,
			3853.273,
			3855.406,
			3857.55,
			3858.625,
			3860.782,
			3861.865,
			3864.037,
			3866.219,
			3869.511,
			3871.718,
			3875.046,
			3876.16,
			3877.277,
			3880.642,
			3882.898,
			3886.301,
			3888.582,
			3890.872,
			3892.021,
			3894.326,
			3895.482,
			3897.801,
			3900.13,
			3901.298,
			3903.641,
			3905.994,
			3909.542,
			3910.729,
			3911.918,
			3915.5,
			3916.698,
			3917.898,
			3921.517,
			3923.941,
			3924.947
		],
		[
			0,
			0.027,
			0.091,
			0.104,
			0.152,
			0.215,
			0.277,
			0.321,
			0.338,
			0.402,
			0.462,
			0.524,
			0.54,
			0.587,
			0.648,
			0.71,
			0.757,
			0.834,
			0.865,
			0.896,
			0.958,
			1.019,
			1.084,
			1.143,
			1.191,
			1.206,
			1.268,
			1.3,
			1.332,
			1.392,
			1.407,
			1.456,
			1.516,
			1.58,
			1.64,
			1.702,
			1.733,
			1.763,
			1.827,
			1.887,
			1.951,
			2.013,
			2.06,
			2.136,
			2.168,
			2.199,
			2.261,
			2.276,
			2.321,
			2.385,
			2.448,
			2.5,
			2.572,
			2.601,
			2.633,
			2.695,
			2.709,
			2.757,
			2.821,
			2.88,
			2.926,
			3.006,
			3.036,
			3.068,
			3.13,
			3.192,
			3.254,
			3.314,
			3.361,
			3.377,
			3.439,
			3.469,
			3.502,
			3.562,
			3.58,
			3.624,
			3.688,
			3.749,
			3.795,
			3.81,
			3.873,
			3.903,
			3.935,
			3.996,
			4.013,
			4.06,
			4.12,
			4.184,
			4.246,
			4.307,
			4.339,
			4.369,
			4.446,
			4.492,
			4.559,
			4.616,
			4.663,
			4.679,
			4.742,
			4.772,
			4.804,
			4.865,
			4.88,
			4.928,
			4.99,
			5.05,
			5.098,
			5.113,
			5.176,
			5.206,
			5.235,
			5.299,
			5.315,
			5.36,
			5.424,
			5.485,
			5.532,
			5.61,
			5.642,
			5.672,
			5.735,
			5.797,
			5.859,
			5.919,
			5.967,
			5.981,
			6.043,
			6.075,
			6.106,
			6.168,
			6.182,
			6.228,
			6.292,
			6.353,
			6.399,
			6.414,
			6.477,
			6.51,
			6.541,
			6.602,
			6.663,
			6.727,
			6.788,
			6.835,
			6.913,
			6.943,
			6.975,
			7.05,
			7.097,
			7.164,
			7.22,
			7.267,
			7.285,
			7.346,
			7.377,
			7.407,
			7.47,
			7.485,
			7.53,
			7.594,
			7.654,
			7.701,
			7.779,
			7.81,
			7.84,
			7.904,
			7.967,
			8.028,
			8.091,
			8.136,
			8.15,
			8.214,
			8.244,
			8.274,
			8.339,
			8.352,
			8.401,
			8.461,
			8.524,
			8.585,
			8.647,
			8.71,
			8.771,
			8.785,
			8.834,
			8.896,
			8.957,
			9.005,
			9.083,
			9.114,
			9.144,
			9.206,
			9.268,
			9.33,
			9.393,
			9.437,
			9.453,
			9.516,
			9.546,
			9.579,
			9.641,
			9.656,
			9.702,
			9.763,
			9.827,
			9.871,
			9.889,
			9.949,
			9.98,
			10.011,
			10.074,
			10.091,
			10.135,
			10.199,
			10.259,
			10.307,
			10.383,
			10.414,
			10.447,
			10.509,
			10.569,
			10.633,
			10.693,
			10.746,
			10.757,
			10.818,
			10.848,
			10.88,
			10.942,
			10.958,
			11.004,
			11.066,
			11.129,
			11.175,
			11.252,
			11.284,
			11.315,
			11.393,
			11.439,
			11.502,
			11.563,
			11.61,
			11.686,
			11.717,
			11.749,
			11.809,
			11.827,
			11.871,
			11.936,
			11.997,
			12.044,
			12.058,
			12.119,
			12.15,
			12.184,
			12.26,
			12.307,
			12.369,
			12.43,
			12.477,
			12.556,
			12.586,
			12.617,
			12.693,
			12.742,
			12.808,
			12.863,
			12.911,
			12.927,
			12.99,
			13.02,
			13.051,
			13.127,
			13.175,
			13.237,
			13.297,
			13.36,
			13.424,
			13.454,
			13.486,
			13.546,
			13.561,
			13.61,
			13.672,
			13.732,
			13.779,
			13.855,
			13.888,
			13.918,
			13.981,
			14.044,
			14.103,
			14.167,
			14.229,
			14.291,
			14.322,
			14.353,
			14.416,
			14.432,
			14.477,
			14.54,
			14.602,
			14.648,
			14.664,
			14.726,
			14.756,
			14.787,
			14.85,
			14.91,
			14.971,
			15.035,
			15.083,
			15.159,
			15.19,
			15.22,
			15.298,
			15.344,
			15.407,
			15.469,
			15.516,
			15.532,
			15.594,
			15.624,
			15.656,
			15.718,
			15.732,
			15.779,
			15.841,
			15.904,
			15.963,
			16.028,
			16.057,
			16.087,
			16.152,
			16.212,
			16.277,
			16.335,
			16.385,
			16.461,
			16.491,
			16.524,
			16.585,
			16.601,
			16.646,
			16.709,
			16.769,
			16.817,
			16.834,
			16.894,
			16.958,
			17.019,
			17.034,
			17.08,
			17.144,
			17.206,
			17.253,
			17.33,
			17.36,
			17.391,
			17.455,
			17.516,
			17.579,
			17.641,
			17.686,
			17.702,
			17.762,
			17.795,
			17.824,
			17.886,
			17.902,
			17.949,
			18.011,
			18.074,
			18.119,
			18.136,
			18.198,
			18.229,
			18.261,
			18.321,
			18.336,
			18.384,
			18.446,
			18.507,
			18.554,
			18.632,
			18.662,
			18.693,
			18.753,
			18.818,
			18.879,
			18.941,
			19.003,
			19.064,
			19.096,
			19.127,
			19.206,
			19.252,
			19.314,
			19.375,
			19.423,
			19.439,
			19.5,
			19.529,
			19.562,
			19.625,
			19.685,
			19.749,
			19.81,
			19.857,
			19.933,
			19.965,
			19.995,
			20.071,
			20.118,
			20.183,
			20.243,
			20.305,
			20.369,
			20.4,
			20.429,
			20.491,
			20.508,
			20.554,
			20.616,
			20.679,
			20.724,
			20.74,
			20.803,
			20.833,
			20.864,
			20.927,
			20.987,
			21.051,
			21.113,
			21.16,
			21.235,
			21.266,
			21.299,
			21.361,
			21.423,
			21.483,
			21.545,
			21.591,
			21.607,
			21.671,
			21.701,
			21.733,
			21.793,
			21.81,
			21.857,
			21.918,
			21.979,
			22.027,
			22.104,
			22.136,
			22.167,
			22.228,
			22.291,
			22.354,
			22.414,
			22.46,
			22.476,
			22.538,
			22.569,
			22.6,
			22.662,
			22.678,
			22.724,
			22.787,
			22.849,
			22.91,
			22.973,
			23.002,
			23.036,
			23.097,
			23.116,
			23.158,
			23.221,
			23.282,
			23.343,
			23.408,
			23.438,
			23.468,
			23.546,
			23.594,
			23.655,
			23.716,
			23.764,
			23.78,
			23.841,
			23.871,
			23.902,
			23.965,
			23.981,
			24.027,
			24.088,
			24.151,
			24.196,
			24.212,
			24.275,
			24.304,
			24.338,
			24.414,
			24.46,
			24.521,
			24.584,
			24.63,
			24.71,
			24.739,
			24.772,
			24.848,
			24.895,
			24.956,
			25.019,
			25.066,
			25.08,
			25.143,
			25.178,
			25.206,
			25.282,
			25.329,
			25.39,
			25.454,
			25.5,
			25.514,
			25.576,
			25.608,
			25.637,
			25.7,
			25.716,
			25.762,
			25.825,
			25.886,
			25.934,
			26.011,
			26.041,
			26.074,
			26.136,
			26.151,
			26.197,
			26.26,
			26.32,
			26.369,
			26.383,
			26.446,
			26.476,
			26.507,
			26.585,
			26.63,
			26.694,
			26.755,
			26.801,
			26.879,
			26.91,
			26.942,
			27.002,
			27.065,
			27.127,
			27.189,
			27.252,
			27.312,
			27.343,
			27.375,
			27.437,
			27.453,
			27.499,
			27.562,
			27.623,
			27.669,
			27.686,
			27.746,
			27.778,
			27.81,
			27.872,
			27.887,
			27.934,
			27.995,
			28.058,
			28.105,
			28.182,
			28.213,
			28.242,
			28.32,
			28.367,
			28.429,
			28.491,
			28.537,
			28.554,
			28.616,
			28.646,
			28.677,
			28.738,
			28.754,
			28.802,
			28.863,
			28.926,
			28.988,
			29.051,
			29.082,
			29.111,
			29.174,
			29.19,
			29.234,
			29.301,
			29.359,
			29.407,
			29.484,
			29.515,
			29.546,
			29.606,
			29.67,
			29.733,
			29.793,
			29.841,
			29.854,
			29.918,
			29.948,
			29.98,
			30.058,
			30.102,
			30.165,
			30.228,
			30.29,
			30.352,
			30.384,
			30.415,
			30.476,
			30.537,
			30.599,
			30.602,
			30.662,
			30.707,
			30.786,
			30.816,
			30.847,
			30.91,
			30.97,
			31.034,
			31.095,
			31.158,
			31.22,
			31.251,
			31.281,
			31.342,
			31.363,
			31.404,
			31.469,
			31.53,
			31.576,
			31.654,
			31.685,
			31.717,
			31.777,
			31.841,
			31.903,
			31.964,
			32.009,
			32.027,
			32.087,
			32.12,
			32.151,
			32.212,
			32.227,
			32.275,
			32.338,
			32.399,
			32.444,
			32.46,
			32.523,
			32.552,
			32.585,
			32.663,
			32.709,
			32.769,
			32.833,
			32.879,
			32.957,
			32.986,
			33.017,
			33.079,
			33.143,
			33.203,
			33.266,
			33.327,
			33.391,
			33.425,
			33.452,
			33.513,
			33.529,
			33.577,
			33.636,
			33.701,
			33.748,
			33.761,
			33.824,
			33.854,
			33.886,
			33.949,
			33.963,
			34.009,
			34.073,
			34.135,
			34.18,
			34.258,
			34.291,
			34.321,
			34.384,
			34.444,
			34.508,
			34.569,
			34.616,
			34.631,
			34.693,
			34.723,
			34.754,
			34.817,
			34.833,
			34.877,
			34.941,
			35.002,
			35.05,
			35.128,
			35.157,
			35.189,
			35.267,
			35.311,
			35.375,
			35.436,
			35.499,
			35.561,
			35.591,
			35.623,
			35.684,
			35.746,
			35.813,
			35.87,
			35.933,
			35.995,
			36.026,
			36.055,
			36.119,
			36.134,
			36.181,
			36.243,
			36.305,
			36.351,
			36.43,
			36.459,
			36.491,
			36.568,
			36.615,
			36.677,
			36.74,
			36.8,
			36.864,
			36.894,
			36.925,
			36.986,
			37.002,
			37.049,
			37.111,
			37.174,
			37.22,
			37.236,
			37.298,
			37.327,
			37.359,
			37.422,
			37.436,
			37.483,
			37.545,
			37.607,
			37.654,
			37.732,
			37.762,
			37.794,
			37.853,
			37.917,
			37.979,
			38.042,
			38.086,
			38.102,
			38.165,
			38.197,
			38.226,
			38.303,
			38.352,
			38.415,
			38.474,
			38.521,
			38.536,
			38.598,
			38.629,
			38.662,
			38.724,
			38.738,
			38.784,
			38.846,
			38.909,
			38.972,
			39.033,
			39.065,
			39.094,
			39.157,
			39.219,
			39.282,
			39.343,
			39.407,
			39.466,
			39.499,
			39.529,
			39.591,
			39.612,
			39.655,
			39.716,
			39.776,
			39.84,
			39.901,
			39.932,
			39.965,
			40.025,
			40.089,
			40.149,
			40.211,
			40.258,
			40.336,
			40.367,
			40.396,
			40.475,
			40.522,
			40.582,
			40.644,
			40.691,
			40.708,
			40.77,
			40.8,
			40.833,
			40.909,
			40.956,
			41.018,
			41.08,
			41.142,
			41.202,
			41.235,
			41.266,
			41.343,
			41.39,
			41.452,
			41.514,
			41.56,
			41.577,
			41.638,
			41.67,
			41.698,
			41.761,
			41.777,
			41.824,
			41.887,
			41.946,
			41.995,
			42.011,
			42.069,
			42.103,
			42.133,
			42.21,
			42.258,
			42.325,
			42.382,
			42.428,
			42.506,
			42.537,
			42.568,
			42.631,
			42.692,
			42.754,
			42.817,
			42.862,
			42.878,
			42.941,
			42.972,
			43.003,
			43.063,
			43.08,
			43.127,
			43.188,
			43.251,
			43.296,
			43.313,
			43.374,
			43.405,
			43.437,
			43.499,
			43.512,
			43.559,
			43.621,
			43.684,
			43.734,
			43.809,
			43.837,
			43.87,
			43.948,
			43.993,
			44.056,
			44.118,
			44.165,
			44.181,
			44.242,
			44.274,
			44.304,
			44.383,
			44.427,
			44.491,
			44.551,
			44.599,
			44.613,
			44.676,
			44.708,
			44.736,
			44.799,
			44.816,
			44.863,
			44.924,
			44.986,
			45.049,
			45.11,
			45.142,
			45.171,
			45.235,
			45.296,
			45.36,
			45.419,
			45.468,
			45.483,
			45.544,
			45.577,
			45.608,
			45.683,
			45.73,
			45.797,
			45.854,
			45.916,
			45.98,
			46.008,
			46.042,
			46.103,
			46.166,
			46.227,
			46.287,
			46.336,
			46.35,
			46.414,
			46.443,
			46.474,
			46.552,
			46.6,
			46.661,
			46.721,
			46.769,
			46.785,
			46.846,
			46.877,
			46.908,
			46.971,
			46.985,
			47.033,
			47.094,
			47.155,
			47.202,
			47.28,
			47.313,
			47.344,
			47.405,
			47.465,
			47.53,
			47.591,
			47.636,
			47.653,
			47.716,
			47.746,
			47.777,
			47.839,
			47.86,
			47.901,
			47.963,
			48.024,
			48.073,
			48.088,
			48.148,
			48.181,
			48.21,
			48.274,
			48.287,
			48.335,
			48.397,
			48.46,
			48.506,
			48.584,
			48.613,
			48.646,
			48.721,
			48.77,
			48.832,
			48.893,
			48.938,
			48.954,
			49.017,
			49.049,
			49.08,
			49.142,
			49.157,
			49.203,
			49.266,
			49.328,
			49.374,
			49.39,
			49.45,
			49.483,
			49.513,
			49.574,
			49.591,
			49.637,
			49.699,
			49.762,
			49.807,
			49.885,
			49.92,
			49.947,
			50.01,
			50.071,
			50.134,
			50.196,
			50.243,
			50.257,
			50.32,
			50.349,
			50.38,
			50.443,
			50.459,
			50.505,
			50.566,
			50.63,
			50.676,
			50.752,
			50.783,
			50.817,
			50.876,
			50.938,
			51.001,
			51.062,
			51.11,
			51.126,
			51.187,
			51.218,
			51.249,
			51.311,
			51.327,
			51.374,
			51.437,
			51.498,
			51.561,
			51.623,
			51.654,
			51.683,
			51.746,
			51.762,
			51.807,
			51.871,
			51.933,
			51.983,
			52.054,
			52.087,
			52.117,
			52.179,
			52.241,
			52.304,
			52.365,
			52.411,
			52.427,
			52.49,
			52.521,
			52.551,
			52.613,
			52.63,
			52.674,
			52.736,
			52.801,
			52.863,
			52.925,
			52.955,
			52.985,
			53.046,
			53.064,
			53.11,
			53.173,
			53.234,
			53.279,
			53.358,
			53.39,
			53.42,
			53.482,
			53.543,
			53.607,
			53.668,
			53.714,
			53.73,
			53.792,
			53.823,
			53.854,
			53.917,
			53.933,
			53.976,
			54.041,
			54.103,
			54.148,
			54.165,
			54.226,
			54.257,
			54.286,
			54.366,
			54.411,
			54.474,
			54.535,
			54.582,
			54.659,
			54.691,
			54.721,
			54.785,
			54.846,
			54.907,
			54.971,
			55.018,
			55.032,
			55.093,
			55.125,
			55.157,
			55.217,
			55.234,
			55.279,
			55.343,
			55.404,
			55.466,
			55.527,
			55.559,
			55.592,
			55.652,
			55.714,
			55.777,
			55.839,
			55.9,
			55.963,
			55.994,
			56.024,
			56.085,
			56.107,
			56.147,
			56.209,
			56.273,
			56.318,
			56.333,
			56.396,
			56.427,
			56.457,
			56.52,
			56.537,
			56.582,
			56.644,
			56.706,
			56.754,
			56.829,
			56.86,
			56.892,
			56.971,
			57.018,
			57.079,
			57.141,
			57.186,
			57.203,
			57.265,
			57.296,
			57.326,
			57.404,
			57.449,
			57.516,
			57.576,
			57.619,
			57.635,
			57.7,
			57.728,
			57.761,
			57.824,
			57.837,
			57.885,
			57.951,
			58.01,
			58.055,
			58.132,
			58.168,
			58.193,
			58.273,
			58.319,
			58.38,
			58.443,
			58.503,
			58.566,
			58.597,
			58.63,
			58.69,
			58.706,
			58.752,
			58.813,
			58.877,
			58.939,
			59.002,
			59.032,
			59.063,
			59.141,
			59.186,
			59.249,
			59.311,
			59.374,
			59.435,
			59.465,
			59.496,
			59.557,
			59.619,
			59.683,
			59.745,
			59.793,
			59.808,
			59.87,
			59.9,
			59.931,
			59.993,
			60.01,
			60.056,
			60.118,
			60.178,
			60.241,
			60.303,
			60.334,
			60.366,
			60.428,
			60.489,
			60.552,
			60.614,
			60.66,
			60.738,
			60.769,
			60.8,
			60.876,
			60.924,
			60.986,
			61.046,
			61.094,
			61.108,
			61.169,
			61.203,
			61.233,
			61.296,
			61.31,
			61.358,
			61.418,
			61.48,
			61.526,
			61.606,
			61.635,
			61.668,
			61.729,
			61.792,
			61.852,
			61.914,
			61.962,
			61.976,
			62.04,
			62.069,
			62.101,
			62.164,
			62.18,
			62.226,
			62.287,
			62.35,
			62.396,
			62.413,
			62.474,
			62.503,
			62.534,
			62.597,
			62.614,
			62.66,
			62.723,
			62.783,
			62.83,
			62.908,
			62.939,
			62.969,
			63.031,
			63.093,
			63.156,
			63.219,
			63.265,
			63.28,
			63.342,
			63.371,
			63.403,
			63.482,
			63.526,
			63.591,
			63.652,
			63.698,
			63.712,
			63.776,
			63.807,
			63.839,
			63.901,
			63.96,
			64.024,
			64.085,
			64.146,
			64.208,
			64.24,
			64.271,
			64.335,
			64.352,
			64.396,
			64.458,
			64.521,
			64.582,
			64.642,
			64.674,
			64.704,
			64.768,
			64.782,
			64.829,
			64.892,
			64.953,
			65.017,
			65.079,
			65.109,
			65.14,
			65.216,
			65.263,
			65.326,
			65.386,
			65.435,
			65.511,
			65.543,
			65.573,
			65.637,
			65.65,
			65.699,
			65.759,
			65.823,
			65.868,
			65.883,
			65.945,
			65.976,
			66.009,
			66.071,
			66.087,
			66.133,
			66.195,
			66.254,
			66.302,
			66.379,
			66.416,
			66.441,
			66.504,
			66.566,
			66.629,
			66.69,
			66.753,
			66.814,
			66.846,
			66.876,
			66.937,
			66.953,
			66.999,
			67.061,
			67.124,
			67.169,
			67.186,
			67.247,
			67.279,
			67.31,
			67.387,
			67.434,
			67.496,
			67.559,
			67.606,
			67.682,
			67.712,
			67.744,
			67.82,
			67.868,
			67.93,
			67.993,
			68.037,
			68.054,
			68.116,
			68.146,
			68.18,
			68.241,
			68.256,
			68.303,
			68.365,
			68.427,
			68.487,
			68.551,
			68.582,
			68.613,
			68.675,
			68.736,
			68.799,
			68.861,
			68.908,
			68.985,
			69.016,
			69.046,
			69.108,
			69.169,
			69.233,
			69.296,
			69.341,
			69.357,
			69.419,
			69.45,
			69.48,
			69.542,
			69.558,
			69.603,
			69.666,
			69.728,
			69.775,
			69.852,
			69.885,
			69.916,
			69.977,
			70.037,
			70.101,
			70.162,
			70.21,
			70.225,
			70.286,
			70.319,
			70.349,
			70.41,
			70.426,
			70.473,
			70.536,
			70.597,
			70.642,
			70.658,
			70.719,
			70.751,
			70.782,
			70.844,
			70.86,
			70.907,
			70.969,
			71.029,
			71.093,
			71.156,
			71.184,
			71.216,
			71.28,
			71.342,
			71.402,
			71.464,
			71.51,
			71.527,
			71.589,
			71.621,
			71.651,
			71.728,
			71.774,
			71.837,
			71.899,
			71.945,
			71.962,
			72.021,
			72.054,
			72.084,
			72.146,
			72.162,
			72.209,
			72.27,
			72.333,
			72.381,
			72.457,
			72.489,
			72.518,
			72.581,
			72.602,
			72.643,
			72.706,
			72.767,
			72.814,
			72.83,
			72.892,
			72.922,
			72.952,
			73.029,
			73.077,
			73.139,
			73.202,
			73.263,
			73.324,
			73.357,
			73.385,
			73.45,
			73.512,
			73.575,
			73.635,
			73.698,
			73.759,
			73.791,
			73.82,
			73.9,
			73.944,
			74.008,
			74.07,
			74.115,
			74.133,
			74.194,
			74.225,
			74.254,
			74.318,
			74.333,
			74.38,
			74.441,
			74.504,
			74.565,
			74.626,
			74.663,
			74.69,
			74.753,
			74.813,
			74.874,
			74.938,
			74.984,
			75.001,
			75.061,
			75.094,
			75.124,
			75.186,
			75.2,
			75.248,
			75.309,
			75.373,
			75.432,
			75.495,
			75.528,
			75.558,
			75.621,
			75.634,
			75.682,
			75.743,
			75.804,
			75.867,
			75.93,
			75.959,
			75.993,
			76.052,
			76.117,
			76.178,
			76.24,
			76.287,
			76.301,
			76.363,
			76.396,
			76.427,
			76.489,
			76.502,
			76.55,
			76.61,
			76.674,
			76.735,
			76.798,
			76.829,
			76.86,
			76.923,
			76.936,
			76.983,
			77.046,
			77.108,
			77.153,
			77.233,
			77.264,
			77.294,
			77.354,
			77.419,
			77.48,
			77.543,
			77.59,
			77.603,
			77.666,
			77.698,
			77.729,
			77.805,
			77.852,
			77.915,
			77.977,
			78.021,
			78.038,
			78.1,
			78.13,
			78.161,
			78.224,
			78.24,
			78.287,
			78.348,
			78.41,
			78.457,
			78.535,
			78.566,
			78.596,
			78.658,
			78.721,
			78.781,
			78.844,
			78.89,
			78.907,
			78.969,
			78.999,
			79.03,
			79.107,
			79.153,
			79.216,
			79.277,
			79.338,
			79.402,
			79.434,
			79.463,
			79.527,
			79.59,
			79.649,
			79.712,
			79.757,
			79.838,
			79.868,
			79.898,
			79.96,
			79.977,
			80.023,
			80.085,
			80.146,
			80.193,
			80.21,
			80.271,
			80.302,
			80.332,
			80.394,
			80.41,
			80.455,
			80.519,
			80.581,
			80.627,
			80.705,
			80.736,
			80.766,
			80.829,
			80.891,
			80.953,
			81.015,
			81.061,
			81.077,
			81.138,
			81.168,
			81.202,
			81.263,
			81.277,
			81.325,
			81.386,
			81.449,
			81.496,
			81.512,
			81.573,
			81.604,
			81.634,
			81.697,
			81.711,
			81.759,
			81.819,
			81.882,
			81.929,
			82.004,
			82.037,
			82.069,
			82.13,
			82.193,
			82.253,
			82.317,
			82.363,
			82.38,
			82.442,
			82.471,
			82.503,
			82.581,
			82.627,
			82.69,
			82.75,
			82.799,
			82.812,
			82.875,
			82.911,
			82.936,
			83,
			83.062,
			83.121,
			83.185,
			83.233,
			83.308,
			83.34,
			83.371,
			83.432,
			83.494,
			83.558,
			83.62,
			83.667,
			83.681,
			83.743,
			83.775,
			83.805,
			83.866,
			83.882,
			83.93,
			83.991,
			84.053,
			84.1,
			84.177,
			84.208,
			84.24,
			84.302,
			84.363,
			84.426,
			84.488,
			84.535,
			84.612,
			84.642,
			84.673,
			84.736,
			84.796,
			84.86,
			84.919,
			84.984,
			85.045,
			85.077,
			85.108,
			85.184,
			85.233,
			85.292,
			85.356,
			85.402,
			85.48,
			85.509,
			85.541,
			85.604,
			85.666,
			85.728,
			85.79,
			85.835,
			85.851,
			85.913,
			85.943,
			85.974,
			86.053,
			86.1,
			86.16,
			86.224,
			86.285,
			86.346,
			86.379,
			86.408,
			86.471,
			86.488,
			86.535,
			86.596,
			86.658,
			86.703,
			86.781,
			86.814,
			86.842,
			86.907,
			86.967,
			87.034,
			87.091,
			87.152,
			87.216,
			87.246,
			87.278,
			87.356,
			87.401,
			87.463,
			87.527,
			87.588,
			87.649,
			87.682,
			87.713,
			87.788,
			87.835,
			87.899,
			87.959,
			88.004,
			88.084,
			88.116,
			88.146,
			88.223,
			88.27,
			88.332,
			88.394,
			88.441,
			88.454,
			88.517,
			88.549,
			88.58,
			88.658,
			88.703,
			88.766,
			88.829,
			88.875,
			88.951,
			88.984,
			89.013,
			89.075,
			89.097,
			89.139,
			89.2,
			89.263,
			89.307,
			89.385,
			89.418,
			89.447,
			89.509,
			89.527,
			89.573,
			89.634,
			89.696,
			89.743,
			89.758,
			89.821,
			89.852,
			89.882,
			89.943,
			89.96,
			90.004,
			90.067,
			90.131,
			90.177,
			90.253,
			90.284,
			90.317,
			90.377,
			90.44,
			90.503,
			90.564,
			90.626,
			90.688,
			90.719,
			90.75,
			90.811,
			90.826,
			90.875,
			90.936,
			90.998,
			91.044,
			91.061,
			91.124,
			91.16,
			91.183,
			91.261,
			91.31,
			91.37,
			91.434,
			91.478,
			91.555,
			91.589,
			91.62,
			91.681,
			91.742,
			91.804,
			91.866,
			91.913,
			91.93,
			91.99,
			92.023,
			92.052,
			92.116,
			92.13,
			92.178,
			92.24,
			92.302,
			92.346,
			92.363,
			92.425,
			92.454,
			92.487,
			92.548,
			92.564,
			92.611,
			92.674,
			92.734,
			92.78,
			92.858,
			92.89,
			92.919,
			92.999,
			93.045,
			93.108,
			93.168,
			93.219,
			93.23,
			93.293,
			93.325,
			93.354,
			93.417,
			93.432,
			93.48,
			93.542,
			93.603,
			93.664,
			93.727,
			93.756,
			93.787,
			93.866,
			93.913,
			93.975,
			94.035,
			94.084,
			94.16,
			94.193,
			94.223,
			94.285,
			94.301,
			94.347,
			94.411,
			94.471,
			94.517,
			94.533,
			94.594,
			94.627,
			94.658,
			94.719,
			94.735,
			94.782,
			94.842,
			94.905,
			94.952,
			95.03,
			95.059,
			95.091,
			95.152,
			95.214,
			95.282,
			95.338,
			95.385,
			95.401,
			95.463,
			95.495,
			95.525,
			95.603,
			95.648,
			95.71,
			95.774,
			95.819,
			95.835,
			95.898,
			95.929,
			95.959,
			96.022,
			96.038,
			96.084,
			96.145,
			96.206,
			96.253,
			96.33,
			96.363,
			96.393,
			96.453,
			96.517,
			96.58,
			96.642,
			96.687,
			96.703,
			96.766,
			96.796,
			96.827,
			96.891,
			96.906,
			96.951,
			97.014,
			97.075,
			97.138,
			97.2,
			97.23,
			97.261,
			97.343,
			97.387,
			97.449,
			97.51,
			97.556,
			97.635,
			97.665,
			97.697,
			97.774,
			97.82,
			97.88,
			97.943,
			97.99,
			98.005,
			98.069,
			98.099,
			98.13,
			98.192,
			98.207,
			98.254,
			98.315,
			98.377,
			98.424,
			98.441,
			98.503,
			98.533,
			98.563,
			98.641,
			98.688,
			98.751,
			98.811,
			98.858,
			98.936,
			98.967,
			98.999,
			99.059,
			99.075,
			99.12,
			99.184,
			99.246,
			99.293,
			99.306,
			99.369,
			99.407,
			99.432,
			99.495,
			99.509,
			99.557,
			99.619,
			99.681,
			99.726,
			99.802,
			99.835,
			99.865,
			99.928,
			99.99,
			100.053,
			100.113,
			100.177,
			100.239,
			100.268,
			100.301,
			100.363,
			100.377,
			100.424,
			100.486,
			100.549,
			100.595,
			100.61,
			100.671,
			100.704,
			100.735,
			100.796,
			100.813,
			100.858,
			100.919,
			100.982,
			101.03,
			101.107,
			101.137,
			101.168,
			101.23,
			101.292,
			101.352,
			101.417,
			101.468,
			101.479,
			101.541,
			101.57,
			101.602,
			101.68,
			101.727,
			101.787,
			101.851,
			101.898,
			101.912,
			101.975,
			102.006,
			102.037,
			102.097,
			102.114,
			102.16,
			102.221,
			102.284,
			102.331,
			102.408,
			102.437,
			102.47,
			102.549,
			102.595,
			102.657,
			102.72,
			102.765,
			102.781,
			102.844,
			102.871,
			102.903,
			102.966,
			102.983,
			103.027,
			103.09,
			103.152,
			103.199,
			103.215,
			103.276,
			103.308,
			103.338,
			103.402,
			103.461,
			103.486
		]
	],
	'2472_2473_7982': [
		[
			66.042
		],
		[
			0
		],
		[
			0,
			0.365,
			2.865,
			6.632,
			9.154,
			11.684,
			12.952,
			15.495,
			16.769,
			19.324,
			23.17,
			25.744,
			28.326,
			32.214,
			34.816,
			38.723,
			40.026,
			41.331,
			45.257,
			47.884,
			51.838,
			54.483,
			57.136,
			58.465,
			61.128,
			62.462,
			63.798,
			67.817,
			69.16,
			70.505,
			74.551,
			77.256,
			79.968,
			81.327,
			84.049,
			86.779,
			88.147,
			92.263,
			95.017,
			97.777,
			101.93,
			104.704,
			108.876,
			110.266,
			111.654,
			115.789,
			118.511,
			122.525,
			125.153,
			127.745,
			129.027,
			131.563,
			132.818,
			135.299,
			137.745,
			138.954,
			141.345,
			143.703,
			146.028,
			149.444,
			151.675,
			153.867,
			154.949,
			157.086,
			158.141,
			160.224,
			162.287,
			165.29,
			167.247,
			170.116,
			171.055,
			171.984,
			174.718,
			176.482,
			179.094,
			180.788,
			182.447,
			183.269,
			184.9,
			185.711,
			187.32,
			189.73,
			191.341,
			192.947,
			195.372,
			196.998,
			199.446,
			200.265,
			201.086,
			202.781,
			203.629,
			205.322,
			207.099,
			209.847,
			211.723,
			214.7,
			215.686,
			216.683,
			219.678,
			221.636,
			224.474,
			226.347,
			228.132,
			229.023,
			230.803,
			232.511,
			233.355,
			235.04,
			235.883,
			237.515,
			239.135,
			241.558,
			243.145,
			245.535,
			246.334,
			247.125,
			249.512,
			251.117,
			253.535,
			255.161,
			256.801,
			259.287,
			260.122,
			260.96,
			263.511,
			264.368,
			265.228,
			267.843,
			269.609,
			272.29,
			274.103,
			275.927,
			276.846,
			278.698,
			279.628,
			281.498,
			283.384,
			286.219,
			288.136,
			291.034,
			292.007,
			292.983,
			295.926,
			297.903,
			300.891,
			302.896,
			304.911,
			305.923,
			307.954,
			308.972,
			311.016,
			313.071,
			314.103,
			316.175,
			318.256,
			320.345,
			322.445,
			323.498,
			325.608,
			327.72,
			328.776,
			330.885,
			331.939,
			334.048,
			336.166,
			339.357,
			341.495,
			344.717,
			345.794,
			346.871,
			350.101,
			352.244,
			355.422,
			357.508,
			359.561,
			360.573,
			362.57,
			363.554,
			365.494,
			368.35,
			370.198,
			372.012,
			374.663,
			376.39,
			378.906,
			379.726,
			380.538,
			382.141,
			382.931,
			384.49,
			386.026,
			386.786,
			388.305,
			389.817,
			392.08,
			392.848,
			393.618,
			395.933,
			396.707,
			397.49,
			399.889,
			401.493,
			403.143,
			403.985,
			405.672,
			406.516,
			408.26,
			410.046,
			410.939,
			412.744,
			414.604,
			417.393,
			419.252,
			422.035,
			422.931,
			423.81,
			426.442,
			428.15,
			430.629,
			432.271,
			433.876,
			436.262,
			437.054,
			437.842,
			440.189,
			441.747,
			444.076,
			445.621,
			447.164,
			447.938,
			449.487,
			451.033,
			451.805,
			453.37,
			454.158,
			455.735,
			457.312,
			459.783,
			461.437,
			463.987,
			464.853,
			465.719,
			468.327,
			470.075,
			472.696,
			474.439,
			477.058,
			478.763,
			479.608,
			480.454,
			482.967,
			483.747,
			485.31,
			486.88,
			488.414,
			489.914,
			490.667,
			492.18,
			493.698,
			494.459,
			496.757,
			498.319,
			499.917,
			502.336,
			504.014,
			506.577,
			507.438,
			508.31,
			510.959,
			512.742,
			515.421,
			517.223,
			519.934,
			521.754,
			523.586,
			524.506,
			526.355,
			527.285,
			529.156,
			531.026,
			533.88,
			534.838,
			535.799,
			538.703,
			539.677,
			540.655,
			542.619,
			543.606,
			545.586,
			547.58,
			550.588,
			552.608,
			555.657,
			556.679,
			557.704,
			560.796,
			562.871,
			566.001,
			568.087,
			570.181,
			571.232,
			573.343,
			574.404,
			576.533,
			578.674,
			579.75,
			581.909,
			584.079,
			587.357,
			589.557,
			592.898,
			594.012,
			595.13,
			598.497,
			600.752,
			604.14,
			606.4,
			608.655,
			612.029,
			613.145,
			614.263,
			617.584,
			618.68,
			619.774,
			623.013,
			625.155,
			627.28,
			628.337,
			630.459,
			633.627,
			635.749,
			636.807,
			638.919,
			641.028,
			644.18,
			646.272,
			649.404,
			650.448,
			651.49,
			654.62,
			656.714,
			659.868,
			661.981,
			664.099,
			665.161,
			667.288,
			668.354,
			670.482,
			672.615,
			673.684,
			675.818,
			677.963,
			681.192,
			682.272,
			683.356,
			685.529,
			687.711,
			688.805,
			690.997,
			692.096,
			694.296,
			696.493,
			697.591,
			699.78,
			701.956,
			705.186,
			706.232,
			707.288,
			710.395,
			712.409,
			715.349,
			717.254,
			720.039,
			721.861,
			722.753,
			724.509,
			726.221,
			727.065,
			728.728,
			730.35,
			732.718,
			733.491,
			734.256,
			735.747,
			737.206,
			737.922,
			740.013,
			741.358,
			742.672,
			744.588,
			745.827,
			747.651,
			748.251,
			748.847,
			750.612,
			751.774,
			753.502,
			754.647,
			756.349,
			757.477,
			758.039,
			759.161,
			760.276,
			760.831,
			761.935,
			763.033,
			764.674,
			765.22,
			765.764,
			767.382,
			767.918,
			768.453,
			770.057,
			771.122,
			772.703,
			773.751,
			774.801,
			775.328,
			776.375,
			776.896,
			777.415,
			778.97,
			780.008,
			781.555,
			782.581,
			783.607,
			784.121,
			785.141,
			786.152,
			786.657,
			787.666,
			788.172,
			789.186,
			790.2,
			791.719,
			792.735,
			794.269,
			794.796,
			795.333,
			796.948,
			798.032,
			799.682,
			800.855,
			802.034,
			802.627,
			803.82,
			805.063,
			805.714,
			807.672,
			808.329,
			810.435,
			811.971,
			813.511,
			814.284,
			816.084,
			817.936,
			818.863,
			820.773,
			821.744,
			823.688,
			825.572,
			828.319,
			830.096,
			832.568,
			833.396,
			834.226,
			836.453,
			837.913,
			840.074,
			841.427,
			842.782,
			843.461,
			844.817,
			845.484,
			846.818,
			848.153,
			848.823,
			850.175,
			851.546,
			853.609,
			854.299,
			855.003,
			857.159,
			857.88,
			858.602,
			860.819,
			862.332,
			863.855,
			866.184,
			867.761,
			870.155,
			870.961,
			871.772,
			874.213,
			875.867,
			878.373,
			880.06,
			881.759,
			882.613,
			884.332,
			886.052,
			886.911,
			888.629,
			889.492,
			891.219,
			892.957,
			895.582,
			896.457,
			897.333,
			899.973,
			900.841,
			901.705,
			904.268,
			905.948,
			908.41,
			910.009,
			911.575,
			912.344,
			913.854,
			914.596,
			915.331,
			917.494,
			918.9,
			920.972,
			922.331,
			924.341,
			925.674,
			927.005,
			927.67,
			929.002,
			929.67,
			931.02,
			932.377,
			934.418,
			935.8,
			937.903,
			938.604,
			939.305,
			941.481,
			942.96,
			945.177,
			946.788,
			948.395,
			949.197,
			950.843,
			951.701,
			953.41,
			955.958,
			956.803,
			959.315,
			960.927,
			962.459,
			963.219,
			964.732,
			966.14,
			966.811,
			968.81,
			970.134,
			971.389,
			973.238,
			974.462,
			976.293,
			976.901,
			977.508,
			979.326,
			980.543,
			982.401,
			983.636,
			984.871,
			985.492,
			986.746,
			987.372,
			988.621,
			990.498,
			991.743,
			992.985,
			994.227,
			996.093,
			997.954,
			998.576,
			999.199,
			1001.089,
			1002.371,
			1003.658,
			1005.607,
			1006.972,
			1009.034,
			1009.727,
			1010.422,
			1012.628,
			1014.115,
			1016.365,
			1017.884,
			1019.414,
			1020.183,
			1021.65,
			1022.374,
			1023.832,
			1025.305,
			1026.024,
			1027.439,
			1028.864,
			1031.033,
			1031.784,
			1032.538,
			1034.818,
			1035.584,
			1036.374,
			1037.968,
			1038.77,
			1040.382,
			1041.994,
			1044.43,
			1046.066,
			1048.535,
			1049.364,
			1050.196,
			1052.716,
			1054.408,
			1056.928,
			1058.628,
			1060.341,
			1061.199,
			1062.921,
			1064.658,
			1065.531,
			1067.286,
			1068.169,
			1069.945,
			1071.739,
			1074.457,
			1076.282,
			1079.055,
			1079.975,
			1080.897,
			1083.684,
			1085.543,
			1088.364,
			1090.249,
			1092.166,
			1093.133,
			1095.069,
			1096.042,
			1097.059,
			1100.114,
			1101.144,
			1102.229,
			1105.492,
			1107.698,
			1111.024,
			1113.124,
			1115.224,
			1116.258,
			1119.14,
			1121.065,
			1122.913,
			1125.694,
			1127.568,
			1130.404,
			1131.359,
			1132.326,
			1135.248,
			1137.218,
			1140.203,
			1142.213,
			1144.238,
			1145.255,
			1147.3,
			1148.329,
			1150.394,
			1153.5,
			1155.598,
			1157.701,
			1160.855,
			1161.909,
			1162.966,
			1165.088,
			1167.219,
			1168.288,
			1171.512,
			1173.675,
			1175.85,
			1179.137,
			1181.342,
			1184.682,
			1185.801,
			1186.925,
			1190.329,
			1192.622,
			1196.095,
			1198.433,
			1200.784,
			1201.964,
			1204.329,
			1205.515,
			1207.891,
			1210.272,
			1211.467,
			1213.855,
			1216.253,
			1219.865,
			1222.284,
			1225.93,
			1227.15,
			1228.39,
			1232.043,
			1234.494,
			1236.954,
			1240.639,
			1243.093,
			1246.759,
			1247.977,
			1249.193,
			1252.826,
			1254.026,
			1255.242,
			1258.784,
			1261.106,
			1263.4,
			1264.537,
			1266.799,
			1269.042,
			1270.158,
			1272.385,
			1273.495,
			1275.716,
			1277.941,
			1281.293,
			1283.546,
			1286.947,
			1288.091,
			1289.239,
			1292.697,
			1295.031,
			1298.567,
			1300.95,
			1303.346,
			1304.551,
			1306.95,
			1308.148,
			1310.534,
			1312.888,
			1314.054,
			1316.363,
			1318.653,
			1320.917,
			1323.155,
			1324.268,
			1326.484,
			1328.682,
			1329.772,
			1331.939,
			1332.987,
			1335.104,
			1337.171,
			1340.185,
			1342.15,
			1345.001,
			1345.954,
			1346.883,
			1349.617,
			1351.417,
			1354.094,
			1355.844,
			1357.525,
			1358.353,
			1359.975,
			1360.78,
			1362.364,
			1363.9,
			1364.653,
			1366.133,
			1367.577,
			1368.962,
			1370.313,
			1370.977,
			1372.283,
			1373.545,
			1374.171,
			1375.377,
			1375.967,
			1377.12,
			1378.237,
			1379.843,
			1380.871,
			1382.353,
			1382.834,
			1383.313,
			1384.715,
			1385.626,
			1386.97,
			1387.863,
			1388.799,
			1389.265,
			1390.197,
			1390.663,
			1391.598,
			1393.036,
			1394.064,
			1395.096,
			1396.658,
			1397.71,
			1399.359,
			1399.909,
			1400.459,
			1402.104,
			1403.203,
			1404.307,
			1405.936,
			1407.01,
			1408.631,
			1409.175,
			1409.715,
			1411.327,
			1411.863,
			1412.399,
			1414.003,
			1415.064,
			1416.118,
			1416.642,
			1417.687,
			1418.693,
			1419.186,
			1420.164,
			1420.65,
			1421.621,
			1422.583,
			1423.95,
			1424.834,
			1426.141,
			1426.57,
			1426.998,
			1428.262,
			1429.063,
			1430.244,
			1431.023,
			1431.8,
			1432.188,
			1432.966,
			1433.349,
			1433.732,
			1434.881,
			1435.265,
			1435.648,
			1436.801,
			1437.573,
			1438.754,
			1439.552,
			1440.353,
			1440.755,
			1441.564,
			1441.972,
			1442.793,
			1443.657,
			1444.99,
			1445.889,
			1447.259,
			1447.722,
			1448.195,
			1449.67,
			1450.669,
			1452.194,
			1453.232,
			1454.291,
			1454.826,
			1455.908,
			1456.455,
			1457.562,
			1458.678,
			1459.237,
			1460.364,
			1461.508,
			1463.255,
			1464.413,
			1465.585,
			1466.773,
			1467.373,
			1468.57,
			1469.159,
			1470.338,
			1471.53,
			1473.341,
			1474.582,
			1476.471,
			1477.108,
			1477.749,
			1479.77,
			1481.152,
			1483.257,
			1484.741,
			1486.242,
			1486.999,
			1488.528,
			1489.305,
			1490.871,
			1492.453,
			1493.25,
			1494.833,
			1496.43,
			1498.846,
			1500.449,
			1502.882,
			1503.697,
			1504.506,
			1506.955,
			1508.606,
			1510.271,
			1512.778,
			1514.455,
			1516.984,
			1517.838,
			1518.696,
			1521.292,
			1522.164,
			1523.039,
			1525.684,
			1527.463,
			1530.157,
			1531.971,
			1533.797,
			1534.718,
			1536.568,
			1537.499,
			1539.37,
			1541.257,
			1544.111,
			1546.026,
			1548.922,
			1549.892,
			1550.865,
			1553.801,
			1555.772,
			1558.75,
			1560.75,
			1562.761,
			1563.771,
			1565.799,
			1566.818,
			1567.839,
			1571.953,
			1572.989,
			1576.112,
			1578.207,
			1581.349,
			1583.449,
			1585.562,
			1586.622,
			1588.75,
			1589.819,
			1591.964,
			1594.118,
			1597.371,
			1599.551,
			1602.84,
			1603.941,
			1605.043,
			1608.365,
			1610.591,
			1613.947,
			1616.195,
			1619.586,
			1621.858,
			1622.997,
			1625.283,
			1627.578,
			1628.73,
			1631.039,
			1633.357,
			1636.851,
			1638.02,
			1639.191,
			1642.72,
			1643.901,
			1645.084,
			1647.459,
			1648.65,
			1651.039,
			1653.439,
			1657.056,
			1659.479,
			1663.131,
			1664.353,
			1665.577,
			1669.264,
			1671.733,
			1675.452,
			1677.942,
			1680.441,
			1681.694,
			1684.206,
			1685.465,
			1687.989,
			1690.521,
			1691.79,
			1694.335,
			1696.888,
			1700.734,
			1703.307,
			1707.182,
			1708.477,
			1709.775,
			1713.679,
			1716.287,
			1720.202,
			1722.821,
			1725.446,
			1729.399,
			1730.72,
			1732.043,
			1736.021,
			1737.351,
			1738.682,
			1742.686,
			1745.364,
			1748.049,
			1749.394,
			1752.09,
			1753.44,
			1756.147,
			1760.22,
			1762.945,
			1765.676,
			1769.787,
			1772.537,
			1776.676,
			1778.059,
			1779.444,
			1783.609,
			1786.394,
			1790.583,
			1793.384,
			1796.19,
			1797.596,
			1800.413,
			1801.824,
			1803.237,
			1807.484,
			1808.902,
			1810.323,
			1814.594,
			1817.449,
			1820.311,
			1821.745,
			1824.616,
			1827.494,
			1828.937,
			1831.826,
			1833.273,
			1836.172,
			1839.078,
			1843.45,
			1846.372,
			1850.767,
			1853.704,
			1858.12,
			1861.071,
			1865.508,
			1868.474,
			1872.935,
			1875.914,
			1877.406,
			1880.395,
			1883.39,
			1884.889,
			1886.389,
			1890.9,
			1893.915,
			1896.936,
			1898.448,
			1901.479,
			1904.517,
			1906.038,
			1909.085,
			1910.61,
			1913.666,
			1916.729,
			1921.333,
			1924.409,
			1929.033,
			1930.577,
			1932.122,
			1936.765,
			1938.315,
			1939.867,
			1944.53,
			1947.646,
			1950.766,
			1952.328,
			1955.456,
			1957.023,
			1960.16,
			1963.302,
			1964.876,
			1968.027,
			1971.184,
			1975.929,
			1977.513,
			1979.099,
			1983.86,
			1985.449,
			1987.039,
			1991.817,
			1995.007,
			1999.793,
			2002.991,
			2006.193,
			2011.004,
			2012.609,
			2014.215,
			2019.04,
			2022.26,
			2027.098,
			2030.329,
			2033.564,
			2035.183,
			2038.424,
			2040.046,
			2043.293,
			2048.17,
			2051.425,
			2054.684,
			2059.58,
			2062.85,
			2067.764,
			2069.404,
			2071.045,
			2075.978,
			2079.271,
			2084.221,
			2087.526,
			2090.834,
			2092.49,
			2095.804,
			2097.46,
			2099.117,
			2104.07,
			2105.712,
			2107.347,
			2112.207,
			2115.402,
			2118.558,
			2120.121,
			2123.219,
			2126.278,
			2127.794,
			2130.799,
			2132.288,
			2135.239,
			2138.152,
			2142.453,
			2145.271,
			2149.424,
			2150.781,
			2152.128,
			2156.113,
			2158.71,
			2162.552,
			2165.082,
			2167.573,
			2168.803,
			2171.236,
			2172.441,
			2174.824,
			2178.338,
			2180.637,
			2182.899,
			2186.222,
			2188.396,
			2190.532,
			2192.635,
			2193.674,
			2196.735,
			2198.732,
			2200.692,
			2203.563,
			2205.431,
			2208.167,
			2209.061,
			2209.947,
			2212.549,
			2214.235,
			2216.693,
			2218.279,
			2220.59,
			2222.082,
			2222.812,
			2224.256,
			2225.653,
			2226.339,
			2227.684,
			2228.997,
			2230.912,
			2231.534,
			2232.142,
			2233.917,
			2234.494,
			2235.064,
			2236.73,
			2237.799,
			2238.848,
			2240.391,
			2241.407,
			2242.89,
			2243.381,
			2243.871,
			2245.341,
			2246.323,
			2247.768,
			2248.729,
			2250.175,
			2251.144,
			2252.133,
			2252.631,
			2253.631,
			2254.132,
			2255.138,
			2256.15,
			2257.95,
			2258.577,
			2259.206,
			2261.107,
			2261.883,
			2262.864,
			2265.825,
			2268.358,
			2272.633,
			2275.532,
			2277.988,
			2279.096,
			2281.315,
			2282.143,
			2282.896,
			2285.917,
			2286.65,
			2288.396,
			2289.564,
			2290.739,
			2291.33,
			2292.458,
			2293.573,
			2294.132,
			2295.255,
			2295.819,
			2296.966,
			2298.145,
			2299.923,
			2301.121,
			2303.002,
			2303.643,
			2304.285,
			2306.227,
			2306.886,
			2307.563,
			2309.603,
			2310.971,
			2312.36,
			2313.064,
			2314.477,
			2315.187,
			2316.613,
			2318.056,
			2318.781,
			2320.239,
			2321.707,
			2323.192,
			2324.689,
			2325.442,
			2326.959,
			2328.49,
			2329.26,
			2330.797,
			2331.578,
			2333.148,
			2334.735,
			2337.145,
			2338.776,
			2341.251,
			2342.084,
			2342.923,
			2345.459,
			2347.156,
			2349.71,
			2351.43,
			2354.034,
			2355.787,
			2356.668,
			2358.435,
			2361.109,
			2362.902,
			2364.702,
			2367.426,
			2368.337,
			2369.252,
			2372.016,
			2373.875,
			2376.687,
			2378.582,
			2380.49,
			2383.379,
			2385.323,
			2388.262,
			2389.248,
			2390.237,
			2393.222,
			2395.225,
			2398.25,
			2400.281,
			2403.349,
			2405.407,
			2406.44,
			2408.516,
			2410.602,
			2411.648,
			2413.747,
			2415.844,
			2419.001,
			2420.058,
			2421.118,
			2424.313,
			2425.384,
			2426.456,
			2429.689,
			2431.856,
			2435.125,
			2437.318,
			2439.52,
			2440.625,
			2442.842,
			2443.954,
			2446.185,
			2448.426,
			2449.549,
			2450.676,
			2454.069,
			2456.343,
			2458.627,
			2459.772,
			2462.07,
			2464.377,
			2465.535,
			2467.856,
			2469.021,
			2471.358,
			2473.704,
			2477.241,
			2479.61,
			2483.18,
			2484.375,
			2485.572,
			2489.177,
			2491.591,
			2495.229,
			2497.667,
			2500.114,
			2501.341,
			2503.8,
			2505.033,
			2507.506,
			2509.987,
			2511.231,
			2512.478,
			2512.958
		],
		[
			0,
			0.017,
			0.062,
			0.125,
			0.186,
			0.235,
			0.249,
			0.311,
			0.341,
			0.372,
			0.451,
			0.496,
			0.559,
			0.621,
			0.668,
			0.744,
			0.775,
			0.806,
			0.87,
			0.933,
			0.998,
			1.055,
			1.102,
			1.118,
			1.178,
			1.212,
			1.24,
			1.303,
			1.32,
			1.364,
			1.428,
			1.489,
			1.537,
			1.551,
			1.612,
			1.646,
			1.676,
			1.754,
			1.799,
			1.862,
			1.924,
			1.984,
			2.047,
			2.08,
			2.109,
			2.172,
			2.233,
			2.296,
			2.359,
			2.403,
			2.419,
			2.482,
			2.512,
			2.545,
			2.604,
			2.621,
			2.668,
			2.728,
			2.791,
			2.855,
			2.914,
			2.945,
			2.978,
			3.041,
			3.061,
			3.102,
			3.164,
			3.225,
			3.272,
			3.351,
			3.381,
			3.411,
			3.475,
			3.535,
			3.599,
			3.661,
			3.705,
			3.722,
			3.784,
			3.816,
			3.846,
			3.922,
			3.97,
			4.032,
			4.095,
			4.155,
			4.219,
			4.25,
			4.278,
			4.342,
			4.359,
			4.403,
			4.467,
			4.528,
			4.589,
			4.653,
			4.684,
			4.713,
			4.776,
			4.838,
			4.899,
			4.961,
			5.009,
			5.023,
			5.085,
			5.122,
			5.149,
			5.211,
			5.225,
			5.272,
			5.335,
			5.396,
			5.444,
			5.52,
			5.551,
			5.581,
			5.659,
			5.707,
			5.769,
			5.829,
			5.878,
			5.953,
			5.984,
			6.017,
			6.077,
			6.095,
			6.139,
			6.201,
			6.265,
			6.325,
			6.39,
			6.42,
			6.45,
			6.512,
			6.526,
			6.575,
			6.638,
			6.699,
			6.743,
			6.822,
			6.853,
			6.885,
			6.947,
			7.009,
			7.07,
			7.132,
			7.184,
			7.193,
			7.257,
			7.286,
			7.319,
			7.381,
			7.395,
			7.444,
			7.506,
			7.567,
			7.614,
			7.628,
			7.689,
			7.722,
			7.754,
			7.813,
			7.831,
			7.877,
			7.938,
			8.002,
			8.047,
			8.125,
			8.155,
			8.187,
			8.265,
			8.311,
			8.372,
			8.435,
			8.48,
			8.497,
			8.558,
			8.589,
			8.62,
			8.698,
			8.746,
			8.808,
			8.87,
			8.932,
			8.992,
			9.022,
			9.054,
			9.117,
			9.133,
			9.178,
			9.239,
			9.245,
			9.303,
			9.349,
			9.425,
			9.458,
			9.488,
			9.551,
			9.568,
			9.614,
			9.677,
			9.736,
			9.784,
			9.799,
			9.862,
			9.892,
			9.922,
			9.986,
			10,
			10.046,
			10.111,
			10.171,
			10.218,
			10.296,
			10.327,
			10.357,
			10.419,
			10.482,
			10.543,
			10.604,
			10.653,
			10.73,
			10.76,
			10.792,
			10.853,
			10.914,
			10.977,
			11.039,
			11.086,
			11.101,
			11.164,
			11.195,
			11.224,
			11.288,
			11.309,
			11.35,
			11.41,
			11.472,
			11.534,
			11.598,
			11.628,
			11.659,
			11.722,
			11.784,
			11.845,
			11.907,
			11.97,
			12.033,
			12.061,
			12.092,
			12.155,
			12.172,
			12.219,
			12.279,
			12.34,
			12.388,
			12.403,
			12.466,
			12.498,
			12.526,
			12.606,
			12.653,
			12.714,
			12.775,
			12.837,
			12.9,
			12.929,
			12.961,
			13.039,
			13.084,
			13.146,
			13.209,
			13.271,
			13.335,
			13.369,
			13.395,
			13.457,
			13.472,
			13.518,
			13.582,
			13.644,
			13.688,
			13.706,
			13.767,
			13.798,
			13.829,
			13.892,
			13.908,
			13.954,
			14.017,
			14.077,
			14.125,
			14.202,
			14.231,
			14.265,
			14.326,
			14.388,
			14.451,
			14.511,
			14.558,
			14.575,
			14.635,
			14.667,
			14.699,
			14.758,
			14.776,
			14.822,
			14.884,
			14.945,
			14.993,
			15.069,
			15.101,
			15.133,
			15.195,
			15.257,
			15.318,
			15.378,
			15.442,
			15.503,
			15.535,
			15.567,
			15.629,
			15.644,
			15.691,
			15.753,
			15.814,
			15.861,
			15.876,
			15.938,
			15.999,
			16.063,
			16.078,
			16.123,
			16.186,
			16.247,
			16.309,
			16.373,
			16.404,
			16.434,
			16.495,
			16.557,
			16.62,
			16.682,
			16.73,
			16.744,
			16.806,
			16.838,
			16.869,
			16.93,
			16.944,
			16.993,
			17.055,
			17.117,
			17.163,
			17.178,
			17.241,
			17.272,
			17.303,
			17.364,
			17.378,
			17.425,
			17.488,
			17.494,
			17.551,
			17.596,
			17.674,
			17.705,
			17.737,
			17.8,
			17.86,
			17.923,
			17.984,
			18.048,
			18.108,
			18.14,
			18.172,
			18.233,
			18.247,
			18.294,
			18.356,
			18.419,
			18.465,
			18.481,
			18.542,
			18.572,
			18.604,
			18.681,
			18.728,
			18.792,
			18.852,
			18.897,
			18.976,
			19.008,
			19.037,
			19.102,
			19.161,
			19.225,
			19.286,
			19.35,
			19.41,
			19.441,
			19.472,
			19.535,
			19.555,
			19.595,
			19.658,
			19.722,
			19.767,
			19.782,
			19.845,
			19.875,
			19.905,
			19.968,
			20.03,
			20.096,
			20.156,
			20.201,
			20.218,
			20.278,
			20.308,
			20.34,
			20.417,
			20.466,
			20.53,
			20.588,
			20.634,
			20.651,
			20.712,
			20.744,
			20.774,
			20.836,
			20.853,
			20.9,
			20.964,
			21.022,
			21.069,
			21.146,
			21.178,
			21.208,
			21.272,
			21.333,
			21.396,
			21.458,
			21.503,
			21.518,
			21.582,
			21.618,
			21.643,
			21.72,
			21.767,
			21.829,
			21.892,
			21.937,
			21.952,
			22.014,
			22.046,
			22.077,
			22.14,
			22.156,
			22.202,
			22.263,
			22.324,
			22.372,
			22.447,
			22.479,
			22.51,
			22.572,
			22.636,
			22.696,
			22.759,
			22.805,
			22.822,
			22.883,
			22.914,
			22.946,
			23.008,
			23.022,
			23.07,
			23.13,
			23.192,
			23.239,
			23.256,
			23.318,
			23.347,
			23.38,
			23.442,
			23.502,
			23.564,
			23.626,
			23.678,
			23.751,
			23.783,
			23.812,
			23.875,
			23.938,
			24,
			24.061,
			24.107,
			24.125,
			24.185,
			24.217,
			24.248,
			24.31,
			24.324,
			24.372,
			24.434,
			24.496,
			24.542,
			24.557,
			24.618,
			24.651,
			24.682,
			24.744,
			24.805,
			24.868,
			24.929,
			24.978,
			24.993,
			25.053,
			25.084,
			25.117,
			25.178,
			25.24,
			25.301,
			25.362,
			25.425,
			25.487,
			25.519,
			25.55,
			25.612,
			25.626,
			25.673,
			25.741,
			25.796,
			25.859,
			25.921,
			25.953,
			25.985,
			26.046,
			26.107,
			26.17,
			26.23,
			26.279,
			26.294,
			26.357,
			26.387,
			26.418,
			26.496,
			26.541,
			26.603,
			26.664,
			26.713,
			26.727,
			26.791,
			26.82,
			26.852,
			26.929,
			26.976,
			27.037,
			27.099,
			27.146,
			27.224,
			27.255,
			27.285,
			27.348,
			27.41,
			27.472,
			27.535,
			27.582,
			27.595,
			27.658,
			27.689,
			27.72,
			27.803,
			27.843,
			27.907,
			27.967,
			28.028,
			28.093,
			28.124,
			28.155,
			28.233,
			28.279,
			28.339,
			28.401,
			28.448,
			28.527,
			28.558,
			28.587,
			28.65,
			28.711,
			28.774,
			28.837,
			28.881,
			28.899,
			28.961,
			28.991,
			29.022,
			29.084,
			29.097,
			29.148,
			29.21,
			29.27,
			29.317,
			29.33,
			29.395,
			29.425,
			29.457,
			29.517,
			29.534,
			29.582,
			29.642,
			29.706,
			29.752,
			29.829,
			29.864,
			29.889,
			29.967,
			30.016,
			30.075,
			30.139,
			30.186,
			30.2,
			30.263,
			30.295,
			30.325,
			30.388,
			30.402,
			30.449,
			30.511,
			30.571,
			30.619,
			30.695,
			30.726,
			30.757,
			30.837,
			30.881,
			30.943,
			31.008,
			31.053,
			31.07,
			31.132,
			31.163,
			31.192,
			31.256,
			31.27,
			31.317,
			31.378,
			31.439,
			31.502,
			31.564,
			31.596,
			31.626,
			31.705,
			31.751,
			31.813,
			31.876,
			31.937,
			31.999,
			32.029,
			32.06,
			32.137,
			32.185,
			32.248,
			32.308,
			32.357,
			32.371,
			32.434,
			32.462,
			32.494,
			32.572,
			32.62,
			32.683,
			32.744,
			32.789,
			32.806,
			32.867,
			32.898,
			32.929,
			33.006,
			33.054,
			33.116,
			33.178,
			33.223,
			33.301,
			33.33,
			33.364,
			33.426,
			33.488,
			33.547,
			33.61,
			33.658,
			33.672,
			33.734,
			33.767,
			33.798,
			33.86,
			33.874,
			33.922,
			33.988,
			34.044,
			34.106,
			34.169,
			34.2,
			34.229,
			34.293,
			34.355,
			34.417,
			34.478,
			34.526,
			34.603,
			34.635,
			34.663,
			34.728,
			34.743,
			34.789,
			34.852,
			34.911,
			34.958,
			34.975,
			35.037,
			35.069,
			35.1,
			35.162,
			35.178,
			35.224,
			35.286,
			35.348,
			35.393,
			35.472,
			35.501,
			35.535,
			35.595,
			35.657,
			35.72,
			35.781,
			35.829,
			35.843,
			35.905,
			35.938,
			35.967,
			36.03,
			36.047,
			36.093,
			36.152,
			36.214,
			36.262,
			36.277,
			36.34,
			36.372,
			36.401,
			36.465,
			36.479,
			36.524,
			36.588,
			36.65,
			36.697,
			36.772,
			36.804,
			36.834,
			36.896,
			36.961,
			37.022,
			37.085,
			37.129,
			37.146,
			37.208,
			37.24,
			37.27,
			37.33,
			37.348,
			37.394,
			37.456,
			37.517,
			37.566,
			37.579,
			37.64,
			37.672,
			37.704,
			37.766,
			37.782,
			37.828,
			37.891,
			37.952,
			37.998,
			38.075,
			38.11,
			38.138,
			38.2,
			38.261,
			38.325,
			38.386,
			38.433,
			38.446,
			38.51,
			38.541,
			38.572,
			38.65,
			38.696,
			38.757,
			38.82,
			38.882,
			38.943,
			38.975,
			39.007,
			39.069,
			39.129,
			39.192,
			39.254,
			39.301,
			39.378,
			39.41,
			39.441,
			39.502,
			39.519,
			39.564,
			39.625,
			39.687,
			39.735,
			39.75,
			39.812,
			39.843,
			39.873,
			39.935,
			39.952,
			39.996,
			40.059,
			40.121,
			40.184,
			40.245,
			40.277,
			40.308,
			40.371,
			40.433,
			40.494,
			40.557,
			40.602,
			40.619,
			40.682,
			40.711,
			40.741,
			40.805,
			40.82,
			40.867,
			40.931,
			40.989,
			41.052,
			41.114,
			41.144,
			41.176,
			41.238,
			41.255,
			41.3,
			41.362,
			41.424,
			41.47,
			41.549,
			41.58,
			41.611,
			41.673,
			41.735,
			41.796,
			41.858,
			41.906,
			41.922,
			41.982,
			42.013,
			42.044,
			42.106,
			42.123,
			42.169,
			42.235,
			42.293,
			42.354,
			42.417,
			42.448,
			42.478,
			42.539,
			42.557,
			42.602,
			42.665,
			42.725,
			42.788,
			42.851,
			42.882,
			42.912,
			42.975,
			43.036,
			43.097,
			43.162,
			43.207,
			43.221,
			43.284,
			43.317,
			43.346,
			43.408,
			43.425,
			43.471,
			43.533,
			43.595,
			43.642,
			43.72,
			43.75,
			43.782,
			43.843,
			43.905,
			43.967,
			44.029,
			44.092,
			44.153,
			44.184,
			44.216,
			44.278,
			44.295,
			44.34,
			44.403,
			44.462,
			44.526,
			44.587,
			44.619,
			44.65,
			44.711,
			44.725,
			44.773,
			44.836,
			44.897,
			44.96,
			45.022,
			45.053,
			45.081,
			45.146,
			45.208,
			45.269,
			45.331,
			45.379,
			45.393,
			45.455,
			45.486,
			45.517,
			45.594,
			45.64,
			45.702,
			45.763,
			45.827,
			45.888,
			45.92,
			45.951,
			46.014,
			46.028,
			46.075,
			46.139,
			46.2,
			46.244,
			46.322,
			46.359,
			46.385,
			46.447,
			46.508,
			46.575,
			46.635,
			46.696,
			46.758,
			46.787,
			46.819,
			46.882,
			46.895,
			46.943,
			47.006,
			47.069,
			47.112,
			47.13,
			47.193,
			47.222,
			47.254,
			47.314,
			47.332,
			47.379,
			47.439,
			47.502,
			47.549,
			47.625,
			47.655,
			47.689,
			47.751,
			47.812,
			47.873,
			47.934,
			47.981,
			47.999,
			48.058,
			48.091,
			48.122,
			48.184,
			48.199,
			48.247,
			48.307,
			48.37,
			48.419,
			48.495,
			48.526,
			48.557,
			48.618,
			48.68,
			48.743,
			48.802,
			48.851,
			48.929,
			48.96,
			48.991,
			49.051,
			49.068,
			49.114,
			49.175,
			49.239,
			49.284,
			49.297,
			49.36,
			49.391,
			49.423,
			49.502,
			49.549,
			49.61,
			49.672,
			49.719,
			49.795,
			49.828,
			49.856,
			49.92,
			49.981,
			50.045,
			50.105,
			50.152,
			50.169,
			50.229,
			50.26,
			50.292,
			50.355,
			50.369,
			50.416,
			50.478,
			50.54,
			50.587,
			50.601,
			50.665,
			50.694,
			50.727,
			50.789,
			50.803,
			50.85,
			50.912,
			50.975,
			51.021,
			51.099,
			51.161,
			51.223,
			51.284,
			51.345,
			51.409,
			51.47,
			51.533,
			51.563,
			51.595,
			51.655,
			51.672,
			51.717,
			51.78,
			51.842,
			51.888,
			51.905,
			51.967,
			51.997,
			52.028,
			52.09,
			52.107,
			52.152,
			52.214,
			52.275,
			52.321,
			52.402,
			52.431,
			52.464,
			52.526,
			52.543,
			52.588,
			52.649,
			52.712,
			52.758,
			52.773,
			52.834,
			52.866,
			52.897,
			52.959,
			52.973,
			53.019,
			53.083,
			53.143,
			53.189,
			53.207,
			53.269,
			53.3,
			53.332,
			53.409,
			53.456,
			53.518,
			53.58,
			53.625,
			53.701,
			53.733,
			53.764,
			53.827,
			53.889,
			53.95,
			54.012,
			54.058,
			54.074,
			54.138,
			54.167,
			54.199,
			54.276,
			54.323,
			54.386,
			54.447,
			54.494,
			54.572,
			54.606,
			54.63,
			54.693,
			54.757,
			54.82,
			54.879,
			54.928,
			54.943,
			55.005,
			55.037,
			55.067,
			55.129,
			55.143,
			55.192,
			55.254,
			55.316,
			55.361,
			55.378,
			55.438,
			55.469,
			55.501,
			55.562,
			55.578,
			55.624,
			55.687,
			55.75,
			55.795,
			55.874,
			55.905,
			55.936,
			55.998,
			56.06,
			56.126,
			56.184,
			56.229,
			56.245,
			56.308,
			56.339,
			56.368,
			56.445,
			56.493,
			56.56,
			56.618,
			56.679,
			56.742,
			56.771,
			56.802,
			56.879,
			56.928,
			56.99,
			57.052,
			57.098,
			57.176,
			57.205,
			57.237,
			57.299,
			57.36,
			57.422,
			57.484,
			57.546,
			57.609,
			57.64,
			57.672,
			57.734,
			57.747,
			57.797,
			57.858,
			57.92,
			57.964,
			57.982,
			58.043,
			58.075,
			58.104,
			58.183,
			58.231,
			58.292,
			58.352,
			58.401,
			58.478,
			58.507,
			58.538,
			58.603,
			58.663,
			58.729,
			58.788,
			58.85,
			58.91,
			58.943,
			58.974,
			59.035,
			59.052,
			59.097,
			59.159,
			59.222,
			59.267,
			59.284,
			59.345,
			59.376,
			59.408,
			59.47,
			59.532,
			59.593,
			59.655,
			59.703,
			59.718,
			59.778,
			59.811,
			59.841,
			59.92,
			59.964,
			60.028,
			60.09,
			60.136,
			60.152,
			60.213,
			60.245,
			60.275,
			60.337,
			60.352,
			60.399,
			60.461,
			60.522,
			60.569,
			60.646,
			60.68,
			60.711,
			60.771,
			60.793,
			60.834,
			60.897,
			60.957,
			61.006,
			61.02,
			61.081,
			61.112,
			61.144,
			61.207,
			61.221,
			61.268,
			61.328,
			61.392,
			61.438,
			61.455,
			61.517,
			61.548,
			61.577,
			61.639,
			61.656,
			61.701,
			61.764,
			61.826,
			61.872,
			61.95,
			61.981,
			62.011,
			62.075,
			62.137,
			62.196,
			62.26,
			62.322,
			62.384,
			62.416,
			62.447,
			62.522,
			62.569,
			62.633,
			62.695,
			62.739,
			62.755,
			62.818,
			62.878,
			62.943,
			63.004,
			63.067,
			63.128,
			63.175,
			63.252,
			63.284,
			63.314,
			63.391,
			63.438,
			63.5,
			63.561,
			63.625,
			63.687,
			63.717,
			63.749,
			63.811,
			63.827,
			63.872,
			63.936,
			63.997,
			64.042,
			64.058,
			64.12,
			64.152,
			64.184,
			64.243,
			64.305,
			64.37,
			64.429,
			64.478,
			64.493,
			64.555,
			64.587,
			64.618,
			64.678,
			64.694,
			64.74,
			64.802,
			64.864,
			64.914,
			64.928,
			64.989,
			65.018,
			65.05,
			65.114,
			65.128,
			65.175,
			65.236,
			65.3,
			65.346,
			65.422,
			65.455,
			65.486,
			65.563,
			65.607,
			65.672,
			65.734,
			65.778,
			65.795,
			65.858,
			65.889,
			65.92,
			65.982,
			65.996,
			66.042,
			66.042
		]
	],
	'2383_2384_7982': [
		[
			90.644
		],
		[
			0
		],
		[
			0,
			0.172,
			4.284,
			7.033,
			11.168,
			12.549,
			13.932,
			18.091,
			20.873,
			25.058,
			27.856,
			30.661,
			32.066,
			34.88,
			36.29,
			37.701,
			41.945,
			43.362,
			44.782,
			49.05,
			51.903,
			54.763,
			56.195,
			59.065,
			61.94,
			63.38,
			66.266,
			67.71,
			70.605,
			73.506,
			77.869,
			80.785,
			85.171,
			86.636,
			88.103,
			92.511,
			95.458,
			99.889,
			102.85,
			107.303,
			110.279,
			111.769,
			114.754,
			117.743,
			119.24,
			122.238,
			125.242,
			129.758,
			132.776,
			137.314,
			138.83,
			140.347,
			144.906,
			147.952,
			151.004,
			155.592,
			158.658,
			163.266,
			164.805,
			166.345,
			172.519,
			174.065,
			178.713,
			181.819,
			184.929,
			186.486,
			189.604,
			191.165,
			194.29,
			198.986,
			202.121,
			205.262,
			209.982,
			213.137,
			217.879,
			219.463,
			221.047,
			225.808,
			228.989,
			233.764,
			236.951,
			240.143,
			241.742,
			244.944,
			246.547,
			248.152,
			252.971,
			254.58,
			256.189,
			261.023,
			264.249,
			267.479,
			269.096,
			272.333,
			275.574,
			277.196,
			282.07,
			285.325,
			288.585,
			293.483,
			296.754,
			301.668,
			303.309,
			304.95,
			309.877,
			313.155,
			318.037,
			321.258,
			324.446,
			326.027,
			329.16,
			330.711,
			333.785,
			336.823,
			338.329,
			341.314,
			344.264,
			347.176,
			351.472,
			354.288,
			357.067,
			358.443,
			361.164,
			362.511,
			365.176,
			367.804,
			371.675,
			374.209,
			377.94,
			379.165,
			380.38,
			383.974,
			386.327,
			389.792,
			392.054,
			395.376,
			397.543,
			398.613,
			400.724,
			402.798,
			403.821,
			405.84,
			407.824,
			410.73,
			412.622,
			414.477,
			416.298,
			417.195,
			418.964,
			419.836,
			421.554,
			423.233,
			425.683,
			427.274,
			429.598,
			430.354,
			431.101,
			433.288,
			434.702,
			436.759,
			438.079,
			439.367,
			439.998,
			441.235,
			441.84,
			443.026,
			444.737,
			445.833,
			446.895,
			448.446,
			449.432,
			450.863,
			451.33,
			451.791,
			453.145,
			454.021,
			455.31,
			456.16,
			457.413,
			458.222,
			458.627,
			459.032,
			460.25,
			460.658,
			461.067,
			462.294,
			463.091,
			464.293,
			465.102,
			465.92,
			466.331,
			467.177,
			467.704,
			468.761,
			469.825,
			471.434,
			472.748,
			475.472,
			476.383,
			477.317,
			480.668,
			482.781,
			485.538,
			487.315,
			488.408,
			488.957,
			490.059,
			490.613,
			491.727,
			492.749,
			493.221,
			494.171,
			495.128,
			496.582,
			497.577,
			498.629,
			499.692,
			500.228,
			501.856,
			502.989,
			504.163,
			505.935,
			507.133,
			509.063,
			509.714,
			510.363,
			512.298,
			513.677,
			515.744,
			517.113,
			518.499,
			519.196,
			520.579,
			521.267,
			522.636,
			524.7,
			526.06,
			527.409,
			529.414,
			530.073,
			530.729,
			532.681,
			533.316,
			533.924,
			535.13,
			535.729,
			536.922,
			538.109,
			539.857,
			541.009,
			542.73,
			543.304,
			543.871,
			545.558,
			546.68,
			548.366,
			549.469,
			550.568,
			551.118,
			552.221,
			552.775,
			553.884,
			554.988,
			555.541,
			556.652,
			557.769,
			559.467,
			560.609,
			562.335,
			562.916,
			563.5,
			565.28,
			566.475,
			568.28,
			569.495,
			570.719,
			571.332,
			572.56,
			573.175,
			573.792,
			576.285,
			576.909,
			578.78,
			580.039,
			581.304,
			581.936,
			583.202,
			584.474,
			585.118,
			586.405,
			587.05,
			588.344,
			589.643,
			591.625,
			592.953,
			594.962,
			595.651,
			596.341,
			598.421,
			599.82,
			601.978,
			603.427,
			605.631,
			607.143,
			607.9,
			609.421,
			611.701,
			613.224,
			614.756,
			616.269,
			618.529,
			620.048,
			621.579,
			622.347,
			623.891,
			624.667,
			626.231,
			627.807,
			630.185,
			631.808,
			634.27,
			635.093,
			635.924,
			638.484,
			640.192,
			642.786,
			644.52,
			646.253,
			647.115,
			648.837,
			649.697,
			651.415,
			653.126,
			653.98,
			655.683,
			657.375,
			659.895,
			660.731,
			661.561,
			663.199,
			664.828,
			665.639,
			668.109,
			669.758,
			671.402,
			673.884,
			675.534,
			677.968,
			678.766,
			679.565,
			681.952,
			683.507,
			685.838,
			687.39,
			688.935,
			689.708,
			691.255,
			692.03,
			693.595,
			695.163,
			695.948,
			697.525,
			699.111,
			701.5,
			703.097,
			705.498,
			706.303,
			707.11,
			709.5,
			711.103,
			712.715,
			715.14,
			716.771,
			717.59,
			719.234,
			720.055,
			720.878,
			723.372,
			724.215,
			725.061,
			727.617,
			729.359,
			731.115,
			731.998,
			733.776,
			735.568,
			736.469,
			738.277,
			739.179,
			740.991,
			742.813,
			745.551,
			747.401,
			750.233,
			751.202,
			752.159,
			755.022,
			756.946,
			759.853,
			761.773,
			763.723,
			764.702,
			766.67,
			767.658,
			769.661,
			772.644,
			774.659,
			776.685,
			778.722,
			780.783,
			781.791,
			783.845,
			785.908,
			786.944,
			789.023,
			790.066,
			792.159,
			794.253,
			797.414,
			799.522,
			802.684,
			803.749,
			804.816,
			808.033,
			810.191,
			813.444,
			815.624,
			817.814,
			818.911,
			821.114,
			822.219,
			824.434,
			827.775,
			830.013,
			832.261,
			835.652,
			836.788,
			837.925,
			841.353,
			842.501,
			843.651,
			845.958,
			847.114,
			849.435,
			852.933,
			855.277,
			857.629,
			861.174,
			862.361,
			863.549,
			867.128,
			869.525,
			873.138,
			875.557,
			877.986,
			879.203,
			881.643,
			882.867,
			885.32,
			887.782,
			889.016,
			891.49,
			893.972,
			897.712,
			898.963,
			900.216,
			903.984,
			905.241,
			906.497,
			910.249,
			912.724,
			916.382,
			918.775,
			922.291,
			924.588,
			925.722,
			926.846,
			930.162,
			931.248,
			932.325,
			935.497,
			937.565,
			940.595,
			942.568,
			944.504,
			945.459,
			947.34,
			948.266,
			950.093,
			951.886,
			954.512,
			956.22,
			958.712,
			959.524,
			960.324,
			962.67,
			964.188,
			966.411,
			967.849,
			969.266,
			969.97,
			971.369,
			972.064,
			973.452,
			974.84,
			975.534,
			976.228,
			978.309,
			979.699,
			981.079,
			981.769,
			983.15,
			984.533,
			985.221,
			987.281,
			988.653,
			990.034,
			992.129,
			993.521,
			995.63,
			996.34,
			997.05,
			999.175,
			1000.603,
			1002.748,
			1004.178,
			1005.62,
			1006.342,
			1007.786,
			1008.507,
			1009.954,
			1012.151,
			1013.611,
			1015.085,
			1017.348,
			1018.099,
			1018.85,
			1021.147,
			1021.921,
			1022.694,
			1024.237,
			1025.007,
			1026.538,
			1028.067,
			1030.365,
			1031.904,
			1034.208,
			1034.985,
			1035.785,
			1038.177,
			1039.772,
			1042.161,
			1043.75,
			1045.327,
			1046.102,
			1047.646,
			1048.418,
			1049.962,
			1051.498,
			1052.264,
			1053.792,
			1055.322,
			1057.609,
			1058.369,
			1059.128,
			1061.388,
			1062.136,
			1062.883,
			1065.122,
			1066.604,
			1068.823,
			1070.313,
			1071.823,
			1074.091,
			1074.85,
			1075.631,
			1077.992,
			1078.781,
			1079.572,
			1082.006,
			1083.643,
			1086.128,
			1087.804,
			1089.488,
			1090.33,
			1092.001,
			1092.84,
			1094.525,
			1096.17,
			1098.62,
			1100.261,
			1102.656,
			1103.46,
			1104.267,
			1106.704,
			1108.34,
			1110.821,
			1112.491,
			1114.174,
			1115.02,
			1116.705,
			1117.551,
			1118.4,
			1120.956,
			1121.806,
			1122.658,
			1125.232,
			1126.949,
			1129.544,
			1131.306,
			1133.089,
			1133.984,
			1135.794,
			1136.712,
			1138.558,
			1140.417,
			1143.229,
			1145.12,
			1147.97,
			1148.927,
			1149.886,
			1152.785,
			1154.733,
			1157.683,
			1159.662,
			1161.647,
			1162.643,
			1164.641,
			1165.627,
			1167.656,
			1169.662,
			1170.695,
			1172.742,
			1174.781,
			1176.857,
			1180.002,
			1182.077,
			1184.193,
			1185.245,
			1188.385,
			1190.5,
			1192.618,
			1195.814,
			1197.956,
			1201.188,
			1202.27,
			1203.354,
			1206.622,
			1208.812,
			1212.115,
			1214.348,
			1216.555,
			1217.67,
			1219.907,
			1221.029,
			1223.302,
			1225.548,
			1226.685,
			1228.983,
			1231.252,
			1234.702,
			1235.857,
			1237.015,
			1240.502,
			1241.67,
			1242.84,
			1246.364,
			1248.725,
			1252.286,
			1254.672,
			1257.069,
			1260.682,
			1261.892,
			1263.105,
			1267.984,
			1269.211,
			1272.913,
			1275.396,
			1279.127,
			1281.623,
			1284.117,
			1285.367,
			1287.873,
			1289.128,
			1291.647,
			1294.179,
			1297.997,
			1300.556,
			1304.419,
			1305.712,
			1307.008,
			1310.911,
			1313.518,
			1317.437,
			1320.048,
			1323.977,
			1326.604,
			1327.92,
			1330.558,
			1333.204,
			1334.529,
			1335.856,
			1339.85,
			1342.521,
			1345.2,
			1346.543,
			1349.234,
			1351.932,
			1353.285,
			1357.352,
			1360.073,
			1362.801,
			1366.907,
			1369.652,
			1373.783,
			1375.163,
			1376.544,
			1380.685,
			1383.443,
			1387.561,
			1390.284,
			1392.98,
			1394.318,
			1396.965,
			1398.275,
			1400.867,
			1403.443,
			1404.707,
			1407.208,
			1409.676,
			1413.32,
			1414.521,
			1415.717,
			1418.09,
			1420.445,
			1421.618,
			1423.951,
			1425.116,
			1427.452,
			1429.786,
			1433.3,
			1435.647,
			1439.219,
			1440.42,
			1441.621,
			1445.286,
			1447.743,
			1451.475,
			1454.005,
			1456.542,
			1457.794,
			1460.271,
			1461.506,
			1463.926,
			1466.321,
			1467.51,
			1469.862,
			1472.192,
			1475.594,
			1476.712,
			1477.816,
			1481.062,
			1482.126,
			1483.179,
			1486.292,
			1488.333,
			1490.352,
			1493.354,
			1495.341,
			1498.311,
			1499.299,
			1500.285,
			1503.247,
			1504.24,
			1505.232,
			1508.216,
			1510.22,
			1512.223,
			1513.23,
			1515.244,
			1517.257,
			1518.264,
			1520.277,
			1521.284,
			1523.294,
			1525.3,
			1528.303,
			1530.297,
			1533.28,
			1534.268,
			1535.255,
			1538.21,
			1540.161,
			1543.088,
			1545.041,
			1546.996,
			1547.982,
			1549.986,
			1550.99,
			1551.994,
			1556.129,
			1557.172,
			1560.399,
			1562.573,
			1564.801,
			1565.92,
			1568.166,
			1570.422,
			1571.553,
			1573.786,
			1574.885,
			1577.091,
			1579.254,
			1582.48,
			1584.62,
			1587.838,
			1588.903,
			1589.97,
			1593.164,
			1595.281,
			1598.424,
			1600.498,
			1602.543,
			1603.558,
			1605.571,
			1606.568,
			1608.548,
			1610.519,
			1611.498,
			1613.453,
			1615.401,
			1617.342,
			1620.245,
			1622.174,
			1624.102,
			1625.064,
			1626.985,
			1627.945,
			1629.849,
			1631.749,
			1634.581,
			1636.461,
			1639.274,
			1640.208,
			1641.142,
			1643.953,
			1645.837,
			1648.679,
			1650.603,
			1652.532,
			1653.506,
			1655.477,
			1656.464,
			1658.457,
			1660.508,
			1661.537,
			1663.615,
			1665.746,
			1668.978,
			1670.075,
			1671.175,
			1674.419,
			1675.485,
			1676.548,
			1678.633,
			1679.621,
			1681.579,
			1684.433,
			1686.274,
			1688.083,
			1690.735,
			1691.603,
			1692.462,
			1695.015,
			1696.683,
			1699.142,
			1700.777,
			1702.369,
			1703.155,
			1704.719,
			1706.266,
			1707.035,
			1708.57,
			1709.346,
			1710.892,
			1712.433,
			1714.793,
			1716.385,
			1718.776,
			1719.581,
			1720.386,
			1722.797,
			1724.402,
			1726.803,
			1728.403,
			1730.038,
			1730.854,
			1732.485,
			1733.3,
			1734.969,
			1736.636,
			1737.468,
			1738.301,
			1740.736,
			1742.353,
			1743.964,
			1744.764,
			1746.36,
			1747.954,
			1748.754,
			1750.357,
			1751.157,
			1752.754,
			1754.333,
			1756.67,
			1758.225,
			1760.54,
			1761.309,
			1762.078,
			1764.406,
			1765.976,
			1768.326,
			1769.913,
			1772.287,
			1773.865,
			1774.645,
			1776.2,
			1777.754,
			1778.531,
			1780.086,
			1781.636,
			1783.963,
			1784.74,
			1785.517,
			1787.07,
			1788.626,
			1789.413,
			1790.985,
			1791.771,
			1793.344,
			1794.98,
			1797.459,
			1799.127,
			1801.677,
			1802.527,
			1803.379,
			1805.862,
			1807.511,
			1809.967,
			1811.59,
			1813.219,
			1814.045,
			1815.756,
			1817.472,
			1818.332,
			1820.086,
			1820.97,
			1822.741,
			1824.524,
			1827.22,
			1829.02,
			1831.701,
			1832.593,
			1833.485,
			1836.111,
			1837.849,
			1839.58,
			1842.156,
			1843.869,
			1846.418,
			1847.266,
			1848.113,
			1850.645,
			1852.327,
			1854.846,
			1856.515,
			1858.185,
			1859.021,
			1860.683,
			1862.346,
			1863.178,
			1864.833,
			1865.652,
			1867.292,
			1868.936,
			1871.383,
			1873.017,
			1875.477,
			1876.298,
			1877.121,
			1879.6,
			1881.254,
			1883.75,
			1885.427,
			1887.962,
			1889.668,
			1890.528,
			1892.258,
			1893.999,
			1894.875,
			1895.754,
			1898.411,
			1900.198,
			1901.998,
			1902.902,
			1904.721,
			1906.554,
			1907.46,
			1909.326,
			1910.255,
			1912.121,
			1914,
			1916.855,
			1918.772,
			1921.687,
			1922.65,
			1923.632,
			1926.592,
			1928.573,
			1931.553,
			1933.546,
			1936.542,
			1938.545,
			1939.547,
			1941.554,
			1943.568,
			1944.578,
			1946.605,
			1948.644,
			1950.711,
			1952.754,
			1953.787,
			1955.859,
			1957.94,
			1958.985,
			1962.12,
			1964.21,
			1966.306,
			1967.375,
			1969.468,
			1971.589,
			1974.789,
			1975.861,
			1976.936,
			1980.161,
			1982.302,
			1985.495,
			1987.581,
			1989.641,
			1990.662,
			1992.693,
			1993.704,
			1995.7,
			1997.719,
			1998.717,
			2000.708,
			2002.689,
			2005.641,
			2006.614,
			2007.582,
			2010.468,
			2011.423,
			2012.378,
			2015.241,
			2017.201,
			2020.136,
			2022.17,
			2024.201,
			2027.214,
			2028.213,
			2029.212,
			2032.218,
			2034.225,
			2037.302,
			2039.376,
			2041.472,
			2042.509,
			2044.641,
			2046.711,
			2047.739,
			2049.781,
			2050.786,
			2052.743,
			2054.691,
			2057.526,
			2058.456,
			2059.381,
			2062.1,
			2062.989,
			2063.869,
			2066.495,
			2068.236,
			2070.85,
			2072.702,
			2074.535,
			2077.572,
			2078.626,
			2079.677,
			2082.74,
			2083.746,
			2084.751,
			2087.417,
			2089.121,
			2090.81,
			2091.618,
			2093.241,
			2094.871,
			2095.676,
			2098.148,
			2099.812,
			2101.487,
			2104.022,
			2105.728,
			2108.304,
			2109.169,
			2110.038,
			2112.653,
			2114.41,
			2117.095,
			2118.894,
			2121.613,
			2123.442,
			2124.357,
			2126.193,
			2128.044,
			2128.968,
			2129.89,
			2132.75,
			2134.676,
			2136.62,
			2137.602,
			2139.563,
			2141.512,
			2142.478,
			2144.408,
			2145.373,
			2147.299,
			2149.226,
			2152.108,
			2154.022,
			2156.878,
			2157.821,
			2158.764,
			2161.603,
			2163.513,
			2166.372,
			2168.266,
			2170.158,
			2171.099,
			2172.953,
			2173.879,
			2175.731,
			2177.62,
			2178.564,
			2180.453,
			2182.348,
			2185.187,
			2186.131,
			2187.064,
			2189.861,
			2190.793,
			2191.73,
			2194.546,
			2196.421,
			2198.284,
			2201.075,
			2202.909,
			2205.654,
			2206.564,
			2207.467,
			2210.174,
			2211.076,
			2211.97,
			2214.646,
			2216.431,
			2218.208,
			2219.097,
			2220.877,
			2222.653,
			2223.541,
			2225.32,
			2226.212,
			2227.992,
			2229.778,
			2232.471,
			2234.277,
			2237.01,
			2237.93,
			2238.854,
			2241.644,
			2243.53,
			2246.383,
			2248.307,
			2250.244,
			2253.168,
			2254.147,
			2255.13,
			2258.097,
			2259.091,
			2260.088,
			2263.088,
			2265.135,
			2268.205,
			2270.254,
			2272.332,
			2273.375,
			2275.477,
			2276.518,
			2278.605,
			2280.703,
			2283.851,
			2285.974,
			2289.177,
			2290.25,
			2291.326,
			2294.567,
			2296.741,
			2300.023,
			2302.223,
			2304.434,
			2305.543,
			2307.769,
			2308.885,
			2311.126,
			2313.376,
			2314.505,
			2316.789,
			2319.06,
			2321.313,
			2323.61,
			2324.75,
			2327.034,
			2329.344,
			2330.504,
			2332.829,
			2333.996,
			2336.314,
			2338.655,
			2342.179,
			2344.56,
			2348.119,
			2349.29,
			2350.483,
			2354.078,
			2356.487,
			2360.117,
			2362.549,
			2364.99,
			2366.214,
			2368.668,
			2369.899,
			2372.367,
			2376.083,
			2378.571,
			2381.067,
			2384.826,
			2386.083,
			2387.343,
			2391.135,
			2392.404,
			2393.674,
			2396.222,
			2397.499,
			2400.061,
			2403.92,
			2406.503,
			2409.094,
			2412.996,
			2414.301,
			2415.606,
			2419.52,
			2422.136,
			2426.073,
			2428.706,
			2431.345,
			2432.667,
			2435.316,
			2437.972,
			2439.303,
			2441.969,
			2443.305,
			2445.982,
			2448.665,
			2452.703,
			2454.052,
			2455.403,
			2459.466,
			2460.823,
			2462.182,
			2466.271,
			2469.004,
			2473.118,
			2475.869,
			2478.626,
			2482.772,
			2484.158,
			2485.545,
			2489.716,
			2491.109,
			2492.504,
			2496.699,
			2499.505,
			2503.725,
			2506.547,
			2509.376,
			2510.793,
			2513.631,
			2515.052,
			2517.9,
			2520.756,
			2525.049,
			2527.919,
			2532.236,
			2533.677,
			2535.121,
			2539.46,
			2542.361,
			2546.722,
			2549.638,
			2552.56,
			2554.023,
			2556.954,
			2558.422,
			2561.362,
			2565.784,
			2567.261,
			2571.701,
			2574.669,
			2577.642,
			2579.13,
			2582.112,
			2585.1,
			2586.595,
			2589.591,
			2591.091,
			2594.094,
			2597.102,
			2601.626,
			2604.649,
			2609.194,
			2610.712,
			2612.231,
			2616.797,
			2619.849,
			2624.437,
			2627.502,
			2632.109,
			2635.188,
			2636.729,
			2639.814,
			2644.451,
			2647.549,
			2650.651,
			2655.304,
			2658.393,
			2662.985,
			2664.5,
			2666.006,
			2668.991,
			2670.469,
			2673.395,
			2676.282,
			2680.541,
			2683.331,
			2687.443,
			2688.794,
			2690.136,
			2694.104,
			2696.701,
			2700.525,
			2703.026,
			2705.49,
			2706.708,
			2709.117,
			2711.49,
			2712.664,
			2716.131,
			2718.394,
			2720.618,
			2723.883,
			2726.014,
			2729.14,
			2730.164,
			2731.178,
			2734.165,
			2736.125,
			2738.972,
			2740.824,
			2743.544,
			2745.324,
			2746.205,
			2747.08,
			2750.526,
			2751.377,
			2753.91,
			2755.583,
			2758.094,
			2759.769,
			2761.446,
			2762.285,
			2764.821,
			2766.517,
			2768.215,
			2770.823,
			2772.561,
			2775.255,
			2776.162,
			2777.07,
			2779.82,
			2781.67,
			2784.437,
			2786.268,
			2788.1,
			2789.02,
			2790.863,
			2791.784,
			2793.624,
			2795.45,
			2796.361,
			2798.181,
			2799.951,
			2801.706,
			2803.457,
			2804.323,
			2806.049,
			2807.77,
			2808.63,
			2810.343,
			2811.198,
			2812.905,
			2814.61,
			2817.16,
			2818.859,
			2821.407,
			2822.256,
			2823.105,
			2825.658,
			2827.363,
			2829.916,
			2831.637,
			2833.369,
			2834.239,
			2835.989,
			2836.869,
			2838.639,
			2840.42,
			2841.314,
			2843.109,
			2844.908,
			2847.609,
			2848.507,
			2849.404,
			2852.104,
			2852.998,
			2853.891,
			2856.572,
			2858.361,
			2860.147,
			2862.824,
			2864.61,
			2867.295,
			2868.192,
			2869.09,
			2871.858,
			2872.785,
			2873.714,
			2876.613,
			2878.572,
			2880.58,
			2881.598,
			2883.64,
			2885.713,
			2886.756,
			2888.847,
			2889.888,
			2891.96,
			2894.031,
			2897.126,
			2899.188,
			2902.224,
			2903.233,
			2904.242,
			2907.126,
			2909.035,
			2911.823,
			2913.666,
			2915.488,
			2916.384,
			2918.176,
			2919.072,
			2919.971,
			2922.698,
			2924.517,
			2927.326,
			2929.21,
			2931.099,
			2932.045,
			2933.943,
			2935.846,
			2936.8,
			2938.714,
			2939.676,
			2941.602,
			2943.538,
			2946.463,
			2948.422,
			2951.37,
			2952.356,
			2953.341,
			2956.301,
			2958.27,
			2961.267,
			2963.277,
			2966.312,
			2968.348,
			2969.37,
			2971.419,
			2973.477,
			2974.526,
			2976.597,
			2978.673,
			2980.751,
			2982.828,
			2983.852,
			2985.951,
			2988.035,
			2989.077,
			2991.142,
			2992.188,
			2994.284,
			2996.391,
			2999.575,
			3001.71,
			3004.932,
			3006.01,
			3007.091,
			3010.347,
			3012.53,
			3015.823,
			3018.029,
			3020.245,
			3021.357,
			3023.587,
			3024.707,
			3026.951,
			3029.205,
			3030.336,
			3032.605,
			3034.884,
			3038.32,
			3039.471,
			3040.624,
			3044.098,
			3045.26,
			3046.426,
			3049.937,
			3052.29,
			3054.653,
			3058.217,
			3060.606,
			3064.208,
			3065.412,
			3066.617,
			3070.231,
			3072.652,
			3076.222,
			3078.574,
			3080.898,
			3082.048,
			3084.295,
			3086.525,
			3087.627,
			3089.804,
			3090.896,
			3093.022,
			3095.118,
			3098.2,
			3100.215,
			3103.174,
			3104.144,
			3105.105,
			3107.944,
			3109.797,
			3111.629,
			3114.353,
			3116.157,
			3118.862,
			3119.762,
			3120.662,
			3123.373,
			3124.287,
			3125.2,
			3127.945,
			3129.801,
			3132.582,
			3134.424,
			3136.265,
			3137.186,
			3139.042,
			3139.972,
			3141.833,
			3143.736,
			3146.633,
			3148.589,
			3151.56,
			3152.552,
			3153.544,
			3156.528,
			3158.518,
			3161.499,
			3163.481,
			3166.427,
			3168.388,
			3169.352,
			3171.288,
			3174.196,
			3176.131,
			3178.078,
			3180.031,
			3181.997,
			3182.983,
			3184.967,
			3186.963,
			3187.965,
			3189.98,
			3190.992,
			3193.023,
			3195.066,
			3198.152,
			3200.223,
			3203.337,
			3204.375,
			3205.415,
			3208.53,
			3210.626,
			3213.789,
			3215.904,
			3218.029,
			3219.113,
			3221.254,
			3222.31,
			3224.469,
			3226.646,
			3227.738,
			3229.938,
			3232.154,
			3234.385,
			3237.769,
			3241.181,
			3242.323,
			3243.468,
			3246.911,
			3249.216,
			3251.526,
			3255.003,
			3257.325,
			3260.808,
			3261.966,
			3263.12,
			3266.552,
			3268.807,
			3272.124,
			3274.289,
			3277.465,
			3279.535,
			3280.555,
			3282.566,
			3284.541,
			3285.515,
			3287.435,
			3289.319,
			3292.082,
			3293.882,
			3296.521,
			3297.384,
			3298.238,
			3300.737,
			3302.351,
			3304.739,
			3306.285,
			3307.81,
			3308.568,
			3310.077,
			3310.828,
			3311.576,
			3313.808,
			3314.549,
			3315.29,
			3317.452,
			3318.885,
			3320.316,
			3321.026,
			3322.439,
			3323.849,
			3324.553,
			3326.704,
			3328.155,
			3329.606,
			3331.808,
			3333.287,
			3335.5,
			3336.252,
			3337.008,
			3339.267,
			3340.769,
			3343.087,
			3344.625,
			3346.164,
			3346.946,
			3348.506,
			3349.285,
			3350.841,
			3352.452,
			3353.261,
			3354.07,
			3356.501,
			3358.155,
			3359.804,
			3360.627,
			3362.267,
			3363.898,
			3364.714,
			3366.344,
			3367.184,
			3368.873,
			3370.563,
			3373.123,
			3374.842,
			3377.385,
			3378.204,
			3379.023,
			3381.485,
			3383.069,
			3385.432,
			3387.012,
			3388.567,
			3389.345,
			3390.906,
			3391.689,
			3393.221,
			3395.516,
			3397.056,
			3398.563,
			3400.83,
			3401.591,
			3402.355,
			3403.931,
			3405.518,
			3406.316,
			3407.933,
			3408.764,
			3410.434,
			3412.116,
			3414.657,
			3416.365,
			3418.953,
			3419.822,
			3420.694,
			3423.335,
			3424.227,
			3425.122,
			3427.826,
			3429.644,
			3431.474,
			3432.394,
			3434.233,
			3435.155,
			3437,
			3439.782,
			3441.662,
			3443.55,
			3446.421,
			3447.385,
			3448.351,
			3451.272,
			3452.252,
			3453.234,
			3456.215,
			3458.206,
			3460.205,
			3463.212,
			3466.233,
			3468.242,
			3469.26,
			3470.281,
			3473.36,
			3474.393,
			3475.429,
			3478.554,
			3480.646,
			3482.737,
			3483.784,
			3485.886,
			3487.999,
			3489.06,
			3491.188,
			3492.257,
			3494.402,
			3496.556,
			3499.806,
			3501.986,
			3505.273,
			3506.375,
			3507.478,
			3510.804,
			3513.034,
			3516.398,
			3518.654,
			3520.92,
			3522.057,
			3524.339,
			3525.484,
			3526.63,
			3531.224,
			3532.375,
			3535.842,
			3538.167,
			3540.501,
			3541.672,
			3544.022,
			3546.381,
			3547.565,
			3549.94,
			3551.132,
			3553.522,
			3555.922,
			3559.54,
			3561.964,
			3565.617,
			3566.839,
			3568.063,
			3571.751,
			3574.221,
			3577.943,
			3580.436,
			3582.939,
			3584.193,
			3586.709,
			3587.971,
			3590.5,
			3593.038,
			3594.31,
			3596.86,
			3599.419,
			3603.274,
			3605.855,
			3609.739,
			3611.039,
			3612.34,
			3614.948,
			3616.251,
			3618.86,
			3621.476,
			3625.413,
			3628.046,
			3632.011,
			3633.336,
			3634.663,
			3638.655,
			3641.325,
			3645.344,
			3648.032,
			3650.726,
			3652.076,
			3654.781,
			3657.494,
			3658.853,
			3661.576,
			3662.94,
			3663.794
		],
		[
			0,
			0.009,
			0.07,
			0.116,
			0.195,
			0.225,
			0.257,
			0.319,
			0.382,
			0.442,
			0.505,
			0.551,
			0.567,
			0.628,
			0.659,
			0.689,
			0.752,
			0.768,
			0.815,
			0.881,
			0.939,
			0.984,
			0.999,
			1.064,
			1.094,
			1.126,
			1.186,
			1.201,
			1.25,
			1.309,
			1.372,
			1.435,
			1.495,
			1.528,
			1.56,
			1.622,
			1.682,
			1.746,
			1.807,
			1.868,
			1.931,
			1.961,
			1.992,
			2.054,
			2.072,
			2.118,
			2.177,
			2.242,
			2.302,
			2.365,
			2.397,
			2.427,
			2.49,
			2.552,
			2.613,
			2.675,
			2.723,
			2.799,
			2.831,
			2.86,
			2.945,
			2.985,
			3.048,
			3.109,
			3.156,
			3.172,
			3.232,
			3.264,
			3.295,
			3.373,
			3.418,
			3.482,
			3.544,
			3.605,
			3.667,
			3.699,
			3.73,
			3.792,
			3.854,
			3.92,
			3.977,
			4.024,
			4.04,
			4.102,
			4.133,
			4.165,
			4.225,
			4.242,
			4.288,
			4.35,
			4.411,
			4.458,
			4.473,
			4.534,
			4.567,
			4.599,
			4.676,
			4.721,
			4.783,
			4.845,
			4.892,
			4.971,
			5.007,
			5.033,
			5.095,
			5.156,
			5.217,
			5.279,
			5.325,
			5.342,
			5.404,
			5.434,
			5.466,
			5.527,
			5.543,
			5.591,
			5.652,
			5.714,
			5.777,
			5.838,
			5.867,
			5.901,
			5.96,
			5.976,
			6.023,
			6.087,
			6.145,
			6.194,
			6.271,
			6.303,
			6.333,
			6.395,
			6.456,
			6.52,
			6.582,
			6.643,
			6.705,
			6.737,
			6.769,
			6.83,
			6.847,
			6.892,
			6.954,
			7.016,
			7.065,
			7.138,
			7.171,
			7.201,
			7.264,
			7.279,
			7.327,
			7.388,
			7.451,
			7.513,
			7.574,
			7.605,
			7.636,
			7.698,
			7.761,
			7.821,
			7.885,
			7.932,
			7.947,
			8.008,
			8.039,
			8.069,
			8.148,
			8.194,
			8.256,
			8.317,
			8.365,
			8.443,
			8.474,
			8.503,
			8.566,
			8.63,
			8.692,
			8.751,
			8.815,
			8.877,
			8.907,
			8.938,
			8.999,
			9.017,
			9.062,
			9.128,
			9.185,
			9.249,
			9.31,
			9.341,
			9.372,
			9.435,
			9.449,
			9.498,
			9.559,
			9.619,
			9.682,
			9.744,
			9.775,
			9.806,
			9.869,
			9.931,
			9.993,
			10.056,
			10.101,
			10.115,
			10.178,
			10.211,
			10.241,
			10.302,
			10.319,
			10.365,
			10.43,
			10.49,
			10.551,
			10.611,
			10.644,
			10.674,
			10.752,
			10.799,
			10.859,
			10.922,
			10.985,
			11.048,
			11.076,
			11.109,
			11.189,
			11.232,
			11.293,
			11.356,
			11.403,
			11.417,
			11.48,
			11.51,
			11.541,
			11.62,
			11.666,
			11.728,
			11.791,
			11.838,
			11.853,
			11.915,
			11.945,
			11.977,
			12.039,
			12.053,
			12.101,
			12.163,
			12.225,
			12.271,
			12.349,
			12.38,
			12.412,
			12.472,
			12.535,
			12.598,
			12.658,
			12.706,
			12.72,
			12.783,
			12.814,
			12.845,
			12.906,
			12.923,
			12.968,
			13.032,
			13.094,
			13.156,
			13.218,
			13.251,
			13.278,
			13.356,
			13.402,
			13.465,
			13.527,
			13.572,
			13.59,
			13.65,
			13.682,
			13.713,
			13.792,
			13.838,
			13.901,
			13.962,
			14.007,
			14.022,
			14.085,
			14.115,
			14.147,
			14.208,
			14.225,
			14.271,
			14.334,
			14.395,
			14.442,
			14.52,
			14.55,
			14.581,
			14.642,
			14.705,
			14.766,
			14.829,
			14.892,
			14.954,
			14.985,
			15.015,
			15.094,
			15.14,
			15.2,
			15.264,
			15.326,
			15.388,
			15.417,
			15.45,
			15.51,
			15.527,
			15.572,
			15.637,
			15.697,
			15.743,
			15.821,
			15.852,
			15.883,
			15.944,
			16.009,
			16.069,
			16.133,
			16.179,
			16.193,
			16.255,
			16.286,
			16.316,
			16.38,
			16.393,
			16.441,
			16.507,
			16.566,
			16.613,
			16.627,
			16.689,
			16.721,
			16.751,
			16.83,
			16.876,
			16.938,
			17,
			17.045,
			17.122,
			17.153,
			17.186,
			17.248,
			17.311,
			17.375,
			17.434,
			17.478,
			17.496,
			17.557,
			17.589,
			17.621,
			17.683,
			17.698,
			17.745,
			17.806,
			17.866,
			17.928,
			17.992,
			18.022,
			18.053,
			18.116,
			18.176,
			18.238,
			18.302,
			18.349,
			18.365,
			18.427,
			18.458,
			18.487,
			18.55,
			18.566,
			18.61,
			18.674,
			18.736,
			18.784,
			18.797,
			18.859,
			18.89,
			18.922,
			18.985,
			18.999,
			19.045,
			19.108,
			19.171,
			19.215,
			19.293,
			19.326,
			19.357,
			19.418,
			19.478,
			19.544,
			19.603,
			19.65,
			19.667,
			19.729,
			19.76,
			19.79,
			19.867,
			19.915,
			19.976,
			20.037,
			20.084,
			20.1,
			20.163,
			20.192,
			20.225,
			20.286,
			20.3,
			20.348,
			20.412,
			20.472,
			20.519,
			20.597,
			20.626,
			20.658,
			20.72,
			20.783,
			20.845,
			20.907,
			20.953,
			20.969,
			21.03,
			21.06,
			21.091,
			21.17,
			21.217,
			21.281,
			21.34,
			21.386,
			21.403,
			21.466,
			21.501,
			21.527,
			21.589,
			21.603,
			21.652,
			21.717,
			21.774,
			21.822,
			21.899,
			21.93,
			21.961,
			22.023,
			22.084,
			22.147,
			22.208,
			22.255,
			22.269,
			22.334,
			22.363,
			22.395,
			22.456,
			22.471,
			22.518,
			22.582,
			22.642,
			22.688,
			22.706,
			22.768,
			22.799,
			22.827,
			22.905,
			22.952,
			23.016,
			23.076,
			23.14,
			23.199,
			23.231,
			23.262,
			23.325,
			23.341,
			23.386,
			23.45,
			23.511,
			23.572,
			23.633,
			23.666,
			23.697,
			23.759,
			23.773,
			23.82,
			23.883,
			23.944,
			24.007,
			24.068,
			24.099,
			24.132,
			24.191,
			24.255,
			24.317,
			24.38,
			24.424,
			24.442,
			24.504,
			24.533,
			24.565,
			24.627,
			24.644,
			24.688,
			24.752,
			24.812,
			24.859,
			24.875,
			24.936,
			24.969,
			24.999,
			25.076,
			25.122,
			25.185,
			25.248,
			25.292,
			25.372,
			25.402,
			25.432,
			25.511,
			25.557,
			25.624,
			25.681,
			25.726,
			25.743,
			25.806,
			25.835,
			25.866,
			25.945,
			25.991,
			26.054,
			26.116,
			26.163,
			26.178,
			26.24,
			26.269,
			26.3,
			26.363,
			26.378,
			26.426,
			26.488,
			26.55,
			26.594,
			26.674,
			26.705,
			26.736,
			26.798,
			26.86,
			26.921,
			26.983,
			27.028,
			27.044,
			27.107,
			27.137,
			27.169,
			27.233,
			27.247,
			27.294,
			27.354,
			27.418,
			27.462,
			27.48,
			27.541,
			27.573,
			27.605,
			27.666,
			27.729,
			27.791,
			27.853,
			27.898,
			27.975,
			28.006,
			28.038,
			28.098,
			28.115,
			28.162,
			28.224,
			28.287,
			28.348,
			28.409,
			28.441,
			28.472,
			28.533,
			28.55,
			28.596,
			28.658,
			28.72,
			28.765,
			28.843,
			28.875,
			28.906,
			28.967,
			29.031,
			29.093,
			29.153,
			29.2,
			29.215,
			29.277,
			29.309,
			29.338,
			29.402,
			29.416,
			29.464,
			29.525,
			29.588,
			29.649,
			29.713,
			29.749,
			29.774,
			29.835,
			29.85,
			29.898,
			29.96,
			30.022,
			30.069,
			30.144,
			30.178,
			30.209,
			30.287,
			30.332,
			30.395,
			30.455,
			30.504,
			30.518,
			30.578,
			30.612,
			30.642,
			30.704,
			30.721,
			30.766,
			30.827,
			30.889,
			30.953,
			31.012,
			31.044,
			31.076,
			31.154,
			31.198,
			31.261,
			31.323,
			31.387,
			31.448,
			31.48,
			31.509,
			31.573,
			31.635,
			31.697,
			31.758,
			31.81,
			31.821,
			31.883,
			31.911,
			31.944,
			32.005,
			32.022,
			32.067,
			32.132,
			32.192,
			32.239,
			32.253,
			32.315,
			32.348,
			32.378,
			32.44,
			32.501,
			32.566,
			32.627,
			32.674,
			32.752,
			32.783,
			32.81,
			32.891,
			32.935,
			33,
			33.061,
			33.124,
			33.184,
			33.216,
			33.248,
			33.308,
			33.325,
			33.369,
			33.433,
			33.494,
			33.542,
			33.619,
			33.65,
			33.682,
			33.742,
			33.803,
			33.868,
			33.927,
			33.991,
			34.051,
			34.082,
			34.115,
			34.176,
			34.191,
			34.237,
			34.3,
			34.363,
			34.41,
			34.426,
			34.488,
			34.519,
			34.55,
			34.626,
			34.672,
			34.736,
			34.798,
			34.843,
			34.92,
			34.952,
			34.983,
			35.043,
			35.108,
			35.168,
			35.232,
			35.276,
			35.291,
			35.356,
			35.386,
			35.418,
			35.48,
			35.495,
			35.54,
			35.603,
			35.666,
			35.71,
			35.728,
			35.789,
			35.82,
			35.852,
			35.912,
			35.932,
			35.974,
			36.038,
			36.099,
			36.16,
			36.224,
			36.253,
			36.284,
			36.363,
			36.408,
			36.471,
			36.533,
			36.579,
			36.593,
			36.656,
			36.689,
			36.72,
			36.782,
			36.795,
			36.843,
			36.904,
			36.968,
			37.013,
			37.03,
			37.09,
			37.121,
			37.154,
			37.231,
			37.278,
			37.339,
			37.4,
			37.462,
			37.526,
			37.557,
			37.586,
			37.649,
			37.665,
			37.711,
			37.774,
			37.835,
			37.883,
			37.899,
			37.96,
			37.993,
			38.02,
			38.084,
			38.098,
			38.147,
			38.206,
			38.27,
			38.315,
			38.393,
			38.424,
			38.457,
			38.533,
			38.58,
			38.641,
			38.703,
			38.749,
			38.765,
			38.828,
			38.859,
			38.888,
			38.967,
			39.012,
			39.078,
			39.137,
			39.185,
			39.201,
			39.26,
			39.294,
			39.325,
			39.386,
			39.4,
			39.448,
			39.51,
			39.573,
			39.635,
			39.696,
			39.726,
			39.757,
			39.836,
			39.882,
			39.944,
			40.004,
			40.058,
			40.067,
			40.13,
			40.16,
			40.191,
			40.255,
			40.269,
			40.317,
			40.378,
			40.439,
			40.503,
			40.564,
			40.595,
			40.626,
			40.688,
			40.704,
			40.749,
			40.81,
			40.873,
			40.92,
			40.999,
			41.027,
			41.059,
			41.122,
			41.182,
			41.247,
			41.307,
			41.354,
			41.37,
			41.433,
			41.462,
			41.494,
			41.555,
			41.571,
			41.618,
			41.678,
			41.742,
			41.788,
			41.804,
			41.866,
			41.897,
			41.927,
			41.989,
			42.007,
			42.053,
			42.12,
			42.176,
			42.223,
			42.301,
			42.332,
			42.362,
			42.425,
			42.485,
			42.55,
			42.611,
			42.656,
			42.672,
			42.735,
			42.765,
			42.797,
			42.859,
			42.873,
			42.92,
			42.982,
			43.043,
			43.091,
			43.168,
			43.198,
			43.229,
			43.292,
			43.354,
			43.417,
			43.477,
			43.524,
			43.541,
			43.603,
			43.632,
			43.665,
			43.727,
			43.741,
			43.787,
			43.849,
			43.91,
			43.959,
			43.973,
			44.037,
			44.068,
			44.099,
			44.161,
			44.182,
			44.223,
			44.284,
			44.346,
			44.409,
			44.469,
			44.503,
			44.532,
			44.61,
			44.658,
			44.718,
			44.781,
			44.844,
			44.903,
			44.935,
			44.965,
			45.029,
			45.045,
			45.09,
			45.157,
			45.216,
			45.262,
			45.276,
			45.338,
			45.368,
			45.399,
			45.462,
			45.476,
			45.525,
			45.587,
			45.648,
			45.71,
			45.774,
			45.802,
			45.835,
			45.895,
			45.959,
			46.019,
			46.083,
			46.131,
			46.144,
			46.206,
			46.243,
			46.268,
			46.332,
			46.345,
			46.392,
			46.455,
			46.517,
			46.58,
			46.64,
			46.67,
			46.702,
			46.781,
			46.826,
			46.89,
			46.951,
			46.995,
			47.074,
			47.105,
			47.136,
			47.199,
			47.26,
			47.322,
			47.385,
			47.431,
			47.448,
			47.51,
			47.54,
			47.57,
			47.632,
			47.648,
			47.696,
			47.756,
			47.818,
			47.866,
			47.943,
			47.974,
			48.004,
			48.067,
			48.128,
			48.19,
			48.254,
			48.316,
			48.378,
			48.409,
			48.44,
			48.501,
			48.518,
			48.564,
			48.624,
			48.688,
			48.733,
			48.75,
			48.809,
			48.843,
			48.873,
			48.934,
			48.95,
			48.997,
			49.06,
			49.12,
			49.168,
			49.245,
			49.277,
			49.307,
			49.384,
			49.432,
			49.494,
			49.556,
			49.619,
			49.681,
			49.711,
			49.741,
			49.804,
			49.818,
			49.865,
			49.926,
			49.99,
			50.037,
			50.05,
			50.114,
			50.145,
			50.175,
			50.254,
			50.301,
			50.36,
			50.365,
			50.424,
			50.47,
			50.548,
			50.579,
			50.609,
			50.673,
			50.733,
			50.797,
			50.858,
			50.904,
			50.92,
			50.983,
			51.012,
			51.044,
			51.106,
			51.12,
			51.168,
			51.231,
			51.291,
			51.339,
			51.354,
			51.417,
			51.447,
			51.478,
			51.54,
			51.602,
			51.666,
			51.724,
			51.772,
			51.849,
			51.881,
			51.911,
			51.974,
			52.034,
			52.098,
			52.16,
			52.205,
			52.222,
			52.282,
			52.316,
			52.347,
			52.406,
			52.428,
			52.469,
			52.533,
			52.594,
			52.64,
			52.657,
			52.719,
			52.749,
			52.781,
			52.842,
			52.903,
			52.967,
			53.028,
			53.074,
			53.153,
			53.184,
			53.214,
			53.275,
			53.292,
			53.339,
			53.4,
			53.462,
			53.51,
			53.523,
			53.585,
			53.618,
			53.648,
			53.726,
			53.771,
			53.835,
			53.895,
			53.959,
			54.021,
			54.05,
			54.082,
			54.144,
			54.205,
			54.269,
			54.331,
			54.391,
			54.454,
			54.488,
			54.517,
			54.578,
			54.595,
			54.639,
			54.704,
			54.764,
			54.81,
			54.825,
			54.888,
			54.92,
			54.949,
			55.01,
			55.027,
			55.074,
			55.137,
			55.198,
			55.26,
			55.322,
			55.354,
			55.383,
			55.445,
			55.509,
			55.571,
			55.634,
			55.676,
			55.694,
			55.757,
			55.788,
			55.817,
			55.878,
			55.896,
			55.944,
			56.005,
			56.067,
			56.112,
			56.127,
			56.19,
			56.221,
			56.253,
			56.33,
			56.376,
			56.439,
			56.501,
			56.562,
			56.625,
			56.656,
			56.686,
			56.75,
			56.765,
			56.809,
			56.873,
			56.935,
			56.982,
			56.997,
			57.058,
			57.09,
			57.12,
			57.182,
			57.197,
			57.243,
			57.307,
			57.37,
			57.415,
			57.492,
			57.523,
			57.556,
			57.617,
			57.68,
			57.741,
			57.802,
			57.864,
			57.927,
			57.957,
			57.989,
			58.052,
			58.066,
			58.113,
			58.174,
			58.238,
			58.298,
			58.36,
			58.392,
			58.424,
			58.485,
			58.5,
			58.546,
			58.612,
			58.671,
			58.717,
			58.794,
			58.826,
			58.857,
			58.935,
			58.981,
			59.042,
			59.106,
			59.151,
			59.167,
			59.228,
			59.261,
			59.291,
			59.353,
			59.369,
			59.415,
			59.479,
			59.539,
			59.585,
			59.6,
			59.663,
			59.694,
			59.725,
			59.787,
			59.803,
			59.848,
			59.909,
			59.973,
			60.019,
			60.097,
			60.128,
			60.158,
			60.221,
			60.282,
			60.349,
			60.406,
			60.454,
			60.47,
			60.532,
			60.563,
			60.594,
			60.673,
			60.718,
			60.778,
			60.841,
			60.887,
			60.903,
			60.965,
			60.995,
			61.027,
			61.088,
			61.104,
			61.15,
			61.215,
			61.275,
			61.338,
			61.4,
			61.431,
			61.46,
			61.522,
			61.586,
			61.649,
			61.711,
			61.755,
			61.771,
			61.833,
			61.866,
			61.895,
			61.958,
			61.974,
			62.02,
			62.082,
			62.143,
			62.189,
			62.206,
			62.266,
			62.299,
			62.328,
			62.408,
			62.453,
			62.516,
			62.578,
			62.625,
			62.701,
			62.737,
			62.763,
			62.827,
			62.841,
			62.887,
			62.949,
			63.01,
			63.073,
			63.136,
			63.167,
			63.199,
			63.259,
			63.275,
			63.32,
			63.382,
			63.447,
			63.492,
			63.569,
			63.601,
			63.632,
			63.709,
			63.756,
			63.82,
			63.881,
			63.927,
			63.941,
			64.003,
			64.033,
			64.066,
			64.142,
			64.189,
			64.253,
			64.314,
			64.361,
			64.375,
			64.438,
			64.469,
			64.499,
			64.563,
			64.577,
			64.624,
			64.687,
			64.748,
			64.799,
			64.873,
			64.903,
			64.934,
			64.997,
			65.057,
			65.12,
			65.182,
			65.245,
			65.307,
			65.338,
			65.369,
			65.445,
			65.493,
			65.554,
			65.615,
			65.677,
			65.741,
			65.771,
			65.803,
			65.865,
			65.878,
			65.926,
			65.989,
			66.051,
			66.098,
			66.174,
			66.204,
			66.235,
			66.298,
			66.361,
			66.423,
			66.483,
			66.532,
			66.547,
			66.609,
			66.64,
			66.67,
			66.748,
			66.793,
			66.859,
			66.918,
			66.966,
			67.042,
			67.074,
			67.106,
			67.168,
			67.23,
			67.292,
			67.353,
			67.415,
			67.478,
			67.508,
			67.538,
			67.617,
			67.664,
			67.724,
			67.787,
			67.849,
			67.91,
			67.942,
			67.972,
			68.049,
			68.098,
			68.16,
			68.221,
			68.267,
			68.345,
			68.376,
			68.405,
			68.468,
			68.53,
			68.594,
			68.655,
			68.701,
			68.717,
			68.777,
			68.81,
			68.842,
			68.904,
			68.923,
			68.965,
			69.027,
			69.088,
			69.136,
			69.149,
			69.211,
			69.245,
			69.273,
			69.335,
			69.353,
			69.398,
			69.459,
			69.522,
			69.569,
			69.648,
			69.676,
			69.709,
			69.77,
			69.832,
			69.895,
			69.957,
			70.004,
			70.018,
			70.082,
			70.11,
			70.144,
			70.205,
			70.221,
			70.267,
			70.33,
			70.391,
			70.437,
			70.453,
			70.515,
			70.544,
			70.578,
			70.655,
			70.702,
			70.762,
			70.825,
			70.872,
			70.948,
			70.983,
			71.012,
			71.073,
			71.09,
			71.135,
			71.201,
			71.26,
			71.305,
			71.322,
			71.383,
			71.415,
			71.445,
			71.507,
			71.522,
			71.57,
			71.632,
			71.694,
			71.74,
			71.817,
			71.848,
			71.878,
			71.942,
			72.002,
			72.066,
			72.128,
			72.173,
			72.19,
			72.252,
			72.283,
			72.313,
			72.389,
			72.438,
			72.498,
			72.56,
			72.609,
			72.625,
			72.687,
			72.717,
			72.745,
			72.809,
			72.826,
			72.871,
			72.934,
			72.995,
			73.057,
			73.119,
			73.152,
			73.182,
			73.242,
			73.306,
			73.367,
			73.428,
			73.493,
			73.553,
			73.585,
			73.616,
			73.677,
			73.694,
			73.74,
			73.802,
			73.863,
			73.909,
			73.926,
			73.989,
			74.019,
			74.05,
			74.11,
			74.127,
			74.174,
			74.237,
			74.298,
			74.343,
			74.421,
			74.453,
			74.483,
			74.544,
			74.609,
			74.671,
			74.732,
			74.777,
			74.794,
			74.856,
			74.887,
			74.916,
			74.981,
			74.994,
			75.041,
			75.104,
			75.167,
			75.212,
			75.226,
			75.29,
			75.322,
			75.352,
			75.43,
			75.477,
			75.538,
			75.599,
			75.662,
			75.724,
			75.755,
			75.786,
			75.849,
			75.91,
			75.973,
			76.033,
			76.081,
			76.097,
			76.159,
			76.19,
			76.22,
			76.283,
			76.297,
			76.344,
			76.407,
			76.467,
			76.516,
			76.593,
			76.623,
			76.655,
			76.715,
			76.776,
			76.839,
			76.903,
			76.95,
			77.025,
			77.057,
			77.088,
			77.149,
			77.172,
			77.213,
			77.275,
			77.335,
			77.399,
			77.459,
			77.491,
			77.522,
			77.584,
			77.601,
			77.647,
			77.709,
			77.77,
			77.817,
			77.893,
			77.926,
			77.956,
			78.017,
			78.081,
			78.144,
			78.205,
			78.266,
			78.328,
			78.36,
			78.39,
			78.467,
			78.515,
			78.578,
			78.639,
			78.684,
			78.7,
			78.763,
			78.794,
			78.824,
			78.887,
			78.903,
			78.949,
			79.012,
			79.074,
			79.135,
			79.196,
			79.234,
			79.259,
			79.335,
			79.382,
			79.444,
			79.506,
			79.553,
			79.567,
			79.632,
			79.661,
			79.692,
			79.754,
			79.77,
			79.817,
			79.877,
			79.939,
			80.002,
			80.065,
			80.095,
			80.126,
			80.205,
			80.251,
			80.313,
			80.373,
			80.421,
			80.499,
			80.53,
			80.56,
			80.639,
			80.685,
			80.748,
			80.809,
			80.87,
			80.932,
			80.963,
			80.994,
			81.056,
			81.073,
			81.119,
			81.186,
			81.242,
			81.295,
			81.368,
			81.399,
			81.43,
			81.49,
			81.553,
			81.616,
			81.678,
			81.724,
			81.74,
			81.802,
			81.832,
			81.864,
			81.926,
			81.94,
			81.988,
			82.049,
			82.112,
			82.157,
			82.174,
			82.236,
			82.266,
			82.298,
			82.375,
			82.42,
			82.482,
			82.545,
			82.608,
			82.67,
			82.699,
			82.731,
			82.794,
			82.856,
			82.918,
			82.98,
			83.024,
			83.042,
			83.104,
			83.133,
			83.166,
			83.227,
			83.241,
			83.288,
			83.357,
			83.412,
			83.46,
			83.474,
			83.536,
			83.569,
			83.599,
			83.66,
			83.676,
			83.723,
			83.784,
			83.848,
			83.895,
			83.97,
			84.002,
			84.032,
			84.111,
			84.156,
			84.218,
			84.283,
			84.327,
			84.344,
			84.406,
			84.436,
			84.468,
			84.545,
			84.593,
			84.655,
			84.715,
			84.763,
			84.776,
			84.839,
			84.869,
			84.902,
			84.962,
			84.98,
			85.024,
			85.089,
			85.15,
			85.195,
			85.274,
			85.305,
			85.335,
			85.398,
			85.417,
			85.461,
			85.522,
			85.583,
			85.628,
			85.644,
			85.709,
			85.739,
			85.771,
			85.847,
			85.895,
			85.955,
			86.018,
			86.063,
			86.081,
			86.142,
			86.174,
			86.202,
			86.265,
			86.328,
			86.389,
			86.452,
			86.515,
			86.576,
			86.606,
			86.638,
			86.7,
			86.716,
			86.761,
			86.824,
			86.885,
			86.931,
			86.949,
			87.009,
			87.041,
			87.073,
			87.135,
			87.149,
			87.196,
			87.259,
			87.321,
			87.366,
			87.445,
			87.477,
			87.507,
			87.582,
			87.629,
			87.693,
			87.755,
			87.799,
			87.817,
			87.879,
			87.909,
			87.939,
			88.019,
			88.064,
			88.126,
			88.188,
			88.235,
			88.25,
			88.31,
			88.344,
			88.375,
			88.435,
			88.452,
			88.498,
			88.561,
			88.623,
			88.668,
			88.747,
			88.777,
			88.808,
			88.869,
			88.932,
			88.994,
			89.056,
			89.103,
			89.119,
			89.181,
			89.21,
			89.241,
			89.305,
			89.32,
			89.367,
			89.429,
			89.491,
			89.542,
			89.615,
			89.645,
			89.677,
			89.739,
			89.753,
			89.8,
			89.862,
			89.923,
			89.97,
			90.049,
			90.078,
			90.111,
			90.171,
			90.235,
			90.297,
			90.359,
			90.405,
			90.419,
			90.482,
			90.515,
			90.545,
			90.606,
			90.621,
			90.644
		]
	],
	'2383_7753_7982': [
		[
			64.423
		],
		[
			0
		],
		[
			0,
			0.783,
			2.169,
			6.329,
			7.718,
			9.106,
			13.284,
			16.079,
			18.882,
			20.285,
			23.098,
			25.917,
			27.329,
			30.157,
			31.573,
			34.41,
			37.253,
			41.531,
			44.39,
			48.691,
			50.127,
			51.566,
			55.89,
			58.781,
			63.129,
			66.036,
			70.407,
			73.329,
			74.793,
			77.724,
			80.661,
			82.132,
			83.605,
			88.03,
			92.47,
			93.953,
			95.437,
			98.411,
			101.39,
			102.882,
			105.869,
			107.365,
			110.362,
			113.365,
			114.869,
			117.88,
			120.897,
			125.433,
			126.948,
			128.465,
			133.022,
			136.065,
			140.641,
			143.698,
			146.761,
			148.295,
			151.366,
			152.903,
			155.982,
			159.066,
			160.609,
			163.701,
			166.798,
			171.454,
			173.008,
			174.564,
			179.238,
			180.798,
			182.36,
			187.054,
			190.189,
			193.33,
			198.05,
			201.203,
			205.942,
			207.524,
			209.108,
			213.865,
			217.042,
			221.817,
			225.001,
			228.189,
			229.784,
			232.979,
			236.178,
			237.779,
			240.985,
			242.589,
			245.802,
			249.019,
			253.853,
			255.467,
			257.082,
			261.934,
			263.553,
			265.173,
			270.04,
			273.282,
			278.146,
			281.397,
			284.659,
			286.295,
			289.575,
			291.217,
			292.86,
			299.438,
			301.083,
			306.024,
			309.323,
			312.615,
			314.255,
			317.516,
			320.745,
			322.347,
			325.529,
			327.107,
			330.234,
			333.322,
			337.887,
			340.886,
			345.317,
			346.775,
			348.224,
			352.513,
			355.326,
			359.474,
			362.191,
			364.87,
			366.196,
			368.819,
			371.404,
			375.212,
			376.463,
			377.704,
			381.372,
			383.772,
			387.308,
			389.622,
			391.898,
			393.022,
			395.243,
			396.339,
			398.502,
			400.627,
			403.746,
			405.779,
			408.759,
			409.734,
			410.7,
			413.543,
			415.393,
			418.104,
			419.869,
			421.599,
			422.45,
			424.123,
			424.946,
			426.564,
			428.921,
			430.447,
			431.939,
			434.113,
			434.82,
			435.518,
			437.566,
			438.231,
			438.889,
			440.18,
			440.813,
			442.057,
			443.275,
			445.067,
			446.256,
			448.017,
			448.602,
			449.186,
			450.937,
			452.094,
			453.817,
			454.965,
			456.114,
			456.673,
			457.781,
			458.334,
			459.437,
			460.539,
			461.091,
			462.166,
			463.232,
			464.826,
			465.888,
			467.721,
			468.349,
			468.976,
			470.859,
			472.487,
			475.124,
			476.961,
			478.915,
			479.89,
			481.593,
			482.192,
			483.385,
			484.583,
			485.183,
			485.785,
			487.477,
			488.581,
			489.686,
			490.241,
			491.365,
			492.558,
			493.155,
			494.954,
			496.163,
			497.421,
			499.322,
			500.593,
			502.532,
			503.183,
			503.832,
			505.752,
			507.055,
			508.962,
			510.189,
			511.387,
			511.999,
			513.196,
			513.783,
			514.935,
			516.635,
			517.213,
			518.914,
			520.029,
			521.135,
			521.69,
			522.797,
			523.899,
			524.449,
			525.547,
			526.097,
			527.162,
			528.215,
			529.79,
			530.842,
			532.372,
			532.872,
			533.372,
			534.873,
			535.88,
			537.364,
			538.34,
			539.319,
			539.811,
			540.8,
			541.299,
			542.31,
			543.325,
			543.834,
			544.858,
			545.893,
			547.475,
			548.539,
			549.611,
			550.692,
			551.238,
			552.904,
			554.023,
			555.151,
			556.868,
			558.044,
			559.818,
			560.413,
			561.009,
			563.393,
			563.991,
			565.794,
			567.006,
			568.223,
			568.833,
			570.059,
			570.676,
			571.926,
			573.201,
			573.841,
			575.128,
			576.428,
			578.423,
			579.093,
			579.765,
			581.799,
			582.487,
			583.177,
			585.253,
			586.646,
			588.061,
			590.186,
			591.609,
			593.766,
			594.485,
			595.204,
			597.374,
			598.103,
			598.832,
			601.015,
			602.477,
			603.94,
			604.671,
			606.13,
			607.601,
			608.339,
			609.81,
			610.545,
			612.017,
			613.506,
			615.729,
			617.208,
			619.429,
			620.167,
			620.903,
			623.119,
			624.596,
			626.809,
			628.271,
			630.476,
			631.962,
			632.7,
			634.181,
			635.671,
			636.42,
			637.173,
			639.456,
			640.989,
			643.318,
			644.884,
			646.459,
			647.252,
			648.844,
			649.642,
			651.243,
			652.854,
			655.281,
			656.904,
			659.357,
			660.175,
			660.994,
			663.46,
			665.108,
			667.58,
			669.23,
			671.698,
			673.341,
			674.16,
			675.794,
			677.423,
			678.235,
			679.852,
			681.46,
			683.859,
			685.447,
			687.819,
			688.605,
			689.389,
			691.736,
			693.293,
			694.843,
			697.166,
			698.7,
			700.998,
			701.765,
			702.53,
			704.796,
			706.309,
			708.571,
			710.074,
			711.581,
			712.337,
			713.873,
			714.643,
			716.189,
			717.747,
			718.542,
			720.138,
			721.745,
			724.176,
			725.826,
			728.33,
			729.173,
			730.018,
			732.552,
			734.246,
			736.813,
			738.54,
			740.28,
			742.914,
			743.799,
			744.687,
			747.369,
			748.27,
			749.175,
			751.907,
			753.745,
			755.595,
			756.525,
			758.394,
			760.276,
			761.222,
			763.122,
			764.078,
			765.997,
			767.927,
			770.839,
			772.794,
			775.748,
			776.739,
			777.733,
			780.729,
			782.741,
			785.78,
			787.82,
			789.871,
			790.901,
			792.969,
			794.007,
			796.092,
			798.187,
			799.235,
			800.282,
			803.428,
			805.536,
			807.654,
			808.716,
			810.849,
			812.991,
			814.065,
			817.303,
			819.474,
			821.653,
			824.94,
			827.143,
			830.465,
			831.577,
			832.692,
			836.049,
			838.298,
			841.691,
			843.964,
			846.247,
			847.391,
			849.688,
			850.84,
			853.151,
			855.471,
			856.635,
			858.968,
			861.313,
			864.846,
			866.029,
			867.214,
			869.59,
			871.975,
			873.171,
			875.569,
			876.772,
			879.183,
			881.604,
			885.251,
			887.693,
			891.371,
			892.602,
			893.835,
			897.545,
			900.029,
			903.77,
			906.274,
			908.781,
			910.035,
			912.539,
			913.787,
			916.266,
			918.721,
			919.936,
			922.34,
			924.705,
			928.181,
			929.32,
			930.45,
			933.782,
			934.874,
			935.956,
			939.145,
			941.224,
			944.273,
			946.26,
			948.209,
			951.064,
			951.998,
			952.922,
			955.643,
			956.533,
			957.414,
			960.005,
			961.685,
			964.148,
			965.73,
			966.507,
			968.036,
			969.541,
			970.283,
			971.747,
			973.197,
			975.355,
			976.777,
			978.906,
			979.615,
			980.325,
			982.457,
			983.878,
			986.022,
			987.462,
			988.901,
			989.621,
			991.076,
			991.807,
			993.267,
			994.727,
			995.462,
			996.204,
			998.427,
			999.907,
			1001.407,
			1002.159,
			1003.66,
			1005.16,
			1005.918,
			1007.436,
			1008.193,
			1009.707,
			1011.227,
			1013.506,
			1015.022,
			1017.305,
			1018.064,
			1018.823,
			1021.106,
			1022.637,
			1024.928,
			1026.473,
			1028.794,
			1030.338,
			1031.125,
			1032.694,
			1034.26,
			1035.042,
			1036.641,
			1038.246,
			1039.849,
			1041.484,
			1042.31,
			1044.783,
			1045.608,
			1046.444,
			1048.111,
			1048.943,
			1050.607,
			1052.274,
			1054.763,
			1056.409,
			1058.85,
			1059.662,
			1060.475,
			1062.833,
			1064.399,
			1066.735,
			1068.277,
			1069.82,
			1070.593,
			1072.147,
			1072.923,
			1074.475,
			1076.03,
			1076.811,
			1078.37,
			1079.928,
			1082.26,
			1083.811,
			1086.132,
			1086.901,
			1087.67,
			1089.974,
			1091.509,
			1093.808,
			1095.343,
			1096.882,
			1099.193,
			1099.965,
			1100.738,
			1103.044,
			1103.814,
			1104.585,
			1106.901,
			1108.443,
			1110.768,
			1112.321,
			1113.879,
			1114.661,
			1116.231,
			1117.018,
			1118.6,
			1120.19,
			1122.597,
			1124.214,
			1126.662,
			1127.483,
			1128.308,
			1130.8,
			1132.482,
			1135.031,
			1136.734,
			1138.468,
			1139.34,
			1141.094,
			1141.976,
			1143.751,
			1145.54,
			1146.439,
			1147.342,
			1150.069,
			1151.902,
			1154.678,
			1156.516,
			1158.345,
			1159.239,
			1161.04,
			1161.932,
			1163.691,
			1165.415,
			1167.933,
			1169.567,
			1171.947,
			1172.722,
			1173.488,
			1175.731,
			1177.18,
			1179.285,
			1180.647,
			1181.975,
			1182.627,
			1183.906,
			1184.534,
			1185.762,
			1186.956,
			1187.541,
			1188.685,
			1189.794,
			1191.403,
			1191.932,
			1192.457,
			1193.494,
			1194.523,
			1195.035,
			1196.572,
			1197.592,
			1198.604,
			1200.105,
			1201.098,
			1202.541,
			1203.013,
			1203.483,
			1204.88,
			1205.807,
			1207.186,
			1208.089,
			1208.985,
			1209.431,
			1210.322,
			1210.767,
			1211.666,
			1212.602,
			1213.067,
			1213.995,
			1214.921,
			1216.314,
			1217.274,
			1218.723,
			1219.206,
			1219.689,
			1220.657,
			1221.142,
			1222.141,
			1223.672,
			1224.699,
			1225.731,
			1227.319,
			1227.86,
			1228.402,
			1230.041,
			1231.145,
			1232.847,
			1233.993,
			1235.146,
			1235.724,
			1236.897,
			1238.098,
			1238.699,
			1239.903,
			1240.506,
			1241.719,
			1242.962,
			1244.824,
			1246.065,
			1247.961,
			1248.595,
			1249.229,
			1251.124,
			1252.411,
			1254.353,
			1255.64,
			1256.933,
			1257.597,
			1258.916,
			1259.571,
			1260.874,
			1262.163,
			1262.796,
			1263.425,
			1265.301,
			1266.546,
			1267.759,
			1268.36,
			1269.557,
			1270.75,
			1271.347,
			1272.541,
			1273.136,
			1274.323,
			1275.509,
			1277.279,
			1278.438,
			1280.176,
			1280.757,
			1281.339,
			1283.033,
			1284.153,
			1285.84,
			1286.968,
			1288.065,
			1288.616,
			1289.722,
			1290.277,
			1291.396,
			1292.536,
			1293.111,
			1294.269,
			1295.437,
			1297.227,
			1297.849,
			1298.475,
			1299.735,
			1301.008,
			1301.65,
			1302.953,
			1303.61,
			1304.937,
			1306.279,
			1308.327,
			1309.714,
			1311.812,
			1312.523,
			1313.239,
			1315.411,
			1316.142,
			1316.878,
			1319.108,
			1320.614,
			1322.136,
			1322.903,
			1324.448,
			1325.226,
			1326.794,
			1329.175,
			1330.78,
			1332.4,
			1334.856,
			1335.682,
			1336.512,
			1339.02,
			1339.864,
			1340.709,
			1343.242,
			1344.936,
			1347.503,
			1349.231,
			1350.972,
			1353.607,
			1354.491,
			1355.379,
			1358.962,
			1359.866,
			1362.596,
			1364.431,
			1366.279,
			1367.208,
			1369.074,
			1370.953,
			1371.898,
			1374.749,
			1376.666,
			1378.595,
			1381.511,
			1383.471,
			1386.433,
			1387.426,
			1388.422,
			1391.428,
			1393.446,
			1396.493,
			1398.538,
			1400.595,
			1401.627,
			1403.701,
			1404.742,
			1406.831,
			1408.927,
			1409.974,
			1411.021,
			1414.173,
			1416.286,
			1418.409,
			1419.474,
			1421.612,
			1423.759,
			1424.836,
			1426.998,
			1428.083,
			1430.258,
			1432.442,
			1435.738,
			1437.947,
			1441.279,
			1442.395,
			1443.513,
			1446.875,
			1449.109,
			1452.422,
			1454.593,
			1456.726,
			1457.779,
			1459.856,
			1460.881,
			1462.902,
			1465.863,
			1466.831,
			1469.696,
			1472.466,
			1474.276,
			1476.063,
			1477.834,
			1478.715,
			1480.466,
			1481.342,
			1483.096,
			1484.848,
			1487.473,
			1489.227,
			1491.859,
			1492.736,
			1493.613,
			1496.259,
			1498.018,
			1500.687,
			1502.466,
			1504.25,
			1505.153,
			1506.954,
			1507.851,
			1509.655,
			1511.467,
			1512.37,
			1514.178,
			1516.002,
			1518.731,
			1519.645,
			1520.559,
			1523.297,
			1524.207,
			1525.113,
			1527.829,
			1529.627,
			1531.402,
			1534.066,
			1535.815,
			1538.443,
			1539.319,
			1540.193,
			1542.82,
			1543.698,
			1544.577,
			1547.221,
			1548.991,
			1550.77,
			1551.662,
			1553.451,
			1555.249,
			1556.151,
			1557.96,
			1558.868,
			1560.689,
			1562.519,
			1565.261,
			1567.103,
			1569.869,
			1570.789,
			1571.708,
			1574.472,
			1576.309,
			1579.059,
			1580.892,
			1582.721,
			1583.634,
			1585.456,
			1586.365,
			1588.177,
			1589.981,
			1590.878,
			1591.773,
			1594.445,
			1596.215,
			1597.977,
			1598.856,
			1600.613,
			1602.365,
			1603.24,
			1604.991,
			1605.87,
			1607.626,
			1609.38,
			1612.067,
			1613.862,
			1616.623,
			1617.554,
			1618.485,
			1621.334,
			1622.3,
			1623.266,
			1626.174,
			1628.118,
			1631.018,
			1632.933,
			1633.892,
			1635.794,
			1637.68,
			1638.625,
			1640.509,
			1642.378,
			1645.189,
			1646.112,
			1647.036,
			1648.889,
			1650.739,
			1651.658,
			1653.503,
			1654.429,
			1656.289,
			1658.158,
			1660.971,
			1662.873,
			1665.751,
			1666.734,
			1667.703,
			1670.631,
			1672.601,
			1675.578,
			1677.561,
			1679.573,
			1680.583,
			1682.613,
			1683.632,
			1685.678,
			1688.768,
			1690.842,
			1692.945,
			1696.071,
			1697.118,
			1698.166,
			1701.321,
			1702.378,
			1703.438,
			1705.565,
			1706.633,
			1708.776,
			1710.929,
			1714.174,
			1716.349,
			1719.628,
			1720.725,
			1721.825,
			1725.138,
			1727.357,
			1730.705,
			1732.947,
			1735.198,
			1736.327,
			1738.591,
			1740.865,
			1742.005,
			1744.292,
			1745.439,
			1747.739,
			1750.048,
			1753.528,
			1754.693,
			1755.86,
			1759.375,
			1760.551,
			1761.73,
			1765.28,
			1767.66,
			1771.247,
			1773.652,
			1776.067,
			1777.279,
			1779.709,
			1780.928,
			1782.149,
			1785.822,
			1787.048,
			1788.273,
			1791.934,
			1794.35,
			1796.74,
			1797.923,
			1800.26,
			1802.561,
			1803.697,
			1805.941,
			1807.049,
			1809.237,
			1811.387,
			1814.544,
			1816.603,
			1819.625,
			1820.615,
			1821.612,
			1824.508,
			1826.409,
			1829.229,
			1831.093,
			1832.95,
			1833.873,
			1835.715,
			1836.635,
			1838.468,
			1840.289,
			1841.199,
			1843.015,
			1844.819,
			1847.522,
			1848.423,
			1849.325,
			1852.028,
			1852.929,
			1853.837,
			1855.655,
			1856.564,
			1858.389,
			1860.253,
			1863.05,
			1865.003,
			1867.942,
			1868.955,
			1869.982,
			1873.071,
			1875.184,
			1878.371,
			1880.539,
			1882.712,
			1883.804,
			1885.993,
			1888.189,
			1889.279,
			1891.459,
			1892.552,
			1894.7,
			1896.823,
			1899.927,
			1900.93,
			1901.936,
			1903.94,
			1905.897,
			1906.879,
			1909.838,
			1911.822,
			1913.816,
			1916.827,
			1918.848,
			1921.907,
			1922.932,
			1923.96,
			1927.068,
			1928.109,
			1929.153,
			1932.281,
			1934.389,
			1936.505,
			1937.567,
			1939.715,
			1941.835,
			1942.908,
			1945.06,
			1946.14,
			1948.306,
			1950.483,
			1953.767,
			1954.869,
			1955.975,
			1959.307,
			1960.425,
			1961.546,
			1964.925,
			1967.193,
			1970.613,
			1972.905,
			1975.207,
			1976.362,
			1978.679,
			1979.841,
			1982.171,
			1984.509,
			1985.679,
			1986.849,
			1990.347,
			1992.658,
			1994.942,
			1996.072,
			1998.305,
			2000.501,
			2001.584,
			2003.722,
			2004.777,
			2006.859,
			2008.904,
			2011.901,
			2013.854,
			2016.714,
			2017.648,
			2018.574,
			2021.3,
			2023.073,
			2025.67,
			2027.349,
			2028.991,
			2029.798,
			2031.381,
			2032.154,
			2033.672,
			2035.899,
			2037.333,
			2038.746,
			2040.837,
			2042.218,
			2043.593,
			2044.963,
			2045.648,
			2047.019,
			2047.705,
			2049.074,
			2050.441,
			2052.495,
			2053.863,
			2055.914,
			2056.599,
			2057.284,
			2059.336,
			2060.701,
			2062.758,
			2064.127,
			2065.495,
			2066.178,
			2067.559,
			2068.25,
			2069.63,
			2071.008,
			2071.699,
			2073.106,
			2074.509,
			2076.607,
			2077.339,
			2078.074,
			2080.272,
			2081.003,
			2081.737,
			2083.305,
			2084.087,
			2085.65,
			2087.225,
			2089.633,
			2091.239,
			2093.6,
			2094.381,
			2095.163,
			2097.505,
			2099.049,
			2101.371,
			2102.938,
			2105.302,
			2106.886,
			2108.499,
			2109.307,
			2110.926,
			2111.738,
			2113.371,
			2115.008,
			2117.475,
			2119.124,
			2121.609,
			2122.435,
			2123.246,
			2125.688,
			2127.329,
			2129.712,
			2131.31,
			2132.902,
			2135.245,
			2136.031,
			2136.821,
			2139.209,
			2140.012,
			2140.816,
			2143.257,
			2144.921,
			2146.596,
			2147.438,
			2149.147,
			2150.872,
			2151.739,
			2153.484,
			2154.364,
			2156.132,
			2157.914,
			2160.613,
			2162.413,
			2165.159,
			2166.081,
			2167.006,
			2169.801,
			2171.679,
			2174.52,
			2176.43,
			2178.352,
			2179.318,
			2181.258,
			2182.233,
			2184.191,
			2186.161,
			2187.15,
			2188.143,
			2191.136,
			2193.147,
			2195.168,
			2196.183,
			2198.222,
			2200.272,
			2201.301,
			2203.368,
			2204.406,
			2206.493,
			2208.589,
			2211.736,
			2213.839,
			2217.013,
			2218.076,
			2219.142,
			2222.358,
			2224.515,
			2227.771,
			2229.954,
			2233.246,
			2235.453,
			2236.56,
			2238.782,
			2241.013,
			2242.132,
			2244.378,
			2246.634,
			2250.037,
			2251.174,
			2252.314,
			2255.75,
			2256.9,
			2258.053,
			2261.525,
			2263.853,
			2266.192,
			2269.722,
			2272.089,
			2275.657,
			2276.852,
			2278.049,
			2281.654,
			2284.07,
			2287.712,
			2290.151,
			2292.6,
			2293.828,
			2296.291,
			2298.763,
			2300.002,
			2303.734,
			2306.234,
			2308.743,
			2312.522,
			2315.053,
			2318.865,
			2320.141,
			2321.418,
			2325.263,
			2327.838,
			2331.715,
			2334.31,
			2336.914,
			2338.218,
			2340.829,
			2342.134,
			2343.44,
			2347.372,
			2348.686,
			2350.002,
			2353.962,
			2356.609,
			2359.263,
			2360.593,
			2363.257,
			2365.927,
			2367.265,
			2369.946,
			2371.289,
			2373.98,
			2376.679,
			2380.737,
			2383.449,
			2387.537,
			2388.904,
			2390.272,
			2394.387,
			2397.146,
			2397.628
		],
		[
			0,
			0.02,
			0.051,
			0.112,
			0.127,
			0.174,
			0.235,
			0.297,
			0.345,
			0.361,
			0.423,
			0.453,
			0.485,
			0.545,
			0.562,
			0.609,
			0.671,
			0.732,
			0.779,
			0.857,
			0.887,
			0.919,
			0.997,
			1.043,
			1.106,
			1.165,
			1.228,
			1.289,
			1.321,
			1.354,
			1.415,
			1.429,
			1.476,
			1.539,
			1.601,
			1.647,
			1.664,
			1.724,
			1.754,
			1.787,
			1.85,
			1.863,
			1.912,
			1.973,
			1.978,
			2.035,
			2.081,
			2.159,
			2.19,
			2.22,
			2.282,
			2.345,
			2.405,
			2.47,
			2.515,
			2.531,
			2.592,
			2.624,
			2.655,
			2.716,
			2.733,
			2.779,
			2.841,
			2.904,
			2.948,
			2.965,
			3.028,
			3.057,
			3.089,
			3.152,
			3.213,
			3.274,
			3.337,
			3.398,
			3.462,
			3.493,
			3.524,
			3.585,
			3.648,
			3.71,
			3.77,
			3.818,
			3.831,
			3.896,
			3.927,
			3.957,
			4.02,
			4.041,
			4.082,
			4.143,
			4.204,
			4.25,
			4.267,
			4.329,
			4.36,
			4.391,
			4.469,
			4.516,
			4.578,
			4.639,
			4.687,
			4.702,
			4.762,
			4.795,
			4.826,
			4.903,
			4.948,
			5.011,
			5.072,
			5.12,
			5.136,
			5.198,
			5.228,
			5.258,
			5.321,
			5.337,
			5.381,
			5.445,
			5.506,
			5.555,
			5.63,
			5.662,
			5.692,
			5.754,
			5.816,
			5.88,
			5.94,
			5.989,
			6.003,
			6.066,
			6.103,
			6.188,
			6.205,
			6.249,
			6.313,
			6.376,
			6.438,
			6.5,
			6.531,
			6.562,
			6.622,
			6.639,
			6.686,
			6.747,
			6.81,
			6.857,
			6.932,
			6.966,
			6.995,
			7.058,
			7.119,
			7.182,
			7.245,
			7.289,
			7.305,
			7.366,
			7.399,
			7.431,
			7.507,
			7.554,
			7.616,
			7.678,
			7.725,
			7.739,
			7.802,
			7.832,
			7.862,
			7.925,
			7.942,
			7.989,
			8.049,
			8.11,
			8.163,
			8.237,
			8.267,
			8.298,
			8.358,
			8.42,
			8.485,
			8.545,
			8.592,
			8.608,
			8.67,
			8.699,
			8.732,
			8.795,
			8.809,
			8.856,
			8.919,
			8.979,
			9.026,
			9.104,
			9.133,
			9.165,
			9.228,
			9.291,
			9.354,
			9.414,
			9.461,
			9.477,
			9.539,
			9.569,
			9.601,
			9.661,
			9.678,
			9.724,
			9.788,
			9.847,
			9.894,
			9.911,
			9.973,
			10.003,
			10.034,
			10.113,
			10.157,
			10.224,
			10.282,
			10.328,
			10.407,
			10.438,
			10.468,
			10.545,
			10.593,
			10.655,
			10.717,
			10.763,
			10.777,
			10.839,
			10.87,
			10.903,
			10.98,
			11.025,
			11.089,
			11.149,
			11.196,
			11.211,
			11.275,
			11.305,
			11.336,
			11.398,
			11.415,
			11.459,
			11.522,
			11.582,
			11.632,
			11.708,
			11.74,
			11.77,
			11.831,
			11.895,
			11.957,
			12.02,
			12.064,
			12.081,
			12.142,
			12.175,
			12.204,
			12.267,
			12.287,
			12.327,
			12.391,
			12.454,
			12.515,
			12.576,
			12.607,
			12.64,
			12.714,
			12.762,
			12.826,
			12.887,
			12.933,
			13.01,
			13.04,
			13.073,
			13.151,
			13.198,
			13.258,
			13.321,
			13.365,
			13.382,
			13.445,
			13.474,
			13.505,
			13.57,
			13.584,
			13.63,
			13.692,
			13.754,
			13.799,
			13.817,
			13.88,
			13.91,
			13.941,
			14.003,
			14.065,
			14.126,
			14.189,
			14.236,
			14.311,
			14.347,
			14.374,
			14.437,
			14.454,
			14.499,
			14.56,
			14.623,
			14.67,
			14.683,
			14.747,
			14.778,
			14.81,
			14.87,
			14.886,
			14.933,
			14.996,
			15.056,
			15.104,
			15.18,
			15.212,
			15.242,
			15.304,
			15.367,
			15.429,
			15.49,
			15.554,
			15.614,
			15.645,
			15.677,
			15.739,
			15.756,
			15.8,
			15.862,
			15.925,
			15.988,
			16.049,
			16.081,
			16.111,
			16.172,
			16.189,
			16.235,
			16.298,
			16.358,
			16.411,
			16.484,
			16.515,
			16.545,
			16.607,
			16.67,
			16.731,
			16.792,
			16.855,
			16.917,
			16.948,
			16.978,
			17.041,
			17.057,
			17.103,
			17.165,
			17.228,
			17.29,
			17.352,
			17.382,
			17.412,
			17.49,
			17.537,
			17.599,
			17.662,
			17.709,
			17.786,
			17.814,
			17.848,
			17.91,
			17.97,
			18.033,
			18.095,
			18.141,
			18.158,
			18.219,
			18.25,
			18.28,
			18.343,
			18.36,
			18.405,
			18.471,
			18.529,
			18.577,
			18.654,
			18.682,
			18.716,
			18.779,
			18.84,
			18.903,
			18.962,
			19.01,
			19.089,
			19.12,
			19.148,
			19.212,
			19.228,
			19.275,
			19.339,
			19.398,
			19.445,
			19.459,
			19.52,
			19.554,
			19.582,
			19.645,
			19.661,
			19.707,
			19.771,
			19.833,
			19.878,
			19.954,
			19.986,
			20.016,
			20.08,
			20.14,
			20.203,
			20.266,
			20.311,
			20.327,
			20.39,
			20.421,
			20.453,
			20.514,
			20.531,
			20.574,
			20.639,
			20.698,
			20.748,
			20.762,
			20.824,
			20.855,
			20.886,
			20.965,
			21.009,
			21.071,
			21.132,
			21.196,
			21.259,
			21.289,
			21.32,
			21.381,
			21.445,
			21.508,
			21.568,
			21.615,
			21.629,
			21.692,
			21.724,
			21.754,
			21.816,
			21.831,
			21.878,
			21.941,
			22.003,
			22.049,
			22.063,
			22.125,
			22.157,
			22.187,
			22.249,
			22.266,
			22.311,
			22.374,
			22.436,
			22.482,
			22.559,
			22.595,
			22.623,
			22.684,
			22.745,
			22.808,
			22.87,
			22.918,
			22.931,
			22.993,
			23.026,
			23.057,
			23.119,
			23.135,
			23.179,
			23.243,
			23.304,
			23.349,
			23.366,
			23.429,
			23.459,
			23.491,
			23.566,
			23.614,
			23.677,
			23.738,
			23.786,
			23.863,
			23.893,
			23.924,
			23.987,
			24.002,
			24.05,
			24.11,
			24.172,
			24.235,
			24.298,
			24.326,
			24.36,
			24.419,
			24.438,
			24.483,
			24.544,
			24.605,
			24.658,
			24.729,
			24.761,
			24.794,
			24.854,
			24.915,
			24.979,
			25.04,
			25.086,
			25.104,
			25.165,
			25.194,
			25.226,
			25.289,
			25.303,
			25.349,
			25.411,
			25.474,
			25.52,
			25.538,
			25.598,
			25.628,
			25.66,
			25.723,
			25.737,
			25.783,
			25.845,
			25.91,
			25.954,
			26.034,
			26.063,
			26.095,
			26.171,
			26.219,
			26.282,
			26.343,
			26.404,
			26.465,
			26.499,
			26.53,
			26.59,
			26.608,
			26.654,
			26.715,
			26.776,
			26.822,
			26.839,
			26.902,
			26.931,
			26.961,
			27.025,
			27.039,
			27.087,
			27.15,
			27.211,
			27.258,
			27.336,
			27.365,
			27.398,
			27.458,
			27.522,
			27.587,
			27.646,
			27.691,
			27.707,
			27.77,
			27.799,
			27.83,
			27.894,
			27.907,
			27.955,
			28.023,
			28.079,
			28.126,
			28.202,
			28.233,
			28.266,
			28.344,
			28.389,
			28.457,
			28.514,
			28.559,
			28.637,
			28.669,
			28.697,
			28.762,
			28.782,
			28.823,
			28.886,
			28.947,
			29.01,
			29.071,
			29.102,
			29.131,
			29.196,
			29.21,
			29.257,
			29.32,
			29.381,
			29.429,
			29.506,
			29.537,
			29.567,
			29.631,
			29.692,
			29.754,
			29.815,
			29.861,
			29.878,
			29.94,
			29.97,
			30.002,
			30.062,
			30.079,
			30.126,
			30.189,
			30.248,
			30.313,
			30.374,
			30.404,
			30.436,
			30.497,
			30.514,
			30.56,
			30.623,
			30.683,
			30.731,
			30.806,
			30.844,
			30.871,
			30.932,
			30.994,
			31.055,
			31.116,
			31.165,
			31.179,
			31.24,
			31.274,
			31.304,
			31.365,
			31.382,
			31.427,
			31.489,
			31.552,
			31.598,
			31.614,
			31.675,
			31.707,
			31.736,
			31.815,
			31.861,
			31.925,
			31.986,
			32.032,
			32.111,
			32.141,
			32.173,
			32.25,
			32.296,
			32.358,
			32.42,
			32.465,
			32.482,
			32.543,
			32.576,
			32.606,
			32.669,
			32.682,
			32.728,
			32.793,
			32.853,
			32.916,
			32.979,
			33.01,
			33.04,
			33.1,
			33.117,
			33.165,
			33.227,
			33.288,
			33.335,
			33.411,
			33.442,
			33.474,
			33.536,
			33.597,
			33.66,
			33.723,
			33.769,
			33.783,
			33.846,
			33.877,
			33.907,
			33.971,
			33.987,
			34.033,
			34.094,
			34.156,
			34.203,
			34.281,
			34.312,
			34.342,
			34.405,
			34.466,
			34.529,
			34.59,
			34.636,
			34.654,
			34.713,
			34.745,
			34.778,
			34.839,
			34.852,
			34.898,
			34.965,
			35.023,
			35.071,
			35.087,
			35.147,
			35.181,
			35.212,
			35.274,
			35.287,
			35.333,
			35.396,
			35.457,
			35.522,
			35.583,
			35.614,
			35.645,
			35.706,
			35.767,
			35.832,
			35.893,
			35.94,
			35.954,
			36.018,
			36.046,
			36.078,
			36.14,
			36.157,
			36.204,
			36.27,
			36.326,
			36.372,
			36.388,
			36.449,
			36.482,
			36.513,
			36.575,
			36.591,
			36.637,
			36.699,
			36.761,
			36.808,
			36.886,
			36.914,
			36.947,
			37.008,
			37.03,
			37.071,
			37.132,
			37.195,
			37.241,
			37.257,
			37.32,
			37.348,
			37.381,
			37.457,
			37.504,
			37.566,
			37.63,
			37.674,
			37.69,
			37.754,
			37.785,
			37.814,
			37.893,
			37.938,
			38.001,
			38.063,
			38.108,
			38.187,
			38.219,
			38.248,
			38.327,
			38.372,
			38.433,
			38.498,
			38.542,
			38.56,
			38.621,
			38.652,
			38.682,
			38.762,
			38.808,
			38.87,
			38.932,
			38.993,
			39.054,
			39.09,
			39.117,
			39.178,
			39.242,
			39.304,
			39.366,
			39.411,
			39.428,
			39.489,
			39.52,
			39.552,
			39.613,
			39.629,
			39.675,
			39.737,
			39.798,
			39.845,
			39.862,
			39.923,
			39.953,
			39.986,
			40.048,
			40.062,
			40.11,
			40.171,
			40.232,
			40.279,
			40.357,
			40.387,
			40.42,
			40.497,
			40.544,
			40.606,
			40.665,
			40.715,
			40.73,
			40.793,
			40.823,
			40.853,
			40.932,
			40.976,
			41.04,
			41.102,
			41.164,
			41.226,
			41.257,
			41.289,
			41.349,
			41.365,
			41.412,
			41.474,
			41.537,
			41.598,
			41.66,
			41.69,
			41.723,
			41.782,
			41.846,
			41.907,
			41.969,
			42.015,
			42.033,
			42.093,
			42.125,
			42.156,
			42.219,
			42.231,
			42.278,
			42.346,
			42.405,
			42.449,
			42.465,
			42.529,
			42.557,
			42.59,
			42.652,
			42.713,
			42.775,
			42.836,
			42.901,
			42.962,
			42.994,
			43.023,
			43.087,
			43.103,
			43.149,
			43.214,
			43.271,
			43.319,
			43.333,
			43.395,
			43.428,
			43.458,
			43.521,
			43.536,
			43.581,
			43.644,
			43.705,
			43.752,
			43.829,
			43.86,
			43.893,
			43.969,
			44.015,
			44.079,
			44.141,
			44.187,
			44.202,
			44.263,
			44.294,
			44.327,
			44.388,
			44.404,
			44.449,
			44.513,
			44.575,
			44.621,
			44.637,
			44.699,
			44.729,
			44.76,
			44.822,
			44.838,
			44.883,
			44.947,
			45.009,
			45.056,
			45.131,
			45.163,
			45.195,
			45.255,
			45.277,
			45.316,
			45.38,
			45.443,
			45.505,
			45.566,
			45.596,
			45.629,
			45.69,
			45.704,
			45.752,
			45.813,
			45.876,
			45.921,
			45.938,
			46.001,
			46.031,
			46.062,
			46.123,
			46.14,
			46.185,
			46.249,
			46.31,
			46.357,
			46.434,
			46.465,
			46.498,
			46.574,
			46.62,
			46.683,
			46.745,
			46.79,
			46.807,
			46.869,
			46.899,
			46.929,
			47.007,
			47.055,
			47.116,
			47.177,
			47.225,
			47.241,
			47.303,
			47.338,
			47.366,
			47.426,
			47.443,
			47.489,
			47.549,
			47.613,
			47.675,
			47.736,
			47.767,
			47.799,
			47.861,
			47.922,
			47.986,
			48.047,
			48.094,
			48.107,
			48.171,
			48.201,
			48.231,
			48.295,
			48.311,
			48.358,
			48.419,
			48.48,
			48.526,
			48.542,
			48.605,
			48.636,
			48.665,
			48.743,
			48.79,
			48.853,
			48.915,
			48.961,
			48.978,
			49.037,
			49.07,
			49.101,
			49.162,
			49.179,
			49.225,
			49.287,
			49.349,
			49.4,
			49.411,
			49.475,
			49.504,
			49.533,
			49.598,
			49.611,
			49.66,
			49.721,
			49.782,
			49.829,
			49.908,
			49.938,
			49.969,
			50.032,
			50.094,
			50.156,
			50.216,
			50.263,
			50.28,
			50.341,
			50.371,
			50.404,
			50.464,
			50.48,
			50.527,
			50.59,
			50.652,
			50.699,
			50.712,
			50.776,
			50.806,
			50.837,
			50.9,
			50.913,
			50.96,
			51.022,
			51.087,
			51.131,
			51.209,
			51.239,
			51.271,
			51.334,
			51.395,
			51.461,
			51.52,
			51.566,
			51.582,
			51.643,
			51.676,
			51.706,
			51.768,
			51.782,
			51.83,
			51.892,
			51.954,
			51.999,
			52.014,
			52.076,
			52.109,
			52.14,
			52.203,
			52.264,
			52.325,
			52.387,
			52.435,
			52.512,
			52.543,
			52.574,
			52.637,
			52.651,
			52.697,
			52.76,
			52.821,
			52.869,
			52.882,
			52.944,
			52.977,
			53.008,
			53.07,
			53.086,
			53.132,
			53.195,
			53.255,
			53.3,
			53.317,
			53.38,
			53.41,
			53.441,
			53.504,
			53.565,
			53.629,
			53.688,
			53.737,
			53.753,
			53.813,
			53.845,
			53.877,
			53.937,
			53.954,
			54,
			54.063,
			54.123,
			54.171,
			54.183,
			54.249,
			54.278,
			54.309,
			54.372,
			54.388,
			54.432,
			54.496,
			54.556,
			54.604,
			54.682,
			54.712,
			54.744,
			54.807,
			54.869,
			54.93,
			54.993,
			55.039,
			55.053,
			55.117,
			55.148,
			55.178,
			55.256,
			55.303,
			55.365,
			55.427,
			55.487,
			55.55,
			55.587,
			55.613,
			55.674,
			55.69,
			55.737,
			55.798,
			55.859,
			55.905,
			55.983,
			56.016,
			56.047,
			56.107,
			56.171,
			56.232,
			56.293,
			56.34,
			56.357,
			56.419,
			56.448,
			56.479,
			56.542,
			56.558,
			56.605,
			56.666,
			56.728,
			56.775,
			56.789,
			56.853,
			56.883,
			56.913,
			56.976,
			56.992,
			57.038,
			57.098,
			57.163,
			57.224,
			57.286,
			57.316,
			57.349,
			57.41,
			57.471,
			57.533,
			57.595,
			57.659,
			57.72,
			57.752,
			57.782,
			57.845,
			57.86,
			57.907,
			57.967,
			58.031,
			58.091,
			58.155,
			58.186,
			58.215,
			58.28,
			58.34,
			58.404,
			58.465,
			58.512,
			58.588,
			58.62,
			58.65,
			58.712,
			58.728,
			58.774,
			58.837,
			58.898,
			58.946,
			58.96,
			59.023,
			59.054,
			59.086,
			59.146,
			59.162,
			59.208,
			59.271,
			59.332,
			59.379,
			59.458,
			59.488,
			59.52,
			59.595,
			59.642,
			59.704,
			59.765,
			59.813,
			59.829,
			59.892,
			59.92,
			59.953,
			60.014,
			60.029,
			60.076,
			60.138,
			60.199,
			60.248,
			60.262,
			60.323,
			60.357,
			60.387,
			60.448,
			60.463,
			60.51,
			60.574,
			60.633,
			60.698,
			60.759,
			60.789,
			60.821,
			60.883,
			60.944,
			61.009,
			61.069,
			61.132,
			61.192,
			61.224,
			61.256,
			61.318,
			61.331,
			61.379,
			61.443,
			61.504,
			61.55,
			61.565,
			61.628,
			61.658,
			61.69,
			61.772,
			61.814,
			61.874,
			61.938,
			61.982,
			62.062,
			62.093,
			62.123,
			62.2,
			62.248,
			62.31,
			62.371,
			62.418,
			62.433,
			62.496,
			62.526,
			62.558,
			62.636,
			62.68,
			62.742,
			62.805,
			62.865,
			62.928,
			62.96,
			62.99,
			63.053,
			63.115,
			63.179,
			63.238,
			63.286,
			63.302,
			63.362,
			63.395,
			63.426,
			63.486,
			63.504,
			63.549,
			63.611,
			63.673,
			63.72,
			63.734,
			63.796,
			63.832,
			63.859,
			63.92,
			63.937,
			63.982,
			64.045,
			64.106,
			64.153,
			64.231,
			64.263,
			64.293,
			64.371,
			64.418,
			64.423
		]
	],
	'2383_7754_7982': [
		[
			85.874
		],
		[
			0
		],
		[
			0,
			1.796,
			4.554,
			7.317,
			11.477,
			14.259,
			18.445,
			19.843,
			21.243,
			25.453,
			28.268,
			32.502,
			35.334,
			39.593,
			42.44,
			45.294,
			46.723,
			49.587,
			51.021,
			53.893,
			56.773,
			61.103,
			62.55,
			63.998,
			68.353,
			69.808,
			71.264,
			75.641,
			78.567,
			82.966,
			85.907,
			90.329,
			93.285,
			94.765,
			96.246,
			102.187,
			103.676,
			108.152,
			111.142,
			114.138,
			115.638,
			118.643,
			121.653,
			123.16,
			127.692,
			130.724,
			133.762,
			138.329,
			141.376,
			145.957,
			147.486,
			149.017,
			153.617,
			156.691,
			161.311,
			164.398,
			167.49,
			169.038,
			172.139,
			173.691,
			176.799,
			179.912,
			181.47,
			184.59,
			187.717,
			192.415,
			193.984,
			195.554,
			198.698,
			201.847,
			203.423,
			206.579,
			208.159,
			211.323,
			214.491,
			219.253,
			222.434,
			227.207,
			228.799,
			230.392,
			235.179,
			238.376,
			243.182,
			246.391,
			249.604,
			251.213,
			254.433,
			256.045,
			259.272,
			264.121,
			267.359,
			270.601,
			275.473,
			278.728,
			283.616,
			285.248,
			286.882,
			291.79,
			295.067,
			298.347,
			303.267,
			306.537,
			311.403,
			313.009,
			314.606,
			319.339,
			320.899,
			322.451,
			327.059,
			330.087,
			333.079,
			334.561,
			337.497,
			338.951,
			341.831,
			344.671,
			346.078,
			348.862,
			351.609,
			355.659,
			358.313,
			362.224,
			363.509,
			364.784,
			368.555,
			371.023,
			374.658,
			377.039,
			379.407,
			380.567,
			382.857,
			383.989,
			385.111,
			388.418,
			389.503,
			390.578,
			393.748,
			395.816,
			397.844,
			398.849,
			400.839,
			402.805,
			403.783,
			405.739,
			406.722,
			408.691,
			410.682,
			413.756,
			415.839,
			419.064,
			420.14,
			421.273,
			424.674,
			426.984,
			430.443,
			432.631,
			435.884,
			437.982,
			439.04,
			440.099,
			444.376,
			446.509,
			448.64,
			451.825,
			452.88,
			453.938,
			456.035,
			458.08,
			459.115,
			461.173,
			462.193,
			464.243,
			466.3,
			469.391,
			471.442,
			474.478,
			475.476,
			476.464,
			479.376,
			481.272,
			484.05,
			485.865,
			487.661,
			488.546,
			490.293,
			491.189,
			492.958,
			494.689,
			495.549,
			497.299,
			499.01,
			501.5,
			502.322,
			503.122,
			505.472,
			506.237,
			506.997,
			508.5,
			509.245,
			510.738,
			512.257,
			514.53,
			516.076,
			518.455,
			519.244,
			520.032,
			522.467,
			524.092,
			526.502,
			528.085,
			529.662,
			530.45,
			531.933,
			533.407,
			534.143,
			535.611,
			536.321,
			537.739,
			539.155,
			541.278,
			541.983,
			542.687,
			544.8,
			545.507,
			546.21,
			548.315,
			549.718,
			551.812,
			553.197,
			554.581,
			555.274,
			556.661,
			557.352,
			558.042,
			560.801,
			561.495,
			563.569,
			564.949,
			567.023,
			568.4,
			569.776,
			570.464,
			571.842,
			572.529,
			573.899,
			575.269,
			577.328,
			578.696,
			580.745,
			581.431,
			582.115,
			584.16,
			585.521,
			587.57,
			588.933,
			590.298,
			590.982,
			592.358,
			593.045,
			594.422,
			595.803,
			596.499,
			597.892,
			599.288,
			600.689,
			602.106,
			602.817,
			604.242,
			605.674,
			606.399,
			608.589,
			610.059,
			611.549,
			613.819,
			615.346,
			617.682,
			618.469,
			619.259,
			621.659,
			623.292,
			625.766,
			627.456,
			629.161,
			630.018,
			631.751,
			632.627,
			634.385,
			636.156,
			637.051,
			638.846,
			640.649,
			643.358,
			644.263,
			645.169,
			647.867,
			648.761,
			649.654,
			652.344,
			654.145,
			655.947,
			658.727,
			660.584,
			663.407,
			664.351,
			665.295,
			668.117,
			669.997,
			672.79,
			674.642,
			676.488,
			677.392,
			679.204,
			681.021,
			681.9,
			683.653,
			684.532,
			686.292,
			688.024,
			690.635,
			692.39,
			695.041,
			695.929,
			696.827,
			699.539,
			701.363,
			704.134,
			705.997,
			707.858,
			708.792,
			710.669,
			711.613,
			712.561,
			715.423,
			716.385,
			717.36,
			720.305,
			722.284,
			724.259,
			725.259,
			727.268,
			729.288,
			730.302,
			732.339,
			733.361,
			735.414,
			737.477,
			740.592,
			742.681,
			745.824,
			746.871,
			747.918,
			751.075,
			753.191,
			756.384,
			758.523,
			761.751,
			763.915,
			765,
			767.179,
			769.369,
			770.467,
			772.67,
			774.883,
			777.106,
			779.339,
			780.459,
			782.706,
			784.962,
			786.093,
			788.364,
			789.502,
			791.787,
			794.081,
			797.539,
			799.856,
			803.349,
			804.518,
			805.689,
			809.217,
			811.58,
			815.141,
			817.527,
			819.922,
			821.122,
			823.531,
			824.738,
			827.159,
			829.59,
			830.808,
			833.251,
			835.703,
			839.397,
			840.632,
			841.87,
			844.352,
			846.841,
			848.089,
			850.591,
			851.846,
			854.36,
			856.883,
			860.682,
			863.225,
			867.053,
			868.333,
			869.615,
			873.472,
			876.052,
			879.937,
			882.53,
			885.122,
			886.415,
			888.986,
			890.263,
			892.793,
			895.287,
			896.519,
			898.955,
			901.352,
			904.875,
			906.03,
			907.176,
			910.555,
			911.663,
			912.76,
			915.997,
			918.108,
			921.205,
			923.222,
			925.203,
			928.105,
			929.054,
			929.994,
			932.759,
			933.664,
			934.56,
			937.198,
			938.913,
			940.591,
			941.431,
			943.054,
			944.638,
			945.417,
			946.952,
			947.71,
			949.196,
			950.663,
			952.843,
			954.287,
			956.448,
			957.169,
			957.892,
			960.08,
			961.537,
			963.736,
			965.21,
			966.683,
			967.42,
			968.913,
			969.661,
			971.156,
			972.651,
			973.405,
			974.159,
			976.418,
			977.923,
			979.423,
			980.171,
			981.666,
			983.165,
			983.921,
			985.43,
			986.184,
			987.69,
			989.263,
			991.626,
			993.217,
			995.682,
			996.502,
			997.322,
			999.808,
			1001.466,
			1003.958,
			1005.62,
			1007.278,
			1008.106,
			1009.754,
			1010.576,
			1012.218,
			1013.854,
			1014.668,
			1016.294,
			1017.918,
			1020.342,
			1021.146,
			1021.95,
			1024.358,
			1025.156,
			1025.953,
			1028.34,
			1029.926,
			1031.506,
			1033.874,
			1035.448,
			1037.804,
			1038.592,
			1039.378,
			1041.735,
			1043.309,
			1045.675,
			1047.251,
			1048.833,
			1049.625,
			1051.207,
			1052.789,
			1053.582,
			1055.962,
			1057.55,
			1059.146,
			1061.544,
			1063.147,
			1065.571,
			1066.381,
			1067.191,
			1069.644,
			1071.288,
			1073.763,
			1075.414,
			1077.903,
			1079.532,
			1080.346,
			1081.162,
			1083.613,
			1085.226,
			1087.665,
			1089.285,
			1090.906,
			1091.721,
			1093.36,
			1094.999,
			1095.822,
			1097.479,
			1098.313,
			1100.004,
			1101.707,
			1104.289,
			1106.029,
			1108.663,
			1109.545,
			1110.431,
			1113.108,
			1114.915,
			1117.651,
			1119.49,
			1121.343,
			1122.274,
			1124.141,
			1125.077,
			1126.941,
			1128.836,
			1129.79,
			1130.747,
			1133.641,
			1135.602,
			1137.576,
			1138.568,
			1140.567,
			1142.579,
			1143.589,
			1145.618,
			1146.637,
			1148.683,
			1150.742,
			1153.852,
			1155.938,
			1159.082,
			1160.128,
			1161.175,
			1164.328,
			1166.443,
			1169.635,
			1171.777,
			1173.928,
			1175.008,
			1177.175,
			1178.262,
			1180.445,
			1182.638,
			1183.739,
			1185.947,
			1188.166,
			1191.514,
			1192.635,
			1193.759,
			1197.146,
			1198.28,
			1199.417,
			1202.84,
			1205.128,
			1208.579,
			1210.886,
			1213.202,
			1216.695,
			1217.864,
			1219.036,
			1222.568,
			1224.935,
			1228.506,
			1230.9,
			1233.303,
			1234.509,
			1236.927,
			1238.14,
			1240.574,
			1243.019,
			1244.245,
			1246.705,
			1249.174,
			1252.894,
			1254.139,
			1255.385,
			1259.137,
			1260.392,
			1261.648,
			1265.432,
			1267.965,
			1271.781,
			1274.336,
			1276.899,
			1280.759,
			1282.05,
			1283.344,
			1287.236,
			1289.84,
			1293.748,
			1296.361,
			1298.983,
			1300.296,
			1302.928,
			1305.568,
			1306.891,
			1309.542,
			1310.87,
			1313.532,
			1316.202,
			1320.22,
			1322.908,
			1326.953,
			1328.305,
			1329.66,
			1333.733,
			1336.457,
			1340.558,
			1343.301,
			1347.429,
			1350.19,
			1351.574,
			1354.347,
			1357.127,
			1358.52,
			1361.312,
			1364.11,
			1366.916,
			1371.129,
			1373.956,
			1376.746,
			1378.134,
			1380.892,
			1382.259,
			1384.97,
			1387.645,
			1391.587,
			1394.167,
			1397.966,
			1399.213,
			1400.452,
			1404.106,
			1406.498,
			1410.018,
			1412.341,
			1414.66,
			1415.807,
			1418.144,
			1420.483,
			1421.643,
			1423.958,
			1425.111,
			1427.396,
			1429.65,
			1433.057,
			1434.206,
			1435.357,
			1437.678,
			1440.023,
			1441.199,
			1443.554,
			1444.732,
			1447.086,
			1449.423,
			1452.901,
			1455.186,
			1458.541,
			1459.635,
			1460.723,
			1463.902,
			1465.968,
			1469.011,
			1471.012,
			1473.007,
			1474.004,
			1475.998,
			1476.996,
			1479.007,
			1481.026,
			1482.04,
			1484.079,
			1486.125,
			1489.211,
			1490.242,
			1491.275,
			1494.377,
			1495.411,
			1496.445,
			1499.537,
			1501.588,
			1504.643,
			1506.669,
			1508.684,
			1511.69,
			1512.689,
			1513.684,
			1516.661,
			1517.652,
			1518.643,
			1521.606,
			1523.603,
			1525.612,
			1526.614,
			1528.686,
			1529.745,
			1531.858,
			1534.081,
			1535.213,
			1537.471,
			1539.842,
			1543.32,
			1545.496,
			1548.675,
			1549.662,
			1550.648,
			1553.586,
			1555.52,
			1558.421,
			1560.356,
			1563.26,
			1565.19,
			1566.157,
			1568.097,
			1571.028,
			1572.009,
			1574.978,
			1576.972,
			1578.979,
			1579.987,
			1582.011,
			1584.046,
			1585.068,
			1587.119,
			1588.147,
			1590.211,
			1592.285,
			1595.409,
			1597.503,
			1600.658,
			1601.714,
			1602.771,
			1605.946,
			1608.068,
			1611.251,
			1613.377,
			1615.506,
			1616.571,
			1618.708,
			1619.787,
			1621.945,
			1624.133,
			1625.245,
			1627.471,
			1629.806,
			1632.196,
			1634.734,
			1636.059,
			1638.703,
			1641.296,
			1642.594,
			1644.979,
			1646.133,
			1648.426,
			1650.59,
			1653.753,
			1655.766,
			1658.712,
			1659.677,
			1660.635,
			1663.465,
			1665.324,
			1668.051,
			1669.844,
			1671.608,
			1672.478,
			1674.219,
			1675.093,
			1676.817,
			1678.511,
			1679.371,
			1681.063,
			1682.731,
			1685.201,
			1686.823,
			1689.243,
			1690.066,
			1690.883,
			1693.309,
			1694.97,
			1697.462,
			1699.124,
			1700.792,
			1703.292,
			1704.114,
			1704.927,
			1707.367,
			1708.181,
			1708.981,
			1711.313,
			1712.87,
			1714.404,
			1715.155,
			1716.657,
			1718.159,
			1718.912,
			1720.424,
			1721.178,
			1722.688,
			1724.208,
			1726.526,
			1728.068,
			1730.394,
			1731.169,
			1731.944,
			1734.264,
			1735.8,
			1738.095,
			1739.619,
			1741.131,
			1741.885,
			1743.392,
			1744.145,
			1744.896,
			1747.14,
			1747.887,
			1748.635,
			1750.888,
			1752.387,
			1753.887,
			1754.65,
			1756.176,
			1757.698,
			1758.459,
			1759.996,
			1760.768,
			1762.307,
			1763.845,
			1766.17,
			1767.716,
			1770.037,
			1770.813,
			1771.587,
			1773.91,
			1775.467,
			1777.798,
			1779.353,
			1781.695,
			1783.258,
			1784.042,
			1785.603,
			1787.165,
			1787.947,
			1789.512,
			1791.064,
			1792.619,
			1794.179,
			1794.965,
			1796.541,
			1798.122,
			1798.916,
			1800.528,
			1801.341,
			1802.971,
			1804.613,
			1807.133,
			1808.825,
			1811.446,
			1812.333,
			1813.223,
			1815.962,
			1817.838,
			1820.678,
			1822.583,
			1824.499,
			1825.434,
			1827.298,
			1828.233,
			1830.097,
			1831.919,
			1832.832,
			1834.664,
			1836.469,
			1839.184,
			1840.082,
			1840.964,
			1843.615,
			1844.501,
			1845.38,
			1848.005,
			1849.758,
			1851.5,
			1852.371,
			1854.111,
			1855.852,
			1858.459,
			1859.328,
			1860.198,
			1862.82,
			1864.568,
			1867.192,
			1868.944,
			1870.702,
			1871.584,
			1873.354,
			1875.136,
			1876.032,
			1877.832,
			1878.737,
			1880.555,
			1882.383,
			1885.133,
			1886.993,
			1889.805,
			1890.752,
			1891.705,
			1894.584,
			1896.527,
			1899.471,
			1901.45,
			1904.441,
			1906.45,
			1907.458,
			1908.469,
			1911.519,
			1912.541,
			1913.566,
			1916.656,
			1918.731,
			1920.817,
			1921.864,
			1923.961,
			1926.056,
			1927.105,
			1929.209,
			1930.265,
			1932.383,
			1934.51,
			1937.719,
			1939.868,
			1943.104,
			1944.187,
			1945.272,
			1948.54,
			1950.731,
			1954.034,
			1956.249,
			1958.473,
			1959.589,
			1961.827,
			1962.949,
			1965.2,
			1968.61,
			1970.881,
			1973.159,
			1975.444,
			1977.732,
			1978.876,
			1981.169,
			1983.453,
			1984.596,
			1986.878,
			1988.015,
			1990.289,
			1992.561,
			1995.966,
			1998.298,
			2001.842,
			2003.108,
			2004.374,
			2008.227,
			2010.848,
			2014.702,
			2017.251,
			2021.028,
			2023.527,
			2024.775,
			2027.252,
			2029.611,
			2030.789,
			2033.029,
			2035.193,
			2038.388,
			2039.435,
			2040.493,
			2043.744,
			2044.863,
			2045.954,
			2048.48,
			2049.829,
			2052.521,
			2056.414,
			2058.738,
			2061.025,
			2064.334,
			2066.514,
			2069.535,
			2071.525,
			2074.439,
			2076.378,
			2078.313,
			2079.292,
			2081.257,
			2083.232,
			2084.224,
			2086.213,
			2087.208,
			2089.199,
			2091.182,
			2094.129,
			2096.073,
			2098.978,
			2099.933,
			2100.88,
			2103.692,
			2105.554,
			2108.308,
			2110.16,
			2112.01,
			2114.838,
			2115.78,
			2116.723,
			2119.565,
			2120.515,
			2121.464,
			2124.332,
			2126.254,
			2128.177,
			2129.141,
			2131.068,
			2132.995,
			2133.942,
			2135.835,
			2136.781,
			2138.67,
			2140.549,
			2143.366,
			2145.248,
			2148.067,
			2149.013,
			2149.96,
			2152.798,
			2154.71,
			2157.589,
			2159.511,
			2161.434,
			2162.396,
			2164.321,
			2165.285,
			2167.211,
			2169.14,
			2170.104,
			2172.035,
			2173.969,
			2176.886,
			2177.86,
			2178.836,
			2181.771,
			2182.75,
			2183.73,
			2185.681,
			2186.657,
			2188.609,
			2190.547,
			2193.453,
			2195.374,
			2198.248,
			2199.206,
			2200.147,
			2202.979,
			2204.868,
			2207.691,
			2209.588,
			2212.449,
			2214.371,
			2215.338,
			2217.28,
			2219.234,
			2220.217,
			2222.19,
			2224.175,
			2227.189,
			2228.201,
			2229.216,
			2232.284,
			2233.312,
			2234.344,
			2236.419,
			2237.461,
			2239.552,
			2242.702,
			2244.808,
			2246.908,
			2250.104,
			2251.175,
			2252.248,
			2255.48,
			2257.646,
			2260.915,
			2263.105,
			2265.305,
			2266.409,
			2268.623,
			2270.848,
			2271.963,
			2274.202,
			2275.325,
			2277.579,
			2279.843,
			2283.256,
			2285.544,
			2288.995,
			2290.15,
			2291.308,
			2294.797,
			2297.135,
			2300.66,
			2303.022,
			2305.396,
			2306.585,
			2308.968,
			2310.164,
			2311.361,
			2314.965,
			2316.171,
			2317.381,
			2321.025,
			2323.468,
			2325.922,
			2327.153,
			2329.621,
			2332.099,
			2333.342,
			2337.083,
			2339.587,
			2342.1,
			2345.886,
			2348.421,
			2352.239,
			2353.515,
			2354.794,
			2358.642,
			2361.217,
			2365.096,
			2367.691,
			2370.294,
			2371.598,
			2374.208,
			2375.513,
			2378.126,
			2380.747,
			2382.061,
			2384.692,
			2387.33,
			2389.976,
			2392.628,
			2393.957,
			2396.62,
			2399.29,
			2400.627,
			2403.307,
			2404.65,
			2407.34,
			2410.038,
			2414.096,
			2416.811,
			2420.895,
			2422.26,
			2423.626,
			2427.736,
			2430.483,
			2434.618,
			2437.382,
			2441.54,
			2444.321,
			2445.714,
			2448.504,
			2451.301,
			2452.702,
			2455.51,
			2458.323,
			2462.556,
			2463.97,
			2465.385,
			2469.642,
			2471.063,
			2472.487,
			2476.768,
			2479.63,
			2483.934,
			2486.81,
			2489.694,
			2494.031,
			2495.479,
			2496.929,
			2501.289,
			2504.203,
			2508.584,
			2511.513,
			2514.448,
			2515.918,
			2518.862,
			2521.812,
			2523.289,
			2527.729,
			2530.697,
			2533.671,
			2538.141,
			2541.129,
			2545.623,
			2547.124,
			2548.626,
			2553.144,
			2556.163,
			2560.701,
			2563.732,
			2566.769,
			2571.333,
			2572.856,
			2575.907,
			2578.964,
			2580.494,
			2582.026,
			2586.63,
			2589.706,
			2594.329,
			2597.417,
			2600.511,
			2602.06,
			2605.162,
			2606.715,
			2609.824,
			2612.939,
			2617.619,
			2620.746,
			2625.444,
			2627.013,
			2628.583,
			2633.3,
			2636.451,
			2641.176,
			2644.313,
			2647.427,
			2648.974,
			2652.04,
			2653.559,
			2656.568,
			2659.537,
			2661.007,
			2663.918,
			2666.79,
			2671.025,
			2672.417,
			2673.8,
			2676.536,
			2679.234,
			2680.569,
			2683.21,
			2684.516,
			2687.1,
			2689.646,
			2693.393,
			2695.844,
			2699.45,
			2700.634,
			2701.81,
			2705.284,
			2707.554,
			2710.888,
			2713.065,
			2715.205,
			2716.277,
			2718.36,
			2719.372,
			2721.412,
			2723.398,
			2724.378,
			2726.292,
			2728.187,
			2730.982,
			2731.893,
			2732.796,
			2735.463,
			2736.34,
			2737.21,
			2738.929,
			2739.78,
			2741.474,
			2743.149,
			2745.64,
			2747.303,
			2749.794,
			2750.637,
			2751.49,
			2754.052,
			2754.906,
			2755.778,
			2758.419,
			2760.183,
			2761.977,
			2762.875,
			2764.675,
			2766.488,
			2767.398,
			2770.132,
			2771.951,
			2773.773,
			2776.5,
			2778.301,
			2781.014,
			2781.909,
			2782.806,
			2785.513,
			2787.311,
			2790.025,
			2791.839,
			2793.656,
			2794.569,
			2796.404,
			2797.324,
			2798.248,
			2801.038,
			2801.976,
			2802.917,
			2805.758,
			2807.67,
			2809.578,
			2810.545,
			2812.489,
			2814.444,
			2815.427,
			2817.401,
			2818.392,
			2820.384,
			2822.389,
			2825.411,
			2827.429,
			2830.443,
			2831.441,
			2832.434,
			2835.381,
			2837.34,
			2840.233,
			2842.154,
			2844.066,
			2845.021,
			2846.927,
			2847.881,
			2849.784,
			2851.681,
			2852.646,
			2854.586,
			2856.518,
			2858.505,
			2861.505,
			2863.601,
			2865.742,
			2866.811,
			2870.281,
			2872.595,
			2874.793,
			2878,
			2879.949,
			2882.873,
			2883.843,
			2884.811,
			2887.713,
			2889.643,
			2892.533,
			2894.358,
			2896.172,
			2897.078,
			2898.845,
			2899.716,
			2901.456,
			2903.201,
			2904.081,
			2905.841,
			2907.607,
			2910.31,
			2912.128,
			2914.896,
			2915.827,
			2916.761,
			2919.594,
			2921.502,
			2923.424,
			2926.332,
			2928.281,
			2931.243,
			2932.237,
			2933.216,
			2936.238,
			2938.246,
			2941.271,
			2943.299,
			2945.335,
			2946.356,
			2948.4,
			2950.45,
			2951.48,
			2953.546,
			2954.582,
			2956.642,
			2958.745,
			2961.854,
			2963.942,
			2967.091,
			2968.145,
			2969.202,
			2972.386,
			2974.52,
			2977.739,
			2979.897,
			2982.063,
			2983.149,
			2985.33,
			2986.423,
			2987.519,
			2990.822,
			2991.928,
			2993.036,
			2996.375,
			2998.614,
			3000.863,
			3001.99,
			3004.253,
			3006.525,
			3007.665,
			3009.951,
			3011.097,
			3013.398,
			3015.709,
			3019.195,
			3021.531,
			3025.053,
			3026.232,
			3027.414,
			3030.975,
			3033.361,
			3036.959,
			3039.369,
			3042.994,
			3045.408,
			3046.612,
			3049.007,
			3051.38,
			3052.557,
			3054.886,
			3057.18,
			3059.437,
			3062.753,
			3064.918,
			3067.049,
			3068.101,
			3070.197,
			3071.223,
			3073.25,
			3075.244,
			3078.186,
			3080.119,
			3082.993,
			3083.946,
			3084.898,
			3087.753,
			3088.704,
			3089.653,
			3092.499,
			3094.398,
			3096.3,
			3097.251,
			3099.163,
			3100.138,
			3102.091,
			3104.047,
			3105.059,
			3107.085,
			3109.114,
			3112.232,
			3113.273,
			3114.316,
			3117.5,
			3118.562,
			3119.629,
			3121.795,
			3122.879,
			3125.054,
			3127.251,
			3130.572,
			3132.828,
			3136.215,
			3137.347,
			3138.483,
			3141.858,
			3144.098,
			3147.351,
			3149.505,
			3151.527,
			3152.526,
			3154.53,
			3155.519,
			3157.472,
			3160.421,
			3162.41,
			3164.409,
			3167.445,
			3169.485,
			3172.582,
			3173.621,
			3174.663,
			3177.817,
			3179.937,
			3183.14,
			3185.289,
			3187.444,
			3188.525,
			3190.693,
			3191.782,
			3192.875,
			3196.168,
			3197.277,
			3198.37,
			3201.74,
			3203.992,
			3206.234,
			3207.368,
			3209.644,
			3211.948,
			3213.094,
			3215.375,
			3216.528,
			3218.86,
			3221.182,
			3224.664,
			3227.009,
			3230.551,
			3231.727,
			3232.886,
			3236.428,
			3238.791,
			3242.322,
			3244.656,
			3246.967,
			3248.112,
			3250.38,
			3251.501,
			3253.714,
			3255.892,
			3256.967,
			3259.089,
			3261.175,
			3264.233,
			3266.226,
			3268.182,
			3270.099,
			3271.042,
			3273.82,
			3275.621,
			3277.388,
			3279.97,
			3281.641,
			3284.08,
			3284.886,
			3285.67,
			3287.975,
			3289.474,
			3291.683,
			3293.134,
			3295.294,
			3296.725,
			3297.438,
			3298.859,
			3300.274,
			3300.978,
			3302.38,
			3303.775,
			3305.87,
			3307.26,
			3308.645,
			3310.042,
			3310.755,
			3312.887,
			3314.305,
			3315.766,
			3317.971,
			3319.44,
			3321.689,
			3322.439,
			3323.189,
			3325.441,
			3326.941,
			3329.186,
			3330.683,
			3332.175,
			3332.919,
			3334.406,
			3335.896,
			3336.64,
			3338.126,
			3338.869,
			3340.356,
			3341.854,
			3344.098,
			3344.847,
			3345.601,
			3347.888,
			3348.65,
			3349.414,
			3351.749,
			3353.33,
			3355.715,
			3357.339,
			3358.968,
			3359.785,
			3361.423,
			3362.245,
			3363.067,
			3365.547,
			3366.373,
			3367.199,
			3369.688,
			3371.349,
			3372.989,
			3373.813,
			3375.469,
			3377.067,
			3377.851,
			3379.427,
			3380.22,
			3381.771,
			3383.282,
			3385.572,
			3387.126,
			3389.493,
			3390.286,
			3391.083,
			3394.372,
			3395.202,
			3397.738,
			3399.459,
			3401.194,
			3402.073,
			3403.845,
			3404.736,
			3405.629,
			3408.332,
			3409.239,
			3411.063,
			3412.903,
			3414.74,
			3417.561,
			3419.437,
			3421.341,
			3422.302,
			3424.237,
			3425.21,
			3427.164,
			3429.13,
			3432.103,
			3434.091,
			3437.094,
			3438.096,
			3439.101,
			3442.131,
			3444.165,
			3447.235,
			3449.296,
			3451.367,
			3452.407,
			3454.495,
			3455.542,
			3457.634,
			3459.728,
			3460.778,
			3462.886,
			3465.004,
			3468.2,
			3469.271,
			3470.345,
			3473.582,
			3474.668,
			3475.758,
			3479.043,
			3481.25,
			3483.467,
			3486.807,
			3489.044,
			3492.412,
			3493.539,
			3494.668,
			3498.062,
			3500.336,
			3503.768,
			3506.069,
			3508.384,
			3509.544,
			3511.867,
			3514.197,
			3515.364,
			3517.704,
			3518.877,
			3521.232,
			3523.597,
			3527.164,
			3528.359,
			3529.558,
			3533.168,
			3534.378,
			3535.59,
			3539.238,
			3541.679,
			3545.354,
			3547.815,
			3550.282,
			3554,
			3555.244,
			3556.49,
			3561.498,
			3562.756,
			3566.541,
			3569.075,
			3572.894,
			3575.45,
			3578.017,
			3579.304,
			3581.882,
			3583.175,
			3585.768,
			3588.368,
			3592.279,
			3594.889,
			3598.815,
			3600.127,
			3601.442,
			3605.395,
			3608.04,
			3612.021,
			3614.684,
			3618.693,
			3621.374,
			3622.717,
			3625.409,
			3628.108,
			3629.46,
			3632.167,
			3634.883,
			3637.607,
			3640.337,
			3641.705,
			3644.446,
			3646.7
		],
		[
			0,
			0.036,
			0.081,
			0.143,
			0.206,
			0.251,
			0.329,
			0.36,
			0.391,
			0.453,
			0.516,
			0.579,
			0.639,
			0.702,
			0.764,
			0.795,
			0.824,
			0.888,
			0.902,
			0.95,
			1.013,
			1.074,
			1.119,
			1.137,
			1.197,
			1.228,
			1.259,
			1.322,
			1.384,
			1.447,
			1.506,
			1.571,
			1.632,
			1.664,
			1.694,
			1.772,
			1.818,
			1.881,
			1.941,
			1.993,
			2.005,
			2.064,
			2.096,
			2.128,
			2.206,
			2.253,
			2.314,
			2.376,
			2.436,
			2.5,
			2.53,
			2.561,
			2.624,
			2.686,
			2.748,
			2.81,
			2.856,
			2.87,
			2.935,
			2.964,
			2.996,
			3.058,
			3.073,
			3.12,
			3.182,
			3.245,
			3.289,
			3.306,
			3.368,
			3.4,
			3.43,
			3.493,
			3.509,
			3.553,
			3.616,
			3.678,
			3.725,
			3.802,
			3.834,
			3.865,
			3.926,
			3.989,
			4.053,
			4.112,
			4.158,
			4.174,
			4.237,
			4.268,
			4.297,
			4.377,
			4.423,
			4.484,
			4.546,
			4.606,
			4.671,
			4.7,
			4.731,
			4.795,
			4.857,
			4.919,
			4.981,
			5.027,
			5.103,
			5.137,
			5.165,
			5.227,
			5.245,
			5.29,
			5.354,
			5.414,
			5.461,
			5.475,
			5.538,
			5.569,
			5.599,
			5.663,
			5.679,
			5.727,
			5.788,
			5.848,
			5.91,
			5.971,
			6.003,
			6.033,
			6.096,
			6.156,
			6.22,
			6.283,
			6.328,
			6.346,
			6.406,
			6.438,
			6.467,
			6.532,
			6.546,
			6.593,
			6.655,
			6.717,
			6.764,
			6.778,
			6.839,
			6.872,
			6.903,
			6.965,
			6.979,
			7.026,
			7.09,
			7.15,
			7.197,
			7.275,
			7.306,
			7.337,
			7.397,
			7.46,
			7.524,
			7.586,
			7.648,
			7.706,
			7.739,
			7.769,
			7.848,
			7.895,
			7.956,
			8.02,
			8.065,
			8.079,
			8.141,
			8.175,
			8.206,
			8.267,
			8.282,
			8.328,
			8.39,
			8.452,
			8.499,
			8.577,
			8.608,
			8.639,
			8.7,
			8.762,
			8.825,
			8.887,
			8.932,
			8.95,
			9.011,
			9.04,
			9.072,
			9.134,
			9.151,
			9.197,
			9.259,
			9.32,
			9.368,
			9.382,
			9.446,
			9.474,
			9.509,
			9.568,
			9.585,
			9.631,
			9.694,
			9.755,
			9.802,
			9.878,
			9.91,
			9.941,
			10.003,
			10.065,
			10.127,
			10.189,
			10.241,
			10.25,
			10.314,
			10.345,
			10.374,
			10.436,
			10.453,
			10.499,
			10.562,
			10.624,
			10.669,
			10.686,
			10.746,
			10.779,
			10.808,
			10.872,
			10.934,
			10.996,
			11.057,
			11.106,
			11.12,
			11.18,
			11.212,
			11.244,
			11.322,
			11.367,
			11.431,
			11.49,
			11.553,
			11.615,
			11.646,
			11.678,
			11.739,
			11.755,
			11.802,
			11.865,
			11.923,
			11.972,
			12.048,
			12.081,
			12.112,
			12.172,
			12.237,
			12.297,
			12.358,
			12.406,
			12.422,
			12.482,
			12.513,
			12.545,
			12.607,
			12.622,
			12.67,
			12.731,
			12.794,
			12.839,
			12.855,
			12.919,
			12.949,
			12.98,
			13.056,
			13.103,
			13.166,
			13.227,
			13.272,
			13.352,
			13.383,
			13.414,
			13.49,
			13.537,
			13.598,
			13.662,
			13.708,
			13.728,
			13.786,
			13.817,
			13.847,
			13.91,
			13.923,
			13.972,
			14.035,
			14.096,
			14.143,
			14.156,
			14.22,
			14.251,
			14.282,
			14.345,
			14.407,
			14.468,
			14.529,
			14.577,
			14.653,
			14.683,
			14.717,
			14.775,
			14.84,
			14.901,
			14.964,
			15.009,
			15.027,
			15.087,
			15.12,
			15.15,
			15.211,
			15.228,
			15.275,
			15.337,
			15.399,
			15.461,
			15.521,
			15.553,
			15.585,
			15.662,
			15.709,
			15.77,
			15.833,
			15.877,
			15.895,
			15.956,
			15.987,
			16.017,
			16.081,
			16.096,
			16.14,
			16.206,
			16.267,
			16.314,
			16.328,
			16.389,
			16.423,
			16.453,
			16.513,
			16.531,
			16.577,
			16.639,
			16.7,
			16.748,
			16.823,
			16.855,
			16.887,
			16.948,
			17.011,
			17.073,
			17.133,
			17.197,
			17.256,
			17.29,
			17.32,
			17.383,
			17.397,
			17.445,
			17.505,
			17.567,
			17.614,
			17.631,
			17.691,
			17.722,
			17.755,
			17.815,
			17.833,
			17.878,
			17.939,
			18.002,
			18.048,
			18.124,
			18.157,
			18.188,
			18.252,
			18.312,
			18.374,
			18.436,
			18.487,
			18.497,
			18.562,
			18.591,
			18.621,
			18.685,
			18.7,
			18.748,
			18.806,
			18.871,
			18.917,
			18.931,
			18.994,
			19.024,
			19.056,
			19.118,
			19.133,
			19.182,
			19.242,
			19.306,
			19.352,
			19.428,
			19.46,
			19.492,
			19.553,
			19.613,
			19.677,
			19.738,
			19.785,
			19.8,
			19.862,
			19.894,
			19.924,
			19.987,
			20.001,
			20.049,
			20.111,
			20.173,
			20.219,
			20.233,
			20.297,
			20.328,
			20.359,
			20.421,
			20.481,
			20.549,
			20.606,
			20.653,
			20.731,
			20.762,
			20.791,
			20.856,
			20.871,
			20.916,
			20.979,
			21.04,
			21.086,
			21.103,
			21.165,
			21.196,
			21.227,
			21.29,
			21.304,
			21.351,
			21.415,
			21.475,
			21.522,
			21.598,
			21.63,
			21.661,
			21.724,
			21.786,
			21.847,
			21.91,
			21.955,
			21.972,
			22.032,
			22.065,
			22.095,
			22.156,
			22.172,
			22.218,
			22.282,
			22.341,
			22.388,
			22.405,
			22.468,
			22.497,
			22.529,
			22.591,
			22.611,
			22.653,
			22.717,
			22.778,
			22.824,
			22.902,
			22.933,
			22.964,
			23.039,
			23.088,
			23.149,
			23.212,
			23.256,
			23.273,
			23.335,
			23.368,
			23.396,
			23.459,
			23.475,
			23.521,
			23.584,
			23.645,
			23.693,
			23.706,
			23.771,
			23.802,
			23.832,
			23.907,
			23.956,
			24.019,
			24.079,
			24.126,
			24.203,
			24.234,
			24.264,
			24.328,
			24.39,
			24.453,
			24.514,
			24.56,
			24.576,
			24.638,
			24.674,
			24.699,
			24.778,
			24.822,
			24.885,
			24.947,
			24.995,
			25.072,
			25.103,
			25.134,
			25.196,
			25.256,
			25.321,
			25.381,
			25.445,
			25.506,
			25.537,
			25.569,
			25.63,
			25.691,
			25.755,
			25.815,
			25.861,
			25.879,
			25.938,
			25.97,
			26.002,
			26.063,
			26.078,
			26.127,
			26.189,
			26.249,
			26.313,
			26.372,
			26.405,
			26.437,
			26.497,
			26.56,
			26.623,
			26.685,
			26.735,
			26.747,
			26.806,
			26.84,
			26.871,
			26.933,
			26.947,
			26.994,
			27.057,
			27.117,
			27.164,
			27.18,
			27.241,
			27.273,
			27.303,
			27.367,
			27.382,
			27.428,
			27.491,
			27.552,
			27.598,
			27.676,
			27.707,
			27.739,
			27.799,
			27.861,
			27.924,
			27.985,
			28.033,
			28.047,
			28.11,
			28.141,
			28.172,
			28.233,
			28.25,
			28.295,
			28.357,
			28.421,
			28.465,
			28.483,
			28.545,
			28.573,
			28.605,
			28.683,
			28.732,
			28.796,
			28.853,
			28.9,
			28.979,
			29.01,
			29.041,
			29.102,
			29.164,
			29.226,
			29.29,
			29.335,
			29.351,
			29.412,
			29.444,
			29.473,
			29.536,
			29.553,
			29.6,
			29.662,
			29.724,
			29.769,
			29.784,
			29.847,
			29.879,
			29.907,
			29.97,
			30.033,
			30.099,
			30.156,
			30.203,
			30.281,
			30.312,
			30.341,
			30.404,
			30.467,
			30.527,
			30.589,
			30.637,
			30.652,
			30.714,
			30.746,
			30.778,
			30.839,
			30.86,
			30.9,
			30.961,
			31.023,
			31.07,
			31.147,
			31.179,
			31.211,
			31.289,
			31.333,
			31.396,
			31.458,
			31.522,
			31.583,
			31.614,
			31.645,
			31.707,
			31.721,
			31.77,
			31.83,
			31.894,
			31.956,
			32.017,
			32.048,
			32.079,
			32.139,
			32.157,
			32.203,
			32.265,
			32.325,
			32.373,
			32.452,
			32.483,
			32.513,
			32.59,
			32.638,
			32.699,
			32.761,
			32.806,
			32.824,
			32.884,
			32.92,
			32.947,
			33.01,
			33.024,
			33.071,
			33.133,
			33.195,
			33.243,
			33.256,
			33.318,
			33.35,
			33.382,
			33.442,
			33.459,
			33.506,
			33.568,
			33.63,
			33.69,
			33.753,
			33.783,
			33.816,
			33.878,
			33.938,
			34.002,
			34.062,
			34.108,
			34.127,
			34.186,
			34.219,
			34.25,
			34.312,
			34.328,
			34.373,
			34.437,
			34.496,
			34.544,
			34.56,
			34.622,
			34.652,
			34.683,
			34.746,
			34.808,
			34.871,
			34.932,
			34.985,
			35.055,
			35.087,
			35.117,
			35.18,
			35.195,
			35.242,
			35.303,
			35.365,
			35.413,
			35.429,
			35.489,
			35.519,
			35.55,
			35.612,
			35.63,
			35.674,
			35.738,
			35.8,
			35.845,
			35.924,
			35.953,
			35.986,
			36.047,
			36.11,
			36.174,
			36.234,
			36.295,
			36.356,
			36.388,
			36.42,
			36.498,
			36.543,
			36.606,
			36.667,
			36.714,
			36.73,
			36.789,
			36.823,
			36.853,
			36.917,
			36.931,
			36.978,
			37.039,
			37.103,
			37.147,
			37.227,
			37.256,
			37.287,
			37.364,
			37.412,
			37.473,
			37.534,
			37.581,
			37.597,
			37.66,
			37.689,
			37.722,
			37.783,
			37.8,
			37.847,
			37.909,
			37.969,
			38.018,
			38.032,
			38.094,
			38.124,
			38.156,
			38.219,
			38.235,
			38.281,
			38.343,
			38.403,
			38.45,
			38.529,
			38.56,
			38.59,
			38.669,
			38.714,
			38.777,
			38.837,
			38.884,
			38.901,
			38.962,
			38.993,
			39.023,
			39.085,
			39.108,
			39.149,
			39.211,
			39.271,
			39.32,
			39.397,
			39.427,
			39.459,
			39.52,
			39.583,
			39.645,
			39.705,
			39.752,
			39.829,
			39.862,
			39.891,
			39.953,
			39.971,
			40.017,
			40.078,
			40.14,
			40.187,
			40.202,
			40.263,
			40.297,
			40.327,
			40.387,
			40.404,
			40.451,
			40.512,
			40.574,
			40.62,
			40.698,
			40.73,
			40.762,
			40.823,
			40.885,
			40.948,
			41.009,
			41.055,
			41.072,
			41.133,
			41.167,
			41.194,
			41.256,
			41.272,
			41.318,
			41.381,
			41.441,
			41.49,
			41.504,
			41.568,
			41.599,
			41.628,
			41.69,
			41.707,
			41.753,
			41.814,
			41.877,
			41.922,
			42,
			42.031,
			42.063,
			42.124,
			42.188,
			42.25,
			42.311,
			42.372,
			42.434,
			42.466,
			42.498,
			42.559,
			42.573,
			42.621,
			42.682,
			42.744,
			42.79,
			42.808,
			42.868,
			42.9,
			42.931,
			42.994,
			43.009,
			43.055,
			43.117,
			43.179,
			43.23,
			43.302,
			43.334,
			43.364,
			43.427,
			43.49,
			43.551,
			43.614,
			43.66,
			43.674,
			43.738,
			43.768,
			43.8,
			43.861,
			43.877,
			43.922,
			43.985,
			44.047,
			44.094,
			44.107,
			44.17,
			44.202,
			44.232,
			44.295,
			44.358,
			44.419,
			44.422,
			44.482,
			44.528,
			44.604,
			44.635,
			44.668,
			44.73,
			44.789,
			44.853,
			44.914,
			44.962,
			44.978,
			45.039,
			45.071,
			45.1,
			45.162,
			45.179,
			45.223,
			45.292,
			45.35,
			45.396,
			45.473,
			45.504,
			45.535,
			45.597,
			45.66,
			45.723,
			45.784,
			45.845,
			45.908,
			45.937,
			45.969,
			46.031,
			46.046,
			46.094,
			46.156,
			46.216,
			46.265,
			46.278,
			46.341,
			46.372,
			46.402,
			46.464,
			46.48,
			46.529,
			46.591,
			46.65,
			46.697,
			46.774,
			46.806,
			46.838,
			46.899,
			46.962,
			47.025,
			47.087,
			47.131,
			47.149,
			47.21,
			47.241,
			47.271,
			47.352,
			47.395,
			47.456,
			47.518,
			47.567,
			47.581,
			47.644,
			47.674,
			47.705,
			47.767,
			47.782,
			47.828,
			47.89,
			47.953,
			48.015,
			48.079,
			48.106,
			48.139,
			48.202,
			48.265,
			48.324,
			48.386,
			48.45,
			48.513,
			48.543,
			48.573,
			48.636,
			48.652,
			48.697,
			48.761,
			48.823,
			48.867,
			48.883,
			48.946,
			48.978,
			49.008,
			49.069,
			49.085,
			49.133,
			49.195,
			49.255,
			49.303,
			49.379,
			49.44,
			49.519,
			49.567,
			49.628,
			49.69,
			49.737,
			49.753,
			49.813,
			49.845,
			49.876,
			49.938,
			49.953,
			50,
			50.063,
			50.124,
			50.17,
			50.249,
			50.278,
			50.31,
			50.373,
			50.435,
			50.496,
			50.559,
			50.605,
			50.683,
			50.714,
			50.744,
			50.806,
			50.82,
			50.867,
			50.929,
			50.993,
			51.038,
			51.053,
			51.115,
			51.147,
			51.178,
			51.241,
			51.256,
			51.302,
			51.364,
			51.428,
			51.488,
			51.55,
			51.581,
			51.612,
			51.673,
			51.737,
			51.8,
			51.861,
			51.906,
			51.922,
			51.984,
			52.016,
			52.047,
			52.106,
			52.124,
			52.171,
			52.233,
			52.296,
			52.341,
			52.358,
			52.42,
			52.45,
			52.481,
			52.541,
			52.558,
			52.606,
			52.666,
			52.729,
			52.775,
			52.853,
			52.883,
			52.914,
			52.991,
			53.039,
			53.1,
			53.163,
			53.223,
			53.286,
			53.316,
			53.35,
			53.411,
			53.427,
			53.473,
			53.54,
			53.597,
			53.642,
			53.66,
			53.722,
			53.751,
			53.784,
			53.844,
			53.858,
			53.908,
			53.97,
			54.03,
			54.078,
			54.156,
			54.184,
			54.215,
			54.278,
			54.34,
			54.403,
			54.464,
			54.512,
			54.524,
			54.589,
			54.619,
			54.652,
			54.713,
			54.728,
			54.774,
			54.837,
			54.9,
			54.945,
			55.023,
			55.055,
			55.084,
			55.146,
			55.21,
			55.272,
			55.332,
			55.381,
			55.395,
			55.456,
			55.488,
			55.519,
			55.582,
			55.601,
			55.643,
			55.706,
			55.768,
			55.813,
			55.829,
			55.892,
			55.921,
			55.952,
			56.03,
			56.077,
			56.139,
			56.202,
			56.246,
			56.324,
			56.356,
			56.387,
			56.464,
			56.511,
			56.573,
			56.635,
			56.681,
			56.698,
			56.76,
			56.789,
			56.821,
			56.884,
			56.899,
			56.945,
			57.006,
			57.069,
			57.116,
			57.131,
			57.191,
			57.222,
			57.255,
			57.318,
			57.333,
			57.378,
			57.441,
			57.504,
			57.549,
			57.628,
			57.663,
			57.689,
			57.751,
			57.815,
			57.877,
			57.939,
			57.999,
			58.062,
			58.094,
			58.124,
			58.186,
			58.2,
			58.248,
			58.309,
			58.372,
			58.418,
			58.433,
			58.497,
			58.524,
			58.556,
			58.621,
			58.681,
			58.745,
			58.804,
			58.851,
			58.929,
			58.961,
			58.992,
			59.053,
			59.116,
			59.178,
			59.239,
			59.285,
			59.302,
			59.365,
			59.396,
			59.425,
			59.503,
			59.55,
			59.613,
			59.673,
			59.735,
			59.797,
			59.83,
			59.861,
			59.936,
			59.983,
			60.045,
			60.108,
			60.154,
			60.232,
			60.263,
			60.295,
			60.357,
			60.371,
			60.417,
			60.483,
			60.54,
			60.603,
			60.665,
			60.697,
			60.729,
			60.789,
			60.806,
			60.851,
			60.913,
			60.977,
			61.039,
			61.099,
			61.131,
			61.162,
			61.224,
			61.287,
			61.347,
			61.408,
			61.457,
			61.471,
			61.533,
			61.565,
			61.597,
			61.658,
			61.674,
			61.721,
			61.787,
			61.845,
			61.892,
			61.906,
			61.968,
			61.999,
			62.03,
			62.092,
			62.106,
			62.153,
			62.218,
			62.278,
			62.324,
			62.401,
			62.432,
			62.464,
			62.524,
			62.589,
			62.649,
			62.712,
			62.757,
			62.774,
			62.836,
			62.868,
			62.899,
			62.958,
			62.976,
			63.023,
			63.084,
			63.147,
			63.193,
			63.208,
			63.271,
			63.302,
			63.331,
			63.394,
			63.411,
			63.456,
			63.518,
			63.581,
			63.627,
			63.705,
			63.735,
			63.766,
			63.83,
			63.847,
			63.89,
			63.953,
			64.013,
			64.062,
			64.077,
			64.138,
			64.171,
			64.2,
			64.279,
			64.325,
			64.387,
			64.449,
			64.508,
			64.571,
			64.603,
			64.635,
			64.697,
			64.757,
			64.82,
			64.883,
			64.93,
			64.945,
			65.006,
			65.038,
			65.067,
			65.131,
			65.145,
			65.191,
			65.256,
			65.318,
			65.362,
			65.378,
			65.44,
			65.472,
			65.504,
			65.565,
			65.58,
			65.627,
			65.688,
			65.751,
			65.797,
			65.875,
			65.912,
			65.938,
			66,
			66.061,
			66.122,
			66.185,
			66.23,
			66.247,
			66.31,
			66.34,
			66.371,
			66.433,
			66.449,
			66.495,
			66.558,
			66.619,
			66.68,
			66.743,
			66.774,
			66.806,
			66.882,
			66.929,
			66.992,
			67.052,
			67.098,
			67.178,
			67.206,
			67.238,
			67.3,
			67.363,
			67.426,
			67.487,
			67.533,
			67.549,
			67.612,
			67.641,
			67.672,
			67.734,
			67.751,
			67.797,
			67.857,
			67.922,
			67.972,
			68.046,
			68.077,
			68.106,
			68.17,
			68.23,
			68.292,
			68.355,
			68.401,
			68.481,
			68.511,
			68.541,
			68.618,
			68.664,
			68.728,
			68.79,
			68.835,
			68.85,
			68.914,
			68.945,
			68.973,
			69.036,
			69.054,
			69.1,
			69.163,
			69.224,
			69.286,
			69.349,
			69.38,
			69.409,
			69.472,
			69.532,
			69.596,
			69.658,
			69.703,
			69.721,
			69.78,
			69.814,
			69.843,
			69.906,
			69.921,
			69.968,
			70.029,
			70.089,
			70.138,
			70.155,
			70.215,
			70.246,
			70.279,
			70.339,
			70.354,
			70.402,
			70.464,
			70.526,
			70.588,
			70.648,
			70.682,
			70.711,
			70.772,
			70.835,
			70.896,
			70.958,
			71.022,
			71.085,
			71.114,
			71.146,
			71.207,
			71.224,
			71.271,
			71.332,
			71.394,
			71.454,
			71.518,
			71.549,
			71.581,
			71.641,
			71.658,
			71.705,
			71.766,
			71.828,
			71.89,
			71.952,
			71.984,
			72.015,
			72.077,
			72.098,
			72.137,
			72.202,
			72.262,
			72.306,
			72.324,
			72.385,
			72.417,
			72.449,
			72.51,
			72.524,
			72.571,
			72.636,
			72.697,
			72.744,
			72.758,
			72.82,
			72.852,
			72.881,
			72.944,
			72.96,
			73.005,
			73.068,
			73.129,
			73.174,
			73.253,
			73.286,
			73.315,
			73.378,
			73.44,
			73.504,
			73.564,
			73.611,
			73.626,
			73.689,
			73.718,
			73.751,
			73.828,
			73.872,
			73.939,
			73.998,
			74.045,
			74.122,
			74.156,
			74.185,
			74.246,
			74.308,
			74.37,
			74.433,
			74.479,
			74.495,
			74.555,
			74.586,
			74.618,
			74.681,
			74.696,
			74.741,
			74.805,
			74.868,
			74.913,
			74.928,
			74.991,
			75.022,
			75.053,
			75.114,
			75.131,
			75.176,
			75.239,
			75.301,
			75.362,
			75.423,
			75.455,
			75.486,
			75.548,
			75.61,
			75.674,
			75.733,
			75.78,
			75.797,
			75.859,
			75.89,
			75.921,
			75.982,
			75.999,
			76.046,
			76.106,
			76.17,
			76.231,
			76.291,
			76.325,
			76.355,
			76.432,
			76.48,
			76.542,
			76.603,
			76.666,
			76.727,
			76.757,
			76.789,
			76.867,
			76.912,
			76.975,
			77.036,
			77.098,
			77.158,
			77.19,
			77.222,
			77.283,
			77.3,
			77.348,
			77.408,
			77.471,
			77.533,
			77.594,
			77.624,
			77.655,
			77.736,
			77.782,
			77.843,
			77.904,
			77.952,
			78.028,
			78.057,
			78.09,
			78.153,
			78.216,
			78.281,
			78.34,
			78.386,
			78.4,
			78.462,
			78.496,
			78.524,
			78.588,
			78.602,
			78.648,
			78.711,
			78.774,
			78.819,
			78.835,
			78.896,
			78.928,
			78.96,
			79.02,
			79.083,
			79.147,
			79.206,
			79.253,
			79.27,
			79.331,
			79.362,
			79.393,
			79.456,
			79.47,
			79.517,
			79.581,
			79.641,
			79.687,
			79.704,
			79.764,
			79.797,
			79.828,
			79.888,
			79.905,
			79.952,
			80.013,
			80.076,
			80.122,
			80.2,
			80.231,
			80.262,
			80.345,
			80.384,
			80.447,
			80.51,
			80.557,
			80.571,
			80.632,
			80.664,
			80.694,
			80.757,
			80.774,
			80.821,
			80.882,
			80.943,
			81.006,
			81.066,
			81.097,
			81.129,
			81.19,
			81.206,
			81.253,
			81.315,
			81.377,
			81.439,
			81.502,
			81.533,
			81.564,
			81.625,
			81.688,
			81.75,
			81.812,
			81.856,
			81.874,
			81.935,
			81.967,
			81.998,
			82.06,
			82.077,
			82.122,
			82.184,
			82.246,
			82.293,
			82.306,
			82.371,
			82.403,
			82.432,
			82.495,
			82.556,
			82.617,
			82.678,
			82.727,
			82.803,
			82.836,
			82.867,
			82.928,
			82.989,
			83.054,
			83.115,
			83.162,
			83.176,
			83.236,
			83.269,
			83.3,
			83.363,
			83.375,
			83.423,
			83.489,
			83.547,
			83.594,
			83.611,
			83.671,
			83.703,
			83.734,
			83.796,
			83.858,
			83.92,
			83.983,
			84.028,
			84.106,
			84.138,
			84.168,
			84.245,
			84.291,
			84.355,
			84.417,
			84.478,
			84.539,
			84.572,
			84.603,
			84.665,
			84.679,
			84.727,
			84.788,
			84.85,
			84.911,
			84.974,
			85.006,
			85.035,
			85.097,
			85.16,
			85.228,
			85.284,
			85.347,
			85.41,
			85.44,
			85.47,
			85.531,
			85.548,
			85.595,
			85.656,
			85.717,
			85.764,
			85.78,
			85.841,
			85.874
		]
	],
	'2383_7755_7982': [
		[
			61.557
		],
		[
			0
		],
		[
			0,
			1.245,
			2.596,
			6.66,
			9.376,
			12.099,
			16.194,
			18.933,
			23.054,
			24.431,
			25.81,
			29.957,
			32.729,
			36.898,
			39.686,
			42.48,
			43.881,
			46.686,
			49.498,
			50.906,
			53.727,
			55.141,
			57.972,
			60.808,
			65.073,
			67.922,
			72.21,
			73.643,
			75.078,
			79.394,
			82.28,
			86.622,
			89.525,
			92.433,
			93.889,
			96.805,
			98.265,
			99.727,
			104.119,
			105.586,
			107.054,
			111.466,
			114.416,
			118.854,
			121.82,
			124.792,
			126.281,
			130.755,
			133.745,
			136.741,
			141.245,
			144.254,
			148.78,
			150.291,
			151.804,
			156.351,
			159.388,
			163.957,
			167.009,
			170.066,
			171.597,
			174.662,
			176.196,
			179.269,
			183.889,
			186.976,
			190.069,
			194.717,
			197.823,
			200.934,
			204.05,
			205.61,
			210.298,
			213.431,
			216.567,
			221.281,
			224.43,
			229.163,
			230.743,
			232.325,
			237.078,
			240.253,
			245.025,
			248.209,
			251.396,
			252.991,
			256.184,
			257.782,
			260.983,
			264.187,
			265.791,
			269.002,
			272.218,
			277.051,
			278.663,
			280.278,
			283.51,
			286.746,
			288.367,
			293.234,
			296.484,
			301.368,
			304.628,
			307.889,
			312.765,
			314.381,
			315.991,
			320.772,
			323.921,
			328.581,
			331.642,
			336.167,
			339.138,
			340.609,
			343.522,
			346.397,
			347.821,
			350.639,
			353.42,
			357.52,
			360.205,
			364.163,
			365.464,
			366.756,
			370.575,
			373.075,
			376.755,
			379.184,
			381.557,
			382.73,
			385.052,
			386.199,
			388.464,
			391.794,
			392.885,
			396.105,
			398.201,
			400.264,
			401.279,
			403.281,
			405.247,
			406.221,
			408.151,
			409.107,
			411.016,
			412.951,
			415.838,
			417.801,
			420.744,
			421.75,
			422.755,
			425.777,
			427.91,
			431.125,
			433.352,
			435.578,
			436.649,
			438.775,
			439.838,
			441.9,
			443.915,
			444.924,
			446.923,
			448.911,
			450.902,
			452.878,
			453.868,
			455.855,
			457.825,
			458.813,
			460.798,
			461.777,
			463.74,
			465.696,
			468.649,
			470.618,
			473.556,
			474.525,
			475.485,
			478.339,
			480.2,
			482.954,
			484.756,
			486.529,
			487.409,
			489.139,
			490.832,
			491.678,
			493.349,
			494.17,
			495.79,
			497.434,
			499.874,
			500.682,
			501.513,
			503.988,
			504.81,
			505.63,
			507.256,
			508.065,
			509.676,
			512.068,
			513.643,
			515.211,
			517.558,
			518.338,
			519.115,
			521.444,
			522.228,
			523.01,
			525.342,
			526.9,
			528.459,
			529.235,
			530.784,
			532.321,
			533.084,
			534.601,
			535.358,
			536.857,
			538.332,
			540.537,
			541.978,
			544.092,
			544.792,
			545.49,
			547.572,
			548.946,
			550.999,
			552.373,
			554.425,
			555.79,
			556.475,
			557.161,
			559.215,
			559.899,
			560.583,
			562.644,
			564.018,
			565.393,
			566.083,
			567.463,
			568.842,
			569.532,
			570.916,
			571.61,
			572.997,
			574.382,
			576.462,
			577.855,
			579.935,
			580.628,
			581.321,
			583.406,
			584.791,
			586.87,
			588.261,
			589.651,
			590.346,
			591.742,
			592.444,
			593.849,
			595.256,
			595.962,
			597.384,
			598.812,
			600.962,
			601.684,
			602.412,
			603.871,
			605.336,
			606.072,
			607.562,
			608.312,
			609.818,
			612.102,
			613.65,
			615.207,
			617.572,
			618.367,
			619.166,
			621.58,
			623.222,
			625.706,
			627.386,
			629.084,
			629.937,
			631.651,
			632.51,
			634.234,
			636.836,
			638.593,
			640.355,
			643.026,
			644.816,
			647.505,
			648.405,
			649.306,
			651.11,
			652.013,
			653.824,
			655.633,
			658.349,
			660.158,
			662.871,
			663.774,
			664.677,
			667.381,
			668.281,
			669.18,
			671.874,
			673.667,
			675.459,
			676.355,
			678.14,
			679.921,
			680.812,
			682.587,
			683.468,
			685.23,
			686.995,
			689.594,
			690.462,
			691.332,
			693.917,
			694.777,
			695.64,
			698.242,
			699.991,
			702.636,
			704.423,
			706.223,
			708.949,
			709.865,
			711.69,
			713.546,
			714.478,
			715.415,
			718.243,
			720.147,
			723.024,
			724.956,
			726.9,
			727.877,
			729.84,
			730.826,
			732.808,
			734.805,
			737.822,
			739.849,
			742.911,
			743.937,
			744.967,
			748.073,
			750.157,
			753.302,
			755.401,
			757.501,
			758.553,
			760.665,
			761.725,
			763.851,
			767.059,
			769.209,
			771.369,
			773.539,
			775.719,
			776.813,
			779.008,
			781.212,
			782.318,
			784.536,
			785.649,
			787.882,
			790.123,
			793.503,
			795.768,
			799.182,
			800.325,
			801.471,
			804.92,
			807.231,
			810.715,
			813.049,
			815.393,
			816.568,
			818.925,
			820.106,
			822.477,
			824.857,
			826.05,
			828.443,
			830.845,
			834.464,
			835.675,
			836.888,
			840.54,
			841.761,
			842.985,
			846.669,
			849.136,
			851.612,
			855.34,
			857.837,
			861.596,
			862.854,
			864.113,
			867.903,
			870.44,
			874.26,
			876.817,
			879.38,
			880.665,
			883.237,
			884.524,
			887.095,
			889.652,
			890.923,
			893.444,
			895.931,
			899.59,
			901.98,
			905.494,
			906.647,
			907.789,
			911.16,
			913.359,
			916.587,
			918.692,
			920.759,
			923.79,
			924.782,
			925.765,
			928.657,
			929.602,
			930.539,
			933.296,
			935.09,
			936.849,
			937.715,
			939.42,
			941.086,
			941.906,
			943.517,
			944.308,
			945.862,
			947.384,
			949.637,
			951.103,
			953.283,
			954.007,
			954.731,
			956.895,
			958.335,
			960.495,
			961.934,
			964.09,
			965.534,
			966.255,
			966.976,
			969.136,
			969.86,
			970.589,
			972.77,
			974.952,
			976.422,
			977.886,
			979.346,
			980.082,
			982.291,
			983.76,
			985.234,
			987.451,
			988.927,
			991.159,
			991.905,
			992.65,
			994.889,
			996.403,
			998.669,
			1000.186,
			1001.717,
			1002.481,
			1004.008,
			1004.772,
			1006.33,
			1007.884,
			1008.659,
			1010.222,
			1011.809,
			1014.183,
			1014.98,
			1015.795,
			1018.23,
			1019.041,
			1019.854,
			1022.382,
			1024.063,
			1025.772,
			1028.361,
			1030.089,
			1032.667,
			1033.525,
			1034.384,
			1036.887,
			1038.541,
			1040.986,
			1042.585,
			1044.182,
			1044.982,
			1046.571,
			1048.156,
			1048.948,
			1050.534,
			1051.328,
			1052.913,
			1054.493,
			1056.863,
			1057.651,
			1058.437,
			1060.793,
			1061.577,
			1062.36,
			1064.701,
			1066.261,
			1068.594,
			1070.148,
			1071.703,
			1072.479,
			1074.031,
			1074.806,
			1075.58,
			1077.897,
			1078.668,
			1079.425,
			1081.738,
			1083.273,
			1084.815,
			1085.582,
			1087.111,
			1088.647,
			1089.419,
			1090.95,
			1091.712,
			1093.243,
			1094.786,
			1097.081,
			1098.62,
			1100.948,
			1101.727,
			1102.509,
			1104.875,
			1106.49,
			1108.944,
			1110.599,
			1113.133,
			1114.837,
			1115.697,
			1117.432,
			1119.18,
			1120.058,
			1120.942,
			1123.615,
			1125.413,
			1127.223,
			1128.133,
			1129.962,
			1131.799,
			1132.721,
			1134.57,
			1135.496,
			1137.342,
			1139.187,
			1141.922,
			1143.736,
			1146.406,
			1147.278,
			1148.14,
			1150.672,
			1152.312,
			1154.704,
			1156.256,
			1157.774,
			1158.52,
			1159.985,
			1160.706,
			1162.125,
			1163.509,
			1164.188,
			1165.524,
			1166.841,
			1168.764,
			1169.384,
			1169.995,
			1171.793,
			1172.376,
			1172.952,
			1174.631,
			1175.719,
			1176.786,
			1178.342,
			1179.357,
			1180.852,
			1181.339,
			1181.824,
			1183.267,
			1184.224,
			1185.658,
			1186.602,
			1188.013,
			1188.954,
			1189.896,
			1190.368,
			1191.368,
			1191.873,
			1192.884,
			1193.897,
			1195.426,
			1195.981,
			1196.548,
			1198.252,
			1198.823,
			1199.396,
			1201.135,
			1202.315,
			1204.093,
			1205.288,
			1206.483,
			1207.079,
			1208.274,
			1208.872,
			1209.472,
			1211.288,
			1211.899,
			1212.51,
			1214.339,
			1215.559,
			1216.804,
			1217.424,
			1218.66,
			1219.894,
			1220.51,
			1222.367,
			1223.593,
			1224.813,
			1226.62,
			1227.802,
			1229.565,
			1230.152,
			1230.738,
			1232.447,
			1233.579,
			1235.275,
			1236.413,
			1238.119,
			1239.256,
			1239.825,
			1240.978,
			1242.161,
			1242.752,
			1243.936,
			1245.124,
			1246.992,
			1248.264,
			1249.542,
			1250.835,
			1251.52,
			1252.893,
			1253.582,
			1254.966,
			1256.343,
			1258.382,
			1259.749,
			1261.765,
			1262.423,
			1263.082,
			1265.065,
			1266.376,
			1268.312,
			1269.606,
			1270.899,
			1271.515,
			1272.747,
			1273.364,
			1274.599,
			1275.84,
			1276.449,
			1277.669,
			1278.892,
			1280.738,
			1281.376,
			1282.019,
			1283.957,
			1284.607,
			1285.259,
			1286.584,
			1287.256,
			1288.605,
			1289.964,
			1292.033,
			1293.432,
			1295.553,
			1296.268,
			1296.987,
			1299.152,
			1300.623,
			1302.854,
			1304.362,
			1305.885,
			1306.649,
			1308.189,
			1308.964,
			1310.527,
			1312.105,
			1312.9,
			1314.5,
			1316.115,
			1318.565,
			1320.216,
			1322.72,
			1323.562,
			1324.406,
			1326.935,
			1328.628,
			1331.194,
			1332.92,
			1334.66,
			1337.293,
			1338.176,
			1339.063,
			1341.741,
			1342.639,
			1343.541,
			1346.264,
			1348.094,
			1349.937,
			1350.863,
			1352.724,
			1354.599,
			1355.54,
			1358.383,
			1360.294,
			1362.218,
			1365.125,
			1367.079,
			1370.033,
			1371.023,
			1372.016,
			1375.014,
			1377.027,
			1380.066,
			1382.106,
			1384.158,
			1385.188,
			1387.256,
			1388.295,
			1389.336,
			1393.522,
			1394.569,
			1397.716,
			1399.825,
			1401.944,
			1403.007,
			1405.14,
			1407.283,
			1408.358,
			1410.516,
			1411.599,
			1413.771,
			1417.047,
			1419.244,
			1421.451,
			1424.778,
			1425.89,
			1427.001,
			1430.323,
			1432.519,
			1435.758,
			1437.876,
			1440.976,
			1442.999,
			1443.998,
			1445.969,
			1448.881,
			1450.771,
			1452.629,
			1455.363,
			1457.153,
			1458.918,
			1460.669,
			1461.542,
			1464.154,
			1465.895,
			1467.636,
			1470.227,
			1471.949,
			1474.52,
			1475.374,
			1476.227,
			1478.792,
			1480.506,
			1483.079,
			1484.826,
			1486.565,
			1487.433,
			1489.217,
			1490.11,
			1491.89,
			1493.705,
			1494.626,
			1496.462,
			1498.299,
			1501.071,
			1501.991,
			1502.908,
			1505.606,
			1506.501,
			1507.396,
			1510.056,
			1511.828,
			1514.497,
			1516.284,
			1518.075,
			1520.743,
			1521.636,
			1522.532,
			1525.157,
			1526.032,
			1526.911,
			1529.535,
			1531.288,
			1533.053,
			1533.943,
			1535.732,
			1537.534,
			1538.44,
			1540.263,
			1541.163,
			1543.005,
			1544.858,
			1547.662,
			1549.546,
			1552.397,
			1553.353,
			1554.311,
			1557.192,
			1559.111,
			1561.975,
			1563.89,
			1565.781,
			1566.724,
			1568.604,
			1569.541,
			1571.409,
			1574.193,
			1575.115,
			1577.874,
			1579.693,
			1581.506,
			1582.411,
			1584.218,
			1586.02,
			1586.921,
			1589.649,
			1591.485,
			1593.321,
			1596.147,
			1598.031,
			1600.925,
			1601.894,
			1602.863,
			1605.868,
			1607.894,
			1610.965,
			1613.024,
			1615.038,
			1616.037,
			1618.037,
			1619.027,
			1620.971,
			1622.918,
			1623.885,
			1625.767,
			1627.649,
			1630.455,
			1632.318,
			1635.13,
			1636.074,
			1637.019,
			1638.909,
			1639.857,
			1641.757,
			1643.661,
			1646.534,
			1648.459,
			1651.387,
			1652.37,
			1653.355,
			1656.34,
			1658.345,
			1661.383,
			1663.423,
			1665.473,
			1666.502,
			1668.568,
			1669.604,
			1671.683,
			1673.771,
			1674.813,
			1676.895,
			1678.965,
			1682.111,
			1684.221,
			1687.405,
			1688.472,
			1689.54,
			1692.762,
			1694.921,
			1697.088,
			1700.356,
			1702.547,
			1705.85,
			1706.956,
			1708.064,
			1711.401,
			1713.637,
			1717.009,
			1719.268,
			1722.677,
			1724.962,
			1727.258,
			1728.409,
			1730.72,
			1731.879,
			1734.205,
			1736.542,
			1740.065,
			1742.426,
			1745.986,
			1747.177,
			1748.371,
			1751.966,
			1754.374,
			1758.001,
			1760.431,
			1762.869,
			1764.091,
			1766.543,
			1767.773,
			1770.236,
			1772.706,
			1773.941,
			1775.174,
			1778.85,
			1781.269,
			1784.835,
			1787.166,
			1789.459,
			1790.591,
			1792.828,
			1793.932,
			1796.113,
			1798.258,
			1801.409,
			1803.465,
			1806.499,
			1807.487,
			1808.466,
			1811.358,
			1813.25,
			1816.06,
			1817.913,
			1819.759,
			1820.68,
			1822.514,
			1823.428,
			1825.249,
			1827.063,
			1827.968,
			1829.773,
			1831.577,
			1834.276,
			1835.173,
			1836.067,
			1837.862,
			1839.651,
			1840.543,
			1842.337,
			1843.24,
			1845.042,
			1846.846,
			1849.623,
			1851.475,
			1854.348,
			1855.309,
			1856.271,
			1859.34,
			1860.376,
			1861.413,
			1864.595,
			1866.73,
			1868.719,
			1869.713,
			1871.706,
			1872.674,
			1874.602,
			1876.539,
			1877.514,
			1879.474,
			1881.445,
			1884.389,
			1886.361,
			1889.279,
			1890.25,
			1891.225,
			1894.136,
			1896.067,
			1898.024,
			1900.995,
			1902.981,
			1903.979,
			1905.985,
			1906.992,
			1908.003,
			1911.056,
			1913.092,
			1916.2,
			1918.287,
			1920.395,
			1921.439,
			1923.535,
			1925.624,
			1926.681,
			1928.802,
			1929.865,
			1932,
			1934.137,
			1937.357,
			1939.511,
			1942.758,
			1943.849,
			1944.943,
			1948.241,
			1950.461,
			1953.81,
			1956.059,
			1958.318,
			1959.452,
			1961.728,
			1962.87,
			1965.16,
			1967.46,
			1968.613,
			1970.928,
			1973.248,
			1975.567,
			1977.879,
			1979.029,
			1981.313,
			1983.57,
			1984.686,
			1987.979,
			1990.127,
			1992.239,
			1995.337,
			1997.356,
			2000.315,
			2001.283,
			2002.241,
			2005.06,
			2006.892,
			2009.575,
			2011.317,
			2013.023,
			2013.863,
			2015.527,
			2016.34,
			2017.946,
			2019.532,
			2020.319,
			2021.882,
			2023.437,
			2025.761,
			2027.304,
			2028.847,
			2030.376,
			2031.139,
			2032.662,
			2033.424,
			2034.945,
			2036.459,
			2037.214,
			2038.724,
			2040.258,
			2042.564,
			2043.33,
			2044.096,
			2046.48,
			2048.068,
			2050.471,
			2052.087,
			2053.699,
			2054.505,
			2056.124,
			2056.931,
			2058.544,
			2061.015,
			2062.68,
			2064.346,
			2066.982,
			2068.742,
			2070.518,
			2072.304,
			2073.196,
			2075.872,
			2077.651,
			2080.327,
			2082.126,
			2083.919,
			2086.561,
			2087.433,
			2088.302,
			2090.85,
			2092.494,
			2094.944,
			2096.519,
			2098.086,
			2098.867,
			2100.419,
			2101.95,
			2102.713,
			2104.235,
			2104.992,
			2106.468,
			2107.939,
			2110.142,
			2111.56,
			2113.684,
			2114.391,
			2115.099,
			2117.196,
			2118.59,
			2120.692,
			2122.128,
			2123.561,
			2125.718,
			2126.452,
			2127.918,
			2129.374,
			2130.109,
			2131.591,
			2133.085,
			2134.604,
			2136.124,
			2136.889,
			2138.417,
			2139.971,
			2140.753,
			2143.119,
			2144.714,
			2146.322,
			2148.762,
			2150.404,
			2152.893,
			2153.729,
			2154.568,
			2157.104,
			2158.811,
			2161.394,
			2163.129,
			2164.878,
			2165.757,
			2167.521,
			2168.408,
			2170.192,
			2171.99,
			2172.894,
			2174.713,
			2176.543,
			2179.311,
			2180.239,
			2181.171,
			2183.982,
			2184.926,
			2185.873,
			2187.779,
			2188.737,
			2190.664,
			2192.605,
			2195.542,
			2197.516,
			2200.502,
			2201.503,
			2202.507,
			2205.537,
			2207.573,
			2210.648,
			2212.712,
			2214.788,
			2215.83,
			2217.924,
			2218.975,
			2221.076,
			2224.232,
			2226.348,
			2228.474,
			2231.681,
			2232.755,
			2233.832,
			2237.078,
			2238.165,
			2239.255,
			2241.442,
			2242.539,
			2244.736,
			2248.04,
			2250.245,
			2252.459,
			2255.798,
			2256.916,
			2258.036,
			2261.414,
			2263.679,
			2267.094,
			2269.384,
			2272.838,
			2275.153,
			2277.479,
			2278.645,
			2280.985,
			2282.158,
			2284.513,
			2286.878,
			2290.443,
			2292.832,
			2296.434,
			2297.64,
			2298.847,
			2302.484,
			2304.921,
			2308.594,
			2311.054,
			2313.523,
			2314.76,
			2317.242,
			2318.487,
			2319.734,
			2323.489,
			2324.744,
			2327.262,
			2329.789,
			2332.324,
			2336.143,
			2338.7,
			2341.265,
			2342.55,
			2345.128,
			2346.42,
			2349.01,
			2351.614,
			2355.536,
			2358.153,
			2362.063,
			2363.367,
			2364.677,
			2368.625,
			2371.265,
			2375.237,
			2377.893,
			2380.56,
			2381.036
		],
		[
			0,
			0.017,
			0.047,
			0.11,
			0.172,
			0.233,
			0.297,
			0.342,
			0.419,
			0.452,
			0.483,
			0.558,
			0.607,
			0.667,
			0.731,
			0.775,
			0.792,
			0.854,
			0.89,
			0.915,
			0.976,
			0.994,
			1.038,
			1.103,
			1.165,
			1.212,
			1.289,
			1.32,
			1.35,
			1.412,
			1.474,
			1.537,
			1.598,
			1.645,
			1.661,
			1.721,
			1.753,
			1.784,
			1.847,
			1.862,
			1.909,
			1.974,
			2.033,
			2.094,
			2.155,
			2.188,
			2.219,
			2.296,
			2.342,
			2.409,
			2.466,
			2.513,
			2.591,
			2.621,
			2.651,
			2.73,
			2.775,
			2.839,
			2.9,
			2.953,
			2.962,
			3.024,
			3.054,
			3.086,
			3.163,
			3.21,
			3.272,
			3.335,
			3.396,
			3.457,
			3.49,
			3.52,
			3.598,
			3.646,
			3.705,
			3.769,
			3.814,
			3.891,
			3.923,
			3.954,
			4.017,
			4.079,
			4.139,
			4.203,
			4.247,
			4.265,
			4.327,
			4.357,
			4.388,
			4.452,
			4.467,
			4.513,
			4.575,
			4.637,
			4.682,
			4.699,
			4.759,
			4.792,
			4.822,
			4.899,
			4.946,
			5.015,
			5.07,
			5.118,
			5.196,
			5.224,
			5.257,
			5.318,
			5.382,
			5.442,
			5.505,
			5.567,
			5.628,
			5.659,
			5.692,
			5.754,
			5.767,
			5.813,
			5.875,
			5.938,
			5.986,
			6.062,
			6.093,
			6.125,
			6.187,
			6.249,
			6.312,
			6.371,
			6.418,
			6.434,
			6.497,
			6.528,
			6.559,
			6.635,
			6.683,
			6.745,
			6.805,
			6.853,
			6.87,
			6.93,
			6.962,
			6.993,
			7.054,
			7.076,
			7.118,
			7.179,
			7.242,
			7.303,
			7.364,
			7.397,
			7.426,
			7.503,
			7.552,
			7.614,
			7.676,
			7.72,
			7.737,
			7.798,
			7.831,
			7.86,
			7.924,
			7.939,
			7.985,
			8.046,
			8.109,
			8.156,
			8.172,
			8.234,
			8.265,
			8.296,
			8.358,
			8.372,
			8.418,
			8.481,
			8.543,
			8.591,
			8.667,
			8.699,
			8.73,
			8.792,
			8.852,
			8.914,
			8.978,
			9.022,
			9.04,
			9.102,
			9.137,
			9.164,
			9.225,
			9.241,
			9.288,
			9.348,
			9.411,
			9.457,
			9.474,
			9.536,
			9.565,
			9.597,
			9.659,
			9.673,
			9.72,
			9.784,
			9.844,
			9.893,
			9.968,
			9.999,
			10.03,
			10.094,
			10.11,
			10.155,
			10.219,
			10.279,
			10.325,
			10.342,
			10.403,
			10.436,
			10.464,
			10.528,
			10.542,
			10.589,
			10.651,
			10.714,
			10.776,
			10.839,
			10.87,
			10.899,
			10.963,
			11.024,
			11.085,
			11.147,
			11.21,
			11.271,
			11.302,
			11.333,
			11.396,
			11.411,
			11.456,
			11.519,
			11.582,
			11.628,
			11.642,
			11.705,
			11.735,
			11.768,
			11.83,
			11.845,
			11.893,
			11.955,
			12.016,
			12.062,
			12.139,
			12.171,
			12.202,
			12.265,
			12.325,
			12.389,
			12.449,
			12.496,
			12.512,
			12.573,
			12.605,
			12.635,
			12.696,
			12.714,
			12.76,
			12.824,
			12.884,
			12.93,
			12.946,
			13.009,
			13.04,
			13.07,
			13.133,
			13.147,
			13.193,
			13.261,
			13.318,
			13.364,
			13.443,
			13.474,
			13.505,
			13.567,
			13.629,
			13.691,
			13.752,
			13.797,
			13.814,
			13.876,
			13.909,
			13.937,
			14.017,
			14.063,
			14.123,
			14.185,
			14.249,
			14.31,
			14.34,
			14.371,
			14.433,
			14.451,
			14.496,
			14.557,
			14.621,
			14.667,
			14.744,
			14.774,
			14.805,
			14.867,
			14.885,
			14.931,
			14.992,
			15.054,
			15.1,
			15.117,
			15.179,
			15.21,
			15.239,
			15.303,
			15.32,
			15.363,
			15.424,
			15.488,
			15.534,
			15.551,
			15.613,
			15.642,
			15.673,
			15.736,
			15.798,
			15.86,
			15.923,
			15.97,
			16.048,
			16.076,
			16.11,
			16.17,
			16.185,
			16.233,
			16.296,
			16.356,
			16.417,
			16.48,
			16.512,
			16.542,
			16.604,
			16.62,
			16.668,
			16.73,
			16.79,
			16.836,
			16.913,
			16.945,
			16.976,
			17.039,
			17.1,
			17.163,
			17.225,
			17.27,
			17.286,
			17.349,
			17.384,
			17.41,
			17.488,
			17.535,
			17.597,
			17.657,
			17.706,
			17.72,
			17.783,
			17.813,
			17.846,
			17.905,
			17.922,
			17.969,
			18.031,
			18.093,
			18.154,
			18.217,
			18.248,
			18.279,
			18.342,
			18.403,
			18.465,
			18.526,
			18.574,
			18.59,
			18.65,
			18.681,
			18.713,
			18.774,
			18.791,
			18.837,
			18.901,
			18.962,
			19.006,
			19.023,
			19.086,
			19.117,
			19.148,
			19.21,
			19.27,
			19.333,
			19.396,
			19.457,
			19.52,
			19.549,
			19.581,
			19.643,
			19.705,
			19.767,
			19.83,
			19.875,
			19.89,
			19.952,
			19.983,
			20.016,
			20.077,
			20.093,
			20.14,
			20.202,
			20.264,
			20.324,
			20.388,
			20.417,
			20.45,
			20.511,
			20.574,
			20.636,
			20.697,
			20.744,
			20.822,
			20.851,
			20.884,
			20.947,
			20.96,
			21.008,
			21.068,
			21.131,
			21.178,
			21.193,
			21.257,
			21.287,
			21.316,
			21.38,
			21.395,
			21.441,
			21.509,
			21.565,
			21.612,
			21.69,
			21.72,
			21.752,
			21.83,
			21.874,
			21.937,
			22.001,
			22.06,
			22.123,
			22.154,
			22.184,
			22.246,
			22.263,
			22.309,
			22.373,
			22.435,
			22.496,
			22.556,
			22.587,
			22.619,
			22.696,
			22.743,
			22.807,
			22.869,
			22.913,
			22.993,
			23.023,
			23.054,
			23.115,
			23.177,
			23.24,
			23.302,
			23.348,
			23.364,
			23.425,
			23.458,
			23.487,
			23.549,
			23.568,
			23.612,
			23.675,
			23.736,
			23.782,
			23.798,
			23.86,
			23.89,
			23.92,
			24,
			24.046,
			24.109,
			24.169,
			24.217,
			24.293,
			24.325,
			24.357,
			24.432,
			24.48,
			24.541,
			24.603,
			24.649,
			24.667,
			24.729,
			24.76,
			24.79,
			24.852,
			24.869,
			24.914,
			24.979,
			25.039,
			25.084,
			25.101,
			25.162,
			25.193,
			25.224,
			25.286,
			25.347,
			25.41,
			25.473,
			25.518,
			25.535,
			25.596,
			25.63,
			25.659,
			25.72,
			25.736,
			25.783,
			25.844,
			25.905,
			25.952,
			25.967,
			26.032,
			26.062,
			26.091,
			26.155,
			26.169,
			26.216,
			26.279,
			26.339,
			26.387,
			26.463,
			26.496,
			26.527,
			26.602,
			26.651,
			26.71,
			26.774,
			26.835,
			26.897,
			26.93,
			26.961,
			27.022,
			27.039,
			27.084,
			27.147,
			27.209,
			27.255,
			27.271,
			27.334,
			27.365,
			27.395,
			27.458,
			27.472,
			27.519,
			27.581,
			27.642,
			27.692,
			27.767,
			27.797,
			27.829,
			27.905,
			27.952,
			28.015,
			28.075,
			28.124,
			28.14,
			28.2,
			28.23,
			28.263,
			28.325,
			28.339,
			28.386,
			28.45,
			28.51,
			28.558,
			28.572,
			28.635,
			28.666,
			28.696,
			28.76,
			28.82,
			28.881,
			28.944,
			28.991,
			29.067,
			29.099,
			29.13,
			29.209,
			29.254,
			29.317,
			29.379,
			29.441,
			29.503,
			29.534,
			29.565,
			29.625,
			29.643,
			29.688,
			29.755,
			29.813,
			29.859,
			29.875,
			29.937,
			29.968,
			30,
			30.062,
			30.123,
			30.189,
			30.248,
			30.292,
			30.31,
			30.372,
			30.402,
			30.434,
			30.496,
			30.51,
			30.558,
			30.618,
			30.682,
			30.727,
			30.743,
			30.805,
			30.837,
			30.866,
			30.945,
			30.992,
			31.052,
			31.116,
			31.162,
			31.239,
			31.269,
			31.302,
			31.363,
			31.424,
			31.489,
			31.549,
			31.612,
			31.672,
			31.705,
			31.736,
			31.798,
			31.817,
			31.859,
			31.922,
			31.984,
			32.046,
			32.109,
			32.138,
			32.169,
			32.231,
			32.247,
			32.294,
			32.356,
			32.416,
			32.464,
			32.542,
			32.572,
			32.604,
			32.664,
			32.727,
			32.793,
			32.852,
			32.899,
			32.913,
			32.976,
			33.008,
			33.036,
			33.098,
			33.116,
			33.161,
			33.223,
			33.285,
			33.333,
			33.349,
			33.41,
			33.44,
			33.47,
			33.533,
			33.549,
			33.596,
			33.658,
			33.72,
			33.767,
			33.843,
			33.881,
			33.906,
			33.966,
			34.029,
			34.092,
			34.153,
			34.201,
			34.215,
			34.278,
			34.308,
			34.341,
			34.402,
			34.416,
			34.463,
			34.526,
			34.587,
			34.648,
			34.713,
			34.742,
			34.775,
			34.837,
			34.899,
			34.96,
			35.022,
			35.069,
			35.146,
			35.178,
			35.207,
			35.268,
			35.284,
			35.332,
			35.394,
			35.454,
			35.503,
			35.517,
			35.581,
			35.611,
			35.643,
			35.72,
			35.765,
			35.829,
			35.889,
			35.952,
			36.013,
			36.046,
			36.076,
			36.137,
			36.2,
			36.263,
			36.325,
			36.371,
			36.387,
			36.449,
			36.479,
			36.508,
			36.586,
			36.633,
			36.697,
			36.759,
			36.804,
			36.821,
			36.881,
			36.914,
			36.943,
			37.005,
			37.022,
			37.069,
			37.135,
			37.192,
			37.255,
			37.317,
			37.348,
			37.378,
			37.457,
			37.502,
			37.565,
			37.626,
			37.689,
			37.751,
			37.78,
			37.813,
			37.889,
			37.937,
			38.004,
			38.06,
			38.121,
			38.184,
			38.215,
			38.246,
			38.325,
			38.371,
			38.434,
			38.494,
			38.54,
			38.618,
			38.649,
			38.68,
			38.744,
			38.803,
			38.868,
			38.93,
			38.975,
			38.991,
			39.054,
			39.083,
			39.114,
			39.177,
			39.191,
			39.239,
			39.3,
			39.363,
			39.408,
			39.425,
			39.487,
			39.518,
			39.549,
			39.625,
			39.673,
			39.735,
			39.798,
			39.843,
			39.922,
			39.951,
			39.983,
			40.045,
			40.064,
			40.107,
			40.17,
			40.231,
			40.278,
			40.291,
			40.353,
			40.385,
			40.416,
			40.479,
			40.492,
			40.543,
			40.602,
			40.666,
			40.71,
			40.79,
			40.82,
			40.85,
			40.913,
			40.976,
			41.036,
			41.1,
			41.146,
			41.162,
			41.224,
			41.253,
			41.286,
			41.363,
			41.409,
			41.476,
			41.532,
			41.58,
			41.595,
			41.658,
			41.687,
			41.719,
			41.796,
			41.843,
			41.905,
			41.968,
			42.013,
			42.092,
			42.127,
			42.152,
			42.216,
			42.277,
			42.338,
			42.401,
			42.447,
			42.464,
			42.526,
			42.555,
			42.588,
			42.65,
			42.665,
			42.711,
			42.774,
			42.836,
			42.898,
			42.96,
			42.99,
			43.022,
			43.083,
			43.099,
			43.145,
			43.209,
			43.27,
			43.317,
			43.391,
			43.424,
			43.454,
			43.517,
			43.579,
			43.642,
			43.702,
			43.748,
			43.766,
			43.826,
			43.858,
			43.89,
			43.952,
			43.967,
			44.013,
			44.075,
			44.137,
			44.199,
			44.263,
			44.292,
			44.324,
			44.401,
			44.449,
			44.509,
			44.57,
			44.617,
			44.697,
			44.725,
			44.758,
			44.819,
			44.882,
			44.943,
			45.006,
			45.068,
			45.13,
			45.16,
			45.192,
			45.253,
			45.268,
			45.317,
			45.376,
			45.439,
			45.486,
			45.564,
			45.595,
			45.626,
			45.704,
			45.749,
			45.812,
			45.873,
			45.92,
			45.935,
			45.998,
			46.029,
			46.06,
			46.122,
			46.137,
			46.184,
			46.246,
			46.306,
			46.369,
			46.431,
			46.464,
			46.492,
			46.555,
			46.571,
			46.617,
			46.681,
			46.743,
			46.802,
			46.866,
			46.897,
			46.928,
			46.991,
			47.052,
			47.115,
			47.175,
			47.223,
			47.237,
			47.299,
			47.332,
			47.363,
			47.424,
			47.439,
			47.486,
			47.548,
			47.61,
			47.657,
			47.671,
			47.734,
			47.765,
			47.797,
			47.857,
			47.874,
			47.921,
			47.981,
			48.043,
			48.09,
			48.167,
			48.198,
			48.231,
			48.292,
			48.312,
			48.353,
			48.417,
			48.479,
			48.523,
			48.541,
			48.603,
			48.633,
			48.664,
			48.726,
			48.74,
			48.788,
			48.849,
			48.913,
			48.96,
			49.037,
			49.067,
			49.098,
			49.161,
			49.223,
			49.283,
			49.347,
			49.394,
			49.409,
			49.47,
			49.5,
			49.532,
			49.596,
			49.656,
			49.718,
			49.78,
			49.827,
			49.842,
			49.904,
			49.935,
			49.967,
			50.03,
			50.044,
			50.092,
			50.152,
			50.214,
			50.278,
			50.338,
			50.374,
			50.401,
			50.463,
			50.525,
			50.586,
			50.648,
			50.696,
			50.71,
			50.772,
			50.803,
			50.836,
			50.896,
			50.913,
			50.96,
			51.022,
			51.084,
			51.129,
			51.143,
			51.206,
			51.236,
			51.27,
			51.345,
			51.392,
			51.456,
			51.517,
			51.579,
			51.64,
			51.672,
			51.704,
			51.765,
			51.825,
			51.888,
			51.952,
			51.997,
			52.013,
			52.076,
			52.104,
			52.137,
			52.198,
			52.214,
			52.261,
			52.323,
			52.387,
			52.437,
			52.508,
			52.539,
			52.57,
			52.633,
			52.647,
			52.694,
			52.758,
			52.76,
			52.82,
			52.865,
			52.943,
			52.974,
			53.005,
			53.067,
			53.13,
			53.191,
			53.252,
			53.3,
			53.314,
			53.375,
			53.408,
			53.439,
			53.517,
			53.564,
			53.626,
			53.686,
			53.749,
			53.809,
			53.843,
			53.872,
			53.936,
			53.998,
			54.06,
			54.12,
			54.167,
			54.246,
			54.275,
			54.306,
			54.37,
			54.431,
			54.499,
			54.554,
			54.601,
			54.617,
			54.679,
			54.71,
			54.74,
			54.804,
			54.818,
			54.866,
			54.929,
			54.99,
			55.037,
			55.114,
			55.143,
			55.176,
			55.237,
			55.3,
			55.36,
			55.423,
			55.469,
			55.548,
			55.577,
			55.611,
			55.671,
			55.687,
			55.735,
			55.796,
			55.859,
			55.904,
			55.921,
			55.981,
			56.011,
			56.043,
			56.121,
			56.168,
			56.231,
			56.292,
			56.339,
			56.414,
			56.446,
			56.477,
			56.54,
			56.6,
			56.663,
			56.725,
			56.771,
			56.788,
			56.851,
			56.881,
			56.912,
			56.972,
			56.989,
			57.035,
			57.097,
			57.16,
			57.206,
			57.223,
			57.285,
			57.315,
			57.346,
			57.409,
			57.423,
			57.47,
			57.532,
			57.592,
			57.639,
			57.719,
			57.748,
			57.781,
			57.843,
			57.905,
			57.967,
			58.029,
			58.075,
			58.089,
			58.151,
			58.184,
			58.214,
			58.29,
			58.338,
			58.4,
			58.463,
			58.509,
			58.524,
			58.587,
			58.623,
			58.647,
			58.709,
			58.724,
			58.771,
			58.837,
			58.897,
			58.942,
			59.02,
			59.051,
			59.082,
			59.145,
			59.206,
			59.268,
			59.33,
			59.391,
			59.454,
			59.485,
			59.515,
			59.578,
			59.592,
			59.639,
			59.704,
			59.765,
			59.81,
			59.887,
			59.92,
			59.95,
			60.026,
			60.075,
			60.138,
			60.199,
			60.245,
			60.261,
			60.323,
			60.353,
			60.383,
			60.447,
			60.462,
			60.509,
			60.571,
			60.632,
			60.695,
			60.757,
			60.791,
			60.817,
			60.88,
			60.897,
			60.942,
			61.006,
			61.065,
			61.129,
			61.19,
			61.222,
			61.252,
			61.315,
			61.377,
			61.439,
			61.502,
			61.548,
			61.557
		]
	],
	'2812_5095_7982': [
		[
			520.328
		],
		[
			0
		],
		[
			0,
			1.259,
			3.865,
			6.462,
			9.048,
			11.708,
			13.085,
			15.845,
			18.577,
			19.946,
			23.989,
			26.659,
			29.219,
			33.124,
			35.792,
			39.909,
			42.692,
			46.915,
			49.615,
			53.656,
			56.405,
			60.601,
			63.426,
			67.56,
			71.873,
			74.747,
			77.545,
			81.895,
			83.374,
			84.839,
			89.154,
			91.932,
			96.095,
			98.893,
			101.754,
			106.111,
			108.979,
			113.211,
			116.01,
			120.55,
			123.54,
			127.989,
			130.927,
			133.831,
			135.278,
			141.006,
			142.429,
			146.585,
			149.509,
			152.517,
			156.847,
			159.843,
			165.973,
			167.492,
			171.946,
			174.969,
			179.694,
			182.718,
			185.8,
			192.039,
			193.569,
			198.201,
			199.753,
			201.27,
			205.776,
			208.732,
			213.235,
			216.269,
			219.216,
			220.691,
			225.527,
			228.602,
			231.584,
			236.445,
			239.699,
			246.085,
			247.66,
			252.447,
			255.676,
			260.491,
			263.667,
			268.285,
			272.832,
			276.029,
			279.339,
			280.962,
			284.211,
			287.404,
			292.178,
			295.352,
			298.469,
			303.373,
			308.179,
			311.261,
			314.46,
			319.403,
			322.749,
			329.222,
			330.765,
			335.602,
			338.877,
			343.765,
			346.986,
			350.241,
			351.874,
			355.056,
			356.622,
			359.861,
			364.936,
			368.356,
			371.617,
			376.54,
			379.883,
			384.963,
			386.64,
			388.318,
			393.366,
			396.741,
			400.123,
			405.086,
			408.539,
			413.684,
			415.371,
			417.101,
			424.148,
			425.873,
			431.012,
			434.471,
			439.66,
			443.125,
			448.315,
			451.729,
			453.432,
			456.736,
			460.001,
			464.843,
			468.066,
			474.477,
			476.066,
			480.723,
			483.679,
			488.133,
			491.047,
			493.861,
			495.255,
			498.71,
			502.798,
			506.804,
			508.121,
			512.019,
			514.571,
			518.33,
			523.215,
			524.415,
			527.964,
			530.289,
			532.577,
			535.941,
			538.139,
			541.367,
			543.475,
			546.568,
			548.584,
			551.542,
			553.47,
			556.297,
			558.138,
			559.048,
			560.846,
			563.484,
			565.198,
			566.878,
			569.334,
			570.929,
			572.488,
			574.009,
			574.757,
			576.949,
			578.37,
			579.758,
			581.782,
			583.092,
			584.998,
			585.616,
			586.224,
			587.999,
			589.139,
			590.785,
			591.841,
			593.359,
			594.33,
			594.803,
			595.724,
			597.063,
			597.935,
			598.798,
			600.093,
			600.971,
			602.784,
			603.243,
			604.632,
			605.567,
			606.98,
			607.921,
			608.86,
			610.261,
			610.725,
			611.187,
			612.572,
			613.475,
			614.788,
			616.491,
			616.929,
			617.783,
			619.013,
			619.803,
			620.943,
			621.676,
			622.736,
			623.42,
			624.418,
			625.185,
			626.403,
			627.184,
			628.323,
			629.07,
			629.81,
			630.915,
			631.284,
			631.654,
			633.349,
			633.882,
			635.473,
			636.528,
			638.115,
			639.187,
			640.276,
			640.802,
			642.345,
			643.383,
			644.427,
			647.096,
			649.133,
			649.555,
			650.842,
			651.718,
			653.058,
			653.971,
			655.371,
			656.804,
			657.713,
			659.071,
			659.989,
			660.909,
			662.3,
			662.76,
			663.22,
			664.142,
			665.522,
			666.887,
			667.79,
			668.686,
			670.904,
			672.217,
			673.084,
			674.389,
			675.266,
			676.558,
			677.404,
			678.653,
			679.881,
			680.689,
			681.889,
			682.802,
			683.959,
			685.657,
			686.773,
			689.001,
			689.56,
			692.617,
			695.042,
			697.38,
			700.561,
			702.678,
			704.491,
			704.99,
			705.488,
			707.464,
			707.958,
			709.445,
			711.169,
			711.555,
			713.489,
			714.656,
			715.439,
			716.228,
			717.429,
			718.245,
			719.478,
			719.893,
			720.309,
			721.572,
			722.427,
			723.732,
			724.634,
			726.025,
			726.969,
			727.446,
			727.927,
			729.393,
			730.392,
			731.949,
			733.007,
			734.623,
			735.723,
			736.839,
			737.422,
			739.234,
			740.461,
			741.703,
			743.598,
			744.931,
			746.944,
			748.299,
			750.246,
			751.539,
			753.505,
			754.807,
			756.771,
			758.1,
			758.77,
			760.125,
			762.182,
			763.572,
			764.992,
			767.886,
			768.618,
			771.635,
			772.399,
			774.705,
			776.275,
			777.86,
			780.276,
			781.911,
			785.211,
			786.051,
			788.532,
			790.214,
			792.76,
			796.208,
			797.078,
			799.711,
			801.486,
			805.984,
			807.809,
			810.572,
			812.432,
			814.304,
			817.139,
			820.004,
			821.933,
			824.851,
			826.817,
			828.794,
			831.785,
			832.788,
			833.794,
			836.824,
			838.858,
			841.925,
			843.984,
			847.094,
			849.182,
			850.23,
			852.322,
			854.417,
			855.467,
			857.577,
			860.762,
			862.899,
			865.047,
			869.376,
			870.466,
			873.754,
			875.96,
			879.29,
			881.522,
			883.765,
			887.146,
			890.55,
			892.832,
			893.976,
			895.123,
			899.737,
			900.897,
			904.393,
			906.736,
			910.271,
			912.64,
			915.018,
			916.21,
			919.802,
			922.206,
			924.62,
			928.258,
			930.695,
			934.367,
			936.828,
			940.537,
			943.022,
			946.767,
			953.051,
			955.579,
			956.845,
			958.114,
			963.202,
			964.479,
			968.312,
			970.876,
			974.733,
			977.312,
			979.899,
			981.196,
			985.094,
			987.695,
			990.3,
			996.846,
			1000.796,
			1003.44,
			1007.418,
			1010.08,
			1014.088,
			1016.769,
			1020.807,
			1023.509,
			1024.863,
			1027.578,
			1031.666,
			1034.401,
			1037.144,
			1039.894,
			1042.651,
			1044.033,
			1046.801,
			1049.573,
			1050.958,
			1055.092,
			1057.818,
			1060.51,
			1064.476,
			1067.073,
			1072.154,
			1073.4,
			1077.086,
			1079.499,
			1083.057,
			1087.689,
			1088.824,
			1091.069,
			1092.179,
			1096.525,
			1097.589,
			1099.69,
			1101.756,
			1104.788,
			1106.771,
			1109.701,
			1110.657,
			1111.607,
			1114.419,
			1116.273,
			1119.037,
			1122.714,
			1123.635,
			1125.474,
			1126.398,
			1127.324,
			1130.103,
			1131.964,
			1134.77,
			1136.649,
			1139.509,
			1141.416,
			1143.361,
			1144.332,
			1146.276,
			1149.233,
			1151.204,
			1154.154,
			1156.119,
			1159.038,
			1160.009,
			1160.979,
			1163.871,
			1168.68,
			1170.601,
			1173.486,
			1176.369,
			1178.299,
			1180.227,
			1181.192,
			1182.156,
			1185.047,
			1188.884,
			1189.84,
			1191.75,
			1194.606,
			1197.46,
			1199.36,
			1201.264,
			1204.109,
			1206.007,
			1208.846,
			1209.792,
			1210.738,
			1213.571,
			1215.458,
			1218.286,
			1220.171,
			1223.005,
			1225.852,
			1227.762,
			1230.644,
			1232.58,
			1237.469,
			1239.442,
			1243.417,
			1244.414,
			1246.413,
			1247.413,
			1249.396,
			1251.391,
			1254.352,
			1256.297,
			1259.155,
			1260.091,
			1261.018,
			1263.746,
			1265.525,
			1268.134,
			1269.83,
			1271.491,
			1272.31,
			1274.716,
			1276.295,
			1278.598,
			1280.11,
			1281.606,
			1283.835,
			1285.312,
			1287.523,
			1288.991,
			1291.186,
			1292.648,
			1294.106,
			1297.754,
			1299.963,
			1300.699,
			1301.435,
			1304.435,
			1305.189,
			1307.463,
			1309.023,
			1311.364,
			1314.565,
			1315.373,
			1317.812,
			1319.471,
			1321.128,
			1323.633,
			1325.309,
			1329.499,
			1332.013,
			1333.685,
			1336.186,
			1337.85,
			1340.33,
			1341.981,
			1342.805,
			1344.448,
			1346.906,
			1347.724,
			1350.171,
			1351.8,
			1354.23,
			1355.845,
			1357.463,
			1358.261,
			1360.652,
			1362.251,
			1363.828,
			1366.191,
			1367.768,
			1370.105,
			1370.885,
			1371.667,
			1374.007,
			1375.565,
			1377.916,
			1379.491,
			1381.073,
			1381.866,
			1384.268,
			1385.88,
			1388.322,
			1389.967,
			1394.132,
			1395.82,
			1399.232,
			1400.093,
			1401.822,
			1402.689,
			1404.426,
			1406.19,
			1408.863,
			1410.667,
			1414.318,
			1415.24,
			1418.029,
			1419.907,
			1422.751,
			1424.667,
			1427.567,
			1430.499,
			1432.471,
			1435.456,
			1437.464,
			1439.485,
			1442.543,
			1444.598,
			1447.704,
			1449.789,
			1452.94,
			1455.047,
			1457.154,
			1462.455,
			1465.667,
			1466.744,
			1467.823,
			1471.075,
			1472.165,
			1473.258,
			1476.55,
			1478.759,
			1482.091,
			1484.326,
			1486.572,
			1487.699,
			1491.095,
			1493.373,
			1495.664,
			1499.122,
			1501.443,
			1504.948,
			1507.299,
			1510.852,
			1513.234,
			1516.833,
			1519.246,
			1522.884,
			1525.321,
			1526.544,
			1528.997,
			1532.69,
			1533.925,
			1537.644,
			1542.636,
			1543.89,
			1546.406,
			1548.933,
			1552.743,
			1554.019,
			1556.579,
			1559.148,
			1563.02,
			1565.612,
			1570.823,
			1572.131,
			1576.064,
			1578.69,
			1582.644,
			1587.947,
			1589.279,
			1593.285,
			1595.966,
			1600.004,
			1602.708,
			1605.42,
			1608.141,
			1612.235,
			1617.719,
			1619.095,
			1621.854,
			1623.236,
			1626.006,
			1628.784,
			1635.769,
			1639.984,
			1641.393,
			1642.804,
			1647.048,
			1649.888,
			1654.163,
			1657.024,
			1661.331,
			1664.211,
			1668.546,
			1671.445,
			1672.896,
			1675.804,
			1678.718,
			1683.101,
			1686.03,
			1690.438,
			1691.911,
			1693.385,
			1697.819,
			1700.785,
			1703.759,
			1708.237,
			1711.234,
			1715.741,
			1718.756,
			1724.806,
			1726.323,
			1730.885,
			1733.934,
			1738.519,
			1741.585,
			1746.194,
			1750.816,
			1753.905,
			1757,
			1761.651,
			1764.759,
			1769.428,
			1772.535,
			1777.168,
			1780.226,
			1784.752,
			1787.723,
			1790.655,
			1794.982,
			1796.406,
			1799.225,
			1803.38,
			1804.746,
			1808.789,
			1811.438,
			1815.339,
			1817.892,
			1821.654,
			1825.337,
			1827.751,
			1830.135,
			1833.652,
			1835.954,
			1840.437,
			1841.534,
			1844.754,
			1846.855,
			1849.921,
			1851.92,
			1853.877,
			1854.842,
			1856.747,
			1857.685,
			1859.534,
			1862.245,
			1864.013,
			1865.75,
			1868.295,
			1869.951,
			1871.587,
			1873.18,
			1873.966,
			1875.511,
			1877.775,
			1879.252,
			1881.414,
			1882.84,
			1884.954,
			1885.651,
			1886.347,
			1889.14,
			1889.836,
			1891.924,
			1893.34,
			1895.466,
			1897.611,
			1899.062,
			1901.243,
			1902.71,
			1904.186,
			1906.399,
			1907.883,
			1910.107,
			1911.589,
			1913.816,
			1915.294,
			1916.77,
			1918.985,
			1920.455,
			1922.661,
			1923.399,
			1924.135,
			1927.07,
			1927.81,
			1930.018,
			1931.486,
			1933.707,
			1936.661,
			1937.401,
			1939.635,
			1941.116,
			1942.601,
			1944.833,
			1946.313,
			1949.275,
			1950.012,
			1952.216,
			1953.686,
			1955.873,
			1957.325,
			1959.509,
			1960.951,
			1961.67,
			1963.114,
			1965.273,
			1965.99,
			1968.143,
			1969.583,
			1971.733,
			1974.622,
			1975.343,
			1976.784,
			1977.505,
			1978.968,
			1980.429,
			1982.617,
			1984.084,
			1986.28,
			1987.012,
			1987.747,
			1989.956,
			1991.431,
			1993.632,
			1995.089,
			1996.549,
			1998.727,
			1999.448,
			2000.895,
			2002.351,
			2003.794,
			2005.964,
			2008.151,
			2009.605,
			2011.07,
			2012.548,
			2013.287,
			2015.518,
			2017.025,
			2018.544,
			2020.84,
			2022.388,
			2026.329,
			2028.742,
			2030.375,
			2032.851,
			2034.528,
			2037.046,
			2038.733,
			2039.584,
			2041.28,
			2043.869,
			2045.613,
			2047.369,
			2050.026,
			2050.917,
			2051.812,
			2054.517,
			2055.424,
			2056.335,
			2059.088,
			2060.939,
			2062.803,
			2065.62,
			2067.514,
			2070.374,
			2071.333,
			2072.295,
			2075.195,
			2077.142,
			2080.083,
			2082.056,
			2084.041,
			2085.037,
			2089.049,
			2090.058,
			2092.086,
			2095.146,
			2097.199,
			2100.298,
			2102.375,
			2105.51,
			2107.601,
			2110.741,
			2112.846,
			2116.019,
			2120.282,
			2121.354,
			2123.503,
			2124.581,
			2126.744,
			2130.005,
			2134.385,
			2136.586,
			2139.906,
			2142.13,
			2144.363,
			2145.483,
			2148.855,
			2151.115,
			2153.384,
			2156.804,
			2159.095,
			2162.548,
			2164.86,
			2168.344,
			2170.677,
			2174.192,
			2176.545,
			2180.089,
			2182.462,
			2186.037,
			2188.428,
			2190.826,
			2194.439,
			2199.276,
			2200.489,
			2202.922,
			2205.363,
			2206.588,
			2210.271,
			2212.737,
			2215.21,
			2218.936,
			2221.427,
			2225.18,
			2226.434,
			2227.69,
			2231.469,
			2233.996,
			2237.8,
			2242.886,
			2244.155,
			2246.685,
			2250.441,
			2254.155,
			2258.957,
			2262.461,
			2264.754,
			2269.248,
			2270.361,
			2272.571,
			2275.879,
			2278.072,
			2281.361,
			2283.548,
			2286.83,
			2289.021,
			2292.312,
			2294.507,
			2297.799,
			2299.988,
			2303.266,
			2305.441,
			2306.529,
			2308.704,
			2310.876,
			2311.967,
			2314.147,
			2316.328,
			2319.622,
			2321.827,
			2325.155,
			2326.266,
			2327.384,
			2330.74,
			2332.984,
			2336.354,
			2340.875,
			2344.287,
			2346.566,
			2349.986,
			2351.128,
			2352.269,
			2355.696,
			2357.974,
			2361.398,
			2365.948,
			2367.088,
			2370.515,
			2372.801,
			2375.092,
			2378.519,
			2380.803,
			2385.347,
			2386.482,
			2389.869,
			2392.11,
			2395.497,
			2397.763,
			2401.174,
			2403.459,
			2404.605,
			2406.903,
			2410.373,
			2412.698,
			2415.038,
			2418.568,
			2420.938,
			2423.321,
			2426.919,
			2433.014,
			2435.478,
			2439.208,
			2441.717,
			2446.782,
			2448.055,
			2451.889,
			2454.458,
			2458.315,
			2460.88,
			2464.723,
			2467.283,
			2471.121,
			2474.964,
			2477.532,
			2483.955,
			2486.506,
			2490.37,
			2491.659,
			2492.973,
			2496.849,
			2499.439,
			2502.01,
			2508.506,
			2512.409,
			2513.709,
			2515.009,
			2518.913,
			2521.522,
			2525.446,
			2528.071,
			2532.019,
			2534.658,
			2537.304,
			2538.629,
			2542.618,
			2545.284,
			2547.959,
			2551.981,
			2554.669,
			2560.065,
			2561.418,
			2565.486,
			2568.208,
			2572.3,
			2577.778,
			2581.902,
			2583.28,
			2584.657,
			2588.803,
			2591.573,
			2595.739,
			2598.523,
			2602.708,
			2605.505,
			2608.308,
			2609.711,
			2613.93,
			2616.75,
			2619.576,
			2623.826,
			2626.667,
			2632.365,
			2633.793,
			2638.086,
			2640.954,
			2645.268,
			2648.15,
			2652.485,
			2656.832,
			2659.736,
			2662.647,
			2667.023,
			2669.948,
			2672.879,
			2677.285,
			2680.23,
			2684.656,
			2692.063,
			2695.035,
			2699.502,
			2702.488,
			2708.473,
			2709.972,
			2714.478,
			2717.488,
			2722.012,
			2725.035,
			2729.579,
			2732.615,
			2734.133,
			2737.176,
			2741.752,
			2747.869,
			2752.468,
			2755.535,
			2760.123,
			2761.644,
			2763.159,
			2767.658,
			2770.612,
			2773.528,
			2777.83,
			2780.65,
			2786.176,
			2787.533,
			2792.872,
			2794.184,
			2798.064,
			2800.603,
			2804.342,
			2806.787,
			2809.195,
			2810.385,
			2812.742,
			2813.907,
			2816.21,
			2818.476,
			2823.973,
			2827.154,
			2829.226,
			2832.259,
			2834.235,
			2837.126,
			2839.009,
			2840.858,
			2844.477,
			2845.363,
			2848.854,
			2849.715,
			2852.275,
			2853.974,
			2855.659,
			2856.499,
			2858.177,
			2860.694,
			2863.21,
			2864.897,
			2866.601,
			2869.154,
			2870.895,
			2873.506,
			2875.266,
			2877.942,
			2879.725,
			2882.445,
			2884.257,
			2886.994,
			2888.818,
			2889.73,
			2891.568,
			2894.319,
			2896.149,
			2897.973,
			2901.603,
			2904.304,
			2906.993,
			2909.67,
			2911.444,
			2913.21,
			2915.847,
			2917.585,
			2920.189,
			2921.05,
			2921.908,
			2924.484,
			2926.197,
			2928.757,
			2930.467,
			2932.169,
			2933.022,
			2934.731,
			2935.588,
			2939.032,
			2941.65,
			2943.415,
			2946.088,
			2947.897,
			2950.635,
			2952.491,
			2955.3,
			2957.198,
			2959.11,
			2962.005,
			2963.952,
			2967.878,
			2971.831,
			2973.827,
			2976.842,
			2978.863,
			2981.912,
			2983.953,
			2987.031,
			2990.126,
			2992.201,
			2994.28,
			2997.396,
			2999.48,
			3002.618,
			3003.665,
			3004.708,
			3007.816,
			3009.86,
			3012.87,
			3014.835,
			3017.717,
			3019.597,
			3020.524,
			3021.442,
			3024.167,
			3025.065,
			3025.975,
			3028.645,
			3030.415,
			3032.179,
			3033.061,
			3034.823,
			3037.465,
			3039.221,
			3040.1,
			3041.857,
			3043.606,
			3046.223,
			3047.963,
			3051.425,
			3052.29,
			3054.885,
			3056.614,
			3059.222,
			3062.707,
			3063.589,
			3067.995,
			3069.775,
			3070.664,
			3072.441,
			3074.238,
			3076.935,
			3078.743,
			3080.561,
			3083.287,
			3086.038,
			3087.869,
			3089.728,
			3092.514,
			3094.395,
			3097.226,
			3098.17,
			3099.121,
			3101.964,
			3103.858,
			3106.693,
			3108.578,
			3111.391,
			3113.265,
			3114.203,
			3116.078,
			3117.954,
			3118.894,
			3120.78,
			3122.667,
			3125.518,
			3126.472,
			3127.428,
			3130.304,
			3131.265,
			3132.227,
			3135.113,
			3137.037,
			3138.966,
			3139.927,
			3141.852,
			3143.785,
			3146.656,
			3147.618,
			3148.583,
			3151.462,
			3153.392,
			3156.28,
			3158.216,
			3160.153,
			3161.122,
			3163.071,
			3164.05,
			3166.011,
			3168.973,
			3170.96,
			3172.959,
			3175.966,
			3178,
			3181.08,
			3182.116,
			3183.155,
			3186.286,
			3188.383,
			3191.555,
			3193.687,
			3195.829,
			3199.066,
			3200.15,
			3201.239,
			3205.618,
			3206.719,
			3210.041,
			3212.265,
			3215.621,
			3220.122,
			3221.254,
			3223.521,
			3226.94,
			3229.232,
			3235.008,
			3238.507,
			3239.679,
			3240.854,
			3244.397,
			3246.774,
			3250.363,
			3252.769,
			3256.397,
			3258.829,
			3260.05,
			3262.498,
			3264.956,
			3266.189,
			3267.425,
			3271.147,
			3273.642,
			3277.404,
			3279.925,
			3282.455,
			3283.723,
			3286.266,
			3287.541,
			3290.097,
			3292.662,
			3296.526,
			3299.113,
			3305.616,
			3309.532,
			3312.146,
			3316.081,
			3318.714,
			3322.677,
			3325.329,
			3326.658,
			3329.321,
			3333.329,
			3336.011,
			3338.701,
			3344.104,
			3345.459,
			3348.177,
			3350.902,
			3352.268,
			3356.376,
			3361.879,
			3363.259,
			3366.025,
			3368.797,
			3372.97,
			3375.761,
			3379.96,
			3381.365,
			3382.77,
			3386.998,
			3389.829,
			3394.089,
			3396.938,
			3399.795,
			3401.226,
			3404.094,
			3405.531,
			3408.41,
			3412.741,
			3415.636,
			3418.537,
			3422.902,
			3425.819,
			3430.206,
			3431.671,
			3433.138,
			3437.546,
			3440.492,
			3444.92,
			3447.88,
			3450.844,
			3455.303,
			3456.792,
			3458.281,
			3462.76,
			3464.255,
			3465.753,
			3470.257,
			3473.268,
			3477.796,
			3480.823,
			3482.337,
			3485.398,
			3489.96,
			3493.014,
			3496.075,
			3500.691,
			3503.786,
			3508.454,
			3511.583,
			3516.307,
			3519.478,
			3524.243,
			3527.423,
			3532.19,
			3535.367,
			3536.957,
			3540.136,
			3544.907,
			3546.499,
			3551.275,
			3554.463,
			3557.655,
			3559.251,
			3562.447,
			3565.622,
			3567.224,
			3570.465,
			3572.075,
			3575.288,
			3578.501,
			3583.299,
			3586.529,
			3591.332,
			3592.932,
			3594.505,
			3599.302,
			3602.5,
			3607.301,
			3610.505,
			3615.319,
			3618.532,
			3620.141,
			3623.362,
			3628.202,
			3631.434,
			3634.669,
			3639.53,
			3642.777,
			3646.028,
			3649.283,
			3650.912,
			3655.803,
			3659.069,
			3662.338,
			3670.526,
			3675.446,
			3677.088,
			3678.731,
			3683.664,
			3686.957,
			3691.903,
			3695.205,
			3700.165,
			3703.476,
			3705.133,
			3708.448,
			3711.767,
			3713.427,
			3716.749,
			3720.074,
			3725.066,
			3728.397,
			3733.398,
			3735.065,
			3736.735,
			3741.745,
			3745.089,
			3748.437,
			3753.465,
			3756.821,
			3761.861,
			3763.544,
			3765.226,
			3770.279,
			3771.965,
			3773.651,
			3778.715,
			3782.094,
			3785.476,
			3787.167,
			3790.553,
			3793.942,
			3795.637,
			3800.728,
			3804.124,
			3807.524,
			3812.629,
			3816.036,
			3821.151,
			3822.857,
			3824.563,
			3829.687,
			3833.104,
			3838.237,
			3841.66,
			3845.084,
			3846.797,
			3850.224,
			3851.939,
			3853.655,
			3860.513,
			3862.219,
			3867.3,
			3870.643,
			3875.581,
			3878.824,
			3882.027,
			3883.613,
			3888.313,
			3891.397,
			3894.441,
			3898.938,
			3901.887,
			3906.236,
			3907.665,
			3909.084,
			3913.282,
			3916.032,
			3920.083,
			3922.737,
			3925.351,
			3926.644,
			3929.2,
			3930.464,
			3932.964,
			3936.644,
			3937.851,
			3941.42,
			3943.756,
			3947.188,
			3949.426,
			3951.63,
			3952.718,
			3955.929,
			3958.02,
			3960.07,
			3963.079,
			3965.038,
			3967.91,
			3969.795,
			3972.526,
			3974.322,
			3976.979,
			3978.712,
			3981.273,
			3982.958,
			3983.792,
			3985.454,
			3987.112,
			3987.925,
			3989.573,
			3991.241,
			3993.735,
			3994.569,
			3995.404,
			3997.913,
			3998.77,
			3999.626,
			4002.202,
			4003.959,
			4006.64,
			4008.446,
			4010.307,
			4013.111,
			4014.068,
			4015.028,
			4017.917,
			4019.872,
			4022.817,
			4024.798,
			4026.789,
			4027.788,
			4029.759,
			4031.726,
			4032.713,
			4035.577,
			4037.47,
			4042.022,
			4043.816,
			4046.575,
			4047.5,
			4048.427,
			4051.352,
			4053.324,
			4056.43,
			4058.517,
			4061.714,
			4063.861,
			4064.928,
			4067.059,
			4070.253,
			4071.312,
			4074.483,
			4076.558,
			4079.667,
			4081.733,
			4083.796,
			4084.827,
			4087.912,
			4089.967,
			4092.007,
			4095.069,
			4097.089,
			4100.125,
			4101.128,
			4102.126,
			4105.128,
			4107.111,
			4110.089,
			4112.075,
			4114.064,
			4115.063,
			4118.07,
			4120.085,
			4122.113,
			4123.132,
			4124.153,
			4127.237,
			4130.35,
			4132.437,
			4134.522,
			4136.607,
			4137.652,
			4140.798,
			4142.905,
			4145.02,
			4148.209,
			4150.347,
			4153.572,
			4154.652,
			4155.734,
			4158.995,
			4161.162,
			4164.458,
			4166.667,
			4169.998,
			4172.23,
			4174.474,
			4175.599,
			4177.855,
			4178.987,
			4181.259,
			4183.539,
			4186.976,
			4188.126,
			4189.278,
			4192.749,
			4193.91,
			4195.073,
			4198.57,
			4200.899,
			4203.219,
			4206.662,
			4208.923,
			4212.247,
			4213.336,
			4214.417,
			4217.602,
			4219.679,
			4222.726,
			4224.71,
			4226.657,
			4227.617,
			4229.509,
			4231.365,
			4232.28,
			4234.975,
			4236.729,
			4238.448,
			4240.958,
			4242.584,
			4245.74,
			4246.513,
			4248.808,
			4250.338,
			4252.602,
			4254.102,
			4255.597,
			4258.568,
			4259.309,
			4262.252,
			4262.984,
			4265.176,
			4266.645,
			4268.111,
			4268.842,
			4271.776,
			4272.515,
			4273.987,
			4274.722,
			4276.193,
			4277.679,
			4279.902,
			4281.388,
			4284.372,
			4285.116,
			4287.351,
			4288.839,
			4291.064,
			4292.549,
			4294.771,
			4296.25,
			4296.991,
			4298.478,
			4300.698,
			4302.18,
			4303.667,
			4305.89,
			4307.373,
			4308.858,
			4310.338,
			4311.077,
			4312.557,
			4313.297,
			4314.773,
			4316.98,
			4318.458,
			4319.931,
			4322.132,
			4322.87,
			4323.607,
			4325.809,
			4327.271,
			4329.476,
			4330.939,
			4333.129,
			4334.59,
			4335.318,
			4336.772,
			4338.953,
			4340.404,
			4341.852,
			4344.738,
			4345.464,
			4348.402,
			4349.145,
			4351.387,
			4352.897,
			4354.42,
			4356.727,
			4358.281,
			4360.598,
			4361.376,
			4362.156,
			4364.5,
			4366.059,
			4368.418,
			4369.995,
			4372.375,
			4373.962,
			4374.764,
			4376.376,
			4378.818,
			4380.457,
			4382.107,
			4384.606,
			4385.445,
			4386.287,
			4388.83,
			4390.543,
			4393.138,
			4394.884,
			4397.532,
			4399.312,
			4402.011,
			4403.826,
			4404.739,
			4405.655,
			4409.352,
			4410.283,
			4413.093,
			4414.981,
			4416.879,
			4417.832,
			4419.747,
			4421.67,
			4422.634,
			4424.562,
			4425.541,
			4427.456,
			4429.337,
			4432.135,
			4433.963,
			4436.646,
			4437.525,
			4438.396,
			4440.955,
			4442.618,
			4445.049,
			4446.625,
			4448.167,
			4448.926,
			4450.416,
			4451.872,
			4452.589,
			4455.416,
			4457.511,
			4458.903,
			4460.999,
			4462.38,
			4463.745,
			4464.421,
			4465.766,
			4466.435,
			4467.77,
			4469.084,
			4471.038,
			4472.334,
			4474.283,
			4474.931,
			4475.578,
			4477.514,
			4478.823,
			4480.811,
			4482.135,
			4484.169,
			4486.262,
			4487.659,
			4489.105,
			4491.353,
			4492.854,
			4495.195,
			4495.988,
			4496.782,
			4499.187,
			4500.017,
			4500.845,
			4503.326,
			4504.979,
			4506.625,
			4509.093,
			4510.673,
			4513.036,
			4513.825,
			4514.59,
			4516.838,
			4518.338,
			4520.567,
			4522.038,
			4524.254,
			4525.728,
			4527.943,
			4530.174,
			4531.668,
			4533.165,
			4535.422,
			4536.177,
			4536.933,
			4539.212,
			4539.974,
			4540.74,
			4543.047,
			4544.595,
			4546.939,
			4548.516,
			4550.093,
			4553.323,
			4554.141,
			4557.458,
			4558.293,
			4560.815,
			4564.172,
			4565.022,
			4566.735,
			4568.461,
			4571.077,
			4571.958,
			4573.731,
			4575.519,
			4578.233,
			4580.062,
			4582.837,
			4583.772,
			4584.71,
			4587.546,
			4589.46,
			4592.357,
			4594.308,
			4596.272,
			4597.259,
			4599.241,
			4600.237,
			4602.241,
			4605.269,
			4607.305,
			4609.352,
			4611.411,
			4614.525,
			4616.617,
			4618.715,
			4619.765,
			4621.866,
			4622.921,
			4625.04,
			4627.172,
			4630.393,
			4632.555,
			4635.82,
			4636.934,
			4638.031,
			4641.343,
			4643.566,
			4646.926,
			4649.18,
			4652.567,
			4654.854,
			4658.33,
			4660.647,
			4661.812,
			4664.156,
			4666.51,
			4670.083,
			4672.482,
			4676.115,
			4677.332,
			4678.554,
			4682.235,
			4684.7,
			4687.175,
			4693.383,
			4697.115,
			4698.363,
			4699.61,
			4703.348,
			4705.862,
			4709.672,
			4712.188,
			4716.011,
			4718.572,
			4721.142,
			4722.451,
			4726.327,
			4728.918,
			4731.515,
			4735.417,
			4738.02,
			4741.927,
			4743.228,
			4744.529,
			4748.426,
			4751,
			4754.922,
			4757.522,
			4761.435,
			4764.056,
			4765.37,
			4766.665,
			4771.961,
			4773.291,
			4777.296,
			4779.977,
			4782.666,
			4784.013,
			4790.791,
			4794.846,
			4797.541,
			4800.219,
			4804.194,
			4806.815,
			4810.73,
			4812.034,
			4813.338,
			4817.249,
			4819.855,
			4823.762,
			4826.37,
			4828.98,
			4830.289,
			4832.911,
			4834.224,
			4835.541,
			4840.835,
			4842.169,
			4846.178,
			4848.863,
			4852.906,
			4855.609,
			4858.321,
			4859.68,
			4862.398,
			4863.759,
			4866.483,
			4870.562,
			4873.276,
			4875.992,
			4880.078,
			4881.441,
			4882.806,
			4886.903,
			4889.65,
			4893.78,
			4896.542,
			4900.693,
			4903.468,
			4904.854,
			4907.632,
			4910.408,
			4911.797,
			4914.579,
			4917.359,
			4921.54,
			4922.934,
			4924.331,
			4927.124,
			4929.923,
			4931.326,
			4935.544,
			4938.368,
			4941.199,
			4945.438,
			4948.286,
			4952.573,
			4954.005,
			4955.438,
			4959.749,
			4962.632,
			4966.967,
			4969.866,
			4974.227,
			4977.143,
			4978.603,
			4981.529,
			4985.93,
			4988.872,
			4991.819,
			4996.246,
			4997.722,
			4999.195,
			5005.087,
			5006.543,
			5010.845,
			5013.691,
			5017.894,
			5020.652,
			5023.375,
			5028.74,
			5030.073,
			5035.42,
			5036.753,
			5040.761,
			5043.442,
			5046.13,
			5047.477,
			5050.149,
			5052.854,
			5056.923,
			5058.284,
			5061.034,
			5063.769,
			5067.889,
			5070.644,
			5074.764,
			5076.146,
			5077.53,
			5081.693,
			5084.477,
			5088.663,
			5091.458,
			5094.261,
			5095.686,
			5098.495,
			5099.878,
			5101.285,
			5106.929,
			5109.786,
			5112.629,
			5115.457,
			5119.75,
			5122.619,
			5125.495,
			5126.934,
			5129.817,
			5131.261,
			5134.154,
			5137.054,
			5141.415,
			5144.331,
			5148.72,
			5150.187,
			5151.654,
			5156.053,
			5158.972,
			5163.331,
			5166.158,
			5170.364,
			5173.12,
			5174.483,
			5177.182,
			5179.841,
			5181.156,
			5183.78,
			5186.345,
			5190.129,
			5191.374,
			5192.61,
			5196.271,
			5197.474,
			5198.669,
			5202.226,
			5204.575,
			5206.929,
			5210.455,
			5212.824,
			5216.381,
			5218.754,
			5222.302,
			5224.654,
			5228.127,
			5232.645,
			5233.752,
			5235.946,
			5237.03,
			5239.176,
			5242.346,
			5244.428,
			5246.488,
			5249.491,
			5251.466,
			5254.378,
			5255.326,
			5256.266,
			5259.045,
			5260.886,
			5263.654,
			5265.527,
			5267.382,
			5270.206,
			5271.145,
			5272.083,
			5275.869,
			5276.812,
			5279.652,
			5281.543,
			5284.376,
			5286.261,
			5288.137,
			5289.072,
			5290.935,
			5293.708,
			5295.538,
			5298.258,
			5300.059,
			5302.741,
			5303.632,
			5304.519,
			5307.156,
			5308.909,
			5311.521,
			5313.257,
			5315.854,
			5317.57,
			5318.429,
			5320.145,
			5321.847,
			5322.696,
			5323.545,
			5326.089,
			5327.78,
			5329.472,
			5330.317,
			5333.695,
			5334.547,
			5337.124,
			5338.874,
			5340.622,
			5343.271,
			5345.052,
			5347.748,
			5348.653,
			5349.562,
			5352.306,
			5354.152,
			5356.945,
			5358.824,
			5361.665,
			5364.538,
			5366.468,
			5369.391,
			5371.355,
			5373.334,
			5376.331,
			5378.345,
			5381.405,
			5383.46,
			5385.534,
			5387.602,
			5388.648,
			5390.755,
			5391.812,
			5393.935,
			5396.061,
			5399.246,
			5401.368,
			5404.567,
			5405.631,
			5406.694,
			5409.899,
			5412.037,
			5415.258,
			5417.419,
			5420.684,
			5422.884,
			5423.987,
			5426.201,
			5428.465,
			5429.59,
			5431.846,
			5434.12,
			5437.524,
			5439.799,
			5443.179,
			5444.298,
			5445.41,
			5448.714,
			5450.883,
			5453.018,
			5456.143,
			5458.191,
			5461.186,
			5463.164,
			5466.08,
			5468.02,
			5470.92,
			5472.851,
			5475.757,
			5477.678,
			5479.613,
			5480.58,
			5483.459,
			5485.359,
			5487.24,
			5490.014,
			5491.833,
			5494.533,
			5495.445,
			5496.338,
			5499.007,
			5500.783,
			5503.438,
			5505.2,
			5506.962,
			5509.593,
			5510.469,
			5511.353,
			5514.001,
			5514.884,
			5515.768,
			5518.458,
			5520.252,
			5522.057,
			5522.962,
			5524.772,
			5526.589,
			5527.502,
			5530.247,
			5532.084,
			5533.928,
			5536.705,
			5538.572,
			5541.388,
			5542.331,
			5543.271,
			5546.105,
			5547.997,
			5550.782,
			5552.646,
			5554.482,
			5555.388,
			5557.196,
			5559.88,
			5562.535,
			5564.298,
			5566.056,
			5568.682,
			5570.458,
			5572.235,
			5574.013,
			5574.915,
			5576.734,
			5577.643,
			5579.461,
			5581.32,
			5584.127,
			5586.015,
			5588.871,
			5589.824,
			5590.784,
			5593.675,
			5595.603,
			5598.49,
			5600.413,
			5602.331,
			5605.197,
			5608.037,
			5610.858,
			5612.685,
			5614.498,
			5617.203,
			5618.974,
			5621.627,
			5623.395,
			5626.044,
			5627.813,
			5630.492,
			5632.282,
			5634.084,
			5636.803,
			5638.611,
			5641.368,
			5643.221,
			5646.021,
			5647.904,
			5650.749,
			5652.659,
			5654.581,
			5655.545,
			5658.454,
			5660.408,
			5662.372,
			5665.339,
			5667.329,
			5670.335,
			5671.342,
			5672.352,
			5675.398,
			5677.441,
			5680.526,
			5682.594,
			5685.716,
			5687.806,
			5688.85,
			5689.894,
			5693.038,
			5694.092,
			5695.148,
			5698.333,
			5700.47,
			5702.616,
			5703.693,
			5705.852,
			5708.012,
			5709.091,
			5712.312,
			5714.436,
			5716.534,
			5719.619,
			5721.629,
			5724.575,
			5725.539,
			5726.493,
			5729.302,
			5731.131,
			5733.812,
			5735.56,
			5737.291,
			5738.139,
			5739.821,
			5740.653,
			5742.307,
			5744.766,
			5746.398,
			5748.028,
			5749.657,
			5752.113,
			5753.75,
			5755.39,
			5756.22,
			5758.706,
			5760.362,
			5762.039,
			5764.562,
			5766.244,
			5769.654,
			5770.506,
			5773.06,
			5774.758,
			5777.306,
			5779.004,
			5781.545,
			5783.258,
			5784.112,
			5785.817,
			5787.537,
			5790.133,
			5791.861,
			5794.455,
			5795.318,
			5796.182,
			5798.761,
			5799.617,
			5800.473,
			5803.037,
			5804.735,
			5806.432,
			5810.637,
			5813.155,
			5813.986,
			5814.817,
			5817.312,
			5818.976,
			5821.462,
			5823.125,
			5824.797,
			5825.622,
			5827.31,
			5829.011,
			5829.87,
			5832.472,
			5834.224,
			5836.004,
			5838.7,
			5840.54,
			5843.312,
			5844.24,
			5845.181,
			5848.03,
			5849.95,
			5852.902,
			5854.888,
			5856.907,
			5859.957,
			5860.982,
			5863.042,
			5866.158,
			5867.207,
			5870.365,
			5872.463,
			5875.625,
			5877.751,
			5880.961,
			5883.102,
			5884.174,
			5886.33,
			5888.491,
			5891.754,
			5893.921,
			5897.161,
			5899.328,
			5902.593,
			5904.773,
			5908.059,
			5910.255,
			5912.46,
			5913.549,
			5916.884,
			5919.108,
			5921.329,
			5922.443,
			5923.56,
			5926.918,
			5929.167,
			5932.564,
			5934.843,
			5937.142,
			5938.297,
			5941.783,
			5944.133,
			5946.494,
			5950.044,
			5952.438,
			5956.05,
			5957.261,
			5958.472,
			5962.119,
			5964.561,
			5968.237,
			5970.697,
			5974.406,
			5976.888,
			5978.133,
			5980.631,
			5984.397,
			5986.92,
			5989.454,
			5993.271,
			5994.549,
			5995.828,
			5999.678,
			6000.967,
			6002.257,
			6006.142,
			6008.743,
			6011.349,
			6015.263,
			6017.882,
			6021.827,
			6023.145,
			6024.465,
			6029.769,
			6031.099,
			6035.104,
			6037.785,
			6041.82,
			6044.521,
			6047.229,
			6048.586,
			6052.668,
			6058.136,
			6062.256,
			6065.011,
			6069.158,
			6070.544,
			6071.932,
			6076.105,
			6078.896,
			6083.095,
			6085.903,
			6088.717,
			6090.127,
			6092.951,
			6094.365,
			6097.198,
			6101.462,
			6102.887,
			6107.169,
			6110.031,
			6114.338,
			6117.215,
			6120.099,
			6121.543,
			6124.437,
			6125.886,
			6128.789,
			6131.698,
			6136.073,
			6138.997,
			6143.396,
			6144.865,
			6146.337,
			6150.758,
			6153.714,
			6158.158,
			6161.127,
			6165.587,
			6168.564,
			6170.052,
			6173.026,
			6175.995,
			6177.477,
			6180.437,
			6183.396,
			6189.341,
			6190.799,
			6193.771,
			6196.746,
			6198.235,
			6202.711,
			6205.704,
			6208.704,
			6213.215,
			6216.236,
			6220.78,
			6222.299,
			6223.819,
			6228.397,
			6231.463,
			6236.086,
			6239.181,
			6243.839,
			6246.958,
			6248.519,
			6251.642,
			6256.368,
			6259.511,
			6262.646,
			6267.333,
			6270.508,
			6275.239,
			6276.816,
			6278.394,
			6283.106,
			6286.271,
			6289.438,
			6294.218,
			6297.364,
			6302.129,
			6303.719,
			6305.31,
			6310.086,
			6313.275,
			6318.064,
			6326.05,
			6329.248,
			6330.847,
			6334.047,
			6338.853,
			6342.061,
			6345.269,
			6350.089,
			6353.306,
			6358.134,
			6359.746,
			6361.357,
			6366.195,
			6369.423,
			6374.268,
			6377.5,
			6380.732,
			6385.587,
			6387.207,
			6388.827,
			6393.688,
			6395.31,
			6396.931,
			6401.805,
			6405.052,
			6409.923,
			6413.172,
			6416.423,
			6418.046,
			6422.908,
			6426.137,
			6429.359,
			6434.153,
			6437.314,
			6442.006,
			6443.583,
			6445.137,
			6449.781,
			6452.871,
			6457.496,
			6460.573,
			6465.156,
			6468.227,
			6469.76,
			6472.829,
			6477.452,
			6480.49,
			6483.556,
			6486.625,
			6489.695,
			6491.23,
			6494.302,
			6497.375,
			6498.913,
			6503.531,
			6506.613,
			6509.699,
			6514.334,
			6517.427,
			6522.067,
			6523.609,
			6525.149,
			6529.74,
			6531.258,
			6532.768,
			6537.248,
			6540.188,
			6543.087,
			6544.523,
			6547.366,
			6548.773,
			6551.56,
			6555.697,
			6561.057,
			6564.993,
			6567.561,
			6570.128,
			6572.683,
			6573.956,
			6576.497,
			6577.765,
			6580.294,
			6582.837,
			6586.628,
			6589.158,
			6592.967,
			6594.245,
			6595.525,
			6599.38,
			6601.972,
			6605.863,
			6608.458,
			6612.349,
			6614.94,
			6617.517,
			6618.799,
			6621.36,
			6622.637,
			6625.175,
			6627.706,
			6631.477,
			6632.73,
			6633.982,
			6637.725,
			6638.969,
			6640.213,
			6643.936,
			6646.407,
			6650.106,
			6652.548,
			6655.033,
			6658.736,
			6659.973,
			6661.211,
			6664.938,
			6666.184,
			6667.43,
			6671.18,
			6673.685,
			6677.448,
			6679.963,
			6683.746,
			6687.541,
			6690.079,
			6692.622,
			6696.447,
			6699.003,
			6702.851,
			6705.429,
			6709.315,
			6711.922,
			6715.854,
			6718.489,
			6722.469,
			6725.133,
			6729.162,
			6733.205,
			6735.953,
			6738.687,
			6741.425,
			6745.526,
			6750.97,
			6752.336,
			6756.434,
			6759.168,
			6761.901,
			6766.008,
			6768.744,
			6774.231,
			6775.604,
			6779.732,
			6782.492,
			6786.646,
			6789.422,
			6792.209,
			6793.605,
			6796.403,
			6797.805,
			6800.613,
			6804.838,
			6807.661,
			6810.491,
			6814.747,
			6817.592,
			6821.869,
			6823.298,
			6824.729,
			6827.595,
			6829.029,
			6831.906,
			6834.788,
			6839.125,
			6842.024,
			6846.385,
			6847.842,
			6849.3,
			6853.686,
			6856.62,
			6861.036,
			6863.989,
			6868.437,
			6871.411,
			6875.885,
			6878.876,
			6880.375,
			6883.378,
			6886.388,
			6890.911,
			6893.93,
			6899.972,
			6901.484,
			6906.019,
			6909.044,
			6912.072,
			6916.618,
			6919.655,
			6924.22,
			6925.745,
			6927.272,
			6931.866,
			6933.401,
			6934.938,
			6939.561,
			6942.651,
			6947.3,
			6950.406,
			6953.517,
			6955.075,
			6959.752,
			6962.877,
			6966.009,
			6970.712,
			6973.854,
			6978.576,
			6980.152,
			6981.729,
			6986.467,
			6989.631,
			6994.384,
			6997.557,
			7002.324,
			7005.507,
			7007.099,
			7010.282,
			7015.062,
			7018.252,
			7021.445,
			7024.644,
			7027.847,
			7029.45,
			7035.877,
			7037.487,
			7040.71,
			7042.324,
			7045.556,
			7048.794,
			7053.661,
			7056.916,
			7061.809,
			7063.443,
			7065.079,
			7069.993,
			7073.274,
			7078.203,
			7081.492,
			7084.783,
			7086.429,
			7089.724,
			7091.373,
			7094.672,
			7097.974,
			7099.626,
			7102.935,
			7106.247,
			7111.224,
			7114.547,
			7117.875,
			7121.208,
			7122.875,
			7126.214,
			7131.23,
			7134.58,
			7142.973,
			7149.708,
			7151.395,
			7156.462,
			7164.934,
			7168.331,
			7173.438,
			7176.848,
			7178.554,
			7181.972,
			7185.394,
			7187.107,
			7190.538,
			7193.974,
			7199.137,
			7200.861,
			7202.585,
			7207.768,
			7209.498,
			7211.23,
			7216.434,
			7219.909,
			7225.132,
			7228.622,
			7232.118,
			7237.374,
			7239.129,
			7240.886,
			7247.928,
			7249.693,
			7254.997,
			7258.539,
			7263.865,
			7267.424,
			7270.989,
			7272.775,
			7278.14,
			7281.722,
			7285.311,
			7290.705,
			7294.305,
			7299.712,
			7301.517,
			7303.324,
			7308.748,
			7312.369,
			7317.812,
			7321.445,
			7326.907,
			7330.552,
			7332.377,
			7334.204,
			7341.521,
			7343.353,
			7348.858,
			7352.535,
			7356.215,
			7358.057,
			7365.441,
			7367.289,
			7372.872,
			7376.58,
			7380.291,
			7385.833,
			7389.554,
			7395.141,
			7397.006,
			7398.871,
			7404.469,
			7408.206,
			7413.818,
			7417.563,
			7421.31,
			7423.185,
			7426.936,
			7428.812,
			7432.567,
			7438.205,
			7441.967,
			7445.729,
			7451.378,
			7455.147,
			7460.803,
			7462.689,
			7464.577,
			7470.242,
			7474.021,
			7477.803,
			7483.479,
			7487.266,
			7492.95,
			7494.846,
			7496.742,
			7502.435,
			7506.233,
			7511.933,
			7515.733,
			7521.438,
			7525.242,
			7527.146,
			7530.953,
			7536.667,
			7540.479,
			7544.291,
			7550.012,
			7551.918,
			7553.824,
			7559.538,
			7561.441,
			7563.344,
			7569.033,
			7572.807,
			7578.438,
			7582.169,
			7585.874,
			7591.382,
			7593.21,
			7595.038,
			7600.545,
			7602.371,
			7604.197,
			7609.64,
			7613.289,
			7616.94,
			7618.768,
			7622.421,
			7626.076,
			7627.906,
			7633.399,
			7637.064,
			7640.734,
			7646.246,
			7649.924,
			7655.448,
			7657.292,
			7659.137,
			7664.674,
			7668.37,
			7673.923,
			7677.627,
			7681.335,
			7683.19,
			7686.901,
			7688.758,
			7692.476,
			7698.055,
			7699.914,
			7705.478,
			7709.159,
			7714.619,
			7718.212,
			7723.531,
			7727.028,
			7730.488,
			7732.204,
			7735.634,
			7737.32,
			7740.663,
			7743.969,
			7748.831,
			7752.044,
			7756.814,
			7758.35,
			7759.902,
			7764.54,
			7767.58,
			7772.099,
			7775.062,
			7779.519,
			7782.418,
			7783.873,
			7786.772,
			7789.675,
			7791.111,
			7793.978,
			7796.831,
			7799.654,
			7802.486,
			7803.897,
			7806.714,
			7809.529,
			7810.938,
			7815.161,
			7817.976,
			7822.204,
			7824.988,
			7827.753,
			7831.86,
			7833.235,
			7834.608,
			7838.722,
			7841.465,
			7845.591,
			7848.375,
			7851.148,
			7852.54,
			7855.328,
			7859.523,
			7863.735,
			7866.534,
			7869.363,
			7873.622,
			7876.5,
			7879.362,
			7882.235,
			7883.675,
			7887.983,
			7890.877,
			7895.25,
			7898.165,
			7901.091,
			7905.495,
			7906.967,
			7908.414,
			7914.317,
			7915.796,
			7920.268,
			7926.218,
			7927.71,
			7930.695,
			7933.662,
			7935.16,
			7938.187,
			7942.704,
			7945.726,
			7950.272,
			7953.287,
			7957.859,
			7959.412,
			7960.941,
			7965.535,
			7968.604,
			7973.219,
			7976.275,
			7979.363,
			7984.004,
			7985.554,
			7987.105,
			7991.769,
			7993.325,
			7994.885,
			7999.568,
			8002.692,
			8005.812,
			8007.365,
			8010.458,
			8013.525,
			8015.047,
			8019.557,
			8022.514,
			8025.434,
			8029.745,
			8032.571,
			8036.74,
			8038.111,
			8039.474,
			8043.505,
			8046.146,
			8050.039,
			8052.588,
			8055.101,
			8056.343,
			8058.801,
			8060.017,
			8062.421,
			8065.964,
			8068.285,
			8070.569,
			8073.928,
			8076.122,
			8078.282,
			8081.456,
			8083.527,
			8084.549,
			8086.566,
			8088.548,
			8091.456,
			8093.352,
			8096.133,
			8097.045,
			8097.951,
			8100.639,
			8102.389,
			8104.985,
			8108.401,
			8109.252,
			8110.959,
			8113.512,
			8115.215,
			8116.072,
			8117.785,
			8119.498,
			8122.08,
			8123.811,
			8126.407,
			8127.282,
			8128.157,
			8130.781,
			8132.529,
			8135.139,
			8136.875,
			8138.618,
			8141.249,
			8142.124,
			8143,
			8145.63,
			8147.381,
			8150.018,
			8151.793,
			8154.454,
			8156.254,
			8158.071,
			8158.979,
			8160.789,
			8161.688,
			8163.48,
			8165.272,
			8167.982,
			8169.798,
			8172.523,
			8173.429,
			8174.335,
			8177.059,
			8178.875,
			8181.606,
			8183.44,
			8186.199,
			8188.051,
			8188.984,
			8189.918,
			8192.725,
			8193.667,
			8194.611,
			8197.443,
			8199.339,
			8201.234,
			8202.184,
			8204.081,
			8205.987,
			8206.939,
			8209.802,
			8211.717,
			8213.633,
			8216.515,
			8218.439,
			8221.338,
			8222.303,
			8223.27,
			8226.18,
			8228.125,
			8231.051,
			8233.013,
			8235.951,
			8237.922,
			8238.912,
			8240.882,
			8243.858,
			8245.851,
			8247.848,
			8250.869,
			8251.879,
			8252.891,
			8254.923,
			8256.969,
			8257.995,
			8261.082,
			8263.156,
			8265.244,
			8268.374,
			8270.476,
			8273.652,
			8274.719,
			8275.793,
			8279.034,
			8281.194,
			8284.485,
			8286.694,
			8288.916,
			8290.032,
			8292.271,
			8295.654,
			8297.922,
			8301.348,
			8303.644,
			8307.109,
			8309.432,
			8311.767,
			8314.112,
			8315.289,
			8317.652,
			8318.839,
			8321.22,
			8323.611,
			8327.223,
			8329.644,
			8333.295,
			8334.518,
			8335.742,
			8339.433,
			8341.904,
			8345.633,
			8348.131,
			8351.896,
			8354.416,
			8355.681,
			8358.217,
			8360.762,
			8362.037,
			8364.597,
			8367.164,
			8371.034,
			8373.626,
			8377.531,
			8378.836,
			8380.143,
			8384.064,
			8386.689,
			8390.643,
			8393.289,
			8395.943,
			8399.942,
			8401.279,
			8402.619,
			8406.649,
			8407.998,
			8409.348,
			8413.41,
			8416.128,
			8418.854,
			8420.222,
			8422.962,
			8425.711,
			8427.09,
			8431.237,
			8434.014,
			8436.802,
			8441,
			8443.81,
			8449.459,
			8450.877,
			8455.146,
			8458.006,
			8462.311,
			8465.217,
			8469.561,
			8472.469,
			8473.903,
			8476.848,
			8481.252,
			8484.2,
			8487.157,
			8490.126,
			8493.104,
			8494.596,
			8497.588,
			8500.588,
			8502.092,
			8505.078,
			8509.636,
			8512.668,
			8521.801,
			8524.828,
			8526.356,
			8529.417,
			8532.478,
			8537.08,
			8540.154,
			8544.798,
			8546.342,
			8547.888,
			8552.534,
			8555.615,
			8560.316,
			8563.445,
			8568.154,
			8571.311,
			8576.034,
			8579.215,
			8580.809,
			8584.004,
			8587.209,
			8592.014,
			8595.222,
			8600.04,
			8601.65,
			8603.287,
			8608.129,
			8611.332,
			8614.569,
			8619.432,
			8622.704,
			8627.57,
			8629.193,
			8630.814,
			8635.649,
			8638.922,
			8643.765,
			8647.014,
			8651.917,
			8655.169,
			8658.427,
			8660.059,
			8664.955,
			8668.228,
			8671.479,
			8676.402,
			8679.688,
			8684.656,
			8687.954,
			8692.885,
			8696.195,
			8701.175,
			8704.5,
			8707.831,
			8712.838,
			8714.51,
			8716.183,
			8722.891,
			8724.57,
			8729.621,
			8732.996,
			8736.377,
			8738.069,
			8741.459,
			8744.855,
			8746.557,
			8749.964,
			8751.669,
			8755.086,
			8758.509,
			8763.656,
			8767.094,
			8772.262,
			8773.986,
			8775.713,
			8780.899,
			8784.365,
			8789.573,
			8793.052,
			8796.538,
			8798.283,
			8801.778,
			8807.034,
			8812.305,
			8815.826,
			8819.354,
			8822.89,
			8828.234,
			8833.538,
			8835.318,
			8837.101,
			8840.671,
			8846.069,
			8849.658,
			8855.023,
			8858.629,
			8864.049,
			8865.857,
			8867.67,
			8873.106,
			8876.735,
			8882.217,
			8885.855,
			8891.284,
			8894.928,
			8896.751,
			8900.399,
			8904.053,
			8909.541,
			8913.205,
			8918.709,
			8920.547,
			8922.384,
			8927.905,
			8929.748,
			8931.594,
			8937.135,
			8940.836,
			8946.398,
			8950.113,
			8953.836,
			8959.464,
			8961.301,
			8963.171,
			8968.79,
			8970.666,
			8972.542,
			8978.18,
			8981.941,
			8985.708,
			8987.593,
			8991.366,
			8993.253,
			8997.03,
			9002.701,
			9006.519,
			9010.309,
			9016.001,
			9019.801,
			9025.51,
			9027.414,
			9029.32,
			9035.044,
			9038.861,
			9044.595,
			9048.423,
			9052.253,
			9054.166,
			9057.997,
			9059.912,
			9061.828,
			9069.48,
			9071.394,
			9077.131,
			9080.918,
			9084.725,
			9086.653,
			9090.402,
			9094.15,
			9096.011,
			9099.699,
			9101.527,
			9105.151,
			9108.736,
			9114.031,
			9117.507,
			9122.645,
			9124.337,
			9126.02,
			9131.009,
			9134.284,
			9139.124,
			9142.3,
			9145.435,
			9146.986,
			9151.582,
			9154.597,
			9159.049,
			9161.968,
			9164.851,
			9169.102,
			9171.888,
			9174.637,
			9177.348,
			9178.688,
			9182.656,
			9185.258,
			9187.824,
			9194.116,
			9199.015,
			9200.209,
			9203.84,
			9206.256,
			9209.872,
			9212.279,
			9215.88,
			9218.279,
			9219.5,
			9221.901,
			9225.524,
			9227.953,
			9230.396,
			9234.083,
			9236.562,
			9240.292,
			9242.803,
			9246.577,
			9249.099,
			9251.619,
			9255.354,
			9257.85,
			9261.61,
			9262.85,
			9264.091,
			9267.821,
			9270.289,
			9274.033,
			9276.536,
			9280.307,
			9282.829,
			9284.095,
			9286.627,
			9290.432,
			9292.959,
			9295.469,
			9299.182,
			9301.613,
			9306.367,
			9307.532,
			9310.974,
			9313.219,
			9316.52,
			9318.673,
			9320.789,
			9323.897,
			9325.924,
			9329.883,
			9330.855,
			9333.772,
			9335.693,
			9338.567,
			9340.48,
			9342.391,
			9343.35,
			9345.271,
			9346.23,
			9348.152,
			9350.087,
			9352.992,
			9354.947,
			9357.895,
			9359.867,
			9362.851,
			9364.842,
			9369.837,
			9372.836,
			9374.833,
			9375.834,
			9377.834,
			9379.833,
			9380.832,
			9382.825,
			9384.816,
			9386.811,
			9389.8,
			9391.801,
			9394.807,
			9397.791,
			9399.774,
			9401.762,
			9404.762,
			9406.767,
			9409.763,
			9410.763,
			9411.764,
			9414.771,
			9415.776,
			9416.784,
			9419.809,
			9421.814,
			9424.845,
			9426.855,
			9428.85,
			9429.838,
			9431.794,
			9432.76,
			9434.664,
			9437.451,
			9439.265,
			9441.045,
			9443.65,
			9445.344,
			9446.999,
			9448.617,
			9449.415,
			9450.982,
			9451.752,
			9453.276,
			9454.762,
			9458.354,
			9460.455,
			9461.152,
			9461.846,
			9463.917,
			9465.293,
			9467.356,
			9468.729,
			9470.784,
			9472.158,
			9472.847,
			9474.223,
			9475.598,
			9477.663,
			9479.047,
			9481.117,
			9482.496,
			9484.571,
			9485.264,
			9485.955,
			9488.025,
			9489.405,
			9490.79,
			9492.859,
			9494.236,
			9497.003,
			9497.694,
			9499.766,
			9500.456,
			9501.156,
			9503.26,
			9506.058,
			9506.789,
			9508.252,
			9509.712,
			9510.441,
			9512.68,
			9514.19,
			9515.699,
			9517.967,
			9519.479,
			9521.742,
			9522.496,
			9523.251,
			9525.448,
			9526.908,
			9529.093,
			9531.892,
			9532.59,
			9533.989,
			9534.69,
			9536.072,
			9538.146,
			9538.838,
			9540.921,
			9542.297,
			9543.687,
			9545.768,
			9547.148,
			9547.838,
			9549.213,
			9549.898,
			9551.264,
			9552.633,
			9554.687,
			9556.044,
			9558.064,
			9558.748,
			9559.432,
			9561.468,
			9562.814,
			9564.855,
			9566.225,
			9567.586,
			9568.266,
			9570.33,
			9571.716,
			9573.098,
			9573.787,
			9575.174,
			9576.565,
			9578.649,
			9580.041,
			9581.436,
			9582.83,
			9583.527,
			9585.625,
			9587.016,
			9588.4,
			9590.485,
			9591.884,
			9594.668,
			9595.367,
			9597.477,
			9598.183,
			9598.888,
			9601.012,
			9602.439,
			9604.568,
			9605.99,
			9607.422,
			9608.144,
			9610.315,
			9611.771,
			9613.238,
			9615.47,
			9616.975,
			9619.259,
			9620.027,
			9620.8,
			9623.152,
			9624.74,
			9627.152,
			9628.781,
			9630.428,
			9632.922,
			9633.758,
			9634.594,
			9637.951,
			9638.799,
			9641.349,
			9643.074,
			9644.814,
			9645.689,
			9647.451,
			9650.119,
			9652.822,
			9654.642,
			9656.473,
			9659.24,
			9661.103,
			9663.921,
			9664.867,
			9665.816,
			9668.685,
			9670.613,
			9673.529,
			9675.489,
			9678.454,
			9680.446,
			9681.447,
			9683.459,
			9686.495,
			9687.513,
			9690.586,
			9692.652,
			9695.773,
			9697.863,
			9699.954,
			9701.001,
			9703.104,
			9706.278,
			9708.408,
			9711.624,
			9713.781,
			9717.037,
			9718.129,
			9719.223,
			9722.52,
			9728.069,
			9730.307,
			9733.687,
			9735.952,
			9739.372,
			9741.666,
			9742.816,
			9745.128,
			9747.449,
			9750.951,
			9752.125,
			9753.301,
			9755.659,
			9758.029,
			9759.217,
			9762.799,
			9765.199,
			9767.61,
			9771.244,
			9773.681,
			9777.352,
			9778.58,
			9779.811,
			9783.516,
			9785.998,
			9789.742,
			9792.25,
			9794.77,
			9796.033,
			9798.566,
			9802.39,
			9806.238,
			9808.814,
			9811.4,
			9815.298,
			9817.91,
			9821.845,
			9823.158,
			9824.473,
			9827.103,
			9831.062,
			9835.034,
			9837.689,
			9840.354,
			9844.367,
			9845.707,
			9847.048,
			9851.081,
			9853.776,
			9857.831,
			9860.54,
			9863.255,
			9864.614,
			9871.429,
			9874.163,
			9875.533,
			9878.275,
			9881.024,
			9885.161,
			9887.928,
			9892.092,
			9893.484,
			9894.879,
			9899.072,
			9901.879,
			9906.104,
			9908.93,
			9911.761,
			9913.18,
			9917.445,
			9918.872,
			9923.16,
			9924.592,
			9926.026,
			9930.338,
			9933.221,
			9937.558,
			9940.457,
			9943.361,
			9944.816,
			9949.189,
			9952.113,
			9955.043,
			9959.451,
			9962.397,
			9966.828,
			9968.308,
			9969.789,
			9975.725,
			9977.213,
			9981.685,
			9984.674,
			9989.17,
			9992.176,
			9993.681,
			9996.695,
			9999.716,
			10001.229,
			10004.259,
			10007.295,
			10011.86,
			10013.385,
			10014.911,
			10019.5,
			10021.031,
			10022.565,
			10027.176,
			10030.258,
			10033.345,
			10037.984,
			10041.084,
			10045.745,
			10047.301,
			10048.857,
			10053.538,
			10056.663,
			10061.362,
			10064.501,
			10067.646,
			10069.22,
			10072.373,
			10077.113,
			10081.864,
			10085.039,
			10088.22,
			10092.994,
			10096.208,
			10100.997,
			10104.17,
			10108.982,
			10112.199,
			10115.424,
			10120.273,
			10123.514,
			10128.387,
			10130.012,
			10131.639,
			10136.522,
			10139.781,
			10144.671,
			10147.933,
			10152.824,
			10156.084,
			10157.712,
			10160.964,
			10164.204,
			10165.82,
			10169.037,
			10172.227,
			10176.946,
			10180.046,
			10184.631,
			10186.145,
			10187.648,
			10192.141,
			10195.078,
			10199.422,
			10202.281,
			10206.523,
			10209.327,
			10210.725,
			10212.118,
			10217.675,
			10219.06,
			10223.207,
			10225.971,
			10230.117,
			10232.886,
			10235.652,
			10237.036,
			10241.206,
			10243.988,
			10246.769,
			10250.952,
			10253.753,
			10259.381,
			10260.792,
			10265.035,
			10267.866,
			10272.097,
			10274.904,
			10279.107,
			10281.901,
			10283.298,
			10286.11,
			10290.335,
			10293.149,
			10295.94,
			10298.721,
			10301.504,
			10302.895,
			10305.679,
			10309.844,
			10312.607,
			10313.992,
			10316.75,
			10319.496,
			10323.622,
			10326.367,
			10330.489,
			10331.842,
			10333.217,
			10337.364,
			10340.116,
			10344.249,
			10347.008,
			10349.771,
			10351.155,
			10355.297,
			10358.082,
			10360.874,
			10362.273,
			10365.078,
			10367.891,
			10372.13,
			10374.965,
			10379.234,
			10382.076,
			10384.904,
			10386.311,
			10389.101,
			10391.858,
			10395.925,
			10398.588,
			10402.512,
			10403.801,
			10405.082,
			10408.863,
			10411.338,
			10414.982,
			10417.367,
			10420.881,
			10425.443,
			10426.561,
			10428.767,
			10429.856,
			10432.01,
			10434.126,
			10437.231,
			10439.256,
			10442.227,
			10443.199,
			10444.178,
			10447.012,
			10448.852,
			10451.55,
			10453.31,
			10455.895,
			10458.419,
			10459.252,
			10461.734,
			10462.554,
			10463.369,
			10465.817,
			10467.452,
			10469.896,
			10473.2,
			10474.026,
			10476.514,
			10478.209,
			10479.901,
			10482.459,
			10484.182,
			10486.765,
			10487.624,
			10488.481,
			10491.053,
			10492.769,
			10495.339,
			10497.047,
			10499.611,
			10501.322,
			10502.175,
			10503.88,
			10506.429,
			10508.121,
			10509.813,
			10511.504,
			10514.036,
			10515.73,
			10518.272,
			10520.824,
			10522.529,
			10524.236,
			10526.811,
			10528.535,
			10532.007,
			10532.882,
			10535.504,
			10537.275,
			10539.955,
			10541.752,
			10544.471,
			10546.299,
			10547.217,
			10549.061,
			10550.917,
			10553.724,
			10555.621,
			10558.457,
			10560.362,
			10562.278,
			10564.203,
			10565.17,
			10568.109,
			10570.089,
			10572.079,
			10575.107,
			10577.143,
			10580.222,
			10582.289,
			10585.402,
			10587.482,
			10590.607,
			10592.686,
			10595.804,
			10597.881,
			10598.918,
			10600.995,
			10604.104,
			10606.174,
			10608.249,
			10611.365,
			10613.449,
			10616.59,
			10617.635,
			10618.682,
			10621.831,
			10623.927,
			10627.121,
			10629.265,
			10632.5,
			10634.672,
			10636.855,
			10640.149,
			10641.253,
			10642.358,
			10645.692,
			10647.929,
			10650.177,
			10651.305,
			10653.567,
			10655.842,
			10656.983,
			10662.726,
			10665.04,
			10668.533,
			10670.875,
			10675.584,
			10676.764,
			10680.293,
			10682.625,
			10686.075,
			10688.336,
			10690.562,
			10691.661,
			10693.836,
			10694.91,
			10697.036,
			10700.162,
			10702.203,
			10704.21,
			10707.16,
			10709.085,
			10710.977,
			10713.75,
			10715.56,
			10718.217,
			10719.947,
			10722.479,
			10724.134,
			10727.386,
			10728.19,
			10730.609,
			10732.205,
			10734.587,
			10736.172,
			10738.543,
			10740.121,
			10740.911,
			10742.502,
			10744.091,
			10744.885,
			10746.477,
			10748.086,
			10750.503,
			10751.31,
			10752.116,
			10754.566,
			10755.387,
			10756.205,
			10758.665,
			10760.337,
			10762.006,
			10764.518,
			10766.212,
			10769.597,
			10770.45,
			10773.029,
			10774.744,
			10777.325,
			10779.045,
			10781.622,
			10783.354,
			10785.096,
			10785.965,
			10788.579,
			10790.324,
			10792.065,
			10794.677,
			10796.432,
			10799.928,
			10800.801,
			10803.431,
			10805.182,
			10807.807,
			10809.561,
			10811.313,
			10813.944,
			10814.814,
			10815.68,
			10819.139,
			10820.005,
			10822.566,
			10825.966,
			10828.48,
			10830.981,
			10833.481,
			10835.143,
			10836.804,
			10839.298,
			10840.956,
			10843.498,
			10844.346,
			10845.197,
			10847.774,
			10849.521,
			10852.166,
			10853.947,
			10856.635,
			10858.459,
			10859.376,
			10861.221,
			10863.08,
			10864.016,
			10864.954,
			10867.793,
			10870.665,
			10872.598,
			10874.547,
			10876.509,
			10877.494,
			10880.477,
			10882.483,
			10884.502,
			10887.558,
			10889.609,
			10892.713,
			10894.798,
			10897.942,
			10900.043,
			10903.203,
			10905.32,
			10908.521,
			10910.666,
			10911.744,
			10913.907,
			10917.175,
			10919.367,
			10921.57,
			10924.898,
			10926.014,
			10927.131,
			10930.503,
			10931.633,
			10932.766,
			10936.181,
			10938.472,
			10941.93,
			10944.249,
			10946.58,
			10950.095,
			10951.273,
			10952.453,
			10956.012,
			10958.396,
			10961.99,
			10966.823,
			10968.038,
			10970.476,
			10972.92,
			10974.146,
			10977.839,
			10980.312,
			10982.792,
			10986.518,
			10988.992,
			10992.682,
			10993.901,
			10995.134,
			10998.734,
			11001.095,
			11004.58,
			11006.865,
			11009.129,
			11010.255,
			11012.496,
			11013.613,
			11014.729,
			11018.07,
			11019.181,
			11020.289,
			11023.603,
			11025.806,
			11029.109,
			11031.311,
			11033.516,
			11034.621,
			11036.83,
			11037.935,
			11040.156,
			11042.384,
			11045.733,
			11047.975,
			11051.349,
			11052.479,
			11053.609,
			11057.026,
			11059.322,
			11062.788,
			11065.113,
			11068.625,
			11070.983,
			11074.547,
			11076.938,
			11080.523,
			11082.926,
			11085.342,
			11087.769,
			11088.979,
			11091.404,
			11093.836,
			11095.049,
			11098.696,
			11101.12,
			11103.553,
			11107.209,
			11109.655,
			11113.358,
			11114.601,
			11115.845,
			11119.614,
			11122.146,
			11125.98,
			11128.562,
			11131.161,
			11132.466,
			11135.08,
			11136.388,
			11139.008,
			11142.944,
			11145.562,
			11152.095,
			11154.702,
			11158.605,
			11159.885,
			11161.189,
			11163.818,
			11165.125,
			11167.738,
			11170.354,
			11174.291,
			11176.9,
			11180.865,
			11182.19,
			11183.518,
			11187.535,
			11190.208,
			11194.235,
			11196.908,
			11200.969,
			11203.684,
			11207.747,
			11210.461,
			11211.798,
			11214.452,
			11217.054,
			11220.928,
			11223.467,
			11227.216,
			11228.447,
			11229.67,
			11233.31,
			11235.685,
			11239.191,
			11241.493,
			11243.769,
			11247.145,
			11248.26,
			11249.369,
			11252.678,
			11254.867,
			11258.14,
			11260.318,
			11263.58,
			11265.76,
			11267.934,
			11269.02,
			11272.283,
			11274.455,
			11276.627,
			11279.88,
			11282.042,
			11285.275,
			11286.351,
			11290.641,
			11292.779,
			11295.975,
			11298.096,
			11301.271,
			11303.385,
			11304.441,
			11306.555,
			11309.731,
			11311.858,
			11313.99,
			11317.205,
			11318.283,
			11319.361,
			11321.526,
			11323.703,
			11324.795,
			11326.981,
			11328.077,
			11330.273,
			11333.578,
			11335.796,
			11338.021,
			11342.514,
			11343.643,
			11347.05,
			11349.337,
			11352.791,
			11355.115,
			11357.449,
			11358.621,
			11360.973,
			11362.152,
			11364.521,
			11368.096,
			11370.467,
			11372.832,
			11376.376,
			11378.729,
			11382.256,
			11383.428,
			11384.602,
			11388.122,
			11390.467,
			11393.986,
			11396.334,
			11398.685,
			11402.222,
			11403.404,
			11404.59,
			11408.16,
			11410.553,
			11414.162,
			11416.585,
			11420.241,
			11423.922,
			11426.387,
			11428.857,
			11430.096,
			11432.577,
			11435.064,
			11438.812,
			11441.318,
			11445.068,
			11446.33,
			11447.594,
			11451.395,
			11453.939,
			11457.773,
			11460.338,
			11462.915,
			11466.795,
			11469.393,
			11474.605,
			11475.911,
			11479.838,
			11482.466,
			11486.424,
			11489.072,
			11491.729,
			11493.06,
			11497.064,
			11499.744,
			11502.432,
			11506.479,
			11509.187,
			11513.264,
			11514.627,
			11515.991,
			11520.098,
			11522.847,
			11526.984,
			11529.754,
			11533.922,
			11536.712,
			11538.109,
			11540.906,
			11545.085,
			11546.469,
			11550.577,
			11554.606,
			11555.932,
			11557.247,
			11559.852,
			11563.691,
			11567.45,
			11569.91,
			11572.334,
			11575.908,
			11578.25,
			11581.701,
			11582.833,
			11583.956,
			11587.271,
			11589.438,
			11592.622,
			11594.701,
			11596.744,
			11597.752,
			11599.742,
			11601.699,
			11602.664,
			11604.572,
			11605.516,
			11607.376,
			11609.229,
			11611.941,
			11613.75,
			11616.5,
			11617.418,
			11618.34,
			11621.13,
			11623.008,
			11624.896,
			11627.767,
			11629.688,
			11632.586,
			11633.551,
			11637.392,
			11639.313,
			11642.179,
			11644.07,
			11646.891,
			11648.767,
			11649.696,
			11651.543,
			11653.332,
			11654.22,
			11655.978,
			11657.712,
			11660.238,
			11661.069,
			11661.892,
			11664.309,
			11665.098,
			11665.878,
			11669.609,
			11671.745,
			11673.127,
			11675.138,
			11676.439,
			11677.081,
			11677.715,
			11679.57,
			11680.182,
			11680.787,
			11682.574,
			11683.76,
			11684.933,
			11685.527,
			11686.718,
			11687.908,
			11688.504,
			11690.298,
			11691.506,
			11694.6,
			11695.835,
			11697.702,
			11698.338,
			11698.971,
			11700.865,
			11702.125,
			11704.035,
			11705.316,
			11706.592,
			11707.227,
			11708.496,
			11709.139,
			11710.418,
			11712.314,
			11712.943,
			11714.839,
			11716.09,
			11717.944,
			11719.175,
			11720.431,
			11721.06,
			11722.307,
			11722.926,
			11724.16,
			11726.026,
			11727.297,
			11728.557,
			11730.438,
			11731.066,
			11731.698,
			11733.615,
			11734.887,
			11736.797,
			11738.073,
			11739.984,
			11741.261,
			11743.172,
			11744.42,
			11745.047,
			11746.3,
			11747.561,
			11749.424,
			11750.043,
			11750.664,
			11753.173,
			11753.806,
			11755.713,
			11756.998,
			11758.296,
			11760.256,
			11761.59,
			11763.625,
			11764.312,
			11765.004,
			11767.115,
			11767.842,
			11768.561,
			11770.744,
			11773.725,
			11774.484,
			11777.564,
			11778.343,
			11780.72,
			11782.369,
			11784.033,
			11786.56,
			11788.259,
			11790.832,
			11791.697,
			11792.568,
			11795.24,
			11797.031,
			11799.724,
			11801.543,
			11803.371,
			11807.062,
			11807.99,
			11811.72,
			11812.658,
			11815.446,
			11817.299,
			11819.16,
			11821.968,
			11823.848,
			11824.792,
			11826.688,
			11827.626,
			11829.513,
			11831.413,
			11834.271,
			11836.193,
			11839.107,
			11840.086,
			11841.068,
			11844.037,
			11846.038,
			11849.045,
			11851.096,
			11853.141,
			11854.169,
			11856.216,
			11857.252,
			11859.334,
			11861.426,
			11863.52,
			11866.667,
			11869.837,
			11871.964,
			11876.251,
			11877.33,
			11879.494,
			11880.582,
			11882.766,
			11884.961,
			11888.275,
			11890.498,
			11893.855,
			11894.979,
			11896.107,
			11899.508,
			11905.232,
			11907.542,
			11911.027,
			11914.538,
			11916.892,
			11919.256,
			11920.442,
			11922.824,
			11925.216,
			11928.824,
			11931.243,
			11933.672,
			11936.113,
			11937.337,
			11941.025,
			11943.499,
			11949.725,
			11952.233,
			11956.016,
			11957.281,
			11958.551,
			11962.371,
			11964.932,
			11968.791,
			11973.972,
			11975.272,
			11977.879,
			11981.794,
			11985.723,
			11988.354,
			11994.965,
			11997.625,
			12001.631,
			12002.971,
			12004.312,
			12008.349,
			12011.051,
			12015.117,
			12017.837,
			12020.566,
			12024.674,
			12026.047,
			12027.422,
			12032.947,
			12034.334,
			12038.513,
			12041.312,
			12045.527,
			12048.353,
			12052.634,
			12055.494,
			12059.797,
			12062.678,
			12067.015,
			12069.92,
			12074.271,
			12075.734,
			12077.195,
			12081.607,
			12084.54,
			12088.961,
			12091.893,
			12094.852,
			12096.333,
			12099.296,
			12102.25,
			12106.658,
			12108.116,
			12109.567,
			12113.877,
			12116.705,
			12120.875,
			12126.301,
			12127.633,
			12131.577,
			12134.158,
			12136.705,
			12140.457,
			12142.911,
			12148.896,
			12152.389,
			12154.674,
			12158.041,
			12160.242,
			12163.482,
			12165.599,
			12168.709,
			12171.745,
			12173.725,
			12175.672,
			12178.553,
			12180.438,
			12182.309,
			12184.166,
			12186.938,
			12187.859,
			12189.699,
			12191.535,
			12194.292,
			12196.133,
			12198.894,
			12199.829,
			12200.764,
			12203.565,
			12205.47,
			12208.341,
			12210.276,
			12212.229,
			12213.207,
			12216.159,
			12220.115,
			12221.1,
			12223.067,
			12225.034,
			12227.996,
			12229.965,
			12232.853,
			12233.812,
			12234.771,
			12237.628,
			12239.521,
			12242.345,
			12246.068,
			12248.847,
			12249.768,
			12250.689,
			12254.375,
			12255.294,
			12258.051,
			12259.896,
			12262.67,
			12264.522,
			12266.378,
			12267.314,
			12269.193,
			12270.133,
			12272.019,
			12273.916,
			12278.718,
			12281.625,
			12283.574,
			12286.516,
			12288.487,
			12293.459,
			12296.471,
			12298.489,
			12299.503,
			12301.538,
			12303.588,
			12304.617,
			12305.648,
			12308.759,
			12310.853,
			12312.96,
			12314.016,
			12316.136,
			12318.272,
			12319.346,
			12322.585,
			12324.758,
			12326.944,
			12330.246,
			12332.464,
			12336.936,
			12338.061,
			12341.45,
			12343.729,
			12347.16,
			12349.459,
			12352.929,
			12356.404,
			12358.724,
			12362.184,
			12364.443,
			12366.703,
			12370.025,
			12371.111,
			12372.185,
			12375.381,
			12377.444,
			12379.461,
			12382.422,
			12384.331,
			12387.143,
			12388.965,
			12391.64,
			12392.517,
			12393.403,
			12395.981,
			12397.678,
			12400.201,
			12403.55,
			12404.386,
			12406.058,
			12408.562,
			12412.752,
			12414.432,
			12416.981,
			12418.697,
			12422.145,
			12423.017,
			12425.621,
			12427.355,
			12429.117,
			12431.828,
			12433.629,
			12437.242,
			12438.145,
			12441.735,
			12442.631,
			12445.313,
			12447.096,
			12449.765,
			12451.482,
			12453.199,
			12454.057,
			12455.777,
			12458.334,
			12460.018,
			12462.548,
			12464.212,
			12466.705,
			12468.399,
			12470.938,
			12472.644,
			12475.224,
			12476.957,
			12479.581,
			12481.346,
			12483.997,
			12486.69,
			12488.499,
			12490.318,
			12492.147,
			12493.99,
			12494.914,
			12498.642,
			12499.58,
			12501.462,
			12502.408,
			12504.307,
			12506.22,
			12509.1,
			12511.034,
			12513.958,
			12514.937,
			12515.917,
			12518.875,
			12520.859,
			12523.854,
			12525.859,
			12528.891,
			12530.922,
			12531.941,
			12533.986,
			12537.074,
			12539.144,
			12541.223,
			12544.354,
			12546.44,
			12548.529,
			12551.679,
			12553.79,
			12554.848,
			12556.972,
			12559.105,
			12562.323,
			12564.478,
			12567.727,
			12569.904,
			12573.189,
			12575.389,
			12580.928,
			12584.279,
			12586.524,
			12587.65,
			12589.91,
			12592.178,
			12593.314,
			12595.597,
			12597.888,
			12602.498,
			12603.656,
			12607.146,
			12608.313,
			12609.483,
			12613.008,
			12615.367,
			12618.926,
			12623.703,
			12627.31,
			12628.517,
			12629.726,
			12634.584,
			12635.805,
			12639.48,
			12641.941,
			12645.65,
			12648.133,
			12650.627,
			12651.877,
			12655.641,
			12658.16,
			12660.691,
			12664.501,
			12667.052,
			12670.898,
			12673.476,
			12677.363,
			12679.964,
			12683.874,
			12686.483,
			12690.414,
			12693.048,
			12694.367,
			12697.01,
			12700.988,
			12702.317,
			12706.315,
			12708.99,
			12713.016,
			12715.709,
			12718.408,
			12719.761,
			12723.826,
			12726.546,
			12729.271,
			12733.373,
			12736.116,
			12741.62,
			12743,
			12747.149,
			12749.923,
			12754.097,
			12756.887,
			12761.085,
			12765.296,
			12768.111,
			12772.345,
			12775.174,
			12778.012,
			12782.279,
			12785.133,
			12789.426,
			12790.858,
			12792.292,
			12795.162,
			12799.475,
			12803.797,
			12806.684,
			12809.576,
			12813.924,
			12815.377,
			12816.831,
			12821.2,
			12824.121,
			12828.511,
			12834.383,
			12838.801,
			12840.276,
			12843.23,
			12846.193,
			12847.678,
			12850.649,
			12853.629,
			12858.109,
			12861.102,
			12865.604,
			12868.611,
			12873.135,
			12876.158,
			12880.701,
			12883.736,
			12886.776,
			12891.346,
			12894.397,
			12898.985,
			12900.516,
			12902.048,
			12906.65,
			12909.725,
			12914.346,
			12917.433,
			12922.076,
			12926.729,
			12929.844,
			12932.965,
			12937.665,
			12940.84,
			12945.582,
			12947.173,
			12948.764,
			12953.555,
			12956.775,
			12961.612,
			12964.864,
			12969.743,
			12972.985,
			12976.22,
			12982.649,
			12984.242,
			12989.015,
			12992.166,
			12995.314,
			12996.889,
			13000.04,
			13004.766,
			13007.916,
			13009.496,
			13012.656,
			13015.816,
			13020.536,
			13023.706,
			13030.062,
			13031.682,
			13036.461,
			13039.652,
			13044.42,
			13047.621,
			13052.433,
			13057.252,
			13060.47,
			13065.303,
			13066.912,
			13071.729,
			13076.506,
			13079.658,
			13082.775,
			13085.855,
			13087.382,
			13091.906,
			13094.878,
			13097.815,
			13102.154,
			13105.002,
			13109.201,
			13110.582,
			13111.953,
			13116.012,
			13118.672,
			13122.589,
			13125.155,
			13128.939,
			13131.426,
			13132.658,
			13135.102,
			13138.705,
			13141.065,
			13143.389,
			13146.803,
			13149.037,
			13152.322,
			13153.4,
			13157.64,
			13159.695,
			13162.7,
			13164.678,
			13166.649,
			13169.556,
			13170.521,
			13171.484,
			13175.323,
			13176.279,
			13179.15,
			13182.954,
			13183.9,
			13185.795,
			13186.74,
			13188.621,
			13193.3,
			13195.161,
			13197.939,
			13199.792,
			13202.561,
			13203.48,
			13204.4,
			13207.159,
			13208.998,
			13211.749,
			13213.582,
			13216.354,
			13219.118,
			13220.966,
			13223.76,
			13224.689,
			13227.477,
			13229.354,
			13232.188,
			13234.076,
			13235.967,
			13236.927,
			13238.85,
			13241.728,
			13243.649,
			13246.584,
			13248.533,
			13251.469,
			13253.446,
			13256.402,
			13258.379,
			13261.363,
			13265.334,
			13266.325,
			13268.302,
			13269.289,
			13271.266,
			13274.221,
			13276.188,
			13278.156,
			13280.102,
			13283.016,
			13284.965,
			13286.901,
			13287.869,
			13290.778,
			13292.726,
			13294.672,
			13297.602,
			13299.565,
			13304.505,
			13307.492,
			13309.494,
			13312.514,
			13314.537,
			13317.586,
			13319.631,
			13322.714,
			13325.814,
			13327.893,
			13329.979,
			13333.123,
			13335.229,
			13339.469,
			13340.533,
			13345.871,
			13348.03,
			13351.284,
			13353.48,
			13357.847,
			13358.948,
			13362.266,
			13363.375,
			13364.487,
			13367.833,
			13370.074,
			13372.324,
			13373.452,
			13375.716,
			13377.988,
			13379.127,
			13382.556,
			13387.153,
			13390.621,
			13392.942,
			13397.608,
			13398.779,
			13402.306,
			13404.665,
			13408.223,
			13410.604,
			13414.19,
			13416.593,
			13419.002,
			13423.845,
			13425.062,
			13428.725,
			13431.175,
			13434.863,
			13437.332,
			13439.809,
			13441.05,
			13443.537,
			13444.783,
			13447.283,
			13451.048,
			13453.566,
			13456.092,
			13459.895,
			13461.166,
			13462.438,
			13466.27,
			13468.832,
			13472.691,
			13475.271,
			13479.152,
			13483.047,
			13485.651,
			13488.261,
			13489.568,
			13492.179,
			13494.789,
			13498.716,
			13501.34,
			13503.97,
			13507.926,
			13511.894,
			13514.545,
			13517.205,
			13521.204,
			13523.877,
			13529.242,
			13530.586,
			13534.629,
			13537.33,
			13541.393,
			13544.108,
			13548.193,
			13552.292,
			13555.031,
			13559.152,
			13561.906,
			13564.668,
			13570.207,
			13571.595,
			13575.77,
			13578.558,
			13581.35,
			13582.75,
			13585.55,
			13588.355,
			13592.574,
			13595.393,
			13599.629,
			13601.043,
			13602.458,
			13606.712,
			13609.554,
			13613.824,
			13616.679,
			13620.972,
			13623.836,
			13628.148,
			13632.47,
			13635.357,
			13638.254,
			13642.631,
			13645.537,
			13649.908,
			13652.83,
			13657.21,
			13660.143,
			13664.576,
			13667.522,
			13670.471,
			13671.947,
			13674.877,
			13679.314,
			13683.767,
			13685.252,
			13689.717,
			13692.727,
			13697.212,
			13700.182,
			13704.687,
			13709.223,
			13712.238,
			13715.24,
			13719.816,
			13722.824,
			13727.382,
			13728.904,
			13730.43,
			13736.537,
			13738.067,
			13742.695,
			13745.77,
			13750.39,
			13753.475,
			13758.121,
			13762.798,
			13765.885,
			13769,
			13775.279,
			13776.846,
			13779.982,
			13784.694,
			13789.386,
			13792.562,
			13795.713,
			13800.444,
			13803.596,
			13808.303,
			13809.883,
			13811.464,
			13816.24,
			13819.406,
			13824.164,
			13827.341,
			13832.088,
			13835.273,
			13836.865,
			13840.057,
			13844.869,
			13848.062,
			13851.258,
			13856.057,
			13859.233,
			13864.052,
			13867.294,
			13872.119,
			13875.337,
			13878.559,
			13883.37,
			13886.599,
			13891.473,
			13894.709,
			13901.154,
			13902.775,
			13907.64,
			13914.159,
			13915.785,
			13922.264,
			13923.892,
			13927.15,
			13928.78,
			13932.045,
			13935.312,
			13940.22,
			13943.518,
			13948.395,
			13950.029,
			13951.666,
			13956.57,
			13959.833,
			13964.726,
			13968.019,
			13971.286,
			13977.818,
			13979.452,
			13984.359,
			13985.97,
			13987.606,
			13992.521,
			13995.795,
			14000.713,
			14003.992,
			14007.276,
			14008.917,
			14013.848,
			14017.139,
			14020.432,
			14025.377,
			14028.677,
			14036.945,
			14041.918,
			14045.242,
			14050.237,
			14053.57,
			14058.579,
			14063.596,
			14066.946,
			14071.979,
			14073.656,
			14078.7,
			14082.068,
			14085.439,
			14087.126,
			14093.88,
			14095.57,
			14100.641,
			14104.049,
			14107.427,
			14112.477,
			14115.795,
			14122.404,
			14124.036,
			14128.869,
			14132.073,
			14136.791,
			14139.893,
			14143.017,
			14144.58,
			14147.709,
			14149.277,
			14152.444,
			14157.178,
			14160.35,
			14163.53,
			14168.318,
			14171.523,
			14176.398,
			14179.656,
			14182.89,
			14184.508,
			14187.737,
			14190.941,
			14195.8,
			14199.102,
			14205.541,
			14207.145,
			14211.926,
			14219.934,
			14223.166,
			14227.977,
			14231.159,
			14234.373,
			14235.981,
			14239.201,
			14244.035,
			14247.262,
			14252.111,
			14255.347,
			14260.207,
			14261.829,
			14263.451,
			14268.322,
			14271.571,
			14276.452,
			14279.709,
			14282.971,
			14289.5,
			14291.136,
			14296.045,
			14297.711,
			14299.35,
			14304.243,
			14307.529,
			14310.818,
			14312.493,
			14315.762,
			14320.712,
			14325.67,
			14328.978,
			14332.289,
			14337.26,
			14340.577,
			14347.223,
			14348.887,
			14353.878,
			14357.205,
			14362.203,
			14365.536,
			14370.546,
			14373.887,
			14378.902,
			14383.928,
			14387.281,
			14390.637,
			14397.351,
			14402.385,
			14407.418,
			14410.775,
			14412.456,
			14415.817,
			14419.18,
			14424.23,
			14427.6,
			14434.34,
			14436.026,
			14441.084,
			14444.46,
			14449.525,
			14452.905,
			14457.98,
			14463.059,
			14466.447,
			14469.838,
			14471.534,
			14474.928,
			14478.324,
			14483.422,
			14486.823,
			14491.928,
			14493.631,
			14495.335,
			14500.446,
			14503.857,
			14507.271,
			14512.395,
			14515.812,
			14520.943,
			14522.654,
			14524.366,
			14529.505,
			14532.934,
			14538.081,
			14541.515,
			14546.67,
			14550.108,
			14555.27,
			14560.438,
			14563.885,
			14567.333,
			14572.509,
			14574.235,
			14575.962,
			14581.146,
			14582.876,
			14584.605,
			14589.797,
			14593.261,
			14598.46,
			14601.93,
			14605.428,
			14610.606,
			14612.344,
			14614.083,
			14619.302,
			14621.043,
			14622.784,
			14628.011,
			14631.5,
			14636.733,
			14643.711,
			14645.456,
			14650.687,
			14657.656,
			14662.882,
			14666.365,
			14671.588,
			14673.33,
			14675.072,
			14680.299,
			14683.783,
			14689.018,
			14696.002,
			14697.749,
			14702.992,
			14706.488,
			14711.738,
			14715.24,
			14718.745,
			14722.252,
			14727.514,
			14731.025,
			14734.539,
			14736.296,
			14739.812,
			14741.57,
			14745.088,
			14748.635,
			14753.914,
			14757.434,
			14762.689,
			14764.451,
			14766.213,
			14771.503,
			14775.031,
			14780.328,
			14787.429,
			14789.199,
			14792.706,
			14798.026,
			14801.528,
			14803.27,
			14806.729,
			14810.152,
			14815.204,
			14818.572,
			14823.484,
			14825.146,
			14826.773,
			14831.601,
			14834.785,
			14837.951,
			14842.674,
			14845.806,
			14850.471,
			14852.033,
			14853.593,
			14858.28,
			14861.349,
			14865.987,
			14869.051,
			14873.633,
			14876.676,
			14878.198,
			14881.244,
			14885.788,
			14888.838,
			14891.892,
			14896.475,
			14899.533,
			14904.123,
			14905.655,
			14907.188,
			14911.791,
			14914.864,
			14919.479,
			14922.561,
			14925.645,
			14931.824,
			14933.372,
			14939.572,
			14941.126,
			14945.789,
			14948.901,
			14953.576,
			14956.695,
			14961.379,
			14966.069,
			14969.199,
			14972.332,
			14977.036,
			14980.176,
			14984.888,
			14988.033,
			14992.756,
			14995.906,
			15000.636,
			15003.792,
			15006.948,
			15008.527,
			15013.266,
			15016.425,
			15021.166,
			15022.748,
			15027.495,
			15030.662,
			15035.414,
			15038.574,
			15041.723,
			15043.29,
			15046.406,
			15051.021,
			15054.048,
			15058.521,
			15061.457,
			15065.79,
			15067.216,
			15072.823,
			15075.593,
			15079.648,
			15082.309,
			15086.23,
			15090.067,
			15092.564,
			15096.309,
			15098.791,
			15101.285,
			15104.985,
			15106.217,
			15107.448,
			15112.379,
			15113.615,
			15117.335,
			15119.828,
			15122.325,
			15126.087,
			15128.627,
			15132.441,
			15133.713,
			15134.986,
			15138.848,
			15141.422,
			15145.283,
			15147.859,
			15150.432,
			15151.718,
			15154.288,
			15155.573,
			15158.141,
			15161.99,
			15164.546,
			15167.092,
			15170.916,
			15173.467,
			15177.295,
			15178.572,
			15179.852,
			15183.692,
			15186.249,
			15190.093,
			15192.662,
			15195.234,
			15199.1,
			15201.682,
			15206.865,
			15208.17,
			15212.083,
			15214.719,
			15218.649,
			15223.914,
			15225.234,
			15229.181,
			15231.846,
			15234.515,
			15241.127,
			15245.105,
			15246.434,
			15247.764,
			15251.748,
			15254.403,
			15258.416,
			15261.082,
			15265.096,
			15269.105,
			15271.807,
			15275.871,
			15277.229,
			15281.312,
			15284.041,
			15288.141,
			15293.621,
			15294.993,
			15299.113,
			15301.864,
			15304.62,
			15308.761,
			15311.529,
			15317.08,
			15318.471,
			15322.652,
			15325.445,
			15329.647,
			15332.454,
			15336.674,
			15339.494,
			15340.906,
			15343.732,
			15347.984,
			15350.822,
			15353.67,
			15357.948,
			15359.377,
			15360.808,
			15365.106,
			15367.979,
			15370.857,
			15372.299,
			15375.186,
			15378.078,
			15382.426,
			15385.331,
			15391.151,
			15392.609,
			15396.988,
			15399.912,
			15404.307,
			15410.18,
			15411.65,
			15414.593,
			15417.543,
			15419.018,
			15426.413,
			15429.382,
			15433.842,
			15436.822,
			15441.297,
			15442.789,
			15444.284,
			15448.771,
			15451.766,
			15456.264,
			15459.268,
			15462.271,
			15466.779,
			15468.281,
			15469.785,
			15475.795,
			15477.298,
			15481.812,
			15484.826,
			15489.336,
			15492.321,
			15495.278,
			15496.746,
			15499.654,
			15501.094,
			15503.943,
			15506.751,
			15510.889,
			15513.599,
			15517.59,
			15518.901,
			15520.203,
			15524.07,
			15526.567,
			15530.288,
			15532.732,
			15536.34,
			15538.715,
			15539.896,
			15542.25,
			15544.596,
			15545.77,
			15546.942,
			15550.479,
			15552.876,
			15555.241,
			15556.457,
			15558.845,
			15561.193,
			15562.357,
			15565.838,
			15568.152,
			15570.453,
			15576.275,
			15579.789,
			15580.95,
			15582.111,
			15585.607,
			15587.948,
			15591.479,
			15593.848,
			15597.406,
			15599.764,
			15600.954,
			15603.358,
			15606.947,
			15609.348,
			15611.753,
			15615.375,
			15616.584,
			15617.796,
			15623.881,
			15627.555,
			15629.992,
			15633.696,
			15636.176,
			15638.666,
			15642.417,
			15643.672,
			15644.928,
			15648.705,
			15651.233,
			15655.037,
			15657.58,
			15660.131,
			15661.407,
			15663.968,
			15667.82,
			15671.689,
			15674.274,
			15676.865,
			15680.763,
			15683.366,
			15687.271,
			15688.574,
			15689.877,
			15693.798,
			15696.418,
			15700.359,
			15702.994,
			15705.632,
			15709.598,
			15712.247,
			15717.562,
			15718.895,
			15722.9,
			15725.577,
			15729.603,
			15732.293,
			15736.338,
			15739.042,
			15740.396,
			15743.108,
			15745.824,
			15749.907,
			15752.635,
			15756.732,
			15758.101,
			15759.471,
			15763.586,
			15766.334,
			15770.459,
			15777.293,
			15780.002,
			15784.033,
			15786.682,
			15787.992,
			15789.293,
			15793.149,
			15795.675,
			15799.392,
			15801.817,
			15804.207,
			15805.389,
			15808.878,
			15811.154,
			15813.394,
			15816.682,
			15818.816,
			15821.949,
			15823.994,
			15826.989,
			15828.945,
			15831.823,
			15833.695,
			15836.438,
			15838.224,
			15840.845,
			15843.389,
			15845.039,
			15846.651,
			15849.023,
			15849.797,
			15850.564,
			15852.076,
			15854.31,
			15855.783,
			15856.518,
			15857.988,
			15859.442,
			15861.631,
			15863.08,
			15865.237,
			15865.952,
			15866.675,
			15868.838,
			15870.266,
			15872.398,
			15873.836,
			15875.268,
			15875.982,
			15877.408,
			15878.835,
			15879.546,
			15881.666,
			15883.074,
			15884.48,
			15886.576,
			15887.97,
			15890.056,
			15890.746,
			15891.436,
			15893.502,
			15894.881,
			15896.947,
			15898.318,
			15899.686,
			15901.74,
			15902.426,
			15903.11,
			15905.834,
			15906.515,
			15908.569,
			15909.935,
			15911.977,
			15913.341,
			15915.408,
			15916.783,
			15917.471,
			15918.847,
			15920.229,
			15922.326,
			15923.721,
			15925.822,
			15926.524,
			15927.236,
			15929.378,
			15930.811,
			15932.968,
			15934.421,
			15936.613,
			15938.078,
			15938.811,
			15939.547,
			15941.772,
			15942.517,
			15943.264,
			15945.512,
			15947.021,
			15948.545,
			15949.309,
			15950.842,
			15952.385,
			15953.159,
			15955.51,
			15957.088,
			15959.475,
			15961.087,
			15962.713,
			15965.172,
			15966.829,
			15969.353,
			15971.043,
			15973.576,
			15975.28,
			15977.009,
			15977.876,
			15979.619,
			15980.496,
			15982.256,
			15984.94,
			15986.748,
			15988.568,
			15991.318,
			15993.163,
			15995.952,
			15996.887,
			15997.823,
			15999.689,
			16000.626,
			16002.508,
			16004.403,
			16007.256,
			16009.17,
			16012.062,
			16013.031,
			16014.006,
			16016.949,
			16018.908,
			16021.894,
			16025.916,
			16026.928,
			16028.959,
			16032.025,
			16034.098,
			16037.18,
			16039.259,
			16042.395,
			16044.488,
			16048.668,
			16049.718,
			16052.875,
			16054.991,
			16058.18,
			16060.313,
			16062.457,
			16065.688,
			16066.77,
			16067.852,
			16071.11,
			16073.291,
			16076.584,
			16078.79,
			16082.11,
			16086.569,
			16087.689,
			16091.062,
			16093.321,
			16095.59,
			16101.299,
			16104.752,
			16105.908,
			16107.065,
			16110.557,
			16112.895,
			16116.417,
			16118.777,
			16122.332,
			16124.711,
			16125.902,
			16128.29,
			16130.686,
			16131.887,
			16133.089,
			16136.7,
			16139.115,
			16141.539,
			16142.754,
			16145.184,
			16147.623,
			16148.846,
			16154.992,
			16157.465,
			16161.19,
			16163.686,
			16167.441,
			16168.698,
			16169.957,
			16173.745,
			16176.279,
			16180.102,
			16182.66,
			16185.224,
			16186.508,
			16189.08,
			16190.368,
			16192.949,
			16196.83,
			16199.42,
			16204.582,
			16208.402,
			16210.916,
			16213.396,
			16214.623,
			16217.049,
			16218.246,
			16220.61,
			16222.938,
			16226.359,
			16228.592,
			16232.938,
			16234,
			16237.129,
			16239.169,
			16242.164,
			16244.113,
			16246.972,
			16248.836,
			16251.574,
			16253.359,
			16255.969,
			16257.66,
			16260.132,
			16260.938,
			16261.735,
			16264.086,
			16264.857,
			16265.623,
			16267.874,
			16269.345,
			16270.797,
			16272.954,
			16274.375,
			16276.487,
			16277.188,
			16277.884,
			16279.954,
			16280.637,
			16281.316,
			16283.346,
			16284.685,
			16286.013,
			16286.673,
			16289.297,
			16289.959,
			16291.936,
			16293.236,
			16294.528,
			16296.481,
			16297.785,
			16299.723,
			16301.006,
			16303.038,
			16304.381,
			16306.388,
			16307.744,
			16309.092,
			16309.763,
			16311.097,
			16311.761,
			16313.087,
			16315.075,
			16315.735,
			16317.707,
			16319.016,
			16320.973,
			16322.298,
			16323.615,
			16324.272,
			16325.588,
			16326.245,
			16327.542,
			16328.832,
			16330.762,
			16332.058,
			16334.018,
			16334.668,
			16335.319,
			16337.278,
			16338.599,
			16340.562,
			16341.872,
			16343.861,
			16345.861,
			16347.193,
			16348.534,
			16350.564,
			16351.91,
			16353.936,
			16355.313,
			16356.684,
			16358.052,
			16358.736,
			16360.817,
			16362.21,
			16363.603,
			16365.715,
			16367.147,
			16369.297,
			16370.016,
			16370.737,
			16372.921,
			16374.381,
			16376.583,
			16378.08,
			16380.328,
			16382.599,
			16384.133,
			16386.443,
			16387.996,
			16389.572,
			16392.73,
			16393.523,
			16395.93,
			16397.531,
			16399.947,
			16401.561,
			16403.176,
			16405.604,
			16407.207,
			16409.617,
			16410.426,
			16411.223,
			16413.621,
			16415.219,
			16417.652,
			16419.258,
			16421.703,
			16423.344,
			16424.996,
			16425.826,
			16427.492,
			16428.324,
			16429.992,
			16431.68,
			16434.238,
			16435.973,
			16438.602,
			16439.484,
			16440.369,
			16443.047,
			16444.871,
			16447.646,
			16449.514,
			16451.398,
			16452.352,
			16454.264,
			16455.227,
			16456.191,
			16460.084,
			16461.061,
			16464.016,
			16466.002,
			16469,
			16471.014,
			16473.041,
			16474.055,
			16476.09,
			16477.111,
			16479.162,
			16481.225,
			16484.312,
			16486.381,
			16489.504,
			16490.551,
			16491.596,
			16494.738,
			16496.834,
			16499.977,
			16502.1,
			16505.301,
			16507.465,
			16508.545,
			16510.709,
			16513.957,
			16516.168,
			16518.369,
			16522.807,
			16523.922,
			16526.16,
			16528.412,
			16529.541,
			16532.943,
			16535.225,
			16537.521,
			16540.982,
			16543.301,
			16546.807,
			16547.984,
			16549.162,
			16552.711,
			16555.07,
			16558.691,
			16561.102,
			16564.734,
			16567.145,
			16568.361,
			16570.801,
			16574.471,
			16576.939,
			16579.393,
			16583.068,
			16584.301,
			16585.533,
			16590.484,
			16591.729,
			16595.467,
			16597.969,
			16600.48,
			16604.26,
			16606.789,
			16610.596,
			16611.869,
			16613.145,
			16616.98,
			16619.549,
			16623.414,
			16626.002,
			16628.596,
			16629.896,
			16632.5,
			16636.408,
			16639.018,
			16640.326,
			16642.947,
			16645.574,
			16649.527,
			16652.168,
			16657.473,
			16658.801,
			16662.801,
			16665.473,
			16669.492,
			16672.18,
			16676.223,
			16680.283,
			16681.639,
			16685.717,
			16687.08,
			16688.443,
			16692.547,
			16695.289,
			16699.418,
			16702.176,
			16704.941,
			16706.328,
			16710.494,
			16713.281,
			16716.072,
			16720.299,
			16723.113,
			16728.74,
			16730.158,
			16734.41,
			16737.273,
			16741.523,
			16744.383,
			16748.674,
			16751.551,
			16752.99,
			16755.895,
			16758.756,
			16760.203,
			16763.096,
			16765.984,
			16768.895,
			16771.756,
			16773.195,
			16776.059,
			16778.893,
			16780.301,
			16784.5,
			16787.244,
			16789.943,
			16793.916,
			16796.492,
			16800.32,
			16801.578,
			16802.832,
			16806.574,
			16809.043,
			16812.746,
			16815.211,
			16817.678,
			16818.91,
			16821.383,
			16822.623,
			16825.102,
			16828.818,
			16831.316,
			16833.809,
			16837.549,
			16840.043,
			16843.77,
			16845.014,
			16846.254,
			16849.963,
			16852.424,
			16856.098,
			16858.537,
			16860.963,
			16864.576,
			16865.779,
			16866.979,
			16870.549,
			16872.914,
			16878.812,
			16882.332,
			16884.697,
			16887.07,
			16888.26,
			16890.645,
			16891.838,
			16894.232,
			16896.633,
			16900.25,
			16902.67,
			16907.537,
			16908.758,
			16912.43,
			16914.889,
			16918.592,
			16921.066,
			16924.797,
			16927.293,
			16928.541,
			16929.793,
			16933.555,
			16934.812,
			16936.07,
			16939.855,
			16942.387,
			16946.191,
			16948.734,
			16951.285,
			16952.562,
			16956.4,
			16958.971,
			16961.566,
			16965.434,
			16968.021,
			16971.914,
			16973.189,
			16974.488,
			16978.389,
			16980.984,
			16984.902,
			16987.514,
			16991.438,
			16994.059,
			16995.371,
			16997.988,
			17001.891,
			17004.52,
			17007.152,
			17013.531,
			17018.484,
			17019.699,
			17022.1,
			17023.287,
			17025.633,
			17027.943,
			17033.6,
			17036.906,
			17039.07,
			17042.33,
			17044.508,
			17047.734,
			17049.879,
			17052.02,
			17053.088,
			17056.295,
			17058.428,
			17060.562,
			17061.627,
			17063.768,
			17065.92,
			17069.148,
			17071.305,
			17075.688,
			17076.783,
			17080.092,
			17082.318,
			17084.547,
			17087.924,
			17090.195,
			17094.758,
			17095.908,
			17099.363,
			17100.516,
			17101.67,
			17105.111,
			17107.408,
			17110.838,
			17113.117,
			17116.543,
			17119.959,
			17122.238,
			17124.52,
			17127.918,
			17130.152,
			17133.43,
			17135.572,
			17138.727,
			17140.789,
			17143.844,
			17145.863,
			17148.871,
			17150.867,
			17151.863,
			17153.859,
			17156.85,
			17157.846,
			17160.846,
			17162.859,
			17164.869,
			17165.875,
			17167.889,
			17169.934,
			17170.955,
			17174.021,
			17176.072,
			17178.129,
			17181.211,
			17183.27,
			17186.365,
			17187.396,
			17188.428,
			17191.51,
			17193.547,
			17196.605,
			17200.645,
			17201.648,
			17203.658,
			17204.666,
			17206.682,
			17209.668,
			17211.668,
			17213.676,
			17216.688,
			17218.703,
			17222.738,
			17223.76,
			17225.809,
			17226.836,
			17228.898,
			17230.967,
			17234.096,
			17236.195,
			17239.359,
			17240.42,
			17241.484,
			17244.684,
			17246.814,
			17249.982,
			17252.061,
			17255.113,
			17257.104,
			17258.088,
			17260.027,
			17261.941,
			17262.887,
			17264.758,
			17266.611,
			17269.369,
			17271.203,
			17274.857,
			17275.77,
			17278.5,
			17280.316,
			17283.045,
			17284.844,
			17286.633,
			17290.189,
			17291.074,
			17293.717,
			17295.473,
			17298.092,
			17299.834,
			17302.465,
			17304.229,
			17306.871,
			17309.521,
			17311.32,
			17313.117,
			17315.816,
			17317.625,
			17320.389,
			17321.312,
			17322.238,
			17325.023,
			17326.898,
			17329.736,
			17331.633,
			17334.49,
			17336.43,
			17337.402,
			17338.375,
			17341.299,
			17342.277,
			17343.262,
			17346.225,
			17348.203,
			17350.191,
			17351.189,
			17353.188,
			17355.186,
			17356.188,
			17359.203,
			17361.223,
			17363.242,
			17366.281,
			17368.316,
			17371.41,
			17372.441,
			17373.473,
			17376.514,
			17378.543,
			17381.598,
			17383.641,
			17385.688,
			17386.713,
			17388.766,
			17389.793,
			17391.85,
			17394.934,
			17395.963,
			17399.055,
			17401.123,
			17404.227,
			17406.305,
			17408.371,
			17409.414,
			17412.553,
			17414.643,
			17416.738,
			17419.904,
			17422.027,
			17425.234,
			17426.307,
			17427.385,
			17431.725,
			17432.816,
			17436.105,
			17438.32,
			17441.682,
			17445.082,
			17447.326,
			17449.598,
			17453.031,
			17455.338,
			17458.811,
			17459.971,
			17461.131,
			17464.641,
			17465.789,
			17466.957,
			17470.471,
			17472.822,
			17475.18,
			17478.754,
			17481.125,
			17484.68,
			17487.074,
			17490.684,
			17491.891,
			17493.102,
			17496.748,
			17499.189,
			17502.875,
			17507.828,
			17509.07,
			17512.814,
			17515.326,
			17517.844,
			17521.637,
			17524.178,
			17528.01,
			17530.574,
			17534.439,
			17537.029,
			17540.93,
			17543.537,
			17546.145,
			17550.07,
			17551.383,
			17552.697,
			17557.975,
			17559.301,
			17563.289,
			17565.955,
			17568.629,
			17569.965,
			17575.316,
			17576.65,
			17579.305,
			17580.627,
			17583.258,
			17585.869,
			17589.74,
			17592.285,
			17597.295,
			17598.523,
			17602.168,
			17604.574,
			17608.146,
			17610.537,
			17612.943,
			17614.133,
			17616.52,
			17617.709,
			17620.09,
			17626.055,
			17628.443,
			17632.037,
			17634.443,
			17639.264,
			17640.473,
			17642.902,
			17644.117,
			17646.549,
			17648.982,
			17652.633,
			17655.07,
			17659.963,
			17661.188,
			17664.871,
			17667.334,
			17671.035,
			17673.51,
			17677.242,
			17679.738,
			17680.988,
			17683.496,
			17686.004,
			17687.262,
			17689.777,
			17692.289,
			17696.004,
			17697.242,
			17698.477,
			17702.127,
			17703.32,
			17704.508,
			17708.014,
			17712.559,
			17715.877,
			17718.047,
			17721.266,
			17723.369,
			17726.484,
			17728.551,
			17731.637,
			17733.688,
			17736.762,
			17738.828,
			17740.895,
			17741.926,
			17745.023,
			17747.107,
			17749.188,
			17752.305,
			17754.391,
			17758.586,
			17759.635,
			17762.783,
			17764.883,
			17768.027,
			17770.123,
			17772.207,
			17775.328,
			17777.408,
			17781.57,
			17782.611,
			17785.738,
			17787.83,
			17789.928,
			17793.084,
			17795.197,
			17796.256,
			17798.385,
			17799.451,
			17801.594,
			17803.746,
			17807.012,
			17809.209,
			17812.523,
			17814.754,
			17819.248,
			17820.381,
			17823.783,
			17826.061,
			17829.5,
			17831.762,
			17832.906,
			17835.203,
			17837.498,
			17838.637,
			17839.777,
			17843.178,
			17845.432,
			17848.793,
			17851.035,
			17853.24,
			17854.34,
			17857.625,
			17859.785,
			17861.928,
			17865.16,
			17867.312,
			17870.545,
			17872.725,
			17875.992,
			17878.178,
			17881.48,
			17883.684,
			17885.889,
			17886.988,
			17890.277,
			17892.471,
			17895.74,
			17897.904,
			17900.068,
			17903.305,
			17905.439,
			17908.637,
			17909.701,
			17910.766,
			17912.885,
			17916.059,
			17918.176,
			17921.359,
			17923.482,
			17926.672,
			17927.738,
			17928.809,
			17932.035,
			17934.193,
			17937.439,
			17939.621,
			17942.895,
			17945.123,
			17947.344,
			17948.439,
			17950.68,
			17951.805,
			17954.064,
			17956.338,
			17959.768,
			17960.916,
			17962.066,
			17965.541,
			17966.707,
			17967.873,
			17971.387,
			17973.744,
			17979.682,
			17983.277,
			17985.684,
			17986.891,
			17988.102,
			17991.75,
			17992.973,
			17994.195,
			17997.879,
			18000.346,
			18004.062,
			18006.539,
			18009.01,
			18010.238,
			18013.906,
			18016.301,
			18022.1,
			18024.359,
			18027.691,
			18028.783,
			18029.861,
			18033.037,
			18035.1,
			18038.119,
			18040.086,
			18042.023,
			18042.98,
			18044.881,
			18045.824,
			18047.709,
			18050.516,
			18051.449,
			18054.244,
			18056.113,
			18058.908,
			18060.768,
			18062.631,
			18063.564,
			18065.434,
			18066.367,
			18068.23,
			18070.098,
			18072.91,
			18074.785,
			18077.592,
			18078.527,
			18079.465,
			18082.279,
			18084.152,
			18086.959,
			18088.836,
			18091.648,
			18093.52,
			18094.457,
			18096.328,
			18098.201,
			18099.137,
			18101.008,
			18102.875,
			18105.686,
			18106.623,
			18107.562,
			18110.369,
			18111.309,
			18112.25,
			18115.078,
			18116.969,
			18121.715,
			18123.621,
			18126.496,
			18127.459,
			18128.408,
			18131.318,
			18133.271,
			18136.193,
			18138.152,
			18141.086,
			18143.041,
			18144.02,
			18145.979,
			18148.926,
			18150.895,
			18152.867,
			18155.834,
			18156.83,
			18157.836,
			18161.877,
			18162.893,
			18165.959,
			18166.996,
			18168.035,
			18171.168,
			18173.271,
			18176.449,
			18178.588,
			18180.725,
			18183.975,
			18185.061,
			18186.15,
			18189.434,
			18191.637,
			18194.961,
			18197.193,
			18199.438,
			18200.562,
			18205.098,
			18206.24,
			18208.535,
			18209.686,
			18211.996,
			18214.32,
			18217.82,
			18220.16,
			18223.672,
			18224.842,
			18226.01,
			18229.496,
			18231.797,
			18235.201,
			18237.43,
			18239.625,
			18240.707,
			18242.852,
			18243.91,
			18244.961,
			18248.062,
			18250.086,
			18253.062,
			18255.008,
			18257.863,
			18259.73,
			18262.504,
			18264.322,
			18265.23,
			18267.041,
			18268.848,
			18273.332,
			18276.008,
			18276.898,
			18277.787,
			18280.443,
			18282.207,
			18284.859,
			18286.625,
			18289.262,
			18291.02,
			18291.902,
			18293.684,
			18296.348,
			18298.125,
			18299.914,
			18303.541,
			18304.447,
			18306.266,
			18308.098,
			18309.021,
			18311.801,
			18313.66,
			18315.531,
			18318.383,
			18320.291,
			18323.166,
			18324.133,
			18325.104,
			18328.02,
			18329.965,
			18332.859,
			18334.777,
			18337.652,
			18339.555,
			18340.49,
			18342.355,
			18345.148,
			18347.008,
			18348.855,
			18351.617,
			18353.457,
			18355.295,
			18357.143,
			18358.07,
			18359.922,
			18360.848,
			18362.701,
			18364.555,
			18367.352,
			18369.217,
			18372.016,
			18372.951,
			18373.887,
			18377.654,
			18378.6,
			18381.441,
			18383.344,
			18385.258,
			18386.223,
			18388.152,
			18391.066,
			18393.031,
			18396.021,
			18398.027,
			18401.055,
			18403.09,
			18407.207,
			18408.248,
			18411.391,
			18413.506,
			18416.717,
			18418.912,
			18421.111,
			18425.418,
			18427.596,
			18429.789,
			18431.99,
			18435.279,
			18437.484,
			18440.771,
			18442.922,
			18445.043,
			18446.094,
			18448.166,
			18451.203,
			18453.18,
			18456.08,
			18457.971,
			18460.742,
			18462.559,
			18465.254,
			18467.043,
			18469.715,
			18471.494,
			18474.162,
			18475.924,
			18476.801,
			18478.557,
			18480.32,
			18482.078,
			18484.711,
			18486.465,
			18488.24,
			18489.137,
			18490.928,
			18492.725,
			18493.625,
			18496.342,
			18498.197,
			18500.057,
			18502.855,
			18504.732,
			18507.6,
			18508.557,
			18509.514,
			18512.395,
			18514.34,
			18517.309,
			18519.289,
			18521.275,
			18522.275,
			18524.291,
			18527.32,
			18530.365,
			18532.406,
			18534.451,
			18537.525,
			18539.582,
			18541.604,
			18543.631,
			18544.645,
			18547.705,
			18549.734,
			18552.777,
			18554.816,
			18556.867,
			18559.951,
			18562.018,
			18565.139,
			18567.229,
			18570.371,
			18572.477,
			18574.578,
			18575.643,
			18581.021,
			18584.289,
			18586.482,
			18588.686,
			18592.01,
			18594.234,
			18597.59,
			18598.715,
			18599.84,
			18603.234,
			18605.508,
			18608.938,
			18611.236,
			18613.547,
			18614.705,
			18617.029,
			18619.363,
			18622.885,
			18624.064,
			18625.246,
			18628.803,
			18631.186,
			18634.777,
			18637.184,
			18640.807,
			18644.447,
			18646.885,
			18649.33,
			18653,
			18655.439,
			18659.068,
			18660.268,
			18661.457,
			18667.285,
			18670.672,
			18672.881,
			18676.125,
			18678.264,
			18679.309,
			18681.367,
			18684.383,
			18686.355,
			18688.297,
			18690.213,
			18693.047,
			18694.924,
			18696.787,
			18697.715,
			18700.477,
			18702.311,
			18704.164,
			18706.938,
			18708.777,
			18711.553,
			18713.426,
			18716.223,
			18717.152,
			18718.082,
			18720.896,
			18722.766,
			18725.562,
			18730.285,
			18732.174,
			18733.117,
			18735.01,
			18736.916,
			18737.865,
			18739.758,
			18741.648,
			18744.498,
			18746.402,
			18748.301,
			18750.195,
			18751.139,
			18753.982,
			18755.871,
			18757.75,
			18760.561,
			18762.422,
			18765.199,
			18766.123,
			18767.045,
			18769.805,
			18771.635,
			18774.377,
			18776.203,
			18778.934,
			18780.754,
			18781.666,
			18783.49,
			18786.227,
			18788.045,
			18789.867,
			18792.611,
			18794.445,
			18798.084,
			18799.004,
			18801.775,
			18803.639,
			18805.508,
			18808.334,
			18810.234,
			18813.104,
			18814.066,
			18815.031,
			18818.926,
			18819.906,
			18822.867,
			18824.855,
			18826.855,
			18827.859,
			18829.877,
			18831.906,
			18832.924,
			18834.969,
			18835.996,
			18838.057,
			18840.131,
			18843.262,
			18845.357,
			18849.551,
			18850.605,
			18853.781,
			18855.914,
			18859.127,
			18863.445,
			18864.529,
			18866.705,
			18867.797,
			18869.988,
			18873.293,
			18875.508,
			18877.73,
			18879.965,
			18883.332,
			18887.855,
			18888.992,
			18891.273,
			18892.418,
			18894.711,
			18897.016,
			18900.488,
			18902.816,
			18907.504,
			18908.684,
			18912.23,
			18914.611,
			18918.191,
			18920.568,
			18924.123,
			18926.48,
			18929.98,
			18932.283,
			18933.422,
			18935.672,
			18937.883,
			18941.148,
			18942.211,
			18943.262,
			18947.371,
			18948.375,
			18951.312,
			18953.236,
			18955.121,
			18957.902,
			18959.707,
			18962.371,
			18963.25,
			18964.121,
			18966.725,
			18968.445,
			18971.016,
			18972.727,
			18974.441,
			18975.299,
			18977.016,
			18977.871,
			18979.584,
			18982.148,
			18983.877,
			18985.605,
			18988.186,
			18989.902,
			18992.5,
			18993.377,
			18994.25,
			18996.855,
			18998.588,
			19000.334,
			19004.738,
			19007.355,
			19008.232,
			19009.111,
			19013.471,
			19016.064,
			19017.801,
			19020.391,
			19023.834,
			19024.693,
			19027.295,
			19029.027,
			19030.758,
			19033.354,
			19035.082,
			19037.672,
			19038.533,
			19039.396,
			19041.988,
			19043.695,
			19046.242,
			19047.938,
			19050.49,
			19052.18,
			19054.699,
			19056.402,
			19058.115,
			19060.695,
			19062.42,
			19064.156,
			19065.027,
			19066.781,
			19068.551,
			19069.438,
			19072.121,
			19073.924,
			19075.732,
			19078.477,
			19080.316,
			19083.082,
			19084.021,
			19084.947,
			19087.746,
			19089.617,
			19092.426,
			19096.182,
			19097.121,
			19099.004,
			19099.945,
			19101.832,
			19104.676,
			19106.578,
			19108.484,
			19111.359,
			19113.287,
			19115.209,
			19117.141,
			19118.109,
			19120.043,
			19122.986,
			19124.951,
			19127.918,
			19129.912,
			19133.934,
			19134.943,
			19137.994,
			19140.039,
			19143.127,
			19145.195,
			19148.309,
			19151.426,
			19153.518,
			19155.621,
			19158.795,
			19160.926,
			19164.141,
			19166.297,
			19170.637,
			19171.727,
			19175.012,
			19177.217,
			19179.436,
			19182.777,
			19185.016,
			19188.4,
			19189.533,
			19190.668,
			19194.09,
			19196.385,
			19199.846,
			19202.168,
			19205.672,
			19208.043,
			19211.592,
			19213.977,
			19215.172,
			19217.572,
			19219.984,
			19226.051,
			19229.738,
			19230.973,
			19232.213,
			19235.945,
			19238.449,
			19242.221,
			19244.746,
			19248.549,
			19251.096,
			19252.373,
			19253.652,
			19258.791,
			19260.08,
			19263.959,
			19269.135,
			19270.428,
			19275.596,
			19276.887,
			19280.758,
			19283.355,
			19285.934,
			19289.793,
			19292.363,
			19296.217,
			19297.502,
			19298.785,
			19302.641,
			19305.193,
			19309.068,
			19311.682,
			19315.594,
			19319.518,
			19322.146,
			19326.117,
			19328.777,
			19331.451,
			19334.137,
			19338.191,
			19340.906,
			19343.629,
			19344.994,
			19347.73,
			19349.098,
			19351.842,
			19354.59,
			19358.719,
			19361.477,
			19365.617,
			19368.361,
			19372.523,
			19373.91,
			19375.303,
			19379.488,
			19382.287,
			19386.508,
			19389.33,
			19393.592,
			19397.867,
			19400.729,
			19403.592,
			19405.027,
			19407.904,
			19410.789,
			19415.133,
			19418.041,
			19422.449,
			19423.916,
			19425.383,
			19429.797,
			19432.748,
			19435.682,
			19443.096,
			19447.559,
			19449.047,
			19450.537,
			19455.004,
			19457.975,
			19462.416,
			19465.361,
			19469.76,
			19474.08,
			19476.904,
			19481.111,
			19483.879,
			19486.615,
			19490.656,
			19491.982,
			19493.303,
			19497.203,
			19499.762,
			19503.541,
			19506.047,
			19509.82,
			19512.32,
			19516.047,
			19518.543,
			19522.293,
			19526.057,
			19527.316,
			19531.107,
			19533.645,
			19536.193,
			19537.469,
			19540.027,
			19543.896,
			19546.457,
			19547.732,
			19550.264,
			19552.768,
			19556.438,
			19558.848,
			19562.418,
			19563.584,
			19564.742,
			19568.156,
			19570.387,
			19573.672,
			19575.836,
			19577.98,
			19579.049,
			19582.244,
			19584.393,
			19587.621,
			19589.773,
			19591.926,
			19594.096,
			19597.352,
			19599.516,
			19602.77,
			19604.941,
			19606.025,
			19608.188,
			19610.342,
			19613.557,
			19615.688,
			19618.875,
			19619.932,
			19620.982,
			19624.125,
			19626.213,
			19629.338,
			19631.408,
			19634.508,
			19636.576,
			19637.611,
			19639.676,
			19642.77,
			19644.834,
			19646.902,
			19650.008,
			19652.08,
			19654.156,
			19656.236,
			19657.277,
			19660.4,
			19662.488,
			19664.574,
			19667.715,
			19669.809,
			19672.965,
			19674.02,
			19675.074,
			19678.25,
			19679.312,
			19680.375,
			19683.582,
			19685.732,
			19687.896,
			19688.984,
			19691.166,
			19693.359,
			19694.461,
			19697.777,
			19700.008,
			19702.246,
			19705.629,
			19707.895,
			19711.324,
			19712.469,
			19713.619,
			19717.084,
			19719.404,
			19722.896,
			19725.232,
			19728.75,
			19731.102,
			19733.459,
			19738.191,
			19739.359,
			19742.926,
			19745.309,
			19748.881,
			19751.264,
			19754.82,
			19757.184,
			19758.361,
			19760.709,
			19763.053,
			19766.553,
			19768.879,
			19773.512,
			19774.666,
			19778.123,
			19780.426,
			19783.891,
			19786.213,
			19789.721,
			19792.078,
			19793.262,
			19795.633,
			19798.027,
			19799.229,
			19800.432,
			19804.082,
			19806.551,
			19810.254,
			19815.238,
			19816.49,
			19820.25,
			19822.768,
			19825.285,
			19829.074,
			19831.609,
			19835.416,
			19836.688,
			19837.959,
			19841.773,
			19844.32,
			19848.143,
			19850.697,
			19854.531,
			19858.379,
			19860.951,
			19864.828,
			19867.42,
			19870.004,
			19875.244,
			19876.553,
			19880.469,
			19881.785,
			19883.102,
			19885.742,
			19887.066,
			19889.719,
			19892.377,
			19896.381,
			19899.062,
			19903.098,
			19904.447,
			19905.797,
			19909.861,
			19912.58,
			19916.666,
			19919.396,
			19923.504,
			19926.25,
			19927.623,
			19930.377,
			19934.52,
			19937.291,
			19940.066,
			19944.246,
			19947.043,
			19952.65,
			19954.057,
			19958.281,
			19961.104,
			19965.361,
			19968.188,
			19971.016,
			19972.432,
			19976.682,
			19978.1,
			19983.77,
			19985.189,
			19992.273,
			19996.51,
			19999.309,
			20002.129,
			20003.539,
			20006.355,
			20007.766,
			20010.582,
			20013.398,
			20020.471,
			20024.701,
			20026.111,
			20027.523,
			20031.758,
			20034.578,
			20038.791,
			20041.611,
			20045.846,
			20048.674,
			20050.092,
			20052.955,
			20055.805,
			20057.234,
			20058.666,
			20062.953,
			20065.84,
			20068.734,
			20070.184,
			20073.092,
			20076.008,
			20077.469,
			20081.869,
			20084.816,
			20087.766,
			20092.211,
			20095.203,
			20101.166,
			20102.66,
			20107.156,
			20110.16,
			20114.676,
			20120.691,
			20122.205,
			20125.232,
			20126.746,
			20129.773,
			20134.324,
			20137.316,
			20140.281,
			20144.678,
			20147.564,
			20150.414,
			20154.609,
			20158.717,
			20161.418,
			20164.094,
			20168.074,
			20170.715,
			20174.648,
			20175.965,
			20177.281,
			20181.229,
			20183.855,
			20187.807,
			20190.42,
			20194.34,
			20196.957,
			20200.891,
			20203.521,
			20207.461,
			20210.105,
			20214.082,
			20216.738,
			20220.758,
			20222.094,
			20223.43,
			20227.451,
			20230.139,
			20232.838,
			20236.898,
			20239.613,
			20243.697,
			20246.426,
			20250.527,
			20251.895,
			20253.262,
			20257.365,
			20260.1,
			20264.172,
			20266.896,
			20269.615,
			20270.973,
			20275.033,
			20277.754,
			20280.426,
			20284.484,
			20287.178,
			20291.223,
			20293.928,
			20298,
			20300.727,
			20304.832,
			20307.562,
			20311.711,
			20314.492,
			20318.695,
			20322.926,
			20325.762,
			20328.604,
			20334.309,
			20335.74,
			20338.607,
			20341.473,
			20342.91,
			20347.209,
			20350.098,
			20352.959,
			20357.254,
			20360.123,
			20364.443,
			20365.863,
			20367.311,
			20371.688,
			20374.602,
			20378.992,
			20384.883,
			20386.363,
			20389.332,
			20390.797,
			20393.809,
			20398.32,
			20404.367,
			20408.914,
			20411.945,
			20414.961,
			20418.029,
			20419.549,
			20424.133,
			20427.186,
			20431.748,
			20434.789,
			20437.83,
			20442.387,
			20443.902,
			20445.42,
			20449.934,
			20452.953,
			20457.477,
			20460.465,
			20464.9,
			20467.891,
			20470.855,
			20472.336,
			20475.277,
			20479.734,
			20482.738,
			20487.195,
			20488.691,
			20490.189,
			20494.721,
			20496.199,
			20497.707,
			20502.24,
			20505.277,
			20509.84,
			20512.895,
			20515.957,
			20520.561,
			20523.641,
			20528.27,
			20531.363,
			20536.012,
			20539.117,
			20543.795,
			20546.918,
			20550.045,
			20551.611,
			20556.311,
			20559.455,
			20562.602,
			20570.496,
			20575.246,
			20578.414,
			20583.176,
			20586.354,
			20591.129,
			20594.316,
			20599.094,
			20602.285,
			20603.881,
			20607.074,
			20611.873,
			20615.076,
			20618.283,
			20621.494,
			20624.709,
			20626.316,
			20629.535,
			20632.758,
			20634.371,
			20637.598,
			20639.215,
			20642.447,
			20645.684,
			20650.547,
			20653.793,
			20660.297,
			20661.924,
			20666.812,
			20670.074,
			20674.973,
			20678.244,
			20683.158,
			20686.436,
			20689.717,
			20691.359,
			20694.646,
			20696.291,
			20699.584,
			20702.869,
			20707.77,
			20711.006,
			20715.803,
			20717.385,
			20718.959,
			20723.625,
			20726.717,
			20729.746,
			20737.141,
			20741.492,
			20742.926,
			20744.354,
			20748.58,
			20751.377,
			20755.477,
			20758.146,
			20762.123,
			20766.02,
			20768.574,
			20771.113,
			20772.357,
			20774.805,
			20777.236,
			20780.824,
			20783.176,
			20786.676,
			20787.82,
			20788.963,
			20792.369,
			20794.627,
			20798.012,
			20800.27,
			20803.668,
			20807.072,
			20808.211,
			20811.627,
			20812.764,
			20813.904,
			20817.318,
			20819.598,
			20821.871,
			20823.006,
			20825.277,
			20827.549,
			20828.68,
			20832.074,
			20834.33,
			20836.584,
			20839.961,
			20842.209,
			20846.705,
			20847.83,
			20851.199,
			20853.449,
			20856.836,
			20859.098,
			20862.496,
			20864.77,
			20868.188,
			20871.623,
			20873.92,
			20876.225,
			20878.531,
			20881.982,
			20884.301,
			20886.648,
			20887.814,
			20890.148,
			20891.32,
			20893.645,
			20895.994,
			20899.531,
			20901.895,
			20905.445,
			20906.627,
			20907.809,
			20911.336,
			20913.656,
			20917.082,
			20919.316,
			20922.594,
			20924.729,
			20927.855,
			20929.893,
			20930.896,
			20932.881,
			20934.83,
			20937.707,
			20939.584,
			20942.373,
			20944.219,
			20946.969,
			20948.797,
			20950.621,
			20953.35,
			20955.16,
			20957.875,
			20958.781,
			20959.689,
			20962.418,
			20964.24,
			20966.99,
			20968.822,
			20971.582,
			20973.422,
			20974.34,
			20976.188,
			20978.961,
			20980.812,
			20982.68,
			20985.473,
			20987.357,
			20991.125,
			20992.074,
			20994.914,
			20996.814,
			20998.717,
			21001.57,
			21003.484,
			21006.354,
			21007.344,
			21008.332,
			21011.291,
			21012.229,
			21013.154,
			21015.934,
			21017.787,
			21020.574,
			21024.363,
			21025.316,
			21027.209,
			21029.984,
			21031.836,
			21034.613,
			21036.473,
			21040.201,
			21041.137,
			21043.957,
			21045.84,
			21048.688,
			21050.596,
			21053.477,
			21055.414,
			21058.34,
			21061.289,
			21062.279,
			21065.262,
			21067.266,
			21070.287,
			21072.316,
			21074.355,
			21075.381,
			21078.471,
			21080.543,
			21082.617,
			21085.717,
			21087.809,
			21092.021,
			21093.082,
			21098.424,
			21101.66,
			21103.83,
			21107.109,
			21109.307,
			21110.41,
			21112.623,
			21114.848,
			21115.965,
			21118.203,
			21120.455,
			21123.852,
			21124.99,
			21126.129,
			21129.564,
			21131.869,
			21135.342,
			21137.672,
			21140.01,
			21143.535,
			21145.896,
			21149.459,
			21150.648,
			21151.842,
			21155.42,
			21157.797,
			21161.334,
			21163.664,
			21165.963,
			21167.1,
			21169.342,
			21172.637,
			21175.848,
			21179.998,
			21183.018,
			21184.988,
			21187.895,
			21188.844,
			21189.787,
			21192.582,
			21194.426,
			21197.166,
			21198.984,
			21200.799,
			21203.496,
			21204.393,
			21205.285,
			21208.842,
			21209.725,
			21212.379,
			21214.148,
			21216.791,
			21218.58,
			21220.365,
			21221.254,
			21224,
			21225.83,
			21227.668,
			21230.426,
			21232.254,
			21234.992,
			21236.857,
			21239.734,
			21241.637,
			21244.477,
			21246.398,
			21248.318,
			21251.168,
			21252.115,
			21254.006,
			21255.914,
			21256.865,
			21257.816,
			21260.658,
			21262.547,
			21264.432,
			21265.367,
			21269.082,
			21270.008,
			21272.781,
			21274.555,
			21276.324,
			21278.98,
			21280.75,
			21284.23,
			21285.092,
			21287.68,
			21289.408,
			21292.006,
			21293.742,
			21296.354,
			21298.098,
			21298.953,
			21300.699,
			21303.324,
			21305.088,
			21306.863,
			21309.553,
			21311.377,
			21315.041,
			21315.965,
			21317.826,
			21318.746,
			21320.625,
			21322.516,
			21325.391,
			21327.311,
			21330.215,
			21331.189,
			21332.166,
			21335.113,
			21337.092,
			21340.078,
			21342.084,
			21345.113,
			21347.146,
			21348.168,
			21352.285,
			21353.322,
			21355.406,
			21357.502,
			21360.645,
			21362.748,
			21365.92,
			21366.982,
			21368.047,
			21371.25,
			21373.395,
			21376.623,
			21378.781,
			21380.945,
			21384.199,
			21386.383,
			21390.771,
			21391.875,
			21395.203,
			21397.434,
			21400.781,
			21405.307,
			21406.445,
			21408.729,
			21409.875,
			21412.172,
			21414.482,
			21417.967,
			21420.303,
			21423.824,
			21425.004,
			21426.186,
			21429.744,
			21432.131,
			21435.727,
			21438.137,
			21441.77,
			21444.203,
			21445.426,
			21446.648,
			21450.332,
			21451.562,
			21452.797,
			21456.514,
			21459,
			21461.498,
			21462.75,
			21465.262,
			21467.779,
			21469.041,
			21472.84,
			21475.383,
			21477.934,
			21481.775,
			21484.346,
			21488.217,
			21490.807,
			21494.705,
			21497.312,
			21501.23,
			21503.844,
			21507.773,
			21510.398,
			21511.713,
			21514.35,
			21518.311,
			21520.959,
			21523.609,
			21530.223,
			21535.441,
			21536.727,
			21539.27,
			21540.527,
			21543.012,
			21545.455,
			21549.059,
			21551.412,
			21554.879,
			21556.018,
			21557.146,
			21560.477,
			21562.652,
			21565.871,
			21571.035,
			21573.076,
			21574.09,
			21576.105,
			21578.129,
			21581.121,
			21583.105,
			21586.07,
			21588.041,
			21591.971,
			21592.953,
			21595.908,
			21597.896,
			21599.891,
			21602.938,
			21604.98,
			21609.152,
			21610.201,
			21614.469,
			21615.543,
			21618.801,
			21620.982,
			21624.266,
			21628.637,
			21629.73,
			21632.984,
			21635.139,
			21637.295,
			21640.516,
			21642.674,
			21647.006,
			21648.096,
			21651.357,
			21653.561,
			21656.883,
			21659.111,
			21662.488,
			21665.863,
			21666.992,
			21670.375,
			21671.512,
			21672.652,
			21676.107,
			21678.408,
			21680.715,
			21681.871,
			21684.189,
			21686.516,
			21687.682,
			21691.195,
			21693.551,
			21695.914,
			21699.473,
			21701.855,
			21705.443,
			21707.844,
			21711.457,
			21713.875,
			21717.52,
			21719.938,
			21722.383,
			21723.611,
			21726.066,
			21727.295,
			21729.766,
			21733.42,
			21735.836,
			21738.225,
			21741.736,
			21744.051,
			21748.602,
			21749.729,
			21751.977,
			21753.102,
			21755.354,
			21757.605,
			21760.994,
			21763.236,
			21766.586,
			21767.695,
			21768.807,
			21772.109,
			21774.32,
			21777.639,
			21783.117,
			21785.305,
			21786.398,
			21788.568,
			21790.785,
			21791.889,
			21794.102,
			21796.326,
			21799.689,
			21801.945,
			21805.352,
			21806.492,
			21807.637,
			21811.084,
			21813.389,
			21815.709,
			21819.197,
			21821.539,
			21826.24,
			21827.422,
			21830.971,
			21833.348,
			21836.922,
			21839.312,
			21842.912,
			21845.32,
			21846.529,
			21848.951,
			21852.596,
			21855.035,
			21857.484,
			21861.172,
			21863.643,
			21867.383,
			21869.889,
			21873.676,
			21876.211,
			21880.047,
			21882.615,
			21886.486,
			21889.082,
			21890.383,
			21892.988,
			21896.916,
			21898.234,
			21902.199,
			21904.852,
			21907.512,
			21908.846,
			21914.213,
			21915.559,
			21919.584,
			21922.275,
			21924.977,
			21929.033,
			21931.736,
			21935.801,
			21937.156,
			21938.492,
			21942.59,
			21945.309,
			21949.387,
			21952.102,
			21954.816,
			21956.152,
			21958.871,
			21960.23,
			21962.957,
			21967.051,
			21969.787,
			21972.525,
			21975.27,
			21979.387,
			21982.135,
			21984.887,
			21986.262,
			21990.398,
			21993.164,
			21995.936,
			22000.105,
			22002.895,
			22007.092,
			22009.896,
			22014.117,
			22016.938,
			22021.184,
			22024.021,
			22028.289,
			22031.145,
			22032.572,
			22035.438,
			22038.307,
			22039.742,
			22042.623,
			22045.51,
			22052.756,
			22057.121,
			22060.039,
			22064.428,
			22067.359,
			22070.299,
			22074.721,
			22077.676,
			22082.117,
			22083.602,
			22085.086,
			22089.549,
			22091.041,
			22092.535,
			22097.027,
			22103.039,
			22104.547,
			22107.564,
			22112.105,
			22116.66,
			22119.703,
			22122.754,
			22127.34,
			22130.404,
			22136.553,
			22138.09,
			22142.719,
			22145.809,
			22150.455,
			22153.559,
			22156.668,
			22158.225,
			22161.34,
			22162.902,
			22164.465,
			22170.723,
			22172.289,
			22177,
			22180.148,
			22183.299,
			22184.879,
			22188.037,
			22192.789,
			22195.959,
			22200.727,
			22203.91,
			22208.684,
			22211.871,
			22216.664,
			22218.266,
			22219.863,
			22226.277,
			22227.883,
			22232.709,
			22235.932,
			22240.775,
			22244.008,
			22247.248,
			22248.869,
			22252.113,
			22253.736,
			22256.988,
			22260.244,
			22261.875,
			22265.137,
			22268.406,
			22271.676,
			22276.594,
			22283.164,
			22284.811,
			22289.754,
			22293.053,
			22296.359,
			22301.326,
			22304.643,
			22309.627,
			22312.953,
			22317.955,
			22321.293,
			22326.311,
			22329.662,
			22334.697,
			22338.061,
			22339.742,
			22343.113,
			22348.176,
			22351.555,
			22354.938,
			22360.023,
			22361.719,
			22363.418,
			22370.219,
			22375.332,
			22377.037,
			22380.453,
			22383.873,
			22389.008,
			22392.439,
			22397.594,
			22401.035,
			22406.203,
			22409.656,
			22414.842,
			22418.303,
			22423.504,
			22426.975,
			22428.713,
			22432.191,
			22440.9,
			22444.391,
			22449.635,
			22453.133,
			22458.391,
			22461.902,
			22467.174,
			22470.691,
			22475.977,
			22483.037,
			22488.344,
			22490.115,
			22491.885,
			22498.979,
			22500.754,
			22506.082,
			22509.637,
			22514.979,
			22518.541,
			22522.109,
			22523.895,
			22527.467,
			22529.254,
			22532.83,
			22536.41,
			22545.371,
			22552.557,
			22554.354,
			22559.752,
			22563.355,
			22568.766,
			22577.797,
			22581.414,
			22586.846,
			22590.471,
			22592.285,
			22595.914,
			22599.545,
			22603.18,
			22608.635,
			22612.277,
			22615.922,
			22617.744,
			22623.217,
			22626.867,
			22630.523,
			22636.01,
			22639.67,
			22645.168,
			22648.836,
			22654.342,
			22658.016,
			22663.533,
			22667.213,
			22672.742,
			22676.43,
			22678.273,
			22681.965,
			22687.504,
			22691.203,
			22694.902,
			22698.604,
			22702.309,
			22704.162,
			22707.871,
			22711.584,
			22713.439,
			22717.154,
			22719.014,
			22722.73,
			22726.453,
			22732.039,
			22735.77,
			22741.365,
			22743.23,
			22745.098,
			22750.703,
			22754.443,
			22760.057,
			22763.801,
			22767.549,
			22769.422,
			22775.047,
			22778.801,
			22784.434,
			22788.191,
			22791.951,
			22797.596,
			22801.361,
			22807.012,
			22808.896,
			22810.783,
			22816.443,
			22820.219,
			22823.996,
			22829.668,
			22833.449,
			22839.129,
			22841.023,
			22842.918,
			22848.602,
			22852.395,
			22858.086,
			22861.883,
			22867.584,
			22871.383,
			22875.189,
			22877.094,
			22880.902,
			22886.617,
			22890.434,
			22896.16,
			22899.98,
			22905.715,
			22907.629,
			22909.541,
			22915.281,
			22919.113,
			22924.857,
			22928.691,
			22934.443,
			22938.281,
			22942.121,
			22947.879,
			22949.801,
			22953.643,
			22957.484,
			22961.328,
			22967.098,
			22970.945,
			22976.721,
			22982.504,
			22986.359,
			22990.217,
			22996.004,
			22999.865,
			23007.594,
			23009.527,
			23015.328,
			23019.199,
			23025.006,
			23028.879,
			23034.691,
			23038.57,
			23040.51,
			23044.389,
			23050.211,
			23054.094,
			23057.979,
			23063.805,
			23067.693,
			23075.469,
			23077.414,
			23081.305,
			23083.252,
			23087.145,
			23091.039,
			23096.883,
			23100.777,
			23106.625,
			23108.574,
			23110.525,
			23116.375,
			23118.326,
			23120.277,
			23126.133,
			23133.939,
			23135.895,
			23139.801,
			23141.754,
			23145.66,
			23149.57,
			23151.523,
			23155.434,
			23159.346,
			23165.213,
			23169.129,
			23174.996,
			23176.953,
			23178.912,
			23184.785,
			23188.701,
			23192.617,
			23198.496,
			23202.414,
			23210.254,
			23212.213,
			23218.094,
			23222.012,
			23227.895,
			23231.818,
			23237.701,
			23245.551,
			23247.512,
			23253.4,
			23257.324,
			23261.248,
			23267.137,
			23271.062,
			23276.953,
			23278.916,
			23280.879,
			23286.77,
			23290.695,
			23296.586,
			23300.514,
			23306.404,
			23310.332,
			23312.295,
			23316.225,
			23320.154,
			23324.082,
			23329.975,
			23333.902,
			23337.83,
			23339.795,
			23343.723,
			23347.652,
			23349.615,
			23355.508,
			23363.363,
			23369.254,
			23373.184,
			23379.074,
			23381.039,
			23383.002,
			23388.893,
			23392.818,
			23398.705,
			23402.633,
			23406.559,
			23408.523,
			23412.451,
			23414.414,
			23418.344,
			23424.234,
			23428.164,
			23432.094,
			23436.021,
			23441.914,
			23445.842,
			23451.736,
			23455.666,
			23457.631,
			23461.559,
			23465.488,
			23471.381,
			23475.309,
			23483.164,
			23485.129,
			23491.021,
			23494.949,
			23500.844,
			23510.666,
			23516.561,
			23520.49,
			23526.387,
			23530.314,
			23534.244,
			23540.139,
			23544.068,
			23549.965,
			23551.93,
			23553.895,
			23559.789,
			23563.721,
			23569.619,
			23573.551,
			23577.484,
			23585.352,
			23587.316,
			23593.219,
			23597.154,
			23603.059,
			23606.994,
			23612.898,
			23616.834,
			23622.738,
			23628.645,
			23632.582,
			23636.52,
			23646.367,
			23652.275,
			23654.246,
			23662.125,
			23666.062,
			23671.973,
			23675.914,
			23681.822,
			23685.764,
			23691.676,
			23697.586,
			23699.559,
			23705.473,
			23709.418,
			23713.359,
			23715.332,
			23719.277,
			23723.221,
			23729.135,
			23731.105,
			23735.051,
			23738.994,
			23744.912,
			23748.855,
			23754.775,
			23758.721,
			23766.613,
			23768.586,
			23774.506,
			23778.453,
			23784.375,
			23788.322,
			23790.297,
			23794.244,
			23798.191,
			23804.113,
			23808.062,
			23812.012,
			23817.934,
			23825.83,
			23827.805,
			23833.729,
			23837.68,
			23841.631,
			23851.508,
			23857.436,
			23861.389,
			23867.318,
			23873.248,
			23877.203,
			23881.156,
			23887.09,
			23891.043,
			23893.021,
			23896.979,
			23900.934,
			23902.914,
			23906.871,
			23910.828,
			23916.762,
			23918.742,
			23920.721,
			23928.637,
			23930.617,
			23936.555,
			23940.516,
			23946.457,
			23950.418,
			23954.383,
			23962.312,
			23964.293,
			23970.242,
			23974.207,
			23980.158,
			23984.125,
			23990.078,
			23994.045,
			24000,
			24005.957,
			24009.93,
			24013.902,
			24019.867,
			24023.842,
			24031.791,
			24033.779,
			24039.738,
			24043.713,
			24049.672,
			24053.646,
			24059.613,
			24065.578,
			24069.559,
			24075.531,
			24077.527,
			24083.518,
			24093.516,
			24097.52,
			24103.527,
			24107.537,
			24109.551,
			24113.576,
			24117.605,
			24123.652,
			24127.68,
			24133.717,
			24135.73,
			24137.75,
			24143.803,
			24147.848,
			24153.918,
			24162.012,
			24164.029,
			24168.064,
			24174.121,
			24180.176,
			24184.207,
			24188.23,
			24194.256,
			24198.27,
			24206.289,
			24208.295,
			24214.312,
			24218.324,
			24222.338,
			24232.385,
			24238.42,
			24240.434,
			24242.445,
			24248.492,
			24252.523,
			24262.607,
			24268.656,
			24272.691,
			24278.746,
			24284.805,
			24288.846,
			24292.883,
			24300.965,
			24302.984,
			24309.049,
			24313.094,
			24319.164,
			24323.211,
			24329.283,
			24333.332,
			24337.379,
			24343.453,
			24347.502,
			24353.578,
			24355.605,
			24357.631,
			24363.711,
			24371.82,
			24373.848,
			24379.932,
			24384.021,
			24390.104,
			24394.156,
			24398.205,
			24404.27,
			24408.291,
			24416.225,
			24418.195,
			24424.051,
			24427.898,
			24433.586,
			24437.328,
			24442.898,
			24446.59,
			24448.428,
			24452.086,
			24457.572,
			24459.355,
			24464.801,
			24468.428,
			24472.059,
			24473.879,
			24477.516,
			24483.008,
			24486.65,
			24488.475,
			24492.121,
			24495.746,
			24501.234,
			24504.896,
			24510.426,
			24512.262,
			24514.064,
			24519.568,
			24523.238,
			24528.742,
			24532.404,
			24536.066,
			24537.896,
			24541.555,
			24547.031,
			24552.512,
			24556.125,
			24559.713,
			24563.242,
			24568.52,
			24571.99,
			24575.426,
			24577.129,
			24582.207,
			24585.572,
			24588.965,
			24594.008,
			24597.338,
			24602.393,
			24605.74,
			24610.771,
			24619.189,
			24622.562,
			24627.617,
			24630.982,
			24636.039,
			24639.41,
			24641.098,
			24644.475,
			24647.82,
			24652.869,
			24654.541,
			24656.215,
			24661.219,
			24662.883,
			24664.547,
			24669.539,
			24672.857,
			24676.16,
			24681.068,
			24684.301,
			24689.082,
			24690.66,
			24692.229,
			24696.871,
			24699.918,
			24704.424,
			24707.385,
			24711.758,
			24714.621,
			24718.848,
			24722.986,
			24725.699,
			24728.375,
			24732.32,
			24734.902,
			24741.209,
			24744.904,
			24747.348,
			24750.936,
			24753.287,
			24756.77,
			24759.051,
			24762.414,
			24765.703,
			24766.789,
			24770.02,
			24772.193,
			24775.449,
			24777.613,
			24779.762,
			24780.834,
			24784.02,
			24786.107,
			24788.15,
			24791.168,
			24793.133,
			24796.004,
			24796.941,
			24797.871,
			24800.604,
			24802.375,
			24804.961,
			24806.637,
			24809.072,
			24810.652,
			24812.949,
			24815.156,
			24816.584,
			24817.986,
			24819.348,
			24821.379,
			24824.055,
			24824.719,
			24826.697,
			24828.004,
			24829.332,
			24831.305,
			24832.602,
			24834.529,
			24835.168,
			24835.805,
			24837.705,
			24839.031,
			24840.996,
			24842.293,
			24844.234,
			24845.527,
			24846.184,
			24847.543,
			24848.891,
			24849.566,
			24850.906,
			24852.25,
			24854.268,
			24854.939,
			24855.607,
			24858.281,
			24858.953,
			24860.969,
			24862.312,
			24865.688,
			24867.049,
			24869.102,
			24870.479,
			24872.561,
			24873.961,
			24876.043,
			24877.43,
			24879.531,
			24880.947,
			24882.359,
			24883.068,
			24885.215,
			24886.666,
			24888.131,
			24891.859,
			24894.141,
			24894.91,
			24895.684,
			24898.023,
			24899.619,
			24902.035,
			24903.68,
			24905.324,
			24907.863,
			24908.719,
			24909.576,
			24913.033,
			24913.9,
			24916.547,
			24921.008,
			24922.816,
			24925.555,
			24928.316,
			24930.178,
			24932.002,
			24934.754,
			24936.602,
			24939.385,
			24940.299,
			24941.215,
			24943.973,
			24945.818,
			24948.6,
			24950.457,
			24953.25,
			24955.113,
			24956.047,
			24957.914,
			24959.785,
			24962.592,
			24964.469,
			24966.34,
			24969.193,
			24973.02,
			24973.982,
			24976.875,
			24978.828,
			24980.791,
			24985.75,
			24988.758,
			24989.768,
			24990.777,
			24993.828,
			24995.877,
			24998.967,
			25001.041,
			25004.172,
			25006.27,
			25009.412,
			25012.568,
			25014.686,
			25016.812,
			25021.094,
			25022.17,
			25027.588,
			25030.867,
			25033.064,
			25035.27,
			25038.596,
			25040.826,
			25044.188,
			25046.441,
			25050.98,
			25052.121,
			25055.559,
			25057.861,
			25060.176,
			25061.334,
			25063.66,
			25064.828,
			25069.521,
			25070.699,
			25073.064,
			25075.438,
			25079.016,
			25081.414,
			25085.025,
			25086.234,
			25087.445,
			25091.09,
			25093.533,
			25097.211,
			25099.674,
			25102.146,
			25103.387,
			25105.871,
			25107.117,
			25108.361,
			25113.369,
			25114.648,
			25118.432,
			25120.938,
			25124.732,
			25127.271,
			25129.816,
			25131.094,
			25133.654,
			25134.938,
			25137.514,
			25140.098,
			25143.988,
			25146.594,
			25150.512,
			25151.82,
			25153.125,
			25157.053,
			25159.678,
			25163.627,
			25166.266,
			25170.238,
			25172.895,
			25176.891,
			25179.561,
			25180.9,
			25183.582,
			25186.27,
			25190.316,
			25193.023,
			25195.734,
			25198.457,
			25199.82,
			25203.918,
			25206.662,
			25209.412,
			25213.551,
			25216.316,
			25220.482,
			25221.873,
			25223.266,
			25227.457,
			25230.262,
			25234.48,
			25237.301,
			25240.129,
			25241.547,
			25244.385,
			25245.805,
			25248.656,
			25252.939,
			25255.805,
			25258.674,
			25265.879,
			25268.773,
			25271.672,
			25273.125,
			25276.033,
			25277.49,
			25280.406,
			25284.795,
			25287.727,
			25290.666,
			25295.086,
			25296.562,
			25298.041,
			25302.484,
			25305.455,
			25309.926,
			25312.912,
			25315.906,
			25320.408,
			25324.924,
			25327.941,
			25329.453,
			25332.479,
			25335.512,
			25340.072,
			25343.119,
			25347.699,
			25349.23,
			25350.762,
			25355.363,
			25358.441,
			25361.521
		],
		[
			0,
			0.074,
			0.119,
			0.188,
			0.243,
			0.29,
			0.306,
			0.367,
			0.399,
			0.431,
			0.508,
			0.553,
			0.615,
			0.679,
			0.74,
			0.802,
			0.862,
			0.926,
			0.989,
			1.051,
			1.112,
			1.173,
			1.236,
			1.297,
			1.375,
			1.42,
			1.483,
			1.546,
			1.592,
			1.609,
			1.669,
			1.731,
			1.795,
			1.856,
			1.917,
			1.979,
			2.043,
			2.103,
			2.165,
			2.242,
			2.29,
			2.352,
			2.414,
			2.461,
			2.477,
			2.569,
			2.6,
			2.676,
			2.724,
			2.787,
			2.847,
			2.911,
			3.003,
			3.035,
			3.111,
			3.159,
			3.219,
			3.281,
			3.329,
			3.437,
			3.468,
			3.53,
			3.544,
			3.592,
			3.654,
			3.715,
			3.777,
			3.841,
			3.873,
			3.903,
			3.979,
			4.027,
			4.092,
			4.149,
			4.213,
			4.305,
			4.335,
			4.397,
			4.46,
			4.525,
			4.583,
			4.645,
			4.74,
			4.772,
			4.833,
			4.849,
			4.895,
			4.957,
			5.019,
			5.082,
			5.142,
			5.206,
			5.283,
			5.328,
			5.391,
			5.453,
			5.515,
			5.608,
			5.638,
			5.7,
			5.763,
			5.826,
			5.887,
			5.934,
			5.95,
			6.011,
			6.043,
			6.074,
			6.154,
			6.195,
			6.264,
			6.322,
			6.383,
			6.444,
			6.476,
			6.506,
			6.569,
			6.632,
			6.693,
			6.756,
			6.816,
			6.879,
			6.911,
			6.942,
			7.019,
			7.065,
			7.127,
			7.189,
			7.251,
			7.312,
			7.374,
			7.437,
			7.453,
			7.499,
			7.561,
			7.623,
			7.685,
			7.779,
			7.809,
			7.888,
			7.933,
			7.994,
			8.058,
			8.102,
			8.12,
			8.182,
			8.243,
			8.321,
			8.367,
			8.429,
			8.492,
			8.552,
			8.647,
			8.678,
			8.754,
			8.8,
			8.865,
			8.924,
			8.972,
			9.047,
			9.11,
			9.19,
			9.235,
			9.299,
			9.358,
			9.422,
			9.484,
			9.514,
			9.545,
			9.623,
			9.67,
			9.732,
			9.794,
			9.853,
			9.918,
			9.949,
			9.98,
			10.057,
			10.102,
			10.164,
			10.227,
			10.289,
			10.353,
			10.381,
			10.413,
			10.476,
			10.537,
			10.601,
			10.661,
			10.724,
			10.786,
			10.817,
			10.848,
			10.924,
			10.972,
			11.032,
			11.094,
			11.158,
			11.252,
			11.282,
			11.345,
			11.405,
			11.468,
			11.531,
			11.591,
			11.653,
			11.684,
			11.715,
			11.792,
			11.84,
			11.902,
			12.009,
			12.024,
			12.086,
			12.148,
			12.212,
			12.274,
			12.342,
			12.396,
			12.458,
			12.522,
			12.583,
			12.662,
			12.707,
			12.77,
			12.83,
			12.892,
			12.956,
			12.987,
			13.017,
			13.095,
			13.143,
			13.205,
			13.265,
			13.328,
			13.391,
			13.422,
			13.453,
			13.538,
			13.576,
			13.637,
			13.746,
			13.856,
			13.885,
			13.965,
			14.009,
			14.074,
			14.134,
			14.197,
			14.289,
			14.32,
			14.403,
			14.445,
			14.505,
			14.569,
			14.614,
			14.631,
			14.693,
			14.753,
			14.831,
			14.878,
			14.94,
			15.064,
			15.125,
			15.188,
			15.249,
			15.311,
			15.375,
			15.435,
			15.498,
			15.592,
			15.623,
			15.698,
			15.745,
			15.81,
			15.87,
			15.931,
			16.025,
			16.056,
			16.135,
			16.182,
			16.242,
			16.303,
			16.367,
			16.429,
			16.467,
			16.492,
			16.569,
			16.615,
			16.677,
			16.786,
			16.8,
			16.924,
			17.002,
			17.049,
			17.111,
			17.174,
			17.233,
			17.295,
			17.327,
			17.358,
			17.436,
			17.483,
			17.545,
			17.608,
			17.67,
			17.732,
			17.761,
			17.793,
			17.855,
			17.915,
			17.979,
			18.04,
			18.102,
			18.163,
			18.195,
			18.227,
			18.305,
			18.351,
			18.413,
			18.473,
			18.536,
			18.599,
			18.659,
			18.723,
			18.786,
			18.847,
			18.907,
			18.97,
			19.033,
			19.064,
			19.095,
			19.172,
			19.218,
			19.282,
			19.391,
			19.405,
			19.498,
			19.53,
			19.606,
			19.652,
			19.715,
			19.778,
			19.839,
			19.931,
			19.964,
			20.041,
			20.086,
			20.15,
			20.259,
			20.273,
			20.367,
			20.397,
			20.522,
			20.583,
			20.646,
			20.707,
			20.768,
			20.832,
			20.909,
			20.954,
			21.02,
			21.079,
			21.142,
			21.202,
			21.233,
			21.266,
			21.342,
			21.39,
			21.451,
			21.513,
			21.576,
			21.638,
			21.668,
			21.698,
			21.762,
			21.786,
			21.824,
			21.9,
			21.947,
			22.009,
			22.118,
			22.134,
			22.197,
			22.258,
			22.32,
			22.382,
			22.443,
			22.505,
			22.568,
			22.631,
			22.667,
			22.692,
			22.776,
			22.814,
			22.878,
			22.94,
			23.001,
			23.063,
			23.093,
			23.125,
			23.203,
			23.248,
			23.311,
			23.373,
			23.437,
			23.498,
			23.561,
			23.642,
			23.684,
			23.745,
			23.869,
			23.93,
			23.963,
			23.993,
			24.072,
			24.116,
			24.182,
			24.242,
			24.305,
			24.366,
			24.398,
			24.427,
			24.506,
			24.553,
			24.615,
			24.727,
			24.799,
			24.861,
			24.925,
			24.985,
			25.047,
			25.111,
			25.172,
			25.233,
			25.265,
			25.295,
			25.374,
			25.42,
			25.483,
			25.543,
			25.591,
			25.606,
			25.667,
			25.699,
			25.73,
			25.808,
			25.854,
			25.915,
			25.979,
			26.04,
			26.133,
			26.165,
			26.242,
			26.288,
			26.35,
			26.46,
			26.475,
			26.536,
			26.568,
			26.66,
			26.675,
			26.722,
			26.789,
			26.845,
			26.908,
			26.97,
			27.002,
			27.032,
			27.108,
			27.157,
			27.22,
			27.328,
			27.343,
			27.402,
			27.436,
			27.465,
			27.542,
			27.591,
			27.653,
			27.716,
			27.776,
			27.838,
			27.869,
			27.9,
			27.964,
			28.024,
			28.088,
			28.149,
			28.194,
			28.272,
			28.303,
			28.333,
			28.412,
			28.52,
			28.583,
			28.644,
			28.738,
			28.769,
			28.829,
			28.853,
			28.892,
			28.956,
			29.064,
			29.078,
			29.141,
			29.202,
			29.282,
			29.327,
			29.39,
			29.45,
			29.497,
			29.575,
			29.606,
			29.637,
			29.7,
			29.761,
			29.823,
			29.885,
			29.947,
			30.041,
			30.069,
			30.147,
			30.194,
			30.32,
			30.382,
			30.474,
			30.505,
			30.567,
			30.582,
			30.628,
			30.691,
			30.753,
			30.814,
			30.878,
			30.915,
			30.939,
			31.015,
			31.063,
			31.126,
			31.187,
			31.232,
			31.249,
			31.343,
			31.374,
			31.45,
			31.496,
			31.558,
			31.622,
			31.684,
			31.744,
			31.808,
			31.883,
			31.93,
			31.993,
			32.101,
			32.18,
			32.211,
			32.241,
			32.32,
			32.364,
			32.427,
			32.489,
			32.551,
			32.644,
			32.674,
			32.753,
			32.8,
			32.862,
			32.923,
			32.977,
			33.108,
			33.171,
			33.234,
			33.298,
			33.357,
			33.42,
			33.482,
			33.516,
			33.545,
			33.622,
			33.667,
			33.73,
			33.792,
			33.853,
			33.914,
			33.946,
			33.977,
			34.054,
			34.1,
			34.165,
			34.224,
			34.288,
			34.349,
			34.382,
			34.412,
			34.489,
			34.535,
			34.599,
			34.66,
			34.707,
			34.722,
			34.814,
			34.845,
			34.924,
			34.97,
			35.095,
			35.156,
			35.249,
			35.279,
			35.342,
			35.358,
			35.403,
			35.467,
			35.527,
			35.574,
			35.682,
			35.714,
			35.79,
			35.839,
			35.901,
			35.963,
			36.023,
			36.115,
			36.146,
			36.225,
			36.27,
			36.335,
			36.395,
			36.444,
			36.519,
			36.583,
			36.658,
			36.706,
			36.767,
			36.875,
			36.953,
			36.983,
			37.017,
			37.077,
			37.103,
			37.14,
			37.203,
			37.265,
			37.326,
			37.389,
			37.419,
			37.45,
			37.527,
			37.574,
			37.635,
			37.698,
			37.744,
			37.823,
			37.885,
			37.948,
			38.008,
			38.07,
			38.132,
			38.195,
			38.256,
			38.286,
			38.319,
			38.396,
			38.442,
			38.503,
			38.614,
			38.627,
			38.691,
			38.722,
			38.814,
			38.829,
			38.876,
			38.94,
			38.999,
			39.064,
			39.16,
			39.187,
			39.265,
			39.31,
			39.373,
			39.481,
			39.496,
			39.59,
			39.62,
			39.697,
			39.746,
			39.806,
			39.868,
			39.929,
			40.024,
			40.055,
			40.117,
			40.131,
			40.178,
			40.242,
			40.348,
			40.426,
			40.456,
			40.49,
			40.564,
			40.614,
			40.675,
			40.736,
			40.8,
			40.86,
			40.923,
			40.985,
			40.999,
			41.047,
			41.108,
			41.172,
			41.232,
			41.294,
			41.325,
			41.357,
			41.434,
			41.48,
			41.543,
			41.606,
			41.651,
			41.73,
			41.791,
			41.869,
			41.915,
			41.977,
			42.04,
			42.102,
			42.163,
			42.224,
			42.302,
			42.348,
			42.411,
			42.474,
			42.535,
			42.596,
			42.66,
			42.722,
			42.782,
			42.845,
			42.908,
			42.967,
			43.03,
			43.064,
			43.094,
			43.17,
			43.216,
			43.285,
			43.341,
			43.403,
			43.464,
			43.526,
			43.605,
			43.651,
			43.714,
			43.776,
			43.837,
			43.931,
			43.961,
			44.039,
			44.086,
			44.148,
			44.21,
			44.256,
			44.272,
			44.334,
			44.363,
			44.395,
			44.474,
			44.52,
			44.582,
			44.644,
			44.706,
			44.768,
			44.799,
			44.829,
			44.89,
			44.953,
			45.015,
			45.079,
			45.139,
			45.201,
			45.232,
			45.264,
			45.346,
			45.389,
			45.449,
			45.511,
			45.574,
			45.668,
			45.696,
			45.776,
			45.822,
			45.884,
			45.947,
			46.009,
			46.07,
			46.132,
			46.208,
			46.256,
			46.316,
			46.38,
			46.442,
			46.503,
			46.535,
			46.564,
			46.644,
			46.691,
			46.753,
			46.814,
			46.875,
			46.969,
			47.001,
			47.077,
			47.122,
			47.185,
			47.248,
			47.311,
			47.408,
			47.433,
			47.495,
			47.558,
			47.62,
			47.681,
			47.745,
			47.807,
			47.837,
			47.869,
			47.946,
			47.993,
			48.055,
			48.116,
			48.178,
			48.272,
			48.3,
			48.365,
			48.38,
			48.427,
			48.49,
			48.55,
			48.615,
			48.675,
			48.706,
			48.736,
			48.812,
			48.861,
			48.923,
			48.984,
			49.03,
			49.107,
			49.14,
			49.17,
			49.232,
			49.293,
			49.358,
			49.418,
			49.479,
			49.542,
			49.574,
			49.606,
			49.683,
			49.727,
			49.792,
			49.853,
			49.898,
			50.039,
			50.1,
			50.164,
			50.225,
			50.286,
			50.348,
			50.411,
			50.442,
			50.47,
			50.548,
			50.599,
			50.658,
			50.721,
			50.767,
			50.782,
			50.846,
			50.876,
			50.907,
			50.984,
			51.032,
			51.092,
			51.155,
			51.217,
			51.278,
			51.309,
			51.341,
			51.418,
			51.464,
			51.534,
			51.589,
			51.635,
			51.649,
			51.743,
			51.774,
			51.836,
			51.897,
			51.961,
			52.022,
			52.085,
			52.148,
			52.208,
			52.287,
			52.333,
			52.394,
			52.502,
			52.52,
			52.582,
			52.611,
			52.644,
			52.721,
			52.83,
			52.89,
			52.952,
			53.013,
			53.047,
			53.076,
			53.155,
			53.2,
			53.263,
			53.324,
			53.388,
			53.449,
			53.511,
			53.593,
			53.636,
			53.701,
			53.758,
			53.82,
			53.884,
			53.946,
			54.007,
			54.068,
			54.131,
			54.24,
			54.256,
			54.318,
			54.347,
			54.379,
			54.457,
			54.503,
			54.564,
			54.627,
			54.689,
			54.752,
			54.782,
			54.812,
			54.875,
			54.938,
			54.999,
			55.109,
			55.125,
			55.184,
			55.248,
			55.323,
			55.433,
			55.495,
			55.557,
			55.657,
			55.683,
			55.742,
			55.806,
			55.867,
			55.93,
			55.993,
			56.053,
			56.117,
			56.192,
			56.24,
			56.303,
			56.362,
			56.427,
			56.488,
			56.517,
			56.551,
			56.611,
			56.626,
			56.673,
			56.737,
			56.798,
			56.844,
			56.923,
			56.953,
			56.984,
			57.061,
			57.107,
			57.17,
			57.279,
			57.355,
			57.417,
			57.48,
			57.496,
			57.542,
			57.603,
			57.664,
			57.726,
			57.82,
			57.85,
			57.928,
			57.976,
			58.039,
			58.1,
			58.147,
			58.255,
			58.286,
			58.347,
			58.41,
			58.473,
			58.535,
			58.596,
			58.657,
			58.689,
			58.719,
			58.799,
			58.844,
			58.907,
			58.968,
			59.032,
			59.093,
			59.154,
			59.277,
			59.34,
			59.402,
			59.464,
			59.556,
			59.589,
			59.65,
			59.712,
			59.779,
			59.836,
			59.898,
			59.96,
			60.023,
			60.098,
			60.147,
			60.269,
			60.331,
			60.394,
			60.424,
			60.456,
			60.534,
			60.579,
			60.642,
			60.75,
			60.827,
			60.858,
			60.89,
			60.967,
			61.014,
			61.077,
			61.139,
			61.2,
			61.264,
			61.294,
			61.324,
			61.401,
			61.448,
			61.512,
			61.572,
			61.635,
			61.727,
			61.759,
			61.842,
			61.883,
			61.944,
			62.053,
			62.13,
			62.161,
			62.192,
			62.255,
			62.317,
			62.381,
			62.44,
			62.502,
			62.564,
			62.596,
			62.628,
			62.705,
			62.752,
			62.813,
			62.875,
			62.936,
			63.031,
			63.061,
			63.138,
			63.185,
			63.248,
			63.308,
			63.372,
			63.464,
			63.494,
			63.556,
			63.62,
			63.686,
			63.742,
			63.806,
			63.866,
			63.932,
			64.052,
			64.114,
			64.177,
			64.239,
			64.332,
			64.364,
			64.44,
			64.486,
			64.551,
			64.611,
			64.674,
			64.736,
			64.767,
			64.796,
			64.875,
			64.984,
			65.045,
			65.107,
			65.17,
			65.201,
			65.232,
			65.293,
			65.355,
			65.417,
			65.48,
			65.527,
			65.634,
			65.664,
			65.744,
			65.79,
			65.851,
			65.913,
			65.975,
			66.036,
			66.068,
			66.1,
			66.161,
			66.176,
			66.223,
			66.285,
			66.393,
			66.473,
			66.533,
			66.612,
			66.658,
			66.719,
			66.781,
			66.842,
			66.937,
			66.968,
			67.044,
			67.092,
			67.153,
			67.214,
			67.263,
			67.278,
			67.34,
			67.402,
			67.479,
			67.526,
			67.59,
			67.65,
			67.698,
			67.774,
			67.835,
			67.898,
			67.958,
			68.027,
			68.084,
			68.145,
			68.209,
			68.239,
			68.27,
			68.347,
			68.395,
			68.456,
			68.564,
			68.642,
			68.703,
			68.782,
			68.827,
			68.89,
			68.95,
			69.015,
			69.075,
			69.108,
			69.136,
			69.215,
			69.261,
			69.323,
			69.385,
			69.431,
			69.449,
			69.509,
			69.54,
			69.634,
			69.695,
			69.756,
			69.819,
			69.881,
			69.944,
			70.006,
			70.089,
			70.129,
			70.192,
			70.254,
			70.3,
			70.408,
			70.502,
			70.564,
			70.628,
			70.686,
			70.751,
			70.814,
			70.873,
			70.953,
			70.997,
			71.062,
			71.123,
			71.17,
			71.247,
			71.277,
			71.308,
			71.386,
			71.434,
			71.494,
			71.556,
			71.618,
			71.682,
			71.714,
			71.743,
			71.803,
			71.819,
			71.867,
			71.928,
			71.991,
			72.036,
			72.052,
			72.115,
			72.175,
			72.239,
			72.255,
			72.301,
			72.365,
			72.423,
			72.486,
			72.578,
			72.611,
			72.689,
			72.734,
			72.797,
			72.903,
			72.921,
			73.044,
			73.107,
			73.122,
			73.169,
			73.23,
			73.294,
			73.356,
			73.417,
			73.479,
			73.557,
			73.603,
			73.667,
			73.726,
			73.774,
			73.852,
			73.883,
			73.914,
			73.99,
			74.038,
			74.098,
			74.16,
			74.223,
			74.285,
			74.315,
			74.348,
			74.409,
			74.424,
			74.47,
			74.533,
			74.595,
			74.642,
			74.658,
			74.719,
			74.748,
			74.78,
			74.859,
			74.903,
			74.968,
			74.971,
			75.028,
			75.091,
			75.152,
			75.182,
			75.214,
			75.275,
			75.336,
			75.399,
			75.464,
			75.509,
			75.524,
			75.586,
			75.616,
			75.648,
			75.726,
			75.773,
			75.836,
			75.897,
			75.957,
			76.02,
			76.053,
			76.082,
			76.148,
			76.206,
			76.274,
			76.33,
			76.377,
			76.453,
			76.486,
			76.515,
			76.594,
			76.642,
			76.703,
			76.766,
			76.826,
			76.92,
			76.952,
			77.013,
			77.074,
			77.137,
			77.245,
			77.324,
			77.352,
			77.385,
			77.464,
			77.509,
			77.572,
			77.633,
			77.697,
			77.758,
			77.787,
			77.819,
			77.883,
			77.896,
			77.943,
			78.006,
			78.066,
			78.131,
			78.191,
			78.223,
			78.253,
			78.315,
			78.334,
			78.377,
			78.439,
			78.501,
			78.549,
			78.688,
			78.749,
			78.812,
			78.875,
			78.936,
			78.997,
			79.061,
			79.09,
			79.122,
			79.2,
			79.247,
			79.311,
			79.417,
			79.432,
			79.492,
			79.527,
			79.557,
			79.641,
			79.741,
			79.757,
			79.803,
			79.866,
			79.927,
			79.99,
			80.053,
			80.081,
			80.112,
			80.192,
			80.237,
			80.301,
			80.361,
			80.411,
			80.425,
			80.485,
			80.517,
			80.547,
			80.626,
			80.672,
			80.734,
			80.795,
			80.858,
			80.92,
			80.95,
			80.98,
			81.059,
			81.106,
			81.172,
			81.229,
			81.277,
			81.352,
			81.386,
			81.415,
			81.479,
			81.493,
			81.54,
			81.604,
			81.664,
			81.725,
			81.789,
			81.818,
			81.851,
			81.926,
			81.974,
			82.037,
			82.097,
			82.161,
			82.224,
			82.285,
			82.347,
			82.408,
			82.474,
			82.532,
			82.593,
			82.656,
			82.686,
			82.718,
			82.796,
			82.841,
			82.903,
			82.967,
			83.015,
			83.028,
			83.09,
			83.12,
			83.152,
			83.214,
			83.23,
			83.277,
			83.338,
			83.4,
			83.464,
			83.524,
			83.556,
			83.586,
			83.663,
			83.711,
			83.773,
			83.835,
			83.896,
			83.959,
			83.99,
			84.019,
			84.097,
			84.145,
			84.208,
			84.269,
			84.331,
			84.392,
			84.424,
			84.453,
			84.538,
			84.579,
			84.641,
			84.749,
			84.827,
			84.859,
			84.886,
			84.965,
			85.014,
			85.074,
			85.137,
			85.197,
			85.26,
			85.292,
			85.322,
			85.385,
			85.4,
			85.447,
			85.508,
			85.571,
			85.618,
			85.694,
			85.725,
			85.757,
			85.834,
			85.882,
			85.942,
			86.006,
			86.067,
			86.13,
			86.158,
			86.191,
			86.252,
			86.269,
			86.315,
			86.378,
			86.439,
			86.485,
			86.502,
			86.562,
			86.6,
			86.624,
			86.702,
			86.749,
			86.81,
			86.873,
			86.935,
			86.997,
			87.027,
			87.058,
			87.12,
			87.184,
			87.244,
			87.308,
			87.353,
			87.37,
			87.43,
			87.461,
			87.492,
			87.57,
			87.617,
			87.68,
			87.74,
			87.803,
			87.864,
			87.897,
			87.927,
			88.003,
			88.052,
			88.113,
			88.176,
			88.238,
			88.3,
			88.331,
			88.361,
			88.437,
			88.484,
			88.547,
			88.608,
			88.658,
			88.672,
			88.734,
			88.764,
			88.796,
			88.872,
			88.917,
			88.98,
			89.042,
			89.106,
			89.167,
			89.198,
			89.23,
			89.306,
			89.352,
			89.417,
			89.478,
			89.524,
			89.601,
			89.662,
			89.74,
			89.789,
			89.85,
			89.911,
			89.973,
			90.037,
			90.066,
			90.097,
			90.16,
			90.176,
			90.222,
			90.283,
			90.345,
			90.393,
			90.409,
			90.47,
			90.502,
			90.533,
			90.608,
			90.656,
			90.72,
			90.78,
			90.842,
			90.902,
			90.935,
			90.965,
			91.029,
			91.09,
			91.151,
			91.215,
			91.261,
			91.276,
			91.337,
			91.37,
			91.4,
			91.476,
			91.524,
			91.648,
			91.708,
			91.774,
			91.803,
			91.834,
			91.895,
			91.959,
			92.02,
			92.081,
			92.144,
			92.206,
			92.235,
			92.268,
			92.345,
			92.391,
			92.453,
			92.515,
			92.578,
			92.64,
			92.67,
			92.702,
			92.785,
			92.827,
			92.888,
			92.95,
			93.013,
			93.074,
			93.103,
			93.134,
			93.214,
			93.26,
			93.324,
			93.385,
			93.431,
			93.445,
			93.539,
			93.569,
			93.633,
			93.648,
			93.693,
			93.758,
			93.818,
			93.879,
			93.943,
			93.975,
			94.003,
			94.082,
			94.128,
			94.191,
			94.25,
			94.3,
			94.375,
			94.408,
			94.439,
			94.516,
			94.564,
			94.624,
			94.686,
			94.747,
			94.809,
			94.849,
			94.872,
			94.935,
			94.95,
			94.995,
			95.059,
			95.121,
			95.167,
			95.183,
			95.245,
			95.275,
			95.306,
			95.385,
			95.431,
			95.491,
			95.552,
			95.615,
			95.678,
			95.71,
			95.74,
			95.803,
			95.863,
			95.928,
			95.99,
			96.036,
			96.051,
			96.112,
			96.145,
			96.174,
			96.253,
			96.298,
			96.361,
			96.422,
			96.484,
			96.577,
			96.608,
			96.67,
			96.732,
			96.795,
			96.858,
			96.917,
			97.012,
			97.042,
			97.119,
			97.165,
			97.23,
			97.29,
			97.339,
			97.353,
			97.445,
			97.475,
			97.539,
			97.553,
			97.6,
			97.664,
			97.724,
			97.786,
			97.879,
			97.911,
			97.988,
			98.035,
			98.095,
			98.158,
			98.222,
			98.281,
			98.313,
			98.346,
			98.423,
			98.469,
			98.531,
			98.594,
			98.656,
			98.716,
			98.747,
			98.778,
			98.84,
			98.856,
			98.902,
			98.971,
			99.026,
			99.089,
			99.15,
			99.182,
			99.212,
			99.29,
			99.337,
			99.398,
			99.462,
			99.523,
			99.586,
			99.615,
			99.647,
			99.726,
			99.77,
			99.834,
			99.941,
			99.957,
			100.05,
			100.08,
			100.158,
			100.206,
			100.267,
			100.328,
			100.375,
			100.453,
			100.484,
			100.514,
			100.577,
			100.64,
			100.7,
			100.762,
			100.825,
			100.886,
			100.917,
			100.95,
			101.033,
			101.073,
			101.137,
			101.198,
			101.242,
			101.258,
			101.32,
			101.384,
			101.446,
			101.506,
			101.571,
			101.63,
			101.694,
			101.757,
			101.788,
			101.817,
			101.894,
			101.942,
			102.003,
			102.065,
			102.111,
			102.127,
			102.19,
			102.219,
			102.25,
			102.315,
			102.33,
			102.375,
			102.438,
			102.5,
			102.561,
			102.624,
			102.656,
			102.685,
			102.747,
			102.81,
			102.872,
			102.933,
			102.981,
			102.997,
			103.058,
			103.093,
			103.119,
			103.242,
			103.306,
			103.367,
			103.43,
			103.49,
			103.523,
			103.553,
			103.615,
			103.632,
			103.677,
			103.741,
			103.803,
			103.847,
			103.926,
			103.956,
			103.988,
			104.066,
			104.113,
			104.175,
			104.235,
			104.297,
			104.39,
			104.422,
			104.482,
			104.547,
			104.609,
			104.67,
			104.718,
			104.733,
			104.795,
			104.825,
			104.856,
			104.933,
			104.98,
			105.041,
			105.102,
			105.167,
			105.229,
			105.258,
			105.29,
			105.35,
			105.414,
			105.477,
			105.538,
			105.6,
			105.661,
			105.724,
			105.802,
			105.847,
			105.912,
			105.972,
			106.017,
			106.035,
			106.097,
			106.128,
			106.158,
			106.236,
			106.283,
			106.345,
			106.407,
			106.467,
			106.562,
			106.593,
			106.67,
			106.717,
			106.778,
			106.886,
			106.902,
			106.964,
			106.995,
			107.089,
			107.103,
			107.15,
			107.217,
			107.274,
			107.336,
			107.399,
			107.43,
			107.46,
			107.537,
			107.584,
			107.648,
			107.707,
			107.756,
			107.769,
			107.833,
			107.862,
			107.895,
			107.972,
			108.018,
			108.079,
			108.141,
			108.203,
			108.267,
			108.297,
			108.33,
			108.39,
			108.406,
			108.453,
			108.514,
			108.577,
			108.64,
			108.699,
			108.733,
			108.764,
			108.841,
			108.885,
			108.948,
			109.009,
			109.073,
			109.135,
			109.195,
			109.259,
			109.281,
			109.32,
			109.381,
			109.445,
			109.506,
			109.57,
			109.6,
			109.631,
			109.709,
			109.753,
			109.816,
			109.942,
			110.003,
			110.034,
			110.065,
			110.127,
			110.189,
			110.251,
			110.311,
			110.374,
			110.438,
			110.468,
			110.498,
			110.577,
			110.624,
			110.685,
			110.747,
			110.794,
			110.872,
			110.903,
			110.933,
			110.996,
			111.057,
			111.119,
			111.18,
			111.244,
			111.305,
			111.341,
			111.366,
			111.444,
			111.491,
			111.554,
			111.615,
			111.662,
			111.677,
			111.802,
			111.88,
			111.926,
			111.988,
			112.05,
			112.11,
			112.173,
			112.205,
			112.236,
			112.311,
			112.36,
			112.422,
			112.482,
			112.529,
			112.546,
			112.607,
			112.639,
			112.668,
			112.745,
			112.792,
			112.856,
			112.917,
			112.98,
			113.042,
			113.072,
			113.103,
			113.165,
			113.18,
			113.226,
			113.294,
			113.353,
			113.403,
			113.475,
			113.508,
			113.539,
			113.615,
			113.661,
			113.724,
			113.786,
			113.848,
			113.908,
			113.94,
			113.972,
			114.035,
			114.05,
			114.097,
			114.157,
			114.219,
			114.266,
			114.282,
			114.343,
			114.375,
			114.406,
			114.481,
			114.53,
			114.593,
			114.655,
			114.716,
			114.778,
			114.808,
			114.84,
			114.902,
			114.964,
			115.029,
			115.089,
			115.149,
			115.211,
			115.241,
			115.273,
			115.352,
			115.398,
			115.465,
			115.523,
			115.568,
			115.583,
			115.677,
			115.709,
			115.769,
			115.831,
			115.895,
			115.956,
			116.018,
			116.11,
			116.142,
			116.219,
			116.267,
			116.327,
			116.39,
			116.437,
			116.45,
			116.514,
			116.546,
			116.639,
			116.653,
			116.701,
			116.762,
			116.823,
			116.886,
			116.947,
			116.98,
			117.01,
			117.088,
			117.133,
			117.195,
			117.258,
			117.305,
			117.321,
			117.383,
			117.413,
			117.443,
			117.526,
			117.568,
			117.63,
			117.691,
			117.754,
			117.815,
			117.847,
			117.88,
			117.94,
			117.957,
			118.002,
			118.065,
			118.127,
			118.173,
			118.25,
			118.281,
			118.313,
			118.39,
			118.436,
			118.498,
			118.561,
			118.623,
			118.683,
			118.715,
			118.747,
			118.808,
			118.823,
			118.871,
			118.933,
			118.995,
			119.041,
			119.056,
			119.119,
			119.15,
			119.179,
			119.258,
			119.305,
			119.368,
			119.428,
			119.491,
			119.553,
			119.615,
			119.677,
			119.74,
			119.8,
			119.908,
			119.925,
			119.986,
			120.018,
			120.05,
			120.127,
			120.173,
			120.235,
			120.298,
			120.359,
			120.42,
			120.45,
			120.483,
			120.546,
			120.608,
			120.669,
			120.73,
			120.776,
			120.855,
			120.886,
			120.915,
			120.995,
			121.041,
			121.103,
			121.165,
			121.228,
			121.289,
			121.32,
			121.351,
			121.411,
			121.475,
			121.538,
			121.599,
			121.662,
			121.723,
			121.753,
			121.786,
			121.861,
			121.909,
			121.972,
			122.033,
			122.096,
			122.158,
			122.188,
			122.22,
			122.28,
			122.296,
			122.342,
			122.406,
			122.467,
			122.513,
			122.528,
			122.623,
			122.652,
			122.729,
			122.776,
			122.839,
			122.901,
			122.948,
			123.025,
			123.057,
			123.088,
			123.148,
			123.211,
			123.275,
			123.335,
			123.397,
			123.491,
			123.522,
			123.599,
			123.645,
			123.711,
			123.769,
			123.83,
			123.894,
			123.957,
			124.016,
			124.052,
			124.086,
			124.143,
			124.157,
			124.203,
			124.266,
			124.326,
			124.39,
			124.451,
			124.481,
			124.514,
			124.591,
			124.638,
			124.703,
			124.762,
			124.824,
			124.886,
			124.918,
			124.949,
			125.009,
			125.025,
			125.071,
			125.133,
			125.196,
			125.258,
			125.32,
			125.35,
			125.381,
			125.458,
			125.507,
			125.567,
			125.629,
			125.675,
			125.753,
			125.815,
			125.877,
			125.939,
			126.003,
			126.064,
			126.126,
			126.188,
			126.219,
			126.251,
			126.326,
			126.373,
			126.436,
			126.498,
			126.545,
			126.621,
			126.653,
			126.683,
			126.744,
			126.808,
			126.869,
			126.933,
			126.992,
			127.056,
			127.086,
			127.117,
			127.179,
			127.197,
			127.242,
			127.304,
			127.367,
			127.414,
			127.429,
			127.49,
			127.522,
			127.553,
			127.629,
			127.677,
			127.739,
			127.8,
			127.863,
			127.924,
			127.955,
			127.986,
			128.048,
			128.11,
			128.173,
			128.233,
			128.281,
			128.297,
			128.358,
			128.42,
			128.499,
			128.544,
			128.611,
			128.669,
			128.731,
			128.792,
			128.824,
			128.853,
			128.916,
			128.93,
			128.979,
			129.041,
			129.102,
			129.148,
			129.226,
			129.258,
			129.289,
			129.364,
			129.413,
			129.475,
			129.535,
			129.583,
			129.661,
			129.723,
			129.8,
			129.845,
			129.913,
			129.971,
			130.032,
			130.095,
			130.157,
			130.235,
			130.279,
			130.343,
			130.403,
			130.451,
			130.529,
			130.59,
			130.653,
			130.714,
			130.779,
			130.838,
			130.902,
			130.964,
			130.995,
			131.024,
			131.101,
			131.149,
			131.212,
			131.272,
			131.32,
			131.397,
			131.428,
			131.459,
			131.52,
			131.582,
			131.645,
			131.706,
			131.768,
			131.83,
			131.863,
			131.893,
			131.953,
			131.977,
			132.018,
			132.078,
			132.142,
			132.187,
			132.202,
			132.265,
			132.297,
			132.328,
			132.405,
			132.45,
			132.513,
			132.574,
			132.638,
			132.697,
			132.729,
			132.761,
			132.824,
			132.885,
			132.949,
			133.009,
			133.056,
			133.072,
			133.131,
			133.164,
			133.196,
			133.274,
			133.32,
			133.382,
			133.442,
			133.506,
			133.565,
			133.597,
			133.63,
			133.706,
			133.754,
			133.815,
			133.877,
			133.924,
			134.035,
			134.064,
			134.142,
			134.188,
			134.249,
			134.31,
			134.374,
			134.436,
			134.467,
			134.498,
			134.559,
			134.621,
			134.684,
			134.746,
			134.791,
			134.808,
			134.869,
			134.898,
			134.93,
			135.007,
			135.056,
			135.118,
			135.241,
			135.303,
			135.332,
			135.365,
			135.443,
			135.49,
			135.552,
			135.614,
			135.66,
			135.676,
			135.739,
			135.769,
			135.8,
			135.877,
			135.924,
			135.987,
			136.048,
			136.109,
			136.172,
			136.203,
			136.233,
			136.295,
			136.359,
			136.424,
			136.481,
			136.531,
			136.606,
			136.636,
			136.668,
			136.745,
			136.791,
			136.855,
			136.916,
			136.978,
			137.04,
			137.103,
			137.162,
			137.179,
			137.226,
			137.288,
			137.35,
			137.413,
			137.475,
			137.536,
			137.614,
			137.659,
			137.722,
			137.783,
			137.829,
			137.844,
			137.94,
			137.969,
			138.032,
			138.046,
			138.093,
			138.159,
			138.217,
			138.281,
			138.342,
			138.373,
			138.403,
			138.482,
			138.527,
			138.59,
			138.65,
			138.699,
			138.775,
			138.808,
			138.836,
			138.914,
			138.962,
			139.025,
			139.086,
			139.149,
			139.208,
			139.242,
			139.273,
			139.351,
			139.395,
			139.458,
			139.52,
			139.567,
			139.583,
			139.645,
			139.676,
			139.707,
			139.783,
			139.829,
			139.891,
			139.953,
			140.017,
			140.08,
			140.108,
			140.142,
			140.223,
			140.264,
			140.327,
			140.389,
			140.451,
			140.512,
			140.543,
			140.575,
			140.65,
			140.759,
			140.823,
			140.884,
			140.945,
			140.978,
			141.009,
			141.07,
			141.132,
			141.196,
			141.256,
			141.304,
			141.318,
			141.38,
			141.411,
			141.443,
			141.52,
			141.567,
			141.629,
			141.692,
			141.753,
			141.814,
			141.845,
			141.876,
			141.939,
			141.953,
			141.999,
			142.063,
			142.125,
			142.172,
			142.25,
			142.286,
			142.31,
			142.389,
			142.434,
			142.497,
			142.558,
			142.621,
			142.683,
			142.712,
			142.747,
			142.807,
			142.823,
			142.868,
			142.932,
			143.039,
			143.055,
			143.115,
			143.149,
			143.179,
			143.254,
			143.302,
			143.364,
			143.426,
			143.49,
			143.55,
			143.581,
			143.614,
			143.675,
			143.736,
			143.8,
			143.861,
			143.923,
			143.985,
			144.014,
			144.045,
			144.123,
			144.171,
			144.233,
			144.294,
			144.347,
			144.42,
			144.449,
			144.482,
			144.558,
			144.606,
			144.667,
			144.73,
			144.79,
			144.854,
			144.883,
			144.916,
			144.992,
			145.039,
			145.102,
			145.224,
			145.288,
			145.318,
			145.348,
			145.425,
			145.472,
			145.536,
			145.598,
			145.658,
			145.721,
			145.752,
			145.783,
			145.859,
			145.908,
			145.97,
			146.03,
			146.079,
			146.156,
			146.187,
			146.216,
			146.28,
			146.295,
			146.342,
			146.409,
			146.464,
			146.527,
			146.589,
			146.621,
			146.652,
			146.731,
			146.774,
			146.839,
			146.899,
			146.946,
			147.024,
			147.056,
			147.086,
			147.148,
			147.209,
			147.273,
			147.335,
			147.396,
			147.458,
			147.489,
			147.521,
			147.597,
			147.645,
			147.706,
			147.767,
			147.815,
			147.831,
			147.891,
			147.923,
			147.953,
			148.03,
			148.077,
			148.14,
			148.2,
			148.264,
			148.326,
			148.356,
			148.388,
			148.45,
			148.471,
			148.511,
			148.575,
			148.636,
			148.683,
			148.698,
			148.758,
			148.791,
			148.822,
			148.9,
			149.007,
			149.07,
			149.132,
			149.193,
			149.223,
			149.256,
			149.316,
			149.333,
			149.382,
			149.441,
			149.502,
			149.548,
			149.629,
			149.66,
			149.691,
			149.766,
			149.815,
			149.875,
			149.939,
			150,
			150.061,
			150.093,
			150.125,
			150.185,
			150.203,
			150.248,
			150.314,
			150.373,
			150.418,
			150.433,
			150.496,
			150.533,
			150.557,
			150.637,
			150.683,
			150.743,
			150.806,
			150.852,
			150.929,
			150.962,
			150.993,
			151.054,
			151.069,
			151.117,
			151.178,
			151.24,
			151.303,
			151.364,
			151.427,
			151.504,
			151.55,
			151.612,
			151.673,
			151.72,
			151.799,
			151.859,
			151.923,
			151.983,
			152.047,
			152.109,
			152.17,
			152.233,
			152.295,
			152.372,
			152.419,
			152.483,
			152.542,
			152.595,
			152.698,
			152.73,
			152.806,
			152.853,
			152.915,
			152.977,
			153.04,
			153.13,
			153.162,
			153.226,
			153.286,
			153.35,
			153.409,
			153.458,
			153.472,
			153.533,
			153.564,
			153.597,
			153.675,
			153.72,
			153.782,
			153.848,
			153.906,
			153.969,
			154,
			154.03,
			154.091,
			154.108,
			154.155,
			154.217,
			154.281,
			154.325,
			154.402,
			154.433,
			154.464,
			154.543,
			154.589,
			154.653,
			154.712,
			154.773,
			154.836,
			154.898,
			154.96,
			154.977,
			155.023,
			155.085,
			155.145,
			155.207,
			155.301,
			155.332,
			155.411,
			155.458,
			155.517,
			155.582,
			155.628,
			155.706,
			155.735,
			155.767,
			155.827,
			155.844,
			155.892,
			155.953,
			156.014,
			156.078,
			156.14,
			156.17,
			156.199,
			156.278,
			156.325,
			156.389,
			156.448,
			156.496,
			156.572,
			156.602,
			156.636,
			156.697,
			156.76,
			156.821,
			156.884,
			156.945,
			157.007,
			157.038,
			157.069,
			157.145,
			157.194,
			157.256,
			157.317,
			157.364,
			157.381,
			157.473,
			157.503,
			157.564,
			157.581,
			157.626,
			157.691,
			157.751,
			157.812,
			157.876,
			157.907,
			157.939,
			158.015,
			158.063,
			158.124,
			158.186,
			158.232,
			158.248,
			158.309,
			158.34,
			158.372,
			158.432,
			158.448,
			158.495,
			158.557,
			158.62,
			158.682,
			158.743,
			158.778,
			158.807,
			158.866,
			158.93,
			158.993,
			159.099,
			159.208,
			159.24,
			159.318,
			159.425,
			159.488,
			159.55,
			159.611,
			159.643,
			159.673,
			159.737,
			159.75,
			159.798,
			159.861,
			159.923,
			159.967,
			159.983,
			160.045,
			160.075,
			160.107,
			160.184,
			160.232,
			160.295,
			160.356,
			160.418,
			160.481,
			160.511,
			160.542,
			160.62,
			160.665,
			160.728,
			160.79,
			160.852,
			160.914,
			160.944,
			160.977,
			161.053,
			161.101,
			161.163,
			161.225,
			161.271,
			161.348,
			161.378,
			161.409,
			161.472,
			161.534,
			161.596,
			161.659,
			161.721,
			161.781,
			161.812,
			161.842,
			161.922,
			161.967,
			162.029,
			162.091,
			162.14,
			162.154,
			162.246,
			162.28,
			162.343,
			162.402,
			162.464,
			162.525,
			162.589,
			162.651,
			162.684,
			162.714,
			162.79,
			162.835,
			162.902,
			162.961,
			163.006,
			163.022,
			163.084,
			163.115,
			163.145,
			163.224,
			163.27,
			163.333,
			163.394,
			163.456,
			163.518,
			163.55,
			163.58,
			163.656,
			163.705,
			163.765,
			163.828,
			163.875,
			163.953,
			163.982,
			164.014,
			164.091,
			164.138,
			164.2,
			164.262,
			164.324,
			164.388,
			164.418,
			164.449,
			164.525,
			164.572,
			164.635,
			164.697,
			164.742,
			164.758,
			164.819,
			164.851,
			164.882,
			164.967,
			165.007,
			165.069,
			165.132,
			165.193,
			165.256,
			165.284,
			165.318,
			165.378,
			165.393,
			165.44,
			165.503,
			165.564,
			165.61,
			165.626,
			165.688,
			165.72,
			165.749,
			165.83,
			165.876,
			165.936,
			165.998,
			166.061,
			166.123,
			166.154,
			166.183,
			166.245,
			166.309,
			166.371,
			166.433,
			166.479,
			166.495,
			166.557,
			166.586,
			166.618,
			166.696,
			166.742,
			166.803,
			166.867,
			166.929,
			166.99,
			167.054,
			167.113,
			167.145,
			167.178,
			167.24,
			167.256,
			167.301,
			167.363,
			167.425,
			167.472,
			167.55,
			167.581,
			167.611,
			167.689,
			167.736,
			167.799,
			167.858,
			167.922,
			167.983,
			168.015,
			168.044,
			168.108,
			168.123,
			168.17,
			168.231,
			168.292,
			168.34,
			168.356,
			168.415,
			168.447,
			168.48,
			168.557,
			168.603,
			168.67,
			168.727,
			168.79,
			168.851,
			168.882,
			168.914,
			168.975,
			169.036,
			169.103,
			169.16,
			169.207,
			169.222,
			169.285,
			169.348,
			169.425,
			169.472,
			169.535,
			169.595,
			169.658,
			169.718,
			169.751,
			169.781,
			169.844,
			169.906,
			169.969,
			170.028,
			170.09,
			170.155,
			170.185,
			170.216,
			170.294,
			170.339,
			170.402,
			170.509,
			170.526,
			170.587,
			170.619,
			170.65,
			170.716,
			170.774,
			170.837,
			170.898,
			170.96,
			171.029,
			171.052,
			171.083,
			171.168,
			171.208,
			171.27,
			171.333,
			171.393,
			171.457,
			171.487,
			171.518,
			171.579,
			171.595,
			171.641,
			171.703,
			171.765,
			171.811,
			171.828,
			171.89,
			171.922,
			171.953,
			172.028,
			172.075,
			172.139,
			172.198,
			172.264,
			172.325,
			172.353,
			172.385,
			172.446,
			172.51,
			172.573,
			172.632,
			172.679,
			172.694,
			172.758,
			172.789,
			172.819,
			172.898,
			172.945,
			173.011,
			173.069,
			173.129,
			173.193,
			173.253,
			173.315,
			173.332,
			173.378,
			173.441,
			173.503,
			173.564,
			173.626,
			173.658,
			173.688,
			173.766,
			173.811,
			173.875,
			173.983,
			173.999,
			174.059,
			174.122,
			174.183,
			174.2,
			174.245,
			174.308,
			174.371,
			174.432,
			174.495,
			174.524,
			174.556,
			174.634,
			174.681,
			174.747,
			174.803,
			174.851,
			174.931,
			174.958,
			174.991,
			175.068,
			175.115,
			175.178,
			175.239,
			175.3,
			175.362,
			175.393,
			175.424,
			175.486,
			175.5,
			175.548,
			175.61,
			175.673,
			175.72,
			175.795,
			175.828,
			175.858,
			175.935,
			175.984,
			176.046,
			176.107,
			176.17,
			176.231,
			176.261,
			176.292,
			176.356,
			176.37,
			176.417,
			176.48,
			176.54,
			176.588,
			176.602,
			176.665,
			176.697,
			176.726,
			176.804,
			176.85,
			176.916,
			176.975,
			177.038,
			177.1,
			177.129,
			177.161,
			177.222,
			177.285,
			177.35,
			177.408,
			177.472,
			177.532,
			177.564,
			177.595,
			177.673,
			177.718,
			177.782,
			177.844,
			177.891,
			177.906,
			177.966,
			177.998,
			178.03,
			178.107,
			178.153,
			178.214,
			178.276,
			178.34,
			178.4,
			178.432,
			178.462,
			178.541,
			178.588,
			178.653,
			178.71,
			178.758,
			178.774,
			178.835,
			178.897,
			178.958,
			179.022,
			179.083,
			179.147,
			179.207,
			179.268,
			179.299,
			179.331,
			179.392,
			179.414,
			179.456,
			179.517,
			179.58,
			179.624,
			179.702,
			179.735,
			179.765,
			179.842,
			179.889,
			179.953,
			180.013,
			180.074,
			180.137,
			180.168,
			180.199,
			180.262,
			180.277,
			180.324,
			180.384,
			180.447,
			180.494,
			180.572,
			180.603,
			180.634,
			180.71,
			180.757,
			180.823,
			180.882,
			180.942,
			181.006,
			181.036,
			181.067,
			181.13,
			181.146,
			181.191,
			181.258,
			181.314,
			181.36,
			181.377,
			181.439,
			181.474,
			181.5,
			181.578,
			181.624,
			181.686,
			181.751,
			181.811,
			181.904,
			181.937,
			181.998,
			182.058,
			182.123,
			182.183,
			182.247,
			182.307,
			182.34,
			182.37,
			182.448,
			182.494,
			182.556,
			182.618,
			182.663,
			182.679,
			182.741,
			182.773,
			182.803,
			182.867,
			182.927,
			182.99,
			183.128,
			183.192,
			183.206,
			183.253,
			183.315,
			183.377,
			183.44,
			183.501,
			183.537,
			183.563,
			183.641,
			183.689,
			183.748,
			183.81,
			183.874,
			183.937,
			183.998,
			184.058,
			184.075,
			184.121,
			184.182,
			184.245,
			184.308,
			184.369,
			184.402,
			184.43,
			184.508,
			184.554,
			184.617,
			184.68,
			184.725,
			184.803,
			184.833,
			184.864,
			184.927,
			184.99,
			185.057,
			185.114,
			185.174,
			185.236,
			185.27,
			185.298,
			185.377,
			185.424,
			185.487,
			185.547,
			185.598,
			185.672,
			185.733,
			185.811,
			185.858,
			185.92,
			185.983,
			186.043,
			186.107,
			186.137,
			186.167,
			186.244,
			186.291,
			186.353,
			186.415,
			186.463,
			186.477,
			186.54,
			186.571,
			186.601,
			186.664,
			186.678,
			186.725,
			186.789,
			186.85,
			186.911,
			186.973,
			187.006,
			187.035,
			187.098,
			187.161,
			187.223,
			187.282,
			187.331,
			187.347,
			187.407,
			187.469,
			187.548,
			187.594,
			187.656,
			187.716,
			187.779,
			187.843,
			187.874,
			187.902,
			187.966,
			188.03,
			188.093,
			188.153,
			188.2,
			188.277,
			188.306,
			188.336,
			188.415,
			188.462,
			188.525,
			188.586,
			188.647,
			188.711,
			188.742,
			188.773,
			188.835,
			188.897,
			188.958,
			189.019,
			189.068,
			189.083,
			189.145,
			189.174,
			189.207,
			189.283,
			189.33,
			189.394,
			189.454,
			189.517,
			189.579,
			189.609,
			189.641,
			189.703,
			189.722,
			189.765,
			189.827,
			189.889,
			189.935,
			189.951,
			190.014,
			190.043,
			190.076,
			190.153,
			190.2,
			190.262,
			190.324,
			190.384,
			190.448,
			190.481,
			190.508,
			190.57,
			190.632,
			190.695,
			190.756,
			190.803,
			190.82,
			190.882,
			190.911,
			190.943,
			191.019,
			191.067,
			191.129,
			191.189,
			191.236,
			191.252,
			191.314,
			191.345,
			191.378,
			191.439,
			191.455,
			191.501,
			191.564,
			191.626,
			191.688,
			191.75,
			191.786,
			191.812,
			191.886,
			191.935,
			191.998,
			192.06,
			192.103,
			192.12,
			192.214,
			192.246,
			192.323,
			192.37,
			192.43,
			192.494,
			192.553,
			192.617,
			192.648,
			192.678,
			192.757,
			192.802,
			192.867,
			192.972,
			193.082,
			193.114,
			193.175,
			193.236,
			193.299,
			193.361,
			193.423,
			193.485,
			193.517,
			193.548,
			193.625,
			193.671,
			193.733,
			193.796,
			193.845,
			193.919,
			193.982,
			194.044,
			194.103,
			194.167,
			194.23,
			194.292,
			194.353,
			194.385,
			194.415,
			194.492,
			194.54,
			194.601,
			194.664,
			194.725,
			194.786,
			194.817,
			194.848,
			194.927,
			194.973,
			195.036,
			195.098,
			195.159,
			195.252,
			195.283,
			195.346,
			195.407,
			195.468,
			195.531,
			195.578,
			195.656,
			195.717,
			195.796,
			195.841,
			195.909,
			195.965,
			196.028,
			196.089,
			196.12,
			196.15,
			196.213,
			196.231,
			196.277,
			196.339,
			196.399,
			196.448,
			196.524,
			196.586,
			196.662,
			196.711,
			196.834,
			196.896,
			196.957,
			196.988,
			197.019,
			197.082,
			197.097,
			197.143,
			197.206,
			197.268,
			197.329,
			197.393,
			197.453,
			197.53,
			197.577,
			197.64,
			197.703,
			197.764,
			197.825,
			197.858,
			197.889,
			197.948,
			197.974,
			198.013,
			198.075,
			198.137,
			198.203,
			198.259,
			198.307,
			198.322,
			198.383,
			198.414,
			198.445,
			198.523,
			198.571,
			198.633,
			198.695,
			198.757,
			198.818,
			198.848,
			198.879,
			198.942,
			198.959,
			199.003,
			199.067,
			199.173,
			199.25,
			199.284,
			199.315,
			199.391,
			199.438,
			199.501,
			199.561,
			199.623,
			199.686,
			199.715,
			199.748,
			199.809,
			199.873,
			199.935,
			199.997,
			200.059,
			200.119,
			200.151,
			200.183,
			200.258,
			200.305,
			200.367,
			200.431,
			200.492,
			200.586,
			200.617,
			200.678,
			200.694,
			200.74,
			200.803,
			200.911,
			200.927,
			200.989,
			201.019,
			201.048,
			201.129,
			201.175,
			201.236,
			201.297,
			201.361,
			201.423,
			201.453,
			201.483,
			201.548,
			201.608,
			201.672,
			201.78,
			201.796,
			201.857,
			201.887,
			201.918,
			201.996,
			202.042,
			202.108,
			202.168,
			202.212,
			202.29,
			202.323,
			202.353,
			202.414,
			202.429,
			202.477,
			202.537,
			202.601,
			202.663,
			202.726,
			202.757,
			202.786,
			202.864,
			202.911,
			202.977,
			203.034,
			203.082,
			203.097,
			203.191,
			203.22,
			203.281,
			203.299,
			203.345,
			203.408,
			203.468,
			203.532,
			203.593,
			203.625,
			203.655,
			203.732,
			203.78,
			203.842,
			203.903,
			203.95,
			204.057,
			204.089,
			204.149,
			204.169,
			204.211,
			204.275,
			204.337,
			204.4,
			204.462,
			204.493,
			204.523,
			204.6,
			204.648,
			204.709,
			204.771,
			204.818,
			204.897,
			204.927,
			204.957,
			205.018,
			205.081,
			205.144,
			205.206,
			205.267,
			205.329,
			205.362,
			205.392,
			205.47,
			205.516,
			205.579,
			205.64,
			205.685,
			205.701,
			205.765,
			205.824,
			205.903,
			205.949,
			206.016,
			206.073,
			206.133,
			206.198,
			206.234,
			206.258,
			206.338,
			206.383,
			206.446,
			206.506,
			206.57,
			206.632,
			206.662,
			206.694,
			206.772,
			206.817,
			206.88,
			206.942,
			207.003,
			207.064,
			207.095,
			207.128,
			207.19,
			207.25,
			207.315,
			207.375,
			207.423,
			207.498,
			207.531,
			207.561,
			207.64,
			207.747,
			207.808,
			207.873,
			207.933,
			207.998,
			208.058,
			208.073,
			208.12,
			208.182,
			208.244,
			208.293,
			208.306,
			208.368,
			208.398,
			208.429,
			208.506,
			208.554,
			208.614,
			208.678,
			208.74,
			208.803,
			208.833,
			208.864,
			208.926,
			208.989,
			209.051,
			209.113,
			209.159,
			209.173,
			209.235,
			209.299,
			209.376,
			209.423,
			209.485,
			209.547,
			209.607,
			209.669,
			209.7,
			209.732,
			209.793,
			209.857,
			209.918,
			209.98,
			210.041,
			210.104,
			210.135,
			210.164,
			210.242,
			210.29,
			210.358,
			210.414,
			210.459,
			210.476,
			210.6,
			210.661,
			210.679,
			210.724,
			210.786,
			210.847,
			210.909,
			210.972,
			211.003,
			211.035,
			211.111,
			211.158,
			211.221,
			211.283,
			211.327,
			211.344,
			211.436,
			211.468,
			211.531,
			211.547,
			211.593,
			211.653,
			211.717,
			211.781,
			211.839,
			211.871,
			211.903,
			211.978,
			212.027,
			212.09,
			212.151,
			212.198,
			212.273,
			212.305,
			212.336,
			212.418,
			212.46,
			212.524,
			212.584,
			212.647,
			212.708,
			212.74,
			212.77,
			212.833,
			212.848,
			212.895,
			212.957,
			213.018,
			213.064,
			213.081,
			213.144,
			213.174,
			213.205,
			213.283,
			213.327,
			213.39,
			213.453,
			213.514,
			213.578,
			213.609,
			213.64,
			213.7,
			213.762,
			213.824,
			213.886,
			213.933,
			213.949,
			214.012,
			214.072,
			214.148,
			214.197,
			214.258,
			214.321,
			214.383,
			214.444,
			214.507,
			214.585,
			214.63,
			214.693,
			214.756,
			214.8,
			214.881,
			214.909,
			214.941,
			215.018,
			215.065,
			215.126,
			215.19,
			215.25,
			215.312,
			215.342,
			215.375,
			215.438,
			215.454,
			215.5,
			215.561,
			215.622,
			215.669,
			215.747,
			215.783,
			215.81,
			215.886,
			215.933,
			215.996,
			216.058,
			216.119,
			216.181,
			216.211,
			216.242,
			216.322,
			216.366,
			216.431,
			216.49,
			216.553,
			216.616,
			216.646,
			216.678,
			216.753,
			216.801,
			216.864,
			216.924,
			216.972,
			217.079,
			217.112,
			217.174,
			217.235,
			217.301,
			217.36,
			217.421,
			217.483,
			217.515,
			217.545,
			217.624,
			217.67,
			217.732,
			217.793,
			217.84,
			217.857,
			217.915,
			217.978,
			218.042,
			218.057,
			218.103,
			218.164,
			218.228,
			218.29,
			218.353,
			218.382,
			218.412,
			218.49,
			218.538,
			218.603,
			218.66,
			218.707,
			218.724,
			218.815,
			218.847,
			218.91,
			218.926,
			218.97,
			219.035,
			219.096,
			219.157,
			219.221,
			219.283,
			219.342,
			219.359,
			219.406,
			219.467,
			219.531,
			219.575,
			219.653,
			219.683,
			219.716,
			219.792,
			219.841,
			219.903,
			219.964,
			220.026,
			220.119,
			220.151,
			220.212,
			220.227,
			220.274,
			220.336,
			220.398,
			220.459,
			220.522,
			220.553,
			220.584,
			220.665,
			220.707,
			220.769,
			220.832,
			220.894,
			220.987,
			221.018,
			221.08,
			221.097,
			221.142,
			221.203,
			221.266,
			221.329,
			221.422,
			221.452,
			221.532,
			221.575,
			221.64,
			221.701,
			221.764,
			221.824,
			221.853,
			221.886,
			221.948,
			222.009,
			222.074,
			222.135,
			222.198,
			222.258,
			222.29,
			222.321,
			222.396,
			222.443,
			222.506,
			222.567,
			222.631,
			222.691,
			222.753,
			222.832,
			222.879,
			222.94,
			223.003,
			223.064,
			223.158,
			223.186,
			223.264,
			223.312,
			223.377,
			223.436,
			223.499,
			223.561,
			223.592,
			223.622,
			223.685,
			223.745,
			223.809,
			223.87,
			223.932,
			223.993,
			224.025,
			224.056,
			224.134,
			224.18,
			224.242,
			224.303,
			224.35,
			224.427,
			224.49,
			224.553,
			224.616,
			224.678,
			224.74,
			224.8,
			224.862,
			224.892,
			224.924,
			225.003,
			225.049,
			225.114,
			225.173,
			225.219,
			225.298,
			225.326,
			225.358,
			225.42,
			225.483,
			225.544,
			225.607,
			225.669,
			225.732,
			225.793,
			225.854,
			225.871,
			225.917,
			225.979,
			226.04,
			226.086,
			226.103,
			226.165,
			226.197,
			226.226,
			226.351,
			226.413,
			226.474,
			226.538,
			226.628,
			226.659,
			226.739,
			226.783,
			226.854,
			226.907,
			226.956,
			226.972,
			227.031,
			227.064,
			227.094,
			227.173,
			227.22,
			227.28,
			227.343,
			227.411,
			227.467,
			227.532,
			227.592,
			227.653,
			227.714,
			227.776,
			227.824,
			227.932,
			227.963,
			228.04,
			228.085,
			228.148,
			228.212,
			228.273,
			228.336,
			228.367,
			228.398,
			228.46,
			228.473,
			228.522,
			228.584,
			228.645,
			228.691,
			228.708,
			228.77,
			228.801,
			228.831,
			228.914,
			228.956,
			229.016,
			229.078,
			229.14,
			229.234,
			229.264,
			229.343,
			229.391,
			229.451,
			229.514,
			229.575,
			229.636,
			229.668,
			229.7,
			229.776,
			229.823,
			229.885,
			229.948,
			230.008,
			230.103,
			230.133,
			230.198,
			230.258,
			230.32,
			230.383,
			230.427,
			230.507,
			230.537,
			230.568,
			230.645,
			230.691,
			230.753,
			230.863,
			230.94,
			231,
			231.079,
			231.125,
			231.188,
			231.25,
			231.311,
			231.375,
			231.403,
			231.437,
			231.514,
			231.558,
			231.623,
			231.684,
			231.746,
			231.808,
			231.84,
			231.869,
			231.93,
			231.947,
			231.993,
			232.059,
			232.12,
			232.179,
			232.242,
			232.274,
			232.305,
			232.38,
			232.429,
			232.49,
			232.552,
			232.597,
			232.677,
			232.738,
			232.801,
			232.864,
			232.925,
			232.987,
			233.047,
			233.111,
			233.141,
			233.173,
			233.248,
			233.297,
			233.358,
			233.419,
			233.465,
			233.483,
			233.546,
			233.574,
			233.606,
			233.669,
			233.73,
			233.798,
			233.853,
			233.916,
			233.979,
			234.01,
			234.04,
			234.117,
			234.165,
			234.227,
			234.335,
			234.35,
			234.411,
			234.444,
			234.473,
			234.551,
			234.598,
			234.661,
			234.723,
			234.783,
			234.847,
			234.878,
			234.908,
			234.985,
			235.032,
			235.1,
			235.157,
			235.203,
			235.218,
			235.28,
			235.311,
			235.341,
			235.403,
			235.419,
			235.467,
			235.53,
			235.591,
			235.653,
			235.715,
			235.748,
			235.785,
			235.839,
			235.853,
			235.9,
			235.962,
			236.026,
			236.072,
			236.148,
			236.181,
			236.212,
			236.289,
			236.335,
			236.398,
			236.46,
			236.522,
			236.582,
			236.646,
			236.708,
			236.769,
			236.832,
			236.892,
			236.94,
			236.956,
			237.017,
			237.047,
			237.079,
			237.161,
			237.203,
			237.265,
			237.327,
			237.39,
			237.451,
			237.48,
			237.514,
			237.574,
			237.638,
			237.7,
			237.76,
			237.808,
			237.823,
			237.883,
			237.914,
			237.947,
			238.023,
			238.072,
			238.195,
			238.258,
			238.32,
			238.348,
			238.379,
			238.442,
			238.458,
			238.506,
			238.567,
			238.631,
			238.692,
			238.753,
			238.783,
			238.815,
			238.893,
			238.94,
			239.001,
			239.062,
			239.126,
			239.188,
			239.25,
			239.311,
			239.327,
			239.374,
			239.435,
			239.497,
			239.559,
			239.619,
			239.653,
			239.683,
			239.761,
			239.807,
			239.87,
			239.93,
			239.978,
			240.053,
			240.086,
			240.115,
			240.178,
			240.241,
			240.304,
			240.365,
			240.427,
			240.489,
			240.52,
			240.551,
			240.628,
			240.675,
			240.739,
			240.799,
			240.845,
			240.924,
			240.956,
			241.048,
			241.109,
			241.173,
			241.232,
			241.296,
			241.358,
			241.389,
			241.42,
			241.498,
			241.544,
			241.606,
			241.668,
			241.714,
			241.729,
			241.793,
			241.823,
			241.854,
			241.917,
			241.93,
			241.976,
			242.044,
			242.102,
			242.164,
			242.257,
			242.287,
			242.348,
			242.413,
			242.473,
			242.537,
			242.581,
			242.597,
			242.658,
			242.689,
			242.722,
			242.8,
			242.846,
			242.908,
			242.97,
			243.032,
			243.094,
			243.125,
			243.157,
			243.231,
			243.279,
			243.348,
			243.402,
			243.45,
			243.531,
			243.558,
			243.591,
			243.666,
			243.713,
			243.776,
			243.837,
			243.9,
			243.993,
			244.025,
			244.086,
			244.103,
			244.147,
			244.212,
			244.273,
			244.32,
			244.397,
			244.426,
			244.459,
			244.535,
			244.582,
			244.645,
			244.706,
			244.767,
			244.829,
			244.891,
			244.969,
			245.014,
			245.079,
			245.14,
			245.202,
			245.263,
			245.297,
			245.326,
			245.407,
			245.449,
			245.512,
			245.573,
			245.622,
			245.697,
			245.728,
			245.759,
			245.823,
			245.883,
			245.948,
			246.009,
			246.071,
			246.132,
			246.162,
			246.196,
			246.272,
			246.317,
			246.381,
			246.443,
			246.489,
			246.505,
			246.565,
			246.628,
			246.706,
			246.754,
			246.814,
			246.876,
			246.937,
			246.999,
			247.032,
			247.063,
			247.141,
			247.187,
			247.248,
			247.311,
			247.357,
			247.372,
			247.435,
			247.472,
			247.496,
			247.558,
			247.573,
			247.622,
			247.682,
			247.744,
			247.808,
			247.87,
			247.899,
			247.93,
			248.007,
			248.056,
			248.118,
			248.178,
			248.224,
			248.303,
			248.333,
			248.426,
			248.49,
			248.55,
			248.611,
			248.676,
			248.737,
			248.767,
			248.8,
			248.86,
			248.877,
			248.923,
			248.986,
			249.047,
			249.092,
			249.109,
			249.17,
			249.2,
			249.232,
			249.356,
			249.42,
			249.481,
			249.543,
			249.606,
			249.635,
			249.665,
			249.728,
			249.744,
			249.789,
			249.853,
			249.916,
			249.961,
			249.975,
			250.04,
			250.069,
			250.099,
			250.179,
			250.225,
			250.348,
			250.411,
			250.474,
			250.503,
			250.536,
			250.598,
			250.66,
			250.722,
			250.783,
			250.83,
			250.845,
			250.908,
			250.939,
			250.969,
			251.048,
			251.092,
			251.156,
			251.217,
			251.279,
			251.342,
			251.373,
			251.404,
			251.465,
			251.481,
			251.528,
			251.594,
			251.649,
			251.698,
			251.776,
			251.806,
			251.838,
			251.914,
			251.961,
			252.025,
			252.084,
			252.147,
			252.21,
			252.272,
			252.334,
			252.348,
			252.396,
			252.457,
			252.52,
			252.566,
			252.581,
			252.673,
			252.706,
			252.784,
			252.829,
			252.891,
			252.953,
			253.015,
			253.077,
			253.107,
			253.14,
			253.202,
			253.218,
			253.265,
			253.325,
			253.434,
			253.451,
			253.543,
			253.573,
			253.656,
			253.699,
			253.761,
			253.823,
			253.868,
			253.944,
			253.976,
			254.009,
			254.068,
			254.133,
			254.195,
			254.256,
			254.318,
			254.412,
			254.442,
			254.518,
			254.564,
			254.627,
			254.691,
			254.735,
			254.815,
			254.845,
			254.875,
			254.938,
			254.952,
			255,
			255.062,
			255.123,
			255.186,
			255.248,
			255.278,
			255.311,
			255.388,
			255.432,
			255.496,
			255.558,
			255.603,
			255.619,
			255.683,
			255.717,
			255.745,
			255.807,
			255.866,
			255.929,
			255.993,
			256.054,
			256.148,
			256.179,
			256.241,
			256.257,
			256.301,
			256.366,
			256.426,
			256.489,
			256.55,
			256.581,
			256.614,
			256.69,
			256.798,
			256.859,
			256.922,
			257.014,
			257.047,
			257.108,
			257.123,
			257.171,
			257.233,
			257.294,
			257.356,
			257.416,
			257.449,
			257.482,
			257.558,
			257.603,
			257.727,
			257.79,
			257.853,
			257.883,
			257.915,
			257.975,
			258.039,
			258.1,
			258.209,
			258.225,
			258.287,
			258.347,
			258.426,
			258.472,
			258.596,
			258.658,
			258.721,
			258.75,
			258.783,
			258.861,
			258.906,
			258.973,
			259.031,
			259.091,
			259.153,
			259.186,
			259.215,
			259.296,
			259.341,
			259.403,
			259.464,
			259.527,
			259.59,
			259.649,
			259.711,
			259.776,
			259.84,
			259.898,
			259.962,
			260.024,
			260.055,
			260.085,
			260.163,
			260.21,
			260.272,
			260.333,
			260.38,
			260.397,
			260.457,
			260.517,
			260.58,
			260.597,
			260.643,
			260.706,
			260.765,
			260.829,
			260.922,
			260.953,
			261.029,
			261.076,
			261.139,
			261.199,
			261.262,
			261.386,
			261.448,
			261.513,
			261.573,
			261.636,
			261.697,
			261.758,
			261.822,
			261.903,
			261.945,
			262.008,
			262.069,
			262.13,
			262.193,
			262.224,
			262.317,
			262.333,
			262.379,
			262.442,
			262.503,
			262.566,
			262.627,
			262.658,
			262.689,
			262.767,
			262.814,
			262.876,
			262.938,
			262.985,
			262.999,
			263.093,
			263.186,
			263.201,
			263.248,
			263.309,
			263.37,
			263.433,
			263.496,
			263.526,
			263.558,
			263.62,
			263.681,
			263.743,
			263.853,
			263.93,
			263.965,
			263.991,
			264.069,
			264.117,
			264.178,
			264.24,
			264.3,
			264.362,
			264.396,
			264.426,
			264.487,
			264.503,
			264.548,
			264.612,
			264.721,
			264.798,
			264.859,
			264.937,
			264.983,
			265.108,
			265.169,
			265.232,
			265.262,
			265.294,
			265.356,
			265.372,
			265.417,
			265.482,
			265.542,
			265.59,
			265.603,
			265.665,
			265.698,
			265.726,
			265.806,
			265.852,
			265.914,
			265.974,
			266.025,
			266.13,
			266.161,
			266.223,
			266.286,
			266.348,
			266.408,
			266.473,
			266.565,
			266.597,
			266.673,
			266.719,
			266.783,
			266.846,
			266.891,
			266.904,
			266.969,
			267.031,
			267.093,
			267.155,
			267.215,
			267.28,
			267.34,
			267.403,
			267.431,
			267.465,
			267.542,
			267.587,
			267.653,
			267.759,
			267.775,
			267.836,
			267.899,
			268.023,
			268.083,
			268.146,
			268.208,
			268.301,
			268.331,
			268.411,
			268.456,
			268.517,
			268.579,
			268.626,
			268.735,
			268.767,
			268.844,
			268.889,
			268.953,
			269.013,
			269.077,
			269.136,
			269.169,
			269.2,
			269.264,
			269.325,
			269.387,
			269.448,
			269.495,
			269.573,
			269.632,
			269.697,
			269.757,
			269.819,
			269.883,
			269.944,
			270.007,
			270.07,
			270.15,
			270.193,
			270.256,
			270.317,
			270.362,
			270.379,
			270.472,
			270.503,
			270.564,
			270.581,
			270.628,
			270.689,
			270.751,
			270.812,
			270.873,
			270.907,
			270.936,
			271.015,
			271.061,
			271.128,
			271.186,
			271.247,
			271.309,
			271.337,
			271.372,
			271.448,
			271.494,
			271.558,
			271.62,
			271.681,
			271.742,
			271.806,
			271.865,
			271.883,
			271.929,
			271.99,
			272.052,
			272.1,
			272.176,
			272.239,
			272.317,
			272.364,
			272.486,
			272.55,
			272.61,
			272.643,
			272.673,
			272.735,
			272.751,
			272.796,
			272.859,
			272.968,
			272.981,
			273.045,
			273.075,
			273.107,
			273.185,
			273.232,
			273.294,
			273.4,
			273.479,
			273.511,
			273.54,
			273.62,
			273.665,
			273.729,
			273.789,
			273.851,
			273.914,
			273.947,
			273.975,
			274.053,
			274.098,
			274.161,
			274.223,
			274.284,
			274.347,
			274.408,
			274.471,
			274.533,
			274.595,
			274.656,
			274.719,
			274.782,
			274.812,
			274.844,
			274.919,
			274.965,
			275.029,
			275.09,
			275.153,
			275.215,
			275.246,
			275.278,
			275.356,
			275.403,
			275.464,
			275.526,
			275.588,
			275.682,
			275.712,
			275.773,
			275.836,
			275.897,
			275.961,
			276.022,
			276.115,
			276.145,
			276.223,
			276.27,
			276.338,
			276.394,
			276.457,
			276.518,
			276.548,
			276.581,
			276.643,
			276.703,
			276.77,
			276.829,
			276.89,
			276.953,
			276.982,
			277.014,
			277.09,
			277.138,
			277.2,
			277.307,
			277.385,
			277.415,
			277.448,
			277.51,
			277.526,
			277.573,
			277.634,
			277.697,
			277.758,
			277.82,
			277.881,
			277.958,
			278.006,
			278.068,
			278.13,
			278.176,
			278.257,
			278.316,
			278.378,
			278.4,
			278.44,
			278.503,
			278.565,
			278.626,
			278.689,
			278.749,
			278.828,
			278.873,
			278.936,
			278.998,
			279.044,
			279.123,
			279.153,
			279.184,
			279.245,
			279.308,
			279.369,
			279.432,
			279.496,
			279.556,
			279.617,
			279.697,
			279.742,
			279.803,
			279.866,
			279.912,
			279.929,
			279.99,
			280.052,
			280.114,
			280.129,
			280.177,
			280.236,
			280.3,
			280.362,
			280.46,
			280.486,
			280.565,
			280.611,
			280.673,
			280.733,
			280.797,
			280.89,
			280.919,
			280.998,
			281.043,
			281.107,
			281.17,
			281.232,
			281.292,
			281.323,
			281.355,
			281.433,
			281.478,
			281.54,
			281.603,
			281.648,
			281.726,
			281.758,
			281.787,
			281.851,
			281.912,
			281.975,
			282.036,
			282.098,
			282.162,
			282.193,
			282.222,
			282.301,
			282.345,
			282.409,
			282.47,
			282.532,
			282.594,
			282.625,
			282.719,
			282.781,
			282.844,
			282.903,
			282.967,
			283.028,
			283.061,
			283.091,
			283.169,
			283.215,
			283.277,
			283.385,
			283.401,
			283.462,
			283.493,
			283.526,
			283.65,
			283.711,
			283.773,
			283.835,
			283.897,
			283.927,
			283.958,
			284.021,
			284.081,
			284.145,
			284.207,
			284.27,
			284.362,
			284.393,
			284.47,
			284.518,
			284.583,
			284.642,
			284.703,
			284.765,
			284.797,
			284.826,
			284.889,
			284.951,
			285.017,
			285.075,
			285.122,
			285.199,
			285.262,
			285.339,
			285.385,
			285.448,
			285.557,
			285.571,
			285.631,
			285.665,
			285.696,
			285.772,
			285.82,
			285.882,
			285.942,
			286.006,
			286.067,
			286.098,
			286.129,
			286.207,
			286.254,
			286.315,
			286.379,
			286.423,
			286.564,
			286.625,
			286.687,
			286.753,
			286.814,
			286.873,
			286.936,
			286.999,
			287.076,
			287.122,
			287.184,
			287.245,
			287.307,
			287.4,
			287.43,
			287.556,
			287.617,
			287.679,
			287.741,
			287.834,
			287.864,
			287.927,
			287.944,
			287.99,
			288.053,
			288.114,
			288.158,
			288.177,
			288.236,
			288.269,
			288.3,
			288.378,
			288.486,
			288.548,
			288.609,
			288.707,
			288.734,
			288.797,
			288.859,
			288.919,
			288.98,
			289.045,
			289.107,
			289.167,
			289.246,
			289.292,
			289.352,
			289.415,
			289.477,
			289.54,
			289.572,
			289.603,
			289.664,
			289.68,
			289.726,
			289.793,
			289.848,
			289.911,
			289.975,
			290.004,
			290.036,
			290.114,
			290.161,
			290.22,
			290.284,
			290.347,
			290.439,
			290.47,
			290.532,
			290.547,
			290.595,
			290.657,
			290.718,
			290.781,
			290.841,
			290.903,
			290.983,
			291.028,
			291.09,
			291.153,
			291.2,
			291.308,
			291.339,
			291.416,
			291.461,
			291.525,
			291.586,
			291.648,
			291.742,
			291.773,
			291.848,
			291.895,
			291.957,
			292.067,
			292.081,
			292.145,
			292.207,
			292.266,
			292.283,
			292.33,
			292.392,
			292.453,
			292.514,
			292.579,
			292.608,
			292.641,
			292.717,
			292.764,
			292.835,
			292.888,
			292.951,
			293.011,
			293.074,
			293.153,
			293.197,
			293.261,
			293.323,
			293.383,
			293.448,
			293.51,
			293.571,
			293.631,
			293.695,
			293.758,
			293.803,
			293.82,
			293.881,
			293.944,
			294.02,
			294.065,
			294.128,
			294.189,
			294.253,
			294.314,
			294.375,
			294.455,
			294.499,
			294.563,
			294.626,
			294.672,
			294.747,
			294.78,
			294.81,
			294.894,
			294.935,
			294.998,
			295.058,
			295.121,
			295.183,
			295.245,
			295.322,
			295.37,
			295.43,
			295.54,
			295.553,
			295.617,
			295.68,
			295.758,
			295.803,
			295.865,
			295.928,
			295.989,
			296.051,
			296.081,
			296.114,
			296.175,
			296.237,
			296.3,
			296.361,
			296.424,
			296.486,
			296.515,
			296.547,
			296.625,
			296.67,
			296.733,
			296.795,
			296.858,
			296.92,
			296.981,
			297.058,
			297.106,
			297.168,
			297.228,
			297.292,
			297.353,
			297.414,
			297.494,
			297.539,
			297.602,
			297.71,
			297.726,
			297.819,
			297.85,
			297.911,
			297.926,
			297.974,
			298.036,
			298.098,
			298.158,
			298.223,
			298.253,
			298.282,
			298.361,
			298.409,
			298.47,
			298.532,
			298.577,
			298.686,
			298.716,
			298.779,
			298.797,
			298.842,
			298.904,
			298.967,
			299.028,
			299.09,
			299.12,
			299.152,
			299.228,
			299.274,
			299.34,
			299.4,
			299.448,
			299.585,
			299.662,
			299.709,
			299.773,
			299.834,
			299.894,
			299.988,
			300.019,
			300.096,
			300.142,
			300.206,
			300.266,
			300.314,
			300.328,
			300.423,
			300.455,
			300.533,
			300.577,
			300.64,
			300.703,
			300.762,
			300.857,
			300.889,
			300.965,
			301.012,
			301.08,
			301.135,
			301.183,
			301.199,
			301.26,
			301.29,
			301.32,
			301.401,
			301.447,
			301.509,
			301.569,
			301.633,
			301.697,
			301.756,
			301.816,
			301.834,
			301.879,
			301.942,
			302.003,
			302.051,
			302.158,
			302.19,
			302.267,
			302.375,
			302.439,
			302.5,
			302.561,
			302.594,
			302.624,
			302.686,
			302.749,
			302.811,
			302.872,
			302.934,
			302.997,
			303.031,
			303.058,
			303.141,
			303.183,
			303.244,
			303.307,
			303.367,
			303.461,
			303.492,
			303.552,
			303.57,
			303.615,
			303.679,
			303.74,
			303.786,
			303.803,
			303.865,
			303.926,
			304.004,
			304.049,
			304.114,
			304.175,
			304.236,
			304.329,
			304.361,
			304.423,
			304.484,
			304.548,
			304.608,
			304.671,
			304.733,
			304.796,
			304.873,
			304.919,
			304.982,
			305.09,
			305.167,
			305.228,
			305.291,
			305.307,
			305.353,
			305.414,
			305.476,
			305.539,
			305.632,
			305.661,
			305.74,
			305.786,
			305.849,
			305.913,
			305.974,
			306.067,
			306.097,
			306.158,
			306.175,
			306.219,
			306.283,
			306.345,
			306.408,
			306.468,
			306.498,
			306.532,
			306.609,
			306.653,
			306.717,
			306.779,
			306.825,
			306.903,
			306.933,
			306.965,
			307.027,
			307.089,
			307.153,
			307.214,
			307.275,
			307.337,
			307.398,
			307.477,
			307.523,
			307.586,
			307.648,
			307.693,
			307.708,
			307.772,
			307.803,
			307.832,
			307.911,
			307.957,
			308.02,
			308.082,
			308.142,
			308.206,
			308.236,
			308.267,
			308.328,
			308.344,
			308.391,
			308.453,
			308.514,
			308.577,
			308.671,
			308.702,
			308.778,
			308.886,
			308.948,
			309.013,
			309.074,
			309.106,
			309.136,
			309.198,
			309.259,
			309.324,
			309.429,
			309.445,
			309.539,
			309.569,
			309.648,
			309.693,
			309.757,
			309.818,
			309.88,
			309.941,
			309.974,
			310.003,
			310.064,
			310.081,
			310.129,
			310.19,
			310.251,
			310.299,
			310.376,
			310.408,
			310.439,
			310.514,
			310.563,
			310.627,
			310.732,
			310.748,
			310.81,
			310.872,
			310.933,
			310.948,
			310.995,
			311.057,
			311.12,
			311.18,
			311.251,
			311.274,
			311.307,
			311.389,
			311.429,
			311.492,
			311.553,
			311.601,
			311.678,
			311.709,
			311.741,
			311.802,
			311.862,
			311.927,
			311.986,
			312.048,
			312.111,
			312.142,
			312.175,
			312.252,
			312.298,
			312.36,
			312.423,
			312.47,
			312.544,
			312.577,
			312.607,
			312.67,
			312.731,
			312.796,
			312.856,
			312.918,
			313.012,
			313.041,
			313.12,
			313.165,
			313.229,
			313.291,
			313.353,
			313.415,
			313.477,
			313.553,
			313.601,
			313.662,
			313.723,
			313.785,
			313.848,
			313.908,
			313.987,
			314.033,
			314.097,
			314.157,
			314.203,
			314.219,
			314.313,
			314.344,
			314.422,
			314.467,
			314.531,
			314.592,
			314.653,
			314.716,
			314.748,
			314.778,
			314.84,
			314.902,
			314.965,
			315.026,
			315.073,
			315.151,
			315.181,
			315.274,
			315.336,
			315.398,
			315.461,
			315.522,
			315.617,
			315.645,
			315.725,
			315.77,
			315.834,
			315.899,
			315.942,
			315.956,
			316.051,
			316.081,
			316.158,
			316.203,
			316.267,
			316.328,
			316.391,
			316.453,
			316.484,
			316.514,
			316.593,
			316.64,
			316.702,
			316.762,
			316.808,
			316.824,
			316.886,
			316.918,
			316.948,
			317.026,
			317.072,
			317.135,
			317.198,
			317.259,
			317.319,
			317.352,
			317.384,
			317.446,
			317.506,
			317.573,
			317.631,
			317.677,
			317.753,
			317.818,
			317.894,
			317.941,
			318.003,
			318.065,
			318.126,
			318.222,
			318.252,
			318.328,
			318.375,
			318.438,
			318.546,
			318.624,
			318.653,
			318.686,
			318.761,
			318.808,
			318.872,
			318.933,
			318.996,
			319.086,
			319.12,
			319.197,
			319.242,
			319.308,
			319.367,
			319.428,
			319.523,
			319.553,
			319.636,
			319.677,
			319.739,
			319.801,
			319.848,
			319.956,
			319.988,
			320.047,
			320.112,
			320.174,
			320.236,
			320.298,
			320.358,
			320.391,
			320.422,
			320.5,
			320.546,
			320.608,
			320.669,
			320.714,
			320.73,
			320.794,
			320.856,
			320.918,
			320.933,
			320.98,
			321.04,
			321.104,
			321.166,
			321.257,
			321.288,
			321.368,
			321.413,
			321.476,
			321.585,
			321.598,
			321.662,
			321.7,
			321.723,
			321.848,
			321.911,
			321.973,
			322.033,
			322.095,
			322.127,
			322.159,
			322.219,
			322.282,
			322.344,
			322.405,
			322.453,
			322.531,
			322.562,
			322.591,
			322.669,
			322.715,
			322.78,
			322.84,
			322.902,
			322.964,
			322.995,
			323.025,
			323.088,
			323.102,
			323.148,
			323.212,
			323.273,
			323.321,
			323.397,
			323.429,
			323.459,
			323.537,
			323.583,
			323.647,
			323.708,
			323.771,
			323.832,
			323.862,
			323.894,
			323.957,
			323.97,
			324.017,
			324.082,
			324.143,
			324.189,
			324.203,
			324.265,
			324.298,
			324.329,
			324.407,
			324.45,
			324.515,
			324.638,
			324.698,
			324.731,
			324.761,
			324.824,
			324.886,
			324.948,
			325.009,
			325.073,
			325.134,
			325.165,
			325.196,
			325.275,
			325.32,
			325.381,
			325.444,
			325.492,
			325.506,
			325.63,
			325.707,
			325.753,
			325.82,
			325.877,
			325.94,
			326.003,
			326.031,
			326.065,
			326.143,
			326.186,
			326.251,
			326.313,
			326.358,
			326.375,
			326.435,
			326.498,
			326.576,
			326.623,
			326.685,
			326.745,
			326.81,
			326.872,
			326.9,
			326.933,
			326.996,
			327.056,
			327.12,
			327.179,
			327.228,
			327.305,
			327.367,
			327.446,
			327.49,
			327.552,
			327.615,
			327.675,
			327.738,
			327.8,
			327.862,
			327.882,
			327.924,
			327.987,
			328.05,
			328.096,
			328.172,
			328.203,
			328.235,
			328.311,
			328.36,
			328.42,
			328.546,
			328.606,
			328.669,
			328.732,
			328.746,
			328.792,
			328.856,
			328.917,
			328.98,
			329.04,
			329.072,
			329.102,
			329.179,
			329.226,
			329.29,
			329.35,
			329.398,
			329.474,
			329.538,
			329.6,
			329.661,
			329.723,
			329.786,
			329.848,
			329.909,
			329.972,
			330.048,
			330.095,
			330.159,
			330.22,
			330.267,
			330.28,
			330.342,
			330.406,
			330.468,
			330.483,
			330.531,
			330.592,
			330.652,
			330.716,
			330.776,
			330.807,
			330.839,
			330.917,
			330.962,
			331.026,
			331.086,
			331.133,
			331.148,
			331.211,
			331.243,
			331.274,
			331.349,
			331.397,
			331.459,
			331.521,
			331.582,
			331.646,
			331.677,
			331.707,
			331.769,
			331.83,
			331.894,
			331.957,
			332.016,
			332.08,
			332.112,
			332.141,
			332.218,
			332.267,
			332.328,
			332.39,
			332.453,
			332.513,
			332.576,
			332.637,
			332.652,
			332.7,
			332.764,
			332.823,
			332.87,
			332.947,
			332.977,
			333.01,
			333.088,
			333.133,
			333.197,
			333.258,
			333.32,
			333.383,
			333.412,
			333.442,
			333.506,
			333.52,
			333.568,
			333.629,
			333.692,
			333.736,
			333.753,
			333.814,
			333.848,
			333.879,
			333.956,
			334.001,
			334.069,
			334.125,
			334.186,
			334.25,
			334.312,
			334.373,
			334.436,
			334.499,
			334.558,
			334.606,
			334.623,
			334.684,
			334.716,
			334.748,
			334.824,
			334.87,
			334.932,
			334.994,
			335.057,
			335.118,
			335.15,
			335.18,
			335.241,
			335.257,
			335.305,
			335.365,
			335.429,
			335.49,
			335.553,
			335.583,
			335.615,
			335.691,
			335.739,
			335.801,
			335.909,
			335.925,
			335.987,
			336.048,
			336.111,
			336.172,
			336.234,
			336.295,
			336.358,
			336.452,
			336.483,
			336.561,
			336.606,
			336.67,
			336.729,
			336.777,
			336.853,
			336.886,
			336.916,
			336.993,
			337.04,
			337.102,
			337.164,
			337.225,
			337.32,
			337.349,
			337.426,
			337.475,
			337.535,
			337.645,
			337.723,
			337.753,
			337.783,
			337.862,
			337.91,
			337.969,
			338.032,
			338.096,
			338.157,
			338.192,
			338.218,
			338.28,
			338.298,
			338.341,
			338.403,
			338.465,
			338.512,
			338.529,
			338.59,
			338.62,
			338.653,
			338.777,
			338.839,
			338.901,
			338.964,
			339.024,
			339.055,
			339.086,
			339.149,
			339.212,
			339.273,
			339.334,
			339.382,
			339.398,
			339.458,
			339.49,
			339.52,
			339.598,
			339.646,
			339.767,
			339.83,
			339.892,
			339.924,
			339.955,
			340.017,
			340.032,
			340.078,
			340.14,
			340.203,
			340.264,
			340.358,
			340.389,
			340.464,
			340.514,
			340.576,
			340.636,
			340.699,
			340.761,
			340.824,
			340.884,
			340.947,
			341.008,
			341.071,
			341.117,
			341.134,
			341.196,
			341.225,
			341.258,
			341.334,
			341.382,
			341.442,
			341.506,
			341.567,
			341.63,
			341.662,
			341.692,
			341.753,
			341.77,
			341.816,
			341.881,
			341.94,
			341.986,
			342.002,
			342.094,
			342.126,
			342.203,
			342.25,
			342.317,
			342.373,
			342.436,
			342.498,
			342.559,
			342.636,
			342.682,
			342.745,
			342.807,
			342.853,
			342.87,
			342.931,
			342.961,
			342.994,
			343.07,
			343.117,
			343.18,
			343.241,
			343.303,
			343.364,
			343.397,
			343.429,
			343.489,
			343.505,
			343.552,
			343.615,
			343.676,
			343.72,
			343.807,
			343.832,
			343.862,
			343.94,
			343.986,
			344.049,
			344.108,
			344.172,
			344.265,
			344.294,
			344.358,
			344.42,
			344.483,
			344.545,
			344.606,
			344.667,
			344.699,
			344.731,
			344.808,
			344.853,
			344.917,
			344.979,
			345.025,
			345.102,
			345.132,
			345.165,
			345.242,
			345.287,
			345.348,
			345.411,
			345.475,
			345.566,
			345.597,
			345.675,
			345.723,
			345.783,
			345.893,
			345.909,
			345.968,
			346.033,
			346.109,
			346.156,
			346.218,
			346.281,
			346.342,
			346.405,
			346.439,
			346.466,
			346.543,
			346.59,
			346.653,
			346.714,
			346.776,
			346.839,
			346.87,
			346.9,
			346.962,
			346.979,
			347.024,
			347.085,
			347.148,
			347.212,
			347.273,
			347.303,
			347.334,
			347.396,
			347.459,
			347.521,
			347.583,
			347.628,
			347.644,
			347.706,
			347.738,
			347.768,
			347.845,
			347.891,
			347.953,
			348.017,
			348.079,
			348.141,
			348.17,
			348.202,
			348.265,
			348.28,
			348.327,
			348.39,
			348.451,
			348.511,
			348.573,
			348.606,
			348.636,
			348.714,
			348.76,
			348.823,
			348.884,
			348.947,
			349.007,
			349.04,
			349.07,
			349.148,
			349.195,
			349.257,
			349.365,
			349.38,
			349.442,
			349.473,
			349.505,
			349.583,
			349.628,
			349.691,
			349.753,
			349.815,
			349.878,
			349.907,
			349.939,
			350,
			350.064,
			350.125,
			350.187,
			350.247,
			350.312,
			350.342,
			350.372,
			350.451,
			350.497,
			350.567,
			350.62,
			350.666,
			350.683,
			350.776,
			350.807,
			350.869,
			350.929,
			350.993,
			351.055,
			351.115,
			351.177,
			351.208,
			351.241,
			351.317,
			351.367,
			351.426,
			351.489,
			351.536,
			351.549,
			351.611,
			351.674,
			351.738,
			351.753,
			351.797,
			351.862,
			351.923,
			351.985,
			352.078,
			352.11,
			352.185,
			352.232,
			352.298,
			352.357,
			352.419,
			352.513,
			352.542,
			352.606,
			352.624,
			352.668,
			352.729,
			352.79,
			352.852,
			352.915,
			352.946,
			352.978,
			353.054,
			353.102,
			353.163,
			353.224,
			353.272,
			353.38,
			353.411,
			353.49,
			353.535,
			353.597,
			353.658,
			353.722,
			353.783,
			353.815,
			353.846,
			353.907,
			353.923,
			353.97,
			354.032,
			354.092,
			354.14,
			354.155,
			354.217,
			354.248,
			354.281,
			354.356,
			354.402,
			354.464,
			354.527,
			354.59,
			354.652,
			354.686,
			354.714,
			354.775,
			354.838,
			354.899,
			354.962,
			355.009,
			355.023,
			355.086,
			355.114,
			355.148,
			355.224,
			355.272,
			355.333,
			355.396,
			355.458,
			355.52,
			355.551,
			355.58,
			355.645,
			355.705,
			355.775,
			355.829,
			355.877,
			355.955,
			355.985,
			356.015,
			356.093,
			356.141,
			356.264,
			356.325,
			356.388,
			356.419,
			356.448,
			356.512,
			356.532,
			356.575,
			356.636,
			356.698,
			356.759,
			356.853,
			356.883,
			356.962,
			357.008,
			357.069,
			357.132,
			357.194,
			357.256,
			357.288,
			357.318,
			357.379,
			357.395,
			357.441,
			357.503,
			357.565,
			357.629,
			357.69,
			357.722,
			357.752,
			357.828,
			357.875,
			357.938,
			358,
			358.061,
			358.122,
			358.153,
			358.187,
			358.247,
			358.309,
			358.373,
			358.435,
			358.496,
			358.558,
			358.59,
			358.62,
			358.697,
			358.745,
			358.809,
			358.929,
			359.024,
			359.053,
			359.115,
			359.131,
			359.178,
			359.241,
			359.348,
			359.426,
			359.489,
			359.564,
			359.615,
			359.675,
			359.735,
			359.781,
			359.798,
			359.89,
			359.922,
			359.985,
			359.998,
			360.045,
			360.109,
			360.172,
			360.233,
			360.324,
			360.357,
			360.434,
			360.481,
			360.542,
			360.603,
			360.665,
			360.758,
			360.789,
			360.852,
			360.873,
			360.915,
			360.979,
			361.039,
			361.101,
			361.162,
			361.226,
			361.302,
			361.348,
			361.411,
			361.473,
			361.519,
			361.597,
			361.66,
			361.72,
			361.784,
			361.845,
			361.907,
			361.969,
			362.031,
			362.062,
			362.094,
			362.17,
			362.216,
			362.281,
			362.341,
			362.388,
			362.403,
			362.465,
			362.496,
			362.53,
			362.603,
			362.65,
			362.712,
			362.775,
			362.836,
			362.9,
			362.935,
			362.961,
			363.038,
			363.085,
			363.148,
			363.253,
			363.27,
			363.332,
			363.364,
			363.394,
			363.472,
			363.519,
			363.58,
			363.644,
			363.706,
			363.798,
			363.828,
			363.89,
			363.906,
			363.953,
			364.014,
			364.078,
			364.14,
			364.199,
			364.232,
			364.262,
			364.34,
			364.386,
			364.45,
			364.512,
			364.572,
			364.636,
			364.665,
			364.696,
			364.759,
			364.776,
			364.822,
			364.883,
			364.945,
			365.007,
			365.1,
			365.132,
			365.208,
			365.256,
			365.318,
			365.38,
			365.427,
			365.534,
			365.564,
			365.627,
			365.69,
			365.75,
			365.812,
			365.875,
			365.938,
			365.998,
			366.077,
			366.123,
			366.186,
			366.247,
			366.295,
			366.372,
			366.402,
			366.434,
			366.495,
			366.558,
			366.62,
			366.682,
			366.744,
			366.806,
			366.836,
			366.868,
			366.93,
			366.946,
			366.99,
			367.059,
			367.116,
			367.161,
			367.176,
			367.241,
			367.271,
			367.3,
			367.38,
			367.426,
			367.488,
			367.55,
			367.611,
			367.674,
			367.703,
			367.737,
			367.798,
			367.858,
			367.92,
			367.985,
			368.031,
			368.047,
			368.108,
			368.14,
			368.169,
			368.247,
			368.293,
			368.356,
			368.417,
			368.48,
			368.542,
			368.573,
			368.602,
			368.682,
			368.729,
			368.79,
			368.852,
			368.897,
			368.977,
			369.008,
			369.038,
			369.122,
			369.161,
			369.225,
			369.286,
			369.347,
			369.44,
			369.472,
			369.535,
			369.597,
			369.658,
			369.72,
			369.768,
			369.783,
			369.845,
			369.874,
			369.906,
			369.983,
			370.032,
			370.092,
			370.153,
			370.216,
			370.279,
			370.342,
			370.403,
			370.419,
			370.465,
			370.525,
			370.588,
			370.649,
			370.752,
			370.774,
			370.851,
			370.898,
			370.962,
			371.023,
			371.069,
			371.145,
			371.208,
			371.269,
			371.332,
			371.396,
			371.456,
			371.517,
			371.58,
			371.612,
			371.642,
			371.719,
			371.767,
			371.83,
			371.89,
			371.936,
			371.952,
			372.045,
			372.076,
			372.139,
			372.153,
			372.199,
			372.264,
			372.325,
			372.386,
			372.481,
			372.512,
			372.588,
			372.633,
			372.698,
			372.759,
			372.806,
			372.82,
			372.884,
			372.915,
			372.946,
			373.068,
			373.129,
			373.194,
			373.253,
			373.348,
			373.378,
			373.441,
			373.457,
			373.503,
			373.565,
			373.629,
			373.674,
			373.783,
			373.811,
			373.89,
			373.936,
			373.998,
			374.062,
			374.123,
			374.184,
			374.214,
			374.248,
			374.309,
			374.323,
			374.372,
			374.432,
			374.496,
			374.542,
			374.557,
			374.619,
			374.651,
			374.681,
			374.758,
			374.865,
			374.927,
			374.991,
			375.053,
			375.116,
			375.178,
			375.239,
			375.307,
			375.364,
			375.426,
			375.488,
			375.518,
			375.55,
			375.627,
			375.674,
			375.736,
			375.797,
			375.859,
			375.953,
			375.983,
			376.061,
			376.108,
			376.169,
			376.23,
			376.293,
			376.356,
			376.417,
			376.495,
			376.54,
			376.603,
			376.665,
			376.711,
			376.79,
			376.821,
			376.852,
			376.913,
			376.93,
			376.974,
			377.036,
			377.1,
			377.147,
			377.223,
			377.285,
			377.369,
			377.41,
			377.473,
			377.533,
			377.595,
			377.656,
			377.689,
			377.72,
			377.78,
			377.798,
			377.842,
			377.907,
			377.967,
			378.03,
			378.092,
			378.122,
			378.153,
			378.232,
			378.279,
			378.341,
			378.403,
			378.464,
			378.526,
			378.588,
			378.65,
			378.713,
			378.774,
			378.836,
			378.883,
			378.899,
			378.99,
			379.022,
			379.098,
			379.144,
			379.209,
			379.269,
			379.33,
			379.394,
			379.431,
			379.456,
			379.517,
			379.581,
			379.643,
			379.705,
			379.751,
			379.827,
			379.859,
			379.89,
			379.968,
			380.014,
			380.075,
			380.138,
			380.2,
			380.262,
			380.293,
			380.323,
			380.386,
			380.401,
			380.448,
			380.512,
			380.572,
			380.618,
			380.634,
			380.696,
			380.726,
			380.759,
			380.836,
			380.882,
			381.006,
			381.068,
			381.129,
			381.16,
			381.192,
			381.253,
			381.269,
			381.317,
			381.38,
			381.441,
			381.503,
			381.565,
			381.594,
			381.627,
			381.703,
			381.751,
			381.874,
			381.937,
			381.999,
			382.031,
			382.061,
			382.122,
			382.182,
			382.248,
			382.308,
			382.356,
			382.37,
			382.431,
			382.462,
			382.495,
			382.572,
			382.618,
			382.68,
			382.742,
			382.803,
			382.866,
			382.898,
			382.928,
			382.99,
			383.006,
			383.052,
			383.114,
			383.176,
			383.223,
			383.301,
			383.331,
			383.364,
			383.44,
			383.486,
			383.553,
			383.614,
			383.673,
			383.733,
			383.766,
			383.796,
			383.859,
			383.875,
			383.921,
			383.983,
			384.046,
			384.092,
			384.107,
			384.169,
			384.198,
			384.231,
			384.309,
			384.356,
			384.477,
			384.54,
			384.603,
			384.633,
			384.664,
			384.727,
			384.789,
			384.849,
			384.911,
			384.974,
			385.036,
			385.067,
			385.098,
			385.177,
			385.223,
			385.283,
			385.348,
			385.393,
			385.408,
			385.503,
			385.532,
			385.594,
			385.62,
			385.658,
			385.719,
			385.782,
			385.843,
			385.906,
			385.952,
			386.028,
			386.059,
			386.09,
			386.168,
			386.213,
			386.279,
			386.34,
			386.387,
			386.399,
			386.495,
			386.524,
			386.588,
			386.604,
			386.647,
			386.712,
			386.774,
			386.833,
			386.899,
			386.928,
			386.958,
			387.035,
			387.084,
			387.146,
			387.208,
			387.253,
			387.27,
			387.33,
			387.361,
			387.393,
			387.456,
			387.517,
			387.578,
			387.642,
			387.703,
			387.766,
			387.827,
			387.89,
			387.904,
			387.951,
			388.014,
			388.123,
			388.199,
			388.23,
			388.262,
			388.34,
			388.386,
			388.447,
			388.508,
			388.572,
			388.634,
			388.664,
			388.696,
			388.773,
			388.819,
			388.882,
			388.99,
			389.006,
			389.067,
			389.098,
			389.129,
			389.207,
			389.253,
			389.317,
			389.377,
			389.44,
			389.502,
			389.532,
			389.564,
			389.626,
			389.687,
			389.753,
			389.812,
			389.873,
			389.934,
			389.967,
			389.998,
			390.075,
			390.123,
			390.182,
			390.25,
			390.309,
			390.368,
			390.401,
			390.431,
			390.492,
			390.51,
			390.557,
			390.617,
			390.68,
			390.742,
			390.803,
			390.836,
			390.865,
			390.944,
			390.989,
			391.053,
			391.113,
			391.161,
			391.175,
			391.236,
			391.299,
			391.363,
			391.423,
			391.486,
			391.547,
			391.609,
			391.703,
			391.734,
			391.797,
			391.858,
			391.92,
			391.98,
			392.03,
			392.138,
			392.168,
			392.23,
			392.291,
			392.353,
			392.415,
			392.479,
			392.54,
			392.572,
			392.603,
			392.665,
			392.726,
			392.79,
			392.851,
			392.897,
			392.975,
			393.035,
			393.115,
			393.159,
			393.22,
			393.284,
			393.345,
			393.409,
			393.44,
			393.47,
			393.533,
			393.593,
			393.656,
			393.718,
			393.766,
			393.78,
			393.841,
			393.881,
			393.904,
			393.983,
			394.031,
			394.091,
			394.153,
			394.214,
			394.275,
			394.307,
			394.34,
			394.401,
			394.461,
			394.525,
			394.586,
			394.633,
			394.648,
			394.709,
			394.772,
			394.85,
			394.896,
			394.958,
			395.02,
			395.083,
			395.143,
			395.175,
			395.207,
			395.269,
			395.332,
			395.393,
			395.453,
			395.515,
			395.58,
			395.64,
			395.718,
			395.767,
			395.827,
			395.888,
			395.942,
			395.952,
			396.074,
			396.153,
			396.2,
			396.263,
			396.322,
			396.385,
			396.447,
			396.477,
			396.509,
			396.586,
			396.632,
			396.695,
			396.757,
			396.804,
			396.82,
			396.879,
			396.942,
			397.005,
			397.021,
			397.066,
			397.129,
			397.191,
			397.252,
			397.315,
			397.378,
			397.453,
			397.499,
			397.562,
			397.624,
			397.686,
			397.749,
			397.781,
			397.812,
			397.933,
			397.997,
			398.057,
			398.12,
			398.182,
			398.214,
			398.245,
			398.322,
			398.369,
			398.432,
			398.493,
			398.554,
			398.617,
			398.648,
			398.68,
			398.756,
			398.803,
			398.866,
			398.926,
			398.99,
			399.052,
			399.114,
			399.176,
			399.207,
			399.236,
			399.315,
			399.361,
			399.424,
			399.547,
			399.608,
			399.641,
			399.672,
			399.733,
			399.748,
			399.794,
			399.858,
			399.919,
			399.982,
			400.042,
			400.08,
			400.105,
			400.183,
			400.229,
			400.292,
			400.352,
			400.415,
			400.477,
			400.508,
			400.54,
			400.6,
			400.663,
			400.727,
			400.788,
			400.848,
			400.913,
			400.942,
			400.973,
			401.05,
			401.099,
			401.161,
			401.22,
			401.268,
			401.378,
			401.409,
			401.483,
			401.532,
			401.593,
			401.656,
			401.716,
			401.779,
			401.81,
			401.842,
			401.92,
			401.965,
			402.028,
			402.09,
			402.141,
			402.152,
			402.213,
			402.244,
			402.276,
			402.336,
			402.353,
			402.398,
			402.461,
			402.523,
			402.586,
			402.68,
			402.71,
			402.788,
			402.834,
			402.898,
			403.003,
			403.021,
			403.081,
			403.112,
			403.144,
			403.221,
			403.268,
			403.331,
			403.392,
			403.453,
			403.547,
			403.578,
			403.64,
			403.656,
			403.704,
			403.765,
			403.826,
			403.874,
			403.981,
			404.014,
			404.089,
			404.137,
			404.197,
			404.262,
			404.323,
			404.383,
			404.446,
			404.51,
			404.524,
			404.57,
			404.632,
			404.694,
			404.742,
			404.757,
			404.85,
			404.88,
			404.958,
			405.003,
			405.064,
			405.129,
			405.19,
			405.253,
			405.282,
			405.314,
			405.375,
			405.439,
			405.501,
			405.561,
			405.608,
			405.625,
			405.685,
			405.715,
			405.748,
			405.824,
			405.873,
			405.933,
			405.995,
			406.059,
			406.119,
			406.15,
			406.183,
			406.267,
			406.306,
			406.367,
			406.477,
			406.552,
			406.585,
			406.617,
			406.74,
			406.803,
			406.864,
			406.926,
			407.019,
			407.05,
			407.127,
			407.175,
			407.237,
			407.298,
			407.345,
			407.423,
			407.453,
			407.484,
			407.561,
			407.609,
			407.672,
			407.733,
			407.796,
			407.856,
			407.919,
			407.981,
			408.042,
			408.106,
			408.165,
			408.213,
			408.228,
			408.291,
			408.329,
			408.353,
			408.429,
			408.476,
			408.539,
			408.6,
			408.663,
			408.727,
			408.757,
			408.786,
			408.847,
			408.911,
			408.974,
			409.081,
			409.098,
			409.157,
			409.189,
			409.219,
			409.298,
			409.345,
			409.407,
			409.469,
			409.532,
			409.592,
			409.624,
			409.653,
			409.716,
			409.778,
			409.842,
			409.904,
			409.951,
			410.058,
			410.089,
			410.168,
			410.214,
			410.277,
			410.338,
			410.399,
			410.492,
			410.523,
			410.584,
			410.648,
			410.708,
			410.772,
			410.833,
			410.927,
			410.957,
			411.035,
			411.08,
			411.142,
			411.205,
			411.267,
			411.33,
			411.361,
			411.391,
			411.452,
			411.514,
			411.577,
			411.639,
			411.699,
			411.764,
			411.825,
			411.888,
			411.903,
			411.95,
			412.013,
			412.12,
			412.197,
			412.228,
			412.259,
			412.338,
			412.383,
			412.444,
			412.507,
			412.57,
			412.632,
			412.661,
			412.692,
			412.772,
			412.818,
			412.881,
			412.989,
			413.002,
			413.096,
			413.129,
			413.204,
			413.253,
			413.314,
			413.375,
			413.437,
			413.5,
			413.531,
			413.562,
			413.624,
			413.687,
			413.747,
			413.811,
			413.872,
			413.965,
			413.997,
			414.073,
			414.12,
			414.182,
			414.243,
			414.306,
			414.366,
			414.398,
			414.429,
			414.493,
			414.514,
			414.555,
			414.615,
			414.679,
			414.741,
			414.802,
			414.849,
			414.925,
			414.957,
			414.986,
			415.066,
			415.112,
			415.173,
			415.237,
			415.297,
			415.391,
			415.422,
			415.484,
			415.499,
			415.545,
			415.608,
			415.669,
			415.733,
			415.795,
			415.824,
			415.857,
			415.933,
			415.981,
			416.042,
			416.151,
			416.229,
			416.26,
			416.29,
			416.353,
			416.415,
			416.476,
			416.537,
			416.601,
			416.692,
			416.725,
			416.802,
			416.848,
			416.911,
			416.972,
			417.017,
			417.033,
			417.097,
			417.158,
			417.236,
			417.283,
			417.346,
			417.406,
			417.468,
			417.53,
			417.595,
			417.669,
			417.714,
			417.78,
			417.84,
			417.886,
			417.904,
			417.964,
			418.026,
			418.089,
			418.103,
			418.151,
			418.213,
			418.273,
			418.338,
			418.398,
			418.431,
			418.459,
			418.538,
			418.583,
			418.649,
			418.708,
			418.756,
			418.77,
			418.864,
			418.893,
			418.97,
			419.018,
			419.08,
			419.142,
			419.203,
			419.265,
			419.327,
			419.389,
			419.407,
			419.452,
			419.514,
			419.577,
			419.623,
			419.699,
			419.732,
			419.764,
			419.84,
			419.885,
			419.951,
			420.011,
			420.072,
			420.135,
			420.165,
			420.198,
			420.274,
			420.321,
			420.382,
			420.444,
			420.508,
			420.567,
			420.599,
			420.63,
			420.716,
			420.752,
			420.817,
			420.88,
			420.942,
			421.003,
			421.033,
			421.066,
			421.128,
			421.143,
			421.19,
			421.252,
			421.313,
			421.359,
			421.376,
			421.437,
			421.469,
			421.498,
			421.576,
			421.623,
			421.686,
			421.747,
			421.809,
			421.87,
			421.903,
			421.933,
			422.014,
			422.056,
			422.12,
			422.182,
			422.244,
			422.306,
			422.367,
			422.445,
			422.491,
			422.553,
			422.615,
			422.677,
			422.74,
			422.801,
			422.864,
			422.88,
			422.925,
			422.986,
			423.049,
			423.112,
			423.202,
			423.235,
			423.311,
			423.359,
			423.423,
			423.483,
			423.546,
			423.608,
			423.639,
			423.669,
			423.731,
			423.748,
			423.792,
			423.856,
			423.917,
			423.981,
			424.074,
			424.103,
			424.181,
			424.226,
			424.29,
			424.351,
			424.398,
			424.476,
			424.506,
			424.538,
			424.601,
			424.663,
			424.725,
			424.786,
			424.847,
			424.94,
			424.97,
			425.048,
			425.094,
			425.159,
			425.264,
			425.282,
			425.344,
			425.374,
			425.407,
			425.466,
			425.482,
			425.529,
			425.593,
			425.653,
			425.715,
			425.779,
			425.809,
			425.84,
			425.916,
			425.965,
			426.026,
			426.089,
			426.15,
			426.212,
			426.242,
			426.273,
			426.352,
			426.398,
			426.46,
			426.52,
			426.583,
			426.677,
			426.707,
			426.771,
			426.832,
			426.899,
			426.957,
			427.003,
			427.019,
			427.111,
			427.143,
			427.22,
			427.266,
			427.391,
			427.453,
			427.516,
			427.545,
			427.575,
			427.639,
			427.653,
			427.7,
			427.762,
			427.872,
			427.948,
			427.98,
			428.012,
			428.087,
			428.134,
			428.198,
			428.259,
			428.32,
			428.382,
			428.412,
			428.445,
			428.507,
			428.522,
			428.567,
			428.631,
			428.693,
			428.74,
			428.756,
			428.815,
			428.849,
			428.879,
			428.961,
			429.002,
			429.063,
			429.125,
			429.189,
			429.282,
			429.312,
			429.373,
			429.436,
			429.5,
			429.608,
			429.622,
			429.684,
			429.716,
			429.748,
			429.826,
			429.877,
			429.933,
			429.996,
			430.056,
			430.117,
			430.181,
			430.259,
			430.303,
			430.366,
			430.428,
			430.475,
			430.553,
			430.583,
			430.615,
			430.693,
			430.739,
			430.801,
			430.864,
			430.924,
			430.985,
			431.048,
			431.11,
			431.173,
			431.235,
			431.297,
			431.359,
			431.421,
			431.451,
			431.481,
			431.562,
			431.607,
			431.667,
			431.732,
			431.776,
			431.856,
			431.915,
			431.98,
			431.994,
			432.04,
			432.102,
			432.163,
			432.227,
			432.289,
			432.32,
			432.351,
			432.429,
			432.475,
			432.536,
			432.6,
			432.645,
			432.723,
			432.785,
			432.847,
			432.909,
			432.97,
			433.032,
			433.095,
			433.156,
			433.219,
			433.297,
			433.344,
			433.403,
			433.515,
			433.53,
			433.59,
			433.62,
			433.653,
			433.729,
			433.777,
			433.84,
			433.9,
			433.964,
			434.025,
			434.057,
			434.086,
			434.15,
			434.211,
			434.273,
			434.381,
			434.397,
			434.46,
			434.491,
			434.522,
			434.598,
			434.709,
			434.769,
			434.831,
			434.893,
			434.925,
			434.956,
			435.033,
			435.08,
			435.148,
			435.203,
			435.265,
			435.326,
			435.359,
			435.39,
			435.465,
			435.513,
			435.577,
			435.638,
			435.699,
			435.762,
			435.793,
			435.824,
			435.887,
			435.948,
			436.011,
			436.072,
			436.117,
			436.133,
			436.194,
			436.228,
			436.258,
			436.335,
			436.382,
			436.445,
			436.506,
			436.553,
			436.629,
			436.691,
			436.753,
			436.815,
			436.88,
			436.94,
			437.003,
			437.065,
			437.096,
			437.127,
			437.209,
			437.248,
			437.312,
			437.422,
			437.498,
			437.562,
			437.638,
			437.685,
			437.746,
			437.808,
			437.87,
			437.932,
			437.962,
			437.995,
			438.072,
			438.119,
			438.181,
			438.241,
			438.287,
			438.303,
			438.366,
			438.397,
			438.427,
			438.491,
			438.504,
			438.553,
			438.614,
			438.677,
			438.739,
			438.832,
			438.863,
			438.94,
			438.986,
			439.049,
			439.111,
			439.172,
			439.233,
			439.273,
			439.296,
			439.357,
			439.374,
			439.421,
			439.483,
			439.545,
			439.606,
			439.669,
			439.7,
			439.731,
			439.809,
			439.853,
			439.915,
			440.026,
			440.103,
			440.132,
			440.165,
			440.243,
			440.29,
			440.349,
			440.413,
			440.473,
			440.568,
			440.599,
			440.659,
			440.675,
			440.722,
			440.786,
			440.848,
			440.895,
			440.969,
			441.003,
			441.031,
			441.111,
			441.157,
			441.22,
			441.281,
			441.344,
			441.436,
			441.467,
			441.529,
			441.544,
			441.59,
			441.653,
			441.714,
			441.762,
			441.776,
			441.838,
			441.869,
			441.9,
			441.977,
			442.025,
			442.087,
			442.15,
			442.211,
			442.303,
			442.335,
			442.412,
			442.458,
			442.523,
			442.583,
			442.645,
			442.707,
			442.769,
			442.846,
			442.893,
			442.957,
			443.018,
			443.079,
			443.14,
			443.172,
			443.203,
			443.265,
			443.279,
			443.328,
			443.393,
			443.451,
			443.513,
			443.575,
			443.607,
			443.637,
			443.714,
			443.762,
			443.825,
			443.885,
			443.948,
			444.009,
			444.072,
			444.134,
			444.15,
			444.195,
			444.256,
			444.32,
			444.38,
			444.443,
			444.505,
			444.582,
			444.628,
			444.692,
			444.754,
			444.816,
			444.879,
			444.908,
			444.939,
			445.002,
			445.062,
			445.126,
			445.188,
			445.25,
			445.31,
			445.343,
			445.373,
			445.456,
			445.498,
			445.559,
			445.62,
			445.683,
			445.778,
			445.808,
			445.883,
			445.93,
			445.993,
			446.057,
			446.117,
			446.18,
			446.212,
			446.242,
			446.305,
			446.318,
			446.366,
			446.428,
			446.49,
			446.551,
			446.646,
			446.674,
			446.738,
			446.799,
			446.863,
			446.924,
			446.986,
			447.078,
			447.112,
			447.17,
			447.232,
			447.294,
			447.358,
			447.419,
			447.481,
			447.544,
			447.62,
			447.667,
			447.729,
			447.792,
			447.853,
			447.915,
			447.947,
			447.977,
			448.055,
			448.103,
			448.165,
			448.227,
			448.273,
			448.381,
			448.411,
			448.536,
			448.598,
			448.661,
			448.723,
			448.783,
			448.815,
			448.845,
			448.908,
			448.924,
			448.968,
			449.033,
			449.095,
			449.14,
			449.157,
			449.219,
			449.28,
			449.357,
			449.403,
			449.468,
			449.526,
			449.59,
			449.653,
			449.683,
			449.715,
			449.777,
			449.838,
			449.901,
			449.961,
			450.008,
			450.024,
			450.086,
			450.149,
			450.225,
			450.336,
			450.395,
			450.458,
			450.52,
			450.552,
			450.582,
			450.645,
			450.706,
			450.768,
			450.831,
			450.891,
			450.953,
			450.986,
			451.016,
			451.095,
			451.141,
			451.202,
			451.265,
			451.326,
			451.39,
			451.418,
			451.45,
			451.528,
			451.574,
			451.641,
			451.698,
			451.745,
			451.823,
			451.883,
			451.961,
			452.007,
			452.072,
			452.132,
			452.193,
			452.258,
			452.288,
			452.319,
			452.382,
			452.395,
			452.442,
			452.506,
			452.568,
			452.612,
			452.629,
			452.722,
			452.753,
			452.829,
			452.877,
			452.94,
			453.002,
			453.064,
			453.157,
			453.188,
			453.249,
			453.311,
			453.373,
			453.435,
			453.498,
			453.559,
			453.59,
			453.62,
			453.703,
			453.746,
			453.806,
			453.869,
			453.931,
			454.024,
			454.053,
			454.118,
			454.132,
			454.178,
			454.242,
			454.302,
			454.364,
			454.427,
			454.458,
			454.489,
			454.565,
			454.613,
			454.675,
			454.735,
			454.799,
			454.862,
			454.891,
			454.986,
			455.001,
			455.047,
			455.11,
			455.172,
			455.234,
			455.294,
			455.327,
			455.358,
			455.435,
			455.48,
			455.546,
			455.606,
			455.653,
			455.729,
			455.769,
			455.869,
			455.916,
			455.978,
			456.04,
			456.101,
			456.195,
			456.224,
			456.286,
			456.302,
			456.35,
			456.411,
			456.473,
			456.536,
			456.596,
			456.627,
			456.659,
			456.736,
			456.783,
			456.847,
			456.906,
			456.969,
			457.032,
			457.061,
			457.092,
			457.155,
			457.172,
			457.217,
			457.281,
			457.342,
			457.388,
			457.402,
			457.465,
			457.497,
			457.528,
			457.605,
			457.651,
			457.713,
			457.776,
			457.836,
			457.898,
			457.96,
			458.024,
			458.087,
			458.148,
			458.208,
			458.272,
			458.334,
			458.364,
			458.394,
			458.474,
			458.52,
			458.581,
			458.707,
			458.799,
			458.829,
			458.892,
			458.906,
			458.954,
			459.015,
			459.078,
			459.139,
			459.202,
			459.232,
			459.265,
			459.34,
			459.388,
			459.449,
			459.574,
			459.636,
			459.667,
			459.698,
			459.758,
			459.822,
			459.886,
			459.947,
			460.008,
			460.101,
			460.131,
			460.211,
			460.256,
			460.316,
			460.378,
			460.425,
			460.534,
			460.567,
			460.645,
			460.69,
			460.752,
			460.815,
			460.875,
			460.969,
			461,
			461.076,
			461.125,
			461.186,
			461.248,
			461.296,
			461.402,
			461.434,
			461.497,
			461.559,
			461.619,
			461.683,
			461.745,
			461.836,
			461.867,
			461.931,
			461.949,
			461.992,
			462.053,
			462.115,
			462.162,
			462.178,
			462.241,
			462.27,
			462.303,
			462.379,
			462.426,
			462.49,
			462.55,
			462.611,
			462.675,
			462.736,
			462.798,
			462.861,
			462.923,
			462.985,
			463.031,
			463.047,
			463.108,
			463.14,
			463.171,
			463.247,
			463.294,
			463.357,
			463.419,
			463.479,
			463.574,
			463.603,
			463.667,
			463.68,
			463.729,
			463.789,
			463.853,
			463.9,
			463.978,
			464.014,
			464.04,
			464.116,
			464.161,
			464.226,
			464.35,
			464.411,
			464.443,
			464.473,
			464.534,
			464.552,
			464.598,
			464.66,
			464.721,
			464.784,
			464.845,
			464.874,
			464.906,
			464.983,
			465.031,
			465.093,
			465.153,
			465.201,
			465.308,
			465.341,
			465.401,
			465.464,
			465.526,
			465.589,
			465.651,
			465.714,
			465.742,
			465.775,
			465.852,
			465.898,
			465.962,
			466.023,
			466.075,
			466.148,
			466.207,
			466.27,
			466.331,
			466.394,
			466.457,
			466.519,
			466.581,
			466.611,
			466.645,
			466.72,
			466.767,
			466.829,
			466.891,
			466.938,
			466.953,
			467.047,
			467.078,
			467.156,
			467.201,
			467.262,
			467.325,
			467.386,
			467.448,
			467.48,
			467.512,
			467.573,
			467.635,
			467.698,
			467.758,
			467.806,
			467.82,
			467.883,
			467.914,
			467.944,
			468.022,
			468.07,
			468.132,
			468.193,
			468.256,
			468.315,
			468.348,
			468.378,
			468.457,
			468.503,
			468.566,
			468.627,
			468.675,
			468.752,
			468.813,
			468.891,
			468.938,
			468.999,
			469.06,
			469.124,
			469.183,
			469.216,
			469.247,
			469.311,
			469.325,
			469.372,
			469.432,
			469.543,
			469.62,
			469.683,
			469.758,
			469.806,
			469.867,
			469.928,
			469.992,
			470.053,
			470.084,
			470.117,
			470.179,
			470.2,
			470.24,
			470.302,
			470.411,
			470.425,
			470.486,
			470.549,
			470.628,
			470.674,
			470.736,
			470.796,
			470.861,
			470.953,
			470.983,
			471.047,
			471.109,
			471.17,
			471.233,
			471.278,
			471.294,
			471.356,
			471.386,
			471.417,
			471.496,
			471.542,
			471.603,
			471.665,
			471.713,
			471.729,
			471.791,
			471.851,
			471.912,
			471.974,
			472.039,
			472.098,
			472.162,
			472.225,
			472.266,
			472.286,
			472.378,
			472.409,
			472.488,
			472.532,
			472.598,
			472.658,
			472.703,
			472.721,
			472.781,
			472.812,
			472.845,
			472.906,
			472.922,
			472.968,
			473.035,
			473.092,
			473.155,
			473.247,
			473.278,
			473.357,
			473.401,
			473.465,
			473.528,
			473.588,
			473.65,
			473.711,
			473.775,
			473.835,
			473.9,
			473.959,
			474.023,
			474.085,
			474.114,
			474.145,
			474.224,
			474.269,
			474.337,
			474.395,
			474.442,
			474.456,
			474.548,
			474.643,
			474.659,
			474.703,
			474.766,
			474.828,
			474.89,
			474.953,
			475.013,
			475.091,
			475.137,
			475.201,
			475.264,
			475.323,
			475.387,
			475.417,
			475.447,
			475.573,
			475.633,
			475.695,
			475.758,
			475.82,
			475.882,
			475.959,
			476.006,
			476.07,
			476.177,
			476.253,
			476.286,
			476.315,
			476.399,
			476.441,
			476.503,
			476.564,
			476.628,
			476.689,
			476.719,
			476.75,
			476.814,
			476.827,
			476.874,
			476.937,
			477.046,
			477.153,
			477.185,
			477.262,
			477.308,
			477.37,
			477.496,
			477.557,
			477.62,
			477.68,
			477.697,
			477.744,
			477.806,
			477.865,
			477.93,
			477.992,
			478.023,
			478.052,
			478.132,
			478.18,
			478.24,
			478.301,
			478.362,
			478.425,
			478.487,
			478.55,
			478.611,
			478.673,
			478.735,
			478.797,
			478.858,
			478.89,
			478.922,
			479,
			479.045,
			479.107,
			479.168,
			479.214,
			479.231,
			479.292,
			479.325,
			479.353,
			479.416,
			479.432,
			479.478,
			479.54,
			479.603,
			479.664,
			479.726,
			479.759,
			479.79,
			479.849,
			479.913,
			479.976,
			480.036,
			480.083,
			480.1,
			480.191,
			480.221,
			480.299,
			480.346,
			480.411,
			480.47,
			480.532,
			480.595,
			480.626,
			480.658,
			480.72,
			480.78,
			480.843,
			480.907,
			480.966,
			481.031,
			481.061,
			481.093,
			481.168,
			481.217,
			481.279,
			481.34,
			481.403,
			481.464,
			481.495,
			481.526,
			481.589,
			481.648,
			481.713,
			481.773,
			481.835,
			481.897,
			481.928,
			481.962,
			482.038,
			482.083,
			482.147,
			482.209,
			482.269,
			482.33,
			482.393,
			482.457,
			482.47,
			482.518,
			482.586,
			482.641,
			482.703,
			482.767,
			482.828,
			482.906,
			482.952,
			483.016,
			483.075,
			483.139,
			483.23,
			483.262,
			483.34,
			483.387,
			483.448,
			483.511,
			483.573,
			483.633,
			483.666,
			483.697,
			483.775,
			483.821,
			483.883,
			483.943,
			484.006,
			484.099,
			484.129,
			484.192,
			484.209,
			484.253,
			484.314,
			484.378,
			484.439,
			484.503,
			484.533,
			484.565,
			484.626,
			484.647,
			484.689,
			484.75,
			484.858,
			484.875,
			484.936,
			484.965,
			484.999,
			485.061,
			485.075,
			485.122,
			485.185,
			485.246,
			485.308,
			485.37,
			485.402,
			485.431,
			485.51,
			485.557,
			485.617,
			485.681,
			485.743,
			485.836,
			485.867,
			485.929,
			485.991,
			486.053,
			486.115,
			486.175,
			486.269,
			486.301,
			486.378,
			486.424,
			486.486,
			486.548,
			486.594,
			486.674,
			486.708,
			486.735,
			486.811,
			486.859,
			486.92,
			486.982,
			487.044,
			487.107,
			487.137,
			487.168,
			487.229,
			487.291,
			487.356,
			487.416,
			487.464,
			487.479,
			487.541,
			487.572,
			487.602,
			487.681,
			487.787,
			487.85,
			487.911,
			487.976,
			488.006,
			488.036,
			488.1,
			488.16,
			488.224,
			488.283,
			488.33,
			488.348,
			488.408,
			488.44,
			488.472,
			488.547,
			488.596,
			488.658,
			488.717,
			488.782,
			488.842,
			488.906,
			488.966,
			488.982,
			489.03,
			489.092,
			489.152,
			489.2,
			489.308,
			489.34,
			489.417,
			489.464,
			489.524,
			489.648,
			489.741,
			489.773,
			489.851,
			489.898,
			489.959,
			490.022,
			490.084,
			490.146,
			490.179,
			490.207,
			490.283,
			490.33,
			490.395,
			490.454,
			490.501,
			490.611,
			490.642,
			490.702,
			490.764,
			490.832,
			490.89,
			490.95,
			491.014,
			491.075,
			491.152,
			491.199,
			491.261,
			491.369,
			491.448,
			491.478,
			491.573,
			491.632,
			491.695,
			491.756,
			491.82,
			491.88,
			491.944,
			492.022,
			492.066,
			492.131,
			492.192,
			492.239,
			492.255,
			492.316,
			492.347,
			492.441,
			492.456,
			492.503,
			492.565,
			492.624,
			492.688,
			492.749,
			492.812,
			492.895,
			492.937,
			492.999,
			493.061,
			493.123,
			493.184,
			493.214,
			493.245,
			493.309,
			493.369,
			493.432,
			493.493,
			493.556,
			493.65,
			493.681,
			493.756,
			493.805,
			493.867,
			493.975,
			494.052,
			494.115,
			494.191,
			494.245,
			494.3,
			494.36,
			494.423,
			494.486,
			494.517,
			494.547,
			494.611,
			494.626,
			494.672,
			494.733,
			494.797,
			494.841,
			494.858,
			494.956,
			494.982,
			495.061,
			495.107,
			495.169,
			495.229,
			495.293,
			495.385,
			495.417,
			495.478,
			495.541,
			495.603,
			495.665,
			495.726,
			495.789,
			495.851,
			495.928,
			495.975,
			496.036,
			496.097,
			496.161,
			496.253,
			496.283,
			496.361,
			496.409,
			496.47,
			496.533,
			496.595,
			496.686,
			496.719,
			496.794,
			496.841,
			496.905,
			497.019,
			497.091,
			497.153,
			497.215,
			497.229,
			497.275,
			497.34,
			497.4,
			497.462,
			497.526,
			497.557,
			497.586,
			497.648,
			497.711,
			497.773,
			497.88,
			497.896,
			497.957,
			498.022,
			498.098,
			498.143,
			498.207,
			498.269,
			498.33,
			498.423,
			498.453,
			498.532,
			498.579,
			498.642,
			498.748,
			498.827,
			498.857,
			498.889,
			498.966,
			499.013,
			499.137,
			499.197,
			499.26,
			499.323,
			499.4,
			499.448,
			499.509,
			499.616,
			499.632,
			499.695,
			499.757,
			499.834,
			499.88,
			499.944,
			500.003,
			500.067,
			500.128,
			500.192,
			500.253,
			500.269,
			500.314,
			500.378,
			500.485,
			500.501,
			500.592,
			500.624,
			500.703,
			500.749,
			500.813,
			500.873,
			500.92,
			501.028,
			501.058,
			501.12,
			501.181,
			501.245,
			501.307,
			501.37,
			501.432,
			501.464,
			501.495,
			501.57,
			501.616,
			501.68,
			501.741,
			501.788,
			501.803,
			501.865,
			501.928,
			501.989,
			502.005,
			502.052,
			502.112,
			502.175,
			502.238,
			502.3,
			502.33,
			502.361,
			502.44,
			502.486,
			502.548,
			502.61,
			502.656,
			502.673,
			502.733,
			502.794,
			502.873,
			502.92,
			502.98,
			503.043,
			503.106,
			503.168,
			503.202,
			503.228,
			503.307,
			503.353,
			503.417,
			503.476,
			503.524,
			503.599,
			503.664,
			503.741,
			503.848,
			503.91,
			503.974,
			504.035,
			504.098,
			504.159,
			504.174,
			504.222,
			504.285,
			504.347,
			504.391,
			504.408,
			504.469,
			504.5,
			504.532,
			504.609,
			504.656,
			504.718,
			504.778,
			504.843,
			504.903,
			504.935,
			504.964,
			505.029,
			505.09,
			505.152,
			505.215,
			505.276,
			505.338,
			505.4,
			505.476,
			505.523,
			505.586,
			505.647,
			505.695,
			505.834,
			505.894,
			505.958,
			506.02,
			506.082,
			506.145,
			506.207,
			506.268,
			506.346,
			506.391,
			506.453,
			506.517,
			506.579,
			506.64,
			506.67,
			506.703,
			506.78,
			506.827,
			506.889,
			506.95,
			507.013,
			507.074,
			507.105,
			507.136,
			507.197,
			507.26,
			507.329,
			507.383,
			507.447,
			507.508,
			507.571,
			507.646,
			507.695,
			507.756,
			507.817,
			507.879,
			507.973,
			508.003,
			508.081,
			508.129,
			508.19,
			508.253,
			508.314,
			508.376,
			508.408,
			508.439,
			508.516,
			508.562,
			508.623,
			508.688,
			508.748,
			508.81,
			508.841,
			508.872,
			508.933,
			508.948,
			508.996,
			509.057,
			509.121,
			509.166,
			509.182,
			509.274,
			509.307,
			509.39,
			509.431,
			509.553,
			509.616,
			509.678,
			509.74,
			509.817,
			509.864,
			509.928,
			509.99,
			510.05,
			510.111,
			510.145,
			510.175,
			510.252,
			510.297,
			510.362,
			510.469,
			510.547,
			510.577,
			510.608,
			510.671,
			510.731,
			510.796,
			510.858,
			510.918,
			510.981,
			511.011,
			511.042,
			511.12,
			511.166,
			511.23,
			511.352,
			511.413,
			511.477,
			511.556,
			511.6,
			511.662,
			511.726,
			511.786,
			511.847,
			511.879,
			511.91,
			511.989,
			512.035,
			512.098,
			512.158,
			512.222,
			512.282,
			512.315,
			512.344,
			512.407,
			512.47,
			512.531,
			512.592,
			512.656,
			512.748,
			512.778,
			512.857,
			512.903,
			512.964,
			513.09,
			513.151,
			513.181,
			513.213,
			513.276,
			513.337,
			513.399,
			513.461,
			513.523,
			513.584,
			513.646,
			513.723,
			513.77,
			513.833,
			513.941,
			513.957,
			514.08,
			514.158,
			514.206,
			514.267,
			514.33,
			514.391,
			514.453,
			514.516,
			514.594,
			514.64,
			514.702,
			514.764,
			514.81,
			514.826,
			514.888,
			514.917,
			515.013,
			515.027,
			515.073,
			515.137,
			515.196,
			515.261,
			515.322,
			515.353,
			515.382,
			515.446,
			515.508,
			515.574,
			515.633,
			515.679,
			515.694,
			515.755,
			515.786,
			515.818,
			515.894,
			515.941,
			516.003,
			516.066,
			516.127,
			516.191,
			516.22,
			516.253,
			516.313,
			516.329,
			516.375,
			516.439,
			516.5,
			516.563,
			516.623,
			516.655,
			516.686,
			516.763,
			516.811,
			516.873,
			516.935,
			516.997,
			517.058,
			517.119,
			517.182,
			517.197,
			517.244,
			517.306,
			517.369,
			517.429,
			517.491,
			517.523,
			517.552,
			517.638,
			517.68,
			517.74,
			517.802,
			517.864,
			517.927,
			517.957,
			517.988,
			518.05,
			518.112,
			518.175,
			518.238,
			518.281,
			518.298,
			518.36,
			518.391,
			518.423,
			518.499,
			518.545,
			518.609,
			518.733,
			518.793,
			518.826,
			518.857,
			518.917,
			518.934,
			518.98,
			519.043,
			519.105,
			519.165,
			519.228,
			519.261,
			519.288,
			519.367,
			519.415,
			519.478,
			519.536,
			519.585,
			519.661,
			519.724,
			519.786,
			519.802,
			519.848,
			519.911,
			519.973,
			520.032,
			520.097,
			520.128,
			520.157,
			520.236,
			520.281,
			520.328
		]
	],
	'2812_2813_7982': [
		[
			419.347
		],
		[
			0
		],
		[
			0,
			1.012,
			4.363,
			5.466,
			6.561,
			9.787,
			11.891,
			14.999,
			17.018,
			19.984,
			21.925,
			22.884,
			24.787,
			27.59,
			28.515,
			31.244,
			33.024,
			34.774,
			35.638,
			39.016,
			39.84,
			41.466,
			42.265,
			43.838,
			45.376,
			47.616,
			49.066,
			51.177,
			51.865,
			52.546,
			54.555,
			55.854,
			57.761,
			59.003,
			60.231,
			62.057,
			63.865,
			65.066,
			65.666,
			66.867,
			68.064,
			69.853,
			71.044,
			72.838,
			74.037,
			75.834,
			77.032,
			78.258,
			80.101,
			81.327,
			83.201,
			83.841,
			84.479,
			86.391,
			87.687,
			89.727,
			91.087,
			93.208,
			95.471,
			96.985,
			99.172,
			100.617,
			102.07,
			104.113,
			104.785,
			105.458,
			107.49,
			108.845,
			110.89,
			112.267,
			113.655,
			115.753,
			117.167,
			119.299,
			120.013,
			120.73,
			122.902,
			124.362,
			126.575,
			128.069,
			129.58,
			130.339,
			131.865,
			133.409,
			134.187,
			136.532,
			138.106,
			139.687,
			142.074,
			143.676,
			146.902,
			147.715,
			150.18,
			151.823,
			154.323,
			156.014,
			157.72,
			158.582,
			160.319,
			161.193,
			162.95,
			165.628,
			166.512,
			169.241,
			171.084,
			173.878,
			175.76,
			177.657,
			178.611,
			180.529,
			183.432,
			185.383,
			188.337,
			190.323,
			193.327,
			195.347,
			198.403,
			200.457,
			203.581,
			205.65,
			208.804,
			210.91,
			211.964,
			214.077,
			216.203,
			217.27,
			219.411,
			221.566,
			224.821,
			227.006,
			229.203,
			231.416,
			232.527,
			235.88,
			238.151,
			240.414,
			246.132,
			250.756,
			251.926,
			255.474,
			257.84,
			261.399,
			263.798,
			267.44,
			269.864,
			271.079,
			273.513,
			277.176,
			279.619,
			282.06,
			285.729,
			286.955,
			288.184,
			293.125,
			294.368,
			298.119,
			300.627,
			304.402,
			306.921,
			309.451,
			313.261,
			314.513,
			315.786,
			319.641,
			320.92,
			322.202,
			326.063,
			328.627,
			331.22,
			332.52,
			335.144,
			337.748,
			339.053,
			342.96,
			345.59,
			348.226,
			352.185,
			354.847,
			358.793,
			360.105,
			361.413,
			365.319,
			367.911,
			371.733,
			376.747,
			377.983,
			381.67,
			384.117,
			387.795,
			390.253,
			392.738,
			395.204,
			398.889,
			401.336,
			403.771,
			404.983,
			408.607,
			411.017,
			414.633,
			417.041,
			419.449,
			423.059,
			424.262,
			425.466,
			429.077,
			431.488,
			435.111,
			437.533,
			441.151,
			443.56,
			444.763,
			447.171,
			449.579,
			453.191,
			455.599,
			458.014,
			461.646,
			465.296,
			466.518,
			467.742,
			471.429,
			473.906,
			476.398,
			480.174,
			482.706,
			486.523,
			487.797,
			489.074,
			492.902,
			495.457,
			499.279,
			501.833,
			505.675,
			508.243,
			509.531,
			512.105,
			515.969,
			518.543,
			521.104,
			525.008,
			527.603,
			531.503,
			534.093,
			536.736,
			538.051,
			540.686,
			544.633,
			547.29,
			549.956,
			555.314,
			556.66,
			560.708,
			563.416,
			567.491,
			570.217,
			572.951,
			574.32,
			577.065,
			578.441,
			581.198,
			585.351,
			588.129,
			590.914,
			595.099,
			597.896,
			602.1,
			604.906,
			609.115,
			611.914,
			616.116,
			621.634,
			625.746,
			627.125,
			629.897,
			634.063,
			635.477,
			639.665,
			642.469,
			646.69,
			649.485,
			652.307,
			653.719,
			656.572,
			657.988,
			660.817,
			663.648,
			667.901,
			670.72,
			674.997,
			677.856,
			682.161,
			685.04,
			689.372,
			692.267,
			696.626,
			699.54,
			700.998,
			703.916,
			706.857,
			711.217,
			714.148,
			718.555,
			721.5,
			724.453,
			727.414,
			728.898,
			733.361,
			736.344,
			739.332,
			743.814,
			746.825,
			751.292,
			754.252,
			758.654,
			761.554,
			765.813,
			768.622,
			772.763,
			775.478,
			776.843,
			779.502,
			783.423,
			785.993,
			788.529,
			793.497,
			794.717,
			798.325,
			800.697,
			804.212,
			806.545,
			808.864,
			812.342,
			814.65,
			818.148,
			819.312,
			820.483,
			823.995,
			825.169,
			826.355,
			829.839,
			832.098,
			835.44,
			839.742,
			840.799,
			843.921,
			845.956,
			847.956,
			850.898,
			852.798,
			856.542,
			857.464,
			860.199,
			861.991,
			864.635,
			866.384,
			868.991,
			870.71,
			871.591,
			873.344,
			875.97,
			877.769,
			879.566,
			881.366,
			884.071,
			885.872,
			887.612,
			888.482,
			890.223,
			891.092,
			892.807,
			894.524,
			897.117,
			898.857,
			901.479,
			902.357,
			903.236,
			905.882,
			907.651,
			910.315,
			912.097,
			914.781,
			916.579,
			919.278,
			921.09,
			921.997,
			923.818,
			925.647,
			929.332,
			930.258,
			932.116,
			934.913,
			937.721,
			939.596,
			941.474,
			944.298,
			946.184,
			949.027,
			950.931,
			953.807,
			955.737,
			958.655,
			962.586,
			963.578,
			965.569,
			966.57,
			968.592,
			971.651,
			973.718,
			975.798,
			978.947,
			981.063,
			984.237,
			985.306,
			986.377,
			989.582,
			991.717,
			993.843,
			997.044,
			999.171,
			1003.435,
			1004.5,
			1007.692,
			1009.817,
			1012.98,
			1015.063,
			1018.137,
			1022.141,
			1023.134,
			1025.131,
			1028.096,
			1030.087,
			1033.088,
			1035.12,
			1038.187,
			1039.226,
			1040.266,
			1043.41,
			1045.549,
			1048.777,
			1053.112,
			1054.177,
			1057.385,
			1059.501,
			1061.612,
			1062.672,
			1063.727,
			1066.881,
			1068.997,
			1072.16,
			1076.304,
			1077.322,
			1080.326,
			1082.285,
			1084.229,
			1088.895,
			1091.634,
			1093.452,
			1096.147,
			1097.932,
			1100.615,
			1102.397,
			1105.072,
			1106.853,
			1109.523,
			1112.198,
			1113.088,
			1115.792,
			1119.425,
			1120.348,
			1122.197,
			1125,
			1127.838,
			1131.637,
			1134.484,
			1136.352,
			1139.163,
			1140.09,
			1141.018,
			1143.802,
			1145.643,
			1148.376,
			1150.204,
			1152.005,
			1152.905,
			1154.695,
			1155.581,
			1157.349,
			1159.999,
			1161.764,
			1163.533,
			1166.222,
			1168.031,
			1170.784,
			1171.715,
			1172.646,
			1175.48,
			1177.432,
			1179.382,
			1182.397,
			1184.407,
			1187.38,
			1188.365,
			1189.347,
			1193.138,
			1194.072,
			1196.787,
			1200.327,
			1202.909,
			1204.622,
			1205.476,
			1208.023,
			1209.712,
			1211.394,
			1213.898,
			1215.559,
			1218.034,
			1218.856,
			1219.676,
			1222.127,
			1223.753,
			1226.187,
			1227.809,
			1229.434,
			1231.9,
			1233.562,
			1236.932,
			1237.788,
			1240.361,
			1242.107,
			1244.752,
			1246.534,
			1248.335,
			1249.255,
			1251.987,
			1253.823,
			1255.671,
			1260.301,
			1263.116,
			1265.006,
			1267.865,
			1269.786,
			1272.689,
			1274.64,
			1277.585,
			1279.56,
			1280.552,
			1282.537,
			1285.531,
			1287.539,
			1289.557,
			1293.627,
			1294.652,
			1296.71,
			1299.814,
			1301.873,
			1302.895,
			1304.919,
			1306.91,
			1309.828,
			1311.728,
			1315.424,
			1316.326,
			1318.992,
			1320.715,
			1323.238,
			1324.886,
			1327.326,
			1329.738,
			1331.347,
			1333.77,
			1335.391,
			1337.019,
			1339.468,
			1341.105,
			1344.391,
			1345.214,
			1347.685,
			1349.34,
			1351.82,
			1355.141,
			1358.468,
			1359.307,
			1361.814,
			1363.503,
			1366.075,
			1367.782,
			1370.429,
			1372.187,
			1374.864,
			1376.649,
			1377.54,
			1379.323,
			1381.099,
			1383.722,
			1385.416,
			1387.941,
			1389.564,
			1391.972,
			1393.568,
			1395.939,
			1399.095,
			1399.887,
			1401.467,
			1402.257,
			1403.842,
			1405.445,
			1406.248,
			1407.052,
			1409.483,
			1411.119,
			1413.587,
			1415.203,
			1416.844,
			1417.67,
			1420.138,
			1421.786,
			1423.448,
			1425.954,
			1427.653,
			1430.202,
			1431.911,
			1434.481,
			1436.203,
			1438.787,
			1440.523,
			1443.135,
			1444.891,
			1447.568,
			1450.286,
			1452.138,
			1454.006,
			1456.869,
			1457.833,
			1458.801,
			1460.752,
			1463.705,
			1466.694,
			1468.705,
			1470.734,
			1473.813,
			1475.885,
			1480.065,
			1481.117,
			1484.293,
			1485.351,
			1486.403,
			1489.581,
			1491.694,
			1494.881,
			1496.996,
			1498.057,
			1500.189,
			1503.356,
			1505.47,
			1510.766,
			1512.9,
			1517.187,
			1518.262,
			1521.489,
			1523.643,
			1525.797,
			1529.033,
			1531.19,
			1535.529,
			1536.62,
			1540.993,
			1542.1,
			1545.437,
			1547.677,
			1551.058,
			1553.325,
			1555.606,
			1556.747,
			1559.036,
			1560.186,
			1562.488,
			1564.799,
			1568.286,
			1570.624,
			1574.153,
			1575.335,
			1576.519,
			1580.089,
			1582.483,
			1586.094,
			1590.945,
			1592.164,
			1595.837,
			1598.301,
			1600.775,
			1602.017,
			1603.263,
			1607.013,
			1609.521,
			1613.296,
			1615.819,
			1618.352,
			1619.622,
			1623.445,
			1626.006,
			1628.577,
			1632.45,
			1635.042,
			1640.251,
			1641.557,
			1645.476,
			1648.098,
			1652.045,
			1654.687,
			1658.661,
			1661.32,
			1662.653,
			1665.323,
			1669.342,
			1672.03,
			1674.726,
			1678.782,
			1680.137,
			1681.494,
			1686.938,
			1688.302,
			1691.038,
			1695.153,
			1697.904,
			1702.043,
			1704.81,
			1710.364,
			1711.756,
			1715.944,
			1718.744,
			1722.956,
			1725.771,
			1728.594,
			1730.007,
			1732.839,
			1737.099,
			1741.376,
			1744.234,
			1747.1,
			1751.41,
			1754.292,
			1758.624,
			1760.072,
			1761.521,
			1764.422,
			1765.875,
			1768.783,
			1773.156,
			1776.078,
			1779.006,
			1783.435,
			1784.902,
			1786.347,
			1790.761,
			1793.708,
			1798.138,
			1801.098,
			1805.553,
			1810.033,
			1813.03,
			1816.033,
			1817.537,
			1820.551,
			1823.57,
			1828.115,
			1831.159,
			1835.752,
			1837.287,
			1838.824,
			1843.442,
			1846.524,
			1851.159,
			1854.28,
			1857.38,
			1862.014,
			1863.57,
			1865.129,
			1869.849,
			1872.995,
			1877.714,
			1880.863,
			1885.573,
			1891.852,
			1893.425,
			1898.157,
			1901.312,
			1904.469,
			1912.375,
			1917.128,
			1920.301,
			1925.066,
			1926.656,
			1928.246,
			1933.023,
			1936.208,
			1940.989,
			1945.774,
			1948.966,
			1953.761,
			1956.964,
			1960.169,
			1966.593,
			1968.2,
			1971.419,
			1974.64,
			1976.25,
			1981.081,
			1984.3,
			1987.521,
			1992.354,
			1995.573,
			2000.408,
			2002.021,
			2003.631,
			2008.461,
			2011.674,
			2016.474,
			2022.798,
			2024.359,
			2027.445,
			2031.996,
			2036.475,
			2039.439,
			2042.415,
			2046.82,
			2049.741,
			2054.086,
			2056.997,
			2061.365,
			2064.277,
			2067.195,
			2071.583,
			2074.512,
			2078.913,
			2081.854,
			2086.272,
			2089.222,
			2093.658,
			2096.624,
			2101.084,
			2107.043,
			2108.535,
			2113.02,
			2116.017,
			2119.02,
			2125.038,
			2126.545,
			2131.071,
			2132.58,
			2134.09,
			2138.613,
			2141.638,
			2146.097,
			2149.035,
			2153.38,
			2157.618,
			2159.021,
			2163.171,
			2164.536,
			2165.892,
			2169.904,
			2172.539,
			2176.448,
			2179.035,
			2181.628,
			2182.923,
			2186.858,
			2189.477,
			2192.118,
			2196.07,
			2198.693,
			2202.635,
			2203.951,
			2205.268,
			2209.218,
			2211.835,
			2215.731,
			2218.298,
			2222.099,
			2224.596,
			2225.834,
			2227.067,
			2231.959,
			2233.173,
			2236.801,
			2239.207,
			2241.609,
			2242.808,
			2245.203,
			2247.599,
			2251.19,
			2252.389,
			2254.783,
			2257.181,
			2260.788,
			2263.197,
			2266.822,
			2268.031,
			2269.242,
			2272.887,
			2275.326,
			2279.006,
			2283.943,
			2285.183,
			2287.669,
			2288.914,
			2291.412,
			2293.897,
			2297.694,
			2300.225,
			2304.043,
			2306.602,
			2309.174,
			2313.05,
			2316.95,
			2319.566,
			2322.192,
			2326.176,
			2328.849,
			2332.881,
			2334.224,
			2335.568,
			2342.271,
			2346.265,
			2348.921,
			2352.899,
			2356.89,
			2359.56,
			2362.213,
			2363.553,
			2366.238,
			2368.952,
			2372.984,
			2374.342,
			2375.701,
			2379.79,
			2381.157,
			2382.526,
			2386.641,
			2389.392,
			2393.53,
			2396.297,
			2400.46,
			2403.243,
			2404.636,
			2406.031,
			2410.226,
			2411.626,
			2413.029,
			2417.245,
			2420.062,
			2422.886,
			2424.301,
			2427.133,
			2429.971,
			2431.393,
			2435.669,
			2438.529,
			2441.396,
			2445.712,
			2448.601,
			2452.957,
			2454.415,
			2455.875,
			2460.281,
			2463.227,
			2467.659,
			2473.593,
			2475.078,
			2478.043,
			2482.488,
			2486.935,
			2489.91,
			2492.893,
			2500.371,
			2503.362,
			2507.847,
			2510.84,
			2512.339,
			2515.345,
			2518.357,
			2522.891,
			2525.921,
			2530.477,
			2533.521,
			2538.1,
			2541.158,
			2545.754,
			2548.825,
			2551.9,
			2553.439,
			2556.524,
			2561.164,
			2565.816,
			2568.924,
			2572.037,
			2576.716,
			2579.841,
			2584.537,
			2586.107,
			2587.678,
			2590.824,
			2592.4,
			2595.552,
			2598.706,
			2603.445,
			2606.611,
			2612.952,
			2614.539,
			2619.299,
			2622.474,
			2627.24,
			2630.422,
			2633.609,
			2635.204,
			2639.997,
			2643.199,
			2646.408,
			2648.016,
			2651.237,
			2654.465,
			2659.324,
			2662.574,
			2667.471,
			2670.754,
			2675.688,
			2678.978,
			2683.905,
			2687.185,
			2690.461,
			2695.378,
			2698.662,
			2703.593,
			2705.236,
			2706.88,
			2711.816,
			2715.112,
			2720.065,
			2726.688,
			2728.347,
			2733.333,
			2736.664,
			2739.999,
			2745.011,
			2748.36,
			2753.391,
			2756.754,
			2761.812,
			2765.193,
			2770.274,
			2773.664,
			2778.754,
			2783.854,
			2787.26,
			2790.671,
			2792.379,
			2795.798,
			2799.223,
			2804.371,
			2807.81,
			2811.254,
			2816.428,
			2821.615,
			2825.078,
			2828.546,
			2833.76,
			2837.243,
			2842.483,
			2845.988,
			2851.259,
			2854.785,
			2860.091,
			2863.637,
			2868.958,
			2872.506,
			2877.838,
			2883.184,
			2886.758,
			2890.34,
			2899.32,
			2902.922,
			2906.528,
			2908.333,
			2911.942,
			2913.748,
			2917.361,
			2922.792,
			2926.423,
			2930.061,
			2935.524,
			2939.168,
			2944.641,
			2948.299,
			2953.799,
			2957.475,
			2963.005,
			2972.289,
			2975.993,
			2981.514,
			2985.226,
			2990.81,
			2994.536,
			3000.118,
			3001.979,
			3003.838,
			3009.424,
			3013.153,
			3018.752,
			3022.486,
			3026.22,
			3031.825,
			3033.695,
			3035.566,
			3043.059,
			3044.934,
			3050.561,
			3054.315,
			3059.954,
			3067.483,
			3069.366,
			3075.016,
			3078.786,
			3082.556,
			3088.217,
			3091.995,
			3097.665,
			3099.556,
			3101.447,
			3107.121,
			3116.574,
			3120.357,
			3126.036,
			3129.824,
			3135.506,
			3139.29,
			3141.179,
			3143.065,
			3148.692,
			3152.412,
			3156.099,
			3157.929,
			3161.568,
			3165.184,
			3166.984,
			3172.37,
			3175.955,
			3179.536,
			3184.88,
			3188.428,
			3193.745,
			3195.516,
			3197.285,
			3202.589,
			3206.126,
			3211.442,
			3214.993,
			3220.327,
			3225.672,
			3229.245,
			3234.616,
			3238.195,
			3241.774,
			3247.145,
			3250.73,
			3254.32,
			3257.915,
			3259.713,
			3265.116,
			3268.723,
			3272.333,
			3277.755,
			3281.374,
			3286.81,
			3288.624,
			3290.438,
			3295.887,
			3299.525,
			3304.99,
			3308.636,
			3312.283,
			3314.106,
			3317.745,
			3319.559,
			3323.173,
			3328.543,
			3332.109,
			3335.607,
			3340.756,
			3344.192,
			3349.231,
			3352.512,
			3357.4,
			3360.609,
			3365.376,
			3368.488,
			3371.564,
			3373.088,
			3376.108,
			3379.072,
			3383.502,
			3386.413,
			3390.74,
			3393.571,
			3397.761,
			3400.5,
			3403.243,
			3404.608,
			3407.333,
			3408.695,
			3411.416,
			3414.135,
			3418.231,
			3420.946,
			3425.014,
			3426.368,
			3427.72,
			3431.753,
			3434.45,
			3438.491,
			3441.183,
			3445.254,
			3447.962,
			3452.044,
			3456.15,
			3458.9,
			3461.64,
			3467.193,
			3468.587,
			3471.401,
			3474.199,
			3475.6,
			3479.812,
			3482.636,
			3486.867,
			3489.716,
			3492.571,
			3496.891,
			3499.765,
			3504.069,
			3505.516,
			3506.963,
			3511.314,
			3514.22,
			3518.615,
			3521.538,
			3525.936,
			3528.853,
			3533.279,
			3536.238,
			3539.226,
			3543.684,
			3548.157,
			3551.122,
			3554.12,
			3557.149,
			3558.655,
			3563.154,
			3566.179,
			3569.212,
			3576.819,
			3581.399,
			3584.46,
			3589.063,
			3592.141,
			3596.766,
			3599.851,
			3604.455,
			3609.011,
			3612.009,
			3614.97,
			3616.436,
			3619.338,
			3622.202,
			3626.428,
			3627.819,
			3629.201,
			3633.296,
			3634.644,
			3635.982,
			3639.941,
			3642.535,
			3645.092,
			3648.861,
			3651.329,
			3654.965,
			3656.16,
			3657.346,
			3660.857,
			3663.156,
			3666.534,
			3668.741,
			3670.913,
			3671.986,
			3676.192,
			3677.221,
			3680.257,
			3682.238,
			3684.184,
			3687.038,
			3688.912,
			3691.647,
			3692.545,
			3693.438,
			3696.079,
			3697.816,
			3700.393,
			3702.097,
			3703.798,
			3707.2,
			3708.899,
			3711.451,
			3712.3,
			3714.846,
			3716.559,
			3719.123,
			3720.843,
			3722.581,
			3723.449,
			3726.061,
			3727.824,
			3729.584,
			3732.216,
			3733.963,
			3736.583,
			3737.452,
			3738.321,
			3740.923,
			3742.658,
			3745.252,
			3746.987,
			3749.597,
			3751.334,
			3752.216,
			3755.783,
			3756.673,
			3758.532,
			3760.414,
			3763.245,
			3765.147,
			3767.049,
			3769.81,
			3771.626,
			3772.536,
			3774.339,
			3776.131,
			3780.656,
			3784.323,
			3785.255,
			3788.061,
			3789.952,
			3792.823,
			3794.756,
			3797.699,
			3800.665,
			3802.652,
			3804.652,
			3805.638,
			3807.608,
			3809.59,
			3812.554,
			3813.544,
			3814.538,
			3816.537,
			3818.545,
			3819.553,
			3822.588,
			3824.619,
			3826.655,
			3829.702,
			3831.736,
			3835.788,
			3836.801,
			3839.801,
			3841.801,
			3844.785,
			3846.772,
			3848.786,
			3849.795,
			3851.813,
			3853.838,
			3854.853,
			3857.894,
			3859.917,
			3861.94,
			3864.988,
			3867.032,
			3870.101,
			3871.138,
			3872.178,
			3875.312,
			3877.41,
			3880.58,
			3882.71,
			3885.926,
			3888.088,
			3889.173,
			3890.26,
			3894.639,
			3895.74,
			3899.061,
			3903.527,
			3904.651,
			3906.906,
			3910.313,
			3912.596,
			3916.044,
			3918.357,
			3921.849,
			3924.19,
			3928.906,
			3930.092,
			3933.663,
			3936.059,
			3939.669,
			3942.089,
			3944.519,
			3945.738,
			3948.183,
			3949.41,
			3951.87,
			3955.579,
			3958.065,
			3960.561,
			3964.324,
			3966.845,
			3969.376,
			3971.917,
			3973.192,
			3975.748,
			3977.03,
			3979.6,
			3982.18,
			3986.068,
			3988.671,
			3992.582,
			3993.887,
			3995.195,
			3999.131,
			4005.734,
			4008.391,
			4012.39,
			4015.067,
			4016.409,
			4019.102,
			4021.801,
			4023.155,
			4025.866,
			4028.59,
			4032.69,
			4035.434,
			4039.567,
			4040.948,
			4042.332,
			4046.497,
			4049.29,
			4053.498,
			4056.34,
			4059.171,
			4063.442,
			4064.872,
			4066.305,
			4072.057,
			4073.499,
			4077.838,
			4080.746,
			4083.666,
			4085.134,
			4088.073,
			4089.545,
			4092.496,
			4096.942,
			4099.92,
			4102.882,
			4107.379,
			4110.362,
			4114.847,
			4116.345,
			4117.846,
			4122.324,
			4125.33,
			4129.844,
			4132.882,
			4135.9,
			4140.438,
			4143.471,
			4149.535,
			4151.062,
			4155.681,
			4158.755,
			4163.385,
			4169.593,
			4171.124,
			4175.807,
			4178.938,
			4182.081,
			4186.815,
			4189.991,
			4194.768,
			4196.365,
			4197.955,
			4202.756,
			4205.915,
			4210.698,
			4213.896,
			4217.12,
			4221.893,
			4223.52,
			4226.724,
			4229.928,
			4231.533,
			4233.136,
			4237.919,
			4241.125,
			4245.93,
			4252.372,
			4253.979,
			4258.819,
			4262.057,
			4265.3,
			4270.147,
			4273.41,
			4278.332,
			4280.014,
			4281.643,
			4286.654,
			4289.943,
			4294.908,
			4298.213,
			4303.174,
			4309.813,
			4311.477,
			4316.469,
			4319.802,
			4323.14,
			4328.156,
			4329.831,
			4331.507,
			4336.548,
			4338.231,
			4339.916,
			4344.981,
			4348.367,
			4351.758,
			4356.859,
			4360.268,
			4365.393,
			4367.104,
			4368.817,
			4373.962,
			4377.4,
			4382.568,
			4386.021,
			4389.479,
			4391.211,
			4398.162,
			4399.905,
			4405.145,
			4408.645,
			4412.147,
			4417.409,
			4420.925,
			4426.241,
			4427.976,
			4429.742,
			4435.051,
			4438.597,
			4442.177,
			4447.485,
			4452.842,
			4458.223,
			4460.021,
			4467.226,
			4469.024,
			4474.418,
			4478.048,
			4481.656,
			4483.433,
			4487.046,
			4492.474,
			4496.102,
			4497.917,
			4501.551,
			4505.19,
			4514.315,
			4519.809,
			4521.643,
			4523.476,
			4528.986,
			4532.667,
			4541.899,
			4547.474,
			4551.207,
			4553.108,
			4556.816,
			4560.581,
			4566.145,
			4569.88,
			4573.623,
			4579.28,
			4583.032,
			4586.786,
			4588.664,
			4594.272,
			4598.04,
			4601.845,
			4607.517,
			4611.306,
			4616.984,
			4620.77,
			4626.464,
			4630.272,
			4635.994,
			4639.783,
			4645.56,
			4649.393,
			4651.307,
			4655.128,
			4660.849,
			4664.663,
			4668.479,
			4674.19,
			4676.084,
			4677.978,
			4681.761,
			4687.395,
			4691.162,
			4696.788,
			4700.509,
			4706.068,
			4709.701,
			4715.042,
			4718.574,
			4723.804,
			4727.242,
			4732.321,
			4735.655,
			4738.946,
			4740.575,
			4746.983,
			4748.56,
			4753.229,
			4756.319,
			4759.346,
			4763.794,
			4766.731,
			4771.065,
			4773.906,
			4778.097,
			4780.847,
			4784.917,
			4787.62,
			4791.589,
			4794.237,
			4795.559,
			4796.877,
			4802.119,
			4803.421,
			4807.312,
			4809.896,
			4813.764,
			4816.34,
			4818.957,
			4820.233,
			4822.871,
			4824.188,
			4826.825,
			4829.459,
			4833.448,
			4836.077,
			4839.944,
			4841.213,
			4842.475,
			4846.223,
			4848.691,
			4852.353,
			4854.801,
			4858.477,
			4860.909,
			4864.558,
			4866.988,
			4868.185,
			4869.404,
			4873.07,
			4875.522,
			4877.982,
			4879.215,
			4884.162,
			4885.403,
			4889.136,
			4891.632,
			4894.136,
			4900.396,
			4904.117,
			4905.345,
			4906.565,
			4910.177,
			4912.542,
			4916.029,
			4918.311,
			4921.668,
			4924.95,
			4927.115,
			4930.271,
			4932.362,
			4934.462,
			4937.575,
			4938.609,
			4939.646,
			4942.766,
			4944.841,
			4946.934,
			4947.979,
			4950.069,
			4952.155,
			4955.28,
			4957.375,
			4960.524,
			4962.628,
			4965.78,
			4967.879,
			4971.016,
			4973.071,
			4975.113,
			4976.131,
			4979.165,
			4981.175,
			4984.179,
			4986.173,
			4988.159,
			4991.124,
			4993.085,
			4996.002,
			4996.972,
			4997.941,
			5000.83,
			5002.752,
			5005.622,
			5007.533,
			5009.446,
			5013.28,
			5014.245,
			5018.117,
			5019.089,
			5022.017,
			5023.955,
			5026.867,
			5028.787,
			5030.688,
			5031.627,
			5033.48,
			5034.393,
			5036.207,
			5037.997,
			5040.614,
			5042.342,
			5045.696,
			5046.516,
			5048.94,
			5050.507,
			5052.765,
			5054.216,
			5056.322,
			5057.65,
			5059.571,
			5060.823,
			5061.434,
			5062.625,
			5063.797,
			5064.954,
			5066.673,
			5068.948,
			5069.516,
			5071.214,
			5072.347,
			5073.477,
			5076.288,
			5078.533,
			5079.091,
			5080.762,
			5081.874,
			5083.552,
			5084.67,
			5086.342,
			5087.455,
			5089.137,
			5090.808,
			5091.918,
			5093.032,
			5094.699,
			5095.805,
			5097.466,
			5098.583,
			5099.714,
			5100.28,
			5101.412,
			5102.548,
			5104.274,
			5105.44,
			5107.789,
			5108.381,
			5110.215,
			5111.455,
			5113.333,
			5114.642,
			5116.021,
			5116.715,
			5120.257,
			5121.72,
			5123.944,
			5125.385,
			5127.527,
			5128.976,
			5131.11,
			5131.824,
			5132.542,
			5134.718,
			5136.177,
			5138.387,
			5139.868,
			5141.346,
			5145.043,
			5148.001,
			5148.74,
			5150.957,
			5152.454,
			5154.69,
			5156.179,
			5157.742,
			5158.521,
			5160.077,
			5162.473,
			5164.141,
			5168.311,
			5170.811,
			5171.645,
			5172.453,
			5174.782,
			5178.639,
			5180.156,
			5182.436,
			5183.968,
			5184.738,
			5186.281,
			5188.606,
			5190.176,
			5191.751,
			5193.333,
			5194.926,
			5195.725,
			5197.328,
			5199.75,
			5202.173,
			5205.438,
			5207.89,
			5209.538,
			5212.025,
			5212.856,
			5213.687,
			5216.178,
			5217.852,
			5220.385,
			5222.092,
			5224.686,
			5227.312,
			5229.086,
			5231.771,
			5233.581,
			5235.402,
			5238.16,
			5239.085,
			5240.014,
			5242.819,
			5243.761,
			5244.707,
			5246.592,
			5249.469,
			5251.406,
			5254.337,
			5256.312,
			5259.299,
			5260.303,
			5261.309,
			5264.346,
			5266.385,
			5269.465,
			5271.527,
			5274.643,
			5279.856,
			5281.944,
			5282.992,
			5285.095,
			5287.21,
			5290.404,
			5292.551,
			5295.797,
			5296.885,
			5297.978,
			5301.271,
			5303.48,
			5306.815,
			5309.048,
			5311.292,
			5314.675,
			5316.942,
			5320.365,
			5321.512,
			5322.661,
			5326.126,
			5327.287,
			5328.45,
			5331.956,
			5336.663,
			5337.846,
			5342.603,
			5343.797,
			5347.399,
			5349.813,
			5352.238,
			5355.894,
			5358.344,
			5363.273,
			5364.512,
			5368.243,
			5370.743,
			5374.512,
			5377.037,
			5380.846,
			5383.398,
			5385.965,
			5391.143,
			5392.448,
			5396.382,
			5399.018,
			5401.654,
			5402.973,
			5405.607,
			5409.548,
			5412.187,
			5413.508,
			5416.159,
			5418.824,
			5422.839,
			5425.525,
			5430.92,
			5432.273,
			5436.349,
			5439.078,
			5443.193,
			5445.926,
			5450.029,
			5452.76,
			5454.127,
			5456.864,
			5460.98,
			5463.732,
			5466.492,
			5470.646,
			5473.426,
			5479.006,
			5480.405,
			5484.613,
			5487.426,
			5490.247,
			5494.49,
			5497.33,
			5501.604,
			5503.033,
			5504.463,
			5508.768,
			5511.645,
			5515.972,
			5518.862,
			5523.209,
			5526.115,
			5527.571,
			5530.489,
			5534.88,
			5537.817,
			5540.763,
			5545.197,
			5548.164,
			5552.61,
			5554.094,
			5555.577,
			5560.033,
			5563.01,
			5567.487,
			5573.486,
			5578.005,
			5579.515,
			5581.027,
			5585.575,
			5588.621,
			5593.2,
			5596.258,
			5600.846,
			5603.909,
			5606.977,
			5608.513,
			5613.129,
			5616.214,
			5619.305,
			5623.954,
			5625.506,
			5627.06,
			5631.729,
			5633.289,
			5639.54,
			5642.671,
			5647.378,
			5650.521,
			5655.244,
			5658.4,
			5661.563,
			5667.908,
			5669.498,
			5674.274,
			5677.461,
			5680.649,
			5682.244,
			5688.638,
			5690.239,
			5693.473,
			5698.268,
			5701.49,
			5706.334,
			5709.573,
			5716.068,
			5717.696,
			5722.591,
			5725.858,
			5730.765,
			5734.038,
			5737.315,
			5738.955,
			5742.236,
			5743.878,
			5747.161,
			5750.447,
			5752.09,
			5755.375,
			5758.652,
			5761.912,
			5766.78,
			5769.971,
			5773.133,
			5774.702,
			5777.795,
			5779.346,
			5782.431,
			5785.486,
			5790.01,
			5793.009,
			5797.409,
			5798.861,
			5800.306,
			5804.594,
			5807.418,
			5811.625,
			5814.423,
			5818.629,
			5821.44,
			5822.847,
			5825.658,
			5828.461,
			5829.858,
			5832.639,
			5835.402,
			5839.508,
			5842.238,
			5846.315,
			5847.676,
			5849.037,
			5853.13,
			5855.87,
			5862.808,
			5865.602,
			5869.81,
			5872.598,
			5876.737,
			5878.099,
			5879.456,
			5883.493,
			5886.153,
			5888.793,
			5890.106,
			5892.684,
			5895.235,
			5896.519,
			5900.24,
			5902.672,
			5905.075,
			5908.633,
			5910.976,
			5915.62,
			5916.775,
			5920.243,
			5922.55,
			5926.016,
			5928.329,
			5931.813,
			5934.146,
			5935.316,
			5937.662,
			5941.197,
			5942.381,
			5945.945,
			5948.335,
			5950.715,
			5951.918,
			5956.764,
			5957.981,
			5961.651,
			5964.108,
			5966.576,
			5970.291,
			5972.778,
			5977.777,
			5979.033,
			5982.813,
			5985.342,
			5989.147,
			5991.685,
			5995.484,
			5997.997,
			5999.244,
			6004.155,
			6006.556,
			6010.09,
			6012.401,
			6015.8,
			6018.02,
			6020.2,
			6021.277,
			6024.451,
			6026.518,
			6028.549,
			6031.527,
			6033.467,
			6037.241,
			6038.164,
			6042.713,
			6045.389,
			6047.157,
			6049.784,
			6052.395,
			6054.117,
			6055.834,
			6056.691,
			6058.41,
			6060.132,
			6062.709,
			6063.571,
			6064.455,
			6067.099,
			6068.861,
			6071.59,
			6073.415,
			6075.259,
			6078.049,
			6079.908,
			6083.69,
			6084.635,
			6087.399,
			6089.231,
			6091.978,
			6095.63,
			6096.544,
			6098.365,
			6101.099,
			6103.834,
			6105.659,
			6107.488,
			6110.241,
			6112.083,
			6114.857,
			6115.785,
			6116.714,
			6119.511,
			6121.375,
			6124.189,
			6126.066,
			6127.948,
			6131.713,
			6132.66,
			6136.477,
			6137.438,
			6140.332,
			6142.27,
			6144.209,
			6145.178,
			6147.114,
			6149.047,
			6150.01,
			6151.931,
			6152.896,
			6154.828,
			6156.759,
			6159.703,
			6161.66,
			6164.629,
			6165.624,
			6166.618,
			6169.638,
			6171.671,
			6174.761,
			6176.833,
			6178.917,
			6179.964,
			6182.064,
			6183.116,
			6185.206,
			6187.304,
			6188.347,
			6190.396,
			6192.452,
			6194.502,
			6197.577,
			6199.64,
			6202.752,
			6205.882,
			6207.966,
			6210.057,
			6215.32,
			6218.519,
			6220.667,
			6223.916,
			6226.079,
			6229.375,
			6231.585,
			6234.921,
			6237.156,
			6238.276,
			6240.525,
			6242.784,
			6246.192,
			6248.477,
			6253.074,
			6254.228,
			6257.7,
			6260.028,
			6263.538,
			6265.892,
			6268.257,
			6274.197,
			6277.727,
			6280.044,
			6283.457,
			6285.687,
			6288.969,
			6291.116,
			6293.229,
			6294.273,
			6296.335,
			6298.363,
			6299.363,
			6302.311,
			6304.234,
			6306.127,
			6308.902,
			6310.718,
			6313.383,
			6315.125,
			6317.692,
			6319.361,
			6321.84,
			6323.483,
			6325.949,
			6327.599,
			6328.426,
			6329.255,
			6332.579,
			6333.413,
			6335.927,
			6339.297,
			6340.144,
			6343.573,
			6344.434,
			6347.046,
			6348.823,
			6350.598,
			6353.307,
			6355.135,
			6357.885,
			6358.819,
			6359.751,
			6362.543,
			6364.414,
			6367.214,
			6369.096,
			6370.988,
			6371.933,
			6373.819,
			6376.636,
			6379.444,
			6383.147,
			6385.006,
			6387.796,
			6391.56,
			6392.514,
			6394.418,
			6395.37,
			6397.251,
			6399.124,
			6401.922,
			6403.761,
			6406.509,
			6408.331,
			6411.05,
			6412.861,
			6415.577,
			6420.079,
			6421.862,
			6422.754,
			6424.533,
			6426.275,
			6427.146,
			6428.892,
			6430.63,
			6433.218,
			6434.945,
			6437.551,
			6438.422,
			6439.293,
			6441.93,
			6443.701,
			6445.479,
			6448.183,
			6450.002,
			6453.697,
			6454.632,
			6457.446,
			6458.398,
			6459.353,
			6462.237,
			6464.179,
			6467.117,
			6469.087,
			6470.077,
			6472.067,
			6475.073,
			6477.095,
			6479.131,
			6483.245,
			6484.283,
			6487.411,
			6488.461,
			6489.513,
			6494.774,
			6497.958,
			6500.095,
			6502.242,
			6505.484,
			6507.659,
			6510.944,
			6513.149,
			6516.478,
			6518.713,
			6522.087,
			6524.352,
			6527.769,
			6531.212,
			6535.844,
			6539.347,
			6541.696,
			6545.238,
			6548.803,
			6551.189,
			6553.586,
			6554.788,
			6557.198,
			6558.408,
			6560.835,
			6563.271,
			6566.945,
			6569.409,
			6574.392,
			6575.637,
			6579.367,
			6581.842,
			6585.525,
			6590.356,
			6591.547,
			6595.079,
			6596.241,
			6599.686,
			6600.821,
			6601.952,
			6605.324,
			6607.537,
			6610.88,
			6613.085,
			6615.277,
			6616.37,
			6619.635,
			6621.805,
			6623.971,
			6627.213,
			6629.373,
			6632.615,
			6633.699,
			6634.782,
			6638.031,
			6640.202,
			6643.463,
			6645.653,
			6648.95,
			6651.173,
			6652.287,
			6654.538,
			6658.01,
			6660.359,
			6662.777,
			6667.633,
			6668.847,
			6673.651,
			6674.838,
			6678.386,
			6680.738,
			6683.079,
			6686.629,
			6689.026,
			6692.578,
			6693.774,
			6694.963,
			6698.562,
			6700.933,
			6704.54,
			6706.941,
			6709.349,
			6710.556,
			6714.218,
			6716.673,
			6720.409,
			6722.923,
			6725.458,
			6731.889,
			6734.516,
			6737.199,
			6738.534,
			6742.514,
			6745.166,
			6749.068,
			6751.67,
			6754.243,
			6758.142,
			6759.447,
			6760.756,
			6766.03,
			6767.35,
			6771.319,
			6773.976,
			6777.949,
			6780.624,
			6784.673,
			6787.367,
			6791.42,
			6794.132,
			6798.212,
			6800.946,
			6805.051,
			6806.418,
			6807.782,
			6811.852,
			6814.536,
			6818.486,
			6821.093,
			6823.665,
			6827.459,
			6828.726,
			6829.963,
			6834.822,
			6836.017,
			6839.549,
			6841.865,
			6845.279,
			6847.515,
			6849.725,
			6850.821,
			6852.99,
			6854.062,
			6856.187,
			6858.282,
			6861.369,
			6863.393,
			6866.368,
			6867.345,
			6868.314,
			6871.177,
			6873.051,
			6875.821,
			6877.65,
			6880.387,
			6882.208,
			6883.117,
			6884.936,
			6887.653,
			6889.461,
			6891.266,
			6894.864,
			6895.764,
			6897.559,
			6900.256,
			6902.958,
			6904.763,
			6906.578,
			6909.307,
			6911.136,
			6913.895,
			6914.82,
			6915.749,
			6918.552,
			6920.437,
			6923.291,
			6925.212,
			6928.128,
			6931.063,
			6933.054,
			6936.088,
			6938.121,
			6940.18,
			6943.295,
			6945.381,
			6948.533,
			6950.659,
			6953.87,
			6956.044,
			6959.309,
			6961.493,
			6963.688,
			6966.951,
			6968.034,
			6969.12,
			6973.457,
			6974.545,
			6977.83,
			6980.032,
			6982.248,
			6983.36,
			6986.71,
			6988.954,
			6991.208,
			6994.611,
			6996.892,
			7000.332,
			7002.638,
			7006.109,
			7008.432,
			7011.92,
			7014.245,
			7017.724,
			7020.035,
			7022.343,
			7023.495,
			7025.797,
			7028.102,
			7032.724,
			7035.046,
			7037.358,
			7039.697,
			7043.226,
			7045.59,
			7047.964,
			7049.154,
			7052.739,
			7055.143,
			7057.555,
			7061.195,
			7063.635,
			7068.546,
			7069.78,
			7073.498,
			7075.988,
			7079.737,
			7082.244,
			7086.02,
			7088.548,
			7089.814,
			7092.358,
			7096.199,
			7097.486,
			7101.363,
			7106.566,
			7107.872,
			7110.487,
			7114.414,
			7117.041,
			7118.356,
			7120.996,
			7123.644,
			7127.631,
			7130.299,
			7134.317,
			7135.661,
			7137.008,
			7141.056,
			7143.755,
			7147.789,
			7150.453,
			7153.092,
			7154.4,
			7156.988,
			7158.268,
			7160.801,
			7164.533,
			7166.977,
			7169.387,
			7172.941,
			7175.267,
			7178.692,
			7179.816,
			7180.931,
			7184.221,
			7186.373,
			7188.49,
			7193.637,
			7196.626,
			7197.607,
			7198.58,
			7201.467,
			7203.343,
			7206.115,
			7207.945,
			7210.673,
			7212.479,
			7214.278,
			7215.175,
			7217.855,
			7219.639,
			7221.426,
			7225.933,
			7229.617,
			7230.545,
			7233.356,
			7235.251,
			7237.145,
			7239.999,
			7241.897,
			7244.719,
			7246.583,
			7249.329,
			7250.222,
			7251.106,
			7253.703,
			7255.376,
			7257.811,
			7259.391,
			7260.926,
			7261.681,
			7263.894,
			7265.326,
			7266.724,
			7268.76,
			7270.079,
			7273.266,
			7275.131,
			7276.381,
			7278.278,
			7279.537,
			7281.438,
			7282.729,
			7283.374,
			7284.663,
			7286.606,
			7287.943,
			7289.283,
			7291.298,
			7291.972,
			7292.656,
			7294.047,
			7296.125,
			7298.215,
			7299.636,
			7301.052,
			7303.176,
			7304.604,
			7306.737,
			7307.445,
			7308.152,
			7310.264,
			7311.651,
			7313.714,
			7315.094,
			7316.477,
			7317.162,
			7318.526,
			7320.598,
			7322.759,
			7324.19,
			7325.624,
			7327.17,
			7329.478,
			7331.782,
			7332.547,
			7333.311,
			7335.6,
			7337.108,
			7339.314,
			7340.786,
			7342.261,
			7344.219,
			7344.873,
			7345.528,
			7347.504,
			7348.847,
			7350.881,
			7352.245,
			7353.629,
			7354.343,
			7356.502,
			7357.957,
			7359.418,
			7360.15,
			7361.612,
			7363.103,
			7365.375,
			7366.93,
			7369.275,
			7370.864,
			7373.282,
			7374.913,
			7377.406,
			7380.791,
			7384.314,
			7385.202,
			7388.813,
			7389.736,
			7392.524,
			7394.368,
			7397.148,
			7399.003,
			7400.818,
			7401.73,
			7404.479,
			7406.311,
			7408.152,
			7410.929,
			7412.787,
			7416.512,
			7417.442,
			7420.24,
			7422.109,
			7424.918,
			7426.801,
			7429.636,
			7432.497,
			7434.427,
			7437.344,
			7438.327,
			7441.299,
			7443.295,
			7445.306,
			7446.315,
			7448.343,
			7451.415,
			7454.513,
			7456.574,
			7458.658,
			7461.782,
			7463.874,
			7467.038,
			7469.167,
			7472.384,
			7474.548,
			7477.815,
			7480.008,
			7482.212,
			7483.318,
			7485.539,
			7487.77,
			7488.889,
			7492.26,
			7496.792,
			7500.221,
			7502.522,
			7504.834,
			7507.158,
			7508.325,
			7510.667,
			7514.198,
			7516.566,
			7520.14,
			7522.534,
			7526.148,
			7527.357,
			7528.57,
			7532.224,
			7534.672,
			7538.365,
			7544.57,
			7547.071,
			7548.325,
			7550.843,
			7553.372,
			7557.181,
			7559.733,
			7563.577,
			7564.863,
			7566.151,
			7571.324,
			7572.622,
			7576.533,
			7579.143,
			7583.069,
			7585.697,
			7588.334,
			7592.304,
			7593.631,
			7594.961,
			7598.963,
			7600.302,
			7601.642,
			7605.676,
			7608.377,
			7611.109,
			7612.466,
			7615.185,
			7617.908,
			7619.27,
			7623.33,
			7628.759,
			7632.837,
			7635.537,
			7639.561,
			7640.913,
			7642.265,
			7646.351,
			7649.073,
			7653.182,
			7655.934,
			7658.706,
			7660.097,
			7662.887,
			7664.289,
			7667.101,
			7671.317,
			7672.735,
			7679.858,
			7684.152,
			7687.016,
			7689.884,
			7691.319,
			7694.186,
			7698.489,
			7702.778,
			7708.432,
			7712.6,
			7713.972,
			7715.333,
			7719.365,
			7722.009,
			7725.913,
			7728.485,
			7732.279,
			7735.996,
			7738.421,
			7740.803,
			7744.31,
			7746.601,
			7749.97,
			7752.173,
			7754.341,
			7756.474,
			7757.527,
			7760.636,
			7762.664,
			7764.659,
			7767.604,
			7769.517,
			7773.263,
			7774.186,
			7776.93,
			7778.738,
			7781.42,
			7783.2,
			7785.862,
			7788.524,
			7790.312,
			7792.122,
			7793.028,
			7794.847,
			7796.701,
			7801.4,
			7804.298,
			7805.265,
			7809.16,
			7811.118,
			7814.068,
			7816.048,
			7818.026,
			7821.017,
			7823.016,
			7826,
			7827.964,
			7830.909,
			7834.732,
			7835.687,
			7837.588,
			7839.47,
			7840.41,
			7845.087,
			7846.945,
			7849.729,
			7851.573,
			7854.336,
			7855.256,
			7856.178,
			7858.933,
			7860.759,
			7863.504,
			7865.326,
			7867.144,
			7868.057,
			7869.875,
			7870.782,
			7872.59,
			7875.297,
			7876.195,
			7878.886,
			7880.675,
			7883.357,
			7886.926,
			7887.818,
			7890.5,
			7892.294,
			7894.097,
			7898.651,
			7901.427,
			7902.358,
			7903.295,
			7906.127,
			7908.029,
			7910.918,
			7912.862,
			7915.81,
			7917.814,
			7918.821,
			7920.847,
			7922.889,
			7923.915,
			7925.98,
			7928.068,
			7930.174,
			7933.356,
			7937.645,
			7938.723,
			7941.958,
			7944.119,
			7946.271,
			7949.515,
			7951.696,
			7956.082,
			7957.185,
			7960.467,
			7962.647,
			7965.886,
			7968.018,
			7971.106,
			7973.106,
			7974.088,
			7976.011,
			7978.818,
			7980.635,
			7982.445,
			7985.148,
			7986.932,
			7989.583,
			7990.461,
			7991.336,
			7993.074,
			7993.936,
			7995.652,
			7998.221,
			7999.929,
			8002.491,
			8004.213,
			8005.074,
			8005.935,
			8008.526,
			8010.268,
			8012.901,
			8014.659,
			8016.467,
			8017.378,
			8019.198,
			8021.048,
			8021.988,
			8024.804,
			8026.703,
			8028.617,
			8029.573,
			8031.485,
			8033.401,
			8036.265,
			8038.134,
			8040.923,
			8042.734,
			8045.415,
			8047.19,
			8049.767,
			8051.479,
			8054.029,
			8055.719,
			8056.562,
			8057.406,
			8060.764,
			8061.603,
			8064.115,
			8065.788,
			8068.307,
			8069.994,
			8071.682,
			8072.529,
			8075.083,
			8076.821,
			8078.569,
			8081.216,
			8083.002,
			8085.701,
			8086.604,
			8087.509,
			8090.238,
			8092.055,
			8096.663,
			8099.446,
			8101.314,
			8104.136,
			8106.03,
			8108.9,
			8110.829,
			8113.742,
			8115.699,
			8117.665,
			8119.642,
			8120.632,
			8123.621,
			8125.627,
			8127.64,
			8130.678,
			8132.717,
			8135.793,
			8137.857,
			8140.976,
			8143.064,
			8146.195,
			8148.285,
			8151.434,
			8153.541,
			8156.718,
			8159.908,
			8162.046,
			8164.193,
			8167.433,
			8169.604,
			8172.88,
			8175.074,
			8178.382,
			8180.6,
			8183.944,
			8186.187,
			8188.439,
			8191.834,
			8194.108,
			8197.539,
			8199.838,
			8203.302,
			8205.623,
			8207.953,
			8209.121,
			8213.816,
			8217.363,
			8220.929,
			8223.315,
			8226.914,
			8229.326,
			8232.959,
			8234.176,
			8235.394,
			8239.062,
			8241.519,
			8245.223,
			8250.193,
			8253.944,
			8255.199,
			8257.717,
			8260.244,
			8261.511,
			8262.78,
			8266.602,
			8269.16,
			8273.015,
			8275.595,
			8279.48,
			8282.081,
			8283.385,
			8285.994,
			8289.913,
			8292.533,
			8295.161,
			8299.115,
			8300.438,
			8301.761,
			8305.743,
			8308.408,
			8312.422,
			8315.107,
			8319.151,
			8321.855,
			8323.209,
			8325.923,
			8328.643,
			8330.006,
			8332.736,
			8335.474,
			8340.969,
			8342.348,
			8345.112,
			8347.884,
			8349.271,
			8353.443,
			8356.229,
			8359.018,
			8363.21,
			8366.012,
			8370.229,
			8371.637,
			8373.048,
			8377.29,
			8380.124,
			8384.384,
			8387.229,
			8391.508,
			8395.797,
			8398.662,
			8402.97,
			8405.85,
			8408.737,
			8414.554,
			8416.014,
			8421.851,
			8423.312,
			8427.685,
			8430.604,
			8433.529,
			8437.926,
			8440.865,
			8445.286,
			8448.241,
			8452.684,
			8455.652,
			8460.116,
			8463.104,
			8466.098,
			8467.598,
			8470.603,
			8473.609,
			8475.115,
			8478.135,
			8479.648,
			8482.68,
			8485.723,
			8490.287,
			8493.334,
			8499.432,
			8500.959,
			8505.548,
			8508.613,
			8513.22,
			8516.297,
			8519.378,
			8520.92,
			8524.006,
			8525.551,
			8527.098,
			8534.861,
			8539.585,
			8542.734,
			8547.48,
			8550.659,
			8555.448,
			8558.649,
			8560.253,
			8563.47,
			8566.697,
			8571.549,
			8574.751,
			8579.576,
			8581.203,
			8582.803,
			8587.546,
			8590.674,
			8595.333,
			8598.423,
			8603.062,
			8606.153,
			8607.673,
			8610.759,
			8615.374,
			8618.447,
			8621.52,
			8624.622,
			8627.702,
			8629.244,
			8632.33,
			8635.417,
			8636.937,
			8641.571,
			8644.665,
			8647.762,
			8652.441,
			8655.529,
			8660.211,
			8661.775,
			8663.342,
			8668.03,
			8671.141,
			8675.758,
			8678.798,
			8683.287,
			8686.232,
			8687.689,
			8690.576,
			8694.836,
			8697.63,
			8700.388,
			8704.455,
			8705.793,
			8707.121,
			8711.053,
			8713.629,
			8717.428,
			8719.914,
			8722.367,
			8725.984,
			8728.356,
			8733.001,
			8734.137,
			8737.492,
			8739.677,
			8742.888,
			8744.983,
			8747.044,
			8748.062,
			8750.086,
			8751.077,
			8753.031,
			8755.895,
			8757.757,
			8759.585,
			8762.277,
			8764.047,
			8766.665,
			8767.527,
			8768.387,
			8770.951,
			8772.65,
			8775.186,
			8776.873,
			8778.575,
			8779.429,
			8781.135,
			8783.694,
			8786.266,
			8787.121,
			8789.688,
			8791.411,
			8793.99,
			8795.71,
			8797.439,
			8798.304,
			8800.03,
			8802.628,
			8804.362,
			8806.964,
			8808.708,
			8811.324,
			8813.074,
			8815.748,
			8817.534,
			8820.244,
			8822.068,
			8824.816,
			8826.658,
			8827.581,
			8829.432,
			8831.295,
			8833.191,
			8836.045,
			8839.938,
			8840.918,
			8842.885,
			8845.865,
			8848.854,
			8850.86,
			8852.867,
			8855.877,
			8857.879,
			8861.838,
			8862.802,
			8865.689,
			8867.589,
			8870.4,
			8872.271,
			8875.072,
			8876.93,
			8877.856,
			8879.71,
			8882.478,
			8884.313,
			8886.14,
			8888.864,
			8889.771,
			8890.674,
			8893.375,
			8895.174,
			8896.977,
			8897.879,
			8899.682,
			8901.489,
			8904.218,
			8906.044,
			8908.806,
			8909.734,
			8910.664,
			8914.396,
			8915.353,
			8918.191,
			8922.016,
			8922.978,
			8926.854,
			8927.83,
			8929.79,
			8932.744,
			8934.723,
			8937.712,
			8939.719,
			8943.752,
			8944.774,
			8947.853,
			8949.94,
			8953.065,
			8955.13,
			8957.205,
			8961.377,
			8962.424,
			8965.578,
			8967.698,
			8970.896,
			8973.041,
			8976.275,
			8978.442,
			8981.707,
			8984.992,
			8987.19,
			8989.398,
			8992.73,
			8994.962,
			8999.46,
			9000.59,
			9003.993,
			9006.272,
			9009.707,
			9012.008,
			9015.473,
			9017.794,
			9021.29,
			9023.631,
			9024.805,
			9027.156,
			9029.518,
			9031.885,
			9034.26,
			9035.451,
			9037.838,
			9040.232,
			9041.434,
			9045.049,
			9047.468,
			9049.905,
			9053.574,
			9056.038,
			9059.745,
			9060.981,
			9062.22,
			9065.941,
			9068.426,
			9072.17,
			9074.683,
			9077.203,
			9078.467,
			9081,
			9084.807,
			9088.62,
			9091.166,
			9093.721,
			9097.556,
			9100.119,
			9103.967,
			9105.254,
			9106.541,
			9109.121,
			9110.414,
			9113.006,
			9116.893,
			9119.49,
			9122.096,
			9126.016,
			9128.637,
			9133.898,
			9135.217,
			9139.185,
			9141.838,
			9145.828,
			9148.496,
			9152.511,
			9156.538,
			9159.23,
			9161.929,
			9165.987,
			9168.699,
			9172.773,
			9174.133,
			9175.495,
			9179.586,
			9180.951,
			9182.318,
			9186.426,
			9189.172,
			9191.922,
			9196.058,
			9198.821,
			9202.977,
			9204.363,
			9205.753,
			9211.32,
			9212.715,
			9216.907,
			9222.52,
			9223.926,
			9229.569,
			9230.986,
			9235.24,
			9238.088,
			9240.938,
			9248.084,
			9253.824,
			9255.263,
			9259.582,
			9262.483,
			9266.794,
			9269.662,
			9273.93,
			9276.786,
			9278.213,
			9281.065,
			9285.342,
			9286.769,
			9291.071,
			9293.901,
			9296.762,
			9298.192,
			9301.057,
			9305.391,
			9308.271,
			9309.715,
			9312.607,
			9315.507,
			9319.844,
			9322.756,
			9328.59,
			9330.049,
			9334.457,
			9337.383,
			9341.779,
			9344.718,
			9349.142,
			9353.563,
			9356.567,
			9361.057,
			9364.059,
			9367.067,
			9371.596,
			9374.631,
			9377.67,
			9380.713,
			9382.235,
			9386.789,
			9389.827,
			9392.868,
			9397.408,
			9400.453,
			9405.039,
			9406.559,
			9408.079,
			9412.64,
			9415.687,
			9420.268,
			9423.33,
			9427.93,
			9430.978,
			9432.516,
			9435.593,
			9438.674,
			9440.216,
			9443.328,
			9446.421,
			9451.069,
			9454.158,
			9458.848,
			9460.42,
			9461.993,
			9466.725,
			9469.887,
			9473.029,
			9477.736,
			9480.864,
			9485.587,
			9487.154,
			9488.723,
			9493.434,
			9496.554,
			9501.282,
			9504.44,
			9507.603,
			9509.186,
			9512.354,
			9515.526,
			9517.113,
			9521.879,
			9528.238,
			9533.015,
			9536.203,
			9542.583,
			9544.178,
			9548.961,
			9552.146,
			9556.925,
			9560.107,
			9564.911,
			9568.096,
			9569.688,
			9572.869,
			9577.643,
			9579.229,
			9583.967,
			9591.908,
			9595.089,
			9599.861,
			9603.042,
			9604.631,
			9607.808,
			9610.973,
			9618.796,
			9623.419,
			9624.951,
			9626.48,
			9631.059,
			9634.107,
			9638.686,
			9641.744,
			9646.339,
			9649.406,
			9650.941,
			9654.015,
			9657.096,
			9658.639,
			9661.729,
			9664.826,
			9671.031,
			9672.585,
			9675.693,
			9680.362,
			9685.038,
			9688.158,
			9691.283,
			9695.979,
			9699.112,
			9703.824,
			9706.971,
			9711.697,
			9714.853,
			9719.589,
			9722.748,
			9727.492,
			9730.66,
			9732.27,
			9735.438,
			9740.17,
			9743.372,
			9746.55,
			9751.326,
			9754.519,
			9759.34,
			9760.956,
			9762.571,
			9767.486,
			9770.756,
			9775.736,
			9779.099,
			9782.437,
			9787.432,
			9790.728,
			9797.372,
			9799.016,
			9803.914,
			9807.129,
			9811.979,
			9816.837,
			9820.112,
			9823.366,
			9824.996,
			9828.256,
			9831.496,
			9836.407,
			9839.688,
			9844.62,
			9846.267,
			9847.914,
			9852.876,
			9856.188,
			9861.155,
			9864.459,
			9869.415,
			9872.72,
			9877.685,
			9882.656,
			9884.315,
			9889.297,
			9892.621,
			9897.644,
			9904.277,
			9905.943,
			9910.971,
			9914.279,
			9917.62,
			9922.639,
			9925.986,
			9932.693,
			9934.371,
			9942.785,
			9947.848,
			9951.226,
			9956.295,
			9959.676,
			9961.367,
			9964.756,
			9968.149,
			9969.874,
			9973.239,
			9976.633,
			9983.433,
			9985.135,
			9991.945,
			9993.646,
			9998.742,
			10002.136,
			10005.527,
			10010.616,
			10014.012,
			10020.812,
			10022.514,
			10027.62,
			10031.026,
			10036.14,
			10039.552,
			10042.965,
			10044.674,
			10048.095,
			10049.805,
			10053.231,
			10058.374,
			10061.805,
			10065.236,
			10070.385,
			10073.817,
			10078.968,
			10080.686,
			10082.403,
			10087.561,
			10091.001,
			10096.166,
			10099.611,
			10103.06,
			10108.235,
			10111.688,
			10118.599,
			10120.327,
			10125.517,
			10132.443,
			10134.176,
			10137.644,
			10141.113,
			10142.849,
			10146.322,
			10148.059,
			10151.531,
			10155.005,
			10160.217,
			10163.691,
			10168.905,
			10170.644,
			10172.383,
			10177.603,
			10181.086,
			10186.314,
			10189.802,
			10193.292,
			10195.037,
			10198.531,
			10203.771,
			10207.267,
			10209.015,
			10210.764,
			10216.013,
			10219.516,
			10224.772,
			10228.278,
			10233.541,
			10237.049,
			10238.805,
			10242.313,
			10245.824,
			10251.091,
			10254.604,
			10259.873,
			10261.63,
			10263.387,
			10268.656,
			10272.172,
			10277.446,
			10280.964,
			10286.239,
			10289.759,
			10295.04,
			10298.561,
			10300.322,
			10303.846,
			10307.371,
			10314.424,
			10316.188,
			10323.248,
			10325.014,
			10330.313,
			10337.387,
			10342.697,
			10346.239,
			10351.584,
			10353.356,
			10355.128,
			10360.447,
			10363.964,
			10369.284,
			10372.833,
			10376.415,
			10378.193,
			10383.537,
			10387.11,
			10392.496,
			10396.085,
			10399.671,
			10405.007,
			10408.58,
			10413.941,
			10415.757,
			10417.539,
			10422.865,
			10426.385,
			10429.888,
			10435.136,
			10438.639,
			10443.857,
			10445.591,
			10447.277,
			10452.414,
			10455.826,
			10460.965,
			10464.342,
			10467.744,
			10469.475,
			10476.283,
			10477.988,
			10481.396,
			10483.102,
			10486.485,
			10489.904,
			10495.066,
			10498.463,
			10503.599,
			10505.312,
			10507.051,
			10512.158,
			10515.583,
			10520.729,
			10524.164,
			10527.601,
			10529.322,
			10532.765,
			10536.214,
			10541.406,
			10543.139,
			10544.883,
			10550.118,
			10553.615,
			10558.854,
			10565.834,
			10567.572,
			10572.783,
			10576.252,
			10579.721,
			10584.93,
			10588.404,
			10595.352,
			10597.088,
			10602.301,
			10605.777,
			10610.995,
			10614.474,
			10619.691,
			10623.166,
			10624.901,
			10628.367,
			10633.545,
			10636.972,
			10640.371,
			10643.735,
			10648.706,
			10651.971,
			10655.194,
			10656.793,
			10661.56,
			10664.674,
			10667.731,
			10672.295,
			10675.303,
			10679.764,
			10681.256,
			10682.71,
			10686.99,
			10688.392,
			10689.777,
			10693.86,
			10696.578,
			10700.581,
			10703.231,
			10707.195,
			10711.149,
			10713.783,
			10716.406,
			10720.328,
			10722.936,
			10726.84,
			10729.453,
			10733.385,
			10736.054,
			10738.727,
			10745.529,
			10749.644,
			10750.992,
			10752.338,
			10756.374,
			10759.049,
			10763.055,
			10765.722,
			10769.726,
			10772.398,
			10773.737,
			10776.418,
			10779.103,
			10780.445,
			10783.135,
			10785.829,
			10789.879,
			10792.586,
			10796.653,
			10798.012,
			10799.37,
			10803.448,
			10806.17,
			10810.256,
			10812.986,
			10815.723,
			10819.833,
			10821.204,
			10823.949,
			10826.664,
			10828.033,
			10829.404,
			10833.533,
			10836.275,
			10840.407,
			10843.15,
			10847.325,
			10851.543,
			10854.35,
			10857.164,
			10861.396,
			10864.2,
			10868.441,
			10869.855,
			10871.27,
			10875.509,
			10878.34,
			10882.593,
			10885.443,
			10889.73,
			10894.031,
			10896.9,
			10901.213,
			10902.652,
			10906.975,
			10909.862,
			10912.754,
			10914.201,
			10917.101,
			10919.998,
			10921.449,
			10928.724,
			10931.646,
			10936.039,
			10938.977,
			10943.39,
			10944.862,
			10946.336,
			10950.761,
			10953.717,
			10958.16,
			10961.127,
			10965.592,
			10970.064,
			10973.053,
			10977.547,
			10980.548,
			10983.554,
			10988.069,
			10991.085,
			10995.617,
			10997.129,
			10998.643,
			11003.188,
			11006.224,
			11009.262,
			11013.826,
			11016.872,
			11021.447,
			11022.974,
			11024.502,
			11029.091,
			11032.154,
			11036.754,
			11044.436,
			11050.595,
			11052.135,
			11055.217,
			11056.757,
			11059.83,
			11062.9,
			11067.494,
			11070.55,
			11075.126,
			11076.65,
			11078.176,
			11082.755,
			11085.809,
			11090.396,
			11093.444,
			11096.505,
			11105.404,
			11108.295,
			11109.726,
			11111.143,
			11115.326,
			11118.061,
			11122.083,
			11124.719,
			11127.318,
			11128.604,
			11132.409,
			11134.918,
			11137.366,
			11140.953,
			11143.324,
			11146.853,
			11148.027,
			11149.203,
			11152.746,
			11155.139,
			11158.773,
			11161.221,
			11164.914,
			11167.406,
			11168.656,
			11171.166,
			11174.961,
			11177.498,
			11180.039,
			11185.125,
			11186.396,
			11188.942,
			11192.726,
			11196.472,
			11198.947,
			11201.428,
			11205.149,
			11207.622,
			11212.615,
			11213.873,
			11217.666,
			11220.203,
			11224.037,
			11226.564,
			11230.398,
			11234.244,
			11236.813,
			11240.676,
			11243.259,
			11245.848,
			11249.741,
			11252.341,
			11256.242,
			11257.549,
			11258.857,
			11261.479,
			11265.428,
			11268.069,
			11274.695,
			11278.682,
			11280.013,
			11281.346,
			11285.354,
			11288.035,
			11292.065,
			11297.448,
			11298.796,
			11304.186,
			11305.537,
			11309.59,
			11312.299,
			11315.014,
			11319.096,
			11321.826,
			11325.932,
			11327.303,
			11328.675,
			11332.799,
			11335.555,
			11339.699,
			11342.464,
			11346.607,
			11349.354,
			11350.721,
			11352.081,
			11356.123,
			11357.455,
			11358.777,
			11362.699,
			11365.281,
			11369.113,
			11371.653,
			11374.193,
			11375.461,
			11379.258,
			11381.799,
			11384.309,
			11388.041,
			11390.485,
			11394.125,
			11395.327,
			11397.705,
			11400.047,
			11403.502,
			11405.76,
			11409.066,
			11410.148,
			11411.221,
			11414.371,
			11416.413,
			11419.405,
			11421.338,
			11424.17,
			11426.016,
			11426.921,
			11428.709,
			11431.329,
			11433.03,
			11434.691,
			11437.114,
			11437.904,
			11438.688,
			11440.232,
			11442.523,
			11444.77,
			11446.258,
			11447.741,
			11449.957,
			11451.433,
			11453.645,
			11454.379,
			11455.113,
			11457.314,
			11458.789,
			11461.001,
			11462.469,
			11463.937,
			11464.671,
			11466.922,
			11468.418,
			11469.912,
			11470.657,
			11472.174,
			11473.711,
			11476.006,
			11477.53,
			11480.6,
			11481.364,
			11483.652,
			11485.175,
			11486.698,
			11490.482,
			11492.756,
			11494.285,
			11496.566,
			11498.083,
			11500.359,
			11501.873,
			11504.132,
			11505.638,
			11507.147,
			11507.9,
			11510.152,
			11511.649,
			11513.147,
			11515.432,
			11516.954,
			11519.234,
			11519.995,
			11523.139,
			11524.714,
			11527.081,
			11528.66,
			11531.029,
			11533.404,
			11534.196,
			11536.516,
			11538.062,
			11540.392,
			11541.945,
			11543.502,
			11544.283,
			11545.848,
			11548.212,
			11550.596,
			11552.192,
			11553.8,
			11556.232,
			11557.865,
			11560.333,
			11561.165,
			11562.001,
			11564.525,
			11566.224,
			11568.809,
			11570.553,
			11572.307,
			11573.189,
			11574.952,
			11577.604,
			11580.28,
			11581.18,
			11583.865,
			11585.666,
			11588.391,
			11592.022,
			11592.938,
			11595.711,
			11597.574,
			11599.449,
			11602.286,
			11604.191,
			11608.057,
			11609.034,
			11611.98,
			11613.961,
			11616.936,
			11618.944,
			11621.98,
			11627.092,
			11630.211,
			11632.289,
			11634.36,
			11637.51,
			11639.61,
			11642.764,
			11643.819,
			11644.893,
			11648.053,
			11650.178,
			11652.308,
			11655.515,
			11657.662,
			11660.896,
			11661.976,
			11663.059,
			11666.318,
			11667.406,
			11668.498,
			11671.787,
			11673.989,
			11677.323,
			11679.525,
			11680.639,
			11682.874,
			11686.242,
			11688.505,
			11690.777,
			11694.236,
			11695.398,
			11696.563,
			11701.296,
			11702.489,
			11705.999,
			11708.345,
			11710.672,
			11714.184,
			11717.736,
			11720.114,
			11721.306,
			11722.5,
			11726.095,
			11727.297,
			11728.5,
			11732.12,
			11734.541,
			11736.972,
			11738.188,
			11740.627,
			11743.072,
			11744.298,
			11747.984,
			11750.45,
			11752.924,
			11756.648,
			11759.142,
			11762.898,
			11765.411,
			11769.203,
			11771.739,
			11775.562,
			11778.12,
			11780.685,
			11781.971,
			11784.544,
			11785.835,
			11788.42,
			11792.312,
			11794.914,
			11797.521,
			11800.135,
			11804.054,
			11806.65,
			11810.502,
			11813.027,
			11814.275,
			11816.74,
			11819.17,
			11822.741,
			11825.074,
			11828.503,
			11829.625,
			11830.738,
			11834.012,
			11836.146,
			11839.279,
			11841.318,
			11844.311,
			11846.263,
			11849.124,
			11850.985,
			11853.713,
			11855.488,
			11858.085,
			11859.77,
			11863.03,
			11863.827,
			11866.189,
			11867.762,
			11869.346,
			11871.741,
			11873.341,
			11876.537,
			11877.347,
			11879.74,
			11881.321,
			11883.671,
			11885.244,
			11887.609,
			11890.723,
			11891.505,
			11893.896,
			11895.472,
			11897.042,
			11899.425,
			11901.01,
			11904.969,
			11907.42,
			11909.044,
			11911.541,
			11913.328,
			11915.987,
			11917.721,
			11918.561,
			11920.23,
			11921.891,
			11922.717,
			11923.52,
			11925.829,
			11927.344,
			11929.613,
			11931.138,
			11932.645,
			11934.881,
			11935.624,
			11937.205,
			11938.771,
			11941.109,
			11942.68,
			11945.148,
			11945.961,
			11946.773,
			11949.195,
			11950.747,
			11953.049,
			11954.576,
			11956.11,
			11956.891,
			11958.438,
			11959.206,
			11962.274,
			11964.598,
			11966.133,
			11967.665,
			11969.963,
			11971.459,
			11972.947,
			11973.691,
			11975.924,
			11977.422,
			11978.911,
			11981.146,
			11982.644,
			11985.852,
			11986.662,
			11989.1,
			11990.911,
			11993.898,
			11995.898,
			11998.872,
			12000.854,
			12001.849,
			12003.694,
			12005.432,
			12006.304,
			12008.057,
			12009.77,
			12013.104,
			12013.944,
			12015.633,
			12018.125,
			12020.638,
			12022.321,
			12023.992,
			12026.5,
			12028.193,
			12030.764,
			12031.627,
			12032.494,
			12035.127,
			12036.896,
			12039.594,
			12043.228,
			12044.171,
			12046.066,
			12048.932,
			12051.854,
			12053.832,
			12055.824,
			12058.821,
			12060.824,
			12063.857,
			12064.866,
			12065.875,
			12068.921,
			12070.95,
			12074.002,
			12076.066,
			12078.124,
			12081.203,
			12082.234,
			12083.27,
			12087.447,
			12088.496,
			12091.644,
			12093.755,
			12096.939,
			12101.221,
			12102.297,
			12105.541,
			12107.72,
			12109.908,
			12113.212,
			12115.43,
			12118.773,
			12119.892,
			12121.013,
			12124.392,
			12126.652,
			12130.061,
			12132.345,
			12135.789,
			12138.098,
			12139.256,
			12140.418,
			12145.089,
			12147.444,
			12149.812,
			12153.383,
			12155.779,
			12158.185,
			12161.805,
			12164.225,
			12167.848,
			12170.283,
			12173.947,
			12176.422,
			12180.095,
			12182.564,
			12186.285,
			12188.771,
			12192.514,
			12195.017,
			12198.787,
			12201.31,
			12205.116,
			12208.942,
			12211.511,
			12214.086,
			12216.672,
			12220.567,
			12223.17,
			12227.082,
			12230.996,
			12233.604,
			12236.22,
			12240.155,
			12242.786,
			12246.748,
			12249.397,
			12254.716,
			12256.048,
			12260.053,
			12262.73,
			12266.758,
			12269.451,
			12270.802,
			12273.506,
			12277.573,
			12280.293,
			12283.019,
			12287.117,
			12289.857,
			12293.979,
			12296.733,
			12300.877,
			12303.647,
			12307.815,
			12310.601,
			12313.393,
			12314.791,
			12319.018,
			12320.423,
			12326.037,
			12327.451,
			12331.704,
			12334.548,
			12337.396,
			12338.823,
			12344.544,
			12345.979,
			12348.854,
			12350.292,
			12353.201,
			12356.07,
			12360.416,
			12363.318,
			12367.697,
			12369.126,
			12370.579,
			12374.932,
			12377.826,
			12382.163,
			12385.021,
			12387.86,
			12389.271,
			12392.068,
			12396.18,
			12400.228,
			12402.898,
			12405.513,
			12408.102,
			12411.953,
			12417.077,
			12418.359,
			12422.211,
			12424.783,
			12427.358,
			12431.235,
			12433.823,
			12437.753,
			12439.068,
			12440.383,
			12444.349,
			12446.997,
			12450.939,
			12453.546,
			12457.424,
			12459.982,
			12461.259,
			12463.807,
			12466.337,
			12467.6,
			12470.123,
			12472.68,
			12476.541,
			12479.039,
			12482.767,
			12484.007,
			12485.221,
			12488.873,
			12491.322,
			12493.802,
			12497.532,
			12500.068,
			12505.193,
			12506.477,
			12510.337,
			12512.914,
			12516.787,
			12519.342,
			12521.876,
			12523.166,
			12525.673,
			12528.227,
			12529.499,
			12533.318,
			12535.884,
			12538.454,
			12542.313,
			12546.199,
			12548.793,
			12550.092,
			12551.393,
			12555.296,
			12557.902,
			12561.826,
			12564.449,
			12568.404,
			12572.375,
			12573.7,
			12577.686,
			12580.346,
			12584.323,
			12586.975,
			12589.626,
			12593.611,
			12596.271,
			12597.624,
			12601.59,
			12604.249,
			12606.91,
			12610.797,
			12613.365,
			12618.408,
			12619.639,
			12623.28,
			12625.657,
			12629.16,
			12631.453,
			12634.831,
			12637.053,
			12640.354,
			12642.539,
			12643.629,
			12645.805,
			12647.977,
			12650.146,
			12653.398,
			12655.563,
			12657.727,
			12658.809,
			12662.059,
			12664.225,
			12666.392,
			12671.911,
			12675.229,
			12676.334,
			12680.788,
			12681.917,
			12683.047,
			12686.436,
			12688.692,
			12692.086,
			12694.35,
			12695.483,
			12697.772,
			12701.259,
			12703.588,
			12705.954,
			12709.517,
			12710.627,
			12711.74,
			12715.083,
			12717.321,
			12720.695,
			12722.95,
			12725.228,
			12728.668,
			12730.957,
			12735.571,
			12736.719,
			12740.125,
			12742.354,
			12745.635,
			12747.736,
			12749.812,
			12750.835,
			12752.855,
			12755.849,
			12758.779,
			12760.723,
			12762.676,
			12765.591,
			12767.526,
			12770.41,
			12771.367,
			12772.321,
			12775.225,
			12777.157,
			12779.081,
			12781.948,
			12783.852,
			12786.697,
			12788.574,
			12791.311,
			12793.129,
			12795.852,
			12800.342,
			12803.883,
			12804.768,
			12806.542,
			12807.432,
			12809.216,
			12811.008,
			12813.713,
			12815.532,
			12818.299,
			12819.209,
			12820.139,
			12822.944,
			12824.841,
			12827.708,
			12829.632,
			12831.57,
			12832.545,
			12834.5,
			12837.455,
			12839.422,
			12840.41,
			12842.395,
			12844.389,
			12846.376,
			12849.361,
			12851.366,
			12853.365,
			12854.359,
			12857.304,
			12859.23,
			12861.123,
			12863.895,
			12865.697,
			12868.334,
			12870.05,
			12872.584,
			12874.24,
			12876.684,
			12878.317,
			12880.758,
			12882.381,
			12883.206,
			12884.829,
			12887.255,
			12888.059,
			12890.467,
			12892.074,
			12893.68,
			12894.482,
			12896.901,
			12897.716,
			12898.531,
			12900.166,
			12902.626,
			12905.098,
			12907.584,
			12909.247,
			12912.625,
			12913.475,
			12916.057,
			12917.797,
			12920.435,
			12924.027,
			12926.77,
			12927.693,
			12928.618,
			12931.416,
			12933.297,
			12936.143,
			12938.052,
			12940.938,
			12942.88,
			12943.854,
			12945.808,
			12947.77,
			12950.726,
			12952.697,
			12955.665,
			12957.621,
			12960.564,
			12961.549,
			12962.537,
			12965.549,
			12967.574,
			12970.654,
			12972.725,
			12974.805,
			12975.846,
			12977.936,
			12978.983,
			12980.031,
			12984.225,
			12985.27,
			12988.393,
			12990.446,
			12993.492,
			12997.508,
			12998.512,
			13001.529,
			13003.556,
			13005.572,
			13008.644,
			13010.706,
			13014.868,
			13015.913,
			13019.057,
			13021.166,
			13024.35,
			13026.484,
			13029.707,
			13031.876,
			13032.967,
			13035.153,
			13038.47,
			13039.58,
			13042.936,
			13045.188,
			13047.457,
			13048.595,
			13053.15,
			13054.289,
			13059.958,
			13062.215,
			13065.592,
			13067.842,
			13071.212,
			13072.336,
			13073.48,
			13076.854,
			13079.096,
			13082.456,
			13084.668,
			13086.9,
			13088.018,
			13090.256,
			13093.641,
			13097.054,
			13099.363,
			13101.667,
			13105.127,
			13107.461,
			13109.811,
			13112.171,
			13113.357,
			13115.74,
			13119.336,
			13121.746,
			13125.376,
			13127.807,
			13131.457,
			13132.68,
			13133.9,
			13137.579,
			13140.047,
			13146.26,
			13150.017,
			13152.531,
			13156.322,
			13158.86,
			13160.132,
			13162.682,
			13165.242,
			13169.099,
			13171.682,
			13175.562,
			13176.852,
			13178.139,
			13181.996,
			13184.518,
			13187.013,
			13190.696,
			13193.121,
			13196.718,
			13197.908,
			13199.075,
			13202.623,
			13204.979,
			13208.514,
			13210.85,
			13214.341,
			13216.668,
			13220.163,
			13223.686,
			13226.043,
			13228.42,
			13232.008,
			13234.417,
			13238.051,
			13239.268,
			13240.485,
			13244.174,
			13246.651,
			13250.432,
			13252.959,
			13256.734,
			13259.231,
			13260.469,
			13262.941,
			13266.614,
			13267.835,
			13271.482,
			13273.904,
			13276.32,
			13277.527,
			13279.94,
			13283.554,
			13287.166,
			13289.562,
			13291.982,
			13295.631,
			13298.074,
			13301.757,
			13304.217,
			13307.891,
			13310.337,
			13313.925,
			13316.277,
			13318.576,
			13319.723,
			13321.994,
			13323.137,
			13325.357,
			13327.547,
			13330.775,
			13332.902,
			13336.063,
			13338.174,
			13340.287,
			13342.41,
			13343.477,
			13345.613,
			13346.684,
			13348.836,
			13350.992,
			13354.233,
			13356.395,
			13359.619,
			13360.688,
			13361.753,
			13364.924,
			13367.021,
			13370.154,
			13372.234,
			13375.354,
			13378.465,
			13380.542,
			13382.613,
			13383.648,
			13385.726,
			13387.801,
			13390.913,
			13392.988,
			13397.135,
			13398.174,
			13401.295,
			13403.387,
			13406.523,
			13408.645,
			13410.794,
			13415.144,
			13416.24,
			13419.59,
			13421.839,
			13425.21,
			13427.467,
			13429.665,
			13430.769,
			13432.984,
			13435.155,
			13436.254,
			13439.563,
			13441.783,
			13444.004,
			13447.348,
			13449.592,
			13454.059,
			13455.181,
			13458.549,
			13460.789,
			13464.148,
			13466.393,
			13468.654,
			13469.767,
			13472.078,
			13473.242,
			13474.408,
			13479.085,
			13480.256,
			13483.755,
			13486.077,
			13489.555,
			13491.869,
			13495.355,
			13498.851,
			13501.188,
			13503.532,
			13506.982,
			13509.291,
			13512.734,
			13513.856,
			13515.019,
			13518.465,
			13520.775,
			13524.275,
			13526.624,
			13530.176,
			13532.557,
			13533.75,
			13536.143,
			13538.539,
			13539.74,
			13542.125,
			13544.565,
			13548.216,
			13550.67,
			13553.116,
			13555.594,
			13556.837,
			13560.578,
			13563.085,
			13565.6,
			13569.39,
			13571.932,
			13575.76,
			13577.042,
			13578.328,
			13582.203,
			13584.803,
			13588.727,
			13591.355,
			13595.297,
			13597.918,
			13599.224,
			13601.829,
			13605.695,
			13608.26,
			13610.768,
			13614.457,
			13616.871,
			13620.405,
			13622.732,
			13626.175,
			13628.402,
			13630.588,
			13633.781,
			13635.857,
			13638.895,
			13639.888,
			13640.872,
			13643.786,
			13645.701,
			13648.534,
			13652.252,
			13653.172,
			13657.729,
			13659.54,
			13660.453,
			13662.279,
			13664.109,
			13666.896,
			13668.751,
			13671.554,
			13672.491,
			13673.43,
			13676.254,
			13678.143,
			13680.984,
			13682.885,
			13684.789,
			13687.659,
			13688.622,
			13689.586,
			13693.467,
			13694.447,
			13697.4,
			13699.379,
			13702.359,
			13704.353,
			13707.344,
			13709.348,
			13710.354,
			13712.371,
			13714.396,
			13717.465,
			13719.521,
			13722.623,
			13723.66,
			13724.695,
			13727.807,
			13729.878,
			13732.974,
			13735.027,
			13738.095,
			13741.117,
			13743.124,
			13745.121,
			13748.106,
			13750.106,
			13753.114,
			13754.12,
			13755.116,
			13757.15,
			13759.211,
			13760.221,
			13763.346,
			13765.431,
			13767.52,
			13770.699,
			13772.858,
			13776.072,
			13777.156,
			13778.242,
			13781.52,
			13783.717,
			13787.032,
			13789.268,
			13792.64,
			13794.909,
			13798.337,
			13801.777,
			13804.085,
			13806.395,
			13809.879,
			13812.21,
			13815.728,
			13816.902,
			13818.08,
			13821.629,
			13824.008,
			13826.397,
			13830.007,
			13832.418,
			13836.021,
			13837.213,
			13838.4,
			13841.914,
			13844.219,
			13847.617,
			13849.861,
			13852.057,
			13853.143,
			13855.29,
			13858.441,
			13863.575,
			13865.565,
			13868.506,
			13870.438,
			13874.262,
			13875.213,
			13878.059,
			13879.961,
			13882.827,
			13884.752,
			13887.65,
			13890.549,
			13891.516,
			13895.354,
			13896.316,
			13899.171,
			13901.049,
			13903.85,
			13905.701,
			13908.461,
			13911.192,
			13913.008,
			13914.82,
			13917.526,
			13919.328,
			13922.02,
			13923.813,
			13926.561,
			13928.385,
			13931.141,
			13932.976,
			13935.715,
			13937.53,
			13940.22,
			13941.959,
			13944.567,
			13946.271,
			13949.672,
			13950.53,
			13954.02,
			13954.896,
			13957.566,
			13961.169,
			13963.898,
			13965.723,
			13969.366,
			13970.274,
			13973.002,
			13974.803,
			13977.498,
			13979.299,
			13981.098,
			13981.997,
			13983.803,
			13984.709,
			13986.532,
			13989.287,
			13991.142,
			13993.025,
			13995.877,
			13997.838,
			14001.836,
			14002.857,
			14006.033,
			14008.158,
			14010.272,
			14013.458,
			14015.571,
			14018.711,
			14019.761,
			14020.807,
			14023.96,
			14026.077,
			14029.261,
			14031.38,
			14033.521,
			14034.593,
			14036.737,
			14038.878,
			14039.947,
			14042.078,
			14043.139,
			14045.24,
			14047.314,
			14050.356,
			14052.33,
			14055.227,
			14056.167,
			14057.101,
			14059.852,
			14061.649,
			14064.301,
			14067.794,
			14068.66,
			14072.112,
			14074.695,
			14075.554,
			14076.412,
			14078.982,
			14080.699,
			14083.283,
			14085.017,
			14086.762,
			14087.639,
			14090.302,
			14092.105,
			14093.922,
			14096.7,
			14098.584,
			14102.413,
			14103.393,
			14108.342,
			14111.369,
			14113.402,
			14116.465,
			14118.514,
			14121.619,
			14123.702,
			14124.747,
			14126.865,
			14129.001,
			14132.216,
			14134.352,
			14136.496,
			14138.635,
			14139.697,
			14142.896,
			14145.012,
			14147.119,
			14150.264,
			14152.32,
			14155.416,
			14156.467,
			14157.521,
			14160.688,
			14162.865,
			14166.209,
			14168.423,
			14171.666,
			14173.843,
			14174.902,
			14177.065,
			14180.341,
			14182.592,
			14184.874,
			14188.275,
			14190.569,
			14192.867,
			14195.168,
			14196.322,
			14198.639,
			14199.8,
			14202.13,
			14204.471,
			14210.361,
			14215.117,
			14216.311,
			14219.906,
			14222.317,
			14225.949,
			14230.814,
			14232.036,
			14236.938,
			14238.17,
			14240.662,
			14244.416,
			14246.932,
			14250.675,
			14253.124,
			14256.732,
			14259.076,
			14262.521,
			14264.79,
			14268.082,
			14272.4,
			14275.533,
			14276.572,
			14278.592,
			14280.577,
			14281.56,
			14282.535,
			14285.436,
			14287.336,
			14290.166,
			14292.047,
			14293.922,
			14294.858,
			14297.65,
			14299.522,
			14301.378,
			14304.15,
			14306.003,
			14308.765,
			14309.681,
			14310.616,
			14313.411,
			14315.268,
			14318.193,
			14320.148,
			14323.077,
			14326,
			14327.932,
			14330.679,
			14332.498,
			14334.303,
			14337.83,
			14338.707,
			14340.461,
			14343.088,
			14344.833,
			14345.705,
			14347.467,
			14349.227,
			14351.864,
			14353.623,
			14356.258,
			14358.016,
			14360.629,
			14362.373,
			14364.99,
			14366.732,
			14368.477,
			14369.351,
			14371.971,
			14373.717,
			14375.463,
			14378.064,
			14379.791,
			14382.377,
			14384.059,
			14387.409,
			14388.244,
			14390.686,
			14392.303,
			14393.909,
			14396.358,
			14397.998,
			14400.444,
			14402.135,
			14405.544,
			14406.397,
			14408.938,
			14410.636,
			14413.198,
			14414.902,
			14415.759,
			14417.481,
			14419.216,
			14420.093,
			14421.84,
			14423.614,
			14426.297,
			14427.195,
			14428.098,
			14430.822,
			14431.732,
			14432.646,
			14435.404,
			14440.05,
			14441.928,
			14444.754,
			14446.652,
			14448.562,
			14452.415,
			14453.389,
			14456.328,
			14458.304,
			14461.295,
			14463.303,
			14465.323,
			14466.338,
			14469.397,
			14471.45,
			14473.514,
			14476.627,
			14478.711,
			14481.842,
			14483.934,
			14487.091,
			14489.213,
			14492.416,
			14494.568,
			14497.819,
			14500,
			14501.096,
			14502.193,
			14506.606,
			14508.826,
			14511.057,
			14513.296,
			14515.546,
			14516.674,
			14521.208,
			14522.344,
			14524.614,
			14525.744,
			14527.996,
			14530.223,
			14533.502,
			14535.646,
			14538.803,
			14540.848,
			14543.855,
			14545.814,
			14548.691,
			14550.57,
			14553.327,
			14556.037,
			14557.819,
			14559.588,
			14560.462,
			14562.205,
			14563.945,
			14566.555,
			14568.289,
			14570.893,
			14571.761,
			14572.633,
			14575.25,
			14576.994,
			14578.742,
			14581.364,
			14583.109,
			14586.615,
			14587.49,
			14590.119,
			14591.934,
			14594.657,
			14596.473,
			14599.324,
			14601.226,
			14603.118,
			14604.051,
			14606.848,
			14608.711,
			14610.528,
			14615.054,
			14617.77,
			14618.676,
			14619.583,
			14622.324,
			14624.165,
			14626.008,
			14628.807,
			14631.64,
			14634.497,
			14635.461,
			14638.359,
			14640.293,
			14643.145,
			14645.053,
			14647.854,
			14651.544,
			14652.472,
			14655.293,
			14657.173,
			14659.088,
			14662.041,
			14664.013,
			14667.014,
			14668.007,
			14669.018,
			14672.076,
			14674.143,
			14677.261,
			14679.393,
			14682.614,
			14684.773,
			14685.865,
			14688.044,
			14691.252,
			14692.285,
			14695.382,
			14697.438,
			14700.412,
			14702.379,
			14704.367,
			14705.366,
			14708.377,
			14710.398,
			14712.453,
			14715.53,
			14717.597,
			14720.695,
			14722.781,
			14726.971,
			14728.02,
			14731.184,
			14733.281,
			14736.434,
			14738.528,
			14739.59,
			14741.718,
			14743.855,
			14744.948,
			14747.106,
			14749.275,
			14752.533,
			14753.631,
			14754.73,
			14758.044,
			14760.266,
			14763.616,
			14765.861,
			14771.518,
			14773.798,
			14777.236,
			14779.543,
			14784.187,
			14785.354,
			14788.873,
			14791.234,
			14794.795,
			14799.57,
			14800.766,
			14803.161,
			14804.379,
			14806.745,
			14810.303,
			14812.682,
			14815.042,
			14818.58,
			14820.939,
			14824.48,
			14825.662,
			14826.844,
			14830.385,
			14832.766,
			14836.375,
			14841.203,
			14846.071,
			14847.295,
			14852.214,
			14853.451,
			14857.176,
			14859.668,
			14862.167,
			14863.417,
			14868.436,
			14869.69,
			14872.208,
			14873.467,
			14875.968,
			14878.5,
			14882.331,
			14884.883,
			14888.722,
			14891.293,
			14895.165,
			14897.755,
			14901.661,
			14904.271,
			14906.889,
			14912.158,
			14913.481,
			14917.473,
			14920.146,
			14924.188,
			14926.893,
			14930.973,
			14936.459,
			14937.84,
			14942.018,
			14944.823,
			14947.637,
			14954.824,
			14959.125,
			14960.527,
			14961.927,
			14966.113,
			14968.904,
			14973.105,
			14975.916,
			14980.148,
			14982.98,
			14984.4,
			14987.25,
			14990.11,
			14991.543,
			14994.414,
			14997.295,
			15003.07,
			15004.519,
			15007.424,
			15011.798,
			15016.201,
			15019.15,
			15022.135,
			15026.59,
			15029.567,
			15034.012,
			15035.496,
			15036.979,
			15041.405,
			15042.866,
			15044.314,
			15048.608,
			15051.407,
			15054.166,
			15055.53,
			15058.215,
			15059.544,
			15062.174,
			15066.043,
			15068.605,
			15071.125,
			15074.827,
			15077.277,
			15080.885,
			15083.254,
			15086.749,
			15089.034,
			15091.283,
			15094.587,
			15096.742,
			15100.949,
			15101.986,
			15105.083,
			15107.164,
			15110.273,
			15112.338,
			15115.482,
			15117.588,
			15119.701,
			15120.762,
			15122.89,
			15123.958,
			15126.104,
			15128.258,
			15131.511,
			15133.691,
			15136.979,
			15138.081,
			15139.205,
			15142.521,
			15144.742,
			15148.077,
			15150.301,
			15152.525,
			15155.853,
			15156.955,
			15158.056,
			15161.35,
			15163.54,
			15166.806,
			15168.975,
			15172.227,
			15176.565,
			15177.654,
			15179.848,
			15180.947,
			15183.149,
			15185.37,
			15188.729,
			15190.985,
			15194.484,
			15195.652,
			15196.822,
			15200.392,
			15202.778,
			15206.311,
			15208.638,
			15212.102,
			15214.381,
			15217.781,
			15220.039,
			15221.16,
			15223.39,
			15225.591,
			15227.771,
			15231.008,
			15233.109,
			15236.249,
			15239.4,
			15241.501,
			15243.596,
			15246.783,
			15248.908,
			15253.129,
			15254.18,
			15257.33,
			15259.412,
			15262.537,
			15264.624,
			15266.715,
			15267.762,
			15269.858,
			15270.91,
			15273.023,
			15276.204,
			15278.338,
			15280.482,
			15283.715,
			15285.893,
			15290.277,
			15291.383,
			15294.738,
			15296.986,
			15299.25,
			15302.672,
			15304.964,
			15308.432,
			15309.591,
			15310.754,
			15314.254,
			15316.598,
			15320.119,
			15322.47,
			15324.828,
			15326.001,
			15328.328,
			15329.504,
			15334.222,
			15335.404,
			15337.774,
			15340.148,
			15343.723,
			15346.112,
			15350.896,
			15352.094,
			15355.689,
			15358.087,
			15361.666,
			15364.037,
			15366.388,
			15367.553,
			15369.867,
			15371.019,
			15372.168,
			15375.609,
			15377.901,
			15381.34,
			15383.629,
			15387.062,
			15391.638,
			15392.788,
			15395.09,
			15396.242,
			15398.579,
			15400.918,
			15404.439,
			15406.826,
			15410.438,
			15411.648,
			15412.844,
			15416.432,
			15418.832,
			15422.383,
			15424.751,
			15428.318,
			15430.7,
			15431.893,
			15433.086,
			15436.676,
			15437.876,
			15439.078,
			15442.695,
			15445.118,
			15447.553,
			15448.773,
			15453.681,
			15454.914,
			15458.625,
			15461.105,
			15463.594,
			15467.318,
			15469.823,
			15474.879,
			15476.143,
			15479.925,
			15482.473,
			15486.309,
			15488.883,
			15492.755,
			15495.342,
			15499.236,
			15503.135,
			15505.739,
			15508.387,
			15512.315,
			15513.637,
			15514.958,
			15517.609,
			15521.595,
			15525.594,
			15528.268,
			15530.949,
			15534.982,
			15537.68,
			15541.761,
			15543.117,
			15544.475,
			15548.535,
			15551.266,
			15555.367,
			15558.11,
			15560.859,
			15562.235,
			15565.017,
			15569.172,
			15573.345,
			15576.138,
			15578.938,
			15583.155,
			15585.974,
			15590.221,
			15593.033,
			15595.903,
			15597.324,
			15600.15,
			15603.012,
			15610.166,
			15614.444,
			15617.303,
			15622.946,
			15624.354,
			15628.582,
			15631.408,
			15635.637,
			15638.479,
			15642.752,
			15645.607,
			15647.037,
			15649.926,
			15652.8,
			15657.14,
			15660.023,
			15664.4,
			15665.864,
			15667.331,
			15671.736,
			15674.688,
			15679.129,
			15682.103,
			15685.082,
			15689.57,
			15691.068,
			15692.594,
			15698.613,
			15700.123,
			15704.66,
			15707.692,
			15712.252,
			15715.272,
			15718.325,
			15719.854,
			15724.473,
			15727.513,
			15730.579,
			15738.217,
			15742.754,
			15744.255,
			15745.748,
			15750.201,
			15753.109,
			15757.405,
			15760.229,
			15764.418,
			15767.186,
			15771.304,
			15775.387,
			15776.741,
			15780.778,
			15783.504,
			15786.199,
			15787.547,
			15790.246,
			15794.281,
			15798.32,
			15801.017,
			15803.717,
			15807.752,
			15810.484,
			15815.945,
			15817.315,
			15821.457,
			15824.23,
			15828.412,
			15831.211,
			15833.994,
			15835.404,
			15838.229,
			15842.489,
			15845.312,
			15846.729,
			15849.558,
			15852.391,
			15856.617,
			15859.447,
			15863.696,
			15866.519,
			15870.753,
			15873.571,
			15876.417,
			15880.649,
			15883.477,
			15889.134,
			15890.552,
			15894.816,
			15897.661,
			15901.93,
			15904.801,
			15909.16,
			15912.077,
			15916.496,
			15920.916,
			15923.864,
			15926.81,
			15931.213,
			15934.156,
			15938.578,
			15940.056,
			15941.533,
			15946.003,
			15948.97,
			15951.94,
			15956.4,
			15959.379,
			15963.838,
			15965.336,
			15966.835,
			15971.359,
			15974.385,
			15978.958,
			15981.995,
			15985.034,
			15986.551,
			15989.557,
			15994.075,
			15998.564,
			16001.5,
			16004.398,
			16008.673,
			16011.474,
			16015.598,
			16016.956,
			16018.307,
			16022.305,
			16024.972,
			16028.998,
			16034.361,
			16035.707,
			16038.406,
			16042.463,
			16046.532,
			16047.889,
			16051.967,
			16054.664,
			16058.748,
			16064.204,
			16065.571,
			16068.311,
			16072.431,
			16075.191,
			16079.365,
			16082.157,
			16086.362,
			16087.768,
			16089.174,
			16093.396,
			16096.219,
			16100.47,
			16103.314,
			16107.6,
			16110.464,
			16114.773,
			16119.101,
			16121.994,
			16124.896,
			16129.266,
			16132.187,
			16136.582,
			16138.05,
			16139.529,
			16143.983,
			16146.96,
			16149.942,
			16157.383,
			16161.843,
			16164.818,
			16169.294,
			16172.284,
			16176.781,
			16179.787,
			16184.307,
			16187.326,
			16188.838,
			16191.865,
			16194.897,
			16196.415,
			16199.456,
			16202.502,
			16207.079,
			16210.136,
			16214.729,
			16217.798,
			16225.49,
			16230.122,
			16233.215,
			16237.864,
			16240.97,
			16242.525,
			16244.08,
			16250.317,
			16251.88,
			16256.572,
			16262.844,
			16264.414,
			16267.558,
			16270.706,
			16272.282,
			16277.016,
			16280.178,
			16283.344,
			16288.101,
			16291.275,
			16296.049,
			16299.23,
			16304.008,
			16307.189,
			16311.93,
			16318.149,
			16319.678,
			16322.707,
			16324.208,
			16327.181,
			16331.568,
			16337.284,
			16340.085,
			16344.217,
			16346.922,
			16350.91,
			16353.524,
			16357.38,
			16359.909,
			16363.654,
			16366.095,
			16369.68,
			16370.857,
			16372.025,
			16375.459,
			16377.734,
			16381.068,
			16383.26,
			16386.512,
			16388.66,
			16391.873,
			16394.01,
			16395.078,
			16397.223,
			16399.375,
			16402.602,
			16404.764,
			16409.1,
			16410.186,
			16413.438,
			16415.605,
			16417.775,
			16421.043,
			16423.221,
			16426.5,
			16428.695,
			16431.986,
			16434.189,
			16437.496,
			16439.695,
			16443.025,
			16445.246,
			16447.467,
			16448.594,
			16451.977,
			16454.234,
			16456.502,
			16462.199,
			16466.754,
			16467.896,
			16471.336,
			16473.637,
			16477.104,
			16479.414,
			16481.73,
			16485.217,
			16486.357,
			16487.52,
			16492.184,
			16493.352,
			16496.867,
			16499.221,
			16501.578,
			16502.762,
			16505.131,
			16507.506,
			16508.695,
			16512.262,
			16514.619,
			16516.949,
			16520.375,
			16522.607,
			16525.887,
			16526.965,
			16528.031,
			16531.203,
			16533.262,
			16536.293,
			16540.246,
			16541.223,
			16543.148,
			16544.117,
			16546.049,
			16548.949,
			16549.908,
			16552.783,
			16554.697,
			16557.562,
			16559.479,
			16561.391,
			16562.344,
			16565.203,
			16567.121,
			16569.035,
			16571.9,
			16573.82,
			16576.713,
			16578.635,
			16581.516,
			16583.426,
			16588.189,
			16591.023,
			16592.908,
			16593.848,
			16595.729,
			16597.625,
			16600.459,
			16602.346,
			16605.215,
			16606.178,
			16607.139,
			16610.02,
			16610.98,
			16611.955,
			16614.895,
			16616.85,
			16619.756,
			16623.533,
			16627.283,
			16628.197,
			16630.932,
			16632.752,
			16635.473,
			16637.279,
			16639.982,
			16641.783,
			16644.461,
			16647.137,
			16648.92,
			16650.715,
			16653.41,
			16655.207,
			16657.932,
			16659.764,
			16661.6,
			16664.365,
			16667.156,
			16669.027,
			16670.91,
			16673.754,
			16675.66,
			16679.488,
			16680.457,
			16683.375,
			16685.336,
			16688.293,
			16692.27,
			16693.271,
			16696.289,
			16698.33,
			16700.348,
			16703.412,
			16705.469,
			16708.588,
			16710.664,
			16713.783,
			16715.889,
			16719.07,
			16721.207,
			16724.43,
			16727.678,
			16728.766,
			16732.047,
			16733.145,
			16734.246,
			16737.564,
			16739.791,
			16743.152,
			16745.402,
			16748.805,
			16752.229,
			16754.523,
			16756.82,
			16762.5,
			16765.816,
			16766.902,
			16767.98,
			16771.154,
			16773.225,
			16776.262,
			16778.24,
			16781.145,
			16783.037,
			16784.91,
			16785.832,
			16788.566,
			16790.373,
			16792.168,
			16793.957,
			16795.758,
			16796.66,
			16800.289,
			16801.203,
			16803.031,
			16803.949,
			16805.801,
			16807.66,
			16810.457,
			16812.326,
			16815.223,
			16816.184,
			16817.145,
			16820.018,
			16821.984,
			16824.908,
			16826.838,
			16828.785,
			16829.768,
			16831.713,
			16834.596,
			16837.402,
			16839.232,
			16841.051,
			16843.764,
			16845.541,
			16848.18,
			16849.932,
			16852.564,
			16854.312,
			16856.92,
			16858.652,
			16860.389,
			16862.977,
			16863.836,
			16864.695,
			16868.123,
			16868.977,
			16871.535,
			16873.234,
			16875.854,
			16877.637,
			16879.42,
			16880.311,
			16882.094,
			16883.008,
			16884.867,
			16886.73,
			16889.535,
			16891.281,
			16893.902,
			16895.664,
			16898.277,
			16900.023,
			16902.67,
			16904.434,
			16906.211,
			16908.9,
			16910.717,
			16913.498,
			16915.367,
			16918.207,
			16920.131,
			16923.041,
			16924.996,
			16927.939,
			16930.908,
			16932.902,
			16934.895,
			16939.92,
			16943.973,
			16944.99,
			16948.061,
			16950.111,
			16953.195,
			16955.258,
			16958.355,
			16960.418,
			16961.449,
			16963.506,
			16966.602,
			16968.641,
			16970.691,
			16973.781,
			16974.816,
			16975.85,
			16977.924,
			16981.066,
			16983.15,
			16984.193,
			16986.285,
			16988.387,
			16991.561,
			16993.672,
			16996.885,
			16997.961,
			16999.039,
			17002.291,
			17004.477,
			17007.773,
			17009.982,
			17012.199,
			17013.309,
			17015.539,
			17018.904,
			17021.164,
			17022.295,
			17024.57,
			17026.854,
			17030.301,
			17032.615,
			17036.1,
			17037.266,
			17038.436,
			17041.957,
			17044.324,
			17047.895,
			17052.693,
			17056.322,
			17058.752,
			17062.408,
			17063.633,
			17064.857,
			17068.566,
			17071.051,
			17074.793,
			17077.297,
			17078.551,
			17081.062,
			17084.826,
			17087.344,
			17089.865,
			17096.201,
			17101.326,
			17102.611,
			17106.5,
			17109.104,
			17113.012,
			17115.619,
			17119.535,
			17122.139,
			17126.021,
			17128.578,
			17129.846,
			17131.102,
			17134.818,
			17137.248,
			17140.82,
			17143.154,
			17146.584,
			17149.928,
			17152.107,
			17154.25,
			17157.393,
			17159.453,
			17162.441,
			17164.393,
			17167.312,
			17169.217,
			17172.086,
			17174.004,
			17175.9,
			17176.844,
			17178.73,
			17179.668,
			17181.535,
			17184.32,
			17186.172,
			17188.02,
			17189.863,
			17192.631,
			17195.408,
			17197.258,
			17199.109,
			17201.895,
			17203.752,
			17206.553,
			17208.463,
			17211.34,
			17212.301,
			17213.266,
			17216.152,
			17218.084,
			17220.994,
			17222.908,
			17225.795,
			17227.729,
			17230.641,
			17232.596,
			17233.578,
			17235.564,
			17237.559,
			17241.57,
			17242.58,
			17246.629,
			17247.643,
			17250.699,
			17252.744,
			17255.824,
			17257.896,
			17259.994,
			17263.156,
			17264.211,
			17265.27,
			17268.443,
			17270.547,
			17273.715,
			17275.828,
			17279.016,
			17281.143,
			17283.314,
			17284.395,
			17287.68,
			17289.885,
			17292.098,
			17297.637,
			17302.1,
			17303.217,
			17306.582,
			17308.838,
			17312.248,
			17314.531,
			17317.988,
			17320.309,
			17321.473,
			17323.811,
			17327.336,
			17328.512,
			17332.045,
			17334.371,
			17336.682,
			17337.832,
			17342.383,
			17343.523,
			17346.93,
			17349.188,
			17351.453,
			17354.801,
			17357.021,
			17360.328,
			17361.422,
			17362.51,
			17365.73,
			17367.881,
			17371.115,
			17373.25,
			17376.447,
			17378.576,
			17379.641,
			17381.766,
			17383.889,
			17384.949,
			17387.076,
			17389.207,
			17392.396,
			17394.527,
			17398.801,
			17399.867,
			17403.07,
			17405.211,
			17408.42,
			17410.562,
			17412.719,
			17417.037,
			17418.121,
			17421.395,
			17423.584,
			17426.883,
			17429.102,
			17432.445,
			17434.684,
			17438.082,
			17441.508,
			17443.809,
			17446.129,
			17450.801,
			17451.977,
			17456.711,
			17457.9,
			17461.484,
			17463.889,
			17466.301,
			17469.945,
			17472.387,
			17476.072,
			17477.301,
			17478.533,
			17483.49,
			17484.736,
			17488.484,
			17490.998,
			17494.785,
			17497.318,
			17499.861,
			17501.137,
			17503.691,
			17504.973,
			17507.537,
			17510.109,
			17513.973,
			17516.555,
			17520.439,
			17521.736,
			17523.035,
			17526.943,
			17529.555,
			17533.473,
			17536.068,
			17540.025,
			17543.973,
			17546.58,
			17549.219,
			17553.176,
			17555.814,
			17558.459,
			17562.428,
			17567.76,
			17569.1,
			17573.107,
			17575.805,
			17578.523,
			17585.359,
			17589.498,
			17590.879,
			17592.262,
			17596.418,
			17599.195,
			17603.361,
			17606.146,
			17610.338,
			17613.139,
			17614.543,
			17617.354,
			17621.578,
			17622.99,
			17627.234,
			17630.068,
			17634.334,
			17637.188,
			17640.045,
			17641.477,
			17645.781,
			17648.656,
			17651.539,
			17655.875,
			17658.775,
			17663.133,
			17664.59,
			17666.049,
			17670.436,
			17673.367,
			17677.775,
			17680.723,
			17685.158,
			17688.121,
			17692.578,
			17697.049,
			17700.037,
			17703.031,
			17707.537,
			17710.547,
			17715.076,
			17716.588,
			17718.102,
			17722.654,
			17725.697,
			17730.275,
			17736.426,
			17741.051,
			17742.596,
			17744.143,
			17750.324,
			17751.867,
			17756.5,
			17764.205,
			17767.293,
			17770.395,
			17771.961,
			17775.102,
			17776.674,
			17779.812,
			17782.959,
			17787.672,
			17790.816,
			17795.562,
			17797.145,
			17798.729,
			17803.492,
			17806.684,
			17811.457,
			17819.424,
			17822.629,
			17824.234,
			17825.838,
			17830.645,
			17832.23,
			17833.816,
			17838.617,
			17841.846,
			17846.717,
			17849.949,
			17853.188,
			17854.807,
			17859.672,
			17862.922,
			17866.178,
			17871.068,
			17874.336,
			17882.521,
			17887.445,
			17890.734,
			17895.676,
			17898.973,
			17903.93,
			17907.24,
			17908.896,
			17912.213,
			17917.195,
			17920.523,
			17923.855,
			17928.861,
			17930.533,
			17932.205,
			17935.555,
			17938.906,
			17940.582,
			17943.941,
			17945.621,
			17948.986,
			17952.355,
			17957.416,
			17960.797,
			17965.875,
			17969.266,
			17974.359,
			17977.76,
			17982.871,
			17986.281,
			17989.699,
			17991.408,
			17996.545,
			17999.975,
			18003.408,
			18008.566,
			18012.008,
			18017.18,
			18020.633,
			18025.82,
			18029.283,
			18034.484,
			18037.957,
			18041.434,
			18046.656,
			18050.141,
			18055.377,
			18057.125,
			18058.873,
			18064.123,
			18067.627,
			18072.889,
			18076.402,
			18081.68,
			18088.727,
			18090.49,
			18094.02,
			18099.322,
			18102.861,
			18108.176,
			18111.723,
			18117.049,
			18118.826,
			18120.605,
			18125.941,
			18129.504,
			18134.854,
			18138.424,
			18141.996,
			18149.152,
			18152.732,
			18158.111,
			18159.906,
			18165.293,
			18168.887,
			18172.484,
			18174.285,
			18177.887,
			18183.297,
			18188.713,
			18192.328,
			18195.945,
			18201.375,
			18205,
			18210.441,
			18214.07,
			18219.523,
			18223.16,
			18228.625,
			18235.916,
			18237.742,
			18243.219,
			18246.875,
			18252.363,
			18259.689,
			18265.193,
			18268.865,
			18272.539,
			18276.215,
			18278.055,
			18283.574,
			18287.258,
			18290.945,
			18300.172,
			18305.715,
			18307.564,
			18309.414,
			18314.969,
			18318.674,
			18324.234,
			18327.945,
			18333.516,
			18339.094,
			18342.812,
			18346.537,
			18348.398,
			18352.125,
			18355.854,
			18361.451,
			18363.318,
			18365.184,
			18370.791,
			18372.66,
			18374.531,
			18380.145,
			18383.891,
			18387.637,
			18393.264,
			18397.016,
			18404.529,
			18406.408,
			18412.051,
			18413.932,
			18415.814,
			18421.465,
			18425.232,
			18429.004,
			18430.891,
			18434.664,
			18440.33,
			18446,
			18449.783,
			18453.568,
			18459.248,
			18463.037,
			18468.723,
			18470.621,
			18472.52,
			18478.219,
			18482.02,
			18487.721,
			18491.523,
			18497.232,
			18501.041,
			18502.947,
			18504.854,
			18512.479,
			18514.387,
			18520.113,
			18523.934,
			18529.666,
			18537.316,
			18539.229,
			18544.971,
			18548.801,
			18552.631,
			18558.379,
			18562.211,
			18569.898,
			18571.824,
			18577.604,
			18581.455,
			18587.229,
			18591.08,
			18594.93,
			18596.854,
			18600.707,
			18606.488,
			18610.346,
			18616.135,
			18619.996,
			18623.857,
			18629.656,
			18637.393,
			18639.328,
			18645.133,
			18649.004,
			18652.879,
			18658.691,
			18662.566,
			18668.383,
			18672.264,
			18678.086,
			18681.969,
			18687.797,
			18691.684,
			18697.512,
			18701.402,
			18707.234,
			18713.072,
			18716.965,
			18720.857,
			18726.699,
			18730.594,
			18738.391,
			18740.344,
			18750.096,
			18753.996,
			18759.838,
			18763.734,
			18771.539,
			18773.49,
			18781.322,
			18783.277,
			18789.135,
			18793.045,
			18796.965,
			18798.92,
			18802.82,
			18804.771,
			18808.672,
			18814.553,
			18822.408,
			18828.277,
			18832.184,
			18838.059,
			18840.018,
			18841.977,
			18847.852,
			18851.77,
			18857.648,
			18861.566,
			18865.486,
			18871.363,
			18875.283,
			18883.121,
			18885.082,
			18890.963,
			18894.883,
			18900.764,
			18908.605,
			18910.564,
			18914.484,
			18916.445,
			18920.367,
			18924.285,
			18930.166,
			18934.086,
			18939.965,
			18941.924,
			18943.883,
			18949.766,
			18953.693,
			18959.586,
			18963.516,
			18969.41,
			18975.273,
			18979.174,
			18985.062,
			18988.988,
			18992.891,
			18996.803,
			19002.684,
			19006.605,
			19010.533,
			19012.498,
			19018.385,
			19022.287,
			19026.189,
			19032.09,
			19036.021,
			19041.912,
			19043.877,
			19045.844,
			19051.734,
			19055.631,
			19061.492,
			19065.412,
			19069.334,
			19071.297,
			19075.219,
			19081.104,
			19086.988,
			19090.912,
			19094.828,
			19104.59,
			19110.477,
			19112.43,
			19114.383,
			19118.312,
			19120.285,
			19124.225,
			19130.121,
			19134.053,
			19137.992,
			19143.863,
			19145.82,
			19147.777,
			19153.641,
			19157.566,
			19163.477,
			19171.336,
			19173.289,
			19177.191,
			19183.07,
			19188.969,
			19192.902,
			19196.836,
			19202.73,
			19206.689,
			19212.58,
			19214.539,
			19216.504,
			19222.41,
			19226.312,
			19232.15,
			19240.041,
			19245.977,
			19247.955,
			19251.904,
			19257.812,
			19259.781,
			19265.684,
			19269.619,
			19275.52,
			19279.453,
			19283.363,
			19285.322,
			19291.215,
			19295.15,
			19299.076,
			19304.98,
			19308.938,
			19316.82,
			19318.777,
			19324.713,
			19328.686,
			19334.535,
			19338.457,
			19344.387,
			19348.305,
			19354.17,
			19360.109,
			19364.074,
			19368.039,
			19373.918,
			19377.822,
			19381.801,
			19385.795,
			19391.746,
			19393.717,
			19397.609,
			19401.512,
			19407.473,
			19411.445,
			19417.359,
			19419.326,
			19421.289,
			19427.191,
			19431.137,
			19437.031,
			19444.895,
			19446.867,
			19450.812,
			19456.73,
			19460.678,
			19462.65,
			19466.598,
			19470.547,
			19476.469,
			19480.416,
			19488.316,
			19490.291,
			19496.217,
			19500.168,
			19504.119,
			19510.049,
			19514.002,
			19519.934,
			19521.912,
			19523.889,
			19529.822,
			19533.775,
			19539.711,
			19543.666,
			19549.602,
			19553.555,
			19559.492,
			19563.459,
			19569.447,
			19573.441,
			19579.402,
			19583.361,
			19589.297,
			19591.275,
			19593.254,
			19599.193,
			19603.152,
			19607.111,
			19613.051,
			19617.012,
			19622.957,
			19628.912,
			19634.873,
			19636.857,
			19642.812,
			19646.783,
			19650.75,
			19652.732,
			19656.693,
			19662.633,
			19668.582,
			19672.549,
			19676.516,
			19682.469,
			19686.445,
			19692.436,
			19694.438,
			19696.445,
			19702.48,
			19706.516,
			19712.578,
			19716.619,
			19720.654,
			19722.676,
			19728.742,
			19732.793,
			19738.885,
			19740.916,
			19746.988,
			19751.01,
			19757.016,
			19765.014,
			19767.016,
			19771.02,
			19773.027,
			19777.039,
			19781.051,
			19787.066,
			19791.074,
			19799.084,
			19801.086,
			19807.094,
			19811.104,
			19817.117,
			19821.131,
			19827.152,
			19831.17,
			19837.199,
			19843.232,
			19847.256,
			19851.283,
			19857.352,
			19861.408,
			19867.463,
			19871.488,
			19877.545,
			19881.586,
			19887.652,
			19891.693,
			19895.734,
			19901.797,
			19905.836,
			19911.898,
			19915.941,
			19922.006,
			19926.051,
			19932.119,
			19936.166,
			19940.215,
			19942.271,
			19948.314,
			19952.363,
			19958.438,
			19962.486,
			19966.533,
			19972.609,
			19976.699,
			19984.801,
			19986.818,
			19990.807,
			19992.809,
			19996.791,
			20002.693,
			20006.574,
			20010.412,
			20016.084,
			20017.955,
			20019.814,
			20027.15,
			20028.957,
			20034.334,
			20043.209,
			20048.512,
			20051.988,
			20057.248,
			20060.781,
			20064.258,
			20069.557,
			20073.08,
			20078.34,
			20081.867,
			20087.162,
			20090.709,
			20096.047,
			20099.631,
			20103.176,
			20104.916,
			20110.234,
			20112.012,
			20119.145,
			20120.932,
			20126.279,
			20129.832,
			20135.145,
			20138.68,
			20143.98,
			20149.312,
			20152.82,
			20156.361,
			20161.674,
			20165.213,
			20172.283,
			20174.053,
			20179.393,
			20182.936,
			20188.24,
			20191.766,
			20197.012,
			20202.207,
			20205.68,
			20210.918,
			20214.389,
			20217.852,
			20224.754,
			20226.477,
			20229.924,
			20233.369,
			20235.09,
			20240.213,
			20243.643,
			20247.064,
			20252.215,
			20255.621,
			20260.688,
			20262.379,
			20264.062,
			20269.072,
			20272.369,
			20277.238,
			20280.436,
			20285.162,
			20288.262,
			20289.797,
			20295.844,
			20297.334,
			20300.289,
			20303.207,
			20307.516,
			20310.342,
			20314.537,
			20315.91,
			20317.275,
			20321.318,
			20323.971,
			20326.59,
			20330.455,
			20332.988,
			20336.736,
			20337.953,
			20339.186,
			20344.146,
			20349.107,
			20351.582,
			20355.27,
			20360.129,
			20361.346,
			20363.76,
			20367.367,
			20369.717,
			20373.152,
			20375.387,
			20378.619,
			20379.686,
			20380.738,
			20383.859,
			20385.871,
			20388.854,
			20390.801,
			20392.715,
			20396.422,
			20398.219,
			20399.992,
			20400.85,
			20401.699,
			20404.205,
			20405.812,
			20408.191,
			20411.215,
			20411.949,
			20414.115,
			20415.521,
			20416.926,
			20419.029,
			20420.418,
			20422.49,
			20423.176,
			20423.861,
			20425.932,
			20427.32,
			20429.389,
			20430.797,
			20432.926,
			20435.055,
			20436.551,
			20438.781,
			20440.266,
			20441.754,
			20444.713,
			20445.434,
			20446.848,
			20448.258,
			20448.963,
			20450.373,
			20451.076,
			20452.479,
			20453.883,
			20456.01,
			20457.443,
			20459.6,
			20461.076,
			20463.316,
			20464.818,
			20467.119,
			20468.664,
			20470.215,
			20470.961,
			20472.461,
			20474.727,
			20476.223,
			20476.973,
			20478.48,
			20480.002,
			20482.32,
			20483.885,
			20487.076,
			20487.867,
			20490.305,
			20491.949,
			20493.607,
			20497.842,
			20500.395,
			20502.145,
			20504.789,
			20506.592,
			20509.328,
			20511.158,
			20513.918,
			20515.77,
			20517.637,
			20518.57,
			20521.404,
			20523.316,
			20525.236,
			20528.057,
			20529.938,
			20533.551,
			20534.447,
			20538.031,
			20541.604,
			20543.391,
			20546.057,
			20547.816,
			20549.566,
			20552.158,
			20553.012,
			20553.859,
			20557.174,
			20557.986,
			20560.379,
			20563.434,
			20564.174,
			20567.041,
			20567.734,
			20569.098,
			20571.08,
			20572.363,
			20574.227,
			20575.426,
			20577.191,
			20577.766,
			20578.334,
			20580.014,
			20581.117,
			20582.752,
			20584.906,
			20585.441,
			20586.508,
			20588.094,
			20589.668,
			20590.193,
			20591.762,
			20592.799,
			20594.352,
			20595.391,
			20596.459,
			20597.004,
			20598.637,
			20599.73,
			20600.83,
			20604.002,
			20605.936,
			20606.656,
			20607.381,
			20609.566,
			20611.021,
			20612.922,
			20614.199,
			20616.119,
			20617.305,
			20617.902,
			20619.104,
			20620.32,
			20622.195,
			20623.475,
			20625.42,
			20626.076,
			20626.73,
			20628.707,
			20629.373,
			20630.043,
			20632.062,
			20633.414,
			20634.781,
			20636.865,
			20638.275,
			20640.42,
			20641.873,
			20644.09,
			20645.584,
			20647.877,
			20649.428,
			20650.994,
			20651.787,
			20654.195,
			20655.816,
			20658.283,
			20659.93,
			20661.604,
			20664.135,
			20665.826,
			20668.363,
			20669.215,
			20670.07,
			20672.652,
			20674.387,
			20676.135,
			20678.77,
			20680.539,
			20683.207,
			20684.998,
			20688.613,
			20689.525,
			20692.277,
			20696.924,
			20698.801,
			20701.635,
			20703.535,
			20704.49,
			20706.406,
			20708.336,
			20711.252,
			20713.215,
			20716.184,
			20717.178,
			20718.174,
			20721.178,
			20723.189,
			20726.225,
			20728.262,
			20730.307,
			20731.334,
			20734.438,
			20735.477,
			20739.656,
			20741.746,
			20743.844,
			20745.951,
			20749.125,
			20753.383,
			20754.453,
			20757.678,
			20759.84,
			20762.008,
			20765.281,
			20767.477,
			20770.785,
			20773.008,
			20776.361,
			20778.611,
			20782,
			20784.266,
			20785.389
		],
		[
			0,
			0.02,
			0.083,
			0.113,
			0.145,
			0.207,
			0.269,
			0.332,
			0.393,
			0.454,
			0.518,
			0.549,
			0.579,
			0.657,
			0.702,
			0.764,
			0.826,
			0.872,
			0.887,
			0.98,
			1.012,
			1.073,
			1.09,
			1.136,
			1.202,
			1.262,
			1.322,
			1.384,
			1.415,
			1.446,
			1.525,
			1.572,
			1.634,
			1.693,
			1.741,
			1.819,
			1.883,
			1.942,
			1.958,
			2.006,
			2.068,
			2.13,
			2.191,
			2.254,
			2.314,
			2.4,
			2.439,
			2.5,
			2.563,
			2.61,
			2.687,
			2.719,
			2.749,
			2.811,
			2.872,
			2.935,
			2.998,
			3.059,
			3.152,
			3.182,
			3.265,
			3.306,
			3.371,
			3.43,
			3.476,
			3.492,
			3.556,
			3.617,
			3.68,
			3.741,
			3.802,
			3.864,
			3.927,
			3.989,
			4.02,
			4.05,
			4.127,
			4.174,
			4.238,
			4.299,
			4.347,
			4.36,
			4.424,
			4.454,
			4.486,
			4.562,
			4.611,
			4.673,
			4.733,
			4.796,
			4.888,
			4.919,
			4.996,
			5.042,
			5.107,
			5.168,
			5.213,
			5.229,
			5.292,
			5.327,
			5.353,
			5.432,
			5.478,
			5.54,
			5.6,
			5.665,
			5.727,
			5.756,
			5.788,
			5.85,
			5.912,
			5.975,
			6.035,
			6.098,
			6.16,
			6.22,
			6.3,
			6.348,
			6.408,
			6.468,
			6.53,
			6.593,
			6.625,
			6.655,
			6.718,
			6.733,
			6.779,
			6.841,
			6.904,
			6.966,
			7.027,
			7.06,
			7.091,
			7.168,
			7.213,
			7.276,
			7.389,
			7.493,
			7.525,
			7.586,
			7.647,
			7.71,
			7.772,
			7.832,
			7.896,
			7.926,
			7.959,
			8.035,
			8.082,
			8.145,
			8.206,
			8.252,
			8.266,
			8.361,
			8.392,
			8.454,
			8.516,
			8.579,
			8.641,
			8.702,
			8.764,
			8.794,
			8.825,
			8.888,
			8.905,
			8.951,
			9.013,
			9.073,
			9.12,
			9.135,
			9.198,
			9.23,
			9.259,
			9.339,
			9.383,
			9.45,
			9.509,
			9.57,
			9.632,
			9.665,
			9.693,
			9.771,
			9.817,
			9.879,
			9.988,
			10.005,
			10.097,
			10.128,
			10.205,
			10.253,
			10.315,
			10.377,
			10.439,
			10.5,
			10.532,
			10.562,
			10.64,
			10.686,
			10.754,
			10.811,
			10.857,
			10.935,
			10.966,
			10.998,
			11.074,
			11.121,
			11.182,
			11.243,
			11.307,
			11.368,
			11.4,
			11.429,
			11.492,
			11.556,
			11.618,
			11.677,
			11.739,
			11.804,
			11.833,
			11.865,
			11.943,
			11.988,
			12.05,
			12.114,
			12.158,
			12.238,
			12.268,
			12.297,
			12.36,
			12.421,
			12.485,
			12.545,
			12.61,
			12.671,
			12.701,
			12.733,
			12.81,
			12.857,
			12.919,
			12.98,
			13.028,
			13.104,
			13.167,
			13.228,
			13.243,
			13.292,
			13.354,
			13.414,
			13.478,
			13.574,
			13.601,
			13.677,
			13.726,
			13.787,
			13.85,
			13.895,
			13.911,
			13.973,
			14.003,
			14.035,
			14.113,
			14.158,
			14.222,
			14.283,
			14.344,
			14.408,
			14.469,
			14.531,
			14.593,
			14.657,
			14.764,
			14.841,
			14.873,
			14.903,
			14.979,
			15.027,
			15.09,
			15.152,
			15.212,
			15.276,
			15.307,
			15.335,
			15.4,
			15.413,
			15.461,
			15.523,
			15.585,
			15.647,
			15.71,
			15.772,
			15.848,
			15.895,
			15.958,
			16.018,
			16.08,
			16.142,
			16.174,
			16.207,
			16.267,
			16.33,
			16.392,
			16.455,
			16.517,
			16.577,
			16.608,
			16.64,
			16.718,
			16.763,
			16.826,
			16.888,
			16.935,
			17.01,
			17.073,
			17.135,
			17.199,
			17.261,
			17.32,
			17.382,
			17.445,
			17.475,
			17.507,
			17.585,
			17.63,
			17.7,
			17.801,
			17.817,
			17.88,
			17.943,
			18.019,
			18.067,
			18.127,
			18.188,
			18.252,
			18.315,
			18.345,
			18.375,
			18.439,
			18.454,
			18.501,
			18.562,
			18.623,
			18.686,
			18.781,
			18.81,
			18.888,
			18.934,
			18.998,
			19.058,
			19.118,
			19.213,
			19.243,
			19.321,
			19.369,
			19.431,
			19.492,
			19.554,
			19.615,
			19.648,
			19.678,
			19.76,
			19.803,
			19.866,
			19.927,
			19.988,
			20.05,
			20.081,
			20.111,
			20.173,
			20.188,
			20.236,
			20.298,
			20.359,
			20.406,
			20.486,
			20.516,
			20.545,
			20.623,
			20.669,
			20.733,
			20.795,
			20.857,
			20.918,
			20.98,
			21.043,
			21.058,
			21.105,
			21.168,
			21.274,
			21.289,
			21.352,
			21.415,
			21.492,
			21.538,
			21.6,
			21.663,
			21.725,
			21.787,
			21.848,
			21.926,
			21.974,
			22.035,
			22.143,
			22.158,
			22.221,
			22.253,
			22.283,
			22.359,
			22.408,
			22.471,
			22.531,
			22.593,
			22.655,
			22.685,
			22.716,
			22.778,
			22.84,
			22.902,
			22.966,
			23.025,
			23.12,
			23.15,
			23.227,
			23.273,
			23.338,
			23.398,
			23.46,
			23.554,
			23.583,
			23.647,
			23.707,
			23.772,
			23.833,
			23.896,
			23.957,
			23.988,
			24.018,
			24.095,
			24.142,
			24.206,
			24.313,
			24.329,
			24.421,
			24.454,
			24.515,
			24.531,
			24.577,
			24.639,
			24.702,
			24.762,
			24.856,
			24.888,
			24.965,
			25.011,
			25.073,
			25.182,
			25.26,
			25.321,
			25.382,
			25.445,
			25.506,
			25.568,
			25.63,
			25.694,
			25.755,
			25.832,
			25.878,
			25.946,
			26.049,
			26.065,
			26.126,
			26.189,
			26.268,
			26.375,
			26.435,
			26.499,
			26.561,
			26.592,
			26.623,
			26.683,
			26.746,
			26.81,
			26.871,
			26.918,
			26.933,
			26.995,
			27.026,
			27.058,
			27.133,
			27.182,
			27.243,
			27.305,
			27.368,
			27.43,
			27.462,
			27.493,
			27.554,
			27.616,
			27.676,
			27.74,
			27.801,
			27.865,
			27.895,
			27.926,
			28.007,
			28.048,
			28.11,
			28.221,
			28.298,
			28.329,
			28.36,
			28.436,
			28.483,
			28.547,
			28.607,
			28.67,
			28.732,
			28.763,
			28.793,
			28.872,
			28.917,
			28.98,
			29.043,
			29.088,
			29.165,
			29.228,
			29.305,
			29.352,
			29.413,
			29.476,
			29.538,
			29.598,
			29.632,
			29.661,
			29.74,
			29.786,
			29.848,
			29.955,
			30.033,
			30.096,
			30.159,
			30.22,
			30.287,
			30.344,
			30.407,
			30.469,
			30.499,
			30.53,
			30.606,
			30.655,
			30.717,
			30.825,
			30.84,
			30.903,
			30.963,
			31.026,
			31.04,
			31.088,
			31.151,
			31.214,
			31.273,
			31.368,
			31.399,
			31.475,
			31.525,
			31.584,
			31.646,
			31.709,
			31.801,
			31.833,
			31.91,
			31.956,
			32.018,
			32.08,
			32.143,
			32.236,
			32.265,
			32.329,
			32.39,
			32.454,
			32.561,
			32.669,
			32.701,
			32.778,
			32.823,
			32.888,
			32.948,
			33.009,
			33.072,
			33.133,
			33.195,
			33.211,
			33.259,
			33.32,
			33.383,
			33.443,
			33.506,
			33.568,
			33.646,
			33.691,
			33.76,
			33.863,
			33.878,
			33.94,
			33.973,
			34.003,
			34.065,
			34.08,
			34.128,
			34.193,
			34.252,
			34.313,
			34.375,
			34.406,
			34.435,
			34.514,
			34.56,
			34.621,
			34.685,
			34.748,
			34.809,
			34.87,
			34.933,
			34.994,
			35.061,
			35.12,
			35.18,
			35.241,
			35.305,
			35.383,
			35.429,
			35.49,
			35.554,
			35.599,
			35.615,
			35.678,
			35.74,
			35.815,
			35.862,
			35.924,
			35.988,
			36.05,
			36.142,
			36.173,
			36.233,
			36.258,
			36.296,
			36.363,
			36.421,
			36.483,
			36.546,
			36.575,
			36.608,
			36.685,
			36.73,
			36.856,
			36.916,
			37.01,
			37.042,
			37.104,
			37.164,
			37.228,
			37.289,
			37.35,
			37.443,
			37.474,
			37.553,
			37.598,
			37.661,
			37.723,
			37.786,
			37.846,
			37.879,
			37.909,
			37.969,
			37.988,
			38.032,
			38.096,
			38.158,
			38.22,
			38.281,
			38.318,
			38.343,
			38.421,
			38.465,
			38.529,
			38.638,
			38.654,
			38.745,
			38.779,
			38.839,
			38.855,
			38.9,
			38.963,
			39.025,
			39.088,
			39.149,
			39.181,
			39.211,
			39.288,
			39.335,
			39.398,
			39.46,
			39.507,
			39.615,
			39.647,
			39.707,
			39.77,
			39.833,
			39.892,
			39.955,
			40.018,
			40.05,
			40.079,
			40.157,
			40.203,
			40.264,
			40.329,
			40.378,
			40.391,
			40.483,
			40.512,
			40.576,
			40.638,
			40.699,
			40.761,
			40.823,
			40.916,
			40.948,
			41.025,
			41.072,
			41.135,
			41.195,
			41.243,
			41.258,
			41.32,
			41.383,
			41.458,
			41.507,
			41.566,
			41.628,
			41.691,
			41.754,
			41.786,
			41.817,
			41.878,
			41.894,
			41.94,
			42.004,
			42.065,
			42.11,
			42.188,
			42.218,
			42.249,
			42.328,
			42.375,
			42.439,
			42.497,
			42.56,
			42.653,
			42.683,
			42.745,
			42.76,
			42.808,
			42.871,
			42.933,
			42.994,
			43.056,
			43.087,
			43.118,
			43.195,
			43.243,
			43.303,
			43.364,
			43.411,
			43.489,
			43.522,
			43.551,
			43.615,
			43.677,
			43.74,
			43.8,
			43.862,
			43.955,
			43.986,
			44.064,
			44.109,
			44.173,
			44.28,
			44.358,
			44.421,
			44.483,
			44.504,
			44.543,
			44.606,
			44.668,
			44.729,
			44.823,
			44.855,
			44.932,
			44.979,
			45.04,
			45.15,
			45.166,
			45.226,
			45.257,
			45.289,
			45.365,
			45.412,
			45.476,
			45.537,
			45.598,
			45.662,
			45.693,
			45.722,
			45.783,
			45.848,
			45.913,
			46.016,
			46.032,
			46.095,
			46.156,
			46.233,
			46.279,
			46.344,
			46.405,
			46.466,
			46.529,
			46.59,
			46.669,
			46.716,
			46.777,
			46.84,
			46.902,
			46.964,
			47.026,
			47.102,
			47.149,
			47.213,
			47.274,
			47.333,
			47.429,
			47.458,
			47.536,
			47.583,
			47.646,
			47.752,
			47.768,
			47.831,
			47.862,
			47.894,
			47.97,
			48.017,
			48.079,
			48.14,
			48.203,
			48.296,
			48.328,
			48.388,
			48.406,
			48.451,
			48.514,
			48.575,
			48.638,
			48.7,
			48.729,
			48.762,
			48.839,
			48.885,
			48.947,
			49.009,
			49.056,
			49.132,
			49.164,
			49.195,
			49.256,
			49.318,
			49.38,
			49.442,
			49.506,
			49.565,
			49.599,
			49.628,
			49.706,
			49.753,
			49.814,
			49.878,
			49.925,
			49.94,
			50.002,
			50.033,
			50.125,
			50.141,
			50.187,
			50.251,
			50.31,
			50.373,
			50.436,
			50.465,
			50.497,
			50.575,
			50.623,
			50.688,
			50.792,
			50.808,
			50.868,
			50.9,
			50.93,
			50.992,
			51.056,
			51.118,
			51.179,
			51.242,
			51.303,
			51.367,
			51.443,
			51.49,
			51.553,
			51.613,
			51.659,
			51.738,
			51.769,
			51.8,
			51.923,
			51.988,
			52.048,
			52.109,
			52.202,
			52.235,
			52.295,
			52.312,
			52.356,
			52.421,
			52.483,
			52.528,
			52.543,
			52.605,
			52.638,
			52.667,
			52.75,
			52.79,
			52.856,
			52.915,
			52.978,
			53.041,
			53.072,
			53.102,
			53.165,
			53.181,
			53.225,
			53.291,
			53.35,
			53.397,
			53.413,
			53.475,
			53.506,
			53.535,
			53.614,
			53.661,
			53.723,
			53.785,
			53.847,
			53.909,
			53.939,
			53.97,
			54.033,
			54.095,
			54.158,
			54.265,
			54.281,
			54.342,
			54.405,
			54.482,
			54.529,
			54.591,
			54.715,
			54.775,
			54.839,
			54.899,
			54.915,
			54.961,
			55.023,
			55.087,
			55.148,
			55.209,
			55.272,
			55.349,
			55.395,
			55.458,
			55.521,
			55.565,
			55.58,
			55.645,
			55.706,
			55.783,
			55.831,
			55.891,
			55.954,
			56.016,
			56.079,
			56.109,
			56.141,
			56.203,
			56.218,
			56.264,
			56.326,
			56.388,
			56.451,
			56.544,
			56.573,
			56.651,
			56.698,
			56.762,
			56.823,
			56.873,
			56.885,
			56.976,
			57.007,
			57.071,
			57.087,
			57.133,
			57.195,
			57.256,
			57.318,
			57.385,
			57.441,
			57.521,
			57.567,
			57.63,
			57.69,
			57.735,
			57.814,
			57.876,
			57.939,
			57.954,
			58.001,
			58.063,
			58.124,
			58.188,
			58.28,
			58.31,
			58.388,
			58.435,
			58.496,
			58.558,
			58.621,
			58.682,
			58.744,
			58.824,
			58.868,
			58.931,
			58.994,
			59.056,
			59.149,
			59.181,
			59.24,
			59.257,
			59.304,
			59.366,
			59.428,
			59.49,
			59.552,
			59.613,
			59.691,
			59.738,
			59.8,
			59.862,
			59.924,
			59.986,
			60.046,
			60.109,
			60.171,
			60.232,
			60.295,
			60.358,
			60.419,
			60.481,
			60.558,
			60.605,
			60.668,
			60.792,
			60.853,
			60.883,
			60.915,
			60.977,
			60.999,
			61.039,
			61.106,
			61.164,
			61.224,
			61.288,
			61.35,
			61.428,
			61.472,
			61.536,
			61.598,
			61.66,
			61.783,
			61.845,
			61.907,
			61.971,
			62.031,
			62.092,
			62.156,
			62.186,
			62.217,
			62.295,
			62.341,
			62.403,
			62.465,
			62.512,
			62.589,
			62.621,
			62.653,
			62.729,
			62.776,
			62.839,
			62.9,
			62.961,
			63.061,
			63.085,
			63.164,
			63.21,
			63.272,
			63.335,
			63.395,
			63.456,
			63.49,
			63.518,
			63.596,
			63.705,
			63.768,
			63.829,
			63.89,
			63.955,
			64.015,
			64.032,
			64.077,
			64.139,
			64.2,
			64.249,
			64.265,
			64.327,
			64.357,
			64.388,
			64.465,
			64.512,
			64.575,
			64.636,
			64.699,
			64.761,
			64.791,
			64.823,
			64.884,
			64.946,
			65.012,
			65.071,
			65.132,
			65.226,
			65.256,
			65.333,
			65.379,
			65.441,
			65.503,
			65.565,
			65.628,
			65.659,
			65.69,
			65.768,
			65.815,
			65.877,
			65.939,
			66,
			66.063,
			66.094,
			66.123,
			66.2,
			66.248,
			66.31,
			66.373,
			66.418,
			66.435,
			66.495,
			66.526,
			66.558,
			66.636,
			66.682,
			66.745,
			66.807,
			66.868,
			66.929,
			66.992,
			67.07,
			67.115,
			67.186,
			67.24,
			67.287,
			67.304,
			67.365,
			67.428,
			67.503,
			67.549,
			67.615,
			67.676,
			67.736,
			67.799,
			67.831,
			67.862,
			67.923,
			67.939,
			67.985,
			68.048,
			68.108,
			68.155,
			68.232,
			68.262,
			68.295,
			68.373,
			68.418,
			68.48,
			68.542,
			68.606,
			68.666,
			68.729,
			68.807,
			68.853,
			68.914,
			69.023,
			69.039,
			69.102,
			69.13,
			69.162,
			69.247,
			69.287,
			69.364,
			69.409,
			69.473,
			69.535,
			69.595,
			69.659,
			69.691,
			69.721,
			69.799,
			69.843,
			69.908,
			69.969,
			70.031,
			70.093,
			70.156,
			70.217,
			70.278,
			70.342,
			70.404,
			70.466,
			70.527,
			70.559,
			70.59,
			70.668,
			70.715,
			70.775,
			70.883,
			70.963,
			71.024,
			71.102,
			71.148,
			71.21,
			71.272,
			71.333,
			71.426,
			71.458,
			71.52,
			71.533,
			71.581,
			71.644,
			71.705,
			71.751,
			71.767,
			71.83,
			71.861,
			71.891,
			71.97,
			72.016,
			72.077,
			72.138,
			72.202,
			72.264,
			72.293,
			72.325,
			72.402,
			72.448,
			72.51,
			72.574,
			72.621,
			72.635,
			72.728,
			72.759,
			72.836,
			72.883,
			72.945,
			73.006,
			73.07,
			73.132,
			73.164,
			73.192,
			73.271,
			73.317,
			73.385,
			73.443,
			73.502,
			73.595,
			73.628,
			73.705,
			73.752,
			73.813,
			73.877,
			73.938,
			74,
			74.03,
			74.063,
			74.139,
			74.186,
			74.249,
			74.31,
			74.372,
			74.433,
			74.466,
			74.495,
			74.572,
			74.62,
			74.682,
			74.743,
			74.806,
			74.868,
			74.897,
			74.992,
			75.007,
			75.055,
			75.115,
			75.178,
			75.241,
			75.302,
			75.364,
			75.426,
			75.445,
			75.488,
			75.55,
			75.658,
			75.768,
			75.799,
			75.876,
			75.922,
			75.986,
			76.046,
			76.107,
			76.202,
			76.232,
			76.294,
			76.31,
			76.357,
			76.419,
			76.479,
			76.528,
			76.543,
			76.603,
			76.633,
			76.664,
			76.744,
			76.789,
			76.853,
			76.913,
			76.977,
			77.068,
			77.101,
			77.161,
			77.225,
			77.287,
			77.348,
			77.393,
			77.409,
			77.472,
			77.508,
			77.535,
			77.61,
			77.658,
			77.72,
			77.782,
			77.845,
			77.906,
			77.938,
			77.969,
			78.045,
			78.092,
			78.154,
			78.216,
			78.278,
			78.339,
			78.371,
			78.402,
			78.48,
			78.525,
			78.59,
			78.696,
			78.713,
			78.774,
			78.837,
			78.897,
			78.961,
			79.023,
			79.085,
			79.148,
			79.24,
			79.269,
			79.347,
			79.395,
			79.457,
			79.518,
			79.572,
			79.58,
			79.644,
			79.672,
			79.705,
			79.782,
			79.83,
			79.89,
			79.954,
			80.016,
			80.077,
			80.109,
			80.14,
			80.2,
			80.216,
			80.264,
			80.326,
			80.387,
			80.449,
			80.51,
			80.542,
			80.572,
			80.65,
			80.758,
			80.82,
			80.883,
			80.946,
			80.975,
			81.009,
			81.068,
			81.085,
			81.13,
			81.193,
			81.256,
			81.318,
			81.38,
			81.41,
			81.441,
			81.518,
			81.564,
			81.633,
			81.691,
			81.751,
			81.813,
			81.843,
			81.876,
			81.954,
			81.999,
			82.062,
			82.123,
			82.168,
			82.185,
			82.248,
			82.277,
			82.31,
			82.388,
			82.433,
			82.496,
			82.558,
			82.62,
			82.68,
			82.712,
			82.743,
			82.806,
			82.865,
			82.93,
			82.991,
			83.053,
			83.115,
			83.177,
			83.255,
			83.302,
			83.365,
			83.426,
			83.488,
			83.58,
			83.61,
			83.692,
			83.735,
			83.802,
			83.858,
			83.921,
			83.983,
			84.015,
			84.046,
			84.123,
			84.169,
			84.235,
			84.293,
			84.34,
			84.418,
			84.451,
			84.48,
			84.543,
			84.557,
			84.603,
			84.668,
			84.726,
			84.789,
			84.881,
			84.914,
			84.991,
			85.038,
			85.1,
			85.163,
			85.223,
			85.285,
			85.316,
			85.348,
			85.425,
			85.471,
			85.535,
			85.597,
			85.658,
			85.758,
			85.782,
			85.859,
			85.906,
			85.968,
			86.03,
			86.077,
			86.09,
			86.153,
			86.185,
			86.216,
			86.278,
			86.34,
			86.402,
			86.464,
			86.526,
			86.589,
			86.617,
			86.648,
			86.726,
			86.774,
			86.835,
			86.899,
			86.945,
			86.961,
			87.053,
			87.083,
			87.161,
			87.207,
			87.27,
			87.331,
			87.394,
			87.456,
			87.488,
			87.519,
			87.579,
			87.643,
			87.702,
			87.765,
			87.829,
			87.921,
			87.95,
			88.03,
			88.077,
			88.14,
			88.2,
			88.248,
			88.273,
			88.325,
			88.384,
			88.448,
			88.464,
			88.509,
			88.573,
			88.68,
			88.759,
			88.788,
			88.821,
			88.898,
			88.945,
			89.069,
			89.131,
			89.192,
			89.222,
			89.254,
			89.314,
			89.378,
			89.445,
			89.502,
			89.565,
			89.625,
			89.658,
			89.689,
			89.767,
			89.812,
			89.878,
			89.936,
			89.983,
			90.061,
			90.123,
			90.2,
			90.246,
			90.309,
			90.371,
			90.432,
			90.493,
			90.526,
			90.556,
			90.635,
			90.681,
			90.749,
			90.806,
			90.85,
			90.865,
			90.928,
			90.989,
			91.053,
			91.115,
			91.177,
			91.24,
			91.302,
			91.363,
			91.426,
			91.502,
			91.549,
			91.61,
			91.673,
			91.72,
			91.733,
			91.828,
			91.859,
			91.942,
			91.982,
			92.045,
			92.105,
			92.168,
			92.23,
			92.294,
			92.355,
			92.415,
			92.479,
			92.539,
			92.603,
			92.665,
			92.695,
			92.725,
			92.803,
			92.85,
			92.913,
			92.973,
			93.038,
			93.099,
			93.131,
			93.161,
			93.223,
			93.239,
			93.286,
			93.346,
			93.408,
			93.455,
			93.534,
			93.563,
			93.595,
			93.672,
			93.72,
			93.782,
			93.844,
			93.906,
			93.967,
			94.029,
			94.092,
			94.107,
			94.153,
			94.216,
			94.277,
			94.323,
			94.338,
			94.431,
			94.464,
			94.539,
			94.585,
			94.65,
			94.772,
			94.836,
			94.867,
			94.897,
			94.959,
			95.021,
			95.083,
			95.145,
			95.208,
			95.3,
			95.333,
			95.409,
			95.456,
			95.522,
			95.58,
			95.626,
			95.641,
			95.704,
			95.767,
			95.826,
			95.844,
			95.891,
			95.95,
			96.014,
			96.076,
			96.138,
			96.198,
			96.278,
			96.322,
			96.385,
			96.446,
			96.493,
			96.509,
			96.602,
			96.632,
			96.71,
			96.759,
			96.818,
			96.88,
			96.943,
			97.006,
			97.035,
			97.068,
			97.129,
			97.19,
			97.255,
			97.314,
			97.362,
			97.471,
			97.5,
			97.579,
			97.626,
			97.687,
			97.75,
			97.812,
			97.873,
			97.906,
			97.937,
			97.997,
			98.013,
			98.06,
			98.124,
			98.184,
			98.229,
			98.339,
			98.371,
			98.447,
			98.493,
			98.556,
			98.619,
			98.679,
			98.743,
			98.812,
			98.868,
			98.881,
			98.929,
			98.991,
			99.053,
			99.115,
			99.207,
			99.24,
			99.314,
			99.363,
			99.425,
			99.549,
			99.642,
			99.673,
			99.75,
			99.797,
			99.858,
			99.92,
			99.983,
			100.043,
			100.107,
			100.189,
			100.229,
			100.291,
			100.355,
			100.415,
			100.478,
			100.54,
			100.602,
			100.618,
			100.665,
			100.728,
			100.788,
			100.851,
			100.943,
			100.975,
			101.053,
			101.099,
			101.159,
			101.221,
			101.27,
			101.285,
			101.408,
			101.47,
			101.53,
			101.594,
			101.656,
			101.719,
			101.78,
			101.812,
			101.843,
			101.921,
			101.968,
			102.029,
			102.091,
			102.136,
			102.253,
			102.355,
			102.4,
			102.464,
			102.523,
			102.587,
			102.648,
			102.68,
			102.71,
			102.773,
			102.835,
			102.898,
			103.006,
			103.082,
			103.114,
			103.144,
			103.223,
			103.33,
			103.392,
			103.455,
			103.515,
			103.548,
			103.58,
			103.657,
			103.703,
			103.764,
			103.825,
			103.872,
			103.888,
			103.95,
			104.013,
			104.09,
			104.197,
			104.262,
			104.322,
			104.383,
			104.415,
			104.448,
			104.51,
			104.572,
			104.633,
			104.694,
			104.758,
			104.851,
			104.882,
			104.96,
			105.006,
			105.068,
			105.13,
			105.175,
			105.191,
			105.253,
			105.285,
			105.315,
			105.377,
			105.44,
			105.502,
			105.564,
			105.626,
			105.688,
			105.719,
			105.749,
			105.826,
			105.872,
			105.936,
			105.998,
			106.058,
			106.183,
			106.245,
			106.26,
			106.308,
			106.374,
			106.43,
			106.492,
			106.554,
			106.587,
			106.617,
			106.696,
			106.741,
			106.812,
			106.866,
			106.926,
			106.99,
			107.051,
			107.115,
			107.145,
			107.176,
			107.237,
			107.254,
			107.3,
			107.361,
			107.471,
			107.486,
			107.579,
			107.608,
			107.688,
			107.733,
			107.799,
			107.858,
			107.919,
			108.011,
			108.044,
			108.106,
			108.168,
			108.23,
			108.291,
			108.354,
			108.416,
			108.455,
			108.556,
			108.601,
			108.664,
			108.725,
			108.772,
			108.788,
			108.851,
			108.91,
			108.975,
			108.988,
			109.036,
			109.098,
			109.16,
			109.22,
			109.314,
			109.344,
			109.424,
			109.469,
			109.533,
			109.594,
			109.656,
			109.718,
			109.747,
			109.781,
			109.858,
			109.904,
			109.965,
			110.029,
			110.09,
			110.183,
			110.213,
			110.29,
			110.338,
			110.401,
			110.462,
			110.523,
			110.585,
			110.615,
			110.647,
			110.726,
			110.773,
			110.835,
			110.895,
			110.958,
			111.021,
			111.051,
			111.083,
			111.16,
			111.206,
			111.268,
			111.329,
			111.393,
			111.454,
			111.485,
			111.517,
			111.594,
			111.64,
			111.707,
			111.81,
			111.887,
			111.92,
			111.95,
			112.013,
			112.074,
			112.138,
			112.197,
			112.26,
			112.321,
			112.354,
			112.386,
			112.463,
			112.509,
			112.575,
			112.632,
			112.677,
			112.693,
			112.755,
			112.788,
			112.879,
			112.941,
			113.005,
			113.067,
			113.13,
			113.19,
			113.251,
			113.331,
			113.375,
			113.44,
			113.5,
			113.548,
			113.564,
			113.657,
			113.687,
			113.748,
			113.81,
			113.874,
			113.935,
			113.995,
			114.09,
			114.12,
			114.2,
			114.245,
			114.305,
			114.369,
			114.416,
			114.43,
			114.493,
			114.523,
			114.555,
			114.617,
			114.639,
			114.679,
			114.742,
			114.802,
			114.865,
			114.925,
			114.957,
			114.989,
			115.051,
			115.065,
			115.113,
			115.175,
			115.235,
			115.284,
			115.36,
			115.393,
			115.422,
			115.5,
			115.547,
			115.608,
			115.67,
			115.734,
			115.795,
			115.827,
			115.858,
			115.918,
			115.936,
			115.982,
			116.044,
			116.106,
			116.154,
			116.23,
			116.259,
			116.291,
			116.368,
			116.414,
			116.538,
			116.603,
			116.664,
			116.725,
			116.787,
			116.803,
			116.848,
			116.912,
			116.972,
			117.02,
			117.035,
			117.096,
			117.129,
			117.158,
			117.236,
			117.282,
			117.347,
			117.406,
			117.454,
			117.562,
			117.592,
			117.655,
			117.717,
			117.78,
			117.84,
			117.904,
			117.966,
			117.997,
			118.035,
			118.106,
			118.151,
			118.215,
			118.275,
			118.322,
			118.335,
			118.429,
			118.461,
			118.54,
			118.586,
			118.647,
			118.71,
			118.773,
			118.866,
			118.896,
			118.974,
			119.02,
			119.083,
			119.144,
			119.206,
			119.268,
			119.299,
			119.391,
			119.452,
			119.519,
			119.577,
			119.641,
			119.702,
			119.732,
			119.764,
			119.84,
			119.888,
			119.95,
			120.01,
			120.059,
			120.168,
			120.197,
			120.32,
			120.385,
			120.445,
			120.507,
			120.6,
			120.632,
			120.693,
			120.709,
			120.754,
			120.823,
			120.881,
			120.925,
			120.943,
			121.005,
			121.065,
			121.144,
			121.191,
			121.253,
			121.312,
			121.374,
			121.468,
			121.499,
			121.562,
			121.624,
			121.686,
			121.795,
			121.81,
			121.872,
			121.933,
			122.01,
			122.058,
			122.12,
			122.182,
			122.243,
			122.306,
			122.335,
			122.368,
			122.445,
			122.493,
			122.556,
			122.618,
			122.677,
			122.771,
			122.803,
			122.885,
			122.926,
			122.989,
			123.051,
			123.096,
			123.115,
			123.175,
			123.205,
			123.236,
			123.297,
			123.315,
			123.359,
			123.423,
			123.484,
			123.547,
			123.607,
			123.639,
			123.671,
			123.749,
			123.795,
			123.857,
			123.919,
			123.964,
			123.98,
			124.042,
			124.074,
			124.103,
			124.165,
			124.181,
			124.229,
			124.291,
			124.35,
			124.414,
			124.477,
			124.539,
			124.616,
			124.662,
			124.725,
			124.834,
			124.91,
			124.973,
			125.05,
			125.096,
			125.159,
			125.221,
			125.283,
			125.344,
			125.375,
			125.406,
			125.469,
			125.532,
			125.598,
			125.699,
			125.715,
			125.779,
			125.839,
			125.918,
			125.965,
			126.028,
			126.152,
			126.211,
			126.273,
			126.337,
			126.398,
			126.46,
			126.523,
			126.569,
			126.585,
			126.648,
			126.679,
			126.708,
			126.786,
			126.832,
			126.895,
			126.958,
			127.008,
			127.081,
			127.143,
			127.205,
			127.268,
			127.331,
			127.392,
			127.453,
			127.516,
			127.546,
			127.577,
			127.656,
			127.701,
			127.764,
			127.873,
			127.887,
			127.98,
			128.01,
			128.088,
			128.136,
			128.198,
			128.259,
			128.321,
			128.382,
			128.414,
			128.446,
			128.523,
			128.568,
			128.63,
			128.693,
			128.741,
			128.755,
			128.815,
			128.88,
			128.958,
			129.064,
			129.126,
			129.19,
			129.282,
			129.312,
			129.376,
			129.389,
			129.438,
			129.498,
			129.561,
			129.607,
			129.685,
			129.748,
			129.824,
			129.872,
			129.932,
			130.056,
			130.12,
			130.149,
			130.183,
			130.243,
			130.258,
			130.306,
			130.373,
			130.431,
			130.49,
			130.553,
			130.585,
			130.616,
			130.693,
			130.74,
			130.802,
			130.864,
			130.91,
			131.019,
			131.049,
			131.111,
			131.133,
			131.173,
			131.237,
			131.297,
			131.359,
			131.42,
			131.452,
			131.483,
			131.56,
			131.608,
			131.67,
			131.777,
			131.795,
			131.855,
			131.887,
			131.919,
			132.043,
			132.105,
			132.167,
			132.226,
			132.288,
			132.352,
			132.428,
			132.475,
			132.539,
			132.6,
			132.662,
			132.725,
			132.787,
			132.863,
			132.973,
			133.035,
			133.095,
			133.159,
			133.235,
			133.297,
			133.329,
			133.361,
			133.421,
			133.438,
			133.483,
			133.547,
			133.609,
			133.67,
			133.764,
			133.795,
			133.872,
			133.918,
			133.981,
			134.088,
			134.103,
			134.197,
			134.226,
			134.288,
			134.306,
			134.352,
			134.414,
			134.476,
			134.538,
			134.599,
			134.631,
			134.661,
			134.739,
			134.787,
			134.848,
			134.91,
			134.957,
			135.033,
			135.065,
			135.094,
			135.158,
			135.22,
			135.29,
			135.344,
			135.406,
			135.468,
			135.498,
			135.533,
			135.608,
			135.655,
			135.716,
			135.825,
			135.84,
			135.933,
			135.964,
			136.042,
			136.088,
			136.151,
			136.213,
			136.275,
			136.336,
			136.368,
			136.398,
			136.46,
			136.523,
			136.585,
			136.647,
			136.693,
			136.707,
			136.802,
			136.833,
			136.91,
			136.955,
			137.018,
			137.143,
			137.203,
			137.233,
			137.266,
			137.328,
			137.391,
			137.453,
			137.515,
			137.577,
			137.638,
			137.67,
			137.699,
			137.778,
			137.823,
			137.891,
			137.949,
			138.011,
			138.072,
			138.133,
			138.195,
			138.258,
			138.321,
			138.38,
			138.444,
			138.506,
			138.537,
			138.567,
			138.645,
			138.692,
			138.755,
			138.816,
			138.865,
			138.941,
			138.973,
			139.001,
			139.081,
			139.126,
			139.189,
			139.25,
			139.312,
			139.375,
			139.412,
			139.436,
			139.498,
			139.514,
			139.561,
			139.621,
			139.684,
			139.731,
			139.808,
			139.839,
			139.871,
			139.948,
			139.995,
			140.055,
			140.118,
			140.18,
			140.243,
			140.273,
			140.306,
			140.382,
			140.429,
			140.49,
			140.6,
			140.616,
			140.678,
			140.74,
			140.815,
			140.864,
			140.925,
			140.988,
			141.034,
			141.109,
			141.14,
			141.174,
			141.236,
			141.297,
			141.359,
			141.422,
			141.483,
			141.577,
			141.608,
			141.684,
			141.731,
			141.793,
			141.856,
			141.917,
			141.98,
			142.041,
			142.118,
			142.164,
			142.229,
			142.291,
			142.35,
			142.413,
			142.444,
			142.476,
			142.554,
			142.6,
			142.664,
			142.723,
			142.771,
			142.783,
			142.878,
			142.91,
			142.972,
			143.033,
			143.095,
			143.158,
			143.218,
			143.28,
			143.343,
			143.421,
			143.468,
			143.535,
			143.59,
			143.636,
			143.654,
			143.715,
			143.778,
			143.856,
			143.909,
			143.97,
			144.026,
			144.087,
			144.15,
			144.179,
			144.212,
			144.289,
			144.336,
			144.397,
			144.459,
			144.506,
			144.616,
			144.646,
			144.723,
			144.769,
			144.831,
			144.892,
			144.956,
			145.018,
			145.049,
			145.081,
			145.156,
			145.203,
			145.267,
			145.374,
			145.391,
			145.452,
			145.513,
			145.577,
			145.596,
			145.639,
			145.699,
			145.761,
			145.823,
			145.885,
			145.915,
			145.948,
			146.025,
			146.072,
			146.132,
			146.198,
			146.242,
			146.256,
			146.321,
			146.351,
			146.382,
			146.46,
			146.506,
			146.568,
			146.63,
			146.69,
			146.754,
			146.785,
			146.815,
			146.878,
			146.941,
			147.002,
			147.111,
			147.189,
			147.219,
			147.25,
			147.326,
			147.373,
			147.436,
			147.499,
			147.561,
			147.622,
			147.658,
			147.684,
			147.761,
			147.809,
			147.871,
			147.98,
			148.088,
			148.118,
			148.196,
			148.241,
			148.303,
			148.366,
			148.428,
			148.49,
			148.552,
			148.615,
			148.628,
			148.676,
			148.74,
			148.8,
			148.865,
			148.925,
			148.955,
			148.987,
			149.064,
			149.111,
			149.173,
			149.235,
			149.282,
			149.421,
			149.483,
			149.543,
			149.605,
			149.668,
			149.73,
			149.791,
			149.824,
			149.855,
			149.931,
			149.98,
			150.04,
			150.104,
			150.148,
			150.166,
			150.225,
			150.288,
			150.365,
			150.412,
			150.475,
			150.538,
			150.597,
			150.662,
			150.692,
			150.723,
			150.785,
			150.846,
			150.914,
			150.97,
			151.016,
			151.033,
			151.095,
			151.157,
			151.233,
			151.279,
			151.343,
			151.403,
			151.467,
			151.529,
			151.558,
			151.589,
			151.667,
			151.715,
			151.782,
			151.839,
			151.885,
			151.962,
			151.993,
			152.025,
			152.102,
			152.148,
			152.211,
			152.273,
			152.321,
			152.335,
			152.427,
			152.46,
			152.521,
			152.535,
			152.581,
			152.644,
			152.707,
			152.77,
			152.832,
			152.894,
			152.969,
			153.017,
			153.079,
			153.188,
			153.296,
			153.327,
			153.406,
			153.45,
			153.513,
			153.574,
			153.635,
			153.7,
			153.729,
			153.76,
			153.845,
			153.883,
			153.948,
			154.008,
			154.054,
			154.164,
			154.195,
			154.273,
			154.32,
			154.38,
			154.444,
			154.505,
			154.598,
			154.63,
			154.707,
			154.753,
			154.816,
			154.878,
			154.924,
			154.939,
			155,
			155.064,
			155.139,
			155.188,
			155.249,
			155.31,
			155.374,
			155.435,
			155.498,
			155.575,
			155.623,
			155.684,
			155.744,
			155.79,
			155.808,
			155.87,
			155.907,
			155.932,
			156.01,
			156.118,
			156.179,
			156.243,
			156.303,
			156.333,
			156.364,
			156.427,
			156.488,
			156.55,
			156.612,
			156.661,
			156.738,
			156.767,
			156.801,
			156.877,
			156.923,
			156.986,
			157.109,
			157.172,
			157.202,
			157.233,
			157.296,
			157.357,
			157.421,
			157.483,
			157.528,
			157.543,
			157.636,
			157.668,
			157.729,
			157.792,
			157.854,
			157.914,
			157.976,
			158.04,
			158.069,
			158.102,
			158.164,
			158.18,
			158.224,
			158.288,
			158.35,
			158.396,
			158.412,
			158.474,
			158.506,
			158.536,
			158.614,
			158.722,
			158.785,
			158.846,
			158.907,
			158.939,
			158.969,
			159.048,
			159.094,
			159.155,
			159.218,
			159.265,
			159.28,
			159.343,
			159.374,
			159.404,
			159.483,
			159.527,
			159.652,
			159.715,
			159.775,
			159.807,
			159.836,
			159.901,
			159.962,
			160.029,
			160.132,
			160.21,
			160.24,
			160.272,
			160.35,
			160.396,
			160.46,
			160.52,
			160.581,
			160.675,
			160.707,
			160.768,
			160.832,
			160.891,
			160.954,
			161.015,
			161.077,
			161.108,
			161.14,
			161.218,
			161.264,
			161.328,
			161.388,
			161.451,
			161.542,
			161.574,
			161.636,
			161.698,
			161.762,
			161.823,
			161.883,
			161.978,
			162.01,
			162.072,
			162.091,
			162.133,
			162.198,
			162.304,
			162.381,
			162.415,
			162.506,
			162.567,
			162.63,
			162.69,
			162.752,
			162.815,
			162.877,
			162.953,
			163.002,
			163.062,
			163.172,
			163.188,
			163.248,
			163.279,
			163.311,
			163.435,
			163.496,
			163.558,
			163.622,
			163.683,
			163.712,
			163.745,
			163.821,
			163.868,
			163.929,
			163.992,
			164.039,
			164.055,
			164.116,
			164.152,
			164.179,
			164.255,
			164.302,
			164.366,
			164.427,
			164.488,
			164.582,
			164.614,
			164.691,
			164.738,
			164.799,
			164.908,
			164.986,
			165.015,
			165.048,
			165.125,
			165.171,
			165.232,
			165.295,
			165.356,
			165.417,
			165.45,
			165.482,
			165.542,
			165.557,
			165.605,
			165.668,
			165.728,
			165.792,
			165.883,
			165.915,
			165.993,
			166.039,
			166.1,
			166.163,
			166.225,
			166.318,
			166.35,
			166.412,
			166.474,
			166.536,
			166.596,
			166.659,
			166.721,
			166.753,
			166.784,
			166.862,
			166.908,
			166.97,
			167.03,
			167.095,
			167.156,
			167.188,
			167.218,
			167.278,
			167.294,
			167.342,
			167.405,
			167.464,
			167.529,
			167.591,
			167.621,
			167.653,
			167.715,
			167.777,
			167.838,
			167.898,
			167.945,
			167.961,
			168.023,
			168.055,
			168.085,
			168.164,
			168.21,
			168.274,
			168.288,
			168.342,
			168.396,
			168.459,
			168.52,
			168.583,
			168.643,
			168.721,
			168.767,
			168.83,
			168.892,
			168.953,
			169.017,
			169.046,
			169.078,
			169.156,
			169.202,
			169.264,
			169.327,
			169.389,
			169.448,
			169.48,
			169.513,
			169.59,
			169.635,
			169.697,
			169.759,
			169.807,
			169.884,
			169.915,
			169.947,
			170.024,
			170.071,
			170.193,
			170.255,
			170.319,
			170.381,
			170.443,
			170.505,
			170.566,
			170.629,
			170.691,
			170.75,
			170.783,
			170.813,
			170.89,
			170.939,
			171.001,
			171.064,
			171.123,
			171.186,
			171.248,
			171.311,
			171.372,
			171.433,
			171.495,
			171.559,
			171.62,
			171.682,
			171.758,
			171.807,
			171.869,
			171.929,
			171.992,
			172.054,
			172.116,
			172.178,
			172.24,
			172.303,
			172.365,
			172.425,
			172.488,
			172.551,
			172.627,
			172.673,
			172.736,
			172.799,
			172.846,
			172.861,
			172.955,
			173.046,
			173.11,
			173.172,
			173.232,
			173.295,
			173.355,
			173.388,
			173.42,
			173.497,
			173.543,
			173.606,
			173.713,
			173.791,
			173.823,
			173.853,
			173.916,
			173.929,
			173.977,
			174.04,
			174.1,
			174.164,
			174.225,
			174.286,
			174.348,
			174.364,
			174.411,
			174.479,
			174.535,
			174.597,
			174.66,
			174.691,
			174.722,
			174.798,
			174.845,
			174.907,
			174.969,
			175.029,
			175.092,
			175.123,
			175.155,
			175.215,
			175.232,
			175.28,
			175.342,
			175.449,
			175.465,
			175.527,
			175.559,
			175.59,
			175.668,
			175.712,
			175.776,
			175.838,
			175.899,
			175.961,
			175.992,
			176.024,
			176.086,
			176.148,
			176.213,
			176.27,
			176.334,
			176.426,
			176.458,
			176.539,
			176.582,
			176.644,
			176.752,
			176.765,
			176.859,
			176.89,
			176.968,
			177.016,
			177.076,
			177.14,
			177.2,
			177.264,
			177.325,
			177.402,
			177.448,
			177.512,
			177.572,
			177.62,
			177.635,
			177.697,
			177.73,
			177.759,
			177.82,
			177.838,
			177.884,
			177.945,
			178.007,
			178.07,
			178.161,
			178.193,
			178.271,
			178.318,
			178.381,
			178.442,
			178.488,
			178.504,
			178.566,
			178.601,
			178.627,
			178.752,
			178.813,
			178.876,
			178.936,
			179,
			179.062,
			179.122,
			179.14,
			179.185,
			179.249,
			179.31,
			179.355,
			179.433,
			179.465,
			179.494,
			179.572,
			179.62,
			179.68,
			179.743,
			179.806,
			179.869,
			179.899,
			179.93,
			180.006,
			180.053,
			180.117,
			180.177,
			180.225,
			180.241,
			180.3,
			180.332,
			180.364,
			180.441,
			180.488,
			180.551,
			180.613,
			180.674,
			180.736,
			180.765,
			180.798,
			180.86,
			180.922,
			180.983,
			181.046,
			181.108,
			181.168,
			181.202,
			181.23,
			181.31,
			181.355,
			181.417,
			181.481,
			181.526,
			181.541,
			181.605,
			181.664,
			181.729,
			181.791,
			181.852,
			181.915,
			181.975,
			182.07,
			182.099,
			182.162,
			182.224,
			182.287,
			182.349,
			182.396,
			182.409,
			182.472,
			182.502,
			182.534,
			182.612,
			182.657,
			182.721,
			182.783,
			182.844,
			182.906,
			182.938,
			182.967,
			183.03,
			183.093,
			183.156,
			183.215,
			183.261,
			183.279,
			183.339,
			183.403,
			183.478,
			183.527,
			183.588,
			183.65,
			183.712,
			183.773,
			183.806,
			183.835,
			183.897,
			183.96,
			184.026,
			184.083,
			184.146,
			184.21,
			184.271,
			184.349,
			184.394,
			184.458,
			184.519,
			184.579,
			184.644,
			184.673,
			184.706,
			184.768,
			184.828,
			184.891,
			184.998,
			185.014,
			185.077,
			185.139,
			185.217,
			185.262,
			185.325,
			185.387,
			185.448,
			185.542,
			185.572,
			185.651,
			185.696,
			185.758,
			185.822,
			185.883,
			185.946,
			185.975,
			186.007,
			186.084,
			186.13,
			186.193,
			186.256,
			186.3,
			186.315,
			186.38,
			186.44,
			186.503,
			186.518,
			186.565,
			186.626,
			186.688,
			186.75,
			186.814,
			186.848,
			186.874,
			186.953,
			186.998,
			187.062,
			187.171,
			187.183,
			187.279,
			187.308,
			187.372,
			187.434,
			187.496,
			187.558,
			187.62,
			187.714,
			187.744,
			187.821,
			187.867,
			187.93,
			187.99,
			188.039,
			188.145,
			188.176,
			188.239,
			188.302,
			188.364,
			188.425,
			188.487,
			188.549,
			188.61,
			188.688,
			188.736,
			188.799,
			188.859,
			188.921,
			189.014,
			189.046,
			189.122,
			189.17,
			189.23,
			189.292,
			189.355,
			189.415,
			189.48,
			189.541,
			189.557,
			189.603,
			189.665,
			189.728,
			189.775,
			189.79,
			189.851,
			189.882,
			189.914,
			189.99,
			190.038,
			190.099,
			190.162,
			190.223,
			190.286,
			190.316,
			190.347,
			190.41,
			190.472,
			190.532,
			190.596,
			190.643,
			190.656,
			190.72,
			190.783,
			190.859,
			190.906,
			190.968,
			191.031,
			191.093,
			191.153,
			191.185,
			191.215,
			191.277,
			191.293,
			191.339,
			191.408,
			191.463,
			191.525,
			191.588,
			191.635,
			191.741,
			191.774,
			191.85,
			191.896,
			191.961,
			192.023,
			192.084,
			192.177,
			192.208,
			192.268,
			192.332,
			192.394,
			192.455,
			192.502,
			192.519,
			192.58,
			192.61,
			192.641,
			192.721,
			192.764,
			192.827,
			192.891,
			192.952,
			193.013,
			193.051,
			193.075,
			193.155,
			193.198,
			193.264,
			193.371,
			193.387,
			193.478,
			193.511,
			193.588,
			193.635,
			193.698,
			193.82,
			193.913,
			193.945,
			194.006,
			194.068,
			194.132,
			194.193,
			194.254,
			194.315,
			194.348,
			194.378,
			194.457,
			194.5,
			194.565,
			194.626,
			194.672,
			194.688,
			194.749,
			194.812,
			194.875,
			194.891,
			194.937,
			194.999,
			195.06,
			195.123,
			195.216,
			195.245,
			195.325,
			195.37,
			195.432,
			195.494,
			195.556,
			195.648,
			195.681,
			195.758,
			195.805,
			195.866,
			195.929,
			195.989,
			196.053,
			196.083,
			196.116,
			196.192,
			196.239,
			196.3,
			196.361,
			196.41,
			196.486,
			196.519,
			196.549,
			196.612,
			196.673,
			196.735,
			196.798,
			196.858,
			196.92,
			196.95,
			196.981,
			197.046,
			197.059,
			197.106,
			197.175,
			197.231,
			197.28,
			197.356,
			197.385,
			197.415,
			197.493,
			197.539,
			197.602,
			197.666,
			197.728,
			197.788,
			197.819,
			197.851,
			197.927,
			197.974,
			198.038,
			198.097,
			198.145,
			198.161,
			198.223,
			198.255,
			198.285,
			198.362,
			198.472,
			198.532,
			198.596,
			198.688,
			198.718,
			198.78,
			198.842,
			198.906,
			198.965,
			199.029,
			199.091,
			199.122,
			199.154,
			199.235,
			199.277,
			199.338,
			199.464,
			199.524,
			199.585,
			199.65,
			199.664,
			199.711,
			199.772,
			199.882,
			199.958,
			199.991,
			200.022,
			200.099,
			200.146,
			200.207,
			200.27,
			200.333,
			200.394,
			200.423,
			200.455,
			200.516,
			200.533,
			200.579,
			200.64,
			200.749,
			200.765,
			200.828,
			200.89,
			200.967,
			201.012,
			201.075,
			201.138,
			201.199,
			201.262,
			201.323,
			201.386,
			201.447,
			201.51,
			201.572,
			201.632,
			201.696,
			201.727,
			201.757,
			201.835,
			201.882,
			201.944,
			202.006,
			202.066,
			202.131,
			202.162,
			202.19,
			202.268,
			202.316,
			202.379,
			202.438,
			202.502,
			202.565,
			202.624,
			202.704,
			202.749,
			202.813,
			202.875,
			202.936,
			203.027,
			203.058,
			203.12,
			203.138,
			203.183,
			203.248,
			203.308,
			203.368,
			203.437,
			203.463,
			203.494,
			203.57,
			203.618,
			203.679,
			203.742,
			203.805,
			203.865,
			203.928,
			204.006,
			204.053,
			204.115,
			204.175,
			204.239,
			204.332,
			204.364,
			204.44,
			204.486,
			204.55,
			204.611,
			204.658,
			204.765,
			204.797,
			204.921,
			204.982,
			205.043,
			205.106,
			205.169,
			205.199,
			205.229,
			205.293,
			205.309,
			205.354,
			205.421,
			205.523,
			205.539,
			205.632,
			205.665,
			205.743,
			205.788,
			205.851,
			205.912,
			205.975,
			206.067,
			206.099,
			206.161,
			206.222,
			206.285,
			206.346,
			206.393,
			206.409,
			206.469,
			206.502,
			206.533,
			206.61,
			206.657,
			206.718,
			206.78,
			206.843,
			206.904,
			206.935,
			206.965,
			207.029,
			207.091,
			207.153,
			207.216,
			207.261,
			207.339,
			207.4,
			207.485,
			207.523,
			207.587,
			207.695,
			207.71,
			207.773,
			207.804,
			207.836,
			207.895,
			207.913,
			207.959,
			208.02,
			208.082,
			208.145,
			208.207,
			208.238,
			208.269,
			208.346,
			208.392,
			208.456,
			208.515,
			208.563,
			208.58,
			208.64,
			208.703,
			208.766,
			208.779,
			208.828,
			208.888,
			208.952,
			209.013,
			209.073,
			209.136,
			209.199,
			209.213,
			209.26,
			209.324,
			209.386,
			209.432,
			209.507,
			209.542,
			209.571,
			209.647,
			209.693,
			209.757,
			209.819,
			209.882,
			209.944,
			210.006,
			210.067,
			210.082,
			210.129,
			210.196,
			210.3,
			210.315,
			210.409,
			210.439,
			210.518,
			210.626,
			210.688,
			210.749,
			210.81,
			210.842,
			210.874,
			210.936,
			210.997,
			211.059,
			211.122,
			211.168,
			211.183,
			211.277,
			211.308,
			211.383,
			211.432,
			211.493,
			211.556,
			211.618,
			211.679,
			211.709,
			211.74,
			211.803,
			211.866,
			211.926,
			211.988,
			212.051,
			212.112,
			212.143,
			212.175,
			212.253,
			212.3,
			212.36,
			212.423,
			212.471,
			212.486,
			212.579,
			212.608,
			212.672,
			212.688,
			212.732,
			212.795,
			212.858,
			212.92,
			212.981,
			213.012,
			213.043,
			213.12,
			213.167,
			213.231,
			213.29,
			213.339,
			213.353,
			213.415,
			213.476,
			213.541,
			213.556,
			213.602,
			213.668,
			213.725,
			213.788,
			213.881,
			213.913,
			213.989,
			214.037,
			214.097,
			214.16,
			214.207,
			214.314,
			214.347,
			214.423,
			214.469,
			214.532,
			214.595,
			214.656,
			214.718,
			214.748,
			214.78,
			214.856,
			214.904,
			214.965,
			215.026,
			215.09,
			215.153,
			215.181,
			215.213,
			215.291,
			215.338,
			215.399,
			215.461,
			215.523,
			215.587,
			215.617,
			215.647,
			215.711,
			215.731,
			215.772,
			215.834,
			215.896,
			215.958,
			216.02,
			216.083,
			216.158,
			216.206,
			216.269,
			216.33,
			216.392,
			216.454,
			216.517,
			216.579,
			216.639,
			216.702,
			216.811,
			216.888,
			216.919,
			216.949,
			217.027,
			217.073,
			217.136,
			217.198,
			217.261,
			217.322,
			217.353,
			217.385,
			217.447,
			217.463,
			217.507,
			217.571,
			217.633,
			217.695,
			217.755,
			217.79,
			217.819,
			217.895,
			217.942,
			218.005,
			218.065,
			218.113,
			218.189,
			218.221,
			218.254,
			218.316,
			218.33,
			218.376,
			218.439,
			218.501,
			218.563,
			218.624,
			218.686,
			218.765,
			218.809,
			218.872,
			218.935,
			218.98,
			219.058,
			219.089,
			219.12,
			219.182,
			219.246,
			219.306,
			219.369,
			219.431,
			219.525,
			219.556,
			219.632,
			219.677,
			219.742,
			219.802,
			219.851,
			219.865,
			219.926,
			219.958,
			219.988,
			220.113,
			220.176,
			220.238,
			220.297,
			220.361,
			220.392,
			220.423,
			220.501,
			220.548,
			220.61,
			220.672,
			220.732,
			220.826,
			220.855,
			220.935,
			220.981,
			221.043,
			221.106,
			221.168,
			221.23,
			221.261,
			221.291,
			221.354,
			221.415,
			221.477,
			221.54,
			221.586,
			221.662,
			221.694,
			221.726,
			221.802,
			221.848,
			221.918,
			222.035,
			222.128,
			222.16,
			222.22,
			222.236,
			222.283,
			222.346,
			222.406,
			222.469,
			222.531,
			222.562,
			222.593,
			222.669,
			222.715,
			222.781,
			222.842,
			222.887,
			223.028,
			223.09,
			223.105,
			223.151,
			223.212,
			223.276,
			223.338,
			223.4,
			223.43,
			223.462,
			223.54,
			223.586,
			223.652,
			223.708,
			223.754,
			223.833,
			223.863,
			223.897,
			223.957,
			224.021,
			224.082,
			224.144,
			224.206,
			224.268,
			224.298,
			224.331,
			224.408,
			224.453,
			224.516,
			224.623,
			224.639,
			224.7,
			224.762,
			224.84,
			224.888,
			224.95,
			225.013,
			225.074,
			225.165,
			225.199,
			225.261,
			225.32,
			225.385,
			225.447,
			225.508,
			225.599,
			225.632,
			225.71,
			225.756,
			225.818,
			225.88,
			225.942,
			226.003,
			226.038,
			226.064,
			226.126,
			226.19,
			226.252,
			226.361,
			226.439,
			226.469,
			226.498,
			226.577,
			226.622,
			226.685,
			226.796,
			226.81,
			226.904,
			226.934,
			227.011,
			227.059,
			227.12,
			227.183,
			227.244,
			227.306,
			227.336,
			227.369,
			227.446,
			227.493,
			227.555,
			227.615,
			227.679,
			227.741,
			227.769,
			227.8,
			227.864,
			227.88,
			227.926,
			227.989,
			228.05,
			228.113,
			228.173,
			228.205,
			228.236,
			228.313,
			228.361,
			228.423,
			228.485,
			228.531,
			228.608,
			228.64,
			228.679,
			228.749,
			228.811,
			228.856,
			228.933,
			228.966,
			228.995,
			229.059,
			229.119,
			229.18,
			229.243,
			229.306,
			229.368,
			229.398,
			229.43,
			229.507,
			229.554,
			229.617,
			229.679,
			229.723,
			229.739,
			229.801,
			229.863,
			229.942,
			229.988,
			230.05,
			230.111,
			230.174,
			230.235,
			230.268,
			230.299,
			230.375,
			230.423,
			230.483,
			230.545,
			230.593,
			230.607,
			230.702,
			230.733,
			230.793,
			230.809,
			230.855,
			230.919,
			230.981,
			231.043,
			231.136,
			231.165,
			231.243,
			231.289,
			231.353,
			231.46,
			231.538,
			231.599,
			231.678,
			231.725,
			231.786,
			231.848,
			231.91,
			231.972,
			232.003,
			232.035,
			232.112,
			232.158,
			232.223,
			232.283,
			232.345,
			232.407,
			232.438,
			232.529,
			232.592,
			232.656,
			232.717,
			232.779,
			232.873,
			232.903,
			232.963,
			233.027,
			233.089,
			233.151,
			233.198,
			233.213,
			233.275,
			233.338,
			233.415,
			233.46,
			233.523,
			233.583,
			233.646,
			233.709,
			233.739,
			233.769,
			233.847,
			233.894,
			233.956,
			234.018,
			234.065,
			234.081,
			234.143,
			234.206,
			234.288,
			234.328,
			234.391,
			234.453,
			234.513,
			234.608,
			234.64,
			234.716,
			234.764,
			234.824,
			234.888,
			234.949,
			235.042,
			235.074,
			235.149,
			235.197,
			235.26,
			235.321,
			235.383,
			235.506,
			235.586,
			235.631,
			235.694,
			235.756,
			235.818,
			235.88,
			235.911,
			235.941,
			236.019,
			236.064,
			236.128,
			236.189,
			236.235,
			236.314,
			236.35,
			236.376,
			236.438,
			236.453,
			236.5,
			236.562,
			236.624,
			236.685,
			236.746,
			236.778,
			236.81,
			236.885,
			236.933,
			236.998,
			237.058,
			237.102,
			237.119,
			237.212,
			237.244,
			237.322,
			237.367,
			237.428,
			237.491,
			237.554,
			237.614,
			237.647,
			237.676,
			237.74,
			237.756,
			237.802,
			237.864,
			237.925,
			237.972,
			237.988,
			238.048,
			238.08,
			238.111,
			238.189,
			238.236,
			238.296,
			238.358,
			238.422,
			238.483,
			238.548,
			238.623,
			238.669,
			238.732,
			238.794,
			238.84,
			238.855,
			238.918,
			238.949,
			238.98,
			239.058,
			239.105,
			239.164,
			239.226,
			239.291,
			239.353,
			239.413,
			239.475,
			239.49,
			239.538,
			239.6,
			239.662,
			239.708,
			239.786,
			239.818,
			239.849,
			239.925,
			239.973,
			240.033,
			240.096,
			240.157,
			240.221,
			240.283,
			240.343,
			240.406,
			240.473,
			240.529,
			240.591,
			240.686,
			240.715,
			240.793,
			240.839,
			240.903,
			240.963,
			241.01,
			241.119,
			241.148,
			241.214,
			241.272,
			241.336,
			241.399,
			241.459,
			241.553,
			241.585,
			241.664,
			241.707,
			241.772,
			241.832,
			241.88,
			242.018,
			242.102,
			242.143,
			242.205,
			242.265,
			242.329,
			242.391,
			242.423,
			242.453,
			242.514,
			242.535,
			242.576,
			242.639,
			242.7,
			242.762,
			242.825,
			242.856,
			242.948,
			242.965,
			243.009,
			243.072,
			243.133,
			243.195,
			243.258,
			243.289,
			243.32,
			243.397,
			243.445,
			243.505,
			243.568,
			243.615,
			243.63,
			243.691,
			243.722,
			243.817,
			243.88,
			243.941,
			244.001,
			244.066,
			244.128,
			244.159,
			244.189,
			244.264,
			244.313,
			244.373,
			244.436,
			244.483,
			244.597,
			244.622,
			244.7,
			244.746,
			244.809,
			244.87,
			244.933,
			244.996,
			245.025,
			245.056,
			245.119,
			245.135,
			245.18,
			245.244,
			245.352,
			245.365,
			245.428,
			245.49,
			245.569,
			245.614,
			245.678,
			245.74,
			245.802,
			245.864,
			245.895,
			245.924,
			245.985,
			246.048,
			246.113,
			246.219,
			246.235,
			246.296,
			246.358,
			246.435,
			246.481,
			246.543,
			246.608,
			246.67,
			246.731,
			246.763,
			246.792,
			246.855,
			246.916,
			246.981,
			247.041,
			247.103,
			247.165,
			247.196,
			247.227,
			247.305,
			247.351,
			247.415,
			247.476,
			247.535,
			247.63,
			247.661,
			247.739,
			247.785,
			247.848,
			247.908,
			247.971,
			248.033,
			248.065,
			248.095,
			248.173,
			248.22,
			248.28,
			248.342,
			248.406,
			248.465,
			248.499,
			248.528,
			248.607,
			248.653,
			248.716,
			248.778,
			248.839,
			248.899,
			248.963,
			249.025,
			249.088,
			249.151,
			249.215,
			249.259,
			249.336,
			249.398,
			249.474,
			249.522,
			249.584,
			249.648,
			249.708,
			249.769,
			249.83,
			249.909,
			249.955,
			250.017,
			250.078,
			250.14,
			250.203,
			250.265,
			250.343,
			250.39,
			250.45,
			250.514,
			250.576,
			250.638,
			250.7,
			250.786,
			250.822,
			250.885,
			250.947,
			251.01,
			251.072,
			251.102,
			251.133,
			251.213,
			251.257,
			251.321,
			251.383,
			251.445,
			251.506,
			251.569,
			251.63,
			251.69,
			251.755,
			251.816,
			251.862,
			251.883,
			251.969,
			252.001,
			252.079,
			252.125,
			252.188,
			252.25,
			252.298,
			252.314,
			252.406,
			252.435,
			252.498,
			252.514,
			252.561,
			252.622,
			252.683,
			252.747,
			252.808,
			252.843,
			252.871,
			252.949,
			252.993,
			253.056,
			253.118,
			253.165,
			253.18,
			253.242,
			253.303,
			253.382,
			253.429,
			253.491,
			253.552,
			253.614,
			253.707,
			253.739,
			253.815,
			253.862,
			253.925,
			253.986,
			254.049,
			254.109,
			254.14,
			254.172,
			254.25,
			254.296,
			254.359,
			254.42,
			254.485,
			254.543,
			254.574,
			254.605,
			254.669,
			254.683,
			254.731,
			254.793,
			254.855,
			254.905,
			254.978,
			255.01,
			255.041,
			255.117,
			255.163,
			255.225,
			255.289,
			255.35,
			255.443,
			255.474,
			255.536,
			255.599,
			255.662,
			255.724,
			255.769,
			255.786,
			255.848,
			255.879,
			255.908,
			255.987,
			256.033,
			256.1,
			256.158,
			256.231,
			256.281,
			256.311,
			256.341,
			256.42,
			256.465,
			256.533,
			256.592,
			256.653,
			256.748,
			256.778,
			256.853,
			256.901,
			256.968,
			257.023,
			257.069,
			257.148,
			257.179,
			257.212,
			257.289,
			257.335,
			257.398,
			257.458,
			257.522,
			257.613,
			257.646,
			257.721,
			257.768,
			257.83,
			257.893,
			257.956,
			258.018,
			258.08,
			258.141,
			258.157,
			258.204,
			258.265,
			258.327,
			258.389,
			258.451,
			258.481,
			258.513,
			258.589,
			258.636,
			258.699,
			258.808,
			258.885,
			258.915,
			259.009,
			259.032,
			259.072,
			259.135,
			259.195,
			259.256,
			259.32,
			259.349,
			259.383,
			259.458,
			259.506,
			259.568,
			259.63,
			259.676,
			259.69,
			259.754,
			259.816,
			259.893,
			259.94,
			260.002,
			260.064,
			260.126,
			260.218,
			260.25,
			260.31,
			260.373,
			260.436,
			260.496,
			260.543,
			260.559,
			260.62,
			260.682,
			260.762,
			260.806,
			260.869,
			260.931,
			260.993,
			261.055,
			261.092,
			261.116,
			261.18,
			261.243,
			261.303,
			261.366,
			261.427,
			261.489,
			261.551,
			261.628,
			261.676,
			261.739,
			261.862,
			261.956,
			261.987,
			262.048,
			262.064,
			262.111,
			262.172,
			262.233,
			262.295,
			262.359,
			262.388,
			262.42,
			262.498,
			262.542,
			262.611,
			262.668,
			262.713,
			262.731,
			262.791,
			262.853,
			262.916,
			262.932,
			262.979,
			263.038,
			263.102,
			263.164,
			263.225,
			263.256,
			263.288,
			263.364,
			263.411,
			263.474,
			263.535,
			263.582,
			263.66,
			263.722,
			263.782,
			263.847,
			263.908,
			263.97,
			264.03,
			264.094,
			264.125,
			264.155,
			264.235,
			264.278,
			264.341,
			264.402,
			264.451,
			264.467,
			264.529,
			264.558,
			264.59,
			264.652,
			264.715,
			264.792,
			264.853,
			264.917,
			265.008,
			265.039,
			265.117,
			265.165,
			265.229,
			265.333,
			265.414,
			265.443,
			265.474,
			265.552,
			265.596,
			265.66,
			265.72,
			265.783,
			265.845,
			265.877,
			265.907,
			265.969,
			266.032,
			266.093,
			266.156,
			266.218,
			266.28,
			266.31,
			266.34,
			266.419,
			266.466,
			266.529,
			266.591,
			266.636,
			266.653,
			266.712,
			266.744,
			266.776,
			266.853,
			266.9,
			266.964,
			267.025,
			267.087,
			267.181,
			267.21,
			267.291,
			267.333,
			267.396,
			267.457,
			267.506,
			267.612,
			267.644,
			267.706,
			267.768,
			267.832,
			267.893,
			267.954,
			268.015,
			268.047,
			268.079,
			268.155,
			268.202,
			268.265,
			268.326,
			268.373,
			268.388,
			268.482,
			268.511,
			268.635,
			268.699,
			268.76,
			268.823,
			268.884,
			268.914,
			268.947,
			269.024,
			269.07,
			269.133,
			269.193,
			269.241,
			269.257,
			269.319,
			269.382,
			269.458,
			269.503,
			269.566,
			269.629,
			269.689,
			269.753,
			269.783,
			269.815,
			269.876,
			269.939,
			270.001,
			270.064,
			270.109,
			270.188,
			270.218,
			270.249,
			270.325,
			270.372,
			270.496,
			270.557,
			270.62,
			270.683,
			270.746,
			270.759,
			270.807,
			270.868,
			270.93,
			270.978,
			271.056,
			271.086,
			271.118,
			271.195,
			271.24,
			271.302,
			271.364,
			271.426,
			271.488,
			271.52,
			271.551,
			271.614,
			271.674,
			271.736,
			271.798,
			271.861,
			271.922,
			271.985,
			272.062,
			272.107,
			272.171,
			272.232,
			272.28,
			272.355,
			272.388,
			272.418,
			272.498,
			272.543,
			272.608,
			272.668,
			272.729,
			272.79,
			272.823,
			272.853,
			272.932,
			272.976,
			273.041,
			273.1,
			273.147,
			273.163,
			273.226,
			273.288,
			273.364,
			273.413,
			273.475,
			273.535,
			273.597,
			273.659,
			273.722,
			273.799,
			273.846,
			273.907,
			273.97,
			274.016,
			274.032,
			274.092,
			274.124,
			274.155,
			274.216,
			274.28,
			274.343,
			274.404,
			274.465,
			274.527,
			274.559,
			274.59,
			274.65,
			274.668,
			274.715,
			274.776,
			274.835,
			274.899,
			274.96,
			274.992,
			275.024,
			275.1,
			275.148,
			275.209,
			275.272,
			275.334,
			275.426,
			275.458,
			275.52,
			275.54,
			275.583,
			275.644,
			275.707,
			275.766,
			275.861,
			275.891,
			275.97,
			276.014,
			276.079,
			276.138,
			276.202,
			276.294,
			276.324,
			276.402,
			276.45,
			276.514,
			276.575,
			276.621,
			276.636,
			276.698,
			276.729,
			276.761,
			276.836,
			276.883,
			276.946,
			277.008,
			277.068,
			277.162,
			277.194,
			277.256,
			277.319,
			277.381,
			277.443,
			277.488,
			277.503,
			277.564,
			277.6,
			277.627,
			277.705,
			277.753,
			277.816,
			277.875,
			277.939,
			278.001,
			278.066,
			278.14,
			278.186,
			278.248,
			278.31,
			278.355,
			278.433,
			278.464,
			278.497,
			278.575,
			278.62,
			278.683,
			278.744,
			278.805,
			278.869,
			278.9,
			278.929,
			278.992,
			279.007,
			279.055,
			279.116,
			279.179,
			279.241,
			279.302,
			279.332,
			279.364,
			279.443,
			279.487,
			279.55,
			279.613,
			279.662,
			279.735,
			279.766,
			279.798,
			279.861,
			279.922,
			279.985,
			280.047,
			280.109,
			280.17,
			280.199,
			280.232,
			280.31,
			280.355,
			280.418,
			280.48,
			280.542,
			280.604,
			280.665,
			280.744,
			280.791,
			280.853,
			280.915,
			280.975,
			281.039,
			281.07,
			281.099,
			281.178,
			281.225,
			281.286,
			281.396,
			281.409,
			281.533,
			281.596,
			281.611,
			281.659,
			281.725,
			281.783,
			281.845,
			281.906,
			281.938,
			281.969,
			282.045,
			282.093,
			282.155,
			282.217,
			282.264,
			282.34,
			282.372,
			282.403,
			282.48,
			282.526,
			282.588,
			282.65,
			282.713,
			282.775,
			282.836,
			282.899,
			282.915,
			282.962,
			283.023,
			283.086,
			283.132,
			283.208,
			283.239,
			283.269,
			283.348,
			283.395,
			283.455,
			283.52,
			283.58,
			283.673,
			283.705,
			283.767,
			283.828,
			283.889,
			283.953,
			283.998,
			284.015,
			284.076,
			284.107,
			284.139,
			284.218,
			284.264,
			284.326,
			284.388,
			284.449,
			284.51,
			284.541,
			284.574,
			284.636,
			284.698,
			284.76,
			284.82,
			284.884,
			284.945,
			285.008,
			285.085,
			285.132,
			285.194,
			285.255,
			285.318,
			285.38,
			285.41,
			285.441,
			285.519,
			285.566,
			285.627,
			285.69,
			285.751,
			285.814,
			285.85,
			285.876,
			285.952,
			285.999,
			286.061,
			286.123,
			286.169,
			286.186,
			286.246,
			286.308,
			286.433,
			286.498,
			286.557,
			286.62,
			286.712,
			286.742,
			286.822,
			286.866,
			286.929,
			286.991,
			287.053,
			287.148,
			287.178,
			287.256,
			287.303,
			287.365,
			287.425,
			287.486,
			287.55,
			287.613,
			287.69,
			287.735,
			287.798,
			287.858,
			287.92,
			287.994,
			288.046,
			288.123,
			288.17,
			288.23,
			288.292,
			288.355,
			288.418,
			288.48,
			288.541,
			288.603,
			288.666,
			288.774,
			288.789,
			288.881,
			288.912,
			288.992,
			289.1,
			289.16,
			289.223,
			289.315,
			289.349,
			289.409,
			289.472,
			289.533,
			289.595,
			289.641,
			289.659,
			289.72,
			289.751,
			289.783,
			289.858,
			289.907,
			289.974,
			290.029,
			290.092,
			290.185,
			290.216,
			290.294,
			290.34,
			290.402,
			290.465,
			290.525,
			290.588,
			290.62,
			290.649,
			290.727,
			290.773,
			290.837,
			290.899,
			290.946,
			290.961,
			291.021,
			291.053,
			291.084,
			291.147,
			291.161,
			291.207,
			291.275,
			291.333,
			291.393,
			291.455,
			291.488,
			291.518,
			291.595,
			291.643,
			291.706,
			291.813,
			291.829,
			291.953,
			292.015,
			292.033,
			292.077,
			292.138,
			292.2,
			292.264,
			292.325,
			292.356,
			292.386,
			292.464,
			292.511,
			292.574,
			292.635,
			292.697,
			292.79,
			292.82,
			292.943,
			293.008,
			293.069,
			293.131,
			293.191,
			293.256,
			293.317,
			293.333,
			293.381,
			293.439,
			293.503,
			293.564,
			293.628,
			293.658,
			293.688,
			293.766,
			293.813,
			293.875,
			293.936,
			293.997,
			294.061,
			294.098,
			294.122,
			294.198,
			294.248,
			294.308,
			294.371,
			294.433,
			294.494,
			294.525,
			294.555,
			294.635,
			294.68,
			294.742,
			294.805,
			294.867,
			294.927,
			294.961,
			294.991,
			295.053,
			295.067,
			295.114,
			295.176,
			295.286,
			295.393,
			295.424,
			295.503,
			295.55,
			295.614,
			295.718,
			295.736,
			295.829,
			295.859,
			295.922,
			295.983,
			296.046,
			296.107,
			296.17,
			296.23,
			296.294,
			296.371,
			296.416,
			296.479,
			296.589,
			296.666,
			296.695,
			296.728,
			296.789,
			296.805,
			296.852,
			296.913,
			296.974,
			297.035,
			297.1,
			297.129,
			297.162,
			297.239,
			297.286,
			297.348,
			297.41,
			297.472,
			297.534,
			297.563,
			297.597,
			297.673,
			297.719,
			297.78,
			297.844,
			297.906,
			298,
			298.03,
			298.106,
			298.154,
			298.22,
			298.324,
			298.339,
			298.402,
			298.465,
			298.526,
			298.541,
			298.589,
			298.65,
			298.711,
			298.773,
			298.836,
			298.897,
			298.974,
			299.02,
			299.083,
			299.145,
			299.193,
			299.208,
			299.301,
			299.332,
			299.393,
			299.455,
			299.518,
			299.58,
			299.641,
			299.735,
			299.764,
			299.843,
			299.89,
			299.95,
			300.013,
			300.061,
			300.139,
			300.2,
			300.283,
			300.324,
			300.386,
			300.447,
			300.509,
			300.572,
			300.602,
			300.633,
			300.697,
			300.713,
			300.758,
			300.821,
			300.883,
			300.927,
			300.943,
			301.006,
			301.037,
			301.069,
			301.145,
			301.255,
			301.317,
			301.379,
			301.441,
			301.503,
			301.58,
			301.626,
			301.689,
			301.751,
			301.813,
			301.874,
			301.905,
			301.936,
			302.013,
			302.059,
			302.123,
			302.183,
			302.231,
			302.308,
			302.37,
			302.432,
			302.493,
			302.555,
			302.618,
			302.68,
			302.743,
			302.773,
			302.802,
			302.883,
			302.93,
			302.99,
			303.05,
			303.098,
			303.115,
			303.208,
			303.239,
			303.3,
			303.316,
			303.363,
			303.423,
			303.487,
			303.547,
			303.611,
			303.672,
			303.748,
			303.796,
			303.858,
			303.921,
			303.983,
			304.076,
			304.106,
			304.168,
			304.184,
			304.23,
			304.294,
			304.356,
			304.418,
			304.48,
			304.51,
			304.54,
			304.619,
			304.666,
			304.725,
			304.788,
			304.836,
			304.943,
			304.975,
			305.036,
			305.098,
			305.161,
			305.221,
			305.284,
			305.345,
			305.379,
			305.408,
			305.486,
			305.533,
			305.595,
			305.703,
			305.78,
			305.811,
			305.841,
			305.922,
			305.965,
			306.028,
			306.091,
			306.154,
			306.246,
			306.277,
			306.353,
			306.4,
			306.468,
			306.524,
			306.588,
			306.681,
			306.713,
			306.788,
			306.836,
			306.896,
			306.961,
			307.022,
			307.084,
			307.114,
			307.146,
			307.223,
			307.268,
			307.333,
			307.392,
			307.455,
			307.515,
			307.548,
			307.58,
			307.655,
			307.703,
			307.767,
			307.828,
			307.889,
			307.95,
			307.983,
			308.013,
			308.091,
			308.138,
			308.2,
			308.261,
			308.308,
			308.385,
			308.448,
			308.531,
			308.572,
			308.633,
			308.694,
			308.757,
			308.819,
			308.85,
			308.881,
			308.943,
			308.958,
			309.005,
			309.068,
			309.13,
			309.175,
			309.19,
			309.253,
			309.315,
			309.391,
			309.44,
			309.563,
			309.624,
			309.687,
			309.75,
			309.843,
			309.875,
			309.936,
			309.997,
			310.061,
			310.168,
			310.183,
			310.245,
			310.278,
			310.308,
			310.385,
			310.43,
			310.493,
			310.555,
			310.618,
			310.679,
			310.711,
			310.741,
			310.819,
			310.864,
			310.929,
			311.037,
			311.146,
			311.176,
			311.253,
			311.3,
			311.362,
			311.422,
			311.469,
			311.485,
			311.58,
			311.61,
			311.673,
			311.687,
			311.734,
			311.796,
			311.857,
			311.918,
			311.982,
			312.043,
			312.121,
			312.167,
			312.229,
			312.291,
			312.338,
			312.447,
			312.478,
			312.54,
			312.601,
			312.667,
			312.726,
			312.788,
			312.881,
			312.911,
			312.99,
			313.036,
			313.098,
			313.207,
			313.283,
			313.316,
			313.346,
			313.423,
			313.469,
			313.532,
			313.593,
			313.656,
			313.717,
			313.75,
			313.78,
			313.842,
			313.858,
			313.903,
			313.965,
			314.074,
			314.09,
			314.151,
			314.214,
			314.291,
			314.339,
			314.4,
			314.462,
			314.523,
			314.585,
			314.615,
			314.647,
			314.711,
			314.73,
			314.771,
			314.835,
			314.897,
			314.943,
			314.959,
			315.02,
			315.051,
			315.083,
			315.158,
			315.207,
			315.27,
			315.329,
			315.393,
			315.454,
			315.514,
			315.579,
			315.64,
			315.703,
			315.765,
			315.826,
			315.919,
			315.95,
			316.026,
			316.073,
			316.135,
			316.198,
			316.26,
			316.323,
			316.353,
			316.385,
			316.447,
			316.463,
			316.509,
			316.572,
			316.633,
			316.693,
			316.757,
			316.792,
			316.819,
			316.897,
			316.943,
			317.005,
			317.066,
			317.114,
			317.191,
			317.221,
			317.252,
			317.314,
			317.377,
			317.439,
			317.5,
			317.561,
			317.655,
			317.687,
			317.747,
			317.763,
			317.81,
			317.872,
			317.933,
			317.98,
			318.058,
			318.09,
			318.12,
			318.197,
			318.243,
			318.306,
			318.369,
			318.43,
			318.491,
			318.555,
			318.617,
			318.631,
			318.679,
			318.739,
			318.801,
			318.863,
			318.926,
			318.988,
			319.066,
			319.113,
			319.173,
			319.235,
			319.298,
			319.39,
			319.423,
			319.485,
			319.545,
			319.609,
			319.671,
			319.718,
			319.733,
			319.793,
			319.826,
			319.855,
			319.932,
			319.98,
			320.043,
			320.103,
			320.167,
			320.258,
			320.29,
			320.368,
			320.414,
			320.477,
			320.539,
			320.6,
			320.661,
			320.694,
			320.723,
			320.803,
			320.848,
			320.915,
			320.972,
			321.019,
			321.035,
			321.095,
			321.128,
			321.219,
			321.236,
			321.283,
			321.343,
			321.407,
			321.469,
			321.562,
			321.592,
			321.669,
			321.716,
			321.779,
			321.841,
			321.888,
			321.903,
			321.965,
			321.996,
			322.027,
			322.103,
			322.151,
			322.211,
			322.274,
			322.335,
			322.429,
			322.459,
			322.522,
			322.538,
			322.584,
			322.645,
			322.708,
			322.756,
			322.833,
			322.863,
			322.895,
			322.975,
			323.018,
			323.08,
			323.141,
			323.204,
			323.267,
			323.297,
			323.328,
			323.389,
			323.406,
			323.452,
			323.519,
			323.576,
			323.623,
			323.639,
			323.731,
			323.76,
			323.839,
			323.886,
			323.948,
			324.011,
			324.073,
			324.164,
			324.196,
			324.273,
			324.321,
			324.382,
			324.444,
			324.506,
			324.568,
			324.63,
			324.708,
			324.754,
			324.817,
			324.88,
			324.925,
			324.94,
			325.003,
			325.065,
			325.14,
			325.188,
			325.25,
			325.312,
			325.374,
			325.437,
			325.468,
			325.498,
			325.575,
			325.621,
			325.684,
			325.745,
			325.792,
			325.81,
			325.871,
			325.933,
			326.011,
			326.055,
			326.119,
			326.181,
			326.241,
			326.305,
			326.366,
			326.427,
			326.442,
			326.49,
			326.552,
			326.661,
			326.739,
			326.799,
			326.879,
			326.923,
			326.988,
			327.05,
			327.109,
			327.172,
			327.234,
			327.295,
			327.313,
			327.358,
			327.421,
			327.483,
			327.529,
			327.605,
			327.638,
			327.669,
			327.746,
			327.791,
			327.855,
			327.916,
			327.977,
			328.041,
			328.071,
			328.102,
			328.18,
			328.227,
			328.289,
			328.35,
			328.414,
			328.474,
			328.506,
			328.537,
			328.615,
			328.661,
			328.724,
			328.831,
			328.909,
			328.939,
			328.969,
			329.033,
			329.096,
			329.164,
			329.22,
			329.281,
			329.343,
			329.406,
			329.48,
			329.528,
			329.59,
			329.653,
			329.701,
			329.714,
			329.777,
			329.838,
			329.916,
			329.961,
			330.023,
			330.086,
			330.148,
			330.243,
			330.272,
			330.351,
			330.395,
			330.46,
			330.52,
			330.567,
			330.583,
			330.645,
			330.707,
			330.769,
			330.785,
			330.83,
			330.893,
			330.956,
			331.015,
			331.079,
			331.141,
			331.223,
			331.264,
			331.328,
			331.387,
			331.435,
			331.543,
			331.573,
			331.653,
			331.697,
			331.764,
			331.822,
			331.886,
			331.945,
			332.007,
			332.088,
			332.132,
			332.195,
			332.257,
			332.303,
			332.382,
			332.413,
			332.443,
			332.506,
			332.568,
			332.628,
			332.691,
			332.753,
			332.815,
			332.847,
			332.878,
			332.94,
			333.002,
			333.067,
			333.126,
			333.172,
			333.188,
			333.25,
			333.31,
			333.388,
			333.435,
			333.498,
			333.558,
			333.62,
			333.683,
			333.713,
			333.744,
			333.806,
			333.868,
			333.933,
			334.039,
			334.056,
			334.118,
			334.18,
			334.255,
			334.302,
			334.365,
			334.427,
			334.49,
			334.583,
			334.612,
			334.676,
			334.738,
			334.798,
			334.861,
			334.908,
			334.986,
			335.016,
			335.048,
			335.125,
			335.172,
			335.233,
			335.298,
			335.358,
			335.419,
			335.481,
			335.558,
			335.607,
			335.667,
			335.729,
			335.791,
			335.855,
			335.884,
			335.914,
			335.992,
			336.04,
			336.102,
			336.21,
			336.287,
			336.349,
			336.413,
			336.472,
			336.536,
			336.597,
			336.66,
			336.721,
			336.752,
			336.784,
			336.847,
			336.863,
			336.908,
			336.97,
			337.032,
			337.079,
			337.156,
			337.217,
			337.341,
			337.403,
			337.466,
			337.529,
			337.59,
			337.621,
			337.653,
			337.729,
			337.776,
			337.839,
			337.946,
			337.961,
			338.023,
			338.055,
			338.087,
			338.163,
			338.21,
			338.273,
			338.334,
			338.397,
			338.459,
			338.521,
			338.582,
			338.644,
			338.706,
			338.815,
			338.831,
			338.893,
			338.922,
			338.953,
			339.033,
			339.139,
			339.202,
			339.265,
			339.326,
			339.388,
			339.45,
			339.511,
			339.574,
			339.635,
			339.684,
			339.759,
			339.792,
			339.821,
			339.9,
			339.945,
			340.008,
			340.071,
			340.133,
			340.196,
			340.256,
			340.318,
			340.333,
			340.379,
			340.442,
			340.505,
			340.566,
			340.661,
			340.691,
			340.767,
			340.815,
			340.877,
			340.938,
			340.985,
			341.064,
			341.124,
			341.201,
			341.249,
			341.309,
			341.372,
			341.435,
			341.497,
			341.534,
			341.558,
			341.637,
			341.683,
			341.747,
			341.854,
			341.961,
			341.991,
			342.056,
			342.116,
			342.18,
			342.24,
			342.302,
			342.364,
			342.396,
			342.425,
			342.505,
			342.552,
			342.612,
			342.676,
			342.721,
			342.736,
			342.797,
			342.831,
			342.86,
			342.939,
			342.983,
			343.046,
			343.108,
			343.169,
			343.232,
			343.262,
			343.296,
			343.357,
			343.418,
			343.48,
			343.595,
			343.606,
			343.666,
			343.698,
			343.73,
			343.806,
			343.852,
			343.916,
			343.977,
			344.04,
			344.102,
			344.133,
			344.164,
			344.241,
			344.288,
			344.349,
			344.413,
			344.473,
			344.536,
			344.597,
			344.675,
			344.721,
			344.846,
			344.908,
			344.969,
			345,
			345.031,
			345.093,
			345.155,
			345.219,
			345.28,
			345.326,
			345.341,
			345.403,
			345.434,
			345.466,
			345.541,
			345.59,
			345.655,
			345.776,
			345.869,
			345.899,
			345.96,
			346.023,
			346.086,
			346.146,
			346.21,
			346.272,
			346.334,
			346.41,
			346.456,
			346.525,
			346.582,
			346.642,
			346.706,
			346.767,
			346.828,
			346.89,
			346.954,
			347.016,
			347.077,
			347.139,
			347.202,
			347.28,
			347.325,
			347.389,
			347.45,
			347.511,
			347.603,
			347.636,
			347.72,
			347.759,
			347.826,
			347.882,
			347.931,
			348.008,
			348.069,
			348.148,
			348.194,
			348.256,
			348.317,
			348.378,
			348.473,
			348.502,
			348.566,
			348.582,
			348.627,
			348.691,
			348.751,
			348.814,
			348.875,
			348.936,
			349.015,
			349.063,
			349.124,
			349.247,
			349.31,
			349.341,
			349.373,
			349.433,
			349.494,
			349.559,
			349.621,
			349.683,
			349.744,
			349.781,
			349.807,
			349.884,
			349.93,
			349.992,
			350.053,
			350.101,
			350.117,
			350.21,
			350.239,
			350.302,
			350.318,
			350.364,
			350.426,
			350.488,
			350.55,
			350.611,
			350.644,
			350.674,
			350.751,
			350.799,
			350.859,
			350.923,
			350.969,
			350.984,
			351.047,
			351.107,
			351.186,
			351.232,
			351.294,
			351.355,
			351.419,
			351.479,
			351.541,
			351.605,
			351.665,
			351.729,
			351.79,
			351.851,
			351.914,
			351.945,
			351.975,
			352.055,
			352.101,
			352.164,
			352.223,
			352.287,
			352.347,
			352.38,
			352.41,
			352.474,
			352.488,
			352.536,
			352.598,
			352.659,
			352.706,
			352.783,
			352.846,
			352.923,
			352.969,
			353.031,
			353.093,
			353.139,
			353.217,
			353.277,
			353.34,
			353.403,
			353.466,
			353.526,
			353.588,
			353.65,
			353.713,
			353.79,
			353.837,
			353.903,
			354.007,
			354.117,
			354.146,
			354.209,
			354.272,
			354.338,
			354.394,
			354.456,
			354.52,
			354.55,
			354.581,
			354.658,
			354.705,
			354.768,
			354.829,
			354.875,
			354.889,
			354.953,
			355.014,
			355.075,
			355.093,
			355.139,
			355.202,
			355.264,
			355.324,
			355.387,
			355.417,
			355.45,
			355.526,
			355.574,
			355.636,
			355.696,
			355.742,
			355.759,
			355.821,
			355.883,
			355.945,
			355.968,
			356.008,
			356.07,
			356.132,
			356.194,
			356.255,
			356.286,
			356.318,
			356.38,
			356.441,
			356.504,
			356.61,
			356.689,
			356.75,
			356.813,
			356.83,
			356.874,
			356.938,
			356.998,
			357.062,
			357.123,
			357.153,
			357.185,
			357.261,
			357.31,
			357.372,
			357.478,
			357.588,
			357.619,
			357.697,
			357.743,
			357.806,
			357.868,
			357.93,
			357.991,
			358.054,
			358.116,
			358.132,
			358.177,
			358.24,
			358.303,
			358.364,
			358.426,
			358.488,
			358.564,
			358.611,
			358.674,
			358.737,
			358.797,
			358.858,
			358.922,
			358.982,
			359.046,
			359.108,
			359.169,
			359.216,
			359.233,
			359.294,
			359.324,
			359.354,
			359.433,
			359.479,
			359.541,
			359.602,
			359.666,
			359.728,
			359.791,
			359.851,
			359.915,
			359.974,
			360.039,
			360.099,
			360.163,
			360.194,
			360.223,
			360.301,
			360.348,
			360.411,
			360.473,
			360.533,
			360.594,
			360.657,
			360.721,
			360.736,
			360.783,
			360.845,
			360.953,
			360.969,
			361.063,
			361.091,
			361.169,
			361.216,
			361.279,
			361.34,
			361.403,
			361.463,
			361.494,
			361.525,
			361.588,
			361.65,
			361.713,
			361.773,
			361.836,
			361.899,
			361.928,
			361.959,
			362.038,
			362.084,
			362.15,
			362.255,
			362.364,
			362.394,
			362.472,
			362.517,
			362.581,
			362.641,
			362.703,
			362.767,
			362.797,
			362.829,
			362.906,
			362.952,
			363.015,
			363.077,
			363.122,
			363.139,
			363.233,
			363.261,
			363.34,
			363.386,
			363.448,
			363.509,
			363.572,
			363.634,
			363.665,
			363.697,
			363.774,
			363.82,
			363.883,
			363.946,
			364.007,
			364.07,
			364.098,
			364.13,
			364.193,
			364.215,
			364.256,
			364.317,
			364.379,
			364.44,
			364.535,
			364.566,
			364.642,
			364.689,
			364.755,
			364.814,
			364.858,
			364.968,
			364.999,
			365.063,
			365.121,
			365.185,
			365.246,
			365.307,
			365.37,
			365.432,
			365.51,
			365.556,
			365.619,
			365.728,
			365.743,
			365.835,
			365.867,
			365.929,
			365.991,
			366.053,
			366.113,
			366.175,
			366.239,
			366.274,
			366.3,
			366.378,
			366.423,
			366.487,
			366.548,
			366.613,
			366.673,
			366.704,
			366.736,
			366.797,
			366.812,
			366.858,
			366.922,
			366.982,
			367.045,
			367.107,
			367.139,
			367.168,
			367.247,
			367.294,
			367.354,
			367.415,
			367.48,
			367.573,
			367.605,
			367.665,
			367.728,
			367.789,
			367.85,
			367.913,
			368.006,
			368.036,
			368.115,
			368.16,
			368.227,
			368.335,
			368.408,
			368.44,
			368.472,
			368.549,
			368.595,
			368.658,
			368.719,
			368.781,
			368.842,
			368.875,
			368.905,
			368.982,
			369.028,
			369.092,
			369.153,
			369.216,
			369.278,
			369.309,
			369.34,
			369.417,
			369.462,
			369.526,
			369.588,
			369.649,
			369.712,
			369.744,
			369.774,
			369.835,
			369.898,
			369.961,
			370.022,
			370.083,
			370.145,
			370.206,
			370.286,
			370.331,
			370.4,
			370.455,
			370.517,
			370.58,
			370.612,
			370.642,
			370.704,
			370.765,
			370.828,
			370.936,
			371.013,
			371.045,
			371.074,
			371.154,
			371.2,
			371.263,
			371.386,
			371.448,
			371.48,
			371.511,
			371.571,
			371.587,
			371.634,
			371.695,
			371.758,
			371.821,
			371.882,
			371.911,
			371.943,
			372.021,
			372.068,
			372.131,
			372.255,
			372.316,
			372.348,
			372.377,
			372.441,
			372.462,
			372.503,
			372.564,
			372.627,
			372.688,
			372.75,
			372.781,
			372.811,
			372.889,
			372.936,
			372.999,
			373.061,
			373.108,
			373.247,
			373.324,
			373.371,
			373.432,
			373.495,
			373.557,
			373.619,
			373.649,
			373.679,
			373.758,
			373.804,
			373.866,
			373.929,
			373.976,
			373.991,
			374.053,
			374.083,
			374.114,
			374.177,
			374.191,
			374.239,
			374.301,
			374.363,
			374.424,
			374.485,
			374.548,
			374.624,
			374.672,
			374.735,
			374.795,
			374.843,
			374.859,
			374.953,
			374.981,
			375.044,
			375.107,
			375.169,
			375.23,
			375.293,
			375.355,
			375.416,
			375.493,
			375.54,
			375.603,
			375.664,
			375.712,
			375.788,
			375.819,
			375.851,
			375.913,
			375.974,
			376.041,
			376.1,
			376.162,
			376.254,
			376.284,
			376.348,
			376.408,
			376.47,
			376.533,
			376.594,
			376.657,
			376.689,
			376.718,
			376.796,
			376.842,
			376.905,
			376.967,
			377.027,
			377.123,
			377.153,
			377.229,
			377.277,
			377.343,
			377.401,
			377.448,
			377.464,
			377.524,
			377.588,
			377.663,
			377.711,
			377.774,
			377.833,
			377.898,
			377.959,
			378.021,
			378.084,
			378.144,
			378.206,
			378.317,
			378.331,
			378.424,
			378.456,
			378.533,
			378.641,
			378.703,
			378.764,
			378.826,
			378.858,
			378.889,
			378.968,
			379.013,
			379.075,
			379.184,
			379.263,
			379.292,
			379.323,
			379.401,
			379.447,
			379.51,
			379.572,
			379.632,
			379.726,
			379.757,
			379.819,
			379.835,
			379.882,
			379.944,
			380.006,
			380.051,
			380.067,
			380.13,
			380.159,
			380.192,
			380.269,
			380.316,
			380.378,
			380.439,
			380.5,
			380.595,
			380.625,
			380.688,
			380.707,
			380.749,
			380.811,
			380.874,
			380.921,
			380.936,
			380.998,
			381.058,
			381.136,
			381.185,
			381.246,
			381.308,
			381.369,
			381.43,
			381.462,
			381.493,
			381.556,
			381.617,
			381.68,
			381.743,
			381.804,
			381.865,
			381.898,
			381.928,
			382.005,
			382.052,
			382.115,
			382.176,
			382.235,
			382.331,
			382.363,
			382.439,
			382.485,
			382.548,
			382.608,
			382.673,
			382.768,
			382.796,
			382.873,
			382.921,
			382.983,
			383.046,
			383.091,
			383.105,
			383.168,
			383.228,
			383.292,
			383.354,
			383.421,
			383.476,
			383.54,
			383.632,
			383.664,
			383.742,
			383.789,
			383.851,
			383.913,
			383.975,
			384.036,
			384.098,
			384.176,
			384.223,
			384.284,
			384.346,
			384.408,
			384.469,
			384.532,
			384.608,
			384.656,
			384.719,
			384.78,
			384.833,
			384.936,
			384.965,
			385.09,
			385.153,
			385.213,
			385.277,
			385.369,
			385.4,
			385.478,
			385.525,
			385.588,
			385.649,
			385.694,
			385.71,
			385.772,
			385.803,
			385.835,
			385.912,
			386.022,
			386.082,
			386.143,
			386.207,
			386.238,
			386.268,
			386.346,
			386.393,
			386.454,
			386.516,
			386.561,
			386.639,
			386.702,
			386.781,
			386.825,
			386.893,
			386.95,
			387.013,
			387.106,
			387.138,
			387.198,
			387.214,
			387.261,
			387.324,
			387.386,
			387.43,
			387.508,
			387.541,
			387.57,
			387.649,
			387.694,
			387.757,
			387.819,
			387.882,
			387.973,
			388.006,
			388.082,
			388.129,
			388.192,
			388.253,
			388.314,
			388.376,
			388.408,
			388.439,
			388.515,
			388.563,
			388.626,
			388.688,
			388.749,
			388.811,
			388.843,
			388.872,
			388.935,
			388.998,
			389.059,
			389.121,
			389.168,
			389.182,
			389.244,
			389.307,
			389.385,
			389.429,
			389.492,
			389.618,
			389.679,
			389.71,
			389.741,
			389.802,
			389.819,
			389.865,
			389.929,
			389.989,
			390.036,
			390.114,
			390.143,
			390.176,
			390.252,
			390.299,
			390.361,
			390.47,
			390.486,
			390.548,
			390.607,
			390.687,
			390.733,
			390.797,
			390.856,
			390.92,
			390.982,
			391.019,
			391.043,
			391.122,
			391.167,
			391.23,
			391.337,
			391.415,
			391.445,
			391.479,
			391.554,
			391.6,
			391.662,
			391.724,
			391.788,
			391.847,
			391.881,
			391.911,
			391.989,
			392.035,
			392.098,
			392.16,
			392.205,
			392.314,
			392.345,
			392.424,
			392.47,
			392.531,
			392.595,
			392.655,
			392.716,
			392.781,
			392.858,
			392.904,
			392.964,
			393.028,
			393.089,
			393.153,
			393.182,
			393.274,
			393.29,
			393.339,
			393.399,
			393.463,
			393.523,
			393.585,
			393.615,
			393.648,
			393.725,
			393.771,
			393.833,
			393.942,
			393.957,
			394.019,
			394.083,
			394.145,
			394.158,
			394.207,
			394.269,
			394.329,
			394.392,
			394.486,
			394.515,
			394.594,
			394.639,
			394.702,
			394.764,
			394.811,
			394.888,
			394.92,
			394.951,
			395.026,
			395.075,
			395.14,
			395.198,
			395.26,
			395.322,
			395.384,
			395.446,
			395.509,
			395.569,
			395.632,
			395.679,
			395.756,
			395.788,
			395.818,
			395.897,
			395.941,
			396.003,
			396.064,
			396.127,
			396.189,
			396.253,
			396.329,
			396.376,
			396.44,
			396.501,
			396.546,
			396.562,
			396.625,
			396.687,
			396.763,
			396.811,
			396.872,
			396.933,
			396.996,
			397.058,
			397.09,
			397.119,
			397.182,
			397.247,
			397.308,
			397.368,
			397.414,
			397.431,
			397.523,
			397.555,
			397.632,
			397.677,
			397.74,
			397.803,
			397.866,
			397.958,
			397.988,
			398.052,
			398.065,
			398.111,
			398.176,
			398.236,
			398.299,
			398.391,
			398.424,
			398.501,
			398.547,
			398.608,
			398.674,
			398.732,
			398.796,
			398.857,
			398.935,
			398.98,
			399.044,
			399.105,
			399.166,
			399.23,
			399.292,
			399.368,
			399.416,
			399.479,
			399.539,
			399.585,
			399.663,
			399.725,
			399.787,
			399.848,
			399.912,
			399.974,
			400.035,
			400.098,
			400.142,
			400.157,
			400.251,
			400.282,
			400.362,
			400.406,
			400.47,
			400.53,
			400.593,
			400.687,
			400.717,
			400.778,
			400.796,
			400.841,
			400.904,
			400.964,
			401.028,
			401.089,
			401.121,
			401.151,
			401.229,
			401.276,
			401.341,
			401.463,
			401.553,
			401.585,
			401.663,
			401.71,
			401.772,
			401.832,
			401.895,
			401.958,
			402.019,
			402.096,
			402.142,
			402.205,
			402.267,
			402.313,
			402.328,
			402.423,
			402.453,
			402.53,
			402.576,
			402.639,
			402.701,
			402.764,
			402.824,
			402.887,
			402.964,
			403.013,
			403.073,
			403.134,
			403.198,
			403.289,
			403.322,
			403.384,
			403.447,
			403.507,
			403.568,
			403.632,
			403.725,
			403.756,
			403.833,
			403.879,
			403.941,
			404.051,
			404.066,
			404.127,
			404.158,
			404.189,
			404.268,
			404.314,
			404.376,
			404.437,
			404.499,
			404.564,
			404.591,
			404.624,
			404.701,
			404.747,
			404.809,
			404.872,
			404.932,
			404.996,
			405.027,
			405.12,
			405.136,
			405.18,
			405.246,
			405.307,
			405.369,
			405.429,
			405.467,
			405.491,
			405.554,
			405.615,
			405.677,
			405.739,
			405.787,
			405.865,
			405.896,
			405.925,
			406.003,
			406.11,
			406.174,
			406.235,
			406.33,
			406.36,
			406.421,
			406.484,
			406.547,
			406.607,
			406.654,
			406.732,
			406.764,
			406.793,
			406.872,
			406.919,
			406.983,
			407.041,
			407.102,
			407.198,
			407.229,
			407.291,
			407.306,
			407.352,
			407.414,
			407.477,
			407.539,
			407.63,
			407.662,
			407.74,
			407.785,
			407.849,
			407.91,
			407.973,
			408.035,
			408.065,
			408.097,
			408.157,
			408.219,
			408.283,
			408.345,
			408.406,
			408.499,
			408.531,
			408.608,
			408.654,
			408.716,
			408.824,
			408.84,
			408.902,
			408.932,
			408.963,
			409.027,
			409.041,
			409.088,
			409.15,
			409.212,
			409.275,
			409.335,
			409.4,
			409.477,
			409.523,
			409.589,
			409.646,
			409.693,
			409.708,
			409.77,
			409.833,
			409.895,
			409.911,
			409.958,
			410.02,
			410.08,
			410.142,
			410.236,
			410.267,
			410.343,
			410.389,
			410.453,
			410.562,
			410.639,
			410.701,
			410.777,
			410.824,
			410.886,
			410.947,
			411.011,
			411.073,
			411.103,
			411.135,
			411.211,
			411.258,
			411.32,
			411.383,
			411.43,
			411.536,
			411.569,
			411.656,
			411.756,
			411.816,
			411.88,
			411.94,
			412.003,
			412.064,
			412.097,
			412.128,
			412.205,
			412.252,
			412.312,
			412.422,
			412.436,
			412.531,
			412.561,
			412.624,
			412.685,
			412.747,
			412.81,
			412.872,
			412.933,
			412.964,
			412.995,
			413.073,
			413.119,
			413.18,
			413.289,
			413.306,
			413.368,
			413.429,
			413.508,
			413.552,
			413.614,
			413.677,
			413.738,
			413.801,
			413.831,
			413.864,
			413.941,
			413.988,
			414.05,
			414.156,
			414.235,
			414.266,
			414.297,
			414.373,
			414.421,
			414.483,
			414.545,
			414.608,
			414.669,
			414.699,
			414.731,
			414.794,
			414.856,
			414.917,
			414.98,
			415.025,
			415.043,
			415.103,
			415.135,
			415.165,
			415.244,
			415.291,
			415.352,
			415.415,
			415.476,
			415.536,
			415.6,
			415.661,
			415.724,
			415.789,
			415.846,
			415.896,
			415.909,
			416.002,
			416.033,
			416.111,
			416.159,
			416.219,
			416.282,
			416.344,
			416.406,
			416.439,
			416.467,
			416.53,
			416.592,
			416.653,
			416.717,
			416.777,
			416.84,
			416.901,
			416.98,
			417.025,
			417.088,
			417.212,
			417.275,
			417.336,
			417.397,
			417.413,
			417.459,
			417.522,
			417.584,
			417.646,
			417.708,
			417.74,
			417.769,
			417.853,
			417.894,
			417.955,
			418.019,
			418.064,
			418.08,
			418.173,
			418.202,
			418.281,
			418.329,
			418.391,
			418.453,
			418.514,
			418.608,
			418.637,
			418.716,
			418.764,
			418.824,
			418.886,
			418.949,
			419.011,
			419.072,
			419.15,
			419.197,
			419.259,
			419.319,
			419.347
		]
	],
	'2812_4975_7982': [
		[
			497.198
		],
		[
			0
		],
		[
			0,
			2.635,
			5.725,
			7.272,
			10.37,
			11.921,
			15.027,
			18.139,
			22.816,
			25.942,
			30.639,
			32.208,
			33.778,
			38.496,
			41.647,
			46.384,
			49.549,
			52.718,
			54.305,
			57.482,
			59.072,
			62.257,
			65.443,
			67.037,
			68.631,
			73.422,
			76.62,
			81.428,
			84.639,
			87.853,
			89.462,
			94.296,
			97.524,
			100.756,
			105.613,
			108.856,
			113.728,
			115.355,
			116.982,
			121.872,
			125.137,
			130.042,
			133.318,
			136.598,
			138.24,
			141.526,
			143.171,
			146.464,
			149.762,
			151.412,
			154.716,
			158.024,
			162.995,
			164.653,
			166.314,
			169.636,
			172.963,
			174.628,
			177.962,
			179.63,
			182.969,
			186.313,
			191.336,
			194.69,
			199.728,
			201.409,
			203.092,
			208.146,
			211.521,
			216.589,
			219.974,
			223.363,
			225.059,
			228.454,
			230.153,
			233.554,
			236.959,
			238.663,
			242.074,
			245.488,
			250.618,
			252.329,
			254.042,
			259.186,
			260.902,
			262.62,
			267.778,
			271.222,
			276.395,
			279.848,
			283.305,
			288.498,
			290.231,
			291.965,
			297.173,
			298.911,
			300.65,
			305.872,
			309.358,
			312.848,
			314.594,
			318.09,
			321.588,
			323.339,
			326.843,
			328.596,
			332.107,
			335.619,
			340.896,
			344.42,
			349.711,
			351.477,
			353.244,
			358.551,
			362.093,
			367.414,
			370.967,
			376.302,
			379.864,
			381.646,
			383.43,
			388.786,
			390.573,
			392.363,
			397.736,
			401.325,
			406.716,
			410.314,
			413.919,
			415.722,
			419.333,
			421.139,
			424.753,
			428.368,
			433.773,
			437.351,
			442.66,
			444.412,
			446.154,
			451.323,
			454.722,
			459.752,
			463.06,
			466.332,
			467.955,
			471.172,
			472.768,
			475.932,
			479.06,
			480.609,
			482.15,
			486.719,
			491.209,
			492.685,
			494.152,
			497.054,
			499.915,
			501.33,
			504.128,
			505.513,
			508.252,
			510.951,
			514.922,
			517.521,
			521.348,
			522.603,
			523.85,
			527.534,
			529.944,
			533.494,
			535.818,
			538.108,
			539.239,
			541.472,
			542.575,
			544.755,
			546.898,
			547.957,
			550.046,
			552.1,
			555.115,
			557.081,
			559.963,
			560.906,
			561.84,
			564.592,
			566.386,
			568.149,
			570.73,
			572.407,
			574.856,
			575.655,
			576.445,
			578.763,
			579.518,
			580.264,
			582.449,
			583.86,
			585.237,
			585.913,
			587.241,
			587.893,
			589.174,
			590.424,
			591.036,
			592.233,
			593.394,
			595.074,
			596.152,
			597.704,
			598.211,
			598.702,
			600.125,
			601.058,
			602.407,
			603.283,
			604.144,
			604.571,
			605.419,
			605.842,
			606.265,
			607.523,
			607.935,
			608.344,
			609.566,
			610.38,
			611.199,
			611.611,
			612.404,
			613.189,
			613.579,
			614.743,
			615.521,
			616.303,
			617.466,
			618.234,
			619.385,
			619.768,
			620.152,
			621.3,
			622.116,
			623.408,
			624.262,
			625.113,
			625.537,
			626.385,
			626.833,
			627.858,
			628.879,
			629.387,
			630.401,
			631.413,
			632.577,
			633.802,
			634.413,
			635.635,
			636.869,
			637.545,
			638.89,
			639.561,
			640.913,
			642.236,
			644.125,
			645.392,
			647.231,
			647.778,
			648.327,
			649.979,
			651.089,
			652.693,
			653.734,
			655.309,
			656.373,
			656.907,
			657.948,
			659.528,
			660.596,
			661.678,
			663.295,
			663.839,
			664.386,
			666.051,
			666.614,
			667.18,
			668.895,
			670.058,
			671.238,
			673.036,
			674.237,
			676.052,
			676.664,
			677.281,
			679.159,
			680.43,
			682.369,
			683.691,
			685.025,
			685.698,
			687.058,
			687.753,
			689.152,
			690.566,
			691.278,
			692.72,
			694.183,
			696.403,
			697.908,
			700.197,
			700.967,
			701.74,
			704.081,
			705.659,
			708.05,
			709.661,
			711.284,
			712.1,
			713.742,
			714.567,
			715.394,
			717.871,
			719.527,
			722.016,
			723.699,
			725.39,
			726.238,
			727.937,
			729.641,
			730.494,
			733.049,
			734.748,
			736.433,
			738.949,
			740.622,
			743.134,
			743.969,
			744.801,
			747.287,
			748.943,
			751.414,
			753.054,
			754.688,
			755.501,
			757.122,
			757.93,
			759.535,
			761.129,
			761.923,
			762.717,
			765.083,
			766.65,
			768.213,
			768.994,
			770.551,
			772.103,
			772.879,
			774.44,
			775.219,
			776.773,
			778.333,
			780.69,
			782.262,
			784.648,
			785.447,
			786.246,
			788.658,
			790.284,
			792.727,
			794.379,
			796.037,
			796.868,
			798.541,
			799.385,
			801.078,
			802.779,
			803.635,
			805.356,
			807.086,
			809.703,
			810.578,
			811.455,
			813.218,
			814.987,
			815.873,
			817.65,
			818.542,
			820.328,
			822.115,
			824.808,
			826.606,
			829.314,
			830.22,
			831.125,
			834.756,
			835.666,
			838.391,
			840.215,
			842.031,
			842.936,
			844.763,
			845.675,
			847.494,
			849.314,
			850.224,
			852.041,
			853.862,
			856.601,
			857.513,
			858.427,
			861.174,
			862.089,
			863.004,
			865.731,
			867.543,
			870.234,
			872.016,
			873.79,
			874.666,
			876.414,
			877.287,
			878.16,
			880.733,
			881.587,
			882.441,
			884.952,
			886.616,
			888.283,
			889.123,
			890.764,
			892.417,
			893.248,
			894.899,
			895.725,
			897.387,
			899.06,
			901.581,
			903.278,
			905.864,
			906.733,
			907.605,
			910.26,
			912.056,
			914.786,
			916.635,
			918.496,
			919.436,
			921.333,
			922.286,
			923.243,
			926.142,
			927.115,
			928.092,
			931.043,
			933.01,
			935.003,
			936.002,
			938.009,
			940.023,
			941.032,
			944.093,
			946.127,
			948.155,
			951.25,
			953.303,
			956.382,
			957.412,
			958.445,
			961.554,
			963.621,
			966.76,
			968.879,
			972.045,
			974.164,
			975.204,
			977.315,
			980.466,
			982.521,
			984.548,
			987.52,
			989.466,
			992.323,
			993.26,
			994.19,
			996.913,
			998.701,
			1000.441,
			1002.98,
			1004.657,
			1007.08,
			1007.875,
			1008.661,
			1010.974,
			1012.489,
			1014.679,
			1016.113,
			1017.513,
			1018.201,
			1019.551,
			1020.214,
			1021.514,
			1022.784,
			1023.408,
			1024.632,
			1025.81,
			1027.513,
			1028.065,
			1028.611,
			1030.205,
			1030.718,
			1031.225,
			1032.727,
			1033.715,
			1035.181,
			1036.137,
			1037.092,
			1038.527,
			1039.008,
			1039.49,
			1040.922,
			1041.397,
			1041.873,
			1043.299,
			1044.252,
			1045.216,
			1045.707,
			1046.682,
			1047.655,
			1048.142,
			1049.117,
			1049.607,
			1050.614,
			1051.632,
			1053.164,
			1054.192,
			1055.78,
			1056.322,
			1056.865,
			1058.502,
			1059.605,
			1061.309,
			1062.462,
			1063.625,
			1064.209,
			1065.402,
			1066.016,
			1066.633,
			1069.127,
			1070.406,
			1071.729,
			1073.058,
			1074.397,
			1075.069,
			1076.473,
			1077.882,
			1078.589,
			1080.74,
			1082.214,
			1083.688,
			1085.931,
			1087.455,
			1089.737,
			1090.506,
			1091.282,
			1093.597,
			1095.135,
			1097.468,
			1099.009,
			1101.308,
			1102.824,
			1103.576,
			1105.072,
			1106.56,
			1107.3,
			1108.771,
			1110.237,
			1112.417,
			1113.14,
			1113.861,
			1116.013,
			1116.724,
			1117.433,
			1118.848,
			1119.555,
			1120.947,
			1122.315,
			1124.362,
			1125.714,
			1127.691,
			1128.342,
			1129.005,
			1130.989,
			1132.297,
			1134.291,
			1135.634,
			1137.645,
			1139.004,
			1140.378,
			1141.071,
			1143.172,
			1144.588,
			1146.021,
			1148.207,
			1148.932,
			1149.655,
			1151.852,
			1152.587,
			1153.32,
			1155.506,
			1157.005,
			1158.497,
			1160.733,
			1163.047,
			1164.583,
			1165.351,
			1166.143,
			1168.541,
			1169.34,
			1170.138,
			1172.589,
			1174.231,
			1175.88,
			1176.717,
			1178.392,
			1180.068,
			1180.911,
			1182.606,
			1183.454,
			1185.155,
			1186.849,
			1189.381,
			1191.066,
			1193.562,
			1194.393,
			1195.224,
			1197.685,
			1199.319,
			1201.757,
			1203.365,
			1204.972,
			1205.777,
			1207.35,
			1208.137,
			1209.713,
			1211.276,
			1212.037,
			1213.56,
			1215.089,
			1216.587,
			1218.05,
			1218.784,
			1220.257,
			1221.699,
			1222.409,
			1223.834,
			1224.551,
			1225.994,
			1227.429,
			1229.607,
			1231.082,
			1233.373,
			1234.144,
			1234.918,
			1237.315,
			1238.959,
			1241.462,
			1243.178,
			1244.896,
			1245.754,
			1247.479,
			1248.348,
			1250.08,
			1252.712,
			1254.481,
			1256.26,
			1258.941,
			1259.842,
			1260.745,
			1262.557,
			1264.381,
			1265.298,
			1267.14,
			1268.065,
			1269.925,
			1272.738,
			1274.627,
			1276.529,
			1279.402,
			1280.365,
			1281.332,
			1284.247,
			1286.204,
			1289.156,
			1291.139,
			1293.13,
			1294.129,
			1296.137,
			1297.144,
			1299.166,
			1301.198,
			1302.219,
			1304.266,
			1306.325,
			1309.43,
			1311.508,
			1314.625,
			1315.666,
			1316.71,
			1319.859,
			1321.971,
			1324.096,
			1327.304,
			1329.458,
			1332.708,
			1333.797,
			1334.89,
			1338.183,
			1339.286,
			1340.392,
			1343.726,
			1345.962,
			1348.208,
			1349.335,
			1351.596,
			1353.868,
			1355.008,
			1358.443,
			1360.744,
			1363.055,
			1366.538,
			1368.872,
			1372.389,
			1373.566,
			1374.745,
			1378.296,
			1380.674,
			1384.258,
			1386.659,
			1389.07,
			1390.278,
			1392.703,
			1393.919,
			1395.138,
			1398.808,
			1400.036,
			1401.266,
			1404.972,
			1407.455,
			1409.948,
			1411.198,
			1413.705,
			1416.221,
			1417.482,
			1420.011,
			1421.279,
			1423.82,
			1426.371,
			1430.211,
			1432.781,
			1436.651,
			1437.945,
			1439.241,
			1443.141,
			1445.746,
			1449.658,
			1452.274,
			1454.895,
			1456.203,
			1458.812,
			1461.4,
			1465.23,
			1466.489,
			1468.979,
			1471.431,
			1473.846,
			1476.227,
			1477.404,
			1479.733,
			1482.028,
			1483.16,
			1485.398,
			1486.503,
			1488.685,
			1490.831,
			1493.981,
			1496.036,
			1499.051,
			1500.039,
			1501.018,
			1503.899,
			1505.777,
			1508.529,
			1510.326,
			1512.089,
			1512.958,
			1514.67,
			1515.512,
			1517.171,
			1518.793,
			1519.591,
			1521.161,
			1522.696,
			1524.932,
			1525.659,
			1526.389,
			1528.495,
			1529.182,
			1529.861,
			1531.872,
			1533.194,
			1534.495,
			1536.415,
			1537.688,
			1539.585,
			1540.214,
			1540.842,
			1543.35,
			1543.98,
			1545.882,
			1547.15,
			1548.421,
			1549.063,
			1550.371,
			1551.025,
			1552.337,
			1553.651,
			1554.323,
			1555.68,
			1557.037,
			1559.078,
			1560.463,
			1562.535,
			1563.225,
			1563.914,
			1566.01,
			1567.405,
			1569.504,
			1570.911,
			1572.315,
			1574.423,
			1575.129,
			1575.833,
			1577.937,
			1578.637,
			1579.341,
			1581.464,
			1582.878,
			1584.297,
			1585.02,
			1586.462,
			1587.901,
			1588.62,
			1590.078,
			1590.811,
			1592.276,
			1594.483,
			1595.963,
			1597.443,
			1599.665,
			1600.401,
			1601.137,
			1603.348,
			1604.821,
			1607.016,
			1608.481,
			1610.675,
			1612.133,
			1612.863,
			1614.328,
			1615.785,
			1616.514,
			1617.971,
			1619.432,
			1620.885,
			1623.061,
			1624.511,
			1625.952,
			1626.672,
			1628.109,
			1628.829,
			1630.265,
			1631.696,
			1633.843,
			1635.276,
			1637.422,
			1638.137,
			1638.854,
			1641.003,
			1642.433,
			1644.578,
			1646,
			1648.13,
			1649.549,
			1650.256,
			1651.666,
			1653.075,
			1653.778,
			1655.187,
			1656.594,
			1658.703,
			1659.407,
			1660.11,
			1662.218,
			1662.922,
			1663.625,
			1665.74,
			1667.153,
			1668.57,
			1670.705,
			1672.133,
			1674.29,
			1675.014,
			1675.742,
			1677.939,
			1679.415,
			1681.669,
			1683.187,
			1684.715,
			1685.496,
			1687.068,
			1687.86,
			1689.454,
			1691.081,
			1691.903,
			1693.556,
			1695.226,
			1697.77,
			1699.47,
			1702.011,
			1702.869,
			1703.731,
			1706.328,
			1708.072,
			1710.709,
			1712.478,
			1714.259,
			1715.153,
			1716.946,
			1718.75,
			1721.477,
			1722.391,
			1723.308,
			1726.076,
			1727.935,
			1729.805,
			1730.744,
			1732.629,
			1734.526,
			1735.479,
			1737.391,
			1738.351,
			1740.281,
			1742.221,
			1745.152,
			1747.12,
			1750.093,
			1751.089,
			1752.088,
			1755.102,
			1757.128,
			1760.187,
			1762.243,
			1764.309,
			1765.346,
			1767.43,
			1768.471,
			1770.554,
			1772.646,
			1773.695,
			1775.802,
			1777.919,
			1780.047,
			1782.186,
			1783.259,
			1785.413,
			1787.576,
			1788.661,
			1791.932,
			1794.123,
			1796.319,
			1799.608,
			1801.787,
			1805.015,
			1806.076,
			1807.13,
			1810.239,
			1812.265,
			1815.235,
			1817.17,
			1820.022,
			1821.874,
			1822.791,
			1824.611,
			1826.417,
			1827.314,
			1829.098,
			1830.873,
			1833.505,
			1835.249,
			1837.838,
			1838.696,
			1839.553,
			1841.265,
			1842.119,
			1843.825,
			1845.528,
			1848.099,
			1849.82,
			1852.419,
			1853.296,
			1854.173,
			1856.814,
			1858.61,
			1861.306,
			1863.132,
			1864.963,
			1865.879,
			1867.729,
			1868.659,
			1870.519,
			1872.393,
			1873.341,
			1875.237,
			1877.138,
			1880.028,
			1881.952,
			1884.852,
			1885.815,
			1886.778,
			1889.66,
			1891.578,
			1894.429,
			1896.325,
			1899.119,
			1900.968,
			1901.893,
			1902.793,
			1906.372,
			1907.259,
			1909.86,
			1911.591,
			1914.158,
			1915.863,
			1917.572,
			1918.431,
			1920.149,
			1921.01,
			1922.742,
			1924.501,
			1927.149,
			1928.95,
			1931.672,
			1932.586,
			1933.51,
			1936.303,
			1938.183,
			1941.027,
			1942.937,
			1944.855,
			1945.818,
			1947.754,
			1948.724,
			1950.672,
			1952.632,
			1953.616,
			1954.604,
			1957.585,
			1959.588,
			1961.603,
			1962.597,
			1964.629,
			1966.673,
			1967.699,
			1969.757,
			1970.79,
			1972.864,
			1974.941,
			1978.065,
			1980.142,
			1983.262,
			1984.299,
			1985.335,
			1988.419,
			1990.445,
			1993.429,
			1995.378,
			1997.292,
			1998.235,
			2000.12,
			2001.044,
			2002.873,
			2005.598,
			2007.407,
			2009.211,
			2011.909,
			2012.805,
			2013.699,
			2015.485,
			2017.262,
			2018.149,
			2019.919,
			2020.802,
			2022.565,
			2024.327,
			2026.967,
			2028.727,
			2031.365,
			2032.244,
			2033.123,
			2035.765,
			2037.525,
			2040.174,
			2041.942,
			2043.711,
			2044.602,
			2046.386,
			2047.278,
			2049.061,
			2050.86,
			2051.758,
			2053.552,
			2055.36,
			2058.077,
			2059.9,
			2062.659,
			2063.578,
			2064.504,
			2067.31,
			2069.181,
			2071.077,
			2073.92,
			2075.839,
			2078.722,
			2079.686,
			2080.654,
			2084.513,
			2085.481,
			2088.376,
			2090.31,
			2093.214,
			2095.15,
			2097.086,
			2098.054,
			2099.988,
			2100.953,
			2102.885,
			2104.816,
			2107.696,
			2109.614,
			2112.487,
			2113.444,
			2114.4,
			2117.233,
			2119.123,
			2121.925,
			2123.795,
			2126.59,
			2128.458,
			2129.397,
			2131.265,
			2133.141,
			2134.084,
			2135.968,
			2137.856,
			2139.759,
			2141.658,
			2142.614,
			2144.532,
			2146.456,
			2147.423,
			2150.341,
			2152.299,
			2154.268,
			2157.246,
			2159.246,
			2162.274,
			2163.29,
			2164.309,
			2167.396,
			2169.471,
			2172.603,
			2174.694,
			2176.783,
			2177.841,
			2179.964,
			2181.031,
			2183.175,
			2186.413,
			2188.583,
			2190.766,
			2194.063,
			2195.167,
			2196.273,
			2199.609,
			2200.727,
			2201.846,
			2204.092,
			2205.219,
			2207.479,
			2209.751,
			2213.181,
			2215.482,
			2218.955,
			2220.119,
			2221.285,
			2224.801,
			2227.16,
			2230.72,
			2233.108,
			2235.507,
			2236.71,
			2239.127,
			2241.554,
			2242.771,
			2246.438,
			2248.895,
			2251.361,
			2255.077,
			2256.321,
			2257.566,
			2261.317,
			2262.572,
			2263.828,
			2267.612,
			2270.145,
			2273.961,
			2276.516,
			2279.08,
			2282.943,
			2284.235,
			2285.53,
			2289.426,
			2290.728,
			2292.033,
			2295.946,
			2298.564,
			2301.191,
			2302.508,
			2305.148,
			2307.797,
			2309.124,
			2313.117,
			2315.789,
			2318.469,
			2322.504,
			2325.205,
			2329.271,
			2330.631,
			2331.993,
			2336.089,
			2338.83,
			2342.953,
			2345.712,
			2348.477,
			2349.862,
			2352.636,
			2354.026,
			2356.81,
			2359.602,
			2361,
			2362.4,
			2366.611,
			2369.427,
			2372.25,
			2373.663,
			2376.496,
			2379.336,
			2380.759,
			2385.038,
			2387.899,
			2390.767,
			2395.081,
			2397.966,
			2402.305,
			2405.207,
			2409.571,
			2412.489,
			2416.877,
			2419.81,
			2422.748,
			2424.219,
			2427.166,
			2428.642,
			2431.597,
			2436.042,
			2439.012,
			2441.987,
			2444.969,
			2449.452,
			2453.949,
			2455.451,
			2456.955,
			2459.967,
			2461.501,
			2464.523,
			2467.551,
			2472.084,
			2475.134,
			2479.748,
			2481.282,
			2482.792,
			2487.435,
			2490.497,
			2495.167,
			2498.273,
			2501.392,
			2502.954,
			2506.088,
			2507.658,
			2510.809,
			2513.969,
			2515.559,
			2518.742,
			2521.947,
			2526.774,
			2530.002,
			2534.849,
			2536.469,
			2538.091,
			2544.597,
			2546.215,
			2551.042,
			2554.299,
			2559.149,
			2562.372,
			2565.566,
			2570.384,
			2572.011,
			2573.586,
			2578.378,
			2581.566,
			2586.366,
			2589.545,
			2592.727,
			2594.319,
			2597.509,
			2600.677,
			2602.281,
			2605.491,
			2607.101,
			2610.353,
			2613.586,
			2618.448,
			2621.696,
			2626.546,
			2628.173,
			2629.8,
			2634.683,
			2637.941,
			2642.832,
			2646.095,
			2649.362,
			2650.997,
			2654.27,
			2655.908,
			2659.189,
			2662.475,
			2664.121,
			2665.768,
			2670.716,
			2674.021,
			2677.333,
			2678.99,
			2682.305,
			2685.625,
			2687.286,
			2690.61,
			2692.273,
			2695.601,
			2698.932,
			2703.932,
			2707.269,
			2712.28,
			2713.951,
			2715.624,
			2720.644,
			2723.994,
			2729.021,
			2732.375,
			2735.732,
			2737.411,
			2740.771,
			2742.452,
			2745.815,
			2749.18,
			2750.864,
			2754.233,
			2757.606,
			2760.981,
			2764.359,
			2766.05,
			2769.434,
			2772.821,
			2774.516,
			2777.907,
			2779.604,
			2783,
			2786.399,
			2791.502,
			2794.909,
			2800.023,
			2801.729,
			2803.435,
			2808.56,
			2811.979,
			2817.113,
			2820.539,
			2823.967,
			2825.682,
			2829.113,
			2830.83,
			2834.265,
			2839.421,
			2842.861,
			2846.299,
			2851.436,
			2853.138,
			2854.833,
			2859.869,
			2861.529,
			2863.179,
			2866.448,
			2868.068,
			2871.279,
			2876.02,
			2879.13,
			2882.202,
			2886.74,
			2888.235,
			2889.72,
			2894.119,
			2897,
			2901.246,
			2904.027,
			2906.769,
			2908.125,
			2910.808,
			2913.451,
			2914.757,
			2917.343,
			2918.622,
			2921.148,
			2923.637,
			2927.296,
			2929.688,
			2933.206,
			2934.362,
			2935.509,
			2938.899,
			2941.113,
			2944.364,
			2946.483,
			2948.569,
			2951.629,
			2952.629,
			2953.62,
			2956.54,
			2957.497,
			2958.444,
			2961.256,
			2963.084,
			2964.887,
			2965.78,
			2967.551,
			2969.295,
			2970.16,
			2971.88,
			2972.734,
			2974.432,
			2976.126,
			2978.673,
			2980.374,
			2982.942,
			2983.811,
			2984.681,
			2987.294,
			2989.092,
			2991.801,
			2993.647,
			2995.516,
			2996.452,
			2998.338,
			2999.291,
			3001.198,
			3003.107,
			3004.061,
			3005.969,
			3007.881,
			3010.705,
			3011.647,
			3012.594,
			3014.434,
			3016.277,
			3017.201,
			3018.996,
			3019.896,
			3021.703,
			3023.509,
			3026.21,
			3028.026,
			3030.816,
			3031.752,
			3032.69,
			3035.646,
			3037.644,
			3040.784,
			3042.927,
			3045.088,
			3046.176,
			3048.359,
			3049.44,
			3051.607,
			3054.855,
			3057.013,
			3059.171,
			3062.394,
			3064.548,
			3067.777,
			3068.856,
			3069.932,
			3072.086,
			3073.164,
			3075.308,
			3077.445,
			3080.65,
			3082.778,
			3085.961,
			3087.02,
			3088.079,
			3091.243,
			3092.293,
			3093.344,
			3096.482,
			3098.569,
			3100.636,
			3101.679,
			3103.768,
			3105.872,
			3106.914,
			3109.002,
			3110.049,
			3112.149,
			3114.257,
			3117.438,
			3119.57,
			3122.785,
			3123.861,
			3124.939,
			3128.191,
			3130.371,
			3133.661,
			3135.866,
			3138.081,
			3139.191,
			3141.421,
			3142.539,
			3143.66,
			3147.036,
			3148.167,
			3149.3,
			3152.711,
			3154.978,
			3157.272,
			3158.422,
			3160.729,
			3163.042,
			3164.202,
			3166.528,
			3167.693,
			3170.028,
			3172.372,
			3175.902,
			3178.26,
			3181.787,
			3182.953,
			3184.114,
			3187.55,
			3189.797,
			3193.098,
			3195.253,
			3197.371,
			3198.416,
			3200.478,
			3201.495,
			3203.502,
			3205.473,
			3206.444,
			3208.359,
			3210.236,
			3212.078,
			3213.887,
			3214.778,
			3216.536,
			3218.258,
			3219.105,
			3220.771,
			3221.59,
			3223.202,
			3224.777,
			3227.093,
			3228.601,
			3230.842,
			3231.586,
			3232.332,
			3234.573,
			3236.072,
			3238.327,
			3239.824,
			3242.072,
			3243.573,
			3244.321,
			3245.815,
			3247.309,
			3248.056,
			3249.553,
			3251.05,
			3253.288,
			3254.791,
			3256.292,
			3257.785,
			3258.53,
			3260.774,
			3262.261,
			3263.74,
			3265.961,
			3267.426,
			3269.614,
			3270.341,
			3271.065,
			3273.219,
			3274.647,
			3276.774,
			3278.175,
			3280.271,
			3281.661,
			3283.04,
			3283.726,
			3285.104,
			3285.794,
			3287.165,
			3288.527,
			3290.578,
			3291.946,
			3293.987,
			3294.665,
			3295.362,
			3297.445,
			3298.831,
			3300.944,
			3302.371,
			3303.796,
			3305.94,
			3306.657,
			3307.375,
			3309.522,
			3310.244,
			3310.964,
			3313.153,
			3314.622,
			3316.094,
			3316.829,
			3318.306,
			3319.797,
			3320.547,
			3322.049,
			3322.804,
			3324.321,
			3325.847,
			3328.154,
			3329.703,
			3332.042,
			3332.825,
			3333.609,
			3335.979,
			3337.587,
			3340.016,
			3341.647,
			3343.293,
			3344.12,
			3345.783,
			3346.616,
			3348.272,
			3349.953,
			3350.795,
			3351.631,
			3354.122,
			3355.8,
			3357.461,
			3358.291,
			3359.958,
			3361.622,
			3362.446,
			3364.101,
			3364.933,
			3366.608,
			3368.304,
			3370.871,
			3372.609,
			3375.251,
			3376.138,
			3377.03,
			3379.736,
			3380.644,
			3381.555,
			3384.312,
			3386.167,
			3388.033,
			3388.97,
			3390.854,
			3391.802,
			3393.703,
			3395.615,
			3396.575,
			3398.496,
			3400.419,
			3403.304,
			3404.264,
			3405.222,
			3408.097,
			3409.044,
			3409.989,
			3411.853,
			3412.786,
			3414.632,
			3416.445,
			3419.1,
			3420.825,
			3423.343,
			3424.163,
			3424.975,
			3427.354,
			3428.896,
			3431.139,
			3432.589,
			3434.006,
			3434.703,
			3436.075,
			3436.75,
			3438.085,
			3439.406,
			3440.062,
			3441.368,
			3442.667,
			3444.601,
			3445.888,
			3447.786,
			3448.416,
			3449.044,
			3450.909,
			3452.142,
			3453.987,
			3455.195,
			3456.395,
			3458.189,
			3458.787,
			3459.386,
			3461.183,
			3461.781,
			3462.379,
			3464.179,
			3465.429,
			3467.298,
			3468.546,
			3469.825,
			3470.48,
			3471.788,
			3472.441,
			3473.75,
			3475.085,
			3477.111,
			3478.466,
			3480.535,
			3481.233,
			3481.931,
			3484.031,
			3485.457,
			3487.614,
			3489.06,
			3491.236,
			3492.692,
			3493.422,
			3494.884,
			3496.331,
			3497.057,
			3498.517,
			3499.976,
			3502.137,
			3503.59,
			3505.051,
			3506.498,
			3507.224,
			3508.688,
			3509.424,
			3510.908,
			3512.402,
			3514.663,
			3516.203,
			3518.543,
			3519.329,
			3520.117,
			3522.528,
			3524.149,
			3526.604,
			3528.253,
			3529.912,
			3530.745,
			3532.41,
			3533.242,
			3534.907,
			3536.585,
			3537.43,
			3539.129,
			3540.844,
			3543.448,
			3544.323,
			3545.201,
			3547.866,
			3548.762,
			3549.663,
			3552.389,
			3554.228,
			3556.065,
			3558.877,
			3560.771,
			3563.641,
			3564.604,
			3565.57,
			3568.493,
			3570.461,
			3573.443,
			3575.45,
			3577.472,
			3578.489,
			3580.533,
			3581.561,
			3583.627,
			3585.707,
			3586.752,
			3588.852,
			3590.954,
			3594.115,
			3595.175,
			3596.238,
			3599.445,
			3600.521,
			3601.599,
			3604.853,
			3607.038,
			3610.34,
			3612.557,
			3614.785,
			3618.153,
			3619.282,
			3620.415,
			3623.85,
			3624.996,
			3626.145,
			3629.614,
			3631.944,
			3634.266,
			3635.445,
			3637.831,
			3640.209,
			3641.403,
			3643.8,
			3645.004,
			3647.425,
			3649.858,
			3653.538,
			3656.007,
			3659.732,
			3660.98,
			3662.23,
			3665.999,
			3668.528,
			3672.339,
			3674.891,
			3677.448,
			3678.729,
			3681.298,
			3682.583,
			3685.161,
			3689.037,
			3691.629,
			3694.223,
			3696.804,
			3699.412,
			3700.72,
			3703.357,
			3705.975,
			3707.285,
			3709.912,
			3711.207,
			3713.845,
			3716.492,
			3720.476,
			3723.142,
			3727.155,
			3728.496,
			3729.839,
			3733.875,
			3736.592,
			3740.635,
			3743.326,
			3746.009,
			3747.347,
			3750.013,
			3751.34,
			3753.956,
			3756.572,
			3757.867,
			3760.437,
			3762.998,
			3766.771,
			3768.021,
			3769.269,
			3773.011,
			3774.259,
			3775.488,
			3777.992,
			3779.269,
			3781.785,
			3784.309,
			3788.11,
			3790.656,
			3794.493,
			3795.778,
			3797.064,
			3800.937,
			3803.53,
			3807.438,
			3810.053,
			3812.68,
			3813.995,
			3816.632,
			3817.955,
			3820.603,
			3824.592,
			3827.254,
			3829.917,
			3833.906,
			3836.564,
			3840.561,
			3841.896,
			3843.234,
			3847.253,
			3849.94,
			3853.974,
			3856.666,
			3859.364,
			3860.715,
			3863.421,
			3864.775,
			3866.13,
			3870.202,
			3871.561,
			3872.923,
			3877,
			3879.7,
			3882.449,
			3883.814,
			3886.543,
			3889.278,
			3890.645,
			3893.386,
			3894.757,
			3897.501,
			3900.25,
			3904.381,
			3907.137,
			3911.279,
			3912.662,
			3914.046,
			3918.216,
			3921.009,
			3925.218,
			3928.042,
			3930.852,
			3932.276,
			3935.154,
			3936.587,
			3938.025,
			3942.347,
			3943.767,
			3945.213,
			3949.555,
			3952.455,
			3955.357,
			3956.809,
			3959.716,
			3962.628,
			3964.086,
			3967.008,
			3968.473,
			3971.407,
			3974.348,
			3978.787,
			3981.719,
			3986.101,
			3987.531,
			3988.982,
			3993.3,
			3996.157,
			4000.421,
			4003.26,
			4007.514,
			4010.373,
			4011.792,
			4014.634,
			4017.484,
			4018.911,
			4021.772,
			4024.642,
			4028.957,
			4031.815,
			4034.699,
			4037.587,
			4039.033,
			4041.957,
			4043.41,
			4046.321,
			4049.241,
			4053.609,
			4056.547,
			4060.965,
			4062.439,
			4063.916,
			4068.352,
			4071.318,
			4075.798,
			4078.748,
			4081.726,
			4083.215,
			4086.196,
			4087.689,
			4090.704,
			4093.676,
			4095.177,
			4098.186,
			4101.203,
			4105.74,
			4107.256,
			4108.773,
			4113.334,
			4114.858,
			4116.383,
			4120.961,
			4124.011,
			4128.566,
			4131.602,
			4134.586,
			4139.005,
			4140.461,
			4141.91,
			4146.2,
			4147.613,
			4149.017,
			4153.176,
			4155.879,
			4158.569,
			4159.9,
			4162.534,
			4165.133,
			4166.419,
			4168.985,
			4170.246,
			4172.74,
			4175.2,
			4178.827,
			4181.201,
			4184.715,
			4185.877,
			4187.035,
			4190.506,
			4192.818,
			4196.331,
			4198.685,
			4201.075,
			4202.271,
			4204.671,
			4205.877,
			4207.082,
			4210.681,
			4211.875,
			4214.232,
			4216.577,
			4218.912,
			4222.307,
			4224.532,
			4226.696,
			4227.766,
			4229.877,
			4230.902,
			4232.928,
			4234.923,
			4237.847,
			4239.768,
			4242.678,
			4243.631,
			4244.577,
			4247.426,
			4249.305,
			4252.101,
			4253.942,
			4255.765,
			4256.67,
			4258.47,
			4259.365,
			4261.146,
			4263.781,
			4265.536,
			4267.276,
			4269.877,
			4271.61,
			4273.335,
			4275.062,
			4275.926,
			4278.518,
			4280.245,
			4282.836,
			4284.561,
			4286.285,
			4288.854,
			4289.709,
			4290.563,
			4293.11,
			4294.798,
			4297.326,
			4299,
			4301.508,
			4303.184,
			4304.021,
			4305.695,
			4307.367,
			4308.214,
			4309.904,
			4311.591,
			4314.133,
			4314.981,
			4315.83,
			4318.375,
			4319.224,
			4320.073,
			4322.617,
			4324.307,
			4325.994,
			4328.524,
			4330.212,
			4332.748,
			4333.595,
			4334.443,
			4336.995,
			4338.703,
			4341.285,
			4343.018,
			4344.765,
			4345.645,
			4347.414,
			4349.196,
			4350.095,
			4351.905,
			4352.814,
			4354.645,
			4356.499,
			4359.29,
			4361.185,
			4364.056,
			4365.019,
			4365.989,
			4368.923,
			4370.893,
			4373.869,
			4375.867,
			4377.87,
			4378.876,
			4380.894,
			4381.903,
			4383.929,
			4386.982,
			4388.003,
			4391.097,
			4393.157,
			4396.263,
			4398.341,
			4400.422,
			4401.464,
			4404.6,
			4406.691,
			4408.793,
			4411.954,
			4414.082,
			4417.283,
			4418.354,
			4419.427,
			4422.66,
			4424.827,
			4428.092,
			4430.271,
			4433.55,
			4435.734,
			4437.924,
			4439.017,
			4441.209,
			4442.308,
			4444.509,
			4446.716,
			4447.82,
			4450.01,
			4452.21,
			4454.397,
			4456.558,
			4457.626,
			4459.736,
			4461.814,
			4462.856,
			4464.882,
			4465.884,
			4467.864,
			4470.758,
			4472.667,
			4474.546,
			4477.339,
			4478.252,
			4479.157,
			4481.84,
			4483.604,
			4486.209,
			4487.933,
			4489.64,
			4490.488,
			4492.188,
			4493.035,
			4494.724,
			4496.41,
			4497.254,
			4498.936,
			4500.613,
			4503.12,
			4503.953,
			4504.785,
			4507.27,
			4508.093,
			4508.915,
			4511.379,
			4513.012,
			4514.64,
			4517.083,
			4518.705,
			4521.146,
			4521.964,
			4522.78,
			4525.218,
			4526.862,
			4529.352,
			4531.01,
			4532.666,
			4533.514,
			4535.182,
			4536.856,
			4537.698,
			4539.387,
			4540.235,
			4541.944,
			4543.666,
			4546.285,
			4548.046,
			4550.731,
			4551.634,
			4552.54,
			4555.295,
			4557.153,
			4559.975,
			4561.879,
			4563.794,
			4564.764,
			4566.708,
			4567.682,
			4569.642,
			4571.616,
			4572.604,
			4573.594,
			4576.582,
			4578.579,
			4580.57,
			4581.562,
			4583.541,
			4585.497,
			4586.461,
			4588.381,
			4589.335,
			4591.198,
			4593.03,
			4595.718,
			4597.469,
			4600.056,
			4600.911,
			4601.761,
			4604.291,
			4605.974,
			4608.505,
			4610.192,
			4612.758,
			4614.478,
			4615.339,
			4617.074,
			4619.688,
			4621.437,
			4623.192,
			4624.951,
			4626.7,
			4627.583,
			4629.357,
			4631.143,
			4632.04,
			4633.841,
			4634.746,
			4636.566,
			4638.412,
			4641.181,
			4643.042,
			4645.854,
			4646.797,
			4647.743,
			4650.584,
			4652.502,
			4655.407,
			4657.358,
			4659.323,
			4660.311,
			4662.293,
			4663.29,
			4665.291,
			4667.304,
			4668.314,
			4670.344,
			4672.385,
			4675.465,
			4677.53,
			4680.647,
			4681.69,
			4682.732,
			4684.81,
			4685.841,
			4687.887,
			4689.9,
			4692.853,
			4694.775,
			4697.592,
			4698.513,
			4699.423,
			4702.108,
			4703.855,
			4706.439,
			4708.128,
			4709.802,
			4710.635,
			4712.296,
			4713.125,
			4714.778,
			4716.43,
			4717.254,
			4718.902,
			4720.551,
			4723.021,
			4724.664,
			4727.132,
			4727.956,
			4728.778,
			4731.243,
			4732.896,
			4735.369,
			4737.023,
			4739.51,
			4741.165,
			4741.997,
			4742.83,
			4745.322,
			4746.152,
			4746.985,
			4749.483,
			4751.144,
			4752.812,
			4753.646,
			4755.311,
			4756.975,
			4757.813,
			4760.319,
			4761.985,
			4763.661,
			4766.168,
			4767.836,
			4770.359,
			4771.198,
			4772.037,
			4774.567,
			4776.257,
			4778.795,
			4780.498,
			4782.201,
			4783.052,
			4784.752,
			4785.6,
			4787.296,
			4788.996,
			4789.848,
			4791.556,
			4793.269,
			4794.979,
			4796.67,
			4797.526,
			4799.249,
			4800.973,
			4801.837,
			4803.571,
			4804.455,
			4806.182,
			4807.907,
			4810.492,
			4812.208,
			4814.778,
			4815.636,
			4816.502,
			4819.102,
			4820.842,
			4823.512,
			4825.304,
			4827.122,
			4828.045,
			4829.899,
			4830.832,
			4832.724,
			4834.643,
			4835.608,
			4837.555,
			4839.524,
			4842.509,
			4843.513,
			4844.521,
			4847.568,
			4848.588,
			4849.612,
			4852.705,
			4854.779,
			4856.869,
			4860.023,
			4862.146,
			4865.343,
			4866.415,
			4867.491,
			4870.719,
			4872.879,
			4876.099,
			4878.254,
			4880.394,
			4881.466,
			4883.619,
			4884.693,
			4886.844,
			4889.001,
			4890.082,
			4892.242,
			4894.404,
			4897.649,
			4899.807,
			4903.047,
			4904.125,
			4905.2,
			4908.432,
			4910.582,
			4913.818,
			4915.978,
			4918.148,
			4919.236,
			4921.404,
			4922.499,
			4924.699,
			4926.91,
			4928.02,
			4929.133,
			4932.49,
			4934.742,
			4937.008,
			4938.145,
			4940.428,
			4942.723,
			4943.874,
			4946.185,
			4947.344,
			4949.671,
			4952.009,
			4955.536,
			4957.901,
			4961.468,
			4962.663,
			4963.86,
			4967.467,
			4969.885,
			4973.532,
			4975.977,
			4978.432,
			4979.664,
			4982.135,
			4983.375,
			4985.861,
			4989.609,
			4990.864,
			4994.644,
			4997.175,
			5000.989,
			5003.545,
			5006.109,
			5007.396,
			5009.973,
			5011.265,
			5013.855,
			5016.453,
			5020.367,
			5022.977,
			5026.901,
			5028.212,
			5029.525,
			5033.474,
			5036.115,
			5040.089,
			5042.748,
			5046.749,
			5049.425,
			5050.766,
			5053.453,
			5056.147,
			5057.498,
			5060.203,
			5062.915,
			5066.997,
			5069.727,
			5073.835,
			5075.208,
			5076.583,
			5079.336,
			5080.715,
			5083.48,
			5086.251,
			5090.419,
			5093.208,
			5097.401,
			5098.802,
			5100.206,
			5104.425,
			5107.247,
			5111.491,
			5114.329,
			5117.172,
			5118.598,
			5121.453,
			5124.315,
			5125.748,
			5128.616,
			5130.053,
			5132.933,
			5135.818,
			5140.157,
			5141.605,
			5143.056,
			5147.418,
			5148.875,
			5150.335,
			5154.722,
			5157.654,
			5162.089,
			5165.028,
			5167.953,
			5172.383,
			5173.859,
			5175.337,
			5179.784,
			5181.269,
			5182.756,
			5187.23,
			5190.222,
			5193.22,
			5194.723,
			5197.734,
			5200.757,
			5202.271,
			5205.308,
			5206.83,
			5209.881,
			5212.939,
			5217.547,
			5220.633,
			5225.279,
			5226.834,
			5228.391,
			5233.067,
			5236.217,
			5240.881,
			5244.015,
			5247.181,
			5248.75,
			5251.889,
			5253.455,
			5256.561,
			5259.694,
			5261.261,
			5264.396,
			5267.557,
			5270.695,
			5273.839,
			5275.412,
			5278.537,
			5281.691,
			5283.272,
			5288.017,
			5291.187,
			5294.359,
			5299.126,
			5302.308,
			5307.085,
			5308.678,
			5310.271,
			5315.052,
			5318.243,
			5323.036,
			5326.236,
			5331.04,
			5334.248,
			5335.854,
			5339.065,
			5343.888,
			5347.107,
			5350.325,
			5355.156,
			5356.766,
			5358.377,
			5363.211,
			5364.823,
			5366.437,
			5371.28,
			5374.511,
			5379.359,
			5382.596,
			5385.832,
			5390.686,
			5393.953,
			5398.784,
			5400.402,
			5402.018,
			5406.865,
			5410.086,
			5414.902,
			5418.126,
			5421.315,
			5422.907,
			5426.062,
			5427.675,
			5430.847,
			5435.597,
			5438.76,
			5441.894,
			5446.629,
			5448.209,
			5449.785,
			5452.961,
			5456.082,
			5457.653,
			5462.369,
			5465.517,
			5470.242,
			5473.395,
			5476.551,
			5481.292,
			5482.874,
			5484.456,
			5489.207,
			5492.378,
			5497.14,
			5500.321,
			5503.506,
			5505.1,
			5508.286,
			5511.47,
			5513.058,
			5517.798,
			5520.93,
			5524.057,
			5528.644,
			5531.656,
			5536.108,
			5537.549,
			5539.006,
			5543.348,
			5546.192,
			5550.405,
			5553.223,
			5556.036,
			5557.442,
			5560.254,
			5561.681,
			5563.09,
			5568.735,
			5570.148,
			5574.396,
			5577.238,
			5581.502,
			5584.341,
			5587.18,
			5588.593,
			5591.393,
			5592.78,
			5595.536,
			5598.259,
			5602.273,
			5604.912,
			5608.841,
			5610.146,
			5611.448,
			5615.342,
			5617.933,
			5621.811,
			5624.395,
			5626.956,
			5628.248,
			5630.832,
			5632.147,
			5634.741,
			5637.34,
			5638.644,
			5641.253,
			5643.868,
			5647.798,
			5649.11,
			5650.424,
			5654.37,
			5655.688,
			5657.008,
			5659.652,
			5660.976,
			5663.628,
			5666.286,
			5670.284,
			5672.959,
			5676.985,
			5678.332,
			5679.681,
			5683.745,
			5686.465,
			5690.573,
			5693.327,
			5697.454,
			5700.267,
			5701.664,
			5704.468,
			5707.282,
			5708.691,
			5711.495,
			5714.352,
			5718.598,
			5720.016,
			5721.43,
			5725.679,
			5727.093,
			5728.484,
			5732.757,
			5735.591,
			5738.428,
			5739.848,
			5742.667,
			5745.539,
			5749.823,
			5751.255,
			5752.664,
			5756.974,
			5759.854,
			5764.191,
			5767.09,
			5769.996,
			5771.45,
			5774.364,
			5777.284,
			5778.746,
			5783.141,
			5786.078,
			5789.022,
			5793.453,
			5796.418,
			5800.879,
			5802.37,
			5803.861,
			5808.347,
			5811.344,
			5815.85,
			5818.861,
			5823.39,
			5826.416,
			5827.932,
			5830.968,
			5834.012,
			5835.537,
			5837.063,
			5841.653,
			5844.724,
			5849.341,
			5852.426,
			5855.516,
			5857.062,
			5860.157,
			5861.706,
			5864.807,
			5867.911,
			5872.572,
			5875.711,
			5880.363,
			5881.924,
			5883.487,
			5888.18,
			5891.313,
			5896.021,
			5899.163,
			5902.309,
			5903.883,
			5907.035,
			5908.612,
			5911.768,
			5916.513,
			5919.683,
			5922.856,
			5927.628,
			5930.814,
			5934.002,
			5937.192,
			5938.788,
			5943.585,
			5946.789,
			5949.999,
			5954.822,
			5958.044,
			5962.888,
			5964.504,
			5966.122,
			5970.98,
			5974.224,
			5979.095,
			5982.345,
			5985.596,
			5987.222,
			5990.476,
			5993.732,
			5995.361,
			5998.621,
			6000.251,
			6003.516,
			6006.785,
			6011.697,
			6013.337,
			6014.978,
			6019.909,
			6021.555,
			6023.202,
			6026.5,
			6028.151,
			6031.458,
			6034.769,
			6039.745,
			6043.071,
			6048.07,
			6049.738,
			6051.408,
			6056.425,
			6058.1,
			6059.775,
			6064.807,
			6068.166,
			6071.527,
			6073.21,
			6076.576,
			6079.947,
			6081.635,
			6085.011,
			6086.7,
			6090.082,
			6093.467,
			6098.552,
			6101.948,
			6107.052,
			6108.755,
			6110.459,
			6115.578,
			6118.997,
			6124.133,
			6127.563,
			6130.999,
			6132.718,
			6136.161,
			6137.884,
			6139.608,
			6144.79,
			6146.519,
			6148.25,
			6153.448,
			6156.921,
			6160.399,
			6162.14,
			6165.625,
			6169.115,
			6170.861,
			6174.357,
			6176.107,
			6179.611,
			6183.119,
			6188.39,
			6191.91,
			6197.201,
			6198.967,
			6200.734,
			6206.049,
			6209.602,
			6214.946,
			6218.518,
			6222.096,
			6223.889,
			6227.477,
			6229.274,
			6232.873,
			6236.478,
			6238.282,
			6241.896,
			6245.515,
			6250.958,
			6252.775,
			6254.594,
			6258.239,
			6261.891,
			6263.72,
			6267.381,
			6269.216,
			6272.889,
			6278.408,
			6282.098,
			6285.792,
			6291.339,
			6293.19,
			6295.043,
			6300.603,
			6304.314,
			6309.887,
			6313.605,
			6317.327,
			6319.189,
			6322.914,
			6324.778,
			6328.51,
			6332.247,
			6334.117,
			6337.864,
			6341.646,
			6347.283,
			6349.166,
			6351.046,
			6356.696,
			6358.548,
			6360.434,
			6366.091,
			6369.863,
			6373.64,
			6379.309,
			6383.087,
			6388.76,
			6390.653,
			6392.545,
			6398.227,
			6400.121,
			6402.016,
			6407.703,
			6411.497,
			6417.194,
			6420.994,
			6424.795,
			6426.697,
			6432.406,
			6436.214,
			6440.023,
			6445.741,
			6449.556,
			6455.282,
			6457.192,
			6459.102,
			6464.834,
			6468.66,
			6474.401,
			6478.229,
			6482.06,
			6483.976,
			6487.808,
			6489.725,
			6491.641,
			6497.393,
			6499.31,
			6501.228,
			6506.986,
			6510.826,
			6514.668,
			6516.59,
			6520.433,
			6524.276,
			6526.197,
			6530.039,
			6531.959,
			6535.795,
			6539.628,
			6545.371,
			6549.193,
			6554.9,
			6556.793,
			6558.681,
			6564.297,
			6568.007,
			6573.588,
			6577.285,
			6580.985,
			6582.836,
			6586.537,
			6590.242,
			6592.099,
			6595.781,
			6597.64,
			6599.498,
			6605.075,
			6608.794,
			6612.516,
			6614.378,
			6618.104,
			6621.832,
			6623.697,
			6627.428,
			6629.294,
			6633.028,
			6636.766,
			6642.377,
			6646.12,
			6651.741,
			6653.616,
			6655.491,
			6661.12,
			6664.876,
			6670.517,
			6674.277,
			6678.031,
			6679.901,
			6683.626,
			6685.478,
			6689.156,
			6692.797,
			6694.604,
			6698.188,
			6701.73,
			6706.975,
			6708.703,
			6710.45,
			6713.854,
			6717.194,
			6718.864,
			6722.176,
			6723.817,
			6727.099,
			6730.313,
			6735.062,
			6738.182,
			6742.794,
			6744.314,
			6745.802,
			6750.297,
			6753.281,
			6757.678,
			6760.567,
			6763.475,
			6764.929,
			6767.854,
			6769.303,
			6772.197,
			6776.527,
			6779.382,
			6782.256,
			6786.554,
			6789.412,
			6793.711,
			6795.135,
			6796.557,
			6800.829,
			6803.678,
			6807.957,
			6810.783,
			6813.63,
			6817.903,
			6819.329,
			6820.754,
			6826.512,
			6827.953,
			6832.292,
			6835.201,
			6838.096,
			6839.56,
			6842.496,
			6845.442,
			6846.92,
			6849.883,
			6851.366,
			6854.339,
			6857.319,
			6861.801,
			6864.796,
			6869.319,
			6870.818,
			6872.32,
			6876.837,
			6879.858,
			6884.365,
			6887.393,
			6890.425,
			6891.944,
			6895.01,
			6896.533,
			6898.057,
			6902.634,
			6904.139,
			6907.201,
			6910.271,
			6913.347,
			6916.429,
			6917.997,
			6921.089,
			6924.187,
			6925.738,
			6928.82,
			6930.374,
			6933.486,
			6936.63,
			6941.315,
			6944.443,
			6949.119,
			6950.689,
			6952.261,
			6957.01,
			6960.165,
			6964.907,
			6968.072,
			6971.234,
			6972.814,
			6975.941,
			6977.512,
			6980.638,
			6983.761,
			6985.271,
			6988.318,
			6991.329,
			6995.775,
			6998.692,
			7001.571,
			7004.412,
			7005.818,
			7008.602,
			7009.98,
			7012.709,
			7015.401,
			7019.372,
			7021.973,
			7025.806,
			7027.064,
			7028.315,
			7032.011,
			7034.431,
			7037.995,
			7040.329,
			7042.629,
			7043.764,
			7046.006,
			7047.115,
			7049.303,
			7051.456,
			7052.519,
			7054.618,
			7056.682,
			7059.708,
			7060.7,
			7061.682,
			7064.574,
			7065.521,
			7066.458,
			7068.306,
			7069.218,
			7071.02,
			7073.691,
			7075.441,
			7077.18,
			7079.772,
			7080.634,
			7081.493,
			7084.063,
			7084.916,
			7085.767,
			7088.318,
			7090.014,
			7092.552,
			7094.247,
			7095.938,
			7096.783,
			7098.477,
			7099.326,
			7101.021,
			7102.715,
			7105.28,
			7106.99,
			7109.578,
			7110.446,
			7111.312,
			7113.927,
			7115.684,
			7118.32,
			7120.092,
			7121.861,
			7124.525,
			7125.417,
			7127.201,
			7128.99,
			7129.89,
			7130.789,
			7133.491,
			7135.306,
			7137.122,
			7138.032,
			7139.861,
			7141.694,
			7142.612,
			7144.455,
			7145.377,
			7147.226,
			7149.081,
			7151.873,
			7153.74,
			7156.547,
			7157.484,
			7158.425,
			7161.258,
			7163.152,
			7166.009,
			7167.918,
			7169.832,
			7170.79,
			7172.706,
			7173.666,
			7175.589,
			7177.515,
			7178.479,
			7180.411,
			7182.345,
			7185.256,
			7187.206,
			7189.157,
			7191.116,
			7192.098,
			7194.059,
			7195.041,
			7197.01,
			7198.975,
			7201.924,
			7203.891,
			7206.832,
			7207.812,
			7208.793,
			7211.725,
			7213.674,
			7216.593,
			7218.531,
			7221.435,
			7223.373,
			7224.34,
			7226.271,
			7228.21,
			7229.183,
			7231.125,
			7233.067,
			7236.009,
			7236.991,
			7237.974,
			7240.95,
			7241.951,
			7242.954,
			7245.97,
			7248.004,
			7251.075,
			7253.144,
			7255.209,
			7258.357,
			7259.409,
			7260.479,
			7263.64,
			7265.763,
			7268.967,
			7271.118,
			7273.28,
			7274.364,
			7276.525,
			7278.714,
			7279.812,
			7283.124,
			7285.346,
			7287.581,
			7290.955,
			7292.086,
			7293.22,
			7296.639,
			7297.785,
			7298.934,
			7302.398,
			7304.722,
			7308.229,
			7310.581,
			7312.945,
			7316.512,
			7317.707,
			7318.905,
			7322.513,
			7323.721,
			7324.933,
			7328.583,
			7331.031,
			7334.721,
			7337.194,
			7339.679,
			7340.924,
			7344.677,
			7347.19,
			7349.715,
			7353.519,
			7356.066,
			7359.906,
			7361.19,
			7362.478,
			7366.353,
			7368.946,
			7372.854,
			7375.464,
			7379.389,
			7382.016,
			7383.333,
			7385.975,
			7388.623,
			7389.951,
			7391.281,
			7395.286,
			7397.964,
			7402,
			7404.703,
			7407.416,
			7408.776,
			7411.503,
			7412.869,
			7415.609,
			7418.359,
			7422.501,
			7425.274,
			7429.452,
			7430.849,
			7432.25,
			7436.464,
			7439.312,
			7443.567,
			7446.417,
			7449.274,
			7450.705,
			7453.572,
			7455.008,
			7457.884,
			7460.764,
			7462.205,
			7465.095,
			7467.991,
			7472.358,
			7473.819,
			7475.283,
			7479.661,
			7481.134,
			7482.607,
			7487.042,
			7490.012,
			7492.989,
			7497.496,
			7500.466,
			7504.966,
			7506.467,
			7507.97,
			7512.484,
			7515.5,
			7520.053,
			7523.077,
			7526.108,
			7527.6,
			7530.639,
			7532.161,
			7535.236,
			7539.831,
			7542.907,
			7545.992,
			7550.614,
			7553.724,
			7558.403,
			7559.968,
			7561.534,
			7566.241,
			7569.39,
			7574.126,
			7577.293,
			7580.497,
			7585.243,
			7586.836,
			7588.433,
			7594.844,
			7596.441,
			7601.211,
			7604.439,
			7607.646,
			7609.252,
			7612.469,
			7615.664,
			7617.28,
			7620.515,
			7622.139,
			7625.388,
			7628.674,
			7633.576,
			7636.856,
			7641.798,
			7643.45,
			7645.076,
			7650.077,
			7653.376,
			7658.377,
			7661.72,
			7665.069,
			7666.744,
			7670.1,
			7671.78,
			7675.142,
			7680.194,
			7681.881,
			7686.943,
			7690.323,
			7693.709,
			7695.403,
			7698.796,
			7702.195,
			7703.897,
			7707.302,
			7709.007,
			7712.42,
			7715.841,
			7720.983,
			7724.42,
			7729.586,
			7731.311,
			7733.037,
			7738.225,
			7741.691,
			7746.901,
			7750.382,
			7755.616,
			7759.114,
			7760.866,
			7764.374,
			7767.887,
			7769.646,
			7773.171,
			7776.702,
			7782.01,
			7783.784,
			7785.558,
			7789.109,
			7792.668,
			7794.45,
			7798.02,
			7799.808,
			7803.387,
			7806.973,
			7812.366,
			7815.971,
			7821.392,
			7823.204,
			7825.018,
			7830.468,
			7832.288,
			7834.109,
			7839.607,
			7843.228,
			7846.911,
			7848.707,
			7852.359,
			7854.185,
			7857.869,
			7861.526,
			7863.324,
			7866.983,
			7870.646,
			7876.148,
			7877.984,
			7879.822,
			7885.342,
			7887.184,
			7889.029,
			7894.571,
			7898.274,
			7903.844,
			7907.565,
			7911.295,
			7916.901,
			7918.771,
			7920.644,
			7926.268,
			7930.025,
			7935.672,
			7939.443,
			7945.114,
			7948.901,
			7952.696,
			7954.595,
			7958.398,
			7960.301,
			7964.142,
			7967.922,
			7973.644,
			7977.462,
			7983.23,
			7985.146,
			7987.062,
			7992.812,
			7996.652,
			8002.423,
			8006.273,
			8010.129,
			8012.055,
			8015.91,
			8017.836,
			8019.731,
			8025.546,
			8027.475,
			8029.4,
			8035.173,
			8039.015,
			8042.853,
			8044.77,
			8048.604,
			8052.438,
			8054.355,
			8058.186,
			8060.101,
			8063.922,
			8067.696,
			8073.357,
			8077.09,
			8082.617,
			8084.44,
			8086.251,
			8091.615,
			8095.14,
			8100.351,
			8103.775,
			8107.158,
			8108.834,
			8112.156,
			8113.802,
			8117.066,
			8120.287,
			8121.883,
			8125.045,
			8128.166,
			8132.772,
			8135.796,
			8138.782,
			8141.731,
			8143.192,
			8146.081,
			8147.511,
			8150.34,
			8153.13,
			8157.245,
			8159.945,
			8163.953,
			8165.266,
			8166.57,
			8170.436,
			8172.981,
			8176.744,
			8179.252,
			8182.998,
			8185.487,
			8186.729,
			8189.211,
			8191.709,
			8192.946,
			8195.422,
			8197.895,
			8201.609,
			8202.848,
			8204.087,
			8207.809,
			8209.054,
			8210.303,
			8214.037,
			8216.564,
			8219.076,
			8222.851,
			8225.373,
			8229.15,
			8230.391,
			8231.673,
			8236.723,
			8237.988,
			8241.792,
			8244.313,
			8246.862,
			8248.138,
			8250.695,
			8251.976,
			8254.54,
			8258.398,
			8260.977,
			8263.558,
			8267.427,
			8269.996,
			8273.813,
			8275.073,
			8276.325,
			8280.029,
			8282.451,
			8286.018,
			8288.353,
			8291.791,
			8294.039,
			8295.168,
			8296.269,
			8299.523,
			8301.66,
			8304.835,
			8306.941,
			8309.044,
			8310.102,
			8312.215,
			8314.327,
			8315.386,
			8317.514,
			8318.576,
			8320.7,
			8322.834,
			8326.031,
			8328.169,
			8331.372,
			8332.438,
			8333.504,
			8336.693,
			8337.752,
			8338.809,
			8341.966,
			8344.059,
			8347.178,
			8349.245,
			8350.276,
			8352.328,
			8354.37,
			8355.389,
			8357.411,
			8359.422,
			8362.419,
			8363.412,
			8364.402,
			8366.378,
			8368.346,
			8369.324,
			8371.275,
			8372.25,
			8374.192,
			8376.126,
			8379.021,
			8380.945,
			8383.826,
			8384.785,
			8385.747,
			8388.629,
			8390.552,
			8393.442,
			8395.359,
			8397.298,
			8398.27,
			8400.212,
			8401.179,
			8403.107,
			8405.012,
			8405.95,
			8407.807,
			8409.639,
			8412.321,
			8413.198,
			8414.073,
			8416.647,
			8417.486,
			8418.316,
			8420.77,
			8422.375,
			8423.944,
			8426.235,
			8427.724,
			8429.885,
			8430.588,
			8431.284,
			8433.326,
			8433.994,
			8434.654,
			8436.607,
			8437.898,
			8439.17,
			8439.805,
			8441.069,
			8441.699,
			8442.958,
			8444.205,
			8444.823,
			8446.056,
			8447.283,
			8449.113,
			8449.723,
			8450.329,
			8452.138,
			8452.736,
			8453.333,
			8455.114,
			8456.296,
			8458.052,
			8459.211,
			8460.361,
			8462.073,
			8462.639,
			8463.201,
			8464.873,
			8465.427,
			8465.978,
			8467.631,
			8468.725,
			8469.811,
			8470.352,
			8471.431,
			8472.51,
			8473.052,
			8474.128,
			8474.662,
			8475.73,
			8476.797,
			8478.425,
			8479.512,
			8481.14,
			8481.683,
			8482.228,
			8483.883,
			8484.998,
			8486.676,
			8487.799,
			8489.518,
			8490.691,
			8491.279,
			8492.462,
			8494.253,
			8494.864,
			8496.751,
			8498.018,
			8499.288,
			8499.929,
			8501.236,
			8502.563,
			8503.231,
			8504.577,
			8505.256,
			8506.623,
			8508.001,
			8510.092,
			8511.505,
			8513.546,
			8514.232,
			8514.921,
			8517.003,
			8518.373,
			8520.46,
			8521.87,
			8523.312,
			8524.041,
			8525.508,
			8526.246,
			8527.749,
			8529.307,
			8530.09,
			8531.664,
			8533.253,
			8535.749,
			8537.423,
			8539.976,
			8540.839,
			8541.703,
			8544.309,
			8546.07,
			8547.836,
			8550.489,
			8552.246,
			8554.883,
			8555.762,
			8556.63,
			8559.227,
			8560.956,
			8563.506,
			8565.192,
			8566.877,
			8567.715,
			8569.369,
			8571.021,
			8571.847,
			8574.313,
			8575.943,
			8577.573,
			8580.014,
			8581.637,
			8584.076,
			8584.889,
			8585.701,
			8588.143,
			8589.776,
			8592.23,
			8593.866,
			8595.509,
			8597.981,
			8598.808,
			8599.635,
			8602.145,
			8602.985,
			8603.826,
			8606.363,
			8608.066,
			8609.771,
			8610.626,
			8612.344,
			8614.071,
			8614.937,
			8616.673,
			8617.545,
			8619.301,
			8621.053,
			8623.729,
			8625.532,
			8628.279,
			8629.195,
			8630.117,
			8632.902,
			8634.776,
			8637.615,
			8639.508,
			8641.43,
			8642.396,
			8644.339,
			8645.315,
			8647.277,
			8649.252,
			8650.244,
			8651.24,
			8654.248,
			8656.268,
			8658.3,
			8659.32,
			8661.371,
			8663.433,
			8664.467,
			8667.589,
			8669.674,
			8671.761,
			8674.91,
			8677.024,
			8680.218,
			8681.288,
			8682.36,
			8685.596,
			8687.766,
			8691.043,
			8693.243,
			8695.454,
			8696.564,
			8698.792,
			8699.91,
			8702.154,
			8704.409,
			8705.541,
			8707.812,
			8710.094,
			8712.387,
			8715.846,
			8719.328,
			8720.494,
			8721.663,
			8724.008,
			8725.185,
			8727.545,
			8729.915,
			8733.49,
			8735.887,
			8739.502,
			8740.713,
			8741.926,
			8745.581,
			8748.031,
			8751.727,
			8754.203,
			8757.938,
			8760.44,
			8761.695,
			8764.213,
			8766.74,
			8768.008,
			8770.552,
			8773.105,
			8776.957,
			8778.246,
			8779.537,
			8783.43,
			8784.733,
			8786.038,
			8789.971,
			8792.604,
			8796.555,
			8799.194,
			8801.836,
			8805.812,
			8807.138,
			8808.467,
			8812.461,
			8813.795,
			8815.129,
			8819.144,
			8821.826,
			8824.516,
			8825.863,
			8828.558,
			8831.261,
			8832.615,
			8835.324,
			8836.682,
			8839.402,
			8842.129,
			8846.229,
			8848.973,
			8853.099,
			8854.478,
			8855.857,
			8860.012,
			8862.789,
			8866.97,
			8869.767,
			8872.569,
			8873.975,
			8876.792,
			8878.203,
			8879.615,
			8883.869,
			8885.291,
			8888.14,
			8890.998,
			8893.863,
			8896.738,
			8898.179,
			8901.063,
			8903.959,
			8905.409,
			8908.315,
			8909.771,
			8912.688,
			8915.609,
			8920.002,
			8922.938,
			8927.352,
			8928.825,
			8930.3,
			8934.733,
			8937.693,
			8942.144,
			8945.115,
			8948.093,
			8949.584,
			8952.568,
			8954.064,
			8957.059,
			8960.059,
			8961.562,
			8964.571,
			8967.587,
			8972.122,
			8973.637,
			8975.153,
			8978.19,
			8981.233,
			8982.757,
			8985.809,
			8987.336,
			8990.396,
			8993.464,
			8998.075,
			9001.156,
			9005.79,
			9007.337,
			9008.886,
			9013.541,
			9016.652,
			9021.33,
			9024.455,
			9027.587,
			9029.154,
			9032.295,
			9033.867,
			9037.017,
			9040.172,
			9041.751,
			9044.914,
			9048.084,
			9052.85,
			9056.035,
			9060.82,
			9062.417,
			9064.015,
			9068.82,
			9072.031,
			9075.25,
			9080.088,
			9083.32,
			9088.18,
			9089.801,
			9091.425,
			9097.931,
			9099.56,
			9104.451,
			9107.717,
			9112.616,
			9115.881,
			9119.138,
			9120.761,
			9125.593,
			9128.777,
			9131.928,
			9136.593,
			9139.662,
			9144.205,
			9145.701,
			9147.188,
			9151.596,
			9154.49,
			9158.772,
			9161.583,
			9164.381,
			9165.756,
			9168.478,
			9169.827,
			9171.168,
			9175.124,
			9176.456,
			9177.758,
			9181.618,
			9184.159,
			9186.683,
			9187.938,
			9190.441,
			9192.935,
			9194.18,
			9196.668,
			9197.911,
			9200.397,
			9202.889,
			9206.635,
			9209.148,
			9212.935,
			9214.204,
			9215.48,
			9219.32,
			9221.901,
			9225.793,
			9228.425,
			9231.068,
			9232.395,
			9235.05,
			9236.378,
			9239.062,
			9241.757,
			9243.097,
			9245.777,
			9248.465,
			9252.511,
			9253.861,
			9255.213,
			9257.914,
			9260.619,
			9261.97,
			9266.018,
			9268.688,
			9271.372,
			9275.418,
			9278.098,
			9282.118,
			9283.457,
			9284.797,
			9288.813,
			9291.493,
			9295.516,
			9298.201,
			9302.234,
			9304.928,
			9306.276,
			9308.976,
			9311.68,
			9313.034,
			9315.747,
			9318.467,
			9322.56,
			9325.296,
			9329.396,
			9330.773,
			9332.155,
			9336.314,
			9339.099,
			9343.29,
			9346.091,
			9348.895,
			9353.092,
			9354.481,
			9355.865,
			9359.975,
			9362.671,
			9366.646,
			9369.25,
			9371.816,
			9373.086,
			9375.598,
			9378.073,
			9379.296,
			9382.91,
			9385.277,
			9387.609,
			9391.039,
			9393.277,
			9396.562,
			9397.64,
			9398.707,
			9401.851,
			9403.901,
			9406.907,
			9408.867,
			9411.745,
			9413.623,
			9414.566,
			9416.404,
			9418.223,
			9419.129,
			9420.934,
			9422.729,
			9424.516,
			9426.294,
			9427.179,
			9428.938,
			9430.688,
			9431.562,
			9433.31,
			9434.184,
			9435.926,
			9437.664,
			9440.302,
			9442.061,
			9444.718,
			9445.614,
			9446.51,
			9449.195,
			9451.007,
			9453.717,
			9455.534,
			9457.353,
			9458.261,
			9460.081,
			9460.995,
			9462.821,
			9464.646,
			9465.562,
			9467.399,
			9469.235,
			9471.996,
			9472.918,
			9473.84,
			9475.682,
			9477.525,
			9478.446,
			9481.205,
			9483.044,
			9484.877,
			9487.627,
			9489.458,
			9492.2,
			9493.115,
			9494.03,
			9496.77,
			9498.585,
			9501.348,
			9503.189,
			9505.043,
			9505.975,
			9507.847,
			9509.721,
			9510.662,
			9513.505,
			9515.411,
			9517.33,
			9520.234,
			9522.188,
			9525.137,
			9526.126,
			9527.117,
			9529.101,
			9530.096,
			9532.099,
			9535.125,
			9537.148,
			9539.175,
			9542.244,
			9543.275,
			9544.31,
			9547.438,
			9549.543,
			9552.727,
			9554.868,
			9557.02,
			9558.095,
			9560.254,
			9561.336,
			9563.492,
			9566.72,
			9568.871,
			9571.019,
			9574.252,
			9576.403,
			9579.647,
			9580.73,
			9581.814,
			9585.083,
			9587.277,
			9590.586,
			9592.81,
			9595.045,
			9598.42,
			9599.551,
			9601.82,
			9604.1,
			9605.242,
			9606.389,
			9609.842,
			9612.136,
			9614.461,
			9615.627,
			9617.965,
			9620.312,
			9621.491,
			9623.854,
			9625.038,
			9627.415,
			9629.805,
			9633.405,
			9635.82,
			9639.452,
			9640.667,
			9641.882,
			9645.527,
			9647.949,
			9651.544,
			9653.904,
			9656.23,
			9657.381,
			9659.654,
			9660.777,
			9662.998,
			9666.26,
			9667.33,
			9670.49,
			9673.571,
			9674.58,
			9675.58,
			9677.556,
			9679.496,
			9680.454,
			9682.344,
			9683.275,
			9685.114,
			9686.925,
			9689.579,
			9691.311,
			9693.865,
			9694.701,
			9695.531,
			9698.016,
			9699.668,
			9702.158,
			9703.826,
			9705.497,
			9706.335,
			9708.016,
			9708.857,
			9710.541,
			9712.23,
			9713.078,
			9714.773,
			9716.467,
			9719.042,
			9720.76,
			9723.337,
			9724.224,
			9725.107,
			9727.754,
			9729.518,
			9732.235,
			9734.041,
			9735.843,
			9738.592,
			9739.504,
			9740.412,
			9743.15,
			9744.075,
			9745,
			9747.762,
			9749.613,
			9751.484,
			9752.418,
			9754.287,
			9755.223,
			9757.098,
			9759.902,
			9761.766,
			9763.606,
			9766.348,
			9768.171,
			9770.942,
			9771.864,
			9772.784,
			9775.497,
			9777.283,
			9779.937,
			9781.693,
			9784.313,
			9786.041,
			9786.902,
			9787.762,
			9790.316,
			9791.161,
			9792.004,
			9794.522,
			9796.172,
			9797.82,
			9798.646,
			9800.28,
			9801.902,
			9802.715,
			9804.342,
			9805.147,
			9806.747,
			9808.35,
			9810.761,
			9812.367,
			9814.785,
			9815.594,
			9816.412,
			9818.869,
			9820.513,
			9823.019,
			9824.705,
			9826.398,
			9827.25,
			9828.97,
			9830.688,
			9831.559,
			9833.311,
			9834.189,
			9835.963,
			9837.749,
			9840.461,
			9842.288,
			9844.129,
			9845.987,
			9846.923,
			9848.806,
			9849.752,
			9851.659,
			9853.58,
			9856.493,
			9858.452,
			9861.416,
			9862.412,
			9863.411,
			9866.42,
			9868.443,
			9871.5,
			9873.553,
			9875.617,
			9876.654,
			9878.733,
			9879.777,
			9881.874,
			9883.978,
			9885.028,
			9887.135,
			9889.249,
			9892.445,
			9893.518,
			9894.592,
			9897.832,
			9898.918,
			9900.008,
			9903.294,
			9905.499,
			9908.829,
			9911.064,
			9913.311,
			9916.699,
			9917.835,
			9918.973,
			9923.553,
			9924.705,
			9928.175,
			9930.502,
			9932.842,
			9934.013,
			9936.365,
			9937.546,
			9939.914,
			9942.291,
			9943.481,
			9945.869,
			9948.258,
			9951.834,
			9954.2,
			9957.711,
			9958.887,
			9960.035,
			9963.412,
			9965.633,
			9968.925,
			9971.076,
			9973.207,
			9974.269,
			9976.383,
			9977.438,
			9978.489,
			9982.694,
			9983.747,
			9986.904,
			9989.012,
			9991.121,
			9992.176,
			9994.286,
			9996.396,
			9997.45,
			9999.557,
			10000.61,
			10002.718,
			10004.822,
			10007.979,
			10010.092,
			10013.265,
			10014.329,
			10015.398,
			10018.61,
			10020.771,
			10024.049,
			10026.246,
			10028.482,
			10029.605,
			10031.861,
			10032.995,
			10035.298,
			10037.615,
			10038.779,
			10041.131,
			10043.501,
			10047.076,
			10048.27,
			10049.464,
			10051.863,
			10054.261,
			10055.46,
			10057.867,
			10059.054,
			10061.484,
			10063.902,
			10067.532,
			10069.924,
			10073.569,
			10074.764,
			10075.959,
			10079.56,
			10081.949,
			10085.543,
			10087.967,
			10091.639,
			10094.107,
			10095.366,
			10097.897,
			10100.441,
			10101.728,
			10104.292,
			10106.912,
			10110.836,
			10112.127,
			10113.464,
			10116.097,
			10118.735,
			10120.059,
			10124.022,
			10126.666,
			10129.294,
			10133.23,
			10135.862,
			10139.826,
			10141.144,
			10142.462,
			10146.42,
			10149.058,
			10153.014,
			10155.648,
			10158.276,
			10159.591,
			10162.214,
			10164.832,
			10166.14,
			10168.729,
			10170.035,
			10172.648,
			10175.286,
			10179.222,
			10181.855,
			10185.821,
			10187.147,
			10188.476,
			10192.467,
			10195.129,
			10199.104,
			10201.734,
			10204.339,
			10205.629,
			10208.186,
			10209.451,
			10210.707,
			10214.436,
			10215.662,
			10216.882,
			10220.495,
			10222.87,
			10225.221,
			10226.386,
			10228.703,
			10230.988,
			10232.121,
			10234.362,
			10235.473,
			10237.675,
			10239.859,
			10243.11,
			10245.262,
			10248.486,
			10249.559,
			10250.631,
			10253.837,
			10255.975,
			10259.177,
			10261.315,
			10263.457,
			10264.529,
			10266.678,
			10267.754,
			10269.914,
			10272.078,
			10273.164,
			10275.34,
			10277.521,
			10280.803,
			10282.995,
			10285.19,
			10287.388,
			10288.488,
			10291.795,
			10294,
			10296.208,
			10299.529,
			10301.744,
			10305.08,
			10306.197,
			10307.314,
			10310.673,
			10312.93,
			10316.329,
			10318.607,
			10322.041,
			10324.34,
			10325.494,
			10327.804,
			10331.28,
			10333.607,
			10335.941,
			10339.462,
			10340.633,
			10341.805,
			10345.334,
			10346.516,
			10347.697,
			10351.248,
			10353.626,
			10356.009,
			10359.593,
			10361.989,
			10365.59,
			10366.793,
			10367.998,
			10371.627,
			10374.058,
			10377.722,
			10380.174,
			10383.865,
			10386.33,
			10388.799,
			10390.034,
			10392.503,
			10393.738,
			10396.213,
			10398.69,
			10402.417,
			10404.91,
			10408.662,
			10409.918,
			10411.175,
			10414.955,
			10417.484,
			10421.277,
			10423.831,
			10426.393,
			10430.258,
			10431.549,
			10432.845,
			10436.759,
			10438.037,
			10439.338,
			10443.256,
			10445.881,
			10448.516,
			10449.835,
			10452.481,
			10455.136,
			10456.465,
			10460.467,
			10463.146,
			10465.831,
			10469.876,
			10472.582,
			10476.658,
			10478.021,
			10479.386,
			10483.492,
			10486.24,
			10490.376,
			10493.144,
			10495.92,
			10497.311,
			10500.098,
			10501.496,
			10504.295,
			10508.51,
			10511.327,
			10514.146,
			10516.954,
			10521.126,
			10523.865,
			10526.57,
			10527.908,
			10530.558,
			10531.868,
			10534.463,
			10537.021,
			10540.789,
			10543.256,
			10546.888,
			10548.081,
			10549.267,
			10552.771,
			10555.066,
			10558.44,
			10560.646,
			10562.818,
			10563.891,
			10566.01,
			10567.057,
			10569.123,
			10572.16,
			10574.145,
			10576.112,
			10578.989,
			10579.936,
			10580.879,
			10583.689,
			10584.623,
			10585.556,
			10587.424,
			10588.361,
			10590.24,
			10592.129,
			10594.986,
			10596.913,
			10599.819,
			10600.793,
			10601.778,
			10604.746,
			10605.739,
			10606.734,
			10609.759,
			10611.783,
			10613.806,
			10614.821,
			10616.848,
			10617.857,
			10619.871,
			10621.862,
			10622.854,
			10624.81,
			10626.732,
			10629.557,
			10631.388,
			10634.086,
			10634.97,
			10635.846,
			10638.419,
			10640.086,
			10642.52,
			10644.097,
			10645.636,
			10647.879,
			10648.61,
			10649.334,
			10651.47,
			10652.163,
			10652.851,
			10654.887,
			10656.237,
			10657.575,
			10658.238,
			10659.555,
			10660.875,
			10661.545,
			10663.534,
			10664.85,
			10666.187,
			10668.215,
			10669.553,
			10671.555,
			10672.229,
			10672.92,
			10674.982,
			10676.354,
			10678.41,
			10679.792,
			10681.175,
			10681.865,
			10683.246,
			10683.938,
			10685.324,
			10687.406,
			10688.785,
			10690.165,
			10692.242,
			10692.93,
			10693.616,
			10694.984,
			10696.352,
			10697.037,
			10699.145,
			10700.542,
			10701.935,
			10704.036,
			10705.469,
			10707.603,
			10708.312,
			10709.023,
			10711.18,
			10712.619,
			10714.774,
			10716.213,
			10717.652,
			10718.371,
			10719.809,
			10720.527,
			10721.967,
			10723.391,
			10724.098,
			10725.516,
			10726.936,
			10729.064,
			10729.765,
			10730.467,
			10731.875,
			10733.291,
			10734.002,
			10736.146,
			10737.574,
			10739.027,
			10741.246,
			10742.756,
			10745.068,
			10745.846,
			10746.635,
			10749.023,
			10750.638,
			10753.104,
			10754.764,
			10757.29,
			10758.992,
			10760.708,
			10761.572,
			10764.156,
			10765.878,
			10767.611,
			10770.218,
			10771.972,
			10774.616,
			10775.5,
			10776.389,
			10779.068,
			10780.861,
			10783.572,
			10785.395,
			10787.226,
			10788.145,
			10789.994,
			10790.923,
			10791.856,
			10794.668,
			10795.609,
			10796.555,
			10799.41,
			10801.326,
			10803.25,
			10804.217,
			10806.159,
			10808.109,
			10809.088,
			10811.055,
			10812.042,
			10814.026,
			10816.023,
			10819.031,
			10821.062,
			10824.149,
			10825.182,
			10826.217,
			10829.333,
			10831.396,
			10834.535,
			10836.643,
			10838.765,
			10839.831,
			10841.973,
			10843.049,
			10845.207,
			10847.382,
			10848.473,
			10850.664,
			10852.867,
			10855.08,
			10857.305,
			10858.422,
			10860.664,
			10862.918,
			10864.049,
			10866.32,
			10867.46,
			10869.747,
			10872.045,
			10875.516,
			10877.844,
			10881.357,
			10882.534,
			10883.714,
			10887.27,
			10889.654,
			10893.254,
			10895.666,
			10898.09,
			10899.306,
			10901.746,
			10902.971,
			10905.427,
			10907.894,
			10909.131,
			10911.614,
			10914.108,
			10917.867,
			10919.125,
			10920.385,
			10924.181,
			10925.451,
			10926.723,
			10929.275,
			10930.555,
			10933.119,
			10935.696,
			10939.576,
			10942.176,
			10946.086,
			10947.391,
			10948.697,
			10952.63,
			10955.262,
			10959.228,
			10961.882,
			10965.878,
			10968.553,
			10969.894,
			10972.581,
			10976.628,
			10979.336,
			10982.053,
			10986.144,
			10988.882,
			10993.006,
			10994.385,
			10995.766,
			10999.923,
			11002.703,
			11006.895,
			11009.724,
			11013.926,
			11016.756,
			11018.174,
			11019.617,
			11023.893,
			11026.757,
			11031.065,
			11033.952,
			11036.848,
			11038.3,
			11041.214,
			11044.113,
			11045.578,
			11048.516,
			11049.988,
			11052.939,
			11055.897,
			11060.352,
			11063.328,
			11067.803,
			11069.293,
			11070.783,
			11075.232,
			11078.17,
			11082.521,
			11085.378,
			11088.197,
			11089.592,
			11092.355,
			11093.723,
			11096.43,
			11099.1,
			11100.421,
			11103.037,
			11105.618,
			11108.161,
			11110.67,
			11111.91,
			11114.362,
			11116.779,
			11117.976,
			11120.34,
			11121.511,
			11123.826,
			11126.102,
			11129.447,
			11131.629,
			11134.835,
			11135.885,
			11136.926,
			11139.996,
			11142.02,
			11144.984,
			11146.939,
			11148.878,
			11149.841,
			11151.764,
			11152.72,
			11154.624,
			11157.471,
			11159.358,
			11161.241,
			11164.059,
			11165.003,
			11165.953,
			11167.85,
			11169.742,
			11170.689,
			11173.587,
			11175.525,
			11178.444,
			11180.427,
			11182.408,
			11185.396,
			11186.406,
			11187.416,
			11190.451,
			11191.471,
			11192.512,
			11195.635,
			11197.728,
			11199.862,
			11200.932,
			11203.074,
			11204.146,
			11206.287,
			11209.504,
			11211.598,
			11213.682,
			11216.778,
			11218.785,
			11221.793,
			11222.771,
			11223.737,
			11226.634,
			11228.564,
			11230.493,
			11233.378,
			11235.31,
			11238.248,
			11239.225,
			11240.2,
			11243.137,
			11244.117,
			11245.098,
			11248.039,
			11249.982,
			11252.947,
			11254.922,
			11256.903,
			11257.896,
			11260.887,
			11262.893,
			11264.911,
			11267.955,
			11269.993,
			11273.067,
			11274.096,
			11275.127,
			11278.241,
			11280.336,
			11283.497,
			11285.616,
			11288.812,
			11290.962,
			11292.043,
			11294.215,
			11296.4,
			11297.498,
			11299.703,
			11301.923,
			11304.155,
			11307.531,
			11309.797,
			11312.077,
			11313.223,
			11315.521,
			11316.674,
			11318.99,
			11321.314,
			11324.824,
			11327.173,
			11330.705,
			11331.881,
			11333.054,
			11336.528,
			11338.82,
			11342.197,
			11344.409,
			11346.569,
			11347.632,
			11349.72,
			11350.75,
			11352.781,
			11354.769,
			11355.748,
			11356.705,
			11359.576,
			11362.413,
			11364.27,
			11367.028,
			11367.939,
			11368.849,
			11371.558,
			11373.355,
			11375.146,
			11377.809,
			11379.572,
			11382.207,
			11383.08,
			11383.951,
			11386.546,
			11388.283,
			11390.879,
			11392.599,
			11395.212,
			11396.956,
			11397.826,
			11399.559,
			11401.332,
			11402.215,
			11403.973,
			11405.724,
			11408.39,
			11410.164,
			11412.824,
			11413.709,
			11414.594,
			11417.249,
			11419.011,
			11421.637,
			11423.402,
			11425.16,
			11427.76,
			11428.632,
			11429.508,
			11432.117,
			11432.982,
			11433.848,
			11436.462,
			11438.216,
			11439.977,
			11440.858,
			11442.624,
			11444.385,
			11445.294,
			11447.083,
			11447.978,
			11449.768,
			11451.565,
			11454.266,
			11456.064,
			11458.753,
			11459.655,
			11460.559,
			11463.278,
			11465.102,
			11467.857,
			11469.708,
			11472.5,
			11474.374,
			11475.314,
			11477.207,
			11479.107,
			11480.062,
			11481.019,
			11483.906,
			11485.846,
			11487.795,
			11488.772,
			11490.738,
			11492.714,
			11493.704,
			11495.691,
			11496.69,
			11498.695,
			11500.708,
			11503.744,
			11505.783,
			11508.854,
			11509.883,
			11510.913,
			11514.02,
			11516.102,
			11519.222,
			11521.305,
			11524.445,
			11526.549,
			11527.605,
			11529.725,
			11531.853,
			11532.92,
			11535.062,
			11537.213,
			11540.456,
			11541.541,
			11542.629,
			11545.906,
			11547.003,
			11548.104,
			11550.308,
			11551.414,
			11553.634,
			11555.862,
			11559.222,
			11561.474,
			11564.867,
			11566.004,
			11567.142,
			11570.572,
			11572.869,
			11576.333,
			11578.654,
			11580.984,
			11582.152,
			11584.498,
			11585.673,
			11588.03,
			11591.585,
			11593.967,
			11596.357,
			11599.961,
			11602.375,
			11606.015,
			11607.232,
			11608.453,
			11612.127,
			11614.588,
			11618.297,
			11620.781,
			11623.275,
			11627.032,
			11628.289,
			11629.549,
			11633.34,
			11634.608,
			11635.879,
			11639.703,
			11642.264,
			11644.835,
			11646.123,
			11648.705,
			11651.297,
			11652.597,
			11656.503,
			11659.109,
			11661.723,
			11665.656,
			11668.291,
			11672.254,
			11673.579,
			11674.906,
			11678.898,
			11681.569,
			11685.588,
			11688.276,
			11692.324,
			11695.031,
			11696.388,
			11699.105,
			11703.194,
			11704.562,
			11708.671,
			11711.42,
			11715.557,
			11718.322,
			11721.097,
			11722.486,
			11726.666,
			11729.461,
			11732.261,
			11736.472,
			11739.288,
			11743.522,
			11744.936,
			11746.352,
			11750.607,
			11753.451,
			11757.729,
			11760.587,
			11763.452,
			11764.887,
			11767.758,
			11769.197,
			11772.077,
			11774.963,
			11776.408,
			11779.303,
			11782.202,
			11786.561,
			11788.018,
			11789.475,
			11792.393,
			11795.315,
			11796.779,
			11799.709,
			11801.176,
			11804.114,
			11807.056,
			11811.504,
			11814.434,
			11818.877,
			11820.359,
			11821.845,
			11826.309,
			11829.291,
			11833.775,
			11836.771,
			11841.279,
			11844.291,
			11845.8,
			11848.82,
			11851.848,
			11853.364,
			11856.4,
			11859.443,
			11864.017,
			11865.544,
			11867.072,
			11871.667,
			11873.201,
			11874.737,
			11877.812,
			11879.353,
			11882.434,
			11887.066,
			11890.161,
			11893.261,
			11897.945,
			11899.5,
			11901.056,
			11905.732,
			11908.855,
			11913.553,
			11916.689,
			11919.837,
			11921.414,
			11924.57,
			11927.742,
			11929.332,
			11932.514,
			11934.111,
			11937.318,
			11940.528,
			11945.375,
			11948.61,
			11953.438,
			11955.059,
			11956.704,
			11961.557,
			11964.787,
			11969.633,
			11972.861,
			11976.091,
			11977.707,
			11980.906,
			11982.521,
			11985.751,
			11989.002,
			11990.616,
			11992.231,
			11997.045,
			12000.274,
			12005.152,
			12008.392,
			12011.639,
			12013.263,
			12016.489,
			12018.119,
			12021.382,
			12024.651,
			12029.562,
			12032.857,
			12037.704,
			12039.316,
			12040.922,
			12045.685,
			12048.812,
			12053.432,
			12056.465,
			12060.948,
			12063.889,
			12065.346,
			12068.229,
			12071.074,
			12072.482,
			12075.272,
			12078.023,
			12082.078,
			12083.41,
			12084.734,
			12087.352,
			12089.933,
			12091.209,
			12093.733,
			12094.98,
			12097.449,
			12099.883,
			12103.479,
			12105.84,
			12109.318,
			12110.458,
			12111.59,
			12114.927,
			12117.104,
			12120.3,
			12122.385,
			12125.442,
			12127.435,
			12128.413,
			12130.344,
			12132.238,
			12133.171,
			12135.002,
			12136.799,
			12139.454,
			12140.32,
			12141.181,
			12143.736,
			12144.58,
			12145.419,
			12147.919,
			12149.577,
			12152.056,
			12153.702,
			12155.347,
			12157.81,
			12158.629,
			12159.447,
			12162.729,
			12163.549,
			12166.006,
			12167.65,
			12169.291,
			12170.111,
			12171.756,
			12173.401,
			12174.224,
			12175.867,
			12176.688,
			12178.339,
			12179.987,
			12182.461,
			12184.122,
			12186.609,
			12187.439,
			12188.273,
			12190.783,
			12192.459,
			12194.998,
			12196.698,
			12199.271,
			12201.003,
			12201.871,
			12202.742,
			12205.38,
			12206.265,
			12207.152,
			12209.836,
			12211.645,
			12214.379,
			12216.23,
			12218.091,
			12219.025,
			12220.921,
			12221.873,
			12223.782,
			12225.714,
			12228.627,
			12230.587,
			12233.541,
			12234.526,
			12235.502,
			12238.424,
			12240.373,
			12243.291,
			12245.229,
			12247.164,
			12248.134,
			12250.064,
			12251.023,
			12252.938,
			12254.849,
			12255.802,
			12257.701,
			12259.598,
			12262.434,
			12263.377,
			12264.319,
			12266.204,
			12268.085,
			12269.024,
			12270.906,
			12271.848,
			12273.728,
			12275.608,
			12278.434,
			12280.321,
			12283.168,
			12284.12,
			12285.072,
			12287.943,
			12289.867,
			12292.773,
			12294.727,
			12296.691,
			12297.676,
			12299.65,
			12300.643,
			12302.633,
			12304.633,
			12305.636,
			12307.649,
			12309.675,
			12312.73,
			12314.781,
			12317.861,
			12318.897,
			12319.938,
			12323.054,
			12325.135,
			12327.225,
			12330.377,
			12332.49,
			12335.678,
			12336.745,
			12337.814,
			12341.037,
			12342.115,
			12343.195,
			12346.453,
			12348.635,
			12350.825,
			12351.924,
			12354.129,
			12355.235,
			12357.453,
			12359.682,
			12360.798,
			12363.04,
			12365.291,
			12368.682,
			12369.817,
			12370.955,
			12374.381,
			12375.526,
			12376.675,
			12380.132,
			12382.446,
			12385.934,
			12388.269,
			12390.611,
			12391.785,
			12394.14,
			12395.32,
			12396.502,
			12400.061,
			12401.249,
			12402.441,
			12406.031,
			12408.432,
			12410.842,
			12412.049,
			12414.47,
			12416.899,
			12418.117,
			12421.781,
			12424.235,
			12426.695,
			12430.403,
			12432.885,
			12436.621,
			12437.87,
			12439.121,
			12442.886,
			12445.403,
			12449.192,
			12451.727,
			12455.538,
			12458.088,
			12459.365,
			12461.924,
			12464.489,
			12465.773,
			12468.35,
			12470.931,
			12474.812,
			12476.11,
			12477.407,
			12479.998,
			12482.596,
			12483.896,
			12486.503,
			12487.809,
			12490.425,
			12493.047,
			12496.99,
			12499.627,
			12503.593,
			12504.918,
			12506.244,
			12510.232,
			12512.898,
			12516.908,
			12519.59,
			12523.623,
			12526.32,
			12527.671,
			12530.376,
			12533.089,
			12534.446,
			12537.168,
			12539.896,
			12543.996,
			12545.367,
			12546.739,
			12549.485,
			12552.236,
			12553.613,
			12557.754,
			12560.521,
			12563.292,
			12567.458,
			12570.242,
			12574.427,
			12575.823,
			12577.222,
			12581.423,
			12584.228,
			12588.445,
			12591.266,
			12594.089,
			12595.501,
			12598.334,
			12601.173,
			12602.593,
			12605.439,
			12606.865,
			12609.722,
			12612.582,
			12616.885,
			12619.761,
			12624.084,
			12625.529,
			12626.976,
			12631.321,
			12634.227,
			12638.594,
			12641.514,
			12644.436,
			12645.898,
			12648.83,
			12650.298,
			12653.238,
			12656.207,
			12657.682,
			12659.158,
			12663.598,
			12666.562,
			12669.533,
			12670.994,
			12673.974,
			12676.957,
			12678.475,
			12681.467,
			12682.965,
			12685.965,
			12690.476,
			12693.465,
			12696.485,
			12701.028,
			12702.546,
			12704.064,
			12708.653,
			12711.705,
			12716.294,
			12719.363,
			12722.437,
			12723.977,
			12727.064,
			12728.61,
			12731.681,
			12736.336,
			12739.472,
			12742.584,
			12745.701,
			12748.818,
			12750.379,
			12753.5,
			12756.619,
			12758.154,
			12761.277,
			12762.865,
			12765.992,
			12769.121,
			12773.818,
			12776.928,
			12781.662,
			12783.233,
			12784.807,
			12789.531,
			12792.685,
			12797.424,
			12800.587,
			12803.754,
			12805.34,
			12808.513,
			12810.102,
			12813.282,
			12816.466,
			12818.061,
			12821.254,
			12824.449,
			12829.227,
			12830.83,
			12832.432,
			12835.665,
			12838.871,
			12840.475,
			12845.261,
			12848.473,
			12851.711,
			12856.506,
			12859.75,
			12864.582,
			12866.194,
			12867.807,
			12872.619,
			12875.85,
			12880.7,
			12883.938,
			12887.178,
			12888.798,
			12892.042,
			12895.291,
			12896.915,
			12900.167,
			12901.796,
			12905.055,
			12908.345,
			12913.247,
			12914.881,
			12916.49,
			12921.396,
			12923.029,
			12924.66,
			12929.542,
			12932.786,
			12936.023,
			12940.903,
			12944.136,
			12948.988,
			12950.606,
			12953.818,
			12957.059,
			12958.68,
			12960.301,
			12965.168,
			12968.414,
			12971.664,
			12973.288,
			12976.54,
			12979.796,
			12981.424,
			12986.313,
			12989.578,
			12992.846,
			12997.754,
			13001.033,
			13005.957,
			13007.601,
			13009.244,
			13014.184,
			13017.484,
			13022.441,
			13025.75,
			13029.063,
			13030.721,
			13034.04,
			13035.701,
			13039.026,
			13042.355,
			13044.021,
			13047.359,
			13050.699,
			13054.044,
			13059.068,
			13062.422,
			13065.779,
			13067.459,
			13070.82,
			13072.502,
			13075.871,
			13079.241,
			13084.304,
			13087.682,
			13092.75,
			13094.439,
			13096.131,
			13101.206,
			13104.592,
			13109.705,
			13113.099,
			13118.197,
			13121.602,
			13123.306,
			13126.725,
			13130.147,
			13131.861,
			13135.304,
			13138.752,
			13143.916,
			13145.65,
			13147.415,
			13152.645,
			13154.391,
			13156.138,
			13159.633,
			13161.381,
			13164.88,
			13170.125,
			13173.616,
			13177.09,
			13182.265,
			13183.988,
			13185.715,
			13190.892,
			13194.367,
			13199.543,
			13202.968,
			13206.423,
			13208.154,
			13211.616,
			13215.08,
			13216.843,
			13220.312,
			13222.019,
			13225.49,
			13228.965,
			13234.182,
			13237.662,
			13242.889,
			13244.633,
			13246.377,
			13251.617,
			13255.113,
			13260.369,
			13263.877,
			13269.18,
			13272.7,
			13274.461,
			13276.194,
			13281.486,
			13285.045,
			13290.315,
			13293.85,
			13297.384,
			13299.15,
			13302.688,
			13306.227,
			13307.995,
			13311.537,
			13313.31,
			13316.854,
			13320.399,
			13325.75,
			13329.298,
			13334.591,
			13336.366,
			13338.143,
			13343.498,
			13347.017,
			13352.34,
			13355.891,
			13359.44,
			13361.217,
			13364.77,
			13366.545,
			13370.1,
			13373.652,
			13375.429,
			13377.205,
			13382.538,
			13387.874,
			13389.653,
			13391.433,
			13394.993,
			13398.553,
			13400.335,
			13405.68,
			13409.244,
			13412.809,
			13418.162,
			13421.734,
			13427.095,
			13428.883,
			13430.67,
			13436.035,
			13439.614,
			13444.983,
			13448.564,
			13453.938,
			13457.521,
			13461.106,
			13462.899,
			13466.484,
			13468.278,
			13471.867,
			13475.457,
			13480.846,
			13482.643,
			13484.439,
			13488.035,
			13491.633,
			13493.432,
			13497.029,
			13498.83,
			13502.432,
			13506.034,
			13511.44,
			13515.047,
			13520.457,
			13522.262,
			13524.064,
			13531.285,
			13533.092,
			13538.513,
			13542.129,
			13547.556,
			13551.178,
			13552.988,
			13556.611,
			13560.236,
			13562.051,
			13565.677,
			13569.308,
			13574.754,
			13578.385,
			13583.834,
			13585.649,
			13587.469,
			13592.923,
			13596.559,
			13600.197,
			13605.652,
			13609.291,
			13611.109,
			13614.745,
			13616.562,
			13618.381,
			13623.834,
			13625.65,
			13627.468,
			13632.919,
			13636.549,
			13640.181,
			13641.998,
			13645.624,
			13649.253,
			13651.068,
			13654.698,
			13656.512,
			13660.145,
			13663.775,
			13669.224,
			13672.855,
			13678.307,
			13680.125,
			13681.943,
			13687.399,
			13691.04,
			13696.504,
			13700.147,
			13703.795,
			13705.618,
			13709.266,
			13711.089,
			13714.736,
			13718.413,
			13720.234,
			13722.057,
			13727.51,
			13731.137,
			13734.754,
			13736.557,
			13740.126,
			13743.719,
			13745.51,
			13749.114,
			13750.898,
			13754.463,
			13758.026,
			13763.372,
			13766.935,
			13772.29,
			13774.074,
			13775.858,
			13781.191,
			13784.792,
			13790.158,
			13793.725,
			13797.253,
			13799.029,
			13802.582,
			13804.358,
			13807.941,
			13813.275,
			13816.834,
			13820.393,
			13825.701,
			13827.482,
			13829.261,
			13832.846,
			13836.368,
			13838.145,
			13843.495,
			13847.035,
			13850.581,
			13855.884,
			13859.42,
			13864.693,
			13866.462,
			13868.261,
			13875.307,
			13877.076,
			13882.418,
			13885.934,
			13889.481,
			13891.258,
			13894.814,
			13898.373,
			13900.154,
			13903.722,
			13905.506,
			13909.078,
			13912.66,
			13918.036,
			13921.63,
			13927.022,
			13928.822,
			13930.621,
			13936.022,
			13939.628,
			13945.039,
			13948.646,
			13952.249,
			13957.654,
			13959.453,
			13961.252,
			13966.645,
			13968.437,
			13970.229,
			13975.58,
			13979.115,
			13982.619,
			13984.356,
			13987.798,
			13991.196,
			13992.879,
			13996.213,
			13997.863,
			14001.137,
			14004.369,
			14009.142,
			14012.273,
			14016.895,
			14018.417,
			14019.928,
			14024.407,
			14027.346,
			14031.676,
			14034.516,
			14037.314,
			14038.698,
			14041.439,
			14042.795,
			14045.476,
			14048.121,
			14049.432,
			14052.025,
			14054.603,
			14057.121,
			14060.839,
			14063.289,
			14065.719,
			14066.93,
			14070.548,
			14072.944,
			14075.33,
			14078.896,
			14081.268,
			14084.816,
			14086.002,
			14087.189,
			14090.754,
			14093.141,
			14096.744,
			14099.132,
			14101.57,
			14102.811,
			14105.256,
			14106.481,
			14108.957,
			14111.436,
			14112.674,
			14115.154,
			14117.648,
			14121.4,
			14123.905,
			14126.416,
			14128.927,
			14130.18,
			14133.924,
			14136.437,
			14138.947,
			14142.746,
			14145.267,
			14149.06,
			14150.326,
			14151.593,
			14155.4,
			14157.946,
			14161.773,
			14164.332,
			14166.898,
			14168.184,
			14170.755,
			14172.043,
			14174.623,
			14177.207,
			14178.502,
			14181.093,
			14183.688,
			14187.587,
			14190.191,
			14194.09,
			14195.389,
			14196.689,
			14200.567,
			14203.173,
			14207.084,
			14209.716,
			14213.639,
			14216.236,
			14217.547,
			14218.86,
			14222.807,
			14224.126,
			14225.469,
			14229.439,
			14232.098,
			14234.762,
			14236.072,
			14238.754,
			14241.441,
			14242.787,
			14245.485,
			14246.84,
			14249.552,
			14252.27,
			14256.354,
			14259.084,
			14263.184,
			14264.551,
			14265.919,
			14270.031,
			14272.774,
			14276.899,
			14279.654,
			14282.412,
			14283.795,
			14286.562,
			14287.944,
			14290.715,
			14294.879,
			14296.269,
			14300.443,
			14303.232,
			14306.027,
			14307.427,
			14310.229,
			14313.037,
			14314.444,
			14317.263,
			14318.674,
			14321.499,
			14324.334,
			14328.596,
			14331.442,
			14335.723,
			14337.154,
			14338.586,
			14342.883,
			14345.756,
			14350.074,
			14352.961,
			14355.85,
			14357.297,
			14360.199,
			14361.65,
			14364.557,
			14367.47,
			14368.93,
			14371.849,
			14374.772,
			14379.17,
			14380.636,
			14382.104,
			14386.514,
			14387.986,
			14389.461,
			14393.887,
			14396.844,
			14399.805,
			14404.252,
			14407.225,
			14411.688,
			14413.178,
			14414.669,
			14419.147,
			14422.137,
			14426.629,
			14429.629,
			14432.629,
			14434.133,
			14437.143,
			14440.155,
			14441.662,
			14444.682,
			14446.193,
			14449.223,
			14452.254,
			14456.803,
			14459.844,
			14464.414,
			14465.963,
			14467.492,
			14472.057,
			14475.117,
			14478.175,
			14482.741,
			14485.75,
			14490.23,
			14491.696,
			14493.148,
			14497.421,
			14500.239,
			14504.398,
			14507.146,
			14509.881,
			14511.268,
			14513.97,
			14516.689,
			14518.049,
			14522.142,
			14524.858,
			14527.58,
			14531.661,
			14534.395,
			14538.494,
			14539.862,
			14541.234,
			14545.327,
			14548.076,
			14552.242,
			14555.011,
			14557.783,
			14559.172,
			14561.95,
			14563.342,
			14566.119,
			14570.267,
			14571.682,
			14575.853,
			14578.636,
			14581.42,
			14582.81,
			14585.568,
			14588.378,
			14589.772,
			14592.562,
			14593.957,
			14596.752,
			14599.554,
			14603.768,
			14606.561,
			14610.802,
			14612.242,
			14613.659,
			14617.93,
			14620.785,
			14625.078,
			14627.927,
			14630.803,
			14632.242,
			14635.158,
			14636.605,
			14639.503,
			14642.378,
			14643.829,
			14646.734,
			14649.645,
			14654.013,
			14655.473,
			14656.932,
			14659.854,
			14662.777,
			14664.241,
			14668.639,
			14671.576,
			14674.517,
			14678.937,
			14681.889,
			14686.325,
			14687.805,
			14689.285,
			14693.732,
			14696.702,
			14701.156,
			14704.115,
			14707.055,
			14708.515,
			14711.408,
			14712.842,
			14715.68,
			14719.861,
			14722.599,
			14725.301,
			14729.307,
			14731.965,
			14735.949,
			14737.277,
			14738.606,
			14742.607,
			14745.232,
			14749.182,
			14751.809,
			14754.389,
			14758.196,
			14759.449,
			14760.67,
			14764.344,
			14765.551,
			14766.75,
			14770.292,
			14772.608,
			14774.89,
			14776.016,
			14778.238,
			14780.421,
			14781.498,
			14783.623,
			14784.672,
			14786.739,
			14788.768,
			14791.736,
			14793.67,
			14796.501,
			14797.426,
			14798.344,
			14801.051,
			14802.814,
			14805.402,
			14807.073,
			14808.694,
			14809.5,
			14811.092,
			14811.879,
			14812.662,
			14815.008,
			14815.78,
			14817.318,
			14818.851,
			14820.379,
			14821.906,
			14822.67,
			14824.195,
			14825.717,
			14826.479,
			14828.002,
			14828.767,
			14830.293,
			14831.815,
			14834.103,
			14835.644,
			14837.943,
			14838.71,
			14839.476,
			14841.809,
			14843.359,
			14845.688,
			14847.259,
			14848.822,
			14849.603,
			14851.168,
			14851.959,
			14853.535,
			14855.105,
			14855.892,
			14857.469,
			14859.047,
			14861.411,
			14862.995,
			14865.371,
			14866.162,
			14866.953,
			14869.342,
			14870.935,
			14872.528,
			14874.934,
			14876.538,
			14878.95,
			14879.754,
			14880.558,
			14882.963,
			14884.568,
			14886.965,
			14888.557,
			14890.149,
			14890.942,
			14892.522,
			14893.312,
			14894.889,
			14897.253,
			14898.824,
			14900.396,
			14902.753,
			14903.539,
			14904.322,
			14906.676,
			14907.461,
			14908.246,
			14910.597,
			14912.164,
			14914.515,
			14916.09,
			14917.664,
			14920.031,
			14920.823,
			14921.619,
			14923.997,
			14924.793,
			14925.589,
			14927.994,
			14929.604,
			14931.219,
			14932.029,
			14933.655,
			14935.293,
			14936.115,
			14938.59,
			14940.251,
			14941.927,
			14944.455,
			14946.152,
			14948.725,
			14949.588,
			14950.453,
			14953.066,
			14954.824,
			14957.48,
			14959.264,
			14961.06,
			14961.964,
			14963.779,
			14964.691,
			14966.522,
			14968.365,
			14969.294,
			14970.229,
			14973.042,
			14974.932,
			14976.839,
			14977.799,
			14979.728,
			14981.666,
			14982.641,
			14985.578,
			14987.535,
			14989.517,
			14992.509,
			14994.516,
			14997.544,
			14998.559,
			14999.58,
			15002.674,
			15004.719,
			15007.838,
			15009.937,
			15013.078,
			15015.183,
			15016.238,
			15018.356,
			15020.48,
			15021.545,
			15023.686,
			15025.833,
			15029.067,
			15031.228,
			15034.48,
			15035.57,
			15036.662,
			15039.938,
			15042.132,
			15044.337,
			15047.678,
			15049.9,
			15053.234,
			15054.359,
			15055.487,
			15058.883,
			15061.156,
			15064.588,
			15066.889,
			15070.354,
			15072.674,
			15073.837,
			15076.17,
			15078.509,
			15079.68,
			15082.027,
			15084.383,
			15087.928,
			15090.298,
			15093.868,
			15095.062,
			15096.258,
			15099.854,
			15102.262,
			15105.888,
			15108.314,
			15110.749,
			15114.416,
			15115.643,
			15116.871,
			15120.567,
			15121.805,
			15123.044,
			15126.771,
			15129.268,
			15131.774,
			15133.029,
			15135.545,
			15138.069,
			15139.334,
			15141.868,
			15143.139,
			15145.686,
			15148.239,
			15152.082,
			15154.654,
			15158.521,
			15159.814,
			15161.108,
			15164.998,
			15167.601,
			15171.513,
			15174.123,
			15176.725,
			15178.02,
			15180.59,
			15181.864,
			15184.383,
			15188.089,
			15189.305,
			15192.895,
			15195.238,
			15197.548,
			15198.688,
			15200.938,
			15203.148,
			15204.24,
			15206.396,
			15207.459,
			15209.559,
			15211.619,
			15214.656,
			15216.639,
			15219.549,
			15220.503,
			15221.449,
			15224.234,
			15226.047,
			15228.705,
			15230.434,
			15232.125,
			15232.959,
			15234.607,
			15235.423,
			15237.047,
			15238.666,
			15239.475,
			15241.104,
			15242.74,
			15245.199,
			15246.027,
			15246.824,
			15248.449,
			15250.059,
			15250.866,
			15253.285,
			15254.898,
			15256.543,
			15259.006,
			15260.649,
			15263.146,
			15263.973,
			15264.794,
			15267.271,
			15268.911,
			15271.35,
			15272.998,
			15274.628,
			15275.438,
			15277.115,
			15277.981,
			15279.699,
			15282.236,
			15283.873,
			15285.492,
			15287.908,
			15289.514,
			15291.883,
			15292.664,
			15293.441,
			15295.76,
			15297.308,
			15299.586,
			15301.09,
			15302.584,
			15304.865,
			15305.619,
			15306.369,
			15308.604,
			15309.348,
			15310.092,
			15312.396,
			15313.922,
			15315.433,
			15316.186,
			15317.699,
			15318.461,
			15319.969,
			15322.215,
			15323.706,
			15325.209,
			15327.444,
			15328.92,
			15331.127,
			15331.865,
			15332.611,
			15334.829,
			15336.297,
			15338.504,
			15340.015,
			15341.543,
			15342.305,
			15343.829,
			15344.594,
			15346.121,
			15347.645,
			15348.408,
			15349.174,
			15351.574,
			15353.171,
			15355.562,
			15357.148,
			15358.732,
			15359.525,
			15361.121,
			15361.922,
			15363.525,
			15365.129,
			15367.551,
			15369.18,
			15371.607,
			15372.421,
			15373.236,
			15375.648,
			15377.239,
			15379.648,
			15381.232,
			15382.82,
			15383.617,
			15385.216,
			15386.011,
			15387.604,
			15389.199,
			15389.999,
			15391.611,
			15393.235,
			15394.852,
			15396.485,
			15397.313,
			15398.982,
			15400.672,
			15401.514,
			15403.212,
			15404.083,
			15405.837,
			15407.602,
			15410.289,
			15412.102,
			15414.883,
			15415.813,
			15416.754,
			15420.555,
			15421.513,
			15424.414,
			15426.352,
			15428.336,
			15429.327,
			15431.298,
			15432.284,
			15434.268,
			15436.264,
			15437.264,
			15439.227,
			15441.219,
			15444.248,
			15446.251,
			15449.271,
			15450.284,
			15451.3,
			15454.349,
			15456.393,
			15458.447,
			15461.548,
			15463.61,
			15466.762,
			15467.808,
			15468.855,
			15472.012,
			15473.068,
			15474.128,
			15477.328,
			15479.478,
			15481.637,
			15482.72,
			15484.895,
			15485.986,
			15488.176,
			15490.375,
			15491.479,
			15493.691,
			15495.912,
			15499.264,
			15501.51,
			15504.894,
			15506.026,
			15507.16,
			15510.582,
			15512.875,
			15516.333,
			15518.63,
			15520.979,
			15522.146,
			15524.486,
			15525.66,
			15526.837,
			15530.38,
			15531.564,
			15532.732,
			15536.306,
			15538.699,
			15541.1,
			15542.323,
			15544.717,
			15547.137,
			15548.35,
			15550.783,
			15552.002,
			15554.447,
			15556.902,
			15560.6,
			15563.075,
			15566.81,
			15568.059,
			15569.309,
			15573.074,
			15575.596,
			15579.395,
			15581.936,
			15584.485,
			15585.763,
			15588.323,
			15589.606,
			15592.177,
			15596.045,
			15598.633,
			15601.229,
			15605.131,
			15606.43,
			15607.732,
			15611.644,
			15612.95,
			15614.258,
			15618.195,
			15620.83,
			15623.47,
			15627.441,
			15630.098,
			15634.096,
			15635.431,
			15636.768,
			15640.791,
			15643.482,
			15647.533,
			15650.241,
			15652.956,
			15654.315,
			15657.039,
			15658.403,
			15661.135,
			15665.242,
			15667.986,
			15670.737,
			15674.873,
			15677.639,
			15681.822,
			15683.214,
			15684.607,
			15688.771,
			15691.573,
			15694.383,
			15698.612,
			15701.442,
			15705.695,
			15707.117,
			15708.538,
			15714.266,
			15715.695,
			15719.991,
			15722.837,
			15725.71,
			15727.148,
			15730.025,
			15732.93,
			15734.373,
			15737.233,
			15738.678,
			15741.565,
			15744.458,
			15748.821,
			15751.71,
			15756.006,
			15757.439,
			15758.866,
			15763.111,
			15765.904,
			15770.044,
			15772.736,
			15775.395,
			15776.715,
			15779.335,
			15780.639,
			15781.936,
			15785.823,
			15787.111,
			15788.398,
			15792.255,
			15794.828,
			15797.395,
			15798.656,
			15801.229,
			15803.834,
			15805.127,
			15807.71,
			15809.003,
			15811.599,
			15814.192,
			15818.078,
			15820.654,
			15824.515,
			15825.802,
			15827.079,
			15830.902,
			15832.178,
			15833.455,
			15837.274,
			15839.822,
			15842.378,
			15843.657,
			15846.211,
			15847.49,
			15850.054,
			15853.91,
			15856.484,
			15859.044,
			15862.929,
			15865.525,
			15868.15,
			15870.761,
			15872.068,
			15874.669,
			15875.98,
			15878.61,
			15881.246,
			15885.212,
			15887.883,
			15891.869,
			15893.178,
			15894.531,
			15898.511,
			15901.184,
			15905.222,
			15907.903,
			15910.566,
			15911.912,
			15914.604,
			15915.951,
			15918.649,
			15922.709,
			15925.417,
			15928.129,
			15932.238,
			15934.965,
			15939.033,
			15940.396,
			15941.76,
			15945.862,
			15948.604,
			15951.336,
			15955.426,
			15958.156,
			15962.138,
			15963.454,
			15964.764,
			15968.627,
			15969.894,
			15971.149,
			15974.857,
			15977.285,
			15979.674,
			15980.854,
			15983.186,
			15985.479,
			15986.609,
			15988.843,
			15989.968,
			15992.152,
			15994.297,
			15997.447,
			15999.502,
			16002.518,
			16003.519,
			16004.535,
			16007.528,
			16009.521,
			16012.504,
			16014.494,
			16016.483,
			16017.479,
			16019.466,
			16020.459,
			16021.453,
			16025.438,
			16026.436,
			16029.431,
			16031.43,
			16034.453,
			16036.479,
			16038.505,
			16039.52,
			16041.56,
			16042.595,
			16044.666,
			16046.738,
			16049.863,
			16051.979,
			16055.154,
			16056.217,
			16057.277,
			16060.48,
			16062.624,
			16065.85,
			16068.01,
			16070.17,
			16071.25,
			16073.412,
			16074.493,
			16076.635,
			16078.795,
			16079.879,
			16082.047,
			16084.213,
			16087.481,
			16088.568,
			16089.656,
			16091.835,
			16094.025,
			16095.121,
			16098.423,
			16100.637,
			16102.863,
			16106.215,
			16108.462,
			16111.854,
			16112.988,
			16114.125,
			16117.545,
			16119.834,
			16123.264,
			16125.543,
			16127.815,
			16128.947,
			16131.197,
			16132.315,
			16134.533,
			16136.731,
			16137.82,
			16139.98,
			16142.119,
			16145.297,
			16146.348,
			16147.395,
			16150.518,
			16151.554,
			16152.586,
			16155.613,
			16157.619,
			16159.603,
			16162.6,
			16164.57,
			16167.51,
			16168.512,
			16169.5,
			16172.45,
			16173.433,
			16174.414,
			16177.365,
			16179.337,
			16182.28,
			16184.26,
			16186.248,
			16187.244,
			16189.247,
			16190.253,
			16192.273,
			16194.318,
			16197.38,
			16199.436,
			16202.537,
			16203.575,
			16204.613,
			16207.729,
			16209.779,
			16212.869,
			16214.893,
			16216.888,
			16217.859,
			16219.818,
			16220.789,
			16222.708,
			16224.595,
			16225.525,
			16226.45,
			16229.191,
			16230.992,
			16232.78,
			16233.67,
			16235.447,
			16237.22,
			16238.104,
			16240.747,
			16242.516,
			16244.265,
			16246.874,
			16248.601,
			16251.175,
			16252.029,
			16252.882,
			16255.406,
			16257.072,
			16259.559,
			16261.211,
			16262.854,
			16263.67,
			16265.301,
			16266.114,
			16267.74,
			16270.193,
			16271.849,
			16273.502,
			16275.154,
			16276.808,
			16277.648,
			16279.346,
			16281.043,
			16281.893,
			16283.594,
			16284.447,
			16286.182,
			16287.924,
			16290.545,
			16292.302,
			16294.967,
			16295.857,
			16296.751,
			16299.44,
			16301.249,
			16303.98,
			16305.811,
			16307.65,
			16308.575,
			16310.441,
			16311.377,
			16313.256,
			16315.147,
			16316.098,
			16318.012,
			16319.935,
			16322.839,
			16324.792,
			16327.729,
			16328.715,
			16329.701,
			16332.677,
			16334.663,
			16337.652,
			16339.65,
			16341.656,
			16344.656,
			16345.66,
			16346.664,
			16349.683,
			16350.687,
			16351.691,
			16354.707,
			16356.721,
			16358.739,
			16359.752,
			16361.772,
			16363.799,
			16364.815,
			16366.853,
			16367.878,
			16369.92,
			16371.986,
			16375.104,
			16376.146,
			16377.194,
			16380.363,
			16381.401,
			16382.459,
			16385.65,
			16387.805,
			16391.055,
			16393.236,
			16395.43,
			16396.535,
			16398.752,
			16399.865,
			16400.98,
			16404.342,
			16405.465,
			16406.59,
			16409.979,
			16412.242,
			16414.514,
			16415.656,
			16417.938,
			16420.195,
			16421.33,
			16423.559,
			16424.672,
			16426.871,
			16429.088,
			16432.426,
			16434.654,
			16438.008,
			16439.133,
			16440.258,
			16443.672,
			16445.949,
			16449.385,
			16451.689,
			16454.006,
			16455.168,
			16457.484,
			16458.654,
			16461.006,
			16463.367,
			16464.553,
			16466.953,
			16469.322,
			16471.723,
			16474.135,
			16475.344,
			16477.773,
			16480.209,
			16481.432,
			16483.883,
			16485.113,
			16487.578,
			16490.055,
			16493.785,
			16496.283,
			16500.047,
			16501.307,
			16502.568,
			16506.367,
			16508.912,
			16512.746,
			16515.312,
			16517.891,
			16519.182,
			16521.77,
			16523.064,
			16525.654,
			16528.238,
			16529.525,
			16532.084,
			16534.617,
			16538.357,
			16539.588,
			16540.809,
			16544.416,
			16545.602,
			16546.797,
			16550.273,
			16552.551,
			16554.807,
			16558.148,
			16560.328,
			16563.572,
			16564.639,
			16565.703,
			16568.879,
			16571.004,
			16574.16,
			16576.26,
			16578.359,
			16579.408,
			16581.508,
			16582.561,
			16584.668,
			16586.775,
			16587.832,
			16589.949,
			16592.076,
			16595.289,
			16597.461,
			16600.75,
			16601.852,
			16602.957,
			16606.289,
			16608.531,
			16611.914,
			16614.186,
			16616.469,
			16617.617,
			16619.922,
			16621.078,
			16622.24,
			16625.738,
			16626.908,
			16628.086,
			16631.627,
			16633.992,
			16636.367,
			16637.559,
			16639.945,
			16642.336,
			16643.531,
			16645.932,
			16647.137,
			16649.545,
			16651.961,
			16655.574,
			16658.027,
			16661.682,
			16662.902,
			16664.096,
			16667.732,
			16670.129,
			16673.686,
			16675.994,
			16678.268,
			16679.373,
			16681.598,
			16682.697,
			16684.871,
			16687.031,
			16688.09,
			16689.141,
			16692.26,
			16694.299,
			16696.35,
			16697.373,
			16699.418,
			16701.467,
			16702.494,
			16704.549,
			16705.594,
			16707.668,
			16709.74,
			16712.871,
			16714.961,
			16718.15,
			16719.221,
			16720.289,
			16723.439,
			16725.527,
			16728.654,
			16730.736,
			16733.848,
			16735.922,
			16736.955,
			16739.027,
			16741.107,
			16742.184,
			16744.336,
			16746.471,
			16749.59,
			16750.633,
			16751.678,
			16754.822,
			16755.875,
			16756.955,
			16760.213,
			16762.379,
			16764.547,
			16767.812,
			16769.996,
			16773.322,
			16774.443,
			16775.566,
			16778.891,
			16781.086,
			16784.43,
			16786.674,
			16788.908,
			16790.031,
			16792.281,
			16794.557,
			16795.688,
			16797.922,
			16799.035,
			16801.264,
			16803.473,
			16806.807,
			16807.918,
			16809.027,
			16812.332,
			16813.445,
			16814.535,
			16817.859,
			16820.082,
			16823.41,
			16825.656,
			16827.906,
			16831.262,
			16832.371,
			16833.484,
			16836.811,
			16837.908,
			16839,
			16842.258,
			16844.41,
			16846.549,
			16847.617,
			16849.752,
			16851.877,
			16852.936,
			16855.055,
			16856.111,
			16858.232,
			16860.346,
			16863.494,
			16865.604,
			16868.785,
			16869.842,
			16870.902,
			16874.096,
			16876.238,
			16879.465,
			16881.625,
			16883.793,
			16884.879,
			16887.057,
			16888.145,
			16890.32,
			16892.496,
			16893.586,
			16895.77,
			16897.951,
			16901.221,
			16902.311,
			16903.41,
			16905.609,
			16907.812,
			16908.92,
			16911.137,
			16912.25,
			16914.484,
			16916.711,
			16920.1,
			16922.375,
			16925.809,
			16926.959,
			16928.113,
			16931.596,
			16933.932,
			16937.457,
			16939.822,
			16942.197,
			16943.391,
			16945.781,
			16946.98,
			16949.389,
			16953.016,
			16955.447,
			16957.887,
			16961.564,
			16962.795,
			16964.027,
			16966.494,
			16968.961,
			16970.211,
			16973.877,
			16976.291,
			16978.68,
			16982.182,
			16984.479,
			16987.861,
			16988.963,
			16990.062,
			16993.297,
			16995.396,
			16998.469,
			17000.465,
			17002.424,
			17003.393,
			17005.309,
			17007.201,
			17008.141,
			17010.002,
			17010.926,
			17012.762,
			17014.586,
			17017.311,
			17018.225,
			17019.133,
			17021.855,
			17022.762,
			17023.668,
			17026.393,
			17028.232,
			17030.076,
			17032.848,
			17034.701,
			17035.637,
			17037.518,
			17038.457,
			17040.344,
			17042.236,
			17044.141,
			17047.023,
			17048.955,
			17050.896,
			17051.871,
			17053.836,
			17055.812,
			17056.805,
			17058.797,
			17059.793,
			17061.797,
			17063.822,
			17066.875,
			17068.922,
			17072.006,
			17073.039,
			17074.074,
			17077.184,
			17079.264,
			17082.387,
			17084.465,
			17086.549,
			17087.59,
			17089.674,
			17090.707,
			17092.773,
			17094.838,
			17095.867,
			17097.93,
			17099.984,
			17102.027,
			17104.064,
			17105.082,
			17107.111,
			17109.135,
			17110.145,
			17112.16,
			17113.166,
			17115.172,
			17117.176,
			17120.164,
			17122.146,
			17125.119,
			17126.113,
			17127.09,
			17130.086,
			17132.1,
			17135.164,
			17137.219,
			17139.283,
			17140.33,
			17142.41,
			17143.463,
			17145.582,
			17147.719,
			17148.793,
			17150.947,
			17153.115,
			17156.402,
			17157.479,
			17158.574,
			17160.777,
			17162.988,
			17164.098,
			17166.328,
			17167.445,
			17169.689,
			17173.074,
			17175.348,
			17177.629,
			17181.072,
			17182.227,
			17183.383,
			17186.869,
			17188.031,
			17189.193,
			17192.668,
			17194.957,
			17197.219,
			17198.336,
			17200.545,
			17202.721,
			17203.797,
			17205.922,
			17206.971,
			17209.047,
			17211.092,
			17214.115,
			17216.084,
			17218.994,
			17219.955,
			17220.912,
			17223.766,
			17225.666,
			17228.521,
			17230.428,
			17232.338,
			17235.207,
			17236.166,
			17237.123,
			17240.008,
			17240.969,
			17241.932,
			17244.82,
			17246.748,
			17248.693,
			17249.672,
			17251.627,
			17253.578,
			17254.555,
			17257.496,
			17259.486,
			17261.473,
			17264.438,
			17266.41,
			17269.395,
			17270.383,
			17271.371,
			17274.314,
			17276.279,
			17279.229,
			17281.188,
			17284.119,
			17286.078,
			17287.055,
			17289.006,
			17291.92,
			17293.855,
			17295.785,
			17297.705,
			17299.621,
			17300.576,
			17302.484,
			17304.379,
			17305.318,
			17308.125,
			17309.996,
			17311.863,
			17314.645,
			17316.482,
			17319.23,
			17320.145,
			17321.059,
			17323.797,
			17325.617,
			17328.35,
			17330.174,
			17332,
			17332.914,
			17334.75,
			17336.586,
			17337.508,
			17340.271,
			17342.117,
			17343.967,
			17346.748,
			17348.609,
			17350.48,
			17352.355,
			17353.295,
			17356.135,
			17358.037,
			17359.953,
			17362.846,
			17364.791,
			17367.73,
			17368.717,
			17369.709,
			17372.703,
			17374.719,
			17377.77,
			17379.822,
			17382.93,
			17385.018,
			17387.117,
			17388.17,
			17391.338,
			17393.457,
			17395.588,
			17398.773,
			17399.84,
			17400.904,
			17404.09,
			17405.135,
			17406.176,
			17409.234,
			17411.242,
			17414.189,
			17416.109,
			17417.992,
			17420.758,
			17421.662,
			17422.559,
			17425.201,
			17426.062,
			17426.918,
			17429.445,
			17431.094,
			17432.727,
			17433.535,
			17435.154,
			17436.762,
			17437.564,
			17439.174,
			17439.98,
			17441.619,
			17443.271,
			17445.764,
			17447.436,
			17449.965,
			17450.809,
			17451.656,
			17454.211,
			17455.932,
			17458.543,
			17460.295,
			17462.084,
			17462.98,
			17464.777,
			17465.68,
			17467.492,
			17469.312,
			17470.227,
			17471.15,
			17473.934,
			17476.744,
			17478.631,
			17480.531,
			17482.473,
			17483.447,
			17485.402,
			17486.379,
			17488.336,
			17491.287,
			17493.27,
			17495.266,
			17498.32,
			17499.344,
			17500.369,
			17503.465,
			17505.541,
			17508.664,
			17510.762,
			17512.873,
			17513.936,
			17516.039,
			17517.09,
			17519.199,
			17521.312,
			17522.371,
			17524.486,
			17526.592,
			17529.768,
			17530.826,
			17531.891,
			17535.096,
			17536.172,
			17537.248,
			17540.502,
			17542.691,
			17544.895,
			17548.24,
			17550.467,
			17553.854,
			17554.988,
			17556.123,
			17559.543,
			17561.836,
			17565.289,
			17567.604,
			17569.928,
			17571.094,
			17573.434,
			17575.785,
			17576.965,
			17580.52,
			17582.9,
			17585.293,
			17588.898,
			17590.105,
			17591.312,
			17594.955,
			17596.172,
			17597.395,
			17601.07,
			17603.531,
			17606,
			17609.717,
			17612.191,
			17615.881,
			17617.102,
			17618.314,
			17623.086,
			17624.254,
			17627.699,
			17629.945,
			17632.152,
			17633.242,
			17635.387,
			17637.508,
			17638.545,
			17641.621,
			17643.623,
			17645.594,
			17648.504,
			17650.406,
			17653.221,
			17654.15,
			17655.078,
			17657.85,
			17659.689,
			17662.436,
			17664.264,
			17666.09,
			17667.008,
			17668.871,
			17669.803,
			17671.662,
			17673.518,
			17674.443,
			17675.371,
			17678.209,
			17680.102,
			17681.984,
			17682.926,
			17684.801,
			17686.717,
			17687.67,
			17689.57,
			17690.516,
			17692.402,
			17694.289,
			17697.129,
			17699.01,
			17701.818,
			17702.752,
			17703.691,
			17706.48,
			17708.324,
			17711.076,
			17712.902,
			17714.717,
			17715.621,
			17717.422,
			17718.318,
			17720.109,
			17722.779,
			17724.547,
			17726.312,
			17728.959,
			17730.719,
			17732.465,
			17734.207,
			17735.08,
			17736.824,
			17737.695,
			17739.439,
			17741.168,
			17743.764,
			17745.498,
			17748.107,
			17748.977,
			17749.844,
			17752.449,
			17754.195,
			17756.826,
			17758.594,
			17760.371,
			17761.262,
			17763.047,
			17764.844,
			17765.746,
			17768.479,
			17770.32,
			17772.176,
			17774.98,
			17775.922,
			17776.85,
			17779.709,
			17780.662,
			17781.619,
			17784.518,
			17786.465,
			17788.432,
			17791.41,
			17793.41,
			17794.414,
			17796.43,
			17797.443,
			17798.455,
			17801.496,
			17802.52,
			17803.545,
			17806.637,
			17808.713,
			17810.797,
			17811.84,
			17813.922,
			17816.012,
			17817.057,
			17819.158,
			17820.213,
			17822.33,
			17824.457,
			17827.672,
			17829.826,
			17833.078,
			17834.168,
			17835.26,
			17838.555,
			17840.76,
			17844.09,
			17846.32,
			17849.688,
			17851.945,
			17853.078,
			17855.35,
			17857.633,
			17858.777,
			17861.074,
			17863.383,
			17865.701,
			17868.027,
			17869.195,
			17871.539,
			17873.891,
			17875.07,
			17877.438,
			17878.623,
			17880.994,
			17883.365,
			17886.9,
			17889.232,
			17892.676,
			17893.807,
			17894.928,
			17898.23,
			17900.402,
			17903.555,
			17905.604,
			17908.605,
			17910.541,
			17911.504,
			17913.422,
			17916.225,
			17918.062,
			17919.883,
			17922.576,
			17923.467,
			17924.354,
			17926.119,
			17927.887,
			17928.768,
			17930.525,
			17931.402,
			17933.154,
			17934.902,
			17937.533,
			17939.285,
			17941.906,
			17942.779,
			17943.652,
			17946.293,
			17948.08,
			17950.746,
			17952.52,
			17954.293,
			17955.193,
			17957.018,
			17957.926,
			17959.736,
			17961.541,
			17962.441,
			17964.256,
			17966.123,
			17968.91,
			17970.76,
			17973.561,
			17974.518,
			17975.473,
			17978.33,
			17980.23,
			17983.139,
			17985.102,
			17987.062,
			17990.012,
			17991.979,
			17994.93,
			17995.914,
			17996.9,
			17999.822,
			18001.748,
			18004.641,
			18006.572,
			18008.418,
			18009.34,
			18011.186,
			18012.111,
			18013.973,
			18015.814,
			18018.508,
			18020.301,
			18023.033,
			18023.949,
			18024.871,
			18027.668,
			18029.539,
			18032.348,
			18034.268,
			18036.23,
			18037.215,
			18039.195,
			18040.188,
			18042.201,
			18044.232,
			18045.26,
			18047.322,
			18049.391,
			18051.463,
			18053.531,
			18054.6,
			18056.709,
			18058.811,
			18059.875,
			18062.014,
			18063.08,
			18065.225,
			18067.379,
			18070.65,
			18072.779,
			18075.949,
			18077.008,
			18078.07,
			18081.275,
			18083.414,
			18086.607,
			18088.734,
			18090.871,
			18091.943,
			18094.094,
			18095.191,
			18097.359,
			18099.539,
			18100.615,
			18102.812,
			18105.016,
			18108.348,
			18110.578,
			18113.947,
			18115.076,
			18116.207,
			18119.617,
			18121.904,
			18124.199,
			18127.664,
			18129.988,
			18133.49,
			18134.664,
			18135.84,
			18139.381,
			18141.754,
			18145.33,
			18147.727,
			18150.133,
			18151.342,
			18153.764,
			18154.98,
			18157.418,
			18159.865,
			18161.094,
			18163.553,
			18166.02,
			18169.723,
			18172.191,
			18175.885,
			18177.113,
			18178.361,
			18182.035,
			18184.482,
			18188.143,
			18190.586,
			18193.033,
			18196.715,
			18197.949,
			18199.182,
			18202.9,
			18204.146,
			18205.396,
			18209.16,
			18211.68,
			18214.211,
			18215.48,
			18218.025,
			18220.582,
			18221.863,
			18225.723,
			18228.309,
			18230.902,
			18234.805,
			18237.416,
			18241.357,
			18242.676,
			18243.996,
			18247.973,
			18250.631,
			18254.637,
			18257.314,
			18261.346,
			18264.047,
			18265.398,
			18266.756,
			18270.822,
			18272.191,
			18273.562,
			18277.727,
			18280.504,
			18284.701,
			18287.516,
			18290.346,
			18291.766,
			18294.613,
			18296.043,
			18298.912,
			18301.789,
			18306.117,
			18309.012,
			18313.361,
			18314.812,
			18316.264,
			18320.629,
			18323.543,
			18327.906,
			18330.859,
			18333.801,
			18335.27,
			18338.215,
			18339.666,
			18342.617,
			18345.572,
			18347.053,
			18350.006,
			18352.969,
			18357.418,
			18358.904,
			18360.393,
			18363.377,
			18366.371,
			18367.869,
			18372.379,
			18375.396,
			18378.42,
			18382.967,
			18386.008,
			18390.611,
			18392.141,
			18393.672,
			18398.277,
			18401.328,
			18405.951,
			18409.031,
			18413.635,
			18416.682,
			18418.195,
			18421.197,
			18424.164,
			18425.633,
			18428.547,
			18431.426,
			18435.723,
			18438.541,
			18442.684,
			18444.053,
			18445.416,
			18449.455,
			18452.105,
			18456.012,
			18458.57,
			18461.09,
			18464.816,
			18466.051,
			18467.281,
			18470.998,
			18472.232,
			18473.465,
			18477.141,
			18479.609,
			18482.082,
			18483.318,
			18485.795,
			18487.035,
			18489.521,
			18493.262,
			18495.77,
			18498.281,
			18502.059,
			18504.598,
			18508.369,
			18509.623,
			18510.877,
			18514.619,
			18517.094,
			18520.76,
			18523.166,
			18525.543,
			18526.717,
			18529.043,
			18530.195,
			18532.459,
			18534.721,
			18535.848,
			18538.125,
			18540.387,
			18542.648,
			18546.053,
			18548.348,
			18550.645,
			18551.791,
			18554.082,
			18555.227,
			18557.539,
			18559.842,
			18563.266,
			18565.525,
			18568.84,
			18569.938,
			18571.045,
			18574.289,
			18576.449,
			18579.689,
			18581.816,
			18583.928,
			18584.979,
			18587.062,
			18588.098,
			18590.166,
			18592.219,
			18593.242,
			18595.281,
			18597.312,
			18600.338,
			18601.342,
			18602.348,
			18604.348,
			18606.342,
			18607.338,
			18609.324,
			18610.312,
			18612.293,
			18614.27,
			18617.225,
			18619.191,
			18622.133,
			18623.113,
			18624.094,
			18627.033,
			18628.992,
			18631.934,
			18633.895,
			18635.863,
			18636.848,
			18638.816,
			18639.807,
			18641.793,
			18643.785,
			18644.785,
			18646.797,
			18648.82,
			18651.873,
			18652.898,
			18653.926,
			18657.02,
			18658.059,
			18659.098,
			18661.186,
			18662.213,
			18664.318,
			18666.447,
			18669.637,
			18671.779,
			18675.01,
			18676.094,
			18677.178,
			18680.426,
			18681.521,
			18682.619,
			18685.926,
			18688.156,
			18690.375,
			18691.488,
			18693.699,
			18694.816,
			18697.055,
			18699.299,
			18700.422,
			18702.672,
			18704.928,
			18708.32,
			18710.592,
			18714.004,
			18715.146,
			18716.291,
			18719.729,
			18722.033,
			18725.5,
			18727.818,
			18731.293,
			18733.602,
			18734.754,
			18735.906,
			18739.354,
			18740.5,
			18741.645,
			18745.096,
			18747.357,
			18749.633,
			18750.771,
			18753.043,
			18755.32,
			18756.461,
			18758.75,
			18759.896,
			18762.225,
			18764.549,
			18768.059,
			18770.418,
			18773.971,
			18775.162,
			18776.355,
			18779.945,
			18782.348,
			18785.963,
			18788.379,
			18790.801,
			18792.014,
			18794.443,
			18795.662,
			18798.104,
			18800.551,
			18801.777,
			18804.234,
			18806.697,
			18810.406,
			18811.643,
			18812.883,
			18815.365,
			18817.854,
			18819.102,
			18821.598,
			18822.85,
			18825.354,
			18827.863,
			18831.623,
			18834.15,
			18837.98,
			18839.256,
			18840.512,
			18844.359,
			18846.938,
			18850.824,
			18853.43,
			18857.348,
			18859.961,
			18861.273,
			18863.896,
			18866.529,
			18867.848,
			18870.488,
			18873.137,
			18877.115,
			18879.777,
			18883.777,
			18885.113,
			18886.453,
			18890.48,
			18893.176,
			18897.229,
			18899.939,
			18902.66,
			18906.771,
			18908.141,
			18909.51,
			18913.629,
			18916.381,
			18920.502,
			18923.271,
			18926.045,
			18927.434,
			18930.242,
			18933.031,
			18934.43,
			18937.232,
			18938.637,
			18941.449,
			18944.273,
			18948.527,
			18951.373,
			18955.658,
			18957.066,
			18958.502,
			18962.812,
			18965.689,
			18970.014,
			18972.896,
			18975.783,
			18977.225,
			18980.133,
			18981.584,
			18983.031,
			18987.344,
			18988.797,
			18991.725,
			18994.629,
			18997.545,
			19000.459,
			19001.916,
			19004.828,
			19007.74,
			19009.197,
			19013.568,
			19016.49,
			19019.418,
			19023.805,
			19026.754,
			19031.199,
			19032.684,
			19034.168,
			19038.648,
			19041.641,
			19046.15,
			19049.162,
			19052.184,
			19053.697,
			19056.754,
			19058.271,
			19061.316,
			19064.363,
			19065.889,
			19068.947,
			19072.012,
			19076.617,
			19078.154,
			19079.695,
			19082.754,
			19085.869,
			19087.414,
			19092.062,
			19095.162,
			19098.262,
			19102.893,
			19105.955,
			19110.5,
			19111.998,
			19113.488,
			19117.895,
			19119.348,
			19120.791,
			19125.094,
			19127.945,
			19130.785,
			19132.203,
			19135.035,
			19136.449,
			19139.273,
			19142.094,
			19143.5,
			19146.305,
			19149.082,
			19153.277,
			19154.682,
			19156.086,
			19160.33,
			19161.746,
			19163.164,
			19167.42,
			19170.27,
			19173.121,
			19177.379,
			19180.242,
			19184.561,
			19185.996,
			19187.432,
			19191.734,
			19194.609,
			19198.926,
			19201.811,
			19204.705,
			19206.152,
			19209.027,
			19211.965,
			19213.422,
			19217.812,
			19220.752,
			19223.693,
			19228.086,
			19231.027,
			19235.439,
			19236.906,
			19238.367,
			19242.785,
			19245.699,
			19250.076,
			19252.984,
			19255.895,
			19257.35,
			19260.266,
			19261.725,
			19263.186,
			19267.588,
			19269.033,
			19270.51,
			19274.961,
			19277.943,
			19280.943,
			19282.443,
			19285.453,
			19288.471,
			19289.982,
			19293.006,
			19294.52,
			19297.551,
			19300.584,
			19305.141,
			19308.182,
			19312.775,
			19314.301,
			19315.826,
			19320.414,
			19323.479,
			19328.086,
			19331.164,
			19334.25,
			19335.793,
			19338.889,
			19340.414,
			19343.543,
			19346.654,
			19348.186,
			19351.307,
			19354.432,
			19357.59,
			19362.305,
			19365.434,
			19368.598,
			19370.207,
			19374.973,
			19378.152,
			19381.344,
			19386.141,
			19389.348,
			19394.16,
			19395.766,
			19397.371,
			19402.184,
			19405.357,
			19410.146,
			19413.355,
			19418.084,
			19421.266,
			19422.842,
			19425.994,
			19429.135,
			19430.705,
			19433.818,
			19436.959,
			19441.678,
			19443.252,
			19444.828,
			19449.562,
			19451.17,
			19452.75,
			19455.918,
			19457.504,
			19460.654,
			19463.838,
			19468.625,
			19471.824,
			19476.641,
			19478.25,
			19479.861,
			19486.322,
			19487.941,
			19492.809,
			19496.059,
			19499.311,
			19500.939,
			19504.201,
			19507.467,
			19509.102,
			19514.016,
			19517.297,
			19520.584,
			19525.523,
			19527.172,
			19528.822,
			19533.773,
			19535.426,
			19537.078,
			19542.045,
			19545.359,
			19550.332,
			19553.648,
			19556.969,
			19558.631,
			19561.953,
			19563.617,
			19566.945,
			19571.938,
			19573.605,
			19578.605,
			19581.941,
			19585.279,
			19586.949,
			19590.291,
			19593.637,
			19595.311,
			19600.334,
			19603.688,
			19607.045,
			19612.084,
			19615.449,
			19620.502,
			19622.186,
			19623.873,
			19628.938,
			19632.324,
			19637.412,
			19640.814,
			19644.219,
			19645.924,
			19649.324,
			19651.021,
			19654.4,
			19657.756,
			19659.424,
			19662.736,
			19666.021,
			19669.268,
			19672.482,
			19674.078,
			19677.244,
			19680.381,
			19681.936,
			19685.018,
			19686.539,
			19689.559,
			19692.535,
			19696.893,
			19699.744,
			19703.908,
			19705.279,
			19706.639,
			19710.645,
			19713.268,
			19717.131,
			19719.662,
			19722.162,
			19723.398,
			19725.848,
			19727.061,
			19729.48,
			19731.844,
			19733.014,
			19735.326,
			19737.613,
			19741,
			19742.123,
			19743.246,
			19746.605,
			19747.723,
			19748.838,
			19752.176,
			19754.391,
			19757.703,
			19759.904,
			19762.098,
			19765.385,
			19766.48,
			19767.574,
			19770.857,
			19771.953,
			19773.051,
			19776.348,
			19778.549,
			19780.766,
			19781.875,
			19784.096,
			19785.209,
			19787.453,
			19789.707,
			19790.836,
			19793.102,
			19795.395,
			19798.844,
			19801.16,
			19804.662,
			19805.832,
			19807.006,
			19810.551,
			19812.924,
			19816.516,
			19818.922,
			19821.334,
			19822.547,
			19824.977,
			19826.191,
			19827.41,
			19831.08,
			19832.307,
			19833.533,
			19837.223,
			19839.684,
			19843.367,
			19845.84,
			19848.316,
			19849.559,
			19852.051,
			19853.287,
			19855.768,
			19858.223,
			19861.938,
			19864.412,
			19868.092,
			19869.309,
			19870.523,
			19874.109,
			19876.457,
			19879.898,
			19882.141,
			19884.34,
			19885.424,
			19887.562,
			19888.619,
			19890.699,
			19893.756,
			19895.75,
			19897.711,
			19900.625,
			19902.535,
			19904.432,
			19906.316,
			19907.26,
			19910.072,
			19911.938,
			19913.793,
			19916.551,
			19918.383,
			19921.123,
			19922.031,
			19922.938,
			19925.656,
			19927.469,
			19930.184,
			19931.996,
			19933.811,
			19934.723,
			19936.553,
			19937.467,
			19939.299,
			19941.146,
			19942.072,
			19943.93,
			19945.789,
			19948.615,
			19950.51,
			19953.377,
			19954.344,
			19955.312,
			19957.254,
			19958.23,
			19960.201,
			19962.18,
			19965.168,
			19967.172,
			19970.186,
			19971.191,
			19972.195,
			19975.223,
			19976.229,
			19977.234,
			19980.262,
			19982.279,
			19984.297,
			19985.307,
			19987.326,
			19989.34,
			19990.35,
			19992.369,
			19993.373,
			19995.385,
			19997.4,
			20000.404,
			20002.41,
			20005.41,
			20006.41,
			20007.41,
			20010.418,
			20012.428,
			20014.441,
			20017.492,
			20019.531,
			20020.555,
			20022.621,
			20023.654,
			20024.689,
			20027.812,
			20028.855,
			20029.902,
			20033.043,
			20035.133,
			20037.227,
			20038.277,
			20040.367,
			20042.465,
			20043.516,
			20045.621,
			20046.672,
			20048.785,
			20050.889,
			20054.086,
			20056.232,
			20059.479,
			20060.568,
			20061.66,
			20064.957,
			20067.174,
			20070.523,
			20072.771,
			20075.031,
			20076.166,
			20078.441,
			20079.586,
			20081.879,
			20084.182,
			20085.338,
			20087.656,
			20089.982,
			20093.492,
			20095.844,
			20098.203,
			20101.766,
			20104.148,
			20105.344,
			20107.744,
			20110.15,
			20113.773,
			20116.191,
			20119.811,
			20121.01,
			20122.203,
			20125.734,
			20128.047,
			20131.447,
			20133.668,
			20135.852,
			20136.93,
			20139.057,
			20140.109,
			20142.182,
			20144.219,
			20145.225,
			20147.209,
			20149.158,
			20152.033,
			20152.98,
			20153.924,
			20156.73,
			20157.678,
			20158.607,
			20160.461,
			20161.381,
			20163.209,
			20165.031,
			20167.711,
			20169.461,
			20172.09,
			20172.979,
			20173.867,
			20176.508,
			20178.262,
			20180.885,
			20182.658,
			20185.297,
			20187.047,
			20188.795,
			20189.67,
			20191.484,
			20192.391,
			20194.193,
			20195.992,
			20198.693,
			20200.557,
			20203.326,
			20204.246,
			20205.164,
			20207,
			20207.949,
			20209.832,
			20212.633,
			20214.492,
			20217.324,
			20219.203,
			20220.141,
			20221.076,
			20223.896,
			20225.805,
			20228.66,
			20230.566,
			20232.49,
			20233.459,
			20235.391,
			20237.316,
			20238.262,
			20240.178,
			20241.121,
			20242.98,
			20244.84,
			20247.645,
			20249.521,
			20252.289,
			20253.213,
			20254.141,
			20256.941,
			20258.805,
			20261.645,
			20263.523,
			20266.387,
			20268.312,
			20269.277,
			20271.229,
			20273.176,
			20274.152,
			20276.117,
			20278.094,
			20280.08,
			20282.078,
			20283.082,
			20285.094,
			20287.121,
			20288.139,
			20291.211,
			20293.256,
			20295.352,
			20298.482,
			20300.57,
			20303.709,
			20304.754,
			20305.805,
			20308.953,
			20311.072,
			20314.266,
			20316.414,
			20319.609,
			20321.73,
			20322.789,
			20324.898,
			20328.027,
			20330.119,
			20332.203,
			20335.34,
			20337.414,
			20340.523,
			20341.561,
			20342.596,
			20344.668,
			20345.703,
			20347.781,
			20349.863,
			20352.994,
			20355.094,
			20358.262,
			20359.305,
			20360.367,
			20363.572,
			20365.725,
			20368.99,
			20371.172,
			20373.342,
			20374.441,
			20376.648,
			20377.756,
			20379.98,
			20382.215,
			20383.34,
			20385.596,
			20387.865,
			20391.293,
			20393.596,
			20397.07,
			20398.234,
			20399.398,
			20402.914,
			20405.266,
			20408.812,
			20411.188,
			20413.568,
			20417.16,
			20418.363,
			20419.566,
			20423.191,
			20424.402,
			20425.621,
			20429.281,
			20431.734,
			20434.195,
			20435.432,
			20437.906,
			20440.391,
			20441.637,
			20444.133,
			20445.385,
			20447.895,
			20450.414,
			20454.207,
			20456.744,
			20460.564,
			20461.844,
			20463.125,
			20466.977,
			20469.553,
			20473.428,
			20476.02,
			20478.613,
			20479.912,
			20482.518,
			20483.82,
			20486.428,
			20489.033,
			20490.338,
			20492.945,
			20495.551,
			20498.148,
			20500.73,
			20502.014,
			20504.559,
			20507.074,
			20508.32,
			20511.998,
			20514.404,
			20516.777,
			20520.275,
			20522.568,
			20525.957,
			20527.061,
			20528.16,
			20531.414,
			20533.559,
			20536.727,
			20538.834,
			20540.949,
			20541.996,
			20544.088,
			20545.129,
			20547.215,
			20549.299,
			20550.34,
			20552.428,
			20554.514,
			20557.66,
			20559.768,
			20562.936,
			20563.998,
			20565.064,
			20567.195,
			20568.264,
			20570.412,
			20572.578,
			20575.832,
			20578.035,
			20581.354,
			20582.463,
			20583.578,
			20586.936,
			20589.178,
			20592.535,
			20594.781,
			20598.105,
			20600.332,
			20601.439,
			20603.645,
			20605.852,
			20606.959,
			20609.176,
			20611.4,
			20614.76,
			20617.016,
			20620.414,
			20621.553,
			20622.693,
			20626.127,
			20628.422,
			20631.881,
			20634.197,
			20636.52,
			20640.02,
			20641.188,
			20642.359,
			20645.887,
			20647.068,
			20648.25,
			20651.809,
			20654.191,
			20656.582,
			20657.777,
			20660.18,
			20662.57,
			20663.777,
			20666.199,
			20667.412,
			20669.846,
			20672.309,
			20675.98,
			20678.418,
			20682.105,
			20683.334,
			20684.564,
			20688.242,
			20690.682,
			20694.334,
			20696.727,
			20699.102,
			20702.613,
			20703.787,
			20706.129,
			20708.465,
			20709.633,
			20710.816,
			20714.301,
			20716.617,
			20718.93,
			20720.082,
			20722.381,
			20724.676,
			20725.818,
			20729.24,
			20731.498,
			20733.773,
			20737.203,
			20739.48,
			20742.902,
			20744.041,
			20745.182,
			20748.617,
			20750.895,
			20754.369,
			20756.693,
			20760.186,
			20762.521,
			20763.697,
			20766.051,
			20768.404,
			20769.584,
			20771.957,
			20774.332,
			20777.898,
			20779.098,
			20780.295,
			20783.889,
			20785.086,
			20786.289,
			20789.914,
			20792.332,
			20794.76,
			20798.422,
			20800.873,
			20804.566,
			20805.805,
			20807.043,
			20810.771,
			20813.264,
			20817.018,
			20819.529,
			20822.041,
			20823.301,
			20825.826,
			20828.363,
			20829.637,
			20832.189,
			20833.469,
			20836.037,
			20838.621,
			20842.516,
			20845.131,
			20849.076,
			20850.396,
			20851.719,
			20855.695,
			20858.357,
			20861.02,
			20865.002,
			20867.66,
			20871.652,
			20872.982,
			20874.316,
			20878.326,
			20879.664,
			20881.002,
			20885.029,
			20887.719,
			20890.412,
			20891.762,
			20894.457,
			20897.156,
			20898.508,
			20901.217,
			20902.57,
			20905.283,
			20908.002,
			20912.09,
			20914.82,
			20918.93,
			20920.277,
			20921.652,
			20925.781,
			20928.539,
			20932.707,
			20935.48,
			20939.625,
			20942.414,
			20943.812,
			20945.211,
			20949.422,
			20950.828,
			20953.648,
			20956.473,
			20959.305,
			20963.566,
			20966.418,
			20969.273,
			20970.705,
			20973.572,
			20975.008,
			20977.887,
			20980.77,
			20985.107,
			20988.008,
			20992.371,
			20993.83,
			20995.289,
			20999.678,
			21002.611,
			21007.027,
			21009.98,
			21014.42,
			21017.389,
			21018.875,
			21021.852,
			21024.836,
			21026.332,
			21029.324,
			21032.328,
			21036.844,
			21038.355,
			21039.867,
			21042.895,
			21045.93,
			21047.447,
			21050.492,
			21052.018,
			21055.072,
			21058.131,
			21059.662,
			21062.73,
			21065.803,
			21070.422,
			21071.963,
			21073.508,
			21078.146,
			21081.246,
			21085.904,
			21089.02,
			21092.141,
			21093.701,
			21096.83,
			21098.396,
			21101.533,
			21104.672,
			21106.244,
			21109.389,
			21112.539,
			21117.27,
			21118.852,
			21120.43,
			21125.18,
			21126.764,
			21128.348,
			21133.109,
			21136.283,
			21139.461,
			21144.234,
			21147.422,
			21152.211,
			21153.811,
			21155.41,
			21161.82,
			21163.426,
			21168.248,
			21171.469,
			21176.309,
			21179.539,
			21182.777,
			21184.396,
			21187.639,
			21189.262,
			21192.512,
			21195.766,
			21200.658,
			21203.924,
			21208.832,
			21210.473,
			21212.111,
			21217.037,
			21220.328,
			21225.27,
			21228.572,
			21231.877,
			21233.531,
			21236.844,
			21238.5,
			21241.82,
			21245.141,
			21246.803,
			21250.131,
			21253.465,
			21256.799,
			21260.141,
			21261.811,
			21265.156,
			21268.508,
			21270.184,
			21273.539,
			21275.221,
			21278.586,
			21281.953,
			21287.018,
			21290.398,
			21295.477,
			21297.174,
			21298.871,
			21303.971,
			21307.375,
			21312.49,
			21315.906,
			21319.326,
			21321.039,
			21324.465,
			21327.895,
			21329.613,
			21334.771,
			21338.215,
			21341.66,
			21345.111,
			21350.293,
			21353.752,
			21357.217,
			21358.949,
			21364.156,
			21367.633,
			21371.113,
			21376.342,
			21379.834,
			21385.078,
			21386.828,
			21388.582,
			21393.842,
			21397.355,
			21402.633,
			21406.156,
			21409.682,
			21411.445,
			21414.979,
			21416.746,
			21420.285,
			21425.6,
			21429.146,
			21432.699,
			21438.029,
			21439.809,
			21441.588,
			21446.934,
			21448.717,
			21450.502,
			21455.855,
			21459.43,
			21463.002,
			21468.367,
			21471.947,
			21473.736,
			21477.32,
			21479.113,
			21480.904,
			21486.291,
			21489.885,
			21495.285,
			21498.887,
			21502.494,
			21504.299,
			21507.91,
			21511.525,
			21513.334,
			21516.953,
			21518.764,
			21522.387,
			21526.012,
			21531.453,
			21535.086,
			21540.537,
			21542.355,
			21544.174,
			21549.633,
			21553.277,
			21558.748,
			21562.4,
			21567.883,
			21571.543,
			21573.375,
			21577.039,
			21580.707,
			21582.543,
			21584.379,
			21589.895,
			21593.578,
			21599.107,
			21602.801,
			21606.498,
			21608.35,
			21612.057,
			21613.91,
			21617.623,
			21621.336,
			21626.912,
			21630.625,
			21636.203,
			21638.061,
			21639.922,
			21645.502,
			21649.225,
			21654.812,
			21658.543,
			21662.275,
			21664.143,
			21667.879,
			21669.746,
			21673.484,
			21677.225,
			21679.094,
			21682.836,
			21686.58,
			21690.324,
			21694.068,
			21695.941,
			21699.689,
			21703.438,
			21705.312,
			21709.066,
			21710.941,
			21714.697,
			21718.457,
			21724.1,
			21727.863,
			21733.518,
			21735.402,
			21737.289,
			21742.949,
			21746.723,
			21752.389,
			21756.168,
			21759.945,
			21761.834,
			21765.615,
			21767.506,
			21771.289,
			21776.971,
			21780.76,
			21784.553,
			21790.246,
			21794.045,
			21799.75,
			21801.652,
			21803.555,
			21809.266,
			21813.076,
			21816.887,
			21822.609,
			21826.426,
			21832.152,
			21834.062,
			21835.975,
			21841.707,
			21843.619,
			21845.531,
			21851.273,
			21855.104,
			21858.934,
			21860.852,
			21864.684,
			21868.521,
			21870.439,
			21874.279,
			21876.199,
			21880.043,
			21883.887,
			21889.66,
			21893.51,
			21899.287,
			21901.213,
			21903.139,
			21908.922,
			21912.777,
			21918.564,
			21922.426,
			21926.285,
			21928.217,
			21932.08,
			21934.012,
			21937.879,
			21941.746,
			21943.68,
			21945.615,
			21951.422,
			21955.295,
			21959.17,
			21961.107,
			21964.984,
			21968.861,
			21970.801,
			21976.623,
			21980.506,
			21984.391,
			21990.221,
			21994.107,
			21999.943,
			22001.887,
			22003.834,
			22009.674,
			22013.566,
			22019.412,
			22023.309,
			22029.152,
			22033.053,
			22035.004,
			22038.904,
			22044.76,
			22048.664,
			22052.568,
			22056.475,
			22060.383,
			22062.336,
			22066.244,
			22070.152,
			22072.109,
			22076.018,
			22077.975,
			22081.887,
			22085.801,
			22091.672,
			22095.586,
			22101.461,
			22103.42,
			22105.379,
			22111.256,
			22115.176,
			22121.055,
			22124.977,
			22130.857,
			22134.777,
			22136.74,
			22140.662,
			22146.547,
			22150.471,
			22154.395,
			22160.285,
			22164.211,
			22170.104,
			22172.07,
			22174.035,
			22179.934,
			22183.865,
			22189.766,
			22193.699,
			22197.637,
			22203.537,
			22205.504,
			22207.473,
			22213.375,
			22217.309,
			22223.211,
			22227.145,
			22231.08,
			22233.047,
			22236.984,
			22240.918,
			22242.887,
			22246.822,
			22248.789,
			22252.725,
			22256.66,
			22262.566,
			22266.502,
			22272.406,
			22274.375,
			22276.344,
			22282.25,
			22286.188,
			22292.092,
			22296.031,
			22299.969,
			22305.877,
			22307.844,
			22309.812,
			22315.721,
			22317.689,
			22321.629,
			22325.566,
			22329.504,
			22333.441,
			22335.408,
			22339.344,
			22343.281,
			22345.25,
			22349.182,
			22351.148,
			22355.082,
			22359.014,
			22364.91,
			22368.84,
			22374.734,
			22376.699,
			22378.666,
			22384.561,
			22388.496,
			22394.398,
			22398.336,
			22402.275,
			22404.246,
			22408.188,
			22410.16,
			22414.104,
			22418.043,
			22420.016,
			22423.955,
			22427.891,
			22431.826,
			22437.727,
			22443.629,
			22445.596,
			22447.562,
			22453.463,
			22457.398,
			22461.332,
			22467.236,
			22471.172,
			22477.074,
			22479.043,
			22481.008,
			22486.91,
			22490.844,
			22496.746,
			22500.68,
			22506.582,
			22510.516,
			22512.484,
			22516.418,
			22520.354,
			22522.32,
			22526.258,
			22530.195,
			22536.105,
			22538.076,
			22540.045,
			22545.957,
			22547.928,
			22549.896,
			22555.809,
			22559.75,
			22565.664,
			22569.605,
			22573.547,
			22579.459,
			22583.402,
			22589.316,
			22591.289,
			22593.262,
			22599.182,
			22603.129,
			22609.053,
			22613.002,
			22616.951,
			22618.924,
			22622.871,
			22624.846,
			22628.793,
			22632.736,
			22638.65,
			22642.594,
			22648.506,
			22650.475,
			22652.447,
			22658.363,
			22662.309,
			22668.23,
			22672.18,
			22676.129,
			22678.105,
			22682.055,
			22684.031,
			22686.004,
			22693.902,
			22695.877,
			22701.799,
			22705.75,
			22709.699,
			22711.674,
			22715.623,
			22719.576,
			22721.553,
			22727.482,
			22731.438,
			22735.389,
			22741.322,
			22745.277,
			22751.207,
			22753.186,
			22755.164,
			22761.094,
			22765.049,
			22770.977,
			22774.93,
			22780.854,
			22784.805,
			22786.779,
			22790.729,
			22794.68,
			22796.654,
			22800.607,
			22804.561,
			22810.49,
			22812.469,
			22814.445,
			22818.4,
			22822.359,
			22824.336,
			22828.293,
			22830.273,
			22834.229,
			22838.186,
			22844.121,
			22848.076,
			22854.012,
			22855.992,
			22857.969,
			22863.908,
			22867.869,
			22873.812,
			22877.775,
			22881.74,
			22883.723,
			22887.688,
			22889.672,
			22893.637,
			22897.604,
			22899.588,
			22903.555,
			22907.521,
			22913.477,
			22915.461,
			22917.445,
			22923.402,
			22925.387,
			22927.373,
			22933.332,
			22937.309,
			22941.283,
			22947.242,
			22951.219,
			22957.18,
			22959.166,
			22961.152,
			22967.111,
			22971.084,
			22977.039,
			22981.008,
			22984.977,
			22986.959,
			22990.93,
			22994.898,
			22996.883,
			23002.848,
			23006.822,
			23010.805,
			23016.775,
			23020.758,
			23026.73,
			23028.723,
			23030.713,
			23036.686,
			23040.672,
			23046.656,
			23050.65,
			23054.646,
			23056.645,
			23060.646,
			23062.648,
			23064.65,
			23070.664,
			23072.672,
			23074.68,
			23080.709,
			23084.734,
			23088.762,
			23090.781,
			23094.82,
			23098.857,
			23100.879,
			23104.926,
			23106.949,
			23110.996,
			23115.043,
			23121.113,
			23125.156,
			23131.219,
			23133.238,
			23135.256,
			23141.311,
			23145.34,
			23151.383,
			23155.406,
			23159.428,
			23161.438,
			23165.457,
			23167.465,
			23171.48,
			23175.496,
			23177.502,
			23181.518,
			23185.535,
			23189.553,
			23193.572,
			23195.584,
			23199.607,
			23203.633,
			23205.646,
			23211.689,
			23215.719,
			23219.752,
			23225.805,
			23229.842,
			23235.906,
			23237.928,
			23239.953,
			23246.031,
			23250.084,
			23256.168,
			23260.229,
			23266.312,
			23270.371,
			23272.4,
			23276.457,
			23280.514,
			23282.543,
			23286.6,
			23290.658,
			23296.746,
			23298.777,
			23300.807,
			23306.898,
			23308.959,
			23310.953,
			23317.035,
			23321.088,
			23327.166,
			23331.219,
			23335.271,
			23341.352,
			23343.414,
			23345.438,
			23351.504,
			23355.535,
			23361.504,
			23365.516,
			23369.436,
			23371.398,
			23375.309,
			23377.246,
			23381.09,
			23386.777,
			23390.52,
			23394.221,
			23399.721,
			23403.355,
			23408.758,
			23410.547,
			23412.322,
			23417.646,
			23421.164,
			23426.406,
			23429.945,
			23433.43,
			23438.705,
			23440.465,
			23442.225,
			23447.508,
			23449.275,
			23451.043,
			23456.373,
			23459.893,
			23463.449,
			23465.225,
			23468.783,
			23472.387,
			23474.172,
			23477.74,
			23479.496,
			23483.074,
			23486.682,
			23492.014,
			23495.59,
			23500.977,
			23502.756,
			23504.539,
			23509.854,
			23513.424,
			23518.734,
			23522.236,
			23525.754,
			23527.51,
			23531.014,
			23532.76,
			23534.504,
			23539.725,
			23541.461,
			23544.938,
			23548.436,
			23551.9,
			23557.102,
			23560.557,
			23563.988,
			23565.717,
			23569.168,
			23570.893,
			23574.371,
			23577.812,
			23582.969,
			23586.402,
			23591.535,
			23593.242,
			23594.922,
			23600.027,
			23603.418,
			23608.484,
			23611.885,
			23615.244,
			23616.922,
			23620.275,
			23621.951,
			23625.246,
			23630.197,
			23633.457,
			23636.676,
			23641.432,
			23642.996,
			23644.551,
			23647.629,
			23650.672,
			23652.18,
			23655.164,
			23656.643,
			23659.57,
			23662.455,
			23666.707,
			23669.494,
			23673.602,
			23674.949,
			23676.289,
			23680.25,
			23681.553,
			23682.867,
			23686.691,
			23689.176,
			23691.654,
			23692.904,
			23695.324,
			23696.539,
			23698.959,
			23701.369,
			23702.574,
			23704.992,
			23707.379,
			23710.938,
			23713.287,
			23716.742,
			23717.887,
			23719.025,
			23722.395,
			23724.578,
			23727.789,
			23729.875,
			23731.971,
			23733,
			23735.049,
			23736.064,
			23737.074,
			23740.045,
			23741.027,
			23742.006,
			23744.902,
			23746.799,
			23748.676,
			23749.596,
			23751.395,
			23753.172,
			23754.047,
			23755.768,
			23756.615,
			23758.275,
			23759.896,
			23762.256,
			23763.789,
			23766.057,
			23766.805,
			23767.551,
			23769.781,
			23771.264,
			23773.473,
			23774.951,
			23776.406,
			23777.133,
			23778.576,
			23779.295,
			23780.73,
			23782.162,
			23782.873,
			23784.291,
			23785.701,
			23787.109,
			23789.223,
			23790.639,
			23792.049,
			23792.752,
			23794.16,
			23794.861,
			23796.303,
			23797.754,
			23799.918,
			23801.359,
			23803.557,
			23804.289,
			23805.023,
			23807.227,
			23808.703,
			23810.98,
			23812.494,
			23814.01,
			23814.771,
			23816.332,
			23817.113,
			23818.672,
			23820.232,
			23821.016,
			23822.652,
			23824.289,
			23826.748,
			23828.395,
			23830.863,
			23831.688,
			23832.496,
			23834.086,
			23834.883,
			23836.479,
			23838.021,
			23840.248,
			23841.727,
			23843.93,
			23844.662,
			23845.396,
			23847.617,
			23849.115,
			23851.383,
			23852.943,
			23855.34,
			23856.953,
			23857.766,
			23859.412,
			23861.062,
			23861.918,
			23863.639,
			23865.367,
			23867.988,
			23869.752,
			23872.4,
			23873.289,
			23874.18,
			23876.871,
			23878.678,
			23881.371,
			23883.172,
			23884.977,
			23885.879,
			23887.686,
			23888.584,
			23890.357,
			23893.016,
			23893.902,
			23896.559,
			23898.328,
			23900.082,
			23900.957,
			23902.711,
			23904.465,
			23905.344,
			23907.1,
			23907.979,
			23909.744,
			23911.516,
			23914.186,
			23915.977,
			23918.676,
			23919.582,
			23920.488,
			23923.219,
			23925.053,
			23927.826,
			23929.674,
			23931.549,
			23932.494,
			23934.391,
			23935.344,
			23937.26,
			23939.189,
			23940.16,
			23942.107,
			23944.07,
			23947.035,
			23948.031,
			23949.029,
			23951.031,
			23953.047,
			23954.059,
			23956.088,
			23957.105,
			23959.152,
			23961.203,
			23964.303,
			23966.379,
			23969.5,
			23970.539,
			23971.58,
			23974.715,
			23975.766,
			23976.82,
			23979.996,
			23982.125,
			23984.268,
			23985.357,
			23987.488,
			23988.568,
			23990.732,
			23992.902,
			23993.992,
			23996.176,
			23998.369,
			24001.68,
			24002.789,
			24003.898,
			24007.244,
			24008.363,
			24009.488,
			24011.738,
			24012.867,
			24015.135,
			24017.412,
			24020.846,
			24023.145,
			24026.617,
			24027.777,
			24028.941,
			24032.449,
			24033.621,
			24034.797,
			24038.336,
			24040.707,
			24043.086,
			24044.279,
			24046.672,
			24049.07,
			24050.275,
			24052.689,
			24053.9,
			24056.328,
			24058.764,
			24062.436,
			24063.662,
			24064.895,
			24068.594,
			24069.832,
			24071.07,
			24074.83,
			24077.33,
			24081.07,
			24083.586,
			24086.109,
			24087.371,
			24089.9,
			24091.17,
			24092.441,
			24096.266,
			24097.547,
			24098.824,
			24102.68,
			24105.258,
			24109.145,
			24111.742,
			24114.348,
			24115.648,
			24118.256,
			24119.562,
			24122.18,
			24124.807,
			24128.758,
			24131.402,
			24135.379,
			24136.707,
			24138.039,
			24142.041,
			24144.719,
			24148.754,
			24151.453,
			24154.164,
			24155.521,
			24158.242,
			24159.605,
			24162.334,
			24166.438,
			24169.18,
			24171.928,
			24174.684,
			24178.83,
			24181.604,
			24184.387,
			24185.779,
			24188.57,
			24189.971,
			24192.773,
			24195.582,
			24199.809,
			24202.635,
			24206.889,
			24208.309,
			24209.73,
			24214.008,
			24216.869,
			24221.172,
			24224.049,
			24228.377,
			24231.27,
			24232.719,
			24235.621,
			24238.531,
			24239.988,
			24242.908,
			24245.832,
			24250.234,
			24251.705,
			24253.178,
			24257.604,
			24259.082,
			24260.562,
			24265.012,
			24267.986,
			24270.967,
			24275.449,
			24278.443,
			24282.949,
			24284.453,
			24285.961,
			24290.486,
			24293.512,
			24298.062,
			24301.102,
			24304.148,
			24305.674,
			24308.73,
			24310.26,
			24313.324,
			24316.396,
			24317.934,
			24321.008,
			24321.309
		],
		[
			0,
			0.054,
			0.084,
			0.115,
			0.178,
			0.192,
			0.238,
			0.3,
			0.363,
			0.41,
			0.486,
			0.519,
			0.548,
			0.612,
			0.672,
			0.736,
			0.797,
			0.844,
			0.859,
			0.922,
			0.952,
			0.983,
			1.044,
			1.061,
			1.105,
			1.168,
			1.231,
			1.293,
			1.354,
			1.385,
			1.417,
			1.493,
			1.542,
			1.602,
			1.665,
			1.725,
			1.789,
			1.819,
			1.852,
			1.913,
			1.974,
			2.038,
			2.098,
			2.146,
			2.161,
			2.222,
			2.254,
			2.285,
			2.347,
			2.363,
			2.41,
			2.472,
			2.534,
			2.58,
			2.596,
			2.657,
			2.688,
			2.719,
			2.782,
			2.796,
			2.844,
			2.904,
			2.968,
			3.014,
			3.089,
			3.122,
			3.153,
			3.23,
			3.277,
			3.34,
			3.402,
			3.446,
			3.463,
			3.525,
			3.556,
			3.588,
			3.649,
			3.664,
			3.711,
			3.777,
			3.836,
			3.881,
			3.897,
			3.96,
			3.996,
			4.02,
			4.083,
			4.146,
			4.209,
			4.27,
			4.316,
			4.393,
			4.425,
			4.455,
			4.516,
			4.533,
			4.58,
			4.641,
			4.704,
			4.751,
			4.765,
			4.827,
			4.858,
			4.89,
			4.952,
			4.967,
			5.013,
			5.077,
			5.137,
			5.185,
			5.263,
			5.293,
			5.324,
			5.401,
			5.447,
			5.51,
			5.571,
			5.634,
			5.695,
			5.727,
			5.757,
			5.82,
			5.836,
			5.88,
			5.944,
			6.006,
			6.068,
			6.13,
			6.16,
			6.192,
			6.254,
			6.269,
			6.316,
			6.379,
			6.439,
			6.502,
			6.563,
			6.595,
			6.626,
			6.687,
			6.749,
			6.813,
			6.875,
			6.921,
			6.936,
			6.997,
			7.03,
			7.06,
			7.123,
			7.137,
			7.182,
			7.246,
			7.309,
			7.356,
			7.371,
			7.431,
			7.462,
			7.495,
			7.557,
			7.572,
			7.619,
			7.679,
			7.743,
			7.788,
			7.865,
			7.898,
			7.929,
			7.991,
			8.052,
			8.121,
			8.177,
			8.221,
			8.239,
			8.299,
			8.332,
			8.362,
			8.423,
			8.438,
			8.487,
			8.549,
			8.61,
			8.673,
			8.734,
			8.766,
			8.797,
			8.872,
			8.919,
			8.981,
			9.045,
			9.09,
			9.167,
			9.2,
			9.229,
			9.293,
			9.308,
			9.352,
			9.415,
			9.478,
			9.526,
			9.54,
			9.602,
			9.632,
			9.664,
			9.726,
			9.743,
			9.787,
			9.851,
			9.912,
			9.975,
			10.036,
			10.068,
			10.098,
			10.161,
			10.223,
			10.283,
			10.347,
			10.393,
			10.409,
			10.47,
			10.502,
			10.531,
			10.594,
			10.611,
			10.656,
			10.719,
			10.78,
			10.827,
			10.842,
			10.904,
			10.936,
			10.967,
			11.044,
			11.091,
			11.153,
			11.213,
			11.26,
			11.338,
			11.369,
			11.399,
			11.477,
			11.525,
			11.59,
			11.648,
			11.693,
			11.71,
			11.771,
			11.802,
			11.835,
			11.896,
			11.911,
			11.958,
			12.021,
			12.081,
			12.129,
			12.144,
			12.207,
			12.242,
			12.268,
			12.331,
			12.343,
			12.39,
			12.453,
			12.516,
			12.563,
			12.639,
			12.67,
			12.701,
			12.764,
			12.826,
			12.889,
			12.951,
			13.013,
			13.075,
			13.106,
			13.137,
			13.214,
			13.26,
			13.323,
			13.383,
			13.431,
			13.447,
			13.509,
			13.54,
			13.571,
			13.633,
			13.693,
			13.757,
			13.817,
			13.863,
			13.943,
			13.972,
			14.005,
			14.065,
			14.128,
			14.19,
			14.252,
			14.304,
			14.314,
			14.377,
			14.406,
			14.439,
			14.501,
			14.516,
			14.563,
			14.625,
			14.686,
			14.733,
			14.81,
			14.839,
			14.873,
			14.948,
			14.996,
			15.059,
			15.119,
			15.168,
			15.183,
			15.243,
			15.276,
			15.305,
			15.368,
			15.43,
			15.493,
			15.555,
			15.6,
			15.615,
			15.68,
			15.71,
			15.74,
			15.818,
			15.863,
			15.927,
			15.989,
			16.036,
			16.113,
			16.144,
			16.175,
			16.236,
			16.297,
			16.365,
			16.422,
			16.468,
			16.485,
			16.547,
			16.577,
			16.608,
			16.671,
			16.685,
			16.732,
			16.793,
			16.856,
			16.904,
			16.919,
			16.982,
			17.01,
			17.043,
			17.105,
			17.121,
			17.166,
			17.229,
			17.29,
			17.337,
			17.416,
			17.446,
			17.477,
			17.539,
			17.601,
			17.664,
			17.725,
			17.772,
			17.787,
			17.85,
			17.879,
			17.91,
			17.974,
			17.989,
			18.034,
			18.096,
			18.159,
			18.206,
			18.221,
			18.282,
			18.314,
			18.344,
			18.405,
			18.427,
			18.468,
			18.532,
			18.593,
			18.639,
			18.717,
			18.746,
			18.78,
			18.858,
			18.904,
			18.965,
			19.026,
			19.073,
			19.088,
			19.151,
			19.182,
			19.212,
			19.276,
			19.29,
			19.338,
			19.4,
			19.462,
			19.506,
			19.524,
			19.584,
			19.615,
			19.648,
			19.71,
			19.771,
			19.834,
			19.896,
			19.942,
			19.958,
			20.018,
			20.051,
			20.082,
			20.143,
			20.159,
			20.206,
			20.268,
			20.33,
			20.376,
			20.392,
			20.453,
			20.49,
			20.515,
			20.578,
			20.593,
			20.64,
			20.7,
			20.763,
			20.809,
			20.887,
			20.919,
			20.948,
			21.012,
			21.073,
			21.136,
			21.198,
			21.245,
			21.26,
			21.321,
			21.352,
			21.382,
			21.445,
			21.461,
			21.507,
			21.571,
			21.631,
			21.678,
			21.693,
			21.755,
			21.785,
			21.818,
			21.895,
			21.94,
			22.004,
			22.067,
			22.127,
			22.189,
			22.222,
			22.251,
			22.33,
			22.375,
			22.437,
			22.499,
			22.562,
			22.625,
			22.655,
			22.685,
			22.763,
			22.809,
			22.871,
			22.935,
			22.997,
			23.058,
			23.089,
			23.119,
			23.183,
			23.244,
			23.305,
			23.369,
			23.414,
			23.492,
			23.523,
			23.553,
			23.631,
			23.677,
			23.741,
			23.802,
			23.848,
			23.863,
			23.925,
			23.955,
			23.988,
			24.051,
			24.066,
			24.112,
			24.175,
			24.236,
			24.281,
			24.297,
			24.358,
			24.392,
			24.422,
			24.499,
			24.547,
			24.613,
			24.669,
			24.731,
			24.793,
			24.826,
			24.856,
			24.917,
			24.934,
			24.981,
			25.044,
			25.104,
			25.149,
			25.165,
			25.228,
			25.26,
			25.29,
			25.353,
			25.368,
			25.415,
			25.477,
			25.538,
			25.601,
			25.663,
			25.693,
			25.724,
			25.787,
			25.848,
			25.91,
			25.973,
			26.02,
			26.035,
			26.097,
			26.127,
			26.156,
			26.236,
			26.283,
			26.345,
			26.407,
			26.451,
			26.467,
			26.531,
			26.56,
			26.593,
			26.676,
			26.716,
			26.778,
			26.839,
			26.888,
			26.964,
			26.995,
			27.027,
			27.089,
			27.149,
			27.213,
			27.276,
			27.337,
			27.397,
			27.43,
			27.461,
			27.522,
			27.538,
			27.583,
			27.647,
			27.709,
			27.755,
			27.771,
			27.833,
			27.862,
			27.895,
			27.956,
			27.972,
			28.019,
			28.081,
			28.143,
			28.19,
			28.267,
			28.297,
			28.33,
			28.39,
			28.453,
			28.516,
			28.577,
			28.639,
			28.701,
			28.738,
			28.764,
			28.839,
			28.887,
			28.949,
			29.01,
			29.055,
			29.074,
			29.134,
			29.165,
			29.196,
			29.258,
			29.32,
			29.381,
			29.446,
			29.508,
			29.569,
			29.598,
			29.629,
			29.693,
			29.709,
			29.754,
			29.816,
			29.88,
			29.926,
			29.942,
			30.003,
			30.035,
			30.065,
			30.125,
			30.143,
			30.19,
			30.249,
			30.313,
			30.361,
			30.437,
			30.467,
			30.5,
			30.561,
			30.624,
			30.689,
			30.747,
			30.799,
			30.81,
			30.871,
			30.902,
			30.933,
			30.995,
			31.01,
			31.058,
			31.118,
			31.182,
			31.227,
			31.243,
			31.304,
			31.336,
			31.366,
			31.43,
			31.445,
			31.491,
			31.552,
			31.614,
			31.678,
			31.74,
			31.769,
			31.802,
			31.88,
			31.926,
			31.986,
			32.048,
			32.095,
			32.111,
			32.172,
			32.205,
			32.234,
			32.313,
			32.359,
			32.422,
			32.484,
			32.53,
			32.546,
			32.607,
			32.639,
			32.669,
			32.732,
			32.747,
			32.793,
			32.86,
			32.918,
			32.962,
			33.039,
			33.072,
			33.104,
			33.181,
			33.226,
			33.291,
			33.353,
			33.399,
			33.415,
			33.474,
			33.506,
			33.537,
			33.599,
			33.615,
			33.662,
			33.724,
			33.786,
			33.848,
			33.911,
			33.94,
			33.972,
			34.034,
			34.096,
			34.155,
			34.218,
			34.266,
			34.344,
			34.375,
			34.406,
			34.467,
			34.483,
			34.529,
			34.592,
			34.653,
			34.701,
			34.715,
			34.778,
			34.808,
			34.84,
			34.922,
			34.963,
			35.027,
			35.086,
			35.134,
			35.21,
			35.242,
			35.273,
			35.335,
			35.398,
			35.463,
			35.52,
			35.566,
			35.583,
			35.646,
			35.676,
			35.706,
			35.768,
			35.783,
			35.831,
			35.894,
			35.955,
			36.002,
			36.016,
			36.08,
			36.109,
			36.142,
			36.203,
			36.218,
			36.266,
			36.326,
			36.389,
			36.434,
			36.513,
			36.545,
			36.576,
			36.636,
			36.7,
			36.76,
			36.822,
			36.869,
			36.884,
			36.947,
			36.983,
			37.072,
			37.087,
			37.133,
			37.197,
			37.255,
			37.302,
			37.319,
			37.38,
			37.41,
			37.444,
			37.504,
			37.52,
			37.567,
			37.629,
			37.689,
			37.737,
			37.814,
			37.846,
			37.876,
			37.94,
			38.001,
			38.065,
			38.126,
			38.173,
			38.188,
			38.249,
			38.279,
			38.311,
			38.372,
			38.389,
			38.436,
			38.502,
			38.56,
			38.605,
			38.62,
			38.684,
			38.715,
			38.746,
			38.808,
			38.871,
			38.93,
			38.993,
			39.055,
			39.116,
			39.148,
			39.18,
			39.258,
			39.303,
			39.365,
			39.428,
			39.475,
			39.489,
			39.553,
			39.581,
			39.613,
			39.676,
			39.691,
			39.737,
			39.8,
			39.861,
			39.908,
			39.987,
			40.016,
			40.048,
			40.11,
			40.172,
			40.233,
			40.296,
			40.342,
			40.419,
			40.452,
			40.482,
			40.544,
			40.56,
			40.605,
			40.67,
			40.731,
			40.776,
			40.793,
			40.853,
			40.885,
			40.916,
			40.976,
			40.994,
			41.039,
			41.108,
			41.164,
			41.211,
			41.289,
			41.319,
			41.35,
			41.427,
			41.472,
			41.537,
			41.599,
			41.66,
			41.721,
			41.752,
			41.783,
			41.847,
			41.862,
			41.909,
			41.969,
			42.031,
			42.095,
			42.156,
			42.186,
			42.217,
			42.28,
			42.295,
			42.344,
			42.404,
			42.467,
			42.513,
			42.589,
			42.622,
			42.651,
			42.715,
			42.776,
			42.839,
			42.901,
			42.963,
			43.025,
			43.054,
			43.087,
			43.149,
			43.169,
			43.21,
			43.273,
			43.334,
			43.382,
			43.395,
			43.459,
			43.489,
			43.521,
			43.583,
			43.644,
			43.707,
			43.767,
			43.815,
			43.893,
			43.922,
			43.956,
			44.016,
			44.078,
			44.142,
			44.201,
			44.249,
			44.263,
			44.326,
			44.356,
			44.389,
			44.449,
			44.465,
			44.513,
			44.575,
			44.637,
			44.697,
			44.759,
			44.793,
			44.822,
			44.885,
			44.947,
			45.008,
			45.071,
			45.118,
			45.134,
			45.194,
			45.256,
			45.319,
			45.334,
			45.382,
			45.442,
			45.505,
			45.551,
			45.566,
			45.629,
			45.66,
			45.691,
			45.751,
			45.767,
			45.816,
			45.877,
			45.939,
			45.986,
			46.063,
			46.094,
			46.125,
			46.187,
			46.248,
			46.311,
			46.372,
			46.418,
			46.435,
			46.497,
			46.527,
			46.56,
			46.621,
			46.637,
			46.684,
			46.746,
			46.805,
			46.852,
			46.868,
			46.93,
			46.962,
			46.993,
			47.071,
			47.118,
			47.18,
			47.242,
			47.302,
			47.365,
			47.396,
			47.427,
			47.489,
			47.549,
			47.614,
			47.676,
			47.737,
			47.8,
			47.83,
			47.861,
			47.921,
			47.938,
			47.985,
			48.046,
			48.109,
			48.171,
			48.233,
			48.263,
			48.296,
			48.356,
			48.372,
			48.42,
			48.482,
			48.544,
			48.59,
			48.668,
			48.698,
			48.729,
			48.792,
			48.854,
			48.916,
			48.977,
			49.024,
			49.038,
			49.1,
			49.131,
			49.163,
			49.227,
			49.242,
			49.287,
			49.353,
			49.412,
			49.473,
			49.536,
			49.565,
			49.598,
			49.676,
			49.722,
			49.783,
			49.846,
			49.909,
			49.969,
			50.001,
			50.031,
			50.11,
			50.156,
			50.219,
			50.28,
			50.34,
			50.402,
			50.436,
			50.465,
			50.528,
			50.543,
			50.591,
			50.652,
			50.713,
			50.777,
			50.837,
			50.868,
			50.9,
			50.977,
			51.024,
			51.085,
			51.146,
			51.195,
			51.209,
			51.271,
			51.302,
			51.333,
			51.396,
			51.414,
			51.457,
			51.519,
			51.58,
			51.628,
			51.644,
			51.707,
			51.737,
			51.767,
			51.83,
			51.845,
			51.893,
			51.953,
			52.017,
			52.062,
			52.139,
			52.17,
			52.202,
			52.264,
			52.327,
			52.388,
			52.449,
			52.496,
			52.513,
			52.572,
			52.605,
			52.636,
			52.714,
			52.76,
			52.821,
			52.883,
			52.93,
			52.946,
			53.006,
			53.04,
			53.071,
			53.132,
			53.147,
			53.194,
			53.255,
			53.319,
			53.364,
			53.443,
			53.477,
			53.504,
			53.567,
			53.629,
			53.691,
			53.752,
			53.797,
			53.813,
			53.877,
			53.906,
			53.939,
			54,
			54.017,
			54.062,
			54.126,
			54.187,
			54.247,
			54.311,
			54.34,
			54.372,
			54.434,
			54.496,
			54.556,
			54.621,
			54.668,
			54.744,
			54.775,
			54.807,
			54.885,
			54.93,
			54.993,
			55.054,
			55.115,
			55.177,
			55.21,
			55.239,
			55.301,
			55.316,
			55.365,
			55.426,
			55.489,
			55.538,
			55.612,
			55.645,
			55.674,
			55.735,
			55.799,
			55.859,
			55.924,
			55.985,
			56.047,
			56.076,
			56.109,
			56.171,
			56.187,
			56.233,
			56.294,
			56.355,
			56.403,
			56.419,
			56.48,
			56.513,
			56.544,
			56.621,
			56.666,
			56.73,
			56.792,
			56.837,
			56.915,
			56.946,
			56.977,
			57.055,
			57.099,
			57.163,
			57.225,
			57.272,
			57.288,
			57.35,
			57.379,
			57.411,
			57.487,
			57.534,
			57.601,
			57.659,
			57.705,
			57.721,
			57.784,
			57.814,
			57.843,
			57.905,
			57.921,
			57.968,
			58.032,
			58.093,
			58.138,
			58.215,
			58.248,
			58.28,
			58.339,
			58.403,
			58.465,
			58.527,
			58.574,
			58.588,
			58.649,
			58.683,
			58.712,
			58.789,
			58.836,
			58.904,
			58.962,
			59.006,
			59.022,
			59.086,
			59.116,
			59.147,
			59.222,
			59.271,
			59.333,
			59.396,
			59.456,
			59.52,
			59.55,
			59.582,
			59.643,
			59.663,
			59.705,
			59.767,
			59.829,
			59.876,
			59.89,
			59.953,
			59.983,
			60.015,
			60.093,
			60.139,
			60.202,
			60.263,
			60.31,
			60.386,
			60.417,
			60.448,
			60.51,
			60.572,
			60.636,
			60.697,
			60.743,
			60.76,
			60.822,
			60.851,
			60.883,
			60.943,
			60.962,
			61.006,
			61.069,
			61.132,
			61.177,
			61.193,
			61.256,
			61.287,
			61.318,
			61.393,
			61.44,
			61.503,
			61.565,
			61.613,
			61.69,
			61.753,
			61.813,
			61.876,
			61.938,
			62.001,
			62.047,
			62.063,
			62.124,
			62.155,
			62.184,
			62.263,
			62.31,
			62.371,
			62.432,
			62.495,
			62.558,
			62.587,
			62.619,
			62.682,
			62.698,
			62.744,
			62.806,
			62.866,
			62.914,
			62.992,
			63.022,
			63.054,
			63.117,
			63.176,
			63.238,
			63.302,
			63.347,
			63.363,
			63.426,
			63.456,
			63.487,
			63.549,
			63.565,
			63.612,
			63.675,
			63.736,
			63.785,
			63.86,
			63.892,
			63.923,
			64.01,
			64.046,
			64.109,
			64.169,
			64.233,
			64.294,
			64.357,
			64.416,
			64.449,
			64.48,
			64.542,
			64.604,
			64.666,
			64.729,
			64.775,
			64.79,
			64.852,
			64.883,
			64.913,
			64.976,
			64.99,
			65.037,
			65.103,
			65.163,
			65.208,
			65.285,
			65.318,
			65.349,
			65.41,
			65.472,
			65.536,
			65.596,
			65.642,
			65.658,
			65.721,
			65.751,
			65.783,
			65.845,
			65.865,
			65.907,
			65.967,
			66.03,
			66.076,
			66.092,
			66.154,
			66.184,
			66.216,
			66.278,
			66.293,
			66.339,
			66.401,
			66.465,
			66.526,
			66.589,
			66.62,
			66.649,
			66.712,
			66.774,
			66.835,
			66.899,
			66.946,
			66.96,
			67.022,
			67.052,
			67.083,
			67.147,
			67.162,
			67.21,
			67.271,
			67.332,
			67.378,
			67.394,
			67.457,
			67.486,
			67.518,
			67.58,
			67.596,
			67.643,
			67.705,
			67.765,
			67.813,
			67.89,
			67.926,
			67.953,
			68.014,
			68.078,
			68.14,
			68.2,
			68.248,
			68.263,
			68.326,
			68.356,
			68.385,
			68.463,
			68.512,
			68.571,
			68.634,
			68.68,
			68.697,
			68.76,
			68.79,
			68.821,
			68.882,
			68.899,
			68.943,
			69.008,
			69.069,
			69.116,
			69.193,
			69.224,
			69.254,
			69.331,
			69.38,
			69.442,
			69.501,
			69.55,
			69.565,
			69.627,
			69.659,
			69.689,
			69.75,
			69.767,
			69.813,
			69.876,
			69.936,
			69.986,
			70.061,
			70.092,
			70.124,
			70.184,
			70.246,
			70.31,
			70.371,
			70.416,
			70.496,
			70.527,
			70.556,
			70.618,
			70.636,
			70.682,
			70.744,
			70.805,
			70.852,
			70.867,
			70.93,
			70.96,
			70.99,
			71.053,
			71.069,
			71.116,
			71.176,
			71.24,
			71.301,
			71.363,
			71.393,
			71.427,
			71.488,
			71.549,
			71.612,
			71.671,
			71.719,
			71.735,
			71.798,
			71.829,
			71.859,
			71.922,
			71.938,
			71.983,
			72.048,
			72.108,
			72.154,
			72.17,
			72.231,
			72.262,
			72.292,
			72.355,
			72.372,
			72.418,
			72.48,
			72.541,
			72.586,
			72.664,
			72.696,
			72.727,
			72.789,
			72.852,
			72.915,
			72.976,
			73.023,
			73.039,
			73.1,
			73.13,
			73.162,
			73.239,
			73.285,
			73.347,
			73.409,
			73.471,
			73.534,
			73.565,
			73.596,
			73.657,
			73.673,
			73.72,
			73.781,
			73.843,
			73.89,
			73.969,
			73.998,
			74.029,
			74.092,
			74.111,
			74.153,
			74.215,
			74.276,
			74.323,
			74.341,
			74.402,
			74.433,
			74.463,
			74.526,
			74.542,
			74.586,
			74.649,
			74.71,
			74.758,
			74.835,
			74.868,
			74.899,
			74.976,
			75.023,
			75.084,
			75.146,
			75.193,
			75.208,
			75.27,
			75.302,
			75.331,
			75.393,
			75.41,
			75.455,
			75.518,
			75.58,
			75.626,
			75.643,
			75.703,
			75.736,
			75.765,
			75.829,
			75.844,
			75.891,
			75.957,
			76.015,
			76.077,
			76.137,
			76.173,
			76.199,
			76.277,
			76.322,
			76.387,
			76.449,
			76.495,
			76.511,
			76.573,
			76.602,
			76.634,
			76.695,
			76.71,
			76.759,
			76.821,
			76.882,
			76.93,
			76.945,
			77.005,
			77.038,
			77.067,
			77.131,
			77.147,
			77.193,
			77.255,
			77.317,
			77.362,
			77.44,
			77.47,
			77.502,
			77.563,
			77.627,
			77.688,
			77.749,
			77.812,
			77.875,
			77.905,
			77.937,
			77.997,
			78.014,
			78.059,
			78.122,
			78.183,
			78.234,
			78.306,
			78.339,
			78.371,
			78.433,
			78.494,
			78.556,
			78.618,
			78.663,
			78.742,
			78.775,
			78.803,
			78.867,
			78.928,
			78.99,
			79.052,
			79.113,
			79.177,
			79.209,
			79.239,
			79.3,
			79.316,
			79.362,
			79.426,
			79.488,
			79.534,
			79.612,
			79.64,
			79.671,
			79.734,
			79.796,
			79.859,
			79.921,
			79.968,
			80.045,
			80.077,
			80.105,
			80.168,
			80.183,
			80.232,
			80.293,
			80.355,
			80.402,
			80.418,
			80.479,
			80.51,
			80.541,
			80.601,
			80.618,
			80.665,
			80.727,
			80.788,
			80.851,
			80.913,
			80.943,
			80.975,
			81.038,
			81.097,
			81.16,
			81.223,
			81.27,
			81.284,
			81.348,
			81.377,
			81.409,
			81.472,
			81.487,
			81.532,
			81.596,
			81.655,
			81.703,
			81.72,
			81.782,
			81.813,
			81.844,
			81.905,
			81.922,
			81.966,
			82.03,
			82.09,
			82.136,
			82.215,
			82.247,
			82.276,
			82.34,
			82.36,
			82.402,
			82.463,
			82.525,
			82.572,
			82.588,
			82.65,
			82.68,
			82.711,
			82.773,
			82.788,
			82.836,
			82.899,
			82.959,
			83.006,
			83.021,
			83.083,
			83.115,
			83.145,
			83.206,
			83.222,
			83.269,
			83.331,
			83.393,
			83.44,
			83.517,
			83.548,
			83.579,
			83.657,
			83.703,
			83.765,
			83.827,
			83.873,
			83.889,
			83.951,
			83.981,
			84.013,
			84.076,
			84.092,
			84.137,
			84.202,
			84.26,
			84.323,
			84.386,
			84.419,
			84.448,
			84.51,
			84.571,
			84.634,
			84.695,
			84.757,
			84.82,
			84.851,
			84.881,
			84.943,
			84.96,
			85.005,
			85.069,
			85.13,
			85.189,
			85.255,
			85.284,
			85.316,
			85.378,
			85.394,
			85.44,
			85.503,
			85.563,
			85.61,
			85.687,
			85.719,
			85.748,
			85.811,
			85.873,
			85.937,
			85.997,
			86.061,
			86.122,
			86.152,
			86.184,
			86.245,
			86.261,
			86.309,
			86.371,
			86.433,
			86.494,
			86.554,
			86.587,
			86.617,
			86.68,
			86.696,
			86.743,
			86.804,
			86.865,
			86.913,
			86.99,
			87.021,
			87.053,
			87.115,
			87.176,
			87.237,
			87.299,
			87.346,
			87.362,
			87.422,
			87.455,
			87.487,
			87.547,
			87.563,
			87.611,
			87.671,
			87.733,
			87.782,
			87.797,
			87.859,
			87.89,
			87.919,
			87.983,
			88.043,
			88.107,
			88.169,
			88.216,
			88.293,
			88.324,
			88.354,
			88.416,
			88.479,
			88.544,
			88.603,
			88.648,
			88.663,
			88.726,
			88.757,
			88.787,
			88.85,
			88.865,
			88.912,
			88.975,
			89.037,
			89.082,
			89.099,
			89.161,
			89.191,
			89.223,
			89.3,
			89.347,
			89.41,
			89.47,
			89.532,
			89.593,
			89.626,
			89.654,
			89.718,
			89.734,
			89.78,
			89.843,
			89.904,
			89.951,
			89.965,
			90.029,
			90.06,
			90.091,
			90.153,
			90.167,
			90.215,
			90.278,
			90.34,
			90.4,
			90.463,
			90.493,
			90.525,
			90.588,
			90.648,
			90.715,
			90.773,
			90.819,
			90.834,
			90.896,
			90.927,
			90.959,
			91.035,
			91.084,
			91.147,
			91.207,
			91.252,
			91.268,
			91.332,
			91.361,
			91.39,
			91.456,
			91.471,
			91.516,
			91.58,
			91.64,
			91.687,
			91.764,
			91.795,
			91.827,
			91.889,
			91.951,
			92.013,
			92.074,
			92.121,
			92.136,
			92.199,
			92.23,
			92.261,
			92.323,
			92.337,
			92.385,
			92.447,
			92.509,
			92.555,
			92.57,
			92.633,
			92.67,
			92.695,
			92.757,
			92.772,
			92.82,
			92.88,
			92.943,
			92.99,
			93.067,
			93.098,
			93.129,
			93.189,
			93.252,
			93.315,
			93.376,
			93.425,
			93.44,
			93.5,
			93.531,
			93.564,
			93.639,
			93.687,
			93.75,
			93.812,
			93.872,
			93.936,
			93.966,
			93.996,
			94.073,
			94.12,
			94.185,
			94.246,
			94.293,
			94.308,
			94.369,
			94.399,
			94.431,
			94.493,
			94.51,
			94.556,
			94.618,
			94.679,
			94.731,
			94.741,
			94.802,
			94.835,
			94.866,
			94.928,
			94.943,
			94.989,
			95.052,
			95.113,
			95.16,
			95.237,
			95.269,
			95.299,
			95.377,
			95.422,
			95.486,
			95.547,
			95.592,
			95.61,
			95.67,
			95.702,
			95.732,
			95.796,
			95.81,
			95.856,
			95.919,
			95.981,
			96.028,
			96.044,
			96.105,
			96.136,
			96.168,
			96.23,
			96.246,
			96.293,
			96.353,
			96.416,
			96.476,
			96.539,
			96.571,
			96.602,
			96.665,
			96.727,
			96.793,
			96.848,
			96.912,
			96.973,
			97.005,
			97.036,
			97.096,
			97.113,
			97.16,
			97.223,
			97.285,
			97.346,
			97.406,
			97.438,
			97.471,
			97.531,
			97.548,
			97.593,
			97.655,
			97.719,
			97.763,
			97.841,
			97.872,
			97.904,
			97.964,
			98.028,
			98.088,
			98.152,
			98.199,
			98.213,
			98.276,
			98.306,
			98.337,
			98.399,
			98.415,
			98.462,
			98.522,
			98.586,
			98.631,
			98.649,
			98.711,
			98.739,
			98.773,
			98.833,
			98.896,
			98.959,
			99.02,
			99.066,
			99.143,
			99.175,
			99.205,
			99.269,
			99.285,
			99.331,
			99.393,
			99.454,
			99.501,
			99.516,
			99.578,
			99.61,
			99.64,
			99.701,
			99.716,
			99.765,
			99.826,
			99.887,
			99.936,
			100.013,
			100.043,
			100.073,
			100.137,
			100.197,
			100.26,
			100.321,
			100.367,
			100.385,
			100.446,
			100.477,
			100.506,
			100.569,
			100.585,
			100.633,
			100.695,
			100.756,
			100.818,
			100.88,
			100.915,
			100.943,
			101.003,
			101.021,
			101.067,
			101.13,
			101.192,
			101.238,
			101.314,
			101.346,
			101.378,
			101.455,
			101.499,
			101.563,
			101.622,
			101.669,
			101.688,
			101.749,
			101.78,
			101.81,
			101.889,
			101.934,
			101.996,
			102.06,
			102.121,
			102.182,
			102.213,
			102.244,
			102.321,
			102.37,
			102.434,
			102.493,
			102.554,
			102.616,
			102.647,
			102.679,
			102.756,
			102.802,
			102.864,
			102.927,
			102.987,
			103.051,
			103.082,
			103.113,
			103.174,
			103.191,
			103.237,
			103.297,
			103.361,
			103.405,
			103.424,
			103.484,
			103.517,
			103.546,
			103.61,
			103.67,
			103.732,
			103.795,
			103.842,
			103.919,
			103.948,
			103.981,
			104.044,
			104.104,
			104.167,
			104.23,
			104.276,
			104.292,
			104.354,
			104.385,
			104.415,
			104.478,
			104.49,
			104.54,
			104.602,
			104.663,
			104.709,
			104.787,
			104.817,
			104.849,
			104.911,
			104.972,
			105.039,
			105.097,
			105.144,
			105.159,
			105.221,
			105.251,
			105.283,
			105.361,
			105.405,
			105.47,
			105.532,
			105.593,
			105.656,
			105.687,
			105.717,
			105.795,
			105.84,
			105.903,
			105.964,
			106.026,
			106.09,
			106.12,
			106.152,
			106.213,
			106.276,
			106.336,
			106.4,
			106.462,
			106.524,
			106.569,
			106.586,
			106.646,
			106.677,
			106.71,
			106.77,
			106.787,
			106.833,
			106.895,
			106.955,
			107.003,
			107.02,
			107.081,
			107.118,
			107.144,
			107.205,
			107.222,
			107.268,
			107.333,
			107.393,
			107.438,
			107.516,
			107.547,
			107.579,
			107.655,
			107.7,
			107.763,
			107.826,
			107.873,
			107.887,
			107.95,
			107.982,
			108.01,
			108.074,
			108.089,
			108.137,
			108.197,
			108.26,
			108.305,
			108.323,
			108.385,
			108.415,
			108.447,
			108.523,
			108.57,
			108.631,
			108.694,
			108.755,
			108.817,
			108.848,
			108.879,
			108.943,
			109.004,
			109.067,
			109.127,
			109.18,
			109.19,
			109.251,
			109.283,
			109.313,
			109.377,
			109.39,
			109.438,
			109.5,
			109.561,
			109.624,
			109.685,
			109.715,
			109.748,
			109.826,
			109.873,
			109.934,
			109.996,
			110.042,
			110.059,
			110.121,
			110.149,
			110.183,
			110.244,
			110.26,
			110.306,
			110.369,
			110.431,
			110.476,
			110.493,
			110.554,
			110.586,
			110.617,
			110.679,
			110.693,
			110.741,
			110.803,
			110.865,
			110.909,
			110.987,
			111.019,
			111.049,
			111.126,
			111.175,
			111.239,
			111.299,
			111.361,
			111.422,
			111.454,
			111.484,
			111.563,
			111.609,
			111.671,
			111.731,
			111.78,
			111.795,
			111.855,
			111.887,
			111.918,
			111.981,
			111.997,
			112.043,
			112.104,
			112.165,
			112.212,
			112.29,
			112.322,
			112.353,
			112.415,
			112.477,
			112.539,
			112.6,
			112.648,
			112.662,
			112.726,
			112.755,
			112.786,
			112.847,
			112.863,
			112.911,
			112.972,
			113.035,
			113.097,
			113.16,
			113.189,
			113.221,
			113.282,
			113.304,
			113.344,
			113.406,
			113.469,
			113.532,
			113.593,
			113.622,
			113.656,
			113.732,
			113.78,
			113.842,
			113.904,
			113.95,
			113.965,
			114.027,
			114.056,
			114.089,
			114.151,
			114.166,
			114.212,
			114.275,
			114.336,
			114.384,
			114.46,
			114.492,
			114.522,
			114.599,
			114.646,
			114.71,
			114.77,
			114.833,
			114.896,
			114.927,
			114.955,
			115.02,
			115.033,
			115.079,
			115.144,
			115.205,
			115.252,
			115.268,
			115.33,
			115.365,
			115.392,
			115.469,
			115.515,
			115.576,
			115.639,
			115.7,
			115.762,
			115.794,
			115.826,
			115.887,
			115.948,
			116.011,
			116.074,
			116.119,
			116.134,
			116.198,
			116.227,
			116.26,
			116.322,
			116.337,
			116.383,
			116.444,
			116.506,
			116.554,
			116.569,
			116.632,
			116.663,
			116.694,
			116.755,
			116.771,
			116.816,
			116.879,
			116.94,
			116.987,
			117.065,
			117.097,
			117.127,
			117.188,
			117.252,
			117.315,
			117.376,
			117.426,
			117.438,
			117.498,
			117.531,
			117.561,
			117.622,
			117.638,
			117.685,
			117.746,
			117.81,
			117.855,
			117.871,
			117.933,
			117.965,
			117.996,
			118.058,
			118.119,
			118.18,
			118.244,
			118.289,
			118.366,
			118.399,
			118.43,
			118.489,
			118.554,
			118.615,
			118.677,
			118.722,
			118.741,
			118.803,
			118.831,
			118.865,
			118.926,
			118.94,
			118.988,
			119.054,
			119.113,
			119.159,
			119.235,
			119.265,
			119.299,
			119.36,
			119.423,
			119.486,
			119.547,
			119.59,
			119.609,
			119.668,
			119.701,
			119.733,
			119.794,
			119.81,
			119.856,
			119.916,
			119.979,
			120.027,
			120.042,
			120.104,
			120.133,
			120.165,
			120.228,
			120.244,
			120.289,
			120.351,
			120.414,
			120.46,
			120.538,
			120.569,
			120.601,
			120.677,
			120.722,
			120.786,
			120.846,
			120.893,
			120.91,
			120.971,
			121.002,
			121.033,
			121.112,
			121.157,
			121.219,
			121.282,
			121.344,
			121.406,
			121.436,
			121.469,
			121.529,
			121.55,
			121.593,
			121.653,
			121.716,
			121.778,
			121.839,
			121.872,
			121.902,
			121.965,
			122.027,
			122.087,
			122.151,
			122.213,
			122.274,
			122.306,
			122.336,
			122.398,
			122.414,
			122.461,
			122.523,
			122.584,
			122.647,
			122.708,
			122.74,
			122.77,
			122.831,
			122.849,
			122.894,
			122.955,
			123.017,
			123.08,
			123.142,
			123.172,
			123.205,
			123.266,
			123.328,
			123.389,
			123.451,
			123.497,
			123.514,
			123.577,
			123.612,
			123.638,
			123.701,
			123.715,
			123.763,
			123.825,
			123.886,
			123.932,
			123.948,
			124.011,
			124.039,
			124.073,
			124.133,
			124.195,
			124.258,
			124.322,
			124.367,
			124.443,
			124.477,
			124.506,
			124.569,
			124.584,
			124.631,
			124.694,
			124.755,
			124.801,
			124.816,
			124.877,
			124.909,
			124.94,
			125.002,
			125.018,
			125.066,
			125.126,
			125.19,
			125.236,
			125.313,
			125.344,
			125.376,
			125.451,
			125.499,
			125.561,
			125.622,
			125.671,
			125.684,
			125.746,
			125.776,
			125.809,
			125.871,
			125.887,
			125.933,
			125.995,
			126.057,
			126.102,
			126.12,
			126.182,
			126.212,
			126.243,
			126.321,
			126.366,
			126.428,
			126.489,
			126.538,
			126.615,
			126.646,
			126.677,
			126.755,
			126.8,
			126.863,
			126.925,
			126.987,
			127.048,
			127.081,
			127.112,
			127.189,
			127.235,
			127.299,
			127.359,
			127.404,
			127.422,
			127.483,
			127.515,
			127.546,
			127.622,
			127.67,
			127.747,
			127.794,
			127.856,
			127.916,
			127.98,
			128.042,
			128.071,
			128.104,
			128.164,
			128.227,
			128.289,
			128.35,
			128.398,
			128.413,
			128.476,
			128.506,
			128.537,
			128.614,
			128.662,
			128.724,
			128.786,
			128.831,
			128.848,
			128.907,
			128.939,
			128.971,
			129.033,
			129.095,
			129.159,
			129.218,
			129.265,
			129.344,
			129.375,
			129.406,
			129.468,
			129.529,
			129.593,
			129.653,
			129.698,
			129.714,
			129.776,
			129.813,
			129.838,
			129.917,
			129.962,
			130.027,
			130.088,
			130.135,
			130.213,
			130.244,
			130.272,
			130.337,
			130.397,
			130.46,
			130.523,
			130.568,
			130.583,
			130.647,
			130.676,
			130.707,
			130.786,
			130.832,
			130.895,
			130.955,
			131.016,
			131.08,
			131.111,
			131.142,
			131.204,
			131.217,
			131.266,
			131.328,
			131.389,
			131.451,
			131.515,
			131.543,
			131.575,
			131.636,
			131.698,
			131.762,
			131.822,
			131.875,
			131.887,
			131.949,
			131.978,
			132.011,
			132.072,
			132.086,
			132.135,
			132.197,
			132.258,
			132.305,
			132.32,
			132.383,
			132.413,
			132.444,
			132.505,
			132.522,
			132.568,
			132.63,
			132.693,
			132.755,
			132.816,
			132.847,
			132.878,
			132.955,
			133.003,
			133.065,
			133.127,
			133.188,
			133.249,
			133.28,
			133.313,
			133.375,
			133.389,
			133.435,
			133.499,
			133.56,
			133.607,
			133.622,
			133.685,
			133.716,
			133.747,
			133.808,
			133.871,
			133.932,
			133.936,
			133.994,
			134.039,
			134.118,
			134.148,
			134.181,
			134.243,
			134.304,
			134.365,
			134.428,
			134.475,
			134.49,
			134.552,
			134.583,
			134.615,
			134.692,
			134.739,
			134.805,
			134.862,
			134.908,
			134.986,
			135.017,
			135.048,
			135.111,
			135.173,
			135.233,
			135.297,
			135.359,
			135.42,
			135.452,
			135.483,
			135.543,
			135.558,
			135.605,
			135.669,
			135.73,
			135.793,
			135.855,
			135.886,
			135.915,
			135.979,
			136.001,
			136.039,
			136.102,
			136.165,
			136.211,
			136.289,
			136.319,
			136.352,
			136.413,
			136.473,
			136.536,
			136.598,
			136.646,
			136.661,
			136.723,
			136.754,
			136.783,
			136.862,
			136.91,
			136.973,
			137.033,
			137.094,
			137.156,
			137.187,
			137.219,
			137.295,
			137.343,
			137.404,
			137.466,
			137.513,
			137.589,
			137.621,
			137.653,
			137.715,
			137.776,
			137.839,
			137.901,
			137.946,
			137.963,
			138.023,
			138.061,
			138.088,
			138.149,
			138.165,
			138.211,
			138.272,
			138.336,
			138.38,
			138.396,
			138.459,
			138.489,
			138.521,
			138.583,
			138.6,
			138.646,
			138.706,
			138.77,
			138.814,
			138.893,
			138.923,
			138.955,
			139.016,
			139.033,
			139.08,
			139.14,
			139.204,
			139.249,
			139.264,
			139.327,
			139.359,
			139.388,
			139.451,
			139.465,
			139.513,
			139.577,
			139.638,
			139.7,
			139.762,
			139.793,
			139.822,
			139.887,
			139.946,
			140.009,
			140.072,
			140.124,
			140.133,
			140.196,
			140.227,
			140.257,
			140.319,
			140.334,
			140.38,
			140.444,
			140.504,
			140.553,
			140.568,
			140.629,
			140.66,
			140.691,
			140.754,
			140.769,
			140.814,
			140.877,
			140.939,
			140.985,
			141.062,
			141.094,
			141.126,
			141.188,
			141.249,
			141.31,
			141.371,
			141.419,
			141.435,
			141.498,
			141.529,
			141.56,
			141.621,
			141.637,
			141.685,
			141.747,
			141.808,
			141.855,
			141.868,
			141.931,
			141.963,
			141.993,
			142.054,
			142.071,
			142.118,
			142.185,
			142.241,
			142.288,
			142.365,
			142.397,
			142.428,
			142.488,
			142.552,
			142.614,
			142.675,
			142.722,
			142.738,
			142.798,
			142.829,
			142.86,
			142.922,
			142.941,
			142.985,
			143.048,
			143.11,
			143.155,
			143.173,
			143.233,
			143.265,
			143.298,
			143.373,
			143.421,
			143.482,
			143.543,
			143.588,
			143.669,
			143.697,
			143.73,
			143.792,
			143.808,
			143.853,
			143.915,
			143.977,
			144.04,
			144.101,
			144.133,
			144.162,
			144.246,
			144.287,
			144.351,
			144.413,
			144.459,
			144.536,
			144.567,
			144.597,
			144.66,
			144.721,
			144.788,
			144.846,
			144.892,
			144.908,
			144.97,
			145.001,
			145.03,
			145.093,
			145.109,
			145.155,
			145.217,
			145.279,
			145.326,
			145.339,
			145.404,
			145.435,
			145.465,
			145.528,
			145.542,
			145.59,
			145.652,
			145.714,
			145.76,
			145.838,
			145.868,
			145.9,
			145.962,
			146.024,
			146.085,
			146.148,
			146.195,
			146.21,
			146.271,
			146.308,
			146.333,
			146.395,
			146.41,
			146.456,
			146.52,
			146.58,
			146.627,
			146.644,
			146.706,
			146.737,
			146.766,
			146.83,
			146.846,
			146.89,
			146.953,
			147.016,
			147.062,
			147.138,
			147.171,
			147.201,
			147.28,
			147.327,
			147.388,
			147.45,
			147.496,
			147.511,
			147.574,
			147.603,
			147.636,
			147.697,
			147.713,
			147.76,
			147.821,
			147.883,
			147.93,
			147.947,
			148.006,
			148.039,
			148.069,
			148.131,
			148.147,
			148.194,
			148.255,
			148.317,
			148.381,
			148.442,
			148.473,
			148.504,
			148.567,
			148.626,
			148.69,
			148.753,
			148.799,
			148.814,
			148.876,
			148.906,
			148.939,
			149.016,
			149.062,
			149.125,
			149.185,
			149.233,
			149.311,
			149.341,
			149.373,
			149.45,
			149.496,
			149.559,
			149.619,
			149.682,
			149.742,
			149.775,
			149.805,
			149.883,
			149.93,
			149.994,
			150.052,
			150.101,
			150.116,
			150.178,
			150.21,
			150.239,
			150.303,
			150.317,
			150.363,
			150.43,
			150.487,
			150.535,
			150.613,
			150.644,
			150.675,
			150.737,
			150.798,
			150.86,
			150.921,
			150.969,
			150.984,
			151.047,
			151.077,
			151.106,
			151.17,
			151.185,
			151.233,
			151.295,
			151.355,
			151.402,
			151.417,
			151.48,
			151.511,
			151.54,
			151.604,
			151.62,
			151.667,
			151.73,
			151.79,
			151.853,
			151.915,
			151.945,
			151.977,
			152.038,
			152.101,
			152.163,
			152.223,
			152.27,
			152.287,
			152.347,
			152.38,
			152.41,
			152.471,
			152.493,
			152.534,
			152.596,
			152.658,
			152.719,
			152.782,
			152.813,
			152.844,
			152.906,
			152.922,
			152.969,
			153.031,
			153.092,
			153.14,
			153.217,
			153.248,
			153.279,
			153.34,
			153.402,
			153.466,
			153.526,
			153.572,
			153.589,
			153.65,
			153.681,
			153.713,
			153.774,
			153.79,
			153.837,
			153.899,
			153.961,
			154.007,
			154.022,
			154.084,
			154.116,
			154.146,
			154.207,
			154.224,
			154.271,
			154.333,
			154.393,
			154.439,
			154.519,
			154.554,
			154.58,
			154.642,
			154.659,
			154.704,
			154.765,
			154.828,
			154.89,
			154.951,
			154.984,
			155.015,
			155.077,
			155.093,
			155.138,
			155.2,
			155.263,
			155.31,
			155.386,
			155.418,
			155.449,
			155.51,
			155.572,
			155.633,
			155.695,
			155.743,
			155.82,
			155.851,
			155.883,
			155.945,
			155.961,
			156.005,
			156.067,
			156.13,
			156.178,
			156.193,
			156.255,
			156.286,
			156.317,
			156.377,
			156.393,
			156.44,
			156.501,
			156.564,
			156.616,
			156.689,
			156.719,
			156.751,
			156.828,
			156.875,
			156.936,
			156.998,
			157.044,
			157.06,
			157.123,
			157.152,
			157.183,
			157.246,
			157.262,
			157.309,
			157.372,
			157.433,
			157.495,
			157.557,
			157.587,
			157.617,
			157.68,
			157.696,
			157.74,
			157.804,
			157.865,
			157.912,
			157.992,
			158.022,
			158.051,
			158.13,
			158.177,
			158.239,
			158.301,
			158.363,
			158.425,
			158.456,
			158.485,
			158.547,
			158.563,
			158.611,
			158.673,
			158.733,
			158.78,
			158.797,
			158.858,
			158.888,
			158.92,
			158.997,
			159.043,
			159.11,
			159.167,
			159.214,
			159.292,
			159.321,
			159.355,
			159.415,
			159.479,
			159.541,
			159.601,
			159.648,
			159.663,
			159.726,
			159.758,
			159.789,
			159.866,
			159.912,
			159.979,
			160.037,
			160.082,
			160.098,
			160.16,
			160.192,
			160.221,
			160.283,
			160.345,
			160.408,
			160.469,
			160.516,
			160.594,
			160.626,
			160.654,
			160.719,
			160.738,
			160.78,
			160.842,
			160.904,
			160.965,
			161.027,
			161.06,
			161.089,
			161.166,
			161.213,
			161.276,
			161.339,
			161.401,
			161.462,
			161.495,
			161.526,
			161.603,
			161.647,
			161.711,
			161.772,
			161.835,
			161.898,
			161.926,
			161.959,
			162.022,
			162.037,
			162.081,
			162.144,
			162.207,
			162.269,
			162.331,
			162.361,
			162.394,
			162.454,
			162.471,
			162.518,
			162.579,
			162.639,
			162.688,
			162.764,
			162.801,
			162.826,
			162.904,
			162.952,
			163.017,
			163.075,
			163.121,
			163.137,
			163.2,
			163.23,
			163.26,
			163.322,
			163.339,
			163.384,
			163.447,
			163.509,
			163.555,
			163.569,
			163.634,
			163.663,
			163.693,
			163.771,
			163.818,
			163.88,
			163.94,
			163.988,
			164.067,
			164.097,
			164.127,
			164.191,
			164.253,
			164.313,
			164.377,
			164.422,
			164.439,
			164.501,
			164.531,
			164.563,
			164.64,
			164.686,
			164.75,
			164.811,
			164.872,
			164.933,
			164.965,
			164.997,
			165.058,
			165.12,
			165.183,
			165.244,
			165.289,
			165.368,
			165.4,
			165.43,
			165.508,
			165.555,
			165.615,
			165.679,
			165.725,
			165.739,
			165.802,
			165.835,
			165.866,
			165.926,
			165.943,
			165.989,
			166.052,
			166.112,
			166.175,
			166.238,
			166.266,
			166.297,
			166.361,
			166.424,
			166.485,
			166.547,
			166.594,
			166.61,
			166.671,
			166.702,
			166.733,
			166.81,
			166.857,
			166.923,
			166.982,
			167.027,
			167.043,
			167.104,
			167.136,
			167.168,
			167.229,
			167.245,
			167.29,
			167.354,
			167.416,
			167.477,
			167.54,
			167.571,
			167.601,
			167.663,
			167.725,
			167.788,
			167.849,
			167.912,
			167.974,
			168.005,
			168.035,
			168.098,
			168.112,
			168.16,
			168.22,
			168.283,
			168.33,
			168.344,
			168.406,
			168.438,
			168.468,
			168.532,
			168.547,
			168.594,
			168.655,
			168.716,
			168.764,
			168.842,
			168.872,
			168.903,
			168.963,
			168.984,
			169.027,
			169.091,
			169.151,
			169.198,
			169.212,
			169.276,
			169.307,
			169.337,
			169.399,
			169.414,
			169.461,
			169.524,
			169.584,
			169.632,
			169.647,
			169.709,
			169.739,
			169.77,
			169.833,
			169.894,
			169.958,
			170.019,
			170.066,
			170.143,
			170.174,
			170.205,
			170.268,
			170.33,
			170.39,
			170.454,
			170.516,
			170.577,
			170.608,
			170.639,
			170.7,
			170.717,
			170.763,
			170.826,
			170.886,
			170.95,
			171.011,
			171.047,
			171.073,
			171.135,
			171.198,
			171.26,
			171.322,
			171.368,
			171.383,
			171.445,
			171.476,
			171.507,
			171.569,
			171.586,
			171.63,
			171.694,
			171.755,
			171.801,
			171.817,
			171.88,
			171.911,
			171.939,
			172.004,
			172.017,
			172.065,
			172.127,
			172.189,
			172.236,
			172.312,
			172.343,
			172.376,
			172.436,
			172.499,
			172.56,
			172.621,
			172.67,
			172.686,
			172.746,
			172.78,
			172.81,
			172.871,
			172.887,
			172.935,
			172.995,
			173.058,
			173.108,
			173.182,
			173.213,
			173.245,
			173.305,
			173.321,
			173.369,
			173.43,
			173.493,
			173.554,
			173.616,
			173.646,
			173.678,
			173.741,
			173.802,
			173.863,
			173.925,
			173.987,
			174.05,
			174.081,
			174.113,
			174.175,
			174.188,
			174.234,
			174.299,
			174.36,
			174.406,
			174.423,
			174.484,
			174.515,
			174.546,
			174.608,
			174.671,
			174.73,
			174.793,
			174.838,
			174.919,
			174.947,
			174.98,
			175.058,
			175.103,
			175.172,
			175.228,
			175.272,
			175.291,
			175.352,
			175.381,
			175.413,
			175.49,
			175.537,
			175.602,
			175.662,
			175.709,
			175.787,
			175.817,
			175.848,
			175.924,
			175.973,
			176.035,
			176.097,
			176.159,
			176.219,
			176.25,
			176.282,
			176.343,
			176.405,
			176.468,
			176.53,
			176.576,
			176.593,
			176.655,
			176.686,
			176.716,
			176.778,
			176.794,
			176.839,
			176.901,
			176.964,
			177.011,
			177.088,
			177.118,
			177.149,
			177.213,
			177.233,
			177.275,
			177.337,
			177.398,
			177.46,
			177.521,
			177.552,
			177.585,
			177.647,
			177.66,
			177.708,
			177.771,
			177.833,
			177.879,
			177.895,
			177.957,
			177.988,
			178.019,
			178.08,
			178.095,
			178.143,
			178.203,
			178.265,
			178.313,
			178.389,
			178.422,
			178.453,
			178.516,
			178.576,
			178.638,
			178.701,
			178.747,
			178.763,
			178.826,
			178.854,
			178.886,
			178.949,
			178.963,
			179.01,
			179.073,
			179.135,
			179.182,
			179.197,
			179.259,
			179.294,
			179.321,
			179.384,
			179.443,
			179.507,
			179.569,
			179.614,
			179.693,
			179.724,
			179.754,
			179.815,
			179.833,
			179.88,
			179.942,
			180.003,
			180.05,
			180.065,
			180.128,
			180.156,
			180.188,
			180.249,
			180.266,
			180.313,
			180.376,
			180.437,
			180.482,
			180.497,
			180.561,
			180.591,
			180.621,
			180.7,
			180.747,
			180.809,
			180.871,
			180.917,
			180.995,
			181.027,
			181.056,
			181.118,
			181.133,
			181.18,
			181.244,
			181.305,
			181.357,
			181.368,
			181.43,
			181.459,
			181.49,
			181.552,
			181.57,
			181.615,
			181.678,
			181.739,
			181.786,
			181.863,
			181.894,
			181.925,
			182.003,
			182.049,
			182.11,
			182.172,
			182.235,
			182.297,
			182.329,
			182.36,
			182.437,
			182.482,
			182.546,
			182.606,
			182.654,
			182.669,
			182.732,
			182.763,
			182.793,
			182.856,
			182.871,
			182.917,
			182.979,
			183.042,
			183.103,
			183.164,
			183.196,
			183.228,
			183.288,
			183.352,
			183.416,
			183.475,
			183.523,
			183.538,
			183.599,
			183.629,
			183.662,
			183.723,
			183.737,
			183.785,
			183.847,
			183.91,
			183.971,
			184.033,
			184.065,
			184.096,
			184.159,
			184.22,
			184.281,
			184.343,
			184.388,
			184.469,
			184.499,
			184.531,
			184.607,
			184.655,
			184.716,
			184.778,
			184.822,
			184.84,
			184.902,
			184.933,
			184.964,
			185.041,
			185.086,
			185.149,
			185.21,
			185.258,
			185.335,
			185.366,
			185.396,
			185.459,
			185.521,
			185.584,
			185.645,
			185.705,
			185.769,
			185.799,
			185.831,
			185.894,
			185.91,
			185.956,
			186.018,
			186.078,
			186.125,
			186.138,
			186.203,
			186.233,
			186.265,
			186.327,
			186.342,
			186.389,
			186.451,
			186.513,
			186.56,
			186.638,
			186.669,
			186.697,
			186.761,
			186.823,
			186.884,
			186.948,
			186.995,
			187.01,
			187.07,
			187.101,
			187.133,
			187.195,
			187.21,
			187.256,
			187.321,
			187.381,
			187.427,
			187.444,
			187.505,
			187.541,
			187.566,
			187.645,
			187.69,
			187.754,
			187.815,
			187.862,
			187.939,
			187.971,
			188.002,
			188.064,
			188.123,
			188.188,
			188.248,
			188.296,
			188.311,
			188.373,
			188.405,
			188.436,
			188.497,
			188.513,
			188.56,
			188.62,
			188.682,
			188.746,
			188.809,
			188.839,
			188.869,
			188.932,
			188.946,
			188.993,
			189.057,
			189.118,
			189.163,
			189.241,
			189.272,
			189.304,
			189.366,
			189.426,
			189.49,
			189.551,
			189.613,
			189.676,
			189.706,
			189.738,
			189.799,
			189.814,
			189.862,
			189.922,
			189.985,
			190.031,
			190.049,
			190.11,
			190.14,
			190.171,
			190.234,
			190.296,
			190.358,
			190.421,
			190.465,
			190.543,
			190.574,
			190.604,
			190.668,
			190.683,
			190.73,
			190.792,
			190.852,
			190.9,
			190.916,
			190.978,
			191.01,
			191.039,
			191.101,
			191.116,
			191.163,
			191.227,
			191.287,
			191.349,
			191.413,
			191.442,
			191.474,
			191.55,
			191.596,
			191.663,
			191.722,
			191.767,
			191.783,
			191.846,
			191.878,
			191.906,
			191.968,
			191.984,
			192.033,
			192.094,
			192.155,
			192.201,
			192.216,
			192.28,
			192.312,
			192.34,
			192.405,
			192.42,
			192.465,
			192.528,
			192.589,
			192.635,
			192.713,
			192.745,
			192.776,
			192.839,
			192.901,
			192.963,
			193.025,
			193.07,
			193.087,
			193.149,
			193.18,
			193.21,
			193.272,
			193.288,
			193.334,
			193.397,
			193.458,
			193.506,
			193.52,
			193.581,
			193.613,
			193.644,
			193.705,
			193.725,
			193.768,
			193.83,
			193.893,
			193.955,
			194.015,
			194.046,
			194.078,
			194.139,
			194.201,
			194.266,
			194.326,
			194.374,
			194.387,
			194.449,
			194.482,
			194.513,
			194.573,
			194.588,
			194.635,
			194.698,
			194.759,
			194.808,
			194.883,
			194.914,
			194.946,
			195.009,
			195.071,
			195.131,
			195.193,
			195.239,
			195.318,
			195.349,
			195.38,
			195.459,
			195.506,
			195.565,
			195.628,
			195.691,
			195.753,
			195.788,
			195.815,
			195.89,
			195.94,
			196,
			196.063,
			196.11,
			196.188,
			196.216,
			196.25,
			196.309,
			196.373,
			196.433,
			196.498,
			196.544,
			196.56,
			196.622,
			196.652,
			196.681,
			196.745,
			196.761,
			196.807,
			196.87,
			196.931,
			196.976,
			196.993,
			197.055,
			197.086,
			197.117,
			197.18,
			197.193,
			197.24,
			197.304,
			197.364,
			197.41,
			197.488,
			197.521,
			197.551,
			197.614,
			197.674,
			197.737,
			197.797,
			197.852,
			197.86,
			197.923,
			197.954,
			197.984,
			198.046,
			198.062,
			198.109,
			198.172,
			198.233,
			198.28,
			198.293,
			198.355,
			198.388,
			198.418,
			198.495,
			198.542,
			198.605,
			198.668,
			198.713,
			198.79,
			198.822,
			198.853,
			198.914,
			198.976,
			199.039,
			199.1,
			199.164,
			199.226,
			199.256,
			199.288,
			199.35,
			199.364,
			199.411,
			199.472,
			199.535,
			199.597,
			199.66,
			199.69,
			199.72,
			199.784,
			199.845,
			199.913,
			199.97,
			200.017,
			200.093,
			200.122,
			200.156,
			200.216,
			200.28,
			200.339,
			200.402,
			200.45,
			200.466,
			200.528,
			200.558,
			200.589,
			200.665,
			200.713,
			200.776,
			200.837,
			200.884,
			200.962,
			200.992,
			201.022,
			201.084,
			201.147,
			201.21,
			201.271,
			201.334,
			201.397,
			201.427,
			201.459,
			201.52,
			201.535,
			201.583,
			201.643,
			201.705,
			201.753,
			201.767,
			201.83,
			201.86,
			201.893,
			201.954,
			201.974,
			202.015,
			202.079,
			202.139,
			202.185,
			202.264,
			202.295,
			202.327,
			202.387,
			202.45,
			202.51,
			202.573,
			202.621,
			202.636,
			202.696,
			202.729,
			202.759,
			202.821,
			202.838,
			202.884,
			202.946,
			203.008,
			203.055,
			203.071,
			203.132,
			203.163,
			203.195,
			203.271,
			203.316,
			203.38,
			203.443,
			203.487,
			203.565,
			203.598,
			203.628,
			203.688,
			203.752,
			203.814,
			203.877,
			203.922,
			203.938,
			203.999,
			204.036,
			204.062,
			204.14,
			204.185,
			204.247,
			204.311,
			204.371,
			204.434,
			204.465,
			204.496,
			204.556,
			204.573,
			204.621,
			204.683,
			204.743,
			204.805,
			204.867,
			204.899,
			204.93,
			205.006,
			205.054,
			205.116,
			205.178,
			205.225,
			205.24,
			205.302,
			205.332,
			205.363,
			205.442,
			205.488,
			205.551,
			205.612,
			205.659,
			205.736,
			205.768,
			205.797,
			205.874,
			205.923,
			205.984,
			206.046,
			206.106,
			206.171,
			206.202,
			206.233,
			206.294,
			206.31,
			206.357,
			206.419,
			206.48,
			206.527,
			206.542,
			206.603,
			206.636,
			206.666,
			206.729,
			206.744,
			206.789,
			206.852,
			206.915,
			206.96,
			207.039,
			207.068,
			207.1,
			207.162,
			207.223,
			207.287,
			207.35,
			207.395,
			207.411,
			207.473,
			207.505,
			207.534,
			207.613,
			207.657,
			207.72,
			207.783,
			207.83,
			207.845,
			207.906,
			207.939,
			207.967,
			208.032,
			208.047,
			208.09,
			208.156,
			208.216,
			208.279,
			208.342,
			208.371,
			208.404,
			208.464,
			208.527,
			208.59,
			208.651,
			208.696,
			208.713,
			208.775,
			208.806,
			208.838,
			208.897,
			208.913,
			208.96,
			209.023,
			209.086,
			209.146,
			209.21,
			209.238,
			209.27,
			209.333,
			209.395,
			209.461,
			209.519,
			209.581,
			209.643,
			209.674,
			209.704,
			209.765,
			209.783,
			209.828,
			209.889,
			209.952,
			209.998,
			210.016,
			210.078,
			210.107,
			210.138,
			210.223,
			210.264,
			210.326,
			210.387,
			210.434,
			210.512,
			210.543,
			210.574,
			210.634,
			210.697,
			210.76,
			210.822,
			210.883,
			210.945,
			210.976,
			211.006,
			211.069,
			211.085,
			211.13,
			211.194,
			211.256,
			211.3,
			211.318,
			211.38,
			211.41,
			211.442,
			211.502,
			211.519,
			211.564,
			211.626,
			211.689,
			211.734,
			211.812,
			211.845,
			211.875,
			211.952,
			212,
			212.06,
			212.124,
			212.17,
			212.186,
			212.247,
			212.283,
			212.308,
			212.372,
			212.386,
			212.433,
			212.496,
			212.558,
			212.619,
			212.682,
			212.713,
			212.744,
			212.806,
			212.82,
			212.869,
			212.93,
			212.99,
			213.037,
			213.116,
			213.146,
			213.177,
			213.239,
			213.302,
			213.364,
			213.426,
			213.473,
			213.488,
			213.55,
			213.58,
			213.612,
			213.674,
			213.688,
			213.734,
			213.798,
			213.86,
			213.906,
			213.922,
			213.983,
			214.014,
			214.045,
			214.109,
			214.169,
			214.233,
			214.294,
			214.344,
			214.418,
			214.45,
			214.481,
			214.558,
			214.604,
			214.665,
			214.726,
			214.774,
			214.789,
			214.852,
			214.882,
			214.915,
			214.977,
			214.992,
			215.038,
			215.101,
			215.162,
			215.208,
			215.286,
			215.316,
			215.348,
			215.41,
			215.471,
			215.535,
			215.597,
			215.643,
			215.658,
			215.721,
			215.752,
			215.782,
			215.861,
			215.905,
			215.971,
			216.029,
			216.077,
			216.093,
			216.155,
			216.184,
			216.216,
			216.277,
			216.293,
			216.34,
			216.407,
			216.465,
			216.509,
			216.588,
			216.619,
			216.65,
			216.712,
			216.775,
			216.837,
			216.898,
			216.945,
			216.96,
			217.023,
			217.054,
			217.084,
			217.146,
			217.162,
			217.209,
			217.271,
			217.333,
			217.38,
			217.394,
			217.455,
			217.488,
			217.518,
			217.58,
			217.595,
			217.643,
			217.703,
			217.767,
			217.827,
			217.889,
			217.921,
			217.951,
			218.03,
			218.077,
			218.139,
			218.2,
			218.263,
			218.322,
			218.355,
			218.388,
			218.448,
			218.47,
			218.509,
			218.571,
			218.635,
			218.682,
			218.697,
			218.757,
			218.79,
			218.821,
			218.883,
			218.945,
			219.007,
			219.07,
			219.114,
			219.193,
			219.225,
			219.253,
			219.316,
			219.38,
			219.441,
			219.502,
			219.55,
			219.563,
			219.626,
			219.658,
			219.689,
			219.75,
			219.765,
			219.813,
			219.876,
			219.936,
			219.998,
			220.06,
			220.092,
			220.121,
			220.184,
			220.246,
			220.31,
			220.37,
			220.418,
			220.433,
			220.495,
			220.531,
			220.556,
			220.619,
			220.633,
			220.682,
			220.743,
			220.804,
			220.852,
			220.865,
			220.929,
			220.961,
			220.992,
			221.054,
			221.069,
			221.115,
			221.178,
			221.238,
			221.284,
			221.363,
			221.395,
			221.426,
			221.487,
			221.548,
			221.612,
			221.674,
			221.719,
			221.734,
			221.798,
			221.829,
			221.86,
			221.922,
			221.936,
			221.984,
			222.045,
			222.108,
			222.169,
			222.232,
			222.262,
			222.293,
			222.371,
			222.418,
			222.479,
			222.54,
			222.593,
			222.665,
			222.696,
			222.728,
			222.79,
			222.852,
			222.914,
			222.976,
			223.036,
			223.098,
			223.13,
			223.16,
			223.24,
			223.285,
			223.348,
			223.41,
			223.456,
			223.471,
			223.533,
			223.565,
			223.596,
			223.658,
			223.719,
			223.781,
			223.844,
			223.904,
			223.966,
			223.999,
			224.03,
			224.09,
			224.154,
			224.216,
			224.276,
			224.339,
			224.401,
			224.433,
			224.464,
			224.527,
			224.542,
			224.588,
			224.655,
			224.712,
			224.758,
			224.835,
			224.866,
			224.896,
			224.96,
			225.022,
			225.084,
			225.145,
			225.193,
			225.27,
			225.299,
			225.331,
			225.394,
			225.41,
			225.455,
			225.519,
			225.58,
			225.626,
			225.643,
			225.705,
			225.734,
			225.765,
			225.844,
			225.888,
			225.953,
			226.013,
			226.076,
			226.139,
			226.17,
			226.2,
			226.277,
			226.325,
			226.387,
			226.449,
			226.493,
			226.509,
			226.572,
			226.602,
			226.633,
			226.714,
			226.759,
			226.818,
			226.881,
			226.945,
			227.005,
			227.037,
			227.069,
			227.13,
			227.144,
			227.193,
			227.255,
			227.316,
			227.378,
			227.439,
			227.471,
			227.503,
			227.565,
			227.627,
			227.689,
			227.751,
			227.797,
			227.812,
			227.873,
			227.904,
			227.935,
			228.015,
			228.061,
			228.122,
			228.183,
			228.23,
			228.247,
			228.309,
			228.34,
			228.369,
			228.432,
			228.449,
			228.493,
			228.557,
			228.618,
			228.664,
			228.742,
			228.778,
			228.803,
			228.867,
			228.883,
			228.928,
			228.991,
			229.053,
			229.099,
			229.113,
			229.177,
			229.208,
			229.238,
			229.301,
			229.317,
			229.362,
			229.426,
			229.485,
			229.533,
			229.612,
			229.639,
			229.673,
			229.735,
			229.797,
			229.86,
			229.92,
			229.968,
			230.044,
			230.076,
			230.105,
			230.168,
			230.184,
			230.23,
			230.293,
			230.354,
			230.403,
			230.415,
			230.477,
			230.511,
			230.54,
			230.619,
			230.665,
			230.728,
			230.789,
			230.851,
			230.913,
			230.944,
			230.973,
			231.037,
			231.1,
			231.16,
			231.222,
			231.27,
			231.285,
			231.346,
			231.377,
			231.409,
			231.487,
			231.534,
			231.593,
			231.658,
			231.703,
			231.719,
			231.78,
			231.813,
			231.842,
			231.92,
			231.965,
			232.029,
			232.091,
			232.152,
			232.214,
			232.246,
			232.277,
			232.338,
			232.4,
			232.463,
			232.523,
			232.572,
			232.588,
			232.65,
			232.682,
			232.711,
			232.773,
			232.787,
			232.835,
			232.903,
			232.959,
			233.007,
			233.022,
			233.082,
			233.113,
			233.145,
			233.223,
			233.27,
			233.33,
			233.394,
			233.439,
			233.518,
			233.549,
			233.58,
			233.657,
			233.703,
			233.765,
			233.828,
			233.888,
			233.952,
			233.983,
			234.013,
			234.092,
			234.137,
			234.199,
			234.26,
			234.309,
			234.385,
			234.417,
			234.447,
			234.51,
			234.572,
			234.637,
			234.696,
			234.742,
			234.758,
			234.82,
			234.851,
			234.88,
			234.944,
			234.963,
			235.004,
			235.067,
			235.129,
			235.176,
			235.192,
			235.253,
			235.285,
			235.315,
			235.377,
			235.39,
			235.439,
			235.502,
			235.562,
			235.611,
			235.688,
			235.718,
			235.751,
			235.826,
			235.873,
			235.937,
			235.997,
			236.043,
			236.059,
			236.123,
			236.152,
			236.184,
			236.246,
			236.26,
			236.308,
			236.37,
			236.43,
			236.478,
			236.494,
			236.556,
			236.586,
			236.617,
			236.68,
			236.695,
			236.74,
			236.804,
			236.866,
			236.913,
			236.991,
			237.026,
			237.052,
			237.113,
			237.176,
			237.239,
			237.3,
			237.346,
			237.362,
			237.423,
			237.455,
			237.487,
			237.548,
			237.564,
			237.609,
			237.67,
			237.734,
			237.78,
			237.795,
			237.859,
			237.889,
			237.921,
			237.981,
			237.996,
			238.044,
			238.107,
			238.168,
			238.23,
			238.292,
			238.322,
			238.355,
			238.432,
			238.478,
			238.539,
			238.603,
			238.663,
			238.726,
			238.756,
			238.789,
			238.865,
			238.913,
			238.975,
			239.037,
			239.088,
			239.16,
			239.189,
			239.222,
			239.3,
			239.347,
			239.41,
			239.471,
			239.533,
			239.595,
			239.626,
			239.655,
			239.718,
			239.78,
			239.842,
			239.905,
			239.951,
			239.966,
			240.028,
			240.06,
			240.09,
			240.151,
			240.167,
			240.213,
			240.276,
			240.339,
			240.386,
			240.463,
			240.494,
			240.522,
			240.586,
			240.649,
			240.71,
			240.771,
			240.82,
			240.835,
			240.897,
			240.926,
			240.958,
			241.02,
			241.034,
			241.083,
			241.149,
			241.205,
			241.252,
			241.268,
			241.329,
			241.362,
			241.392,
			241.455,
			241.47,
			241.516,
			241.58,
			241.64,
			241.687,
			241.764,
			241.795,
			241.827,
			241.89,
			241.95,
			242.013,
			242.074,
			242.122,
			242.136,
			242.198,
			242.229,
			242.261,
			242.338,
			242.384,
			242.447,
			242.509,
			242.554,
			242.569,
			242.632,
			242.663,
			242.694,
			242.772,
			242.82,
			242.885,
			242.942,
			243.006,
			243.066,
			243.098,
			243.129,
			243.19,
			243.21,
			243.253,
			243.315,
			243.376,
			243.423,
			243.438,
			243.501,
			243.532,
			243.563,
			243.641,
			243.687,
			243.749,
			243.811,
			243.873,
			243.936,
			243.965,
			243.997,
			244.075,
			244.122,
			244.182,
			244.245,
			244.305,
			244.37,
			244.4,
			244.43,
			244.493,
			244.51,
			244.555,
			244.617,
			244.68,
			244.739,
			244.802,
			244.835,
			244.864,
			244.943,
			244.989,
			245.052,
			245.113,
			245.16,
			245.237,
			245.272,
			245.299,
			245.378,
			245.421,
			245.486,
			245.547,
			245.61,
			245.671,
			245.702,
			245.733,
			245.796,
			245.812,
			245.858,
			245.92,
			245.98,
			246.044,
			246.104,
			246.135,
			246.166,
			246.229,
			246.245,
			246.292,
			246.354,
			246.415,
			246.463,
			246.539,
			246.571,
			246.601,
			246.663,
			246.726,
			246.788,
			246.85,
			246.897,
			246.91,
			246.971,
			247.005,
			247.036,
			247.098,
			247.113,
			247.157,
			247.223,
			247.284,
			247.335,
			247.408,
			247.44,
			247.469,
			247.546,
			247.594,
			247.656,
			247.719,
			247.765,
			247.842,
			247.872,
			247.903,
			247.982,
			248.028,
			248.09,
			248.153,
			248.214,
			248.276,
			248.307,
			248.339,
			248.399,
			248.415,
			248.463,
			248.522,
			248.587,
			248.633,
			248.709,
			248.742,
			248.773,
			248.835,
			248.896,
			248.959,
			249.019,
			249.065,
			249.144,
			249.173,
			249.207,
			249.268,
			249.283,
			249.33,
			249.396,
			249.455,
			249.501,
			249.517,
			249.578,
			249.609,
			249.639,
			249.703,
			249.718,
			249.764,
			249.827,
			249.889,
			249.935,
			250.013,
			250.043,
			250.075,
			250.136,
			250.199,
			250.261,
			250.321,
			250.383,
			250.446,
			250.477,
			250.509,
			250.571,
			250.584,
			250.631,
			250.695,
			250.756,
			250.804,
			250.819,
			250.88,
			250.912,
			250.943,
			251.003,
			251.021,
			251.065,
			251.127,
			251.19,
			251.237,
			251.315,
			251.346,
			251.377,
			251.439,
			251.5,
			251.563,
			251.622,
			251.685,
			251.749,
			251.78,
			251.811,
			251.871,
			251.887,
			251.935,
			251.997,
			252.06,
			252.104,
			252.121,
			252.183,
			252.213,
			252.245,
			252.306,
			252.322,
			252.368,
			252.431,
			252.494,
			252.555,
			252.615,
			252.648,
			252.679,
			252.755,
			252.804,
			252.866,
			252.927,
			252.973,
			252.989,
			253.05,
			253.082,
			253.114,
			253.188,
			253.235,
			253.297,
			253.361,
			253.421,
			253.483,
			253.52,
			253.546,
			253.624,
			253.67,
			253.734,
			253.794,
			253.84,
			253.92,
			253.951,
			253.982,
			254.044,
			254.058,
			254.105,
			254.168,
			254.229,
			254.275,
			254.291,
			254.353,
			254.383,
			254.415,
			254.493,
			254.538,
			254.601,
			254.663,
			254.71,
			254.786,
			254.819,
			254.847,
			254.911,
			254.974,
			255.036,
			255.098,
			255.159,
			255.222,
			255.253,
			255.284,
			255.361,
			255.406,
			255.469,
			255.532,
			255.594,
			255.656,
			255.685,
			255.718,
			255.795,
			255.842,
			255.902,
			255.966,
			256.013,
			256.089,
			256.121,
			256.15,
			256.214,
			256.275,
			256.339,
			256.4,
			256.446,
			256.461,
			256.522,
			256.554,
			256.585,
			256.648,
			256.663,
			256.709,
			256.77,
			256.833,
			256.88,
			256.894,
			256.957,
			256.989,
			257.018,
			257.08,
			257.098,
			257.142,
			257.207,
			257.268,
			257.33,
			257.389,
			257.423,
			257.452,
			257.514,
			257.577,
			257.643,
			257.7,
			257.762,
			257.827,
			257.857,
			257.886,
			257.949,
			257.963,
			258.011,
			258.074,
			258.136,
			258.182,
			258.196,
			258.26,
			258.289,
			258.322,
			258.382,
			258.398,
			258.447,
			258.509,
			258.569,
			258.617,
			258.693,
			258.725,
			258.755,
			258.816,
			258.88,
			258.94,
			259.003,
			259.049,
			259.066,
			259.127,
			259.159,
			259.189,
			259.251,
			259.267,
			259.313,
			259.377,
			259.436,
			259.483,
			259.563,
			259.594,
			259.624,
			259.685,
			259.747,
			259.809,
			259.871,
			259.918,
			259.934,
			259.996,
			260.027,
			260.058,
			260.12,
			260.135,
			260.18,
			260.243,
			260.306,
			260.368,
			260.43,
			260.459,
			260.491,
			260.553,
			260.569,
			260.615,
			260.678,
			260.74,
			260.785,
			260.862,
			260.895,
			260.927,
			260.989,
			261.049,
			261.113,
			261.172,
			261.236,
			261.297,
			261.33,
			261.361,
			261.423,
			261.438,
			261.483,
			261.545,
			261.609,
			261.654,
			261.671,
			261.732,
			261.77,
			261.794,
			261.856,
			261.872,
			261.918,
			261.981,
			262.043,
			262.104,
			262.166,
			262.197,
			262.229,
			262.288,
			262.353,
			262.416,
			262.477,
			262.537,
			262.6,
			262.63,
			262.663,
			262.725,
			262.739,
			262.786,
			262.85,
			262.91,
			262.957,
			262.972,
			263.035,
			263.066,
			263.096,
			263.158,
			263.219,
			263.283,
			263.344,
			263.39,
			263.469,
			263.499,
			263.53,
			263.609,
			263.654,
			263.716,
			263.779,
			263.827,
			263.839,
			263.901,
			263.933,
			263.963,
			264.027,
			264.042,
			264.089,
			264.151,
			264.213,
			264.259,
			264.337,
			264.367,
			264.398,
			264.461,
			264.523,
			264.589,
			264.646,
			264.708,
			264.771,
			264.8,
			264.831,
			264.895,
			264.91,
			264.956,
			265.018,
			265.079,
			265.142,
			265.205,
			265.236,
			265.265,
			265.329,
			265.344,
			265.391,
			265.452,
			265.513,
			265.56,
			265.638,
			265.669,
			265.702,
			265.763,
			265.825,
			265.892,
			265.949,
			265.996,
			266.011,
			266.072,
			266.104,
			266.135,
			266.197,
			266.212,
			266.26,
			266.322,
			266.383,
			266.43,
			266.444,
			266.506,
			266.538,
			266.567,
			266.631,
			266.645,
			266.694,
			266.755,
			266.815,
			266.863,
			266.94,
			266.972,
			267.004,
			267.081,
			267.127,
			267.19,
			267.252,
			267.298,
			267.312,
			267.375,
			267.407,
			267.438,
			267.499,
			267.515,
			267.562,
			267.622,
			267.685,
			267.733,
			267.81,
			267.841,
			267.87,
			267.933,
			267.996,
			268.056,
			268.119,
			268.165,
			268.242,
			268.274,
			268.304,
			268.367,
			268.383,
			268.429,
			268.493,
			268.553,
			268.598,
			268.614,
			268.678,
			268.706,
			268.739,
			268.802,
			268.815,
			268.864,
			268.927,
			268.988,
			269.032,
			269.049,
			269.112,
			269.143,
			269.172,
			269.235,
			269.298,
			269.361,
			269.421,
			269.467,
			269.483,
			269.545,
			269.576,
			269.606,
			269.668,
			269.685,
			269.732,
			269.794,
			269.854,
			269.901,
			269.918,
			269.981,
			270.015,
			270.04,
			270.119,
			270.166,
			270.227,
			270.29,
			270.352,
			270.413,
			270.444,
			270.476,
			270.536,
			270.599,
			270.66,
			270.723,
			270.786,
			270.847,
			270.88,
			270.91,
			270.972,
			270.986,
			271.033,
			271.096,
			271.158,
			271.204,
			271.219,
			271.282,
			271.312,
			271.345,
			271.407,
			271.42,
			271.466,
			271.53,
			271.593,
			271.637,
			271.715,
			271.746,
			271.777,
			271.839,
			271.902,
			271.963,
			272.026,
			272.089,
			272.15,
			272.18,
			272.21,
			272.274,
			272.29,
			272.336,
			272.398,
			272.46,
			272.505,
			272.522,
			272.582,
			272.614,
			272.645,
			272.708,
			272.771,
			272.831,
			272.895,
			272.941,
			273.019,
			273.05,
			273.081,
			273.143,
			273.204,
			273.267,
			273.329,
			273.376,
			273.389,
			273.452,
			273.484,
			273.515,
			273.576,
			273.593,
			273.638,
			273.703,
			273.761,
			273.809,
			273.886,
			273.917,
			273.949,
			274.01,
			274.073,
			274.136,
			274.197,
			274.244,
			274.259,
			274.32,
			274.351,
			274.383,
			274.443,
			274.46,
			274.506,
			274.568,
			274.629,
			274.678,
			274.692,
			274.753,
			274.785,
			274.817,
			274.877,
			274.895,
			274.939,
			275.008,
			275.064,
			275.124,
			275.189,
			275.218,
			275.251,
			275.313,
			275.375,
			275.438,
			275.498,
			275.543,
			275.56,
			275.621,
			275.653,
			275.686,
			275.763,
			275.809,
			275.87,
			275.931,
			275.98,
			275.996,
			276.056,
			276.088,
			276.12,
			276.18,
			276.201,
			276.243,
			276.305,
			276.368,
			276.413,
			276.489,
			276.522,
			276.554,
			276.615,
			276.677,
			276.739,
			276.8,
			276.848,
			276.862,
			276.925,
			276.955,
			276.986,
			277.051,
			277.065,
			277.112,
			277.172,
			277.235,
			277.28,
			277.296,
			277.357,
			277.391,
			277.419,
			277.498,
			277.545,
			277.607,
			277.668,
			277.716,
			277.794,
			277.825,
			277.855,
			277.915,
			277.979,
			278.042,
			278.102,
			278.148,
			278.165,
			278.227,
			278.263,
			278.29,
			278.351,
			278.367,
			278.414,
			278.477,
			278.538,
			278.582,
			278.599,
			278.661,
			278.693,
			278.724,
			278.8,
			278.847,
			278.907,
			278.972,
			279.032,
			279.094,
			279.126,
			279.158,
			279.219,
			279.235,
			279.28,
			279.344,
			279.404,
			279.452,
			279.467,
			279.529,
			279.56,
			279.592,
			279.668,
			279.716,
			279.778,
			279.84,
			279.885,
			279.962,
			279.995,
			280.027,
			280.086,
			280.148,
			280.212,
			280.273,
			280.326,
			280.335,
			280.398,
			280.427,
			280.46,
			280.522,
			280.536,
			280.583,
			280.649,
			280.705,
			280.768,
			280.83,
			280.862,
			280.893,
			280.956,
			280.972,
			281.018,
			281.08,
			281.142,
			281.189,
			281.266,
			281.296,
			281.327,
			281.39,
			281.452,
			281.519,
			281.575,
			281.638,
			281.7,
			281.73,
			281.763,
			281.823,
			281.84,
			281.885,
			281.948,
			282.01,
			282.056,
			282.073,
			282.134,
			282.164,
			282.197,
			282.256,
			282.273,
			282.319,
			282.385,
			282.444,
			282.489,
			282.568,
			282.598,
			282.63,
			282.69,
			282.753,
			282.816,
			282.878,
			282.925,
			282.939,
			283.002,
			283.033,
			283.063,
			283.127,
			283.142,
			283.187,
			283.249,
			283.312,
			283.358,
			283.434,
			283.465,
			283.498,
			283.56,
			283.621,
			283.685,
			283.746,
			283.808,
			283.869,
			283.901,
			283.932,
			283.994,
			284.055,
			284.117,
			284.18,
			284.227,
			284.24,
			284.303,
			284.336,
			284.365,
			284.429,
			284.449,
			284.49,
			284.552,
			284.613,
			284.661,
			284.738,
			284.769,
			284.8,
			284.863,
			284.925,
			284.987,
			285.047,
			285.095,
			285.11,
			285.173,
			285.204,
			285.233,
			285.296,
			285.312,
			285.356,
			285.421,
			285.483,
			285.529,
			285.545,
			285.605,
			285.636,
			285.668,
			285.746,
			285.792,
			285.854,
			285.915,
			285.963,
			286.039,
			286.071,
			286.103,
			286.165,
			286.227,
			286.29,
			286.35,
			286.412,
			286.475,
			286.511,
			286.537,
			286.599,
			286.613,
			286.661,
			286.722,
			286.786,
			286.83,
			286.847,
			286.907,
			286.94,
			286.971,
			287.031,
			287.048,
			287.093,
			287.155,
			287.219,
			287.266,
			287.341,
			287.372,
			287.405,
			287.483,
			287.53,
			287.589,
			287.654,
			287.714,
			287.778,
			287.806,
			287.839,
			287.901,
			287.915,
			287.964,
			288.025,
			288.087,
			288.134,
			288.211,
			288.242,
			288.272,
			288.336,
			288.396,
			288.457,
			288.522,
			288.573,
			288.583,
			288.646,
			288.676,
			288.706,
			288.768,
			288.785,
			288.829,
			288.893,
			288.955,
			289.001,
			289.016,
			289.079,
			289.108,
			289.142,
			289.202,
			289.217,
			289.263,
			289.331,
			289.389,
			289.435,
			289.512,
			289.543,
			289.575,
			289.653,
			289.698,
			289.762,
			289.821,
			289.87,
			289.886,
			289.947,
			289.977,
			290.01,
			290.071,
			290.087,
			290.132,
			290.199,
			290.256,
			290.303,
			290.32,
			290.381,
			290.413,
			290.444,
			290.506,
			290.522,
			290.567,
			290.632,
			290.691,
			290.736,
			290.815,
			290.847,
			290.878,
			290.939,
			291,
			291.063,
			291.125,
			291.172,
			291.188,
			291.248,
			291.28,
			291.312,
			291.389,
			291.435,
			291.498,
			291.56,
			291.606,
			291.622,
			291.682,
			291.713,
			291.746,
			291.808,
			291.87,
			291.93,
			291.992,
			292.041,
			292.116,
			292.148,
			292.18,
			292.258,
			292.304,
			292.365,
			292.427,
			292.473,
			292.489,
			292.552,
			292.583,
			292.612,
			292.676,
			292.695,
			292.737,
			292.801,
			292.862,
			292.924,
			292.986,
			293.018,
			293.048,
			293.11,
			293.171,
			293.233,
			293.295,
			293.342,
			293.418,
			293.45,
			293.48,
			293.545,
			293.56,
			293.606,
			293.667,
			293.731,
			293.776,
			293.792,
			293.854,
			293.886,
			293.915,
			293.977,
			293.993,
			294.038,
			294.101,
			294.162,
			294.225,
			294.288,
			294.318,
			294.349,
			294.427,
			294.473,
			294.537,
			294.598,
			294.644,
			294.66,
			294.722,
			294.758,
			294.783,
			294.845,
			294.86,
			294.909,
			294.971,
			295.03,
			295.094,
			295.156,
			295.186,
			295.217,
			295.296,
			295.34,
			295.404,
			295.465,
			295.529,
			295.589,
			295.622,
			295.653,
			295.714,
			295.776,
			295.837,
			295.899,
			295.947,
			295.963,
			296.024,
			296.056,
			296.086,
			296.147,
			296.163,
			296.211,
			296.272,
			296.335,
			296.397,
			296.456,
			296.489,
			296.52,
			296.583,
			296.644,
			296.706,
			296.768,
			296.83,
			296.893,
			296.922,
			296.955,
			297.015,
			297.078,
			297.14,
			297.202,
			297.248,
			297.263,
			297.326,
			297.356,
			297.388,
			297.449,
			297.466,
			297.513,
			297.572,
			297.638,
			297.683,
			297.761,
			297.79,
			297.824,
			297.883,
			297.946,
			298.009,
			298.069,
			298.133,
			298.194,
			298.226,
			298.256,
			298.319,
			298.335,
			298.381,
			298.444,
			298.505,
			298.55,
			298.565,
			298.628,
			298.661,
			298.69,
			298.754,
			298.768,
			298.816,
			298.881,
			298.94,
			298.986,
			299.063,
			299.094,
			299.124,
			299.188,
			299.249,
			299.31,
			299.373,
			299.42,
			299.434,
			299.496,
			299.527,
			299.559,
			299.637,
			299.681,
			299.745,
			299.807,
			299.854,
			299.869,
			299.931,
			299.963,
			299.994,
			300.055,
			300.069,
			300.116,
			300.18,
			300.242,
			300.304,
			300.365,
			300.395,
			300.427,
			300.488,
			300.551,
			300.613,
			300.673,
			300.722,
			300.736,
			300.798,
			300.83,
			300.861,
			300.922,
			300.945,
			300.986,
			301.047,
			301.109,
			301.155,
			301.172,
			301.234,
			301.264,
			301.295,
			301.358,
			301.419,
			301.48,
			301.543,
			301.589,
			301.666,
			301.697,
			301.729,
			301.79,
			301.854,
			301.916,
			301.978,
			302.025,
			302.04,
			302.1,
			302.133,
			302.163,
			302.224,
			302.239,
			302.286,
			302.35,
			302.411,
			302.459,
			302.536,
			302.565,
			302.596,
			302.66,
			302.721,
			302.782,
			302.845,
			302.892,
			302.968,
			303.004,
			303.03,
			303.094,
			303.155,
			303.216,
			303.28,
			303.326,
			303.342,
			303.404,
			303.435,
			303.465,
			303.543,
			303.588,
			303.651,
			303.713,
			303.774,
			303.837,
			303.869,
			303.899,
			303.978,
			304.023,
			304.085,
			304.147,
			304.194,
			304.21,
			304.271,
			304.303,
			304.335,
			304.412,
			304.456,
			304.521,
			304.581,
			304.628,
			304.645,
			304.706,
			304.737,
			304.768,
			304.83,
			304.844,
			304.893,
			304.955,
			305.017,
			305.065,
			305.138,
			305.17,
			305.202,
			305.28,
			305.325,
			305.393,
			305.449,
			305.497,
			305.51,
			305.572,
			305.605,
			305.635,
			305.698,
			305.713,
			305.761,
			305.823,
			305.883,
			305.931,
			305.947,
			306.006,
			306.039,
			306.07,
			306.147,
			306.194,
			306.255,
			306.318,
			306.365,
			306.442,
			306.472,
			306.504,
			306.564,
			306.627,
			306.69,
			306.75,
			306.797,
			306.813,
			306.876,
			306.906,
			306.937,
			307.017,
			307.063,
			307.131,
			307.186,
			307.234,
			307.31,
			307.342,
			307.371,
			307.435,
			307.497,
			307.56,
			307.62,
			307.682,
			307.745,
			307.775,
			307.806,
			307.869,
			307.884,
			307.93,
			307.992,
			308.055,
			308.102,
			308.117,
			308.179,
			308.209,
			308.242,
			308.302,
			308.319,
			308.365,
			308.427,
			308.488,
			308.535,
			308.612,
			308.642,
			308.674,
			308.737,
			308.798,
			308.86,
			308.923,
			308.97,
			308.985,
			309.046,
			309.076,
			309.107,
			309.171,
			309.192,
			309.233,
			309.295,
			309.355,
			309.403,
			309.418,
			309.48,
			309.51,
			309.543,
			309.605,
			309.621,
			309.666,
			309.729,
			309.79,
			309.837,
			309.914,
			309.945,
			309.978,
			310.039,
			310.102,
			310.163,
			310.226,
			310.271,
			310.286,
			310.347,
			310.38,
			310.41,
			310.471,
			310.489,
			310.535,
			310.598,
			310.659,
			310.72,
			310.783,
			310.813,
			310.845,
			310.921,
			310.97,
			311.031,
			311.093,
			311.139,
			311.217,
			311.251,
			311.279,
			311.355,
			311.402,
			311.464,
			311.527,
			311.573,
			311.588,
			311.65,
			311.682,
			311.712,
			311.791,
			311.838,
			311.898,
			311.96,
			312.007,
			312.022,
			312.085,
			312.115,
			312.147,
			312.208,
			312.27,
			312.333,
			312.396,
			312.443,
			312.518,
			312.551,
			312.581,
			312.643,
			312.66,
			312.705,
			312.768,
			312.83,
			312.877,
			312.89,
			312.952,
			312.985,
			313.016,
			313.092,
			313.14,
			313.201,
			313.263,
			313.314,
			313.387,
			313.419,
			313.449,
			313.51,
			313.573,
			313.635,
			313.697,
			313.743,
			313.76,
			313.82,
			313.852,
			313.883,
			313.945,
			313.96,
			314.007,
			314.07,
			314.13,
			314.178,
			314.193,
			314.255,
			314.286,
			314.317,
			314.395,
			314.442,
			314.504,
			314.564,
			314.628,
			314.69,
			314.721,
			314.751,
			314.814,
			314.876,
			314.94,
			314.998,
			315.062,
			315.123,
			315.155,
			315.186,
			315.247,
			315.263,
			315.31,
			315.378,
			315.435,
			315.496,
			315.558,
			315.589,
			315.621,
			315.699,
			315.744,
			315.805,
			315.869,
			315.913,
			315.991,
			316.023,
			316.054,
			316.115,
			316.176,
			316.239,
			316.302,
			316.364,
			316.425,
			316.456,
			316.488,
			316.551,
			316.565,
			316.61,
			316.673,
			316.735,
			316.783,
			316.86,
			316.892,
			316.923,
			316.984,
			317.047,
			317.108,
			317.168,
			317.217,
			317.292,
			317.323,
			317.356,
			317.416,
			317.439,
			317.479,
			317.542,
			317.604,
			317.651,
			317.664,
			317.727,
			317.76,
			317.789,
			317.851,
			317.866,
			317.915,
			317.976,
			318.038,
			318.086,
			318.162,
			318.193,
			318.222,
			318.302,
			318.349,
			318.41,
			318.471,
			318.519,
			318.534,
			318.596,
			318.628,
			318.658,
			318.735,
			318.781,
			318.844,
			318.907,
			318.952,
			318.967,
			319.03,
			319.062,
			319.093,
			319.155,
			319.171,
			319.215,
			319.277,
			319.339,
			319.403,
			319.465,
			319.499,
			319.527,
			319.588,
			319.65,
			319.713,
			319.775,
			319.822,
			319.837,
			319.898,
			319.93,
			319.96,
			320.022,
			320.038,
			320.083,
			320.147,
			320.209,
			320.253,
			320.27,
			320.332,
			320.363,
			320.395,
			320.472,
			320.518,
			320.581,
			320.643,
			320.69,
			320.765,
			320.798,
			320.829,
			320.892,
			320.953,
			321.014,
			321.077,
			321.124,
			321.139,
			321.201,
			321.232,
			321.263,
			321.34,
			321.386,
			321.447,
			321.511,
			321.561,
			321.635,
			321.665,
			321.696,
			321.759,
			321.821,
			321.883,
			321.945,
			322.005,
			322.07,
			322.1,
			322.129,
			322.193,
			322.209,
			322.256,
			322.317,
			322.378,
			322.426,
			322.439,
			322.504,
			322.532,
			322.565,
			322.64,
			322.689,
			322.751,
			322.813,
			322.86,
			322.938,
			322.968,
			322.998,
			323.062,
			323.122,
			323.188,
			323.247,
			323.293,
			323.31,
			323.371,
			323.401,
			323.434,
			323.495,
			323.51,
			323.556,
			323.623,
			323.68,
			323.743,
			323.805,
			323.836,
			323.867,
			323.929,
			323.944,
			323.989,
			324.052,
			324.116,
			324.163,
			324.238,
			324.271,
			324.302,
			324.362,
			324.426,
			324.488,
			324.55,
			324.597,
			324.612,
			324.671,
			324.703,
			324.735,
			324.797,
			324.814,
			324.859,
			324.922,
			324.982,
			325.031,
			325.046,
			325.106,
			325.138,
			325.17,
			325.23,
			325.247,
			325.293,
			325.356,
			325.416,
			325.463,
			325.542,
			325.571,
			325.602,
			325.685,
			325.728,
			325.789,
			325.852,
			325.897,
			325.913,
			325.974,
			326.005,
			326.038,
			326.099,
			326.115,
			326.162,
			326.224,
			326.284,
			326.347,
			326.409,
			326.441,
			326.47,
			326.533,
			326.596,
			326.655,
			326.72,
			326.765,
			326.844,
			326.875,
			326.905,
			326.968,
			326.983,
			327.031,
			327.092,
			327.152,
			327.2,
			327.215,
			327.279,
			327.306,
			327.34,
			327.4,
			327.418,
			327.464,
			327.526,
			327.588,
			327.634,
			327.711,
			327.748,
			327.773,
			327.835,
			327.897,
			327.961,
			328.021,
			328.069,
			328.084,
			328.146,
			328.176,
			328.206,
			328.27,
			328.285,
			328.331,
			328.393,
			328.457,
			328.502,
			328.517,
			328.579,
			328.611,
			328.643,
			328.704,
			328.719,
			328.765,
			328.828,
			328.891,
			328.936,
			329.014,
			329.045,
			329.077,
			329.154,
			329.201,
			329.26,
			329.324,
			329.37,
			329.385,
			329.449,
			329.479,
			329.51,
			329.587,
			329.635,
			329.698,
			329.758,
			329.806,
			329.82,
			329.883,
			329.913,
			329.945,
			330.022,
			330.068,
			330.131,
			330.193,
			330.241,
			330.315,
			330.348,
			330.377,
			330.455,
			330.502,
			330.565,
			330.627,
			330.672,
			330.688,
			330.75,
			330.78,
			330.813,
			330.891,
			330.935,
			330.999,
			331.061,
			331.108,
			331.184,
			331.215,
			331.247,
			331.309,
			331.37,
			331.432,
			331.495,
			331.555,
			331.619,
			331.649,
			331.68,
			331.758,
			331.804,
			331.873,
			331.928,
			331.976,
			331.99,
			332.052,
			332.083,
			332.113,
			332.177,
			332.193,
			332.238,
			332.3,
			332.362,
			332.409,
			332.487,
			332.519,
			332.547,
			332.61,
			332.674,
			332.735,
			332.797,
			332.843,
			332.858,
			332.922,
			332.95,
			332.982,
			333.045,
			333.061,
			333.106,
			333.17,
			333.23,
			333.278,
			333.293,
			333.353,
			333.386,
			333.416,
			333.48,
			333.495,
			333.539,
			333.603,
			333.666,
			333.71,
			333.789,
			333.821,
			333.852,
			333.913,
			333.933,
			333.975,
			334.038,
			334.097,
			334.146,
			334.16,
			334.223,
			334.253,
			334.285,
			334.363,
			334.409,
			334.472,
			334.533,
			334.595,
			334.656,
			334.689,
			334.719,
			334.779,
			334.796,
			334.844,
			334.904,
			334.965,
			335.013,
			335.092,
			335.123,
			335.152,
			335.216,
			335.278,
			335.339,
			335.4,
			335.447,
			335.464,
			335.523,
			335.556,
			335.588,
			335.666,
			335.712,
			335.774,
			335.835,
			335.896,
			335.959,
			335.995,
			336.021,
			336.084,
			336.145,
			336.206,
			336.269,
			336.317,
			336.393,
			336.425,
			336.456,
			336.518,
			336.533,
			336.58,
			336.641,
			336.704,
			336.748,
			336.764,
			336.828,
			336.859,
			336.889,
			336.951,
			336.967,
			337.014,
			337.076,
			337.136,
			337.185,
			337.262,
			337.293,
			337.325,
			337.401,
			337.448,
			337.509,
			337.572,
			337.616,
			337.634,
			337.697,
			337.727,
			337.756,
			337.834,
			337.882,
			337.944,
			338.005,
			338.068,
			338.13,
			338.16,
			338.19,
			338.255,
			338.27,
			338.316,
			338.377,
			338.438,
			338.5,
			338.563,
			338.594,
			338.627,
			338.688,
			338.75,
			338.813,
			338.872,
			338.921,
			338.936,
			338.999,
			339.029,
			339.06,
			339.12,
			339.137,
			339.183,
			339.247,
			339.308,
			339.355,
			339.371,
			339.431,
			339.463,
			339.493,
			339.571,
			339.619,
			339.68,
			339.743,
			339.788,
			339.865,
			339.897,
			339.929,
			339.991,
			340.053,
			340.119,
			340.176,
			340.223,
			340.238,
			340.301,
			340.331,
			340.363,
			340.422,
			340.438,
			340.485,
			340.549,
			340.61,
			340.655,
			340.673,
			340.735,
			340.764,
			340.796,
			340.859,
			340.92,
			340.982,
			341.045,
			341.089,
			341.169,
			341.198,
			341.229,
			341.292,
			341.308,
			341.354,
			341.417,
			341.478,
			341.539,
			341.603,
			341.634,
			341.664,
			341.727,
			341.74,
			341.788,
			341.85,
			341.913,
			341.958,
			342.037,
			342.067,
			342.098,
			342.16,
			342.221,
			342.285,
			342.347,
			342.393,
			342.408,
			342.469,
			342.501,
			342.533,
			342.595,
			342.61,
			342.656,
			342.717,
			342.78,
			342.827,
			342.843,
			342.904,
			342.936,
			342.965,
			343.045,
			343.089,
			343.154,
			343.215,
			343.278,
			343.337,
			343.369,
			343.401,
			343.462,
			343.523,
			343.586,
			343.649,
			343.696,
			343.711,
			343.773,
			343.802,
			343.835,
			343.912,
			343.958,
			344.021,
			344.082,
			344.13,
			344.145,
			344.206,
			344.24,
			344.268,
			344.33,
			344.347,
			344.394,
			344.456,
			344.515,
			344.58,
			344.639,
			344.672,
			344.702,
			344.765,
			344.827,
			344.888,
			344.95,
			344.997,
			345.013,
			345.076,
			345.107,
			345.136,
			345.199,
			345.214,
			345.26,
			345.324,
			345.385,
			345.446,
			345.509,
			345.54,
			345.572,
			345.633,
			345.695,
			345.76,
			345.818,
			345.864,
			345.942,
			345.973,
			346.005,
			346.067,
			346.083,
			346.129,
			346.19,
			346.253,
			346.304,
			346.315,
			346.378,
			346.408,
			346.438,
			346.502,
			346.517,
			346.562,
			346.623,
			346.688,
			346.732,
			346.75,
			346.812,
			346.842,
			346.871,
			346.935,
			346.997,
			347.06,
			347.122,
			347.166,
			347.184,
			347.245,
			347.276,
			347.306,
			347.368,
			347.385,
			347.431,
			347.494,
			347.555,
			347.602,
			347.616,
			347.679,
			347.711,
			347.742,
			347.804,
			347.819,
			347.865,
			347.928,
			347.99,
			348.052,
			348.113,
			348.145,
			348.176,
			348.238,
			348.3,
			348.365,
			348.423,
			348.471,
			348.485,
			348.546,
			348.579,
			348.609,
			348.67,
			348.686,
			348.734,
			348.797,
			348.856,
			348.903,
			348.919,
			348.982,
			349.013,
			349.044,
			349.105,
			349.12,
			349.166,
			349.23,
			349.292,
			349.338,
			349.417,
			349.446,
			349.477,
			349.54,
			349.6,
			349.664,
			349.726,
			349.772,
			349.787,
			349.85,
			349.881,
			349.91,
			349.974,
			349.99,
			350.035,
			350.097,
			350.16,
			350.206,
			350.222,
			350.283,
			350.315,
			350.345,
			350.408,
			350.47,
			350.531,
			350.594,
			350.638,
			350.719,
			350.747,
			350.78,
			350.842,
			350.904,
			350.967,
			351.028,
			351.075,
			351.089,
			351.152,
			351.182,
			351.214,
			351.274,
			351.292,
			351.338,
			351.399,
			351.462,
			351.509,
			351.585,
			351.618,
			351.648,
			351.71,
			351.773,
			351.835,
			351.895,
			351.94,
			351.958,
			352.018,
			352.051,
			352.082,
			352.145,
			352.16,
			352.205,
			352.269,
			352.33,
			352.376,
			352.393,
			352.454,
			352.49,
			352.517,
			352.577,
			352.593,
			352.64,
			352.704,
			352.765,
			352.811,
			352.889,
			352.92,
			352.949,
			353.013,
			353.073,
			353.137,
			353.197,
			353.244,
			353.261,
			353.323,
			353.354,
			353.384,
			353.449,
			353.462,
			353.507,
			353.57,
			353.632,
			353.678,
			353.693,
			353.755,
			353.786,
			353.819,
			353.88,
			353.895,
			353.943,
			354.004,
			354.066,
			354.113,
			354.19,
			354.22,
			354.252,
			354.33,
			354.376,
			354.437,
			354.5,
			354.562,
			354.623,
			354.656,
			354.687,
			354.747,
			354.763,
			354.81,
			354.873,
			354.935,
			354.98,
			354.996,
			355.058,
			355.09,
			355.12,
			355.198,
			355.245,
			355.305,
			355.369,
			355.414,
			355.493,
			355.523,
			355.555,
			355.632,
			355.677,
			355.741,
			355.803,
			355.848,
			355.864,
			355.926,
			355.958,
			355.989,
			356.05,
			356.065,
			356.112,
			356.18,
			356.238,
			356.282,
			356.297,
			356.36,
			356.392,
			356.423,
			356.485,
			356.547,
			356.609,
			356.67,
			356.717,
			356.795,
			356.826,
			356.855,
			356.92,
			356.935,
			356.982,
			357.044,
			357.104,
			357.152,
			357.165,
			357.229,
			357.259,
			357.291,
			357.353,
			357.369,
			357.415,
			357.478,
			357.537,
			357.585,
			357.663,
			357.694,
			357.726,
			357.788,
			357.847,
			357.909,
			357.973,
			358.02,
			358.036,
			358.096,
			358.128,
			358.16,
			358.22,
			358.237,
			358.283,
			358.345,
			358.408,
			358.452,
			358.47,
			358.53,
			358.563,
			358.594,
			358.656,
			358.677,
			358.716,
			358.779,
			358.841,
			358.902,
			358.966,
			358.996,
			359.026,
			359.106,
			359.15,
			359.213,
			359.276,
			359.322,
			359.337,
			359.399,
			359.431,
			359.462,
			359.538,
			359.585,
			359.646,
			359.71,
			359.755,
			359.77,
			359.832,
			359.863,
			359.896,
			359.958,
			360.02,
			360.081,
			360.142,
			360.189,
			360.269,
			360.298,
			360.33,
			360.405,
			360.452,
			360.517,
			360.578,
			360.623,
			360.639,
			360.703,
			360.739,
			360.765,
			360.826,
			360.841,
			360.888,
			360.95,
			361.013,
			361.056,
			361.072,
			361.135,
			361.165,
			361.197,
			361.26,
			361.322,
			361.382,
			361.444,
			361.494,
			361.509,
			361.569,
			361.602,
			361.633,
			361.694,
			361.756,
			361.817,
			361.879,
			361.927,
			361.942,
			362.003,
			362.036,
			362.066,
			362.126,
			362.144,
			362.189,
			362.252,
			362.313,
			362.359,
			362.438,
			362.469,
			362.501,
			362.578,
			362.624,
			362.685,
			362.747,
			362.798,
			362.809,
			362.872,
			362.904,
			362.934,
			362.995,
			363.012,
			363.059,
			363.118,
			363.181,
			363.227,
			363.244,
			363.306,
			363.337,
			363.368,
			363.429,
			363.446,
			363.492,
			363.555,
			363.616,
			363.662,
			363.738,
			363.772,
			363.802,
			363.865,
			363.926,
			363.988,
			364.049,
			364.097,
			364.113,
			364.172,
			364.206,
			364.237,
			364.298,
			364.313,
			364.36,
			364.423,
			364.484,
			364.53,
			364.547,
			364.606,
			364.639,
			364.671,
			364.732,
			364.746,
			364.793,
			364.86,
			364.919,
			364.964,
			365.043,
			365.073,
			365.105,
			365.166,
			365.183,
			365.227,
			365.288,
			365.352,
			365.398,
			365.415,
			365.476,
			365.509,
			365.538,
			365.599,
			365.617,
			365.663,
			365.723,
			365.785,
			365.834,
			365.909,
			365.94,
			365.973,
			366.035,
			366.096,
			366.16,
			366.221,
			366.268,
			366.344,
			366.376,
			366.405,
			366.468,
			366.483,
			366.53,
			366.592,
			366.654,
			366.701,
			366.715,
			366.778,
			366.809,
			366.84,
			366.923,
			366.965,
			367.027,
			367.089,
			367.136,
			367.213,
			367.244,
			367.276,
			367.353,
			367.397,
			367.46,
			367.523,
			367.586,
			367.647,
			367.677,
			367.709,
			367.787,
			367.833,
			367.894,
			367.955,
			368.004,
			368.019,
			368.081,
			368.112,
			368.144,
			368.22,
			368.265,
			368.331,
			368.389,
			368.437,
			368.514,
			368.546,
			368.578,
			368.638,
			368.7,
			368.763,
			368.826,
			368.87,
			368.885,
			368.947,
			368.985,
			369.012,
			369.089,
			369.135,
			369.198,
			369.26,
			369.322,
			369.382,
			369.413,
			369.445,
			369.523,
			369.57,
			369.63,
			369.694,
			369.755,
			369.817,
			369.847,
			369.879,
			369.941,
			370.004,
			370.065,
			370.127,
			370.19,
			370.252,
			370.283,
			370.313,
			370.391,
			370.436,
			370.501,
			370.561,
			370.607,
			370.623,
			370.685,
			370.715,
			370.748,
			370.81,
			370.871,
			370.934,
			370.994,
			371.046,
			371.119,
			371.15,
			371.18,
			371.243,
			371.259,
			371.305,
			371.368,
			371.43,
			371.475,
			371.492,
			371.552,
			371.585,
			371.616,
			371.677,
			371.694,
			371.741,
			371.802,
			371.863,
			371.91,
			371.987,
			372.017,
			372.05,
			372.112,
			372.172,
			372.237,
			372.297,
			372.344,
			372.36,
			372.42,
			372.452,
			372.484,
			372.546,
			372.56,
			372.606,
			372.67,
			372.733,
			372.793,
			372.856,
			372.886,
			372.916,
			372.98,
			372.995,
			373.04,
			373.11,
			373.164,
			373.213,
			373.289,
			373.319,
			373.353,
			373.43,
			373.476,
			373.539,
			373.6,
			373.647,
			373.663,
			373.723,
			373.755,
			373.785,
			373.847,
			373.863,
			373.909,
			373.973,
			374.035,
			374.081,
			374.096,
			374.159,
			374.188,
			374.219,
			374.283,
			374.344,
			374.405,
			374.469,
			374.514,
			374.59,
			374.623,
			374.652,
			374.716,
			374.779,
			374.84,
			374.9,
			374.947,
			374.965,
			375.027,
			375.058,
			375.088,
			375.172,
			375.213,
			375.272,
			375.336,
			375.383,
			375.397,
			375.461,
			375.49,
			375.523,
			375.584,
			375.647,
			375.707,
			375.77,
			375.816,
			375.895,
			375.925,
			375.957,
			376.034,
			376.081,
			376.142,
			376.205,
			376.25,
			376.266,
			376.328,
			376.359,
			376.391,
			376.466,
			376.513,
			376.577,
			376.638,
			376.685,
			376.763,
			376.793,
			376.823,
			376.886,
			376.948,
			377.01,
			377.073,
			377.119,
			377.134,
			377.196,
			377.23,
			377.258,
			377.32,
			377.335,
			377.381,
			377.445,
			377.507,
			377.552,
			377.568,
			377.631,
			377.66,
			377.694,
			377.755,
			377.771,
			377.817,
			377.877,
			377.941,
			377.988,
			378.064,
			378.096,
			378.127,
			378.19,
			378.25,
			378.313,
			378.376,
			378.421,
			378.437,
			378.5,
			378.531,
			378.56,
			378.639,
			378.685,
			378.747,
			378.809,
			378.872,
			378.932,
			378.964,
			378.994,
			379.057,
			379.072,
			379.12,
			379.181,
			379.244,
			379.305,
			379.368,
			379.397,
			379.429,
			379.505,
			379.552,
			379.616,
			379.678,
			379.724,
			379.739,
			379.8,
			379.833,
			379.863,
			379.94,
			379.987,
			380.049,
			380.111,
			380.156,
			380.173,
			380.235,
			380.265,
			380.296,
			380.359,
			380.42,
			380.482,
			380.545,
			380.59,
			380.608,
			380.668,
			380.7,
			380.73,
			380.793,
			380.809,
			380.855,
			380.918,
			380.98,
			381.026,
			381.042,
			381.102,
			381.135,
			381.166,
			381.227,
			381.244,
			381.288,
			381.352,
			381.414,
			381.46,
			381.536,
			381.568,
			381.6,
			381.662,
			381.724,
			381.785,
			381.847,
			381.91,
			381.97,
			382.002,
			382.033,
			382.094,
			382.11,
			382.158,
			382.218,
			382.28,
			382.329,
			382.342,
			382.405,
			382.437,
			382.468,
			382.529,
			382.546,
			382.589,
			382.653,
			382.715,
			382.778,
			382.84,
			382.871,
			382.901,
			382.964,
			383.025,
			383.088,
			383.15,
			383.211,
			383.272,
			383.304,
			383.335,
			383.416,
			383.46,
			383.522,
			383.583,
			383.63,
			383.646,
			383.706,
			383.737,
			383.768,
			383.832,
			383.847,
			383.893,
			383.955,
			384.018,
			384.065,
			384.142,
			384.173,
			384.202,
			384.267,
			384.326,
			384.393,
			384.451,
			384.497,
			384.513,
			384.576,
			384.605,
			384.639,
			384.699,
			384.714,
			384.761,
			384.823,
			384.885,
			384.933,
			385.009,
			385.042,
			385.071,
			385.134,
			385.197,
			385.259,
			385.32,
			385.381,
			385.445,
			385.507,
			385.568,
			385.583,
			385.63,
			385.692,
			385.754,
			385.815,
			385.877,
			385.91,
			385.938,
			386,
			386.016,
			386.063,
			386.127,
			386.188,
			386.235,
			386.312,
			386.343,
			386.372,
			386.437,
			386.498,
			386.56,
			386.622,
			386.669,
			386.685,
			386.746,
			386.778,
			386.809,
			386.871,
			386.885,
			386.933,
			386.998,
			387.055,
			387.101,
			387.119,
			387.18,
			387.211,
			387.243,
			387.303,
			387.319,
			387.365,
			387.427,
			387.489,
			387.553,
			387.613,
			387.645,
			387.676,
			387.754,
			387.801,
			387.863,
			387.925,
			387.969,
			387.987,
			388.049,
			388.08,
			388.111,
			388.171,
			388.186,
			388.235,
			388.297,
			388.359,
			388.42,
			388.483,
			388.515,
			388.543,
			388.622,
			388.667,
			388.731,
			388.793,
			388.853,
			388.916,
			388.948,
			388.979,
			389.041,
			389.104,
			389.165,
			389.227,
			389.273,
			389.288,
			389.349,
			389.381,
			389.412,
			389.476,
			389.489,
			389.537,
			389.597,
			389.661,
			389.722,
			389.785,
			389.815,
			389.847,
			389.91,
			389.969,
			390.038,
			390.094,
			390.142,
			390.218,
			390.248,
			390.281,
			390.343,
			390.358,
			390.405,
			390.468,
			390.529,
			390.575,
			390.591,
			390.653,
			390.685,
			390.715,
			390.793,
			390.838,
			390.906,
			390.963,
			391.009,
			391.086,
			391.118,
			391.149,
			391.211,
			391.272,
			391.335,
			391.397,
			391.459,
			391.519,
			391.552,
			391.581,
			391.643,
			391.666,
			391.706,
			391.767,
			391.83,
			391.893,
			391.956,
			391.986,
			392.017,
			392.08,
			392.093,
			392.142,
			392.204,
			392.266,
			392.312,
			392.389,
			392.419,
			392.452,
			392.513,
			392.576,
			392.637,
			392.698,
			392.747,
			392.762,
			392.823,
			392.854,
			392.885,
			392.947,
			392.963,
			393.009,
			393.072,
			393.134,
			393.179,
			393.196,
			393.255,
			393.289,
			393.32,
			393.396,
			393.442,
			393.506,
			393.568,
			393.613,
			393.692,
			393.726,
			393.753,
			393.815,
			393.876,
			393.939,
			394.002,
			394.063,
			394.126,
			394.156,
			394.187,
			394.25,
			394.266,
			394.31,
			394.373,
			394.435,
			394.497,
			394.56,
			394.589,
			394.621,
			394.683,
			394.745,
			394.809,
			394.868,
			394.915,
			394.992,
			395.025,
			395.055,
			395.118,
			395.133,
			395.18,
			395.24,
			395.305,
			395.35,
			395.367,
			395.428,
			395.456,
			395.489,
			395.568,
			395.613,
			395.676,
			395.737,
			395.8,
			395.86,
			395.893,
			395.923,
			395.986,
			396.047,
			396.11,
			396.172,
			396.218,
			396.234,
			396.296,
			396.327,
			396.358,
			396.42,
			396.436,
			396.483,
			396.544,
			396.607,
			396.668,
			396.73,
			396.762,
			396.792,
			396.855,
			396.87,
			396.915,
			396.977,
			397.04,
			397.087,
			397.163,
			397.196,
			397.226,
			397.287,
			397.35,
			397.412,
			397.472,
			397.52,
			397.536,
			397.598,
			397.627,
			397.659,
			397.722,
			397.737,
			397.785,
			397.85,
			397.909,
			397.955,
			397.97,
			398.032,
			398.063,
			398.093,
			398.155,
			398.172,
			398.219,
			398.28,
			398.343,
			398.388,
			398.465,
			398.498,
			398.53,
			398.591,
			398.653,
			398.714,
			398.776,
			398.823,
			398.838,
			398.901,
			398.932,
			398.963,
			399.023,
			399.039,
			399.085,
			399.148,
			399.21,
			399.255,
			399.271,
			399.335,
			399.364,
			399.396,
			399.457,
			399.472,
			399.521,
			399.581,
			399.645,
			399.692,
			399.769,
			399.799,
			399.83,
			399.893,
			399.913,
			399.955,
			400.016,
			400.077,
			400.123,
			400.141,
			400.201,
			400.232,
			400.265,
			400.327,
			400.34,
			400.388,
			400.45,
			400.513,
			400.558,
			400.635,
			400.667,
			400.698,
			400.759,
			400.823,
			400.885,
			400.948,
			401.009,
			401.07,
			401.102,
			401.132,
			401.193,
			401.211,
			401.255,
			401.318,
			401.38,
			401.426,
			401.443,
			401.506,
			401.536,
			401.568,
			401.63,
			401.645,
			401.69,
			401.753,
			401.814,
			401.861,
			401.937,
			401.973,
			402,
			402.064,
			402.126,
			402.188,
			402.248,
			402.296,
			402.311,
			402.373,
			402.402,
			402.435,
			402.498,
			402.513,
			402.56,
			402.622,
			402.683,
			402.73,
			402.746,
			402.807,
			402.839,
			402.868,
			402.93,
			402.946,
			402.994,
			403.056,
			403.116,
			403.178,
			403.24,
			403.271,
			403.304,
			403.365,
			403.426,
			403.488,
			403.55,
			403.614,
			403.675,
			403.706,
			403.738,
			403.798,
			403.813,
			403.86,
			403.921,
			403.984,
			404.048,
			404.11,
			404.14,
			404.171,
			404.249,
			404.295,
			404.362,
			404.419,
			404.465,
			404.543,
			404.575,
			404.604,
			404.666,
			404.73,
			404.792,
			404.854,
			404.9,
			404.914,
			404.977,
			405.009,
			405.04,
			405.1,
			405.116,
			405.163,
			405.226,
			405.288,
			405.349,
			405.412,
			405.442,
			405.473,
			405.536,
			405.598,
			405.659,
			405.722,
			405.766,
			405.784,
			405.843,
			405.874,
			405.907,
			405.97,
			405.985,
			406.033,
			406.1,
			406.155,
			406.201,
			406.218,
			406.28,
			406.311,
			406.34,
			406.419,
			406.466,
			406.528,
			406.588,
			406.65,
			406.712,
			406.744,
			406.773,
			406.838,
			406.899,
			406.963,
			407.023,
			407.07,
			407.086,
			407.148,
			407.18,
			407.21,
			407.27,
			407.287,
			407.335,
			407.396,
			407.459,
			407.505,
			407.521,
			407.58,
			407.613,
			407.645,
			407.721,
			407.768,
			407.83,
			407.892,
			407.938,
			408.015,
			408.047,
			408.077,
			408.138,
			408.159,
			408.201,
			408.263,
			408.325,
			408.371,
			408.386,
			408.448,
			408.479,
			408.513,
			408.572,
			408.589,
			408.637,
			408.698,
			408.76,
			408.805,
			408.823,
			408.885,
			408.915,
			408.945,
			409.022,
			409.071,
			409.13,
			409.194,
			409.256,
			409.319,
			409.349,
			409.381,
			409.456,
			409.505,
			409.566,
			409.627,
			409.674,
			409.691,
			409.753,
			409.784,
			409.813,
			409.892,
			409.939,
			410,
			410.063,
			410.109,
			410.185,
			410.221,
			410.247,
			410.309,
			410.372,
			410.434,
			410.497,
			410.543,
			410.559,
			410.621,
			410.651,
			410.681,
			410.745,
			410.76,
			410.806,
			410.868,
			410.93,
			410.978,
			410.992,
			411.053,
			411.085,
			411.116,
			411.179,
			411.195,
			411.239,
			411.301,
			411.365,
			411.412,
			411.488,
			411.52,
			411.55,
			411.613,
			411.676,
			411.735,
			411.798,
			411.845,
			411.859,
			411.921,
			411.952,
			411.984,
			412.047,
			412.062,
			412.11,
			412.171,
			412.232,
			412.293,
			412.356,
			412.387,
			412.419,
			412.496,
			412.541,
			412.607,
			412.665,
			412.714,
			412.791,
			412.822,
			412.852,
			412.916,
			412.976,
			413.039,
			413.102,
			413.163,
			413.225,
			413.254,
			413.288,
			413.35,
			413.365,
			413.411,
			413.472,
			413.535,
			413.58,
			413.596,
			413.66,
			413.691,
			413.721,
			413.782,
			413.797,
			413.845,
			413.905,
			413.969,
			414.016,
			414.094,
			414.125,
			414.156,
			414.233,
			414.277,
			414.345,
			414.404,
			414.45,
			414.465,
			414.528,
			414.558,
			414.588,
			414.665,
			414.713,
			414.775,
			414.837,
			414.882,
			414.899,
			414.961,
			414.993,
			415.022,
			415.085,
			415.147,
			415.208,
			415.271,
			415.316,
			415.334,
			415.394,
			415.426,
			415.458,
			415.535,
			415.58,
			415.643,
			415.706,
			415.753,
			415.768,
			415.83,
			415.86,
			415.89,
			415.969,
			416.015,
			416.077,
			416.14,
			416.185,
			416.263,
			416.292,
			416.325,
			416.387,
			416.45,
			416.516,
			416.573,
			416.619,
			416.635,
			416.697,
			416.727,
			416.76,
			416.82,
			416.837,
			416.883,
			416.946,
			417.006,
			417.053,
			417.07,
			417.132,
			417.162,
			417.194,
			417.255,
			417.27,
			417.318,
			417.38,
			417.44,
			417.487,
			417.564,
			417.596,
			417.628,
			417.688,
			417.75,
			417.813,
			417.876,
			417.922,
			417.938,
			418,
			418.029,
			418.061,
			418.122,
			418.14,
			418.185,
			418.247,
			418.31,
			418.356,
			418.372,
			418.434,
			418.469,
			418.497,
			418.558,
			418.619,
			418.683,
			418.744,
			418.789,
			418.868,
			418.897,
			418.93,
			418.992,
			419.008,
			419.053,
			419.116,
			419.178,
			419.225,
			419.24,
			419.302,
			419.331,
			419.363,
			419.425,
			419.44,
			419.487,
			419.549,
			419.611,
			419.659,
			419.735,
			419.766,
			419.798,
			419.859,
			419.922,
			419.985,
			420.046,
			420.093,
			420.109,
			420.17,
			420.201,
			420.232,
			420.293,
			420.311,
			420.354,
			420.418,
			420.48,
			420.54,
			420.602,
			420.635,
			420.667,
			420.728,
			420.744,
			420.788,
			420.853,
			420.915,
			420.96,
			421.038,
			421.068,
			421.1,
			421.163,
			421.223,
			421.288,
			421.347,
			421.395,
			421.41,
			421.473,
			421.504,
			421.535,
			421.61,
			421.658,
			421.721,
			421.783,
			421.845,
			421.906,
			421.937,
			421.968,
			422.046,
			422.092,
			422.155,
			422.217,
			422.263,
			422.34,
			422.37,
			422.402,
			422.463,
			422.526,
			422.592,
			422.651,
			422.697,
			422.713,
			422.774,
			422.806,
			422.836,
			422.898,
			422.913,
			422.961,
			423.027,
			423.085,
			423.146,
			423.209,
			423.241,
			423.271,
			423.332,
			423.347,
			423.393,
			423.455,
			423.519,
			423.566,
			423.644,
			423.675,
			423.704,
			423.765,
			423.783,
			423.829,
			423.889,
			423.952,
			424,
			424.015,
			424.076,
			424.108,
			424.138,
			424.2,
			424.215,
			424.263,
			424.326,
			424.386,
			424.433,
			424.511,
			424.542,
			424.574,
			424.635,
			424.696,
			424.757,
			424.821,
			424.868,
			424.883,
			424.943,
			424.976,
			425.007,
			425.069,
			425.085,
			425.13,
			425.192,
			425.254,
			425.302,
			425.318,
			425.38,
			425.41,
			425.44,
			425.504,
			425.518,
			425.563,
			425.626,
			425.689,
			425.735,
			425.813,
			425.843,
			425.876,
			425.953,
			426,
			426.06,
			426.123,
			426.17,
			426.184,
			426.248,
			426.277,
			426.309,
			426.371,
			426.387,
			426.433,
			426.495,
			426.558,
			426.62,
			426.681,
			426.744,
			426.806,
			426.821,
			426.867,
			426.93,
			426.99,
			427.037,
			427.114,
			427.147,
			427.176,
			427.24,
			427.301,
			427.363,
			427.426,
			427.472,
			427.488,
			427.55,
			427.58,
			427.611,
			427.672,
			427.688,
			427.736,
			427.798,
			427.86,
			427.905,
			427.922,
			427.983,
			428.015,
			428.045,
			428.106,
			428.123,
			428.17,
			428.231,
			428.293,
			428.339,
			428.418,
			428.447,
			428.48,
			428.54,
			428.604,
			428.665,
			428.727,
			428.788,
			428.851,
			428.883,
			428.913,
			428.976,
			428.991,
			429.037,
			429.099,
			429.161,
			429.208,
			429.286,
			429.315,
			429.349,
			429.407,
			429.426,
			429.473,
			429.535,
			429.595,
			429.659,
			429.721,
			429.751,
			429.78,
			429.843,
			429.905,
			429.967,
			430.03,
			430.076,
			430.092,
			430.153,
			430.186,
			430.215,
			430.277,
			430.294,
			430.34,
			430.402,
			430.463,
			430.527,
			430.588,
			430.62,
			430.65,
			430.711,
			430.773,
			430.84,
			430.898,
			430.96,
			431.022,
			431.052,
			431.085,
			431.146,
			431.162,
			431.208,
			431.271,
			431.331,
			431.378,
			431.393,
			431.455,
			431.486,
			431.518,
			431.596,
			431.642,
			431.704,
			431.765,
			431.812,
			431.889,
			431.92,
			431.952,
			432.013,
			432.076,
			432.137,
			432.199,
			432.263,
			432.325,
			432.354,
			432.387,
			432.464,
			432.51,
			432.573,
			432.635,
			432.697,
			432.758,
			432.79,
			432.821,
			432.882,
			432.902,
			432.943,
			433.007,
			433.069,
			433.13,
			433.193,
			433.223,
			433.255,
			433.315,
			433.379,
			433.44,
			433.503,
			433.55,
			433.565,
			433.626,
			433.657,
			433.689,
			433.751,
			433.767,
			433.812,
			433.875,
			433.936,
			433.997,
			434.061,
			434.089,
			434.123,
			434.185,
			434.247,
			434.309,
			434.37,
			434.417,
			434.493,
			434.526,
			434.554,
			434.618,
			434.635,
			434.68,
			434.743,
			434.804,
			434.85,
			434.866,
			434.929,
			434.964,
			434.991,
			435.053,
			435.068,
			435.113,
			435.176,
			435.239,
			435.286,
			435.363,
			435.393,
			435.426,
			435.487,
			435.547,
			435.61,
			435.673,
			435.72,
			435.735,
			435.796,
			435.826,
			435.86,
			435.92,
			435.936,
			435.984,
			436.045,
			436.106,
			436.152,
			436.167,
			436.231,
			436.26,
			436.293,
			436.371,
			436.417,
			436.48,
			436.541,
			436.604,
			436.664,
			436.696,
			436.727,
			436.788,
			436.851,
			436.914,
			436.976,
			437.026,
			437.036,
			437.099,
			437.131,
			437.161,
			437.223,
			437.237,
			437.285,
			437.347,
			437.409,
			437.472,
			437.533,
			437.563,
			437.596,
			437.655,
			437.671,
			437.718,
			437.78,
			437.843,
			437.889,
			437.966,
			437.998,
			438.03,
			438.09,
			438.153,
			438.215,
			438.277,
			438.34,
			438.4,
			438.432,
			438.463,
			438.526,
			438.539,
			438.588,
			438.65,
			438.712,
			438.758,
			438.834,
			438.865,
			438.898,
			438.976,
			439.022,
			439.087,
			439.145,
			439.207,
			439.269,
			439.301,
			439.331,
			439.392,
			439.409,
			439.456,
			439.522,
			439.58,
			439.627,
			439.64,
			439.704,
			439.734,
			439.765,
			439.828,
			439.843,
			439.889,
			439.95,
			440.014,
			440.06,
			440.138,
			440.167,
			440.198,
			440.261,
			440.323,
			440.386,
			440.448,
			440.493,
			440.57,
			440.602,
			440.633,
			440.696,
			440.711,
			440.756,
			440.819,
			440.882,
			440.928,
			440.945,
			441.006,
			441.036,
			441.068,
			441.15,
			441.19,
			441.254,
			441.315,
			441.363,
			441.44,
			441.472,
			441.502,
			441.563,
			441.625,
			441.689,
			441.75,
			441.812,
			441.873,
			441.905,
			441.935,
			441.997,
			442.013,
			442.06,
			442.122,
			442.183,
			442.23,
			442.246,
			442.309,
			442.338,
			442.368,
			442.433,
			442.493,
			442.557,
			442.618,
			442.664,
			442.74,
			442.774,
			442.803,
			442.882,
			442.927,
			442.99,
			443.052,
			443.097,
			443.113,
			443.177,
			443.211,
			443.236,
			443.301,
			443.316,
			443.363,
			443.425,
			443.486,
			443.534,
			443.611,
			443.641,
			443.672,
			443.735,
			443.796,
			443.857,
			443.92,
			443.967,
			444.045,
			444.076,
			444.105,
			444.167,
			444.185,
			444.23,
			444.291,
			444.355,
			444.4,
			444.416,
			444.478,
			444.509,
			444.541,
			444.601,
			444.619,
			444.663,
			444.727,
			444.789,
			444.835,
			444.913,
			444.943,
			444.972,
			445.036,
			445.098,
			445.16,
			445.221,
			445.285,
			445.346,
			445.377,
			445.406,
			445.471,
			445.485,
			445.533,
			445.595,
			445.655,
			445.719,
			445.78,
			445.811,
			445.843,
			445.905,
			445.919,
			445.965,
			446.03,
			446.092,
			446.151,
			446.216,
			446.246,
			446.277,
			446.355,
			446.401,
			446.463,
			446.525,
			446.586,
			446.648,
			446.68,
			446.71,
			446.774,
			446.787,
			446.836,
			446.897,
			446.959,
			447.006,
			447.021,
			447.081,
			447.113,
			447.145,
			447.207,
			447.223,
			447.268,
			447.33,
			447.336,
			447.392,
			447.44,
			447.517,
			447.547,
			447.578,
			447.639,
			447.703,
			447.765,
			447.826,
			447.872,
			447.888,
			447.95,
			447.98,
			448.013,
			448.075,
			448.09,
			448.135,
			448.199,
			448.261,
			448.305,
			448.322,
			448.383,
			448.415,
			448.447,
			448.523,
			448.571,
			448.632,
			448.696,
			448.74,
			448.818,
			448.851,
			448.881,
			448.959,
			449.005,
			449.068,
			449.129,
			449.192,
			449.254,
			449.284,
			449.316,
			449.377,
			449.397,
			449.439,
			449.502,
			449.564,
			449.625,
			449.686,
			449.718,
			449.749,
			449.811,
			449.873,
			449.936,
			449.998,
			450.044,
			450.06,
			450.122,
			450.151,
			450.183,
			450.244,
			450.26,
			450.308,
			450.371,
			450.432,
			450.479,
			450.493,
			450.555,
			450.585,
			450.617,
			450.68,
			450.694,
			450.739,
			450.807,
			450.865,
			450.91,
			450.989,
			451.021,
			451.051,
			451.113,
			451.176,
			451.237,
			451.3,
			451.346,
			451.362,
			451.422,
			451.459,
			451.485,
			451.563,
			451.61,
			451.671,
			451.732,
			451.796,
			451.856,
			451.889,
			451.919,
			451.997,
			452.043,
			452.105,
			452.168,
			452.23,
			452.292,
			452.321,
			452.352,
			452.416,
			452.478,
			452.539,
			452.602,
			452.647,
			452.663,
			452.726,
			452.757,
			452.788,
			452.865,
			452.912,
			452.972,
			453.035,
			453.082,
			453.098,
			453.161,
			453.189,
			453.222,
			453.284,
			453.346,
			453.407,
			453.469,
			453.52,
			453.533,
			453.593,
			453.625,
			453.656,
			453.732,
			453.781,
			453.843,
			453.905,
			453.95,
			453.966,
			454.028,
			454.06,
			454.088,
			454.152,
			454.166,
			454.215,
			454.274,
			454.339,
			454.385,
			454.462,
			454.494,
			454.523,
			454.585,
			454.647,
			454.711,
			454.771,
			454.835,
			454.894,
			454.926,
			454.959,
			455.02,
			455.035,
			455.081,
			455.144,
			455.207,
			455.267,
			455.33,
			455.36,
			455.392,
			455.455,
			455.469,
			455.517,
			455.579,
			455.639,
			455.688,
			455.763,
			455.796,
			455.826,
			455.887,
			455.949,
			456.013,
			456.072,
			456.122,
			456.135,
			456.198,
			456.23,
			456.26,
			456.322,
			456.338,
			456.383,
			456.447,
			456.507,
			456.553,
			456.569,
			456.631,
			456.663,
			456.693,
			456.756,
			456.771,
			456.819,
			456.88,
			456.943,
			456.989,
			457.065,
			457.098,
			457.127,
			457.205,
			457.251,
			457.315,
			457.377,
			457.422,
			457.438,
			457.501,
			457.53,
			457.561,
			457.647,
			457.685,
			457.75,
			457.811,
			457.872,
			457.935,
			457.967,
			457.997,
			458.059,
			458.119,
			458.181,
			458.245,
			458.289,
			458.37,
			458.399,
			458.431,
			458.492,
			458.508,
			458.553,
			458.617,
			458.679,
			458.725,
			458.742,
			458.802,
			458.835,
			458.863,
			458.927,
			458.943,
			458.988,
			459.054,
			459.113,
			459.16,
			459.238,
			459.269,
			459.299,
			459.36,
			459.421,
			459.486,
			459.548,
			459.594,
			459.609,
			459.67,
			459.706,
			459.734,
			459.796,
			459.811,
			459.856,
			459.919,
			459.98,
			460.027,
			460.044,
			460.104,
			460.136,
			460.166,
			460.243,
			460.29,
			460.353,
			460.415,
			460.461,
			460.539,
			460.57,
			460.601,
			460.663,
			460.726,
			460.787,
			460.85,
			460.912,
			460.971,
			461.005,
			461.034,
			461.113,
			461.16,
			461.22,
			461.282,
			461.331,
			461.344,
			461.407,
			461.438,
			461.469,
			461.53,
			461.546,
			461.594,
			461.655,
			461.716,
			461.768,
			461.842,
			461.873,
			461.904,
			461.981,
			462.027,
			462.089,
			462.152,
			462.213,
			462.275,
			462.307,
			462.336,
			462.415,
			462.461,
			462.523,
			462.584,
			462.648,
			462.71,
			462.739,
			462.771,
			462.834,
			462.896,
			462.959,
			463.018,
			463.066,
			463.143,
			463.173,
			463.204,
			463.266,
			463.33,
			463.389,
			463.452,
			463.501,
			463.515,
			463.577,
			463.61,
			463.641,
			463.701,
			463.716,
			463.764,
			463.83,
			463.888,
			463.935,
			464.012,
			464.044,
			464.073,
			464.151,
			464.199,
			464.261,
			464.321,
			464.369,
			464.444,
			464.477,
			464.506,
			464.571,
			464.586,
			464.633,
			464.695,
			464.757,
			464.802,
			464.817,
			464.879,
			464.911,
			464.942,
			465.004,
			465.018,
			465.065,
			465.127,
			465.19,
			465.236,
			465.313,
			465.346,
			465.377,
			465.452,
			465.499,
			465.562,
			465.625,
			465.67,
			465.687,
			465.749,
			465.78,
			465.811,
			465.871,
			465.89,
			465.934,
			465.996,
			466.056,
			466.119,
			466.183,
			466.212,
			466.244,
			466.321,
			466.368,
			466.43,
			466.49,
			466.539,
			466.615,
			466.646,
			466.677,
			466.738,
			466.802,
			466.864,
			466.927,
			466.987,
			467.051,
			467.082,
			467.112,
			467.172,
			467.19,
			467.235,
			467.298,
			467.36,
			467.405,
			467.421,
			467.484,
			467.516,
			467.547,
			467.609,
			467.67,
			467.736,
			467.794,
			467.842,
			467.919,
			467.979,
			468.042,
			468.058,
			468.104,
			468.165,
			468.228,
			468.289,
			468.352,
			468.384,
			468.415,
			468.476,
			468.493,
			468.539,
			468.599,
			468.663,
			468.723,
			468.787,
			468.817,
			468.847,
			468.924,
			468.973,
			469.035,
			469.095,
			469.14,
			469.159,
			469.22,
			469.252,
			469.283,
			469.36,
			469.407,
			469.469,
			469.531,
			469.576,
			469.593,
			469.653,
			469.685,
			469.716,
			469.794,
			469.84,
			469.904,
			469.964,
			470.027,
			470.089,
			470.119,
			470.151,
			470.212,
			470.273,
			470.338,
			470.399,
			470.461,
			470.523,
			470.553,
			470.583,
			470.646,
			470.663,
			470.709,
			470.771,
			470.834,
			470.88,
			470.896,
			470.956,
			470.987,
			471.018,
			471.079,
			471.096,
			471.143,
			471.203,
			471.265,
			471.313,
			471.392,
			471.421,
			471.452,
			471.515,
			471.576,
			471.639,
			471.7,
			471.748,
			471.762,
			471.823,
			471.855,
			471.888,
			471.947,
			471.964,
			472.009,
			472.073,
			472.135,
			472.18,
			472.196,
			472.259,
			472.29,
			472.32,
			472.398,
			472.445,
			472.507,
			472.57,
			472.615,
			472.693,
			472.723,
			472.756,
			472.816,
			472.879,
			472.94,
			473.002,
			473.051,
			473.066,
			473.126,
			473.159,
			473.189,
			473.266,
			473.313,
			473.38,
			473.437,
			473.483,
			473.56,
			473.593,
			473.622,
			473.684,
			473.748,
			473.809,
			473.871,
			473.919,
			473.933,
			473.996,
			474.026,
			474.056,
			474.119,
			474.141,
			474.181,
			474.243,
			474.304,
			474.352,
			474.368,
			474.428,
			474.46,
			474.492,
			474.553,
			474.569,
			474.616,
			474.677,
			474.738,
			474.784,
			474.863,
			474.893,
			474.926,
			474.987,
			475.049,
			475.11,
			475.173,
			475.22,
			475.234,
			475.298,
			475.329,
			475.36,
			475.421,
			475.437,
			475.485,
			475.545,
			475.607,
			475.655,
			475.668,
			475.732,
			475.762,
			475.794,
			475.87,
			475.919,
			475.981,
			476.043,
			476.089,
			476.165,
			476.203,
			476.228,
			476.291,
			476.351,
			476.413,
			476.476,
			476.537,
			476.601,
			476.629,
			476.661,
			476.725,
			476.739,
			476.784,
			476.849,
			476.91,
			476.954,
			476.973,
			477.033,
			477.065,
			477.096,
			477.158,
			477.218,
			477.285,
			477.344,
			477.392,
			477.466,
			477.498,
			477.53,
			477.593,
			477.654,
			477.716,
			477.778,
			477.826,
			477.838,
			477.901,
			477.932,
			477.962,
			478.04,
			478.088,
			478.149,
			478.212,
			478.275,
			478.336,
			478.368,
			478.398,
			478.46,
			478.523,
			478.585,
			478.645,
			478.693,
			478.771,
			478.802,
			478.831,
			478.893,
			478.91,
			478.956,
			479.018,
			479.08,
			479.127,
			479.143,
			479.204,
			479.235,
			479.265,
			479.327,
			479.343,
			479.389,
			479.452,
			479.513,
			479.562,
			479.638,
			479.669,
			479.7,
			479.763,
			479.823,
			479.892,
			479.948,
			479.996,
			480.009,
			480.071,
			480.102,
			480.132,
			480.196,
			480.212,
			480.259,
			480.325,
			480.382,
			480.445,
			480.507,
			480.539,
			480.569,
			480.629,
			480.647,
			480.693,
			480.754,
			480.816,
			480.862,
			480.939,
			480.972,
			481.002,
			481.065,
			481.128,
			481.19,
			481.251,
			481.297,
			481.313,
			481.375,
			481.405,
			481.438,
			481.513,
			481.56,
			481.622,
			481.684,
			481.73,
			481.747,
			481.807,
			481.839,
			481.869,
			481.932,
			481.949,
			481.995,
			482.055,
			482.12,
			482.18,
			482.244,
			482.274,
			482.306,
			482.367,
			482.388,
			482.43,
			482.492,
			482.554,
			482.6,
			482.613,
			482.677,
			482.707,
			482.739,
			482.801,
			482.815,
			482.863,
			482.93,
			482.985,
			483.034,
			483.111,
			483.143,
			483.172,
			483.236,
			483.298,
			483.36,
			483.421,
			483.467,
			483.483,
			483.545,
			483.576,
			483.607,
			483.67,
			483.686,
			483.731,
			483.793,
			483.855,
			483.902,
			483.916,
			483.98,
			484.011,
			484.04,
			484.104,
			484.119,
			484.165,
			484.227,
			484.288,
			484.335,
			484.413,
			484.449,
			484.476,
			484.538,
			484.6,
			484.663,
			484.723,
			484.771,
			484.784,
			484.847,
			484.877,
			484.91,
			484.97,
			484.988,
			485.033,
			485.096,
			485.156,
			485.219,
			485.282,
			485.313,
			485.344,
			485.405,
			485.421,
			485.467,
			485.53,
			485.592,
			485.638,
			485.715,
			485.746,
			485.778,
			485.838,
			485.9,
			485.963,
			486.026,
			486.073,
			486.088,
			486.148,
			486.18,
			486.211,
			486.273,
			486.288,
			486.336,
			486.398,
			486.46,
			486.511,
			486.583,
			486.613,
			486.646,
			486.706,
			486.722,
			486.768,
			486.832,
			486.895,
			486.939,
			487.017,
			487.047,
			487.081,
			487.142,
			487.204,
			487.267,
			487.328,
			487.391,
			487.453,
			487.481,
			487.513,
			487.575,
			487.592,
			487.637,
			487.7,
			487.763,
			487.823,
			487.885,
			487.918,
			487.948,
			488.011,
			488.073,
			488.134,
			488.196,
			488.243,
			488.258,
			488.319,
			488.351,
			488.383,
			488.46,
			488.505,
			488.572,
			488.63,
			488.676,
			488.693,
			488.755,
			488.785,
			488.816,
			488.877,
			488.894,
			488.939,
			489.001,
			489.063,
			489.11,
			489.188,
			489.218,
			489.249,
			489.313,
			489.375,
			489.437,
			489.497,
			489.544,
			489.559,
			489.623,
			489.652,
			489.685,
			489.747,
			489.76,
			489.809,
			489.871,
			489.933,
			489.98,
			489.993,
			490.055,
			490.088,
			490.118,
			490.18,
			490.196,
			490.242,
			490.305,
			490.365,
			490.414,
			490.49,
			490.522,
			490.551,
			490.614,
			490.635,
			490.677,
			490.739,
			490.801,
			490.848,
			490.862,
			490.924,
			490.956,
			490.987,
			491.047,
			491.064,
			491.111,
			491.173,
			491.233,
			491.281,
			491.296,
			491.359,
			491.389,
			491.421,
			491.482,
			491.498,
			491.545,
			491.605,
			491.668,
			491.715,
			491.793,
			491.821,
			491.853,
			491.917,
			491.933,
			491.979,
			492.042,
			492.101,
			492.148,
			492.164,
			492.227,
			492.259,
			492.289,
			492.35,
			492.365,
			492.413,
			492.475,
			492.535,
			492.582,
			492.597,
			492.66,
			492.696,
			492.722,
			492.799,
			492.846,
			492.909,
			492.971,
			493.017,
			493.034,
			493.096,
			493.127,
			493.155,
			493.22,
			493.233,
			493.28,
			493.343,
			493.405,
			493.465,
			493.53,
			493.56,
			493.591,
			493.652,
			493.668,
			493.713,
			493.777,
			493.838,
			493.884,
			493.964,
			493.995,
			494.023,
			494.101,
			494.148,
			494.211,
			494.273,
			494.319,
			494.335,
			494.397,
			494.428,
			494.458,
			494.536,
			494.583,
			494.645,
			494.706,
			494.758,
			494.83,
			494.863,
			494.893,
			494.955,
			494.971,
			495.018,
			495.081,
			495.139,
			495.188,
			495.265,
			495.296,
			495.327,
			495.406,
			495.452,
			495.514,
			495.575,
			495.638,
			495.7,
			495.731,
			495.76,
			495.823,
			495.838,
			495.885,
			495.95,
			496.01,
			496.056,
			496.072,
			496.133,
			496.165,
			496.194,
			496.258,
			496.319,
			496.381,
			496.442,
			496.505,
			496.568,
			496.599,
			496.63,
			496.689,
			496.754,
			496.817,
			496.877,
			496.925,
			496.939,
			497.001,
			497.031,
			497.063,
			497.126,
			497.139,
			497.186,
			497.198
		]
	],
	'2517_2518_7982': [
		[
			52.644
		],
		[
			0
		],
		[
			0,
			4.414,
			7.652,
			10.894,
			15.765,
			19.018,
			23.905,
			25.536,
			27.168,
			32.071,
			35.345,
			40.265,
			43.549,
			48.485,
			51.78,
			53.429,
			56.731,
			61.689,
			63.343,
			68.311,
			73.287,
			74.947,
			76.608,
			79.932,
			84.926,
			88.259,
			89.927,
			93.265,
			96.606,
			101.623,
			104.971,
			111.679,
			115.038,
			118.401,
			121.768,
			126.825,
			130.202,
			133.583,
			135.275,
			138.661,
			140.356,
			143.747,
			147.142,
			152.242,
			155.646,
			160.759,
			164.173,
			169.298,
			171.009,
			172.72,
			177.858,
			181.287,
			184.72,
			189.876,
			193.318,
			198.487,
			200.212,
			201.938,
			208.855,
			210.586,
			215.786,
			219.257,
			224.47,
			227.951,
			231.435,
			233.178,
			236.667,
			238.413,
			241.907,
			245.404,
			250.655,
			254.158,
			261.175,
			262.931,
			268.206,
			269.966,
			271.726,
			277.013,
			280.54,
			285.838,
			289.373,
			292.911,
			298.209,
			299.969,
			301.724,
			308.669,
			310.386,
			315.483,
			322.146,
			323.788,
			327.044,
			330.264,
			331.859,
			336.587,
			339.695,
			342.791,
			347.311,
			350.301,
			354.716,
			356.169,
			357.612,
			361.882,
			364.683,
			368.812,
			371.516,
			374.181,
			375.499,
			378.106,
			379.395,
			381.944,
			385.697,
			386.93,
			390.571,
			392.955,
			396.467,
			398.765,
			401.023,
			402.137,
			405.424,
			407.568,
			409.675,
			412.766,
			414.781,
			418.703,
			419.66,
			422.486,
			424.326,
			427.038,
			428.806,
			431.398,
			433.085,
			433.914,
			435.543,
			437.133,
			437.914,
			439.448,
			440.94,
			443.102,
			444.498,
			445.856,
			447.177,
			447.824,
			449.718,
			450.932,
			452.109,
			453.815,
			454.902,
			456.449,
			457.431,
			458.837,
			459.733,
			460.999,
			461.798,
			462.952,
			464.423,
			464.778,
			465.814,
			466.482,
			467.137,
			468.748,
			470.355,
			471.35,
			472.006,
			472.984,
			473.638,
			474.628,
			475.3,
			475.681,
			476.06,
			477.566,
			477.942,
			479.074,
			479.838,
			480.709,
			481.215,
			483.752,
			485.303,
			486.711,
			488.122,
			490.256,
			491.851,
			494.253,
			495.056,
			495.887,
			498.411,
			500.098,
			502.712,
			504.448,
			506.09,
			506.848,
			508.351,
			509.099,
			509.849,
			512.479,
			513.732,
			514.979,
			516.078,
			517.573,
			518.563,
			519.551,
			520.045,
			521.373,
			522.214,
			523.058,
			525.176,
			526.467,
			526.899,
			527.332,
			528.636,
			529.512,
			530.855,
			531.806,
			533.245,
			534.215,
			534.703,
			535.689,
			536.686,
			537.189,
			538.204,
			539.231,
			540.797,
			541.326,
			541.859,
			543.482,
			544.033,
			544.589,
			546.283,
			547.435,
			548.605,
			550.44,
			551.689,
			553.583,
			554.841,
			556.728,
			558.003,
			559.94,
			561.254,
			563.252,
			564.605,
			566.666,
			568.762,
			570.181,
			571.615,
			573.798,
			575.273,
			577.516,
			578.271,
			579.031,
			581.336,
			582.892,
			585.254,
			586.847,
			588.456,
			590.895,
			591.714,
			592.538,
			595.864,
			596.702,
			599.215,
			600.895,
			603.442,
			605.156,
			606.882,
			607.75,
			610.374,
			612.14,
			613.918,
			616.61,
			618.421,
			621.162,
			623.005,
			625.794,
			627.67,
			630.508,
			632.416,
			635.302,
			637.242,
			640.173,
			642.144,
			644.127,
			647.125,
			649.137,
			651.162,
			652.179,
			654.221,
			656.275,
			657.306,
			660.417,
			662.505,
			667.741,
			669.844,
			673.02,
			674.084,
			675.15,
			678.364,
			680.519,
			683.771,
			685.952,
			688.143,
			689.242,
			691.447,
			692.554,
			694.774,
			698.125,
			700.371,
			702.628,
			706.034,
			708.318,
			711.767,
			712.922,
			714.08,
			717.576,
			719.921,
			722.282,
			725.841,
			728.227,
			731.82,
			733.022,
			734.226,
			737.845,
			740.267,
			743.911,
			746.349,
			750.021,
			752.481,
			754.949,
			756.187,
			758.671,
			759.916,
			762.414,
			764.922,
			768.701,
			771.229,
			775.034,
			776.303,
			777.575,
			781.4,
			783.957,
			787.806,
			790.382,
			794.25,
			798.096,
			799.368,
			803.133,
			804.369,
			805.596,
			809.222,
			811.59,
			813.918,
			815.068,
			819.542,
			820.635,
			823.841,
			825.934,
			827.993,
			831.016,
			832.998,
			835.906,
			836.861,
			837.807,
			840.593,
			842.41,
			845.073,
			846.805,
			848.501,
			849.335,
			850.977,
			851.785,
			853.374,
			855.691,
			857.196,
			858.665,
			860.102,
			862.225,
			863.611,
			864.986,
			865.672,
			867.042,
			867.726,
			869.091,
			870.452,
			872.469,
			873.807,
			875.805,
			876.457,
			877.109,
			879.057,
			880.355,
			882.251,
			883.505,
			884.759,
			885.386,
			886.618,
			888.447,
			889.664,
			890.273,
			891.49,
			892.7,
			893.904,
			895.706,
			897.617,
			898.251,
			898.883,
			900.774,
			902.175,
			903.568,
			905.65,
			907.179,
			909.485,
			910.252,
			911.05,
			914.543,
			915.415,
			918.192,
			920.057,
			922.73,
			924.478,
			925.35,
			927.012,
			928.64,
			929.454,
			931.074,
			932.616,
			934.928,
			936.436,
			938.543,
			939.948,
			941.35,
			942.03,
			943.4,
			945.478,
			946.878,
			948.99,
			950.402,
			951.121,
			951.83,
			954.67,
			955.38,
			957.491,
			958.891,
			960.29,
			960.989,
			962.364,
			963.734,
			964.418,
			965.786,
			967.81,
			969.152,
			971.162,
			972.478,
			975.097,
			975.75,
			977.831,
			979.226,
			981.394,
			983.264,
			985.13,
			986.062,
			988.995,
			990.469,
			994.514,
			1000.763,
			1003.747,
			1006.417,
			1008.172,
			1010.826,
			1012.19,
			1013.477,
			1014.122,
			1015.421,
			1016.074,
			1017.356,
			1018.646,
			1020.601,
			1021.967,
			1024.094,
			1024.809,
			1025.529,
			1027.76,
			1029.281,
			1031.592,
			1033.166,
			1035.552,
			1037.168,
			1037.984,
			1039.619,
			1041.252,
			1042.078,
			1043.738,
			1045.411,
			1047.958,
			1049.676,
			1052.282,
			1053.16,
			1054.042,
			1056.707,
			1058.507,
			1060.32,
			1063.065,
			1064.897,
			1067.691,
			1068.629,
			1069.57,
			1072.427,
			1074.339,
			1077.215,
			1079.158,
			1082.096,
			1084.072,
			1085.064,
			1087.059,
			1090.078,
			1092.107,
			1094.15,
			1097.235,
			1098.27,
			1099.326,
			1103.489,
			1104.541,
			1107.7,
			1109.806,
			1112.98,
			1115.108,
			1117.245,
			1121.548,
			1122.63,
			1126.98,
			1128.073,
			1131.367,
			1133.575,
			1136.918,
			1139.13,
			1142.492,
			1144.746,
			1148.143,
			1150.418,
			1153.845,
			1156.14,
			1159.597,
			1160.754,
			1161.913,
			1165.405,
			1167.745,
			1171.294,
			1173.641,
			1176.017,
			1177.209,
			1179.6,
			1180.799,
			1183.203,
			1186.827,
			1188.04,
			1191.692,
			1194.136,
			1197.819,
			1200.285,
			1202.759,
			1203.998,
			1207.73,
			1210.229,
			1212.737,
			1219.043,
			1222.854,
			1224.127,
			1225.403,
			1230.526,
			1231.812,
			1235.681,
			1238.27,
			1242.165,
			1246.065,
			1248.673,
			1251.289,
			1252.6,
			1255.228,
			1257.862,
			1261.828,
			1263.154,
			1264.481,
			1268.474,
			1269.809,
			1271.146,
			1275.167,
			1277.856,
			1280.553,
			1284.611,
			1287.326,
			1291.411,
			1292.777,
			1294.144,
			1298.257,
			1301.007,
			1305.146,
			1307.913,
			1312.077,
			1314.863,
			1316.259,
			1319.055,
			1323.263,
			1326.077,
			1328.898,
			1333.142,
			1334.56,
			1335.98,
			1340.25,
			1343.103,
			1347.396,
			1350.267,
			1353.144,
			1357.47,
			1361.812,
			1364.714,
			1366.167,
			1367.621,
			1373.453,
			1374.915,
			1379.308,
			1385.183,
			1386.655,
			1389.604,
			1392.56,
			1394.04,
			1397.006,
			1401.492,
			1404.474,
			1408.938,
			1411.942,
			1416.468,
			1417.982,
			1419.523,
			1424.096,
			1427.132,
			1431.748,
			1434.867,
			1439.537,
			1442.649,
			1444.202,
			1447.302,
			1451.903,
			1453.418,
			1457.881,
			1460.827,
			1465.182,
			1468.037,
			1470.88,
			1472.277,
			1475.043,
			1479.11,
			1481.792,
			1485.748,
			1488.333,
			1492.125,
			1494.601,
			1498.245,
			1504.145,
			1506.445,
			1509.824,
			1512.032,
			1513.122,
			1515.275,
			1517.394,
			1518.439,
			1520.501,
			1522.527,
			1524.515,
			1526.465,
			1527.426,
			1529.321,
			1531.179,
			1532.095,
			1534.793,
			1536.554,
			1538.282,
			1540.807,
			1542.451,
			1544.846,
			1545.626,
			1546.395,
			1548.646,
			1550.101,
			1552.207,
			1553.566,
			1554.893,
			1555.543,
			1556.822,
			1557.449,
			1558.674,
			1560.445,
			1561.574,
			1562.667,
			1564.24,
			1565.256,
			1566.711,
			1567.654,
			1569.043,
			1569.961,
			1570.872,
			1572.225,
			1573.129,
			1574.949,
			1575.408,
			1577.22,
			1577.673,
			1579.035,
			1579.948,
			1580.859,
			1581.31,
			1582.209,
			1583.105,
			1583.553,
			1584.449,
			1584.897,
			1585.796,
			1586.691,
			1588.019,
			1588.899,
			1590.215,
			1590.666,
			1591.155,
			1592.606,
			1593.087,
			1593.565,
			1594.992,
			1596.078,
			1597.349,
			1597.981,
			1599.239,
			1600.493,
			1603.192,
			1604.098,
			1605.001,
			1607.886,
			1609.829,
			1612.558,
			1614.324,
			1616.105,
			1617.018,
			1619.747,
			1621.513,
			1623.216,
			1625.753,
			1627.141,
			1629.22,
			1629.915,
			1630.611,
			1632.269,
			1633.363,
			1635.016,
			1636.052,
			1637.416,
			1638.331,
			1638.791,
			1639.716,
			1640.648,
			1641.984,
			1642.88,
			1644.238,
			1644.696,
			1645.156,
			1646.573,
			1647.055,
			1647.539,
			1649.007,
			1650,
			1651.02,
			1652.629,
			1653.718,
			1655.38,
			1655.948,
			1656.539,
			1658.333,
			1659.547,
			1661.417,
			1662.706,
			1664.01,
			1664.669,
			1666.001,
			1666.684,
			1668.062,
			1670.158,
			1671.581,
			1673.027,
			1675.227,
			1676.702,
			1678.966,
			1679.728,
			1680.495,
			1682.819,
			1684.387,
			1686.77,
			1688.378,
			1690.001,
			1692.461,
			1693.288,
			1694.118,
			1696.628,
			1698.313,
			1700.842,
			1702.532,
			1705.094,
			1706.817,
			1708.555,
			1709.429,
			1711.186,
			1712.068,
			1713.842,
			1715.628,
			1718.327,
			1720.141,
			1722.884,
			1723.804,
			1724.728,
			1727.521,
			1729.402,
			1734.171,
			1737.058,
			1738.979,
			1739.952,
			1740.928,
			1743.869,
			1744.855,
			1745.844,
			1748.834,
			1750.813,
			1752.812,
			1753.813,
			1755.841,
			1757.84,
			1758.85,
			1761.894,
			1763.928,
			1765.967,
			1769.032,
			1771.054,
			1774.124,
			1775.152,
			1776.182,
			1779.291,
			1781.375,
			1784.55,
			1786.682,
			1788.863,
			1789.957,
			1792.166,
			1793.294,
			1795.557,
			1799.056,
			1801.427,
			1803.905,
			1807.684,
			1810.268,
			1812.841,
			1815.402,
			1816.707,
			1819.173,
			1820.411,
			1822.858,
			1825.257,
			1828.89,
			1831.318,
			1834.989,
			1836.218,
			1837.448,
			1841.148,
			1843.622,
			1847.344,
			1849.829,
			1853.568,
			1856.068,
			1857.319,
			1859.823,
			1862.335,
			1863.591,
			1866.109,
			1868.635,
			1873.706,
			1874.978,
			1877.528,
			1880.083,
			1881.363,
			1885.208,
			1887.78,
			1890.355,
			1894.231,
			1896.824,
			1900.724,
			1902.027,
			1903.329,
			1907.238,
			1908.543,
			1909.85,
			1913.781,
			1916.41,
			1920.364,
			1923.009,
			1925.661,
			1926.989,
			1930.985,
			1933.658,
			1936.337,
			1943.065,
			1947.123,
			1949.836,
			1953.919,
			1956.649,
			1960.758,
			1963.506,
			1966.261,
			1970.409,
			1973.183,
			1978.757,
			1980.156,
			1984.365,
			1987.18,
			1991.417,
			1994.251,
			1997.091,
			1998.513,
			2001.361,
			2002.788,
			2005.643,
			2008.505,
			2012.807,
			2015.683,
			2020.009,
			2021.454,
			2022.9,
			2027.251,
			2030.159,
			2034.533,
			2037.457,
			2041.854,
			2046.268,
			2049.219,
			2052.176,
			2053.658,
			2056.625,
			2059.598,
			2062.578,
			2067.06,
			2070.055,
			2073.057,
			2074.56,
			2079.077,
			2082.096,
			2085.121,
			2089.671,
			2092.713,
			2098.815,
			2100.344,
			2104.935,
			2108,
			2112.607,
			2115.684,
			2120.308,
			2124.944,
			2128.042,
			2132.7,
			2135.813,
			2138.93,
			2143.618,
			2145.184,
			2146.751,
			2151.459,
			2154.603,
			2159.329,
			2162.485,
			2167.228,
			2170.396,
			2173.568,
			2178.337,
			2179.929,
			2181.522,
			2187.897,
			2189.494,
			2194.292,
			2200.705,
			2202.311,
			2205.526,
			2208.749,
			2210.361,
			2210.387
		],
		[
			0,
			0.07,
			0.118,
			0.18,
			0.241,
			0.288,
			0.365,
			0.398,
			0.427,
			0.489,
			0.553,
			0.613,
			0.677,
			0.738,
			0.8,
			0.831,
			0.863,
			0.939,
			0.985,
			1.051,
			1.11,
			1.157,
			1.173,
			1.234,
			1.298,
			1.358,
			1.374,
			1.42,
			1.481,
			1.544,
			1.607,
			1.703,
			1.736,
			1.808,
			1.854,
			1.916,
			1.978,
			2.024,
			2.04,
			2.103,
			2.133,
			2.163,
			2.227,
			2.289,
			2.35,
			2.414,
			2.474,
			2.536,
			2.568,
			2.597,
			2.661,
			2.723,
			2.783,
			2.847,
			2.893,
			2.97,
			3.001,
			3.031,
			3.11,
			3.157,
			3.219,
			3.28,
			3.343,
			3.403,
			3.436,
			3.466,
			3.528,
			3.544,
			3.59,
			3.651,
			3.713,
			3.778,
			3.884,
			3.9,
			3.964,
			3.994,
			4.024,
			4.102,
			4.151,
			4.211,
			4.272,
			4.335,
			4.396,
			4.428,
			4.459,
			4.536,
			4.583,
			4.646,
			4.752,
			4.769,
			4.831,
			4.862,
			4.891,
			4.97,
			5.018,
			5.081,
			5.139,
			5.204,
			5.265,
			5.297,
			5.327,
			5.39,
			5.45,
			5.514,
			5.576,
			5.622,
			5.636,
			5.697,
			5.73,
			5.762,
			5.843,
			5.883,
			5.946,
			6.009,
			6.071,
			6.133,
			6.165,
			6.197,
			6.261,
			6.32,
			6.383,
			6.443,
			6.489,
			6.597,
			6.63,
			6.707,
			6.752,
			6.815,
			6.877,
			6.939,
			7.001,
			7.033,
			7.063,
			7.124,
			7.141,
			7.188,
			7.249,
			7.31,
			7.373,
			7.434,
			7.466,
			7.496,
			7.576,
			7.621,
			7.683,
			7.745,
			7.791,
			7.87,
			7.93,
			7.994,
			8.055,
			8.119,
			8.18,
			8.24,
			8.335,
			8.365,
			8.444,
			8.489,
			8.555,
			8.66,
			8.799,
			8.862,
			8.922,
			8.986,
			9.048,
			9.111,
			9.172,
			9.203,
			9.234,
			9.31,
			9.359,
			9.42,
			9.483,
			9.528,
			9.544,
			9.666,
			9.745,
			9.792,
			9.853,
			9.916,
			9.978,
			10.041,
			10.069,
			10.102,
			10.179,
			10.227,
			10.289,
			10.35,
			10.397,
			10.412,
			10.472,
			10.504,
			10.534,
			10.613,
			10.661,
			10.721,
			10.783,
			10.847,
			10.908,
			10.939,
			10.97,
			11.046,
			11.093,
			11.155,
			11.264,
			11.34,
			11.374,
			11.404,
			11.48,
			11.528,
			11.591,
			11.651,
			11.715,
			11.777,
			11.807,
			11.838,
			11.9,
			11.916,
			11.963,
			12.027,
			12.086,
			12.134,
			12.147,
			12.211,
			12.242,
			12.271,
			12.351,
			12.397,
			12.457,
			12.52,
			12.584,
			12.643,
			12.706,
			12.768,
			12.831,
			12.892,
			12.955,
			13.016,
			13.079,
			13.139,
			13.216,
			13.264,
			13.327,
			13.389,
			13.451,
			13.513,
			13.543,
			13.574,
			13.636,
			13.7,
			13.762,
			13.823,
			13.868,
			13.946,
			13.976,
			14.007,
			14.088,
			14.132,
			14.195,
			14.256,
			14.319,
			14.379,
			14.41,
			14.442,
			14.521,
			14.567,
			14.628,
			14.691,
			14.738,
			14.813,
			14.876,
			14.955,
			15.001,
			15.063,
			15.125,
			15.188,
			15.249,
			15.311,
			15.371,
			15.433,
			15.498,
			15.56,
			15.606,
			15.62,
			15.683,
			15.713,
			15.745,
			15.822,
			15.87,
			15.994,
			16.055,
			16.115,
			16.154,
			16.18,
			16.24,
			16.302,
			16.369,
			16.427,
			16.472,
			16.488,
			16.549,
			16.581,
			16.613,
			16.69,
			16.737,
			16.8,
			16.862,
			16.922,
			16.985,
			17.016,
			17.046,
			17.11,
			17.172,
			17.234,
			17.296,
			17.339,
			17.42,
			17.451,
			17.481,
			17.558,
			17.605,
			17.668,
			17.732,
			17.791,
			17.853,
			17.885,
			17.916,
			17.977,
			17.993,
			18.039,
			18.1,
			18.163,
			18.224,
			18.288,
			18.319,
			18.349,
			18.426,
			18.473,
			18.535,
			18.598,
			18.66,
			18.753,
			18.784,
			18.845,
			18.86,
			18.906,
			18.968,
			19.03,
			19.077,
			19.093,
			19.186,
			19.218,
			19.293,
			19.341,
			19.404,
			19.466,
			19.527,
			19.589,
			19.621,
			19.651,
			19.712,
			19.776,
			19.838,
			19.899,
			19.948,
			19.961,
			20.023,
			20.055,
			20.086,
			20.164,
			20.211,
			20.276,
			20.334,
			20.394,
			20.456,
			20.488,
			20.518,
			20.58,
			20.596,
			20.645,
			20.704,
			20.767,
			20.83,
			20.891,
			20.922,
			20.952,
			21.03,
			21.077,
			21.141,
			21.2,
			21.25,
			21.263,
			21.326,
			21.388,
			21.45,
			21.464,
			21.511,
			21.573,
			21.635,
			21.698,
			21.76,
			21.789,
			21.822,
			21.9,
			21.947,
			22.007,
			22.069,
			22.115,
			22.194,
			22.225,
			22.256,
			22.337,
			22.38,
			22.441,
			22.504,
			22.566,
			22.627,
			22.659,
			22.688,
			22.751,
			22.768,
			22.815,
			22.876,
			22.938,
			22.999,
			23.065,
			23.124,
			23.184,
			23.2,
			23.248,
			23.312,
			23.37,
			23.435,
			23.497,
			23.527,
			23.559,
			23.637,
			23.681,
			23.749,
			23.807,
			23.853,
			23.867,
			23.929,
			23.961,
			23.993,
			24.055,
			24.117,
			24.18,
			24.24,
			24.302,
			24.403,
			24.427,
			24.504,
			24.551,
			24.612,
			24.674,
			24.721,
			24.737,
			24.797,
			24.83,
			24.86,
			24.937,
			24.986,
			25.047,
			25.109,
			25.17,
			25.231,
			25.262,
			25.294,
			25.357,
			25.373,
			25.418,
			25.481,
			25.542,
			25.604,
			25.665,
			25.696,
			25.727,
			25.804,
			25.851,
			25.915,
			25.978,
			26.039,
			26.1,
			26.132,
			26.164,
			26.224,
			26.24,
			26.288,
			26.35,
			26.412,
			26.473,
			26.535,
			26.566,
			26.597,
			26.675,
			26.722,
			26.783,
			26.844,
			26.908,
			26.968,
			26.998,
			27.03,
			27.093,
			27.153,
			27.218,
			27.277,
			27.341,
			27.402,
			27.434,
			27.463,
			27.542,
			27.588,
			27.652,
			27.713,
			27.759,
			27.776,
			27.868,
			27.899,
			27.977,
			28.022,
			28.086,
			28.148,
			28.21,
			28.301,
			28.331,
			28.41,
			28.457,
			28.523,
			28.581,
			28.644,
			28.706,
			28.765,
			28.828,
			28.892,
			28.954,
			29.015,
			29.078,
			29.138,
			29.17,
			29.2,
			29.277,
			29.325,
			29.387,
			29.45,
			29.498,
			29.512,
			29.574,
			29.605,
			29.636,
			29.712,
			29.76,
			29.821,
			29.884,
			29.945,
			30.007,
			30.037,
			30.068,
			30.147,
			30.193,
			30.256,
			30.364,
			30.441,
			30.472,
			30.503,
			30.586,
			30.627,
			30.688,
			30.75,
			30.815,
			30.905,
			30.938,
			30.999,
			31.014,
			31.062,
			31.123,
			31.185,
			31.231,
			31.248,
			31.31,
			31.339,
			31.37,
			31.45,
			31.496,
			31.557,
			31.619,
			31.681,
			31.743,
			31.773,
			31.804,
			31.869,
			31.93,
			31.992,
			32.055,
			32.116,
			32.179,
			32.209,
			32.24,
			32.316,
			32.362,
			32.426,
			32.486,
			32.534,
			32.55,
			32.613,
			32.674,
			32.735,
			32.798,
			32.86,
			32.923,
			32.986,
			33.048,
			33.077,
			33.108,
			33.186,
			33.231,
			33.293,
			33.402,
			33.417,
			33.48,
			33.51,
			33.543,
			33.605,
			33.665,
			33.728,
			33.789,
			33.851,
			33.913,
			33.945,
			33.977,
			34.052,
			34.1,
			34.163,
			34.223,
			34.286,
			34.349,
			34.381,
			34.411,
			34.487,
			34.532,
			34.596,
			34.657,
			34.719,
			34.783,
			34.816,
			34.844,
			34.907,
			34.969,
			35.032,
			35.092,
			35.138,
			35.217,
			35.279,
			35.357,
			35.463,
			35.527,
			35.588,
			35.649,
			35.68,
			35.713,
			35.773,
			35.789,
			35.837,
			35.9,
			35.96,
			36.006,
			36.023,
			36.083,
			36.116,
			36.147,
			36.223,
			36.269,
			36.333,
			36.395,
			36.455,
			36.519,
			36.55,
			36.581,
			36.642,
			36.705,
			36.772,
			36.828,
			36.875,
			36.89,
			36.952,
			36.983,
			37.013,
			37.093,
			37.138,
			37.199,
			37.263,
			37.326,
			37.385,
			37.449,
			37.527,
			37.571,
			37.633,
			37.696,
			37.76,
			37.852,
			37.883,
			37.961,
			38.008,
			38.07,
			38.131,
			38.178,
			38.193,
			38.255,
			38.286,
			38.317,
			38.378,
			38.393,
			38.441,
			38.502,
			38.566,
			38.627,
			38.688,
			38.72,
			38.75,
			38.815,
			38.837,
			38.874,
			38.938,
			38.999,
			39.045,
			39.062,
			39.122,
			39.184,
			39.247,
			39.263,
			39.309,
			39.372,
			39.433,
			39.494,
			39.558,
			39.589,
			39.62,
			39.696,
			39.741,
			39.806,
			39.866,
			39.912,
			39.99,
			40.023,
			40.051,
			40.129,
			40.178,
			40.239,
			40.3,
			40.365,
			40.423,
			40.455,
			40.487,
			40.548,
			40.612,
			40.672,
			40.736,
			40.78,
			40.796,
			40.86,
			40.894,
			40.92,
			40.997,
			41.045,
			41.108,
			41.17,
			41.23,
			41.293,
			41.324,
			41.354,
			41.417,
			41.48,
			41.539,
			41.603,
			41.65,
			41.666,
			41.728,
			41.758,
			41.79,
			41.866,
			41.913,
			41.977,
			42.038,
			42.099,
			42.161,
			42.19,
			42.223,
			42.286,
			42.346,
			42.41,
			42.471,
			42.518,
			42.594,
			42.627,
			42.656,
			42.734,
			42.781,
			42.845,
			42.904,
			42.968,
			43.03,
			43.061,
			43.092,
			43.152,
			43.169,
			43.215,
			43.279,
			43.339,
			43.386,
			43.465,
			43.495,
			43.525,
			43.602,
			43.648,
			43.774,
			43.835,
			43.899,
			43.927,
			43.957,
			44.023,
			44.038,
			44.083,
			44.149,
			44.209,
			44.255,
			44.269,
			44.332,
			44.362,
			44.394,
			44.472,
			44.517,
			44.579,
			44.641,
			44.704,
			44.766,
			44.798,
			44.829,
			44.89,
			44.95,
			45.018,
			45.076,
			45.122,
			45.138,
			45.199,
			45.23,
			45.261,
			45.34,
			45.385,
			45.448,
			45.51,
			45.572,
			45.633,
			45.665,
			45.695,
			45.759,
			45.772,
			45.821,
			45.883,
			45.945,
			46.006,
			46.068,
			46.1,
			46.131,
			46.207,
			46.253,
			46.317,
			46.377,
			46.44,
			46.502,
			46.533,
			46.564,
			46.627,
			46.642,
			46.689,
			46.75,
			46.86,
			46.874,
			46.935,
			46.967,
			46.998,
			47.082,
			47.123,
			47.184,
			47.248,
			47.294,
			47.369,
			47.401,
			47.431,
			47.494,
			47.511,
			47.557,
			47.619,
			47.679,
			47.741,
			47.805,
			47.835,
			47.867,
			47.943,
			47.99,
			48.053,
			48.176,
			48.237,
			48.301,
			48.361,
			48.423,
			48.486,
			48.549,
			48.61,
			48.672,
			48.734,
			48.812,
			48.859,
			48.922,
			48.983,
			49.043,
			49.107,
			49.143,
			49.169,
			49.232,
			49.247,
			49.293,
			49.355,
			49.416,
			49.48,
			49.541,
			49.571,
			49.602,
			49.68,
			49.728,
			49.788,
			49.85,
			49.913,
			50.007,
			50.036,
			50.099,
			50.113,
			50.16,
			50.221,
			50.284,
			50.348,
			50.408,
			50.441,
			50.47,
			50.548,
			50.595,
			50.656,
			50.719,
			50.779,
			50.874,
			50.904,
			50.968,
			51.03,
			51.091,
			51.152,
			51.215,
			51.307,
			51.338,
			51.416,
			51.461,
			51.523,
			51.587,
			51.634,
			51.65,
			51.711,
			51.772,
			51.835,
			51.897,
			51.96,
			52.021,
			52.084,
			52.144,
			52.177,
			52.206,
			52.285,
			52.331,
			52.394,
			52.503,
			52.518,
			52.579,
			52.61,
			52.64,
			52.644
		]
	],
	'1690_1691_7982': [
		[
			120.064
		],
		[
			0
		],
		[
			0,
			0.931,
			2.267,
			7.628,
			8.973,
			13.02,
			15.727,
			19.802,
			25.259,
			26.628,
			29.371,
			30.746,
			33.499,
			36.26,
			40.413,
			43.191,
			47.37,
			50.165,
			54.371,
			57.183,
			61.414,
			68.501,
			71.347,
			72.773,
			75.629,
			78.493,
			79.927,
			82.8,
			85.68,
			90.014,
			92.911,
			95.814,
			100.183,
			104.566,
			107.496,
			110.432,
			114.849,
			117.802,
			122.242,
			125.21,
			129.674,
			132.658,
			137.146,
			140.144,
			144.654,
			147.667,
			152.2,
			156.746,
			159.784,
			162.827,
			167.405,
			168.933,
			170.464,
			178.137,
			181.216,
			185.846,
			188.94,
			193.593,
			196.703,
			201.379,
			204.504,
			210.772,
			212.342,
			217.061,
			220.214,
			223.373,
			224.954,
			228.12,
			229.704,
			232.877,
			240.83,
			244.019,
			248.803,
			251.998,
			258.399,
			260.002,
			264.816,
			268.03,
			271.249,
			276.086,
			279.315,
			280.932,
			284.169,
			285.79,
			287.411,
			292.282,
			295.535,
			300.422,
			303.686,
			308.59,
			311.866,
			316.788,
			321.719,
			325.012,
			328.31,
			333.264,
			336.572,
			341.542,
			343.2,
			344.86,
			349.844,
			353.173,
			358.173,
			361.512,
			366.527,
			369.878,
			371.554,
			374.91,
			379.953,
			383.318,
			386.687,
			390.061,
			393.438,
			395.129,
			401.903,
			403.599,
			408.693,
			412.096,
			415.503,
			420.62,
			424.038,
			429.172,
			430.886,
			432.601,
			437.752,
			441.191,
			446.356,
			449.795,
			453.218,
			454.922,
			458.306,
			459.985,
			463.315,
			468.237,
			471.471,
			474.667,
			479.392,
			482.496,
			487.081,
			488.592,
			490.095,
			493.073,
			494.548,
			497.47,
			500.353,
			504.607,
			507.396,
			511.509,
			512.861,
			514.204,
			519.481,
			520.776,
			524.61,
			527.12,
			529.593,
			530.815,
			534.429,
			536.795,
			539.127,
			540.281,
			542.56,
			544.801,
			548.094,
			550.243,
			553.397,
			554.43,
			555.454,
			558.473,
			560.44,
			563.324,
			565.202,
			567.954,
			570.632,
			571.508,
			574.085,
			575.758,
			578.203,
			579.792,
			582.11,
			583.613,
			585.082,
			585.804,
			587.916,
			589.283,
			590.618,
			592.572,
			593.828,
			595.648,
			596.817,
			598.507,
			599.591,
			601.151,
			602.146,
			603.579,
			604.498,
			604.948,
			605.829,
			607.109,
			607.526,
			608.76,
			609.571,
			610.765,
			611.549,
			612.33,
			612.72,
			613.499,
			613.887,
			614.667,
			615.464,
			616.674,
			617.479,
			618.692,
			619.099,
			619.508,
			620.83,
			621.769,
			623.185,
			624.138,
			625.63,
			627.312,
			628.442,
			629.58,
			630.154,
			631.488,
			632.89,
			635.005,
			636.661,
			638.473,
			640.289,
			641.207,
			643.978,
			645.794,
			647.301,
			649.604,
			651.078,
			652.942,
			654.191,
			656.051,
			657.143,
			658.789,
			659.898,
			661.553,
			663.163,
			664.25,
			665.903,
			667.017,
			668.142,
			669.854,
			670.432,
			671.012,
			672.777,
			673.367,
			673.96,
			676.998,
			678.27,
			680.202,
			682.192,
			683.562,
			684.253,
			684.947,
			687.065,
			687.788,
			688.515,
			690.718,
			693.719,
			694.478,
			696.009,
			697.551,
			698.325,
			700.665,
			702.243,
			703.818,
			706.203,
			707.805,
			711.025,
			711.838,
			714.288,
			715.919,
			718.38,
			720.035,
			721.7,
			722.536,
			724.216,
			725.059,
			726.754,
			729.314,
			730.157,
			732.74,
			734.471,
			737.058,
			738.771,
			740.496,
			741.356,
			743.083,
			743.937,
			745.668,
			747.39,
			749.969,
			751.688,
			754.25,
			755.95,
			758.484,
			760.162,
			762.658,
			764.316,
			765.966,
			766.789,
			768.432,
			770.901,
			773.37,
			775.019,
			776.688,
			778.355,
			780.874,
			782.57,
			784.265,
			785.114,
			787.673,
			789.376,
			791.938,
			793.641,
			795.343,
			797.921,
			798.776,
			802.206,
			803.918,
			806.498,
			808.239,
			810.844,
			812.62,
			813.507,
			815.279,
			817.06,
			817.956,
			819.746,
			821.536,
			824.192,
			825.965,
			828.599,
			830.352,
			832.984,
			834.741,
			837.391,
			839.19,
			840.994,
			843.758,
			844.693,
			845.629,
			848.468,
			849.425,
			850.382,
			853.27,
			855.212,
			857.157,
			858.12,
			860.028,
			862.895,
			865.727,
			867.616,
			869.506,
			872.34,
			874.224,
			877.04,
			877.98,
			878.921,
			881.75,
			883.639,
			886.446,
			888.325,
			890.173,
			892.93,
			895.675,
			898.409,
			900.236,
			902.065,
			903.904,
			906.681,
			908.546,
			910.423,
			911.367,
			914.215,
			916.13,
			918.057,
			920.972,
			922.931,
			925.894,
			927.888,
			930.9,
			932.926,
			935.97,
			938.028,
			940.097,
			941.136,
			943.219,
			944.26,
			946.345,
			948.441,
			949.494,
			951.609,
			953.735,
			956.947,
			959.102,
			961.268,
			963.443,
			964.533,
			967.839,
			970.039,
			972.249,
			975.562,
			977.789,
			981.122,
			982.227,
			983.328,
			986.597,
			988.737,
			991.881,
			993.931,
			996.935,
			998.893,
			999.857,
			1001.758,
			1004.544,
			1006.353,
			1008.13,
			1010.736,
			1012.43,
			1014.903,
			1015.708,
			1016.518,
			1018.852,
			1020.364,
			1022.558,
			1023.977,
			1025.362,
			1027.379,
			1028.036,
			1028.685,
			1030.593,
			1031.834,
			1033.646,
			1035.994,
			1036.57,
			1037.713,
			1038.281,
			1039.409,
			1041.094,
			1042.217,
			1043.339,
			1045.021,
			1046.144,
			1048.429,
			1049,
			1050.714,
			1051.866,
			1053.605,
			1054.759,
			1055.91,
			1056.486,
			1057.658,
			1058.242,
			1058.825,
			1061.138,
			1061.713,
			1064.632,
			1066.361,
			1067.519,
			1068.699,
			1069.286,
			1070.454,
			1072.202,
			1073.393,
			1075.171,
			1076.354,
			1078.152,
			1078.765,
			1079.376,
			1081.209,
			1082.436,
			1084.327,
			1087.491,
			1088.764,
			1089.399,
			1090.667,
			1091.935,
			1092.571,
			1093.849,
			1095.126,
			1096.404,
			1098.335,
			1100.323,
			1100.987,
			1101.652,
			1103.674,
			1105.062,
			1106.456,
			1108.568,
			1110.009,
			1112.187,
			1112.919,
			1113.656,
			1115.91,
			1117.429,
			1119.717,
			1121.246,
			1123.563,
			1125.096,
			1125.863,
			1127.403,
			1129.713,
			1131.211,
			1132.723,
			1134.999,
			1135.745,
			1136.495,
			1139.533,
			1140.29,
			1141.811,
			1142.564,
			1144.105,
			1146.425,
			1147.972,
			1149.519,
			1151.829,
			1152.595,
			1154.126,
			1156.427,
			1157.186,
			1159.452,
			1160.956,
			1162.46,
			1163.209,
			1164.699,
			1166.946,
			1168.464,
			1169.221,
			1170.729,
			1172.297,
			1174.686,
			1176.274,
			1178.801,
			1179.643,
			1180.484,
			1183.042,
			1184.764,
			1187.346,
			1189.053,
			1191.615,
			1193.307,
			1194.147,
			1194.987,
			1197.507,
			1198.367,
			1199.23,
			1201.822,
			1203.574,
			1206.272,
			1208.075,
			1210.772,
			1213.461,
			1215.116,
			1216.776,
			1219.235,
			1220.805,
			1223.174,
			1223.951,
			1224.71,
			1226.998,
			1228.536,
			1230.841,
			1232.389,
			1234.74,
			1236.331,
			1237.13,
			1238.739,
			1240.376,
			1241.199,
			1242.844,
			1244.494,
			1247.008,
			1247.852,
			1248.7,
			1250.41,
			1252.137,
			1253.005,
			1255.63,
			1257.397,
			1259.177,
			1261.862,
			1263.665,
			1266.375,
			1268.204,
			1270.974,
			1272.842,
			1275.667,
			1279.49,
			1280.453,
			1282.385,
			1283.355,
			1285.304,
			1288.244,
			1290.218,
			1292.206,
			1295.215,
			1297.236,
			1300.298,
			1301.324,
			1302.361,
			1305.512,
			1307.629,
			1310.815,
			1312.917,
			1315.006,
			1319.213,
			1320.27,
			1323.467,
			1325.616,
			1328.863,
			1331.041,
			1333.231,
			1334.33,
			1337.643,
			1339.865,
			1342.097,
			1343.216,
			1345.464,
			1347.722,
			1353.413,
			1356.86,
			1358.015,
			1359.173,
			1362.662,
			1364.999,
			1370.877,
			1374.425,
			1376.802,
			1377.992,
			1379.185,
			1382.776,
			1385.18,
			1388.801,
			1391.225,
			1393.659,
			1394.879,
			1397.327,
			1399.783,
			1401.015,
			1404.725,
			1407.21,
			1409.706,
			1413.468,
			1415.989,
			1419.786,
			1421.057,
			1422.33,
			1426.163,
			1428.733,
			1432.606,
			1435.209,
			1437.821,
			1439.134,
			1441.765,
			1443.08,
			1445.71,
			1449.662,
			1450.982,
			1454.944,
			1458.915,
			1461.569,
			1464.227,
			1466.891,
			1468.225,
			1470.898,
			1474.92,
			1478.959,
			1481.661,
			1484.371,
			1488.45,
			1491.18,
			1495.29,
			1498.038,
			1502.175,
			1504.943,
			1509.11,
			1511.896,
			1513.292,
			1516.09,
			1518.895,
			1520.302,
			1523.119,
			1525.945,
			1530.198,
			1531.619,
			1533.042,
			1537.322,
			1538.752,
			1540.185,
			1544.492,
			1547.366,
			1551.673,
			1554.527,
			1557.358,
			1561.544,
			1562.921,
			1564.287,
			1568.326,
			1569.653,
			1570.97,
			1574.867,
			1577.418,
			1581.171,
			1583.625,
			1586.041,
			1587.235,
			1590.766,
			1593.076,
			1595.348,
			1598.684,
			1599.778,
			1600.862,
			1604.06,
			1605.108,
			1606.147,
			1609.21,
			1611.206,
			1614.133,
			1616.056,
			1617.935,
			1620.714,
			1621.631,
			1622.546,
			1626.175,
			1627.079,
			1629.782,
			1631.578,
			1633.375,
			1634.272,
			1636.064,
			1638.756,
			1640.56,
			1641.462,
			1643.27,
			1645.1,
			1647.86,
			1649.73,
			1652.571,
			1653.521,
			1654.492,
			1657.423,
			1659.396,
			1662.388,
			1664.391,
			1666.398,
			1667.404,
			1669.424,
			1670.435,
			1672.463,
			1675.525,
			1677.574,
			1679.638,
			1682.756,
			1684.848,
			1686.949,
			1689.049,
			1690.101,
			1692.199,
			1693.246,
			1695.322,
			1697.385,
			1700.447,
			1702.466,
			1706.462,
			1707.453,
			1710.411,
			1712.372,
			1715.303,
			1717.247,
			1720.155,
			1722.087,
			1723.053,
			1724.983,
			1727.876,
			1729.804,
			1731.731,
			1735.586,
			1736.55,
			1739.446,
			1740.41,
			1741.375,
			1743.311,
			1744.281,
			1746.223,
			1748.174,
			1751.122,
			1753.103,
			1756.102,
			1757.109,
			1758.117,
			1761.154,
			1763.165,
			1766.192,
			1768.187,
			1770.155,
			1771.127,
			1773.043,
			1774.921,
			1775.846,
			1778.566,
			1780.333,
			1782.063,
			1784.592,
			1786.233,
			1788.634,
			1789.422,
			1790.22,
			1792.537,
			1794.06,
			1796.328,
			1799.342,
			1800.097,
			1801.607,
			1803.118,
			1806.148,
			1806.906,
			1809.179,
			1810.701,
			1812.981,
			1814.503,
			1816.806,
			1818.339,
			1819.106,
			1820.659,
			1822.216,
			1824.547,
			1826.128,
			1829.28,
			1830.074,
			1832.465,
			1834.057,
			1836.471,
			1838.083,
			1840.521,
			1842.169,
			1842.993,
			1844.644,
			1846.334,
			1847.179,
			1848.024,
			1850.561,
			1853.937,
			1854.78,
			1856.441,
			1858.099,
			1858.929,
			1861.393,
			1863.027,
			1864.665,
			1867.103,
			1868.73,
			1871.177,
			1871.993,
			1872.809,
			1875.266,
			1876.904,
			1879.371,
			1881.013,
			1883.477,
			1885.124,
			1885.937,
			1887.566,
			1890.026,
			1891.652,
			1893.287,
			1895.763,
			1897.431,
			1899.951,
			1901.668,
			1904.257,
			1906.012,
			1908.674,
			1910.467,
			1912.291,
			1915.042,
			1915.974,
			1916.907,
			1919.721,
			1921.614,
			1924.462,
			1926.389,
			1928.329,
			1931.263,
			1933.234,
			1934.225,
			1936.214,
			1937.213,
			1939.223,
			1941.245,
			1944.317,
			1946.352,
			1949.456,
			1951.542,
			1954.69,
			1956.791,
			1959.957,
			1962.083,
			1965.292,
			1967.446,
			1968.527,
			1969.612,
			1972.882,
			1973.978,
			1975.077,
			1978.392,
			1980.616,
			1983.975,
			1986.228,
			1988.492,
			1989.629,
			1991.909,
			1993.054,
			1995.351,
			1997.658,
			2003.476,
			2006.999,
			2009.362,
			2012.927,
			2014.121,
			2015.318,
			2018.925,
			2021.345,
			2024.999,
			2027.452,
			2029.918,
			2031.156,
			2033.647,
			2034.896,
			2036.149,
			2041.191,
			2043.727,
			2046.273,
			2048.825,
			2052.665,
			2055.235,
			2057.81,
			2059.101,
			2061.688,
			2062.984,
			2065.584,
			2068.194,
			2072.126,
			2074.756,
			2078.706,
			2081.348,
			2085.33,
			2087.993,
			2092.002,
			2094.684,
			2098.718,
			2101.417,
			2105.477,
			2108.192,
			2112.279,
			2115.013,
			2120.508,
			2121.888,
			2126.04,
			2127.429,
			2128.82,
			2133.006,
			2135.808,
			2138.62,
			2145.685,
			2149.948,
			2151.374,
			2152.802,
			2157.098,
			2159.973,
			2164.302,
			2170.105,
			2171.562,
			2174.479,
			2175.941,
			2178.871,
			2183.281,
			2186.231,
			2189.187,
			2193.633,
			2196.605,
			2202.567,
			2204.062,
			2208.556,
			2211.561,
			2216.083,
			2219.104,
			2222.133,
			2228.207,
			2229.729,
			2235.831,
			2237.36,
			2241.958,
			2248.112,
			2249.654,
			2252.744,
			2255.841,
			2257.392,
			2260.503,
			2262.062,
			2265.188,
			2268.325,
			2273.051,
			2276.214,
			2280.957,
			2282.534,
			2284.104,
			2288.777,
			2291.848,
			2296.378,
			2299.349,
			2302.279,
			2303.729,
			2308.023,
			2310.837,
			2313.614,
			2317.706,
			2320.388,
			2323.034,
			2326.931,
			2329.481,
			2331.994,
			2333.236,
			2336.903,
			2339.301,
			2341.661,
			2345.135,
			2347.406,
			2350.743,
			2351.837,
			2352.922,
			2356.122,
			2358.21,
			2361.267,
			2363.258,
			2366.178,
			2368.081,
			2369.018,
			2370.867,
			2373.577,
			2375.34,
			2377.07,
			2379.611,
			2380.433,
			2381.247,
			2382.85,
			2384.419,
			2385.191,
			2387.473,
			2388.974,
			2390.477,
			2392.717,
			2394.214,
			2396.509,
			2397.272,
			2398.036,
			2400.357,
			2401.912,
			2404.228,
			2405.787,
			2407.326,
			2408.089,
			2409.604,
			2411.107,
			2411.847,
			2414.024,
			2415.461,
			2416.872,
			2418.947,
			2420.322,
			2423.024,
			2423.694,
			2425.715,
			2427.059,
			2429.073,
			2430.413,
			2431.75,
			2434.422,
			2435.081,
			2437.714,
			2438.374,
			2440.356,
			2441.675,
			2443.652,
			2444.993,
			2446.344,
			2447.017,
			2448.364,
			2449.039,
			2450.422,
			2451.804,
			2455.278,
			2457.376,
			2458.78,
			2460.882,
			2462.283,
			2464.396,
			2465.806,
			2467.926,
			2469.353,
			2470.076,
			2471.523,
			2472.974,
			2473.702,
			2474.435,
			2476.67,
			2478.163,
			2480.429,
			2481.952,
			2483.476,
			2484.239,
			2486.538,
			2488.07,
			2489.611,
			2491.944,
			2493.504,
			2495.883,
			2496.682,
			2497.484,
			2499.902,
			2501.534,
			2503.997,
			2507.297,
			2508.128,
			2509.793,
			2510.618,
			2512.276,
			2514.778,
			2516.418,
			2518.07,
			2519.732,
			2522.198,
			2523.859,
			2525.517,
			2526.346,
			2528.013,
			2530.52,
			2533.01,
			2536.364,
			2538.908,
			2539.764,
			2540.627,
			2543.236,
			2544.993,
			2547.669,
			2552.207,
			2554.048,
			2554.973,
			2556.839,
			2558.701,
			2561.54,
			2563.447,
			2566.328,
			2568.263,
			2571.186,
			2572.165,
			2573.147,
			2576.11,
			2578.099,
			2580.099,
			2583.12,
			2585.148,
			2586.166,
			2588.211,
			2589.237,
			2590.266,
			2594.408,
			2595.45,
			2598.59,
			2600.684,
			2603.827,
			2605.934,
			2606.991,
			2609.111,
			2611.241,
			2612.311,
			2614.454,
			2616.607,
			2619.855,
			2622.032,
			2625.313,
			2627.513,
			2630.829,
			2633.051,
			2636.402,
			2638.646,
			2642.029,
			2645.433,
			2646.571,
			2651.147,
			2653.448,
			2655.757,
			2658.075,
			2661.567,
			2663.906,
			2666.254,
			2667.43,
			2670.972,
			2673.345,
			2675.725,
			2679.31,
			2681.709,
			2685.323,
			2686.531,
			2687.742,
			2691.386,
			2693.825,
			2697.5,
			2699.958,
			2702.426,
			2703.662,
			2706.141,
			2708.628,
			2709.874,
			2713.623,
			2716.132,
			2718.649,
			2722.438,
			2724.972,
			2727.514,
			2730.063,
			2731.34,
			2733.899,
			2735.18,
			2737.749,
			2740.323,
			2744.194,
			2746.782,
			2750.675,
			2751.976,
			2753.278,
			2757.19,
			2763.708,
			2766.305,
			2770.189,
			2772.737,
			2774.001,
			2776.504,
			2778.977,
			2780.201,
			2782.635,
			2785.045,
			2788.639,
			2791.028,
			2794.602,
			2795.791,
			2796.981,
			2800.558,
			2802.95,
			2808.983,
			2811.412,
			2815.078,
			2816.301,
			2817.525,
			2821.206,
			2822.43,
			2823.651,
			2827.29,
			2829.696,
			2832.08,
			2833.267,
			2835.628,
			2837.977,
			2839.149,
			2842.661,
			2845.006,
			2847.351,
			2850.886,
			2853.243,
			2857.942,
			2859.11,
			2862.603,
			2864.911,
			2868.353,
			2870.624,
			2872.887,
			2874.011,
			2876.252,
			2877.369,
			2878.479,
			2882.888,
			2883.975,
			2887.228,
			2889.382,
			2892.604,
			2894.751,
			2896.904,
			2897.981,
			2900.135,
			2901.212,
			2903.355,
			2905.525,
			2908.814,
			2911.007,
			2914.294,
			2915.4,
			2916.507,
			2919.846,
			2922.083,
			2925.465,
			2927.732,
			2930.024,
			2931.174,
			2933.503,
			2934.673,
			2937.015,
			2939.372,
			2940.536,
			2942.917,
			2945.285,
			2947.645,
			2951.17,
			2953.477,
			2955.794,
			2956.951,
			2960.414,
			2962.72,
			2965.037,
			2968.485,
			2970.778,
			2974.224,
			2975.37,
			2976.519,
			2979.941,
			2982.256,
			2985.701,
			2988.002,
			2991.455,
			2993.761,
			2994.91,
			2997.197,
			2999.506,
			3000.662,
			3002.979,
			3005.3,
			3008.814,
			3009.98,
			3011.149,
			3014.646,
			3015.821,
			3016.998,
			3020.54,
			3022.91,
			3025.288,
			3028.869,
			3031.266,
			3034.877,
			3037.297,
			3040.944,
			3042.165,
			3043.387,
			3047.068,
			3049.532,
			3052.005,
			3053.244,
			3055.728,
			3058.219,
			3059.467,
			3063.22,
			3065.73,
			3068.247,
			3072.036,
			3074.57,
			3079.657,
			3080.934,
			3084.774,
			3087.345,
			3091.214,
			3093.803,
			3096.4,
			3100.306,
			3102.91,
			3108.136,
			3110.758,
			3113.387,
			3116.022,
			3118.664,
			3119.987,
			3126.625,
			3129.291,
			3130.627,
			3133.303,
			3135.985,
			3140.02,
			3142.718,
			3146.776,
			3148.132,
			3149.489,
			3153.571,
			3156.3,
			3160.406,
			3163.151,
			3165.903,
			3167.281,
			3170.042,
			3171.425,
			3174.194,
			3178.361,
			3179.752,
			3183.938,
			3186.735,
			3190.943,
			3193.755,
			3196.574,
			3197.985,
			3200.813,
			3202.229,
			3205.065,
			3207.908,
			3212.183,
			3215.041,
			3219.337,
			3220.773,
			3222.209,
			3226.527,
			3229.412,
			3233.75,
			3236.649,
			3241.008,
			3245.38,
			3248.301,
			3251.228,
			3252.694,
			3255.631,
			3258.573,
			3262.997,
			3264.475,
			3265.955,
			3270.4,
			3271.884,
			3273.368,
			3277.813,
			3280.758,
			3283.678,
			3287.993,
			3290.823,
			3294.995,
			3296.366,
			3297.728,
			3301.758,
			3304.398,
			3308.287,
			3310.835,
			3313.344,
			3314.585,
			3317.04,
			3318.253,
			3320.654,
			3324.19,
			3326.506,
			3328.784,
			3334.311,
			3337.513,
			3338.561,
			3339.599,
			3342.656,
			3344.663,
			3347.578,
			3349.476,
			3351.336,
			3354.978,
			3356.765,
			3359.426,
			3360.305,
			3362.938,
			3364.691,
			3367.317,
			3369.067,
			3370.817,
			3371.692,
			3373.438,
			3376.076,
			3377.842,
			3380.528,
			3382.366,
			3385.138,
			3386.091,
			3387.042,
			3389.897,
			3391.825,
			3394.712,
			3396.656,
			3398.594,
			3399.562,
			3402.469,
			3404.402,
			3406.341,
			3407.31,
			3409.245,
			3411.187,
			3414.099,
			3416.037,
			3419.9,
			3420.85,
			3423.689,
			3425.563,
			3427.403,
			3430.152,
			3431.92,
			3434.566,
			3435.44,
			3436.302,
			3438.885,
			3440.609,
			3443.203,
			3444.931,
			3447.551,
			3449.3,
			3451.942,
			3453.711,
			3454.597,
			3456.376,
			3458.163,
			3460.854,
			3461.757,
			3462.662,
			3465.392,
			3466.309,
			3467.228,
			3470.007,
			3471.872,
			3474.692,
			3476.588,
			3478.479,
			3482.325,
			3483.293,
			3486.212,
			3488.169,
			3491.125,
			3493.107,
			3496.103,
			3498.113,
			3501.151,
			3504.214,
			3506.271,
			3508.341,
			3511.457,
			3513.539,
			3516.652,
			3517.687,
			3518.72,
			3521.797,
			3523.842,
			3526.836,
			3528.803,
			3530.747,
			3534.595,
			3535.552,
			3539.357,
			3540.303,
			3543.127,
			3544.999,
			3547.792,
			3551.499,
			3552.419,
			3554.26,
			3555.181,
			3557.025,
			3558.867,
			3561.634,
			3563.489,
			3566.273,
			3567.209,
			3568.148,
			3570.967,
			3572.863,
			3575.721,
			3577.636,
			3579.559,
			3580.52,
			3583.415,
			3584.382,
			3588.265,
			3590.22,
			3592.178,
			3595.144,
			3597.124,
			3599.133,
			3601.143,
			3602.15,
			3605.213,
			3607.259,
			3609.331,
			3612.441,
			3614.524,
			3617.646,
			3618.679,
			3619.711,
			3622.806,
			3624.859,
			3627.935,
			3629.976,
			3633.03,
			3635.057,
			3636.07,
			3638.101,
			3640.13,
			3641.145,
			3643.175,
			3645.201,
			3648.232,
			3649.232,
			3650.231,
			3653.227,
			3654.214,
			3655.201,
			3658.164,
			3660.125,
			3663.077,
			3665.041,
			3667.009,
			3669.977,
			3670.968,
			3671.961,
			3674.955,
			3676.958,
			3679.987,
			3682.018,
			3684.065,
			3685.095,
			3687.164,
			3688.201,
			3690.286,
			3693.418,
			3695.527,
			3697.629,
			3700.834,
			3702.986,
			3706.238,
			3707.328,
			3708.419,
			3711.711,
			3713.919,
			3717.249,
			3721.727,
			3722.854,
			3725.115,
			3726.25,
			3727.387,
			3731.965,
			3733.116,
			3736.586,
			3738.914,
			3742.426,
			3744.781,
			3747.148,
			3748.335,
			3750.718,
			3751.914,
			3754.314,
			3756.723,
			3760.358,
			3762.794,
			3766.467,
			3767.697,
			3768.929,
			3772.641,
			3775.128,
			3778.878,
			3781.39,
			3785.176,
			3787.712,
			3788.983,
			3791.533,
			3794.092,
			3795.375,
			3797.948,
			3800.53,
			3803.12,
			3805.719,
			3807.022,
			3809.63,
			3812.24,
			3813.547,
			3817.482,
			3820.116,
			3822.759,
			3826.738,
			3829.402,
			3833.414,
			3836.099,
			3840.143,
			3842.85,
			3846.924,
			3849.652,
			3853.756,
			3856.502,
			3860.632,
			3864.779,
			3867.552,
			3870.332,
			3874.514,
			3875.912,
			3877.312,
			3880.116,
			3884.335,
			3888.57,
			3891.402,
			3894.241,
			3898.513,
			3901.37,
			3905.669,
			3907.105,
			3908.543,
			3914.312,
			3915.759,
			3920.109,
			3923.018,
			3927.393,
			3930.318,
			3931.783,
			3934.717,
			3937.657,
			3939.13,
			3942.08,
			3945.036,
			3949.481,
			3952.452,
			3956.922,
			3958.415,
			3959.909,
			3964.401,
			3967.404,
			3971.92,
			3974.938,
			3977.962,
			3982.508,
			3987.068,
			3990.115,
			3991.641,
			3993.168,
			3997.758,
			4000.852,
			4005.465,
			4008.55,
			4011.644,
			4013.198,
			4016.311,
			4017.88,
			4021.019,
			4024.187,
			4028.971,
			4032.179,
			4037.005,
			4038.609,
			4040.215,
			4045.02,
			4048.213,
			4052.99,
			4056.172,
			4060.916,
			4064.125,
			4068.902,
			4072.087,
			4073.677,
			4076.833,
			4080.02,
			4083.21,
			4086.431,
			4088.029,
			4091.227,
			4094.425,
			4095.999,
			4100.799,
			4104,
			4107.198,
			4111.987,
			4115.181,
			4121.569,
			4123.168,
			4127.967,
			4131.172,
			4135.99,
			4139.208,
			4144.045,
			4147.275,
			4148.892,
			4152.126,
			4156.987,
			4160.231,
			4163.479,
			4168.358,
			4171.615,
			4176.507,
			4178.14,
			4179.773,
			4183.042,
			4184.679,
			4187.954,
			4191.232,
			4196.156,
			4199.444,
			4204.382,
			4206.03,
			4207.679,
			4214.283,
			4215.936,
			4220.898,
			4224.209,
			4229.18,
			4234.155,
			4237.474,
			4240.795,
			4242.457,
			4245.784,
			4249.115,
			4254.116,
			4257.455,
			4262.471,
			4264.145,
			4265.82,
			4270.85,
			4274.207,
			4277.567,
			4285.983,
			4287.668,
			4291.041,
			4292.729,
			4294.417,
			4301.178,
			4302.87,
			4307.95,
			4311.34,
			4316.43,
			4319.827,
			4323.227,
			4324.927,
			4330.033,
			4333.439,
			4336.849,
			4341.961,
			4345.354,
			4350.395,
			4353.71,
			4358.608,
			4361.823,
			4366.569,
			4369.684,
			4374.281,
			4377.298,
			4378.792,
			4381.753,
			4386.123,
			4388.986,
			4391.81,
			4394.595,
			4397.34,
			4398.698,
			4401.384,
			4404.032,
			4407.93,
			4409.21,
			4411.741,
			4414.233,
			4417.897,
			4420.292,
			4423.815,
			4426.117,
			4429.496,
			4431.697,
			4434.926,
			4439.095,
			4440.114,
			4442.122,
			4445.064,
			4447.92,
			4449.776,
			4451.597,
			4454.262,
			4455.998,
			4458.53,
			4459.355,
			4460.172,
			4462.569,
			4464.12,
			4465.636,
			4467.839,
			4469.26,
			4471.315,
			4471.981,
			4472.639,
			4474.577,
			4475.822,
			4477.637,
			4478.822,
			4480.562,
			4481.693,
			4482.815,
			4483.374,
			4484.487,
			4485.043,
			4486.139,
			4487.229,
			4488.855,
			4489.397,
			4489.939,
			4491.629,
			4492.191,
			4492.754,
			4494.442,
			4495.653,
			4497.653,
			4498.989,
			4500.37,
			4502.809,
			4503.621,
			4504.435,
			4506.853,
			4507.655,
			4508.459,
			4510.755,
			4512.084,
			4514.087,
			4516.606,
			4517.205,
			4519.01,
			4520.219,
			4521.411,
			4523.196,
			4524.381,
			4526.181,
			4526.793,
			4527.403,
			4529.219,
			4530.41,
			4532.554,
			4533.975,
			4536.233,
			4538.028,
			4538.922,
			4540.692,
			4542.273,
			4543.059,
			4544.623,
			4546.083,
			4547.336,
			4548.584,
			4549.207,
			4550.452,
			4551.594,
			4552.152,
			4553.823,
			4554.937,
			4556.041,
			4557.654,
			4558.73,
			4560.35,
			4560.895,
			4561.453,
			4563.129,
			4563.69,
			4564.252,
			4565.952,
			4567.139,
			4568.331,
			4568.93,
			4570.134,
			4570.74,
			4571.967,
			4573.827,
			4575.08,
			4576.343,
			4578.249,
			4579.533,
			4581.484,
			4582.798,
			4584.795,
			4586.145,
			4587.507,
			4589.579,
			4590.98,
			4593.11,
			4593.824,
			4594.542,
			4597.445,
			4598.185,
			4600.427,
			4601.944,
			4604.255,
			4605.814,
			4606.598,
			4608.18,
			4609.777,
			4610.58,
			4612.198,
			4613.828,
			4616.3,
			4617.964,
			4620.479,
			4621.316,
			4622.154,
			4624.682,
			4626.383,
			4628.956,
			4630.686,
			4632.428,
			4633.304,
			4635.062,
			4635.946,
			4636.833,
			4639.508,
			4640.404,
			4641.305,
			4644.02,
			4645.844,
			4648.601,
			4650.454,
			4652.318,
			4653.256,
			4656.086,
			4657.989,
			4659.906,
			4662.802,
			4664.751,
			4667.696,
			4668.684,
			4669.673,
			4672.659,
			4674.664,
			4677.69,
			4679.723,
			4682.792,
			4684.852,
			4685.886,
			4687.962,
			4690.048,
			4691.095,
			4693.185,
			4695.275,
			4697.374,
			4700.539,
			4702.661,
			4704.793,
			4705.862,
			4709.083,
			4711.241,
			4713.409,
			4716.678,
			4718.869,
			4722.174,
			4723.28,
			4724.389,
			4727.728,
			4729.957,
			4733.292,
			4737.667,
			4738.744,
			4740.868,
			4741.916,
			4743.984,
			4747.013,
			4748.985,
			4750.92,
			4753.751,
			4755.593,
			4759.169,
			4760.041,
			4761.754,
			4762.597,
			4764.256,
			4765.878,
			4768.278,
			4769.85,
			4772.18,
			4772.952,
			4773.723,
			4776.031,
			4777.566,
			4779.862,
			4781.393,
			4783.682,
			4785.206,
			4787.493,
			4789.014,
			4789.774,
			4791.297,
			4792.82,
			4795.094,
			4796.625,
			4798.917,
			4799.678,
			4800.438,
			4802.75,
			4804.287,
			4806.598,
			4808.147,
			4809.693,
			4812.802,
			4813.582,
			4815.918,
			4816.706,
			4817.494,
			4819.861,
			4821.449,
			4823.872,
			4827.136,
			4827.971,
			4830.478,
			4832.186,
			4833.935,
			4836.562,
			4838.396,
			4841.14,
			4842.961,
			4845.683,
			4847.461,
			4850.059,
			4851.788,
			4854.342,
			4856.041,
			4856.89,
			4858.557,
			4861.088,
			4861.931,
			4864.472,
			4866.175,
			4867.894,
			4868.762,
			4870.504,
			4872.257,
			4873.134,
			4875.775,
			4877.549,
			4879.305,
			4881.957,
			4883.705,
			4886.311,
			4887.183,
			4888.054,
			4890.64,
			4892.37,
			4894.958,
			4896.686,
			4898.415,
			4899.275,
			4901,
			4901.864,
			4903.596,
			4906.198,
			4907.942,
			4909.697,
			4912.344,
			4914.125,
			4915.925,
			4917.733,
			4918.646,
			4921.426,
			4923.295,
			4925.189,
			4929.973,
			4932.894,
			4933.873,
			4934.856,
			4937.822,
			4939.816,
			4942.832,
			4944.857,
			4947.923,
			4949.982,
			4951.017,
			4953.095,
			4955.185,
			4958.338,
			4960.439,
			4963.603,
			4965.725,
			4968.925,
			4969.997,
			4971.071,
			4974.307,
			4976.476,
			4979.746,
			4981.938,
			4984.138,
			4989.678,
			4994.152,
			4995.276,
			4998.664,
			5000.935,
			5004.361,
			5006.658,
			5008.965,
			5010.123,
			5013.61,
			5015.948,
			5018.295,
			5021.835,
			5024.207,
			5027.781,
			5028.977,
			5030.174,
			5033.782,
			5036.198,
			5039.839,
			5042.277,
			5045.951,
			5048.411,
			5049.646,
			5052.121,
			5054.605,
			5055.852,
			5058.35,
			5060.859,
			5065.907,
			5067.175,
			5072.271,
			5073.551,
			5077.402,
			5079.979,
			5082.565,
			5086.458,
			5089.062,
			5092.978,
			5094.283,
			5095.589,
			5099.517,
			5102.144,
			5106.096,
			5108.739,
			5111.389,
			5112.717,
			5115.378,
			5116.711,
			5119.383,
			5123.209
		],
		[
			0,
			0.023,
			0.055,
			0.14,
			0.179,
			0.239,
			0.303,
			0.365,
			0.457,
			0.488,
			0.549,
			0.568,
			0.613,
			0.673,
			0.735,
			0.783,
			0.86,
			0.921,
			1,
			1.047,
			1.109,
			1.232,
			1.292,
			1.325,
			1.357,
			1.419,
			1.434,
			1.481,
			1.543,
			1.605,
			1.665,
			1.728,
			1.789,
			1.869,
			1.915,
			1.979,
			2.038,
			2.099,
			2.163,
			2.223,
			2.302,
			2.349,
			2.411,
			2.471,
			2.536,
			2.595,
			2.659,
			2.736,
			2.782,
			2.845,
			2.907,
			2.952,
			2.969,
			3.094,
			3.154,
			3.217,
			3.279,
			3.34,
			3.403,
			3.465,
			3.527,
			3.605,
			3.65,
			3.713,
			3.775,
			3.822,
			3.835,
			3.899,
			3.929,
			3.962,
			4.083,
			4.147,
			4.209,
			4.27,
			4.365,
			4.396,
			4.472,
			4.518,
			4.58,
			4.644,
			4.689,
			4.706,
			4.766,
			4.799,
			4.829,
			4.889,
			4.953,
			5.016,
			5.075,
			5.139,
			5.203,
			5.263,
			5.34,
			5.385,
			5.449,
			5.511,
			5.558,
			5.635,
			5.665,
			5.695,
			5.775,
			5.822,
			5.886,
			5.945,
			6.007,
			6.069,
			6.099,
			6.131,
			6.209,
			6.256,
			6.321,
			6.379,
			6.425,
			6.442,
			6.536,
			6.564,
			6.641,
			6.688,
			6.752,
			6.814,
			6.876,
			6.937,
			6.969,
			7,
			7.061,
			7.123,
			7.186,
			7.246,
			7.294,
			7.308,
			7.371,
			7.403,
			7.434,
			7.511,
			7.558,
			7.622,
			7.682,
			7.743,
			7.805,
			7.835,
			7.867,
			7.929,
			7.946,
			7.99,
			8.054,
			8.116,
			8.159,
			8.239,
			8.27,
			8.301,
			8.386,
			8.425,
			8.489,
			8.551,
			8.597,
			8.612,
			8.705,
			8.734,
			8.796,
			8.813,
			8.861,
			8.921,
			8.983,
			9.046,
			9.107,
			9.139,
			9.169,
			9.247,
			9.294,
			9.357,
			9.419,
			9.48,
			9.573,
			9.603,
			9.667,
			9.728,
			9.789,
			9.85,
			9.916,
			9.975,
			10.007,
			10.039,
			10.115,
			10.161,
			10.225,
			10.285,
			10.347,
			10.409,
			10.471,
			10.534,
			10.596,
			10.659,
			10.721,
			10.782,
			10.844,
			10.876,
			10.907,
			10.984,
			11.03,
			11.09,
			11.154,
			11.215,
			11.279,
			11.308,
			11.341,
			11.402,
			11.418,
			11.464,
			11.524,
			11.589,
			11.649,
			11.713,
			11.742,
			11.775,
			11.852,
			11.898,
			11.961,
			12.022,
			12.084,
			12.178,
			12.207,
			12.27,
			12.288,
			12.334,
			12.399,
			12.457,
			12.519,
			12.58,
			12.612,
			12.641,
			12.719,
			12.766,
			12.83,
			12.891,
			12.953,
			13.014,
			13.074,
			13.139,
			13.2,
			13.263,
			13.325,
			13.386,
			13.479,
			13.511,
			13.586,
			13.633,
			13.695,
			13.759,
			13.804,
			13.819,
			13.885,
			13.913,
			13.945,
			14.069,
			14.13,
			14.191,
			14.256,
			14.317,
			14.348,
			14.379,
			14.442,
			14.456,
			14.503,
			14.569,
			14.673,
			14.69,
			14.75,
			14.781,
			14.813,
			14.889,
			14.936,
			15,
			15.062,
			15.122,
			15.217,
			15.247,
			15.322,
			15.371,
			15.435,
			15.494,
			15.541,
			15.556,
			15.619,
			15.65,
			15.68,
			15.758,
			15.803,
			15.868,
			15.93,
			15.992,
			16.053,
			16.083,
			16.116,
			16.175,
			16.193,
			16.24,
			16.302,
			16.363,
			16.424,
			16.485,
			16.549,
			16.63,
			16.672,
			16.736,
			16.797,
			16.844,
			16.858,
			16.922,
			16.983,
			17.061,
			17.108,
			17.171,
			17.229,
			17.294,
			17.354,
			17.386,
			17.417,
			17.495,
			17.542,
			17.605,
			17.664,
			17.728,
			17.789,
			17.819,
			17.914,
			17.974,
			18.037,
			18.1,
			18.162,
			18.224,
			18.253,
			18.285,
			18.347,
			18.363,
			18.408,
			18.471,
			18.533,
			18.58,
			18.658,
			18.718,
			18.797,
			18.844,
			18.905,
			18.967,
			19.03,
			19.092,
			19.121,
			19.153,
			19.216,
			19.23,
			19.278,
			19.339,
			19.401,
			19.447,
			19.463,
			19.524,
			19.586,
			19.664,
			19.711,
			19.774,
			19.836,
			19.896,
			19.958,
			19.991,
			20.021,
			20.084,
			20.146,
			20.208,
			20.269,
			20.33,
			20.391,
			20.456,
			20.535,
			20.58,
			20.641,
			20.705,
			20.766,
			20.828,
			20.858,
			20.889,
			20.966,
			21.013,
			21.074,
			21.138,
			21.185,
			21.263,
			21.324,
			21.401,
			21.447,
			21.511,
			21.572,
			21.619,
			21.635,
			21.697,
			21.728,
			21.757,
			21.818,
			21.836,
			21.883,
			21.945,
			22.006,
			22.067,
			22.129,
			22.162,
			22.192,
			22.269,
			22.316,
			22.38,
			22.439,
			22.485,
			22.562,
			22.595,
			22.624,
			22.688,
			22.749,
			22.819,
			22.874,
			22.936,
			22.997,
			23.03,
			23.06,
			23.139,
			23.184,
			23.247,
			23.308,
			23.356,
			23.433,
			23.462,
			23.494,
			23.557,
			23.617,
			23.68,
			23.742,
			23.803,
			23.867,
			23.898,
			23.93,
			24.005,
			24.052,
			24.114,
			24.221,
			24.238,
			24.299,
			24.33,
			24.362,
			24.44,
			24.486,
			24.549,
			24.611,
			24.673,
			24.764,
			24.797,
			24.878,
			24.921,
			24.983,
			25.046,
			25.089,
			25.105,
			25.168,
			25.203,
			25.229,
			25.307,
			25.353,
			25.478,
			25.539,
			25.602,
			25.633,
			25.665,
			25.728,
			25.788,
			25.85,
			25.913,
			25.96,
			26.036,
			26.067,
			26.099,
			26.175,
			26.221,
			26.286,
			26.408,
			26.471,
			26.501,
			26.532,
			26.595,
			26.611,
			26.656,
			26.719,
			26.78,
			26.841,
			26.905,
			26.941,
			26.965,
			27.044,
			27.091,
			27.152,
			27.214,
			27.262,
			27.339,
			27.369,
			27.401,
			27.463,
			27.524,
			27.586,
			27.649,
			27.712,
			27.773,
			27.803,
			27.835,
			27.912,
			27.958,
			28.022,
			28.082,
			28.13,
			28.146,
			28.238,
			28.269,
			28.329,
			28.347,
			28.39,
			28.456,
			28.516,
			28.578,
			28.641,
			28.671,
			28.705,
			28.78,
			28.828,
			28.89,
			28.951,
			29.004,
			29.014,
			29.075,
			29.137,
			29.199,
			29.216,
			29.262,
			29.322,
			29.386,
			29.446,
			29.51,
			29.541,
			29.571,
			29.647,
			29.696,
			29.758,
			29.819,
			29.881,
			29.941,
			29.975,
			30.005,
			30.067,
			30.083,
			30.13,
			30.189,
			30.252,
			30.316,
			30.377,
			30.44,
			30.518,
			30.564,
			30.625,
			30.686,
			30.75,
			30.812,
			30.842,
			30.874,
			30.95,
			30.998,
			31.057,
			31.12,
			31.184,
			31.245,
			31.274,
			31.306,
			31.371,
			31.384,
			31.43,
			31.494,
			31.556,
			31.602,
			31.618,
			31.679,
			31.71,
			31.741,
			31.819,
			31.867,
			31.928,
			31.99,
			32.05,
			32.113,
			32.175,
			32.236,
			32.3,
			32.363,
			32.471,
			32.484,
			32.546,
			32.58,
			32.609,
			32.687,
			32.732,
			32.795,
			32.858,
			32.921,
			32.98,
			33.012,
			33.042,
			33.107,
			33.168,
			33.23,
			33.291,
			33.354,
			33.446,
			33.478,
			33.554,
			33.602,
			33.663,
			33.724,
			33.773,
			33.788,
			33.879,
			33.913,
			33.973,
			33.995,
			34.038,
			34.097,
			34.207,
			34.283,
			34.314,
			34.347,
			34.427,
			34.469,
			34.592,
			34.657,
			34.718,
			34.749,
			34.78,
			34.842,
			34.905,
			34.966,
			35.029,
			35.074,
			35.089,
			35.152,
			35.188,
			35.214,
			35.291,
			35.337,
			35.4,
			35.463,
			35.524,
			35.586,
			35.616,
			35.648,
			35.727,
			35.771,
			35.833,
			35.896,
			35.943,
			35.958,
			36.02,
			36.05,
			36.083,
			36.158,
			36.205,
			36.27,
			36.33,
			36.392,
			36.453,
			36.485,
			36.515,
			36.579,
			36.64,
			36.706,
			36.763,
			36.811,
			36.887,
			36.95,
			37.028,
			37.073,
			37.135,
			37.197,
			37.261,
			37.324,
			37.353,
			37.386,
			37.446,
			37.463,
			37.507,
			37.572,
			37.632,
			37.678,
			37.694,
			37.756,
			37.788,
			37.817,
			37.896,
			37.942,
			38.007,
			38.066,
			38.13,
			38.192,
			38.222,
			38.252,
			38.313,
			38.33,
			38.375,
			38.44,
			38.499,
			38.562,
			38.624,
			38.657,
			38.686,
			38.763,
			38.811,
			38.872,
			38.935,
			38.98,
			38.996,
			39.057,
			39.089,
			39.122,
			39.183,
			39.244,
			39.312,
			39.369,
			39.429,
			39.49,
			39.523,
			39.553,
			39.632,
			39.679,
			39.74,
			39.804,
			39.849,
			39.866,
			39.927,
			39.989,
			40.052,
			40.065,
			40.113,
			40.174,
			40.236,
			40.301,
			40.361,
			40.391,
			40.424,
			40.499,
			40.547,
			40.609,
			40.67,
			40.717,
			40.733,
			40.795,
			40.825,
			40.858,
			40.933,
			40.981,
			41.041,
			41.106,
			41.167,
			41.229,
			41.261,
			41.291,
			41.354,
			41.374,
			41.416,
			41.478,
			41.54,
			41.585,
			41.694,
			41.724,
			41.802,
			41.85,
			41.912,
			41.972,
			42.036,
			42.098,
			42.129,
			42.157,
			42.235,
			42.286,
			42.346,
			42.453,
			42.469,
			42.531,
			42.562,
			42.591,
			42.654,
			42.672,
			42.717,
			42.779,
			42.842,
			42.905,
			42.964,
			42.996,
			43.028,
			43.088,
			43.152,
			43.214,
			43.275,
			43.323,
			43.336,
			43.401,
			43.438,
			43.463,
			43.539,
			43.586,
			43.647,
			43.711,
			43.772,
			43.833,
			43.863,
			43.896,
			43.958,
			44.02,
			44.083,
			44.189,
			44.207,
			44.266,
			44.329,
			44.407,
			44.452,
			44.516,
			44.578,
			44.639,
			44.701,
			44.765,
			44.825,
			44.842,
			44.887,
			44.95,
			45.012,
			45.059,
			45.166,
			45.196,
			45.275,
			45.322,
			45.388,
			45.446,
			45.507,
			45.568,
			45.6,
			45.633,
			45.694,
			45.71,
			45.755,
			45.819,
			45.926,
			45.943,
			46.004,
			46.036,
			46.066,
			46.145,
			46.189,
			46.252,
			46.315,
			46.377,
			46.439,
			46.468,
			46.5,
			46.563,
			46.624,
			46.686,
			46.747,
			46.808,
			46.872,
			46.903,
			46.934,
			47.012,
			47.059,
			47.121,
			47.183,
			47.245,
			47.306,
			47.369,
			47.43,
			47.493,
			47.558,
			47.617,
			47.679,
			47.739,
			47.772,
			47.802,
			47.88,
			47.927,
			47.987,
			48.05,
			48.097,
			48.175,
			48.205,
			48.235,
			48.297,
			48.314,
			48.362,
			48.424,
			48.484,
			48.546,
			48.607,
			48.672,
			48.749,
			48.796,
			48.857,
			48.919,
			48.98,
			49.042,
			49.073,
			49.103,
			49.166,
			49.182,
			49.229,
			49.29,
			49.354,
			49.414,
			49.475,
			49.507,
			49.54,
			49.601,
			49.622,
			49.663,
			49.724,
			49.832,
			49.911,
			49.971,
			50.035,
			50.066,
			50.097,
			50.158,
			50.222,
			50.285,
			50.346,
			50.392,
			50.406,
			50.47,
			50.5,
			50.529,
			50.609,
			50.655,
			50.718,
			50.779,
			50.842,
			50.902,
			50.933,
			50.966,
			51.025,
			51.042,
			51.09,
			51.15,
			51.212,
			51.261,
			51.335,
			51.397,
			51.478,
			51.523,
			51.584,
			51.646,
			51.707,
			51.772,
			51.833,
			51.895,
			51.957,
			52.019,
			52.128,
			52.144,
			52.206,
			52.235,
			52.267,
			52.346,
			52.392,
			52.453,
			52.577,
			52.639,
			52.669,
			52.702,
			52.763,
			52.824,
			52.887,
			52.995,
			53.011,
			53.072,
			53.103,
			53.135,
			53.212,
			53.26,
			53.322,
			53.384,
			53.446,
			53.539,
			53.569,
			53.632,
			53.692,
			53.759,
			53.816,
			53.88,
			53.972,
			54.002,
			54.081,
			54.125,
			54.19,
			54.298,
			54.313,
			54.375,
			54.407,
			54.436,
			54.498,
			54.515,
			54.563,
			54.625,
			54.685,
			54.749,
			54.809,
			54.84,
			54.872,
			54.952,
			54.996,
			55.059,
			55.119,
			55.166,
			55.182,
			55.275,
			55.306,
			55.366,
			55.43,
			55.492,
			55.554,
			55.616,
			55.679,
			55.708,
			55.738,
			55.821,
			55.863,
			55.927,
			55.987,
			56.033,
			56.113,
			56.142,
			56.175,
			56.235,
			56.299,
			56.361,
			56.421,
			56.483,
			56.546,
			56.577,
			56.609,
			56.686,
			56.732,
			56.792,
			56.857,
			56.903,
			56.917,
			56.98,
			57.012,
			57.041,
			57.12,
			57.166,
			57.228,
			57.291,
			57.353,
			57.415,
			57.445,
			57.475,
			57.553,
			57.599,
			57.667,
			57.725,
			57.769,
			57.786,
			57.846,
			57.887,
			57.911,
			57.987,
			58.034,
			58.096,
			58.157,
			58.219,
			58.314,
			58.345,
			58.407,
			58.469,
			58.531,
			58.593,
			58.637,
			58.747,
			58.779,
			58.855,
			58.903,
			58.964,
			59.025,
			59.089,
			59.15,
			59.181,
			59.213,
			59.275,
			59.289,
			59.335,
			59.399,
			59.508,
			59.585,
			59.648,
			59.724,
			59.769,
			59.835,
			59.895,
			59.956,
			60.018,
			60.049,
			60.081,
			60.141,
			60.159,
			60.204,
			60.269,
			60.33,
			60.391,
			60.453,
			60.483,
			60.515,
			60.592,
			60.638,
			60.702,
			60.763,
			60.826,
			60.888,
			60.916,
			60.95,
			61.01,
			61.072,
			61.135,
			61.244,
			61.258,
			61.321,
			61.353,
			61.385,
			61.461,
			61.508,
			61.569,
			61.629,
			61.691,
			61.755,
			61.785,
			61.817,
			61.88,
			61.939,
			62.007,
			62.112,
			62.188,
			62.219,
			62.251,
			62.329,
			62.375,
			62.442,
			62.562,
			62.624,
			62.653,
			62.683,
			62.746,
			62.807,
			62.871,
			62.933,
			62.996,
			63.057,
			63.087,
			63.119,
			63.197,
			63.243,
			63.305,
			63.366,
			63.414,
			63.43,
			63.49,
			63.522,
			63.553,
			63.632,
			63.678,
			63.739,
			63.802,
			63.863,
			63.926,
			63.955,
			63.986,
			64.05,
			64.072,
			64.111,
			64.174,
			64.236,
			64.297,
			64.357,
			64.421,
			64.483,
			64.545,
			64.606,
			64.668,
			64.732,
			64.825,
			64.855,
			64.933,
			64.98,
			65.04,
			65.102,
			65.166,
			65.228,
			65.257,
			65.289,
			65.367,
			65.413,
			65.476,
			65.539,
			65.599,
			65.662,
			65.691,
			65.722,
			65.785,
			65.849,
			65.911,
			65.973,
			66.019,
			66.032,
			66.096,
			66.13,
			66.158,
			66.234,
			66.28,
			66.342,
			66.406,
			66.468,
			66.529,
			66.56,
			66.591,
			66.653,
			66.669,
			66.715,
			66.777,
			66.841,
			66.885,
			66.962,
			66.996,
			67.024,
			67.104,
			67.211,
			67.274,
			67.335,
			67.396,
			67.428,
			67.458,
			67.521,
			67.537,
			67.584,
			67.646,
			67.708,
			67.755,
			67.833,
			67.863,
			67.894,
			67.97,
			68.019,
			68.14,
			68.204,
			68.266,
			68.296,
			68.328,
			68.391,
			68.405,
			68.452,
			68.514,
			68.577,
			68.624,
			68.638,
			68.699,
			68.731,
			68.764,
			68.84,
			68.886,
			68.95,
			69.011,
			69.071,
			69.166,
			69.196,
			69.258,
			69.32,
			69.383,
			69.445,
			69.489,
			69.507,
			69.567,
			69.599,
			69.63,
			69.707,
			69.754,
			69.817,
			69.878,
			69.939,
			70.002,
			70.032,
			70.065,
			70.127,
			70.14,
			70.189,
			70.253,
			70.312,
			70.374,
			70.435,
			70.466,
			70.497,
			70.575,
			70.621,
			70.685,
			70.747,
			70.791,
			70.808,
			70.871,
			70.902,
			70.932,
			70.994,
			71.011,
			71.057,
			71.121,
			71.18,
			71.241,
			71.305,
			71.335,
			71.366,
			71.445,
			71.491,
			71.553,
			71.614,
			71.661,
			71.738,
			71.769,
			71.799,
			71.878,
			71.926,
			71.988,
			72.049,
			72.111,
			72.174,
			72.202,
			72.235,
			72.296,
			72.319,
			72.358,
			72.421,
			72.481,
			72.528,
			72.545,
			72.608,
			72.638,
			72.669,
			72.747,
			72.792,
			72.855,
			72.915,
			72.978,
			73.041,
			73.102,
			73.164,
			73.18,
			73.227,
			73.287,
			73.35,
			73.396,
			73.413,
			73.476,
			73.506,
			73.536,
			73.614,
			73.66,
			73.724,
			73.785,
			73.846,
			73.939,
			73.97,
			74.033,
			74.095,
			74.157,
			74.22,
			74.28,
			74.341,
			74.381,
			74.483,
			74.53,
			74.592,
			74.652,
			74.699,
			74.717,
			74.839,
			74.902,
			74.916,
			74.964,
			75.025,
			75.088,
			75.149,
			75.212,
			75.242,
			75.272,
			75.35,
			75.396,
			75.458,
			75.522,
			75.568,
			75.584,
			75.645,
			75.675,
			75.707,
			75.785,
			75.83,
			75.895,
			75.954,
			76.016,
			76.08,
			76.111,
			76.142,
			76.202,
			76.219,
			76.266,
			76.329,
			76.39,
			76.451,
			76.513,
			76.544,
			76.576,
			76.653,
			76.699,
			76.762,
			76.824,
			76.886,
			76.979,
			77.01,
			77.071,
			77.087,
			77.133,
			77.195,
			77.257,
			77.304,
			77.32,
			77.381,
			77.413,
			77.442,
			77.52,
			77.568,
			77.63,
			77.692,
			77.755,
			77.816,
			77.847,
			77.877,
			77.941,
			78.002,
			78.063,
			78.125,
			78.173,
			78.186,
			78.248,
			78.279,
			78.311,
			78.389,
			78.435,
			78.504,
			78.622,
			78.684,
			78.713,
			78.745,
			78.808,
			78.868,
			78.933,
			78.993,
			79.039,
			79.15,
			79.18,
			79.258,
			79.303,
			79.369,
			79.429,
			79.489,
			79.553,
			79.583,
			79.615,
			79.675,
			79.739,
			79.8,
			79.863,
			79.923,
			79.987,
			80.016,
			80.047,
			80.127,
			80.172,
			80.234,
			80.297,
			80.342,
			80.358,
			80.452,
			80.483,
			80.544,
			80.563,
			80.606,
			80.669,
			80.73,
			80.794,
			80.886,
			80.916,
			80.994,
			81.041,
			81.102,
			81.165,
			81.225,
			81.288,
			81.319,
			81.35,
			81.413,
			81.474,
			81.535,
			81.6,
			81.661,
			81.722,
			81.786,
			81.846,
			81.863,
			81.907,
			81.972,
			82.032,
			82.079,
			82.095,
			82.156,
			82.188,
			82.219,
			82.297,
			82.342,
			82.406,
			82.466,
			82.529,
			82.624,
			82.652,
			82.714,
			82.776,
			82.84,
			82.9,
			82.963,
			83.025,
			83.086,
			83.164,
			83.211,
			83.272,
			83.333,
			83.395,
			83.457,
			83.488,
			83.522,
			83.597,
			83.645,
			83.708,
			83.77,
			83.816,
			83.924,
			83.954,
			84.034,
			84.079,
			84.14,
			84.203,
			84.264,
			84.358,
			84.388,
			84.45,
			84.466,
			84.513,
			84.575,
			84.636,
			84.699,
			84.761,
			84.793,
			84.821,
			84.901,
			84.948,
			85.009,
			85.071,
			85.116,
			85.133,
			85.224,
			85.255,
			85.333,
			85.381,
			85.441,
			85.505,
			85.568,
			85.629,
			85.66,
			85.691,
			85.767,
			85.818,
			85.877,
			85.94,
			85.986,
			86.064,
			86.094,
			86.124,
			86.187,
			86.25,
			86.313,
			86.374,
			86.434,
			86.497,
			86.528,
			86.56,
			86.621,
			86.636,
			86.683,
			86.753,
			86.807,
			86.854,
			86.868,
			86.93,
			86.962,
			86.993,
			87.071,
			87.116,
			87.18,
			87.242,
			87.303,
			87.365,
			87.396,
			87.428,
			87.489,
			87.55,
			87.614,
			87.675,
			87.721,
			87.738,
			87.799,
			87.83,
			87.862,
			87.939,
			87.985,
			88.047,
			88.109,
			88.172,
			88.233,
			88.263,
			88.295,
			88.357,
			88.418,
			88.482,
			88.591,
			88.606,
			88.666,
			88.7,
			88.73,
			88.811,
			88.852,
			88.917,
			88.978,
			89.039,
			89.1,
			89.133,
			89.164,
			89.225,
			89.242,
			89.289,
			89.349,
			89.412,
			89.459,
			89.537,
			89.566,
			89.598,
			89.674,
			89.723,
			89.787,
			89.845,
			89.908,
			89.97,
			90.002,
			90.033,
			90.095,
			90.108,
			90.156,
			90.217,
			90.279,
			90.328,
			90.34,
			90.405,
			90.435,
			90.466,
			90.542,
			90.59,
			90.654,
			90.715,
			90.774,
			90.838,
			90.9,
			90.962,
			91.022,
			91.085,
			91.148,
			91.211,
			91.273,
			91.333,
			91.413,
			91.459,
			91.52,
			91.583,
			91.629,
			91.645,
			91.705,
			91.769,
			91.831,
			91.892,
			91.955,
			92.015,
			92.077,
			92.141,
			92.172,
			92.203,
			92.28,
			92.324,
			92.387,
			92.449,
			92.511,
			92.575,
			92.604,
			92.637,
			92.697,
			92.714,
			92.759,
			92.822,
			92.883,
			92.947,
			93.007,
			93.04,
			93.069,
			93.147,
			93.194,
			93.258,
			93.319,
			93.365,
			93.441,
			93.505,
			93.565,
			93.583,
			93.628,
			93.691,
			93.753,
			93.813,
			93.878,
			93.908,
			93.939,
			93.999,
			94.017,
			94.063,
			94.125,
			94.187,
			94.232,
			94.311,
			94.342,
			94.373,
			94.449,
			94.497,
			94.558,
			94.62,
			94.683,
			94.744,
			94.806,
			94.869,
			94.885,
			94.931,
			94.996,
			95.054,
			95.102,
			95.116,
			95.179,
			95.208,
			95.242,
			95.317,
			95.364,
			95.428,
			95.487,
			95.552,
			95.644,
			95.674,
			95.738,
			95.797,
			95.861,
			95.924,
			95.984,
			96.046,
			96.078,
			96.109,
			96.186,
			96.233,
			96.295,
			96.358,
			96.418,
			96.48,
			96.511,
			96.541,
			96.604,
			96.621,
			96.666,
			96.729,
			96.789,
			96.837,
			96.914,
			96.947,
			96.975,
			97.061,
			97.102,
			97.163,
			97.225,
			97.287,
			97.379,
			97.411,
			97.473,
			97.489,
			97.536,
			97.597,
			97.66,
			97.721,
			97.783,
			97.813,
			97.846,
			97.922,
			97.969,
			98.03,
			98.139,
			98.156,
			98.216,
			98.249,
			98.279,
			98.358,
			98.402,
			98.466,
			98.528,
			98.589,
			98.652,
			98.682,
			98.714,
			98.789,
			98.836,
			98.898,
			98.961,
			99.008,
			99.085,
			99.147,
			99.208,
			99.271,
			99.335,
			99.395,
			99.457,
			99.519,
			99.55,
			99.582,
			99.659,
			99.706,
			99.769,
			99.83,
			99.875,
			99.89,
			99.953,
			99.985,
			100.078,
			100.092,
			100.14,
			100.202,
			100.263,
			100.325,
			100.389,
			100.45,
			100.528,
			100.574,
			100.636,
			100.745,
			100.758,
			100.823,
			100.883,
			100.961,
			101.007,
			101.069,
			101.132,
			101.195,
			101.255,
			101.288,
			101.319,
			101.381,
			101.441,
			101.504,
			101.567,
			101.612,
			101.689,
			101.72,
			101.75,
			101.829,
			101.874,
			101.938,
			102,
			102.063,
			102.124,
			102.155,
			102.186,
			102.249,
			102.262,
			102.311,
			102.371,
			102.435,
			102.48,
			102.496,
			102.559,
			102.589,
			102.62,
			102.697,
			102.745,
			102.806,
			102.867,
			102.93,
			102.992,
			103.023,
			103.053,
			103.116,
			103.132,
			103.178,
			103.244,
			103.302,
			103.363,
			103.458,
			103.489,
			103.565,
			103.613,
			103.674,
			103.737,
			103.782,
			103.859,
			103.89,
			103.923,
			103.985,
			104.046,
			104.107,
			104.171,
			104.232,
			104.294,
			104.324,
			104.355,
			104.418,
			104.433,
			104.481,
			104.541,
			104.603,
			104.652,
			104.665,
			104.729,
			104.759,
			104.79,
			104.868,
			104.915,
			104.977,
			105.039,
			105.099,
			105.162,
			105.193,
			105.224,
			105.285,
			105.309,
			105.349,
			105.411,
			105.472,
			105.518,
			105.536,
			105.595,
			105.628,
			105.659,
			105.735,
			105.784,
			105.845,
			105.906,
			105.968,
			106.03,
			106.091,
			106.17,
			106.216,
			106.278,
			106.34,
			106.386,
			106.463,
			106.497,
			106.525,
			106.605,
			106.651,
			106.713,
			106.776,
			106.838,
			106.9,
			106.929,
			106.962,
			107.021,
			107.038,
			107.083,
			107.147,
			107.209,
			107.269,
			107.333,
			107.371,
			107.394,
			107.471,
			107.519,
			107.581,
			107.644,
			107.688,
			107.705,
			107.767,
			107.797,
			107.829,
			107.889,
			107.906,
			107.952,
			108.016,
			108.075,
			108.139,
			108.202,
			108.232,
			108.262,
			108.339,
			108.385,
			108.45,
			108.512,
			108.559,
			108.634,
			108.666,
			108.697,
			108.757,
			108.821,
			108.883,
			108.945,
			109.008,
			109.07,
			109.1,
			109.131,
			109.192,
			109.209,
			109.256,
			109.316,
			109.38,
			109.432,
			109.504,
			109.534,
			109.567,
			109.643,
			109.689,
			109.752,
			109.813,
			109.873,
			109.937,
			109.969,
			110,
			110.062,
			110.123,
			110.185,
			110.294,
			110.309,
			110.372,
			110.403,
			110.432,
			110.512,
			110.556,
			110.62,
			110.682,
			110.745,
			110.835,
			110.866,
			110.929,
			110.945,
			110.99,
			111.052,
			111.116,
			111.163,
			111.24,
			111.269,
			111.3,
			111.38,
			111.425,
			111.49,
			111.551,
			111.612,
			111.674,
			111.734,
			111.796,
			111.813,
			111.86,
			111.923,
			111.984,
			112.045,
			112.106,
			112.139,
			112.171,
			112.247,
			112.294,
			112.355,
			112.417,
			112.463,
			112.572,
			112.605,
			112.666,
			112.682,
			112.729,
			112.789,
			112.852,
			112.914,
			113.007,
			113.039,
			113.115,
			113.162,
			113.225,
			113.286,
			113.332,
			113.407,
			113.473,
			113.535,
			113.595,
			113.657,
			113.719,
			113.781,
			113.844,
			113.874,
			113.907,
			113.983,
			114.029,
			114.091,
			114.154,
			114.199,
			114.215,
			114.278,
			114.309,
			114.34,
			114.418,
			114.463,
			114.526,
			114.588,
			114.65,
			114.713,
			114.743,
			114.775,
			114.835,
			114.899,
			114.959,
			115.022,
			115.069,
			115.084,
			115.147,
			115.175,
			115.209,
			115.286,
			115.334,
			115.395,
			115.456,
			115.519,
			115.579,
			115.616,
			115.643,
			115.705,
			115.767,
			115.829,
			115.936,
			116.014,
			116.046,
			116.077,
			116.153,
			116.199,
			116.266,
			116.323,
			116.387,
			116.449,
			116.479,
			116.511,
			116.571,
			116.633,
			116.697,
			116.757,
			116.822,
			116.883,
			116.914,
			116.946,
			117.02,
			117.069,
			117.131,
			117.194,
			117.238,
			117.379,
			117.456,
			117.503,
			117.565,
			117.628,
			117.689,
			117.752,
			117.782,
			117.813,
			117.891,
			117.936,
			118,
			118.062,
			118.108,
			118.185,
			118.216,
			118.248,
			118.325,
			118.371,
			118.434,
			118.494,
			118.556,
			118.618,
			118.649,
			118.682,
			118.741,
			118.759,
			118.805,
			118.868,
			118.975,
			118.991,
			119.084,
			119.116,
			119.192,
			119.239,
			119.302,
			119.363,
			119.423,
			119.486,
			119.517,
			119.548,
			119.609,
			119.672,
			119.742,
			119.795,
			119.843,
			119.86,
			119.92,
			119.951,
			119.982,
			120.064
		]
	],
	'1690_2010_7982': [
		[
			116.979
		],
		[
			0
		],
		[
			0,
			0.162,
			4.114,
			6.758,
			10.738,
			12.069,
			13.402,
			17.411,
			20.093,
			24.13,
			26.83,
			29.538,
			30.894,
			34.973,
			40.437,
			41.807,
			44.553,
			47.306,
			50.066,
			54.218,
			58.387,
			59.779,
			61.174,
			65.368,
			68.174,
			72.394,
			75.216,
			78.045,
			83.724,
			85.148,
			89.431,
			92.294,
			96.603,
			99.483,
			103.817,
			106.714,
			108.165,
			111.072,
			113.986,
			118.369,
			121.298,
			128.651,
			133.081,
			134.561,
			136.042,
			140.495,
			143.472,
			147.948,
			150.94,
			153.938,
			158.446,
			161.459,
			165.989,
			169.018,
			173.571,
			176.614,
			179.664,
			181.191,
			184.249,
			185.78,
			188.848,
			193.461,
			196.544,
			199.633,
			204.279,
			207.383,
			212.052,
			213.611,
			215.17,
			219.858,
			222.991,
			227.699,
			230.843,
			235.569,
			240.307,
			241.888,
			248.226,
			249.813,
			254.582,
			257.768,
			262.545,
			265.736,
			268.931,
			270.53,
			273.732,
			275.334,
			278.543,
			281.756,
			289.81,
			294.656,
			296.274,
			297.893,
			302.756,
			306.003,
			310.883,
			314.143,
			319.041,
			322.311,
			323.948,
			327.224,
			332.149,
			333.793,
			338.73,
			342.028,
			346.982,
			350.29,
			355.262,
			360.244,
			363.571,
			366.901,
			375.25,
			380.272,
			383.626,
			390.346,
			392.029,
			397.086,
			400.462,
			405.536,
			408.923,
			410.618,
			414.013,
			419.115,
			422.52,
			425.93,
			431.054,
			432.764,
			434.474,
			441.327,
			443.043,
			448.192,
			451.615,
			456.709,
			460.068,
			463.389,
			468.303,
			469.922,
			471.533,
			476.308,
			477.881,
			479.445,
			484.076,
			487.117,
			491.61,
			494.559,
			497.462,
			498.899,
			503.151,
			505.938,
			508.684,
			512.732,
			515.384,
			519.29,
			520.573,
			521.847,
			525.615,
			528.08,
			531.711,
			534.088,
			536.431,
			537.591,
			539.881,
			541.011,
			542.133,
			546.526,
			547.602,
			550.772,
			552.84,
			555.873,
			559.79,
			560.747,
			562.635,
			563.566,
			565.401,
			567.205,
			569.85,
			571.571,
			574.086,
			575.718,
			578.102,
			579.65,
			581.908,
			583.369,
			585.5,
			586.879,
			587.556,
			588.886,
			590.185,
			590.823,
			592.084,
			593.299,
			594.481,
			596.188,
			597.283,
			598.346,
			598.866,
			600.384,
			601.36,
			602.313,
			603.707,
			604.617,
			605.953,
			606.829,
			608.133,
			609.001,
			610.299,
			611.155,
			612.007,
			612.433,
			613.285,
			613.712,
			614.569,
			615.865,
			616.729,
			617.594,
			618.901,
			619.781,
			621.715,
			622.207,
			623.687,
			624.678,
			625.734,
			627.452,
			628.598,
			630.995,
			631.694,
			633.791,
			635.19,
			637.722,
			639.473,
			642.123,
			643.911,
			644.814,
			646.45,
			647.937,
			648.684,
			650.185,
			651.52,
			653.367,
			654.605,
			656.945,
			657.494,
			659.153,
			660.272,
			661.911,
			664.062,
			665.704,
			666.261,
			666.822,
			669.096,
			669.672,
			671.433,
			672.668,
			674.545,
			675.815,
			677.134,
			677.811,
			679.861,
			681.247,
			682.683,
			684.876,
			686.357,
			690.204,
			692.565,
			693.36,
			694.159,
			696.576,
			698.191,
			700.626,
			703.068,
			704.693,
			707.134,
			708.739,
			710.346,
			711.963,
			713.585,
			714.399,
			718.507,
			720.168,
			721.002,
			722.679,
			724.357,
			726.913,
			728.627,
			731.207,
			732.907,
			735.455,
			737.127,
			739.611,
			741.265,
			743.744,
			745.406,
			746.234,
			747.884,
			750.354,
			751.995,
			753.628,
			756.063,
			757.676,
			760.081,
			761.665,
			764.032,
			765.6,
			767.162,
			771.072,
			774.214,
			775.006,
			779.031,
			781.492,
			783.151,
			785.653,
			787.336,
			788.18,
			789.87,
			791.573,
			794.137,
			795.858,
			798.474,
			800.219,
			802.863,
			803.745,
			804.628,
			807.321,
			809.135,
			811.882,
			813.741,
			816.531,
			818.401,
			819.336,
			821.204,
			823.037,
			823.95,
			824.861,
			827.572,
			831.131,
			832.019,
			833.789,
			836.434,
			839.105,
			840.887,
			845.43,
			847.261,
			850.053,
			851.911,
			854.731,
			856.615,
			859.401,
			863.088,
			863.996,
			865.809,
			866.714,
			868.507,
			871.226,
			873.035,
			874.83,
			876.629,
			879.337,
			881.148,
			882.967,
			883.873,
			885.67,
			888.389,
			890.167,
			892.828,
			894.608,
			897.238,
			898.121,
			899.007,
			901.66,
			903.442,
			906.137,
			907.949,
			909.773,
			910.692,
			915.329,
			917.208,
			918.152,
			920.049,
			921.962,
			924.855,
			926.805,
			929.757,
			930.747,
			931.743,
			934.748,
			936.766,
			938.793,
			943.872,
			946.925,
			947.948,
			948.973,
			952.035,
			954.101,
			957.237,
			959.32,
			962.45,
			965.595,
			967.705,
			970.857,
			973.001,
			975.134,
			978.331,
			979.406,
			980.483,
			983.726,
			984.798,
			985.865,
			991.159,
			994.253,
			996.252,
			998.239,
			1002.106,
			1003.988,
			1006.737,
			1007.636,
			1010.285,
			1012.008,
			1014.529,
			1016.161,
			1018.54,
			1022.328,
			1023.772,
			1025.871,
			1027.224,
			1029.196,
			1030.474,
			1032.33,
			1033.525,
			1035.264,
			1036.389,
			1037.488,
			1038.028,
			1039.095,
			1039.622,
			1040.667,
			1042.212,
			1043.228,
			1044.238,
			1045.246,
			1046.768,
			1048.812,
			1049.323,
			1050.858,
			1051.895,
			1052.941,
			1054.511,
			1055.562,
			1057.168,
			1058.263,
			1059.913,
			1061.022,
			1062.736,
			1063.911,
			1065.69,
			1066.891,
			1067.512,
			1068.793,
			1070.085,
			1072.04,
			1073.403,
			1075.475,
			1076.169,
			1076.866,
			1079.781,
			1080.52,
			1082.741,
			1084.274,
			1088.065,
			1089.548,
			1091.756,
			1092.49,
			1093.221,
			1095.388,
			1096.825,
			1098.993,
			1100.447,
			1101.893,
			1102.615,
			1104.076,
			1105.539,
			1106.266,
			1109.885,
			1111.323,
			1113.466,
			1114.904,
			1117.039,
			1117.746,
			1119.15,
			1120.541,
			1122.632,
			1124.031,
			1126.099,
			1126.789,
			1127.48,
			1129.539,
			1130.856,
			1132.838,
			1135.446,
			1137.384,
			1138.037,
			1138.693,
			1141.318,
			1141.978,
			1145.336,
			1147.382,
			1148.766,
			1150.166,
			1150.869,
			1153.001,
			1154.441,
			1155.898,
			1158.12,
			1159.612,
			1161.884,
			1162.651,
			1163.422,
			1165.754,
			1167.363,
			1169.819,
			1171.484,
			1173.248,
			1174.132,
			1176.82,
			1178.707,
			1180.599,
			1181.548,
			1182.514,
			1185.425,
			1187.364,
			1190.233,
			1192.142,
			1194.02,
			1194.956,
			1197.767,
			1199.671,
			1201.572,
			1204.45,
			1206.383,
			1209.256,
			1210.2,
			1211.142,
			1213.907,
			1215.636,
			1218.217,
			1219.853,
			1222.262,
			1223.855,
			1224.63,
			1226.175,
			1228.482,
			1230.021,
			1231.555,
			1235.388,
			1237.69,
			1238.455,
			1239.221,
			1241.538,
			1243.073,
			1244.611,
			1246.931,
			1248.459,
			1250.782,
			1252.326,
			1254.622,
			1256.17,
			1258.508,
			1260.079,
			1261.656,
			1262.462,
			1265.663,
			1266.454,
			1268.08,
			1268.89,
			1270.522,
			1272.159,
			1274.661,
			1276.35,
			1278.912,
			1279.773,
			1280.637,
			1283.241,
			1284.994,
			1286.756,
			1289.42,
			1291.212,
			1292.116,
			1293.935,
			1294.849,
			1295.767,
			1298.546,
			1299.48,
			1300.418,
			1303.275,
			1305.215,
			1307.169,
			1308.151,
			1310.126,
			1313.074,
			1316.004,
			1317.97,
			1322.946,
			1324.967,
			1329.05,
			1330.079,
			1333.184,
			1335.27,
			1338.408,
			1340.502,
			1342.604,
			1343.66,
			1345.778,
			1346.841,
			1348.977,
			1352.198,
			1353.279,
			1356.535,
			1358.72,
			1362.018,
			1364.228,
			1366.448,
			1367.562,
			1369.793,
			1370.912,
			1373.157,
			1375.41,
			1378.805,
			1381.079,
			1384.507,
			1386.804,
			1390.266,
			1392.585,
			1396.083,
			1398.427,
			1400.782,
			1401.962,
			1404.332,
			1405.521,
			1407.906,
			1410.302,
			1411.504,
			1413.916,
			1416.337,
			1419.988,
			1422.433,
			1427.354,
			1428.593,
			1432.322,
			1434.831,
			1437.349,
			1441.159,
			1443.71,
			1448.831,
			1450.114,
			1453.971,
			1456.547,
			1460.418,
			1463.007,
			1466.898,
			1469.5,
			1470.8,
			1473.401,
			1477.314,
			1479.933,
			1482.56,
			1486.516,
			1489.163,
			1494.484,
			1499.838,
			1502.527,
			1506.576,
			1509.285,
			1513.363,
			1516.092,
			1518.828,
			1524.327,
			1525.707,
			1529.857,
			1532.633,
			1535.417,
			1536.811,
			1539.606,
			1542.41,
			1543.816,
			1548.046,
			1550.876,
			1553.714,
			1557.975,
			1560.801,
			1565.006,
			1566.394,
			1567.774,
			1571.858,
			1574.533,
			1578.475,
			1581.053,
			1583.593,
			1584.848,
			1587.329,
			1588.555,
			1589.772,
			1594.546,
			1596.88,
			1599.181,
			1602.563,
			1604.772,
			1606.944,
			1609.081,
			1610.137,
			1612.219,
			1615.275,
			1617.267,
			1620.194,
			1622.121,
			1624.935,
			1625.862,
			1626.784,
			1629.529,
			1634.067,
			1635.873,
			1638.584,
			1640.395,
			1641.301,
			1643.115,
			1644.94,
			1645.853,
			1647.682,
			1649.524,
			1652.303,
			1653.228,
			1654.163,
			1656.981,
			1657.919,
			1658.859,
			1661.689,
			1663.569,
			1665.446,
			1670.122,
			1672.928,
			1673.865,
			1674.808,
			1678.578,
			1679.532,
			1682.396,
			1684.312,
			1687.21,
			1689.141,
			1690.114,
			1692.055,
			1694.963,
			1696.898,
			1698.835,
			1702.684,
			1706.54,
			1707.505,
			1708.473,
			1711.379,
			1713.328,
			1715.283,
			1718.237,
			1721.211,
			1723.207,
			1725.211,
			1729.238,
			1730.244,
			1733.263,
			1735.27,
			1737.27,
			1740.263,
			1743.24,
			1746.213,
			1748.196,
			1751.182,
			1755.198,
			1756.212,
			1758.246,
			1761.314,
			1764.387,
			1766.408,
			1768.43,
			1771.412,
			1773.359,
			1776.208,
			1777.139,
			1778.061,
			1780.773,
			1782.534,
			1786.778,
			1789.217,
			1790.799,
			1791.577,
			1793.115,
			1794.632,
			1795.386,
			1796.137,
			1798.376,
			1799.875,
			1802.098,
			1803.573,
			1805.049,
			1805.787,
			1807.996,
			1809.466,
			1813.163,
			1814.64,
			1816.885,
			1817.632,
			1818.38,
			1820.648,
			1822.176,
			1824.466,
			1826.022,
			1828.357,
			1829.92,
			1832.293,
			1833.875,
			1834.667,
			1836.278,
			1838.695,
			1839.502,
			1841.986,
			1843.645,
			1846.182,
			1847.893,
			1850.465,
			1852.178,
			1854.746,
			1856.424,
			1858.934,
			1860.593,
			1863.057,
			1863.88,
			1864.704,
			1867.137,
			1868.763,
			1871.204,
			1872.833,
			1875.29,
			1876.938,
			1877.764,
			1879.422,
			1881.078,
			1881.907,
			1883.571,
			1885.234,
			1887.691,
			1889.342,
			1892.588,
			1893.4,
			1895.846,
			1897.479,
			1899.118,
			1901.612,
			1903.29,
			1906.696,
			1907.559,
			1910.159,
			1911.93,
			1914.603,
			1918.228,
			1919.141,
			1920.981,
			1922.838,
			1923.771,
			1926.579,
			1928.479,
			1930.393,
			1933.289,
			1935.237,
			1938.202,
			1939.191,
			1940.185,
			1943.17,
			1945.188,
			1948.239,
			1950.29,
			1952.352,
			1953.388,
			1955.469,
			1956.514,
			1957.561,
			1961.755,
			1962.822,
			1965.985,
			1968.09,
			1971.296,
			1973.448,
			1976.698,
			1978.88,
			1982.176,
			1984.388,
			1987.729,
			1989.97,
			1993.354,
			1995.623,
			1999.049,
			2001.346,
			2004.814,
			2007.141,
			2010.651,
			2013.005,
			2016.556,
			2020.132,
			2021.33,
			2024.942,
			2027.366,
			2031.024,
			2033.482,
			2035.951,
			2037.19,
			2040.922,
			2043.421,
			2049.707,
			2052.235,
			2056.041,
			2058.588,
			2062.425,
			2064.994,
			2068.865,
			2071.457,
			2075.362,
			2079.286,
			2081.904,
			2085.843,
			2088.478,
			2091.121,
			2095.1,
			2097.763,
			2101.771,
			2103.111,
			2104.453,
			2107.143,
			2108.49,
			2111.191,
			2115.26,
			2117.983,
			2120.715,
			2124.827,
			2127.579,
			2131.722,
			2134.495,
			2138.67,
			2144.265,
			2145.668,
			2149.893,
			2152.719,
			2155.552,
			2156.972,
			2159.818,
			2162.671,
			2166.97,
			2169.846,
			2174.175,
			2177.07,
			2182.886,
			2184.345,
			2188.732,
			2191.666,
			2196.081,
			2199.033,
			2201.991,
			2206.442,
			2207.93,
			2209.42,
			2213.9,
			2215.397,
			2216.896,
			2221.404,
			2224.417,
			2228.95,
			2235.011,
			2236.531,
			2241.101,
			2244.156,
			2247.218,
			2251.822,
			2254.901,
			2259.532,
			2261.08,
			2262.629,
			2267.296,
			2270.419,
			2275.108,
			2281.349,
			2282.888,
			2285.938,
			2287.426,
			2290.421,
			2294.844,
			2296.298,
			2300.601,
			2303.421,
			2306.201,
			2310.301,
			2314.314,
			2316.94,
			2320.811,
			2323.344,
			2327.076,
			2329.517,
			2334.292,
			2335.463,
			2338.925,
			2341.181,
			2344.495,
			2346.657,
			2349.831,
			2352.925,
			2354.943,
			2357.903,
			2359.829,
			2361.718,
			2364.484,
			2366.288,
			2369.816,
			2370.673,
			2373.192,
			2374.825,
			2376.42,
			2378.747,
			2380.247,
			2383.168,
			2383.888,
			2386.006,
			2387.408,
			2389.503,
			2390.891,
			2392.981,
			2394.388,
			2396.526,
			2398.683,
			2400.157,
			2401.646,
			2403.885,
			2405.418,
			2409.245,
			2411.567,
			2413.097,
			2414.625,
			2416.907,
			2418.401,
			2420.639,
			2421.38,
			2422.117,
			2425.051,
			2425.786,
			2427.976,
			2429.429,
			2431.611,
			2433.056,
			2434.5,
			2435.216,
			2436.641,
			2437.352,
			2438.774,
			2440.205,
			2442.344,
			2443.769,
			2446.701,
			2447.436,
			2449.661,
			2451.192,
			2453.488,
			2455.038,
			2456.599,
			2457.381,
			2458.949,
			2459.729,
			2461.285,
			2463.627,
			2465.185,
			2466.734,
			2468.29,
			2470.65,
			2472.231,
			2473.818,
			2474.62,
			2477.05,
			2478.674,
			2480.308,
			2482.766,
			2484.407,
			2486.849,
			2487.662,
			2488.477,
			2492.543,
			2494.988,
			2496.624,
			2499.08,
			2500.712,
			2503.159,
			2504.788,
			2505.595,
			2507.209,
			2508.827,
			2511.226,
			2512.022,
			2512.82,
			2514.42,
			2516.774,
			2519.142,
			2520.708,
			2522.275,
			2524.642,
			2526.218,
			2529.399,
			2530.202,
			2532.623,
			2534.246,
			2536.695,
			2540.003,
			2540.848,
			2542.547,
			2543.401,
			2545.122,
			2547.739,
			2549.495,
			2551.271,
			2553.94,
			2555.75,
			2558.489,
			2559.41,
			2560.333,
			2563.124,
			2565,
			2566.885,
			2569.737,
			2571.653,
			2574.547,
			2575.517,
			2576.489,
			2580.404,
			2581.389,
			2584.361,
			2586.358,
			2588.366,
			2589.374,
			2591.399,
			2594.457,
			2596.508,
			2597.537,
			2599.604,
			2601.679,
			2604.81,
			2606.909,
			2610.056,
			2611.106,
			2612.158,
			2615.328,
			2617.454,
			2620.66,
			2624.969,
			2626.051,
			2629.314,
			2630.406,
			2633.696,
			2634.797,
			2635.9,
			2639.223,
			2641.45,
			2644.806,
			2647.054,
			2649.311,
			2650.442,
			2653.848,
			2656.13,
			2658.42,
			2661.871,
			2664.184,
			2668.833,
			2670.001,
			2673.518,
			2675.873,
			2679.422,
			2681.798,
			2685.376,
			2687.772,
			2688.973,
			2691.382,
			2695.009,
			2697.438,
			2699.875,
			2703.545,
			2704.772,
			2706.001,
			2708.465,
			2712.175,
			2714.658,
			2718.396,
			2720.898,
			2724.665,
			2727.185,
			2730.98,
			2732.249,
			2733.519,
			2737.338,
			2739.893,
			2743.736,
			2746.304,
			2748.879,
			2750.169,
			2752.754,
			2756.644,
			2760.546,
			2763.151,
			2765.754,
			2769.656,
			2772.215,
			2776.001,
			2778.48,
			2782.127,
			2784.511,
			2788.035,
			2790.356,
			2792.658,
			2796.079,
			2797.215,
			2798.348,
			2802.867,
			2803.998,
			2807.381,
			2809.642,
			2813.028,
			2815.285,
			2817.537,
			2818.667,
			2820.924,
			2822.05,
			2824.311,
			2826.569,
			2829.96,
			2832.219,
			2835.609,
			2836.738,
			2837.867,
			2841.261,
			2843.534,
			2849.281,
			2851.608,
			2855.138,
			2856.321,
			2858.693,
			2861.071,
			2862.263,
			2863.455,
			2867.032,
			2871.789,
			2872.979,
			2875.362,
			2877.747,
			2878.938,
			2882.518,
			2884.884,
			2887.257,
			2890.78,
			2893.146,
			2897.898,
			2899.058,
			2902.608,
			2904.974,
			2908.533,
			2910.909,
			2913.292,
			2914.485,
			2916.875,
			2918.073,
			2920.472,
			2924.087,
			2926.51,
			2928.946,
			2931.398,
			2935.117,
			2937.637,
			2940.173,
			2941.446,
			2943.986,
			2945.275,
			2947.853,
			2950.473,
			2954.349,
			2956.948,
			2960.844,
			2962.143,
			2963.44,
			2967.347,
			2969.93,
			2973.804,
			2976.373,
			2978.944,
			2980.222,
			2982.76,
			2986.607,
			2989.161,
			2990.436,
			2992.991,
			2995.54,
			2999.348,
			3001.902,
			3005.738,
			3007.018,
			3008.322,
			3012.17,
			3014.74,
			3018.585,
			3023.763,
			3028.933,
			3030.231,
			3034.134,
			3036.751,
			3040.7,
			3043.346,
			3047.31,
			3049.976,
			3052.649,
			3053.987,
			3058.028,
			3060.712,
			3063.377,
			3067.413,
			3070.11,
			3074.164,
			3076.87,
			3080.94,
			3083.663,
			3087.759,
			3090.499,
			3094.623,
			3097.381,
			3098.763,
			3100.147,
			3105.7,
			3107.092,
			3111.277,
			3114.075,
			3116.879,
			3118.284,
			3121.097,
			3123.916,
			3125.329,
			3128.156,
			3129.572,
			3132.409,
			3135.252,
			3139.53,
			3142.387,
			3146.684,
			3148.119,
			3149.556,
			3153.875,
			3156.762,
			3161.103,
			3164.003,
			3166.91,
			3168.365,
			3171.281,
			3172.74,
			3175.663,
			3182.996,
			3185.939,
			3190.363,
			3193.319,
			3196.281,
			3199.247,
			3200.732,
			3205.195,
			3208.178,
			3212.662,
			3215.658,
			3218.659,
			3223.17,
			3226.184,
			3230.713,
			3233.739,
			3238.287,
			3241.326,
			3245.893,
			3248.944,
			3250.472,
			3253.531,
			3258.131,
			3261.204,
			3264.276,
			3270.401,
			3271.92,
			3276.433,
			3277.92,
			3279.397,
			3283.769,
			3286.635,
			3289.462,
			3293.631,
			3296.362,
			3300.387,
			3303.025,
			3306.91,
			3309.454,
			3313.201,
			3318.072,
			3319.268,
			3321.637,
			3323.971,
			3325.126,
			3328.533,
			3330.757,
			3332.942,
			3336.151,
			3338.244,
			3341.309,
			3342.312,
			3343.306,
			3346.225,
			3348.141,
			3350.929,
			3352.758,
			3354.573,
			3357.265,
			3358.16,
			3359.94,
			3362.586,
			3363.467,
			3366.098,
			3367.846,
			3370.461,
			3373.937,
			3374.807,
			3377.426,
			3379.173,
			3380.965,
			3383.674,
			3385.519,
			3388.348,
			3389.291,
			3390.25,
			3393.15,
			3395.088,
			3398.033,
			3399.995,
			3402.949,
			3406.884,
			3407.868,
			3410.817,
			3411.8,
			3414.744,
			3416.692,
			3418.634,
			3419.605,
			3421.508,
			3423.4,
			3424.347,
			3427.107,
			3428.931,
			3430.72,
			3433.332,
			3435.071,
			3438.465,
			3439.312,
			3441.866,
			3443.571,
			3446.141,
			3447.867,
			3449.593,
			3450.459,
			3452.207,
			3453.081,
			3454.829,
			3457.48,
			3459.253,
			3463.738,
			3465.549,
			3468.285,
			3469.202,
			3470.121,
			3472.892,
			3474.755,
			3477.556,
			3479.463,
			3481.367,
			3484.245,
			3485.211,
			3486.178,
			3489.081,
			3491.042,
			3494.001,
			3495.989,
			3498.987,
			3503.02,
			3504.035,
			3506.071,
			3507.093,
			3509.144,
			3511.207,
			3514.313,
			3516.391,
			3519.507,
			3520.542,
			3521.572,
			3524.647,
			3526.676,
			3529.675,
			3533.561,
			3534.511,
			3536.384,
			3539.164,
			3540.979,
			3541.883,
			3542.784,
			3545.474,
			3547.263,
			3549.944,
			3551.73,
			3553.513,
			3554.404,
			3557.077,
			3558.857,
			3560.638,
			3563.313,
			3565.095,
			3567.776,
			3569.564,
			3572.25,
			3574.049,
			3576.741,
			3578.546,
			3580.345,
			3581.244,
			3583.05,
			3583.954,
			3585.758,
			3588.48,
			3589.39,
			3592.12,
			3594.875,
			3596.707,
			3598.56,
			3600.418,
			3601.349,
			3603.23,
			3604.176,
			3606.071,
			3607.982,
			3610.873,
			3612.812,
			3616.725,
			3617.705,
			3620.651,
			3622.623,
			3625.592,
			3627.582,
			3630.579,
			3632.589,
			3633.596,
			3635.618,
			3637.652,
			3638.676,
			3640.732,
			3642.801,
			3645.929,
			3648.023,
			3652.19,
			3653.228,
			3656.326,
			3658.394,
			3663.544,
			3665.601,
			3668.7,
			3669.729,
			3670.759,
			3673.861,
			3674.902,
			3675.943,
			3679.088,
			3681.2,
			3683.324,
			3684.395,
			3686.544,
			3688.706,
			3689.797,
			3693.082,
			3695.297,
			3697.523,
			3703.15,
			3706.562,
			3707.705,
			3708.851,
			3712.284,
			3714.599,
			3718.086,
			3720.423,
			3723.948,
			3726.311,
			3728.684,
			3733.462,
			3734.663,
			3738.283,
			3740.709,
			3744.369,
			3746.823,
			3749.287,
			3750.523,
			3753.004,
			3754.248,
			3756.743,
			3759.249,
			3763.026,
			3765.556,
			3770.646,
			3771.924,
			3775.773,
			3778.352,
			3784.837,
			3788.75,
			3792.669,
			3795.293,
			3797.924,
			3799.243,
			3801.886,
			3804.537,
			3807.197,
			3809.865,
			3811.201,
			3813.88,
			3817.915,
			3821.969,
			3824.681,
			3827.402,
			3831.501,
			3834.244,
			3838.374,
			3839.755,
			3841.138,
			3845.299,
			3848.083,
			3852.274,
			3855.077,
			3857.887,
			3859.294,
			3862.115,
			3863.527,
			3866.358,
			3870.615,
			3873.462,
			3876.315,
			3880.607,
			3883.477,
			3886.354,
			3889.238,
			3890.682,
			3895.025,
			3897.93,
			3902.3,
			3905.221,
			3909.615,
			3912.553,
			3914.025,
			3915.499,
			3921.409,
			3922.89,
			3927.344,
			3930.321,
			3933.303,
			3934.797,
			3939.285,
			3942.284,
			3945.289,
			3946.793,
			3949.806,
			3952.824,
			3957.36,
			3960.392,
			3964.95,
			3966.472,
			3967.996,
			3972.573,
			3975.632,
			3980.229,
			3983.302,
			3986.38,
			3987.922,
			3991.009,
			3992.554,
			3994.102,
			3998.752,
			4000.305,
			4001.86,
			4006.537,
			4009.692,
			4014.416,
			4017.565,
			4020.761,
			4022.373,
			4027.241,
			4030.55,
			4033.847,
			4038.798,
			4042.094,
			4046.99,
			4048.645,
			4050.273,
			4055.146,
			4058.388,
			4063.217,
			4066.48,
			4071.33,
			4074.556,
			4076.169,
			4079.367,
			4082.621,
			4084.234,
			4087.463,
			4090.685,
			4093.88,
			4097.098,
			4098.733,
			4101.949,
			4105.161,
			4106.766,
			4111.583,
			4114.795,
			4118.011,
			4122.844,
			4126.078,
			4130.945,
			4132.573,
			4134.202,
			4139.112,
			4142.397,
			4147.333,
			4150.601,
			4153.899,
			4155.551,
			4158.855,
			4160.508,
			4163.816,
			4168.785,
			4172.102,
			4175.421,
			4180.406,
			4183.733,
			4187.063,
			4190.396,
			4192.062,
			4195.398,
			4197.068,
			4200.41,
			4203.756,
			4208.784,
			4212.141,
			4217.187,
			4218.87,
			4220.554,
			4227.295,
			4228.981,
			4234.044,
			4237.42,
			4240.796,
			4242.485,
			4245.865,
			4249.247,
			4250.938,
			4256.016,
			4259.404,
			4262.796,
			4267.89,
			4271.292,
			4276.399,
			4278.104,
			4279.809,
			4284.928,
			4288.346,
			4293.478,
			4296.903,
			4300.333,
			4305.481,
			4310.637,
			4315.797,
			4317.52,
			4322.689,
			4326.139,
			4331.317,
			4334.773,
			4339.961,
			4345.154,
			4348.619,
			4352.085,
			4357.29,
			4360.763,
			4365.975,
			4369.453,
			4374.669,
			4378.138,
			4383.304,
			4386.712,
			4391.753,
			4395.064,
			4396.705,
			4399.957,
			4404.76,
			4407.913,
			4411.026,
			4415.622,
			4417.136,
			4418.639,
			4424.557,
			4426.01,
			4428.886,
			4433.119,
			4435.891,
			4439.976,
			4442.65,
			4447.886,
			4449.169,
			4452.964,
			4455.448,
			4459.107,
			4461.506,
			4463.872,
			4465.044,
			4468.508,
			4470.763,
			4472.971,
			4474.061,
			4476.202,
			4478.298,
			4481.372,
			4483.351,
			4486.278,
			4487.248,
			4488.211,
			4491.066,
			4492.943,
			4494.808,
			4497.6,
			4499.453,
			4502.227,
			4503.147,
			4504.055,
			4506.774,
			4508.594,
			4511.332,
			4513.192,
			4515.989,
			4519.72,
			4520.643,
			4523.479,
			4525.651,
			4527.82,
			4533.496,
			4536.57,
			4537.754,
			4538.948,
			4542.542,
			4544.93,
			4547.2,
			4550.269,
			4552.217,
			4554.993,
			4555.909,
			4556.826,
			4559.525,
			4561.329,
			4564.16,
			4566.085,
			4568.111,
			4569.128,
			4571.202,
			4574.327,
			4576.358,
			4577.377,
			4579.463,
			4581.56,
			4584.825,
			4587.03,
			4590.399,
			4591.535,
			4592.671,
			4596.03,
			4598.251,
			4601.641,
			4603.927,
			4606.157,
			4607.242,
			4609.415,
			4610.503,
			4612.744,
			4616.127,
			4617.246,
			4620.596,
			4622.76,
			4625.914,
			4628.032,
			4630.164,
			4631.234,
			4633.383,
			4634.462,
			4636.626,
			4638.803,
			4642.085,
			4644.287,
			4647.609,
			4648.723,
			4649.84,
			4653.206,
			4655.468,
			4658.857,
			4661.14,
			4664.578,
			4666.899,
			4668.054,
			4670.371,
			4672.693,
			4673.856,
			4676.188,
			4678.508,
			4682.034,
			4683.214,
			4684.396,
			4687.956,
			4689.146,
			4690.339,
			4693.93,
			4696.334,
			4698.747,
			4702.38,
			4704.807,
			4708.438,
			4709.642,
			4710.838,
			4714.386,
			4716.708,
			4720.119,
			4722.346,
			4725.613,
			4727.761,
			4728.812,
			4730.884,
			4733.923,
			4735.901,
			4737.842,
			4740.667,
			4742.516,
			4745.222,
			4746.107,
			4746.983,
			4749.57,
			4751.233,
			4753.668,
			4755.251,
			4756.812,
			4759.904,
			4760.672,
			4763.745,
			4764.513,
			4766.825,
			4768.364,
			4769.902,
			4770.673,
			4772.21,
			4774.511,
			4776.051,
			4778.353,
			4779.885,
			4782.205,
			4783.744,
			4786.07,
			4786.852,
			4787.631,
			4789.963,
			4791.545,
			4793.918,
			4795.5,
			4797.094,
			4797.889,
			4799.478,
			4800.271,
			4801.872,
			4803.47,
			4804.269,
			4805.068,
			4807.49,
			4809.107,
			4811.551,
			4813.191,
			4815.649,
			4817.324,
			4818.161,
			4819.835,
			4821.537,
			4824.144,
			4825.882,
			4828.604,
			4829.511,
			4830.42,
			4833.141,
			4837.633,
			4839.393,
			4842.036,
			4843.789,
			4844.668,
			4846.433,
			4848.186,
			4849.063,
			4850.822,
			4852.586,
			4856.12,
			4857.007,
			4858.79,
			4861.47,
			4864.14,
			4865.921,
			4867.697,
			4870.339,
			4872.109,
			4874.701,
			4875.568,
			4876.438,
			4879.023,
			4879.883,
			4880.745,
			4883.334,
			4885.057,
			4886.789,
			4887.657,
			4889.381,
			4891.112,
			4891.981,
			4894.588,
			4896.332,
			4898.083,
			4900.723,
			4902.488,
			4905.168,
			4906.063,
			4906.959,
			4909.689,
			4911.524,
			4914.298,
			4918.035,
			4920.856,
			4921.801,
			4922.746,
			4925.595,
			4927.487,
			4930.359,
			4932.287,
			4935.205,
			4937.169,
			4939.145,
			4940.14,
			4942.14,
			4943.144,
			4945.163,
			4947.195,
			4950.265,
			4952.326,
			4955.44,
			4956.483,
			4957.529,
			4960.681,
			4962.799,
			4965.931,
			4968.047,
			4971.212,
			4973.311,
			4974.373,
			4976.503,
			4978.642,
			4979.714,
			4981.867,
			4984.03,
			4986.203,
			4989.485,
			4991.686,
			4995.007,
			4998.353,
			5000.597,
			5002.851,
			5006.252,
			5008.532,
			5011.97,
			5014.274,
			5017.749,
			5020.076,
			5023.584,
			5025.936,
			5029.48,
			5031.854,
			5033.045,
			5035.433,
			5039.032,
			5041.445,
			5043.867,
			5047.518,
			5049.965,
			5053.653,
			5056.125,
			5059.851,
			5062.347,
			5064.852,
			5068.626,
			5071.153,
			5074.959,
			5076.232,
			5077.506,
			5081.341,
			5083.908,
			5087.772,
			5090.358,
			5092.951,
			5094.25,
			5096.853,
			5098.156,
			5100.761,
			5103.371,
			5104.678,
			5107.299,
			5109.928,
			5112.62
		],
		[
			0,
			0.011,
			0.071,
			0.135,
			0.197,
			0.234,
			0.258,
			0.336,
			0.383,
			0.445,
			0.507,
			0.553,
			0.569,
			0.662,
			0.755,
			0.77,
			0.817,
			0.879,
			0.939,
			1.006,
			1.067,
			1.096,
			1.127,
			1.205,
			1.25,
			1.317,
			1.375,
			1.422,
			1.53,
			1.563,
			1.621,
			1.684,
			1.748,
			1.807,
			1.871,
			1.933,
			1.963,
			1.995,
			2.056,
			2.117,
			2.181,
			2.292,
			2.366,
			2.398,
			2.43,
			2.506,
			2.553,
			2.616,
			2.679,
			2.739,
			2.799,
			2.862,
			2.925,
			2.987,
			3.05,
			3.11,
			3.158,
			3.171,
			3.233,
			3.265,
			3.298,
			3.375,
			3.422,
			3.484,
			3.545,
			3.607,
			3.669,
			3.701,
			3.732,
			3.794,
			3.856,
			3.917,
			3.98,
			4.04,
			4.135,
			4.165,
			4.244,
			4.289,
			4.356,
			4.413,
			4.475,
			4.537,
			4.566,
			4.599,
			4.66,
			4.676,
			4.723,
			4.785,
			4.894,
			4.972,
			5.003,
			5.032,
			5.109,
			5.156,
			5.219,
			5.28,
			5.343,
			5.404,
			5.435,
			5.467,
			5.545,
			5.59,
			5.655,
			5.714,
			5.777,
			5.84,
			5.9,
			5.979,
			6.024,
			6.087,
			6.195,
			6.272,
			6.336,
			6.42,
			6.461,
			6.523,
			6.583,
			6.646,
			6.706,
			6.739,
			6.77,
			6.847,
			6.892,
			6.957,
			7.018,
			7.063,
			7.079,
			7.173,
			7.204,
			7.282,
			7.327,
			7.391,
			7.452,
			7.513,
			7.575,
			7.607,
			7.638,
			7.699,
			7.716,
			7.762,
			7.824,
			7.886,
			7.946,
			8.008,
			8.041,
			8.073,
			8.147,
			8.197,
			8.256,
			8.318,
			8.382,
			8.444,
			8.48,
			8.506,
			8.567,
			8.63,
			8.691,
			8.753,
			8.8,
			8.817,
			8.879,
			8.909,
			8.94,
			9.017,
			9.064,
			9.127,
			9.188,
			9.249,
			9.345,
			9.374,
			9.437,
			9.449,
			9.497,
			9.56,
			9.621,
			9.683,
			9.747,
			9.809,
			9.884,
			9.933,
			9.993,
			10.056,
			10.118,
			10.179,
			10.212,
			10.241,
			10.305,
			10.318,
			10.367,
			10.427,
			10.489,
			10.551,
			10.613,
			10.645,
			10.677,
			10.755,
			10.801,
			10.863,
			10.923,
			10.987,
			11.049,
			11.109,
			11.173,
			11.235,
			11.297,
			11.358,
			11.406,
			11.42,
			11.482,
			11.513,
			11.545,
			11.62,
			11.666,
			11.731,
			11.793,
			11.855,
			11.947,
			11.979,
			12.055,
			12.1,
			12.164,
			12.226,
			12.288,
			12.381,
			12.413,
			12.49,
			12.535,
			12.605,
			12.661,
			12.722,
			12.783,
			12.814,
			12.846,
			12.909,
			12.924,
			12.971,
			13.034,
			13.093,
			13.155,
			13.25,
			13.28,
			13.359,
			13.406,
			13.467,
			13.576,
			13.653,
			13.683,
			13.714,
			13.793,
			13.84,
			13.9,
			13.963,
			14.023,
			14.085,
			14.118,
			14.148,
			14.226,
			14.273,
			14.335,
			14.398,
			14.445,
			14.584,
			14.646,
			14.666,
			14.705,
			14.769,
			14.83,
			14.895,
			14.986,
			15.016,
			15.095,
			15.143,
			15.203,
			15.264,
			15.311,
			15.327,
			15.45,
			15.512,
			15.527,
			15.575,
			15.637,
			15.7,
			15.759,
			15.823,
			15.885,
			15.962,
			16.009,
			16.072,
			16.134,
			16.197,
			16.256,
			16.288,
			16.318,
			16.397,
			16.444,
			16.506,
			16.566,
			16.63,
			16.692,
			16.752,
			16.83,
			16.876,
			16.94,
			17.049,
			17.156,
			17.188,
			17.313,
			17.373,
			17.436,
			17.496,
			17.56,
			17.589,
			17.622,
			17.684,
			17.752,
			17.808,
			17.869,
			17.916,
			17.994,
			18.024,
			18.056,
			18.133,
			18.18,
			18.243,
			18.304,
			18.367,
			18.426,
			18.459,
			18.49,
			18.551,
			18.567,
			18.614,
			18.675,
			18.788,
			18.798,
			18.862,
			18.924,
			19,
			19.048,
			19.172,
			19.234,
			19.295,
			19.358,
			19.421,
			19.481,
			19.543,
			19.651,
			19.666,
			19.73,
			19.762,
			19.791,
			19.87,
			19.916,
			19.979,
			20.039,
			20.1,
			20.164,
			20.196,
			20.224,
			20.288,
			20.351,
			20.413,
			20.474,
			20.536,
			20.597,
			20.628,
			20.658,
			20.737,
			20.784,
			20.852,
			20.906,
			20.955,
			20.971,
			21.094,
			21.157,
			21.172,
			21.217,
			21.281,
			21.342,
			21.404,
			21.467,
			21.496,
			21.529,
			21.604,
			21.65,
			21.715,
			21.823,
			21.9,
			21.932,
			21.961,
			22.039,
			22.087,
			22.148,
			22.209,
			22.271,
			22.364,
			22.397,
			22.474,
			22.519,
			22.582,
			22.643,
			22.689,
			22.707,
			22.767,
			22.8,
			22.829,
			22.954,
			23.016,
			23.077,
			23.139,
			23.234,
			23.266,
			23.342,
			23.388,
			23.451,
			23.512,
			23.574,
			23.637,
			23.699,
			23.822,
			23.883,
			23.947,
			24.007,
			24.071,
			24.134,
			24.193,
			24.256,
			24.32,
			24.38,
			24.426,
			24.444,
			24.506,
			24.536,
			24.567,
			24.646,
			24.69,
			24.751,
			24.813,
			24.877,
			24.976,
			25.001,
			25.077,
			25.126,
			25.185,
			25.249,
			25.312,
			25.372,
			25.436,
			25.513,
			25.557,
			25.621,
			25.681,
			25.745,
			25.805,
			25.838,
			25.869,
			25.932,
			25.992,
			26.056,
			26.118,
			26.163,
			26.179,
			26.272,
			26.303,
			26.38,
			26.426,
			26.55,
			26.613,
			26.673,
			26.704,
			26.735,
			26.797,
			26.861,
			26.922,
			26.985,
			27.036,
			27.047,
			27.108,
			27.14,
			27.172,
			27.295,
			27.357,
			27.42,
			27.48,
			27.548,
			27.58,
			27.618,
			27.684,
			27.743,
			27.806,
			27.868,
			27.9,
			27.93,
			27.993,
			28.056,
			28.116,
			28.225,
			28.304,
			28.333,
			28.365,
			28.443,
			28.49,
			28.614,
			28.675,
			28.737,
			28.767,
			28.797,
			28.878,
			28.923,
			28.984,
			29.046,
			29.11,
			29.17,
			29.202,
			29.232,
			29.309,
			29.355,
			29.418,
			29.481,
			29.526,
			29.544,
			29.636,
			29.668,
			29.73,
			29.743,
			29.789,
			29.852,
			29.914,
			29.979,
			30.038,
			30.07,
			30.1,
			30.179,
			30.223,
			30.288,
			30.349,
			30.397,
			30.472,
			30.504,
			30.533,
			30.612,
			30.659,
			30.722,
			30.782,
			30.846,
			30.907,
			30.939,
			30.97,
			31.047,
			31.093,
			31.16,
			31.278,
			31.342,
			31.373,
			31.404,
			31.481,
			31.527,
			31.59,
			31.652,
			31.713,
			31.776,
			31.838,
			31.915,
			31.961,
			32.028,
			32.086,
			32.133,
			32.147,
			32.241,
			32.272,
			32.333,
			32.349,
			32.394,
			32.458,
			32.52,
			32.582,
			32.646,
			32.673,
			32.705,
			32.784,
			32.83,
			32.89,
			32.954,
			33,
			33.017,
			33.076,
			33.107,
			33.139,
			33.2,
			33.222,
			33.264,
			33.327,
			33.389,
			33.433,
			33.448,
			33.512,
			33.574,
			33.65,
			33.697,
			33.822,
			33.884,
			33.975,
			34.008,
			34.071,
			34.13,
			34.194,
			34.256,
			34.303,
			34.319,
			34.38,
			34.409,
			34.441,
			34.518,
			34.564,
			34.627,
			34.688,
			34.752,
			34.814,
			34.845,
			34.876,
			34.937,
			34.951,
			35,
			35.062,
			35.122,
			35.185,
			35.248,
			35.309,
			35.388,
			35.434,
			35.497,
			35.558,
			35.605,
			35.621,
			35.683,
			35.713,
			35.743,
			35.806,
			35.822,
			35.867,
			35.929,
			35.991,
			36.055,
			36.147,
			36.179,
			36.257,
			36.302,
			36.365,
			36.427,
			36.472,
			36.581,
			36.613,
			36.689,
			36.737,
			36.799,
			36.859,
			36.922,
			36.983,
			37.015,
			37.045,
			37.123,
			37.17,
			37.233,
			37.295,
			37.347,
			37.449,
			37.542,
			37.605,
			37.666,
			37.727,
			37.79,
			37.85,
			37.915,
			37.991,
			38.037,
			38.101,
			38.162,
			38.209,
			38.225,
			38.288,
			38.317,
			38.349,
			38.426,
			38.471,
			38.533,
			38.596,
			38.657,
			38.721,
			38.75,
			38.783,
			38.858,
			38.907,
			38.97,
			39.03,
			39.077,
			39.093,
			39.155,
			39.186,
			39.215,
			39.294,
			39.342,
			39.407,
			39.465,
			39.526,
			39.589,
			39.62,
			39.65,
			39.714,
			39.773,
			39.838,
			39.897,
			39.961,
			40.023,
			40.053,
			40.085,
			40.162,
			40.27,
			40.333,
			40.394,
			40.457,
			40.488,
			40.519,
			40.581,
			40.597,
			40.645,
			40.705,
			40.766,
			40.812,
			40.828,
			40.891,
			40.922,
			40.954,
			41.029,
			41.075,
			41.14,
			41.264,
			41.325,
			41.356,
			41.387,
			41.47,
			41.512,
			41.574,
			41.636,
			41.698,
			41.76,
			41.79,
			41.821,
			41.9,
			41.944,
			42.009,
			42.115,
			42.193,
			42.224,
			42.255,
			42.317,
			42.379,
			42.44,
			42.504,
			42.566,
			42.628,
			42.69,
			42.767,
			42.813,
			42.876,
			42.937,
			42.983,
			43.061,
			43.122,
			43.199,
			43.247,
			43.33,
			43.433,
			43.45,
			43.511,
			43.573,
			43.65,
			43.697,
			43.762,
			43.821,
			43.866,
			43.943,
			43.976,
			44.005,
			44.084,
			44.132,
			44.256,
			44.316,
			44.378,
			44.409,
			44.442,
			44.503,
			44.519,
			44.565,
			44.626,
			44.688,
			44.752,
			44.814,
			44.845,
			44.875,
			44.953,
			45,
			45.122,
			45.186,
			45.247,
			45.279,
			45.309,
			45.385,
			45.432,
			45.494,
			45.556,
			45.619,
			45.68,
			45.743,
			45.805,
			45.834,
			45.867,
			45.943,
			45.99,
			46.054,
			46.114,
			46.176,
			46.238,
			46.3,
			46.363,
			46.425,
			46.488,
			46.55,
			46.596,
			46.673,
			46.705,
			46.735,
			46.814,
			46.861,
			46.923,
			46.983,
			47.046,
			47.108,
			47.138,
			47.17,
			47.23,
			47.247,
			47.294,
			47.354,
			47.417,
			47.48,
			47.573,
			47.604,
			47.684,
			47.73,
			47.79,
			47.852,
			47.914,
			48.006,
			48.038,
			48.1,
			48.163,
			48.223,
			48.333,
			48.346,
			48.41,
			48.441,
			48.471,
			48.55,
			48.597,
			48.658,
			48.72,
			48.782,
			48.844,
			48.873,
			48.906,
			48.968,
			49.03,
			49.092,
			49.155,
			49.199,
			49.215,
			49.276,
			49.308,
			49.338,
			49.417,
			49.464,
			49.532,
			49.588,
			49.648,
			49.71,
			49.773,
			49.837,
			49.899,
			49.959,
			50.022,
			50.069,
			50.144,
			50.208,
			50.285,
			50.333,
			50.394,
			50.456,
			50.517,
			50.58,
			50.642,
			50.72,
			50.765,
			50.829,
			50.89,
			50.951,
			51.014,
			51.045,
			51.075,
			51.154,
			51.201,
			51.323,
			51.385,
			51.448,
			51.512,
			51.589,
			51.635,
			51.696,
			51.757,
			51.821,
			51.914,
			51.945,
			52.022,
			52.066,
			52.132,
			52.193,
			52.253,
			52.317,
			52.346,
			52.38,
			52.44,
			52.456,
			52.501,
			52.566,
			52.627,
			52.688,
			52.751,
			52.812,
			52.889,
			52.937,
			52.997,
			53.108,
			53.121,
			53.215,
			53.248,
			53.309,
			53.323,
			53.372,
			53.433,
			53.495,
			53.556,
			53.617,
			53.68,
			53.773,
			53.805,
			53.887,
			53.928,
			53.991,
			54.054,
			54.115,
			54.177,
			54.208,
			54.239,
			54.3,
			54.317,
			54.362,
			54.425,
			54.488,
			54.547,
			54.642,
			54.673,
			54.751,
			54.798,
			54.858,
			54.922,
			54.982,
			55.043,
			55.075,
			55.107,
			55.17,
			55.231,
			55.292,
			55.403,
			55.416,
			55.478,
			55.511,
			55.542,
			55.619,
			55.665,
			55.729,
			55.79,
			55.837,
			55.913,
			55.973,
			56.038,
			56.1,
			56.162,
			56.222,
			56.284,
			56.377,
			56.412,
			56.487,
			56.533,
			56.596,
			56.658,
			56.72,
			56.813,
			56.842,
			56.921,
			56.967,
			57.03,
			57.091,
			57.153,
			57.245,
			57.276,
			57.355,
			57.401,
			57.464,
			57.526,
			57.585,
			57.681,
			57.711,
			57.788,
			57.836,
			57.897,
			57.96,
			58.021,
			58.083,
			58.146,
			58.223,
			58.267,
			58.332,
			58.395,
			58.455,
			58.579,
			58.641,
			58.704,
			58.764,
			58.827,
			58.888,
			58.952,
			58.984,
			59.013,
			59.091,
			59.138,
			59.2,
			59.262,
			59.325,
			59.386,
			59.416,
			59.448,
			59.51,
			59.526,
			59.571,
			59.634,
			59.694,
			59.756,
			59.852,
			59.882,
			59.958,
			60.004,
			60.072,
			60.13,
			60.177,
			60.191,
			60.253,
			60.285,
			60.316,
			60.393,
			60.441,
			60.502,
			60.563,
			60.626,
			60.689,
			60.719,
			60.75,
			60.826,
			60.872,
			60.936,
			60.997,
			61.043,
			61.121,
			61.154,
			61.183,
			61.308,
			61.371,
			61.43,
			61.494,
			61.557,
			61.618,
			61.68,
			61.696,
			61.743,
			61.805,
			61.867,
			61.913,
			61.927,
			61.989,
			62.051,
			62.134,
			62.175,
			62.238,
			62.301,
			62.361,
			62.456,
			62.487,
			62.548,
			62.609,
			62.672,
			62.78,
			62.796,
			62.859,
			62.89,
			62.92,
			62.998,
			63.043,
			63.105,
			63.168,
			63.23,
			63.292,
			63.322,
			63.353,
			63.416,
			63.479,
			63.54,
			63.602,
			63.648,
			63.727,
			63.756,
			63.788,
			63.866,
			63.913,
			63.975,
			64.038,
			64.081,
			64.098,
			64.161,
			64.223,
			64.284,
			64.3,
			64.345,
			64.408,
			64.47,
			64.534,
			64.594,
			64.624,
			64.657,
			64.733,
			64.781,
			64.845,
			64.952,
			64.967,
			65.059,
			65.09,
			65.151,
			65.168,
			65.215,
			65.277,
			65.339,
			65.4,
			65.463,
			65.495,
			65.526,
			65.6,
			65.647,
			65.709,
			65.773,
			65.82,
			65.927,
			65.959,
			66.021,
			66.083,
			66.146,
			66.206,
			66.267,
			66.332,
			66.362,
			66.392,
			66.47,
			66.516,
			66.584,
			66.641,
			66.687,
			66.704,
			66.764,
			66.828,
			66.887,
			66.95,
			67.014,
			67.076,
			67.137,
			67.197,
			67.23,
			67.26,
			67.339,
			67.385,
			67.447,
			67.51,
			67.556,
			67.571,
			67.634,
			67.694,
			67.773,
			67.82,
			67.881,
			67.944,
			68.004,
			68.066,
			68.129,
			68.191,
			68.253,
			68.321,
			68.376,
			68.44,
			68.501,
			68.531,
			68.564,
			68.641,
			68.688,
			68.749,
			68.811,
			68.875,
			68.934,
			68.966,
			68.996,
			69.059,
			69.074,
			69.12,
			69.184,
			69.246,
			69.293,
			69.368,
			69.401,
			69.431,
			69.508,
			69.555,
			69.68,
			69.74,
			69.804,
			69.833,
			69.866,
			69.927,
			69.943,
			69.988,
			70.052,
			70.161,
			70.176,
			70.238,
			70.269,
			70.301,
			70.383,
			70.423,
			70.484,
			70.547,
			70.612,
			70.704,
			70.732,
			70.797,
			70.856,
			70.92,
			70.983,
			71.029,
			71.044,
			71.106,
			71.138,
			71.166,
			71.246,
			71.293,
			71.354,
			71.415,
			71.479,
			71.54,
			71.571,
			71.602,
			71.664,
			71.68,
			71.729,
			71.789,
			71.848,
			71.896,
			71.973,
			72.005,
			72.034,
			72.114,
			72.159,
			72.225,
			72.283,
			72.33,
			72.346,
			72.409,
			72.47,
			72.533,
			72.548,
			72.595,
			72.655,
			72.719,
			72.78,
			72.844,
			72.873,
			72.904,
			72.982,
			73.026,
			73.091,
			73.198,
			73.306,
			73.34,
			73.4,
			73.463,
			73.523,
			73.585,
			73.647,
			73.709,
			73.742,
			73.771,
			73.85,
			73.898,
			73.963,
			74.021,
			74.067,
			74.146,
			74.206,
			74.268,
			74.329,
			74.392,
			74.454,
			74.517,
			74.577,
			74.61,
			74.639,
			74.716,
			74.764,
			74.826,
			74.889,
			74.935,
			74.951,
			75.013,
			75.043,
			75.073,
			75.137,
			75.151,
			75.198,
			75.26,
			75.322,
			75.385,
			75.446,
			75.479,
			75.508,
			75.571,
			75.634,
			75.694,
			75.755,
			75.804,
			75.817,
			75.882,
			75.911,
			75.945,
			76.066,
			76.127,
			76.191,
			76.254,
			76.315,
			76.346,
			76.375,
			76.455,
			76.5,
			76.57,
			76.625,
			76.687,
			76.75,
			76.809,
			76.873,
			76.933,
			76.997,
			77.061,
			77.122,
			77.184,
			77.214,
			77.245,
			77.322,
			77.367,
			77.43,
			77.539,
			77.555,
			77.617,
			77.648,
			77.678,
			77.755,
			77.8,
			77.864,
			77.926,
			77.988,
			78.051,
			78.113,
			78.19,
			78.237,
			78.299,
			78.408,
			78.423,
			78.483,
			78.515,
			78.547,
			78.633,
			78.671,
			78.734,
			78.797,
			78.856,
			78.918,
			78.951,
			78.98,
			79.043,
			79.105,
			79.167,
			79.23,
			79.278,
			79.354,
			79.385,
			79.416,
			79.494,
			79.54,
			79.601,
			79.664,
			79.726,
			79.819,
			79.849,
			79.926,
			79.974,
			80.036,
			80.098,
			80.143,
			80.221,
			80.253,
			80.284,
			80.361,
			80.407,
			80.47,
			80.532,
			80.594,
			80.69,
			80.717,
			80.795,
			80.84,
			80.905,
			80.965,
			81.012,
			81.028,
			81.089,
			81.121,
			81.152,
			81.23,
			81.275,
			81.338,
			81.399,
			81.461,
			81.555,
			81.584,
			81.648,
			81.708,
			81.772,
			81.833,
			81.881,
			81.897,
			81.957,
			81.989,
			82.018,
			82.098,
			82.148,
			82.269,
			82.33,
			82.393,
			82.424,
			82.455,
			82.517,
			82.579,
			82.645,
			82.7,
			82.764,
			82.826,
			82.855,
			82.886,
			82.964,
			83.011,
			83.073,
			83.136,
			83.197,
			83.291,
			83.322,
			83.384,
			83.4,
			83.446,
			83.508,
			83.57,
			83.632,
			83.694,
			83.725,
			83.756,
			83.834,
			83.88,
			83.944,
			84.049,
			84.067,
			84.128,
			84.191,
			84.251,
			84.267,
			84.315,
			84.38,
			84.438,
			84.501,
			84.564,
			84.593,
			84.625,
			84.7,
			84.747,
			84.814,
			84.872,
			84.919,
			84.997,
			85.064,
			85.12,
			85.183,
			85.244,
			85.307,
			85.354,
			85.369,
			85.431,
			85.462,
			85.493,
			85.57,
			85.615,
			85.68,
			85.741,
			85.803,
			85.864,
			85.896,
			85.925,
			85.989,
			86.004,
			86.051,
			86.113,
			86.174,
			86.221,
			86.329,
			86.359,
			86.437,
			86.484,
			86.546,
			86.609,
			86.671,
			86.732,
			86.763,
			86.793,
			86.856,
			86.877,
			86.919,
			86.982,
			87.042,
			87.104,
			87.198,
			87.229,
			87.307,
			87.353,
			87.477,
			87.538,
			87.601,
			87.633,
			87.663,
			87.723,
			87.741,
			87.787,
			87.849,
			87.911,
			87.957,
			87.973,
			88.034,
			88.067,
			88.097,
			88.174,
			88.222,
			88.282,
			88.408,
			88.47,
			88.5,
			88.531,
			88.608,
			88.656,
			88.718,
			88.779,
			88.841,
			88.903,
			88.963,
			89.042,
			89.09,
			89.15,
			89.212,
			89.274,
			89.338,
			89.367,
			89.4,
			89.461,
			89.478,
			89.522,
			89.583,
			89.647,
			89.693,
			89.8,
			89.834,
			89.911,
			89.956,
			90.081,
			90.144,
			90.237,
			90.268,
			90.328,
			90.344,
			90.392,
			90.454,
			90.514,
			90.562,
			90.576,
			90.638,
			90.702,
			90.779,
			90.826,
			90.888,
			90.948,
			91.012,
			91.074,
			91.104,
			91.136,
			91.198,
			91.26,
			91.321,
			91.383,
			91.43,
			91.444,
			91.507,
			91.539,
			91.57,
			91.648,
			91.695,
			91.757,
			91.818,
			91.88,
			91.94,
			91.971,
			92.003,
			92.066,
			92.126,
			92.191,
			92.249,
			92.316,
			92.377,
			92.406,
			92.436,
			92.516,
			92.56,
			92.623,
			92.685,
			92.73,
			92.746,
			92.839,
			92.871,
			92.933,
			92.949,
			92.995,
			93.064,
			93.121,
			93.181,
			93.242,
			93.274,
			93.306,
			93.384,
			93.43,
			93.492,
			93.555,
			93.6,
			93.616,
			93.677,
			93.71,
			93.738,
			93.8,
			93.817,
			93.864,
			93.926,
			93.989,
			94.048,
			94.113,
			94.143,
			94.174,
			94.249,
			94.297,
			94.359,
			94.422,
			94.468,
			94.546,
			94.578,
			94.606,
			94.684,
			94.731,
			94.794,
			94.855,
			94.917,
			94.978,
			95.011,
			95.04,
			95.104,
			95.122,
			95.167,
			95.228,
			95.289,
			95.337,
			95.351,
			95.413,
			95.444,
			95.478,
			95.554,
			95.601,
			95.66,
			95.725,
			95.786,
			95.849,
			95.88,
			95.911,
			95.971,
			96.035,
			96.097,
			96.157,
			96.206,
			96.218,
			96.283,
			96.314,
			96.345,
			96.422,
			96.469,
			96.53,
			96.592,
			96.655,
			96.715,
			96.747,
			96.778,
			96.839,
			96.855,
			96.9,
			96.963,
			97.026,
			97.09,
			97.151,
			97.185,
			97.211,
			97.291,
			97.336,
			97.398,
			97.461,
			97.506,
			97.522,
			97.583,
			97.616,
			97.645,
			97.725,
			97.771,
			97.832,
			97.895,
			97.956,
			98.017,
			98.048,
			98.08,
			98.159,
			98.205,
			98.267,
			98.328,
			98.373,
			98.451,
			98.515,
			98.592,
			98.639,
			98.7,
			98.761,
			98.825,
			98.888,
			98.95,
			99.026,
			99.072,
			99.134,
			99.196,
			99.248,
			99.321,
			99.383,
			99.444,
			99.507,
			99.57,
			99.63,
			99.695,
			99.755,
			99.787,
			99.817,
			99.896,
			99.942,
			100.004,
			100.065,
			100.111,
			100.126,
			100.22,
			100.25,
			100.313,
			100.375,
			100.44,
			100.5,
			100.563,
			100.654,
			100.686,
			100.762,
			100.807,
			100.871,
			100.933,
			100.979,
			100.995,
			101.089,
			101.118,
			101.18,
			101.196,
			101.241,
			101.311,
			101.366,
			101.429,
			101.492,
			101.521,
			101.553,
			101.632,
			101.676,
			101.739,
			101.801,
			101.847,
			101.925,
			101.956,
			101.987,
			102.064,
			102.112,
			102.173,
			102.234,
			102.297,
			102.391,
			102.422,
			102.498,
			102.545,
			102.608,
			102.716,
			102.795,
			102.823,
			102.856,
			102.932,
			102.978,
			103.04,
			103.104,
			103.165,
			103.229,
			103.259,
			103.289,
			103.35,
			103.413,
			103.477,
			103.538,
			103.584,
			103.6,
			103.662,
			103.722,
			103.783,
			103.8,
			103.848,
			103.911,
			103.971,
			104.034,
			104.095,
			104.127,
			104.157,
			104.234,
			104.282,
			104.342,
			104.405,
			104.453,
			104.469,
			104.531,
			104.562,
			104.592,
			104.667,
			104.715,
			104.779,
			104.839,
			104.9,
			104.963,
			104.995,
			105.025,
			105.089,
			105.103,
			105.149,
			105.215,
			105.273,
			105.321,
			105.398,
			105.433,
			105.46,
			105.537,
			105.583,
			105.647,
			105.708,
			105.77,
			105.832,
			105.862,
			105.893,
			105.956,
			105.97,
			106.019,
			106.08,
			106.143,
			106.189,
			106.203,
			106.267,
			106.298,
			106.328,
			106.405,
			106.452,
			106.515,
			106.578,
			106.638,
			106.7,
			106.73,
			106.761,
			106.822,
			106.884,
			106.947,
			107.011,
			107.071,
			107.133,
			107.165,
			107.195,
			107.274,
			107.32,
			107.382,
			107.445,
			107.505,
			107.568,
			107.6,
			107.63,
			107.692,
			107.755,
			107.817,
			107.879,
			107.939,
			108.034,
			108.065,
			108.142,
			108.189,
			108.249,
			108.311,
			108.359,
			108.375,
			108.436,
			108.497,
			108.559,
			108.622,
			108.683,
			108.745,
			108.808,
			108.871,
			108.9,
			108.931,
			109.009,
			109.055,
			109.117,
			109.181,
			109.229,
			109.244,
			109.305,
			109.333,
			109.368,
			109.427,
			109.442,
			109.49,
			109.557,
			109.615,
			109.675,
			109.739,
			109.799,
			109.862,
			109.877,
			109.924,
			109.988,
			110.048,
			110.096,
			110.173,
			110.203,
			110.233,
			110.313,
			110.422,
			110.483,
			110.544,
			110.606,
			110.637,
			110.67,
			110.73,
			110.746,
			110.794,
			110.855,
			110.964,
			110.98,
			111.04,
			111.103,
			111.179,
			111.229,
			111.29,
			111.35,
			111.412,
			111.475,
			111.505,
			111.537,
			111.598,
			111.621,
			111.661,
			111.722,
			111.783,
			111.832,
			111.847,
			111.909,
			111.941,
			111.971,
			112.048,
			112.094,
			112.156,
			112.217,
			112.28,
			112.344,
			112.375,
			112.405,
			112.467,
			112.527,
			112.59,
			112.7,
			112.777,
			112.807,
			112.837,
			112.915,
			112.963,
			113.026,
			113.085,
			113.149,
			113.21,
			113.242,
			113.272,
			113.336,
			113.349,
			113.397,
			113.459,
			113.522,
			113.566,
			113.645,
			113.681,
			113.707,
			113.785,
			113.832,
			113.894,
			113.956,
			114.017,
			114.079,
			114.109,
			114.141,
			114.204,
			114.217,
			114.266,
			114.327,
			114.39,
			114.452,
			114.514,
			114.578,
			114.651,
			114.699,
			114.76,
			114.824,
			114.871,
			114.948,
			115.008,
			115.071,
			115.133,
			115.196,
			115.257,
			115.32,
			115.381,
			115.413,
			115.444,
			115.52,
			115.569,
			115.63,
			115.692,
			115.741,
			115.815,
			115.877,
			115.94,
			115.999,
			116.065,
			116.127,
			116.188,
			116.25,
			116.281,
			116.31,
			116.389,
			116.434,
			116.497,
			116.561,
			116.607,
			116.62,
			116.683,
			116.714,
			116.747,
			116.809,
			116.822,
			116.87,
			116.933,
			116.979
		]
	],
	'1690_3377_7982': [
		[
			89.209
		],
		[
			0
		],
		[
			0,
			2.219,
			3.546,
			6.208,
			7.542,
			10.216,
			12.896,
			16.928,
			19.624,
			23.682,
			25.038,
			26.396,
			31.845,
			33.211,
			37.32,
			40.068,
			44.203,
			49.743,
			51.132,
			53.915,
			58.103,
			60.903,
			65.117,
			67.934,
			72.173,
			73.588,
			75.005,
			79.264,
			82.109,
			86.392,
			89.255,
			93.566,
			97.894,
			99.34,
			103.69,
			106.599,
			110.975,
			113.901,
			118.302,
			121.245,
			124.195,
			125.672,
			130.112,
			133.08,
			136.054,
			140.527,
			143.517,
			148.013,
			151.018,
			155.536,
			158.557,
			163.1,
			166.135,
			170.7,
			173.751,
			176.808,
			178.338,
			182.94,
			184.476,
			189.095,
			192.18,
			195.272,
			196.819,
			199.919,
			203.024,
			204.579,
			209.25,
			212.372,
			215.498,
			220.198,
			223.339,
			228.059,
			231.209,
			235.937,
			239.091,
			243.828,
			250.171,
			251.763,
			254.949,
			256.544,
			262.941,
			264.543,
			267.75,
			270.96,
			275.785,
			279.009,
			283.856,
			285.473,
			287.093,
			291.956,
			295.201,
			298.451,
			303.333,
			306.593,
			311.491,
			313.126,
			314.761,
			321.314,
			322.955,
			327.884,
			331.175,
			336.118,
			339.419,
			342.723,
			344.377,
			349.346,
			352.662,
			355.983,
			362.641,
			364.308,
			369.315,
			370.987,
			372.659,
			377.683,
			381.038,
			386.075,
			389.435,
			392.8,
			397.852,
			401.226,
			406.297,
			407.991,
			409.685,
			414.776,
			418.174,
			421.574,
			423.276,
			430.095,
			431.801,
			436.913,
			440.321,
			443.731,
			448.843,
			452.239,
			457.29,
			460.619,
			465.543,
			468.774,
			473.542,
			476.671,
			481.294,
			485.84,
			488.831,
			493.25,
			496.147,
			499.003,
			503.216,
			505.978,
			511.409,
			512.744,
			515.39,
			516.699,
			519.284,
			521.831,
			525.575,
			528.024,
			532.818,
			533.997,
			537.481,
			539.76,
			543.11,
			545.299,
			548.516,
			551.65,
			553.694,
			555.702,
			556.692,
			558.646,
			560.564,
			563.376,
			565.208,
			567.897,
			569.65,
			572.212,
			573.874,
			575.499,
			579.409,
			582.382,
			583.103,
			585.22,
			586.591,
			588.586,
			589.88,
			591.756,
			594.137,
			594.71,
			596.383,
			597.45,
			598.484,
			600.909,
			602.274,
			602.717,
			603.156,
			604.443,
			605.277,
			606.504,
			607.311,
			608.514,
			609.317,
			610.119,
			611.321,
			611.722,
			612.123,
			613.336,
			614.152,
			614.993,
			615.414,
			616.259,
			617.106,
			617.532,
			618.816,
			619.751,
			620.783,
			622.331,
			623.365,
			625.081,
			626.387,
			628.337,
			629.619,
			631.442,
			632.647,
			634.443,
			635.557,
			636.112,
			636.665,
			638.88,
			639.434,
			641.272,
			643.147,
			644.41,
			647.086,
			647.766,
			649.133,
			651.058,
			652.276,
			654.12,
			655.324,
			657.466,
			658.008,
			659.651,
			660.705,
			662.229,
			663.26,
			664.305,
			665.913,
			667.573,
			668.698,
			669.267,
			670.419,
			671.627,
			673.453,
			674.669,
			676.539,
			677.174,
			677.813,
			679.756,
			681.074,
			682.414,
			684.455,
			685.834,
			687.944,
			688.655,
			689.369,
			691.536,
			692.994,
			695.209,
			696.704,
			698.97,
			700.5,
			702.046,
			702.825,
			705.184,
			706.773,
			708.365,
			710.773,
			712.393,
			715.648,
			716.462,
			720.575,
			723.093,
			724.8,
			727.38,
			729.121,
			729.997,
			730.877,
			733.53,
			734.423,
			735.317,
			738.002,
			739.787,
			742.466,
			745.996,
			746.875,
			749.496,
			751.223,
			752.943,
			755.526,
			757.25,
			760.691,
			761.551,
			764.119,
			765.828,
			768.39,
			770.093,
			771.788,
			772.635,
			774.327,
			776.896,
			779.454,
			781.16,
			782.859,
			785.403,
			787.102,
			788.794,
			791.344,
			793.051,
			793.902,
			795.603,
			797.319,
			799.878,
			801.598,
			804.186,
			805.046,
			805.909,
			808.52,
			810.258,
			812.866,
			814.603,
			817.194,
			818.908,
			821.483,
			824.091,
			825.827,
			827.596,
			830.258,
			832.021,
			833.77,
			836.4,
			839.034,
			840.796,
			842.578,
			845.273,
			847.075,
			849.789,
			850.695,
			851.601,
			854.269,
			856.047,
			858.688,
			860.443,
			863.097,
			864.884,
			865.779,
			867.579,
			869.401,
			872.138,
			873.954,
			878.472,
			881.146,
			882.924,
			885.569,
			887.329,
			889.951,
			891.696,
			893.438,
			896.039,
			896.907,
			897.775,
			900.374,
			902.098,
			904.734,
			908.267,
			909.159,
			912.755,
			913.663,
			916.409,
			918.255,
			920.121,
			922.933,
			924.834,
			928.675,
			929.639,
			932.543,
			934.495,
			937.443,
			939.425,
			942.434,
			944.455,
			947.519,
			950.61,
			952.685,
			954.772,
			956.866,
			958.96,
			962.112,
			964.227,
			965.288,
			968.474,
			970.595,
			972.697,
			975.811,
			977.851,
			980.846,
			981.825,
			982.796,
			985.656,
			987.521,
			990.254,
			992.044,
			994.673,
			997.239,
			998.895,
			1000.517,
			1001.316,
			1002.883,
			1004.431,
			1005.965,
			1008.245,
			1009.758,
			1011.272,
			1012.037,
			1014.338,
			1015.881,
			1017.435,
			1021.349,
			1023.688,
			1025.245,
			1027.579,
			1029.128,
			1031.439,
			1032.985,
			1035.291,
			1036.801,
			1039.108,
			1041.353,
			1042.827,
			1044.295,
			1046.438,
			1047.141,
			1047.841,
			1050.582,
			1053.273,
			1054.623,
			1055.958,
			1057.959,
			1059.981,
			1061.323,
			1062.664,
			1064.71,
			1065.392,
			1066.072,
			1068.122,
			1069.504,
			1071.577,
			1072.963,
			1074.344,
			1075.033,
			1077.109,
			1078.509,
			1079.918,
			1082.041,
			1083.488,
			1086.504,
			1087.262,
			1089.541,
			1091.059,
			1093.344,
			1094.86,
			1096.373,
			1097.129,
			1099.407,
			1100.927,
			1103.202,
			1103.96,
			1106.227,
			1107.732,
			1109.976,
			1112.925,
			1113.658,
			1115.068,
			1117.131,
			1118.499,
			1120.525,
			1121.856,
			1123.843,
			1124.504,
			1125.165,
			1127.139,
			1128.448,
			1130.412,
			1131.721,
			1133.686,
			1134.997,
			1136.954,
			1138.259,
			1138.913,
			1140.21,
			1141.516,
			1143.492,
			1144.158,
			1144.827,
			1146.875,
			1147.567,
			1148.264,
			1150.376,
			1151.813,
			1153.267,
			1155.49,
			1156.988,
			1159.258,
			1160.021,
			1160.787,
			1163.14,
			1164.721,
			1167.168,
			1168.844,
			1171.376,
			1173.084,
			1175.651,
			1178.29,
			1180.056,
			1181.871,
			1184.762,
			1186.699,
			1189.754,
			1191.785,
			1193.78,
			1196.76,
			1199.385,
			1201.119,
			1202.844,
			1205.404,
			1206.252,
			1207.099,
			1210.417,
			1211.241,
			1213.65,
			1215.225,
			1216.8,
			1217.57,
			1219.046,
			1221.256,
			1223.439,
			1224.865,
			1226.288,
			1228.431,
			1229.876,
			1232.04,
			1232.762,
			1233.495,
			1235.717,
			1237.201,
			1239.467,
			1241,
			1242.539,
			1243.313,
			1245.658,
			1247.219,
			1249.576,
			1250.368,
			1252.748,
			1254.339,
			1256.729,
			1259.95,
			1260.761,
			1262.393,
			1263.214,
			1264.846,
			1266.505,
			1269.007,
			1270.679,
			1273.196,
			1274.889,
			1277.454,
			1279.181,
			1281.797,
			1286.222,
			1288.016,
			1288.918,
			1290.732,
			1293.468,
			1295.307,
			1297.156,
			1299.948,
			1301.828,
			1305.646,
			1306.608,
			1309.527,
			1311.489,
			1313.458,
			1316.431,
			1318.417,
			1323.422,
			1326.456,
			1327.473,
			1328.5,
			1331.6,
			1333.688,
			1336.844,
			1338.946,
			1340,
			1342.112,
			1345.291,
			1347.42,
			1349.558,
			1354.947,
			1358.214,
			1360.406,
			1363.712,
			1365.929,
			1369.273,
			1371.515,
			1373.767,
			1378.301,
			1379.441,
			1382.875,
			1385.176,
			1388.641,
			1390.962,
			1393.293,
			1394.462,
			1396.808,
			1397.985,
			1400.347,
			1403.907,
			1408.682,
			1412.281,
			1414.69,
			1419.534,
			1420.751,
			1424.418,
			1426.875,
			1430.577,
			1433.054,
			1436.786,
			1439.288,
			1441.799,
			1446.849,
			1449.385,
			1451.932,
			1454.492,
			1458.349,
			1463.524,
			1464.822,
			1467.426,
			1468.729,
			1471.332,
			1473.925,
			1477.78,
			1480.312,
			1485.265,
			1486.479,
			1490.068,
			1492.417,
			1498.14,
			1501.461,
			1504.699,
			1506.811,
			1509.909,
			1510.923,
			1513.909,
			1515.851,
			1518.695,
			1520.55,
			1523.269,
			1525.921,
			1527.653,
			1529.347,
			1531.822,
			1533.441,
			1535.782,
			1536.543,
			1537.29,
			1539.481,
			1540.899,
			1542.957,
			1544.292,
			1546.25,
			1547.524,
			1549.401,
			1551.256,
			1552.496,
			1553.734,
			1556.214,
			1556.856,
			1558.808,
			1560.116,
			1562.142,
			1563.56,
			1565.698,
			1567.185,
			1568.723,
			1571.031,
			1571.813,
			1572.604,
			1574.984,
			1576.569,
			1578.879,
			1580.415,
			1582.611,
			1584.014,
			1586.108,
			1587.472,
			1588.146,
			1589.488,
			1590.823,
			1592.838,
			1594.174,
			1596.835,
			1597.507,
			1599.506,
			1600.828,
			1602.82,
			1604.14,
			1606.112,
			1607.43,
			1608.086,
			1610.698,
			1611.351,
			1612.01,
			1613.985,
			1615.299,
			1617.282,
			1619.936,
			1620.601,
			1622.581,
			1623.89,
			1625.203,
			1627.181,
			1628.499,
			1630.485,
			1631.152,
			1631.825,
			1634.568,
			1637.365,
			1638.803,
			1640.978,
			1642.46,
			1643.211,
			1644.721,
			1647.011,
			1648.544,
			1650.087,
			1651.64,
			1653.182,
			1653.955,
			1656.283,
			1657.056,
			1657.822,
			1660.132,
			1661.681,
			1663.211,
			1665.512,
			1667.047,
			1669.325,
			1670.848,
			1673.105,
			1674.594,
			1676.839,
			1678.325,
			1679.814,
			1680.56,
			1682.065,
			1682.853,
			1684.434,
			1686.811,
			1688.432,
			1690.058,
			1692.511,
			1694.164,
			1696.657,
			1698.338,
			1700.876,
			1702.577,
			1705.123,
			1706.829,
			1708.542,
			1711.117,
			1711.986,
			1712.852,
			1716.346,
			1717.226,
			1719.875,
			1721.657,
			1724.356,
			1728,
			1728.919,
			1731.695,
			1733.565,
			1735.447,
			1738.295,
			1740.211,
			1743.107,
			1744.078,
			1745.053,
			1747.995,
			1749.971,
			1752.958,
			1754.963,
			1756.98,
			1757.993,
			1760.026,
			1761.047,
			1762.071,
			1766.195,
			1767.233,
			1770.364,
			1772.459,
			1775.601,
			1777.703,
			1779.817,
			1780.877,
			1784.074,
			1786.218,
			1788.371,
			1793.793,
			1798.174,
			1799.276,
			1804.817,
			1808.162,
			1810.391,
			1813.712,
			1815.9,
			1816.983,
			1819.126,
			1821.231,
			1824.317,
			1826.327,
			1828.3,
			1830.236,
			1831.207,
			1834.953,
			1835.876,
			1838.621,
			1840.441,
			1842.254,
			1844.965,
			1846.768,
			1849.463,
			1850.357,
			1851.251,
			1853.924,
			1855.688,
			1858.332,
			1860.101,
			1862.755,
			1864.557,
			1867.271,
			1870.055,
			1871.934,
			1873.827,
			1876.716,
			1878.638,
			1881.503,
			1882.457,
			1883.411,
			1886.343,
			1888.293,
			1891.398,
			1893.47,
			1895.635,
			1898.867,
			1899.924,
			1900.979,
			1904.106,
			1909.05,
			1910.965,
			1913.833,
			1915.701,
			1918.509,
			1921.271,
			1923.116,
			1924.953,
			1927.709,
			1929.549,
			1932.308,
			1933.23,
			1934.155,
			1936.943,
			1938.806,
			1941.624,
			1943.508,
			1946.351,
			1948.257,
			1949.214,
			1951.14,
			1954.047,
			1955.024,
			1957.974,
			1959.952,
			1962.921,
			1964.921,
			1967.935,
			1970.969,
			1973.005,
			1975.051,
			1980.215,
			1983.335,
			1984.374,
			1985.416,
			1988.557,
			1990.664,
			1993.845,
			1995.984,
			1999.207,
			2001.36,
			2002.433,
			2004.569,
			2007.733,
			2009.808,
			2011.856,
			2015.871,
			2016.852,
			2019.756,
			2020.712,
			2021.664,
			2024.496,
			2026.363,
			2028.225,
			2031.011,
			2032.865,
			2036.559,
			2037.48,
			2040.23,
			2042.046,
			2044.768,
			2046.577,
			2048.384,
			2049.288,
			2051.112,
			2053.846,
			2055.687,
			2056.612,
			2058.459,
			2060.329,
			2063.175,
			2065.092,
			2068.034,
			2069.013,
			2070.004,
			2073.009,
			2075.001,
			2076.961,
			2079.886,
			2081.767,
			2085.526,
			2086.466,
			2090.234,
			2091.192,
			2094.063,
			2096.009,
			2098.946,
			2102.906,
			2103.897,
			2105.868,
			2106.849,
			2108.806,
			2110.786,
			2113.78,
			2115.795,
			2118.847,
			2119.862,
			2120.867,
			2123.881,
			2125.88,
			2130.847,
			2133.833,
			2135.803,
			2136.778,
			2138.729,
			2141.568,
			2142.496,
			2145.287,
			2149.022,
			2149.959,
			2151.858,
			2153.767,
			2154.726,
			2157.615,
			2159.554,
			2161.503,
			2164.447,
			2166.422,
			2169.402,
			2170.4,
			2171.4,
			2174.417,
			2176.438,
			2179.484,
			2181.528,
			2183.58,
			2184.593,
			2187.717,
			2189.814,
			2193.005,
			2195.153,
			2197.317,
			2200.583,
			2202.775,
			2206.085,
			2208.306,
			2210.539,
			2211.659,
			2213.908,
			2216.168,
			2219.58,
			2221.868,
			2225.319,
			2227.633,
			2231.124,
			2233.463,
			2236.993,
			2241.734,
			2242.925,
			2248.906,
			2251.311,
			2252.517,
			2254.938,
			2257.374,
			2261.053,
			2263.522,
			2268.49,
			2269.739,
			2272.242,
			2273.498,
			2276.016,
			2279.81,
			2282.352,
			2284.903,
			2288.746,
			2291.319,
			2295.196,
			2297.791,
			2301.7,
			2304.314,
			2308.25,
			2310.887,
			2312.206,
			2314.849,
			2318.821,
			2321.476,
			2324.139,
			2328.149,
			2330.834,
			2334.875,
			2336.226,
			2337.58,
			2341.652,
			2344.376,
			2348.476,
			2351.219,
			2355.348,
			2358.11,
			2359.493,
			2360.878,
			2366.439,
			2367.834,
			2372.029,
			2374.835,
			2377.649,
			2379.059,
			2381.883,
			2386.133,
			2388.975,
			2390.399,
			2393.251,
			2396.111,
			2400.413,
			2403.29,
			2407.619,
			2409.065,
			2410.513,
			2414.866,
			2417.776,
			2422.149,
			2425.073,
			2428.004,
			2429.474,
			2432.418,
			2433.893,
			2436.847,
			2441.292,
			2444.263,
			2447.24,
			2451.742,
			2454.709,
			2457.708,
			2460.71,
			2462.213,
			2465.223,
			2469.747,
			2472.794,
			2477.348,
			2480.397,
			2484.986,
			2486.519,
			2488.054,
			2492.718,
			2495.875,
			2500.643,
			2503.809,
			2508.611,
			2511.761,
			2513.337,
			2516.457,
			2521.14,
			2524.237,
			2527.366,
			2533.692,
			2535.283,
			2540.031,
			2541.622,
			2543.213,
			2548.046,
			2551.282,
			2554.527,
			2559.365,
			2562.545,
			2567.303,
			2568.875,
			2570.451,
			2576.559,
			2578.063,
			2582.532,
			2585.482,
			2589.876,
			2592.773,
			2594.232,
			2597.155,
			2601.566,
			2604.493,
			2607.42,
			2611.827,
			2614.752,
			2619.2,
			2620.685,
			2622.172,
			2626.641,
			2629.627,
			2634.107,
			2637.099,
			2640.096,
			2644.61,
			2646.118,
			2647.629,
			2653.682,
			2655.198,
			2659.745,
			2662.785,
			2665.83,
			2670.415,
			2673.48,
			2675.014,
			2678.084,
			2682.694,
			2685.772,
			2690.399,
			2693.493,
			2698.144,
			2699.696,
			2701.251,
			2705.922,
			2709.042,
			2713.729,
			2719.987,
			2721.553,
			2724.688,
			2726.257,
			2729.4,
			2734.119,
			2735.694,
			2740.425,
			2743.586,
			2748.337,
			2751.51,
			2754.687,
			2756.277,
			2759.46,
			2764.239,
			2767.425,
			2772.21,
			2775.404,
			2780.203,
			2783.406,
			2788.219,
			2791.432,
			2796.256,
			2799.475,
			2804.311,
			2807.538,
			2809.153,
			2812.384,
			2817.237,
			2820.476,
			2823.717,
			2828.584,
			2830.208,
			2831.832,
			2838.317,
			2844.732,
			2847.892,
			2851.013,
			2855.621,
			2858.645,
			2863.11,
			2864.58,
			2866.039,
			2870.358,
			2871.777,
			2873.187,
			2877.358,
			2880.088,
			2882.777,
			2884.105,
			2886.731,
			2889.318,
			2890.598,
			2894.38,
			2896.855,
			2899.291,
			2902.874,
			2905.216,
			2909.788,
			2910.906,
			2914.203,
			2916.353,
			2919.506,
			2921.56,
			2923.575,
			2924.569,
			2927.493,
			2928.449,
			2932.183,
			2933.094,
			2935.782,
			2937.533,
			2940.08,
			2941.724,
			2944.118,
			2945.671,
			2946.434,
			2947.932,
			2949.393,
			2952.885,
			2954.879,
			2955.528,
			2956.168,
			2958.044,
			2959.242,
			2960.987,
			2962.111,
			2963.751,
			2964.804,
			2965.319,
			2966.336,
			2967.338,
			2967.835,
			2968.822,
			2969.801,
			2970.768,
			2971.729,
			2972.208,
			2973.166,
			2974.125,
			2974.615,
			2976.085,
			2977.064,
			2978.045,
			2979.596,
			2980.805,
			2983.227,
			2983.836,
			2985.928,
			2987.365,
			2989.549,
			2991.126,
			2993.529,
			2995.087,
			2995.85,
			2997.383,
			2999.604,
			3000.774,
			3001.961,
			3003.759,
			3004.358,
			3004.883,
			3006.474,
			3007.538,
			3009.169,
			3010.264,
			3011.929,
			3013.061,
			3014.193,
			3016.684,
			3017.329,
			3019.978,
			3020.767,
			3023.128,
			3024.76,
			3027.645,
			3030.404,
			3032.097,
			3033.791,
			3034.6,
			3035.874,
			3037.145,
			3039.053,
			3040.286,
			3042.098,
			3042.703,
			3043.309,
			3045.154,
			3046.42,
			3048.32,
			3049.588,
			3051.532,
			3052.827,
			3054.772,
			3056.08,
			3056.733,
			3057.386,
			3059.348,
			3060.651,
			3062.632,
			3063.964,
			3065.31,
			3065.988,
			3067.356,
			3068.046,
			3069.436,
			3071.556,
			3072.991,
			3074.44,
			3076.635,
			3078.124,
			3080.341,
			3081.087,
			3081.834,
			3084.094,
			3085.617,
			3087.926,
			3089.483,
			3091.842,
			3093.431,
			3094.23,
			3095.845,
			3098.307,
			3099.965,
			3101.639,
			3103.321,
			3105.838,
			3107.518,
			3109.212,
			3110.063,
			3114.359,
			3116.099,
			3118.733,
			3120.504,
			3123.18,
			3124.078,
			3124.979,
			3127.708,
			3129.544,
			3132.324,
			3134.193,
			3137.015,
			3138.908,
			3139.859,
			3141.769,
			3144.654,
			3146.592,
			3148.542,
			3150.502,
			3153.466,
			3156.457,
			3157.462,
			3158.469,
			3160.493,
			3161.508,
			3163.545,
			3165.594,
			3168.678,
			3170.747,
			3173.869,
			3174.911,
			3175.952,
			3179.093,
			3181.199,
			3184.38,
			3186.513,
			3189.726,
			3191.88,
			3192.96,
			3195.127,
			3197.303,
			3198.394,
			3200.583,
			3202.781,
			3206.094,
			3208.315,
			3211.665,
			3212.785,
			3213.906,
			3217.257,
			3219.466,
			3222.718,
			3226.921,
			3227.946,
			3230.963,
			3231.95,
			3234.859,
			3235.81,
			3236.752,
			3239.522,
			3241.326,
			3243.966,
			3245.696,
			3246.542,
			3248.213,
			3250.675,
			3252.294,
			3253.902,
			3256.302,
			3257.902,
			3260.305,
			3261.908,
			3264.323,
			3265.934,
			3268.367,
			3270,
			3271.632,
			3274.099,
			3274.923,
			3276.569,
			3279.053,
			3279.882,
			3282.367,
			3285.734,
			3286.575,
			3290.021,
			3290.887,
			3292.625,
			3293.508,
			3295.269,
			3297.028,
			3299.636,
			3301.365,
			3303.962,
			3304.828,
			3305.693,
			3308.309,
			3310.094,
			3312.768,
			3316.353,
			3317.248,
			3319.957,
			3321.76,
			3324.469,
			3326.27,
			3328.053,
			3330.587,
			3332.268,
			3335.603,
			3336.432,
			3338.1,
			3338.965,
			3340.691,
			3342.414,
			3344.995,
			3346.71,
			3350.129,
			3350.981,
			3353.527,
			3355.201,
			3359.336,
			3361.754,
			3363.365,
			3365.776,
			3367.387,
			3368.195,
			3369.81,
			3371.428,
			3373.862,
			3374.671,
			3375.482,
			3378.735,
			3379.545,
			3381.983,
			3383.618,
			3385.248,
			3387.709,
			3389.36,
			3392.679,
			3393.516,
			3396.06,
			3397.774,
			3400.37,
			3402.117,
			3404.763,
			3406.544,
			3408.339,
			3409.242,
			3412.001,
			3413.856,
			3415.734,
			3418.576,
			3420.473,
			3423.366,
			3424.336,
			3425.309,
			3428.247,
			3430.22,
			3433.201,
			3435.203,
			3438.229,
			3441.281,
			3442.304,
			3446.425,
			3447.462,
			3450.59,
			3452.688,
			3454.787,
			3455.836,
			3457.941,
			3460.056,
			3461.117,
			3463.246,
			3466.459,
			3468.614,
			3471.865,
			3474.045,
			3477.334,
			3478.436,
			3479.54,
			3482.867,
			3485.098,
			3488.463,
			3490.718,
			3492.979,
			3494.112,
			3496.385,
			3497.523,
			3499.807,
			3503.251,
			3505.559,
			3507.881,
			3511.385,
			3513.735,
			3516.095,
			3518.466,
			3519.655,
			3522.041,
			3523.237,
			3525.637,
			3529.255,
			3531.678,
			3534.111,
			3537.778,
			3539.004,
			3540.233,
			3543.934,
			3546.413,
			3550.153,
			3552.659,
			3556.436,
			3560.227,
			3562.764,
			3565.305,
			3569.131,
			3571.691,
			3575.546,
			3578.127,
			3583.317,
			3584.62,
			3588.532,
			3591.145,
			3593.765,
			3600.345,
			3604.315,
			3606.971,
			3609.2
		],
		[
			0,
			0.036,
			0.066,
			0.128,
			0.143,
			0.192,
			0.252,
			0.314,
			0.376,
			0.438,
			0.469,
			0.5,
			0.58,
			0.625,
			0.688,
			0.748,
			0.812,
			0.905,
			0.936,
			0.998,
			1.06,
			1.123,
			1.184,
			1.245,
			1.306,
			1.339,
			1.369,
			1.446,
			1.493,
			1.555,
			1.617,
			1.68,
			1.771,
			1.802,
			1.866,
			1.927,
			1.99,
			2.05,
			2.112,
			2.175,
			2.206,
			2.238,
			2.315,
			2.361,
			2.424,
			2.486,
			2.533,
			2.61,
			2.671,
			2.733,
			2.794,
			2.858,
			2.919,
			2.982,
			3.044,
			3.081,
			3.106,
			3.184,
			3.227,
			3.291,
			3.353,
			3.4,
			3.416,
			3.477,
			3.509,
			3.54,
			3.615,
			3.662,
			3.725,
			3.787,
			3.848,
			3.913,
			3.974,
			4.035,
			4.099,
			4.159,
			4.268,
			4.284,
			4.346,
			4.377,
			4.468,
			4.485,
			4.533,
			4.595,
			4.656,
			4.716,
			4.78,
			4.811,
			4.842,
			4.918,
			4.964,
			5.028,
			5.091,
			5.14,
			5.213,
			5.244,
			5.275,
			5.354,
			5.398,
			5.461,
			5.524,
			5.587,
			5.649,
			5.679,
			5.709,
			5.788,
			5.834,
			5.896,
			6.003,
			6.02,
			6.082,
			6.113,
			6.145,
			6.221,
			6.269,
			6.331,
			6.391,
			6.452,
			6.516,
			6.579,
			6.641,
			6.655,
			6.701,
			6.765,
			6.827,
			6.871,
			6.888,
			6.98,
			7.012,
			7.091,
			7.137,
			7.203,
			7.259,
			7.323,
			7.383,
			7.447,
			7.509,
			7.569,
			7.633,
			7.694,
			7.755,
			7.849,
			7.88,
			7.956,
			8.005,
			8.066,
			8.129,
			8.188,
			8.282,
			8.314,
			8.375,
			8.392,
			8.437,
			8.499,
			8.563,
			8.625,
			8.717,
			8.749,
			8.826,
			8.871,
			8.935,
			8.996,
			9.058,
			9.151,
			9.183,
			9.244,
			9.265,
			9.308,
			9.368,
			9.43,
			9.492,
			9.554,
			9.617,
			9.694,
			9.742,
			9.803,
			9.911,
			10.019,
			10.051,
			10.129,
			10.175,
			10.236,
			10.299,
			10.361,
			10.454,
			10.485,
			10.563,
			10.608,
			10.672,
			10.779,
			10.857,
			10.888,
			10.918,
			10.995,
			11.043,
			11.104,
			11.166,
			11.229,
			11.292,
			11.351,
			11.415,
			11.429,
			11.476,
			11.537,
			11.601,
			11.646,
			11.663,
			11.724,
			11.757,
			11.787,
			11.864,
			11.91,
			11.975,
			12.035,
			12.096,
			12.159,
			12.22,
			12.284,
			12.344,
			12.409,
			12.468,
			12.532,
			12.594,
			12.625,
			12.653,
			12.733,
			12.778,
			12.841,
			12.904,
			12.965,
			13.058,
			13.089,
			13.151,
			13.211,
			13.275,
			13.337,
			13.399,
			13.493,
			13.521,
			13.599,
			13.647,
			13.711,
			13.772,
			13.817,
			13.895,
			13.958,
			14.019,
			14.033,
			14.082,
			14.142,
			14.206,
			14.266,
			14.331,
			14.359,
			14.392,
			14.469,
			14.515,
			14.576,
			14.641,
			14.686,
			14.764,
			14.793,
			14.826,
			14.886,
			14.95,
			15.011,
			15.075,
			15.134,
			15.198,
			15.23,
			15.259,
			15.336,
			15.383,
			15.451,
			15.509,
			15.555,
			15.662,
			15.693,
			15.819,
			15.881,
			15.941,
			16.005,
			16.064,
			16.097,
			16.129,
			16.19,
			16.205,
			16.253,
			16.314,
			16.376,
			16.438,
			16.53,
			16.561,
			16.64,
			16.684,
			16.748,
			16.808,
			16.871,
			16.966,
			16.995,
			17.058,
			17.121,
			17.181,
			17.243,
			17.29,
			17.305,
			17.366,
			17.43,
			17.513,
			17.554,
			17.617,
			17.679,
			17.738,
			17.801,
			17.863,
			17.927,
			17.941,
			17.987,
			18.051,
			18.113,
			18.175,
			18.237,
			18.267,
			18.298,
			18.375,
			18.423,
			18.486,
			18.547,
			18.608,
			18.669,
			18.733,
			18.808,
			18.857,
			18.919,
			18.981,
			19.043,
			19.103,
			19.167,
			19.244,
			19.291,
			19.353,
			19.413,
			19.461,
			19.537,
			19.574,
			19.599,
			19.662,
			19.726,
			19.786,
			19.847,
			19.911,
			19.971,
			20.003,
			20.034,
			20.097,
			20.158,
			20.22,
			20.329,
			20.408,
			20.468,
			20.546,
			20.593,
			20.657,
			20.717,
			20.778,
			20.838,
			20.869,
			20.902,
			20.965,
			21.025,
			21.089,
			21.197,
			21.212,
			21.307,
			21.336,
			21.415,
			21.46,
			21.525,
			21.584,
			21.645,
			21.741,
			21.769,
			21.849,
			21.894,
			21.958,
			22.02,
			22.081,
			22.142,
			22.206,
			22.283,
			22.329,
			22.392,
			22.453,
			22.498,
			22.578,
			22.609,
			22.638,
			22.715,
			22.763,
			22.825,
			22.887,
			22.948,
			23.012,
			23.042,
			23.075,
			23.151,
			23.197,
			23.258,
			23.319,
			23.382,
			23.476,
			23.508,
			23.568,
			23.585,
			23.632,
			23.698,
			23.753,
			23.816,
			23.878,
			23.911,
			23.941,
			24.02,
			24.065,
			24.129,
			24.235,
			24.314,
			24.375,
			24.438,
			24.499,
			24.561,
			24.622,
			24.684,
			24.748,
			24.808,
			24.887,
			24.934,
			24.997,
			25.057,
			25.102,
			25.119,
			25.211,
			25.304,
			25.366,
			25.428,
			25.492,
			25.554,
			25.616,
			25.678,
			25.738,
			25.762,
			25.8,
			25.864,
			25.924,
			25.988,
			26.049,
			26.081,
			26.11,
			26.187,
			26.236,
			26.296,
			26.358,
			26.421,
			26.516,
			26.545,
			26.624,
			26.669,
			26.733,
			26.792,
			26.841,
			26.856,
			26.949,
			26.98,
			27.055,
			27.103,
			27.167,
			27.227,
			27.291,
			27.383,
			27.414,
			27.476,
			27.538,
			27.6,
			27.66,
			27.723,
			27.787,
			27.819,
			27.847,
			27.925,
			27.971,
			28.033,
			28.095,
			28.158,
			28.218,
			28.282,
			28.343,
			28.359,
			28.407,
			28.467,
			28.53,
			28.577,
			28.59,
			28.654,
			28.683,
			28.716,
			28.792,
			28.838,
			28.901,
			28.964,
			29.011,
			29.088,
			29.119,
			29.148,
			29.211,
			29.275,
			29.341,
			29.398,
			29.459,
			29.521,
			29.585,
			29.662,
			29.708,
			29.77,
			29.833,
			29.894,
			29.957,
			30.018,
			30.079,
			30.141,
			30.205,
			30.265,
			30.327,
			30.391,
			30.421,
			30.451,
			30.53,
			30.576,
			30.639,
			30.701,
			30.746,
			30.763,
			30.824,
			30.887,
			30.963,
			31.011,
			31.073,
			31.133,
			31.197,
			31.259,
			31.287,
			31.321,
			31.382,
			31.443,
			31.508,
			31.569,
			31.616,
			31.63,
			31.726,
			31.754,
			31.832,
			31.877,
			31.942,
			32.003,
			32.065,
			32.159,
			32.187,
			32.25,
			32.267,
			32.314,
			32.376,
			32.437,
			32.485,
			32.561,
			32.623,
			32.699,
			32.748,
			32.809,
			32.931,
			32.995,
			33.025,
			33.057,
			33.134,
			33.18,
			33.243,
			33.305,
			33.365,
			33.461,
			33.491,
			33.567,
			33.616,
			33.677,
			33.739,
			33.785,
			33.925,
			33.986,
			34.008,
			34.049,
			34.112,
			34.171,
			34.234,
			34.295,
			34.328,
			34.36,
			34.437,
			34.481,
			34.546,
			34.654,
			34.73,
			34.794,
			34.87,
			34.918,
			34.979,
			35.041,
			35.102,
			35.196,
			35.226,
			35.29,
			35.352,
			35.414,
			35.475,
			35.523,
			35.537,
			35.599,
			35.629,
			35.662,
			35.74,
			35.848,
			35.909,
			35.969,
			36.068,
			36.096,
			36.173,
			36.219,
			36.282,
			36.344,
			36.406,
			36.467,
			36.528,
			36.608,
			36.654,
			36.715,
			36.776,
			36.84,
			36.932,
			36.963,
			37.026,
			37.042,
			37.088,
			37.15,
			37.211,
			37.258,
			37.366,
			37.396,
			37.476,
			37.519,
			37.646,
			37.708,
			37.8,
			37.832,
			37.909,
			37.953,
			38.019,
			38.079,
			38.141,
			38.203,
			38.266,
			38.344,
			38.389,
			38.453,
			38.515,
			38.576,
			38.636,
			38.668,
			38.699,
			38.761,
			38.825,
			38.885,
			38.948,
			39.01,
			39.071,
			39.134,
			39.211,
			39.259,
			39.319,
			39.428,
			39.444,
			39.506,
			39.567,
			39.63,
			39.69,
			39.754,
			39.816,
			39.877,
			39.941,
			39.972,
			40.003,
			40.078,
			40.125,
			40.194,
			40.249,
			40.311,
			40.374,
			40.437,
			40.498,
			40.514,
			40.561,
			40.626,
			40.684,
			40.746,
			40.839,
			40.87,
			40.948,
			40.993,
			41.057,
			41.117,
			41.18,
			41.244,
			41.275,
			41.367,
			41.383,
			41.427,
			41.491,
			41.552,
			41.612,
			41.709,
			41.738,
			41.814,
			41.862,
			41.924,
			41.986,
			42.032,
			42.109,
			42.141,
			42.172,
			42.256,
			42.359,
			42.419,
			42.483,
			42.544,
			42.576,
			42.607,
			42.684,
			42.731,
			42.793,
			42.852,
			42.901,
			42.917,
			42.979,
			43.009,
			43.042,
			43.119,
			43.163,
			43.226,
			43.288,
			43.349,
			43.413,
			43.475,
			43.536,
			43.599,
			43.662,
			43.723,
			43.77,
			43.786,
			43.847,
			43.878,
			43.909,
			43.987,
			44.031,
			44.095,
			44.158,
			44.219,
			44.282,
			44.344,
			44.405,
			44.465,
			44.53,
			44.591,
			44.652,
			44.715,
			44.745,
			44.777,
			44.855,
			44.9,
			44.964,
			45.025,
			45.088,
			45.179,
			45.21,
			45.288,
			45.335,
			45.398,
			45.459,
			45.522,
			45.582,
			45.613,
			45.643,
			45.722,
			45.77,
			45.832,
			45.894,
			45.94,
			45.954,
			46.018,
			46.046,
			46.077,
			46.158,
			46.201,
			46.27,
			46.327,
			46.39,
			46.45,
			46.489,
			46.517,
			46.592,
			46.637,
			46.7,
			46.809,
			46.917,
			46.946,
			47.07,
			47.133,
			47.195,
			47.259,
			47.319,
			47.35,
			47.38,
			47.444,
			47.505,
			47.572,
			47.628,
			47.677,
			47.69,
			47.785,
			47.815,
			47.893,
			47.938,
			48.002,
			48.064,
			48.125,
			48.188,
			48.219,
			48.25,
			48.327,
			48.372,
			48.438,
			48.497,
			48.558,
			48.622,
			48.682,
			48.761,
			48.808,
			48.869,
			48.932,
			48.993,
			49.057,
			49.085,
			49.118,
			49.18,
			49.242,
			49.304,
			49.365,
			49.428,
			49.491,
			49.521,
			49.553,
			49.629,
			49.737,
			49.799,
			49.862,
			49.924,
			49.985,
			50.064,
			50.109,
			50.173,
			50.234,
			50.279,
			50.357,
			50.387,
			50.42,
			50.503,
			50.544,
			50.611,
			50.666,
			50.729,
			50.79,
			50.821,
			50.854,
			50.932,
			50.976,
			51.041,
			51.103,
			51.165,
			51.226,
			51.287,
			51.364,
			51.413,
			51.475,
			51.582,
			51.658,
			51.691,
			51.723,
			51.783,
			51.846,
			51.91,
			51.969,
			52.03,
			52.094,
			52.125,
			52.157,
			52.234,
			52.281,
			52.342,
			52.451,
			52.467,
			52.529,
			52.565,
			52.591,
			52.667,
			52.716,
			52.776,
			52.836,
			52.899,
			52.993,
			53.024,
			53.101,
			53.149,
			53.211,
			53.273,
			53.318,
			53.336,
			53.395,
			53.458,
			53.52,
			53.534,
			53.582,
			53.646,
			53.708,
			53.769,
			53.83,
			53.862,
			53.892,
			53.971,
			54.016,
			54.077,
			54.138,
			54.203,
			54.296,
			54.327,
			54.404,
			54.449,
			54.513,
			54.574,
			54.635,
			54.729,
			54.76,
			54.823,
			54.837,
			54.885,
			54.945,
			55.009,
			55.056,
			55.133,
			55.165,
			55.194,
			55.273,
			55.319,
			55.443,
			55.505,
			55.568,
			55.598,
			55.629,
			55.706,
			55.752,
			55.816,
			55.925,
			55.939,
			56.002,
			56.032,
			56.063,
			56.14,
			56.185,
			56.25,
			56.311,
			56.373,
			56.436,
			56.466,
			56.497,
			56.558,
			56.621,
			56.687,
			56.744,
			56.792,
			56.808,
			56.899,
			56.93,
			57.009,
			57.054,
			57.118,
			57.179,
			57.243,
			57.304,
			57.364,
			57.427,
			57.442,
			57.488,
			57.55,
			57.614,
			57.675,
			57.737,
			57.799,
			57.876,
			57.925,
			57.985,
			58.094,
			58.111,
			58.232,
			58.294,
			58.31,
			58.358,
			58.419,
			58.481,
			58.544,
			58.636,
			58.668,
			58.728,
			58.75,
			58.792,
			58.854,
			58.915,
			58.962,
			59.039,
			59.102,
			59.163,
			59.225,
			59.287,
			59.349,
			59.412,
			59.472,
			59.503,
			59.534,
			59.611,
			59.665,
			59.723,
			59.783,
			59.846,
			59.911,
			59.938,
			59.969,
			60.032,
			60.093,
			60.155,
			60.218,
			60.279,
			60.341,
			60.373,
			60.402,
			60.482,
			60.527,
			60.592,
			60.651,
			60.699,
			60.714,
			60.775,
			60.837,
			60.899,
			60.916,
			60.961,
			61.025,
			61.085,
			61.146,
			61.208,
			61.243,
			61.273,
			61.349,
			61.396,
			61.458,
			61.52,
			61.565,
			61.582,
			61.643,
			61.675,
			61.705,
			61.783,
			61.83,
			61.892,
			61.954,
			62.016,
			62.076,
			62.11,
			62.14,
			62.203,
			62.264,
			62.326,
			62.387,
			62.434,
			62.511,
			62.544,
			62.574,
			62.65,
			62.697,
			62.76,
			62.821,
			62.883,
			62.947,
			62.976,
			63.009,
			63.084,
			63.132,
			63.194,
			63.301,
			63.318,
			63.38,
			63.418,
			63.442,
			63.518,
			63.566,
			63.628,
			63.691,
			63.751,
			63.815,
			63.845,
			63.876,
			63.954,
			64.001,
			64.061,
			64.124,
			64.186,
			64.247,
			64.279,
			64.311,
			64.388,
			64.433,
			64.497,
			64.558,
			64.621,
			64.681,
			64.712,
			64.743,
			64.805,
			64.867,
			64.935,
			64.992,
			65.052,
			65.117,
			65.147,
			65.177,
			65.254,
			65.301,
			65.366,
			65.427,
			65.475,
			65.549,
			65.581,
			65.613,
			65.674,
			65.735,
			65.8,
			65.861,
			65.923,
			65.983,
			66.014,
			66.045,
			66.125,
			66.169,
			66.232,
			66.342,
			66.358,
			66.418,
			66.451,
			66.482,
			66.558,
			66.603,
			66.668,
			66.727,
			66.789,
			66.851,
			66.884,
			66.915,
			66.978,
			67.037,
			67.1,
			67.162,
			67.209,
			67.285,
			67.348,
			67.411,
			67.471,
			67.534,
			67.596,
			67.661,
			67.722,
			67.751,
			67.783,
			67.86,
			67.909,
			67.968,
			68.032,
			68.076,
			68.092,
			68.186,
			68.279,
			68.343,
			68.402,
			68.465,
			68.527,
			68.587,
			68.62,
			68.65,
			68.713,
			68.729,
			68.773,
			68.837,
			68.9,
			68.946,
			68.961,
			69.025,
			69.059,
			69.085,
			69.162,
			69.211,
			69.271,
			69.331,
			69.396,
			69.487,
			69.52,
			69.582,
			69.644,
			69.704,
			69.768,
			69.812,
			69.83,
			69.923,
			69.952,
			70.031,
			70.077,
			70.14,
			70.2,
			70.263,
			70.326,
			70.388,
			70.448,
			70.464,
			70.511,
			70.576,
			70.682,
			70.76,
			70.791,
			70.821,
			70.9,
			70.946,
			71.008,
			71.07,
			71.131,
			71.193,
			71.226,
			71.255,
			71.318,
			71.333,
			71.379,
			71.441,
			71.502,
			71.548,
			71.566,
			71.626,
			71.658,
			71.693,
			71.768,
			71.814,
			71.876,
			71.937,
			71.983,
			72.094,
			72.125,
			72.184,
			72.248,
			72.311,
			72.371,
			72.433,
			72.496,
			72.526,
			72.559,
			72.636,
			72.682,
			72.744,
			72.806,
			72.851,
			72.869,
			72.93,
			72.991,
			73.054,
			73.114,
			73.182,
			73.238,
			73.301,
			73.395,
			73.425,
			73.504,
			73.549,
			73.611,
			73.674,
			73.736,
			73.828,
			73.86,
			73.921,
			73.937,
			73.984,
			74.046,
			74.108,
			74.17,
			74.231,
			74.264,
			74.294,
			74.369,
			74.416,
			74.488,
			74.544,
			74.604,
			74.666,
			74.729,
			74.788,
			74.804,
			74.851,
			74.914,
			74.975,
			75.037,
			75.1,
			75.131,
			75.162,
			75.225,
			75.249,
			75.285,
			75.362,
			75.41,
			75.47,
			75.534,
			75.581,
			75.66,
			75.69,
			75.72,
			75.782,
			75.843,
			75.905,
			75.969,
			76.031,
			76.091,
			76.123,
			76.155,
			76.231,
			76.279,
			76.342,
			76.402,
			76.465,
			76.527,
			76.558,
			76.589,
			76.713,
			76.776,
			76.835,
			76.897,
			76.96,
			76.991,
			77.02,
			77.084,
			77.147,
			77.21,
			77.269,
			77.331,
			77.395,
			77.426,
			77.455,
			77.534,
			77.581,
			77.643,
			77.702,
			77.766,
			77.83,
			77.86,
			77.892,
			77.953,
			77.967,
			78.016,
			78.078,
			78.138,
			78.184,
			78.262,
			78.295,
			78.325,
			78.401,
			78.45,
			78.511,
			78.573,
			78.633,
			78.698,
			78.727,
			78.758,
			78.82,
			78.836,
			78.881,
			78.946,
			79.009,
			79.07,
			79.13,
			79.163,
			79.192,
			79.271,
			79.316,
			79.382,
			79.488,
			79.504,
			79.594,
			79.627,
			79.687,
			79.704,
			79.751,
			79.813,
			79.874,
			79.935,
			79.999,
			80.028,
			80.062,
			80.14,
			80.184,
			80.247,
			80.309,
			80.356,
			80.433,
			80.496,
			80.575,
			80.619,
			80.68,
			80.744,
			80.804,
			80.867,
			80.897,
			80.93,
			81.009,
			81.052,
			81.114,
			81.224,
			81.239,
			81.332,
			81.363,
			81.426,
			81.446,
			81.487,
			81.55,
			81.611,
			81.671,
			81.736,
			81.766,
			81.797,
			81.875,
			81.92,
			81.984,
			82.092,
			82.108,
			82.201,
			82.231,
			82.31,
			82.356,
			82.417,
			82.479,
			82.542,
			82.633,
			82.666,
			82.726,
			82.743,
			82.787,
			82.851,
			82.913,
			82.959,
			83.068,
			83.099,
			83.176,
			83.224,
			83.346,
			83.409,
			83.471,
			83.534,
			83.596,
			83.611,
			83.659,
			83.719,
			83.781,
			83.829,
			83.843,
			83.936,
			83.969,
			84.046,
			84.091,
			84.154,
			84.215,
			84.262,
			84.369,
			84.403,
			84.465,
			84.526,
			84.588,
			84.649,
			84.711,
			84.775,
			84.805,
			84.834,
			84.912,
			84.958,
			85.023,
			85.082,
			85.146,
			85.207,
			85.238,
			85.269,
			85.347,
			85.395,
			85.458,
			85.519,
			85.579,
			85.671,
			85.702,
			85.782,
			85.827,
			85.892,
			85.952,
			85.998,
			86.014,
			86.076,
			86.109,
			86.136,
			86.198,
			86.263,
			86.325,
			86.385,
			86.447,
			86.511,
			86.539,
			86.572,
			86.648,
			86.697,
			86.76,
			86.821,
			86.867,
			86.881,
			86.943,
			86.975,
			87.007,
			87.083,
			87.131,
			87.192,
			87.255,
			87.317,
			87.377,
			87.409,
			87.441,
			87.501,
			87.518,
			87.564,
			87.63,
			87.688,
			87.735,
			87.811,
			87.844,
			87.874,
			87.95,
			87.999,
			88.062,
			88.121,
			88.183,
			88.276,
			88.308,
			88.369,
			88.432,
			88.496,
			88.558,
			88.618,
			88.711,
			88.743,
			88.819,
			88.865,
			88.928,
			89.052,
			89.115,
			89.175,
			89.209
		]
	],
	'1690_2011_7982': [
		[
			85.585
		],
		[
			0
		],
		[
			0,
			2.374,
			7.727,
			9.071,
			13.11,
			15.811,
			19.874,
			22.592,
			25.317,
			29.417,
			33.532,
			36.284,
			39.042,
			44.581,
			45.97,
			50.148,
			57.146,
			59.957,
			62.774,
			64.186,
			67.014,
			68.431,
			71.269,
			74.112,
			78.388,
			81.244,
			86.977,
			88.414,
			92.741,
			95.634,
			99.987,
			102.898,
			107.279,
			111.675,
			113.144,
			119.035,
			120.512,
			124.953,
			127.921,
			132.384,
			135.368,
			138.357,
			139.854,
			144.354,
			147.362,
			151.884,
			154.907,
			157.936,
			162.491,
			165.536,
			170.112,
			173.171,
			177.77,
			180.844,
			185.465,
			188.553,
			193.195,
			197.849,
			200.959,
			204.074,
			207.194,
			210.32,
			211.885,
			215.019,
			218.158,
			219.729,
			224.453,
			227.608,
			230.767,
			235.51,
			238.675,
			245.019,
			246.61,
			251.386,
			254.577,
			259.377,
			262.583,
			265.793,
			267.4,
			270.616,
			272.225,
			275.449,
			280.293,
			283.529,
			286.769,
			291.638,
			294.888,
			301.399,
			303.03,
			306.294,
			311.198,
			314.472,
			319.39,
			322.674,
			327.606,
			330.9,
			335.851,
			344.125,
			347.442,
			352.423,
			355.749,
			357.413,
			360.746,
			364.084,
			369.098,
			372.446,
			377.477,
			380.835,
			385.883,
			389.255,
			394.322,
			397.707,
			402.792,
			406.188,
			411.289,
			414.695,
			418.105,
			424.943,
			426.655,
			431.801,
			435.245,
			440.431,
			443.898,
			449.096,
			454.27,
			457.689,
			461.077,
			466.088,
			469.384,
			474.264,
			475.872,
			477.47,
			482.205,
			485.311,
			489.896,
			492.909,
			497.364,
			500.287,
			501.734,
			504.597,
			508.818,
			510.204,
			514.305,
			516.99,
			519.642,
			520.954,
			523.552,
			527.38,
			531.124,
			533.574,
			535.987,
			539.542,
			541.87,
			545.294,
			546.418,
			547.532,
			550.821,
			552.967,
			556.119,
			558.176,
			560.197,
			561.195,
			563.162,
			564.133,
			566.048,
			568.852,
			570.681,
			572.476,
			575.105,
			576.816,
			580.134,
			580.941,
			582.531,
			583.314,
			584.857,
			586.363,
			589.978,
			592.046,
			592.72,
			593.385,
			595.966,
			596.601,
			598.42,
			599.593,
			601.283,
			602.895,
			603.93,
			604.941,
			605.434,
			606.396,
			607.332,
			609.587,
			610.897,
			611.326,
			611.753,
			613.026,
			613.866,
			615.189,
			616.083,
			617.422,
			618.762,
			619.211,
			621.557,
			622.153,
			623.957,
			625.425,
			627.805,
			629.397,
			631.388,
			633.386,
			634.699,
			635.87,
			638.818,
			640.709,
			641.366,
			642.021,
			644.006,
			645.381,
			647.527,
			648.965,
			651.014,
			652.303,
			652.947,
			654.237,
			655.473,
			656.59,
			658.26,
			659.369,
			660.45,
			660.955,
			662.971,
			663.475,
			664.994,
			666.008,
			667.019,
			668.542,
			669.564,
			671.132,
			671.667,
			672.204,
			673.825,
			674.917,
			676.59,
			678.87,
			679.447,
			680.61,
			681.202,
			682.397,
			684.211,
			685.438,
			686.684,
			687.936,
			689.817,
			691.725,
			692.366,
			693.011,
			694.315,
			694.971,
			696.295,
			697.633,
			699.664,
			701.037,
			703.133,
			703.839,
			704.549,
			706.698,
			708.137,
			710.317,
			711.791,
			714.012,
			715.51,
			716.263,
			717.784,
			720.105,
			721.668,
			723.234,
			725.636,
			727.253,
			730.536,
			731.362,
			733.846,
			735.517,
			738.03,
			739.688,
			741.339,
			743.795,
			745.429,
			747.861,
			749.471,
			751.855,
			753.442,
			755.842,
			757.45,
			759.862,
			762.272,
			763.871,
			765.468,
			769.453,
			772.601,
			773.387,
			775.77,
			777.367,
			779.757,
			781.35,
			783.734,
			785.323,
			786.119,
			787.709,
			790.089,
			790.897,
			793.31,
			795.724,
			796.538,
			797.351,
			800.593,
			801.417,
			803.879,
			805.519,
			807.182,
			809.676,
			811.344,
			813.855,
			814.693,
			815.532,
			818.046,
			819.725,
			822.274,
			823.993,
			825.716,
			826.587,
			828.356,
			829.24,
			831.009,
			833.644,
			835.4,
			837.159,
			839.799,
			841.563,
			843.362,
			845.164,
			846.067,
			847.886,
			850.63,
			852.453,
			856.955,
			859.632,
			860.528,
			861.428,
			864.16,
			865.999,
			868.795,
			870.681,
			873.522,
			875.418,
			878.263,
			881.086,
			882.975,
			884.851,
			887.679,
			888.616,
			889.554,
			893.327,
			894.271,
			897.111,
			899.012,
			901.869,
			905.702,
			908.592,
			909.56,
			910.529,
			913.446,
			914.427,
			915.412,
			918.376,
			920.379,
			922.395,
			923.407,
			925.438,
			927.48,
			928.506,
			931.567,
			933.617,
			935.674,
			938.759,
			940.821,
			943.97,
			945.028,
			946.092,
			949.301,
			951.452,
			954.718,
			956.879,
			960.168,
			962.39,
			963.496,
			964.605,
			969.052,
			971.265,
			973.497,
			975.73,
			979.072,
			981.277,
			983.455,
			986.664,
			989.797,
			991.848,
			994.865,
			996.831,
			1000.65,
			1001.579,
			1004.299,
			1006.072,
			1008.665,
			1010.364,
			1012.02,
			1012.839,
			1014.451,
			1016.804,
			1019.09,
			1020.569,
			1022.012,
			1024.09,
			1025.436,
			1028.043,
			1028.677,
			1029.926,
			1031.758,
			1032.958,
			1035.891,
			1037.633,
			1038.798,
			1041.121,
			1041.702,
			1043.479,
			1044.67,
			1046.458,
			1048.273,
			1049.493,
			1051.327,
			1052.565,
			1053.836,
			1057.028,
			1059.007,
			1060.336,
			1062.336,
			1063.701,
			1065.762,
			1067.137,
			1068.53,
			1070.636,
			1071.335,
			1072.735,
			1074.838,
			1075.536,
			1077.629,
			1079.048,
			1080.464,
			1081.171,
			1084.052,
			1084.799,
			1087.034,
			1088.525,
			1090.022,
			1092.262,
			1093.759,
			1096.005,
			1096.753,
			1097.503,
			1099.779,
			1101.31,
			1103.615,
			1105.176,
			1106.742,
			1107.528,
			1109.106,
			1111.494,
			1113.902,
			1115.402,
			1116.891,
			1118.391,
			1120.611,
			1123.552,
			1124.295,
			1125.79,
			1126.541,
			1128.052,
			1129.58,
			1131.896,
			1133.455,
			1136.591,
			1137.378,
			1139.758,
			1141.342,
			1143.727,
			1145.318,
			1146.936,
			1147.746,
			1150.175,
			1151.809,
			1153.422,
			1154.225,
			1155.026,
			1157.424,
			1159.806,
			1161.394,
			1164.58,
			1165.371,
			1167.826,
			1169.468,
			1171.104,
			1173.573,
			1175.208,
			1178.538,
			1179.372,
			1181.926,
			1183.751,
			1186.481,
			1188.416,
			1191.317,
			1193.233,
			1194.187,
			1196.095,
			1198.702,
			1200.38,
			1202.061,
			1204.594,
			1206.285,
			1208.826,
			1209.673,
			1213.041,
			1214.688,
			1216.334,
			1218.708,
			1220.245,
			1223.281,
			1224.014,
			1226.207,
			1227.67,
			1229.878,
			1231.351,
			1232.826,
			1233.573,
			1236.586,
			1237.342,
			1239.642,
			1241.192,
			1242.747,
			1245.111,
			1246.698,
			1249.102,
			1249.911,
			1250.709,
			1253.166,
			1254.817,
			1257.325,
			1258.979,
			1260.648,
			1261.485,
			1263.185,
			1264.018,
			1265.731,
			1268.33,
			1269.203,
			1271.844,
			1274.515,
			1276.313,
			1278.124,
			1279.948,
			1280.864,
			1283.635,
			1285.498,
			1288.318,
			1290.215,
			1292.118,
			1294.992,
			1295.956,
			1296.919,
			1299.821,
			1301.775,
			1304.747,
			1306.743,
			1308.764,
			1309.78,
			1312.837,
			1314.882,
			1317.959,
			1320.009,
			1322.069,
			1324.134,
			1327.233,
			1331.428,
			1332.485,
			1335.697,
			1337.852,
			1341.101,
			1343.275,
			1345.45,
			1348.729,
			1350.926,
			1354.24,
			1356.466,
			1359.824,
			1362.076,
			1365.473,
			1367.749,
			1368.891,
			1371.182,
			1374.637,
			1376.953,
			1379.279,
			1382.785,
			1383.959,
			1385.133,
			1391.037,
			1394.606,
			1396.999,
			1400.606,
			1403.023,
			1405.45,
			1409.101,
			1410.323,
			1411.546,
			1416.46,
			1417.694,
			1421.412,
			1423.903,
			1427.657,
			1432.692,
			1433.955,
			1437.759,
			1440.305,
			1442.86,
			1446.708,
			1449.282,
			1454.459,
			1455.759,
			1459.666,
			1462.275,
			1466.204,
			1471.461,
			1472.774,
			1475.39,
			1476.69,
			1479.269,
			1483.07,
			1488.003,
			1490.413,
			1493.965,
			1496.292,
			1499.711,
			1501.945,
			1503.047,
			1505.224,
			1507.363,
			1510.502,
			1512.548,
			1515.542,
			1516.521,
			1517.49,
			1521.286,
			1522.213,
			1524.944,
			1529.369,
			1531.077,
			1533.589,
			1535.21,
			1536.007,
			1537.56,
			1539.076,
			1541.284,
			1542.709,
			1544.099,
			1545.458,
			1546.127,
			1548.074,
			1549.334,
			1550.563,
			1553.539,
			1555.269,
			1555.84,
			1556.409,
			1558.153,
			1559.309,
			1561.041,
			1562.248,
			1564.093,
			1565.948,
			1567.242,
			1569.232,
			1570.566,
			1571.926,
			1574.024,
			1575.438,
			1577.563,
			1578.977,
			1581.103,
			1582.451,
			1584.435,
			1585.749,
			1587.038,
			1588.931,
			1589.559,
			1590.186,
			1592.71,
			1593.345,
			1595.245,
			1596.511,
			1598.443,
			1600.993,
			1602.918,
			1604.827,
			1606.092,
			1607.997,
			1609.257,
			1611.136,
			1612.402,
			1614.293,
			1615.547,
			1617.441,
			1618.708,
			1619.971,
			1620.601,
			1621.854,
			1623.08,
			1625.525,
			1626.137,
			1627.958,
			1629.165,
			1630.981,
			1632.222,
			1633.487,
			1634.12,
			1635.389,
			1637.345,
			1638.698,
			1642.141,
			1644.336,
			1645.072,
			1645.812,
			1648.053,
			1649.564,
			1651.853,
			1653.38,
			1655.696,
			1658.001,
			1659.533,
			1661.85,
			1663.348,
			1664.857,
			1667.14,
			1667.905,
			1668.672,
			1670.992,
			1671.771,
			1672.55,
			1674.901,
			1676.482,
			1678.013,
			1681.845,
			1684.11,
			1685.636,
			1687.962,
			1689.536,
			1691.925,
			1693.548,
			1696.008,
			1697.672,
			1700.19,
			1702.717,
			1704.425,
			1706.145,
			1708.755,
			1709.633,
			1710.513,
			1714.965,
			1717.672,
			1719.491,
			1722.244,
			1724.092,
			1725.938,
			1729.686,
			1730.629,
			1734.426,
			1735.382,
			1738.264,
			1742.142,
			1743.117,
			1745.075,
			1748.033,
			1751.013,
			1753.013,
			1755.023,
			1758.059,
			1760.098,
			1764.207,
			1765.241,
			1768.359,
			1770.442,
			1773.573,
			1775.673,
			1778.844,
			1782.04,
			1784.184,
			1787.42,
			1788.504,
			1791.769,
			1793.958,
			1797.259,
			1801.695,
			1802.81,
			1805.047,
			1806.168,
			1808.416,
			1810.669,
			1814.051,
			1816.301,
			1819.649,
			1820.754,
			1821.852,
			1825.097,
			1827.215,
			1830.323,
			1832.347,
			1835.334,
			1837.27,
			1838.228,
			1840.124,
			1841.997,
			1842.927,
			1844.77,
			1846.597,
			1849.317,
			1850.219,
			1851.12,
			1852.916,
			1855.59,
			1858.252,
			1860.042,
			1861.83,
			1864.545,
			1866.379,
			1870.133,
			1871.095,
			1873.997,
			1875.964,
			1878.906,
			1880.847,
			1882.787,
			1883.765,
			1885.749,
			1886.74,
			1888.738,
			1891.887,
			1894,
			1896.167,
			1901.477,
			1905.532,
			1906.515,
			1909.431,
			1911.325,
			1913.221,
			1916.007,
			1917.86,
			1920.615,
			1921.531,
			1922.448,
			1926.108,
			1927.024,
			1929.782,
			1931.628,
			1934.418,
			1936.301,
			1939.145,
			1941.062,
			1942.989,
			1943.958,
			1946.877,
			1948.838,
			1950.825,
			1954.847,
			1955.869,
			1958.95,
			1961.023,
			1964.152,
			1966.239,
			1969.385,
			1971.474,
			1973.564,
			1976.689,
			1977.741,
			1978.796,
			1983.033,
			1984.099,
			1987.301,
			1989.448,
			1992.678,
			1994.831,
			1998.033,
			2001.176,
			2003.229,
			2005.249,
			2008.23,
			2010.172,
			2013.042,
			2014.935,
			2017.766,
			2019.647,
			2022.471,
			2024.35,
			2027.151,
			2029.947,
			2031.807,
			2034.595,
			2035.52,
			2038.293,
			2040.132,
			2041.959,
			2042.871,
			2044.694,
			2047.418,
			2049.231,
			2051.966,
			2053.788,
			2056.545,
			2058.385,
			2061.172,
			2062.106,
			2063.039,
			2065.862,
			2067.76,
			2070.615,
			2074.441,
			2075.389,
			2077.278,
			2080.104,
			2082.894,
			2084.752,
			2086.612,
			2089.397,
			2091.267,
			2094.069,
			2095.958,
			2097.853,
			2098.8,
			2100.708,
			2103.584,
			2105.502,
			2107.418,
			2110.302,
			2112.255,
			2115.192,
			2117.183,
			2120.17,
			2122.156,
			2125.136,
			2127.105,
			2130.067,
			2133.031,
			2135.009,
			2136.954,
			2139.881,
			2140.824,
			2141.768,
			2145.569,
			2146.53,
			2149.425,
			2151.375,
			2154.322,
			2156.298,
			2159.281,
			2161.283,
			2163.296,
			2166.332,
			2167.348,
			2168.366,
			2171.437,
			2173.497,
			2176.586,
			2178.669,
			2180.762,
			2181.813,
			2183.922,
			2186.045,
			2187.114,
			2190.338,
			2194.707,
			2198.018,
			2200.24,
			2204.719,
			2205.845,
			2209.243,
			2211.522,
			2214.961,
			2217.268,
			2220.75,
			2223.084,
			2224.255,
			2226.606,
			2230.153,
			2231.34,
			2234.917,
			2237.315,
			2240.932,
			2243.353,
			2246.999,
			2249.436,
			2250.658,
			2253.11,
			2255.571,
			2261.79,
			2265.555,
			2266.815,
			2268.078,
			2273.151,
			2274.426,
			2278.262,
			2280.831,
			2283.409,
			2284.702,
			2287.293,
			2288.592,
			2291.196,
			2295.11,
			2297.724,
			2300.346,
			2302.98,
			2306.951,
			2312.28,
			2313.615,
			2317.627,
			2320.307,
			2322.994,
			2327.04,
			2329.748,
			2335.188,
			2336.553,
			2340.661,
			2343.408,
			2347.544,
			2350.311,
			2354.475,
			2357.26,
			2358.655,
			2361.451,
			2365.659,
			2368.474,
			2371.295,
			2375.542,
			2378.381,
			2384.083,
			2385.512,
			2392.688,
			2395.57,
			2399.906,
			2402.806,
			2408.625,
			2410.084,
			2415.935,
			2417.402,
			2421.805,
			2424.747,
			2427.695,
			2429.174,
			2432.137,
			2436.596,
			2441.069,
			2444.059,
			2447.055,
			2451.584,
			2454.594,
			2459.094,
			2462.116,
			2466.658,
			2469.716,
			2474.251,
			2477.3,
			2480.359,
			2481.891,
			2486.502,
			2488.042,
			2494.327,
			2495.914,
			2500.698,
			2507.101,
			2511.86,
			2515.013,
			2516.584,
			2521.297,
			2524.443,
			2527.596,
			2532.354,
			2535.545,
			2540.342,
			2543.51,
			2548.365,
			2551.618,
			2556.505,
			2559.759,
			2563.01,
			2564.632,
			2567.834,
			2572.641,
			2575.86,
			2577.454,
			2580.644,
			2583.83,
			2587.015,
			2591.77,
			2594.961,
			2599.756,
			2604.585,
			2607.788,
			2610.969,
			2615.793,
			2619.018,
			2623.865,
			2625.482,
			2627.101,
			2631.96,
			2635.202,
			2640.07,
			2643.323,
			2648.212,
			2653.11,
			2656.378,
			2661.281,
			2664.555,
			2667.836,
			2672.768,
			2676.06,
			2682.648,
			2684.297,
			2689.247,
			2692.553,
			2695.864,
			2700.837,
			2704.158,
			2710.809,
			2712.473,
			2717.467,
			2720.798,
			2725.797,
			2729.134,
			2732.474,
			2734.144,
			2737.488,
			2740.835,
			2742.511,
			2747.543,
			2750.902,
			2754.263,
			2759.311,
			2762.68,
			2767.739,
			2769.427,
			2771.115,
			2776.185,
			2779.569,
			2784.653,
			2791.447,
			2793.147,
			2796.548,
			2799.951,
			2806.765,
			2808.471,
			2813.591,
			2817.007,
			2822.133,
			2825.553,
			2828.977,
			2830.689,
			2835.832,
			2839.262,
			2842.695,
			2847.847,
			2851.285,
			2856.446,
			2859.89,
			2865.055,
			2868.488,
			2873.601,
			2881.958,
			2885.225,
			2886.843,
			2890.049,
			2893.217,
			2894.787,
			2897.899,
			2900.971,
			2905.504,
			2908.479,
			2911.414,
			2914.311,
			2915.745,
			2919.989,
			2922.768,
			2925.51,
			2932.195,
			2936.086,
			2937.362,
			2938.629,
			2942.376,
			2944.825,
			2948.43,
			2950.79,
			2954.263,
			2956.529,
			2957.648,
			2959.858,
			2963.106,
			2965.219,
			2967.291,
			2970.303,
			2972.242,
			2977.071,
			2979.963,
			2981.919,
			2983.865,
			2986.694,
			2988.579,
			2991.421,
			2992.368,
			2993.309,
			2997.001,
			2997.945,
			3000.773,
			3002.691,
			3004.627,
			3005.594,
			3007.546,
			3008.534,
			3010.505,
			3013.497,
			3015.567,
			3017.634,
			3020.831,
			3022.993,
			3026.046,
			3028.008,
			3030.927,
			3032.786,
			3035.576,
			3037.438,
			3039.294,
			3040.231,
			3043.052,
			3043.996,
			3047.883,
			3051.821,
			3053.817,
			3056.858,
			3058.886,
			3060.923,
			3061.952,
			3064.063,
			3065.11,
			3067.203,
			3069.314,
			3074.839,
			3077.99,
			3079.04,
			3080.091,
			3083.237,
			3085.328,
			3088.466,
			3090.565,
			3093.704,
			3096.941,
			3099.09,
			3101.238,
			3102.312,
			3104.413,
			3106.514,
			3109.654,
			3111.744,
			3115.844,
			3116.847,
			3119.947,
			3122.016,
			3124.097,
			3127.238,
			3129.345,
			3133.589,
			3134.657,
			3137.872,
			3140.029,
			3143.282,
			3145.468,
			3148.763,
			3150.969,
			3154.272,
			3157.605,
			3159.837,
			3162.079,
			3166.602,
			3167.74,
			3171.171,
			3172.32,
			3173.472,
			3175.778,
			3179.254,
			3181.582,
			3185.09,
			3187.439,
			3192.148,
			3193.323,
			3196.817,
			3199.112,
			3202.488,
			3206.854,
			3207.922,
			3212.095,
			3213.115,
			3216.11,
			3218.059,
			3219.972,
			3222.774,
			3224.614,
			3227.288,
			3228.164,
			3229.032,
			3231.597,
			3233.284,
			3235.791,
			3239.112,
			3239.942,
			3241.604,
			3242.438,
			3244.112,
			3246.635,
			3247.477,
			3250.005,
			3251.696,
			3254.211,
			3255.893,
			3257.556,
			3258.377,
			3260.006,
			3261.622,
			3262.421,
			3264.793,
			3266.364,
			3267.92,
			3270.243,
			3271.797,
			3274.115,
			3275.682,
			3278.044,
			3279.613,
			3281.989,
			3283.568,
			3285.924,
			3288.26,
			3289.038,
			3292.16,
			3292.941,
			3295.299,
			3296.911,
			3298.522,
			3299.329,
			3302.579,
			3303.392,
			3307.5,
			3309.154,
			3311.655,
			3313.326,
			3315.8,
			3316.597,
			3317.393,
			3319.778,
			3321.364,
			3323.732,
			3326.972,
			3327.797,
			3329.445,
			3330.27,
			3331.931,
			3334.415,
			3336.075,
			3337.736,
			3339.393,
			3341.863,
			3345.147,
			3345.946,
			3347.524,
			3348.314,
			3349.898,
			3352.268,
			3353.851,
			3355.441,
			3357.842,
			3358.646,
			3359.453,
			3361.874,
			3363.49,
			3367.544,
			3369.983,
			3371.614,
			3372.426,
			3374.059,
			3375.706,
			3378.175,
			3379.833,
			3382.345,
			3383.189,
			3384.036,
			3387.457,
			3388.32,
			3390.93,
			3392.688,
			3394.458,
			3397.144,
			3398.965,
			3401.723,
			3402.654,
			3403.588,
			3406.409,
			3407.342,
			3408.294,
			3411.169,
			3413.102,
			3416.025,
			3417.99,
			3419.966,
			3420.959,
			3423.955,
			3425.967,
			3427.992,
			3431.051,
			3433.105,
			3436.209,
			3438.289,
			3441.43,
			3443.527,
			3446.68,
			3448.793,
			3451.982,
			3454.121,
			3455.194,
			3456.269,
			3460.597,
			3461.685,
			3464.965,
			3469.375,
			3470.484,
			3474.943,
			3476.064,
			3479.439,
			3481.697,
			3483.962,
			3487.373,
			3489.659,
			3493.107,
			3495.423,
			3498.917,
			3501.26,
			3504.795,
			3507.165,
			3509.544,
			3510.737,
			3514.332,
			3516.74,
			3520.37,
			3522.802,
			3525.243,
			3528.921,
			3531.386,
			3533.86,
			3536.346,
			3537.593,
			3540.093,
			3543.863,
			3546.384,
			3550.179,
			3552.716,
			3557.811,
			3559.089,
			3562.936,
			3565.511,
			3569.394,
			3571.992,
			3575.9,
			3578.508,
			3581.122,
			3582.434,
			3586.378,
			3589.013,
			3591.654,
			3596.453
		],
		[
			0,
			0.048,
			0.14,
			0.172,
			0.234,
			0.295,
			0.358,
			0.42,
			0.481,
			0.551,
			0.607,
			0.665,
			0.73,
			0.808,
			0.854,
			0.916,
			1.04,
			1.102,
			1.133,
			1.161,
			1.226,
			1.241,
			1.286,
			1.347,
			1.412,
			1.473,
			1.567,
			1.597,
			1.675,
			1.72,
			1.784,
			1.846,
			1.907,
			2.001,
			2.031,
			2.109,
			2.154,
			2.217,
			2.279,
			2.342,
			2.402,
			2.435,
			2.467,
			2.543,
			2.588,
			2.657,
			2.712,
			2.76,
			2.839,
			2.9,
			2.962,
			3.025,
			3.086,
			3.148,
			3.212,
			3.272,
			3.334,
			3.411,
			3.457,
			3.521,
			3.58,
			3.629,
			3.643,
			3.704,
			3.737,
			3.769,
			3.844,
			3.891,
			3.954,
			4.016,
			4.078,
			4.17,
			4.203,
			4.265,
			4.325,
			4.389,
			4.449,
			4.497,
			4.512,
			4.573,
			4.603,
			4.635,
			4.719,
			4.76,
			4.823,
			4.884,
			4.947,
			5.038,
			5.068,
			5.131,
			5.194,
			5.256,
			5.318,
			5.363,
			5.441,
			5.504,
			5.581,
			5.688,
			5.752,
			5.813,
			5.875,
			5.906,
			5.939,
			6.001,
			6.061,
			6.127,
			6.186,
			6.248,
			6.31,
			6.373,
			6.45,
			6.495,
			6.559,
			6.619,
			6.683,
			6.743,
			6.806,
			6.884,
			6.93,
			6.995,
			7.054,
			7.117,
			7.178,
			7.239,
			7.318,
			7.364,
			7.426,
			7.489,
			7.551,
			7.613,
			7.644,
			7.675,
			7.737,
			7.799,
			7.862,
			7.921,
			7.985,
			8.046,
			8.077,
			8.109,
			8.184,
			8.231,
			8.294,
			8.355,
			8.402,
			8.419,
			8.481,
			8.541,
			8.619,
			8.666,
			8.728,
			8.79,
			8.852,
			8.915,
			8.946,
			8.977,
			9.038,
			9.1,
			9.161,
			9.225,
			9.272,
			9.288,
			9.347,
			9.381,
			9.411,
			9.488,
			9.535,
			9.597,
			9.658,
			9.722,
			9.813,
			9.843,
			9.906,
			9.921,
			9.969,
			10.031,
			10.139,
			10.218,
			10.246,
			10.28,
			10.357,
			10.402,
			10.463,
			10.527,
			10.59,
			10.681,
			10.713,
			10.775,
			10.791,
			10.835,
			10.905,
			11.007,
			11.085,
			11.113,
			11.146,
			11.223,
			11.27,
			11.334,
			11.394,
			11.458,
			11.55,
			11.58,
			11.658,
			11.704,
			11.768,
			11.831,
			11.891,
			11.952,
			12.013,
			12.095,
			12.138,
			12.201,
			12.31,
			12.387,
			12.419,
			12.45,
			12.51,
			12.572,
			12.635,
			12.696,
			12.761,
			12.822,
			12.852,
			12.884,
			12.945,
			13.006,
			13.069,
			13.129,
			13.176,
			13.194,
			13.285,
			13.318,
			13.395,
			13.442,
			13.505,
			13.564,
			13.628,
			13.688,
			13.721,
			13.751,
			13.813,
			13.876,
			13.938,
			14.046,
			14.06,
			14.124,
			14.155,
			14.185,
			14.262,
			14.31,
			14.372,
			14.432,
			14.494,
			14.559,
			14.589,
			14.619,
			14.68,
			14.697,
			14.746,
			14.805,
			14.867,
			14.913,
			14.991,
			15.029,
			15.055,
			15.131,
			15.177,
			15.239,
			15.302,
			15.364,
			15.426,
			15.456,
			15.488,
			15.566,
			15.612,
			15.674,
			15.736,
			15.798,
			15.891,
			15.921,
			15.998,
			16.046,
			16.108,
			16.169,
			16.231,
			16.295,
			16.355,
			16.431,
			16.479,
			16.542,
			16.605,
			16.663,
			16.727,
			16.79,
			16.865,
			16.913,
			16.978,
			17.088,
			17.194,
			17.225,
			17.285,
			17.347,
			17.411,
			17.471,
			17.535,
			17.595,
			17.628,
			17.657,
			17.736,
			17.78,
			17.843,
			17.907,
			17.952,
			17.969,
			18.06,
			18.093,
			18.171,
			18.217,
			18.278,
			18.339,
			18.402,
			18.464,
			18.495,
			18.525,
			18.602,
			18.651,
			18.711,
			18.774,
			18.819,
			18.836,
			18.896,
			18.929,
			18.959,
			19.038,
			19.084,
			19.151,
			19.209,
			19.271,
			19.33,
			19.364,
			19.393,
			19.455,
			19.518,
			19.581,
			19.688,
			19.767,
			19.798,
			19.828,
			19.905,
			19.952,
			20.013,
			20.076,
			20.139,
			20.202,
			20.263,
			20.339,
			20.386,
			20.449,
			20.51,
			20.556,
			20.573,
			20.665,
			20.697,
			20.774,
			20.819,
			20.883,
			21.006,
			21.069,
			21.098,
			21.131,
			21.192,
			21.216,
			21.255,
			21.317,
			21.378,
			21.425,
			21.441,
			21.501,
			21.534,
			21.564,
			21.643,
			21.69,
			21.752,
			21.811,
			21.859,
			21.936,
			21.968,
			22,
			22.061,
			22.121,
			22.185,
			22.247,
			22.309,
			22.371,
			22.402,
			22.43,
			22.51,
			22.558,
			22.619,
			22.679,
			22.744,
			22.806,
			22.836,
			22.928,
			22.992,
			23.053,
			23.114,
			23.178,
			23.275,
			23.301,
			23.378,
			23.425,
			23.489,
			23.547,
			23.597,
			23.611,
			23.672,
			23.735,
			23.813,
			23.859,
			23.922,
			23.984,
			24.046,
			24.138,
			24.169,
			24.231,
			24.292,
			24.356,
			24.463,
			24.542,
			24.603,
			24.685,
			24.727,
			24.789,
			24.851,
			24.913,
			25.005,
			25.036,
			25.113,
			25.161,
			25.225,
			25.337,
			25.41,
			25.472,
			25.547,
			25.595,
			25.657,
			25.72,
			25.781,
			25.843,
			25.875,
			25.907,
			25.983,
			26.031,
			26.091,
			26.154,
			26.2,
			26.213,
			26.308,
			26.339,
			26.417,
			26.462,
			26.527,
			26.589,
			26.65,
			26.71,
			26.742,
			26.775,
			26.837,
			26.898,
			26.961,
			27.02,
			27.068,
			27.084,
			27.147,
			27.208,
			27.286,
			27.332,
			27.4,
			27.455,
			27.518,
			27.611,
			27.643,
			27.704,
			27.719,
			27.765,
			27.828,
			27.891,
			27.951,
			28.045,
			28.075,
			28.154,
			28.198,
			28.261,
			28.324,
			28.371,
			28.385,
			28.478,
			28.51,
			28.573,
			28.586,
			28.632,
			28.696,
			28.759,
			28.822,
			28.913,
			28.944,
			29.022,
			29.069,
			29.131,
			29.192,
			29.255,
			29.348,
			29.379,
			29.441,
			29.502,
			29.565,
			29.626,
			29.689,
			29.75,
			29.781,
			29.811,
			29.889,
			29.935,
			29.998,
			30.061,
			30.108,
			30.185,
			30.213,
			30.309,
			30.371,
			30.43,
			30.494,
			30.555,
			30.65,
			30.679,
			30.756,
			30.805,
			30.869,
			30.927,
			30.976,
			30.989,
			31.084,
			31.114,
			31.191,
			31.238,
			31.301,
			31.362,
			31.426,
			31.488,
			31.523,
			31.546,
			31.627,
			31.672,
			31.736,
			31.798,
			31.842,
			31.859,
			31.919,
			31.953,
			31.984,
			32.061,
			32.106,
			32.17,
			32.232,
			32.294,
			32.354,
			32.386,
			32.417,
			32.494,
			32.541,
			32.608,
			32.663,
			32.725,
			32.787,
			32.819,
			32.852,
			32.927,
			32.975,
			33.038,
			33.098,
			33.147,
			33.162,
			33.255,
			33.285,
			33.361,
			33.408,
			33.472,
			33.53,
			33.595,
			33.687,
			33.719,
			33.797,
			33.842,
			33.908,
			33.967,
			34.014,
			34.09,
			34.153,
			34.214,
			34.278,
			34.341,
			34.401,
			34.464,
			34.525,
			34.556,
			34.586,
			34.664,
			34.71,
			34.772,
			34.836,
			34.88,
			34.898,
			35.021,
			35.084,
			35.143,
			35.207,
			35.268,
			35.331,
			35.394,
			35.425,
			35.456,
			35.533,
			35.58,
			35.65,
			35.703,
			35.764,
			35.858,
			35.889,
			35.966,
			36.013,
			36.075,
			36.136,
			36.2,
			36.293,
			36.322,
			36.386,
			36.446,
			36.511,
			36.618,
			36.634,
			36.694,
			36.726,
			36.757,
			36.836,
			36.945,
			37.004,
			37.068,
			37.131,
			37.193,
			37.253,
			37.269,
			37.313,
			37.378,
			37.439,
			37.502,
			37.564,
			37.595,
			37.625,
			37.71,
			37.75,
			37.813,
			37.936,
			37.997,
			38.06,
			38.12,
			38.136,
			38.184,
			38.246,
			38.308,
			38.371,
			38.43,
			38.463,
			38.496,
			38.571,
			38.618,
			38.68,
			38.789,
			38.867,
			38.897,
			38.927,
			38.99,
			39.052,
			39.114,
			39.175,
			39.237,
			39.331,
			39.363,
			39.438,
			39.485,
			39.551,
			39.61,
			39.658,
			39.735,
			39.795,
			39.873,
			39.921,
			39.983,
			40.043,
			40.105,
			40.169,
			40.199,
			40.23,
			40.307,
			40.353,
			40.417,
			40.479,
			40.539,
			40.634,
			40.728,
			40.789,
			40.851,
			40.912,
			40.974,
			41.035,
			41.097,
			41.177,
			41.222,
			41.284,
			41.345,
			41.392,
			41.409,
			41.47,
			41.53,
			41.613,
			41.655,
			41.719,
			41.781,
			41.842,
			41.904,
			41.936,
			41.967,
			42.029,
			42.09,
			42.152,
			42.261,
			42.339,
			42.369,
			42.398,
			42.477,
			42.523,
			42.586,
			42.646,
			42.713,
			42.805,
			42.835,
			42.911,
			42.959,
			43.022,
			43.082,
			43.129,
			43.145,
			43.208,
			43.238,
			43.269,
			43.346,
			43.394,
			43.455,
			43.562,
			43.641,
			43.702,
			43.764,
			43.828,
			43.892,
			43.951,
			44.014,
			44.075,
			44.136,
			44.212,
			44.261,
			44.324,
			44.386,
			44.429,
			44.447,
			44.572,
			44.634,
			44.696,
			44.758,
			44.819,
			44.88,
			44.974,
			45.003,
			45.083,
			45.128,
			45.19,
			45.3,
			45.315,
			45.378,
			45.44,
			45.516,
			45.562,
			45.625,
			45.686,
			45.75,
			45.841,
			45.872,
			45.936,
			45.998,
			46.065,
			46.12,
			46.184,
			46.278,
			46.308,
			46.385,
			46.431,
			46.495,
			46.555,
			46.617,
			46.709,
			46.741,
			46.802,
			46.819,
			46.864,
			46.926,
			46.989,
			47.035,
			47.112,
			47.144,
			47.175,
			47.251,
			47.3,
			47.36,
			47.424,
			47.485,
			47.548,
			47.58,
			47.61,
			47.67,
			47.687,
			47.733,
			47.794,
			47.857,
			47.903,
			47.92,
			47.979,
			48.043,
			48.122,
			48.167,
			48.228,
			48.292,
			48.352,
			48.446,
			48.477,
			48.539,
			48.603,
			48.664,
			48.725,
			48.772,
			48.789,
			48.85,
			48.881,
			48.911,
			48.989,
			49.036,
			49.098,
			49.222,
			49.314,
			49.346,
			49.409,
			49.469,
			49.531,
			49.595,
			49.656,
			49.719,
			49.746,
			49.781,
			49.858,
			49.904,
			49.969,
			50.027,
			50.084,
			50.152,
			50.214,
			50.277,
			50.308,
			50.339,
			50.414,
			50.461,
			50.524,
			50.63,
			50.647,
			50.711,
			50.771,
			50.848,
			50.894,
			50.958,
			51.02,
			51.08,
			51.143,
			51.175,
			51.205,
			51.284,
			51.331,
			51.396,
			51.455,
			51.514,
			51.581,
			51.64,
			51.719,
			51.764,
			51.828,
			51.888,
			51.951,
			52.013,
			52.075,
			52.154,
			52.197,
			52.26,
			52.323,
			52.384,
			52.476,
			52.507,
			52.586,
			52.63,
			52.694,
			52.755,
			52.802,
			52.819,
			52.88,
			52.943,
			53.005,
			53.067,
			53.128,
			53.191,
			53.252,
			53.313,
			53.344,
			53.377,
			53.454,
			53.501,
			53.561,
			53.671,
			53.687,
			53.748,
			53.811,
			53.888,
			53.935,
			53.998,
			54.058,
			54.121,
			54.183,
			54.246,
			54.307,
			54.321,
			54.369,
			54.435,
			54.492,
			54.555,
			54.617,
			54.677,
			54.756,
			54.803,
			54.862,
			54.926,
			54.988,
			55.051,
			55.111,
			55.191,
			55.238,
			55.298,
			55.361,
			55.406,
			55.421,
			55.516,
			55.547,
			55.625,
			55.672,
			55.734,
			55.795,
			55.857,
			55.918,
			55.979,
			56.043,
			56.057,
			56.105,
			56.168,
			56.228,
			56.291,
			56.352,
			56.4,
			56.415,
			56.476,
			56.509,
			56.539,
			56.617,
			56.725,
			56.788,
			56.849,
			56.943,
			56.971,
			57.035,
			57.096,
			57.16,
			57.22,
			57.283,
			57.345,
			57.378,
			57.408,
			57.485,
			57.531,
			57.593,
			57.655,
			57.717,
			57.778,
			57.841,
			57.903,
			57.918,
			57.963,
			58.027,
			58.136,
			58.213,
			58.246,
			58.276,
			58.358,
			58.401,
			58.461,
			58.524,
			58.569,
			58.585,
			58.648,
			58.679,
			58.709,
			58.786,
			58.834,
			58.895,
			58.956,
			59.018,
			59.113,
			59.144,
			59.219,
			59.267,
			59.331,
			59.392,
			59.439,
			59.545,
			59.578,
			59.638,
			59.703,
			59.763,
			59.826,
			59.886,
			59.947,
			59.979,
			60.012,
			60.089,
			60.137,
			60.197,
			60.258,
			60.322,
			60.419,
			60.445,
			60.569,
			60.631,
			60.693,
			60.756,
			60.846,
			60.88,
			60.957,
			61.002,
			61.066,
			61.128,
			61.173,
			61.188,
			61.251,
			61.312,
			61.391,
			61.439,
			61.5,
			61.563,
			61.625,
			61.686,
			61.747,
			61.809,
			61.872,
			61.935,
			61.995,
			62.041,
			62.058,
			62.151,
			62.18,
			62.26,
			62.306,
			62.369,
			62.481,
			62.553,
			62.586,
			62.614,
			62.693,
			62.741,
			62.806,
			62.863,
			62.928,
			62.987,
			63.05,
			63.127,
			63.173,
			63.236,
			63.297,
			63.345,
			63.362,
			63.422,
			63.485,
			63.545,
			63.562,
			63.608,
			63.671,
			63.731,
			63.795,
			63.855,
			63.919,
			63.994,
			64.044,
			64.106,
			64.164,
			64.214,
			64.29,
			64.32,
			64.351,
			64.413,
			64.475,
			64.545,
			64.601,
			64.661,
			64.755,
			64.785,
			64.863,
			64.91,
			64.974,
			65.034,
			65.095,
			65.19,
			65.219,
			65.297,
			65.345,
			65.405,
			65.469,
			65.53,
			65.625,
			65.653,
			65.73,
			65.78,
			65.84,
			65.902,
			65.948,
			65.965,
			66.026,
			66.057,
			66.089,
			66.166,
			66.213,
			66.275,
			66.336,
			66.398,
			66.46,
			66.492,
			66.522,
			66.606,
			66.647,
			66.71,
			66.818,
			66.833,
			66.896,
			66.955,
			67.034,
			67.079,
			67.143,
			67.204,
			67.267,
			67.33,
			67.359,
			67.391,
			67.468,
			67.515,
			67.576,
			67.638,
			67.685,
			67.764,
			67.824,
			67.901,
			67.95,
			68.011,
			68.136,
			68.197,
			68.226,
			68.26,
			68.321,
			68.335,
			68.383,
			68.445,
			68.507,
			68.57,
			68.631,
			68.669,
			68.695,
			68.774,
			68.818,
			68.879,
			68.989,
			69.065,
			69.095,
			69.128,
			69.189,
			69.252,
			69.313,
			69.375,
			69.436,
			69.497,
			69.529,
			69.56,
			69.639,
			69.684,
			69.748,
			69.81,
			69.871,
			69.994,
			70.071,
			70.12,
			70.18,
			70.244,
			70.306,
			70.368,
			70.4,
			70.428,
			70.508,
			70.553,
			70.614,
			70.677,
			70.729,
			70.74,
			70.802,
			70.831,
			70.863,
			70.94,
			70.988,
			71.05,
			71.113,
			71.172,
			71.236,
			71.298,
			71.36,
			71.422,
			71.484,
			71.546,
			71.591,
			71.609,
			71.701,
			71.731,
			71.81,
			71.919,
			71.98,
			72.041,
			72.103,
			72.136,
			72.165,
			72.229,
			72.244,
			72.29,
			72.352,
			72.461,
			72.539,
			72.569,
			72.6,
			72.677,
			72.723,
			72.792,
			72.847,
			72.91,
			73.002,
			73.034,
			73.096,
			73.11,
			73.159,
			73.219,
			73.283,
			73.344,
			73.435,
			73.468,
			73.545,
			73.592,
			73.655,
			73.717,
			73.778,
			73.87,
			73.902,
			73.98,
			74.027,
			74.089,
			74.15,
			74.214,
			74.275,
			74.336,
			74.415,
			74.459,
			74.522,
			74.63,
			74.645,
			74.708,
			74.739,
			74.77,
			74.831,
			74.895,
			74.956,
			75.019,
			75.08,
			75.173,
			75.204,
			75.281,
			75.328,
			75.391,
			75.497,
			75.513,
			75.607,
			75.638,
			75.716,
			75.761,
			75.824,
			75.885,
			75.946,
			76.01,
			76.041,
			76.071,
			76.135,
			76.195,
			76.258,
			76.365,
			76.383,
			76.443,
			76.475,
			76.507,
			76.585,
			76.63,
			76.694,
			76.754,
			76.817,
			76.879,
			76.919,
			76.941,
			77.003,
			77.035,
			77.063,
			77.143,
			77.189,
			77.253,
			77.311,
			77.359,
			77.436,
			77.501,
			77.56,
			77.622,
			77.685,
			77.749,
			77.809,
			77.903,
			77.931,
			78.01,
			78.056,
			78.119,
			78.181,
			78.228,
			78.242,
			78.336,
			78.367,
			78.491,
			78.554,
			78.618,
			78.676,
			78.739,
			78.768,
			78.801,
			78.864,
			78.924,
			78.992,
			79.095,
			79.11,
			79.172,
			79.205,
			79.235,
			79.311,
			79.358,
			79.421,
			79.48,
			79.546,
			79.638,
			79.67,
			79.73,
			79.747,
			79.791,
			79.857,
			79.917,
			79.962,
			80.04,
			80.072,
			80.102,
			80.18,
			80.228,
			80.351,
			80.413,
			80.475,
			80.505,
			80.536,
			80.598,
			80.661,
			80.723,
			80.786,
			80.83,
			80.847,
			80.94,
			80.971,
			81.052,
			81.095,
			81.156,
			81.219,
			81.265,
			81.343,
			81.375,
			81.406,
			81.467,
			81.483,
			81.529,
			81.591,
			81.652,
			81.715,
			81.778,
			81.809,
			81.839,
			81.918,
			81.963,
			82.025,
			82.087,
			82.135,
			82.212,
			82.274,
			82.336,
			82.395,
			82.461,
			82.522,
			82.583,
			82.644,
			82.677,
			82.706,
			82.786,
			82.832,
			82.893,
			83.002,
			83.017,
			83.118,
			83.142,
			83.22,
			83.265,
			83.33,
			83.389,
			83.453,
			83.514,
			83.575,
			83.638,
			83.7,
			83.763,
			83.823,
			83.869,
			83.885,
			83.977,
			84.009,
			84.086,
			84.134,
			84.195,
			84.257,
			84.321,
			84.381,
			84.413,
			84.445,
			84.505,
			84.569,
			84.63,
			84.693,
			84.737,
			84.846,
			84.876,
			84.956,
			85.003,
			85.063,
			85.127,
			85.187,
			85.25,
			85.282,
			85.313,
			85.389,
			85.437,
			85.499,
			85.585
		]
	],
	'2866_4246_7982': [
		[
			116.611
		],
		[
			0
		],
		[
			0,
			1.702,
			4.444,
			8.569,
			11.327,
			15.476,
			18.25,
			22.423,
			25.213,
			29.411,
			32.218,
			36.441,
			39.263,
			42.092,
			43.509,
			47.769,
			50.616,
			53.47,
			59.197,
			60.632,
			66.389,
			67.831,
			72.168,
			75.09,
			79.413,
			82.31,
			85.206,
			91.007,
			92.461,
			96.833,
			98.293,
			99.755,
			104.15,
			110.031,
			111.506,
			117.418,
			118.899,
			123.353,
			126.33,
			129.313,
			133.798,
			136.796,
			141.303,
			142.808,
			144.315,
			148.844,
			151.871,
			156.423,
			159.465,
			164.037,
			167.092,
			168.622,
			171.686,
			176.291,
			177.829,
			182.453,
			185.541,
			190.185,
			193.287,
			196.395,
			197.951,
			201.067,
			205.75,
			208.879,
			210.446,
			213.583,
			216.725,
			221.449,
			224.603,
			229.345,
			232.513,
			237.273,
			240.453,
			245.232,
			248.418,
			253.205,
			256.401,
			258.001,
			261.205,
			264.412,
			266.018,
			269.233,
			272.451,
			277.287,
			278.901,
			280.517,
			286.99,
			288.611,
			293.481,
			296.734,
			304.887,
			308.156,
			313.068,
			314.708,
			316.349,
			321.279,
			324.57,
			329.517,
			332.82,
			337.783,
			341.098,
			346.077,
			351.066,
			354.397,
			357.732,
			362.744,
			366.09,
			371.118,
			372.796,
			374.475,
			379.52,
			382.888,
			386.261,
			391.329,
			394.712,
			399.794,
			403.187,
			408.285,
			411.688,
			416.8,
			420.213,
			423.63,
			425.339,
			428.762,
			432.188,
			433.903,
			439.053,
			442.491,
			445.933,
			451.104,
			454.555,
			461.472,
			463.203,
			468.403,
			471.874,
			477.088,
			480.568,
			485.794,
			489.283,
			491.029,
			494.523,
			498.019,
			501.519,
			506.775,
			510.283,
			515.55,
			522.585,
			524.345,
			529.633,
			533.162,
			536.694,
			541.998,
			545.537,
			550.848,
			552.619,
			554.39,
			563.229,
			568.49,
			571.978,
			577.188,
			582.373,
			585.833,
			589.239,
			590.93,
			592.612,
			597.577,
			602.484,
			604.1,
			605.706,
			612.065,
			613.625,
			618.265,
			621.331,
			624.379,
			628.935,
			631.967,
			637.998,
			639.511,
			644.051,
			647.102,
			651.64,
			654.666,
			659.205,
			662.208,
			663.724,
			666.76,
			671.357,
			674.409,
			677.434,
			682.027,
			685.062,
			689.614,
			691.132,
			692.648,
			697.177,
			700.217,
			704.778,
			707.846,
			710.89,
			715.451,
			716.97,
			718.487,
			723.009,
			726.032,
			730.575,
			733.576,
			738.04,
			745.532,
			748.496,
			752.982,
			755.998,
			760.495,
			763.48,
			768.013,
			769.53,
			771.049,
			775.626,
			778.69,
			783.299,
			789.467,
			791.013,
			794.109,
			795.659,
			797.211,
			801.899,
			805.011,
			809.651,
			812.76,
			817.424,
			820.56,
			825.226,
			829.896,
			833.017,
			836.151,
			840.891,
			844.05,
			850.462,
			852.097,
			860.131,
			864.928,
			868.113,
			872.87,
			876.032,
			877.613,
			880.77,
			883.92,
			885.493,
			888.632,
			891.761,
			897.984,
			899.529,
			902.612,
			905.686,
			907.221,
			911.791,
			914.849,
			917.907,
			922.516,
			925.571,
			931.686,
			933.217,
			937.787,
			940.856,
			945.47,
			948.58,
			951.671,
			953.219,
			956.295,
			959.403,
			960.959,
			968.773,
			971.915,
			976.649,
			979.818,
			984.594,
			986.191,
			987.789,
			992.598,
			995.81,
			1000.635,
			1003.853,
			1007.075,
			1011.918,
			1015.152,
			1021.63,
			1028.119,
			1031.369,
			1036.249,
			1039.506,
			1042.768,
			1044.4,
			1049.303,
			1052.576,
			1055.852,
			1060.771,
			1064.054,
			1070.626,
			1072.27,
			1077.207,
			1080.501,
			1085.443,
			1088.741,
			1093.688,
			1098.639,
			1100.291,
			1105.252,
			1108.563,
			1113.537,
			1116.859,
			1121.849,
			1128.517,
			1130.186,
			1133.528,
			1135.199,
			1138.539,
			1143.524,
			1146.818,
			1150.079,
			1158.067,
			1164.282,
			1165.812,
			1170.345,
			1173.321,
			1177.715,
			1180.595,
			1184.841,
			1187.622,
			1191.721,
			1194.405,
			1198.36,
			1200.951,
			1203.503,
			1207.264,
			1209.725,
			1212.153,
			1213.354,
			1216.908,
			1219.235,
			1221.528,
			1224.896,
			1227.095,
			1230.325,
			1231.386,
			1232.437,
			1235.537,
			1237.576,
			1240.542,
			1242.474,
			1245.307,
			1247.154,
			1249.876,
			1252.552,
			1254.313,
			1256.061,
			1258.656,
			1260.382,
			1262.107,
			1263.831,
			1264.692,
			1267.287,
			1269.019,
			1270.752,
			1273.382,
			1275.133,
			1277.801,
			1278.694,
			1279.587,
			1282.296,
			1284.121,
			1286.873,
			1288.736,
			1291.533,
			1293.433,
			1296.27,
			1298.17,
			1301.006,
			1302.868,
			1305.646,
			1307.452,
			1311.019,
			1311.887,
			1314.468,
			1316.18,
			1318.699,
			1320.353,
			1322.009,
			1324.475,
			1326.085,
			1328.464,
			1330.025,
			1332.33,
			1333.841,
			1335.334,
			1336.076,
			1337.549,
			1339.773,
			1341.985,
			1343.471,
			1344.951,
			1347.184,
			1348.696,
			1350.951,
			1352.471,
			1354.76,
			1356.285,
			1358.557,
			1360.07,
			1362.333,
			1363.829,
			1364.577,
			1366.077,
			1368.333,
			1369.085,
			1371.35,
			1372.879,
			1375.179,
			1376.716,
			1378.269,
			1379.044,
			1380.595,
			1382.925,
			1384.475,
			1386.798,
			1388.34,
			1390.646,
			1391.415,
			1392.182,
			1394.471,
			1395.993,
			1398.265,
			1401.268,
			1402.017,
			1403.51,
			1404.254,
			1407.23,
			1407.975,
			1409.463,
			1410.95,
			1413.187,
			1414.678,
			1416.167,
			1417.66,
			1418.407,
			1420.644,
			1422.134,
			1423.627,
			1425.858,
			1427.343,
			1429.57,
			1431.05,
			1433.266,
			1434.728,
			1436.944,
			1438.426,
			1440.666,
			1442.16,
			1444.364,
			1446.508,
			1447.892,
			1449.24,
			1451.199,
			1452.466,
			1454.307,
			1454.903,
			1455.492,
			1457.229,
			1458.375,
			1459.528,
			1461.255,
			1462.422,
			1464.201,
			1464.797,
			1465.395,
			1467.194,
			1468.398,
			1470.195,
			1471.387,
			1472.569,
			1473.15,
			1474.302,
			1476.001,
			1477.668,
			1478.757,
			1479.832,
			1481.428,
			1482.494,
			1484.694,
			1485.241,
			1486.879,
			1488.076,
			1489.972,
			1491.228,
			1493.232,
			1494.693,
			1496.143,
			1498.931,
			1499.602,
			1502.854,
			1504.534,
			1505.63,
			1506.716,
			1507.257,
			1508.258,
			1508.744,
			1509.707,
			1510.661,
			1512.086,
			1513.02,
			1514.834,
			1515.282,
			1516.623,
			1517.517,
			1518.912,
			1519.829,
			1521.194,
			1522.56,
			1523.541,
			1525.011,
			1525.992,
			1526.975,
			1527.972,
			1529.496,
			1531.522,
			1532.032,
			1533.574,
			1534.617,
			1536.211,
			1537.293,
			1538.391,
			1540.065,
			1541.201,
			1542.936,
			1543.521,
			1544.111,
			1545.903,
			1547.119,
			1548.971,
			1551.499,
			1552.141,
			1554.091,
			1555.411,
			1557.423,
			1558.783,
			1560.16,
			1562.256,
			1563.672,
			1565.826,
			1567.282,
			1569.506,
			1570.999,
			1572.508,
			1574.794,
			1576.336,
			1579.455,
			1580.241,
			1582.62,
			1586.636,
			1588.265,
			1590.746,
			1592.409,
			1594.117,
			1594.978,
			1596.698,
			1597.558,
			1599.336,
			1601.13,
			1603.841,
			1605.668,
			1609.321,
			1610.231,
			1612.961,
			1614.808,
			1616.651,
			1619.45,
			1622.273,
			1624.139,
			1625.99,
			1628.74,
			1630.54,
			1633.221,
			1635.01,
			1636.785,
			1637.669,
			1639.445,
			1642.094,
			1644.726,
			1646.47,
			1648.21,
			1650.757,
			1652.449,
			1654.947,
			1655.772,
			1656.597,
			1659.065,
			1660.703,
			1663.16,
			1664.8,
			1667.265,
			1668.913,
			1669.739,
			1671.393,
			1673.885,
			1675.549,
			1677.217,
			1680.566,
			1681.405,
			1685.618,
			1687.309,
			1688.155,
			1689.853,
			1691.552,
			1694.101,
			1695.799,
			1698.341,
			1700.035,
			1702.563,
			1704.263,
			1706.834,
			1708.565,
			1710.311,
			1711.19,
			1713.846,
			1715.637,
			1718.348,
			1720.189,
			1722.029,
			1724.815,
			1726.689,
			1730.477,
			1731.431,
			1734.314,
			1736.252,
			1739.181,
			1741.149,
			1743.129,
			1746.121,
			1747.125,
			1751.169,
			1753.21,
			1756.293,
			1758.363,
			1761.489,
			1763.586,
			1765.694,
			1766.75,
			1769.916,
			1772.03,
			1774.136,
			1779.493,
			1782.74,
			1783.828,
			1784.918,
			1788.21,
			1790.417,
			1793.751,
			1795.988,
			1798.235,
			1801.625,
			1802.76,
			1803.898,
			1807.327,
			1808.476,
			1809.627,
			1813.093,
			1815.415,
			1818.928,
			1821.262,
			1823.598,
			1824.769,
			1828.271,
			1830.613,
			1832.945,
			1836.455,
			1838.798,
			1843.522,
			1844.71,
			1848.328,
			1850.762,
			1854.492,
			1857.006,
			1859.542,
			1860.815,
			1863.401,
			1864.697,
			1869.958,
			1871.285,
			1873.933,
			1876.626,
			1879.3,
			1883.281,
			1885.928,
			1889.832,
			1892.42,
			1893.707,
			1896.286,
			1898.853,
			1902.709,
			1905.282,
			1909.147,
			1910.438,
			1911.731,
			1915.618,
			1918.208,
			1922.099,
			1924.7,
			1928.615,
			1933.859,
			1935.175,
			1937.812,
			1941.76,
			1944.415,
			1949.751,
			1951.09,
			1955.122,
			1957.822,
			1961.889,
			1964.612,
			1967.342,
			1971.456,
			1974.208,
			1978.348,
			1981.117,
			1985.282,
			1988.066,
			1992.255,
			1995.054,
			1999.264,
			2002.078,
			2003.486,
			2006.305,
			2010.548,
			2013.386,
			2016.232,
			2020.515,
			2023.379,
			2029.127,
			2030.565,
			2034.889,
			2037.778,
			2040.676,
			2045.038,
			2049.422,
			2052.355,
			2053.823,
			2055.294,
			2059.716,
			2062.671,
			2067.115,
			2070.086,
			2074.553,
			2082.026,
			2085.025,
			2089.534,
			2092.547,
			2097.078,
			2100.105,
			2104.657,
			2107.699,
			2112.272,
			2115.329,
			2119.924,
			2122.995,
			2127.61,
			2130.694,
			2132.238,
			2133.784,
			2141.53,
			2146.194,
			2149.31,
			2153.995,
			2157.125,
			2160.26,
			2161.829,
			2164.972,
			2169.696,
			2174.431,
			2177.594,
			2180.761,
			2187.112,
			2188.702,
			2193.479,
			2196.665,
			2201.453,
			2204.651,
			2209.457,
			2214.271,
			2217.486,
			2220.706,
			2222.317,
			2225.543,
			2228.773,
			2233.628,
			2235.248,
			2236.869,
			2240.115,
			2244.992,
			2249.878,
			2256.409,
			2261.317,
			2264.595,
			2269.519,
			2271.162,
			2272.806,
			2277.746,
			2281.044,
			2285.999,
			2292.621,
			2294.279,
			2297.598,
			2302.584,
			2307.58,
			2310.915,
			2314.255,
			2319.272,
			2322.622,
			2329.334,
			2331.015,
			2336.062,
			2339.433,
			2344.496,
			2347.876,
			2351.261,
			2358.042,
			2359.74,
			2364.839,
			2368.243,
			2373.358,
			2376.772,
			2381.901,
			2385.325,
			2388.753,
			2390.469,
			2393.903,
			2395.621,
			2399.062,
			2402.506,
			2407.679,
			2411.132,
			2418.051,
			2419.782,
			2424.983,
			2428.456,
			2437.152,
			2442.381,
			2445.872,
			2447.619,
			2451.115,
			2454.615,
			2456.367,
			2458.119,
			2463.382,
			2466.895,
			2472.172,
			2475.694,
			2480.983,
			2486.281,
			2489.817,
			2493.358,
			2498.675,
			2502.224,
			2507.554,
			2509.333,
			2511.112,
			2516.456,
			2520.021,
			2525.377,
			2528.952,
			2534.32,
			2537.903,
			2539.695,
			2543.282,
			2548.67,
			2552.265,
			2555.864,
			2561.267,
			2563.07,
			2568.483,
			2572.096,
			2573.904,
			2577.521,
			2582.952,
			2586.577,
			2592.019,
			2595.651,
			2602.925,
			2604.745,
			2610.21,
			2613.858,
			2619.335,
			2622.989,
			2626.646,
			2628.475,
			2632.136,
			2637.634,
			2641.302,
			2643.136,
			2646.808,
			2650.482,
			2655.999,
			2659.682,
			2667.057,
			2668.903,
			2674.443,
			2678.14,
			2683.691,
			2687.396,
			2691.102,
			2696.668,
			2698.525,
			2705.957,
			2707.817,
			2709.678,
			2715.263,
			2718.99,
			2724.585,
			2732.055,
			2733.924,
			2739.535,
			2743.279,
			2748.9,
			2752.651,
			2756.404,
			2765.797,
			2771.441,
			2775.206,
			2780.858,
			2784.63,
			2790.291,
			2794.068,
			2799.738,
			2805.414,
			2809.2,
			2814.884,
			2816.78,
			2822.472,
			2830.068,
			2831.969,
			2839.576,
			2841.479,
			2845.287,
			2847.191,
			2851.002,
			2854.815,
			2860.537,
			2864.354,
			2871.995,
			2873.907,
			2879.645,
			2883.473,
			2889.218,
			2893.051,
			2898.805,
			2902.643,
			2904.563,
			2908.405,
			2912.247,
			2914.169,
			2916.092,
			2921.863,
			2925.713,
			2931.491,
			2939.203,
			2941.132,
			2946.922,
			2950.785,
			2954.649,
			2960.448,
			2964.317,
			2970.124,
			2972.061,
			2979.811,
			2983.689,
			2989.51,
			2993.394,
			2999.223,
			3003.111,
			3005.056,
			3008.947,
			3014.788,
			3018.683,
			3022.579,
			3028.428,
			3030.379,
			3032.33,
			3038.185,
			3040.138,
			3042.091,
			3047.954,
			3057.733,
			3061.648,
			3065.565,
			3071.443,
			3073.403,
			3075.364,
			3081.248,
			3085.173,
			3091.063,
			3094.992,
			3098.922,
			3100.888,
			3104.821,
			3106.788,
			3110.723,
			3116.629,
			3120.568,
			3124.508,
			3130.421,
			3134.366,
			3140.284,
			3142.258,
			3144.232,
			3150.157,
			3154.109,
			3160.037,
			3163.992,
			3167.947,
			3169.925,
			3173.881,
			3175.86,
			3177.839,
			3185.758,
			3187.738,
			3193.679,
			3197.643,
			3203.589,
			3207.554,
			3213.505,
			3219.456,
			3223.426,
			3227.396,
			3233.354,
			3237.327,
			3245.275,
			3247.262,
			3253.226,
			3257.203,
			3263.17,
			3267.149,
			3273.12,
			3277.101,
			3279.092,
			3283.074,
			3287.058,
			3289.05,
			3293.035,
			3297.019,
			3302.998,
			3306.984,
			3310.971,
			3316.954,
			3322.938,
			3326.928,
			3330.919,
			3336.906,
			3340.899,
			3346.889,
			3350.883,
			3356.874,
			3360.869,
			3366.864,
			3370.861,
			3376.857,
			3380.856,
			3386.854,
			3390.853,
			3392.853,
			3396.853,
			3400.854,
			3408.856,
			3410.857,
			3420.864,
			3426.87,
			3430.874,
			3436.88,
			3440.886,
			3444.891,
			3450.9,
			3454.908,
			3462.923,
			3464.926,
			3470.939,
			3474.948,
			3478.958,
			3480.962,
			3486.978,
			3490.99,
			3495,
			3501.018,
			3505.03,
			3511.049,
			3515.062,
			3521.082,
			3523.089,
			3525.096,
			3531.115,
			3535.128,
			3541.149,
			3545.163,
			3549.177,
			3555.2,
			3561.222,
			3565.239,
			3569.253,
			3575.275,
			3579.291,
			3585.313,
			3593.344,
			3595.352,
			3601.375,
			3605.391,
			3609.407,
			3619.449,
			3625.474,
			3627.483,
			3629.491,
			3635.516,
			3639.531,
			3645.556,
			3649.57,
			3655.573,
			3659.549,
			3661.525,
			3665.45,
			3671.263,
			3675.087,
			3678.869,
			3686.31,
			3688.144,
			3695.378,
			3697.16,
			3702.448,
			3705.923,
			3709.357,
			3714.433,
			3717.768,
			3724.319,
			3725.933,
			3730.713,
			3733.851,
			3738.49,
			3741.537,
			3744.549,
			3746.042,
			3750.492,
			3753.398,
			3757.688,
			3760.503,
			3763.288,
			3767.419,
			3770.156,
			3774.221,
			3775.58,
			3776.938,
			3781.017,
			3783.709,
			3786.39,
			3790.398,
			3793.067,
			3798.42,
			3799.76,
			3803.8,
			3806.498,
			3810.549,
			3813.259,
			3817.328,
			3820.046,
			3824.133,
			3826.86,
			3830.952,
			3833.681,
			3840.526,
			3846.024,
			3847.405,
			3851.565,
			3854.337,
			3858.552,
			3861.4,
			3864.234,
			3868.493,
			3871.328,
			3875.574,
			3876.988,
			3878.4,
			3882.595,
			3885.383,
			3889.552,
			3892.32,
			3895.082,
			3896.461,
			3900.587,
			3903.324,
			3906.053,
			3912.826,
			3919.548,
			3923.564,
			3926.242,
			3930.252,
			3932.925,
			3936.934,
			3939.604,
			3940.941,
			3942.277,
			3947.623,
			3948.96,
			3952.981,
			3957.015,
			3958.362,
			3959.711,
			3962.414,
			3965.123,
			3969.198,
			3970.562,
			3973.294,
			3976.035,
			3980.159,
			3982.918,
			3987.046,
			3988.434,
			3989.823,
			3994.002,
			3996.798,
			4001.004,
			4003.816,
			4008.042,
			4010.868,
			4012.282,
			4015.117,
			4019.382,
			4022.235,
			4025.096,
			4027.965,
			4032.283,
			4036.616,
			4039.514,
			4043.873,
			4046.787,
			4049.709,
			4054.103,
			4057.039,
			4062.929,
			4064.406,
			4068.845,
			4071.812,
			4076.274,
			4079.257,
			4083.744,
			4086.746,
			4089.755,
			4091.265,
			4094.289,
			4095.805,
			4098.844,
			4101.889,
			4106.467,
			4107.993,
			4109.521,
			4115.631,
			4117.16,
			4121.745,
			4124.79,
			4129.317,
			4132.294,
			4135.234,
			4141.002,
			4142.42,
			4148,
			4149.37,
			4153.425,
			4156.081,
			4158.7,
			4159.995,
			4162.557,
			4166.332,
			4170.028,
			4172.449,
			4174.835,
			4180.663,
			4185.138,
			4186.241,
			4189.517,
			4191.651,
			4194.794,
			4196.873,
			4199.962,
			4203.082,
			4205.171,
			4208.368,
			4210.514,
			4212.682,
			4214.859,
			4218.136,
			4220.329,
			4223.611,
			4226.892,
			4229.076,
			4231.251,
			4234.501,
			4236.649,
			4239.84,
			4241.938,
			4245.061,
			4247.119,
			4250.183,
			4252.213,
			4254.243,
			4255.256,
			4258.308,
			4260.363,
			4262.427,
			4265.553,
			4267.649,
			4270.818,
			4272.938,
			4276.122,
			4277.183,
			4278.242,
			4281.396,
			4283.485,
			4285.57,
			4288.676,
			4290.725,
			4294.76,
			4295.763,
			4298.741,
			4299.721,
			4300.697,
			4303.6,
			4305.521,
			4308.376,
			4310.276,
			4311.225,
			4313.116,
			4315.962,
			4317.868,
			4319.78,
			4322.662,
			4324.593,
			4329.463,
			4332.411,
			4334.386,
			4337.35,
			4339.329,
			4341.3,
			4344.24,
			4345.212,
			4346.182,
			4349.046,
			4349.989,
			4350.925,
			4353.686,
			4355.488,
			4358.155,
			4361.622,
			4362.481,
			4365.037,
			4366.733,
			4368.43,
			4370.973,
			4372.669,
			4375.214,
			4376.062,
			4376.911,
			4379.455,
			4381.149,
			4383.689,
			4385.378,
			4387.064,
			4387.907,
			4390.435,
			4392.116,
			4396.319,
			4397.999,
			4399.68,
			4402.201,
			4403.879,
			4406.404,
			4408.085,
			4410.614,
			4412.303,
			4414.843,
			4416.544,
			4419.099,
			4420.813,
			4423.39,
			4427.713,
			4429.452,
			4432.088,
			4433.853,
			4434.736,
			4436.537,
			4438.345,
			4441.083,
			4442.939,
			4444.796,
			4446.683,
			4447.633,
			4451.432,
			4452.384,
			4455.235,
			4457.141,
			4459.042,
			4461.889,
			4463.778,
			4467.569,
			4468.521,
			4472.33,
			4473.286,
			4476.149,
			4478.063,
			4479.977,
			4480.933,
			4482.834,
			4484.727,
			4485.673,
			4488.487,
			4490.353,
			4492.216,
			4494.962,
			4496.797,
			4500.437,
			4501.349,
			4504.083,
			4505.91,
			4508.667,
			4512.378,
			4513.313,
			4515.191,
			4517.082,
			4520.904,
			4521.868,
			4526.731,
			4529.685,
			4533.665,
			4534.667,
			4536.682,
			4539.724,
			4541.766,
			4544.85,
			4546.901,
			4551.07,
			4552.133,
			4555.27,
			4557.352,
			4560.521,
			4562.647,
			4564.785,
			4568.01,
			4569.091,
			4570.174,
			4573.439,
			4574.532,
			4575.628,
			4578.931,
			4582.255,
			4584.483,
			4588.966,
			4590.092,
			4593.485,
			4595.76,
			4598.044,
			4601.49,
			4603.799,
			4608.449,
			4609.618,
			4613.141,
			4615.501,
			4619.061,
			4623.84,
			4625.041,
			4627.45,
			4628.658,
			4631.083,
			4634.736,
			4635.958,
			4639.634,
			4643.294,
			4645.708,
			4650.439,
			4651.6,
			4655.029,
			4657.29,
			4659.477,
			4662.718,
			4664.827,
			4668.931,
			4669.932,
			4673.847,
			4674.821,
			4677.651,
			4679.514,
			4682.29,
			4684.138,
			4686.918,
			4689.698,
			4691.545,
			4693.384,
			4696.129,
			4697.937,
			4700.619,
			4702.377,
			4704.104,
			4704.964,
			4706.657,
			4708.316,
			4710.761,
			4713.157,
			4714.733,
			4715.517,
			4716.298,
			4719.405,
			4720.181,
			4722.513,
			4724.07,
			4726.421,
			4727.996,
			4730.362,
			4731.959,
			4732.757,
			4734.354,
			4735.957,
			4738.369,
			4739.974,
			4742.399,
			4743.21,
			4744.019,
			4746.443,
			4748.058,
			4750.47,
			4753.669,
			4754.465,
			4756.051,
			4758.422,
			4760.78,
			4762.345,
			4763.904,
			4765.455,
			4767.767,
			4769.298,
			4770.82,
			4771.579,
			4773.097,
			4773.858,
			4775.374,
			4776.884,
			4779.158,
			4780.676,
			4783.708,
			4784.48,
			4786.79,
			4788.328,
			4790.66,
			4792.216,
			4794.555,
			4796.122,
			4798.471,
			4800.035,
			4800.813,
			4802.366,
			4803.917,
			4806.236,
			4807.771,
			4810.817,
			4811.566,
			4813.801,
			4815.281,
			4816.74,
			4820.352,
			4822.492,
			4823.908,
			4826.019,
			4827.412,
			4829.488,
			4830.868,
			4832.923,
			4834.286,
			4834.967,
			4836.328,
			4838.369,
			4839.727,
			4841.084,
			4843.137,
			4844.507,
			4847.236,
			4847.921,
			4849.962,
			4851.326,
			4853.388,
			4854.756,
			4856.114,
			4858.178,
			4858.872,
			4859.568,
			4862.387,
			4863.102,
			4865.274,
			4866.739,
			4868.966,
			4870.472,
			4872.756,
			4874.299,
			4875.077,
			4876.644,
			4878.223,
			4880.621,
			4882.237,
			4884.685,
			4885.507,
			4886.332,
			4888.829,
			4890.508,
			4893.051,
			4894.763,
			4897.358,
			4899.106,
			4899.985,
			4901.755,
			4903.54,
			4904.438,
			4905.338,
			4908.064,
			4909.901,
			4912.687,
			4916.47,
			4917.424,
			4920.304,
			4922.241,
			4924.193,
			4929.126,
			4932.117,
			4934.127,
			4937.169,
			4938.189,
			4939.211,
			4942.319,
			4944.388,
			4947.522,
			4949.609,
			4950.684,
			4952.809,
			4956.011,
			4958.137,
			4960.306,
			4964.612,
			4965.673,
			4967.846,
			4971.091,
			4974.354,
			4976.538,
			4978.724,
			4982.012,
			4984.214,
			4987.514,
			4988.625,
			4989.738,
			4993.091,
			4995.357,
			4998.75,
			5001.026,
			5003.316,
			5004.464,
			5006.767,
			5007.92,
			5010.235,
			5013.732,
			5016.078,
			5018.438,
			5021.997,
			5024.386,
			5027.985,
			5029.188,
			5030.394,
			5034.025,
			5036.463,
			5040.139,
			5045.073,
			5046.312,
			5048.796,
			5051.279,
			5056.256,
			5057.512,
			5061.288,
			5063.812,
			5067.631,
			5072.731,
			5074.014,
			5076.586,
			5077.877,
			5080.464,
			5083.064,
			5086.98,
			5089.573,
			5093.534,
			5094.854,
			5096.174,
			5100.145,
			5102.798,
			5109.427,
			5113.423,
			5116.089,
			5117.425,
			5118.763,
			5122.784,
			5126.824,
			5129.526,
			5132.229,
			5136.296,
			5141.747,
			5143.113,
			5147.223,
			5149.972,
			5152.72,
			5156.853,
			5159.619,
			5163.743,
			5166.499,
			5170.608,
			5173.314,
			5177.309,
			5179.927,
			5183.781,
			5187.549,
			5190.006,
			5193.616,
			5195.972,
			5198.291,
			5201.689,
			5203.904,
			5207.148,
			5208.209,
			5209.262,
			5211.339,
			5212.364,
			5214.386,
			5216.375,
			5219.303,
			5221.227,
			5224.064,
			5225.916,
			5228.664,
			5230.463,
			5233.101,
			5234.826,
			5237.39,
			5239.08,
			5239.924,
			5241.614,
			5244.161,
			5245.866,
			5247.582,
			5250.175,
			5251.914,
			5254.538,
			5255.414,
			5256.292,
			5258.928,
			5260.685,
			5263.308,
			5266.775,
			5269.341,
			5271.038,
			5273.562,
			5274.399,
			5275.235,
			5277.738,
			5279.398,
			5281.888,
			5283.541,
			5285.189,
			5286.014,
			5288.485,
			5290.124,
			5291.761,
			5294.204,
			5295.823,
			5299.056,
			5299.86,
			5302.267,
			5303.868,
			5306.258,
			5307.857,
			5310.252,
			5311.842,
			5312.639,
			5314.235,
			5316.616,
			5317.407,
			5319.79,
			5322.945,
			5323.739,
			5325.323,
			5327.678,
			5330.051,
			5331.642,
			5333.206,
			5335.621,
			5337.234,
			5339.677,
			5340.507,
			5341.338,
			5343.848,
			5345.553,
			5348.159,
			5349.915,
			5351.693,
			5352.586,
			5355.283,
			5357.084,
			5358.888,
			5359.79,
			5361.589,
			5363.377,
			5366.039,
			5367.753,
			5369.457,
			5371.15,
			5371.982,
			5373.641,
			5374.469,
			5376.123,
			5377.77,
			5380.241,
			5381.894,
			5385.208,
			5386.036,
			5388.527,
			5390.188,
			5392.678,
			5394.336,
			5396.811,
			5398.454,
			5400.894,
			5402.5,
			5404.871,
			5406.419,
			5408.674,
			5410.142,
			5412.26,
			5413.626,
			5415.613,
			5416.892,
			5418.138,
			5419.941,
			5421.104,
			5423.329,
			5423.865,
			5425.44,
			5425.957,
			5426.469,
			5427.98,
			5428.972,
			5430.446,
			5431.429,
			5432.407,
			5432.894,
			5434.35,
			5435.316,
			5436.284,
			5437.725,
			5438.681,
			5440.115,
			5440.594,
			5441.072,
			5442.498,
			5443.442,
			5444.857,
			5445.809,
			5447.267,
			5448.232,
			5448.713,
			5449.674,
			5451.166,
			5452.217,
			5453.26,
			5454.3,
			5455.338,
			5455.885,
			5457.046,
			5458.769,
			5460.488,
			5461.684,
			5462.876,
			5464.659,
			5465.852,
			5468.199,
			5468.786,
			5470.556,
			5471.68,
			5473.357,
			5475.608,
			5476.137,
			5477.183,
			5477.708,
			5479.826,
			5480.361,
			5481.404,
			5482.437,
			5484.002,
			5485.06,
			5486.133,
			5487.771,
			5489.436,
			5490.563,
			5491.747,
			5494.788,
			5497.336,
			5499.976,
			5501.323,
			5503.381,
			5504.773,
			5506.889,
			5508.316,
			5509.034,
			5510.482,
			5512.665,
			5514.146,
			5515.654,
			5517.903,
			5519.425,
			5521.735,
			5523.294,
			5526.457,
			5527.257,
			5529.679,
			5531.312,
			5532.959,
			5535.456,
			5537.138,
			5539.673,
			5541.364,
			5543.924,
			5545.647,
			5548.257,
			5551.782,
			5552.671,
			5557.167,
			5558.988,
			5561.744,
			5563.597,
			5566.401,
			5568.286,
			5571.138,
			5573.057,
			5575.958,
			5577.908,
			5580.857,
			5582.838,
			5584.832,
			5585.833,
			5587.845,
			5588.855,
			5589.869,
			5592.927,
			5594.979,
			5600.161,
			5603.3,
			5607.491,
			5608.544,
			5610.66,
			5611.721,
			5613.85,
			5615.99,
			5619.22,
			5621.385,
			5624.653,
			5625.748,
			5626.845,
			5630.153,
			5632.371,
			5635.717,
			5637.961,
			5641.346,
			5644.753,
			5647.038,
			5650.483,
			5652.794,
			5655.114,
			5657.444,
			5659.784,
			5660.958,
			5663.312,
			5665.676,
			5666.862,
			5670.433,
			5672.826,
			5678.85,
			5681.276,
			5684.933,
			5686.156,
			5687.382,
			5691.072,
			5693.543,
			5697.266,
			5699.759,
			5703.514,
			5706.028,
			5709.815,
			5713.621,
			5716.167,
			5718.722,
			5722.568,
			5725.143,
			5729.02,
			5730.315,
			5731.613,
			5735.517,
			5738.125,
			5740.733,
			5744.657,
			5747.282,
			5751.231,
			5753.873,
			5757.849,
			5760.508,
			5764.509,
			5767.185,
			5769.868,
			5771.212,
			5773.906,
			5777.958,
			5780.666,
			5784.744,
			5787.471,
			5787.721
		],
		[
			0,
			0.027,
			0.09,
			0.151,
			0.198,
			0.278,
			0.34,
			0.4,
			0.462,
			0.525,
			0.585,
			0.65,
			0.709,
			0.743,
			0.773,
			0.849,
			0.898,
			0.959,
			1.067,
			1.084,
			1.177,
			1.208,
			1.283,
			1.332,
			1.394,
			1.455,
			1.516,
			1.61,
			1.642,
			1.704,
			1.719,
			1.765,
			1.829,
			1.934,
			1.95,
			2.044,
			2.076,
			2.151,
			2.198,
			2.263,
			2.322,
			2.384,
			2.448,
			2.478,
			2.51,
			2.572,
			2.632,
			2.696,
			2.756,
			2.819,
			2.88,
			2.912,
			2.944,
			3.023,
			3.067,
			3.13,
			3.192,
			3.254,
			3.315,
			3.346,
			3.378,
			3.439,
			3.502,
			3.562,
			3.578,
			3.625,
			3.688,
			3.748,
			3.795,
			3.874,
			3.936,
			4.014,
			4.06,
			4.123,
			4.184,
			4.245,
			4.308,
			4.338,
			4.369,
			4.431,
			4.447,
			4.494,
			4.556,
			4.618,
			4.664,
			4.681,
			4.772,
			4.804,
			4.882,
			4.929,
			5.051,
			5.112,
			5.175,
			5.206,
			5.239,
			5.315,
			5.361,
			5.425,
			5.484,
			5.546,
			5.609,
			5.672,
			5.749,
			5.795,
			5.863,
			5.919,
			5.981,
			6.044,
			6.075,
			6.106,
			6.168,
			6.23,
			6.291,
			6.355,
			6.416,
			6.478,
			6.54,
			6.616,
			6.665,
			6.726,
			6.788,
			6.834,
			6.849,
			6.913,
			6.944,
			6.973,
			7.053,
			7.097,
			7.161,
			7.222,
			7.285,
			7.377,
			7.409,
			7.486,
			7.531,
			7.601,
			7.657,
			7.719,
			7.78,
			7.81,
			7.843,
			7.906,
			7.965,
			8.027,
			8.09,
			8.152,
			8.246,
			8.276,
			8.354,
			8.401,
			8.463,
			8.524,
			8.573,
			8.648,
			8.678,
			8.709,
			8.834,
			8.896,
			8.959,
			9.023,
			9.112,
			9.145,
			9.207,
			9.223,
			9.267,
			9.331,
			9.394,
			9.439,
			9.456,
			9.548,
			9.577,
			9.664,
			9.702,
			9.766,
			9.826,
			9.889,
			9.981,
			10.011,
			10.074,
			10.136,
			10.198,
			10.261,
			10.322,
			10.385,
			10.415,
			10.447,
			10.523,
			10.575,
			10.632,
			10.695,
			10.757,
			10.819,
			10.848,
			10.881,
			10.943,
			11.006,
			11.069,
			11.127,
			11.191,
			11.253,
			11.285,
			11.315,
			11.391,
			11.44,
			11.5,
			11.562,
			11.624,
			11.748,
			11.811,
			11.873,
			11.935,
			11.995,
			12.059,
			12.119,
			12.152,
			12.181,
			12.261,
			12.306,
			12.369,
			12.477,
			12.493,
			12.556,
			12.585,
			12.616,
			12.68,
			12.742,
			12.804,
			12.864,
			12.928,
			12.99,
			13.049,
			13.127,
			13.175,
			13.238,
			13.298,
			13.345,
			13.456,
			13.485,
			13.609,
			13.669,
			13.732,
			13.795,
			13.858,
			13.889,
			13.92,
			13.981,
			13.995,
			14.044,
			14.107,
			14.212,
			14.229,
			14.291,
			14.322,
			14.355,
			14.432,
			14.476,
			14.54,
			14.601,
			14.662,
			14.757,
			14.789,
			14.865,
			14.912,
			14.973,
			15.034,
			15.082,
			15.098,
			15.16,
			15.195,
			15.227,
			15.344,
			15.407,
			15.469,
			15.53,
			15.593,
			15.625,
			15.656,
			15.734,
			15.781,
			15.845,
			15.905,
			15.95,
			16.027,
			16.089,
			16.168,
			16.277,
			16.336,
			16.398,
			16.461,
			16.493,
			16.523,
			16.602,
			16.648,
			16.71,
			16.773,
			16.833,
			16.927,
			16.956,
			17.036,
			17.081,
			17.144,
			17.206,
			17.269,
			17.361,
			17.391,
			17.467,
			17.515,
			17.577,
			17.64,
			17.702,
			17.796,
			17.826,
			17.889,
			17.913,
			17.949,
			18.026,
			18.073,
			18.138,
			18.243,
			18.354,
			18.385,
			18.463,
			18.508,
			18.573,
			18.633,
			18.695,
			18.756,
			18.818,
			18.879,
			18.943,
			19.006,
			19.067,
			19.128,
			19.191,
			19.223,
			19.25,
			19.33,
			19.376,
			19.439,
			19.5,
			19.562,
			19.624,
			19.655,
			19.685,
			19.747,
			19.81,
			19.872,
			19.935,
			19.995,
			20.059,
			20.118,
			20.197,
			20.245,
			20.307,
			20.368,
			20.431,
			20.492,
			20.524,
			20.555,
			20.631,
			20.678,
			20.741,
			20.801,
			20.863,
			20.927,
			20.957,
			20.99,
			21.066,
			21.111,
			21.174,
			21.238,
			21.299,
			21.361,
			21.422,
			21.484,
			21.545,
			21.61,
			21.671,
			21.732,
			21.826,
			21.856,
			21.934,
			21.981,
			22.048,
			22.105,
			22.166,
			22.23,
			22.29,
			22.354,
			22.414,
			22.476,
			22.539,
			22.584,
			22.601,
			22.663,
			22.725,
			22.8,
			22.846,
			22.911,
			22.973,
			23.035,
			23.096,
			23.16,
			23.234,
			23.283,
			23.344,
			23.407,
			23.468,
			23.53,
			23.562,
			23.593,
			23.669,
			23.716,
			23.779,
			23.841,
			23.902,
			23.965,
			23.995,
			24.027,
			24.089,
			24.15,
			24.214,
			24.275,
			24.338,
			24.398,
			24.43,
			24.462,
			24.539,
			24.585,
			24.646,
			24.755,
			24.772,
			24.832,
			24.865,
			24.957,
			24.973,
			25.02,
			25.083,
			25.144,
			25.207,
			25.267,
			25.299,
			25.328,
			25.407,
			25.452,
			25.516,
			25.577,
			25.639,
			25.701,
			25.762,
			25.841,
			25.885,
			25.951,
			26.01,
			26.074,
			26.136,
			26.197,
			26.276,
			26.323,
			26.382,
			26.445,
			26.49,
			26.569,
			26.601,
			26.63,
			26.709,
			26.757,
			26.818,
			26.879,
			26.943,
			27.002,
			27.034,
			27.066,
			27.127,
			27.19,
			27.25,
			27.315,
			27.36,
			27.375,
			27.438,
			27.5,
			27.577,
			27.625,
			27.685,
			27.748,
			27.809,
			27.902,
			27.934,
			27.995,
			28.059,
			28.118,
			28.18,
			28.243,
			28.306,
			28.366,
			28.446,
			28.492,
			28.616,
			28.679,
			28.74,
			28.769,
			28.801,
			28.864,
			28.88,
			28.926,
			28.989,
			29.049,
			29.097,
			29.205,
			29.234,
			29.314,
			29.36,
			29.423,
			29.483,
			29.546,
			29.639,
			29.672,
			29.749,
			29.795,
			29.856,
			29.918,
			29.98,
			30.072,
			30.102,
			30.181,
			30.228,
			30.299,
			30.351,
			30.416,
			30.476,
			30.539,
			30.601,
			30.63,
			30.663,
			30.74,
			30.786,
			30.848,
			30.956,
			30.972,
			31.066,
			31.095,
			31.175,
			31.221,
			31.283,
			31.345,
			31.406,
			31.468,
			31.529,
			31.593,
			31.657,
			31.716,
			31.779,
			31.823,
			31.933,
			31.963,
			32.042,
			32.152,
			32.213,
			32.274,
			32.338,
			32.374,
			32.399,
			32.461,
			32.477,
			32.523,
			32.585,
			32.647,
			32.709,
			32.803,
			32.831,
			32.909,
			32.956,
			33.017,
			33.081,
			33.144,
			33.205,
			33.267,
			33.328,
			33.39,
			33.454,
			33.514,
			33.561,
			33.576,
			33.638,
			33.7,
			33.779,
			33.826,
			33.886,
			33.949,
			34.009,
			34.072,
			34.105,
			34.134,
			34.211,
			34.26,
			34.323,
			34.384,
			34.443,
			34.508,
			34.539,
			34.569,
			34.646,
			34.694,
			34.756,
			34.863,
			34.879,
			35.002,
			35.063,
			35.08,
			35.128,
			35.19,
			35.249,
			35.313,
			35.376,
			35.436,
			35.516,
			35.562,
			35.623,
			35.684,
			35.732,
			35.748,
			35.839,
			35.872,
			35.948,
			35.996,
			36.058,
			36.12,
			36.182,
			36.273,
			36.306,
			36.383,
			36.43,
			36.495,
			36.552,
			36.614,
			36.677,
			36.709,
			36.801,
			36.864,
			36.927,
			36.988,
			37.049,
			37.11,
			37.143,
			37.173,
			37.251,
			37.298,
			37.359,
			37.469,
			37.546,
			37.577,
			37.606,
			37.686,
			37.73,
			37.797,
			37.856,
			37.916,
			37.978,
			38.011,
			38.041,
			38.104,
			38.118,
			38.164,
			38.228,
			38.289,
			38.35,
			38.413,
			38.445,
			38.476,
			38.558,
			38.601,
			38.661,
			38.725,
			38.784,
			38.878,
			38.911,
			38.972,
			39.034,
			39.097,
			39.159,
			39.204,
			39.221,
			39.282,
			39.313,
			39.406,
			39.421,
			39.468,
			39.531,
			39.59,
			39.652,
			39.716,
			39.776,
			39.839,
			39.855,
			39.901,
			39.965,
			40.026,
			40.088,
			40.151,
			40.181,
			40.212,
			40.29,
			40.335,
			40.399,
			40.461,
			40.523,
			40.619,
			40.645,
			40.707,
			40.772,
			40.834,
			40.94,
			40.956,
			41.019,
			41.081,
			41.159,
			41.203,
			41.266,
			41.328,
			41.389,
			41.452,
			41.515,
			41.592,
			41.639,
			41.7,
			41.762,
			41.823,
			41.886,
			41.917,
			41.948,
			42.025,
			42.074,
			42.136,
			42.197,
			42.243,
			42.351,
			42.383,
			42.444,
			42.506,
			42.567,
			42.63,
			42.693,
			42.756,
			42.784,
			42.816,
			42.892,
			42.941,
			43.002,
			43.065,
			43.128,
			43.25,
			43.313,
			43.373,
			43.435,
			43.497,
			43.561,
			43.622,
			43.684,
			43.762,
			43.809,
			43.872,
			43.933,
			43.996,
			44.057,
			44.088,
			44.118,
			44.242,
			44.306,
			44.365,
			44.429,
			44.49,
			44.522,
			44.552,
			44.614,
			44.678,
			44.747,
			44.8,
			44.847,
			44.955,
			44.986,
			45.065,
			45.109,
			45.174,
			45.234,
			45.295,
			45.389,
			45.422,
			45.482,
			45.498,
			45.543,
			45.608,
			45.669,
			45.715,
			45.73,
			45.792,
			45.856,
			45.933,
			46.041,
			46.101,
			46.164,
			46.228,
			46.258,
			46.29,
			46.35,
			46.411,
			46.476,
			46.583,
			46.599,
			46.661,
			46.724,
			46.806,
			46.848,
			46.911,
			46.973,
			47.032,
			47.126,
			47.157,
			47.219,
			47.282,
			47.343,
			47.407,
			47.467,
			47.561,
			47.591,
			47.667,
			47.716,
			47.777,
			47.84,
			47.9,
			47.962,
			47.994,
			48.024,
			48.088,
			48.101,
			48.148,
			48.211,
			48.273,
			48.322,
			48.428,
			48.461,
			48.536,
			48.584,
			48.707,
			48.768,
			48.83,
			48.865,
			48.894,
			48.956,
			48.969,
			49.017,
			49.078,
			49.141,
			49.205,
			49.264,
			49.329,
			49.406,
			49.45,
			49.513,
			49.575,
			49.622,
			49.7,
			49.73,
			49.763,
			49.823,
			49.886,
			49.948,
			50.008,
			50.073,
			50.132,
			50.164,
			50.196,
			50.274,
			50.321,
			50.383,
			50.443,
			50.491,
			50.567,
			50.599,
			50.628,
			50.69,
			50.754,
			50.816,
			50.878,
			50.939,
			51.032,
			51.063,
			51.141,
			51.186,
			51.25,
			51.31,
			51.359,
			51.373,
			51.434,
			51.498,
			51.56,
			51.576,
			51.623,
			51.685,
			51.746,
			51.809,
			51.901,
			51.931,
			52.01,
			52.055,
			52.119,
			52.179,
			52.241,
			52.305,
			52.336,
			52.428,
			52.444,
			52.49,
			52.551,
			52.614,
			52.677,
			52.769,
			52.799,
			52.878,
			52.925,
			52.996,
			53.048,
			53.11,
			53.219,
			53.295,
			53.359,
			53.434,
			53.481,
			53.545,
			53.606,
			53.668,
			53.761,
			53.793,
			53.868,
			53.917,
			53.978,
			54.088,
			54.101,
			54.195,
			54.227,
			54.288,
			54.305,
			54.351,
			54.412,
			54.473,
			54.537,
			54.629,
			54.66,
			54.74,
			54.783,
			54.848,
			54.908,
			54.968,
			55.032,
			55.066,
			55.094,
			55.158,
			55.173,
			55.217,
			55.282,
			55.342,
			55.406,
			55.498,
			55.528,
			55.606,
			55.652,
			55.716,
			55.777,
			55.823,
			55.902,
			55.932,
			56.025,
			56.085,
			56.148,
			56.211,
			56.272,
			56.333,
			56.365,
			56.396,
			56.474,
			56.522,
			56.583,
			56.643,
			56.691,
			56.707,
			56.769,
			56.801,
			56.829,
			56.908,
			57.018,
			57.078,
			57.14,
			57.202,
			57.234,
			57.263,
			57.327,
			57.389,
			57.451,
			57.514,
			57.56,
			57.575,
			57.636,
			57.666,
			57.699,
			57.775,
			57.823,
			57.886,
			57.948,
			58.01,
			58.074,
			58.102,
			58.133,
			58.194,
			58.258,
			58.318,
			58.381,
			58.428,
			58.443,
			58.506,
			58.534,
			58.566,
			58.644,
			58.692,
			58.756,
			58.815,
			58.878,
			58.938,
			59,
			59.079,
			59.124,
			59.192,
			59.248,
			59.295,
			59.404,
			59.433,
			59.513,
			59.559,
			59.624,
			59.684,
			59.746,
			59.806,
			59.839,
			59.868,
			59.93,
			59.946,
			59.993,
			60.057,
			60.118,
			60.18,
			60.241,
			60.302,
			60.382,
			60.428,
			60.489,
			60.552,
			60.599,
			60.674,
			60.739,
			60.8,
			60.861,
			60.923,
			60.986,
			61.048,
			61.108,
			61.172,
			61.232,
			61.255,
			61.296,
			61.357,
			61.466,
			61.482,
			61.606,
			61.668,
			61.731,
			61.793,
			61.854,
			61.916,
			61.978,
			62.039,
			62.118,
			62.163,
			62.226,
			62.289,
			62.334,
			62.349,
			62.441,
			62.473,
			62.535,
			62.598,
			62.66,
			62.723,
			62.782,
			62.846,
			62.877,
			62.907,
			62.983,
			63.033,
			63.095,
			63.156,
			63.202,
			63.28,
			63.343,
			63.417,
			63.464,
			63.528,
			63.589,
			63.651,
			63.745,
			63.775,
			63.851,
			63.901,
			63.964,
			64.074,
			64.149,
			64.179,
			64.209,
			64.286,
			64.334,
			64.395,
			64.457,
			64.521,
			64.582,
			64.613,
			64.643,
			64.721,
			64.768,
			64.832,
			64.938,
			64.955,
			65.048,
			65.078,
			65.156,
			65.201,
			65.266,
			65.326,
			65.39,
			65.482,
			65.511,
			65.573,
			65.639,
			65.7,
			65.76,
			65.807,
			65.822,
			65.916,
			65.945,
			66.023,
			66.074,
			66.132,
			66.195,
			66.255,
			66.318,
			66.348,
			66.381,
			66.459,
			66.506,
			66.567,
			66.629,
			66.674,
			66.784,
			66.816,
			66.892,
			66.94,
			67.001,
			67.062,
			67.125,
			67.186,
			67.248,
			67.31,
			67.372,
			67.438,
			67.544,
			67.651,
			67.684,
			67.76,
			67.807,
			67.868,
			67.93,
			67.976,
			68.055,
			68.115,
			68.177,
			68.193,
			68.241,
			68.302,
			68.364,
			68.426,
			68.489,
			68.518,
			68.551,
			68.628,
			68.674,
			68.739,
			68.845,
			68.986,
			69.063,
			69.11,
			69.172,
			69.231,
			69.294,
			69.356,
			69.389,
			69.418,
			69.499,
			69.543,
			69.606,
			69.668,
			69.713,
			69.731,
			69.79,
			69.823,
			69.914,
			69.93,
			69.976,
			70.039,
			70.1,
			70.163,
			70.226,
			70.257,
			70.288,
			70.348,
			70.412,
			70.474,
			70.535,
			70.598,
			70.659,
			70.69,
			70.721,
			70.798,
			70.846,
			70.906,
			70.967,
			71.032,
			71.093,
			71.156,
			71.218,
			71.28,
			71.341,
			71.405,
			71.448,
			71.563,
			71.59,
			71.667,
			71.712,
			71.775,
			71.839,
			71.898,
			71.963,
			71.994,
			72.024,
			72.084,
			72.101,
			72.147,
			72.21,
			72.269,
			72.317,
			72.333,
			72.427,
			72.458,
			72.535,
			72.581,
			72.643,
			72.706,
			72.768,
			72.861,
			72.891,
			72.969,
			73.017,
			73.079,
			73.14,
			73.185,
			73.202,
			73.263,
			73.326,
			73.404,
			73.451,
			73.511,
			73.635,
			73.728,
			73.76,
			73.837,
			73.885,
			73.945,
			74.008,
			74.068,
			74.163,
			74.193,
			74.271,
			74.318,
			74.381,
			74.442,
			74.502,
			74.566,
			74.627,
			74.706,
			74.751,
			74.814,
			74.875,
			74.938,
			75,
			75.062,
			75.141,
			75.185,
			75.249,
			75.31,
			75.356,
			75.372,
			75.464,
			75.496,
			75.559,
			75.619,
			75.688,
			75.743,
			75.806,
			75.869,
			75.898,
			75.93,
			76.009,
			76.052,
			76.116,
			76.177,
			76.225,
			76.332,
			76.363,
			76.426,
			76.443,
			76.49,
			76.55,
			76.613,
			76.675,
			76.737,
			76.766,
			76.797,
			76.876,
			76.923,
			76.986,
			77.046,
			77.109,
			77.232,
			77.294,
			77.356,
			77.419,
			77.481,
			77.542,
			77.605,
			77.633,
			77.665,
			77.728,
			77.747,
			77.791,
			77.852,
			77.915,
			77.976,
			78.068,
			78.101,
			78.179,
			78.224,
			78.288,
			78.349,
			78.41,
			78.473,
			78.505,
			78.533,
			78.611,
			78.657,
			78.719,
			78.782,
			78.831,
			78.844,
			78.938,
			78.968,
			79.093,
			79.155,
			79.216,
			79.279,
			79.341,
			79.401,
			79.466,
			79.527,
			79.589,
			79.651,
			79.698,
			79.776,
			79.835,
			79.915,
			80.023,
			80.085,
			80.148,
			80.21,
			80.241,
			80.272,
			80.333,
			80.395,
			80.456,
			80.517,
			80.565,
			80.581,
			80.674,
			80.705,
			80.781,
			80.829,
			80.89,
			80.954,
			81.015,
			81.109,
			81.14,
			81.219,
			81.263,
			81.326,
			81.386,
			81.434,
			81.449,
			81.51,
			81.543,
			81.573,
			81.651,
			81.697,
			81.759,
			81.822,
			81.884,
			81.976,
			82.008,
			82.068,
			82.13,
			82.194,
			82.302,
			82.318,
			82.378,
			82.441,
			82.519,
			82.566,
			82.689,
			82.751,
			82.843,
			82.874,
			82.938,
			83,
			83.063,
			83.123,
			83.184,
			83.279,
			83.309,
			83.384,
			83.434,
			83.496,
			83.556,
			83.605,
			83.683,
			83.711,
			83.743,
			83.806,
			83.823,
			83.866,
			83.935,
			83.993,
			84.052,
			84.148,
			84.177,
			84.256,
			84.301,
			84.365,
			84.427,
			84.489,
			84.581,
			84.613,
			84.672,
			84.735,
			84.797,
			84.907,
			84.923,
			84.983,
			85.013,
			85.045,
			85.123,
			85.167,
			85.232,
			85.293,
			85.356,
			85.448,
			85.479,
			85.557,
			85.602,
			85.666,
			85.728,
			85.773,
			85.883,
			85.913,
			85.996,
			86.038,
			86.1,
			86.161,
			86.223,
			86.287,
			86.349,
			86.425,
			86.473,
			86.533,
			86.597,
			86.643,
			86.718,
			86.783,
			86.843,
			86.859,
			86.906,
			86.967,
			87.029,
			87.093,
			87.152,
			87.184,
			87.216,
			87.295,
			87.341,
			87.401,
			87.465,
			87.527,
			87.589,
			87.651,
			87.712,
			87.728,
			87.774,
			87.836,
			87.898,
			87.96,
			88.023,
			88.062,
			88.085,
			88.161,
			88.209,
			88.272,
			88.378,
			88.393,
			88.457,
			88.518,
			88.596,
			88.643,
			88.702,
			88.767,
			88.828,
			88.889,
			88.923,
			88.951,
			89.014,
			89.03,
			89.077,
			89.14,
			89.2,
			89.261,
			89.356,
			89.386,
			89.465,
			89.512,
			89.572,
			89.634,
			89.696,
			89.758,
			89.822,
			89.882,
			89.898,
			89.944,
			90.008,
			90.069,
			90.13,
			90.223,
			90.253,
			90.332,
			90.379,
			90.439,
			90.563,
			90.626,
			90.689,
			90.752,
			90.812,
			90.873,
			90.937,
			90.998,
			91.061,
			91.091,
			91.123,
			91.201,
			91.246,
			91.309,
			91.371,
			91.418,
			91.526,
			91.558,
			91.619,
			91.682,
			91.743,
			91.805,
			91.866,
			91.93,
			91.959,
			91.993,
			92.069,
			92.115,
			92.181,
			92.239,
			92.302,
			92.363,
			92.425,
			92.486,
			92.502,
			92.548,
			92.61,
			92.672,
			92.736,
			92.798,
			92.829,
			92.859,
			92.938,
			92.982,
			93.049,
			93.106,
			93.168,
			93.23,
			93.262,
			93.293,
			93.356,
			93.371,
			93.416,
			93.478,
			93.541,
			93.604,
			93.697,
			93.727,
			93.806,
			93.851,
			93.914,
			94.023,
			94.1,
			94.161,
			94.224,
			94.245,
			94.285,
			94.348,
			94.41,
			94.472,
			94.533,
			94.564,
			94.595,
			94.673,
			94.719,
			94.783,
			94.89,
			94.907,
			94.967,
			95.03,
			95.107,
			95.154,
			95.215,
			95.278,
			95.34,
			95.401,
			95.432,
			95.463,
			95.526,
			95.589,
			95.649,
			95.711,
			95.757,
			95.774,
			95.834,
			95.866,
			95.898,
			95.976,
			96.022,
			96.084,
			96.145,
			96.207,
			96.27,
			96.306,
			96.332,
			96.394,
			96.457,
			96.518,
			96.626,
			96.643,
			96.705,
			96.765,
			96.844,
			96.89,
			96.952,
			97.012,
			97.076,
			97.168,
			97.198,
			97.262,
			97.278,
			97.324,
			97.386,
			97.448,
			97.494,
			97.572,
			97.602,
			97.633,
			97.711,
			97.756,
			97.881,
			97.945,
			98.006,
			98.038,
			98.067,
			98.13,
			98.193,
			98.255,
			98.317,
			98.378,
			98.471,
			98.502,
			98.582,
			98.625,
			98.69,
			98.751,
			98.813,
			98.874,
			98.938,
			98.999,
			99.06,
			99.123,
			99.185,
			99.245,
			99.338,
			99.372,
			99.448,
			99.494,
			99.556,
			99.618,
			99.681,
			99.744,
			99.774,
			99.805,
			99.867,
			99.883,
			99.929,
			99.989,
			100.051,
			100.114,
			100.176,
			100.239,
			100.316,
			100.363,
			100.431,
			100.49,
			100.549,
			100.611,
			100.641,
			100.673,
			100.749,
			100.797,
			100.86,
			100.919,
			100.983,
			101.043,
			101.076,
			101.107,
			101.185,
			101.23,
			101.293,
			101.401,
			101.478,
			101.54,
			101.603,
			101.619,
			101.663,
			101.728,
			101.789,
			101.852,
			101.913,
			101.943,
			101.973,
			102.05,
			102.098,
			102.16,
			102.223,
			102.269,
			102.379,
			102.408,
			102.469,
			102.531,
			102.596,
			102.655,
			102.718,
			102.781,
			102.813,
			102.844,
			102.919,
			102.967,
			103.029,
			103.138,
			103.152,
			103.214,
			103.276,
			103.356,
			103.399,
			103.464,
			103.523,
			103.586,
			103.648,
			103.681,
			103.71,
			103.789,
			103.834,
			103.898,
			103.96,
			104.006,
			104.019,
			104.113,
			104.145,
			104.208,
			104.223,
			104.269,
			104.331,
			104.393,
			104.455,
			104.517,
			104.552,
			104.579,
			104.64,
			104.659,
			104.704,
			104.766,
			104.826,
			104.874,
			104.983,
			105.013,
			105.09,
			105.138,
			105.198,
			105.262,
			105.324,
			105.385,
			105.447,
			105.509,
			105.572,
			105.635,
			105.696,
			105.743,
			105.823,
			105.881,
			105.959,
			106.005,
			106.067,
			106.13,
			106.191,
			106.283,
			106.316,
			106.377,
			106.393,
			106.441,
			106.5,
			106.564,
			106.625,
			106.689,
			106.719,
			106.75,
			106.827,
			106.874,
			106.935,
			106.997,
			107.061,
			107.123,
			107.154,
			107.182,
			107.246,
			107.307,
			107.369,
			107.431,
			107.495,
			107.556,
			107.585,
			107.618,
			107.696,
			107.743,
			107.805,
			107.866,
			107.913,
			107.927,
			107.99,
			108.051,
			108.129,
			108.177,
			108.239,
			108.3,
			108.36,
			108.455,
			108.484,
			108.564,
			108.61,
			108.676,
			108.78,
			108.795,
			108.857,
			108.89,
			108.98,
			108.998,
			109.043,
			109.107,
			109.168,
			109.23,
			109.291,
			109.354,
			109.431,
			109.479,
			109.54,
			109.65,
			109.758,
			109.849,
			109.911,
			109.973,
			110.038,
			110.098,
			110.16,
			110.192,
			110.223,
			110.299,
			110.347,
			110.409,
			110.469,
			110.531,
			110.594,
			110.656,
			110.743,
			110.781,
			110.86,
			110.906,
			110.967,
			111.027,
			111.091,
			111.151,
			111.214,
			111.292,
			111.339,
			111.4,
			111.51,
			111.523,
			111.648,
			111.711,
			111.772,
			111.835,
			111.895,
			111.96,
			112.021,
			112.083,
			112.161,
			112.207,
			112.269,
			112.332,
			112.378,
			112.394,
			112.455,
			112.486,
			112.517,
			112.593,
			112.642,
			112.763,
			112.828,
			112.919,
			112.951,
			113.012,
			113.028,
			113.076,
			113.136,
			113.198,
			113.261,
			113.322,
			113.356,
			113.384,
			113.463,
			113.508,
			113.572,
			113.632,
			113.695,
			113.788,
			113.818,
			113.896,
			113.943,
			114.006,
			114.067,
			114.114,
			114.129,
			114.191,
			114.222,
			114.254,
			114.331,
			114.377,
			114.5,
			114.561,
			114.623,
			114.656,
			114.689,
			114.749,
			114.811,
			114.877,
			114.936,
			114.997,
			115.06,
			115.121,
			115.198,
			115.246,
			115.307,
			115.369,
			115.431,
			115.494,
			115.524,
			115.556,
			115.632,
			115.68,
			115.742,
			115.801,
			115.866,
			115.927,
			115.989,
			116.067,
			116.113,
			116.177,
			116.239,
			116.285,
			116.3,
			116.361,
			116.424,
			116.486,
			116.548,
			116.609,
			116.611
		]
	],
	'2866_4247_7982': [
		[
			130.814
		],
		[
			0
		],
		[
			0,
			0.764,
			3.505,
			7.628,
			13.148,
			14.532,
			17.305,
			18.694,
			24.265,
			25.662,
			27.061,
			31.267,
			34.079,
			38.31,
			41.138,
			43.972,
			45.391,
			48.235,
			52.512,
			55.372,
			62.545,
			66.866,
			69.754,
			74.121,
			76.995,
			81.345,
			84.245,
			88.602,
			92.973,
			95.897,
			98.829,
			103.239,
			106.189,
			110.624,
			113.589,
			116.56,
			119.536,
			121.026,
			125.506,
			128.499,
			136.007,
			139.02,
			145.063,
			146.577,
			151.129,
			154.17,
			158.744,
			161.801,
			166.395,
			169.464,
			171.001,
			174.079,
			178.706,
			181.798,
			184.896,
			192.663,
			197.339,
			198.9,
			200.463,
			205.159,
			208.296,
			213.012,
			216.162,
			220.897,
			224.06,
			227.227,
			233.578,
			235.169,
			239.945,
			243.131,
			246.322,
			247.918,
			255.918,
			259.126,
			260.731,
			263.944,
			267.162,
			271.994,
			275.22,
			281.686,
			283.306,
			288.174,
			291.426,
			296.315,
			299.58,
			302.851,
			304.488,
			307.765,
			312.689,
			315.978,
			317.624,
			319.272,
			324.221,
			327.525,
			332.492,
			335.808,
			339.128,
			340.79,
			345.779,
			349.11,
			352.442,
			357.448,
			360.79,
			367.488,
			369.165,
			374.207,
			377.575,
			382.636,
			386.016,
			391.095,
			394.486,
			396.182,
			399.578,
			402.977,
			404.678,
			408.084,
			411.493,
			418.323,
			420.033,
			425.167,
			428.595,
			433.745,
			437.183,
			440.625,
			445.796,
			449.249,
			454.436,
			456.168,
			457.901,
			463.103,
			466.577,
			471.793,
			475.271,
			478.745,
			480.477,
			483.926,
			485.642,
			489.049,
			494.089,
			497.399,
			500.67,
			505.504,
			508.677,
			514.905,
			516.437,
			520.98,
			523.962,
			526.909,
			531.257,
			534.106,
			539.688,
			541.059,
			545.115,
			547.769,
			551.678,
			554.235,
			556.753,
			560.459,
			562.884,
			564.082,
			566.453,
			567.625,
			569.944,
			572.229,
			575.584,
			577.775,
			580.99,
			582.042,
			583.085,
			586.159,
			588.162,
			591.097,
			593.023,
			594.897,
			595.821,
			597.646,
			600.331,
			602.087,
			604.673,
			606.366,
			608.032,
			610.502,
			612.112,
			613.713,
			614.513,
			616.955,
			618.606,
			620.259,
			622.875,
			624.637,
			627.246,
			628.107,
			631.561,
			633.29,
			635.895,
			637.638,
			640.293,
			642.069,
			642.957,
			644.757,
			647.475,
			649.333,
			651.288,
			656.233,
			661.177,
			663.053,
			665.871,
			667.769,
			670.607,
			672.509,
			675.369,
			676.32,
			677.271,
			680.16,
			682.088,
			684.988,
			688.913,
			689.889,
			694.659,
			696.512,
			699.137,
			700.889,
			703.462,
			705.148,
			708.501,
			709.348,
			711.83,
			713.472,
			715.134,
			717.601,
			719.237,
			721.754,
			722.605,
			723.442,
			725.974,
			727.671,
			730.21,
			731.989,
			734.648,
			736.438,
			739.113,
			741.628,
			743.275,
			744.917,
			747.45,
			749.134,
			752.533,
			753.387,
			755.94,
			757.611,
			760.109,
			761.848,
			763.59,
			767.056,
			767.92,
			771.329,
			772.152,
			774.639,
			777.988,
			778.831,
			780.528,
			783,
			784.636,
			785.459,
			787.095,
			788.702,
			791.135,
			792.776,
			795.271,
			796.949,
			799.553,
			801.314,
			803.991,
			805.821,
			808.589,
			811.37,
			813.238,
			815.141,
			816.103,
			818.036,
			819.979,
			821.931,
			824.87,
			828.771,
			829.734,
			832.679,
			834.638,
			836.604,
			839.601,
			841.607,
			844.639,
			846.654,
			851.719,
			854.729,
			856.751,
			859.803,
			861.87,
			862.907,
			864.979,
			867.049,
			870.173,
			872.265,
			875.419,
			876.474,
			877.532,
			880.727,
			881.804,
			882.884,
			886.135,
			888.314,
			890.502,
			893.798,
			896.007,
			899.388,
			900.529,
			901.671,
			905.147,
			907.496,
			911.031,
			913.386,
			916.93,
			919.282,
			920.461,
			922.827,
			926.428,
			928.841,
			931.266,
			934.922,
			937.371,
			941.061,
			943.54,
			947.273,
			949.77,
			953.523,
			956.023,
			959.778,
			962.281,
			966.059,
			969.863,
			971.136,
			974.961,
			977.5,
			981.353,
			983.951,
			987.878,
			991.793,
			994.389,
			996.992,
			1000.91,
			1003.534,
			1008.805,
			1010.127,
			1014.109,
			1016.775,
			1020.791,
			1023.48,
			1026.178,
			1027.53,
			1031.599,
			1034.321,
			1038.419,
			1041.16,
			1043.907,
			1048.042,
			1050.808,
			1053.582,
			1056.363,
			1057.755,
			1060.546,
			1064.742,
			1067.546,
			1071.765,
			1074.586,
			1080.247,
			1081.666,
			1085.935,
			1093.084,
			1095.955,
			1100.272,
			1103.157,
			1104.602,
			1107.495,
			1110.395,
			1114.754,
			1117.667,
			1122.047,
			1124.972,
			1129.368,
			1130.836,
			1132.305,
			1136.717,
			1139.664,
			1144.094,
			1147.055,
			1150.021,
			1154.483,
			1157.466,
			1163.449,
			1164.949,
			1169.457,
			1172.47,
			1177,
			1180.027,
			1183.06,
			1184.578,
			1189.141,
			1192.19,
			1195.243,
			1199.822,
			1202.863,
			1207.381,
			1210.352,
			1214.736,
			1216.178,
			1217.61,
			1221.847,
			1224.62,
			1227.355,
			1231.382,
			1232.705,
			1234.018,
			1239.177,
			1240.443,
			1244.187,
			1246.636,
			1249.052,
			1250.246,
			1252.612,
			1256.103,
			1259.516,
			1261.746,
			1263.936,
			1267.153,
			1269.25,
			1273.333,
			1274.331,
			1277.271,
			1279.185,
			1281.988,
			1283.812,
			1285.602,
			1286.484,
			1288.222,
			1290.779,
			1293.258,
			1294.881,
			1296.487,
			1298.873,
			1300.451,
			1302.026,
			1303.588,
			1304.365,
			1305.916,
			1308.232,
			1309.76,
			1313.569,
			1316.601,
			1317.356,
			1319.659,
			1321.187,
			1323.505,
			1325.075,
			1327.422,
			1329.861,
			1331.486,
			1333.136,
			1333.984,
			1335.678,
			1337.37,
			1339.956,
			1340.817,
			1341.677,
			1344.23,
			1345.935,
			1348.482,
			1350.142,
			1352.634,
			1354.246,
			1355.87,
			1359.122,
			1359.932,
			1362.381,
			1364.026,
			1366.509,
			1368.173,
			1370.688,
			1372.341,
			1374.801,
			1377.21,
			1378.793,
			1380.359,
			1382.68,
			1384.251,
			1386.599,
			1387.379,
			1388.169,
			1390.582,
			1392.182,
			1394.605,
			1396.219,
			1398.623,
			1400.206,
			1400.994,
			1401.781,
			1404.886,
			1405.653,
			1407.943,
			1409.466,
			1411.745,
			1413.267,
			1414.816,
			1415.589,
			1417.134,
			1419.48,
			1421.057,
			1423.426,
			1425.019,
			1428.201,
			1428.999,
			1431.388,
			1432.982,
			1435.371,
			1436.962,
			1438.551,
			1439.336,
			1441.691,
			1443.259,
			1444.804,
			1445.574,
			1447.115,
			1448.653,
			1450.182,
			1452.474,
			1454.006,
			1456.307,
			1457.85,
			1458.624,
			1460.174,
			1461.731,
			1464.083,
			1465.655,
			1468.032,
			1469.624,
			1472.026,
			1476.072,
			1477.703,
			1480.164,
			1481.813,
			1484.291,
			1485.943,
			1488.413,
			1490.046,
			1492.457,
			1493.257,
			1494.048,
			1496.365,
			1497.12,
			1497.866,
			1500.044,
			1501.448,
			1502.818,
			1504.815,
			1506.108,
			1507.985,
			1508.593,
			1509.209,
			1510.978,
			1511.548,
			1512.11,
			1513.751,
			1514.827,
			1516.383,
			1518.38,
			1518.87,
			1520.306,
			1521.241,
			1522.167,
			1523.553,
			1524.471,
			1526.275,
			1526.724,
			1528.07,
			1528.986,
			1530.431,
			1531.39,
			1532.833,
			1533.803,
			1534.36,
			1535.493,
			1537.196,
			1537.765,
			1539.526,
			1541.996,
			1542.615,
			1543.858,
			1545.641,
			1546.832,
			1548.628,
			1549.761,
			1551.43,
			1552.548,
			1554.219,
			1555.266,
			1556.845,
			1557.906,
			1559.506,
			1560.569,
			1561.641,
			1562.18,
			1563.268,
			1563.816,
			1564.958,
			1566.707,
			1567.889,
			1569.087,
			1570.989,
			1572.277,
			1574.916,
			1575.595,
			1577.65,
			1579.035,
			1580.433,
			1582.555,
			1583.974,
			1586.852,
			1587.573,
			1589.796,
			1591.275,
			1593.51,
			1595.009,
			1597.264,
			1598.801,
			1601.096,
			1603.431,
			1605.003,
			1606.598,
			1608.994,
			1610.594,
			1613.796,
			1614.594,
			1618.611,
			1620.23,
			1622.653,
			1624.276,
			1627.5,
			1628.307,
			1630.737,
			1632.359,
			1634.803,
			1636.463,
			1638.121,
			1638.95,
			1640.65,
			1641.506,
			1643.217,
			1645.812,
			1647.556,
			1649.297,
			1651.916,
			1653.657,
			1656.292,
			1657.172,
			1658.052,
			1660.717,
			1662.511,
			1665.196,
			1666.978,
			1669.644,
			1671.402,
			1672.28,
			1673.157,
			1676.679,
			1677.561,
			1680.21,
			1681.989,
			1684.648,
			1686.418,
			1688.184,
			1689.066,
			1690.812,
			1691.68,
			1693.413,
			1695.139,
			1697.693,
			1699.395,
			1702.787,
			1703.635,
			1706.183,
			1707.882,
			1710.43,
			1713.81,
			1714.657,
			1717.191,
			1718.882,
			1721.427,
			1723.128,
			1724.836,
			1727.42,
			1729.156,
			1730.905,
			1733.556,
			1735.339,
			1738.036,
			1739.849,
			1742.591,
			1744.434,
			1747.223,
			1748.158,
			1749.097,
			1751.934,
			1753.84,
			1756.725,
			1758.665,
			1761.599,
			1763.573,
			1766.558,
			1768.566,
			1769.574,
			1771.602,
			1773.641,
			1776.724,
			1778.794,
			1781.921,
			1784.021,
			1787.179,
			1789.286,
			1792.464,
			1794.578,
			1796.722,
			1799.958,
			1802.147,
			1805.403,
			1807.598,
			1810.906,
			1813.125,
			1815.354,
			1816.472,
			1818.719,
			1820.975,
			1822.108,
			1825.525,
			1827.819,
			1833.605,
			1835.939,
			1839.458,
			1840.636,
			1841.816,
			1845.366,
			1847.744,
			1851.325,
			1853.72,
			1856.124,
			1859.733,
			1860.94,
			1862.147,
			1866.994,
			1868.198,
			1871.821,
			1874.231,
			1877.859,
			1880.284,
			1883.949,
			1886.409,
			1887.651,
			1890.161,
			1892.689,
			1896.546,
			1899.148,
			1904.415,
			1905.747,
			1909.783,
			1912.497,
			1916.599,
			1919.334,
			1923.425,
			1926.123,
			1927.472,
			1930.125,
			1932.779,
			1934.092,
			1935.406,
			1939.319,
			1941.961,
			1945.885,
			1948.506,
			1952.444,
			1956.396,
			1959.038,
			1961.687,
			1965.672,
			1968.338,
			1975.04,
			1979.087,
			1981.795,
			1985.87,
			1988.594,
			1992.693,
			1995.432,
			1996.805,
			1999.555,
			2003.69,
			2006.452,
			2009.218,
			2013.378,
			2016.14,
			2021.723,
			2023.123,
			2027.331,
			2030.144,
			2032.963,
			2037.2,
			2040.031,
			2044.287,
			2045.707,
			2047.128,
			2051.403,
			2054.261,
			2058.566,
			2064.334,
			2065.781,
			2068.678,
			2073.03,
			2080.307,
			2083.231,
			2087.636,
			2090.583,
			2095.018,
			2097.984,
			2102.444,
			2105.425,
			2109.906,
			2112.902,
			2115.904,
			2120.416,
			2121.923,
			2123.431,
			2129.479,
			2130.994,
			2135.546,
			2138.589,
			2143.164,
			2149.282,
			2150.816,
			2153.887,
			2158.504,
			2161.589,
			2166.226,
			2169.324,
			2173.982,
			2175.537,
			2177.094,
			2181.769,
			2184.893,
			2189.59,
			2197.443,
			2202.171,
			2205.33,
			2208.493,
			2213.248,
			2216.423,
			2219.604,
			2224.38,
			2227.566,
			2230.757,
			2232.353,
			2237.151,
			2240.355,
			2243.564,
			2248.387,
			2251.608,
			2256.446,
			2259.676,
			2264.528,
			2267.769,
			2272.639,
			2275.892,
			2280.779,
			2284.042,
			2287.309,
			2288.945,
			2293.856,
			2297.136,
			2300.421,
			2307.001,
			2308.648,
			2315.249,
			2316.902,
			2321.867,
			2325.182,
			2328.501,
			2333.487,
			2336.817,
			2343.488,
			2345.159,
			2350.177,
			2353.526,
			2358.558,
			2365.281,
			2366.964,
			2370.334,
			2373.708,
			2375.396,
			2380.467,
			2383.854,
			2387.244,
			2392.337,
			2395.737,
			2400.844,
			2404.254,
			2409.377,
			2412.796,
			2417.933,
			2421.362,
			2426.514,
			2429.953,
			2431.675,
			2433.397,
			2440.297,
			2447.212,
			2450.675,
			2455.877,
			2462.826,
			2464.565,
			2468.046,
			2469.788,
			2473.275,
			2476.766,
			2482.008,
			2485.507,
			2490.765,
			2492.519,
			2494.274,
			2499.544,
			2503.063,
			2508.347,
			2511.874,
			2517.172,
			2522.476,
			2526.018,
			2529.563,
			2531.337,
			2534.887,
			2538.441,
			2541.999,
			2545.56,
			2547.341,
			2550.908,
			2556.264,
			2561.627,
			2565.207,
			2568.79,
			2574.17,
			2577.761,
			2584.951,
			2586.75,
			2592.151,
			2595.757,
			2601.17,
			2604.782,
			2610.209,
			2613.83,
			2619.268,
			2624.713,
			2628.346,
			2631.982,
			2637.443,
			2641.087,
			2644.734,
			2650.211,
			2655.695,
			2659.354,
			2663.015,
			2668.513,
			2672.181,
			2677.688,
			2681.362,
			2686.872,
			2690.547,
			2696.068,
			2703.448,
			2705.296,
			2708.996,
			2712.699,
			2718.258,
			2720.113,
			2723.823,
			2727.536,
			2733.109,
			2736.829,
			2742.413,
			2744.275,
			2746.138,
			2751.731,
			2755.463,
			2761.066,
			2768.544,
			2770.416,
			2774.159,
			2776.032,
			2777.906,
			2785.406,
			2792.918,
			2796.677,
			2802.318,
			2809.803,
			2811.659,
			2815.346,
			2817.175,
			2820.801,
			2824.387,
			2829.691,
			2833.18,
			2840.039,
			2841.729,
			2846.741,
			2854.901,
			2858.098,
			2862.824,
			2865.931,
			2869.001,
			2873.542,
			2876.524,
			2880.93,
			2885.253,
			2888.087,
			2890.88,
			2893.635,
			2894.998,
			2899.029,
			2901.67,
			2908.117,
			2910.638,
			2914.352,
			2915.572,
			2916.783,
			2920.364,
			2922.708,
			2926.18,
			2928.466,
			2931.829,
			2934.03,
			2937.268,
			2940.423,
			2942.481,
			2944.502,
			2947.464,
			2949.393,
			2952.219,
			2953.143,
			2954.058,
			2956.754,
			2958.51,
			2960.231,
			2962.747,
			2964.391,
			2966.772,
			2967.548,
			2968.315,
			2970.565,
			2972.021,
			2974.14,
			2975.518,
			2977.522,
			2978.823,
			2980.74,
			2982.598,
			2983.809,
			2985.013,
			2986.813,
			2988.033,
			2989.858,
			2990.466,
			2991.074,
			2993.052,
			2994.457,
			2996.566,
			2998.281,
			3000.025,
			3003.575,
			3004.474,
			3006.873,
			3007.567,
			3008.262,
			3010.364,
			3011.73,
			3013.077,
			3013.754,
			3016.485,
			3017.177,
			3019.265,
			3020.67,
			3022.098,
			3025.735,
			3027.953,
			3028.697,
			3029.445,
			3031.712,
			3033.254,
			3035.595,
			3037.162,
			3039.493,
			3041.031,
			3041.794,
			3042.545,
			3045.44,
			3046.144,
			3048.203,
			3049.542,
			3050.858,
			3052.814,
			3054.741,
			3056.017,
			3057.918,
			3059.184,
			3061.072,
			3062.325,
			3064.244,
			3065.516,
			3067.437,
			3068.721,
			3070.643,
			3071.982,
			3074.016,
			3077.466,
			3079.551,
			3080.929,
			3082.288,
			3084.317,
			3085.679,
			3087.123,
			3088.561,
			3089.279,
			3091.498,
			3092.998,
			3095.25,
			3096.753,
			3098.257,
			3100.461,
			3101.86,
			3103.968,
			3105.338,
			3107.364,
			3108.723,
			3110.713,
			3112.028,
			3112.689,
			3114.019,
			3115.364,
			3117.401,
			3118.773,
			3120.844,
			3122.236,
			3124.35,
			3125.051,
			3125.756,
			3127.894,
			3129.338,
			3131.588,
			3133.116,
			3135.463,
			3137.078,
			3138.708,
			3141.176,
			3142.839,
			3145.371,
			3147.094,
			3148.83,
			3149.711,
			3151.514,
			3153.325,
			3154.237,
			3156.945,
			3158.768,
			3160.616,
			3163.413,
			3165.298,
			3168.151,
			3169.071,
			3169.99,
			3172.767,
			3174.637,
			3177.49,
			3179.408,
			3182.253,
			3185.118,
			3187.036,
			3189.93,
			3191.875,
			3193.833,
			3195.803,
			3197.785,
			3198.783,
			3202.802,
			3203.814,
			3206.869,
			3208.92,
			3210.987,
			3214.107,
			3216.197,
			3219.337,
			3220.399,
			3221.466,
			3224.679,
			3226.823,
			3230.05,
			3232.207,
			3234.389,
			3235.49,
			3237.701,
			3238.801,
			3240.996,
			3243.214,
			3244.342,
			3246.605,
			3248.879,
			3251.163,
			3254.56,
			3257.958,
			3259.093,
			3260.229,
			3263.655,
			3265.952,
			3268.259,
			3274.089,
			3277.611,
			3279.974,
			3283.542,
			3285.932,
			3289.536,
			3291.959,
			3295.618,
			3298.08,
			3299.314,
			3301.798,
			3305.547,
			3308.06,
			3310.581,
			3314.388,
			3316.937,
			3322.029,
			3323.294,
			3327.095,
			3329.64,
			3333.473,
			3336.04,
			3338.615,
			3342.493,
			3345.088,
			3348.993,
			3350.294,
			3351.598,
			3355.518,
			3358.139,
			3360.769,
			3362.086,
			3364.727,
			3368.701,
			3372.691,
			3375.36,
			3378.036,
			3382.063,
			3384.756,
			3388.808,
			3390.162,
			3391.519,
			3395.597,
			3398.325,
			3402.43,
			3405.175,
			3407.926,
			3409.304,
			3412.066,
			3413.45,
			3416.221,
			3420.392,
			3421.785,
			3425.975,
			3428.776,
			3432.99,
			3435.808,
			3438.632,
			3440.047,
			3442.881,
			3444.301,
			3447.146,
			3449.996,
			3454.284,
			3457.151,
			3461.462,
			3462.903,
			3464.345,
			3468.68,
			3471.578,
			3475.937,
			3478.85,
			3483.231,
			3487.626,
			3490.564,
			3493.508,
			3494.982,
			3497.935,
			3500.894,
			3505.343,
			3508.317,
			3511.296,
			3515.777,
			3520.27,
			3523.272,
			3526.279,
			3530.802,
			3533.824,
			3538.368,
			3541.404,
			3545.968,
			3549.018,
			3553.602,
			3556.665,
			3561.27,
			3565.885,
			3568.97,
			3573.606,
			3576.704,
			3579.806,
			3584.47,
			3587.584,
			3592.267,
			3593.83,
			3595.395,
			3598.526,
			3603.234,
			3606.378,
			3611.104,
			3614.26,
			3620.584,
			3622.167,
			3628.515,
			3630.105,
			3634.877,
			3638.06,
			3641.247,
			3642.842,
			3646.035,
			3647.633,
			3650.832,
			3655.638,
			3658.846,
			3662.059,
			3666.884,
			3670.106,
			3676.56,
			3678.176,
			3683.03,
			3686.271,
			3691.138,
			3694.388,
			3699.27,
			3702.529,
			3707.425,
			3712.329,
			3713.966,
			3718.882,
			3722.163,
			3727.091,
			3733.674,
			3735.322,
			3738.621,
			3740.271,
			3743.575,
			3746.883,
			3751.851,
			3755.167,
			3760.149,
			3763.474,
			3768.469,
			3771.804,
			3776.812,
			3780.154,
			3785.172,
			3788.52,
			3793.54,
			3796.878,
			3798.541,
			3801.851,
			3805.131,
			3808.376,
			3811.583,
			3813.171,
			3816.319,
			3819.427,
			3820.967,
			3825.53,
			3828.528,
			3831.492,
			3835.868,
			3838.764,
			3844.377,
			3845.765,
			3849.879,
			3852.59,
			3856.627,
			3859.305,
			3863.328,
			3865.984,
			3867.309,
			3869.953,
			3873.903,
			3876.526,
			3879.137,
			3885.65,
			3889.56,
			3890.866,
			3892.172,
			3896.111,
			3898.749,
			3902.732,
			3905.408,
			3908.093,
			3913.511,
			3914.875,
			3918.981,
			3920.354,
			3921.728,
			3925.858,
			3928.618,
			3931.386,
			3935.551,
			3938.334,
			3939.729,
			3943.936,
			3946.757,
			3949.588,
			3953.858,
			3956.718,
			3961.028,
			3963.911,
			3968.25,
			3971.153,
			3975.489,
			3981.283,
			3982.733,
			3987.083,
			3989.99,
			3992.899,
			3994.352,
			3995.804,
			4000.156,
			4003.053,
			4007.394,
			4010.281,
			4013.162,
			4014.6,
			4018.905,
			4021.757,
			4024.601,
			4028.842,
			4031.657,
			4035.869,
			4038.673,
			4042.875,
			4045.676,
			4049.884,
			4052.692,
			4056.912,
			4059.729,
			4061.14,
			4063.971,
			4068.227,
			4069.648,
			4073.924,
			4079.625,
			4081.061,
			4083.938,
			4086.821,
			4088.264,
			4091.157,
			4092.604,
			4095.52,
			4098.415,
			4102.735,
			4105.642,
			4110.015,
			4112.938,
			4117.339,
			4120.284,
			4124.716,
			4127.678,
			4132.134,
			4135.111,
			4139.615,
			4142.608,
			4147.084,
			4150.092,
			4154.615,
			4157.638,
			4162.185,
			4165.224,
			4169.795,
			4172.85,
			4177.47,
			4180.516,
			4183.598,
			4188.241,
			4189.794,
			4191.348,
			4196.012,
			4199.11,
			4203.708,
			4206.721,
			4211.165,
			4214.077,
			4216.948,
			4218.369,
			4222.574,
			4225.331,
			4228.049,
			4232.057,
			4234.681,
			4239.815,
			4241.076,
			4244.801,
			4247.239,
			4250.828,
			4253.179,
			4256.664,
			4258.934,
			4260.056,
			4261.17,
			4265.561,
			4267.729,
			4269.884,
			4274.163,
			4275.226,
			4277.346,
			4280.506,
			4283.656,
			4285.75,
			4287.843,
			4290.973,
			4293.059,
			4296.202,
			4298.302,
			4301.486,
			4303.622,
			4306.863,
			4309.039,
			4311.23,
			4312.33,
			4315.647,
			4317.872,
			4321.235,
			4322.361,
			4329.189,
			4331.492,
			4336.124,
			4337.287,
			4339.608,
			4343.076,
			4345.367,
			4348.762,
			4350.989,
			4355.356,
			4356.429,
			4360.654,
			4361.701,
			4364.829,
			4366.912,
			4370.039,
			4372.131,
			4373.176,
			4375.272,
			4377.377,
			4378.436,
			4380.556,
			4382.686,
			4385.9,
			4388.051,
			4391.291,
			4392.37,
			4393.449,
			4396.673,
			4398.812,
			4400.934,
			4404.096,
			4406.186,
			4409.31,
			4411.388,
			4414.494,
			4415.525,
			4416.554,
			4419.624,
			4421.65,
			4424.647,
			4426.609,
			4429.492,
			4432.304,
			4434.152,
			4435.979,
			4438.698,
			4440.497,
			4444.05,
			4444.934,
			4447.547,
			4449.278,
			4451.855,
			4453.559,
			4456.101,
			4457.79,
			4458.631,
			4459.47,
			4462.812,
			4463.642,
			4466.121,
			4467.764,
			4469.398,
			4470.212,
			4471.836,
			4474.257,
			4475.862,
			4478.248,
			4479.826,
			4482.188,
			4483.758,
			4486.11,
			4487.684,
			4490.046,
			4491.623,
			4494.008,
			4495.601,
			4498.002,
			4499.61,
			4502.034,
			4504.493,
			4506.141,
			4507.805,
			4510.322,
			4512.014,
			4515.439,
			4516.302,
			4518.041,
			4518.916,
			4520.673,
			4522.444,
			4526.939,
			4529.684,
			4530.604,
			4531.528,
			4534.335,
			4536.231,
			4539.111,
			4541.063,
			4544.013,
			4546.004,
			4547.001,
			4549.007,
			4551.027,
			4552.039,
			4554.067,
			4556.099,
			4560.185,
			4561.214,
			4563.272,
			4565.341,
			4566.376,
			4569.478,
			4571.541,
			4573.599,
			4576.655,
			4578.682,
			4582.676,
			4583.668,
			4586.609,
			4587.582,
			4588.555,
			4591.459,
			4593.386,
			4596.277,
			4598.204,
			4599.17,
			4601.109,
			4604.033,
			4605.997,
			4607.978,
			4612.982,
			4616.021,
			4617.04,
			4618.062,
			4621.146,
			4623.214,
			4626.314,
			4628.392,
			4630.479,
			4633.628,
			4635.741,
			4639.995,
			4641.065,
			4644.288,
			4646.448,
			4649.707,
			4654.088,
			4655.191,
			4657.386,
			4658.495,
			4660.722,
			4662.957,
			4666.334,
			4668.601,
			4672.022,
			4673.169,
			4674.317,
			4678.939,
			4680.101,
			4683.601,
			4688.299,
			4689.48,
			4693.036,
			4695.417,
			4699.007,
			4701.411,
			4703.824,
			4706.246,
			4709.896,
			4714.794,
			4716.024,
			4719.729,
			4722.205,
			4724.672,
			4728.333,
			4730.734,
			4734.265,
			4735.424,
			4739.968,
			4742.187,
			4745.448,
			4747.579,
			4750.708,
			4752.748,
			4753.755,
			4755.741,
			4758.646,
			4760.555,
			4762.418,
			4765.183,
			4766.1,
			4767.015,
			4769.763,
			4770.681,
			4771.602,
			4774.373,
			4776.232,
			4778.098,
			4780.906,
			4782.782,
			4785.592,
			4786.526,
			4787.458,
			4790.233,
			4792.068,
			4794.781,
			4796.556,
			4799.168,
			4802.539,
			4803.364,
			4805.787,
			4807.362,
			4808.914,
			4811.178,
			4812.648,
			4814.814,
			4815.523,
			4816.227,
			4818.309,
			4819.681,
			4821.712,
			4823.055,
			4825.063,
			4826.397,
			4827.064,
			4827.73,
			4830.414,
			4831.085,
			4833.101,
			4834.459,
			4836.507,
			4839.254,
			4839.948,
			4841.341,
			4842.04,
			4843.442,
			4844.854,
			4847.003,
			4848.446,
			4851.374,
			4852.115,
			4854.354,
			4855.862,
			4858.144,
			4859.673,
			4861.984,
			4863.529,
			4864.302,
			4865.859,
			4867.427,
			4868.211,
			4868.995,
			4871.361,
			4873.747,
			4875.331,
			4876.935,
			4878.555,
			4879.363,
			4881.786,
			4883.43,
			4885.063,
			4887.511,
			4889.156,
			4891.602,
			4892.414,
			4893.224,
			4895.631,
			4897.223,
			4899.612,
			4901.193,
			4903.535,
			4905.072,
			4905.838,
			4907.366,
			4909.603,
			4911.079,
			4912.549,
			4914.723,
			4915.441,
			4916.158,
			4918.301,
			4918.991,
			4919.669,
			4921.043,
			4921.729,
			4923.1,
			4924.465,
			4926.483,
			4927.838,
			4930.534,
			4931.207,
			4933.237,
			4934.602,
			4936.66,
			4938.047,
			4939.449,
			4940.156,
			4941.585,
			4942.305,
			4943.756,
			4945.226,
			4945.974,
			4947.48,
			4949.002,
			4951.327,
			4952.907,
			4955.305,
			4956.119,
			4956.94,
			4959.427,
			4961.105,
			4963.655,
			4965.382,
			4967.955,
			4970.574,
			4971.452,
			4974.979,
			4975.868,
			4978.544,
			4980.343,
			4983.078,
			4986.725,
			4987.649,
			4989.509,
			4990.459,
			4992.336,
			4994.228,
			4999.002,
			5001.938,
			5002.916,
			5003.897,
			5006.858,
			5008.875,
			5011.888,
			5013.906,
			5016.929,
			5018.961,
			5019.996,
			5022.031,
			5024.055,
			5025.08,
			5027.139,
			5029.211,
			5031.294,
			5033.393,
			5034.446,
			5036.58,
			5038.711,
			5039.782,
			5043.011,
			5045.169,
			5047.328,
			5050.551,
			5052.723,
			5056.021,
			5057.119,
			5058.219,
			5061.527,
			5063.743,
			5067.084,
			5069.323,
			5071.564,
			5072.688,
			5074.94,
			5076.07,
			5078.335,
			5081.75,
			5084.017,
			5086.312,
			5089.773,
			5090.933,
			5092.093,
			5095.613,
			5096.785,
			5097.96,
			5100.312,
			5101.492,
			5103.859,
			5107.431,
			5109.823,
			5112.228,
			5115.853,
			5117.065,
			5118.281,
			5121.939,
			5124.388,
			5128.079,
			5130.551,
			5133.032,
			5134.276,
			5139.272,
			5140.526,
			5143.039,
			5144.297,
			5146.82,
			5149.353,
			5153.167,
			5155.718,
			5159.559,
			5162.129,
			5166.005,
			5168.6,
			5172.509,
			5175.127,
			5177.754,
			5181.706,
			5183.023,
			5184.34,
			5188.31,
			5190.966,
			5194.962,
			5197.635,
			5201.656,
			5204.347,
			5207.045,
			5208.396,
			5212.459,
			5215.178,
			5217.905,
			5222.015,
			5224.757,
			5230.269,
			5231.653,
			5235.792,
			5238.568,
			5242.721,
			5245.458,
			5249.51,
			5252.171,
			5253.488,
			5256.089,
			5259.919,
			5262.431,
			5264.904,
			5267.342,
			5269.744,
			5270.934,
			5273.293,
			5275.613,
			5276.759,
			5279.022,
			5280.139,
			5282.337,
			5284.492,
			5287.646,
			5289.691,
			5292.688,
			5293.661,
			5294.626,
			5297.465,
			5299.302,
			5301.991,
			5303.74,
			5305.464,
			5306.306,
			5308.781,
			5310.396,
			5311.993,
			5312.787,
			5314.371,
			5315.959,
			5318.348,
			5319.961,
			5323.256,
			5324.088,
			5326.626,
			5328.338,
			5330.063,
			5332.677,
			5334.427,
			5337.062,
			5337.941,
			5338.82,
			5341.452,
			5343.208,
			5345.823,
			5347.56,
			5350.146,
			5351.852,
			5352.704,
			5354.41,
			5356.949,
			5358.641,
			5360.335,
			5362.868,
			5364.557,
			5367.089,
			5368.777,
			5371.312,
			5373.01,
			5375.57,
			5377.286,
			5379.011,
			5379.875,
			5381.606,
			5382.474,
			5383.342,
			5386.824,
			5387.693,
			5390.296,
			5392.021,
			5394.591,
			5396.306,
			5398.863,
			5400.561,
			5403.102,
			5404.798,
			5407.37,
			5409.09,
			5411.676,
			5413.399,
			5415.985,
			5417.722,
			5420.31,
			5422.023,
			5424.584,
			5426.294,
			5428.911,
			5430.661,
			5431.537,
			5433.311,
			5435.086,
			5436.857,
			5439.537,
			5443.088,
			5443.976,
			5446.63,
			5448.396,
			5450.155,
			5452.784,
			5454.519,
			5457.096,
			5457.947,
			5458.781,
			5461.176,
			5462.735,
			5465.005,
			5466.48,
			5468.672,
			5470.111,
			5470.828,
			5472.263,
			5474.423,
			5475.871,
			5477.321,
			5480.242,
			5480.977,
			5483.186,
			5483.925,
			5484.665,
			5486.891,
			5488.373,
			5489.853,
			5492.072,
			5493.538,
			5495.712,
			5496.429,
			5497.141,
			5499.244,
			5499.933,
			5500.614,
			5502.605,
			5503.895,
			5505.146,
			5505.759,
			5507.541,
			5508.679,
			5510.311,
			5511.355,
			5512.367,
			5513.836,
			5514.792,
			5516.214,
			5516.687,
			5517.158,
			5518.574,
			5519.521,
			5520.931,
			5521.864,
			5522.793,
			5524.693,
			5525.163,
			5527.019,
			5527.48,
			5528.878,
			5529.807,
			5531.17,
			5532.068,
			5532.967,
			5533.415,
			5534.279,
			5534.707,
			5535.555,
			5536.398,
			5538.507,
			5539.763,
			5540.601,
			5541.861,
			5542.705,
			5544.102,
			5545.123,
			5546.663,
			5547.698,
			5548.218,
			5549.505,
			5550.838,
			5551.508,
			5552.181,
			5554.276,
			5555.747,
			5557.23,
			5557.977,
			5559.408,
			5560.819,
			5561.53,
			5563.654,
			5564.918,
			5566.18,
			5568.102,
			5569.267,
			5570.962,
			5572.104,
			5573.816,
			5574.915,
			5576.582,
			5577.71,
			5579.434,
			5580.601,
			5581.188,
			5582.375,
			5584.22,
			5585.502,
			5586.794,
			5588.77,
			5590.15,
			5592.94,
			5593.645,
			5595.072,
			5595.788,
			5597.221,
			5598.657,
			5600.843,
			5602.318,
			5604.547,
			5605.293,
			5606.045,
			5608.312,
			5609.837,
			5612.149,
			5613.708,
			5615.281,
			5616.073,
			5619.249,
			5620.061,
			5621.671,
			5622.468,
			5624.098,
			5625.74,
			5628.224,
			5629.892,
			5632.415,
			5633.256,
			5634.097,
			5636.637,
			5638.348,
			5640.083,
			5642.679,
			5644.435,
			5645.317,
			5647.093,
			5648.881,
			5652.496,
			5653.407,
			5656.162,
			5658.015,
			5660.819,
			5664.604,
			5665.558,
			5668.44,
			5670.378,
			5672.328,
			5675.277,
			5677.258,
			5680.253,
			5681.258,
			5682.265,
			5685.304,
			5690.43,
			5692.5,
			5695.626,
			5697.719,
			5698.765,
			5700.86,
			5702.964,
			5706.14,
			5708.27,
			5710.409,
			5713.637,
			5715.802,
			5717.978,
			5719.069,
			5722.36,
			5724.567,
			5726.785,
			5730.131,
			5732.375,
			5735.759,
			5736.893,
			5738.028,
			5741.45,
			5743.745,
			5747.205,
			5749.525,
			5751.854,
			5753.023,
			5755.367,
			5758.901,
			5762.458,
			5764.841,
			5767.233,
			5770.84,
			5772.046,
			5773.256,
			5776.898,
			5778.116,
			5779.337,
			5783.014,
			5785.476,
			5789.186,
			5791.669,
			5794.162,
			5797.917,
			5799.173,
			5800.431,
			5804.217,
			5806.751,
			5810.567,
			5813.121,
			5816.966,
			5819.539,
			5820.828,
			5823.413,
			5826.006,
			5829.909,
			5832.516,
			5836.428,
			5839.044,
			5842.982,
			5844.299,
			5845.616,
			5849.58,
			5852.231,
			5856.22,
			5861.563,
			5862.903,
			5865.59,
			5866.934,
			5868.279,
			5872.326,
			5875.031,
			5879.11,
			5880.444
		],
		[
			0,
			0.02,
			0.064,
			0.13,
			0.236,
			0.252,
			0.315,
			0.348,
			0.436,
			0.453,
			0.498,
			0.564,
			0.624,
			0.686,
			0.748,
			0.78,
			0.811,
			0.872,
			0.933,
			0.996,
			1.105,
			1.182,
			1.246,
			1.323,
			1.369,
			1.431,
			1.493,
			1.555,
			1.648,
			1.681,
			1.74,
			1.802,
			1.865,
			1.926,
			1.989,
			2.05,
			2.081,
			2.111,
			2.189,
			2.237,
			2.361,
			2.423,
			2.516,
			2.546,
			2.608,
			2.672,
			2.731,
			2.794,
			2.855,
			2.918,
			2.95,
			2.979,
			3.058,
			3.106,
			3.167,
			3.28,
			3.353,
			3.384,
			3.414,
			3.476,
			3.54,
			3.601,
			3.663,
			3.726,
			3.788,
			3.85,
			3.926,
			3.974,
			4.035,
			4.097,
			4.144,
			4.158,
			4.283,
			4.344,
			4.361,
			4.406,
			4.469,
			4.53,
			4.592,
			4.685,
			4.716,
			4.794,
			4.84,
			4.903,
			4.964,
			5.012,
			5.027,
			5.089,
			5.15,
			5.213,
			5.228,
			5.274,
			5.342,
			5.399,
			5.46,
			5.523,
			5.555,
			5.585,
			5.662,
			5.709,
			5.769,
			5.833,
			5.893,
			5.987,
			6.02,
			6.098,
			6.144,
			6.206,
			6.268,
			6.33,
			6.391,
			6.423,
			6.451,
			6.515,
			6.531,
			6.576,
			6.641,
			6.749,
			6.764,
			6.826,
			6.888,
			6.964,
			7.016,
			7.074,
			7.135,
			7.198,
			7.259,
			7.291,
			7.32,
			7.383,
			7.444,
			7.508,
			7.569,
			7.618,
			7.635,
			7.693,
			7.723,
			7.755,
			7.833,
			7.88,
			7.941,
			8.003,
			8.065,
			8.157,
			8.189,
			8.252,
			8.313,
			8.375,
			8.438,
			8.484,
			8.593,
			8.624,
			8.7,
			8.747,
			8.809,
			8.872,
			8.918,
			8.996,
			9.026,
			9.057,
			9.119,
			9.135,
			9.182,
			9.244,
			9.307,
			9.367,
			9.43,
			9.468,
			9.493,
			9.568,
			9.617,
			9.682,
			9.741,
			9.786,
			9.803,
			9.863,
			9.927,
			9.986,
			10.051,
			10.113,
			10.173,
			10.234,
			10.299,
			10.328,
			10.36,
			10.435,
			10.484,
			10.547,
			10.609,
			10.655,
			10.73,
			10.765,
			10.856,
			10.919,
			10.98,
			11.04,
			11.105,
			11.165,
			11.196,
			11.228,
			11.306,
			11.352,
			11.415,
			11.528,
			11.661,
			11.723,
			11.786,
			11.849,
			11.911,
			11.972,
			12.033,
			12.066,
			12.096,
			12.173,
			12.221,
			12.282,
			12.391,
			12.407,
			12.531,
			12.591,
			12.653,
			12.715,
			12.777,
			12.84,
			12.935,
			12.964,
			13.043,
			13.089,
			13.15,
			13.212,
			13.273,
			13.335,
			13.368,
			13.398,
			13.461,
			13.523,
			13.588,
			13.647,
			13.709,
			13.769,
			13.833,
			13.911,
			13.955,
			14.018,
			14.081,
			14.125,
			14.236,
			14.267,
			14.328,
			14.391,
			14.452,
			14.515,
			14.575,
			14.669,
			14.7,
			14.777,
			14.824,
			14.891,
			14.996,
			15.009,
			15.073,
			15.136,
			15.198,
			15.213,
			15.259,
			15.322,
			15.382,
			15.444,
			15.507,
			15.567,
			15.629,
			15.692,
			15.756,
			15.816,
			15.881,
			15.971,
			16.005,
			16.064,
			16.081,
			16.126,
			16.19,
			16.25,
			16.312,
			16.406,
			16.435,
			16.514,
			16.56,
			16.624,
			16.684,
			16.729,
			16.808,
			16.87,
			16.994,
			17.058,
			17.119,
			17.181,
			17.241,
			17.274,
			17.306,
			17.368,
			17.429,
			17.491,
			17.553,
			17.599,
			17.615,
			17.677,
			17.712,
			17.74,
			17.816,
			17.861,
			17.924,
			17.985,
			18.049,
			18.114,
			18.143,
			18.174,
			18.236,
			18.296,
			18.36,
			18.422,
			18.483,
			18.545,
			18.575,
			18.607,
			18.685,
			18.732,
			18.793,
			18.855,
			18.918,
			18.979,
			19.041,
			19.12,
			19.164,
			19.228,
			19.29,
			19.352,
			19.414,
			19.476,
			19.552,
			19.599,
			19.662,
			19.722,
			19.785,
			19.848,
			19.908,
			19.987,
			20.033,
			20.096,
			20.158,
			20.218,
			20.312,
			20.344,
			20.422,
			20.468,
			20.529,
			20.591,
			20.636,
			20.653,
			20.747,
			20.78,
			20.853,
			20.902,
			20.965,
			21.027,
			21.088,
			21.15,
			21.181,
			21.21,
			21.273,
			21.337,
			21.396,
			21.461,
			21.507,
			21.616,
			21.646,
			21.723,
			21.831,
			21.893,
			21.957,
			22.017,
			22.05,
			22.078,
			22.142,
			22.205,
			22.264,
			22.327,
			22.375,
			22.451,
			22.483,
			22.514,
			22.591,
			22.638,
			22.701,
			22.763,
			22.823,
			22.886,
			22.948,
			23.025,
			23.072,
			23.133,
			23.195,
			23.258,
			23.321,
			23.351,
			23.381,
			23.458,
			23.506,
			23.569,
			23.631,
			23.676,
			23.755,
			23.817,
			23.878,
			23.902,
			23.941,
			24.002,
			24.064,
			24.125,
			24.189,
			24.22,
			24.25,
			24.327,
			24.374,
			24.434,
			24.498,
			24.544,
			24.559,
			24.623,
			24.685,
			24.761,
			24.809,
			24.868,
			24.933,
			24.994,
			25.085,
			25.117,
			25.196,
			25.243,
			25.305,
			25.366,
			25.413,
			25.428,
			25.491,
			25.552,
			25.628,
			25.676,
			25.737,
			25.801,
			25.861,
			25.924,
			25.961,
			25.985,
			26.049,
			26.109,
			26.171,
			26.281,
			26.391,
			26.419,
			26.497,
			26.545,
			26.608,
			26.67,
			26.731,
			26.823,
			26.856,
			26.917,
			26.931,
			26.978,
			27.041,
			27.102,
			27.149,
			27.163,
			27.226,
			27.289,
			27.367,
			27.413,
			27.48,
			27.536,
			27.583,
			27.691,
			27.723,
			27.785,
			27.847,
			27.908,
			27.969,
			28.033,
			28.095,
			28.158,
			28.235,
			28.281,
			28.342,
			28.405,
			28.451,
			28.529,
			28.559,
			28.59,
			28.653,
			28.715,
			28.777,
			28.839,
			28.9,
			28.964,
			28.994,
			29.024,
			29.104,
			29.15,
			29.212,
			29.273,
			29.336,
			29.398,
			29.428,
			29.458,
			29.522,
			29.584,
			29.646,
			29.708,
			29.77,
			29.862,
			29.892,
			29.956,
			30.017,
			30.084,
			30.14,
			30.189,
			30.202,
			30.295,
			30.329,
			30.39,
			30.406,
			30.45,
			30.515,
			30.575,
			30.638,
			30.699,
			30.761,
			30.823,
			30.839,
			30.884,
			30.949,
			31.01,
			31.056,
			31.134,
			31.195,
			31.273,
			31.381,
			31.442,
			31.507,
			31.567,
			31.631,
			31.691,
			31.753,
			31.818,
			31.876,
			31.923,
			31.94,
			32.001,
			32.033,
			32.064,
			32.144,
			32.189,
			32.25,
			32.311,
			32.374,
			32.434,
			32.466,
			32.498,
			32.56,
			32.576,
			32.623,
			32.688,
			32.746,
			32.808,
			32.901,
			32.932,
			33.008,
			33.056,
			33.117,
			33.178,
			33.241,
			33.336,
			33.364,
			33.428,
			33.49,
			33.552,
			33.614,
			33.676,
			33.738,
			33.769,
			33.801,
			33.878,
			33.924,
			33.986,
			34.095,
			34.11,
			34.17,
			34.233,
			34.295,
			34.358,
			34.42,
			34.483,
			34.544,
			34.607,
			34.667,
			34.746,
			34.791,
			34.852,
			34.916,
			34.964,
			34.98,
			35.041,
			35.07,
			35.101,
			35.179,
			35.226,
			35.289,
			35.351,
			35.411,
			35.506,
			35.535,
			35.614,
			35.66,
			35.723,
			35.784,
			35.83,
			35.939,
			35.968,
			36.048,
			36.094,
			36.158,
			36.217,
			36.281,
			36.343,
			36.405,
			36.483,
			36.529,
			36.591,
			36.652,
			36.714,
			36.807,
			36.838,
			36.962,
			37.024,
			37.086,
			37.149,
			37.241,
			37.274,
			37.336,
			37.396,
			37.46,
			37.521,
			37.568,
			37.583,
			37.644,
			37.675,
			37.706,
			37.785,
			37.831,
			37.894,
			37.953,
			38.018,
			38.078,
			38.111,
			38.141,
			38.202,
			38.264,
			38.333,
			38.387,
			38.451,
			38.513,
			38.544,
			38.573,
			38.651,
			38.7,
			38.762,
			38.824,
			38.885,
			38.946,
			38.979,
			39.008,
			39.069,
			39.087,
			39.133,
			39.195,
			39.256,
			39.319,
			39.411,
			39.443,
			39.519,
			39.567,
			39.628,
			39.739,
			39.753,
			39.847,
			39.875,
			39.956,
			40.002,
			40.064,
			40.126,
			40.185,
			40.25,
			40.311,
			40.374,
			40.434,
			40.498,
			40.559,
			40.621,
			40.683,
			40.715,
			40.746,
			40.823,
			40.869,
			40.931,
			40.994,
			41.055,
			41.117,
			41.18,
			41.242,
			41.258,
			41.303,
			41.366,
			41.43,
			41.49,
			41.552,
			41.614,
			41.691,
			41.737,
			41.8,
			41.86,
			41.924,
			41.985,
			42.048,
			42.109,
			42.17,
			42.233,
			42.295,
			42.343,
			42.358,
			42.42,
			42.455,
			42.482,
			42.56,
			42.606,
			42.728,
			42.791,
			42.854,
			42.886,
			42.913,
			42.994,
			43.04,
			43.103,
			43.162,
			43.21,
			43.288,
			43.319,
			43.349,
			43.426,
			43.474,
			43.535,
			43.597,
			43.66,
			43.72,
			43.784,
			43.846,
			43.861,
			43.908,
			43.971,
			44.031,
			44.08,
			44.187,
			44.219,
			44.297,
			44.341,
			44.405,
			44.465,
			44.529,
			44.59,
			44.62,
			44.651,
			44.712,
			44.728,
			44.775,
			44.836,
			44.899,
			44.962,
			45.025,
			45.086,
			45.164,
			45.208,
			45.27,
			45.333,
			45.395,
			45.52,
			45.598,
			45.646,
			45.707,
			45.769,
			45.83,
			45.892,
			45.922,
			45.955,
			46.031,
			46.078,
			46.141,
			46.203,
			46.263,
			46.357,
			46.389,
			46.451,
			46.511,
			46.574,
			46.636,
			46.698,
			46.761,
			46.79,
			46.823,
			46.899,
			46.947,
			47.007,
			47.118,
			47.133,
			47.194,
			47.256,
			47.381,
			47.442,
			47.505,
			47.566,
			47.628,
			47.691,
			47.752,
			47.813,
			47.875,
			47.94,
			47.985,
			48.061,
			48.093,
			48.124,
			48.202,
			48.249,
			48.311,
			48.373,
			48.434,
			48.527,
			48.558,
			48.621,
			48.684,
			48.744,
			48.806,
			48.853,
			48.93,
			48.962,
			48.991,
			49.07,
			49.118,
			49.18,
			49.301,
			49.396,
			49.426,
			49.488,
			49.552,
			49.612,
			49.675,
			49.735,
			49.799,
			49.83,
			49.862,
			49.936,
			49.985,
			50.047,
			50.108,
			50.171,
			50.232,
			50.296,
			50.373,
			50.419,
			50.482,
			50.543,
			50.605,
			50.666,
			50.704,
			50.728,
			50.806,
			50.853,
			50.914,
			51.024,
			51.04,
			51.132,
			51.163,
			51.24,
			51.286,
			51.35,
			51.412,
			51.473,
			51.566,
			51.597,
			51.658,
			51.72,
			51.783,
			51.891,
			51.907,
			51.97,
			52.001,
			52.031,
			52.108,
			52.156,
			52.218,
			52.279,
			52.341,
			52.403,
			52.466,
			52.527,
			52.59,
			52.652,
			52.713,
			52.776,
			52.836,
			52.869,
			52.9,
			52.976,
			53.085,
			53.148,
			53.208,
			53.302,
			53.333,
			53.395,
			53.411,
			53.458,
			53.519,
			53.58,
			53.627,
			53.706,
			53.735,
			53.768,
			53.846,
			53.89,
			53.958,
			54.015,
			54.078,
			54.168,
			54.202,
			54.262,
			54.277,
			54.325,
			54.389,
			54.45,
			54.497,
			54.51,
			54.574,
			54.635,
			54.713,
			54.758,
			54.824,
			54.884,
			54.945,
			55.037,
			55.07,
			55.146,
			55.194,
			55.257,
			55.317,
			55.38,
			55.44,
			55.504,
			55.58,
			55.628,
			55.691,
			55.752,
			55.813,
			55.875,
			55.936,
			56.013,
			56.062,
			56.124,
			56.186,
			56.247,
			56.31,
			56.372,
			56.449,
			56.494,
			56.557,
			56.667,
			56.681,
			56.745,
			56.775,
			56.869,
			56.889,
			56.931,
			56.993,
			57.052,
			57.115,
			57.178,
			57.208,
			57.24,
			57.301,
			57.363,
			57.427,
			57.535,
			57.551,
			57.611,
			57.644,
			57.674,
			57.751,
			57.861,
			57.923,
			57.985,
			58.077,
			58.107,
			58.17,
			58.186,
			58.232,
			58.296,
			58.357,
			58.403,
			58.512,
			58.541,
			58.621,
			58.728,
			58.791,
			58.852,
			58.915,
			58.951,
			59.039,
			59.1,
			59.162,
			59.225,
			59.286,
			59.349,
			59.378,
			59.409,
			59.489,
			59.535,
			59.659,
			59.718,
			59.782,
			59.814,
			59.843,
			59.907,
			59.969,
			60.031,
			60.094,
			60.156,
			60.218,
			60.278,
			60.357,
			60.402,
			60.466,
			60.527,
			60.587,
			60.651,
			60.683,
			60.712,
			60.79,
			60.837,
			60.9,
			60.96,
			61.023,
			61.086,
			61.114,
			61.147,
			61.224,
			61.269,
			61.333,
			61.394,
			61.456,
			61.517,
			61.579,
			61.658,
			61.705,
			61.768,
			61.829,
			61.891,
			61.952,
			61.984,
			62.015,
			62.093,
			62.139,
			62.202,
			62.263,
			62.309,
			62.417,
			62.449,
			62.512,
			62.526,
			62.573,
			62.64,
			62.696,
			62.744,
			62.758,
			62.853,
			62.884,
			62.961,
			63.006,
			63.074,
			63.194,
			63.256,
			63.286,
			63.318,
			63.379,
			63.441,
			63.501,
			63.564,
			63.627,
			63.689,
			63.72,
			63.75,
			63.828,
			63.874,
			63.936,
			64,
			64.046,
			64.123,
			64.186,
			64.248,
			64.308,
			64.373,
			64.434,
			64.496,
			64.558,
			64.619,
			64.697,
			64.742,
			64.807,
			64.867,
			64.929,
			65.052,
			65.134,
			65.179,
			65.24,
			65.3,
			65.363,
			65.425,
			65.455,
			65.486,
			65.565,
			65.612,
			65.678,
			65.736,
			65.782,
			65.859,
			65.92,
			65.999,
			66.045,
			66.108,
			66.171,
			66.231,
			66.294,
			66.323,
			66.356,
			66.417,
			66.478,
			66.541,
			66.603,
			66.666,
			66.727,
			66.758,
			66.791,
			66.868,
			66.914,
			66.977,
			67.037,
			67.101,
			67.161,
			67.223,
			67.285,
			67.348,
			67.411,
			67.47,
			67.518,
			67.533,
			67.596,
			67.626,
			67.657,
			67.735,
			67.783,
			67.844,
			67.907,
			67.968,
			68.031,
			68.06,
			68.091,
			68.153,
			68.217,
			68.278,
			68.34,
			68.401,
			68.495,
			68.526,
			68.602,
			68.651,
			68.712,
			68.774,
			68.822,
			68.835,
			68.928,
			68.961,
			69.039,
			69.083,
			69.147,
			69.208,
			69.27,
			69.331,
			69.364,
			69.396,
			69.473,
			69.518,
			69.581,
			69.641,
			69.69,
			69.705,
			69.767,
			69.797,
			69.829,
			69.89,
			69.906,
			69.952,
			70.014,
			70.075,
			70.139,
			70.201,
			70.231,
			70.263,
			70.339,
			70.386,
			70.449,
			70.557,
			70.635,
			70.697,
			70.759,
			70.82,
			70.882,
			70.944,
			71.007,
			71.068,
			71.099,
			71.129,
			71.206,
			71.256,
			71.319,
			71.378,
			71.439,
			71.534,
			71.564,
			71.641,
			71.689,
			71.752,
			71.813,
			71.875,
			71.936,
			71.999,
			72.061,
			72.076,
			72.123,
			72.184,
			72.246,
			72.292,
			72.308,
			72.369,
			72.433,
			72.509,
			72.557,
			72.618,
			72.68,
			72.743,
			72.803,
			72.835,
			72.868,
			72.927,
			72.989,
			73.052,
			73.115,
			73.162,
			73.177,
			73.239,
			73.27,
			73.3,
			73.382,
			73.425,
			73.486,
			73.549,
			73.611,
			73.673,
			73.703,
			73.735,
			73.797,
			73.813,
			73.86,
			73.922,
			73.982,
			74.029,
			74.108,
			74.136,
			74.168,
			74.247,
			74.293,
			74.36,
			74.417,
			74.479,
			74.571,
			74.602,
			74.665,
			74.681,
			74.728,
			74.787,
			74.851,
			74.913,
			74.974,
			75.037,
			75.115,
			75.16,
			75.225,
			75.285,
			75.331,
			75.408,
			75.471,
			75.533,
			75.595,
			75.658,
			75.719,
			75.78,
			75.874,
			75.906,
			75.983,
			76.028,
			76.097,
			76.153,
			76.216,
			76.277,
			76.309,
			76.337,
			76.4,
			76.464,
			76.524,
			76.585,
			76.649,
			76.741,
			76.774,
			76.852,
			76.896,
			76.959,
			77.02,
			77.066,
			77.083,
			77.144,
			77.175,
			77.206,
			77.284,
			77.33,
			77.397,
			77.456,
			77.517,
			77.611,
			77.643,
			77.703,
			77.764,
			77.833,
			77.89,
			77.952,
			78.015,
			78.076,
			78.152,
			78.2,
			78.262,
			78.324,
			78.386,
			78.479,
			78.511,
			78.571,
			78.588,
			78.634,
			78.694,
			78.757,
			78.82,
			78.881,
			78.944,
			79.02,
			79.067,
			79.131,
			79.192,
			79.255,
			79.317,
			79.377,
			79.44,
			79.456,
			79.502,
			79.568,
			79.624,
			79.673,
			79.689,
			79.75,
			79.781,
			79.811,
			79.89,
			79.936,
			79.999,
			80.06,
			80.123,
			80.215,
			80.246,
			80.309,
			80.37,
			80.433,
			80.495,
			80.556,
			80.619,
			80.649,
			80.679,
			80.758,
			80.805,
			80.867,
			80.991,
			81.052,
			81.083,
			81.114,
			81.176,
			81.237,
			81.301,
			81.362,
			81.423,
			81.518,
			81.549,
			81.61,
			81.633,
			81.673,
			81.734,
			81.795,
			81.843,
			81.92,
			81.952,
			81.983,
			82.061,
			82.107,
			82.169,
			82.231,
			82.293,
			82.352,
			82.416,
			82.495,
			82.54,
			82.601,
			82.71,
			82.726,
			82.819,
			82.851,
			82.911,
			82.927,
			82.974,
			83.036,
			83.098,
			83.162,
			83.224,
			83.252,
			83.284,
			83.362,
			83.407,
			83.47,
			83.534,
			83.578,
			83.656,
			83.717,
			83.795,
			83.843,
			83.904,
			83.968,
			84.03,
			84.091,
			84.122,
			84.151,
			84.229,
			84.275,
			84.339,
			84.447,
			84.462,
			84.524,
			84.556,
			84.586,
			84.65,
			84.664,
			84.71,
			84.774,
			84.834,
			84.897,
			84.958,
			85.021,
			85.099,
			85.144,
			85.206,
			85.269,
			85.332,
			85.391,
			85.455,
			85.517,
			85.579,
			85.645,
			85.703,
			85.762,
			85.826,
			85.889,
			85.967,
			86.014,
			86.076,
			86.135,
			86.184,
			86.262,
			86.292,
			86.324,
			86.385,
			86.446,
			86.511,
			86.571,
			86.634,
			86.696,
			86.727,
			86.757,
			86.834,
			86.882,
			86.944,
			87.006,
			87.052,
			87.161,
			87.19,
			87.269,
			87.316,
			87.381,
			87.44,
			87.501,
			87.564,
			87.595,
			87.624,
			87.704,
			87.75,
			87.816,
			87.919,
			87.934,
			87.998,
			88.06,
			88.136,
			88.183,
			88.247,
			88.306,
			88.369,
			88.432,
			88.494,
			88.557,
			88.617,
			88.681,
			88.74,
			88.787,
			88.804,
			88.897,
			88.927,
			89.004,
			89.05,
			89.177,
			89.237,
			89.33,
			89.362,
			89.424,
			89.485,
			89.547,
			89.611,
			89.67,
			89.764,
			89.797,
			89.878,
			89.92,
			89.982,
			90.044,
			90.107,
			90.166,
			90.199,
			90.228,
			90.291,
			90.308,
			90.353,
			90.417,
			90.477,
			90.54,
			90.601,
			90.633,
			90.663,
			90.741,
			90.787,
			90.85,
			90.911,
			90.974,
			91.037,
			91.096,
			91.159,
			91.175,
			91.22,
			91.284,
			91.346,
			91.409,
			91.469,
			91.533,
			91.611,
			91.656,
			91.718,
			91.782,
			91.827,
			91.941,
			91.967,
			92.044,
			92.091,
			92.152,
			92.215,
			92.276,
			92.336,
			92.368,
			92.4,
			92.477,
			92.525,
			92.587,
			92.648,
			92.695,
			92.71,
			92.772,
			92.835,
			92.897,
			92.959,
			93.022,
			93.083,
			93.145,
			93.206,
			93.267,
			93.346,
			93.391,
			93.453,
			93.516,
			93.579,
			93.64,
			93.702,
			93.78,
			93.826,
			93.887,
			93.951,
			94.012,
			94.104,
			94.136,
			94.199,
			94.215,
			94.261,
			94.322,
			94.431,
			94.508,
			94.541,
			94.571,
			94.648,
			94.694,
			94.758,
			94.818,
			94.882,
			94.944,
			94.974,
			95.005,
			95.067,
			95.082,
			95.127,
			95.191,
			95.3,
			95.316,
			95.375,
			95.408,
			95.439,
			95.516,
			95.562,
			95.624,
			95.685,
			95.749,
			95.843,
			95.874,
			95.934,
			95.95,
			95.997,
			96.066,
			96.12,
			96.183,
			96.246,
			96.275,
			96.307,
			96.384,
			96.429,
			96.494,
			96.617,
			96.678,
			96.71,
			96.741,
			96.803,
			96.864,
			96.929,
			96.99,
			97.05,
			97.112,
			97.175,
			97.254,
			97.3,
			97.362,
			97.424,
			97.486,
			97.577,
			97.609,
			97.669,
			97.686,
			97.732,
			97.796,
			97.858,
			97.92,
			97.981,
			98.014,
			98.042,
			98.126,
			98.167,
			98.228,
			98.336,
			98.353,
			98.447,
			98.476,
			98.555,
			98.602,
			98.665,
			98.725,
			98.787,
			98.881,
			98.912,
			98.989,
			99.034,
			99.096,
			99.16,
			99.206,
			99.283,
			99.312,
			99.407,
			99.469,
			99.531,
			99.594,
			99.656,
			99.717,
			99.749,
			99.778,
			99.857,
			99.904,
			99.967,
			100.028,
			100.074,
			100.091,
			100.152,
			100.186,
			100.215,
			100.292,
			100.337,
			100.4,
			100.461,
			100.523,
			100.586,
			100.618,
			100.649,
			100.725,
			100.772,
			100.834,
			100.895,
			100.957,
			101.051,
			101.08,
			101.158,
			101.206,
			101.267,
			101.329,
			101.391,
			101.452,
			101.485,
			101.515,
			101.578,
			101.64,
			101.703,
			101.765,
			101.826,
			101.886,
			101.92,
			101.948,
			102.028,
			102.075,
			102.137,
			102.197,
			102.26,
			102.352,
			102.384,
			102.445,
			102.461,
			102.506,
			102.57,
			102.632,
			102.678,
			102.785,
			102.817,
			102.897,
			102.941,
			103.006,
			103.066,
			103.13,
			103.191,
			103.221,
			103.252,
			103.314,
			103.33,
			103.375,
			103.439,
			103.501,
			103.563,
			103.625,
			103.656,
			103.686,
			103.764,
			103.81,
			103.873,
			103.933,
			103.98,
			104.059,
			104.09,
			104.119,
			104.18,
			104.243,
			104.31,
			104.367,
			104.43,
			104.491,
			104.524,
			104.555,
			104.631,
			104.679,
			104.74,
			104.803,
			104.847,
			104.865,
			104.926,
			104.956,
			104.99,
			105.049,
			105.067,
			105.113,
			105.173,
			105.236,
			105.299,
			105.392,
			105.423,
			105.498,
			105.546,
			105.608,
			105.671,
			105.718,
			105.733,
			105.794,
			105.824,
			105.856,
			105.92,
			105.935,
			105.981,
			106.043,
			106.105,
			106.167,
			106.229,
			106.259,
			106.289,
			106.353,
			106.413,
			106.478,
			106.54,
			106.601,
			106.693,
			106.723,
			106.801,
			106.85,
			106.91,
			106.973,
			107.035,
			107.128,
			107.158,
			107.219,
			107.236,
			107.284,
			107.346,
			107.453,
			107.53,
			107.561,
			107.592,
			107.668,
			107.717,
			107.779,
			107.842,
			107.903,
			107.966,
			107.995,
			108.027,
			108.09,
			108.102,
			108.151,
			108.214,
			108.274,
			108.323,
			108.337,
			108.397,
			108.436,
			108.461,
			108.536,
			108.585,
			108.647,
			108.71,
			108.772,
			108.833,
			108.863,
			108.895,
			108.958,
			109.019,
			109.081,
			109.144,
			109.19,
			109.203,
			109.267,
			109.298,
			109.33,
			109.408,
			109.454,
			109.516,
			109.577,
			109.624,
			109.637,
			109.701,
			109.733,
			109.764,
			109.825,
			109.841,
			109.885,
			109.951,
			110.01,
			110.072,
			110.136,
			110.167,
			110.197,
			110.275,
			110.32,
			110.383,
			110.444,
			110.497,
			110.507,
			110.6,
			110.631,
			110.693,
			110.708,
			110.753,
			110.818,
			110.878,
			110.942,
			111.002,
			111.066,
			111.142,
			111.19,
			111.251,
			111.313,
			111.361,
			111.438,
			111.469,
			111.499,
			111.561,
			111.624,
			111.687,
			111.749,
			111.81,
			111.873,
			111.901,
			111.934,
			112.012,
			112.059,
			112.12,
			112.182,
			112.229,
			112.335,
			112.368,
			112.444,
			112.493,
			112.558,
			112.617,
			112.677,
			112.741,
			112.77,
			112.801,
			112.878,
			112.927,
			112.992,
			113.049,
			113.097,
			113.112,
			113.173,
			113.204,
			113.235,
			113.298,
			113.314,
			113.361,
			113.423,
			113.485,
			113.544,
			113.609,
			113.64,
			113.668,
			113.748,
			113.793,
			113.856,
			113.916,
			113.964,
			113.98,
			114.074,
			114.102,
			114.167,
			114.181,
			114.228,
			114.29,
			114.352,
			114.415,
			114.507,
			114.539,
			114.62,
			114.661,
			114.724,
			114.787,
			114.833,
			114.912,
			114.94,
			114.972,
			115.035,
			115.096,
			115.159,
			115.221,
			115.281,
			115.344,
			115.375,
			115.406,
			115.484,
			115.531,
			115.591,
			115.655,
			115.716,
			115.779,
			115.84,
			115.901,
			115.964,
			116.027,
			116.088,
			116.134,
			116.15,
			116.213,
			116.244,
			116.275,
			116.352,
			116.396,
			116.462,
			116.523,
			116.584,
			116.645,
			116.708,
			116.772,
			116.832,
			116.895,
			116.955,
			117.017,
			117.081,
			117.141,
			117.219,
			117.268,
			117.329,
			117.391,
			117.452,
			117.515,
			117.577,
			117.638,
			117.655,
			117.702,
			117.762,
			117.824,
			117.885,
			117.979,
			118.011,
			118.089,
			118.136,
			118.198,
			118.258,
			118.304,
			118.383,
			118.414,
			118.444,
			118.506,
			118.569,
			118.63,
			118.693,
			118.753,
			118.817,
			118.846,
			118.879,
			118.957,
			119.003,
			119.064,
			119.174,
			119.188,
			119.251,
			119.28,
			119.313,
			119.376,
			119.435,
			119.497,
			119.561,
			119.623,
			119.686,
			119.717,
			119.747,
			119.808,
			119.825,
			119.87,
			119.934,
			119.996,
			120.041,
			120.058,
			120.15,
			120.181,
			120.26,
			120.304,
			120.368,
			120.429,
			120.492,
			120.552,
			120.583,
			120.613,
			120.677,
			120.739,
			120.807,
			120.864,
			120.91,
			121.019,
			121.05,
			121.129,
			121.174,
			121.235,
			121.297,
			121.359,
			121.42,
			121.451,
			121.483,
			121.545,
			121.561,
			121.608,
			121.67,
			121.778,
			121.856,
			121.917,
			121.994,
			122.041,
			122.103,
			122.166,
			122.229,
			122.29,
			122.32,
			122.352,
			122.414,
			122.428,
			122.474,
			122.539,
			122.6,
			122.647,
			122.661,
			122.724,
			122.756,
			122.785,
			122.87,
			122.91,
			122.972,
			123.033,
			123.08,
			123.158,
			123.22,
			123.283,
			123.344,
			123.407,
			123.467,
			123.53,
			123.593,
			123.622,
			123.653,
			123.731,
			123.778,
			123.84,
			123.903,
			123.962,
			124.056,
			124.086,
			124.15,
			124.165,
			124.211,
			124.274,
			124.334,
			124.397,
			124.461,
			124.492,
			124.523,
			124.599,
			124.646,
			124.707,
			124.769,
			124.817,
			124.832,
			124.93,
			124.956,
			125.019,
			125.033,
			125.081,
			125.141,
			125.202,
			125.267,
			125.328,
			125.358,
			125.39,
			125.451,
			125.514,
			125.575,
			125.639,
			125.686,
			125.701,
			125.762,
			125.825,
			125.903,
			125.947,
			126.011,
			126.073,
			126.135,
			126.226,
			126.258,
			126.336,
			126.383,
			126.444,
			126.507,
			126.553,
			126.631,
			126.662,
			126.691,
			126.769,
			126.878,
			126.941,
			127.002,
			127.063,
			127.097,
			127.127,
			127.188,
			127.25,
			127.313,
			127.374,
			127.437,
			127.498,
			127.53,
			127.561,
			127.639,
			127.685,
			127.748,
			127.807,
			127.871,
			127.932,
			127.964,
			127.995,
			128.056,
			128.119,
			128.182,
			128.241,
			128.289,
			128.302,
			128.367,
			128.429,
			128.508,
			128.553,
			128.615,
			128.678,
			128.723,
			128.737,
			128.801,
			128.831,
			128.861,
			128.941,
			128.986,
			129.054,
			129.11,
			129.173,
			129.233,
			129.266,
			129.297,
			129.375,
			129.419,
			129.483,
			129.544,
			129.607,
			129.67,
			129.7,
			129.731,
			129.794,
			129.854,
			129.916,
			129.979,
			130.04,
			130.102,
			130.134,
			130.165,
			130.242,
			130.288,
			130.352,
			130.459,
			130.476,
			130.536,
			130.568,
			130.599,
			130.66,
			130.722,
			130.785,
			130.814
		]
	],
	'2866_4248_7982': [
		[
			78.036
		],
		[
			0
		],
		[
			0,
			0.945,
			2.289,
			4.983,
			7.682,
			11.744,
			14.462,
			18.55,
			21.283,
			25.396,
			28.145,
			30.902,
			35.048,
			37.821,
			41.992,
			44.782,
			48.979,
			51.786,
			56.01,
			58.834,
			63.082,
			65.92,
			67.342,
			70.189,
			74.495,
			77.328,
			80.187,
			87.342,
			91.649,
			94.528,
			98.858,
			101.751,
			106.103,
			109.012,
			111.926,
			117.773,
			119.239,
			125.117,
			126.59,
			131.02,
			133.981,
			136.948,
			138.433,
			141.41,
			144.392,
			148.877,
			150.376,
			153.376,
			156.383,
			160.905,
			163.927,
			168.469,
			169.987,
			171.505,
			176.07,
			179.12,
			183.706,
			186.77,
			189.841,
			191.377,
			194.455,
			195.996,
			199.082,
			203.722,
			206.822,
			209.93,
			213.04,
			217.715,
			220.838,
			225.534,
			230.246,
			233.394,
			236.547,
			244.447,
			249.199,
			250.786,
			252.374,
			258.739,
			260.331,
			265.113,
			268.307,
			273.105,
			276.309,
			277.913,
			281.124,
			285.951,
			289.173,
			292.4,
			297.25,
			300.489,
			305.358,
			308.61,
			313.498,
			316.762,
			320.031,
			324.943,
			328.224,
			333.152,
			334.797,
			336.443,
			341.388,
			344.689,
			349.648,
			356.274,
			357.933,
			364.579,
			366.244,
			374.582,
			377.926,
			382.95,
			386.304,
			393.027,
			394.71,
			399.766,
			403.141,
			408.212,
			411.598,
			414.988,
			420.079,
			423.479,
			428.586,
			431.995,
			437.117,
			440.536,
			445.672,
			452.533,
			454.251,
			457.689,
			459.409,
			462.853,
			466.3,
			471.477,
			474.933,
			481.855,
			483.587,
			488.79,
			492.262,
			497.466,
			504.357,
			509.45,
			511.13,
			514.459,
			519.38,
			522.614,
			525.809,
			528.967,
			533.633,
			536.699,
			539.729,
			541.232,
			545.686,
			548.609,
			551.495,
			555.752,
			558.542,
			562.655,
			565.348,
			569.317,
			571.914,
			575.74,
			578.244,
			581.928,
			584.338,
			585.53,
			587.887,
			591.351,
			593.611,
			595.831,
			599.087,
			600.152,
			601.208,
			603.291,
			606.342,
			611.242,
			613.137,
			615.913,
			617.721,
			620.375,
			622.101,
			625.456,
			626.275,
			628.675,
			631.785,
			632.539,
			634.021,
			636.193,
			638.285,
			639.633,
			640.944,
			642.843,
			644.055,
			645.784,
			646.891,
			648.484,
			649.495,
			650.938,
			651.856,
			653.175,
			654.008,
			654.411,
			654.804,
			656.299,
			656.652,
			657.671,
			658.328,
			659.279,
			659.885,
			660.477,
			660.769,
			661.345,
			661.63,
			662.197,
			662.764,
			663.618,
			664.233,
			665.185,
			665.501,
			665.818,
			666.767,
			667.404,
			668.375,
			669.089,
			670.353,
			671.193,
			672.456,
			673.306,
			673.736,
			674.662,
			675.588,
			676.509,
			677.896,
			678.828,
			680.089,
			681.331,
			682.156,
			682.984,
			684.209,
			684.978,
			686.504,
			686.885,
			688.03,
			688.799,
			689.954,
			690.725,
			691.881,
			692.649,
			693.032,
			693.799,
			694.967,
			695.749,
			696.533,
			697.717,
			698.115,
			698.513,
			699.327,
			700.157,
			700.575,
			701.839,
			702.692,
			703.554,
			704.884,
			705.789,
			707.168,
			707.634,
			708.104,
			709.53,
			710.51,
			712.02,
			713.052,
			714.641,
			715.726,
			717.394,
			719.107,
			720.275,
			721.462,
			723.28,
			724.515,
			727.017,
			727.643,
			729.537,
			730.814,
			732.764,
			734.085,
			735.42,
			736.095,
			737.457,
			738.144,
			738.835,
			741.642,
			742.354,
			744.515,
			745.979,
			748.206,
			749.712,
			751.236,
			752.004,
			753.552,
			755.906,
			757.495,
			759.909,
			761.537,
			764.008,
			764.84,
			765.675,
			768.202,
			769.891,
			772.433,
			774.144,
			776.736,
			779.358,
			781.122,
			782.9,
			783.794,
			784.69,
			787.398,
			789.22,
			791.053,
			791.975,
			793.828,
			795.692,
			796.629,
			799.458,
			801.359,
			803.273,
			806.168,
			808.114,
			811.056,
			813.034,
			816.027,
			818.04,
			821.088,
			825.204,
			826.244,
			828.334,
			831.495,
			834.673,
			836.798,
			838.931,
			843.232,
			844.315,
			846.488,
			849.766,
			851.967,
			855.289,
			857.523,
			860.912,
			863.171,
			867.712,
			868.833,
			872.264,
			874.56,
			878.018,
			880.334,
			882.655,
			883.819,
			886.152,
			887.32,
			889.663,
			892.013,
			893.19,
			895.552,
			897.918,
			901.483,
			903.869,
			907.465,
			908.667,
			909.873,
			912.29,
			913.502,
			915.932,
			918.372,
			922.047,
			924.509,
			928.218,
			929.459,
			930.702,
			934.445,
			936.951,
			940.725,
			943.252,
			947.058,
			952.161,
			953.442,
			956.009,
			957.296,
			959.876,
			962.464,
			968.966,
			972.877,
			975.493,
			979.433,
			982.068,
			986.035,
			988.689,
			992.684,
			995.357,
			999.378,
			1002.068,
			1003.415,
			1004.765,
			1008.823,
			1011.537,
			1014.259,
			1015.623,
			1018.355,
			1021.095,
			1022.467,
			1026.594,
			1029.355,
			1032.123,
			1036.287,
			1039.073,
			1043.263,
			1044.664,
			1046.066,
			1050.283,
			1053.103,
			1057.346,
			1063.028,
			1064.453,
			1068.737,
			1071.602,
			1075.912,
			1078.794,
			1081.683,
			1086.028,
			1088.933,
			1091.844,
			1094.761,
			1096.222,
			1100.615,
			1103.551,
			1106.492,
			1110.917,
			1113.875,
			1118.324,
			1119.81,
			1121.297,
			1125.769,
			1128.758,
			1133.253,
			1139.268,
			1140.776,
			1145.307,
			1148.335,
			1151.37,
			1152.89,
			1155.935,
			1158.987,
			1163.574,
			1166.64,
			1171.249,
			1172.788,
			1174.329,
			1178.963,
			1182.059,
			1186.711,
			1189.819,
			1192.932,
			1197.612,
			1199.175,
			1200.739,
			1205.438,
			1208.576,
			1213.293,
			1216.444,
			1221.179,
			1225.925,
			1229.094,
			1233.858,
			1237.04,
			1240.225,
			1245.005,
			1248.197,
			1252.993,
			1254.594,
			1256.197,
			1261.011,
			1264.226,
			1269.058,
			1272.285,
			1277.136,
			1280.376,
			1281.998,
			1285.245,
			1288.496,
			1290.124,
			1291.752,
			1296.644,
			1299.911,
			1304.82,
			1311.378,
			1313.021,
			1317.955,
			1321.25,
			1324.55,
			1329.508,
			1332.819,
			1339.455,
			1341.117,
			1346.112,
			1349.447,
			1354.459,
			1357.807,
			1361.159,
			1362.837,
			1366.195,
			1371.242,
			1376.298,
			1379.673,
			1388.131,
			1391.521,
			1398.311,
			1400.01,
			1403.413,
			1405.116,
			1408.525,
			1411.938,
			1417.065,
			1420.487,
			1427.343,
			1429.06,
			1434.217,
			1437.66,
			1442.831,
			1446.284,
			1451.471,
			1454.933,
			1456.665,
			1460.133,
			1465.343,
			1468.82,
			1472.3,
			1477.527,
			1481.017,
			1486.258,
			1488.006,
			1489.756,
			1495.01,
			1498.516,
			1503.783,
			1507.298,
			1510.817,
			1516.102,
			1517.865,
			1519.629,
			1524.926,
			1526.694,
			1528.462,
			1533.771,
			1537.313,
			1542.634,
			1546.185,
			1549.739,
			1551.517,
			1556.857,
			1560.421,
			1563.988,
			1569.345,
			1572.92,
			1580.08,
			1581.872,
			1587.254,
			1590.847,
			1596.242,
			1599.842,
			1605.249,
			1608.857,
			1610.662,
			1612.468,
			1619.698,
			1621.508,
			1626.938,
			1630.562,
			1636.002,
			1639.631,
			1643.264,
			1648.717,
			1650.536,
			1654.176,
			1657.819,
			1663.289,
			1666.939,
			1674.246,
			1676.075,
			1681.565,
			1685.228,
			1690.727,
			1694.397,
			1699.906,
			1705.422,
			1709.101,
			1718.31,
			1721.998,
			1727.536,
			1731.23,
			1734.926,
			1740.476,
			1746.031,
			1749.737,
			1753.446,
			1762.729,
			1770.165,
			1772.026,
			1777.611,
			1781.337,
			1786.931,
			1790.663,
			1796.265,
			1800.002,
			1801.871,
			1805.612,
			1809.353,
			1811.225,
			1814.97,
			1818.717,
			1824.34,
			1826.216,
			1828.092,
			1833.723,
			1835.601,
			1837.479,
			1843.117,
			1846.878,
			1856.288,
			1860.055,
			1865.709,
			1869.48,
			1875.139,
			1878.914,
			1884.58,
			1888.359,
			1894.031,
			1901.598,
			1903.491,
			1909.173,
			1912.964,
			1916.756,
			1922.447,
			1926.242,
			1933.838,
			1935.737,
			1941.428,
			1945.208,
			1950.83,
			1954.535,
			1958.202,
			1960.02,
			1963.626,
			1965.413,
			1968.959,
			1974.202,
			1975.929,
			1981.053,
			1984.419,
			1989.394,
			1992.661,
			1997.487,
			2000.655,
			2002.224,
			2005.333,
			2008.405,
			2015.92,
			2020.32,
			2021.766,
			2023.202,
			2027.476,
			2030.239,
			2034.349,
			2039.708,
			2041.051,
			2043.681,
			2044.992,
			2047.584,
			2050.191,
			2051.493,
			2052.792,
			2056.698,
			2060.567,
			2063.144,
			2068.279,
			2069.57,
			2073.45,
			2076.066,
			2078.666,
			2082.579,
			2085.19,
			2090.414,
			2091.726,
			2095.667,
			2098.318,
			2102.335,
			2105.012,
			2109.067,
			2111.783,
			2114.509,
			2115.874,
			2119.977,
			2122.708,
			2125.431,
			2129.466,
			2130.801,
			2132.129,
			2137.37,
			2138.636,
			2141.185,
			2142.467,
			2144.965,
			2147.441,
			2151.119,
			2153.557,
			2157.177,
			2158.389,
			2159.601,
			2164.476,
			2165.694,
			2169.365,
			2171.821,
			2174.283,
			2175.516,
			2177.985,
			2180.457,
			2181.695,
			2185.406,
			2187.882,
			2190.354,
			2194.062,
			2196.529,
			2200.215,
			2202.665,
			2206.325,
			2208.756,
			2212.365,
			2214.771,
			2217.189,
			2218.384,
			2220.765,
			2221.954,
			2226.688,
			2227.867,
			2229.049,
			2232.568,
			2234.929,
			2238.49,
			2240.851,
			2243.206,
			2244.382,
			2247.889,
			2250.203,
			2252.493,
			2255.848,
			2258.051,
			2261.274,
			2262.329,
			2263.375,
			2266.454,
			2268.461,
			2271.406,
			2273.324,
			2276.143,
			2277.978,
			2278.882,
			2280.674,
			2282.432,
			2283.298,
			2285.007,
			2286.681,
			2289.123,
			2289.92,
			2290.707,
			2292.254,
			2293.764,
			2294.505,
			2296.675,
			2298.075,
			2299.442,
			2300.114,
			2301.433,
			2302.719,
			2305.192,
			2305.789,
			2307.534,
			2308.646,
			2310.249,
			2311.274,
			2312.264,
			2312.748,
			2313.694,
			2315.062,
			2316.378,
			2317.237,
			2318.086,
			2319.348,
			2320.169,
			2321.388,
			2321.794,
			2322.199,
			2323.01,
			2323.416,
			2324.228,
			2325.485,
			2326.327,
			2327.169,
			2328.439,
			2328.866,
			2329.295,
			2331.262,
			2331.764,
			2333.272,
			2334.28,
			2336.053,
			2337.267,
			2338.475,
			2339.078,
			2340.298,
			2340.91,
			2342.124,
			2343.33,
			2345.082,
			2346.118,
			2347.655,
			2348.165,
			2348.675,
			2350.178,
			2351.113,
			2352.509,
			2353.441,
			2354.378,
			2355.784,
			2356.252,
			2357.189,
			2358.132,
			2358.606,
			2359.083,
			2360.524,
			2361.494,
			2362.965,
			2364.965,
			2365.471,
			2367.006,
			2368.045,
			2369.097,
			2370.713,
			2371.813,
			2374.06,
			2374.633,
			2376.406,
			2377.613,
			2379.447,
			2380.68,
			2382.552,
			2383.813,
			2385.735,
			2387.681,
			2388.994,
			2390.324,
			2392.332,
			2393.006,
			2393.685,
			2395.049,
			2397.136,
			2398.542,
			2399.248,
			2400.675,
			2402.142,
			2404.361,
			2405.864,
			2408.193,
			2408.975,
			2409.76,
			2412.176,
			2413.816,
			2416.305,
			2417.985,
			2419.679,
			2420.533,
			2423.09,
			2424.809,
			2427.372,
			2429.098,
			2430.834,
			2433.407,
			2435.125,
			2437.66,
			2438.496,
			2439.33,
			2441.81,
			2443.453,
			2445.881,
			2447.482,
			2449.072,
			2451.447,
			2453.027,
			2456.172,
			2456.963,
			2459.329,
			2460.903,
			2463.278,
			2464.861,
			2467.243,
			2468.84,
			2469.638,
			2471.235,
			2472.847,
			2473.654,
			2475.266,
			2476.882,
			2480.134,
			2480.945,
			2483.405,
			2484.226,
			2485.046,
			2487.514,
			2491.658,
			2493.335,
			2495.837,
			2497.503,
			2498.341,
			2499.176,
			2501.667,
			2502.502,
			2503.34,
			2505.838,
			2507.501,
			2509.178,
			2510.013,
			2511.678,
			2513.365,
			2514.214,
			2516.75,
			2518.441,
			2520.131,
			2522.657,
			2524.312,
			2527.614,
			2528.41,
			2530.797,
			2532.391,
			2534.729,
			2536.289,
			2538.625,
			2540.175,
			2542.514,
			2544.855,
			2545.644,
			2548.037,
			2549.635,
			2551.238,
			2552.042,
			2553.659,
			2555.285,
			2556.099,
			2557.727,
			2558.548,
			2560.194,
			2561.838,
			2564.311,
			2565.962,
			2568.426,
			2569.25,
			2570.072,
			2572.542,
			2574.195,
			2576.687,
			2578.354,
			2580.031,
			2580.871,
			2582.551,
			2584.234,
			2585.078,
			2587.597,
			2589.299,
			2590.978,
			2593.539,
			2595.251,
			2598.664,
			2599.525,
			2602.124,
			2603.862,
			2605.608,
			2608.24,
			2610.003,
			2612.681,
			2613.574,
			2614.469,
			2617.169,
			2618.982,
			2621.731,
			2623.583,
			2626.387,
			2628.274,
			2631.131,
			2633.051,
			2634.015,
			2635.954,
			2637.904,
			2640.852,
			2642.833,
			2645.827,
			2647.833,
			2652.88,
			2655.967,
			2658.025,
			2661.113,
			2663.212,
			2665.303,
			2668.44,
			2669.497,
			2670.573,
			2673.757,
			2675.88,
			2678.008,
			2679.056,
			2681.208,
			2683.331,
			2684.404,
			2687.629,
			2691.979,
			2695.256,
			2697.454,
			2700.755,
			2701.866,
			2702.979,
			2706.328,
			2708.573,
			2711.96,
			2714.225,
			2716.497,
			2717.637,
			2719.921,
			2723.384,
			2726.83,
			2727.99,
			2731.504,
			2733.846,
			2737.377,
			2739.743,
			2742.121,
			2743.313,
			2745.707,
			2746.908,
			2749.316,
			2751.731,
			2755.372,
			2757.812,
			2761.465,
			2762.693,
			2763.924,
			2767.646,
			2770.126,
			2773.865,
			2776.369,
			2780.141,
			2782.676,
			2786.495,
			2789.054,
			2792.9,
			2795.472,
			2798.055,
			2801.945,
			2805.854,
			2807.161,
			2808.469,
			2812.408,
			2815.045,
			2817.688,
			2824.319,
			2828.304,
			2830.971,
			2834.987,
			2837.673,
			2841.713,
			2844.418,
			2848.489,
			2852.551,
			2855.281,
			2858.019,
			2859.388,
			2862.131,
			2864.874,
			2868.969,
			2871.668,
			2875.651,
			2878.257,
			2880.819,
			2884.588,
			2888.264,
			2890.667,
			2893.031,
			2896.505,
			2898.77,
			2903.177,
			2904.254,
			2907.424,
			2909.489,
			2912.512,
			2914.481,
			2916.413,
			2917.366,
			2920.17,
			2922.013,
			2923.809,
			2926.453,
			2928.181,
			2930.721,
			2932.401,
			2934.891,
			2936.553,
			2939.055,
			2942.438,
			2943.294,
			2945.016,
			2946.751,
			2950.257,
			2951.142,
			2953.804,
			2955.588,
			2958.265,
			2960.048,
			2961.831,
			2962.72,
			2965.377,
			2967.141,
			2968.895,
			2971.504,
			2973.241,
			2975.831,
			2976.691,
			2977.555,
			2980.137,
			2981.855,
			2984.443,
			2986.167,
			2988.758,
			2990.485,
			2991.349,
			2993.079,
			2994.811,
			2995.677,
			2996.545,
			2999.159,
			3002.659,
			3003.539,
			3005.297,
			3007.056,
			3007.936,
			3010.583,
			3012.337,
			3014.102,
			3016.745,
			3018.511,
			3021.18,
			3022.071,
			3022.962,
			3025.674,
			3027.484,
			3030.216,
			3032.037,
			3034.762,
			3037.483,
			3039.295,
			3042.001,
			3043.802,
			3045.599,
			3048.282,
			3050.068,
			3052.735,
			3053.621,
			3054.504,
			3057.17,
			3058.936,
			3061.582,
			3063.337,
			3065.088,
			3067.71,
			3068.582,
			3069.453,
			3072.929,
			3073.797,
			3076.381,
			3078.095,
			3079.809,
			3080.658,
			3082.353,
			3084.049,
			3084.897,
			3086.564,
			3087.397,
			3089.065,
			3090.728,
			3093.206,
			3094.861,
			3097.339,
			3098.165,
			3098.989,
			3101.459,
			3103.098,
			3105.537,
			3107.151,
			3108.747,
			3109.536,
			3111.081,
			3111.847,
			3113.354,
			3114.824,
			3115.545,
			3116.251,
			3118.314,
			3119.647,
			3121.581,
			3122.826,
			3124.036,
			3124.627,
			3126.356,
			3127.475,
			3128.565,
			3130.158,
			3131.209,
			3132.771,
			3133.797,
			3135.326,
			3136.344,
			3137.864,
			3138.87,
			3140.374,
			3141.378,
			3141.874,
			3142.861,
			3143.843,
			3144.333,
			3145.314,
			3146.299,
			3147.299,
			3148.297,
			3148.795,
			3150.288,
			3150.786,
			3151.286,
			3152.38,
			3152.941,
			3154.059,
			3155.174,
			3156.885,
			3158.15,
			3160.039,
			3160.667,
			3161.296,
			3163.179,
			3164.421,
			3166.273,
			3168.71,
			3169.309,
			3170.498,
			3172.265,
			3173.419,
			3175.124,
			3176.252,
			3177.858,
			3178.886,
			3180.421,
			3181.444,
			3182.925,
			3183.89,
			3185.332,
			3186.291,
			3187.253,
			3187.742,
			3189.2,
			3189.683,
			3191.121,
			3192.084,
			3193.564,
			3194.562,
			3196.09,
			3197.123,
			3198.177,
			3198.71,
			3199.788,
			3200.333,
			3201.435,
			3202.553,
			3204.266,
			3205.427,
			3207.2,
			3207.799,
			3208.392,
			3210.227,
			3211.47,
			3213.367,
			3214.653,
			3216.612,
			3217.938,
			3218.608,
			3219.96,
			3222.019,
			3222.713,
			3224.82,
			3226.245,
			3227.687,
			3228.413,
			3229.881,
			3231.364,
			3232.111,
			3234.381,
			3235.916,
			3239.821,
			3241.411,
			3243.825,
			3244.637,
			3245.453,
			3247.922,
			3249.586,
			3252.106,
			3253.792,
			3255.478,
			3256.325,
			3258.031,
			3260.613,
			3263.226,
			3264.984,
			3266.756,
			3269.438,
			3271.243,
			3273.061,
			3274.893,
			3275.814,
			3278.598,
			3280.471,
			3282.357,
			3285.211,
			3287.13,
			3291.006,
			3291.982,
			3294.931,
			3296.912,
			3299.904,
			3301.913,
			3303.934,
			3304.949,
			3308.011,
			3310.067,
			3312.134,
			3313.171,
			3315.255,
			3317.349,
			3320.491,
			3322.591,
			3325.761,
			3326.823,
			3327.888,
			3331.098,
			3333.251,
			3336.501,
			3340.873,
			3345.289,
			3346.4,
			3349.748,
			3351.992,
			3355.377,
			3357.646,
			3361.067,
			3363.36,
			3365.662,
			3366.817,
			3370.296,
			3372.628,
			3374.97,
			3378.501,
			3380.867,
			3384.433,
			3385.627,
			3386.822,
			3390.423,
			3392.835,
			3396.47,
			3398.903,
			3401.345,
			3405.024,
			3406.254,
			3408.723,
			3411.199,
			3412.441,
			3413.685,
			3417.43,
			3419.937,
			3422.451,
			3423.712,
			3426.241,
			3428.777,
			3430.048,
			3432.595,
			3433.873,
			3436.434,
			3439.001,
			3442.869,
			3445.456,
			3449.352,
			3450.655,
			3451.958,
			3455.868,
			3458.481,
			3462.413,
			3465.043,
			3467.679,
			3469,
			3471.647,
			3472.973,
			3475.631,
			3479.63,
			3482.303,
			3484.984,
			3488.693
		],
		[
			0,
			0.021,
			0.036,
			0.082,
			0.146,
			0.207,
			0.268,
			0.332,
			0.393,
			0.468,
			0.518,
			0.579,
			0.642,
			0.685,
			0.763,
			0.827,
			0.889,
			0.95,
			1.013,
			1.074,
			1.135,
			1.198,
			1.23,
			1.261,
			1.337,
			1.386,
			1.448,
			1.556,
			1.633,
			1.695,
			1.758,
			1.819,
			1.882,
			1.943,
			2.002,
			2.098,
			2.129,
			2.208,
			2.251,
			2.319,
			2.376,
			2.422,
			2.438,
			2.5,
			2.533,
			2.624,
			2.641,
			2.687,
			2.749,
			2.81,
			2.873,
			2.935,
			2.965,
			2.998,
			3.058,
			3.119,
			3.183,
			3.245,
			3.291,
			3.308,
			3.368,
			3.399,
			3.432,
			3.508,
			3.556,
			3.618,
			3.68,
			3.743,
			3.802,
			3.866,
			3.943,
			3.988,
			4.053,
			4.161,
			4.235,
			4.268,
			4.3,
			4.384,
			4.425,
			4.485,
			4.547,
			4.609,
			4.672,
			4.701,
			4.735,
			4.811,
			4.857,
			4.918,
			4.982,
			5.044,
			5.105,
			5.168,
			5.246,
			5.291,
			5.352,
			5.417,
			5.477,
			5.541,
			5.568,
			5.601,
			5.679,
			5.725,
			5.789,
			5.897,
			5.91,
			6.006,
			6.035,
			6.161,
			6.223,
			6.284,
			6.347,
			6.447,
			6.468,
			6.533,
			6.594,
			6.658,
			6.717,
			6.766,
			6.841,
			6.905,
			6.98,
			7.027,
			7.091,
			7.152,
			7.215,
			7.307,
			7.338,
			7.401,
			7.415,
			7.463,
			7.524,
			7.587,
			7.649,
			7.741,
			7.772,
			7.849,
			7.896,
			7.958,
			8.066,
			8.144,
			8.174,
			8.206,
			8.283,
			8.331,
			8.392,
			8.452,
			8.515,
			8.579,
			8.609,
			8.639,
			8.719,
			8.765,
			8.827,
			8.888,
			8.95,
			9.011,
			9.074,
			9.134,
			9.199,
			9.26,
			9.322,
			9.385,
			9.446,
			9.477,
			9.507,
			9.586,
			9.633,
			9.694,
			9.758,
			9.804,
			9.819,
			9.88,
			9.944,
			10.066,
			10.128,
			10.191,
			10.252,
			10.314,
			10.377,
			10.456,
			10.501,
			10.566,
			10.672,
			10.685,
			10.748,
			10.809,
			10.889,
			10.934,
			10.997,
			11.058,
			11.122,
			11.181,
			11.25,
			11.308,
			11.368,
			11.432,
			11.494,
			11.555,
			11.617,
			11.646,
			11.677,
			11.757,
			11.802,
			11.864,
			11.927,
			11.99,
			12.05,
			12.081,
			12.113,
			12.176,
			12.19,
			12.235,
			12.3,
			12.361,
			12.424,
			12.483,
			12.515,
			12.548,
			12.629,
			12.669,
			12.733,
			12.794,
			12.858,
			12.917,
			12.981,
			13.043,
			13.06,
			13.106,
			13.169,
			13.23,
			13.293,
			13.353,
			13.416,
			13.493,
			13.539,
			13.602,
			13.663,
			13.71,
			13.816,
			13.848,
			13.925,
			13.974,
			14.033,
			14.097,
			14.16,
			14.222,
			14.252,
			14.284,
			14.361,
			14.407,
			14.47,
			14.532,
			14.577,
			14.595,
			14.655,
			14.691,
			14.716,
			14.795,
			14.84,
			14.904,
			14.965,
			15.027,
			15.088,
			15.119,
			15.15,
			15.228,
			15.277,
			15.338,
			15.399,
			15.461,
			15.525,
			15.587,
			15.663,
			15.71,
			15.772,
			15.835,
			15.896,
			15.989,
			16.019,
			16.096,
			16.145,
			16.206,
			16.269,
			16.313,
			16.331,
			16.393,
			16.423,
			16.452,
			16.531,
			16.577,
			16.641,
			16.702,
			16.764,
			16.827,
			16.858,
			16.887,
			16.949,
			17.013,
			17.073,
			17.135,
			17.181,
			17.261,
			17.291,
			17.322,
			17.399,
			17.444,
			17.507,
			17.567,
			17.632,
			17.726,
			17.756,
			17.817,
			17.832,
			17.88,
			17.943,
			18.005,
			18.049,
			18.067,
			18.129,
			18.158,
			18.191,
			18.267,
			18.315,
			18.377,
			18.438,
			18.499,
			18.562,
			18.624,
			18.687,
			18.749,
			18.816,
			18.919,
			18.935,
			18.996,
			19.061,
			19.141,
			19.182,
			19.246,
			19.353,
			19.369,
			19.429,
			19.491,
			19.552,
			19.617,
			19.677,
			19.74,
			19.801,
			19.896,
			19.927,
			20.005,
			20.049,
			20.114,
			20.176,
			20.218,
			20.235,
			20.299,
			20.329,
			20.361,
			20.423,
			20.438,
			20.485,
			20.547,
			20.61,
			20.672,
			20.732,
			20.762,
			20.795,
			20.855,
			20.878,
			20.918,
			20.98,
			21.041,
			21.089,
			21.166,
			21.197,
			21.228,
			21.305,
			21.352,
			21.415,
			21.477,
			21.54,
			21.632,
			21.661,
			21.725,
			21.74,
			21.788,
			21.849,
			21.957,
			22.035,
			22.096,
			22.173,
			22.221,
			22.282,
			22.346,
			22.408,
			22.468,
			22.53,
			22.591,
			22.611,
			22.652,
			22.717,
			22.78,
			22.826,
			22.841,
			22.902,
			22.938,
			22.965,
			23.043,
			23.09,
			23.152,
			23.211,
			23.275,
			23.338,
			23.368,
			23.399,
			23.477,
			23.522,
			23.584,
			23.692,
			23.709,
			23.802,
			23.833,
			23.91,
			23.958,
			24.018,
			24.082,
			24.143,
			24.205,
			24.236,
			24.266,
			24.344,
			24.391,
			24.453,
			24.515,
			24.578,
			24.639,
			24.669,
			24.701,
			24.78,
			24.826,
			24.888,
			25.001,
			25.012,
			25.104,
			25.136,
			25.197,
			25.213,
			25.26,
			25.322,
			25.384,
			25.446,
			25.508,
			25.538,
			25.568,
			25.646,
			25.697,
			25.755,
			25.817,
			25.863,
			25.941,
			25.973,
			26.002,
			26.066,
			26.127,
			26.191,
			26.251,
			26.314,
			26.405,
			26.438,
			26.515,
			26.563,
			26.622,
			26.685,
			26.732,
			26.811,
			26.843,
			26.872,
			26.949,
			26.994,
			27.058,
			27.119,
			27.182,
			27.244,
			27.277,
			27.306,
			27.368,
			27.384,
			27.43,
			27.491,
			27.552,
			27.616,
			27.709,
			27.741,
			27.818,
			27.865,
			27.925,
			27.988,
			28.051,
			28.141,
			28.174,
			28.236,
			28.299,
			28.361,
			28.422,
			28.468,
			28.482,
			28.545,
			28.609,
			28.683,
			28.732,
			28.857,
			28.917,
			29.011,
			29.041,
			29.102,
			29.124,
			29.166,
			29.227,
			29.29,
			29.335,
			29.446,
			29.478,
			29.551,
			29.601,
			29.661,
			29.724,
			29.785,
			29.847,
			29.88,
			29.91,
			29.988,
			30.034,
			30.101,
			30.158,
			30.218,
			30.282,
			30.314,
			30.344,
			30.422,
			30.468,
			30.531,
			30.591,
			30.638,
			30.716,
			30.748,
			30.778,
			30.839,
			30.855,
			30.903,
			30.964,
			31.026,
			31.089,
			31.149,
			31.186,
			31.211,
			31.289,
			31.336,
			31.399,
			31.461,
			31.507,
			31.616,
			31.646,
			31.707,
			31.771,
			31.832,
			31.895,
			31.956,
			32.019,
			32.049,
			32.079,
			32.158,
			32.202,
			32.265,
			32.328,
			32.39,
			32.452,
			32.485,
			32.577,
			32.594,
			32.639,
			32.702,
			32.764,
			32.825,
			32.919,
			32.95,
			33.027,
			33.072,
			33.133,
			33.195,
			33.258,
			33.35,
			33.383,
			33.508,
			33.567,
			33.631,
			33.694,
			33.752,
			33.816,
			33.894,
			33.944,
			34.001,
			34.111,
			34.218,
			34.251,
			34.314,
			34.374,
			34.438,
			34.498,
			34.561,
			34.623,
			34.655,
			34.683,
			34.746,
			34.762,
			34.808,
			34.871,
			34.933,
			34.978,
			34.995,
			35.057,
			35.089,
			35.118,
			35.197,
			35.242,
			35.368,
			35.429,
			35.493,
			35.554,
			35.63,
			35.678,
			35.741,
			35.802,
			35.863,
			35.956,
			35.985,
			36.066,
			36.11,
			36.174,
			36.236,
			36.298,
			36.391,
			36.422,
			36.483,
			36.552,
			36.607,
			36.669,
			36.715,
			36.732,
			36.794,
			36.824,
			36.856,
			36.933,
			36.979,
			37.043,
			37.103,
			37.165,
			37.227,
			37.29,
			37.35,
			37.371,
			37.413,
			37.475,
			37.584,
			37.661,
			37.694,
			37.724,
			37.801,
			37.846,
			37.911,
			38.018,
			38.035,
			38.094,
			38.125,
			38.158,
			38.22,
			38.235,
			38.279,
			38.347,
			38.407,
			38.469,
			38.561,
			38.591,
			38.67,
			38.716,
			38.779,
			38.838,
			38.902,
			38.994,
			39.027,
			39.088,
			39.15,
			39.211,
			39.273,
			39.334,
			39.398,
			39.434,
			39.461,
			39.535,
			39.584,
			39.647,
			39.71,
			39.754,
			39.77,
			39.863,
			39.894,
			39.955,
			39.972,
			40.019,
			40.08,
			40.142,
			40.202,
			40.266,
			40.296,
			40.327,
			40.406,
			40.452,
			40.515,
			40.574,
			40.622,
			40.639,
			40.7,
			40.731,
			40.763,
			40.838,
			40.885,
			40.95,
			41.01,
			41.072,
			41.133,
			41.197,
			41.273,
			41.321,
			41.383,
			41.445,
			41.498,
			41.506,
			41.569,
			41.599,
			41.693,
			41.708,
			41.754,
			41.816,
			41.877,
			41.939,
			42,
			42.033,
			42.064,
			42.143,
			42.189,
			42.249,
			42.312,
			42.357,
			42.437,
			42.468,
			42.498,
			42.56,
			42.622,
			42.685,
			42.746,
			42.809,
			42.869,
			42.9,
			42.931,
			42.995,
			43.009,
			43.056,
			43.118,
			43.181,
			43.227,
			43.243,
			43.305,
			43.333,
			43.366,
			43.443,
			43.49,
			43.551,
			43.557,
			43.614,
			43.677,
			43.767,
			43.8,
			43.863,
			43.924,
			43.985,
			44.048,
			44.096,
			44.111,
			44.173,
			44.236,
			44.311,
			44.358,
			44.422,
			44.482,
			44.544,
			44.608,
			44.638,
			44.669,
			44.73,
			44.746,
			44.793,
			44.856,
			44.915,
			44.963,
			45.041,
			45.072,
			45.102,
			45.181,
			45.227,
			45.29,
			45.352,
			45.414,
			45.474,
			45.507,
			45.535,
			45.599,
			45.617,
			45.66,
			45.722,
			45.784,
			45.846,
			45.908,
			45.938,
			45.972,
			46.049,
			46.094,
			46.157,
			46.219,
			46.266,
			46.342,
			46.373,
			46.406,
			46.466,
			46.483,
			46.53,
			46.591,
			46.653,
			46.713,
			46.807,
			46.84,
			46.917,
			46.964,
			47.027,
			47.088,
			47.134,
			47.241,
			47.274,
			47.334,
			47.398,
			47.46,
			47.522,
			47.583,
			47.644,
			47.706,
			47.785,
			47.832,
			47.895,
			47.956,
			48.002,
			48.016,
			48.079,
			48.142,
			48.203,
			48.219,
			48.266,
			48.326,
			48.39,
			48.45,
			48.513,
			48.545,
			48.575,
			48.654,
			48.7,
			48.762,
			48.823,
			48.869,
			48.891,
			48.977,
			49.01,
			49.088,
			49.132,
			49.195,
			49.257,
			49.318,
			49.382,
			49.411,
			49.444,
			49.506,
			49.568,
			49.631,
			49.694,
			49.751,
			49.815,
			49.863,
			49.969,
			50.002,
			50.078,
			50.126,
			50.187,
			50.249,
			50.31,
			50.372,
			50.402,
			50.435,
			50.498,
			50.512,
			50.56,
			50.622,
			50.729,
			50.744,
			50.807,
			50.839,
			50.867,
			50.947,
			51.056,
			51.117,
			51.18,
			51.24,
			51.274,
			51.304,
			51.367,
			51.381,
			51.427,
			51.491,
			51.55,
			51.598,
			51.613,
			51.674,
			51.707,
			51.736,
			51.821,
			51.862,
			51.925,
			51.987,
			52.048,
			52.141,
			52.172,
			52.234,
			52.295,
			52.364,
			52.419,
			52.482,
			52.544,
			52.607,
			52.682,
			52.73,
			52.794,
			52.855,
			52.9,
			52.917,
			52.977,
			53.01,
			53.042,
			53.101,
			53.119,
			53.163,
			53.227,
			53.288,
			53.35,
			53.413,
			53.444,
			53.474,
			53.552,
			53.598,
			53.66,
			53.723,
			53.767,
			53.785,
			53.846,
			53.883,
			53.907,
			53.984,
			54.031,
			54.094,
			54.157,
			54.219,
			54.31,
			54.342,
			54.419,
			54.465,
			54.529,
			54.59,
			54.638,
			54.715,
			54.744,
			54.776,
			54.854,
			54.9,
			54.963,
			55.026,
			55.084,
			55.149,
			55.211,
			55.272,
			55.289,
			55.336,
			55.397,
			55.458,
			55.506,
			55.582,
			55.644,
			55.769,
			55.831,
			55.894,
			55.956,
			56.016,
			56.08,
			56.141,
			56.156,
			56.202,
			56.266,
			56.327,
			56.372,
			56.39,
			56.449,
			56.482,
			56.512,
			56.59,
			56.698,
			56.76,
			56.822,
			56.883,
			56.917,
			56.948,
			57.01,
			57.071,
			57.135,
			57.194,
			57.241,
			57.257,
			57.317,
			57.382,
			57.459,
			57.505,
			57.566,
			57.629,
			57.694,
			57.751,
			57.785,
			57.816,
			57.877,
			57.894,
			57.938,
			58.005,
			58.062,
			58.127,
			58.188,
			58.219,
			58.248,
			58.325,
			58.373,
			58.433,
			58.497,
			58.558,
			58.622,
			58.684,
			58.745,
			58.808,
			58.869,
			58.93,
			58.994,
			59.056,
			59.087,
			59.117,
			59.195,
			59.243,
			59.304,
			59.413,
			59.488,
			59.552,
			59.612,
			59.674,
			59.738,
			59.799,
			59.86,
			59.955,
			59.985,
			60.048,
			60.068,
			60.11,
			60.172,
			60.234,
			60.28,
			60.357,
			60.417,
			60.48,
			60.544,
			60.606,
			60.668,
			60.729,
			60.794,
			60.852,
			60.932,
			60.979,
			61.039,
			61.103,
			61.163,
			61.225,
			61.257,
			61.287,
			61.366,
			61.41,
			61.477,
			61.534,
			61.597,
			61.66,
			61.722,
			61.784,
			61.844,
			61.908,
			62.017,
			62.032,
			62.095,
			62.132,
			62.232,
			62.28,
			62.343,
			62.401,
			62.465,
			62.527,
			62.558,
			62.591,
			62.666,
			62.716,
			62.778,
			62.838,
			62.885,
			62.962,
			62.994,
			63.023,
			63.101,
			63.149,
			63.209,
			63.273,
			63.334,
			63.397,
			63.427,
			63.457,
			63.519,
			63.535,
			63.58,
			63.643,
			63.752,
			63.768,
			63.83,
			63.861,
			63.892,
			63.969,
			64.017,
			64.078,
			64.141,
			64.202,
			64.263,
			64.296,
			64.327,
			64.404,
			64.451,
			64.511,
			64.575,
			64.635,
			64.73,
			64.761,
			64.838,
			64.883,
			64.947,
			65.008,
			65.069,
			65.131,
			65.163,
			65.194,
			65.256,
			65.317,
			65.38,
			65.443,
			65.505,
			65.565,
			65.596,
			65.628,
			65.706,
			65.75,
			65.815,
			65.876,
			65.923,
			65.938,
			66.001,
			66.031,
			66.063,
			66.124,
			66.141,
			66.185,
			66.252,
			66.31,
			66.372,
			66.435,
			66.465,
			66.498,
			66.574,
			66.622,
			66.684,
			66.745,
			66.79,
			66.807,
			66.869,
			66.899,
			66.931,
			66.993,
			67.008,
			67.055,
			67.119,
			67.177,
			67.244,
			67.301,
			67.334,
			67.364,
			67.443,
			67.488,
			67.55,
			67.611,
			67.658,
			67.737,
			67.799,
			67.86,
			67.923,
			67.984,
			68.048,
			68.107,
			68.171,
			68.2,
			68.232,
			68.294,
			68.316,
			68.358,
			68.417,
			68.48,
			68.527,
			68.543,
			68.606,
			68.635,
			68.666,
			68.727,
			68.744,
			68.793,
			68.852,
			68.914,
			68.975,
			69.039,
			69.07,
			69.1,
			69.177,
			69.226,
			69.287,
			69.396,
			69.41,
			69.474,
			69.535,
			69.596,
			69.658,
			69.722,
			69.782,
			69.845,
			69.908,
			69.968,
			70.048,
			70.093,
			70.157,
			70.217,
			70.264,
			70.28,
			70.377,
			70.404,
			70.48,
			70.528,
			70.59,
			70.651,
			70.713,
			70.777,
			70.807,
			70.838,
			70.898,
			70.916,
			70.962,
			71.029,
			71.084,
			71.133,
			71.208,
			71.24,
			71.272,
			71.348,
			71.394,
			71.457,
			71.517,
			71.582,
			71.643,
			71.674,
			71.706,
			71.783,
			71.829,
			71.891,
			71.952,
			71.999,
			72.016,
			72.077,
			72.107,
			72.139,
			72.216,
			72.264,
			72.388,
			72.448,
			72.513,
			72.544,
			72.574,
			72.637,
			72.698,
			72.761,
			72.822,
			72.869,
			72.883,
			72.947,
			73.007,
			73.085,
			73.133,
			73.194,
			73.257,
			73.319,
			73.38,
			73.41,
			73.443,
			73.519,
			73.566,
			73.629,
			73.689,
			73.752,
			73.843,
			73.874,
			73.951,
			74,
			74.063,
			74.124,
			74.17,
			74.186,
			74.279,
			74.31,
			74.372,
			74.388,
			74.434,
			74.502,
			74.558,
			74.62,
			74.682,
			74.713,
			74.745,
			74.822,
			74.869,
			74.931,
			75.039,
			75.147,
			75.178,
			75.239,
			75.303,
			75.364,
			75.426,
			75.489,
			75.549,
			75.581,
			75.613,
			75.69,
			75.735,
			75.799,
			75.861,
			75.923,
			75.984,
			76.014,
			76.045,
			76.108,
			76.171,
			76.232,
			76.295,
			76.355,
			76.419,
			76.448,
			76.481,
			76.543,
			76.564,
			76.604,
			76.665,
			76.728,
			76.776,
			76.79,
			76.852,
			76.884,
			76.913,
			76.977,
			76.991,
			77.038,
			77.102,
			77.163,
			77.226,
			77.284,
			77.318,
			77.35,
			77.41,
			77.472,
			77.534,
			77.596,
			77.643,
			77.658,
			77.72,
			77.75,
			77.783,
			77.861,
			77.907,
			77.967,
			78.036
		]
	],
	'2866_2867_7982': [
		[
			91.287
		],
		[
			0
		],
		[
			0,
			1.153,
			3.841,
			7.885,
			10.589,
			13.3,
			14.659,
			17.38,
			21.474,
			24.212,
			25.584,
			28.332,
			31.086,
			33.848,
			38.001,
			40.779,
			43.562,
			44.956,
			49.147,
			51.95,
			54.76,
			58.986,
			61.812,
			66.063,
			68.904,
			73.177,
			76.054,
			80.31,
			83.163,
			87.446,
			91.74,
			94.61,
			98.925,
			101.808,
			104.697,
			110.492,
			111.945,
			116.313,
			117.772,
			119.233,
			123.623,
			126.559,
			129.501,
			133.925,
			136.883,
			141.33,
			142.816,
			144.303,
			148.774,
			151.763,
			156.257,
			159.26,
			163.776,
			166.794,
			169.817,
			171.331,
			175.881,
			178.922,
			181.969,
			186.55,
			189.611,
			194.213,
			197.288,
			201.911,
			205,
			209.643,
			212.746,
			215.854,
			220.527,
			222.087,
			223.649,
			229.907,
			231.475,
			236.187,
			239.335,
			244.065,
			247.225,
			250.39,
			251.975,
			256.735,
			259.916,
			263.1,
			267.881,
			271.073,
			275.871,
			277.473,
			279.076,
			283.89,
			287.104,
			291.933,
			295.157,
			300,
			303.235,
			306.474,
			311.341,
			314.592,
			319.477,
			322.739,
			327.64,
			330.913,
			334.191,
			335.831,
			340.761,
			344.053,
			347.351,
			352.3,
			355.592,
			360.489,
			362.107,
			363.716,
			368.483,
			371.612,
			376.233,
			379.267,
			383.75,
			386.693,
			388.151,
			391.037,
			393.885,
			395.294,
			398.085,
			400.837,
			404.895,
			407.553,
			410.173,
			414.033,
			420.28,
			422.713,
			426.295,
			428.64,
			432.092,
			433.224,
			434.346,
			437.658,
			439.819,
			442.989,
			445.056,
			447.086,
			448.088,
			450.062,
			451.036,
			452.955,
			457.594,
			459.389,
			462.017,
			463.725,
			466.219,
			467.032,
			467.836,
			470.193,
			471.719,
			473.94,
			475.376,
			476.777,
			478.815,
			479.478,
			480.134,
			482.672,
			483.283,
			485.067,
			486.223,
			487.873,
			488.93,
			490.479,
			491.474,
			491.96,
			492.912,
			493.842,
			496.049,
			497.319,
			498.154,
			499.398,
			500.223,
			501.462,
			502.29,
			503.538,
			504.375,
			504.827,
			505.768,
			507.185,
			507.66,
			509.102,
			510.261,
			512.298,
			513.67,
			515.085,
			515.853,
			518.167,
			519.616,
			520.915,
			522.873,
			524.312,
			526.613,
			528.252,
			530.754,
			532.122,
			533.959,
			535.194,
			536.35,
			536.877,
			537.937,
			539.007,
			539.546,
			540.636,
			541.19,
			542.306,
			543.434,
			545.154,
			546.336,
			548.136,
			549.355,
			550.599,
			551.848,
			552.472,
			554.373,
			555.665,
			556.977,
			560.34,
			563.125,
			566.009,
			567.434,
			569.583,
			571.041,
			573.318,
			574.857,
			577.212,
			578.801,
			579.576,
			581.137,
			582.714,
			585.105,
			586.717,
			589.172,
			590.833,
			595.01,
			596.681,
			599.22,
			600.93,
			603.542,
			604.422,
			605.305,
			607.933,
			609.683,
			612.321,
			614.084,
			615.836,
			616.731,
			618.524,
			621.186,
			623.803,
			627.274,
			629.908,
			631.636,
			634.263,
			635.998,
			638.51,
			640.146,
			642.596,
			644.155,
			645.662,
			647.912,
			648.661,
			649.408,
			652.478,
			653.25,
			655.557,
			657.351,
			660.529,
			662.806,
			665.931,
			667.3,
			668.866,
			671.215,
			672.772,
			674.968,
			676.433,
			678.658,
			679.416,
			680.174,
			682.453,
			683.994,
			686.337,
			687.906,
			690.296,
			691.904,
			692.711,
			694.335,
			695.977,
			698.456,
			700.126,
			701.811,
			703.508,
			704.361,
			706.065,
			707.796,
			708.667,
			711.299,
			713.07,
			717.556,
			719.373,
			723.046,
			723.972,
			726.771,
			728.652,
			731.499,
			735.338,
			736.305,
			738.25,
			740.206,
			741.189,
			744.156,
			746.15,
			748.154,
			753.218,
			757.32,
			758.353,
			760.426,
			761.467,
			763.557,
			765.652,
			768.795,
			770.899,
			774.074,
			776.204,
			780.494,
			781.572,
			784.824,
			787.004,
			790.293,
			792.498,
			793.604,
			795.824,
			798.054,
			799.172,
			801.417,
			803.672,
			807.073,
			809.352,
			813.94,
			815.093,
			818.568,
			820.896,
			824.407,
			829.121,
			830.306,
			833.873,
			835.067,
			838.664,
			839.867,
			841.073,
			844.705,
			847.137,
			850.803,
			853.258,
			855.723,
			856.958,
			860.677,
			863.168,
			865.667,
			869.433,
			871.954,
			875.75,
			877.017,
			878.285,
			882.081,
			884.591,
			888.304,
			890.736,
			894.313,
			896.651,
			897.806,
			900.086,
			903.44,
			904.544,
			907.814,
			911.012,
			913.102,
			915.158,
			917.177,
			918.172,
			921.106,
			923.016,
			924.89,
			925.814,
			927.637,
			929.426,
			932.047,
			932.902,
			933.749,
			936.235,
			937.847,
			940.197,
			943.241,
			943.985,
			945.461,
			946.196,
			947.655,
			949.83,
			951.273,
			952.707,
			954.853,
			956.274,
			958.379,
			959.08,
			959.78,
			961.177,
			963.225,
			964.589,
			966.646,
			968.052,
			970.158,
			970.86,
			971.587,
			974.014,
			975.629,
			978.444,
			980.393,
			983.302,
			985.238,
			987.535,
			989.054,
			989.815,
			991.305,
			992.776,
			994.986,
			996.47,
			998.707,
			999.453,
			1000.201,
			1002.471,
			1003.993,
			1006.282,
			1007.799,
			1010.073,
			1011.609,
			1012.386,
			1013.164,
			1016.286,
			1017.081,
			1019.421,
			1020.976,
			1023.27,
			1024.781,
			1026.286,
			1027.037,
			1029.28,
			1030.765,
			1032.244,
			1034.446,
			1035.901,
			1038.789,
			1039.506,
			1041.647,
			1043.106,
			1045.288,
			1046.758,
			1048.973,
			1050.447,
			1052.031,
			1052.821,
			1055.201,
			1056.022,
			1058.477,
			1060.103,
			1061.695,
			1062.49,
			1065.719,
			1066.579,
			1068.296,
			1069.154,
			1070.935,
			1072.731,
			1075.406,
			1077.169,
			1080.535,
			1081.322,
			1083.675,
			1085.166,
			1087.362,
			1088.82,
			1090.207,
			1090.897,
			1092.274,
			1092.961,
			1095.719,
			1096.408,
			1097.784,
			1099.156,
			1101.206,
			1102.569,
			1104.598,
			1105.928,
			1107.935,
			1109.285,
			1110.665,
			1114.145,
			1116.335,
			1117.812,
			1120.052,
			1121.56,
			1123.849,
			1125.411,
			1127.786,
			1129.408,
			1130.247,
			1131.934,
			1133.637,
			1136.177,
			1137.888,
			1141.377,
			1142.258,
			1144.936,
			1146.741,
			1149.396,
			1151.168,
			1155.636,
			1158.393,
			1161.134,
			1162.047,
			1165.728,
			1166.65,
			1169.436,
			1171.309,
			1174.139,
			1176.041,
			1178.914,
			1181.814,
			1183.767,
			1185.736,
			1188.711,
			1190.707,
			1194.735,
			1195.75,
			1198.811,
			1200.866,
			1203.97,
			1206.058,
			1208.155,
			1209.206,
			1212.358,
			1214.465,
			1217.647,
			1218.714,
			1221.931,
			1224.083,
			1227.326,
			1229.495,
			1232.77,
			1234.971,
			1236.075,
			1238.295,
			1240.529,
			1243.904,
			1246.168,
			1249.57,
			1251.844,
			1255.297,
			1257.62,
			1261.148,
			1265.861,
			1267.035,
			1273.011,
			1275.45,
			1276.681,
			1279.149,
			1281.644,
			1285.409,
			1287.927,
			1291.733,
			1294.297,
			1298.135,
			1300.689,
			1303.196,
			1306.92,
			1309.414,
			1314.43,
			1315.689,
			1320.749,
			1322.019,
			1325.843,
			1328.402,
			1332.258,
			1336.132,
			1338.724,
			1342.629,
			1345.237,
			1347.846,
			1354.396,
			1358.349,
			1360.992,
			1364.97,
			1367.631,
			1371.635,
			1374.313,
			1376.999,
			1381.039,
			1383.741,
			1389.167,
			1390.527,
			1394.619,
			1397.356,
			1400.099,
			1401.473,
			1404.227,
			1408.37,
			1412.528,
			1415.308,
			1418.095,
			1422.288,
			1425.092,
			1429.31,
			1430.719,
			1432.13,
			1436.372,
			1439.208,
			1443.475,
			1446.328,
			1449.187,
			1450.618,
			1454.924,
			1457.801,
			1462.13,
			1465.024,
			1467.924,
			1472.286,
			1475.201,
			1481.05,
			1482.516,
			1485.453,
			1489.869,
			1492.82,
			1497.259,
			1500.225,
			1504.686,
			1507.667,
			1512.149,
			1515.145,
			1519.648,
			1522.658,
			1527.184,
			1530.208,
			1531.722,
			1534.754,
			1539.314,
			1542.36,
			1545.411,
			1549.999,
			1551.531,
			1553.064,
			1556.135,
			1559.211,
			1560.752,
			1565.379,
			1568.471,
			1571.568,
			1576.223,
			1579.333,
			1584.007,
			1585.567,
			1587.129,
			1591.821,
			1594.954,
			1599.664,
			1602.81,
			1607.537,
			1610.693,
			1612.273,
			1615.438,
			1620.193,
			1623.368,
			1626.548,
			1631.326,
			1634.516,
			1639.3,
			1640.897,
			1642.494,
			1647.293,
			1650.497,
			1655.308,
			1658.522,
			1661.739,
			1666.571,
			1669.797,
			1674.643,
			1676.26,
			1677.879,
			1682.739,
			1685.984,
			1690.858,
			1694.113,
			1699.001,
			1703.898,
			1707.166,
			1710.438,
			1715.352,
			1718.633,
			1723.561,
			1725.205,
			1726.851,
			1731.792,
			1735.091,
			1740.046,
			1743.354,
			1748.322,
			1753.298,
			1756.62,
			1761.608,
			1768.268,
			1771.602,
			1776.605,
			1779.937,
			1784.914,
			1788.207,
			1789.843,
			1793.092,
			1797.895,
			1801.051,
			1804.194,
			1810.294,
			1811.806,
			1816.29,
			1819.259,
			1823.614,
			1826.485,
			1830.759,
			1833.568,
			1834.981,
			1837.799,
			1842.031,
			1844.829,
			1847.623,
			1851.812,
			1854.597,
			1858.766,
			1860.152,
			1861.536,
			1865.684,
			1868.452,
			1871.225,
			1875.404,
			1878.195,
			1882.389,
			1885.193,
			1889.403,
			1892.216,
			1896.445,
			1899.261,
			1903.468,
			1906.256,
			1907.648,
			1910.428,
			1914.591,
			1917.364,
			1920.142,
			1924.328,
			1925.734,
			1927.141,
			1932.797,
			1934.217,
			1938.497,
			1941.36,
			1944.232,
			1948.553,
			1951.415,
			1957.203,
			1958.649,
			1964.441,
			1965.891,
			1970.254,
			1973.165,
			1976.048,
			1977.502,
			1980.405,
			1984.785,
			1989.138,
			1992.033,
			1994.923,
			1999.248,
			2002.119,
			2006.417,
			2007.848,
			2009.277,
			2013.551,
			2016.388,
			2020.632,
			2023.455,
			2026.276,
			2027.687,
			2030.508,
			2031.917,
			2033.327,
			2038.966,
			2041.789,
			2044.617,
			2047.452,
			2051.719,
			2057.433,
			2058.867,
			2061.738,
			2063.178,
			2066.062,
			2068.931,
			2073.281,
			2076.188,
			2082.016,
			2083.476,
			2087.866,
			2090.8,
			2095.214,
			2098.166,
			2102.607,
			2105.575,
			2107.062,
			2110.039,
			2114.516,
			2117.51,
			2120.51,
			2126.529,
			2128.038,
			2132.573,
			2134.087,
			2135.603,
			2140.161,
			2143.206,
			2146.256,
			2150.843,
			2153.909,
			2160.061,
			2161.603,
			2166.249,
			2169.356,
			2174.03,
			2177.153,
			2180.278,
			2181.841,
			2188.087,
			2189.642,
			2194.277,
			2197.329,
			2200.343,
			2204.793,
			2207.712,
			2212.02,
			2213.437,
			2214.844,
			2219.009,
			2221.737,
			2225.758,
			2228.393,
			2230.989,
			2234.814,
			2236.072,
			2237.32,
			2242.228,
			2243.434,
			2247.004,
			2249.342,
			2252.802,
			2257.27,
			2258.366,
			2260.536,
			2263.751,
			2265.881,
			2269.07,
			2271.223,
			2274.512,
			2276.712,
			2280.07,
			2282.328,
			2285.747,
			2288.029,
			2291.443,
			2293.709,
			2294.836,
			2297.081,
			2299.313,
			2300.423,
			2301.527,
			2304.81,
			2306.966,
			2310.142,
			2312.22,
			2314.271,
			2315.294,
			2318.334,
			2320.348,
			2323.364,
			2327.411,
			2330.477,
			2332.546,
			2335.67,
			2337.767,
			2340.924,
			2343.034,
			2346.196,
			2348.294,
			2349.34,
			2351.424,
			2354.511,
			2356.552,
			2358.585,
			2360.606,
			2362.62,
			2363.625,
			2365.628,
			2367.626,
			2368.626,
			2371.614,
			2373.594,
			2375.562,
			2378.501,
			2380.442,
			2383.34,
			2384.298,
			2385.254,
			2388.114,
			2390.015,
			2392.857,
			2394.749,
			2396.637,
			2397.581,
			2399.47,
			2400.415,
			2402.303,
			2405.137,
			2407.026,
			2408.915,
			2411.745,
			2413.63,
			2416.45,
			2417.388,
			2418.327,
			2421.128,
			2422.988,
			2425.769,
			2427.612,
			2429.453,
			2430.372,
			2432.203,
			2433.117,
			2434.03,
			2437.686,
			2438.598,
			2441.336,
			2443.159,
			2445.888,
			2447.703,
			2449.515,
			2450.42,
			2452.227,
			2454.931,
			2456.729,
			2459.415,
			2461.203,
			2464.771,
			2465.661,
			2468.336,
			2470.116,
			2472.793,
			2477.262,
			2479.053,
			2479.949,
			2481.745,
			2483.55,
			2484.453,
			2485.355,
			2488.073,
			2489.885,
			2492.608,
			2494.423,
			2497.143,
			2499.857,
			2501.663,
			2503.477,
			2506.19,
			2508.009,
			2510.757,
			2511.671,
			2512.59,
			2515.404,
			2517.277,
			2520.156,
			2522.08,
			2524.995,
			2526.948,
			2527.929,
			2529.904,
			2532.878,
			2534.862,
			2536.85,
			2538.852,
			2540.878,
			2541.892,
			2546.995,
			2550.071,
			2552.121,
			2554.166,
			2557.189,
			2559.199,
			2562.149,
			2563.131,
			2564.112,
			2567.925,
			2568.875,
			2571.686,
			2575.402,
			2576.325,
			2578.168,
			2579.089,
			2580.93,
			2583.689,
			2585.53,
			2587.375,
			2590.146,
			2592.003,
			2594.792,
			2595.724,
			2596.658,
			2598.53,
			2599.467,
			2601.346,
			2603.233,
			2606.074,
			2607.979,
			2610.851,
			2611.812,
			2612.775,
			2616.651,
			2617.625,
			2620.564,
			2622.535,
			2625.511,
			2627.49,
			2629.497,
			2630.504,
			2632.525,
			2635.579,
			2637.627,
			2640.721,
			2642.798,
			2645.934,
			2646.985,
			2648.039,
			2651.216,
			2653.348,
			2656.565,
			2658.724,
			2661.981,
			2664.166,
			2667.462,
			2670.783,
			2673.01,
			2675.248,
			2677.496,
			2679.755,
			2680.888,
			2683.163,
			2686.594,
			2690.048,
			2692.362,
			2694.688,
			2698.194,
			2700.544,
			2705.273,
			2706.461,
			2710.038,
			2712.435,
			2716.043,
			2718.449,
			2722.031,
			2724.387,
			2727.855,
			2731.237,
			2733.444,
			2735.613,
			2740.858,
			2743.892,
			2744.885,
			2745.869,
			2748.767,
			2750.656,
			2752.513,
			2755.254,
			2757.032,
			2759.641,
			2760.495,
			2761.343,
			2763.839,
			2765.465,
			2767.865,
			2769.444,
			2771.013,
			2771.797,
			2773.364,
			2775.718,
			2777.297,
			2778.089,
			2779.673,
			2781.26,
			2783.655,
			2785.251,
			2787.651,
			2788.452,
			2789.253,
			2791.652,
			2793.253,
			2795.651,
			2798.841,
			2802.014,
			2802.805,
			2806.75,
			2809.106,
			2810.669,
			2813.001,
			2814.566,
			2816.124,
			2816.899,
			2819.226,
			2820.777,
			2822.323,
			2824.639,
			2826.187,
			2828.501,
			2830.047,
			2832.373,
			2833.922,
			2836.255,
			2837.812,
			2840.15,
			2841.713,
			2842.495,
			2844.059,
			2846.427,
			2847.218,
			2849.593,
			2852.802,
			2853.606,
			2855.213,
			2856.854,
			2857.679,
			2861.812,
			2863.486,
			2865.993,
			2867.673,
			2870.192,
			2871.03,
			2871.866,
			2874.344,
			2875.991,
			2878.447,
			2880.074,
			2881.698,
			2882.498,
			2884.86,
			2886.427,
			2888.738,
			2890.256,
			2891.768,
			2893.27,
			2895.491,
			2896.963,
			2898.422,
			2899.142,
			2900.578,
			2901.294,
			2902.723,
			2904.138,
			2906.244,
			2907.644,
			2909.74,
			2911.135,
			2913.224,
			2914.624,
			2916.723,
			2918.117,
			2920.213,
			2921.615,
			2922.314,
			2923.712,
			2925.829,
			2927.25,
			2928.676,
			2930.824,
			2932.282,
			2935.231,
			2935.982,
			2938.257,
			2939.79,
			2942.125,
			2943.699,
			2945.287,
			2947.702,
			2948.514,
			2949.33,
			2951.797,
			2952.627,
			2953.46,
			2955.98,
			2957.674,
			2960.255,
			2961.984,
			2962.852,
			2964.599,
			2967.244,
			2969.012,
			2970.807,
			2973.524,
			2975.354,
			2978.137,
			2980.003,
			2982.827,
			2984.713,
			2987.606,
			2989.55,
			2991.506,
			2994.464,
			2996.454,
			2999.462,
			3002.494,
			3004.53,
			3006.579,
			3009.697,
			3011.762,
			3013.863,
			3014.918,
			3018.121,
			3022.415,
			3025.667,
			3027.835,
			3031.078,
			3032.16,
			3033.245,
			3036.512,
			3038.698,
			3041.987,
			3044.191,
			3046.404,
			3047.513,
			3049.732,
			3050.837,
			3053.053,
			3056.389,
			3058.606,
			3060.868,
			3063.116,
			3066.496,
			3068.759,
			3071.012,
			3072.171,
			3074.463,
			3077.917,
			3080.227,
			3086.033,
			3089.562,
			3091.934,
			3095.512,
			3097.912,
			3101.546,
			3107.618,
			3110.067,
			3111.295,
			3113.758,
			3116.23,
			3117.47,
			3119.955,
			3122.441,
			3126.179,
			3128.666,
			3132.441,
			3133.7,
			3134.982,
			3138.775,
			3141.312,
			3143.863,
			3147.713,
			3150.292,
			3154.179,
			3156.784,
			3160.7,
			3162.008,
			3163.317,
			3167.266,
			3169.908,
			3173.882,
			3176.535,
			3177.86,
			3180.512,
			3184.503,
			3187.17,
			3189.84,
			3193.858,
			3196.546,
			3200.596,
			3201.948,
			3203.299,
			3207.361,
			3210.082,
			3214.176,
			3216.913,
			3221.032,
			3223.782,
			3225.157,
			3227.911,
			3232.036,
			3233.422,
			3237.578,
			3240.346,
			3244.473,
			3247.191,
			3249.875,
			3251.205,
			3255.135,
			3257.704,
			3260.235,
			3263.941,
			3266.364,
			3269.912,
			3271.077,
			3272.232,
			3275.628,
			3277.843,
			3281.088,
			3283.207,
			3285.292,
			3286.321,
			3288.354,
			3289.368,
			3291.368,
			3294.342,
			3296.302,
			3298.229,
			3300.153,
			3302.997,
			3304.846,
			3307.553,
			3309.329,
			3310.204,
			3311.936,
			3313.66,
			3316.244,
			3317.97,
			3320.58,
			3322.339,
			3324.996,
			3326.784,
			3329.485,
			3331.295,
			3334.028,
			3336.762,
			3338.585,
			3340.401,
			3343.109,
			3344.897,
			3347.557,
			3349.315,
			3351.933,
			3352.802,
			3353.671,
			3356.266,
			3357.991,
			3359.715,
			3362.291,
			3364.005,
			3366.569,
			3368.271,
			3371.672,
			3372.518,
			3375.052,
			3376.737,
			3379.252,
			3380.929,
			3383.432,
			3385.099,
			3385.934,
			3387.597,
			3389.254,
			3391.741,
			3393.391,
			3395.866,
			3397.522,
			3399.993,
			3401.64,
			3404.096,
			3405.725,
			3408.205,
			3409.839,
			3411.484,
			3414.003,
			3414.836,
			3415.668,
			3418.178,
			3419.895,
			3422.465,
			3424.197,
			3426.808,
			3429.438,
			3431.198,
			3432.966,
			3435.622,
			3437.399,
			3440.86,
			3441.725,
			3444.309,
			3446.021,
			3448.596,
			3450.301,
			3452.874,
			3454.6,
			3457.203,
			3459.831,
			3461.594,
			3463.36,
			3466.903,
			3467.786,
			3470.411,
			3472.135,
			3474.694,
			3476.357,
			3477.995,
			3480.386,
			3481.935,
			3484.183,
			3484.913,
			3485.635,
			3487.746,
			3489.109,
			3491.092,
			3492.372,
			3493.636,
			3494.25,
			3496.035,
			3497.186,
			3498.311,
			3498.863,
			3499.946,
			3501.005,
			3502.557,
			3503.568,
			3505.055,
			3505.545,
			3506.034,
			3507.494,
			3508.458,
			3509.413,
			3510.84,
			3511.791,
			3513.22,
			3513.696,
			3514.171,
			3516.072,
			3516.548,
			3517.995,
			3519.026,
			3520.575,
			3521.614,
			3522.66,
			3523.252,
			3525.128,
			3526.386,
			3527.654,
			3529.876,
			3531.384,
			3533.667,
			3534.431,
			3535.195,
			3537.487,
			3538.919,
			3540.993,
			3542.369,
			3544.258,
			3545.462,
			3546.062,
			3546.662,
			3548.95,
			3549.49,
			3551.098,
			3553.223,
			3553.739,
			3555.789,
			3556.299,
			3557.83,
			3558.87,
			3559.908,
			3561.46,
			3562.492,
			3564.092,
			3564.631,
			3565.171,
			3566.793,
			3567.876,
			3569.544,
			3570.659,
			3572.34,
			3574.039,
			3575.187,
			3576.933,
			3578.106,
			3579.304,
			3580.517,
			3582.367,
			3584.262,
			3584.887,
			3585.527,
			3586.817,
			3588.777,
			3590.1,
			3592.114,
			3593.476,
			3596.249,
			3596.952,
			3599.086,
			3600.531,
			3602.727,
			3604.212,
			3605.714,
			3606.471,
			3607.996,
			3610.316,
			3611.882,
			3614.261,
			3615.866,
			3618.302,
			3619.945,
			3622.436,
			3624.114,
			3626.643,
			3628.329,
			3630.026,
			3632.599,
			3634.331,
			3636.952,
			3638.716,
			3641.387,
			3642.283,
			3643.183,
			3645.905,
			3647.735,
			3650.506,
			3652.369,
			3654.246,
			3655.189,
			3657.085,
			3658.038,
			3659.953,
			3661.881,
			3665.775,
			3666.757,
			3669.719,
			3670.712,
			3671.709,
			3674.717,
			3676.737,
			3679.792,
			3681.844,
			3683.907,
			3687.021,
			3688.065,
			3689.112,
			3692.254,
			3693.301,
			3694.351,
			3697.512,
			3699.633,
			3701.765,
			3702.834,
			3707.139,
			3708.222,
			3711.486,
			3713.676,
			3715.875,
			3719.193,
			3721.417,
			3724.774,
			3725.898,
			3727.025,
			3730.421,
			3732.697,
			3736.13,
			3738.431,
			3740.742,
			3741.901,
			3745.394,
			3747.734,
			3751.263,
			3753.627,
			3756.001,
			3759.581,
			3761.979,
			3766.804,
			3768.016,
			3771.665,
			3774.11,
			3776.564,
			3780.262,
			3782.737,
			3786.466,
			3787.713,
			3788.962,
			3792.721,
			3795.237,
			3799.026,
			3801.563,
			3805.382,
			3807.938,
			3811.786,
			3814.361,
			3815.651,
			3818.237,
			3820.831,
			3824.735,
			3826.038,
			3827.339,
			3831.254,
			3832.563,
			3833.873,
			3837.814,
			3840.45,
			3843.093,
			3847.069,
			3849.729,
			3853.729,
			3855.066,
			3856.405,
			3859.92
		],
		[
			0,
			0.029,
			0.074,
			0.139,
			0.199,
			0.245,
			0.261,
			0.324,
			0.385,
			0.448,
			0.462,
			0.51,
			0.57,
			0.633,
			0.695,
			0.756,
			0.789,
			0.82,
			0.903,
			0.943,
			1.006,
			1.066,
			1.112,
			1.191,
			1.253,
			1.314,
			1.378,
			1.44,
			1.501,
			1.565,
			1.657,
			1.687,
			1.764,
			1.812,
			1.875,
			1.983,
			1.998,
			2.06,
			2.09,
			2.12,
			2.201,
			2.245,
			2.309,
			2.368,
			2.43,
			2.495,
			2.524,
			2.556,
			2.617,
			2.678,
			2.743,
			2.803,
			2.865,
			2.926,
			2.965,
			2.99,
			3.068,
			3.115,
			3.174,
			3.238,
			3.301,
			3.362,
			3.424,
			3.487,
			3.547,
			3.61,
			3.672,
			3.719,
			3.797,
			3.828,
			3.857,
			3.935,
			3.982,
			4.044,
			4.105,
			4.169,
			4.23,
			4.261,
			4.292,
			4.369,
			4.416,
			4.481,
			4.541,
			4.602,
			4.664,
			4.695,
			4.727,
			4.804,
			4.849,
			4.912,
			4.975,
			5.036,
			5.097,
			5.159,
			5.222,
			5.284,
			5.346,
			5.408,
			5.471,
			5.533,
			5.562,
			5.594,
			5.671,
			5.717,
			5.78,
			5.841,
			5.886,
			5.967,
			5.998,
			6.028,
			6.105,
			6.153,
			6.214,
			6.277,
			6.336,
			6.4,
			6.432,
			6.461,
			6.525,
			6.541,
			6.588,
			6.647,
			6.71,
			6.773,
			6.833,
			6.897,
			7.02,
			7.082,
			7.144,
			7.208,
			7.27,
			7.299,
			7.33,
			7.408,
			7.456,
			7.517,
			7.577,
			7.625,
			7.641,
			7.702,
			7.733,
			7.764,
			7.886,
			7.95,
			8.012,
			8.076,
			8.138,
			8.169,
			8.198,
			8.277,
			8.323,
			8.385,
			8.447,
			8.494,
			8.57,
			8.603,
			8.633,
			8.711,
			8.758,
			8.819,
			8.88,
			8.944,
			9.003,
			9.066,
			9.127,
			9.15,
			9.191,
			9.253,
			9.36,
			9.439,
			9.5,
			9.562,
			9.624,
			9.686,
			9.747,
			9.81,
			9.871,
			9.903,
			9.935,
			10.013,
			10.059,
			10.12,
			10.183,
			10.244,
			10.307,
			10.336,
			10.37,
			10.447,
			10.494,
			10.554,
			10.618,
			10.678,
			10.742,
			10.802,
			10.866,
			10.927,
			10.989,
			11.052,
			11.097,
			11.113,
			11.18,
			11.216,
			11.24,
			11.299,
			11.329,
			11.361,
			11.423,
			11.486,
			11.548,
			11.61,
			11.669,
			11.734,
			11.763,
			11.794,
			11.874,
			11.92,
			11.982,
			12.09,
			12.199,
			12.292,
			12.353,
			12.416,
			12.477,
			12.54,
			12.602,
			12.662,
			12.725,
			12.742,
			12.785,
			12.85,
			12.913,
			12.974,
			13.037,
			13.097,
			13.221,
			13.284,
			13.345,
			13.407,
			13.47,
			13.501,
			13.531,
			13.608,
			13.656,
			13.72,
			13.78,
			13.827,
			13.841,
			13.903,
			13.966,
			14.044,
			14.152,
			14.214,
			14.276,
			14.337,
			14.399,
			14.462,
			14.524,
			14.586,
			14.647,
			14.695,
			14.772,
			14.804,
			14.834,
			14.91,
			14.959,
			15.02,
			15.081,
			15.145,
			15.207,
			15.237,
			15.267,
			15.329,
			15.392,
			15.454,
			15.517,
			15.563,
			15.64,
			15.672,
			15.701,
			15.78,
			15.826,
			15.889,
			15.95,
			16.012,
			16.075,
			16.106,
			16.136,
			16.197,
			16.26,
			16.323,
			16.384,
			16.429,
			16.447,
			16.507,
			16.54,
			16.57,
			16.648,
			16.695,
			16.817,
			16.879,
			16.974,
			17.004,
			17.083,
			17.13,
			17.19,
			17.297,
			17.314,
			17.376,
			17.414,
			17.436,
			17.517,
			17.563,
			17.626,
			17.748,
			17.842,
			17.874,
			17.934,
			17.948,
			17.995,
			18.058,
			18.119,
			18.166,
			18.244,
			18.306,
			18.385,
			18.431,
			18.493,
			18.554,
			18.617,
			18.679,
			18.708,
			18.741,
			18.802,
			18.817,
			18.865,
			18.928,
			18.989,
			19.052,
			19.144,
			19.176,
			19.253,
			19.298,
			19.362,
			19.477,
			19.485,
			19.577,
			19.608,
			19.671,
			19.687,
			19.731,
			19.794,
			19.857,
			19.918,
			19.98,
			20.012,
			20.041,
			20.12,
			20.167,
			20.23,
			20.291,
			20.337,
			20.414,
			20.444,
			20.478,
			20.553,
			20.601,
			20.662,
			20.724,
			20.786,
			20.848,
			20.879,
			20.911,
			20.989,
			21.033,
			21.102,
			21.159,
			21.222,
			21.283,
			21.312,
			21.346,
			21.424,
			21.47,
			21.531,
			21.536,
			21.594,
			21.655,
			21.718,
			21.749,
			21.778,
			21.841,
			21.903,
			21.966,
			22.074,
			22.089,
			22.151,
			22.181,
			22.212,
			22.291,
			22.337,
			22.399,
			22.461,
			22.523,
			22.585,
			22.616,
			22.648,
			22.708,
			22.769,
			22.833,
			22.894,
			22.941,
			23.018,
			23.05,
			23.082,
			23.159,
			23.205,
			23.267,
			23.328,
			23.39,
			23.453,
			23.514,
			23.577,
			23.597,
			23.639,
			23.701,
			23.763,
			23.825,
			23.887,
			23.917,
			23.95,
			24.027,
			24.074,
			24.136,
			24.198,
			24.26,
			24.32,
			24.352,
			24.382,
			24.46,
			24.507,
			24.572,
			24.631,
			24.694,
			24.757,
			24.787,
			24.816,
			24.894,
			24.941,
			25.005,
			25.066,
			25.113,
			25.219,
			25.25,
			25.313,
			25.377,
			25.436,
			25.499,
			25.562,
			25.624,
			25.661,
			25.687,
			25.763,
			25.809,
			25.87,
			25.933,
			25.98,
			25.995,
			26.086,
			26.12,
			26.18,
			26.196,
			26.243,
			26.307,
			26.366,
			26.43,
			26.521,
			26.553,
			26.63,
			26.679,
			26.74,
			26.803,
			26.847,
			26.863,
			26.926,
			26.956,
			27.048,
			27.066,
			27.111,
			27.175,
			27.237,
			27.297,
			27.36,
			27.423,
			27.499,
			27.547,
			27.609,
			27.72,
			27.794,
			27.856,
			27.933,
			27.979,
			28.042,
			28.103,
			28.166,
			28.227,
			28.258,
			28.292,
			28.352,
			28.413,
			28.476,
			28.583,
			28.601,
			28.661,
			28.725,
			28.801,
			28.847,
			28.971,
			29.034,
			29.129,
			29.158,
			29.235,
			29.283,
			29.344,
			29.407,
			29.467,
			29.53,
			29.592,
			29.67,
			29.717,
			29.783,
			29.841,
			29.902,
			29.995,
			30.027,
			30.103,
			30.149,
			30.214,
			30.274,
			30.32,
			30.336,
			30.429,
			30.461,
			30.539,
			30.583,
			30.646,
			30.708,
			30.771,
			30.833,
			30.893,
			30.955,
			30.97,
			31.017,
			31.08,
			31.143,
			31.203,
			31.267,
			31.33,
			31.407,
			31.452,
			31.514,
			31.621,
			31.64,
			31.763,
			31.825,
			31.847,
			31.885,
			31.95,
			32.012,
			32.074,
			32.135,
			32.197,
			32.275,
			32.321,
			32.383,
			32.444,
			32.492,
			32.599,
			32.63,
			32.71,
			32.753,
			32.816,
			32.88,
			32.942,
			33.033,
			33.066,
			33.143,
			33.19,
			33.25,
			33.361,
			33.436,
			33.498,
			33.576,
			33.623,
			33.685,
			33.747,
			33.808,
			33.87,
			33.932,
			34.01,
			34.057,
			34.119,
			34.182,
			34.228,
			34.244,
			34.306,
			34.367,
			34.446,
			34.491,
			34.554,
			34.614,
			34.678,
			34.739,
			34.769,
			34.8,
			34.877,
			34.925,
			34.988,
			35.049,
			35.094,
			35.112,
			35.203,
			35.237,
			35.311,
			35.359,
			35.419,
			35.484,
			35.545,
			35.637,
			35.67,
			35.731,
			35.794,
			35.857,
			35.917,
			35.967,
			36.042,
			36.103,
			36.18,
			36.227,
			36.29,
			36.352,
			36.414,
			36.475,
			36.507,
			36.536,
			36.616,
			36.663,
			36.724,
			36.786,
			36.833,
			36.846,
			36.908,
			36.942,
			36.973,
			37.048,
			37.095,
			37.159,
			37.219,
			37.267,
			37.345,
			37.375,
			37.406,
			37.466,
			37.53,
			37.594,
			37.652,
			37.716,
			37.776,
			37.808,
			37.841,
			37.917,
			37.963,
			38.03,
			38.086,
			38.135,
			38.212,
			38.243,
			38.277,
			38.351,
			38.396,
			38.46,
			38.523,
			38.584,
			38.647,
			38.708,
			38.77,
			38.785,
			38.831,
			38.899,
			38.956,
			39.018,
			39.079,
			39.142,
			39.22,
			39.265,
			39.326,
			39.39,
			39.451,
			39.513,
			39.544,
			39.576,
			39.636,
			39.7,
			39.761,
			39.825,
			39.885,
			39.979,
			40.01,
			40.093,
			40.197,
			40.258,
			40.322,
			40.383,
			40.443,
			40.507,
			40.524,
			40.567,
			40.635,
			40.692,
			40.739,
			40.846,
			40.878,
			40.957,
			41.002,
			41.065,
			41.126,
			41.19,
			41.249,
			41.28,
			41.313,
			41.39,
			41.436,
			41.498,
			41.56,
			41.621,
			41.685,
			41.717,
			41.746,
			41.824,
			41.872,
			41.933,
			41.994,
			42.041,
			42.119,
			42.18,
			42.243,
			42.306,
			42.366,
			42.428,
			42.491,
			42.552,
			42.582,
			42.616,
			42.693,
			42.737,
			42.802,
			42.863,
			42.908,
			42.925,
			43.018,
			43.048,
			43.109,
			43.174,
			43.233,
			43.297,
			43.358,
			43.452,
			43.482,
			43.56,
			43.607,
			43.669,
			43.73,
			43.777,
			43.794,
			43.854,
			43.915,
			43.994,
			44.042,
			44.103,
			44.166,
			44.227,
			44.288,
			44.32,
			44.351,
			44.428,
			44.475,
			44.536,
			44.6,
			44.645,
			44.661,
			44.723,
			44.754,
			44.784,
			44.864,
			44.91,
			44.971,
			45.032,
			45.094,
			45.189,
			45.219,
			45.281,
			45.297,
			45.344,
			45.404,
			45.467,
			45.514,
			45.623,
			45.655,
			45.731,
			45.777,
			45.841,
			45.901,
			45.964,
			46.025,
			46.055,
			46.086,
			46.163,
			46.21,
			46.277,
			46.38,
			46.398,
			46.467,
			46.496,
			46.528,
			46.597,
			46.646,
			46.708,
			46.768,
			46.83,
			46.925,
			46.956,
			47.018,
			47.079,
			47.141,
			47.202,
			47.25,
			47.266,
			47.359,
			47.389,
			47.466,
			47.512,
			47.575,
			47.638,
			47.698,
			47.762,
			47.792,
			47.821,
			47.902,
			47.947,
			48.011,
			48.07,
			48.119,
			48.196,
			48.227,
			48.259,
			48.341,
			48.382,
			48.445,
			48.507,
			48.567,
			48.659,
			48.692,
			48.755,
			48.815,
			48.879,
			48.938,
			48.986,
			49.064,
			49.127,
			49.205,
			49.248,
			49.313,
			49.375,
			49.436,
			49.499,
			49.528,
			49.56,
			49.623,
			49.636,
			49.682,
			49.746,
			49.807,
			49.871,
			49.931,
			49.964,
			49.994,
			50.07,
			50.118,
			50.184,
			50.289,
			50.366,
			50.427,
			50.506,
			50.552,
			50.616,
			50.677,
			50.736,
			50.801,
			50.831,
			50.861,
			50.94,
			50.986,
			51.05,
			51.109,
			51.154,
			51.172,
			51.233,
			51.265,
			51.296,
			51.375,
			51.42,
			51.483,
			51.545,
			51.605,
			51.667,
			51.7,
			51.729,
			51.793,
			51.854,
			51.916,
			51.978,
			52.025,
			52.04,
			52.101,
			52.133,
			52.163,
			52.243,
			52.29,
			52.351,
			52.411,
			52.475,
			52.536,
			52.567,
			52.597,
			52.66,
			52.723,
			52.785,
			52.848,
			52.894,
			52.91,
			52.97,
			53.002,
			53.034,
			53.111,
			53.157,
			53.218,
			53.282,
			53.341,
			53.406,
			53.436,
			53.467,
			53.53,
			53.592,
			53.654,
			53.715,
			53.778,
			53.869,
			53.902,
			53.978,
			54.025,
			54.088,
			54.21,
			54.271,
			54.304,
			54.335,
			54.396,
			54.412,
			54.458,
			54.526,
			54.583,
			54.644,
			54.707,
			54.77,
			54.846,
			54.893,
			54.956,
			55.018,
			55.08,
			55.141,
			55.172,
			55.203,
			55.279,
			55.328,
			55.39,
			55.452,
			55.513,
			55.575,
			55.604,
			55.636,
			55.713,
			55.76,
			55.824,
			55.884,
			55.932,
			55.946,
			56.069,
			56.149,
			56.194,
			56.259,
			56.32,
			56.38,
			56.444,
			56.475,
			56.505,
			56.59,
			56.627,
			56.691,
			56.8,
			56.814,
			56.877,
			56.909,
			56.939,
			57.016,
			57.064,
			57.125,
			57.187,
			57.25,
			57.311,
			57.342,
			57.374,
			57.434,
			57.451,
			57.496,
			57.559,
			57.62,
			57.668,
			57.745,
			57.776,
			57.807,
			57.884,
			57.93,
			57.994,
			58.053,
			58.116,
			58.179,
			58.21,
			58.241,
			58.303,
			58.365,
			58.427,
			58.488,
			58.553,
			58.613,
			58.648,
			58.676,
			58.753,
			58.8,
			58.863,
			58.924,
			58.986,
			59.047,
			59.11,
			59.186,
			59.234,
			59.295,
			59.359,
			59.403,
			59.421,
			59.483,
			59.544,
			59.621,
			59.666,
			59.73,
			59.791,
			59.853,
			59.947,
			59.977,
			60.04,
			60.1,
			60.163,
			60.225,
			60.287,
			60.35,
			60.41,
			60.49,
			60.536,
			60.599,
			60.713,
			60.785,
			60.814,
			60.845,
			60.923,
			60.97,
			61.033,
			61.094,
			61.156,
			61.217,
			61.249,
			61.281,
			61.359,
			61.402,
			61.467,
			61.527,
			61.575,
			61.59,
			61.653,
			61.714,
			61.776,
			61.792,
			61.837,
			61.901,
			61.961,
			62.025,
			62.086,
			62.117,
			62.148,
			62.21,
			62.273,
			62.336,
			62.442,
			62.55,
			62.583,
			62.708,
			62.775,
			62.83,
			62.892,
			62.954,
			62.985,
			63.017,
			63.094,
			63.14,
			63.203,
			63.264,
			63.328,
			63.386,
			63.449,
			63.512,
			63.574,
			63.635,
			63.699,
			63.761,
			63.821,
			63.855,
			63.885,
			63.962,
			64.009,
			64.07,
			64.177,
			64.194,
			64.257,
			64.287,
			64.319,
			64.442,
			64.503,
			64.566,
			64.629,
			64.691,
			64.721,
			64.752,
			64.814,
			64.878,
			64.937,
			65,
			65.048,
			65.061,
			65.157,
			65.186,
			65.264,
			65.31,
			65.374,
			65.433,
			65.496,
			65.559,
			65.59,
			65.62,
			65.682,
			65.698,
			65.743,
			65.808,
			65.869,
			65.93,
			65.992,
			66.053,
			66.132,
			66.179,
			66.242,
			66.305,
			66.364,
			66.427,
			66.459,
			66.49,
			66.566,
			66.611,
			66.675,
			66.737,
			66.8,
			66.896,
			66.924,
			67,
			67.047,
			67.109,
			67.172,
			67.232,
			67.294,
			67.325,
			67.357,
			67.419,
			67.436,
			67.48,
			67.544,
			67.606,
			67.667,
			67.73,
			67.759,
			67.791,
			67.869,
			67.914,
			67.977,
			68.04,
			68.086,
			68.164,
			68.226,
			68.287,
			68.35,
			68.412,
			68.473,
			68.534,
			68.597,
			68.659,
			68.719,
			68.784,
			68.845,
			68.908,
			68.97,
			69.03,
			69.063,
			69.094,
			69.169,
			69.278,
			69.34,
			69.402,
			69.466,
			69.496,
			69.527,
			69.59,
			69.652,
			69.713,
			69.777,
			69.823,
			69.836,
			69.901,
			69.931,
			69.961,
			70.04,
			70.086,
			70.149,
			70.209,
			70.27,
			70.333,
			70.366,
			70.396,
			70.458,
			70.52,
			70.582,
			70.691,
			70.767,
			70.828,
			70.907,
			70.954,
			71.019,
			71.14,
			71.203,
			71.231,
			71.265,
			71.326,
			71.341,
			71.386,
			71.45,
			71.512,
			71.574,
			71.637,
			71.668,
			71.696,
			71.775,
			71.82,
			71.883,
			71.947,
			71.992,
			72.069,
			72.132,
			72.194,
			72.211,
			72.257,
			72.318,
			72.38,
			72.443,
			72.502,
			72.534,
			72.567,
			72.643,
			72.691,
			72.753,
			72.814,
			72.875,
			72.937,
			72.97,
			73.001,
			73.083,
			73.124,
			73.188,
			73.247,
			73.311,
			73.37,
			73.403,
			73.434,
			73.511,
			73.558,
			73.619,
			73.683,
			73.744,
			73.807,
			73.835,
			73.867,
			73.946,
			73.991,
			74.053,
			74.115,
			74.177,
			74.241,
			74.269,
			74.303,
			74.363,
			74.426,
			74.489,
			74.55,
			74.597,
			74.613,
			74.675,
			74.704,
			74.736,
			74.814,
			74.859,
			74.924,
			74.983,
			75.046,
			75.109,
			75.171,
			75.233,
			75.246,
			75.294,
			75.357,
			75.418,
			75.48,
			75.544,
			75.604,
			75.682,
			75.729,
			75.792,
			75.853,
			75.915,
			76.008,
			76.036,
			76.101,
			76.163,
			76.226,
			76.286,
			76.347,
			76.412,
			76.442,
			76.471,
			76.55,
			76.596,
			76.658,
			76.72,
			76.782,
			76.844,
			76.908,
			76.985,
			77.03,
			77.094,
			77.154,
			77.215,
			77.278,
			77.341,
			77.404,
			77.418,
			77.466,
			77.527,
			77.59,
			77.649,
			77.712,
			77.775,
			77.852,
			77.898,
			77.961,
			78.023,
			78.084,
			78.147,
			78.209,
			78.272,
			78.287,
			78.334,
			78.394,
			78.458,
			78.518,
			78.581,
			78.642,
			78.72,
			78.768,
			78.829,
			78.891,
			78.955,
			79.046,
			79.078,
			79.14,
			79.202,
			79.269,
			79.324,
			79.386,
			79.45,
			79.512,
			79.589,
			79.636,
			79.699,
			79.803,
			79.82,
			79.884,
			79.944,
			80.02,
			80.07,
			80.133,
			80.194,
			80.254,
			80.318,
			80.348,
			80.378,
			80.459,
			80.502,
			80.566,
			80.626,
			80.671,
			80.69,
			80.782,
			80.813,
			80.874,
			80.891,
			80.938,
			80.999,
			81.061,
			81.124,
			81.185,
			81.217,
			81.247,
			81.31,
			81.371,
			81.433,
			81.494,
			81.543,
			81.623,
			81.651,
			81.68,
			81.76,
			81.805,
			81.868,
			81.931,
			81.991,
			82.053,
			82.085,
			82.115,
			82.192,
			82.239,
			82.302,
			82.364,
			82.411,
			82.487,
			82.518,
			82.549,
			82.628,
			82.675,
			82.737,
			82.798,
			82.86,
			82.921,
			82.953,
			82.983,
			83.062,
			83.109,
			83.17,
			83.277,
			83.294,
			83.394,
			83.417,
			83.495,
			83.543,
			83.602,
			83.668,
			83.727,
			83.791,
			83.82,
			83.853,
			83.914,
			83.975,
			84.039,
			84.099,
			84.161,
			84.256,
			84.286,
			84.364,
			84.411,
			84.472,
			84.533,
			84.597,
			84.659,
			84.687,
			84.721,
			84.782,
			84.844,
			84.907,
			84.967,
			85.03,
			85.123,
			85.154,
			85.231,
			85.277,
			85.341,
			85.402,
			85.453,
			85.464,
			85.525,
			85.589,
			85.65,
			85.711,
			85.775,
			85.835,
			85.899,
			85.959,
			86.023,
			86.098,
			86.145,
			86.208,
			86.269,
			86.331,
			86.395,
			86.457,
			86.517,
			86.534,
			86.581,
			86.645,
			86.704,
			86.765,
			86.828,
			86.861,
			86.89,
			86.951,
			86.969,
			87.014,
			87.078,
			87.184,
			87.199,
			87.263,
			87.294,
			87.325,
			87.402,
			87.448,
			87.515,
			87.573,
			87.633,
			87.696,
			87.728,
			87.758,
			87.821,
			87.839,
			87.884,
			87.947,
			88.007,
			88.052,
			88.068,
			88.163,
			88.193,
			88.269,
			88.317,
			88.378,
			88.441,
			88.502,
			88.566,
			88.595,
			88.628,
			88.69,
			88.75,
			88.813,
			88.882,
			88.927,
			88.948,
			89.03,
			89.06,
			89.136,
			89.185,
			89.247,
			89.31,
			89.369,
			89.463,
			89.495,
			89.577,
			89.62,
			89.683,
			89.744,
			89.791,
			89.865,
			89.899,
			89.927,
			90.007,
			90.054,
			90.115,
			90.177,
			90.238,
			90.301,
			90.364,
			90.425,
			90.441,
			90.487,
			90.549,
			90.611,
			90.658,
			90.673,
			90.735,
			90.766,
			90.797,
			90.875,
			90.922,
			90.984,
			91.045,
			91.108,
			91.171,
			91.2,
			91.23,
			91.287
		]
	],
	'1771_1784_7982': [
		[
			93.874
		],
		[
			0
		],
		[
			0,
			0.227,
			7.358,
			9.145,
			14.505,
			18.081,
			23.453,
			27.038,
			30.628,
			37.816,
			39.615,
			45.016,
			46.818,
			48.62,
			54.031,
			57.642,
			61.257,
			63.065,
			66.684,
			70.306,
			72.118,
			77.558,
			81.188,
			84.822,
			90.278,
			93.918,
			101.208,
			103.033,
			108.512,
			112.168,
			117.658,
			121.321,
			124.987,
			126.821,
			130.491,
			136,
			141.515,
			145.195,
			148.879,
			156.255,
			158.101,
			161.796,
			167.343,
			171.044,
			172.896,
			176.603,
			180.312,
			185.882,
			189.598,
			195.176,
			197.034,
			198.89,
			204.43,
			208.091,
			213.521,
			217.099,
			220.647,
			222.413,
			227.678,
			231.166,
			234.641,
			239.829,
			243.262,
			248.366,
			251.734,
			258.373,
			260.013,
			264.877,
			268.074,
			271.235,
			275.908,
			278.978,
			283.52,
			285.017,
			290.914,
			293.806,
			298.076,
			300.877,
			305.009,
			307.719,
			311.712,
			315.621,
			318.183,
			321.954,
			324.423,
			326.854,
			330.431,
			331.607,
			332.773,
			336.222,
			337.353,
			341.781,
			343.934,
			347.091,
			349.147,
			351.168,
			355.118,
			356.078,
			358.904,
			360.742,
			363.439,
			365.203,
			367.802,
			369.505,
			370.349,
			372.025,
			374.506,
			376.145,
			377.786,
			380.262,
			381.906,
			384.416,
			385.262,
			386.107,
			388.661,
			390.422,
			393.062,
			394.856,
			396.653,
			397.552,
			400.268,
			402.089,
			404.819,
			406.631,
			410.228,
			412.878,
			414.649,
			417.278,
			419.913,
			421.642,
			423.377,
			425.969,
			427.685,
			431.134,
			431.998,
			434.604,
			436.366,
			439.04,
			440.839,
			443.578,
			446.344,
			448.202,
			450.072,
			452.898,
			454.795,
			457.67,
			458.637,
			459.607,
			462.524,
			463.514,
			464.506,
			467.502,
			469.521,
			471.552,
			476.684,
			479.796,
			481.884,
			485.031,
			487.131,
			490.283,
			492.394,
			495.576,
			497.706,
			498.774,
			500.914,
			504.148,
			506.294,
			508.438,
			511.646,
			513.778,
			516.964,
			518.022,
			519.077,
			522.229,
			524.313,
			526.39,
			529.483,
			531.536,
			535.635,
			536.658,
			539.743,
			541.803,
			544.925,
			547.018,
			549.13,
			550.193,
			555.57,
			557.753,
			558.858,
			561.091,
			563.334,
			566.753,
			569.046,
			572.512,
			574.831,
			578.305,
			580.611,
			584.041,
			588.565,
			589.694,
			593.062,
			595.307,
			598.69,
			599.821,
			603.244,
			605.545,
			609.031,
			611.373,
			614.909,
			618.466,
			620.846,
			623.229,
			626.766,
			629.12,
			632.597,
			634.871,
			638.212,
			640.392,
			643.59,
			645.676,
			648.733,
			650.725,
			651.707,
			653.644,
			656.479,
			658.322,
			660.131,
			661.904,
			663.643,
			664.5,
			667.013,
			667.831,
			668.641,
			671.015,
			672.552,
			674.051,
			676.232,
			677.641,
			680.357,
			681.015,
			682.941,
			684.184,
			685.979,
			688.247,
			688.801,
			689.865,
			691.411,
			692.916,
			693.902,
			696.322,
			697.285,
			698.74,
			699.711,
			701.17,
			702.145,
			703.636,
			704.643,
			705.647,
			707.65,
			708.158,
			709.831,
			710.937,
			712.586,
			713.734,
			715.518,
			716.695,
			717.863,
			718.445,
			720.298,
			721.519,
			722.73,
			724.462,
			725.549,
			727.696,
			728.23,
			729.816,
			730.853,
			732.393,
			734.442,
			736.976,
			738.49,
			740.014,
			741.028,
			742.04,
			743.564,
			745.605,
			746.114,
			747.647,
			748.677,
			749.717,
			751.281,
			752.332,
			754.462,
			755.003,
			756.644,
			757.753,
			759.443,
			760.586,
			762.325,
			763.503,
			765.3,
			767.129,
			767.747,
			769.625,
			771.527,
			772.173,
			772.823,
			774.134,
			775.461,
			776.13,
			777.482,
			779.54,
			780.93,
			783.047,
			784.476,
			786.648,
			788.115,
			790.343,
			791.845,
			794.128,
			795.666,
			798.002,
			799.578,
			800.37,
			801.964,
			804.389,
			806.021,
			807.665,
			810.172,
			811.847,
			814.383,
			815.229,
			816.073,
			818.607,
			820.316,
			822.888,
			824.62,
			826.361,
			828.979,
			830.756,
			833.43,
			835.225,
			837.926,
			839.717,
			842.419,
			845.971,
			846.846,
			848.578,
			849.436,
			851.135,
			852.795,
			855.265,
			856.901,
			859.348,
			860.164,
			860.982,
			863.498,
			865.191,
			867.754,
			869.476,
			872.066,
			874.628,
			876.344,
			878.931,
			879.796,
			882.4,
			884.142,
			886.765,
			890.272,
			891.15,
			893.798,
			895.572,
			897.355,
			901.875,
			904.632,
			905.557,
			906.484,
			909.285,
			911.159,
			913.981,
			915.873,
			918.694,
			920.591,
			923.427,
			926.288,
			928.208,
			930.139,
			933.063,
			935.031,
			939.007,
			940.012,
			943.042,
			945.065,
			947.117,
			950.22,
			952.303,
			956.498,
			957.547,
			960.699,
			962.812,
			965.999,
			970.283,
			971.36,
			976.781,
			978.966,
			980.062,
			982.262,
			984.472,
			987.805,
			990.038,
			993.407,
			994.534,
			995.664,
			999.068,
			1001.348,
			1003.638,
			1009.404,
			1012.891,
			1014.059,
			1015.228,
			1021.111,
			1024.668,
			1027.05,
			1030.639,
			1033.043,
			1035.456,
			1036.665,
			1040.306,
			1042.742,
			1045.187,
			1048.869,
			1050.1,
			1051.333,
			1055.046,
			1056.288,
			1057.532,
			1061.276,
			1067.558,
			1070.086,
			1072.621,
			1076.438,
			1078.993,
			1082.839,
			1084.125,
			1086.703,
			1089.288,
			1094.48,
			1095.782,
			1098.388,
			1100.995,
			1102.3,
			1106.225,
			1108.852,
			1111.487,
			1115.455,
			1118.111,
			1122.11,
			1123.446,
			1124.784,
			1128.805,
			1131.492,
			1135.534,
			1138.236,
			1142.299,
			1145.016,
			1146.376,
			1149.102,
			1153.202,
			1155.943,
			1158.691,
			1161.445,
			1165.589,
			1168.358,
			1172.523,
			1175.308,
			1176.703,
			1179.498,
			1182.299,
			1186.515,
			1189.334,
			1193.574,
			1196.409,
			1200.673,
			1203.524,
			1207.812,
			1210.679,
			1213.552,
			1214.991,
			1217.873,
			1222.208,
			1225.105,
			1229.437,
			1232.3,
			1236.533,
			1239.308,
			1243.4,
			1246.08,
			1250.029,
			1252.616,
			1256.427,
			1258.923,
			1261.385,
			1267.39,
			1270.89,
			1272.038,
			1273.176,
			1276.537,
			1278.732,
			1281.955,
			1286.124,
			1287.143,
			1290.146,
			1292.103,
			1294.024,
			1296.838,
			1298.67,
			1301.356,
			1303.106,
			1305.665,
			1307.325,
			1309.748,
			1311.319,
			1313.611,
			1315.095,
			1316.545,
			1319.36,
			1320.042,
			1322.058,
			1324.675,
			1325.321,
			1326.611,
			1328.539,
			1329.819,
			1331.743,
			1333.035,
			1334.986,
			1336.288,
			1338.251,
			1338.921,
			1339.59,
			1341.597,
			1342.936,
			1345.014,
			1347.777,
			1348.476,
			1349.889,
			1352,
			1354.114,
			1354.825,
			1356.945,
			1359.043,
			1360.442,
			1361.833,
			1363.218,
			1363.91,
			1366.035,
			1367.445,
			1368.851,
			1370.974,
			1372.384,
			1374.492,
			1375.89,
			1377.975,
			1379.362,
			1381.434,
			1382.811,
			1384.876,
			1386.251,
			1387.622,
			1388.307,
			1389.678,
			1390.355,
			1391.705,
			1393.05,
			1395.07,
			1396.418,
			1398.434,
			1399.106,
			1399.779,
			1401.831,
			1403.197,
			1406.593,
			1407.939,
			1409.955,
			1410.626,
			1411.29,
			1413.277,
			1414.601,
			1416.578,
			1417.886,
			1419.192,
			1419.846,
			1421.147,
			1423.069,
			1424.995,
			1426.286,
			1427.579,
			1429.521,
			1430.818,
			1433.399,
			1434.045,
			1435.985,
			1437.281,
			1439.229,
			1440.522,
			1441.85,
			1443.92,
			1445.318,
			1448.154,
			1448.869,
			1451.035,
			1452.498,
			1454.716,
			1458.496,
			1460.039,
			1460.816,
			1462.381,
			1463.948,
			1466.347,
			1467.964,
			1471.225,
			1472.061,
			1474.544,
			1476.207,
			1478.701,
			1482.043,
			1482.89,
			1484.596,
			1485.456,
			1488.924,
			1491.556,
			1493.326,
			1495.993,
			1497.784,
			1499.585,
			1501.39,
			1502.298,
			1505.038,
			1506.884,
			1509.674,
			1513.454,
			1516.321,
			1517.282,
			1518.247,
			1521.155,
			1523.106,
			1526.054,
			1528.032,
			1531.024,
			1533.036,
			1536.077,
			1538.126,
			1539.154,
			1541.223,
			1543.307,
			1546.458,
			1547.517,
			1548.576,
			1551.755,
			1553.887,
			1557.101,
			1559.247,
			1561.379,
			1564.539,
			1566.601,
			1569.602,
			1571.552,
			1575.299,
			1576.214,
			1578.894,
			1580.636,
			1582.341,
			1583.18,
			1586.442,
			1587.233,
			1589.55,
			1591.049,
			1592.509,
			1594.624,
			1595.99,
			1598.635,
			1599.281,
			1601.203,
			1602.46,
			1604.343,
			1605.588,
			1606.826,
			1608.683,
			1609.3,
			1609.914,
			1612.352,
			1612.959,
			1614.78,
			1615.985,
			1617.781,
			1618.987,
			1620.787,
			1621.982,
			1622.579,
			1623.782,
			1625.002,
			1628.043,
			1629.901,
			1631.144,
			1633.013,
			1634.27,
			1636.157,
			1637.417,
			1639.311,
			1641.833,
			1643.727,
			1644.365,
			1645.002,
			1646.908,
			1648.179,
			1650.121,
			1651.419,
			1652.715,
			1653.363,
			1655.325,
			1656.631,
			1657.936,
			1661.19,
			1663.775,
			1664.423,
			1666.357,
			1667.642,
			1669.574,
			1670.869,
			1672.804,
			1674.094,
			1674.756,
			1676.073,
			1678.043,
			1679.36,
			1680.723,
			1682.763,
			1683.444,
			1684.125,
			1686.231,
			1686.935,
			1687.638,
			1689.754,
			1691.183,
			1693.323,
			1694.749,
			1696.174,
			1699.011,
			1699.717,
			1701.775,
			1703.142,
			1705.188,
			1706.527,
			1708.535,
			1709.878,
			1711.881,
			1713.888,
			1715.236,
			1716.58,
			1718.602,
			1719.961,
			1722.699,
			1723.386,
			1725.459,
			1726.85,
			1728.945,
			1731.768,
			1734.629,
			1735.351,
			1738.269,
			1739.006,
			1741.238,
			1742.746,
			1745.034,
			1748.928,
			1750.502,
			1751.302,
			1752.913,
			1755.357,
			1757.007,
			1758.671,
			1761.194,
			1762.883,
			1765.423,
			1766.277,
			1767.136,
			1769.731,
			1771.48,
			1774.133,
			1775.92,
			1778.63,
			1780.468,
			1782.29,
			1785.071,
			1786.005,
			1786.942,
			1789.776,
			1791.684,
			1794.589,
			1796.537,
			1798.498,
			1799.484,
			1802.467,
			1804.473,
			1806.495,
			1809.552,
			1811.611,
			1815.771,
			1816.824,
			1819.998,
			1822.149,
			1825.393,
			1827.564,
			1830.829,
			1833.033,
			1835.245,
			1836.354,
			1839.681,
			1841.908,
			1844.146,
			1847.515,
			1848.642,
			1849.771,
			1855.438,
			1857.719,
			1858.866,
			1861.165,
			1863.48,
			1866.981,
			1869.343,
			1872.911,
			1874.109,
			1875.29,
			1878.898,
			1881.308,
			1884.902,
			1889.669,
			1890.843,
			1894.348,
			1896.664,
			1900.14,
			1902.459,
			1904.788,
			1908.299,
			1910.658,
			1914.216,
			1915.411,
			1916.608,
			1920.212,
			1922.63,
			1926.275,
			1928.718,
			1931.17,
			1936.101,
			1937.339,
			1942.292,
			1943.541,
			1947.292,
			1949.8,
			1953.587,
			1956.104,
			1959.867,
			1962.396,
			1963.662,
			1966.2,
			1968.745,
			1973.853,
			1975.135,
			1978.989,
			1980.277,
			1981.568,
			1985.451,
			1988.043,
			1991.942,
			1994.553,
			1997.171,
			2002.432,
			2005.073,
			2009.032,
			2010.369,
			2014.288,
			2019.427,
			2020.694,
			2025.678,
			2026.904,
			2030.519,
			2032.905,
			2035.266,
			2038.783,
			2041.097,
			2044.52,
			2045.648,
			2046.77,
			2050.078,
			2052.263,
			2055.487,
			2057.605,
			2059.685,
			2060.708,
			2063.722,
			2065.666,
			2068.502,
			2070.346,
			2072.153,
			2074.802,
			2076.527,
			2079.902,
			2080.726,
			2082.356,
			2084.772,
			2086.364,
			2090.278,
			2092.582,
			2093.342,
			2094.101,
			2096.364,
			2097.863,
			2100.095,
			2103.794,
			2105.26,
			2105.993,
			2107.456,
			2109.635,
			2111.08,
			2112.518,
			2114.662,
			2116.087,
			2118.923,
			2119.629,
			2121.75,
			2123.164,
			2125.281,
			2126.699,
			2128.122,
			2130.963,
			2131.681,
			2133.835,
			2134.552,
			2135.269,
			2137.45,
			2140.383,
			2141.116,
			2142.627,
			2144.14,
			2144.895,
			2147.169,
			2148.69,
			2150.208,
			2154.013,
			2156.29,
			2157.058,
			2157.827,
			2160.13,
			2161.666,
			2163.995,
			2165.545,
			2167.092,
			2169.392,
			2170.926,
			2173.978,
			2175.494,
			2177.012,
			2178.515,
			2180.011,
			2180.759,
			2182.255,
			2183.726,
			2185.927,
			2186.662,
			2188.128,
			2189.587,
			2191.78,
			2193.248,
			2195.453,
			2196.928,
			2199.157,
			2200.655,
			2202.914,
			2204.427,
			2205.967,
			2206.742,
			2208.302,
			2209.105,
			2210.699,
			2212.307,
			2213.117,
			2214.753,
			2216.41,
			2218.081,
			2220.607,
			2222.322,
			2224.051,
			2224.921,
			2226.673,
			2227.554,
			2229.329,
			2231.118,
			2233.829,
			2235.654,
			2238.42,
			2239.348,
			2240.281,
			2243.103,
			2247.881,
			2249.818,
			2252.752,
			2254.726,
			2255.719,
			2257.714,
			2260.736,
			2262.767,
			2264.812,
			2267.906,
			2269.986,
			2273.124,
			2274.171,
			2275.219,
			2278.377,
			2280.5,
			2282.634,
			2285.86,
			2288.024,
			2291.294,
			2292.39,
			2293.489,
			2296.804,
			2299.03,
			2302.394,
			2304.671,
			2306.943,
			2308.084,
			2312.679,
			2313.834,
			2317.316,
			2319.647,
			2325.507,
			2327.861,
			2331.406,
			2333.77,
			2337.315,
			2339.668,
			2343.183,
			2345.504,
			2347.805,
			2352.324,
			2353.434,
			2357.795,
			2358.864,
			2362.021,
			2364.082,
			2367.11,
			2369.088,
			2371.035,
			2371.998,
			2373.898,
			2374.835,
			2376.687,
			2378.505,
			2381.172,
			2382.913,
			2385.483,
			2387.208,
			2389.762,
			2391.456,
			2393.981,
			2395.658,
			2398.168,
			2399.837,
			2400.671,
			2402.342,
			2404.012,
			2404.847,
			2406.518,
			2408.191,
			2410.699,
			2412.373,
			2414.047,
			2416.555,
			2419.067,
			2420.74,
			2422.419,
			2424.959,
			2426.654,
			2429.237,
			2430.968,
			2433.572,
			2435.31,
			2437.914,
			2439.638,
			2442.22,
			2443.938,
			2444.795,
			2446.508,
			2449.101,
			2450.84,
			2452.577,
			2456.142,
			2457.034,
			2459.759,
			2461.588,
			2463.414,
			2466.137,
			2467.954,
			2470.587,
			2472.344,
			2475.792,
			2476.648,
			2480.067,
			2480.92,
			2483.487,
			2485.197,
			2487.76,
			2489.462,
			2492,
			2494.515,
			2496.174,
			2497.814,
			2500.228,
			2501.799,
			2504.836,
			2505.572,
			2507.712,
			2509.101,
			2511.137,
			2512.444,
			2513.723,
			2514.355,
			2516.229,
			2517.459,
			2519.296,
			2519.91,
			2521.751,
			2522.979,
			2524.821,
			2526.043,
			2527.264,
			2527.873,
			2529.089,
			2530.899,
			2532.096,
			2533.881,
			2535.071,
			2536.848,
			2538.022,
			2539.772,
			2540.949,
			2542.699,
			2543.855,
			2545.01,
			2545.596,
			2546.761,
			2548.494,
			2549.642,
			2550.223,
			2551.392,
			2552.553,
			2554.286,
			2555.445,
			2556.61,
			2557.77,
			2558.349,
			2560.087,
			2561.264,
			2562.441,
			2564.206,
			2565.387,
			2567.193,
			2568.402,
			2570.227,
			2571.454,
			2573.306,
			2574.55,
			2576.426,
			2577.679,
			2578.309,
			2579.575,
			2580.853,
			2581.493,
			2582.773,
			2584.066,
			2586.03,
			2586.681,
			2587.332,
			2589.305,
			2589.97,
			2590.639,
			2592.648,
			2594.011,
			2596.087,
			2597.491,
			2598.912,
			2601.075,
			2602.539,
			2604.766,
			2606.271,
			2608.575,
			2610.132,
			2611.705,
			2612.508,
			2614.122,
			2614.933,
			2616.574,
			2619.052,
			2620.709,
			2622.376,
			2624.896,
			2626.576,
			2629.113,
			2629.958,
			2630.803,
			2633.353,
			2635.056,
			2637.612,
			2639.327,
			2641.892,
			2644.492,
			2645.359,
			2648.829,
			2649.698,
			2652.296,
			2654.04,
			2655.78,
			2656.648,
			2660.119,
			2660.982,
			2663.564,
			2665.27,
			2666.963,
			2669.49,
			2671.162,
			2673.65,
			2674.469,
			2675.285,
			2677.712,
			2679.318,
			2681.696,
			2684.825,
			2687.129,
			2687.893,
			2689.411,
			2690.908,
			2691.653,
			2693.141,
			2694.624,
			2696.836,
			2698.294,
			2699.764,
			2701.247,
			2701.984,
			2704.206,
			2705.7,
			2707.2,
			2709.468,
			2710.993,
			2714.86,
			2717.226,
			2718.82,
			2721.243,
			2722.878,
			2725.358,
			2727.033,
			2727.875,
			2729.564,
			2732.104,
			2733.812,
			2735.534,
			2738.15,
			2739.911,
			2742.591,
			2743.493,
			2744.398,
			2748.998,
			2750.866,
			2753.707,
			2755.617,
			2758.52,
			2759.494,
			2760.471,
			2764.424,
			2765.421,
			2768.433,
			2770.455,
			2772.489,
			2773.51,
			2777.623,
			2778.652,
			2781.757,
			2783.834,
			2789.059,
			2791.164,
			2794.329,
			2795.382,
			2796.438,
			2799.616,
			2801.747,
			2804.965,
			2809.291,
			2810.377,
			2812.558,
			2815.843,
			2819.148,
			2820.254,
			2823.586,
			2825.821,
			2829.174,
			2831.439,
			2834.857,
			2837.151,
			2840.61,
			2842.928,
			2846.423,
			2848.765,
			2852.297,
			2853.479,
			2854.664,
			2858.23,
			2860.62,
			2864.222,
			2866.636,
			2870.276,
			2872.716,
			2873.939,
			2876.395,
			2878.859,
			2880.095,
			2882.575,
			2885.065,
			2888.816,
			2890.071,
			2891.328,
			2893.848,
			2896.376,
			2897.643,
			2901.459,
			2904.013,
			2906.575,
			2910.434,
			2913.017,
			2916.909,
			2918.21,
			2919.513,
			2923.421,
			2926.033,
			2929.966,
			2932.598,
			2935.237,
			2936.559,
			2939.206,
			2940.531,
			2943.179,
			2947.151,
			2949.759,
			2952.34,
			2956.151,
			2958.646,
			2962.321,
			2963.527,
			2964.726,
			2968.262,
			2970.576,
			2973.985,
			2978.471,
			2981.824,
			2982.948,
			2984.073,
			2988.603,
			2989.74,
			2993.149,
			2995.416,
			2998.798,
			3001.042,
			3003.259,
			3004.375,
			3006.605,
			3009.977,
			3012.21,
			3015.565,
			3017.805,
			3021.2,
			3023.458,
			3026.909,
			3029.234,
			3032.776,
			3035.137,
			3038.695,
			3041.082,
			3042.276,
			3043.472,
			3047.062,
			3048.258,
			3049.455,
			3053.04,
			3055.425,
			3057.803,
			3058.99,
			3061.366,
			3063.74,
			3064.927,
			3068.483,
			3070.857,
			3073.239,
			3076.824,
			3079.224,
			3082.832,
			3084.037,
			3085.244,
			3088.874,
			3091.304,
			3094.975,
			3097.434,
			3101.137,
			3103.622,
			3104.87,
			3107.369,
			3111.124,
			3113.633,
			3116.148,
			3118.667,
			3122.447,
			3124.969,
			3128.754,
			3132.536,
			3135.064,
			3137.597,
			3143.978,
			3147.844,
			3150.437,
			3154.337,
			3156.948,
			3160.882,
			3163.519,
			3166.168,
			3167.495,
			3170.148,
			3171.476,
			3174.141,
			3176.823,
			3178.166,
			3180.861,
			3183.56,
			3187.647,
			3190.372,
			3193.095,
			3195.811,
			3197.168,
			3201.239,
			3203.953,
			3206.669,
			3210.752,
			3213.492,
			3217.62,
			3220.384,
			3224.583,
			3227.391,
			3231.592,
			3234.415,
			3238.657,
			3241.477,
			3242.888,
			3245.707,
			3249.924,
			3252.724,
			3255.506,
			3259.662,
			3261.037,
			3262.412,
			3266.521,
			3267.885,
			3273.332,
			3276.048,
			3280.115,
			3282.827,
			3285.537,
			3289.608,
			3290.966,
			3292.325,
			3297.774,
			3299.14,
			3303.245,
			3305.992,
			3308.744,
			3310.123,
			3312.884,
			3315.649,
			3317.033,
			3321.19,
			3323.965,
			3326.745,
			3330.897,
			3333.69,
			3337.892,
			3340.706,
			3344.945,
			3346.364,
			3347.785,
			3352.061,
			3354.922,
			3359.232,
			3362.115,
			3366.452,
			3369.351,
			3373.709,
			3376.622,
			3378.081,
			3379.54,
			3383.929,
			3386.864,
			3391.277,
			3394.227,
			3397.184,
			3398.664,
			3403.113,
			3406.085,
			3409.061,
			3413.534,
			3416.522,
			3421.014,
			3424.016,
			3428.524,
			3431.534,
			3436.052,
			3439.069,
			3443.603,
			3446.63,
			3448.146,
			3451.183,
			3455.748,
			3458.799,
			3461.854,
			3466.448,
			3469.517,
			3472.589,
			3477.207,
			3480.292,
			3481.835,
			3484.927,
			3488.022,
			3492.674,
			3495.781,
			3500.45,
			3502.009,
			3503.569,
			3508.257,
			3511.388,
			3516.093,
			3519.234,
			3522.38,
			3523.954,
			3527.106,
			3528.683,
			3531.842,
			3536.588,
			3539.756,
			3542.931,
			3547.707,
			3550.899,
			3555.691,
			3557.29,
			3558.889,
			3563.694,
			3566.9,
			3571.714,
			3574.927,
			3578.144,
			3582.974,
			3584.585,
			3586.198,
			3591.043,
			3599.14,
			3602.388,
			3607.269,
			3610.528,
			3613.79,
			3615.423,
			3618.692,
			3620.327,
			3623.6,
			3626.877,
			3631.798,
			3635.084,
			3640.019,
			3641.666,
			3643.314,
			3648.264,
			3651.569,
			3656.535,
			3659.852,
			3664.832,
			3668.157,
			3669.822,
			3671.487,
			3676.489,
			3678.159,
			3681.501,
			3684.848,
			3688.199,
			3693.232,
			3696.592,
			3699.955,
			3701.638,
			3706.692,
			3710.067,
			3713.446,
			3718.522,
			3721.91,
			3727,
			3728.699,
			3730.399,
			3735.503,
			3738.911,
			3744.03,
			3747.448,
			3752.582,
			3756.009,
			3757.723,
			3761.156,
			3766.313,
			3769.755,
			3773.201,
			3778.377,
			3781.833,
			3788.755,
			3790.489,
			3793.96,
			3795.698,
			3799.175,
			3802.657,
			3807.886,
			3811.377,
			3818.367,
			3820.115,
			3825.367,
			3827.119,
			3828.872,
			3834.135,
			3837.647,
			3842.923,
			3846.444,
			3851.732,
			3857.029,
			3860.564,
			3864.103,
			3869.417,
			3872.964,
			3878.291,
			3880.068,
			3881.847,
			3885.079
		],
		[
			0,
			0.012,
			0.106,
			0.137,
			0.218,
			0.26,
			0.323,
			0.383,
			0.446,
			0.539,
			0.569,
			0.633,
			0.647,
			0.695,
			0.758,
			0.819,
			0.866,
			0.881,
			0.942,
			0.974,
			1.005,
			1.083,
			1.128,
			1.19,
			1.252,
			1.313,
			1.407,
			1.438,
			1.516,
			1.563,
			1.626,
			1.688,
			1.733,
			1.747,
			1.811,
			1.874,
			1.95,
			1.997,
			2.06,
			2.166,
			2.183,
			2.245,
			2.305,
			2.367,
			2.386,
			2.43,
			2.496,
			2.554,
			2.618,
			2.678,
			2.711,
			2.742,
			2.818,
			2.866,
			2.927,
			2.989,
			3.036,
			3.05,
			3.144,
			3.175,
			3.236,
			3.299,
			3.362,
			3.424,
			3.485,
			3.577,
			3.61,
			3.685,
			3.733,
			3.795,
			3.857,
			3.902,
			3.98,
			4.01,
			4.103,
			4.166,
			4.228,
			4.293,
			4.354,
			4.416,
			4.478,
			4.57,
			4.6,
			4.678,
			4.726,
			4.788,
			4.85,
			4.895,
			4.911,
			4.974,
			5.005,
			5.097,
			5.158,
			5.222,
			5.283,
			5.345,
			5.437,
			5.47,
			5.532,
			5.595,
			5.657,
			5.718,
			5.778,
			5.841,
			5.871,
			5.903,
			5.981,
			6.026,
			6.09,
			6.151,
			6.212,
			6.274,
			6.307,
			6.338,
			6.4,
			6.461,
			6.523,
			6.586,
			6.632,
			6.648,
			6.739,
			6.772,
			6.848,
			6.896,
			7.02,
			7.08,
			7.144,
			7.206,
			7.282,
			7.329,
			7.392,
			7.454,
			7.501,
			7.608,
			7.639,
			7.718,
			7.764,
			7.825,
			7.886,
			7.95,
			8.043,
			8.075,
			8.135,
			8.199,
			8.261,
			8.322,
			8.369,
			8.384,
			8.447,
			8.482,
			8.508,
			8.584,
			8.632,
			8.695,
			8.801,
			8.879,
			8.941,
			9.005,
			9.066,
			9.128,
			9.19,
			9.251,
			9.313,
			9.345,
			9.376,
			9.452,
			9.499,
			9.561,
			9.623,
			9.686,
			9.748,
			9.78,
			9.811,
			9.872,
			9.933,
			9.996,
			10.059,
			10.12,
			10.212,
			10.244,
			10.32,
			10.369,
			10.432,
			10.493,
			10.545,
			10.553,
			10.677,
			10.739,
			10.756,
			10.802,
			10.866,
			10.925,
			10.987,
			11.049,
			11.112,
			11.19,
			11.235,
			11.299,
			11.407,
			11.423,
			11.516,
			11.547,
			11.63,
			11.669,
			11.732,
			11.795,
			11.857,
			11.919,
			11.979,
			12.058,
			12.104,
			12.167,
			12.228,
			12.291,
			12.351,
			12.414,
			12.492,
			12.537,
			12.605,
			12.662,
			12.723,
			12.786,
			12.816,
			12.848,
			12.926,
			12.973,
			13.035,
			13.096,
			13.142,
			13.159,
			13.222,
			13.252,
			13.283,
			13.359,
			13.406,
			13.469,
			13.532,
			13.592,
			13.684,
			13.717,
			13.779,
			13.841,
			13.902,
			14.012,
			14.028,
			14.087,
			14.152,
			14.227,
			14.273,
			14.398,
			14.46,
			14.523,
			14.585,
			14.666,
			14.709,
			14.772,
			14.833,
			14.879,
			14.986,
			15.019,
			15.095,
			15.143,
			15.204,
			15.266,
			15.327,
			15.391,
			15.422,
			15.452,
			15.531,
			15.577,
			15.639,
			15.702,
			15.763,
			15.856,
			15.887,
			15.965,
			16.012,
			16.078,
			16.181,
			16.32,
			16.384,
			16.446,
			16.507,
			16.568,
			16.629,
			16.729,
			16.755,
			16.833,
			16.879,
			16.943,
			17.004,
			17.066,
			17.157,
			17.19,
			17.251,
			17.314,
			17.376,
			17.438,
			17.499,
			17.56,
			17.623,
			17.702,
			17.746,
			17.81,
			17.871,
			17.918,
			17.933,
			17.995,
			18.026,
			18.057,
			18.12,
			18.181,
			18.245,
			18.305,
			18.368,
			18.43,
			18.49,
			18.568,
			18.614,
			18.677,
			18.739,
			18.8,
			18.864,
			18.893,
			18.925,
			19.003,
			19.05,
			19.113,
			19.174,
			19.237,
			19.298,
			19.329,
			19.359,
			19.422,
			19.484,
			19.547,
			19.608,
			19.653,
			19.732,
			19.795,
			19.87,
			19.918,
			19.98,
			20.042,
			20.104,
			20.197,
			20.226,
			20.29,
			20.305,
			20.352,
			20.413,
			20.474,
			20.522,
			20.601,
			20.629,
			20.661,
			20.74,
			20.786,
			20.852,
			20.911,
			20.972,
			21.065,
			21.096,
			21.177,
			21.22,
			21.283,
			21.345,
			21.405,
			21.5,
			21.528,
			21.606,
			21.654,
			21.717,
			21.843,
			21.903,
			21.932,
			21.965,
			22.025,
			22.087,
			22.152,
			22.211,
			22.276,
			22.337,
			22.399,
			22.476,
			22.523,
			22.584,
			22.647,
			22.707,
			22.801,
			22.833,
			22.915,
			22.956,
			23.019,
			23.081,
			23.143,
			23.234,
			23.266,
			23.343,
			23.39,
			23.451,
			23.56,
			23.577,
			23.701,
			23.76,
			23.778,
			23.823,
			23.885,
			23.948,
			24.01,
			24.073,
			24.103,
			24.134,
			24.211,
			24.258,
			24.32,
			24.43,
			24.509,
			24.538,
			24.568,
			24.692,
			24.753,
			24.817,
			24.879,
			24.939,
			24.978,
			25.001,
			25.08,
			25.128,
			25.19,
			25.25,
			25.295,
			25.311,
			25.375,
			25.406,
			25.437,
			25.514,
			25.623,
			25.686,
			25.745,
			25.808,
			25.87,
			25.934,
			25.949,
			25.999,
			26.057,
			26.165,
			26.181,
			26.244,
			26.275,
			26.306,
			26.383,
			26.43,
			26.492,
			26.552,
			26.614,
			26.676,
			26.706,
			26.739,
			26.8,
			26.864,
			26.926,
			26.987,
			27.048,
			27.112,
			27.142,
			27.173,
			27.252,
			27.298,
			27.358,
			27.42,
			27.484,
			27.545,
			27.608,
			27.67,
			27.683,
			27.731,
			27.794,
			27.854,
			27.917,
			27.978,
			28.042,
			28.117,
			28.165,
			28.228,
			28.289,
			28.335,
			28.352,
			28.414,
			28.476,
			28.537,
			28.599,
			28.66,
			28.723,
			28.785,
			28.848,
			28.909,
			28.985,
			29.034,
			29.101,
			29.156,
			29.205,
			29.344,
			29.405,
			29.421,
			29.468,
			29.535,
			29.591,
			29.652,
			29.747,
			29.777,
			29.854,
			29.901,
			29.965,
			30.026,
			30.072,
			30.15,
			30.21,
			30.275,
			30.336,
			30.399,
			30.461,
			30.522,
			30.583,
			30.645,
			30.723,
			30.77,
			30.832,
			30.939,
			30.956,
			31.016,
			31.079,
			31.143,
			31.204,
			31.265,
			31.329,
			31.389,
			31.451,
			31.481,
			31.514,
			31.592,
			31.636,
			31.701,
			31.808,
			31.824,
			31.885,
			31.947,
			32.026,
			32.07,
			32.134,
			32.197,
			32.258,
			32.319,
			32.351,
			32.381,
			32.46,
			32.505,
			32.568,
			32.629,
			32.678,
			32.753,
			32.816,
			32.894,
			32.94,
			33.003,
			33.065,
			33.126,
			33.189,
			33.222,
			33.251,
			33.311,
			33.326,
			33.374,
			33.435,
			33.499,
			33.56,
			33.623,
			33.653,
			33.684,
			33.761,
			33.808,
			33.933,
			33.995,
			34.056,
			34.086,
			34.119,
			34.18,
			34.241,
			34.309,
			34.365,
			34.413,
			34.428,
			34.49,
			34.552,
			34.628,
			34.675,
			34.738,
			34.801,
			34.862,
			34.954,
			34.985,
			35.064,
			35.11,
			35.172,
			35.234,
			35.294,
			35.357,
			35.42,
			35.498,
			35.544,
			35.61,
			35.669,
			35.73,
			35.853,
			35.917,
			35.933,
			35.978,
			36.04,
			36.102,
			36.165,
			36.258,
			36.289,
			36.366,
			36.414,
			36.473,
			36.584,
			36.599,
			36.659,
			36.69,
			36.783,
			36.847,
			36.909,
			36.971,
			37.033,
			37.095,
			37.126,
			37.157,
			37.233,
			37.281,
			37.348,
			37.452,
			37.527,
			37.559,
			37.593,
			37.669,
			37.716,
			37.777,
			37.838,
			37.901,
			37.964,
			38.025,
			38.086,
			38.103,
			38.149,
			38.211,
			38.275,
			38.319,
			38.335,
			38.397,
			38.46,
			38.521,
			38.583,
			38.645,
			38.708,
			38.77,
			38.832,
			38.894,
			38.972,
			39.017,
			39.08,
			39.141,
			39.186,
			39.204,
			39.297,
			39.327,
			39.409,
			39.45,
			39.514,
			39.575,
			39.636,
			39.729,
			39.76,
			39.825,
			39.884,
			39.949,
			40.011,
			40.057,
			40.134,
			40.164,
			40.195,
			40.273,
			40.32,
			40.383,
			40.443,
			40.506,
			40.566,
			40.629,
			40.69,
			40.708,
			40.754,
			40.815,
			40.923,
			41.001,
			41.064,
			41.141,
			41.188,
			41.258,
			41.312,
			41.375,
			41.472,
			41.558,
			41.575,
			41.62,
			41.684,
			41.744,
			41.806,
			41.87,
			41.902,
			41.933,
			42.01,
			42.055,
			42.118,
			42.227,
			42.333,
			42.366,
			42.427,
			42.491,
			42.552,
			42.614,
			42.676,
			42.738,
			42.769,
			42.801,
			42.876,
			42.924,
			42.986,
			43.048,
			43.093,
			43.111,
			43.172,
			43.201,
			43.233,
			43.311,
			43.357,
			43.423,
			43.483,
			43.543,
			43.635,
			43.667,
			43.745,
			43.793,
			43.853,
			43.914,
			43.977,
			44.038,
			44.101,
			44.179,
			44.227,
			44.293,
			44.351,
			44.413,
			44.505,
			44.535,
			44.599,
			44.659,
			44.726,
			44.829,
			44.94,
			44.971,
			45.049,
			45.094,
			45.158,
			45.217,
			45.281,
			45.404,
			45.466,
			45.481,
			45.527,
			45.599,
			45.652,
			45.714,
			45.777,
			45.823,
			45.899,
			45.931,
			45.96,
			46.041,
			46.085,
			46.149,
			46.21,
			46.272,
			46.335,
			46.395,
			46.458,
			46.472,
			46.521,
			46.582,
			46.644,
			46.708,
			46.769,
			46.799,
			46.83,
			46.906,
			46.953,
			47.017,
			47.079,
			47.141,
			47.234,
			47.265,
			47.326,
			47.387,
			47.45,
			47.511,
			47.573,
			47.636,
			47.673,
			47.699,
			47.775,
			47.823,
			47.885,
			47.946,
			47.993,
			48.007,
			48.133,
			48.196,
			48.21,
			48.257,
			48.318,
			48.38,
			48.442,
			48.504,
			48.534,
			48.565,
			48.644,
			48.69,
			48.752,
			48.861,
			48.877,
			48.971,
			49.001,
			49.078,
			49.126,
			49.186,
			49.25,
			49.31,
			49.373,
			49.404,
			49.434,
			49.513,
			49.56,
			49.622,
			49.682,
			49.734,
			49.837,
			49.868,
			49.946,
			49.992,
			50.054,
			50.117,
			50.178,
			50.241,
			50.303,
			50.366,
			50.38,
			50.426,
			50.49,
			50.596,
			50.612,
			50.674,
			50.705,
			50.737,
			50.799,
			50.86,
			50.923,
			50.984,
			51.046,
			51.139,
			51.172,
			51.247,
			51.296,
			51.357,
			51.467,
			51.481,
			51.575,
			51.606,
			51.683,
			51.727,
			51.79,
			51.854,
			51.916,
			51.977,
			52.007,
			52.039,
			52.102,
			52.162,
			52.227,
			52.288,
			52.333,
			52.35,
			52.442,
			52.473,
			52.551,
			52.597,
			52.659,
			52.722,
			52.784,
			52.877,
			52.906,
			52.97,
			53.032,
			53.093,
			53.203,
			53.278,
			53.31,
			53.341,
			53.418,
			53.466,
			53.529,
			53.651,
			53.713,
			53.746,
			53.777,
			53.861,
			53.899,
			53.961,
			54.024,
			54.084,
			54.177,
			54.209,
			54.287,
			54.334,
			54.397,
			54.459,
			54.52,
			54.613,
			54.644,
			54.706,
			54.721,
			54.769,
			54.828,
			54.938,
			54.952,
			55.016,
			55.047,
			55.077,
			55.154,
			55.202,
			55.265,
			55.372,
			55.45,
			55.48,
			55.512,
			55.574,
			55.637,
			55.701,
			55.761,
			55.821,
			55.885,
			55.946,
			56.023,
			56.071,
			56.133,
			56.193,
			56.24,
			56.257,
			56.317,
			56.35,
			56.441,
			56.458,
			56.503,
			56.57,
			56.627,
			56.689,
			56.751,
			56.814,
			56.892,
			56.939,
			57.004,
			57.06,
			57.107,
			57.123,
			57.187,
			57.218,
			57.249,
			57.311,
			57.325,
			57.373,
			57.433,
			57.494,
			57.558,
			57.619,
			57.652,
			57.683,
			57.743,
			57.761,
			57.808,
			57.867,
			57.931,
			57.98,
			58.055,
			58.085,
			58.115,
			58.193,
			58.302,
			58.365,
			58.426,
			58.488,
			58.518,
			58.551,
			58.629,
			58.674,
			58.736,
			58.798,
			58.846,
			58.924,
			58.952,
			58.986,
			59.062,
			59.11,
			59.169,
			59.233,
			59.294,
			59.356,
			59.389,
			59.419,
			59.481,
			59.543,
			59.606,
			59.667,
			59.714,
			59.729,
			59.823,
			59.851,
			59.931,
			59.978,
			60.102,
			60.163,
			60.224,
			60.286,
			60.365,
			60.411,
			60.473,
			60.536,
			60.595,
			60.691,
			60.719,
			60.799,
			60.843,
			60.906,
			60.968,
			61.032,
			61.093,
			61.125,
			61.155,
			61.216,
			61.232,
			61.279,
			61.346,
			61.402,
			61.449,
			61.526,
			61.59,
			61.666,
			61.714,
			61.777,
			61.838,
			61.898,
			61.961,
			61.991,
			62.023,
			62.086,
			62.104,
			62.148,
			62.209,
			62.272,
			62.333,
			62.394,
			62.457,
			62.534,
			62.58,
			62.644,
			62.704,
			62.767,
			62.83,
			62.891,
			62.952,
			63.016,
			63.077,
			63.139,
			63.201,
			63.264,
			63.295,
			63.326,
			63.403,
			63.448,
			63.513,
			63.618,
			63.634,
			63.697,
			63.758,
			63.82,
			63.884,
			63.945,
			64.006,
			64.068,
			64.168,
			64.192,
			64.272,
			64.317,
			64.381,
			64.441,
			64.503,
			64.566,
			64.627,
			64.706,
			64.751,
			64.813,
			64.877,
			64.937,
			65.031,
			65.061,
			65.123,
			65.186,
			65.249,
			65.311,
			65.355,
			65.372,
			65.465,
			65.497,
			65.574,
			65.62,
			65.682,
			65.745,
			65.805,
			65.866,
			65.898,
			65.928,
			65.992,
			66.055,
			66.115,
			66.177,
			66.24,
			66.3,
			66.364,
			66.442,
			66.487,
			66.549,
			66.611,
			66.659,
			66.676,
			66.737,
			66.799,
			66.861,
			66.876,
			66.922,
			66.984,
			67.046,
			67.107,
			67.171,
			67.201,
			67.232,
			67.311,
			67.358,
			67.417,
			67.481,
			67.541,
			67.604,
			67.666,
			67.728,
			67.791,
			67.851,
			67.913,
			67.976,
			68.037,
			68.07,
			68.099,
			68.161,
			68.178,
			68.223,
			68.292,
			68.347,
			68.394,
			68.411,
			68.472,
			68.504,
			68.533,
			68.611,
			68.659,
			68.721,
			68.782,
			68.845,
			68.908,
			68.969,
			69.031,
			69.091,
			69.155,
			69.215,
			69.263,
			69.278,
			69.34,
			69.371,
			69.402,
			69.481,
			69.526,
			69.589,
			69.65,
			69.712,
			69.777,
			69.807,
			69.835,
			69.899,
			69.96,
			70.027,
			70.084,
			70.146,
			70.24,
			70.269,
			70.354,
			70.395,
			70.457,
			70.518,
			70.565,
			70.583,
			70.674,
			70.705,
			70.781,
			70.829,
			70.89,
			70.952,
			71.016,
			71.077,
			71.108,
			71.138,
			71.216,
			71.263,
			71.326,
			71.433,
			71.512,
			71.541,
			71.574,
			71.633,
			71.651,
			71.697,
			71.758,
			71.822,
			71.884,
			71.946,
			71.976,
			72.007,
			72.085,
			72.132,
			72.194,
			72.255,
			72.301,
			72.442,
			72.517,
			72.565,
			72.626,
			72.69,
			72.752,
			72.813,
			72.844,
			72.875,
			72.953,
			72.999,
			73.065,
			73.123,
			73.185,
			73.247,
			73.277,
			73.309,
			73.432,
			73.495,
			73.557,
			73.62,
			73.68,
			73.712,
			73.743,
			73.822,
			73.868,
			73.929,
			73.992,
			74.037,
			74.052,
			74.146,
			74.178,
			74.254,
			74.3,
			74.425,
			74.486,
			74.55,
			74.58,
			74.612,
			74.673,
			74.734,
			74.797,
			74.906,
			74.921,
			74.982,
			75.046,
			75.125,
			75.168,
			75.232,
			75.292,
			75.355,
			75.416,
			75.479,
			75.542,
			75.602,
			75.666,
			75.726,
			75.79,
			75.852,
			75.883,
			75.914,
			75.992,
			76.037,
			76.101,
			76.162,
			76.223,
			76.285,
			76.316,
			76.348,
			76.41,
			76.425,
			76.472,
			76.539,
			76.593,
			76.643,
			76.658,
			76.718,
			76.751,
			76.783,
			76.858,
			76.906,
			76.968,
			77.029,
			77.091,
			77.155,
			77.183,
			77.216,
			77.277,
			77.341,
			77.402,
			77.464,
			77.51,
			77.526,
			77.587,
			77.62,
			77.649,
			77.726,
			77.772,
			77.834,
			77.898,
			77.961,
			78.021,
			78.052,
			78.083,
			78.146,
			78.207,
			78.274,
			78.378,
			78.456,
			78.487,
			78.517,
			78.6,
			78.641,
			78.705,
			78.765,
			78.828,
			78.89,
			78.921,
			78.951,
			79.015,
			79.077,
			79.139,
			79.199,
			79.247,
			79.323,
			79.387,
			79.464,
			79.511,
			79.572,
			79.634,
			79.697,
			79.758,
			79.789,
			79.821,
			79.882,
			79.898,
			79.943,
			80.007,
			80.067,
			80.116,
			80.131,
			80.193,
			80.224,
			80.255,
			80.332,
			80.377,
			80.443,
			80.504,
			80.564,
			80.625,
			80.663,
			80.687,
			80.749,
			80.813,
			80.879,
			80.936,
			80.998,
			81.061,
			81.091,
			81.123,
			81.2,
			81.246,
			81.311,
			81.371,
			81.433,
			81.494,
			81.557,
			81.633,
			81.682,
			81.744,
			81.851,
			81.928,
			81.989,
			82.069,
			82.116,
			82.177,
			82.239,
			82.284,
			82.302,
			82.362,
			82.392,
			82.427,
			82.486,
			82.503,
			82.549,
			82.614,
			82.672,
			82.734,
			82.796,
			82.826,
			82.857,
			82.937,
			82.983,
			83.046,
			83.105,
			83.153,
			83.231,
			83.293,
			83.354,
			83.417,
			83.48,
			83.54,
			83.602,
			83.665,
			83.694,
			83.726,
			83.805,
			83.851,
			83.915,
			83.976,
			84.02,
			84.037,
			84.1,
			84.131,
			84.225,
			84.285,
			84.35,
			84.409,
			84.47,
			84.533,
			84.565,
			84.596,
			84.672,
			84.719,
			84.785,
			84.842,
			84.889,
			84.906,
			84.968,
			84.998,
			85.029,
			85.106,
			85.153,
			85.215,
			85.278,
			85.34,
			85.411,
			85.462,
			85.524,
			85.56,
			85.588,
			85.665,
			85.712,
			85.774,
			85.836,
			85.897,
			85.958,
			86.022,
			86.084,
			86.099,
			86.144,
			86.208,
			86.269,
			86.331,
			86.393,
			86.425,
			86.455,
			86.533,
			86.578,
			86.64,
			86.703,
			86.751,
			86.828,
			86.89,
			86.951,
			87.012,
			87.075,
			87.137,
			87.199,
			87.263,
			87.293,
			87.323,
			87.4,
			87.448,
			87.51,
			87.572,
			87.632,
			87.695,
			87.756,
			87.82,
			87.834,
			87.881,
			87.944,
			88.004,
			88.069,
			88.128,
			88.159,
			88.192,
			88.27,
			88.316,
			88.378,
			88.439,
			88.487,
			88.501,
			88.562,
			88.594,
			88.626,
			88.704,
			88.75,
			88.811,
			88.874,
			88.934,
			88.999,
			89.027,
			89.06,
			89.122,
			89.184,
			89.246,
			89.306,
			89.353,
			89.432,
			89.464,
			89.494,
			89.57,
			89.68,
			89.741,
			89.803,
			89.865,
			89.896,
			89.927,
			89.99,
			90.004,
			90.052,
			90.115,
			90.177,
			90.222,
			90.3,
			90.331,
			90.362,
			90.439,
			90.487,
			90.549,
			90.611,
			90.673,
			90.733,
			90.766,
			90.795,
			90.858,
			90.872,
			90.921,
			90.986,
			91.043,
			91.106,
			91.167,
			91.199,
			91.231,
			91.309,
			91.354,
			91.417,
			91.478,
			91.526,
			91.601,
			91.633,
			91.664,
			91.727,
			91.788,
			91.853,
			91.911,
			91.973,
			92.037,
			92.068,
			92.098,
			92.176,
			92.223,
			92.285,
			92.347,
			92.408,
			92.503,
			92.533,
			92.595,
			92.611,
			92.656,
			92.72,
			92.781,
			92.843,
			92.936,
			92.967,
			93.027,
			93.051,
			93.091,
			93.155,
			93.214,
			93.277,
			93.339,
			93.399,
			93.479,
			93.525,
			93.586,
			93.649,
			93.711,
			93.775,
			93.804,
			93.835,
			93.874
		]
	],
	'1771_1785_7982': [
		[
			100.868
		],
		[
			0
		],
		[
			0,
			1.118,
			6.253,
			7.967,
			14.834,
			16.553,
			21.715,
			25.161,
			30.336,
			33.792,
			37.25,
			38.98,
			44.178,
			47.647,
			51.118,
			56.334,
			59.816,
			65.051,
			66.798,
			68.543,
			73.79,
			77.298,
			82.558,
			86.069,
			91.341,
			96.62,
			100.144,
			105.436,
			107.202,
			112.504,
			116.042,
			119.585,
			121.357,
			128.458,
			130.235,
			135.573,
			139.136,
			142.701,
			148.057,
			151.632,
			157,
			158.79,
			160.581,
			165.96,
			169.55,
			174.94,
			178.538,
			183.941,
			187.549,
			189.354,
			192.964,
			196.569,
			201.94,
			205.484,
			210.736,
			214.196,
			219.327,
			221.022,
			222.711,
			226.072,
			231.071,
			234.374,
			239.281,
			242.518,
			247.325,
			248.914,
			250.495,
			255.189,
			258.278,
			265.845,
			270.283,
			273.201,
			274.646,
			277.509,
			280.335,
			281.734,
			284.505,
			287.24,
			291.273,
			293.915,
			297.81,
			299.091,
			300.362,
			304.123,
			306.586,
			310.21,
			312.585,
			314.925,
			318.372,
			319.501,
			320.62,
			323.922,
			326.07,
			329.223,
			331.275,
			333.291,
			334.286,
			336.251,
			338.179,
			339.13,
			341.93,
			343.752,
			345.54,
			348.157,
			349.857,
			352.326,
			353.924,
			356.253,
			357.759,
			359.952,
			361.369,
			363.431,
			365.416,
			366.696,
			368.549,
			369.147,
			372.015,
			373.614,
			374.637,
			375.629,
			376.116,
			377.536,
			378.455,
			379.358,
			380.691,
			381.57,
			382.86,
			383.287,
			383.712,
			384.992,
			385.848,
			387.142,
			388.018,
			388.896,
			389.335,
			390.661,
			391.551,
			392.915,
			393.848,
			394.781,
			396.181,
			397.118,
			398.066,
			399.528,
			400.982,
			401.949,
			402.918,
			404.451,
			405.469,
			406.988,
			408.001,
			409.563,
			410.604,
			412.157,
			413.192,
			414.767,
			415.811,
			417.369,
			418.931,
			419.984,
			421.029,
			422.592,
			423.114,
			423.638,
			425.208,
			425.728,
			426.249,
			428.856,
			430.441,
			431.505,
			432.577,
			434.748,
			435.298,
			436.956,
			438.07,
			439.74,
			440.882,
			442.009,
			442.569,
			443.686,
			444.797,
			445.36,
			447.029,
			448.128,
			449.22,
			450.876,
			451.994,
			453.652,
			454.201,
			454.75,
			456.457,
			457.616,
			459.343,
			460.492,
			461.739,
			463.651,
			464.922,
			467.525,
			468.183,
			470.16,
			471.484,
			473.471,
			474.799,
			476.126,
			476.751,
			478.002,
			479.888,
			481.153,
			482.896,
			484.064,
			485.835,
			487.01,
			488.761,
			489.942,
			491.736,
			492.944,
			494.78,
			496.022,
			496.649,
			497.913,
			499.838,
			501.142,
			502.46,
			503.782,
			505.811,
			507.875,
			508.57,
			509.269,
			511.39,
			512.824,
			514.272,
			516.476,
			517.966,
			520.994,
			521.762,
			524.103,
			525.677,
			528.066,
			529.678,
			532.119,
			533.765,
			536.245,
			538.743,
			540.405,
			542.067,
			544.576,
			546.28,
			549.704,
			550.583,
			553.254,
			555.039,
			556.898,
			559.7,
			561.65,
			565.691,
			566.723,
			569.82,
			571.867,
			574.888,
			576.883,
			578.802,
			579.76,
			581.675,
			584.401,
			587.106,
			588.856,
			590.603,
			593.211,
			594.937,
			597.525,
			599.238,
			601.81,
			603.524,
			606.095,
			607.815,
			609.554,
			610.425,
			613.045,
			613.928,
			617.472,
			619.254,
			621.043,
			622.839,
			625.542,
			627.355,
			629.178,
			630.109,
			631.982,
			634.826,
			636.748,
			639.655,
			641.611,
			645.566,
			646.563,
			649.58,
			651.611,
			656.694,
			659.769,
			661.828,
			662.856,
			664.902,
			666.925,
			667.924,
			669.893,
			671.824,
			674.649,
			675.572,
			676.486,
			680.047,
			680.913,
			683.457,
			685.107,
			687.51,
			690.585,
			692.794,
			694.221,
			696.293,
			697.632,
			699.576,
			700.831,
			702.048,
			702.643,
			703.807,
			705.499,
			707.12,
			708.166,
			709.204,
			710.706,
			711.694,
			713.165,
			713.652,
			714.137,
			715.097,
			715.568,
			716.502,
			717.428,
			718.802,
			719.712,
			721.077,
			721.527,
			721.974,
			723.745,
			724.184,
			725.516,
			726.408,
			727.727,
			729.465,
			729.898,
			730.789,
			732.124,
			733.007,
			734.32,
			735.188,
			737.012,
			737.464,
			738.812,
			739.711,
			741.105,
			742.072,
			743.515,
			744.962,
			745.448,
			746.959,
			747.467,
			747.974,
			749.504,
			750.534,
			752.144,
			753.24,
			754.901,
			756.024,
			757.73,
			758.883,
			760.64,
			761.815,
			763.541,
			764.713,
			766.503,
			767.644,
			769.363,
			770.531,
			772.318,
			773.53,
			774.749,
			775.358,
			776.578,
			778.454,
			779.724,
			781.66,
			782.315,
			782.978,
			784.314,
			785.666,
			786.348,
			788.428,
			789.841,
			791.269,
			794.914,
			797.902,
			798.66,
			800.954,
			802.501,
			804.851,
			806.421,
			808.824,
			811.26,
			812.9,
			815.389,
			817.063,
			819.582,
			821.261,
			823.796,
			825.506,
			828.093,
			829.814,
			832.392,
			834.957,
			836.66,
			838.351,
			840.877,
			842.548,
			844.21,
			846.711,
			848.366,
			850.863,
			853.357,
			855.028,
			856.71,
			860.075,
			860.94,
			863.525,
			865.254,
			867.865,
			869.599,
			872.15,
			875.527,
			876.349,
			877.993,
			878.817,
			880.467,
			882.102,
			886.212,
			888.685,
			889.514,
			890.345,
			892.851,
			894.53,
			897.054,
			898.728,
			900.406,
			903.738,
			904.567,
			907.878,
			908.696,
			911.156,
			912.799,
			914.426,
			915.241,
			916.877,
			918.519,
			919.34,
			921.813,
			925.15,
			927.679,
			929.378,
			931.941,
			932.8,
			933.661,
			936.265,
			938.01,
			940.652,
			942.429,
			944.215,
			945.117,
			946.931,
			949.673,
			952.448,
			953.379,
			956.191,
			958.08,
			960.922,
			962.846,
			965.755,
			968.699,
			970.677,
			972.672,
			975.687,
			977.714,
			980.778,
			981.805,
			982.835,
			985.942,
			988.027,
			991.17,
			993.264,
			995.365,
			996.419,
			999.596,
			1001.727,
			1004.942,
			1007.098,
			1009.264,
			1012.531,
			1014.721,
			1016.922,
			1020.24,
			1023.58,
			1025.818,
			1028.066,
			1031.455,
			1033.727,
			1037.151,
			1038.297,
			1042.903,
			1045.219,
			1048.711,
			1051.051,
			1054.577,
			1056.939,
			1058.124,
			1060.5,
			1062.884,
			1064.08,
			1066.477,
			1068.884,
			1072.51,
			1074.937,
			1078.595,
			1079.818,
			1081.044,
			1087.204,
			1090.924,
			1093.415,
			1095.914,
			1099.679,
			1100.938,
			1102.199,
			1107.262,
			1108.533,
			1112.356,
			1114.915,
			1117.48,
			1118.766,
			1121.343,
			1123.927,
			1125.221,
			1129.116,
			1131.721,
			1138.241,
			1140.858,
			1144.798,
			1147.432,
			1151.396,
			1154.047,
			1158.038,
			1163.385,
			1164.726,
			1167.414,
			1168.76,
			1171.458,
			1175.517,
			1176.873,
			1180.951,
			1187.78,
			1190.522,
			1193.271,
			1194.648,
			1197.407,
			1198.789,
			1201.558,
			1204.333,
			1208.509,
			1211.3,
			1216.906,
			1218.314,
			1222.55,
			1225.383,
			1229.643,
			1232.49,
			1236.767,
			1239.625,
			1243.918,
			1248.186,
			1251,
			1253.779,
			1256.521,
			1260.561,
			1263.207,
			1265.816,
			1267.106,
			1270.92,
			1273.417,
			1275.877,
			1279.5,
			1281.873,
			1285.367,
			1287.649,
			1291.004,
			1293.193,
			1296.409,
			1298.507,
			1301.585,
			1304.581,
			1306.533,
			1309.392,
			1311.254,
			1313.082,
			1315.761,
			1316.638,
			1317.505,
			1320.054,
			1321.708,
			1324.121,
			1325.685,
			1327.963,
			1329.438,
			1330.876,
			1332.982,
			1333.665,
			1334.34,
			1336.318,
			1337.604,
			1339.479,
			1340.699,
			1341.897,
			1342.49,
			1344.791,
			1345.354,
			1347.036,
			1348.156,
			1349.273,
			1350.948,
			1352.067,
			1353.757,
			1354.343,
			1354.93,
			1356.693,
			1357.875,
			1359.689,
			1360.923,
			1362.782,
			1364.028,
			1365.933,
			1367.839,
			1368.475,
			1370.421,
			1371.726,
			1373.684,
			1375.028,
			1377.076,
			1379.109,
			1380.474,
			1381.834,
			1383.861,
			1385.183,
			1387.761,
			1388.4,
			1390.333,
			1391.639,
			1393.591,
			1394.907,
			1396.97,
			1398.342,
			1399.027,
			1400.4,
			1402.46,
			1403.146,
			1405.203,
			1407.221,
			1408.565,
			1409.914,
			1411.945,
			1413.978,
			1415.336,
			1416.704,
			1418.75,
			1420.112,
			1422.11,
			1423.43,
			1425.406,
			1426.695,
			1428.602,
			1429.874,
			1431.755,
			1432.981,
			1433.593,
			1434.817,
			1436.648,
			1437.849,
			1439.051,
			1441.468,
			1442.072,
			1443.886,
			1445.1,
			1446.943,
			1448.188,
			1450.061,
			1451.317,
			1452.601,
			1454.537,
			1455.186,
			1455.836,
			1458.457,
			1459.116,
			1461.108,
			1462.447,
			1464.474,
			1465.83,
			1467.213,
			1467.909,
			1470.018,
			1471.443,
			1472.883,
			1475.069,
			1476.545,
			1479.552,
			1480.315,
			1482.623,
			1484.188,
			1486.562,
			1488.164,
			1490.595,
			1493.057,
			1494.718,
			1497.233,
			1500.603,
			1502.292,
			1504.848,
			1508.296,
			1509.165,
			1511.783,
			1513.544,
			1515.314,
			1517.99,
			1519.789,
			1523.422,
			1524.338,
			1527.108,
			1528.97,
			1531.786,
			1533.679,
			1536.541,
			1539.429,
			1541.369,
			1544.303,
			1546.274,
			1548.258,
			1550.254,
			1553.272,
			1556.32,
			1557.344,
			1558.375,
			1561.48,
			1563.577,
			1565.691,
			1571.028,
			1574.227,
			1576.353,
			1579.517,
			1581.593,
			1584.641,
			1586.625,
			1589.53,
			1591.418,
			1592.35,
			1594.185,
			1596.868,
			1598.612,
			1600.314,
			1602.795,
			1604.403,
			1606.738,
			1607.499,
			1609.727,
			1610.451,
			1611.874,
			1613.26,
			1615.289,
			1616.615,
			1619.181,
			1619.811,
			1621.732,
			1623.02,
			1624.992,
			1627.663,
			1628.335,
			1629.682,
			1631.053,
			1631.74,
			1635.155,
			1636.549,
			1638.612,
			1639.968,
			1641.956,
			1643.251,
			1645.157,
			1646.401,
			1648.221,
			1649.41,
			1650.584,
			1651.169,
			1652.329,
			1652.905,
			1654.049,
			1655.752,
			1656.324,
			1658.047,
			1659.199,
			1660.935,
			1663.31,
			1663.904,
			1665.093,
			1665.688,
			1666.892,
			1668.098,
			1669.898,
			1671.095,
			1672.887,
			1673.48,
			1674.072,
			1675.839,
			1677.015,
			1678.767,
			1681.647,
			1682.785,
			1683.35,
			1684.471,
			1686.138,
			1687.245,
			1688.347,
			1689.44,
			1690.528,
			1691.071,
			1692.159,
			1693.794,
			1695.42,
			1696.505,
			1697.596,
			1700.328,
			1701.971,
			1703.087,
			1704.768,
			1705.892,
			1707.595,
			1708.749,
			1710.487,
			1711.656,
			1713.436,
			1715.233,
			1716.442,
			1717.664,
			1719.523,
			1720.146,
			1720.771,
			1722.028,
			1723.936,
			1725.86,
			1727.151,
			1729.113,
			1730.431,
			1731.759,
			1734.46,
			1735.145,
			1737.913,
			1738.612,
			1740.722,
			1742.142,
			1743.572,
			1744.29,
			1746.456,
			1747.914,
			1751.582,
			1753.07,
			1755.315,
			1756.826,
			1759.895,
			1760.67,
			1763.02,
			1764.608,
			1767.017,
			1770.272,
			1771.1,
			1772.767,
			1773.606,
			1774.449,
			1777.823,
			1778.673,
			1781.248,
			1785.619,
			1787.397,
			1789.191,
			1790.094,
			1791.911,
			1792.825,
			1794.666,
			1796.522,
			1799.335,
			1801.229,
			1804.1,
			1806.032,
			1808.97,
			1810.948,
			1813.96,
			1815.99,
			1819.085,
			1821.155,
			1824.292,
			1826.402,
			1827.462,
			1829.588,
			1831.724,
			1834.94,
			1836.014,
			1837.09,
			1841.397,
			1842.477,
			1845.743,
			1847.936,
			1850.149,
			1853.507,
			1855.765,
			1859.176,
			1861.465,
			1864.911,
			1867.206,
			1870.659,
			1872.975,
			1876.463,
			1878.808,
			1879.983,
			1882.342,
			1885.882,
			1888.288,
			1890.678,
			1894.271,
			1895.471,
			1896.674,
			1902.7,
			1906.325,
			1908.722,
			1911.112,
			1914.679,
			1917.053,
			1920.592,
			1921.781,
			1922.972,
			1927.763,
			1928.962,
			1932.57,
			1934.987,
			1938.609,
			1943.496,
			1944.722,
			1948.411,
			1950.9,
			1953.376,
			1957.102,
			1959.575,
			1963.329,
			1964.584,
			1965.842,
			1969.625,
			1972.156,
			1975.968,
			1981.073,
			1984.916,
			1986.201,
			1988.775,
			1992.648,
			1993.943,
			1997.836,
			2000.439,
			2004.342,
			2006.949,
			2009.564,
			2010.875,
			2013.501,
			2014.817,
			2017.453,
			2020.099,
			2026.746,
			2030.756,
			2032.095,
			2033.434,
			2037.463,
			2040.119,
			2044.063,
			2046.658,
			2050.469,
			2053.002,
			2054.245,
			2056.71,
			2059.14,
			2061.528,
			2065.087,
			2069.764,
			2070.915,
			2073.204,
			2075.458,
			2076.582,
			2079.901,
			2082.068,
			2084.197,
			2087.315,
			2089.338,
			2092.284,
			2093.25,
			2094.207,
			2097.026,
			2098.859,
			2101.561,
			2103.294,
			2104.982,
			2105.812,
			2107.446,
			2109.814,
			2112.124,
			2113.644,
			2115.152,
			2118.888,
			2121.873,
			2122.616,
			2124.854,
			2126.341,
			2127.824,
			2130.041,
			2131.51,
			2133.703,
			2134.431,
			2135.156,
			2138.036,
			2138.752,
			2140.88,
			2142.287,
			2143.687,
			2144.387,
			2145.783,
			2146.478,
			2147.861,
			2149.238,
			2149.938,
			2151.332,
			2152.718,
			2154.809,
			2156.229,
			2159.058,
			2159.774,
			2161.999,
			2163.476,
			2165.718,
			2167.229,
			2168.738,
			2169.493,
			2171.784,
			2172.549,
			2174.847,
			2176.365,
			2178.637,
			2180.15,
			2182.381,
			2183.867,
			2186.089,
			2187.554,
			2189.754,
			2191.944,
			2193.394,
			2194.845,
			2197.026,
			2198.477,
			2200.654,
			2201.381,
			2202.108,
			2204.29,
			2205.747,
			2207.921,
			2209.37,
			2211.552,
			2213.006,
			2213.735,
			2214.466,
			2217.42,
			2218.169,
			2220.438,
			2221.97,
			2223.523,
			2225.881,
			2228.278,
			2230.718,
			2232.364,
			2234.034,
			2236.555,
			2238.239,
			2240.799,
			2242.511,
			2245.13,
			2246.896,
			2249.575,
			2251.381,
			2253.202,
			2254.118,
			2255.964,
			2256.892,
			2260.643,
			2261.59,
			2263.497,
			2265.418,
			2268.328,
			2270.287,
			2274.247,
			2275.246,
			2278.264,
			2280.295,
			2282.339,
			2285.432,
			2287.51,
			2290.648,
			2291.695,
			2292.742,
			2295.901,
			2298.023,
			2301.229,
			2303.383,
			2306.636,
			2308.82,
			2309.917,
			2312.119,
			2315.444,
			2317.677,
			2319.922,
			2324.452,
			2325.593,
			2329.033,
			2330.204,
			2331.359,
			2334.835,
			2337.161,
			2340.645,
			2342.96,
			2345.255,
			2349.779,
			2350.894,
			2354.196,
			2355.279,
			2356.351,
			2359.516,
			2361.592,
			2363.631,
			2364.637,
			2368.582,
			2369.545,
			2372.395,
			2374.251,
			2376.077,
			2380.528,
			2383.126,
			2383.979,
			2384.825,
			2387.326,
			2388.97,
			2391.406,
			2393.01,
			2395.398,
			2396.986,
			2399.359,
			2401.733,
			2402.525,
			2404.905,
			2406.494,
			2408.895,
			2410.501,
			2412.92,
			2414.541,
			2415.354,
			2416.983,
			2418.624,
			2421.102,
			2422.765,
			2425.301,
			2427.005,
			2429.598,
			2431.355,
			2434.008,
			2437.612,
			2438.515,
			2440.342,
			2443.093,
			2444.935,
			2447.711,
			2449.562,
			2452.364,
			2454.229,
			2456.126,
			2458.027,
			2458.976,
			2461.86,
			2463.792,
			2465.717,
			2468.576,
			2470.474,
			2473.29,
			2475.159,
			2477.885,
			2479.684,
			2482.35,
			2484.104,
			2486.731,
			2488.476,
			2489.349,
			2491.096,
			2493.718,
			2495.462,
			2497.205,
			2499.808,
			2501.536,
			2504.087,
			2504.929,
			2505.767,
			2508.239,
			2509.857,
			2511.462,
			2513.854,
			2515.444,
			2517.841,
			2518.645,
			2519.448,
			2521.855,
			2523.462,
			2525.855,
			2527.433,
			2528.991,
			2529.761,
			2531.28,
			2533.491,
			2535.615,
			2536.986,
			2538.324,
			2540.269,
			2541.528,
			2543.369,
			2543.971,
			2544.569,
			2546.337,
			2547.499,
			2549.217,
			2550.353,
			2551.487,
			2552.054,
			2553.19,
			2553.758,
			2554.326,
			2556.605,
			2557.177,
			2558.924,
			2560.101,
			2561.875,
			2563.065,
			2564.301,
			2564.92,
			2566.158,
			2566.779,
			2568.021,
			2569.298,
			2571.216,
			2572.495,
			2574.417,
			2575.057,
			2575.696,
			2577.61,
			2578.881,
			2580.756,
			2582.002,
			2583.867,
			2585.697,
			2586.91,
			2588.123,
			2589.927,
			2591.12,
			2592.907,
			2594.103,
			2595.291,
			2596.479,
			2597.074,
			2598.866,
			2600.074,
			2601.281,
			2603.091,
			2604.304,
			2606.146,
			2606.761,
			2607.377,
			2609.232,
			2610.486,
			2612.376,
			2613.644,
			2615.563,
			2616.874,
			2617.534,
			2618.867,
			2620.922,
			2622.329,
			2623.753,
			2625.932,
			2627.417,
			2629.676,
			2630.449,
			2631.213,
			2633.517,
			2635.064,
			2636.618,
			2638.955,
			2641.305,
			2642.88,
			2643.667,
			2644.454,
			2647.615,
			2648.406,
			2650.778,
			2652.367,
			2653.952,
			2654.743,
			2656.324,
			2657.908,
			2658.697,
			2661.056,
			2662.631,
			2664.199,
			2666.543,
			2668.118,
			2670.474,
			2671.258,
			2672.042,
			2674.426,
			2676.011,
			2678.406,
			2680.013,
			2681.62,
			2682.426,
			2684.053,
			2684.868,
			2685.683,
			2688.956,
			2689.777,
			2692.24,
			2693.89,
			2696.377,
			2698.036,
			2699.697,
			2700.526,
			2702.187,
			2703.018,
			2704.684,
			2706.348,
			2708.852,
			2710.512,
			2713.008,
			2713.843,
			2714.678,
			2717.184,
			2718.864,
			2721.397,
			2723.096,
			2725.653,
			2727.358,
			2728.214,
			2729.936,
			2731.671,
			2732.542,
			2734.295,
			2736.064,
			2738.74,
			2739.638,
			2740.542,
			2743.269,
			2744.183,
			2745.099,
			2747.879,
			2749.742,
			2754.46,
			2756.363,
			2759.234,
			2760.194,
			2761.155,
			2764.052,
			2765.989,
			2768.917,
			2772.852,
			2773.847,
			2775.842,
			2776.844,
			2778.852,
			2781.884,
			2783.916,
			2785.957,
			2789.028,
			2791.081,
			2794.173,
			2795.206,
			2796.241,
			2798.316,
			2799.357,
			2801.444,
			2804.581,
			2806.678,
			2808.783,
			2811.951,
			2813.012,
			2814.07,
			2818.289,
			2819.349,
			2822.529,
			2824.661,
			2826.796,
			2827.867,
			2830.017,
			2832.178,
			2833.262,
			2835.44,
			2836.535,
			2838.732,
			2840.941,
			2844.278,
			2846.517,
			2849.894,
			2851.006,
			2852.137,
			2855.546,
			2857.831,
			2861.277,
			2863.588,
			2865.91,
			2869.415,
			2872.947,
			2875.316,
			2876.505,
			2877.696,
			2881.288,
			2883.695,
			2887.326,
			2889.758,
			2892.2,
			2893.425,
			2895.881,
			2897.112,
			2899.582,
			2902.06,
			2905.79,
			2908.287,
			2913.303,
			2914.563,
			2918.357,
			2920.898,
			2924.722,
			2927.266,
			2931.046,
			2933.529,
			2934.757,
			2937.186,
			2940.76,
			2943.096,
			2945.395,
			2947.658,
			2949.888,
			2951.008,
			2953.185,
			2955.327,
			2956.387,
			2959.516,
			2961.575,
			2963.611,
			2966.659,
			2968.694,
			2971.752,
			2972.779,
			2973.806,
			2976.886,
			2978.966,
			2982.083,
			2984.182,
			2986.276,
			2987.32,
			2989.422,
			2991.517,
			2992.562,
			2995.695,
			2997.777,
			3002.987,
			3005.072,
			3008.202,
			3009.248,
			3010.295,
			3013.441,
			3015.541,
			3017.643,
			3020.778,
			3022.896,
			3026.02,
			3027.063,
			3028.108,
			3032.296,
			3033.341,
			3036.467,
			3038.573,
			3041.777,
			3043.918,
			3046.075,
			3047.161,
			3049.342,
			3050.436,
			3052.634,
			3054.842,
			3058.153,
			3060.385,
			3063.755,
			3064.884,
			3066.016,
			3069.436,
			3071.731,
			3075.201,
			3077.529,
			3079.87,
			3081.045,
			3084.585,
			3085.771,
			3089.343,
			3090.538,
			3091.736,
			3095.347,
			3097.767,
			3101.417,
			3103.863,
			3106.318,
			3107.55,
			3111.26,
			3113.745,
			3116.24,
			3122.494,
			3126.22,
			3128.675,
			3132.3,
			3134.671,
			3138.162,
			3140.443,
			3143.795,
			3145.982,
			3147.061,
			3149.19,
			3152.316,
			3153.34,
			3156.375,
			3158.35,
			3160.299,
			3161.267,
			3165.103,
			3166.055,
			3167.951,
			3168.896,
			3170.783,
			3173.602,
			3175.473,
			3177.345,
			3180.149,
			3181.083,
			3182.018,
			3184.834,
			3186.711,
			3189.542,
			3191.435,
			3193.33,
			3194.286,
			3196.196,
			3197.15,
			3199.068,
			3201.957,
			3203.886,
			3205.833,
			3208.756,
			3210.716,
			3213.662,
			3214.646,
			3215.629,
			3218.582,
			3220.555,
			3222.53,
			3225.504,
			3227.497,
			3230.495,
			3231.491,
			3232.489,
			3235.493,
			3236.496,
			3237.5,
			3240.524,
			3242.541,
			3245.581,
			3249.653,
			3250.677,
			3252.73,
			3253.759,
			3255.823,
			3257.897,
			3261.021,
			3263.116,
			3266.273,
			3267.33,
			3268.389,
			3271.579,
			3273.716,
			3276.939,
			3279.098,
			3282.349,
			3284.528,
			3287.813,
			3291.12,
			3292.208,
			3295.543,
			3297.797,
			3301.168,
			3303.438,
			3305.719,
			3306.867,
			3309.153,
			3310.309,
			3312.634,
			3314.972,
			3318.499,
			3320.864,
			3324.428,
			3325.62,
			3326.814,
			3330.406,
			3332.812,
			3336.435,
			3338.86,
			3341.294,
			3344.962,
			3348.645,
			3351.111,
			3352.347,
			3354.825,
			3357.312,
			3359.81,
			3363.573,
			3366.094,
			3368.625,
			3369.893,
			3373.711,
			3376.268,
			3378.834,
			3382.699,
			3385.285,
			3389.179,
			3390.482,
			3391.785,
			3395.695,
			3398.308,
			3402.24,
			3404.869,
			3408.826,
			3411.471,
			3412.796,
			3415.451,
			3419.444,
			3422.114,
			3424.791,
			3428.815,
			3431.504,
			3435.547,
			3438.249,
			3442.311,
			3445.026,
			3447.746,
			3451.836,
			3454.571,
			3458.684,
			3460.058,
			3461.434,
			3465.57,
			3466.952,
			3468.335,
			3472.493,
			3475.273,
			3478.059,
			3479.454,
			3482.249,
			3485.05,
			3486.453,
			3490.669,
			3493.488,
			3496.312,
			3500.56,
			3503.399,
			3507.668,
			3509.094,
			3510.521,
			3514.812,
			3517.681,
			3521.994,
			3524.877,
			3529.214,
			3532.112,
			3533.563,
			3535.016,
			3540.841,
			3542.302,
			3546.691,
			3549.625,
			3554.037,
			3556.985,
			3559.939,
			3561.418,
			3564.382,
			3565.866,
			3568.838,
			3571.816,
			3576.295,
			3579.288,
			3583.787,
			3585.291,
			3586.795,
			3591.318,
			3594.341,
			3598.886,
			3601.923,
			3606.489,
			3609.541,
			3611.069,
			3614.129,
			3617.196,
			3618.732,
			3621.807,
			3624.887,
			3629.517,
			3632.61,
			3637.26,
			3638.813,
			3640.367,
			3645.04,
			3648.165,
			3651.297,
			3656.004,
			3659.148,
			3663.874,
			3665.45,
			3667.027,
			3671.765,
			3674.928,
			3679.678,
			3686.023,
			3687.612,
			3690.793,
			3692.384,
			3695.566,
			3700.348,
			3703.542,
			3706.74,
			3711.548,
			3714.759,
			3717.976,
			3721.197,
			3722.809,
			3727.652,
			3730.887,
			3734.126,
			3738.992,
			3742.242,
			3747.124,
			3748.754,
			3750.385,
			3756.918,
			3758.554,
			3763.468,
			3766.75,
			3770.035,
			3771.68,
			3774.971,
			3778.268,
			3779.917,
			3783.22,
			3784.873,
			3788.18,
			3791.492,
			3796.468,
			3799.79,
			3804.782,
			3806.448,
			3808.115,
			3813.123,
			3816.467,
			3821.49,
			3824.844,
			3828.202,
			3833.248,
			3836.615,
			3841.674,
			3843.362,
			3845.051,
			3850.125,
			3853.512,
			3858.6,
			3861.997,
			3865.398,
			3867.1,
			3872.211,
			3875.623,
			3879.04,
			3880.894
		],
		[
			0,
			0.009,
			0.102,
			0.132,
			0.209,
			0.255,
			0.32,
			0.38,
			0.443,
			0.503,
			0.536,
			0.566,
			0.643,
			0.689,
			0.752,
			0.814,
			0.862,
			0.937,
			0.969,
			1.001,
			1.076,
			1.124,
			1.186,
			1.249,
			1.31,
			1.403,
			1.435,
			1.513,
			1.558,
			1.621,
			1.683,
			1.73,
			1.746,
			1.836,
			1.869,
			1.947,
			1.993,
			2.058,
			2.117,
			2.179,
			2.24,
			2.27,
			2.304,
			2.381,
			2.426,
			2.489,
			2.552,
			2.613,
			2.675,
			2.705,
			2.738,
			2.798,
			2.861,
			2.922,
			2.985,
			3.046,
			3.11,
			3.138,
			3.171,
			3.232,
			3.296,
			3.358,
			3.418,
			3.482,
			3.542,
			3.575,
			3.604,
			3.681,
			3.73,
			3.852,
			3.916,
			3.977,
			4.007,
			4.039,
			4.102,
			4.116,
			4.163,
			4.225,
			4.286,
			4.349,
			4.411,
			4.44,
			4.474,
			4.55,
			4.597,
			4.66,
			4.722,
			4.783,
			4.845,
			4.876,
			4.906,
			4.969,
			5.032,
			5.094,
			5.156,
			5.202,
			5.218,
			5.28,
			5.317,
			5.341,
			5.418,
			5.466,
			5.528,
			5.59,
			5.651,
			5.713,
			5.776,
			5.854,
			5.899,
			5.963,
			6.023,
			6.086,
			6.178,
			6.21,
			6.287,
			6.333,
			6.457,
			6.52,
			6.582,
			6.613,
			6.642,
			6.72,
			6.769,
			6.83,
			6.891,
			6.954,
			7.016,
			7.047,
			7.077,
			7.155,
			7.202,
			7.268,
			7.326,
			7.375,
			7.388,
			7.481,
			7.51,
			7.59,
			7.635,
			7.697,
			7.76,
			7.823,
			7.883,
			7.944,
			8.024,
			8.069,
			8.132,
			8.192,
			8.254,
			8.318,
			8.379,
			8.442,
			8.504,
			8.567,
			8.629,
			8.689,
			8.752,
			8.814,
			8.891,
			8.938,
			9.001,
			9.062,
			9.107,
			9.124,
			9.186,
			9.218,
			9.249,
			9.372,
			9.437,
			9.496,
			9.557,
			9.652,
			9.682,
			9.742,
			9.806,
			9.867,
			9.93,
			9.976,
			9.991,
			10.055,
			10.085,
			10.117,
			10.193,
			10.239,
			10.303,
			10.364,
			10.425,
			10.488,
			10.52,
			10.55,
			10.61,
			10.673,
			10.736,
			10.798,
			10.847,
			10.923,
			10.983,
			11.063,
			11.108,
			11.17,
			11.232,
			11.292,
			11.355,
			11.386,
			11.417,
			11.48,
			11.542,
			11.604,
			11.667,
			11.73,
			11.79,
			11.852,
			11.931,
			11.977,
			12.039,
			12.099,
			12.163,
			12.224,
			12.255,
			12.285,
			12.363,
			12.411,
			12.474,
			12.534,
			12.597,
			12.659,
			12.688,
			12.721,
			12.796,
			12.846,
			12.905,
			12.97,
			13.031,
			13.125,
			13.155,
			13.218,
			13.277,
			13.341,
			13.403,
			13.466,
			13.526,
			13.588,
			13.665,
			13.713,
			13.776,
			13.836,
			13.897,
			13.993,
			14.023,
			14.101,
			14.147,
			14.208,
			14.271,
			14.332,
			14.424,
			14.457,
			14.533,
			14.581,
			14.643,
			14.704,
			14.751,
			14.767,
			14.829,
			14.892,
			14.967,
			15.013,
			15.078,
			15.137,
			15.201,
			15.26,
			15.323,
			15.402,
			15.447,
			15.513,
			15.571,
			15.623,
			15.635,
			15.729,
			15.758,
			15.836,
			15.884,
			15.946,
			16.007,
			16.068,
			16.131,
			16.163,
			16.192,
			16.255,
			16.317,
			16.381,
			16.44,
			16.489,
			16.596,
			16.628,
			16.703,
			16.752,
			16.875,
			16.937,
			16.999,
			17.03,
			17.062,
			17.122,
			17.139,
			17.185,
			17.248,
			17.31,
			17.355,
			17.371,
			17.463,
			17.496,
			17.572,
			17.618,
			17.684,
			17.805,
			17.868,
			17.93,
			17.991,
			18.053,
			18.116,
			18.178,
			18.224,
			18.24,
			18.301,
			18.364,
			18.439,
			18.488,
			18.551,
			18.612,
			18.674,
			18.735,
			18.767,
			18.797,
			18.859,
			18.876,
			18.921,
			18.983,
			19.046,
			19.108,
			19.171,
			19.202,
			19.231,
			19.31,
			19.355,
			19.418,
			19.48,
			19.541,
			19.635,
			19.666,
			19.726,
			19.79,
			19.851,
			19.914,
			19.977,
			20.068,
			20.099,
			20.177,
			20.224,
			20.286,
			20.349,
			20.41,
			20.503,
			20.533,
			20.596,
			20.61,
			20.658,
			20.72,
			20.783,
			20.842,
			20.907,
			20.969,
			21.03,
			21.09,
			21.154,
			21.215,
			21.276,
			21.338,
			21.402,
			21.48,
			21.524,
			21.588,
			21.649,
			21.713,
			21.775,
			21.81,
			21.837,
			21.897,
			21.961,
			22.022,
			22.084,
			22.131,
			22.147,
			22.207,
			22.239,
			22.269,
			22.347,
			22.394,
			22.458,
			22.581,
			22.672,
			22.704,
			22.766,
			22.83,
			22.892,
			22.951,
			23.03,
			23.091,
			23.153,
			23.216,
			23.279,
			23.34,
			23.402,
			23.468,
			23.525,
			23.587,
			23.649,
			23.713,
			23.789,
			23.837,
			23.902,
			23.96,
			24.021,
			24.082,
			24.147,
			24.207,
			24.271,
			24.334,
			24.392,
			24.456,
			24.549,
			24.58,
			24.658,
			24.705,
			24.767,
			24.83,
			24.891,
			24.984,
			25.016,
			25.075,
			25.091,
			25.139,
			25.199,
			25.309,
			25.385,
			25.418,
			25.447,
			25.526,
			25.573,
			25.635,
			25.697,
			25.758,
			25.851,
			25.883,
			25.964,
			26.007,
			26.07,
			26.13,
			26.176,
			26.193,
			26.255,
			26.285,
			26.315,
			26.394,
			26.503,
			26.563,
			26.626,
			26.687,
			26.72,
			26.751,
			26.826,
			26.873,
			26.936,
			26.998,
			27.046,
			27.062,
			27.122,
			27.186,
			27.26,
			27.307,
			27.371,
			27.434,
			27.493,
			27.555,
			27.618,
			27.694,
			27.742,
			27.805,
			27.868,
			27.93,
			27.991,
			28.024,
			28.051,
			28.129,
			28.177,
			28.24,
			28.301,
			28.348,
			28.363,
			28.454,
			28.487,
			28.567,
			28.61,
			28.677,
			28.735,
			28.797,
			28.858,
			28.921,
			28.997,
			29.046,
			29.108,
			29.168,
			29.229,
			29.291,
			29.325,
			29.416,
			29.479,
			29.542,
			29.602,
			29.664,
			29.727,
			29.757,
			29.787,
			29.849,
			29.868,
			29.913,
			29.977,
			30.037,
			30.099,
			30.16,
			30.191,
			30.223,
			30.349,
			30.41,
			30.472,
			30.533,
			30.593,
			30.625,
			30.658,
			30.735,
			30.782,
			30.843,
			30.904,
			30.951,
			30.967,
			31.03,
			31.061,
			31.09,
			31.17,
			31.215,
			31.339,
			31.402,
			31.463,
			31.526,
			31.603,
			31.648,
			31.71,
			31.82,
			31.836,
			31.896,
			31.928,
			31.96,
			32.037,
			32.083,
			32.151,
			32.269,
			32.332,
			32.363,
			32.394,
			32.455,
			32.472,
			32.517,
			32.579,
			32.642,
			32.703,
			32.797,
			32.825,
			32.906,
			32.952,
			33.016,
			33.075,
			33.139,
			33.2,
			33.262,
			33.339,
			33.387,
			33.449,
			33.509,
			33.571,
			33.632,
			33.663,
			33.696,
			33.774,
			33.819,
			33.881,
			33.944,
			34.006,
			34.068,
			34.129,
			34.191,
			34.255,
			34.317,
			34.377,
			34.439,
			34.533,
			34.564,
			34.642,
			34.686,
			34.749,
			34.81,
			34.857,
			34.875,
			34.935,
			34.996,
			35.06,
			35.122,
			35.185,
			35.246,
			35.307,
			35.371,
			35.401,
			35.431,
			35.507,
			35.556,
			35.618,
			35.68,
			35.725,
			35.743,
			35.836,
			35.866,
			35.944,
			35.99,
			36.051,
			36.114,
			36.177,
			36.237,
			36.272,
			36.301,
			36.362,
			36.425,
			36.487,
			36.547,
			36.609,
			36.672,
			36.734,
			36.813,
			36.857,
			36.922,
			36.983,
			37.044,
			37.107,
			37.169,
			37.246,
			37.293,
			37.354,
			37.416,
			37.463,
			37.57,
			37.601,
			37.68,
			37.724,
			37.79,
			37.851,
			37.914,
			37.975,
			38.005,
			38.037,
			38.113,
			38.158,
			38.221,
			38.285,
			38.348,
			38.408,
			38.47,
			38.549,
			38.593,
			38.656,
			38.719,
			38.782,
			38.843,
			38.905,
			38.967,
			39.03,
			39.091,
			39.152,
			39.215,
			39.277,
			39.306,
			39.338,
			39.416,
			39.463,
			39.525,
			39.633,
			39.65,
			39.709,
			39.772,
			39.835,
			39.897,
			39.959,
			40.02,
			40.082,
			40.146,
			40.174,
			40.208,
			40.285,
			40.331,
			40.399,
			40.455,
			40.516,
			40.58,
			40.612,
			40.639,
			40.718,
			40.765,
			40.828,
			40.888,
			40.951,
			41.045,
			41.074,
			41.136,
			41.199,
			41.262,
			41.322,
			41.385,
			41.479,
			41.51,
			41.587,
			41.697,
			41.758,
			41.82,
			41.91,
			41.942,
			42.022,
			42.066,
			42.127,
			42.19,
			42.239,
			42.347,
			42.376,
			42.458,
			42.501,
			42.564,
			42.626,
			42.687,
			42.781,
			42.813,
			42.889,
			42.936,
			42.997,
			43.058,
			43.122,
			43.184,
			43.213,
			43.247,
			43.324,
			43.369,
			43.432,
			43.539,
			43.617,
			43.681,
			43.741,
			43.803,
			43.865,
			43.927,
			43.989,
			44.05,
			44.083,
			44.114,
			44.192,
			44.237,
			44.299,
			44.362,
			44.409,
			44.485,
			44.522,
			44.608,
			44.626,
			44.67,
			44.732,
			44.796,
			44.858,
			44.951,
			44.982,
			45.058,
			45.105,
			45.169,
			45.281,
			45.292,
			45.352,
			45.385,
			45.417,
			45.539,
			45.602,
			45.663,
			45.726,
			45.787,
			45.85,
			45.91,
			45.973,
			46.035,
			46.098,
			46.143,
			46.16,
			46.221,
			46.254,
			46.285,
			46.362,
			46.406,
			46.469,
			46.531,
			46.595,
			46.686,
			46.717,
			46.78,
			46.796,
			46.84,
			46.903,
			46.967,
			47.026,
			47.089,
			47.12,
			47.152,
			47.231,
			47.276,
			47.339,
			47.464,
			47.525,
			47.554,
			47.585,
			47.664,
			47.71,
			47.771,
			47.833,
			47.881,
			47.897,
			47.958,
			48.021,
			48.097,
			48.143,
			48.208,
			48.315,
			48.393,
			48.454,
			48.516,
			48.578,
			48.643,
			48.702,
			48.764,
			48.825,
			48.889,
			48.966,
			49.01,
			49.076,
			49.136,
			49.183,
			49.199,
			49.258,
			49.323,
			49.384,
			49.447,
			49.51,
			49.571,
			49.633,
			49.724,
			49.757,
			49.835,
			49.882,
			49.943,
			50.005,
			50.051,
			50.066,
			50.158,
			50.192,
			50.313,
			50.377,
			50.439,
			50.501,
			50.593,
			50.626,
			50.707,
			50.748,
			50.813,
			50.918,
			50.935,
			50.997,
			51.029,
			51.058,
			51.135,
			51.182,
			51.25,
			51.368,
			51.43,
			51.463,
			51.491,
			51.555,
			51.57,
			51.616,
			51.68,
			51.739,
			51.801,
			51.864,
			51.925,
			52.005,
			52.052,
			52.114,
			52.175,
			52.237,
			52.299,
			52.362,
			52.422,
			52.438,
			52.484,
			52.548,
			52.609,
			52.656,
			52.671,
			52.77,
			52.796,
			52.871,
			52.919,
			52.983,
			53.043,
			53.088,
			53.166,
			53.23,
			53.29,
			53.352,
			53.416,
			53.475,
			53.538,
			53.602,
			53.631,
			53.663,
			53.74,
			53.786,
			53.85,
			53.912,
			53.957,
			53.973,
			54.097,
			54.16,
			54.222,
			54.283,
			54.345,
			54.406,
			54.469,
			54.501,
			54.532,
			54.609,
			54.655,
			54.718,
			54.779,
			54.841,
			54.935,
			54.965,
			55.042,
			55.088,
			55.151,
			55.214,
			55.275,
			55.337,
			55.369,
			55.399,
			55.476,
			55.524,
			55.585,
			55.693,
			55.771,
			55.802,
			55.835,
			55.912,
			55.957,
			56.02,
			56.081,
			56.144,
			56.205,
			56.238,
			56.268,
			56.33,
			56.346,
			56.391,
			56.453,
			56.562,
			56.639,
			56.672,
			56.702,
			56.78,
			56.825,
			56.887,
			56.949,
			57.01,
			57.074,
			57.104,
			57.136,
			57.199,
			57.258,
			57.322,
			57.431,
			57.446,
			57.508,
			57.538,
			57.569,
			57.648,
			57.694,
			57.755,
			57.818,
			57.88,
			57.942,
			57.972,
			58.004,
			58.083,
			58.129,
			58.19,
			58.252,
			58.299,
			58.313,
			58.377,
			58.437,
			58.516,
			58.562,
			58.625,
			58.749,
			58.841,
			58.871,
			58.934,
			58.997,
			59.057,
			59.121,
			59.183,
			59.242,
			59.275,
			59.307,
			59.384,
			59.43,
			59.491,
			59.555,
			59.602,
			59.615,
			59.68,
			59.708,
			59.739,
			59.803,
			59.817,
			59.864,
			59.925,
			59.987,
			60.049,
			60.142,
			60.175,
			60.251,
			60.298,
			60.36,
			60.421,
			60.469,
			60.484,
			60.577,
			60.607,
			60.671,
			60.732,
			60.797,
			60.857,
			60.918,
			60.981,
			61.042,
			61.105,
			61.167,
			61.242,
			61.29,
			61.352,
			61.414,
			61.464,
			61.538,
			61.569,
			61.6,
			61.677,
			61.725,
			61.788,
			61.849,
			61.91,
			61.973,
			62.003,
			62.033,
			62.114,
			62.159,
			62.222,
			62.282,
			62.33,
			62.408,
			62.469,
			62.547,
			62.593,
			62.655,
			62.717,
			62.78,
			62.84,
			62.903,
			62.981,
			63.026,
			63.091,
			63.149,
			63.197,
			63.214,
			63.275,
			63.304,
			63.399,
			63.414,
			63.462,
			63.522,
			63.585,
			63.647,
			63.74,
			63.77,
			63.847,
			63.896,
			63.957,
			64.019,
			64.08,
			64.142,
			64.173,
			64.205,
			64.267,
			64.326,
			64.391,
			64.452,
			64.516,
			64.575,
			64.607,
			64.64,
			64.717,
			64.763,
			64.826,
			64.932,
			64.949,
			65.013,
			65.042,
			65.074,
			65.155,
			65.197,
			65.26,
			65.321,
			65.382,
			65.475,
			65.506,
			65.568,
			65.585,
			65.632,
			65.691,
			65.754,
			65.801,
			65.818,
			65.91,
			65.941,
			66.02,
			66.066,
			66.128,
			66.251,
			66.313,
			66.343,
			66.376,
			66.452,
			66.498,
			66.563,
			66.622,
			66.685,
			66.747,
			66.81,
			66.886,
			66.932,
			66.996,
			67.058,
			67.119,
			67.183,
			67.242,
			67.306,
			67.32,
			67.369,
			67.432,
			67.491,
			67.554,
			67.617,
			67.681,
			67.755,
			67.802,
			67.863,
			67.971,
			67.988,
			68.05,
			68.112,
			68.173,
			68.235,
			68.299,
			68.36,
			68.42,
			68.483,
			68.515,
			68.546,
			68.624,
			68.668,
			68.731,
			68.793,
			68.84,
			68.917,
			68.98,
			69.057,
			69.103,
			69.165,
			69.229,
			69.29,
			69.352,
			69.382,
			69.414,
			69.491,
			69.537,
			69.601,
			69.662,
			69.709,
			69.786,
			69.817,
			69.849,
			69.926,
			69.972,
			70.034,
			70.096,
			70.158,
			70.219,
			70.252,
			70.283,
			70.347,
			70.405,
			70.468,
			70.53,
			70.575,
			70.593,
			70.655,
			70.716,
			70.794,
			70.839,
			70.903,
			70.964,
			71.026,
			71.087,
			71.118,
			71.151,
			71.227,
			71.275,
			71.34,
			71.399,
			71.446,
			71.459,
			71.523,
			71.553,
			71.583,
			71.662,
			71.708,
			71.772,
			71.833,
			71.896,
			71.955,
			71.986,
			72.019,
			72.079,
			72.096,
			72.142,
			72.203,
			72.266,
			72.329,
			72.391,
			72.421,
			72.451,
			72.529,
			72.577,
			72.638,
			72.699,
			72.763,
			72.854,
			72.885,
			72.948,
			73.01,
			73.072,
			73.135,
			73.197,
			73.258,
			73.29,
			73.321,
			73.402,
			73.446,
			73.506,
			73.568,
			73.616,
			73.693,
			73.722,
			73.755,
			73.817,
			73.879,
			73.941,
			74.001,
			74.064,
			74.125,
			74.158,
			74.187,
			74.266,
			74.314,
			74.375,
			74.437,
			74.5,
			74.562,
			74.591,
			74.622,
			74.685,
			74.747,
			74.808,
			74.871,
			74.934,
			74.996,
			75.024,
			75.056,
			75.135,
			75.182,
			75.241,
			75.304,
			75.351,
			75.365,
			75.427,
			75.468,
			75.49,
			75.568,
			75.616,
			75.678,
			75.738,
			75.801,
			75.864,
			75.895,
			75.925,
			76.002,
			76.049,
			76.11,
			76.172,
			76.221,
			76.235,
			76.297,
			76.327,
			76.358,
			76.438,
			76.482,
			76.546,
			76.608,
			76.668,
			76.732,
			76.763,
			76.793,
			76.855,
			76.87,
			76.916,
			76.98,
			77.041,
			77.086,
			77.166,
			77.197,
			77.229,
			77.305,
			77.352,
			77.414,
			77.476,
			77.536,
			77.599,
			77.63,
			77.659,
			77.724,
			77.737,
			77.786,
			77.847,
			77.91,
			77.956,
			77.971,
			78.035,
			78.065,
			78.096,
			78.171,
			78.22,
			78.345,
			78.404,
			78.467,
			78.498,
			78.529,
			78.591,
			78.654,
			78.715,
			78.824,
			78.839,
			78.903,
			78.932,
			78.963,
			79.04,
			79.087,
			79.151,
			79.213,
			79.273,
			79.336,
			79.365,
			79.398,
			79.458,
			79.475,
			79.521,
			79.587,
			79.646,
			79.707,
			79.768,
			79.801,
			79.832,
			79.91,
			79.954,
			80.019,
			80.08,
			80.125,
			80.141,
			80.204,
			80.234,
			80.266,
			80.329,
			80.343,
			80.39,
			80.452,
			80.513,
			80.574,
			80.637,
			80.668,
			80.698,
			80.779,
			80.824,
			80.885,
			80.948,
			80.996,
			81.073,
			81.135,
			81.197,
			81.213,
			81.257,
			81.32,
			81.382,
			81.446,
			81.506,
			81.536,
			81.567,
			81.631,
			81.65,
			81.692,
			81.755,
			81.817,
			81.864,
			81.971,
			82.002,
			82.064,
			82.127,
			82.189,
			82.251,
			82.313,
			82.373,
			82.406,
			82.436,
			82.514,
			82.559,
			82.624,
			82.683,
			82.73,
			82.747,
			82.807,
			82.84,
			82.869,
			82.946,
			82.996,
			83.057,
			83.118,
			83.181,
			83.242,
			83.272,
			83.303,
			83.366,
			83.427,
			83.49,
			83.551,
			83.599,
			83.615,
			83.676,
			83.709,
			83.739,
			83.817,
			83.863,
			83.986,
			84.049,
			84.11,
			84.14,
			84.173,
			84.235,
			84.297,
			84.358,
			84.42,
			84.468,
			84.547,
			84.577,
			84.608,
			84.685,
			84.731,
			84.792,
			84.855,
			84.917,
			84.979,
			85.01,
			85.042,
			85.102,
			85.118,
			85.165,
			85.225,
			85.29,
			85.352,
			85.414,
			85.444,
			85.475,
			85.552,
			85.598,
			85.663,
			85.722,
			85.775,
			85.785,
			85.876,
			85.907,
			85.97,
			85.987,
			86.033,
			86.096,
			86.158,
			86.218,
			86.281,
			86.312,
			86.342,
			86.421,
			86.467,
			86.529,
			86.637,
			86.716,
			86.778,
			86.837,
			86.902,
			86.967,
			87.024,
			87.088,
			87.149,
			87.181,
			87.212,
			87.288,
			87.334,
			87.397,
			87.458,
			87.504,
			87.522,
			87.615,
			87.643,
			87.707,
			87.722,
			87.768,
			87.837,
			87.892,
			87.956,
			88.018,
			88.047,
			88.079,
			88.157,
			88.203,
			88.266,
			88.328,
			88.375,
			88.388,
			88.452,
			88.482,
			88.513,
			88.591,
			88.637,
			88.699,
			88.76,
			88.823,
			88.885,
			88.917,
			88.948,
			89.025,
			89.071,
			89.133,
			89.193,
			89.243,
			89.318,
			89.351,
			89.38,
			89.442,
			89.459,
			89.504,
			89.568,
			89.63,
			89.69,
			89.785,
			89.816,
			89.876,
			89.899,
			89.939,
			90.003,
			90.064,
			90.11,
			90.188,
			90.218,
			90.249,
			90.326,
			90.375,
			90.437,
			90.499,
			90.56,
			90.623,
			90.685,
			90.763,
			90.807,
			90.869,
			90.933,
			90.995,
			91.055,
			91.087,
			91.117,
			91.18,
			91.193,
			91.242,
			91.303,
			91.367,
			91.41,
			91.491,
			91.522,
			91.552,
			91.63,
			91.677,
			91.737,
			91.799,
			91.846,
			91.924,
			91.984,
			92.046,
			92.064,
			92.109,
			92.172,
			92.232,
			92.296,
			92.358,
			92.387,
			92.421,
			92.497,
			92.542,
			92.605,
			92.667,
			92.731,
			92.791,
			92.822,
			92.855,
			92.917,
			92.977,
			93.039,
			93.102,
			93.165,
			93.225,
			93.258,
			93.288,
			93.364,
			93.413,
			93.473,
			93.536,
			93.598,
			93.66,
			93.721,
			93.784,
			93.847,
			93.907,
			93.971,
			94.032,
			94.095,
			94.125,
			94.156,
			94.219,
			94.234,
			94.279,
			94.342,
			94.403,
			94.451,
			94.467,
			94.526,
			94.559,
			94.591,
			94.667,
			94.714,
			94.777,
			94.837,
			94.899,
			94.963,
			94.995,
			95.023,
			95.087,
			95.148,
			95.214,
			95.271,
			95.335,
			95.397,
			95.428,
			95.457,
			95.537,
			95.581,
			95.643,
			95.706,
			95.769,
			95.832,
			95.862,
			95.893,
			95.954,
			95.969,
			96.017,
			96.084,
			96.142,
			96.187,
			96.266,
			96.296,
			96.325,
			96.403,
			96.452,
			96.513,
			96.575,
			96.636,
			96.7,
			96.731,
			96.761,
			96.823,
			96.839,
			96.885,
			96.948,
			97.01,
			97.071,
			97.134,
			97.164,
			97.193,
			97.271,
			97.318,
			97.379,
			97.443,
			97.505,
			97.566,
			97.599,
			97.629,
			97.691,
			97.753,
			97.816,
			97.924,
			97.937,
			98.002,
			98.032,
			98.064,
			98.148,
			98.188,
			98.249,
			98.312,
			98.372,
			98.434,
			98.466,
			98.497,
			98.56,
			98.622,
			98.683,
			98.745,
			98.806,
			98.869,
			98.898,
			98.932,
			99.009,
			99.054,
			99.118,
			99.18,
			99.225,
			99.24,
			99.302,
			99.335,
			99.364,
			99.428,
			99.441,
			99.49,
			99.551,
			99.613,
			99.674,
			99.736,
			99.767,
			99.799,
			99.877,
			99.923,
			99.985,
			100.047,
			100.095,
			100.172,
			100.232,
			100.296,
			100.311,
			100.357,
			100.419,
			100.482,
			100.542,
			100.606,
			100.636,
			100.667,
			100.746,
			100.79,
			100.853,
			100.868
		]
	],
	'1771_1778_7982': [
		[
			106.641
		],
		[
			0
		],
		[
			0,
			1.459,
			5.062,
			10.469,
			12.272,
			14.075,
			19.488,
			23.099,
			28.517,
			32.132,
			35.749,
			41.18,
			44.804,
			53.878,
			59.334,
			62.976,
			68.443,
			72.092,
			75.743,
			77.569,
			81.225,
			83.053,
			86.712,
			90.374,
			95.872,
			99.541,
			105.051,
			106.888,
			108.727,
			114.246,
			123.461,
			127.152,
			132.694,
			138.242,
			141.944,
			145.651,
			147.505,
			149.361,
			154.939,
			162.394,
			164.26,
			167.991,
			173.588,
			179.187,
			182.921,
			186.658,
			192.266,
			196.008,
			201.623,
			203.49,
			205.354,
			210.906,
			214.57,
			220.002,
			225.37,
			228.928,
			234.256,
			237.806,
			243.115,
			246.631,
			251.854,
			255.298,
			258.712,
			263.768,
			265.436,
			268.744,
			273.637,
			276.852,
			280.032,
			283.176,
			287.825,
			292.394,
			293.901,
			295.397,
			299.835,
			302.745,
			305.62,
			309.858,
			312.66,
			318.083,
			319.421,
			323.378,
			325.967,
			329.764,
			332.244,
			335.882,
			338.266,
			339.467,
			341.805,
			345.26,
			347.519,
			349.746,
			353.02,
			354.093,
			355.156,
			358.291,
			359.315,
			360.326,
			363.304,
			365.238,
			367.131,
			369.91,
			371.734,
			374.412,
			375.294,
			376.175,
			378.78,
			380.492,
			383.068,
			384.771,
			387.35,
			389.081,
			390.818,
			391.696,
			394.35,
			396.131,
			397.951,
			400.706,
			402.568,
			405.427,
			407.341,
			410.163,
			412.047,
			414.825,
			416.662,
			418.502,
			419.384,
			421.146,
			422.027,
			423.789,
			426.361,
			427.216,
			429.787,
			431.526,
			434.138,
			435.905,
			437.691,
			438.584,
			440.376,
			441.282,
			443.1,
			444.923,
			447.675,
			449.52,
			453.241,
			454.178,
			457.005,
			458.91,
			461.774,
			463.704,
			465.646,
			466.621,
			468.57,
			471.509,
			473.483,
			474.475,
			476.468,
			478.475,
			481.517,
			483.558,
			487.676,
			488.712,
			491.824,
			493.899,
			495.973,
			499.097,
			501.198,
			504.394,
			506.527,
			509.744,
			511.892,
			515.111,
			517.257,
			520.465,
			522.601,
			523.663,
			525.782,
			528.943,
			531.033,
			533.118,
			536.244,
			537.286,
			538.327,
			541.495,
			542.554,
			546.869,
			549.051,
			552.432,
			554.705,
			557.007,
			560.491,
			561.675,
			562.86,
			567.6,
			568.784,
			572.285,
			574.589,
			576.887,
			578.033,
			580.332,
			582.623,
			583.768,
			586.062,
			587.201,
			589.476,
			591.754,
			595.176,
			597.46,
			600.897,
			602.044,
			603.192,
			606.641,
			608.949,
			612.423,
			617.109,
			618.286,
			621.844,
			623.031,
			626.583,
			627.743,
			630.09,
			632.413,
			634.707,
			638.091,
			640.3,
			642.474,
			643.546,
			646.7,
			648.753,
			650.768,
			653.717,
			655.637,
			658.454,
			659.375,
			660.287,
			662.977,
			664.729,
			667.29,
			668.951,
			671.372,
			672.94,
			675.222,
			677.423,
			678.858,
			680.243,
			681.583,
			682.913,
			683.559,
			684.825,
			686.056,
			686.659,
			688.425,
			689.566,
			690.68,
			692.315,
			693.352,
			695.374,
			695.872,
			697.361,
			698.332,
			699.764,
			701.663,
			702.138,
			703.596,
			704.606,
			706.118,
			707.128,
			708.142,
			709.753,
			710.846,
			712.492,
			713.045,
			713.599,
			715.376,
			716.584,
			717.795,
			719.655,
			720.941,
			722.871,
			723.516,
			724.163,
			726.894,
			727.58,
			729.643,
			731.089,
			733.251,
			734.69,
			736.098,
			736.794,
			738.872,
			740.256,
			742.142,
			743.373,
			746.423,
			747.578,
			749.302,
			750.454,
			752.194,
			753.359,
			755.105,
			756.273,
			758.052,
			759.839,
			760.436,
			762.861,
			763.476,
			765.332,
			766.579,
			768.49,
			769.781,
			771.081,
			771.735,
			773.055,
			773.719,
			775.057,
			776.407,
			778.451,
			779.814,
			781.903,
			782.607,
			783.314,
			785.454,
			786.899,
			789.095,
			790.579,
			792.832,
			794.357,
			796.675,
			798.237,
			799.024,
			799.819,
			802.234,
			804.667,
			806.314,
			807.972,
			809.641,
			810.484,
			813.027,
			814.721,
			816.416,
			818.969,
			820.682,
			824.124,
			824.988,
			827.59,
			829.327,
			831.937,
			833.697,
			836.324,
			838.092,
			838.996,
			840.794,
			843.475,
			845.302,
			847.106,
			850.714,
			854.288,
			855.156,
			856.021,
			857.746,
			858.605,
			860.323,
			862.035,
			864.59,
			866.286,
			868.831,
			869.682,
			870.541,
			873.121,
			874.846,
			877.468,
			881.863,
			883.647,
			886.344,
			888.151,
			889.052,
			890.866,
			892.636,
			895.28,
			897.061,
			899.663,
			901.411,
			904.033,
			905.774,
			908.41,
			910.17,
			911.937,
			914.61,
			915.506,
			916.404,
			919.115,
			920.025,
			920.94,
			923.702,
			925.561,
			928.365,
			930.262,
			932.171,
			933.13,
			936.025,
			937.953,
			939.881,
			942.795,
			944.754,
			949.739,
			952.865,
			954.964,
			958.19,
			960.343,
			963.488,
			966.645,
			968.764,
			971.962,
			973.033,
			976.261,
			980.599,
			981.688,
			983.875,
			987.168,
			989.374,
			990.48,
			992.7,
			994.929,
			998.294,
			1000.55,
			1003.952,
			1006.232,
			1009.668,
			1011.971,
			1015.442,
			1017.768,
			1020.103,
			1021.274,
			1023.622,
			1024.8,
			1027.16,
			1030.707,
			1033.079,
			1035.458,
			1039.047,
			1041.453,
			1045.086,
			1046.302,
			1047.52,
			1051.193,
			1053.653,
			1056.125,
			1059.851,
			1062.347,
			1066.103,
			1067.359,
			1068.616,
			1072.397,
			1074.927,
			1078.735,
			1081.285,
			1085.124,
			1088.98,
			1091.558,
			1094.144,
			1095.439,
			1098.035,
			1100.64,
			1104.554,
			1107.174,
			1111.121,
			1112.442,
			1113.763,
			1117.735,
			1120.389,
			1127.031,
			1129.695,
			1135.043,
			1136.384,
			1140.419,
			1143.118,
			1147.179,
			1149.895,
			1152.617,
			1153.98,
			1159.449,
			1160.82,
			1164.942,
			1167.699,
			1170.463,
			1174.622,
			1177.403,
			1181.588,
			1182.986,
			1184.386,
			1188.595,
			1191.408,
			1195.638,
			1198.465,
			1202.717,
			1205.561,
			1209.839,
			1214.133,
			1215.568,
			1219.883,
			1225.658,
			1227.106,
			1230.006,
			1232.914,
			1234.37,
			1237.287,
			1238.748,
			1241.674,
			1246.076,
			1249.019,
			1251.969,
			1256.404,
			1257.886,
			1259.37,
			1263.831,
			1266.813,
			1271.299,
			1274.298,
			1277.304,
			1278.809,
			1281.825,
			1286.362,
			1289.395,
			1290.913,
			1293.955,
			1297.002,
			1301.56,
			1304.575,
			1307.559,
			1310.504,
			1311.963,
			1316.282,
			1319.113,
			1321.908,
			1328.745,
			1332.738,
			1335.35,
			1339.194,
			1340.457,
			1341.711,
			1345.415,
			1347.838,
			1351.409,
			1353.746,
			1354.902,
			1357.188,
			1360.546,
			1362.738,
			1364.894,
			1368.06,
			1370.124,
			1374.142,
			1375.124,
			1378.016,
			1379.898,
			1382.659,
			1384.458,
			1386.225,
			1388.816,
			1389.661,
			1390.497,
			1393.748,
			1394.538,
			1396.851,
			1398.345,
			1399.804,
			1400.52,
			1401.923,
			1403.292,
			1403.963,
			1405.929,
			1407.196,
			1408.426,
			1410.202,
			1411.355,
			1413.021,
			1413.564,
			1414.101,
			1415.686,
			1416.721,
			1418.198,
			1419.17,
			1420.139,
			1421.592,
			1423,
			1424.398,
			1424.864,
			1426.738,
			1427.227,
			1428.711,
			1429.701,
			1431.196,
			1433.29,
			1433.825,
			1435.432,
			1436.512,
			1437.667,
			1439.466,
			1440.664,
			1442.523,
			1443.211,
			1443.896,
			1445.942,
			1447.305,
			1449.372,
			1450.73,
			1452.738,
			1454.015,
			1454.647,
			1455.898,
			1457.141,
			1458.937,
			1460.113,
			1462.432,
			1462.983,
			1464.071,
			1465.144,
			1465.677,
			1467.27,
			1469.292,
			1470.781,
			1471.769,
			1473.267,
			1473.771,
			1474.273,
			1475.772,
			1476.767,
			1478.286,
			1479.326,
			1480.875,
			1481.904,
			1483.447,
			1484.992,
			1487.075,
			1488.667,
			1489.75,
			1491.393,
			1491.947,
			1492.503,
			1494.193,
			1495.349,
			1497.113,
			1498.307,
			1499.512,
			1501.347,
			1501.966,
			1502.59,
			1505.125,
			1505.769,
			1507.724,
			1509.051,
			1511.083,
			1513.846,
			1514.549,
			1515.965,
			1518.118,
			1519.573,
			1521.786,
			1523.28,
			1526.312,
			1527.079,
			1529.403,
			1530.97,
			1533.347,
			1534.95,
			1537.378,
			1539.838,
			1541.49,
			1543.156,
			1543.993,
			1544.833,
			1547.354,
			1549.034,
			1550.724,
			1551.575,
			1553.285,
			1555.874,
			1558.494,
			1560.256,
			1562.031,
			1564.717,
			1566.523,
			1569.254,
			1571.089,
			1573.865,
			1575.731,
			1578.551,
			1580.446,
			1583.31,
			1585.234,
			1586.2,
			1588.14,
			1591.072,
			1593.04,
			1595.02,
			1597.01,
			1599.012,
			1600.016,
			1602.033,
			1604.061,
			1605.077,
			1608.14,
			1610.193,
			1613.288,
			1615.363,
			1617.448,
			1621.619,
			1622.661,
			1625.795,
			1627.891,
			1631.047,
			1633.158,
			1636.337,
			1639.532,
			1641.675,
			1643.827,
			1644.907,
			1647.078,
			1649.258,
			1652.554,
			1654.766,
			1659.226,
			1660.348,
			1663.736,
			1666.008,
			1668.315,
			1671.747,
			1674.064,
			1677.554,
			1678.741,
			1679.91,
			1683.422,
			1685.768,
			1689.291,
			1691.641,
			1695.164,
			1698.69,
			1701.045,
			1704.587,
			1706.957,
			1709.314,
			1712.893,
			1715.292,
			1718.91,
			1721.339,
			1725.002,
			1727.458,
			1731.167,
			1733.653,
			1737.394,
			1739.896,
			1741.149,
			1742.403,
			1747.438,
			1748.701,
			1752.502,
			1755.045,
			1757.608,
			1758.893,
			1761.468,
			1765.345,
			1767.929,
			1769.224,
			1771.819,
			1774.417,
			1778.33,
			1780.941,
			1784.861,
			1786.171,
			1787.482,
			1791.425,
			1794.06,
			1798.027,
			1800.681,
			1803.343,
			1804.677,
			1807.351,
			1808.691,
			1814.069,
			1815.417,
			1818.116,
			1820.815,
			1823.513,
			1827.546,
			1830.216,
			1832.863,
			1834.175,
			1838.061,
			1840.605,
			1843.111,
			1846.802,
			1849.219,
			1853.978,
			1855.142,
			1858.585,
			1859.713,
			1860.833,
			1864.147,
			1866.316,
			1869.529,
			1871.654,
			1872.71,
			1874.828,
			1878.026,
			1880.174,
			1882.344,
			1885.604,
			1886.694,
			1887.785,
			1891.06,
			1892.153,
			1893.246,
			1896.531,
			1898.724,
			1900.925,
			1904.232,
			1906.476,
			1909.86,
			1911.012,
			1912.163,
			1915.612,
			1916.755,
			1917.898,
			1921.297,
			1923.531,
			1925.761,
			1926.874,
			1929.097,
			1931.301,
			1932.393,
			1935.655,
			1937.782,
			1939.906,
			1943.049,
			1945.138,
			1948.238,
			1949.276,
			1950.313,
			1953.421,
			1955.515,
			1958.634,
			1960.722,
			1962.815,
			1965.97,
			1967.027,
			1968.087,
			1972.348,
			1973.419,
			1976.635,
			1978.786,
			1981.993,
			1984.094,
			1986.187,
			1987.222,
			1989.265,
			1990.273,
			1992.262,
			1994.219,
			1998.954,
			2001.693,
			2003.48,
			2006.113,
			2007.819,
			2010.331,
			2014.404,
			2016.002,
			2016.797,
			2018.362,
			2019.902,
			2022.202,
			2023.711,
			2025.2,
			2027.431,
			2030.416,
			2031.161,
			2033.412,
			2034.948,
			2036.484,
			2038.823,
			2040.423,
			2042.825,
			2043.654,
			2044.498,
			2047.028,
			2048.735,
			2051.402,
			2053.177,
			2054.966,
			2057.642,
			2058.533,
			2060.288,
			2062.896,
			2064.653,
			2066.439,
			2069.077,
			2070.973,
			2074.726,
			2075.706,
			2078.622,
			2080.504,
			2083.264,
			2086.605,
			2088.997,
			2089.766,
			2092.694,
			2093.422,
			2094.148,
			2096.308,
			2097.736,
			2099.863,
			2101.969,
			2103.369,
			2105.46,
			2106.836,
			2108.212,
			2110.278,
			2111.669,
			2113.755,
			2114.451,
			2115.147,
			2117.316,
			2118.768,
			2120.972,
			2122.468,
			2124.733,
			2126.252,
			2127.011,
			2127.774,
			2130.855,
			2131.628,
			2133.959,
			2135.53,
			2137.092,
			2137.883,
			2139.473,
			2141.076,
			2141.882,
			2144.317,
			2145.956,
			2147.616,
			2150.135,
			2151.83,
			2154.407,
			2155.272,
			2156.14,
			2158.762,
			2160.526,
			2163.191,
			2164.978,
			2166.779,
			2167.683,
			2169.496,
			2170.408,
			2172.24,
			2175.004,
			2178.732,
			2180.613,
			2183.459,
			2185.374,
			2187.302,
			2188.27,
			2190.219,
			2193.167,
			2195.148,
			2198.145,
			2200.159,
			2203.205,
			2205.252,
			2208.345,
			2210.423,
			2213.552,
			2215.639,
			2218.792,
			2220.907,
			2221.97,
			2224.105,
			2226.25,
			2227.329,
			2229.494,
			2231.67,
			2233.858,
			2237.162,
			2240.495,
			2241.612,
			2242.731,
			2246.109,
			2248.376,
			2250.656,
			2254.097,
			2256.406,
			2259.893,
			2261.061,
			2262.232,
			2265.763,
			2268.131,
			2271.706,
			2274.103,
			2276.512,
			2277.72,
			2280.145,
			2281.362,
			2283.805,
			2287.49,
			2289.96,
			2292.442,
			2296.185,
			2298.693,
			2302.471,
			2303.736,
			2305.002,
			2308.812,
			2311.367,
			2315.218,
			2317.801,
			2320.395,
			2321.695,
			2324.297,
			2325.601,
			2326.907,
			2332.158,
			2333.476,
			2337.448,
			2340.128,
			2344.126,
			2346.801,
			2349.481,
			2350.825,
			2353.518,
			2354.865,
			2357.566,
			2360.265,
			2364.326,
			2367.043,
			2371.135,
			2372.503,
			2373.874,
			2378.001,
			2380.761,
			2384.913,
			2387.688,
			2391.876,
			2394.683,
			2396.098,
			2398.933,
			2401.793,
			2403.229,
			2406.113,
			2409.018,
			2411.938,
			2414.877,
			2416.35,
			2422.275,
			2423.763,
			2428.225,
			2431.2,
			2434.166,
			2438.609,
			2441.57,
			2446.006,
			2447.485,
			2448.965,
			2453.408,
			2456.381,
			2460.834,
			2466.812,
			2468.288,
			2471.212,
			2472.664,
			2475.51,
			2479.707,
			2482.455,
			2485.146,
			2489.179,
			2491.848,
			2494.534,
			2497.195,
			2498.523,
			2502.511,
			2505.164,
			2507.812,
			2511.755,
			2514.418,
			2518.39,
			2519.715,
			2521.043,
			2525.013,
			2527.684,
			2531.725,
			2534.414,
			2538.459,
			2541.163,
			2542.494,
			2545.206,
			2549.275,
			2551.995,
			2554.721,
			2561.569,
			2565.698,
			2567.079,
			2568.462,
			2572.623,
			2575.406,
			2579.574,
			2582.333,
			2585.068,
			2589.107,
			2590.437,
			2591.757,
			2595.671,
			2596.958,
			2598.235,
			2602.04,
			2604.54,
			2608.267,
			2613.099,
			2614.285,
			2617.789,
			2620.077,
			2622.325,
			2625.629,
			2627.792,
			2632.078,
			2633.146,
			2636.357,
			2638.505,
			2641.745,
			2643.917,
			2646.1,
			2647.199,
			2649.4,
			2651.616,
			2656.08,
			2657.208,
			2660.578,
			2662.785,
			2666.071,
			2668.181,
			2671.264,
			2673.23,
			2674.196,
			2676.101,
			2677.947,
			2680.643,
			2682.396,
			2684.958,
			2685.793,
			2686.619,
			2689.044,
			2690.628,
			2692.914,
			2694.39,
			2695.828,
			2696.533,
			2698.601,
			2699.939,
			2701.25,
			2701.896,
			2703.179,
			2704.438,
			2705.67,
			2707.487,
			2709.289,
			2710.472,
			2712.232,
			2713.409,
			2714.584,
			2716.335,
			2717.502,
			2720.46,
			2722.235,
			2723.438,
			2725.272,
			2726.498,
			2728.353,
			2729.631,
			2730.271,
			2731.557,
			2733.506,
			2734.843,
			2736.188,
			2738.224,
			2739.618,
			2742.441,
			2743.155,
			2745.347,
			2746.828,
			2749.084,
			2750.617,
			2752.167,
			2755.29,
			2756.074,
			2759.24,
			2760.015,
			2762.363,
			2763.947,
			2765.479,
			2766.247,
			2769.335,
			2770.087,
			2771.594,
			2772.349,
			2773.863,
			2775.39,
			2777.691,
			2779.229,
			2781.597,
			2782.392,
			2783.187,
			2785.588,
			2787.208,
			2789.638,
			2792.899,
			2793.715,
			2795.347,
			2796.162,
			2797.789,
			2800.227,
			2801.861,
			2803.499,
			2805.126,
			2807.618,
			2809.302,
			2811.85,
			2813.553,
			2816.136,
			2817.861,
			2820.5,
			2822.275,
			2825.868,
			2826.776,
			2829.516,
			2831.362,
			2834.153,
			2836.031,
			2838.868,
			2840.772,
			2841.73,
			2843.656,
			2845.597,
			2848.537,
			2850.52,
			2853.522,
			2854.53,
			2855.542,
			2858.601,
			2860.659,
			2863.775,
			2867.958,
			2871.119,
			2873.245,
			2877.56,
			2878.644,
			2881.919,
			2884.12,
			2887.448,
			2889.683,
			2891.928,
			2893.053,
			2896.446,
			2898.71,
			2902.125,
			2904.403,
			2906.691,
			2910.135,
			2912.442,
			2915.942,
			2917.113,
			2918.299,
			2921.924,
			2924.383,
			2928.12,
			2930.674,
			2933.243,
			2937.134,
			2939.724,
			2944.845,
			2949.898,
			2952.391,
			2956.101,
			2958.561,
			2962.247,
			2964.702,
			2965.929,
			2968.395,
			2970.857,
			2974.52,
			2976.951,
			2980.564,
			2981.768,
			2982.963,
			2986.548,
			2988.935,
			2992.523,
			2994.938,
			2998.575,
			3001.008,
			3002.229,
			3003.445,
			3007.07,
			3010.723,
			3013.148,
			3015.561,
			3019.19,
			3021.603,
			3025.234,
			3028.871,
			3031.309,
			3033.755,
			3037.448,
			3039.921,
			3043.658,
			3044.887,
			3046.16,
			3049.935,
			3052.462,
			3056.268,
			3058.816,
			3062.623,
			3066.433,
			3068.95,
			3072.677,
			3075.121,
			3077.527,
			3081.071,
			3083.388,
			3085.668,
			3087.91,
			3089.016,
			3091.2,
			3092.278,
			3094.405,
			3096.494,
			3099.558,
			3101.553,
			3104.474,
			3106.373,
			3109.15,
			3110.955,
			3113.597,
			3115.306,
			3117.799,
			3121.752,
			3123.267,
			3124.01,
			3125.472,
			3126.896,
			3128.974,
			3130.308,
			3132.245,
			3132.875,
			3133.498,
			3135.315,
			3136.486,
			3138.199,
			3139.319,
			3140.417,
			3142.034,
			3142.567,
			3144.692,
			3145.22,
			3145.747,
			3147.32,
			3148.365,
			3149.94,
			3151.003,
			3152.593,
			3154.185,
			3155.271,
			3156.379,
			3158.042,
			3158.598,
			3159.156,
			3160.877,
			3162.044,
			3163.802,
			3164.986,
			3166.804,
			3168.022,
			3169.87,
			3171.147,
			3171.792,
			3173.088,
			3175.058,
			3175.755,
			3177.89,
			3180.791,
			3181.536,
			3184.529,
			3185.282,
			3187.591,
			3189.125,
			3190.666,
			3193.026,
			3194.582,
			3196.911,
			3197.684,
			3198.453,
			3200.742,
			3202.25,
			3204.491,
			3205.973,
			3207.417,
			3208.137,
			3210.295,
			3211.709,
			3213.781,
			3215.16,
			3216.527,
			3218.482,
			3219.784,
			3222.36,
			3222.992,
			3224.886,
			3226.151,
			3227.417,
			3229.32,
			3230.599,
			3232.539,
			3233.845,
			3235.829,
			3237.172,
			3239.234,
			3240.628,
			3242.756,
			3244.204,
			3246.406,
			3248.647,
			3250.163,
			3251.694,
			3254.021,
			3254.804,
			3255.591,
			3258.779,
			3259.587,
			3262.032,
			3263.681,
			3267.865,
			3269.547,
			3272.06,
			3273.76,
			3277.192,
			3278.058,
			3280.672,
			3282.43,
			3284.2,
			3285.088,
			3286.874,
			3287.772,
			3289.577,
			3292.307,
			3294.143,
			3295.991,
			3298.788,
			3300.669,
			3303.514,
			3304.465,
			3305.418,
			3308.277,
			3310.168,
			3312.98,
			3314.832,
			3317.568,
			3320.235,
			3321.107,
			3324.515,
			3325.349,
			3327.829,
			3331.92,
			3333.55,
			3335.177,
			3335.991,
			3337.619,
			3338.433,
			3340.059,
			3341.685,
			3344.101,
			3345.706,
			3348.888,
			3349.677,
			3352.042,
			3353.626,
			3355.999,
			3357.583,
			3359.206,
			3360.016,
			3362.448,
			3364.132,
			3365.84,
			3368.418,
			3370.172,
			3373.689,
			3374.571,
			3376.339,
			3379.011,
			3381.746,
			3383.575,
			3386.425,
			3388.327,
			3390.243,
			3393.115,
			3394.073,
			3395.026,
			3397.882,
			3399.797,
			3402.708,
			3404.646,
			3406.571,
			3407.532,
			3409.455,
			3410.424,
			3412.361,
			3415.256,
			3417.157,
			3419.057,
			3421.835,
			3423.667,
			3427.26,
			3428.148,
			3430.795,
			3432.537,
			3435.148,
			3436.885,
			3438.621,
			3441.228,
			3442.097,
			3442.966,
			3445.578,
			3447.328,
			3449.959,
			3454.363,
			3456.132,
			3457.903,
			3458.79,
			3461.463,
			3463.25,
			3465.045,
			3467.756,
			3469.573,
			3472.321,
			3473.243,
			3474.168,
			3476.959,
			3478.835,
			3481.673,
			3486.46,
			3489.371,
			3491.325,
			3493.292,
			3494.279,
			3495.269,
			3498.25,
			3500.245,
			3503.25,
			3505.257,
			3507.269,
			3508.277,
			3511.298,
			3513.315,
			3515.331,
			3518.357,
			3520.369,
			3524.391,
			3525.395,
			3528.41,
			3530.417,
			3533.432,
			3535.45,
			3538.486,
			3540.53,
			3541.555,
			3543.602,
			3546.716,
			3548.808,
			3550.922,
			3554.119,
			3555.189,
			3558.401,
			3560.553,
			3561.625,
			3563.756,
			3567,
			3569.177,
			3572.469,
			3574.687,
			3578.043,
			3579.172,
			3580.303,
			3583.719,
			3586.012,
			3589.476,
			3594.135,
			3595.306,
			3598.837,
			3601.206,
			3603.586,
			3604.783,
			3607.185,
			3609.599,
			3613.243,
			3615.684,
			3619.362,
			3621.822,
			3625.529,
			3628.009,
			3630.497,
			3634.243,
			3636.75,
			3640.526,
			3643.055,
			3646.863,
			3648.136,
			3649.411,
			3653.25,
			3655.821,
			3659.692,
			3662.283,
			3666.185,
			3670.1,
			3672.71,
			3675.32,
			3681.872,
			3685.821,
			3687.16,
			3688.477,
			3692.414,
			3695.018,
			3698.88,
			3701.41,
			3705.133,
			3707.561,
			3708.761,
			3711.113,
			3714.627,
			3715.779,
			3719.189,
			3723.661,
			3724.762,
			3729.134,
			3730.214,
			3732.375,
			3733.451,
			3735.598,
			3738.825,
			3740.973,
			3743.123,
			3746.342,
			3747.414,
			3748.484,
			3751.687,
			3753.813,
			3757.003,
			3759.133,
			3761.268,
			3764.494,
			3765.574,
			3767.746,
			3769.932,
			3771.029,
			3773.234,
			3775.451,
			3777.681,
			3781.054,
			3785.59,
			3786.731,
			3790.183,
			3792.5,
			3794.828,
			3798.341,
			3800.702,
			3804.269,
			3806.667,
			3810.295,
			3812.732,
			3816.425,
			3818.906,
			3822.636,
			3825.133,
			3826.384,
			3828.893,
			3831.411,
			3832.673,
			3835.21,
			3837.761,
			3841.647,
			3844.268,
			3848.258,
			3849.593,
			3850.939,
			3854.992,
			3857.683,
			3861.7,
			3864.35,
			3866.997,
			3870.96,
			3873.609,
			3877.6,
			3880.274,
			3884.302,
			3886.993,
			3889.684,
			3891.031,
			3893.74,
			3897.815,
			3900.533,
			3904.63,
			3907.369,
			3911.462,
			3914.193,
			3918.293,
			3921.021,
			3925.11,
			3927.83,
			3931.925,
			3934.665,
			3937.422,
			3938.802,
			3942.962,
			3944.351,
			3949.886,
			3951.267,
			3955.395,
			3958.129,
			3962.218,
			3967.683,
			3969.053,
			3973.163,
			3975.901,
			3978.633,
			3982.727,
			3985.445,
			3989.532,
			3992.261,
			3999.113,
			4003.242,
			4006.003,
			4010.156,
			4014.321,
			4017.103,
			4019.866,
			4024.075,
			4026.871,
			4031.069,
			4033.863,
			4039.458,
			4040.858,
			4045.074,
			4047.894,
			4050.731,
			4054.994,
			4057.839,
			4062.108,
			4063.533,
			4064.959,
			4069.255,
			4072.136,
			4076.459,
			4079.406,
			4083.805,
			4086.75,
			4091.157,
			4095.604,
			4098.575,
			4101.551,
			4106.024,
			4109.009,
			4113.495,
			4116.491,
			4120.999,
			4124.013,
			4127.033,
			4131.576,
			4134.61,
			4140.684,
			4142.203,
			4146.768,
			4149.816,
			4154.403,
			4162.081,
			4168.25,
			4169.796,
			4172.891,
			4177.541,
			4180.645,
			4185.309,
			4188.423,
			4193.103,
			4194.665,
			4196.229,
			4200.924,
			4204.058,
			4208.768,
			4211.912,
			4216.636,
			4219.79,
			4224.527,
			4229.274,
			4230.859,
			4235.619,
			4238.796,
			4243.567,
			4246.749,
			4249.934,
			4251.527,
			4256.314,
			4259.51,
			4262.708,
			4267.514,
			4270.722,
			4275.542,
			4277.15,
			4278.759,
			4283.593,
			4286.821,
			4291.67,
			4294.906,
			4298.146,
			4299.769,
			4303.016,
			4304.641,
			4307.895,
			4312.781,
			4316.044,
			4319.312,
			4324.227,
			4327.516,
			4330.81,
			4334.109,
			4335.759,
			4340.711,
			4344.013,
			4347.318,
			4352.279,
			4355.591,
			4362.227,
			4363.889,
			4368.879,
			4372.211,
			4377.216,
			4385.576,
			4388.927,
			4393.961,
			4399.004,
			4402.371,
			4405.741,
			4410.804,
			4414.186,
			4419.263,
			4422.653,
			4427.745,
			4431.146,
			4434.548,
			4439.66,
			4443.073,
			4448.2,
			4453.336,
			4456.765,
			4460.197,
			4465.352,
			4468.793,
			4473.953,
			4477.392,
			4482.552,
			4485.997,
			4489.447,
			4494.641,
			4496.38,
			4499.865,
			4503.358,
			4505.106,
			4508.605,
			4512.104,
			4517.355,
			4520.857,
			4526.113,
			4527.866,
			4529.62,
			4534.887,
			4538.402,
			4543.682,
			4547.205,
			4550.733,
			4557.799,
			4559.568,
			4566.648,
			4568.419,
			4573.739,
			4577.29,
			4582.624,
			4586.183,
			4591.531,
			4596.887,
			4600.46,
			4604.038,
			4609.41,
			4612.996,
			4618.389,
			4621.992,
			4623.46
		],
		[
			0,
			0.017,
			0.08,
			0.141,
			0.172,
			0.202,
			0.278,
			0.326,
			0.389,
			0.449,
			0.498,
			0.574,
			0.637,
			0.761,
			0.822,
			0.884,
			0.948,
			1.009,
			1.044,
			1.072,
			1.133,
			1.149,
			1.193,
			1.258,
			1.318,
			1.367,
			1.444,
			1.475,
			1.505,
			1.582,
			1.69,
			1.754,
			1.815,
			1.907,
			1.94,
			2.001,
			2.018,
			2.061,
			2.124,
			2.234,
			2.25,
			2.312,
			2.374,
			2.45,
			2.497,
			2.56,
			2.622,
			2.683,
			2.746,
			2.777,
			2.806,
			2.869,
			2.932,
			3.01,
			3.071,
			3.133,
			3.194,
			3.257,
			3.334,
			3.382,
			3.444,
			3.506,
			3.551,
			3.629,
			3.659,
			3.691,
			3.769,
			3.815,
			3.875,
			3.937,
			4.002,
			4.064,
			4.094,
			4.124,
			4.202,
			4.249,
			4.313,
			4.374,
			4.419,
			4.529,
			4.56,
			4.622,
			4.681,
			4.744,
			4.808,
			4.868,
			4.932,
			4.963,
			4.995,
			5.072,
			5.117,
			5.186,
			5.241,
			5.288,
			5.304,
			5.365,
			5.396,
			5.426,
			5.505,
			5.552,
			5.611,
			5.674,
			5.738,
			5.799,
			5.831,
			5.861,
			5.922,
			5.986,
			6.049,
			6.108,
			6.172,
			6.232,
			6.264,
			6.293,
			6.372,
			6.419,
			6.483,
			6.543,
			6.605,
			6.668,
			6.73,
			6.791,
			6.853,
			6.916,
			6.977,
			7.022,
			7.04,
			7.101,
			7.132,
			7.164,
			7.243,
			7.286,
			7.35,
			7.41,
			7.474,
			7.536,
			7.565,
			7.597,
			7.66,
			7.675,
			7.721,
			7.785,
			7.846,
			7.893,
			7.999,
			8.031,
			8.108,
			8.156,
			8.217,
			8.279,
			8.325,
			8.342,
			8.405,
			8.466,
			8.528,
			8.542,
			8.589,
			8.653,
			8.714,
			8.775,
			8.869,
			8.898,
			8.978,
			9.023,
			9.087,
			9.148,
			9.194,
			9.272,
			9.336,
			9.397,
			9.459,
			9.521,
			9.582,
			9.644,
			9.706,
			9.737,
			9.769,
			9.846,
			9.893,
			9.955,
			10.016,
			10.06,
			10.077,
			10.139,
			10.172,
			10.264,
			10.327,
			10.389,
			10.45,
			10.513,
			10.575,
			10.606,
			10.637,
			10.715,
			10.76,
			10.823,
			10.885,
			10.932,
			10.947,
			11.007,
			11.039,
			11.071,
			11.132,
			11.148,
			11.193,
			11.255,
			11.317,
			11.381,
			11.441,
			11.472,
			11.504,
			11.566,
			11.628,
			11.691,
			11.799,
			11.814,
			11.906,
			11.938,
			12.001,
			12.016,
			12.064,
			12.125,
			12.187,
			12.251,
			12.31,
			12.34,
			12.372,
			12.45,
			12.498,
			12.558,
			12.621,
			12.667,
			12.744,
			12.774,
			12.807,
			12.87,
			12.931,
			12.992,
			13.053,
			13.117,
			13.176,
			13.24,
			13.319,
			13.366,
			13.432,
			13.488,
			13.535,
			13.551,
			13.611,
			13.642,
			13.675,
			13.752,
			13.8,
			13.862,
			13.922,
			13.986,
			14.076,
			14.108,
			14.187,
			14.233,
			14.293,
			14.404,
			14.418,
			14.513,
			14.542,
			14.621,
			14.668,
			14.727,
			14.792,
			14.853,
			14.916,
			14.947,
			14.976,
			15.052,
			15.1,
			15.161,
			15.224,
			15.286,
			15.348,
			15.38,
			15.409,
			15.493,
			15.536,
			15.598,
			15.66,
			15.719,
			15.782,
			15.814,
			15.846,
			15.921,
			15.967,
			16.04,
			16.092,
			16.215,
			16.278,
			16.342,
			16.403,
			16.466,
			16.528,
			16.59,
			16.65,
			16.714,
			16.806,
			16.837,
			16.916,
			16.962,
			17.024,
			17.086,
			17.148,
			17.209,
			17.24,
			17.272,
			17.333,
			17.349,
			17.394,
			17.457,
			17.519,
			17.583,
			17.644,
			17.674,
			17.706,
			17.782,
			17.828,
			17.892,
			17.953,
			18.015,
			18.077,
			18.14,
			18.202,
			18.216,
			18.261,
			18.325,
			18.389,
			18.45,
			18.51,
			18.542,
			18.574,
			18.652,
			18.698,
			18.761,
			18.821,
			18.867,
			18.975,
			19.007,
			19.086,
			19.133,
			19.194,
			19.256,
			19.315,
			19.378,
			19.41,
			19.443,
			19.519,
			19.566,
			19.634,
			19.736,
			19.814,
			19.844,
			19.875,
			19.938,
			19.953,
			19.999,
			20.061,
			20.122,
			20.186,
			20.248,
			20.277,
			20.309,
			20.386,
			20.434,
			20.494,
			20.619,
			20.683,
			20.743,
			20.805,
			20.821,
			20.868,
			20.931,
			20.992,
			21.053,
			21.116,
			21.178,
			21.256,
			21.301,
			21.364,
			21.424,
			21.487,
			21.55,
			21.582,
			21.611,
			21.673,
			21.694,
			21.736,
			21.8,
			21.861,
			21.923,
			21.984,
			22.014,
			22.046,
			22.125,
			22.17,
			22.232,
			22.294,
			22.34,
			22.478,
			22.542,
			22.603,
			22.668,
			22.728,
			22.789,
			22.883,
			22.915,
			22.992,
			23.038,
			23.099,
			23.207,
			23.223,
			23.285,
			23.348,
			23.41,
			23.426,
			23.473,
			23.538,
			23.597,
			23.658,
			23.72,
			23.781,
			23.845,
			23.906,
			23.969,
			24.032,
			24.078,
			24.092,
			24.155,
			24.186,
			24.215,
			24.292,
			24.341,
			24.402,
			24.465,
			24.527,
			24.589,
			24.62,
			24.649,
			24.726,
			24.773,
			24.838,
			24.899,
			24.946,
			25.022,
			25.054,
			25.086,
			25.148,
			25.209,
			25.276,
			25.332,
			25.394,
			25.487,
			25.519,
			25.581,
			25.594,
			25.642,
			25.706,
			25.766,
			25.828,
			25.891,
			25.923,
			25.954,
			26.031,
			26.078,
			26.201,
			26.261,
			26.356,
			26.387,
			26.448,
			26.511,
			26.576,
			26.634,
			26.681,
			26.698,
			26.791,
			26.822,
			26.898,
			26.943,
			27.008,
			27.069,
			27.133,
			27.192,
			27.222,
			27.255,
			27.317,
			27.38,
			27.442,
			27.504,
			27.565,
			27.627,
			27.69,
			27.766,
			27.812,
			27.878,
			27.985,
			28.001,
			28.061,
			28.092,
			28.124,
			28.186,
			28.201,
			28.248,
			28.314,
			28.37,
			28.432,
			28.493,
			28.525,
			28.557,
			28.636,
			28.683,
			28.744,
			28.806,
			28.853,
			28.867,
			28.928,
			28.99,
			29.054,
			29.07,
			29.115,
			29.176,
			29.24,
			29.301,
			29.363,
			29.393,
			29.425,
			29.504,
			29.548,
			29.611,
			29.719,
			29.798,
			29.858,
			29.922,
			29.94,
			29.983,
			30.046,
			30.106,
			30.171,
			30.232,
			30.263,
			30.294,
			30.372,
			30.419,
			30.48,
			30.543,
			30.603,
			30.697,
			30.728,
			30.79,
			30.852,
			30.915,
			30.976,
			31.035,
			31.101,
			31.131,
			31.161,
			31.239,
			31.284,
			31.349,
			31.408,
			31.456,
			31.472,
			31.533,
			31.565,
			31.596,
			31.674,
			31.719,
			31.782,
			31.842,
			31.906,
			31.966,
			32.007,
			32.028,
			32.094,
			32.153,
			32.231,
			32.276,
			32.338,
			32.402,
			32.464,
			32.558,
			32.587,
			32.667,
			32.71,
			32.775,
			32.837,
			32.898,
			32.992,
			33.022,
			33.099,
			33.144,
			33.208,
			33.271,
			33.317,
			33.393,
			33.425,
			33.457,
			33.535,
			33.581,
			33.643,
			33.703,
			33.767,
			33.826,
			33.859,
			33.891,
			33.952,
			34.014,
			34.082,
			34.183,
			34.201,
			34.263,
			34.292,
			34.325,
			34.404,
			34.509,
			34.572,
			34.634,
			34.697,
			34.726,
			34.757,
			34.821,
			34.882,
			34.944,
			35.006,
			35.066,
			35.131,
			35.193,
			35.269,
			35.378,
			35.441,
			35.502,
			35.567,
			35.593,
			35.625,
			35.689,
			35.751,
			35.814,
			35.874,
			35.937,
			35.999,
			36.032,
			36.06,
			36.142,
			36.183,
			36.244,
			36.31,
			36.372,
			36.464,
			36.494,
			36.555,
			36.62,
			36.681,
			36.744,
			36.805,
			36.897,
			36.928,
			37.006,
			37.053,
			37.116,
			37.176,
			37.239,
			37.334,
			37.364,
			37.424,
			37.44,
			37.488,
			37.552,
			37.611,
			37.657,
			37.672,
			37.735,
			37.797,
			37.875,
			37.919,
			37.983,
			38.045,
			38.106,
			38.169,
			38.231,
			38.294,
			38.355,
			38.419,
			38.477,
			38.541,
			38.604,
			38.633,
			38.665,
			38.741,
			38.789,
			38.85,
			38.913,
			38.958,
			38.974,
			39.038,
			39.067,
			39.098,
			39.175,
			39.223,
			39.29,
			39.347,
			39.409,
			39.503,
			39.532,
			39.611,
			39.656,
			39.719,
			39.781,
			39.844,
			39.937,
			39.965,
			40.027,
			40.045,
			40.092,
			40.155,
			40.216,
			40.277,
			40.371,
			40.4,
			40.478,
			40.524,
			40.587,
			40.648,
			40.694,
			40.774,
			40.803,
			40.836,
			40.898,
			40.958,
			41.024,
			41.083,
			41.144,
			41.238,
			41.271,
			41.348,
			41.393,
			41.456,
			41.518,
			41.564,
			41.642,
			41.704,
			41.781,
			41.827,
			41.891,
			41.953,
			42.014,
			42.075,
			42.108,
			42.137,
			42.216,
			42.261,
			42.328,
			42.386,
			42.431,
			42.448,
			42.509,
			42.573,
			42.634,
			42.65,
			42.696,
			42.758,
			42.82,
			42.881,
			42.945,
			42.973,
			43.006,
			43.084,
			43.128,
			43.191,
			43.256,
			43.299,
			43.316,
			43.376,
			43.407,
			43.5,
			43.517,
			43.565,
			43.625,
			43.688,
			43.751,
			43.81,
			43.844,
			43.873,
			43.951,
			44,
			44.061,
			44.121,
			44.167,
			44.277,
			44.308,
			44.371,
			44.392,
			44.432,
			44.494,
			44.555,
			44.617,
			44.681,
			44.709,
			44.743,
			44.82,
			44.867,
			44.93,
			44.991,
			45.038,
			45.053,
			45.115,
			45.144,
			45.175,
			45.239,
			45.301,
			45.36,
			45.424,
			45.486,
			45.547,
			45.58,
			45.61,
			45.673,
			45.689,
			45.734,
			45.797,
			45.858,
			45.906,
			45.92,
			45.982,
			46.014,
			46.043,
			46.121,
			46.169,
			46.231,
			46.291,
			46.353,
			46.417,
			46.454,
			46.479,
			46.555,
			46.603,
			46.668,
			46.726,
			46.771,
			46.849,
			46.881,
			46.913,
			46.989,
			47.036,
			47.101,
			47.16,
			47.222,
			47.285,
			47.316,
			47.347,
			47.407,
			47.424,
			47.47,
			47.534,
			47.64,
			47.719,
			47.781,
			47.859,
			47.904,
			47.966,
			48.09,
			48.153,
			48.182,
			48.214,
			48.278,
			48.339,
			48.4,
			48.463,
			48.525,
			48.618,
			48.649,
			48.726,
			48.772,
			48.836,
			48.897,
			48.96,
			49.021,
			49.051,
			49.082,
			49.143,
			49.207,
			49.269,
			49.331,
			49.377,
			49.454,
			49.486,
			49.517,
			49.595,
			49.641,
			49.703,
			49.767,
			49.828,
			49.92,
			49.952,
			50.03,
			50.074,
			50.14,
			50.247,
			50.321,
			50.355,
			50.448,
			50.464,
			50.509,
			50.575,
			50.634,
			50.695,
			50.788,
			50.819,
			50.897,
			50.944,
			51.006,
			51.067,
			51.114,
			51.191,
			51.222,
			51.254,
			51.331,
			51.376,
			51.44,
			51.499,
			51.564,
			51.624,
			51.655,
			51.688,
			51.765,
			51.81,
			51.873,
			51.935,
			51.981,
			51.997,
			52.06,
			52.091,
			52.12,
			52.199,
			52.243,
			52.309,
			52.37,
			52.431,
			52.494,
			52.524,
			52.554,
			52.616,
			52.678,
			52.742,
			52.804,
			52.849,
			52.867,
			52.925,
			52.958,
			52.989,
			53.066,
			53.176,
			53.237,
			53.3,
			53.361,
			53.393,
			53.424,
			53.485,
			53.548,
			53.611,
			53.671,
			53.717,
			53.794,
			53.858,
			53.936,
			53.982,
			54.047,
			54.106,
			54.169,
			54.228,
			54.259,
			54.291,
			54.352,
			54.368,
			54.414,
			54.478,
			54.538,
			54.602,
			54.665,
			54.701,
			54.726,
			54.805,
			54.85,
			54.911,
			54.974,
			55.036,
			55.099,
			55.129,
			55.161,
			55.237,
			55.285,
			55.346,
			55.408,
			55.455,
			55.469,
			55.531,
			55.561,
			55.593,
			55.67,
			55.716,
			55.781,
			55.843,
			55.905,
			55.964,
			55.998,
			56.026,
			56.09,
			56.152,
			56.215,
			56.275,
			56.321,
			56.339,
			56.399,
			56.432,
			56.462,
			56.539,
			56.586,
			56.65,
			56.71,
			56.772,
			56.834,
			56.866,
			56.896,
			56.958,
			56.973,
			57.021,
			57.082,
			57.143,
			57.189,
			57.267,
			57.3,
			57.33,
			57.409,
			57.454,
			57.517,
			57.577,
			57.641,
			57.702,
			57.734,
			57.765,
			57.825,
			57.842,
			57.889,
			57.951,
			58.011,
			58.058,
			58.074,
			58.165,
			58.198,
			58.274,
			58.323,
			58.385,
			58.446,
			58.508,
			58.571,
			58.601,
			58.632,
			58.693,
			58.756,
			58.822,
			58.928,
			58.941,
			59.005,
			59.036,
			59.067,
			59.143,
			59.191,
			59.253,
			59.315,
			59.377,
			59.438,
			59.469,
			59.501,
			59.564,
			59.625,
			59.687,
			59.749,
			59.811,
			59.873,
			59.904,
			59.934,
			60.011,
			60.059,
			60.121,
			60.183,
			60.243,
			60.308,
			60.338,
			60.369,
			60.444,
			60.492,
			60.553,
			60.664,
			60.74,
			60.772,
			60.802,
			60.887,
			60.926,
			60.989,
			61.051,
			61.113,
			61.176,
			61.204,
			61.238,
			61.3,
			61.314,
			61.361,
			61.423,
			61.486,
			61.548,
			61.639,
			61.671,
			61.75,
			61.796,
			61.857,
			61.921,
			61.981,
			62.074,
			62.105,
			62.168,
			62.227,
			62.292,
			62.354,
			62.399,
			62.416,
			62.477,
			62.538,
			62.616,
			62.663,
			62.725,
			62.787,
			62.849,
			62.911,
			62.974,
			63.034,
			63.05,
			63.097,
			63.161,
			63.222,
			63.269,
			63.343,
			63.375,
			63.408,
			63.486,
			63.531,
			63.595,
			63.656,
			63.703,
			63.716,
			63.811,
			63.841,
			63.904,
			63.919,
			63.965,
			64.027,
			64.088,
			64.151,
			64.215,
			64.274,
			64.353,
			64.4,
			64.46,
			64.523,
			64.586,
			64.709,
			64.771,
			64.835,
			64.894,
			64.959,
			65.019,
			65.084,
			65.112,
			65.143,
			65.22,
			65.269,
			65.328,
			65.392,
			65.439,
			65.547,
			65.577,
			65.64,
			65.702,
			65.764,
			65.825,
			65.886,
			65.981,
			66.012,
			66.089,
			66.136,
			66.199,
			66.259,
			66.305,
			66.321,
			66.416,
			66.445,
			66.507,
			66.524,
			66.569,
			66.634,
			66.695,
			66.756,
			66.816,
			66.85,
			66.88,
			66.957,
			67.002,
			67.072,
			67.175,
			67.189,
			67.254,
			67.282,
			67.315,
			67.39,
			67.439,
			67.5,
			67.561,
			67.623,
			67.687,
			67.75,
			67.809,
			67.871,
			67.934,
			67.996,
			68.043,
			68.152,
			68.184,
			68.259,
			68.305,
			68.368,
			68.431,
			68.492,
			68.555,
			68.585,
			68.616,
			68.678,
			68.741,
			68.807,
			68.864,
			68.91,
			68.926,
			68.989,
			69.049,
			69.132,
			69.235,
			69.3,
			69.361,
			69.452,
			69.483,
			69.544,
			69.608,
			69.672,
			69.733,
			69.78,
			69.792,
			69.886,
			69.917,
			69.995,
			70.042,
			70.105,
			70.166,
			70.226,
			70.29,
			70.321,
			70.354,
			70.431,
			70.475,
			70.542,
			70.601,
			70.65,
			70.724,
			70.787,
			70.864,
			70.972,
			71.035,
			71.098,
			71.158,
			71.22,
			71.282,
			71.298,
			71.344,
			71.406,
			71.469,
			71.516,
			71.593,
			71.624,
			71.654,
			71.731,
			71.78,
			71.842,
			71.902,
			71.965,
			72.027,
			72.058,
			72.088,
			72.15,
			72.214,
			72.275,
			72.338,
			72.398,
			72.459,
			72.524,
			72.601,
			72.647,
			72.71,
			72.771,
			72.832,
			72.893,
			72.925,
			72.958,
			73.034,
			73.082,
			73.142,
			73.205,
			73.267,
			73.36,
			73.39,
			73.47,
			73.516,
			73.577,
			73.64,
			73.702,
			73.761,
			73.794,
			73.824,
			73.888,
			73.904,
			73.949,
			74.01,
			74.073,
			74.136,
			74.197,
			74.258,
			74.337,
			74.384,
			74.446,
			74.507,
			74.57,
			74.692,
			74.755,
			74.772,
			74.817,
			74.881,
			74.94,
			75.004,
			75.065,
			75.098,
			75.128,
			75.204,
			75.25,
			75.319,
			75.374,
			75.437,
			75.5,
			75.531,
			75.624,
			75.639,
			75.685,
			75.748,
			75.81,
			75.871,
			75.933,
			75.994,
			76.072,
			76.121,
			76.181,
			76.243,
			76.289,
			76.305,
			76.366,
			76.431,
			76.492,
			76.552,
			76.617,
			76.677,
			76.74,
			76.802,
			76.832,
			76.864,
			76.94,
			76.988,
			77.049,
			77.157,
			77.173,
			77.267,
			77.299,
			77.381,
			77.423,
			77.482,
			77.545,
			77.609,
			77.671,
			77.702,
			77.732,
			77.794,
			77.855,
			77.921,
			77.981,
			78.027,
			78.043,
			78.136,
			78.166,
			78.243,
			78.29,
			78.354,
			78.421,
			78.475,
			78.567,
			78.6,
			78.675,
			78.724,
			78.788,
			78.848,
			78.911,
			78.972,
			79.034,
			79.113,
			79.159,
			79.22,
			79.283,
			79.344,
			79.407,
			79.469,
			79.544,
			79.591,
			79.654,
			79.716,
			79.763,
			79.779,
			79.871,
			79.903,
			79.981,
			80.025,
			80.149,
			80.212,
			80.274,
			80.336,
			80.414,
			80.459,
			80.521,
			80.585,
			80.631,
			80.646,
			80.707,
			80.738,
			80.769,
			80.849,
			80.894,
			80.957,
			81.017,
			81.064,
			81.143,
			81.174,
			81.205,
			81.267,
			81.327,
			81.389,
			81.452,
			81.515,
			81.609,
			81.638,
			81.715,
			81.76,
			81.826,
			81.95,
			82.009,
			82.043,
			82.071,
			82.136,
			82.151,
			82.197,
			82.26,
			82.319,
			82.381,
			82.476,
			82.506,
			82.585,
			82.631,
			82.693,
			82.755,
			82.799,
			82.818,
			82.909,
			82.941,
			83.003,
			83.064,
			83.127,
			83.235,
			83.251,
			83.311,
			83.374,
			83.452,
			83.498,
			83.566,
			83.623,
			83.685,
			83.748,
			83.777,
			83.81,
			83.869,
			83.932,
			83.993,
			84.057,
			84.104,
			84.118,
			84.182,
			84.21,
			84.243,
			84.319,
			84.365,
			84.427,
			84.49,
			84.553,
			84.644,
			84.676,
			84.739,
			84.802,
			84.864,
			84.926,
			84.971,
			85.05,
			85.08,
			85.109,
			85.188,
			85.236,
			85.298,
			85.421,
			85.482,
			85.515,
			85.545,
			85.626,
			85.669,
			85.732,
			85.792,
			85.84,
			85.917,
			85.95,
			85.978,
			86.057,
			86.103,
			86.171,
			86.29,
			86.382,
			86.414,
			86.474,
			86.49,
			86.538,
			86.598,
			86.659,
			86.723,
			86.785,
			86.816,
			86.849,
			86.925,
			86.971,
			87.033,
			87.094,
			87.142,
			87.25,
			87.282,
			87.342,
			87.405,
			87.468,
			87.529,
			87.59,
			87.654,
			87.688,
			87.716,
			87.792,
			87.84,
			87.902,
			87.965,
			88.01,
			88.087,
			88.119,
			88.149,
			88.21,
			88.273,
			88.337,
			88.399,
			88.459,
			88.521,
			88.554,
			88.584,
			88.659,
			88.709,
			88.769,
			88.876,
			88.892,
			88.987,
			89.019,
			89.078,
			89.094,
			89.141,
			89.204,
			89.266,
			89.326,
			89.389,
			89.452,
			89.53,
			89.576,
			89.637,
			89.699,
			89.754,
			89.823,
			89.884,
			89.947,
			89.964,
			90.011,
			90.072,
			90.134,
			90.197,
			90.258,
			90.321,
			90.398,
			90.444,
			90.506,
			90.616,
			90.693,
			90.722,
			90.754,
			90.832,
			90.877,
			90.941,
			91.002,
			91.065,
			91.127,
			91.157,
			91.189,
			91.266,
			91.313,
			91.374,
			91.482,
			91.5,
			91.592,
			91.621,
			91.684,
			91.7,
			91.748,
			91.814,
			91.871,
			91.932,
			91.992,
			92.024,
			92.057,
			92.132,
			92.18,
			92.241,
			92.305,
			92.35,
			92.427,
			92.46,
			92.489,
			92.553,
			92.566,
			92.615,
			92.677,
			92.739,
			92.799,
			92.893,
			92.925,
			93.002,
			93.047,
			93.109,
			93.171,
			93.219,
			93.294,
			93.359,
			93.422,
			93.482,
			93.544,
			93.606,
			93.669,
			93.731,
			93.763,
			93.792,
			93.853,
			93.875,
			93.917,
			93.979,
			94.041,
			94.102,
			94.166,
			94.195,
			94.227,
			94.306,
			94.35,
			94.414,
			94.474,
			94.535,
			94.599,
			94.661,
			94.738,
			94.785,
			94.848,
			94.908,
			94.954,
			94.97,
			95.033,
			95.096,
			95.158,
			95.219,
			95.281,
			95.342,
			95.405,
			95.466,
			95.527,
			95.607,
			95.653,
			95.715,
			95.777,
			95.824,
			95.839,
			95.937,
			95.961,
			96.039,
			96.088,
			96.15,
			96.211,
			96.274,
			96.366,
			96.397,
			96.476,
			96.522,
			96.585,
			96.643,
			96.708,
			96.769,
			96.832,
			96.955,
			97.017,
			97.08,
			97.142,
			97.235,
			97.266,
			97.328,
			97.389,
			97.452,
			97.514,
			97.575,
			97.667,
			97.699,
			97.775,
			97.824,
			97.885,
			97.949,
			98.009,
			98.07,
			98.103,
			98.132,
			98.196,
			98.256,
			98.319,
			98.381,
			98.444,
			98.505,
			98.566,
			98.644,
			98.692,
			98.755,
			98.815,
			98.876,
			98.94,
			99.002,
			99.064,
			99.124,
			99.187,
			99.248,
			99.311,
			99.405,
			99.435,
			99.511,
			99.56,
			99.622,
			99.745,
			99.839,
			99.869,
			99.932,
			99.994,
			100.059,
			100.118,
			100.178,
			100.242,
			100.271,
			100.303,
			100.382,
			100.426,
			100.489,
			100.551,
			100.614,
			100.674,
			100.739,
			100.815,
			100.861,
			100.925,
			100.986,
			101.047,
			101.11,
			101.14,
			101.171,
			101.248,
			101.296,
			101.359,
			101.421,
			101.483,
			101.544,
			101.575,
			101.607,
			101.683,
			101.731,
			101.791,
			101.853,
			101.899,
			101.917,
			101.979,
			102.008,
			102.039,
			102.122,
			102.166,
			102.225,
			102.289,
			102.351,
			102.41,
			102.444,
			102.473,
			102.552,
			102.599,
			102.661,
			102.723,
			102.769,
			102.876,
			102.909,
			102.986,
			103.031,
			103.095,
			103.219,
			103.278,
			103.342,
			103.418,
			103.466,
			103.529,
			103.591,
			103.653,
			103.714,
			103.777,
			103.842,
			103.899,
			103.961,
			104.024,
			104.087,
			104.148,
			104.217,
			104.272,
			104.334,
			104.397,
			104.457,
			104.535,
			104.581,
			104.646,
			104.708,
			104.752,
			104.831,
			104.861,
			104.892,
			104.955,
			104.969,
			105.017,
			105.077,
			105.141,
			105.203,
			105.265,
			105.295,
			105.328,
			105.404,
			105.452,
			105.516,
			105.574,
			105.62,
			105.728,
			105.761,
			105.839,
			105.886,
			105.947,
			106.01,
			106.072,
			106.131,
			106.195,
			106.277,
			106.319,
			106.382,
			106.441,
			106.489,
			106.567,
			106.627,
			106.641
		]
	],
	'1771_1783_7982': [
		[
			101.479
		],
		[
			0
		],
		[
			0,
			5.402,
			8.82,
			12.242,
			17.381,
			20.812,
			25.965,
			27.684,
			29.404,
			34.569,
			38.018,
			43.198,
			46.658,
			51.86,
			57.07,
			60.548,
			65.769,
			69.253,
			72.743,
			77.988,
			79.739,
			81.49,
			86.748,
			90.256,
			93.764,
			95.519,
			99.032,
			102.548,
			107.828,
			111.352,
			116.642,
			118.406,
			120.17,
			127.236,
			129.005,
			134.321,
			141.425,
			143.204,
			146.764,
			152.111,
			157.466,
			161.039,
			164.617,
			169.989,
			173.574,
			178.958,
			180.755,
			182.552,
			187.95,
			191.552,
			196.962,
			204.189,
			205.997,
			209.607,
			213.201,
			222.072,
			227.344,
			230.85,
			236.089,
			239.557,
			244.712,
			248.116,
			249.809,
			253.172,
			256.506,
			261.445,
			264.693,
			269.497,
			271.079,
			272.653,
			280.384,
			284.919,
			287.899,
			292.299,
			295.185,
			296.614,
			299.445,
			303.624,
			304.998,
			309.066,
			311.73,
			315.66,
			318.232,
			320.768,
			322.021,
			325.724,
			328.14,
			331.694,
			334.015,
			336.303,
			339.667,
			340.77,
			341.866,
			345.099,
			347.209,
			350.326,
			352.347,
			354.334,
			355.314,
			358.2,
			360.078,
			362.83,
			364.616,
			366.373,
			368.966,
			370.657,
			373.165,
			374.822,
			377.293,
			378.938,
			380.594,
			383.079,
			384.758,
			387.38,
			389.131,
			391.91,
			393.776,
			396.648,
			398.592,
			401.419,
			403.244,
			405.966,
			407.741,
			408.626,
			410.396,
			412.136,
			414.732,
			416.449,
			418.995,
			419.843,
			420.69,
			423.219,
			424.902,
			427.42,
			429.092,
			430.766,
			433.267,
			434.933,
			438.292,
			439.138,
			441.687,
			443.406,
			446.005,
			449.508,
			450.391,
			453.061,
			454.873,
			456.696,
			459.462,
			461.309,
			464.121,
			466.008,
			468.86,
			470.774,
			473.667,
			475.616,
			478.562,
			480.544,
			481.54,
			482.539,
			486.561,
			487.573,
			490.626,
			492.672,
			494.728,
			495.761,
			497.824,
			499.897,
			500.937,
			503.032,
			504.07,
			506.152,
			508.255,
			511.431,
			513.563,
			516.78,
			518.935,
			522.182,
			524.354,
			527.621,
			531.975,
			533.064,
			535.245,
			536.336,
			538.516,
			541.803,
			543.986,
			546.199,
			549.517,
			551.753,
			555.13,
			556.27,
			557.408,
			559.693,
			560.848,
			563.154,
			565.428,
			568.798,
			570.977,
			574.198,
			575.268,
			576.328,
			579.515,
			581.644,
			584.852,
			586.991,
			590.21,
			592.354,
			593.429,
			595.585,
			597.752,
			598.839,
			601.023,
			603.253,
			606.618,
			608.883,
			612.294,
			613.432,
			614.572,
			617.99,
			620.293,
			623.767,
			628.394,
			631.82,
			632.946,
			634.064,
			637.363,
			638.443,
			639.514,
			642.668,
			644.721,
			647.727,
			649.681,
			651.598,
			652.535,
			655.293,
			657.089,
			658.854,
			661.438,
			663.123,
			666.39,
			667.183,
			669.505,
			671.006,
			673.191,
			674.603,
			676.654,
			678.636,
			679.916,
			681.768,
			682.959,
			684.116,
			685.249,
			686.345,
			686.886,
			687.954,
			689.006,
			689.527,
			691.077,
			692.104,
			693.115,
			694.607,
			695.596,
			697.072,
			697.564,
			698.058,
			699.531,
			700.506,
			701.965,
			702.944,
			703.941,
			704.437,
			705.427,
			705.922,
			706.91,
			708.429,
			709.471,
			710.509,
			712.062,
			713.131,
			714.278,
			715.42,
			715.99,
			717.129,
			717.698,
			718.883,
			720.067,
			721.836,
			723.016,
			724.771,
			725.352,
			725.933,
			727.677,
			728.867,
			730.676,
			731.881,
			733.713,
			734.976,
			735.607,
			736.869,
			738.138,
			738.753,
			739.957,
			741.162,
			742.98,
			744.16,
			745.885,
			747.042,
			748.794,
			749.95,
			751.11,
			754.044,
			755.828,
			757.024,
			758.836,
			759.458,
			760.087,
			761.984,
			763.258,
			765.192,
			766.495,
			767.807,
			768.466,
			770.455,
			771.794,
			773.145,
			775.196,
			776.568,
			778.67,
			779.378,
			780.089,
			782.243,
			783.697,
			785.908,
			787.401,
			789.668,
			791.199,
			791.971,
			793.523,
			795.089,
			795.88,
			797.47,
			799.073,
			800.69,
			803.158,
			804.808,
			806.475,
			807.313,
			809.84,
			811.529,
			813.217,
			815.766,
			817.483,
			820.073,
			820.941,
			821.812,
			824.432,
			826.183,
			828.808,
			830.552,
			832.3,
			833.173,
			834.91,
			835.774,
			837.51,
			840.092,
			841.794,
			843.488,
			846.004,
			847.672,
			850.153,
			850.974,
			851.795,
			853.438,
			854.26,
			855.905,
			857.553,
			860.03,
			861.687,
			864.99,
			865.82,
			868.311,
			869.977,
			872.503,
			874.224,
			876.83,
			878.611,
			879.509,
			881.315,
			883.127,
			885.861,
			887.687,
			890.381,
			892.192,
			894.904,
			895.813,
			896.724,
			899.462,
			901.299,
			903.146,
			905.929,
			907.8,
			910.615,
			911.558,
			912.505,
			915.354,
			916.308,
			917.265,
			920.149,
			922.082,
			925.006,
			926.974,
			928.953,
			929.956,
			933.003,
			935.052,
			937.136,
			940.281,
			942.371,
			945.538,
			947.64,
			950.805,
			952.919,
			956.107,
			958.244,
			961.469,
			963.636,
			966.907,
			970.202,
			972.412,
			974.632,
			976.862,
			979.102,
			980.225,
			982.48,
			985.88,
			988.158,
			989.301,
			991.595,
			993.897,
			997.368,
			999.694,
			1003.2,
			1004.374,
			1005.549,
			1009.09,
			1011.462,
			1015.036,
			1017.43,
			1021.036,
			1023.451,
			1027.089,
			1030.747,
			1033.196,
			1035.655,
			1038.122,
			1041.838,
			1044.326,
			1046.823,
			1048.076,
			1050.587,
			1051.847,
			1054.373,
			1056.907,
			1060.723,
			1063.275,
			1067.119,
			1068.402,
			1069.688,
			1073.554,
			1076.141,
			1080.035,
			1082.64,
			1086.55,
			1089.16,
			1090.468,
			1093.087,
			1097.029,
			1099.666,
			1102.307,
			1106.283,
			1107.612,
			1108.943,
			1112.945,
			1114.282,
			1115.621,
			1119.648,
			1122.342,
			1125.044,
			1129.111,
			1131.831,
			1137.284,
			1138.65,
			1142.756,
			1145.499,
			1149.623,
			1152.38,
			1155.144,
			1156.529,
			1159.304,
			1162.087,
			1163.481,
			1167.674,
			1170.479,
			1173.29,
			1177.52,
			1180.349,
			1184.605,
			1186.028,
			1187.453,
			1191.736,
			1194.599,
			1198.904,
			1204.665,
			1206.109,
			1209.002,
			1210.45,
			1211.9,
			1217.715,
			1219.173,
			1223.554,
			1226.481,
			1230.884,
			1233.827,
			1236.777,
			1238.255,
			1241.214,
			1242.697,
			1245.666,
			1248.642,
			1253.118,
			1256.111,
			1260.612,
			1262.115,
			1263.621,
			1268.147,
			1271.173,
			1275.724,
			1278.766,
			1281.815,
			1283.342,
			1287.932,
			1290.999,
			1294.061,
			1295.587,
			1298.622,
			1301.626,
			1304.593,
			1308.972,
			1311.844,
			1314.677,
			1316.08,
			1320.231,
			1322.951,
			1326.962,
			1329.589,
			1332.178,
			1337.246,
			1338.491,
			1342.17,
			1344.579,
			1348.131,
			1350.456,
			1353.876,
			1356.111,
			1357.214,
			1359.392,
			1362.591,
			1364.678,
			1366.729,
			1369.734,
			1370.718,
			1371.693,
			1374.563,
			1375.501,
			1376.431,
			1379.167,
			1380.949,
			1383.559,
			1385.254,
			1386.913,
			1389.332,
			1390.12,
			1390.9,
			1393.927,
			1394.662,
			1396.812,
			1398.201,
			1399.558,
			1400.224,
			1401.531,
			1402.807,
			1403.434,
			1406.431,
			1407.568,
			1409.217,
			1410.264,
			1412.269,
			1412.757,
			1414.19,
			1415.129,
			1416.524,
			1417.452,
			1418.374,
			1418.832,
			1419.747,
			1420.205,
			1421.124,
			1422.51,
			1424.427,
			1425.395,
			1426.845,
			1427.814,
			1428.829,
			1429.349,
			1430.382,
			1431.922,
			1432.947,
			1434.595,
			1435.702,
			1437.343,
			1437.888,
			1438.434,
			1440.241,
			1441.424,
			1443.184,
			1444.414,
			1446.266,
			1448.094,
			1449.321,
			1450.539,
			1451.143,
			1452.34,
			1453.531,
			1455.277,
			1456.42,
			1458.124,
			1458.69,
			1459.236,
			1460.858,
			1461.927,
			1462.986,
			1464.508,
			1465.482,
			1466.928,
			1467.886,
			1469.282,
			1470.167,
			1471.493,
			1472.378,
			1473.714,
			1475.478,
			1475.922,
			1477.26,
			1478.16,
			1479.068,
			1480.453,
			1480.923,
			1482.857,
			1483.351,
			1483.85,
			1485.392,
			1486.446,
			1488.064,
			1489.167,
			1490.296,
			1492.026,
			1492.612,
			1493.203,
			1495.003,
			1496.227,
			1498.093,
			1499.343,
			1500.592,
			1501.211,
			1502.48,
			1503.761,
			1504.408,
			1505.716,
			1506.375,
			1507.705,
			1509.05,
			1511.103,
			1512.493,
			1514.607,
			1515.319,
			1516.034,
			1518.205,
			1519.671,
			1521.875,
			1523.357,
			1524.854,
			1525.608,
			1527.122,
			1527.885,
			1529.42,
			1531.758,
			1532.549,
			1534.94,
			1536.558,
			1539.023,
			1540.682,
			1542.359,
			1543.202,
			1544.893,
			1545.737,
			1547.424,
			1549.123,
			1551.693,
			1553.419,
			1556.032,
			1557.789,
			1560.446,
			1562.232,
			1564.934,
			1566.749,
			1569.494,
			1571.339,
			1572.266,
			1574.128,
			1576.941,
			1578.832,
			1580.733,
			1583.607,
			1585.537,
			1587.477,
			1589.429,
			1590.408,
			1593.363,
			1595.346,
			1598.341,
			1600.35,
			1602.369,
			1605.418,
			1607.462,
			1610.548,
			1611.581,
			1612.617,
			1615.74,
			1617.829,
			1620.959,
			1623.053,
			1626.212,
			1629.392,
			1631.529,
			1634.752,
			1636.917,
			1639.093,
			1642.373,
			1643.47,
			1644.57,
			1647.89,
			1649.002,
			1650.116,
			1652.355,
			1653.479,
			1655.733,
			1658.004,
			1661.43,
			1663.733,
			1667.226,
			1668.373,
			1669.541,
			1673.066,
			1675.431,
			1678.999,
			1683.774,
			1684.966,
			1687.336,
			1689.695,
			1690.863,
			1693.177,
			1694.328,
			1696.6,
			1698.834,
			1702.113,
			1704.217,
			1707.276,
			1708.267,
			1709.225,
			1712.047,
			1713.871,
			1715.649,
			1719.952,
			1722.434,
			1723.244,
			1724.049,
			1727.183,
			1727.943,
			1730.191,
			1731.65,
			1733.772,
			1735.166,
			1736.526,
			1737.193,
			1738.512,
			1739.158,
			1740.426,
			1741.66,
			1743.469,
			1744.671,
			1746.465,
			1747.06,
			1747.654,
			1750.666,
			1752.505,
			1753.742,
			1755.629,
			1756.901,
			1758.829,
			1760.125,
			1760.775,
			1762.077,
			1763.382,
			1764.683,
			1765.949,
			1766.579,
			1767.835,
			1769.083,
			1769.702,
			1771.529,
			1772.725,
			1775.653,
			1776.801,
			1778.495,
			1779.053,
			1779.61,
			1781.266,
			1782.359,
			1783.99,
			1785.078,
			1786.711,
			1787.796,
			1788.338,
			1789.422,
			1791.06,
			1792.15,
			1793.238,
			1794.869,
			1795.417,
			1795.966,
			1798.153,
			1798.697,
			1799.785,
			1800.331,
			1801.437,
			1803.084,
			1804.178,
			1805.275,
			1806.957,
			1807.514,
			1808.071,
			1809.742,
			1810.877,
			1812.596,
			1813.743,
			1814.893,
			1815.471,
			1817.261,
			1818.457,
			1819.656,
			1820.258,
			1821.484,
			1822.714,
			1824.563,
			1825.806,
			1827.695,
			1828.326,
			1828.958,
			1830.866,
			1832.149,
			1833.437,
			1836.684,
			1839.277,
			1839.93,
			1842.553,
			1843.211,
			1845.196,
			1846.533,
			1848.571,
			1849.943,
			1851.328,
			1852.028,
			1854.148,
			1855.578,
			1857.024,
			1860.693,
			1862.938,
			1863.695,
			1864.455,
			1868.311,
			1870.67,
			1872.264,
			1874.679,
			1876.311,
			1877.133,
			1878.788,
			1880.458,
			1882.993,
			1884.694,
			1886.395,
			1888.106,
			1888.951,
			1890.682,
			1892.428,
			1893.306,
			1895.957,
			1897.743,
			1899.538,
			1902.257,
			1904.085,
			1906.851,
			1907.781,
			1908.714,
			1911.534,
			1913.433,
			1916.31,
			1920.213,
			1921.193,
			1923.163,
			1925.147,
			1926.143,
			1929.15,
			1931.172,
			1933.205,
			1936.284,
			1938.356,
			1940.441,
			1942.542,
			1943.596,
			1946.765,
			1948.881,
			1951.006,
			1954.216,
			1956.369,
			1960.707,
			1961.802,
			1965.103,
			1967.325,
			1970.683,
			1972.965,
			1975.261,
			1976.416,
			1978.765,
			1982.3,
			1984.667,
			1985.852,
			1988.213,
			1990.571,
			1994.122,
			1996.498,
			2000.066,
			2001.258,
			2002.452,
			2006.016,
			2008.393,
			2011.943,
			2014.306,
			2016.671,
			2017.857,
			2021.425,
			2022.618,
			2027.401,
			2028.601,
			2032.203,
			2034.611,
			2038.228,
			2040.642,
			2043.058,
			2044.268,
			2046.686,
			2047.896,
			2050.316,
			2052.737,
			2056.365,
			2058.782,
			2062.407,
			2063.616,
			2064.825,
			2068.459,
			2070.888,
			2074.525,
			2076.974,
			2080.662,
			2083.152,
			2084.389,
			2086.849,
			2089.336,
			2090.583,
			2093.078,
			2095.58,
			2098.086,
			2100.6,
			2101.86,
			2104.384,
			2106.916,
			2108.186,
			2112.006,
			2114.564,
			2118.416,
			2120.994,
			2123.58,
			2127.474,
			2128.775,
			2130.076,
			2133.982,
			2136.596,
			2140.53,
			2143.16,
			2145.798,
			2147.118,
			2149.756,
			2151.071,
			2153.691,
			2157.579,
			2160.132,
			2162.646,
			2166.35,
			2168.773,
			2172.356,
			2173.536,
			2174.726,
			2177.052,
			2178.201,
			2180.473,
			2182.714,
			2186.011,
			2188.169,
			2191.359,
			2192.418,
			2193.471,
			2196.62,
			2198.723,
			2201.857,
			2203.931,
			2207.007,
			2209.031,
			2210.035,
			2212.003,
			2213.956,
			2214.919,
			2216.828,
			2218.675,
			2221.38,
			2223.115,
			2225.651,
			2226.481,
			2227.301,
			2229.722,
			2231.314,
			2233.682,
			2235.25,
			2236.812,
			2239.141,
			2239.913,
			2240.684,
			2242.988,
			2243.755,
			2244.519,
			2246.802,
			2248.322,
			2250.587,
			2252.088,
			2253.582,
			2254.324,
			2256.542,
			2258.014,
			2259.479,
			2261.663,
			2263.119,
			2266.025,
			2266.75,
			2268.938,
			2270.402,
			2272.594,
			2274.084,
			2276.328,
			2277.825,
			2278.585,
			2280.118,
			2281.652,
			2282.42,
			2283.96,
			2285.502,
			2288.6,
			2289.385,
			2290.957,
			2292.534,
			2293.326,
			2295.801,
			2297.458,
			2299.142,
			2301.773,
			2303.529,
			2306.193,
			2307.08,
			2307.968,
			2310.613,
			2312.363,
			2314.937,
			2316.581,
			2318.221,
			2319.04,
			2320.65,
			2321.448,
			2323.038,
			2325.403,
			2326.955,
			2328.501,
			2330.042,
			2332.336,
			2333.859,
			2335.383,
			2336.15,
			2337.679,
			2338.442,
			2339.966,
			2341.496,
			2343.774,
			2345.3,
			2347.592,
			2348.357,
			2349.136,
			2351.452,
			2352.998,
			2355.36,
			2356.957,
			2359.381,
			2361.016,
			2361.84,
			2363.5,
			2365.174,
			2366.018,
			2367.717,
			2369.431,
			2372.031,
			2373.785,
			2376.444,
			2378.238,
			2380.958,
			2382.791,
			2384.64,
			2387.441,
			2389.328,
			2393.148,
			2394.112,
			2397.027,
			2398.006,
			2398.988,
			2401.957,
			2403.952,
			2406.972,
			2409.002,
			2411.046,
			2412.074,
			2415.176,
			2417.262,
			2420.409,
			2422.51,
			2424.62,
			2427.805,
			2428.874,
			2429.945,
			2433.197,
			2435.37,
			2438.654,
			2440.859,
			2444.189,
			2446.418,
			2447.537,
			2449.784,
			2452.032,
			2453.161,
			2454.292,
			2457.695,
			2459.976,
			2462.275,
			2463.428,
			2465.743,
			2468.085,
			2469.259,
			2472.807,
			2475.188,
			2477.578,
			2481.175,
			2483.583,
			2487.211,
			2488.427,
			2489.645,
			2493.322,
			2495.788,
			2499.528,
			2502.028,
			2504.527,
			2505.776,
			2508.28,
			2509.533,
			2512.035,
			2515.819,
			2518.323,
			2520.811,
			2524.495,
			2526.927,
			2529.342,
			2531.747,
			2532.95,
			2535.363,
			2536.566,
			2538.984,
			2541.411,
			2545.047,
			2547.446,
			2551.035,
			2552.246,
			2553.434,
			2556.932,
			2559.248,
			2562.688,
			2564.971,
			2567.249,
			2568.39,
			2570.654,
			2571.798,
			2574.093,
			2576.411,
			2579.873,
			2582.186,
			2585.671,
			2588.002,
			2591.52,
			2592.698,
			2593.859,
			2597.439,
			2599.826,
			2603.429,
			2605.843,
			2608.247,
			2611.896,
			2613.116,
			2614.338,
			2618.012,
			2620.466,
			2624.135,
			2626.561,
			2630.15,
			2632.496,
			2633.654,
			2635.94,
			2639.291,
			2641.476,
			2643.624,
			2646.775,
			2648.833,
			2651.847,
			2652.834,
			2653.811,
			2656.685,
			2658.553,
			2661.286,
			2663.063,
			2665.662,
			2667.348,
			2668.177,
			2669.806,
			2671.398,
			2672.952,
			2675.214,
			2676.675,
			2678.099,
			2678.799,
			2680.17,
			2681.519,
			2682.177,
			2684.118,
			2685.384,
			2686.629,
			2688.473,
			2689.697,
			2691.521,
			2692.128,
			2692.734,
			2694.574,
			2695.813,
			2697.67,
			2698.917,
			2700.196,
			2700.836,
			2702.117,
			2704.052,
			2706.016,
			2707.323,
			2708.632,
			2711.945,
			2713.268,
			2715.315,
			2716.699,
			2717.39,
			2718.772,
			2720.219,
			2722.41,
			2723.867,
			2726.048,
			2726.77,
			2727.491,
			2729.656,
			2731.091,
			2733.232,
			2734.664,
			2736.109,
			2738.265,
			2740.459,
			2741.957,
			2744.195,
			2745.726,
			2748.039,
			2749.582,
			2751.875,
			2752.638,
			2753.403,
			2755.677,
			2757.167,
			2758.661,
			2762.232,
			2764.335,
			2765.72,
			2767.758,
			2769.119,
			2771.123,
			2772.433,
			2774.403,
			2775.719,
			2777.031,
			2777.689,
			2779.009,
			2779.672,
			2781.017,
			2782.382,
			2785.84,
			2787.97,
			2788.685,
			2789.404,
			2791.582,
			2793.053,
			2795.287,
			2796.793,
			2799.081,
			2800.626,
			2801.403,
			2802.181,
			2805.334,
			2806.132,
			2808.532,
			2811.801,
			2812.629,
			2815.973,
			2816.813,
			2819.334,
			2821.027,
			2822.735,
			2825.32,
			2827.06,
			2829.693,
			2830.577,
			2831.465,
			2834.145,
			2835.948,
			2838.676,
			2840.511,
			2842.359,
			2843.287,
			2845.154,
			2846.092,
			2847.978,
			2850.832,
			2852.75,
			2854.685,
			2857.61,
			2859.577,
			2861.553,
			2863.533,
			2864.524,
			2866.5,
			2869.433,
			2871.365,
			2874.217,
			2876.08,
			2878.808,
			2879.7,
			2880.586,
			2883.196,
			2884.923,
			2887.464,
			2890.815,
			2891.647,
			2894.132,
			2895.768,
			2898.207,
			2899.826,
			2901.437,
			2903.843,
			2905.449,
			2908.674,
			2909.479,
			2911.91,
			2913.531,
			2915.149,
			2917.608,
			2919.247,
			2921.73,
			2923.423,
			2927.693,
			2930.294,
			2932.031,
			2934.637,
			2936.375,
			2939.011,
			2940.767,
			2941.646,
			2943.445,
			2945.247,
			2947.961,
			2949.781,
			2953.419,
			2954.33,
			2957.056,
			2958.868,
			2961.574,
			2963.372,
			2966.041,
			2967.819,
			2968.695,
			2969.565,
			2972.175,
			2973.046,
			2973.901,
			2976.456,
			2978.162,
			2980.706,
			2984.104,
			2984.957,
			2987.513,
			2989.223,
			2990.939,
			2993.515,
			2995.239,
			2997.828,
			2999.561,
			3002.167,
			3003.913,
			3006.546,
			3008.308,
			3010.974,
			3012.761,
			3015.469,
			3018.211,
			3020.057,
			3021.922,
			3023.797,
			3025.684,
			3026.631,
			3030.449,
			3031.408,
			3033.334,
			3036.25,
			3038.213,
			3041.189,
			3043.216,
			3046.282,
			3047.317,
			3048.355,
			3051.486,
			3053.585,
			3056.752,
			3058.868,
			3060.992,
			3062.06,
			3065.284,
			3069.641,
			3070.734,
			3072.94,
			3075.171,
			3078.52,
			3080.752,
			3082.974,
			3085.179,
			3086.285,
			3089.591,
			3091.799,
			3095.116,
			3099.572,
			3102.943,
			3104.071,
			3108.61,
			3109.75,
			3110.894,
			3114.338,
			3116.626,
			3120.106,
			3123.607,
			3125.952,
			3129.488,
			3131.858,
			3134.24,
			3140.234,
			3143.86,
			3145.074,
			3146.292,
			3149.958,
			3152.417,
			3156.122,
			3158.606,
			3161.099,
			3166.113,
			3167.372,
			3171.165,
			3173.706,
			3177.536,
			3182.677,
			3183.967,
			3186.553,
			3189.143,
			3190.438,
			3194.308,
			3196.865,
			3199.396,
			3203.125,
			3205.566,
			3209.177,
			3210.355,
			3211.524,
			3214.978,
			3217.238,
			3220.561,
			3222.732,
			3224.865,
			3225.918,
			3227.997,
			3231.065,
			3234.104,
			3236.13,
			3238.17,
			3241.252,
			3243.318,
			3245.389,
			3247.455,
			3248.513,
			3250.622,
			3253.753,
			3255.816,
			3258.863,
			3260.87,
			3263.869,
			3265.888,
			3268.908,
			3270.926,
			3273.938,
			3275.93,
			3278.912,
			3280.907,
			3281.903,
			3283.87,
			3285.804,
			3288.713,
			3290.64,
			3293.521,
			3295.436,
			3298.318,
			3299.283,
			3300.25,
			3303.165,
			3305.123,
			3307.098,
			3310.08,
			3312.087,
			3316.118,
			3317.138,
			3320.212,
			3322.281,
			3325.404,
			3327.499,
			3330.664,
			3332.789,
			3334.926,
			3335.998,
			3339.226,
			3341.392,
			3343.565,
			3346.848,
			3349.052,
			3353.499,
			3354.62,
			3358.002,
			3360.271,
			3363.696,
			3365.993,
			3369.459,
			3371.784,
			3372.95,
			3374.118,
			3378.817,
			3379.997,
			3383.555,
			3388.334,
			3389.537,
			3394.366,
			3395.578,
			3399.202,
			3401.595,
			3403.969,
			3407.475,
			3409.769,
			3413.137,
			3414.241,
			3415.336,
			3418.569,
			3420.679,
			3423.775,
			3425.812,
			3428.782,
			3431.697,
			3433.617,
			3436.478,
			3438.378,
			3440.277,
			3442.174,
			3445.013,
			3448.787,
			3449.728,
			3451.613,
			3452.558,
			3454.449,
			3456.338,
			3459.214,
			3461.131,
			3464.026,
			3465.956,
			3469.877,
			3470.862,
			3473.857,
			3475.869,
			3478.89,
			3480.904,
			3481.911,
			3483.916,
			3485.916,
			3486.917,
			3488.912,
			3490.901,
			3493.897,
			3495.917,
			3499.989,
			3501.016,
			3504.112,
			3506.202,
			3508.301,
			3511.442,
			3513.542,
			3516.675,
			3518.765,
			3521.884,
			3523.968,
			3527.107,
			3529.21,
			3532.374,
			3536.62,
			3537.687,
			3540.902,
			3543.055,
			3545.217,
			3550.657,
			3553.943,
			3556.145,
			3559.446,
			3561.671,
			3565.025,
			3567.274,
			3570.661,
			3574.071,
			3575.213,
			3579.807,
			3580.963,
			3584.444,
			3589.115,
			3590.289,
			3595.007,
			3596.193,
			3602.152,
			3604.549,
			3608.164,
			3610.586,
			3615.459,
			3616.683,
			3620.372,
			3622.842,
			3626.567,
			3629.062,
			3632.821,
			3635.337,
			3636.598,
			3639.127,
			3645.483,
			3648.039,
			3651.883,
			3654.456,
			3657.034,
			3660.916,
			3664.815,
			3667.419,
			3670.021,
			3673.938,
			3676.556,
			3680.498,
			3681.815,
			3683.135,
			3687.103,
			3689.757,
			3693.749,
			3696.417,
			3700.43,
			3703.113,
			3704.456,
			3707.147,
			3709.844,
			3711.194,
			3713.899,
			3716.61,
			3720.688,
			3723.413,
			3727.511,
			3728.881,
			3730.251,
			3734.372,
			3737.125,
			3739.885,
			3746.806,
			3750.97,
			3753.753,
			3757.936,
			3760.734,
			3764.944,
			3767.76,
			3771.997,
			3774.83,
			3777.668,
			3779.089,
			3783.363,
			3786.224,
			3789.092,
			3796.299,
			3802.087,
			3803.537,
			3807.892,
			3810.801,
			3815.175,
			3818.097,
			3821.026,
			3825.43,
			3826.901,
			3828.374,
			3834.278,
			3835.758,
			3840.207,
			3843.181,
			3847.651,
			3850.639,
			3853.633,
			3855.132,
			3859.637,
			3862.649,
			3865.667,
			3870.206,
			3873.239,
			3877.799,
			3879.322,
			3880.846,
			3886.956,
			3888.486,
			3893.087,
			3899.245,
			3900.789,
			3903.884,
			3906.987,
			3913.205,
			3916.321,
			3919.44,
			3922.562,
			3927.252,
			3930.385,
			3935.094,
			3938.239,
			3942.966,
			3946.124,
			3954.038,
			3958.802,
			3960.392,
			3961.983,
			3966.763,
			3969.951,
			3974.741,
			3977.939,
			3982.746,
			3985.957,
			3990.779,
			3994,
			3995.612,
			3998.838,
			4002.07,
			4006.925,
			4008.546,
			4010.168,
			4015.04,
			4016.666,
			4018.292,
			4023.18,
			4026.444,
			4031.347,
			4034.62,
			4037.898,
			4044.468,
			4046.113,
			4051.052,
			4054.351,
			4059.308,
			4062.617,
			4067.591,
			4070.912,
			4072.573,
			4075.9,
			4080.897,
			4084.233,
			4087.573,
			4092.589,
			4094.263,
			4100.969,
			4102.649,
			4104.329,
			4109.377,
			4112.748,
			4117.811,
			4121.19,
			4124.574,
			4129.656,
			4133.049,
			4139.849,
			4141.551,
			4146.663,
			4148.393
		],
		[
			0,
			0.085,
			0.131,
			0.193,
			0.255,
			0.315,
			0.376,
			0.411,
			0.441,
			0.503,
			0.564,
			0.627,
			0.689,
			0.75,
			0.844,
			0.873,
			0.951,
			0.999,
			1.06,
			1.122,
			1.17,
			1.184,
			1.247,
			1.307,
			1.371,
			1.386,
			1.434,
			1.494,
			1.558,
			1.619,
			1.681,
			1.71,
			1.744,
			1.821,
			1.866,
			1.93,
			2.038,
			2.053,
			2.115,
			2.175,
			2.255,
			2.302,
			2.361,
			2.424,
			2.485,
			2.549,
			2.585,
			2.61,
			2.673,
			2.734,
			2.799,
			2.906,
			2.922,
			2.982,
			3.043,
			3.169,
			3.232,
			3.294,
			3.355,
			3.418,
			3.477,
			3.541,
			3.557,
			3.605,
			3.667,
			3.727,
			3.774,
			3.851,
			3.882,
			3.913,
			4.038,
			4.099,
			4.16,
			4.223,
			4.285,
			4.316,
			4.347,
			4.425,
			4.471,
			4.532,
			4.593,
			4.646,
			4.719,
			4.75,
			4.782,
			4.861,
			4.905,
			4.971,
			5.03,
			5.092,
			5.152,
			5.184,
			5.216,
			5.294,
			5.34,
			5.403,
			5.464,
			5.51,
			5.526,
			5.618,
			5.649,
			5.726,
			5.776,
			5.836,
			5.898,
			5.96,
			6.022,
			6.083,
			6.16,
			6.21,
			6.271,
			6.333,
			6.393,
			6.457,
			6.518,
			6.596,
			6.641,
			6.711,
			6.766,
			6.827,
			6.89,
			6.951,
			7.014,
			7.027,
			7.076,
			7.138,
			7.201,
			7.263,
			7.324,
			7.354,
			7.385,
			7.465,
			7.51,
			7.573,
			7.633,
			7.681,
			7.757,
			7.82,
			7.898,
			7.944,
			8.006,
			8.067,
			8.13,
			8.224,
			8.252,
			8.332,
			8.377,
			8.44,
			8.502,
			8.565,
			8.627,
			8.689,
			8.77,
			8.812,
			8.876,
			8.935,
			8.998,
			9.061,
			9.091,
			9.121,
			9.2,
			9.245,
			9.308,
			9.371,
			9.415,
			9.432,
			9.493,
			9.526,
			9.556,
			9.618,
			9.634,
			9.681,
			9.744,
			9.805,
			9.866,
			9.927,
			9.99,
			10.068,
			10.115,
			10.177,
			10.283,
			10.3,
			10.363,
			10.392,
			10.426,
			10.502,
			10.548,
			10.612,
			10.672,
			10.735,
			10.797,
			10.832,
			10.858,
			10.919,
			10.934,
			10.982,
			11.043,
			11.107,
			11.152,
			11.232,
			11.262,
			11.293,
			11.369,
			11.418,
			11.478,
			11.539,
			11.602,
			11.665,
			11.694,
			11.726,
			11.788,
			11.805,
			11.852,
			11.913,
			11.975,
			12.036,
			12.099,
			12.131,
			12.161,
			12.238,
			12.286,
			12.347,
			12.471,
			12.532,
			12.565,
			12.595,
			12.655,
			12.672,
			12.719,
			12.782,
			12.844,
			12.905,
			12.965,
			12.998,
			13.027,
			13.107,
			13.152,
			13.22,
			13.275,
			13.323,
			13.432,
			13.461,
			13.523,
			13.588,
			13.649,
			13.71,
			13.772,
			13.867,
			13.897,
			13.975,
			14.021,
			14.084,
			14.143,
			14.19,
			14.207,
			14.268,
			14.299,
			14.332,
			14.408,
			14.455,
			14.518,
			14.58,
			14.64,
			14.703,
			14.735,
			14.765,
			14.827,
			14.89,
			14.957,
			15.013,
			15.06,
			15.075,
			15.138,
			15.168,
			15.198,
			15.277,
			15.322,
			15.386,
			15.447,
			15.508,
			15.571,
			15.601,
			15.632,
			15.695,
			15.709,
			15.758,
			15.819,
			15.882,
			15.926,
			16.005,
			16.037,
			16.068,
			16.143,
			16.19,
			16.252,
			16.315,
			16.378,
			16.44,
			16.471,
			16.5,
			16.565,
			16.577,
			16.626,
			16.688,
			16.749,
			16.81,
			16.874,
			16.934,
			16.999,
			17.058,
			17.121,
			17.229,
			17.309,
			17.369,
			17.431,
			17.447,
			17.493,
			17.555,
			17.616,
			17.679,
			17.742,
			17.773,
			17.803,
			17.882,
			17.928,
			17.99,
			18.051,
			18.099,
			18.177,
			18.208,
			18.238,
			18.299,
			18.361,
			18.425,
			18.486,
			18.547,
			18.61,
			18.641,
			18.672,
			18.732,
			18.749,
			18.796,
			18.859,
			18.92,
			18.982,
			19.043,
			19.078,
			19.106,
			19.182,
			19.231,
			19.292,
			19.353,
			19.416,
			19.477,
			19.507,
			19.54,
			19.602,
			19.664,
			19.728,
			19.788,
			19.835,
			19.849,
			19.91,
			19.943,
			19.974,
			20.052,
			20.099,
			20.162,
			20.222,
			20.283,
			20.347,
			20.378,
			20.408,
			20.469,
			20.485,
			20.533,
			20.593,
			20.655,
			20.702,
			20.812,
			20.843,
			20.918,
			20.965,
			21.027,
			21.092,
			21.153,
			21.214,
			21.244,
			21.276,
			21.338,
			21.4,
			21.463,
			21.524,
			21.572,
			21.649,
			21.68,
			21.71,
			21.788,
			21.834,
			21.895,
			21.959,
			22.02,
			22.082,
			22.114,
			22.144,
			22.207,
			22.221,
			22.269,
			22.328,
			22.392,
			22.455,
			22.517,
			22.548,
			22.576,
			22.654,
			22.702,
			22.766,
			22.827,
			22.874,
			22.951,
			23.014,
			23.074,
			23.135,
			23.202,
			23.261,
			23.323,
			23.385,
			23.447,
			23.526,
			23.572,
			23.634,
			23.694,
			23.742,
			23.756,
			23.819,
			23.879,
			23.942,
			23.958,
			24.006,
			24.068,
			24.127,
			24.191,
			24.252,
			24.283,
			24.315,
			24.391,
			24.439,
			24.501,
			24.56,
			24.623,
			24.686,
			24.749,
			24.826,
			24.874,
			24.935,
			24.994,
			25.06,
			25.121,
			25.15,
			25.183,
			25.245,
			25.267,
			25.309,
			25.369,
			25.432,
			25.477,
			25.556,
			25.593,
			25.619,
			25.694,
			25.741,
			25.803,
			25.866,
			25.928,
			25.988,
			26.021,
			26.052,
			26.128,
			26.174,
			26.238,
			26.299,
			26.344,
			26.362,
			26.424,
			26.455,
			26.486,
			26.564,
			26.61,
			26.671,
			26.734,
			26.795,
			26.888,
			26.92,
			26.995,
			27.042,
			27.105,
			27.166,
			27.215,
			27.227,
			27.291,
			27.327,
			27.352,
			27.431,
			27.477,
			27.54,
			27.601,
			27.665,
			27.725,
			27.757,
			27.788,
			27.848,
			27.911,
			27.974,
			28.083,
			28.097,
			28.158,
			28.19,
			28.221,
			28.299,
			28.344,
			28.408,
			28.469,
			28.531,
			28.592,
			28.624,
			28.656,
			28.716,
			28.734,
			28.778,
			28.84,
			28.904,
			28.967,
			29.027,
			29.059,
			29.089,
			29.168,
			29.215,
			29.277,
			29.338,
			29.389,
			29.399,
			29.491,
			29.523,
			29.586,
			29.6,
			29.647,
			29.71,
			29.771,
			29.833,
			29.895,
			29.927,
			29.957,
			30.034,
			30.083,
			30.148,
			30.207,
			30.253,
			30.359,
			30.392,
			30.455,
			30.515,
			30.578,
			30.64,
			30.701,
			30.765,
			30.793,
			30.826,
			30.904,
			30.948,
			31.012,
			31.074,
			31.119,
			31.136,
			31.199,
			31.23,
			31.26,
			31.323,
			31.385,
			31.45,
			31.507,
			31.571,
			31.633,
			31.666,
			31.694,
			31.772,
			31.818,
			31.881,
			31.942,
			31.988,
			32.005,
			32.066,
			32.097,
			32.127,
			32.251,
			32.316,
			32.377,
			32.439,
			32.531,
			32.56,
			32.623,
			32.687,
			32.749,
			32.809,
			32.857,
			32.872,
			32.934,
			32.966,
			32.997,
			33.073,
			33.182,
			33.243,
			33.305,
			33.367,
			33.4,
			33.431,
			33.491,
			33.553,
			33.617,
			33.678,
			33.725,
			33.801,
			33.833,
			33.865,
			33.942,
			33.988,
			34.05,
			34.113,
			34.174,
			34.266,
			34.299,
			34.36,
			34.377,
			34.423,
			34.486,
			34.547,
			34.607,
			34.671,
			34.702,
			34.732,
			34.81,
			34.858,
			34.919,
			34.981,
			35.041,
			35.106,
			35.166,
			35.227,
			35.289,
			35.354,
			35.414,
			35.477,
			35.573,
			35.601,
			35.677,
			35.724,
			35.786,
			35.848,
			35.895,
			35.973,
			36.004,
			36.035,
			36.098,
			36.158,
			36.222,
			36.282,
			36.344,
			36.406,
			36.439,
			36.468,
			36.544,
			36.593,
			36.655,
			36.716,
			36.765,
			36.779,
			36.84,
			36.872,
			36.902,
			36.966,
			36.981,
			37.026,
			37.09,
			37.152,
			37.211,
			37.274,
			37.305,
			37.338,
			37.4,
			37.462,
			37.523,
			37.586,
			37.634,
			37.648,
			37.71,
			37.741,
			37.772,
			37.848,
			37.894,
			37.959,
			38.019,
			38.082,
			38.144,
			38.174,
			38.205,
			38.267,
			38.283,
			38.33,
			38.393,
			38.453,
			38.499,
			38.577,
			38.64,
			38.717,
			38.761,
			38.824,
			38.887,
			38.949,
			39.012,
			39.041,
			39.074,
			39.151,
			39.197,
			39.26,
			39.322,
			39.382,
			39.445,
			39.476,
			39.506,
			39.585,
			39.631,
			39.702,
			39.756,
			39.819,
			39.88,
			39.941,
			40.002,
			40.035,
			40.065,
			40.126,
			40.191,
			40.253,
			40.314,
			40.376,
			40.468,
			40.499,
			40.577,
			40.624,
			40.687,
			40.747,
			40.794,
			40.808,
			40.872,
			40.902,
			40.934,
			40.995,
			41.012,
			41.059,
			41.119,
			41.182,
			41.244,
			41.305,
			41.336,
			41.368,
			41.444,
			41.49,
			41.555,
			41.662,
			41.676,
			41.739,
			41.776,
			41.801,
			41.862,
			41.88,
			41.925,
			41.988,
			42.048,
			42.111,
			42.175,
			42.206,
			42.237,
			42.314,
			42.361,
			42.421,
			42.528,
			42.608,
			42.639,
			42.669,
			42.749,
			42.794,
			42.857,
			42.919,
			42.981,
			43.041,
			43.074,
			43.105,
			43.167,
			43.182,
			43.229,
			43.291,
			43.353,
			43.415,
			43.477,
			43.507,
			43.537,
			43.661,
			43.725,
			43.786,
			43.848,
			43.911,
			43.973,
			44.035,
			44.049,
			44.096,
			44.157,
			44.219,
			44.265,
			44.283,
			44.344,
			44.374,
			44.407,
			44.484,
			44.532,
			44.655,
			44.716,
			44.776,
			44.808,
			44.839,
			44.902,
			44.965,
			45.028,
			45.088,
			45.151,
			45.21,
			45.243,
			45.274,
			45.352,
			45.399,
			45.461,
			45.523,
			45.569,
			45.585,
			45.677,
			45.71,
			45.771,
			45.786,
			45.833,
			45.899,
			45.959,
			46.02,
			46.081,
			46.11,
			46.144,
			46.22,
			46.265,
			46.329,
			46.391,
			46.438,
			46.452,
			46.544,
			46.577,
			46.638,
			46.653,
			46.7,
			46.763,
			46.826,
			46.885,
			46.949,
			46.98,
			47.01,
			47.089,
			47.135,
			47.195,
			47.305,
			47.415,
			47.444,
			47.522,
			47.567,
			47.632,
			47.692,
			47.755,
			47.815,
			47.848,
			47.879,
			47.965,
			48.003,
			48.069,
			48.174,
			48.252,
			48.283,
			48.314,
			48.436,
			48.499,
			48.56,
			48.624,
			48.684,
			48.717,
			48.748,
			48.807,
			48.872,
			48.932,
			48.994,
			49.041,
			49.057,
			49.12,
			49.149,
			49.181,
			49.259,
			49.306,
			49.368,
			49.43,
			49.492,
			49.552,
			49.583,
			49.615,
			49.676,
			49.738,
			49.801,
			49.908,
			49.926,
			49.988,
			50.024,
			50.049,
			50.126,
			50.173,
			50.235,
			50.297,
			50.36,
			50.421,
			50.452,
			50.484,
			50.56,
			50.607,
			50.669,
			50.731,
			50.794,
			50.885,
			50.917,
			50.994,
			51.04,
			51.102,
			51.165,
			51.213,
			51.226,
			51.29,
			51.352,
			51.414,
			51.427,
			51.474,
			51.538,
			51.599,
			51.66,
			51.724,
			51.755,
			51.785,
			51.863,
			51.91,
			51.973,
			52.032,
			52.085,
			52.096,
			52.188,
			52.22,
			52.297,
			52.344,
			52.407,
			52.468,
			52.53,
			52.592,
			52.624,
			52.655,
			52.715,
			52.731,
			52.777,
			52.84,
			52.901,
			52.949,
			53.027,
			53.059,
			53.087,
			53.165,
			53.211,
			53.274,
			53.337,
			53.398,
			53.459,
			53.49,
			53.521,
			53.585,
			53.598,
			53.647,
			53.708,
			53.769,
			53.815,
			53.832,
			53.895,
			53.925,
			53.955,
			54.033,
			54.08,
			54.147,
			54.203,
			54.267,
			54.329,
			54.359,
			54.389,
			54.451,
			54.514,
			54.576,
			54.639,
			54.686,
			54.699,
			54.763,
			54.793,
			54.824,
			54.901,
			54.948,
			55.01,
			55.072,
			55.133,
			55.197,
			55.226,
			55.259,
			55.32,
			55.336,
			55.383,
			55.443,
			55.507,
			55.551,
			55.63,
			55.659,
			55.692,
			55.768,
			55.815,
			55.876,
			55.941,
			56.002,
			56.064,
			56.095,
			56.127,
			56.188,
			56.207,
			56.25,
			56.31,
			56.373,
			56.437,
			56.499,
			56.53,
			56.56,
			56.637,
			56.684,
			56.747,
			56.809,
			56.854,
			56.933,
			56.963,
			56.994,
			57.056,
			57.073,
			57.119,
			57.182,
			57.243,
			57.305,
			57.368,
			57.399,
			57.427,
			57.505,
			57.552,
			57.616,
			57.677,
			57.723,
			57.833,
			57.86,
			57.94,
			57.986,
			58.05,
			58.112,
			58.172,
			58.235,
			58.269,
			58.298,
			58.36,
			58.373,
			58.422,
			58.481,
			58.59,
			58.606,
			58.669,
			58.699,
			58.731,
			58.809,
			58.856,
			58.916,
			58.977,
			59.041,
			59.102,
			59.134,
			59.166,
			59.227,
			59.289,
			59.351,
			59.41,
			59.458,
			59.475,
			59.537,
			59.568,
			59.598,
			59.677,
			59.723,
			59.784,
			59.845,
			59.909,
			59.971,
			60.001,
			60.033,
			60.096,
			60.112,
			60.157,
			60.219,
			60.282,
			60.343,
			60.406,
			60.437,
			60.467,
			60.543,
			60.591,
			60.652,
			60.716,
			60.777,
			60.838,
			60.869,
			60.9,
			60.964,
			60.979,
			61.024,
			61.088,
			61.15,
			61.197,
			61.273,
			61.335,
			61.414,
			61.459,
			61.52,
			61.584,
			61.63,
			61.738,
			61.768,
			61.832,
			61.847,
			61.895,
			61.956,
			62.018,
			62.077,
			62.14,
			62.172,
			62.202,
			62.282,
			62.327,
			62.397,
			62.45,
			62.499,
			62.576,
			62.606,
			62.638,
			62.699,
			62.761,
			62.824,
			62.886,
			62.947,
			63.011,
			63.042,
			63.072,
			63.135,
			63.148,
			63.194,
			63.256,
			63.32,
			63.366,
			63.382,
			63.442,
			63.474,
			63.505,
			63.582,
			63.63,
			63.69,
			63.753,
			63.815,
			63.876,
			63.908,
			63.939,
			64.003,
			64.063,
			64.126,
			64.188,
			64.233,
			64.25,
			64.311,
			64.341,
			64.375,
			64.455,
			64.499,
			64.56,
			64.623,
			64.683,
			64.744,
			64.777,
			64.808,
			64.869,
			64.885,
			64.931,
			64.994,
			65.055,
			65.102,
			65.181,
			65.21,
			65.243,
			65.318,
			65.365,
			65.426,
			65.489,
			65.536,
			65.552,
			65.615,
			65.643,
			65.677,
			65.739,
			65.799,
			65.865,
			65.923,
			65.986,
			66.049,
			66.077,
			66.111,
			66.188,
			66.234,
			66.297,
			66.359,
			66.405,
			66.482,
			66.516,
			66.545,
			66.605,
			66.669,
			66.73,
			66.791,
			66.854,
			66.916,
			66.945,
			66.979,
			67.056,
			67.102,
			67.168,
			67.225,
			67.272,
			67.351,
			67.382,
			67.41,
			67.491,
			67.535,
			67.598,
			67.66,
			67.722,
			67.785,
			67.816,
			67.848,
			67.909,
			67.969,
			68.033,
			68.093,
			68.14,
			68.157,
			68.217,
			68.25,
			68.279,
			68.357,
			68.405,
			68.468,
			68.529,
			68.59,
			68.653,
			68.684,
			68.714,
			68.776,
			68.838,
			68.902,
			68.963,
			69.01,
			69.025,
			69.087,
			69.149,
			69.226,
			69.272,
			69.335,
			69.459,
			69.52,
			69.583,
			69.644,
			69.661,
			69.707,
			69.77,
			69.83,
			69.877,
			69.954,
			69.986,
			70.017,
			70.095,
			70.14,
			70.202,
			70.265,
			70.31,
			70.39,
			70.449,
			70.513,
			70.575,
			70.642,
			70.699,
			70.762,
			70.824,
			70.855,
			70.883,
			70.961,
			71.01,
			71.071,
			71.179,
			71.257,
			71.319,
			71.396,
			71.443,
			71.506,
			71.568,
			71.628,
			71.691,
			71.722,
			71.754,
			71.816,
			71.831,
			71.876,
			71.939,
			72.047,
			72.124,
			72.155,
			72.186,
			72.265,
			72.31,
			72.374,
			72.434,
			72.498,
			72.559,
			72.592,
			72.621,
			72.701,
			72.745,
			72.809,
			72.915,
			72.932,
			73.024,
			73.055,
			73.132,
			73.177,
			73.242,
			73.303,
			73.366,
			73.427,
			73.458,
			73.488,
			73.551,
			73.613,
			73.675,
			73.736,
			73.784,
			73.798,
			73.86,
			73.895,
			73.928,
			74,
			74.047,
			74.115,
			74.172,
			74.232,
			74.296,
			74.326,
			74.357,
			74.419,
			74.482,
			74.545,
			74.606,
			74.668,
			74.73,
			74.765,
			74.793,
			74.869,
			74.917,
			74.977,
			75.085,
			75.103,
			75.195,
			75.225,
			75.303,
			75.349,
			75.411,
			75.474,
			75.534,
			75.627,
			75.66,
			75.738,
			75.783,
			75.845,
			75.909,
			75.955,
			76.031,
			76.093,
			76.218,
			76.281,
			76.343,
			76.404,
			76.466,
			76.526,
			76.59,
			76.606,
			76.652,
			76.715,
			76.777,
			76.839,
			76.932,
			76.96,
			77.04,
			77.086,
			77.149,
			77.211,
			77.273,
			77.334,
			77.366,
			77.395,
			77.459,
			77.473,
			77.521,
			77.581,
			77.643,
			77.706,
			77.798,
			77.828,
			77.907,
			77.955,
			78.018,
			78.077,
			78.141,
			78.201,
			78.264,
			78.327,
			78.388,
			78.451,
			78.511,
			78.573,
			78.635,
			78.699,
			78.776,
			78.823,
			78.888,
			78.944,
			78.993,
			79.007,
			79.102,
			79.132,
			79.193,
			79.256,
			79.318,
			79.38,
			79.443,
			79.504,
			79.536,
			79.565,
			79.645,
			79.692,
			79.751,
			79.815,
			79.862,
			79.875,
			79.969,
			80.061,
			80.077,
			80.123,
			80.188,
			80.25,
			80.311,
			80.371,
			80.404,
			80.435,
			80.513,
			80.559,
			80.622,
			80.728,
			80.808,
			80.838,
			80.931,
			80.95,
			80.993,
			81.056,
			81.115,
			81.18,
			81.271,
			81.302,
			81.381,
			81.426,
			81.488,
			81.598,
			81.674,
			81.705,
			81.737,
			81.815,
			81.862,
			81.925,
			81.984,
			82.045,
			82.14,
			82.17,
			82.232,
			82.293,
			82.359,
			82.464,
			82.482,
			82.542,
			82.574,
			82.605,
			82.682,
			82.73,
			82.79,
			82.852,
			82.915,
			82.977,
			83.015,
			83.039,
			83.102,
			83.164,
			83.226,
			83.286,
			83.335,
			83.348,
			83.412,
			83.474,
			83.552,
			83.597,
			83.659,
			83.722,
			83.784,
			83.843,
			83.877,
			83.908,
			83.968,
			84.031,
			84.094,
			84.155,
			84.216,
			84.28,
			84.34,
			84.418,
			84.465,
			84.532,
			84.589,
			84.651,
			84.713,
			84.745,
			84.776,
			84.837,
			84.899,
			84.961,
			85.022,
			85.085,
			85.148,
			85.176,
			85.21,
			85.286,
			85.334,
			85.396,
			85.46,
			85.521,
			85.614,
			85.644,
			85.721,
			85.769,
			85.83,
			85.891,
			85.952,
			86.016,
			86.047,
			86.077,
			86.156,
			86.201,
			86.267,
			86.325,
			86.388,
			86.482,
			86.513,
			86.588,
			86.637,
			86.698,
			86.761,
			86.822,
			86.883,
			86.915,
			86.944,
			87.022,
			87.071,
			87.135,
			87.243,
			87.255,
			87.348,
			87.38,
			87.457,
			87.504,
			87.567,
			87.626,
			87.69,
			87.751,
			87.781,
			87.815,
			87.877,
			87.937,
			88.001,
			88.062,
			88.123,
			88.215,
			88.249,
			88.325,
			88.372,
			88.435,
			88.495,
			88.558,
			88.652,
			88.682,
			88.745,
			88.759,
			88.807,
			88.869,
			88.928,
			88.975,
			89.054,
			89.115,
			89.197,
			89.239,
			89.303,
			89.364,
			89.425,
			89.489,
			89.52,
			89.549,
			89.613,
			89.626,
			89.673,
			89.736,
			89.798,
			89.859,
			89.954,
			89.985,
			90.061,
			90.107,
			90.171,
			90.233,
			90.295,
			90.357,
			90.418,
			90.495,
			90.541,
			90.605,
			90.666,
			90.728,
			90.822,
			90.851,
			90.931,
			90.976,
			91.039,
			91.149,
			91.225,
			91.285,
			91.349,
			91.411,
			91.472,
			91.536,
			91.598,
			91.688,
			91.719,
			91.798,
			91.843,
			91.907,
			92.016,
			92.032,
			92.124,
			92.155,
			92.278,
			92.34,
			92.402,
			92.466,
			92.557,
			92.59,
			92.665,
			92.713,
			92.776,
			92.838,
			92.898,
			92.962,
			92.991,
			93.021,
			93.148,
			93.208,
			93.272,
			93.331,
			93.393,
			93.459,
			93.535,
			93.582,
			93.644,
			93.705,
			93.751,
			93.828,
			93.861,
			93.891,
			93.968,
			94.016,
			94.077,
			94.138,
			94.2,
			94.264,
			94.293,
			94.324,
			94.388,
			94.402,
			94.449,
			94.51,
			94.575,
			94.635,
			94.697,
			94.728,
			94.759,
			94.835,
			94.882,
			94.944,
			95.069,
			95.132,
			95.192,
			95.271,
			95.318,
			95.385,
			95.443,
			95.503,
			95.565,
			95.597,
			95.626,
			95.705,
			95.75,
			95.815,
			95.922,
			96.031,
			96.061,
			96.124,
			96.186,
			96.249,
			96.309,
			96.371,
			96.433,
			96.465,
			96.494,
			96.574,
			96.618,
			96.682,
			96.742,
			96.806,
			96.868,
			96.899,
			96.927,
			97.009,
			97.052,
			97.116,
			97.176,
			97.241,
			97.302,
			97.333,
			97.363,
			97.447,
			97.489,
			97.55,
			97.657,
			97.674,
			97.735,
			97.795,
			97.875,
			97.923,
			97.985,
			98.043,
			98.107,
			98.169,
			98.232,
			98.294,
			98.357,
			98.418,
			98.526,
			98.605,
			98.635,
			98.666,
			98.743,
			98.789,
			98.851,
			98.914,
			98.977,
			99.038,
			99.1,
			99.161,
			99.178,
			99.223,
			99.285,
			99.348,
			99.394,
			99.41,
			99.473,
			99.506,
			99.533,
			99.612,
			99.658,
			99.722,
			99.782,
			99.844,
			99.937,
			99.968,
			100.029,
			100.092,
			100.153,
			100.216,
			100.277,
			100.341,
			100.371,
			100.401,
			100.48,
			100.525,
			100.588,
			100.65,
			100.695,
			100.775,
			100.805,
			100.836,
			100.899,
			100.96,
			101.024,
			101.084,
			101.144,
			101.21,
			101.27,
			101.349,
			101.393,
			101.458,
			101.479
		]
	],
	'262_266_7982': [
		[
			73.82
		],
		[
			0
		],
		[
			0,
			3.105,
			8.719,
			15.764,
			20.005,
			22.843,
			27.113,
			29.964,
			34.252,
			37.118,
			38.554,
			41.43,
			45.756,
			48.649,
			51.549,
			55.911,
			57.369,
			58.828,
			64.678,
			66.146,
			73.507,
			77.942,
			80.906,
			83.875,
			89.831,
			91.323,
			95.81,
			97.308,
			98.807,
			103.314,
			106.326,
			109.345,
			110.856,
			113.884,
			116.918,
			118.438,
			123.009,
			126.068,
			129.136,
			133.755,
			136.849,
			143.08,
			144.649,
			149.378,
			152.547,
			157.326,
			160.512,
			163.701,
			165.289,
			168.471,
			170.057,
			173.23,
			177.99,
			179.578,
			184.35,
			187.536,
			192.328,
			195.528,
			200.335,
			203.548,
			208.378,
			211.602,
			216.453,
			219.695,
			226.197,
			227.825,
			232.716,
			235.979,
			240.88,
			244.151,
			249.088,
			255.61,
			257.248,
			262.165,
			265.445,
			268.726,
			273.651,
			276.934,
			280.217,
			285.142,
			290.069,
			293.357,
			296.648,
			301.589,
			304.887,
			309.841,
			313.149,
			318.12,
			321.44,
			326.427,
			329.757,
			334.759,
			338.099,
			343.116,
			348.133,
			351.464,
			354.773,
			359.684,
			362.919,
			367.704,
			370.852,
			375.505,
			378.56,
			383.075,
			386.042,
			390.421,
			394.714,
			396.127,
			401.683,
			403.049,
			407.094,
			409.745,
			412.357,
			413.649,
			416.205,
			419.968,
			422.428,
			423.643,
			426.047,
			428.415,
			431.901,
			434.18,
			438.621,
			439.707,
			442.915,
			445.008,
			448.083,
			452.057,
			453.028,
			454.944,
			455.888,
			457.754,
			460.504,
			462.287,
			464.036,
			465.75,
			468.255,
			469.884,
			471.487,
			472.277,
			474.611,
			476.151,
			478.432,
			479.94,
			481.448,
			483.683,
			484.424,
			487.403,
			488.911,
			491.186,
			492.727,
			495.166,
			497.616,
			499.403,
			502.1,
			504.002,
			505.961,
			508.957,
			511.027,
			515.115,
			516.125,
			519.094,
			520.993,
			522.887,
			525.595,
			527.381,
			529.893,
			530.694,
			531.493,
			533.867,
			535.382,
			537.651,
			539.151,
			540.666,
			541.422,
			542.931,
			543.683,
			545.272,
			549.29,
			551.027,
			553.61,
			555.38,
			558.904,
			559.823,
			562.634,
			564.506,
			567.367,
			569.264,
			570.958,
			573.469,
			574.305,
			575.08,
			578.043,
			578.782,
			580.954,
			582.378,
			584.515,
			585.923,
			588.025,
			589.424,
			591.525,
			592.93,
			595.047,
			596.496,
			598.681,
			600.148,
			602.395,
			603.906,
			606.191,
			607.728,
			610.058,
			611.63,
			614.015,
			615.62,
			616.43,
			617.243,
			619.7,
			621.341,
			623.01,
			623.849,
			625.551,
			627.252,
			628.109,
			630.683,
			632.426,
			634.183,
			636.842,
			638.632,
			642.25,
			643.163,
			645.937,
			647.792,
			650.579,
			652.474,
			655.322,
			658.184,
			660.118,
			663.039,
			665.002,
			666.977,
			671.962,
			675.998,
			677.014,
			680.077,
			682.133,
			684.199,
			687.318,
			689.409,
			692.554,
			694.65,
			697.809,
			699.926,
			703.118,
			705.257,
			708.482,
			710.643,
			713.901,
			716.084,
			719.375,
			721.58,
			724.906,
			726.019,
			727.135,
			730.496,
			731.621,
			732.749,
			736.145,
			738.422,
			740.709,
			746.469,
			751.12,
			753.459,
			756.986,
			758.167,
			761.722,
			764.104,
			766.496,
			767.695,
			770.102,
			772.518,
			773.729,
			777.376,
			779.818,
			785.967,
			788.442,
			792.171,
			794.669,
			798.433,
			800.953,
			804.752,
			809.848,
			811.128,
			813.694,
			816.27,
			821.444,
			824.039,
			826.634,
			829.218,
			833.065,
			838.106,
			839.345,
			841.793,
			843.003,
			845.395,
			847.748,
			853.466,
			856.782,
			857.868,
			858.946,
			862.123,
			864.195,
			867.246,
			869.239,
			872.162,
			875.004,
			876.868,
			878.684,
			881.335,
			883.082,
			886.508,
			887.351,
			889.883,
			891.563,
			894.083,
			895.758,
			897.374,
			899.784,
			901.397,
			904.675,
			905.497,
			907.952,
			909.59,
			912.084,
			913.816,
			916.437,
			919.243,
			921.16,
			924.238,
			926.328,
			928.545,
			934.155,
			937.463,
			939.494,
			942.524,
			944.363,
			947.125,
			948.917,
			950.71,
			953.424,
			955.249,
			958.014,
			959.931,
			962.826,
			964.869,
			966.921,
			967.951,
			970.149,
			973.447,
			975.78,
			979.277,
			981.599,
			984.992,
			987.179,
			990.304,
			991.303,
			992.291,
			995.17,
			997.019,
			999.727,
			1001.494,
			1003.25,
			1004.126,
			1006.745,
			1008.496,
			1010.257,
			1011.142,
			1012.028,
			1014.689,
			1016.473,
			1019.139,
			1020.912,
			1022.678,
			1023.555,
			1026.168,
			1027.89,
			1029.594,
			1032.108,
			1033.752,
			1036.165,
			1037.736,
			1040.033,
			1041.524,
			1043.707,
			1045.134,
			1047.239,
			1048.624,
			1050.685,
			1052.731,
			1054.096,
			1055.466,
			1057.529,
			1058.91,
			1061.688,
			1062.389,
			1063.794,
			1065.906,
			1067.325,
			1069.466,
			1070.903,
			1073.101,
			1073.844,
			1074.591,
			1076.857,
			1078.403,
			1080.749,
			1083.933,
			1084.738,
			1086.357,
			1088.811,
			1090.458,
			1091.286,
			1092.947,
			1094.615,
			1097.13,
			1098.842,
			1101.42,
			1102.281,
			1103.141,
			1105.723,
			1107.447,
			1109.136,
			1111.664,
			1113.334,
			1115.793,
			1117.427,
			1119.869,
			1121.491,
			1123.922,
			1125.543,
			1127.972,
			1129.585,
			1130.391,
			1132.011,
			1134.465,
			1136.108,
			1137.755,
			1140.271,
			1141.972,
			1144.559,
			1145.435,
			1146.314,
			1148.975,
			1150.776,
			1153.505,
			1155.34,
			1157.187,
			1159.984,
			1160.918,
			1161.856,
			1165.648,
			1167.561,
			1169.491,
			1171.435,
			1173.395,
			1174.38,
			1176.361,
			1179.36,
			1182.388,
			1186.472,
			1189.551,
			1191.613,
			1195.749,
			1196.78,
			1199.854,
			1201.876,
			1204.851,
			1208.701,
			1209.642,
			1211.5,
			1214.224,
			1216.882,
			1218.619,
			1220.333,
			1224.542,
			1226.206,
			1227.864,
			1228.691,
			1230.347,
			1232.842,
			1234.514,
			1237.036,
			1238.726,
			1241.274,
			1242.128,
			1242.985,
			1245.56,
			1247.285,
			1249.896,
			1251.64,
			1254.271,
			1256.029,
			1256.905,
			1258.661,
			1261.315,
			1263.065,
			1264.855,
			1267.517,
			1268.393,
			1269.304,
			1272.061,
			1272.964,
			1273.859,
			1276.655,
			1278.481,
			1281.205,
			1284.733,
			1287.246,
			1288.071,
			1288.889,
			1291.27,
			1292.821,
			1295.13,
			1296.596,
			1298.8,
			1300.275,
			1301.688,
			1302.394,
			1304.524,
			1305.946,
			1307.329,
			1309.415,
			1310.819,
			1314.365,
			1316.54,
			1318.014,
			1320.253,
			1321.776,
			1324.066,
			1325.609,
			1326.386,
			1327.952,
			1330.332,
			1331.119,
			1333.552,
			1335.196,
			1336.858,
			1337.684,
			1339.346,
			1341.873,
			1343.584,
			1344.447,
			1346.186,
			1347.926,
			1350.598,
			1352.397,
			1356.055,
			1356.976,
			1359.764,
			1361.643,
			1364.473,
			1368.318,
			1369.288,
			1371.238,
			1372.218,
			1374.187,
			1377.184,
			1379.17,
			1381.184,
			1384.226,
			1386.27,
			1388.327,
			1391.432,
			1394.559,
			1396.657,
			1398.766,
			1401.936,
			1404.048,
			1407.23,
			1409.364,
			1412.581,
			1414.737,
			1417.989,
			1420.167,
			1423.456,
			1425.66,
			1426.766,
			1428.99,
			1431.225,
			1432.365,
			1434.606,
			1436.874,
			1441.463,
			1442.617,
			1444.942,
			1447.294,
			1448.462,
			1451.957,
			1454.323,
			1457.834,
			1460.201,
			1462.552,
			1466.065,
			1467.233,
			1468.401,
			1473.023,
			1474.199,
			1477.653,
			1479.947,
			1483.371,
			1485.644,
			1487.908,
			1489.038,
			1492.421,
			1494.674,
			1496.928,
			1500.316,
			1502.564,
			1505.979,
			1507.141,
			1508.285,
			1511.731,
			1514.04,
			1517.519,
			1519.848,
			1523.339,
			1525.69,
			1529.234,
			1532.817,
			1534.009,
			1537.58,
			1539.985,
			1543.608,
			1546.035,
			1548.471,
			1549.692,
			1553.367,
			1555.827,
			1558.296,
			1562.015,
			1564.505,
			1568.274,
			1569.528,
			1570.785,
			1574.57,
			1577.084,
			1580.912,
			1583.48,
			1587.355,
			1589.951,
			1591.252,
			1593.887,
			1597.809,
			1600.467,
			1603.147,
			1605.831,
			1608.517,
			1609.865,
			1612.543,
			1615.246,
			1616.598,
			1620.67,
			1626.046,
			1630.069,
			1632.742,
			1636.75,
			1638.083,
			1639.412,
			1643.392,
			1646.021,
			1649.935,
			1655.036,
			1656.274,
			1660.043,
			1662.523,
			1666.203,
			1671.136,
			1674.795,
			1677.226,
			1680.831,
			1683.241,
			1686.866,
			1689.244,
			1692.835,
			1695.246,
			1697.636,
			1701.208,
			1702.407,
			1703.608,
			1708.434,
			1709.647,
			1713.296,
			1715.74,
			1719.422,
			1721.891,
			1724.367,
			1725.609,
			1728.099,
			1729.348,
			1731.853,
			1734.389,
			1738.159,
			1740.698,
			1744.522,
			1745.801,
			1747.082,
			1750.957,
			1753.534,
			1757.413,
			1760.006,
			1763.886,
			1766.493,
			1767.799,
			1770.414,
			1773.033,
			1774.345,
			1775.657,
			1779.601,
			1782.235,
			1784.875,
			1786.197,
			1791.493,
			1792.82,
			1796.801,
			1799.441,
			1802.059,
			1805.927,
			1808.464,
			1812.212,
			1813.454,
			1814.691,
			1818.379,
			1820.808,
			1824.406,
			1826.768,
			1830.25,
			1832.528,
			1835.883,
			1839.166,
			1841.318,
			1846.552,
			1848.588,
			1850.591,
			1852.565,
			1853.539,
			1855.465,
			1856.419,
			1858.312,
			1860.193,
			1862.989,
			1864.848,
			1867.622,
			1868.538,
			1869.453,
			1873.075,
			1873.973,
			1876.661,
			1878.446,
			1881.118,
			1882.895,
			1883.78,
			1885.546,
			1887.305,
			1888.181,
			1889.929,
			1891.672,
			1894.272,
			1896,
			1897.726,
			1899.449,
			1900.31,
			1902.901,
			1904.634,
			1907.24,
			1908.995,
			1910.752,
			1914.309,
			1915.205,
			1917.907,
			1919.733,
			1922.476,
			1924.327,
			1927.104,
			1928.977,
			1929.914,
			1931.789,
			1934.623,
			1936.515,
			1938.436,
			1943.319,
			1946.301,
			1947.297,
			1948.305,
			1951.372,
			1953.413,
			1956.458,
			1958.496,
			1960.549,
			1963.62,
			1964.62,
			1965.62,
			1969.616,
			1970.607,
			1973.587,
			1975.516,
			1977.441,
			1978.406,
			1980.31,
			1982.2,
			1983.148,
			1985.991,
			1987.89,
			1989.799,
			1992.675,
			1994.605,
			1997.516,
			1998.491,
			1999.469,
			2002.405,
			2004.377,
			2007.343,
			2009.335,
			2011.339,
			2012.345,
			2014.367,
			2015.383,
			2017.433,
			2020.528,
			2024.711,
			2026.808,
			2029.978,
			2032.106,
			2034.248,
			2035.322,
			2037.479,
			2038.562,
			2040.735,
			2042.916,
			2046.183,
			2048.383,
			2051.696,
			2052.805,
			2055.028,
			2057.26,
			2059.502,
			2062.899,
			2065.16,
			2068.541,
			2070.811,
			2074.217,
			2076.486,
			2077.621,
			2079.894,
			2082.167,
			2085.609,
			2087.896,
			2091.325,
			2093.629,
			2097.104,
			2099.435,
			2102.958,
			2105.331,
			2107.718,
			2111.337,
			2112.55,
			2113.77,
			2117.444,
			2119.914,
			2123.642,
			2126.153,
			2128.675,
			2129.944,
			2135.053,
			2136.348,
			2140.248,
			2142.862,
			2145.491,
			2149.49,
			2152.163,
			2156.165,
			2158.854,
			2162.863,
			2165.536,
			2169.526,
			2172.159,
			2174.784,
			2176.092,
			2178.696,
			2180.001,
			2181.306,
			2186.529,
			2187.84,
			2191.783,
			2194.43,
			2198.419,
			2203.787,
			2205.137,
			2207.849,
			2209.214,
			2211.949,
			2214.709,
			2218.881,
			2221.718,
			2225.943,
			2227.365,
			2228.791,
			2234.584,
			2236.028,
			2240.367,
			2243.268,
			2247.632,
			2250.555,
			2254.967,
			2257.927,
			2262.387,
			2265.371,
			2268.357,
			2272.831,
			2275.806,
			2278.746,
			2280.214,
			2284.583,
			2287.46,
			2290.345,
			2294.679,
			2297.572,
			2301.926,
			2303.38,
			2304.836,
			2309.214,
			2312.14,
			2316.542,
			2319.485,
			2322.434,
			2323.911,
			2326.87,
			2328.351,
			2331.317,
			2335.777,
			2338.758,
			2341.745,
			2346.24,
			2349.246,
			2352.258,
			2356.79,
			2362.86,
			2364.382,
			2367.429,
			2368.955,
			2372.01,
			2375.07,
			2379.67,
			2382.743,
			2388.909,
			2390.454,
			2395.098,
			2398.201,
			2402.861,
			2405.973,
			2410.65,
			2415.342,
			2418.478,
			2423.191,
			2426.341,
			2429.495,
			2434.234,
			2435.816,
			2437.399,
			2442.156,
			2443.744,
			2445.333,
			2450.105,
			2453.292,
			2456.478,
			2461.263,
			2464.458,
			2469.257,
			2470.858,
			2472.461,
			2478.882,
			2480.49,
			2485.319,
			2488.545,
			2491.774,
			2493.389,
			2499.863,
			2501.484,
			2506.353,
			2509.604,
			2512.859,
			2517.748,
			2521.011,
			2527.55,
			2529.187,
			2534.104,
			2537.387,
			2542.317,
			2545.609,
			2548.904,
			2550.553,
			2553.854,
			2555.506,
			2557.158,
			2563.782,
			2565.44,
			2570.421,
			2573.746,
			2577.073,
			2578.738,
			2585.408,
			2587.077,
			2592.094,
			2595.444,
			2598.798,
			2607.204,
			2613.948,
			2615.637,
			2622.402,
			2624.095,
			2629.179,
			2632.571,
			2637.662,
			2642.759,
			2646.161,
			2649.566,
			2654.679,
			2658.091,
			2663.214,
			2666.633,
			2671.77,
			2673.484,
			2675.198,
			2680.348,
			2683.785,
			2687.225,
			2692.391,
			2695.839,
			2701.018,
			2704.475,
			2709.667,
			2713.133,
			2718.34,
			2721.819,
			2727.046,
			2730.536,
			2732.283,
			2735.778,
			2741.022,
			2744.519,
			2748.018,
			2755.027,
			2756.78,
			2762.046,
			2763.803,
			2765.561,
			2774.36,
			2777.886,
			2783.182,
			2786.714,
			2792.018,
			2793.787,
			2795.556,
			2802.608,
			2804.359,
			2809.566,
			2812.992,
			2818.058,
			2821.386,
			2826.306,
			2829.539,
			2831.141,
			2834.318,
			2837.461,
			2842.107,
			2845.161,
			2849.678,
			2852.643,
			2857.015,
			2859.88,
			2864.103,
			2866.868,
			2869.594,
			2870.944,
			2877.546,
			2880.119,
			2881.392,
			2882.655,
			2886.388,
			2888.829,
			2892.426,
			2897.104,
			2898.251,
			2900.517,
			2901.636,
			2903.849,
			2906.03,
			2909.239,
			2911.333,
			2914.407,
			2915.414,
			2916.411,
			2919.349,
			2921.262,
			2924.064,
			2925.89,
			2928.567,
			2931.167,
			2932.857,
			2935.324,
			2936.926,
			2938.507,
			2940.796,
			2941.543,
			2942.281,
			2943.73,
			2945.145,
			2945.841,
			2947.211,
			2947.885,
			2949.212,
			2950.521,
			2952.454,
			2953.731,
			2955.622,
			2956.248,
			2956.872,
			2958.764,
			2960.024,
			2961.913,
			2963.2,
			2964.503,
			2965.155,
			2966.458,
			2967.775,
			2968.457,
			2970.501,
			2971.868,
			2973.265,
			2975.399,
			2976.828,
			2979.033,
			2980.525,
			2982.771,
			2984.362,
			2985.957,
			2989.902,
			2992.236,
			2993.776,
			2996.051,
			2997.564,
			2999.858,
			3001.389,
			3003.691,
			3005.238,
			3006.781,
			3007.552,
			3009.076,
			3009.827,
			3011.327,
			3012.828,
			3016.507,
			3018.714,
			3019.45,
			3020.185,
			3023.135,
			3023.879,
			3026.127,
			3027.631,
			3029.146,
			3032.236,
			3033.792,
			3035.355,
			3036.922,
			3039.29,
			3040.838,
			3042.384,
			3043.16,
			3044.72,
			3046.251,
			3047.02,
			3049.34,
			3050.887,
			3052.431,
			3056.327,
			3058.685,
			3059.478,
			3060.274,
			3062.686,
			3064.307,
			3066.759,
			3068.398,
			3070.047,
			3070.876,
			3072.556,
			3073.4,
			3075.095,
			3077.67,
			3079.403,
			3081.147,
			3083.779,
			3085.557,
			3087.349,
			3090.061,
			3092.802,
			3094.646,
			3096.501,
			3101.199,
			3105.014,
			3105.975,
			3108.877,
			3110.825,
			3113.771,
			3115.749,
			3118.747,
			3121.73,
			3123.743,
			3125.765,
			3126.78,
			3128.817,
			3130.864,
			3133.953,
			3136.024,
			3140.196,
			3141.26,
			3144.395,
			3146.485,
			3148.577,
			3151.717,
			3153.795,
			3156.953,
			3158.009,
			3159.068,
			3162.261,
			3163.331,
			3164.403,
			3167.634,
			3169.801,
			3173.069,
			3175.261,
			3178.568,
			3181.897,
			3184.13,
			3186.373,
			3192.027,
			3195.449,
			3197.744,
			3201.204,
			3203.523,
			3207.02,
			3209.364,
			3212.897,
			3215.266,
			3216.454,
			3218.837,
			3222.43,
			3224.837,
			3227.253,
			3229.678,
			3233.333,
			3235.779,
			3238.236,
			3239.467,
			3241.935,
			3243.172,
			3245.653,
			3248.142,
			3251.89,
			3254.397,
			3258.176,
			3259.44,
			3260.706,
			3264.517,
			3267.068,
			3270.91,
			3273.482,
			3276.062,
			3277.356,
			3279.948,
			3281.248,
			3283.852,
			3286.456,
			3290.371,
			3292.991,
			3295.619,
			3299.577,
			3303.554,
			3306.217,
			3310.222,
			3312.901,
			3315.591,
			3322.342,
			3327.774,
			3329.139,
			3333.248,
			3335.989,
			3340.113,
			3342.873,
			3347.028,
			3349.807,
			3351.2,
			3353.991,
			3356.204
		],
		[
			0,
			0.046,
			0.151,
			0.289,
			0.365,
			0.414,
			0.474,
			0.538,
			0.598,
			0.661,
			0.692,
			0.725,
			0.801,
			0.846,
			0.911,
			0.971,
			1.017,
			1.031,
			1.127,
			1.156,
			1.28,
			1.344,
			1.407,
			1.467,
			1.559,
			1.59,
			1.654,
			1.67,
			1.715,
			1.779,
			1.84,
			1.887,
			1.9,
			1.965,
			1.995,
			2.026,
			2.11,
			2.151,
			2.212,
			2.273,
			2.334,
			2.427,
			2.459,
			2.521,
			2.584,
			2.645,
			2.706,
			2.756,
			2.771,
			2.83,
			2.864,
			2.894,
			2.972,
			3.017,
			3.08,
			3.142,
			3.204,
			3.266,
			3.328,
			3.388,
			3.452,
			3.514,
			3.576,
			3.639,
			3.731,
			3.763,
			3.84,
			3.887,
			3.948,
			4.011,
			4.074,
			4.169,
			4.195,
			4.275,
			4.32,
			4.383,
			4.445,
			4.506,
			4.567,
			4.63,
			4.709,
			4.754,
			4.817,
			4.879,
			4.94,
			5,
			5.063,
			5.142,
			5.187,
			5.251,
			5.311,
			5.374,
			5.437,
			5.498,
			5.576,
			5.621,
			5.682,
			5.745,
			5.809,
			5.872,
			5.931,
			5.995,
			6.056,
			6.118,
			6.18,
			6.243,
			6.334,
			6.366,
			6.445,
			6.492,
			6.551,
			6.614,
			6.662,
			6.677,
			6.74,
			6.8,
			6.863,
			6.878,
			6.925,
			6.988,
			7.048,
			7.112,
			7.205,
			7.234,
			7.313,
			7.358,
			7.422,
			7.529,
			7.545,
			7.608,
			7.638,
			7.669,
			7.745,
			7.793,
			7.856,
			7.916,
			7.978,
			8.039,
			8.072,
			8.103,
			8.18,
			8.226,
			8.295,
			8.352,
			8.397,
			8.476,
			8.506,
			8.599,
			8.66,
			8.724,
			8.784,
			8.845,
			8.939,
			8.97,
			9.048,
			9.095,
			9.156,
			9.22,
			9.281,
			9.372,
			9.404,
			9.481,
			9.529,
			9.593,
			9.653,
			9.714,
			9.777,
			9.808,
			9.84,
			9.902,
			9.964,
			10.029,
			10.088,
			10.134,
			10.15,
			10.212,
			10.242,
			10.274,
			10.398,
			10.461,
			10.522,
			10.582,
			10.676,
			10.708,
			10.769,
			10.832,
			10.894,
			10.954,
			11.016,
			11.078,
			11.11,
			11.141,
			11.22,
			11.264,
			11.328,
			11.388,
			11.45,
			11.512,
			11.575,
			11.638,
			11.698,
			11.761,
			11.823,
			11.884,
			11.946,
			12.01,
			12.088,
			12.133,
			12.196,
			12.258,
			12.32,
			12.382,
			12.443,
			12.507,
			12.521,
			12.567,
			12.63,
			12.69,
			12.74,
			12.754,
			12.817,
			12.845,
			12.879,
			12.955,
			13.003,
			13.065,
			13.126,
			13.188,
			13.281,
			13.311,
			13.39,
			13.435,
			13.499,
			13.56,
			13.621,
			13.716,
			13.745,
			13.823,
			13.871,
			13.933,
			14.056,
			14.148,
			14.18,
			14.243,
			14.304,
			14.365,
			14.428,
			14.487,
			14.552,
			14.613,
			14.69,
			14.74,
			14.801,
			14.863,
			14.925,
			14.986,
			15.047,
			15.111,
			15.173,
			15.232,
			15.295,
			15.343,
			15.358,
			15.422,
			15.451,
			15.482,
			15.56,
			15.607,
			15.668,
			15.791,
			15.887,
			15.918,
			16,
			16.041,
			16.103,
			16.164,
			16.209,
			16.227,
			16.287,
			16.32,
			16.35,
			16.428,
			16.474,
			16.599,
			16.661,
			16.721,
			16.782,
			16.847,
			16.909,
			16.975,
			17.077,
			17.095,
			17.156,
			17.217,
			17.296,
			17.343,
			17.405,
			17.466,
			17.528,
			17.621,
			17.65,
			17.714,
			17.73,
			17.777,
			17.842,
			17.946,
			18.025,
			18.056,
			18.089,
			18.165,
			18.212,
			18.273,
			18.334,
			18.398,
			18.49,
			18.521,
			18.584,
			18.644,
			18.707,
			18.816,
			18.831,
			18.893,
			18.954,
			19.033,
			19.078,
			19.139,
			19.203,
			19.263,
			19.359,
			19.387,
			19.466,
			19.513,
			19.576,
			19.638,
			19.7,
			19.792,
			19.822,
			19.901,
			19.947,
			20.01,
			20.119,
			20.195,
			20.255,
			20.319,
			20.38,
			20.444,
			20.503,
			20.566,
			20.629,
			20.691,
			20.767,
			20.814,
			20.878,
			20.94,
			20.983,
			21.002,
			21.064,
			21.126,
			21.188,
			21.248,
			21.313,
			21.373,
			21.436,
			21.496,
			21.528,
			21.56,
			21.638,
			21.682,
			21.746,
			21.808,
			21.854,
			21.87,
			21.961,
			21.993,
			22.056,
			22.073,
			22.115,
			22.179,
			22.242,
			22.304,
			22.365,
			22.397,
			22.428,
			22.504,
			22.552,
			22.617,
			22.675,
			22.727,
			22.798,
			22.861,
			22.922,
			22.986,
			23.047,
			23.109,
			23.172,
			23.234,
			23.294,
			23.373,
			23.421,
			23.481,
			23.543,
			23.606,
			23.699,
			23.728,
			23.793,
			23.855,
			23.917,
			23.979,
			24.04,
			24.101,
			24.132,
			24.163,
			24.241,
			24.287,
			24.354,
			24.457,
			24.473,
			24.537,
			24.598,
			24.66,
			24.675,
			24.721,
			24.79,
			24.847,
			24.906,
			24.971,
			25,
			25.03,
			25.109,
			25.158,
			25.216,
			25.278,
			25.342,
			25.403,
			25.466,
			25.528,
			25.589,
			25.653,
			25.714,
			25.776,
			25.838,
			25.867,
			25.898,
			25.978,
			26.023,
			26.088,
			26.147,
			26.209,
			26.272,
			26.303,
			26.334,
			26.413,
			26.459,
			26.52,
			26.581,
			26.643,
			26.706,
			26.74,
			26.768,
			26.849,
			26.893,
			26.954,
			27.015,
			27.062,
			27.078,
			27.141,
			27.202,
			27.28,
			27.39,
			27.451,
			27.511,
			27.604,
			27.634,
			27.697,
			27.761,
			27.821,
			27.93,
			27.946,
			28.009,
			28.07,
			28.146,
			28.195,
			28.256,
			28.379,
			28.441,
			28.473,
			28.504,
			28.565,
			28.628,
			28.69,
			28.75,
			28.799,
			28.875,
			28.913,
			28.938,
			29.014,
			29.063,
			29.125,
			29.184,
			29.249,
			29.312,
			29.34,
			29.372,
			29.45,
			29.497,
			29.56,
			29.621,
			29.666,
			29.682,
			29.746,
			29.777,
			29.806,
			29.883,
			29.931,
			29.993,
			30.115,
			30.177,
			30.21,
			30.24,
			30.301,
			30.365,
			30.427,
			30.49,
			30.549,
			30.613,
			30.645,
			30.673,
			30.753,
			30.799,
			30.86,
			30.924,
			30.976,
			31.108,
			31.187,
			31.232,
			31.296,
			31.356,
			31.42,
			31.487,
			31.513,
			31.544,
			31.621,
			31.667,
			31.729,
			31.792,
			31.837,
			31.854,
			31.915,
			31.977,
			32.039,
			32.055,
			32.101,
			32.163,
			32.224,
			32.286,
			32.379,
			32.411,
			32.489,
			32.536,
			32.597,
			32.705,
			32.721,
			32.782,
			32.815,
			32.844,
			32.921,
			32.969,
			33.037,
			33.093,
			33.157,
			33.215,
			33.28,
			33.356,
			33.404,
			33.464,
			33.528,
			33.572,
			33.652,
			33.714,
			33.791,
			33.838,
			33.898,
			33.962,
			34.024,
			34.084,
			34.116,
			34.148,
			34.209,
			34.226,
			34.27,
			34.332,
			34.442,
			34.457,
			34.517,
			34.549,
			34.582,
			34.659,
			34.706,
			34.769,
			34.828,
			34.891,
			34.955,
			34.984,
			35.016,
			35.097,
			35.137,
			35.199,
			35.263,
			35.326,
			35.388,
			35.419,
			35.448,
			35.528,
			35.574,
			35.636,
			35.697,
			35.76,
			35.821,
			35.851,
			35.882,
			35.945,
			36.007,
			36.071,
			36.132,
			36.193,
			36.255,
			36.318,
			36.394,
			36.442,
			36.506,
			36.566,
			36.627,
			36.69,
			36.721,
			36.752,
			36.828,
			36.876,
			36.938,
			37,
			37.047,
			37.124,
			37.161,
			37.187,
			37.262,
			37.308,
			37.372,
			37.434,
			37.495,
			37.558,
			37.588,
			37.621,
			37.696,
			37.745,
			37.809,
			37.867,
			37.913,
			37.93,
			37.993,
			38.023,
			38.054,
			38.132,
			38.241,
			38.3,
			38.363,
			38.427,
			38.455,
			38.488,
			38.564,
			38.612,
			38.674,
			38.782,
			38.798,
			38.892,
			38.923,
			39,
			39.109,
			39.171,
			39.233,
			39.295,
			39.357,
			39.433,
			39.48,
			39.543,
			39.603,
			39.665,
			39.729,
			39.758,
			39.791,
			39.868,
			39.913,
			39.977,
			40.038,
			40.098,
			40.161,
			40.194,
			40.224,
			40.288,
			40.301,
			40.349,
			40.411,
			40.471,
			40.519,
			40.596,
			40.628,
			40.66,
			40.737,
			40.783,
			40.846,
			40.907,
			40.969,
			41.03,
			41.062,
			41.093,
			41.154,
			41.173,
			41.217,
			41.284,
			41.34,
			41.387,
			41.401,
			41.495,
			41.527,
			41.604,
			41.651,
			41.712,
			41.776,
			41.837,
			41.899,
			41.93,
			41.96,
			42.021,
			42.084,
			42.146,
			42.21,
			42.271,
			42.333,
			42.393,
			42.471,
			42.52,
			42.644,
			42.704,
			42.766,
			42.797,
			42.829,
			42.89,
			42.906,
			42.955,
			43.016,
			43.078,
			43.123,
			43.202,
			43.231,
			43.263,
			43.348,
			43.387,
			43.449,
			43.512,
			43.574,
			43.633,
			43.667,
			43.698,
			43.76,
			43.774,
			43.821,
			43.883,
			43.944,
			44.008,
			44.067,
			44.099,
			44.131,
			44.209,
			44.256,
			44.318,
			44.379,
			44.442,
			44.534,
			44.564,
			44.628,
			44.69,
			44.752,
			44.814,
			44.876,
			44.938,
			44.967,
			44.998,
			45.077,
			45.123,
			45.186,
			45.294,
			45.372,
			45.409,
			45.434,
			45.495,
			45.558,
			45.62,
			45.68,
			45.742,
			45.806,
			45.838,
			45.866,
			45.943,
			45.99,
			46.054,
			46.114,
			46.162,
			46.178,
			46.238,
			46.271,
			46.301,
			46.38,
			46.426,
			46.487,
			46.549,
			46.611,
			46.673,
			46.704,
			46.737,
			46.796,
			46.86,
			46.922,
			46.983,
			47.03,
			47.045,
			47.106,
			47.137,
			47.17,
			47.247,
			47.357,
			47.417,
			47.481,
			47.542,
			47.572,
			47.603,
			47.667,
			47.68,
			47.728,
			47.79,
			47.853,
			47.898,
			47.976,
			48.008,
			48.043,
			48.115,
			48.161,
			48.225,
			48.284,
			48.348,
			48.41,
			48.473,
			48.534,
			48.548,
			48.595,
			48.658,
			48.72,
			48.782,
			48.843,
			48.905,
			48.984,
			49.031,
			49.093,
			49.156,
			49.215,
			49.278,
			49.31,
			49.339,
			49.4,
			49.463,
			49.532,
			49.588,
			49.635,
			49.651,
			49.744,
			49.773,
			49.853,
			49.898,
			49.96,
			50.022,
			50.084,
			50.146,
			50.209,
			50.271,
			50.333,
			50.395,
			50.457,
			50.503,
			50.519,
			50.58,
			50.611,
			50.642,
			50.722,
			50.765,
			50.829,
			50.892,
			50.953,
			51.045,
			51.077,
			51.139,
			51.154,
			51.201,
			51.262,
			51.324,
			51.388,
			51.447,
			51.478,
			51.51,
			51.593,
			51.636,
			51.698,
			51.759,
			51.823,
			51.882,
			51.945,
			52.006,
			52.07,
			52.131,
			52.192,
			52.255,
			52.317,
			52.347,
			52.379,
			52.456,
			52.504,
			52.565,
			52.626,
			52.688,
			52.751,
			52.783,
			52.811,
			52.874,
			52.938,
			52.998,
			53.061,
			53.108,
			53.121,
			53.183,
			53.216,
			53.245,
			53.325,
			53.371,
			53.433,
			53.495,
			53.556,
			53.617,
			53.681,
			53.773,
			53.804,
			53.867,
			53.881,
			53.93,
			53.992,
			54.053,
			54.098,
			54.208,
			54.24,
			54.316,
			54.363,
			54.424,
			54.487,
			54.548,
			54.641,
			54.673,
			54.751,
			54.797,
			54.862,
			54.923,
			54.967,
			54.983,
			55.045,
			55.078,
			55.106,
			55.182,
			55.23,
			55.292,
			55.354,
			55.416,
			55.478,
			55.51,
			55.541,
			55.619,
			55.664,
			55.731,
			55.789,
			55.837,
			55.852,
			55.945,
			55.976,
			56.051,
			56.1,
			56.161,
			56.223,
			56.284,
			56.378,
			56.409,
			56.472,
			56.532,
			56.597,
			56.658,
			56.705,
			56.719,
			56.782,
			56.811,
			56.842,
			56.921,
			56.966,
			57.029,
			57.092,
			57.138,
			57.154,
			57.245,
			57.278,
			57.357,
			57.402,
			57.465,
			57.573,
			57.68,
			57.71,
			57.793,
			57.837,
			57.898,
			57.959,
			58.021,
			58.114,
			58.145,
			58.206,
			58.27,
			58.332,
			58.395,
			58.455,
			58.519,
			58.548,
			58.58,
			58.656,
			58.705,
			58.767,
			58.828,
			58.891,
			58.951,
			59.012,
			59.077,
			59.137,
			59.199,
			59.262,
			59.324,
			59.384,
			59.416,
			59.448,
			59.525,
			59.573,
			59.632,
			59.741,
			59.757,
			59.821,
			59.855,
			59.88,
			60.006,
			60.067,
			60.13,
			60.191,
			60.255,
			60.284,
			60.315,
			60.394,
			60.44,
			60.5,
			60.564,
			60.625,
			60.688,
			60.75,
			60.812,
			60.827,
			60.873,
			60.937,
			60.997,
			61.06,
			61.121,
			61.183,
			61.262,
			61.309,
			61.372,
			61.432,
			61.477,
			61.495,
			61.618,
			61.679,
			61.695,
			61.741,
			61.806,
			61.867,
			61.929,
			62.021,
			62.051,
			62.115,
			62.131,
			62.176,
			62.24,
			62.298,
			62.348,
			62.425,
			62.455,
			62.488,
			62.563,
			62.609,
			62.673,
			62.733,
			62.795,
			62.89,
			62.92,
			62.998,
			63.045,
			63.105,
			63.169,
			63.215,
			63.23,
			63.292,
			63.323,
			63.354,
			63.415,
			63.43,
			63.478,
			63.539,
			63.6,
			63.664,
			63.726,
			63.756,
			63.787,
			63.85,
			63.912,
			63.98,
			64.038,
			64.082,
			64.097,
			64.162,
			64.193,
			64.221,
			64.3,
			64.345,
			64.41,
			64.471,
			64.533,
			64.594,
			64.656,
			64.719,
			64.781,
			64.842,
			64.951,
			65.028,
			65.092,
			65.166,
			65.214,
			65.278,
			65.34,
			65.401,
			65.463,
			65.494,
			65.523,
			65.587,
			65.603,
			65.649,
			65.711,
			65.82,
			65.897,
			65.928,
			65.959,
			66.043,
			66.082,
			66.144,
			66.206,
			66.267,
			66.362,
			66.394,
			66.455,
			66.517,
			66.579,
			66.64,
			66.688,
			66.704,
			66.764,
			66.796,
			66.828,
			66.905,
			66.951,
			67.014,
			67.137,
			67.2,
			67.229,
			67.26,
			67.327,
			67.391,
			67.448,
			67.509,
			67.554,
			67.571,
			67.633,
			67.663,
			67.696,
			67.772,
			67.82,
			67.882,
			67.944,
			68.006,
			68.067,
			68.129,
			68.206,
			68.255,
			68.316,
			68.421,
			68.532,
			68.564,
			68.64,
			68.687,
			68.75,
			68.812,
			68.873,
			68.967,
			68.997,
			69.059,
			69.076,
			69.121,
			69.182,
			69.246,
			69.308,
			69.4,
			69.431,
			69.509,
			69.555,
			69.617,
			69.68,
			69.726,
			69.805,
			69.834,
			69.866,
			69.927,
			69.943,
			69.987,
			70.05,
			70.114,
			70.176,
			70.239,
			70.299,
			70.377,
			70.424,
			70.484,
			70.595,
			70.672,
			70.732,
			70.795,
			70.858,
			70.922,
			70.98,
			71.045,
			71.105,
			71.137,
			71.168,
			71.245,
			71.293,
			71.36,
			71.415,
			71.478,
			71.539,
			71.57,
			71.601,
			71.663,
			71.68,
			71.726,
			71.788,
			71.849,
			71.913,
			71.973,
			72.006,
			72.034,
			72.114,
			72.16,
			72.228,
			72.282,
			72.331,
			72.345,
			72.409,
			72.44,
			72.472,
			72.532,
			72.595,
			72.656,
			72.718,
			72.78,
			72.843,
			72.904,
			72.981,
			73.027,
			73.09,
			73.198,
			73.308,
			73.338,
			73.417,
			73.461,
			73.524,
			73.588,
			73.648,
			73.711,
			73.742,
			73.773,
			73.82
		]
	],
	'262_264_7982': [
		[
			96.416
		],
		[
			0
		],
		[
			0,
			1.573,
			4.306,
			5.675,
			9.792,
			12.546,
			15.307,
			19.463,
			22.242,
			26.425,
			27.823,
			33.43,
			36.243,
			40.476,
			43.305,
			47.563,
			50.409,
			54.691,
			57.553,
			58.986,
			61.856,
			64.732,
			69.058,
			70.503,
			71.949,
			76.297,
			77.75,
			79.204,
			83.577,
			86.5,
			89.431,
			93.842,
			96.792,
			101.231,
			102.714,
			104.2,
			108.672,
			111.665,
			116.173,
			122.212,
			123.726,
			126.756,
			131.318,
			135.898,
			138.957,
			142.029,
			149.758,
			154.426,
			155.986,
			157.555,
			162.269,
			165.416,
			168.563,
			173.284,
			176.432,
			182.732,
			184.31,
			190.632,
			192.215,
			196.977,
			204.956,
			208.151,
			209.751,
			212.958,
			216.169,
			220.982,
			224.195,
			229.022,
			232.244,
			237.082,
			240.311,
			245.161,
			248.424,
			253.277,
			256.486,
			259.722,
			261.341,
			266.198,
			269.438,
			274.307,
			275.931,
			280.809,
			284.064,
			288.951,
			295.475,
			297.108,
			302.007,
			305.278,
			308.553,
			313.473,
			316.76,
			321.697,
			324.993,
			329.944,
			333.246,
			338.204,
			341.513,
			346.484,
			351.461,
			354.779,
			359.738,
			363.016,
			366.26,
			369.467,
			372.634,
			374.202,
			381.892,
			384.906,
			386.401,
			389.362,
			392.285,
			396.593,
			399.418,
			403.59,
			404.962,
			406.324,
			410.359,
			413.004,
			416.914,
			422.018,
			423.274,
			425.759,
			426.979,
			429.388,
			431.762,
			432.934,
			435.255,
			437.537,
			440.889,
			443.078,
			446.293,
			448.391,
			450.452,
			451.469,
			453.478,
			456.422,
			460.228,
			463.005,
			464.805,
			468.308,
			469.162,
			471.674,
			473.302,
			475.678,
			477.224,
			479.469,
			480.931,
			481.654,
			483.075,
			484.462,
			486.517,
			487.197,
			487.878,
			490.613,
			491.299,
			493.392,
			494.866,
			498.627,
			500.257,
			502.716,
			503.57,
			505.343,
			507.122,
			508.013,
			509.882,
			511.77,
			513.668,
			515.643,
			516.631,
			518.608,
			520.536,
			521.499,
			524.322,
			526.04,
			527.755,
			530.152,
			531.661,
			533.92,
			534.649,
			535.377,
			537.559,
			539.005,
			541.218,
			542.708,
			544.224,
			544.992,
			546.534,
			547.307,
			548.858,
			551.313,
			552.963,
			554.66,
			557.455,
			559.317,
			561.344,
			564.358,
			566.384,
			569.409,
			571.199,
			573.861,
			575.52,
			577.86,
			578.637,
			579.413,
			581.614,
			583.053,
			585.222,
			586.672,
			588.841,
			591.031,
			592.502,
			593.981,
			596.228,
			597.743,
			600.036,
			600.816,
			601.6,
			603.968,
			604.764,
			605.57,
			608.015,
			609.661,
			611.326,
			613.849,
			615.548,
			618.119,
			619.848,
			623.35,
			624.233,
			626.903,
			628.685,
			630.494,
			631.403,
			633.235,
			635.079,
			636.006,
			638.81,
			640.695,
			642.593,
			645.462,
			647.388,
			651.273,
			652.251,
			655.201,
			657.183,
			660.17,
			662.175,
			665.198,
			667.227,
			670.29,
			672.345,
			673.377,
			674.413,
			678.582,
			679.63,
			682.773,
			684.872,
			688.039,
			692.294,
			693.364,
			695.509,
			696.585,
			698.744,
			700.912,
			704.182,
			706.374,
			709.679,
			711.894,
			715.235,
			717.474,
			720.849,
			723.11,
			725.38,
			726.518,
			729.945,
			732.241,
			735.701,
			738.019,
			740.346,
			742.681,
			746.201,
			750.925,
			752.112,
			755.686,
			758.08,
			761.689,
			764.107,
			766.534,
			770.195,
			771.42,
			772.648,
			778.821,
			782.554,
			785.055,
			788.823,
			791.346,
			795.149,
			797.695,
			798.972,
			801.532,
			804.101,
			807.971,
			810.562,
			813.162,
			815.77,
			817.074,
			820.991,
			823.612,
			826.24,
			830.198,
			832.845,
			836.819,
			838.142,
			839.463,
			843.398,
			845.991,
			849.82,
			852.328,
			856.019,
			858.434,
			859.628,
			861.99,
			865.469,
			867.741,
			869.976,
			873.258,
			875.398,
			878.558,
			879.587,
			880.607,
			883.598,
			885.576,
			887.509,
			890.362,
			892.245,
			895.038,
			896.893,
			900.553,
			901.463,
			904.146,
			905.898,
			908.571,
			910.362,
			912.155,
			913.051,
			914.939,
			915.883,
			917.749,
			919.707,
			924.774,
			928.01,
			929.127,
			930.266,
			933.663,
			935.848,
			939.082,
			941.062,
			944.009,
			945.819,
			946.734,
			948.574,
			951.252,
			952.146,
			954.828,
			958.396,
			959.302,
			961.152,
			963.012,
			963.945,
			966.907,
			968.907,
			970.97,
			974.103,
			976.243,
			979.495,
			980.576,
			981.656,
			984.865,
			986.94,
			989.991,
			991.95,
			993.868,
			994.814,
			996.646,
			999.359,
			1002.046,
			1003.824,
			1005.592,
			1008.209,
			1009.932,
			1012.472,
			1014.134,
			1016.581,
			1018.18,
			1019.747,
			1023.566,
			1025.783,
			1026.519,
			1027.252,
			1029.424,
			1032.991,
			1034.408,
			1036.534,
			1037.949,
			1039.368,
			1040.079,
			1041.506,
			1042.217,
			1043.64,
			1045.067,
			1047.921,
			1048.631,
			1050.766,
			1051.479,
			1052.191,
			1054.325,
			1055.748,
			1057.883,
			1061.433,
			1062.854,
			1063.564,
			1064.275,
			1067.108,
			1067.817,
			1069.938,
			1071.347,
			1073.476,
			1074.907,
			1077.05,
			1079.222,
			1080.677,
			1084.341,
			1085.821,
			1088.052,
			1088.806,
			1089.561,
			1091.842,
			1093.378,
			1095.749,
			1097.346,
			1099.789,
			1102.273,
			1103.93,
			1106.459,
			1108.156,
			1111.497,
			1113.991,
			1117.179,
			1117.975,
			1119.572,
			1120.35,
			1121.911,
			1123.478,
			1125.843,
			1127.428,
			1129.823,
			1130.631,
			1131.441,
			1133.894,
			1135.554,
			1138.085,
			1139.789,
			1142.396,
			1144.153,
			1146.828,
			1148.634,
			1149.543,
			1151.371,
			1153.212,
			1156.001,
			1156.934,
			1157.87,
			1161.649,
			1162.599,
			1165.474,
			1167.407,
			1169.354,
			1174.274,
			1178.296,
			1179.311,
			1182.38,
			1184.444,
			1187.549,
			1189.626,
			1192.767,
			1194.864,
			1196.981,
			1198.028,
			1201.132,
			1203.156,
			1205.165,
			1208.135,
			1210.064,
			1214.74,
			1217.455,
			1219.238,
			1221.881,
			1223.627,
			1225.364,
			1227.965,
			1228.833,
			1229.701,
			1232.308,
			1234.051,
			1236.661,
			1238.402,
			1240.142,
			1241.009,
			1242.743,
			1243.611,
			1245.349,
			1247.952,
			1249.687,
			1251.43,
			1254.03,
			1255.77,
			1258.372,
			1259.232,
			1260.092,
			1262.72,
			1264.443,
			1267.046,
			1270.462,
			1271.364,
			1273.151,
			1274.036,
			1274.917,
			1278.48,
			1280.25,
			1282.042,
			1283.832,
			1286.472,
			1288.179,
			1289.879,
			1290.728,
			1292.319,
			1293.113,
			1294.696,
			1296.26,
			1298.51,
			1300.007,
			1302.221,
			1302.951,
			1303.681,
			1305.876,
			1309.537,
			1311.008,
			1313.226,
			1314.707,
			1316.93,
			1319.153,
			1320.638,
			1322.127,
			1324.373,
			1325.127,
			1325.883,
			1327.408,
			1329.736,
			1332.095,
			1333.705,
			1335.337,
			1339.511,
			1342.068,
			1342.934,
			1343.819,
			1346.457,
			1348.221,
			1350.933,
			1352.752,
			1355.49,
			1357.343,
			1359.208,
			1360.142,
			1362.967,
			1364.863,
			1366.769,
			1369.654,
			1371.591,
			1374.52,
			1375.503,
			1376.489,
			1379.467,
			1381.466,
			1384.488,
			1386.516,
			1388.555,
			1392.667,
			1393.703,
			1397.87,
			1398.918,
			1402.068,
			1406.277,
			1407.336,
			1409.461,
			1412.669,
			1414.821,
			1415.9,
			1418.071,
			1420.251,
			1423.546,
			1425.755,
			1430.235,
			1431.356,
			1434.753,
			1437.031,
			1440.481,
			1442.794,
			1445.12,
			1448.627,
			1452.156,
			1454.526,
			1455.715,
			1456.905,
			1460.485,
			1462.881,
			1466.494,
			1471.311,
			1472.517,
			1476.133,
			1478.551,
			1480.946,
			1484.578,
			1487,
			1490.647,
			1491.863,
			1493.082,
			1496.746,
			1499.194,
			1502.878,
			1505.341,
			1509.048,
			1511.529,
			1515.267,
			1517.771,
			1519.026,
			1521.542,
			1524.067,
			1527.866,
			1529.135,
			1530.406,
			1534.245,
			1536.778,
			1540.623,
			1543.197,
			1547.073,
			1549.666,
			1552.291,
			1557.517,
			1558.826,
			1562.735,
			1565.365,
			1569.342,
			1571.985,
			1574.63,
			1575.952,
			1578.595,
			1579.914,
			1582.544,
			1586.459,
			1589.045,
			1591.603,
			1595.404,
			1597.9,
			1601.669,
			1602.959,
			1604.232,
			1608.071,
			1610.657,
			1613.256,
			1617.182,
			1619.816,
			1623.776,
			1625.095,
			1626.415,
			1630.368,
			1633.005,
			1636.942,
			1639.567,
			1643.504,
			1646.111,
			1648.767,
			1650.087,
			1652.729,
			1654.053,
			1656.702,
			1659.358,
			1663.325,
			1665.985,
			1671.318,
			1672.643,
			1676.606,
			1679.218,
			1683.149,
			1685.76,
			1688.361,
			1692.272,
			1693.545,
			1694.839,
			1698.72,
			1700.014,
			1701.307,
			1705.195,
			1707.817,
			1711.711,
			1714.331,
			1718.276,
			1722.237,
			1724.912,
			1727.572,
			1731.582,
			1734.268,
			1738.319,
			1739.674,
			1741.033,
			1745.102,
			1747.866,
			1751.995,
			1754.756,
			1758.908,
			1764.436,
			1765.826,
			1770.002,
			1772.79,
			1775.582,
			1779.776,
			1781.176,
			1782.577,
			1785.384,
			1788.194,
			1789.601,
			1796.648,
			1799.457,
			1803.633,
			1806.381,
			1810.44,
			1813.105,
			1817.055,
			1819.665,
			1823.551,
			1826.116,
			1829.913,
			1833.633,
			1836.072,
			1839.669,
			1842.031,
			1844.361,
			1847.806,
			1850.059,
			1853.38,
			1855.55,
			1858.745,
			1860.834,
			1863.9,
			1867.854,
			1871.665,
			1872.596,
			1876.266,
			1877.171,
			1879.88,
			1881.683,
			1884.389,
			1887.95,
			1888.834,
			1891.476,
			1893.236,
			1894.989,
			1897.611,
			1899.352,
			1901.954,
			1903.685,
			1906.266,
			1907.124,
			1907.978,
			1910.529,
			1912.221,
			1914.748,
			1916.426,
			1918.104,
			1920.617,
			1921.452,
			1922.288,
			1925.648,
			1926.486,
			1929.019,
			1933.261,
			1934.982,
			1936.7,
			1937.559,
			1939.306,
			1940.179,
			1941.923,
			1943.685,
			1946.366,
			1948.152,
			1950.89,
			1952.714,
			1955.513,
			1957.389,
			1960.229,
			1962.117,
			1964.036,
			1964.974,
			1966.859,
			1967.807,
			1969.684,
			1972.473,
			1974.337,
			1976.172,
			1978.022,
			1980.785,
			1982.63,
			1984.486,
			1985.411,
			1988.209,
			1990.088,
			1991.973,
			1994.823,
			1996.733,
			1999.62,
			2001.55,
			2004.47,
			2006.426,
			2009.382,
			2011.37,
			2014.376,
			2016.406,
			2018.452,
			2019.479,
			2022.591,
			2024.677,
			2026.771,
			2030.99,
			2032.057,
			2035.272,
			2037.43,
			2040.684,
			2042.863,
			2045.05,
			2048.325,
			2050.53,
			2054.963,
			2056.077,
			2059.431,
			2061.678,
			2065.062,
			2067.33,
			2069.604,
			2070.743,
			2073.027,
			2075.312,
			2076.457,
			2079.901,
			2082.205,
			2084.516,
			2088.016,
			2090.347,
			2093.841,
			2095.018,
			2096.198,
			2099.756,
			2102.157,
			2105.787,
			2108.238,
			2110.702,
			2114.431,
			2116.927,
			2121.944,
			2123.202,
			2126.988,
			2129.543,
			2133.351,
			2138.517,
			2139.823,
			2143.769,
			2146.422,
			2149.09,
			2153.114,
			2155.826,
			2159.917,
			2161.289,
			2162.662,
			2166.803,
			2169.569,
			2173.714,
			2176.425,
			2179.137,
			2180.462,
			2183.119,
			2184.449,
			2187.084,
			2191.049,
			2193.7,
			2196.369,
			2199.053,
			2201.756,
			2203.113,
			2205.835,
			2208.573,
			2209.946,
			2214.085,
			2216.858,
			2219.643,
			2223.864,
			2226.65,
			2232.323,
			2233.742,
			2238.01,
			2240.868,
			2245.172,
			2248.051,
			2252.384,
			2255.282,
			2256.734,
			2259.637,
			2263.991,
			2266.877,
			2269.754,
			2272.608,
			2276.854,
			2279.655,
			2283.797,
			2287.877,
			2290.567,
			2293.255,
			2297.284,
			2299.954,
			2304.01,
			2305.365,
			2306.72,
			2312.132,
			2313.478,
			2317.511,
			2322.751,
			2324.048,
			2326.619,
			2327.894,
			2330.42,
			2334.153,
			2336.603,
			2339.025,
			2342.6,
			2344.945,
			2348.395,
			2349.528,
			2350.651,
			2353.971,
			2356.143,
			2359.339,
			2361.424,
			2363.472,
			2366.473,
			2367.455,
			2368.427,
			2372.213,
			2373.137,
			2375.85,
			2377.616,
			2380.196,
			2381.869,
			2384.308,
			2385.899,
			2388.198,
			2389.682,
			2393.234,
			2395.279,
			2395.948,
			2396.613,
			2398.583,
			2399.887,
			2401.819,
			2403.093,
			2405.029,
			2406.341,
			2407.647,
			2409.621,
			2411.683,
			2413.052,
			2414.423,
			2416.543,
			2417.953,
			2419.364,
			2420.08,
			2422.242,
			2423.683,
			2425.125,
			2427.28,
			2428.713,
			2430.907,
			2432.41,
			2434.656,
			2436.208,
			2438.566,
			2440.135,
			2442.48,
			2445.595,
			2446.356,
			2448.636,
			2450.153,
			2451.661,
			2453.915,
			2454.668,
			2455.422,
			2456.884,
			2459.066,
			2460.529,
			2461.226,
			2462.585,
			2463.952,
			2466.014,
			2467.367,
			2469.414,
			2470.793,
			2472.899,
			2474.318,
			2476.462,
			2477.923,
			2479.4,
			2480.144,
			2481.643,
			2483.919,
			2486.233,
			2489.385,
			2491.778,
			2493.391,
			2495.824,
			2496.644,
			2497.482,
			2500.004,
			2501.696,
			2504.37,
			2506.157,
			2508.002,
			2511.735,
			2512.707,
			2516.627,
			2517.647,
			2520.75,
			2526.115,
			2528.242,
			2530.331,
			2531.364,
			2534.252,
			2536.101,
			2537.892,
			2540.353,
			2541.978,
			2544.239,
			2544.973,
			2545.705,
			2547.888,
			2549.301,
			2551.412,
			2552.817,
			2554.227,
			2554.931,
			2556.338,
			2557.041,
			2557.744,
			2559.901,
			2560.621,
			2562.061,
			2563.511,
			2565.724,
			2567.202,
			2568.685,
			2570.929,
			2572.425,
			2573.175,
			2574.687,
			2576.206,
			2578.493,
			2580.036,
			2583.144,
			2583.932,
			2586.326,
			2587.938,
			2590.379,
			2593.677,
			2594.509,
			2597.021,
			2598.711,
			2601.268,
			2602.986,
			2604.716,
			2607.335,
			2609.095,
			2612.651,
			2613.548,
			2615.35,
			2616.256,
			2618.078,
			2619.912,
			2622.687,
			2624.552,
			2628.324,
			2629.276,
			2632.147,
			2634.076,
			2636.99,
			2638.946,
			2641.901,
			2643.882,
			2646.875,
			2648.878,
			2649.883,
			2651.901,
			2653.927,
			2659.027,
			2662.116,
			2663.151,
			2664.189,
			2667.318,
			2669.413,
			2672.557,
			2676.779,
			2679.972,
			2682.115,
			2685.349,
			2687.517,
			2690.786,
			2692.977,
			2696.282,
			2698.499,
			2700.726,
			2701.844,
			2705.211,
			2707.468,
			2709.735,
			2713.148,
			2715.434,
			2720.03,
			2721.185,
			2724.664,
			2726.995,
			2730.513,
			2732.871,
			2736.429,
			2738.821,
			2742.446,
			2746.05,
			2748.479,
			2750.92,
			2753.367,
			2757.052,
			2759.518,
			2761.991,
			2765.709,
			2769.438,
			2771.934,
			2775.695,
			2778.214,
			2782.009,
			2784.551,
			2788.374,
			2790.933,
			2794.786,
			2797.368,
			2799.959,
			2801.258,
			2803.864,
			2805.191,
			2807.809,
			2811.739,
			2816.954,
			2819.513,
			2823.323,
			2825.817,
			2829.486,
			2831.881,
			2833.064,
			2835.4,
			2837.694,
			2841.063,
			2843.258,
			2847.529,
			2848.572,
			2851.64,
			2853.634,
			2856.549,
			2858.442,
			2861.21,
			2863.008,
			2863.894,
			2865.65,
			2868.186,
			2869.823,
			2871.419,
			2873.734,
			2875.221,
			2877.384,
			2878.09,
			2878.787,
			2880.821,
			2882.151,
			2883.466,
			2885.406,
			2887.328,
			2888.611,
			2889.254,
			2889.9,
			2891.845,
			2892.496,
			2893.149,
			2895.129,
			2896.466,
			2898.488,
			2899.852,
			2901.232,
			2901.924,
			2903.312,
			2904.708,
			2905.424,
			2907.574,
			2909.011,
			2910.471,
			2912.702,
			2914.19,
			2916.454,
			2917.218,
			2917.981,
			2920.272,
			2921.805,
			2924.106,
			2925.65,
			2927.22,
			2928.006,
			2929.583,
			2931.179,
			2934.412,
			2935.227,
			2937.628,
			2939.235,
			2941.623,
			2943.173,
			2944.739,
			2945.527,
			2947.082,
			2947.859,
			2949.425,
			2951.008,
			2953.406,
			2955.025,
			2957.487,
			2958.312,
			2959.137,
			2961.623,
			2963.307,
			2965.859,
			2967.584,
			2969.328,
			2970.205,
			2971.973,
			2974.664,
			2976.478,
			2978.311,
			2981.094,
			2983.922,
			2985.828,
			2989.697,
			2990.676,
			2993.637,
			2995.628,
			2997.614,
			3000.658,
			3002.692,
			3005.758,
			3007.825,
			3010.94,
			3013.038,
			3016.183,
			3018.275,
			3021.385,
			3023.414,
			3026.424,
			3028.405,
			3029.374,
			3031.281,
			3033.136,
			3036.782,
			3037.667,
			3040.271,
			3041.959,
			3044.433,
			3046.053,
			3048.448,
			3050.024,
			3051.6,
			3053.962,
			3054.747,
			3055.531,
			3058.73,
			3059.534,
			3061.952,
			3065.191,
			3065.995,
			3067.617,
			3069.235,
			3070.038,
			3072.45,
			3074.07,
			3075.665,
			3078.049,
			3079.629,
			3081.956,
			3082.754,
			3083.55,
			3085.924,
			3087.505,
			3089.888,
			3091.465,
			3093.85,
			3095.449,
			3097.052,
			3100.086,
			3100.838,
			3103.086,
			3104.531,
			3105.983,
			3106.711,
			3110.289,
			3111.717,
			3113.864,
			3115.285,
			3117.436,
			3118.882,
			3121.065,
			3122.537,
			3124.78,
			3126.297,
			3128.597,
			3131.725,
			3132.515,
			3134.106,
			3134.905,
			3136.51,
			3138.939,
			3140.573,
			3142.207,
			3143.024,
			3143.843,
			3146.33,
			3148.003,
			3150.546,
			3152.267,
			3154,
			3154.874,
			3157.543,
			3159.339,
			3161.157,
			3165.748,
			3169.476,
			3170.417,
			3173.237,
			3175.132,
			3177.992,
			3179.902,
			3182.747,
			3184.614,
			3185.535,
			3187.348,
			3190,
			3191.721,
			3193.404,
			3195.05,
			3197.45,
			3199.003,
			3201.264,
			3203.44,
			3204.846,
			3206.889,
			3208.21,
			3209.5,
			3211.372,
			3211.979,
			3212.578,
			3214.345,
			3215.473,
			3217.104,
			3218.167,
			3219.221,
			3219.741,
			3220.771,
			3221.282,
			3222.298,
			3223.818,
			3224.829,
			3225.839,
			3227.361,
			3228.382,
			3229.896,
			3230.4,
			3230.904,
			3232.417,
			3233.428,
			3234.931,
			3236.915,
			3237.409,
			3238.398,
			3238.9,
			3239.415,
			3241.455,
			3241.961,
			3243.473,
			3244.56,
			3246.235,
			3247.341,
			3248.44,
			3248.987,
			3250.19,
			3250.79,
			3251.981,
			3253.165,
			3254.951,
			3256.157,
			3257.954,
			3259.147,
			3261.05,
			3262.332,
			3264.251,
			3265.61,
			3266.998,
			3269.077,
			3271.167,
			3272.558,
			3273.252,
			3273.948,
			3275.997,
			3277.33,
			3279.336,
			3281.797,
			3282.389,
			3284.173,
			3285.374,
			3286.454,
			3288.078,
			3289.171,
			3291.338,
			3291.863,
			3293.451,
			3294.522,
			3296.161,
			3297.28,
			3298.981,
			3300.133,
			3300.716,
			3301.928,
			3303.779,
			3305.033,
			3306.311,
			3308.281,
			3308.945,
			3309.613,
			3312.338,
			3313.032,
			3314.432,
			3316.563,
			3318.008,
			3320.205,
			3321.69,
			3323.944,
			3325.466,
			3327.763,
			3329.32,
			3331.683,
			3333.275,
			3334.881,
			3335.689,
			3339.783,
			3341.444,
			3342.28,
			3343.955,
			3345.629,
			3348.155,
			3349.856,
			3353.297,
			3354.165,
			3356.788,
			3358.554,
			3360.333,
			3363.025,
			3364.839,
			3369.433,
			3372.232,
			3373.171,
			3374.115,
			3376.969,
			3378.888,
			3381.802,
			3383.761,
			3386.732,
			3388.729,
			3389.732,
			3391.751,
			3393.775,
			3396.805,
			3398.808,
			3401.764,
			3402.732,
			3403.691,
			3406.51,
			3408.341,
			3411.017,
			3412.752,
			3415.287,
			3417.739,
			3419.328,
			3420.881,
			3422.396,
			3424.605,
			3426.047,
			3428.121,
			3429.473,
			3430.809,
			3431.471,
			3433.434,
			3434.73,
			3436.021,
			3437.944,
			3439.216,
			3441.112,
			3442.376,
			3444.263,
			3445.514,
			3447.401,
			3448.664,
			3450.552,
			3451.81,
			3453.733,
			3455.651,
			3456.931,
			3458.245,
			3460.874,
			3462.881,
			3464.248,
			3464.931,
			3466.296,
			3466.979,
			3468.381,
			3469.794,
			3471.909,
			3473.324,
			3476.15,
			3476.855,
			3479.003,
			3480.448,
			3482.614,
			3484.123,
			3485.653,
			3486.418,
			3487.95,
			3488.734,
			3491.902,
			3492.695,
			3494.259,
			3495.801,
			3498.124,
			3499.678,
			3502.015,
			3502.797,
			3503.581,
			3506.039,
			3507.695,
			3510.197,
			3511.875,
			3513.561,
			3516.806,
			3517.609,
			3520.024,
			3521.569,
			3523.904,
			3525.468,
			3527.778,
			3529.33,
			3530.887,
			3531.659,
			3533.209,
			3533.987,
			3535.55,
			3537.111,
			3539.463,
			3541.041,
			3544.205,
			3544.999,
			3547.402,
			3549.015,
			3551.437,
			3553.073,
			3555.53,
			3558.011,
			3559.678,
			3561.349,
			3562.188,
			3563.03,
			3565.553,
			3568.937,
			3569.794,
			3571.518,
			3574.123,
			3576.755,
			3580.303,
			3582.993,
			3584.803,
			3588.458,
			3589.382,
			3592.169,
			3594.043,
			3596.88,
			3600.704,
			3601.667,
			3603.602,
			3606.521,
			3609.454,
			3611.412,
			3613.37,
			3616.297,
			3618.241,
			3621.145,
			3623.075,
			3625.99,
			3627.929,
			3629.869,
			3632.784,
			3634.715,
			3637.651,
			3638.63,
			3639.611,
			3642.568,
			3644.554,
			3647.558,
			3651.606,
			3652.625,
			3655.702,
			3657.766,
			3660.884,
			3662.976,
			3665.074,
			3668.221,
			3670.329,
			3673.51,
			3674.576,
			3675.645,
			3678.866,
			3681.026,
			3684.287,
			3686.474,
			3688.671,
			3691.985,
			3693.094,
			3694.206,
			3698.68,
			3699.805,
			3703.194,
			3705.467,
			3708.893,
			3711.189,
			3714.652,
			3718.137,
			3720.471,
			3722.814,
			3728.712,
			3732.277,
			3733.47,
			3734.665,
			3738.261,
			3740.671,
			3746.739,
			3750.407,
			3752.864,
			3754.095,
			3756.566,
			3759.046,
			3761.534,
			3765.283,
			3767.793,
			3771.574,
			3776.643,
			3777.915,
			3781.747,
			3784.312,
			3786.888,
			3793.367,
			3798.587,
			3799.893,
			3803.822,
			3806.451,
			3810.409,
			3813.056,
			3817.039,
			3821.037,
			3823.709,
			3827.729,
			3829.074,
			3833.118,
			3838.532,
			3840.563
		],
		[
			0,
			0.039,
			0.07,
			0.101,
			0.176,
			0.224,
			0.287,
			0.345,
			0.394,
			0.471,
			0.502,
			0.594,
			0.659,
			0.721,
			0.782,
			0.843,
			0.904,
			0.968,
			1.03,
			1.043,
			1.093,
			1.155,
			1.218,
			1.261,
			1.277,
			1.341,
			1.374,
			1.403,
			1.48,
			1.527,
			1.587,
			1.649,
			1.713,
			1.775,
			1.804,
			1.835,
			1.899,
			1.961,
			2.022,
			2.131,
			2.147,
			2.208,
			2.269,
			2.348,
			2.395,
			2.455,
			2.578,
			2.642,
			2.673,
			2.703,
			2.782,
			2.826,
			2.89,
			2.951,
			3.014,
			3.108,
			3.139,
			3.216,
			3.262,
			3.323,
			3.45,
			3.51,
			3.54,
			3.573,
			3.635,
			3.695,
			3.758,
			3.821,
			3.884,
			3.945,
			4.007,
			4.084,
			4.13,
			4.191,
			4.253,
			4.302,
			4.318,
			4.411,
			4.442,
			4.518,
			4.563,
			4.627,
			4.689,
			4.749,
			4.842,
			4.875,
			4.951,
			4.999,
			5.062,
			5.123,
			5.169,
			5.245,
			5.308,
			5.37,
			5.433,
			5.502,
			5.557,
			5.619,
			5.711,
			5.744,
			5.82,
			5.866,
			5.927,
			5.99,
			6.036,
			6.053,
			6.178,
			6.24,
			6.255,
			6.299,
			6.361,
			6.425,
			6.485,
			6.549,
			6.581,
			6.61,
			6.687,
			6.736,
			6.798,
			6.906,
			6.922,
			6.983,
			7.014,
			7.045,
			7.108,
			7.121,
			7.169,
			7.233,
			7.294,
			7.354,
			7.416,
			7.479,
			7.54,
			7.562,
			7.603,
			7.667,
			7.773,
			7.851,
			7.912,
			7.991,
			8.038,
			8.099,
			8.161,
			8.222,
			8.286,
			8.346,
			8.408,
			8.425,
			8.472,
			8.534,
			8.596,
			8.64,
			8.656,
			8.75,
			8.782,
			8.859,
			8.906,
			9.03,
			9.09,
			9.153,
			9.184,
			9.216,
			9.278,
			9.293,
			9.344,
			9.405,
			9.465,
			9.51,
			9.524,
			9.587,
			9.624,
			9.65,
			9.728,
			9.774,
			9.835,
			9.899,
			9.96,
			10.02,
			10.052,
			10.085,
			10.147,
			10.209,
			10.271,
			10.331,
			10.377,
			10.394,
			10.456,
			10.486,
			10.518,
			10.597,
			10.641,
			10.704,
			10.767,
			10.829,
			10.888,
			10.951,
			11.014,
			11.075,
			11.136,
			11.2,
			11.246,
			11.324,
			11.355,
			11.385,
			11.465,
			11.51,
			11.572,
			11.634,
			11.695,
			11.789,
			11.82,
			11.883,
			11.943,
			12.006,
			12.067,
			12.115,
			12.13,
			12.192,
			12.224,
			12.253,
			12.332,
			12.378,
			12.44,
			12.502,
			12.55,
			12.625,
			12.687,
			12.766,
			12.81,
			12.874,
			12.935,
			12.983,
			12.999,
			13.062,
			13.091,
			13.121,
			13.199,
			13.244,
			13.307,
			13.369,
			13.432,
			13.525,
			13.555,
			13.618,
			13.681,
			13.751,
			13.804,
			13.866,
			13.929,
			13.991,
			14.052,
			14.083,
			14.114,
			14.191,
			14.238,
			14.301,
			14.362,
			14.424,
			14.518,
			14.548,
			14.611,
			14.624,
			14.672,
			14.735,
			14.794,
			14.859,
			14.921,
			14.983,
			15.061,
			15.105,
			15.169,
			15.231,
			15.276,
			15.293,
			15.385,
			15.416,
			15.495,
			15.541,
			15.602,
			15.662,
			15.727,
			15.824,
			15.852,
			15.927,
			15.974,
			16.041,
			16.099,
			16.161,
			16.222,
			16.254,
			16.284,
			16.409,
			16.472,
			16.533,
			16.596,
			16.658,
			16.718,
			16.782,
			16.796,
			16.844,
			16.904,
			16.966,
			17.028,
			17.09,
			17.121,
			17.151,
			17.228,
			17.276,
			17.339,
			17.401,
			17.462,
			17.526,
			17.556,
			17.587,
			17.65,
			17.71,
			17.773,
			17.835,
			17.897,
			17.957,
			17.99,
			18.021,
			18.099,
			18.144,
			18.207,
			18.268,
			18.332,
			18.397,
			18.424,
			18.454,
			18.518,
			18.579,
			18.64,
			18.704,
			18.749,
			18.827,
			18.89,
			18.966,
			19.011,
			19.076,
			19.137,
			19.199,
			19.261,
			19.292,
			19.323,
			19.385,
			19.401,
			19.446,
			19.51,
			19.619,
			19.693,
			19.727,
			19.757,
			19.836,
			19.881,
			19.951,
			20.005,
			20.067,
			20.13,
			20.161,
			20.192,
			20.269,
			20.315,
			20.379,
			20.486,
			20.5,
			20.564,
			20.593,
			20.624,
			20.702,
			20.749,
			20.814,
			20.872,
			20.935,
			20.995,
			21.027,
			21.058,
			21.12,
			21.183,
			21.245,
			21.308,
			21.353,
			21.37,
			21.432,
			21.493,
			21.571,
			21.619,
			21.681,
			21.741,
			21.804,
			21.867,
			21.928,
			21.991,
			22.051,
			22.112,
			22.222,
			22.299,
			22.331,
			22.361,
			22.439,
			22.549,
			22.61,
			22.672,
			22.735,
			22.766,
			22.795,
			22.857,
			22.872,
			22.919,
			22.982,
			23.09,
			23.105,
			23.167,
			23.2,
			23.228,
			23.308,
			23.353,
			23.416,
			23.541,
			23.601,
			23.633,
			23.664,
			23.741,
			23.787,
			23.851,
			23.91,
			23.973,
			24.035,
			24.098,
			24.175,
			24.22,
			24.345,
			24.408,
			24.471,
			24.5,
			24.533,
			24.593,
			24.657,
			24.718,
			24.781,
			24.843,
			24.935,
			24.967,
			25.042,
			25.091,
			25.214,
			25.275,
			25.37,
			25.399,
			25.461,
			25.481,
			25.524,
			25.59,
			25.65,
			25.696,
			25.774,
			25.802,
			25.833,
			25.91,
			25.958,
			26.021,
			26.083,
			26.144,
			26.207,
			26.268,
			26.328,
			26.345,
			26.393,
			26.454,
			26.516,
			26.562,
			26.577,
			26.671,
			26.702,
			26.778,
			26.827,
			26.889,
			26.997,
			27.105,
			27.136,
			27.215,
			27.26,
			27.326,
			27.385,
			27.447,
			27.508,
			27.541,
			27.57,
			27.646,
			27.695,
			27.757,
			27.818,
			27.866,
			28.005,
			28.083,
			28.128,
			28.194,
			28.254,
			28.315,
			28.377,
			28.409,
			28.439,
			28.515,
			28.564,
			28.626,
			28.686,
			28.733,
			28.749,
			28.812,
			28.84,
			28.873,
			28.949,
			28.995,
			29.065,
			29.122,
			29.183,
			29.245,
			29.274,
			29.307,
			29.369,
			29.432,
			29.494,
			29.601,
			29.618,
			29.68,
			29.71,
			29.74,
			29.818,
			29.866,
			29.927,
			29.989,
			30.052,
			30.112,
			30.143,
			30.175,
			30.238,
			30.257,
			30.299,
			30.36,
			30.422,
			30.47,
			30.548,
			30.578,
			30.609,
			30.687,
			30.794,
			30.857,
			30.918,
			30.982,
			31.045,
			31.121,
			31.167,
			31.231,
			31.291,
			31.338,
			31.353,
			31.415,
			31.477,
			31.554,
			31.602,
			31.663,
			31.787,
			31.849,
			31.879,
			31.911,
			31.974,
			32.035,
			32.095,
			32.159,
			32.221,
			32.282,
			32.32,
			32.347,
			32.423,
			32.468,
			32.533,
			32.595,
			32.654,
			32.717,
			32.749,
			32.779,
			32.856,
			32.905,
			32.966,
			33.027,
			33.089,
			33.182,
			33.214,
			33.292,
			33.336,
			33.399,
			33.507,
			33.523,
			33.586,
			33.65,
			33.709,
			33.725,
			33.771,
			33.835,
			33.896,
			33.957,
			34.049,
			34.082,
			34.158,
			34.206,
			34.268,
			34.331,
			34.384,
			34.455,
			34.516,
			34.577,
			34.594,
			34.64,
			34.702,
			34.765,
			34.824,
			34.918,
			34.95,
			35.028,
			35.073,
			35.136,
			35.198,
			35.26,
			35.322,
			35.353,
			35.385,
			35.46,
			35.507,
			35.572,
			35.633,
			35.693,
			35.755,
			35.819,
			35.881,
			35.894,
			35.942,
			36.004,
			36.067,
			36.114,
			36.127,
			36.191,
			36.251,
			36.331,
			36.375,
			36.442,
			36.499,
			36.562,
			36.654,
			36.686,
			36.764,
			36.812,
			36.871,
			36.935,
			36.979,
			36.997,
			37.058,
			37.089,
			37.12,
			37.197,
			37.244,
			37.308,
			37.368,
			37.431,
			37.491,
			37.524,
			37.554,
			37.616,
			37.678,
			37.74,
			37.802,
			37.849,
			37.926,
			37.957,
			37.988,
			38.065,
			38.111,
			38.174,
			38.235,
			38.298,
			38.36,
			38.391,
			38.422,
			38.485,
			38.504,
			38.546,
			38.61,
			38.671,
			38.733,
			38.826,
			38.857,
			38.935,
			38.982,
			39.043,
			39.104,
			39.152,
			39.229,
			39.259,
			39.29,
			39.351,
			39.369,
			39.414,
			39.477,
			39.539,
			39.599,
			39.664,
			39.725,
			39.802,
			39.849,
			39.911,
			39.973,
			40.036,
			40.096,
			40.128,
			40.159,
			40.236,
			40.282,
			40.344,
			40.407,
			40.468,
			40.568,
			40.591,
			40.671,
			40.718,
			40.778,
			40.841,
			40.888,
			40.901,
			40.962,
			40.994,
			41.026,
			41.15,
			41.212,
			41.275,
			41.338,
			41.399,
			41.46,
			41.539,
			41.585,
			41.649,
			41.709,
			41.772,
			41.864,
			41.895,
			41.974,
			42.019,
			42.081,
			42.146,
			42.204,
			42.268,
			42.328,
			42.392,
			42.454,
			42.515,
			42.629,
			42.734,
			42.764,
			42.841,
			42.888,
			42.95,
			43.012,
			43.074,
			43.166,
			43.197,
			43.273,
			43.32,
			43.383,
			43.446,
			43.491,
			43.571,
			43.632,
			43.693,
			43.724,
			43.755,
			43.833,
			43.878,
			43.942,
			44.004,
			44.05,
			44.126,
			44.158,
			44.189,
			44.266,
			44.314,
			44.376,
			44.499,
			44.563,
			44.592,
			44.625,
			44.686,
			44.706,
			44.749,
			44.812,
			44.871,
			44.935,
			44.997,
			45.056,
			45.135,
			45.182,
			45.247,
			45.305,
			45.353,
			45.367,
			45.427,
			45.461,
			45.493,
			45.57,
			45.616,
			45.676,
			45.74,
			45.803,
			45.863,
			45.893,
			45.927,
			46.003,
			46.049,
			46.112,
			46.173,
			46.22,
			46.299,
			46.361,
			46.42,
			46.485,
			46.547,
			46.608,
			46.669,
			46.733,
			46.769,
			46.795,
			46.873,
			46.918,
			46.979,
			47.089,
			47.103,
			47.166,
			47.227,
			47.291,
			47.353,
			47.415,
			47.476,
			47.538,
			47.629,
			47.66,
			47.74,
			47.787,
			47.848,
			47.911,
			47.956,
			47.973,
			48.035,
			48.066,
			48.095,
			48.174,
			48.221,
			48.282,
			48.344,
			48.407,
			48.467,
			48.499,
			48.53,
			48.591,
			48.655,
			48.717,
			48.777,
			48.84,
			48.902,
			48.964,
			49.041,
			49.087,
			49.149,
			49.214,
			49.273,
			49.368,
			49.399,
			49.476,
			49.521,
			49.585,
			49.647,
			49.709,
			49.77,
			49.801,
			49.833,
			49.909,
			49.956,
			50.019,
			50.082,
			50.127,
			50.143,
			50.205,
			50.236,
			50.266,
			50.343,
			50.391,
			50.454,
			50.515,
			50.561,
			50.576,
			50.638,
			50.669,
			50.7,
			50.779,
			50.824,
			50.889,
			50.949,
			51.012,
			51.105,
			51.136,
			51.198,
			51.259,
			51.321,
			51.382,
			51.446,
			51.508,
			51.538,
			51.568,
			51.647,
			51.692,
			51.755,
			51.815,
			51.88,
			51.94,
			52.004,
			52.078,
			52.126,
			52.19,
			52.25,
			52.311,
			52.376,
			52.406,
			52.437,
			52.515,
			52.562,
			52.623,
			52.732,
			52.747,
			52.809,
			52.838,
			52.872,
			52.954,
			52.994,
			53.056,
			53.119,
			53.183,
			53.244,
			53.274,
			53.304,
			53.382,
			53.429,
			53.491,
			53.553,
			53.6,
			53.677,
			53.708,
			53.738,
			53.818,
			53.863,
			53.925,
			53.988,
			54.049,
			54.11,
			54.173,
			54.236,
			54.295,
			54.36,
			54.468,
			54.545,
			54.575,
			54.606,
			54.685,
			54.733,
			54.794,
			54.854,
			54.918,
			54.978,
			55.04,
			55.104,
			55.166,
			55.226,
			55.29,
			55.351,
			55.414,
			55.443,
			55.476,
			55.554,
			55.599,
			55.663,
			55.724,
			55.785,
			55.849,
			55.911,
			55.987,
			56.033,
			56.1,
			56.157,
			56.219,
			56.316,
			56.345,
			56.42,
			56.468,
			56.529,
			56.591,
			56.637,
			56.655,
			56.715,
			56.779,
			56.839,
			56.855,
			56.902,
			56.964,
			57.026,
			57.089,
			57.15,
			57.211,
			57.289,
			57.336,
			57.4,
			57.46,
			57.505,
			57.52,
			57.583,
			57.643,
			57.724,
			57.832,
			57.893,
			57.955,
			58.017,
			58.048,
			58.078,
			58.143,
			58.204,
			58.267,
			58.327,
			58.373,
			58.483,
			58.512,
			58.591,
			58.638,
			58.701,
			58.825,
			58.886,
			58.917,
			58.949,
			59.026,
			59.072,
			59.139,
			59.197,
			59.259,
			59.321,
			59.351,
			59.383,
			59.461,
			59.506,
			59.568,
			59.631,
			59.679,
			59.693,
			59.754,
			59.786,
			59.815,
			59.876,
			59.893,
			59.941,
			60.003,
			60.068,
			60.116,
			60.187,
			60.249,
			60.312,
			60.329,
			60.375,
			60.438,
			60.499,
			60.56,
			60.653,
			60.685,
			60.761,
			60.809,
			60.872,
			60.977,
			60.993,
			61.088,
			61.118,
			61.201,
			61.242,
			61.304,
			61.366,
			61.427,
			61.521,
			61.551,
			61.614,
			61.631,
			61.677,
			61.737,
			61.802,
			61.847,
			61.957,
			61.985,
			62.064,
			62.109,
			62.174,
			62.234,
			62.296,
			62.358,
			62.421,
			62.483,
			62.498,
			62.543,
			62.607,
			62.716,
			62.793,
			62.824,
			62.855,
			62.932,
			62.977,
			63.042,
			63.165,
			63.227,
			63.287,
			63.352,
			63.411,
			63.476,
			63.535,
			63.599,
			63.659,
			63.691,
			63.721,
			63.8,
			63.847,
			63.909,
			63.972,
			64.033,
			64.126,
			64.157,
			64.219,
			64.281,
			64.344,
			64.404,
			64.466,
			64.527,
			64.591,
			64.669,
			64.716,
			64.776,
			64.838,
			64.901,
			64.964,
			64.993,
			65.087,
			65.149,
			65.21,
			65.274,
			65.335,
			65.399,
			65.459,
			65.535,
			65.583,
			65.646,
			65.708,
			65.754,
			65.768,
			65.832,
			65.86,
			65.893,
			65.969,
			66.077,
			66.14,
			66.204,
			66.265,
			66.327,
			66.39,
			66.405,
			66.451,
			66.514,
			66.574,
			66.62,
			66.73,
			66.762,
			66.838,
			66.885,
			66.945,
			67.01,
			67.073,
			67.133,
			67.165,
			67.194,
			67.272,
			67.321,
			67.385,
			67.443,
			67.491,
			67.569,
			67.599,
			67.628,
			67.708,
			67.753,
			67.815,
			67.878,
			67.941,
			68.002,
			68.033,
			68.064,
			68.125,
			68.155,
			68.189,
			68.25,
			68.31,
			68.374,
			68.437,
			68.482,
			68.499,
			68.561,
			68.591,
			68.62,
			68.7,
			68.745,
			68.81,
			68.871,
			68.932,
			68.995,
			69.026,
			69.055,
			69.133,
			69.18,
			69.241,
			69.305,
			69.351,
			69.366,
			69.426,
			69.466,
			69.567,
			69.612,
			69.677,
			69.738,
			69.799,
			69.86,
			69.893,
			69.924,
			69.986,
			70.002,
			70.049,
			70.111,
			70.172,
			70.234,
			70.294,
			70.327,
			70.357,
			70.436,
			70.483,
			70.545,
			70.605,
			70.651,
			70.668,
			70.731,
			70.793,
			70.855,
			70.915,
			70.979,
			71.041,
			71.102,
			71.195,
			71.227,
			71.304,
			71.35,
			71.41,
			71.474,
			71.537,
			71.599,
			71.661,
			71.721,
			71.785,
			71.846,
			71.907,
			71.971,
			72.033,
			72.094,
			72.155,
			72.17,
			72.217,
			72.281,
			72.39,
			72.404,
			72.467,
			72.529,
			72.604,
			72.653,
			72.716,
			72.775,
			72.839,
			72.9,
			72.932,
			72.962,
			73.041,
			73.087,
			73.149,
			73.257,
			73.271,
			73.334,
			73.367,
			73.398,
			73.475,
			73.521,
			73.588,
			73.645,
			73.707,
			73.768,
			73.8,
			73.831,
			73.893,
			73.954,
			74.017,
			74.077,
			74.142,
			74.203,
			74.262,
			74.343,
			74.388,
			74.451,
			74.512,
			74.558,
			74.575,
			74.699,
			74.76,
			74.822,
			74.884,
			74.946,
			75.008,
			75.071,
			75.132,
			75.209,
			75.257,
			75.318,
			75.429,
			75.442,
			75.504,
			75.537,
			75.566,
			75.654,
			75.692,
			75.752,
			75.768,
			75.815,
			75.878,
			75.939,
			76.001,
			76.062,
			76.093,
			76.125,
			76.202,
			76.249,
			76.313,
			76.42,
			76.527,
			76.56,
			76.621,
			76.684,
			76.743,
			76.807,
			76.868,
			76.932,
			76.963,
			76.993,
			77.069,
			77.118,
			77.177,
			77.24,
			77.303,
			77.365,
			77.428,
			77.505,
			77.551,
			77.617,
			77.676,
			77.738,
			77.8,
			77.832,
			77.861,
			77.94,
			77.986,
			78.048,
			78.111,
			78.155,
			78.171,
			78.232,
			78.262,
			78.294,
			78.373,
			78.42,
			78.483,
			78.543,
			78.606,
			78.668,
			78.699,
			78.727,
			78.791,
			78.854,
			78.92,
			79.024,
			79.041,
			79.101,
			79.133,
			79.161,
			79.242,
			79.288,
			79.351,
			79.41,
			79.474,
			79.536,
			79.566,
			79.598,
			79.659,
			79.674,
			79.722,
			79.788,
			79.846,
			79.891,
			79.969,
			80.032,
			80.109,
			80.155,
			80.22,
			80.281,
			80.34,
			80.404,
			80.466,
			80.528,
			80.542,
			80.589,
			80.657,
			80.714,
			80.778,
			80.869,
			80.9,
			80.978,
			81.024,
			81.088,
			81.148,
			81.194,
			81.302,
			81.333,
			81.412,
			81.458,
			81.522,
			81.582,
			81.646,
			81.706,
			81.738,
			81.768,
			81.851,
			81.891,
			81.96,
			82.016,
			82.062,
			82.078,
			82.17,
			82.201,
			82.264,
			82.327,
			82.388,
			82.451,
			82.512,
			82.573,
			82.636,
			82.715,
			82.762,
			82.823,
			82.884,
			82.932,
			82.946,
			83.069,
			83.132,
			83.149,
			83.196,
			83.255,
			83.318,
			83.38,
			83.473,
			83.503,
			83.582,
			83.627,
			83.69,
			83.751,
			83.8,
			83.916,
			83.999,
			84.016,
			84.062,
			84.124,
			84.186,
			84.249,
			84.312,
			84.372,
			84.435,
			84.45,
			84.497,
			84.56,
			84.621,
			84.667,
			84.744,
			84.776,
			84.805,
			84.885,
			84.931,
			84.994,
			85.054,
			85.116,
			85.211,
			85.242,
			85.302,
			85.362,
			85.426,
			85.487,
			85.551,
			85.613,
			85.644,
			85.675,
			85.752,
			85.798,
			85.862,
			85.923,
			85.984,
			86.048,
			86.108,
			86.17,
			86.233,
			86.296,
			86.357,
			86.418,
			86.481,
			86.543,
			86.619,
			86.667,
			86.73,
			86.838,
			86.915,
			86.947,
			86.976,
			87.04,
			87.055,
			87.1,
			87.163,
			87.226,
			87.288,
			87.38,
			87.41,
			87.49,
			87.535,
			87.598,
			87.66,
			87.705,
			87.72,
			87.783,
			87.812,
			87.908,
			87.923,
			87.969,
			88.035,
			88.092,
			88.154,
			88.217,
			88.249,
			88.28,
			88.357,
			88.403,
			88.466,
			88.528,
			88.573,
			88.682,
			88.715,
			88.776,
			88.838,
			88.9,
			88.96,
			89.023,
			89.084,
			89.116,
			89.146,
			89.208,
			89.224,
			89.271,
			89.337,
			89.395,
			89.457,
			89.55,
			89.582,
			89.66,
			89.704,
			89.768,
			89.828,
			89.898,
			89.984,
			90.017,
			90.078,
			90.101,
			90.14,
			90.202,
			90.313,
			90.326,
			90.388,
			90.449,
			90.527,
			90.636,
			90.698,
			90.762,
			90.851,
			90.882,
			90.946,
			91.006,
			91.07,
			91.178,
			91.192,
			91.257,
			91.318,
			91.395,
			91.441,
			91.504,
			91.566,
			91.629,
			91.691,
			91.751,
			91.829,
			91.875,
			91.939,
			92,
			92.06,
			92.123,
			92.159,
			92.184,
			92.262,
			92.31,
			92.371,
			92.481,
			92.494,
			92.587,
			92.619,
			92.696,
			92.743,
			92.806,
			92.868,
			92.931,
			92.992,
			93.022,
			93.055,
			93.133,
			93.179,
			93.241,
			93.301,
			93.348,
			93.427,
			93.457,
			93.489,
			93.566,
			93.611,
			93.674,
			93.738,
			93.798,
			93.86,
			93.922,
			94,
			94.047,
			94.108,
			94.221,
			94.295,
			94.325,
			94.355,
			94.433,
			94.482,
			94.604,
			94.666,
			94.726,
			94.761,
			94.791,
			94.851,
			94.912,
			94.979,
			95.038,
			95.1,
			95.194,
			95.224,
			95.301,
			95.349,
			95.412,
			95.519,
			95.626,
			95.659,
			95.736,
			95.783,
			95.846,
			95.907,
			95.969,
			96.062,
			96.093,
			96.17,
			96.215,
			96.285,
			96.387,
			96.416
		]
	],
	'262_265_7982': [
		[
			85.034
		],
		[
			0
		],
		[
			0,
			0.507,
			3.335,
			7.588,
			10.434,
			13.287,
			17.579,
			20.45,
			24.769,
			27.656,
			32,
			34.904,
			37.815,
			42.192,
			45.119,
			49.521,
			50.991,
			52.463,
			56.889,
			64.295,
			67.268,
			71.738,
			74.726,
			76.222,
			79.219,
			83.726,
			86.738,
			89.756,
			94.293,
			97.325,
			103.407,
			104.931,
			109.513,
			112.574,
			117.179,
			120.256,
			124.887,
			129.536,
			131.091,
			137.331,
			138.896,
			143.602,
			146.748,
			151.479,
			154.639,
			159.387,
			164.145,
			167.321,
			170.501,
			175.28,
			178.47,
			184.854,
			186.452,
			191.254,
			194.46,
			199.279,
			202.497,
			207.332,
			212.178,
			215.414,
			220.277,
			223.524,
			226.775,
			233.29,
			234.922,
			238.188,
			241.459,
			243.096,
			246.4,
			248.04,
			251.321,
			254.582,
			259.519,
			262.814,
			267.76,
			269.41,
			271.06,
			276.009,
			279.309,
			284.264,
			287.569,
			292.532,
			297.5,
			300.816,
			304.135,
			309.119,
			312.444,
			317.439,
			320.773,
			325.781,
			327.451,
			329.122,
			334.141,
			337.49,
			340.838,
			345.835,
			349.137,
			354.035,
			355.653,
			357.265,
			363.642,
			369.885,
			372.953,
			377.484,
			380.459,
			383.398,
			384.859,
			387.765,
			389.204,
			392.057,
			394.867,
			398.955,
			400.299,
			401.634,
			406.89,
			408.184,
			412.003,
			414.501,
			418.173,
			420.575,
			422.941,
			426.431,
			427.577,
			429.842,
			432.075,
			433.177,
			434.271,
			437.498,
			439.604,
			442.697,
			446.715,
			447.695,
			450.582,
			452.477,
			454.323,
			457.049,
			458.82,
			462.259,
			463.098,
			465.578,
			467.176,
			469.512,
			471.04,
			473.28,
			474.756,
			475.492,
			476.962,
			479.148,
			480.607,
			482.06,
			483.471,
			485.587,
			487.003,
			489.163,
			490.602,
			491.322,
			492.797,
			494.304,
			496.56,
			498.153,
			501.441,
			502.272,
			505.066,
			506.918,
			511.67,
			514.521,
			516.414,
			517.356,
			519.149,
			520.919,
			521.802,
			523.436,
			524.948,
			527.207,
			527.945,
			528.649,
			530.756,
			531.457,
			532.147,
			534.231,
			535.623,
			537.027,
			540.449,
			542.5,
			543.893,
			546.741,
			547.46,
			549.811,
			551.474,
			554.008,
			556.573,
			558.282,
			561.04,
			562.885,
			564.871,
			567.955,
			569.86,
			572.602,
			573.513,
			574.334,
			576.734,
			578.326,
			580.591,
			582.072,
			584.285,
			585.721,
			587.162,
			590.071,
			590.806,
			593.021,
			594.509,
			596.774,
			598.295,
			600.602,
			602.942,
			604.517,
			606.111,
			608.529,
			610.158,
			613.484,
			614.323,
			616.856,
			618.573,
			621.173,
			624.686,
			625.572,
			627.353,
			630.048,
			632.771,
			634.602,
			636.446,
			638.302,
			641.109,
			642.997,
			644.898,
			645.852,
			648.734,
			650.671,
			652.62,
			657.548,
			660.536,
			662.542,
			665.573,
			667.607,
			670.679,
			672.74,
			675.85,
			677.936,
			681.077,
			684.215,
			686.315,
			688.424,
			691.603,
			692.667,
			693.734,
			698.02,
			699.098,
			702.342,
			704.517,
			706.7,
			709.991,
			712.197,
			716.635,
			717.75,
			721.109,
			723.359,
			726.752,
			729.026,
			731.309,
			732.455,
			734.753,
			738.219,
			740.541,
			744.044,
			746.392,
			749.932,
			752.304,
			755.879,
			757.076,
			758.275,
			761.886,
			764.306,
			767.955,
			770.4,
			774.085,
			777.791,
			779.032,
			782.767,
			784.017,
			785.27,
			789.041,
			791.565,
			795.367,
			797.912,
			800.466,
			801.746,
			805.599,
			808.177,
			810.764,
			814.661,
			817.265,
			821.175,
			822.482,
			823.792,
			827.729,
			830.364,
			834.33,
			836.984,
			840.978,
			843.652,
			847.676,
			850.367,
			854.416,
			857.117,
			862.505,
			863.866,
			869.171,
			870.481,
			874.365,
			876.911,
			879.421,
			883.118,
			885.535,
			889.091,
			890.26,
			891.424,
			894.864,
			897.138,
			900.512,
			902.759,
			905.004,
			906.127,
			908.387,
			909.53,
			911.819,
			915.407,
			917.841,
			920.446,
			924.425,
			927.168,
			932.602,
			933.928,
			937.828,
			940.316,
			942.778,
			946.329,
			948.711,
			953.278,
			954.411,
			958.876,
			960.012,
			963.384,
			965.688,
			969.096,
			972.558,
			974.817,
			978.145,
			980.291,
			982.37,
			985.432,
			987.43,
			990.4,
			991.384,
			992.367,
			995.309,
			997.256,
			1000.172,
			1002.092,
			1004.002,
			1006.828,
			1008.677,
			1011.409,
			1012.305,
			1013.194,
			1015.825,
			1017.559,
			1020.079,
			1023.327,
			1024.115,
			1026.421,
			1027.92,
			1029.408,
			1031.592,
			1033.037,
			1035.182,
			1035.892,
			1036.601,
			1038.688,
			1040.071,
			1042.143,
			1043.526,
			1045.6,
			1046.983,
			1047.677,
			1049.062,
			1051.137,
			1051.829,
			1053.898,
			1055.273,
			1056.647,
			1057.335,
			1058.683,
			1060.702,
			1062.048,
			1064.073,
			1065.425,
			1067.452,
			1068.826,
			1070.897,
			1071.589,
			1072.284,
			1074.436,
			1075.893,
			1078.106,
			1079.616,
			1081.138,
			1081.905,
			1083.471,
			1084.284,
			1085.922,
			1087.574,
			1088.412,
			1090.117,
			1091.837,
			1094.43,
			1096.162,
			1098.772,
			1100.482,
			1103.069,
			1104.792,
			1107.38,
			1109.106,
			1110.823,
			1114.257,
			1115.11,
			1117.668,
			1119.373,
			1121.923,
			1123.623,
			1126.179,
			1129.59,
			1130.442,
			1132.15,
			1133.006,
			1134.717,
			1136.428,
			1139.004,
			1140.729,
			1144.206,
			1145.088,
			1147.759,
			1149.56,
			1151.395,
			1154.176,
			1156.055,
			1158.906,
			1159.865,
			1160.826,
			1163.732,
			1164.709,
			1165.689,
			1168.651,
			1170.645,
			1172.653,
			1173.662,
			1175.69,
			1177.723,
			1178.745,
			1181.804,
			1183.863,
			1185.923,
			1189.019,
			1191.09,
			1195.233,
			1196.274,
			1199.403,
			1201.474,
			1204.543,
			1206.551,
			1209.494,
			1212.364,
			1214.241,
			1216.995,
			1218.801,
			1220.577,
			1224.052,
			1224.908,
			1226.607,
			1229.125,
			1230.792,
			1233.291,
			1234.957,
			1237.473,
			1239.159,
			1241.706,
			1242.561,
			1243.419,
			1246.003,
			1247.734,
			1250.351,
			1252.094,
			1253.846,
			1254.727,
			1257.356,
			1259.115,
			1261.744,
			1263.483,
			1265.245,
			1267.842,
			1269.59,
			1273.044,
			1273.898,
			1276.465,
			1278.143,
			1280.631,
			1282.27,
			1283.894,
			1286.287,
			1287.873,
			1290.216,
			1291.754,
			1294.062,
			1295.57,
			1297.831,
			1299.34,
			1301.578,
			1303.074,
			1303.823,
			1305.306,
			1306.783,
			1310.491,
			1312.712,
			1313.454,
			1314.196,
			1316.439,
			1317.938,
			1319.443,
			1321.733,
			1323.273,
			1326.4,
			1327.193,
			1330.398,
			1331.211,
			1333.672,
			1335.332,
			1337.009,
			1337.84,
			1339.542,
			1342.124,
			1344.741,
			1346.505,
			1348.282,
			1350.978,
			1352.792,
			1355.544,
			1357.396,
			1360.202,
			1362.09,
			1364.948,
			1366.869,
			1369.776,
			1371.729,
			1372.71,
			1374.681,
			1377.659,
			1379.656,
			1381.666,
			1383.687,
			1386.741,
			1391.893,
			1393.975,
			1395.021,
			1397.119,
			1399.222,
			1402.381,
			1404.503,
			1407.704,
			1408.778,
			1409.854,
			1413.096,
			1415.27,
			1418.548,
			1422.955,
			1424.062,
			1426.284,
			1428.514,
			1429.654,
			1433.011,
			1435.276,
			1437.569,
			1440.983,
			1443.304,
			1446.769,
			1447.928,
			1449.088,
			1452.592,
			1454.92,
			1457.305,
			1460.854,
			1463.256,
			1466.897,
			1468.11,
			1469.325,
			1472.977,
			1475.42,
			1481.521,
			1485.186,
			1487.631,
			1491.286,
			1493.756,
			1494.984,
			1497.444,
			1499.887,
			1503.594,
			1506.072,
			1509.799,
			1511.045,
			1512.293,
			1516.046,
			1518.558,
			1522.34,
			1524.87,
			1527.404,
			1531.219,
			1533.772,
			1537.616,
			1540.189,
			1544.062,
			1546.654,
			1550.558,
			1553.167,
			1557.084,
			1561.02,
			1563.655,
			1566.322,
			1570.307,
			1572.976,
			1576.993,
			1579.683,
			1583.734,
			1586.446,
			1590.534,
			1593.271,
			1597.395,
			1601.532,
			1604.311,
			1608.492,
			1609.892,
			1614.099,
			1619.712,
			1621.108,
			1626.645,
			1628.014,
			1632.076,
			1634.752,
			1637.413,
			1641.357,
			1644.014,
			1647.956,
			1650.572,
			1654.482,
			1657.074,
			1660.957,
			1663.518,
			1666.117,
			1667.402,
			1671.245,
			1673.794,
			1677.577,
			1680.1,
			1682.612,
			1686.362,
			1688.875,
			1691.346,
			1693.842,
			1695.091,
			1697.594,
			1701.363,
			1703.886,
			1707.689,
			1710.236,
			1714.073,
			1715.356,
			1716.641,
			1720.51,
			1723.099,
			1727.001,
			1729.613,
			1733.547,
			1736.18,
			1737.501,
			1740.147,
			1742.802,
			1744.133,
			1746.799,
			1749.495,
			1756.206,
			1760.227,
			1762.931,
			1767.019,
			1769.713,
			1772.434,
			1776.525,
			1779.259,
			1783.368,
			1784.74,
			1786.112,
			1790.232,
			1792.972,
			1797.043,
			1799.723,
			1803.686,
			1806.285,
			1808.85,
			1810.121,
			1813.901,
			1816.391,
			1818.861,
			1822.514,
			1824.911,
			1828.441,
			1830.751,
			1834.145,
			1836.362,
			1839.619,
			1841.749,
			1844.88,
			1846.925,
			1847.935,
			1849.928,
			1852.865,
			1854.786,
			1856.682,
			1858.562,
			1860.429,
			1861.361,
			1863.221,
			1866.019,
			1868.789,
			1870.63,
			1872.469,
			1875.224,
			1877.061,
			1879.809,
			1881.643,
			1884.38,
			1886.207,
			1888.952,
			1892.626,
			1893.548,
			1896.322,
			1898.18,
			1902.867,
			1904.756,
			1907.594,
			1909.5,
			1912.369,
			1913.326,
			1914.294,
			1916.236,
			1917.207,
			1919.157,
			1921.135,
			1926.122,
			1929.148,
			1930.164,
			1931.181,
			1934.236,
			1939.405,
			1941.487,
			1944.611,
			1948.805,
			1949.854,
			1951.953,
			1953.002,
			1955.097,
			1957.183,
			1960.304,
			1962.377,
			1965.473,
			1967.505,
			1970.54,
			1972.532,
			1975.508,
			1977.458,
			1979.398,
			1982.289,
			1983.246,
			1984.202,
			1988.003,
			1988.947,
			1991.77,
			1993.65,
			1995.525,
			1996.459,
			1998.328,
			2001.12,
			2003.899,
			2005.745,
			2007.57,
			2010.335,
			2012.162,
			2014.9,
			2016.721,
			2019.446,
			2021.292,
			2024.041,
			2025.899,
			2027.766,
			2030.591,
			2031.538,
			2033.439,
			2036.313,
			2037.276,
			2040.181,
			2044.091,
			2045.074,
			2049.031,
			2050.025,
			2052.021,
			2055.032,
			2057.05,
			2060.096,
			2062.139,
			2065.222,
			2066.255,
			2067.291,
			2070.411,
			2072.503,
			2075.66,
			2077.777,
			2079.92,
			2080.987,
			2083.128,
			2084.203,
			2088.506,
			2089.592,
			2091.771,
			2093.959,
			2097.26,
			2099.471,
			2102.828,
			2103.946,
			2105.048,
			2108.43,
			2110.698,
			2112.985,
			2118.766,
			2122.28,
			2124.65,
			2128.234,
			2130.645,
			2134.302,
			2136.759,
			2140.479,
			2142.973,
			2144.225,
			2146.737,
			2149.254,
			2150.514,
			2153.043,
			2155.568,
			2159.366,
			2161.896,
			2165.696,
			2168.233,
			2172.043,
			2174.59,
			2177.135,
			2180.965,
			2183.522,
			2187.366,
			2188.65,
			2189.936,
			2193.806,
			2195.1,
			2196.399,
			2200.298,
			2202.917,
			2205.55,
			2206.875,
			2209.542,
			2213.577,
			2217.648,
			2220.378,
			2223.118,
			2227.242,
			2230.024,
			2234.149,
			2235.532,
			2236.94,
			2241.091,
			2243.861,
			2248.015,
			2250.79,
			2254.958,
			2259.132,
			2261.912,
			2266.079,
			2267.466,
			2271.622,
			2274.385,
			2278.503,
			2281.232,
			2283.947,
			2285.303,
			2288.004,
			2292.052,
			2294.749,
			2298.784,
			2301.493,
			2306.92,
			2308.274,
			2312.313,
			2314.979,
			2318.922,
			2321.51,
			2324.066,
			2325.333,
			2327.841,
			2331.544,
			2333.975,
			2337.564,
			2339.917,
			2343.384,
			2345.653,
			2348.992,
			2351.175,
			2354.388,
			2356.486,
			2358.547,
			2361.567,
			2363.533,
			2367.354,
			2368.285,
			2371.026,
			2372.806,
			2375.413,
			2377.104,
			2379.567,
			2381.946,
			2383.492,
			2384.989,
			2385.724,
			2387.165,
			2388.566,
			2390.607,
			2391.94,
			2393.905,
			2395.19,
			2397.092,
			2398.369,
			2400.258,
			2401.508,
			2402.78,
			2404.711,
			2405.992,
			2407.929,
			2409.243,
			2411.208,
			2412.521,
			2414.506,
			2415.829,
			2417.826,
			2419.869,
			2421.233,
			2422.602,
			2426.173,
			2428.332,
			2429.059,
			2429.786,
			2431.965,
			2433.421,
			2435.603,
			2437.054,
			2438.518,
			2441.488,
			2442.967,
			2445.193,
			2445.933,
			2448.152,
			2451.043,
			2451.763,
			2453.201,
			2454.591,
			2455.281,
			2457.354,
			2458.729,
			2460.077,
			2462.103,
			2463.457,
			2466.106,
			2466.777,
			2468.812,
			2470.172,
			2472.242,
			2473.643,
			2475.055,
			2475.766,
			2477.201,
			2479.397,
			2481.612,
			2483.108,
			2484.622,
			2486.927,
			2488.481,
			2491.688,
			2492.504,
			2494.991,
			2496.722,
			2498.463,
			2503.105,
			2506.02,
			2508.014,
			2511.009,
			2516.046,
			2518.109,
			2521.172,
			2523.169,
			2524.162,
			2526.107,
			2527.974,
			2528.902,
			2530.749,
			2532.51,
			2535.14,
			2536.014,
			2536.847,
			2539.331,
			2540.155,
			2540.978,
			2543.344,
			2544.9,
			2547.21,
			2548.72,
			2550.224,
			2552.48,
			2553.994,
			2556.242,
			2557.757,
			2560.063,
			2561.596,
			2563.915,
			2565.473,
			2567.025,
			2567.812,
			2570.193,
			2571.795,
			2573.423,
			2577.521,
			2580.006,
			2581.703,
			2584.243,
			2585.939,
			2588.519,
			2590.25,
			2592.869,
			2594.631,
			2595.516,
			2596.404,
			2599.988,
			2600.892,
			2603.62,
			2605.455,
			2608.232,
			2612.931,
			2614.834,
			2615.792,
			2617.719,
			2619.659,
			2622.592,
			2624.562,
			2627.537,
			2628.534,
			2629.534,
			2632.55,
			2634.575,
			2637.631,
			2641.738,
			2642.77,
			2644.839,
			2645.878,
			2647.962,
			2651.105,
			2653.209,
			2655.309,
			2658.471,
			2660.591,
			2662.719,
			2665.928,
			2668.079,
			2669.158,
			2671.323,
			2673.499,
			2676.785,
			2678.99,
			2682.327,
			2683.444,
			2684.567,
			2687.954,
			2690.223,
			2693.646,
			2695.934,
			2699.383,
			2701.685,
			2702.839,
			2705.155,
			2707.469,
			2708.63,
			2710.956,
			2713.285,
			2716.795,
			2717.969,
			2719.144,
			2722.684,
			2723.869,
			2725.056,
			2728.632,
			2731.028,
			2733.434,
			2737.075,
			2739.496,
			2744.342,
			2745.564,
			2750.469,
			2751.7,
			2755.415,
			2757.905,
			2761.663,
			2766.719,
			2767.99,
			2771.823,
			2774.392,
			2776.974,
			2780.866,
			2783.475,
			2787.393,
			2790.014,
			2793.945,
			2796.568,
			2800.519,
			2803.137,
			2807.042,
			2810.894,
			2813.404,
			2817.126,
			2818.349,
			2821.957,
			2826.649,
			2827.802,
			2832.311,
			2833.417,
			2836.667,
			2838.783,
			2840.857,
			2843.887,
			2845.853,
			2848.723,
			2850.585,
			2853.304,
			2855.07,
			2857.663,
			2859.333,
			2860.96,
			2861.758,
			2864.092,
			2865.597,
			2867.782,
			2869.187,
			2872.542,
			2873.841,
			2875.117,
			2877.013,
			2878.275,
			2878.908,
			2880.195,
			2881.49,
			2883.453,
			2884.791,
			2887.531,
			2888.224,
			2890.328,
			2891.752,
			2893.906,
			2895.361,
			2897.568,
			2899.048,
			2901.303,
			2902.817,
			2903.575,
			2905.097,
			2906.643,
			2908.953,
			2910.49,
			2913.559,
			2914.324,
			2916.589,
			2918.088,
			2919.59,
			2921.822,
			2923.308,
			2927.052,
			2929.326,
			2930.092,
			2930.858,
			2933.162,
			2934.716,
			2937.067,
			2940.237,
			2941.038,
			2943.442,
			2945.061,
			2946.695,
			2949.157,
			2950.801,
			2953.294,
			2954.974,
			2959.239,
			2961.843,
			2963.604,
			2966.271,
			2968.066,
			2968.971,
			2969.878,
			2972.611,
			2973.53,
			2974.449,
			2977.211,
			2980.94,
			2981.879,
			2983.768,
			2985.674,
			2986.632,
			2989.53,
			2991.481,
			2996.416,
			2998.419,
			3001.439,
			3003.477,
			3006.553,
			3008.622,
			3011.746,
			3013.823,
			3015.919,
			3016.967,
			3020.125,
			3022.24,
			3025.436,
			3027.576,
			3029.728,
			3032.973,
			3035.148,
			3037.335,
			3039.53,
			3040.632,
			3042.841,
			3043.95,
			3046.173,
			3048.406,
			3051.776,
			3054.034,
			3058.579,
			3059.721,
			3064.315,
			3065.469,
			3068.946,
			3071.274,
			3073.614,
			3074.787,
			3077.135,
			3080.674,
			3083.042,
			3084.23,
			3086.611,
			3088.999,
			3092.597,
			3095.004,
			3098.634,
			3099.849,
			3101.066,
			3104.728,
			3107.179,
			3110.873,
			3113.348,
			3115.831,
			3120.823,
			3122.076,
			3125.852,
			3127.114,
			3128.377,
			3132.178,
			3134.723,
			3138.556,
			3141.122,
			3143.697,
			3144.988,
			3148.872,
			3151.465,
			3154.065,
			3159.293,
			3160.605,
			3164.556,
			3167.22,
			3171.194,
			3173.845,
			3177.808,
			3180.435,
			3184.329,
			3186.892,
			3190.674,
			3194.376,
			3195.593,
			3199.21,
			3203.921,
			3205.078,
			3209.634,
			3210.737,
			3216.236,
			3218.369,
			3221.52,
			3223.582,
			3226.612,
			3227.607,
			3228.596,
			3231.546,
			3233.489,
			3236.381,
			3238.301,
			3240.221,
			3241.186,
			3243.115,
			3244.079,
			3246.01,
			3248.908,
			3250.839,
			3252.772,
			3255.666,
			3257.588,
			3259.503,
			3262.366,
			3264.267,
			3265.216,
			3267.115,
			3269.02,
			3271.867,
			3273.766,
			3276.608,
			3277.553,
			3278.518,
			3281.408,
			3283.339,
			3286.262,
			3288.209,
			3291.174,
			3293.147,
			3294.149,
			3296.151,
			3299.144,
			3301.132,
			3303.111,
			3306.072,
			3308.035,
			3309.991,
			3311.935,
			3312.903,
			3315.79,
			3317.698,
			3319.598,
			3322.413,
			3324.283,
			3327.054,
			3327.973,
			3328.892,
			3331.631,
			3332.539,
			3333.447,
			3336.167,
			3337.975,
			3340.699,
			3342.519,
			3345.253,
			3348.025,
			3349.901,
			3351.774,
			3354.615,
			3356.522,
			3359.4,
			3361.343,
			3364.283,
			3366.258,
			3369.24,
			3371.242,
			3374.264,
			3377.309,
			3378.329,
			3382.435,
			3383.468,
			3386.582,
			3388.67,
			3391.807,
			3395.993,
			3397.045,
			3400.213,
			3402.335,
			3404.467,
			3407.676,
			3409.829,
			3413.086,
			3415.255,
			3418.502,
			3420.669,
			3423.914,
			3426.077,
			3428.236,
			3429.333,
			3431.525,
			3432.624,
			3433.724,
			3438.142,
			3442.612,
			3444.869,
			3448.273,
			3450.557,
			3452.851,
			3454.002,
			3456.311,
			3459.792,
			3462.118,
			3465.624,
			3467.969,
			3471.504,
			3472.686,
			3473.87,
			3477.437,
			3479.827,
			3485.849,
			3489.494,
			3491.936,
			3495.618,
			3498.083,
			3501.797,
			3504.283,
			3508.027,
			3510.531,
			3513.044,
			3515.565,
			3516.829,
			3520.631,
			3523.176,
			3525.727,
			3529.573,
			3532.151,
			3536.033,
			3537.332,
			3538.633,
			3542.538,
			3545.145,
			3549.071,
			3551.697,
			3554.329,
			3555.648,
			3560.941,
			3562.269,
			3566.262,
			3568.934,
			3571.613,
			3575.646,
			3578.344,
			3582.405,
			3583.762,
			3585.12,
			3589.207,
			3591.94,
			3596.052,
			3598.803,
			3601.56,
			3605.709,
			3607.095,
			3608.483,
			3614.052,
			3615.448,
			3619.651,
			3622.462,
			3626.689,
			3628.089
		],
		[
			0,
			0.02,
			0.052,
			0.126,
			0.174,
			0.239,
			0.299,
			0.359,
			0.422,
			0.486,
			0.547,
			0.608,
			0.67,
			0.734,
			0.78,
			0.857,
			0.889,
			0.917,
			0.994,
			1.105,
			1.166,
			1.229,
			1.291,
			1.321,
			1.354,
			1.43,
			1.477,
			1.538,
			1.602,
			1.664,
			1.756,
			1.787,
			1.864,
			1.911,
			1.972,
			2.036,
			2.097,
			2.189,
			2.221,
			2.3,
			2.343,
			2.408,
			2.47,
			2.53,
			2.593,
			2.654,
			2.733,
			2.779,
			2.843,
			2.902,
			2.966,
			3.057,
			3.09,
			3.168,
			3.214,
			3.274,
			3.338,
			3.401,
			3.491,
			3.523,
			3.601,
			3.647,
			3.708,
			3.818,
			3.834,
			3.894,
			3.926,
			3.956,
			4.021,
			4.035,
			4.082,
			4.142,
			4.204,
			4.267,
			4.33,
			4.359,
			4.391,
			4.469,
			4.515,
			4.582,
			4.64,
			4.7,
			4.793,
			4.825,
			4.888,
			4.951,
			5.012,
			5.073,
			5.135,
			5.197,
			5.23,
			5.26,
			5.336,
			5.383,
			5.446,
			5.509,
			5.553,
			5.63,
			5.663,
			5.692,
			5.772,
			5.881,
			5.941,
			6.002,
			6.065,
			6.097,
			6.127,
			6.189,
			6.206,
			6.253,
			6.313,
			6.375,
			6.421,
			6.436,
			6.531,
			6.563,
			6.641,
			6.686,
			6.75,
			6.809,
			6.872,
			6.934,
			6.969,
			6.997,
			7.058,
			7.074,
			7.12,
			7.183,
			7.245,
			7.306,
			7.399,
			7.429,
			7.506,
			7.555,
			7.617,
			7.679,
			7.74,
			7.834,
			7.865,
			7.927,
			7.987,
			8.05,
			8.113,
			8.174,
			8.237,
			8.268,
			8.297,
			8.374,
			8.422,
			8.484,
			8.546,
			8.609,
			8.67,
			8.733,
			8.796,
			8.808,
			8.857,
			8.92,
			8.98,
			9.027,
			9.134,
			9.167,
			9.242,
			9.29,
			9.415,
			9.477,
			9.538,
			9.57,
			9.601,
			9.661,
			9.676,
			9.726,
			9.787,
			9.85,
			9.894,
			9.912,
			9.973,
			10.004,
			10.035,
			10.112,
			10.157,
			10.222,
			10.346,
			10.406,
			10.469,
			10.547,
			10.593,
			10.656,
			10.717,
			10.78,
			10.87,
			10.904,
			10.981,
			11.026,
			11.09,
			11.151,
			11.197,
			11.274,
			11.305,
			11.337,
			11.397,
			11.46,
			11.524,
			11.583,
			11.647,
			11.71,
			11.771,
			11.848,
			11.893,
			11.958,
			12.018,
			12.081,
			12.142,
			12.203,
			12.281,
			12.329,
			12.392,
			12.454,
			12.516,
			12.609,
			12.638,
			12.717,
			12.764,
			12.831,
			12.937,
			12.95,
			13.009,
			13.073,
			13.152,
			13.198,
			13.259,
			13.32,
			13.382,
			13.446,
			13.475,
			13.508,
			13.585,
			13.631,
			13.693,
			13.803,
			13.88,
			13.942,
			14.002,
			14.064,
			14.127,
			14.19,
			14.252,
			14.313,
			14.375,
			14.454,
			14.499,
			14.562,
			14.624,
			14.671,
			14.683,
			14.779,
			14.81,
			14.872,
			14.934,
			14.996,
			15.058,
			15.12,
			15.214,
			15.242,
			15.32,
			15.369,
			15.431,
			15.491,
			15.538,
			15.555,
			15.614,
			15.677,
			15.739,
			15.801,
			15.865,
			15.925,
			15.988,
			16.05,
			16.08,
			16.11,
			16.189,
			16.234,
			16.298,
			16.361,
			16.422,
			16.514,
			16.546,
			16.607,
			16.623,
			16.67,
			16.733,
			16.792,
			16.854,
			16.919,
			16.955,
			16.98,
			17.056,
			17.102,
			17.169,
			17.226,
			17.273,
			17.353,
			17.382,
			17.414,
			17.49,
			17.539,
			17.6,
			17.66,
			17.724,
			17.784,
			17.848,
			17.908,
			17.973,
			18.033,
			18.142,
			18.158,
			18.251,
			18.281,
			18.359,
			18.407,
			18.468,
			18.53,
			18.591,
			18.655,
			18.685,
			18.717,
			18.777,
			18.839,
			18.903,
			18.965,
			19.014,
			19.026,
			19.089,
			19.119,
			19.15,
			19.226,
			19.274,
			19.336,
			19.398,
			19.464,
			19.552,
			19.583,
			19.66,
			19.707,
			19.77,
			19.833,
			19.88,
			19.988,
			20.019,
			20.097,
			20.141,
			20.203,
			20.267,
			20.327,
			20.421,
			20.453,
			20.53,
			20.577,
			20.639,
			20.701,
			20.763,
			20.825,
			20.854,
			20.886,
			20.963,
			21.012,
			21.077,
			21.133,
			21.197,
			21.258,
			21.322,
			21.383,
			21.399,
			21.445,
			21.507,
			21.569,
			21.631,
			21.722,
			21.754,
			21.832,
			21.877,
			21.941,
			22.003,
			22.05,
			22.127,
			22.156,
			22.189,
			22.267,
			22.314,
			22.376,
			22.437,
			22.499,
			22.561,
			22.59,
			22.623,
			22.699,
			22.746,
			22.81,
			22.87,
			22.917,
			22.934,
			22.996,
			23.056,
			23.119,
			23.182,
			23.242,
			23.304,
			23.366,
			23.427,
			23.458,
			23.49,
			23.569,
			23.615,
			23.677,
			23.739,
			23.784,
			23.8,
			23.863,
			23.893,
			23.924,
			23.987,
			24.002,
			24.048,
			24.112,
			24.172,
			24.234,
			24.297,
			24.358,
			24.422,
			24.483,
			24.549,
			24.608,
			24.654,
			24.763,
			24.793,
			24.869,
			24.918,
			24.98,
			25.04,
			25.104,
			25.2,
			25.228,
			25.289,
			25.304,
			25.352,
			25.414,
			25.475,
			25.522,
			25.63,
			25.662,
			25.738,
			25.784,
			25.846,
			25.91,
			25.971,
			26.034,
			26.065,
			26.096,
			26.157,
			26.174,
			26.219,
			26.286,
			26.344,
			26.389,
			26.406,
			26.466,
			26.499,
			26.531,
			26.606,
			26.654,
			26.716,
			26.779,
			26.84,
			26.934,
			26.964,
			27.025,
			27.089,
			27.15,
			27.212,
			27.272,
			27.367,
			27.397,
			27.475,
			27.522,
			27.589,
			27.693,
			27.709,
			27.769,
			27.832,
			27.893,
			27.957,
			28.018,
			28.08,
			28.143,
			28.204,
			28.236,
			28.266,
			28.342,
			28.391,
			28.453,
			28.514,
			28.559,
			28.576,
			28.669,
			28.699,
			28.777,
			28.823,
			28.886,
			28.949,
			29.009,
			29.102,
			29.133,
			29.197,
			29.259,
			29.326,
			29.38,
			29.427,
			29.505,
			29.568,
			29.646,
			29.692,
			29.755,
			29.817,
			29.879,
			29.939,
			30.003,
			30.065,
			30.079,
			30.125,
			30.187,
			30.298,
			30.374,
			30.405,
			30.436,
			30.514,
			30.56,
			30.621,
			30.685,
			30.746,
			30.84,
			30.87,
			30.947,
			30.993,
			31.056,
			31.117,
			31.165,
			31.18,
			31.242,
			31.303,
			31.387,
			31.427,
			31.491,
			31.552,
			31.598,
			31.676,
			31.739,
			31.8,
			31.864,
			31.924,
			31.986,
			32.049,
			32.108,
			32.14,
			32.173,
			32.248,
			32.297,
			32.359,
			32.422,
			32.481,
			32.605,
			32.67,
			32.683,
			32.731,
			32.794,
			32.854,
			32.916,
			32.977,
			33.01,
			33.041,
			33.119,
			33.164,
			33.225,
			33.336,
			33.351,
			33.412,
			33.447,
			33.475,
			33.551,
			33.599,
			33.66,
			33.723,
			33.783,
			33.847,
			33.878,
			33.907,
			33.985,
			34.033,
			34.095,
			34.157,
			34.203,
			34.281,
			34.312,
			34.341,
			34.419,
			34.467,
			34.589,
			34.651,
			34.715,
			34.777,
			34.838,
			34.853,
			34.9,
			34.964,
			35.025,
			35.087,
			35.15,
			35.179,
			35.209,
			35.289,
			35.335,
			35.397,
			35.46,
			35.521,
			35.583,
			35.646,
			35.721,
			35.769,
			35.83,
			35.892,
			35.954,
			36.016,
			36.08,
			36.157,
			36.204,
			36.264,
			36.327,
			36.388,
			36.451,
			36.513,
			36.576,
			36.636,
			36.701,
			36.761,
			36.823,
			36.917,
			36.947,
			37.025,
			37.07,
			37.133,
			37.24,
			37.256,
			37.351,
			37.381,
			37.459,
			37.505,
			37.571,
			37.63,
			37.692,
			37.753,
			37.815,
			37.892,
			37.939,
			38.003,
			38.064,
			38.111,
			38.125,
			38.219,
			38.249,
			38.327,
			38.374,
			38.434,
			38.498,
			38.56,
			38.621,
			38.652,
			38.683,
			38.746,
			38.809,
			38.869,
			38.931,
			38.979,
			39.057,
			39.087,
			39.117,
			39.196,
			39.243,
			39.305,
			39.367,
			39.426,
			39.491,
			39.52,
			39.552,
			39.614,
			39.636,
			39.675,
			39.739,
			39.847,
			39.925,
			39.985,
			40.064,
			40.109,
			40.171,
			40.234,
			40.281,
			40.357,
			40.387,
			40.421,
			40.482,
			40.542,
			40.605,
			40.668,
			40.731,
			40.79,
			40.824,
			40.855,
			40.931,
			40.979,
			41.044,
			41.103,
			41.164,
			41.227,
			41.289,
			41.351,
			41.411,
			41.473,
			41.535,
			41.598,
			41.66,
			41.696,
			41.722,
			41.799,
			41.847,
			41.908,
			41.971,
			42.016,
			42.032,
			42.095,
			42.156,
			42.233,
			42.281,
			42.341,
			42.403,
			42.467,
			42.528,
			42.59,
			42.666,
			42.714,
			42.777,
			42.884,
			42.9,
			42.992,
			43.024,
			43.148,
			43.21,
			43.273,
			43.335,
			43.397,
			43.425,
			43.458,
			43.521,
			43.536,
			43.582,
			43.646,
			43.757,
			43.831,
			43.862,
			43.892,
			43.969,
			44.08,
			44.14,
			44.204,
			44.297,
			44.325,
			44.388,
			44.405,
			44.452,
			44.513,
			44.575,
			44.622,
			44.699,
			44.759,
			44.838,
			44.883,
			44.947,
			45.01,
			45.071,
			45.133,
			45.163,
			45.195,
			45.273,
			45.32,
			45.38,
			45.441,
			45.488,
			45.505,
			45.567,
			45.63,
			45.707,
			45.751,
			45.819,
			45.877,
			45.938,
			46,
			46.064,
			46.141,
			46.187,
			46.25,
			46.312,
			46.371,
			46.434,
			46.465,
			46.497,
			46.575,
			46.621,
			46.683,
			46.792,
			46.807,
			46.9,
			46.931,
			46.993,
			47.054,
			47.118,
			47.18,
			47.241,
			47.303,
			47.335,
			47.365,
			47.443,
			47.49,
			47.552,
			47.613,
			47.66,
			47.675,
			47.738,
			47.769,
			47.861,
			47.882,
			47.922,
			47.985,
			48.047,
			48.109,
			48.172,
			48.203,
			48.232,
			48.312,
			48.357,
			48.421,
			48.529,
			48.605,
			48.668,
			48.743,
			48.792,
			48.854,
			48.914,
			48.978,
			49.04,
			49.071,
			49.1,
			49.164,
			49.179,
			49.226,
			49.288,
			49.349,
			49.397,
			49.475,
			49.536,
			49.614,
			49.659,
			49.72,
			49.783,
			49.846,
			49.907,
			49.942,
			49.97,
			50.033,
			50.047,
			50.094,
			50.157,
			50.217,
			50.263,
			50.28,
			50.342,
			50.404,
			50.482,
			50.526,
			50.591,
			50.652,
			50.697,
			50.775,
			50.808,
			50.838,
			50.9,
			50.962,
			51.026,
			51.087,
			51.147,
			51.24,
			51.272,
			51.35,
			51.396,
			51.458,
			51.519,
			51.581,
			51.642,
			51.674,
			51.705,
			51.767,
			51.831,
			51.892,
			51.953,
			52.015,
			52.109,
			52.141,
			52.217,
			52.264,
			52.328,
			52.389,
			52.434,
			52.451,
			52.512,
			52.575,
			52.636,
			52.702,
			52.765,
			52.822,
			52.884,
			52.947,
			53.008,
			53.086,
			53.133,
			53.195,
			53.257,
			53.303,
			53.41,
			53.442,
			53.521,
			53.567,
			53.63,
			53.69,
			53.752,
			53.843,
			53.876,
			53.938,
			53.954,
			53.999,
			54.067,
			54.125,
			54.172,
			54.249,
			54.311,
			54.389,
			54.434,
			54.497,
			54.557,
			54.621,
			54.683,
			54.743,
			54.805,
			54.867,
			54.929,
			54.991,
			55.054,
			55.117,
			55.179,
			55.255,
			55.303,
			55.363,
			55.489,
			55.552,
			55.58,
			55.612,
			55.69,
			55.738,
			55.8,
			55.86,
			55.921,
			56.017,
			56.047,
			56.13,
			56.169,
			56.234,
			56.34,
			56.355,
			56.419,
			56.45,
			56.482,
			56.557,
			56.606,
			56.668,
			56.727,
			56.792,
			56.885,
			56.914,
			56.992,
			57.038,
			57.102,
			57.161,
			57.21,
			57.225,
			57.286,
			57.347,
			57.425,
			57.474,
			57.534,
			57.597,
			57.659,
			57.753,
			57.784,
			57.86,
			57.908,
			57.97,
			58.077,
			58.155,
			58.217,
			58.293,
			58.403,
			58.466,
			58.525,
			58.589,
			58.621,
			58.652,
			58.714,
			58.729,
			58.776,
			58.837,
			58.899,
			58.944,
			58.959,
			59.024,
			59.055,
			59.086,
			59.163,
			59.209,
			59.275,
			59.334,
			59.393,
			59.458,
			59.519,
			59.581,
			59.642,
			59.704,
			59.767,
			59.83,
			59.89,
			59.922,
			59.954,
			60.031,
			60.075,
			60.141,
			60.264,
			60.324,
			60.387,
			60.463,
			60.509,
			60.574,
			60.634,
			60.697,
			60.759,
			60.791,
			60.821,
			60.9,
			60.944,
			61.008,
			61.07,
			61.133,
			61.255,
			61.319,
			61.332,
			61.38,
			61.443,
			61.504,
			61.567,
			61.628,
			61.659,
			61.691,
			61.767,
			61.814,
			61.875,
			61.984,
			61.999,
			62.06,
			62.091,
			62.124,
			62.202,
			62.247,
			62.31,
			62.372,
			62.434,
			62.496,
			62.557,
			62.62,
			62.635,
			62.681,
			62.745,
			62.805,
			62.868,
			62.93,
			62.961,
			62.993,
			63.07,
			63.115,
			63.176,
			63.239,
			63.301,
			63.365,
			63.393,
			63.425,
			63.488,
			63.502,
			63.549,
			63.613,
			63.673,
			63.72,
			63.736,
			63.797,
			63.829,
			63.858,
			63.938,
			63.984,
			64.044,
			64.108,
			64.17,
			64.263,
			64.293,
			64.377,
			64.418,
			64.482,
			64.542,
			64.603,
			64.697,
			64.728,
			64.805,
			64.852,
			64.914,
			64.976,
			65.037,
			65.1,
			65.163,
			65.224,
			65.286,
			65.349,
			65.411,
			65.472,
			65.565,
			65.597,
			65.672,
			65.719,
			65.782,
			65.89,
			65.906,
			65.999,
			66.03,
			66.107,
			66.155,
			66.215,
			66.277,
			66.339,
			66.401,
			66.465,
			66.527,
			66.588,
			66.651,
			66.711,
			66.76,
			66.775,
			66.867,
			66.9,
			66.975,
			67.023,
			67.148,
			67.207,
			67.27,
			67.332,
			67.394,
			67.411,
			67.458,
			67.52,
			67.581,
			67.626,
			67.737,
			67.768,
			67.844,
			67.891,
			67.954,
			68.015,
			68.077,
			68.138,
			68.202,
			68.264,
			68.277,
			68.324,
			68.387,
			68.447,
			68.509,
			68.605,
			68.634,
			68.712,
			68.759,
			68.819,
			68.884,
			68.93,
			69.069,
			69.131,
			69.148,
			69.192,
			69.257,
			69.316,
			69.378,
			69.473,
			69.502,
			69.582,
			69.627,
			69.69,
			69.75,
			69.811,
			69.875,
			69.937,
			70.06,
			70.124,
			70.186,
			70.247,
			70.31,
			70.34,
			70.369,
			70.434,
			70.447,
			70.495,
			70.564,
			70.667,
			70.68,
			70.741,
			70.773,
			70.805,
			70.883,
			70.93,
			71.053,
			71.116,
			71.177,
			71.24,
			71.301,
			71.364,
			71.425,
			71.487,
			71.533,
			71.549,
			71.642,
			71.674,
			71.75,
			71.797,
			71.858,
			71.922,
			71.982,
			72.043,
			72.075,
			72.107,
			72.171,
			72.184,
			72.231,
			72.293,
			72.357,
			72.417,
			72.511,
			72.543,
			72.625,
			72.667,
			72.727,
			72.789,
			72.836,
			72.853,
			72.915,
			72.975,
			73.037,
			73.052,
			73.1,
			73.16,
			73.224,
			73.286,
			73.349,
			73.377,
			73.408,
			73.487,
			73.534,
			73.6,
			73.656,
			73.702,
			73.811,
			73.845,
			73.907,
			73.922,
			73.968,
			74.03,
			74.091,
			74.154,
			74.217,
			74.247,
			74.277,
			74.354,
			74.402,
			74.465,
			74.571,
			74.588,
			74.65,
			74.712,
			74.774,
			74.836,
			74.897,
			74.96,
			75.022,
			75.084,
			75.147,
			75.224,
			75.269,
			75.333,
			75.44,
			75.457,
			75.55,
			75.579,
			75.705,
			75.766,
			75.827,
			75.89,
			75.952,
			75.984,
			76.015,
			76.092,
			76.139,
			76.2,
			76.262,
			76.309,
			76.325,
			76.386,
			76.417,
			76.449,
			76.527,
			76.572,
			76.634,
			76.697,
			76.757,
			76.82,
			76.881,
			76.944,
			76.959,
			77.007,
			77.067,
			77.13,
			77.193,
			77.253,
			77.284,
			77.315,
			77.392,
			77.441,
			77.501,
			77.565,
			77.627,
			77.689,
			77.72,
			77.752,
			77.829,
			77.875,
			77.936,
			77.999,
			78.06,
			78.122,
			78.153,
			78.184,
			78.263,
			78.309,
			78.371,
			78.431,
			78.48,
			78.557,
			78.589,
			78.618,
			78.68,
			78.697,
			78.741,
			78.809,
			78.868,
			78.929,
			78.99,
			79.053,
			79.13,
			79.177,
			79.24,
			79.302,
			79.364,
			79.425,
			79.487,
			79.55,
			79.61,
			79.673,
			79.734,
			79.797,
			79.89,
			79.921,
			79.997,
			80.046,
			80.107,
			80.167,
			80.231,
			80.325,
			80.354,
			80.431,
			80.477,
			80.541,
			80.602,
			80.666,
			80.727,
			80.789,
			80.872,
			80.913,
			80.974,
			81.037,
			81.082,
			81.099,
			81.162,
			81.192,
			81.222,
			81.299,
			81.408,
			81.472,
			81.534,
			81.595,
			81.627,
			81.657,
			81.719,
			81.782,
			81.844,
			81.905,
			81.951,
			82.03,
			82.061,
			82.092,
			82.169,
			82.215,
			82.339,
			82.402,
			82.464,
			82.524,
			82.588,
			82.649,
			82.71,
			82.774,
			82.835,
			82.896,
			82.933,
			82.96,
			83.038,
			83.083,
			83.146,
			83.206,
			83.27,
			83.331,
			83.363,
			83.392,
			83.454,
			83.518,
			83.58,
			83.641,
			83.688,
			83.703,
			83.797,
			83.829,
			83.905,
			83.952,
			84.015,
			84.074,
			84.122,
			84.199,
			84.232,
			84.26,
			84.34,
			84.385,
			84.449,
			84.509,
			84.571,
			84.633,
			84.665,
			84.694,
			84.774,
			84.819,
			84.88,
			84.944,
			84.997,
			85.034
		]
	],
	'262_263_7982': [
		[
			94.685
		],
		[
			0
		],
		[
			0,
			3.459,
			6.223,
			8.994,
			13.164,
			15.958,
			21.571,
			22.979,
			27.213,
			30.043,
			34.295,
			37.137,
			39.984,
			41.41,
			44.266,
			45.696,
			47.127,
			52.867,
			54.305,
			58.629,
			61.519,
			64.413,
			65.863,
			68.766,
			71.673,
			73.129,
			76.045,
			80.43,
			83.359,
			87.765,
			90.711,
			96.626,
			98.109,
			102.574,
			105.559,
			110.049,
			116.079,
			117.6,
			125.212,
			128.265,
			129.794,
			132.859,
			135.936,
			139.022,
			143.675,
			146.807,
			149.946,
			151.518,
			156.229,
			159.365,
			162.495,
			167.209,
			170.363,
			175.107,
			176.686,
			178.267,
			183.007,
			186.169,
			190.92,
			194.101,
			198.899,
			202.11,
			206.938,
			210.15,
			211.756,
			214.963,
			218.178,
			223.014,
			224.632,
			226.251,
			231.11,
			234.35,
			239.211,
			242.451,
			245.72,
			250.586,
			253.833,
			258.683,
			260.31,
			261.94,
			266.831,
			270.095,
			274.994,
			278.262,
			281.532,
			283.168,
			286.443,
			289.721,
			291.362,
			296.291,
			299.583,
			307.829,
			311.128,
			316.078,
			317.728,
			319.379,
			324.328,
			327.628,
			332.583,
			335.893,
			340.869,
			344.194,
			345.857,
			349.184,
			354.171,
			355.827,
			360.751,
			363.981,
			367.157,
			371.871,
			374.974,
			376.526,
			379.603,
			381.127,
			384.168,
			387.171,
			391.586,
			394.475,
			398.729,
			400.128,
			401.517,
			405.629,
			408.321,
			412.289,
			414.886,
			417.45,
			418.718,
			421.228,
			424.927,
			427.348,
			428.546,
			430.916,
			433.252,
			435.553,
			438.934,
			441.142,
			444.386,
			447.547,
			449.608,
			451.633,
			454.598,
			456.526,
			459.35,
			460.289,
			461.204,
			463.901,
			465.664,
			468.247,
			469.928,
			472.389,
			473.995,
			474.788,
			476.343,
			477.874,
			478.633,
			480.138,
			481.614,
			483.796,
			484.52,
			485.243,
			488.131,
			488.854,
			491.027,
			492.515,
			494.826,
			496.373,
			498.03,
			500.667,
			501.549,
			502.439,
			505.337,
			507.275,
			510.306,
			512.345,
			515.145,
			516.96,
			518.749,
			519.602,
			522.157,
			523.845,
			527.946,
			529.538,
			531.88,
			532.658,
			533.435,
			535.732,
			537.271,
			539.532,
			541.045,
			542.569,
			545.63,
			546.403,
			549.492,
			550.279,
			552.607,
			554.129,
			555.656,
			556.427,
			557.943,
			559.43,
			560.159,
			561.743,
			562.534,
			564.097,
			565.643,
			568.119,
			569.757,
			572.126,
			572.887,
			573.648,
			575.942,
			577.352,
			579.329,
			582.035,
			582.662,
			584.566,
			585.854,
			587.157,
			589.159,
			590.514,
			592.574,
			593.977,
			596.85,
			597.578,
			599.807,
			601.323,
			602.854,
			606.781,
			609.999,
			610.815,
			613.284,
			614.933,
			617.455,
			619.155,
			621.69,
			623.399,
			624.258,
			625.97,
			628.587,
			630.347,
			632.12,
			634.804,
			635.705,
			636.608,
			639.336,
			640.251,
			641.17,
			643.016,
			645.806,
			648.624,
			650.533,
			652.421,
			653.378,
			655.3,
			657.232,
			660.151,
			662.107,
			666.05,
			667.042,
			670.035,
			672.047,
			675.09,
			677.133,
			679.19,
			680.222,
			683.335,
			685.424,
			688.565,
			690.659,
			692.76,
			695.934,
			698.062,
			701.271,
			702.345,
			703.422,
			706.662,
			708.834,
			711.014,
			714.299,
			716.501,
			719.82,
			720.931,
			725.396,
			727.643,
			731.031,
			733.301,
			736.723,
			739.016,
			742.474,
			745.953,
			748.284,
			750.624,
			754.153,
			756.516,
			760.079,
			761.272,
			762.467,
			766.066,
			768.478,
			772.113,
			774.549,
			776.995,
			780.68,
			783.148,
			786.869,
			789.361,
			793.114,
			795.627,
			799.414,
			801.949,
			804.494,
			805.769,
			809.61,
			812.182,
			814.764,
			818.652,
			821.255,
			826.469,
			827.775,
			831.705,
			833.019,
			834.334,
			838.294,
			840.943,
			843.602,
			844.934,
			848.966,
			851.648,
			855.683,
			857.031,
			861.075,
			867.773,
			870.413,
			873.021,
			874.311,
			876.861,
			878.101,
			880.609,
			883.084,
			886.722,
			889.119,
			893.771,
			894.915,
			898.294,
			900.502,
			903.745,
			905.842,
			907.928,
			908.991,
			912.154,
			914.343,
			916.571,
			920.041,
			922.474,
			926.179,
			928.662,
			931.13,
			934.773,
			938.056,
			940.195,
			943.228,
			945.271,
			947.326,
			951.464,
			952.506,
			955.644,
			957.751,
			960.926,
			963.095,
			966.372,
			970.774,
			971.859,
			975.224,
			977.43,
			979.779,
			985.752,
			989.298,
			990.38,
			991.454,
			994.479,
			996.275,
			998.903,
			1000.579,
			1002.256,
			1004.724,
			1005.548,
			1006.368,
			1009.613,
			1010.42,
			1012.83,
			1014.412,
			1015.976,
			1016.759,
			1018.307,
			1020.591,
			1022.845,
			1024.335,
			1025.814,
			1028.016,
			1029.473,
			1031.645,
			1032.369,
			1033.09,
			1035.245,
			1036.676,
			1038.818,
			1040.242,
			1041.665,
			1042.376,
			1043.791,
			1044.496,
			1045.905,
			1048.016,
			1050.82,
			1052.22,
			1054.317,
			1055.707,
			1057.792,
			1059.183,
			1059.879,
			1061.271,
			1062.665,
			1064.757,
			1066.17,
			1068.295,
			1069.004,
			1069.716,
			1071.926,
			1073.409,
			1075.649,
			1077.164,
			1079.45,
			1081.737,
			1083.252,
			1084.776,
			1085.542,
			1087.094,
			1088.672,
			1091.053,
			1092.678,
			1095.138,
			1095.961,
			1096.786,
			1099.27,
			1100.931,
			1102.596,
			1105.1,
			1106.774,
			1109.246,
			1110.07,
			1110.895,
			1113.352,
			1114.983,
			1117.436,
			1119.06,
			1121.507,
			1123.144,
			1125.609,
			1128.099,
			1129.774,
			1131.449,
			1134.023,
			1135.752,
			1140.133,
			1142.832,
			1144.646,
			1147.404,
			1149.266,
			1151.143,
			1153.979,
			1154.929,
			1155.884,
			1159.732,
			1160.702,
			1163.639,
			1165.617,
			1168.611,
			1172.635,
			1173.651,
			1175.689,
			1178.768,
			1180.807,
			1183.869,
			1185.919,
			1190.032,
			1191.063,
			1194.129,
			1196.16,
			1199.217,
			1201.219,
			1204.199,
			1206.146,
			1207.11,
			1209.016,
			1210.887,
			1211.812,
			1213.633,
			1215.417,
			1217.171,
			1219.741,
			1221.404,
			1223.048,
			1223.865,
			1226.289,
			1227.888,
			1229.484,
			1231.885,
			1233.504,
			1236.765,
			1237.584,
			1240.04,
			1241.682,
			1244.125,
			1245.734,
			1248.134,
			1249.736,
			1250.534,
			1252.117,
			1254.483,
			1256.072,
			1257.635,
			1259.954,
			1261.489,
			1263.754,
			1264.504,
			1265.27,
			1267.544,
			1269.045,
			1270.547,
			1272.85,
			1274.381,
			1276.688,
			1277.465,
			1278.242,
			1281.348,
			1282.12,
			1284.432,
			1285.973,
			1287.498,
			1288.251,
			1289.757,
			1290.509,
			1292.015,
			1294.261,
			1295.759,
			1297.26,
			1299.493,
			1300.987,
			1303.239,
			1303.982,
			1304.728,
			1306.973,
			1308.493,
			1310.016,
			1312.335,
			1313.888,
			1314.671,
			1316.252,
			1317.047,
			1317.845,
			1321.075,
			1321.892,
			1324.363,
			1326.039,
			1328.572,
			1330.287,
			1332.028,
			1332.919,
			1335.575,
			1337.367,
			1339.177,
			1341.924,
			1343.768,
			1346.548,
			1347.488,
			1348.429,
			1351.278,
			1353.187,
			1356.084,
			1358.034,
			1360.986,
			1362.972,
			1363.953,
			1365.957,
			1367.973,
			1368.986,
			1370.001,
			1373.081,
			1375.121,
			1377.192,
			1378.232,
			1380.321,
			1382.429,
			1383.487,
			1386.659,
			1388.776,
			1390.904,
			1394.106,
			1396.248,
			1400.559,
			1401.642,
			1404.906,
			1407.094,
			1410.393,
			1412.607,
			1415.944,
			1418.184,
			1419.308,
			1421.561,
			1424.964,
			1427.265,
			1429.541,
			1433.03,
			1434.196,
			1435.384,
			1438.946,
			1441.329,
			1443.736,
			1444.942,
			1447.364,
			1449.826,
			1453.538,
			1456.037,
			1459.745,
			1460.975,
			1462.225,
			1465.878,
			1468.299,
			1471.917,
			1474.326,
			1476.735,
			1477.939,
			1480.343,
			1482.744,
			1483.946,
			1487.522,
			1489.923,
			1492.319,
			1495.938,
			1498.318,
			1501.925,
			1503.13,
			1504.338,
			1507.97,
			1510.402,
			1514.065,
			1516.519,
			1518.981,
			1522.692,
			1525.173,
			1530.156,
			1531.406,
			1535.172,
			1537.693,
			1541.49,
			1544.031,
			1546.579,
			1547.856,
			1550.417,
			1551.721,
			1554.272,
			1556.853,
			1560.739,
			1563.359,
			1567.255,
			1568.555,
			1569.857,
			1573.751,
			1576.369,
			1580.337,
			1582.977,
			1586.97,
			1589.65,
			1591.006,
			1593.7,
			1596.463,
			1597.839,
			1599.218,
			1603.338,
			1606.084,
			1608.831,
			1610.207,
			1613,
			1615.801,
			1617.214,
			1621.453,
			1624.273,
			1627.092,
			1631.325,
			1634.115,
			1639.571,
			1640.91,
			1644.863,
			1647.485,
			1651.4,
			1653.996,
			1657.888,
			1660.481,
			1661.775,
			1664.315,
			1668.076,
			1670.566,
			1673.044,
			1677.977,
			1679.206,
			1684.114,
			1685.357,
			1689.001,
			1691.438,
			1695.088,
			1697.523,
			1699.959,
			1703.629,
			1704.854,
			1706.081,
			1709.772,
			1712.24,
			1715.957,
			1718.445,
			1720.943,
			1722.196,
			1725.968,
			1731.03,
			1732.302,
			1734.852,
			1737.411,
			1741.267,
			1743.848,
			1747.758,
			1749.058,
			1750.36,
			1754.278,
			1756.896,
			1760.839,
			1763.473,
			1766.114,
			1767.436,
			1770.061,
			1771.386,
			1772.713,
			1778.024,
			1783.347,
			1786.009,
			1789.988,
			1792.617,
			1793.922,
			1796.507,
			1799.05,
			1800.31,
			1802.8,
			1805.254,
			1811.276,
			1814.823,
			1815.993,
			1817.156,
			1820.6,
			1822.859,
			1826.185,
			1828.363,
			1831.569,
			1833.665,
			1834.7,
			1835.729,
			1838.763,
			1839.758,
			1841.724,
			1843.656,
			1845.555,
			1848.357,
			1850.199,
			1852.03,
			1852.944,
			1855.683,
			1857.494,
			1860.213,
			1862.008,
			1866.47,
			1868.244,
			1870.886,
			1871.759,
			1872.63,
			1875.231,
			1876.949,
			1879.51,
			1882.919,
			1883.771,
			1885.474,
			1886.326,
			1888.027,
			1890.558,
			1892.244,
			1893.925,
			1896.425,
			1898.085,
			1899.74,
			1901.387,
			1902.208,
			1903.845,
			1904.669,
			1906.334,
			1907.995,
			1910.514,
			1912.23,
			1914.811,
			1915.702,
			1916.593,
			1919.27,
			1921.037,
			1923.677,
			1925.439,
			1927.221,
			1928.113,
			1929.897,
			1932.632,
			1934.469,
			1935.385,
			1937.246,
			1939.12,
			1941.938,
			1943.886,
			1946.804,
			1947.778,
			1948.758,
			1951.702,
			1953.67,
			1955.619,
			1960.365,
			1963.067,
			1963.971,
			1964.846,
			1968.338,
			1969.216,
			1971.849,
			1973.614,
			1976.273,
			1978.051,
			1978.944,
			1980.737,
			1983.431,
			1985.238,
			1987.059,
			1989.839,
			1991.705,
			1994.53,
			1995.478,
			1996.429,
			2001.233,
			2004.152,
			2006.109,
			2009.045,
			2011.021,
			2012.016,
			2014.013,
			2016.02,
			2017.027,
			2018.023,
			2021.016,
			2023.035,
			2025.092,
			2026.12,
			2028.194,
			2030.318,
			2031.383,
			2034.604,
			2038.945,
			2042.214,
			2044.401,
			2047.669,
			2048.769,
			2049.87,
			2053.185,
			2055.404,
			2058.749,
			2060.987,
			2063.234,
			2064.362,
			2066.619,
			2070.021,
			2073.429,
			2075.711,
			2077.999,
			2080.315,
			2083.788,
			2086.116,
			2088.435,
			2089.608,
			2091.958,
			2093.136,
			2095.498,
			2097.867,
			2101.462,
			2103.846,
			2107.488,
			2108.716,
			2109.945,
			2113.67,
			2116.175,
			2119.958,
			2125.049,
			2126.33,
			2128.907,
			2132.803,
			2135.443,
			2136.767,
			2139.425,
			2142.096,
			2146.099,
			2148.77,
			2154.062,
			2155.382,
			2159.341,
			2161.987,
			2164.641,
			2168.621,
			2171.278,
			2176.587,
			2177.916,
			2181.913,
			2184.586,
			2188.61,
			2191.302,
			2195.353,
			2198.064,
			2202.15,
			2206.261,
			2209.017,
			2211.782,
			2215.952,
			2218.743,
			2224.379,
			2225.794,
			2230.087,
			2232.902,
			2237.144,
			2239.915,
			2242.645,
			2246.664,
			2249.288,
			2253.145,
			2254.41,
			2255.666,
			2259.366,
			2261.785,
			2264.17,
			2265.351,
			2269.994,
			2271.136,
			2274.513,
			2276.733,
			2278.938,
			2282.231,
			2284.429,
			2287.74,
			2288.851,
			2289.967,
			2293.321,
			2295.56,
			2298.909,
			2301.128,
			2304.421,
			2306.584,
			2307.637,
			2309.755,
			2312.875,
			2313.902,
			2316.935,
			2319.907,
			2321.868,
			2323.824,
			2325.775,
			2326.745,
			2328.67,
			2331.532,
			2333.401,
			2336.164,
			2337.978,
			2340.712,
			2341.619,
			2342.525,
			2345.245,
			2347.033,
			2349.695,
			2351.462,
			2354.112,
			2355.87,
			2356.746,
			2358.518,
			2361.183,
			2362.951,
			2364.746,
			2367.439,
			2368.334,
			2369.229,
			2372.786,
			2373.673,
			2375.449,
			2376.342,
			2378.125,
			2380.798,
			2382.609,
			2384.422,
			2388.042,
			2388.941,
			2391.643,
			2393.445,
			2396.143,
			2397.946,
			2399.764,
			2400.675,
			2402.498,
			2405.272,
			2408.093,
			2409.98,
			2411.9,
			2414.777,
			2416.672,
			2419.495,
			2420.436,
			2421.377,
			2424.197,
			2426.075,
			2428.912,
			2430.805,
			2432.695,
			2433.634,
			2435.508,
			2436.445,
			2437.382,
			2440.176,
			2441.105,
			2442.034,
			2444.808,
			2446.64,
			2449.379,
			2451.198,
			2453.02,
			2453.934,
			2455.77,
			2458.526,
			2460.357,
			2463.102,
			2464.919,
			2467.646,
			2468.554,
			2469.457,
			2472.16,
			2473.984,
			2476.772,
			2478.624,
			2481.483,
			2484.32,
			2486.211,
			2488.083,
			2491.075,
			2493.108,
			2495.115,
			2497.093,
			2498.078,
			2500.042,
			2501.941,
			2502.882,
			2505.667,
			2509.143,
			2511.702,
			2513.413,
			2515.969,
			2516.811,
			2517.655,
			2520.203,
			2521.902,
			2524.452,
			2526.164,
			2528.74,
			2532.221,
			2533.105,
			2535.774,
			2537.572,
			2539.386,
			2542.119,
			2543.934,
			2547.581,
			2548.508,
			2551.293,
			2553.156,
			2555.052,
			2557.907,
			2559.797,
			2562.674,
			2563.639,
			2564.611,
			2568.527,
			2569.515,
			2572.499,
			2576.51,
			2577.517,
			2579.54,
			2581.57,
			2582.588,
			2584.633,
			2585.659,
			2587.721,
			2589.793,
			2592.926,
			2595.031,
			2598.197,
			2600.312,
			2603.501,
			2605.636,
			2608.856,
			2611.009,
			2613.168,
			2619.688,
			2621.878,
			2622.977,
			2624.08,
			2627.402,
			2629.633,
			2633.009,
			2635.265,
			2637.527,
			2638.661,
			2642.085,
			2644.385,
			2646.695,
			2650.181,
			2652.52,
			2656.045,
			2657.217,
			2658.392,
			2661.926,
			2664.285,
			2667.838,
			2670.215,
			2673.796,
			2676.194,
			2679.813,
			2684.682,
			2685.909,
			2689.604,
			2690.84,
			2695.82,
			2697.076,
			2700.858,
			2705.945,
			2709.715,
			2712.254,
			2713.527,
			2716.077,
			2717.355,
			2719.917,
			2722.471,
			2726.318,
			2728.894,
			2734.078,
			2735.379,
			2739.288,
			2741.899,
			2745.816,
			2751.065,
			2752.383,
			2756.349,
			2759.002,
			2761.664,
			2762.998,
			2764.332,
			2768.364,
			2772.362,
			2775.007,
			2777.635,
			2780.226,
			2781.488,
			2785.277,
			2787.749,
			2790.175,
			2793.741,
			2796.072,
			2799.497,
			2800.62,
			2801.732,
			2805.008,
			2807.146,
			2810.275,
			2812.314,
			2815.299,
			2818.193,
			2820.09,
			2821.93,
			2822.836,
			2824.621,
			2826.369,
			2828.919,
			2829.75,
			2830.569,
			2832.965,
			2833.744,
			2834.514,
			2836.763,
			2838.219,
			2840.359,
			2841.757,
			2843.134,
			2845.204,
			2845.896,
			2846.591,
			2848.69,
			2850.098,
			2852.234,
			2853.687,
			2855.147,
			2855.879,
			2857.346,
			2858.088,
			2859.605,
			2861.885,
			2863.406,
			2867.268,
			2868.811,
			2871.915,
			2872.688,
			2875.002,
			2876.542,
			2878.853,
			2881.966,
			2882.749,
			2884.319,
			2885.107,
			2885.883,
			2888.954,
			2889.727,
			2892.048,
			2893.587,
			2895.907,
			2897.465,
			2899.039,
			2899.829,
			2901.412,
			2902.207,
			2903.794,
			2905.374,
			2907.758,
			2909.357,
			2911.772,
			2912.582,
			2913.394,
			2915.854,
			2917.522,
			2920.047,
			2921.748,
			2923.453,
			2924.31,
			2926.905,
			2927.785,
			2930.454,
			2931.351,
			2933.162,
			2935,
			2937.787,
			2939.662,
			2940.606,
			2942.508,
			2943.466,
			2945.377,
			2947.302,
			2950.218,
			2952.185,
			2954.166,
			2957.182,
			2960.24,
			2962.278,
			2964.353,
			2967.491,
			2969.584,
			2972.712,
			2973.748,
			2974.776,
			2979.83,
			2982.758,
			2984.668,
			2987.455,
			2989.282,
			2990.199,
			2991.977,
			2994.584,
			2996.279,
			2997.939,
			3000.37,
			3001.163,
			3001.948,
			3003.499,
			3005.029,
			3005.788,
			3008.036,
			3009.519,
			3010.993,
			3013.206,
			3014.684,
			3017.64,
			3018.381,
			3021.41,
			3022.168,
			3024.446,
			3025.99,
			3027.54,
			3028.314,
			3029.86,
			3031.428,
			3032.233,
			3034.636,
			3036.228,
			3037.877,
			3040.349,
			3041.973,
			3044.728,
			3046.548,
			3049.069,
			3050.678,
			3053.062,
			3054.539,
			3055.948,
			3058.072,
			3058.782,
			3059.45,
			3062.104,
			3062.766,
			3064.762,
			3066.105,
			3067.45,
			3068.122,
			3070.809,
			3071.48,
			3072.827,
			3073.502,
			3074.857,
			3076.209,
			3078.239,
			3079.6,
			3081.66,
			3082.35,
			3083.04,
			3085.124,
			3086.527,
			3088.656,
			3090.094,
			3091.542,
			3092.272,
			3093.741,
			3094.482,
			3095.981,
			3098.256,
			3099.792,
			3101.345,
			3102.922,
			3105.312,
			3106.926,
			3108.559,
			3109.38,
			3111.866,
			3113.538,
			3115.208,
			3117.728,
			3119.425,
			3121.995,
			3122.857,
			3123.722,
			3126.334,
			3128.091,
			3130.745,
			3132.53,
			3135.212,
			3137.017,
			3137.919,
			3139.715,
			3141.491,
			3142.369,
			3144.099,
			3145.793,
			3148.266,
			3149.869,
			3152.208,
			3152.969,
			3153.722,
			3155.927,
			3157.354,
			3158.745,
			3160.769,
			3162.082,
			3163.996,
			3164.618,
			3165.232,
			3167.045,
			3167.64,
			3168.23,
			3169.973,
			3171.119,
			3172.265,
			3172.838,
			3175.122,
			3175.691,
			3177.397,
			3178.538,
			3179.681,
			3181.392,
			3182.532,
			3184.245,
			3185.365,
			3187.027,
			3188.132,
			3189.787,
			3190.875,
			3192.466,
			3193.522,
			3194.049,
			3195.103,
			3196.701,
			3197.25,
			3198.894,
			3199.987,
			3201.083,
			3201.631,
			3203.959,
			3204.544,
			3205.714,
			3206.299,
			3207.494,
			3208.83,
			3210.827,
			3212.156,
			3214.369,
			3215.115,
			3215.861,
			3218.098,
			3219.899,
			3222.627,
			3224.345,
			3225.972,
			3226.782,
			3230.739,
			3232.254,
			3234.508,
			3235.998,
			3237.473,
			3239.67,
			3241.056,
			3242.976,
			3244.873,
			3246.098,
			3247.22,
			3248.884,
			3249.988,
			3251.629,
			3252.167,
			3252.704,
			3254.856,
			3255.396,
			3257.026,
			3258.123,
			3259.779,
			3260.895,
			3261.456,
			3262.629,
			3263.816,
			3264.413,
			3265.618,
			3266.836,
			3269.38,
			3270.031,
			3271.998,
			3272.659,
			3273.324,
			3275.366,
			3276.758,
			3280.284,
			3281.708,
			3283.878,
			3284.603,
			3285.333,
			3287.545,
			3288.303,
			3289.04,
			3291.302,
			3292.83,
			3294.374,
			3295.153,
			3296.723,
			3297.513,
			3299.105,
			3301.516,
			3303.141,
			3304.781,
			3307.258,
			3308.925,
			3311.426,
			3313.096,
			3315.628,
			3317.337,
			3319.927,
			3321.67,
			3323.428,
			3326.983,
			3327.88,
			3331.497,
			3332.41,
			3335.167,
			3337.021,
			3338.889,
			3339.829,
			3341.718,
			3344.576,
			3346.495,
			3349.381,
			3351.303,
			3354.162,
			3356.044,
			3358.813,
			3359.72,
			3360.62,
			3363.264,
			3364.982,
			3367.496,
			3370.721,
			3371.507,
			3373.06,
			3375.353,
			3377.635,
			3380.641,
			3382.884,
			3384.372,
			3386.595,
			3387.32,
			3388.044,
			3390.209,
			3391.649,
			3393.063,
			3395.161,
			3396.558,
			3398.663,
			3400.067,
			3402.175,
			3403.576,
			3405.668,
			3409.188,
			3410.614,
			3411.327,
			3412.752,
			3414.212,
			3414.945,
			3416.407,
			3417.869,
			3420.091,
			3420.831,
			3421.571,
			3423.791,
			3424.544,
			3425.295,
			3427.538,
			3429.036,
			3431.301,
			3432.803,
			3434.318,
			3436.633,
			3438.166,
			3440.491,
			3442.04,
			3444.355,
			3445.911,
			3448.225,
			3449.775,
			3450.564,
			3452.132,
			3454.47,
			3456.029,
			3457.577,
			3459.889,
			3461.443,
			3463.764,
			3465.304,
			3467.556,
			3469.055,
			3471.293,
			3472.778,
			3475.014,
			3476.504,
			3478.739,
			3480.233,
			3481.727,
			3483.967,
			3485.465,
			3486.96,
			3487.704,
			3490.682,
			3491.427,
			3493.656,
			3495.142,
			3496.628,
			3498.869,
			3500.359,
			3502.596,
			3504.089,
			3506.319,
			3507.801,
			3510.02,
			3511.494,
			3512.957,
			3513.711,
			3515.198,
			3515.943,
			3517.433,
			3519.663,
			3521.17,
			3522.685,
			3524.212,
			3526.527,
			3529.655,
			3530.445,
			3532.035,
			3534.443,
			3536.059,
			3540.15,
			3542.637,
			3543.473,
			3544.311,
			3546.849,
			3548.559,
			3551.146,
			3552.89,
			3555.528,
			3557.302,
			3559.986,
			3561.79,
			3564.515,
			3566.346,
			3569.112,
			3570.969,
			3573.778,
			3574.72,
			3575.665,
			3578.52,
			3580.437,
			3582.384,
			3585.297,
			3587.24,
			3591.134,
			3592.095,
			3595.021,
			3596.972,
			3599.913,
			3601.887,
			3604.875,
			3607.899,
			3609.932,
			3613.011,
			3615.077,
			3617.157,
			3620.296,
			3622.392,
			3627.651,
			3630.836,
			3632.972,
			3636.195,
			3638.356,
			3641.616,
			3643.802,
			3646,
			3650.428,
			3651.542,
			3654.9,
			3657.152,
			3659.414,
			3660.549,
			3662.824,
			3665.108,
			3666.254,
			3669.704,
			3674.337,
			3677.841,
			3680.189,
			3683.733,
			3686.107,
			3689.692,
			3692.094,
			3695.727,
			3700.608,
			3701.833,
			3704.286,
			3707.978,
			3711.678,
			3712.916,
			3716.627,
			3719.11,
			3722.848,
			3725.353,
			3729.128,
			3731.658,
			3732.926,
			3735.472,
			3738.029,
			3741.88,
			3744.458,
			3748.343,
			3749.642,
			3750.942,
			3754.852,
			3757.46,
			3761.384,
			3764.01,
			3767.961,
			3770.604,
			3771.929,
			3774.583,
			3777.244,
			3778.576,
			3781.247,
			3783.925,
			3787.958,
			3789.306,
			3790.655,
			3794.715,
			3796.072,
			3797.245
		],
		[
			0,
			0.059,
			0.105,
			0.168,
			0.228,
			0.292,
			0.384,
			0.414,
			0.477,
			0.538,
			0.601,
			0.663,
			0.711,
			0.726,
			0.788,
			0.819,
			0.849,
			0.926,
			0.973,
			1.034,
			1.096,
			1.148,
			1.16,
			1.221,
			1.251,
			1.284,
			1.347,
			1.408,
			1.47,
			1.533,
			1.595,
			1.687,
			1.717,
			1.796,
			1.843,
			1.904,
			2.012,
			2.028,
			2.15,
			2.213,
			2.23,
			2.277,
			2.339,
			2.399,
			2.461,
			2.522,
			2.553,
			2.584,
			2.663,
			2.71,
			2.773,
			2.833,
			2.881,
			2.959,
			2.988,
			3.021,
			3.096,
			3.143,
			3.206,
			3.27,
			3.328,
			3.39,
			3.453,
			3.515,
			3.531,
			3.578,
			3.64,
			3.701,
			3.747,
			3.764,
			3.827,
			3.889,
			3.965,
			4.01,
			4.073,
			4.134,
			4.198,
			4.261,
			4.291,
			4.323,
			4.384,
			4.445,
			4.507,
			4.568,
			4.618,
			4.631,
			4.694,
			4.725,
			4.756,
			4.834,
			4.881,
			5.004,
			5.066,
			5.128,
			5.16,
			5.19,
			5.253,
			5.313,
			5.377,
			5.438,
			5.501,
			5.562,
			5.592,
			5.625,
			5.702,
			5.749,
			5.809,
			5.873,
			5.918,
			5.995,
			6.027,
			6.059,
			6.121,
			6.137,
			6.181,
			6.247,
			6.307,
			6.369,
			6.429,
			6.463,
			6.492,
			6.571,
			6.615,
			6.677,
			6.741,
			6.787,
			6.8,
			6.864,
			6.927,
			6.988,
			7.005,
			7.052,
			7.113,
			7.174,
			7.237,
			7.299,
			7.361,
			7.437,
			7.483,
			7.548,
			7.609,
			7.656,
			7.734,
			7.764,
			7.794,
			7.857,
			7.917,
			7.98,
			8.042,
			8.105,
			8.166,
			8.198,
			8.228,
			8.29,
			8.307,
			8.354,
			8.414,
			8.477,
			8.523,
			8.538,
			8.633,
			8.663,
			8.74,
			8.788,
			8.85,
			8.911,
			8.973,
			9.036,
			9.066,
			9.098,
			9.158,
			9.219,
			9.282,
			9.343,
			9.407,
			9.467,
			9.501,
			9.53,
			9.609,
			9.655,
			9.779,
			9.842,
			9.901,
			9.935,
			9.966,
			10.042,
			10.089,
			10.153,
			10.212,
			10.275,
			10.367,
			10.398,
			10.477,
			10.523,
			10.586,
			10.646,
			10.695,
			10.708,
			10.771,
			10.803,
			10.832,
			10.895,
			10.91,
			10.957,
			11.018,
			11.082,
			11.144,
			11.205,
			11.237,
			11.267,
			11.344,
			11.392,
			11.459,
			11.562,
			11.577,
			11.672,
			11.701,
			11.763,
			11.825,
			11.888,
			11.95,
			12.01,
			12.105,
			12.134,
			12.212,
			12.259,
			12.322,
			12.43,
			12.538,
			12.57,
			12.645,
			12.694,
			12.757,
			12.818,
			12.88,
			12.943,
			12.974,
			13.005,
			13.08,
			13.126,
			13.189,
			13.253,
			13.299,
			13.314,
			13.377,
			13.408,
			13.439,
			13.5,
			13.565,
			13.628,
			13.684,
			13.748,
			13.764,
			13.81,
			13.872,
			13.935,
			13.995,
			14.088,
			14.119,
			14.196,
			14.244,
			14.305,
			14.366,
			14.414,
			14.43,
			14.524,
			14.554,
			14.632,
			14.677,
			14.74,
			14.8,
			14.862,
			14.926,
			14.957,
			14.988,
			15.066,
			15.11,
			15.174,
			15.234,
			15.283,
			15.359,
			15.392,
			15.482,
			15.546,
			15.612,
			15.668,
			15.731,
			15.795,
			15.856,
			15.935,
			15.979,
			16.042,
			16.104,
			16.151,
			16.227,
			16.258,
			16.289,
			16.367,
			16.413,
			16.475,
			16.538,
			16.599,
			16.661,
			16.723,
			16.787,
			16.848,
			16.911,
			16.972,
			17.034,
			17.095,
			17.127,
			17.157,
			17.236,
			17.282,
			17.345,
			17.407,
			17.468,
			17.561,
			17.592,
			17.655,
			17.675,
			17.716,
			17.778,
			17.84,
			17.885,
			17.903,
			17.996,
			18.025,
			18.104,
			18.149,
			18.212,
			18.334,
			18.399,
			18.43,
			18.461,
			18.522,
			18.538,
			18.583,
			18.646,
			18.708,
			18.755,
			18.862,
			18.896,
			18.971,
			19.02,
			19.08,
			19.144,
			19.188,
			19.204,
			19.298,
			19.329,
			19.389,
			19.452,
			19.514,
			19.576,
			19.639,
			19.699,
			19.762,
			19.841,
			19.887,
			19.95,
			20.01,
			20.056,
			20.166,
			20.196,
			20.258,
			20.322,
			20.382,
			20.445,
			20.507,
			20.601,
			20.629,
			20.707,
			20.756,
			20.816,
			20.925,
			21.001,
			21.033,
			21.065,
			21.126,
			21.188,
			21.252,
			21.312,
			21.375,
			21.438,
			21.466,
			21.499,
			21.577,
			21.623,
			21.686,
			21.746,
			21.799,
			21.81,
			21.871,
			21.933,
			22.012,
			22.058,
			22.119,
			22.181,
			22.242,
			22.305,
			22.337,
			22.368,
			22.428,
			22.492,
			22.554,
			22.616,
			22.662,
			22.676,
			22.739,
			22.771,
			22.803,
			22.878,
			22.988,
			23.048,
			23.11,
			23.173,
			23.233,
			23.296,
			23.313,
			23.36,
			23.422,
			23.482,
			23.546,
			23.608,
			23.638,
			23.667,
			23.746,
			23.792,
			23.859,
			23.916,
			23.98,
			24.074,
			24.103,
			24.166,
			24.181,
			24.227,
			24.29,
			24.353,
			24.412,
			24.477,
			24.505,
			24.539,
			24.616,
			24.66,
			24.723,
			24.786,
			24.846,
			24.91,
			24.941,
			24.971,
			25.033,
			25.096,
			25.159,
			25.218,
			25.282,
			25.344,
			25.405,
			25.483,
			25.529,
			25.593,
			25.654,
			25.701,
			25.84,
			25.9,
			25.965,
			26.026,
			26.089,
			26.149,
			26.213,
			26.241,
			26.274,
			26.353,
			26.399,
			26.46,
			26.522,
			26.584,
			26.676,
			26.708,
			26.771,
			26.831,
			26.894,
			26.957,
			27.001,
			27.11,
			27.143,
			27.218,
			27.267,
			27.328,
			27.39,
			27.452,
			27.515,
			27.545,
			27.577,
			27.639,
			27.651,
			27.701,
			27.764,
			27.824,
			27.886,
			27.949,
			27.983,
			28.01,
			28.088,
			28.132,
			28.198,
			28.258,
			28.304,
			28.412,
			28.445,
			28.507,
			28.569,
			28.634,
			28.692,
			28.755,
			28.815,
			28.846,
			28.879,
			28.957,
			29,
			29.066,
			29.125,
			29.188,
			29.251,
			29.282,
			29.311,
			29.395,
			29.443,
			29.498,
			29.559,
			29.622,
			29.686,
			29.716,
			29.746,
			29.825,
			29.869,
			29.934,
			29.993,
			30.046,
			30.057,
			30.12,
			30.149,
			30.18,
			30.259,
			30.304,
			30.368,
			30.43,
			30.49,
			30.551,
			30.583,
			30.614,
			30.675,
			30.738,
			30.799,
			30.863,
			30.909,
			30.926,
			30.988,
			31.017,
			31.049,
			31.126,
			31.173,
			31.236,
			31.298,
			31.36,
			31.421,
			31.451,
			31.482,
			31.56,
			31.607,
			31.671,
			31.73,
			31.793,
			31.855,
			31.884,
			31.916,
			31.993,
			32.04,
			32.107,
			32.165,
			32.227,
			32.29,
			32.321,
			32.35,
			32.414,
			32.427,
			32.474,
			32.538,
			32.598,
			32.645,
			32.661,
			32.723,
			32.753,
			32.785,
			32.862,
			32.908,
			32.971,
			33.032,
			33.095,
			33.188,
			33.218,
			33.296,
			33.344,
			33.406,
			33.467,
			33.528,
			33.592,
			33.622,
			33.653,
			33.732,
			33.777,
			33.84,
			33.901,
			33.947,
			33.964,
			34.026,
			34.088,
			34.148,
			34.173,
			34.211,
			34.273,
			34.333,
			34.398,
			34.46,
			34.49,
			34.521,
			34.598,
			34.645,
			34.707,
			34.769,
			34.817,
			34.831,
			34.892,
			34.925,
			34.955,
			35.033,
			35.08,
			35.14,
			35.201,
			35.264,
			35.327,
			35.358,
			35.391,
			35.467,
			35.515,
			35.576,
			35.637,
			35.683,
			35.762,
			35.824,
			35.901,
			35.949,
			36.01,
			36.072,
			36.135,
			36.196,
			36.23,
			36.258,
			36.32,
			36.336,
			36.38,
			36.443,
			36.507,
			36.55,
			36.63,
			36.66,
			36.692,
			36.767,
			36.816,
			36.879,
			36.938,
			37.001,
			37.062,
			37.095,
			37.125,
			37.189,
			37.201,
			37.249,
			37.314,
			37.374,
			37.421,
			37.437,
			37.496,
			37.528,
			37.56,
			37.637,
			37.682,
			37.746,
			37.807,
			37.87,
			37.963,
			37.993,
			38.056,
			38.116,
			38.181,
			38.243,
			38.305,
			38.366,
			38.397,
			38.428,
			38.505,
			38.551,
			38.614,
			38.723,
			38.738,
			38.83,
			38.861,
			38.925,
			38.987,
			39.051,
			39.111,
			39.171,
			39.235,
			39.265,
			39.295,
			39.373,
			39.418,
			39.482,
			39.545,
			39.59,
			39.606,
			39.699,
			39.793,
			39.808,
			39.854,
			39.917,
			39.977,
			40.039,
			40.103,
			40.133,
			40.163,
			40.225,
			40.288,
			40.358,
			40.413,
			40.458,
			40.475,
			40.535,
			40.567,
			40.599,
			40.677,
			40.784,
			40.847,
			40.908,
			40.968,
			40.999,
			41.033,
			41.095,
			41.109,
			41.157,
			41.217,
			41.327,
			41.406,
			41.437,
			41.467,
			41.544,
			41.59,
			41.651,
			41.716,
			41.776,
			41.838,
			41.871,
			41.9,
			41.962,
			41.977,
			42.025,
			42.088,
			42.15,
			42.21,
			42.272,
			42.303,
			42.334,
			42.418,
			42.458,
			42.53,
			42.584,
			42.707,
			42.769,
			42.829,
			42.862,
			42.893,
			42.971,
			43.017,
			43.079,
			43.185,
			43.204,
			43.266,
			43.295,
			43.326,
			43.405,
			43.453,
			43.513,
			43.576,
			43.637,
			43.698,
			43.73,
			43.76,
			43.823,
			43.838,
			43.883,
			43.946,
			44.009,
			44.07,
			44.133,
			44.164,
			44.195,
			44.272,
			44.318,
			44.385,
			44.443,
			44.492,
			44.505,
			44.567,
			44.629,
			44.691,
			44.705,
			44.751,
			44.815,
			44.877,
			44.94,
			45.001,
			45.031,
			45.064,
			45.139,
			45.188,
			45.249,
			45.357,
			45.434,
			45.465,
			45.496,
			45.575,
			45.622,
			45.688,
			45.744,
			45.808,
			45.867,
			45.899,
			45.932,
			46.01,
			46.054,
			46.118,
			46.178,
			46.24,
			46.303,
			46.332,
			46.365,
			46.49,
			46.553,
			46.614,
			46.675,
			46.738,
			46.767,
			46.801,
			46.861,
			46.876,
			46.923,
			46.986,
			47.046,
			47.095,
			47.109,
			47.171,
			47.201,
			47.232,
			47.31,
			47.418,
			47.48,
			47.542,
			47.606,
			47.637,
			47.668,
			47.73,
			47.792,
			47.853,
			47.914,
			47.962,
			47.978,
			48.039,
			48.102,
			48.179,
			48.225,
			48.289,
			48.349,
			48.411,
			48.473,
			48.505,
			48.533,
			48.599,
			48.616,
			48.66,
			48.723,
			48.784,
			48.846,
			48.908,
			48.938,
			48.968,
			49.048,
			49.095,
			49.155,
			49.265,
			49.28,
			49.341,
			49.405,
			49.464,
			49.482,
			49.527,
			49.589,
			49.651,
			49.713,
			49.806,
			49.838,
			49.915,
			49.961,
			50.023,
			50.088,
			50.133,
			50.241,
			50.271,
			50.333,
			50.395,
			50.459,
			50.521,
			50.581,
			50.643,
			50.707,
			50.783,
			50.829,
			50.892,
			50.954,
			51,
			51.11,
			51.14,
			51.2,
			51.265,
			51.327,
			51.388,
			51.449,
			51.511,
			51.573,
			51.636,
			51.65,
			51.699,
			51.759,
			51.822,
			51.869,
			51.885,
			51.977,
			52.007,
			52.085,
			52.132,
			52.195,
			52.257,
			52.319,
			52.379,
			52.412,
			52.443,
			52.504,
			52.565,
			52.629,
			52.691,
			52.753,
			52.813,
			52.846,
			52.876,
			52.955,
			52.999,
			53.066,
			53.125,
			53.186,
			53.248,
			53.279,
			53.311,
			53.371,
			53.435,
			53.496,
			53.56,
			53.605,
			53.683,
			53.714,
			53.745,
			53.823,
			53.871,
			53.931,
			53.993,
			54.055,
			54.116,
			54.146,
			54.178,
			54.257,
			54.301,
			54.364,
			54.426,
			54.474,
			54.489,
			54.582,
			54.612,
			54.673,
			54.69,
			54.737,
			54.806,
			54.86,
			54.922,
			55.015,
			55.048,
			55.125,
			55.171,
			55.234,
			55.294,
			55.343,
			55.357,
			55.42,
			55.482,
			55.56,
			55.605,
			55.666,
			55.73,
			55.791,
			55.853,
			55.882,
			55.915,
			55.993,
			56.04,
			56.102,
			56.163,
			56.209,
			56.226,
			56.288,
			56.319,
			56.348,
			56.41,
			56.426,
			56.474,
			56.539,
			56.598,
			56.66,
			56.721,
			56.753,
			56.783,
			56.844,
			56.906,
			56.97,
			57.032,
			57.077,
			57.155,
			57.187,
			57.217,
			57.294,
			57.342,
			57.401,
			57.464,
			57.526,
			57.62,
			57.651,
			57.713,
			57.775,
			57.839,
			57.898,
			57.946,
			57.962,
			58.025,
			58.055,
			58.084,
			58.164,
			58.271,
			58.335,
			58.394,
			58.458,
			58.488,
			58.518,
			58.58,
			58.643,
			58.705,
			58.768,
			58.83,
			58.927,
			58.953,
			59.031,
			59.079,
			59.14,
			59.202,
			59.263,
			59.358,
			59.388,
			59.45,
			59.511,
			59.574,
			59.635,
			59.681,
			59.758,
			59.79,
			59.822,
			59.9,
			59.946,
			60.008,
			60.117,
			60.13,
			60.192,
			60.226,
			60.254,
			60.318,
			60.334,
			60.378,
			60.442,
			60.501,
			60.565,
			60.627,
			60.689,
			60.769,
			60.812,
			60.876,
			60.937,
			60.99,
			61.125,
			61.187,
			61.2,
			61.249,
			61.311,
			61.372,
			61.433,
			61.497,
			61.528,
			61.557,
			61.637,
			61.682,
			61.745,
			61.805,
			61.868,
			61.929,
			61.962,
			61.992,
			62.07,
			62.116,
			62.18,
			62.242,
			62.312,
			62.364,
			62.426,
			62.533,
			62.55,
			62.643,
			62.674,
			62.753,
			62.798,
			62.861,
			62.97,
			63.046,
			63.083,
			63.107,
			63.171,
			63.183,
			63.231,
			63.295,
			63.357,
			63.404,
			63.511,
			63.543,
			63.621,
			63.667,
			63.728,
			63.837,
			63.854,
			63.944,
			63.976,
			64.038,
			64.053,
			64.1,
			64.161,
			64.225,
			64.288,
			64.349,
			64.379,
			64.411,
			64.488,
			64.535,
			64.596,
			64.66,
			64.706,
			64.783,
			64.814,
			64.843,
			64.906,
			64.969,
			65.031,
			65.092,
			65.155,
			65.248,
			65.278,
			65.341,
			65.357,
			65.401,
			65.464,
			65.526,
			65.573,
			65.59,
			65.65,
			65.681,
			65.711,
			65.791,
			65.835,
			65.9,
			65.959,
			66.023,
			66.086,
			66.115,
			66.146,
			66.21,
			66.269,
			66.338,
			66.395,
			66.441,
			66.455,
			66.517,
			66.549,
			66.579,
			66.659,
			66.705,
			66.83,
			66.892,
			66.986,
			67.016,
			67.079,
			67.138,
			67.206,
			67.31,
			67.326,
			67.388,
			67.419,
			67.449,
			67.526,
			67.574,
			67.634,
			67.696,
			67.76,
			67.821,
			67.851,
			67.883,
			67.946,
			67.96,
			68.008,
			68.068,
			68.132,
			68.193,
			68.254,
			68.284,
			68.317,
			68.394,
			68.441,
			68.504,
			68.566,
			68.613,
			68.628,
			68.718,
			68.749,
			68.812,
			68.827,
			68.875,
			68.939,
			69.011,
			69.051,
			69.082,
			69.138,
			69.153,
			69.2,
			69.267,
			69.326,
			69.385,
			69.448,
			69.51,
			69.589,
			69.636,
			69.697,
			69.76,
			69.822,
			69.884,
			69.915,
			69.944,
			70.07,
			70.131,
			70.192,
			70.255,
			70.316,
			70.347,
			70.38,
			70.458,
			70.504,
			70.565,
			70.628,
			70.673,
			70.69,
			70.75,
			70.781,
			70.812,
			70.89,
			70.938,
			70.999,
			71.061,
			71.124,
			71.215,
			71.246,
			71.329,
			71.368,
			71.438,
			71.494,
			71.541,
			71.557,
			71.62,
			71.651,
			71.682,
			71.759,
			71.805,
			71.867,
			71.928,
			71.991,
			72.055,
			72.116,
			72.191,
			72.24,
			72.301,
			72.364,
			72.425,
			72.487,
			72.52,
			72.548,
			72.628,
			72.674,
			72.734,
			72.798,
			72.845,
			72.859,
			72.951,
			72.983,
			73.046,
			73.062,
			73.109,
			73.168,
			73.233,
			73.295,
			73.355,
			73.391,
			73.421,
			73.495,
			73.543,
			73.601,
			73.665,
			73.712,
			73.726,
			73.789,
			73.82,
			73.85,
			73.928,
			73.977,
			74.038,
			74.098,
			74.161,
			74.224,
			74.254,
			74.286,
			74.363,
			74.41,
			74.472,
			74.535,
			74.579,
			74.658,
			74.69,
			74.721,
			74.798,
			74.844,
			74.907,
			74.969,
			75.031,
			75.092,
			75.124,
			75.151,
			75.215,
			75.231,
			75.279,
			75.34,
			75.403,
			75.455,
			75.526,
			75.558,
			75.589,
			75.665,
			75.712,
			75.772,
			75.836,
			75.899,
			75.96,
			75.99,
			76.022,
			76.084,
			76.101,
			76.145,
			76.209,
			76.269,
			76.316,
			76.332,
			76.426,
			76.457,
			76.535,
			76.58,
			76.642,
			76.705,
			76.765,
			76.828,
			76.89,
			76.953,
			77.015,
			77.078,
			77.138,
			77.201,
			77.263,
			77.292,
			77.325,
			77.401,
			77.449,
			77.514,
			77.573,
			77.616,
			77.633,
			77.728,
			77.757,
			77.822,
			77.834,
			77.883,
			77.945,
			78.007,
			78.07,
			78.13,
			78.16,
			78.192,
			78.271,
			78.318,
			78.377,
			78.44,
			78.487,
			78.502,
			78.628,
			78.689,
			78.752,
			78.818,
			78.874,
			78.937,
			78.998,
			79.062,
			79.138,
			79.183,
			79.248,
			79.309,
			79.37,
			79.433,
			79.464,
			79.494,
			79.577,
			79.617,
			79.682,
			79.744,
			79.805,
			79.866,
			79.896,
			79.929,
			79.991,
			80.006,
			80.053,
			80.115,
			80.223,
			80.239,
			80.301,
			80.33,
			80.364,
			80.44,
			80.488,
			80.61,
			80.671,
			80.734,
			80.767,
			80.797,
			80.86,
			80.875,
			80.921,
			80.982,
			81.046,
			81.091,
			81.108,
			81.17,
			81.199,
			81.23,
			81.309,
			81.355,
			81.417,
			81.48,
			81.526,
			81.601,
			81.665,
			81.743,
			81.79,
			81.85,
			81.914,
			81.973,
			82.068,
			82.099,
			82.176,
			82.224,
			82.283,
			82.346,
			82.393,
			82.41,
			82.471,
			82.533,
			82.594,
			82.658,
			82.718,
			82.78,
			82.843,
			82.906,
			82.934,
			82.966,
			83.029,
			83.09,
			83.154,
			83.263,
			83.278,
			83.339,
			83.403,
			83.478,
			83.589,
			83.65,
			83.71,
			83.78,
			83.808,
			83.834,
			83.913,
			83.958,
			84.022,
			84.083,
			84.145,
			84.207,
			84.268,
			84.348,
			84.395,
			84.455,
			84.578,
			84.64,
			84.673,
			84.704,
			84.767,
			84.781,
			84.826,
			84.889,
			84.95,
			84.998,
			85.013,
			85.075,
			85.107,
			85.137,
			85.214,
			85.261,
			85.325,
			85.386,
			85.432,
			85.509,
			85.573,
			85.633,
			85.695,
			85.762,
			85.82,
			85.881,
			85.944,
			85.974,
			86.007,
			86.084,
			86.13,
			86.192,
			86.255,
			86.315,
			86.377,
			86.44,
			86.503,
			86.562,
			86.628,
			86.689,
			86.751,
			86.811,
			86.875,
			86.936,
			86.999,
			87.06,
			87.122,
			87.17,
			87.184,
			87.278,
			87.307,
			87.383,
			87.433,
			87.494,
			87.556,
			87.619,
			87.68,
			87.741,
			87.802,
			87.865,
			87.93,
			87.99,
			88.037,
			88.053,
			88.114,
			88.145,
			88.176,
			88.254,
			88.301,
			88.362,
			88.424,
			88.486,
			88.579,
			88.609,
			88.672,
			88.733,
			88.796,
			88.905,
			88.982,
			89.012,
			89.044,
			89.123,
			89.166,
			89.232,
			89.292,
			89.355,
			89.417,
			89.477,
			89.541,
			89.601,
			89.666,
			89.728,
			89.788,
			89.852,
			89.887,
			89.914,
			89.99,
			90.037,
			90.098,
			90.161,
			90.208,
			90.315,
			90.345,
			90.409,
			90.471,
			90.533,
			90.593,
			90.657,
			90.749,
			90.782,
			90.858,
			90.905,
			90.967,
			91.028,
			91.076,
			91.214,
			91.292,
			91.337,
			91.401,
			91.462,
			91.525,
			91.587,
			91.649,
			91.726,
			91.774,
			91.836,
			91.895,
			91.947,
			91.958,
			92.021,
			92.053,
			92.082,
			92.161,
			92.269,
			92.33,
			92.392,
			92.455,
			92.517,
			92.58,
			92.64,
			92.705,
			92.813,
			92.828,
			92.89,
			92.95,
			93.029,
			93.074,
			93.138,
			93.198,
			93.262,
			93.323,
			93.386,
			93.448,
			93.463,
			93.508,
			93.572,
			93.632,
			93.695,
			93.757,
			93.788,
			93.817,
			93.896,
			93.944,
			94.013,
			94.066,
			94.129,
			94.192,
			94.223,
			94.254,
			94.315,
			94.331,
			94.376,
			94.44,
			94.501,
			94.549,
			94.564,
			94.625,
			94.657,
			94.685
		]
	],
	'262_267_7982': [
		[
			82.724
		],
		[
			0
		],
		[
			0,
			2.835,
			8.527,
			9.954,
			12.814,
			17.119,
			21.44,
			24.329,
			30.126,
			34.491,
			37.409,
			40.334,
			41.799,
			46.203,
			49.148,
			52.099,
			56.538,
			59.505,
			63.965,
			65.455,
			66.946,
			71.425,
			74.418,
			78.918,
			81.924,
			86.444,
			89.465,
			92.491,
			94.006,
			97.042,
			98.561,
			101.606,
			104.657,
			109.245,
			112.315,
			116.936,
			118.481,
			120.027,
			124.686,
			127.805,
			130.931,
			135.64,
			138.787,
			145.104,
			146.688,
			151.453,
			153.045,
			154.639,
			159.427,
			162.624,
			167.424,
			170.63,
			175.444,
			180.263,
			183.482,
			186.705,
			191.55,
			194.787,
			199.651,
			202.898,
			207.774,
			211.027,
			215.91,
			219.169,
			224.062,
			227.329,
			228.965,
			230.6,
			237.156,
			240.439,
			243.725,
			247.042,
			250.333,
			251.98,
			255.246,
			258.54,
			260.188,
			265.137,
			268.441,
			271.747,
			276.712,
			280.025,
			284.995,
			286.653,
			288.31,
			293.283,
			296.602,
			301.587,
			304.915,
			308.247,
			309.915,
			313.253,
			314.924,
			318.269,
			323.292,
			326.644,
			329.999,
			335.037,
			338.399,
			343.45,
			345.135,
			346.82,
			350.194,
			351.879,
			355.25,
			358.618,
			366.969,
			371.891,
			373.514,
			375.127,
			379.913,
			383.058,
			387.72,
			390.791,
			395.309,
			398.255,
			399.714,
			402.61,
			405.472,
			406.888,
			409.69,
			412.455,
			416.535,
			419.208,
			423.151,
			425.735,
			429.546,
			432.042,
			434.501,
			438.123,
			440.495,
			443.992,
			446.28,
			449.645,
			450.749,
			451.843,
			455.072,
			457.179,
			460.275,
			462.293,
			464.277,
			465.256,
			468.137,
			470.03,
			471.871,
			474.571,
			476.329,
			478.908,
			480.58,
			484.635,
			486.977,
			488.521,
			490.825,
			492.353,
			493.117,
			494.685,
			496.266,
			497.055,
			498.632,
			500.318,
			501.998,
			503.673,
			504.542,
			506.289,
			508.027,
			508.894,
			511.605,
			513.412,
			515.273,
			518.132,
			520.034,
			522.88,
			524.765,
			527.308,
			528.999,
			531.418,
			532.992,
			534.567,
			535.352,
			536.918,
			537.701,
			539.266,
			541.601,
			543.173,
			544.728,
			547.095,
			548.679,
			550.261,
			551.954,
			552.805,
			554.505,
			555.354,
			557.216,
			559.089,
			561.997,
			564.009,
			566.997,
			567.982,
			568.966,
			571.795,
			573.501,
			576.054,
			577.595,
			579.878,
			581.397,
			583.634,
			585.123,
			585.869,
			587.375,
			588.894,
			591.182,
			592.722,
			595.051,
			595.83,
			596.61,
			598.982,
			600.575,
			602.985,
			604.612,
			606.248,
			608.729,
			609.564,
			610.401,
			612.934,
			613.787,
			614.643,
			617.229,
			618.97,
			621.605,
			625.145,
			626.041,
			628.748,
			630.568,
			632.399,
			635.17,
			637.032,
			640.794,
			641.742,
			644.602,
			646.523,
			649.427,
			651.377,
			654.323,
			656.303,
			657.297,
			658.294,
			662.313,
			663.324,
			666.378,
			668.429,
			670.49,
			671.525,
			673.603,
			675.692,
			676.739,
			678.833,
			679.879,
			681.977,
			684.083,
			687.26,
			689.39,
			693.678,
			694.755,
			698.002,
			700.178,
			703.458,
			705.657,
			707.864,
			708.97,
			711.191,
			712.304,
			714.538,
			716.782,
			717.907,
			720.165,
			722.431,
			725.849,
			728.139,
			730.438,
			732.747,
			733.905,
			737.394,
			739.731,
			742.077,
			745.613,
			747.983,
			751.554,
			752.749,
			753.946,
			757.552,
			759.967,
			763.607,
			766.046,
			769.722,
			772.183,
			774.654,
			775.892,
			778.376,
			779.621,
			782.118,
			784.624,
			788.398,
			790.925,
			794.731,
			796.004,
			797.279,
			801.118,
			803.689,
			807.561,
			810.154,
			812.756,
			816.664,
			817.969,
			819.274,
			823.203,
			825.833,
			829.792,
			832.442,
			836.432,
			839.102,
			841.78,
			843.121,
			847.154,
			849.848,
			852.542,
			856.567,
			859.227,
			863.168,
			864.466,
			865.755,
			869.564,
			872.055,
			875.721,
			878.118,
			880.48,
			883.958,
			885.118,
			887.372,
			890.686,
			891.776,
			894.996,
			897.118,
			899.212,
			900.255,
			905.437,
			907.505,
			908.543,
			910.618,
			912.709,
			915.937,
			918.148,
			921.666,
			922.867,
			924.167,
			928.088,
			930.767,
			934.683,
			937.192,
			939.547,
			940.677,
			942.944,
			944.043,
			946.18,
			949.412,
			951.553,
			953.703,
			955.857,
			959.11,
			962.421,
			963.521,
			964.66,
			966.957,
			968.096,
			970.444,
			974.002,
			976.371,
			978.747,
			982.221,
			983.36,
			984.488,
			987.69,
			989.767,
			992.7,
			994.627,
			997.413,
			999.245,
			1000.158,
			1001.964,
			1003.764,
			1004.661,
			1006.448,
			1008.219,
			1010.846,
			1012.575,
			1015.126,
			1016.794,
			1019.239,
			1020.811,
			1023.12,
			1024.601,
			1026.77,
			1028.192,
			1028.893,
			1029.589,
			1031.647,
			1032.329,
			1033.008,
			1035.024,
			1036.36,
			1037.695,
			1038.363,
			1039.699,
			1041.037,
			1041.706,
			1043.704,
			1045.037,
			1046.375,
			1048.384,
			1049.727,
			1051.752,
			1052.431,
			1053.108,
			1055.155,
			1056.532,
			1058.614,
			1060.01,
			1062.116,
			1063.535,
			1064.247,
			1064.959,
			1067.818,
			1068.531,
			1070.668,
			1072.093,
			1074.242,
			1077.095,
			1077.805,
			1079.239,
			1081.388,
			1082.819,
			1085.003,
			1086.46,
			1088.647,
			1090.12,
			1092.324,
			1093.797,
			1096.025,
			1097.508,
			1099.75,
			1101.261,
			1103.531,
			1105.079,
			1105.849,
			1107.395,
			1108.954,
			1110.529,
			1112.909,
			1115.292,
			1116.089,
			1116.889,
			1119.303,
			1120.899,
			1122.512,
			1124.947,
			1126.569,
			1129.032,
			1129.855,
			1130.679,
			1133.174,
			1134.848,
			1137.351,
			1139.035,
			1141.582,
			1143.309,
			1144.179,
			1145.947,
			1148.607,
			1150.401,
			1152.213,
			1154.96,
			1155.883,
			1156.81,
			1159.615,
			1160.558,
			1161.503,
			1164.365,
			1166.289,
			1169.2,
			1171.157,
			1173.124,
			1176.101,
			1177.096,
			1178.096,
			1182.128,
			1183.142,
			1186.208,
			1188.263,
			1191.358,
			1193.435,
			1194.478,
			1196.57,
			1198.661,
			1199.704,
			1201.782,
			1203.834,
			1206.862,
			1208.834,
			1211.726,
			1212.672,
			1213.609,
			1216.371,
			1218.174,
			1220.825,
			1224.265,
			1225.109,
			1227.607,
			1229.257,
			1230.895,
			1231.711,
			1233.341,
			1234.966,
			1236.595,
			1239.047,
			1240.683,
			1242.327,
			1243.152,
			1245.617,
			1247.259,
			1248.901,
			1251.349,
			1252.968,
			1256.21,
			1257.012,
			1259.41,
			1261.01,
			1263.371,
			1264.965,
			1267.346,
			1269.746,
			1271.411,
			1273.07,
			1273.899,
			1275.654,
			1277.426,
			1280.966,
			1281.849,
			1283.613,
			1285.35,
			1286.2,
			1288.744,
			1292.08,
			1294.555,
			1296.152,
			1298.517,
			1300.082,
			1302.305,
			1303.78,
			1305.966,
			1307.394,
			1308.823,
			1309.54,
			1310.976,
			1311.697,
			1313.144,
			1315.327,
			1316.798,
			1318.277,
			1320.509,
			1322.015,
			1325.065,
			1325.834,
			1328.184,
			1329.769,
			1332.177,
			1333.809,
			1335.457,
			1337.95,
			1338.795,
			1339.645,
			1343.087,
			1343.973,
			1346.597,
			1348.377,
			1351.092,
			1352.912,
			1354.731,
			1355.654,
			1357.512,
			1358.446,
			1360.326,
			1362.22,
			1365.083,
			1367.009,
			1369.919,
			1370.895,
			1371.874,
			1374.829,
			1376.814,
			1379.81,
			1381.819,
			1383.84,
			1386.888,
			1388.935,
			1392.024,
			1393.059,
			1394.097,
			1397.227,
			1399.323,
			1402.468,
			1404.573,
			1406.691,
			1407.754,
			1410.961,
			1413.114,
			1415.278,
			1418.551,
			1420.747,
			1424.06,
			1425.169,
			1426.285,
			1429.664,
			1431.923,
			1435.311,
			1437.604,
			1441.078,
			1443.399,
			1444.562,
			1446.896,
			1450.397,
			1452.777,
			1455.146,
			1457.505,
			1459.917,
			1461.116,
			1463.499,
			1465.93,
			1467.138,
			1469.561,
			1473.181,
			1475.619,
			1479.244,
			1481.66,
			1485.283,
			1486.494,
			1487.707,
			1491.327,
			1493.765,
			1497.455,
			1499.913,
			1502.359,
			1503.595,
			1506.072,
			1507.313,
			1509.803,
			1513.551,
			1516.059,
			1518.575,
			1522.362,
			1524.896,
			1528.703,
			1529.976,
			1531.271,
			1535.083,
			1537.648,
			1541.509,
			1544.093,
			1546.683,
			1550.583,
			1551.887,
			1553.211,
			1558.407,
			1559.715,
			1563.652,
			1566.284,
			1570.269,
			1572.92,
			1575.578,
			1576.91,
			1579.579,
			1580.915,
			1583.594,
			1586.277,
			1590.311,
			1593.007,
			1597.035,
			1598.416,
			1599.751,
			1603.866,
			1606.609,
			1610.755,
			1613.536,
			1616.333,
			1621.963,
			1623.374,
			1627.604,
			1629.008,
			1630.407,
			1634.588,
			1637.35,
			1641.449,
			1644.144,
			1648.125,
			1652.051,
			1654.629,
			1657.227,
			1661.132,
			1663.69,
			1667.56,
			1668.839,
			1670.118,
			1673.904,
			1676.435,
			1680.211,
			1682.722,
			1686.499,
			1688.997,
			1690.245,
			1692.741,
			1695.24,
			1696.469,
			1698.974,
			1701.484,
			1703.999,
			1706.521,
			1707.806,
			1710.339,
			1712.879,
			1714.152,
			1717.96,
			1720.523,
			1723.096,
			1726.975,
			1729.597,
			1734.829,
			1736.144,
			1740.081,
			1742.733,
			1746.725,
			1749.418,
			1752.094,
			1753.435,
			1756.118,
			1757.461,
			1760.15,
			1764.166,
			1766.865,
			1769.569,
			1773.635,
			1776.352,
			1780.441,
			1781.807,
			1783.175,
			1787.286,
			1790.035,
			1792.789,
			1796.925,
			1799.67,
			1803.747,
			1805.091,
			1806.427,
			1811.686,
			1812.983,
			1816.842,
			1819.387,
			1823.153,
			1825.628,
			1829.28,
			1831.672,
			1832.856,
			1835.198,
			1837.506,
			1840.911,
			1843.142,
			1846.432,
			1847.513,
			1848.586,
			1851.755,
			1853.827,
			1856.866,
			1858.85,
			1861.761,
			1863.656,
			1864.59,
			1866.43,
			1868.235,
			1869.131,
			1870.024,
			1872.68,
			1874.442,
			1877.084,
			1878.823,
			1880.557,
			1881.422,
			1884.008,
			1885.725,
			1887.443,
			1890.011,
			1891.72,
			1894.301,
			1895.156,
			1896.011,
			1898.578,
			1900.284,
			1902.832,
			1904.519,
			1907.04,
			1909.535,
			1911.189,
			1913.667,
			1915.317,
			1916.965,
			1919.454,
			1920.288,
			1921.123,
			1922.792,
			1925.354,
			1927.074,
			1929.707,
			1931.5,
			1934.229,
			1936.104,
			1938.947,
			1939.928,
			1940.913,
			1943.888,
			1945.918,
			1948.994,
			1951.036,
			1953.091,
			1954.124,
			1956.16,
			1959.239,
			1961.288,
			1962.315,
			1964.378,
			1966.419,
			1969.497,
			1971.553,
			1974.653,
			1975.677,
			1976.694,
			1979.761,
			1981.8,
			1983.832,
			1986.892,
			1988.932,
			1992.008,
			1993.038,
			1997.162,
			1999.229,
			2002.348,
			2004.439,
			2007.594,
			2011.826,
			2012.894,
			2015.037,
			2016.113,
			2018.277,
			2020.462,
			2023.761,
			2024.874,
			2025.988,
			2029.35,
			2030.479,
			2031.611,
			2035.02,
			2037.305,
			2040.728,
			2043.052,
			2046.503,
			2048.824,
			2049.986,
			2051.148,
			2054.64,
			2055.804,
			2056.986,
			2060.471,
			2062.789,
			2065.103,
			2066.241,
			2068.555,
			2070.866,
			2072.021,
			2075.486,
			2077.8,
			2080.119,
			2083.631,
			2085.97,
			2089.495,
			2090.655,
			2091.836,
			2095.39,
			2097.769,
			2101.352,
			2106.194,
			2107.385,
			2109.833,
			2113.53,
			2117.274,
			2118.529,
			2124.914,
			2128.79,
			2131.386,
			2133.99,
			2135.296,
			2137.913,
			2139.225,
			2141.869,
			2145.861,
			2148.549,
			2151.255,
			2156.712,
			2158.086,
			2162.221,
			2164.984,
			2169.145,
			2171.906,
			2176.01,
			2180.033,
			2182.693,
			2185.335,
			2189.292,
			2191.932,
			2195.942,
			2198.642,
			2202.727,
			2204.1,
			2205.476,
			2209.621,
			2212.397,
			2215.186,
			2219.41,
			2222.222,
			2226.459,
			2227.875,
			2229.297,
			2233.573,
			2236.436,
			2240.744,
			2243.626,
			2247.966,
			2250.866,
			2255.227,
			2259.607,
			2262.538,
			2265.467,
			2272.733,
			2278.418,
			2279.816,
			2283.921,
			2286.624,
			2289.289,
			2293.222,
			2295.8,
			2299.601,
			2300.851,
			2302.092,
			2305.757,
			2306.961,
			2308.156,
			2311.691,
			2314.005,
			2316.285,
			2317.411,
			2319.641,
			2321.835,
			2322.92,
			2326.126,
			2328.224,
			2330.293,
			2333.338,
			2335.335,
			2338.289,
			2340.207,
			2343.029,
			2344.871,
			2347.595,
			2349.388,
			2351.171,
			2352.061,
			2353.84,
			2356.49,
			2359.135,
			2360.896,
			2362.653,
			2364.413,
			2367.07,
			2368.839,
			2370.622,
			2371.514,
			2373.3,
			2374.193,
			2375.995,
			2377.8,
			2380.523,
			2382.363,
			2386.067,
			2387.013,
			2389.849,
			2391.753,
			2394.613,
			2396.515,
			2398.411,
			2399.357,
			2401.248,
			2402.188,
			2404.062,
			2406.867,
			2408.725,
			2410.58,
			2413.385,
			2415.262,
			2418.104,
			2419.062,
			2420.019,
			2422.897,
			2424.833,
			2426.763,
			2429.624,
			2431.523,
			2434.343,
			2436.214,
			2439.002,
			2439.926,
			2440.85,
			2443.618,
			2447.311,
			2448.244,
			2450.123,
			2451.067,
			2452.963,
			2455.802,
			2457.721,
			2459.61,
			2462.457,
			2464.298,
			2467.887,
			2468.755,
			2471.313,
			2472.978,
			2474.64,
			2477.147,
			2478.812,
			2479.671,
			2481.383,
			2482.238,
			2483.091,
			2486.601,
			2487.481,
			2490.146,
			2491.932,
			2494.621,
			2496.437,
			2498.246,
			2499.151,
			2500.973,
			2501.881,
			2503.695,
			2505.478,
			2508.13,
			2509.878,
			2512.414,
			2513.259,
			2514.103,
			2516.513,
			2518.105,
			2520.473,
			2522.023,
			2523.574,
			2524.351,
			2525.917,
			2528.278,
			2529.856,
			2530.666,
			2531.477,
			2533.919,
			2535.563,
			2538.074,
			2539.76,
			2542.318,
			2544.904,
			2546.642,
			2548.39,
			2551.032,
			2552.803,
			2555.476,
			2557.262,
			2559.948,
			2561.756,
			2564.48,
			2566.31,
			2569.075,
			2570.933,
			2571.867,
			2573.742,
			2576.578,
			2578.486,
			2580.405,
			2583.306,
			2585.256,
			2587.218,
			2590.185,
			2593.179,
			2595.192,
			2597.217,
			2600.282,
			2602.34,
			2605.449,
			2606.491,
			2607.536,
			2610.685,
			2611.739,
			2612.795,
			2615.958,
			2618.071,
			2620.189,
			2621.251,
			2623.382,
			2626.593,
			2629.824,
			2631.99,
			2634.165,
			2637.443,
			2639.64,
			2642.952,
			2644.061,
			2645.172,
			2648.519,
			2650.762,
			2654.145,
			2656.412,
			2658.687,
			2662.116,
			2663.264,
			2664.414,
			2669.036,
			2670.197,
			2673.693,
			2676.035,
			2679.562,
			2684.294,
			2685.482,
			2687.866,
			2689.061,
			2691.458,
			2693.863,
			2697.487,
			2699.91,
			2703.555,
			2704.773,
			2705.993,
			2709.665,
			2712.152,
			2715.842,
			2718.333,
			2720.834,
			2722.088,
			2725.86,
			2728.383,
			2730.913,
			2734.717,
			2737.262,
			2739.812,
			2743.653,
			2746.223,
			2748.804,
			2750.097,
			2753.994,
			2756.605,
			2759.224,
			2763.159,
			2765.787,
			2769.743,
			2771.064,
			2772.388,
			2776.389,
			2777.716,
			2779.042,
			2782.998,
			2785.603,
			2789.422,
			2791.934,
			2793.174,
			2795.626,
			2798.038,
			2799.229,
			2801.588,
			2803.909,
			2807.32,
			2808.437,
			2809.543,
			2812.807,
			2813.874,
			2814.93,
			2818.044,
			2820.068,
			2822.054,
			2824.955,
			2826.838,
			2829.59,
			2830.489,
			2831.378,
			2834.005,
			2835.698,
			2838.161,
			2839.751,
			2841.301,
			2842.061,
			2843.55,
			2845,
			2845.713,
			2847.796,
			2849.153,
			2850.496,
			2852.487,
			2853.804,
			2856.444,
			2857.109,
			2859.112,
			2860.453,
			2862.478,
			2863.835,
			2865.193,
			2867.246,
			2867.941,
			2868.637,
			2870.724,
			2872.135,
			2874.326,
			2875.787,
			2878.047,
			2879.594,
			2881.141,
			2881.915,
			2883.503,
			2884.297,
			2885.887,
			2887.459,
			2889.738,
			2891.258,
			2893.51,
			2894.254,
			2894.999,
			2897.242,
			2898.75,
			2901.024,
			2904.082,
			2904.851,
			2907.173,
			2908.698,
			2910.22,
			2910.985,
			2912.529,
			2914.058,
			2915.593,
			2917.923,
			2919.487,
			2921.066,
			2921.861,
			2924.276,
			2925.906,
			2927.552,
			2930.046,
			2931.711,
			2935.082,
			2935.936,
			2938.517,
			2940.264,
			2942.911,
			2944.697,
			2947.412,
			2950.165,
			2952.023,
			2954.841,
			2956.741,
			2958.641,
			2961.541,
			2962.513,
			2963.49,
			2965.453,
			2967.433,
			2968.427,
			2971.427,
			2973.445,
			2975.482,
			2978.575,
			2980.647,
			2983.774,
			2985.875,
			2989.028,
			2991.136,
			2994.303,
			2996.439,
			2998.587,
			2999.683,
			3001.828,
			3004.002,
			3005.092,
			3008.38,
			3010.583,
			3012.796,
			3018.375,
			3021.754,
			3022.885,
			3024.018,
			3027.431,
			3029.718,
			3033.165,
			3035.474,
			3037.793,
			3041.289,
			3042.459,
			3043.632,
			3048.338,
			3049.52,
			3053.075,
			3055.455,
			3059.038,
			3061.436,
			3063.84,
			3065.045,
			3067.46,
			3068.671,
			3071.097,
			3073.53,
			3077.198,
			3079.653,
			3083.35,
			3084.586,
			3085.825,
			3089.555,
			3092.053,
			3095.817,
			3098.337,
			3100.866,
			3102.133,
			3105.944,
			3108.496,
			3111.058,
			3112.343,
			3114.917,
			3117.5,
			3120.089,
			3123.976,
			3126.578,
			3130.496,
			3134.432,
			3137.088,
			3139.729,
			3146.335,
			3150.33,
			3152.998,
			3157.034,
			3159.697,
			3163.735,
			3166.43,
			3170.491,
			3173.172,
			3174.507,
			3177.162,
			3181.097,
			3183.664,
			3186.217,
			3191.224,
			3192.455,
			3196.115,
			3197.31,
			3198.496,
			3202.005,
			3204.299,
			3206.558,
			3209.874,
			3212.038,
			3215.215,
			3216.256,
			3217.288,
			3220.327,
			3222.307,
			3225.213,
			3227.109,
			3228.976,
			3229.898,
			3231.724,
			3233.53,
			3234.424,
			3237.071,
			3238.812,
			3240.539,
			3243.111,
			3244.819,
			3247.378,
			3248.233,
			3249.087,
			3251.649,
			3253.36,
			3255.943,
			3257.666,
			3260.298,
			3262.061,
			3262.944,
			3263.841,
			3267.486,
			3268.401,
			3271.194,
			3273.063,
			3275.914,
			3277.829,
			3280.745,
			3282.707,
			3283.691,
			3285.687,
			3287.691,
			3290.692,
			3292.695,
			3295.686,
			3297.673,
			3300.658,
			3302.647,
			3305.635,
			3307.621,
			3309.617,
			3310.612,
			3312.602,
			3314.601,
			3318.612,
			3319.602,
			3322.635,
			3324.667,
			3327.753,
			3331.88,
			3332.919,
			3336.02,
			3338.1,
			3340.188,
			3345.449,
			3348.633,
			3349.699,
			3350.768,
			3353.985,
			3356.141,
			3359.393,
			3361.573,
			3364.858,
			3367.06,
			3368.164,
			3370.378,
			3372.6,
			3373.712,
			3375.935,
			3378.155,
			3382.575,
			3383.676,
			3386.966,
			3388.059,
			3389.152,
			3392.445,
			3394.627,
			3396.812,
			3400.088,
			3402.271,
			3405.546,
			3406.652,
			3407.757,
			3411.075,
			3413.293,
			3416.637,
			3418.879,
			3421.131,
			3422.261,
			3424.528,
			3425.665,
			3427.947,
			3431.389,
			3433.697,
			3436.016,
			3439.515,
			3441.86,
			3445.399,
			3446.584,
			3447.771,
			3451.345,
			3453.739,
			3457.346,
			3459.763,
			3462.188,
			3465.843,
			3468.292,
			3473.217,
			3474.454,
			3478.178,
			3480.672,
			3483.174,
			3484.428,
			3486.942,
			3489.464,
			3490.728,
			3493.263,
			3494.533,
			3497.078,
			3499.63,
			3503.471,
			3506.044,
			3509.92,
			3512.514,
			3516.417,
			3519.021,
			3522.943,
			3525.569,
			3528.203,
			3529.523,
			3533.495,
			3534.822,
			3538.811,
			3540.144,
			3541.479,
			3545.494,
			3548.181,
			3552.226,
			3554.931,
			3559.001,
			3563.088,
			3565.82,
			3568.559,
			3572.679,
			3575.435,
			3579.58,
			3580.965,
			3582.353,
			3586.524,
			3589.313,
			3593.509,
			3596.317,
			3600.542,
			3603.366,
			3604.719
		],
		[
			0,
			0.043,
			0.153,
			0.168,
			0.228,
			0.292,
			0.369,
			0.416,
			0.538,
			0.603,
			0.662,
			0.695,
			0.726,
			0.804,
			0.849,
			0.91,
			0.975,
			1.021,
			1.098,
			1.13,
			1.161,
			1.236,
			1.282,
			1.346,
			1.408,
			1.47,
			1.531,
			1.563,
			1.594,
			1.656,
			1.671,
			1.719,
			1.78,
			1.843,
			1.889,
			1.967,
			1.997,
			2.033,
			2.11,
			2.152,
			2.212,
			2.278,
			2.323,
			2.431,
			2.461,
			2.523,
			2.543,
			2.586,
			2.649,
			2.709,
			2.772,
			2.834,
			2.896,
			2.973,
			3.019,
			3.085,
			3.143,
			3.19,
			3.269,
			3.33,
			3.392,
			3.453,
			3.522,
			3.578,
			3.64,
			3.702,
			3.734,
			3.763,
			3.843,
			3.889,
			3.952,
			4.012,
			4.058,
			4.075,
			4.137,
			4.166,
			4.199,
			4.275,
			4.321,
			4.385,
			4.445,
			4.506,
			4.571,
			4.602,
			4.633,
			4.695,
			4.756,
			4.819,
			4.881,
			4.926,
			4.943,
			5.005,
			5.034,
			5.067,
			5.144,
			5.191,
			5.253,
			5.314,
			5.376,
			5.439,
			5.47,
			5.499,
			5.562,
			5.581,
			5.623,
			5.686,
			5.794,
			5.872,
			5.904,
			5.934,
			6.011,
			6.059,
			6.121,
			6.184,
			6.246,
			6.306,
			6.336,
			6.368,
			6.43,
			6.446,
			6.493,
			6.556,
			6.616,
			6.679,
			6.741,
			6.802,
			6.881,
			6.926,
			6.987,
			7.051,
			7.112,
			7.173,
			7.238,
			7.298,
			7.317,
			7.361,
			7.422,
			7.485,
			7.546,
			7.609,
			7.643,
			7.671,
			7.747,
			7.795,
			7.858,
			7.918,
			7.966,
			8.042,
			8.106,
			8.23,
			8.292,
			8.353,
			8.416,
			8.475,
			8.509,
			8.539,
			8.599,
			8.618,
			8.663,
			8.725,
			8.787,
			8.834,
			8.849,
			8.91,
			8.942,
			8.973,
			9.051,
			9.097,
			9.161,
			9.222,
			9.284,
			9.346,
			9.408,
			9.469,
			9.532,
			9.594,
			9.654,
			9.708,
			9.716,
			9.779,
			9.81,
			9.841,
			9.919,
			9.965,
			10.027,
			10.089,
			10.152,
			10.213,
			10.243,
			10.273,
			10.336,
			10.353,
			10.4,
			10.46,
			10.523,
			10.568,
			10.647,
			10.678,
			10.71,
			10.786,
			10.834,
			10.896,
			10.958,
			11.02,
			11.081,
			11.143,
			11.205,
			11.222,
			11.268,
			11.329,
			11.392,
			11.438,
			11.516,
			11.546,
			11.578,
			11.655,
			11.702,
			11.769,
			11.826,
			11.873,
			11.951,
			11.979,
			12.011,
			12.073,
			12.09,
			12.136,
			12.197,
			12.26,
			12.322,
			12.415,
			12.446,
			12.523,
			12.569,
			12.633,
			12.694,
			12.74,
			12.85,
			12.879,
			12.943,
			13.003,
			13.067,
			13.127,
			13.189,
			13.251,
			13.282,
			13.313,
			13.392,
			13.437,
			13.5,
			13.562,
			13.609,
			13.625,
			13.685,
			13.718,
			13.748,
			13.81,
			13.829,
			13.872,
			13.935,
			13.995,
			14.059,
			14.152,
			14.182,
			14.259,
			14.305,
			14.367,
			14.43,
			14.476,
			14.492,
			14.555,
			14.586,
			14.617,
			14.678,
			14.694,
			14.739,
			14.802,
			14.864,
			14.926,
			14.987,
			15.019,
			15.05,
			15.127,
			15.175,
			15.236,
			15.297,
			15.346,
			15.423,
			15.454,
			15.485,
			15.563,
			15.609,
			15.672,
			15.733,
			15.794,
			15.856,
			15.891,
			15.918,
			15.981,
			15.994,
			16.043,
			16.104,
			16.168,
			16.213,
			16.291,
			16.321,
			16.353,
			16.428,
			16.475,
			16.539,
			16.6,
			16.661,
			16.725,
			16.754,
			16.786,
			16.848,
			16.91,
			16.972,
			17.035,
			17.095,
			17.159,
			17.19,
			17.219,
			17.296,
			17.343,
			17.407,
			17.469,
			17.516,
			17.593,
			17.624,
			17.653,
			17.715,
			17.777,
			17.842,
			17.903,
			17.963,
			18.028,
			18.058,
			18.089,
			18.168,
			18.212,
			18.273,
			18.337,
			18.382,
			18.398,
			18.524,
			18.584,
			18.599,
			18.647,
			18.71,
			18.772,
			18.831,
			18.895,
			18.927,
			18.957,
			19.033,
			19.082,
			19.143,
			19.204,
			19.252,
			19.265,
			19.328,
			19.36,
			19.39,
			19.468,
			19.516,
			19.576,
			19.638,
			19.699,
			19.764,
			19.794,
			19.825,
			19.887,
			19.901,
			19.951,
			20.016,
			20.071,
			20.12,
			20.197,
			20.227,
			20.26,
			20.336,
			20.384,
			20.446,
			20.506,
			20.57,
			20.631,
			20.661,
			20.691,
			20.756,
			20.77,
			20.816,
			20.88,
			20.941,
			20.989,
			21.064,
			21.128,
			21.204,
			21.252,
			21.314,
			21.375,
			21.438,
			21.499,
			21.531,
			21.561,
			21.622,
			21.64,
			21.686,
			21.749,
			21.81,
			21.856,
			21.87,
			21.933,
			21.965,
			21.994,
			22.076,
			22.119,
			22.183,
			22.242,
			22.306,
			22.367,
			22.397,
			22.428,
			22.492,
			22.554,
			22.615,
			22.678,
			22.738,
			22.802,
			22.834,
			22.862,
			22.942,
			22.988,
			23.051,
			23.111,
			23.174,
			23.265,
			23.297,
			23.36,
			23.423,
			23.483,
			23.547,
			23.593,
			23.67,
			23.732,
			23.81,
			23.856,
			23.919,
			23.979,
			24.043,
			24.105,
			24.165,
			24.228,
			24.244,
			24.29,
			24.352,
			24.413,
			24.475,
			24.538,
			24.567,
			24.599,
			24.678,
			24.723,
			24.787,
			24.849,
			24.895,
			24.971,
			25.003,
			25.033,
			25.095,
			25.158,
			25.226,
			25.28,
			25.343,
			25.405,
			25.438,
			25.466,
			25.546,
			25.593,
			25.656,
			25.718,
			25.763,
			25.779,
			25.84,
			25.872,
			25.901,
			25.979,
			26.027,
			26.09,
			26.149,
			26.213,
			26.275,
			26.306,
			26.336,
			26.415,
			26.463,
			26.53,
			26.585,
			26.645,
			26.709,
			26.738,
			26.77,
			26.833,
			26.847,
			26.895,
			26.955,
			27.018,
			27.081,
			27.143,
			27.174,
			27.205,
			27.282,
			27.329,
			27.39,
			27.499,
			27.515,
			27.608,
			27.638,
			27.699,
			27.718,
			27.763,
			27.823,
			27.886,
			27.949,
			28.01,
			28.041,
			28.073,
			28.149,
			28.196,
			28.263,
			28.32,
			28.385,
			28.475,
			28.505,
			28.568,
			28.631,
			28.692,
			28.756,
			28.818,
			28.909,
			28.941,
			29.003,
			29.018,
			29.064,
			29.127,
			29.234,
			29.25,
			29.313,
			29.344,
			29.376,
			29.453,
			29.56,
			29.621,
			29.684,
			29.745,
			29.81,
			29.872,
			29.932,
			29.993,
			30.055,
			30.102,
			30.119,
			30.182,
			30.213,
			30.243,
			30.325,
			30.367,
			30.427,
			30.493,
			30.551,
			30.646,
			30.678,
			30.739,
			30.802,
			30.863,
			30.926,
			30.97,
			31.048,
			31.079,
			31.111,
			31.189,
			31.234,
			31.296,
			31.359,
			31.422,
			31.481,
			31.513,
			31.546,
			31.608,
			31.623,
			31.67,
			31.733,
			31.793,
			31.854,
			31.917,
			31.948,
			31.979,
			32.056,
			32.102,
			32.166,
			32.227,
			32.274,
			32.35,
			32.413,
			32.475,
			32.489,
			32.536,
			32.599,
			32.66,
			32.723,
			32.786,
			32.817,
			32.846,
			32.924,
			32.971,
			33.032,
			33.096,
			33.143,
			33.22,
			33.249,
			33.281,
			33.344,
			33.405,
			33.468,
			33.528,
			33.592,
			33.653,
			33.684,
			33.716,
			33.794,
			33.838,
			33.901,
			33.962,
			34.01,
			34.026,
			34.087,
			34.119,
			34.149,
			34.21,
			34.274,
			34.336,
			34.398,
			34.46,
			34.522,
			34.553,
			34.583,
			34.646,
			34.709,
			34.769,
			34.832,
			34.877,
			34.894,
			34.956,
			34.985,
			35.017,
			35.095,
			35.142,
			35.205,
			35.266,
			35.327,
			35.388,
			35.42,
			35.452,
			35.514,
			35.576,
			35.639,
			35.699,
			35.746,
			35.825,
			35.855,
			35.885,
			35.963,
			36.01,
			36.073,
			36.135,
			36.195,
			36.258,
			36.288,
			36.32,
			36.382,
			36.398,
			36.444,
			36.511,
			36.568,
			36.628,
			36.693,
			36.723,
			36.755,
			36.832,
			36.878,
			36.941,
			37.003,
			37.05,
			37.156,
			37.186,
			37.248,
			37.265,
			37.311,
			37.375,
			37.435,
			37.499,
			37.56,
			37.623,
			37.7,
			37.747,
			37.807,
			37.869,
			37.915,
			37.994,
			38.026,
			38.055,
			38.133,
			38.179,
			38.242,
			38.304,
			38.365,
			38.427,
			38.459,
			38.491,
			38.552,
			38.572,
			38.614,
			38.676,
			38.737,
			38.785,
			38.799,
			38.861,
			38.894,
			38.924,
			39.001,
			39.05,
			39.111,
			39.171,
			39.235,
			39.327,
			39.359,
			39.42,
			39.482,
			39.545,
			39.607,
			39.653,
			39.669,
			39.729,
			39.761,
			39.792,
			39.868,
			39.917,
			39.979,
			40.042,
			40.102,
			40.164,
			40.195,
			40.227,
			40.29,
			40.352,
			40.413,
			40.474,
			40.522,
			40.598,
			40.635,
			40.66,
			40.739,
			40.784,
			40.846,
			40.909,
			40.969,
			41.034,
			41.094,
			41.158,
			41.172,
			41.219,
			41.281,
			41.343,
			41.39,
			41.465,
			41.497,
			41.527,
			41.607,
			41.653,
			41.718,
			41.777,
			41.839,
			41.901,
			41.931,
			41.964,
			42.025,
			42.04,
			42.087,
			42.148,
			42.21,
			42.273,
			42.336,
			42.366,
			42.396,
			42.473,
			42.521,
			42.583,
			42.646,
			42.695,
			42.769,
			42.801,
			42.83,
			42.91,
			42.954,
			43.02,
			43.078,
			43.14,
			43.233,
			43.266,
			43.343,
			43.388,
			43.453,
			43.514,
			43.559,
			43.576,
			43.636,
			43.7,
			43.76,
			43.824,
			43.886,
			43.946,
			44.01,
			44.072,
			44.102,
			44.134,
			44.211,
			44.256,
			44.321,
			44.38,
			44.428,
			44.443,
			44.505,
			44.567,
			44.628,
			44.644,
			44.692,
			44.759,
			44.816,
			44.877,
			44.938,
			44.97,
			45.001,
			45.079,
			45.124,
			45.186,
			45.248,
			45.311,
			45.374,
			45.404,
			45.497,
			45.56,
			45.622,
			45.685,
			45.746,
			45.84,
			45.869,
			45.93,
			45.946,
			45.994,
			46.055,
			46.116,
			46.163,
			46.179,
			46.242,
			46.272,
			46.303,
			46.382,
			46.427,
			46.489,
			46.551,
			46.613,
			46.677,
			46.706,
			46.738,
			46.8,
			46.823,
			46.862,
			46.923,
			46.987,
			47.032,
			47.047,
			47.109,
			47.142,
			47.17,
			47.248,
			47.297,
			47.357,
			47.42,
			47.482,
			47.543,
			47.575,
			47.604,
			47.669,
			47.729,
			47.792,
			47.899,
			47.915,
			47.979,
			48.042,
			48.118,
			48.163,
			48.288,
			48.348,
			48.413,
			48.444,
			48.472,
			48.535,
			48.552,
			48.596,
			48.665,
			48.721,
			48.769,
			48.882,
			48.909,
			48.986,
			49.032,
			49.093,
			49.156,
			49.218,
			49.31,
			49.342,
			49.403,
			49.465,
			49.528,
			49.59,
			49.652,
			49.714,
			49.745,
			49.777,
			49.853,
			49.9,
			49.963,
			50.023,
			50.071,
			50.149,
			50.18,
			50.209,
			50.271,
			50.334,
			50.396,
			50.458,
			50.521,
			50.584,
			50.643,
			50.721,
			50.769,
			50.832,
			50.943,
			51.046,
			51.079,
			51.155,
			51.202,
			51.263,
			51.326,
			51.388,
			51.449,
			51.481,
			51.512,
			51.574,
			51.589,
			51.635,
			51.698,
			51.76,
			51.808,
			51.823,
			51.886,
			51.916,
			51.947,
			52.025,
			52.071,
			52.134,
			52.193,
			52.256,
			52.318,
			52.38,
			52.459,
			52.505,
			52.568,
			52.627,
			52.675,
			52.691,
			52.752,
			52.815,
			52.892,
			52.938,
			53.006,
			53.061,
			53.124,
			53.186,
			53.217,
			53.249,
			53.31,
			53.326,
			53.371,
			53.434,
			53.496,
			53.556,
			53.653,
			53.682,
			53.759,
			53.807,
			53.868,
			53.931,
			53.978,
			53.993,
			54.054,
			54.087,
			54.115,
			54.195,
			54.24,
			54.302,
			54.365,
			54.428,
			54.489,
			54.519,
			54.552,
			54.628,
			54.676,
			54.738,
			54.798,
			54.861,
			54.923,
			54.984,
			55.048,
			55.068,
			55.109,
			55.173,
			55.279,
			55.296,
			55.356,
			55.387,
			55.419,
			55.496,
			55.542,
			55.605,
			55.667,
			55.728,
			55.823,
			55.852,
			55.915,
			55.976,
			56.038,
			56.101,
			56.146,
			56.163,
			56.226,
			56.257,
			56.288,
			56.365,
			56.411,
			56.474,
			56.536,
			56.596,
			56.66,
			56.691,
			56.722,
			56.783,
			56.799,
			56.845,
			56.906,
			56.969,
			57.031,
			57.094,
			57.129,
			57.156,
			57.232,
			57.28,
			57.341,
			57.402,
			57.449,
			57.469,
			57.527,
			57.589,
			57.653,
			57.668,
			57.712,
			57.776,
			57.838,
			57.899,
			57.962,
			58.024,
			58.101,
			58.146,
			58.211,
			58.271,
			58.319,
			58.396,
			58.456,
			58.535,
			58.583,
			58.643,
			58.705,
			58.767,
			58.828,
			58.86,
			58.893,
			58.968,
			59.016,
			59.076,
			59.14,
			59.191,
			59.262,
			59.327,
			59.403,
			59.449,
			59.51,
			59.575,
			59.636,
			59.698,
			59.729,
			59.76,
			59.821,
			59.838,
			59.883,
			59.945,
			60.007,
			60.055,
			60.071,
			60.132,
			60.195,
			60.273,
			60.319,
			60.379,
			60.443,
			60.503,
			60.566,
			60.596,
			60.629,
			60.69,
			60.753,
			60.815,
			60.877,
			60.924,
			61,
			61.032,
			61.061,
			61.139,
			61.186,
			61.253,
			61.311,
			61.372,
			61.465,
			61.497,
			61.559,
			61.575,
			61.62,
			61.682,
			61.744,
			61.808,
			61.869,
			61.898,
			61.929,
			62.009,
			62.054,
			62.118,
			62.179,
			62.224,
			62.242,
			62.333,
			62.365,
			62.425,
			62.489,
			62.553,
			62.612,
			62.675,
			62.736,
			62.768,
			62.798,
			62.877,
			62.922,
			62.986,
			63.046,
			63.094,
			63.172,
			63.202,
			63.233,
			63.294,
			63.315,
			63.356,
			63.418,
			63.48,
			63.543,
			63.604,
			63.634,
			63.665,
			63.727,
			63.746,
			63.79,
			63.854,
			63.915,
			63.961,
			63.978,
			64.038,
			64.071,
			64.1,
			64.178,
			64.226,
			64.285,
			64.349,
			64.41,
			64.474,
			64.504,
			64.535,
			64.598,
			64.658,
			64.721,
			64.781,
			64.828,
			64.844,
			64.904,
			64.938,
			64.968,
			65.046,
			65.094,
			65.155,
			65.218,
			65.278,
			65.376,
			65.403,
			65.465,
			65.528,
			65.594,
			65.651,
			65.696,
			65.776,
			65.807,
			65.837,
			65.913,
			65.961,
			66.022,
			66.086,
			66.147,
			66.21,
			66.241,
			66.272,
			66.334,
			66.349,
			66.395,
			66.458,
			66.518,
			66.581,
			66.644,
			66.675,
			66.705,
			66.784,
			66.83,
			66.893,
			67,
			67.016,
			67.109,
			67.138,
			67.202,
			67.215,
			67.264,
			67.327,
			67.386,
			67.449,
			67.512,
			67.542,
			67.572,
			67.652,
			67.697,
			67.76,
			67.821,
			67.869,
			67.976,
			68.005,
			68.068,
			68.132,
			68.193,
			68.254,
			68.316,
			68.41,
			68.442,
			68.519,
			68.564,
			68.628,
			68.689,
			68.735,
			68.752,
			68.813,
			68.844,
			68.876,
			68.953,
			69.002,
			69.062,
			69.122,
			69.186,
			69.247,
			69.31,
			69.372,
			69.433,
			69.5,
			69.555,
			69.604,
			69.62,
			69.68,
			69.714,
			69.742,
			69.821,
			69.866,
			69.93,
			70.053,
			70.116,
			70.145,
			70.178,
			70.24,
			70.302,
			70.364,
			70.426,
			70.486,
			70.55,
			70.581,
			70.613,
			70.69,
			70.735,
			70.799,
			70.86,
			70.921,
			70.985,
			71.014,
			71.046,
			71.109,
			71.125,
			71.168,
			71.234,
			71.293,
			71.355,
			71.419,
			71.448,
			71.48,
			71.56,
			71.605,
			71.668,
			71.727,
			71.775,
			71.791,
			71.884,
			71.915,
			71.976,
			71.991,
			72.038,
			72.102,
			72.161,
			72.225,
			72.285,
			72.349,
			72.426,
			72.472,
			72.535,
			72.645,
			72.721,
			72.784,
			72.844,
			72.905,
			72.969,
			73.031,
			73.09,
			73.155,
			73.185,
			73.215,
			73.295,
			73.339,
			73.402,
			73.512,
			73.527,
			73.588,
			73.624,
			73.652,
			73.729,
			73.775,
			73.837,
			73.898,
			73.959,
			74.021,
			74.054,
			74.084,
			74.146,
			74.207,
			74.269,
			74.334,
			74.381,
			74.393,
			74.455,
			74.488,
			74.519,
			74.596,
			74.643,
			74.704,
			74.768,
			74.828,
			74.89,
			74.921,
			74.952,
			75.015,
			75.077,
			75.139,
			75.201,
			75.263,
			75.325,
			75.355,
			75.387,
			75.464,
			75.511,
			75.574,
			75.635,
			75.698,
			75.759,
			75.82,
			75.883,
			75.899,
			75.943,
			76.007,
			76.069,
			76.13,
			76.193,
			76.255,
			76.331,
			76.379,
			76.441,
			76.502,
			76.548,
			76.565,
			76.626,
			76.688,
			76.765,
			76.811,
			76.876,
			76.938,
			76.999,
			77.092,
			77.123,
			77.201,
			77.246,
			77.309,
			77.419,
			77.495,
			77.526,
			77.557,
			77.619,
			77.68,
			77.742,
			77.805,
			77.867,
			77.929,
			77.96,
			77.992,
			78.053,
			78.068,
			78.116,
			78.177,
			78.286,
			78.3,
			78.364,
			78.393,
			78.426,
			78.501,
			78.549,
			78.612,
			78.671,
			78.735,
			78.798,
			78.827,
			78.859,
			78.921,
			78.982,
			79.045,
			79.108,
			79.153,
			79.169,
			79.232,
			79.261,
			79.293,
			79.37,
			79.418,
			79.48,
			79.542,
			79.604,
			79.665,
			79.696,
			79.728,
			79.789,
			79.852,
			79.913,
			79.976,
			80.022,
			80.099,
			80.161,
			80.239,
			80.286,
			80.349,
			80.41,
			80.456,
			80.472,
			80.535,
			80.565,
			80.596,
			80.658,
			80.674,
			80.72,
			80.782,
			80.843,
			80.907,
			80.968,
			81.031,
			81.105,
			81.154,
			81.219,
			81.278,
			81.324,
			81.338,
			81.433,
			81.463,
			81.527,
			81.541,
			81.588,
			81.65,
			81.712,
			81.775,
			81.836,
			81.897,
			81.974,
			82.021,
			82.085,
			82.146,
			82.193,
			82.268,
			82.302,
			82.333,
			82.393,
			82.455,
			82.518,
			82.58,
			82.642,
			82.705,
			82.724
		]
	],
	'2521_2556_7982': [
		[
			91.121
		],
		[
			0
		],
		[
			0,
			1.389,
			4.538,
			9.27,
			12.43,
			17.184,
			21.954,
			23.547,
			28.333,
			31.523,
			34.715,
			36.312,
			39.508,
			42.708,
			44.308,
			49.116,
			52.326,
			55.544,
			60.383,
			63.618,
			68.487,
			70.113,
			71.74,
			76.626,
			79.886,
			84.782,
			88.047,
			92.95,
			96.221,
			101.133,
			106.053,
			107.695,
			112.62,
			119.192,
			120.837,
			124.126,
			129.065,
			132.361,
			134.011,
			137.314,
			140.618,
			145.58,
			148.892,
			153.864,
			155.522,
			157.182,
			162.164,
			165.489,
			170.477,
			173.796,
			177.101,
			178.746,
			183.635,
			186.846,
			191.587,
			194.697,
			197.766,
			202.292,
			205.262,
			209.647,
			211.089,
			212.521,
			215.356,
			216.758,
			219.529,
			223.611,
			226.284,
			228.918,
			232.793,
			234.065,
			235.327,
			240.274,
			241.487,
			245.069,
			247.41,
			250.858,
			253.116,
			254.231,
			256.433,
			259.672,
			261.784,
			263.866,
			266.915,
			268.905,
			271.818,
			272.77,
			273.71,
			276.474,
			278.286,
			280.904,
			284.292,
			285.12,
			286.759,
			287.572,
			288.38,
			290.773,
			291.559,
			292.342,
			294.68,
			296.231,
			298.51,
			300.022,
			302.286,
			304.53,
			306.022,
			307.513,
			311.436,
			313.872,
			315.734,
			318.539,
			320.534,
			323.521,
			325.608,
			328.83,
			330.753,
			333.472,
			336.044,
			337.659,
			339.278,
			341.634,
			342.406,
			343.178,
			344.723,
			346.275,
			347.054,
			349.396,
			350.961,
			352.553,
			354.969,
			356.591,
			359.08,
			360.755,
			363.313,
			365.044,
			367.655,
			369.442,
			372.162,
			373.977,
			374.884,
			376.707,
			379.453,
			381.29,
			383.136,
			385.928,
			387.799,
			390.627,
			391.577,
			392.525,
			395.387,
			397.309,
			399.241,
			402.162,
			404.12,
			407.079,
			408.07,
			409.063,
			413.061,
			414.067,
			419.139,
			422.217,
			424.282,
			425.318,
			427.399,
			430.54,
			432.634,
			434.73,
			437.889,
			440.007,
			443.197,
			444.264,
			445.334,
			448.553,
			450.711,
			453.966,
			456.149,
			458.343,
			462.761,
			463.872,
			467.221,
			468.342,
			469.466,
			472.861,
			475.137,
			478.565,
			483.163,
			484.317,
			487.792,
			490.124,
			492.466,
			496.002,
			498.372,
			501.947,
			503.144,
			504.343,
			507.957,
			510.376,
			514.022,
			516.464,
			520.146,
			522.612,
			526.331,
			530.07,
			532.573,
			535.086,
			537.607,
			540.137,
			541.406,
			543.949,
			546.501,
			547.78,
			551.629,
			554.205,
			556.788,
			560.678,
			563.281,
			567.189,
			569.798,
			573.725,
			576.352,
			580.306,
			582.951,
			585.603,
			586.931,
			589.593,
			590.927,
			593.601,
			597.623,
			600.314,
			603.012,
			607.072,
			609.79,
			613.882,
			615.25,
			616.619,
			619.367,
			620.743,
			623.502,
			626.267,
			630.431,
			633.217,
			637.409,
			638.81,
			640.21,
			645.828,
			648.646,
			651.47,
			654.3,
			658.552,
			661.394,
			664.242,
			665.668,
			668.524,
			669.955,
			672.82,
			675.691,
			680.01,
			682.897,
			687.237,
			690.14,
			694.505,
			697.424,
			701.815,
			704.751,
			707.692,
			713.593,
			715.072,
			719.516,
			721.001,
			722.488,
			726.956,
			729.942,
			734.432,
			737.432,
			741.942,
			746.466,
			749.487,
			752.513,
			760.101,
			764.667,
			766.191,
			767.716,
			772.297,
			775.356,
			779.953,
			783.021,
			787.631,
			790.708,
			792.247,
			795.33,
			799.961,
			801.507,
			806.155,
			809.261,
			812.373,
			813.93,
			817.049,
			820.172,
			821.735,
			824.865,
			826.431,
			829.568,
			834.28,
			837.426,
			840.576,
			845.308,
			846.887,
			848.467,
			853.213,
			856.381,
			861.142,
			867.503,
			869.095,
			873.873,
			877.059,
			881.843,
			885.035,
			888.23,
			893.025,
			896.224,
			902.634,
			904.24,
			909.065,
			912.288,
			915.518,
			920.369,
			923.607,
			930.089,
			931.711,
			936.581,
			939.831,
			944.71,
			947.964,
			952.85,
			956.111,
			957.743,
			961.01,
			964.282,
			965.919,
			969.196,
			972.475,
			977.398,
			979.04,
			980.683,
			985.642,
			987.287,
			988.933,
			993.847,
			997.144,
			1002.098,
			1005.403,
			1008.709,
			1013.672,
			1015.327,
			1016.983,
			1021.953,
			1025.269,
			1030.249,
			1033.571,
			1036.896,
			1038.559,
			1041.886,
			1045.217,
			1046.884,
			1051.887,
			1055.225,
			1058.566,
			1063.583,
			1066.926,
			1071.94,
			1075.284,
			1080.301,
			1081.974,
			1083.646,
			1088.664,
			1092.011,
			1097.033,
			1103.721,
			1105.384,
			1108.688,
			1110.327,
			1113.577,
			1118.374,
			1119.951,
			1124.624,
			1127.693,
			1132.224,
			1135.196,
			1138.128,
			1139.58,
			1142.451,
			1146.677,
			1149.443,
			1153.516,
			1156.18,
			1160.102,
			1161.388,
			1162.665,
			1166.435,
			1168.9,
			1172.525,
			1174.894,
			1178.379,
			1181.774,
			1183.989,
			1186.163,
			1189.353,
			1191.431,
			1193.469,
			1196.451,
			1198.389,
			1201.219,
			1203.96,
			1205.742,
			1207.488,
			1210.035,
			1211.684,
			1214.085,
			1215.636,
			1217.89,
			1219.345,
			1221.455,
			1222.816,
			1224.789,
			1226.066,
			1226.69,
			1227.907,
			1229.672,
			1230.803,
			1231.903,
			1233.503,
			1234.024,
			1234.541,
			1236.051,
			1237.027,
			1238.464,
			1239.413,
			1240.819,
			1241.738,
			1242.649,
			1244.007,
			1244.912,
			1246.773,
			1247.238,
			1248.627,
			1249.554,
			1250.487,
			1251.018,
			1252.124,
			1253.227,
			1253.779,
			1254.888,
			1255.445,
			1256.781,
			1258.21,
			1260.367,
			1261.953,
			1264.428,
			1265.26,
			1266.081,
			1268.47,
			1270.075,
			1272.091,
			1273.311,
			1274.54,
			1275.158,
			1276.345,
			1276.864,
			1277.905,
			1279.474,
			1280.001,
			1282.72,
			1284.391,
			1285.512,
			1286.661,
			1287.254,
			1288.445,
			1290.244,
			1291.457,
			1293.313,
			1294.556,
			1296.423,
			1297.05,
			1297.681,
			1300.216,
			1300.852,
			1302.766,
			1304.053,
			1305.983,
			1307.918,
			1309.218,
			1310.514,
			1311.182,
			1312.506,
			1313.835,
			1315.191,
			1317.255,
			1318.652,
			1320.066,
			1320.779,
			1322.942,
			1324.404,
			1325.882,
			1328.128,
			1329.645,
			1333.501,
			1335.86,
			1337.464,
			1339.863,
			1341.499,
			1343.955,
			1345.611,
			1346.431,
			1348.116,
			1350.623,
			1352.305,
			1353.987,
			1356.555,
			1358.283,
			1360.899,
			1361.778,
			1362.66,
			1365.326,
			1367.12,
			1369.838,
			1371.666,
			1373.508,
			1376.297,
			1377.233,
			1378.173,
			1381.016,
			1381.971,
			1382.928,
			1385.824,
			1387.771,
			1389.732,
			1390.718,
			1392.7,
			1394.693,
			1395.695,
			1398.72,
			1400.752,
			1402.795,
			1405.882,
			1407.955,
			1412.132,
			1413.18,
			1416.324,
			1418.43,
			1421.611,
			1423.745,
			1425.89,
			1426.967,
			1429.129,
			1430.214,
			1432.394,
			1435.684,
			1437.893,
			1440.111,
			1442.343,
			1445.712,
			1450.248,
			1451.389,
			1453.682,
			1454.833,
			1457.142,
			1459.464,
			1462.968,
			1465.32,
			1468.868,
			1470.057,
			1471.249,
			1474.841,
			1477.249,
			1480.882,
			1483.316,
			1486.981,
			1489.437,
			1490.669,
			1493.142,
			1495.623,
			1496.867,
			1498.113,
			1501.866,
			1504.38,
			1508.169,
			1510.707,
			1513.252,
			1514.529,
			1518.37,
			1520.941,
			1523.522,
			1530.012,
			1533.931,
			1535.24,
			1540.482,
			1543.113,
			1547.074,
			1549.724,
			1553.713,
			1556.382,
			1557.719,
			1560.4,
			1564.435,
			1567.133,
			1569.839,
			1573.912,
			1575.274,
			1576.637,
			1580.738,
			1582.108,
			1583.48,
			1590.372,
			1594.53,
			1597.312,
			1600.103,
			1604.303,
			1605.706,
			1607.111,
			1611.338,
			1612.75,
			1614.165,
			1618.418,
			1621.261,
			1624.113,
			1625.541,
			1628.403,
			1632.708,
			1637.028,
			1639.917,
			1642.814,
			1647.172,
			1650.086,
			1654.47,
			1655.935,
			1657.401,
			1661.81,
			1664.756,
			1669.188,
			1672.15,
			1675.119,
			1676.606,
			1679.585,
			1681.077,
			1682.57,
			1688.56,
			1690.062,
			1697.594,
			1702.131,
			1705.164,
			1708.203,
			1709.725,
			1712.773,
			1714.299,
			1717.356,
			1720.42,
			1728.108,
			1734.287,
			1735.835,
			1740.49,
			1743.6,
			1748.277,
			1751.402,
			1756.101,
			1759.241,
			1760.813,
			1763.961,
			1767.115,
			1768.694,
			1770.275,
			1775.025,
			1778.199,
			1781.378,
			1782.969,
			1789.347,
			1790.942,
			1795.736,
			1798.938,
			1802.147,
			1806.97,
			1810.192,
			1816.651,
			1818.268,
			1823.134,
			1826.383,
			1831.268,
			1834.534,
			1839.446,
			1842.727,
			1844.369,
			1847.658,
			1852.603,
			1855.905,
			1859.212,
			1864.183,
			1865.842,
			1867.502,
			1872.488,
			1874.152,
			1875.818,
			1880.822,
			1884.164,
			1887.511,
			1892.539,
			1895.891,
			1900.898,
			1904.209,
			1909.114,
			1910.729,
			1912.334,
			1917.091,
			1920.215,
			1924.827,
			1927.857,
			1930.852,
			1932.336,
			1935.276,
			1936.732,
			1939.616,
			1942.463,
			1943.873,
			1946.664,
			1949.419,
			1953.485,
			1956.149,
			1961.368,
			1962.65,
			1966.44,
			1968.921,
			1972.574,
			1977.323,
			1980.797,
			1983.067,
			1987.497,
			1988.581,
			1991.78,
			1993.868,
			1996.932,
			1998.93,
			2000.892,
			2001.86,
			2003.768,
			2004.709,
			2006.564,
			2008.386,
			2011.058,
			2012.8,
			2015.344,
			2016.175,
			2016.997,
			2020.197,
			2020.974,
			2023.268,
			2024.744,
			2026.9,
			2028.305,
			2028.999,
			2030.375,
			2031.74,
			2032.419,
			2033.772,
			2035.117,
			2036.459,
			2038.462,
			2039.783,
			2041.101,
			2041.76,
			2043.737,
			2045.044,
			2046.352,
			2049.655,
			2051.686,
			2053.04,
			2055.165,
			2056.625,
			2058.811,
			2060.367,
			2062.751,
			2064.336,
			2065.145,
			2066.781,
			2069.226,
			2070.838,
			2072.424,
			2074.803,
			2075.599,
			2076.402,
			2078.006,
			2079.611,
			2080.414,
			2082.839,
			2084.456,
			2086.073,
			2088.42,
			2089.975,
			2092.305,
			2093.07,
			2093.834,
			2096.127,
			2097.62,
			2099.784,
			2101.229,
			2102.665,
			2103.367,
			2104.772,
			2105.476,
			2106.889,
			2109.025,
			2110.454,
			2111.891,
			2114.088,
			2115.572,
			2117.812,
			2119.333,
			2121.644,
			2123.197,
			2125.559,
			2128.755,
			2131.189,
			2132.829,
			2136.146,
			2136.976,
			2139.475,
			2143.674,
			2145.37,
			2147.077,
			2147.934,
			2150.527,
			2152.272,
			2154.031,
			2156.694,
			2158.485,
			2161.197,
			2162.108,
			2163.024,
			2165.79,
			2167.649,
			2170.461,
			2172.351,
			2174.263,
			2175.225,
			2177.158,
			2180.085,
			2182.053,
			2184.033,
			2187.046,
			2189.069,
			2192.131,
			2194.187,
			2196.249,
			2197.279,
			2200.375,
			2202.45,
			2204.534,
			2207.678,
			2209.783,
			2212.96,
			2214.026,
			2215.096,
			2218.319,
			2220.481,
			2223.741,
			2225.935,
			2229.252,
			2231.478,
			2234.838,
			2238.223,
			2240.494,
			2242.776,
			2245.07,
			2247.375,
			2248.512,
			2252.006,
			2253.175,
			2254.368,
			2257.905,
			2260.281,
			2262.669,
			2266.281,
			2268.684,
			2272.382,
			2273.613,
			2274.846,
			2278.561,
			2281.056,
			2284.838,
			2287.377,
			2289.925,
			2291.194,
			2293.733,
			2295.006,
			2297.56,
			2301.411,
			2303.967,
			2306.531,
			2310.384,
			2312.961,
			2316.835,
			2318.129,
			2319.425,
			2323.315,
			2325.919,
			2329.844,
			2332.469,
			2335.095,
			2339.031,
			2340.353,
			2341.678,
			2345.666,
			2348.334,
			2352.349,
			2355.037,
			2357.726,
			2361.768,
			2364.471,
			2365.825,
			2368.533,
			2369.889,
			2372.605,
			2375.324,
			2379.41,
			2382.139,
			2386.24,
			2388.981,
			2393.104,
			2395.859,
			2400.004,
			2405.552,
			2411.123,
			2413.918,
			2416.719,
			2418.121,
			2419.526,
			2423.745,
			2426.562,
			2430.781,
			2433.578,
			2436.349,
			2437.724,
			2441.792,
			2444.458,
			2447.087,
			2453.495,
			2457.229,
			2459.67,
			2463.266,
			2465.62,
			2469.086,
			2471.35,
			2474.676,
			2476.846,
			2480.03,
			2483.125,
			2484.139,
			2487.118,
			2490.014,
			2491.917,
			2493.773,
			2496.521,
			2499.239,
			2501.044,
			2503.723,
			2505.492,
			2507.255,
			2510.748,
			2511.617,
			2514.216,
			2515.937,
			2518.515,
			2520.24,
			2521.967,
			2522.831,
			2525.433,
			2527.184,
			2529.81,
			2531.579,
			2533.354,
			2536.015,
			2537.807,
			2540.493,
			2542.288,
			2544.986,
			2546.783,
			2548.582,
			2551.278,
			2553.081,
			2556.695,
			2557.6,
			2560.331,
			2562.149,
			2564.902,
			2566.74,
			2569.532,
			2573.291,
			2574.24,
			2576.14,
			2577.091,
			2578.995,
			2580.945,
			2583.877,
			2585.811,
			2588.703,
			2589.669,
			2590.636,
			2593.538,
			2595.486,
			2598.424,
			2600.396,
			2602.395,
			2603.394,
			2605.393,
			2606.393,
			2607.393,
			2611.39,
			2613.404,
			2615.415,
			2617.419,
			2620.417,
			2622.379,
			2624.32,
			2625.29,
			2628.159,
			2630.054,
			2631.949,
			2634.791,
			2636.685,
			2639.541,
			2640.493,
			2641.445,
			2644.311,
			2646.225,
			2649.102,
			2651.025,
			2653.913,
			2655.835,
			2656.797,
			2658.724,
			2661.617,
			2663.551,
			2665.483,
			2668.374,
			2669.342,
			2670.312,
			2672.238,
			2674.175,
			2675.149,
			2680.028,
			2681.995,
			2684.956,
			2686.945,
			2689.94,
			2690.944,
			2691.951,
			2694.987,
			2697.024,
			2700.103,
			2702.163,
			2704.225,
			2705.259,
			2707.325,
			2710.473,
			2713.665,
			2715.809,
			2717.974,
			2721.245,
			2723.446,
			2726.788,
			2729.028,
			2732.406,
			2734.68,
			2736.958,
			2740.397,
			2742.705,
			2746.18,
			2747.342,
			2748.503,
			2753.135,
			2754.3,
			2757.809,
			2760.16,
			2763.704,
			2766.08,
			2769.665,
			2772.089,
			2773.295,
			2775.712,
			2778.141,
			2781.805,
			2784.269,
			2787.968,
			2790.481,
			2794.226,
			2796.688,
			2800.363,
			2802.774,
			2805.143,
			2806.312,
			2808.622,
			2809.757,
			2814.188,
			2815.274,
			2816.34,
			2819.484,
			2821.55,
			2824.528,
			2826.471,
			2828.392,
			2829.342,
			2832.163,
			2834.011,
			2835.841,
			2838.573,
			2840.398,
			2843.135,
			2844.967,
			2847.718,
			2849.555,
			2852.33,
			2854.18,
			2856.979,
			2858.852,
			2859.788,
			2861.67,
			2864.512,
			2865.459,
			2868.312,
			2872.121,
			2873.074,
			2874.982,
			2876.888,
			2877.841,
			2880.711,
			2882.628,
			2884.546,
			2887.462,
			2889.411,
			2892.369,
			2893.362,
			2894.356,
			2897.356,
			2899.376,
			2902.415,
			2904.453,
			2906.492,
			2907.513,
			2909.542,
			2912.584,
			2915.643,
			2917.685,
			2919.736,
			2922.831,
			2924.9,
			2928.054,
			2929.106,
			2930.161,
			2932.304,
			2933.381,
			2935.539,
			2938.755,
			2940.9,
			2943.046,
			2946.265,
			2947.343,
			2948.415,
			2951.632,
			2953.787,
			2957.009,
			2959.17,
			2962.417,
			2964.591,
			2967.86,
			2971.143,
			2973.314,
			2975.493,
			2978.74,
			2980.898,
			2984.128,
			2985.201,
			2986.276,
			2989.495,
			2991.663,
			2994.953,
			2997.169,
			2999.406,
			3000.534,
			3002.798,
			3003.934,
			3005.073,
			3008.506,
			3009.655,
			3010.805,
			3014.271,
			3016.606,
			3020.143,
			3022.525,
			3024.924,
			3026.127,
			3028.557,
			3029.765,
			3032.187,
			3034.608,
			3040.756,
			3044.448,
			3045.68,
			3046.914,
			3053.125,
			3056.875,
			3059.375,
			3063.147,
			3065.656,
			3066.923,
			3069.457,
			3073.283,
			3075.821,
			3078.36,
			3080.912,
			3083.473,
			3084.754,
			3087.301,
			3089.863,
			3091.167,
			3095.016,
			3097.583,
			3103.991,
			3106.564,
			3110.453,
			3111.744,
			3113.038,
			3116.919,
			3119.513,
			3123.413,
			3126.017,
			3128.629,
			3129.935,
			3132.543,
			3135.155,
			3136.461,
			3140.388,
			3143.01,
			3145.638,
			3149.583,
			3152.218,
			3156.177,
			3158.818,
			3161.464,
			3162.789,
			3165.443,
			3168.105,
			3172.098,
			3174.814,
			3178.874,
			3180.232,
			3182.93,
			3185.655,
			3188.386,
			3192.495,
			3195.242,
			3197.999,
			3199.38,
			3203.533,
			3206.309,
			3209.091,
			3213.277,
			3216.077,
			3220.286,
			3223.106,
			3227.347,
			3228.766,
			3230.186,
			3234.46,
			3237.324,
			3241.647,
			3244.546,
			3247.435,
			3254.62,
			3258.905,
			3260.331,
			3261.758,
			3266.05,
			3268.92,
			3273.244,
			3279.043,
			3280.499,
			3284.882,
			3287.813,
			3290.755,
			3295.181,
			3298.141,
			3302.593,
			3305.568,
			3310.04,
			3313.028,
			3317.521,
			3320.522,
			3325.033,
			3328.047,
			3329.555,
			3332.576,
			3337.116,
			3340.149,
			3343.186,
			3346.229,
			3349.276,
			3350.802,
			3353.857,
			3356.918,
			3358.45,
			3363.053,
			3366.129,
			3369.211,
			3370.753,
			3373.843,
			3376.937,
			3381.588,
			3383.141,
			3384.695,
			3389.365,
			3392.493,
			3397.2,
			3400.345,
			3403.507,
			3405.09,
			3408.245,
			3409.819,
			3412.968,
			3417.661,
			3420.788,
			3423.911,
			3428.618,
			3431.769,
			3436.539,
			3438.135,
			3439.734,
			3444.537,
			3447.748,
			3450.96,
			3459.002,
			3463.837,
			3465.451,
			3467.066,
			3471.914,
			3475.15,
			3480.012,
			3483.254,
			3488.1,
			3494.475,
			3496.046,
			3499.159,
			3500.701,
			3503.757,
			3506.779,
			3511.251,
			3514.191,
			3518.533,
			3519.964,
			3521.385,
			3525.594,
			3528.353,
			3532.419,
			3535.083,
			3539.003,
			3541.568,
			3542.835,
			3545.34,
			3547.809,
			3550.238,
			3553.817,
			3556.16,
			3559.608,
			3564.069,
			3565.16,
			3568.378,
			3570.497,
			3572.562,
			3575.604,
			3577.601,
			3581.556,
			3582.536,
			3585.47,
			3587.43,
			3590.364,
			3592.327,
			3595.292,
			3597.277,
			3599.274,
			3600.276,
			3603.296,
			3605.325,
			3607.361,
			3609.411,
			3612.526,
			3616.721,
			3619.896,
			3623.109,
			3625.234,
			3628.443,
			3630.567,
			3633.756,
			3634.814,
			3635.865,
			3639.02,
			3641.116,
			3644.202,
			3646.227,
			3648.251,
			3649.241,
			3651.22,
			3652.212,
			3654.2,
			3657.182,
			3659.178,
			3661.163,
			3664.145,
			3666.129,
			3669.081,
			3670.069,
			3671.058,
			3674.004,
			3675.973,
			3677.914,
			3680.822,
			3682.753,
			3686.624,
			3687.598,
			3691.51,
			3692.495,
			3695.458,
			3697.447,
			3700.449,
			3702.464,
			3705.505,
			3708.568,
			3710.628,
			3712.702,
			3715.834,
			3717.946,
			3722.185,
			3723.26,
			3726.517,
			3728.693,
			3731.984,
			3734.192,
			3737.535,
			3739.754,
			3740.875,
			3742.017,
			3745.405,
			3747.659,
			3751.087,
			3753.386,
			3756.873,
			3759.172,
			3761.505,
			3762.675,
			3766.204,
			3768.578,
			3770.957,
			3774.522,
			3776.886,
			3780.38,
			3781.533,
			3782.679,
			3786.085,
			3788.282,
			3791.539,
			3793.666,
			3796.794,
			3799.845,
			3801.835,
			3804.763,
			3806.686,
			3808.612,
			3811.458,
			3812.404,
			3813.348,
			3816.168,
			3817.105,
			3818.042,
			3820.819,
			3822.664,
			3824.493,
			3827.219,
			3829.033,
			3832.641,
			3833.543,
			3836.279,
			3838.101,
			3840.954,
			3842.912,
			3844.912,
			3846,
			3848.171,
			3849.256,
			3851.397,
			3854.591,
			3858.795,
			3861.881,
			3863.888,
			3867.862,
			3868.832,
			3870.772,
			3873.615,
			3875.461,
			3878.234,
			3880.039,
			3882.774,
			3884.598,
			3887.35,
			3889.192,
			3891.988,
			3893.86,
			3896.697,
			3900.524,
			3901.487,
			3903.413,
			3904.38,
			3906.322,
			3908.272,
			3911.217,
			3913.199,
			3916.194,
			3917.198,
			3918.209,
			3921.253,
			3923.295,
			3926.376,
			3928.442,
			3930.517,
			3933.645,
			3935.745,
			3938.913,
			3939.974,
			3941.038,
			3944.246,
			3946.396,
			3949.646,
			3951.824,
			3955.109,
			3958.413,
			3960.609,
			3962.851,
			3968.454,
			3971.827,
			3972.962,
			3974.099,
			3977.525,
			3979.822,
			3983.29,
			3985.614,
			3989.123,
			3991.475,
			3992.658,
			3995.03,
			3997.412,
			3998.61,
			4001.013,
			4003.427,
			4007.067,
			4008.286,
			4009.507,
			4011.956,
			4015.648,
			4019.361,
			4021.847,
			4024.343,
			4028.104,
			4030.622,
			4034.416,
			4036.956,
			4040.783,
			4043.344,
			4047.205,
			4049.789,
			4052.384,
			4053.684,
			4056.287,
			4057.589,
			4060.199,
			4064.127,
			4066.756,
			4069.392,
			4073.359,
			4076.014,
			4078.675,
			4082.683,
			4085.364,
			4086.708,
			4089.4,
			4092.101,
			4098.887,
			4104.355,
			4105.728,
			4109.856,
			4112.619,
			4116.774,
			4119.552,
			4123.727,
			4126.517,
			4127.914,
			4130.713,
			4133.52,
			4137.743,
			4140.567,
			4144.816,
			4147.657,
			4153.362,
			4154.793,
			4159.096,
			4166.299,
			4169.19,
			4172.089,
			4177.906,
			4179.365,
			4183.752,
			4186.685,
			4191.096,
			4194.046,
			4197.005,
			4198.488,
			4204.437,
			4205.927,
			4213.427,
			4216.438,
			4220.959,
			4223.976,
			4228.507,
			4231.531,
			4236.076,
			4239.111,
			4243.673,
			4249.775,
			4251.304,
			4254.366,
			4255.899,
			4258.97,
			4263.584,
			4266.667,
			4269.755,
			4272.848,
			4277.496,
			4280.601,
			4283.71,
			4285.267,
			4288.385,
			4289.947,
			4293.075,
			4296.208,
			4300.92,
			4304.066,
			4305.562
		],
		[
			0,
			0.014,
			0.077,
			0.14,
			0.201,
			0.264,
			0.339,
			0.386,
			0.449,
			0.512,
			0.557,
			0.574,
			0.636,
			0.667,
			0.696,
			0.773,
			0.82,
			0.884,
			0.945,
			1.005,
			1.07,
			1.1,
			1.131,
			1.193,
			1.254,
			1.317,
			1.378,
			1.442,
			1.504,
			1.565,
			1.64,
			1.688,
			1.755,
			1.86,
			1.876,
			1.936,
			1.999,
			2.06,
			2.078,
			2.123,
			2.186,
			2.248,
			2.31,
			2.372,
			2.402,
			2.433,
			2.512,
			2.556,
			2.619,
			2.681,
			2.729,
			2.744,
			2.836,
			2.869,
			2.944,
			2.99,
			3.055,
			3.115,
			3.177,
			3.239,
			3.271,
			3.302,
			3.363,
			3.38,
			3.424,
			3.489,
			3.55,
			3.595,
			3.673,
			3.703,
			3.735,
			3.818,
			3.86,
			3.923,
			3.985,
			4.045,
			4.107,
			4.138,
			4.169,
			4.246,
			4.295,
			4.357,
			4.418,
			4.478,
			4.543,
			4.573,
			4.603,
			4.681,
			4.729,
			4.789,
			4.898,
			4.915,
			4.974,
			5.006,
			5.037,
			5.101,
			5.115,
			5.162,
			5.223,
			5.286,
			5.348,
			5.41,
			5.471,
			5.551,
			5.596,
			5.66,
			5.766,
			5.845,
			5.905,
			5.969,
			6.029,
			6.094,
			6.155,
			6.217,
			6.278,
			6.34,
			6.417,
			6.465,
			6.527,
			6.589,
			6.634,
			6.65,
			6.713,
			6.746,
			6.773,
			6.85,
			6.899,
			6.961,
			7.021,
			7.084,
			7.147,
			7.209,
			7.269,
			7.333,
			7.394,
			7.456,
			7.517,
			7.579,
			7.612,
			7.644,
			7.72,
			7.767,
			7.829,
			7.889,
			7.951,
			8.016,
			8.045,
			8.076,
			8.155,
			8.202,
			8.263,
			8.324,
			8.386,
			8.449,
			8.478,
			8.511,
			8.589,
			8.633,
			8.757,
			8.82,
			8.883,
			8.913,
			8.945,
			9.022,
			9.07,
			9.131,
			9.193,
			9.253,
			9.316,
			9.346,
			9.379,
			9.455,
			9.504,
			9.566,
			9.628,
			9.671,
			9.782,
			9.813,
			9.873,
			9.89,
			9.936,
			10.005,
			10.06,
			10.121,
			10.216,
			10.247,
			10.324,
			10.37,
			10.434,
			10.496,
			10.543,
			10.617,
			10.649,
			10.682,
			10.743,
			10.804,
			10.867,
			10.929,
			10.991,
			11.054,
			11.116,
			11.194,
			11.24,
			11.301,
			11.362,
			11.407,
			11.424,
			11.487,
			11.517,
			11.548,
			11.627,
			11.672,
			11.736,
			11.798,
			11.859,
			11.92,
			11.984,
			12.045,
			12.106,
			12.169,
			12.232,
			12.279,
			12.293,
			12.355,
			12.386,
			12.417,
			12.494,
			12.543,
			12.605,
			12.666,
			12.728,
			12.79,
			12.82,
			12.852,
			12.912,
			12.928,
			12.976,
			13.036,
			13.1,
			13.161,
			13.223,
			13.255,
			13.284,
			13.364,
			13.416,
			13.471,
			13.534,
			13.595,
			13.657,
			13.689,
			13.719,
			13.783,
			13.796,
			13.844,
			13.906,
			13.968,
			14.029,
			14.09,
			14.153,
			14.232,
			14.278,
			14.339,
			14.403,
			14.449,
			14.556,
			14.588,
			14.651,
			14.665,
			14.713,
			14.775,
			14.836,
			14.898,
			14.96,
			15.023,
			15.099,
			15.146,
			15.211,
			15.316,
			15.395,
			15.426,
			15.456,
			15.518,
			15.579,
			15.643,
			15.702,
			15.765,
			15.829,
			15.86,
			15.89,
			15.967,
			16.012,
			16.077,
			16.138,
			16.187,
			16.201,
			16.263,
			16.293,
			16.322,
			16.385,
			16.402,
			16.449,
			16.514,
			16.571,
			16.635,
			16.697,
			16.729,
			16.757,
			16.821,
			16.882,
			16.945,
			17.051,
			17.068,
			17.161,
			17.192,
			17.268,
			17.316,
			17.378,
			17.44,
			17.502,
			17.596,
			17.627,
			17.689,
			17.75,
			17.812,
			17.876,
			17.92,
			18.028,
			18.06,
			18.138,
			18.185,
			18.246,
			18.307,
			18.37,
			18.431,
			18.462,
			18.495,
			18.555,
			18.572,
			18.619,
			18.68,
			18.74,
			18.787,
			18.805,
			18.866,
			18.898,
			18.93,
			19.004,
			19.051,
			19.116,
			19.177,
			19.237,
			19.301,
			19.332,
			19.363,
			19.438,
			19.487,
			19.549,
			19.61,
			19.658,
			19.674,
			19.734,
			19.766,
			19.796,
			19.875,
			19.921,
			19.984,
			20.044,
			20.107,
			20.167,
			20.231,
			20.294,
			20.318,
			20.355,
			20.433,
			20.478,
			20.54,
			20.65,
			20.666,
			20.727,
			20.757,
			20.79,
			20.867,
			20.913,
			20.975,
			21.037,
			21.098,
			21.161,
			21.193,
			21.223,
			21.284,
			21.348,
			21.409,
			21.47,
			21.516,
			21.594,
			21.626,
			21.658,
			21.733,
			21.78,
			21.848,
			21.904,
			21.968,
			22.06,
			22.089,
			22.151,
			22.215,
			22.279,
			22.338,
			22.402,
			22.463,
			22.526,
			22.601,
			22.648,
			22.712,
			22.773,
			22.819,
			22.898,
			22.957,
			23.021,
			23.083,
			23.146,
			23.207,
			23.27,
			23.332,
			23.363,
			23.393,
			23.47,
			23.516,
			23.579,
			23.64,
			23.688,
			23.704,
			23.766,
			23.828,
			23.889,
			23.951,
			24.014,
			24.076,
			24.138,
			24.2,
			24.262,
			24.339,
			24.386,
			24.451,
			24.509,
			24.557,
			24.57,
			24.634,
			24.665,
			24.694,
			24.759,
			24.774,
			24.82,
			24.882,
			24.944,
			25.006,
			25.068,
			25.099,
			25.129,
			25.207,
			25.255,
			25.317,
			25.379,
			25.423,
			25.439,
			25.502,
			25.533,
			25.565,
			25.639,
			25.686,
			25.812,
			25.873,
			25.934,
			25.967,
			25.998,
			26.06,
			26.121,
			26.184,
			26.245,
			26.293,
			26.371,
			26.4,
			26.432,
			26.514,
			26.555,
			26.617,
			26.679,
			26.74,
			26.835,
			26.867,
			26.929,
			26.944,
			26.99,
			27.053,
			27.113,
			27.176,
			27.236,
			27.269,
			27.3,
			27.378,
			27.424,
			27.487,
			27.546,
			27.594,
			27.733,
			27.795,
			27.857,
			27.919,
			27.982,
			28.044,
			28.106,
			28.137,
			28.167,
			28.244,
			28.29,
			28.354,
			28.416,
			28.478,
			28.54,
			28.579,
			28.603,
			28.665,
			28.727,
			28.789,
			28.851,
			28.913,
			28.973,
			29.006,
			29.036,
			29.098,
			29.114,
			29.16,
			29.222,
			29.283,
			29.33,
			29.346,
			29.41,
			29.44,
			29.47,
			29.548,
			29.595,
			29.657,
			29.717,
			29.781,
			29.873,
			29.903,
			29.966,
			30.028,
			30.089,
			30.152,
			30.2,
			30.216,
			30.277,
			30.31,
			30.339,
			30.416,
			30.464,
			30.523,
			30.585,
			30.648,
			30.743,
			30.773,
			30.834,
			30.851,
			30.895,
			30.959,
			31.019,
			31.068,
			31.145,
			31.173,
			31.206,
			31.284,
			31.329,
			31.394,
			31.454,
			31.516,
			31.579,
			31.61,
			31.64,
			31.702,
			31.719,
			31.763,
			31.828,
			31.887,
			31.95,
			32.013,
			32.045,
			32.076,
			32.151,
			32.2,
			32.26,
			32.368,
			32.446,
			32.479,
			32.57,
			32.632,
			32.699,
			32.757,
			32.818,
			32.879,
			32.912,
			32.944,
			33.019,
			33.066,
			33.129,
			33.19,
			33.236,
			33.253,
			33.315,
			33.346,
			33.377,
			33.501,
			33.564,
			33.626,
			33.687,
			33.75,
			33.781,
			33.812,
			33.873,
			33.89,
			33.935,
			33.996,
			34.06,
			34.105,
			34.122,
			34.182,
			34.244,
			34.324,
			34.368,
			34.432,
			34.493,
			34.555,
			34.618,
			34.647,
			34.678,
			34.761,
			34.802,
			34.866,
			34.928,
			34.975,
			34.989,
			35.05,
			35.081,
			35.112,
			35.19,
			35.236,
			35.362,
			35.422,
			35.484,
			35.517,
			35.547,
			35.61,
			35.624,
			35.67,
			35.733,
			35.842,
			35.95,
			35.981,
			36.059,
			36.106,
			36.167,
			36.23,
			36.29,
			36.354,
			36.383,
			36.415,
			36.479,
			36.491,
			36.539,
			36.603,
			36.663,
			36.71,
			36.724,
			36.824,
			36.849,
			36.928,
			36.972,
			37.036,
			37.098,
			37.144,
			37.251,
			37.285,
			37.346,
			37.406,
			37.469,
			37.532,
			37.594,
			37.655,
			37.688,
			37.718,
			37.795,
			37.841,
			37.903,
			37.967,
			38.013,
			38.028,
			38.089,
			38.12,
			38.151,
			38.214,
			38.274,
			38.338,
			38.399,
			38.462,
			38.523,
			38.587,
			38.647,
			38.664,
			38.71,
			38.771,
			38.834,
			38.904,
			38.961,
			39.004,
			39.02,
			39.082,
			39.113,
			39.144,
			39.205,
			39.221,
			39.269,
			39.329,
			39.393,
			39.454,
			39.547,
			39.579,
			39.64,
			39.702,
			39.765,
			39.873,
			39.951,
			40.012,
			40.09,
			40.137,
			40.198,
			40.26,
			40.323,
			40.384,
			40.416,
			40.446,
			40.507,
			40.524,
			40.571,
			40.632,
			40.695,
			40.739,
			40.818,
			40.85,
			40.88,
			40.964,
			41.003,
			41.067,
			41.127,
			41.189,
			41.253,
			41.284,
			41.315,
			41.377,
			41.394,
			41.439,
			41.501,
			41.563,
			41.623,
			41.685,
			41.716,
			41.747,
			41.826,
			41.872,
			41.933,
			42.043,
			42.121,
			42.182,
			42.246,
			42.305,
			42.37,
			42.431,
			42.494,
			42.553,
			42.586,
			42.617,
			42.694,
			42.739,
			42.803,
			42.865,
			42.911,
			42.927,
			42.988,
			43.021,
			43.05,
			43.128,
			43.174,
			43.236,
			43.298,
			43.361,
			43.423,
			43.455,
			43.484,
			43.545,
			43.607,
			43.671,
			43.732,
			43.779,
			43.793,
			43.857,
			43.888,
			43.918,
			43.997,
			44.04,
			44.106,
			44.167,
			44.229,
			44.289,
			44.354,
			44.416,
			44.477,
			44.54,
			44.648,
			44.724,
			44.787,
			44.864,
			44.912,
			44.971,
			45.096,
			45.16,
			45.189,
			45.221,
			45.299,
			45.345,
			45.408,
			45.47,
			45.53,
			45.593,
			45.623,
			45.655,
			45.732,
			45.779,
			45.849,
			45.904,
			45.95,
			45.966,
			46.028,
			46.089,
			46.152,
			46.213,
			46.277,
			46.337,
			46.4,
			46.461,
			46.494,
			46.522,
			46.601,
			46.646,
			46.711,
			46.772,
			46.817,
			46.896,
			46.928,
			46.956,
			47.019,
			47.081,
			47.148,
			47.206,
			47.266,
			47.329,
			47.39,
			47.467,
			47.516,
			47.579,
			47.638,
			47.686,
			47.702,
			47.764,
			47.794,
			47.827,
			47.902,
			47.95,
			48.012,
			48.073,
			48.137,
			48.198,
			48.229,
			48.257,
			48.32,
			48.385,
			48.445,
			48.507,
			48.553,
			48.57,
			48.631,
			48.662,
			48.693,
			48.772,
			48.817,
			48.879,
			48.942,
			49.005,
			49.066,
			49.096,
			49.127,
			49.19,
			49.251,
			49.315,
			49.377,
			49.421,
			49.498,
			49.531,
			49.561,
			49.638,
			49.685,
			49.748,
			49.811,
			49.855,
			49.933,
			49.966,
			49.997,
			50.057,
			50.071,
			50.12,
			50.182,
			50.245,
			50.304,
			50.369,
			50.43,
			50.508,
			50.555,
			50.617,
			50.724,
			50.832,
			50.864,
			50.927,
			50.941,
			50.988,
			51.05,
			51.111,
			51.176,
			51.235,
			51.272,
			51.298,
			51.373,
			51.423,
			51.485,
			51.593,
			51.67,
			51.733,
			51.794,
			51.857,
			51.917,
			51.981,
			52.043,
			52.104,
			52.166,
			52.245,
			52.288,
			52.352,
			52.414,
			52.477,
			52.537,
			52.599,
			52.677,
			52.723,
			52.79,
			52.848,
			52.91,
			53.002,
			53.035,
			53.096,
			53.159,
			53.22,
			53.283,
			53.333,
			53.346,
			53.437,
			53.47,
			53.545,
			53.593,
			53.654,
			53.716,
			53.778,
			53.84,
			53.902,
			53.979,
			54.028,
			54.088,
			54.151,
			54.196,
			54.307,
			54.335,
			54.413,
			54.46,
			54.521,
			54.585,
			54.647,
			54.739,
			54.769,
			54.832,
			54.848,
			54.895,
			54.961,
			55.02,
			55.081,
			55.14,
			55.174,
			55.205,
			55.283,
			55.329,
			55.398,
			55.452,
			55.5,
			55.516,
			55.577,
			55.607,
			55.638,
			55.716,
			55.764,
			55.83,
			55.886,
			55.95,
			56.011,
			56.043,
			56.073,
			56.151,
			56.198,
			56.257,
			56.321,
			56.367,
			56.445,
			56.477,
			56.507,
			56.57,
			56.632,
			56.694,
			56.754,
			56.818,
			56.878,
			56.911,
			56.942,
			57.017,
			57.066,
			57.127,
			57.19,
			57.236,
			57.25,
			57.313,
			57.344,
			57.376,
			57.5,
			57.562,
			57.623,
			57.684,
			57.746,
			57.779,
			57.809,
			57.879,
			57.933,
			57.995,
			58.057,
			58.104,
			58.118,
			58.182,
			58.245,
			58.322,
			58.368,
			58.428,
			58.489,
			58.553,
			58.614,
			58.678,
			58.74,
			58.801,
			58.862,
			58.927,
			58.973,
			59.05,
			59.081,
			59.112,
			59.19,
			59.235,
			59.296,
			59.359,
			59.422,
			59.482,
			59.544,
			59.606,
			59.622,
			59.669,
			59.732,
			59.794,
			59.856,
			59.917,
			59.981,
			60.056,
			60.102,
			60.166,
			60.229,
			60.273,
			60.29,
			60.35,
			60.383,
			60.476,
			60.489,
			60.538,
			60.601,
			60.663,
			60.723,
			60.786,
			60.816,
			60.85,
			60.926,
			60.972,
			61.034,
			61.096,
			61.157,
			61.221,
			61.281,
			61.343,
			61.404,
			61.467,
			61.531,
			61.592,
			61.653,
			61.686,
			61.715,
			61.793,
			61.838,
			61.902,
			62.012,
			62.026,
			62.087,
			62.12,
			62.151,
			62.229,
			62.275,
			62.338,
			62.398,
			62.461,
			62.522,
			62.554,
			62.585,
			62.647,
			62.706,
			62.77,
			62.832,
			62.879,
			62.894,
			62.956,
			63.019,
			63.094,
			63.14,
			63.205,
			63.266,
			63.329,
			63.389,
			63.42,
			63.452,
			63.513,
			63.529,
			63.577,
			63.644,
			63.701,
			63.745,
			63.825,
			63.856,
			63.886,
			63.962,
			64.009,
			64.073,
			64.133,
			64.198,
			64.259,
			64.32,
			64.397,
			64.444,
			64.506,
			64.568,
			64.63,
			64.693,
			64.723,
			64.755,
			64.832,
			64.878,
			64.939,
			65.002,
			65.049,
			65.065,
			65.126,
			65.156,
			65.189,
			65.251,
			65.266,
			65.313,
			65.376,
			65.435,
			65.499,
			65.562,
			65.592,
			65.622,
			65.684,
			65.705,
			65.746,
			65.81,
			65.918,
			65.993,
			66.024,
			66.055,
			66.179,
			66.243,
			66.303,
			66.366,
			66.429,
			66.461,
			66.49,
			66.568,
			66.615,
			66.68,
			66.738,
			66.786,
			66.799,
			66.861,
			66.894,
			66.924,
			67.002,
			67.048,
			67.171,
			67.235,
			67.298,
			67.328,
			67.359,
			67.421,
			67.483,
			67.543,
			67.606,
			67.654,
			67.668,
			67.73,
			67.766,
			67.794,
			67.872,
			67.918,
			67.978,
			68.043,
			68.102,
			68.166,
			68.227,
			68.288,
			68.306,
			68.352,
			68.413,
			68.476,
			68.536,
			68.598,
			68.629,
			68.669,
			68.738,
			68.784,
			68.847,
			68.909,
			68.956,
			68.972,
			69.063,
			69.096,
			69.157,
			69.218,
			69.282,
			69.344,
			69.406,
			69.466,
			69.498,
			69.529,
			69.605,
			69.653,
			69.717,
			69.776,
			69.829,
			69.962,
			70.023,
			70.042,
			70.088,
			70.15,
			70.212,
			70.274,
			70.366,
			70.396,
			70.474,
			70.52,
			70.584,
			70.647,
			70.693,
			70.768,
			70.832,
			70.894,
			70.957,
			71.019,
			71.078,
			71.142,
			71.203,
			71.234,
			71.266,
			71.341,
			71.391,
			71.451,
			71.513,
			71.56,
			71.576,
			71.637,
			71.668,
			71.701,
			71.777,
			71.823,
			71.886,
			71.892,
			71.947,
			72.01,
			72.073,
			72.101,
			72.134,
			72.195,
			72.259,
			72.32,
			72.381,
			72.429,
			72.443,
			72.505,
			72.537,
			72.566,
			72.645,
			72.693,
			72.753,
			72.817,
			72.878,
			72.94,
			72.972,
			73.001,
			73.064,
			73.126,
			73.187,
			73.297,
			73.373,
			73.404,
			73.437,
			73.513,
			73.56,
			73.622,
			73.684,
			73.746,
			73.839,
			73.869,
			73.932,
			73.954,
			73.994,
			74.057,
			74.118,
			74.179,
			74.242,
			74.272,
			74.303,
			74.381,
			74.429,
			74.494,
			74.553,
			74.614,
			74.678,
			74.707,
			74.739,
			74.8,
			74.862,
			74.923,
			74.987,
			75.048,
			75.14,
			75.173,
			75.25,
			75.295,
			75.357,
			75.42,
			75.482,
			75.574,
			75.605,
			75.667,
			75.731,
			75.79,
			75.853,
			75.917,
			75.978,
			76.015,
			76.04,
			76.117,
			76.164,
			76.227,
			76.287,
			76.35,
			76.443,
			76.537,
			76.598,
			76.662,
			76.724,
			76.784,
			76.848,
			76.878,
			76.91,
			76.987,
			77.033,
			77.094,
			77.157,
			77.202,
			77.218,
			77.281,
			77.312,
			77.345,
			77.421,
			77.466,
			77.53,
			77.589,
			77.653,
			77.714,
			77.744,
			77.777,
			77.84,
			77.901,
			77.963,
			78.023,
			78.085,
			78.181,
			78.211,
			78.289,
			78.333,
			78.397,
			78.46,
			78.52,
			78.584,
			78.645,
			78.721,
			78.769,
			78.832,
			78.894,
			78.955,
			79.048,
			79.077,
			79.154,
			79.202,
			79.268,
			79.327,
			79.389,
			79.45,
			79.481,
			79.513,
			79.576,
			79.636,
			79.7,
			79.761,
			79.822,
			79.884,
			79.917,
			79.946,
			80.025,
			80.07,
			80.138,
			80.195,
			80.245,
			80.32,
			80.351,
			80.382,
			80.443,
			80.504,
			80.568,
			80.63,
			80.69,
			80.783,
			80.815,
			80.894,
			80.939,
			81.001,
			81.064,
			81.112,
			81.124,
			81.19,
			81.221,
			81.249,
			81.327,
			81.372,
			81.437,
			81.498,
			81.559,
			81.652,
			81.684,
			81.744,
			81.806,
			81.87,
			81.932,
			81.979,
			81.994,
			82.054,
			82.087,
			82.117,
			82.2,
			82.303,
			82.365,
			82.427,
			82.521,
			82.552,
			82.613,
			82.676,
			82.737,
			82.8,
			82.846,
			82.922,
			82.987,
			83.063,
			83.11,
			83.173,
			83.234,
			83.296,
			83.389,
			83.418,
			83.481,
			83.498,
			83.545,
			83.606,
			83.667,
			83.728,
			83.793,
			83.823,
			83.854,
			83.931,
			83.978,
			84.04,
			84.101,
			84.149,
			84.224,
			84.287,
			84.351,
			84.365,
			84.41,
			84.473,
			84.535,
			84.598,
			84.66,
			84.721,
			84.799,
			84.847,
			84.91,
			85.016,
			85.094,
			85.125,
			85.155,
			85.235,
			85.279,
			85.341,
			85.403,
			85.466,
			85.529,
			85.557,
			85.589,
			85.652,
			85.668,
			85.714,
			85.778,
			85.839,
			85.886,
			85.9,
			85.961,
			86.025,
			86.1,
			86.148,
			86.211,
			86.272,
			86.335,
			86.396,
			86.458,
			86.519,
			86.583,
			86.644,
			86.707,
			86.751,
			86.767,
			86.829,
			86.862,
			86.89,
			86.968,
			87.017,
			87.079,
			87.14,
			87.201,
			87.263,
			87.327,
			87.387,
			87.404,
			87.451,
			87.513,
			87.621,
			87.73,
			87.761,
			87.838,
			87.885,
			87.948,
			88.007,
			88.069,
			88.132,
			88.163,
			88.194,
			88.256,
			88.318,
			88.387,
			88.442,
			88.505,
			88.599,
			88.629,
			88.706,
			88.816,
			88.877,
			88.938,
			89.032,
			89.063,
			89.138,
			89.186,
			89.249,
			89.311,
			89.356,
			89.371,
			89.466,
			89.496,
			89.619,
			89.682,
			89.745,
			89.807,
			89.868,
			89.932,
			89.99,
			90.055,
			90.118,
			90.223,
			90.239,
			90.303,
			90.335,
			90.365,
			90.448,
			90.49,
			90.551,
			90.613,
			90.674,
			90.737,
			90.767,
			90.8,
			90.86,
			90.877,
			90.924,
			90.986,
			91.048,
			91.093,
			91.121
		]
	],
	'2521_5794_7982': [
		[
			50.165
		],
		[
			0
		],
		[
			0,
			3.265,
			8.01,
			11.18,
			14.356,
			19.128,
			22.317,
			27.106,
			30.301,
			35.103,
			38.31,
			43.125,
			47.95,
			51.17,
			56.006,
			59.235,
			62.467,
			67.32,
			68.94,
			70.56,
			77.05,
			78.675,
			83.556,
			86.814,
			90.075,
			94.975,
			98.246,
			103.159,
			104.799,
			106.439,
			113.01,
			114.655,
			119.595,
			126.197,
			127.849,
			132.813,
			136.126,
			139.441,
			141.101,
			144.421,
			147.746,
			152.739,
			156.07,
			161.07,
			162.739,
			164.407,
			169.412,
			172.737,
			177.68,
			184.148,
			185.74,
			188.893,
			193.548,
			196.601,
			198.114,
			199.617,
			204.069,
			208.435,
			211.297,
			214.121,
			218.279,
			222.346,
			225.007,
			227.631,
			231.491,
			234.016,
			237.728,
			238.945,
			240.153,
			243.717,
			246.047,
			249.473,
			251.707,
			254.987,
			257.123,
			259.221,
			260.254,
			262.293,
			263.298,
			265.278,
			267.221,
			270.993,
			271.913,
			273.727,
			275.52,
			276.395,
			278.974,
			280.651,
			282.302,
			284.736,
			286.335,
			288.7,
			289.483,
			290.264,
			292.592,
			294.13,
			296.431,
			297.944,
			299.451,
			300.204,
			301.71,
			302.464,
			303.973,
			306.234,
			307.797,
			309.401,
			311.805,
			313.617,
			316.351,
			317.298,
			318.318,
			321.375,
			323.428,
			325.484,
			328.389,
			330.21,
			332.798,
			334.347,
			336.669,
			338.124,
			340.255,
			343.136,
			343.874,
			345.348,
			347.588,
			349.153,
			349.935,
			351.501,
			353.095,
			355.515,
			357.132,
			359.585,
			360.404,
			361.225,
			363.688,
			365.322,
			367.809,
			369.469,
			371.142,
			373.663,
			375.353,
			378.762,
			379.62,
			382.214,
			383.956,
			386.593,
			390.148,
			391.043,
			393.749,
			395.568,
			397.4,
			400.168,
			402.029,
			404.838,
			406.725,
			411.489,
			414.384,
			416.328,
			419.267,
			421.241,
			424.223,
			426.224,
			427.229,
			429.246,
			431.275,
			435.367,
			436.398,
			440.549,
			441.594,
			444.727,
			446.807,
			448.895,
			452.051,
			454.167,
			457.358,
			459.499,
			462.722,
			464.882,
			468.161,
			472.572,
			473.682,
			477.029,
			479.275,
			482.665,
			484.938,
			487.221,
			490.662,
			492.968,
			496.444,
			498.772,
			502.282,
			504.633,
			506.993,
			510.551,
			512.933,
			516.524,
			517.725,
			518.929,
			523.767,
			524.982,
			528.64,
			531.089,
			533.545,
			537.248,
			539.728,
			540.971,
			543.464,
			544.714,
			547.22,
			549.735,
			553.524,
			556.06,
			561.157,
			562.437,
			566.289,
			568.867,
			572.75,
			575.349,
			577.955,
			579.26,
			581.868,
			583.173,
			584.48,
			588.41,
			589.723,
			591.038,
			594.995,
			597.642,
			601.623,
			604.28,
			608.249,
			612.169,
			614.742,
			617.277,
			621.009,
			623.449,
			627.039,
			628.217,
			629.387,
			632.846,
			635.106,
			638.425,
			640.591,
			643.773,
			645.847,
			648.891,
			650.89,
			651.867,
			653.797,
			655.689,
			657.547,
			659.37,
			660.27,
			662.918,
			663.784,
			664.642,
			667.176,
			668.837,
			670.481,
			672.929,
			674.554,
			676.986,
			677.803,
			678.618,
			681.065,
			682.71,
			685.19,
			686.844,
			688.513,
			689.346,
			691.013,
			691.846,
			693.52,
			696.028,
			697.703,
			699.379,
			701.89,
			703.574,
			705.255,
			706.935,
			707.781,
			710.314,
			712.002,
			713.701,
			716.245,
			717.947,
			720.501,
			722.204,
			725.616,
			726.468,
			729.029,
			730.733,
			733.291,
			734.995,
			737.551,
			739.258,
			740.109,
			741.811,
			743.523,
			746.087,
			747.799,
			750.369,
			751.224,
			752.079,
			754.653,
			756.365,
			758.943,
			763.233,
			764.951,
			765.808,
			767.525,
			770.112,
			770.972,
			773.565,
			777.025,
			777.895,
			779.634,
			782.24,
			784.858,
			786.602,
			788.356,
			790.993,
			792.759,
			796.311,
			797.2,
			799.877,
			801.663,
			804.347,
			806.136,
			808.823,
			810.612,
			811.506,
			813.295,
			815.973,
			817.753,
			819.519,
			821.281,
			823.032,
			825.631,
			827.361,
			828.216,
			829.924,
			832.481,
			834.168,
			836.692,
			838.365,
			840.859,
			842.505,
			844.966,
			846.606,
			849.058,
			850.697,
			852.347,
			853.175,
			854.839,
			855.677,
			857.377,
			859.079,
			859.936,
			861.659,
			863.4,
			866.044,
			867.826,
			870.545,
			871.458,
			872.374,
			875.176,
			877.063,
			879.963,
			881.92,
			883.903,
			886.915,
			888.959,
			892.068,
			893.116,
			894.18,
			897.39,
			899.565,
			902.857,
			905.062,
			907.276,
			908.386,
			911.719,
			913.946,
			916.176,
			919.53,
			921.771,
			926.245,
			927.363,
			930.716,
			936.307,
			938.544,
			941.897,
			944.128,
			945.241,
			946.353,
			949.688,
			950.798,
			951.908,
			955.222,
			957.424,
			959.621,
			960.718,
			962.913,
			965.109,
			966.208,
			969.499,
			971.688,
			973.872,
			977.122,
			979.28,
			982.497,
			983.568,
			984.63,
			987.814,
			989.923,
			993.072,
			995.152,
			997.225,
			998.262,
			1000.317,
			1001.343,
			1003.395,
			1006.458,
			1008.499,
			1010.554,
			1013.645,
			1015.746,
			1019.981,
			1021.056,
			1023.193,
			1026.47,
			1028.67,
			1034.285,
			1037.674,
			1038.833,
			1039.975,
			1043.414,
			1045.707,
			1049.152,
			1051.452,
			1054.914,
			1057.212,
			1060.712,
			1063.035,
			1064.199,
			1066.536,
			1068.879,
			1072.402,
			1074.758,
			1078.297,
			1079.479,
			1080.663,
			1084.224,
			1086.606,
			1088.996,
			1092.606,
			1095.025,
			1098.667,
			1099.885,
			1101.105,
			1104.777,
			1107.234,
			1110.935,
			1113.412,
			1117.139,
			1119.633,
			1122.134,
			1123.388,
			1127.157,
			1129.677,
			1132.203,
			1136.005,
			1137.274,
			1138.546,
			1142.375,
			1144.937,
			1148.797,
			1151.378,
			1155.261,
			1157.857,
			1160.439,
			1165.659,
			1166.967,
			1172.213,
			1173.525,
			1177.458,
			1180.086,
			1182.716,
			1186.671,
			1189.313,
			1190.637,
			1193.284,
			1194.61,
			1197.266,
			1199.926,
			1203.923,
			1206.594,
			1210.609,
			1211.949,
			1213.291,
			1217.326,
			1220.022,
			1224.079,
			1226.792,
			1229.515,
			1230.878,
			1233.615,
			1234.985,
			1237.731,
			1240.483,
			1241.861,
			1243.242,
			1247.394,
			1251.563,
			1254.352,
			1257.147,
			1259.949,
			1261.352,
			1265.569,
			1268.386,
			1271.208,
			1275.448,
			1278.281,
			1282.539,
			1283.961,
			1285.385,
			1289.662,
			1292.52,
			1296.816,
			1299.686,
			1304.003,
			1306.888,
			1311.231,
			1314.135,
			1315.589,
			1318.502,
			1321.422,
			1325.813,
			1327.28,
			1328.749,
			1334.643,
			1336.12,
			1340.56,
			1343.526,
			1347.984,
			1350.963,
			1353.946,
			1359.929,
			1361.428,
			1365.932,
			1368.941,
			1373.464,
			1376.484,
			1379.509,
			1381.023,
			1384.055,
			1387.091,
			1388.61,
			1393.177,
			1396.226,
			1399.282,
			1403.876,
			1406.944,
			1411.557,
			1413.097,
			1414.638,
			1419.27,
			1422.365,
			1427.017,
			1430.125,
			1434.795,
			1437.915,
			1439.477,
			1441.04,
			1447.304,
			1448.873,
			1453.587,
			1459.89,
			1464.632,
			1467.799,
			1469.385,
			1472.561,
			1474.15,
			1477.333,
			1480.518,
			1485.299,
			1488.491,
			1493.287,
			1496.49,
			1501.302,
			1504.515,
			1509.343,
			1512.563,
			1517.379,
			1522.149,
			1523.724,
			1528.394,
			1529.932,
			1532.985,
			1536.004,
			1540.47,
			1543.401,
			1546.293,
			1549.146,
			1550.558,
			1554.737,
			1557.476,
			1560.177,
			1564.154,
			1566.758,
			1570.594,
			1571.853,
			1573.103,
			1576.796,
			1579.211,
			1582.768,
			1585.097,
			1588.519,
			1590.755,
			1591.858,
			1594.038,
			1597.256,
			1599.347,
			1601.404,
			1605.427,
			1606.416,
			1610.318,
			1611.284,
			1614.166,
			1616.087,
			1618.007,
			1620.888,
			1622.816,
			1625.709,
			1626.678,
			1627.648,
			1630.56,
			1631.539,
			1632.524,
			1635.489,
			1637.49,
			1639.506,
			1640.516,
			1642.558,
			1644.611,
			1645.639,
			1648.768,
			1650.861,
			1652.991,
			1658.355,
			1661.59,
			1663.756,
			1666.989,
			1669.128,
			1672.309,
			1676.475,
			1679.525,
			1680.541,
			1682.56,
			1685.574,
			1686.579,
			1689.563,
			1691.554,
			1694.521,
			1696.502,
			1698.48,
			1699.469,
			1702.44,
			1704.417,
			1706.396,
			1709.362,
			1711.34,
			1714.302,
			1715.289,
			1716.276,
			1719.236,
			1721.209,
			1724.174,
			1726.153,
			1729.132,
			1731.124,
			1732.122,
			1734.112,
			1736.128,
			1737.14,
			1739.177,
			1741.224,
			1743.286,
			1745.361,
			1746.403,
			1748.498,
			1750.605,
			1751.663,
			1754.862,
			1757.009,
			1759.174,
			1764.645,
			1767.966,
			1769.079,
			1770.195,
			1773.558,
			1775.811,
			1779.21,
			1781.489,
			1784.927,
			1787.231,
			1788.387,
			1790.706,
			1794.202,
			1796.54,
			1798.877,
			1802.363,
			1804.662,
			1808.057,
			1809.171,
			1812.46,
			1813.538,
			1815.668,
			1818.795,
			1820.834,
			1822.838,
			1825.777,
			1826.74,
			1827.693,
			1830.499,
			1832.334,
			1835.064,
			1836.86,
			1838.648,
			1839.538,
			1841.311,
			1843.959,
			1845.713,
			1846.588,
			1848.335,
			1850.077,
			1852.68,
			1854.408,
			1857.849,
			1858.711,
			1861.292,
			1863.012,
			1864.768,
			1867.405,
			1869.23,
			1872.038,
			1872.975,
			1873.983,
			1877.062,
			1878.089,
			1879.159,
			1882.38,
			1884.481,
			1887.584,
			1891.433,
			1892.383,
			1894.229,
			1895.13,
			1896.933,
			1898.736,
			1901.422,
			1903.221,
			1905.919,
			1906.822,
			1907.727,
			1910.457,
			1912.29,
			1916.937,
			1919.77,
			1921.679,
			1924.567,
			1926.51,
			1927.487,
			1928.466,
			1931.419,
			1933.404,
			1935.396,
			1936.396,
			1938.405,
			1940.423,
			1941.436,
			1944.493,
			1946.544,
			1948.606,
			1951.709,
			1953.78,
			1956.906,
			1957.936,
			1958.987,
			1962.157,
			1964.301,
			1967.509,
			1969.659,
			1972.884,
			1975.06,
			1976.152,
			1978.344,
			1981.654,
			1983.874,
			1986.107,
			1990.604,
			1991.734,
			1994.001,
			1997.425,
			1999.72,
			2000.873,
			2003.186,
			2006.676,
			2009.016,
			2011.368,
			2014.914,
			2016.1,
			2017.289,
			2020.869,
			2023.266,
			2026.881,
			2029.303,
			2031.736,
			2032.957,
			2035.404,
			2036.631,
			2039.091,
			2042.803,
			2045.291,
			2047.791,
			2051.562,
			2054.089,
			2057.899,
			2060.451,
			2064.296,
			2066.869,
			2070.742,
			2073.334,
			2075.934,
			2079.843,
			2081.144,
			2082.448,
			2087.678,
			2088.99,
			2092.936,
			2095.576,
			2099.549,
			2104.873,
			2106.208,
			2108.885,
			2110.226,
			2112.915,
			2115.611,
			2119.675,
			2122.396,
			2126.492,
			2127.861,
			2129.233,
			2133.357,
			2136.115,
			2140.262,
			2145.815,
			2147.207,
			2149.996,
			2151.393,
			2154.191,
			2156.997,
			2161.218,
			2164.041,
			2166.87,
			2171.127,
			2173.974,
			2176.827,
			2178.257,
			2182.558,
			2185.435,
			2188.319,
			2192.659,
			2195.561,
			2199.926,
			2202.845,
			2207.233,
			2210.166,
			2214.577,
			2217.525,
			2221.961,
			2226.412,
			2229.386,
			2233.858,
			2236.847,
			2239.841,
			2244.342,
			2245.845,
			2247.349,
			2250.362,
			2254.891,
			2257.916,
			2262.464,
			2265.503,
			2270.073,
			2273.127,
			2277.718,
			2279.251,
			2280.786,
			2285.398,
			2288.479,
			2293.111,
			2296.206,
			2299.306,
			2300.858,
			2303.967,
			2305.523,
			2308.639,
			2313.323,
			2316.452,
			2319.586,
			2324.299,
			2327.448,
			2328.298
		],
		[
			0,
			0.055,
			0.121,
			0.176,
			0.238,
			0.3,
			0.363,
			0.426,
			0.488,
			0.55,
			0.611,
			0.673,
			0.767,
			0.797,
			0.876,
			0.92,
			0.984,
			1.046,
			1.092,
			1.109,
			1.199,
			1.232,
			1.295,
			1.355,
			1.417,
			1.479,
			1.543,
			1.603,
			1.636,
			1.665,
			1.744,
			1.79,
			1.852,
			1.962,
			1.975,
			2.068,
			2.099,
			2.163,
			2.183,
			2.225,
			2.285,
			2.347,
			2.409,
			2.471,
			2.504,
			2.533,
			2.611,
			2.657,
			2.72,
			2.829,
			2.844,
			2.905,
			2.968,
			3.029,
			3.045,
			3.091,
			3.16,
			3.219,
			3.279,
			3.34,
			3.402,
			3.481,
			3.526,
			3.587,
			3.651,
			3.711,
			3.774,
			3.804,
			3.837,
			3.915,
			3.961,
			4.02,
			4.083,
			4.147,
			4.209,
			4.246,
			4.27,
			4.332,
			4.349,
			4.394,
			4.455,
			4.566,
			4.579,
			4.643,
			4.674,
			4.705,
			4.782,
			4.828,
			4.891,
			4.951,
			5.015,
			5.076,
			5.108,
			5.14,
			5.202,
			5.264,
			5.326,
			5.387,
			5.433,
			5.449,
			5.511,
			5.541,
			5.574,
			5.651,
			5.696,
			5.76,
			5.822,
			5.881,
			5.946,
			5.976,
			6.008,
			6.068,
			6.131,
			6.193,
			6.254,
			6.317,
			6.378,
			6.442,
			6.518,
			6.565,
			6.627,
			6.736,
			6.749,
			6.813,
			6.876,
			6.937,
			6.951,
			6.999,
			7.062,
			7.124,
			7.186,
			7.245,
			7.277,
			7.31,
			7.387,
			7.433,
			7.494,
			7.557,
			7.618,
			7.681,
			7.742,
			7.819,
			7.865,
			7.931,
			7.991,
			8.054,
			8.147,
			8.177,
			8.254,
			8.301,
			8.368,
			8.426,
			8.472,
			8.548,
			8.612,
			8.736,
			8.796,
			8.859,
			8.92,
			8.982,
			9.046,
			9.108,
			9.122,
			9.169,
			9.232,
			9.34,
			9.354,
			9.448,
			9.479,
			9.558,
			9.604,
			9.665,
			9.727,
			9.788,
			9.85,
			9.914,
			9.976,
			10.037,
			10.098,
			10.208,
			10.221,
			10.315,
			10.349,
			10.43,
			10.474,
			10.532,
			10.595,
			10.658,
			10.72,
			10.782,
			10.859,
			10.904,
			10.968,
			11.029,
			11.075,
			11.154,
			11.185,
			11.216,
			11.293,
			11.34,
			11.402,
			11.462,
			11.509,
			11.588,
			11.619,
			11.651,
			11.713,
			11.727,
			11.772,
			11.837,
			11.898,
			11.958,
			12.051,
			12.084,
			12.162,
			12.209,
			12.27,
			12.332,
			12.379,
			12.394,
			12.455,
			12.493,
			12.518,
			12.579,
			12.594,
			12.641,
			12.703,
			12.764,
			12.827,
			12.891,
			12.951,
			13.03,
			13.077,
			13.137,
			13.199,
			13.247,
			13.322,
			13.354,
			13.387,
			13.448,
			13.51,
			13.571,
			13.635,
			13.696,
			13.758,
			13.82,
			13.883,
			13.897,
			13.945,
			14.006,
			14.068,
			14.114,
			14.129,
			14.194,
			14.222,
			14.254,
			14.332,
			14.379,
			14.439,
			14.501,
			14.564,
			14.626,
			14.658,
			14.687,
			14.75,
			14.813,
			14.872,
			14.935,
			14.983,
			14.998,
			15.058,
			15.091,
			15.121,
			15.198,
			15.245,
			15.309,
			15.369,
			15.433,
			15.493,
			15.526,
			15.555,
			15.619,
			15.681,
			15.743,
			15.807,
			15.849,
			15.928,
			15.991,
			16.069,
			16.113,
			16.176,
			16.237,
			16.302,
			16.362,
			16.426,
			16.485,
			16.502,
			16.548,
			16.614,
			16.673,
			16.733,
			16.797,
			16.826,
			16.86,
			16.937,
			16.983,
			17.044,
			17.169,
			17.232,
			17.262,
			17.294,
			17.371,
			17.418,
			17.48,
			17.587,
			17.603,
			17.666,
			17.726,
			17.805,
			17.849,
			17.914,
			17.975,
			18.038,
			18.13,
			18.161,
			18.222,
			18.285,
			18.348,
			18.409,
			18.471,
			18.533,
			18.565,
			18.594,
			18.677,
			18.719,
			18.78,
			18.842,
			18.89,
			18.965,
			18.997,
			19.029,
			19.09,
			19.154,
			19.215,
			19.278,
			19.338,
			19.401,
			19.462,
			19.541,
			19.586,
			19.651,
			19.71,
			19.757,
			19.774,
			19.834,
			19.866,
			19.897,
			19.96,
			19.975,
			20.02,
			20.082,
			20.144,
			20.207,
			20.268,
			20.299,
			20.331,
			20.409,
			20.455,
			20.518,
			20.58,
			20.642,
			20.702,
			20.766,
			20.827,
			20.843,
			20.887,
			20.952,
			21.013,
			21.076,
			21.136,
			21.168,
			21.198,
			21.277,
			21.324,
			21.384,
			21.448,
			21.495,
			21.601,
			21.633,
			21.71,
			21.819,
			21.88,
			21.944,
			22.005,
			22.037,
			22.067,
			22.13,
			22.145,
			22.191,
			22.254,
			22.316,
			22.362,
			22.376,
			22.44,
			22.472,
			22.5,
			22.579,
			22.624,
			22.69,
			22.75,
			22.812,
			22.873,
			22.904,
			22.936,
			22.998,
			23.061,
			23.122,
			23.183,
			23.229,
			23.246,
			23.308,
			23.337,
			23.369,
			23.447,
			23.494,
			23.555,
			23.619,
			23.679,
			23.774,
			23.805,
			23.864,
			23.927,
			23.99,
			24.099,
			24.176,
			24.205,
			24.236,
			24.316,
			24.363,
			24.422,
			24.487,
			24.547,
			24.61,
			24.672,
			24.734,
			24.748,
			24.796,
			24.862,
			24.921,
			24.98,
			25.044,
			25.075,
			25.104,
			25.183,
			25.229,
			25.292,
			25.354,
			25.416,
			25.479,
			25.509,
			25.541,
			25.602,
			25.664,
			25.729,
			25.788,
			25.849,
			25.912,
			25.944,
			25.975,
			26.053,
			26.098,
			26.16,
			26.223,
			26.268,
			26.283,
			26.347,
			26.408,
			26.47,
			26.531,
			26.594,
			26.655,
			26.718,
			26.81,
			26.842,
			26.925,
			26.967,
			27.029,
			27.091,
			27.138,
			27.213,
			27.245,
			27.276,
			27.337,
			27.354,
			27.399,
			27.463,
			27.522,
			27.587,
			27.647,
			27.678,
			27.71,
			27.787,
			27.835,
			27.897,
			27.959,
			28.004,
			28.021,
			28.083,
			28.113,
			28.144,
			28.205,
			28.225,
			28.267,
			28.331,
			28.393,
			28.455,
			28.515,
			28.548,
			28.579,
			28.654,
			28.702,
			28.765,
			28.828,
			28.874,
			28.951,
			28.987,
			29.014,
			29.088,
			29.138,
			29.2,
			29.262,
			29.321,
			29.386,
			29.446,
			29.509,
			29.525,
			29.571,
			29.633,
			29.695,
			29.742,
			29.758,
			29.849,
			29.881,
			29.959,
			30.003,
			30.069,
			30.128,
			30.191,
			30.284,
			30.316,
			30.375,
			30.437,
			30.5,
			30.564,
			30.611,
			30.625,
			30.687,
			30.718,
			30.75,
			30.826,
			30.873,
			30.936,
			30.996,
			31.059,
			31.123,
			31.152,
			31.182,
			31.246,
			31.31,
			31.37,
			31.431,
			31.494,
			31.556,
			31.585,
			31.617,
			31.694,
			31.742,
			31.804,
			31.912,
			31.99,
			32.021,
			32.052,
			32.112,
			32.13,
			32.176,
			32.241,
			32.299,
			32.361,
			32.424,
			32.485,
			32.562,
			32.609,
			32.673,
			32.732,
			32.796,
			32.886,
			32.918,
			32.981,
			32.996,
			33.043,
			33.11,
			33.169,
			33.229,
			33.291,
			33.322,
			33.354,
			33.43,
			33.478,
			33.541,
			33.601,
			33.647,
			33.725,
			33.755,
			33.787,
			33.849,
			33.911,
			33.974,
			34.035,
			34.098,
			34.16,
			34.189,
			34.221,
			34.3,
			34.347,
			34.412,
			34.515,
			34.53,
			34.624,
			34.657,
			34.733,
			34.779,
			34.841,
			34.903,
			34.966,
			35.028,
			35.058,
			35.09,
			35.15,
			35.171,
			35.213,
			35.277,
			35.336,
			35.383,
			35.401,
			35.462,
			35.494,
			35.524,
			35.6,
			35.649,
			35.713,
			35.835,
			35.897,
			35.958,
			36.018,
			36.082,
			36.144,
			36.257,
			36.329,
			36.36,
			36.393,
			36.47,
			36.517,
			36.578,
			36.641,
			36.702,
			36.764,
			36.796,
			36.826,
			36.902,
			36.951,
			37.013,
			37.072,
			37.119,
			37.198,
			37.233,
			37.259,
			37.338,
			37.384,
			37.449,
			37.509,
			37.569,
			37.632,
			37.664,
			37.693,
			37.755,
			37.77,
			37.819,
			37.88,
			37.941,
			37.988,
			38.005,
			38.065,
			38.098,
			38.129,
			38.205,
			38.252,
			38.313,
			38.437,
			38.499,
			38.531,
			38.561,
			38.64,
			38.685,
			38.75,
			38.81,
			38.871,
			38.935,
			38.966,
			38.995,
			39.075,
			39.119,
			39.182,
			39.245,
			39.296,
			39.369,
			39.398,
			39.492,
			39.509,
			39.554,
			39.618,
			39.679,
			39.742,
			39.802,
			39.834,
			39.866,
			39.942,
			39.99,
			40.05,
			40.112,
			40.158,
			40.175,
			40.236,
			40.298,
			40.359,
			40.377,
			40.421,
			40.486,
			40.547,
			40.61,
			40.7,
			40.732,
			40.809,
			40.858,
			40.917,
			40.98,
			41.027,
			41.105,
			41.135,
			41.166,
			41.228,
			41.245,
			41.288,
			41.352,
			41.415,
			41.477,
			41.569,
			41.601,
			41.662,
			41.679,
			41.727,
			41.786,
			41.85,
			41.894,
			41.974,
			42.004,
			42.035,
			42.113,
			42.157,
			42.283,
			42.345,
			42.407,
			42.47,
			42.531,
			42.547,
			42.592,
			42.653,
			42.717,
			42.763,
			42.778,
			42.841,
			42.872,
			42.903,
			42.98,
			43.028,
			43.088,
			43.151,
			43.197,
			43.276,
			43.306,
			43.338,
			43.4,
			43.462,
			43.524,
			43.584,
			43.647,
			43.709,
			43.741,
			43.77,
			43.847,
			43.896,
			43.957,
			44.066,
			44.081,
			44.142,
			44.207,
			44.268,
			44.282,
			44.33,
			44.394,
			44.453,
			44.514,
			44.576,
			44.609,
			44.639,
			44.702,
			44.763,
			44.827,
			44.889,
			44.933,
			44.95,
			45.013,
			45.042,
			45.072,
			45.152,
			45.196,
			45.259,
			45.32,
			45.385,
			45.446,
			45.508,
			45.585,
			45.633,
			45.694,
			45.754,
			45.802,
			45.878,
			45.911,
			45.941,
			46.019,
			46.066,
			46.127,
			46.19,
			46.251,
			46.343,
			46.376,
			46.437,
			46.454,
			46.5,
			46.561,
			46.624,
			46.687,
			46.749,
			46.78,
			46.811,
			46.887,
			46.935,
			46.995,
			47.105,
			47.12,
			47.182,
			47.214,
			47.244,
			47.304,
			47.368,
			47.43,
			47.492,
			47.555,
			47.616,
			47.646,
			47.677,
			47.757,
			47.803,
			47.863,
			47.927,
			47.973,
			48.05,
			48.111,
			48.174,
			48.236,
			48.299,
			48.359,
			48.422,
			48.515,
			48.545,
			48.625,
			48.671,
			48.731,
			48.793,
			48.841,
			48.857,
			48.917,
			48.98,
			49.042,
			49.104,
			49.165,
			49.229,
			49.29,
			49.353,
			49.384,
			49.415,
			49.491,
			49.539,
			49.604,
			49.663,
			49.709,
			49.724,
			49.787,
			49.816,
			49.847,
			49.926,
			49.971,
			50.033,
			50.096,
			50.158,
			50.165
		]
	],
	'5275_5276_7982': [
		[
			128.969
		],
		[
			0
		],
		[
			0,
			4.083,
			5.764,
			7.445,
			14.179,
			15.865,
			20.929,
			24.309,
			29.388,
			32.778,
			36.172,
			37.87,
			41.27,
			42.971,
			46.376,
			49.785,
			54.905,
			58.323,
			63.457,
			65.17,
			66.885,
			72.033,
			75.47,
			80.633,
			84.079,
			87.529,
			89.255,
			92.71,
			94.439,
			97.901,
			101.365,
			103.099,
			106.569,
			110.042,
			113.519,
			117,
			118.742,
			122.228,
			125.717,
			127.463,
			130.957,
			132.706,
			136.207,
			139.71,
			144.972,
			148.484,
			153.759,
			155.519,
			157.279,
			162.567,
			166.096,
			171.397,
			174.935,
			178.476,
			180.248,
			183.794,
			185.568,
			189.119,
			192.673,
			194.452,
			198.012,
			201.574,
			206.924,
			208.709,
			210.496,
			214.071,
			217.649,
			219.439,
			224.815,
			228.403,
			231.995,
			237.388,
			240.988,
			246.396,
			248.2,
			250.004,
			255.424,
			259.042,
			264.474,
			268.101,
			271.729,
			273.544,
			277.177,
			278.994,
			282.63,
			286.269,
			288.09,
			291.734,
			295.38,
			300.857,
			304.514,
			310.007,
			311.84,
			313.674,
			319.179,
			322.852,
			326.528,
			332.045,
			335.725,
			341.25,
			343.093,
			344.936,
			350.468,
			354.158,
			359.698,
			363.394,
			367.092,
			368.943,
			372.646,
			376.352,
			378.206,
			383.772,
			387.485,
			391.201,
			396.778,
			400.499,
			406.083,
			407.946,
			409.809,
			415.404,
			419.139,
			424.746,
			428.487,
			432.231,
			434.103,
			437.851,
			439.726,
			443.478,
			447.231,
			449.108,
			452.862,
			456.607,
			460.333,
			464.034,
			465.873,
			469.522,
			473.132,
			474.921,
			480.226,
			483.715,
			487.164,
			492.266,
			495.617,
			500.571,
			502.204,
			503.827,
			508.639,
			511.799,
			516.468,
			519.532,
			524.063,
			527.039,
			528.512,
			531.431,
			534.312,
			535.739,
			537.156,
			541.352,
			545.464,
			548.157,
			552.126,
			553.43,
			554.725,
			557.287,
			558.553,
			561.059,
			563.527,
			567.163,
			569.544,
			573.052,
			574.204,
			575.348,
			578.745,
			579.853,
			580.953,
			584.201,
			586.317,
			588.399,
			589.428,
			591.454,
			592.452,
			594.426,
			596.37,
			597.331,
			599.239,
			601.132,
			603.972,
			604.92,
			605.868,
			608.725,
			609.681,
			610.637,
			613.506,
			615.421,
			617.332,
			620.208,
			622.128,
			625.02,
			625.988,
			626.956,
			629.868,
			631.818,
			634.746,
			636.704,
			639.649,
			641.634,
			643.627,
			644.627,
			646.632,
			647.639,
			649.659,
			651.687,
			654.739,
			656.786,
			659.884,
			660.921,
			661.96,
			665.088,
			667.182,
			670.343,
			672.459,
			674.578,
			675.635,
			677.754,
			678.815,
			679.878,
			683.079,
			684.148,
			685.222,
			688.473,
			690.652,
			692.816,
			693.9,
			696.076,
			698.246,
			699.329,
			701.504,
			702.595,
			704.769,
			706.95,
			710.232,
			712.424,
			715.723,
			716.822,
			717.923,
			721.24,
			723.46,
			726.806,
			729.056,
			731.316,
			732.449,
			734.716,
			735.851,
			738.128,
			741.568,
			743.871,
			746.2,
			748.539,
			752.052,
			754.392,
			756.725,
			757.888,
			760.192,
			761.372,
			763.688,
			766.003,
			769.47,
			771.783,
			775.25,
			776.416,
			777.583,
			781.088,
			783.437,
			786.979,
			789.354,
			791.749,
			792.95,
			795.361,
			796.571,
			798.999,
			801.436,
			802.659,
			805.109,
			807.55,
			811.231,
			812.464,
			813.698,
			816.2,
			818.741,
			820.015,
			822.569,
			823.849,
			826.402,
			828.946,
			832.778,
			835.345,
			839.211,
			840.505,
			841.801,
			845.701,
			848.312,
			852.233,
			854.859,
			857.494,
			858.815,
			861.462,
			864.112,
			865.441,
			868.101,
			869.434,
			872.106,
			874.784,
			878.817,
			880.166,
			881.516,
			885.577,
			886.934,
			888.294,
			892.384,
			895.12,
			899.239,
			901.994,
			904.757,
			908.914,
			910.303,
			911.695,
			915.879,
			917.277,
			918.677,
			922.888,
			925.705,
			929.945,
			932.782,
			935.626,
			937.051,
			939.907,
			941.338,
			944.206,
			947.081,
			951.408,
			954.301,
			958.653,
			960.107,
			961.563,
			965.941,
			967.403,
			968.867,
			973.268,
			976.212,
			979.162,
			980.639,
			983.599,
			985.081,
			988.05,
			991.025,
			992.515,
			994.007,
			998.49,
			1001.484,
			1005.971,
			1010.422,
			1011.892,
			1013.354,
			1017.688,
			1020.531,
			1023.335,
			1027.47,
			1030.179,
			1034.173,
			1035.486,
			1036.789,
			1040.645,
			1043.169,
			1046.885,
			1049.317,
			1051.712,
			1052.896,
			1055.24,
			1056.4,
			1058.692,
			1060.945,
			1062.057,
			1064.254,
			1066.413,
			1069.583,
			1070.622,
			1071.651,
			1074.684,
			1075.676,
			1076.658,
			1079.552,
			1081.451,
			1083.302,
			1086.029,
			1087.821,
			1090.48,
			1091.359,
			1092.235,
			1094.852,
			1096.597,
			1099.21,
			1100.958,
			1102.71,
			1103.585,
			1105.339,
			1106.22,
			1107.982,
			1109.745,
			1110.631,
			1112.407,
			1114.184,
			1116.864,
			1117.76,
			1118.656,
			1121.363,
			1122.275,
			1123.187,
			1125.933,
			1127.799,
			1130.596,
			1132.472,
			1134.345,
			1135.281,
			1137.144,
			1138.072,
			1139.929,
			1142.72,
			1143.651,
			1146.441,
			1148.294,
			1150.147,
			1151.073,
			1152.908,
			1154.743,
			1155.662,
			1157.507,
			1158.433,
			1160.287,
			1162.148,
			1164.952,
			1166.823,
			1169.612,
			1170.541,
			1171.469,
			1174.237,
			1176.073,
			1178.815,
			1180.633,
			1182.451,
			1183.355,
			1185.164,
			1186.068,
			1187.877,
			1190.576,
			1192.379,
			1194.173,
			1196.866,
			1198.666,
			1200.465,
			1202.273,
			1203.18,
			1205.017,
			1205.94,
			1207.794,
			1209.684,
			1212.564,
			1214.503,
			1217.444,
			1218.43,
			1219.418,
			1222.399,
			1224.402,
			1227.436,
			1229.456,
			1231.506,
			1232.535,
			1234.6,
			1235.635,
			1237.714,
			1239.803,
			1240.851,
			1242.945,
			1245.039,
			1248.195,
			1249.252,
			1250.312,
			1253.506,
			1254.576,
			1255.647,
			1257.796,
			1258.874,
			1261.036,
			1263.205,
			1266.477,
			1268.67,
			1271.978,
			1273.086,
			1274.197,
			1277.544,
			1279.788,
			1283.175,
			1285.446,
			1287.728,
			1288.873,
			1291.17,
			1293.479,
			1294.637,
			1298.126,
			1300.466,
			1302.815,
			1306.358,
			1307.544,
			1308.732,
			1312.311,
			1313.509,
			1314.708,
			1318.322,
			1320.742,
			1324.389,
			1326.831,
			1329.282,
			1332.975,
			1334.211,
			1335.448,
			1339.172,
			1340.418,
			1341.666,
			1345.421,
			1347.935,
			1351.722,
			1354.256,
			1356.798,
			1358.073,
			1360.626,
			1361.906,
			1364.472,
			1367.046,
			1370.921,
			1373.515,
			1377.422,
			1378.727,
			1380.031,
			1383.948,
			1386.566,
			1390.506,
			1393.141,
			1395.782,
			1397.106,
			1399.758,
			1401.086,
			1403.75,
			1407.762,
			1410.447,
			1413.139,
			1417.187,
			1419.893,
			1422.604,
			1425.322,
			1426.682,
			1429.408,
			1430.774,
			1433.509,
			1436.25,
			1440.374,
			1443.131,
			1447.278,
			1448.664,
			1450.051,
			1454.222,
			1457.011,
			1461.206,
			1464.01,
			1468.229,
			1471.048,
			1472.46,
			1475.29,
			1478.125,
			1479.546,
			1482.393,
			1485.248,
			1489.544,
			1490.979,
			1492.417,
			1496.737,
			1498.18,
			1499.625,
			1502.517,
			1503.966,
			1506.865,
			1509.771,
			1514.14,
			1517.059,
			1521.45,
			1522.917,
			1524.385,
			1528.799,
			1531.749,
			1536.187,
			1539.152,
			1542.123,
			1543.611,
			1546.592,
			1549.58,
			1551.076,
			1554.073,
			1555.575,
			1558.583,
			1561.6,
			1566.138,
			1567.654,
			1569.171,
			1573.733,
			1575.257,
			1576.783,
			1581.37,
			1584.435,
			1589.044,
			1592.125,
			1595.215,
			1599.864,
			1601.417,
			1602.971,
			1609.209,
			1610.774,
			1615.476,
			1618.617,
			1623.334,
			1626.482,
			1629.631,
			1631.208,
			1634.363,
			1635.943,
			1639.11,
			1642.283,
			1647.055,
			1650.24,
			1655.026,
			1656.623,
			1658.22,
			1663.008,
			1666.203,
			1671.003,
			1674.208,
			1677.417,
			1679.024,
			1682.24,
			1683.851,
			1687.076,
			1690.307,
			1691.925,
			1695.165,
			1698.408,
			1703.283,
			1704.91,
			1706.537,
			1709.796,
			1713.059,
			1714.691,
			1717.96,
			1719.596,
			1722.871,
			1726.15,
			1731.078,
			1734.368,
			1739.312,
			1740.962,
			1742.614,
			1747.575,
			1750.889,
			1755.869,
			1759.194,
			1764.19,
			1767.526,
			1769.195,
			1772.535,
			1775.881,
			1777.555,
			1780.906,
			1784.26,
			1789.3,
			1790.981,
			1792.664,
			1796.033,
			1799.406,
			1801.093,
			1804.472,
			1806.163,
			1809.548,
			1812.937,
			1818.029,
			1821.429,
			1826.536,
			1828.24,
			1829.945,
			1835.068,
			1838.488,
			1843.627,
			1847.058,
			1850.494,
			1852.213,
			1855.655,
			1857.377,
			1860.825,
			1864.277,
			1866.004,
			1869.461,
			1872.922,
			1878.12,
			1881.589,
			1886.799,
			1888.538,
			1890.278,
			1895.502,
			1898.99,
			1902.482,
			1907.727,
			1911.228,
			1916.486,
			1918.241,
			1919.996,
			1925.267,
			1927.025,
			1928.784,
			1934.068,
			1937.594,
			1941.125,
			1942.891,
			1946.426,
			1951.736,
			1955.281,
			1957.055,
			1960.606,
			1964.16,
			1969.495,
			1973.056,
			1978.401,
			1980.184,
			1981.967,
			1987.321,
			1990.895,
			1996.262,
			1999.846,
			2003.434,
			2005.229,
			2008.823,
			2010.621,
			2014.22,
			2017.823,
			2019.625,
			2023.233,
			2026.845,
			2030.461,
			2034.081,
			2035.892,
			2039.517,
			2043.148,
			2044.964,
			2048.602,
			2050.422,
			2054.066,
			2057.714,
			2063.192,
			2066.848,
			2072.34,
			2074.172,
			2076.005,
			2081.509,
			2085.181,
			2090.695,
			2094.376,
			2098.06,
			2099.905,
			2103.597,
			2105.444,
			2109.141,
			2114.69,
			2118.392,
			2122.095,
			2127.658,
			2131.37,
			2136.945,
			2138.805,
			2140.666,
			2144.389,
			2146.251,
			2149.976,
			2153.698,
			2159.255,
			2162.929,
			2168.378,
			2170.176,
			2171.965,
			2177.277,
			2180.772,
			2185.946,
			2189.349,
			2192.715,
			2194.384,
			2197.693,
			2199.333,
			2202.586,
			2205.802,
			2207.396,
			2210.555,
			2213.677,
			2218.288,
			2219.808,
			2221.318,
			2225.799,
			2227.275,
			2228.741,
			2233.083,
			2235.93,
			2240.129,
			2242.88,
			2245.59,
			2249.583,
			2250.894,
			2252.195,
			2256.042,
			2257.306,
			2258.561,
			2262.276,
			2264.709,
			2267.11,
			2268.297,
			2270.649,
			2272.969,
			2274.115,
			2276.383,
			2277.503,
			2279.716,
			2281.891,
			2285.086,
			2287.171,
			2290.231,
			2291.233,
			2292.226,
			2295.152,
			2297.059,
			2299.853,
			2301.673,
			2304.356,
			2306.095,
			2306.951,
			2308.635,
			2310.287,
			2311.1,
			2312.698,
			2314.262,
			2316.542,
			2318.008,
			2319.442,
			2320.848,
			2321.538,
			2322.89,
			2323.558,
			2324.88,
			2326.187,
			2328.17,
			2329.487,
			2331.462,
			2332.163,
			2332.867,
			2334.987,
			2336.408,
			2338.686,
			2340.217,
			2341.753,
			2342.527,
			2344.072,
			2344.844,
			2346.388,
			2347.914,
			2348.671,
			2350.183,
			2351.693,
			2353.988,
			2354.75,
			2355.51,
			2357.03,
			2358.567,
			2359.333,
			2361.621,
			2363.123,
			2364.608,
			2366.832,
			2368.277,
			2370.413,
			2371.125,
			2371.837,
			2373.929,
			2375.311,
			2377.384,
			2378.736,
			2380.758,
			2382.114,
			2383.467,
			2384.138,
			2386.16,
			2387.518,
			2388.904,
			2391.016,
			2392.436,
			2394.625,
			2395.365,
			2396.109,
			2398.358,
			2399.887,
			2402.205,
			2403.768,
			2405.347,
			2407.742,
			2408.548,
			2409.341,
			2411.778,
			2412.61,
			2413.432,
			2415.899,
			2417.567,
			2420.083,
			2421.778,
			2423.456,
			2424.307,
			2426.022,
			2426.885,
			2428.618,
			2430.364,
			2433.007,
			2434.784,
			2437.47,
			2438.371,
			2439.274,
			2442.001,
			2443.832,
			2446.595,
			2448.451,
			2451.256,
			2453.14,
			2454.087,
			2455.036,
			2457.904,
			2458.866,
			2459.83,
			2462.742,
			2464.697,
			2466.663,
			2467.651,
			2469.633,
			2471.625,
			2472.626,
			2475.642,
			2477.666,
			2479.699,
			2482.767,
			2484.825,
			2487.928,
			2488.968,
			2490.009,
			2493.146,
			2495.245,
			2498.388,
			2500.49,
			2503.658,
			2505.78,
			2506.843,
			2508.977,
			2512.195,
			2514.35,
			2516.512,
			2519.769,
			2520.858,
			2521.949,
			2525.232,
			2526.33,
			2527.429,
			2530.739,
			2532.955,
			2536.293,
			2538.527,
			2540.77,
			2544.148,
			2545.279,
			2546.41,
			2549.816,
			2552.097,
			2555.529,
			2557.814,
			2561.224,
			2563.469,
			2564.581,
			2566.783,
			2570.019,
			2572.134,
			2574.214,
			2577.272,
			2579.266,
			2582.189,
			2583.145,
			2584.092,
			2586.88,
			2588.693,
			2591.346,
			2593.07,
			2595.596,
			2597.252,
			2598.074,
			2599.71,
			2601.35,
			2602.161,
			2602.969,
			2605.381,
			2606.976,
			2608.565,
			2609.356,
			2610.929,
			2612.496,
			2613.277,
			2614.837,
			2615.615,
			2617.166,
			2618.713,
			2621.045,
			2622.6,
			2624.94,
			2625.746,
			2626.55,
			2628.964,
			2630.621,
			2633.172,
			2634.876,
			2636.719,
			2637.646,
			2639.504,
			2640.447,
			2642.368,
			2644.295,
			2645.261,
			2647.224,
			2649.195,
			2651.135,
			2653.024,
			2653.972,
			2655.867,
			2657.713,
			2658.639,
			2660.498,
			2661.412,
			2663.239,
			2665.072,
			2667.813,
			2669.642,
			2672.367,
			2673.269,
			2674.171,
			2676.869,
			2678.647,
			2681.317,
			2683.055,
			2684.793,
			2685.663,
			2687.389,
			2689.098,
			2689.952,
			2691.663,
			2692.523,
			2694.243,
			2695.965,
			2698.576,
			2699.451,
			2700.327,
			2702.964,
			2703.846,
			2704.729,
			2706.502,
			2707.393,
			2709.181,
			2710.978,
			2713.702,
			2715.538,
			2718.315,
			2719.249,
			2720.186,
			2723.014,
			2724.916,
			2727.79,
			2729.719,
			2731.644,
			2732.619,
			2734.579,
			2735.564,
			2737.542,
			2739.534,
			2740.536,
			2742.547,
			2744.571,
			2747.629,
			2748.655,
			2749.683,
			2752.786,
			2753.826,
			2754.869,
			2758.001,
			2760.091,
			2762.19,
			2765.359,
			2767.486,
			2770.696,
			2771.771,
			2772.85,
			2776.101,
			2777.191,
			2778.284,
			2781.577,
			2783.787,
			2786.006,
			2787.119,
			2789.354,
			2790.474,
			2792.724,
			2794.985,
			2796.119,
			2798.396,
			2800.682,
			2804.13,
			2806.44,
			2809.924,
			2811.09,
			2812.259,
			2815.782,
			2818.137,
			2821.657,
			2824.003,
			2826.305,
			2827.444,
			2829.7,
			2830.815,
			2833.019,
			2835.187,
			2836.258,
			2837.32,
			2840.462,
			2842.529,
			2844.581,
			2845.601,
			2847.633,
			2849.659,
			2850.666,
			2852.674,
			2853.676,
			2855.675,
			2857.669,
			2858.666,
			2860.663,
			2862.668,
			2865.683,
			2866.694,
			2867.706,
			2870.743,
			2872.772,
			2875.812,
			2877.832,
			2879.849,
			2880.859,
			2882.887,
			2883.901,
			2885.933,
			2887.996,
			2889.029,
			2891.1,
			2893.21,
			2895.329,
			2898.524,
			2900.667,
			2902.815,
			2903.891,
			2906.051,
			2907.141,
			2909.354,
			2911.577,
			2914.964,
			2917.239,
			2920.638,
			2921.776,
			2922.912,
			2926.271,
			2928.519,
			2931.883,
			2934.13,
			2936.376,
			2937.504,
			2939.745,
			2941.985,
			2943.109,
			2945.359,
			2946.487,
			2948.753,
			2951.035,
			2954.48,
			2956.793,
			2960.284,
			2961.455,
			2962.627,
			2966.163,
			2968.534,
			2970.919,
			2974.516,
			2976.928,
			2980.544,
			2981.761,
			2982.98,
			2987.88,
			2989.11,
			2992.812,
			2995.291,
			2997.778,
			2999.025,
			3001.526,
			3004.033,
			3005.29,
			3007.81,
			3009.073,
			3011.605,
			3014.15,
			3017.985,
			3020.554,
			3024.427,
			3025.722,
			3027.02,
			3030.924,
			3033.529,
			3037.452,
			3040.078,
			3042.712,
			3046.68,
			3048.007,
			3050.667,
			3053.334,
			3054.671,
			3056.01,
			3060.041,
			3062.738,
			3065.443,
			3066.799,
			3069.517,
			3072.242,
			3073.608,
			3076.346,
			3077.719,
			3080.47,
			3083.229,
			3087.382,
			3090.161,
			3094.344,
			3095.742,
			3097.143,
			3101.354,
			3104.171,
			3108.411,
			3111.248,
			3114.091,
			3115.516,
			3118.371,
			3119.801,
			3122.668,
			3125.542,
			3126.982,
			3129.867,
			3132.76,
			3135.66,
			3140.024,
			3142.942,
			3145.867,
			3147.332,
			3150.268,
			3151.738,
			3154.684,
			3157.637,
			3162.079,
			3165.049,
			3169.517,
			3171.01,
			3172.504,
			3176.998,
			3180.001,
			3184.507,
			3187.495,
			3190.46,
			3191.93,
			3194.844,
			3196.287,
			3199.144,
			3201.962,
			3203.358,
			3206.12,
			3208.846,
			3212.864,
			3214.184,
			3215.495,
			3219.373,
			3220.647,
			3221.912,
			3225.649,
			3228.095,
			3230.504,
			3234.052,
			3236.375,
			3237.523,
			3239.792,
			3240.911,
			3242.022,
			3246.372,
			3247.436,
			3250.573,
			3252.618,
			3254.626,
			3255.617,
			3257.57,
			3259.487,
			3260.431,
			3262.293,
			3263.213,
			3265.025,
			3266.805,
			3269.415,
			3271.11,
			3273.597,
			3274.403,
			3275.203,
			3277.565,
			3279.113,
			3281.412,
			3282.936,
			3285.206,
			3286.713,
			3287.466,
			3288.965,
			3290.459,
			3291.205,
			3291.951,
			3294.209,
			3295.724,
			3297.239,
			3297.996,
			3299.576,
			3301.161,
			3301.953,
			3303.547,
			3304.385,
			3306.057,
			3307.728,
			3310.241,
			3311.914,
			3314.429,
			3315.272,
			3316.113,
			3318.634,
			3320.34,
			3322.9,
			3324.607,
			3326.312,
			3327.163,
			3328.864,
			3329.705,
			3331.36,
			3333.014,
			3333.839,
			3335.439,
			3337.012,
			3339.37,
			3340.138,
			3340.898,
			3343.177,
			3343.936,
			3344.695,
			3346.934,
			3348.429,
			3349.929,
			3352.169,
			3353.667,
			3355.938,
			3356.702,
			3357.469,
			3359.777,
			3361.346,
			3363.732,
			3365.334,
			3367.781,
			3369.427,
			3370.254,
			3371.921,
			3373.602,
			3374.448,
			3376.148,
			3377.86,
			3380.451,
			3381.322,
			3382.194,
			3384.829,
			3385.7,
			3386.588,
			3389.268,
			3391.071,
			3393.796,
			3395.628,
			3397.472,
			3400.258,
			3401.193,
			3402.131,
			3404.958,
			3406.859,
			3409.731,
			3411.66,
			3413.602,
			3414.577,
			3416.536,
			3418.508,
			3419.498,
			3422.488,
			3424.496,
			3426.515,
			3429.567,
			3431.617,
			3434.714,
			3435.752,
			3436.793,
			3439.922,
			3442.012,
			3445.164,
			3447.282,
			3449.41,
			3452.625,
			3453.721,
			3454.801,
			3458.054,
			3459.143,
			3460.235,
			3463.516,
			3465.713,
			3467.914,
			3469.016,
			3471.228,
			3473.447,
			3474.559,
			3477.911,
			3480.163,
			3482.425,
			3485.841,
			3488.129,
			3491.567,
			3492.715,
			3493.863,
			3497.304,
			3499.599,
			3503.033,
			3505.317,
			3507.595,
			3508.731,
			3511.003,
			3512.135,
			3514.396,
			3516.652,
			3517.777,
			3520.023,
			3522.266,
			3525.62,
			3527.854,
			3530.085,
			3532.318,
			3533.436,
			3535.671,
			3536.791,
			3539.034,
			3541.281,
			3544.662,
			3546.917,
			3550.298,
			3551.425,
			3552.551,
			3555.921,
			3558.161,
			3561.51,
			3563.738,
			3565.96,
			3567.069,
			3569.282,
			3570.387,
			3572.59,
			3574.789,
			3575.887,
			3578.079,
			3580.271,
			3583.561,
			3584.657,
			3585.756,
			3589.053,
			3590.152,
			3591.252,
			3594.552,
			3596.747,
			3600.03,
			3602.215,
			3604.398,
			3607.674,
			3608.766,
			3609.857,
			3614.225,
			3615.316,
			3618.588,
			3620.77,
			3622.955,
			3624.048,
			3626.24,
			3627.339,
			3629.54,
			3632.855,
			3635.077,
			3637.308,
			3640.671,
			3642.926,
			3646.321,
			3647.456,
			3648.593,
			3652.008,
			3654.286,
			3657.697,
			3659.966,
			3662.228,
			3663.359,
			3665.622,
			3666.751,
			3667.881,
			3671.288,
			3672.425,
			3673.562,
			3676.988,
			3679.273,
			3681.561,
			3682.704,
			3684.986,
			3687.26,
			3688.395,
			3690.66,
			3691.789,
			3694.044,
			3696.299,
			3699.675,
			3701.926,
			3705.289,
			3706.405,
			3707.52,
			3710.849,
			3713.051,
			3716.339,
			3718.52,
			3720.698,
			3721.784,
			3723.957,
			3725.043,
			3727.218,
			3729.394,
			3730.483,
			3732.669,
			3734.861,
			3738.16,
			3739.261,
			3740.364,
			3742.569,
			3744.776,
			3745.88,
			3748.088,
			3749.192,
			3751.4,
			3753.611,
			3756.928,
			3759.142,
			3762.461,
			3763.569,
			3764.677,
			3768,
			3770.217,
			3773.54,
			3775.756,
			3777.971,
			3779.079,
			3781.293,
			3782.4,
			3784.609,
			3787.917,
			3790.118,
			3792.316,
			3795.608,
			3797.801,
			3801.094,
			3802.192,
			3803.289,
			3806.579,
			3808.77,
			3810.962,
			3814.252,
			3816.449,
			3819.749,
			3820.85,
			3821.953,
			3825.263,
			3827.475,
			3830.802,
			3833.03,
			3835.266,
			3836.389,
			3838.642,
			3840.905,
			3842.04,
			3845.461,
			3847.752,
			3850.054,
			3853.515,
			3855.834,
			3859.331,
			3860.501,
			3861.675,
			3865.211,
			3867.582,
			3871.154,
			3873.545,
			3875.944,
			3879.564,
			3880.777,
			3881.991,
			3886.896,
			3888.131,
			3891.862,
			3894.368,
			3898.155,
			3900.714,
			3903.283,
			3904.577,
			3908.471,
			3911.088,
			3913.713,
			3917.643,
			3920.261,
			3924.165,
			3925.464,
			3926.761,
			3930.661,
			3933.267,
			3937.184,
			3939.793,
			3942.404,
			3943.69,
			3946.309,
			3947.62,
			3950.246,
			3952.904,
			3954.225,
			3956.873,
			3959.507,
			3962.17,
			3964.839,
			3966.176,
			3968.857,
			3971.545,
			3972.894,
			3975.596,
			3976.951,
			3979.667,
			3982.39,
			3986.491,
			3989.233,
			3993.361,
			3994.741,
			3996.122,
			4000.278,
			4003.058,
			4007.243,
			4010.042,
			4012.847,
			4014.253,
			4017.07,
			4018.481,
			4021.31,
			4024.146,
			4025.567,
			4028.413,
			4031.267,
			4035.561,
			4036.997,
			4038.434,
			4042.755,
			4044.198,
			4045.644,
			4049.992,
			4052.899,
			4057.273,
			4060.198,
			4063.13,
			4067.541,
			4069.014,
			4070.489,
			4074.925,
			4076.407,
			4077.891,
			4082.353,
			4085.334,
			4088.323,
			4089.82,
			4092.819,
			4094.321,
			4097.329,
			4100.343,
			4101.854,
			4104.877,
			4107.908,
			4112.467,
			4115.513,
			4120.095,
			4121.625,
			4123.156,
			4127.761,
			4130.838,
			4135.465,
			4138.558,
			4141.656,
			4143.207,
			4146.313,
			4147.869,
			4150.985,
			4154.106,
			4155.669,
			4157.233,
			4161.932,
			4165.068,
			4168.209,
			4169.78,
			4172.926,
			4176.077,
			4177.655,
			4180.817,
			4182.401,
			4185.577,
			4188.762,
			4193.549,
			4196.746,
			4201.55,
			4203.154,
			4204.759,
			4209.578,
			4212.795,
			4217.628,
			4220.855,
			4224.083,
			4225.699,
			4228.927,
			4230.542,
			4233.771,
			4237.001,
			4238.617,
			4241.851,
			4245.083,
			4249.942,
			4251.544,
			4253.144,
			4256.323,
			4259.476,
			4261.041,
			4264.124,
			4265.668,
			4268.731,
			4271.764,
			4276.249,
			4279.229,
			4283.624,
			4285.083,
			4286.539,
			4290.896,
			4293.795,
			4298.137,
			4301.027,
			4303.918,
			4305.361,
			4308.252,
			4309.677,
			4312.6,
			4315.516,
			4316.975,
			4319.909,
			4322.852,
			4327.291,
			4330.275,
			4334.779,
			4336.287,
			4337.795,
			4342.338,
			4345.379,
			4348.43,
			4353.014,
			4356.083,
			4360.713,
			4362.264,
			4363.816,
			4368.452,
			4371.535,
			4376.142,
			4379.188,
			4382.224,
			4383.733,
			4386.746,
			4388.239,
			4391.228,
			4394.21,
			4395.7,
			4398.683,
			4401.667,
			4406.158,
			4409.158,
			4413.669,
			4415.174,
			4416.681,
			4421.21,
			4424.237,
			4428.763,
			4431.804,
			4434.849,
			4439.421,
			4440.945,
			4443.999,
			4447.057,
			4448.587,
			4450.12,
			4454.733,
			4457.818,
			4460.911,
			4462.46,
			4465.562,
			4468.671,
			4470.228,
			4473.346,
			4474.906,
			4478.032,
			4481.162,
			4485.868,
			4489.012,
			4493.739,
			4495.318,
			4496.898,
			4501.646,
			4504.818,
			4509.583,
			4512.764,
			4515.947,
			4517.538,
			4520.723,
			4522.317,
			4525.509,
			4528.705,
			4530.305,
			4533.506,
			4536.712,
			4539.922,
			4543.136,
			4544.745,
			4547.966,
			4551.19,
			4552.805,
			4556.036,
			4557.654,
			4560.893,
			4564.137,
			4569.012,
			4572.269,
			4577.163,
			4578.797,
			4580.432,
			4585.343,
			4586.983,
			4588.625,
			4593.561,
			4596.859,
			4601.813,
			4605.121,
			4606.776,
			4610.089,
			4615.064,
			4618.387,
			4621.713,
			4626.707,
			4630.039,
			4635.043,
			4636.713,
			4638.383,
			4643.396,
			4646.744,
			4650.095,
			4655.129,
			4658.49,
			4663.537,
			4665.222,
			4666.907,
			4671.971,
			4675.352,
			4680.433,
			4683.827,
			4687.226,
			4688.927,
			4692.332,
			4695.741,
			4697.448,
			4700.865,
			4702.576,
			4706.002,
			4709.433,
			4714.592,
			4716.317,
			4718.043,
			4723.23,
			4724.962,
			4726.694,
			4731.901,
			4735.373,
			4740.587,
			4744.064,
			4747.539,
			4752.754,
			4754.493,
			4756.231,
			4763.19,
			4764.932,
			4770.157,
			4773.644,
			4778.88,
			4782.373,
			4785.868,
			4787.615,
			4792.857,
			4796.355,
			4799.855,
			4805.114,
			4808.629,
			4813.914,
			4815.678,
			4817.445,
			4822.753,
			4826.298,
			4831.621,
			4835.172,
			4838.727,
			4840.506,
			4844.065,
			4845.846,
			4849.409,
			4852.976,
			4854.76,
			4856.545,
			4861.904,
			4865.48,
			4870.849,
			4874.432,
			4878.017,
			4879.811,
			4883.4,
			4885.196,
			4888.789,
			4892.385,
			4897.782,
			4901.382,
			4906.787,
			4908.59,
			4910.394,
			4915.811,
			4919.426,
			4924.858,
			4928.484,
			4932.114,
			4933.93,
			4937.565,
			4939.383,
			4943.024,
			4946.669,
			4948.493,
			4952.143,
			4955.798,
			4961.287,
			4963.119,
			4964.952,
			4970.455,
			4972.291,
			4974.128,
			4979.643,
			4983.324,
			4987.007,
			4992.535,
			4996.222,
			5001.753,
			5003.597,
			5005.439,
			5010.953,
			5014.604,
			5020.025,
			5023.594,
			5027.122,
			5028.87,
			5032.338,
			5035.766,
			5037.466,
			5040.835,
			5042.505,
			5045.815,
			5049.086,
			5053.918,
			5057.091,
			5061.777,
			5063.321,
			5064.854,
			5069.401,
			5072.388,
			5076.8,
			5079.691,
			5082.542,
			5086.745,
			5088.126,
			5089.498,
			5094.889,
			5096.212,
			5100.126,
			5102.687,
			5106.458,
			5108.924,
			5111.354,
			5112.555,
			5116.106,
			5118.431,
			5120.718,
			5124.078,
			5126.272,
			5129.493,
			5130.548,
			5131.593,
			5134.675,
			5135.684,
			5136.683,
			5139.644,
			5141.562,
			5143.445,
			5144.374,
			5146.205,
			5147.108,
			5148.894,
			5150.641,
			5151.503,
			5152.358,
			5154.889,
			5156.547,
			5158.186,
			5159,
			5160.632,
			5162.262,
			5163.074,
			5164.693,
			5165.514,
			5167.168,
			5168.817,
			5171.31,
			5172.982,
			5175.49,
			5176.346,
			5177.204,
			5179.775,
			5181.493,
			5184.068,
			5185.788,
			5187.521,
			5188.389,
			5190.126,
			5190.997,
			5192.764,
			5195.443,
			5197.23,
			5199.008,
			5201.681,
			5202.577,
			5203.476,
			5206.172,
			5207.073,
			5207.958,
			5210.605,
			5212.373,
			5214.146,
			5216.806,
			5218.564,
			5221.17,
			5222.04,
			5222.91,
			5225.47,
			5227.173,
			5229.714,
			5231.395,
			5233.081,
			5233.928,
			5235.634,
			5237.345,
			5238.203,
			5239.935,
			5240.806,
			5242.554,
			5244.312,
			5246.982,
			5247.877,
			5248.775,
			5251.502,
			5252.42,
			5253.341,
			5256.123,
			5257.995,
			5260.822,
			5262.71,
			5265.562,
			5267.479,
			5268.426,
			5269.392,
			5272.309,
			5273.289,
			5274.271,
			5277.235,
			5279.222,
			5282.22,
			5284.223,
			5286.237,
			5287.249,
			5289.28,
			5290.3,
			5292.348,
			5294.407,
			5297.517,
			5299.603,
			5302.732,
			5303.776,
			5304.822,
			5307.973,
			5310.082,
			5313.26,
			5315.39,
			5317.529,
			5318.602,
			5320.758,
			5321.841,
			5324.013,
			5327.308,
			5328.386,
			5331.69,
			5333.906,
			5336.13,
			5337.247,
			5339.488,
			5341.74,
			5342.871,
			5345.138,
			5346.275,
			5348.564,
			5350.864,
			5354.343,
			5356.677,
			5360.204,
			5361.384,
			5362.567,
			5366.132,
			5368.519,
			5372.116,
			5374.521,
			5376.933,
			5378.141,
			5380.56,
			5381.771,
			5384.199,
			5387.854,
			5390.303,
			5392.791,
			5396.486,
			5397.731,
			5398.978,
			5402.726,
			5403.979,
			5405.233,
			5407.742,
			5408.994,
			5411.504,
			5414.015,
			5417.789,
			5420.31,
			5424.104,
			5425.371,
			5426.64,
			5430.454,
			5432.999,
			5436.816,
			5439.354,
			5443.159,
			5445.655,
			5446.894,
			5449.349,
			5451.768,
			5452.961,
			5455.318,
			5457.622,
			5461.075,
			5463.343,
			5466.728,
			5467.857,
			5468.987,
			5472.378,
			5474.655,
			5476.93,
			5480.354,
			5482.634,
			5486.062,
			5487.205,
			5488.347,
			5491.765,
			5494.046,
			5497.478,
			5499.769,
			5502.077,
			5503.231,
			5505.547,
			5507.874,
			5509.04,
			5511.386,
			5512.565,
			5514.927,
			5517.312,
			5520.89,
			5523.271,
			5526.827,
			5528.006,
			5529.186,
			5532.714,
			5535.064,
			5538.599,
			5540.961,
			5543.32,
			5544.512,
			5546.923,
			5548.125,
			5550.533,
			5552.952,
			5554.166,
			5555.382,
			5559.041,
			5561.49,
			5563.935,
			5565.155,
			5567.597,
			5570.025,
			5571.237,
			5573.662,
			5574.865,
			5577.271,
			5579.677,
			5583.284,
			5585.691,
			5589.31,
			5590.517,
			5591.728,
			5595.369,
			5597.806,
			5601.477,
			5603.91,
			5606.37,
			5607.602,
			5610.07,
			5611.308,
			5613.787,
			5616.271,
			5617.517,
			5620.018,
			5622.528,
			5626.313,
			5627.581,
			5628.85,
			5631.398,
			5633.955,
			5635.237,
			5637.811,
			5639.1,
			5641.686,
			5644.28,
			5648.187,
			5650.793,
			5654.713,
			5656.023,
			5657.335,
			5661.281,
			5663.919,
			5667.892,
			5670.549,
			5674.55,
			5677.23,
			5678.573,
			5681.269,
			5683.975,
			5685.331,
			5688.053,
			5690.783,
			5694.895,
			5696.27,
			5697.646,
			5701.791,
			5703.176,
			5704.561,
			5708.722,
			5711.495,
			5715.644,
			5718.384,
			5721.103,
			5725.115,
			5726.435,
			5727.744,
			5732.895,
			5734.16,
			5737.897,
			5740.343,
			5743.945,
			5746.302,
			5748.625,
			5749.772,
			5752.038,
			5753.158,
			5755.389,
			5757.564,
			5760.737,
			5762.816,
			5765.862,
			5766.857,
			5767.845,
			5770.769,
			5772.664,
			5775.443,
			5777.266,
			5779.06,
			5779.946,
			5781.703,
			5782.579,
			5783.45,
			5786.041,
			5786.899,
			5788.607,
			5790.31,
			5792.011,
			5793.712,
			5794.562,
			5796.265,
			5797.969,
			5798.819,
			5800.52,
			5801.37,
			5803.071,
			5804.752,
			5807.272,
			5808.958,
			5811.489,
			5812.333,
			5813.176,
			5815.726,
			5817.43,
			5819.988,
			5821.707,
			5823.422,
			5824.279,
			5826,
			5826.863,
			5828.586,
			5830.309,
			5831.177,
			5832.93,
			5834.681,
			5836.431,
			5838.208,
			5839.097,
			5841.763,
			5842.663,
			5843.568,
			5845.38,
			5846.287,
			5848.113,
			5849.968,
			5852.76,
			5854.625,
			5857.433,
			5858.372,
			5859.312,
			5862.14,
			5864.035,
			5866.899,
			5868.82,
			5871.72,
			5873.663,
			5875.611,
			5876.563,
			5878.476,
			5879.437,
			5881.367,
			5883.299,
			5886.219,
			5888.17,
			5891.118,
			5892.095,
			5893.072,
			5896.02,
			5897.986,
			5900.948,
			5902.942,
			5904.95,
			5907.983,
			5909,
			5910.02,
			5914.124,
			5915.157,
			5918.261,
			5920.34,
			5922.426,
			5923.473,
			5925.557,
			5927.667,
			5928.727,
			5931.925,
			5934.055,
			5936.194,
			5939.422,
			5941.577,
			5944.874,
			5945.977,
			5947.083,
			5950.415,
			5952.651,
			5956.005,
			5958.268,
			5960.538,
			5961.677,
			5963.964,
			5965.111,
			5967.411,
			5969.718,
			5970.875,
			5973.195,
			5975.522,
			5977.859,
			5981.376,
			5983.731,
			5986.094,
			5987.279,
			5989.655,
			5990.847,
			5993.238,
			5995.638,
			5999.258,
			6001.683,
			6005.338,
			6006.561,
			6007.786,
			6011.476,
			6013.947,
			6017.67,
			6020.163,
			6023.918,
			6026.433,
			6027.693,
			6030.219,
			6032.754,
			6034.025,
			6036.572,
			6039.127,
			6042.974,
			6044.26,
			6045.548,
			6049.424,
			6050.72,
			6052.018,
			6055.921,
			6058.528,
			6061.135,
			6065.057,
			6067.68,
			6071.627,
			6072.946,
			6074.267,
			6078.239,
			6080.895,
			6084.892,
			6087.564,
			6090.244,
			6091.586,
			6094.275,
			6095.623,
			6098.322,
			6101.029,
			6102.385,
			6105.104,
			6107.829,
			6111.927,
			6113.296,
			6114.666,
			6118.781,
			6120.156,
			6121.532,
			6125.668,
			6128.433,
			6132.589,
			6135.366,
			6138.149,
			6142.334,
			6143.731,
			6145.13,
			6149.333,
			6150.736,
			6152.142,
			6156.362,
			6159.183,
			6163.421,
			6166.251,
			6169.088,
			6170.506,
			6173.348,
			6174.771,
			6177.617,
			6180.472,
			6184.757,
			6187.619,
			6191.921,
			6193.355,
			6194.792,
			6199.106,
			6201.986,
			6206.312,
			6209.201,
			6212.091,
			6213.538,
			6216.434,
			6217.882,
			6220.782,
			6223.682,
			6225.134,
			6228.039,
			6230.948,
			6233.86,
			6238.236,
			6241.157,
			6244.085,
			6245.549,
			6249.952,
			6252.891,
			6255.836,
			6260.26,
			6263.214,
			6267.655,
			6269.138,
			6270.622,
			6275.079,
			6278.058,
			6282.533,
			6285.523,
			6288.518,
			6290.017,
			6293.018,
			6294.521,
			6297.53,
			6300.544,
			6302.054,
			6305.075,
			6308.101,
			6311.132,
			6314.167,
			6315.687,
			6320.252,
			6321.776,
			6323.301,
			6326.354,
			6327.883,
			6330.943,
			6334.008,
			6338.613,
			6341.689,
			6346.312,
			6347.855,
			6349.399,
			6354.039,
			6357.137,
			6361.792,
			6364.901,
			6368.015,
			6369.574,
			6372.695,
			6375.82,
			6377.384,
			6380.515,
			6382.083,
			6385.22,
			6388.362,
			6393.078,
			6394.652,
			6396.226,
			6400.957,
			6402.536,
			6404.117,
			6408.868,
			6412.042,
			6416.813,
			6419.997,
			6423.182,
			6427.967,
			6429.564,
			6431.162,
			6435.963,
			6437.565,
			6439.169,
			6443.985,
			6447.202,
			6452.033,
			6455.259,
			6458.49,
			6460.106,
			6463.342,
			6464.962,
			6468.204,
			6471.451,
			6476.328,
			6479.585,
			6484.477,
			6486.109,
			6487.743,
			6492.65,
			6495.926,
			6500.848,
			6504.134,
			6507.424,
			6509.071,
			6512.366,
			6514.015,
			6517.316,
			6520.621,
			6522.274,
			6525.584,
			6528.898,
			6532.215,
			6535.537,
			6537.198,
			6540.523,
			6543.853,
			6545.519,
			6548.854,
			6550.523,
			6553.863,
			6557.206,
			6562.228,
			6565.582,
			6569.49
		],
		[
			0,
			0.063,
			0.094,
			0.124,
			0.207,
			0.249,
			0.311,
			0.372,
			0.436,
			0.497,
			0.528,
			0.56,
			0.621,
			0.635,
			0.682,
			0.749,
			0.807,
			0.87,
			0.931,
			0.961,
			0.994,
			1.054,
			1.116,
			1.18,
			1.241,
			1.287,
			1.305,
			1.365,
			1.396,
			1.428,
			1.489,
			1.505,
			1.553,
			1.617,
			1.674,
			1.723,
			1.738,
			1.798,
			1.831,
			1.862,
			1.923,
			1.939,
			1.985,
			2.048,
			2.108,
			2.157,
			2.232,
			2.27,
			2.296,
			2.373,
			2.42,
			2.483,
			2.542,
			2.589,
			2.607,
			2.668,
			2.697,
			2.729,
			2.792,
			2.807,
			2.855,
			2.922,
			2.978,
			3.025,
			3.039,
			3.101,
			3.132,
			3.162,
			3.241,
			3.288,
			3.351,
			3.412,
			3.458,
			3.536,
			3.567,
			3.599,
			3.661,
			3.721,
			3.783,
			3.844,
			3.893,
			3.909,
			3.97,
			4.001,
			4.03,
			4.094,
			4.108,
			4.156,
			4.217,
			4.28,
			4.33,
			4.404,
			4.433,
			4.464,
			4.529,
			4.59,
			4.651,
			4.714,
			4.776,
			4.838,
			4.867,
			4.901,
			4.976,
			5.023,
			5.084,
			5.149,
			5.196,
			5.21,
			5.273,
			5.304,
			5.334,
			5.413,
			5.458,
			5.522,
			5.583,
			5.629,
			5.705,
			5.735,
			5.769,
			5.831,
			5.892,
			5.954,
			6.015,
			6.064,
			6.077,
			6.141,
			6.171,
			6.203,
			6.264,
			6.28,
			6.327,
			6.392,
			6.449,
			6.496,
			6.512,
			6.574,
			6.605,
			6.635,
			6.712,
			6.76,
			6.821,
			6.883,
			6.929,
			7.008,
			7.04,
			7.07,
			7.131,
			7.193,
			7.257,
			7.317,
			7.38,
			7.442,
			7.474,
			7.505,
			7.566,
			7.582,
			7.627,
			7.69,
			7.754,
			7.815,
			7.877,
			7.908,
			7.94,
			8,
			8.016,
			8.063,
			8.125,
			8.187,
			8.232,
			8.311,
			8.341,
			8.374,
			8.435,
			8.455,
			8.496,
			8.56,
			8.621,
			8.668,
			8.682,
			8.745,
			8.776,
			8.808,
			8.867,
			8.882,
			8.93,
			8.993,
			9.055,
			9.1,
			9.117,
			9.177,
			9.209,
			9.241,
			9.304,
			9.365,
			9.426,
			9.489,
			9.536,
			9.612,
			9.644,
			9.675,
			9.738,
			9.798,
			9.862,
			9.923,
			9.983,
			10.046,
			10.077,
			10.109,
			10.172,
			10.187,
			10.234,
			10.298,
			10.358,
			10.403,
			10.482,
			10.517,
			10.542,
			10.621,
			10.667,
			10.73,
			10.791,
			10.839,
			10.854,
			10.914,
			10.946,
			10.977,
			11.038,
			11.055,
			11.1,
			11.163,
			11.226,
			11.271,
			11.288,
			11.348,
			11.38,
			11.41,
			11.474,
			11.488,
			11.536,
			11.597,
			11.66,
			11.705,
			11.784,
			11.813,
			11.846,
			11.907,
			11.97,
			12.033,
			12.093,
			12.141,
			12.154,
			12.216,
			12.248,
			12.279,
			12.357,
			12.403,
			12.466,
			12.527,
			12.59,
			12.651,
			12.683,
			12.712,
			12.776,
			12.79,
			12.837,
			12.899,
			12.96,
			13.022,
			13.086,
			13.116,
			13.146,
			13.209,
			13.272,
			13.337,
			13.396,
			13.442,
			13.458,
			13.52,
			13.55,
			13.58,
			13.643,
			13.66,
			13.705,
			13.769,
			13.83,
			13.875,
			13.892,
			13.952,
			13.985,
			14.014,
			14.077,
			14.093,
			14.139,
			14.201,
			14.264,
			14.311,
			14.387,
			14.42,
			14.451,
			14.512,
			14.575,
			14.636,
			14.697,
			14.744,
			14.761,
			14.822,
			14.853,
			14.884,
			14.946,
			14.962,
			15.007,
			15.071,
			15.132,
			15.177,
			15.194,
			15.255,
			15.288,
			15.318,
			15.396,
			15.443,
			15.505,
			15.566,
			15.612,
			15.69,
			15.721,
			15.75,
			15.814,
			15.83,
			15.875,
			15.937,
			15.999,
			16.062,
			16.123,
			16.155,
			16.187,
			16.249,
			16.262,
			16.31,
			16.373,
			16.435,
			16.48,
			16.557,
			16.588,
			16.62,
			16.683,
			16.703,
			16.743,
			16.805,
			16.866,
			16.916,
			16.929,
			16.992,
			17.024,
			17.054,
			17.116,
			17.132,
			17.177,
			17.24,
			17.302,
			17.363,
			17.427,
			17.458,
			17.489,
			17.566,
			17.613,
			17.674,
			17.737,
			17.798,
			17.861,
			17.891,
			17.923,
			17.983,
			18.046,
			18.108,
			18.17,
			18.217,
			18.231,
			18.294,
			18.324,
			18.357,
			18.418,
			18.433,
			18.479,
			18.542,
			18.604,
			18.651,
			18.666,
			18.729,
			18.764,
			18.791,
			18.853,
			18.914,
			18.976,
			19.038,
			19.099,
			19.162,
			19.193,
			19.224,
			19.288,
			19.348,
			19.411,
			19.473,
			19.52,
			19.534,
			19.597,
			19.627,
			19.66,
			19.722,
			19.736,
			19.781,
			19.845,
			19.907,
			19.952,
			19.967,
			20.03,
			20.061,
			20.091,
			20.154,
			20.216,
			20.279,
			20.341,
			20.388,
			20.403,
			20.464,
			20.496,
			20.527,
			20.605,
			20.649,
			20.712,
			20.773,
			20.825,
			20.838,
			20.898,
			20.93,
			20.961,
			21.022,
			21.037,
			21.083,
			21.147,
			21.208,
			21.255,
			21.333,
			21.365,
			21.395,
			21.472,
			21.517,
			21.58,
			21.642,
			21.689,
			21.705,
			21.767,
			21.797,
			21.828,
			21.907,
			21.953,
			22.014,
			22.078,
			22.139,
			22.201,
			22.231,
			22.264,
			22.325,
			22.341,
			22.385,
			22.45,
			22.51,
			22.558,
			22.635,
			22.666,
			22.696,
			22.76,
			22.822,
			22.888,
			22.945,
			22.993,
			23.007,
			23.069,
			23.099,
			23.131,
			23.194,
			23.208,
			23.255,
			23.317,
			23.378,
			23.427,
			23.441,
			23.503,
			23.535,
			23.565,
			23.627,
			23.643,
			23.688,
			23.749,
			23.813,
			23.861,
			23.938,
			23.968,
			24,
			24.062,
			24.124,
			24.184,
			24.248,
			24.293,
			24.31,
			24.372,
			24.404,
			24.433,
			24.511,
			24.558,
			24.617,
			24.68,
			24.727,
			24.744,
			24.806,
			24.834,
			24.865,
			24.929,
			24.994,
			25.054,
			25.117,
			25.162,
			25.238,
			25.272,
			25.301,
			25.364,
			25.378,
			25.425,
			25.487,
			25.549,
			25.613,
			25.674,
			25.706,
			25.734,
			25.797,
			25.814,
			25.858,
			25.922,
			25.984,
			26.03,
			26.108,
			26.138,
			26.171,
			26.232,
			26.293,
			26.355,
			26.417,
			26.464,
			26.48,
			26.543,
			26.574,
			26.605,
			26.68,
			26.727,
			26.791,
			26.852,
			26.912,
			26.976,
			27.01,
			27.037,
			27.098,
			27.116,
			27.162,
			27.224,
			27.286,
			27.331,
			27.409,
			27.441,
			27.47,
			27.532,
			27.596,
			27.658,
			27.72,
			27.78,
			27.844,
			27.874,
			27.906,
			27.967,
			27.984,
			28.029,
			28.093,
			28.155,
			28.202,
			28.216,
			28.279,
			28.307,
			28.34,
			28.402,
			28.416,
			28.463,
			28.526,
			28.588,
			28.633,
			28.712,
			28.743,
			28.774,
			28.85,
			28.898,
			28.961,
			29.023,
			29.074,
			29.083,
			29.145,
			29.177,
			29.208,
			29.27,
			29.286,
			29.332,
			29.394,
			29.457,
			29.501,
			29.519,
			29.58,
			29.612,
			29.643,
			29.721,
			29.766,
			29.828,
			29.891,
			29.935,
			30.013,
			30.046,
			30.076,
			30.155,
			30.199,
			30.264,
			30.324,
			30.384,
			30.447,
			30.479,
			30.51,
			30.571,
			30.588,
			30.634,
			30.697,
			30.758,
			30.805,
			30.881,
			30.914,
			30.945,
			31.022,
			31.069,
			31.132,
			31.193,
			31.238,
			31.255,
			31.316,
			31.347,
			31.379,
			31.441,
			31.455,
			31.503,
			31.564,
			31.628,
			31.674,
			31.688,
			31.749,
			31.783,
			31.811,
			31.876,
			31.891,
			31.938,
			31.998,
			32.06,
			32.122,
			32.184,
			32.215,
			32.246,
			32.309,
			32.37,
			32.433,
			32.494,
			32.557,
			32.618,
			32.649,
			32.681,
			32.742,
			32.758,
			32.804,
			32.866,
			32.929,
			32.974,
			32.991,
			33.051,
			33.082,
			33.115,
			33.177,
			33.195,
			33.238,
			33.299,
			33.363,
			33.408,
			33.488,
			33.517,
			33.548,
			33.625,
			33.673,
			33.734,
			33.797,
			33.845,
			33.858,
			33.921,
			33.951,
			33.982,
			34.046,
			34.06,
			34.107,
			34.17,
			34.23,
			34.293,
			34.355,
			34.387,
			34.418,
			34.48,
			34.542,
			34.601,
			34.664,
			34.726,
			34.788,
			34.821,
			34.849,
			34.913,
			34.929,
			34.974,
			35.038,
			35.1,
			35.145,
			35.161,
			35.222,
			35.286,
			35.348,
			35.363,
			35.41,
			35.471,
			35.533,
			35.581,
			35.656,
			35.689,
			35.717,
			35.796,
			35.842,
			35.905,
			35.968,
			36.014,
			36.03,
			36.09,
			36.122,
			36.155,
			36.214,
			36.231,
			36.277,
			36.342,
			36.401,
			36.448,
			36.463,
			36.526,
			36.556,
			36.588,
			36.65,
			36.665,
			36.711,
			36.774,
			36.834,
			36.881,
			36.959,
			36.991,
			37.022,
			37.082,
			37.146,
			37.209,
			37.27,
			37.321,
			37.332,
			37.392,
			37.426,
			37.457,
			37.533,
			37.579,
			37.642,
			37.704,
			37.767,
			37.828,
			37.858,
			37.89,
			37.951,
			37.967,
			38.012,
			38.074,
			38.137,
			38.183,
			38.261,
			38.292,
			38.323,
			38.401,
			38.448,
			38.51,
			38.573,
			38.62,
			38.635,
			38.694,
			38.727,
			38.757,
			38.821,
			38.837,
			38.881,
			38.943,
			39.006,
			39.05,
			39.066,
			39.128,
			39.16,
			39.192,
			39.255,
			39.316,
			39.382,
			39.441,
			39.486,
			39.565,
			39.595,
			39.626,
			39.688,
			39.705,
			39.75,
			39.813,
			39.875,
			39.922,
			39.936,
			39.999,
			40.028,
			40.059,
			40.122,
			40.137,
			40.182,
			40.246,
			40.307,
			40.354,
			40.431,
			40.464,
			40.493,
			40.555,
			40.618,
			40.68,
			40.743,
			40.805,
			40.866,
			40.897,
			40.928,
			40.991,
			41.005,
			41.053,
			41.116,
			41.177,
			41.238,
			41.301,
			41.332,
			41.362,
			41.425,
			41.445,
			41.487,
			41.548,
			41.61,
			41.657,
			41.732,
			41.765,
			41.797,
			41.857,
			41.92,
			41.985,
			42.045,
			42.092,
			42.105,
			42.166,
			42.199,
			42.23,
			42.292,
			42.308,
			42.355,
			42.418,
			42.478,
			42.525,
			42.541,
			42.601,
			42.632,
			42.664,
			42.743,
			42.788,
			42.85,
			42.911,
			42.96,
			43.037,
			43.067,
			43.098,
			43.16,
			43.222,
			43.285,
			43.346,
			43.41,
			43.471,
			43.509,
			43.532,
			43.611,
			43.658,
			43.72,
			43.781,
			43.828,
			43.905,
			43.936,
			43.967,
			44.044,
			44.091,
			44.153,
			44.215,
			44.26,
			44.338,
			44.37,
			44.399,
			44.462,
			44.479,
			44.525,
			44.588,
			44.648,
			44.711,
			44.773,
			44.805,
			44.834,
			44.897,
			44.911,
			44.959,
			45.024,
			45.082,
			45.145,
			45.207,
			45.239,
			45.267,
			45.33,
			45.393,
			45.455,
			45.516,
			45.579,
			45.641,
			45.672,
			45.702,
			45.764,
			45.781,
			45.826,
			45.888,
			45.951,
			45.996,
			46.014,
			46.074,
			46.107,
			46.137,
			46.215,
			46.262,
			46.322,
			46.383,
			46.431,
			46.508,
			46.541,
			46.572,
			46.632,
			46.695,
			46.757,
			46.82,
			46.88,
			46.943,
			46.975,
			47.006,
			47.082,
			47.128,
			47.193,
			47.254,
			47.3,
			47.316,
			47.378,
			47.408,
			47.438,
			47.517,
			47.564,
			47.628,
			47.689,
			47.732,
			47.81,
			47.842,
			47.874,
			47.952,
			47.996,
			48.061,
			48.121,
			48.183,
			48.245,
			48.275,
			48.307,
			48.385,
			48.431,
			48.493,
			48.554,
			48.603,
			48.681,
			48.71,
			48.743,
			48.804,
			48.867,
			48.928,
			48.99,
			49.052,
			49.112,
			49.145,
			49.177,
			49.238,
			49.254,
			49.299,
			49.361,
			49.425,
			49.471,
			49.484,
			49.548,
			49.578,
			49.611,
			49.672,
			49.693,
			49.734,
			49.796,
			49.858,
			49.905,
			49.983,
			50.014,
			50.043,
			50.105,
			50.169,
			50.229,
			50.293,
			50.338,
			50.354,
			50.416,
			50.446,
			50.479,
			50.541,
			50.556,
			50.603,
			50.666,
			50.726,
			50.772,
			50.789,
			50.851,
			50.882,
			50.911,
			50.975,
			50.988,
			51.038,
			51.099,
			51.159,
			51.205,
			51.284,
			51.314,
			51.347,
			51.424,
			51.471,
			51.532,
			51.595,
			51.641,
			51.655,
			51.719,
			51.755,
			51.78,
			51.842,
			51.856,
			51.904,
			51.967,
			52.028,
			52.075,
			52.09,
			52.153,
			52.183,
			52.215,
			52.277,
			52.291,
			52.339,
			52.399,
			52.461,
			52.508,
			52.586,
			52.616,
			52.648,
			52.726,
			52.773,
			52.835,
			52.896,
			52.942,
			52.958,
			53.021,
			53.051,
			53.083,
			53.143,
			53.16,
			53.206,
			53.267,
			53.33,
			53.376,
			53.391,
			53.455,
			53.484,
			53.518,
			53.593,
			53.641,
			53.702,
			53.766,
			53.814,
			53.887,
			53.921,
			53.95,
			54.013,
			54.029,
			54.076,
			54.138,
			54.197,
			54.245,
			54.26,
			54.321,
			54.351,
			54.384,
			54.448,
			54.461,
			54.508,
			54.571,
			54.633,
			54.678,
			54.758,
			54.788,
			54.818,
			54.881,
			54.942,
			55.004,
			55.067,
			55.114,
			55.129,
			55.192,
			55.222,
			55.253,
			55.314,
			55.33,
			55.376,
			55.444,
			55.501,
			55.548,
			55.562,
			55.624,
			55.656,
			55.688,
			55.748,
			55.764,
			55.811,
			55.873,
			55.879,
			55.934,
			55.98,
			56.058,
			56.089,
			56.121,
			56.181,
			56.245,
			56.308,
			56.367,
			56.416,
			56.432,
			56.493,
			56.525,
			56.555,
			56.616,
			56.633,
			56.678,
			56.743,
			56.802,
			56.866,
			56.926,
			56.958,
			56.988,
			57.052,
			57.068,
			57.112,
			57.174,
			57.237,
			57.298,
			57.361,
			57.392,
			57.423,
			57.486,
			57.548,
			57.61,
			57.671,
			57.716,
			57.732,
			57.796,
			57.827,
			57.858,
			57.92,
			57.941,
			57.98,
			58.043,
			58.105,
			58.167,
			58.228,
			58.261,
			58.291,
			58.367,
			58.416,
			58.477,
			58.54,
			58.584,
			58.662,
			58.693,
			58.727,
			58.803,
			58.848,
			58.91,
			58.972,
			59.02,
			59.035,
			59.096,
			59.129,
			59.158,
			59.222,
			59.238,
			59.285,
			59.346,
			59.408,
			59.454,
			59.531,
			59.561,
			59.594,
			59.655,
			59.716,
			59.779,
			59.843,
			59.888,
			59.965,
			60.001,
			60.027,
			60.088,
			60.105,
			60.149,
			60.214,
			60.277,
			60.322,
			60.338,
			60.398,
			60.429,
			60.462,
			60.524,
			60.538,
			60.587,
			60.649,
			60.71,
			60.772,
			60.832,
			60.865,
			60.897,
			60.957,
			61.018,
			61.081,
			61.143,
			61.191,
			61.204,
			61.267,
			61.297,
			61.329,
			61.391,
			61.408,
			61.455,
			61.517,
			61.576,
			61.641,
			61.7,
			61.734,
			61.764,
			61.827,
			61.841,
			61.888,
			61.95,
			62.012,
			62.063,
			62.135,
			62.167,
			62.199,
			62.261,
			62.322,
			62.384,
			62.445,
			62.491,
			62.508,
			62.57,
			62.6,
			62.633,
			62.695,
			62.711,
			62.756,
			62.817,
			62.88,
			62.926,
			62.943,
			63.004,
			63.036,
			63.067,
			63.128,
			63.191,
			63.251,
			63.314,
			63.361,
			63.377,
			63.438,
			63.468,
			63.5,
			63.579,
			63.624,
			63.684,
			63.748,
			63.795,
			63.811,
			63.872,
			63.903,
			63.934,
			63.995,
			64.012,
			64.058,
			64.125,
			64.182,
			64.229,
			64.307,
			64.338,
			64.369,
			64.43,
			64.493,
			64.554,
			64.617,
			64.678,
			64.741,
			64.772,
			64.804,
			64.866,
			64.878,
			64.926,
			64.989,
			65.051,
			65.098,
			65.111,
			65.176,
			65.207,
			65.235,
			65.298,
			65.315,
			65.362,
			65.423,
			65.483,
			65.532,
			65.608,
			65.641,
			65.671,
			65.732,
			65.796,
			65.858,
			65.918,
			65.965,
			65.981,
			66.043,
			66.074,
			66.105,
			66.166,
			66.184,
			66.229,
			66.291,
			66.352,
			66.399,
			66.415,
			66.477,
			66.508,
			66.537,
			66.617,
			66.664,
			66.724,
			66.786,
			66.832,
			66.91,
			66.942,
			66.973,
			67.05,
			67.095,
			67.159,
			67.222,
			67.283,
			67.343,
			67.376,
			67.408,
			67.467,
			67.484,
			67.532,
			67.594,
			67.655,
			67.701,
			67.716,
			67.78,
			67.81,
			67.841,
			67.903,
			67.966,
			68.029,
			68.088,
			68.135,
			68.213,
			68.249,
			68.276,
			68.337,
			68.399,
			68.46,
			68.524,
			68.57,
			68.586,
			68.648,
			68.677,
			68.71,
			68.787,
			68.833,
			68.895,
			68.958,
			69.02,
			69.08,
			69.112,
			69.142,
			69.204,
			69.268,
			69.331,
			69.392,
			69.437,
			69.516,
			69.547,
			69.577,
			69.64,
			69.655,
			69.7,
			69.763,
			69.826,
			69.871,
			69.889,
			69.948,
			69.982,
			70.011,
			70.089,
			70.136,
			70.198,
			70.258,
			70.31,
			70.382,
			70.415,
			70.446,
			70.523,
			70.569,
			70.631,
			70.693,
			70.741,
			70.755,
			70.817,
			70.849,
			70.881,
			70.941,
			70.958,
			71.004,
			71.064,
			71.128,
			71.19,
			71.252,
			71.283,
			71.313,
			71.374,
			71.391,
			71.438,
			71.501,
			71.562,
			71.624,
			71.686,
			71.716,
			71.746,
			71.81,
			71.871,
			71.934,
			71.994,
			72.043,
			72.057,
			72.12,
			72.15,
			72.181,
			72.243,
			72.259,
			72.304,
			72.373,
			72.429,
			72.477,
			72.493,
			72.554,
			72.584,
			72.614,
			72.679,
			72.741,
			72.803,
			72.863,
			72.925,
			72.989,
			73.02,
			73.049,
			73.128,
			73.174,
			73.234,
			73.298,
			73.345,
			73.361,
			73.422,
			73.451,
			73.484,
			73.563,
			73.608,
			73.67,
			73.732,
			73.793,
			73.857,
			73.887,
			73.919,
			73.98,
			74.043,
			74.105,
			74.165,
			74.211,
			74.228,
			74.289,
			74.321,
			74.351,
			74.413,
			74.434,
			74.476,
			74.538,
			74.601,
			74.648,
			74.661,
			74.724,
			74.755,
			74.787,
			74.848,
			74.864,
			74.909,
			74.973,
			75.033,
			75.083,
			75.159,
			75.188,
			75.218,
			75.283,
			75.345,
			75.405,
			75.469,
			75.516,
			75.529,
			75.591,
			75.622,
			75.654,
			75.715,
			75.732,
			75.777,
			75.84,
			75.904,
			75.947,
			75.964,
			76.025,
			76.057,
			76.088,
			76.149,
			76.166,
			76.213,
			76.275,
			76.338,
			76.384,
			76.462,
			76.496,
			76.522,
			76.583,
			76.646,
			76.709,
			76.771,
			76.816,
			76.833,
			76.896,
			76.926,
			76.958,
			77.034,
			77.081,
			77.142,
			77.205,
			77.267,
			77.329,
			77.36,
			77.39,
			77.453,
			77.515,
			77.577,
			77.638,
			77.699,
			77.762,
			77.795,
			77.825,
			77.887,
			77.949,
			78.011,
			78.074,
			78.12,
			78.133,
			78.197,
			78.227,
			78.258,
			78.337,
			78.382,
			78.445,
			78.508,
			78.568,
			78.629,
			78.663,
			78.693,
			78.771,
			78.816,
			78.88,
			78.941,
			78.988,
			79.066,
			79.095,
			79.127,
			79.205,
			79.249,
			79.314,
			79.375,
			79.438,
			79.499,
			79.529,
			79.56,
			79.638,
			79.684,
			79.746,
			79.809,
			79.856,
			79.934,
			79.965,
			79.994,
			80.071,
			80.12,
			80.18,
			80.242,
			80.289,
			80.305,
			80.367,
			80.397,
			80.429,
			80.491,
			80.507,
			80.554,
			80.62,
			80.676,
			80.722,
			80.74,
			80.801,
			80.833,
			80.862,
			80.924,
			80.941,
			80.988,
			81.048,
			81.11,
			81.157,
			81.236,
			81.265,
			81.296,
			81.36,
			81.422,
			81.482,
			81.545,
			81.592,
			81.607,
			81.667,
			81.701,
			81.731,
			81.794,
			81.81,
			81.855,
			81.915,
			81.978,
			82.024,
			82.041,
			82.105,
			82.133,
			82.165,
			82.228,
			82.289,
			82.353,
			82.414,
			82.46,
			82.538,
			82.569,
			82.6,
			82.66,
			82.681,
			82.724,
			82.786,
			82.848,
			82.895,
			82.91,
			82.973,
			83.001,
			83.033,
			83.096,
			83.11,
			83.157,
			83.22,
			83.281,
			83.329,
			83.407,
			83.435,
			83.468,
			83.53,
			83.591,
			83.654,
			83.715,
			83.761,
			83.779,
			83.841,
			83.87,
			83.902,
			83.963,
			83.979,
			84.026,
			84.089,
			84.15,
			84.196,
			84.212,
			84.273,
			84.305,
			84.336,
			84.397,
			84.414,
			84.461,
			84.522,
			84.583,
			84.63,
			84.708,
			84.742,
			84.771,
			84.832,
			84.892,
			84.955,
			85.017,
			85.064,
			85.079,
			85.143,
			85.174,
			85.204,
			85.266,
			85.283,
			85.328,
			85.391,
			85.453,
			85.497,
			85.514,
			85.577,
			85.607,
			85.637,
			85.701,
			85.717,
			85.763,
			85.825,
			85.887,
			85.933,
			86.011,
			86.042,
			86.073,
			86.132,
			86.197,
			86.258,
			86.318,
			86.367,
			86.383,
			86.443,
			86.474,
			86.507,
			86.568,
			86.582,
			86.63,
			86.693,
			86.755,
			86.815,
			86.878,
			86.909,
			86.94,
			87.003,
			87.065,
			87.127,
			87.189,
			87.234,
			87.313,
			87.343,
			87.373,
			87.437,
			87.499,
			87.559,
			87.623,
			87.67,
			87.684,
			87.746,
			87.776,
			87.807,
			87.872,
			87.887,
			87.932,
			87.996,
			88.057,
			88.103,
			88.179,
			88.211,
			88.243,
			88.321,
			88.367,
			88.43,
			88.49,
			88.552,
			88.615,
			88.646,
			88.678,
			88.739,
			88.755,
			88.8,
			88.866,
			88.925,
			88.972,
			88.988,
			89.05,
			89.078,
			89.11,
			89.172,
			89.189,
			89.234,
			89.301,
			89.358,
			89.406,
			89.481,
			89.512,
			89.546,
			89.607,
			89.67,
			89.731,
			89.793,
			89.84,
			89.854,
			89.916,
			89.947,
			89.978,
			90.04,
			90.057,
			90.103,
			90.164,
			90.226,
			90.272,
			90.288,
			90.351,
			90.382,
			90.413,
			90.475,
			90.492,
			90.538,
			90.599,
			90.661,
			90.707,
			90.785,
			90.817,
			90.847,
			90.908,
			90.929,
			90.971,
			91.034,
			91.094,
			91.156,
			91.219,
			91.249,
			91.282,
			91.36,
			91.405,
			91.466,
			91.528,
			91.591,
			91.654,
			91.683,
			91.715,
			91.778,
			91.84,
			91.899,
			91.964,
			92.011,
			92.088,
			92.117,
			92.149,
			92.212,
			92.274,
			92.336,
			92.396,
			92.443,
			92.46,
			92.522,
			92.553,
			92.584,
			92.646,
			92.66,
			92.708,
			92.768,
			92.831,
			92.877,
			92.894,
			92.956,
			92.99,
			93.016,
			93.08,
			93.142,
			93.204,
			93.264,
			93.313,
			93.389,
			93.422,
			93.451,
			93.53,
			93.577,
			93.638,
			93.7,
			93.762,
			93.822,
			93.856,
			93.884,
			93.962,
			94.01,
			94.072,
			94.135,
			94.18,
			94.256,
			94.289,
			94.318,
			94.397,
			94.442,
			94.505,
			94.568,
			94.614,
			94.631,
			94.693,
			94.724,
			94.754,
			94.814,
			94.831,
			94.876,
			94.939,
			95.001,
			95.064,
			95.126,
			95.158,
			95.188,
			95.249,
			95.266,
			95.311,
			95.374,
			95.436,
			95.481,
			95.558,
			95.59,
			95.621,
			95.7,
			95.745,
			95.809,
			95.87,
			95.916,
			95.932,
			95.992,
			96.024,
			96.055,
			96.116,
			96.134,
			96.179,
			96.242,
			96.305,
			96.349,
			96.366,
			96.428,
			96.459,
			96.49,
			96.566,
			96.614,
			96.677,
			96.738,
			96.783,
			96.863,
			96.894,
			96.923,
			96.987,
			97.049,
			97.114,
			97.173,
			97.219,
			97.232,
			97.296,
			97.328,
			97.359,
			97.421,
			97.437,
			97.483,
			97.544,
			97.607,
			97.652,
			97.73,
			97.76,
			97.793,
			97.868,
			97.917,
			97.978,
			98.04,
			98.087,
			98.165,
			98.194,
			98.227,
			98.304,
			98.35,
			98.413,
			98.473,
			98.537,
			98.599,
			98.629,
			98.661,
			98.739,
			98.785,
			98.846,
			98.907,
			98.971,
			99.031,
			99.064,
			99.093,
			99.155,
			99.178,
			99.22,
			99.281,
			99.342,
			99.389,
			99.405,
			99.465,
			99.496,
			99.528,
			99.591,
			99.607,
			99.651,
			99.717,
			99.777,
			99.823,
			99.838,
			99.902,
			99.932,
			99.963,
			100.027,
			100.04,
			100.087,
			100.148,
			100.21,
			100.257,
			100.333,
			100.365,
			100.397,
			100.473,
			100.522,
			100.587,
			100.645,
			100.691,
			100.708,
			100.769,
			100.799,
			100.83,
			100.907,
			100.954,
			101.016,
			101.077,
			101.124,
			101.141,
			101.202,
			101.238,
			101.266,
			101.328,
			101.388,
			101.45,
			101.512,
			101.56,
			101.638,
			101.666,
			101.697,
			101.76,
			101.823,
			101.884,
			101.946,
			101.994,
			102.008,
			102.071,
			102.103,
			102.133,
			102.196,
			102.211,
			102.257,
			102.319,
			102.382,
			102.426,
			102.443,
			102.506,
			102.536,
			102.567,
			102.646,
			102.691,
			102.753,
			102.815,
			102.877,
			102.94,
			102.971,
			103.001,
			103.063,
			103.078,
			103.125,
			103.187,
			103.25,
			103.302,
			103.374,
			103.404,
			103.433,
			103.497,
			103.511,
			103.56,
			103.622,
			103.684,
			103.73,
			103.808,
			103.838,
			103.87,
			103.931,
			103.992,
			104.055,
			104.117,
			104.163,
			104.179,
			104.24,
			104.272,
			104.304,
			104.381,
			104.427,
			104.488,
			104.55,
			104.597,
			104.614,
			104.676,
			104.707,
			104.738,
			104.799,
			104.816,
			104.861,
			104.925,
			104.986,
			105.047,
			105.111,
			105.141,
			105.172,
			105.233,
			105.296,
			105.363,
			105.42,
			105.467,
			105.48,
			105.544,
			105.574,
			105.606,
			105.683,
			105.728,
			105.792,
			105.853,
			105.9,
			105.917,
			105.977,
			106.009,
			106.04,
			106.101,
			106.118,
			106.162,
			106.224,
			106.288,
			106.333,
			106.413,
			106.441,
			106.473,
			106.536,
			106.597,
			106.659,
			106.722,
			106.784,
			106.846,
			106.875,
			106.906,
			106.971,
			106.985,
			107.031,
			107.094,
			107.155,
			107.203,
			107.281,
			107.311,
			107.342,
			107.405,
			107.465,
			107.527,
			107.591,
			107.65,
			107.712,
			107.746,
			107.776,
			107.838,
			107.9,
			107.963,
			108.024,
			108.072,
			108.086,
			108.149,
			108.178,
			108.211,
			108.272,
			108.288,
			108.332,
			108.396,
			108.459,
			108.505,
			108.583,
			108.612,
			108.645,
			108.705,
			108.768,
			108.829,
			108.891,
			108.938,
			108.954,
			109.017,
			109.046,
			109.077,
			109.139,
			109.155,
			109.201,
			109.264,
			109.325,
			109.372,
			109.388,
			109.449,
			109.486,
			109.512,
			109.574,
			109.591,
			109.637,
			109.698,
			109.761,
			109.806,
			109.883,
			109.914,
			109.945,
			110.023,
			110.071,
			110.132,
			110.194,
			110.241,
			110.255,
			110.319,
			110.35,
			110.38,
			110.442,
			110.457,
			110.504,
			110.566,
			110.629,
			110.674,
			110.69,
			110.752,
			110.782,
			110.814,
			110.876,
			110.892,
			110.938,
			111.001,
			111.063,
			111.11,
			111.185,
			111.216,
			111.249,
			111.327,
			111.373,
			111.436,
			111.496,
			111.557,
			111.621,
			111.652,
			111.682,
			111.745,
			111.759,
			111.807,
			111.867,
			111.931,
			111.977,
			111.993,
			112.056,
			112.087,
			112.115,
			112.177,
			112.241,
			112.304,
			112.364,
			112.426,
			112.488,
			112.518,
			112.551,
			112.627,
			112.673,
			112.738,
			112.799,
			112.859,
			112.924,
			112.955,
			112.985,
			113.047,
			113.063,
			113.108,
			113.171,
			113.232,
			113.279,
			113.358,
			113.388,
			113.419,
			113.482,
			113.543,
			113.61,
			113.666,
			113.713,
			113.73,
			113.791,
			113.821,
			113.851,
			113.916,
			113.932,
			113.978,
			114.038,
			114.102,
			114.147,
			114.164,
			114.226,
			114.255,
			114.288,
			114.348,
			114.363,
			114.41,
			114.472,
			114.536,
			114.582,
			114.66,
			114.691,
			114.721,
			114.784,
			114.845,
			114.909,
			114.967,
			115.016,
			115.031,
			115.092,
			115.124,
			115.156,
			115.217,
			115.232,
			115.278,
			115.343,
			115.401,
			115.449,
			115.464,
			115.527,
			115.558,
			115.59,
			115.651,
			115.671,
			115.714,
			115.775,
			115.837,
			115.9,
			115.962,
			115.993,
			116.025,
			116.084,
			116.148,
			116.21,
			116.271,
			116.332,
			116.396,
			116.428,
			116.457,
			116.521,
			116.535,
			116.58,
			116.643,
			116.705,
			116.766,
			116.83,
			116.861,
			116.891,
			116.969,
			117.016,
			117.077,
			117.14,
			117.2,
			117.263,
			117.296,
			117.324,
			117.403,
			117.449,
			117.51,
			117.574,
			117.622,
			117.637,
			117.699,
			117.733,
			117.759,
			117.837,
			117.883,
			117.946,
			118.007,
			118.068,
			118.132,
			118.164,
			118.193,
			118.271,
			118.317,
			118.379,
			118.441,
			118.489,
			118.505,
			118.564,
			118.598,
			118.629,
			118.689,
			118.706,
			118.753,
			118.815,
			118.875,
			118.939,
			119.001,
			119.032,
			119.061,
			119.124,
			119.14,
			119.184,
			119.249,
			119.31,
			119.358,
			119.433,
			119.465,
			119.497,
			119.558,
			119.621,
			119.683,
			119.743,
			119.807,
			119.868,
			119.899,
			119.93,
			119.993,
			120.008,
			120.055,
			120.117,
			120.18,
			120.225,
			120.241,
			120.302,
			120.332,
			120.364,
			120.427,
			120.488,
			120.551,
			120.613,
			120.659,
			120.736,
			120.766,
			120.799,
			120.876,
			120.922,
			120.988,
			121.047,
			121.092,
			121.108,
			121.172,
			121.201,
			121.232,
			121.293,
			121.311,
			121.356,
			121.416,
			121.48,
			121.526,
			121.541,
			121.605,
			121.635,
			121.666,
			121.729,
			121.79,
			121.857,
			121.913,
			121.959,
			122.039,
			122.07,
			122.101,
			122.163,
			122.179,
			122.224,
			122.288,
			122.349,
			122.41,
			122.472,
			122.505,
			122.536,
			122.598,
			122.611,
			122.657,
			122.721,
			122.783,
			122.829,
			122.908,
			122.937,
			122.967,
			123.031,
			123.093,
			123.155,
			123.217,
			123.263,
			123.279,
			123.341,
			123.373,
			123.403,
			123.465,
			123.479,
			123.528,
			123.588,
			123.651,
			123.712,
			123.774,
			123.805,
			123.838,
			123.917,
			123.961,
			124.023,
			124.084,
			124.131,
			124.208,
			124.239,
			124.271,
			124.348,
			124.396,
			124.458,
			124.517,
			124.564,
			124.581,
			124.642,
			124.674,
			124.706,
			124.766,
			124.781,
			124.83,
			124.891,
			124.952,
			125.001,
			125.014,
			125.078,
			125.108,
			125.138,
			125.201,
			125.217,
			125.263,
			125.324,
			125.387,
			125.435,
			125.511,
			125.542,
			125.573,
			125.636,
			125.697,
			125.758,
			125.822,
			125.866,
			125.883,
			125.945,
			125.982,
			126.007,
			126.07,
			126.083,
			126.13,
			126.193,
			126.254,
			126.3,
			126.316,
			126.378,
			126.41,
			126.442,
			126.504,
			126.565,
			126.628,
			126.688,
			126.737,
			126.814,
			126.844,
			126.875,
			126.938,
			126.953,
			126.999,
			127.061,
			127.124,
			127.183,
			127.248,
			127.278,
			127.308,
			127.372,
			127.388,
			127.432,
			127.496,
			127.559,
			127.605,
			127.68,
			127.713,
			127.742,
			127.821,
			127.866,
			127.931,
			127.991,
			128.043,
			128.055,
			128.116,
			128.145,
			128.178,
			128.241,
			128.255,
			128.303,
			128.366,
			128.424,
			128.473,
			128.487,
			128.55,
			128.58,
			128.61,
			128.674,
			128.688,
			128.736,
			128.8,
			128.859,
			128.907,
			128.969
		]
	],
	'3537_3538_7982': [
		[
			83.678
		],
		[
			0
		],
		[
			0,
			1.145,
			4.081,
			7.02,
			8.491,
			11.437,
			14.393,
			15.873,
			18.844,
			20.332,
			23.305,
			26.283,
			30.757,
			33.75,
			38.254,
			39.759,
			41.266,
			45.794,
			48.818,
			51.848,
			56.407,
			59.45,
			60.973,
			64.018,
			65.542,
			67.068,
			71.651,
			73.181,
			74.713,
			79.315,
			82.389,
			87.014,
			90.103,
			93.195,
			94.739,
			97.831,
			99.377,
			102.473,
			105.575,
			110.236,
			113.348,
			118.019,
			119.579,
			121.14,
			125.836,
			128.976,
			133.697,
			136.851,
			140.012,
			141.594,
			144.763,
			146.348,
			149.519,
			152.694,
			154.283,
			157.464,
			160.649,
			163.835,
			167.021,
			168.616,
			171.807,
			175.007,
			176.608,
			179.806,
			181.4,
			184.57,
			187.706,
			192.348,
			195.397,
			199.903,
			201.385,
			202.859,
			207.224,
			210.085,
			214.302,
			217.066,
			219.793,
			221.143,
			223.814,
			225.138,
			227.758,
			230.343,
			231.622,
			234.152,
			236.64,
			240.302,
			241.504,
			242.697,
			246.222,
			247.379,
			248.528,
			250.796,
			251.916,
			254.129,
			256.302,
			259.493,
			261.574,
			264.626,
			265.624,
			266.612,
			269.524,
			270.475,
			271.418,
			274.192,
			276,
			277.775,
			278.65,
			280.376,
			282.066,
			282.898,
			285.338,
			286.919,
			288.465,
			290.725,
			291.455,
			292.177,
			294.288,
			294.975,
			295.653,
			297.639,
			298.924,
			300.785,
			301.98,
			303.15,
			303.726,
			304.863,
			305.415,
			305.962,
			307.58,
			308.113,
			308.645,
			310.229,
			311.277,
			312.846,
			313.892,
			314.945,
			315.491,
			316.583,
			317.13,
			318.224,
			319.323,
			321.034,
			322.2,
			323.959,
			324.549,
			325.147,
			326.979,
			328.208,
			330.062,
			331.309,
			332.557,
			333.182,
			334.432,
			335.059,
			336.32,
			337.578,
			338.206,
			338.834,
			340.727,
			341.994,
			343.255,
			343.884,
			345.141,
			346.415,
			347.048,
			348.938,
			350.194,
			351.47,
			353.372,
			354.638,
			356.589,
			357.245,
			357.9,
			359.868,
			361.214,
			363.248,
			364.611,
			365.985,
			366.674,
			368.055,
			368.747,
			370.139,
			372.251,
			373.665,
			375.089,
			376.551,
			378.765,
			381.017,
			381.783,
			382.552,
			384.876,
			386.451,
			388.04,
			390.444,
			392.038,
			394.45,
			395.261,
			396.055,
			398.431,
			400.03,
			402.376,
			403.943,
			405.524,
			406.29,
			407.83,
			408.606,
			410.168,
			411.698,
			412.461,
			413.998,
			415.55,
			417.851,
			419.4,
			421.753,
			422.544,
			423.338,
			425.736,
			427.381,
			429.86,
			431.533,
			433.232,
			435.801,
			436.677,
			437.557,
			440.204,
			441.104,
			442.015,
			444.77,
			446.638,
			448.538,
			449.495,
			451.423,
			453.384,
			454.369,
			457.325,
			459.291,
			461.251,
			464.146,
			466.059,
			468.892,
			469.826,
			470.759,
			473.528,
			475.362,
			478.097,
			479.909,
			481.713,
			482.611,
			484.4,
			485.292,
			487.074,
			488.847,
			489.73,
			491.491,
			493.247,
			494.993,
			496.733,
			497.601,
			499.332,
			501.058,
			501.92,
			503.642,
			504.502,
			506.22,
			507.939,
			510.516,
			512.237,
			514.82,
			515.68,
			516.54,
			519.128,
			520.851,
			523.438,
			525.162,
			526.875,
			527.743,
			529.483,
			530.356,
			532.107,
			533.884,
			534.767,
			536.535,
			538.317,
			540.994,
			541.888,
			542.79,
			544.593,
			546.398,
			547.305,
			550.035,
			551.855,
			553.69,
			556.438,
			558.285,
			561.058,
			561.983,
			562.913,
			565.698,
			567.562,
			570.382,
			572.266,
			574.176,
			575.131,
			577.044,
			578.011,
			579.952,
			581.894,
			582.87,
			584.832,
			586.795,
			589.759,
			591.74,
			594.678,
			595.662,
			596.651,
			599.571,
			601.511,
			603.444,
			606.293,
			608.207,
			611.054,
			612.01,
			612.969,
			616.829,
			617.802,
			620.75,
			622.732,
			624.737,
			625.746,
			627.768,
			629.825,
			630.857,
			632.913,
			633.959,
			636.054,
			638.122,
			641.253,
			643.315,
			646.347,
			647.34,
			648.307,
			651.185,
			653.059,
			655.788,
			657.577,
			660.165,
			661.844,
			662.67,
			664.288,
			666.639,
			668.167,
			669.658,
			671.118,
			673.276,
			674.684,
			676.079,
			676.773,
			678.846,
			680.22,
			681.585,
			683.624,
			684.987,
			687.014,
			687.686,
			688.357,
			690.365,
			691.694,
			693.68,
			694.997,
			696.307,
			696.961,
			698.268,
			698.921,
			700.216,
			701.508,
			702.153,
			702.799,
			704.731,
			706.653,
			707.937,
			709.222,
			710.501,
			711.14,
			712.419,
			713.059,
			714.342,
			715.622,
			717.537,
			718.814,
			720.738,
			721.375,
			722.012,
			723.919,
			725.197,
			727.097,
			728.359,
			729.627,
			730.261,
			731.524,
			732.154,
			733.413,
			734.681,
			735.318,
			736.589,
			737.858,
			739.778,
			741.072,
			743.016,
			743.667,
			744.321,
			746.31,
			747.64,
			749.656,
			751.028,
			752.405,
			754.492,
			755.204,
			755.918,
			758.072,
			758.794,
			759.526,
			761.755,
			763.251,
			765.539,
			767.084,
			768.634,
			769.415,
			771.009,
			771.806,
			773.401,
			775.014,
			777.461,
			779.095,
			781.611,
			782.451,
			783.291,
			785.862,
			787.602,
			790.223,
			791.996,
			793.759,
			794.64,
			796.43,
			797.32,
			799.091,
			800.868,
			801.755,
			803.523,
			805.284,
			807.032,
			808.776,
			809.647,
			811.329,
			813.005,
			813.843,
			815.491,
			816.295,
			817.903,
			819.513,
			821.874,
			823.445,
			825.796,
			826.575,
			827.355,
			829.713,
			831.279,
			833.647,
			835.238,
			836.82,
			837.615,
			839.215,
			840.019,
			841.607,
			843.218,
			844.028,
			845.656,
			847.29,
			849.735,
			851.388,
			853.059,
			854.741,
			855.59,
			858.199,
			859.952,
			861.759,
			864.518,
			866.399,
			869.289,
			870.258,
			871.239,
			874.217,
			876.216,
			879.232,
			881.254,
			884.295,
			886.33,
			887.343,
			889.378,
			891.414,
			892.423,
			894.447,
			896.465,
			899.461,
			900.451,
			901.427,
			904.296,
			905.24,
			906.173,
			908.894,
			910.674,
			913.291,
			915.004,
			916.716,
			919.266,
			920.107,
			920.944,
			923.442,
			925.094,
			927.575,
			929.225,
			931.692,
			933.341,
			934.984,
			935.804,
			937.442,
			938.259,
			939.888,
			941.513,
			943.94,
			945.556,
			947.983,
			948.792,
			949.602,
			952.031,
			953.648,
			956.077,
			957.699,
			959.323,
			960.137,
			961.767,
			962.589,
			963.411,
			965.888,
			966.717,
			967.556,
			970.086,
			971.786,
			973.516,
			974.385,
			976.131,
			977.902,
			978.797,
			980.595,
			981.498,
			983.341,
			985.203,
			988.037,
			989.96,
			992.873,
			993.859,
			994.846,
			997.82,
			999.819,
			1002.812,
			1004.792,
			1006.762,
			1007.737,
			1009.653,
			1010.604,
			1012.491,
			1015.229,
			1017.018,
			1018.756,
			1021.297,
			1022.955,
			1024.566,
			1026.178,
			1026.981,
			1028.574,
			1029.377,
			1030.952,
			1032.519,
			1034.848,
			1036.388,
			1038.689,
			1039.454,
			1040.217,
			1042.494,
			1044.013,
			1046.279,
			1047.784,
			1050.039,
			1051.536,
			1052.284,
			1053.78,
			1055.272,
			1056.016,
			1057.504,
			1058.983,
			1061.194,
			1062.667,
			1064.884,
			1065.623,
			1066.363,
			1068.591,
			1070.092,
			1071.594,
			1073.865,
			1075.404,
			1077.711,
			1078.486,
			1079.279,
			1082.448,
			1083.24,
			1085.682,
			1087.311,
			1088.944,
			1089.767,
			1091.41,
			1093.052,
			1093.874,
			1096.344,
			1097.988,
			1099.636,
			1102.115,
			1103.769,
			1106.268,
			1107.103,
			1107.939,
			1110.446,
			1112.118,
			1114.629,
			1116.292,
			1117.963,
			1118.802,
			1120.462,
			1121.293,
			1122.127,
			1125.474,
			1126.313,
			1128.852,
			1130.55,
			1132.258,
			1133.118,
			1134.843,
			1136.576,
			1137.447,
			1139.2,
			1140.077,
			1141.842,
			1143.621,
			1146.312,
			1148.123,
			1150.873,
			1151.798,
			1152.727,
			1155.542,
			1157.427,
			1160.306,
			1162.247,
			1164.218,
			1165.202,
			1167.185,
			1168.181,
			1170.185,
			1172.207,
			1173.206,
			1175.248,
			1177.305,
			1179.375,
			1181.457,
			1182.502,
			1185.647,
			1186.696,
			1187.764,
			1190.937,
			1193.067,
			1195.211,
			1198.45,
			1200.606,
			1203.877,
			1204.973,
			1206.069,
			1209.385,
			1211.594,
			1214.914,
			1217.132,
			1220.458,
			1222.669,
			1223.772,
			1225.977,
			1228.178,
			1229.277,
			1231.475,
			1233.675,
			1236.968,
			1238.065,
			1239.161,
			1242.446,
			1243.54,
			1244.633,
			1247.913,
			1250.1,
			1253.385,
			1255.579,
			1257.777,
			1261.087,
			1262.195,
			1263.307,
			1266.65,
			1267.772,
			1268.896,
			1272.275,
			1274.546,
			1276.82,
			1277.96,
			1280.242,
			1282.529,
			1283.675,
			1285.97,
			1287.12,
			1289.424,
			1291.734,
			1295.215,
			1297.547,
			1301.066,
			1302.245,
			1303.429,
			1307.002,
			1309.404,
			1313.033,
			1315.474,
			1317.928,
			1319.161,
			1321.635,
			1322.876,
			1324.122,
			1329.14,
			1330.403,
			1334.201,
			1336.735,
			1339.27,
			1340.538,
			1343.073,
			1345.611,
			1346.88,
			1349.415,
			1350.681,
			1353.208,
			1355.729,
			1359.495,
			1362.001,
			1365.75,
			1366.998,
			1368.245,
			1371.986,
			1374.482,
			1378.222,
			1380.719,
			1383.22,
			1384.473,
			1386.981,
			1388.237,
			1390.755,
			1393.29,
			1394.561,
			1397.113,
			1399.678,
			1402.256,
			1406.139,
			1408.712,
			1411.294,
			1412.583,
			1415.168,
			1416.464,
			1419.047,
			1421.638,
			1425.532,
			1428.136,
			1432.052,
			1433.357,
			1434.664,
			1438.589,
			1441.208,
			1445.128,
			1447.743,
			1450.344,
			1451.647,
			1454.247,
			1455.549,
			1458.156,
			1460.768,
			1462.076,
			1464.702,
			1467.336,
			1471.31,
			1472.639,
			1473.972,
			1477.959,
			1479.3,
			1480.642,
			1484.677,
			1487.375,
			1490.079,
			1494.15,
			1496.874,
			1500.977,
			1502.348,
			1503.722,
			1507.85,
			1509.23,
			1510.612,
			1514.768,
			1517.546,
			1520.333,
			1521.73,
			1524.529,
			1527.338,
			1528.745,
			1531.563,
			1532.975,
			1535.801,
			1538.633,
			1542.885,
			1545.727,
			1549.999,
			1551.427,
			1552.855,
			1557.15,
			1560.02,
			1564.331,
			1567.212,
			1571.548,
			1574.448,
			1575.902,
			1577.357,
			1581.731,
			1583.192,
			1584.654,
			1589.041,
			1591.971,
			1594.907,
			1596.377,
			1599.319,
			1602.264,
			1603.738,
			1606.692,
			1608.171,
			1611.136,
			1614.111,
			1618.589,
			1621.581,
			1626.079,
			1627.579,
			1629.078,
			1633.558,
			1636.521,
			1640.915,
			1643.804,
			1648.07,
			1650.891,
			1652.273,
			1655.006,
			1657.698,
			1659.007,
			1661.639,
			1664.231,
			1666.802,
			1670.541,
			1672.977,
			1675.368,
			1676.551,
			1680.058,
			1682.368,
			1684.657,
			1688.064,
			1690.322,
			1693.703,
			1694.828,
			1695.951,
			1699.332,
			1701.582,
			1705.103,
			1707.482,
			1710.052,
			1711.334,
			1713.915,
			1715.209,
			1717.781,
			1720.298,
			1721.555,
			1724.015,
			1726.441,
			1729.978,
			1731.149,
			1732.32,
			1735.762,
			1736.927,
			1738.072,
			1741.474,
			1743.745,
			1746.009,
			1747.144,
			1749.413,
			1751.684,
			1755.099,
			1756.239,
			1757.38,
			1760.81,
			1763.103,
			1766.556,
			1768.865,
			1771.181,
			1772.341,
			1774.666,
			1776.996,
			1778.163,
			1781.674,
			1784.021,
			1786.371,
			1789.908,
			1791.09,
			1792.273,
			1795.828,
			1797.015,
			1798.203,
			1801.767,
			1804.147,
			1807.714,
			1810.095,
			1812.483,
			1816.073,
			1817.272,
			1818.47,
			1822.064,
			1823.261,
			1824.458,
			1828.027,
			1830.424,
			1832.827,
			1834.032,
			1836.448,
			1838.892,
			1840.108,
			1842.523,
			1843.744,
			1846.214,
			1848.653,
			1852.366,
			1854.855,
			1858.608,
			1859.863,
			1861.12,
			1864.903,
			1867.432,
			1871.235,
			1873.781,
			1876.333,
			1877.615,
			1880.185,
			1881.473,
			1884.048,
			1886.628,
			1887.917,
			1890.494,
			1893.088,
			1895.654,
			1898.213,
			1899.489,
			1902.036,
			1904.57,
			1905.835,
			1909.595,
			1912.112,
			1914.621,
			1918.413,
			1920.931,
			1924.719,
			1925.985,
			1927.253,
			1931.065,
			1933.597,
			1937.447,
			1940.034,
			1943.936,
			1946.571,
			1949.202,
			1950.525,
			1953.159,
			1954.502,
			1957.194,
			1959.927,
			1964.013,
			1965.377,
			1966.744,
			1970.834,
			1972.197,
			1973.561,
			1977.625,
			1980.366,
			1983.073,
			1987.089,
			1989.738,
			1993.634,
			1994.95,
			1996.239,
			2000.095,
			2001.375,
			2002.654,
			2006.49,
			2009.044,
			2011.601,
			2012.879,
			2015.434,
			2016.713,
			2019.263,
			2021.812,
			2023.081,
			2025.608,
			2028.12,
			2031.839,
			2034.285,
			2037.912,
			2039.112,
			2040.308,
			2043.877,
			2046.244,
			2049.775,
			2052.113,
			2054.439,
			2057.897,
			2059.043,
			2060.187,
			2063.585,
			2064.712,
			2065.838,
			2069.207,
			2071.449,
			2073.703,
			2074.828,
			2077.093,
			2079.37,
			2080.509,
			2082.814,
			2083.97,
			2086.283,
			2088.617,
			2092.111,
			2094.436,
			2097.916,
			2099.072,
			2100.229,
			2103.689,
			2105.992,
			2109.434,
			2111.726,
			2114.008,
			2115.148,
			2117.426,
			2118.56,
			2120.827,
			2123.082,
			2124.203,
			2126.445,
			2128.677,
			2132.019,
			2133.132,
			2134.247,
			2136.479,
			2138.717,
			2139.839,
			2142.089,
			2143.219,
			2145.486,
			2147.762,
			2151.203,
			2153.506,
			2156.977,
			2158.138,
			2159.305,
			2162.819,
			2165.176,
			2168.728,
			2171.087,
			2173.474,
			2174.695,
			2177.103,
			2178.31,
			2180.746,
			2184.417,
			2186.879,
			2189.348,
			2193.042,
			2194.305,
			2195.549,
			2199.272,
			2200.524,
			2201.8,
			2205.57,
			2208.065,
			2210.585,
			2214.364,
			2216.885,
			2220.679,
			2221.947,
			2223.217,
			2227.046,
			2229.616,
			2233.496,
			2236.089,
			2238.693,
			2239.998,
			2242.615,
			2243.928,
			2246.56,
			2249.202,
			2250.526,
			2253.18,
			2255.863,
			2259.869,
			2262.545,
			2266.565,
			2267.906,
			2269.249,
			2273.277,
			2275.964,
			2279.999,
			2282.69,
			2285.394,
			2289.458,
			2290.821,
			2292.184,
			2297.684,
			2299.065,
			2303.216,
			2305.969,
			2310.1,
			2312.857,
			2315.618,
			2316.999,
			2319.766,
			2321.15,
			2323.924,
			2326.688,
			2330.83,
			2333.583,
			2337.71,
			2339.083,
			2340.459,
			2344.561,
			2347.296,
			2351.403,
			2354.145,
			2358.27,
			2361.027,
			2363.789,
			2365.172,
			2367.94,
			2369.325,
			2372.094,
			2374.835,
			2377.593,
			2380.345,
			2381.721,
			2384.502,
			2387.263,
			2388.626,
			2391.401,
			2392.792,
			2395.585,
			2398.386,
			2402.608,
			2405.432,
			2409.679,
			2411.099,
			2412.521,
			2416.8,
			2419.667,
			2423.989,
			2426.885,
			2429.787,
			2431.242,
			2434.156,
			2435.619,
			2438.551,
			2441.499,
			2442.978,
			2445.944,
			2448.922,
			2453.402,
			2454.899,
			2456.397,
			2460.898,
			2462.402,
			2463.907,
			2466.923,
			2468.436,
			2471.467,
			2474.51,
			2479.095,
			2482.168,
			2486.798,
			2488.371,
			2489.919,
			2494.543,
			2497.647,
			2502.34,
			2505.435,
			2508.563,
			2510.13,
			2513.271,
			2514.844,
			2517.997,
			2521.158,
			2522.742,
			2525.916,
			2529.099,
			2533.884,
			2537.08,
			2541.888,
			2543.494,
			2545.102,
			2549.934,
			2553.164,
			2558.019,
			2561.263,
			2564.515,
			2569.404,
			2571.036,
			2572.669,
			2577.583,
			2579.225,
			2580.869,
			2585.811,
			2589.114,
			2592.424,
			2594.082,
			2597.403,
			2600.729,
			2602.395,
			2605.731,
			2607.402,
			2610.748,
			2614.099,
			2619.137,
			2622.502,
			2627.562,
			2629.252,
			2630.943,
			2636.027,
			2639.423,
			2644.529,
			2647.941,
			2651.357,
			2653.068,
			2656.493,
			2658.208,
			2661.643,
			2665.081,
			2666.801,
			2670.233,
			2673.649,
			2677.041,
			2680.399,
			2682.064,
			2685.368,
			2688.636,
			2690.256,
			2693.465,
			2695.055,
			2698.209,
			2701.327,
			2705.934,
			2708.961,
			2713.435,
			2714.907,
			2716.37,
			2720.693,
			2723.524,
			2727.696,
			2730.429,
			2733.125,
			2734.458,
			2737.097,
			2738.403,
			2740.985,
			2744.808,
			2747.295,
			2749.735,
			2753.324,
			2755.668,
			2759.123,
			2760.256,
			2761.381,
			2763.604,
			2764.702,
			2766.872,
			2769.004,
			2772.12,
			2774.171,
			2777.204,
			2778.195,
			2779.184,
			2782.122,
			2784.068,
			2786.986,
			2788.927,
			2791.838,
			2793.763,
			2795.683,
			2796.637,
			2799.474,
			2801.356,
			2803.229,
			2806.032,
			2807.9,
			2810.703,
			2811.641,
			2812.577,
			2815.389,
			2817.27,
			2820.088,
			2821.972,
			2823.854,
			2826.68,
			2827.624,
			2828.566,
			2831.397,
			2832.346,
			2833.294,
			2836.136,
			2838.039,
			2839.938,
			2840.885,
			2842.781,
			2844.67,
			2845.612,
			2847.501,
			2848.445,
			2850.327,
			2852.206,
			2855.013,
			2856.875,
			2859.649,
			2860.57,
			2861.488,
			2864.228,
			2866.043,
			2868.757,
			2870.556,
			2872.358,
			2873.263,
			2875.067,
			2875.967,
			2877.769,
			2880.468,
			2881.366,
			2884.048,
			2885.832,
			2887.608,
			2888.49,
			2890.252,
			2892.014,
			2892.898,
			2895.556,
			2897.331,
			2899.107,
			2901.782,
			2903.572,
			2906.266,
			2907.17,
			2908.075,
			2910.785,
			2912.606,
			2915.338,
			2917.171,
			2919.01,
			2919.929,
			2921.778,
			2922.707,
			2924.564,
			2927.369,
			2929.248,
			2931.131,
			2933.977,
			2934.924,
			2935.875,
			2938.744,
			2939.702,
			2940.66,
			2942.585,
			2943.552,
			2945.488,
			2947.414,
			2950.367,
			2952.341,
			2955.309,
			2956.314,
			2957.322,
			2960.365,
			2961.39,
			2962.418,
			2965.522,
			2967.621,
			2969.704,
			2970.733,
			2972.839,
			2973.896,
			2976.037,
			2978.158,
			2979.232,
			2981.387,
			2983.555,
			2986.844,
			2989.033,
			2992.322,
			2993.427,
			2994.533,
			2997.866,
			3000.102,
			3003.471,
			3005.728,
			3007.992,
			3011.404,
			3012.546,
			3013.69,
			3017.137,
			3018.29,
			3019.445,
			3022.924,
			3025.25,
			3027.605,
			3028.775,
			3031.102,
			3033.476,
			3034.638,
			3037.005,
			3038.192,
			3040.571,
			3042.959,
			3046.556,
			3048.965,
			3052.597,
			3053.812,
			3055.032,
			3058.707,
			3061.17,
			3064.883,
			3067.368,
			3071.113,
			3073.621,
			3074.878,
			3077.399,
			3079.928,
			3081.196,
			3082.466,
			3086.289,
			3088.848,
			3091.415,
			3092.7,
			3095.277,
			3097.861,
			3099.155,
			3101.749,
			3103.048,
			3105.65,
			3108.258,
			3112.171,
			3114.774,
			3118.654,
			3119.937,
			3121.212,
			3124.994,
			3127.469,
			3131.108,
			3133.485,
			3135.824,
			3136.979,
			3139.261,
			3140.387,
			3142.612,
			3145.874,
			3147.999,
			3150.082,
			3153.128,
			3154.125,
			3155.112,
			3158.014,
			3158.962,
			3159.902,
			3161.754,
			3162.668,
			3164.474,
			3166.275,
			3168.923,
			3170.67,
			3173.265,
			3174.125,
			3174.98,
			3177.515,
			3179.193,
			3181.668,
			3183.302,
			3185.735,
			3187.339,
			3188.138,
			3189.732,
			3191.331,
			3192.128,
			3193.717,
			3195.312,
			3197.757,
			3198.569,
			3199.381,
			3201.906,
			3202.759,
			3203.61,
			3206.23,
			3208.046,
			3210.794,
			3212.695,
			3214.593,
			3217.537,
			3218.526,
			3219.514,
			3223.63,
			3224.67,
			3227.874,
			3230.031,
			3232.163,
			3233.222,
			3235.34,
			3237.443,
			3238.49,
			3240.582,
			3241.602,
			3243.623,
			3245.638,
			3248.637,
			3250.63,
			3253.542,
			3254.507,
			3255.471,
			3258.273,
			3260.123,
			3262.809,
			3264.561,
			3266.312,
			3268.824,
			3269.659,
			3271.327,
			3273.715,
			3274.509,
			3276.879,
			3278.418,
			3279.961,
			3280.735,
			3282.288,
			3283.845,
			3284.627,
			3286.198,
			3286.988,
			3288.595,
			3290.212,
			3292.665,
			3294.33,
			3296.844,
			3297.693,
			3298.552,
			3301.144,
			3302.888,
			3305.536,
			3307.315,
			3309.104,
			3310.002,
			3311.807,
			3312.714,
			3314.531,
			3316.344,
			3317.263,
			3319.111,
			3320.97,
			3323.784,
			3324.729,
			3325.678,
			3327.583,
			3329.494,
			3330.469,
			3332.378,
			3333.345,
			3335.278,
			3337.222,
			3340.147,
			3342.105,
			3345.059,
			3346.049,
			3347.041,
			3350.028,
			3352.03,
			3355.052,
			3357.08,
			3359.117,
			3360.141,
			3362.197,
			3363.229,
			3365.3,
			3367.396,
			3368.436,
			3370.493,
			3372.568,
			3375.698,
			3377.792,
			3380.952,
			3382.01,
			3383.072,
			3386.271,
			3388.417,
			3391.652,
			3393.818,
			3395.992,
			3399.27,
			3400.367,
			3401.467,
			3404.782,
			3405.892,
			3407.004,
			3410.352,
			3412.594,
			3414.845,
			3415.974,
			3418.239,
			3420.514,
			3421.654,
			3423.941,
			3425.088,
			3427.389,
			3429.698,
			3433.178,
			3435.51,
			3439.024,
			3440.2,
			3441.379,
			3444.926,
			3447.302,
			3450.882,
			3453.28,
			3455.684,
			3456.889,
			3459.306,
			3460.518,
			3462.948,
			3465.39,
			3466.614,
			3467.84,
			3471.535,
			3474.009,
			3476.49,
			3477.735,
			3480.229,
			3482.731,
			3483.986,
			3486.501,
			3487.763,
			3490.292,
			3492.829,
			3496.651,
			3499.209,
			3503.061,
			3504.349,
			3505.639,
			3509.52,
			3512.118,
			3516.024,
			3518.628,
			3522.548,
			3525.17,
			3526.484,
			3529.117,
			3533.081,
			3535.733,
			3538.392,
			3542.395,
			3545.071,
			3547.754,
			3550.443,
			3551.79,
			3554.49,
			3555.842,
			3558.552,
			3561.269,
			3565.357,
			3568.091,
			3572.206,
			3573.581,
			3574.958,
			3579.097,
			3581.867,
			3586.033,
			3588.819,
			3591.612,
			3593.012,
			3595.816,
			3597.22,
			3600.033,
			3602.853,
			3604.265,
			3607.094,
			3609.93,
			3614.198,
			3617.052,
			3621.347,
			3622.782,
			3624.219,
			3628.538,
			3631.425,
			3635.762,
			3638.66,
			3641.569,
			3643.025,
			3645.943,
			3647.405,
			3648.867,
			3653.279,
			3653.824
		],
		[
			0,
			0.016,
			0.077,
			0.122,
			0.141,
			0.202,
			0.233,
			0.263,
			0.325,
			0.339,
			0.387,
			0.451,
			0.512,
			0.572,
			0.635,
			0.668,
			0.698,
			0.759,
			0.82,
			0.883,
			0.945,
			0.992,
			1.009,
			1.069,
			1.101,
			1.131,
			1.193,
			1.21,
			1.255,
			1.318,
			1.381,
			1.442,
			1.504,
			1.535,
			1.566,
			1.631,
			1.643,
			1.688,
			1.75,
			1.814,
			1.876,
			1.938,
			1.969,
			1.999,
			2.062,
			2.123,
			2.191,
			2.247,
			2.293,
			2.31,
			2.372,
			2.402,
			2.435,
			2.494,
			2.511,
			2.558,
			2.619,
			2.681,
			2.728,
			2.745,
			2.805,
			2.838,
			2.868,
			2.929,
			2.945,
			2.992,
			3.055,
			3.116,
			3.161,
			3.241,
			3.272,
			3.302,
			3.364,
			3.427,
			3.488,
			3.55,
			3.597,
			3.611,
			3.676,
			3.705,
			3.737,
			3.797,
			3.814,
			3.861,
			3.922,
			3.985,
			4.032,
			4.046,
			4.109,
			4.14,
			4.169,
			4.233,
			4.248,
			4.293,
			4.355,
			4.417,
			4.465,
			4.542,
			4.572,
			4.603,
			4.667,
			4.686,
			4.727,
			4.788,
			4.853,
			4.897,
			4.914,
			4.977,
			5.008,
			5.038,
			5.117,
			5.161,
			5.226,
			5.286,
			5.331,
			5.347,
			5.411,
			5.442,
			5.471,
			5.535,
			5.595,
			5.659,
			5.721,
			5.765,
			5.783,
			5.844,
			5.875,
			5.906,
			5.97,
			5.985,
			6.032,
			6.093,
			6.154,
			6.216,
			6.279,
			6.31,
			6.339,
			6.403,
			6.418,
			6.464,
			6.528,
			6.589,
			6.65,
			6.713,
			6.748,
			6.776,
			6.835,
			6.897,
			6.961,
			7.022,
			7.069,
			7.085,
			7.145,
			7.178,
			7.208,
			7.272,
			7.286,
			7.333,
			7.395,
			7.456,
			7.504,
			7.519,
			7.581,
			7.611,
			7.642,
			7.72,
			7.767,
			7.83,
			7.891,
			7.938,
			8.013,
			8.046,
			8.077,
			8.139,
			8.2,
			8.263,
			8.326,
			8.371,
			8.386,
			8.447,
			8.48,
			8.512,
			8.587,
			8.635,
			8.697,
			8.756,
			8.82,
			8.884,
			8.915,
			8.944,
			9.008,
			9.068,
			9.131,
			9.194,
			9.255,
			9.316,
			9.349,
			9.378,
			9.442,
			9.503,
			9.567,
			9.627,
			9.675,
			9.69,
			9.752,
			9.783,
			9.814,
			9.875,
			9.891,
			9.938,
			10,
			10.06,
			10.109,
			10.186,
			10.216,
			10.248,
			10.326,
			10.371,
			10.433,
			10.494,
			10.543,
			10.618,
			10.65,
			10.68,
			10.743,
			10.76,
			10.805,
			10.871,
			10.93,
			10.975,
			10.993,
			11.054,
			11.084,
			11.115,
			11.194,
			11.239,
			11.302,
			11.363,
			11.427,
			11.486,
			11.519,
			11.548,
			11.61,
			11.672,
			11.737,
			11.797,
			11.844,
			11.859,
			11.922,
			11.953,
			11.983,
			12.045,
			12.062,
			12.109,
			12.17,
			12.23,
			12.278,
			12.293,
			12.356,
			12.386,
			12.418,
			12.478,
			12.496,
			12.542,
			12.603,
			12.665,
			12.711,
			12.789,
			12.821,
			12.853,
			12.933,
			12.975,
			13.038,
			13.099,
			13.145,
			13.161,
			13.224,
			13.255,
			13.287,
			13.347,
			13.363,
			13.409,
			13.471,
			13.533,
			13.581,
			13.597,
			13.656,
			13.689,
			13.718,
			13.798,
			13.844,
			13.906,
			13.967,
			14.015,
			14.092,
			14.122,
			14.155,
			14.217,
			14.279,
			14.339,
			14.401,
			14.448,
			14.464,
			14.526,
			14.555,
			14.587,
			14.65,
			14.665,
			14.713,
			14.775,
			14.836,
			14.884,
			14.96,
			14.996,
			15.021,
			15.084,
			15.144,
			15.206,
			15.269,
			15.317,
			15.395,
			15.426,
			15.455,
			15.533,
			15.581,
			15.643,
			15.704,
			15.75,
			15.765,
			15.829,
			15.86,
			15.891,
			15.952,
			15.969,
			16.013,
			16.077,
			16.137,
			16.184,
			16.261,
			16.294,
			16.325,
			16.385,
			16.448,
			16.51,
			16.572,
			16.635,
			16.697,
			16.726,
			16.758,
			16.836,
			16.883,
			16.944,
			17.008,
			17.068,
			17.131,
			17.162,
			17.193,
			17.271,
			17.316,
			17.379,
			17.442,
			17.487,
			17.563,
			17.597,
			17.626,
			17.689,
			17.752,
			17.812,
			17.876,
			17.921,
			17.937,
			17.998,
			18.029,
			18.061,
			18.121,
			18.137,
			18.183,
			18.247,
			18.309,
			18.37,
			18.432,
			18.464,
			18.494,
			18.555,
			18.571,
			18.617,
			18.68,
			18.743,
			18.79,
			18.867,
			18.898,
			18.928,
			18.991,
			19.053,
			19.118,
			19.178,
			19.222,
			19.238,
			19.299,
			19.33,
			19.363,
			19.426,
			19.438,
			19.485,
			19.55,
			19.61,
			19.658,
			19.734,
			19.764,
			19.798,
			19.876,
			19.921,
			19.984,
			20.044,
			20.105,
			20.17,
			20.198,
			20.231,
			20.293,
			20.31,
			20.355,
			20.417,
			20.479,
			20.541,
			20.603,
			20.634,
			20.666,
			20.728,
			20.741,
			20.789,
			20.856,
			20.913,
			20.961,
			21.038,
			21.069,
			21.1,
			21.162,
			21.221,
			21.286,
			21.346,
			21.393,
			21.409,
			21.471,
			21.503,
			21.533,
			21.594,
			21.612,
			21.658,
			21.719,
			21.78,
			21.827,
			21.844,
			21.906,
			21.935,
			21.967,
			22.03,
			22.044,
			22.092,
			22.153,
			22.214,
			22.261,
			22.339,
			22.372,
			22.402,
			22.463,
			22.526,
			22.588,
			22.65,
			22.694,
			22.712,
			22.774,
			22.805,
			22.835,
			22.898,
			22.911,
			22.96,
			23.02,
			23.083,
			23.144,
			23.208,
			23.243,
			23.271,
			23.348,
			23.394,
			23.457,
			23.517,
			23.564,
			23.643,
			23.671,
			23.703,
			23.766,
			23.829,
			23.893,
			23.952,
			24.012,
			24.077,
			24.107,
			24.138,
			24.2,
			24.214,
			24.261,
			24.325,
			24.385,
			24.431,
			24.448,
			24.509,
			24.539,
			24.572,
			24.635,
			24.695,
			24.76,
			24.82,
			24.867,
			24.943,
			24.975,
			25.004,
			25.068,
			25.129,
			25.193,
			25.255,
			25.315,
			25.377,
			25.408,
			25.438,
			25.502,
			25.519,
			25.564,
			25.627,
			25.688,
			25.736,
			25.81,
			25.842,
			25.872,
			25.936,
			25.998,
			26.06,
			26.121,
			26.169,
			26.185,
			26.244,
			26.277,
			26.307,
			26.369,
			26.386,
			26.431,
			26.493,
			26.557,
			26.602,
			26.619,
			26.68,
			26.712,
			26.743,
			26.803,
			26.819,
			26.867,
			26.928,
			26.989,
			27.037,
			27.113,
			27.145,
			27.176,
			27.239,
			27.299,
			27.362,
			27.423,
			27.47,
			27.486,
			27.548,
			27.578,
			27.611,
			27.688,
			27.734,
			27.797,
			27.859,
			27.921,
			27.981,
			28.014,
			28.045,
			28.105,
			28.122,
			28.168,
			28.231,
			28.293,
			28.337,
			28.416,
			28.446,
			28.479,
			28.542,
			28.603,
			28.664,
			28.727,
			28.787,
			28.851,
			28.882,
			28.913,
			28.973,
			28.989,
			29.037,
			29.1,
			29.161,
			29.222,
			29.286,
			29.315,
			29.348,
			29.409,
			29.471,
			29.533,
			29.593,
			29.657,
			29.719,
			29.751,
			29.781,
			29.859,
			29.905,
			29.967,
			30.03,
			30.073,
			30.09,
			30.153,
			30.183,
			30.214,
			30.292,
			30.34,
			30.402,
			30.462,
			30.509,
			30.585,
			30.617,
			30.649,
			30.726,
			30.771,
			30.835,
			30.896,
			30.944,
			30.958,
			31.02,
			31.051,
			31.081,
			31.16,
			31.207,
			31.268,
			31.33,
			31.377,
			31.393,
			31.454,
			31.493,
			31.517,
			31.578,
			31.594,
			31.642,
			31.702,
			31.764,
			31.811,
			31.888,
			31.92,
			31.951,
			32.014,
			32.076,
			32.139,
			32.199,
			32.244,
			32.261,
			32.321,
			32.355,
			32.386,
			32.446,
			32.461,
			32.509,
			32.572,
			32.632,
			32.681,
			32.694,
			32.758,
			32.788,
			32.819,
			32.897,
			32.944,
			33.004,
			33.067,
			33.128,
			33.192,
			33.221,
			33.253,
			33.331,
			33.376,
			33.44,
			33.501,
			33.564,
			33.623,
			33.656,
			33.686,
			33.748,
			33.766,
			33.81,
			33.873,
			33.936,
			33.98,
			33.996,
			34.06,
			34.092,
			34.12,
			34.2,
			34.245,
			34.308,
			34.37,
			34.417,
			34.493,
			34.523,
			34.555,
			34.618,
			34.634,
			34.681,
			34.742,
			34.803,
			34.85,
			34.866,
			34.929,
			34.959,
			34.989,
			35.052,
			35.067,
			35.115,
			35.18,
			35.237,
			35.298,
			35.361,
			35.393,
			35.423,
			35.485,
			35.548,
			35.614,
			35.672,
			35.719,
			35.734,
			35.797,
			35.827,
			35.856,
			35.935,
			35.98,
			36.045,
			36.106,
			36.153,
			36.168,
			36.23,
			36.261,
			36.291,
			36.355,
			36.368,
			36.417,
			36.479,
			36.538,
			36.586,
			36.663,
			36.695,
			36.725,
			36.789,
			36.85,
			36.913,
			36.973,
			37.02,
			37.036,
			37.099,
			37.127,
			37.16,
			37.221,
			37.238,
			37.285,
			37.346,
			37.407,
			37.469,
			37.531,
			37.564,
			37.594,
			37.657,
			37.676,
			37.719,
			37.78,
			37.843,
			37.889,
			37.967,
			37.997,
			38.027,
			38.091,
			38.153,
			38.214,
			38.276,
			38.322,
			38.339,
			38.4,
			38.43,
			38.462,
			38.523,
			38.541,
			38.586,
			38.647,
			38.709,
			38.756,
			38.773,
			38.835,
			38.866,
			38.896,
			38.958,
			39.02,
			39.082,
			39.144,
			39.206,
			39.268,
			39.3,
			39.33,
			39.392,
			39.409,
			39.453,
			39.517,
			39.578,
			39.626,
			39.642,
			39.703,
			39.738,
			39.763,
			39.826,
			39.842,
			39.887,
			39.949,
			40.013,
			40.06,
			40.138,
			40.168,
			40.198,
			40.261,
			40.322,
			40.386,
			40.446,
			40.509,
			40.57,
			40.6,
			40.631,
			40.695,
			40.709,
			40.756,
			40.82,
			40.88,
			40.928,
			40.943,
			41.004,
			41.035,
			41.066,
			41.129,
			41.144,
			41.192,
			41.253,
			41.314,
			41.362,
			41.438,
			41.468,
			41.499,
			41.561,
			41.625,
			41.686,
			41.75,
			41.809,
			41.873,
			41.905,
			41.935,
			41.997,
			42.013,
			42.058,
			42.121,
			42.182,
			42.245,
			42.305,
			42.338,
			42.368,
			42.445,
			42.493,
			42.556,
			42.617,
			42.662,
			42.74,
			42.771,
			42.803,
			42.864,
			42.925,
			42.989,
			43.051,
			43.097,
			43.113,
			43.176,
			43.205,
			43.236,
			43.298,
			43.315,
			43.361,
			43.424,
			43.485,
			43.531,
			43.548,
			43.609,
			43.639,
			43.67,
			43.748,
			43.796,
			43.855,
			43.861,
			43.919,
			43.982,
			44.042,
			44.073,
			44.106,
			44.183,
			44.228,
			44.292,
			44.353,
			44.4,
			44.414,
			44.477,
			44.509,
			44.538,
			44.617,
			44.663,
			44.726,
			44.786,
			44.832,
			44.85,
			44.912,
			44.943,
			44.972,
			45.036,
			45.097,
			45.161,
			45.222,
			45.269,
			45.345,
			45.377,
			45.406,
			45.47,
			45.486,
			45.531,
			45.593,
			45.656,
			45.702,
			45.717,
			45.778,
			45.811,
			45.839,
			45.903,
			45.921,
			45.965,
			46.028,
			46.089,
			46.137,
			46.213,
			46.246,
			46.276,
			46.337,
			46.4,
			46.461,
			46.522,
			46.57,
			46.585,
			46.649,
			46.679,
			46.71,
			46.772,
			46.788,
			46.835,
			46.896,
			46.958,
			47.003,
			47.019,
			47.081,
			47.113,
			47.145,
			47.222,
			47.268,
			47.331,
			47.393,
			47.452,
			47.514,
			47.548,
			47.578,
			47.656,
			47.701,
			47.764,
			47.826,
			47.888,
			47.949,
			47.984,
			48.012,
			48.074,
			48.088,
			48.135,
			48.199,
			48.26,
			48.305,
			48.323,
			48.386,
			48.413,
			48.446,
			48.509,
			48.57,
			48.632,
			48.694,
			48.742,
			48.819,
			48.848,
			48.881,
			48.943,
			48.959,
			49.004,
			49.065,
			49.128,
			49.176,
			49.188,
			49.252,
			49.282,
			49.312,
			49.377,
			49.392,
			49.438,
			49.503,
			49.563,
			49.608,
			49.687,
			49.718,
			49.749,
			49.825,
			49.873,
			49.934,
			49.995,
			50.056,
			50.121,
			50.153,
			50.181,
			50.245,
			50.26,
			50.306,
			50.368,
			50.431,
			50.477,
			50.493,
			50.554,
			50.585,
			50.615,
			50.677,
			50.694,
			50.739,
			50.802,
			50.864,
			50.927,
			50.989,
			51.019,
			51.05,
			51.112,
			51.173,
			51.238,
			51.298,
			51.343,
			51.361,
			51.422,
			51.452,
			51.485,
			51.547,
			51.562,
			51.61,
			51.672,
			51.735,
			51.78,
			51.794,
			51.856,
			51.889,
			51.917,
			51.981,
			51.995,
			52.042,
			52.103,
			52.165,
			52.214,
			52.289,
			52.323,
			52.354,
			52.414,
			52.476,
			52.54,
			52.601,
			52.648,
			52.664,
			52.725,
			52.756,
			52.785,
			52.864,
			52.911,
			52.973,
			53.034,
			53.081,
			53.097,
			53.16,
			53.189,
			53.222,
			53.298,
			53.344,
			53.408,
			53.47,
			53.514,
			53.594,
			53.622,
			53.655,
			53.716,
			53.778,
			53.841,
			53.903,
			53.948,
			53.964,
			54.028,
			54.056,
			54.089,
			54.149,
			54.171,
			54.214,
			54.276,
			54.337,
			54.384,
			54.459,
			54.493,
			54.521,
			54.585,
			54.648,
			54.709,
			54.77,
			54.817,
			54.896,
			54.926,
			54.955,
			55.036,
			55.08,
			55.144,
			55.205,
			55.266,
			55.33,
			55.359,
			55.392,
			55.452,
			55.47,
			55.516,
			55.577,
			55.638,
			55.687,
			55.764,
			55.795,
			55.826,
			55.903,
			55.948,
			56.012,
			56.074,
			56.136,
			56.197,
			56.233,
			56.258,
			56.32,
			56.337,
			56.384,
			56.445,
			56.506,
			56.553,
			56.57,
			56.632,
			56.663,
			56.694,
			56.755,
			56.769,
			56.818,
			56.879,
			56.942,
			56.988,
			57.066,
			57.097,
			57.127,
			57.204,
			57.252,
			57.314,
			57.376,
			57.422,
			57.436,
			57.5,
			57.529,
			57.562,
			57.622,
			57.638,
			57.686,
			57.751,
			57.81,
			57.855,
			57.872,
			57.934,
			57.964,
			57.997,
			58.057,
			58.073,
			58.12,
			58.183,
			58.243,
			58.293,
			58.369,
			58.398,
			58.431,
			58.505,
			58.553,
			58.617,
			58.677,
			58.723,
			58.74,
			58.803,
			58.833,
			58.865,
			58.926,
			58.941,
			58.989,
			59.05,
			59.11,
			59.173,
			59.236,
			59.268,
			59.297,
			59.359,
			59.422,
			59.484,
			59.545,
			59.592,
			59.671,
			59.7,
			59.731,
			59.793,
			59.81,
			59.856,
			59.917,
			59.98,
			60.026,
			60.043,
			60.105,
			60.136,
			60.167,
			60.228,
			60.244,
			60.289,
			60.355,
			60.413,
			60.462,
			60.538,
			60.569,
			60.601,
			60.661,
			60.726,
			60.786,
			60.847,
			60.893,
			60.911,
			60.973,
			61.004,
			61.034,
			61.097,
			61.111,
			61.158,
			61.22,
			61.281,
			61.329,
			61.345,
			61.405,
			61.438,
			61.468,
			61.529,
			61.546,
			61.593,
			61.655,
			61.717,
			61.767,
			61.84,
			61.871,
			61.903,
			61.964,
			62.027,
			62.088,
			62.15,
			62.197,
			62.211,
			62.274,
			62.306,
			62.337,
			62.419,
			62.461,
			62.523,
			62.585,
			62.645,
			62.71,
			62.74,
			62.769,
			62.832,
			62.849,
			62.894,
			62.955,
			63.017,
			63.065,
			63.144,
			63.175,
			63.205,
			63.267,
			63.329,
			63.39,
			63.453,
			63.516,
			63.577,
			63.609,
			63.639,
			63.715,
			63.763,
			63.826,
			63.887,
			63.934,
			64.01,
			64.043,
			64.072,
			64.135,
			64.195,
			64.26,
			64.32,
			64.381,
			64.445,
			64.481,
			64.506,
			64.568,
			64.585,
			64.632,
			64.693,
			64.753,
			64.802,
			64.816,
			64.878,
			64.909,
			64.941,
			65.003,
			65.019,
			65.064,
			65.128,
			65.189,
			65.235,
			65.311,
			65.344,
			65.376,
			65.437,
			65.498,
			65.56,
			65.621,
			65.669,
			65.685,
			65.748,
			65.777,
			65.81,
			65.886,
			65.932,
			65.994,
			66.057,
			66.103,
			66.118,
			66.18,
			66.213,
			66.244,
			66.321,
			66.368,
			66.431,
			66.49,
			66.553,
			66.616,
			66.647,
			66.676,
			66.737,
			66.802,
			66.862,
			66.926,
			66.97,
			66.987,
			67.049,
			67.079,
			67.111,
			67.188,
			67.236,
			67.296,
			67.36,
			67.406,
			67.42,
			67.483,
			67.514,
			67.546,
			67.608,
			67.621,
			67.669,
			67.73,
			67.792,
			67.839,
			67.917,
			67.947,
			67.978,
			68.042,
			68.058,
			68.104,
			68.165,
			68.227,
			68.275,
			68.29,
			68.35,
			68.381,
			68.412,
			68.475,
			68.492,
			68.537,
			68.604,
			68.66,
			68.709,
			68.784,
			68.817,
			68.849,
			68.911,
			68.972,
			69.035,
			69.097,
			69.156,
			69.221,
			69.25,
			69.281,
			69.343,
			69.359,
			69.405,
			69.467,
			69.53,
			69.576,
			69.59,
			69.653,
			69.685,
			69.717,
			69.779,
			69.793,
			69.839,
			69.9,
			69.962,
			70.009,
			70.086,
			70.117,
			70.151,
			70.228,
			70.272,
			70.336,
			70.397,
			70.46,
			70.522,
			70.554,
			70.585,
			70.644,
			70.666,
			70.706,
			70.768,
			70.831,
			70.877,
			70.893,
			70.956,
			70.986,
			71.017,
			71.081,
			71.095,
			71.142,
			71.202,
			71.266,
			71.313,
			71.388,
			71.42,
			71.451,
			71.514,
			71.577,
			71.64,
			71.7,
			71.747,
			71.76,
			71.823,
			71.854,
			71.885,
			71.965,
			72.01,
			72.072,
			72.134,
			72.179,
			72.196,
			72.259,
			72.288,
			72.319,
			72.382,
			72.398,
			72.445,
			72.506,
			72.568,
			72.631,
			72.693,
			72.728,
			72.753,
			72.815,
			72.878,
			72.941,
			73.003,
			73.064,
			73.126,
			73.156,
			73.188,
			73.251,
			73.265,
			73.312,
			73.375,
			73.437,
			73.483,
			73.499,
			73.561,
			73.592,
			73.622,
			73.685,
			73.747,
			73.809,
			73.869,
			73.917,
			73.994,
			74.027,
			74.058,
			74.135,
			74.18,
			74.243,
			74.305,
			74.351,
			74.365,
			74.429,
			74.459,
			74.489,
			74.552,
			74.569,
			74.617,
			74.676,
			74.74,
			74.789,
			74.861,
			74.894,
			74.923,
			74.986,
			75.05,
			75.112,
			75.173,
			75.233,
			75.296,
			75.328,
			75.36,
			75.437,
			75.481,
			75.545,
			75.605,
			75.654,
			75.668,
			75.732,
			75.762,
			75.793,
			75.854,
			75.871,
			75.916,
			75.979,
			76.04,
			76.087,
			76.164,
			76.194,
			76.227,
			76.289,
			76.352,
			76.414,
			76.476,
			76.52,
			76.536,
			76.598,
			76.63,
			76.661,
			76.721,
			76.738,
			76.785,
			76.851,
			76.91,
			76.955,
			76.972,
			77.032,
			77.063,
			77.094,
			77.156,
			77.172,
			77.219,
			77.282,
			77.343,
			77.388,
			77.467,
			77.497,
			77.528,
			77.592,
			77.653,
			77.715,
			77.777,
			77.825,
			77.838,
			77.9,
			77.931,
			77.964,
			78.026,
			78.041,
			78.088,
			78.151,
			78.211,
			78.272,
			78.334,
			78.365,
			78.398,
			78.459,
			78.521,
			78.584,
			78.644,
			78.691,
			78.769,
			78.801,
			78.832,
			78.893,
			78.914,
			78.954,
			79.018,
			79.078,
			79.126,
			79.142,
			79.202,
			79.234,
			79.264,
			79.328,
			79.343,
			79.388,
			79.45,
			79.512,
			79.56,
			79.638,
			79.669,
			79.7,
			79.76,
			79.825,
			79.885,
			79.947,
			79.994,
			80.009,
			80.072,
			80.103,
			80.134,
			80.196,
			80.211,
			80.256,
			80.319,
			80.383,
			80.428,
			80.443,
			80.505,
			80.536,
			80.569,
			80.63,
			80.644,
			80.69,
			80.755,
			80.815,
			80.863,
			80.939,
			80.975,
			81.001,
			81.079,
			81.127,
			81.189,
			81.25,
			81.31,
			81.374,
			81.405,
			81.436,
			81.514,
			81.559,
			81.621,
			81.685,
			81.745,
			81.806,
			81.84,
			81.871,
			81.93,
			81.947,
			81.993,
			82.055,
			82.118,
			82.165,
			82.242,
			82.273,
			82.303,
			82.365,
			82.427,
			82.489,
			82.552,
			82.599,
			82.614,
			82.676,
			82.706,
			82.739,
			82.799,
			82.816,
			82.862,
			82.922,
			82.986,
			83.048,
			83.111,
			83.142,
			83.172,
			83.235,
			83.295,
			83.359,
			83.42,
			83.467,
			83.483,
			83.545,
			83.576,
			83.607,
			83.683,
			83.678
		]
	],
	'2020_2026_7982': [
		[
			78.784
		],
		[
			0
		],
		[
			0,
			4.134,
			8.596,
			11.578,
			14.565,
			16.061,
			19.057,
			20.557,
			22.058,
			26.57,
			28.077,
			29.586,
			34.12,
			37.151,
			40.187,
			41.707,
			44.752,
			47.803,
			49.331,
			52.391,
			53.923,
			56.992,
			60.066,
			64.689,
			67.777,
			72.42,
			73.97,
			75.522,
			80.185,
			83.3,
			87.982,
			91.11,
			94.243,
			95.811,
			98.951,
			100.523,
			103.67,
			108.402,
			111.562,
			114.728,
			119.485,
			121.073,
			122.662,
			127.438,
			129.031,
			130.624,
			133.812,
			135.408,
			138.602,
			141.802,
			146.609,
			149.82,
			154.644,
			156.255,
			157.866,
			162.707,
			165.938,
			170.793,
			174.035,
			177.281,
			178.905,
			182.158,
			183.785,
			187.044,
			190.307,
			191.94,
			195.209,
			198.483,
			203.403,
			206.688,
			211.624,
			213.271,
			214.919,
			218.218,
			219.869,
			223.175,
			228.141,
			231.457,
			234.776,
			239.763,
			241.427,
			243.092,
			248.094,
			249.764,
			251.434,
			256.452,
			259.803,
			263.158,
			264.837,
			268.197,
			271.562,
			273.245,
			276.616,
			278.303,
			281.679,
			285.059,
			290.138,
			293.529,
			298.622,
			300.322,
			302.022,
			307.127,
			310.534,
			315.653,
			319.07,
			322.49,
			324.202,
			327.627,
			329.342,
			332.773,
			336.209,
			337.928,
			341.368,
			344.812,
			348.259,
			351.71,
			353.437,
			356.894,
			360.354,
			362.085,
			365.551,
			367.286,
			370.757,
			374.232,
			379.452,
			382.937,
			388.172,
			389.919,
			391.666,
			396.914,
			400.418,
			405.679,
			409.191,
			412.705,
			414.464,
			417.983,
			419.744,
			423.269,
			426.797,
			428.561,
			432.093,
			435.627,
			440.934,
			442.705,
			444.476,
			449.796,
			451.571,
			453.347,
			456.902,
			458.681,
			462.24,
			465.803,
			471.154,
			474.725,
			480.088,
			481.877,
			483.668,
			489.042,
			492.63,
			498.016,
			501.604,
			505.178,
			506.958,
			510.493,
			513.997,
			515.734,
			519.182,
			520.892,
			524.28,
			527.627,
			532.571,
			535.815,
			540.61,
			542.189,
			543.76,
			548.418,
			551.477,
			555.998,
			558.966,
			561.896,
			563.344,
			566.213,
			567.633,
			569.043,
			573.216,
			575.951,
			579.983,
			582.622,
			585.222,
			586.507,
			589.047,
			590.302,
			592.783,
			595.225,
			596.432,
			598.82,
			601.175,
			604.656,
			606.937,
			610.285,
			611.381,
			612.469,
			615.657,
			617.729,
			620.763,
			622.736,
			625.632,
			627.523,
			628.455,
			630.293,
			632.987,
			633.868,
			636.461,
			638.145,
			639.792,
			640.603,
			642.199,
			643.758,
			644.524,
			646.771,
			648.225,
			649.643,
			651.709,
			653.044,
			655,
			655.637,
			656.267,
			658.118,
			659.325,
			661.093,
			662.245,
			663.384,
			663.951,
			665.079,
			665.635,
			666.739,
			668.385,
			669.48,
			670.615,
			672.355,
			672.934,
			673.513,
			674.673,
			675.983,
			676.664,
			678.027,
			678.711,
			680.092,
			681.64,
			682.415,
			683.974,
			685.471,
			687.531,
			688.221,
			688.915,
			690.774,
			691.863,
			693.522,
			694.646,
			696.382,
			697.561,
			698.157,
			699.359,
			701.558,
			703.197,
			704.856,
			708.255,
			709.419,
			710.604,
			714.263,
			715.378,
			716.257,
			718.889,
			720.514,
			721.869,
			723.937,
			726.036,
			727.45,
			728.161,
			728.875,
			731.848,
			732.614,
			734.919,
			736.497,
			738.081,
			738.874,
			740.47,
			742.095,
			742.909,
			744.538,
			745.355,
			747.001,
			748.649,
			751.133,
			752.796,
			755.287,
			756.119,
			756.952,
			759.453,
			761.132,
			763.674,
			765.38,
			767.082,
			769.663,
			770.53,
			772.28,
			774.928,
			775.833,
			778.525,
			780.336,
			782.161,
			783.079,
			784.907,
			786.764,
			787.712,
			790.53,
			792.421,
			794.324,
			797.2,
			799.131,
			802.045,
			803.02,
			803.999,
			806.951,
			808.932,
			811.925,
			813.934,
			815.955,
			816.97,
			819.008,
			821.059,
			822.088,
			825.194,
			827.278,
			829.373,
			832.532,
			833.586,
			834.64,
			836.748,
			838.864,
			839.927,
			843.108,
			845.252,
			847.406,
			850.653,
			852.83,
			856.112,
			857.211,
			858.313,
			861.632,
			863.857,
			867.212,
			869.461,
			871.719,
			872.852,
			875.125,
			876.265,
			878.551,
			880.847,
			881.998,
			884.308,
			886.626,
			890.12,
			891.29,
			892.462,
			895.991,
			897.172,
			898.356,
			901.922,
			904.311,
			906.71,
			910.325,
			912.747,
			916.396,
			917.618,
			918.842,
			923.76,
			924.995,
			928.714,
			931.205,
			933.704,
			934.957,
			937.468,
			939.988,
			941.251,
			943.783,
			945.052,
			947.596,
			950.149,
			953.994,
			956.568,
			960.444,
			961.739,
			963.036,
			966.94,
			969.547,
			973.46,
			976.077,
			978.7,
			982.647,
			983.965,
			985.286,
			989.258,
			990.585,
			991.914,
			995.91,
			998.583,
			1001.261,
			1002.603,
			1005.292,
			1007.988,
			1009.339,
			1012.046,
			1013.403,
			1016.122,
			1018.85,
			1022.956,
			1025.702,
			1029.835,
			1031.216,
			1032.599,
			1036.756,
			1039.535,
			1043.714,
			1046.508,
			1049.307,
			1050.709,
			1053.518,
			1054.925,
			1056.333,
			1060.593,
			1062.008,
			1063.426,
			1067.665,
			1070.517,
			1073.377,
			1074.81,
			1077.681,
			1080.586,
			1082.029,
			1086.372,
			1089.251,
			1092.16,
			1096.524,
			1099.425,
			1103.745,
			1105.174,
			1106.594,
			1110.805,
			1113.564,
			1117.632,
			1120.296,
			1122.921,
			1124.22,
			1126.788,
			1128.058,
			1130.57,
			1133.043,
			1134.265,
			1136.68,
			1139.057,
			1142.555,
			1143.701,
			1144.837,
			1148.186,
			1149.281,
			1150.367,
			1152.51,
			1153.566,
			1155.649,
			1158.702,
			1160.689,
			1162.639,
			1165.51,
			1166.443,
			1167.367,
			1170.086,
			1171.867,
			1174.494,
			1176.217,
			1177.922,
			1178.766,
			1180.444,
			1182.11,
			1182.941,
			1185.431,
			1187.082,
			1188.735,
			1191.207,
			1192.85,
			1195.306,
			1196.123,
			1196.94,
			1199.396,
			1201.034,
			1203.5,
			1205.16,
			1206.82,
			1207.651,
			1209.341,
			1210.189,
			1211.887,
			1214.468,
			1215.337,
			1217.951,
			1219.728,
			1221.511,
			1222.404,
			1224.209,
			1226.027,
			1226.936,
			1228.763,
			1229.679,
			1231.512,
			1233.347,
			1236.104,
			1237.941,
			1240.721,
			1241.647,
			1242.574,
			1245.39,
			1247.27,
			1250.129,
			1252.044,
			1253.977,
			1254.956,
			1256.911,
			1257.887,
			1259.868,
			1261.849,
			1262.838,
			1264.831,
			1266.823,
			1268.811,
			1270.796,
			1271.786,
			1273.761,
			1275.72,
			1276.698,
			1278.646,
			1279.605,
			1281.516,
			1283.425,
			1286.244,
			1288.116,
			1290.894,
			1291.815,
			1292.735,
			1295.476,
			1297.294,
			1300.011,
			1301.815,
			1303.621,
			1304.513,
			1306.296,
			1307.188,
			1308.968,
			1310.73,
			1311.611,
			1313.374,
			1315.131,
			1317.768,
			1318.65,
			1319.529,
			1322.17,
			1323.054,
			1323.938,
			1326.59,
			1328.367,
			1331.044,
			1332.839,
			1334.648,
			1337.388,
			1338.307,
			1339.231,
			1342.961,
			1343.901,
			1346.729,
			1348.628,
			1350.547,
			1351.512,
			1353.449,
			1354.426,
			1356.396,
			1358.377,
			1359.37,
			1361.36,
			1363.36,
			1366.364,
			1368.377,
			1371.402,
			1372.41,
			1373.418,
			1376.442,
			1378.468,
			1380.509,
			1383.591,
			1385.665,
			1386.707,
			1388.799,
			1389.851,
			1390.906,
			1394.085,
			1395.153,
			1396.223,
			1399.447,
			1401.615,
			1403.79,
			1404.882,
			1407.072,
			1409.27,
			1410.374,
			1412.587,
			1413.697,
			1415.908,
			1418.15,
			1421.541,
			1423.818,
			1427.266,
			1428.422,
			1429.58,
			1433.08,
			1435.426,
			1438.967,
			1441.339,
			1443.723,
			1444.918,
			1447.321,
			1448.525,
			1450.944,
			1453.375,
			1454.594,
			1455.816,
			1459.499,
			1461.967,
			1464.445,
			1465.687,
			1468.179,
			1470.68,
			1471.934,
			1474.448,
			1475.709,
			1478.237,
			1480.774,
			1484.596,
			1487.156,
			1491.013,
			1492.303,
			1493.595,
			1497.485,
			1500.088,
			1504.004,
			1506.616,
			1509.234,
			1510.545,
			1513.173,
			1514.489,
			1517.126,
			1519.77,
			1521.094,
			1523.747,
			1526.406,
			1530.406,
			1531.743,
			1533.082,
			1535.763,
			1538.454,
			1539.801,
			1543.855,
			1546.566,
			1549.283,
			1553.374,
			1556.111,
			1560.232,
			1561.609,
			1562.99,
			1567.14,
			1569.917,
			1574.093,
			1576.885,
			1579.683,
			1581.085,
			1583.891,
			1586.704,
			1588.112,
			1590.934,
			1592.347,
			1595.178,
			1598.016,
			1602.284,
			1605.137,
			1609.429,
			1610.863,
			1612.299,
			1616.615,
			1619.501,
			1623.842,
			1626.743,
			1629.654,
			1634.033,
			1635.497,
			1636.962,
			1641.367,
			1642.838,
			1644.311,
			1648.74,
			1651.701,
			1654.668,
			1656.153,
			1659.129,
			1662.109,
			1663.602,
			1666.591,
			1668.087,
			1671.084,
			1674.086,
			1678.598,
			1681.613,
			1686.144,
			1687.657,
			1689.172,
			1693.723,
			1696.763,
			1701.336,
			1704.391,
			1707.452,
			1708.985,
			1712.055,
			1713.592,
			1716.669,
			1719.75,
			1721.293,
			1722.838,
			1727.478,
			1730.577,
			1733.682,
			1735.236,
			1738.349,
			1741.468,
			1743.029,
			1747.721,
			1750.857,
			1753.998,
			1758.718,
			1761.871,
			1766.609,
			1768.19,
			1769.773,
			1774.529,
			1777.706,
			1782.48,
			1785.669,
			1788.863,
			1790.461,
			1793.659,
			1795.258,
			1798.459,
			1801.665,
			1803.27,
			1806.484,
			1809.702,
			1814.537,
			1816.152,
			1817.766,
			1822.617,
			1824.235,
			1825.854,
			1830.717,
			1833.962,
			1837.211,
			1842.091,
			1845.35,
			1850.247,
			1851.881,
			1853.516,
			1858.428,
			1860.067,
			1861.707,
			1866.631,
			1869.919,
			1874.858,
			1878.156,
			1879.806,
			1883.109,
			1886.416,
			1888.07,
			1891.384,
			1894.702,
			1899.686,
			1901.349,
			1903.014,
			1908.013,
			1909.682,
			1911.351,
			1916.362,
			1919.706,
			1924.728,
			1928.078,
			1931.431,
			1933.108,
			1936.465,
			1938.145,
			1939.825,
			1944.871,
			1946.554,
			1948.238,
			1953.293,
			1956.667,
			1960.045,
			1961.736,
			1965.12,
			1968.508,
			1970.204,
			1975.296,
			1978.695,
			1982.099,
			1987.212,
			1990.625,
			1995.751,
			1997.462,
			1999.173,
			2004.314,
			2007.746,
			2012.901,
			2016.342,
			2021.51,
			2024.96,
			2026.686,
			2030.141,
			2033.598,
			2035.328,
			2038.791,
			2042.257,
			2045.726,
			2049.2,
			2050.938,
			2054.417,
			2057.9,
			2059.644,
			2063.134,
			2064.88,
			2068.375,
			2071.874,
			2077.13,
			2080.638,
			2085.906,
			2087.664,
			2089.423,
			2094.706,
			2098.233,
			2103.531,
			2107.069,
			2112.389,
			2115.943,
			2117.722,
			2121.283,
			2124.845,
			2126.628,
			2130.195,
			2133.762,
			2139.113,
			2140.896,
			2142.679,
			2148.027,
			2149.81,
			2151.593,
			2155.161,
			2156.945,
			2160.515,
			2164.087,
			2169.45,
			2173.028,
			2178.401,
			2180.193,
			2181.985,
			2187.366,
			2190.957,
			2196.353,
			2199.956,
			2203.562,
			2205.367,
			2208.98,
			2212.595,
			2214.403,
			2218.021,
			2219.83,
			2223.451,
			2227.074,
			2232.51,
			2236.136,
			2241.577,
			2243.391,
			2245.205,
			2250.65,
			2254.282,
			2259.735,
			2263.373,
			2267.013,
			2268.834,
			2272.478,
			2274.301,
			2276.124,
			2281.598,
			2285.251,
			2290.735,
			2294.394,
			2299.886,
			2303.55,
			2307.218,
			2309.052,
			2312.722,
			2314.558,
			2318.23,
			2321.906,
			2327.423,
			2331.104,
			2336.629,
			2338.473,
			2340.316,
			2345.851,
			2349.542,
			2355.084,
			2358.782,
			2362.482,
			2364.333,
			2368.037,
			2369.89,
			2373.598,
			2377.307,
			2379.164,
			2381.02,
			2386.594,
			2390.312,
			2394.033,
			2395.895,
			2399.62,
			2403.348,
			2405.214,
			2408.946,
			2410.813,
			2414.55,
			2418.29,
			2423.906,
			2427.654,
			2433.278,
			2435.154,
			2437.03,
			2442.664,
			2446.421,
			2452.06,
			2455.82,
			2459.612,
			2461.461,
			2465.223,
			2467.104,
			2470.869,
			2474.635,
			2476.519,
			2478.435,
			2484.063,
			2487.839,
			2491.618,
			2493.509,
			2497.294,
			2501.082,
			2502.976,
			2508.664,
			2512.457,
			2516.253,
			2521.954,
			2525.76,
			2531.472,
			2533.376,
			2535.28,
			2540.991,
			2542.895,
			2544.799,
			2550.508,
			2554.314,
			2558.118,
			2560.021,
			2563.826,
			2565.729,
			2569.535,
			2573.343,
			2575.248,
			2579.058,
			2582.87,
			2588.592,
			2592.41,
			2598.14,
			2600.05,
			2601.962,
			2607.699,
			2611.525,
			2615.354,
			2621.101,
			2624.933,
			2626.849,
			2630.683,
			2632.601,
			2634.52,
			2642.196,
			2644.116,
			2649.875,
			2653.72,
			2657.572,
			2659.502,
			2663.374,
			2667.262,
			2669.216,
			2673.126,
			2675.083,
			2678.992,
			2682.894,
			2688.769,
			2692.632,
			2698.509,
			2700.424,
			2702.371,
			2708.204,
			2712.088,
			2717.905,
			2721.781,
			2725.655,
			2727.593,
			2731.469,
			2733.408,
			2737.287,
			2741.168,
			2743.11,
			2745.052,
			2750.882,
			2754.771,
			2758.66,
			2760.604,
			2764.493,
			2768.383,
			2770.329,
			2776.167,
			2780.052,
			2783.921,
			2789.675,
			2793.465,
			2799.066,
			2800.91,
			2802.744,
			2808.175,
			2811.743,
			2817.019,
			2820.486,
			2823.944,
			2825.646,
			2828.994,
			2830.67,
			2833.997,
			2837.292,
			2838.953,
			2842.191,
			2845.395,
			2848.543,
			2853.269,
			2856.408,
			2859.54,
			2861.13,
			2864.253,
			2865.812,
			2868.927,
			2872.035,
			2876.686,
			2879.778,
			2884.404,
			2885.942,
			2887.478,
			2892.077,
			2895.138,
			2899.722,
			2902.776,
			2907.358,
			2910.416,
			2911.946,
			2915.009,
			2918.079,
			2919.615,
			2922.691,
			2925.773,
			2930.407,
			2931.956,
			2933.506,
			2938.163,
			2939.718,
			2941.276,
			2944.395,
			2945.957,
			2949.088,
			2952.226,
			2956.946,
			2960.1,
			2964.809,
			2966.415,
			2967.995,
			2972.743,
			2974.328,
			2975.914,
			2980.677,
			2983.854,
			2987.032,
			2988.622,
			2991.801,
			2994.981,
			2996.573,
			2999.76,
			3001.354,
			3004.552,
			3007.757,
			3012.586,
			3015.825,
			3020.708,
			3022.339,
			3023.975,
			3028.888,
			3032.168,
			3037.074,
			3040.322,
			3043.552,
			3045.158,
			3048.361,
			3049.958,
			3051.552,
			3056.328,
			3057.918,
			3059.508,
			3064.269,
			3067.411,
			3072.187,
			3075.354,
			3078.525,
			3080.112,
			3083.287,
			3084.874,
			3088.047,
			3091.215,
			3095.967,
			3099.107,
			3103.854,
			3105.463,
			3107.047,
			3111.802,
			3113.389,
			3114.977,
			3119.747,
			3122.932,
			3127.719,
			3130.918,
			3132.52,
			3135.727,
			3140.545,
			3143.76,
			3146.975,
			3151.8,
			3153.409,
			3155.018,
			3159.848,
			3161.458,
			3163.07,
			3166.296,
			3167.884,
			3171.117,
			3174.355,
			3179.249,
			3182.497,
			3187.376,
			3189.005,
			3190.606,
			3195.5,
			3198.793,
			3203.701,
			3206.95,
			3211.872,
			3215.157,
			3216.802,
			3220.094,
			3225.067,
			3228.341,
			3231.647,
			3236.614,
			3238.272,
			3239.931,
			3244.914,
			3246.577,
			3248.24,
			3253.237,
			3256.571,
			3259.904,
			3264.884,
			3268.181,
			3273.073,
			3274.687,
			3276.292,
			3281.051,
			3284.179,
			3288.803,
			3291.841,
			3296.335,
			3299.288,
			3300.75,
			3303.646,
			3306.504,
			3307.919,
			3310.72,
			3313.505,
			3317.578,
			3318.917,
			3320.248,
			3324.164,
			3325.458,
			3326.743,
			3330.542,
			3333.028,
			3336.704,
			3339.093,
			3341.427,
			3342.591,
			3344.89,
			3346.025,
			3348.283,
			3350.482,
			3351.567,
			3352.644,
			3355.813,
			3357.882,
			3360.916,
			3362.897,
			3364.846,
			3365.809,
			3367.715,
			3368.659,
			3370.524,
			3372.368,
			3375.097,
			3376.896,
			3379.582,
			3380.476,
			3381.37,
			3384.059,
			3385.867,
			3388.587,
			3390.417,
			3392.253,
			3393.173,
			3395.021,
			3395.948,
			3396.876,
			3399.667,
			3400.604,
			3402.476,
			3404.351,
			3406.238,
			3408.129,
			3409.076,
			3410.981,
			3412.892,
			3413.85,
			3415.779,
			3416.747,
			3418.687,
			3420.643,
			3423.594,
			3425.579,
			3428.575,
			3429.575,
			3430.589,
			3433.636,
			3435.681,
			3438.77,
			3440.842,
			3443.97,
			3446.061,
			3447.108,
			3449.199,
			3451.295,
			3452.343,
			3454.433,
			3456.532,
			3459.679,
			3461.784,
			3464.938,
			3465.989,
			3467.038,
			3469.133,
			3470.18,
			3472.284,
			3474.384,
			3477.543,
			3479.65,
			3482.808,
			3483.859,
			3484.911,
			3488.063,
			3490.163,
			3493.308,
			3495.403,
			3497.487,
			3498.527,
			3500.607,
			3501.653,
			3503.74,
			3505.826,
			3506.869,
			3508.951,
			3511.03,
			3514.138,
			3516.204,
			3519.277,
			3520.3,
			3521.318,
			3524.35,
			3526.367,
			3529.371,
			3531.373,
			3533.365,
			3536.356,
			3537.347,
			3538.337,
			3541.313,
			3542.307,
			3543.302,
			3546.293,
			3548.296,
			3551.312,
			3553.333,
			3555.362,
			3556.379,
			3558.423,
			3559.448,
			3561.506,
			3563.578,
			3566.707,
			3568.813,
			3571.993,
			3573.061,
			3574.133,
			3577.365,
			3579.521,
			3582.771,
			3584.936,
			3587.111,
			3588.197,
			3590.373,
			3591.465,
			3593.652,
			3595.846,
			3596.947,
			3599.156,
			3601.374,
			3603.602,
			3605.84,
			3606.962,
			3609.214,
			3611.456,
			3612.589,
			3614.859,
			3615.995,
			3618.263,
			3620.517,
			3623.85,
			3626.029,
			3629.227,
			3630.274,
			3631.312,
			3634.371,
			3636.364,
			3639.285,
			3641.188,
			3643.975,
			3645.791,
			3646.687,
			3648.462,
			3651.099,
			3651.965,
			3654.546,
			3656.252,
			3657.951,
			3658.798,
			3660.484,
			3662.163,
			3663.001,
			3665.514,
			3667.187,
			3668.859,
			3671.378,
			3673.057,
			3675.579,
			3676.421,
			3677.262,
			3679.787,
			3681.479,
			3684.012,
			3685.714,
			3687.422,
			3688.275,
			3689.988,
			3690.862,
			3692.608,
			3694.354,
			3695.24,
			3697.043,
			3698.846,
			3701.613,
			3703.486,
			3706.331,
			3707.293,
			3708.255,
			3711.155,
			3713.101,
			3715.046,
			3717.889,
			3719.778,
			3722.517,
			3723.421,
			3724.326,
			3726.997,
			3728.758,
			3731.374,
			3733.114,
			3734.864,
			3735.744,
			3737.501,
			3739.27,
			3740.16,
			3741.945,
			3742.841,
			3744.644,
			3746.459,
			3749.205,
			3751.052,
			3753.851,
			3754.791,
			3755.733,
			3758.591,
			3760.526,
			3763.433,
			3765.376,
			3767.331,
			3770.273,
			3771.252,
			3773.221,
			3775.181,
			3776.159,
			3777.138,
			3780.066,
			3782.013,
			3783.958,
			3784.929,
			3786.873,
			3788.815,
			3789.785,
			3791.726,
			3792.699,
			3794.645,
			3796.589,
			3799.547,
			3801.519,
			3804.507,
			3805.503,
			3806.499,
			3809.49,
			3811.483,
			3814.458,
			3816.44,
			3818.406,
			3819.381,
			3821.338,
			3822.32,
			3824.248,
			3826.182,
			3827.153,
			3829.073,
			3831.018,
			3833.956,
			3835.927,
			3837.911,
			3839.907,
			3840.91,
			3842.925,
			3843.938,
			3845.975,
			3848.024,
			3851.13,
			3853.211,
			3856.368,
			3857.414,
			3858.461,
			3861.617,
			3863.721,
			3866.893,
			3869.012,
			3871.144,
			3872.21,
			3874.353,
			3875.435,
			3877.596,
			3879.78,
			3880.881,
			3883.083,
			3885.309,
			3888.664,
			3889.782,
			3890.901,
			3894.261,
			3895.361,
			3896.463,
			3899.762,
			3901.911,
			3904.067,
			3907.254,
			3909.374,
			3912.574,
			3913.645,
			3914.72,
			3919.074,
			3920.17,
			3923.478,
			3925.689,
			3927.906,
			3929.017,
			3931.233,
			3933.451,
			3934.564,
			3936.779,
			3937.888,
			3940.112,
			3942.328,
			3945.661,
			3947.884,
			3951.229,
			3952.347,
			3953.466,
			3956.839,
			3959.1,
			3962.509,
			3964.793,
			3968.233,
			3970.536,
			3971.687,
			3972.841,
			3976.316,
			3978.645,
			3982.155,
			3984.508,
			3986.871,
			3988.058,
			3990.438,
			3992.811,
			3994.01,
			3996.417,
			3997.624,
			4000.064,
			4002.49,
			4006.138,
			4008.557,
			4012.232,
			4013.46,
			4014.692,
			4018.403,
			4020.892,
			4024.656,
			4027.18,
			4029.724,
			4030.999,
			4033.56,
			4034.846,
			4037.424,
			4040.013,
			4041.31,
			4043.909,
			4046.515,
			4049.123,
			4053.036,
			4055.655,
			4058.28,
			4059.596,
			4062.232,
			4063.553,
			4066.201,
			4068.856,
			4072.852,
			4075.524,
			4079.545,
			4080.89,
			4082.235,
			4086.284,
			4088.992,
			4093.066,
			4095.792,
			4098.524,
			4099.893,
			4102.635,
			4104.009,
			4106.761,
			4109.52,
			4110.902,
			4113.671,
			4116.447,
			4120.626,
			4122.023,
			4123.422,
			4127.628,
			4129.034,
			4130.441,
			4134.675,
			4137.505,
			4140.342,
			4141.763,
			4144.609,
			4147.461,
			4151.751,
			4153.185,
			4154.619,
			4158.932,
			4161.814,
			4166.151,
			4169.05,
			4171.956,
			4173.411,
			4176.327,
			4177.787,
			4180.713,
			4183.645,
			4185.113,
			4188.054,
			4191,
			4195.431,
			4196.266
		],
		[
			0,
			0.075,
			0.135,
			0.199,
			0.244,
			0.261,
			0.321,
			0.354,
			0.383,
			0.446,
			0.463,
			0.507,
			0.571,
			0.633,
			0.678,
			0.695,
			0.757,
			0.787,
			0.818,
			0.882,
			0.896,
			0.943,
			1.005,
			1.066,
			1.128,
			1.191,
			1.223,
			1.253,
			1.33,
			1.377,
			1.44,
			1.5,
			1.55,
			1.563,
			1.625,
			1.656,
			1.687,
			1.765,
			1.81,
			1.871,
			1.935,
			1.982,
			1.997,
			2.06,
			2.088,
			2.121,
			2.183,
			2.197,
			2.243,
			2.306,
			2.367,
			2.415,
			2.494,
			2.524,
			2.556,
			2.631,
			2.68,
			2.742,
			2.804,
			2.85,
			2.866,
			2.927,
			2.957,
			2.989,
			3.051,
			3.066,
			3.114,
			3.175,
			3.237,
			3.298,
			3.361,
			3.392,
			3.423,
			3.483,
			3.501,
			3.546,
			3.609,
			3.67,
			3.718,
			3.795,
			3.826,
			3.856,
			3.92,
			3.935,
			3.981,
			4.045,
			4.106,
			4.152,
			4.168,
			4.23,
			4.26,
			4.29,
			4.353,
			4.367,
			4.416,
			4.478,
			4.539,
			4.602,
			4.662,
			4.694,
			4.725,
			4.788,
			4.85,
			4.91,
			4.973,
			5.02,
			5.034,
			5.098,
			5.128,
			5.158,
			5.222,
			5.237,
			5.285,
			5.345,
			5.407,
			5.453,
			5.467,
			5.532,
			5.562,
			5.594,
			5.656,
			5.675,
			5.718,
			5.779,
			5.843,
			5.887,
			5.967,
			5.998,
			6.027,
			6.091,
			6.15,
			6.214,
			6.277,
			6.322,
			6.339,
			6.398,
			6.43,
			6.46,
			6.523,
			6.54,
			6.585,
			6.648,
			6.711,
			6.756,
			6.773,
			6.834,
			6.864,
			6.895,
			6.957,
			6.974,
			7.021,
			7.082,
			7.145,
			7.206,
			7.268,
			7.298,
			7.331,
			7.406,
			7.454,
			7.516,
			7.577,
			7.624,
			7.64,
			7.7,
			7.735,
			7.763,
			7.824,
			7.842,
			7.887,
			7.951,
			8.01,
			8.058,
			8.134,
			8.165,
			8.198,
			8.261,
			8.321,
			8.383,
			8.445,
			8.492,
			8.509,
			8.571,
			8.602,
			8.631,
			8.695,
			8.757,
			8.817,
			8.881,
			8.925,
			8.942,
			9.005,
			9.033,
			9.064,
			9.129,
			9.142,
			9.189,
			9.253,
			9.314,
			9.361,
			9.438,
			9.47,
			9.498,
			9.577,
			9.625,
			9.684,
			9.748,
			9.809,
			9.871,
			9.904,
			9.934,
			10.011,
			10.058,
			10.119,
			10.182,
			10.228,
			10.245,
			10.305,
			10.337,
			10.366,
			10.445,
			10.492,
			10.555,
			10.615,
			10.663,
			10.738,
			10.771,
			10.802,
			10.863,
			10.926,
			10.988,
			11.051,
			11.097,
			11.111,
			11.175,
			11.205,
			11.237,
			11.313,
			11.361,
			11.423,
			11.485,
			11.53,
			11.546,
			11.608,
			11.64,
			11.669,
			11.731,
			11.747,
			11.795,
			11.856,
			11.861,
			11.917,
			11.981,
			12.043,
			12.074,
			12.105,
			12.181,
			12.229,
			12.292,
			12.353,
			12.414,
			12.477,
			12.506,
			12.537,
			12.615,
			12.663,
			12.726,
			12.787,
			12.832,
			12.848,
			12.909,
			12.942,
			12.971,
			13.049,
			13.096,
			13.158,
			13.22,
			13.283,
			13.345,
			13.375,
			13.406,
			13.484,
			13.53,
			13.593,
			13.654,
			13.701,
			13.715,
			13.778,
			13.809,
			13.839,
			13.903,
			13.923,
			13.965,
			14.028,
			14.09,
			14.135,
			14.214,
			14.243,
			14.274,
			14.336,
			14.397,
			14.461,
			14.522,
			14.57,
			14.646,
			14.677,
			14.709,
			14.786,
			14.832,
			14.895,
			14.957,
			15.004,
			15.019,
			15.081,
			15.111,
			15.142,
			15.221,
			15.266,
			15.33,
			15.391,
			15.453,
			15.515,
			15.545,
			15.576,
			15.639,
			15.701,
			15.763,
			15.825,
			15.872,
			15.888,
			15.948,
			15.983,
			16.011,
			16.088,
			16.136,
			16.195,
			16.258,
			16.306,
			16.322,
			16.381,
			16.414,
			16.444,
			16.522,
			16.57,
			16.629,
			16.692,
			16.739,
			16.816,
			16.847,
			16.878,
			16.942,
			17.002,
			17.066,
			17.128,
			17.174,
			17.189,
			17.25,
			17.28,
			17.312,
			17.375,
			17.389,
			17.437,
			17.5,
			17.562,
			17.606,
			17.623,
			17.685,
			17.715,
			17.747,
			17.809,
			17.87,
			17.933,
			17.995,
			18.045,
			18.12,
			18.148,
			18.181,
			18.259,
			18.303,
			18.366,
			18.428,
			18.474,
			18.491,
			18.554,
			18.585,
			18.615,
			18.678,
			18.691,
			18.738,
			18.802,
			18.861,
			18.909,
			18.987,
			19.019,
			19.049,
			19.125,
			19.172,
			19.235,
			19.298,
			19.344,
			19.42,
			19.453,
			19.481,
			19.544,
			19.559,
			19.605,
			19.67,
			19.73,
			19.779,
			19.793,
			19.856,
			19.887,
			19.916,
			19.979,
			19.995,
			20.042,
			20.107,
			20.164,
			20.211,
			20.29,
			20.321,
			20.352,
			20.412,
			20.476,
			20.536,
			20.598,
			20.646,
			20.66,
			20.722,
			20.753,
			20.782,
			20.846,
			20.863,
			20.908,
			20.97,
			21.032,
			21.078,
			21.095,
			21.156,
			21.187,
			21.219,
			21.296,
			21.344,
			21.404,
			21.467,
			21.528,
			21.592,
			21.623,
			21.653,
			21.731,
			21.777,
			21.839,
			21.9,
			21.947,
			21.963,
			22.024,
			22.057,
			22.088,
			22.148,
			22.169,
			22.21,
			22.272,
			22.336,
			22.382,
			22.397,
			22.461,
			22.492,
			22.523,
			22.583,
			22.6,
			22.646,
			22.709,
			22.77,
			22.815,
			22.892,
			22.926,
			22.956,
			23.017,
			23.08,
			23.142,
			23.203,
			23.25,
			23.265,
			23.327,
			23.359,
			23.39,
			23.467,
			23.514,
			23.576,
			23.638,
			23.684,
			23.763,
			23.791,
			23.825,
			23.886,
			23.947,
			24.009,
			24.073,
			24.118,
			24.134,
			24.195,
			24.231,
			24.258,
			24.336,
			24.38,
			24.443,
			24.506,
			24.553,
			24.566,
			24.628,
			24.661,
			24.691,
			24.753,
			24.77,
			24.815,
			24.879,
			24.938,
			24.987,
			25.065,
			25.095,
			25.124,
			25.189,
			25.248,
			25.311,
			25.375,
			25.42,
			25.436,
			25.497,
			25.529,
			25.561,
			25.623,
			25.637,
			25.685,
			25.746,
			25.807,
			25.855,
			25.871,
			25.933,
			25.964,
			25.995,
			26.056,
			26.072,
			26.119,
			26.181,
			26.242,
			26.292,
			26.367,
			26.397,
			26.429,
			26.505,
			26.553,
			26.615,
			26.677,
			26.722,
			26.739,
			26.801,
			26.831,
			26.861,
			26.925,
			26.939,
			26.986,
			27.048,
			27.11,
			27.158,
			27.171,
			27.234,
			27.264,
			27.297,
			27.358,
			27.42,
			27.483,
			27.544,
			27.607,
			27.67,
			27.699,
			27.73,
			27.809,
			27.855,
			27.915,
			27.977,
			28.025,
			28.04,
			28.103,
			28.132,
			28.163,
			28.226,
			28.242,
			28.29,
			28.35,
			28.414,
			28.459,
			28.537,
			28.566,
			28.599,
			28.676,
			28.722,
			28.783,
			28.847,
			28.893,
			28.909,
			28.972,
			29.002,
			29.033,
			29.094,
			29.111,
			29.157,
			29.22,
			29.28,
			29.326,
			29.341,
			29.404,
			29.437,
			29.467,
			29.529,
			29.545,
			29.59,
			29.653,
			29.714,
			29.762,
			29.839,
			29.869,
			29.9,
			29.964,
			30.024,
			30.086,
			30.148,
			30.196,
			30.211,
			30.272,
			30.304,
			30.336,
			30.398,
			30.415,
			30.458,
			30.521,
			30.581,
			30.63,
			30.644,
			30.705,
			30.738,
			30.767,
			30.831,
			30.847,
			30.892,
			30.954,
			31.016,
			31.063,
			31.14,
			31.171,
			31.203,
			31.265,
			31.327,
			31.39,
			31.451,
			31.498,
			31.512,
			31.576,
			31.607,
			31.637,
			31.697,
			31.715,
			31.761,
			31.823,
			31.885,
			31.93,
			31.947,
			32.008,
			32.04,
			32.072,
			32.134,
			32.195,
			32.256,
			32.32,
			32.382,
			32.442,
			32.478,
			32.506,
			32.568,
			32.63,
			32.692,
			32.754,
			32.8,
			32.816,
			32.877,
			32.909,
			32.938,
			32.999,
			33.016,
			33.063,
			33.125,
			33.186,
			33.235,
			33.312,
			33.343,
			33.374,
			33.433,
			33.498,
			33.559,
			33.622,
			33.669,
			33.745,
			33.775,
			33.807,
			33.871,
			33.885,
			33.933,
			33.994,
			34.054,
			34.103,
			34.119,
			34.179,
			34.211,
			34.242,
			34.302,
			34.32,
			34.364,
			34.429,
			34.49,
			34.54,
			34.615,
			34.646,
			34.674,
			34.739,
			34.799,
			34.862,
			34.923,
			34.969,
			34.986,
			35.048,
			35.078,
			35.109,
			35.172,
			35.187,
			35.231,
			35.296,
			35.357,
			35.405,
			35.42,
			35.481,
			35.512,
			35.545,
			35.622,
			35.668,
			35.731,
			35.791,
			35.839,
			35.916,
			35.946,
			35.979,
			36.038,
			36.103,
			36.164,
			36.227,
			36.271,
			36.289,
			36.349,
			36.38,
			36.412,
			36.474,
			36.488,
			36.537,
			36.602,
			36.661,
			36.706,
			36.722,
			36.783,
			36.813,
			36.844,
			36.922,
			36.971,
			37.032,
			37.095,
			37.139,
			37.217,
			37.25,
			37.281,
			37.341,
			37.359,
			37.403,
			37.464,
			37.527,
			37.589,
			37.653,
			37.683,
			37.714,
			37.775,
			37.792,
			37.838,
			37.901,
			37.962,
			38.008,
			38.025,
			38.087,
			38.117,
			38.147,
			38.21,
			38.272,
			38.335,
			38.395,
			38.442,
			38.458,
			38.521,
			38.553,
			38.583,
			38.644,
			38.664,
			38.706,
			38.767,
			38.83,
			38.877,
			38.892,
			38.953,
			38.986,
			39.016,
			39.094,
			39.138,
			39.2,
			39.263,
			39.311,
			39.387,
			39.419,
			39.45,
			39.512,
			39.575,
			39.637,
			39.698,
			39.759,
			39.823,
			39.853,
			39.885,
			39.945,
			39.961,
			40.009,
			40.07,
			40.131,
			40.179,
			40.195,
			40.255,
			40.287,
			40.319,
			40.38,
			40.395,
			40.441,
			40.503,
			40.565,
			40.613,
			40.689,
			40.725,
			40.75,
			40.831,
			40.876,
			40.938,
			41,
			41.063,
			41.123,
			41.155,
			41.187,
			41.248,
			41.265,
			41.31,
			41.373,
			41.435,
			41.481,
			41.495,
			41.559,
			41.589,
			41.619,
			41.682,
			41.697,
			41.743,
			41.807,
			41.869,
			41.913,
			41.992,
			42.022,
			42.053,
			42.115,
			42.178,
			42.242,
			42.303,
			42.35,
			42.364,
			42.425,
			42.459,
			42.489,
			42.548,
			42.565,
			42.612,
			42.676,
			42.736,
			42.798,
			42.861,
			42.892,
			42.924,
			42.984,
			43.046,
			43.109,
			43.169,
			43.217,
			43.234,
			43.294,
			43.326,
			43.356,
			43.432,
			43.48,
			43.545,
			43.606,
			43.666,
			43.728,
			43.761,
			43.792,
			43.853,
			43.866,
			43.914,
			43.978,
			44.038,
			44.099,
			44.162,
			44.194,
			44.225,
			44.288,
			44.35,
			44.41,
			44.471,
			44.52,
			44.535,
			44.597,
			44.629,
			44.659,
			44.72,
			44.736,
			44.781,
			44.843,
			44.905,
			44.954,
			44.969,
			45.031,
			45.062,
			45.092,
			45.154,
			45.17,
			45.215,
			45.278,
			45.342,
			45.387,
			45.464,
			45.495,
			45.528,
			45.588,
			45.651,
			45.714,
			45.776,
			45.823,
			45.837,
			45.899,
			45.931,
			45.962,
			46.022,
			46.039,
			46.083,
			46.148,
			46.208,
			46.256,
			46.272,
			46.332,
			46.365,
			46.395,
			46.472,
			46.519,
			46.581,
			46.643,
			46.69,
			46.767,
			46.797,
			46.828,
			46.892,
			46.909,
			46.954,
			47.016,
			47.078,
			47.124,
			47.138,
			47.2,
			47.233,
			47.264,
			47.324,
			47.342,
			47.386,
			47.448,
			47.512,
			47.572,
			47.633,
			47.666,
			47.698,
			47.76,
			47.821,
			47.881,
			47.944,
			47.993,
			48.008,
			48.07,
			48.099,
			48.13,
			48.21,
			48.256,
			48.316,
			48.38,
			48.426,
			48.442,
			48.504,
			48.534,
			48.564,
			48.628,
			48.644,
			48.689,
			48.752,
			48.815,
			48.861,
			48.938,
			48.975,
			48.998,
			49.061,
			49.123,
			49.187,
			49.248,
			49.294,
			49.309,
			49.371,
			49.403,
			49.434,
			49.495,
			49.512,
			49.557,
			49.62,
			49.68,
			49.728,
			49.742,
			49.804,
			49.836,
			49.866,
			49.945,
			49.992,
			50.053,
			50.116,
			50.162,
			50.238,
			50.269,
			50.3,
			50.364,
			50.424,
			50.488,
			50.55,
			50.595,
			50.611,
			50.674,
			50.704,
			50.736,
			50.796,
			50.813,
			50.861,
			50.921,
			50.981,
			51.044,
			51.108,
			51.138,
			51.17,
			51.231,
			51.247,
			51.295,
			51.355,
			51.416,
			51.464,
			51.541,
			51.572,
			51.604,
			51.68,
			51.728,
			51.788,
			51.852,
			51.914,
			51.976,
			52.007,
			52.038,
			52.099,
			52.114,
			52.162,
			52.225,
			52.286,
			52.331,
			52.347,
			52.411,
			52.442,
			52.472,
			52.533,
			52.548,
			52.595,
			52.656,
			52.72,
			52.764,
			52.843,
			52.874,
			52.907,
			52.968,
			52.983,
			53.03,
			53.091,
			53.154,
			53.2,
			53.216,
			53.276,
			53.308,
			53.338,
			53.403,
			53.415,
			53.464,
			53.527,
			53.587,
			53.633,
			53.712,
			53.742,
			53.775,
			53.837,
			53.898,
			53.96,
			54.021,
			54.07,
			54.084,
			54.145,
			54.177,
			54.208,
			54.271,
			54.286,
			54.333,
			54.395,
			54.457,
			54.517,
			54.581,
			54.611,
			54.642,
			54.705,
			54.718,
			54.765,
			54.829,
			54.889,
			54.937,
			55.014,
			55.045,
			55.075,
			55.138,
			55.159,
			55.201,
			55.264,
			55.323,
			55.385,
			55.447,
			55.478,
			55.511,
			55.586,
			55.634,
			55.697,
			55.758,
			55.803,
			55.819,
			55.883,
			55.914,
			55.944,
			56.007,
			56.021,
			56.067,
			56.131,
			56.191,
			56.238,
			56.317,
			56.347,
			56.378,
			56.439,
			56.5,
			56.563,
			56.627,
			56.688,
			56.75,
			56.78,
			56.813,
			56.889,
			56.938,
			57.003,
			57.06,
			57.106,
			57.122,
			57.185,
			57.222,
			57.247,
			57.325,
			57.369,
			57.432,
			57.493,
			57.54,
			57.617,
			57.65,
			57.681,
			57.741,
			57.805,
			57.866,
			57.927,
			57.991,
			58.054,
			58.083,
			58.114,
			58.177,
			58.191,
			58.239,
			58.302,
			58.364,
			58.408,
			58.424,
			58.487,
			58.518,
			58.548,
			58.61,
			58.671,
			58.736,
			58.798,
			58.844,
			58.858,
			58.92,
			58.95,
			58.983,
			59.045,
			59.06,
			59.107,
			59.169,
			59.231,
			59.291,
			59.356,
			59.387,
			59.416,
			59.48,
			59.494,
			59.541,
			59.603,
			59.666,
			59.712,
			59.789,
			59.821,
			59.851,
			59.914,
			59.975,
			60.038,
			60.097,
			60.147,
			60.161,
			60.222,
			60.253,
			60.283,
			60.348,
			60.364,
			60.409,
			60.471,
			60.533,
			60.581,
			60.595,
			60.658,
			60.688,
			60.72,
			60.782,
			60.795,
			60.842,
			60.907,
			60.967,
			61.013,
			61.091,
			61.122,
			61.153,
			61.214,
			61.277,
			61.343,
			61.4,
			61.462,
			61.524,
			61.555,
			61.587,
			61.648,
			61.665,
			61.71,
			61.772,
			61.835,
			61.896,
			61.959,
			61.989,
			62.02,
			62.081,
			62.098,
			62.144,
			62.207,
			62.27,
			62.33,
			62.394,
			62.425,
			62.454,
			62.517,
			62.58,
			62.642,
			62.704,
			62.749,
			62.766,
			62.828,
			62.858,
			62.889,
			62.951,
			62.966,
			63.012,
			63.075,
			63.137,
			63.2,
			63.26,
			63.292,
			63.324,
			63.405,
			63.447,
			63.509,
			63.571,
			63.618,
			63.694,
			63.725,
			63.758,
			63.819,
			63.835,
			63.882,
			63.945,
			64.005,
			64.066,
			64.128,
			64.159,
			64.192,
			64.254,
			64.269,
			64.314,
			64.377,
			64.439,
			64.487,
			64.564,
			64.595,
			64.626,
			64.688,
			64.749,
			64.812,
			64.872,
			64.92,
			64.935,
			64.996,
			65.027,
			65.058,
			65.12,
			65.136,
			65.183,
			65.244,
			65.308,
			65.354,
			65.367,
			65.43,
			65.465,
			65.493,
			65.556,
			65.571,
			65.617,
			65.678,
			65.742,
			65.788,
			65.864,
			65.895,
			65.928,
			65.989,
			66.05,
			66.114,
			66.176,
			66.238,
			66.298,
			66.329,
			66.361,
			66.44,
			66.483,
			66.547,
			66.607,
			66.656,
			66.671,
			66.731,
			66.763,
			66.794,
			66.871,
			66.92,
			66.983,
			67.041,
			67.089,
			67.167,
			67.198,
			67.229,
			67.307,
			67.354,
			67.415,
			67.475,
			67.529,
			67.54,
			67.602,
			67.633,
			67.662,
			67.725,
			67.739,
			67.786,
			67.85,
			67.911,
			67.959,
			68.036,
			68.064,
			68.097,
			68.175,
			68.22,
			68.282,
			68.344,
			68.392,
			68.469,
			68.501,
			68.53,
			68.592,
			68.656,
			68.718,
			68.779,
			68.827,
			68.842,
			68.904,
			68.934,
			68.966,
			69.028,
			69.041,
			69.088,
			69.15,
			69.212,
			69.261,
			69.337,
			69.369,
			69.4,
			69.477,
			69.522,
			69.589,
			69.648,
			69.693,
			69.771,
			69.803,
			69.834,
			69.896,
			69.912,
			69.956,
			70.021,
			70.08,
			70.128,
			70.142,
			70.204,
			70.237,
			70.266,
			70.33,
			70.345,
			70.391,
			70.454,
			70.516,
			70.561,
			70.638,
			70.671,
			70.703,
			70.764,
			70.825,
			70.889,
			70.948,
			70.995,
			71.01,
			71.075,
			71.105,
			71.136,
			71.198,
			71.214,
			71.26,
			71.322,
			71.384,
			71.445,
			71.507,
			71.54,
			71.569,
			71.631,
			71.653,
			71.692,
			71.756,
			71.818,
			71.881,
			71.942,
			71.972,
			72.005,
			72.064,
			72.128,
			72.189,
			72.25,
			72.298,
			72.314,
			72.374,
			72.406,
			72.438,
			72.5,
			72.515,
			72.562,
			72.623,
			72.685,
			72.73,
			72.748,
			72.808,
			72.841,
			72.872,
			72.95,
			72.996,
			73.056,
			73.12,
			73.181,
			73.243,
			73.273,
			73.305,
			73.383,
			73.43,
			73.492,
			73.553,
			73.598,
			73.614,
			73.678,
			73.713,
			73.74,
			73.8,
			73.816,
			73.863,
			73.927,
			73.987,
			74.048,
			74.112,
			74.142,
			74.173,
			74.236,
			74.299,
			74.361,
			74.422,
			74.485,
			74.547,
			74.577,
			74.607,
			74.669,
			74.73,
			74.795,
			74.856,
			74.9,
			74.917,
			74.979,
			75.011,
			75.041,
			75.103,
			75.119,
			75.166,
			75.228,
			75.289,
			75.336,
			75.414,
			75.445,
			75.475,
			75.554,
			75.6,
			75.661,
			75.722,
			75.775,
			75.785,
			75.848,
			75.879,
			75.91,
			75.972,
			75.988,
			76.034,
			76.097,
			76.157,
			76.22,
			76.28,
			76.311,
			76.344,
			76.405,
			76.422,
			76.466,
			76.528,
			76.59,
			76.639,
			76.714,
			76.746,
			76.777,
			76.84,
			76.902,
			76.964,
			77.025,
			77.071,
			77.087,
			77.149,
			77.18,
			77.212,
			77.273,
			77.29,
			77.336,
			77.397,
			77.459,
			77.506,
			77.522,
			77.584,
			77.615,
			77.645,
			77.709,
			77.769,
			77.831,
			77.837,
			77.895,
			77.956,
			78.019,
			78.049,
			78.08,
			78.156,
			78.203,
			78.264,
			78.326,
			78.374,
			78.39,
			78.45,
			78.481,
			78.515,
			78.575,
			78.592,
			78.636,
			78.699,
			78.762,
			78.784
		]
	],
	'5300_5301_7982': [
		[
			91.735
		],
		[
			0
		],
		[
			0,
			0.788,
			1.985,
			5.581,
			7.991,
			11.625,
			14.061,
			17.72,
			20.142,
			21.344,
			23.725,
			26.07,
			27.228,
			29.516,
			31.765,
			35.069,
			36.151,
			37.224,
			39.342,
			41.424,
			42.451,
			44.481,
			45.499,
			47.474,
			49.416,
			52.276,
			54.153,
			56.947,
			57.876,
			58.801,
			61.58,
			62.507,
			63.433,
			66.207,
			68.056,
			69.908,
			70.832,
			72.678,
			73.606,
			75.457,
			77.3,
			78.225,
			80.08,
			81.928,
			84.708,
			86.562,
			89.335,
			90.256,
			91.174,
			93.928,
			95.765,
			98.508,
			100.333,
			102.149,
			104.858,
			105.758,
			106.655,
			109.35,
			111.146,
			113.853,
			115.675,
			117.498,
			118.417,
			120.279,
			122.14,
			123.073,
			124.919,
			125.842,
			127.688,
			129.527,
			132.278,
			134.112,
			136.853,
			137.767,
			138.681,
			141.415,
			143.236,
			145.963,
			147.779,
			150.5,
			152.311,
			153.216,
			154.132,
			156.873,
			157.787,
			159.65,
			161.527,
			163.405,
			166.274,
			168.185,
			170.093,
			171.045,
			172.948,
			173.897,
			175.784,
			177.668,
			180.479,
			182.345,
			185.139,
			186.068,
			186.997,
			189.785,
			191.645,
			194.439,
			196.309,
			198.177,
			199.115,
			200.994,
			201.933,
			203.81,
			206.643,
			208.528,
			210.409,
			213.228,
			215.094,
			216.953,
			218.814,
			219.746,
			222.542,
			224.406,
			226.27,
			229.066,
			230.911,
			233.687,
			234.61,
			235.534,
			238.314,
			240.168,
			242.956,
			244.81,
			246.655,
			247.58,
			249.432,
			250.353,
			252.202,
			254.06,
			254.997,
			256.878,
			258.77,
			261.639,
			263.566,
			266.487,
			267.467,
			268.45,
			271.441,
			273.454,
			276.508,
			278.56,
			280.62,
			281.653,
			283.711,
			284.747,
			285.782,
			289.925,
			290.96,
			294.082,
			296.189,
			298.308,
			299.371,
			301.513,
			303.665,
			304.745,
			306.915,
			308.003,
			310.188,
			312.382,
			315.692,
			317.912,
			321.26,
			322.381,
			323.504,
			326.889,
			329.158,
			332.581,
			334.875,
			337.179,
			338.335,
			340.653,
			341.816,
			344.149,
			346.491,
			347.666,
			348.843,
			352.389,
			354.765,
			357.149,
			358.345,
			360.744,
			363.152,
			364.359,
			366.78,
			367.994,
			370.428,
			372.872,
			376.553,
			379.018,
			382.732,
			383.975,
			385.219,
			388.965,
			391.473,
			395.25,
			397.779,
			401.585,
			404.131,
			405.407,
			407.964,
			410.53,
			411.816,
			414.395,
			416.983,
			420.881,
			422.184,
			423.487,
			426.093,
			428.706,
			430.015,
			432.638,
			433.953,
			436.587,
			439.229,
			443.205,
			445.864,
			449.866,
			451.204,
			452.543,
			456.571,
			459.265,
			463.32,
			466.033,
			468.752,
			470.114,
			472.845,
			475.582,
			476.953,
			481.077,
			483.835,
			486.6,
			490.761,
			493.542,
			497.726,
			499.124,
			500.524,
			504.733,
			507.547,
			510.368,
			514.614,
			517.452,
			521.723,
			523.15,
			524.579,
			528.876,
			530.311,
			531.748,
			536.07,
			538.959,
			541.855,
			543.305,
			546.21,
			549.122,
			550.58,
			554.964,
			557.895,
			560.832,
			565.248,
			568.2,
			572.639,
			574.122,
			575.607,
			580.068,
			583.05,
			587.537,
			590.535,
			595.043,
			598.056,
			599.564,
			601.074,
			605.613,
			607.129,
			608.646,
			613.205,
			616.248,
			619.294,
			620.818,
			623.871,
			626.929,
			628.461,
			631.53,
			633.068,
			636.152,
			639.244,
			643.896,
			647.004,
			651.672,
			653.231,
			654.789,
			659.474,
			662.603,
			667.306,
			670.448,
			673.594,
			675.17,
			678.324,
			679.903,
			683.065,
			686.232,
			687.817,
			690.992,
			694.172,
			698.948,
			700.541,
			702.135,
			706.924,
			708.523,
			710.123,
			713.325,
			714.927,
			718.135,
			721.346,
			722.953,
			726.171,
			729.393,
			734.234,
			735.849,
			737.466,
			742.322,
			745.564,
			750.435,
			753.688,
			758.575,
			761.839,
			763.472,
			766.742,
			770.016,
			771.655,
			774.935,
			778.218,
			783.151,
			786.445,
			791.395,
			793.047,
			794.7,
			799.664,
			802.979,
			807.959,
			811.285,
			814.614,
			819.615,
			821.284,
			822.954,
			827.969,
			831.317,
			836.348,
			839.706,
			843.068,
			844.75,
			848.117,
			851.489,
			853.176,
			856.554,
			858.244,
			861.627,
			865.013,
			870.1,
			871.797,
			873.495,
			878.596,
			880.297,
			882,
			887.114,
			890.528,
			895.66,
			899.087,
			902.517,
			907.67,
			909.389,
			911.109,
			917.994,
			919.718,
			924.895,
			928.352,
			933.548,
			937.02,
			940.498,
			942.238,
			945.72,
			947.462,
			950.947,
			954.433,
			959.668,
			963.162,
			968.409,
			970.16,
			971.912,
			977.173,
			980.685,
			985.959,
			989.479,
			993.003,
			994.766,
			998.296,
			1001.832,
			1003.602,
			1007.143,
			1008.913,
			1010.685,
			1015.999,
			1019.545,
			1023.095,
			1024.871,
			1028.426,
			1031.983,
			1033.764,
			1037.327,
			1039.11,
			1042.679,
			1046.251,
			1051.617,
			1055.199,
			1060.582,
			1062.379,
			1064.177,
			1069.574,
			1073.172,
			1078.568,
			1082.167,
			1087.572,
			1091.18,
			1092.985,
			1096.599,
			1102.025,
			1105.646,
			1109.269,
			1114.71,
			1118.34,
			1123.78,
			1125.587,
			1127.39,
			1132.753,
			1136.284,
			1139.776,
			1144.939,
			1148.333,
			1153.347,
			1154.999,
			1156.642,
			1161.524,
			1164.731,
			1169.472,
			1172.588,
			1177.188,
			1180.211,
			1181.71,
			1184.681,
			1187.615,
			1189.068,
			1191.945,
			1194.783,
			1198.967,
			1200.343,
			1201.709,
			1205.759,
			1207.091,
			1208.413,
			1212.318,
			1214.878,
			1218.654,
			1221.126,
			1224.763,
			1227.145,
			1228.322,
			1230.652,
			1232.946,
			1234.078,
			1235.202,
			1238.522,
			1240.69,
			1242.82,
			1243.872,
			1245.948,
			1247.988,
			1248.994,
			1251.959,
			1253.89,
			1255.784,
			1258.571,
			1260.378,
			1263.028,
			1263.895,
			1264.755,
			1267.289,
			1268.945,
			1271.399,
			1273.009,
			1274.599,
			1275.399,
			1276.986,
			1277.774,
			1279.345,
			1280.923,
			1281.711,
			1283.285,
			1284.863,
			1286.471,
			1288.882,
			1290.499,
			1292.12,
			1292.93,
			1294.551,
			1295.366,
			1296.994,
			1298.619,
			1301.063,
			1302.694,
			1305.14,
			1305.957,
			1306.773,
			1309.221,
			1310.854,
			1313.304,
			1314.941,
			1316.58,
			1317.399,
			1319.041,
			1319.863,
			1321.492,
			1323.122,
			1323.938,
			1325.569,
			1327.183,
			1329.615,
			1331.237,
			1333.674,
			1334.49,
			1335.309,
			1337.768,
			1339.42,
			1341.07,
			1343.586,
			1345.28,
			1347.847,
			1348.709,
			1349.574,
			1352.193,
			1353.073,
			1353.956,
			1356.627,
			1358.425,
			1360.237,
			1361.148,
			1362.98,
			1364.825,
			1365.753,
			1367.618,
			1368.555,
			1370.439,
			1372.337,
			1375.207,
			1377.136,
			1380.053,
			1381.033,
			1382.017,
			1384.987,
			1386.988,
			1390.015,
			1392.047,
			1394.088,
			1397.165,
			1398.193,
			1399.222,
			1402.322,
			1403.356,
			1404.394,
			1407.516,
			1409.597,
			1411.684,
			1412.735,
			1414.844,
			1416.964,
			1418.031,
			1420.171,
			1421.245,
			1423.401,
			1425.567,
			1428.834,
			1431.026,
			1434.333,
			1435.44,
			1436.55,
			1439.896,
			1442.139,
			1445.524,
			1447.794,
			1450.073,
			1451.217,
			1453.511,
			1454.661,
			1456.966,
			1459.278,
			1460.435,
			1462.744,
			1465.043,
			1468.446,
			1469.563,
			1470.673,
			1472.863,
			1475.009,
			1476.069,
			1478.171,
			1479.212,
			1481.266,
			1483.301,
			1486.291,
			1488.237,
			1491.089,
			1492.037,
			1492.956,
			1495.678,
			1497.472,
			1500.136,
			1501.899,
			1503.657,
			1504.535,
			1506.29,
			1507.168,
			1508.913,
			1510.658,
			1511.531,
			1513.282,
			1515.033,
			1517.666,
			1518.55,
			1519.434,
			1522.085,
			1522.986,
			1523.889,
			1526.598,
			1528.433,
			1530.287,
			1533.077,
			1534.962,
			1537.786,
			1538.729,
			1539.67,
			1542.493,
			1544.379,
			1547.201,
			1549.072,
			1550.936,
			1551.867,
			1553.724,
			1554.649,
			1556.498,
			1559.267,
			1561.109,
			1562.951,
			1565.704,
			1567.538,
			1570.276,
			1571.188,
			1572.099,
			1574.818,
			1576.625,
			1579.321,
			1581.11,
			1582.896,
			1583.782,
			1585.55,
			1586.433,
			1587.316,
			1589.946,
			1590.82,
			1591.693,
			1594.312,
			1596.054,
			1597.795,
			1598.666,
			1600.407,
			1602.147,
			1603.017,
			1604.764,
			1605.637,
			1607.385,
			1609.138,
			1611.774,
			1613.536,
			1616.169,
			1617.056,
			1617.946,
			1620.631,
			1622.436,
			1625.173,
			1627.016,
			1628.873,
			1629.808,
			1631.688,
			1632.632,
			1634.532,
			1636.446,
			1637.407,
			1639.34,
			1641.285,
			1643.243,
			1646.194,
			1648.177,
			1650.166,
			1651.164,
			1653.168,
			1654.176,
			1656.2,
			1658.235,
			1661.316,
			1663.384,
			1666.512,
			1667.559,
			1668.606,
			1671.749,
			1673.852,
			1677.028,
			1679.158,
			1681.299,
			1682.373,
			1684.528,
			1685.61,
			1687.781,
			1689.962,
			1691.057,
			1693.255,
			1695.463,
			1698.795,
			1701.029,
			1704.397,
			1705.524,
			1706.653,
			1710.055,
			1712.334,
			1715.771,
			1718.074,
			1720.386,
			1723.874,
			1726.211,
			1729.736,
			1732.097,
			1735.657,
			1738.042,
			1740.436,
			1741.636,
			1744.044,
			1745.251,
			1747.673,
			1750.103,
			1751.321,
			1753.765,
			1756.218,
			1759.914,
			1762.388,
			1766.117,
			1767.365,
			1768.614,
			1772.376,
			1774.896,
			1778.693,
			1781.235,
			1783.785,
			1785.063,
			1787.624,
			1788.907,
			1791.48,
			1794.06,
			1795.354,
			1796.649,
			1800.546,
			1803.153,
			1805.759,
			1807.064,
			1809.678,
			1812.299,
			1813.612,
			1816.245,
			1817.563,
			1820.207,
			1822.857,
			1826.846,
			1829.515,
			1833.532,
			1834.874,
			1836.218,
			1840.261,
			1842.965,
			1847.034,
			1849.758,
			1853.859,
			1856.604,
			1857.979,
			1860.734,
			1863.495,
			1864.878,
			1866.263,
			1870.423,
			1873.204,
			1875.99,
			1877.386,
			1880.181,
			1882.983,
			1884.384,
			1887.192,
			1888.596,
			1891.406,
			1894.224,
			1898.464,
			1901.303,
			1905.579,
			1907.009,
			1908.441,
			1912.75,
			1915.631,
			1919.965,
			1922.861,
			1927.218,
			1930.13,
			1931.587,
			1934.496,
			1937.389,
			1938.826,
			1941.677,
			1944.492,
			1948.647,
			1951.368,
			1954.049,
			1956.689,
			1957.996,
			1961.856,
			1964.381,
			1968.098,
			1970.528,
			1972.922,
			1976.445,
			1977.602,
			1978.75,
			1982.136,
			1983.245,
			1984.345,
			1987.59,
			1989.707,
			1991.788,
			1992.814,
			1994.838,
			1996.825,
			1997.805,
			1999.74,
			2000.695,
			2002.597,
			2004.457,
			2007.191,
			2008.996,
			2011.665,
			2012.551,
			2013.438,
			2016.124,
			2017.929,
			2020.667,
			2022.515,
			2024.374,
			2025.311,
			2027.198,
			2028.144,
			2029.092,
			2031.977,
			2032.944,
			2033.911,
			2036.836,
			2038.788,
			2040.739,
			2041.714,
			2043.654,
			2045.581,
			2046.536,
			2048.436,
			2049.383,
			2051.268,
			2053.143,
			2055.951,
			2057.823,
			2060.632,
			2061.574,
			2062.515,
			2065.333,
			2067.219,
			2070.036,
			2071.908,
			2074.701,
			2076.554,
			2077.476,
			2079.312,
			2081.14,
			2082.05,
			2082.957,
			2085.666,
			2088.362,
			2090.146,
			2091.928,
			2093.703,
			2094.587,
			2097.234,
			2098.99,
			2100.741,
			2103.363,
			2105.104,
			2107.722,
			2108.596,
			2109.468,
			2112.09,
			2113.845,
			2116.476,
			2118.238,
			2120.878,
			2122.644,
			2123.527,
			2125.295,
			2127.065,
			2127.951,
			2129.722,
			2131.496,
			2134.156,
			2135.044,
			2135.934,
			2138.611,
			2139.506,
			2140.402,
			2142.202,
			2143.111,
			2144.931,
			2146.757,
			2149.549,
			2151.417,
			2154.257,
			2155.208,
			2156.16,
			2159.049,
			2160.987,
			2163.915,
			2165.875,
			2167.836,
			2168.815,
			2170.771,
			2172.724,
			2173.682,
			2175.595,
			2176.55,
			2178.442,
			2180.303,
			2183.084,
			2184.916,
			2187.655,
			2188.567,
			2189.479,
			2192.204,
			2194.016,
			2196.722,
			2198.522,
			2200.313,
			2202.996,
			2203.889,
			2204.779,
			2207.45,
			2208.341,
			2209.227,
			2211.881,
			2213.653,
			2216.293,
			2218.057,
			2219.823,
			2220.706,
			2222.476,
			2223.362,
			2225.16,
			2226.972,
			2229.72,
			2231.591,
			2234.416,
			2235.362,
			2236.31,
			2239.175,
			2241.089,
			2243.968,
			2245.917,
			2247.88,
			2248.865,
			2250.851,
			2251.85,
			2252.851,
			2255.875,
			2256.888,
			2257.905,
			2260.973,
			2263.032,
			2265.101,
			2266.135,
			2268.213,
			2270.3,
			2271.345,
			2273.433,
			2274.476,
			2276.574,
			2278.683,
			2281.871,
			2284.011,
			2287.244,
			2288.327,
			2289.413,
			2292.685,
			2294.88,
			2298.189,
			2300.408,
			2302.637,
			2303.755,
			2305.998,
			2307.122,
			2309.378,
			2311.645,
			2312.781,
			2315.062,
			2317.353,
			2320.808,
			2321.965,
			2323.124,
			2326.617,
			2327.788,
			2328.96,
			2331.312,
			2332.491,
			2334.857,
			2337.228,
			2340.797,
			2343.186,
			2346.786,
			2347.993,
			2349.202,
			2352.843,
			2354.061,
			2355.282,
			2358.959,
			2361.423,
			2363.894,
			2365.133,
			2367.617,
			2368.863,
			2371.36,
			2373.864,
			2375.12,
			2377.637,
			2380.163,
			2383.968,
			2386.516,
			2390.354,
			2391.637,
			2392.923,
			2396.791,
			2399.38,
			2403.28,
			2405.886,
			2408.493,
			2409.8,
			2412.419,
			2413.732,
			2415.046,
			2419.001,
			2420.323,
			2421.646,
			2425.626,
			2428.288,
			2430.957,
			2432.294,
			2434.973,
			2437.654,
			2438.991,
			2441.652,
			2442.974,
			2445.593,
			2448.176,
			2451.979,
			2454.468,
			2458.13,
			2459.333,
			2460.527,
			2464.058,
			2466.371,
			2469.774,
			2472.017,
			2474.205,
			2475.286,
			2477.42,
			2478.477,
			2480.573,
			2482.653,
			2483.691,
			2485.762,
			2487.838,
			2489.918,
			2493.043,
			2495.132,
			2497.22,
			2498.263,
			2500.359,
			2501.409,
			2503.503,
			2505.597,
			2508.721,
			2510.786,
			2513.837,
			2514.844,
			2515.849,
			2518.833,
			2520.823,
			2523.804,
			2525.808,
			2527.828,
			2528.838,
			2530.882,
			2531.916,
			2533.989,
			2536.075,
			2537.122,
			2539.219,
			2541.316,
			2544.455,
			2546.533,
			2548.597,
			2550.642,
			2551.64,
			2554.611,
			2556.549,
			2558.457,
			2561.297,
			2563.174,
			2565.979,
			2566.917,
			2567.854,
			2570.66,
			2572.545,
			2575.367,
			2577.253,
			2579.138,
			2580.079,
			2581.959,
			2583.833,
			2584.768,
			2586.637,
			2587.573,
			2589.437,
			2591.299,
			2594.096,
			2595.954,
			2598.738,
			2599.661,
			2600.581,
			2602.428,
			2603.351,
			2605.19,
			2607.964,
			2609.819,
			2611.669,
			2614.452,
			2615.377,
			2616.3,
			2619.052,
			2620.877,
			2623.588,
			2625.383,
			2627.177,
			2628.075,
			2629.868,
			2631.661,
			2632.565,
			2635.293,
			2637.113,
			2638.972,
			2641.763,
			2643.646,
			2646.481,
			2647.428,
			2648.377,
			2651.219,
			2653.111,
			2655.92,
			2657.794,
			2660.554,
			2662.395,
			2663.312,
			2665.131,
			2666.954,
			2667.866,
			2668.784,
			2671.54,
			2673.385,
			2675.24,
			2676.169,
			2678.033,
			2679.9,
			2680.835,
			2682.71,
			2683.651,
			2685.528,
			2687.413,
			2690.246,
			2692.138,
			2694.998,
			2695.955,
			2696.916,
			2699.818,
			2700.793,
			2701.77,
			2704.72,
			2706.703,
			2708.697,
			2709.702,
			2711.722,
			2712.736,
			2714.775,
			2716.832,
			2717.865,
			2719.938,
			2722.004,
			2725.149,
			2726.195,
			2727.226,
			2729.331,
			2731.445,
			2732.506,
			2735.701,
			2737.839,
			2739.987,
			2743.225,
			2745.398,
			2748.683,
			2749.783,
			2750.887,
			2754.212,
			2756.442,
			2759.805,
			2762.06,
			2764.325,
			2765.461,
			2767.741,
			2770.031,
			2771.179,
			2773.481,
			2774.636,
			2776.953,
			2779.279,
			2782.786,
			2785.135,
			2788.676,
			2791.049,
			2794.625,
			2797.02,
			2800.632,
			2803.052,
			2805.48,
			2809.141,
			2810.366,
			2811.593,
			2815.286,
			2816.522,
			2817.759,
			2821.486,
			2823.981,
			2826.485,
			2827.741,
			2830.257,
			2832.782,
			2834.048,
			2837.857,
			2840.407,
			2842.965,
			2846.817,
			2849.395,
			2853.276,
			2854.574,
			2855.875,
			2859.786,
			2862.394,
			2866.316,
			2868.94,
			2871.571,
			2872.889,
			2875.531,
			2876.855,
			2878.181,
			2882.171,
			2883.504,
			2886.177,
			2888.857,
			2891.542,
			2895.582,
			2898.284,
			2900.994,
			2902.352,
			2905.072,
			2906.435,
			2909.165,
			2911.902,
			2916.021,
			2918.777,
			2922.922,
			2924.308,
			2925.694,
			2929.865,
			2932.655,
			2936.852,
			2939.659,
			2942.472,
			2943.882,
			2946.706,
			2948.121,
			2950.955,
			2953.796,
			2955.219,
			2958.07,
			2960.927,
			2965.226,
			2966.663,
			2968.101,
			2970.981,
			2973.868,
			2975.314,
			2978.211,
			2979.662,
			2982.568,
			2985.482,
			2989.864,
			2992.794,
			2997.2,
			2998.672,
			3000.146,
			3004.576,
			3007.537,
			3011.99,
			3014.965,
			3017.947,
			3019.441,
			3022.432,
			3025.43,
			3026.931,
			3031.444,
			3034.459,
			3037.479,
			3042.02,
			3043.535,
			3045.05,
			3049.58,
			3051.081,
			3052.576,
			3057.01,
			3059.92,
			3064.212,
			3067.026,
			3069.801,
			3073.89,
			3075.234,
			3076.569,
			3081.815,
			3083.102,
			3086.906,
			3089.396,
			3091.848,
			3093.061,
			3095.458,
			3097.821,
			3098.989,
			3102.442,
			3104.695,
			3106.911,
			3110.165,
			3112.288,
			3115.401,
			3116.42,
			3117.43,
			3120.404,
			3122.34,
			3125.176,
			3127.022,
			3128.835,
			3129.729,
			3131.492,
			3132.36,
			3133.22,
			3135.756,
			3136.579,
			3138.198,
			3139.783,
			3141.326,
			3142.835,
			3143.579,
			3145.049,
			3146.493,
			3147.208,
			3148.631,
			3149.341,
			3150.76,
			3152.189,
			3154.334,
			3155.769,
			3157.938,
			3158.661,
			3159.384,
			3161.563,
			3163.021,
			3165.207,
			3166.683,
			3168.169,
			3168.912,
			3170.396,
			3171.151,
			3172.703,
			3174.252,
			3175.026,
			3176.597,
			3178.201,
			3180.601,
			3181.397,
			3182.184,
			3184.541,
			3185.325,
			3186.111,
			3188.428,
			3189.971,
			3191.515,
			3193.812,
			3195.343,
			3197.628,
			3198.384,
			3199.139,
			3201.404,
			3202.918,
			3205.184,
			3206.694,
			3208.208,
			3208.963,
			3210.472,
			3211.225,
			3212.721,
			3214.206,
			3214.947,
			3216.427,
			3217.892,
			3220.075,
			3221.528,
			3223.694,
			3224.414,
			3225.134,
			3227.296,
			3228.742,
			3230.188,
			3232.37,
			3233.859,
			3236.114,
			3236.872,
			3237.638,
			3239.965,
			3240.748,
			3241.534,
			3243.899,
			3245.489,
			3247.093,
			3247.899,
			3249.522,
			3250.338,
			3251.982,
			3254.453,
			3256.114,
			3257.791,
			3260.342,
			3262.061,
			3264.676,
			3265.562,
			3266.451,
			3269.12,
			3270.905,
			3273.605,
			3275.42,
			3278.169,
			3280.02,
			3280.953,
			3281.889,
			3284.715,
			3285.665,
			3286.617,
			3289.499,
			3291.436,
			3293.387,
			3294.368,
			3296.337,
			3298.316,
			3299.31,
			3301.307,
			3302.312,
			3304.331,
			3306.362,
			3309.433,
			3311.494,
			3314.609,
			3315.653,
			3316.699,
			3319.847,
			3321.944,
			3325.1,
			3327.212,
			3329.328,
			3330.388,
			3332.528,
			3333.59,
			3335.713,
			3338.907,
			3341.036,
			3343.163,
			3346.342,
			3347.399,
			3348.454,
			3350.558,
			3352.649,
			3353.688,
			3356.794,
			3358.851,
			3361.923,
			3363.97,
			3366.01,
			3369.073,
			3370.093,
			3371.112,
			3374.166,
			3376.195,
			3379.23,
			3381.245,
			3383.261,
			3384.269,
			3386.281,
			3387.288,
			3389.3,
			3391.31,
			3392.314,
			3394.319,
			3396.321,
			3399.318,
			3400.315,
			3401.312,
			3404.306,
			3405.303,
			3406.3,
			3408.3,
			3409.301,
			3411.3,
			3413.293,
			3416.272,
			3418.247,
			3421.198,
			3422.184,
			3423.183,
			3426.18,
			3428.193,
			3431.226,
			3433.241,
			3435.246,
			3436.248,
			3438.24,
			3440.218,
			3441.206,
			3443.182,
			3444.169,
			3446.145,
			3448.127,
			3451.113,
			3453.113,
			3456.127,
			3457.135,
			3458.147,
			3461.192,
			3463.232,
			3466.305,
			3468.367,
			3470.44,
			3471.478,
			3473.567,
			3474.617,
			3476.718,
			3478.82,
			3479.871,
			3480.922,
			3484.07,
			3486.164,
			3488.258,
			3489.306,
			3491.397,
			3494.527,
			3496.605,
			3497.642,
			3499.723,
			3501.797,
			3504.928,
			3507.017,
			3510.137,
			3511.177,
			3512.216,
			3515.345,
			3517.437,
			3520.604,
			3522.726,
			3524.886,
			3525.969,
			3528.142,
			3529.238,
			3531.43,
			3533.609,
			3534.685,
			3536.835,
			3538.97,
			3542.144,
			3543.201,
			3544.256,
			3546.361,
			3548.464,
			3549.518,
			3552.667,
			3554.761,
			3556.847,
			3559.96,
			3562.024,
			3565.114,
			3566.142,
			3567.168,
			3570.239,
			3572.278,
			3575.321,
			3577.339,
			3579.353,
			3580.357,
			3582.359,
			3583.359,
			3585.365,
			3587.364,
			3588.361,
			3590.355,
			3592.343,
			3595.309,
			3597.278,
			3600.225,
			3601.205,
			3602.184,
			3605.123,
			3607.082,
			3610.027,
			3611.992,
			3614.951,
			3616.928,
			3617.918,
			3618.91,
			3621.896,
			3622.893,
			3623.896,
			3626.918,
			3628.945,
			3630.991,
			3632.016,
			3634.072,
			3636.137,
			3637.172,
			3639.25,
			3640.294,
			3642.388,
			3644.498,
			3647.694,
			3649.843,
			3653.094,
			3654.188,
			3655.288,
			3658.604,
			3660.834,
			3664.194,
			3666.43,
			3668.677,
			3669.788,
			3672.014,
			3673.13,
			3674.239,
			3677.526,
			3678.62,
			3680.792,
			3682.952,
			3685.098,
			3687.226,
			3688.282,
			3690.374,
			3692.435,
			3693.455,
			3695.471,
			3696.469,
			3698.447,
			3700.406,
			3703.322,
			3705.261,
			3708.172,
			3709.145,
			3710.123,
			3713.061,
			3715.024,
			3717.965,
			3719.922,
			3721.875,
			3722.847,
			3724.783,
			3725.747,
			3727.657,
			3729.541,
			3730.471,
			3732.309,
			3734.099,
			3735.868,
			3737.601,
			3738.455,
			3740.13,
			3741.765,
			3742.569,
			3744.923,
			3746.443,
			3747.927,
			3750.083,
			3751.476,
			3753.5,
			3754.158,
			3754.812,
			3756.726,
			3757.969,
			3759.755,
			3760.913,
			3762.036,
			3762.585,
			3763.664,
			3764.193,
			3765.238,
			3766.757,
			3767.745,
			3768.719,
			3770.17,
			3771.128,
			3772.552,
			3773.026,
			3773.499,
			3774.947,
			3775.94,
			3776.93,
			3778.415,
			3779.41,
			3780.978,
			3781.499,
			3782.021,
			3783.592,
			3784.653,
			3786.268,
			3787.346,
			3788.429,
			3788.973,
			3790.086,
			3791.212,
			3791.774,
			3792.902,
			3793.467,
			3794.607,
			3795.778,
			3797.536,
			3798.712,
			3800.477,
			3801.062,
			3801.647,
			3803.401,
			3804.575,
			3806.328,
			3807.497,
			3808.668,
			3809.255,
			3810.416,
			3810.993,
			3812.148,
			3813.309,
			3813.893,
			3815.057,
			3816.215,
			3817.379,
			3818.551,
			3819.141,
			3820.327,
			3821.522,
			3822.124,
			3823.334,
			3823.944,
			3825.167,
			3826.396,
			3828.262,
			3829.514,
			3831.457,
			3832.112,
			3832.772,
			3834.78,
			3836.168,
			3838.281,
			3839.71,
			3841.891,
			3843.366,
			3844.109,
			3845.61,
			3847.893,
			3849.435,
			3850.983,
			3853.332,
			3854.917,
			3857.312,
			3858.119,
			3858.929,
			3860.56,
			3861.38,
			3863.033,
			3864.699,
			3867.222,
			3868.909,
			3871.445,
			3872.297,
			3873.152,
			3875.737,
			3877.476,
			3880.109,
			3881.88,
			3883.665,
			3884.561,
			3886.365,
			3887.271,
			3889.095,
			3890.93,
			3891.853,
			3893.708,
			3895.576,
			3898.401,
			3899.35,
			3900.301,
			3903.173,
			3904.137,
			3905.104,
			3907.047,
			3908.024,
			3909.986,
			3912.952,
			3914.944,
			3916.948,
			3919.977,
			3920.993,
			3922.011,
			3925.083,
			3926.112,
			3927.144,
			3930.257,
			3932.345,
			3935.486,
			3937.58,
			3939.682,
			3940.737,
			3942.853,
			3943.915,
			3946.047,
			3948.189,
			3951.422,
			3953.589,
			3956.857,
			3957.951,
			3959.046,
			3962.349,
			3964.562,
			3967.901,
			3970.139,
			3972.389,
			3973.517,
			3975.781,
			3976.919,
			3978.06,
			3981.496,
			3982.645,
			3983.797,
			3987.266,
			3989.59,
			3991.925,
			3993.095,
			3995.443,
			3997.801,
			3998.983,
			4001.355,
			4002.545,
			4003.442
		],
		[
			0,
			0.013,
			0.044,
			0.104,
			0.166,
			0.23,
			0.29,
			0.352,
			0.414,
			0.446,
			0.476,
			0.538,
			0.554,
			0.602,
			0.663,
			0.725,
			0.772,
			0.788,
			0.849,
			0.881,
			0.911,
			0.972,
			0.993,
			1.036,
			1.096,
			1.16,
			1.207,
			1.282,
			1.313,
			1.345,
			1.407,
			1.424,
			1.469,
			1.532,
			1.592,
			1.64,
			1.654,
			1.718,
			1.749,
			1.779,
			1.841,
			1.856,
			1.904,
			1.964,
			2.026,
			2.075,
			2.152,
			2.181,
			2.214,
			2.277,
			2.336,
			2.401,
			2.461,
			2.507,
			2.586,
			2.616,
			2.647,
			2.724,
			2.771,
			2.832,
			2.897,
			2.941,
			2.957,
			3.02,
			3.057,
			3.082,
			3.144,
			3.16,
			3.207,
			3.266,
			3.328,
			3.392,
			3.454,
			3.485,
			3.515,
			3.578,
			3.64,
			3.703,
			3.762,
			3.827,
			3.888,
			3.919,
			3.949,
			4.013,
			4.027,
			4.075,
			4.136,
			4.197,
			4.26,
			4.321,
			4.353,
			4.382,
			4.447,
			4.46,
			4.507,
			4.571,
			4.631,
			4.694,
			4.755,
			4.787,
			4.818,
			4.88,
			4.943,
			5.005,
			5.067,
			5.115,
			5.129,
			5.191,
			5.222,
			5.253,
			5.328,
			5.375,
			5.438,
			5.5,
			5.561,
			5.624,
			5.655,
			5.687,
			5.763,
			5.81,
			5.871,
			5.935,
			5.997,
			6.058,
			6.088,
			6.121,
			6.197,
			6.244,
			6.305,
			6.369,
			6.413,
			6.429,
			6.493,
			6.524,
			6.554,
			6.617,
			6.632,
			6.679,
			6.741,
			6.803,
			6.849,
			6.926,
			6.956,
			6.987,
			7.049,
			7.113,
			7.18,
			7.236,
			7.281,
			7.299,
			7.36,
			7.39,
			7.421,
			7.501,
			7.547,
			7.609,
			7.671,
			7.718,
			7.731,
			7.794,
			7.826,
			7.857,
			7.919,
			7.935,
			7.981,
			8.044,
			8.106,
			8.165,
			8.229,
			8.261,
			8.291,
			8.353,
			8.413,
			8.477,
			8.539,
			8.586,
			8.601,
			8.662,
			8.694,
			8.724,
			8.785,
			8.802,
			8.847,
			8.91,
			8.973,
			9.02,
			9.035,
			9.098,
			9.128,
			9.158,
			9.22,
			9.24,
			9.281,
			9.343,
			9.407,
			9.453,
			9.529,
			9.562,
			9.593,
			9.669,
			9.717,
			9.779,
			9.84,
			9.904,
			9.966,
			9.996,
			10.026,
			10.088,
			10.105,
			10.152,
			10.212,
			10.275,
			10.32,
			10.336,
			10.399,
			10.43,
			10.461,
			10.524,
			10.537,
			10.586,
			10.647,
			10.71,
			10.771,
			10.832,
			10.865,
			10.894,
			10.958,
			11.019,
			11.083,
			11.143,
			11.191,
			11.205,
			11.266,
			11.302,
			11.328,
			11.407,
			11.454,
			11.521,
			11.576,
			11.624,
			11.7,
			11.731,
			11.764,
			11.825,
			11.886,
			11.948,
			12.011,
			12.056,
			12.136,
			12.165,
			12.195,
			12.26,
			12.274,
			12.322,
			12.382,
			12.444,
			12.491,
			12.508,
			12.569,
			12.601,
			12.63,
			12.709,
			12.756,
			12.815,
			12.879,
			12.927,
			13.003,
			13.036,
			13.065,
			13.127,
			13.19,
			13.252,
			13.314,
			13.376,
			13.438,
			13.47,
			13.498,
			13.561,
			13.578,
			13.623,
			13.687,
			13.746,
			13.794,
			13.811,
			13.871,
			13.903,
			13.932,
			13.996,
			14.012,
			14.058,
			14.121,
			14.181,
			14.244,
			14.307,
			14.338,
			14.366,
			14.445,
			14.493,
			14.559,
			14.616,
			14.661,
			14.678,
			14.738,
			14.771,
			14.802,
			14.864,
			14.88,
			14.924,
			14.993,
			15.049,
			15.095,
			15.112,
			15.174,
			15.205,
			15.237,
			15.299,
			15.312,
			15.36,
			15.422,
			15.425,
			15.485,
			15.529,
			15.607,
			15.637,
			15.668,
			15.748,
			15.793,
			15.855,
			15.918,
			15.979,
			16.043,
			16.074,
			16.104,
			16.167,
			16.182,
			16.229,
			16.29,
			16.353,
			16.412,
			16.476,
			16.508,
			16.538,
			16.616,
			16.661,
			16.726,
			16.787,
			16.832,
			16.91,
			16.941,
			16.972,
			17.049,
			17.095,
			17.159,
			17.22,
			17.266,
			17.282,
			17.343,
			17.376,
			17.406,
			17.469,
			17.487,
			17.529,
			17.593,
			17.654,
			17.699,
			17.717,
			17.777,
			17.809,
			17.84,
			17.902,
			17.963,
			18.027,
			18.087,
			18.135,
			18.213,
			18.243,
			18.273,
			18.353,
			18.398,
			18.46,
			18.521,
			18.586,
			18.646,
			18.676,
			18.707,
			18.771,
			18.786,
			18.832,
			18.893,
			18.957,
			19.004,
			19.079,
			19.11,
			19.144,
			19.205,
			19.268,
			19.327,
			19.391,
			19.438,
			19.452,
			19.515,
			19.551,
			19.577,
			19.639,
			19.655,
			19.699,
			19.763,
			19.824,
			19.872,
			19.886,
			19.949,
			19.98,
			20.01,
			20.073,
			20.087,
			20.133,
			20.197,
			20.259,
			20.304,
			20.382,
			20.415,
			20.445,
			20.506,
			20.569,
			20.631,
			20.693,
			20.754,
			20.817,
			20.847,
			20.879,
			20.957,
			21.004,
			21.064,
			21.125,
			21.174,
			21.249,
			21.282,
			21.312,
			21.376,
			21.437,
			21.499,
			21.561,
			21.609,
			21.686,
			21.716,
			21.745,
			21.807,
			21.87,
			21.934,
			21.993,
			22.058,
			22.12,
			22.149,
			22.18,
			22.243,
			22.258,
			22.304,
			22.366,
			22.43,
			22.474,
			22.49,
			22.553,
			22.583,
			22.615,
			22.677,
			22.738,
			22.802,
			22.863,
			22.925,
			22.988,
			23.016,
			23.05,
			23.11,
			23.127,
			23.174,
			23.236,
			23.296,
			23.342,
			23.359,
			23.422,
			23.453,
			23.482,
			23.56,
			23.607,
			23.673,
			23.732,
			23.792,
			23.856,
			23.887,
			23.916,
			23.995,
			24.041,
			24.104,
			24.163,
			24.212,
			24.227,
			24.289,
			24.319,
			24.352,
			24.413,
			24.429,
			24.476,
			24.537,
			24.598,
			24.661,
			24.723,
			24.754,
			24.786,
			24.846,
			24.863,
			24.91,
			24.971,
			25.033,
			25.081,
			25.157,
			25.187,
			25.22,
			25.282,
			25.343,
			25.407,
			25.466,
			25.516,
			25.53,
			25.591,
			25.621,
			25.655,
			25.714,
			25.736,
			25.778,
			25.839,
			25.902,
			25.964,
			26.024,
			26.058,
			26.086,
			26.15,
			26.21,
			26.272,
			26.336,
			26.383,
			26.46,
			26.491,
			26.521,
			26.584,
			26.599,
			26.646,
			26.709,
			26.771,
			26.816,
			26.832,
			26.893,
			26.926,
			26.956,
			27.016,
			27.032,
			27.079,
			27.141,
			27.202,
			27.251,
			27.329,
			27.36,
			27.39,
			27.468,
			27.512,
			27.575,
			27.637,
			27.685,
			27.763,
			27.798,
			27.823,
			27.886,
			27.902,
			27.947,
			28.011,
			28.071,
			28.12,
			28.135,
			28.197,
			28.227,
			28.259,
			28.321,
			28.336,
			28.383,
			28.446,
			28.507,
			28.569,
			28.629,
			28.66,
			28.693,
			28.754,
			28.816,
			28.879,
			28.941,
			28.987,
			29.002,
			29.065,
			29.094,
			29.125,
			29.187,
			29.204,
			29.249,
			29.311,
			29.375,
			29.419,
			29.436,
			29.499,
			29.529,
			29.561,
			29.623,
			29.637,
			29.684,
			29.746,
			29.808,
			29.87,
			29.93,
			29.964,
			29.994,
			30.055,
			30.12,
			30.181,
			30.241,
			30.288,
			30.303,
			30.367,
			30.396,
			30.429,
			30.491,
			30.505,
			30.552,
			30.615,
			30.677,
			30.723,
			30.737,
			30.801,
			30.832,
			30.864,
			30.926,
			30.986,
			31.049,
			31.112,
			31.156,
			31.235,
			31.267,
			31.295,
			31.358,
			31.421,
			31.482,
			31.543,
			31.591,
			31.607,
			31.669,
			31.698,
			31.731,
			31.809,
			31.855,
			31.922,
			31.978,
			32.025,
			32.103,
			32.136,
			32.163,
			32.226,
			32.29,
			32.349,
			32.412,
			32.46,
			32.476,
			32.536,
			32.567,
			32.599,
			32.66,
			32.677,
			32.723,
			32.785,
			32.847,
			32.894,
			32.908,
			32.97,
			33.003,
			33.034,
			33.093,
			33.11,
			33.158,
			33.22,
			33.28,
			33.326,
			33.405,
			33.437,
			33.468,
			33.528,
			33.592,
			33.653,
			33.716,
			33.762,
			33.776,
			33.84,
			33.869,
			33.902,
			33.964,
			33.982,
			34.026,
			34.087,
			34.149,
			34.211,
			34.272,
			34.304,
			34.335,
			34.398,
			34.413,
			34.458,
			34.521,
			34.584,
			34.63,
			34.707,
			34.737,
			34.77,
			34.83,
			34.893,
			34.955,
			35.015,
			35.063,
			35.079,
			35.142,
			35.171,
			35.203,
			35.266,
			35.282,
			35.327,
			35.391,
			35.452,
			35.512,
			35.575,
			35.605,
			35.638,
			35.713,
			35.76,
			35.824,
			35.885,
			35.931,
			36.01,
			36.071,
			36.132,
			36.195,
			36.256,
			36.32,
			36.367,
			36.381,
			36.444,
			36.474,
			36.504,
			36.569,
			36.582,
			36.631,
			36.691,
			36.752,
			36.815,
			36.877,
			36.909,
			36.938,
			37.002,
			37.064,
			37.125,
			37.188,
			37.232,
			37.25,
			37.311,
			37.344,
			37.375,
			37.437,
			37.452,
			37.496,
			37.561,
			37.622,
			37.666,
			37.683,
			37.746,
			37.777,
			37.809,
			37.869,
			37.885,
			37.931,
			37.994,
			38.055,
			38.119,
			38.179,
			38.211,
			38.242,
			38.302,
			38.365,
			38.427,
			38.49,
			38.552,
			38.615,
			38.645,
			38.675,
			38.739,
			38.752,
			38.799,
			38.861,
			38.923,
			38.969,
			38.985,
			39.047,
			39.079,
			39.11,
			39.172,
			39.188,
			39.233,
			39.297,
			39.357,
			39.406,
			39.483,
			39.513,
			39.546,
			39.622,
			39.669,
			39.73,
			39.792,
			39.855,
			39.917,
			39.946,
			39.977,
			40.039,
			40.055,
			40.103,
			40.168,
			40.227,
			40.288,
			40.349,
			40.382,
			40.413,
			40.49,
			40.535,
			40.602,
			40.661,
			40.707,
			40.782,
			40.816,
			40.846,
			40.908,
			40.924,
			40.97,
			41.032,
			41.093,
			41.139,
			41.156,
			41.218,
			41.249,
			41.279,
			41.341,
			41.357,
			41.405,
			41.465,
			41.528,
			41.574,
			41.652,
			41.681,
			41.713,
			41.776,
			41.837,
			41.901,
			41.964,
			42.009,
			42.025,
			42.087,
			42.119,
			42.149,
			42.21,
			42.232,
			42.274,
			42.336,
			42.396,
			42.443,
			42.46,
			42.52,
			42.552,
			42.583,
			42.646,
			42.66,
			42.707,
			42.769,
			42.83,
			42.877,
			42.954,
			42.986,
			43.016,
			43.078,
			43.142,
			43.204,
			43.263,
			43.327,
			43.388,
			43.42,
			43.451,
			43.513,
			43.529,
			43.574,
			43.637,
			43.699,
			43.762,
			43.823,
			43.855,
			43.883,
			43.963,
			44.008,
			44.072,
			44.131,
			44.18,
			44.257,
			44.291,
			44.32,
			44.396,
			44.441,
			44.505,
			44.565,
			44.628,
			44.692,
			44.721,
			44.753,
			44.815,
			44.829,
			44.878,
			44.939,
			45.002,
			45.048,
			45.062,
			45.125,
			45.155,
			45.187,
			45.248,
			45.265,
			45.31,
			45.374,
			45.436,
			45.496,
			45.559,
			45.59,
			45.621,
			45.682,
			45.746,
			45.807,
			45.87,
			45.916,
			45.93,
			45.993,
			46.025,
			46.054,
			46.115,
			46.132,
			46.179,
			46.241,
			46.304,
			46.354,
			46.426,
			46.457,
			46.488,
			46.552,
			46.614,
			46.675,
			46.736,
			46.784,
			46.86,
			46.891,
			46.924,
			46.985,
			47.001,
			47.046,
			47.108,
			47.171,
			47.234,
			47.295,
			47.325,
			47.356,
			47.419,
			47.435,
			47.481,
			47.544,
			47.605,
			47.668,
			47.729,
			47.761,
			47.792,
			47.852,
			47.914,
			47.977,
			48.039,
			48.086,
			48.103,
			48.163,
			48.194,
			48.224,
			48.287,
			48.304,
			48.347,
			48.416,
			48.474,
			48.521,
			48.536,
			48.597,
			48.629,
			48.66,
			48.722,
			48.738,
			48.782,
			48.846,
			48.907,
			48.953,
			49.032,
			49.062,
			49.093,
			49.154,
			49.218,
			49.279,
			49.342,
			49.389,
			49.403,
			49.467,
			49.495,
			49.529,
			49.588,
			49.604,
			49.652,
			49.713,
			49.776,
			49.821,
			49.837,
			49.899,
			49.932,
			49.961,
			50.023,
			50.039,
			50.086,
			50.149,
			50.211,
			50.255,
			50.335,
			50.366,
			50.395,
			50.457,
			50.479,
			50.521,
			50.584,
			50.643,
			50.691,
			50.706,
			50.769,
			50.798,
			50.829,
			50.893,
			50.907,
			50.955,
			51.016,
			51.079,
			51.139,
			51.202,
			51.234,
			51.264,
			51.343,
			51.387,
			51.449,
			51.513,
			51.56,
			51.575,
			51.637,
			51.668,
			51.698,
			51.761,
			51.776,
			51.823,
			51.886,
			51.945,
			51.992,
			52.009,
			52.071,
			52.102,
			52.131,
			52.194,
			52.21,
			52.257,
			52.319,
			52.379,
			52.426,
			52.504,
			52.54,
			52.565,
			52.629,
			52.691,
			52.752,
			52.814,
			52.861,
			52.877,
			52.937,
			52.97,
			53.001,
			53.063,
			53.077,
			53.125,
			53.185,
			53.248,
			53.311,
			53.372,
			53.404,
			53.435,
			53.496,
			53.512,
			53.558,
			53.622,
			53.681,
			53.73,
			53.806,
			53.836,
			53.869,
			53.929,
			53.993,
			54.055,
			54.116,
			54.163,
			54.179,
			54.24,
			54.271,
			54.303,
			54.365,
			54.38,
			54.426,
			54.49,
			54.549,
			54.613,
			54.673,
			54.707,
			54.736,
			54.799,
			54.86,
			54.923,
			54.986,
			55.032,
			55.107,
			55.14,
			55.17,
			55.232,
			55.296,
			55.357,
			55.418,
			55.464,
			55.479,
			55.544,
			55.575,
			55.605,
			55.667,
			55.682,
			55.729,
			55.794,
			55.853,
			55.9,
			55.976,
			56.007,
			56.04,
			56.099,
			56.117,
			56.162,
			56.226,
			56.287,
			56.334,
			56.41,
			56.442,
			56.472,
			56.536,
			56.595,
			56.658,
			56.722,
			56.766,
			56.782,
			56.846,
			56.877,
			56.907,
			56.985,
			57.032,
			57.093,
			57.156,
			57.216,
			57.278,
			57.31,
			57.341,
			57.404,
			57.466,
			57.527,
			57.589,
			57.651,
			57.713,
			57.743,
			57.776,
			57.837,
			57.852,
			57.898,
			57.96,
			58.023,
			58.069,
			58.086,
			58.146,
			58.179,
			58.21,
			58.271,
			58.287,
			58.331,
			58.398,
			58.458,
			58.503,
			58.581,
			58.613,
			58.644,
			58.704,
			58.726,
			58.766,
			58.829,
			58.89,
			58.937,
			58.953,
			59.016,
			59.045,
			59.078,
			59.14,
			59.154,
			59.202,
			59.263,
			59.325,
			59.372,
			59.386,
			59.448,
			59.48,
			59.513,
			59.59,
			59.635,
			59.697,
			59.76,
			59.805,
			59.884,
			59.914,
			59.947,
			60.009,
			60.07,
			60.132,
			60.194,
			60.24,
			60.254,
			60.316,
			60.349,
			60.378,
			60.442,
			60.457,
			60.504,
			60.567,
			60.628,
			60.691,
			60.752,
			60.813,
			60.877,
			60.936,
			61.001,
			61.063,
			61.107,
			61.187,
			61.218,
			61.246,
			61.31,
			61.326,
			61.372,
			61.435,
			61.496,
			61.543,
			61.558,
			61.619,
			61.651,
			61.681,
			61.76,
			61.805,
			61.869,
			61.929,
			61.991,
			62.054,
			62.085,
			62.115,
			62.178,
			62.239,
			62.302,
			62.364,
			62.41,
			62.427,
			62.488,
			62.519,
			62.549,
			62.612,
			62.628,
			62.675,
			62.737,
			62.798,
			62.85,
			62.922,
			62.953,
			62.985,
			63.046,
			63.062,
			63.109,
			63.169,
			63.23,
			63.28,
			63.357,
			63.387,
			63.419,
			63.479,
			63.541,
			63.604,
			63.664,
			63.714,
			63.728,
			63.79,
			63.822,
			63.853,
			63.915,
			63.929,
			63.977,
			64.037,
			64.099,
			64.146,
			64.162,
			64.223,
			64.254,
			64.287,
			64.348,
			64.364,
			64.41,
			64.472,
			64.536,
			64.581,
			64.658,
			64.688,
			64.721,
			64.782,
			64.844,
			64.911,
			64.969,
			65.016,
			65.03,
			65.091,
			65.125,
			65.154,
			65.232,
			65.279,
			65.341,
			65.402,
			65.448,
			65.464,
			65.526,
			65.557,
			65.588,
			65.652,
			65.712,
			65.776,
			65.835,
			65.883,
			65.96,
			65.992,
			66.023,
			66.101,
			66.146,
			66.207,
			66.269,
			66.315,
			66.332,
			66.395,
			66.427,
			66.456,
			66.533,
			66.581,
			66.643,
			66.705,
			66.752,
			66.827,
			66.86,
			66.891,
			66.954,
			67.014,
			67.077,
			67.139,
			67.186,
			67.201,
			67.263,
			67.294,
			67.323,
			67.387,
			67.402,
			67.45,
			67.512,
			67.571,
			67.62,
			67.636,
			67.696,
			67.727,
			67.758,
			67.82,
			67.836,
			67.882,
			67.946,
			68.006,
			68.055,
			68.132,
			68.163,
			68.194,
			68.255,
			68.318,
			68.378,
			68.441,
			68.487,
			68.504,
			68.563,
			68.594,
			68.627,
			68.689,
			68.705,
			68.752,
			68.812,
			68.876,
			68.921,
			68.938,
			68.999,
			69.035,
			69.062,
			69.137,
			69.185,
			69.246,
			69.308,
			69.354,
			69.431,
			69.464,
			69.494,
			69.557,
			69.62,
			69.683,
			69.744,
			69.79,
			69.804,
			69.865,
			69.899,
			69.929,
			69.99,
			70.007,
			70.054,
			70.115,
			70.177,
			70.24,
			70.302,
			70.331,
			70.364,
			70.442,
			70.488,
			70.549,
			70.612,
			70.673,
			70.735,
			70.766,
			70.796,
			70.86,
			70.875,
			70.922,
			70.982,
			71.046,
			71.096,
			71.107,
			71.171,
			71.199,
			71.232,
			71.309,
			71.355,
			71.419,
			71.479,
			71.543,
			71.602,
			71.635,
			71.666,
			71.728,
			71.79,
			71.854,
			71.913,
			71.976,
			72.039,
			72.069,
			72.098,
			72.161,
			72.178,
			72.224,
			72.286,
			72.349,
			72.395,
			72.411,
			72.471,
			72.502,
			72.535,
			72.594,
			72.612,
			72.657,
			72.719,
			72.781,
			72.843,
			72.906,
			72.936,
			72.968,
			73.03,
			73.093,
			73.157,
			73.215,
			73.262,
			73.277,
			73.341,
			73.371,
			73.403,
			73.479,
			73.526,
			73.589,
			73.651,
			73.696,
			73.711,
			73.774,
			73.804,
			73.837,
			73.915,
			73.961,
			74.027,
			74.082,
			74.146,
			74.207,
			74.239,
			74.269,
			74.33,
			74.393,
			74.455,
			74.519,
			74.564,
			74.579,
			74.641,
			74.674,
			74.704,
			74.766,
			74.782,
			74.829,
			74.892,
			74.952,
			74.997,
			75.013,
			75.077,
			75.108,
			75.137,
			75.198,
			75.22,
			75.263,
			75.324,
			75.386,
			75.432,
			75.51,
			75.541,
			75.573,
			75.635,
			75.696,
			75.759,
			75.821,
			75.867,
			75.88,
			75.944,
			75.976,
			76.007,
			76.069,
			76.084,
			76.131,
			76.193,
			76.255,
			76.318,
			76.38,
			76.411,
			76.441,
			76.503,
			76.566,
			76.629,
			76.687,
			76.735,
			76.751,
			76.813,
			76.844,
			76.875,
			76.937,
			76.953,
			76.998,
			77.061,
			77.124,
			77.17,
			77.186,
			77.248,
			77.307,
			77.37,
			77.386,
			77.432,
			77.496,
			77.556,
			77.619,
			77.679,
			77.71,
			77.741,
			77.819,
			77.865,
			77.929,
			77.989,
			78.036,
			78.053,
			78.116,
			78.146,
			78.178,
			78.24,
			78.253,
			78.299,
			78.362,
			78.425,
			78.47,
			78.487,
			78.548,
			78.579,
			78.61,
			78.689,
			78.735,
			78.798,
			78.857,
			78.92,
			78.983,
			79.014,
			79.044,
			79.106,
			79.17,
			79.231,
			79.294,
			79.345,
			79.356,
			79.415,
			79.447,
			79.48,
			79.54,
			79.556,
			79.603,
			79.664,
			79.726,
			79.788,
			79.851,
			79.881,
			79.914,
			79.975,
			80.036,
			80.099,
			80.16,
			80.224,
			80.286,
			80.317,
			80.346,
			80.409,
			80.425,
			80.471,
			80.534,
			80.596,
			80.642,
			80.658,
			80.719,
			80.751,
			80.781,
			80.842,
			80.859,
			80.904,
			80.965,
			81.028,
			81.076,
			81.154,
			81.182,
			81.215,
			81.277,
			81.34,
			81.404,
			81.463,
			81.508,
			81.526,
			81.588,
			81.62,
			81.647,
			81.71,
			81.726,
			81.775,
			81.836,
			81.898,
			81.944,
			81.961,
			82.022,
			82.053,
			82.082,
			82.146,
			82.161,
			82.207,
			82.269,
			82.332,
			82.377,
			82.457,
			82.487,
			82.519,
			82.58,
			82.643,
			82.704,
			82.765,
			82.812,
			82.827,
			82.891,
			82.919,
			82.951,
			83.014,
			83.03,
			83.074,
			83.137,
			83.199,
			83.246,
			83.263,
			83.322,
			83.355,
			83.385,
			83.466,
			83.51,
			83.572,
			83.632,
			83.679,
			83.759,
			83.789,
			83.819,
			83.88,
			83.944,
			84.005,
			84.068,
			84.113,
			84.129,
			84.19,
			84.223,
			84.254,
			84.332,
			84.378,
			84.441,
			84.502,
			84.549,
			84.625,
			84.657,
			84.689,
			84.764,
			84.813,
			84.874,
			84.936,
			84.983,
			85.061,
			85.09,
			85.123,
			85.185,
			85.245,
			85.308,
			85.371,
			85.415,
			85.432,
			85.494,
			85.53,
			85.556,
			85.619,
			85.635,
			85.68,
			85.741,
			85.805,
			85.849,
			85.927,
			85.96,
			85.99,
			86.053,
			86.114,
			86.175,
			86.237,
			86.283,
			86.3,
			86.361,
			86.394,
			86.425,
			86.487,
			86.502,
			86.549,
			86.61,
			86.671,
			86.719,
			86.734,
			86.796,
			86.826,
			86.857,
			86.919,
			86.937,
			86.982,
			87.043,
			87.106,
			87.154,
			87.231,
			87.26,
			87.293,
			87.354,
			87.416,
			87.479,
			87.542,
			87.603,
			87.664,
			87.696,
			87.727,
			87.805,
			87.851,
			87.916,
			87.975,
			88.037,
			88.099,
			88.129,
			88.16,
			88.222,
			88.239,
			88.283,
			88.348,
			88.408,
			88.454,
			88.531,
			88.563,
			88.594,
			88.657,
			88.719,
			88.781,
			88.842,
			88.89,
			88.903,
			88.966,
			88.997,
			89.029,
			89.091,
			89.107,
			89.152,
			89.216,
			89.277,
			89.323,
			89.34,
			89.401,
			89.432,
			89.464,
			89.524,
			89.54,
			89.587,
			89.653,
			89.712,
			89.773,
			89.835,
			89.865,
			89.898,
			89.958,
			89.975,
			90.02,
			90.082,
			90.145,
			90.207,
			90.269,
			90.3,
			90.332,
			90.393,
			90.408,
			90.455,
			90.523,
			90.579,
			90.641,
			90.704,
			90.734,
			90.766,
			90.828,
			90.888,
			90.952,
			91.013,
			91.06,
			91.075,
			91.137,
			91.169,
			91.199,
			91.261,
			91.276,
			91.322,
			91.386,
			91.446,
			91.495,
			91.509,
			91.572,
			91.602,
			91.632,
			91.695,
			91.713,
			91.735
		]
	],
	'5300_6164_7982': [
		[
			48.341
		],
		[
			0
		],
		[
			0,
			1.091,
			3.464,
			7.041,
			9.44,
			11.849,
			13.056,
			15.477,
			16.691,
			17.906,
			21.566,
			22.789,
			24.014,
			27.695,
			30.147,
			33.804,
			36.217,
			38.605,
			39.786,
			42.123,
			43.279,
			45.562,
			47.807,
			51.103,
			53.252,
			56.403,
			57.434,
			58.455,
			61.482,
			63.445,
			66.326,
			68.21,
			70.062,
			70.976,
			72.785,
			73.681,
			75.452,
			77.2,
			78.072,
			79.805,
			81.528,
			83.252,
			84.971,
			85.827,
			87.539,
			89.253,
			90.107,
			91.813,
			92.67,
			94.384,
			96.095,
			98.666,
			100.379,
			102.949,
			103.807,
			104.664,
			107.236,
			108.951,
			111.521,
			113.236,
			114.95,
			115.806,
			117.521,
			118.381,
			120.1,
			122.68,
			124.405,
			126.131,
			128.729,
			129.596,
			130.464,
			133.077,
			133.952,
			134.827,
			136.581,
			137.462,
			139.231,
			141.891,
			143.676,
			145.464,
			148.158,
			149.06,
			149.963,
			152.683,
			154.511,
			157.265,
			159.123,
			160.986,
			161.92,
			163.807,
			164.754,
			166.652,
			168.567,
			169.531,
			171.46,
			173.397,
			176.316,
			178.263,
			181.181,
			182.153,
			183.126,
			186.038,
			187.978,
			190.879,
			192.81,
			194.734,
			197.614,
			198.571,
			199.525,
			202.384,
			203.334,
			204.282,
			207.12,
			209.01,
			210.897,
			211.84,
			213.731,
			215.625,
			216.572,
			219.422,
			221.326,
			223.233,
			226.1,
			228.015,
			230.885,
			231.843,
			232.804,
			235.669,
			237.585,
			240.448,
			242.363,
			244.274,
			245.23,
			247.148,
			248.109,
			250.028,
			252.923,
			253.893,
			256.817,
			259.771,
			260.762,
			261.756,
			263.755,
			265.767,
			266.778,
			268.807,
			269.826,
			271.872,
			273.931,
			277.03,
			279.118,
			282.254,
			283.302,
			284.354,
			287.523,
			289.648,
			292.854,
			295.003,
			298.247,
			300.421,
			301.512,
			303.702,
			305.903,
			307.008,
			309.224,
			311.453,
			314.815,
			315.941,
			317.069,
			320.47,
			321.609,
			322.749,
			326.187,
			328.491,
			330.804,
			334.291,
			336.628,
			340.15,
			341.329,
			342.51,
			346.068,
			348.452,
			352.045,
			354.453,
			356.869,
			358.08,
			360.51,
			361.729,
			364.172,
			366.625,
			367.855,
			370.321,
			372.796,
			376.525,
			379.022,
			382.787,
			384.045,
			385.307,
			389.103,
			391.644,
			394.192,
			398.029,
			400.597,
			404.463,
			405.755,
			407.049,
			412.247,
			413.55,
			417.459,
			420.071,
			424.003,
			426.632,
			429.268,
			430.589,
			433.236,
			434.562,
			437.219,
			439.883,
			443.891,
			446.572,
			450.607,
			451.956,
			453.307,
			457.371,
			460.089,
			464.18,
			466.916,
			471.034,
			473.787,
			475.166,
			476.547,
			480.7,
			482.087,
			483.477,
			487.655,
			490.449,
			493.249,
			494.652,
			497.463,
			500.28,
			501.692,
			505.937,
			508.775,
			511.62,
			515.9,
			518.762,
			523.067,
			524.505,
			525.945,
			530.274,
			533.169,
			537.522,
			540.433,
			544.811,
			547.738,
			549.204,
			552.14,
			555.083,
			556.556,
			559.508,
			562.466,
			566.914,
			568.4,
			569.888,
			574.359,
			575.852,
			577.346,
			580.34,
			581.836,
			584.831,
			587.832,
			592.347,
			595.379,
			599.944,
			601.47,
			602.997,
			607.563,
			610.614,
			615.202,
			618.267,
			621.339,
			622.876,
			625.956,
			627.498,
			630.585,
			635.223,
			638.32,
			641.421,
			646.081,
			649.194,
			653.872,
			655.433,
			656.996,
			661.691,
			664.828,
			669.536,
			672.661,
			675.765,
			680.362,
			681.875,
			683.379,
			687.83,
			689.294,
			690.75,
			695.061,
			697.887,
			700.674,
			702.053,
			704.782,
			707.473,
			708.804,
			711.439,
			712.742,
			715.32,
			717.859,
			721.598,
			724.044,
			727.647,
			728.83,
			730.005,
			733.478,
			735.746,
			739.077,
			741.251,
			744.442,
			746.523,
			747.551,
			749.577,
			751.568,
			752.549,
			753.521,
			756.382,
			758.244,
			760.073,
			760.976,
			762.755,
			764.5,
			765.359,
			767.048,
			767.88,
			769.527,
			771.123,
			773.449,
			774.954,
			777.155,
			777.876,
			778.592,
			780.707,
			782.099,
			784.175,
			785.533,
			786.89,
			787.569,
			788.927,
			789.598,
			790.936,
			792.275,
			792.945,
			794.296,
			795.69,
			797.783,
			798.483,
			799.19,
			800.705,
			802.218,
			802.975,
			805.327,
			806.947,
			808.567,
			811.02,
			812.655,
			815.075,
			815.862,
			816.648,
			818.999,
			820.495,
			822.705,
			824.171,
			825.578,
			826.276,
			827.667,
			828.36,
			829.741,
			831.107,
			831.788,
			833.144,
			834.496,
			836.507,
			837.843,
			839.853,
			840.529,
			841.205,
			843.235,
			844.6,
			846.705,
			848.11,
			849.519,
			851.658,
			852.367,
			853.072,
			855.864,
			856.554,
			858.606,
			859.945,
			861.283,
			861.949,
			863.279,
			864.602,
			865.259,
			866.578,
			867.229,
			868.525,
			869.813,
			871.719,
			872.977,
			874.849,
			875.464,
			876.077,
			877.902,
			879.11,
			880.931,
			882.134,
			883.33,
			883.925,
			885.161,
			885.796,
			886.428,
			888.314,
			888.941,
			889.58,
			891.682,
			893.077,
			895.212,
			896.69,
			898.165,
			898.902,
			900.298,
			900.968,
			902.304,
			903.641,
			905.533,
			906.736,
			908.544,
			909.15,
			909.755,
			911.562,
			912.771,
			914.596,
			915.833,
			917.074,
			917.697,
			918.947,
			919.576,
			920.857,
			922.146,
			922.792,
			924.092,
			925.412,
			927.419,
			928.094,
			928.771,
			930.138,
			931.522,
			932.22,
			933.627,
			934.337,
			935.773,
			937.224,
			939.432,
			940.924,
			943.19,
			943.954,
			944.721,
			947.039,
			948.603,
			950.956,
			952.547,
			954.152,
			954.958,
			956.576,
			957.391,
			959.03,
			961.511,
			963.169,
			964.828,
			967.345,
			968.191,
			969.04,
			971.61,
			972.471,
			973.331,
			975.903,
			977.613,
			980.108,
			981.742,
			983.354,
			985.747,
			986.542,
			987.337,
			989.724,
			990.524,
			991.325,
			993.741,
			995.361,
			996.985,
			997.8,
			999.433,
			1001.067,
			1001.883,
			1003.513,
			1004.328,
			1005.95,
			1007.561,
			1009.959,
			1011.551,
			1013.911,
			1014.691,
			1015.477,
			1017.844,
			1019.409,
			1021.809,
			1023.421,
			1025.028,
			1027.487,
			1028.305,
			1029.122,
			1031.584,
			1032.405,
			1033.225,
			1035.677,
			1037.307,
			1039.735,
			1041.354,
			1042.967,
			1043.77,
			1045.373,
			1046.176,
			1047.773,
			1049.364,
			1051.745,
			1053.328,
			1055.703,
			1056.495,
			1057.286,
			1059.656,
			1061.231,
			1063.585,
			1065.155,
			1066.71,
			1067.486,
			1069.039,
			1069.816,
			1071.353,
			1072.879,
			1073.641,
			1075.169,
			1076.678,
			1078.94,
			1079.695,
			1080.452,
			1082.699,
			1083.45,
			1084.202,
			1085.709,
			1086.458,
			1087.959,
			1089.466,
			1091.737,
			1093.256,
			1095.55,
			1096.326,
			1097.105,
			1099.454,
			1101.04,
			1103.471,
			1105.106,
			1106.767,
			1107.606,
			1109.293,
			1110.982,
			1111.843,
			1114.446,
			1116.197,
			1117.961,
			1120.632,
			1121.526,
			1122.424,
			1125.136,
			1126.044,
			1126.948,
			1129.681,
			1131.521,
			1134.31,
			1136.186,
			1138.074,
			1139.023,
			1140.93,
			1141.887,
			1142.848,
			1145.748,
			1146.72,
			1147.696,
			1150.642,
			1152.621,
			1155.613,
			1157.621,
			1159.641,
			1160.655,
			1162.691,
			1163.714,
			1165.767,
			1167.832,
			1170.951,
			1173.045,
			1176.186,
			1177.234,
			1178.285,
			1181.452,
			1183.577,
			1186.8,
			1188.947,
			1192.173,
			1194.327,
			1195.404,
			1196.48,
			1199.708,
			1200.781,
			1201.851,
			1205.046,
			1207.166,
			1209.275,
			1210.326,
			1212.421,
			1214.503,
			1215.541,
			1217.611,
			1218.643,
			1220.704,
			1222.768,
			1225.862,
			1227.934,
			1231.047,
			1232.09,
			1233.137,
			1236.282,
			1238.396,
			1241.57,
			1243.698,
			1245.823,
			1246.885,
			1249.006,
			1250.064,
			1252.178,
			1255.334,
			1257.43,
			1259.518,
			1262.64,
			1264.715,
			1267.825,
			1268.859,
			1269.894,
			1271.962,
			1272.999,
			1275.068,
			1277.136,
			1278.173,
			1280.241,
			1282.304,
			1285.394,
			1286.42,
			1287.446,
			1290.51,
			1292.538,
			1295.564,
			1297.576,
			1299.582,
			1300.584,
			1302.588,
			1303.59,
			1305.593,
			1308.599,
			1310.603,
			1312.608,
			1315.615,
			1316.617,
			1317.619,
			1320.621,
			1321.621,
			1322.621,
			1325.62,
			1327.621,
			1329.624,
			1332.633,
			1334.647,
			1337.673,
			1338.686,
			1339.698,
			1342.74,
			1343.758,
			1344.776,
			1347.83,
			1349.867,
			1351.905,
			1352.925,
			1354.967,
			1357.01,
			1358.034,
			1360.083,
			1361.107,
			1363.159,
			1365.217,
			1368.308,
			1370.379,
			1373.491,
			1374.531,
			1375.571,
			1378.692,
			1380.77,
			1383.887,
			1385.962,
			1388.036,
			1389.074,
			1391.146,
			1392.179,
			1394.248,
			1397.339,
			1398.368,
			1401.447,
			1403.493,
			1405.534,
			1406.551,
			1408.584,
			1410.612,
			1411.624,
			1413.646,
			1414.656,
			1416.674,
			1418.693,
			1421.722,
			1423.748,
			1426.802,
			1427.822,
			1428.848,
			1431.936,
			1434,
			1437.1,
			1439.168,
			1442.27,
			1444.333,
			1446.391,
			1447.419,
			1449.466,
			1450.485,
			1452.52,
			1454.548,
			1457.577,
			1458.585,
			1459.592,
			1461.603,
			1463.612,
			1464.617,
			1466.627,
			1467.632,
			1469.649,
			1471.67,
			1474.708,
			1476.74,
			1479.8,
			1480.824,
			1481.85,
			1484.937,
			1487.004,
			1490.118,
			1492.202,
			1494.291,
			1495.334,
			1497.428,
			1498.477,
			1500.576,
			1502.681,
			1503.736,
			1505.847,
			1507.964,
			1511.143,
			1513.269,
			1516.458,
			1517.523,
			1518.59,
			1521.795,
			1523.94,
			1527.162,
			1529.316,
			1531.474,
			1534.72,
			1535.803,
			1536.887,
			1540.143,
			1541.229,
			1542.316,
			1545.582,
			1547.76,
			1551.034,
			1553.22,
			1555.412,
			1556.508,
			1558.7,
			1559.798,
			1561.992,
			1564.184,
			1567.461,
			1569.637,
			1572.885,
			1573.964,
			1575.044,
			1578.258,
			1580.38,
			1583.526,
			1585.577,
			1587.616,
			1588.623,
			1590.612,
			1591.592,
			1592.565,
			1595.435,
			1596.376,
			1597.307,
			1600.05,
			1601.837,
			1603.59,
			1604.454,
			1606.155,
			1607.818,
			1608.636,
			1611.039,
			1612.595,
			1614.13,
			1616.36,
			1617.802,
			1619.91,
			1620.598,
			1621.276,
			1623.264,
			1624.555,
			1626.439,
			1627.656,
			1628.847,
			1629.445,
			1630.628,
			1631.209,
			1632.355,
			1633.483,
			1634.043,
			1635.196,
			1636.328,
			1637.446,
			1638.556,
			1639.115,
			1640.851,
			1641.426,
			1641.999,
			1643.144,
			1643.716,
			1644.913,
			1646.122,
			1647.929,
			1649.14,
			1651.637,
			1652.26,
			1654.134,
			1655.41,
			1657.319,
			1658.597,
			1660.523,
			1661.805,
			1662.447,
			1663.734,
			1664.984,
			1665.6,
			1666.831,
			1668.066,
			1669.885,
			1671.064,
			1672.838,
			1673.431,
			1674.026,
			1675.743,
			1676.887,
			1678.607,
			1679.743,
			1680.871,
			1682.567,
			1683.134,
			1683.703,
			1685.446,
			1686.032,
			1686.621,
			1688.397,
			1689.602,
			1690.834,
			1691.453,
			1692.7,
			1693.957,
			1694.591,
			1695.873,
			1696.519,
			1697.824,
			1699.145,
			1701.153,
			1702.513,
			1704.57,
			1705.266,
			1705.966,
			1708.089,
			1709.525,
			1711.709,
			1713.185,
			1715.426,
			1716.938,
			1717.7,
			1718.466,
			1720.784,
			1721.564,
			1722.348,
			1724.722,
			1726.324,
			1727.94,
			1728.753,
			1730.391,
			1732.043,
			1732.875,
			1734.548,
			1735.388,
			1737.069,
			1738.75,
			1741.295,
			1743.008,
			1745.603,
			1746.474,
			1747.348,
			1749.991,
			1751.769,
			1754.461,
			1756.271,
			1759.011,
			1760.854,
			1761.78,
			1763.642,
			1765.517,
			1766.459,
			1767.405,
			1770.26,
			1772.179,
			1774.111,
			1775.081,
			1778.012,
			1778.995,
			1779.981,
			1781.963,
			1782.959,
			1784.959,
			1786.971,
			1790.011,
			1792.053,
			1795.137,
			1796.171,
			1797.207,
			1800.332,
			1802.427,
			1805.569,
			1807.669,
			1809.779,
			1810.838,
			1812.963,
			1814.029,
			1816.169,
			1818.318,
			1819.397,
			1821.561,
			1823.736,
			1827.016,
			1828.115,
			1829.216,
			1832.533,
			1833.643,
			1834.756,
			1838.11,
			1840.358,
			1842.616,
			1846.021,
			1848.303,
			1851.745,
			1852.897,
			1854.052,
			1857.528,
			1859.857,
			1863.369,
			1864.191
		],
		[
			0,
			0.008,
			0.07,
			0.132,
			0.193,
			0.241,
			0.256,
			0.318,
			0.346,
			0.379,
			0.442,
			0.456,
			0.502,
			0.566,
			0.626,
			0.688,
			0.752,
			0.783,
			0.812,
			0.875,
			0.89,
			0.937,
			0.999,
			1.061,
			1.107,
			1.184,
			1.216,
			1.248,
			1.309,
			1.371,
			1.434,
			1.496,
			1.543,
			1.556,
			1.619,
			1.651,
			1.683,
			1.742,
			1.76,
			1.805,
			1.868,
			1.929,
			1.975,
			1.991,
			2.055,
			2.085,
			2.115,
			2.177,
			2.194,
			2.241,
			2.303,
			2.363,
			2.41,
			2.489,
			2.518,
			2.549,
			2.611,
			2.674,
			2.737,
			2.796,
			2.844,
			2.861,
			2.923,
			2.953,
			2.983,
			3.062,
			3.108,
			3.169,
			3.233,
			3.277,
			3.294,
			3.357,
			3.39,
			3.419,
			3.48,
			3.494,
			3.542,
			3.605,
			3.666,
			3.71,
			3.79,
			3.821,
			3.853,
			3.913,
			3.975,
			4.039,
			4.099,
			4.145,
			4.163,
			4.225,
			4.254,
			4.287,
			4.348,
			4.363,
			4.41,
			4.472,
			4.535,
			4.582,
			4.658,
			4.689,
			4.721,
			4.783,
			4.843,
			4.907,
			4.967,
			5.015,
			5.092,
			5.124,
			5.154,
			5.217,
			5.232,
			5.278,
			5.34,
			5.403,
			5.452,
			5.465,
			5.526,
			5.556,
			5.589,
			5.666,
			5.713,
			5.776,
			5.836,
			5.883,
			5.96,
			5.992,
			6.023,
			6.1,
			6.146,
			6.21,
			6.27,
			6.317,
			6.333,
			6.394,
			6.424,
			6.456,
			6.533,
			6.579,
			6.642,
			6.705,
			6.75,
			6.766,
			6.829,
			6.858,
			6.89,
			6.952,
			6.967,
			7.016,
			7.076,
			7.138,
			7.202,
			7.263,
			7.294,
			7.326,
			7.388,
			7.449,
			7.513,
			7.572,
			7.634,
			7.697,
			7.729,
			7.758,
			7.822,
			7.837,
			7.882,
			7.945,
			8.005,
			8.053,
			8.07,
			8.132,
			8.161,
			8.193,
			8.255,
			8.316,
			8.377,
			8.44,
			8.488,
			8.565,
			8.595,
			8.626,
			8.689,
			8.751,
			8.812,
			8.875,
			8.922,
			8.936,
			8.999,
			9.03,
			9.062,
			9.123,
			9.138,
			9.185,
			9.248,
			9.31,
			9.355,
			9.432,
			9.463,
			9.494,
			9.578,
			9.618,
			9.68,
			9.741,
			9.789,
			9.867,
			9.898,
			9.928,
			10.007,
			10.054,
			10.115,
			10.177,
			10.239,
			10.302,
			10.333,
			10.363,
			10.426,
			10.442,
			10.485,
			10.549,
			10.61,
			10.656,
			10.735,
			10.766,
			10.798,
			10.876,
			10.92,
			10.984,
			11.044,
			11.108,
			11.169,
			11.201,
			11.23,
			11.293,
			11.308,
			11.354,
			11.417,
			11.477,
			11.526,
			11.541,
			11.603,
			11.638,
			11.665,
			11.742,
			11.788,
			11.852,
			11.915,
			11.976,
			12.038,
			12.068,
			12.1,
			12.177,
			12.223,
			12.284,
			12.348,
			12.408,
			12.472,
			12.501,
			12.532,
			12.597,
			12.611,
			12.658,
			12.721,
			12.782,
			12.829,
			12.844,
			12.905,
			12.936,
			12.968,
			13.029,
			13.044,
			13.091,
			13.154,
			13.215,
			13.263,
			13.339,
			13.37,
			13.401,
			13.463,
			13.527,
			13.589,
			13.651,
			13.699,
			13.711,
			13.774,
			13.805,
			13.835,
			13.913,
			13.958,
			14.023,
			14.085,
			14.131,
			14.207,
			14.239,
			14.269,
			14.347,
			14.394,
			14.457,
			14.517,
			14.578,
			14.643,
			14.674,
			14.703,
			14.767,
			14.782,
			14.827,
			14.891,
			14.95,
			14.996,
			15.015,
			15.074,
			15.106,
			15.139,
			15.2,
			15.216,
			15.26,
			15.325,
			15.385,
			15.433,
			15.51,
			15.541,
			15.573,
			15.635,
			15.695,
			15.758,
			15.821,
			15.883,
			15.944,
			15.975,
			16.005,
			16.068,
			16.083,
			16.128,
			16.191,
			16.255,
			16.301,
			16.317,
			16.378,
			16.41,
			16.439,
			16.501,
			16.518,
			16.562,
			16.627,
			16.689,
			16.733,
			16.811,
			16.842,
			16.875,
			16.937,
			16.998,
			17.06,
			17.121,
			17.168,
			17.183,
			17.247,
			17.278,
			17.308,
			17.371,
			17.386,
			17.433,
			17.494,
			17.557,
			17.603,
			17.618,
			17.679,
			17.71,
			17.742,
			17.805,
			17.866,
			17.928,
			17.991,
			18.035,
			18.115,
			18.145,
			18.176,
			18.239,
			18.301,
			18.366,
			18.423,
			18.472,
			18.487,
			18.549,
			18.578,
			18.609,
			18.672,
			18.688,
			18.735,
			18.798,
			18.858,
			18.906,
			18.983,
			19.012,
			19.045,
			19.106,
			19.17,
			19.231,
			19.294,
			19.339,
			19.418,
			19.446,
			19.478,
			19.555,
			19.603,
			19.666,
			19.726,
			19.774,
			19.788,
			19.852,
			19.886,
			19.914,
			19.975,
			19.989,
			20.037,
			20.1,
			20.159,
			20.222,
			20.286,
			20.316,
			20.347,
			20.41,
			20.471,
			20.533,
			20.594,
			20.64,
			20.658,
			20.719,
			20.75,
			20.779,
			20.843,
			20.857,
			20.903,
			20.967,
			21.027,
			21.091,
			21.154,
			21.183,
			21.216,
			21.278,
			21.292,
			21.339,
			21.401,
			21.462,
			21.508,
			21.587,
			21.619,
			21.65,
			21.725,
			21.772,
			21.834,
			21.896,
			21.946,
			21.96,
			22.021,
			22.053,
			22.084,
			22.146,
			22.161,
			22.207,
			22.27,
			22.332,
			22.377,
			22.394,
			22.454,
			22.485,
			22.516,
			22.578,
			22.596,
			22.641,
			22.703,
			22.766,
			22.81,
			22.888,
			22.921,
			22.952,
			23.012,
			23.075,
			23.137,
			23.2,
			23.244,
			23.261,
			23.322,
			23.353,
			23.386,
			23.461,
			23.508,
			23.57,
			23.634,
			23.678,
			23.696,
			23.757,
			23.788,
			23.819,
			23.882,
			23.944,
			24.009,
			24.066,
			24.128,
			24.192,
			24.223,
			24.255,
			24.316,
			24.332,
			24.379,
			24.439,
			24.502,
			24.549,
			24.562,
			24.626,
			24.658,
			24.686,
			24.75,
			24.765,
			24.81,
			24.875,
			24.936,
			24.982,
			25.059,
			25.091,
			25.121,
			25.184,
			25.244,
			25.307,
			25.37,
			25.416,
			25.495,
			25.525,
			25.555,
			25.617,
			25.632,
			25.678,
			25.741,
			25.804,
			25.866,
			25.927,
			25.958,
			25.988,
			26.051,
			26.073,
			26.112,
			26.176,
			26.237,
			26.284,
			26.361,
			26.391,
			26.424,
			26.485,
			26.548,
			26.61,
			26.672,
			26.717,
			26.733,
			26.795,
			26.827,
			26.858,
			26.92,
			26.936,
			26.983,
			27.045,
			27.107,
			27.153,
			27.167,
			27.231,
			27.262,
			27.293,
			27.355,
			27.369,
			27.416,
			27.479,
			27.539,
			27.586,
			27.665,
			27.695,
			27.725,
			27.787,
			27.849,
			27.913,
			27.975,
			28.022,
			28.036,
			28.099,
			28.136,
			28.159,
			28.239,
			28.285,
			28.347,
			28.407,
			28.454,
			28.469,
			28.533,
			28.561,
			28.593,
			28.657,
			28.718,
			28.781,
			28.843,
			28.889,
			28.905,
			28.966,
			28.999,
			29.029,
			29.091,
			29.106,
			29.153,
			29.215,
			29.276,
			29.339,
			29.4,
			29.431,
			29.46,
			29.525,
			29.54,
			29.586,
			29.652,
			29.711,
			29.755,
			29.833,
			29.867,
			29.898,
			29.957,
			30.02,
			30.083,
			30.145,
			30.207,
			30.269,
			30.299,
			30.33,
			30.392,
			30.407,
			30.454,
			30.516,
			30.579,
			30.624,
			30.64,
			30.702,
			30.735,
			30.762,
			30.826,
			30.843,
			30.888,
			30.951,
			31.011,
			31.059,
			31.138,
			31.168,
			31.198,
			31.261,
			31.321,
			31.385,
			31.448,
			31.492,
			31.509,
			31.57,
			31.603,
			31.633,
			31.71,
			31.756,
			31.819,
			31.88,
			31.942,
			32.005,
			32.035,
			32.065,
			32.127,
			32.145,
			32.191,
			32.252,
			32.258,
			32.316,
			32.362,
			32.44,
			32.47,
			32.502,
			32.578,
			32.626,
			32.686,
			32.749,
			32.797,
			32.81,
			32.872,
			32.904,
			32.936,
			33.012,
			33.059,
			33.122,
			33.183,
			33.229,
			33.246,
			33.308,
			33.337,
			33.37,
			33.431,
			33.492,
			33.555,
			33.617,
			33.665,
			33.741,
			33.772,
			33.803,
			33.865,
			33.882,
			33.927,
			33.989,
			34.05,
			34.096,
			34.114,
			34.175,
			34.207,
			34.238,
			34.3,
			34.319,
			34.361,
			34.423,
			34.486,
			34.545,
			34.609,
			34.639,
			34.67,
			34.734,
			34.796,
			34.858,
			34.919,
			34.967,
			34.983,
			35.044,
			35.074,
			35.106,
			35.182,
			35.227,
			35.292,
			35.352,
			35.4,
			35.415,
			35.478,
			35.508,
			35.539,
			35.6,
			35.618,
			35.663,
			35.725,
			35.787,
			35.835,
			35.912,
			35.943,
			35.975,
			36.037,
			36.096,
			36.16,
			36.221,
			36.282,
			36.345,
			36.382,
			36.407,
			36.469,
			36.486,
			36.533,
			36.595,
			36.656,
			36.703,
			36.718,
			36.778,
			36.81,
			36.842,
			36.904,
			36.919,
			36.966,
			37.029,
			37.09,
			37.152,
			37.213,
			37.244,
			37.274,
			37.339,
			37.4,
			37.462,
			37.524,
			37.57,
			37.586,
			37.648,
			37.679,
			37.708,
			37.772,
			37.787,
			37.833,
			37.895,
			37.956,
			38.02,
			38.083,
			38.112,
			38.143,
			38.222,
			38.269,
			38.331,
			38.392,
			38.455,
			38.517,
			38.548,
			38.577,
			38.641,
			38.655,
			38.703,
			38.765,
			38.825,
			38.887,
			38.949,
			38.982,
			39.013,
			39.073,
			39.089,
			39.136,
			39.199,
			39.261,
			39.307,
			39.384,
			39.413,
			39.447,
			39.508,
			39.571,
			39.633,
			39.695,
			39.74,
			39.757,
			39.818,
			39.848,
			39.878,
			39.942,
			39.957,
			40.004,
			40.07,
			40.128,
			40.172,
			40.19,
			40.251,
			40.283,
			40.315,
			40.392,
			40.437,
			40.501,
			40.562,
			40.624,
			40.685,
			40.718,
			40.75,
			40.81,
			40.872,
			40.933,
			40.994,
			41.043,
			41.057,
			41.12,
			41.152,
			41.183,
			41.244,
			41.26,
			41.307,
			41.367,
			41.429,
			41.477,
			41.493,
			41.555,
			41.587,
			41.617,
			41.678,
			41.695,
			41.742,
			41.803,
			41.865,
			41.912,
			42.02,
			42.049,
			42.113,
			42.175,
			42.238,
			42.299,
			42.36,
			42.422,
			42.455,
			42.484,
			42.547,
			42.566,
			42.607,
			42.672,
			42.733,
			42.795,
			42.857,
			42.889,
			42.918,
			42.982,
			43.042,
			43.106,
			43.166,
			43.213,
			43.289,
			43.321,
			43.353,
			43.416,
			43.43,
			43.476,
			43.54,
			43.599,
			43.647,
			43.663,
			43.724,
			43.757,
			43.787,
			43.85,
			43.866,
			43.909,
			43.972,
			44.035,
			44.083,
			44.159,
			44.189,
			44.221,
			44.283,
			44.346,
			44.408,
			44.47,
			44.531,
			44.594,
			44.627,
			44.655,
			44.718,
			44.732,
			44.778,
			44.842,
			44.903,
			44.95,
			44.965,
			45.028,
			45.057,
			45.09,
			45.151,
			45.168,
			45.213,
			45.276,
			45.337,
			45.384,
			45.46,
			45.491,
			45.524,
			45.586,
			45.645,
			45.711,
			45.772,
			45.833,
			45.895,
			45.925,
			45.956,
			46.019,
			46.035,
			46.079,
			46.142,
			46.204,
			46.25,
			46.268,
			46.33,
			46.361,
			46.391,
			46.454,
			46.467,
			46.515,
			46.578,
			46.639,
			46.69,
			46.762,
			46.795,
			46.826,
			46.902,
			46.951,
			47.017,
			47.073,
			47.12,
			47.135,
			47.197,
			47.227,
			47.259,
			47.322,
			47.337,
			47.385,
			47.446,
			47.508,
			47.553,
			47.57,
			47.632,
			47.661,
			47.694,
			47.756,
			47.817,
			47.88,
			47.94,
			47.989,
			48.065,
			48.097,
			48.127,
			48.189,
			48.251,
			48.314,
			48.341
		]
	],
	'6340_6341_7982': [
		[
			127.961
		],
		[
			0
		],
		[
			0,
			1.722,
			3.122,
			7.33,
			10.145,
			12.966,
			17.212,
			20.051,
			24.319,
			25.745,
			27.174,
			31.468,
			34.338,
			38.657,
			41.544,
			44.438,
			45.888,
			48.791,
			50.245,
			53.158,
			56.078,
			57.54,
			60.47,
			63.405,
			67.82,
			69.296,
			70.772,
			75.211,
			76.693,
			78.178,
			82.64,
			85.623,
			88.611,
			93.105,
			96.109,
			100.627,
			102.136,
			103.646,
			108.185,
			111.218,
			115.778,
			118.826,
			121.879,
			123.407,
			126.468,
			128.001,
			131.071,
			134.146,
			135.686,
			138.77,
			141.86,
			146.504,
			149.608,
			154.273,
			155.83,
			157.389,
			162.073,
			165.202,
			168.336,
			173.044,
			176.186,
			180.905,
			182.48,
			184.057,
			188.797,
			190.382,
			191.968,
			196.739,
			199.927,
			204.714,
			207.909,
			211.109,
			212.711,
			215.919,
			217.525,
			220.738,
			223.954,
			228.785,
			232.01,
			236.854,
			238.471,
			240.089,
			244.949,
			248.195,
			253.071,
			256.327,
			261.219,
			264.486,
			266.12,
			267.756,
			274.31,
			277.594,
			280.882,
			284.174,
			287.471,
			289.122,
			292.425,
			295.733,
			297.388,
			302.361,
			305.682,
			309.007,
			314.001,
			317.336,
			322.347,
			324.019,
			325.693,
			330.719,
			334.075,
			339.117,
			342.483,
			345.853,
			347.54,
			350.916,
			352.606,
			355.988,
			359.374,
			361.069,
			364.461,
			367.858,
			372.96,
			376.366,
			381.482,
			383.19,
			384.898,
			388.319,
			390.03,
			393.456,
			396.886,
			402.038,
			405.478,
			410.644,
			412.368,
			414.093,
			419.272,
			422.73,
			427.922,
			431.388,
			434.857,
			436.592,
			440.063,
			441.799,
			445.27,
			448.737,
			450.466,
			453.914,
			457.338,
			462.414,
			465.751,
			470.683,
			472.307,
			473.921,
			478.696,
			481.829,
			486.456,
			489.496,
			492.503,
			496.949,
			498.412,
			499.865,
			504.168,
			506.988,
			511.145,
			513.868,
			516.553,
			517.88,
			520.507,
			523.095,
			524.375,
			528.157,
			530.632,
			533.068,
			536.653,
			538.998,
			542.45,
			543.58,
			544.694,
			547.98,
			550.116,
			553.242,
			555.282,
			557.288,
			558.277,
			560.231,
			561.198,
			563.104,
			564.974,
			565.897,
			567.719,
			569.507,
			571.264,
			572.985,
			573.846,
			575.514,
			577.147,
			577.95,
			580.309,
			581.839,
			583.335,
			585.516,
			586.923,
			588.973,
			589.641,
			590.303,
			592.225,
			593.478,
			595.328,
			596.53,
			597.711,
			598.3,
			599.474,
			600.062,
			601.238,
			602.41,
			602.996,
			604.169,
			605.342,
			607.124,
			608.319,
			609.511,
			610.701,
			611.3,
			612.539,
			613.156,
			614.385,
			615.608,
			617.474,
			618.726,
			620.587,
			621.204,
			621.826,
			623.706,
			624.948,
			626.8,
			628.075,
			629.974,
			631.235,
			631.865,
			633.166,
			634.464,
			635.111,
			636.406,
			637.709,
			639.67,
			640.975,
			642.941,
			643.604,
			644.265,
			646.245,
			647.564,
			648.901,
			650.9,
			652.23,
			654.282,
			654.974,
			655.664,
			657.731,
			659.102,
			661.144,
			662.501,
			663.86,
			664.539,
			665.892,
			667.242,
			667.917,
			669.913,
			671.228,
			672.541,
			674.533,
			675.862,
			677.837,
			678.494,
			679.174,
			681.269,
			682.657,
			684.757,
			686.171,
			687.577,
			689.694,
			690.413,
			691.129,
			693.265,
			693.975,
			694.679,
			696.746,
			698.116,
			699.485,
			700.179,
			701.566,
			702.946,
			703.636,
			705.014,
			705.695,
			707.054,
			708.414,
			710.457,
			711.818,
			713.867,
			714.553,
			715.238,
			717.289,
			718.664,
			720.702,
			722.023,
			723.353,
			724.022,
			725.359,
			725.991,
			727.287,
			728.598,
			729.26,
			730.594,
			731.91,
			733.892,
			734.549,
			735.203,
			736.486,
			737.734,
			738.346,
			739.542,
			740.127,
			741.271,
			742.385,
			743.995,
			745.035,
			746.553,
			747.05,
			747.545,
			749.021,
			750.008,
			751.465,
			752.425,
			753.851,
			754.795,
			755.265,
			756.201,
			757.145,
			757.614,
			758.545,
			759.467,
			760.84,
			761.768,
			763.197,
			763.669,
			764.14,
			765.08,
			765.549,
			766.486,
			767.535,
			769.12,
			770.173,
			771.756,
			772.41,
			773.074,
			775.065,
			776.396,
			778.886,
			780.687,
			782.593,
			783.68,
			785.863,
			789.428,
			791.934,
			793.004,
			795.08,
			797.175,
			799.46,
			800.99,
			803.165,
			803.794,
			804.424,
			806.331,
			807.62,
			809.409,
			810.605,
			811.814,
			812.424,
			813.638,
			814.244,
			814.853,
			816.699,
			817.322,
			817.947,
			819.778,
			821.01,
			822.881,
			824.106,
			825.337,
			825.956,
			827.201,
			827.827,
			829.081,
			830.336,
			832.229,
			833.5,
			835.468,
			836.126,
			836.784,
			838.781,
			840.178,
			842.276,
			843.679,
			845.836,
			847.276,
			847.997,
			849.438,
			850.882,
			851.604,
			853.039,
			854.491,
			855.954,
			857.431,
			858.175,
			859.677,
			861.221,
			862,
			863.57,
			864.361,
			865.982,
			867.638,
			870.154,
			871.884,
			874.539,
			875.427,
			876.305,
			878.946,
			880.719,
			883.353,
			885.114,
			886.878,
			887.763,
			889.54,
			890.432,
			892.229,
			894.046,
			894.959,
			896.795,
			898.654,
			901.472,
			902.417,
			903.362,
			906.217,
			907.177,
			908.14,
			911.049,
			913.009,
			915.984,
			917.986,
			919.993,
			923.03,
			924.042,
			925.052,
			928.101,
			929.121,
			930.139,
			933.212,
			935.291,
			937.391,
			938.446,
			940.583,
			941.662,
			943.829,
			946.009,
			947.104,
			949.297,
			951.466,
			954.723,
			956.88,
			960.16,
			961.266,
			962.376,
			965.742,
			967.981,
			971.384,
			973.655,
			975.936,
			977.076,
			979.365,
			980.514,
			982.812,
			985.119,
			986.276,
			987.449,
			990.918,
			993.243,
			995.576,
			996.746,
			999.11,
			1001.491,
			1002.685,
			1005.105,
			1006.32,
			1008.76,
			1011.222,
			1014.938,
			1017.436,
			1021.203,
			1022.465,
			1023.728,
			1027.533,
			1030.059,
			1033.897,
			1036.482,
			1039.043,
			1040.326,
			1042.892,
			1044.176,
			1046.747,
			1049.322,
			1050.611,
			1053.186,
			1055.76,
			1059.612,
			1060.896,
			1062.181,
			1064.74,
			1067.284,
			1068.57,
			1072.46,
			1075.058,
			1077.665,
			1081.606,
			1084.248,
			1088.251,
			1089.593,
			1090.944,
			1095.001,
			1097.708,
			1101.768,
			1104.473,
			1107.175,
			1108.526,
			1111.233,
			1113.937,
			1115.291,
			1117.99,
			1119.338,
			1122.014,
			1124.723,
			1128.759,
			1130.105,
			1131.453,
			1135.508,
			1136.863,
			1138.221,
			1142.304,
			1145.029,
			1147.758,
			1151.848,
			1154.576,
			1158.672,
			1160.035,
			1161.399,
			1165.482,
			1168.204,
			1172.302,
			1175.05,
			1177.812,
			1179.196,
			1181.994,
			1184.797,
			1186.202,
			1190.423,
			1193.234,
			1196.051,
			1200.28,
			1203.107,
			1207.365,
			1208.769,
			1210.198,
			1214.512,
			1217.401,
			1221.753,
			1224.663,
			1227.578,
			1231.962,
			1233.426,
			1234.892,
			1239.296,
			1240.767,
			1242.239,
			1246.664,
			1249.621,
			1252.584,
			1254.062,
			1256.968,
			1259.877,
			1261.332,
			1264.247,
			1265.767,
			1268.812,
			1271.908,
			1276.532,
			1279.584,
			1284.103,
			1285.611,
			1287.119,
			1291.651,
			1294.675,
			1299.213,
			1302.244,
			1305.28,
			1306.8,
			1309.842,
			1311.365,
			1314.414,
			1318.997,
			1322.057,
			1325.123,
			1328.198,
			1331.286,
			1332.834,
			1335.94,
			1339.058,
			1340.619,
			1343.745,
			1345.307,
			1348.43,
			1351.55,
			1356.227,
			1359.347,
			1364.041,
			1365.612,
			1367.187,
			1371.919,
			1375.077,
			1379.801,
			1382.947,
			1386.097,
			1387.674,
			1390.83,
			1392.41,
			1395.576,
			1400.351,
			1403.562,
			1406.774,
			1411.582,
			1414.776,
			1419.553,
			1421.144,
			1422.735,
			1427.507,
			1430.689,
			1435.469,
			1438.664,
			1441.847,
			1446.57,
			1448.124,
			1449.668,
			1454.251,
			1455.768,
			1457.284,
			1461.79,
			1464.758,
			1467.677,
			1469.12,
			1471.971,
			1473.379,
			1476.165,
			1478.91,
			1480.269,
			1482.956,
			1485.605,
			1489.504,
			1492.054,
			1495.807,
			1497.039,
			1498.262,
			1501.876,
			1504.241,
			1507.724,
			1509.999,
			1512.243,
			1513.351,
			1515.539,
			1516.618,
			1518.747,
			1520.836,
			1521.864,
			1522.881,
			1525.877,
			1527.825,
			1529.735,
			1530.676,
			1532.528,
			1534.346,
			1535.242,
			1537.013,
			1537.886,
			1539.607,
			1541.293,
			1543.767,
			1545.361,
			1547.682,
			1548.438,
			1549.184,
			1551.37,
			1552.783,
			1554.847,
			1556.188,
			1557.501,
			1558.149,
			1559.434,
			1560.7,
			1561.316,
			1563.146,
			1564.355,
			1565.562,
			1566.754,
			1567.944,
			1568.539,
			1569.729,
			1570.942,
			1571.574,
			1572.838,
			1573.471,
			1574.739,
			1576.047,
			1578.129,
			1579.521,
			1581.729,
			1582.507,
			1583.285,
			1585.627,
			1587.307,
			1589.83,
			1591.471,
			1593.069,
			1593.868,
			1595.465,
			1596.26,
			1597.846,
			1599.432,
			1600.225,
			1601.766,
			1603.283,
			1605.548,
			1606.238,
			1606.913,
			1608.936,
			1609.611,
			1610.285,
			1611.567,
			1612.193,
			1613.445,
			1614.697,
			1616.555,
			1617.774,
			1619.6,
			1620.208,
			1620.818,
			1622.7,
			1623.326,
			1623.952,
			1625.832,
			1627.127,
			1628.423,
			1629.072,
			1630.373,
			1631.029,
			1632.357,
			1633.689,
			1634.358,
			1635.698,
			1637.069,
			1639.142,
			1640.535,
			1642.654,
			1643.367,
			1644.083,
			1646.254,
			1647.723,
			1649.956,
			1651.465,
			1652.978,
			1653.746,
			1655.293,
			1656.073,
			1656.855,
			1659.225,
			1660.023,
			1660.824,
			1663.247,
			1664.88,
			1666.528,
			1667.358,
			1669.028,
			1670.711,
			1671.556,
			1673.246,
			1674.09,
			1675.784,
			1678.349,
			1680.075,
			1681.813,
			1684.442,
			1685.325,
			1686.211,
			1688.888,
			1690.689,
			1693.414,
			1695.246,
			1697.089,
			1698.014,
			1699.875,
			1700.809,
			1702.687,
			1705.526,
			1707.434,
			1709.354,
			1711.286,
			1713.23,
			1714.207,
			1716.168,
			1718.14,
			1719.13,
			1721.119,
			1722.118,
			1724.123,
			1726.141,
			1729.186,
			1731.23,
			1734.315,
			1735.349,
			1736.385,
			1739.507,
			1741.587,
			1744.72,
			1746.822,
			1748.935,
			1749.995,
			1752.123,
			1753.191,
			1755.335,
			1757.488,
			1758.568,
			1760.736,
			1762.91,
			1766.189,
			1767.286,
			1768.387,
			1771.702,
			1772.814,
			1773.929,
			1777.29,
			1779.547,
			1782.953,
			1785.239,
			1787.536,
			1791.023,
			1792.185,
			1793.35,
			1798.037,
			1799.215,
			1802.764,
			1805.143,
			1807.532,
			1808.73,
			1811.133,
			1812.338,
			1814.753,
			1817.176,
			1818.385,
			1820.808,
			1823.23,
			1826.856,
			1829.27,
			1832.881,
			1834.083,
			1835.282,
			1838.85,
			1841.198,
			1844.651,
			1846.945,
			1849.193,
			1850.307,
			1852.517,
			1853.621,
			1854.72,
			1858.01,
			1859.112,
			1860.214,
			1863.528,
			1865.748,
			1869.082,
			1871.307,
			1873.534,
			1874.648,
			1877.99,
			1880.218,
			1882.446,
			1885.787,
			1888.011,
			1891.344,
			1892.453,
			1893.562,
			1896.887,
			1899.103,
			1902.424,
			1904.638,
			1906.849,
			1907.955,
			1910.17,
			1911.276,
			1913.49,
			1916.819,
			1919.05,
			1921.291,
			1923.542,
			1925.816,
			1926.954,
			1929.244,
			1931.546,
			1932.697,
			1935.02,
			1936.183,
			1938.51,
			1940.843,
			1944.34,
			1946.667,
			1950.125,
			1951.269,
			1952.412,
			1955.801,
			1958.051,
			1961.398,
			1963.63,
			1965.866,
			1966.984,
			1969.239,
			1970.379,
			1972.656,
			1974.954,
			1976.105,
			1978.403,
			1980.698,
			1984.13,
			1985.271,
			1986.411,
			1989.833,
			1990.974,
			1992.116,
			1994.416,
			1995.576,
			1997.894,
			2001.446,
			2003.82,
			2006.201,
			2009.717,
			2010.865,
			2012.006,
			2015.348,
			2017.532,
			2020.733,
			2022.842,
			2024.943,
			2025.991,
			2028.082,
			2030.202,
			2031.265,
			2033.386,
			2034.463,
			2036.623,
			2038.78,
			2042.039,
			2044.209,
			2047.461,
			2048.544,
			2049.619,
			2052.844,
			2054.977,
			2058.167,
			2060.28,
			2062.393,
			2063.454,
			2065.563,
			2066.603,
			2067.663,
			2070.87,
			2071.936,
			2073.005,
			2076.221,
			2078.358,
			2081.576,
			2083.73,
			2085.876,
			2086.952,
			2089.109,
			2090.188,
			2092.352,
			2094.524,
			2097.787,
			2099.972,
			2103.261,
			2104.362,
			2105.463,
			2108.782,
			2111.004,
			2114.353,
			2116.597,
			2118.847,
			2119.976,
			2122.254,
			2123.384,
			2125.66,
			2127.94,
			2129.078,
			2130.239,
			2133.718,
			2136.063,
			2139.561,
			2141.871,
			2144.151,
			2145.28,
			2147.512,
			2148.616,
			2150.8,
			2152.957,
			2156.163,
			2158.288,
			2161.484,
			2162.551,
			2163.618,
			2166.827,
			2168.967,
			2172.181,
			2174.323,
			2176.449,
			2177.511,
			2179.633,
			2180.685,
			2182.787,
			2184.885,
			2185.926,
			2188.007,
			2190.083,
			2193.167,
			2194.195,
			2195.228,
			2197.301,
			2199.373,
			2200.428,
			2203.611,
			2205.733,
			2207.856,
			2211.025,
			2213.124,
			2216.267,
			2218.36,
			2221.501,
			2223.596,
			2226.766,
			2228.896,
			2231.041,
			2232.119,
			2234.284,
			2235.365,
			2237.517,
			2239.679,
			2240.755,
			2242.907,
			2245.068,
			2248.301,
			2250.467,
			2253.724,
			2254.814,
			2255.903,
			2259.179,
			2261.359,
			2264.63,
			2266.807,
			2268.99,
			2270.084,
			2272.268,
			2273.363,
			2274.459,
			2277.755,
			2278.857,
			2279.96,
			2283.302,
			2285.541,
			2287.804,
			2288.937,
			2291.214,
			2293.505,
			2294.652,
			2296.947,
			2298.096,
			2300.4,
			2302.703,
			2306.178,
			2308.516,
			2312.043,
			2313.223,
			2314.406,
			2317.944,
			2320.324,
			2323.948,
			2326.367,
			2330.015,
			2332.476,
			2333.71,
			2336.183,
			2338.662,
			2339.904,
			2342.391,
			2344.885,
			2347.386,
			2349.895,
			2351.153,
			2353.673,
			2356.202,
			2357.469,
			2361.283,
			2363.836,
			2366.397,
			2370.253,
			2372.834,
			2376.721,
			2378.021,
			2379.345,
			2383.239,
			2385.872,
			2389.796,
			2392.396,
			2395.021,
			2396.357,
			2398.966,
			2400.283,
			2402.921,
			2405.565,
			2406.889,
			2409.541,
			2412.198,
			2416.198,
			2417.535,
			2418.873,
			2421.553,
			2424.24,
			2425.585,
			2429.632,
			2432.338,
			2435.051,
			2439.132,
			2441.861,
			2445.966,
			2447.337,
			2448.709,
			2452.833,
			2455.587,
			2459.727,
			2462.496,
			2465.271,
			2466.661,
			2469.445,
			2470.834,
			2473.602,
			2476.345,
			2477.705,
			2480.399,
			2483.054,
			2486.967,
			2489.53,
			2493.306,
			2494.547,
			2495.78,
			2499.423,
			2501.809,
			2505.323,
			2507.617,
			2509.868,
			2510.98,
			2513.173,
			2514.256,
			2515.329,
			2518.519,
			2519.56,
			2520.593,
			2523.642,
			2525.633,
			2527.588,
			2528.553,
			2530.458,
			2532.331,
			2533.256,
			2535.09,
			2536,
			2537.807,
			2539.601,
			2542.283,
			2544.055,
			2546.705,
			2547.588,
			2548.471,
			2551.119,
			2552.906,
			2555.615,
			2557.447,
			2559.347,
			2560.299,
			2562.209,
			2563.182,
			2564.156,
			2567.086,
			2568.072,
			2570.05,
			2572.037,
			2574.071,
			2576.111,
			2577.138,
			2579.214,
			2581.299,
			2582.341,
			2584.398,
			2585.429,
			2587.485,
			2589.481,
			2592.468,
			2594.389,
			2597.25,
			2598.157,
			2599.061,
			2601.741,
			2603.511,
			2606.139,
			2607.898,
			2609.684,
			2610.573,
			2612.346,
			2613.239,
			2615.018,
			2616.787,
			2617.673,
			2619.446,
			2621.209,
			2623.852,
			2624.73,
			2625.608,
			2627.358,
			2629.104,
			2629.975,
			2631.716,
			2632.584,
			2634.31,
			2636.034,
			2638.609,
			2640.317,
			2642.874,
			2643.718,
			2644.562,
			2647.097,
			2648.783,
			2651.314,
			2653.004,
			2655.539,
			2657.234,
			2658.084,
			2659.786,
			2661.494,
			2662.351,
			2664.104,
			2665.866,
			2668.557,
			2670.395,
			2673.213,
			2674.2,
			2675.191,
			2678.254,
			2680.474,
			2683.843,
			2686.118,
			2688.362,
			2689.451,
			2691.637,
			2692.729,
			2693.78,
			2697.987,
			2698.988,
			2702.007,
			2703.958,
			2705.882,
			2706.847,
			2708.776,
			2710.705,
			2711.671,
			2714.623,
			2716.611,
			2718.613,
			2721.637,
			2723.666,
			2726.73,
			2727.755,
			2728.79,
			2731.901,
			2733.981,
			2737.118,
			2739.226,
			2741.346,
			2742.409,
			2744.546,
			2745.618,
			2747.77,
			2751.012,
			2753.181,
			2755.354,
			2757.537,
			2759.732,
			2760.833,
			2763.025,
			2765.248,
			2766.363,
			2769.725,
			2771.979,
			2774.242,
			2777.654,
			2779.94,
			2783.387,
			2784.541,
			2785.697,
			2789.179,
			2791.511,
			2795.028,
			2797.405,
			2799.771,
			2800.938,
			2803.317,
			2804.51,
			2806.904,
			2809.307,
			2810.511,
			2812.928,
			2815.352,
			2818.999,
			2820.218,
			2821.438,
			2823.887,
			2826.344,
			2827.576,
			2830.046,
			2831.284,
			2833.766,
			2836.258,
			2840.011,
			2842.525,
			2846.309,
			2847.575,
			2848.842,
			2853.933,
			2855.211,
			2859.058,
			2861.635,
			2864.22,
			2865.518,
			2868.118,
			2869.422,
			2872.035,
			2874.648,
			2875.957,
			2878.577,
			2881.206,
			2885.163,
			2887.81,
			2891.793,
			2893.125,
			2894.458,
			2898.468,
			2901.148,
			2905.181,
			2907.877,
			2911.932,
			2914.644,
			2916.001,
			2917.361,
			2921.449,
			2924.183,
			2928.296,
			2931.047,
			2935.185,
			2937.952,
			2940.725,
			2942.115,
			2944.899,
			2946.294,
			2949.09,
			2951.893,
			2956.11,
			2958.93,
			2963.174,
			2964.592,
			2966.012,
			2970.283,
			2973.14,
			2977.439,
			2980.314,
			2984.639,
			2987.531,
			2988.98,
			2990.43,
			2996.25,
			2997.71,
			3002.099,
			3005.033,
			3009.448,
			3012.4,
			3015.359,
			3016.84,
			3019.809,
			3021.295,
			3024.273,
			3027.253,
			3031.712,
			3034.663,
			3039.036,
			3040.476,
			3041.906,
			3046.14,
			3048.914,
			3053.007,
			3055.69,
			3058.335,
			3059.643,
			3062.23,
			3063.51,
			3066.041,
			3068.535,
			3069.768,
			3072.206,
			3074.608,
			3078.146,
			3080.462,
			3083.865,
			3084.981,
			3086.087,
			3089.331,
			3091.446,
			3093.512,
			3096.542,
			3098.525,
			3101.43,
			3102.386,
			3103.334,
			3106.12,
			3107.937,
			3110.598,
			3112.329,
			3114.024,
			3114.857,
			3116.496,
			3117.302,
			3118.886,
			3120.433,
			3121.193,
			3122.687,
			3124.144,
			3126.262,
			3127.631,
			3129.621,
			3130.268,
			3130.907,
			3132.773,
			3133.971,
			3135.143,
			3136.82,
			3137.89,
			3139.429,
			3139.927,
			3140.418,
			3141.849,
			3142.31,
			3142.763,
			3144.091,
			3144.955,
			3145.807,
			3146.229,
			3147.059,
			3147.869,
			3148.27,
			3149.462,
			3150.253,
			3151.045,
			3152.247,
			3153.051,
			3154.253,
			3154.654,
			3155.056,
			3156.269,
			3157.123,
			3158.473,
			3159.372,
			3160.275,
			3160.73,
			3161.646,
			3162.171,
			3163.258,
			3164.887,
			3165.977,
			3167.132,
			3168.432,
			3169.728,
			3170.377,
			3171.681,
			3173.013,
			3173.681,
			3175.695,
			3177.025,
			3178.267,
			3180.142,
			3181.404,
			3183.211,
			3183.808,
			3184.408,
			3186.222,
			3186.833,
			3187.424,
			3189.201,
			3190.395,
			3191.602,
			3192.175,
			3193.285,
			3193.842,
			3194.958,
			3196.08,
			3196.645,
			3197.741,
			3198.833,
			3200.481,
			3201.586,
			3203.316,
			3203.898,
			3204.481,
			3205.65,
			3206.236,
			3207.421,
			3208.619,
			3210.421,
			3211.652,
			3213.492,
			3214.105,
			3214.723,
			3216.595,
			3217.887,
			3219.871,
			3221.21,
			3222.572,
			3223.262,
			3224.653,
			3225.354,
			3226.768,
			3228.189,
			3228.905,
			3230.334,
			3231.793,
			3234.004,
			3234.748,
			3235.497,
			3237.769,
			3238.538,
			3239.311,
			3241.652,
			3243.237,
			3245.646,
			3247.27,
			3248.908,
			3249.733,
			3251.393,
			3252.228,
			3253.078,
			3255.65,
			3256.513,
			3257.379,
			3260.039,
			3261.829,
			3264.489,
			3266.295,
			3268.098,
			3269.007,
			3270.828,
			3271.742,
			3273.585,
			3275.445,
			3278.257,
			3280.148,
			3282.999,
			3283.947,
			3284.896,
			3287.757,
			3289.656,
			3292.519,
			3294.445,
			3297.35,
			3299.317,
			3300.307,
			3301.299,
			3304.288,
			3305.288,
			3306.291,
			3309.301,
			3311.309,
			3313.323,
			3314.329,
			3316.345,
			3318.363,
			3319.358,
			3321.353,
			3322.351,
			3324.328,
			3326.29,
			3329.224,
			3331.162,
			3334.064,
			3335.024,
			3335.984,
			3338.869,
			3340.788,
			3343.68,
			3345.627,
			3347.582,
			3348.564,
			3350.552,
			3351.549,
			3353.554,
			3356.598,
			3358.64,
			3360.684,
			3363.766,
			3364.789,
			3365.813,
			3367.86,
			3369.895,
			3370.911,
			3373.974,
			3376.018,
			3378.072,
			3381.199,
			3383.299,
			3386.516,
			3387.596,
			3388.677,
			3391.938,
			3394.123,
			3397.408,
			3399.609,
			3401.817,
			3402.923,
			3405.126,
			3406.224,
			3408.424,
			3410.62,
			3411.718,
			3413.916,
			3416.109,
			3419.403,
			3421.569,
			3424.817,
			3425.888,
			3426.959,
			3429.103,
			3430.179,
			3432.328,
			3434.474,
			3437.712,
			3439.863,
			3443.079,
			3444.133,
			3445.183,
			3448.298,
			3449.313,
			3450.31,
			3453.231,
			3455.14,
			3457.003,
			3457.937,
			3459.748,
			3461.516,
			3462.388,
			3464.111,
			3464.963,
			3466.647,
			3468.303,
			3470.766,
			3472.396,
			3474.813,
			3475.625,
			3476.434,
			3478.848,
			3480.454,
			3482.861,
			3484.467,
			3486.066,
			3486.865,
			3488.463,
			3489.263,
			3490.847,
			3492.421,
			3493.209,
			3493.999,
			3496.345,
			3497.898,
			3499.454,
			3500.233,
			3501.792,
			3503.351,
			3504.13,
			3505.714,
			3506.519,
			3508.128,
			3509.736,
			3512.274,
			3513.974,
			3516.553,
			3517.418,
			3518.282,
			3520.872,
			3522.598,
			3525.186,
			3527.028,
			3529.786,
			3531.75,
			3532.751,
			3534.75,
			3536.709,
			3537.68,
			3539.62,
			3541.485,
			3544.226,
			3546.038,
			3547.818,
			3549.605,
			3550.498,
			3552.212,
			3553.071,
			3554.794,
			3556.484,
			3558.99,
			3560.663,
			3563.16,
			3563.994,
			3564.829,
			3567.354,
			3569.045,
			3571.603,
			3573.325,
			3575.055,
			3575.923,
			3577.671,
			3578.547,
			3580.307,
			3582.081,
			3582.972,
			3584.762,
			3586.563,
			3589.284,
			3590.197,
			3591.111,
			3593.857,
			3594.778,
			3595.703,
			3598.511,
			3600.398,
			3603.288,
			3605.234,
			3607.201,
			3610.183,
			3611.183,
			3612.17,
			3615.201,
			3616.217,
			3617.252,
			3620.323,
			3622.368,
			3625.484,
			3627.574,
			3629.675,
			3630.726,
			3632.824,
			3633.875,
			3635.982,
			3638.098,
			3641.29,
			3643.428,
			3646.655,
			3647.736,
			3648.818,
			3652.082,
			3654.269,
			3657.567,
			3659.777,
			3661.996,
			3663.109,
			3665.343,
			3666.464,
			3668.712,
			3670.97,
			3672.103,
			3673.238,
			3676.656,
			3678.948,
			3682.405,
			3684.72,
			3687.045,
			3688.211,
			3691.724,
			3694.077,
			3696.439,
			3700.002,
			3702.389,
			3705.988,
			3707.193,
			3708.4,
			3712.036,
			3714.472,
			3718.145,
			3720.606,
			3724.316,
			3726.801,
			3728.048,
			3730.549,
			3733.06,
			3734.32,
			3736.846,
			3739.383,
			3743.208,
			3744.49,
			3745.773,
			3748.346,
			3750.93,
			3752.222,
			3754.804,
			3756.092,
			3758.653,
			3761.187,
			3764.941,
			3767.381,
			3770.973,
			3772.152,
			3773.323,
			3776.783,
			3779.051,
			3782.411,
			3784.631,
			3786.838,
			3787.939,
			3790.131,
			3791.222,
			3793.402,
			3795.569,
			3796.649,
			3798.809,
			3800.96,
			3804.184,
			3805.258,
			3806.333,
			3809.554,
			3810.631,
			3811.708,
			3814.936,
			3817.096,
			3819.256,
			3822.507,
			3824.673,
			3827.971,
			3829.069,
			3830.185,
			3833.587,
			3834.721,
			3835.879,
			3839.352,
			3841.684,
			3844.018,
			3845.196,
			3847.595,
			3848.794,
			3851.233,
			3853.708,
			3854.945,
			3857.438,
			3859.929,
			3863.655,
			3864.896,
			3866.125,
			3869.808,
			3871.028,
			3872.246,
			3875.885,
			3878.266,
			3881.804,
			3884.133,
			3886.439,
			3887.577,
			3889.859,
			3890.994,
			3892.116,
			3895.499,
			3896.617,
			3897.737,
			3901.108,
			3903.363,
			3905.627,
			3906.765,
			3909.049,
			3911.345,
			3912.5,
			3914.82,
			3915.983,
			3918.328,
			3920.684,
			3924.25,
			3926.643,
			3930.239,
			3931.45,
			3932.666,
			3936.328,
			3938.789,
			3942.501,
			3944.991,
			3947.495,
			3948.752,
			3951.275,
			3952.546,
			3955.1,
			3958.945,
			3961.541,
			3964.099,
			3968.019,
			3969.33,
			3970.642,
			3973.294,
			3975.974,
			3977.32,
			3981.394,
			3984.125,
			3986.864,
			3990.979,
			3993.722,
			3997.842,
			3999.219,
			4000.597,
			4004.738,
			4007.503,
			4011.662,
			4014.443,
			4018.625,
			4021.424,
			4022.825,
			4025.615,
			4029.793,
			4032.566,
			4035.325,
			4039.441,
			4040.804,
			4042.164,
			4046.201,
			4047.533,
			4048.86,
			4052.802,
			4055.407,
			4059.26,
			4061.793,
			4064.301,
			4068.056,
			4069.309,
			4070.573,
			4074.356,
			4076.894,
			4080.688,
			4083.203,
			4085.666,
			4086.883,
			4089.309,
			4091.674,
			4092.853,
			4095.195,
			4096.357,
			4098.665,
			4100.95,
			4104.305,
			4106.512,
			4109.771,
			4110.834,
			4111.889,
			4115,
			4117.04,
			4120.035,
			4121.981,
			4123.909,
			4124.859,
			4126.73,
			4127.645,
			4128.55,
			4131.177,
			4132.034,
			4132.882,
			4135.374,
			4136.984,
			4138.562,
			4139.337,
			4140.874,
			4142.367,
			4143.106,
			4144.568,
			4145.293,
			4146.73,
			4148.161,
			4150.306,
			4151.733,
			4153.871,
			4154.583,
			4155.296,
			4157.42,
			4158.833,
			4160.954,
			4162.374,
			4163.79,
			4164.498,
			4165.919,
			4166.636,
			4168.064,
			4169.491,
			4170.204,
			4171.622,
			4173.032,
			4174.44,
			4175.846,
			4176.546,
			4177.94,
			4179.33,
			4180.023,
			4181.416,
			4182.113,
			4183.502,
			4184.888,
			4186.979,
			4188.376,
			4190.467,
			4191.17,
			4191.877,
			4193.996,
			4195.408,
			4197.556,
			4198.992,
			4201.155,
			4202.626,
			4203.361,
			4204.832,
			4206.31,
			4207.079,
			4208.615,
			4210.148,
			4212.54,
			4213.364,
			4214.188,
			4216.675,
			4217.545,
			4218.414,
			4221.019,
			4222.805,
			4224.608,
			4227.306,
			4229.093,
			4231.78,
			4232.669,
			4233.561,
			4236.246,
			4237.979,
			4240.556,
			4242.243,
			4243.839,
			4244.64,
			4246.246,
			4247.807,
			4248.574,
			4250.88,
			4252.423,
			4253.969,
			4256.294,
			4257.849,
			4260.183,
			4260.963,
			4261.745,
			4264.059,
			4265.605,
			4267.934,
			4269.487,
			4271.05,
			4271.835,
			4273.441,
			4274.256,
			4275.895,
			4277.547,
			4278.402,
			4279.26,
			4281.853,
			4283.608,
			4286.274,
			4288.069,
			4289.879,
			4290.787,
			4292.611,
			4293.527,
			4295.366,
			4297.214,
			4299.995,
			4301.87,
			4304.693,
			4305.639,
			4306.588,
			4309.453,
			4311.378,
			4314.29,
			4316.246,
			4318.23,
			4319.22,
			4321.189,
			4322.202,
			4323.201,
			4327.194,
			4329.214,
			4331.248,
			4333.293,
			4335.351,
			4336.383,
			4338.457,
			4340.538,
			4341.581,
			4344.725,
			4346.826,
			4348.926,
			4352.088,
			4354.209,
			4357.413,
			4358.486,
			4359.562,
			4362.808,
			4364.985,
			4368.269,
			4370.469,
			4373.786,
			4376.007,
			4377.12,
			4379.353,
			4381.593,
			4382.716,
			4384.97,
			4387.233,
			4390.646,
			4391.788,
			4392.933,
			4396.381,
			4397.536,
			4398.693,
			4402.178,
			4404.514,
			4406.859,
			4408.035,
			4410.394,
			4412.762,
			4416.331,
			4417.526,
			4418.724,
			4423.534,
			4424.743,
			4428.38,
			4430.814,
			4433.258,
			4434.482,
			4436.939,
			4438.171,
			4440.641,
			4443.121,
			4444.365,
			4446.858,
			4449.362,
			4453.135,
			4455.662,
			4459.468,
			4460.741,
			4462.017,
			4465.855,
			4468.424,
			4472.292,
			4474.881,
			4477.478,
			4478.779,
			4481.383,
			4482.684,
			4483.986,
			4487.901,
			4489.21,
			4490.521,
			4494.465,
			4497.104,
			4501.076,
			4503.733,
			4506.399,
			4507.735,
			4510.411,
			4511.752,
			4514.439,
			4517.133,
			4521.188,
			4523.9,
			4527.981,
			4529.345,
			4530.711,
			4534.819,
			4537.566,
			4541.7,
			4544.464,
			4547.235,
			4548.624,
			4551.405,
			4552.799,
			4555.59,
			4559.791,
			4562.601,
			4565.417,
			4569.653,
			4571.069,
			4572.486,
			4575.325,
			4578.172,
			4579.598,
			4583.884,
			4586.75,
			4589.623,
			4593.944,
			4596.833,
			4601.178,
			4602.63,
			4604.084,
			4608.455,
			4611.376,
			4615.77,
			4618.706,
			4621.65,
			4623.124,
			4626.077,
			4629.037,
			4630.519,
			4633.487,
			4634.974,
			4637.952,
			4640.936,
			4645.423,
			4646.921,
			4648.421,
			4652.931,
			4654.437,
			4655.944,
			4660.475,
			4663.503,
			4668.058,
			4671.101,
			4674.149,
			4678.733,
			4680.264,
			4681.796,
			4686.401,
			4687.938,
			4689.478,
			4694.102,
			4697.192,
			4700.288,
			4701.838,
			4704.942,
			4708.051,
			4709.607,
			4712.726,
			4714.286,
			4717.412,
			4720.542,
			4725.248,
			4728.392,
			4733.116,
			4734.693,
			4736.272,
			4741.017,
			4744.185,
			4748.947,
			4752.127,
			4755.313,
			4756.907,
			4760.094,
			4761.688,
			4763.284,
			4769.68,
			4771.281,
			4776.092,
			4779.305,
			4782.521,
			4784.131,
			4787.354,
			4790.581,
			4792.196,
			4795.43,
			4797.048,
			4800.287,
			4803.532,
			4808.405,
			4811.66,
			4816.551,
			4818.183,
			4819.816,
			4824.721,
			4827.996,
			4832.917,
			4836.202,
			4841.139,
			4844.435,
			4846.084,
			4849.386,
			4852.693,
			4854.348,
			4857.66,
			4860.977,
			4864.297,
			4867.621,
			4869.285,
			4872.615,
			4875.95,
			4877.619,
			4880.959,
			4882.631,
			4885.978,
			4889.328,
			4891.005,
			4894.362,
			4897.722,
			4902.771,
			4904.456,
			4906.141,
			4911.204,
			4914.583,
			4919.661,
			4923.051,
			4926.444,
			4928.142,
			4931.541,
			4933.242,
			4936.646,
			4940.054,
			4941.76,
			4945.173,
			4948.59,
			4953.711,
			4955.414,
			4957.113,
			4962.177,
			4963.849,
			4965.513,
			4968.812,
			4970.446,
			4973.686,
			4976.886,
			4981.614,
			4984.717,
			4989.3,
			4990.809,
			4992.309,
			4996.751,
			4999.663,
			5003.959,
			5006.775,
			5009.553,
			5010.928,
			5013.648,
			5016.331,
			5017.658,
			5020.284,
			5021.582,
			5024.149,
			5026.679,
			5030.401,
			5032.835,
			5036.418,
			5037.596,
			5038.764,
			5042.217,
			5044.47,
			5047.776,
			5049.934,
			5053.101,
			5055.165,
			5056.183,
			5057.192,
			5060.161,
			5061.133,
			5062.095,
			5064.925,
			5066.767,
			5068.575,
			5069.466,
			5071.223,
			5072.943,
			5073.79,
			5075.455,
			5076.274,
			5077.884,
			5079.458,
			5081.765,
			5083.247,
			5085.403,
			5086.104,
			5086.795,
			5088.823,
			5090.132,
			5092.042,
			5093.281,
			5094.492,
			5095.084,
			5096.252,
			5097.971,
			5099.102,
			5099.655,
			5100.745,
			5101.827,
			5102.905,
			5104.52,
			5105.595,
			5106.665,
			5107.2,
			5108.269,
			5108.804,
			5109.897,
			5111.034,
			5112.738,
			5113.879,
			5115.707,
			5116.343,
			5116.981,
			5118.904,
			5120.253,
			5122.474,
			5123.96,
			5126.27,
			5127.847,
			5128.636,
			5130.214,
			5131.757,
			5132.527,
			5134.068,
			5135.604,
			5137.886,
			5138.644,
			5139.401,
			5141.766,
			5142.565,
			5143.364,
			5144.962,
			5145.783,
			5147.431,
			5149.077,
			5151.47,
			5153.021,
			5155.341,
			5156.076,
			5156.81,
			5159.005,
			5160.457,
			5162.484,
			5163.83,
			5165.173,
			5165.851,
			5167.214,
			5167.894,
			5169.25,
			5170.609,
			5171.315,
			5172.729,
			5174.143,
			5176.261,
			5176.959,
			5177.656,
			5179.751,
			5180.452,
			5181.145,
			5183.18,
			5184.542,
			5186.586,
			5187.935,
			5189.291,
			5189.972,
			5191.34,
			5192.014,
			5192.69,
			5195.42,
			5196.11,
			5198.147,
			5199.517,
			5200.899,
			5201.591,
			5202.953,
			5204.327,
			5205.02,
			5207.106,
			5208.487,
			5209.883,
			5211.995,
			5213.4,
			5215.531,
			5216.249,
			5216.97,
			5219.158,
			5220.633,
			5222.958,
			5224.6,
			5226.255,
			5227.089,
			5228.894,
			5229.799,
			5231.619,
			5233.42,
			5234.305,
			5236.114,
			5237.915,
			5239.703,
			5241.489,
			5242.387,
			5244.181,
			5245.988,
			5246.896,
			5248.71,
			5249.63,
			5251.495,
			5253.342,
			5256.159,
			5258.051,
			5260.911,
			5261.869,
			5262.832,
			5265.727,
			5267.671,
			5270.598,
			5272.559,
			5274.529,
			5275.517,
			5277.498,
			5278.493,
			5280.494,
			5282.506,
			5283.513,
			5285.539,
			5287.575,
			5290.648,
			5291.676,
			5292.707,
			5294.776,
			5296.858,
			5297.902,
			5299.999,
			5301.049,
			5303.147,
			5305.25,
			5308.426,
			5310.555,
			5313.766,
			5314.841,
			5315.92,
			5319.173,
			5321.374,
			5324.655,
			5326.869,
			5329.093,
			5330.208,
			5332.443,
			5333.564,
			5335.813,
			5338.066,
			5339.196,
			5341.462,
			5343.734,
			5347.16,
			5349.453,
			5352.91,
			5354.067,
			5355.226,
			5358.717,
			5361.057,
			5364.585,
			5366.949,
			5369.323,
			5372.903,
			5374.101,
			5375.3,
			5378.915,
			5380.124,
			5381.335,
			5384.981,
			5387.418,
			5389.865,
			5391.087,
			5393.54,
			5396,
			5397.234,
			5400.95,
			5403.441,
			5405.941,
			5409.715,
			5412.242,
			5416.051,
			5417.325,
			5418.603,
			5422.444,
			5425.018,
			5428.893,
			5431.487,
			5435.407,
			5438.032,
			5439.348,
			5441.984,
			5445.946,
			5447.27,
			5451.243,
			5455.221,
			5456.548,
			5457.876,
			5460.537,
			5463.204,
			5464.54,
			5467.215,
			5468.556,
			5471.244,
			5473.938,
			5477.99,
			5480.698,
			5484.772,
			5486.133,
			5487.496,
			5491.596,
			5494.338,
			5498.468,
			5501.229,
			5503.999,
			5505.385,
			5508.164,
			5509.556,
			5512.346,
			5515.143,
			5516.543,
			5519.35,
			5522.164,
			5526.403,
			5527.817,
			5528.126
		],
		[
			0,
			0.021,
			0.053,
			0.129,
			0.176,
			0.243,
			0.3,
			0.347,
			0.426,
			0.457,
			0.488,
			0.55,
			0.61,
			0.673,
			0.736,
			0.783,
			0.797,
			0.858,
			0.891,
			0.922,
			0.983,
			0.999,
			1.046,
			1.107,
			1.168,
			1.215,
			1.231,
			1.294,
			1.33,
			1.355,
			1.418,
			1.479,
			1.541,
			1.602,
			1.65,
			1.727,
			1.758,
			1.788,
			1.867,
			1.913,
			1.976,
			2.038,
			2.084,
			2.1,
			2.162,
			2.192,
			2.223,
			2.285,
			2.3,
			2.348,
			2.41,
			2.471,
			2.518,
			2.594,
			2.625,
			2.658,
			2.721,
			2.781,
			2.843,
			2.906,
			2.968,
			3.029,
			3.059,
			3.093,
			3.155,
			3.168,
			3.216,
			3.278,
			3.341,
			3.401,
			3.463,
			3.494,
			3.524,
			3.587,
			3.604,
			3.65,
			3.712,
			3.774,
			3.821,
			3.897,
			3.929,
			3.96,
			4.037,
			4.083,
			4.147,
			4.208,
			4.269,
			4.332,
			4.363,
			4.393,
			4.471,
			4.519,
			4.579,
			4.643,
			4.688,
			4.704,
			4.766,
			4.797,
			4.826,
			4.906,
			4.952,
			5.014,
			5.077,
			5.137,
			5.2,
			5.231,
			5.262,
			5.322,
			5.387,
			5.453,
			5.511,
			5.555,
			5.571,
			5.634,
			5.665,
			5.696,
			5.757,
			5.775,
			5.819,
			5.883,
			5.944,
			6.007,
			6.069,
			6.099,
			6.131,
			6.192,
			6.208,
			6.254,
			6.316,
			6.378,
			6.426,
			6.502,
			6.533,
			6.565,
			6.627,
			6.688,
			6.752,
			6.813,
			6.858,
			6.875,
			6.936,
			6.967,
			6.999,
			7.06,
			7.077,
			7.122,
			7.183,
			7.245,
			7.293,
			7.371,
			7.402,
			7.433,
			7.494,
			7.557,
			7.619,
			7.679,
			7.727,
			7.805,
			7.836,
			7.866,
			7.943,
			7.99,
			8.053,
			8.113,
			8.161,
			8.175,
			8.239,
			8.269,
			8.299,
			8.378,
			8.425,
			8.486,
			8.55,
			8.595,
			8.673,
			8.703,
			8.735,
			8.797,
			8.857,
			8.921,
			8.983,
			9.028,
			9.045,
			9.108,
			9.136,
			9.169,
			9.229,
			9.246,
			9.294,
			9.355,
			9.417,
			9.464,
			9.48,
			9.54,
			9.575,
			9.603,
			9.68,
			9.726,
			9.789,
			9.85,
			9.896,
			9.976,
			10.005,
			10.035,
			10.099,
			10.161,
			10.224,
			10.283,
			10.331,
			10.347,
			10.407,
			10.441,
			10.471,
			10.533,
			10.546,
			10.594,
			10.661,
			10.719,
			10.782,
			10.842,
			10.873,
			10.906,
			10.966,
			10.983,
			11.028,
			11.091,
			11.152,
			11.213,
			11.278,
			11.307,
			11.339,
			11.402,
			11.463,
			11.524,
			11.587,
			11.65,
			11.711,
			11.741,
			11.773,
			11.835,
			11.85,
			11.897,
			11.96,
			12.021,
			12.068,
			12.144,
			12.176,
			12.206,
			12.27,
			12.331,
			12.392,
			12.456,
			12.502,
			12.58,
			12.609,
			12.641,
			12.704,
			12.766,
			12.827,
			12.888,
			12.936,
			12.951,
			13.014,
			13.043,
			13.074,
			13.153,
			13.2,
			13.261,
			13.322,
			13.37,
			13.448,
			13.477,
			13.51,
			13.572,
			13.634,
			13.694,
			13.757,
			13.804,
			13.882,
			13.912,
			13.942,
			14.005,
			14.022,
			14.066,
			14.129,
			14.191,
			14.238,
			14.252,
			14.316,
			14.346,
			14.377,
			14.44,
			14.454,
			14.502,
			14.563,
			14.625,
			14.673,
			14.751,
			14.781,
			14.81,
			14.872,
			14.935,
			14.996,
			15.059,
			15.105,
			15.121,
			15.183,
			15.213,
			15.246,
			15.308,
			15.324,
			15.37,
			15.432,
			15.493,
			15.54,
			15.556,
			15.616,
			15.65,
			15.679,
			15.742,
			15.76,
			15.803,
			15.866,
			15.927,
			15.975,
			16.052,
			16.084,
			16.113,
			16.175,
			16.238,
			16.299,
			16.361,
			16.423,
			16.486,
			16.516,
			16.549,
			16.61,
			16.624,
			16.672,
			16.735,
			16.794,
			16.858,
			16.921,
			16.951,
			16.983,
			17.043,
			17.058,
			17.106,
			17.167,
			17.228,
			17.275,
			17.354,
			17.385,
			17.416,
			17.477,
			17.539,
			17.602,
			17.663,
			17.711,
			17.727,
			17.788,
			17.85,
			17.913,
			17.927,
			17.975,
			18.036,
			18.096,
			18.144,
			18.221,
			18.252,
			18.284,
			18.347,
			18.407,
			18.471,
			18.533,
			18.577,
			18.595,
			18.656,
			18.686,
			18.718,
			18.781,
			18.794,
			18.841,
			18.906,
			18.966,
			19.028,
			19.089,
			19.122,
			19.152,
			19.215,
			19.228,
			19.276,
			19.338,
			19.401,
			19.463,
			19.523,
			19.555,
			19.586,
			19.649,
			19.711,
			19.772,
			19.835,
			19.897,
			19.958,
			19.991,
			20.021,
			20.082,
			20.099,
			20.146,
			20.206,
			20.268,
			20.313,
			20.331,
			20.392,
			20.422,
			20.455,
			20.516,
			20.533,
			20.578,
			20.64,
			20.702,
			20.75,
			20.827,
			20.857,
			20.888,
			20.967,
			21.011,
			21.074,
			21.136,
			21.183,
			21.199,
			21.261,
			21.293,
			21.324,
			21.385,
			21.401,
			21.447,
			21.509,
			21.572,
			21.617,
			21.633,
			21.693,
			21.725,
			21.758,
			21.82,
			21.879,
			21.946,
			22.005,
			22.05,
			22.129,
			22.16,
			22.189,
			22.252,
			22.269,
			22.316,
			22.377,
			22.438,
			22.485,
			22.499,
			22.561,
			22.593,
			22.625,
			22.687,
			22.702,
			22.749,
			22.811,
			22.872,
			22.92,
			22.998,
			23.028,
			23.058,
			23.121,
			23.183,
			23.246,
			23.307,
			23.354,
			23.368,
			23.432,
			23.461,
			23.493,
			23.556,
			23.57,
			23.617,
			23.678,
			23.742,
			23.788,
			23.802,
			23.866,
			23.897,
			23.926,
			23.989,
			24.008,
			24.052,
			24.111,
			24.173,
			24.222,
			24.3,
			24.331,
			24.36,
			24.438,
			24.486,
			24.548,
			24.608,
			24.655,
			24.67,
			24.734,
			24.765,
			24.796,
			24.858,
			24.874,
			24.92,
			24.979,
			25.044,
			25.088,
			25.104,
			25.168,
			25.199,
			25.229,
			25.308,
			25.354,
			25.416,
			25.477,
			25.54,
			25.602,
			25.633,
			25.664,
			25.724,
			25.788,
			25.851,
			25.912,
			25.959,
			25.975,
			26.035,
			26.072,
			26.099,
			26.161,
			26.174,
			26.222,
			26.283,
			26.344,
			26.392,
			26.406,
			26.471,
			26.502,
			26.531,
			26.594,
			26.654,
			26.717,
			26.779,
			26.826,
			26.902,
			26.936,
			26.966,
			27.028,
			27.088,
			27.15,
			27.214,
			27.261,
			27.274,
			27.337,
			27.369,
			27.401,
			27.478,
			27.522,
			27.587,
			27.649,
			27.695,
			27.771,
			27.802,
			27.833,
			27.897,
			27.958,
			28.019,
			28.082,
			28.142,
			28.206,
			28.237,
			28.268,
			28.331,
			28.344,
			28.392,
			28.454,
			28.516,
			28.563,
			28.578,
			28.64,
			28.671,
			28.703,
			28.765,
			28.779,
			28.826,
			28.887,
			28.951,
			28.995,
			29.073,
			29.105,
			29.137,
			29.199,
			29.26,
			29.323,
			29.385,
			29.432,
			29.445,
			29.507,
			29.539,
			29.569,
			29.646,
			29.695,
			29.755,
			29.817,
			29.864,
			29.878,
			29.943,
			29.974,
			30.004,
			30.066,
			30.081,
			30.127,
			30.189,
			30.253,
			30.299,
			30.376,
			30.406,
			30.438,
			30.5,
			30.561,
			30.625,
			30.686,
			30.733,
			30.75,
			30.809,
			30.84,
			30.872,
			30.951,
			30.995,
			31.058,
			31.121,
			31.183,
			31.245,
			31.276,
			31.307,
			31.369,
			31.431,
			31.496,
			31.554,
			31.602,
			31.68,
			31.708,
			31.741,
			31.801,
			31.818,
			31.863,
			31.926,
			31.987,
			32.034,
			32.052,
			32.113,
			32.143,
			32.176,
			32.237,
			32.255,
			32.299,
			32.361,
			32.421,
			32.469,
			32.548,
			32.576,
			32.609,
			32.672,
			32.733,
			32.796,
			32.856,
			32.902,
			32.919,
			32.981,
			33.01,
			33.043,
			33.104,
			33.119,
			33.166,
			33.23,
			33.289,
			33.338,
			33.352,
			33.416,
			33.445,
			33.478,
			33.54,
			33.555,
			33.601,
			33.662,
			33.724,
			33.772,
			33.849,
			33.879,
			33.911,
			33.973,
			34.036,
			34.095,
			34.158,
			34.206,
			34.222,
			34.283,
			34.318,
			34.346,
			34.424,
			34.469,
			34.531,
			34.593,
			34.639,
			34.656,
			34.717,
			34.749,
			34.78,
			34.839,
			34.855,
			34.903,
			34.965,
			35.028,
			35.072,
			35.152,
			35.183,
			35.214,
			35.275,
			35.337,
			35.4,
			35.46,
			35.509,
			35.522,
			35.586,
			35.616,
			35.646,
			35.708,
			35.724,
			35.771,
			35.834,
			35.894,
			35.943,
			35.958,
			36.02,
			36.049,
			36.082,
			36.142,
			36.158,
			36.204,
			36.267,
			36.329,
			36.391,
			36.453,
			36.483,
			36.517,
			36.577,
			36.594,
			36.64,
			36.702,
			36.763,
			36.81,
			36.824,
			36.888,
			36.918,
			36.951,
			37.01,
			37.028,
			37.075,
			37.134,
			37.198,
			37.245,
			37.321,
			37.353,
			37.383,
			37.446,
			37.506,
			37.57,
			37.632,
			37.678,
			37.694,
			37.757,
			37.785,
			37.817,
			37.879,
			37.895,
			37.942,
			38.004,
			38.066,
			38.111,
			38.127,
			38.19,
			38.221,
			38.25,
			38.314,
			38.328,
			38.376,
			38.443,
			38.5,
			38.561,
			38.623,
			38.654,
			38.684,
			38.748,
			38.809,
			38.871,
			38.933,
			38.98,
			38.995,
			39.056,
			39.088,
			39.119,
			39.199,
			39.243,
			39.307,
			39.367,
			39.413,
			39.429,
			39.49,
			39.524,
			39.552,
			39.617,
			39.629,
			39.677,
			39.74,
			39.803,
			39.849,
			39.925,
			39.958,
			39.989,
			40.051,
			40.111,
			40.175,
			40.236,
			40.283,
			40.299,
			40.359,
			40.39,
			40.423,
			40.485,
			40.504,
			40.546,
			40.608,
			40.671,
			40.716,
			40.73,
			40.793,
			40.826,
			40.855,
			40.933,
			40.98,
			41.043,
			41.105,
			41.166,
			41.229,
			41.258,
			41.291,
			41.369,
			41.412,
			41.476,
			41.538,
			41.585,
			41.599,
			41.661,
			41.692,
			41.725,
			41.787,
			41.802,
			41.849,
			41.91,
			41.973,
			42.019,
			42.098,
			42.127,
			42.158,
			42.22,
			42.283,
			42.345,
			42.406,
			42.452,
			42.47,
			42.529,
			42.567,
			42.592,
			42.655,
			42.67,
			42.716,
			42.779,
			42.839,
			42.902,
			42.963,
			42.997,
			43.027,
			43.104,
			43.15,
			43.212,
			43.275,
			43.337,
			43.4,
			43.429,
			43.459,
			43.522,
			43.586,
			43.648,
			43.709,
			43.754,
			43.77,
			43.833,
			43.862,
			43.893,
			43.972,
			44.018,
			44.079,
			44.143,
			44.19,
			44.206,
			44.267,
			44.297,
			44.329,
			44.39,
			44.405,
			44.452,
			44.516,
			44.578,
			44.637,
			44.702,
			44.733,
			44.761,
			44.825,
			44.886,
			44.949,
			45.011,
			45.058,
			45.073,
			45.135,
			45.166,
			45.198,
			45.259,
			45.273,
			45.32,
			45.383,
			45.444,
			45.49,
			45.506,
			45.57,
			45.601,
			45.631,
			45.693,
			45.709,
			45.756,
			45.818,
			45.878,
			45.941,
			46.002,
			46.034,
			46.065,
			46.126,
			46.19,
			46.25,
			46.312,
			46.36,
			46.376,
			46.437,
			46.469,
			46.499,
			46.561,
			46.578,
			46.624,
			46.69,
			46.746,
			46.794,
			46.87,
			46.902,
			46.933,
			46.995,
			47.056,
			47.119,
			47.181,
			47.227,
			47.244,
			47.306,
			47.335,
			47.366,
			47.429,
			47.444,
			47.49,
			47.553,
			47.616,
			47.676,
			47.739,
			47.771,
			47.801,
			47.864,
			47.879,
			47.926,
			47.987,
			48.049,
			48.096,
			48.174,
			48.205,
			48.236,
			48.296,
			48.358,
			48.423,
			48.483,
			48.53,
			48.547,
			48.608,
			48.638,
			48.671,
			48.733,
			48.75,
			48.792,
			48.856,
			48.918,
			48.979,
			49.043,
			49.072,
			49.102,
			49.167,
			49.182,
			49.228,
			49.291,
			49.352,
			49.398,
			49.477,
			49.506,
			49.537,
			49.6,
			49.662,
			49.725,
			49.786,
			49.832,
			49.847,
			49.91,
			49.942,
			49.972,
			50.034,
			50.05,
			50.094,
			50.158,
			50.218,
			50.266,
			50.283,
			50.342,
			50.374,
			50.405,
			50.483,
			50.528,
			50.591,
			50.652,
			50.699,
			50.776,
			50.839,
			50.9,
			50.963,
			51.027,
			51.087,
			51.133,
			51.15,
			51.212,
			51.243,
			51.272,
			51.337,
			51.351,
			51.399,
			51.461,
			51.521,
			51.583,
			51.644,
			51.676,
			51.707,
			51.77,
			51.833,
			51.895,
			51.955,
			52.003,
			52.019,
			52.081,
			52.112,
			52.143,
			52.205,
			52.219,
			52.266,
			52.327,
			52.389,
			52.436,
			52.453,
			52.512,
			52.546,
			52.574,
			52.637,
			52.653,
			52.701,
			52.763,
			52.823,
			52.885,
			52.948,
			52.979,
			53.009,
			53.073,
			53.134,
			53.196,
			53.258,
			53.321,
			53.38,
			53.413,
			53.444,
			53.507,
			53.521,
			53.568,
			53.631,
			53.692,
			53.74,
			53.755,
			53.818,
			53.849,
			53.878,
			53.956,
			54.003,
			54.065,
			54.127,
			54.174,
			54.25,
			54.281,
			54.313,
			54.375,
			54.437,
			54.499,
			54.56,
			54.606,
			54.622,
			54.685,
			54.716,
			54.746,
			54.809,
			54.823,
			54.871,
			54.932,
			54.995,
			55.04,
			55.057,
			55.118,
			55.15,
			55.18,
			55.258,
			55.305,
			55.366,
			55.429,
			55.475,
			55.551,
			55.584,
			55.614,
			55.691,
			55.738,
			55.802,
			55.861,
			55.91,
			55.924,
			55.988,
			56.016,
			56.049,
			56.111,
			56.127,
			56.173,
			56.234,
			56.297,
			56.343,
			56.421,
			56.451,
			56.483,
			56.561,
			56.608,
			56.67,
			56.732,
			56.778,
			56.794,
			56.854,
			56.885,
			56.917,
			56.98,
			57,
			57.04,
			57.102,
			57.165,
			57.211,
			57.228,
			57.288,
			57.32,
			57.351,
			57.414,
			57.427,
			57.474,
			57.538,
			57.6,
			57.66,
			57.724,
			57.755,
			57.785,
			57.846,
			57.908,
			57.97,
			58.033,
			58.081,
			58.094,
			58.158,
			58.187,
			58.218,
			58.281,
			58.297,
			58.343,
			58.406,
			58.466,
			58.515,
			58.528,
			58.59,
			58.621,
			58.654,
			58.716,
			58.732,
			58.777,
			58.84,
			58.9,
			58.948,
			59.026,
			59.06,
			59.088,
			59.149,
			59.21,
			59.274,
			59.334,
			59.383,
			59.398,
			59.458,
			59.489,
			59.521,
			59.583,
			59.6,
			59.645,
			59.707,
			59.77,
			59.816,
			59.833,
			59.892,
			59.926,
			59.954,
			60.016,
			60.033,
			60.081,
			60.141,
			60.205,
			60.266,
			60.326,
			60.359,
			60.39,
			60.453,
			60.514,
			60.575,
			60.638,
			60.699,
			60.76,
			60.791,
			60.823,
			60.884,
			60.902,
			60.947,
			61.011,
			61.07,
			61.133,
			61.195,
			61.228,
			61.257,
			61.321,
			61.379,
			61.445,
			61.505,
			61.553,
			61.568,
			61.629,
			61.66,
			61.69,
			61.77,
			61.816,
			61.878,
			61.939,
			61.986,
			62.003,
			62.062,
			62.096,
			62.126,
			62.202,
			62.249,
			62.312,
			62.373,
			62.419,
			62.496,
			62.527,
			62.561,
			62.623,
			62.685,
			62.747,
			62.809,
			62.854,
			62.871,
			62.932,
			62.962,
			62.994,
			63.071,
			63.12,
			63.185,
			63.243,
			63.288,
			63.305,
			63.366,
			63.397,
			63.429,
			63.505,
			63.553,
			63.616,
			63.676,
			63.737,
			63.801,
			63.831,
			63.861,
			63.924,
			63.987,
			64.049,
			64.111,
			64.155,
			64.172,
			64.233,
			64.266,
			64.296,
			64.357,
			64.373,
			64.42,
			64.484,
			64.544,
			64.589,
			64.606,
			64.667,
			64.699,
			64.731,
			64.791,
			64.808,
			64.854,
			64.916,
			64.978,
			65.04,
			65.102,
			65.135,
			65.165,
			65.245,
			65.287,
			65.351,
			65.411,
			65.458,
			65.474,
			65.536,
			65.568,
			65.598,
			65.662,
			65.677,
			65.723,
			65.785,
			65.846,
			65.894,
			65.971,
			66.001,
			66.033,
			66.111,
			66.156,
			66.22,
			66.279,
			66.343,
			66.406,
			66.435,
			66.468,
			66.529,
			66.59,
			66.654,
			66.714,
			66.776,
			66.838,
			66.87,
			66.9,
			66.962,
			66.977,
			67.025,
			67.088,
			67.15,
			67.196,
			67.272,
			67.31,
			67.335,
			67.396,
			67.458,
			67.521,
			67.584,
			67.645,
			67.707,
			67.739,
			67.767,
			67.848,
			67.892,
			67.954,
			68.016,
			68.079,
			68.142,
			68.173,
			68.204,
			68.266,
			68.279,
			68.326,
			68.389,
			68.451,
			68.499,
			68.575,
			68.607,
			68.637,
			68.699,
			68.76,
			68.823,
			68.886,
			68.931,
			68.948,
			69.008,
			69.04,
			69.072,
			69.133,
			69.148,
			69.194,
			69.258,
			69.32,
			69.371,
			69.444,
			69.475,
			69.506,
			69.568,
			69.629,
			69.69,
			69.754,
			69.801,
			69.877,
			69.908,
			69.939,
			70,
			70.064,
			70.126,
			70.188,
			70.235,
			70.249,
			70.312,
			70.343,
			70.372,
			70.434,
			70.451,
			70.498,
			70.559,
			70.622,
			70.683,
			70.745,
			70.776,
			70.807,
			70.87,
			70.932,
			70.992,
			71.056,
			71.116,
			71.18,
			71.21,
			71.24,
			71.303,
			71.319,
			71.365,
			71.434,
			71.491,
			71.535,
			71.551,
			71.615,
			71.645,
			71.677,
			71.752,
			71.8,
			71.86,
			71.924,
			71.984,
			72.046,
			72.079,
			72.11,
			72.188,
			72.233,
			72.296,
			72.359,
			72.405,
			72.419,
			72.482,
			72.511,
			72.543,
			72.621,
			72.669,
			72.729,
			72.791,
			72.839,
			72.854,
			72.917,
			72.948,
			72.977,
			73.054,
			73.102,
			73.165,
			73.226,
			73.273,
			73.349,
			73.379,
			73.411,
			73.473,
			73.493,
			73.535,
			73.602,
			73.658,
			73.707,
			73.722,
			73.783,
			73.813,
			73.846,
			73.906,
			73.923,
			73.97,
			74.033,
			74.095,
			74.156,
			74.219,
			74.25,
			74.281,
			74.342,
			74.359,
			74.405,
			74.467,
			74.527,
			74.574,
			74.651,
			74.683,
			74.715,
			74.79,
			74.838,
			74.899,
			74.963,
			75.009,
			75.023,
			75.086,
			75.116,
			75.148,
			75.211,
			75.224,
			75.273,
			75.333,
			75.396,
			75.441,
			75.458,
			75.521,
			75.558,
			75.582,
			75.644,
			75.707,
			75.768,
			75.831,
			75.876,
			75.893,
			75.954,
			75.985,
			76.016,
			76.077,
			76.093,
			76.141,
			76.202,
			76.264,
			76.325,
			76.389,
			76.42,
			76.451,
			76.511,
			76.527,
			76.575,
			76.636,
			76.699,
			76.745,
			76.821,
			76.854,
			76.885,
			76.948,
			77.007,
			77.07,
			77.133,
			77.195,
			77.257,
			77.287,
			77.318,
			77.379,
			77.395,
			77.442,
			77.506,
			77.567,
			77.618,
			77.629,
			77.691,
			77.721,
			77.754,
			77.814,
			77.831,
			77.876,
			77.939,
			78,
			78.045,
			78.126,
			78.155,
			78.186,
			78.249,
			78.311,
			78.372,
			78.436,
			78.481,
			78.498,
			78.557,
			78.589,
			78.622,
			78.7,
			78.743,
			78.807,
			78.868,
			78.917,
			78.929,
			78.991,
			79.024,
			79.054,
			79.133,
			79.178,
			79.24,
			79.302,
			79.35,
			79.426,
			79.457,
			79.489,
			79.551,
			79.611,
			79.678,
			79.738,
			79.783,
			79.799,
			79.862,
			79.892,
			79.924,
			79.984,
			79.999,
			80.048,
			80.11,
			80.171,
			80.233,
			80.296,
			80.325,
			80.358,
			80.418,
			80.434,
			80.479,
			80.543,
			80.605,
			80.652,
			80.729,
			80.761,
			80.792,
			80.853,
			80.87,
			80.916,
			80.977,
			81.04,
			81.086,
			81.102,
			81.163,
			81.193,
			81.225,
			81.288,
			81.303,
			81.349,
			81.41,
			81.473,
			81.519,
			81.597,
			81.629,
			81.658,
			81.722,
			81.784,
			81.845,
			81.908,
			81.954,
			81.968,
			82.031,
			82.063,
			82.093,
			82.155,
			82.17,
			82.216,
			82.277,
			82.342,
			82.388,
			82.404,
			82.465,
			82.496,
			82.526,
			82.59,
			82.604,
			82.653,
			82.712,
			82.776,
			82.823,
			82.9,
			82.929,
			82.962,
			83.023,
			83.084,
			83.148,
			83.211,
			83.271,
			83.333,
			83.363,
			83.395,
			83.458,
			83.474,
			83.519,
			83.583,
			83.644,
			83.706,
			83.768,
			83.803,
			83.831,
			83.89,
			83.908,
			83.953,
			84.016,
			84.077,
			84.124,
			84.203,
			84.233,
			84.265,
			84.326,
			84.386,
			84.45,
			84.513,
			84.557,
			84.574,
			84.634,
			84.668,
			84.699,
			84.758,
			84.776,
			84.821,
			84.885,
			84.946,
			84.991,
			85.007,
			85.07,
			85.099,
			85.132,
			85.194,
			85.255,
			85.319,
			85.379,
			85.427,
			85.503,
			85.536,
			85.567,
			85.627,
			85.644,
			85.691,
			85.752,
			85.815,
			85.877,
			85.938,
			85.969,
			85.999,
			86.063,
			86.076,
			86.124,
			86.187,
			86.246,
			86.294,
			86.373,
			86.404,
			86.434,
			86.511,
			86.558,
			86.621,
			86.682,
			86.728,
			86.744,
			86.806,
			86.836,
			86.869,
			86.93,
			86.947,
			86.991,
			87.055,
			87.116,
			87.178,
			87.24,
			87.271,
			87.302,
			87.379,
			87.427,
			87.487,
			87.55,
			87.611,
			87.674,
			87.705,
			87.735,
			87.799,
			87.861,
			87.925,
			87.983,
			88.048,
			88.107,
			88.139,
			88.17,
			88.232,
			88.249,
			88.295,
			88.358,
			88.42,
			88.465,
			88.479,
			88.541,
			88.573,
			88.605,
			88.667,
			88.682,
			88.727,
			88.79,
			88.854,
			88.914,
			88.976,
			89.007,
			89.038,
			89.099,
			89.161,
			89.224,
			89.285,
			89.333,
			89.349,
			89.41,
			89.442,
			89.473,
			89.535,
			89.549,
			89.597,
			89.66,
			89.719,
			89.766,
			89.783,
			89.843,
			89.875,
			89.906,
			89.969,
			90.032,
			90.093,
			90.154,
			90.2,
			90.279,
			90.31,
			90.341,
			90.402,
			90.419,
			90.463,
			90.526,
			90.587,
			90.636,
			90.65,
			90.711,
			90.742,
			90.776,
			90.837,
			90.851,
			90.899,
			90.962,
			91.023,
			91.068,
			91.084,
			91.145,
			91.179,
			91.208,
			91.27,
			91.334,
			91.395,
			91.457,
			91.504,
			91.52,
			91.582,
			91.612,
			91.641,
			91.704,
			91.721,
			91.767,
			91.827,
			91.892,
			91.937,
			91.953,
			92.015,
			92.05,
			92.077,
			92.138,
			92.155,
			92.201,
			92.261,
			92.325,
			92.388,
			92.449,
			92.481,
			92.511,
			92.574,
			92.636,
			92.697,
			92.758,
			92.805,
			92.822,
			92.884,
			92.915,
			92.945,
			93.023,
			93.07,
			93.132,
			93.194,
			93.24,
			93.255,
			93.316,
			93.346,
			93.379,
			93.457,
			93.503,
			93.567,
			93.627,
			93.689,
			93.751,
			93.783,
			93.811,
			93.875,
			93.938,
			94,
			94.062,
			94.123,
			94.187,
			94.216,
			94.247,
			94.326,
			94.372,
			94.433,
			94.495,
			94.541,
			94.557,
			94.62,
			94.652,
			94.683,
			94.743,
			94.805,
			94.868,
			94.928,
			94.976,
			95.053,
			95.086,
			95.116,
			95.177,
			95.239,
			95.302,
			95.365,
			95.409,
			95.425,
			95.488,
			95.519,
			95.549,
			95.611,
			95.627,
			95.673,
			95.735,
			95.797,
			95.861,
			95.923,
			95.951,
			95.984,
			96.047,
			96.108,
			96.173,
			96.233,
			96.277,
			96.293,
			96.356,
			96.387,
			96.417,
			96.479,
			96.495,
			96.541,
			96.603,
			96.665,
			96.714,
			96.726,
			96.789,
			96.821,
			96.852,
			96.914,
			96.929,
			96.977,
			97.038,
			97.101,
			97.148,
			97.224,
			97.254,
			97.286,
			97.363,
			97.411,
			97.472,
			97.535,
			97.58,
			97.596,
			97.658,
			97.689,
			97.72,
			97.783,
			97.798,
			97.843,
			97.906,
			97.967,
			98.015,
			98.03,
			98.092,
			98.124,
			98.155,
			98.216,
			98.234,
			98.279,
			98.341,
			98.403,
			98.465,
			98.526,
			98.557,
			98.587,
			98.65,
			98.712,
			98.774,
			98.836,
			98.898,
			98.96,
			98.991,
			99.023,
			99.085,
			99.102,
			99.146,
			99.208,
			99.269,
			99.318,
			99.332,
			99.395,
			99.425,
			99.457,
			99.52,
			99.581,
			99.641,
			99.706,
			99.751,
			99.828,
			99.859,
			99.891,
			99.954,
			100.015,
			100.077,
			100.138,
			100.184,
			100.2,
			100.262,
			100.296,
			100.326,
			100.402,
			100.449,
			100.51,
			100.572,
			100.636,
			100.696,
			100.727,
			100.757,
			100.82,
			100.883,
			100.944,
			101.006,
			101.052,
			101.069,
			101.131,
			101.162,
			101.193,
			101.255,
			101.27,
			101.316,
			101.377,
			101.44,
			101.503,
			101.565,
			101.596,
			101.625,
			101.688,
			101.705,
			101.75,
			101.814,
			101.874,
			101.921,
			102,
			102.029,
			102.061,
			102.122,
			102.184,
			102.248,
			102.31,
			102.36,
			102.371,
			102.433,
			102.464,
			102.493,
			102.574,
			102.62,
			102.682,
			102.743,
			102.791,
			102.805,
			102.867,
			102.898,
			102.929,
			103.005,
			103.053,
			103.115,
			103.177,
			103.223,
			103.302,
			103.333,
			103.362,
			103.44,
			103.487,
			103.549,
			103.61,
			103.672,
			103.734,
			103.767,
			103.796,
			103.86,
			103.873,
			103.92,
			103.983,
			104.044,
			104.093,
			104.106,
			104.168,
			104.199,
			104.232,
			104.294,
			104.354,
			104.418,
			104.423,
			104.477,
			104.525,
			104.604,
			104.635,
			104.666,
			104.743,
			104.789,
			104.852,
			104.913,
			104.96,
			104.974,
			105.036,
			105.068,
			105.099,
			105.16,
			105.178,
			105.222,
			105.285,
			105.345,
			105.41,
			105.473,
			105.503,
			105.534,
			105.594,
			105.657,
			105.721,
			105.782,
			105.829,
			105.845,
			105.906,
			105.938,
			105.967,
			106.031,
			106.044,
			106.092,
			106.153,
			106.216,
			106.276,
			106.339,
			106.371,
			106.4,
			106.465,
			106.484,
			106.526,
			106.587,
			106.65,
			106.695,
			106.772,
			106.806,
			106.836,
			106.897,
			106.961,
			107.021,
			107.083,
			107.129,
			107.146,
			107.209,
			107.24,
			107.27,
			107.346,
			107.393,
			107.457,
			107.518,
			107.564,
			107.579,
			107.642,
			107.673,
			107.703,
			107.779,
			107.826,
			107.89,
			107.951,
			108.013,
			108.076,
			108.106,
			108.138,
			108.199,
			108.263,
			108.323,
			108.386,
			108.433,
			108.448,
			108.511,
			108.546,
			108.571,
			108.634,
			108.65,
			108.694,
			108.757,
			108.821,
			108.867,
			108.883,
			108.943,
			108.975,
			109.007,
			109.084,
			109.131,
			109.193,
			109.254,
			109.302,
			109.379,
			109.409,
			109.439,
			109.502,
			109.518,
			109.565,
			109.626,
			109.688,
			109.736,
			109.75,
			109.812,
			109.844,
			109.874,
			109.935,
			109.95,
			109.998,
			110.061,
			110.122,
			110.168,
			110.245,
			110.276,
			110.307,
			110.37,
			110.433,
			110.493,
			110.555,
			110.605,
			110.619,
			110.679,
			110.71,
			110.743,
			110.821,
			110.867,
			110.929,
			110.99,
			111.038,
			111.053,
			111.116,
			111.146,
			111.177,
			111.239,
			111.254,
			111.301,
			111.362,
			111.426,
			111.47,
			111.549,
			111.579,
			111.609,
			111.688,
			111.735,
			111.796,
			111.859,
			111.92,
			111.983,
			112.013,
			112.046,
			112.106,
			112.122,
			112.169,
			112.231,
			112.292,
			112.34,
			112.355,
			112.417,
			112.449,
			112.478,
			112.54,
			112.557,
			112.603,
			112.665,
			112.67,
			112.726,
			112.773,
			112.85,
			112.882,
			112.914,
			112.975,
			113.038,
			113.099,
			113.16,
			113.207,
			113.223,
			113.285,
			113.316,
			113.348,
			113.408,
			113.423,
			113.47,
			113.534,
			113.594,
			113.641,
			113.656,
			113.718,
			113.75,
			113.781,
			113.842,
			113.857,
			113.905,
			113.968,
			114.027,
			114.077,
			114.153,
			114.185,
			114.215,
			114.291,
			114.339,
			114.401,
			114.463,
			114.509,
			114.526,
			114.586,
			114.619,
			114.65,
			114.712,
			114.733,
			114.773,
			114.836,
			114.898,
			114.96,
			115.021,
			115.052,
			115.083,
			115.144,
			115.208,
			115.269,
			115.331,
			115.394,
			115.456,
			115.486,
			115.517,
			115.579,
			115.595,
			115.642,
			115.704,
			115.766,
			115.811,
			115.826,
			115.888,
			115.921,
			115.95,
			116.013,
			116.027,
			116.076,
			116.137,
			116.2,
			116.245,
			116.323,
			116.355,
			116.386,
			116.446,
			116.51,
			116.571,
			116.633,
			116.681,
			116.695,
			116.757,
			116.821,
			116.882,
			116.899,
			116.945,
			117.005,
			117.066,
			117.128,
			117.19,
			117.223,
			117.253,
			117.316,
			117.332,
			117.377,
			117.441,
			117.502,
			117.565,
			117.626,
			117.657,
			117.687,
			117.749,
			117.811,
			117.873,
			117.936,
			117.997,
			118.06,
			118.089,
			118.122,
			118.183,
			118.2,
			118.245,
			118.306,
			118.369,
			118.416,
			118.432,
			118.493,
			118.525,
			118.556,
			118.617,
			118.634,
			118.68,
			118.743,
			118.804,
			118.854,
			118.927,
			118.958,
			118.991,
			119.053,
			119.112,
			119.176,
			119.238,
			119.285,
			119.3,
			119.36,
			119.392,
			119.425,
			119.487,
			119.502,
			119.548,
			119.609,
			119.672,
			119.718,
			119.733,
			119.796,
			119.826,
			119.859,
			119.92,
			119.982,
			120.045,
			120.106,
			120.154,
			120.168,
			120.23,
			120.262,
			120.293,
			120.37,
			120.416,
			120.477,
			120.54,
			120.588,
			120.602,
			120.663,
			120.694,
			120.727,
			120.805,
			120.85,
			120.918,
			120.973,
			121.022,
			121.098,
			121.129,
			121.16,
			121.222,
			121.284,
			121.346,
			121.408,
			121.455,
			121.47,
			121.533,
			121.562,
			121.595,
			121.656,
			121.671,
			121.719,
			121.781,
			121.841,
			121.887,
			121.904,
			121.967,
			121.997,
			122.028,
			122.09,
			122.106,
			122.152,
			122.215,
			122.276,
			122.324,
			122.401,
			122.429,
			122.46,
			122.523,
			122.588,
			122.65,
			122.711,
			122.758,
			122.774,
			122.833,
			122.866,
			122.897,
			122.958,
			122.977,
			123.021,
			123.083,
			123.144,
			123.191,
			123.207,
			123.268,
			123.3,
			123.331,
			123.393,
			123.408,
			123.456,
			123.517,
			123.579,
			123.639,
			123.702,
			123.733,
			123.764,
			123.825,
			123.887,
			123.951,
			124.012,
			124.059,
			124.075,
			124.137,
			124.166,
			124.196,
			124.26,
			124.277,
			124.323,
			124.386,
			124.446,
			124.493,
			124.571,
			124.603,
			124.634,
			124.709,
			124.757,
			124.82,
			124.879,
			124.928,
			125.004,
			125.04,
			125.067,
			125.129,
			125.145,
			125.191,
			125.255,
			125.316,
			125.361,
			125.375,
			125.438,
			125.471,
			125.501,
			125.58,
			125.624,
			125.686,
			125.75,
			125.794,
			125.873,
			125.903,
			125.934,
			126.013,
			126.059,
			126.121,
			126.183,
			126.245,
			126.306,
			126.338,
			126.37,
			126.448,
			126.492,
			126.554,
			126.618,
			126.664,
			126.68,
			126.74,
			126.773,
			126.804,
			126.866,
			126.881,
			126.927,
			126.989,
			127.052,
			127.113,
			127.175,
			127.207,
			127.237,
			127.313,
			127.361,
			127.423,
			127.484,
			127.532,
			127.549,
			127.609,
			127.641,
			127.67,
			127.733,
			127.75,
			127.796,
			127.857,
			127.92,
			127.965,
			127.961
		]
	],
	'6340_6578_7982': [
		[
			64.222
		],
		[
			0
		],
		[
			0,
			2.739,
			5.692,
			10.131,
			13.101,
			14.589,
			17.571,
			20.558,
			22.052,
			25.04,
			28.033,
			32.534,
			35.541,
			40.064,
			41.574,
			43.087,
			47.634,
			50.672,
			55.237,
			58.287,
			61.344,
			62.875,
			65.94,
			67.474,
			70.548,
			73.627,
			75.168,
			76.711,
			81.347,
			84.444,
			87.546,
			89.099,
			92.208,
			95.322,
			96.88,
			101.563,
			104.69,
			107.822,
			112.529,
			115.674,
			120.401,
			121.979,
			123.558,
			128.304,
			131.474,
			136.24,
			139.421,
			142.603,
			144.196,
			147.384,
			148.98,
			152.175,
			155.374,
			156.975,
			160.181,
			163.392,
			168.217,
			169.828,
			171.439,
			174.665,
			177.897,
			179.514,
			182.752,
			184.372,
			187.616,
			190.865,
			195.746,
			199.006,
			203.903,
			205.537,
			207.173,
			212.086,
			215.366,
			220.295,
			223.586,
			228.53,
			231.832,
			233.485,
			236.792,
			241.762,
			245.081,
			248.404,
			253.395,
			256.728,
			261.734,
			263.405,
			265.077,
			270.099,
			273.453,
			278.49,
			281.853,
			285.22,
			290.278,
			291.966,
			293.655,
			298.729,
			300.422,
			302.116,
			307.205,
			310.602,
			314.003,
			315.705,
			319.111,
			322.522,
			324.229,
			329.356,
			332.778,
			336.204,
			341.35,
			344.785,
			349.945,
			351.667,
			353.39,
			358.564,
			362.019,
			367.207,
			370.671,
			375.873,
			379.346,
			381.084,
			384.563,
			389.788,
			391.532,
			396.768,
			400.263,
			405.514,
			409.019,
			412.527,
			414.282,
			417.796,
			419.555,
			423.074,
			426.596,
			431.887,
			435.418,
			440.721,
			442.491,
			444.261,
			449.572,
			453.1,
			458.347,
			461.805,
			466.92,
			470.281,
			471.948,
			475.251,
			478.514,
			480.131,
			481.738,
			486.5,
			491.175,
			492.714,
			494.244,
			497.276,
			500.272,
			501.757,
			504.698,
			506.153,
			509.036,
			511.88,
			516.072,
			518.819,
			522.867,
			524.196,
			525.516,
			529.419,
			531.974,
			535.733,
			538.192,
			541.81,
			544.177,
			545.347,
			547.661,
			549.937,
			551.06,
			553.277,
			555.45,
			558.638,
			559.678,
			560.71,
			563.748,
			564.742,
			565.726,
			568.621,
			570.498,
			573.245,
			575.027,
			576.774,
			579.348,
			580.183,
			581.01,
			583.436,
			585.013,
			587.313,
			588.805,
			590.271,
			590.991,
			592.405,
			593.786,
			594.466,
			595.802,
			596.458,
			597.748,
			599.008,
			600.816,
			601.986,
			603.696,
			604.254,
			604.804,
			606.427,
			607.493,
			609.077,
			610.138,
			611.193,
			611.72,
			612.772,
			613.298,
			613.824,
			615.442,
			615.982,
			616.523,
			618.145,
			619.234,
			620.363,
			620.927,
			622.058,
			623.193,
			623.762,
			624.925,
			625.515,
			626.699,
			627.887,
			629.686,
			630.908,
			632.743,
			633.356,
			633.97,
			635.836,
			637.081,
			638.946,
			640.208,
			641.478,
			642.111,
			643.376,
			644.008,
			645.289,
			647.217,
			648.497,
			649.779,
			651.07,
			652.354,
			652.994,
			654.275,
			655.584,
			656.245,
			658.22,
			659.533,
			660.9,
			662.963,
			664.335,
			666.459,
			667.172,
			667.885,
			670.024,
			671.538,
			673.799,
			675.323,
			676.891,
			677.67,
			679.225,
			680.002,
			681.565,
			683.121,
			683.9,
			685.449,
			686.957,
			689.216,
			689.971,
			690.698,
			692.83,
			693.54,
			694.251,
			695.67,
			696.367,
			697.758,
			699.148,
			701.26,
			702.687,
			704.832,
			705.55,
			706.286,
			708.491,
			709.228,
			709.967,
			712.159,
			713.614,
			715.806,
			717.25,
			719.422,
			720.876,
			721.602,
			723.057,
			724.514,
			726.7,
			728.154,
			730.344,
			731.069,
			731.758,
			733.863,
			735.264,
			737.303,
			738.632,
			739.945,
			740.595,
			741.853,
			742.464,
			743.067,
			745.395,
			745.956,
			747.566,
			748.595,
			749.594,
			750.085,
			751.053,
			752.026,
			752.506,
			753.463,
			753.938,
			754.885,
			755.826,
			757.287,
			758.276,
			759.751,
			760.241,
			760.73,
			762.266,
			763.319,
			764.888,
			765.93,
			767.647,
			768.837,
			769.43,
			770.614,
			772.612,
			774.076,
			775.53,
			777.819,
			778.612,
			779.404,
			780.982,
			782.536,
			783.301,
			784.824,
			785.583,
			787.115,
			788.676,
			791.009,
			792.531,
			794.76,
			795.506,
			796.253,
			798.284,
			799.588,
			801.55,
			802.753,
			804.498,
			805.662,
			806.245,
			807.379,
			808.445,
			808.978,
			810.048,
			811.122,
			812.697,
			813.196,
			813.696,
			815.201,
			815.706,
			816.213,
			817.231,
			817.726,
			818.717,
			820.217,
			821.228,
			822.252,
			823.807,
			824.33,
			824.855,
			826.448,
			827.53,
			829.181,
			830.297,
			831.428,
			831.999,
			833.162,
			834.34,
			834.935,
			836.738,
			837.954,
			839.177,
			841.035,
			842.29,
			844.33,
			845.022,
			845.718,
			847.833,
			849.263,
			851.442,
			852.903,
			854.393,
			855.145,
			856.791,
			857.658,
			858.529,
			861.133,
			861.948,
			862.766,
			865.248,
			866.879,
			868.509,
			869.33,
			870.985,
			872.672,
			873.513,
			875.196,
			876.042,
			877.758,
			879.49,
			882.127,
			883.946,
			886.7,
			887.641,
			888.584,
			891.444,
			893.378,
			896.306,
			898.26,
			900.229,
			901.219,
			903.212,
			904.213,
			905.219,
			908.264,
			909.286,
			911.344,
			913.421,
			915.512,
			917.612,
			918.667,
			920.786,
			922.912,
			923.98,
			926.125,
			927.199,
			929.357,
			931.526,
			934.787,
			936.964,
			940.206,
			941.291,
			942.371,
			945.629,
			947.812,
			951.104,
			953.307,
			955.52,
			956.63,
			958.847,
			961.075,
			962.191,
			964.427,
			965.548,
			967.798,
			970.055,
			973.457,
			975.736,
			979.173,
			980.324,
			981.478,
			984.953,
			987.283,
			989.621,
			993.147,
			995.511,
			999.094,
			1000.297,
			1001.502,
			1005.169,
			1007.633,
			1011.371,
			1013.878,
			1016.396,
			1017.658,
			1020.182,
			1021.448,
			1023.973,
			1027.767,
			1030.292,
			1032.824,
			1036.625,
			1039.147,
			1042.999,
			1044.284,
			1045.57,
			1049.442,
			1052.029,
			1055.928,
			1058.522,
			1061.114,
			1065.004,
			1066.281,
			1067.58,
			1071.527,
			1072.838,
			1074.15,
			1078.089,
			1080.707,
			1083.307,
			1084.616,
			1087.264,
			1089.901,
			1091.223,
			1093.879,
			1095.215,
			1097.894,
			1100.576,
			1104.609,
			1107.302,
			1111.331,
			1112.674,
			1114.012,
			1118.03,
			1120.699,
			1124.705,
			1127.375,
			1130.049,
			1131.388,
			1134.07,
			1135.412,
			1138.101,
			1142.146,
			1144.85,
			1147.558,
			1150.269,
			1152.983,
			1154.34,
			1157.057,
			1159.774,
			1161.134,
			1165.214,
			1167.938,
			1170.663,
			1174.759,
			1177.494,
			1181.608,
			1182.982,
			1184.357,
			1188.492,
			1191.259,
			1195.421,
			1198.204,
			1202.382,
			1205.17,
			1206.565,
			1209.336,
			1212.143,
			1213.548,
			1216.384,
			1219.231,
			1223.547,
			1224.994,
			1226.445,
			1230.81,
			1232.269,
			1233.73,
			1236.655,
			1238.12,
			1241.048,
			1243.98,
			1248.383,
			1251.324,
			1255.749,
			1257.228,
			1258.708,
			1263.162,
			1264.651,
			1266.141,
			1270.619,
			1273.609,
			1276.603,
			1278.101,
			1281.098,
			1284.098,
			1285.598,
			1288.6,
			1290.102,
			1293.106,
			1296.114,
			1300.632,
			1303.65,
			1308.186,
			1309.7,
			1311.215,
			1315.77,
			1318.813,
			1323.387,
			1326.442,
			1329.503,
			1331.036,
			1334.104,
			1335.64,
			1337.177,
			1341.794,
			1343.336,
			1344.879,
			1349.513,
			1352.609,
			1355.709,
			1357.263,
			1360.376,
			1363.497,
			1365.06,
			1368.192,
			1369.761,
			1372.903,
			1376.051,
			1380.779,
			1383.931,
			1388.664,
			1390.245,
			1391.827,
			1396.588,
			1399.769,
			1404.549,
			1407.74,
			1412.535,
			1415.734,
			1417.335,
			1420.536,
			1423.737,
			1425.337,
			1426.937,
			1431.738,
			1434.941,
			1438.145,
			1439.747,
			1442.947,
			1446.132,
			1447.716,
			1450.861,
			1452.42,
			1455.509,
			1458.557,
			1463.05,
			1465.991,
			1470.322,
			1471.744,
			1473.156,
			1477.327,
			1480.06,
			1484.089,
			1486.731,
			1489.337,
			1490.626,
			1493.175,
			1494.436,
			1496.928,
			1499.384,
			1500.599,
			1503,
			1505.367,
			1508.852,
			1509.995,
			1511.128,
			1514.47,
			1515.565,
			1516.65,
			1519.851,
			1521.938,
			1523.99,
			1527.007,
			1528.972,
			1531.855,
			1532.797,
			1533.729,
			1536.474,
			1538.264,
			1540.885,
			1542.586,
			1544.25,
			1545.068,
			1546.677,
			1548.247,
			1549.019,
			1550.54,
			1551.301,
			1552.776,
			1554.23,
			1556.373,
			1557.781,
			1559.865,
			1560.551,
			1561.234,
			1563.277,
			1564.619,
			1566.585,
			1567.889,
			1569.189,
			1571.066,
			1571.69,
			1572.313,
			1574.186,
			1574.821,
			1575.456,
			1577.36,
			1578.632,
			1579.989,
			1580.698,
			1582.117,
			1583.538,
			1584.258,
			1585.941,
			1586.781,
			1588.46,
			1590.25,
			1593.06,
			1594.963,
			1597.899,
			1598.875,
			1599.846,
			1602.743,
			1604.651,
			1607.417,
			1609.245,
			1610.941,
			1611.784,
			1613.464,
			1614.298,
			1615.739,
			1617.89,
			1619.315,
			1620.545,
			1621.77,
			1622.989,
			1623.598,
			1624.799,
			1625.978,
			1626.556,
			1628.316,
			1629.507,
			1630.738,
			1632.594,
			1633.839,
			1635.761,
			1636.411,
			1637.062,
			1639.034,
			1640.385,
			1642.454,
			1643.853,
			1645.999,
			1647.454,
			1648.187,
			1649.667,
			1651.174,
			1651.933,
			1653.464,
			1655.013,
			1657.379,
			1658.175,
			1658.975,
			1661.398,
			1662.212,
			1663.031,
			1664.68,
			1665.505,
			1667.165,
			1668.839,
			1671.37,
			1673.065,
			1675.604,
			1676.453,
			1677.291,
			1679.867,
			1681.594,
			1684.209,
			1685.966,
			1687.735,
			1688.625,
			1690.413,
			1691.313,
			1693.121,
			1694.943,
			1695.857,
			1697.694,
			1699.544,
			1702.338,
			1704.215,
			1707.053,
			1708.005,
			1708.959,
			1711.841,
			1713.776,
			1716.704,
			1718.671,
			1720.65,
			1723.643,
			1724.646,
			1725.652,
			1728.687,
			1729.705,
			1730.725,
			1733.802,
			1735.866,
			1737.941,
			1738.983,
			1741.069,
			1743.153,
			1744.196,
			1746.29,
			1747.34,
			1749.448,
			1751.565,
			1754.759,
			1756.901,
			1760.131,
			1761.213,
			1762.298,
			1765.568,
			1767.761,
			1771.071,
			1773.291,
			1775.521,
			1776.642,
			1778.892,
			1780.021,
			1781.153,
			1784.571,
			1785.715,
			1788.016,
			1790.328,
			1792.653,
			1794.989,
			1796.181,
			1798.533,
			1800.896,
			1802.08,
			1804.456,
			1805.647,
			1808.036,
			1810.433,
			1814.044,
			1816.46,
			1820.087,
			1821.299,
			1822.506,
			1826.134,
			1828.549,
			1832.172,
			1834.577,
			1836.973,
			1838.164,
			1840.533,
			1841.71,
			1844.051,
			1846.372,
			1847.532,
			1849.851,
			1852.167,
			1855.661,
			1856.826,
			1858,
			1861.529,
			1862.713,
			1863.902,
			1867.469,
			1869.868,
			1872.266,
			1875.892,
			1878.318,
			1881.969,
			1883.192,
			1884.416,
			1888.081,
			1890.525,
			1894.173,
			1896.592,
			1898.997,
			1900.194,
			1902.584,
			1904.941,
			1906.118,
			1909.627,
			1911.953,
			1914.276,
			1917.75,
			1918.911,
			1920.071,
			1923.542,
			1924.699,
			1925.854,
			1929.316,
			1931.621,
			1935.067,
			1937.356,
			1939.635,
			1943.039,
			1944.169,
			1945.297,
			1948.67,
			1949.791,
			1950.909,
			1954.266,
			1956.512,
			1958.762,
			1959.895,
			1962.162,
			1964.446,
			1965.596,
			1969.053,
			1971.374,
			1973.696,
			1977.192,
			1979.516,
			1982.989,
			1984.145,
			1985.299,
			1988.755,
			1991.046,
			1994.473,
			1996.751,
			2000.168,
			2002.446,
			2003.584,
			2005.864,
			2008.139,
			2009.277,
			2011.555,
			2013.832,
			2016.099,
			2018.363,
			2019.491,
			2021.739,
			2023.983,
			2025.1,
			2027.33,
			2028.444,
			2030.673,
			2032.9,
			2036.244,
			2038.471,
			2041.792,
			2042.897,
			2044.003,
			2047.321,
			2049.537,
			2052.874,
			2055.11,
			2057.359,
			2058.487,
			2060.765,
			2061.913,
			2064.214,
			2067.707,
			2070.048,
			2072.398,
			2075.944,
			2077.133,
			2078.324,
			2080.698,
			2083.104,
			2084.31,
			2087.893,
			2090.274,
			2092.626,
			2096.122,
			2098.42,
			2101.807,
			2102.925,
			2104.046,
			2107.385,
			2109.617,
			2113.028,
			2115.326,
			2118.848,
			2121.23,
			2122.431,
			2124.833,
			2128.47,
			2130.862,
			2133.233,
			2136.733,
			2137.883,
			2139.025,
			2142.408,
			2143.519,
			2144.621,
			2147.892,
			2150.031,
			2153.208,
			2155.303,
			2157.41,
			2160.573,
			2161.636,
			2162.701,
			2165.904,
			2166.974,
			2168.043,
			2171.247,
			2173.376,
			2176.534,
			2178.604,
			2180.65,
			2181.658,
			2183.656,
			2184.647,
			2186.623,
			2188.592,
			2191.549,
			2193.531,
			2196.513,
			2197.511,
			2198.51,
			2201.517,
			2203.528,
			2206.551,
			2208.568,
			2210.588,
			2211.595,
			2213.608,
			2214.615,
			2216.628,
			2219.638,
			2221.642,
			2223.642,
			2225.639,
			2227.641,
			2228.64,
			2230.634,
			2232.622,
			2233.613,
			2235.588,
			2236.568,
			2238.514,
			2240.448,
			2243.32,
			2245.223,
			2248.046,
			2248.981,
			2249.915,
			2252.692,
			2254.53,
			2257.274,
			2259.091,
			2260.901,
			2261.802,
			2263.597,
			2264.49,
			2266.272,
			2268.051,
			2268.937,
			2270.702,
			2272.463,
			2275.086,
			2275.955,
			2276.822,
			2278.547,
			2280.268,
			2281.128,
			2282.844,
			2283.701,
			2285.416,
			2287.133,
			2289.708,
			2291.424,
			2294.003,
			2294.862,
			2295.721,
			2298.305,
			2300.029,
			2302.623,
			2304.36,
			2306.104,
			2306.98,
			2308.741,
			2309.625,
			2311.403,
			2313.192,
			2314.091,
			2315.898,
			2317.725,
			2320.496,
			2322.365,
			2325.207,
			2326.16,
			2327.117,
			2330,
			2331.938,
			2333.867,
			2336.783,
			2338.729,
			2339.706,
			2341.669,
			2342.639,
			2343.63,
			2346.615,
			2347.621,
			2348.632,
			2351.661,
			2353.681,
			2355.683,
			2356.674,
			2358.625,
			2360.538,
			2361.481,
			2363.332,
			2364.242,
			2366.034,
			2367.789,
			2370.349,
			2372.009,
			2374.432,
			2375.221,
			2376.001,
			2378.296,
			2379.796,
			2382.031,
			2383.5,
			2384.959,
			2385.687,
			2387.138,
			2387.863,
			2389.304,
			2390.74,
			2391.457,
			2392.173,
			2394.292,
			2395.697,
			2397.101,
			2397.808,
			2399.22,
			2400.631,
			2401.336,
			2403.506,
			2404.983,
			2406.459,
			2408.749,
			2410.306,
			2412.645,
			2413.434,
			2414.222,
			2416.581,
			2418.152,
			2420.506,
			2422.074,
			2423.677,
			2424.481,
			2426.09,
			2426.896,
			2428.514,
			2430.954,
			2432.583,
			2434.136,
			2436.482,
			2437.269,
			2438.024,
			2439.521,
			2441.026,
			2441.783,
			2443.289,
			2444.038,
			2445.545,
			2447.064,
			2449.387,
			2450.955,
			2453.344,
			2454.156,
			2454.97,
			2457.433,
			2459.107,
			2461.646,
			2463.359,
			2465.092,
			2465.963,
			2467.717,
			2468.602,
			2470.381,
			2472.173,
			2473.074,
			2474.888,
			2476.714,
			2479.465,
			2481.327,
			2484.144,
			2485.09,
			2486.039,
			2488.901,
			2490.822,
			2492.756,
			2495.673,
			2497.634,
			2500.591,
			2501.582,
			2502.577,
			2506.572,
			2507.578,
			2510.612,
			2512.648,
			2514.699,
			2515.729,
			2517.797,
			2519.879,
			2520.924,
			2523.018,
			2524.066,
			2526.162,
			2528.267,
			2531.447,
			2533.579,
			2536.797,
			2537.875,
			2538.955,
			2542.212,
			2544.396,
			2547.691,
			2549.9,
			2553.232,
			2555.466,
			2556.587,
			2558.836,
			2561.093,
			2562.226,
			2564.498,
			2566.78,
			2569.073,
			2571.374,
			2572.529,
			2574.845,
			2577.171,
			2578.338,
			2580.679,
			2581.853,
			2584.208,
			2586.573,
			2590.138,
			2592.528,
			2596.132,
			2597.338,
			2598.546,
			2602.187,
			2604.626,
			2608.304,
			2610.768,
			2613.24,
			2614.48,
			2616.968,
			2618.215,
			2620.717,
			2624.487,
			2627.014,
			2629.55,
			2632.095,
			2634.648,
			2635.926,
			2639.774,
			2641.058,
			2642.345,
			2644.924,
			2646.215,
			2648.802,
			2651.396,
			2655.298,
			2657.903,
			2661.813,
			2663.119,
			2664.427,
			2668.36,
			2669.674,
			2670.99,
			2674.945,
			2677.589,
			2681.566,
			2684.226,
			2685.559,
			2688.23,
			2692.254,
			2694.948,
			2697.652,
			2701.725,
			2704.451,
			2708.556,
			2709.929,
			2711.303,
			2715.435,
			2718.197,
			2722.352,
			2725.129,
			2727.91,
			2729.302,
			2732.089,
			2733.483,
			2734.878,
			2739.072,
			2740.473,
			2741.875,
			2746.09,
			2748.909,
			2751.735,
			2753.151,
			2755.989,
			2758.834,
			2760.26,
			2763.117,
			2764.549,
			2767.418,
			2770.294,
			2774.622,
			2776.068,
			2777.516,
			2781.87,
			2783.326,
			2784.784,
			2789.169,
			2792.131
		],
		[
			0,
			0.043,
			0.105,
			0.17,
			0.229,
			0.26,
			0.291,
			0.355,
			0.369,
			0.416,
			0.478,
			0.539,
			0.587,
			0.664,
			0.694,
			0.725,
			0.789,
			0.849,
			0.912,
			0.975,
			1.022,
			1.036,
			1.1,
			1.129,
			1.162,
			1.222,
			1.239,
			1.283,
			1.348,
			1.408,
			1.456,
			1.471,
			1.533,
			1.563,
			1.595,
			1.672,
			1.719,
			1.781,
			1.843,
			1.889,
			1.965,
			1.998,
			2.027,
			2.091,
			2.152,
			2.219,
			2.277,
			2.323,
			2.338,
			2.401,
			2.43,
			2.462,
			2.525,
			2.541,
			2.586,
			2.647,
			2.711,
			2.758,
			2.771,
			2.834,
			2.864,
			2.897,
			2.957,
			2.974,
			3.021,
			3.081,
			3.146,
			3.207,
			3.27,
			3.301,
			3.33,
			3.393,
			3.455,
			3.518,
			3.579,
			3.642,
			3.703,
			3.734,
			3.764,
			3.842,
			3.888,
			3.956,
			4.012,
			4.076,
			4.137,
			4.168,
			4.199,
			4.262,
			4.324,
			4.386,
			4.448,
			4.509,
			4.571,
			4.603,
			4.632,
			4.694,
			4.712,
			4.758,
			4.819,
			4.881,
			4.926,
			4.942,
			5.006,
			5.036,
			5.067,
			5.146,
			5.192,
			5.253,
			5.314,
			5.378,
			5.438,
			5.47,
			5.502,
			5.564,
			5.626,
			5.689,
			5.75,
			5.811,
			5.873,
			5.903,
			5.936,
			6.013,
			6.06,
			6.122,
			6.185,
			6.246,
			6.306,
			6.341,
			6.37,
			6.43,
			6.447,
			6.494,
			6.557,
			6.619,
			6.665,
			6.742,
			6.773,
			6.803,
			6.865,
			6.928,
			6.989,
			7.052,
			7.115,
			7.175,
			7.206,
			7.238,
			7.298,
			7.315,
			7.361,
			7.423,
			7.486,
			7.532,
			7.549,
			7.609,
			7.639,
			7.673,
			7.735,
			7.747,
			7.794,
			7.858,
			7.921,
			7.964,
			8.045,
			8.075,
			8.106,
			8.168,
			8.229,
			8.292,
			8.354,
			8.415,
			8.478,
			8.509,
			8.539,
			8.602,
			8.616,
			8.664,
			8.725,
			8.787,
			8.834,
			8.848,
			8.913,
			8.943,
			8.975,
			9.052,
			9.097,
			9.161,
			9.221,
			9.269,
			9.345,
			9.377,
			9.407,
			9.47,
			9.533,
			9.595,
			9.657,
			9.703,
			9.718,
			9.779,
			9.812,
			9.842,
			9.903,
			9.92,
			9.965,
			10.029,
			10.091,
			10.136,
			10.213,
			10.246,
			10.275,
			10.339,
			10.4,
			10.468,
			10.524,
			10.57,
			10.587,
			10.648,
			10.68,
			10.71,
			10.772,
			10.789,
			10.835,
			10.895,
			10.958,
			11.006,
			11.019,
			11.08,
			11.112,
			11.145,
			11.206,
			11.221,
			11.268,
			11.329,
			11.392,
			11.439,
			11.514,
			11.547,
			11.578,
			11.641,
			11.703,
			11.764,
			11.825,
			11.872,
			11.888,
			11.949,
			11.98,
			12.013,
			12.088,
			12.137,
			12.198,
			12.26,
			12.307,
			12.322,
			12.385,
			12.416,
			12.447,
			12.53,
			12.57,
			12.631,
			12.694,
			12.755,
			12.816,
			12.847,
			12.881,
			12.942,
			13.006,
			13.068,
			13.129,
			13.176,
			13.191,
			13.253,
			13.285,
			13.313,
			13.376,
			13.392,
			13.439,
			13.501,
			13.562,
			13.61,
			13.625,
			13.686,
			13.717,
			13.747,
			13.81,
			13.825,
			13.872,
			13.933,
			13.997,
			14.044,
			14.122,
			14.152,
			14.182,
			14.244,
			14.261,
			14.308,
			14.369,
			14.431,
			14.492,
			14.556,
			14.615,
			14.679,
			14.695,
			14.739,
			14.803,
			14.865,
			14.912,
			14.989,
			15.021,
			15.052,
			15.114,
			15.175,
			15.239,
			15.298,
			15.345,
			15.362,
			15.422,
			15.453,
			15.486,
			15.563,
			15.608,
			15.675,
			15.731,
			15.779,
			15.795,
			15.858,
			15.888,
			15.919,
			15.982,
			15.997,
			16.043,
			16.105,
			16.168,
			16.214,
			16.291,
			16.322,
			16.353,
			16.415,
			16.477,
			16.538,
			16.6,
			16.662,
			16.726,
			16.756,
			16.788,
			16.864,
			16.911,
			16.972,
			17.036,
			17.082,
			17.098,
			17.158,
			17.191,
			17.222,
			17.282,
			17.297,
			17.344,
			17.406,
			17.469,
			17.514,
			17.592,
			17.624,
			17.655,
			17.716,
			17.778,
			17.841,
			17.903,
			17.964,
			18.028,
			18.058,
			18.091,
			18.15,
			18.168,
			18.215,
			18.276,
			18.338,
			18.384,
			18.398,
			18.461,
			18.492,
			18.524,
			18.585,
			18.602,
			18.647,
			18.712,
			18.771,
			18.818,
			18.895,
			18.927,
			18.958,
			19.019,
			19.081,
			19.143,
			19.205,
			19.252,
			19.266,
			19.331,
			19.361,
			19.391,
			19.469,
			19.514,
			19.577,
			19.639,
			19.7,
			19.764,
			19.795,
			19.827,
			19.888,
			19.949,
			20.011,
			20.074,
			20.12,
			20.136,
			20.198,
			20.227,
			20.26,
			20.322,
			20.336,
			20.385,
			20.445,
			20.506,
			20.553,
			20.57,
			20.632,
			20.664,
			20.695,
			20.756,
			20.776,
			20.816,
			20.882,
			20.943,
			21.005,
			21.066,
			21.098,
			21.127,
			21.189,
			21.252,
			21.315,
			21.377,
			21.423,
			21.439,
			21.498,
			21.531,
			21.561,
			21.624,
			21.639,
			21.686,
			21.749,
			21.809,
			21.856,
			21.873,
			21.933,
			21.966,
			21.997,
			22.058,
			22.073,
			22.12,
			22.181,
			22.244,
			22.29,
			22.369,
			22.4,
			22.43,
			22.491,
			22.555,
			22.617,
			22.678,
			22.723,
			22.741,
			22.803,
			22.837,
			22.864,
			22.926,
			22.942,
			22.989,
			23.052,
			23.111,
			23.175,
			23.237,
			23.265,
			23.298,
			23.361,
			23.422,
			23.486,
			23.545,
			23.592,
			23.67,
			23.702,
			23.732,
			23.809,
			23.856,
			23.919,
			23.981,
			24.027,
			24.042,
			24.106,
			24.135,
			24.168,
			24.244,
			24.289,
			24.353,
			24.413,
			24.462,
			24.539,
			24.569,
			24.6,
			24.661,
			24.724,
			24.786,
			24.85,
			24.899,
			24.972,
			25.004,
			25.035,
			25.097,
			25.111,
			25.16,
			25.22,
			25.281,
			25.329,
			25.343,
			25.405,
			25.438,
			25.466,
			25.53,
			25.547,
			25.593,
			25.654,
			25.716,
			25.762,
			25.841,
			25.873,
			25.902,
			25.966,
			26.028,
			26.089,
			26.151,
			26.197,
			26.213,
			26.275,
			26.305,
			26.337,
			26.415,
			26.462,
			26.525,
			26.583,
			26.632,
			26.648,
			26.708,
			26.738,
			26.771,
			26.848,
			26.894,
			26.961,
			27.02,
			27.065,
			27.144,
			27.175,
			27.205,
			27.266,
			27.329,
			27.391,
			27.453,
			27.516,
			27.578,
			27.608,
			27.64,
			27.699,
			27.716,
			27.764,
			27.827,
			27.887,
			27.934,
			27.947,
			28.012,
			28.041,
			28.072,
			28.133,
			28.151,
			28.198,
			28.259,
			28.321,
			28.383,
			28.446,
			28.476,
			28.508,
			28.57,
			28.586,
			28.631,
			28.694,
			28.754,
			28.802,
			28.815,
			28.88,
			28.911,
			28.94,
			29.003,
			29.022,
			29.065,
			29.128,
			29.188,
			29.236,
			29.313,
			29.346,
			29.374,
			29.438,
			29.501,
			29.563,
			29.624,
			29.669,
			29.687,
			29.748,
			29.779,
			29.809,
			29.871,
			29.888,
			29.933,
			29.997,
			30.058,
			30.103,
			30.12,
			30.181,
			30.212,
			30.243,
			30.307,
			30.321,
			30.368,
			30.43,
			30.49,
			30.539,
			30.615,
			30.646,
			30.678,
			30.741,
			30.801,
			30.863,
			30.926,
			30.986,
			31.048,
			31.083,
			31.112,
			31.174,
			31.188,
			31.236,
			31.299,
			31.36,
			31.405,
			31.423,
			31.484,
			31.514,
			31.545,
			31.608,
			31.622,
			31.669,
			31.733,
			31.795,
			31.839,
			31.916,
			31.948,
			31.978,
			32.042,
			32.103,
			32.165,
			32.229,
			32.274,
			32.289,
			32.353,
			32.384,
			32.414,
			32.477,
			32.491,
			32.538,
			32.601,
			32.663,
			32.708,
			32.724,
			32.787,
			32.818,
			32.848,
			32.911,
			32.972,
			33.032,
			33.097,
			33.148,
			33.221,
			33.251,
			33.282,
			33.345,
			33.406,
			33.468,
			33.53,
			33.576,
			33.593,
			33.653,
			33.686,
			33.715,
			33.777,
			33.794,
			33.839,
			33.902,
			33.964,
			34.026,
			34.089,
			34.119,
			34.15,
			34.212,
			34.274,
			34.336,
			34.399,
			34.444,
			34.522,
			34.554,
			34.583,
			34.645,
			34.663,
			34.709,
			34.771,
			34.83,
			34.88,
			34.893,
			34.956,
			34.988,
			35.019,
			35.082,
			35.095,
			35.142,
			35.206,
			35.265,
			35.328,
			35.392,
			35.421,
			35.453,
			35.515,
			35.577,
			35.638,
			35.702,
			35.747,
			35.764,
			35.825,
			35.857,
			35.887,
			35.964,
			36.011,
			36.072,
			36.135,
			36.18,
			36.197,
			36.258,
			36.288,
			36.322,
			36.397,
			36.445,
			36.506,
			36.57,
			36.615,
			36.692,
			36.723,
			36.756,
			36.815,
			36.879,
			36.94,
			37.002,
			37.064,
			37.128,
			37.158,
			37.188,
			37.252,
			37.271,
			37.314,
			37.376,
			37.436,
			37.482,
			37.498,
			37.561,
			37.591,
			37.621,
			37.683,
			37.698,
			37.747,
			37.81,
			37.87,
			37.916,
			37.995,
			38.025,
			38.058,
			38.119,
			38.181,
			38.244,
			38.305,
			38.352,
			38.368,
			38.429,
			38.461,
			38.491,
			38.554,
			38.569,
			38.614,
			38.677,
			38.74,
			38.786,
			38.863,
			38.894,
			38.925,
			39.003,
			39.049,
			39.112,
			39.173,
			39.219,
			39.297,
			39.332,
			39.359,
			39.421,
			39.437,
			39.482,
			39.544,
			39.607,
			39.654,
			39.669,
			39.732,
			39.762,
			39.794,
			39.854,
			39.87,
			39.916,
			39.98,
			40.041,
			40.103,
			40.166,
			40.197,
			40.228,
			40.288,
			40.353,
			40.414,
			40.476,
			40.522,
			40.537,
			40.6,
			40.631,
			40.66,
			40.722,
			40.739,
			40.786,
			40.848,
			40.911,
			40.956,
			40.972,
			41.033,
			41.063,
			41.095,
			41.157,
			41.172,
			41.22,
			41.281,
			41.343,
			41.405,
			41.468,
			41.499,
			41.529,
			41.591,
			41.653,
			41.716,
			41.778,
			41.824,
			41.841,
			41.903,
			41.931,
			41.964,
			42.026,
			42.04,
			42.088,
			42.15,
			42.211,
			42.258,
			42.273,
			42.336,
			42.366,
			42.398,
			42.461,
			42.521,
			42.582,
			42.647,
			42.691,
			42.769,
			42.801,
			42.831,
			42.892,
			42.956,
			43.019,
			43.078,
			43.126,
			43.142,
			43.203,
			43.236,
			43.266,
			43.344,
			43.39,
			43.454,
			43.514,
			43.559,
			43.577,
			43.639,
			43.67,
			43.698,
			43.777,
			43.825,
			43.886,
			43.947,
			43.995,
			44.073,
			44.104,
			44.135,
			44.197,
			44.211,
			44.257,
			44.321,
			44.383,
			44.427,
			44.443,
			44.505,
			44.536,
			44.568,
			44.645,
			44.691,
			44.755,
			44.815,
			44.879,
			44.94,
			44.971,
			45,
			45.064,
			45.126,
			45.188,
			45.248,
			45.312,
			45.375,
			45.406,
			45.436,
			45.498,
			45.515,
			45.561,
			45.622,
			45.682,
			45.732,
			45.747,
			45.809,
			45.839,
			45.87,
			45.931,
			45.948,
			45.994,
			46.057,
			46.119,
			46.164,
			46.241,
			46.272,
			46.304,
			46.383,
			46.43,
			46.492,
			46.552,
			46.599,
			46.616,
			46.677,
			46.709,
			46.738,
			46.817,
			46.862,
			46.925,
			46.986,
			47.034,
			47.048,
			47.109,
			47.142,
			47.174,
			47.248,
			47.295,
			47.359,
			47.422,
			47.482,
			47.545,
			47.579,
			47.606,
			47.668,
			47.73,
			47.792,
			47.855,
			47.916,
			47.978,
			48.009,
			48.041,
			48.118,
			48.165,
			48.228,
			48.288,
			48.335,
			48.352,
			48.414,
			48.442,
			48.474,
			48.538,
			48.598,
			48.661,
			48.721,
			48.785,
			48.848,
			48.878,
			48.909,
			48.97,
			48.987,
			49.034,
			49.095,
			49.155,
			49.219,
			49.28,
			49.313,
			49.344,
			49.405,
			49.422,
			49.466,
			49.53,
			49.591,
			49.641,
			49.714,
			49.745,
			49.777,
			49.84,
			49.901,
			49.968,
			50.024,
			50.072,
			50.087,
			50.148,
			50.18,
			50.211,
			50.289,
			50.336,
			50.397,
			50.458,
			50.507,
			50.521,
			50.581,
			50.615,
			50.646,
			50.706,
			50.723,
			50.769,
			50.831,
			50.894,
			50.938,
			51.018,
			51.048,
			51.078,
			51.157,
			51.202,
			51.266,
			51.327,
			51.374,
			51.388,
			51.453,
			51.481,
			51.514,
			51.576,
			51.591,
			51.637,
			51.703,
			51.761,
			51.809,
			51.823,
			51.886,
			51.915,
			51.946,
			52.01,
			52.024,
			52.072,
			52.135,
			52.194,
			52.242,
			52.321,
			52.351,
			52.381,
			52.444,
			52.505,
			52.571,
			52.631,
			52.677,
			52.691,
			52.754,
			52.785,
			52.815,
			52.877,
			52.894,
			52.939,
			53.003,
			53.063,
			53.111,
			53.188,
			53.22,
			53.248,
			53.311,
			53.374,
			53.436,
			53.497,
			53.545,
			53.561,
			53.621,
			53.654,
			53.684,
			53.747,
			53.764,
			53.809,
			53.869,
			53.93,
			53.978,
			53.995,
			54.055,
			54.086,
			54.118,
			54.179,
			54.195,
			54.241,
			54.303,
			54.364,
			54.413,
			54.488,
			54.521,
			54.553,
			54.614,
			54.675,
			54.739,
			54.798,
			54.847,
			54.862,
			54.925,
			54.954,
			54.986,
			55.047,
			55.064,
			55.109,
			55.172,
			55.234,
			55.28,
			55.295,
			55.358,
			55.389,
			55.421,
			55.497,
			55.543,
			55.605,
			55.669,
			55.714,
			55.792,
			55.828,
			55.853,
			55.916,
			55.979,
			56.039,
			56.103,
			56.148,
			56.165,
			56.227,
			56.257,
			56.288,
			56.365,
			56.412,
			56.475,
			56.536,
			56.582,
			56.598,
			56.659,
			56.692,
			56.721,
			56.785,
			56.801,
			56.847,
			56.908,
			56.971,
			57.03,
			57.094,
			57.124,
			57.156,
			57.219,
			57.279,
			57.344,
			57.403,
			57.449,
			57.465,
			57.528,
			57.559,
			57.589,
			57.653,
			57.669,
			57.713,
			57.776,
			57.838,
			57.889,
			57.962,
			57.992,
			58.025,
			58.087,
			58.148,
			58.21,
			58.271,
			58.319,
			58.397,
			58.428,
			58.459,
			58.536,
			58.581,
			58.644,
			58.706,
			58.753,
			58.769,
			58.831,
			58.861,
			58.892,
			58.956,
			58.969,
			59.016,
			59.079,
			59.141,
			59.188,
			59.264,
			59.296,
			59.328,
			59.405,
			59.449,
			59.513,
			59.575,
			59.636,
			59.698,
			59.729,
			59.761,
			59.822,
			59.839,
			59.886,
			59.953,
			60.008,
			60.055,
			60.072,
			60.131,
			60.165,
			60.194,
			60.255,
			60.272,
			60.316,
			60.38,
			60.444,
			60.489,
			60.568,
			60.597,
			60.628,
			60.69,
			60.754,
			60.816,
			60.876,
			60.924,
			60.938,
			61.001,
			61.033,
			61.064,
			61.141,
			61.188,
			61.25,
			61.31,
			61.358,
			61.372,
			61.436,
			61.466,
			61.498,
			61.559,
			61.576,
			61.622,
			61.681,
			61.745,
			61.807,
			61.87,
			61.901,
			61.93,
			61.992,
			62.015,
			62.056,
			62.117,
			62.179,
			62.242,
			62.303,
			62.335,
			62.364,
			62.441,
			62.488,
			62.549,
			62.613,
			62.674,
			62.738,
			62.766,
			62.798,
			62.877,
			62.924,
			62.986,
			63.047,
			63.094,
			63.111,
			63.172,
			63.203,
			63.232,
			63.295,
			63.312,
			63.358,
			63.42,
			63.48,
			63.528,
			63.544,
			63.605,
			63.636,
			63.667,
			63.73,
			63.744,
			63.792,
			63.853,
			63.915,
			63.961,
			63.977,
			64.041,
			64.075,
			64.103,
			64.165,
			64.222
		]
	],
	'6340_6579_7982': [
		[
			60.955
		],
		[
			0
		],
		[
			0,
			1.357,
			5.989,
			9.083,
			12.182,
			13.733,
			18.396,
			21.512,
			24.633,
			29.324,
			32.459,
			37.17,
			38.742,
			40.316,
			45.039,
			48.191,
			52.931,
			56.099,
			59.273,
			60.862,
			64.044,
			65.637,
			68.822,
			72.011,
			73.608,
			75.205,
			80.003,
			83.207,
			86.415,
			88.021,
			91.236,
			94.455,
			96.066,
			100.906,
			104.138,
			107.374,
			112.235,
			115.482,
			120.36,
			121.988,
			123.617,
			128.51,
			131.777,
			136.686,
			139.965,
			143.246,
			144.889,
			148.177,
			149.822,
			153.117,
			156.416,
			158.066,
			161.37,
			164.679,
			167.991,
			171.308,
			172.967,
			176.291,
			179.618,
			181.283,
			184.616,
			186.285,
			189.623,
			192.966,
			197.988,
			201.34,
			206.376,
			208.057,
			209.738,
			214.789,
			218.16,
			223.225,
			226.605,
			229.989,
			231.683,
			235.07,
			236.765,
			240.157,
			243.553,
			245.252,
			248.654,
			252.061,
			257.178,
			260.59,
			265.711,
			267.419,
			269.128,
			274.265,
			277.697,
			281.133,
			286.295,
			289.741,
			294.915,
			296.641,
			298.368,
			303.556,
			307.019,
			312.223,
			315.703,
			320.944,
			324.454,
			327.976,
			329.735,
			335.004,
			338.511,
			342.025,
			347.308,
			350.828,
			356.097,
			357.852,
			359.608,
			364.879,
			368.395,
			373.675,
			377.201,
			380.73,
			382.496,
			386.029,
			387.795,
			389.56,
			396.578,
			398.316,
			403.48,
			406.876,
			410.234,
			411.899,
			415.2,
			418.465,
			420.082,
			424.878,
			428.028,
			431.139,
			435.733,
			438.749,
			443.208,
			444.676,
			446.135,
			450.454,
			453.284,
			457.457,
			460.191,
			462.885,
			464.217,
			466.852,
			468.154,
			470.73,
			473.267,
			474.521,
			477.001,
			479.443,
			481.85,
			484.222,
			485.396,
			488.868,
			490.007,
			491.136,
			493.372,
			494.475,
			496.655,
			498.8,
			501.942,
			503.98,
			506.963,
			507.936,
			508.899,
			511.734,
			513.584,
			516.295,
			518.063,
			519.797,
			520.651,
			522.329,
			523.154,
			524.776,
			526.361,
			527.139,
			528.663,
			530.153,
			532.32,
			533.025,
			533.722,
			535.76,
			536.424,
			537.079,
			539.007,
			540.26,
			542.053,
			543.182,
			544.274,
			545.848,
			546.358,
			546.861,
			548.311,
			548.78,
			549.243,
			550.602,
			551.488,
			552.367,
			552.808,
			553.68,
			554.543,
			554.973,
			555.832,
			556.262,
			557.121,
			558.005,
			559.323,
			560.199,
			561.519,
			561.961,
			562.414,
			563.798,
			564.722,
			566.116,
			567.056,
			568.065,
			569.601,
			570.116,
			571.152,
			572.199,
			572.763,
			573.334,
			575.051,
			576.2,
			577.355,
			577.944,
			579.119,
			580.291,
			580.877,
			582.049,
			582.639,
			583.82,
			584.992,
			586.744,
			587.925,
			589.712,
			590.305,
			590.898,
			592.687,
			593.903,
			595.721,
			596.937,
			598.878,
			600.206,
			600.872,
			602.208,
			603.656,
			604.401,
			605.896,
			607.399,
			608.792,
			610.166,
			610.855,
			612.242,
			613.497,
			614.094,
			615.292,
			615.894,
			617.107,
			618.268,
			619.932,
			621.053,
			622.756,
			623.316,
			623.875,
			625.55,
			626.674,
			628.408,
			629.617,
			631.45,
			632.689,
			633.334,
			634.648,
			636.645,
			637.998,
			639.389,
			641.503,
			642.216,
			642.932,
			645.129,
			645.87,
			646.614,
			648.114,
			648.875,
			650.406,
			651.947,
			654.279,
			655.852,
			658.225,
			659.027,
			659.832,
			662.27,
			663.914,
			666.406,
			668.084,
			669.769,
			670.61,
			672.295,
			673.141,
			674.836,
			676.542,
			677.399,
			679.123,
			680.855,
			683.478,
			685.25,
			687.934,
			688.835,
			689.742,
			692.481,
			694.324,
			697.11,
			698.98,
			700.86,
			701.805,
			703.703,
			704.656,
			705.613,
			708.5,
			709.468,
			710.44,
			713.37,
			715.335,
			718.301,
			720.292,
			722.291,
			723.294,
			726.32,
			728.351,
			730.41,
			733.478,
			735.549,
			738.676,
			739.723,
			740.773,
			743.918,
			746.025,
			749.206,
			751.352,
			753.511,
			754.594,
			756.765,
			757.854,
			760.037,
			762.217,
			763.311,
			765.505,
			767.707,
			769.92,
			772.143,
			773.259,
			775.502,
			777.764,
			778.898,
			781.172,
			782.312,
			784.6,
			786.909,
			790.395,
			792.732,
			796.243,
			797.409,
			798.578,
			802.096,
			804.454,
			808.005,
			810.384,
			812.772,
			813.969,
			816.371,
			817.574,
			819.989,
			823.626,
			826.063,
			828.513,
			832.208,
			833.446,
			834.685,
			838.418,
			839.666,
			840.917,
			844.682,
			847.203,
			849.733,
			853.544,
			856.096,
			859.94,
			861.226,
			862.514,
			866.39,
			868.984,
			872.882,
			875.485,
			878.096,
			879.404,
			882.026,
			884.655,
			885.973,
			889.938,
			892.59,
			895.249,
			899.254,
			900.593,
			901.932,
			905.962,
			907.307,
			908.654,
			912.701,
			915.405,
			919.474,
			922.196,
			924.928,
			929.043,
			930.419,
			931.797,
			935.945,
			937.331,
			938.719,
			942.892,
			945.681,
			948.471,
			949.868,
			952.667,
			955.471,
			956.878,
			959.697,
			961.109,
			963.935,
			966.754,
			970.952,
			973.719,
			977.804,
			979.148,
			980.483,
			984.431,
			987.016,
			990.824,
			993.316,
			995.772,
			996.985,
			999.385,
			1000.573,
			1002.921,
			1005.236,
			1006.379,
			1008.637,
			1010.859,
			1013.042,
			1015.188,
			1016.247,
			1018.338,
			1020.392,
			1021.405,
			1023.403,
			1024.388,
			1026.33,
			1028.236,
			1031.026,
			1032.844,
			1035.506,
			1036.377,
			1037.238,
			1039.767,
			1041.408,
			1043.801,
			1045.35,
			1046.864,
			1047.609,
			1049.07,
			1049.787,
			1051.195,
			1053.247,
			1054.574,
			1055.868,
			1057.758,
			1058.365,
			1058.964,
			1060.135,
			1061.27,
			1061.826,
			1062.913,
			1063.445,
			1064.487,
			1065.506,
			1066.008,
			1066.993,
			1067.942,
			1069.339,
			1069.798,
			1070.256,
			1071.625,
			1072.068,
			1072.504,
			1073.804,
			1074.668,
			1075.532,
			1075.965,
			1076.835,
			1077.279,
			1078.165,
			1079.049,
			1079.492,
			1080.381,
			1081.276,
			1082.712,
			1083.707,
			1085.203,
			1085.703,
			1086.204,
			1087.935,
			1089.198,
			1091.09,
			1092.524,
			1094.237,
			1095.09,
			1096.797,
			1097.663,
			1098.528,
			1101.975,
			1102.7,
			1104.863,
			1106.302,
			1108.306,
			1109.59,
			1110.869,
			1111.509,
			1112.698,
			1113.267,
			1114.401,
			1115.539,
			1117.231,
			1118.274,
			1119.844,
			1120.369,
			1120.896,
			1122.471,
			1123.504,
			1125.061,
			1126.108,
			1127.169,
			1127.713,
			1128.808,
			1129.358,
			1130.466,
			1131.585,
			1132.154,
			1133.329,
			1134.516,
			1135.716,
			1136.929,
			1137.551,
			1138.808,
			1140.079,
			1140.721,
			1142.016,
			1142.675,
			1144.005,
			1145.349,
			1147.398,
			1148.79,
			1150.907,
			1151.62,
			1152.339,
			1154.529,
			1155.996,
			1158.243,
			1159.76,
			1161.293,
			1162.065,
			1163.609,
			1165.168,
			1165.954,
			1167.533,
			1168.324,
			1169.919,
			1171.528,
			1173.965,
			1174.784,
			1175.607,
			1178.099,
			1178.94,
			1179.784,
			1182.323,
			1184.047,
			1186.656,
			1188.442,
			1190.275,
			1193.038,
			1193.976,
			1194.917,
			1197.756,
			1199.653,
			1202.514,
			1204.421,
			1206.334,
			1207.294,
			1209.236,
			1210.209,
			1212.161,
			1214.142,
			1215.136,
			1217.126,
			1219.14,
			1222.17,
			1223.181,
			1224.192,
			1227.227,
			1228.235,
			1229.242,
			1232.266,
			1234.258,
			1237.246,
			1239.212,
			1241.176,
			1244.12,
			1245.098,
			1246.079,
			1249.027,
			1250.012,
			1250.999,
			1253.97,
			1255.96,
			1258.954,
			1260.952,
			1262.959,
			1263.957,
			1265.957,
			1266.96,
			1268.962,
			1270.965,
			1273.978,
			1275.986,
			1279.013,
			1280.023,
			1281.036,
			1284.083,
			1286.122,
			1289.207,
			1291.287,
			1293.38,
			1294.437,
			1296.557,
			1297.62,
			1299.759,
			1301.907,
			1302.982,
			1304.058,
			1307.289,
			1309.42,
			1311.536,
			1312.598,
			1314.677,
			1316.748,
			1317.786,
			1319.853,
			1320.887,
			1322.96,
			1325.081,
			1328.282,
			1330.488,
			1333.821,
			1334.953,
			1336.086,
			1339.507,
			1341.806,
			1345.271,
			1347.59,
			1351.057,
			1353.361,
			1354.5,
			1356.763,
			1359.002,
			1360.105,
			1362.287,
			1364.404,
			1367.505,
			1368.521,
			1369.519,
			1371.486,
			1373.437,
			1374.397,
			1377.236,
			1379.093,
			1380.937,
			1383.643,
			1385.426,
			1388.042,
			1388.901,
			1389.758,
			1392.3,
			1393.978,
			1396.47,
			1398.123,
			1400.596,
			1402.242,
			1403.89,
			1404.716,
			1406.368,
			1407.194,
			1408.846,
			1410.496,
			1412.967,
			1414.61,
			1417.06,
			1417.876,
			1418.693,
			1421.146,
			1422.78,
			1425.261,
			1426.93,
			1428.597,
			1429.44,
			1431.141,
			1431.99,
			1432.839,
			1436.318,
			1437.198,
			1439.873,
			1441.713,
			1443.554,
			1444.486,
			1446.38,
			1448.275,
			1449.225,
			1451.15,
			1452.113,
			1454.043,
			1455.982,
			1458.894,
			1460.91,
			1463.957,
			1464.996,
			1466.044,
			1469.19,
			1471.271,
			1474.392,
			1476.424,
			1478.46,
			1479.475,
			1481.433,
			1483.396,
			1484.379,
			1487.135,
			1488.057,
			1490.726,
			1492.444,
			1494.168,
			1495.023,
			1496.728,
			1498.438,
			1499.297,
			1501.951,
			1503.734,
			1505.551,
			1508.32,
			1510.191,
			1513.044,
			1514.002,
			1514.968,
			1517.9,
			1519.875,
			1522.887,
			1524.91,
			1527.968,
			1530.019,
			1531.044,
			1533.104,
			1536.21,
			1538.291,
			1540.382,
			1542.481,
			1544.563,
			1545.611,
			1548.768,
			1549.825,
			1550.884,
			1554.073,
			1556.21,
			1558.357,
			1561.595,
			1563.765,
			1567.04,
			1568.136,
			1569.235,
			1572.545,
			1574.764,
			1578.109,
			1580.351,
			1582.604,
			1583.733,
			1586,
			1587.137,
			1589.419,
			1591.71,
			1592.86,
			1595.166,
			1597.481,
			1600.971,
			1603.307,
			1606.83,
			1608.008,
			1609.189,
			1612.747,
			1615.133,
			1618.732,
			1621.144,
			1623.566,
			1627.217,
			1628.438,
			1629.662,
			1633.349,
			1634.582,
			1635.818,
			1639.541,
			1642.035,
			1645.792,
			1648.308,
			1650.832,
			1652.098,
			1654.636,
			1655.908,
			1658.458,
			1661.017,
			1664.873,
			1667.453,
			1671.344,
			1672.65,
			1673.955,
			1677.875,
			1680.478,
			1684.322,
			1686.832,
			1689.298,
			1690.517,
			1692.925,
			1694.117,
			1696.477,
			1699.957,
			1701.118,
			1704.491,
			1706.697,
			1708.868,
			1709.942,
			1712.067,
			1714.168,
			1715.212,
			1718.31,
			1720.364,
			1722.413,
			1725.483,
			1727.532,
			1730.607,
			1731.632,
			1732.657,
			1735.73,
			1737.772,
			1740.831,
			1742.862,
			1744.892,
			1745.906,
			1747.946,
			1748.965,
			1751.006,
			1753.082,
			1754.122,
			1756.206,
			1758.347,
			1761.587,
			1762.71,
			1763.835,
			1766.093,
			1768.488,
			1769.693,
			1773.386,
			1775.891,
			1778.37,
			1782.083,
			1784.527,
			1788.191,
			1789.411,
			1790.634,
			1794.251,
			1796.661,
			1800.235,
			1802.63,
			1806.241,
			1808.678,
			1809.901,
			1812.359,
			1814.834,
			1816.074,
			1818.527,
			1820.974,
			1824.53,
			1826.881,
			1830.298,
			1831.44,
			1832.581,
			1836.008,
			1838.333,
			1841.859,
			1844.246,
			1847.844,
			1850.25,
			1851.457,
			1852.665,
			1857.515,
			1858.737,
			1862.397,
			1864.87,
			1867.352,
			1868.599,
			1871.099,
			1873.609,
			1874.867,
			1877.391,
			1878.656,
			1881.194,
			1883.771,
			1887.63,
			1890.22,
			1894.104,
			1895.396,
			1896.688,
			1900.573,
			1903.151,
			1907.108,
			1909.816,
			1912.507,
			1913.867,
			1916.589,
			1917.946,
			1920.638,
			1923.313,
			1924.626,
			1927.246,
			1929.842,
			1932.427,
			1935.027,
			1936.33,
			1938.936,
			1941.545,
			1942.852,
			1945.466,
			1946.775,
			1949.387,
			1951.995,
			1955.872,
			1958.448,
			1962.282,
			1963.563,
			1964.846,
			1968.706,
			1971.299,
			1975.21,
			1977.833,
			1980.471,
			1981.805,
			1984.521,
			1985.883,
			1988.599,
			1991.331,
			1992.686,
			1995.364,
			1998.003,
			2001.78,
			2003.023,
			2004.232,
			2006.601,
			2008.93,
			2010.091,
			2012.348,
			2013.463,
			2015.656,
			2017.813,
			2020.97,
			2023.029,
			2026.045,
			2027.033,
			2028.011,
			2030.892,
			2032.77,
			2035.52,
			2037.31,
			2039.064,
			2039.924,
			2041.624,
			2042.464,
			2044.126,
			2045.76,
			2046.57,
			2048.181,
			2049.776,
			2052.162,
			2052.96,
			2053.759,
			2056.163,
			2056.966,
			2057.769,
			2060.201,
			2061.832,
			2064.285,
			2065.945,
			2067.6,
			2070.066,
			2070.883,
			2071.698,
			2074.126,
			2074.916,
			2075.702,
			2078.048,
			2079.593,
			2081.115,
			2081.873,
			2083.382,
			2084.888,
			2085.638,
			2087.13,
			2087.873,
			2089.358,
			2090.848,
			2093.065,
			2094.542,
			2096.761,
			2097.499,
			2098.237,
			2100.457,
			2101.937,
			2104.151,
			2105.628,
			2107.099,
			2107.833,
			2109.298,
			2110.026,
			2111.477,
			2112.924,
			2113.646,
			2114.371,
			2116.551,
			2118,
			2120.194,
			2121.677,
			2123.163,
			2123.907,
			2125.438,
			2126.209,
			2127.754,
			2129.305,
			2131.715,
			2133.322,
			2135.785,
			2136.615,
			2137.444,
			2139.946,
			2141.631,
			2144.154,
			2145.883,
			2147.609,
			2148.471,
			2150.26,
			2151.184,
			2153.027,
			2154.899,
			2155.875,
			2157.822,
			2159.781,
			2162.812,
			2163.819,
			2164.822,
			2166.81,
			2168.794,
			2169.759,
			2171.587,
			2172.5,
			2174.323,
			2175.965,
			2178.408,
			2179.997,
			2182.251,
			2183.006,
			2183.762,
			2186.01,
			2187.508,
			2189.774,
			2191.308,
			2192.853,
			2193.631,
			2195.202,
			2195.997,
			2197.596,
			2199.207,
			2200.025,
			2201.685,
			2203.357,
			2205.905,
			2206.765,
			2207.628,
			2210.243,
			2211.126,
			2212.013,
			2214.694,
			2216.507,
			2218.332,
			2221.098,
			2222.96,
			2225.777,
			2226.723,
			2227.671,
			2231.491,
			2232.453,
			2235.338,
			2237.284,
			2240.225,
			2242.198,
			2244.183,
			2245.18,
			2247.184,
			2248.189,
			2250.21,
			2252.243,
			2255.315,
			2257.378,
			2260.494,
			2261.537,
			2262.584,
			2265.727,
			2267.822,
			2270.978,
			2273.091,
			2275.216,
			2276.282,
			2278.423,
			2279.498,
			2281.657,
			2283.829,
			2284.918,
			2286.011,
			2289.305,
			2291.514,
			2293.733,
			2294.847,
			2297.081,
			2299.326,
			2300.452,
			2303.846,
			2306.121,
			2308.406,
			2311.851,
			2314.161,
			2317.639,
			2318.803,
			2319.969,
			2323.477,
			2325.829,
			2329.377,
			2331.756,
			2334.146,
			2335.344,
			2337.749,
			2338.954,
			2341.372,
			2343.8,
			2345.017,
			2347.458,
			2349.909,
			2353.6,
			2354.835,
			2356.072,
			2358.553,
			2361.042,
			2362.291,
			2366.05,
			2368.569,
			2371.097,
			2374.905,
			2377.455,
			2381.296,
			2382.58,
			2383.866,
			2387.737,
			2390.327,
			2394.229,
			2396.838,
			2399.447,
			2400.753,
			2403.369,
			2404.68,
			2407.307,
			2409.942,
			2411.261,
			2413.906,
			2416.557,
			2420.547,
			2423.216,
			2427.232,
			2428.575,
			2429.919,
			2433.961,
			2436.665,
			2439.375,
			2443.453,
			2446.179,
			2450.282,
			2451.653,
			2453.024,
			2457.147,
			2458.523,
			2459.902,
			2464.049,
			2466.823,
			2469.606,
			2471,
			2473.795,
			2476.597,
			2478,
			2480.812,
			2482.221,
			2485.043,
			2487.872,
			2492.129,
			2494.975,
			2499.256,
			2500.687,
			2502.119,
			2506.424,
			2509.303,
			2513.635,
			2516.53,
			2519.432,
			2520.885,
			2523.796,
			2525.254,
			2528.175,
			2531.102,
			2532.568,
			2534.035,
			2538.447,
			2541.396,
			2544.352,
			2545.832,
			2548.796,
			2551.768,
			2553.255,
			2556.235,
			2557.728,
			2560.717,
			2563.712,
			2568.216,
			2571.227,
			2575.754,
			2577.266,
			2578.779,
			2583.328,
			2586.367,
			2590.937,
			2593.991,
			2597.05,
			2598.582,
			2601.65,
			2603.187,
			2603.651
		],
		[
			0,
			0.032,
			0.096,
			0.157,
			0.187,
			0.218,
			0.295,
			0.342,
			0.405,
			0.465,
			0.514,
			0.591,
			0.62,
			0.653,
			0.716,
			0.777,
			0.839,
			0.9,
			0.947,
			0.964,
			1.023,
			1.056,
			1.087,
			1.149,
			1.165,
			1.211,
			1.273,
			1.334,
			1.381,
			1.397,
			1.46,
			1.49,
			1.521,
			1.599,
			1.643,
			1.706,
			1.769,
			1.831,
			1.893,
			1.923,
			1.954,
			2.017,
			2.077,
			2.141,
			2.203,
			2.248,
			2.264,
			2.327,
			2.357,
			2.389,
			2.451,
			2.467,
			2.513,
			2.575,
			2.636,
			2.683,
			2.7,
			2.761,
			2.797,
			2.823,
			2.885,
			2.898,
			2.946,
			3.009,
			3.072,
			3.116,
			3.194,
			3.225,
			3.257,
			3.335,
			3.381,
			3.444,
			3.506,
			3.551,
			3.566,
			3.628,
			3.66,
			3.692,
			3.754,
			3.769,
			3.814,
			3.877,
			3.94,
			4,
			4.062,
			4.093,
			4.124,
			4.187,
			4.25,
			4.31,
			4.374,
			4.436,
			4.498,
			4.529,
			4.56,
			4.621,
			4.683,
			4.744,
			4.808,
			4.869,
			4.932,
			4.962,
			4.993,
			5.071,
			5.117,
			5.181,
			5.24,
			5.288,
			5.364,
			5.397,
			5.428,
			5.49,
			5.552,
			5.613,
			5.676,
			5.722,
			5.739,
			5.8,
			5.83,
			5.861,
			5.94,
			5.985,
			6.048,
			6.108,
			6.156,
			6.173,
			6.234,
			6.263,
			6.295,
			6.372,
			6.42,
			6.481,
			6.542,
			6.59,
			6.667,
			6.7,
			6.729,
			6.807,
			6.854,
			6.921,
			6.976,
			7.024,
			7.039,
			7.102,
			7.133,
			7.162,
			7.225,
			7.242,
			7.287,
			7.349,
			7.411,
			7.459,
			7.475,
			7.537,
			7.567,
			7.598,
			7.66,
			7.674,
			7.721,
			7.782,
			7.847,
			7.891,
			7.971,
			7.999,
			8.033,
			8.094,
			8.154,
			8.216,
			8.281,
			8.327,
			8.34,
			8.405,
			8.434,
			8.465,
			8.527,
			8.543,
			8.591,
			8.654,
			8.713,
			8.76,
			8.775,
			8.838,
			8.87,
			8.901,
			8.963,
			9.025,
			9.087,
			9.149,
			9.195,
			9.271,
			9.303,
			9.333,
			9.395,
			9.412,
			9.457,
			9.521,
			9.583,
			9.627,
			9.645,
			9.705,
			9.738,
			9.766,
			9.831,
			9.845,
			9.892,
			9.955,
			10.017,
			10.063,
			10.139,
			10.171,
			10.2,
			10.265,
			10.327,
			10.388,
			10.45,
			10.497,
			10.574,
			10.606,
			10.638,
			10.697,
			10.715,
			10.761,
			10.822,
			10.883,
			10.931,
			10.945,
			11.008,
			11.044,
			11.072,
			11.133,
			11.147,
			11.193,
			11.258,
			11.317,
			11.38,
			11.442,
			11.473,
			11.505,
			11.583,
			11.628,
			11.69,
			11.754,
			11.816,
			11.878,
			11.906,
			11.94,
			11.999,
			12.015,
			12.063,
			12.124,
			12.186,
			12.233,
			12.249,
			12.31,
			12.34,
			12.372,
			12.434,
			12.45,
			12.496,
			12.558,
			12.62,
			12.668,
			12.746,
			12.777,
			12.806,
			12.87,
			12.93,
			12.992,
			13.054,
			13.116,
			13.177,
			13.21,
			13.24,
			13.319,
			13.365,
			13.431,
			13.488,
			13.535,
			13.55,
			13.614,
			13.643,
			13.674,
			13.737,
			13.753,
			13.799,
			13.861,
			13.921,
			13.969,
			14.048,
			14.079,
			14.108,
			14.172,
			14.232,
			14.295,
			14.357,
			14.404,
			14.418,
			14.48,
			14.511,
			14.543,
			14.606,
			14.619,
			14.666,
			14.728,
			14.791,
			14.839,
			14.915,
			14.945,
			14.976,
			15.055,
			15.102,
			15.165,
			15.224,
			15.272,
			15.288,
			15.348,
			15.381,
			15.41,
			15.472,
			15.489,
			15.535,
			15.597,
			15.659,
			15.722,
			15.782,
			15.815,
			15.846,
			15.921,
			15.969,
			16.032,
			16.094,
			16.139,
			16.215,
			16.247,
			16.279,
			16.341,
			16.404,
			16.466,
			16.528,
			16.572,
			16.589,
			16.653,
			16.681,
			16.714,
			16.775,
			16.791,
			16.837,
			16.898,
			16.96,
			17.009,
			17.024,
			17.086,
			17.116,
			17.149,
			17.208,
			17.229,
			17.271,
			17.334,
			17.397,
			17.441,
			17.519,
			17.552,
			17.58,
			17.66,
			17.707,
			17.767,
			17.83,
			17.875,
			17.891,
			17.955,
			17.984,
			18.017,
			18.094,
			18.14,
			18.201,
			18.263,
			18.31,
			18.326,
			18.387,
			18.417,
			18.45,
			18.513,
			18.572,
			18.635,
			18.698,
			18.76,
			18.821,
			18.853,
			18.885,
			18.961,
			19.009,
			19.071,
			19.133,
			19.179,
			19.193,
			19.254,
			19.291,
			19.317,
			19.395,
			19.441,
			19.505,
			19.566,
			19.611,
			19.629,
			19.689,
			19.72,
			19.752,
			19.813,
			19.875,
			19.937,
			19.998,
			20.046,
			20.125,
			20.154,
			20.185,
			20.248,
			20.263,
			20.311,
			20.372,
			20.434,
			20.481,
			20.495,
			20.559,
			20.589,
			20.621,
			20.681,
			20.697,
			20.745,
			20.806,
			20.867,
			20.914,
			20.992,
			21.022,
			21.055,
			21.117,
			21.178,
			21.241,
			21.303,
			21.354,
			21.365,
			21.425,
			21.458,
			21.489,
			21.548,
			21.565,
			21.613,
			21.674,
			21.736,
			21.781,
			21.797,
			21.86,
			21.89,
			21.921,
			21.983,
			21.999,
			22.045,
			22.108,
			22.17,
			22.215,
			22.295,
			22.324,
			22.357,
			22.42,
			22.482,
			22.543,
			22.604,
			22.649,
			22.667,
			22.729,
			22.761,
			22.789,
			22.867,
			22.914,
			22.977,
			23.04,
			23.085,
			23.1,
			23.161,
			23.192,
			23.223,
			23.287,
			23.303,
			23.348,
			23.409,
			23.414,
			23.471,
			23.519,
			23.595,
			23.629,
			23.659,
			23.721,
			23.737,
			23.784,
			23.845,
			23.906,
			23.954,
			23.968,
			24.031,
			24.06,
			24.092,
			24.155,
			24.171,
			24.217,
			24.28,
			24.34,
			24.388,
			24.464,
			24.495,
			24.528,
			24.588,
			24.651,
			24.714,
			24.774,
			24.821,
			24.837,
			24.898,
			24.93,
			24.96,
			25.038,
			25.084,
			25.148,
			25.209,
			25.271,
			25.331,
			25.364,
			25.396,
			25.457,
			25.477,
			25.518,
			25.584,
			25.643,
			25.69,
			25.767,
			25.798,
			25.828,
			25.891,
			25.953,
			26.016,
			26.078,
			26.123,
			26.139,
			26.202,
			26.233,
			26.263,
			26.324,
			26.341,
			26.387,
			26.449,
			26.51,
			26.557,
			26.573,
			26.634,
			26.667,
			26.696,
			26.76,
			26.774,
			26.821,
			26.883,
			26.946,
			26.992,
			27.069,
			27.1,
			27.132,
			27.21,
			27.254,
			27.318,
			27.38,
			27.424,
			27.441,
			27.505,
			27.54,
			27.565,
			27.627,
			27.641,
			27.688,
			27.752,
			27.814,
			27.86,
			27.876,
			27.938,
			27.97,
			27.998,
			28.076,
			28.122,
			28.187,
			28.248,
			28.293,
			28.371,
			28.402,
			28.433,
			28.494,
			28.558,
			28.621,
			28.681,
			28.727,
			28.745,
			28.805,
			28.837,
			28.867,
			28.93,
			28.944,
			28.991,
			29.053,
			29.116,
			29.161,
			29.178,
			29.24,
			29.272,
			29.3,
			29.364,
			29.425,
			29.49,
			29.55,
			29.602,
			29.675,
			29.705,
			29.734,
			29.797,
			29.814,
			29.86,
			29.922,
			29.982,
			30.047,
			30.107,
			30.138,
			30.169,
			30.23,
			30.246,
			30.294,
			30.356,
			30.417,
			30.464,
			30.541,
			30.571,
			30.604,
			30.667,
			30.727,
			30.79,
			30.852,
			30.897,
			30.914,
			30.974,
			31.008,
			31.039,
			31.101,
			31.114,
			31.161,
			31.224,
			31.286,
			31.333,
			31.347,
			31.411,
			31.442,
			31.472,
			31.533,
			31.55,
			31.597,
			31.662,
			31.721,
			31.765,
			31.843,
			31.875,
			31.905,
			31.966,
			32.03,
			32.093,
			32.154,
			32.217,
			32.278,
			32.308,
			32.34,
			32.402,
			32.416,
			32.464,
			32.527,
			32.588,
			32.633,
			32.65,
			32.711,
			32.743,
			32.775,
			32.837,
			32.899,
			32.96,
			33.021,
			33.069,
			33.145,
			33.178,
			33.207,
			33.271,
			33.331,
			33.393,
			33.456,
			33.519,
			33.579,
			33.612,
			33.641,
			33.704,
			33.723,
			33.766,
			33.829,
			33.889,
			33.938,
			34.015,
			34.046,
			34.077,
			34.154,
			34.198,
			34.264,
			34.326,
			34.372,
			34.388,
			34.447,
			34.48,
			34.51,
			34.587,
			34.635,
			34.697,
			34.758,
			34.806,
			34.822,
			34.883,
			34.913,
			34.946,
			35.007,
			35.023,
			35.069,
			35.13,
			35.192,
			35.238,
			35.315,
			35.348,
			35.38,
			35.442,
			35.502,
			35.564,
			35.626,
			35.673,
			35.688,
			35.751,
			35.787,
			35.814,
			35.889,
			35.936,
			36,
			36.06,
			36.108,
			36.124,
			36.185,
			36.215,
			36.247,
			36.323,
			36.371,
			36.434,
			36.496,
			36.542,
			36.62,
			36.649,
			36.681,
			36.743,
			36.804,
			36.866,
			36.928,
			36.991,
			37.054,
			37.084,
			37.115,
			37.193,
			37.238,
			37.301,
			37.362,
			37.408,
			37.425,
			37.487,
			37.519,
			37.55,
			37.612,
			37.672,
			37.735,
			37.796,
			37.848,
			37.921,
			37.951,
			37.984,
			38.06,
			38.106,
			38.17,
			38.231,
			38.277,
			38.293,
			38.357,
			38.386,
			38.416,
			38.478,
			38.496,
			38.542,
			38.604,
			38.664,
			38.726,
			38.789,
			38.819,
			38.851,
			38.914,
			38.975,
			39.038,
			39.099,
			39.161,
			39.222,
			39.255,
			39.286,
			39.348,
			39.363,
			39.41,
			39.471,
			39.534,
			39.596,
			39.656,
			39.687,
			39.717,
			39.781,
			39.798,
			39.844,
			39.904,
			39.969,
			40.014,
			40.091,
			40.121,
			40.154,
			40.216,
			40.277,
			40.341,
			40.402,
			40.448,
			40.464,
			40.526,
			40.557,
			40.588,
			40.665,
			40.711,
			40.774,
			40.834,
			40.882,
			40.897,
			40.958,
			40.99,
			41.022,
			41.098,
			41.145,
			41.207,
			41.27,
			41.317,
			41.392,
			41.423,
			41.455,
			41.516,
			41.579,
			41.642,
			41.704,
			41.751,
			41.767,
			41.826,
			41.858,
			41.89,
			41.952,
			41.972,
			42.014,
			42.075,
			42.138,
			42.182,
			42.198,
			42.261,
			42.291,
			42.323,
			42.402,
			42.447,
			42.51,
			42.573,
			42.617,
			42.697,
			42.726,
			42.757,
			42.82,
			42.883,
			42.943,
			43.007,
			43.069,
			43.13,
			43.161,
			43.192,
			43.254,
			43.271,
			43.315,
			43.378,
			43.44,
			43.503,
			43.564,
			43.595,
			43.625,
			43.687,
			43.749,
			43.813,
			43.874,
			43.937,
			43.998,
			44.032,
			44.059,
			44.137,
			44.184,
			44.251,
			44.307,
			44.354,
			44.369,
			44.432,
			44.464,
			44.493,
			44.555,
			44.572,
			44.619,
			44.682,
			44.743,
			44.805,
			44.865,
			44.896,
			44.928,
			44.99,
			45.053,
			45.115,
			45.176,
			45.221,
			45.238,
			45.3,
			45.332,
			45.364,
			45.423,
			45.44,
			45.487,
			45.55,
			45.61,
			45.657,
			45.673,
			45.736,
			45.765,
			45.797,
			45.858,
			45.874,
			45.921,
			45.982,
			46.044,
			46.097,
			46.167,
			46.198,
			46.23,
			46.293,
			46.354,
			46.416,
			46.478,
			46.525,
			46.539,
			46.603,
			46.632,
			46.665,
			46.726,
			46.742,
			46.788,
			46.849,
			46.913,
			46.96,
			46.974,
			47.036,
			47.066,
			47.098,
			47.161,
			47.175,
			47.222,
			47.283,
			47.347,
			47.393,
			47.471,
			47.501,
			47.532,
			47.593,
			47.655,
			47.72,
			47.779,
			47.828,
			47.844,
			47.905,
			47.936,
			47.966,
			48.027,
			48.045,
			48.092,
			48.157,
			48.214,
			48.261,
			48.278,
			48.339,
			48.371,
			48.402,
			48.464,
			48.524,
			48.587,
			48.649,
			48.696,
			48.774,
			48.805,
			48.836,
			48.896,
			48.913,
			48.96,
			49.023,
			49.083,
			49.129,
			49.146,
			49.207,
			49.238,
			49.27,
			49.332,
			49.346,
			49.393,
			49.456,
			49.517,
			49.58,
			49.641,
			49.673,
			49.704,
			49.766,
			49.827,
			49.89,
			49.951,
			49.998,
			50.012,
			50.076,
			50.105,
			50.137,
			50.199,
			50.219,
			50.261,
			50.323,
			50.386,
			50.447,
			50.51,
			50.54,
			50.572,
			50.633,
			50.648,
			50.695,
			50.757,
			50.82,
			50.866,
			50.942,
			50.974,
			51.006,
			51.067,
			51.131,
			51.191,
			51.255,
			51.298,
			51.315,
			51.378,
			51.409,
			51.441,
			51.5,
			51.517,
			51.564,
			51.626,
			51.689,
			51.732,
			51.75,
			51.812,
			51.842,
			51.872,
			51.934,
			51.951,
			51.999,
			52.059,
			52.121,
			52.167,
			52.246,
			52.281,
			52.308,
			52.371,
			52.433,
			52.495,
			52.556,
			52.6,
			52.618,
			52.679,
			52.71,
			52.742,
			52.804,
			52.818,
			52.866,
			52.928,
			52.991,
			53.036,
			53.05,
			53.115,
			53.145,
			53.174,
			53.237,
			53.3,
			53.361,
			53.422,
			53.47,
			53.548,
			53.58,
			53.609,
			53.687,
			53.733,
			53.797,
			53.858,
			53.919,
			53.981,
			54.013,
			54.043,
			54.105,
			54.122,
			54.167,
			54.229,
			54.292,
			54.355,
			54.415,
			54.448,
			54.479,
			54.539,
			54.602,
			54.665,
			54.727,
			54.773,
			54.787,
			54.851,
			54.882,
			54.912,
			54.975,
			54.991,
			55.035,
			55.098,
			55.159,
			55.206,
			55.223,
			55.285,
			55.315,
			55.346,
			55.424,
			55.471,
			55.532,
			55.594,
			55.657,
			55.719,
			55.75,
			55.78,
			55.842,
			55.904,
			55.967,
			56.029,
			56.074,
			56.09,
			56.153,
			56.182,
			56.215,
			56.275,
			56.29,
			56.337,
			56.406,
			56.462,
			56.508,
			56.524,
			56.585,
			56.619,
			56.648,
			56.725,
			56.772,
			56.835,
			56.897,
			56.943,
			57.02,
			57.052,
			57.082,
			57.143,
			57.207,
			57.27,
			57.331,
			57.376,
			57.392,
			57.455,
			57.484,
			57.516,
			57.579,
			57.595,
			57.64,
			57.704,
			57.765,
			57.825,
			57.888,
			57.919,
			57.948,
			58.013,
			58.076,
			58.136,
			58.198,
			58.261,
			58.323,
			58.353,
			58.385,
			58.448,
			58.468,
			58.509,
			58.572,
			58.633,
			58.68,
			58.694,
			58.757,
			58.788,
			58.819,
			58.881,
			58.895,
			58.944,
			59.006,
			59.066,
			59.113,
			59.189,
			59.22,
			59.254,
			59.314,
			59.377,
			59.442,
			59.5,
			59.547,
			59.563,
			59.623,
			59.655,
			59.687,
			59.75,
			59.763,
			59.811,
			59.873,
			59.936,
			59.98,
			59.998,
			60.06,
			60.091,
			60.121,
			60.182,
			60.199,
			60.245,
			60.307,
			60.369,
			60.431,
			60.494,
			60.529,
			60.555,
			60.617,
			60.679,
			60.742,
			60.803,
			60.85,
			60.864,
			60.926,
			60.958,
			60.955
		]
	],
	'2021_2027_7982': [
		[
			127.556
		],
		[
			0
		],
		[
			0,
			3.622,
			5.157,
			6.694,
			11.313,
			12.855,
			14.398,
			19.035,
			22.134,
			26.793,
			29.906,
			33.024,
			37.71,
			39.275,
			40.841,
			45.547,
			47.118,
			48.69,
			53.414,
			56.57,
			59.73,
			61.313,
			64.481,
			67.654,
			69.243,
			74.015,
			77.203,
			80.39,
			85.18,
			88.378,
			93.184,
			94.789,
			96.394,
			101.217,
			104.436,
			109.274,
			112.505,
			115.741,
			117.36,
			120.601,
			122.223,
			125.47,
			128.721,
			130.349,
			131.977,
			136.867,
			140.133,
			145.039,
			148.314,
			151.593,
			153.234,
			156.518,
			158.162,
			161.452,
			164.747,
			169.695,
			172.999,
			177.965,
			179.622,
			181.281,
			186.263,
			189.589,
			194.586,
			197.923,
			201.264,
			202.935,
			206.28,
			207.954,
			211.304,
			216.336,
			219.695,
			223.057,
			228.108,
			229.794,
			231.479,
			234.848,
			238.206,
			239.877,
			243.199,
			244.847,
			248.113,
			251.343,
			256.118,
			259.253,
			263.885,
			265.41,
			266.926,
			271.421,
			274.371,
			278.72,
			281.57,
			285.773,
			288.525,
			289.887,
			292.581,
			295.237,
			296.551,
			299.15,
			301.713,
			305.486,
			307.952,
			311.582,
			312.772,
			313.954,
			317.444,
			319.72,
			323.078,
			325.254,
			327.391,
			330.523,
			331.55,
			332.567,
			335.572,
			336.562,
			337.547,
			340.476,
			342.418,
			344.353,
			345.319,
			347.254,
			349.185,
			350.152,
			352.089,
			353.06,
			355.005,
			356.955,
			359.9,
			361.873,
			364.865,
			365.867,
			366.87,
			369.907,
			371.936,
			374.996,
			377.033,
			379.08,
			380.102,
			382.137,
			383.159,
			385.2,
			388.253,
			389.276,
			392.344,
			394.405,
			396.474,
			397.512,
			399.613,
			401.723,
			402.779,
			405.99,
			408.131,
			410.287,
			413.502,
			415.63,
			418.785,
			419.826,
			420.859,
			423.909,
			425.903,
			428.847,
			430.793,
			433.699,
			435.643,
			436.617,
			438.573,
			440.544,
			441.532,
			443.509,
			445.488,
			448.45,
			449.437,
			450.423,
			453.366,
			454.34,
			455.31,
			457.236,
			458.191,
			460.082,
			461.95,
			464.723,
			466.561,
			469.312,
			470.221,
			471.131,
			473.871,
			475.704,
			478.466,
			480.325,
			482.194,
			483.131,
			485.011,
			485.952,
			487.835,
			489.716,
			490.654,
			492.526,
			494.389,
			497.163,
			498.08,
			498.994,
			501.714,
			502.614,
			503.511,
			506.194,
			507.973,
			509.741,
			512.364,
			514.093,
			516.65,
			517.495,
			518.335,
			520.825,
			522.456,
			524.855,
			526.412,
			527.938,
			528.69,
			530.174,
			531.63,
			532.348,
			533.766,
			534.467,
			535.848,
			537.212,
			539.237,
			540.575,
			542.552,
			543.207,
			543.859,
			545.796,
			547.065,
			548.951,
			550.198,
			551.423,
			552.031,
			553.243,
			553.846,
			555.048,
			556.814,
			557.398,
			559.135,
			560.284,
			561.41,
			561.97,
			563.084,
			564.191,
			564.743,
			566.386,
			567.471,
			568.552,
			570.169,
			571.253,
			572.872,
			573.411,
			573.949,
			575.569,
			576.667,
			578.31,
			579.406,
			581.065,
			582.177,
			582.733,
			583.845,
			584.959,
			585.517,
			586.628,
			587.738,
			589.403,
			589.959,
			590.517,
			592.18,
			592.733,
			593.286,
			594.946,
			596.058,
			597.168,
			598.827,
			599.933,
			601.611,
			602.172,
			602.733,
			604.413,
			605.532,
			607.258,
			608.408,
			610.128,
			611.289,
			611.875,
			613.045,
			614.212,
			614.795,
			615.964,
			617.157,
			618.938,
			620.124,
			621.933,
			622.543,
			623.151,
			624.971,
			626.191,
			628.075,
			629.326,
			630.576,
			632.524,
			633.18,
			633.836,
			635.802,
			637.172,
			639.242,
			640.619,
			642.047,
			642.772,
			644.219,
			645.664,
			646.399,
			647.907,
			648.661,
			650.169,
			651.693,
			654.023,
			655.582,
			657.932,
			658.717,
			659.503,
			661.879,
			663.487,
			665.914,
			667.534,
			669.157,
			671.6,
			672.379,
			673.942,
			676.294,
			677.042,
			679.256,
			680.742,
			682.197,
			682.899,
			684.295,
			685.674,
			686.356,
			688.321,
			689.604,
			690.879,
			692.778,
			694.045,
			695.916,
			696.537,
			697.171,
			699.088,
			700.354,
			702.268,
			703.599,
			704.92,
			705.578,
			706.891,
			707.581,
			708.957,
			710.325,
			711.008,
			712.386,
			713.797,
			715.898,
			716.598,
			717.302,
			718.74,
			720.173,
			720.889,
			722.32,
			723.04,
			724.475,
			725.905,
			728.046,
			729.465,
			731.587,
			732.295,
			733.02,
			735.197,
			736.643,
			738.87,
			740.379,
			741.891,
			742.65,
			744.222,
			745.006,
			746.577,
			748.141,
			748.911,
			750.452,
			751.995,
			754.221,
			754.951,
			755.681,
			757.867,
			758.568,
			759.267,
			760.664,
			761.363,
			762.761,
			764.796,
			766.145,
			767.492,
			769.458,
			770.106,
			770.752,
			772.688,
			773.944,
			775.805,
			777.042,
			778.277,
			778.885,
			780.098,
			781.31,
			781.917,
			783.133,
			783.741,
			784.957,
			786.177,
			788.016,
			789.262,
			791.14,
			791.769,
			792.399,
			794.33,
			795.639,
			797.617,
			798.959,
			800.322,
			801.006,
			802.383,
			803.076,
			803.773,
			805.876,
			806.583,
			807.292,
			809.429,
			810.856,
			813.012,
			814.46,
			815.909,
			816.634,
			818.089,
			818.819,
			820.279,
			821.747,
			823.963,
			825.443,
			827.677,
			828.427,
			829.185,
			831.484,
			833.025,
			835.379,
			836.966,
			838.558,
			839.361,
			840.98,
			841.79,
			843.412,
			845.04,
			845.854,
			847.485,
			849.118,
			850.756,
			852.393,
			853.212,
			854.861,
			856.522,
			857.355,
			859.03,
			859.878,
			861.586,
			863.308,
			865.93,
			867.701,
			870.387,
			871.292,
			872.2,
			874.946,
			876.795,
			879.577,
			881.455,
			883.361,
			884.311,
			886.216,
			887.173,
			889.096,
			891.996,
			893.942,
			895.9,
			898.839,
			899.83,
			900.842,
			903.844,
			904.851,
			905.862,
			908.912,
			910.96,
			914.033,
			916.105,
			918.195,
			921.302,
			922.34,
			923.363,
			926.502,
			927.556,
			928.613,
			931.8,
			933.942,
			936.094,
			937.177,
			939.349,
			941.531,
			942.628,
			945.931,
			948.147,
			950.372,
			953.731,
			955.982,
			959.381,
			960.519,
			961.659,
			965.096,
			967.399,
			970.873,
			973.199,
			975.534,
			976.705,
			979.05,
			980.226,
			982.584,
			984.949,
			986.135,
			987.323,
			990.907,
			993.31,
			996.939,
			999.371,
			1001.813,
			1003.038,
			1005.494,
			1006.726,
			1009.195,
			1011.672,
			1015.404,
			1017.901,
			1021.662,
			1022.92,
			1024.179,
			1027.97,
			1030.508,
			1034.331,
			1036.889,
			1039.456,
			1040.742,
			1043.32,
			1044.612,
			1047.202,
			1049.8,
			1051.102,
			1053.707,
			1056.312,
			1058.925,
			1061.545,
			1062.858,
			1065.488,
			1068.126,
			1069.448,
			1072.097,
			1073.424,
			1076.084,
			1078.75,
			1082.762,
			1085.445,
			1089.483,
			1090.833,
			1092.184,
			1096.248,
			1098.965,
			1103.052,
			1105.785,
			1108.523,
			1109.895,
			1112.641,
			1115.394,
			1116.772,
			1119.533,
			1120.916,
			1123.687,
			1126.465,
			1130.646,
			1132.045,
			1133.444,
			1137.655,
			1139.063,
			1140.472,
			1144.726,
			1147.58,
			1150.475,
			1154.791,
			1157.705,
			1162.106,
			1163.581,
			1165.056,
			1170.978,
			1172.46,
			1176.91,
			1179.878,
			1182.85,
			1184.335,
			1187.31,
			1188.797,
			1191.775,
			1194.757,
			1196.25,
			1199.24,
			1202.236,
			1206.736,
			1209.73,
			1214.219,
			1215.713,
			1217.208,
			1221.691,
			1224.681,
			1229.171,
			1232.168,
			1235.168,
			1236.669,
			1239.676,
			1241.182,
			1242.689,
			1248.734,
			1250.249,
			1254.807,
			1257.855,
			1260.911,
			1262.441,
			1265.507,
			1268.579,
			1270.117,
			1274.74,
			1277.827,
			1280.915,
			1285.523,
			1288.566,
			1293.062,
			1294.542,
			1296.011,
			1300.361,
			1303.215,
			1307.425,
			1310.184,
			1314.252,
			1316.916,
			1318.234,
			1320.842,
			1324.683,
			1327.196,
			1329.673,
			1332.112,
			1334.515,
			1335.704,
			1338.056,
			1340.374,
			1341.52,
			1343.782,
			1344.9,
			1347.107,
			1350.349,
			1352.465,
			1354.543,
			1357.592,
			1358.591,
			1359.58,
			1362.493,
			1364.393,
			1367.175,
			1368.989,
			1370.769,
			1371.646,
			1373.374,
			1374.224,
			1375.896,
			1377.531,
			1378.334,
			1379.912,
			1381.452,
			1383.694,
			1384.421,
			1385.139,
			1387.24,
			1387.923,
			1388.597,
			1390.567,
			1391.838,
			1393.692,
			1394.874,
			1396.021,
			1397.678,
			1398.216,
			1398.744,
			1400.274,
			1401.263,
			1402.71,
			1403.654,
			1404.563,
			1405.012,
			1405.904,
			1406.347,
			1407.231,
			1408.554,
			1409.436,
			1410.313,
			1411.623,
			1412.498,
			1413.823,
			1414.284,
			1414.744,
			1416.121,
			1417.041,
			1418.432,
			1419.429,
			1420.962,
			1421.988,
			1422.504,
			1423.022,
			1424.658,
			1425.226,
			1425.794,
			1427.503,
			1428.652,
			1429.878,
			1430.499,
			1431.743,
			1432.993,
			1433.621,
			1435.616,
			1436.965,
			1438.319,
			1440.392,
			1441.788,
			1443.895,
			1444.587,
			1445.271,
			1447.328,
			1448.709,
			1450.695,
			1452,
			1453.978,
			1455.232,
			1455.842,
			1457.069,
			1458.305,
			1458.926,
			1460.139,
			1461.321,
			1462.508,
			1464.305,
			1465.532,
			1466.769,
			1467.389,
			1469.262,
			1470.556,
			1471.87,
			1473.857,
			1475.2,
			1477.245,
			1477.932,
			1478.622,
			1480.714,
			1482.125,
			1484.257,
			1485.695,
			1487.14,
			1487.868,
			1489.334,
			1490.074,
			1491.559,
			1493.055,
			1493.807,
			1495.319,
			1496.83,
			1498.355,
			1499.894,
			1500.667,
			1502.998,
			1503.781,
			1504.567,
			1506.956,
			1508.57,
			1510.197,
			1512.653,
			1514.292,
			1516.761,
			1517.595,
			1518.433,
			1520.978,
			1521.829,
			1522.682,
			1525.259,
			1526.995,
			1528.728,
			1529.602,
			1531.367,
			1533.134,
			1534.022,
			1535.806,
			1536.7,
			1538.495,
			1540.303,
			1543.031,
			1544.844,
			1547.623,
			1548.548,
			1549.477,
			1552.266,
			1554.151,
			1557,
			1558.92,
			1560.852,
			1561.823,
			1563.777,
			1564.761,
			1566.736,
			1568.727,
			1569.728,
			1570.732,
			1573.763,
			1575.801,
			1578.878,
			1580.945,
			1583.017,
			1584.054,
			1586.13,
			1587.171,
			1589.261,
			1591.362,
			1594.531,
			1596.657,
			1599.864,
			1600.938,
			1602.014,
			1605.258,
			1607.433,
			1610.713,
			1612.912,
			1615.122,
			1616.231,
			1618.456,
			1619.573,
			1621.813,
			1624.063,
			1625.192,
			1627.456,
			1629.73,
			1632.013,
			1635.454,
			1637.759,
			1640.073,
			1641.233,
			1643.559,
			1644.726,
			1647.065,
			1649.414,
			1652.954,
			1655.325,
			1658.901,
			1660.097,
			1661.296,
			1664.907,
			1667.326,
			1670.971,
			1673.412,
			1675.862,
			1677.091,
			1679.555,
			1680.79,
			1683.269,
			1685.758,
			1687.006,
			1689.51,
			1692.022,
			1695.813,
			1697.081,
			1698.353,
			1702.18,
			1703.462,
			1704.746,
			1707.32,
			1708.61,
			1711.197,
			1713.791,
			1717.697,
			1720.305,
			1724.219,
			1725.525,
			1726.832,
			1730.74,
			1733.351,
			1737.189,
			1739.709,
			1743.433,
			1745.884,
			1747.105,
			1749.542,
			1753.177,
			1755.635,
			1758.074,
			1761.731,
			1764.167,
			1767.8,
			1769.009,
			1770.214,
			1773.818,
			1776.213,
			1779.776,
			1782.182,
			1784.559,
			1788.105,
			1789.279,
			1790.451,
			1793.939,
			1795.091,
			1796.22,
			1799.616,
			1801.851,
			1805.177,
			1807.368,
			1809.543,
			1810.628,
			1812.792,
			1813.872,
			1816.035,
			1818.203,
			1821.456,
			1823.624,
			1826.87,
			1827.95,
			1829.028,
			1832.254,
			1834.398,
			1837.614,
			1839.758,
			1841.904,
			1842.978,
			1845.124,
			1846.197,
			1848.343,
			1850.487,
			1851.56,
			1853.704,
			1855.846,
			1857.986,
			1860.123,
			1861.192,
			1863.33,
			1865.467,
			1866.536,
			1868.683,
			1869.757,
			1871.906,
			1874.065,
			1877.31,
			1879.484,
			1882.752,
			1883.846,
			1884.939,
			1888.227,
			1890.425,
			1893.725,
			1895.926,
			1898.127,
			1899.228,
			1901.428,
			1902.529,
			1904.733,
			1906.937,
			1908.04,
			1910.249,
			1912.456,
			1915.767,
			1916.87,
			1917.973,
			1921.283,
			1922.387,
			1923.493,
			1926.828,
			1929.054,
			1931.278,
			1934.62,
			1936.856,
			1940.215,
			1941.335,
			1942.456,
			1945.822,
			1948.069,
			1951.452,
			1953.714,
			1955.982,
			1957.118,
			1959.395,
			1960.536,
			1962.824,
			1965.121,
			1966.273,
			1968.583,
			1970.905,
			1974.406,
			1976.749,
			1980.279,
			1981.46,
			1982.643,
			1986.204,
			1988.587,
			1990.977,
			1994.573,
			1996.979,
			2000.601,
			2001.806,
			2003.014,
			2006.648,
			2007.862,
			2009.078,
			2012.733,
			2015.176,
			2017.628,
			2018.856,
			2021.32,
			2023.788,
			2025.024,
			2027.504,
			2028.747,
			2031.238,
			2033.737,
			2037.5,
			2040.012,
			2043.79,
			2045.05,
			2046.31,
			2050.084,
			2052.592,
			2056.309,
			2058.775,
			2061.212,
			2062.417,
			2064.797,
			2065.972,
			2068.293,
			2070.576,
			2071.703,
			2072.821,
			2076.121,
			2078.273,
			2080.386,
			2081.429,
			2083.486,
			2085.504,
			2086.498,
			2088.458,
			2089.427,
			2091.337,
			2093.216,
			2095.987,
			2097.79,
			2100.457,
			2101.338,
			2102.218,
			2104.856,
			2106.622,
			2109.276,
			2111.055,
			2112.838,
			2113.732,
			2115.526,
			2116.427,
			2118.234,
			2120.049,
			2120.964,
			2122.802,
			2124.652,
			2126.517,
			2128.397,
			2129.34,
			2132.198,
			2133.157,
			2134.117,
			2136.045,
			2137.014,
			2138.952,
			2140.887,
			2143.786,
			2145.705,
			2148.56,
			2149.505,
			2150.447,
			2153.261,
			2155.125,
			2157.915,
			2159.77,
			2161.619,
			2162.542,
			2164.381,
			2166.212,
			2167.128,
			2169.871,
			2171.689,
			2173.507,
			2176.217,
			2178.02,
			2180.715,
			2181.61,
			2182.506,
			2185.186,
			2186.968,
			2189.644,
			2191.425,
			2193.204,
			2195.865,
			2196.751,
			2197.638,
			2200.307,
			2201.197,
			2202.086,
			2204.77,
			2206.565,
			2208.363,
			2209.264,
			2211.066,
			2212.87,
			2213.774,
			2215.598,
			2216.511,
			2218.341,
			2220.176,
			2222.934,
			2224.783,
			2227.582,
			2228.515,
			2229.45,
			2232.282,
			2234.17,
			2237.031,
			2238.94,
			2241.817,
			2243.737,
			2244.696,
			2246.603,
			2249.461,
			2250.417,
			2253.286,
			2255.2,
			2258.068,
			2259.984,
			2261.893,
			2262.849,
			2264.766,
			2265.72,
			2267.63,
			2269.545,
			2272.443,
			2274.385,
			2277.317,
			2278.298,
			2279.28,
			2282.245,
			2284.232,
			2287.223,
			2289.222,
			2292.242,
			2294.27,
			2295.288,
			2297.337,
			2299.402,
			2300.439,
			2302.494,
			2304.574,
			2307.71,
			2309.805,
			2312.936,
			2313.956,
			2314.971,
			2317.945,
			2319.87,
			2321.758,
			2324.499,
			2326.277,
			2328.87,
			2329.716,
			2330.552,
			2333.003,
			2334.59,
			2336.904,
			2338.398,
			2339.858,
			2340.578,
			2342.015,
			2342.721,
			2344.113,
			2345.502,
			2346.197,
			2347.594,
			2348.992,
			2351.098,
			2352.512,
			2354.656,
			2355.373,
			2356.092,
			2358.259,
			2359.704,
			2361.876,
			2363.33,
			2365.498,
			2366.939,
			2367.658,
			2368.376,
			2371.311,
			2372.039,
			2374.247,
			2375.766,
			2377.28,
			2378.037,
			2379.601,
			2381.217,
			2382.024,
			2383.634,
			2384.473,
			2386.197,
			2387.915,
			2390.572,
			2392.387,
			2395.085,
			2395.971,
			2396.854,
			2399.484,
			2401.146,
			2403.619,
			2405.214,
			2406.744,
			2407.505,
			2409.021,
			2409.772,
			2411.195,
			2412.606,
			2413.31,
			2414.013,
			2416.085,
			2417.438,
			2418.784,
			2419.456,
			2420.788,
			2422.098,
			2422.75,
			2424.701,
			2426,
			2427.298,
			2429.241,
			2430.537,
			2432.502,
			2433.159,
			2433.815,
			2435.789,
			2437.119,
			2439.124,
			2440.467,
			2441.825,
			2442.512,
			2443.889,
			2444.581,
			2445.97,
			2447.38,
			2448.094,
			2449.527,
			2450.97,
			2453.175,
			2453.921,
			2454.67,
			2456.177,
			2457.7,
			2458.468,
			2460.014,
			2460.793,
			2462.362,
			2463.937,
			2466.324,
			2467.931,
			2470.348,
			2471.161,
			2471.977,
			2474.427,
			2476.066,
			2478.519,
			2480.156,
			2482.634,
			2484.31,
			2485.152,
			2486.846,
			2489.44,
			2491.202,
			2492.976,
			2495.745,
			2497.608,
			2500.505,
			2501.486,
			2502.471,
			2505.495,
			2507.54,
			2509.603,
			2512.725,
			2514.8,
			2517.914,
			2518.953,
			2519.963,
			2523.006,
			2524.025,
			2525.012,
			2527.965,
			2529.938,
			2531.892,
			2532.873,
			2534.841,
			2536.817,
			2537.808,
			2539.797,
			2540.802,
			2542.817,
			2544.841,
			2547.925,
			2549.984,
			2553.103,
			2554.147,
			2555.195,
			2558.358,
			2560.48,
			2563.685,
			2565.834,
			2569.077,
			2571.251,
			2572.342,
			2573.435,
			2577.811,
			2578.916,
			2582.243,
			2584.476,
			2587.844,
			2590.1,
			2592.366,
			2593.502,
			2595.781,
			2596.923,
			2599.221,
			2601.531,
			2605.025,
			2607.372,
			2610.918,
			2612.106,
			2613.295,
			2616.894,
			2619.269,
			2622.877,
			2625.292,
			2628.93,
			2631.367,
			2632.588,
			2635.038,
			2637.498,
			2638.731,
			2641.205,
			2643.687,
			2647.429,
			2648.681,
			2649.935,
			2652.45,
			2654.973,
			2656.237,
			2658.772,
			2660.042,
			2662.589,
			2666.423,
			2668.987,
			2671.56,
			2675.434,
			2676.729,
			2678.026,
			2683.232,
			2684.535,
			2688.447,
			2691.06,
			2694.987,
			2697.612,
			2698.927,
			2701.563,
			2704.208,
			2705.536,
			2708.196,
			2710.868,
			2714.892,
			2716.238,
			2717.585,
			2721.638,
			2722.992,
			2724.347,
			2728.425,
			2731.15,
			2735.251,
			2737.993,
			2740.741,
			2744.876,
			2746.259,
			2747.642,
			2751.803,
			2753.194,
			2754.586,
			2758.772,
			2761.571,
			2764.377,
			2765.783,
			2768.6,
			2771.422,
			2772.836,
			2775.668,
			2777.087,
			2779.93,
			2782.778,
			2787.064,
			2789.929,
			2794.239,
			2795.679,
			2797.121,
			2801.456,
			2804.354,
			2808.712,
			2811.626,
			2814.546,
			2816.008,
			2818.935,
			2820.401,
			2823.338,
			2826.281,
			2827.756,
			2829.231,
			2833.668,
			2836.634,
			2841.096,
			2844.078,
			2847.064,
			2848.559,
			2851.543,
			2853.031,
			2855.991,
			2858.922,
			2863.251,
			2866.089,
			2870.273,
			2871.648,
			2873.015,
			2877.054,
			2879.699,
			2883.593,
			2886.142,
			2888.654,
			2889.896,
			2892.351,
			2893.564,
			2895.964,
			2898.329,
			2899.498,
			2901.811,
			2904.086,
			2907.428,
			2909.609,
			2911.752,
			2913.855,
			2914.893,
			2916.94,
			2917.947,
			2919.932,
			2921.881,
			2924.738,
			2926.597,
			2929.33,
			2930.226,
			2931.112,
			2933.723,
			2935.42,
			2937.896,
			2939.499,
			2941.065,
			2941.835,
			2943.346,
			2944.088,
			2945.542,
			2946.96,
			2947.656,
			2949.022,
			2950.354,
			2952.298,
			2952.935,
			2953.576,
			2955.44,
			2956.053,
			2956.662,
			2958.475,
			2959.665,
			2960.84,
			2962.59,
			2963.754,
			2965.436,
			2965.99,
			2966.544,
			2968.202,
			2968.756,
			2969.308,
			2970.941,
			2972.027,
			2973.656,
			2974.778,
			2975.931,
			2976.506,
			2977.656,
			2978.233,
			2979.393,
			2980.706,
			2982.664,
			2983.969,
			2986.09,
			2986.807,
			2987.523,
			2989.667,
			2991.094,
			2993.212,
			2994.617,
			2995.918,
			2996.56,
			2997.838,
			2998.475,
			2999.745,
			3000.968,
			3001.573,
			3002.177,
			3003.983,
			3005.181,
			3006.371,
			3006.963,
			3008.145,
			3009.323,
			3009.912,
			3011.08,
			3011.663,
			3012.828,
			3013.994,
			3015.746,
			3016.904,
			3018.637,
			3019.221,
			3019.806,
			3021.604,
			3022.833,
			3024.705,
			3025.982,
			3027.355,
			3028.045,
			3029.429,
			3030.121,
			3031.526,
			3032.963,
			3033.678,
			3035.102,
			3036.524,
			3037.964,
			3039.38,
			3040.085,
			3041.49,
			3042.927,
			3043.643,
			3045.068,
			3045.779,
			3047.209,
			3048.654,
			3050.812,
			3052.254,
			3054.441,
			3055.168,
			3055.895,
			3058.08,
			3059.533,
			3061.711,
			3063.161,
			3064.6,
			3065.319,
			3066.759,
			3067.481,
			3068.873,
			3070.26,
			3070.955,
			3072.348,
			3073.722,
			3075.765,
			3077.134,
			3079.164,
			3080.506,
			3082.532,
			3083.883,
			3085.902,
			3087.257,
			3088.623,
			3090.664,
			3091.348,
			3092.034,
			3094.106,
			3095.49,
			3097.579,
			3098.982,
			3100.389,
			3101.093,
			3102.505,
			3103.922,
			3104.634,
			3106.058,
			3106.771,
			3108.198,
			3109.629,
			3111.793,
			3113.238,
			3115.417,
			3116.148,
			3116.88,
			3119.083,
			3120.559,
			3122.779,
			3124.267,
			3125.765,
			3128.031,
			3128.79,
			3130.315,
			3132.622,
			3133.395,
			3135.731,
			3137.309,
			3138.896,
			3139.689,
			3141.276,
			3142.881,
			3143.687,
			3145.307,
			3146.122,
			3147.761,
			3149.41,
			3151.902,
			3153.587,
			3156.125,
			3156.977,
			3157.834,
			3160.412,
			3162.138,
			3164.754,
			3166.502,
			3169.149,
			3170.92,
			3171.807,
			3173.592,
			3175.383,
			3176.278,
			3177.174,
			3179.882,
			3181.69,
			3184.415,
			3186.238,
			3188.06,
			3188.979,
			3190.818,
			3191.737,
			3193.58,
			3195.439,
			3198.237,
			3200.127,
			3202.987,
			3203.951,
			3204.921,
			3207.85,
			3209.828,
			3212.827,
			3214.845,
			3216.886,
			3217.91,
			3219.965,
			3221,
			3223.075,
			3225.156,
			3226.2,
			3228.289,
			3230.383,
			3233.536,
			3234.588,
			3235.641,
			3238.803,
			3239.857,
			3240.912,
			3243.04,
			3244.105,
			3246.235,
			3248.367,
			3251.563,
			3253.69,
			3256.877,
			3257.936,
			3258.995,
			3262.164,
			3263.215,
			3264.265,
			3267.407,
			3269.493,
			3272.616,
			3274.692,
			3276.764,
			3277.795,
			3279.848,
			3280.872,
			3282.919,
			3284.961,
			3288.03,
			3289.055,
			3290.081,
			3293.163,
			3294.192,
			3295.221,
			3298.31,
			3300.368,
			3303.46,
			3305.522,
			3307.589,
			3308.624,
			3310.701,
			3311.741,
			3313.828,
			3316.977,
			3318.031,
			3321.203,
			3323.325,
			3325.448,
			3326.512,
			3328.643,
			3330.784,
			3331.858,
			3334.013,
			3335.094,
			3337.265,
			3339.445,
			3342.737,
			3344.944,
			3348.272,
			3349.386,
			3350.502,
			3353.868,
			3356.124,
			3359.529,
			3361.811,
			3365.25,
			3367.555,
			3368.711,
			3371.03,
			3373.357,
			3374.524,
			3376.865,
			3379.211,
			3381.566,
			3385.111,
			3387.484,
			3389.86,
			3391.051,
			3393.438,
			3394.632,
			3397.024,
			3399.423,
			3403.03,
			3405.441,
			3409.072,
			3410.285,
			3411.5,
			3415.154,
			3417.596,
			3421.271,
			3423.723,
			3426.183,
			3427.415,
			3429.884,
			3431.12,
			3433.595,
			3436.077,
			3437.325,
			3439.806,
			3442.318,
			3446.131,
			3447.4,
			3448.673,
			3452.484,
			3453.77,
			3455.059,
			3458.938,
			3461.555,
			3464.134,
			3468.071,
			3470.692,
			3474.634,
			3475.952,
			3477.27,
			3481.25,
			3483.914,
			3487.914,
			3490.563,
			3493.262,
			3494.579,
			3497.28,
			3499.946,
			3501.313,
			3503.984,
			3505.351,
			3508.045,
			3510.732,
			3514.816,
			3517.553,
			3521.67,
			3523.045,
			3524.423,
			3528.558,
			3531.319,
			3535.47,
			3538.243,
			3542.414,
			3545.202,
			3546.598,
			3549.396,
			3552.202,
			3553.608,
			3555.015,
			3559.249,
			3562.082,
			3564.923,
			3566.347,
			3569.199,
			3572.062,
			3573.496,
			3576.372,
			3577.813,
			3580.701,
			3583.596,
			3587.95,
			3590.86,
			3595.235,
			3596.696,
			3598.159,
			3602.558,
			3605.499,
			3609.923,
			3612.881,
			3615.846,
			3617.331,
			3620.304,
			3621.793,
			3624.775,
			3627.763,
			3629.259,
			3632.255,
			3635.256,
			3638.262,
			3641.276,
			3642.785,
			3645.808,
			3648.838,
			3650.355,
			3653.396,
			3654.919,
			3657.968,
			3661.024,
			3665.618,
			3668.688,
			3673.303,
			3674.844,
			3676.386,
			3681.02,
			3684.116,
			3688.767,
			3691.875,
			3694.989,
			3696.548,
			3699.669,
			3701.232,
			3704.363,
			3707.498,
			3709.068,
			3712.211,
			3715.358,
			3720.089,
			3721.668,
			3723.249,
			3727.998,
			3729.583,
			3731.169,
			3735.935,
			3739.12,
			3742.306,
			3747.09,
			3750.284,
			3755.084,
			3756.686,
			3758.289,
			3763.105,
			3766.321,
			3771.153,
			3774.381,
			3777.612,
			3779.229,
			3782.466,
			3784.087,
			3787.331,
			3790.578,
			3792.204,
			3795.458,
			3798.715,
			3803.609,
			3806.876,
			3811.783,
			3813.421,
			3815.06,
			3819.984,
			3823.272,
			3828.212,
			3831.51,
			3834.814,
			3836.467,
			3839.776,
			3841.432,
			3844.747,
			3849.729,
			3851.391,
			3856.384,
			3859.717,
			3863.055,
			3864.725,
			3868.069,
			3871.417,
			3873.092,
			3876.446,
			3878.124,
			3881.484,
			3884.849,
			3889.904,
			3893.279,
			3898.349,
			3900.041,
			3901.733,
			3906.814,
			3910.206,
			3915.3,
			3918.701,
			3922.106,
			3923.811,
			3927.223,
			3928.931,
			3932.349,
			3935.771,
			3937.483,
			3940.912,
			3944.344,
			3947.779,
			3952.94,
			3956.385,
			3959.833,
			3961.558,
			3965.011,
			3966.74,
			3970.198,
			3973.66,
			3978.858,
			3982.329,
			3987.542,
			3989.282,
			3991.022,
			3996.25,
			3999.74,
			4004.983,
			4008.483,
			4011.987,
			4013.74,
			4017.248,
			4019.004,
			4022.517,
			4026.033,
			4027.792,
			4031.312,
			4034.835,
			4040.126,
			4041.891,
			4043.657,
			4047.192,
			4050.731,
			4052.501,
			4057.817,
			4061.365,
			4064.915,
			4070.245,
			4073.802,
			4079.144,
			4080.926,
			4082.709,
			4088.064,
			4089.851,
			4091.638,
			4097.007,
			4100.591,
			4104.178,
			4105.972,
			4109.563,
			4113.158,
			4114.956,
			4120.356,
			4123.96,
			4127.567,
			4132.983,
			4136.598,
			4142.025,
			4143.836,
			4145.647,
			4151.085,
			4154.715,
			4160.165,
			4163.803,
			4167.443,
			4169.265,
			4172.911,
			4174.734,
			4176.559,
			4182.036,
			4183.863,
			4185.692,
			4191.18,
			4194.841,
			4198.505,
			4200.338,
			4204.007,
			4207.679,
			4209.516,
			4213.192,
			4215.031,
			4218.71,
			4222.394,
			4227.923,
			4231.612,
			4237.15,
			4238.998,
			4240.846,
			4246.394,
			4250.097,
			4255.655,
			4259.363,
			4264.932,
			4268.646,
			4270.505,
			4274.224,
			4277.945,
			4279.807,
			4283.531,
			4287.257,
			4292.851,
			4294.717,
			4296.583,
			4300.318,
			4304.055,
			4305.924,
			4311.537,
			4315.284,
			4319.032,
			4324.659,
			4328.412,
			4334.048,
			4335.927,
			4337.808,
			4343.452,
			4347.218,
			4352.87,
			4356.641,
			4360.413,
			4362.299,
			4366.075,
			4367.963,
			4371.742,
			4375.522,
			4377.414,
			4381.199,
			4384.985,
			4390.669,
			4392.565,
			4394.462,
			4400.155,
			4402.054,
			4403.954,
			4409.657,
			4413.463,
			4417.27,
			4422.984,
			4426.796,
			4432.514,
			4434.417,
			4436.317,
			4441.984,
			4443.857,
			4445.722,
			4451.256,
			4454.896,
			4460.282,
			4463.823,
			4467.324,
			4469.059,
			4472.5,
			4474.207,
			4477.588,
			4480.933,
			4485.876,
			4489.123,
			4493.919,
			4495.499,
			4497.068,
			4501.72,
			4504.773,
			4509.287,
			4512.25,
			4515.173,
			4516.62,
			4519.485,
			4520.903,
			4523.709,
			4526.476,
			4527.845,
			4529.205,
			4533.228,
			4535.862,
			4539.744,
			4542.284,
			4544.787,
			4546.024,
			4548.47,
			4549.679,
			4552.07,
			4554.427,
			4557.898,
			4560.165,
			4563.494,
			4564.585,
			4565.667,
			4568.857,
			4570.937,
			4573.986,
			4575.973,
			4577.923,
			4578.884,
			4580.78,
			4581.714,
			4583.556,
			4585.368,
			4586.261,
			4588.022,
			4589.75,
			4591.441,
			4593.096,
			4593.909,
			4595.509,
			4597.072,
			4597.839,
			4600.087,
			4601.539,
			4602.955,
			4605.014,
			4606.345,
			4608.277,
			4608.905,
			4609.522,
			4611.319,
			4612.472,
			4614.133,
			4615.191,
			4616.214,
			4616.711,
			4617.68,
			4618.151,
			4619.071,
			4620.4,
			4621.25,
			4622.082,
			4623.303,
			4623.705,
			4624.097,
			4625.24,
			4625.615,
			4625.988,
			4627.101,
			4627.836,
			4628.938,
			4629.654,
			4630.361,
			4631.414,
			4631.764,
			4632.115,
			4633.173,
			4633.528,
			4633.894,
			4635.067,
			4635.842,
			4637.001,
			4637.778,
			4638.17,
			4639.007,
			4640.029,
			4640.539,
			4641.562,
			4642.591,
			4644.255,
			4645.604,
			4647.638,
			4648.323,
			4649.019,
			4651.159,
			4652.59,
			4654.659,
			4655.952,
			4657.243,
			4657.887,
			4659.138,
			4659.709,
			4660.285,
			4662.021,
			4663.188,
			4664.865,
			4665.949,
			4667.039,
			4667.586,
			4668.685,
			4669.736,
			4670.256,
			4671.297,
			4671.818,
			4672.863,
			4673.912,
			4675.424,
			4676.431,
			4677.945,
			4678.452,
			4678.959,
			4680.484,
			4681.505,
			4683.048,
			4684.09,
			4685.163,
			4685.702,
			4686.785,
			4687.33,
			4688.429,
			4689.552,
			4690.124,
			4690.699,
			4692.441,
			4694.214,
			4694.819,
			4695.427,
			4696.646,
			4697.864,
			4698.473,
			4700.327,
			4701.578,
			4702.848,
			4704.78,
			4706.084,
			4708.065,
			4708.729,
			4709.396,
			4711.406,
			4712.751,
			4714.792,
			4716.172,
			4717.56,
			4718.269,
			4719.675,
			4721.1,
			4721.817,
			4723.983,
			4725.454,
			4726.939,
			4729.197,
			4729.957,
			4730.721,
			4733.049,
			4733.828,
			4734.611,
			4736.99,
			4738.594,
			4740.215,
			4742.68,
			4744.333,
			4746.817,
			4747.649,
			4748.485,
			4751.009,
			4752.69,
			4755.271,
			4756.995,
			4758.732,
			4759.604,
			4761.345,
			4762.228,
			4764.001,
			4765.793,
			4766.694,
			4768.506,
			4770.335,
			4773.111,
			4774.979,
			4777.814,
			4778.766,
			4779.721,
			4782.608,
			4784.548,
			4787.483,
			4789.455,
			4791.439,
			4794.438,
			4795.444,
			4796.453,
			4799.499,
			4800.52,
			4801.544,
			4804.634,
			4806.709,
			4808.794,
			4809.837,
			4811.923,
			4814.015,
			4815.064,
			4818.227,
			4820.348,
			4822.48,
			4825.697,
			4827.855,
			4831.11,
			4832.2,
			4833.293,
			4836.586,
			4838.794,
			4842.126,
			4844.36,
			4847.729,
			4849.988,
			4851.121,
			4853.395,
			4855.678,
			4856.823,
			4859.121,
			4861.428,
			4863.745,
			4866.072,
			4867.238,
			4869.58,
			4871.931,
			4873.11,
			4875.476,
			4876.663,
			4879.042,
			4881.431,
			4885.033,
			4887.446,
			4891.085,
			4892.303,
			4893.522,
			4897.198,
			4899.66,
			4903.371,
			4905.856,
			4908.352,
			4909.602,
			4912.11,
			4913.366,
			4915.885,
			4918.407,
			4919.671,
			4922.202,
			4924.736,
			4928.548,
			4929.82,
			4931.094,
			4934.911,
			4936.179,
			4937.444,
			4939.957,
			4941.203,
			4943.672,
			4947.31,
			4949.691,
			4952.035,
			4955.486,
			4956.618,
			4957.742,
			4961.057,
			4963.221,
			4966.398,
			4968.47,
			4970.504,
			4971.508,
			4973.491,
			4974.468,
			4976.396,
			4978.301,
			4979.232,
			4981.068,
			4982.872,
			4985.529,
			4987.27,
			4989.847,
			4990.701,
			4991.555,
			4994.092,
			4995.784,
			4998.306,
			4999.982,
			5001.662,
			5002.494,
			5004.158,
			5004.991,
			5005.824,
			5008.354,
			5009.201,
			5010.048,
			5012.67,
			5014.503,
			5016.335,
			5017.311,
			5019.317,
			5021.32,
			5022.337,
			5024.379,
			5025.398,
			5027.392,
			5029.314,
			5032.14,
			5033.877,
			5036.474,
			5037.307,
			5038.128,
			5040.589,
			5042.226,
			5044.673,
			5046.304,
			5047.952,
			5048.779,
			5050.437,
			5052.107,
			5052.957,
			5055.52,
			5056.38,
			5059.001,
			5060.767,
			5062.55,
			5063.449,
			5065.255,
			5067.069,
			5067.979,
			5069.8,
			5070.71,
			5072.516,
			5074.348,
			5077.075,
			5078.893,
			5081.637,
			5082.541,
			5083.461,
			5086.232,
			5088.108,
			5090.907,
			5092.796,
			5094.697,
			5095.668,
			5097.57,
			5098.535,
			5100.473,
			5103.415,
			5105.365,
			5107.342,
			5110.33,
			5111.345,
			5112.346,
			5115.355,
			5116.37,
			5117.388,
			5119.436,
			5120.464,
			5122.529,
			5124.607,
			5127.746,
			5129.851,
			5133.024,
			5134.08,
			5135.137,
			5138.317,
			5140.448,
			5143.663,
			5145.816,
			5147.979,
			5149.063,
			5151.24,
			5153.444,
			5154.521,
			5156.719,
			5157.821,
			5160.033,
			5162.255,
			5165.606,
			5167.854,
			5171.244,
			5172.379,
			5173.517,
			5176.945,
			5179.242,
			5182.708,
			5185.031,
			5187.365,
			5190.883,
			5192.061,
			5193.24,
			5197.985,
			5199.178,
			5202.771,
			5205.179,
			5207.598,
			5208.811,
			5211.245,
			5213.689,
			5214.915,
			5217.374,
			5218.608,
			5221.081,
			5223.564,
			5227.307,
			5229.812,
			5233.589,
			5234.852,
			5236.117,
			5239.928,
			5242.479,
			5246.321,
			5248.893,
			5251.472,
			5252.764,
			5255.354,
			5256.652,
			5259.254,
			5261.862,
			5263.166,
			5264.47,
			5268.391,
			5271.014,
			5273.645,
			5274.963,
			5277.603,
			5280.25,
			5281.577,
			5284.236,
			5285.568,
			5288.238,
			5290.914,
			5294.941,
			5297.636,
			5301.689,
			5303.045,
			5304.402,
			5308.482,
			5311.21,
			5315.316,
			5318.062,
			5320.815,
			5322.193,
			5324.956,
			5326.34,
			5329.112,
			5333.283,
			5336.073,
			5338.868,
			5343.075,
			5344.481,
			5345.889,
			5350.122,
			5351.537,
			5352.953,
			5355.789,
			5357.21,
			5360.058,
			5364.34,
			5367.204,
			5370.074,
			5374.391,
			5375.833,
			5377.276,
			5381.617,
			5384.52,
			5388.886,
			5391.804,
			5396.195,
			5399.13,
			5400.6,
			5403.543,
			5406.494,
			5407.971,
			5410.932,
			5413.898,
			5418.359,
			5421.34,
			5425.824,
			5427.321,
			5428.82,
			5431.822,
			5433.325,
			5436.337,
			5440.864,
			5443.89,
			5446.922,
			5451.481,
			5453.004,
			5454.528,
			5459.111,
			5460.641,
			5462.173,
			5463.51
		],
		[
			0,
			0.053,
			0.097,
			0.113,
			0.175,
			0.207,
			0.236,
			0.3,
			0.362,
			0.424,
			0.486,
			0.534,
			0.611,
			0.642,
			0.671,
			0.735,
			0.75,
			0.795,
			0.858,
			0.921,
			0.966,
			0.983,
			1.043,
			1.074,
			1.105,
			1.184,
			1.229,
			1.291,
			1.355,
			1.401,
			1.479,
			1.508,
			1.539,
			1.602,
			1.665,
			1.725,
			1.787,
			1.835,
			1.849,
			1.913,
			1.942,
			1.975,
			2.037,
			2.051,
			2.098,
			2.159,
			2.223,
			2.284,
			2.345,
			2.377,
			2.409,
			2.471,
			2.486,
			2.531,
			2.595,
			2.655,
			2.703,
			2.78,
			2.811,
			2.841,
			2.905,
			2.966,
			3.033,
			3.089,
			3.137,
			3.154,
			3.214,
			3.245,
			3.278,
			3.355,
			3.401,
			3.463,
			3.526,
			3.572,
			3.587,
			3.648,
			3.685,
			3.711,
			3.772,
			3.788,
			3.833,
			3.895,
			3.959,
			4.006,
			4.084,
			4.114,
			4.144,
			4.207,
			4.269,
			4.33,
			4.391,
			4.455,
			4.518,
			4.548,
			4.577,
			4.641,
			4.657,
			4.703,
			4.766,
			4.827,
			4.874,
			4.952,
			4.981,
			5.012,
			5.075,
			5.137,
			5.2,
			5.261,
			5.307,
			5.383,
			5.415,
			5.447,
			5.507,
			5.525,
			5.569,
			5.634,
			5.694,
			5.747,
			5.757,
			5.82,
			5.851,
			5.881,
			5.942,
			5.958,
			6.004,
			6.068,
			6.13,
			6.175,
			6.254,
			6.284,
			6.315,
			6.392,
			6.439,
			6.502,
			6.563,
			6.61,
			6.625,
			6.688,
			6.718,
			6.75,
			6.827,
			6.873,
			6.934,
			6.998,
			7.042,
			7.06,
			7.122,
			7.153,
			7.182,
			7.262,
			7.308,
			7.37,
			7.43,
			7.479,
			7.555,
			7.586,
			7.618,
			7.695,
			7.74,
			7.809,
			7.863,
			7.926,
			7.989,
			8.021,
			8.052,
			8.112,
			8.13,
			8.175,
			8.238,
			8.299,
			8.345,
			8.361,
			8.424,
			8.453,
			8.484,
			8.547,
			8.563,
			8.608,
			8.672,
			8.735,
			8.78,
			8.857,
			8.887,
			8.921,
			8.996,
			9.043,
			9.107,
			9.167,
			9.215,
			9.23,
			9.292,
			9.323,
			9.354,
			9.416,
			9.431,
			9.479,
			9.539,
			9.603,
			9.648,
			9.663,
			9.727,
			9.756,
			9.788,
			9.85,
			9.911,
			9.973,
			10.037,
			10.082,
			10.16,
			10.19,
			10.221,
			10.299,
			10.346,
			10.408,
			10.471,
			10.517,
			10.53,
			10.595,
			10.625,
			10.657,
			10.719,
			10.733,
			10.781,
			10.842,
			10.904,
			10.966,
			11.027,
			11.059,
			11.091,
			11.153,
			11.215,
			11.277,
			11.339,
			11.386,
			11.401,
			11.461,
			11.491,
			11.524,
			11.603,
			11.648,
			11.711,
			11.772,
			11.819,
			11.834,
			11.895,
			11.932,
			11.957,
			12.034,
			12.083,
			12.145,
			12.206,
			12.253,
			12.329,
			12.36,
			12.392,
			12.454,
			12.516,
			12.582,
			12.64,
			12.703,
			12.764,
			12.795,
			12.826,
			12.889,
			12.904,
			12.951,
			13.017,
			13.075,
			13.121,
			13.136,
			13.199,
			13.23,
			13.258,
			13.339,
			13.384,
			13.446,
			13.508,
			13.556,
			13.632,
			13.663,
			13.694,
			13.757,
			13.818,
			13.881,
			13.942,
			14.003,
			14.066,
			14.096,
			14.127,
			14.191,
			14.204,
			14.253,
			14.315,
			14.377,
			14.439,
			14.501,
			14.53,
			14.564,
			14.624,
			14.687,
			14.749,
			14.81,
			14.856,
			14.933,
			14.966,
			14.998,
			15.06,
			15.12,
			15.184,
			15.244,
			15.291,
			15.307,
			15.368,
			15.4,
			15.429,
			15.491,
			15.507,
			15.553,
			15.617,
			15.679,
			15.74,
			15.803,
			15.833,
			15.865,
			15.925,
			15.99,
			16.054,
			16.113,
			16.16,
			16.237,
			16.269,
			16.299,
			16.375,
			16.422,
			16.483,
			16.546,
			16.593,
			16.609,
			16.671,
			16.703,
			16.733,
			16.809,
			16.856,
			16.92,
			16.981,
			17.026,
			17.104,
			17.136,
			17.167,
			17.228,
			17.291,
			17.354,
			17.416,
			17.462,
			17.478,
			17.538,
			17.571,
			17.601,
			17.663,
			17.679,
			17.724,
			17.787,
			17.85,
			17.895,
			17.911,
			17.972,
			18.004,
			18.036,
			18.098,
			18.118,
			18.159,
			18.221,
			18.282,
			18.33,
			18.408,
			18.439,
			18.47,
			18.53,
			18.594,
			18.656,
			18.718,
			18.764,
			18.779,
			18.841,
			18.873,
			18.904,
			18.966,
			18.98,
			19.027,
			19.089,
			19.152,
			19.198,
			19.214,
			19.275,
			19.306,
			19.337,
			19.398,
			19.415,
			19.462,
			19.524,
			19.586,
			19.631,
			19.71,
			19.739,
			19.772,
			19.847,
			19.894,
			19.956,
			20.021,
			20.066,
			20.082,
			20.142,
			20.18,
			20.206,
			20.267,
			20.281,
			20.33,
			20.391,
			20.454,
			20.514,
			20.577,
			20.609,
			20.641,
			20.703,
			20.764,
			20.829,
			20.889,
			20.934,
			20.95,
			21.011,
			21.043,
			21.073,
			21.137,
			21.15,
			21.199,
			21.261,
			21.322,
			21.384,
			21.445,
			21.477,
			21.506,
			21.57,
			21.586,
			21.631,
			21.695,
			21.755,
			21.803,
			21.881,
			21.91,
			21.942,
			22.003,
			22.064,
			22.127,
			22.188,
			22.242,
			22.251,
			22.314,
			22.345,
			22.376,
			22.436,
			22.454,
			22.501,
			22.562,
			22.623,
			22.67,
			22.686,
			22.746,
			22.778,
			22.81,
			22.872,
			22.888,
			22.933,
			22.998,
			23.057,
			23.105,
			23.181,
			23.212,
			23.243,
			23.307,
			23.368,
			23.431,
			23.492,
			23.537,
			23.554,
			23.615,
			23.647,
			23.679,
			23.756,
			23.802,
			23.864,
			23.926,
			23.972,
			23.987,
			24.051,
			24.082,
			24.113,
			24.191,
			24.237,
			24.304,
			24.36,
			24.405,
			24.483,
			24.513,
			24.546,
			24.609,
			24.625,
			24.669,
			24.733,
			24.793,
			24.842,
			24.855,
			24.919,
			24.95,
			24.981,
			25.057,
			25.105,
			25.167,
			25.228,
			25.276,
			25.351,
			25.383,
			25.413,
			25.476,
			25.539,
			25.601,
			25.661,
			25.71,
			25.724,
			25.787,
			25.817,
			25.849,
			25.91,
			25.926,
			25.972,
			26.035,
			26.095,
			26.158,
			26.221,
			26.252,
			26.284,
			26.345,
			26.364,
			26.405,
			26.47,
			26.532,
			26.577,
			26.654,
			26.684,
			26.716,
			26.778,
			26.839,
			26.904,
			26.964,
			27.011,
			27.028,
			27.089,
			27.12,
			27.151,
			27.211,
			27.228,
			27.275,
			27.337,
			27.397,
			27.444,
			27.46,
			27.524,
			27.555,
			27.584,
			27.645,
			27.662,
			27.709,
			27.771,
			27.833,
			27.895,
			27.956,
			27.989,
			28.02,
			28.096,
			28.143,
			28.205,
			28.267,
			28.312,
			28.328,
			28.392,
			28.424,
			28.454,
			28.515,
			28.529,
			28.575,
			28.639,
			28.701,
			28.746,
			28.762,
			28.825,
			28.857,
			28.887,
			28.95,
			29.011,
			29.073,
			29.136,
			29.183,
			29.26,
			29.291,
			29.322,
			29.399,
			29.444,
			29.506,
			29.57,
			29.615,
			29.631,
			29.692,
			29.724,
			29.754,
			29.816,
			29.832,
			29.878,
			29.941,
			30.004,
			30.049,
			30.128,
			30.158,
			30.189,
			30.267,
			30.313,
			30.376,
			30.438,
			30.487,
			30.5,
			30.562,
			30.591,
			30.623,
			30.702,
			30.748,
			30.81,
			30.872,
			30.917,
			30.934,
			30.995,
			31.025,
			31.057,
			31.133,
			31.181,
			31.243,
			31.306,
			31.368,
			31.431,
			31.46,
			31.492,
			31.567,
			31.616,
			31.676,
			31.739,
			31.801,
			31.863,
			31.896,
			31.925,
			32.004,
			32.05,
			32.112,
			32.174,
			32.221,
			32.234,
			32.298,
			32.329,
			32.359,
			32.421,
			32.438,
			32.482,
			32.55,
			32.609,
			32.655,
			32.731,
			32.762,
			32.795,
			32.854,
			32.916,
			32.981,
			33.041,
			33.088,
			33.104,
			33.165,
			33.197,
			33.229,
			33.291,
			33.304,
			33.353,
			33.413,
			33.476,
			33.523,
			33.536,
			33.6,
			33.63,
			33.662,
			33.74,
			33.787,
			33.849,
			33.91,
			33.956,
			34.032,
			34.064,
			34.094,
			34.173,
			34.218,
			34.282,
			34.345,
			34.389,
			34.405,
			34.469,
			34.499,
			34.53,
			34.612,
			34.654,
			34.717,
			34.779,
			34.825,
			34.9,
			34.934,
			34.964,
			35.041,
			35.088,
			35.15,
			35.213,
			35.274,
			35.337,
			35.366,
			35.399,
			35.459,
			35.475,
			35.522,
			35.584,
			35.645,
			35.691,
			35.707,
			35.77,
			35.8,
			35.832,
			35.909,
			35.957,
			36.016,
			36.081,
			36.127,
			36.206,
			36.234,
			36.266,
			36.328,
			36.39,
			36.45,
			36.514,
			36.577,
			36.638,
			36.673,
			36.702,
			36.763,
			36.779,
			36.825,
			36.887,
			36.947,
			37.011,
			37.072,
			37.103,
			37.133,
			37.21,
			37.259,
			37.321,
			37.384,
			37.429,
			37.506,
			37.537,
			37.568,
			37.632,
			37.693,
			37.756,
			37.817,
			37.863,
			37.879,
			37.94,
			37.971,
			38.003,
			38.065,
			38.081,
			38.127,
			38.19,
			38.249,
			38.298,
			38.311,
			38.375,
			38.405,
			38.437,
			38.513,
			38.56,
			38.624,
			38.684,
			38.733,
			38.808,
			38.84,
			38.869,
			38.931,
			38.949,
			38.995,
			39.058,
			39.12,
			39.166,
			39.18,
			39.243,
			39.274,
			39.305,
			39.366,
			39.383,
			39.429,
			39.491,
			39.554,
			39.6,
			39.677,
			39.706,
			39.739,
			39.801,
			39.861,
			39.925,
			39.987,
			40.034,
			40.05,
			40.112,
			40.141,
			40.174,
			40.234,
			40.25,
			40.296,
			40.358,
			40.422,
			40.484,
			40.545,
			40.577,
			40.606,
			40.67,
			40.684,
			40.73,
			40.797,
			40.854,
			40.9,
			40.98,
			41.009,
			41.041,
			41.103,
			41.166,
			41.228,
			41.289,
			41.334,
			41.35,
			41.412,
			41.444,
			41.475,
			41.538,
			41.554,
			41.6,
			41.661,
			41.723,
			41.785,
			41.846,
			41.878,
			41.91,
			41.971,
			41.988,
			42.033,
			42.095,
			42.157,
			42.204,
			42.282,
			42.313,
			42.342,
			42.406,
			42.468,
			42.529,
			42.592,
			42.637,
			42.653,
			42.716,
			42.746,
			42.777,
			42.839,
			42.858,
			42.901,
			42.962,
			43.025,
			43.071,
			43.087,
			43.149,
			43.181,
			43.213,
			43.273,
			43.289,
			43.336,
			43.397,
			43.458,
			43.505,
			43.583,
			43.616,
			43.646,
			43.707,
			43.769,
			43.832,
			43.893,
			43.954,
			44.017,
			44.047,
			44.078,
			44.157,
			44.204,
			44.266,
			44.327,
			44.388,
			44.45,
			44.484,
			44.514,
			44.577,
			44.637,
			44.699,
			44.761,
			44.823,
			44.887,
			44.921,
			44.948,
			45.01,
			45.026,
			45.072,
			45.133,
			45.195,
			45.259,
			45.32,
			45.35,
			45.383,
			45.445,
			45.459,
			45.507,
			45.567,
			45.63,
			45.675,
			45.755,
			45.785,
			45.817,
			45.879,
			45.939,
			46,
			46.064,
			46.11,
			46.125,
			46.189,
			46.22,
			46.251,
			46.313,
			46.329,
			46.375,
			46.437,
			46.498,
			46.544,
			46.559,
			46.622,
			46.654,
			46.683,
			46.746,
			46.762,
			46.808,
			46.87,
			46.931,
			46.984,
			47.057,
			47.087,
			47.119,
			47.179,
			47.242,
			47.304,
			47.366,
			47.413,
			47.428,
			47.489,
			47.522,
			47.553,
			47.615,
			47.631,
			47.675,
			47.739,
			47.8,
			47.847,
			47.863,
			47.925,
			47.956,
			47.987,
			48.049,
			48.11,
			48.172,
			48.232,
			48.297,
			48.359,
			48.389,
			48.42,
			48.483,
			48.543,
			48.605,
			48.668,
			48.716,
			48.73,
			48.792,
			48.824,
			48.855,
			48.916,
			48.931,
			48.979,
			49.047,
			49.103,
			49.15,
			49.227,
			49.256,
			49.287,
			49.366,
			49.414,
			49.473,
			49.537,
			49.583,
			49.66,
			49.691,
			49.724,
			49.785,
			49.801,
			49.848,
			49.908,
			49.969,
			50.017,
			50.034,
			50.093,
			50.124,
			50.158,
			50.22,
			50.235,
			50.28,
			50.343,
			50.406,
			50.466,
			50.528,
			50.56,
			50.59,
			50.653,
			50.716,
			50.777,
			50.838,
			50.884,
			50.901,
			50.961,
			50.994,
			51.024,
			51.087,
			51.11,
			51.148,
			51.212,
			51.273,
			51.32,
			51.333,
			51.397,
			51.427,
			51.459,
			51.521,
			51.535,
			51.583,
			51.646,
			51.706,
			51.754,
			51.831,
			51.861,
			51.894,
			51.97,
			52.016,
			52.078,
			52.14,
			52.188,
			52.204,
			52.265,
			52.295,
			52.327,
			52.39,
			52.406,
			52.452,
			52.514,
			52.574,
			52.622,
			52.638,
			52.7,
			52.73,
			52.762,
			52.824,
			52.84,
			52.886,
			52.945,
			53.009,
			53.057,
			53.133,
			53.169,
			53.195,
			53.257,
			53.32,
			53.382,
			53.442,
			53.491,
			53.504,
			53.567,
			53.599,
			53.629,
			53.706,
			53.753,
			53.816,
			53.876,
			53.924,
			54.001,
			54.033,
			54.064,
			54.126,
			54.187,
			54.25,
			54.311,
			54.358,
			54.433,
			54.466,
			54.497,
			54.559,
			54.575,
			54.62,
			54.683,
			54.744,
			54.793,
			54.806,
			54.868,
			54.901,
			54.932,
			54.993,
			55.008,
			55.057,
			55.119,
			55.181,
			55.231,
			55.304,
			55.334,
			55.366,
			55.442,
			55.489,
			55.553,
			55.613,
			55.675,
			55.737,
			55.77,
			55.8,
			55.876,
			55.923,
			55.986,
			56.047,
			56.109,
			56.172,
			56.203,
			56.233,
			56.295,
			56.311,
			56.358,
			56.421,
			56.481,
			56.528,
			56.604,
			56.636,
			56.667,
			56.729,
			56.791,
			56.854,
			56.915,
			56.978,
			57.041,
			57.069,
			57.102,
			57.163,
			57.178,
			57.227,
			57.292,
			57.35,
			57.412,
			57.474,
			57.505,
			57.536,
			57.614,
			57.658,
			57.724,
			57.784,
			57.829,
			57.909,
			57.94,
			57.97,
			58.031,
			58.095,
			58.156,
			58.218,
			58.266,
			58.279,
			58.342,
			58.373,
			58.405,
			58.465,
			58.481,
			58.528,
			58.589,
			58.653,
			58.714,
			58.777,
			58.808,
			58.839,
			58.899,
			58.963,
			59.024,
			59.086,
			59.149,
			59.209,
			59.242,
			59.272,
			59.355,
			59.395,
			59.459,
			59.52,
			59.566,
			59.583,
			59.644,
			59.675,
			59.707,
			59.768,
			59.783,
			59.829,
			59.892,
			59.955,
			60.017,
			60.08,
			60.108,
			60.141,
			60.204,
			60.265,
			60.328,
			60.389,
			60.435,
			60.451,
			60.514,
			60.545,
			60.574,
			60.638,
			60.652,
			60.697,
			60.76,
			60.823,
			60.87,
			60.885,
			60.946,
			60.977,
			61.01,
			61.084,
			61.133,
			61.196,
			61.257,
			61.304,
			61.379,
			61.416,
			61.442,
			61.504,
			61.566,
			61.628,
			61.691,
			61.737,
			61.753,
			61.815,
			61.844,
			61.877,
			61.939,
			61.955,
			62.001,
			62.063,
			62.126,
			62.17,
			62.187,
			62.248,
			62.279,
			62.311,
			62.372,
			62.389,
			62.433,
			62.497,
			62.557,
			62.604,
			62.683,
			62.713,
			62.745,
			62.807,
			62.867,
			62.932,
			62.993,
			63.056,
			63.118,
			63.148,
			63.18,
			63.258,
			63.303,
			63.365,
			63.426,
			63.49,
			63.552,
			63.583,
			63.613,
			63.677,
			63.737,
			63.798,
			63.862,
			63.908,
			63.984,
			64.017,
			64.048,
			64.109,
			64.124,
			64.171,
			64.237,
			64.295,
			64.34,
			64.356,
			64.42,
			64.451,
			64.481,
			64.543,
			64.559,
			64.605,
			64.666,
			64.729,
			64.776,
			64.853,
			64.883,
			64.915,
			64.978,
			65.038,
			65.1,
			65.162,
			65.226,
			65.287,
			65.319,
			65.348,
			65.427,
			65.472,
			65.539,
			65.598,
			65.658,
			65.72,
			65.753,
			65.784,
			65.846,
			65.862,
			65.908,
			65.971,
			66.03,
			66.077,
			66.154,
			66.187,
			66.218,
			66.279,
			66.342,
			66.403,
			66.465,
			66.527,
			66.589,
			66.622,
			66.651,
			66.714,
			66.729,
			66.776,
			66.837,
			66.901,
			66.947,
			66.961,
			67.024,
			67.054,
			67.084,
			67.148,
			67.164,
			67.209,
			67.276,
			67.335,
			67.381,
			67.457,
			67.489,
			67.52,
			67.6,
			67.643,
			67.705,
			67.769,
			67.83,
			67.891,
			67.923,
			67.954,
			68.016,
			68.032,
			68.078,
			68.139,
			68.201,
			68.248,
			68.264,
			68.325,
			68.358,
			68.389,
			68.451,
			68.512,
			68.575,
			68.637,
			68.683,
			68.76,
			68.791,
			68.822,
			68.884,
			68.9,
			68.946,
			69.008,
			69.071,
			69.118,
			69.131,
			69.192,
			69.225,
			69.256,
			69.316,
			69.334,
			69.38,
			69.444,
			69.504,
			69.567,
			69.629,
			69.662,
			69.689,
			69.753,
			69.814,
			69.878,
			69.939,
			69.985,
			70.001,
			70.063,
			70.092,
			70.124,
			70.187,
			70.2,
			70.248,
			70.311,
			70.373,
			70.433,
			70.495,
			70.527,
			70.559,
			70.621,
			70.633,
			70.683,
			70.745,
			70.806,
			70.87,
			70.931,
			70.961,
			70.991,
			71.053,
			71.117,
			71.178,
			71.241,
			71.287,
			71.304,
			71.363,
			71.395,
			71.427,
			71.489,
			71.505,
			71.55,
			71.614,
			71.675,
			71.725,
			71.798,
			71.831,
			71.861,
			71.923,
			71.939,
			71.985,
			72.046,
			72.108,
			72.17,
			72.233,
			72.263,
			72.295,
			72.355,
			72.417,
			72.48,
			72.543,
			72.588,
			72.604,
			72.666,
			72.697,
			72.729,
			72.792,
			72.805,
			72.854,
			72.914,
			72.977,
			73.022,
			73.039,
			73.101,
			73.132,
			73.163,
			73.241,
			73.287,
			73.348,
			73.412,
			73.458,
			73.533,
			73.565,
			73.595,
			73.659,
			73.675,
			73.722,
			73.787,
			73.845,
			73.908,
			73.97,
			73.999,
			74.031,
			74.092,
			74.109,
			74.154,
			74.218,
			74.278,
			74.326,
			74.404,
			74.434,
			74.466,
			74.527,
			74.589,
			74.651,
			74.712,
			74.758,
			74.775,
			74.837,
			74.869,
			74.899,
			74.96,
			74.977,
			75.022,
			75.086,
			75.147,
			75.195,
			75.209,
			75.271,
			75.303,
			75.334,
			75.394,
			75.41,
			75.456,
			75.519,
			75.581,
			75.626,
			75.704,
			75.737,
			75.768,
			75.828,
			75.892,
			75.954,
			76.015,
			76.062,
			76.077,
			76.138,
			76.171,
			76.199,
			76.262,
			76.278,
			76.326,
			76.386,
			76.448,
			76.497,
			76.51,
			76.573,
			76.605,
			76.633,
			76.696,
			76.712,
			76.758,
			76.821,
			76.883,
			76.93,
			77.006,
			77.039,
			77.07,
			77.146,
			77.193,
			77.257,
			77.318,
			77.365,
			77.378,
			77.442,
			77.474,
			77.503,
			77.566,
			77.582,
			77.628,
			77.69,
			77.751,
			77.813,
			77.875,
			77.939,
			78.001,
			78.062,
			78.124,
			78.187,
			78.231,
			78.31,
			78.341,
			78.371,
			78.434,
			78.496,
			78.557,
			78.621,
			78.666,
			78.68,
			78.745,
			78.775,
			78.807,
			78.867,
			78.883,
			78.929,
			78.991,
			79.054,
			79.115,
			79.177,
			79.208,
			79.239,
			79.303,
			79.364,
			79.425,
			79.487,
			79.548,
			79.61,
			79.643,
			79.674,
			79.75,
			79.797,
			79.858,
			79.921,
			79.972,
			79.984,
			80.047,
			80.076,
			80.109,
			80.171,
			80.184,
			80.232,
			80.295,
			80.356,
			80.404,
			80.48,
			80.51,
			80.542,
			80.604,
			80.665,
			80.729,
			80.791,
			80.852,
			80.915,
			80.946,
			80.977,
			81.037,
			81.054,
			81.099,
			81.163,
			81.224,
			81.286,
			81.349,
			81.379,
			81.409,
			81.472,
			81.488,
			81.535,
			81.597,
			81.657,
			81.704,
			81.781,
			81.814,
			81.843,
			81.922,
			81.966,
			82.031,
			82.091,
			82.137,
			82.153,
			82.216,
			82.246,
			82.279,
			82.339,
			82.356,
			82.404,
			82.465,
			82.525,
			82.572,
			82.589,
			82.651,
			82.682,
			82.712,
			82.773,
			82.79,
			82.834,
			82.898,
			82.959,
			83.007,
			83.083,
			83.115,
			83.146,
			83.208,
			83.224,
			83.271,
			83.333,
			83.396,
			83.455,
			83.519,
			83.549,
			83.579,
			83.643,
			83.659,
			83.705,
			83.766,
			83.828,
			83.874,
			83.89,
			83.954,
			83.984,
			84.013,
			84.075,
			84.139,
			84.2,
			84.263,
			84.309,
			84.324,
			84.388,
			84.418,
			84.449,
			84.525,
			84.572,
			84.636,
			84.696,
			84.744,
			84.759,
			84.82,
			84.853,
			84.883,
			84.945,
			84.959,
			85.006,
			85.068,
			85.131,
			85.178,
			85.255,
			85.287,
			85.317,
			85.377,
			85.44,
			85.504,
			85.563,
			85.627,
			85.689,
			85.721,
			85.75,
			85.812,
			85.829,
			85.876,
			85.937,
			85.998,
			86.06,
			86.123,
			86.158,
			86.186,
			86.248,
			86.261,
			86.31,
			86.373,
			86.434,
			86.494,
			86.557,
			86.589,
			86.619,
			86.681,
			86.742,
			86.805,
			86.868,
			86.914,
			86.93,
			86.992,
			87.022,
			87.054,
			87.116,
			87.13,
			87.178,
			87.24,
			87.3,
			87.348,
			87.362,
			87.425,
			87.457,
			87.486,
			87.566,
			87.61,
			87.672,
			87.736,
			87.783,
			87.859,
			87.891,
			87.921,
			87.997,
			88.045,
			88.109,
			88.171,
			88.221,
			88.23,
			88.294,
			88.324,
			88.354,
			88.417,
			88.432,
			88.478,
			88.54,
			88.604,
			88.651,
			88.728,
			88.759,
			88.789,
			88.868,
			88.913,
			88.975,
			89.038,
			89.1,
			89.162,
			89.193,
			89.224,
			89.287,
			89.3,
			89.348,
			89.411,
			89.471,
			89.519,
			89.533,
			89.596,
			89.628,
			89.659,
			89.72,
			89.734,
			89.782,
			89.844,
			89.906,
			89.952,
			90.029,
			90.062,
			90.09,
			90.17,
			90.215,
			90.277,
			90.34,
			90.387,
			90.402,
			90.463,
			90.496,
			90.524,
			90.588,
			90.604,
			90.651,
			90.712,
			90.773,
			90.821,
			90.836,
			90.897,
			90.929,
			90.96,
			91.021,
			91.037,
			91.084,
			91.146,
			91.209,
			91.254,
			91.333,
			91.363,
			91.392,
			91.471,
			91.518,
			91.581,
			91.643,
			91.688,
			91.703,
			91.766,
			91.796,
			91.828,
			91.89,
			91.906,
			91.953,
			92.015,
			92.077,
			92.122,
			92.139,
			92.201,
			92.23,
			92.262,
			92.324,
			92.387,
			92.447,
			92.51,
			92.556,
			92.633,
			92.666,
			92.695,
			92.773,
			92.82,
			92.883,
			92.945,
			92.99,
			93.006,
			93.068,
			93.098,
			93.13,
			93.191,
			93.207,
			93.255,
			93.317,
			93.379,
			93.426,
			93.503,
			93.534,
			93.564,
			93.625,
			93.687,
			93.75,
			93.812,
			93.858,
			93.874,
			93.937,
			93.968,
			93.999,
			94.076,
			94.122,
			94.184,
			94.245,
			94.292,
			94.308,
			94.371,
			94.406,
			94.433,
			94.495,
			94.51,
			94.558,
			94.618,
			94.679,
			94.726,
			94.803,
			94.834,
			94.866,
			94.928,
			94.991,
			95.053,
			95.116,
			95.162,
			95.177,
			95.239,
			95.269,
			95.301,
			95.362,
			95.377,
			95.424,
			95.488,
			95.547,
			95.611,
			95.672,
			95.704,
			95.733,
			95.795,
			95.811,
			95.859,
			95.921,
			95.983,
			96.03,
			96.106,
			96.139,
			96.17,
			96.23,
			96.294,
			96.356,
			96.417,
			96.469,
			96.479,
			96.542,
			96.57,
			96.603,
			96.664,
			96.681,
			96.726,
			96.789,
			96.851,
			96.896,
			96.912,
			96.973,
			97.007,
			97.037,
			97.1,
			97.16,
			97.223,
			97.284,
			97.333,
			97.408,
			97.439,
			97.471,
			97.533,
			97.55,
			97.595,
			97.659,
			97.718,
			97.765,
			97.782,
			97.843,
			97.875,
			97.906,
			97.983,
			98.029,
			98.091,
			98.151,
			98.215,
			98.278,
			98.307,
			98.338,
			98.4,
			98.463,
			98.529,
			98.587,
			98.632,
			98.649,
			98.71,
			98.742,
			98.774,
			98.836,
			98.851,
			98.896,
			98.96,
			99.022,
			99.067,
			99.084,
			99.145,
			99.175,
			99.208,
			99.268,
			99.286,
			99.332,
			99.394,
			99.456,
			99.501,
			99.579,
			99.609,
			99.642,
			99.717,
			99.765,
			99.827,
			99.889,
			99.953,
			100.012,
			100.043,
			100.075,
			100.137,
			100.151,
			100.201,
			100.261,
			100.324,
			100.371,
			100.386,
			100.448,
			100.479,
			100.508,
			100.592,
			100.634,
			100.695,
			100.758,
			100.82,
			100.882,
			100.913,
			100.943,
			101.005,
			101.068,
			101.13,
			101.191,
			101.238,
			101.254,
			101.315,
			101.345,
			101.378,
			101.441,
			101.454,
			101.501,
			101.565,
			101.626,
			101.673,
			101.687,
			101.749,
			101.781,
			101.813,
			101.874,
			101.937,
			101.997,
			102.061,
			102.105,
			102.185,
			102.214,
			102.244,
			102.307,
			102.324,
			102.371,
			102.433,
			102.494,
			102.555,
			102.618,
			102.653,
			102.681,
			102.742,
			102.758,
			102.804,
			102.866,
			102.926,
			102.975,
			103.053,
			103.084,
			103.114,
			103.177,
			103.239,
			103.301,
			103.363,
			103.408,
			103.424,
			103.487,
			103.517,
			103.549,
			103.611,
			103.624,
			103.672,
			103.732,
			103.797,
			103.859,
			103.92,
			103.951,
			103.981,
			104.044,
			104.06,
			104.107,
			104.169,
			104.229,
			104.277,
			104.354,
			104.386,
			104.417,
			104.479,
			104.54,
			104.603,
			104.663,
			104.714,
			104.727,
			104.788,
			104.817,
			104.851,
			104.913,
			104.927,
			104.974,
			105.037,
			105.098,
			105.144,
			105.16,
			105.221,
			105.253,
			105.283,
			105.36,
			105.407,
			105.473,
			105.533,
			105.577,
			105.657,
			105.688,
			105.72,
			105.796,
			105.842,
			105.905,
			105.967,
			106.013,
			106.03,
			106.089,
			106.122,
			106.152,
			106.231,
			106.277,
			106.339,
			106.401,
			106.448,
			106.463,
			106.525,
			106.556,
			106.587,
			106.665,
			106.711,
			106.775,
			106.834,
			106.88,
			106.959,
			106.991,
			107.021,
			107.082,
			107.099,
			107.145,
			107.208,
			107.267,
			107.33,
			107.391,
			107.423,
			107.456,
			107.517,
			107.533,
			107.579,
			107.642,
			107.701,
			107.749,
			107.826,
			107.857,
			107.889,
			107.965,
			108.013,
			108.075,
			108.137,
			108.183,
			108.2,
			108.26,
			108.292,
			108.322,
			108.398,
			108.446,
			108.51,
			108.572,
			108.616,
			108.633,
			108.696,
			108.727,
			108.758,
			108.82,
			108.838,
			108.881,
			108.943,
			109.004,
			109.068,
			109.128,
			109.159,
			109.191,
			109.253,
			109.316,
			109.378,
			109.439,
			109.484,
			109.5,
			109.563,
			109.595,
			109.625,
			109.687,
			109.704,
			109.748,
			109.812,
			109.874,
			109.919,
			109.934,
			109.998,
			110.028,
			110.059,
			110.137,
			110.182,
			110.245,
			110.307,
			110.353,
			110.432,
			110.461,
			110.494,
			110.555,
			110.615,
			110.678,
			110.74,
			110.787,
			110.803,
			110.865,
			110.9,
			110.927,
			111.003,
			111.052,
			111.113,
			111.176,
			111.221,
			111.237,
			111.3,
			111.331,
			111.36,
			111.424,
			111.484,
			111.546,
			111.61,
			111.671,
			111.734,
			111.764,
			111.797,
			111.859,
			111.921,
			111.982,
			112.043,
			112.089,
			112.106,
			112.167,
			112.198,
			112.229,
			112.292,
			112.307,
			112.354,
			112.415,
			112.476,
			112.525,
			112.6,
			112.634,
			112.665,
			112.724,
			112.787,
			112.85,
			112.913,
			112.962,
			113.034,
			113.067,
			113.098,
			113.16,
			113.176,
			113.222,
			113.284,
			113.345,
			113.391,
			113.409,
			113.47,
			113.501,
			113.532,
			113.608,
			113.656,
			113.719,
			113.779,
			113.825,
			113.904,
			113.933,
			113.966,
			114.028,
			114.091,
			114.153,
			114.215,
			114.274,
			114.339,
			114.369,
			114.401,
			114.462,
			114.477,
			114.524,
			114.587,
			114.649,
			114.695,
			114.711,
			114.771,
			114.804,
			114.834,
			114.896,
			114.912,
			114.958,
			115.023,
			115.082,
			115.129,
			115.206,
			115.238,
			115.269,
			115.329,
			115.391,
			115.454,
			115.516,
			115.564,
			115.579,
			115.639,
			115.671,
			115.702,
			115.763,
			115.779,
			115.826,
			115.887,
			115.949,
			115.995,
			116.012,
			116.075,
			116.105,
			116.137,
			116.198,
			116.215,
			116.261,
			116.325,
			116.382,
			116.431,
			116.509,
			116.539,
			116.57,
			116.632,
			116.693,
			116.756,
			116.818,
			116.863,
			116.879,
			116.942,
			116.973,
			117.003,
			117.066,
			117.084,
			117.129,
			117.19,
			117.254,
			117.299,
			117.378,
			117.406,
			117.44,
			117.5,
			117.561,
			117.626,
			117.687,
			117.734,
			117.75,
			117.81,
			117.842,
			117.871,
			117.933,
			117.951,
			117.997,
			118.058,
			118.121,
			118.167,
			118.182,
			118.243,
			118.275,
			118.306,
			118.368,
			118.384,
			118.43,
			118.498,
			118.555,
			118.6,
			118.679,
			118.711,
			118.741,
			118.818,
			118.864,
			118.926,
			118.989,
			119.036,
			119.05,
			119.114,
			119.149,
			119.174,
			119.253,
			119.298,
			119.36,
			119.423,
			119.467,
			119.486,
			119.546,
			119.578,
			119.609,
			119.67,
			119.686,
			119.734,
			119.794,
			119.857,
			119.904,
			119.98,
			120.013,
			120.044,
			120.12,
			120.166,
			120.23,
			120.292,
			120.337,
			120.353,
			120.414,
			120.446,
			120.477,
			120.555,
			120.6,
			120.664,
			120.724,
			120.772,
			120.787,
			120.85,
			120.881,
			120.912,
			120.973,
			120.989,
			121.035,
			121.096,
			121.16,
			121.209,
			121.282,
			121.315,
			121.344,
			121.406,
			121.469,
			121.53,
			121.592,
			121.641,
			121.654,
			121.716,
			121.749,
			121.78,
			121.841,
			121.857,
			121.904,
			121.966,
			122.026,
			122.074,
			122.151,
			122.181,
			122.212,
			122.275,
			122.338,
			122.399,
			122.461,
			122.507,
			122.587,
			122.616,
			122.647,
			122.724,
			122.772,
			122.835,
			122.895,
			122.943,
			122.958,
			123.02,
			123.051,
			123.082,
			123.143,
			123.158,
			123.207,
			123.271,
			123.328,
			123.391,
			123.454,
			123.484,
			123.516,
			123.578,
			123.641,
			123.703,
			123.764,
			123.81,
			123.826,
			123.887,
			123.918,
			123.95,
			124.012,
			124.028,
			124.073,
			124.136,
			124.198,
			124.243,
			124.259,
			124.322,
			124.353,
			124.385,
			124.445,
			124.461,
			124.507,
			124.57,
			124.632,
			124.678,
			124.756,
			124.787,
			124.817,
			124.878,
			124.941,
			125.003,
			125.064,
			125.112,
			125.129,
			125.191,
			125.22,
			125.253,
			125.333,
			125.376,
			125.437,
			125.501,
			125.547,
			125.562,
			125.624,
			125.654,
			125.687,
			125.748,
			125.764,
			125.81,
			125.875,
			125.933,
			125.98,
			126.057,
			126.088,
			126.12,
			126.183,
			126.245,
			126.31,
			126.368,
			126.431,
			126.491,
			126.522,
			126.554,
			126.615,
			126.632,
			126.678,
			126.739,
			126.8,
			126.849,
			126.927,
			126.957,
			126.988,
			127.049,
			127.064,
			127.111,
			127.175,
			127.236,
			127.284,
			127.359,
			127.396,
			127.422,
			127.484,
			127.499,
			127.547,
			127.556
		]
	],
	'2021_4041_7982': [
		[
			108.722
		],
		[
			0
		],
		[
			0,
			2.609,
			7.273,
			8.831,
			13.51,
			16.636,
			19.767,
			21.335,
			24.474,
			27.618,
			29.192,
			32.344,
			33.922,
			37.082,
			40.246,
			45.002,
			48.178,
			52.95,
			54.542,
			56.133,
			60.914,
			64.107,
			68.905,
			72.109,
			75.317,
			76.923,
			80.138,
			81.748,
			84.97,
			88.196,
			89.811,
			91.426,
			96.282,
			99.523,
			104.393,
			107.644,
			110.9,
			112.53,
			115.792,
			117.425,
			120.693,
			123.965,
			128.881,
			132.163,
			137.093,
			138.739,
			140.385,
			145.331,
			148.633,
			153.592,
			156.903,
			160.217,
			161.876,
			165.196,
			166.857,
			170.183,
			173.513,
			175.18,
			178.517,
			181.858,
			186.878,
			188.553,
			190.23,
			193.585,
			196.944,
			198.626,
			203.674,
			207.043,
			210.416,
			215.481,
			218.863,
			223.941,
			225.635,
			227.33,
			232.421,
			234.12,
			235.82,
			240.919,
			244.306,
			247.67,
			249.341,
			252.658,
			254.302,
			257.562,
			260.784,
			262.381,
			265.546,
			268.672,
			273.287,
			276.317,
			280.793,
			282.266,
			283.73,
			288.063,
			290.902,
			295.088,
			297.832,
			300.539,
			301.879,
			304.53,
			305.842,
			308.436,
			310.992,
			312.255,
			313.508,
			317.23,
			319.643,
			322.021,
			323.194,
			325.515,
			327.8,
			328.928,
			331.157,
			332.258,
			334.429,
			336.566,
			339.704,
			341.753,
			344.764,
			345.753,
			346.737,
			349.659,
			351.592,
			354.489,
			356.414,
			358.338,
			359.301,
			361.227,
			362.191,
			363.161,
			366.075,
			367.045,
			368.991,
			370.928,
			373.828,
			374.795,
			375.761,
			377.695,
			379.645,
			380.621,
			382.579,
			383.571,
			385.56,
			387.555,
			390.581,
			392.599,
			395.632,
			396.64,
			397.646,
			400.673,
			402.674,
			405.679,
			407.666,
			409.662,
			410.659,
			412.652,
			413.649,
			415.645,
			417.648,
			418.653,
			420.669,
			422.689,
			425.72,
			426.729,
			427.737,
			429.755,
			431.768,
			432.77,
			434.77,
			435.766,
			437.748,
			440.712,
			442.683,
			444.656,
			447.621,
			448.612,
			449.61,
			452.617,
			453.622,
			454.629,
			457.653,
			459.671,
			461.686,
			462.692,
			464.696,
			465.693,
			467.678,
			469.643,
			470.614,
			472.548,
			474.475,
			477.346,
			479.261,
			482.156,
			483.123,
			484.092,
			487.021,
			488.977,
			491.913,
			493.866,
			495.817,
			498.725,
			499.69,
			501.613,
			503.527,
			504.481,
			505.437,
			508.283,
			510.162,
			512.026,
			512.952,
			514.791,
			516.595,
			517.486,
			519.243,
			520.106,
			521.803,
			523.463,
			525.89,
			527.469,
			529.794,
			530.558,
			531.318,
			533.581,
			535.083,
			537.323,
			538.811,
			541.023,
			542.479,
			543.201,
			544.633,
			546.037,
			546.728,
			547.412,
			549.436,
			550.754,
			552.046,
			552.686,
			553.957,
			555.217,
			555.833,
			557.057,
			557.666,
			558.877,
			560.082,
			561.841,
			563,
			564.723,
			565.293,
			565.853,
			567.521,
			568.624,
			570.27,
			571.361,
			572.446,
			572.986,
			574.065,
			574.603,
			575.683,
			576.77,
			577.312,
			578.394,
			579.476,
			581.107,
			581.653,
			582.198,
			583.835,
			584.382,
			584.929,
			586.57,
			587.661,
			589.297,
			590.39,
			591.475,
			593.092,
			593.63,
			594.167,
			596.32,
			596.856,
			598.457,
			599.521,
			600.587,
			601.127,
			602.203,
			602.74,
			603.809,
			604.877,
			605.412,
			606.505,
			607.599,
			609.232,
			609.775,
			610.318,
			611.971,
			612.521,
			613.071,
			614.714,
			615.807,
			617.464,
			618.562,
			619.656,
			620.204,
			621.311,
			621.874,
			622.437,
			624.683,
			625.244,
			626.972,
			628.145,
			629.32,
			629.908,
			631.091,
			632.35,
			632.98,
			634.24,
			634.87,
			636.136,
			637.495,
			639.532,
			640.891,
			643.051,
			643.774,
			644.497,
			646.688,
			648.191,
			650.442,
			651.956,
			653.48,
			654.239,
			655.757,
			656.515,
			657.27,
			659.528,
			660.28,
			661.791,
			663.314,
			664.84,
			666.372,
			667.134,
			668.659,
			670.189,
			670.955,
			673.176,
			674.645,
			676.836,
			678.201,
			679.583,
			681.655,
			682.309,
			682.961,
			684.894,
			686.165,
			688.016,
			689.231,
			691.042,
			692.251,
			692.847,
			694.034,
			695.213,
			695.8,
			696.978,
			698.184,
			699.977,
			701.166,
			703.014,
			703.642,
			704.269,
			705.518,
			706.142,
			707.418,
			709.392,
			710.705,
			712.021,
			714.075,
			714.759,
			715.444,
			717.515,
			718.225,
			718.933,
			721.056,
			722.474,
			723.899,
			724.609,
			726.027,
			727.443,
			728.148,
			729.551,
			730.251,
			731.649,
			733.068,
			735.221,
			736.652,
			738.853,
			739.596,
			740.338,
			741.82,
			742.562,
			744.109,
			746.42,
			747.954,
			749.47,
			751.723,
			752.47,
			753.19,
			755.311,
			756.716,
			758.78,
			760.122,
			761.457,
			762.123,
			763.447,
			764.755,
			765.407,
			767.356,
			768.646,
			769.924,
			771.835,
			773.105,
			774.954,
			775.57,
			776.188,
			778.046,
			779.257,
			781.076,
			782.296,
			784.114,
			785.321,
			785.926,
			787.143,
			788.366,
			788.976,
			790.201,
			791.433,
			792.676,
			793.934,
			794.568,
			795.844,
			797.132,
			797.781,
			799.754,
			801.087,
			802.434,
			804.481,
			805.862,
			807.963,
			808.67,
			809.377,
			811.521,
			812.244,
			812.97,
			815.161,
			816.637,
			818.127,
			818.876,
			820.372,
			821.125,
			822.64,
			824.179,
			824.966,
			826.552,
			828.151,
			830.602,
			832.253,
			833.904,
			835.568,
			836.405,
			838.075,
			838.931,
			840.664,
			842.424,
			845.055,
			846.821,
			849.494,
			850.391,
			851.291,
			853.995,
			855.82,
			858.58,
			860.431,
			862.293,
			863.229,
			865.125,
			866.079,
			867.998,
			869.928,
			870.898,
			872.845,
			874.839,
			877.874,
			879.887,
			882.92,
			884.884,
			887.856,
			889.87,
			892.921,
			894.977,
			897.047,
			900.172,
			901.214,
			902.255,
			905.389,
			906.437,
			907.488,
			910.652,
			912.771,
			915.962,
			918.072,
			920.159,
			921.189,
			923.219,
			924.221,
			926.189,
			928.117,
			930.97,
			932.836,
			935.588,
			936.489,
			937.375,
			939.969,
			941.659,
			944.127,
			945.72,
			947.277,
			948.043,
			949.546,
			950.272,
			951.698,
			953.778,
			954.457,
			956.467,
			957.826,
			959.857,
			961.219,
			962.585,
			963.268,
			964.61,
			965.267,
			966.582,
			967.898,
			969.865,
			971.177,
			973.18,
			973.847,
			974.515,
			976.494,
			977.806,
			979.76,
			981.051,
			983.013,
			984.346,
			985.011,
			986.341,
			987.673,
			988.382,
			989.795,
			991.204,
			993.374,
			994.283,
			995.19,
			997.906,
			998.81,
			999.644,
			1002.047,
			1003.647,
			1005.246,
			1007.586,
			1009.047,
			1011.226,
			1011.958,
			1012.691,
			1014.806,
			1016.173,
			1018.255,
			1019.663,
			1021.804,
			1023.264,
			1024.006,
			1025.503,
			1027.015,
			1027.777,
			1029.314,
			1030.866,
			1033.357,
			1035.045,
			1037.608,
			1038.477,
			1039.35,
			1041.993,
			1043.773,
			1046.558,
			1048.428,
			1050.298,
			1051.228,
			1053.095,
			1054.033,
			1055.955,
			1057.935,
			1058.928,
			1059.925,
			1062.965,
			1065.021,
			1068.109,
			1070.079,
			1072.038,
			1072.99,
			1074.901,
			1075.843,
			1077.711,
			1079.584,
			1082.385,
			1084.246,
			1086.997,
			1087.912,
			1088.826,
			1091.565,
			1093.425,
			1096.243,
			1098.148,
			1101.028,
			1102.979,
			1103.955,
			1105.917,
			1107.875,
			1108.879,
			1110.905,
			1112.939,
			1114.983,
			1117.031,
			1118.056,
			1120.132,
			1122.18,
			1123.214,
			1125.329,
			1126.397,
			1128.537,
			1130.681,
			1133.889,
			1136.045,
			1139.242,
			1140.304,
			1141.368,
			1144.606,
			1146.793,
			1150.095,
			1152.293,
			1154.519,
			1155.626,
			1157.848,
			1158.962,
			1161.166,
			1163.397,
			1164.516,
			1166.759,
			1169.013,
			1172.418,
			1173.557,
			1174.697,
			1178.131,
			1179.276,
			1180.424,
			1183.883,
			1186.207,
			1188.541,
			1192.061,
			1194.421,
			1197.983,
			1199.172,
			1200.364,
			1203.954,
			1206.356,
			1209.972,
			1212.383,
			1215.972,
			1218.335,
			1219.505,
			1221.824,
			1224.107,
			1225.236,
			1227.467,
			1229.66,
			1232.876,
			1234.992,
			1238.059,
			1239.064,
			1240.043,
			1242.972,
			1244.9,
			1246.771,
			1249.528,
			1251.338,
			1252.238,
			1254.019,
			1254.918,
			1255.832,
			1258.528,
			1260.334,
			1263.056,
			1264.88,
			1266.714,
			1267.637,
			1269.484,
			1270.41,
			1272.271,
			1275.075,
			1276.951,
			1278.837,
			1281.666,
			1283.553,
			1286.373,
			1287.313,
			1288.251,
			1291.048,
			1292.905,
			1295.668,
			1297.494,
			1299.311,
			1300.214,
			1302.013,
			1302.912,
			1303.809,
			1307.388,
			1309.186,
			1310.985,
			1312.785,
			1314.593,
			1315.496,
			1317.302,
			1319.117,
			1320.025,
			1321.837,
			1322.742,
			1324.56,
			1326.372,
			1329.084,
			1330.888,
			1333.581,
			1334.478,
			1335.371,
			1338.041,
			1339.817,
			1342.469,
			1344.229,
			1346.859,
			1348.61,
			1349.486,
			1351.24,
			1353.87,
			1355.631,
			1357.394,
			1360.042,
			1360.926,
			1361.81,
			1363.577,
			1365.349,
			1366.238,
			1368.017,
			1368.906,
			1370.685,
			1372.463,
			1375.127,
			1376.912,
			1379.586,
			1380.477,
			1381.373,
			1384.056,
			1385.845,
			1388.54,
			1390.334,
			1392.133,
			1393.031,
			1394.827,
			1395.725,
			1397.514,
			1400.194,
			1401.986,
			1403.779,
			1406.472,
			1407.371,
			1408.269,
			1410.97,
			1411.871,
			1412.772,
			1415.483,
			1417.293,
			1420.015,
			1421.841,
			1423.681,
			1424.603,
			1426.455,
			1427.387,
			1428.321,
			1431.138,
			1432.083,
			1433.035,
			1435.907,
			1437.837,
			1439.781,
			1440.757,
			1442.725,
			1444.716,
			1445.717,
			1447.733,
			1448.751,
			1450.781,
			1452.838,
			1455.933,
			1458.01,
			1461.134,
			1462.18,
			1463.219,
			1466.296,
			1468.328,
			1471.274,
			1473.2,
			1475.075,
			1475.999,
			1477.818,
			1478.712,
			1480.469,
			1483.037,
			1483.874,
			1486.326,
			1487.916,
			1489.465,
			1490.224,
			1491.715,
			1493.176,
			1493.896,
			1495.307,
			1496.007,
			1497.397,
			1498.791,
			1500.843,
			1502.199,
			1504.224,
			1504.902,
			1505.578,
			1507.596,
			1508.937,
			1510.94,
			1512.268,
			1513.593,
			1514.256,
			1515.568,
			1516.221,
			1517.524,
			1518.828,
			1519.481,
			1520.806,
			1522.129,
			1524.111,
			1524.775,
			1525.467,
			1527.542,
			1528.233,
			1528.925,
			1531.085,
			1532.58,
			1534.075,
			1536.438,
			1538.078,
			1540.567,
			1541.463,
			1542.358,
			1545.042,
			1546.84,
			1549.531,
			1551.294,
			1553.02,
			1553.881,
			1555.601,
			1556.418,
			1558.044,
			1559.667,
			1560.479,
			1562.038,
			1563.574,
			1565.875,
			1566.625,
			1567.365,
			1569.587,
			1570.329,
			1571.072,
			1573.253,
			1574.704,
			1576.159,
			1578.318,
			1579.755,
			1581.92,
			1582.639,
			1583.358,
			1585.516,
			1586.238,
			1586.96,
			1589.128,
			1590.577,
			1592.031,
			1592.763,
			1594.236,
			1594.976,
			1596.463,
			1597.966,
			1598.73,
			1600.266,
			1601.815,
			1604.178,
			1605.782,
			1608.211,
			1609.03,
			1609.854,
			1612.342,
			1614.016,
			1616.541,
			1618.239,
			1619.94,
			1620.792,
			1622.504,
			1623.363,
			1624.221,
			1627.659,
			1629.385,
			1631.105,
			1632.83,
			1634.561,
			1635.429,
			1637.166,
			1638.906,
			1639.785,
			1642.438,
			1644.208,
			1646.023,
			1648.743,
			1650.586,
			1653.377,
			1654.306,
			1655.25,
			1658.091,
			1659.983,
			1662.835,
			1664.73,
			1666.617,
			1667.558,
			1669.44,
			1670.372,
			1672.216,
			1674.058,
			1674.979,
			1676.779,
			1678.567,
			1680.352,
			1682.115,
			1682.98,
			1685.634,
			1686.514,
			1687.396,
			1690.049,
			1691.83,
			1693.605,
			1696.313,
			1698.141,
			1700.907,
			1701.836,
			1702.772,
			1705.597,
			1707.497,
			1710.375,
			1712.31,
			1714.26,
			1715.239,
			1717.206,
			1718.195,
			1720.182,
			1722.18,
			1723.184,
			1725.201,
			1727.229,
			1730.293,
			1732.349,
			1735.457,
			1736.499,
			1737.542,
			1740.671,
			1742.761,
			1745.915,
			1748.029,
			1750.156,
			1753.369,
			1754.447,
			1755.53,
			1758.796,
			1759.892,
			1760.991,
			1764.304,
			1766.522,
			1768.75,
			1769.867,
			1772.106,
			1774.355,
			1775.482,
			1777.744,
			1778.878,
			1781.154,
			1783.44,
			1786.887,
			1789.198,
			1792.682,
			1793.848,
			1795.016,
			1798.538,
			1800.898,
			1804.456,
			1806.839,
			1809.231,
			1812.835,
			1814.041,
			1816.458,
			1818.885,
			1820.102,
			1822.542,
			1824.991,
			1827.449,
			1829.915,
			1831.152,
			1833.631,
			1836.118,
			1837.365,
			1841.116,
			1843.626,
			1846.141,
			1849.926,
			1852.461,
			1856.282,
			1857.561,
			1858.842,
			1862.703,
			1865.289,
			1869.186,
			1871.793,
			1875.708,
			1878.322,
			1879.631,
			1882.254,
			1886.201,
			1888.841,
			1891.488,
			1895.471,
			1896.802,
			1898.135,
			1900.806,
			1903.484,
			1904.825,
			1907.514,
			1908.861,
			1911.56,
			1914.267,
			1918.339,
			1921.062,
			1925.16,
			1926.529,
			1927.9,
			1932.023,
			1934.781,
			1938.929,
			1941.704,
			1944.485,
			1945.879,
			1948.67,
			1950.068,
			1952.87,
			1955.678,
			1957.085,
			1959.904,
			1962.729,
			1966.978,
			1968.397,
			1969.818,
			1972.665,
			1975.518,
			1976.947,
			1979.811,
			1981.246,
			1984.12,
			1988.443,
			1991.334,
			1994.231,
			1998.59,
			2000.046,
			2001.503,
			2005.875,
			2007.33,
			2008.782,
			2013.107,
			2015.955,
			2020.16,
			2022.915,
			2025.632,
			2026.975,
			2030.948,
			2033.549,
			2036.112,
			2039.886,
			2042.355,
			2045.988,
			2047.181,
			2048.365,
			2051.865,
			2054.154,
			2057.516,
			2059.71,
			2062.931,
			2065.03,
			2066.064,
			2067.09,
			2070.107,
			2071.093,
			2073.037,
			2074.947,
			2076.82,
			2078.659,
			2079.569,
			2081.364,
			2083.125,
			2083.993,
			2086.544,
			2088.199,
			2089.818,
			2092.176,
			2093.703,
			2095.924,
			2096.645,
			2097.357,
			2099.452,
			2100.795,
			2102.745,
			2104.004,
			2105.232,
			2105.833,
			2107.016,
			2107.599,
			2108.753,
			2109.894,
			2110.456,
			2111.565,
			2112.668,
			2114.32,
			2114.872,
			2115.416,
			2116.486,
			2117.556,
			2118.092,
			2119.705,
			2120.792,
			2121.883,
			2123.527,
			2124.629,
			2126.381,
			2126.988,
			2127.596,
			2129.421,
			2130.671,
			2132.839,
			2134.282,
			2135.743,
			2136.522,
			2138.074,
			2139.619,
			2140.389,
			2142.558,
			2143.977,
			2145.389,
			2147.271,
			2147.874,
			2148.474,
			2150.26,
			2150.853,
			2151.425,
			2152.544,
			2153.1,
			2154.204,
			2155.849,
			2156.947,
			2158.04,
			2159.669,
			2160.21,
			2160.751,
			2162.374,
			2162.914,
			2163.453,
			2165.069,
			2166.148,
			2167.224,
			2167.762,
			2168.837,
			2169.914,
			2170.453,
			2171.535,
			2172.097,
			2173.234,
			2174.366,
			2176.09,
			2177.277,
			2179.201,
			2179.851,
			2180.504,
			2182.502,
			2183.893,
			2185.996,
			2187.42,
			2188.845,
			2189.557,
			2190.983,
			2191.698,
			2193.145,
			2195.348,
			2196.817,
			2198.299,
			2199.798,
			2201.292,
			2202.039,
			2203.538,
			2205.053,
			2205.809,
			2208.074,
			2209.588,
			2211.095,
			2213.347,
			2214.836,
			2217.054,
			2217.791,
			2218.526,
			2220.645,
			2222.041,
			2224.128,
			2225.486,
			2227.519,
			2228.873,
			2229.539,
			2230.856,
			2232.826,
			2234.141,
			2235.436,
			2236.728,
			2238.02,
			2238.667,
			2239.961,
			2241.243,
			2241.885,
			2243.814,
			2245.103,
			2246.384,
			2247.026,
			2248.314,
			2249.608,
			2251.552,
			2252.202,
			2252.853,
			2254.82,
			2256.142,
			2258.135,
			2259.476,
			2260.829,
			2261.511,
			2262.884,
			2263.574,
			2264.964,
			2266.369,
			2267.078,
			2268.505,
			2269.945,
			2272.131,
			2272.865,
			2273.601,
			2275.084,
			2276.582,
			2277.337,
			2279.618,
			2281.154,
			2283.479,
			2285.044,
			2286.62,
			2288.996,
			2289.787,
			2290.578,
			2292.971,
			2294.583,
			2297.013,
			2298.645,
			2300.283,
			2301.105,
			2302.756,
			2303.59,
			2305.262,
			2306.942,
			2307.784,
			2309.481,
			2311.183,
			2313.748,
			2315.47,
			2318.058,
			2318.927,
			2319.8,
			2322.418,
			2324.171,
			2326.811,
			2328.57,
			2331.226,
			2332.996,
			2333.88,
			2334.769,
			2337.434,
			2338.322,
			2339.208,
			2341.881,
			2343.658,
			2345.441,
			2346.335,
			2348.119,
			2349.903,
			2350.8,
			2352.59,
			2353.484,
			2355.279,
			2357.066,
			2359.768,
			2361.607,
			2364.361,
			2365.284,
			2366.221,
			2369.052,
			2370.955,
			2373.86,
			2375.812,
			2378.782,
			2380.777,
			2382.796,
			2383.811,
			2385.848,
			2386.872,
			2388.933,
			2391,
			2394.144,
			2395.2,
			2396.257,
			2398.386,
			2400.529,
			2401.603,
			2403.761,
			2404.844,
			2407.013,
			2409.191,
			2412.466,
			2414.651,
			2417.934,
			2419.028,
			2420.122,
			2423.412,
			2425.611,
			2428.909,
			2431.097,
			2433.286,
			2434.381,
			2436.571,
			2437.665,
			2439.854,
			2442.049,
			2443.149,
			2445.357,
			2447.575,
			2449.8,
			2452.031,
			2453.149,
			2456.511,
			2457.634,
			2458.76,
			2462.156,
			2464.432,
			2466.724,
			2470.189,
			2472.498,
			2475.971,
			2477.138,
			2478.308,
			2481.833,
			2483.01,
			2484.188,
			2487.74,
			2490.117,
			2492.505,
			2493.703,
			2496.105,
			2497.309,
			2499.72,
			2502.141,
			2503.353,
			2505.783,
			2508.221,
			2511.89,
			2514.341,
			2518.032,
			2519.265,
			2520.5,
			2524.214,
			2526.696,
			2530.427,
			2532.922,
			2535.419,
			2539.176,
			2540.427,
			2541.681,
			2545.448,
			2546.704,
			2547.961,
			2551.739,
			2554.262,
			2558.054,
			2560.59,
			2563.13,
			2564.402,
			2566.951,
			2568.226,
			2570.782,
			2573.337,
			2577.178,
			2579.736,
			2583.577,
			2584.858,
			2586.142,
			2589.989,
			2592.579,
			2596.511,
			2599.129,
			2601.76,
			2603.056,
			2605.705,
			2607.033,
			2609.697,
			2613.739,
			2615.083,
			2619.105,
			2621.832,
			2625.897,
			2628.611,
			2631.329,
			2632.696,
			2635.413,
			2636.783,
			2639.526,
			2642.276,
			2646.408,
			2649.17,
			2653.327,
			2654.716,
			2656.105,
			2660.282,
			2663.083,
			2667.325,
			2670.149,
			2672.953,
			2674.37,
			2677.206,
			2678.625,
			2681.466,
			2684.31,
			2685.734,
			2688.584,
			2691.44,
			2695.734,
			2697.169,
			2698.605,
			2702.924,
			2704.367,
			2705.812,
			2710.158,
			2713.065,
			2715.979,
			2720.368,
			2723.304,
			2727.724,
			2729.201,
			2730.681,
			2735.13,
			2736.616,
			2738.103,
			2742.573,
			2745.559,
			2748.552,
			2750.05,
			2753.052,
			2754.555,
			2757.566,
			2760.584,
			2762.096,
			2765.123,
			2768.156,
			2772.717,
			2775.765,
			2780.346,
			2781.875,
			2783.405,
			2788.005,
			2791.077,
			2795.7,
			2798.789,
			2801.885,
			2803.435,
			2806.54,
			2808.095,
			2809.651,
			2814.327,
			2815.888,
			2817.451,
			2822.147,
			2825.284,
			2828.426,
			2829.998,
			2833.146,
			2836.3,
			2837.878,
			2841.038,
			2842.62,
			2845.788,
			2848.96,
			2853.729,
			2856.914,
			2861.694,
			2863.289,
			2864.885,
			2869.678,
			2872.879,
			2877.688,
			2880.9,
			2884.115,
			2885.725,
			2888.947,
			2890.559,
			2893.788,
			2897.021,
			2898.64,
			2901.879,
			2905.123,
			2908.371,
			2911.623,
			2913.251,
			2918.141,
			2919.773,
			2921.406,
			2924.675,
			2926.311,
			2929.586,
			2932.866,
			2937.793,
			2941.083,
			2946.025,
			2947.674,
			2949.324,
			2954.28,
			2957.588,
			2962.558,
			2965.876,
			2969.198,
			2970.861,
			2974.189,
			2975.855,
			2979.19,
			2982.53,
			2984.201,
			2987.547,
			2990.896,
			2995.928,
			2997.607,
			2999.288,
			3004.335,
			3006.019,
			3007.704,
			3012.765,
			3016.145,
			3019.528,
			3024.61,
			3028.003,
			3033.1,
			3034.802,
			3036.504,
			3041.618,
			3045.032,
			3050.16,
			3053.583,
			3058.721,
			3062.15,
			3065.584,
			3067.302,
			3072.462,
			3075.907,
			3079.357,
			3084.54,
			3088,
			3093.196,
			3094.929,
			3096.664,
			3101.874,
			3105.352,
			3110.575,
			3114.062,
			3119.298,
			3122.791,
			3124.539,
			3126.288,
			3131.541,
			3133.293,
			3135.047,
			3140.313,
			3143.83,
			3147.349,
			3149.109,
			3152.635,
			3156.164,
			3157.93,
			3161.465,
			3163.234,
			3166.774,
			3170.317,
			3175.636,
			3179.187,
			3184.515,
			3186.293,
			3188.072,
			3193.413,
			3196.978,
			3202.331,
			3205.904,
			3209.48,
			3211.269,
			3214.849,
			3216.639,
			3220.223,
			3223.809,
			3225.603,
			3229.192,
			3232.785,
			3236.382,
			3241.784,
			3245.389,
			3248.999,
			3250.805,
			3254.42,
			3256.229,
			3259.848,
			3263.469,
			3268.907,
			3272.537,
			3277.987,
			3279.805,
			3281.624,
			3287.084,
			3290.729,
			3296.201,
			3299.852,
			3303.505,
			3305.333,
			3308.993,
			3310.823,
			3314.487,
			3318.153,
			3319.988,
			3323.66,
			3327.334,
			3332.851,
			3334.691,
			3336.532,
			3342.057,
			3343.9,
			3345.744,
			3351.28,
			3354.973,
			3358.668,
			3364.218,
			3367.921,
			3373.481,
			3377.191,
			3382.761,
			3384.618,
			3386.476,
			3392.053,
			3395.774,
			3399.499,
			3401.362,
			3405.089,
			3406.954,
			3410.685,
			3414.419,
			3416.286,
			3420.024,
			3423.764,
			3429.379,
			3431.251,
			3433.124,
			3438.744,
			3440.618,
			3442.492,
			3448.12,
			3451.875,
			3457.512,
			3461.276,
			3465.041,
			3470.694,
			3472.579,
			3474.465,
			3482.014,
			3483.903,
			3489.572,
			3493.354,
			3497.14,
			3499.032,
			3502.82,
			3506.609,
			3508.504,
			3512.295,
			3514.192,
			3517.986,
			3521.784,
			3527.482,
			3531.284,
			3536.992,
			3538.895,
			3540.799,
			3546.515,
			3550.329,
			3556.055,
			3559.874,
			3563.697,
			3565.609,
			3569.435,
			3571.348,
			3573.262,
			3579.006,
			3580.92,
			3582.834,
			3588.558,
			3592.346,
			3596.101,
			3597.964,
			3601.66,
			3605.317,
			3607.131,
			3610.729,
			3612.513,
			3616.05,
			3619.548,
			3624.719,
			3628.119,
			3633.145,
			3634.801,
			3636.447,
			3641.327,
			3644.532,
			3649.266,
			3652.374,
			3655.443,
			3656.964,
			3659.979,
			3661.473,
			3664.433,
			3667.354,
			3668.8,
			3671.662,
			3674.485,
			3678.646,
			3681.373,
			3685.392,
			3686.713,
			3688.024,
			3690.618,
			3691.902,
			3694.439,
			3696.939,
			3700.619,
			3703.026,
			3706.572,
			3707.736,
			3708.893,
			3712.303,
			3714.53,
			3717.801,
			3719.934,
			3722.03,
			3723.064,
			3725.104,
			3726.111,
			3728.095,
			3730.043,
			3731.004,
			3732.898,
			3734.755,
			3737.482,
			3738.375,
			3739.258,
			3741.861,
			3742.71,
			3743.55,
			3746.016,
			3747.614,
			3749.941,
			3751.447,
			3752.916,
			3755.051,
			3755.744,
			3756.429,
			3758.434,
			3759.086,
			3759.729,
			3761.605,
			3762.81,
			3763.979,
			3764.55,
			3765.661,
			3766.733,
			3767.256,
			3768.276,
			3768.772,
			3769.743,
			3770.685,
			3772.055,
			3772.939,
			3774.245,
			3774.677,
			3775.107,
			3776.377,
			3777.207,
			3778.434,
			3779.25,
			3780.464,
			3781.246,
			3781.634,
			3782.402,
			3783.168,
			3783.55,
			3783.933,
			3785.101,
			3785.933,
			3786.758,
			3787.168,
			3787.988,
			3788.809,
			3789.22,
			3790.638,
			3791.732,
			3792.824,
			3794.468,
			3795.755,
			3797.886,
			3798.598,
			3799.313,
			3801.485,
			3802.927,
			3805.078,
			3806.349,
			3807.611,
			3808.24,
			3809.494,
			3810.114,
			3811.245,
			3812.377,
			3812.944,
			3814.081,
			3815.208,
			3816.801,
			3817.864,
			3819.465,
			3820.001,
			3820.511,
			3822.041,
			3823.064,
			3824.608,
			3825.619,
			3826.616,
			3828.123,
			3828.628,
			3829.136,
			3830.668,
			3831.18,
			3831.694,
			3833.249,
			3834.299,
			3835.368,
			3835.915,
			3837.018,
			3837.573,
			3838.693,
			3839.828,
			3840.406,
			3841.589,
			3842.786,
			3844.593,
			3845.807,
			3847.662,
			3848.287,
			3848.915,
			3850.829,
			3852.125,
			3854.097,
			3855.433,
			3856.781,
			3857.461,
			3858.832,
			3859.522,
			3860.216,
			3862.32,
			3863.025,
			3863.731,
			3865.862,
			3867.302,
			3869.476,
			3870.963,
			3872.455,
			3873.207,
			3875.487,
			3877.012,
			3878.565,
			3880.922,
			3882.512,
			3884.937,
			3885.75,
			3886.567,
			3889.024,
			3890.676,
			3893.184,
			3894.849,
			3896.541,
			3897.392,
			3899.103,
			3899.963,
			3900.826,
			3903.43,
			3904.305,
			3906.062,
			3907.831,
			3910.507,
			3911.406,
			3912.308,
			3914.122,
			3915.948,
			3916.869,
			3918.719,
			3919.648,
			3921.521,
			3923.411,
			3926.272,
			3928.202,
			3931.12,
			3932.101,
			3933.083,
			3936.052,
			3938.046,
			3941.059,
			3943.082,
			3945.117,
			3946.139,
			3948.192,
			3949.223,
			3951.293,
			3953.375,
			3954.42,
			3956.507,
			3958.597,
			3961.748,
			3962.804,
			3963.862,
			3967.051,
			3968.119,
			3969.189,
			3971.338,
			3972.415,
			3974.578,
			3976.75,
			3980.027,
			3982.224,
			3985.538,
			3986.647,
			3987.76,
			3991.112,
			3992.234,
			3993.359,
			3996.748,
			3999.021,
			4001.303,
			4002.448,
			4004.745,
			4005.897,
			4008.208,
			4010.529,
			4011.693,
			4014.029,
			4016.374,
			4019.909,
			4021.092,
			4022.277,
			4025.847,
			4027.042,
			4028.239,
			4031.845,
			4034.26,
			4037.901,
			4040.34,
			4042.788,
			4046.48,
			4047.716,
			4048.953,
			4052.68,
			4053.927,
			4055.177,
			4058.938,
			4061.457,
			4065.25,
			4067.787,
			4070.329,
			4071.603,
			4074.151,
			4075.427,
			4077.98,
			4080.532,
			4084.354,
			4086.889,
			4090.659,
			4091.902,
			4093.139,
			4096.797,
			4099.191,
			4102.712,
			4105.018,
			4107.285,
			4108.406,
			4110.62,
			4111.712,
			4112.796,
			4115.993,
			4117.04,
			4118.077,
			4121.131,
			4123.122,
			4125.076,
			4126.039,
			4127.938,
			4129.814,
			4130.732,
			4132.55,
			4133.448,
			4135.232,
			4137,
			4139.618,
			4141.35,
			4143.925,
			4144.776,
			4145.627,
			4148.164,
			4149.829,
			4152.319,
			4153.957,
			4156.397,
			4158.027,
			4158.853,
			4160.502,
			4162.996,
			4164.799,
			4166.603,
			4169.488,
			4171.5,
			4174.579,
			4175.62,
			4176.661,
			4178.7,
			4179.694,
			4181.68,
			4183.579,
			4186.266,
			4188.045,
			4190.571,
			4191.417,
			4192.265,
			4194.783,
			4196.463,
			4199.013,
			4200.734,
			4202.467,
			4203.346,
			4205.131,
			4206.028,
			4207.832,
			4209.672,
			4210.598,
			4212.458,
			4214.34,
			4217.192,
			4218.149,
			4219.11,
			4222.011,
			4222.983,
			4223.957,
			4226.89,
			4228.851,
			4230.815,
			4233.772,
			4235.748,
			4238.75,
			4239.755,
			4240.763,
			4243.822,
			4244.842,
			4245.865,
			4248.929,
			4250.996,
			4253.066,
			4254.103,
			4256.178,
			4258.259,
			4259.324,
			4261.406,
			4262.458,
			4264.573,
			4266.7,
			4269.913,
			4272.07,
			4275.326,
			4276.417,
			4277.51,
			4280.805,
			4283.014,
			4286.342,
			4288.573,
			4290.812,
			4291.935,
			4294.188,
			4295.316,
			4297.582,
			4300.997,
			4302.158,
			4305.579,
			4309.038,
			4310.195,
			4311.354,
			4313.681,
			4316.016,
			4317.188,
			4319.538,
			4320.717,
			4323.083,
			4325.458,
			4329.039,
			4331.438,
			4335.056,
			4336.267,
			4337.479,
			4341.133,
			4342.355,
			4343.58,
			4347.268,
			4349.739,
			4352.22,
			4353.464,
			4355.959,
			4357.21,
			4359.72,
			4362.24,
			4363.503,
			4366.037,
			4368.581,
			4372.412,
			4373.693,
			4374.977,
			4378.841,
			4380.133,
			4381.428,
			4385.322,
			4387.929,
			4390.536,
			4394.457,
			4397.079,
			4401.026,
			4402.345,
			4403.666,
			4407.638,
			4410.294,
			4414.291,
			4416.965,
			4419.646,
			4420.988,
			4423.681,
			4426.379,
			4427.731,
			4430.44,
			4431.797,
			4434.517,
			4437.243,
			4441.346,
			4442.717,
			4444.089,
			4448.218,
			4449.598,
			4450.979,
			4455.134,
			4457.913,
			4462.093,
			4464.888,
			4467.689,
			4469.093,
			4471.905,
			4473.312,
			4474.723,
			4478.962,
			4481.797,
			4486.062,
			4488.912,
			4491.769,
			4493.201,
			4496.068,
			4498.943,
			4500.383,
			4503.268,
			4504.712,
			4507.605,
			4510.507,
			4514.869,
			4516.327,
			4517.786,
			4522.173,
			4523.639,
			4525.105,
			4529.516,
			4532.463,
			4536.898,
			4539.863,
			4542.833,
			4544.32,
			4547.3,
			4548.792,
			4551.781,
			4554.775,
			4556.275,
			4559.278,
			4562.288,
			4565.304,
			4569.839,
			4572.869,
			4575.905,
			4577.425,
			4580.47,
			4581.994,
			4585.047,
			4588.106,
			4592.705,
			4595.778,
			4600.397,
			4601.94,
			4603.484,
			4608.124,
			4611.225,
			4614.634
		],
		[
			0,
			0.033,
			0.11,
			0.157,
			0.217,
			0.28,
			0.325,
			0.341,
			0.403,
			0.434,
			0.467,
			0.529,
			0.544,
			0.589,
			0.653,
			0.715,
			0.766,
			0.837,
			0.868,
			0.901,
			0.963,
			1.023,
			1.087,
			1.148,
			1.196,
			1.21,
			1.271,
			1.304,
			1.335,
			1.395,
			1.412,
			1.457,
			1.519,
			1.581,
			1.645,
			1.706,
			1.736,
			1.768,
			1.83,
			1.845,
			1.892,
			1.954,
			2.015,
			2.062,
			2.14,
			2.172,
			2.202,
			2.265,
			2.327,
			2.388,
			2.452,
			2.498,
			2.513,
			2.575,
			2.607,
			2.638,
			2.697,
			2.715,
			2.761,
			2.827,
			2.883,
			2.93,
			2.947,
			3.007,
			3.041,
			3.07,
			3.133,
			3.192,
			3.257,
			3.32,
			3.364,
			3.44,
			3.475,
			3.504,
			3.566,
			3.583,
			3.628,
			3.692,
			3.753,
			3.8,
			3.815,
			3.877,
			3.907,
			3.939,
			4.001,
			4.015,
			4.063,
			4.123,
			4.186,
			4.247,
			4.312,
			4.342,
			4.373,
			4.451,
			4.497,
			4.56,
			4.62,
			4.668,
			4.683,
			4.745,
			4.777,
			4.807,
			4.868,
			4.887,
			4.931,
			4.994,
			5.055,
			5.102,
			5.116,
			5.179,
			5.21,
			5.24,
			5.304,
			5.319,
			5.365,
			5.427,
			5.489,
			5.534,
			5.612,
			5.644,
			5.673,
			5.737,
			5.798,
			5.862,
			5.922,
			5.968,
			5.985,
			6.046,
			6.079,
			6.107,
			6.172,
			6.188,
			6.233,
			6.295,
			6.358,
			6.404,
			6.418,
			6.481,
			6.513,
			6.541,
			6.604,
			6.621,
			6.666,
			6.731,
			6.791,
			6.839,
			6.916,
			6.95,
			6.976,
			7.056,
			7.101,
			7.163,
			7.224,
			7.273,
			7.287,
			7.349,
			7.379,
			7.412,
			7.472,
			7.488,
			7.535,
			7.597,
			7.66,
			7.705,
			7.721,
			7.782,
			7.814,
			7.845,
			7.906,
			7.924,
			7.97,
			8.033,
			8.094,
			8.139,
			8.218,
			8.249,
			8.281,
			8.341,
			8.358,
			8.404,
			8.464,
			8.527,
			8.575,
			8.589,
			8.651,
			8.682,
			8.712,
			8.775,
			8.79,
			8.838,
			8.9,
			8.962,
			9.012,
			9.086,
			9.118,
			9.148,
			9.211,
			9.272,
			9.335,
			9.396,
			9.44,
			9.521,
			9.551,
			9.583,
			9.645,
			9.658,
			9.706,
			9.769,
			9.831,
			9.874,
			9.892,
			9.954,
			9.986,
			10.016,
			10.078,
			10.092,
			10.14,
			10.203,
			10.265,
			10.311,
			10.387,
			10.42,
			10.449,
			10.512,
			10.575,
			10.636,
			10.699,
			10.759,
			10.821,
			10.853,
			10.884,
			10.946,
			10.962,
			11.006,
			11.073,
			11.132,
			11.179,
			11.194,
			11.256,
			11.287,
			11.318,
			11.38,
			11.396,
			11.439,
			11.503,
			11.567,
			11.612,
			11.69,
			11.722,
			11.751,
			11.813,
			11.875,
			11.939,
			11.999,
			12.046,
			12.062,
			12.123,
			12.155,
			12.187,
			12.248,
			12.263,
			12.311,
			12.372,
			12.433,
			12.481,
			12.497,
			12.558,
			12.589,
			12.62,
			12.697,
			12.744,
			12.807,
			12.868,
			12.915,
			12.993,
			13.022,
			13.054,
			13.134,
			13.179,
			13.24,
			13.301,
			13.348,
			13.364,
			13.425,
			13.457,
			13.489,
			13.549,
			13.567,
			13.613,
			13.674,
			13.737,
			13.782,
			13.798,
			13.861,
			13.891,
			13.923,
			13.985,
			14.047,
			14.108,
			14.17,
			14.218,
			14.232,
			14.294,
			14.324,
			14.355,
			14.434,
			14.48,
			14.543,
			14.606,
			14.651,
			14.665,
			14.729,
			14.761,
			14.79,
			14.851,
			14.869,
			14.914,
			14.978,
			15.037,
			15.084,
			15.162,
			15.197,
			15.223,
			15.286,
			15.349,
			15.415,
			15.474,
			15.518,
			15.536,
			15.597,
			15.627,
			15.657,
			15.72,
			15.736,
			15.782,
			15.846,
			15.907,
			15.952,
			15.97,
			16.03,
			16.062,
			16.094,
			16.17,
			16.217,
			16.283,
			16.34,
			16.403,
			16.464,
			16.496,
			16.525,
			16.589,
			16.65,
			16.714,
			16.774,
			16.837,
			16.899,
			16.929,
			16.961,
			17.024,
			17.039,
			17.086,
			17.147,
			17.21,
			17.257,
			17.333,
			17.365,
			17.396,
			17.457,
			17.472,
			17.52,
			17.583,
			17.642,
			17.69,
			17.768,
			17.798,
			17.829,
			17.892,
			17.908,
			17.954,
			18.016,
			18.077,
			18.124,
			18.139,
			18.201,
			18.232,
			18.262,
			18.325,
			18.339,
			18.386,
			18.449,
			18.511,
			18.573,
			18.634,
			18.666,
			18.697,
			18.757,
			18.774,
			18.822,
			18.884,
			18.946,
			18.993,
			19.068,
			19.101,
			19.131,
			19.192,
			19.256,
			19.321,
			19.38,
			19.425,
			19.44,
			19.504,
			19.533,
			19.565,
			19.644,
			19.691,
			19.752,
			19.815,
			19.861,
			19.937,
			19.968,
			20,
			20.062,
			20.124,
			20.187,
			20.248,
			20.311,
			20.372,
			20.404,
			20.434,
			20.497,
			20.512,
			20.558,
			20.619,
			20.682,
			20.729,
			20.745,
			20.806,
			20.835,
			20.867,
			20.946,
			20.99,
			21.055,
			21.115,
			21.178,
			21.24,
			21.272,
			21.301,
			21.365,
			21.384,
			21.425,
			21.489,
			21.548,
			21.596,
			21.612,
			21.674,
			21.705,
			21.737,
			21.799,
			21.812,
			21.859,
			21.923,
			21.984,
			22.047,
			22.106,
			22.139,
			22.17,
			22.232,
			22.248,
			22.295,
			22.357,
			22.419,
			22.463,
			22.541,
			22.573,
			22.606,
			22.667,
			22.729,
			22.789,
			22.851,
			22.899,
			22.913,
			22.977,
			23.006,
			23.038,
			23.1,
			23.117,
			23.162,
			23.225,
			23.286,
			23.351,
			23.411,
			23.471,
			23.533,
			23.596,
			23.657,
			23.721,
			23.782,
			23.842,
			23.875,
			23.907,
			23.969,
			23.984,
			24.03,
			24.093,
			24.153,
			24.215,
			24.279,
			24.31,
			24.341,
			24.403,
			24.417,
			24.463,
			24.525,
			24.588,
			24.649,
			24.713,
			24.742,
			24.774,
			24.837,
			24.899,
			24.961,
			25.022,
			25.068,
			25.084,
			25.148,
			25.177,
			25.208,
			25.286,
			25.331,
			25.394,
			25.457,
			25.518,
			25.58,
			25.612,
			25.642,
			25.706,
			25.719,
			25.766,
			25.83,
			25.89,
			25.937,
			26.015,
			26.046,
			26.077,
			26.139,
			26.2,
			26.263,
			26.323,
			26.386,
			26.448,
			26.479,
			26.511,
			26.572,
			26.588,
			26.634,
			26.696,
			26.758,
			26.806,
			26.821,
			26.883,
			26.914,
			26.946,
			27.022,
			27.068,
			27.132,
			27.194,
			27.24,
			27.315,
			27.348,
			27.378,
			27.442,
			27.502,
			27.565,
			27.627,
			27.689,
			27.751,
			27.782,
			27.814,
			27.875,
			27.889,
			27.937,
			27.999,
			28.062,
			28.109,
			28.185,
			28.217,
			28.248,
			28.308,
			28.371,
			28.434,
			28.495,
			28.54,
			28.557,
			28.62,
			28.649,
			28.682,
			28.741,
			28.76,
			28.804,
			28.868,
			28.929,
			28.99,
			29.052,
			29.084,
			29.115,
			29.177,
			29.194,
			29.24,
			29.302,
			29.365,
			29.426,
			29.486,
			29.519,
			29.549,
			29.612,
			29.672,
			29.734,
			29.798,
			29.858,
			29.922,
			29.953,
			29.984,
			30.046,
			30.062,
			30.108,
			30.171,
			30.232,
			30.279,
			30.294,
			30.356,
			30.387,
			30.418,
			30.479,
			30.495,
			30.541,
			30.603,
			30.665,
			30.712,
			30.789,
			30.82,
			30.852,
			30.915,
			30.974,
			31.042,
			31.099,
			31.146,
			31.162,
			31.224,
			31.256,
			31.286,
			31.348,
			31.363,
			31.407,
			31.473,
			31.533,
			31.58,
			31.596,
			31.658,
			31.692,
			31.72,
			31.782,
			31.841,
			31.904,
			31.969,
			32.013,
			32.09,
			32.122,
			32.155,
			32.216,
			32.278,
			32.34,
			32.402,
			32.462,
			32.525,
			32.557,
			32.587,
			32.649,
			32.666,
			32.712,
			32.773,
			32.837,
			32.896,
			32.961,
			32.99,
			33.021,
			33.084,
			33.145,
			33.207,
			33.268,
			33.315,
			33.332,
			33.394,
			33.423,
			33.457,
			33.517,
			33.58,
			33.64,
			33.704,
			33.756,
			33.767,
			33.828,
			33.857,
			33.891,
			33.968,
			34.015,
			34.077,
			34.139,
			34.186,
			34.262,
			34.291,
			34.324,
			34.385,
			34.449,
			34.509,
			34.572,
			34.619,
			34.635,
			34.696,
			34.727,
			34.757,
			34.834,
			34.882,
			34.945,
			35.005,
			35.054,
			35.067,
			35.131,
			35.161,
			35.19,
			35.255,
			35.268,
			35.315,
			35.379,
			35.44,
			35.487,
			35.565,
			35.595,
			35.626,
			35.704,
			35.749,
			35.813,
			35.873,
			35.937,
			35.998,
			36.03,
			36.062,
			36.137,
			36.183,
			36.245,
			36.309,
			36.356,
			36.37,
			36.432,
			36.464,
			36.494,
			36.556,
			36.573,
			36.62,
			36.679,
			36.742,
			36.79,
			36.868,
			36.897,
			36.929,
			36.99,
			37.052,
			37.115,
			37.177,
			37.223,
			37.239,
			37.3,
			37.332,
			37.363,
			37.439,
			37.487,
			37.554,
			37.612,
			37.656,
			37.674,
			37.733,
			37.765,
			37.798,
			37.859,
			37.921,
			37.983,
			38.046,
			38.09,
			38.108,
			38.169,
			38.2,
			38.232,
			38.29,
			38.308,
			38.355,
			38.418,
			38.479,
			38.524,
			38.539,
			38.604,
			38.634,
			38.663,
			38.727,
			38.743,
			38.787,
			38.85,
			38.913,
			38.958,
			39.036,
			39.068,
			39.1,
			39.162,
			39.222,
			39.284,
			39.346,
			39.391,
			39.408,
			39.471,
			39.502,
			39.534,
			39.611,
			39.656,
			39.718,
			39.78,
			39.828,
			39.841,
			39.904,
			39.94,
			39.966,
			40.029,
			40.045,
			40.092,
			40.155,
			40.216,
			40.263,
			40.34,
			40.369,
			40.4,
			40.48,
			40.526,
			40.587,
			40.649,
			40.695,
			40.711,
			40.774,
			40.804,
			40.834,
			40.896,
			40.912,
			40.958,
			41.023,
			41.083,
			41.129,
			41.146,
			41.208,
			41.237,
			41.269,
			41.346,
			41.394,
			41.455,
			41.518,
			41.563,
			41.642,
			41.672,
			41.704,
			41.765,
			41.829,
			41.888,
			41.951,
			42.004,
			42.012,
			42.077,
			42.106,
			42.136,
			42.198,
			42.215,
			42.262,
			42.325,
			42.386,
			42.431,
			42.449,
			42.509,
			42.54,
			42.571,
			42.633,
			42.696,
			42.756,
			42.821,
			42.867,
			42.944,
			42.974,
			43.005,
			43.066,
			43.084,
			43.131,
			43.191,
			43.255,
			43.301,
			43.316,
			43.379,
			43.407,
			43.439,
			43.501,
			43.518,
			43.564,
			43.625,
			43.686,
			43.733,
			43.812,
			43.842,
			43.874,
			43.952,
			43.997,
			44.065,
			44.122,
			44.169,
			44.183,
			44.246,
			44.277,
			44.307,
			44.386,
			44.432,
			44.494,
			44.554,
			44.602,
			44.616,
			44.679,
			44.711,
			44.74,
			44.818,
			44.866,
			44.929,
			44.99,
			45.037,
			45.113,
			45.146,
			45.175,
			45.239,
			45.301,
			45.362,
			45.425,
			45.471,
			45.487,
			45.548,
			45.578,
			45.609,
			45.672,
			45.688,
			45.733,
			45.795,
			45.857,
			45.904,
			45.919,
			45.982,
			46.012,
			46.044,
			46.107,
			46.169,
			46.231,
			46.291,
			46.34,
			46.417,
			46.446,
			46.478,
			46.54,
			46.602,
			46.666,
			46.726,
			46.771,
			46.789,
			46.851,
			46.88,
			46.913,
			46.974,
			46.991,
			47.035,
			47.099,
			47.161,
			47.208,
			47.284,
			47.316,
			47.347,
			47.408,
			47.47,
			47.534,
			47.592,
			47.641,
			47.717,
			47.749,
			47.78,
			47.841,
			47.858,
			47.904,
			47.967,
			48.03,
			48.074,
			48.091,
			48.153,
			48.187,
			48.216,
			48.274,
			48.292,
			48.339,
			48.401,
			48.462,
			48.51,
			48.586,
			48.617,
			48.649,
			48.711,
			48.772,
			48.834,
			48.897,
			48.944,
			49.021,
			49.051,
			49.083,
			49.145,
			49.159,
			49.207,
			49.268,
			49.331,
			49.377,
			49.39,
			49.454,
			49.486,
			49.516,
			49.595,
			49.639,
			49.702,
			49.763,
			49.812,
			49.888,
			49.92,
			49.95,
			50.028,
			50.074,
			50.138,
			50.198,
			50.262,
			50.321,
			50.355,
			50.383,
			50.463,
			50.508,
			50.571,
			50.633,
			50.679,
			50.696,
			50.757,
			50.788,
			50.82,
			50.881,
			50.895,
			50.942,
			51.006,
			51.066,
			51.113,
			51.19,
			51.223,
			51.253,
			51.316,
			51.378,
			51.441,
			51.501,
			51.548,
			51.562,
			51.624,
			51.656,
			51.686,
			51.748,
			51.765,
			51.811,
			51.873,
			51.936,
			51.982,
			51.996,
			52.057,
			52.089,
			52.12,
			52.182,
			52.199,
			52.246,
			52.313,
			52.37,
			52.416,
			52.494,
			52.525,
			52.556,
			52.616,
			52.633,
			52.679,
			52.74,
			52.803,
			52.865,
			52.925,
			52.959,
			52.989,
			53.067,
			53.112,
			53.174,
			53.238,
			53.299,
			53.359,
			53.394,
			53.423,
			53.5,
			53.549,
			53.611,
			53.673,
			53.735,
			53.796,
			53.828,
			53.856,
			53.92,
			53.936,
			53.983,
			54.045,
			54.106,
			54.153,
			54.167,
			54.231,
			54.261,
			54.291,
			54.374,
			54.414,
			54.478,
			54.54,
			54.585,
			54.663,
			54.695,
			54.727,
			54.788,
			54.849,
			54.913,
			54.973,
			55.021,
			55.035,
			55.098,
			55.13,
			55.16,
			55.222,
			55.238,
			55.285,
			55.346,
			55.408,
			55.455,
			55.469,
			55.532,
			55.563,
			55.592,
			55.672,
			55.719,
			55.781,
			55.84,
			55.887,
			55.965,
			55.997,
			56.028,
			56.106,
			56.151,
			56.215,
			56.274,
			56.321,
			56.338,
			56.4,
			56.435,
			56.463,
			56.54,
			56.586,
			56.648,
			56.709,
			56.755,
			56.771,
			56.834,
			56.865,
			56.896,
			56.957,
			56.972,
			57.021,
			57.083,
			57.144,
			57.19,
			57.268,
			57.298,
			57.331,
			57.391,
			57.408,
			57.454,
			57.516,
			57.578,
			57.624,
			57.641,
			57.701,
			57.734,
			57.763,
			57.827,
			57.84,
			57.888,
			57.952,
			58.012,
			58.059,
			58.137,
			58.166,
			58.199,
			58.259,
			58.322,
			58.384,
			58.446,
			58.498,
			58.509,
			58.569,
			58.602,
			58.632,
			58.708,
			58.757,
			58.82,
			58.881,
			58.928,
			58.944,
			59.005,
			59.034,
			59.067,
			59.145,
			59.19,
			59.251,
			59.314,
			59.377,
			59.439,
			59.47,
			59.501,
			59.578,
			59.625,
			59.688,
			59.748,
			59.811,
			59.871,
			59.904,
			59.933,
			60.014,
			60.058,
			60.121,
			60.181,
			60.23,
			60.246,
			60.306,
			60.338,
			60.368,
			60.446,
			60.492,
			60.555,
			60.558,
			60.615,
			60.663,
			60.74,
			60.772,
			60.801,
			60.864,
			60.928,
			60.989,
			61.049,
			61.098,
			61.112,
			61.173,
			61.204,
			61.239,
			61.298,
			61.313,
			61.361,
			61.422,
			61.483,
			61.531,
			61.545,
			61.606,
			61.64,
			61.67,
			61.749,
			61.796,
			61.857,
			61.918,
			61.98,
			62.042,
			62.073,
			62.105,
			62.182,
			62.229,
			62.291,
			62.353,
			62.398,
			62.415,
			62.477,
			62.506,
			62.539,
			62.601,
			62.619,
			62.664,
			62.725,
			62.786,
			62.849,
			62.912,
			62.94,
			62.974,
			63.034,
			63.097,
			63.158,
			63.222,
			63.284,
			63.346,
			63.376,
			63.407,
			63.469,
			63.483,
			63.531,
			63.597,
			63.656,
			63.701,
			63.718,
			63.78,
			63.812,
			63.842,
			63.902,
			63.919,
			63.965,
			64.028,
			64.09,
			64.134,
			64.212,
			64.244,
			64.275,
			64.354,
			64.399,
			64.462,
			64.524,
			64.585,
			64.646,
			64.683,
			64.708,
			64.771,
			64.788,
			64.833,
			64.897,
			64.958,
			65.004,
			65.019,
			65.082,
			65.114,
			65.143,
			65.206,
			65.221,
			65.268,
			65.329,
			65.39,
			65.439,
			65.516,
			65.547,
			65.576,
			65.639,
			65.701,
			65.764,
			65.825,
			65.871,
			65.889,
			65.949,
			65.981,
			66.012,
			66.074,
			66.09,
			66.136,
			66.197,
			66.257,
			66.307,
			66.321,
			66.383,
			66.415,
			66.446,
			66.524,
			66.569,
			66.631,
			66.692,
			66.755,
			66.817,
			66.848,
			66.879,
			66.94,
			66.957,
			67.004,
			67.066,
			67.129,
			67.174,
			67.19,
			67.251,
			67.281,
			67.313,
			67.374,
			67.39,
			67.439,
			67.501,
			67.562,
			67.609,
			67.685,
			67.717,
			67.748,
			67.827,
			67.871,
			67.936,
			67.996,
			68.043,
			68.121,
			68.152,
			68.181,
			68.244,
			68.26,
			68.306,
			68.368,
			68.429,
			68.492,
			68.553,
			68.584,
			68.616,
			68.679,
			68.693,
			68.74,
			68.802,
			68.865,
			68.912,
			68.987,
			69.019,
			69.051,
			69.113,
			69.175,
			69.237,
			69.298,
			69.345,
			69.361,
			69.422,
			69.453,
			69.483,
			69.562,
			69.606,
			69.67,
			69.731,
			69.795,
			69.856,
			69.888,
			69.918,
			69.981,
			69.997,
			70.04,
			70.104,
			70.165,
			70.212,
			70.29,
			70.321,
			70.353,
			70.43,
			70.477,
			70.539,
			70.599,
			70.646,
			70.664,
			70.725,
			70.757,
			70.788,
			70.848,
			70.869,
			70.912,
			70.974,
			71.034,
			71.082,
			71.096,
			71.159,
			71.189,
			71.222,
			71.297,
			71.345,
			71.405,
			71.468,
			71.514,
			71.592,
			71.625,
			71.655,
			71.718,
			71.732,
			71.779,
			71.84,
			71.903,
			71.949,
			71.966,
			72.026,
			72.057,
			72.089,
			72.152,
			72.166,
			72.213,
			72.275,
			72.335,
			72.384,
			72.461,
			72.492,
			72.522,
			72.584,
			72.647,
			72.708,
			72.769,
			72.818,
			72.832,
			72.896,
			72.929,
			72.957,
			73.019,
			73.033,
			73.081,
			73.141,
			73.205,
			73.252,
			73.267,
			73.329,
			73.36,
			73.39,
			73.452,
			73.469,
			73.515,
			73.579,
			73.638,
			73.7,
			73.762,
			73.795,
			73.824,
			73.888,
			73.948,
			74.012,
			74.073,
			74.12,
			74.136,
			74.198,
			74.228,
			74.258,
			74.322,
			74.335,
			74.383,
			74.446,
			74.506,
			74.553,
			74.568,
			74.632,
			74.663,
			74.692,
			74.756,
			74.77,
			74.818,
			74.879,
			74.94,
			74.991,
			75.066,
			75.096,
			75.128,
			75.19,
			75.252,
			75.313,
			75.375,
			75.421,
			75.436,
			75.498,
			75.531,
			75.562,
			75.623,
			75.637,
			75.686,
			75.747,
			75.81,
			75.856,
			75.872,
			75.934,
			75.965,
			75.996,
			76.058,
			76.118,
			76.181,
			76.244,
			76.291,
			76.368,
			76.399,
			76.43,
			76.492,
			76.555,
			76.615,
			76.679,
			76.739,
			76.8,
			76.834,
			76.865,
			76.941,
			76.989,
			77.055,
			77.111,
			77.172,
			77.236,
			77.267,
			77.298,
			77.359,
			77.421,
			77.483,
			77.546,
			77.609,
			77.668,
			77.7,
			77.731,
			77.794,
			77.808,
			77.856,
			77.917,
			77.981,
			78.024,
			78.041,
			78.105,
			78.133,
			78.167,
			78.229,
			78.244,
			78.29,
			78.352,
			78.413,
			78.46,
			78.539,
			78.568,
			78.6,
			78.662,
			78.725,
			78.787,
			78.847,
			78.894,
			78.91,
			78.97,
			79.001,
			79.035,
			79.097,
			79.114,
			79.159,
			79.221,
			79.282,
			79.344,
			79.407,
			79.436,
			79.468,
			79.529,
			79.546,
			79.592,
			79.655,
			79.717,
			79.763,
			79.841,
			79.87,
			79.902,
			79.964,
			80.024,
			80.092,
			80.151,
			80.197,
			80.212,
			80.274,
			80.305,
			80.337,
			80.398,
			80.415,
			80.46,
			80.524,
			80.583,
			80.629,
			80.647,
			80.708,
			80.739,
			80.771,
			80.833,
			80.894,
			80.956,
			81.018,
			81.066,
			81.14,
			81.205,
			81.265,
			81.283,
			81.329,
			81.389,
			81.452,
			81.499,
			81.513,
			81.575,
			81.606,
			81.637,
			81.7,
			81.716,
			81.763,
			81.824,
			81.887,
			81.932,
			81.949,
			82.01,
			82.04,
			82.071,
			82.135,
			82.196,
			82.258,
			82.32,
			82.367,
			82.445,
			82.475,
			82.506,
			82.583,
			82.631,
			82.694,
			82.755,
			82.801,
			82.817,
			82.879,
			82.91,
			82.941,
			83.003,
			83.017,
			83.063,
			83.126,
			83.188,
			83.24,
			83.313,
			83.342,
			83.373,
			83.437,
			83.498,
			83.562,
			83.624,
			83.669,
			83.685,
			83.746,
			83.778,
			83.806,
			83.871,
			83.885,
			83.931,
			83.998,
			84.055,
			84.104,
			84.12,
			84.181,
			84.211,
			84.242,
			84.304,
			84.321,
			84.367,
			84.429,
			84.492,
			84.554,
			84.614,
			84.646,
			84.678,
			84.738,
			84.8,
			84.863,
			84.924,
			84.971,
			84.987,
			85.048,
			85.08,
			85.112,
			85.174,
			85.188,
			85.236,
			85.301,
			85.36,
			85.421,
			85.483,
			85.513,
			85.545,
			85.607,
			85.624,
			85.669,
			85.731,
			85.792,
			85.841,
			85.916,
			85.948,
			85.979,
			86.042,
			86.104,
			86.165,
			86.228,
			86.274,
			86.289,
			86.351,
			86.381,
			86.414,
			86.474,
			86.49,
			86.537,
			86.601,
			86.662,
			86.706,
			86.723,
			86.785,
			86.816,
			86.847,
			86.91,
			86.971,
			87.034,
			87.096,
			87.157,
			87.218,
			87.25,
			87.282,
			87.344,
			87.365,
			87.406,
			87.467,
			87.529,
			87.574,
			87.59,
			87.653,
			87.684,
			87.715,
			87.777,
			87.791,
			87.84,
			87.901,
			87.965,
			88.023,
			88.088,
			88.117,
			88.148,
			88.212,
			88.273,
			88.335,
			88.397,
			88.461,
			88.521,
			88.553,
			88.585,
			88.646,
			88.662,
			88.706,
			88.769,
			88.83,
			88.879,
			88.894,
			88.956,
			88.987,
			89.018,
			89.096,
			89.14,
			89.204,
			89.264,
			89.312,
			89.39,
			89.424,
			89.453,
			89.513,
			89.575,
			89.638,
			89.7,
			89.746,
			89.762,
			89.823,
			89.856,
			89.884,
			89.946,
			89.964,
			90.01,
			90.071,
			90.135,
			90.196,
			90.259,
			90.289,
			90.32,
			90.397,
			90.444,
			90.51,
			90.569,
			90.616,
			90.691,
			90.722,
			90.755,
			90.815,
			90.833,
			90.878,
			90.94,
			91.001,
			91.049,
			91.065,
			91.125,
			91.156,
			91.188,
			91.251,
			91.266,
			91.313,
			91.375,
			91.435,
			91.485,
			91.561,
			91.592,
			91.622,
			91.685,
			91.747,
			91.808,
			91.869,
			91.916,
			91.933,
			91.994,
			92.025,
			92.055,
			92.118,
			92.134,
			92.181,
			92.242,
			92.304,
			92.366,
			92.428,
			92.46,
			92.494,
			92.569,
			92.615,
			92.677,
			92.739,
			92.784,
			92.862,
			92.893,
			92.925,
			92.986,
			93.048,
			93.112,
			93.172,
			93.22,
			93.233,
			93.297,
			93.325,
			93.357,
			93.421,
			93.435,
			93.483,
			93.544,
			93.607,
			93.653,
			93.668,
			93.732,
			93.762,
			93.79,
			93.855,
			93.869,
			93.917,
			93.979,
			94.039,
			94.087,
			94.165,
			94.196,
			94.228,
			94.288,
			94.351,
			94.412,
			94.474,
			94.521,
			94.535,
			94.599,
			94.629,
			94.66,
			94.722,
			94.738,
			94.784,
			94.845,
			94.908,
			94.955,
			94.971,
			95.032,
			95.062,
			95.096,
			95.155,
			95.171,
			95.219,
			95.279,
			95.344,
			95.39,
			95.468,
			95.497,
			95.529,
			95.589,
			95.613,
			95.653,
			95.715,
			95.778,
			95.822,
			95.838,
			95.9,
			95.931,
			95.964,
			96.025,
			96.041,
			96.087,
			96.15,
			96.211,
			96.256,
			96.272,
			96.335,
			96.366,
			96.397,
			96.458,
			96.521,
			96.585,
			96.646,
			96.692,
			96.768,
			96.8,
			96.831,
			96.892,
			96.91,
			96.954,
			97.019,
			97.078,
			97.14,
			97.203,
			97.233,
			97.265,
			97.328,
			97.342,
			97.389,
			97.452,
			97.512,
			97.56,
			97.638,
			97.672,
			97.698,
			97.777,
			97.824,
			97.886,
			97.946,
			97.992,
			98.009,
			98.072,
			98.101,
			98.132,
			98.196,
			98.211,
			98.256,
			98.319,
			98.382,
			98.428,
			98.441,
			98.504,
			98.536,
			98.568,
			98.63,
			98.646,
			98.691,
			98.753,
			98.815,
			98.862,
			98.939,
			98.969,
			99.002,
			99.065,
			99.125,
			99.188,
			99.249,
			99.313,
			99.373,
			99.404,
			99.436,
			99.512,
			99.56,
			99.622,
			99.683,
			99.746,
			99.809,
			99.839,
			99.869,
			99.931,
			99.946,
			99.992,
			100.056,
			100.118,
			100.163,
			100.239,
			100.272,
			100.304,
			100.365,
			100.428,
			100.489,
			100.55,
			100.597,
			100.612,
			100.674,
			100.707,
			100.738,
			100.8,
			100.815,
			100.862,
			100.924,
			100.985,
			101.031,
			101.048,
			101.111,
			101.14,
			101.173,
			101.234,
			101.296,
			101.356,
			101.42,
			101.467,
			101.544,
			101.574,
			101.607,
			101.668,
			101.683,
			101.73,
			101.79,
			101.854,
			101.901,
			101.916,
			101.979,
			102.009,
			102.039,
			102.101,
			102.118,
			102.165,
			102.227,
			102.287,
			102.351,
			102.413,
			102.441,
			102.475,
			102.552,
			102.598,
			102.662,
			102.722,
			102.768,
			102.785,
			102.845,
			102.878,
			102.909,
			102.986,
			103.031,
			103.092,
			103.157,
			103.203,
			103.219,
			103.279,
			103.311,
			103.342,
			103.405,
			103.419,
			103.465,
			103.528,
			103.59,
			103.637,
			103.715,
			103.746,
			103.775,
			103.838,
			103.858,
			103.901,
			103.965,
			104.025,
			104.069,
			104.087,
			104.148,
			104.178,
			104.211,
			104.273,
			104.289,
			104.333,
			104.397,
			104.457,
			104.506,
			104.52,
			104.582,
			104.613,
			104.645,
			104.721,
			104.768,
			104.832,
			104.893,
			104.954,
			105.017,
			105.048,
			105.079,
			105.14,
			105.202,
			105.266,
			105.325,
			105.372,
			105.388,
			105.451,
			105.482,
			105.512,
			105.574,
			105.591,
			105.638,
			105.699,
			105.762,
			105.806,
			105.824,
			105.884,
			105.921,
			105.945,
			106.009,
			106.071,
			106.133,
			106.195,
			106.24,
			106.257,
			106.319,
			106.348,
			106.382,
			106.441,
			106.504,
			106.566,
			106.629,
			106.675,
			106.689,
			106.752,
			106.782,
			106.814,
			106.877,
			106.891,
			106.939,
			107.002,
			107.062,
			107.107,
			107.123,
			107.187,
			107.217,
			107.248,
			107.312,
			107.372,
			107.437,
			107.498,
			107.543,
			107.559,
			107.621,
			107.652,
			107.683,
			107.745,
			107.761,
			107.808,
			107.869,
			107.931,
			107.984,
			108.055,
			108.085,
			108.118,
			108.179,
			108.194,
			108.24,
			108.304,
			108.366,
			108.412,
			108.488,
			108.519,
			108.55,
			108.612,
			108.675,
			108.722
		]
	],
	'2021_4042_7982': [
		[
			78.984
		],
		[
			0
		],
		[
			0,
			0.422,
			3.152,
			7.259,
			10.006,
			12.76,
			14.14,
			16.903,
			18.288,
			21.062,
			23.843,
			28.027,
			30.825,
			35.035,
			36.442,
			37.85,
			42.084,
			44.916,
			49.175,
			52.023,
			56.308,
			59.174,
			60.608,
			63.483,
			66.365,
			67.808,
			70.699,
			73.597,
			77.955,
			79.411,
			80.868,
			83.788,
			86.714,
			88.18,
			92.586,
			95.531,
			98.482,
			102.921,
			105.887,
			110.349,
			111.839,
			113.33,
			117.814,
			120.812,
			125.319,
			128.33,
			131.348,
			132.859,
			135.887,
			137.404,
			140.441,
			143.485,
			145.009,
			148.061,
			151.118,
			155.715,
			158.786,
			163.403,
			164.945,
			166.488,
			171.124,
			174.221,
			178.876,
			181.986,
			185.101,
			189.786,
			191.35,
			192.917,
			197.628,
			199.202,
			200.777,
			205.511,
			208.672,
			211.837,
			213.42,
			216.59,
			219.76,
			221.346,
			224.52,
			226.109,
			229.287,
			232.467,
			237.241,
			240.432,
			245.231,
			246.834,
			248.439,
			253.265,
			256.489,
			261.318,
			264.516,
			267.686,
			269.256,
			272.368,
			273.909,
			275.44,
			279.977,
			281.471,
			284.432,
			287.353,
			290.235,
			294.487,
			297.273,
			300.023,
			301.384,
			304.079,
			305.413,
			308.073,
			310.675,
			314.509,
			317.021,
			320.718,
			321.932,
			323.138,
			326.692,
			329.016,
			332.423,
			334.647,
			337.906,
			340.037,
			341.092,
			343.185,
			345.267,
			346.304,
			348.372,
			350.448,
			353.575,
			354.623,
			355.677,
			357.785,
			359.894,
			360.95,
			364.104,
			366.199,
			368.278,
			371.372,
			373.406,
			376.434,
			377.436,
			378.435,
			381.449,
			383.466,
			386.532,
			388.597,
			390.679,
			391.729,
			393.826,
			395.924,
			396.972,
			400.099,
			402.167,
			404.216,
			407.237,
			409.214,
			412.123,
			413.081,
			414.036,
			416.886,
			418.784,
			421.647,
			423.567,
			425.499,
			428.429,
			429.412,
			430.398,
			433.382,
			434.382,
			435.384,
			438.414,
			440.436,
			442.464,
			443.476,
			445.499,
			447.511,
			448.513,
			450.508,
			451.498,
			453.463,
			455.411,
			458.298,
			460.205,
			463.045,
			463.986,
			464.926,
			467.738,
			469.607,
			472.413,
			474.287,
			477.107,
			478.991,
			479.936,
			481.832,
			483.74,
			484.697,
			485.655,
			488.546,
			490.483,
			492.434,
			493.416,
			495.387,
			497.368,
			498.364,
			500.356,
			501.352,
			503.352,
			505.349,
			508.327,
			510.295,
			513.217,
			514.179,
			515.137,
			517.983,
			519.85,
			522.619,
			524.437,
			526.23,
			527.118,
			528.86,
			529.718,
			531.411,
			533.88,
			535.469,
			537.052,
			539.331,
			540.072,
			540.805,
			542.955,
			543.654,
			544.348,
			545.718,
			546.396,
			547.741,
			549.727,
			551.046,
			552.364,
			554.322,
			554.97,
			555.619,
			557.56,
			558.838,
			560.734,
			561.992,
			563.859,
			565.085,
			565.696,
			566.911,
			568.119,
			568.718,
			569.907,
			571.086,
			572.84,
			573.421,
			573.998,
			575.715,
			576.283,
			576.849,
			578.541,
			579.648,
			581.297,
			582.392,
			583.486,
			585.124,
			585.668,
			586.211,
			587.838,
			588.381,
			588.927,
			590.575,
			591.671,
			592.769,
			593.32,
			594.443,
			595.577,
			596.143,
			597.274,
			597.84,
			598.976,
			600.123,
			601.836,
			602.976,
			604.699,
			605.275,
			605.849,
			607.567,
			608.712,
			610.438,
			611.582,
			613.291,
			614.43,
			614.999,
			616.133,
			617.261,
			617.823,
			618.386,
			620.097,
			621.249,
			622.398,
			622.973,
			624.124,
			625.333,
			625.943,
			627.162,
			627.771,
			628.992,
			630.91,
			632.202,
			633.494,
			635.48,
			636.156,
			636.83,
			638.852,
			640.225,
			642.309,
			643.697,
			645.105,
			645.827,
			647.268,
			647.989,
			649.432,
			650.921,
			651.669,
			653.167,
			654.669,
			656.237,
			657.803,
			658.587,
			660.173,
			661.79,
			662.601,
			664.227,
			665.043,
			666.671,
			668.302,
			670.717,
			672.284,
			674.646,
			675.404,
			676.152,
			678.406,
			679.903,
			682.069,
			683.5,
			685.59,
			686.884,
			687.53,
			688.812,
			690.084,
			690.714,
			691.94,
			693.158,
			694.971,
			695.585,
			696.206,
			698.056,
			698.669,
			699.281,
			701.186,
			702.51,
			704.479,
			705.834,
			707.27,
			707.986,
			709.415,
			710.129,
			710.877,
			713.152,
			714.665,
			716.94,
			718.458,
			720.734,
			722.238,
			723.743,
			724.496,
			726.004,
			726.755,
			728.256,
			729.758,
			732.015,
			733.516,
			735.773,
			736.54,
			737.306,
			739.596,
			741.118,
			743.378,
			744.876,
			746.373,
			747.117,
			748.598,
			749.336,
			750.81,
			752.252,
			752.968,
			753.682,
			755.815,
			757.227,
			759.328,
			760.723,
			762.126,
			762.826,
			764.22,
			764.914,
			766.3,
			768.37,
			769.74,
			771.108,
			773.093,
			773.747,
			774.4,
			776.359,
			777.607,
			779.458,
			780.688,
			782.509,
			783.706,
			784.305,
			785.503,
			786.703,
			787.298,
			788.486,
			789.674,
			791.458,
			792.053,
			792.645,
			794.423,
			795.017,
			795.613,
			796.808,
			797.408,
			798.61,
			799.816,
			801.638,
			802.87,
			804.734,
			805.359,
			805.986,
			807.899,
			809.198,
			811.166,
			812.499,
			813.866,
			814.553,
			815.936,
			817.336,
			818.056,
			819.504,
			820.233,
			821.698,
			823.157,
			825.307,
			826.752,
			828.929,
			829.655,
			830.383,
			832.578,
			834.077,
			836.362,
			837.895,
			840.217,
			841.78,
			842.566,
			843.359,
			845.778,
			846.591,
			847.408,
			849.89,
			851.55,
			853.216,
			854.06,
			855.758,
			857.467,
			858.327,
			860.063,
			860.935,
			862.688,
			864.457,
			867.135,
			868.937,
			871.664,
			872.58,
			873.498,
			876.272,
			878.137,
			880.952,
			882.844,
			884.742,
			885.695,
			887.609,
			888.57,
			890.49,
			892.421,
			893.391,
			894.355,
			897.264,
			899.213,
			901.146,
			902.124,
			904.108,
			906.09,
			907.086,
			910.108,
			912.147,
			914.205,
			917.319,
			919.408,
			922.577,
			923.638,
			924.703,
			927.914,
			930.062,
			933.298,
			935.463,
			938.708,
			940.886,
			941.978,
			944.168,
			946.377,
			947.457,
			949.661,
			951.872,
			954.094,
			957.475,
			959.715,
			961.987,
			963.126,
			965.416,
			966.564,
			968.868,
			971.186,
			974.683,
			977.031,
			980.579,
			981.768,
			982.96,
			986.554,
			988.963,
			992.593,
			995.024,
			997.463,
			998.686,
			1001.137,
			1002.367,
			1004.831,
			1007.303,
			1008.543,
			1011.027,
			1013.519,
			1017.271,
			1019.782,
			1023.563,
			1024.828,
			1026.094,
			1028.633,
			1029.905,
			1032.456,
			1036.299,
			1038.871,
			1041.453,
			1045.343,
			1046.644,
			1047.947,
			1051.869,
			1054.493,
			1058.43,
			1061.061,
			1065.021,
			1067.671,
			1070.328,
			1071.659,
			1074.328,
			1075.665,
			1078.344,
			1081.03,
			1085.071,
			1087.773,
			1091.837,
			1093.194,
			1094.553,
			1098.638,
			1101.367,
			1105.471,
			1108.213,
			1112.336,
			1115.092,
			1116.471,
			1119.236,
			1122.007,
			1123.395,
			1124.785,
			1128.965,
			1131.759,
			1135.963,
			1138.775,
			1141.592,
			1143.003,
			1145.83,
			1147.246,
			1150.083,
			1152.926,
			1157.203,
			1160.063,
			1164.364,
			1165.8,
			1167.239,
			1171.564,
			1174.454,
			1178.802,
			1181.708,
			1184.619,
			1186.078,
			1189,
			1190.464,
			1193.395,
			1196.333,
			1197.804,
			1199.277,
			1203.705,
			1206.666,
			1209.632,
			1211.118,
			1214.094,
			1217.076,
			1218.569,
			1221.559,
			1223.056,
			1226.048,
			1229.031,
			1233.462,
			1236.375,
			1240.672,
			1242.085,
			1243.488,
			1247.638,
			1250.357,
			1254.36,
			1256.981,
			1259.563,
			1260.84,
			1263.365,
			1264.613,
			1267.08,
			1269.509,
			1270.709,
			1273.082,
			1275.421,
			1278.862,
			1279.99,
			1281.109,
			1284.407,
			1285.487,
			1286.558,
			1289.715,
			1291.772,
			1294.789,
			1296.754,
			1298.682,
			1301.506,
			1302.429,
			1303.343,
			1306.035,
			1307.787,
			1310.35,
			1312.013,
			1313.64,
			1314.439,
			1316.012,
			1317.547,
			1318.302,
			1319.783,
			1320.511,
			1321.939,
			1323.344,
			1325.368,
			1326.675,
			1328.572,
			1329.187,
			1329.794,
			1331.555,
			1332.678,
			1334.309,
			1335.365,
			1336.39,
			1336.889,
			1337.873,
			1338.36,
			1339.323,
			1340.278,
			1340.752,
			1341.224,
			1342.625,
			1343.556,
			1344.954,
			1345.889,
			1346.839,
			1347.315,
			1348.268,
			1348.745,
			1349.703,
			1350.666,
			1352.149,
			1353.147,
			1354.656,
			1355.163,
			1355.672,
			1357.243,
			1358.304,
			1359.913,
			1361.001,
			1362.128,
			1362.695,
			1363.837,
			1364.413,
			1365.575,
			1366.771,
			1367.379,
			1368.604,
			1369.841,
			1371.735,
			1372.378,
			1373.023,
			1374.316,
			1375.613,
			1376.268,
			1377.598,
			1378.261,
			1379.58,
			1380.894,
			1382.925,
			1384.262,
			1386.251,
			1386.942,
			1387.625,
			1389.635,
			1390.952,
			1393.001,
			1394.346,
			1396.355,
			1397.736,
			1399.1,
			1399.778,
			1401.806,
			1403.148,
			1404.48,
			1406.456,
			1407.084,
			1407.711,
			1409.583,
			1410.206,
			1410.828,
			1412.548,
			1413.669,
			1415.348,
			1416.444,
			1417.471,
			1419.009,
			1419.522,
			1420.036,
			1421.573,
			1422.581,
			1424.097,
			1425.113,
			1426.134,
			1426.656,
			1427.704,
			1428.757,
			1429.286,
			1430.35,
			1430.885,
			1431.979,
			1433.089,
			1434.77,
			1435.903,
			1437.647,
			1438.236,
			1438.828,
			1440.622,
			1441.842,
			1443.709,
			1444.973,
			1446.253,
			1446.902,
			1448.214,
			1448.876,
			1449.542,
			1451.563,
			1452.247,
			1452.934,
			1455.019,
			1456.429,
			1457.855,
			1458.574,
			1460.024,
			1461.49,
			1462.227,
			1463.713,
			1464.462,
			1465.972,
			1467.492,
			1469.798,
			1471.354,
			1473.696,
			1474.488,
			1475.284,
			1477.691,
			1479.313,
			1481.77,
			1483.419,
			1485.068,
			1485.892,
			1487.545,
			1488.376,
			1490.048,
			1491.733,
			1492.582,
			1493.434,
			1496.008,
			1497.749,
			1500.387,
			1502.167,
			1503.968,
			1504.874,
			1506.694,
			1507.613,
			1509.459,
			1511.318,
			1514.136,
			1516.03,
			1518.896,
			1519.858,
			1520.823,
			1523.733,
			1525.687,
			1528.635,
			1530.613,
			1532.601,
			1533.598,
			1535.6,
			1536.606,
			1538.626,
			1540.657,
			1541.677,
			1543.724,
			1545.783,
			1548.891,
			1549.933,
			1550.976,
			1554.105,
			1555.149,
			1556.195,
			1559.351,
			1561.467,
			1563.593,
			1566.8,
			1568.949,
			1572.191,
			1573.276,
			1574.363,
			1577.641,
			1579.839,
			1583.155,
			1585.377,
			1587.608,
			1588.727,
			1590.973,
			1593.229,
			1594.36,
			1596.63,
			1597.77,
			1600.055,
			1602.351,
			1605.82,
			1608.146,
			1611.656,
			1612.832,
			1614.01,
			1617.559,
			1619.938,
			1623.524,
			1625.928,
			1628.361,
			1631.995,
			1633.209,
			1634.423,
			1638.068,
			1639.284,
			1640.5,
			1644.145,
			1646.572,
			1648.991,
			1650.198,
			1652.607,
			1654.995,
			1656.187,
			1659.742,
			1662.104,
			1664.462,
			1668.001,
			1670.363,
			1673.913,
			1675.099,
			1676.286,
			1679.88,
			1682.294,
			1685.976,
			1688.451,
			1692.201,
			1694.698,
			1695.956,
			1698.468,
			1700.993,
			1702.235,
			1704.702,
			1707.136,
			1710.728,
			1711.909,
			1713.08,
			1715.399,
			1717.685,
			1718.813,
			1721.045,
			1722.151,
			1724.345,
			1726.523,
			1729.782,
			1731.959,
			1735.247,
			1736.348,
			1737.452,
			1740.781,
			1743.013,
			1746.386,
			1748.647,
			1750.921,
			1752.059,
			1754.341,
			1755.483,
			1757.761,
			1761.168,
			1763.422,
			1765.666,
			1769.012,
			1770.124,
			1771.234,
			1774.553,
			1775.654,
			1776.754,
			1780.042,
			1782.225,
			1785.484,
			1787.646,
			1789.801,
			1793.017,
			1794.085,
			1795.15,
			1799.397,
			1800.456,
			1803.632,
			1805.749,
			1807.865,
			1808.923,
			1811.037,
			1813.151,
			1814.209,
			1816.322,
			1817.379,
			1819.494,
			1821.611,
			1824.793,
			1826.921,
			1830.12,
			1831.188,
			1832.257,
			1835.472,
			1837.62,
			1840.854,
			1843.018,
			1845.192,
			1846.283,
			1848.47,
			1849.57,
			1850.672,
			1853.994,
			1855.105,
			1856.22,
			1859.576,
			1861.825,
			1865.209,
			1867.472,
			1869.74,
			1870.875,
			1873.15,
			1874.289,
			1876.573,
			1878.866,
			1882.318,
			1884.633,
			1888.124,
			1889.291,
			1890.459,
			1893.976,
			1896.32,
			1899.847,
			1902.2,
			1905.742,
			1908.111,
			1909.299,
			1911.677,
			1914.061,
			1915.254,
			1917.643,
			1920.033,
			1923.618,
			1924.813,
			1926.009,
			1928.404,
			1930.803,
			1932.005,
			1934.414,
			1935.619,
			1938.036,
			1940.461,
			1944.112,
			1946.555,
			1950.217,
			1951.448,
			1952.68,
			1956.372,
			1958.817,
			1962.439,
			1964.811,
			1967.148,
			1968.302,
			1970.585,
			1971.714,
			1973.943,
			1976.133,
			1977.214,
			1979.348,
			1981.444,
			1984.516,
			1986.515,
			1988.481,
			1990.413,
			1991.38,
			1994.182,
			1996.009,
			1997.814,
			2000.488,
			2002.29,
			2004.976,
			2005.875,
			2006.774,
			2009.48,
			2011.295,
			2014.021,
			2015.847,
			2017.677,
			2018.592,
			2020.422,
			2021.34,
			2023.17,
			2024.997,
			2025.913,
			2027.737,
			2029.557,
			2032.283,
			2034.099,
			2036.827,
			2037.737,
			2038.646,
			2041.382,
			2043.208,
			2045.947,
			2047.774,
			2049.594,
			2050.507,
			2052.338,
			2053.252,
			2054.164,
			2056.913,
			2057.83,
			2058.746,
			2061.5,
			2063.337,
			2065.173,
			2066.094,
			2067.933,
			2069.773,
			2070.694,
			2072.533,
			2073.453,
			2075.292,
			2077.128,
			2079.879,
			2081.714,
			2084.463,
			2085.379,
			2086.297,
			2089.044,
			2090.874,
			2093.628,
			2095.465,
			2097.322,
			2098.25,
			2100.107,
			2101.044,
			2102.921,
			2104.799,
			2105.739,
			2107.622,
			2109.504,
			2111.388,
			2114.216,
			2116.104,
			2117.99,
			2118.934,
			2120.821,
			2121.76,
			2123.638,
			2125.518,
			2128.32,
			2130.188,
			2132.98,
			2133.91,
			2134.84,
			2137.627,
			2138.554,
			2139.482,
			2142.265,
			2144.118,
			2146.899,
			2148.751,
			2149.678,
			2151.534,
			2153.391,
			2154.321,
			2156.187,
			2158.064,
			2160.891,
			2162.798,
			2165.67,
			2166.641,
			2167.614,
			2170.543,
			2172.53,
			2175.533,
			2177.557,
			2179.596,
			2182.664,
			2183.69,
			2184.72,
			2187.793,
			2189.846,
			2192.895,
			2194.942,
			2196.984,
			2198.002,
			2200.048,
			2202.089,
			2203.109,
			2205.157,
			2206.183,
			2208.227,
			2210.281,
			2213.361,
			2215.413,
			2218.513,
			2220.592,
			2223.739,
			2225.851,
			2229.056,
			2231.206,
			2233.379,
			2234.47,
			2236.661,
			2237.763,
			2239.975,
			2242.197,
			2243.311,
			2244.428,
			2247.789,
			2250.036,
			2252.295,
			2253.428,
			2255.703,
			2258.009,
			2259.169,
			2261.521,
			2262.711,
			2265.097,
			2267.522,
			2271.215,
			2273.735,
			2277.53,
			2278.798,
			2280.066,
			2283.801,
			2286.306,
			2289.95,
			2292.353,
			2294.713,
			2295.892,
			2298.219,
			2299.374,
			2301.678,
			2305.108,
			2307.368,
			2309.589,
			2311.815,
			2314.041,
			2315.173,
			2317.405,
			2319.645,
			2320.767,
			2323.017,
			2324.144,
			2326.385,
			2328.656,
			2332.081,
			2334.378,
			2337.84,
			2339,
			2340.161,
			2343.659,
			2346.004,
			2349.539,
			2351.907,
			2354.287,
			2355.481,
			2357.876,
			2359.078,
			2361.489,
			2363.91,
			2365.125,
			2367.561,
			2370.008,
			2373.697,
			2374.932,
			2376.168,
			2379.895,
			2381.142,
			2382.391,
			2384.898,
			2386.154,
			2388.674,
			2391.202,
			2395.01,
			2397.559,
			2401.396,
			2402.678,
			2403.962,
			2407.826,
			2410.41,
			2414.302,
			2416.907,
			2419.52,
			2420.828,
			2423.444,
			2424.753,
			2427.375,
			2430.003,
			2431.318,
			2433.941,
			2436.547,
			2440.403,
			2441.669,
			2442.927,
			2446.646,
			2447.865,
			2449.078,
			2452.659,
			2455.009,
			2458.466,
			2460.725,
			2462.946,
			2466.205,
			2467.272,
			2468.329,
			2471.442,
			2473.47,
			2476.438,
			2478.367,
			2480.253,
			2481.181,
			2483.012,
			2484.796,
			2485.674,
			2487.406,
			2488.258,
			2489.926,
			2491.556,
			2493.936,
			2495.479,
			2497.727,
			2498.458,
			2499.184,
			2501.304,
			2502.673,
			2504.669,
			2505.958,
			2507.83,
			2509.031,
			2509.617,
			2510.764,
			2511.882,
			2512.423,
			2512.955,
			2514.494,
			2515.474,
			2516.417,
			2516.875,
			2517.768,
			2518.632,
			2519.052,
			2519.873,
			2520.274,
			2521.063,
			2521.839,
			2522.981,
			2523.732,
			2524.846,
			2525.215,
			2525.585,
			2526.695,
			2527.44,
			2528.565,
			2529.316,
			2530.064,
			2530.438,
			2531.183,
			2531.555,
			2532.299,
			2533.043,
			2533.416,
			2534.166,
			2534.906,
			2536,
			2536.361,
			2536.72,
			2537.792,
			2538.148,
			2538.506,
			2539.269,
			2539.643,
			2540.38,
			2541.104,
			2542.176,
			2542.887,
			2544.053,
			2544.477,
			2544.896,
			2546.137,
			2546.955,
			2548.178,
			2549.161,
			2550.237,
			2550.771,
			2551.834,
			2552.366,
			2553.433,
			2555.181,
			2556.338,
			2557.495,
			2559.072,
			2559.52,
			2559.969,
			2561.319,
			2561.771,
			2562.225,
			2563.583,
			2564.314,
			2565.424,
			2566.172,
			2566.929,
			2567.311,
			2568.081,
			2568.462,
			2568.871,
			2570.541,
			2570.96,
			2572.212,
			2573.042,
			2573.932,
			2574.436,
			2575.433,
			2576.428,
			2576.918,
			2577.892,
			2578.376,
			2579.506,
			2580.661,
			2582.367,
			2583.496,
			2585.421,
			2586.058,
			2586.692,
			2588.579,
			2589.746,
			2591.455,
			2592.579,
			2594.069,
			2594.848,
			2595.236,
			2596.008,
			2596.779,
			2597.163,
			2597.93,
			2598.691,
			2599.399,
			2600.107,
			2600.462,
			2601.174,
			2601.889,
			2602.248,
			2602.968,
			2603.33,
			2604.097,
			2604.897,
			2606.102,
			2606.912,
			2608.144,
			2608.56,
			2608.99,
			2610.302,
			2611.19,
			2612.544,
			2613.466,
			2614.394,
			2614.862,
			2615.81,
			2616.289,
			2617.26,
			2618.748,
			2619.744,
			2620.757,
			2621.777,
			2622.818,
			2623.341,
			2624.388,
			2625.432,
			2625.952,
			2626.991,
			2627.51,
			2628.544,
			2629.592,
			2631.161,
			2632.193,
			2633.734,
			2634.261,
			2634.786,
			2636.341,
			2637.367,
			2638.901,
			2639.932,
			2641.45,
			2642.452,
			2642.951,
			2643.955,
			2644.978,
			2645.486,
			2646.498,
			2647.507,
			2649.043,
			2650.18,
			2651.889,
			2652.46,
			2653.032,
			2654.864,
			2656.158,
			2658.111,
			2659.413,
			2660.704,
			2661.352,
			2662.656,
			2663.313,
			2663.969,
			2665.802,
			2666.418,
			2667.037,
			2668.915,
			2670.089,
			2671.871,
			2673.078,
			2674.288,
			2674.884,
			2676.086,
			2676.693,
			2677.919,
			2679.153,
			2680.974,
			2682.188,
			2684.032,
			2684.643,
			2685.256,
			2687.123,
			2688.386,
			2690.323,
			2691.636,
			2693.635,
			2695.017,
			2695.718,
			2696.423,
			2698.562,
			2699.289,
			2700.771,
			2702.268,
			2703.782,
			2705.322,
			2706.1,
			2707.668,
			2709.251,
			2710.054,
			2711.67,
			2712.471,
			2714.109,
			2715.769,
			2718.287,
			2719.983,
			2722.528,
			2723.378,
			2724.231,
			2726.807,
			2728.54,
			2731.162,
			2732.924,
			2735.591,
			2737.384,
			2738.286,
			2740.098,
			2742.84,
			2744.685,
			2746.541,
			2749.349,
			2750.292,
			2751.238,
			2753.14,
			2755.054,
			2756.017,
			2757.951,
			2758.923,
			2760.876,
			2762.842,
			2765.813,
			2767.81,
			2770.827,
			2771.838,
			2772.853,
			2775.914,
			2777.968,
			2781.07,
			2783.153,
			2786.293,
			2788.387,
			2789.436,
			2791.542,
			2793.66,
			2794.723,
			2796.857,
			2799.002,
			2802.239,
			2803.322,
			2804.409,
			2807.681,
			2808.777,
			2809.875,
			2813.183,
			2815.4,
			2818.742,
			2820.983,
			2823.232,
			2826.623,
			2827.758,
			2828.896,
			2832.323,
			2833.47,
			2834.62,
			2838.083,
			2840.404,
			2842.735,
			2843.903,
			2846.248,
			2848.603,
			2849.783,
			2853.34,
			2855.723,
			2858.117,
			2861.725,
			2864.143,
			2867.788,
			2869.007,
			2870.229,
			2873.907,
			2876.37,
			2880.081,
			2882.567,
			2885.062,
			2886.313,
			2888.822,
			2890.08,
			2891.339,
			2895.133,
			2896.402,
			2898.947,
			2901.5,
			2904.061,
			2906.631,
			2907.919,
			2910.502,
			2913.093,
			2914.392,
			2916.994,
			2918.298,
			2920.91,
			2923.521,
			2927.444,
			2930.067,
			2934.015,
			2935.334,
			2936.655,
			2940.628,
			2943.286,
			2947.286,
			2949.961,
			2953.987,
			2956.681,
			2958.03,
			2960.733,
			2964.803,
			2967.526,
			2968.973
		],
		[
			0,
			0.007,
			0.067,
			0.13,
			0.193,
			0.221,
			0.255,
			0.315,
			0.331,
			0.379,
			0.442,
			0.503,
			0.547,
			0.626,
			0.658,
			0.689,
			0.749,
			0.813,
			0.872,
			0.935,
			0.997,
			1.06,
			1.092,
			1.122,
			1.184,
			1.2,
			1.246,
			1.308,
			1.369,
			1.417,
			1.433,
			1.493,
			1.526,
			1.554,
			1.619,
			1.681,
			1.743,
			1.804,
			1.865,
			1.929,
			1.959,
			1.99,
			2.051,
			2.114,
			2.177,
			2.24,
			2.285,
			2.301,
			2.363,
			2.392,
			2.426,
			2.488,
			2.502,
			2.549,
			2.61,
			2.673,
			2.719,
			2.797,
			2.826,
			2.859,
			2.922,
			2.982,
			3.05,
			3.105,
			3.153,
			3.232,
			3.261,
			3.294,
			3.355,
			3.369,
			3.417,
			3.479,
			3.54,
			3.588,
			3.602,
			3.666,
			3.696,
			3.727,
			3.788,
			3.803,
			3.85,
			3.914,
			3.975,
			4.02,
			4.1,
			4.128,
			4.161,
			4.221,
			4.284,
			4.346,
			4.41,
			4.456,
			4.47,
			4.533,
			4.562,
			4.593,
			4.657,
			4.671,
			4.72,
			4.78,
			4.842,
			4.906,
			4.967,
			4.997,
			5.029,
			5.092,
			5.112,
			5.152,
			5.215,
			5.277,
			5.322,
			5.402,
			5.432,
			5.464,
			5.54,
			5.587,
			5.648,
			5.711,
			5.774,
			5.836,
			5.866,
			5.896,
			5.96,
			5.975,
			6.021,
			6.084,
			6.144,
			6.192,
			6.208,
			6.269,
			6.301,
			6.331,
			6.409,
			6.455,
			6.518,
			6.58,
			6.625,
			6.703,
			6.733,
			6.765,
			6.844,
			6.888,
			6.952,
			7.013,
			7.06,
			7.075,
			7.136,
			7.171,
			7.199,
			7.277,
			7.321,
			7.385,
			7.446,
			7.509,
			7.571,
			7.603,
			7.633,
			7.694,
			7.758,
			7.82,
			7.882,
			7.943,
			8.005,
			8.037,
			8.067,
			8.128,
			8.145,
			8.192,
			8.254,
			8.316,
			8.361,
			8.378,
			8.439,
			8.471,
			8.5,
			8.564,
			8.58,
			8.627,
			8.688,
			8.748,
			8.812,
			8.874,
			8.906,
			8.934,
			8.996,
			9.059,
			9.122,
			9.185,
			9.247,
			9.309,
			9.339,
			9.37,
			9.431,
			9.446,
			9.494,
			9.557,
			9.617,
			9.665,
			9.68,
			9.742,
			9.771,
			9.802,
			9.865,
			9.881,
			9.928,
			9.989,
			10.053,
			10.112,
			10.176,
			10.205,
			10.239,
			10.298,
			10.362,
			10.422,
			10.485,
			10.531,
			10.549,
			10.61,
			10.64,
			10.672,
			10.748,
			10.797,
			10.859,
			10.921,
			10.967,
			10.983,
			11.045,
			11.076,
			11.105,
			11.167,
			11.183,
			11.231,
			11.295,
			11.355,
			11.401,
			11.478,
			11.51,
			11.541,
			11.601,
			11.663,
			11.725,
			11.789,
			11.851,
			11.912,
			11.942,
			11.974,
			12.036,
			12.053,
			12.097,
			12.161,
			12.221,
			12.268,
			12.284,
			12.346,
			12.376,
			12.409,
			12.471,
			12.532,
			12.595,
			12.655,
			12.703,
			12.781,
			12.811,
			12.842,
			12.905,
			12.92,
			12.967,
			13.029,
			13.092,
			13.138,
			13.151,
			13.213,
			13.245,
			13.275,
			13.339,
			13.36,
			13.401,
			13.463,
			13.525,
			13.572,
			13.649,
			13.68,
			13.71,
			13.772,
			13.834,
			13.897,
			13.959,
			14.022,
			14.084,
			14.114,
			14.145,
			14.208,
			14.221,
			14.267,
			14.33,
			14.392,
			14.439,
			14.456,
			14.518,
			14.546,
			14.579,
			14.639,
			14.655,
			14.701,
			14.77,
			14.827,
			14.871,
			14.952,
			14.981,
			15.014,
			15.075,
			15.137,
			15.199,
			15.26,
			15.308,
			15.321,
			15.385,
			15.418,
			15.448,
			15.509,
			15.524,
			15.571,
			15.632,
			15.694,
			15.741,
			15.758,
			15.818,
			15.849,
			15.882,
			15.942,
			15.958,
			16.005,
			16.066,
			16.128,
			16.176,
			16.251,
			16.283,
			16.314,
			16.378,
			16.439,
			16.503,
			16.562,
			16.626,
			16.686,
			16.719,
			16.749,
			16.81,
			16.827,
			16.872,
			16.936,
			16.997,
			17.042,
			17.059,
			17.121,
			17.153,
			17.182,
			17.245,
			17.305,
			17.37,
			17.43,
			17.481,
			17.494,
			17.555,
			17.585,
			17.618,
			17.679,
			17.742,
			17.805,
			17.866,
			17.927,
			17.989,
			18.02,
			18.051,
			18.112,
			18.129,
			18.176,
			18.236,
			18.299,
			18.362,
			18.422,
			18.453,
			18.484,
			18.547,
			18.61,
			18.671,
			18.734,
			18.781,
			18.796,
			18.858,
			18.888,
			18.919,
			18.981,
			18.997,
			19.042,
			19.106,
			19.168,
			19.23,
			19.293,
			19.322,
			19.353,
			19.417,
			19.432,
			19.479,
			19.546,
			19.601,
			19.662,
			19.726,
			19.757,
			19.788,
			19.85,
			19.913,
			19.972,
			20.036,
			20.096,
			20.16,
			20.19,
			20.223,
			20.283,
			20.299,
			20.346,
			20.409,
			20.47,
			20.516,
			20.533,
			20.594,
			20.625,
			20.656,
			20.718,
			20.734,
			20.779,
			20.842,
			20.904,
			20.95,
			21.029,
			21.059,
			21.089,
			21.168,
			21.213,
			21.276,
			21.336,
			21.385,
			21.4,
			21.461,
			21.494,
			21.524,
			21.585,
			21.605,
			21.649,
			21.71,
			21.772,
			21.833,
			21.896,
			21.927,
			21.959,
			22.02,
			22.083,
			22.145,
			22.206,
			22.269,
			22.331,
			22.362,
			22.393,
			22.455,
			22.47,
			22.517,
			22.577,
			22.641,
			22.686,
			22.703,
			22.763,
			22.796,
			22.827,
			22.889,
			22.903,
			22.949,
			23.012,
			23.073,
			23.135,
			23.197,
			23.229,
			23.259,
			23.321,
			23.385,
			23.448,
			23.509,
			23.556,
			23.57,
			23.633,
			23.667,
			23.694,
			23.757,
			23.771,
			23.819,
			23.88,
			23.943,
			23.99,
			24.004,
			24.067,
			24.097,
			24.129,
			24.205,
			24.253,
			24.314,
			24.377,
			24.421,
			24.499,
			24.532,
			24.561,
			24.625,
			24.687,
			24.749,
			24.81,
			24.873,
			24.934,
			24.964,
			24.996,
			25.06,
			25.074,
			25.121,
			25.182,
			25.244,
			25.308,
			25.367,
			25.398,
			25.432,
			25.493,
			25.508,
			25.555,
			25.616,
			25.679,
			25.731,
			25.803,
			25.832,
			25.866,
			25.926,
			25.988,
			26.049,
			26.112,
			26.16,
			26.175,
			26.237,
			26.267,
			26.297,
			26.36,
			26.376,
			26.421,
			26.485,
			26.547,
			26.594,
			26.671,
			26.7,
			26.732,
			26.793,
			26.81,
			26.855,
			26.92,
			26.98,
			27.043,
			27.105,
			27.134,
			27.167,
			27.243,
			27.292,
			27.353,
			27.414,
			27.476,
			27.54,
			27.57,
			27.602,
			27.663,
			27.679,
			27.725,
			27.787,
			27.849,
			27.895,
			27.972,
			28.003,
			28.034,
			28.097,
			28.16,
			28.221,
			28.284,
			28.345,
			28.406,
			28.438,
			28.47,
			28.532,
			28.546,
			28.594,
			28.654,
			28.717,
			28.779,
			28.841,
			28.872,
			28.903,
			28.964,
			28.981,
			29.027,
			29.089,
			29.152,
			29.214,
			29.276,
			29.307,
			29.336,
			29.4,
			29.462,
			29.524,
			29.586,
			29.63,
			29.648,
			29.71,
			29.741,
			29.772,
			29.833,
			29.854,
			29.894,
			29.961,
			30.018,
			30.064,
			30.08,
			30.142,
			30.176,
			30.206,
			30.267,
			30.283,
			30.329,
			30.393,
			30.454,
			30.515,
			30.577,
			30.609,
			30.639,
			30.702,
			30.765,
			30.826,
			30.886,
			30.933,
			30.949,
			31.011,
			31.042,
			31.073,
			31.136,
			31.15,
			31.197,
			31.26,
			31.321,
			31.367,
			31.383,
			31.444,
			31.475,
			31.508,
			31.586,
			31.63,
			31.694,
			31.755,
			31.818,
			31.879,
			31.916,
			31.942,
			32.004,
			32.067,
			32.127,
			32.189,
			32.236,
			32.253,
			32.314,
			32.344,
			32.376,
			32.438,
			32.454,
			32.498,
			32.562,
			32.622,
			32.671,
			32.749,
			32.778,
			32.811,
			32.872,
			32.934,
			32.996,
			33.059,
			33.105,
			33.12,
			33.18,
			33.214,
			33.244,
			33.305,
			33.321,
			33.367,
			33.429,
			33.491,
			33.553,
			33.617,
			33.646,
			33.677,
			33.739,
			33.756,
			33.803,
			33.863,
			33.926,
			33.988,
			34.051,
			34.081,
			34.113,
			34.174,
			34.236,
			34.3,
			34.36,
			34.408,
			34.423,
			34.484,
			34.516,
			34.545,
			34.609,
			34.625,
			34.67,
			34.733,
			34.794,
			34.842,
			34.855,
			34.918,
			34.949,
			34.979,
			35.042,
			35.057,
			35.103,
			35.165,
			35.228,
			35.275,
			35.352,
			35.383,
			35.414,
			35.475,
			35.538,
			35.601,
			35.662,
			35.724,
			35.786,
			35.821,
			35.849,
			35.925,
			35.971,
			36.038,
			36.095,
			36.143,
			36.158,
			36.22,
			36.252,
			36.283,
			36.359,
			36.408,
			36.47,
			36.532,
			36.578,
			36.655,
			36.685,
			36.716,
			36.779,
			36.84,
			36.902,
			36.964,
			37.012,
			37.026,
			37.089,
			37.119,
			37.152,
			37.213,
			37.229,
			37.274,
			37.336,
			37.399,
			37.461,
			37.522,
			37.554,
			37.585,
			37.645,
			37.709,
			37.771,
			37.833,
			37.88,
			37.894,
			37.955,
			37.986,
			38.018,
			38.08,
			38.102,
			38.143,
			38.204,
			38.268,
			38.312,
			38.33,
			38.391,
			38.422,
			38.452,
			38.514,
			38.53,
			38.577,
			38.64,
			38.7,
			38.746,
			38.825,
			38.854,
			38.887,
			38.963,
			39.009,
			39.072,
			39.135,
			39.18,
			39.196,
			39.259,
			39.288,
			39.322,
			39.383,
			39.397,
			39.444,
			39.508,
			39.569,
			39.63,
			39.693,
			39.725,
			39.754,
			39.817,
			39.834,
			39.879,
			39.942,
			40.003,
			40.05,
			40.127,
			40.162,
			40.19,
			40.25,
			40.313,
			40.376,
			40.437,
			40.483,
			40.499,
			40.562,
			40.592,
			40.622,
			40.685,
			40.701,
			40.747,
			40.811,
			40.871,
			40.917,
			40.933,
			40.996,
			41.027,
			41.057,
			41.135,
			41.182,
			41.243,
			41.305,
			41.367,
			41.428,
			41.461,
			41.489,
			41.554,
			41.616,
			41.678,
			41.739,
			41.785,
			41.801,
			41.864,
			41.894,
			41.926,
			41.987,
			42.003,
			42.048,
			42.112,
			42.175,
			42.236,
			42.297,
			42.33,
			42.359,
			42.422,
			42.483,
			42.547,
			42.608,
			42.669,
			42.731,
			42.763,
			42.793,
			42.855,
			42.872,
			42.917,
			42.979,
			43.042,
			43.089,
			43.103,
			43.166,
			43.198,
			43.227,
			43.306,
			43.352,
			43.413,
			43.476,
			43.522,
			43.6,
			43.63,
			43.662,
			43.723,
			43.785,
			43.849,
			43.911,
			43.972,
			44.032,
			44.064,
			44.096,
			44.158,
			44.174,
			44.221,
			44.288,
			44.344,
			44.389,
			44.406,
			44.466,
			44.5,
			44.529,
			44.592,
			44.606,
			44.654,
			44.716,
			44.777,
			44.839,
			44.903,
			44.933,
			44.962,
			45.042,
			45.089,
			45.151,
			45.212,
			45.259,
			45.275,
			45.335,
			45.368,
			45.399,
			45.476,
			45.522,
			45.585,
			45.646,
			45.692,
			45.709,
			45.77,
			45.8,
			45.831,
			45.895,
			45.955,
			46.023,
			46.079,
			46.142,
			46.205,
			46.236,
			46.265,
			46.348,
			46.389,
			46.452,
			46.513,
			46.561,
			46.577,
			46.639,
			46.67,
			46.701,
			46.761,
			46.777,
			46.824,
			46.886,
			46.948,
			47.011,
			47.072,
			47.103,
			47.136,
			47.196,
			47.259,
			47.321,
			47.382,
			47.429,
			47.445,
			47.505,
			47.537,
			47.567,
			47.629,
			47.644,
			47.693,
			47.755,
			47.818,
			47.879,
			47.942,
			47.972,
			48.003,
			48.064,
			48.08,
			48.127,
			48.188,
			48.251,
			48.297,
			48.375,
			48.41,
			48.436,
			48.514,
			48.561,
			48.623,
			48.684,
			48.746,
			48.809,
			48.84,
			48.87,
			48.932,
			48.949,
			48.995,
			49.056,
			49.12,
			49.166,
			49.181,
			49.244,
			49.275,
			49.304,
			49.367,
			49.383,
			49.429,
			49.491,
			49.554,
			49.6,
			49.676,
			49.709,
			49.739,
			49.8,
			49.864,
			49.925,
			49.985,
			50.032,
			50.049,
			50.111,
			50.143,
			50.172,
			50.236,
			50.249,
			50.296,
			50.359,
			50.422,
			50.471,
			50.543,
			50.577,
			50.608,
			50.669,
			50.731,
			50.794,
			50.856,
			50.9,
			50.979,
			51.008,
			51.042,
			51.102,
			51.165,
			51.229,
			51.287,
			51.335,
			51.351,
			51.414,
			51.443,
			51.476,
			51.538,
			51.551,
			51.6,
			51.661,
			51.723,
			51.769,
			51.847,
			51.877,
			51.909,
			51.988,
			52.032,
			52.094,
			52.157,
			52.203,
			52.22,
			52.28,
			52.311,
			52.342,
			52.405,
			52.419,
			52.466,
			52.533,
			52.592,
			52.638,
			52.652,
			52.715,
			52.746,
			52.778,
			52.839,
			52.855,
			52.901,
			52.963,
			53.026,
			53.072,
			53.148,
			53.18,
			53.212,
			53.272,
			53.334,
			53.398,
			53.46,
			53.506,
			53.522,
			53.582,
			53.617,
			53.647,
			53.709,
			53.723,
			53.769,
			53.831,
			53.893,
			53.956,
			54.018,
			54.05,
			54.08,
			54.142,
			54.157,
			54.203,
			54.266,
			54.328,
			54.388,
			54.452,
			54.482,
			54.513,
			54.576,
			54.596,
			54.638,
			54.7,
			54.761,
			54.825,
			54.885,
			54.917,
			54.946,
			55.009,
			55.025,
			55.071,
			55.135,
			55.194,
			55.259,
			55.32,
			55.35,
			55.383,
			55.459,
			55.505,
			55.571,
			55.63,
			55.676,
			55.752,
			55.785,
			55.816,
			55.877,
			55.938,
			56.003,
			56.063,
			56.112,
			56.125,
			56.188,
			56.22,
			56.248,
			56.312,
			56.328,
			56.374,
			56.439,
			56.497,
			56.545,
			56.621,
			56.685,
			56.747,
			56.809,
			56.87,
			56.932,
			56.979,
			56.995,
			57.056,
			57.087,
			57.119,
			57.179,
			57.195,
			57.241,
			57.305,
			57.366,
			57.412,
			57.429,
			57.491,
			57.521,
			57.551,
			57.614,
			57.63,
			57.676,
			57.737,
			57.799,
			57.846,
			57.923,
			57.955,
			57.985,
			58.049,
			58.111,
			58.172,
			58.233,
			58.282,
			58.295,
			58.359,
			58.387,
			58.419,
			58.498,
			58.544,
			58.604,
			58.667,
			58.718,
			58.73,
			58.792,
			58.822,
			58.855,
			58.915,
			58.933,
			58.978,
			59.038,
			59.103,
			59.147,
			59.227,
			59.259,
			59.289,
			59.351,
			59.414,
			59.475,
			59.535,
			59.583,
			59.598,
			59.661,
			59.692,
			59.721,
			59.785,
			59.799,
			59.846,
			59.909,
			59.97,
			60.018,
			60.033,
			60.094,
			60.126,
			60.157,
			60.219,
			60.233,
			60.281,
			60.343,
			60.405,
			60.452,
			60.53,
			60.56,
			60.589,
			60.654,
			60.714,
			60.779,
			60.839,
			60.886,
			60.899,
			60.964,
			60.993,
			61.025,
			61.086,
			61.103,
			61.15,
			61.21,
			61.273,
			61.317,
			61.333,
			61.396,
			61.426,
			61.46,
			61.535,
			61.583,
			61.645,
			61.707,
			61.754,
			61.83,
			61.86,
			61.893,
			61.969,
			62.018,
			62.081,
			62.142,
			62.188,
			62.203,
			62.264,
			62.297,
			62.326,
			62.388,
			62.405,
			62.451,
			62.513,
			62.575,
			62.638,
			62.699,
			62.731,
			62.761,
			62.823,
			62.886,
			62.947,
			63.009,
			63.07,
			63.133,
			63.165,
			63.194,
			63.255,
			63.273,
			63.318,
			63.381,
			63.443,
			63.488,
			63.505,
			63.566,
			63.599,
			63.63,
			63.692,
			63.707,
			63.753,
			63.814,
			63.877,
			63.925,
			64,
			64.033,
			64.063,
			64.124,
			64.186,
			64.249,
			64.312,
			64.358,
			64.375,
			64.436,
			64.467,
			64.497,
			64.559,
			64.575,
			64.62,
			64.682,
			64.746,
			64.793,
			64.807,
			64.869,
			64.904,
			64.93,
			64.993,
			65.01,
			65.056,
			65.117,
			65.181,
			65.227,
			65.303,
			65.333,
			65.366,
			65.429,
			65.489,
			65.551,
			65.612,
			65.661,
			65.676,
			65.738,
			65.768,
			65.799,
			65.877,
			65.925,
			65.984,
			66.049,
			66.093,
			66.109,
			66.173,
			66.202,
			66.232,
			66.296,
			66.358,
			66.421,
			66.481,
			66.528,
			66.544,
			66.607,
			66.637,
			66.668,
			66.745,
			66.793,
			66.854,
			66.916,
			66.965,
			66.979,
			67.038,
			67.072,
			67.1,
			67.163,
			67.179,
			67.226,
			67.287,
			67.35,
			67.397,
			67.475,
			67.503,
			67.535,
			67.613,
			67.659,
			67.721,
			67.784,
			67.846,
			67.908,
			67.939,
			67.971,
			68.032,
			68.048,
			68.095,
			68.157,
			68.219,
			68.264,
			68.279,
			68.342,
			68.372,
			68.405,
			68.466,
			68.482,
			68.529,
			68.591,
			68.652,
			68.697,
			68.775,
			68.808,
			68.839,
			68.915,
			68.962,
			69.029,
			69.086,
			69.133,
			69.149,
			69.21,
			69.242,
			69.273,
			69.349,
			69.396,
			69.459,
			69.519,
			69.567,
			69.583,
			69.643,
			69.675,
			69.705,
			69.767,
			69.783,
			69.831,
			69.893,
			69.954,
			70.015,
			70.079,
			70.109,
			70.141,
			70.216,
			70.265,
			70.326,
			70.388,
			70.451,
			70.512,
			70.543,
			70.576,
			70.636,
			70.652,
			70.699,
			70.765,
			70.821,
			70.886,
			70.948,
			70.977,
			71.008,
			71.07,
			71.133,
			71.196,
			71.256,
			71.303,
			71.319,
			71.38,
			71.413,
			71.442,
			71.505,
			71.521,
			71.565,
			71.629,
			71.689,
			71.752,
			71.815,
			71.846,
			71.877,
			71.939,
			71.955,
			72.002,
			72.063,
			72.126,
			72.187,
			72.25,
			72.278,
			72.313,
			72.372,
			72.435,
			72.496,
			72.558,
			72.621,
			72.683,
			72.714,
			72.743,
			72.806,
			72.822,
			72.87,
			72.932,
			72.991,
			73.038,
			73.055,
			73.117,
			73.151,
			73.178,
			73.242,
			73.254,
			73.303,
			73.363,
			73.425,
			73.474,
			73.551,
			73.583,
			73.614,
			73.676,
			73.738,
			73.801,
			73.861,
			73.924,
			73.986,
			74.015,
			74.047,
			74.125,
			74.172,
			74.234,
			74.294,
			74.342,
			74.358,
			74.419,
			74.451,
			74.482,
			74.542,
			74.559,
			74.605,
			74.667,
			74.73,
			74.775,
			74.853,
			74.885,
			74.916,
			74.977,
			75.038,
			75.102,
			75.164,
			75.226,
			75.288,
			75.317,
			75.35,
			75.41,
			75.427,
			75.473,
			75.534,
			75.598,
			75.643,
			75.659,
			75.721,
			75.751,
			75.782,
			75.846,
			75.908,
			75.97,
			76.031,
			76.079,
			76.155,
			76.184,
			76.219,
			76.278,
			76.296,
			76.342,
			76.404,
			76.466,
			76.512,
			76.528,
			76.59,
			76.62,
			76.652,
			76.728,
			76.776,
			76.837,
			76.899,
			76.962,
			77.024,
			77.055,
			77.084,
			77.147,
			77.211,
			77.274,
			77.334,
			77.379,
			77.396,
			77.457,
			77.488,
			77.518,
			77.582,
			77.597,
			77.645,
			77.706,
			77.766,
			77.814,
			77.831,
			77.892,
			77.923,
			77.954,
			78.014,
			78.031,
			78.076,
			78.139,
			78.201,
			78.247,
			78.326,
			78.357,
			78.388,
			78.464,
			78.512,
			78.575,
			78.636,
			78.698,
			78.76,
			78.79,
			78.823,
			78.9,
			78.945,
			78.984
		]
	],
	'4038_4039_7982': [
		[
			124.553
		],
		[
			0
		],
		[
			0,
			0.96,
			2.369,
			5.193,
			6.609,
			9.48,
			12.359,
			16.699,
			19.588,
			23.931,
			25.38,
			26.832,
			31.199,
			34.124,
			38.524,
			41.467,
			44.41,
			45.883,
			48.832,
			50.309,
			53.265,
			57.71,
			59.194,
			63.657,
			66.638,
			69.626,
			71.122,
			74.117,
			77.118,
			78.621,
			83.137,
			86.155,
			89.179,
			93.725,
			96.764,
			101.333,
			102.858,
			104.385,
			108.974,
			112.04,
			116.648,
			119.725,
			122.808,
			124.352,
			127.442,
			128.99,
			132.088,
			135.191,
			136.745,
			139.856,
			142.971,
			147.654,
			149.217,
			150.782,
			155.482,
			157.051,
			158.621,
			161.765,
			163.338,
			166.48,
			169.61,
			174.259,
			177.317,
			181.833,
			183.319,
			184.795,
			189.163,
			192.026,
			196.246,
			199.011,
			201.739,
			203.089,
			205.759,
			207.079,
			209.692,
			212.268,
			213.542,
			216.086,
			218.573,
			222.247,
			223.458,
			224.664,
			228.258,
			229.449,
			230.639,
			234.198,
			236.551,
			238.894,
			242.376,
			244.688,
			248.144,
			249.296,
			250.447,
			253.906,
			255.065,
			256.225,
			259.725,
			262.08,
			264.452,
			265.652,
			268.052,
			270.496,
			271.726,
			274.201,
			275.472,
			278.012,
			280.61,
			284.549,
			287.197,
			291.116,
			292.409,
			293.7,
			297.452,
			299.92,
			303.565,
			305.97,
			308.366,
			309.538,
			311.913,
			313.101,
			315.492,
			319.056,
			321.425,
			323.8,
			326.166,
			328.535,
			329.719,
			332.08,
			334.45,
			335.634,
			338.009,
			339.2,
			341.593,
			343.995,
			347.617,
			350.041,
			353.684,
			354.902,
			356.119,
			359.781,
			362.232,
			365.904,
			368.377,
			370.881,
			372.127,
			374.625,
			375.878,
			378.39,
			380.889,
			382.152,
			384.705,
			387.246,
			391.053,
			392.335,
			393.618,
			396.194,
			398.78,
			400.076,
			403.979,
			406.583,
			409.194,
			413.125,
			415.755,
			419.715,
			421.039,
			422.365,
			426.355,
			429.025,
			433.046,
			435.734,
			438.451,
			439.797,
			442.499,
			443.849,
			446.556,
			449.269,
			450.63,
			453.369,
			456.12,
			460.278,
			461.674,
			463.07,
			467.254,
			468.647,
			470.04,
			474.191,
			476.946,
			481.068,
			483.81,
			486.554,
			490.671,
			492.044,
			493.419,
			497.546,
			498.924,
			500.301,
			504.451,
			507.232,
			511.426,
			514.244,
			515.656,
			518.485,
			521.319,
			522.737,
			525.576,
			528.409,
			532.65,
			535.47,
			539.679,
			541.082,
			542.477,
			546.663,
			549.446,
			553.615,
			556.392,
			560.555,
			563.329,
			564.717,
			567.49,
			571.649,
			573.036,
			577.204,
			579.988,
			582.779,
			584.177,
			586.979,
			589.79,
			591.198,
			594.022,
			595.436,
			598.271,
			601.113,
			605.39,
			608.249,
			612.526,
			613.963,
			615.401,
			619.722,
			622.61,
			626.952,
			629.851,
			632.755,
			634.208,
			637.118,
			638.573,
			641.479,
			644.37,
			645.808,
			648.662,
			651.485,
			655.649,
			658.379,
			661.07,
			663.724,
			665.037,
			667.635,
			668.919,
			671.46,
			673.964,
			677.647,
			680.053,
			683.593,
			684.755,
			685.908,
			689.311,
			691.531,
			694.79,
			696.915,
			699.006,
			700.038,
			702.073,
			703.079,
			705.062,
			707.009,
			707.969,
			709.864,
			711.722,
			714.447,
			716.221,
			718.817,
			719.663,
			720.501,
			722.971,
			724.562,
			726.879,
			728.378,
			729.84,
			731.968,
			732.662,
			733.345,
			735.357,
			736.017,
			736.673,
			738.61,
			739.874,
			741.746,
			742.986,
			744.22,
			744.835,
			746.062,
			746.674,
			747.906,
			749.164,
			751.047,
			752.303,
			754.269,
			754.93,
			755.592,
			757.581,
			758.969,
			761.06,
			762.461,
			764.65,
			766.121,
			766.859,
			768.366,
			769.909,
			770.681,
			771.455,
			773.843,
			775.484,
			777.127,
			777.956,
			779.655,
			781.351,
			782.199,
			783.807,
			784.587,
			786.144,
			787.703,
			789.771,
			791.138,
			793.183,
			793.849,
			794.514,
			796.512,
			797.849,
			799.832,
			801.158,
			802.491,
			803.158,
			804.492,
			805.161,
			806.504,
			807.855,
			808.554,
			809.959,
			811.374,
			813.533,
			815.016,
			816.511,
			818.018,
			818.782,
			820.317,
			821.09,
			822.647,
			824.216,
			826.596,
			828.202,
			830.629,
			831.445,
			832.265,
			834.738,
			836.4,
			838.894,
			840.564,
			843.078,
			844.786,
			845.643,
			847.357,
			849.911,
			851.587,
			853.229,
			855.625,
			857.178,
			859.439,
			860.175,
			860.902,
			863.028,
			864.406,
			865.75,
			867.703,
			868.971,
			870.809,
			871.403,
			871.99,
			873.717,
			874.295,
			874.861,
			876.534,
			877.634,
			878.733,
			879.283,
			880.378,
			881.469,
			882.013,
			883.1,
			883.645,
			884.734,
			885.82,
			887.446,
			888.535,
			890.209,
			890.765,
			891.321,
			892.988,
			894.16,
			895.967,
			897.17,
			898.373,
			898.99,
			900.223,
			900.838,
			902.066,
			903.292,
			903.893,
			904.488,
			906.262,
			907.44,
			908.627,
			909.244,
			910.473,
			911.696,
			912.308,
			913.533,
			914.169,
			915.435,
			916.694,
			918.583,
			919.866,
			921.774,
			922.408,
			923.041,
			925.007,
			926.324,
			928.296,
			929.578,
			930.847,
			931.481,
			932.748,
			933.384,
			934.589,
			935.782,
			936.378,
			937.573,
			938.766,
			940.493,
			941.647,
			942.806,
			943.957,
			944.518,
			945.641,
			946.205,
			947.338,
			948.48,
			950.158,
			951.284,
			952.991,
			953.566,
			954.149,
			955.913,
			956.507,
			957.104,
			958.925,
			960.189,
			961.468,
			962.113,
			963.415,
			964.079,
			965.426,
			966.79,
			967.477,
			968.865,
			970.277,
			972.424,
			973.148,
			973.876,
			976.085,
			976.83,
			977.578,
			979.846,
			981.379,
			982.913,
			985.264,
			986.85,
			989.255,
			990.063,
			990.875,
			993.33,
			994.983,
			997.482,
			999.147,
			1000.814,
			1001.653,
			1003.341,
			1005.036,
			1005.888,
			1007.601,
			1008.462,
			1010.191,
			1011.931,
			1014.565,
			1016.339,
			1019.023,
			1019.923,
			1020.828,
			1023.558,
			1025.397,
			1028.178,
			1030.049,
			1031.934,
			1034.785,
			1035.744,
			1036.705,
			1039.626,
			1040.605,
			1041.588,
			1044.555,
			1046.557,
			1048.571,
			1049.583,
			1051.622,
			1053.671,
			1054.7,
			1057.804,
			1059.886,
			1061.971,
			1065.095,
			1067.169,
			1070.294,
			1071.335,
			1072.379,
			1075.522,
			1077.625,
			1080.798,
			1082.925,
			1086.136,
			1088.291,
			1089.371,
			1091.545,
			1093.726,
			1094.818,
			1097.016,
			1099.217,
			1101.431,
			1104.766,
			1107.02,
			1109.284,
			1110.422,
			1112.731,
			1113.888,
			1116.216,
			1118.567,
			1122.129,
			1124.527,
			1128.15,
			1129.364,
			1130.578,
			1134.213,
			1136.632,
			1140.254,
			1142.644,
			1145.04,
			1146.225,
			1148.586,
			1149.77,
			1152.127,
			1154.485,
			1155.668,
			1158.048,
			1160.437,
			1164.06,
			1165.272,
			1166.489,
			1170.159,
			1171.386,
			1172.615,
			1175.079,
			1176.313,
			1178.786,
			1182.508,
			1184.999,
			1187.497,
			1191.236,
			1192.494,
			1193.755,
			1197.551,
			1200.095,
			1203.931,
			1206.5,
			1209.084,
			1210.379,
			1212.977,
			1214.28,
			1216.892,
			1219.504,
			1220.812,
			1223.432,
			1226.059,
			1230.009,
			1232.649,
			1236.617,
			1237.94,
			1239.266,
			1243.252,
			1245.918,
			1249.93,
			1252.614,
			1255.306,
			1256.655,
			1259.36,
			1260.716,
			1262.073,
			1266.157,
			1267.523,
			1268.891,
			1273.004,
			1275.755,
			1278.513,
			1279.895,
			1282.663,
			1285.439,
			1286.83,
			1289.617,
			1291.013,
			1293.81,
			1296.613,
			1300.831,
			1303.652,
			1307.896,
			1309.314,
			1310.734,
			1315.002,
			1317.857,
			1322.15,
			1325.021,
			1329.34,
			1332.227,
			1333.673,
			1336.57,
			1339.474,
			1340.929,
			1343.843,
			1346.763,
			1349.69,
			1352.623,
			1354.092,
			1357.035,
			1359.985,
			1361.462,
			1364.421,
			1365.903,
			1368.873,
			1371.851,
			1376.331,
			1379.325,
			1383.828,
			1385.333,
			1386.838,
			1391.362,
			1394.384,
			1398.926,
			1401.961,
			1405.001,
			1406.523,
			1409.572,
			1411.098,
			1414.155,
			1418.749,
			1421.821,
			1424.898,
			1429.525,
			1432.617,
			1437.266,
			1438.818,
			1440.371,
			1443.481,
			1445.038,
			1448.157,
			1451.28,
			1455.974,
			1459.111,
			1463.823,
			1465.396,
			1466.971,
			1471.702,
			1474.862,
			1479.611,
			1482.784,
			1485.962,
			1487.553,
			1490.739,
			1492.334,
			1495.525,
			1500.314,
			1503.512,
			1506.715,
			1511.527,
			1513.133,
			1514.74,
			1519.567,
			1521.178,
			1522.79,
			1527.632,
			1530.865,
			1535.723,
			1538.966,
			1542.214,
			1543.839,
			1547.094,
			1548.722,
			1550.352,
			1556.881,
			1558.515,
			1563.426,
			1566.706,
			1569.99,
			1571.633,
			1574.922,
			1578.217,
			1579.865,
			1584.818,
			1588.125,
			1591.436,
			1596.411,
			1599.732,
			1604.722,
			1606.388,
			1608.055,
			1613.061,
			1616.404,
			1621.425,
			1624.779,
			1628.137,
			1629.817,
			1633.181,
			1634.864,
			1638.234,
			1641.609,
			1643.297,
			1644.987,
			1650.059,
			1653.444,
			1656.833,
			1658.529,
			1661.922,
			1665.307,
			1666.993,
			1670.347,
			1672.012,
			1675.315,
			1678.579,
			1683.402,
			1686.57,
			1691.251,
			1692.792,
			1694.325,
			1698.868,
			1701.853,
			1706.264,
			1709.158,
			1712.013,
			1713.427,
			1716.227,
			1717.612,
			1720.358,
			1723.067,
			1724.408,
			1727.062,
			1729.678,
			1733.532,
			1734.798,
			1736.055,
			1739.769,
			1740.987,
			1742.196,
			1744.589,
			1745.771,
			1748.109,
			1751.544,
			1753.784,
			1755.978,
			1759.198,
			1760.242,
			1761.276,
			1764.34,
			1766.301,
			1769.175,
			1771.047,
			1772.885,
			1773.792,
			1775.594,
			1776.486,
			1778.247,
			1779.985,
			1780.851,
			1782.557,
			1784.234,
			1786.708,
			1788.328,
			1790.719,
			1791.506,
			1792.289,
			1794.624,
			1796.174,
			1798.497,
			1800.052,
			1801.612,
			1802.395,
			1803.963,
			1804.75,
			1805.539,
			1808.7,
			1809.492,
			1811.898,
			1813.505,
			1815.936,
			1817.571,
			1819.205,
			1820.024,
			1822.491,
			1824.135,
			1825.78,
			1828.246,
			1829.889,
			1832.354,
			1833.175,
			1833.996,
			1836.466,
			1838.113,
			1840.593,
			1842.26,
			1844.765,
			1846.465,
			1847.318,
			1849.025,
			1851.611,
			1853.345,
			1855.081,
			1856.804,
			1858.525,
			1859.386,
			1861.106,
			1862.823,
			1863.68,
			1865.4,
			1866.285,
			1868.05,
			1869.812,
			1872.559,
			1874.4,
			1877.191,
			1878.124,
			1879.056,
			1881.85,
			1883.707,
			1886.487,
			1888.333,
			1890.177,
			1891.098,
			1892.936,
			1893.854,
			1895.686,
			1898.406,
			1900.218,
			1901.973,
			1904.592,
			1905.466,
			1906.308,
			1908.836,
			1909.681,
			1910.529,
			1912.206,
			1913.048,
			1914.74,
			1916.442,
			1919.02,
			1920.754,
			1923.378,
			1924.259,
			1925.143,
			1927.816,
			1929.599,
			1932.321,
			1934.153,
			1936.924,
			1938.781,
			1940.634,
			1941.555,
			1943.38,
			1944.281,
			1946.057,
			1947.794,
			1950.333,
			1951.16,
			1951.98,
			1954.384,
			1955.167,
			1955.941,
			1958.225,
			1959.712,
			1961.93,
			1963.397,
			1964.865,
			1967.091,
			1967.841,
			1968.592,
			1970.856,
			1971.618,
			1972.385,
			1974.693,
			1976.236,
			1977.798,
			1978.577,
			1980.133,
			1981.689,
			1982.472,
			1984.033,
			1984.811,
			1986.363,
			1987.966,
			1990.366,
			1991.996,
			1994.524,
			1995.365,
			1996.206,
			1998.828,
			2000.574,
			2003.255,
			2005.057,
			2007.78,
			2009.608,
			2010.518,
			2012.314,
			2014.077,
			2014.954,
			2015.83,
			2018.314,
			2020.727,
			2022.277,
			2023.757,
			2025.227,
			2025.96,
			2027.427,
			2028.162,
			2029.628,
			2031.092,
			2033.356,
			2034.869,
			2037.134,
			2037.899,
			2038.663,
			2040.942,
			2042.461,
			2044.731,
			2046.239,
			2047.74,
			2048.487,
			2049.978,
			2050.722,
			2052.209,
			2054.409,
			2055.875,
			2057.34,
			2059.512,
			2060.237,
			2060.962,
			2062.413,
			2063.856,
			2064.576,
			2066.017,
			2066.739,
			2068.189,
			2069.64,
			2071.815,
			2073.285,
			2075.494,
			2076.23,
			2076.967,
			2079.22,
			2080.726,
			2083.003,
			2084.551,
			2086.101,
			2086.878,
			2088.458,
			2089.256,
			2090.856,
			2093.276,
			2094.907,
			2096.545,
			2099.015,
			2100.669,
			2103.165,
			2103.992,
			2104.821,
			2107.323,
			2108.975,
			2110.623,
			2113.111,
			2114.757,
			2117.238,
			2118.067,
			2118.891,
			2121.37,
			2122.199,
			2123.031,
			2125.512,
			2127.172,
			2128.826,
			2129.648,
			2131.294,
			2132.944,
			2133.764,
			2135.402,
			2136.223,
			2137.868,
			2139.504,
			2141.966,
			2143.618,
			2146.103,
			2146.938,
			2147.774,
			2150.271,
			2151.938,
			2154.463,
			2156.159,
			2158.726,
			2160.448,
			2161.314,
			2163.056,
			2164.809,
			2165.69,
			2166.574,
			2169.248,
			2171.05,
			2172.865,
			2173.777,
			2175.614,
			2177.463,
			2178.393,
			2180.269,
			2181.212,
			2183.107,
			2185.025,
			2187.931,
			2189.891,
			2192.843,
			2193.839,
			2194.841,
			2197.865,
			2199.896,
			2202.967,
			2205.029,
			2207.102,
			2208.143,
			2210.233,
			2211.281,
			2213.382,
			2215.481,
			2216.533,
			2218.642,
			2220.762,
			2223.962,
			2226.108,
			2229.344,
			2230.428,
			2231.515,
			2233.695,
			2234.789,
			2236.985,
			2239.19,
			2242.517,
			2244.747,
			2248.109,
			2249.235,
			2250.362,
			2253.755,
			2256.028,
			2259.453,
			2261.747,
			2264.051,
			2265.206,
			2267.518,
			2268.677,
			2271,
			2273.332,
			2274.5,
			2276.845,
			2279.198,
			2282.745,
			2283.931,
			2285.12,
			2288.703,
			2289.902,
			2291.104,
			2294.732,
			2297.164,
			2300.833,
			2303.293,
			2305.762,
			2309.488,
			2310.734,
			2311.983,
			2315.766,
			2317.025,
			2318.288,
			2322.09,
			2324.639,
			2327.198,
			2328.484,
			2331.062,
			2333.65,
			2334.926,
			2337.527,
			2338.832,
			2341.47,
			2344.091,
			2348.031,
			2350.653,
			2354.594,
			2355.91,
			2357.206,
			2361.165,
			2363.813,
			2367.824,
			2370.503,
			2373.167,
			2374.521,
			2377.233,
			2378.594,
			2381.334,
			2384.084,
			2385.466,
			2386.849,
			2391.021,
			2393.815,
			2396.618,
			2398.021,
			2400.827,
			2403.662,
			2405.063,
			2407.872,
			2409.276,
			2412.082,
			2414.87,
			2419.102,
			2421.968,
			2426.256,
			2427.691,
			2429.128,
			2433.439,
			2436.368,
			2440.733,
			2443.645,
			2446.558,
			2448.016,
			2450.936,
			2452.397,
			2455.323,
			2458.252,
			2459.718,
			2462.657,
			2465.604,
			2470.041,
			2471.524,
			2472.984,
			2477.473,
			2478.964,
			2480.457,
			2483.418,
			2484.914,
			2487.895,
			2490.876,
			2495.37,
			2498.344,
			2502.774,
			2504.256,
			2505.739,
			2510.175,
			2513.133,
			2517.576,
			2520.544,
			2523.517,
			2525.006,
			2527.988,
			2530.976,
			2532.471,
			2535.467,
			2536.967,
			2539.97,
			2542.98,
			2547.504,
			2549.016,
			2550.529,
			2555.078,
			2556.597,
			2558.118,
			2562.69,
			2565.746,
			2568.808,
			2573.413,
			2576.49,
			2581.116,
			2582.66,
			2584.207,
			2588.855,
			2590.406,
			2591.96,
			2596.627,
			2599.746,
			2602.871,
			2604.435,
			2607.569,
			2610.708,
			2612.279,
			2615.426,
			2617.003,
			2620.16,
			2623.323,
			2628.079,
			2631.256,
			2636.03,
			2637.624,
			2639.219,
			2644.007,
			2647.198,
			2651.992,
			2655.191,
			2658.393,
			2659.996,
			2663.204,
			2664.809,
			2668.021,
			2671.238,
			2672.847,
			2674.458,
			2679.295,
			2682.526,
			2685.76,
			2687.378,
			2690.618,
			2693.862,
			2695.486,
			2700.362,
			2703.617,
			2706.875,
			2711.769,
			2715.035,
			2719.941,
			2721.578,
			2723.217,
			2728.138,
			2731.426,
			2736.364,
			2739.66,
			2742.961,
			2744.613,
			2747.921,
			2749.576,
			2752.888,
			2756.206,
			2757.865,
			2761.189,
			2764.517,
			2769.516,
			2771.185,
			2772.854,
			2777.869,
			2779.543,
			2781.218,
			2784.57,
			2786.248,
			2789.605,
			2794.645,
			2797.995,
			2801.326,
			2806.266,
			2807.894,
			2809.512,
			2814.305,
			2817.453,
			2822.104,
			2825.155,
			2828.17,
			2829.664,
			2832.623,
			2834.088,
			2836.989,
			2841.266,
			2844.068,
			2846.833,
			2850.91,
			2853.58,
			2857.517,
			2858.811,
			2860.096,
			2863.897,
			2866.385,
			2870.048,
			2872.447,
			2874.812,
			2878.317,
			2879.461,
			2880.597,
			2883.96,
			2886.167,
			2889.444,
			2891.609,
			2893.768,
			2894.847,
			2897.002,
			2898.081,
			2900.245,
			2903.509,
			2905.709,
			2907.925,
			2911.284,
			2913.545,
			2916.96,
			2918.103,
			2919.246,
			2922.667,
			2924.947,
			2928.333,
			2930.588,
			2932.816,
			2933.939,
			2936.223,
			2937.368,
			2939.657,
			2941.987,
			2943.162,
			2944.337,
			2947.877,
			2950.242,
			2952.614,
			2953.801,
			2956.169,
			2958.509,
			2959.68,
			2962.013,
			2963.174,
			2965.497,
			2967.812,
			2971.284,
			2973.605,
			2977.089,
			2978.251,
			2979.413,
			2982.898,
			2985.22,
			2988.695,
			2991.009,
			2993.309,
			2994.457,
			2996.751,
			2997.886,
			3000.154,
			3002.416,
			3003.536,
			3005.773,
			3008,
			3011.32,
			3013.527,
			3015.729,
			3017.927,
			3019.024,
			3022.31,
			3024.495,
			3026.676,
			3029.937,
			3032.108,
			3035.366,
			3036.454,
			3037.543,
			3040.827,
			3043.029,
			3046.337,
			3048.565,
			3051.954,
			3054.213,
			3055.348,
			3057.625,
			3059.914,
			3061.062,
			3063.348,
			3065.662,
			3069.149,
			3071.484,
			3075.005,
			3076.183,
			3077.364,
			3080.917,
			3083.295,
			3085.682,
			3089.28,
			3091.693,
			3095.332,
			3096.552,
			3097.774,
			3101.453,
			3102.685,
			3103.917,
			3107.629,
			3110.113,
			3112.606,
			3113.857,
			3116.364,
			3118.88,
			3120.141,
			3122.671,
			3123.939,
			3126.481,
			3129.034,
			3132.879,
			3135.453,
			3139.327,
			3140.623,
			3141.92,
			3145.821,
			3148.421,
			3152.322,
			3154.927,
			3157.537,
			3158.845,
			3161.466,
			3162.779,
			3165.409,
			3168.047,
			3169.369,
			3170.692,
			3174.673,
			3177.335,
			3181.341,
			3184.02,
			3186.707,
			3188.052,
			3190.751,
			3192.103,
			3194.813,
			3198.891,
			3201.618,
			3204.353,
			3208.469,
			3209.845,
			3211.224,
			3215.369,
			3218.143,
			3222.319,
			3225.112,
			3229.321,
			3232.137,
			3233.548,
			3236.375,
			3240.629,
			3243.473,
			3246.324,
			3250.612,
			3252.044,
			3253.478,
			3257.789,
			3259.228,
			3260.669,
			3263.556,
			3265.001,
			3267.897,
			3272.253,
			3275.164,
			3278.082,
			3282.469,
			3283.935,
			3285.402,
			3289.813,
			3292.762,
			3297.196,
			3300.16,
			3303.13,
			3304.617,
			3307.596,
			3309.088,
			3312.076,
			3316.57,
			3319.573,
			3322.583,
			3327.108,
			3328.619,
			3330.132,
			3334.68,
			3336.199,
			3337.719,
			3342.289,
			3345.344,
			3349.936,
			3353.005,
			3356.08,
			3360.703,
			3362.247,
			3363.792,
			3368.435,
			3369.986,
			3371.538,
			3376.203,
			3379.319,
			3384.004,
			3387.135,
			3390.271,
			3391.841,
			3396.558,
			3399.709,
			3402.865,
			3407.608,
			3410.775,
			3415.558,
			3417.146,
			3418.732,
			3423.498,
			3426.672,
			3431.43,
			3434.601,
			3437.769,
			3439.354,
			3442.527,
			3444.116,
			3445.709,
			3450.494,
			3452.093,
			3453.664,
			3458.465,
			3461.669,
			3464.877,
			3466.483,
			3469.722,
			3472.942,
			3474.548,
			3477.764,
			3479.367,
			3482.582,
			3485.763,
			3490.581,
			3493.768,
			3498.493,
			3500.053,
			3501.601,
			3506.179,
			3509.176,
			3513.586,
			3516.469,
			3519.298,
			3520.698,
			3523.433,
			3524.783,
			3527.441,
			3531.326,
			3533.851,
			3536.335,
			3540,
			3541.196,
			3542.384,
			3544.733,
			3547.052,
			3548.203,
			3550.466,
			3551.594,
			3553.856,
			3556.074,
			3559.374,
			3561.557,
			3564.821,
			3565.908,
			3566.99,
			3570.269,
			3572.466,
			3575.778,
			3577.989,
			3580.218,
			3581.335,
			3583.573,
			3584.686,
			3586.915,
			3589.147,
			3590.255,
			3592.479,
			3594.692,
			3597.978,
			3599.076,
			3600.167,
			3603.449,
			3604.544,
			3605.631,
			3608.906,
			3611.094,
			3613.289,
			3616.592,
			3618.802,
			3622.125,
			3623.235,
			3624.348,
			3627.692,
			3628.811,
			3629.931,
			3633.291,
			3635.541,
			3638.926,
			3641.195,
			3642.333,
			3644.617,
			3646.894,
			3648.066,
			3650.379,
			3652.69,
			3656.206,
			3658.567,
			3662.128,
			3663.321,
			3664.517,
			3668.115,
			3670.517,
			3674.104,
			3676.487,
			3678.82,
			3679.974,
			3682.26,
			3683.378,
			3684.509,
			3687.88,
			3689.004,
			3690.127,
			3693.486,
			3695.741,
			3697.97,
			3699.087,
			3701.316,
			3703.539,
			3704.649,
			3707.97,
			3710.177,
			3712.377,
			3715.659,
			3717.838,
			3721.101,
			3722.186,
			3723.269,
			3726.517,
			3728.678,
			3731.931,
			3734.1,
			3736.271,
			3737.356,
			3739.527,
			3740.615,
			3742.792,
			3744.967,
			3746.053,
			3748.224,
			3750.395,
			3752.574,
			3754.752,
			3755.845,
			3758.036,
			3760.226,
			3761.325,
			3763.522,
			3764.621,
			3766.83,
			3769.044,
			3772.374,
			3774.601,
			3777.954,
			3779.075,
			3780.196,
			3783.568,
			3785.821,
			3789.174,
			3791.417,
			3794.755,
			3796.985,
			3798.083,
			3800.284,
			3803.539,
			3805.701,
			3807.849,
			3811.035,
			3812.088,
			3813.139,
			3816.25,
			3817.272,
			3818.296,
			3821.312,
			3823.308,
			3825.306,
			3828.251,
			3830.226,
			3833.163,
			3834.14,
			3835.115,
			3838.025,
			3838.977,
			3839.929,
			3842.784,
			3844.641,
			3847.424,
			3849.267,
			3851.103,
			3852.02,
			3853.856,
			3854.773,
			3856.608,
			3858.444,
			3861.207,
			3863.051,
			3865.868,
			3866.81,
			3867.752,
			3870.651,
			3872.598,
			3875.571,
			3877.566,
			3879.594,
			3882.678,
			3883.708,
			3885.804,
			3888.948,
			3890.032,
			3893.292,
			3895.482,
			3897.682,
			3898.783,
			3900.958,
			3903.127,
			3904.212,
			3906.365,
			3907.441,
			3909.593,
			3911.667,
			3914.776,
			3916.806,
			3919.849,
			3920.86,
			3921.868,
			3924.896,
			3926.909,
			3929.93,
			3931.945,
			3933.96,
			3934.97,
			3936.983,
			3937.988,
			3940.001,
			3942.013,
			3943.017,
			3944.023,
			3947.047,
			3950.073,
			3952.101,
			3954.128,
			3956.166,
			3957.188,
			3959.229,
			3960.252,
			3962.307,
			3964.365,
			3967.469,
			3969.548,
			3972.686,
			3973.737,
			3974.792,
			3977.953,
			3980.089,
			3983.313,
			3985.479,
			3987.656,
			3988.75,
			3990.947,
			3992.049,
			3994.264,
			3996.493,
			3997.612,
			3999.859,
			4002.12,
			4005.536,
			4006.682,
			4007.831,
			4011.302,
			4012.466,
			4013.634,
			4017.169,
			4019.542,
			4021.94,
			4025.582,
			4028.02,
			4031.692,
			4032.937,
			4034.185,
			4037.949,
			4040.468,
			4044.298,
			4046.839,
			4049.365,
			4050.63,
			4053.163,
			4054.431,
			4056.969,
			4059.495,
			4060.759,
			4063.264,
			4065.789,
			4069.601,
			4072.136,
			4075.945,
			4077.219,
			4078.473,
			4082.308,
			4084.873,
			4088.756,
			4091.332,
			4093.894,
			4095.185,
			4097.772,
			4099.069,
			4101.656,
			4104.25,
			4105.544,
			4106.839,
			4110.729,
			4113.325,
			4115.924,
			4117.224,
			4119.822,
			4122.423,
			4123.727,
			4126.339,
			4127.648,
			4130.272,
			4132.906,
			4136.871,
			4139.527,
			4143.523,
			4144.859,
			4146.197,
			4150.225,
			4152.919,
			4156.974,
			4159.687,
			4162.405,
			4163.768,
			4166.496,
			4167.863,
			4170.603,
			4174.725,
			4177.48,
			4180.244,
			4183.013,
			4185.789,
			4187.18,
			4189.966,
			4192.759,
			4194.158,
			4196.961,
			4198.366,
			4201.18,
			4204,
			4208.244,
			4211.082,
			4215.351,
			4216.778,
			4218.206,
			4222.501,
			4225.373,
			4229.692,
			4232.579,
			4235.473,
			4236.922,
			4239.826,
			4241.28,
			4244.192,
			4247.111,
			4248.573,
			4251.501,
			4254.436,
			4258.851,
			4260.326,
			4261.803,
			4266.242,
			4267.726,
			4269.21,
			4273.673,
			4276.656,
			4279.646,
			4284.141,
			4287.146,
			4291.664,
			4293.173,
			4294.684,
			4299.224,
			4302.258,
			4306.823,
			4309.873,
			4312.928,
			4314.458,
			4317.523,
			4320.594,
			4322.131,
			4325.211,
			4326.752,
			4329.84,
			4332.933,
			4337.584,
			4340.692,
			4345.363,
			4346.923,
			4348.485,
			4353.176,
			4356.31,
			4361.021,
			4364.17,
			4368.902,
			4372.062,
			4373.645,
			4375.228,
			4379.986,
			4381.574,
			4383.165,
			4387.941,
			4391.125,
			4394.314,
			4395.91,
			4399.105,
			4402.306,
			4403.907,
			4408.719,
			4411.932,
			4415.149,
			4419.982,
			4423.211,
			4428.062,
			4429.681,
			4431.301,
			4436.168,
			4439.418,
			4444.301,
			4447.562,
			4450.827,
			4452.461,
			4455.732,
			4457.37,
			4460.648,
			4465.572,
			4467.216,
			4472.155,
			4477.102,
			4480.405,
			4483.713,
			4487.025,
			4488.682,
			4492,
			4493.661,
			4496.985,
			4500.313,
			4505.313,
			4508.652,
			4513.669,
			4515.343,
			4517.019,
			4522.05,
			4525.409,
			4530.458,
			4533.828,
			4538.891,
			4542.271,
			4543.964,
			4547.351,
			4550.742,
			4552.439,
			4555.838,
			4559.239,
			4564.35,
			4566.056,
			4567.763,
			4572.891,
			4574.602,
			4576.313,
			4581.456,
			4584.888,
			4588.324,
			4593.484,
			4596.93,
			4602.104,
			4603.831,
			4605.558,
			4610.746,
			4612.477,
			4614.209,
			4619.411,
			4622.884,
			4626.36,
			4628.1,
			4631.582,
			4633.324,
			4636.811,
			4640.302,
			4642.048,
			4645.544,
			4649.043,
			4654.301,
			4657.81,
			4663.08,
			4664.838,
			4666.598,
			4671.88,
			4675.407,
			4680.703,
			4684.236,
			4687.772,
			4689.541,
			4693.082,
			4694.852,
			4696.623,
			4701.942,
			4703.716,
			4705.492,
			4710.822,
			4714.378,
			4719.718,
			4723.27,
			4726.808,
			4728.568,
			4732.065,
			4733.799,
			4737.238,
			4740.639,
			4745.665,
			4748.965,
			4753.841,
			4755.448,
			4757.045,
			4761.774,
			4764.879,
			4769.461,
			4772.472,
			4776.92,
			4779.838,
			4781.283,
			4784.145,
			4786.967,
			4788.365,
			4791.131,
			4793.86,
			4796.549,
			4800.514,
			4803.11,
			4805.668,
			4806.934,
			4809.437,
			4810.675,
			4813.124,
			4815.537,
			4819.091,
			4821.419,
			4824.864,
			4825.989,
			4827.105,
			4830.405,
			4832.563,
			4835.737,
			4837.811,
			4839.856,
			4840.871,
			4842.862,
			4843.855,
			4845.846,
			4847.806,
			4848.778,
			4850.718,
			4852.663,
			4855.562,
			4857.525,
			4860.458,
			4861.44,
			4862.426,
			4864.391,
			4865.37,
			4867.333,
			4869.294,
			4870.271,
			4872.22,
			4874.159,
			4877.057,
			4878.014,
			4878.97,
			4881.836,
			4883.766,
			4886.662,
			4888.617,
			4890.577,
			4891.558,
			4893.539,
			4895.535,
			4896.532,
			4898.534,
			4899.542,
			4901.556,
			4903.571,
			4906.605,
			4908.626,
			4911.629,
			4912.63,
			4913.631,
			4916.598,
			4918.574,
			4921.537,
			4923.511,
			4925.497,
			4928.497,
			4929.496,
			4931.497,
			4933.495,
			4934.494,
			4935.496,
			4938.501,
			4940.506,
			4942.513,
			4943.516,
			4945.524,
			4947.548,
			4948.56,
			4950.586,
			4951.608,
			4953.654,
			4955.703,
			4958.806,
			4960.879,
			4964.016,
			4965.064,
			4966.113,
			4969.302,
			4971.444,
			4974.666,
			4976.822,
			4978.984,
			4980.067,
			4982.24,
			4983.33,
			4985.503,
			4987.687,
			4988.776,
			4989.859,
			4993.123,
			4995.309,
			4998.607,
			5000.744,
			5002.886,
			5003.961,
			5006.104,
			5007.178,
			5009.334,
			5011.431,
			5014.589,
			5016.659,
			5019.777,
			5020.806,
			5021.834,
			5024.934,
			5025.971,
			5027.011,
			5030.145,
			5032.258,
			5034.385,
			5035.452,
			5037.605,
			5038.688,
			5040.86,
			5043.055,
			5044.158,
			5046.37,
			5048.607,
			5051.965,
			5053.101,
			5054.241,
			5057.676,
			5058.832,
			5059.993,
			5062.323,
			5063.491,
			5065.849,
			5068.218,
			5071.803,
			5074.213,
			5077.835,
			5079.047,
			5080.26,
			5083.9,
			5086.332,
			5089.99,
			5092.431,
			5094.882,
			5096.109,
			5098.568,
			5099.802,
			5102.279,
			5104.768,
			5106.015,
			5108.521,
			5111.037,
			5114.839,
			5117.388,
			5121.233,
			5122.521,
			5123.812,
			5127.7,
			5130.304,
			5134.229,
			5136.859,
			5139.498,
			5140.82,
			5143.47,
			5144.795,
			5146.119,
			5150.101,
			5151.432,
			5152.763,
			5156.768,
			5159.449,
			5162.139,
			5163.483,
			5166.177,
			5168.872,
			5170.221,
			5172.923,
			5174.274,
			5176.983,
			5179.692,
			5183.765,
			5186.485,
			5190.574,
			5191.94,
			5193.308,
			5197.417,
			5200.164,
			5204.295,
			5207.059,
			5209.828,
			5211.216,
			5213.996,
			5215.388,
			5218.178,
			5222.375,
			5225.181,
			5227.995,
			5232.227,
			5235.057,
			5237.893,
			5240.735,
			5242.16,
			5245.014,
			5246.443,
			5249.308,
			5252.18,
			5256.5,
			5259.385,
			5263.695,
			5265.122,
			5266.541,
			5270.749,
			5273.507,
			5277.573,
			5280.238,
			5282.866,
			5284.165,
			5286.736,
			5288.008,
			5290.522,
			5293,
			5294.225,
			5296.647,
			5299.035,
			5302.55,
			5303.704,
			5304.847,
			5308.219,
			5309.324,
			5310.42,
			5312.583,
			5313.65,
			5315.758,
			5317.826,
			5320.858,
			5322.834,
			5325.729,
			5326.676,
			5327.614,
			5330.375,
			5332.173,
			5334.806,
			5336.518,
			5338.192,
			5339.017,
			5340.637,
			5341.433,
			5342.999,
			5344.531,
			5345.284,
			5346.774,
			5348.216,
			5350.309,
			5351.665,
			5353.652,
			5354.303,
			5354.943,
			5356.834,
			5358.077,
			5359.912,
			5361.113,
			5362.312,
			5364.108,
			5364.707,
			5365.301,
			5367.081,
			5367.674,
			5368.268,
			5370.062,
			5371.285,
			5372.507,
			5373.119,
			5374.343,
			5375.618,
			5376.273,
			5377.581,
			5378.234,
			5379.541,
			5380.907,
			5383.007,
			5384.404,
			5386.575,
			5387.312,
			5388.049,
			5390.255,
			5391.79,
			5394.108,
			5395.654,
			5397.125,
			5397.855,
			5399.317,
			5400.049,
			5401.478,
			5403.541,
			5404.231,
			5406.296,
			5407.623,
			5408.954,
			5409.622,
			5410.964,
			5412.305,
			5412.978,
			5414.331,
			5415.011,
			5416.378,
			5417.753,
			5419.851,
			5421.257,
			5423.387,
			5424.099,
			5424.812,
			5426.979,
			5428.439,
			5430.629,
			5432.104,
			5433.638,
			5434.404,
			5435.935,
			5436.701,
			5438.311,
			5439.944,
			5440.761,
			5442.395,
			5444.179,
			5446.87,
			5447.767,
			5448.687,
			5451.452,
			5452.374,
			5453.296,
			5455.188,
			5456.132,
			5458.021,
			5459.79,
			5462.405,
			5464.145,
			5466.702,
			5467.554,
			5468.407,
			5470.918,
			5472.59,
			5475.107,
			5476.787,
			5478.477,
			5479.326,
			5481.007,
			5481.849,
			5483.537,
			5486.049,
			5487.699,
			5489.358,
			5491.789,
			5493.4,
			5495.826,
			5496.626,
			5497.429,
			5499.855,
			5501.49,
			5503.947,
			5505.591,
			5507.264,
			5509.797,
			5510.648,
			5511.504,
			5514.095,
			5514.964,
			5515.837,
			5518.485,
			5520.266,
			5522.061,
			5522.964,
			5524.781,
			5526.61,
			5527.531,
			5529.387,
			5530.319,
			5532.192,
			5534.08,
			5536.936,
			5538.854,
			5541.752,
			5542.723,
			5543.696,
			5546.625,
			5548.588,
			5551.546,
			5553.527,
			5555.51,
			5556.5,
			5558.485,
			5559.481,
			5561.478,
			5563.48,
			5564.485,
			5566.506,
			5568.533,
			5570.559,
			5572.603,
			5573.63,
			5575.692,
			5577.771,
			5578.817,
			5580.912,
			5581.961,
			5584.073,
			5586.185,
			5589.351,
			5591.456,
			5594.602,
			5595.646,
			5596.691,
			5599.824,
			5601.917,
			5605.064,
			5607.188,
			5609.312,
			5610.379,
			5612.525,
			5613.598,
			5615.744,
			5617.921,
			5619.01,
			5621.189,
			5623.4,
			5625.619,
			5627.852,
			5628.979,
			5632.37,
			5633.516,
			5634.666,
			5636.974,
			5638.138,
			5640.494,
			5642.858,
			5646.391,
			5648.759,
			5652.346,
			5653.544,
			5654.723,
			5658.256,
			5660.558,
			5663.991,
			5666.254,
			5668.512,
			5669.642,
			5671.9,
			5673.029,
			5675.292,
			5678.683,
			5680.952,
			5683.223,
			5686.645,
			5687.791,
			5688.938,
			5692.399,
			5693.56,
			5694.722,
			5698.227,
			5700.559,
			5704.104,
			5706.479,
			5708.865,
			5712.463,
			5713.667,
			5714.874,
			5718.509,
			5719.725,
			5720.944,
			5724.614,
			5727.072,
			5729.539,
			5730.776,
			5733.258,
			5735.748,
			5736.997,
			5739.501,
			5740.756,
			5743.273,
			5745.799,
			5749.604,
			5752.152,
			5755.987,
			5757.27,
			5758.553,
			5762.417,
			5765.002,
			5768.895,
			5771.498,
			5774.105,
			5775.408,
			5778.019,
			5779.326,
			5781.947,
			5784.575,
			5785.892,
			5787.21,
			5791.176,
			5793.829,
			5796.489,
			5797.823,
			5800.494,
			5803.173,
			5804.515,
			5807.205,
			5808.553,
			5811.255,
			5813.964,
			5818.042,
			5820.772,
			5824.883,
			5826.258,
			5827.634,
			5831.775,
			5834.545,
			5838.711,
			5841.495,
			5844.285,
			5845.67,
			5848.432,
			5849.816,
			5852.606,
			5855.405,
			5856.807,
			5859.616,
			5862.448,
			5866.691,
			5869.518,
			5872.945
		],
		[
			0,
			0.007,
			0.037,
			0.098,
			0.115,
			0.161,
			0.223,
			0.285,
			0.331,
			0.408,
			0.441,
			0.47,
			0.534,
			0.596,
			0.658,
			0.72,
			0.765,
			0.782,
			0.844,
			0.873,
			0.906,
			0.983,
			1.027,
			1.092,
			1.153,
			1.199,
			1.215,
			1.279,
			1.31,
			1.34,
			1.418,
			1.461,
			1.525,
			1.588,
			1.649,
			1.71,
			1.741,
			1.774,
			1.837,
			1.898,
			1.961,
			2.021,
			2.067,
			2.083,
			2.145,
			2.178,
			2.208,
			2.27,
			2.285,
			2.332,
			2.393,
			2.456,
			2.502,
			2.519,
			2.581,
			2.611,
			2.642,
			2.704,
			2.72,
			2.765,
			2.829,
			2.888,
			2.952,
			3.013,
			3.045,
			3.077,
			3.136,
			3.201,
			3.26,
			3.324,
			3.369,
			3.385,
			3.449,
			3.479,
			3.509,
			3.571,
			3.586,
			3.634,
			3.7,
			3.759,
			3.805,
			3.818,
			3.882,
			3.914,
			3.944,
			4.022,
			4.069,
			4.13,
			4.193,
			4.254,
			4.314,
			4.347,
			4.376,
			4.439,
			4.455,
			4.502,
			4.565,
			4.627,
			4.673,
			4.689,
			4.751,
			4.781,
			4.811,
			4.874,
			4.891,
			4.937,
			4.999,
			5.06,
			5.106,
			5.183,
			5.215,
			5.247,
			5.308,
			5.37,
			5.432,
			5.494,
			5.542,
			5.557,
			5.618,
			5.649,
			5.681,
			5.762,
			5.805,
			5.865,
			5.926,
			5.976,
			5.99,
			6.052,
			6.083,
			6.115,
			6.177,
			6.192,
			6.239,
			6.3,
			6.363,
			6.409,
			6.485,
			6.517,
			6.548,
			6.61,
			6.672,
			6.736,
			6.796,
			6.844,
			6.859,
			6.919,
			6.951,
			6.982,
			7.045,
			7.06,
			7.105,
			7.168,
			7.231,
			7.277,
			7.292,
			7.353,
			7.385,
			7.416,
			7.493,
			7.541,
			7.602,
			7.665,
			7.711,
			7.789,
			7.825,
			7.851,
			7.913,
			7.976,
			8.038,
			8.098,
			8.146,
			8.159,
			8.223,
			8.255,
			8.284,
			8.347,
			8.361,
			8.408,
			8.471,
			8.532,
			8.579,
			8.592,
			8.657,
			8.688,
			8.718,
			8.78,
			8.843,
			8.906,
			8.966,
			9.029,
			9.091,
			9.121,
			9.152,
			9.215,
			9.231,
			9.277,
			9.338,
			9.401,
			9.461,
			9.526,
			9.555,
			9.585,
			9.649,
			9.664,
			9.711,
			9.773,
			9.836,
			9.884,
			9.959,
			9.99,
			10.021,
			10.082,
			10.145,
			10.207,
			10.268,
			10.331,
			10.394,
			10.424,
			10.456,
			10.533,
			10.578,
			10.641,
			10.702,
			10.75,
			10.766,
			10.826,
			10.859,
			10.89,
			10.952,
			10.966,
			11.014,
			11.076,
			11.138,
			11.184,
			11.26,
			11.293,
			11.323,
			11.386,
			11.445,
			11.51,
			11.571,
			11.617,
			11.633,
			11.694,
			11.727,
			11.757,
			11.82,
			11.835,
			11.881,
			11.948,
			12.005,
			12.068,
			12.127,
			12.16,
			12.191,
			12.252,
			12.268,
			12.316,
			12.376,
			12.44,
			12.484,
			12.564,
			12.594,
			12.624,
			12.687,
			12.749,
			12.81,
			12.874,
			12.92,
			12.935,
			12.998,
			13.03,
			13.06,
			13.121,
			13.137,
			13.182,
			13.245,
			13.307,
			13.371,
			13.432,
			13.464,
			13.494,
			13.556,
			13.617,
			13.68,
			13.741,
			13.788,
			13.866,
			13.896,
			13.928,
			13.988,
			14.01,
			14.052,
			14.115,
			14.174,
			14.238,
			14.3,
			14.331,
			14.36,
			14.425,
			14.438,
			14.484,
			14.548,
			14.611,
			14.67,
			14.734,
			14.764,
			14.794,
			14.857,
			14.921,
			14.985,
			15.043,
			15.105,
			15.168,
			15.199,
			15.231,
			15.293,
			15.307,
			15.355,
			15.415,
			15.477,
			15.524,
			15.541,
			15.603,
			15.633,
			15.664,
			15.726,
			15.741,
			15.787,
			15.849,
			15.911,
			15.959,
			16.037,
			16.073,
			16.098,
			16.175,
			16.221,
			16.285,
			16.344,
			16.394,
			16.408,
			16.47,
			16.502,
			16.533,
			16.594,
			16.608,
			16.657,
			16.718,
			16.78,
			16.841,
			16.903,
			16.936,
			16.965,
			17.027,
			17.044,
			17.091,
			17.152,
			17.215,
			17.261,
			17.338,
			17.369,
			17.4,
			17.46,
			17.524,
			17.585,
			17.649,
			17.71,
			17.773,
			17.804,
			17.835,
			17.913,
			17.959,
			18.021,
			18.082,
			18.145,
			18.207,
			18.238,
			18.268,
			18.331,
			18.391,
			18.454,
			18.517,
			18.563,
			18.641,
			18.672,
			18.702,
			18.765,
			18.781,
			18.827,
			18.888,
			18.951,
			18.997,
			19.014,
			19.074,
			19.106,
			19.138,
			19.199,
			19.214,
			19.261,
			19.322,
			19.386,
			19.431,
			19.508,
			19.539,
			19.571,
			19.632,
			19.694,
			19.759,
			19.82,
			19.865,
			19.881,
			19.942,
			19.974,
			20.005,
			20.066,
			20.083,
			20.13,
			20.197,
			20.253,
			20.3,
			20.316,
			20.375,
			20.409,
			20.439,
			20.501,
			20.515,
			20.564,
			20.624,
			20.686,
			20.732,
			20.809,
			20.84,
			20.871,
			20.951,
			20.997,
			21.06,
			21.122,
			21.166,
			21.183,
			21.244,
			21.277,
			21.306,
			21.37,
			21.385,
			21.431,
			21.494,
			21.556,
			21.615,
			21.68,
			21.71,
			21.742,
			21.803,
			21.819,
			21.865,
			21.928,
			21.99,
			22.034,
			22.114,
			22.145,
			22.174,
			22.236,
			22.257,
			22.299,
			22.362,
			22.424,
			22.468,
			22.485,
			22.547,
			22.578,
			22.609,
			22.67,
			22.687,
			22.733,
			22.794,
			22.857,
			22.903,
			22.92,
			22.981,
			23.013,
			23.043,
			23.106,
			23.168,
			23.229,
			23.291,
			23.336,
			23.415,
			23.444,
			23.477,
			23.539,
			23.602,
			23.665,
			23.726,
			23.771,
			23.788,
			23.848,
			23.88,
			23.91,
			23.974,
			23.988,
			24.036,
			24.098,
			24.16,
			24.222,
			24.284,
			24.317,
			24.346,
			24.408,
			24.468,
			24.536,
			24.594,
			24.639,
			24.717,
			24.748,
			24.78,
			24.842,
			24.857,
			24.903,
			24.965,
			25.027,
			25.074,
			25.091,
			25.153,
			25.183,
			25.215,
			25.291,
			25.338,
			25.399,
			25.463,
			25.507,
			25.586,
			25.616,
			25.648,
			25.711,
			25.773,
			25.833,
			25.897,
			25.958,
			26.02,
			26.049,
			26.082,
			26.142,
			26.159,
			26.207,
			26.268,
			26.329,
			26.381,
			26.453,
			26.483,
			26.516,
			26.576,
			26.593,
			26.639,
			26.702,
			26.765,
			26.811,
			26.886,
			26.918,
			26.951,
			27.013,
			27.074,
			27.135,
			27.198,
			27.244,
			27.259,
			27.32,
			27.351,
			27.385,
			27.446,
			27.461,
			27.508,
			27.571,
			27.632,
			27.677,
			27.694,
			27.757,
			27.788,
			27.817,
			27.88,
			27.895,
			27.943,
			28.009,
			28.067,
			28.127,
			28.191,
			28.22,
			28.252,
			28.328,
			28.377,
			28.442,
			28.499,
			28.545,
			28.561,
			28.624,
			28.654,
			28.686,
			28.748,
			28.765,
			28.81,
			28.871,
			28.933,
			28.981,
			29.057,
			29.09,
			29.119,
			29.183,
			29.243,
			29.308,
			29.369,
			29.416,
			29.43,
			29.491,
			29.522,
			29.553,
			29.615,
			29.632,
			29.677,
			29.74,
			29.803,
			29.849,
			29.865,
			29.927,
			29.956,
			29.987,
			30.051,
			30.067,
			30.113,
			30.173,
			30.236,
			30.283,
			30.361,
			30.391,
			30.421,
			30.485,
			30.547,
			30.609,
			30.67,
			30.731,
			30.794,
			30.826,
			30.858,
			30.919,
			30.933,
			30.981,
			31.042,
			31.105,
			31.15,
			31.167,
			31.228,
			31.26,
			31.291,
			31.352,
			31.368,
			31.415,
			31.476,
			31.539,
			31.584,
			31.664,
			31.692,
			31.724,
			31.786,
			31.849,
			31.911,
			31.973,
			32.02,
			32.036,
			32.097,
			32.127,
			32.16,
			32.236,
			32.282,
			32.345,
			32.407,
			32.468,
			32.531,
			32.567,
			32.594,
			32.653,
			32.67,
			32.718,
			32.778,
			32.842,
			32.888,
			32.965,
			32.994,
			33.027,
			33.088,
			33.15,
			33.214,
			33.274,
			33.322,
			33.336,
			33.4,
			33.428,
			33.461,
			33.54,
			33.586,
			33.647,
			33.709,
			33.755,
			33.771,
			33.833,
			33.864,
			33.893,
			33.957,
			34.02,
			34.082,
			34.144,
			34.188,
			34.205,
			34.266,
			34.297,
			34.327,
			34.406,
			34.452,
			34.515,
			34.578,
			34.627,
			34.638,
			34.7,
			34.731,
			34.764,
			34.841,
			34.888,
			34.95,
			35.012,
			35.073,
			35.136,
			35.165,
			35.197,
			35.276,
			35.32,
			35.382,
			35.445,
			35.492,
			35.506,
			35.568,
			35.6,
			35.632,
			35.692,
			35.71,
			35.754,
			35.817,
			35.878,
			35.924,
			35.941,
			36.003,
			36.035,
			36.066,
			36.128,
			36.142,
			36.191,
			36.251,
			36.314,
			36.361,
			36.436,
			36.468,
			36.498,
			36.562,
			36.624,
			36.688,
			36.748,
			36.795,
			36.809,
			36.87,
			36.902,
			36.935,
			36.994,
			37.01,
			37.057,
			37.12,
			37.182,
			37.227,
			37.243,
			37.307,
			37.336,
			37.368,
			37.43,
			37.444,
			37.493,
			37.558,
			37.615,
			37.662,
			37.741,
			37.771,
			37.801,
			37.864,
			37.924,
			37.988,
			38.051,
			38.097,
			38.111,
			38.174,
			38.204,
			38.236,
			38.298,
			38.314,
			38.359,
			38.421,
			38.483,
			38.531,
			38.609,
			38.638,
			38.67,
			38.732,
			38.794,
			38.856,
			38.918,
			38.964,
			38.98,
			39.041,
			39.074,
			39.105,
			39.182,
			39.227,
			39.291,
			39.353,
			39.415,
			39.476,
			39.507,
			39.537,
			39.615,
			39.66,
			39.726,
			39.785,
			39.848,
			39.91,
			39.941,
			39.971,
			40.048,
			40.097,
			40.16,
			40.219,
			40.281,
			40.344,
			40.374,
			40.407,
			40.484,
			40.531,
			40.592,
			40.653,
			40.7,
			40.716,
			40.779,
			40.811,
			40.841,
			40.901,
			40.917,
			40.965,
			41.026,
			41.088,
			41.134,
			41.213,
			41.243,
			41.274,
			41.337,
			41.398,
			41.461,
			41.523,
			41.567,
			41.585,
			41.648,
			41.678,
			41.709,
			41.787,
			41.833,
			41.896,
			41.957,
			42.003,
			42.019,
			42.082,
			42.11,
			42.143,
			42.205,
			42.221,
			42.268,
			42.328,
			42.39,
			42.437,
			42.515,
			42.545,
			42.575,
			42.654,
			42.702,
			42.764,
			42.824,
			42.887,
			42.949,
			42.98,
			43.01,
			43.073,
			43.087,
			43.135,
			43.198,
			43.257,
			43.304,
			43.321,
			43.382,
			43.414,
			43.446,
			43.506,
			43.569,
			43.631,
			43.692,
			43.739,
			43.817,
			43.848,
			43.877,
			43.94,
			43.957,
			44.004,
			44.065,
			44.126,
			44.173,
			44.19,
			44.249,
			44.283,
			44.314,
			44.376,
			44.39,
			44.435,
			44.5,
			44.56,
			44.608,
			44.686,
			44.717,
			44.747,
			44.807,
			44.87,
			44.938,
			44.994,
			45.058,
			45.118,
			45.151,
			45.182,
			45.242,
			45.258,
			45.305,
			45.368,
			45.43,
			45.49,
			45.554,
			45.585,
			45.615,
			45.678,
			45.691,
			45.74,
			45.803,
			45.864,
			45.909,
			45.988,
			46.018,
			46.048,
			46.128,
			46.173,
			46.235,
			46.297,
			46.343,
			46.359,
			46.421,
			46.452,
			46.484,
			46.559,
			46.608,
			46.67,
			46.731,
			46.779,
			46.793,
			46.855,
			46.886,
			46.918,
			46.98,
			47.001,
			47.041,
			47.104,
			47.165,
			47.213,
			47.289,
			47.319,
			47.352,
			47.413,
			47.476,
			47.538,
			47.598,
			47.647,
			47.66,
			47.723,
			47.754,
			47.785,
			47.864,
			47.909,
			47.976,
			48.033,
			48.081,
			48.159,
			48.187,
			48.22,
			48.298,
			48.344,
			48.405,
			48.469,
			48.515,
			48.593,
			48.624,
			48.655,
			48.715,
			48.731,
			48.777,
			48.84,
			48.902,
			48.948,
			48.964,
			49.026,
			49.063,
			49.087,
			49.149,
			49.165,
			49.21,
			49.273,
			49.336,
			49.399,
			49.46,
			49.49,
			49.521,
			49.582,
			49.646,
			49.707,
			49.77,
			49.832,
			49.894,
			49.924,
			49.956,
			50.018,
			50.034,
			50.08,
			50.142,
			50.204,
			50.251,
			50.265,
			50.327,
			50.36,
			50.39,
			50.453,
			50.467,
			50.515,
			50.574,
			50.637,
			50.685,
			50.763,
			50.792,
			50.825,
			50.902,
			50.948,
			51.01,
			51.073,
			51.125,
			51.133,
			51.194,
			51.226,
			51.259,
			51.319,
			51.336,
			51.382,
			51.442,
			51.505,
			51.569,
			51.63,
			51.659,
			51.691,
			51.754,
			51.77,
			51.816,
			51.879,
			51.94,
			51.987,
			52.065,
			52.096,
			52.125,
			52.187,
			52.251,
			52.314,
			52.374,
			52.421,
			52.435,
			52.497,
			52.527,
			52.56,
			52.623,
			52.636,
			52.683,
			52.748,
			52.809,
			52.854,
			52.871,
			52.933,
			52.961,
			52.992,
			53.057,
			53.117,
			53.186,
			53.242,
			53.289,
			53.366,
			53.398,
			53.427,
			53.49,
			53.507,
			53.551,
			53.614,
			53.676,
			53.724,
			53.738,
			53.801,
			53.833,
			53.86,
			53.926,
			53.94,
			53.988,
			54.048,
			54.11,
			54.173,
			54.235,
			54.267,
			54.298,
			54.359,
			54.421,
			54.483,
			54.544,
			54.592,
			54.607,
			54.668,
			54.699,
			54.731,
			54.793,
			54.808,
			54.853,
			54.917,
			54.978,
			55.024,
			55.041,
			55.102,
			55.133,
			55.165,
			55.226,
			55.246,
			55.288,
			55.35,
			55.413,
			55.459,
			55.537,
			55.567,
			55.598,
			55.661,
			55.724,
			55.785,
			55.847,
			55.894,
			55.91,
			55.971,
			56.001,
			56.033,
			56.094,
			56.111,
			56.157,
			56.22,
			56.281,
			56.326,
			56.343,
			56.406,
			56.436,
			56.466,
			56.529,
			56.546,
			56.592,
			56.654,
			56.715,
			56.777,
			56.84,
			56.871,
			56.9,
			56.963,
			57.024,
			57.086,
			57.15,
			57.197,
			57.21,
			57.274,
			57.308,
			57.335,
			57.398,
			57.412,
			57.458,
			57.522,
			57.582,
			57.63,
			57.647,
			57.707,
			57.737,
			57.768,
			57.831,
			57.893,
			57.954,
			58.016,
			58.08,
			58.141,
			58.171,
			58.204,
			58.265,
			58.281,
			58.327,
			58.39,
			58.451,
			58.498,
			58.515,
			58.577,
			58.607,
			58.637,
			58.699,
			58.714,
			58.763,
			58.824,
			58.885,
			58.932,
			59.008,
			59.041,
			59.073,
			59.133,
			59.196,
			59.258,
			59.319,
			59.37,
			59.381,
			59.443,
			59.475,
			59.506,
			59.568,
			59.584,
			59.628,
			59.691,
			59.754,
			59.801,
			59.815,
			59.876,
			59.907,
			59.94,
			60.016,
			60.063,
			60.126,
			60.186,
			60.233,
			60.31,
			60.343,
			60.373,
			60.436,
			60.498,
			60.56,
			60.621,
			60.667,
			60.683,
			60.747,
			60.776,
			60.807,
			60.87,
			60.886,
			60.931,
			60.992,
			61.056,
			61.102,
			61.118,
			61.181,
			61.209,
			61.243,
			61.304,
			61.32,
			61.365,
			61.433,
			61.49,
			61.535,
			61.614,
			61.645,
			61.675,
			61.738,
			61.8,
			61.863,
			61.924,
			61.97,
			61.985,
			62.049,
			62.077,
			62.11,
			62.187,
			62.233,
			62.297,
			62.358,
			62.42,
			62.483,
			62.513,
			62.543,
			62.605,
			62.669,
			62.731,
			62.791,
			62.854,
			62.916,
			62.947,
			62.976,
			63.054,
			63.103,
			63.166,
			63.226,
			63.274,
			63.288,
			63.35,
			63.38,
			63.413,
			63.492,
			63.537,
			63.599,
			63.659,
			63.708,
			63.783,
			63.816,
			63.844,
			63.908,
			63.97,
			64.032,
			64.093,
			64.14,
			64.157,
			64.217,
			64.248,
			64.281,
			64.343,
			64.357,
			64.405,
			64.467,
			64.528,
			64.575,
			64.591,
			64.651,
			64.684,
			64.713,
			64.776,
			64.791,
			64.839,
			64.902,
			64.961,
			65.01,
			65.086,
			65.119,
			65.147,
			65.21,
			65.274,
			65.334,
			65.397,
			65.444,
			65.46,
			65.522,
			65.554,
			65.584,
			65.644,
			65.661,
			65.707,
			65.769,
			65.83,
			65.891,
			65.954,
			65.986,
			66.015,
			66.095,
			66.141,
			66.202,
			66.265,
			66.326,
			66.39,
			66.419,
			66.452,
			66.529,
			66.576,
			66.638,
			66.698,
			66.76,
			66.822,
			66.855,
			66.883,
			66.948,
			66.963,
			67.008,
			67.071,
			67.134,
			67.195,
			67.257,
			67.287,
			67.318,
			67.381,
			67.442,
			67.505,
			67.567,
			67.618,
			67.691,
			67.721,
			67.752,
			67.815,
			67.832,
			67.876,
			67.938,
			68.002,
			68.049,
			68.063,
			68.125,
			68.156,
			68.188,
			68.248,
			68.264,
			68.311,
			68.374,
			68.435,
			68.482,
			68.56,
			68.591,
			68.621,
			68.682,
			68.745,
			68.807,
			68.868,
			68.916,
			68.932,
			68.992,
			69.024,
			69.056,
			69.117,
			69.133,
			69.177,
			69.24,
			69.304,
			69.364,
			69.426,
			69.457,
			69.49,
			69.551,
			69.567,
			69.613,
			69.68,
			69.738,
			69.798,
			69.86,
			69.892,
			69.923,
			69.986,
			70.048,
			70.11,
			70.172,
			70.234,
			70.295,
			70.327,
			70.358,
			70.436,
			70.482,
			70.545,
			70.606,
			70.652,
			70.668,
			70.73,
			70.76,
			70.793,
			70.855,
			70.868,
			70.916,
			70.982,
			71.039,
			71.087,
			71.163,
			71.192,
			71.226,
			71.288,
			71.349,
			71.41,
			71.474,
			71.521,
			71.535,
			71.598,
			71.628,
			71.658,
			71.742,
			71.785,
			71.847,
			71.907,
			71.954,
			71.97,
			72.031,
			72.063,
			72.093,
			72.156,
			72.218,
			72.281,
			72.341,
			72.403,
			72.464,
			72.497,
			72.527,
			72.59,
			72.606,
			72.652,
			72.713,
			72.775,
			72.838,
			72.899,
			72.931,
			72.96,
			73.038,
			73.087,
			73.148,
			73.21,
			73.258,
			73.335,
			73.364,
			73.396,
			73.458,
			73.519,
			73.586,
			73.644,
			73.691,
			73.707,
			73.768,
			73.802,
			73.83,
			73.892,
			73.908,
			73.955,
			74.015,
			74.079,
			74.126,
			74.14,
			74.203,
			74.232,
			74.265,
			74.325,
			74.341,
			74.388,
			74.45,
			74.513,
			74.558,
			74.636,
			74.667,
			74.699,
			74.759,
			74.823,
			74.884,
			74.946,
			74.993,
			75.009,
			75.071,
			75.101,
			75.131,
			75.21,
			75.257,
			75.317,
			75.381,
			75.426,
			75.441,
			75.505,
			75.534,
			75.567,
			75.627,
			75.644,
			75.69,
			75.752,
			75.814,
			75.865,
			75.939,
			75.971,
			76,
			76.063,
			76.124,
			76.188,
			76.249,
			76.296,
			76.31,
			76.373,
			76.405,
			76.434,
			76.496,
			76.512,
			76.558,
			76.621,
			76.684,
			76.728,
			76.744,
			76.806,
			76.838,
			76.87,
			76.932,
			76.991,
			77.054,
			77.115,
			77.164,
			77.241,
			77.272,
			77.302,
			77.365,
			77.381,
			77.426,
			77.488,
			77.551,
			77.613,
			77.674,
			77.704,
			77.737,
			77.798,
			77.814,
			77.859,
			77.926,
			77.985,
			78.031,
			78.109,
			78.14,
			78.172,
			78.233,
			78.292,
			78.357,
			78.417,
			78.466,
			78.482,
			78.544,
			78.574,
			78.604,
			78.665,
			78.682,
			78.729,
			78.793,
			78.852,
			78.898,
			78.916,
			78.976,
			79.008,
			79.04,
			79.117,
			79.163,
			79.226,
			79.288,
			79.334,
			79.41,
			79.441,
			79.474,
			79.551,
			79.598,
			79.657,
			79.72,
			79.766,
			79.782,
			79.846,
			79.875,
			79.907,
			79.968,
			79.99,
			80.031,
			80.094,
			80.154,
			80.202,
			80.216,
			80.279,
			80.31,
			80.34,
			80.403,
			80.42,
			80.465,
			80.526,
			80.588,
			80.634,
			80.714,
			80.744,
			80.774,
			80.854,
			80.898,
			80.963,
			81.023,
			81.084,
			81.147,
			81.18,
			81.21,
			81.286,
			81.332,
			81.393,
			81.457,
			81.503,
			81.52,
			81.583,
			81.613,
			81.643,
			81.707,
			81.768,
			81.828,
			81.891,
			81.938,
			82.016,
			82.052,
			82.077,
			82.14,
			82.156,
			82.202,
			82.265,
			82.326,
			82.387,
			82.448,
			82.481,
			82.51,
			82.575,
			82.589,
			82.635,
			82.698,
			82.761,
			82.807,
			82.885,
			82.916,
			82.947,
			83.007,
			83.069,
			83.132,
			83.192,
			83.241,
			83.318,
			83.348,
			83.381,
			83.458,
			83.504,
			83.565,
			83.628,
			83.673,
			83.691,
			83.751,
			83.782,
			83.813,
			83.877,
			83.892,
			83.937,
			84.001,
			84.063,
			84.114,
			84.185,
			84.216,
			84.248,
			84.309,
			84.371,
			84.434,
			84.497,
			84.543,
			84.558,
			84.621,
			84.651,
			84.682,
			84.743,
			84.759,
			84.805,
			84.867,
			84.93,
			84.992,
			85.054,
			85.084,
			85.117,
			85.177,
			85.194,
			85.241,
			85.301,
			85.365,
			85.411,
			85.489,
			85.518,
			85.549,
			85.613,
			85.673,
			85.736,
			85.798,
			85.842,
			85.859,
			85.921,
			85.954,
			85.984,
			86.046,
			86.063,
			86.108,
			86.169,
			86.233,
			86.279,
			86.293,
			86.357,
			86.387,
			86.417,
			86.48,
			86.541,
			86.605,
			86.667,
			86.727,
			86.79,
			86.82,
			86.852,
			86.915,
			86.977,
			87.038,
			87.101,
			87.147,
			87.163,
			87.224,
			87.255,
			87.287,
			87.348,
			87.364,
			87.41,
			87.474,
			87.535,
			87.597,
			87.658,
			87.688,
			87.719,
			87.782,
			87.843,
			87.907,
			87.968,
			88.016,
			88.03,
			88.092,
			88.123,
			88.156,
			88.216,
			88.238,
			88.276,
			88.341,
			88.403,
			88.448,
			88.465,
			88.527,
			88.558,
			88.588,
			88.651,
			88.665,
			88.711,
			88.774,
			88.837,
			88.884,
			88.961,
			88.992,
			89.023,
			89.084,
			89.147,
			89.209,
			89.271,
			89.318,
			89.332,
			89.394,
			89.424,
			89.457,
			89.535,
			89.582,
			89.645,
			89.703,
			89.751,
			89.768,
			89.828,
			89.859,
			89.889,
			89.954,
			89.967,
			90.014,
			90.078,
			90.138,
			90.2,
			90.263,
			90.3,
			90.324,
			90.386,
			90.449,
			90.515,
			90.574,
			90.621,
			90.634,
			90.698,
			90.727,
			90.76,
			90.822,
			90.835,
			90.882,
			90.945,
			91.008,
			91.054,
			91.068,
			91.131,
			91.161,
			91.194,
			91.272,
			91.316,
			91.378,
			91.442,
			91.489,
			91.565,
			91.596,
			91.626,
			91.704,
			91.751,
			91.813,
			91.876,
			91.923,
			91.936,
			92,
			92.031,
			92.06,
			92.123,
			92.138,
			92.184,
			92.248,
			92.31,
			92.37,
			92.434,
			92.465,
			92.495,
			92.557,
			92.618,
			92.683,
			92.742,
			92.806,
			92.867,
			92.898,
			92.93,
			92.991,
			93.007,
			93.053,
			93.115,
			93.177,
			93.225,
			93.241,
			93.301,
			93.334,
			93.363,
			93.441,
			93.487,
			93.553,
			93.612,
			93.659,
			93.736,
			93.768,
			93.798,
			93.86,
			93.922,
			93.985,
			94.046,
			94.092,
			94.109,
			94.169,
			94.201,
			94.231,
			94.31,
			94.354,
			94.422,
			94.481,
			94.542,
			94.602,
			94.635,
			94.666,
			94.727,
			94.744,
			94.791,
			94.852,
			94.914,
			94.975,
			95.038,
			95.068,
			95.098,
			95.161,
			95.224,
			95.286,
			95.348,
			95.409,
			95.471,
			95.503,
			95.533,
			95.596,
			95.612,
			95.657,
			95.719,
			95.781,
			95.828,
			95.842,
			95.906,
			95.938,
			95.968,
			96.031,
			96.092,
			96.154,
			96.215,
			96.277,
			96.34,
			96.371,
			96.402,
			96.465,
			96.484,
			96.526,
			96.588,
			96.651,
			96.698,
			96.713,
			96.773,
			96.804,
			96.836,
			96.899,
			96.915,
			96.959,
			97.021,
			97.085,
			97.13,
			97.208,
			97.239,
			97.271,
			97.331,
			97.395,
			97.456,
			97.517,
			97.565,
			97.58,
			97.64,
			97.674,
			97.703,
			97.765,
			97.782,
			97.829,
			97.892,
			97.953,
			98.015,
			98.076,
			98.106,
			98.137,
			98.199,
			98.215,
			98.261,
			98.326,
			98.385,
			98.449,
			98.511,
			98.547,
			98.571,
			98.633,
			98.698,
			98.759,
			98.821,
			98.884,
			98.943,
			98.974,
			99.008,
			99.07,
			99.085,
			99.131,
			99.194,
			99.253,
			99.316,
			99.379,
			99.41,
			99.441,
			99.502,
			99.517,
			99.566,
			99.627,
			99.69,
			99.736,
			99.813,
			99.843,
			99.874,
			99.938,
			100.001,
			100.059,
			100.121,
			100.17,
			100.186,
			100.247,
			100.279,
			100.307,
			100.37,
			100.387,
			100.432,
			100.493,
			100.558,
			100.617,
			100.68,
			100.712,
			100.743,
			100.803,
			100.821,
			100.868,
			100.928,
			100.932,
			100.991,
			101.051,
			101.114,
			101.147,
			101.177,
			101.238,
			101.301,
			101.365,
			101.424,
			101.472,
			101.488,
			101.55,
			101.58,
			101.611,
			101.673,
			101.688,
			101.736,
			101.798,
			101.859,
			101.906,
			101.982,
			102.014,
			102.046,
			102.107,
			102.17,
			102.232,
			102.292,
			102.354,
			102.417,
			102.448,
			102.48,
			102.541,
			102.557,
			102.602,
			102.668,
			102.727,
			102.774,
			102.79,
			102.85,
			102.882,
			102.913,
			102.976,
			102.992,
			103.038,
			103.1,
			103.161,
			103.207,
			103.285,
			103.315,
			103.348,
			103.424,
			103.471,
			103.532,
			103.594,
			103.641,
			103.658,
			103.719,
			103.75,
			103.781,
			103.844,
			103.86,
			103.904,
			103.968,
			104.03,
			104.092,
			104.154,
			104.183,
			104.215,
			104.277,
			104.292,
			104.338,
			104.401,
			104.463,
			104.511,
			104.586,
			104.619,
			104.651,
			104.71,
			104.732,
			104.775,
			104.836,
			104.898,
			104.943,
			104.959,
			105.02,
			105.054,
			105.082,
			105.147,
			105.159,
			105.207,
			105.27,
			105.333,
			105.376,
			105.393,
			105.457,
			105.487,
			105.518,
			105.58,
			105.594,
			105.641,
			105.705,
			105.764,
			105.81,
			105.888,
			105.921,
			105.951,
			106.015,
			106.074,
			106.138,
			106.201,
			106.248,
			106.26,
			106.324,
			106.354,
			106.385,
			106.447,
			106.464,
			106.51,
			106.572,
			106.634,
			106.681,
			106.757,
			106.796,
			106.818,
			106.882,
			106.943,
			107.006,
			107.068,
			107.115,
			107.131,
			107.193,
			107.223,
			107.254,
			107.317,
			107.333,
			107.376,
			107.44,
			107.502,
			107.547,
			107.565,
			107.626,
			107.657,
			107.689,
			107.751,
			107.765,
			107.813,
			107.875,
			107.935,
			107.983,
			108.061,
			108.091,
			108.123,
			108.184,
			108.247,
			108.307,
			108.37,
			108.418,
			108.431,
			108.495,
			108.526,
			108.557,
			108.633,
			108.681,
			108.746,
			108.805,
			108.856,
			108.929,
			108.959,
			108.991,
			109.053,
			109.067,
			109.115,
			109.177,
			109.239,
			109.283,
			109.36,
			109.393,
			109.424,
			109.486,
			109.548,
			109.609,
			109.673,
			109.718,
			109.735,
			109.797,
			109.826,
			109.859,
			109.921,
			109.934,
			109.983,
			110.045,
			110.107,
			110.154,
			110.167,
			110.231,
			110.262,
			110.291,
			110.354,
			110.371,
			110.417,
			110.48,
			110.541,
			110.588,
			110.665,
			110.696,
			110.726,
			110.804,
			110.851,
			110.917,
			110.976,
			111.02,
			111.036,
			111.098,
			111.13,
			111.16,
			111.224,
			111.237,
			111.284,
			111.347,
			111.409,
			111.456,
			111.532,
			111.564,
			111.596,
			111.657,
			111.717,
			111.782,
			111.841,
			111.89,
			111.968,
			111.998,
			112.029,
			112.091,
			112.106,
			112.153,
			112.215,
			112.276,
			112.324,
			112.339,
			112.402,
			112.431,
			112.465,
			112.524,
			112.541,
			112.586,
			112.647,
			112.712,
			112.758,
			112.835,
			112.867,
			112.895,
			112.959,
			113.021,
			113.085,
			113.145,
			113.191,
			113.207,
			113.268,
			113.299,
			113.332,
			113.409,
			113.454,
			113.519,
			113.58,
			113.626,
			113.64,
			113.704,
			113.734,
			113.765,
			113.826,
			113.842,
			113.89,
			113.951,
			114.013,
			114.074,
			114.137,
			114.167,
			114.199,
			114.262,
			114.323,
			114.385,
			114.447,
			114.493,
			114.509,
			114.571,
			114.603,
			114.632,
			114.696,
			114.71,
			114.758,
			114.818,
			114.882,
			114.927,
			114.943,
			115.006,
			115.041,
			115.066,
			115.129,
			115.145,
			115.191,
			115.253,
			115.316,
			115.361,
			115.439,
			115.471,
			115.501,
			115.564,
			115.625,
			115.687,
			115.748,
			115.796,
			115.812,
			115.873,
			115.905,
			115.935,
			116.014,
			116.059,
			116.12,
			116.185,
			116.231,
			116.307,
			116.339,
			116.369,
			116.432,
			116.492,
			116.556,
			116.618,
			116.665,
			116.74,
			116.771,
			116.805,
			116.865,
			116.881,
			116.926,
			116.991,
			117.051,
			117.101,
			117.114,
			117.176,
			117.208,
			117.236,
			117.3,
			117.314,
			117.361,
			117.426,
			117.485,
			117.549,
			117.609,
			117.64,
			117.67,
			117.734,
			117.796,
			117.857,
			117.918,
			117.965,
			117.983,
			118.045,
			118.073,
			118.106,
			118.168,
			118.182,
			118.231,
			118.291,
			118.354,
			118.399,
			118.416,
			118.478,
			118.51,
			118.541,
			118.603,
			118.616,
			118.664,
			118.726,
			118.787,
			118.836,
			118.91,
			118.944,
			118.974,
			119.035,
			119.098,
			119.161,
			119.221,
			119.267,
			119.283,
			119.347,
			119.377,
			119.407,
			119.47,
			119.486,
			119.533,
			119.593,
			119.655,
			119.702,
			119.717,
			119.78,
			119.812,
			119.841,
			119.904,
			119.921,
			119.965,
			120.027,
			120.091,
			120.153,
			120.214,
			120.245,
			120.276,
			120.337,
			120.401,
			120.463,
			120.524,
			120.572,
			120.587,
			120.648,
			120.68,
			120.71,
			120.789,
			120.835,
			120.897,
			120.959,
			121.004,
			121.021,
			121.081,
			121.115,
			121.145,
			121.207,
			121.268,
			121.331,
			121.393,
			121.44,
			121.517,
			121.548,
			121.577,
			121.64,
			121.656,
			121.701,
			121.765,
			121.827,
			121.874,
			121.888,
			121.951,
			121.981,
			122.012,
			122.076,
			122.09,
			122.136,
			122.198,
			122.261,
			122.308,
			122.385,
			122.416,
			122.448,
			122.51,
			122.571,
			122.632,
			122.694,
			122.742,
			122.757,
			122.819,
			122.85,
			122.88,
			122.94,
			122.959,
			123.004,
			123.067,
			123.127,
			123.175,
			123.191,
			123.253,
			123.288,
			123.314,
			123.378,
			123.393,
			123.437,
			123.501,
			123.563,
			123.61,
			123.687,
			123.717,
			123.748,
			123.826,
			123.873,
			123.934,
			123.997,
			124.043,
			124.058,
			124.122,
			124.15,
			124.182,
			124.245,
			124.26,
			124.307,
			124.37,
			124.431,
			124.493,
			124.553
		]
	],
	'4038_5862_7982': [
		[
			125.372
		],
		[
			0
		],
		[
			0,
			1.314,
			2.948,
			6.22,
			9.496,
			11.136,
			16.06,
			19.348,
			22.64,
			27.586,
			30.889,
			35.853,
			37.51,
			39.168,
			44.151,
			47.479,
			52.482,
			55.823,
			59.166,
			60.838,
			64.182,
			65.855,
			69.202,
			72.552,
			74.228,
			77.584,
			80.944,
			85.979,
			89.325,
			94.307,
			95.954,
			97.592,
			102.453,
			105.649,
			110.374,
			113.475,
			116.537,
			121.062,
			122.554,
			124.036,
			128.424,
			129.867,
			131.301,
			135.544,
			138.323,
			141.062,
			142.415,
			145.093,
			147.732,
			149.036,
			151.616,
			152.891,
			155.414,
			157.899,
			161.555,
			163.949,
			167.479,
			168.659,
			169.811,
			173.216,
			175.442,
			178.72,
			180.868,
			182.993,
			184.05,
			186.158,
			187.21,
			189.315,
			191.424,
			192.478,
			193.531,
			196.697,
			198.805,
			200.911,
			201.966,
			204.072,
			206.182,
			207.248,
			209.381,
			210.447,
			212.612,
			214.792,
			218.076,
			220.273,
			223.582,
			224.688,
			225.796,
			229.133,
			231.365,
			234.726,
			236.971,
			239.22,
			240.346,
			242.598,
			243.723,
			245.963,
			249.323,
			251.558,
			253.792,
			257.128,
			258.238,
			259.349,
			262.66,
			263.761,
			264.862,
			268.167,
			270.37,
			273.678,
			275.888,
			278.102,
			281.435,
			282.549,
			283.659,
			287,
			289.217,
			292.534,
			294.729,
			297.996,
			300.16,
			301.233,
			303.375,
			306.573,
			308.696,
			310.82,
			313.997,
			315.059,
			316.121,
			319.3,
			320.362,
			321.426,
			323.553,
			324.618,
			326.767,
			328.918,
			332.169,
			334.331,
			335.443,
			337.643,
			338.747,
			339.853,
			343.185,
			344.3,
			345.418,
			348.784,
			351.039,
			353.284,
			354.42,
			356.717,
			359.003,
			360.15,
			362.447,
			363.597,
			365.883,
			368.193,
			371.668,
			373.996,
			377.503,
			378.676,
			379.852,
			383.393,
			385.766,
			389.342,
			391.738,
			394.144,
			395.35,
			397.768,
			398.98,
			401.412,
			403.853,
			405.077,
			406.304,
			410.001,
			412.479,
			414.967,
			416.215,
			418.719,
			421.232,
			422.492,
			426.284,
			428.823,
			431.37,
			435.205,
			437.772,
			441.638,
			442.931,
			444.226,
			448.126,
			450.733,
			454.65,
			457.269,
			459.896,
			461.211,
			463.844,
			465.163,
			467.806,
			470.454,
			471.78,
			474.438,
			477.102,
			481.112,
			482.453,
			483.795,
			486.485,
			489.182,
			490.533,
			493.242,
			494.598,
			497.315,
			500.04,
			504.145,
			506.889,
			511.016,
			512.395,
			513.774,
			517.924,
			520.704,
			524.887,
			527.684,
			530.49,
			531.896,
			534.715,
			536.127,
			538.956,
			541.792,
			543.21,
			546.052,
			548.898,
			553.177,
			556.038,
			560.341,
			561.779,
			563.218,
			567.545,
			570.438,
			574.789,
			577.699,
			580.616,
			582.076,
			585.004,
			586.47,
			587.939,
			592.356,
			593.833,
			595.311,
			599.756,
			602.726,
			605.702,
			607.192,
			610.173,
			613.159,
			614.653,
			617.646,
			619.145,
			622.146,
			625.155,
			629.68,
			632.704,
			637.252,
			640.291,
			644.857,
			647.907,
			652.492,
			655.556,
			658.625,
			660.162,
			663.24,
			664.781,
			666.324,
			672.507,
			674.057,
			678.714,
			681.826,
			684.943,
			686.503,
			689.629,
			692.761,
			694.329,
			697.469,
			699.041,
			702.187,
			705.358,
			710.064,
			713.183,
			717.82,
			719.353,
			720.881,
			725.443,
			728.446,
			732.97,
			735.977,
			738.999,
			740.494,
			743.476,
			744.963,
			747.933,
			750.908,
			752.394,
			755.361,
			758.363,
			762.926,
			764.484,
			766.021,
			769.102,
			772.198,
			773.746,
			776.824,
			778.372,
			781.49,
			784.526,
			789.067,
			792.037,
			796.415,
			797.851,
			799.276,
			803.482,
			804.854,
			806.214,
			810.222,
			812.828,
			816.653,
			819.151,
			820.384,
			822.821,
			826.397,
			828.737,
			831.015,
			834.375,
			836.534,
			839.716,
			840.762,
			841.802,
			844.9,
			846.944,
			849.001,
			852.172,
			854.289,
			857.553,
			858.644,
			859.764,
			863.168,
			865.479,
			868.98,
			871.327,
			873.669,
			874.833,
			877.151,
			879.459,
			880.596,
			882.867,
			884,
			886.214,
			888.419,
			891.644,
			893.772,
			896.889,
			897.922,
			898.955,
			902.023,
			904.07,
			907.144,
			909.201,
			911.269,
			912.305,
			914.383,
			915.428,
			917.521,
			920.674,
			921.728,
			924.898,
			927.021,
			929.147,
			930.215,
			932.354,
			934.502,
			935.58,
			938.81,
			940.985,
			943.169,
			946.46,
			948.666,
			951.992,
			953.105,
			954.218,
			957.557,
			959.773,
			963.086,
			965.254,
			968.456,
			970.559,
			971.605,
			973.685,
			975.757,
			976.791,
			978.853,
			980.912,
			983.994,
			986.051,
			989.138,
			990.17,
			991.204,
			993.273,
			994.308,
			996.386,
			998.467,
			1001.6,
			1003.693,
			1006.863,
			1007.923,
			1008.984,
			1012.207,
			1014.363,
			1017.631,
			1019.815,
			1023.103,
			1025.295,
			1026.39,
			1028.579,
			1030.767,
			1031.859,
			1034.039,
			1036.219,
			1039.486,
			1040.573,
			1041.66,
			1044.923,
			1046.01,
			1047.098,
			1049.279,
			1050.373,
			1052.569,
			1054.768,
			1058.091,
			1060.315,
			1063.657,
			1064.774,
			1065.888,
			1069.231,
			1070.349,
			1071.457,
			1074.795,
			1077.011,
			1079.231,
			1080.344,
			1082.556,
			1083.666,
			1085.888,
			1088.102,
			1089.212,
			1091.428,
			1093.619,
			1096.918,
			1099.095,
			1102.312,
			1103.368,
			1104.416,
			1107.503,
			1109.516,
			1112.473,
			1114.4,
			1117.23,
			1119.076,
			1119.987,
			1120.909,
			1123.598,
			1124.484,
			1125.365,
			1128.013,
			1129.763,
			1132.409,
			1134.176,
			1135.949,
			1136.845,
			1138.636,
			1139.532,
			1141.332,
			1143.141,
			1145.856,
			1147.678,
			1150.409,
			1151.322,
			1152.234,
			1154.97,
			1156.796,
			1159.535,
			1161.362,
			1164.102,
			1165.933,
			1166.851,
			1168.69,
			1170.532,
			1171.458,
			1173.313,
			1175.174,
			1177.983,
			1178.922,
			1179.864,
			1181.755,
			1183.656,
			1184.61,
			1186.526,
			1187.488,
			1189.421,
			1191.366,
			1194.302,
			1196.272,
			1199.241,
			1200.235,
			1201.231,
			1204.224,
			1206.227,
			1209.239,
			1211.253,
			1213.263,
			1214.27,
			1216.284,
			1217.284,
			1219.284,
			1221.284,
			1222.275,
			1224.255,
			1226.235,
			1229.167,
			1230.144,
			1231.12,
			1234.011,
			1234.972,
			1235.934,
			1238.798,
			1240.704,
			1243.562,
			1245.468,
			1247.378,
			1250.252,
			1251.211,
			1252.176,
			1255.067,
			1257.021,
			1259.988,
			1261.983,
			1263.993,
			1265.003,
			1267.031,
			1269.068,
			1270.09,
			1272.16,
			1273.188,
			1275.237,
			1277.313,
			1280.446,
			1282.539,
			1285.684,
			1286.737,
			1287.792,
			1290.972,
			1293.106,
			1296.328,
			1298.497,
			1300.68,
			1301.776,
			1303.983,
			1305.092,
			1306.202,
			1309.562,
			1310.688,
			1311.817,
			1315.243,
			1317.544,
			1321.057,
			1323.41,
			1325.783,
			1326.973,
			1329.377,
			1330.584,
			1333.008,
			1335.446,
			1339.125,
			1341.593,
			1345.311,
			1346.555,
			1347.8,
			1351.533,
			1354.018,
			1357.742,
			1360.22,
			1362.706,
			1363.941,
			1366.413,
			1367.652,
			1370.123,
			1372.598,
			1373.836,
			1376.313,
			1378.797,
			1381.284,
			1383.78,
			1385.029,
			1387.535,
			1390.047,
			1391.305,
			1393.807,
			1395.071,
			1397.605,
			1400.146,
			1403.973,
			1406.533,
			1410.388,
			1411.677,
			1412.968,
			1416.852,
			1419.452,
			1423.356,
			1425.966,
			1428.583,
			1429.895,
			1432.525,
			1433.842,
			1436.485,
			1439.134,
			1440.463,
			1443.125,
			1445.794,
			1449.813,
			1452.501,
			1456.546,
			1457.898,
			1459.252,
			1463.322,
			1466.044,
			1468.773,
			1472.88,
			1475.626,
			1479.76,
			1481.141,
			1482.525,
			1488.074,
			1489.466,
			1493.649,
			1496.446,
			1499.25,
			1500.654,
			1503.467,
			1506.286,
			1507.698,
			1510.528,
			1511.945,
			1514.785,
			1517.63,
			1521.912,
			1524.775,
			1529.081,
			1530.52,
			1531.96,
			1536.293,
			1539.19,
			1543.548,
			1546.462,
			1550.844,
			1553.774,
			1555.241,
			1556.711,
			1561.128,
			1562.604,
			1564.081,
			1568.521,
			1571.49,
			1575.954,
			1578.938,
			1581.929,
			1583.427,
			1586.427,
			1587.929,
			1590.938,
			1593.951,
			1598.483,
			1601.511,
			1606.064,
			1607.585,
			1609.108,
			1613.684,
			1616.742,
			1621.341,
			1624.413,
			1627.491,
			1629.032,
			1632.119,
			1633.664,
			1636.758,
			1639.858,
			1641.411,
			1644.519,
			1647.633,
			1650.752,
			1653.876,
			1655.441,
			1660.141,
			1661.71,
			1663.281,
			1666.426,
			1668,
			1671.153,
			1674.31,
			1679.056,
			1682.225,
			1686.99,
			1688.58,
			1690.171,
			1694.947,
			1698.135,
			1702.924,
			1706.122,
			1709.325,
			1710.928,
			1714.138,
			1715.744,
			1718.959,
			1722.18,
			1723.791,
			1727.017,
			1730.248,
			1735.103,
			1736.723,
			1738.345,
			1741.591,
			1744.841,
			1746.469,
			1751.357,
			1754.623,
			1757.892,
			1762.805,
			1766.086,
			1771.016,
			1772.661,
			1774.308,
			1779.255,
			1782.558,
			1787.521,
			1790.835,
			1795.815,
			1799.14,
			1802.469,
			1804.136,
			1807.471,
			1809.141,
			1812.483,
			1815.829,
			1820.856,
			1824.212,
			1829.255,
			1830.938,
			1832.621,
			1837.679,
			1841.055,
			1846.125,
			1849.509,
			1852.896,
			1854.592,
			1857.986,
			1859.685,
			1861.385,
			1868.194,
			1869.899,
			1875.018,
			1878.436,
			1881.859,
			1883.572,
			1887,
			1890.433,
			1892.15,
			1897.31,
			1900.754,
			1904.202,
			1909.382,
			1912.839,
			1918.032,
			1919.765,
			1921.5,
			1926.708,
			1930.184,
			1935.402,
			1938.885,
			1942.371,
			1944.115,
			1947.606,
			1949.353,
			1952.849,
			1956.348,
			1958.099,
			1961.604,
			1965.111,
			1970.378,
			1972.135,
			1973.893,
			1977.41,
			1980.93,
			1982.692,
			1986.217,
			1987.98,
			1991.51,
			1995.042,
			2000.347,
			2003.887,
			2009.19,
			2010.95,
			2012.707,
			2017.933,
			2021.374,
			2026.462,
			2029.805,
			2033.109,
			2034.746,
			2037.989,
			2039.596,
			2042.781,
			2045.928,
			2047.487,
			2050.576,
			2053.627,
			2058.133,
			2059.617,
			2061.092,
			2065.46,
			2066.897,
			2068.325,
			2071.153,
			2072.552,
			2075.321,
			2078.052,
			2082.076,
			2084.709,
			2088.587,
			2089.861,
			2091.125,
			2094.861,
			2096.088,
			2097.305,
			2100.902,
			2103.256,
			2105.573,
			2106.718,
			2108.974,
			2110.087,
			2112.305,
			2114.469,
			2115.538,
			2117.65,
			2119.731,
			2122.79,
			2123.792,
			2124.786,
			2127.717,
			2128.678,
			2129.63,
			2132.437,
			2134.27,
			2136.967,
			2138.736,
			2140.486,
			2141.355,
			2143.084,
			2143.947,
			2145.668,
			2147.389,
			2148.253,
			2149.12,
			2151.727,
			2153.469,
			2155.216,
			2156.089,
			2157.834,
			2159.584,
			2160.459,
			2162.208,
			2163.082,
			2164.837,
			2166.591,
			2169.224,
			2170.979,
			2173.611,
			2174.49,
			2175.368,
			2177.998,
			2179.754,
			2182.384,
			2184.137,
			2185.889,
			2186.765,
			2188.515,
			2189.392,
			2191.143,
			2193.771,
			2194.651,
			2197.287,
			2199.046,
			2201.689,
			2203.452,
			2205.224,
			2206.11,
			2207.884,
			2208.773,
			2210.56,
			2212.35,
			2215.052,
			2216.864,
			2219.598,
			2220.516,
			2221.434,
			2224.198,
			2226.054,
			2228.842,
			2230.713,
			2233.52,
			2235.398,
			2236.337,
			2238.215,
			2240.097,
			2241.038,
			2242.921,
			2244.806,
			2247.637,
			2248.582,
			2249.53,
			2252.382,
			2253.335,
			2254.289,
			2256.201,
			2257.159,
			2259.081,
			2261.012,
			2263.922,
			2265.871,
			2268.807,
			2269.79,
			2270.773,
			2273.741,
			2274.733,
			2275.727,
			2278.728,
			2280.735,
			2282.752,
			2283.765,
			2285.791,
			2287.824,
			2288.846,
			2291.906,
			2293.958,
			2296.008,
			2299.096,
			2300.126,
			2301.155,
			2304.257,
			2305.293,
			2306.329,
			2309.435,
			2311.508,
			2314.635,
			2316.735,
			2318.846,
			2319.906,
			2322.038,
			2323.108,
			2324.18,
			2327.42,
			2328.506,
			2329.594,
			2332.882,
			2335.088,
			2337.314,
			2338.43,
			2340.656,
			2342.917,
			2344.051,
			2346.331,
			2347.475,
			2349.773,
			2352.081,
			2355.563,
			2357.896,
			2361.412,
			2362.589,
			2363.768,
			2367.32,
			2369.7,
			2373.287,
			2375.69,
			2378.102,
			2379.311,
			2381.738,
			2382.954,
			2385.395,
			2387.844,
			2389.072,
			2391.536,
			2394.008,
			2397.734,
			2398.981,
			2400.23,
			2402.735,
			2405.249,
			2406.509,
			2410.304,
			2412.844,
			2415.394,
			2419.234,
			2421.806,
			2425.678,
			2426.972,
			2428.269,
			2432.169,
			2434.778,
			2438.695,
			2441.312,
			2443.936,
			2445.251,
			2447.885,
			2449.205,
			2451.85,
			2454.499,
			2455.827,
			2458.485,
			2461.15,
			2465.159,
			2466.499,
			2467.84,
			2470.529,
			2473.224,
			2474.574,
			2478.635,
			2481.35,
			2484.071,
			2488.164,
			2490.899,
			2495.014,
			2496.389,
			2497.765,
			2501.904,
			2504.674,
			2508.847,
			2511.641,
			2515.85,
			2518.655,
			2521.472,
			2522.862,
			2525.623,
			2526.993,
			2529.687,
			2532.368,
			2536.328,
			2538.953,
			2542.818,
			2544.098,
			2545.375,
			2549.192,
			2551.726,
			2555.506,
			2558.02,
			2560.515,
			2564.249,
			2565.489,
			2567.966,
			2570.444,
			2571.682,
			2572.918,
			2576.639,
			2579.121,
			2581.606,
			2582.847,
			2585.351,
			2587.852,
			2589.12,
			2592.934,
			2595.517,
			2598.104,
			2602.04,
			2604.65,
			2608.61,
			2609.958,
			2611.285,
			2615.276,
			2617.943,
			2621.897,
			2624.524,
			2628.449,
			2631.064,
			2632.374,
			2634.994,
			2637.618,
			2638.931,
			2641.55,
			2644.173,
			2648.091,
			2650.69,
			2653.285,
			2655.864,
			2657.151,
			2659.718,
			2660.97,
			2663.514,
			2666.031,
			2669.766,
			2672.224,
			2675.864,
			2677.065,
			2678.259,
			2681.828,
			2684.176,
			2687.689,
			2689.995,
			2693.41,
			2695.647,
			2696.754,
			2698.941,
			2701.119,
			2702.188,
			2704.304,
			2706.428,
			2709.583,
			2711.726,
			2714.928,
			2715.997,
			2717.064,
			2720.249,
			2722.339,
			2725.442,
			2727.457,
			2729.466,
			2732.418,
			2733.396,
			2734.374,
			2737.264,
			2739.18,
			2742.017,
			2743.899,
			2745.77,
			2746.698,
			2748.556,
			2750.415,
			2751.34,
			2754.12,
			2755.98,
			2757.845,
			2760.654,
			2761.596,
			2762.54,
			2765.381,
			2766.336,
			2767.292,
			2770.174,
			2772.109,
			2774.05,
			2776.975,
			2778.93,
			2781.869,
			2782.85,
			2783.83,
			2786.773,
			2787.752,
			2788.732,
			2791.668,
			2793.623,
			2795.571,
			2796.544,
			2798.486,
			2800.421,
			2801.387,
			2803.313,
			2804.273,
			2806.188,
			2808.099,
			2810.955,
			2812.851,
			2815.686,
			2816.631,
			2817.576,
			2820.406,
			2822.291,
			2825.115,
			2827,
			2828.886,
			2829.831,
			2831.721,
			2832.667,
			2834.563,
			2836.467,
			2837.429,
			2839.359,
			2841.296,
			2843.241,
			2846.167,
			2848.131,
			2850.078,
			2851.056,
			2853.011,
			2853.987,
			2855.93,
			2857.883,
			2860.766,
			2862.681,
			2865.553,
			2866.506,
			2867.461,
			2870.316,
			2872.238,
			2875.224,
			2877.219,
			2880.288,
			2882.348,
			2884.426,
			2885.468,
			2887.555,
			2888.596,
			2890.68,
			2892.761,
			2895.854,
			2897.907,
			2900.92,
			2901.931,
			2902.933,
			2904.934,
			2905.93,
			2907.923,
			2909.895,
			2912.868,
			2914.852,
			2917.845,
			2918.846,
			2919.849,
			2922.857,
			2924.899,
			2927.957,
			2930.008,
			2932.07,
			2933.107,
			2935.198,
			2936.247,
			2938.356,
			2940.486,
			2941.553,
			2943.701,
			2945.865,
			2949.134,
			2951.331,
			2954.648,
			2955.76,
			2956.873,
			2960.236,
			2962.491,
			2965.9,
			2968.188,
			2970.465,
			2973.922,
			2975.076,
			2976.233,
			2979.73,
			2982.052,
			2985.54,
			2987.87,
			2990.185,
			2991.353,
			2993.695,
			2996.045,
			2997.223,
			2999.584,
			3000.769,
			3003.146,
			3005.533,
			3009.131,
			3011.544,
			3015.18,
			3016.397,
			3017.616,
			3021.288,
			3023.747,
			3027.452,
			3029.933,
			3032.422,
			3033.67,
			3036.173,
			3037.428,
			3039.945,
			3042.472,
			3043.739,
			3045.008,
			3048.828,
			3052.667,
			3055.236,
			3057.812,
			3060.395,
			3061.689,
			3064.283,
			3065.583,
			3068.188,
			3072.1,
			3074.712,
			3077.331,
			3081.275,
			3082.594,
			3083.916,
			3087.889,
			3090.547,
			3094.547,
			3097.222,
			3099.902,
			3101.245,
			3103.935,
			3105.282,
			3107.98,
			3110.685,
			3112.039,
			3114.753,
			3117.474,
			3121.566,
			3122.933,
			3124.302,
			3127.046,
			3129.796,
			3131.174,
			3133.933,
			3135.315,
			3138.084,
			3140.859,
			3145.035,
			3147.828,
			3152.028,
			3153.432,
			3154.837,
			3159.062,
			3161.886,
			3166.135,
			3168.976,
			3173.254,
			3176.114,
			3178.982,
			3180.419,
			3183.299,
			3184.741,
			3187.629,
			3190.524,
			3194.878,
			3197.787,
			3202.163,
			3203.624,
			3205.087,
			3209.485,
			3212.425,
			3216.845,
			3219.799,
			3222.756,
			3227.203,
			3228.688,
			3230.174,
			3234.64,
			3237.625,
			3242.112,
			3245.112,
			3248.116,
			3249.62,
			3252.631,
			3255.648,
			3257.158,
			3260.183,
			3261.698,
			3264.73,
			3267.768,
			3272.333,
			3275.382,
			3279.966,
			3281.497,
			3283.03,
			3287.635,
			3290.713,
			3295.329,
			3298.397,
			3301.449,
			3302.966,
			3305.979,
			3307.473,
			3308.959,
			3313.362,
			3314.81,
			3316.249,
			3320.504,
			3323.292,
			3326.04,
			3327.4,
			3330.088,
			3332.737,
			3334.048,
			3336.64,
			3337.922,
			3340.459,
			3342.958,
			3346.646,
			3349.081,
			3352.63,
			3353.803,
			3354.968,
			3358.426,
			3360.667,
			3363.968,
			3366.129,
			3368.27,
			3369.334,
			3371.449,
			3372.504,
			3374.614,
			3376.712,
			3377.763,
			3379.869,
			3381.981,
			3385.172,
			3387.33,
			3389.495,
			3391.701,
			3392.809,
			3396.169,
			3398.443,
			3400.739,
			3404.234,
			3406.612,
			3410.209,
			3411.427,
			3412.642,
			3416.28,
			3418.706,
			3422.282,
			3424.636,
			3426.953,
			3428.1,
			3430.395,
			3431.519,
			3433.757,
			3435.996,
			3437.104,
			3439.323,
			3441.545,
			3444.874,
			3445.988,
			3447.096,
			3450.432,
			3451.548,
			3452.664,
			3456.023,
			3458.273,
			3460.532,
			3463.938,
			3466.221,
			3469.661,
			3470.812,
			3471.967,
			3475.442,
			3476.605,
			3477.771,
			3481.279,
			3483.629,
			3487.151,
			3489.521,
			3491.9,
			3493.113,
			3495.505,
			3496.704,
			3499.108,
			3501.5,
			3505.136,
			3507.57,
			3511.235,
			3512.461,
			3513.689,
			3517.385,
			3519.859,
			3523.587,
			3526.084,
			3528.589,
			3529.846,
			3532.365,
			3533.628,
			3534.894,
			3538.704,
			3539.981,
			3542.542,
			3545.112,
			3547.692,
			3550.282,
			3551.58,
			3554.183,
			3556.797,
			3558.107,
			3560.73,
			3562.066,
			3564.68,
			3567.333,
			3571.352,
			3574.027,
			3578.058,
			3579.403,
			3580.751,
			3584.799,
			3587.508,
			3591.598,
			3594.347,
			3597.123,
			3598.517,
			3601.314,
			3602.716,
			3605.524,
			3608.337,
			3609.743,
			3612.559,
			3615.367,
			3618.166,
			3620.942,
			3622.317,
			3625.055,
			3627.765,
			3629.113,
			3633.143,
			3635.817,
			3638.486,
			3642.475,
			3645.125,
			3649.08,
			3650.394,
			3651.707,
			3655.63,
			3658.229,
			3662.108,
			3664.674,
			3667.232,
			3668.502,
			3671.039,
			3672.305,
			3674.828,
			3677.35,
			3678.608,
			3681.125,
			3683.642,
			3687.42,
			3689.939,
			3693.725,
			3694.988,
			3696.252,
			3700.052,
			3702.593,
			3706.417,
			3708.954,
			3711.521,
			3715.408,
			3716.7,
			3717.995,
			3721.872,
			3724.482,
			3728.413,
			3731.043,
			3733.68,
			3735.001,
			3737.648,
			3740.301,
			3741.63,
			3744.292,
			3745.624,
			3748.293,
			3750.967,
			3754.987,
			3757.674,
			3761.718,
			3763.068,
			3764.417,
			3768.446,
			3771.104,
			3775.025,
			3777.593,
			3780.124,
			3781.375,
			3783.849,
			3785.072,
			3786.286,
			3789.873,
			3791.052,
			3793.382,
			3795.677,
			3797.934,
			3800.154,
			3801.25,
			3803.412,
			3805.537,
			3806.585,
			3808.653,
			3809.672,
			3811.684,
			3813.658,
			3816.549,
			3818.429,
			3821.181,
			3822.081,
			3822.973,
			3825.597,
			3827.303,
			3829.79,
			3831.403,
			3832.979,
			3833.753,
			3835.274,
			3836.022,
			3837.489,
			3839.625,
			3841.006,
			3842.361,
			3844.376,
			3845.038,
			3845.698,
			3847.022,
			3848.357,
			3849.029,
			3851.061,
			3852.432,
			3853.813,
			3855.902,
			3857.306,
			3859.402,
			3860.099,
			3860.796,
			3862.885,
			3864.246,
			3866.275,
			3867.618,
			3869.598,
			3870.902,
			3872.193,
			3872.833,
			3874.144,
			3874.794,
			3876.083,
			3877.352,
			3879.336,
			3880.009,
			3880.676,
			3882.651,
			3883.329,
			3884.047,
			3886.184,
			3887.601,
			3889.072,
			3891.323,
			3892.823,
			3895.141,
			3895.919,
			3896.698,
			3899.029,
			3900.562,
			3902.864,
			3904.372,
			3905.854,
			3906.594,
			3908.076,
			3909.511,
			3910.212,
			3911.614,
			3912.315,
			3913.72,
			3915.046,
			3917.032,
			3918.362,
			3920.273,
			3920.905,
			3921.538,
			3923.444,
			3924.707,
			3926.597,
			3927.865,
			3929.143,
			3929.784,
			3931.07,
			3931.716,
			3933.012,
			3934.321,
			3934.985,
			3935.65,
			3937.659,
			3939.01,
			3940.392,
			3941.088,
			3942.49,
			3943.903,
			3944.619,
			3946.084,
			3946.822,
			3948.307,
			3949.812,
			3952.127,
			3953.69,
			3956.066,
			3956.866,
			3957.669,
			3960.1,
			3961.733,
			3964.197,
			3965.861,
			3967.537,
			3968.379,
			3970.069,
			3970.909,
			3972.587,
			3974.257,
			3975.085,
			3976.714,
			3978.314,
			3980.644,
			3982.146,
			3983.613,
			3985.044,
			3985.746,
			3987.123,
			3987.799,
			3989.125,
			3990.42,
			3992.308,
			3993.522,
			3995.277,
			3995.851,
			3996.421,
			3998.094,
			3999.165,
			4000.725,
			4001.756,
			4002.782,
			4003.287,
			4004.297,
			4004.802,
			4005.819,
			4006.85,
			4007.366,
			4008.4,
			4009.433,
			4010.98,
			4012.009,
			4013.528,
			4014.028,
			4014.524,
			4015.995,
			4016.966,
			4017.923,
			4019.341,
			4020.281,
			4021.705,
			4022.185,
			4022.662,
			4024.084,
			4025.026,
			4026.464,
			4027.5,
			4028.525,
			4029.035,
			4030.049,
			4031.06,
			4031.602,
			4033.338,
			4034.491,
			4035.644,
			4037.474,
			4038.724,
			4040.567,
			4041.18,
			4041.804,
			4043.666,
			4044.889,
			4046.727,
			4047.961,
			4049.187,
			4049.798,
			4051.021,
			4051.632,
			4052.226,
			4054.592,
			4055.183,
			4056.957,
			4058.087,
			4059.219,
			4059.786,
			4060.926,
			4062.056,
			4062.58,
			4063.631,
			4064.158,
			4065.217,
			4066.287,
			4067.841,
			4068.859,
			4070.407,
			4070.93,
			4071.456,
			4073.023,
			4074.07,
			4075.667,
			4076.751,
			4078.39,
			4079.497,
			4080.058,
			4081.191,
			4082.926,
			4084.103,
			4085.299,
			4087.126,
			4087.743,
			4088.362,
			4089.601,
			4090.839,
			4091.463,
			4093.358,
			4094.644,
			4095.948,
			4097.935,
			4099.293,
			4101.348,
			4102.044,
			4102.744,
			4104.868,
			4106.304,
			4108.491,
			4109.971,
			4111.466,
			4112.22,
			4113.738,
			4114.502,
			4116.041,
			4117.596,
			4118.379,
			4119.954,
			4121.543,
			4123.955,
			4125.578,
			4128.041,
			4128.868,
			4129.697,
			4132.202,
			4133.872,
			4135.547,
			4138.087,
			4139.795,
			4142.383,
			4143.251,
			4144.124,
			4146.776,
			4147.661,
			4148.55,
			4151.228,
			4153.041,
			4155.775,
			4157.626,
			4158.548,
			4160.406,
			4162.278,
			4163.219,
			4165.11,
			4167.013,
			4169.889,
			4171.818,
			4174.733,
			4175.71,
			4176.69,
			4179.646,
			4181.629,
			4184.623,
			4186.63,
			4188.647,
			4189.661,
			4191.696,
			4192.717,
			4194.768,
			4197.864,
			4198.896,
			4202,
			4204.085,
			4207.232,
			4209.341,
			4211.457,
			4212.52,
			4214.647,
			4215.711,
			4217.85,
			4219.99,
			4223.205,
			4225.346,
			4228.565,
			4229.64,
			4230.713,
			4233.962,
			4236.135,
			4239.464,
			4241.694,
			4245.167,
			4247.512,
			4248.727,
			4251.161,
			4253.646,
			4254.909,
			4256.173,
			4259.912,
			4263.584,
			4264.767,
			4265.951,
			4268.278,
			4270.527,
			4271.655,
			4273.862,
			4274.962,
			4277.169,
			4279.361,
			4282.672,
			4284.88,
			4288.21,
			4289.333,
			4290.459,
			4293.854,
			4296.143,
			4299.599,
			4301.923,
			4304.259,
			4305.434,
			4307.791,
			4308.973,
			4311.352,
			4313.74,
			4314.939,
			4317.345,
			4319.742,
			4323.39,
			4325.839,
			4329.538,
			4330.776,
			4332.017,
			4334.504,
			4335.749,
			4338.243,
			4341.994,
			4344.502,
			4347.014,
			4350.795,
			4352.059,
			4353.325,
			4357.133,
			4359.68,
			4363.515,
			4366.082,
			4368.657,
			4369.948,
			4372.535,
			4375.13,
			4376.431,
			4379.034,
			4380.334,
			4382.941,
			4385.555,
			4389.49,
			4392.123,
			4396.085,
			4397.409,
			4398.735,
			4402.726,
			4405.394,
			4409.409,
			4412.094,
			4414.786,
			4416.135,
			4418.837,
			4420.191,
			4421.547,
			4425.626,
			4426.99,
			4428.355,
			4432.462,
			4435.208,
			4439.342,
			4442.107,
			4444.879,
			4446.269,
			4449.053,
			4450.449,
			4453.246,
			4456.049,
			4460.267,
			4463.087,
			4467.328,
			4468.745,
			4470.164,
			4474.433,
			4477.288,
			4481.583,
			4484.457,
			4487.336,
			4488.777,
			4491.665,
			4493.11,
			4496.008,
			4498.912,
			4500.365,
			4503.276,
			4506.193,
			4510.58,
			4512.045,
			4513.512,
			4516.448,
			4519.391,
			4520.863,
			4523.813,
			4525.291,
			4528.251,
			4531.216,
			4535.675,
			4538.656,
			4543.141,
			4544.639,
			4546.139,
			4550.646,
			4552.15,
			4553.657,
			4558.186,
			4561.214,
			4565.767,
			4568.811,
			4570.334,
			4573.386,
			4576.443,
			4577.975,
			4581.042,
			4584.114,
			4588.731,
			4591.815,
			4596.449,
			4597.997,
			4599.545,
			4602.646,
			4604.199,
			4607.309,
			4610.424,
			4615.105,
			4618.233,
			4622.934,
			4624.504,
			4626.075,
			4630.797,
			4632.374,
			4633.952,
			4638.696,
			4641.867,
			4645.042,
			4646.632,
			4649.817,
			4653.007,
			4654.604,
			4657.797,
			4659.396,
			4662.596,
			4665.8,
			4670.614,
			4673.829,
			4678.658,
			4680.27,
			4681.882,
			4686.728,
			4689.963,
			4694.826,
			4698.071,
			4701.322,
			4706.206,
			4707.834,
			4709.465,
			4714.36,
			4715.993,
			4719.262,
			4722.535,
			4725.809,
			4729.087,
			4730.727,
			4734.01,
			4737.298,
			4738.943,
			4742.24,
			4743.89,
			4747.194,
			4750.503,
			4755.476,
			4758.797,
			4763.785,
			4765.45,
			4767.115,
			4772.118,
			4775.459,
			4780.479,
			4783.829,
			4787.18,
			4788.854,
			4792.195,
			4793.858,
			4797.169,
			4800.453,
			4802.082,
			4805.31,
			4808.5,
			4813.21,
			4816.302,
			4819.354,
			4822.372,
			4823.867,
			4828.3,
			4831.208,
			4834.08,
			4838.316,
			4841.094,
			4845.189,
			4846.536,
			4847.873,
			4851.826,
			4854.414,
			4858.226,
			4860.72,
			4864.392,
			4866.795,
			4867.983,
			4870.335,
			4872.653,
			4873.798,
			4876.06,
			4878.285,
			4881.55,
			4883.68,
			4886.821,
			4887.844,
			4888.858,
			4890.862,
			4891.848,
			4893.795,
			4895.711,
			4898.492,
			4900.324,
			4903.044,
			4903.939,
			4904.833,
			4907.516,
			4909.301,
			4911.964,
			4913.729,
			4915.472,
			4916.334,
			4918.053,
			4919.744,
			4920.582,
			4923.078,
			4924.73,
			4926.374,
			4928.832,
			4929.648,
			4930.462,
			4932.895,
			4933.702,
			4934.507,
			4936.912,
			4938.505,
			4940.883,
			4942.464,
			4944.824,
			4946.393,
			4947.176,
			4947.959,
			4951.081,
			4951.859,
			4954.203,
			4955.762,
			4957.321,
			4958.103,
			4959.67,
			4961.237,
			4962.021,
			4963.595,
			4964.385,
			4965.964,
			4967.546,
			4969.934,
			4971.529,
			4973.934,
			4974.739,
			4975.545,
			4977.966,
			4978.775,
			4979.589,
			4982.035,
			4983.667,
			4985.319,
			4986.148,
			4987.809,
			4988.64,
			4990.327,
			4992.035,
			4992.89,
			4993.747,
			4996.387,
			4998.174,
			4999.975,
			5000.893,
			5002.73,
			5004.571,
			5005.512,
			5007.437,
			5008.398,
			5010.324,
			5012.317,
			5015.309,
			5017.211,
			5020.056,
			5020.98,
			5021.865,
			5024.516,
			5026.27,
			5028.814,
			5030.51,
			5032.191,
			5033.029,
			5034.704,
			5035.543,
			5037.226,
			5038.912,
			5039.756,
			5041.453,
			5043.168,
			5045.75,
			5047.498,
			5050.138,
			5051.913,
			5054.603,
			5056.407,
			5058.217,
			5060.949,
			5062.757,
			5065.484,
			5066.388,
			5067.287,
			5069.941,
			5071.673,
			5074.202,
			5075.842,
			5078.236,
			5079.788,
			5080.553,
			5082.066,
			5083.577,
			5084.321,
			5085.801,
			5087.275,
			5089.488,
			5090.225,
			5090.961,
			5093.172,
			5093.913,
			5094.653,
			5096.872,
			5098.358,
			5100.606,
			5102.103,
			5103.608,
			5105.904,
			5106.669,
			5107.433,
			5109.761,
			5110.545,
			5111.328,
			5113.677,
			5115.259,
			5116.838,
			5117.627,
			5119.211,
			5120.818,
			5121.621,
			5124.024,
			5125.655,
			5127.285,
			5129.729,
			5131.358,
			5133.798,
			5134.611,
			5135.429,
			5137.876,
			5139.506,
			5141.944,
			5143.564,
			5145.185,
			5146.001,
			5147.63,
			5148.443,
			5150.068,
			5152.509,
			5153.32,
			5155.752,
			5157.376,
			5159.806,
			5161.431,
			5163.073,
			5163.892,
			5166.345,
			5167.973,
			5169.596,
			5172.032,
			5173.656,
			5176.085,
			5176.895,
			5177.706,
			5180.13,
			5181.744,
			5184.172,
			5185.785,
			5188.205,
			5189.812,
			5190.615,
			5192.219,
			5193.825,
			5194.626,
			5196.225,
			5197.826,
			5200.235,
			5201.037,
			5201.838,
			5204.252,
			5205.057,
			5205.862,
			5208.282,
			5209.899,
			5211.518,
			5213.956,
			5215.585,
			5218.039,
			5218.863,
			5219.687,
			5222.998,
			5223.837,
			5226.362,
			5228.055,
			5229.771,
			5230.632,
			5232.358,
			5233.226,
			5234.961,
			5236.702,
			5237.576,
			5239.32,
			5241.057,
			5243.67,
			5245.413,
			5248.03,
			5248.905,
			5249.779,
			5252.401,
			5254.15,
			5256.733,
			5258.451,
			5260.162,
			5262.688,
			5263.531,
			5264.374,
			5267.704,
			5268.534,
			5271.019,
			5272.659,
			5275.125,
			5276.748,
			5278.387,
			5279.21,
			5281.707,
			5283.368,
			5285.042,
			5287.569,
			5289.266,
			5291.835,
			5292.7,
			5293.567,
			5296.188,
			5297.96,
			5300.643,
			5302.451,
			5304.278,
			5305.197,
			5307.043,
			5307.977,
			5309.84,
			5311.731,
			5312.684,
			5313.645,
			5316.545,
			5318.495,
			5321.446,
			5323.427,
			5325.418,
			5326.418,
			5328.425,
			5329.431,
			5331.45,
			5333.481,
			5336.547,
			5338.604,
			5341.709,
			5342.75,
			5343.793,
			5346.925,
			5349.014,
			5352.164,
			5354.277,
			5356.4,
			5357.467,
			5359.605,
			5360.679,
			5362.833,
			5364.998,
			5366.084,
			5368.264,
			5370.454,
			5373.758,
			5374.864,
			5375.974,
			5378.202,
			5380.44,
			5381.564,
			5383.82,
			5384.953,
			5387.224,
			5389.504,
			5392.938,
			5395.236,
			5398.696,
			5399.853,
			5401.011,
			5404.499,
			5406.833,
			5410.352,
			5412.708,
			5415.072,
			5416.257,
			5418.634,
			5419.826,
			5422.218,
			5424.619,
			5425.826,
			5428.248,
			5430.682,
			5434.359,
			5436.829,
			5440.558,
			5441.808,
			5443.062,
			5446.839,
			5449.377,
			5453.202,
			5455.769,
			5458.365,
			5462.25,
			5463.549,
			5466.159,
			5468.777,
			5470.091,
			5471.405,
			5475.342,
			5477.994,
			5480.672,
			5482,
			5484.639,
			5487.306,
			5488.665,
			5491.346,
			5492.689,
			5495.38,
			5498.044,
			5502.083,
			5504.781,
			5508.827,
			5510.178,
			5511.529,
			5515.614,
			5518.329,
			5522.413,
			5525.143,
			5527.88,
			5529.25,
			5531.998,
			5533.374,
			5534.752,
			5538.898,
			5540.285,
			5543.063,
			5545.852,
			5550.052,
			5552.862,
			5555.696,
			5558.541,
			5559.972,
			5562.84,
			5564.282,
			5567.173,
			5570.079,
			5574.459,
			5577.396,
			5581.815,
			5583.293,
			5584.771,
			5589.207,
			5592.161,
			5596.589,
			5599.537,
			5603.942,
			5606.876,
			5608.341,
			5611.249,
			5614.176,
			5615.642,
			5618.569,
			5621.525,
			5625.919,
			5627.386,
			5628.83,
			5633.245,
			5634.72,
			5636.196,
			5639.155,
			5640.637,
			5643.605,
			5646.581,
			5651.054,
			5654.044,
			5658.543,
			5660.046,
			5661.551,
			5666.076,
			5669.102,
			5673.652,
			5676.695,
			5679.744,
			5681.27,
			5684.326,
			5687.388,
			5688.921,
			5691.991,
			5693.526,
			5696.602,
			5699.681,
			5704.311,
			5707.403,
			5712.051,
			5713.603,
			5715.156,
			5719.823,
			5722.939,
			5727.623,
			5730.751,
			5733.884,
			5738.592,
			5740.164,
			5741.736,
			5746.46,
			5748.037,
			5749.615,
			5754.357,
			5757.525,
			5760.699,
			5762.288,
			5765.468,
			5768.648,
			5770.239,
			5775.017,
			5778.208,
			5781.405,
			5786.216,
			5789.437,
			5794.273,
			5795.888,
			5797.503,
			5802.355,
			5805.59,
			5810.446,
			5813.687,
			5818.547,
			5821.793,
			5823.418,
			5826.669,
			5829.924,
			5831.553,
			5833.184,
			5838.233
		],
		[
			0,
			0.034,
			0.047,
			0.111,
			0.142,
			0.172,
			0.252,
			0.297,
			0.36,
			0.421,
			0.484,
			0.545,
			0.584,
			0.606,
			0.669,
			0.733,
			0.792,
			0.857,
			0.902,
			0.918,
			0.981,
			1.011,
			1.04,
			1.104,
			1.119,
			1.167,
			1.226,
			1.288,
			1.337,
			1.415,
			1.444,
			1.476,
			1.553,
			1.6,
			1.662,
			1.724,
			1.771,
			1.847,
			1.879,
			1.91,
			1.97,
			1.989,
			2.035,
			2.095,
			2.158,
			2.205,
			2.218,
			2.281,
			2.311,
			2.344,
			2.406,
			2.422,
			2.467,
			2.531,
			2.59,
			2.644,
			2.714,
			2.748,
			2.779,
			2.839,
			2.901,
			2.962,
			3.027,
			3.073,
			3.089,
			3.15,
			3.18,
			3.212,
			3.275,
			3.29,
			3.334,
			3.397,
			3.459,
			3.505,
			3.523,
			3.584,
			3.614,
			3.645,
			3.709,
			3.723,
			3.771,
			3.834,
			3.893,
			3.941,
			4.019,
			4.047,
			4.079,
			4.143,
			4.204,
			4.267,
			4.328,
			4.374,
			4.389,
			4.451,
			4.483,
			4.514,
			4.59,
			4.638,
			4.706,
			4.763,
			4.809,
			4.824,
			4.887,
			4.917,
			4.947,
			5.027,
			5.073,
			5.14,
			5.195,
			5.258,
			5.321,
			5.351,
			5.384,
			5.444,
			5.505,
			5.569,
			5.629,
			5.692,
			5.754,
			5.786,
			5.817,
			5.895,
			5.939,
			6.002,
			6.064,
			6.11,
			6.126,
			6.187,
			6.22,
			6.251,
			6.311,
			6.329,
			6.375,
			6.436,
			6.497,
			6.544,
			6.562,
			6.621,
			6.654,
			6.685,
			6.746,
			6.769,
			6.808,
			6.87,
			6.931,
			6.979,
			6.996,
			7.056,
			7.087,
			7.118,
			7.18,
			7.196,
			7.244,
			7.305,
			7.366,
			7.414,
			7.491,
			7.521,
			7.553,
			7.614,
			7.677,
			7.738,
			7.8,
			7.848,
			7.861,
			7.925,
			7.955,
			7.987,
			8.05,
			8.062,
			8.11,
			8.172,
			8.235,
			8.282,
			8.297,
			8.359,
			8.388,
			8.419,
			8.497,
			8.543,
			8.606,
			8.668,
			8.717,
			8.793,
			8.829,
			8.853,
			8.917,
			8.977,
			9.041,
			9.102,
			9.148,
			9.166,
			9.225,
			9.258,
			9.29,
			9.352,
			9.367,
			9.413,
			9.474,
			9.538,
			9.584,
			9.597,
			9.66,
			9.692,
			9.724,
			9.786,
			9.801,
			9.847,
			9.911,
			9.971,
			10.018,
			10.094,
			10.126,
			10.158,
			10.236,
			10.281,
			10.343,
			10.404,
			10.453,
			10.467,
			10.53,
			10.56,
			10.59,
			10.654,
			10.669,
			10.716,
			10.777,
			10.839,
			10.892,
			10.964,
			10.995,
			11.026,
			11.102,
			11.15,
			11.213,
			11.272,
			11.319,
			11.337,
			11.397,
			11.429,
			11.459,
			11.521,
			11.537,
			11.584,
			11.646,
			11.708,
			11.752,
			11.77,
			11.83,
			11.864,
			11.894,
			11.955,
			11.971,
			12.018,
			12.08,
			12.142,
			12.188,
			12.267,
			12.327,
			12.391,
			12.452,
			12.519,
			12.576,
			12.623,
			12.637,
			12.701,
			12.729,
			12.761,
			12.839,
			12.884,
			12.952,
			13.011,
			13.057,
			13.072,
			13.134,
			13.165,
			13.195,
			13.257,
			13.272,
			13.319,
			13.381,
			13.443,
			13.507,
			13.567,
			13.6,
			13.63,
			13.692,
			13.754,
			13.814,
			13.877,
			13.923,
			13.94,
			14.002,
			14.033,
			14.063,
			14.126,
			14.142,
			14.188,
			14.25,
			14.312,
			14.357,
			14.373,
			14.435,
			14.467,
			14.497,
			14.561,
			14.576,
			14.622,
			14.684,
			14.746,
			14.792,
			14.87,
			14.901,
			14.93,
			14.992,
			15.014,
			15.054,
			15.118,
			15.179,
			15.241,
			15.304,
			15.334,
			15.366,
			15.445,
			15.489,
			15.553,
			15.612,
			15.677,
			15.739,
			15.769,
			15.8,
			15.863,
			15.925,
			15.985,
			16.047,
			16.11,
			16.172,
			16.202,
			16.234,
			16.296,
			16.359,
			16.422,
			16.483,
			16.528,
			16.544,
			16.606,
			16.637,
			16.667,
			16.731,
			16.746,
			16.792,
			16.854,
			16.914,
			16.962,
			17.04,
			17.075,
			17.102,
			17.164,
			17.227,
			17.289,
			17.35,
			17.398,
			17.412,
			17.473,
			17.505,
			17.538,
			17.613,
			17.659,
			17.722,
			17.785,
			17.832,
			17.846,
			17.908,
			17.939,
			17.97,
			18.048,
			18.095,
			18.156,
			18.218,
			18.279,
			18.343,
			18.374,
			18.404,
			18.467,
			18.529,
			18.59,
			18.652,
			18.714,
			18.776,
			18.807,
			18.84,
			18.9,
			18.916,
			18.962,
			19.026,
			19.087,
			19.147,
			19.211,
			19.242,
			19.272,
			19.336,
			19.351,
			19.396,
			19.459,
			19.52,
			19.567,
			19.644,
			19.675,
			19.705,
			19.769,
			19.83,
			19.894,
			19.954,
			20.017,
			20.079,
			20.11,
			20.141,
			20.204,
			20.217,
			20.266,
			20.326,
			20.389,
			20.436,
			20.451,
			20.512,
			20.544,
			20.576,
			20.636,
			20.653,
			20.7,
			20.761,
			20.823,
			20.87,
			20.948,
			20.979,
			21.009,
			21.071,
			21.087,
			21.133,
			21.199,
			21.258,
			21.303,
			21.32,
			21.379,
			21.411,
			21.443,
			21.505,
			21.521,
			21.567,
			21.629,
			21.691,
			21.738,
			21.816,
			21.847,
			21.878,
			21.954,
			22.002,
			22.064,
			22.124,
			22.188,
			22.248,
			22.279,
			22.31,
			22.373,
			22.388,
			22.435,
			22.501,
			22.558,
			22.622,
			22.684,
			22.712,
			22.745,
			22.808,
			22.821,
			22.868,
			22.93,
			22.992,
			23.056,
			23.117,
			23.147,
			23.18,
			23.24,
			23.304,
			23.366,
			23.426,
			23.489,
			23.551,
			23.584,
			23.614,
			23.675,
			23.69,
			23.737,
			23.799,
			23.862,
			23.907,
			23.925,
			23.985,
			24.016,
			24.049,
			24.111,
			24.125,
			24.17,
			24.235,
			24.296,
			24.342,
			24.419,
			24.452,
			24.481,
			24.543,
			24.606,
			24.669,
			24.728,
			24.775,
			24.792,
			24.855,
			24.885,
			24.917,
			24.978,
			24.992,
			25.039,
			25.102,
			25.164,
			25.21,
			25.226,
			25.289,
			25.322,
			25.351,
			25.428,
			25.472,
			25.537,
			25.599,
			25.645,
			25.722,
			25.753,
			25.784,
			25.845,
			25.907,
			25.971,
			26.032,
			26.078,
			26.095,
			26.154,
			26.187,
			26.217,
			26.281,
			26.294,
			26.342,
			26.406,
			26.467,
			26.513,
			26.591,
			26.621,
			26.652,
			26.729,
			26.777,
			26.839,
			26.9,
			26.947,
			26.962,
			27.024,
			27.055,
			27.085,
			27.146,
			27.164,
			27.21,
			27.271,
			27.334,
			27.395,
			27.459,
			27.488,
			27.519,
			27.583,
			27.597,
			27.643,
			27.707,
			27.768,
			27.816,
			27.892,
			27.924,
			27.953,
			28.032,
			28.079,
			28.14,
			28.202,
			28.249,
			28.264,
			28.326,
			28.358,
			28.388,
			28.451,
			28.467,
			28.513,
			28.575,
			28.635,
			28.684,
			28.698,
			28.76,
			28.792,
			28.822,
			28.885,
			28.9,
			28.946,
			29.007,
			29.071,
			29.131,
			29.196,
			29.225,
			29.256,
			29.32,
			29.379,
			29.446,
			29.504,
			29.551,
			29.567,
			29.63,
			29.66,
			29.689,
			29.753,
			29.768,
			29.814,
			29.876,
			29.939,
			30.001,
			30.061,
			30.093,
			30.123,
			30.202,
			30.248,
			30.311,
			30.371,
			30.419,
			30.498,
			30.528,
			30.559,
			30.637,
			30.683,
			30.745,
			30.808,
			30.854,
			30.869,
			30.931,
			30.962,
			30.992,
			31.054,
			31.071,
			31.118,
			31.18,
			31.242,
			31.288,
			31.366,
			31.396,
			31.427,
			31.49,
			31.552,
			31.613,
			31.673,
			31.737,
			31.799,
			31.831,
			31.861,
			31.923,
			31.939,
			31.984,
			32.047,
			32.11,
			32.171,
			32.233,
			32.264,
			32.294,
			32.357,
			32.371,
			32.42,
			32.481,
			32.542,
			32.589,
			32.667,
			32.696,
			32.728,
			32.807,
			32.852,
			32.914,
			32.977,
			33.024,
			33.038,
			33.101,
			33.131,
			33.162,
			33.226,
			33.239,
			33.287,
			33.35,
			33.411,
			33.457,
			33.473,
			33.536,
			33.571,
			33.597,
			33.658,
			33.674,
			33.72,
			33.785,
			33.846,
			33.893,
			33.969,
			34.001,
			34.03,
			34.092,
			34.155,
			34.217,
			34.278,
			34.326,
			34.341,
			34.404,
			34.433,
			34.467,
			34.527,
			34.541,
			34.589,
			34.652,
			34.712,
			34.761,
			34.776,
			34.836,
			34.869,
			34.899,
			34.963,
			35.024,
			35.086,
			35.148,
			35.194,
			35.27,
			35.301,
			35.334,
			35.411,
			35.456,
			35.518,
			35.583,
			35.643,
			35.704,
			35.736,
			35.768,
			35.829,
			35.845,
			35.891,
			35.955,
			36.016,
			36.079,
			36.14,
			36.171,
			36.202,
			36.263,
			36.325,
			36.388,
			36.45,
			36.496,
			36.512,
			36.574,
			36.605,
			36.636,
			36.713,
			36.761,
			36.822,
			36.885,
			36.928,
			36.945,
			37.007,
			37.037,
			37.071,
			37.148,
			37.193,
			37.256,
			37.318,
			37.378,
			37.441,
			37.471,
			37.504,
			37.567,
			37.629,
			37.691,
			37.751,
			37.799,
			37.814,
			37.876,
			37.907,
			37.937,
			38.001,
			38.014,
			38.063,
			38.124,
			38.187,
			38.232,
			38.249,
			38.31,
			38.342,
			38.373,
			38.434,
			38.451,
			38.495,
			38.56,
			38.619,
			38.683,
			38.745,
			38.774,
			38.807,
			38.884,
			38.93,
			38.993,
			39.055,
			39.101,
			39.117,
			39.177,
			39.21,
			39.241,
			39.302,
			39.317,
			39.363,
			39.427,
			39.489,
			39.535,
			39.551,
			39.613,
			39.643,
			39.675,
			39.735,
			39.757,
			39.799,
			39.86,
			39.921,
			39.985,
			40.045,
			40.077,
			40.109,
			40.169,
			40.187,
			40.234,
			40.295,
			40.356,
			40.402,
			40.419,
			40.48,
			40.51,
			40.541,
			40.603,
			40.62,
			40.668,
			40.729,
			40.79,
			40.836,
			40.853,
			40.916,
			40.947,
			40.977,
			41.039,
			41.101,
			41.164,
			41.223,
			41.271,
			41.286,
			41.347,
			41.378,
			41.412,
			41.473,
			41.489,
			41.536,
			41.596,
			41.658,
			41.706,
			41.721,
			41.784,
			41.821,
			41.845,
			41.907,
			41.921,
			41.97,
			42.03,
			42.093,
			42.139,
			42.218,
			42.246,
			42.277,
			42.342,
			42.404,
			42.466,
			42.527,
			42.573,
			42.588,
			42.65,
			42.681,
			42.711,
			42.791,
			42.835,
			42.897,
			42.961,
			43.021,
			43.084,
			43.114,
			43.147,
			43.209,
			43.224,
			43.271,
			43.334,
			43.395,
			43.458,
			43.52,
			43.55,
			43.582,
			43.643,
			43.706,
			43.768,
			43.83,
			43.892,
			43.953,
			43.984,
			44.014,
			44.078,
			44.093,
			44.14,
			44.201,
			44.263,
			44.309,
			44.325,
			44.388,
			44.419,
			44.45,
			44.511,
			44.526,
			44.572,
			44.635,
			44.696,
			44.744,
			44.822,
			44.852,
			44.884,
			44.945,
			44.961,
			45.006,
			45.07,
			45.131,
			45.177,
			45.192,
			45.254,
			45.287,
			45.318,
			45.396,
			45.44,
			45.506,
			45.564,
			45.611,
			45.627,
			45.689,
			45.721,
			45.752,
			45.814,
			45.874,
			45.942,
			46,
			46.047,
			46.061,
			46.124,
			46.155,
			46.185,
			46.248,
			46.263,
			46.31,
			46.372,
			46.434,
			46.481,
			46.495,
			46.558,
			46.587,
			46.619,
			46.683,
			46.695,
			46.745,
			46.806,
			46.867,
			46.914,
			46.991,
			47.021,
			47.054,
			47.129,
			47.177,
			47.24,
			47.302,
			47.349,
			47.364,
			47.426,
			47.456,
			47.489,
			47.549,
			47.564,
			47.612,
			47.672,
			47.736,
			47.782,
			47.796,
			47.859,
			47.89,
			47.921,
			48.004,
			48.045,
			48.107,
			48.171,
			48.217,
			48.293,
			48.324,
			48.354,
			48.418,
			48.479,
			48.543,
			48.604,
			48.651,
			48.666,
			48.729,
			48.759,
			48.788,
			48.851,
			48.868,
			48.914,
			48.976,
			49.039,
			49.085,
			49.097,
			49.161,
			49.193,
			49.224,
			49.301,
			49.347,
			49.411,
			49.471,
			49.535,
			49.597,
			49.626,
			49.659,
			49.735,
			49.781,
			49.844,
			49.907,
			49.969,
			50.03,
			50.066,
			50.093,
			50.154,
			50.169,
			50.216,
			50.279,
			50.339,
			50.403,
			50.463,
			50.496,
			50.527,
			50.588,
			50.651,
			50.712,
			50.773,
			50.821,
			50.896,
			50.929,
			50.961,
			51.023,
			51.037,
			51.085,
			51.146,
			51.209,
			51.254,
			51.271,
			51.334,
			51.364,
			51.395,
			51.472,
			51.518,
			51.578,
			51.643,
			51.703,
			51.767,
			51.797,
			51.829,
			51.891,
			51.951,
			52.013,
			52.076,
			52.14,
			52.201,
			52.23,
			52.264,
			52.325,
			52.341,
			52.387,
			52.448,
			52.512,
			52.572,
			52.635,
			52.666,
			52.696,
			52.759,
			52.775,
			52.821,
			52.882,
			52.945,
			53.008,
			53.068,
			53.101,
			53.129,
			53.209,
			53.254,
			53.317,
			53.378,
			53.441,
			53.501,
			53.534,
			53.565,
			53.627,
			53.643,
			53.689,
			53.752,
			53.812,
			53.874,
			53.938,
			53.967,
			53.999,
			54.076,
			54.124,
			54.19,
			54.245,
			54.308,
			54.371,
			54.402,
			54.433,
			54.509,
			54.556,
			54.618,
			54.682,
			54.727,
			54.743,
			54.806,
			54.837,
			54.868,
			54.945,
			54.991,
			55.058,
			55.113,
			55.161,
			55.176,
			55.239,
			55.269,
			55.301,
			55.362,
			55.425,
			55.486,
			55.55,
			55.611,
			55.672,
			55.704,
			55.736,
			55.796,
			55.814,
			55.858,
			55.921,
			55.984,
			56.03,
			56.044,
			56.107,
			56.139,
			56.168,
			56.23,
			56.252,
			56.292,
			56.357,
			56.417,
			56.463,
			56.54,
			56.572,
			56.604,
			56.666,
			56.727,
			56.79,
			56.852,
			56.898,
			56.914,
			56.976,
			57.007,
			57.038,
			57.099,
			57.114,
			57.162,
			57.224,
			57.286,
			57.346,
			57.409,
			57.44,
			57.472,
			57.534,
			57.548,
			57.596,
			57.657,
			57.72,
			57.767,
			57.844,
			57.876,
			57.905,
			57.967,
			58.029,
			58.095,
			58.154,
			58.216,
			58.276,
			58.314,
			58.339,
			58.401,
			58.418,
			58.464,
			58.526,
			58.588,
			58.649,
			58.712,
			58.744,
			58.773,
			58.835,
			58.852,
			58.898,
			58.959,
			59.021,
			59.084,
			59.145,
			59.178,
			59.208,
			59.269,
			59.332,
			59.393,
			59.455,
			59.502,
			59.518,
			59.58,
			59.61,
			59.642,
			59.702,
			59.718,
			59.767,
			59.827,
			59.889,
			59.936,
			60.012,
			60.045,
			60.077,
			60.138,
			60.2,
			60.261,
			60.324,
			60.374,
			60.448,
			60.477,
			60.508,
			60.572,
			60.634,
			60.696,
			60.759,
			60.805,
			60.821,
			60.883,
			60.914,
			60.945,
			61.007,
			61.02,
			61.068,
			61.129,
			61.192,
			61.237,
			61.314,
			61.347,
			61.379,
			61.456,
			61.502,
			61.565,
			61.626,
			61.671,
			61.688,
			61.751,
			61.779,
			61.811,
			61.875,
			61.89,
			61.935,
			61.997,
			62.061,
			62.122,
			62.184,
			62.214,
			62.246,
			62.308,
			62.325,
			62.37,
			62.438,
			62.493,
			62.539,
			62.617,
			62.648,
			62.681,
			62.743,
			62.805,
			62.867,
			62.927,
			62.976,
			62.991,
			63.053,
			63.084,
			63.113,
			63.176,
			63.191,
			63.238,
			63.301,
			63.364,
			63.407,
			63.425,
			63.485,
			63.519,
			63.547,
			63.611,
			63.626,
			63.674,
			63.735,
			63.795,
			63.843,
			63.921,
			63.951,
			63.981,
			64.045,
			64.106,
			64.168,
			64.231,
			64.293,
			64.355,
			64.386,
			64.418,
			64.479,
			64.498,
			64.539,
			64.606,
			64.664,
			64.727,
			64.788,
			64.821,
			64.851,
			64.928,
			64.976,
			65.039,
			65.099,
			65.159,
			65.222,
			65.253,
			65.285,
			65.345,
			65.409,
			65.472,
			65.533,
			65.58,
			65.594,
			65.657,
			65.688,
			65.719,
			65.781,
			65.796,
			65.842,
			65.903,
			65.967,
			66.012,
			66.092,
			66.121,
			66.153,
			66.216,
			66.276,
			66.338,
			66.401,
			66.447,
			66.464,
			66.525,
			66.561,
			66.586,
			66.648,
			66.665,
			66.711,
			66.774,
			66.836,
			66.88,
			66.895,
			66.96,
			66.989,
			67.022,
			67.083,
			67.099,
			67.145,
			67.207,
			67.268,
			67.316,
			67.394,
			67.423,
			67.454,
			67.533,
			67.578,
			67.641,
			67.702,
			67.748,
			67.766,
			67.828,
			67.858,
			67.889,
			67.951,
			67.968,
			68.014,
			68.075,
			68.138,
			68.2,
			68.261,
			68.293,
			68.324,
			68.401,
			68.445,
			68.51,
			68.57,
			68.634,
			68.694,
			68.726,
			68.758,
			68.82,
			68.88,
			68.944,
			69.006,
			69.051,
			69.069,
			69.13,
			69.161,
			69.191,
			69.255,
			69.27,
			69.317,
			69.379,
			69.441,
			69.486,
			69.502,
			69.563,
			69.596,
			69.626,
			69.688,
			69.747,
			69.811,
			69.875,
			69.921,
			69.997,
			70.028,
			70.061,
			70.122,
			70.139,
			70.185,
			70.246,
			70.309,
			70.371,
			70.431,
			70.463,
			70.493,
			70.556,
			70.571,
			70.617,
			70.686,
			70.74,
			70.789,
			70.865,
			70.895,
			70.926,
			70.991,
			71.052,
			71.114,
			71.177,
			71.222,
			71.238,
			71.3,
			71.33,
			71.36,
			71.424,
			71.44,
			71.486,
			71.548,
			71.611,
			71.655,
			71.671,
			71.735,
			71.764,
			71.797,
			71.858,
			71.872,
			71.921,
			71.981,
			72.044,
			72.092,
			72.168,
			72.197,
			72.229,
			72.292,
			72.354,
			72.415,
			72.479,
			72.524,
			72.541,
			72.603,
			72.633,
			72.664,
			72.727,
			72.746,
			72.789,
			72.851,
			72.911,
			72.959,
			72.973,
			73.035,
			73.067,
			73.097,
			73.175,
			73.223,
			73.286,
			73.346,
			73.408,
			73.47,
			73.502,
			73.531,
			73.593,
			73.656,
			73.719,
			73.78,
			73.828,
			73.843,
			73.904,
			73.935,
			73.967,
			74.027,
			74.043,
			74.091,
			74.153,
			74.214,
			74.277,
			74.338,
			74.37,
			74.4,
			74.463,
			74.524,
			74.587,
			74.649,
			74.695,
			74.771,
			74.806,
			74.834,
			74.898,
			74.958,
			75.02,
			75.083,
			75.129,
			75.144,
			75.206,
			75.238,
			75.268,
			75.329,
			75.345,
			75.394,
			75.456,
			75.518,
			75.58,
			75.639,
			75.673,
			75.704,
			75.78,
			75.828,
			75.888,
			75.952,
			75.997,
			76.011,
			76.074,
			76.105,
			76.135,
			76.197,
			76.213,
			76.261,
			76.324,
			76.385,
			76.431,
			76.446,
			76.508,
			76.541,
			76.571,
			76.634,
			76.647,
			76.693,
			76.756,
			76.818,
			76.868,
			76.944,
			76.974,
			77.004,
			77.08,
			77.127,
			77.192,
			77.253,
			77.299,
			77.314,
			77.376,
			77.408,
			77.439,
			77.517,
			77.564,
			77.624,
			77.688,
			77.734,
			77.748,
			77.81,
			77.842,
			77.872,
			77.95,
			77.997,
			78.059,
			78.122,
			78.167,
			78.246,
			78.276,
			78.307,
			78.368,
			78.429,
			78.497,
			78.555,
			78.617,
			78.679,
			78.711,
			78.74,
			78.804,
			78.819,
			78.864,
			78.93,
			78.988,
			79.036,
			79.052,
			79.114,
			79.143,
			79.174,
			79.237,
			79.299,
			79.361,
			79.422,
			79.486,
			79.547,
			79.578,
			79.609,
			79.672,
			79.733,
			79.796,
			79.856,
			79.902,
			79.92,
			79.981,
			80.012,
			80.043,
			80.106,
			80.12,
			80.167,
			80.231,
			80.291,
			80.337,
			80.416,
			80.446,
			80.477,
			80.54,
			80.601,
			80.664,
			80.726,
			80.772,
			80.788,
			80.85,
			80.881,
			80.913,
			80.974,
			80.992,
			81.034,
			81.097,
			81.16,
			81.206,
			81.222,
			81.285,
			81.313,
			81.344,
			81.408,
			81.422,
			81.47,
			81.53,
			81.593,
			81.641,
			81.718,
			81.75,
			81.779,
			81.841,
			81.904,
			81.967,
			82.028,
			82.074,
			82.088,
			82.151,
			82.183,
			82.214,
			82.275,
			82.291,
			82.337,
			82.4,
			82.463,
			82.525,
			82.584,
			82.617,
			82.649,
			82.71,
			82.725,
			82.772,
			82.834,
			82.896,
			82.942,
			83.019,
			83.056,
			83.081,
			83.143,
			83.206,
			83.267,
			83.331,
			83.376,
			83.393,
			83.453,
			83.485,
			83.514,
			83.578,
			83.594,
			83.641,
			83.701,
			83.763,
			83.812,
			83.889,
			83.92,
			83.948,
			84.013,
			84.075,
			84.136,
			84.197,
			84.246,
			84.323,
			84.354,
			84.385,
			84.46,
			84.508,
			84.571,
			84.63,
			84.679,
			84.695,
			84.755,
			84.788,
			84.819,
			84.895,
			84.942,
			85.006,
			85.066,
			85.128,
			85.19,
			85.221,
			85.251,
			85.329,
			85.377,
			85.439,
			85.501,
			85.546,
			85.563,
			85.623,
			85.656,
			85.685,
			85.764,
			85.809,
			85.873,
			85.935,
			85.98,
			85.995,
			86.057,
			86.09,
			86.12,
			86.183,
			86.196,
			86.245,
			86.306,
			86.368,
			86.416,
			86.492,
			86.523,
			86.555,
			86.617,
			86.679,
			86.741,
			86.802,
			86.863,
			86.927,
			86.957,
			86.987,
			87.067,
			87.113,
			87.18,
			87.237,
			87.283,
			87.297,
			87.36,
			87.391,
			87.423,
			87.501,
			87.547,
			87.609,
			87.67,
			87.717,
			87.793,
			87.825,
			87.857,
			87.918,
			87.979,
			88.043,
			88.105,
			88.152,
			88.166,
			88.228,
			88.258,
			88.291,
			88.354,
			88.368,
			88.414,
			88.478,
			88.54,
			88.586,
			88.663,
			88.692,
			88.726,
			88.787,
			88.85,
			88.91,
			88.972,
			89.018,
			89.096,
			89.128,
			89.16,
			89.22,
			89.239,
			89.284,
			89.347,
			89.408,
			89.469,
			89.53,
			89.561,
			89.594,
			89.656,
			89.671,
			89.717,
			89.779,
			89.842,
			89.889,
			89.964,
			89.997,
			90.027,
			90.106,
			90.151,
			90.212,
			90.275,
			90.32,
			90.338,
			90.4,
			90.431,
			90.462,
			90.539,
			90.585,
			90.648,
			90.709,
			90.771,
			90.834,
			90.865,
			90.895,
			90.956,
			90.974,
			91.019,
			91.081,
			91.144,
			91.191,
			91.268,
			91.302,
			91.329,
			91.39,
			91.453,
			91.52,
			91.577,
			91.64,
			91.702,
			91.73,
			91.764,
			91.826,
			91.841,
			91.886,
			91.948,
			92.012,
			92.058,
			92.073,
			92.136,
			92.167,
			92.197,
			92.258,
			92.274,
			92.322,
			92.384,
			92.444,
			92.493,
			92.57,
			92.601,
			92.633,
			92.709,
			92.757,
			92.819,
			92.879,
			92.926,
			92.943,
			93.002,
			93.035,
			93.064,
			93.127,
			93.142,
			93.189,
			93.252,
			93.312,
			93.364,
			93.439,
			93.469,
			93.498,
			93.561,
			93.577,
			93.622,
			93.687,
			93.747,
			93.793,
			93.872,
			93.902,
			93.935,
			94.011,
			94.058,
			94.123,
			94.181,
			94.228,
			94.245,
			94.306,
			94.337,
			94.368,
			94.43,
			94.444,
			94.492,
			94.554,
			94.616,
			94.662,
			94.739,
			94.77,
			94.803,
			94.865,
			94.925,
			94.989,
			95.048,
			95.096,
			95.112,
			95.175,
			95.206,
			95.236,
			95.297,
			95.315,
			95.359,
			95.427,
			95.485,
			95.546,
			95.607,
			95.638,
			95.671,
			95.73,
			95.747,
			95.795,
			95.856,
			95.918,
			95.98,
			96.042,
			96.074,
			96.104,
			96.166,
			96.228,
			96.289,
			96.353,
			96.397,
			96.415,
			96.476,
			96.508,
			96.539,
			96.602,
			96.614,
			96.661,
			96.725,
			96.787,
			96.833,
			96.846,
			96.909,
			96.941,
			96.972,
			97.034,
			97.051,
			97.095,
			97.158,
			97.221,
			97.283,
			97.345,
			97.376,
			97.407,
			97.47,
			97.487,
			97.529,
			97.592,
			97.655,
			97.717,
			97.778,
			97.809,
			97.842,
			97.903,
			97.918,
			97.965,
			98.026,
			98.088,
			98.151,
			98.212,
			98.242,
			98.273,
			98.336,
			98.351,
			98.399,
			98.46,
			98.523,
			98.569,
			98.646,
			98.676,
			98.707,
			98.77,
			98.787,
			98.831,
			98.893,
			98.958,
			99.003,
			99.018,
			99.08,
			99.112,
			99.143,
			99.205,
			99.221,
			99.268,
			99.33,
			99.392,
			99.438,
			99.513,
			99.551,
			99.576,
			99.639,
			99.701,
			99.763,
			99.825,
			99.872,
			99.949,
			99.98,
			100.01,
			100.072,
			100.089,
			100.137,
			100.198,
			100.26,
			100.306,
			100.32,
			100.381,
			100.412,
			100.445,
			100.506,
			100.521,
			100.57,
			100.629,
			100.694,
			100.738,
			100.815,
			100.846,
			100.879,
			100.942,
			101.003,
			101.065,
			101.129,
			101.174,
			101.188,
			101.251,
			101.281,
			101.314,
			101.375,
			101.391,
			101.437,
			101.498,
			101.562,
			101.622,
			101.684,
			101.716,
			101.748,
			101.824,
			101.87,
			101.931,
			101.995,
			102.043,
			102.118,
			102.151,
			102.181,
			102.243,
			102.304,
			102.367,
			102.429,
			102.492,
			102.554,
			102.585,
			102.617,
			102.678,
			102.693,
			102.739,
			102.802,
			102.863,
			102.924,
			102.987,
			103.018,
			103.048,
			103.111,
			103.126,
			103.173,
			103.236,
			103.297,
			103.344,
			103.42,
			103.452,
			103.484,
			103.546,
			103.608,
			103.672,
			103.731,
			103.777,
			103.793,
			103.854,
			103.888,
			103.918,
			103.995,
			104.041,
			104.103,
			104.166,
			104.211,
			104.228,
			104.29,
			104.322,
			104.35,
			104.428,
			104.475,
			104.539,
			104.598,
			104.661,
			104.723,
			104.755,
			104.784,
			104.864,
			104.91,
			104.972,
			105.034,
			105.081,
			105.095,
			105.157,
			105.189,
			105.221,
			105.28,
			105.296,
			105.344,
			105.407,
			105.468,
			105.53,
			105.591,
			105.621,
			105.654,
			105.714,
			105.737,
			105.779,
			105.838,
			105.901,
			105.947,
			105.964,
			106.027,
			106.056,
			106.087,
			106.15,
			106.165,
			106.21,
			106.273,
			106.335,
			106.383,
			106.399,
			106.461,
			106.492,
			106.522,
			106.584,
			106.601,
			106.646,
			106.707,
			106.77,
			106.818,
			106.894,
			106.926,
			106.957,
			107.035,
			107.08,
			107.143,
			107.205,
			107.251,
			107.267,
			107.327,
			107.359,
			107.39,
			107.453,
			107.468,
			107.515,
			107.577,
			107.639,
			107.701,
			107.762,
			107.825,
			107.886,
			107.946,
			108.009,
			108.072,
			108.119,
			108.196,
			108.227,
			108.258,
			108.335,
			108.381,
			108.445,
			108.507,
			108.567,
			108.63,
			108.66,
			108.692,
			108.754,
			108.771,
			108.814,
			108.878,
			108.941,
			108.986,
			109.002,
			109.066,
			109.096,
			109.127,
			109.204,
			109.251,
			109.312,
			109.373,
			109.422,
			109.5,
			109.529,
			109.56,
			109.623,
			109.638,
			109.684,
			109.745,
			109.809,
			109.86,
			109.871,
			109.931,
			109.964,
			109.995,
			110.073,
			110.119,
			110.182,
			110.244,
			110.29,
			110.367,
			110.397,
			110.429,
			110.492,
			110.553,
			110.613,
			110.678,
			110.723,
			110.739,
			110.801,
			110.831,
			110.863,
			110.939,
			110.986,
			111.049,
			111.11,
			111.172,
			111.234,
			111.266,
			111.295,
			111.373,
			111.42,
			111.484,
			111.544,
			111.607,
			111.669,
			111.7,
			111.731,
			111.793,
			111.854,
			111.921,
			111.978,
			112.042,
			112.104,
			112.135,
			112.166,
			112.228,
			112.242,
			112.289,
			112.352,
			112.414,
			112.458,
			112.476,
			112.537,
			112.568,
			112.599,
			112.662,
			112.723,
			112.785,
			112.846,
			112.893,
			112.971,
			113.001,
			113.031,
			113.111,
			113.156,
			113.22,
			113.281,
			113.328,
			113.342,
			113.404,
			113.435,
			113.468,
			113.529,
			113.543,
			113.592,
			113.653,
			113.714,
			113.763,
			113.839,
			113.87,
			113.901,
			113.964,
			114.025,
			114.087,
			114.15,
			114.196,
			114.272,
			114.304,
			114.334,
			114.413,
			114.46,
			114.521,
			114.584,
			114.645,
			114.707,
			114.739,
			114.77,
			114.846,
			114.892,
			114.955,
			115.017,
			115.064,
			115.142,
			115.173,
			115.205,
			115.264,
			115.326,
			115.39,
			115.451,
			115.497,
			115.515,
			115.577,
			115.606,
			115.637,
			115.698,
			115.716,
			115.761,
			115.823,
			115.886,
			115.946,
			116.01,
			116.046,
			116.072,
			116.135,
			116.149,
			116.195,
			116.259,
			116.319,
			116.38,
			116.443,
			116.475,
			116.504,
			116.567,
			116.63,
			116.691,
			116.754,
			116.801,
			116.816,
			116.877,
			116.91,
			116.94,
			117.001,
			117.017,
			117.064,
			117.126,
			117.189,
			117.235,
			117.251,
			117.31,
			117.344,
			117.373,
			117.436,
			117.452,
			117.498,
			117.56,
			117.622,
			117.668,
			117.747,
			117.777,
			117.808,
			117.869,
			117.931,
			117.994,
			118.056,
			118.106,
			118.118,
			118.179,
			118.21,
			118.242,
			118.304,
			118.32,
			118.367,
			118.429,
			118.49,
			118.538,
			118.614,
			118.645,
			118.677,
			118.739,
			118.801,
			118.863,
			118.924,
			118.97,
			119.047,
			119.08,
			119.111,
			119.172,
			119.189,
			119.234,
			119.296,
			119.357,
			119.404,
			119.421,
			119.482,
			119.513,
			119.544,
			119.607,
			119.622,
			119.667,
			119.729,
			119.792,
			119.838,
			119.917,
			119.947,
			119.978,
			120.039,
			120.102,
			120.164,
			120.228,
			120.273,
			120.288,
			120.351,
			120.379,
			120.411,
			120.475,
			120.491,
			120.537,
			120.599,
			120.662,
			120.723,
			120.784,
			120.814,
			120.847,
			120.91,
			120.924,
			120.971,
			121.033,
			121.094,
			121.158,
			121.218,
			121.249,
			121.28,
			121.344,
			121.404,
			121.467,
			121.53,
			121.591,
			121.652,
			121.684,
			121.714,
			121.778,
			121.792,
			121.838,
			121.901,
			121.963,
			122.009,
			122.025,
			122.087,
			122.118,
			122.149,
			122.21,
			122.23,
			122.273,
			122.334,
			122.396,
			122.442,
			122.522,
			122.551,
			122.584,
			122.643,
			122.707,
			122.769,
			122.832,
			122.878,
			122.892,
			122.953,
			122.986,
			123.018,
			123.08,
			123.093,
			123.141,
			123.203,
			123.263,
			123.312,
			123.39,
			123.42,
			123.451,
			123.529,
			123.576,
			123.638,
			123.7,
			123.76,
			123.823,
			123.853,
			123.885,
			123.946,
			123.964,
			124.01,
			124.071,
			124.133,
			124.18,
			124.195,
			124.257,
			124.292,
			124.318,
			124.395,
			124.442,
			124.506,
			124.567,
			124.63,
			124.691,
			124.721,
			124.752,
			124.816,
			124.877,
			124.938,
			125.001,
			125.063,
			125.124,
			125.157,
			125.188,
			125.248,
			125.263,
			125.311,
			125.372
		]
	],
	'4038_5816_7982': [
		[
			66.137
		],
		[
			0
		],
		[
			0,
			1.265,
			4.077,
			5.485,
			9.72,
			12.552,
			15.39,
			19.658,
			22.512,
			26.805,
			28.239,
			29.674,
			33.99,
			36.874,
			41.213,
			44.114,
			47.022,
			48.479,
			51.397,
			52.858,
			55.786,
			60.187,
			61.656,
			66.074,
			69.027,
			71.985,
			73.466,
			76.433,
			79.405,
			80.894,
			83.877,
			85.371,
			88.365,
			91.365,
			95.876,
			98.889,
			103.418,
			104.93,
			106.443,
			110.99,
			114.027,
			118.593,
			121.644,
			126.229,
			129.292,
			130.826,
			133.898,
			136.975,
			138.515,
			141.599,
			144.687,
			149.326,
			150.875,
			152.425,
			155.528,
			158.636,
			160.191,
			163.303,
			164.858,
			167.96,
			172.578,
			175.624,
			178.635,
			183.083,
			184.547,
			186.001,
			190.303,
			191.718,
			193.122,
			197.275,
			199.992,
			202.67,
			203.992,
			206.607,
			209.182,
			210.454,
			212.972,
			214.218,
			216.68,
			219.108,
			222.686,
			225.034,
			228.512,
			229.648,
			230.776,
			234.108,
			236.29,
			239.512,
			241.635,
			244.794,
			246.896,
			247.948,
			248.999,
			252.153,
			253.208,
			254.265,
			257.441,
			259.578,
			262.793,
			264.948,
			267.102,
			268.181,
			270.342,
			271.421,
			273.586,
			275.764,
			279.051,
			281.269,
			284.658,
			285.805,
			286.95,
			290.454,
			292.798,
			296.319,
			298.63,
			300.875,
			301.998,
			304.175,
			305.241,
			307.374,
			310.53,
			312.632,
			314.739,
			317.915,
			318.981,
			320.049,
			322.194,
			324.352,
			325.436,
			327.611,
			328.703,
			330.895,
			333.096,
			336.41,
			338.63,
			341.971,
			343.089,
			344.208,
			347.58,
			349.836,
			353.215,
			355.485,
			358.924,
			361.21,
			362.357,
			364.634,
			366.937,
			368.092,
			370.41,
			372.756,
			376.249,
			378.604,
			380.977,
			383.358,
			384.556,
			386.958,
			388.162,
			390.581,
			394.227,
			396.669,
			399.12,
			402.811,
			404.044,
			405.279,
			408.997,
			410.24,
			411.484,
			415.229,
			417.736,
			420.249,
			421.508,
			424.033,
			426.566,
			427.836,
			430.382,
			431.659,
			434.219,
			436.791,
			440.672,
			443.275,
			447.231,
			448.547,
			449.87,
			453.846,
			456.512,
			460.534,
			463.233,
			465.938,
			470.002,
			471.356,
			472.71,
			476.77,
			478.123,
			479.476,
			483.523,
			486.214,
			488.897,
			490.239,
			492.919,
			495.601,
			496.944,
			499.626,
			500.973,
			503.659,
			506.343,
			510.361,
			513.034,
			517.028,
			518.358,
			519.687,
			523.656,
			526.3,
			530.264,
			532.904,
			536.864,
			539.503,
			540.822,
			543.462,
			546.104,
			547.426,
			548.749,
			552.717,
			556.685,
			558.008,
			559.332,
			561.98,
			564.631,
			565.958,
			568.618,
			569.95,
			572.624,
			575.305,
			579.345,
			582.048,
			586.117,
			587.478,
			588.841,
			592.94,
			595.682,
			599.806,
			602.565,
			605.331,
			606.719,
			609.498,
			610.89,
			613.681,
			616.456,
			617.857,
			620.664,
			623.476,
			627.705,
			629.117,
			630.53,
			634.774,
			636.19,
			637.607,
			640.439,
			641.851,
			644.662,
			648.827,
			651.561,
			654.256,
			658.23,
			659.536,
			660.833,
			664.668,
			667.179,
			670.876,
			673.295,
			675.679,
			676.859,
			679.191,
			680.345,
			682.624,
			684.865,
			685.971,
			688.156,
			690.303,
			693.454,
			695.509,
			698.522,
			699.508,
			700.484,
			703.36,
			705.232,
			707.975,
			709.763,
			711.517,
			712.382,
			714.085,
			714.922,
			715.75,
			718.973,
			719.756,
			722.071,
			723.573,
			725.792,
			727.252,
			729.425,
			730.87,
			731.591,
			733.024,
			734.455,
			736.597,
			738.006,
			740.115,
			740.817,
			741.521,
			743.633,
			745.037,
			747.157,
			748.583,
			750.006,
			750.717,
			752.175,
			752.919,
			754.405,
			755.89,
			756.644,
			758.204,
			759.76,
			761.315,
			762.886,
			763.67,
			765.237,
			766.802,
			767.581,
			769.136,
			769.912,
			771.464,
			773.077,
			775.489,
			777.15,
			779.764,
			780.635,
			781.504,
			784.04,
			785.72,
			788.083,
			789.602,
			791.117,
			791.863,
			793.315,
			794.039,
			795.486,
			796.916,
			797.601,
			798.968,
			800.335,
			802.372,
			803.706,
			805.71,
			806.379,
			807.064,
			809.177,
			810.589,
			812.744,
			814.209,
			815.679,
			817.91,
			818.661,
			819.414,
			821.684,
			823.217,
			825.534,
			827.091,
			828.66,
			829.448,
			831.032,
			831.828,
			833.429,
			835.041,
			835.852,
			837.483,
			839.124,
			841.609,
			843.263,
			845.783,
			846.63,
			847.475,
			850.023,
			851.737,
			854.329,
			856.065,
			857.822,
			858.695,
			860.438,
			861.307,
			862.174,
			864.783,
			865.649,
			866.514,
			869.109,
			870.831,
			872.552,
			873.416,
			875.138,
			876.851,
			877.709,
			880.276,
			881.978,
			883.703,
			886.279,
			887.998,
			890.587,
			891.447,
			892.307,
			894.97,
			896.741,
			899.459,
			901.28,
			903.113,
			904.037,
			905.884,
			906.808,
			908.652,
			911.416,
			913.229,
			915.004,
			916.782,
			919.325,
			920.993,
			922.663,
			923.476,
			925.106,
			925.925,
			927.571,
			929.236,
			931.758,
			933.465,
			936.063,
			936.934,
			937.809,
			940.451,
			942.229,
			944.907,
			946.716,
			948.536,
			949.45,
			951.287,
			952.211,
			954.069,
			955.942,
			956.883,
			958.773,
			960.677,
			963.554,
			964.518,
			965.485,
			967.429,
			969.387,
			970.371,
			973.342,
			975.338,
			977.347,
			980.381,
			982.412,
			985.454,
			986.463,
			987.467,
			990.442,
			991.419,
			992.387,
			995.239,
			997.095,
			998.914,
			999.81,
			1001.576,
			1003.305,
			1004.156,
			1006.676,
			1008.316,
			1009.941,
			1012.348,
			1013.921,
			1016.274,
			1017.057,
			1017.839,
			1020.198,
			1021.76,
			1024.102,
			1025.66,
			1027.994,
			1029.54,
			1030.309,
			1031.077,
			1033.377,
			1034.138,
			1034.895,
			1037.157,
			1038.663,
			1040.167,
			1040.917,
			1042.415,
			1043.92,
			1044.707,
			1047.043,
			1048.589,
			1050.264,
			1052.807,
			1054.625,
			1057.654,
			1058.66,
			1059.637,
			1062.535,
			1064.396,
			1066.909,
			1068.584,
			1070.221,
			1071.034,
			1072.659,
			1073.472,
			1075.083,
			1076.687,
			1077.489,
			1079.096,
			1080.683,
			1083.065,
			1083.859,
			1084.635,
			1086.177,
			1087.723,
			1088.498,
			1090.025,
			1090.782,
			1092.302,
			1093.828,
			1096.116,
			1097.649,
			1099.974,
			1100.757,
			1101.542,
			1103.911,
			1105.531,
			1107.993,
			1109.657,
			1111.348,
			1112.198,
			1113.908,
			1114.775,
			1116.527,
			1118.29,
			1119.177,
			1120.96,
			1122.756,
			1125.458,
			1127.286,
			1130.053,
			1130.981,
			1131.913,
			1134.728,
			1136.623,
			1139.489,
			1141.417,
			1143.357,
			1146.29,
			1147.274,
			1148.26,
			1152.24,
			1153.243,
			1156.269,
			1158.3,
			1160.343,
			1161.369,
			1163.429,
			1164.463,
			1166.541,
			1169.679,
			1171.779,
			1173.877,
			1177.034,
			1179.151,
			1182.329,
			1183.393,
			1184.459,
			1187.656,
			1189.799,
			1193.031,
			1195.2,
			1198.478,
			1200.68,
			1201.789,
			1202.9,
			1206.254,
			1207.38,
			1208.509,
			1211.914,
			1214.199,
			1216.498,
			1217.653,
			1219.971,
			1222.302,
			1223.471,
			1226.99,
			1229.347,
			1231.71,
			1235.27,
			1237.647,
			1241.227,
			1242.421,
			1243.617,
			1247.215,
			1249.624,
			1253.256,
			1255.689,
			1259.361,
			1261.822,
			1263.056,
			1265.532,
			1268.019,
			1269.265,
			1270.514,
			1274.278,
			1276.8,
			1279.331,
			1280.599,
			1283.145,
			1285.699,
			1286.979,
			1289.544,
			1290.83,
			1293.406,
			1295.991,
			1299.877,
			1302.476,
			1306.383,
			1307.684,
			1308.986,
			1312.904,
			1315.525,
			1319.47,
			1322.109,
			1324.757,
			1326.083,
			1328.742,
			1331.407,
			1332.743,
			1335.42,
			1336.761,
			1339.449,
			1342.144,
			1346.2,
			1347.556,
			1348.914,
			1352.998,
			1354.362,
			1355.729,
			1358.467,
			1359.838,
			1362.587,
			1365.343,
			1369.49,
			1372.264,
			1376.436,
			1377.83,
			1379.227,
			1383.426,
			1386.234,
			1390.459,
			1393.284,
			1396.116,
			1397.535,
			1400.377,
			1401.801,
			1404.654,
			1407.513,
			1408.945,
			1411.814,
			1414.691,
			1419.018,
			1421.911,
			1426.262,
			1427.716,
			1429.171,
			1433.548,
			1436.473,
			1440.874,
			1443.816,
			1448.241,
			1451.199,
			1452.68,
			1454.163,
			1458.621,
			1460.11,
			1461.6,
			1466.082,
			1469.077,
			1473.581,
			1476.592,
			1479.608,
			1481.118,
			1485.658,
			1488.692,
			1491.733,
			1496.305,
			1499.359,
			1503.952,
			1505.486,
			1507.021,
			1511.636,
			1514.72,
			1519.355,
			1522.451,
			1525.553,
			1527.107,
			1530.217,
			1531.775,
			1534.893,
			1539.58,
			1542.712,
			1545.849,
			1548.99,
			1552.137,
			1553.713,
			1556.868,
			1560.027,
			1561.609,
			1566.362,
			1569.536,
			1572.716,
			1577.492,
			1580.677,
			1585.464,
			1587.063,
			1588.662,
			1593.467,
			1596.675,
			1601.495,
			1604.715,
			1607.94,
			1609.554,
			1612.785,
			1614.402,
			1617.64,
			1620.882,
			1622.504,
			1625.753,
			1629.006,
			1633.895,
			1635.526,
			1637.159,
			1640.427,
			1643.7,
			1645.339,
			1648.618,
			1650.26,
			1653.545,
			1656.835,
			1661.779,
			1665.08,
			1670.039,
			1671.695,
			1673.351,
			1678.325,
			1681.646,
			1686.635,
			1689.965,
			1694.969,
			1698.31,
			1701.654,
			1703.328,
			1708.357,
			1711.714,
			1715.076,
			1720.126,
			1723.498,
			1728.564,
			1730.255,
			1731.947,
			1737.028,
			1740.421,
			1745.517,
			1748.92,
			1752.327,
			1757.445,
			1759.152,
			1762.57,
			1765.992,
			1767.705,
			1769.418,
			1774.565,
			1778,
			1781.437,
			1783.157,
			1786.597,
			1790.038,
			1791.759,
			1796.928,
			1800.377,
			1803.827,
			1809.003,
			1812.456,
			1817.642,
			1819.369,
			1821.094,
			1826.245,
			1829.648,
			1834.692,
			1838.007,
			1842.911,
			1846.131,
			1847.727,
			1850.89,
			1855.564,
			1857.103,
			1861.664,
			1866.145,
			1869.085,
			1871.986,
			1874.849,
			1876.265,
			1879.068,
			1880.456,
			1883.2,
			1885.907,
			1889.896,
			1892.506,
			1896.347,
			1897.607,
			1898.857,
			1902.549,
			1904.962,
			1908.516,
			1910.843,
			1913.136,
			1914.287,
			1916.523,
			1917.628,
			1919.792,
			1921.943,
			1923.005,
			1925.121,
			1927.182,
			1930.208,
			1932.177,
			1934.11,
			1936.009,
			1936.949,
			1939.728,
			1941.56,
			1943.389,
			1946.122,
			1947.959,
			1950.707,
			1951.626,
			1952.55,
			1955.318,
			1957.166,
			1959.944,
			1961.796,
			1964.573,
			1966.424,
			1967.344,
			1969.184,
			1971.023,
			1971.941,
			1973.774,
			1975.605,
			1978.359,
			1980.194,
			1982.961,
			1983.887,
			1984.812,
			1987.602,
			1989.474,
			1992.286,
			1994.169,
			1996.05,
			1996.99,
			1998.868,
			1999.806,
			2000.745,
			2004.508,
			2005.449,
			2008.286,
			2010.185,
			2012.091,
			2013.053,
			2014.98,
			2016.914,
			2017.898,
			2019.869,
			2020.856,
			2022.844,
			2024.845,
			2027.856,
			2029.872,
			2032.9,
			2033.912,
			2034.924,
			2037.958,
			2039.969,
			2042.984,
			2044.981,
			2047.979,
			2049.968,
			2050.964,
			2051.961,
			2054.944,
			2055.938,
			2057.932,
			2059.928,
			2062.927,
			2063.93,
			2064.933,
			2066.939,
			2068.958,
			2069.969,
			2073.009,
			2075.046,
			2077.087,
			2080.155,
			2082.207,
			2085.293,
			2086.326,
			2087.362,
			2090.464,
			2092.541,
			2095.648,
			2097.723,
			2100.811,
			2102.871,
			2103.893,
			2105.932,
			2107.972,
			2108.984,
			2111.001,
			2113.021,
			2116.029,
			2118.04,
			2121.056,
			2122.064,
			2123.076,
			2125.112,
			2126.131,
			2128.167,
			2130.234,
			2133.365,
			2135.497,
			2138.71,
			2139.802,
			2140.899,
			2144.205,
			2146.429,
			2149.783,
			2152.028,
			2154.284,
			2155.409,
			2157.666,
			2158.799,
			2161.061,
			2164.468,
			2166.743,
			2169.03,
			2172.474,
			2173.626,
			2174.782,
			2178.261,
			2179.427,
			2180.596,
			2182.942,
			2184.119,
			2186.492,
			2190.078,
			2192.495,
			2194.923,
			2196.143,
			2198.591,
			2199.819,
			2201.045,
			2204.741,
			2207.211,
			2210.93,
			2213.415,
			2215.91,
			2217.162,
			2219.672,
			2222.199,
			2223.468,
			2227.296,
			2229.865,
			2232.453,
			2236.356,
			2238.972,
			2242.906,
			2244.22,
			2245.536,
			2249.48,
			2252.115,
			2256.071,
			2258.701,
			2261.336,
			2262.654,
			2265.295,
			2266.619,
			2269.273,
			2273.271,
			2274.607,
			2278.627,
			2281.314,
			2284.008,
			2285.358,
			2288.061,
			2290.767,
			2292.122,
			2294.829,
			2296.185,
			2298.896,
			2301.608,
			2305.68,
			2308.399,
			2312.483,
			2313.849,
			2315.216,
			2319.332,
			2322.088,
			2326.245,
			2329.03,
			2331.825,
			2333.225,
			2336.034,
			2337.441,
			2340.26,
			2344.502,
			2347.34,
			2350.184,
			2354.461,
			2355.889,
			2357.316,
			2361.582,
			2362.994,
			2364.401,
			2367.193,
			2368.575,
			2371.313,
			2374.013,
			2377.993,
			2380.597,
			2384.43,
			2385.687,
			2386.935,
			2390.619,
			2393.028,
			2396.572,
			2398.889,
			2401.165,
			2402.288,
			2404.506,
			2405.6,
			2407.76,
			2409.881,
			2410.926,
			2412.988,
			2415.013,
			2417.977,
			2418.946,
			2419.906,
			2422.731,
			2423.655,
			2424.569,
			2427.258,
			2429.008,
			2431.569,
			2433.23,
			2434.855,
			2437.23,
			2438.004,
			2438.769,
			2441.744,
			2442.466,
			2444.591,
			2445.96,
			2447.963,
			2449.266,
			2450.545,
			2451.172,
			2452.407,
			2453.017,
			2454.224,
			2455.418,
			2457.165,
			2458.316,
			2460.036,
			2460.609,
			2461.187,
			2462.926,
			2464.082,
			2465.819,
			2467.067,
			2468.938,
			2470.187,
			2470.814,
			2472.144,
			2473.476,
			2474.143,
			2474.811,
			2476.844,
			2478.905,
			2479.595,
			2480.287,
			2481.662,
			2483.028,
			2483.712,
			2485.771,
			2487.141,
			2488.506,
			2490.556,
			2491.891,
			2493.859,
			2494.515,
			2495.171,
			2497.102,
			2498.364,
			2500.259,
			2501.527,
			2502.793,
			2503.427,
			2504.697,
			2505.333,
			2506.617,
			2508.57,
			2509.878,
			2511.191,
			2513.194,
			2513.865,
			2514.525,
			2516.541,
			2517.217,
			2517.891,
			2519.234,
			2519.903,
			2521.239,
			2522.612,
			2524.696,
			2526.085,
			2528.258,
			2528.989,
			2529.719,
			2531.936,
			2533.473,
			2535.779,
			2537.342,
			2539.712,
			2541.294,
			2542.909,
			2543.721,
			2545.344,
			2546.156,
			2547.81,
			2549.474,
			2551.975,
			2553.72,
			2556.329,
			2557.208,
			2558.099,
			2560.763,
			2562.517,
			2565.09,
			2566.802,
			2568.359,
			2570.675,
			2571.445,
			2572.197,
			2574.362,
			2575.806,
			2577.978,
			2579.426,
			2580.876,
			2581.602,
			2583.048,
			2584.492,
			2585.215,
			2586.664,
			2587.385,
			2588.819,
			2590.257,
			2592.428,
			2593.879,
			2596.072,
			2596.807,
			2597.545,
			2599.768,
			2601.264,
			2603.529,
			2605.054,
			2606.594,
			2607.369,
			2608.927,
			2609.711,
			2610.499,
			2612.885,
			2613.685,
			2614.489,
			2616.921,
			2618.562,
			2620.217,
			2621.049,
			2622.719,
			2624.395,
			2625.233,
			2626.922,
			2627.772,
			2629.49,
			2631.22,
			2633.84,
			2635.602,
			2638.269,
			2639.164,
			2640.062,
			2642.776,
			2644.603,
			2647.366,
			2649.22,
			2651.084,
			2652.022,
			2653.905,
			2654.85,
			2656.75,
			2658.662,
			2659.623,
			2661.553,
			2663.495,
			2666.434,
			2667.42,
			2668.408,
			2670.399,
			2672.4,
			2673.406,
			2675.428,
			2676.444,
			2678.483,
			2680.538,
			2683.64,
			2685.729,
			2688.871,
			2689.921,
			2690.973,
			2694.145,
			2696.281,
			2699.502,
			2701.667,
			2703.837,
			2704.925,
			2707.104,
			2708.195,
			2710.377,
			2712.56,
			2713.65,
			2715.833,
			2718.014,
			2721.293,
			2723.486,
			2726.78,
			2727.88,
			2728.978,
			2732.296,
			2734.511,
			2737.859,
			2740.09,
			2742.311,
			2745.632,
			2746.726,
			2747.819,
			2751.079,
			2752.146,
			2753.213,
			2756.38,
			2758.446,
			2760.516,
			2761.534,
			2763.576,
			2764.6,
			2766.691,
			2768.817,
			2769.883,
			2772.073,
			2774.293,
			2777.676,
			2779.958,
			2783.398,
			2784.549,
			2785.703,
			2789.152,
			2791.46,
			2794.919,
			2797.234,
			2800.719,
			2803.05,
			2804.198,
			2805.369,
			2808.891,
			2810.068,
			2811.248,
			2814.788,
			2817.156,
			2819.525,
			2820.714,
			2823.097,
			2825.488,
			2826.688,
			2829.092,
			2830.298,
			2832.72,
			2835.16,
			2838.835,
			2841.294,
			2844.992,
			2846.227,
			2847.465,
			2851.226,
			2853.744,
			2857.537,
			2860.073,
			2862.616,
			2863.89,
			2866.445,
			2867.725,
			2870.292,
			2874.148,
			2876.721,
			2879.294,
			2883.164,
			2884.46,
			2885.756,
			2888.358,
			2890.982,
			2892.297,
			2896.261,
			2898.91,
			2901.562,
			2905.546,
			2908.209,
			2912.214,
			2913.553,
			2914.894,
			2918.926,
			2921.622,
			2925.679,
			2928.393,
			2931.115,
			2932.478,
			2935.211,
			2936.581,
			2939.323,
			2942.071,
			2943.447,
			2946.205,
			2948.969,
			2953.125,
			2955.905,
			2960.086,
			2961.483,
			2962.882,
			2966.502
		],
		[
			0,
			0.031,
			0.063,
			0.093,
			0.171,
			0.219,
			0.283,
			0.343,
			0.389,
			0.467,
			0.497,
			0.529,
			0.59,
			0.651,
			0.714,
			0.776,
			0.823,
			0.839,
			0.901,
			0.932,
			0.963,
			1.041,
			1.085,
			1.148,
			1.21,
			1.256,
			1.272,
			1.334,
			1.364,
			1.398,
			1.457,
			1.473,
			1.519,
			1.583,
			1.646,
			1.69,
			1.768,
			1.8,
			1.83,
			1.908,
			1.955,
			2.019,
			2.079,
			2.14,
			2.203,
			2.233,
			2.264,
			2.325,
			2.341,
			2.389,
			2.452,
			2.514,
			2.559,
			2.574,
			2.634,
			2.668,
			2.697,
			2.759,
			2.775,
			2.824,
			2.889,
			2.948,
			2.993,
			3.072,
			3.101,
			3.131,
			3.195,
			3.21,
			3.256,
			3.319,
			3.38,
			3.426,
			3.442,
			3.504,
			3.535,
			3.567,
			3.628,
			3.645,
			3.692,
			3.752,
			3.815,
			3.862,
			3.939,
			3.971,
			4.001,
			4.062,
			4.126,
			4.188,
			4.25,
			4.311,
			4.373,
			4.405,
			4.434,
			4.498,
			4.512,
			4.559,
			4.622,
			4.682,
			4.744,
			4.808,
			4.838,
			4.87,
			4.932,
			4.947,
			4.992,
			5.055,
			5.116,
			5.178,
			5.242,
			5.273,
			5.304,
			5.364,
			5.428,
			5.489,
			5.551,
			5.598,
			5.613,
			5.676,
			5.705,
			5.738,
			5.815,
			5.862,
			5.925,
			5.985,
			6.032,
			6.048,
			6.108,
			6.142,
			6.172,
			6.233,
			6.25,
			6.295,
			6.357,
			6.42,
			6.464,
			6.542,
			6.573,
			6.605,
			6.683,
			6.73,
			6.793,
			6.854,
			6.917,
			6.979,
			7.009,
			7.039,
			7.101,
			7.118,
			7.164,
			7.227,
			7.288,
			7.349,
			7.41,
			7.442,
			7.473,
			7.534,
			7.551,
			7.598,
			7.661,
			7.723,
			7.769,
			7.846,
			7.876,
			7.908,
			7.971,
			7.985,
			8.031,
			8.093,
			8.155,
			8.206,
			8.217,
			8.279,
			8.311,
			8.342,
			8.405,
			8.419,
			8.467,
			8.529,
			8.59,
			8.651,
			8.714,
			8.744,
			8.775,
			8.837,
			8.901,
			8.964,
			9.023,
			9.071,
			9.147,
			9.179,
			9.209,
			9.272,
			9.287,
			9.333,
			9.397,
			9.459,
			9.505,
			9.519,
			9.582,
			9.612,
			9.644,
			9.706,
			9.723,
			9.768,
			9.832,
			9.892,
			9.938,
			10.016,
			10.047,
			10.077,
			10.14,
			10.2,
			10.264,
			10.327,
			10.388,
			10.449,
			10.482,
			10.512,
			10.575,
			10.589,
			10.635,
			10.697,
			10.761,
			10.807,
			10.822,
			10.883,
			10.915,
			10.947,
			11.007,
			11.023,
			11.07,
			11.131,
			11.195,
			11.242,
			11.318,
			11.35,
			11.38,
			11.441,
			11.504,
			11.566,
			11.628,
			11.676,
			11.689,
			11.754,
			11.782,
			11.815,
			11.875,
			11.892,
			11.938,
			12.001,
			12.061,
			12.108,
			12.125,
			12.187,
			12.217,
			12.249,
			12.309,
			12.332,
			12.372,
			12.436,
			12.497,
			12.544,
			12.62,
			12.652,
			12.683,
			12.746,
			12.808,
			12.868,
			12.931,
			12.976,
			12.993,
			13.054,
			13.084,
			13.116,
			13.177,
			13.195,
			13.239,
			13.308,
			13.364,
			13.411,
			13.489,
			13.52,
			13.552,
			13.613,
			13.676,
			13.737,
			13.8,
			13.845,
			13.861,
			13.923,
			13.954,
			13.984,
			14.063,
			14.108,
			14.172,
			14.234,
			14.295,
			14.355,
			14.419,
			14.48,
			14.497,
			14.541,
			14.605,
			14.668,
			14.714,
			14.789,
			14.822,
			14.852,
			14.914,
			14.978,
			15.039,
			15.101,
			15.147,
			15.163,
			15.224,
			15.257,
			15.288,
			15.35,
			15.365,
			15.412,
			15.472,
			15.534,
			15.583,
			15.598,
			15.659,
			15.689,
			15.721,
			15.783,
			15.8,
			15.845,
			15.909,
			15.97,
			16.032,
			16.092,
			16.125,
			16.156,
			16.216,
			16.28,
			16.345,
			16.403,
			16.455,
			16.466,
			16.528,
			16.558,
			16.59,
			16.65,
			16.666,
			16.712,
			16.774,
			16.838,
			16.885,
			16.961,
			16.992,
			17.022,
			17.1,
			17.148,
			17.211,
			17.271,
			17.318,
			17.396,
			17.425,
			17.458,
			17.533,
			17.58,
			17.643,
			17.706,
			17.75,
			17.767,
			17.829,
			17.859,
			17.891,
			17.955,
			17.967,
			18.017,
			18.076,
			18.14,
			18.187,
			18.264,
			18.296,
			18.325,
			18.388,
			18.45,
			18.511,
			18.575,
			18.62,
			18.637,
			18.698,
			18.729,
			18.759,
			18.822,
			18.838,
			18.884,
			18.944,
			19.008,
			19.055,
			19.071,
			19.131,
			19.162,
			19.193,
			19.272,
			19.318,
			19.38,
			19.442,
			19.489,
			19.564,
			19.597,
			19.628,
			19.691,
			19.751,
			19.814,
			19.874,
			19.923,
			19.938,
			20,
			20.032,
			20.062,
			20.139,
			20.186,
			20.248,
			20.309,
			20.372,
			20.433,
			20.463,
			20.497,
			20.556,
			20.577,
			20.62,
			20.682,
			20.742,
			20.804,
			20.868,
			20.9,
			20.928,
			20.993,
			21.055,
			21.117,
			21.179,
			21.226,
			21.239,
			21.301,
			21.334,
			21.363,
			21.426,
			21.443,
			21.488,
			21.549,
			21.611,
			21.657,
			21.673,
			21.734,
			21.767,
			21.799,
			21.861,
			21.923,
			21.983,
			22.046,
			22.109,
			22.168,
			22.2,
			22.231,
			22.295,
			22.31,
			22.355,
			22.419,
			22.482,
			22.527,
			22.543,
			22.604,
			22.638,
			22.667,
			22.744,
			22.789,
			22.853,
			22.915,
			22.96,
			23.039,
			23.07,
			23.102,
			23.164,
			23.225,
			23.29,
			23.349,
			23.411,
			23.472,
			23.504,
			23.533,
			23.597,
			23.612,
			23.659,
			23.719,
			23.782,
			23.83,
			23.845,
			23.906,
			23.938,
			23.967,
			24.046,
			24.093,
			24.156,
			24.217,
			24.265,
			24.34,
			24.372,
			24.402,
			24.479,
			24.528,
			24.589,
			24.652,
			24.703,
			24.714,
			24.776,
			24.806,
			24.835,
			24.9,
			24.914,
			24.96,
			25.022,
			25.088,
			25.13,
			25.147,
			25.208,
			25.24,
			25.271,
			25.334,
			25.349,
			25.395,
			25.458,
			25.517,
			25.565,
			25.642,
			25.674,
			25.705,
			25.766,
			25.831,
			25.89,
			25.954,
			26,
			26.016,
			26.077,
			26.107,
			26.14,
			26.202,
			26.216,
			26.264,
			26.324,
			26.388,
			26.449,
			26.512,
			26.542,
			26.573,
			26.65,
			26.697,
			26.765,
			26.822,
			26.868,
			26.944,
			26.977,
			27.008,
			27.085,
			27.132,
			27.192,
			27.255,
			27.302,
			27.317,
			27.379,
			27.409,
			27.442,
			27.517,
			27.565,
			27.627,
			27.689,
			27.735,
			27.813,
			27.843,
			27.876,
			27.937,
			27.998,
			28.062,
			28.124,
			28.185,
			28.246,
			28.277,
			28.308,
			28.371,
			28.388,
			28.432,
			28.497,
			28.556,
			28.604,
			28.62,
			28.682,
			28.713,
			28.743,
			28.825,
			28.868,
			28.929,
			28.991,
			29.054,
			29.115,
			29.147,
			29.178,
			29.239,
			29.302,
			29.365,
			29.425,
			29.488,
			29.55,
			29.58,
			29.612,
			29.673,
			29.689,
			29.735,
			29.797,
			29.859,
			29.906,
			29.922,
			29.984,
			30.014,
			30.045,
			30.109,
			30.122,
			30.17,
			30.232,
			30.292,
			30.34,
			30.418,
			30.447,
			30.48,
			30.558,
			30.605,
			30.667,
			30.727,
			30.773,
			30.789,
			30.852,
			30.888,
			30.913,
			30.976,
			30.991,
			31.038,
			31.099,
			31.162,
			31.207,
			31.223,
			31.287,
			31.316,
			31.348,
			31.408,
			31.424,
			31.473,
			31.534,
			31.596,
			31.642,
			31.718,
			31.751,
			31.782,
			31.844,
			31.906,
			31.968,
			32.03,
			32.077,
			32.092,
			32.154,
			32.183,
			32.216,
			32.278,
			32.293,
			32.34,
			32.402,
			32.465,
			32.51,
			32.588,
			32.619,
			32.648,
			32.713,
			32.774,
			32.836,
			32.899,
			32.961,
			33.022,
			33.054,
			33.083,
			33.146,
			33.162,
			33.209,
			33.268,
			33.331,
			33.395,
			33.456,
			33.487,
			33.519,
			33.596,
			33.642,
			33.705,
			33.767,
			33.828,
			33.889,
			33.922,
			33.951,
			34.029,
			34.075,
			34.139,
			34.201,
			34.248,
			34.263,
			34.324,
			34.357,
			34.385,
			34.464,
			34.511,
			34.572,
			34.634,
			34.68,
			34.696,
			34.758,
			34.79,
			34.821,
			34.897,
			34.943,
			35.009,
			35.07,
			35.131,
			35.193,
			35.224,
			35.253,
			35.317,
			35.377,
			35.441,
			35.5,
			35.548,
			35.564,
			35.626,
			35.658,
			35.689,
			35.751,
			35.767,
			35.812,
			35.874,
			35.937,
			35.983,
			36,
			36.06,
			36.091,
			36.121,
			36.183,
			36.201,
			36.248,
			36.309,
			36.372,
			36.417,
			36.494,
			36.527,
			36.558,
			36.634,
			36.68,
			36.743,
			36.806,
			36.867,
			36.93,
			36.96,
			36.99,
			37.072,
			37.114,
			37.177,
			37.239,
			37.285,
			37.364,
			37.395,
			37.426,
			37.485,
			37.548,
			37.61,
			37.672,
			37.721,
			37.797,
			37.828,
			37.86,
			37.921,
			37.937,
			37.981,
			38.044,
			38.108,
			38.152,
			38.169,
			38.231,
			38.263,
			38.293,
			38.372,
			38.416,
			38.48,
			38.54,
			38.604,
			38.664,
			38.696,
			38.728,
			38.79,
			38.852,
			38.913,
			38.976,
			39.038,
			39.1,
			39.134,
			39.16,
			39.239,
			39.283,
			39.347,
			39.41,
			39.472,
			39.532,
			39.563,
			39.595,
			39.658,
			39.672,
			39.718,
			39.78,
			39.843,
			39.889,
			39.968,
			40,
			40.033,
			40.092,
			40.154,
			40.216,
			40.278,
			40.323,
			40.339,
			40.401,
			40.433,
			40.463,
			40.524,
			40.54,
			40.588,
			40.649,
			40.71,
			40.773,
			40.835,
			40.865,
			40.898,
			40.961,
			41.022,
			41.082,
			41.145,
			41.197,
			41.268,
			41.302,
			41.331,
			41.41,
			41.455,
			41.518,
			41.58,
			41.641,
			41.705,
			41.734,
			41.766,
			41.826,
			41.842,
			41.889,
			41.95,
			42.014,
			42.075,
			42.139,
			42.168,
			42.2,
			42.263,
			42.322,
			42.387,
			42.447,
			42.495,
			42.51,
			42.572,
			42.601,
			42.633,
			42.712,
			42.757,
			42.82,
			42.882,
			42.929,
			42.943,
			43.006,
			43.038,
			43.067,
			43.13,
			43.145,
			43.192,
			43.257,
			43.314,
			43.362,
			43.438,
			43.471,
			43.503,
			43.58,
			43.626,
			43.688,
			43.75,
			43.811,
			43.874,
			43.905,
			43.934,
			43.997,
			44.014,
			44.06,
			44.122,
			44.185,
			44.231,
			44.245,
			44.309,
			44.339,
			44.37,
			44.447,
			44.493,
			44.556,
			44.617,
			44.68,
			44.743,
			44.772,
			44.805,
			44.867,
			44.929,
			44.989,
			45.051,
			45.114,
			45.177,
			45.206,
			45.239,
			45.301,
			45.32,
			45.361,
			45.425,
			45.487,
			45.548,
			45.611,
			45.641,
			45.673,
			45.734,
			45.749,
			45.795,
			45.859,
			45.921,
			45.967,
			46.044,
			46.075,
			46.106,
			46.168,
			46.231,
			46.292,
			46.355,
			46.4,
			46.417,
			46.478,
			46.509,
			46.539,
			46.616,
			46.665,
			46.728,
			46.789,
			46.834,
			46.85,
			46.912,
			46.945,
			46.975,
			47.035,
			47.051,
			47.1,
			47.161,
			47.222,
			47.267,
			47.285,
			47.347,
			47.38,
			47.407,
			47.468,
			47.532,
			47.595,
			47.655,
			47.704,
			47.717,
			47.78,
			47.812,
			47.842,
			47.919,
			47.967,
			48.03,
			48.089,
			48.137,
			48.213,
			48.245,
			48.276,
			48.355,
			48.4,
			48.464,
			48.523,
			48.57,
			48.588,
			48.647,
			48.681,
			48.71,
			48.789,
			48.834,
			48.898,
			48.957,
			49.004,
			49.021,
			49.082,
			49.113,
			49.144,
			49.206,
			49.223,
			49.268,
			49.331,
			49.391,
			49.455,
			49.516,
			49.548,
			49.58,
			49.642,
			49.703,
			49.765,
			49.827,
			49.872,
			49.89,
			49.95,
			49.982,
			50.014,
			50.09,
			50.135,
			50.198,
			50.261,
			50.308,
			50.324,
			50.385,
			50.416,
			50.447,
			50.508,
			50.524,
			50.571,
			50.634,
			50.695,
			50.741,
			50.818,
			50.849,
			50.882,
			50.959,
			51.004,
			51.066,
			51.13,
			51.177,
			51.192,
			51.253,
			51.283,
			51.316,
			51.376,
			51.392,
			51.44,
			51.509,
			51.563,
			51.609,
			51.626,
			51.688,
			51.719,
			51.75,
			51.825,
			51.873,
			51.936,
			51.996,
			52.043,
			52.122,
			52.15,
			52.183,
			52.26,
			52.308,
			52.368,
			52.431,
			52.494,
			52.556,
			52.586,
			52.616,
			52.68,
			52.696,
			52.743,
			52.804,
			52.865,
			52.911,
			52.99,
			53.022,
			53.051,
			53.13,
			53.175,
			53.238,
			53.3,
			53.361,
			53.422,
			53.456,
			53.486,
			53.548,
			53.569,
			53.609,
			53.672,
			53.735,
			53.779,
			53.796,
			53.857,
			53.888,
			53.918,
			53.998,
			54.043,
			54.106,
			54.168,
			54.229,
			54.292,
			54.322,
			54.355,
			54.43,
			54.479,
			54.54,
			54.6,
			54.649,
			54.663,
			54.724,
			54.757,
			54.788,
			54.865,
			54.91,
			54.973,
			55.036,
			55.083,
			55.099,
			55.16,
			55.192,
			55.223,
			55.283,
			55.299,
			55.347,
			55.409,
			55.469,
			55.533,
			55.594,
			55.63,
			55.657,
			55.719,
			55.781,
			55.843,
			55.904,
			55.967,
			56.028,
			56.06,
			56.089,
			56.15,
			56.167,
			56.215,
			56.276,
			56.337,
			56.385,
			56.463,
			56.492,
			56.523,
			56.587,
			56.649,
			56.71,
			56.772,
			56.834,
			56.897,
			56.928,
			56.957,
			57.021,
			57.082,
			57.143,
			57.206,
			57.254,
			57.267,
			57.33,
			57.361,
			57.393,
			57.455,
			57.47,
			57.515,
			57.583,
			57.64,
			57.703,
			57.765,
			57.795,
			57.826,
			57.889,
			57.95,
			58.011,
			58.076,
			58.122,
			58.137,
			58.198,
			58.231,
			58.259,
			58.324,
			58.338,
			58.383,
			58.446,
			58.509,
			58.555,
			58.572,
			58.634,
			58.664,
			58.695,
			58.755,
			58.773,
			58.819,
			58.882,
			58.941,
			59.004,
			59.066,
			59.098,
			59.127,
			59.192,
			59.251,
			59.315,
			59.376,
			59.423,
			59.438,
			59.501,
			59.531,
			59.562,
			59.626,
			59.641,
			59.686,
			59.754,
			59.81,
			59.856,
			59.871,
			59.934,
			59.966,
			59.997,
			60.057,
			60.073,
			60.122,
			60.183,
			60.245,
			60.29,
			60.367,
			60.4,
			60.429,
			60.492,
			60.555,
			60.616,
			60.68,
			60.726,
			60.741,
			60.804,
			60.833,
			60.866,
			60.927,
			60.943,
			60.99,
			61.051,
			61.114,
			61.175,
			61.237,
			61.266,
			61.299,
			61.361,
			61.422,
			61.485,
			61.548,
			61.609,
			61.672,
			61.703,
			61.733,
			61.795,
			61.817,
			61.856,
			61.917,
			61.981,
			62.027,
			62.044,
			62.106,
			62.134,
			62.168,
			62.23,
			62.243,
			62.29,
			62.353,
			62.416,
			62.463,
			62.539,
			62.569,
			62.6,
			62.664,
			62.726,
			62.789,
			62.849,
			62.911,
			62.972,
			63.005,
			63.034,
			63.098,
			63.114,
			63.158,
			63.222,
			63.283,
			63.329,
			63.345,
			63.407,
			63.439,
			63.47,
			63.53,
			63.547,
			63.593,
			63.657,
			63.717,
			63.765,
			63.842,
			63.875,
			63.903,
			63.964,
			64.026,
			64.09,
			64.152,
			64.198,
			64.213,
			64.275,
			64.306,
			64.339,
			64.414,
			64.46,
			64.523,
			64.585,
			64.631,
			64.648,
			64.709,
			64.741,
			64.773,
			64.849,
			64.895,
			64.959,
			65.019,
			65.067,
			65.145,
			65.175,
			65.206,
			65.267,
			65.33,
			65.391,
			65.454,
			65.499,
			65.514,
			65.577,
			65.609,
			65.639,
			65.701,
			65.716,
			65.763,
			65.826,
			65.889,
			65.938,
			66.011,
			66.043,
			66.074,
			66.137
		]
	],
	'4038_5817_7982': [
		[
			55.94
		],
		[
			0
		],
		[
			0,
			0.208,
			5.132,
			8.419,
			13.358,
			16.657,
			21.613,
			24.922,
			28.234,
			34.873,
			36.535,
			41.527,
			44.858,
			48.195,
			49.865,
			53.21,
			56.561,
			58.239,
			61.599,
			63.281,
			66.649,
			70.02,
			75.082,
			78.459,
			83.521,
			85.202,
			86.879,
			91.868,
			95.149,
			99.997,
			103.18,
			107.883,
			110.971,
			112.5,
			115.531,
			118.525,
			120.009,
			122.949,
			125.849,
			130.127,
			131.533,
			132.93,
			135.695,
			138.421,
			139.77,
			142.438,
			143.757,
			146.368,
			148.942,
			152.732,
			155.211,
			158.859,
			160.058,
			161.248,
			164.79,
			167.099,
			170.5,
			172.728,
			174.929,
			176.02,
			178.189,
			179.268,
			181.419,
			184.645,
			186.8,
			188.956,
			192.199,
			194.364,
			197.621,
			198.705,
			199.793,
			203.051,
			205.222,
			207.393,
			210.659,
			212.847,
			216.141,
			217.242,
			218.343,
			221.662,
			223.879,
			227.231,
			229.468,
			232.847,
			235.101,
			237.357,
			238.485,
			240.735,
			241.857,
			244.103,
			246.344,
			249.71,
			251.973,
			255.383,
			256.527,
			257.671,
			261.106,
			263.394,
			266.807,
			269.068,
			271.315,
			274.663,
			275.772,
			276.879,
			280.195,
			281.294,
			282.394,
			285.686,
			287.853,
			291.091,
			293.223,
			295.353,
			296.403,
			298.501,
			299.547,
			301.627,
			303.692,
			306.779,
			308.833,
			311.915,
			312.943,
			313.971,
			317.057,
			319.119,
			322.226,
			324.308,
			326.4,
			327.454,
			329.565,
			330.624,
			332.751,
			334.868,
			335.957,
			338.106,
			340.265,
			342.436,
			344.618,
			345.712,
			347.909,
			350.115,
			351.202,
			353.424,
			354.538,
			356.774,
			359.036,
			362.4,
			364.664,
			368.076,
			369.217,
			370.36,
			373.804,
			376.112,
			379.591,
			381.922,
			384.264,
			385.438,
			387.794,
			388.977,
			391.35,
			393.733,
			394.929,
			397.326,
			399.731,
			403.355,
			404.568,
			405.783,
			409.443,
			410.667,
			411.894,
			414.356,
			415.59,
			418.065,
			421.795,
			424.292,
			426.799,
			430.575,
			431.837,
			433.102,
			436.907,
			439.455,
			443.292,
			445.86,
			448.436,
			449.727,
			452.315,
			454.911,
			456.211,
			458.809,
			460.11,
			462.716,
			465.329,
			469.262,
			471.894,
			475.854,
			477.178,
			478.504,
			482.489,
			485.155,
			489.168,
			491.852,
			494.543,
			495.892,
			498.595,
			499.949,
			502.662,
			505.383,
			506.746,
			508.111,
			512.216,
			514.961,
			517.713,
			519.092,
			521.854,
			524.623,
			526.009,
			528.788,
			530.18,
			532.97,
			535.766,
			539.974,
			542.788,
			547.021,
			548.435,
			549.851,
			554.111,
			556.96,
			561.244,
			564.109,
			566.98,
			568.418,
			571.298,
			572.741,
			575.631,
			579.977,
			582.881,
			585.792,
			588.708,
			591.63,
			593.093,
			596.024,
			598.961,
			600.433,
			603.38,
			604.855,
			607.812,
			610.774,
			615.229,
			618.206,
			622.679,
			624.172,
			625.666,
			630.158,
			633.164,
			637.691,
			640.717,
			643.749,
			645.263,
			648.293,
			649.81,
			652.844,
			655.876,
			657.401,
			660.46,
			663.531,
			668.154,
			669.698,
			671.244,
			675.885,
			677.435,
			678.985,
			682.089,
			683.645,
			686.759,
			689.877,
			694.564,
			697.695,
			702.4,
			703.969,
			705.539,
			710.251,
			713.395,
			718.138,
			721.283,
			724.425,
			725.996,
			729.137,
			732.275,
			733.844,
			736.979,
			738.522,
			741.658,
			744.799,
			749.519,
			751.095,
			752.7,
			757.459,
			759.057,
			760.651,
			765.396,
			768.557,
			773.252,
			776.345,
			779.437,
			783.94,
			785.454,
			786.909,
			791.278,
			792.679,
			794.081,
			798.221,
			800.911,
			803.551,
			804.849,
			807.417,
			809.921,
			811.158,
			813.596,
			814.795,
			817.169,
			819.498,
			822.925,
			825.158,
			828.436,
			829.509,
			830.572,
			833.709,
			835.758,
			838.771,
			840.743,
			842.679,
			843.649,
			845.561,
			846.503,
			848.391,
			850.254,
			851.173,
			852.994,
			854.809,
			856.589,
			858.342,
			859.214,
			860.931,
			862.617,
			863.46,
			865.137,
			865.969,
			867.623,
			869.296,
			871.798,
			873.458,
			875.937,
			876.759,
			877.58,
			880.006,
			881.612,
			884.003,
			885.581,
			887.156,
			887.942,
			889.501,
			890.279,
			891.833,
			894.159,
			895.704,
			897.249,
			899.572,
			900.347,
			901.121,
			903.45,
			904.23,
			905.01,
			906.568,
			907.347,
			908.915,
			910.488,
			912.844,
			914.423,
			916.784,
			917.57,
			918.355,
			920.698,
			922.253,
			924.572,
			926.108,
			928.405,
			929.921,
			930.677,
			932.187,
			933.691,
			934.436,
			935.923,
			937.408,
			939.623,
			941.09,
			943.289,
			944.019,
			944.747,
			946.932,
			948.389,
			950.56,
			952.008,
			953.458,
			955.625,
			956.348,
			957.072,
			959.972,
			960.698,
			962.883,
			964.349,
			966.56,
			968.042,
			969.542,
			970.296,
			971.809,
			972.568,
			974.098,
			975.64,
			977.972,
			979.545,
			981.927,
			982.727,
			983.531,
			985.97,
			987.612,
			990.101,
			991.778,
			993.466,
			994.316,
			996.025,
			996.884,
			998.611,
			1001.233,
			1002.114,
			1004.778,
			1006.574,
			1009.276,
			1011.103,
			1012.943,
			1013.867,
			1015.72,
			1016.651,
			1018.523,
			1020.408,
			1023.261,
			1025.18,
			1028.084,
			1029.06,
			1030.043,
			1033.008,
			1035.027,
			1038.063,
			1040.095,
			1043.217,
			1045.302,
			1046.351,
			1048.438,
			1050.546,
			1051.603,
			1053.738,
			1055.84,
			1058.984,
			1060.038,
			1061.094,
			1064.274,
			1065.338,
			1066.404,
			1069.616,
			1071.769,
			1073.931,
			1077.195,
			1079.383,
			1082.686,
			1083.792,
			1084.9,
			1088.24,
			1089.358,
			1090.478,
			1093.853,
			1096.116,
			1098.389,
			1099.528,
			1101.816,
			1102.964,
			1105.266,
			1107.578,
			1108.738,
			1111.065,
			1113.402,
			1116.93,
			1119.295,
			1122.87,
			1124.067,
			1125.267,
			1128.883,
			1131.305,
			1134.956,
			1137.399,
			1139.851,
			1143.542,
			1144.777,
			1146.013,
			1149.735,
			1152.226,
			1155.978,
			1158.49,
			1161.01,
			1162.273,
			1164.805,
			1167.347,
			1168.621,
			1171.177,
			1172.46,
			1175.03,
			1177.612,
			1181.5,
			1184.102,
			1188.018,
			1189.324,
			1190.629,
			1194.553,
			1197.176,
			1201.122,
			1203.759,
			1207.727,
			1210.38,
			1211.709,
			1214.373,
			1217.044,
			1218.382,
			1221.064,
			1223.752,
			1226.448,
			1229.151,
			1230.505,
			1233.219,
			1235.94,
			1237.303,
			1240.036,
			1241.405,
			1244.147,
			1246.897,
			1251.035,
			1253.803,
			1257.967,
			1259.359,
			1260.752,
			1264.942,
			1267.743,
			1271.958,
			1274.776,
			1277.6,
			1279.015,
			1281.849,
			1283.269,
			1286.113,
			1288.964,
			1290.392,
			1293.253,
			1296.12,
			1300.434,
			1303.318,
			1306.208,
			1309.104,
			1310.555,
			1314.917,
			1317.833,
			1320.755,
			1325.149,
			1328.087,
			1332.505,
			1333.98,
			1335.457,
			1339.898,
			1342.865,
			1347.33,
			1350.313,
			1354.8,
			1357.798,
			1359.3,
			1362.308,
			1365.322,
			1366.831,
			1369.854,
			1372.882,
			1377.436,
			1380.479,
			1385.054,
			1386.581,
			1388.11,
			1392.706,
			1395.777,
			1400.393,
			1403.477,
			1406.566,
			1411.211,
			1412.762,
			1414.314,
			1418.978,
			1420.536,
			1422.095,
			1426.78,
			1429.91,
			1433.046,
			1434.615,
			1437.758,
			1440.907,
			1442.483,
			1445.64,
			1447.22,
			1450.384,
			1453.552,
			1458.315,
			1461.496,
			1466.274,
			1467.866,
			1469.46,
			1474.248,
			1477.447,
			1482.251,
			1485.46,
			1488.674,
			1490.282,
			1493.502,
			1495.114,
			1498.34,
			1501.571,
			1503.188,
			1504.806,
			1509.666,
			1512.908,
			1516.154,
			1517.778,
			1521.03,
			1524.287,
			1525.917,
			1529.182,
			1530.816,
			1534.088,
			1537.363,
			1542.282,
			1545.568,
			1550.507,
			1552.156,
			1553.805,
			1558.76,
			1560.413,
			1562.068,
			1567.04,
			1570.361,
			1575.351,
			1578.684,
			1580.352,
			1583.691,
			1588.695,
			1592.013,
			1595.304,
			1598.559,
			1603.37,
			1606.528,
			1609.649,
			1611.196,
			1614.26,
			1615.779,
			1618.79,
			1621.764,
			1626.15,
			1629.021,
			1633.254,
			1634.645,
			1636.026,
			1640.12,
			1642.801,
			1646.75,
			1649.327,
			1651.869,
			1653.129,
			1655.622,
			1656.852,
			1659.278,
			1661.67,
			1662.856,
			1665.223,
			1667.532,
			1670.902,
			1673.126,
			1676.398,
			1677.459,
			1678.511,
			1681.613,
			1683.659,
			1685.653,
			1688.592,
			1690.513,
			1691.462,
			1693.335,
			1694.271,
			1695.202,
			1697.979,
			1698.9,
			1699.834,
			1702.596,
			1704.448,
			1706.29,
			1707.21,
			1709.04,
			1710.85,
			1711.747,
			1713.524,
			1714.403,
			1716.147,
			1717.882,
			1720.473,
			1722.195,
			1724.775,
			1725.632,
			1726.487,
			1729.04,
			1730.731,
			1733.258,
			1734.931,
			1737.423,
			1739.068,
			1739.888,
			1740.706,
			1743.166,
			1743.987,
			1744.806,
			1747.263,
			1748.904,
			1751.359,
			1753,
			1754.639,
			1755.458,
			1757.919,
			1759.563,
			1762.028,
			1763.684,
			1765.338,
			1767.835,
			1768.674,
			1769.513,
			1772.03,
			1773.719,
			1776.249,
			1777.942,
			1779.639,
			1780.488,
			1782.188,
			1783.051,
			1784.776,
			1786.499,
			1787.37,
			1789.133,
			1790.89,
			1793.57,
			1794.475,
			1795.378,
			1797.187,
			1799.114,
			1800.073,
			1802.971,
			1804.945,
			1806.917,
			1809.737,
			1811.605,
			1814.194,
			1815.038,
			1815.881,
			1818.341,
			1819.932,
			1822.329,
			1823.906,
			1826.283,
			1827.88,
			1828.683,
			1830.296,
			1831.92,
			1832.741,
			1834.401,
			1836.073,
			1838.61,
			1839.464,
			1840.319,
			1842.903,
			1843.775,
			1844.648,
			1847.276,
			1849.041,
			1851.701,
			1853.482,
			1855.273,
			1857.986,
			1858.899,
			1859.813,
			1862.552,
			1863.474,
			1864.394,
			1867.135,
			1868.937,
			1870.706,
			1871.576,
			1873.295,
			1874.983,
			1875.813,
			1878.263,
			1879.845,
			1881.395,
			1883.676,
			1885.17,
			1887.369,
			1888.104,
			1888.842,
			1891.068,
			1892.544,
			1894.802,
			1896.303,
			1897.803,
			1898.578,
			1900.12,
			1900.888,
			1901.654,
			1903.988,
			1904.775,
			1905.561,
			1907.911,
			1909.481,
			1911.044,
			1911.823,
			1913.366,
			1914.887,
			1915.646,
			1917.163,
			1917.917,
			1919.4,
			1920.872,
			1923.064,
			1924.504,
			1926.644,
			1927.354,
			1928.061,
			1930.161,
			1931.545,
			1933.603,
			1934.955,
			1936.966,
			1938.299,
			1938.962,
			1940.284,
			1941.604,
			1942.264,
			1943.588,
			1944.909,
			1946.229,
			1947.55,
			1948.213,
			1950.203,
			1950.865,
			1951.529,
			1952.858,
			1953.526,
			1954.861,
			1956.197,
			1958.213,
			1959.569,
			1961.609,
			1962.292,
			1962.978,
			1965.058,
			1966.453,
			1968.571,
			1970.009,
			1971.453,
			1972.177,
			1973.643,
			1974.386,
			1975.876,
			1977.372,
			1978.122,
			1979.648,
			1981.175,
			1983.472,
			1984.245,
			1985.017,
			1987.337,
			1988.111,
			1988.889,
			1990.444,
			1991.221,
			1992.773,
			1995.118,
			1996.678,
			1998.235,
			2000.604,
			2001.392,
			2002.179,
			2005.35,
			2006.145,
			2008.529,
			2010.132,
			2012.529,
			2014.137,
			2015.756,
			2016.567,
			2019.02,
			2020.683,
			2022.352,
			2024.878,
			2026.576,
			2029.13,
			2029.979,
			2030.831,
			2033.404,
			2035.103,
			2037.676,
			2039.394,
			2041.978,
			2043.716,
			2044.572,
			2046.294,
			2048.029,
			2048.893,
			2049.745,
			2052.319,
			2054.041,
			2055.746,
			2056.598,
			2058.301,
			2059.99,
			2060.834,
			2062.528,
			2063.378,
			2065.073,
			2066.771,
			2069.327,
			2071.03,
			2073.598,
			2074.452,
			2075.308,
			2077.885,
			2079.614,
			2082.224,
			2083.981,
			2085.76,
			2086.654,
			2088.451,
			2089.359,
			2091.188,
			2093.03,
			2093.957,
			2095.833,
			2097.722,
			2100.602,
			2101.572,
			2102.546,
			2105.501,
			2106.497,
			2107.495,
			2110.494,
			2112.521,
			2114.558,
			2117.63,
			2119.691,
			2122.797,
			2123.838,
			2124.881,
			2128.025,
			2130.124,
			2133.276,
			2135.388,
			2138.574,
			2140.708,
			2141.778,
			2143.925,
			2146.082,
			2147.164,
			2149.335,
			2151.516,
			2154.806,
			2157.01,
			2160.335,
			2161.448,
			2162.563,
			2165.925,
			2168.178,
			2170.441,
			2173.856,
			2176.145,
			2179.596,
			2180.75,
			2181.907,
			2185.393,
			2187.727,
			2191.243,
			2193.596,
			2195.956,
			2197.139,
			2199.511,
			2201.891,
			2203.084,
			2205.497,
			2206.697,
			2209.083,
			2211.502,
			2215.15,
			2217.602,
			2221.298,
			2222.536,
			2223.777,
			2227.517,
			2230.023,
			2233.805,
			2236.339,
			2238.884,
			2240.159,
			2242.719,
			2244.002,
			2246.578,
			2250.482,
			2251.782,
			2255.695,
			2258.314,
			2262.26,
			2264.891,
			2267.528,
			2268.827,
			2271.474,
			2272.823,
			2275.46,
			2278.128,
			2282.166,
			2284.855,
			2288.902,
			2290.255,
			2291.587,
			2295.663,
			2298.389,
			2302.492,
			2305.236,
			2308.01,
			2309.388,
			2312.126,
			2313.509,
			2316.28,
			2319.059,
			2320.452,
			2323.246,
			2326.07,
			2328.878,
			2331.669,
			2333.079,
			2335.904,
			2338.759,
			2340.177,
			2343.022,
			2344.448,
			2347.306,
			2350.171,
			2354.488,
			2357.375,
			2361.719,
			2363.169,
			2364.62,
			2368.979,
			2371.89,
			2376.262,
			2379.182,
			2383.571,
			2386.502,
			2387.97,
			2390.909,
			2393.854,
			2395.328,
			2398.28,
			2401.237,
			2405.651,
			2407.133,
			2408.639,
			2413.088,
			2414.572,
			2416.057,
			2419.027,
			2420.513,
			2423.462,
			2427.928,
			2430.91,
			2433.922,
			2438.385,
			2439.884,
			2441.384,
			2445.896,
			2448.911,
			2453.446,
			2456.476,
			2461.03,
			2464.073,
			2465.597,
			2468.649,
			2473.237,
			2476.303,
			2479.375,
			2483.994,
			2487.078,
			2491.715,
			2493.263,
			2494.812,
			2499.468,
			2502.579,
			2507.254,
			2510.378,
			2513.507,
			2518.209,
			2519.779,
			2522.923,
			2526.073,
			2527.651,
			2530.81,
			2533.974,
			2537.144,
			2540.319,
			2541.908,
			2545.09,
			2548.277,
			2549.872,
			2554.657,
			2557.85,
			2561.047,
			2565.852,
			2569.06,
			2573.88,
			2575.489,
			2577.098,
			2581.932,
			2585.16,
			2590.009,
			2593.247,
			2596.488,
			2598.109,
			2601.354,
			2602.978,
			2606.228,
			2611.109,
			2612.738,
			2617.631,
			2622.535,
			2625.809,
			2629.084,
			2629.843
		],
		[
			0,
			0.008,
			0.072,
			0.133,
			0.196,
			0.259,
			0.32,
			0.38,
			0.443,
			0.522,
			0.567,
			0.629,
			0.691,
			0.739,
			0.755,
			0.817,
			0.848,
			0.877,
			0.94,
			0.956,
			1.002,
			1.066,
			1.124,
			1.173,
			1.25,
			1.281,
			1.311,
			1.375,
			1.437,
			1.499,
			1.559,
			1.622,
			1.683,
			1.716,
			1.745,
			1.808,
			1.823,
			1.87,
			1.934,
			1.995,
			2.042,
			2.055,
			2.117,
			2.148,
			2.18,
			2.241,
			2.258,
			2.304,
			2.367,
			2.429,
			2.491,
			2.552,
			2.584,
			2.615,
			2.69,
			2.738,
			2.8,
			2.863,
			2.907,
			2.925,
			2.986,
			3.016,
			3.047,
			3.127,
			3.171,
			3.233,
			3.297,
			3.359,
			3.42,
			3.45,
			3.481,
			3.544,
			3.606,
			3.666,
			3.73,
			3.792,
			3.854,
			3.887,
			3.917,
			3.978,
			4.039,
			4.1,
			4.163,
			4.227,
			4.288,
			4.32,
			4.351,
			4.412,
			4.427,
			4.474,
			4.54,
			4.598,
			4.661,
			4.722,
			4.754,
			4.785,
			4.847,
			4.908,
			4.972,
			5.034,
			5.078,
			5.156,
			5.188,
			5.217,
			5.28,
			5.295,
			5.343,
			5.405,
			5.466,
			5.528,
			5.589,
			5.621,
			5.651,
			5.713,
			5.731,
			5.777,
			5.839,
			5.902,
			5.962,
			6.025,
			6.054,
			6.088,
			6.164,
			6.211,
			6.272,
			6.336,
			6.382,
			6.397,
			6.458,
			6.488,
			6.522,
			6.583,
			6.603,
			6.646,
			6.707,
			6.766,
			6.815,
			6.831,
			6.894,
			6.923,
			6.955,
			7.017,
			7.032,
			7.08,
			7.142,
			7.204,
			7.249,
			7.326,
			7.359,
			7.388,
			7.452,
			7.513,
			7.576,
			7.637,
			7.683,
			7.699,
			7.762,
			7.792,
			7.822,
			7.883,
			7.9,
			7.947,
			8.011,
			8.072,
			8.116,
			8.134,
			8.195,
			8.225,
			8.256,
			8.319,
			8.335,
			8.382,
			8.444,
			8.506,
			8.566,
			8.628,
			8.666,
			8.691,
			8.767,
			8.816,
			8.878,
			8.939,
			8.987,
			9.001,
			9.065,
			9.095,
			9.125,
			9.188,
			9.201,
			9.251,
			9.313,
			9.372,
			9.437,
			9.498,
			9.527,
			9.558,
			9.622,
			9.684,
			9.744,
			9.806,
			9.855,
			9.871,
			9.933,
			9.961,
			9.994,
			10.055,
			10.071,
			10.117,
			10.178,
			10.241,
			10.288,
			10.305,
			10.364,
			10.398,
			10.427,
			10.491,
			10.505,
			10.549,
			10.615,
			10.675,
			10.727,
			10.799,
			10.831,
			10.861,
			10.924,
			10.983,
			11.049,
			11.111,
			11.155,
			11.172,
			11.233,
			11.265,
			11.296,
			11.374,
			11.42,
			11.48,
			11.544,
			11.591,
			11.606,
			11.666,
			11.698,
			11.732,
			11.792,
			11.808,
			11.854,
			11.915,
			11.977,
			12.039,
			12.1,
			12.133,
			12.163,
			12.227,
			12.289,
			12.35,
			12.411,
			12.458,
			12.475,
			12.533,
			12.566,
			12.597,
			12.661,
			12.674,
			12.721,
			12.786,
			12.846,
			12.891,
			12.908,
			12.97,
			13,
			13.033,
			13.094,
			13.111,
			13.156,
			13.216,
			13.281,
			13.325,
			13.405,
			13.434,
			13.467,
			13.527,
			13.589,
			13.653,
			13.714,
			13.761,
			13.776,
			13.839,
			13.87,
			13.901,
			13.962,
			13.976,
			14.025,
			14.086,
			14.148,
			14.194,
			14.211,
			14.272,
			14.304,
			14.333,
			14.396,
			14.458,
			14.521,
			14.583,
			14.628,
			14.707,
			14.738,
			14.768,
			14.831,
			14.854,
			14.892,
			14.955,
			15.017,
			15.062,
			15.077,
			15.141,
			15.172,
			15.2,
			15.265,
			15.281,
			15.327,
			15.388,
			15.45,
			15.497,
			15.574,
			15.605,
			15.637,
			15.698,
			15.761,
			15.822,
			15.883,
			15.93,
			15.947,
			16.008,
			16.039,
			16.072,
			16.133,
			16.147,
			16.195,
			16.258,
			16.316,
			16.364,
			16.381,
			16.441,
			16.474,
			16.506,
			16.566,
			16.583,
			16.629,
			16.691,
			16.752,
			16.815,
			16.877,
			16.913,
			16.939,
			17,
			17.064,
			17.126,
			17.186,
			17.233,
			17.247,
			17.311,
			17.342,
			17.372,
			17.451,
			17.497,
			17.559,
			17.622,
			17.666,
			17.683,
			17.744,
			17.775,
			17.808,
			17.867,
			17.885,
			17.929,
			17.991,
			18.056,
			18.117,
			18.178,
			18.208,
			18.241,
			18.304,
			18.365,
			18.427,
			18.488,
			18.549,
			18.614,
			18.644,
			18.674,
			18.738,
			18.753,
			18.799,
			18.861,
			18.924,
			18.986,
			19.047,
			19.078,
			19.107,
			19.172,
			19.233,
			19.295,
			19.356,
			19.405,
			19.481,
			19.511,
			19.544,
			19.621,
			19.667,
			19.73,
			19.79,
			19.854,
			19.914,
			19.947,
			19.978,
			20.039,
			20.055,
			20.102,
			20.166,
			20.226,
			20.271,
			20.349,
			20.381,
			20.411,
			20.474,
			20.533,
			20.599,
			20.66,
			20.705,
			20.721,
			20.783,
			20.813,
			20.844,
			20.921,
			20.968,
			21.037,
			21.092,
			21.155,
			21.217,
			21.249,
			21.278,
			21.342,
			21.357,
			21.403,
			21.466,
			21.527,
			21.574,
			21.653,
			21.683,
			21.714,
			21.775,
			21.838,
			21.901,
			21.962,
			22.025,
			22.086,
			22.117,
			22.147,
			22.209,
			22.226,
			22.273,
			22.333,
			22.395,
			22.441,
			22.457,
			22.52,
			22.55,
			22.58,
			22.644,
			22.706,
			22.766,
			22.832,
			22.875,
			22.954,
			22.983,
			23.017,
			23.077,
			23.098,
			23.14,
			23.201,
			23.264,
			23.311,
			23.325,
			23.387,
			23.418,
			23.449,
			23.51,
			23.526,
			23.574,
			23.637,
			23.699,
			23.76,
			23.82,
			23.853,
			23.882,
			23.945,
			24.008,
			24.071,
			24.13,
			24.194,
			24.256,
			24.288,
			24.317,
			24.381,
			24.441,
			24.505,
			24.565,
			24.613,
			24.628,
			24.691,
			24.721,
			24.753,
			24.813,
			24.831,
			24.875,
			24.939,
			25,
			25.048,
			25.125,
			25.158,
			25.184,
			25.249,
			25.311,
			25.377,
			25.433,
			25.495,
			25.558,
			25.588,
			25.62,
			25.681,
			25.696,
			25.744,
			25.806,
			25.867,
			25.913,
			25.932,
			25.991,
			26.023,
			26.054,
			26.117,
			26.131,
			26.177,
			26.239,
			26.303,
			26.347,
			26.427,
			26.458,
			26.489,
			26.55,
			26.611,
			26.675,
			26.734,
			26.784,
			26.798,
			26.859,
			26.891,
			26.922,
			26.984,
			26.998,
			27.051,
			27.109,
			27.17,
			27.221,
			27.294,
			27.325,
			27.356,
			27.433,
			27.48,
			27.544,
			27.605,
			27.652,
			27.728,
			27.758,
			27.792,
			27.853,
			27.914,
			27.978,
			28.039,
			28.101,
			28.162,
			28.193,
			28.225,
			28.284,
			28.301,
			28.35,
			28.411,
			28.472,
			28.52,
			28.598,
			28.628,
			28.658,
			28.722,
			28.783,
			28.845,
			28.906,
			28.954,
			29.03,
			29.062,
			29.092,
			29.156,
			29.172,
			29.216,
			29.284,
			29.341,
			29.388,
			29.404,
			29.464,
			29.494,
			29.527,
			29.589,
			29.604,
			29.65,
			29.713,
			29.775,
			29.834,
			29.9,
			29.93,
			29.961,
			30.024,
			30.086,
			30.148,
			30.209,
			30.255,
			30.271,
			30.334,
			30.363,
			30.395,
			30.458,
			30.473,
			30.517,
			30.581,
			30.644,
			30.691,
			30.705,
			30.766,
			30.8,
			30.829,
			30.892,
			30.905,
			30.954,
			31.014,
			31.078,
			31.124,
			31.202,
			31.233,
			31.264,
			31.325,
			31.345,
			31.387,
			31.45,
			31.511,
			31.574,
			31.633,
			31.667,
			31.698,
			31.775,
			31.822,
			31.883,
			31.944,
			32.006,
			32.068,
			32.1,
			32.131,
			32.194,
			32.207,
			32.254,
			32.317,
			32.378,
			32.442,
			32.501,
			32.533,
			32.566,
			32.627,
			32.69,
			32.752,
			32.813,
			32.861,
			32.874,
			32.937,
			32.966,
			33,
			33.06,
			33.078,
			33.123,
			33.186,
			33.248,
			33.295,
			33.372,
			33.406,
			33.433,
			33.497,
			33.558,
			33.618,
			33.682,
			33.728,
			33.744,
			33.806,
			33.837,
			33.867,
			33.931,
			33.945,
			33.991,
			34.055,
			34.115,
			34.163,
			34.178,
			34.239,
			34.271,
			34.3,
			34.366,
			34.38,
			34.426,
			34.488,
			34.55,
			34.597,
			34.674,
			34.704,
			34.733,
			34.798,
			34.861,
			34.922,
			34.983,
			35.046,
			35.109,
			35.138,
			35.168,
			35.233,
			35.247,
			35.293,
			35.355,
			35.417,
			35.48,
			35.542,
			35.572,
			35.604,
			35.681,
			35.727,
			35.795,
			35.85,
			35.898,
			35.975,
			36.007,
			36.037,
			36.116,
			36.161,
			36.228,
			36.286,
			36.333,
			36.349,
			36.41,
			36.441,
			36.472,
			36.533,
			36.55,
			36.597,
			36.659,
			36.72,
			36.767,
			36.783,
			36.844,
			36.875,
			36.906,
			36.984,
			37.031,
			37.092,
			37.154,
			37.2,
			37.279,
			37.31,
			37.339,
			37.402,
			37.466,
			37.531,
			37.588,
			37.651,
			37.713,
			37.744,
			37.774,
			37.837,
			37.85,
			37.899,
			37.961,
			38.022,
			38.066,
			38.084,
			38.147,
			38.178,
			38.207,
			38.27,
			38.331,
			38.394,
			38.455,
			38.501,
			38.581,
			38.611,
			38.641,
			38.706,
			38.721,
			38.765,
			38.829,
			38.889,
			38.937,
			38.953,
			39.014,
			39.045,
			39.078,
			39.155,
			39.202,
			39.263,
			39.326,
			39.372,
			39.447,
			39.481,
			39.511,
			39.593,
			39.635,
			39.699,
			39.759,
			39.805,
			39.821,
			39.883,
			39.914,
			39.944,
			40.007,
			40.021,
			40.067,
			40.131,
			40.193,
			40.239,
			40.256,
			40.316,
			40.349,
			40.378,
			40.441,
			40.455,
			40.504,
			40.565,
			40.626,
			40.674,
			40.75,
			40.783,
			40.814,
			40.874,
			40.937,
			41,
			41.061,
			41.122,
			41.185,
			41.217,
			41.247,
			41.307,
			41.324,
			41.371,
			41.434,
			41.494,
			41.541,
			41.557,
			41.619,
			41.654,
			41.681,
			41.744,
			41.758,
			41.805,
			41.867,
			41.928,
			41.974,
			42.054,
			42.083,
			42.116,
			42.178,
			42.238,
			42.302,
			42.365,
			42.409,
			42.425,
			42.487,
			42.516,
			42.55,
			42.611,
			42.628,
			42.674,
			42.736,
			42.799,
			42.844,
			42.86,
			42.922,
			42.951,
			42.984,
			43.044,
			43.06,
			43.108,
			43.171,
			43.232,
			43.277,
			43.356,
			43.387,
			43.417,
			43.495,
			43.541,
			43.604,
			43.664,
			43.727,
			43.789,
			43.821,
			43.851,
			43.928,
			43.974,
			44.038,
			44.098,
			44.144,
			44.223,
			44.255,
			44.284,
			44.364,
			44.411,
			44.472,
			44.533,
			44.595,
			44.658,
			44.687,
			44.72,
			44.783,
			44.797,
			44.842,
			44.906,
			44.969,
			45.013,
			45.03,
			45.093,
			45.123,
			45.155,
			45.217,
			45.23,
			45.278,
			45.34,
			45.402,
			45.448,
			45.525,
			45.557,
			45.587,
			45.665,
			45.711,
			45.774,
			45.837,
			45.883,
			45.898,
			45.96,
			45.991,
			46.023,
			46.083,
			46.099,
			46.145,
			46.208,
			46.271,
			46.316,
			46.333,
			46.395,
			46.424,
			46.455,
			46.533,
			46.58,
			46.641,
			46.705,
			46.749,
			46.827,
			46.858,
			46.891,
			46.969,
			47.015,
			47.077,
			47.138,
			47.2,
			47.262,
			47.292,
			47.324,
			47.386,
			47.399,
			47.449,
			47.51,
			47.572,
			47.619,
			47.696,
			47.728,
			47.759,
			47.82,
			47.883,
			47.943,
			48.006,
			48.054,
			48.131,
			48.16,
			48.193,
			48.268,
			48.315,
			48.377,
			48.439,
			48.487,
			48.503,
			48.563,
			48.596,
			48.626,
			48.688,
			48.702,
			48.749,
			48.813,
			48.874,
			48.922,
			48.999,
			49.028,
			49.06,
			49.138,
			49.184,
			49.247,
			49.308,
			49.354,
			49.37,
			49.433,
			49.463,
			49.494,
			49.572,
			49.617,
			49.682,
			49.741,
			49.805,
			49.866,
			49.906,
			49.929,
			49.99,
			50.007,
			50.052,
			50.115,
			50.178,
			50.222,
			50.299,
			50.332,
			50.364,
			50.425,
			50.484,
			50.55,
			50.609,
			50.658,
			50.672,
			50.733,
			50.766,
			50.797,
			50.857,
			50.875,
			50.922,
			50.983,
			51.044,
			51.09,
			51.107,
			51.166,
			51.2,
			51.23,
			51.294,
			51.307,
			51.355,
			51.418,
			51.478,
			51.541,
			51.604,
			51.633,
			51.663,
			51.726,
			51.788,
			51.852,
			51.913,
			51.975,
			52.038,
			52.066,
			52.099,
			52.159,
			52.175,
			52.223,
			52.285,
			52.349,
			52.393,
			52.408,
			52.471,
			52.501,
			52.533,
			52.594,
			52.609,
			52.656,
			52.72,
			52.78,
			52.826,
			52.905,
			52.935,
			52.967,
			53.044,
			53.091,
			53.155,
			53.216,
			53.278,
			53.339,
			53.369,
			53.4,
			53.479,
			53.524,
			53.588,
			53.651,
			53.696,
			53.772,
			53.805,
			53.833,
			53.898,
			53.96,
			54.025,
			54.083,
			54.131,
			54.207,
			54.238,
			54.27,
			54.331,
			54.347,
			54.394,
			54.456,
			54.517,
			54.564,
			54.58,
			54.642,
			54.672,
			54.704,
			54.781,
			54.828,
			54.891,
			54.95,
			54.998,
			55.074,
			55.107,
			55.138,
			55.199,
			55.262,
			55.324,
			55.386,
			55.43,
			55.447,
			55.509,
			55.54,
			55.572,
			55.651,
			55.694,
			55.758,
			55.82,
			55.881,
			55.943,
			55.94
		]
	],
	'2029_2064_7982': [
		[
			123.376
		],
		[
			0
		],
		[
			0,
			1.991,
			3.603,
			5.216,
			8.442,
			10.055,
			13.288,
			16.525,
			21.388,
			24.637,
			29.518,
			31.147,
			32.777,
			37.67,
			40.939,
			45.85,
			49.129,
			54.053,
			57.341,
			58.987,
			62.282,
			65.58,
			67.23,
			70.533,
			73.841,
			78.812,
			82.13,
			87.116,
			88.78,
			90.445,
			95.446,
			98.785,
			103.801,
			107.15,
			110.503,
			115.54,
			117.221,
			118.903,
			123.955,
			125.641,
			127.328,
			132.395,
			135.778,
			139.165,
			140.86,
			144.253,
			147.649,
			149.349,
			154.454,
			157.862,
			161.274,
			166.399,
			169.821,
			174.96,
			176.675,
			178.391,
			183.545,
			186.986,
			192.154,
			195.605,
			199.059,
			200.788,
			204.247,
			205.978,
			209.443,
			214.648,
			218.121,
			221.598,
			225.078,
			228.562,
			230.306,
			233.795,
			237.287,
			239.035,
			242.533,
			244.283,
			247.786,
			251.293,
			256.559,
			260.075,
			265.355,
			267.117,
			268.88,
			274.175,
			277.709,
			283.017,
			286.559,
			290.105,
			291.879,
			295.429,
			297.205,
			300.759,
			304.317,
			306.097,
			309.659,
			313.225,
			318.58,
			320.367,
			322.154,
			325.731,
			329.311,
			331.102,
			334.687,
			336.481,
			340.071,
			343.664,
			349.059,
			352.659,
			358.065,
			359.869,
			361.673,
			367.091,
			370.706,
			376.135,
			379.758,
			383.384,
			385.198,
			388.828,
			390.644,
			394.278,
			399.734,
			403.375,
			407.019,
			412.491,
			414.316,
			416.142,
			421.623,
			423.452,
			425.281,
			430.773,
			434.438,
			439.94,
			443.611,
			447.285,
			452.801,
			454.641,
			456.482,
			462.003,
			463.839,
			465.671,
			471.126,
			474.72,
			478.274,
			480.037,
			483.532,
			486.987,
			488.7,
			492.097,
			493.78,
			497.117,
			500.415,
			505.289,
			508.489,
			513.217,
			514.774,
			516.322,
			520.908,
			523.921,
			528.372,
			531.293,
			534.174,
			535.601,
			538.424,
			539.822,
			542.588,
			545.317,
			546.668,
			548.009,
			551.978,
			554.578,
			557.142,
			558.41,
			560.919,
			563.392,
			564.634,
			567.052,
			568.249,
			570.615,
			572.948,
			576.376,
			578.615,
			581.898,
			582.974,
			584.042,
			587.189,
			589.257,
			592.336,
			594.387,
			596.444,
			597.473,
			599.53,
			600.556,
			602.615,
			604.661,
			605.679,
			607.721,
			609.749,
			612.797,
			613.817,
			614.836,
			617.918,
			618.954,
			619.99,
			622.063,
			623.112,
			625.204,
			627.289,
			630.409,
			632.48,
			635.567,
			636.59,
			637.61,
			640.647,
			642.66,
			645.701,
			647.72,
			649.76,
			650.779,
			652.812,
			653.838,
			655.907,
			657.966,
			658.998,
			661.061,
			663.115,
			666.176,
			667.191,
			668.204,
			671.184,
			672.17,
			673.154,
			676.047,
			677.957,
			680.794,
			682.675,
			684.552,
			687.353,
			688.284,
			689.214,
			691.995,
			693.844,
			696.606,
			698.442,
			701.192,
			703.021,
			704.849,
			705.755,
			707.566,
			708.471,
			710.283,
			712.082,
			714.782,
			716.579,
			719.273,
			720.172,
			721.073,
			723.778,
			725.584,
			728.299,
			730.111,
			731.926,
			732.835,
			734.653,
			735.564,
			737.388,
			739.214,
			740.129,
			741.045,
			743.794,
			745.632,
			747.477,
			748.4,
			750.253,
			752.113,
			753.046,
			754.914,
			755.853,
			757.739,
			759.648,
			762.533,
			764.483,
			767.434,
			768.427,
			769.431,
			772.441,
			774.481,
			777.573,
			779.652,
			781.746,
			782.797,
			784.902,
			785.954,
			788.06,
			790.174,
			791.233,
			793.358,
			795.492,
			797.631,
			799.781,
			800.858,
			803.019,
			805.19,
			806.278,
			809.558,
			811.757,
			813.966,
			817.297,
			819.529,
			822.898,
			824.026,
			825.157,
			828.564,
			830.848,
			834.293,
			836.602,
			838.921,
			840.084,
			842.418,
			844.761,
			845.937,
			848.294,
			849.476,
			851.848,
			854.228,
			857.817,
			859.018,
			860.221,
			863.844,
			865.056,
			866.271,
			869.928,
			872.378,
			876.068,
			878.539,
			881.019,
			884.755,
			886.005,
			887.257,
			891.026,
			892.286,
			893.549,
			897.35,
			899.894,
			903.725,
			906.29,
			908.863,
			910.152,
			912.737,
			914.032,
			916.627,
			919.231,
			923.146,
			925.757,
			929.684,
			930.996,
			932.309,
			936.26,
			938.901,
			942.875,
			945.532,
			948.195,
			949.529,
			952.202,
			953.54,
			956.222,
			960.254,
			962.95,
			965.651,
			968.358,
			971.07,
			972.429,
			975.15,
			977.876,
			979.241,
			981.976,
			983.345,
			986.088,
			988.836,
			992.968,
			995.731,
			999.883,
			1001.271,
			1002.659,
			1006.835,
			1009.624,
			1013.819,
			1016.623,
			1019.433,
			1020.84,
			1023.659,
			1025.07,
			1027.899,
			1030.732,
			1032.151,
			1034.993,
			1037.841,
			1040.693,
			1044.983,
			1047.852,
			1050.727,
			1052.167,
			1055.052,
			1056.498,
			1059.393,
			1062.298,
			1063.752,
			1066.669,
			1069.593,
			1073.992,
			1075.462,
			1076.933,
			1081.356,
			1084.312,
			1088.757,
			1091.729,
			1094.708,
			1096.201,
			1099.191,
			1100.689,
			1103.69,
			1106.699,
			1108.206,
			1111.225,
			1114.251,
			1118.805,
			1121.849,
			1126.429,
			1127.96,
			1129.492,
			1134.098,
			1137.177,
			1141.809,
			1144.905,
			1148.008,
			1152.676,
			1154.235,
			1155.796,
			1160.49,
			1163.627,
			1168.345,
			1171.499,
			1174.657,
			1176.239,
			1179.404,
			1182.571,
			1184.153,
			1187.312,
			1188.885,
			1192.015,
			1195.116,
			1199.704,
			1202.713,
			1207.152,
			1208.612,
			1210.062,
			1214.374,
			1217.158,
			1221.303,
			1224.043,
			1226.725,
			1228.053,
			1230.691,
			1231.997,
			1234.595,
			1237.177,
			1238.463,
			1239.727,
			1243.585,
			1246.182,
			1248.768,
			1250.062,
			1252.656,
			1255.26,
			1256.563,
			1259.177,
			1260.487,
			1263.113,
			1265.748,
			1269.716,
			1272.372,
			1276.375,
			1277.714,
			1279.054,
			1283.1,
			1285.811,
			1289.897,
			1292.64,
			1296.773,
			1299.546,
			1302.329,
			1303.724,
			1306.52,
			1307.924,
			1310.735,
			1313.556,
			1317.801,
			1319.219,
			1320.639,
			1323.479,
			1326.318,
			1327.738,
			1331.988,
			1334.819,
			1337.646,
			1341.879,
			1344.698,
			1348.925,
			1350.334,
			1351.72,
			1355.969,
			1358.786,
			1363.009,
			1365.818,
			1368.63,
			1370.033,
			1372.837,
			1374.238,
			1377.035,
			1379.824,
			1381.217,
			1383.999,
			1386.776,
			1390.937,
			1392.323,
			1393.709,
			1397.87,
			1399.258,
			1400.648,
			1404.826,
			1407.62,
			1410.421,
			1414.638,
			1417.458,
			1421.701,
			1423.117,
			1424.535,
			1428.797,
			1430.22,
			1431.645,
			1435.928,
			1438.791,
			1443.107,
			1445.995,
			1448.891,
			1450.343,
			1453.25,
			1454.706,
			1457.62,
			1460.54,
			1464.922,
			1467.845,
			1472.229,
			1473.688,
			1475.148,
			1479.535,
			1482.465,
			1486.869,
			1489.812,
			1492.763,
			1494.24,
			1497.197,
			1498.677,
			1500.158,
			1506.087,
			1507.57,
			1512.029,
			1515.006,
			1517.988,
			1519.48,
			1522.444,
			1525.436,
			1526.935,
			1529.934,
			1531.436,
			1534.443,
			1538.964,
			1541.986,
			1545.013,
			1549.566,
			1551.087,
			1552.609,
			1557.185,
			1560.244,
			1564.846,
			1567.923,
			1572.55,
			1575.642,
			1577.191,
			1580.292,
			1583.398,
			1584.953,
			1588.068,
			1591.187,
			1594.312,
			1597.441,
			1599.008,
			1602.146,
			1605.289,
			1606.862,
			1610.013,
			1611.59,
			1614.75,
			1617.917,
			1622.682,
			1625.87,
			1630.679,
			1632.286,
			1633.895,
			1638.724,
			1641.947,
			1646.792,
			1650.03,
			1653.271,
			1654.888,
			1658.12,
			1659.72,
			1662.923,
			1666.122,
			1667.723,
			1670.918,
			1674.11,
			1678.86,
			1681.976,
			1686.564,
			1688.083,
			1689.592,
			1694.209,
			1697.481,
			1702.797,
			1706.312,
			1709.763,
			1714.86,
			1716.54,
			1718.219,
			1723.223,
			1726.529,
			1731.477,
			1734.763,
			1739.681,
			1742.968,
			1746.267,
			1747.921,
			1751.237,
			1752.897,
			1756.219,
			1759.543,
			1764.53,
			1767.858,
			1772.858,
			1774.527,
			1776.197,
			1781.211,
			1784.558,
			1789.587,
			1792.946,
			1796.31,
			1797.996,
			1801.37,
			1803.06,
			1806.444,
			1811.527,
			1813.223,
			1818.315,
			1821.713,
			1825.115,
			1826.818,
			1830.229,
			1833.643,
			1835.35,
			1838.767,
			1840.478,
			1843.905,
			1847.343,
			1852.496,
			1855.924,
			1861.043,
			1862.742,
			1864.443,
			1869.566,
			1873.03,
			1878.234,
			1881.697,
			1885.161,
			1886.896,
			1890.367,
			1892.105,
			1895.586,
			1899.073,
			1900.818,
			1904.311,
			1907.806,
			1911.302,
			1914.797,
			1916.544,
			1920.039,
			1923.533,
			1925.278,
			1930.49,
			1933.932,
			1937.342,
			1942.386,
			1945.706,
			1950.632,
			1952.261,
			1953.882,
			1958.703,
			1961.878,
			1966.573,
			1969.657,
			1974.218,
			1977.217,
			1978.704,
			1981.648,
			1985.992,
			1988.842,
			1991.652,
			1995.796,
			1997.158,
			1998.511,
			2002.511,
			2003.826,
			2005.131,
			2008.991,
			2011.517,
			2015.237,
			2017.67,
			2020.068,
			2023.598,
			2024.758,
			2025.908,
			2029.302,
			2031.518,
			2034.771,
			2036.893,
			2038.978,
			2040.007,
			2042.036,
			2044.029,
			2045.011,
			2046.949,
			2047.904,
			2049.786,
			2051.632,
			2054.336,
			2056.111,
			2058.688,
			2059.53,
			2060.362,
			2062.804,
			2064.39,
			2066.711,
			2068.226,
			2069.721,
			2070.465,
			2071.947,
			2072.682,
			2073.416,
			2075.617,
			2076.356,
			2077.094,
			2079.312,
			2080.807,
			2082.317,
			2083.073,
			2084.592,
			2086.122,
			2086.89,
			2089.199,
			2090.747,
			2092.3,
			2094.634,
			2096.202,
			2098.558,
			2099.343,
			2100.129,
			2102.52,
			2104.11,
			2106.516,
			2108.126,
			2109.731,
			2110.539,
			2112.16,
			2112.967,
			2114.577,
			2116.182,
			2116.981,
			2118.572,
			2120.156,
			2121.711,
			2123.256,
			2124.026,
			2125.558,
			2127.068,
			2127.82,
			2129.32,
			2130.069,
			2131.563,
			2133.05,
			2135.276,
			2136.77,
			2139.003,
			2139.747,
			2140.494,
			2142.747,
			2144.247,
			2146.512,
			2148.029,
			2150.31,
			2151.853,
			2152.625,
			2154.172,
			2155.727,
			2156.509,
			2158.078,
			2159.652,
			2162.006,
			2163.577,
			2165.943,
			2166.728,
			2167.515,
			2169.889,
			2171.48,
			2173.08,
			2175.5,
			2177.132,
			2179.599,
			2180.427,
			2181.263,
			2184.628,
			2185.474,
			2188.058,
			2189.788,
			2191.541,
			2192.424,
			2194.193,
			2195.079,
			2196.879,
			2198.69,
			2199.595,
			2201.424,
			2203.271,
			2206.044,
			2207.914,
			2210.711,
			2211.643,
			2212.572,
			2215.351,
			2217.182,
			2219.906,
			2221.702,
			2223.478,
			2226.121,
			2226.987,
			2227.852,
			2230.433,
			2231.282,
			2232.123,
			2234.635,
			2236.298,
			2237.943,
			2238.763,
			2240.399,
			2242.025,
			2242.835,
			2244.452,
			2245.258,
			2246.864,
			2248.462,
			2250.849,
			2252.418,
			2254.782,
			2255.569,
			2256.367,
			2258.714,
			2260.276,
			2262.606,
			2264.175,
			2265.753,
			2266.546,
			2268.142,
			2268.945,
			2270.562,
			2272.191,
			2273.011,
			2274.66,
			2276.323,
			2277.998,
			2279.686,
			2280.535,
			2282.243,
			2283.963,
			2284.828,
			2286.568,
			2287.442,
			2289.202,
			2290.974,
			2293.66,
			2295.466,
			2298.203,
			2299.123,
			2300.045,
			2302.834,
			2304.71,
			2307.549,
			2309.458,
			2311.38,
			2312.346,
			2314.286,
			2315.26,
			2317.218,
			2319.186,
			2320.175,
			2322.16,
			2324.156,
			2327.172,
			2329.196,
			2331.232,
			2333.279,
			2334.307,
			2337.408,
			2339.49,
			2341.583,
			2344.732,
			2346.833,
			2350.003,
			2351.066,
			2352.13,
			2355.359,
			2357.513,
			2360.764,
			2362.943,
			2366.212,
			2368.414,
			2369.518,
			2371.752,
			2373.976,
			2375.092,
			2377.331,
			2379.581,
			2382.978,
			2385.258,
			2388.708,
			2389.866,
			2391.027,
			2394.541,
			2396.9,
			2400.486,
			2402.896,
			2405.327,
			2406.547,
			2409.009,
			2410.246,
			2411.486,
			2415.233,
			2416.487,
			2417.744,
			2421.523,
			2424.047,
			2426.576,
			2427.842,
			2430.353,
			2432.909,
			2434.174,
			2436.711,
			2437.98,
			2440.52,
			2443.065,
			2446.888,
			2449.448,
			2453.305,
			2454.597,
			2455.89,
			2459.782,
			2462.387,
			2466.306,
			2468.931,
			2471.565,
			2472.886,
			2475.511,
			2476.858,
			2479.512,
			2483.496,
			2484.826,
			2488.825,
			2491.501,
			2494.16,
			2495.502,
			2498.188,
			2500.882,
			2502.22,
			2504.874,
			2506.198,
			2508.841,
			2511.479,
			2515.437,
			2518.059,
			2522.034,
			2523.384,
			2524.713,
			2528.711,
			2531.385,
			2535.388,
			2538.08,
			2542.129,
			2544.838,
			2546.194,
			2548.912,
			2551.635,
			2553,
			2555.732,
			2558.472,
			2562.591,
			2563.967,
			2565.345,
			2569.488,
			2570.872,
			2572.258,
			2575.037,
			2576.428,
			2579.218,
			2582.014,
			2586.212,
			2589.004,
			2593.158,
			2594.531,
			2595.896,
			2599.961,
			2601.291,
			2602.611,
			2606.516,
			2609.053,
			2611.574,
			2612.843,
			2615.312,
			2616.537,
			2618.953,
			2621.393,
			2622.599,
			2625.008,
			2627.41,
			2631.007,
			2633.399,
			2636.996,
			2638.194,
			2639.396,
			2643.013,
			2645.444,
			2649.11,
			2651.586,
			2655.326,
			2657.839,
			2659.1,
			2660.367,
			2664.179,
			2665.45,
			2666.723,
			2670.542,
			2673.087,
			2675.634,
			2676.908,
			2679.461,
			2682.018,
			2683.299,
			2685.867,
			2687.154,
			2689.733,
			2692.321,
			2696.217,
			2698.824,
			2702.752,
			2704.064,
			2705.38,
			2709.338,
			2711.985,
			2715.968,
			2718.629,
			2722.629,
			2725.301,
			2726.636,
			2729.312,
			2733.327,
			2734.668,
			2738.69,
			2741.375,
			2745.409,
			2748.102,
			2750.799,
			2752.149,
			2756.208,
			2758.92,
			2761.638,
			2765.726,
			2768.458,
			2772.567,
			2773.939,
			2775.312,
			2779.423,
			2782.154,
			2786.224,
			2788.887,
			2792.859,
			2795.461,
			2796.749,
			2799.297,
			2801.807,
			2803.048,
			2805.503,
			2807.921,
			2811.48,
			2812.649,
			2813.809,
			2817.237,
			2818.362,
			2819.477,
			2821.679,
			2822.766,
			2824.912,
			2827.021,
			2830.113,
			2832.127,
			2835.097,
			2836.063,
			2837.021,
			2839.852,
			2840.784,
			2841.71,
			2844.465,
			2846.29,
			2849.02,
			2850.84,
			2851.749,
			2853.568,
			2855.386,
			2856.296,
			2858.113,
			2859.93,
			2862.653,
			2864.467,
			2867.187,
			2868.092,
			2868.997,
			2871.712,
			2873.52,
			2876.231,
			2878.041,
			2879.848,
			2882.573,
			2883.481,
			2884.391,
			2887.132,
			2888.048,
			2888.964,
			2891.722,
			2893.568,
			2896.343,
			2898.205,
			2900.066,
			2901.001,
			2902.881,
			2903.82,
			2905.699,
			2907.592,
			2910.428,
			2912.324,
			2915.164,
			2916.11,
			2917.057,
			2919.892,
			2921.784,
			2924.617,
			2926.508,
			2929.347,
			2931.24,
			2932.189,
			2934.085,
			2935.981,
			2936.934,
			2937.885,
			2940.738,
			2943.601,
			2945.506,
			2947.417,
			2949.324,
			2950.276,
			2952.182,
			2953.134,
			2955.034,
			2956.929,
			2959.76,
			2961.643,
			2964.455,
			2965.392,
			2966.328,
			2969.131,
			2970.999,
			2973.801,
			2975.669,
			2977.543,
			2978.481,
			2980.358,
			2981.299,
			2983.187,
			2985.078,
			2986.025,
			2987.935,
			2989.847,
			2992.742,
			2994.682,
			2997.614,
			2998.597,
			2999.579,
			3002.535,
			3004.508,
			3006.478,
			3009.406,
			3011.355,
			3014.253,
			3015.218,
			3016.183,
			3019.072,
			3020.995,
			3023.883,
			3025.805,
			3028.692,
			3030.615,
			3031.578,
			3033.507,
			3035.436,
			3036.402,
			3038.341,
			3040.281,
			3043.198,
			3044.171,
			3045.145,
			3048.068,
			3049.043,
			3050.019,
			3052.947,
			3054.897,
			3057.823,
			3059.771,
			3062.691,
			3064.634,
			3065.605,
			3066.577,
			3070.451,
			3071.42,
			3074.316,
			3076.248,
			3078.176,
			3079.14,
			3081.069,
			3083,
			3083.966,
			3086.87,
			3088.816,
			3090.768,
			3093.698,
			3095.676,
			3098.666,
			3099.669,
			3100.675,
			3103.71,
			3105.749,
			3108.831,
			3110.902,
			3113.009,
			3114.059,
			3116.165,
			3117.221,
			3119.348,
			3121.481,
			3122.561,
			3124.733,
			3126.907,
			3129.117,
			3131.328,
			3132.441,
			3134.68,
			3136.919,
			3138.051,
			3140.313,
			3141.445,
			3143.717,
			3145.989,
			3149.374,
			3151.622,
			3154.938,
			3156.041,
			3157.142,
			3160.393,
			3161.477,
			3162.556,
			3165.772,
			3167.915,
			3170.052,
			3171.123,
			3173.266,
			3174.34,
			3176.493,
			3178.654,
			3179.738,
			3181.912,
			3184.095,
			3187.387,
			3188.488,
			3189.592,
			3191.806,
			3194.029,
			3195.144,
			3198.503,
			3200.735,
			3202.996,
			3206.405,
			3208.691,
			3212.127,
			3213.271,
			3214.413,
			3217.811,
			3220.044,
			3223.328,
			3225.469,
			3227.573,
			3228.61,
			3230.656,
			3231.666,
			3233.657,
			3235.612,
			3236.576,
			3238.476,
			3240.34,
			3243.07,
			3244.848,
			3247.452,
			3248.302,
			3249.143,
			3251.611,
			3253.211,
			3255.542,
			3257.05,
			3258.522,
			3260.662,
			3261.357,
			3262.044,
			3264.707,
			3265.352,
			3267.25,
			3268.474,
			3269.678,
			3270.277,
			3271.47,
			3272.663,
			3273.259,
			3274.455,
			3275.055,
			3276.26,
			3277.474,
			3279.3,
			3280.519,
			3282.347,
			3282.957,
			3283.568,
			3285.388,
			3286.589,
			3288.394,
			3289.608,
			3290.817,
			3291.42,
			3292.626,
			3293.251,
			3294.51,
			3295.763,
			3296.388,
			3297.012,
			3298.941,
			3300.895,
			3301.542,
			3302.188,
			3303.508,
			3304.849,
			3305.515,
			3306.84,
			3307.5,
			3308.827,
			3310.145,
			3312.102,
			3313.388,
			3315.271,
			3315.893,
			3316.513,
			3318.359,
			3319.565,
			3321.358,
			3322.548,
			3323.74,
			3324.334,
			3325.518,
			3326.108,
			3327.288,
			3328.487,
			3329.095,
			3330.308,
			3331.521,
			3333.366,
			3334.006,
			3334.646,
			3336.568,
			3337.211,
			3337.855,
			3339.86,
			3341.204,
			3342.554,
			3344.601,
			3345.974,
			3348.051,
			3348.72,
			3349.387,
			3351.402,
			3352.76,
			3354.697,
			3355.982,
			3357.281,
			3357.935,
			3359.211,
			3359.85,
			3361.136,
			3362.437,
			3363.092,
			3364.414,
			3365.751,
			3367.786,
			3368.474,
			3369.168,
			3371.273,
			3371.983,
			3372.697,
			3374.87,
			3376.339,
			3378.574,
			3380.085,
			3381.613,
			3382.382,
			3383.933,
			3384.714,
			3385.499,
			3387.876,
			3389.48,
			3391.9,
			3393.54,
			3395.195,
			3396.027,
			3397.702,
			3399.377,
			3400.215,
			3401.897,
			3402.744,
			3404.446,
			3406.161,
			3408.758,
			3410.504,
			3413.149,
			3414.037,
			3414.928,
			3417.623,
			3419.436,
			3422.18,
			3424.027,
			3425.889,
			3426.824,
			3428.705,
			3429.652,
			3431.555,
			3433.471,
			3434.434,
			3436.371,
			3438.321,
			3440.284,
			3443.252,
			3445.247,
			3447.254,
			3448.262,
			3450.287,
			3451.303,
			3453.345,
			3455.398,
			3458.498,
			3460.578,
			3463.719,
			3464.77,
			3465.821,
			3468.973,
			3471.083,
			3474.269,
			3476.405,
			3478.551,
			3479.627,
			3481.789,
			3482.873,
			3485.048,
			3488.33,
			3490.531,
			3492.741,
			3496.075,
			3497.191,
			3498.31,
			3501.681,
			3502.81,
			3503.94,
			3506.21,
			3507.348,
			3509.633,
			3511.927,
			3515.386,
			3517.705,
			3521.2,
			3522.369,
			3523.542,
			3527.071,
			3529.437,
			3533,
			3535.386,
			3538.981,
			3541.387,
			3542.593,
			3545.011,
			3547.456,
			3548.672,
			3551.107,
			3553.547,
			3557.215,
			3558.438,
			3559.662,
			3563.327,
			3564.547,
			3565.766,
			3569.412,
			3571.833,
			3575.448,
			3577.849,
			3580.243,
			3583.829,
			3585.022,
			3586.223,
			3589.823,
			3591.033,
			3592.245,
			3595.889,
			3598.334,
			3602.011,
			3604.469,
			3606.929,
			3608.16,
			3611.845,
			3614.295,
			3616.742,
			3620.408,
			3622.86,
			3626.542,
			3627.773,
			3629.003,
			3632.708,
			3635.18,
			3638.908,
			3641.397,
			3643.891,
			3645.137,
			3647.629,
			3648.874,
			3650.118,
			3653.833,
			3655.07,
			3657.531,
			3659.986,
			3662.428,
			3664.865,
			3666.082,
			3668.512,
			3670.942,
			3672.157,
			3674.587,
			3675.803,
			3678.238,
			3680.677,
			3684.352,
			3686.812,
			3690.52,
			3691.759,
			3693,
			3696.727,
			3699.212,
			3702.936,
			3705.413,
			3709.112,
			3711.574,
			3712.798,
			3715.247,
			3717.688,
			3718.907,
			3721.347,
			3723.781,
			3727.436,
			3728.655,
			3729.875,
			3733.541,
			3734.765,
			3735.99,
			3738.447,
			3739.677,
			3742.144,
			3744.618,
			3745.858,
			3748.344,
			3750.836,
			3754.588,
			3755.843,
			3757.099,
			3760.88,
			3763.412,
			3767.225,
			3769.778,
			3772.317,
			3773.621,
			3776.17,
			3777.458,
			3780.057,
			3782.642,
			3783.915,
			3786.508,
			3789.097,
			3792.989,
			3794.289,
			3795.591,
			3799.5,
			3800.803,
			3802.106,
			3806.018,
			3808.63,
			3812.561,
			3815.19,
			3817.824,
			3821.788,
			3823.113,
			3824.439,
			3828.428,
			3829.762,
			3831.098,
			3835.117,
			3837.806,
			3841.857,
			3844.569,
			3847.288,
			3848.65,
			3851.381,
			3852.748,
			3855.489,
			3858.235,
			3862.365,
			3865.126,
			3869.28,
			3870.667,
			3872.057,
			3876.233,
			3879.025,
			3883.226,
			3886.034,
			3890.259,
			3893.084,
			3894.5,
			3897.334,
			3900.176,
			3901.598,
			3904.449,
			3907.306,
			3910.17,
			3913.04,
			3914.477,
			3917.357,
			3920.243,
			3921.689,
			3924.586,
			3926.037,
			3928.944,
			3931.858,
			3936.24,
			3939.17,
			3943.577,
			3945.05,
			3946.525,
			3950.959,
			3953.923,
			3958.382,
			3961.362,
			3964.349,
			3965.845,
			3968.842,
			3970.343,
			3973.35,
			3976.362,
			3977.87,
			3980.892,
			3983.921,
			3988.475,
			3989.992,
			3991.508,
			3994.525,
			3997.515,
			3998.997,
			4001.934,
			4003.389,
			4006.272,
			4009.118,
			4013.315,
			4016.068,
			4020.128,
			4021.464,
			4022.792,
			4026.718,
			4029.289,
			4033.078,
			4035.559,
			4038.002,
			4039.21,
			4041.6,
			4042.782,
			4045.12,
			4047.424,
			4048.562,
			4050.809,
			4053.02,
			4056.266,
			4057.331,
			4058.386,
			4061.498,
			4062.516,
			4063.526,
			4066.501,
			4068.438,
			4071.278,
			4073.125,
			4074.94,
			4077.6,
			4078.47,
			4079.331,
			4082.684,
			4083.5,
			4085.892,
			4087.433,
			4088.95,
			4089.688,
			4091.138,
			4092.538,
			4093.227,
			4094.584,
			4095.256,
			4096.582,
			4097.883,
			4099.81,
			4101.08,
			4102.968,
			4103.593,
			4104.218,
			4106.084,
			4107.341,
			4109.221,
			4110.471,
			4111.734,
			4112.374,
			4113.652,
			4114.29,
			4115.567,
			4116.861,
			4117.517,
			4118.172,
			4120.139,
			4121.457,
			4122.799,
			4123.471,
			4124.816,
			4126.166,
			4126.848,
			4128.215,
			4128.898,
			4130.266,
			4131.637,
			4133.704,
			4135.08,
			4137.153,
			4137.85,
			4138.546,
			4140.631,
			4142.032,
			4144.157,
			4145.574,
			4147.005,
			4147.734,
			4149.192,
			4149.921,
			4151.38,
			4153.627,
			4155.126,
			4156.625,
			4158.935,
			4159.702,
			4160.468,
			4162.007,
			4163.569,
			4164.347,
			4166.674,
			4168.234,
			4169.788,
			4172.11,
			4173.636,
			4175.912,
			4176.669,
			4177.417,
			4179.642,
			4181.122,
			4183.324,
			4184.781,
			4186.234,
			4186.96,
			4188.39,
			4189.102,
			4190.522,
			4192.635,
			4194.02,
			4195.4,
			4197.458,
			4198.804,
			4200.817,
			4201.487,
			4202.154,
			4204.109,
			4205.41,
			4206.715,
			4208.655,
			4209.946,
			4210.593,
			4211.89,
			4212.546,
			4213.205,
			4215.185,
			4215.847,
			4216.511,
			4218.535,
			4219.897,
			4221.268,
			4221.957,
			4223.354,
			4224.762,
			4225.47,
			4227.611,
			4229.062,
			4230.513,
			4232.735,
			4234.245,
			4236.54,
			4237.313,
			4238.096,
			4240.472,
			4242.077,
			4244.553,
			4246.217,
			4248.76,
			4250.485,
			4251.353,
			4252.224,
			4254.879,
			4255.765,
			4256.65,
			4259.345,
			4261.162,
			4262.988,
			4263.906,
			4265.745,
			4267.59,
			4268.507,
			4270.338,
			4271.254,
			4273.088,
			4274.905,
			4277.632,
			4279.447,
			4282.166,
			4283.074,
			4283.982,
			4286.708,
			4288.528,
			4291.275,
			4293.104,
			4294.95,
			4295.873,
			4297.716,
			4298.639,
			4300.498,
			4303.28,
			4305.14,
			4306.998,
			4309.788,
			4310.719,
			4311.65,
			4314.451,
			4315.384,
			4316.318,
			4319.131,
			4321.01,
			4322.897,
			4325.728,
			4327.62,
			4330.461,
			4331.409,
			4332.359,
			4335.219,
			4337.13,
			4340.013,
			4341.941,
			4344.849,
			4346.794,
			4347.771,
			4349.731,
			4352.683,
			4354.664,
			4356.652,
			4359.652,
			4360.655,
			4361.66,
			4364.693,
			4365.691,
			4366.708,
			4369.776,
			4371.837,
			4374.949,
			4377.041,
			4379.134,
			4382.286,
			4383.342,
			4384.4,
			4387.621,
			4388.677,
			4389.754,
			4393.02,
			4395.229,
			4397.433,
			4398.548,
			4400.81,
			4403.082,
			4404.222,
			4406.529,
			4407.694,
			4410.033,
			4412.399,
			4415.973,
			4418.374,
			4421.981,
			4423.181,
			4424.383,
			4427.979,
			4430.378,
			4433.971,
			4436.347,
			4438.742,
			4439.933,
			4442.31,
			4443.496,
			4445.872,
			4448.247,
			4449.436,
			4450.625,
			4454.205,
			4456.602,
			4459.008,
			4460.214,
			4462.634,
			4465.062,
			4466.279,
			4468.716,
			4469.938,
			4472.383,
			4474.83,
			4478.489,
			4480.968,
			4484.644,
			4485.879,
			4487.116,
			4490.834,
			4493.321,
			4497.065,
			4499.57,
			4502.083,
			4503.342,
			4505.865,
			4507.13,
			4509.663,
			4513.477,
			4516.028,
			4518.587,
			4522.438,
			4523.725,
			4525.014,
			4527.597,
			4530.188,
			4531.486,
			4534.088,
			4535.39,
			4537.993,
			4540.601,
			4544.523,
			4547.146,
			4551.088,
			4552.405,
			4553.724,
			4557.687,
			4560.335,
			4564.319,
			4566.983,
			4570.993,
			4573.675,
			4576.363,
			4577.71,
			4581.763,
			4584.473,
			4587.189,
			4591.279,
			4592.646,
			4594.016,
			4598.133,
			4599.508,
			4600.886,
			4603.646,
			4605.028,
			4607.8,
			4610.578,
			4614.759,
			4617.557,
			4621.766,
			4623.172,
			4624.581,
			4628.818,
			4631.651,
			4635.916,
			4638.767,
			4641.626,
			4643.059,
			4645.928,
			4648.807,
			4650.248,
			4653.137,
			4654.584,
			4657.483,
			4660.39,
			4664.762,
			4667.687,
			4672.087,
			4673.558,
			4675.03,
			4679.456,
			4682.417,
			4686.87,
			4689.848,
			4692.832,
			4697.322,
			4698.821,
			4700.323,
			4704.838,
			4706.347,
			4709.368,
			4712.397,
			4715.433,
			4718.477,
			4720.001,
			4723.056,
			4726.118,
			4727.651,
			4730.725,
			4732.263,
			4735.346,
			4738.437,
			4743.084,
			4746.191,
			4750.865,
			4752.427,
			4753.989,
			4758.686,
			4761.823,
			4766.539,
			4769.687,
			4774.419,
			4777.581,
			4779.163,
			4782.332,
			4785.504,
			4787.092,
			4788.682,
			4793.456,
			4796.644,
			4799.834,
			4801.429,
			4804.622,
			4807.819,
			4809.419,
			4812.622,
			4814.225,
			4817.434,
			4822.253,
			4825.471,
			4828.692,
			4833.53,
			4835.144,
			4836.76,
			4841.613,
			4844.854,
			4849.723,
			4852.974,
			4856.23,
			4857.859,
			4861.12,
			4862.752,
			4866.02,
			4869.292,
			4870.929,
			4874.207,
			4877.489,
			4882.421,
			4884.067,
			4885.714,
			4890.662,
			4892.313,
			4893.967,
			4898.934,
			4902.251,
			4905.573,
			4910.564,
			4913.896,
			4918.9,
			4920.571,
			4922.242,
			4927.263,
			4928.938,
			4930.615,
			4935.651,
			4939.015,
			4942.381,
			4944.066,
			4947.437,
			4950.811,
			4952.499,
			4955.878,
			4957.568,
			4960.953,
			4964.34,
			4969.429,
			4972.826,
			4977.928,
			4979.631,
			4981.334,
			4986.449,
			4989.865,
			4994.996,
			4998.421,
			5001.851,
			5003.567,
			5007.001,
			5008.719,
			5010.439,
			5017.326,
			5019.05,
			5024.226,
			5027.682,
			5031.142,
			5032.873,
			5036.338,
			5039.808,
			5041.544,
			5045.019,
			5046.757,
			5050.238,
			5053.722,
			5058.954,
			5062.436,
			5067.625,
			5069.341,
			5071.047,
			5076.111,
			5079.436,
			5084.351,
			5087.578,
			5090.766,
			5092.345,
			5095.475,
			5098.566,
			5100.098,
			5103.133,
			5104.637,
			5107.618,
			5110.561,
			5114.901,
			5116.329,
			5117.747,
			5120.552,
			5123.318,
			5124.687,
			5127.395,
			5128.734,
			5131.385,
			5133.997,
			5137.845,
			5140.362,
			5144.067,
			5145.284,
			5146.491,
			5150.059,
			5152.394,
			5155.828,
			5158.069,
			5160.274,
			5161.362,
			5163.512,
			5164.573,
			5166.667,
			5168.725,
			5169.739,
			5171.759,
			5173.727,
			5176.618,
			5178.503,
			5181.272,
			5182.181,
			5183.083,
			5184.875,
			5185.765,
			5187.537,
			5189.303,
			5191.966,
			5193.742,
			5196.421,
			5197.313,
			5198.204,
			5201.773,
			5202.664,
			5205.326,
			5207.099,
			5208.866,
			5209.75,
			5211.52,
			5213.288,
			5214.173,
			5215.954,
			5216.846,
			5218.63,
			5220.425,
			5223.135,
			5224.038,
			5224.944,
			5227.695,
			5228.611,
			5229.527,
			5232.319,
			5234.184,
			5236.981,
			5238.845,
			5240.7,
			5241.613,
			5243.439,
			5244.352,
			5246.165,
			5247.958,
			5248.854,
			5249.749,
			5252.422,
			5254.197,
			5256.856,
			5258.622,
			5260.386,
			5261.269,
			5263.029,
			5263.909,
			5265.667,
			5267.421,
			5270.047,
			5271.797,
			5274.419,
			5275.293,
			5276.167,
			5278.786,
			5280.53,
			5283.152,
			5284.898,
			5286.646,
			5287.521,
			5289.269,
			5290.142,
			5291.889,
			5293.637,
			5294.51,
			5296.254,
			5298.003,
			5299.75,
			5302.373,
			5304.126,
			5305.876,
			5306.751,
			5308.503,
			5309.377,
			5311.124,
			5312.868,
			5315.477,
			5317.215,
			5319.821,
			5320.688,
			5321.555,
			5324.155,
			5325.884,
			5328.479,
			5330.207,
			5331.933,
			5332.796,
			5334.521,
			5335.382,
			5337.103,
			5338.828,
			5339.689,
			5341.412,
			5343.137,
			5345.731,
			5347.46,
			5350.071,
			5350.942,
			5351.814,
			5353.565,
			5354.446,
			5356.209,
			5357.977,
			5360.65,
			5362.438,
			5365.14,
			5366.044,
			5366.95,
			5369.685,
			5371.521,
			5374.288,
			5376.142,
			5378.004,
			5378.939,
			5380.813,
			5381.753,
			5383.641,
			5386.487,
			5388.397,
			5390.318,
			5393.221,
			5395.176,
			5398.136,
			5399.129,
			5400.135,
			5403.171,
			5405.222,
			5408.338,
			5410.444,
			5413.642,
			5415.796,
			5416.879,
			5417.966,
			5422.353,
			5423.458,
			5426.789,
			5429.022,
			5432.345,
			5434.561,
			5436.761,
			5437.864,
			5440.073,
			5441.174,
			5443.38,
			5445.593,
			5448.919,
			5451.148,
			5454.505,
			5455.629,
			5456.756,
			5460.144,
			5462.416,
			5465.835,
			5468.123,
			5470.417,
			5471.568,
			5473.875,
			5475.031,
			5477.34,
			5479.65,
			5480.809,
			5481.965,
			5485.438,
			5488.902,
			5490.059,
			5491.217,
			5493.526,
			5495.841,
			5496.999,
			5499.316,
			5500.477,
			5502.798,
			5505.121,
			5508.615,
			5510.952,
			5514.475,
			5515.654,
			5516.835,
			5520.394,
			5522.776,
			5526.363,
			5528.764,
			5531.173,
			5532.381,
			5534.782,
			5535.997,
			5538.431,
			5540.873,
			5542.098,
			5544.553,
			5547.017,
			5550.728,
			5551.969,
			5553.213,
			5556.956,
			5558.208,
			5559.461,
			5561.976,
			5563.236,
			5565.764,
			5568.3,
			5572.121,
			5574.679,
			5578.53,
			5579.819,
			5581.108,
			5584.992,
			5587.593,
			5591.507,
			5594.12,
			5596.742,
			5598.057,
			5600.692,
			5603.339,
			5604.666,
			5607.327,
			5608.66,
			5611.334,
			5614.017,
			5618.057,
			5620.761,
			5624.837,
			5626.2,
			5627.565,
			5631.674,
			5634.429,
			5638.609,
			5641.397,
			5644.182,
			5648.436,
			5649.849,
			5651.239,
			5656.932,
			5658.351,
			5662.617,
			5665.448,
			5668.336,
			5669.772,
			5672.649,
			5675.537,
			5676.981,
			5679.847,
			5681.293,
			5684.185,
			5687.105,
			5691.46,
			5694.364,
			5698.722,
			5700.172,
			5701.625,
			5705.953,
			5708.858,
			5713.217,
			5716.146,
			5719.056,
			5720.486,
			5723.399,
			5724.854,
			5727.766,
			5732.14,
			5735.061,
			5737.987,
			5740.919,
			5743.857,
			5745.329,
			5748.278,
			5751.233,
			5752.712,
			5757.159,
			5760.13,
			5763.106,
			5767.578,
			5770.567,
			5775.061,
			5776.561,
			5778.062,
			5782.577,
			5785.595,
			5790.133,
			5793.165,
			5796.203,
			5797.725,
			5800.771,
			5802.296,
			5805.352,
			5808.412,
			5809.944,
			5813.014,
			5816.088,
			5819.167,
			5822.252,
			5823.797,
			5826.889,
			5829.986,
			5831.536,
			5834.641,
			5836.195,
			5839.307,
			5842.424,
			5847.11,
			5850.241,
			5854.945,
			5856.517,
			5858.088,
			5862.812,
			5865.967,
			5870.708,
			5873.873,
			5877.044,
			5878.631,
			5881.81,
			5884.992,
			5886.585,
			5891.363,
			5894.554,
			5897.749,
			5902.549,
			5905.755,
			5910.573,
			5912.181,
			5913.789,
			5915.108
		],
		[
			0,
			0.023,
			0.053,
			0.083,
			0.147,
			0.162,
			0.207,
			0.271,
			0.331,
			0.378,
			0.457,
			0.487,
			0.517,
			0.581,
			0.642,
			0.705,
			0.765,
			0.828,
			0.891,
			0.922,
			0.951,
			1.014,
			1.031,
			1.076,
			1.139,
			1.2,
			1.248,
			1.325,
			1.355,
			1.387,
			1.447,
			1.511,
			1.574,
			1.636,
			1.682,
			1.759,
			1.79,
			1.821,
			1.884,
			1.899,
			1.944,
			2.006,
			2.067,
			2.115,
			2.131,
			2.193,
			2.222,
			2.254,
			2.331,
			2.379,
			2.44,
			2.504,
			2.565,
			2.627,
			2.656,
			2.688,
			2.765,
			2.811,
			2.879,
			2.937,
			2.982,
			3,
			3.059,
			3.093,
			3.123,
			3.201,
			3.247,
			3.309,
			3.371,
			3.415,
			3.431,
			3.496,
			3.527,
			3.557,
			3.617,
			3.636,
			3.681,
			3.742,
			3.804,
			3.851,
			3.929,
			3.958,
			3.99,
			4.054,
			4.114,
			4.178,
			4.237,
			4.285,
			4.301,
			4.364,
			4.395,
			4.426,
			4.488,
			4.502,
			4.549,
			4.61,
			4.673,
			4.721,
			4.735,
			4.796,
			4.827,
			4.86,
			4.921,
			4.941,
			4.983,
			5.046,
			5.107,
			5.154,
			5.232,
			5.262,
			5.294,
			5.355,
			5.416,
			5.478,
			5.54,
			5.588,
			5.604,
			5.664,
			5.696,
			5.728,
			5.805,
			5.852,
			5.914,
			5.975,
			6.021,
			6.037,
			6.099,
			6.131,
			6.161,
			6.223,
			6.284,
			6.348,
			6.41,
			6.457,
			6.532,
			6.565,
			6.595,
			6.657,
			6.673,
			6.718,
			6.781,
			6.843,
			6.89,
			6.905,
			6.967,
			7.004,
			7.029,
			7.092,
			7.106,
			7.153,
			7.214,
			7.276,
			7.323,
			7.403,
			7.431,
			7.465,
			7.527,
			7.588,
			7.649,
			7.711,
			7.756,
			7.773,
			7.836,
			7.865,
			7.898,
			7.96,
			7.976,
			8.021,
			8.084,
			8.144,
			8.19,
			8.208,
			8.268,
			8.299,
			8.331,
			8.393,
			8.409,
			8.457,
			8.518,
			8.579,
			8.627,
			8.704,
			8.734,
			8.765,
			8.829,
			8.89,
			8.951,
			9.012,
			9.067,
			9.076,
			9.138,
			9.167,
			9.199,
			9.261,
			9.278,
			9.323,
			9.387,
			9.447,
			9.494,
			9.508,
			9.572,
			9.603,
			9.634,
			9.695,
			9.711,
			9.759,
			9.821,
			9.881,
			9.944,
			10.006,
			10.037,
			10.067,
			10.129,
			10.191,
			10.255,
			10.317,
			10.362,
			10.377,
			10.44,
			10.471,
			10.502,
			10.564,
			10.578,
			10.626,
			10.688,
			10.749,
			10.796,
			10.813,
			10.873,
			10.906,
			10.934,
			11.014,
			11.061,
			11.128,
			11.182,
			11.232,
			11.307,
			11.34,
			11.371,
			11.432,
			11.493,
			11.556,
			11.618,
			11.679,
			11.743,
			11.773,
			11.805,
			11.867,
			11.881,
			11.929,
			11.99,
			12.053,
			12.099,
			12.176,
			12.207,
			12.237,
			12.3,
			12.361,
			12.423,
			12.484,
			12.531,
			12.549,
			12.611,
			12.64,
			12.673,
			12.732,
			12.75,
			12.796,
			12.859,
			12.922,
			12.967,
			12.982,
			13.043,
			13.076,
			13.107,
			13.169,
			13.188,
			13.231,
			13.292,
			13.354,
			13.418,
			13.479,
			13.508,
			13.54,
			13.603,
			13.665,
			13.726,
			13.788,
			13.835,
			13.849,
			13.913,
			13.943,
			13.973,
			14.034,
			14.051,
			14.099,
			14.16,
			14.221,
			14.267,
			14.285,
			14.345,
			14.377,
			14.409,
			14.486,
			14.531,
			14.595,
			14.656,
			14.703,
			14.781,
			14.812,
			14.842,
			14.905,
			14.968,
			15.029,
			15.09,
			15.137,
			15.154,
			15.216,
			15.25,
			15.276,
			15.338,
			15.354,
			15.4,
			15.464,
			15.525,
			15.572,
			15.588,
			15.647,
			15.678,
			15.71,
			15.772,
			15.836,
			15.898,
			15.96,
			16.005,
			16.082,
			16.114,
			16.145,
			16.208,
			16.223,
			16.268,
			16.331,
			16.394,
			16.455,
			16.518,
			16.549,
			16.579,
			16.64,
			16.657,
			16.704,
			16.764,
			16.826,
			16.873,
			16.95,
			16.981,
			17.014,
			17.076,
			17.137,
			17.198,
			17.262,
			17.312,
			17.324,
			17.385,
			17.416,
			17.448,
			17.525,
			17.572,
			17.638,
			17.696,
			17.742,
			17.757,
			17.82,
			17.851,
			17.882,
			17.943,
			17.96,
			18.005,
			18.067,
			18.129,
			18.19,
			18.253,
			18.283,
			18.314,
			18.378,
			18.44,
			18.5,
			18.564,
			18.61,
			18.626,
			18.688,
			18.718,
			18.748,
			18.812,
			18.826,
			18.874,
			18.936,
			18.997,
			19.06,
			19.121,
			19.153,
			19.185,
			19.246,
			19.262,
			19.308,
			19.368,
			19.374,
			19.432,
			19.478,
			19.557,
			19.588,
			19.619,
			19.679,
			19.74,
			19.805,
			19.865,
			19.913,
			19.927,
			19.991,
			20.021,
			20.053,
			20.112,
			20.13,
			20.176,
			20.243,
			20.3,
			20.362,
			20.422,
			20.455,
			20.484,
			20.549,
			20.61,
			20.673,
			20.733,
			20.796,
			20.857,
			20.889,
			20.92,
			20.982,
			21.043,
			21.105,
			21.166,
			21.214,
			21.228,
			21.293,
			21.323,
			21.354,
			21.417,
			21.438,
			21.478,
			21.539,
			21.603,
			21.649,
			21.727,
			21.756,
			21.788,
			21.85,
			21.911,
			21.973,
			22.036,
			22.081,
			22.099,
			22.161,
			22.191,
			22.222,
			22.284,
			22.299,
			22.345,
			22.407,
			22.471,
			22.518,
			22.532,
			22.593,
			22.626,
			22.657,
			22.717,
			22.734,
			22.779,
			22.843,
			22.905,
			22.949,
			23.027,
			23.059,
			23.089,
			23.153,
			23.215,
			23.28,
			23.338,
			23.399,
			23.461,
			23.498,
			23.523,
			23.584,
			23.601,
			23.648,
			23.711,
			23.772,
			23.817,
			23.836,
			23.896,
			23.926,
			23.957,
			24.036,
			24.081,
			24.146,
			24.207,
			24.254,
			24.33,
			24.363,
			24.393,
			24.454,
			24.515,
			24.579,
			24.64,
			24.687,
			24.703,
			24.764,
			24.796,
			24.827,
			24.888,
			24.905,
			24.949,
			25.012,
			25.076,
			25.121,
			25.134,
			25.199,
			25.229,
			25.26,
			25.322,
			25.386,
			25.446,
			25.51,
			25.57,
			25.632,
			25.662,
			25.693,
			25.756,
			25.773,
			25.817,
			25.881,
			25.944,
			26.005,
			26.068,
			26.097,
			26.129,
			26.19,
			26.206,
			26.252,
			26.314,
			26.376,
			26.424,
			26.499,
			26.531,
			26.561,
			26.624,
			26.688,
			26.749,
			26.81,
			26.857,
			26.872,
			26.934,
			26.965,
			26.996,
			27.073,
			27.122,
			27.182,
			27.246,
			27.292,
			27.307,
			27.367,
			27.401,
			27.43,
			27.494,
			27.508,
			27.556,
			27.623,
			27.68,
			27.74,
			27.804,
			27.833,
			27.865,
			27.926,
			27.988,
			28.049,
			28.113,
			28.175,
			28.237,
			28.266,
			28.3,
			28.362,
			28.375,
			28.423,
			28.486,
			28.546,
			28.594,
			28.608,
			28.67,
			28.703,
			28.734,
			28.796,
			28.81,
			28.856,
			28.919,
			28.982,
			29.029,
			29.105,
			29.137,
			29.165,
			29.23,
			29.29,
			29.354,
			29.414,
			29.461,
			29.477,
			29.538,
			29.571,
			29.601,
			29.662,
			29.682,
			29.726,
			29.787,
			29.849,
			29.912,
			29.972,
			30.005,
			30.036,
			30.098,
			30.16,
			30.223,
			30.284,
			30.331,
			30.408,
			30.44,
			30.468,
			30.532,
			30.593,
			30.657,
			30.717,
			30.779,
			30.842,
			30.872,
			30.904,
			30.965,
			30.982,
			31.029,
			31.09,
			31.152,
			31.199,
			31.277,
			31.307,
			31.338,
			31.4,
			31.462,
			31.523,
			31.584,
			31.632,
			31.648,
			31.709,
			31.745,
			31.773,
			31.85,
			31.894,
			31.957,
			32.021,
			32.067,
			32.082,
			32.143,
			32.173,
			32.205,
			32.267,
			32.282,
			32.329,
			32.39,
			32.454,
			32.501,
			32.578,
			32.607,
			32.64,
			32.717,
			32.765,
			32.826,
			32.888,
			32.932,
			32.95,
			33.011,
			33.044,
			33.074,
			33.137,
			33.153,
			33.199,
			33.262,
			33.321,
			33.368,
			33.382,
			33.445,
			33.477,
			33.508,
			33.586,
			33.633,
			33.694,
			33.756,
			33.817,
			33.879,
			33.911,
			33.942,
			34.018,
			34.065,
			34.129,
			34.191,
			34.253,
			34.314,
			34.345,
			34.376,
			34.455,
			34.501,
			34.561,
			34.623,
			34.671,
			34.687,
			34.747,
			34.778,
			34.81,
			34.873,
			34.934,
			35,
			35.057,
			35.105,
			35.182,
			35.214,
			35.243,
			35.307,
			35.367,
			35.436,
			35.493,
			35.537,
			35.554,
			35.617,
			35.647,
			35.678,
			35.74,
			35.757,
			35.803,
			35.867,
			35.926,
			35.974,
			36.05,
			36.08,
			36.112,
			36.176,
			36.238,
			36.3,
			36.36,
			36.407,
			36.424,
			36.485,
			36.516,
			36.547,
			36.608,
			36.624,
			36.671,
			36.732,
			36.793,
			36.842,
			36.857,
			36.919,
			36.949,
			36.979,
			37.057,
			37.105,
			37.166,
			37.229,
			37.275,
			37.353,
			37.382,
			37.414,
			37.476,
			37.54,
			37.601,
			37.664,
			37.709,
			37.724,
			37.788,
			37.817,
			37.849,
			37.912,
			37.929,
			37.974,
			38.034,
			38.096,
			38.144,
			38.159,
			38.221,
			38.251,
			38.282,
			38.344,
			38.361,
			38.406,
			38.467,
			38.53,
			38.593,
			38.656,
			38.686,
			38.716,
			38.779,
			38.842,
			38.903,
			38.966,
			39.027,
			39.089,
			39.121,
			39.151,
			39.213,
			39.229,
			39.276,
			39.338,
			39.399,
			39.46,
			39.524,
			39.554,
			39.585,
			39.662,
			39.708,
			39.77,
			39.834,
			39.879,
			39.957,
			39.992,
			40.02,
			40.097,
			40.142,
			40.205,
			40.267,
			40.315,
			40.329,
			40.392,
			40.421,
			40.451,
			40.514,
			40.532,
			40.577,
			40.638,
			40.702,
			40.749,
			40.826,
			40.857,
			40.887,
			40.949,
			41.01,
			41.073,
			41.136,
			41.182,
			41.259,
			41.29,
			41.321,
			41.382,
			41.399,
			41.446,
			41.507,
			41.571,
			41.615,
			41.632,
			41.694,
			41.725,
			41.756,
			41.817,
			41.834,
			41.879,
			41.942,
			42.005,
			42.054,
			42.127,
			42.159,
			42.189,
			42.251,
			42.313,
			42.377,
			42.438,
			42.483,
			42.499,
			42.561,
			42.593,
			42.623,
			42.686,
			42.699,
			42.747,
			42.81,
			42.871,
			42.919,
			42.932,
			42.995,
			43.026,
			43.057,
			43.12,
			43.134,
			43.183,
			43.245,
			43.306,
			43.351,
			43.429,
			43.462,
			43.492,
			43.568,
			43.615,
			43.678,
			43.739,
			43.787,
			43.803,
			43.864,
			43.894,
			43.927,
			43.988,
			44.004,
			44.048,
			44.116,
			44.173,
			44.236,
			44.297,
			44.33,
			44.359,
			44.423,
			44.482,
			44.546,
			44.608,
			44.655,
			44.732,
			44.762,
			44.793,
			44.857,
			44.917,
			44.979,
			45.043,
			45.105,
			45.165,
			45.196,
			45.228,
			45.29,
			45.304,
			45.353,
			45.415,
			45.476,
			45.538,
			45.599,
			45.632,
			45.661,
			45.723,
			45.787,
			45.852,
			45.909,
			45.957,
			45.973,
			46.035,
			46.064,
			46.095,
			46.157,
			46.176,
			46.221,
			46.282,
			46.344,
			46.39,
			46.405,
			46.468,
			46.5,
			46.531,
			46.593,
			46.608,
			46.654,
			46.717,
			46.778,
			46.824,
			46.903,
			46.934,
			46.964,
			47.026,
			47.087,
			47.15,
			47.211,
			47.259,
			47.273,
			47.338,
			47.367,
			47.4,
			47.477,
			47.522,
			47.584,
			47.646,
			47.694,
			47.708,
			47.771,
			47.803,
			47.833,
			47.893,
			47.911,
			47.957,
			48.021,
			48.081,
			48.126,
			48.205,
			48.24,
			48.265,
			48.343,
			48.39,
			48.453,
			48.515,
			48.578,
			48.64,
			48.668,
			48.699,
			48.763,
			48.777,
			48.823,
			48.887,
			48.949,
			48.996,
			49.01,
			49.073,
			49.105,
			49.136,
			49.195,
			49.213,
			49.259,
			49.321,
			49.381,
			49.43,
			49.506,
			49.538,
			49.567,
			49.632,
			49.647,
			49.693,
			49.756,
			49.818,
			49.864,
			49.878,
			49.94,
			49.971,
			50.004,
			50.065,
			50.079,
			50.128,
			50.191,
			50.25,
			50.312,
			50.376,
			50.406,
			50.438,
			50.498,
			50.561,
			50.624,
			50.686,
			50.748,
			50.81,
			50.841,
			50.871,
			50.932,
			50.95,
			50.996,
			51.057,
			51.118,
			51.165,
			51.182,
			51.243,
			51.273,
			51.305,
			51.367,
			51.383,
			51.429,
			51.491,
			51.554,
			51.615,
			51.676,
			51.706,
			51.74,
			51.802,
			51.864,
			51.926,
			51.987,
			52.049,
			52.111,
			52.143,
			52.173,
			52.249,
			52.296,
			52.365,
			52.421,
			52.482,
			52.544,
			52.576,
			52.607,
			52.684,
			52.732,
			52.795,
			52.856,
			52.915,
			52.979,
			53.011,
			53.04,
			53.105,
			53.166,
			53.228,
			53.29,
			53.35,
			53.412,
			53.444,
			53.476,
			53.538,
			53.553,
			53.598,
			53.662,
			53.722,
			53.771,
			53.785,
			53.849,
			53.879,
			53.91,
			53.97,
			53.988,
			54.033,
			54.096,
			54.157,
			54.205,
			54.282,
			54.312,
			54.344,
			54.404,
			54.427,
			54.468,
			54.529,
			54.591,
			54.655,
			54.714,
			54.746,
			54.779,
			54.84,
			54.854,
			54.903,
			54.964,
			55.026,
			55.073,
			55.149,
			55.181,
			55.212,
			55.29,
			55.336,
			55.398,
			55.46,
			55.522,
			55.583,
			55.615,
			55.646,
			55.706,
			55.723,
			55.768,
			55.832,
			55.893,
			55.956,
			56.018,
			56.05,
			56.081,
			56.143,
			56.158,
			56.204,
			56.268,
			56.327,
			56.375,
			56.45,
			56.487,
			56.513,
			56.577,
			56.639,
			56.699,
			56.761,
			56.824,
			56.884,
			56.916,
			56.947,
			57.009,
			57.027,
			57.071,
			57.133,
			57.197,
			57.259,
			57.32,
			57.351,
			57.381,
			57.445,
			57.46,
			57.507,
			57.569,
			57.629,
			57.693,
			57.755,
			57.786,
			57.817,
			57.879,
			57.941,
			58.001,
			58.065,
			58.112,
			58.127,
			58.188,
			58.221,
			58.248,
			58.311,
			58.327,
			58.374,
			58.439,
			58.498,
			58.55,
			58.623,
			58.654,
			58.683,
			58.761,
			58.808,
			58.872,
			58.932,
			58.978,
			59.056,
			59.088,
			59.118,
			59.179,
			59.243,
			59.304,
			59.367,
			59.429,
			59.49,
			59.521,
			59.554,
			59.616,
			59.629,
			59.676,
			59.74,
			59.8,
			59.846,
			59.862,
			59.926,
			59.957,
			59.985,
			60.048,
			60.11,
			60.173,
			60.236,
			60.298,
			60.357,
			60.391,
			60.421,
			60.497,
			60.545,
			60.61,
			60.667,
			60.716,
			60.729,
			60.793,
			60.825,
			60.855,
			60.932,
			60.977,
			61.039,
			61.104,
			61.165,
			61.226,
			61.259,
			61.29,
			61.35,
			61.412,
			61.473,
			61.538,
			61.582,
			61.6,
			61.662,
			61.693,
			61.724,
			61.786,
			61.8,
			61.848,
			61.91,
			61.971,
			62.018,
			62.033,
			62.094,
			62.126,
			62.156,
			62.219,
			62.233,
			62.282,
			62.344,
			62.405,
			62.451,
			62.529,
			62.559,
			62.592,
			62.653,
			62.674,
			62.715,
			62.776,
			62.84,
			62.886,
			62.902,
			62.964,
			62.994,
			63.025,
			63.088,
			63.103,
			63.15,
			63.211,
			63.273,
			63.32,
			63.334,
			63.396,
			63.429,
			63.46,
			63.522,
			63.582,
			63.645,
			63.707,
			63.755,
			63.832,
			63.862,
			63.893,
			63.955,
			64.016,
			64.079,
			64.142,
			64.189,
			64.204,
			64.265,
			64.297,
			64.328,
			64.39,
			64.404,
			64.45,
			64.514,
			64.576,
			64.623,
			64.699,
			64.734,
			64.761,
			64.824,
			64.886,
			64.948,
			65.009,
			65.055,
			65.132,
			65.164,
			65.194,
			65.273,
			65.32,
			65.381,
			65.443,
			65.491,
			65.505,
			65.568,
			65.598,
			65.629,
			65.692,
			65.707,
			65.755,
			65.817,
			65.879,
			65.923,
			66.002,
			66.031,
			66.064,
			66.126,
			66.187,
			66.251,
			66.312,
			66.359,
			66.374,
			66.433,
			66.468,
			66.499,
			66.56,
			66.576,
			66.621,
			66.682,
			66.747,
			66.797,
			66.808,
			66.868,
			66.899,
			66.932,
			66.993,
			67.008,
			67.055,
			67.117,
			67.18,
			67.227,
			67.304,
			67.336,
			67.365,
			67.428,
			67.49,
			67.55,
			67.614,
			67.662,
			67.676,
			67.738,
			67.77,
			67.799,
			67.862,
			67.877,
			67.925,
			67.988,
			68.049,
			68.094,
			68.11,
			68.173,
			68.204,
			68.232,
			68.311,
			68.357,
			68.42,
			68.482,
			68.545,
			68.607,
			68.638,
			68.668,
			68.746,
			68.793,
			68.859,
			68.917,
			68.961,
			68.978,
			69.04,
			69.071,
			69.102,
			69.165,
			69.179,
			69.225,
			69.288,
			69.351,
			69.396,
			69.411,
			69.474,
			69.506,
			69.536,
			69.598,
			69.662,
			69.724,
			69.784,
			69.831,
			69.847,
			69.909,
			69.94,
			69.972,
			70.032,
			70.094,
			70.158,
			70.218,
			70.265,
			70.279,
			70.343,
			70.374,
			70.405,
			70.465,
			70.483,
			70.529,
			70.59,
			70.654,
			70.698,
			70.776,
			70.806,
			70.838,
			70.901,
			70.962,
			71.025,
			71.088,
			71.132,
			71.149,
			71.21,
			71.242,
			71.274,
			71.333,
			71.35,
			71.398,
			71.458,
			71.521,
			71.582,
			71.644,
			71.676,
			71.707,
			71.768,
			71.782,
			71.83,
			71.893,
			71.954,
			72.018,
			72.079,
			72.11,
			72.14,
			72.202,
			72.265,
			72.328,
			72.389,
			72.436,
			72.449,
			72.514,
			72.542,
			72.573,
			72.654,
			72.699,
			72.76,
			72.823,
			72.867,
			72.886,
			72.947,
			72.982,
			73.007,
			73.071,
			73.088,
			73.133,
			73.194,
			73.256,
			73.304,
			73.382,
			73.413,
			73.443,
			73.52,
			73.565,
			73.63,
			73.689,
			73.753,
			73.816,
			73.845,
			73.877,
			73.938,
			73.954,
			74,
			74.064,
			74.125,
			74.171,
			74.188,
			74.25,
			74.281,
			74.312,
			74.373,
			74.436,
			74.499,
			74.559,
			74.606,
			74.684,
			74.715,
			74.746,
			74.806,
			74.822,
			74.867,
			74.931,
			74.993,
			75.055,
			75.117,
			75.147,
			75.179,
			75.257,
			75.303,
			75.367,
			75.427,
			75.472,
			75.549,
			75.581,
			75.614,
			75.676,
			75.738,
			75.799,
			75.862,
			75.909,
			75.923,
			75.985,
			76.017,
			76.047,
			76.111,
			76.126,
			76.173,
			76.234,
			76.296,
			76.342,
			76.357,
			76.418,
			76.449,
			76.482,
			76.543,
			76.56,
			76.604,
			76.668,
			76.729,
			76.777,
			76.853,
			76.885,
			76.915,
			76.994,
			77.041,
			77.105,
			77.162,
			77.226,
			77.287,
			77.317,
			77.349,
			77.411,
			77.427,
			77.473,
			77.533,
			77.597,
			77.645,
			77.66,
			77.723,
			77.754,
			77.782,
			77.846,
			77.861,
			77.908,
			77.971,
			77.972,
			78.031,
			78.079,
			78.155,
			78.188,
			78.219,
			78.281,
			78.343,
			78.408,
			78.465,
			78.511,
			78.529,
			78.59,
			78.621,
			78.653,
			78.714,
			78.729,
			78.777,
			78.841,
			78.899,
			78.946,
			78.962,
			79.024,
			79.054,
			79.086,
			79.149,
			79.209,
			79.272,
			79.335,
			79.396,
			79.459,
			79.489,
			79.521,
			79.582,
			79.598,
			79.644,
			79.705,
			79.769,
			79.831,
			79.892,
			79.923,
			79.953,
			80.015,
			80.032,
			80.079,
			80.14,
			80.203,
			80.249,
			80.328,
			80.356,
			80.387,
			80.451,
			80.511,
			80.573,
			80.634,
			80.697,
			80.76,
			80.792,
			80.822,
			80.886,
			80.899,
			80.947,
			81.007,
			81.072,
			81.117,
			81.132,
			81.193,
			81.229,
			81.257,
			81.316,
			81.332,
			81.381,
			81.442,
			81.504,
			81.549,
			81.63,
			81.659,
			81.69,
			81.768,
			81.813,
			81.876,
			81.94,
			81.984,
			82,
			82.062,
			82.094,
			82.126,
			82.188,
			82.203,
			82.25,
			82.311,
			82.372,
			82.418,
			82.434,
			82.495,
			82.527,
			82.558,
			82.62,
			82.634,
			82.682,
			82.743,
			82.807,
			82.854,
			82.932,
			82.96,
			82.994,
			83.054,
			83.117,
			83.179,
			83.242,
			83.291,
			83.303,
			83.363,
			83.396,
			83.427,
			83.487,
			83.505,
			83.55,
			83.614,
			83.675,
			83.721,
			83.738,
			83.799,
			83.831,
			83.862,
			83.922,
			83.986,
			84.047,
			84.107,
			84.154,
			84.232,
			84.265,
			84.293,
			84.373,
			84.42,
			84.482,
			84.543,
			84.589,
			84.606,
			84.668,
			84.699,
			84.728,
			84.79,
			84.808,
			84.851,
			84.915,
			84.977,
			85.023,
			85.101,
			85.133,
			85.162,
			85.226,
			85.288,
			85.354,
			85.412,
			85.457,
			85.474,
			85.536,
			85.565,
			85.598,
			85.66,
			85.675,
			85.721,
			85.784,
			85.846,
			85.891,
			85.907,
			85.968,
			85.999,
			86.031,
			86.094,
			86.11,
			86.155,
			86.217,
			86.278,
			86.326,
			86.404,
			86.434,
			86.464,
			86.526,
			86.59,
			86.653,
			86.714,
			86.759,
			86.776,
			86.838,
			86.87,
			86.899,
			86.976,
			87.023,
			87.084,
			87.148,
			87.193,
			87.21,
			87.271,
			87.303,
			87.332,
			87.414,
			87.457,
			87.518,
			87.582,
			87.629,
			87.704,
			87.734,
			87.768,
			87.845,
			87.891,
			87.954,
			88.014,
			88.062,
			88.077,
			88.14,
			88.171,
			88.201,
			88.279,
			88.325,
			88.388,
			88.448,
			88.497,
			88.573,
			88.605,
			88.636,
			88.697,
			88.76,
			88.821,
			88.883,
			88.929,
			88.947,
			89.008,
			89.038,
			89.071,
			89.13,
			89.147,
			89.195,
			89.26,
			89.316,
			89.365,
			89.379,
			89.441,
			89.476,
			89.505,
			89.581,
			89.627,
			89.69,
			89.753,
			89.812,
			89.876,
			89.908,
			89.938,
			89.999,
			90.062,
			90.124,
			90.187,
			90.249,
			90.31,
			90.342,
			90.372,
			90.435,
			90.449,
			90.496,
			90.556,
			90.621,
			90.665,
			90.682,
			90.744,
			90.775,
			90.807,
			90.867,
			90.885,
			90.931,
			90.992,
			91.054,
			91.101,
			91.179,
			91.208,
			91.239,
			91.303,
			91.365,
			91.427,
			91.489,
			91.538,
			91.549,
			91.612,
			91.643,
			91.673,
			91.751,
			91.798,
			91.861,
			91.923,
			91.968,
			91.982,
			92.047,
			92.078,
			92.109,
			92.186,
			92.232,
			92.294,
			92.357,
			92.417,
			92.479,
			92.512,
			92.543,
			92.62,
			92.667,
			92.729,
			92.79,
			92.854,
			92.915,
			92.944,
			92.975,
			93.053,
			93.1,
			93.162,
			93.223,
			93.271,
			93.285,
			93.347,
			93.381,
			93.411,
			93.472,
			93.534,
			93.598,
			93.659,
			93.704,
			93.782,
			93.813,
			93.844,
			93.908,
			93.922,
			93.968,
			94.031,
			94.092,
			94.138,
			94.156,
			94.217,
			94.247,
			94.279,
			94.341,
			94.356,
			94.403,
			94.464,
			94.528,
			94.588,
			94.649,
			94.681,
			94.712,
			94.775,
			94.838,
			94.898,
			94.962,
			95.007,
			95.023,
			95.086,
			95.115,
			95.147,
			95.207,
			95.224,
			95.272,
			95.332,
			95.394,
			95.44,
			95.456,
			95.517,
			95.548,
			95.582,
			95.643,
			95.662,
			95.705,
			95.771,
			95.829,
			95.877,
			95.951,
			95.982,
			96.014,
			96.076,
			96.14,
			96.202,
			96.262,
			96.31,
			96.324,
			96.387,
			96.418,
			96.448,
			96.526,
			96.574,
			96.635,
			96.697,
			96.743,
			96.76,
			96.821,
			96.853,
			96.883,
			96.944,
			96.962,
			97.007,
			97.068,
			97.131,
			97.192,
			97.255,
			97.286,
			97.316,
			97.378,
			97.442,
			97.504,
			97.565,
			97.626,
			97.69,
			97.725,
			97.751,
			97.829,
			97.876,
			97.937,
			97.999,
			98.046,
			98.06,
			98.122,
			98.155,
			98.186,
			98.246,
			98.263,
			98.31,
			98.372,
			98.432,
			98.481,
			98.558,
			98.588,
			98.618,
			98.697,
			98.744,
			98.805,
			98.866,
			98.915,
			98.93,
			98.99,
			99.023,
			99.055,
			99.115,
			99.13,
			99.178,
			99.241,
			99.301,
			99.365,
			99.427,
			99.457,
			99.487,
			99.549,
			99.612,
			99.673,
			99.736,
			99.796,
			99.86,
			99.892,
			99.921,
			99.984,
			99.999,
			100.047,
			100.107,
			100.168,
			100.215,
			100.231,
			100.293,
			100.324,
			100.356,
			100.417,
			100.434,
			100.479,
			100.54,
			100.604,
			100.65,
			100.729,
			100.759,
			100.79,
			100.853,
			100.913,
			100.977,
			101.038,
			101.099,
			101.162,
			101.193,
			101.223,
			101.286,
			101.302,
			101.346,
			101.411,
			101.471,
			101.52,
			101.533,
			101.596,
			101.628,
			101.658,
			101.72,
			101.735,
			101.783,
			101.85,
			101.907,
			101.954,
			102.029,
			102.062,
			102.093,
			102.155,
			102.215,
			102.279,
			102.34,
			102.385,
			102.402,
			102.465,
			102.496,
			102.527,
			102.588,
			102.605,
			102.651,
			102.713,
			102.773,
			102.82,
			102.836,
			102.897,
			102.929,
			102.96,
			103.023,
			103.084,
			103.146,
			103.208,
			103.255,
			103.332,
			103.364,
			103.394,
			103.456,
			103.472,
			103.519,
			103.579,
			103.643,
			103.69,
			103.704,
			103.765,
			103.798,
			103.829,
			103.89,
			103.91,
			103.952,
			104.014,
			104.075,
			104.139,
			104.199,
			104.232,
			104.261,
			104.323,
			104.387,
			104.45,
			104.51,
			104.557,
			104.573,
			104.636,
			104.665,
			104.696,
			104.774,
			104.821,
			104.884,
			104.945,
			104.989,
			105.006,
			105.067,
			105.098,
			105.129,
			105.193,
			105.208,
			105.254,
			105.317,
			105.38,
			105.441,
			105.501,
			105.532,
			105.564,
			105.627,
			105.69,
			105.75,
			105.811,
			105.86,
			105.874,
			105.937,
			105.972,
			105.999,
			106.061,
			106.077,
			106.123,
			106.184,
			106.248,
			106.294,
			106.309,
			106.37,
			106.402,
			106.432,
			106.494,
			106.509,
			106.557,
			106.617,
			106.681,
			106.727,
			106.804,
			106.836,
			106.867,
			106.929,
			106.991,
			107.054,
			107.115,
			107.163,
			107.177,
			107.238,
			107.271,
			107.302,
			107.362,
			107.379,
			107.425,
			107.488,
			107.55,
			107.611,
			107.673,
			107.703,
			107.736,
			107.796,
			107.812,
			107.858,
			107.921,
			107.984,
			108.034,
			108.108,
			108.138,
			108.17,
			108.248,
			108.293,
			108.356,
			108.416,
			108.465,
			108.479,
			108.54,
			108.573,
			108.601,
			108.665,
			108.68,
			108.727,
			108.79,
			108.851,
			108.896,
			108.914,
			108.974,
			109.006,
			109.038,
			109.099,
			109.162,
			109.226,
			109.284,
			109.331,
			109.347,
			109.409,
			109.44,
			109.472,
			109.532,
			109.548,
			109.596,
			109.658,
			109.719,
			109.782,
			109.843,
			109.874,
			109.906,
			109.965,
			109.982,
			110.03,
			110.096,
			110.154,
			110.201,
			110.277,
			110.307,
			110.34,
			110.401,
			110.465,
			110.526,
			110.588,
			110.634,
			110.65,
			110.713,
			110.741,
			110.773,
			110.834,
			110.85,
			110.898,
			110.961,
			111.02,
			111.083,
			111.144,
			111.177,
			111.206,
			111.27,
			111.284,
			111.331,
			111.393,
			111.456,
			111.503,
			111.58,
			111.61,
			111.641,
			111.705,
			111.764,
			111.829,
			111.89,
			111.937,
			111.953,
			112.012,
			112.043,
			112.075,
			112.138,
			112.158,
			112.199,
			112.261,
			112.323,
			112.387,
			112.449,
			112.478,
			112.509,
			112.571,
			112.587,
			112.632,
			112.695,
			112.758,
			112.804,
			112.881,
			112.911,
			112.943,
			113.021,
			113.067,
			113.13,
			113.193,
			113.238,
			113.255,
			113.317,
			113.346,
			113.379,
			113.456,
			113.502,
			113.565,
			113.626,
			113.673,
			113.75,
			113.782,
			113.812,
			113.89,
			113.936,
			113.999,
			114.06,
			114.123,
			114.184,
			114.221,
			114.246,
			114.323,
			114.371,
			114.432,
			114.493,
			114.556,
			114.619,
			114.649,
			114.679,
			114.743,
			114.757,
			114.805,
			114.868,
			114.929,
			114.975,
			115.053,
			115.082,
			115.114,
			115.19,
			115.238,
			115.301,
			115.362,
			115.407,
			115.423,
			115.486,
			115.516,
			115.548,
			115.61,
			115.626,
			115.671,
			115.733,
			115.797,
			115.844,
			115.86,
			115.921,
			115.953,
			115.983,
			116.044,
			116.061,
			116.106,
			116.168,
			116.23,
			116.292,
			116.354,
			116.386,
			116.417,
			116.494,
			116.54,
			116.608,
			116.665,
			116.712,
			116.727,
			116.788,
			116.82,
			116.849,
			116.912,
			116.928,
			116.973,
			117.038,
			117.099,
			117.144,
			117.162,
			117.223,
			117.255,
			117.286,
			117.345,
			117.362,
			117.407,
			117.471,
			117.532,
			117.579,
			117.656,
			117.686,
			117.72,
			117.779,
			117.844,
			117.907,
			117.967,
			118.012,
			118.029,
			118.09,
			118.122,
			118.154,
			118.215,
			118.23,
			118.277,
			118.34,
			118.399,
			118.448,
			118.525,
			118.557,
			118.588,
			118.65,
			118.712,
			118.773,
			118.836,
			118.896,
			118.959,
			118.99,
			119.021,
			119.099,
			119.144,
			119.207,
			119.269,
			119.315,
			119.331,
			119.393,
			119.425,
			119.455,
			119.518,
			119.534,
			119.579,
			119.641,
			119.704,
			119.75,
			119.827,
			119.858,
			119.89,
			119.952,
			120.014,
			120.076,
			120.137,
			120.182,
			120.2,
			120.261,
			120.293,
			120.324,
			120.406,
			120.448,
			120.508,
			120.571,
			120.617,
			120.634,
			120.696,
			120.726,
			120.757,
			120.836,
			120.881,
			120.943,
			121.004,
			121.053,
			121.129,
			121.16,
			121.19,
			121.254,
			121.315,
			121.378,
			121.439,
			121.484,
			121.501,
			121.564,
			121.593,
			121.625,
			121.688,
			121.703,
			121.749,
			121.812,
			121.872,
			121.921,
			121.936,
			121.997,
			122.03,
			122.058,
			122.121,
			122.138,
			122.184,
			122.246,
			122.307,
			122.354,
			122.433,
			122.468,
			122.494,
			122.555,
			122.617,
			122.68,
			122.742,
			122.789,
			122.804,
			122.866,
			122.897,
			122.929,
			123.006,
			123.053,
			123.113,
			123.176,
			123.223,
			123.3,
			123.332,
			123.362,
			123.376
		]
	],
	'1853_3913_7982': [
		[
			93.953
		],
		[
			0
		],
		[
			0,
			0.591,
			4.423,
			5.704,
			6.988,
			10.851,
			13.436,
			16.028,
			17.328,
			19.932,
			22.541,
			23.844,
			26.455,
			27.763,
			30.382,
			33.008,
			36.959,
			38.279,
			39.601,
			43.599,
			44.927,
			46.256,
			50.253,
			52.925,
			56.942,
			59.627,
			63.665,
			66.37,
			67.725,
			70.446,
			73.182,
			74.553,
			75.937,
			80.103,
			82.919,
			85.749,
			87.176,
			90.035,
			92.924,
			94.372,
			97.285,
			98.748,
			101.677,
			104.606,
			109.011,
			111.942,
			116.296,
			117.769,
			119.22,
			123.545,
			126.419,
			130.715,
			133.573,
			136.435,
			137.866,
			140.732,
			142.167,
			145.042,
			149.368,
			152.26,
			155.161,
			158.071,
			160.99,
			162.452,
			165.383,
			168.32,
			169.791,
			172.737,
			174.214,
			177.169,
			180.13,
			184.576,
			187.544,
			191.996,
			193.482,
			194.969,
			199.434,
			200.924,
			202.415,
			206.885,
			209.856,
			212.814,
			214.288,
			217.231,
			218.701,
			221.642,
			224.586,
			226.062,
			229.008,
			231.957,
			236.372,
			239.311,
			243.729,
			245.196,
			246.665,
			251.03,
			253.905,
			256.747,
			260.945,
			263.702,
			267.767,
			269.101,
			270.425,
			274.348,
			276.934,
			280.787,
			283.351,
			285.907,
			287.185,
			289.737,
			292.279,
			293.548,
			296.087,
			297.355,
			299.891,
			302.426,
			306.212,
			308.722,
			312.467,
			313.707,
			314.942,
			318.598,
			321.032,
			324.603,
			326.946,
			329.262,
			330.41,
			332.682,
			333.807,
			336.033,
			339.324,
			340.417,
			343.661,
			345.829,
			348.001,
			349.099,
			351.243,
			353.382,
			354.441,
			357.605,
			359.702,
			361.757,
			364.836,
			366.85,
			369.81,
			370.773,
			371.727,
			374.53,
			376.351,
			379.009,
			380.738,
			383.257,
			384.888,
			385.692,
			387.277,
			388.847,
			389.618,
			391.16,
			392.686,
			394.201,
			396.483,
			398.755,
			399.509,
			400.267,
			401.796,
			402.558,
			404.077,
			405.601,
			407.907,
			409.441,
			411.751,
			412.522,
			413.293,
			415.608,
			417.153,
			419.47,
			421.013,
			422.553,
			423.323,
			424.866,
			425.639,
			427.185,
			428.733,
			429.508,
			431.068,
			432.641,
			435.008,
			436.607,
			439.021,
			439.827,
			440.634,
			443.058,
			444.678,
			446.296,
			448.707,
			450.318,
			452.715,
			453.513,
			454.312,
			456.709,
			458.3,
			460.694,
			462.279,
			463.863,
			464.657,
			466.25,
			467.823,
			468.612,
			470.992,
			472.573,
			474.158,
			476.552,
			478.149,
			480.564,
			481.374,
			482.184,
			484.63,
			486.275,
			488.765,
			490.439,
			492.127,
			494.688,
			495.547,
			496.41,
			498.982,
			499.844,
			500.709,
			503.333,
			505.102,
			506.884,
			507.779,
			509.61,
			511.464,
			512.396,
			515.213,
			517.112,
			519.022,
			521.877,
			523.788,
			526.671,
			527.637,
			528.606,
			531.534,
			532.516,
			533.501,
			536.476,
			538.474,
			541.475,
			543.5,
			544.517,
			546.559,
			548.611,
			549.641,
			551.711,
			553.792,
			555.878,
			557.963,
			559.007,
			562.151,
			563.204,
			564.259,
			566.377,
			567.44,
			569.573,
			571.716,
			574.95,
			577.12,
			580.393,
			581.49,
			582.59,
			585.904,
			588.127,
			591.483,
			593.737,
			596.003,
			597.142,
			599.426,
			600.574,
			602.877,
			606.35,
			608.677,
			611.012,
			614.531,
			616.893,
			620.455,
			621.653,
			622.853,
			626.478,
			628.921,
			631.38,
			635.101,
			637.589,
			641.329,
			642.571,
			643.816,
			647.536,
			650.005,
			653.687,
			656.099,
			658.477,
			659.656,
			661.983,
			663.131,
			665.402,
			668.74,
			670.941,
			673.134,
			676.412,
			678.604,
			681.9,
			683.003,
			684.106,
			687.428,
			689.649,
			693.001,
			695.239,
			697.488,
			698.613,
			700.868,
			702.011,
			704.292,
			706.589,
			707.746,
			708.899,
			712.373,
			714.689,
			716.998,
			718.147,
			720.427,
			722.68,
			723.796,
			727.11,
			729.286,
			731.44,
			734.646,
			736.76,
			739.884,
			740.946,
			741.975,
			745.068,
			747.116,
			750.164,
			752.208,
			754.254,
			755.279,
			757.321,
			758.339,
			760.363,
			762.37,
			763.363,
			765.326,
			767.252,
			769.139,
			770.986,
			771.898,
			773.695,
			775.46,
			776.334,
			778.068,
			778.942,
			780.651,
			782.36,
			784.91,
			786.605,
			789.15,
			789.994,
			790.837,
			793.398,
			795.102,
			797.681,
			799.41,
			801.135,
			802.009,
			803.757,
			804.63,
			806.377,
			808.128,
			809.004,
			810.756,
			812.486,
			815.071,
			815.935,
			816.789,
			819.33,
			820.18,
			821.03,
			823.549,
			825.234,
			826.92,
			829.443,
			831.139,
			833.689,
			834.544,
			835.402,
			837.988,
			838.854,
			839.722,
			842.336,
			844.082,
			846.715,
			848.469,
			850.226,
			851.104,
			852.858,
			853.733,
			855.479,
			857.218,
			859.821,
			861.544,
			864.199,
			865.081,
			865.964,
			868.625,
			870.404,
			873.084,
			874.878,
			877.569,
			879.388,
			880.303,
			882.143,
			883.997,
			884.929,
			886.802,
			888.692,
			890.595,
			892.513,
			893.477,
			895.415,
			897.368,
			898.35,
			900.325,
			901.318,
			903.315,
			905.328,
			908.374,
			910.421,
			913.518,
			914.557,
			915.599,
			918.744,
			920.846,
			923.999,
			926.094,
			929.209,
			931.257,
			932.269,
			934.274,
			936.245,
			937.217,
			939.138,
			941.025,
			942.877,
			944.695,
			945.592,
			947.361,
			949.092,
			949.945,
			951.623,
			952.448,
			954.069,
			955.655,
			957.966,
			959.46,
			961.635,
			962.342,
			963.038,
			965.078,
			966.416,
			968.361,
			969.634,
			970.895,
			971.522,
			972.767,
			973.384,
			974.612,
			976.445,
			977.666,
			978.887,
			980.71,
			981.317,
			981.924,
			983.772,
			984.389,
			985.005,
			986.238,
			986.855,
			988.114,
			989.384,
			991.291,
			992.577,
			994.565,
			995.227,
			995.889,
			997.906,
			999.294,
			1001.373,
			1002.776,
			1004.925,
			1006.35,
			1007.063,
			1008.492,
			1010.628,
			1012.05,
			1013.457,
			1015.556,
			1016.957,
			1019.039,
			1019.73,
			1020.423,
			1022.51,
			1023.889,
			1025.969,
			1027.37,
			1028.757,
			1029.453,
			1030.849,
			1031.551,
			1032.254,
			1034.357,
			1035.06,
			1035.764,
			1037.887,
			1039.303,
			1040.727,
			1041.442,
			1042.879,
			1044.318,
			1045.041,
			1046.494,
			1047.224,
			1048.687,
			1050.16,
			1052.394,
			1053.907,
			1056.208,
			1056.984,
			1057.766,
			1060.156,
			1061.77,
			1064.236,
			1065.906,
			1067.587,
			1068.431,
			1070.124,
			1070.975,
			1072.678,
			1074.414,
			1075.288,
			1077.048,
			1078.825,
			1080.619,
			1082.431,
			1083.342,
			1085.174,
			1087.024,
			1087.956,
			1089.823,
			1090.763,
			1092.657,
			1094.558,
			1097.438,
			1099.371,
			1102.296,
			1103.278,
			1104.261,
			1107.231,
			1109.227,
			1112.242,
			1114.265,
			1116.3,
			1117.322,
			1119.373,
			1120.402,
			1122.47,
			1124.566,
			1125.608,
			1127.692,
			1129.778,
			1132.926,
			1133.981,
			1135.039,
			1137.164,
			1139.301,
			1140.373,
			1143.609,
			1145.785,
			1147.972,
			1151.288,
			1153.511,
			1156.879,
			1158.004,
			1159.134,
			1162.531,
			1163.666,
			1164.804,
			1168.222,
			1170.505,
			1173.931,
			1176.212,
			1178.493,
			1179.632,
			1181.908,
			1183.044,
			1185.31,
			1187.569,
			1190.949,
			1192.074,
			1193.198,
			1196.571,
			1197.695,
			1198.82,
			1202.2,
			1204.46,
			1207.867,
			1210.154,
			1212.453,
			1213.606,
			1215.927,
			1217.092,
			1218.259,
			1222.964,
			1224.147,
			1227.706,
			1230.09,
			1232.483,
			1233.683,
			1236.089,
			1238.505,
			1239.717,
			1242.148,
			1243.367,
			1245.812,
			1248.266,
			1251.959,
			1254.43,
			1258.145,
			1259.387,
			1260.631,
			1264.376,
			1266.882,
			1270.654,
			1273.176,
			1276.97,
			1279.509,
			1280.779,
			1283.325,
			1285.879,
			1287.157,
			1288.438,
			1292.29,
			1294.866,
			1297.448,
			1298.741,
			1301.333,
			1303.929,
			1305.23,
			1309.139,
			1311.751,
			1314.347,
			1318.273,
			1320.891,
			1324.828,
			1326.142,
			1327.458,
			1331.411,
			1334.053,
			1338.022,
			1340.674,
			1343.33,
			1344.661,
			1347.324,
			1348.658,
			1351.33,
			1354.008,
			1355.349,
			1358.036,
			1360.73,
			1364.779,
			1366.127,
			1367.472,
			1370.147,
			1372.797,
			1374.109,
			1377.994,
			1380.543,
			1383.062,
			1386.779,
			1389.215,
			1392.81,
			1393.992,
			1395.166,
			1398.638,
			1400.911,
			1404.261,
			1406.454,
			1408.615,
			1409.682,
			1411.797,
			1412.845,
			1414.929,
			1417.005,
			1418.041,
			1420.105,
			1422.156,
			1425.222,
			1427.231,
			1430.215,
			1431.202,
			1432.186,
			1435.121,
			1437.068,
			1439.977,
			1441.909,
			1443.834,
			1446.717,
			1447.677,
			1448.636,
			1451.509,
			1452.466,
			1453.422,
			1456.286,
			1458.192,
			1461.045,
			1462.943,
			1464.835,
			1465.78,
			1467.664,
			1468.604,
			1470.48,
			1472.354,
			1475.156,
			1477.019,
			1479.803,
			1480.729,
			1481.655,
			1484.422,
			1486.262,
			1489.013,
			1490.841,
			1492.669,
			1493.58,
			1495.399,
			1496.311,
			1497.223,
			1499.957,
			1500.868,
			1501.781,
			1504.507,
			1506.318,
			1509.027,
			1510.824,
			1512.629,
			1513.529,
			1515.326,
			1516.226,
			1518.029,
			1519.829,
			1522.535,
			1524.34,
			1527.053,
			1527.96,
			1528.866,
			1531.593,
			1533.417,
			1536.162,
			1538.005,
			1539.853,
			1540.779,
			1542.649,
			1543.587,
			1545.469,
			1547.367,
			1548.321,
			1550.235,
			1552.164,
			1554.107,
			1556.059,
			1557.045,
			1559.026,
			1561.017,
			1562.023,
			1565.057,
			1567.096,
			1569.15,
			1572.247,
			1574.323,
			1577.438,
			1578.475,
			1579.511,
			1582.618,
			1584.686,
			1587.783,
			1589.842,
			1592.92,
			1594.96,
			1596.991,
			1598,
			1600.011,
			1601.014,
			1603.013,
			1605.007,
			1607.995,
			1608.99,
			1609.986,
			1612.982,
			1613.982,
			1614.983,
			1617.99,
			1619.995,
			1623.01,
			1625.022,
			1627.041,
			1630.065,
			1631.075,
			1632.088,
			1636.153,
			1637.175,
			1640.256,
			1642.323,
			1644.401,
			1645.444,
			1647.536,
			1649.634,
			1650.687,
			1652.799,
			1653.855,
			1655.977,
			1658.104,
			1661.307,
			1663.451,
			1666.661,
			1667.729,
			1668.798,
			1672.018,
			1674.168,
			1677.403,
			1679.559,
			1681.72,
			1682.799,
			1684.957,
			1686.036,
			1688.195,
			1690.351,
			1691.43,
			1692.508,
			1695.738,
			1698.969,
			1700.047,
			1701.125,
			1703.282,
			1705.44,
			1706.522,
			1708.688,
			1709.773,
			1711.95,
			1714.132,
			1717.419,
			1719.621,
			1722.938,
			1724.05,
			1725.163,
			1728.516,
			1730.765,
			1734.155,
			1736.425,
			1738.702,
			1739.843,
			1742.131,
			1743.277,
			1745.573,
			1747.876,
			1749.03,
			1751.344,
			1753.666,
			1757.145,
			1758.319,
			1759.497,
			1761.859,
			1764.241,
			1765.438,
			1769.051,
			1771.483,
			1775.158,
			1777.624,
			1780.101,
			1783.831,
			1785.077,
			1786.325,
			1790.076,
			1792.584,
			1796.358,
			1798.882,
			1802.678,
			1805.215,
			1806.487,
			1809.033,
			1811.585,
			1812.863,
			1815.423,
			1817.988,
			1821.848,
			1824.429,
			1828.312,
			1829.61,
			1830.908,
			1834.802,
			1837.404,
			1840.013,
			1843.941,
			1846.569,
			1850.526,
			1851.85,
			1853.175,
			1857.161,
			1859.827,
			1863.839,
			1866.521,
			1869.209,
			1870.554,
			1873.249,
			1875.949,
			1877.302,
			1880.01,
			1881.366,
			1884.083,
			1886.804,
			1890.897,
			1893.632,
			1897.745,
			1899.119,
			1900.494,
			1904.628,
			1907.392,
			1911.548,
			1914.327,
			1917.111,
			1918.505,
			1921.299,
			1922.698,
			1924.099,
			1929.716,
			1931.124,
			1935.356,
			1938.185,
			1942.439,
			1945.282,
			1948.13,
			1949.557,
			1952.413,
			1953.844,
			1956.708,
			1959.577,
			1963.893,
			1966.776,
			1971.112,
			1972.56,
			1974.01,
			1978.366,
			1981.277,
			1985.654,
			1988.578,
			1991.507,
			1992.974,
			1995.911,
			1997.381,
			2000.325,
			2003.274,
			2004.75,
			2007.705,
			2010.666,
			2015.141,
			2016.626,
			2018.086,
			2021.061,
			2024.041,
			2025.532,
			2030.004,
			2032.972,
			2035.916,
			2040.269,
			2043.122,
			2047.327,
			2048.71,
			2050.082,
			2054.142,
			2056.799,
			2060.712,
			2063.272,
			2065.794,
			2067.041,
			2069.505,
			2070.723,
			2073.133,
			2075.508,
			2076.682,
			2079.004,
			2081.289,
			2084.644,
			2085.742,
			2086.832,
			2090.042,
			2091.092,
			2092.134,
			2095.202,
			2097.2,
			2099.16,
			2102.029,
			2103.894,
			2106.626,
			2107.519,
			2108.403,
			2111.847,
			2112.684,
			2115.137,
			2116.724,
			2118.272,
			2119.032,
			2120.525,
			2121.978,
			2122.69,
			2124.087,
			2124.773,
			2126.118,
			2127.427,
			2129.327,
			2130.549,
			2132.309,
			2132.877,
			2133.436,
			2135.058,
			2136.094,
			2137.572,
			2138.512,
			2139.418,
			2140.713,
			2141.129,
			2141.543,
			2143.149,
			2143.932,
			2144.709,
			2145.487,
			2146.268,
			2146.658,
			2147.439,
			2148.221,
			2148.614,
			2149.403,
			2149.801,
			2150.602,
			2151.404,
			2152.571,
			2153.342,
			2154.491,
			2154.874,
			2155.257,
			2156.404,
			2157.137,
			2158.219,
			2158.93,
			2159.984,
			2160.685,
			2161.035,
			2161.85,
			2162.7,
			2163.121,
			2163.96,
			2164.795,
			2166.045,
			2166.594,
			2167.273,
			2168.623,
			2169.967,
			2170.638,
			2172.364,
			2173.393,
			2175.442,
			2177.574,
			2180.829,
			2182.355,
			2184.374,
			2185.043,
			2185.713,
			2187.268,
			2188.171,
			2189.523,
			2190.427,
			2191.338,
			2191.762,
			2192.607,
			2193.029,
			2193.875,
			2194.725,
			2195.153,
			2196.017,
			2196.875,
			2198.16,
			2199.028,
			2200.349,
			2200.795,
			2201.244,
			2202.568,
			2203.458,
			2204.816,
			2205.74,
			2206.687,
			2208.154,
			2208.65,
			2209.15,
			2210.673,
			2211.189,
			2211.712,
			2213.308,
			2214.389,
			2215.487,
			2216.042,
			2217.186,
			2218.354,
			2218.942,
			2220.131,
			2220.73,
			2221.952,
			2223.198,
			2225.08,
			2226.352,
			2228.337,
			2229.009,
			2229.684,
			2231.736,
			2233.145,
			2235.286,
			2236.736,
			2238.205,
			2238.946,
			2240.427,
			2241.181,
			2241.938,
			2244.238,
			2245.013,
			2246.574,
			2248.153,
			2249.746,
			2251.355,
			2252.165,
			2253.797,
			2255.444,
			2256.272,
			2258.78,
			2260.457,
			2262.135,
			2264.677,
			2266.389,
			2268.985,
			2269.856,
			2270.732,
			2273.378,
			2275.16,
			2277.858,
			2279.675,
			2281.504,
			2282.424,
			2284.273,
			2285.203,
			2287.072,
			2288.954,
			2289.9,
			2291.802,
			2293.717,
			2296.615,
			2297.588,
			2298.563,
			2300.525,
			2302.5,
			2303.493,
			2305.489,
			2306.491,
			2308.509,
			2310.541,
			2313.616,
			2315.685,
			2318.819,
			2319.872,
			2320.928,
			2324.128,
			2326.256,
			2329.477,
			2331.641,
			2334.927,
			2337.134,
			2338.251,
			2340.497,
			2342.756,
			2343.896,
			2346.184,
			2348.485,
			2351.962,
			2353.106,
			2354.27,
			2357.793,
			2358.958,
			2360.124,
			2363.625,
			2365.935,
			2368.266,
			2371.747,
			2374.058,
			2377.516,
			2378.664,
			2379.81,
			2383.264,
			2384.423,
			2385.584,
			2389.101,
			2391.485,
			2395.122,
			2397.583,
			2398.846,
			2401.356,
			2403.876,
			2405.142,
			2407.676,
			2410.208,
			2414.002,
			2416.524,
			2420.318,
			2421.588,
			2422.857,
			2426.696,
			2429.267,
			2433.143,
			2435.714,
			2438.316,
			2439.614,
			2442.229,
			2443.529,
			2446.106,
			2448.678,
			2449.952,
			2451.221,
			2455.004,
			2457.519,
			2460.013,
			2461.246,
			2463.694,
			2466.1,
			2467.29,
			2469.639,
			2470.797,
			2473.085,
			2475.34,
			2478.681,
			2480.871,
			2484.133,
			2485.221,
			2486.309,
			2489.563,
			2491.742,
			2495.002,
			2497.18,
			2499.354,
			2500.446,
			2502.632,
			2503.726,
			2505.924,
			2508.133,
			2509.241,
			2511.469,
			2513.708,
			2517.1,
			2518.239,
			2519.38,
			2522.829,
			2523.984,
			2525.14,
			2527.457,
			2528.619,
			2530.947,
			2533.276,
			2536.781,
			2539.126,
			2542.655,
			2543.833,
			2545.012,
			2548.563,
			2550.939,
			2554.513,
			2556.902,
			2559.294,
			2560.494,
			2562.884,
			2564.082,
			2566.46,
			2568.855,
			2570.057,
			2572.455,
			2574.858,
			2578.47,
			2579.679,
			2580.889,
			2584.531,
			2585.768,
			2586.988,
			2590.652,
			2593.109,
			2595.563,
			2599.267,
			2601.743,
			2605.488,
			2606.747,
			2608.016,
			2611.829,
			2613.104,
			2614.38,
			2618.213,
			2620.775,
			2623.33,
			2624.607,
			2627.16,
			2629.703,
			2630.976,
			2634.799,
			2637.354,
			2639.914,
			2643.76,
			2646.329,
			2650.183,
			2651.472,
			2652.761,
			2656.64,
			2659.225,
			2663.111,
			2665.705,
			2669.598,
			2672.176,
			2673.475,
			2676.078,
			2680.001,
			2681.281,
			2685.2,
			2687.822,
			2690.453,
			2691.771,
			2694.414,
			2697.063,
			2698.392,
			2702.387,
			2705.06,
			2707.74,
			2711.775,
			2714.473,
			2718.535,
			2719.893,
			2721.253,
			2725.343,
			2728.079,
			2732.195,
			2734.951,
			2737.712,
			2739.097,
			2741.871,
			2743.261,
			2746.049,
			2750.246,
			2753.055,
			2755.873,
			2758.698,
			2761.533,
			2762.954,
			2767.227,
			2768.656,
			2770.087,
			2774.394,
			2777.275,
			2780.165,
			2784.516,
			2787.425,
			2791.804,
			2793.268,
			2794.734,
			2799.143,
			2802.092,
			2806.53,
			2809.498,
			2812.474,
			2813.964,
			2816.951,
			2818.447,
			2821.443,
			2824.44,
			2825.935,
			2828.912,
			2831.861,
			2836.217,
			2839.073,
			2843.287,
			2844.672,
			2846.048,
			2848.771,
			2850.119,
			2852.786,
			2855.416,
			2859.289,
			2861.824,
			2865.557,
			2866.783,
			2867.999,
			2871.594,
			2872.775,
			2873.947,
			2877.413,
			2879.677,
			2881.904,
			2883.004,
			2885.175,
			2887.309,
			2888.362,
			2890.441,
			2891.467,
			2893.492,
			2895.48,
			2898.394,
			2900.291,
			2903.07,
			2903.979,
			2904.88,
			2907.532,
			2909.257,
			2911.774,
			2913.407,
			2915.788,
			2917.33,
			2918.088,
			2919.576,
			2921.743,
			2923.144,
			2924.511,
			2925.845,
			2927.785,
			2929.036,
			2930.25,
			2930.843,
			2932.001,
			2932.576,
			2933.679,
			2934.75,
			2936.303,
			2937.307,
			2938.778,
			2939.262,
			2939.743,
			2941.17,
			2942.111,
			2943.52,
			2944.462,
			2945.406,
			2945.876,
			2946.816,
			2947.286,
			2948.229,
			2949.176,
			2949.652,
			2950.624,
			2951.608,
			2953.085,
			2953.578,
			2954.072,
			2955.657,
			2956.265,
			2956.871,
			2958.083,
			2958.688,
			2959.898,
			2961.539,
			2964.198,
			2965.933,
			2968.442,
			2969.275,
			2970.109,
			2971.959,
			2973.152,
			2974.933,
			2975.995,
			2977.495,
			2978.488,
			2978.981,
			2979.958,
			2980.882,
			2981.332,
			2982.22,
			2983.107,
			2984.415,
			2985.281,
			2986.653,
			2987.106,
			2987.557,
			2988.898,
			2989.787,
			2990.752,
			2992.429,
			2993.543,
			2995.212,
			2995.869,
			2996.58,
			2998.681,
			3000.075,
			3002.306,
			3003.793,
			3005.277,
			3005.953,
			3007.244,
			3008.524,
			3009.163,
			3010.441,
			3010.973,
			3011.999,
			3013.02,
			3014.554,
			3015.58,
			3016.944,
			3017.4,
			3017.857,
			3019.236,
			3020.164,
			3021.529,
			3022.434,
			3023.348,
			3023.808,
			3024.735,
			3025.203,
			3026.151,
			3027.113,
			3027.598,
			3028.577,
			3029.57,
			3030.577,
			3031.618,
			3032.145,
			3033.211,
			3034.293,
			3034.841,
			3035.952,
			3036.519,
			3037.666,
			3038.831,
			3040.617,
			3041.834,
			3043.695,
			3044.323,
			3044.95,
			3046.833,
			3048.102,
			3050.031,
			3051.338,
			3053.324,
			3054.669,
			3055.348,
			3056.717,
			3058.101,
			3058.8,
			3059.502,
			3061.623,
			3063.793,
			3064.525,
			3065.26,
			3066.745,
			3068.247,
			3069.005,
			3070.533,
			3071.303,
			3072.861,
			3074.435,
			3076.826,
			3078.442,
			3080.897,
			3081.723,
			3082.554,
			3085.069,
			3086.761,
			3089.304,
			3091.005,
			3092.72,
			3093.582,
			3095.316,
			3096.189,
			3097.942,
			3099.707,
			3100.594,
			3102.378,
			3104.189,
			3106.907,
			3107.818,
			3108.732,
			3111.493,
			3112.421,
			3113.352,
			3115.224,
			3116.165,
			3118.059,
			3119.968,
			3122.857,
			3124.8,
			3127.741,
			3128.729,
			3129.721,
			3132.714,
			3133.722,
			3134.732,
			3137.784,
			3139.843,
			3141.913,
			3142.957,
			3145.063,
			3147.18,
			3148.248,
			3150.402,
			3151.482,
			3153.648,
			3155.827,
			3159.112,
			3161.315,
			3164.641,
			3165.755,
			3166.872,
			3170.247,
			3172.514,
			3175.94,
			3178.237,
			3180.547,
			3184.029,
			3185.196,
			3186.364,
			3189.878,
			3191.053,
			3192.23,
			3195.773,
			3198.146,
			3200.523,
			3201.714,
			3204.101,
			3206.489,
			3207.687,
			3210.079,
			3211.276,
			3213.677,
			3216.072,
			3219.677,
			3222.084,
			3225.703,
			3226.91,
			3228.12,
			3231.752,
			3234.182,
			3237.834,
			3240.277,
			3242.725,
			3243.952,
			3246.412,
			3247.645,
			3250.116,
			3252.594,
			3253.836,
			3256.306,
			3258.805,
			3262.568,
			3263.827,
			3265.087,
			3267.615,
			3270.15,
			3271.42,
			3273.968,
			3275.245,
			3277.805,
			3280.373,
			3284.241,
			3286.83,
			3290.727,
			3292.026,
			3293.325,
			3297.231,
			3299.844,
			3303.774,
			3306.404,
			3309.04,
			3310.359,
			3312.999,
			3314.316,
			3316.943,
			3319.55,
			3320.844,
			3323.409,
			3325.938,
			3329.662,
			3332.099,
			3335.689,
			3336.869,
			3338.04,
			3341.503,
			3343.766,
			3345.993,
			3349.267,
			3351.404,
			3354.542,
			3355.57,
			3356.59,
			3359.597,
			3361.556,
			3364.425,
			3366.292,
			3369.031,
			3370.814,
			3372.563,
			3373.424,
			3375.118,
			3375.952,
			3377.587,
			3379.185,
			3381.512,
			3383.009,
			3385.184,
			3385.891,
			3386.589,
			3388.624,
			3389.938,
			3391.219,
			3393.077,
			3394.27,
			3395.994,
			3396.551,
			3397.099,
			3398.689,
			3399.202,
			3399.706,
			3401.165,
			3402.094,
			3402.997,
			3403.431,
			3404.273,
			3405.084,
			3405.479,
			3406.254,
			3406.633,
			3407.365,
			3408.064,
			3409.091,
			3409.761,
			3410.75,
			3411.077,
			3411.406,
			3412.396,
			3413.058,
			3414.05,
			3414.715,
			3415.383,
			3415.719,
			3416.396,
			3416.737,
			3417.439,
			3418.159,
			3418.517,
			3419.23,
			3419.943,
			3420.657,
			3421.374,
			3421.733,
			3422.502,
			3423.351,
			3423.769,
			3425.01,
			3425.83,
			3426.65,
			3427.982,
			3428.93,
			3430.338,
			3430.808,
			3431.277,
			3432.705,
			3433.186,
			3433.667,
			3435.106,
			3436.068,
			3437.037,
			3437.528,
			3438.53,
			3439.032,
			3440.037,
			3441.049,
			3441.559,
			3442.586,
			3443.603,
			3445.132,
			3445.644,
			3446.158,
			3447.702,
			3448.198,
			3448.696,
			3449.696,
			3450.199,
			3451.215,
			3452.243,
			3453.743,
			3454.744,
			3456.261,
			3456.772,
			3457.285,
			3458.796,
			3459.809,
			3461.348,
			3462.389,
			3463.424,
			3463.943,
			3464.992,
			3466.054,
			3466.591,
			3467.677,
			3468.222,
			3469.324,
			3470.442,
			3472.151,
			3473.319,
			3475.11,
			3475.715,
			3476.324,
			3478.152,
			3479.392,
			3481.276,
			3482.541,
			3483.847,
			3484.51,
			3485.846,
			3486.52,
			3487.881,
			3489.271,
			3489.972,
			3490.678,
			3492.82,
			3494.271,
			3496.479,
			3497.97,
			3499.477,
			3500.235,
			3501.763,
			3502.532,
			3504.083,
			3505.647,
			3508.024,
			3509.627,
			3512.06,
			3512.879,
			3513.701,
			3516.19,
			3517.867,
			3520.409,
			3522.107,
			3523.806,
			3524.66,
			3526.379,
			3527.244,
			3528.982,
			3530.732,
			3531.611,
			3532.493,
			3535.159,
			3536.952,
			3539.664,
			3541.488,
			3543.325,
			3544.249,
			3546.107,
			3547.042,
			3548.922,
			3550.816,
			3553.684,
			3555.613,
			3558.531,
			3559.51,
			3560.492,
			3563.461,
			3565.456,
			3568.476,
			3570.504,
			3572.549,
			3573.576,
			3575.639,
			3576.676,
			3578.758,
			3580.852,
			3581.905,
			3584.018,
			3586.141,
			3589.335,
			3590.4,
			3591.462,
			3594.657,
			3595.728,
			3596.797,
			3598.937,
			3600.01,
			3602.162,
			3604.315,
			3607.561,
			3609.734,
			3613.01,
			3614.106,
			3615.205,
			3618.516,
			3619.625,
			3620.736,
			3624.084,
			3626.33,
			3628.585,
			3629.717,
			3631.987,
			3634.267,
			3635.411,
			3638.857,
			3641.166,
			3643.485,
			3646.981,
			3649.323,
			3652.855,
			3654.036,
			3655.22,
			3658.786,
			3661.175,
			3664.775,
			3667.185,
			3669.604,
			3673.251,
			3674.471,
			3675.694,
			3679.376,
			3680.608,
			3681.842,
			3685.556,
			3688.042,
			3690.543,
			3691.797,
			3694.31,
			3696.829,
			3698.091,
			3700.622,
			3701.89,
			3704.433,
			3706.555
		],
		[
			0,
			0.018,
			0.08,
			0.098,
			0.143,
			0.206,
			0.268,
			0.314,
			0.328,
			0.392,
			0.423,
			0.453,
			0.514,
			0.531,
			0.577,
			0.641,
			0.7,
			0.746,
			0.762,
			0.824,
			0.856,
			0.888,
			0.949,
			1.011,
			1.074,
			1.136,
			1.197,
			1.26,
			1.289,
			1.322,
			1.384,
			1.4,
			1.444,
			1.51,
			1.569,
			1.616,
			1.631,
			1.694,
			1.723,
			1.756,
			1.818,
			1.833,
			1.878,
			1.942,
			2.002,
			2.066,
			2.128,
			2.157,
			2.19,
			2.251,
			2.313,
			2.375,
			2.438,
			2.484,
			2.499,
			2.563,
			2.592,
			2.624,
			2.701,
			2.746,
			2.811,
			2.871,
			2.918,
			2.933,
			2.996,
			3.025,
			3.057,
			3.12,
			3.136,
			3.183,
			3.242,
			3.306,
			3.368,
			3.431,
			3.46,
			3.492,
			3.553,
			3.574,
			3.614,
			3.679,
			3.738,
			3.786,
			3.803,
			3.862,
			3.896,
			3.925,
			3.989,
			4.002,
			4.05,
			4.111,
			4.172,
			4.221,
			4.298,
			4.328,
			4.361,
			4.422,
			4.484,
			4.545,
			4.606,
			4.653,
			4.73,
			4.763,
			4.794,
			4.854,
			4.918,
			4.983,
			5.042,
			5.089,
			5.104,
			5.167,
			5.196,
			5.228,
			5.289,
			5.305,
			5.352,
			5.413,
			5.475,
			5.522,
			5.599,
			5.638,
			5.663,
			5.725,
			5.787,
			5.849,
			5.911,
			5.957,
			5.972,
			6.035,
			6.066,
			6.096,
			6.172,
			6.22,
			6.281,
			6.345,
			6.389,
			6.407,
			6.467,
			6.5,
			6.531,
			6.607,
			6.654,
			6.714,
			6.779,
			6.824,
			6.903,
			6.934,
			6.964,
			7.026,
			7.088,
			7.15,
			7.211,
			7.274,
			7.335,
			7.368,
			7.399,
			7.461,
			7.477,
			7.523,
			7.584,
			7.646,
			7.708,
			7.771,
			7.8,
			7.833,
			7.895,
			7.908,
			7.957,
			8.019,
			8.079,
			8.128,
			8.205,
			8.235,
			8.266,
			8.329,
			8.391,
			8.454,
			8.515,
			8.561,
			8.576,
			8.637,
			8.67,
			8.701,
			8.762,
			8.778,
			8.823,
			8.886,
			8.948,
			8.996,
			9.072,
			9.102,
			9.136,
			9.213,
			9.258,
			9.32,
			9.384,
			9.445,
			9.506,
			9.538,
			9.568,
			9.629,
			9.693,
			9.756,
			9.816,
			9.864,
			9.878,
			9.939,
			9.972,
			10.004,
			10.079,
			10.127,
			10.189,
			10.25,
			10.296,
			10.374,
			10.404,
			10.437,
			10.499,
			10.561,
			10.622,
			10.684,
			10.745,
			10.81,
			10.839,
			10.871,
			10.934,
			10.949,
			10.996,
			11.056,
			11.12,
			11.167,
			11.179,
			11.242,
			11.275,
			11.304,
			11.383,
			11.429,
			11.492,
			11.553,
			11.6,
			11.677,
			11.709,
			11.738,
			11.8,
			11.822,
			11.864,
			11.925,
			11.986,
			12.049,
			12.112,
			12.143,
			12.174,
			12.236,
			12.25,
			12.297,
			12.36,
			12.42,
			12.468,
			12.483,
			12.546,
			12.576,
			12.608,
			12.668,
			12.684,
			12.731,
			12.794,
			12.856,
			12.903,
			12.978,
			13.01,
			13.04,
			13.102,
			13.165,
			13.228,
			13.288,
			13.336,
			13.352,
			13.414,
			13.444,
			13.475,
			13.552,
			13.6,
			13.662,
			13.723,
			13.786,
			13.847,
			13.882,
			13.91,
			13.988,
			14.033,
			14.096,
			14.157,
			14.203,
			14.283,
			14.313,
			14.343,
			14.407,
			14.467,
			14.529,
			14.59,
			14.639,
			14.653,
			14.716,
			14.746,
			14.778,
			14.856,
			14.901,
			14.969,
			15.026,
			15.072,
			15.15,
			15.182,
			15.212,
			15.289,
			15.335,
			15.399,
			15.46,
			15.507,
			15.522,
			15.584,
			15.616,
			15.646,
			15.709,
			15.722,
			15.769,
			15.833,
			15.893,
			15.944,
			15.955,
			16.016,
			16.05,
			16.078,
			16.157,
			16.202,
			16.266,
			16.328,
			16.373,
			16.452,
			16.484,
			16.512,
			16.575,
			16.637,
			16.699,
			16.763,
			16.808,
			16.824,
			16.887,
			16.917,
			16.947,
			17.009,
			17.026,
			17.072,
			17.134,
			17.195,
			17.243,
			17.257,
			17.32,
			17.35,
			17.38,
			17.445,
			17.458,
			17.505,
			17.569,
			17.629,
			17.676,
			17.755,
			17.784,
			17.817,
			17.878,
			17.941,
			18.002,
			18.065,
			18.111,
			18.127,
			18.188,
			18.22,
			18.25,
			18.31,
			18.327,
			18.375,
			18.436,
			18.499,
			18.545,
			18.56,
			18.622,
			18.654,
			18.683,
			18.763,
			18.809,
			18.869,
			18.933,
			18.979,
			19.057,
			19.088,
			19.118,
			19.18,
			19.197,
			19.243,
			19.303,
			19.367,
			19.428,
			19.489,
			19.522,
			19.551,
			19.615,
			19.629,
			19.676,
			19.74,
			19.8,
			19.862,
			19.926,
			19.956,
			19.987,
			20.049,
			20.111,
			20.174,
			20.234,
			20.298,
			20.36,
			20.389,
			20.422,
			20.483,
			20.499,
			20.546,
			20.606,
			20.669,
			20.713,
			20.731,
			20.793,
			20.823,
			20.853,
			20.917,
			20.933,
			20.977,
			21.041,
			21.103,
			21.15,
			21.227,
			21.259,
			21.289,
			21.366,
			21.413,
			21.473,
			21.537,
			21.599,
			21.662,
			21.691,
			21.722,
			21.784,
			21.799,
			21.847,
			21.909,
			21.97,
			22.016,
			22.033,
			22.093,
			22.131,
			22.156,
			22.219,
			22.235,
			22.282,
			22.343,
			22.404,
			22.451,
			22.529,
			22.561,
			22.591,
			22.652,
			22.713,
			22.778,
			22.84,
			22.885,
			22.901,
			22.963,
			22.995,
			23.025,
			23.102,
			23.15,
			23.21,
			23.273,
			23.319,
			23.336,
			23.398,
			23.428,
			23.46,
			23.52,
			23.536,
			23.583,
			23.644,
			23.708,
			23.754,
			23.83,
			23.862,
			23.894,
			23.954,
			24.018,
			24.078,
			24.14,
			24.203,
			24.266,
			24.296,
			24.328,
			24.405,
			24.452,
			24.513,
			24.574,
			24.622,
			24.7,
			24.731,
			24.761,
			24.822,
			24.886,
			24.946,
			25.008,
			25.056,
			25.072,
			25.132,
			25.166,
			25.196,
			25.256,
			25.274,
			25.319,
			25.383,
			25.444,
			25.49,
			25.505,
			25.568,
			25.598,
			25.628,
			25.693,
			25.706,
			25.753,
			25.814,
			25.878,
			25.925,
			26.003,
			26.034,
			26.064,
			26.126,
			26.189,
			26.251,
			26.311,
			26.358,
			26.375,
			26.436,
			26.467,
			26.498,
			26.56,
			26.576,
			26.621,
			26.685,
			26.745,
			26.793,
			26.808,
			26.87,
			26.901,
			26.932,
			26.995,
			27.008,
			27.057,
			27.119,
			27.181,
			27.226,
			27.303,
			27.333,
			27.367,
			27.428,
			27.489,
			27.551,
			27.614,
			27.66,
			27.675,
			27.737,
			27.767,
			27.801,
			27.863,
			27.876,
			27.924,
			27.987,
			28.049,
			28.093,
			28.111,
			28.17,
			28.203,
			28.235,
			28.317,
			28.357,
			28.42,
			28.483,
			28.543,
			28.607,
			28.637,
			28.668,
			28.731,
			28.746,
			28.793,
			28.854,
			28.917,
			28.978,
			29.039,
			29.072,
			29.101,
			29.164,
			29.178,
			29.226,
			29.288,
			29.351,
			29.395,
			29.412,
			29.475,
			29.504,
			29.535,
			29.598,
			29.661,
			29.722,
			29.784,
			29.831,
			29.847,
			29.909,
			29.938,
			29.97,
			30.049,
			30.093,
			30.156,
			30.219,
			30.266,
			30.28,
			30.342,
			30.378,
			30.405,
			30.465,
			30.48,
			30.529,
			30.59,
			30.651,
			30.715,
			30.777,
			30.806,
			30.837,
			30.9,
			30.962,
			31.023,
			31.086,
			31.148,
			31.212,
			31.241,
			31.272,
			31.335,
			31.35,
			31.396,
			31.46,
			31.521,
			31.567,
			31.584,
			31.645,
			31.675,
			31.707,
			31.784,
			31.831,
			31.892,
			31.955,
			32,
			32.079,
			32.108,
			32.141,
			32.201,
			32.264,
			32.327,
			32.387,
			32.439,
			32.45,
			32.514,
			32.544,
			32.575,
			32.637,
			32.652,
			32.7,
			32.761,
			32.822,
			32.869,
			32.884,
			32.945,
			32.977,
			33.007,
			33.072,
			33.133,
			33.195,
			33.257,
			33.319,
			33.379,
			33.413,
			33.444,
			33.519,
			33.568,
			33.629,
			33.69,
			33.738,
			33.753,
			33.815,
			33.845,
			33.878,
			33.938,
			33.954,
			34,
			34.063,
			34.126,
			34.172,
			34.25,
			34.279,
			34.31,
			34.39,
			34.435,
			34.503,
			34.559,
			34.604,
			34.684,
			34.713,
			34.745,
			34.806,
			34.823,
			34.87,
			34.932,
			34.994,
			35.056,
			35.117,
			35.149,
			35.18,
			35.242,
			35.256,
			35.302,
			35.364,
			35.428,
			35.475,
			35.55,
			35.583,
			35.612,
			35.675,
			35.738,
			35.799,
			35.861,
			35.906,
			35.923,
			35.984,
			36.016,
			36.046,
			36.108,
			36.125,
			36.171,
			36.233,
			36.296,
			36.358,
			36.419,
			36.451,
			36.482,
			36.544,
			36.565,
			36.605,
			36.668,
			36.729,
			36.79,
			36.854,
			36.884,
			36.917,
			36.977,
			37.039,
			37.101,
			37.162,
			37.21,
			37.225,
			37.288,
			37.32,
			37.35,
			37.413,
			37.427,
			37.475,
			37.537,
			37.596,
			37.645,
			37.661,
			37.72,
			37.751,
			37.784,
			37.861,
			37.909,
			37.968,
			38.032,
			38.078,
			38.155,
			38.187,
			38.218,
			38.281,
			38.342,
			38.405,
			38.465,
			38.527,
			38.59,
			38.625,
			38.65,
			38.715,
			38.728,
			38.776,
			38.838,
			38.899,
			38.946,
			38.963,
			39.024,
			39.056,
			39.086,
			39.149,
			39.209,
			39.273,
			39.333,
			39.38,
			39.457,
			39.489,
			39.519,
			39.598,
			39.644,
			39.706,
			39.767,
			39.815,
			39.831,
			39.893,
			39.923,
			39.954,
			40.017,
			40.03,
			40.078,
			40.141,
			40.203,
			40.25,
			40.327,
			40.356,
			40.387,
			40.451,
			40.513,
			40.575,
			40.637,
			40.687,
			40.699,
			40.761,
			40.79,
			40.822,
			40.884,
			40.899,
			40.945,
			41.007,
			41.071,
			41.116,
			41.133,
			41.195,
			41.225,
			41.256,
			41.319,
			41.334,
			41.378,
			41.442,
			41.505,
			41.551,
			41.629,
			41.659,
			41.69,
			41.768,
			41.816,
			41.875,
			41.939,
			41.986,
			42,
			42.061,
			42.092,
			42.125,
			42.187,
			42.2,
			42.248,
			42.311,
			42.372,
			42.419,
			42.435,
			42.496,
			42.526,
			42.559,
			42.622,
			42.683,
			42.751,
			42.806,
			42.867,
			42.93,
			42.962,
			42.993,
			43.054,
			43.117,
			43.178,
			43.241,
			43.302,
			43.363,
			43.396,
			43.426,
			43.488,
			43.504,
			43.55,
			43.614,
			43.675,
			43.722,
			43.799,
			43.83,
			43.86,
			43.921,
			43.984,
			44.045,
			44.109,
			44.154,
			44.233,
			44.263,
			44.296,
			44.357,
			44.42,
			44.479,
			44.542,
			44.588,
			44.604,
			44.665,
			44.698,
			44.73,
			44.791,
			44.813,
			44.852,
			44.916,
			44.977,
			45.022,
			45.099,
			45.132,
			45.164,
			45.226,
			45.286,
			45.35,
			45.411,
			45.457,
			45.472,
			45.535,
			45.567,
			45.596,
			45.674,
			45.72,
			45.784,
			45.844,
			45.906,
			45.969,
			45.999,
			46.032,
			46.093,
			46.108,
			46.156,
			46.218,
			46.28,
			46.325,
			46.402,
			46.434,
			46.466,
			46.528,
			46.59,
			46.651,
			46.714,
			46.76,
			46.775,
			46.838,
			46.871,
			46.899,
			46.961,
			46.977,
			47.022,
			47.089,
			47.148,
			47.193,
			47.207,
			47.271,
			47.302,
			47.334,
			47.411,
			47.457,
			47.52,
			47.582,
			47.643,
			47.706,
			47.736,
			47.767,
			47.829,
			47.891,
			47.953,
			48.018,
			48.063,
			48.078,
			48.139,
			48.17,
			48.201,
			48.263,
			48.279,
			48.326,
			48.389,
			48.448,
			48.495,
			48.512,
			48.573,
			48.604,
			48.637,
			48.699,
			48.761,
			48.821,
			48.884,
			48.945,
			49.007,
			49.04,
			49.07,
			49.147,
			49.193,
			49.256,
			49.317,
			49.364,
			49.38,
			49.441,
			49.474,
			49.504,
			49.567,
			49.579,
			49.627,
			49.69,
			49.753,
			49.799,
			49.875,
			49.907,
			49.936,
			50.015,
			50.061,
			50.124,
			50.184,
			50.246,
			50.309,
			50.341,
			50.37,
			50.451,
			50.497,
			50.559,
			50.618,
			50.667,
			50.683,
			50.744,
			50.774,
			50.806,
			50.868,
			50.884,
			50.931,
			50.995,
			51.054,
			51.117,
			51.179,
			51.209,
			51.239,
			51.302,
			51.365,
			51.427,
			51.489,
			51.55,
			51.611,
			51.643,
			51.675,
			51.735,
			51.751,
			51.799,
			51.86,
			51.922,
			51.969,
			51.983,
			52.045,
			52.077,
			52.108,
			52.171,
			52.185,
			52.233,
			52.294,
			52.356,
			52.404,
			52.479,
			52.512,
			52.541,
			52.619,
			52.667,
			52.732,
			52.789,
			52.836,
			52.852,
			52.912,
			52.945,
			52.977,
			53.038,
			53.057,
			53.1,
			53.163,
			53.224,
			53.286,
			53.349,
			53.38,
			53.409,
			53.471,
			53.535,
			53.596,
			53.658,
			53.703,
			53.782,
			53.813,
			53.844,
			53.907,
			53.922,
			53.968,
			54.031,
			54.094,
			54.14,
			54.153,
			54.218,
			54.247,
			54.278,
			54.34,
			54.356,
			54.403,
			54.466,
			54.525,
			54.574,
			54.65,
			54.682,
			54.712,
			54.773,
			54.835,
			54.899,
			54.96,
			55.007,
			55.023,
			55.083,
			55.12,
			55.146,
			55.209,
			55.225,
			55.272,
			55.333,
			55.395,
			55.442,
			55.457,
			55.52,
			55.55,
			55.582,
			55.657,
			55.706,
			55.766,
			55.829,
			55.89,
			55.951,
			55.984,
			56.015,
			56.075,
			56.139,
			56.201,
			56.262,
			56.31,
			56.323,
			56.387,
			56.416,
			56.449,
			56.511,
			56.526,
			56.571,
			56.636,
			56.698,
			56.743,
			56.76,
			56.82,
			56.853,
			56.883,
			56.945,
			56.961,
			57.006,
			57.069,
			57.131,
			57.182,
			57.256,
			57.285,
			57.317,
			57.378,
			57.44,
			57.504,
			57.565,
			57.628,
			57.688,
			57.72,
			57.752,
			57.813,
			57.828,
			57.876,
			57.939,
			58,
			58.045,
			58.062,
			58.123,
			58.154,
			58.186,
			58.264,
			58.309,
			58.371,
			58.433,
			58.481,
			58.556,
			58.587,
			58.619,
			58.68,
			58.697,
			58.743,
			58.804,
			58.868,
			58.928,
			58.992,
			59.021,
			59.053,
			59.115,
			59.132,
			59.177,
			59.245,
			59.302,
			59.348,
			59.425,
			59.457,
			59.486,
			59.549,
			59.611,
			59.673,
			59.737,
			59.782,
			59.798,
			59.859,
			59.889,
			59.923,
			59.985,
			60,
			60.045,
			60.106,
			60.171,
			60.217,
			60.233,
			60.294,
			60.323,
			60.355,
			60.417,
			60.433,
			60.478,
			60.542,
			60.603,
			60.65,
			60.728,
			60.759,
			60.79,
			60.867,
			60.913,
			60.975,
			61.038,
			61.084,
			61.1,
			61.161,
			61.194,
			61.223,
			61.286,
			61.306,
			61.348,
			61.409,
			61.472,
			61.518,
			61.534,
			61.596,
			61.628,
			61.659,
			61.721,
			61.736,
			61.783,
			61.844,
			61.905,
			61.953,
			62.029,
			62.061,
			62.092,
			62.154,
			62.216,
			62.278,
			62.339,
			62.386,
			62.403,
			62.464,
			62.493,
			62.526,
			62.589,
			62.603,
			62.651,
			62.711,
			62.775,
			62.821,
			62.837,
			62.898,
			62.929,
			62.96,
			63.022,
			63.084,
			63.145,
			63.207,
			63.255,
			63.332,
			63.369,
			63.395,
			63.455,
			63.472,
			63.517,
			63.58,
			63.642,
			63.689,
			63.703,
			63.767,
			63.798,
			63.828,
			63.907,
			63.952,
			64.013,
			64.075,
			64.139,
			64.201,
			64.23,
			64.262,
			64.323,
			64.387,
			64.449,
			64.51,
			64.571,
			64.633,
			64.666,
			64.698,
			64.775,
			64.82,
			64.883,
			64.944,
			64.992,
			65.006,
			65.069,
			65.099,
			65.131,
			65.209,
			65.253,
			65.317,
			65.379,
			65.44,
			65.503,
			65.534,
			65.566,
			65.642,
			65.69,
			65.751,
			65.813,
			65.859,
			65.874,
			65.935,
			65.968,
			65.999,
			66.076,
			66.124,
			66.184,
			66.246,
			66.294,
			66.307,
			66.371,
			66.401,
			66.433,
			66.51,
			66.556,
			66.618,
			66.679,
			66.728,
			66.804,
			66.837,
			66.868,
			66.927,
			66.99,
			67.054,
			67.116,
			67.16,
			67.176,
			67.237,
			67.271,
			67.301,
			67.363,
			67.378,
			67.425,
			67.49,
			67.549,
			67.611,
			67.672,
			67.703,
			67.734,
			67.796,
			67.813,
			67.859,
			67.92,
			67.983,
			68.031,
			68.107,
			68.136,
			68.168,
			68.231,
			68.247,
			68.292,
			68.354,
			68.417,
			68.465,
			68.478,
			68.541,
			68.573,
			68.603,
			68.666,
			68.681,
			68.727,
			68.79,
			68.853,
			68.897,
			68.975,
			69.006,
			69.037,
			69.099,
			69.161,
			69.223,
			69.284,
			69.346,
			69.408,
			69.439,
			69.471,
			69.554,
			69.596,
			69.658,
			69.719,
			69.78,
			69.842,
			69.875,
			69.904,
			69.966,
			69.983,
			70.029,
			70.09,
			70.154,
			70.201,
			70.278,
			70.307,
			70.339,
			70.402,
			70.464,
			70.524,
			70.586,
			70.632,
			70.65,
			70.711,
			70.743,
			70.774,
			70.835,
			70.852,
			70.898,
			70.959,
			71.023,
			71.067,
			71.083,
			71.146,
			71.175,
			71.209,
			71.268,
			71.284,
			71.332,
			71.395,
			71.456,
			71.516,
			71.58,
			71.615,
			71.643,
			71.702,
			71.766,
			71.833,
			71.889,
			71.951,
			72.012,
			72.046,
			72.076,
			72.137,
			72.153,
			72.2,
			72.266,
			72.322,
			72.387,
			72.448,
			72.478,
			72.51,
			72.572,
			72.634,
			72.695,
			72.756,
			72.803,
			72.882,
			72.912,
			72.945,
			73.004,
			73.068,
			73.131,
			73.192,
			73.239,
			73.254,
			73.316,
			73.348,
			73.378,
			73.439,
			73.455,
			73.503,
			73.563,
			73.626,
			73.677,
			73.75,
			73.78,
			73.812,
			73.874,
			73.936,
			73.997,
			74.059,
			74.106,
			74.123,
			74.184,
			74.216,
			74.246,
			74.309,
			74.325,
			74.371,
			74.433,
			74.494,
			74.539,
			74.557,
			74.619,
			74.649,
			74.68,
			74.742,
			74.758,
			74.804,
			74.868,
			74.929,
			74.975,
			75.053,
			75.084,
			75.114,
			75.177,
			75.237,
			75.299,
			75.361,
			75.423,
			75.485,
			75.517,
			75.55,
			75.611,
			75.625,
			75.671,
			75.737,
			75.797,
			75.843,
			75.859,
			75.92,
			75.95,
			75.983,
			76.043,
			76.059,
			76.105,
			76.169,
			76.228,
			76.275,
			76.355,
			76.385,
			76.417,
			76.478,
			76.539,
			76.602,
			76.666,
			76.712,
			76.728,
			76.789,
			76.819,
			76.851,
			76.913,
			76.928,
			76.976,
			77.036,
			77.1,
			77.144,
			77.161,
			77.223,
			77.253,
			77.286,
			77.345,
			77.362,
			77.409,
			77.47,
			77.533,
			77.596,
			77.656,
			77.686,
			77.719,
			77.78,
			77.799,
			77.843,
			77.906,
			77.967,
			78.014,
			78.029,
			78.089,
			78.121,
			78.151,
			78.215,
			78.231,
			78.278,
			78.339,
			78.399,
			78.463,
			78.523,
			78.554,
			78.587,
			78.649,
			78.711,
			78.772,
			78.833,
			78.895,
			78.957,
			78.99,
			79.019,
			79.081,
			79.099,
			79.143,
			79.206,
			79.268,
			79.314,
			79.332,
			79.394,
			79.423,
			79.453,
			79.517,
			79.533,
			79.579,
			79.641,
			79.704,
			79.751,
			79.828,
			79.863,
			79.89,
			79.951,
			80.012,
			80.074,
			80.137,
			80.184,
			80.2,
			80.26,
			80.292,
			80.324,
			80.386,
			80.4,
			80.448,
			80.508,
			80.572,
			80.618,
			80.633,
			80.695,
			80.727,
			80.757,
			80.819,
			80.835,
			80.882,
			80.943,
			81.005,
			81.067,
			81.128,
			81.159,
			81.191,
			81.253,
			81.316,
			81.379,
			81.439,
			81.484,
			81.5,
			81.562,
			81.596,
			81.626,
			81.688,
			81.703,
			81.75,
			81.811,
			81.873,
			81.922,
			81.998,
			82.029,
			82.06,
			82.122,
			82.182,
			82.245,
			82.307,
			82.354,
			82.432,
			82.463,
			82.493,
			82.571,
			82.617,
			82.678,
			82.743,
			82.802,
			82.867,
			82.896,
			82.929,
			82.99,
			83.005,
			83.051,
			83.113,
			83.175,
			83.236,
			83.299,
			83.33,
			83.361,
			83.423,
			83.486,
			83.546,
			83.61,
			83.657,
			83.735,
			83.765,
			83.795,
			83.859,
			83.872,
			83.919,
			83.985,
			84.043,
			84.089,
			84.105,
			84.168,
			84.2,
			84.231,
			84.291,
			84.307,
			84.354,
			84.416,
			84.479,
			84.525,
			84.6,
			84.633,
			84.665,
			84.725,
			84.787,
			84.85,
			84.912,
			84.959,
			84.974,
			85.035,
			85.067,
			85.098,
			85.16,
			85.176,
			85.223,
			85.284,
			85.345,
			85.392,
			85.409,
			85.469,
			85.501,
			85.532,
			85.611,
			85.655,
			85.719,
			85.78,
			85.826,
			85.905,
			85.935,
			85.967,
			86.028,
			86.048,
			86.089,
			86.154,
			86.213,
			86.259,
			86.276,
			86.337,
			86.369,
			86.399,
			86.463,
			86.479,
			86.525,
			86.586,
			86.648,
			86.695,
			86.711,
			86.771,
			86.802,
			86.835,
			86.895,
			86.911,
			86.957,
			87.019,
			87.082,
			87.129,
			87.205,
			87.236,
			87.269,
			87.346,
			87.392,
			87.459,
			87.516,
			87.564,
			87.578,
			87.64,
			87.672,
			87.702,
			87.764,
			87.778,
			87.826,
			87.892,
			87.95,
			87.998,
			88.076,
			88.11,
			88.137,
			88.215,
			88.26,
			88.322,
			88.383,
			88.431,
			88.446,
			88.507,
			88.539,
			88.572,
			88.633,
			88.649,
			88.695,
			88.756,
			88.819,
			88.882,
			88.942,
			88.973,
			89.004,
			89.066,
			89.08,
			89.129,
			89.189,
			89.252,
			89.3,
			89.377,
			89.408,
			89.438,
			89.501,
			89.564,
			89.625,
			89.687,
			89.734,
			89.748,
			89.812,
			89.842,
			89.872,
			89.934,
			89.95,
			89.995,
			90.059,
			90.119,
			90.172,
			90.246,
			90.275,
			90.307,
			90.368,
			90.385,
			90.432,
			90.493,
			90.553,
			90.617,
			90.678,
			90.71,
			90.74,
			90.801,
			90.863,
			90.929,
			90.989,
			91.036,
			91.051,
			91.113,
			91.145,
			91.174,
			91.236,
			91.253,
			91.299,
			91.362,
			91.424,
			91.469,
			91.486,
			91.546,
			91.577,
			91.609,
			91.671,
			91.687,
			91.734,
			91.795,
			91.857,
			91.903,
			91.98,
			92.013,
			92.043,
			92.106,
			92.121,
			92.167,
			92.234,
			92.291,
			92.336,
			92.354,
			92.414,
			92.447,
			92.477,
			92.553,
			92.6,
			92.664,
			92.724,
			92.772,
			92.85,
			92.88,
			92.912,
			92.972,
			93.036,
			93.098,
			93.159,
			93.206,
			93.284,
			93.315,
			93.344,
			93.406,
			93.422,
			93.469,
			93.532,
			93.593,
			93.638,
			93.654,
			93.717,
			93.75,
			93.779,
			93.842,
			93.856,
			93.904,
			93.953
		]
	],
	'1853_3912_7982': [
		[
			93.605
		],
		[
			0
		],
		[
			0,
			2.483,
			3.951,
			5.42,
			9.835,
			12.785,
			17.22,
			20.184,
			24.64,
			29.105,
			32.088,
			35.074,
			36.568,
			38.063,
			42.551,
			45.571,
			50.068,
			53.07,
			57.583,
			62.114,
			65.148,
			68.196,
			72.793,
			75.876,
			82.092,
			83.656,
			88.369,
			91.529,
			96.313,
			99.524,
			104.353,
			109.181,
			112.393,
			117.195,
			120.386,
			123.569,
			126.748,
			131.494,
			137.807,
			139.384,
			144.089,
			147.27,
			150.428,
			155.171,
			158.338,
			163.071,
			164.66,
			166.276,
			171.055,
			174.244,
			179.033,
			182.225,
			185.411,
			186.999,
			190.161,
			194.861,
			199.496,
			202.543,
			205.525,
			209.963,
			212.872,
			218.628,
			220.05,
			222.886,
			224.303,
			227.132,
			229.967,
			234.233,
			237.088,
			241.394,
			242.833,
			244.278,
			250.064,
			251.511,
			255.852,
			258.732,
			263.014,
			265.833,
			268.616,
			269.993,
			274.06,
			276.719,
			279.344,
			283.216,
			285.759,
			289.498,
			290.726,
			291.945,
			295.541,
			297.928,
			301.483,
			303.862,
			306.211,
			307.383,
			310.888,
			312.053,
			315.542,
			317.859,
			321.325,
			323.627,
			327.073,
			329.367,
			332.804,
			336.248,
			338.54,
			340.838,
			344.28,
			346.581,
			350.008,
			351.15,
			352.287,
			357.854,
			361.077,
			363.2,
			366.284,
			368.295,
			370.261,
			373.128,
			374.072,
			375.021,
			377.764,
			379.55,
			382.163,
			385.514,
			386.33,
			388.743,
			390.325,
			391.892,
			394.235,
			395.804,
			398.15,
			398.939,
			399.743,
			402.149,
			403.752,
			406.204,
			407.841,
			409.49,
			410.32,
			411.982,
			414.492,
			417.04,
			418.741,
			420.46,
			423.039,
			424.759,
			426.478,
			428.198,
			429.058,
			431.629,
			433.343,
			435.055,
			437.618,
			439.323,
			441.859,
			443.55,
			446.063,
			447.731,
			450.226,
			453.53,
			456.008,
			456.834,
			458.487,
			460.139,
			460.964,
			462.614,
			464.264,
			466.701,
			468.33,
			470.745,
			471.545,
			472.345,
			474.751,
			476.349,
			478.756,
			480.371,
			481.992,
			485.262,
			486.086,
			488.573,
			490.244,
			492.774,
			494.475,
			497.049,
			500.522,
			501.397,
			504.039,
			505.814,
			507.6,
			512.116,
			514.862,
			515.784,
			516.709,
			519.502,
			521.384,
			524.228,
			526.144,
			529.043,
			530.993,
			531.973,
			533.941,
			535.922,
			536.917,
			537.915,
			540.909,
			542.929,
			544.961,
			545.98,
			548.027,
			550.085,
			551.118,
			554.233,
			556.311,
			558.39,
			561.526,
			563.63,
			566.804,
			567.868,
			568.933,
			572.147,
			574.302,
			577.555,
			579.735,
			581.927,
			583.026,
			585.232,
			586.339,
			588.56,
			591.911,
			594.157,
			596.413,
			598.678,
			602.095,
			604.384,
			606.683,
			607.836,
			610.15,
			611.312,
			613.645,
			615.991,
			619.543,
			621.936,
			625.554,
			626.776,
			628.001,
			631.699,
			634.189,
			637.944,
			644.224,
			646.696,
			647.919,
			650.352,
			652.734,
			653.915,
			656.25,
			658.539,
			661.907,
			664.107,
			667.37,
			669.525,
			672.748,
			674.893,
			677.048,
			682.473,
			685.753,
			687.972,
			691.315,
			692.444,
			693.574,
			696.978,
			699.263,
			702.722,
			707.38,
			708.55,
			712.056,
			714.393,
			716.719,
			720.194,
			722.496,
			725.919,
			727.05,
			728.175,
			733.687,
			735.848,
			739.022,
			742.152,
			744.22,
			745.252,
			746.284,
			749.362,
			751.386,
			754.411,
			756.397,
			758.347,
			759.308,
			761.2,
			763.05,
			763.975,
			766.653,
			768.375,
			770.074,
			772.547,
			774.16,
			776.495,
			777.267,
			778.032,
			780.295,
			781.79,
			784.059,
			785.568,
			787.868,
			789.422,
			790.201,
			791.766,
			794.152,
			795.747,
			797.339,
			800.513,
			801.308,
			802.876,
			804.437,
			805.217,
			806.776,
			807.55,
			809.095,
			810.64,
			812.957,
			814.502,
			817.578,
			818.341,
			820.628,
			822.149,
			824.401,
			827.412,
			828.158,
			829.655,
			830.407,
			831.918,
			833.425,
			834.18,
			835.697,
			837.222,
			839.514,
			841.052,
			843.376,
			844.155,
			844.937,
			847.294,
			848.876,
			850.466,
			854.477,
			856.897,
			857.705,
			858.516,
			860.944,
			862.563,
			865.014,
			866.659,
			869.145,
			870.832,
			873.39,
			875.986,
			877.733,
			879.498,
			882.169,
			883.968,
			886.693,
			887.608,
			888.527,
			891.306,
			893.176,
			896.006,
			897.909,
			900.795,
			903.715,
			905.679,
			907.661,
			909.653,
			912.67,
			914.697,
			916.739,
			917.765,
			919.825,
			921.894,
			922.929,
			926.011,
			928.038,
			930.03,
			932.951,
			934.854,
			937.644,
			938.556,
			939.459,
			942.115,
			943.84,
			946.363,
			948,
			950.391,
			951.941,
			953.456,
			956.383,
			957.095,
			959.191,
			960.567,
			961.946,
			962.629,
			965.349,
			966.023,
			968.041,
			969.374,
			970.696,
			972.667,
			973.972,
			975.91,
			977.192,
			979.114,
			980.407,
			982.335,
			983.615,
			984.961,
			985.633,
			987.642,
			988.997,
			991.132,
			992.553,
			993.987,
			996.226,
			997.72,
			999.225,
			1001.503,
			1003.788,
			1005.263,
			1006.737,
			1008.944,
			1010.368,
			1013.922,
			1016.039,
			1017.45,
			1019.567,
			1020.969,
			1023.074,
			1024.473,
			1025.856,
			1026.548,
			1028.628,
			1029.993,
			1031.349,
			1033.39,
			1034.074,
			1034.751,
			1036.787,
			1038.152,
			1040.222,
			1041.614,
			1043.719,
			1045.147,
			1046.589,
			1048.774,
			1049.511,
			1050.259,
			1053.279,
			1054.043,
			1056.377,
			1059.548,
			1060.357,
			1061.987,
			1062.808,
			1064.449,
			1066.968,
			1068.655,
			1070.346,
			1072.911,
			1074.641,
			1077.266,
			1079.038,
			1081.721,
			1083.531,
			1086.272,
			1088.119,
			1090.917,
			1092.799,
			1093.747,
			1094.699,
			1098.534,
			1099.503,
			1102.424,
			1104.389,
			1107.358,
			1109.355,
			1111.361,
			1112.368,
			1114.392,
			1115.408,
			1117.447,
			1119.497,
			1122.591,
			1124.666,
			1128.835,
			1129.877,
			1133.023,
			1135.135,
			1138.335,
			1140.484,
			1142.661,
			1143.753,
			1149.296,
			1151.546,
			1152.68,
			1154.956,
			1157.244,
			1160.694,
			1162.997,
			1165.308,
			1167.629,
			1168.792,
			1172.297,
			1174.648,
			1178.191,
			1182.939,
			1186.508,
			1187.698,
			1188.889,
			1192.458,
			1194.835,
			1198.396,
			1200.768,
			1204.332,
			1207.908,
			1210.303,
			1212.706,
			1213.912,
			1216.331,
			1218.763,
			1222.435,
			1224.899,
			1229.856,
			1231.1,
			1234.834,
			1237.331,
			1239.83,
			1243.589,
			1246.1,
			1249.866,
			1252.376,
			1256.135,
			1257.388,
			1258.641,
			1262.404,
			1267.431,
			1268.69,
			1271.212,
			1273.741,
			1275.009,
			1278.821,
			1281.372,
			1283.931,
			1287.781,
			1290.356,
			1294.232,
			1296.823,
			1300.719,
			1303.323,
			1307.236,
			1309.849,
			1313.754,
			1316.373,
			1317.681,
			1320.299,
			1324.234,
			1325.548,
			1329.497,
			1332.135,
			1336.101,
			1338.753,
			1342.74,
			1346.74,
			1349.415,
			1352.092,
			1356.088,
			1358.727,
			1362.631,
			1363.917,
			1365.195,
			1368.982,
			1371.467,
			1375.135,
			1377.537,
			1379.906,
			1381.079,
			1383.398,
			1386.811,
			1389.046,
			1390.152,
			1392.341,
			1394.499,
			1396.65,
			1399.804,
			1401.911,
			1405.068,
			1408.235,
			1410.359,
			1412.511,
			1415.733,
			1417.89,
			1421.133,
			1422.215,
			1423.296,
			1426.537,
			1428.688,
			1431.898,
			1434.024,
			1437.243,
			1439.385,
			1440.477,
			1442.684,
			1446.037,
			1448.286,
			1450.532,
			1453.904,
			1456.132,
			1459.433,
			1460.521,
			1461.594,
			1464.778,
			1466.854,
			1468.893,
			1471.895,
			1473.858,
			1476.748,
			1477.701,
			1478.646,
			1481.454,
			1482.381,
			1483.304,
			1486.053,
			1487.883,
			1489.71,
			1490.625,
			1492.467,
			1493.392,
			1495.25,
			1498.057,
			1499.942,
			1501.832,
			1504.674,
			1506.575,
			1509.416,
			1510.366,
			1511.316,
			1514.151,
			1516.047,
			1517.94,
			1520.784,
			1522.68,
			1523.627,
			1526.463,
			1528.348,
			1531.172,
			1533.052,
			1534.929,
			1536.805,
			1539.61,
			1541.474,
			1543.336,
			1544.264,
			1546.116,
			1547.041,
			1548.884,
			1550.719,
			1553.462,
			1555.282,
			1558.003,
			1558.908,
			1559.811,
			1562.516,
			1564.314,
			1567.003,
			1568.79,
			1571.46,
			1573.238,
			1574.125,
			1575.894,
			1577.661,
			1578.544,
			1580.306,
			1582.064,
			1583.821,
			1586.45,
			1588.203,
			1589.955,
			1590.831,
			1593.463,
			1595.221,
			1596.982,
			1599.63,
			1601.399,
			1604.066,
			1604.958,
			1605.852,
			1608.543,
			1610.348,
			1613.071,
			1614.899,
			1617.655,
			1619.502,
			1620.429,
			1622.29,
			1624.165,
			1625.104,
			1626.994,
			1628.896,
			1632.729,
			1633.694,
			1636.609,
			1638.563,
			1641.525,
			1643.509,
			1646.517,
			1648.533,
			1651.588,
			1653.636,
			1654.667,
			1655.7,
			1658.814,
			1660.907,
			1664.047,
			1666.149,
			1669.322,
			1671.452,
			1673.591,
			1674.667,
			1677.906,
			1680.08,
			1682.264,
			1685.558,
			1687.766,
			1692.211,
			1693.328,
			1696.691,
			1698.945,
			1702.338,
			1704.61,
			1708.029,
			1710.315,
			1711.459,
			1713.754,
			1717.202,
			1718.355,
			1721.819,
			1724.136,
			1727.617,
			1729.946,
			1732.28,
			1733.45,
			1736.97,
			1739.326,
			1741.696,
			1745.271,
			1747.672,
			1751.273,
			1752.487,
			1753.702,
			1757.363,
			1759.814,
			1763.507,
			1765.978,
			1768.456,
			1769.698,
			1772.188,
			1773.436,
			1775.938,
			1779.706,
			1782.229,
			1784.76,
			1787.299,
			1791.123,
			1794.964,
			1796.248,
			1797.534,
			1800.112,
			1801.404,
			1803.992,
			1806.588,
			1810.49,
			1813.091,
			1818.308,
			1819.615,
			1823.542,
			1826.166,
			1830.11,
			1832.745,
			1836.709,
			1839.356,
			1840.682,
			1843.335,
			1845.993,
			1847.324,
			1849.989,
			1852.659,
			1856.673,
			1859.358,
			1862.048,
			1864.747,
			1866.098,
			1870.165,
			1872.884,
			1875.613,
			1879.721,
			1882.468,
			1886.607,
			1889.376,
			1893.543,
			1896.33,
			1900.522,
			1903.325,
			1907.539,
			1913.18,
			1914.594,
			1918.844,
			1921.684,
			1924.529,
			1928.806,
			1931.665,
			1938.837,
			1943.159,
			1946.048,
			1950.393,
			1953.297,
			1957.663,
			1960.581,
			1962.042,
			1964.968,
			1969.368,
			1972.308,
			1975.252,
			1978.202,
			1981.157,
			1982.636,
			1988.566,
			1990.052,
			1994.517,
			1997.501,
			2000.49,
			2004.986,
			2007.989,
			2012.502,
			2014.008,
			2015.515,
			2020.043,
			2023.065,
			2027.605,
			2030.63,
			2035.14,
			2038.111,
			2039.582,
			2042.495,
			2046.789,
			2049.604,
			2055.118,
			2059.149,
			2064.386,
			2065.671,
			2068.212,
			2069.469,
			2071.954,
			2074.401,
			2078,
			2080.355,
			2083.819,
			2084.954,
			2086.08,
			2089.398,
			2091.563,
			2094.735,
			2096.802,
			2099.829,
			2101.799,
			2102.77,
			2104.683,
			2106.56,
			2107.484,
			2109.307,
			2111.096,
			2113.713,
			2115.413,
			2117.893,
			2118.702,
			2119.502,
			2121.847,
			2123.368,
			2124.868,
			2128.431,
			2130.465,
			2131.127,
			2131.781,
			2133.693,
			2134.925,
			2136.726,
			2137.9,
			2139.616,
			2140.743,
			2141.86,
			2142.417,
			2144.089,
			2145.203,
			2146.316,
			2147.99,
			2149.151,
			2150.905,
			2151.49,
			2152.077,
			2153.898,
			2155.172,
			2157.083,
			2158.373,
			2159.744,
			2161.796,
			2163.166,
			2165.305,
			2166.018,
			2166.73,
			2168.876,
			2170.321,
			2171.762,
			2172.482,
			2173.906,
			2175.985,
			2178.058,
			2179.396,
			2180.704,
			2182.661,
			2183.94,
			2185.772,
			2186.381,
			2186.989,
			2188.814,
			2189.999,
			2191.775,
			2192.964,
			2194.766,
			2196.572,
			2197.78,
			2199.623,
			2200.865,
			2202.109,
			2203.357,
			2205.259,
			2207.815,
			2208.459,
			2209.748,
			2211.686,
			2212.986,
			2214.939,
			2216.238,
			2218.863,
			2219.527,
			2221.53,
			2222.877,
			2224.977,
			2226.45,
			2227.929,
			2228.673,
			2231.16,
			2232.876,
			2234.643,
			2235.637,
			2237.628,
			2239.639,
			2242.813,
			2244.92,
			2249.052,
			2250.049,
			2252.927,
			2254.821,
			2257.382,
			2259.09,
			2260.718,
			2263.095,
			2264.684,
			2267.745,
			2268.514,
			2270.814,
			2272.337,
			2274.639,
			2276.183,
			2278.514,
			2280.083,
			2280.878,
			2282.477,
			2284.089,
			2286.543,
			2288.202,
			2290.694,
			2291.531,
			2292.372,
			2294.915,
			2296.631,
			2299.236,
			2300.989,
			2303.648,
			2305.437,
			2306.322,
			2307.226,
			2309.956,
			2310.873,
			2311.793,
			2314.573,
			2316.444,
			2318.329,
			2319.276,
			2323.102,
			2324.067,
			2326.982,
			2328.943,
			2330.917,
			2333.904,
			2335.911,
			2338.949,
			2339.969,
			2340.991,
			2344.081,
			2346.157,
			2349.296,
			2351.405,
			2354.577,
			2356.692,
			2357.754,
			2359.886,
			2363.106,
			2365.272,
			2367.449,
			2369.646,
			2371.856,
			2372.966,
			2375.206,
			2377.456,
			2378.59,
			2380.872,
			2384.344,
			2386.67,
			2390.187,
			2392.547,
			2396.119,
			2397.315,
			2398.514,
			2402.112,
			2404.497,
			2408.126,
			2412.916,
			2414.112,
			2416.472,
			2417.664,
			2420.042,
			2423.608,
			2428.357,
			2431.93,
			2434.325,
			2437.948,
			2440.391,
			2444.089,
			2446.602,
			2450.427,
			2455.577,
			2459.479,
			2460.785,
			2462.092,
			2466.025,
			2468.652,
			2475.267,
			2479.276,
			2481.968,
			2484.674,
			2486.026,
			2488.73,
			2490.082,
			2492.777,
			2495.448,
			2499.424,
			2502.039,
			2505.885,
			2507.143,
			2508.39,
			2512.074,
			2514.477,
			2518.037,
			2520.355,
			2522.642,
			2527.094,
			2528.194,
			2531.458,
			2532.526,
			2533.587,
			2536.74,
			2538.825,
			2541.935,
			2546.074,
			2547.106,
			2550.216,
			2552.291,
			2554.385,
			2557.537,
			2559.661,
			2563.929,
			2565.002,
			2568.234,
			2570.42,
			2573.718,
			2575.928,
			2579.26,
			2581.492,
			2584.853,
			2588.221,
			2590.483,
			2592.756,
			2595.037,
			2598.472,
			2600.776,
			2603.088,
			2604.246,
			2606.559,
			2610.036,
			2612.346,
			2615.802,
			2618.11,
			2621.574,
			2623.881,
			2627.337,
			2629.646,
			2633.101,
			2635.424,
			2637.727,
			2638.877,
			2642.336,
			2644.644,
			2646.96,
			2648.121,
			2650.441,
			2652.771,
			2656.268,
			2658.609,
			2662.121,
			2663.294,
			2664.467,
			2667.987,
			2670.339,
			2672.697,
			2676.247,
			2678.626,
			2682.211,
			2683.415,
			2684.62,
			2689.474,
			2690.695,
			2694.38,
			2696.847,
			2700.561,
			2703.041,
			2704.281,
			2706.769,
			2709.25,
			2712.978,
			2715.451,
			2720.388,
			2721.619,
			2725.3,
			2726.524,
			2727.747,
			2731.396,
			2733.819,
			2736.231,
			2739.833,
			2743.412,
			2745.78,
			2748.139,
			2751.652,
			2752.82,
			2753.986,
			2757.477,
			2762.128,
			2763.292,
			2765.626,
			2767.965,
			2769.136,
			2772.663,
			2775.022,
			2777.386,
			2783.329,
			2786.926,
			2789.316,
			2792.951,
			2795.386,
			2799.079,
			2801.52,
			2805.227,
			2808.961,
			2810.21,
			2815.234,
			2816.497,
			2820.299,
			2822.85,
			2825.411,
			2826.695,
			2829.271,
			2831.858,
			2833.155,
			2835.756,
			2837.062,
			2839.679,
			2842.3,
			2846.279,
			2848.934,
			2852.914,
			2854.254,
			2855.596,
			2859.663,
			2862.37,
			2866.426,
			2869.156,
			2871.917,
			2873.293,
			2876.052,
			2877.435,
			2880.21,
			2882.995,
			2887.196,
			2890.003,
			2894.223,
			2897.038,
			2902.677,
			2904.09,
			2908.335,
			2911.174,
			2914.018,
			2921.152,
			2925.449,
			2926.884,
			2928.321,
			2932.614,
			2939.861,
			2942.762,
			2947.102,
			2950.021,
			2952.949,
			2954.416,
			2957.355,
			2961.776,
			2964.733,
			2969.181,
			2972.156,
			2976.655,
			2979.646,
			2984.146,
			2987.154,
			2990.169,
			2994.711,
			2997.75,
			3002.323,
			3006.913,
			3009.981,
			3013.055,
			3017.677,
			3020.763,
			3025.404,
			3028.504,
			3031.609,
			3033.161,
			3037.825,
			3040.939,
			3044.06,
			3048.759,
			3051.879,
			3058.295,
			3059.875,
			3064.614,
			3067.756,
			3072.463,
			3075.622,
			3080.261,
			3083.317,
			3087.847,
			3092.234,
			3095.135,
			3097.997,
			3100.823,
			3103.606,
			3104.983,
			3107.708,
			3111.72,
			3114.344,
			3115.642,
			3118.208,
			3120.734,
			3124.439,
			3126.859,
			3131.568,
			3132.722,
			3137.287,
			3138.408,
			3141.753,
			3143.97,
			3147.283,
			3149.493,
			3150.597,
			3152.809,
			3156.138,
			3158.369,
			3160.611,
			3163.99,
			3166.254,
			3169.668,
			3170.808,
			3171.949,
			3175.404,
			3177.717,
			3180.058,
			3183.592,
			3185.974,
			3190.778,
			3191.986,
			3196.842,
			3198.061,
			3201.722,
			3204.165,
			3207.833,
			3210.284,
			3211.511,
			3213.971,
			3217.665,
			3220.136,
			3222.605,
			3226.294,
			3227.529,
			3228.765,
			3232.494,
			3233.729,
			3234.964,
			3238.675,
			3241.144,
			3244.839,
			3247.302,
			3250.982,
			3253.437,
			3254.658,
			3255.882,
			3259.554,
			3262.005,
			3265.694,
			3268.164,
			3271.883,
			3274.373,
			3278.124,
			3281.894,
			3284.418,
			3286.928,
			3290.739,
			3293.311,
			3298.413,
			3299.7,
			3303.571,
			3306.165,
			3310.065,
			3312.671,
			3316.597,
			3320.54,
			3321.858,
			3327.145,
			3329.798,
			3332.457,
			3337.795,
			3339.134,
			3341.818,
			3344.509,
			3345.857,
			3348.56,
			3352.63,
			3355.355,
			3359.457,
			3362.202,
			3366.333,
			3369.095,
			3373.253,
			3376.033,
			3380.213,
			3383.006,
			3385.804,
			3387.206,
			3391.417,
			3394.233,
			3398.469,
			3404.14,
			3408.412,
			3411.269,
			3417.002,
			3418.44,
			3421.322,
			3425.66,
			3428.563,
			3435.851,
			3440.24,
			3441.706,
			3443.174,
			3447.585,
			3450.534,
			3454.969,
			3457.957,
			3462.386,
			3465.364,
			3468.347,
			3469.836,
			3474.285,
			3477.222,
			3480.124,
			3484.409,
			3487.221,
			3491.376,
			3494.101,
			3498.12,
			3500.751,
			3503.344,
			3507.162,
			3509.66,
			3513.337,
			3515.742,
			3520.446,
			3521.601,
			3525.006,
			3527.229,
			3530.494,
			3532.623,
			3535.747,
			3538.786,
			3540.766,
			3542.709,
			3545.553,
			3547.403,
			3550.114,
			3551.001,
			3551.88,
			3555.304,
			3556.136,
			3558.577,
			3560.161,
			3562.474,
			3564.704,
			3566.14,
			3567.54,
			3568.905,
			3570.898,
			3573.452,
			3574.066,
			3575.261,
			3576.419,
			3576.984,
			3578.626,
			3579.682,
			3580.716,
			3582.218,
			3583.193,
			3585.1,
			3585.569,
			3586.961,
			3587.881,
			3589.257,
			3590.206,
			3591.161,
			3591.639,
			3592.596,
			3594.037,
			3595.565,
			3596.608,
			3597.655,
			3599.234,
			3600.363,
			3602.092,
			3603.246,
			3604.403,
			3605.007,
			3606.229,
			3607.447,
			3609.273,
			3610.53,
			3612.404,
			3613.024,
			3613.645,
			3616.114,
			3616.728,
			3618.57,
			3619.804,
			3621.661,
			3624.723,
			3625.922,
			3626.521,
			3627.716,
			3628.908,
			3630.671,
			3631.828,
			3634.138,
			3634.717,
			3636.424,
			3637.557,
			3638.692,
			3640.406,
			3641.549,
			3642.122,
			3643.846,
			3644.425,
			3646.786,
			3647.38,
			3649.175,
			3650.395,
			3652.254,
			3653.504,
			3655.403,
			3656.679,
			3658.618,
			3659.928,
			3661.877,
			3663.195,
			3665.195,
			3665.857,
			3666.522,
			3668.544,
			3669.913,
			3671.947,
			3673.321,
			3675.403,
			3676.783,
			3677.479,
			3678.884,
			3680.302,
			3681.011,
			3681.724,
			3683.886,
			3685.351,
			3687.59,
			3689.103,
			3690.636,
			3691.413,
			3693.767,
			3695.356,
			3696.965,
			3699.391,
			3701.035,
			3703.527,
			3704.364,
			3705.205,
			3707.73,
			3709.414,
			3711.963,
			3713.678,
			3716.276,
			3718.904,
			3720.671,
			3723.347,
			3725.147,
			3726.96,
			3729.703,
			3730.624,
			3731.548,
			3733.404,
			3735.274,
			3738.102,
			3739.051,
			3740.958,
			3742.878,
			3745.781,
			3747.732,
			3750.681,
			3751.67,
			3752.663,
			3755.657,
			3757.669,
			3760.709,
			3764.805,
			3765.837,
			3767.908,
			3768.948,
			3771.036,
			3774.182,
			3776.279,
			3778.38,
			3781.552,
			3783.68,
			3786.892,
			3789.045,
			3791.209,
			3792.295,
			3794.475,
			3797.764,
			3799.97,
			3802.186,
			3803.298,
			3805.53,
			3806.65,
			3807.772,
			3811.156,
			3813.427,
			3819.151,
			3822.618,
			3824.942,
			3827.276,
			3828.447,
			3830.795,
			3831.972,
			3834.355,
			3836.729,
			3840.316,
			3842.73,
			3846.378,
			3847.605,
			3848.834,
			3852.542,
			3855.031,
			3858.79,
			3863.843,
			3867.661,
			3868.938,
			3871.499,
			3874.068,
			3875.355,
			3876.645,
			3880.527,
			3883.126,
			3887.039,
			3889.655,
			3893.586,
			3897.521,
			3900.143,
			3902.741,
			3906.672,
			3909.286,
			3913.203,
			3915.811,
			3919.729,
			3922.349,
			3926.288,
			3928.922,
			3932.885,
			3935.534,
			3936.862,
			3939.52,
			3943.517,
			3946.189,
			3948.866,
			3954.237,
			3955.583,
			3960.984,
			3962.339,
			3965.057,
			3966.417,
			3969.15,
			3971.89,
			3976.02,
			3978.784,
			3982.946,
			3985.73,
			3989.918,
			3992.718,
			3996.931,
			3999.749,
			4003.992,
			4011.101,
			4013.955,
			4015.385,
			4018.249,
			4021.12,
			4025.439,
			4028.327,
			4031.221,
			4035.573,
			4039.938,
			4042.855,
			4046.491
		],
		[
			0,
			0.035,
			0.069,
			0.096,
			0.172,
			0.221,
			0.283,
			0.345,
			0.409,
			0.501,
			0.533,
			0.594,
			0.61,
			0.655,
			0.719,
			0.78,
			0.841,
			0.904,
			0.966,
			1.044,
			1.089,
			1.152,
			1.214,
			1.261,
			1.367,
			1.4,
			1.46,
			1.522,
			1.585,
			1.647,
			1.709,
			1.802,
			1.832,
			1.911,
			1.958,
			2.018,
			2.08,
			2.144,
			2.235,
			2.267,
			2.346,
			2.391,
			2.452,
			2.514,
			2.578,
			2.637,
			2.669,
			2.7,
			2.762,
			2.823,
			2.888,
			2.949,
			2.996,
			3.011,
			3.072,
			3.133,
			3.213,
			3.26,
			3.321,
			3.383,
			3.446,
			3.538,
			3.569,
			3.63,
			3.646,
			3.694,
			3.756,
			3.816,
			3.88,
			3.94,
			3.971,
			4.002,
			4.082,
			4.128,
			4.193,
			4.25,
			4.313,
			4.376,
			4.407,
			4.436,
			4.514,
			4.562,
			4.623,
			4.684,
			4.747,
			4.809,
			4.84,
			4.872,
			4.948,
			4.995,
			5.058,
			5.12,
			5.166,
			5.182,
			5.273,
			5.305,
			5.368,
			5.428,
			5.493,
			5.552,
			5.616,
			5.678,
			5.739,
			5.817,
			5.863,
			5.927,
			5.988,
			6.033,
			6.112,
			6.14,
			6.171,
			6.297,
			6.364,
			6.421,
			6.484,
			6.546,
			6.605,
			6.668,
			6.684,
			6.73,
			6.792,
			6.853,
			6.916,
			7.01,
			7.039,
			7.117,
			7.166,
			7.228,
			7.289,
			7.351,
			7.413,
			7.444,
			7.476,
			7.536,
			7.6,
			7.662,
			7.723,
			7.77,
			7.786,
			7.846,
			7.909,
			7.986,
			8.034,
			8.094,
			8.157,
			8.22,
			8.28,
			8.316,
			8.343,
			8.406,
			8.465,
			8.528,
			8.589,
			8.653,
			8.714,
			8.778,
			8.853,
			8.901,
			8.963,
			9.071,
			9.149,
			9.18,
			9.21,
			9.274,
			9.29,
			9.335,
			9.397,
			9.46,
			9.521,
			9.583,
			9.615,
			9.645,
			9.723,
			9.768,
			9.831,
			9.892,
			9.94,
			10.047,
			10.08,
			10.14,
			10.202,
			10.264,
			10.326,
			10.389,
			10.481,
			10.513,
			10.59,
			10.636,
			10.699,
			10.806,
			10.886,
			10.915,
			10.946,
			11.025,
			11.072,
			11.134,
			11.195,
			11.258,
			11.32,
			11.35,
			11.381,
			11.444,
			11.459,
			11.504,
			11.568,
			11.628,
			11.676,
			11.692,
			11.753,
			11.783,
			11.816,
			11.893,
			11.939,
			12.002,
			12.065,
			12.126,
			12.186,
			12.217,
			12.25,
			12.311,
			12.373,
			12.44,
			12.496,
			12.544,
			12.56,
			12.621,
			12.653,
			12.682,
			12.761,
			12.807,
			12.868,
			12.931,
			12.994,
			13.054,
			13.086,
			13.118,
			13.179,
			13.194,
			13.24,
			13.305,
			13.365,
			13.427,
			13.489,
			13.521,
			13.551,
			13.627,
			13.673,
			13.737,
			13.86,
			13.922,
			13.954,
			13.985,
			14.045,
			14.063,
			14.109,
			14.17,
			14.233,
			14.294,
			14.356,
			14.419,
			14.481,
			14.544,
			14.605,
			14.714,
			14.791,
			14.853,
			14.914,
			14.931,
			14.977,
			15.039,
			15.101,
			15.163,
			15.257,
			15.288,
			15.365,
			15.412,
			15.473,
			15.534,
			15.583,
			15.66,
			15.69,
			15.72,
			15.846,
			15.906,
			15.969,
			16.032,
			16.093,
			16.123,
			16.154,
			16.216,
			16.28,
			16.339,
			16.403,
			16.45,
			16.464,
			16.527,
			16.563,
			16.588,
			16.666,
			16.713,
			16.776,
			16.838,
			16.899,
			16.961,
			16.993,
			17.023,
			17.084,
			17.147,
			17.21,
			17.27,
			17.334,
			17.396,
			17.428,
			17.458,
			17.536,
			17.582,
			17.645,
			17.753,
			17.768,
			17.831,
			17.861,
			17.892,
			17.955,
			17.97,
			18.015,
			18.077,
			18.139,
			18.202,
			18.293,
			18.326,
			18.404,
			18.449,
			18.511,
			18.626,
			18.635,
			18.698,
			18.729,
			18.76,
			18.82,
			18.837,
			18.884,
			18.947,
			19.007,
			19.068,
			19.132,
			19.163,
			19.194,
			19.27,
			19.318,
			19.379,
			19.487,
			19.565,
			19.597,
			19.628,
			19.704,
			19.751,
			19.815,
			19.876,
			19.937,
			20,
			20.062,
			20.139,
			20.185,
			20.247,
			20.311,
			20.357,
			20.433,
			20.466,
			20.496,
			20.572,
			20.62,
			20.683,
			20.745,
			20.807,
			20.9,
			20.931,
			20.992,
			21.054,
			21.117,
			21.178,
			21.223,
			21.239,
			21.301,
			21.333,
			21.364,
			21.443,
			21.489,
			21.549,
			21.612,
			21.672,
			21.736,
			21.768,
			21.798,
			21.86,
			21.921,
			21.984,
			22.046,
			22.109,
			22.17,
			22.231,
			22.31,
			22.355,
			22.417,
			22.48,
			22.526,
			22.54,
			22.636,
			22.664,
			22.749,
			22.788,
			22.853,
			22.912,
			22.976,
			23.038,
			23.1,
			23.179,
			23.223,
			23.286,
			23.347,
			23.393,
			23.411,
			23.503,
			23.535,
			23.611,
			23.656,
			23.721,
			23.782,
			23.845,
			23.905,
			23.967,
			24.046,
			24.093,
			24.153,
			24.215,
			24.264,
			24.402,
			24.479,
			24.526,
			24.588,
			24.649,
			24.712,
			24.773,
			24.812,
			24.835,
			24.912,
			24.96,
			25.023,
			25.083,
			25.13,
			25.146,
			25.206,
			25.271,
			25.346,
			25.394,
			25.457,
			25.519,
			25.579,
			25.643,
			25.673,
			25.704,
			25.783,
			25.828,
			25.889,
			25.997,
			26.013,
			26.076,
			26.105,
			26.139,
			26.215,
			26.263,
			26.325,
			26.387,
			26.449,
			26.511,
			26.572,
			26.635,
			26.697,
			26.759,
			26.821,
			26.883,
			26.945,
			26.976,
			27.005,
			27.085,
			27.13,
			27.193,
			27.253,
			27.318,
			27.378,
			27.41,
			27.438,
			27.502,
			27.519,
			27.563,
			27.628,
			27.69,
			27.75,
			27.843,
			27.875,
			27.951,
			27.999,
			28.064,
			28.122,
			28.169,
			28.184,
			28.309,
			28.37,
			28.385,
			28.433,
			28.493,
			28.556,
			28.617,
			28.679,
			28.711,
			28.743,
			28.821,
			28.867,
			28.933,
			29.036,
			29.115,
			29.145,
			29.177,
			29.253,
			29.301,
			29.364,
			29.426,
			29.486,
			29.58,
			29.612,
			29.673,
			29.689,
			29.736,
			29.799,
			29.859,
			29.92,
			30.013,
			30.046,
			30.122,
			30.169,
			30.231,
			30.293,
			30.355,
			30.417,
			30.478,
			30.542,
			30.558,
			30.603,
			30.666,
			30.773,
			30.789,
			30.85,
			30.883,
			30.914,
			30.994,
			31.036,
			31.098,
			31.162,
			31.224,
			31.286,
			31.346,
			31.411,
			31.47,
			31.534,
			31.596,
			31.659,
			31.72,
			31.751,
			31.781,
			31.86,
			31.905,
			31.971,
			32.029,
			32.091,
			32.153,
			32.214,
			32.294,
			32.341,
			32.403,
			32.465,
			32.511,
			32.586,
			32.619,
			32.649,
			32.728,
			32.773,
			32.836,
			32.898,
			32.943,
			32.961,
			33.022,
			33.083,
			33.146,
			33.162,
			33.207,
			33.27,
			33.33,
			33.395,
			33.455,
			33.516,
			33.596,
			33.641,
			33.703,
			33.764,
			33.813,
			33.888,
			33.92,
			33.952,
			34.013,
			34.076,
			34.137,
			34.199,
			34.263,
			34.323,
			34.355,
			34.386,
			34.463,
			34.51,
			34.572,
			34.633,
			34.694,
			34.756,
			34.789,
			34.818,
			34.897,
			34.944,
			35.005,
			35.068,
			35.129,
			35.192,
			35.224,
			35.255,
			35.315,
			35.332,
			35.378,
			35.439,
			35.502,
			35.549,
			35.564,
			35.627,
			35.656,
			35.689,
			35.767,
			35.812,
			35.875,
			35.937,
			35.998,
			36.061,
			36.092,
			36.122,
			36.184,
			36.245,
			36.306,
			36.37,
			36.416,
			36.432,
			36.523,
			36.556,
			36.634,
			36.681,
			36.743,
			36.804,
			36.866,
			36.927,
			36.96,
			36.989,
			37.051,
			37.067,
			37.114,
			37.18,
			37.239,
			37.299,
			37.363,
			37.394,
			37.425,
			37.503,
			37.548,
			37.612,
			37.674,
			37.733,
			37.797,
			37.827,
			37.86,
			37.92,
			37.936,
			37.982,
			38.045,
			38.105,
			38.17,
			38.23,
			38.26,
			38.293,
			38.369,
			38.417,
			38.477,
			38.539,
			38.586,
			38.664,
			38.697,
			38.728,
			38.804,
			38.85,
			38.914,
			38.976,
			39.038,
			39.098,
			39.13,
			39.16,
			39.222,
			39.242,
			39.285,
			39.346,
			39.456,
			39.469,
			39.532,
			39.595,
			39.673,
			39.719,
			39.782,
			39.84,
			39.906,
			39.966,
			39.998,
			40.028,
			40.09,
			40.154,
			40.216,
			40.278,
			40.337,
			40.401,
			40.431,
			40.462,
			40.539,
			40.586,
			40.649,
			40.711,
			40.757,
			40.867,
			40.897,
			40.959,
			41.021,
			41.084,
			41.146,
			41.207,
			41.268,
			41.304,
			41.332,
			41.409,
			41.455,
			41.517,
			41.579,
			41.64,
			41.704,
			41.735,
			41.766,
			41.842,
			41.889,
			41.952,
			42.014,
			42.075,
			42.137,
			42.167,
			42.199,
			42.261,
			42.324,
			42.386,
			42.447,
			42.493,
			42.509,
			42.572,
			42.603,
			42.633,
			42.711,
			42.756,
			42.82,
			42.88,
			42.945,
			43.006,
			43.036,
			43.066,
			43.129,
			43.145,
			43.189,
			43.254,
			43.317,
			43.378,
			43.472,
			43.503,
			43.578,
			43.627,
			43.686,
			43.749,
			43.811,
			43.873,
			43.904,
			43.936,
			43.999,
			44.012,
			44.06,
			44.123,
			44.184,
			44.245,
			44.306,
			44.34,
			44.369,
			44.446,
			44.494,
			44.556,
			44.617,
			44.663,
			44.742,
			44.804,
			44.867,
			44.928,
			44.99,
			45.051,
			45.113,
			45.207,
			45.237,
			45.315,
			45.363,
			45.427,
			45.487,
			45.533,
			45.673,
			45.733,
			45.795,
			45.859,
			45.92,
			45.983,
			46.045,
			46.076,
			46.106,
			46.183,
			46.231,
			46.292,
			46.353,
			46.401,
			46.417,
			46.509,
			46.539,
			46.618,
			46.664,
			46.727,
			46.787,
			46.849,
			46.912,
			46.943,
			46.972,
			47.037,
			47.097,
			47.161,
			47.223,
			47.284,
			47.346,
			47.377,
			47.407,
			47.488,
			47.533,
			47.656,
			47.717,
			47.811,
			47.842,
			47.904,
			47.92,
			47.966,
			48.027,
			48.089,
			48.135,
			48.213,
			48.244,
			48.277,
			48.355,
			48.401,
			48.464,
			48.525,
			48.587,
			48.647,
			48.68,
			48.711,
			48.773,
			48.786,
			48.835,
			48.897,
			48.96,
			49.019,
			49.084,
			49.113,
			49.146,
			49.222,
			49.269,
			49.33,
			49.438,
			49.516,
			49.551,
			49.579,
			49.639,
			49.703,
			49.764,
			49.826,
			49.89,
			49.95,
			49.983,
			50.012,
			50.09,
			50.136,
			50.2,
			50.261,
			50.323,
			50.383,
			50.417,
			50.445,
			50.522,
			50.57,
			50.638,
			50.695,
			50.755,
			50.819,
			50.88,
			50.943,
			50.959,
			51.005,
			51.066,
			51.128,
			51.174,
			51.19,
			51.253,
			51.315,
			51.392,
			51.44,
			51.502,
			51.563,
			51.623,
			51.686,
			51.717,
			51.748,
			51.811,
			51.872,
			51.935,
			51.997,
			52.06,
			52.152,
			52.184,
			52.262,
			52.306,
			52.369,
			52.43,
			52.494,
			52.586,
			52.617,
			52.678,
			52.739,
			52.804,
			52.864,
			52.928,
			53.02,
			53.05,
			53.127,
			53.176,
			53.237,
			53.3,
			53.347,
			53.361,
			53.453,
			53.485,
			53.547,
			53.563,
			53.61,
			53.676,
			53.734,
			53.796,
			53.888,
			53.92,
			53.996,
			54.044,
			54.106,
			54.166,
			54.213,
			54.293,
			54.354,
			54.431,
			54.478,
			54.538,
			54.602,
			54.664,
			54.726,
			54.788,
			54.849,
			54.866,
			54.912,
			54.974,
			55.036,
			55.083,
			55.16,
			55.191,
			55.22,
			55.3,
			55.346,
			55.407,
			55.471,
			55.531,
			55.595,
			55.625,
			55.656,
			55.718,
			55.737,
			55.781,
			55.842,
			55.902,
			55.949,
			55.967,
			56.058,
			56.089,
			56.168,
			56.212,
			56.278,
			56.336,
			56.383,
			56.462,
			56.494,
			56.524,
			56.587,
			56.647,
			56.71,
			56.772,
			56.833,
			56.896,
			56.926,
			56.956,
			57.036,
			57.083,
			57.145,
			57.205,
			57.252,
			57.266,
			57.329,
			57.359,
			57.391,
			57.453,
			57.516,
			57.578,
			57.64,
			57.703,
			57.765,
			57.801,
			57.826,
			57.889,
			57.949,
			58.012,
			58.12,
			58.136,
			58.199,
			58.23,
			58.261,
			58.337,
			58.446,
			58.507,
			58.57,
			58.633,
			58.693,
			58.757,
			58.819,
			58.881,
			58.99,
			59.066,
			59.097,
			59.129,
			59.204,
			59.251,
			59.377,
			59.438,
			59.499,
			59.532,
			59.563,
			59.623,
			59.641,
			59.686,
			59.75,
			59.811,
			59.873,
			59.933,
			59.966,
			59.997,
			60.074,
			60.12,
			60.183,
			60.245,
			60.305,
			60.4,
			60.429,
			60.494,
			60.507,
			60.555,
			60.618,
			60.679,
			60.74,
			60.833,
			60.864,
			60.941,
			60.989,
			61.052,
			61.111,
			61.175,
			61.266,
			61.299,
			61.36,
			61.423,
			61.485,
			61.547,
			61.608,
			61.67,
			61.733,
			61.811,
			61.856,
			61.923,
			61.981,
			62.044,
			62.104,
			62.137,
			62.168,
			62.227,
			62.291,
			62.352,
			62.414,
			62.478,
			62.537,
			62.601,
			62.677,
			62.724,
			62.786,
			62.849,
			62.894,
			62.911,
			63.005,
			63.035,
			63.098,
			63.112,
			63.16,
			63.221,
			63.283,
			63.345,
			63.407,
			63.438,
			63.47,
			63.546,
			63.593,
			63.656,
			63.717,
			63.765,
			63.841,
			63.871,
			63.902,
			63.987,
			64.028,
			64.088,
			64.152,
			64.214,
			64.275,
			64.306,
			64.337,
			64.399,
			64.461,
			64.523,
			64.63,
			64.647,
			64.71,
			64.738,
			64.772,
			64.848,
			64.896,
			64.956,
			65.02,
			65.081,
			65.144,
			65.205,
			65.266,
			65.283,
			65.33,
			65.389,
			65.499,
			65.516,
			65.577,
			65.61,
			65.64,
			65.716,
			65.764,
			65.826,
			65.949,
			66.011,
			66.073,
			66.134,
			66.197,
			66.26,
			66.321,
			66.385,
			66.477,
			66.506,
			66.585,
			66.63,
			66.694,
			66.756,
			66.803,
			66.818,
			66.88,
			66.911,
			66.941,
			67.003,
			67.02,
			67.067,
			67.129,
			67.19,
			67.252,
			67.313,
			67.345,
			67.376,
			67.453,
			67.5,
			67.561,
			67.623,
			67.669,
			67.685,
			67.746,
			67.779,
			67.81,
			67.872,
			67.933,
			67.994,
			68.057,
			68.12,
			68.213,
			68.244,
			68.32,
			68.367,
			68.428,
			68.539,
			68.616,
			68.647,
			68.678,
			68.755,
			68.864,
			68.927,
			68.987,
			69.049,
			69.081,
			69.111,
			69.172,
			69.237,
			69.298,
			69.36,
			69.408,
			69.484,
			69.546,
			69.623,
			69.671,
			69.731,
			69.795,
			69.856,
			69.917,
			69.981,
			70.042,
			70.103,
			70.17,
			70.228,
			70.29,
			70.353,
			70.384,
			70.415,
			70.493,
			70.539,
			70.604,
			70.662,
			70.725,
			70.818,
			70.847,
			70.911,
			70.973,
			71.036,
			71.097,
			71.16,
			71.22,
			71.283,
			71.36,
			71.407,
			71.467,
			71.529,
			71.578,
			71.593,
			71.653,
			71.718,
			71.777,
			71.795,
			71.839,
			71.901,
			71.965,
			72.026,
			72.12,
			72.151,
			72.232,
			72.276,
			72.338,
			72.4,
			72.46,
			72.523,
			72.553,
			72.585,
			72.663,
			72.706,
			72.77,
			72.832,
			72.895,
			72.956,
			72.988,
			73.018,
			73.097,
			73.142,
			73.204,
			73.265,
			73.312,
			73.422,
			73.453,
			73.531,
			73.578,
			73.637,
			73.701,
			73.763,
			73.822,
			73.855,
			73.886,
			73.963,
			74.01,
			74.072,
			74.134,
			74.18,
			74.195,
			74.26,
			74.295,
			74.32,
			74.397,
			74.444,
			74.506,
			74.57,
			74.631,
			74.694,
			74.723,
			74.755,
			74.818,
			74.878,
			74.941,
			75.004,
			75.064,
			75.127,
			75.189,
			75.268,
			75.314,
			75.374,
			75.436,
			75.5,
			75.592,
			75.623,
			75.685,
			75.748,
			75.807,
			75.871,
			75.933,
			76.027,
			76.056,
			76.134,
			76.182,
			76.244,
			76.356,
			76.367,
			76.429,
			76.461,
			76.489,
			76.553,
			76.614,
			76.677,
			76.74,
			76.801,
			76.862,
			76.926,
			77.004,
			77.048,
			77.112,
			77.173,
			77.22,
			77.234,
			77.328,
			77.36,
			77.437,
			77.546,
			77.607,
			77.669,
			77.761,
			77.794,
			77.855,
			77.917,
			77.98,
			78.088,
			78.165,
			78.198,
			78.229,
			78.304,
			78.352,
			78.419,
			78.476,
			78.537,
			78.6,
			78.632,
			78.663,
			78.739,
			78.786,
			78.849,
			78.909,
			78.973,
			79.035,
			79.097,
			79.174,
			79.22,
			79.281,
			79.344,
			79.389,
			79.467,
			79.53,
			79.607,
			79.653,
			79.716,
			79.778,
			79.838,
			79.901,
			79.963,
			80.043,
			80.087,
			80.151,
			80.212,
			80.273,
			80.336,
			80.366,
			80.398,
			80.482,
			80.523,
			80.583,
			80.646,
			80.707,
			80.801,
			80.831,
			80.894,
			80.956,
			81.018,
			81.127,
			81.141,
			81.205,
			81.236,
			81.267,
			81.344,
			81.389,
			81.453,
			81.515,
			81.577,
			81.669,
			81.7,
			81.778,
			81.823,
			81.886,
			81.949,
			81.994,
			82.011,
			82.073,
			82.135,
			82.21,
			82.258,
			82.32,
			82.381,
			82.445,
			82.507,
			82.569,
			82.629,
			82.647,
			82.693,
			82.755,
			82.815,
			82.878,
			82.941,
			82.97,
			83.003,
			83.081,
			83.127,
			83.187,
			83.251,
			83.313,
			83.436,
			83.497,
			83.515,
			83.56,
			83.621,
			83.683,
			83.748,
			83.839,
			83.87,
			83.948,
			83.994,
			84.056,
			84.12,
			84.164,
			84.181,
			84.273,
			84.306,
			84.383,
			84.429,
			84.489,
			84.553,
			84.613,
			84.678,
			84.739,
			84.799,
			84.862,
			84.923,
			84.987,
			85.033,
			85.11,
			85.142,
			85.173,
			85.251,
			85.298,
			85.36,
			85.419,
			85.481,
			85.545,
			85.577,
			85.607,
			85.668,
			85.685,
			85.729,
			85.796,
			85.853,
			85.917,
			85.98,
			86.01,
			86.039,
			86.118,
			86.166,
			86.228,
			86.289,
			86.334,
			86.412,
			86.444,
			86.476,
			86.553,
			86.598,
			86.667,
			86.723,
			86.786,
			86.878,
			86.91,
			86.987,
			87.033,
			87.094,
			87.157,
			87.203,
			87.218,
			87.281,
			87.313,
			87.406,
			87.42,
			87.468,
			87.529,
			87.589,
			87.652,
			87.716,
			87.747,
			87.777,
			87.84,
			87.901,
			87.965,
			88.072,
			88.087,
			88.15,
			88.18,
			88.212,
			88.29,
			88.336,
			88.398,
			88.46,
			88.522,
			88.582,
			88.646,
			88.705,
			88.727,
			88.77,
			88.832,
			88.894,
			88.939,
			88.956,
			89.017,
			89.05,
			89.079,
			89.156,
			89.202,
			89.328,
			89.39,
			89.452,
			89.483,
			89.514,
			89.576,
			89.589,
			89.636,
			89.701,
			89.763,
			89.823,
			89.886,
			89.918,
			89.946,
			90.026,
			90.072,
			90.135,
			90.243,
			90.32,
			90.352,
			90.381,
			90.444,
			90.459,
			90.505,
			90.569,
			90.628,
			90.689,
			90.754,
			90.816,
			90.894,
			90.939,
			91.001,
			91.063,
			91.124,
			91.189,
			91.249,
			91.312,
			91.375,
			91.435,
			91.497,
			91.56,
			91.623,
			91.652,
			91.685,
			91.762,
			91.806,
			91.869,
			91.978,
			91.995,
			92.088,
			92.119,
			92.178,
			92.197,
			92.242,
			92.306,
			92.366,
			92.428,
			92.49,
			92.552,
			92.63,
			92.677,
			92.74,
			92.801,
			92.862,
			92.985,
			93.049,
			93.062,
			93.111,
			93.173,
			93.235,
			93.296,
			93.356,
			93.421,
			93.499,
			93.544,
			93.605
		]
	],
	'1853_2016_7982': [
		[
			73.738
		],
		[
			0
		],
		[
			0,
			2.47,
			6.827,
			9.729,
			14.092,
			15.531,
			16.965,
			21.239,
			24.033,
			28.214,
			30.973,
			33.735,
			35.118,
			37.889,
			39.274,
			42.07,
			44.846,
			46.235,
			47.625,
			51.779,
			55.975,
			58.782,
			61.591,
			64.411,
			65.828,
			70.098,
			72.962,
			75.847,
			80.2,
			83.144,
			87.542,
			89.007,
			90.477,
			94.853,
			97.777,
			102.134,
			105.047,
			109.364,
			112.227,
			113.656,
			116.503,
			119.344,
			120.758,
			123.583,
			126.4,
			130.62,
			132.025,
			133.431,
			137.647,
			139.052,
			140.456,
			143.265,
			144.67,
			147.481,
			150.297,
			154.525,
			157.361,
			161.635,
			163.067,
			164.5,
			168.819,
			171.71,
			176.06,
			178.969,
			181.882,
			183.341,
			186.262,
			189.187,
			190.651,
			195.044,
			197.97,
			200.887,
			205.226,
			208.086,
			212.313,
			213.703,
			215.084,
			219.184,
			221.893,
			225.942,
			228.643,
			231.348,
			235.426,
			236.786,
			239.518,
			243.614,
			244.979,
			249.073,
			251.792,
			254.513,
			255.863,
			258.55,
			261.213,
			262.536,
			265.169,
			266.477,
			269.094,
			271.704,
			275.623,
			278.232,
			282.14,
			283.439,
			284.737,
			288.605,
			291.164,
			294.948,
			297.469,
			299.996,
			301.244,
			303.732,
			304.975,
			307.454,
			311.144,
			313.592,
			316.011,
			319.66,
			320.879,
			322.117,
			324.564,
			327.04,
			328.278,
			330.766,
			332.013,
			334.512,
			337.019,
			340.761,
			343.242,
			346.886,
			348.095,
			349.296,
			352.787,
			355.055,
			358.364,
			360.513,
			362.606,
			363.639,
			365.672,
			366.676,
			368.661,
			371.542,
			373.408,
			375.233,
			377.895,
			378.76,
			379.617,
			382.119,
			382.939,
			383.753,
			385.36,
			386.153,
			387.729,
			389.3,
			391.68,
			393.269,
			395.666,
			396.481,
			397.294,
			399.73,
			400.553,
			401.387,
			403.879,
			405.541,
			408.061,
			409.737,
			410.578,
			412.268,
			413.957,
			414.802,
			416.508,
			418.218,
			420.787,
			422.519,
			425.115,
			425.986,
			426.859,
			429.477,
			431.203,
			433.754,
			435.456,
			437.124,
			437.957,
			439.622,
			440.455,
			441.279,
			444.565,
			445.387,
			447.825,
			449.446,
			451.066,
			451.871,
			453.477,
			455.083,
			455.886,
			457.483,
			458.28,
			459.871,
			461.457,
			463.805,
			465.366,
			467.669,
			468.429,
			469.188,
			471.461,
			472.949,
			475.177,
			476.666,
			478.164,
			478.913,
			480.411,
			481.161,
			482.688,
			484.222,
			484.99,
			486.529,
			488.088,
			490.433,
			491.217,
			492.003,
			493.581,
			495.164,
			495.958,
			497.551,
			498.35,
			499.956,
			501.571,
			504.017,
			505.663,
			508.156,
			508.994,
			509.835,
			512.362,
			514.069,
			516.653,
			518.387,
			520.132,
			521.008,
			522.77,
			523.653,
			525.428,
			528.116,
			529.928,
			531.752,
			534.522,
			535.452,
			536.386,
			539.21,
			540.159,
			541.111,
			543.986,
			545.919,
			547.864,
			550.807,
			552.783,
			555.768,
			556.769,
			557.772,
			560.791,
			562.817,
			565.887,
			567.949,
			570.021,
			571.061,
			573.146,
			574.188,
			576.273,
			578.367,
			579.417,
			581.527,
			583.649,
			586.851,
			588.998,
			592.237,
			593.321,
			594.408,
			597.682,
			599.877,
			603.187,
			605.405,
			607.633,
			610.993,
			612.118,
			613.248,
			617.799,
			618.949,
			622.425,
			624.781,
			627.152,
			628.358,
			630.777,
			633.219,
			634.448,
			636.914,
			638.147,
			640.619,
			643.079,
			646.753,
			649.186,
			652.79,
			653.976,
			655.159,
			658.672,
			660.975,
			664.384,
			666.618,
			669.927,
			672.091,
			673.169,
			675.307,
			677.424,
			678.483,
			680.585,
			682.676,
			685.812,
			686.856,
			687.904,
			690.01,
			692.118,
			693.186,
			695.335,
			696.41,
			698.589,
			700.797,
			704.162,
			706.441,
			709.905,
			711.064,
			712.221,
			715.72,
			718.047,
			721.517,
			723.81,
			727.223,
			729.477,
			730.597,
			732.824,
			734.99,
			736.051,
			738.197,
			740.31,
			743.434,
			745.494,
			748.563,
			749.578,
			750.59,
			752.594,
			753.599,
			755.597,
			757.58,
			760.503,
			762.41,
			765.196,
			766.105,
			767.006,
			769.668,
			771.393,
			773.93,
			775.593,
			777.244,
			778.067,
			779.709,
			781.347,
			782.166,
			783.809,
			784.633,
			786.28,
			787.928,
			790.439,
			792.116,
			794.675,
			795.54,
			796.404,
			799.021,
			800.802,
			803.476,
			805.269,
			807.065,
			809.753,
			810.646,
			811.54,
			814.215,
			815.982,
			818.637,
			820.386,
			822.14,
			823.02,
			824.769,
			826.521,
			827.401,
			829.161,
			830.041,
			831.809,
			833.59,
			836.26,
			838.056,
			840.757,
			841.662,
			842.57,
			845.295,
			847.115,
			849.843,
			851.656,
			853.467,
			854.367,
			856.161,
			857.042,
			858.828,
			860.606,
			861.494,
			863.27,
			865.067,
			866.854,
			868.648,
			869.554,
			871.377,
			873.211,
			874.137,
			875.995,
			876.937,
			878.83,
			880.769,
			883.681,
			885.65,
			888.63,
			889.63,
			890.634,
			893.65,
			895.683,
			898.753,
			900.812,
			902.883,
			903.924,
			906.008,
			907.056,
			909.157,
			911.253,
			912.304,
			914.415,
			916.536,
			919.742,
			920.816,
			921.893,
			924.057,
			926.232,
			927.324,
			930.617,
			932.827,
			935.049,
			938.406,
			940.658,
			944.059,
			945.198,
			946.34,
			949.781,
			952.09,
			955.573,
			957.909,
			961.433,
			963.796,
			967.36,
			970.947,
			973.35,
			975.763,
			979.399,
			981.835,
			985.508,
			986.738,
			987.97,
			991.682,
			994.191,
			997.921,
			1000.434,
			1002.953,
			1006.742,
			1008.004,
			1009.263,
			1013.015,
			1014.253,
			1015.482,
			1019.116,
			1021.492,
			1023.83,
			1024.986,
			1027.273,
			1029.523,
			1030.639,
			1032.844,
			1033.934,
			1036.091,
			1038.214,
			1041.334,
			1043.369,
			1046.352,
			1047.329,
			1048.315,
			1051.182,
			1053.062,
			1055.845,
			1057.676,
			1060.408,
			1062.212,
			1063.113,
			1064.918,
			1066.714,
			1067.612,
			1069.409,
			1071.226,
			1073.051,
			1075.82,
			1077.712,
			1079.605,
			1080.575,
			1082.549,
			1083.537,
			1085.536,
			1087.617,
			1090.773,
			1092.969,
			1096.287,
			1097.406,
			1098.523,
			1101.817,
			1103.972,
			1107.084,
			1109.109,
			1111.093,
			1112.056,
			1113.982,
			1114.945,
			1116.821,
			1118.687,
			1119.619,
			1121.467,
			1123.306,
			1126.071,
			1126.997,
			1127.923,
			1130.719,
			1131.66,
			1132.604,
			1134.499,
			1135.451,
			1137.352,
			1140.248,
			1142.195,
			1144.169,
			1147.128,
			1148.12,
			1149.116,
			1152.103,
			1154.118,
			1157.161,
			1159.203,
			1161.256,
			1162.287,
			1164.358,
			1165.397,
			1167.485,
			1169.584,
			1170.637,
			1172.753,
			1174.881,
			1178.093,
			1179.169,
			1180.248,
			1183.501,
			1184.591,
			1185.683,
			1188.977,
			1191.187,
			1194.521,
			1196.758,
			1199.005,
			1202.395,
			1203.531,
			1204.669,
			1208.101,
			1209.25,
			1210.402,
			1213.873,
			1216.2,
			1218.537,
			1219.71,
			1222.062,
			1224.426,
			1225.612,
			1227.991,
			1229.184,
			1231.578,
			1233.982,
			1237.605,
			1240.032,
			1243.689,
			1244.913,
			1246.139,
			1249.827,
			1252.296,
			1256.014,
			1258.499,
			1260.994,
			1262.243,
			1264.748,
			1266.004,
			1268.523,
			1271.05,
			1272.316,
			1273.585,
			1277.404,
			1279.959,
			1282.519,
			1283.8,
			1286.356,
			1288.896,
			1290.157,
			1292.657,
			1293.894,
			1296.34,
			1298.754,
			1302.308,
			1304.636,
			1308.063,
			1309.188,
			1310.303,
			1313.592,
			1315.738,
			1318.888,
			1320.941,
			1322.957,
			1323.951,
			1325.911,
			1327.834,
			1328.781,
			1331.57,
			1333.386,
			1335.168,
			1337.772,
			1338.62,
			1339.459,
			1341.92,
			1342.719,
			1343.51,
			1345.063,
			1345.826,
			1347.324,
			1348.784,
			1350.918,
			1352.293,
			1354.311,
			1354.976,
			1355.637,
			1357.602,
			1358.891,
			1360.813,
			1362.085,
			1363.949,
			1365.187,
			1365.805,
			1367.038,
			1368.245,
			1368.846,
			1370.046,
			1371.246,
			1373.033,
			1374.214,
			1375.987,
			1376.579,
			1377.184,
			1379.062,
			1380.309,
			1382.201,
			1383.564,
			1384.925,
			1386.967,
			1387.729,
			1388.499,
			1390.806,
			1391.574,
			1392.371,
			1394.909,
			1396.6,
			1398.363,
			1399.26,
			1401.053,
			1402.891,
			1403.842,
			1405.744,
			1406.696,
			1408.64,
			1410.593,
			1413.479,
			1415.364,
			1418.088,
			1418.932,
			1419.775,
			1422.287,
			1423.683,
			1425.767,
			1427.152,
			1428.392,
			1429.005,
			1430.229,
			1430.841,
			1432.063,
			1433.922,
			1435.156,
			1436.388,
			1437.642,
			1438.951,
			1439.604,
			1440.911,
			1442.221,
			1442.907,
			1444.289,
			1444.98,
			1446.366,
			1447.77,
			1448.488,
			1449.927,
			1451.371,
			1453.546,
			1454.282,
			1455.022,
			1457.27,
			1458.789,
			1461.101,
			1462.664,
			1464.258,
			1465.054,
			1466.66,
			1467.47,
			1469.112,
			1470.77,
			1471.605,
			1473.299,
			1475.021,
			1476.757,
			1479.405,
			1482.09,
			1482.992,
			1483.896,
			1486.618,
			1488.456,
			1490.313,
			1493.122,
			1495.005,
			1497.855,
			1498.803,
			1499.754,
			1502.629,
			1504.537,
			1507.424,
			1509.342,
			1511.271,
			1512.241,
			1514.175,
			1515.145,
			1517.095,
			1520.035,
			1522.008,
			1523.994,
			1526.993,
			1528,
			1529.012,
			1532.066,
			1533.092,
			1534.123,
			1537.234,
			1539.331,
			1541.443,
			1544.641,
			1546.79,
			1550.024,
			1551.105,
			1552.188,
			1556.546,
			1557.648,
			1560.976,
			1563.208,
			1566.572,
			1568.823,
			1571.078,
			1572.209,
			1574.468,
			1575.597,
			1577.865,
			1580.122,
			1583.517,
			1585.766,
			1589.15,
			1590.276,
			1591.404,
			1594.812,
			1597.102,
			1600.591,
			1602.94,
			1605.323,
			1606.54,
			1608.943,
			1610.148,
			1612.565,
			1614.983,
			1616.193,
			1617.405,
			1621.052,
			1623.496,
			1625.971,
			1627.213,
			1629.721,
			1632.257,
			1633.529,
			1636.103,
			1637.393,
			1639.985,
			1642.589,
			1646.51,
			1649.129,
			1653.02,
			1654.315,
			1655.623,
			1659.444,
			1661.963,
			1665.674,
			1668.097,
			1670.475,
			1671.649,
			1673.97,
			1675.113,
			1677.373,
			1680.69,
			1682.854,
			1685.002,
			1688.146,
			1689.185,
			1690.224,
			1693.323,
			1694.357,
			1695.394,
			1698.495,
			1700.573,
			1702.649,
			1705.771,
			1707.852,
			1710.977,
			1712.019,
			1713.062,
			1716.203,
			1718.306,
			1721.485,
			1723.615,
			1725.767,
			1726.845,
			1729.008,
			1730.098,
			1732.281,
			1734.472,
			1735.572,
			1737.777,
			1739.988,
			1743.315,
			1745.541,
			1748.893,
			1750.015,
			1751.14,
			1754.527,
			1756.807,
			1759.078,
			1762.545,
			1764.847,
			1768.291,
			1769.439,
			1770.576,
			1773.975,
			1775.108,
			1776.237,
			1779.657,
			1781.896,
			1785.294,
			1787.557,
			1788.693,
			1790.967,
			1793.248,
			1794.391,
			1796.686,
			1799.003,
			1802.459,
			1804.753,
			1808.203,
			1809.35,
			1810.5,
			1813.945,
			1816.242,
			1819.685,
			1821.986,
			1825.439,
			1827.753,
			1828.908,
			1831.237,
			1833.573,
			1834.739,
			1835.913,
			1839.429,
			1841.765,
			1844.1,
			1845.267,
			1847.597,
			1849.93,
			1851.097,
			1853.434,
			1854.603,
			1856.941,
			1859.283,
			1862.799,
			1865.15,
			1868.678,
			1869.858,
			1871.036,
			1874.58,
			1876.949,
			1880.518,
			1882.9,
			1885.292,
			1886.492,
			1888.898,
			1890.105,
			1892.534,
			1894.972,
			1896.197,
			1898.656,
			1901.125,
			1904.845,
			1906.087,
			1907.33,
			1909.802,
			1912.291,
			1913.538,
			1917.272,
			1919.762,
			1922.244,
			1925.97,
			1928.453,
			1932.18,
			1933.424,
			1934.669,
			1938.417,
			1940.926,
			1944.731,
			1947.268,
			1949.794,
			1951.072,
			1953.636,
			1954.922,
			1957.504,
			1960.095,
			1961.395,
			1964.002,
			1966.61,
			1970.539,
			1973.17,
			1977.133,
			1978.46,
			1979.788,
			1982.452,
			1983.787,
			1986.463,
			1990.492,
			1993.188,
			1995.891,
			1999.961,
			2001.322,
			2002.683,
			2006.804,
			2009.521,
			2013.647,
			2016.409,
			2019.183,
			2020.573,
			2023.367,
			2026.172,
			2027.586,
			2030.445,
			2031.871,
			2034.742,
			2037.627,
			2041.979,
			2044.89,
			2049.259,
			2050.717,
			2052.173,
			2056.541,
			2059.449,
			2063.819,
			2066.738,
			2069.659,
			2071.121,
			2074.047,
			2075.511,
			2078.443,
			2081.379,
			2082.85,
			2084.322,
			2088.746,
			2091.678,
			2096.125,
			2099.098,
			2102.077,
			2103.57,
			2106.561,
			2108.06,
			2111.064,
			2114.08,
			2118.623,
			2121.664,
			2126.238,
			2127.766,
			2129.295,
			2133.914,
			2136.981,
			2141.587,
			2144.661,
			2147.741,
			2149.283,
			2152.37,
			2153.916,
			2157.011,
			2160.111,
			2161.662,
			2164.768,
			2167.877,
			2170.99,
			2174.107,
			2175.667,
			2178.793,
			2181.924,
			2183.493,
			2186.634,
			2188.207,
			2191.359,
			2194.519,
			2199.269,
			2202.442,
			2207.214,
			2208.81,
			2210.407,
			2215.211,
			2218.398,
			2223.261,
			2226.474,
			2229.718,
			2231.34,
			2234.582,
			2236.201,
			2239.433,
			2244.25,
			2247.426,
			2250.568,
			2255.208,
			2258.252,
			2262.735,
			2264.207,
			2265.667,
			2269.98,
			2272.799,
			2275.571,
			2279.655,
			2282.328,
			2286.268,
			2287.563,
			2288.85,
			2292.655,
			2295.146,
			2298.816,
			2301.221,
			2304.792,
			2307.129,
			2309.443,
			2310.594,
			2312.885,
			2314.027,
			2316.312,
			2318.591,
			2322.022,
			2324.311,
			2327.772,
			2328.925,
			2330.095,
			2333.603,
			2335.966,
			2339.52,
			2341.898,
			2344.274,
			2345.462,
			2347.837,
			2349.025,
			2350.208,
			2353.766,
			2354.955,
			2356.145,
			2359.733,
			2362.133,
			2365.746,
			2368.16,
			2370.581,
			2371.793,
			2374.22,
			2375.434,
			2377.864,
			2380.292,
			2383.928,
			2386.35,
			2389.982,
			2391.191,
			2392.4,
			2396.019,
			2398.425,
			2402.019,
			2404.415,
			2407.99,
			2410.378,
			2411.57,
			2413.958,
			2416.351,
			2417.549,
			2419.952,
			2422.362,
			2425.994,
			2427.209,
			2428.426,
			2430.866,
			2433.312,
			2434.539,
			2436.997,
			2438.229,
			2440.7,
			2443.178,
			2446.889,
			2449.386,
			2453.167,
			2454.424,
			2455.684,
			2459.473,
			2461.989,
			2465.81,
			2468.368,
			2470.934,
			2472.22,
			2474.798,
			2476.09,
			2478.68,
			2481.274,
			2482.572,
			2485.173,
			2487.783,
			2491.714,
			2493.03,
			2494.347,
			2498.311,
			2499.636,
			2500.963,
			2504.956,
			2507.627,
			2510.306,
			2514.337,
			2517.034,
			2521.094,
			2522.45,
			2523.809,
			2527.896,
			2529.262,
			2530.63,
			2534.747,
			2537.501,
			2540.262,
			2541.645,
			2544.414,
			2547.19,
			2548.579,
			2551.364,
			2552.759,
			2555.554,
			2558.355,
			2562.569,
			2565.386,
			2569.624,
			2571.04,
			2572.457,
			2576.718,
			2579.568,
			2583.854,
			2586.719,
			2589.592,
			2591.031,
			2593.916,
			2595.361,
			2596.808,
			2601.163,
			2602.618,
			2604.075,
			2608.456,
			2611.384,
			2614.317,
			2615.81,
			2618.727,
			2621.672,
			2623.147,
			2626.101,
			2627.578,
			2630.53,
			2633.471,
			2637.839,
			2640.712,
			2644.955,
			2646.352,
			2647.74,
			2651.849,
			2654.543,
			2658.513,
			2661.112,
			2663.675,
			2664.941,
			2667.448,
			2668.686,
			2671.136,
			2674.742,
			2675.927,
			2679.43,
			2681.72,
			2685.085,
			2687.282,
			2689.441,
			2690.507,
			2692.61,
			2693.648,
			2695.696,
			2697.707,
			2700.653,
			2702.571,
			2705.378,
			2706.296,
			2707.205,
			2709.882,
			2711.624,
			2714.171,
			2715.822,
			2717.437,
			2718.23,
			2719.789,
			2720.554,
			2722.058,
			2723.525,
			2724.243,
			2725.651,
			2727.025,
			2729.021,
			2729.667,
			2730.305,
			2731.556,
			2732.782,
			2733.376,
			2735.101,
			2736.206,
			2737.28,
			2738.842,
			2739.852,
			2741.323,
			2741.805,
			2742.282,
			2743.698,
			2744.169,
			2744.637,
			2746.027,
			2746.949,
			2747.87,
			2748.331,
			2749.264,
			2750.201,
			2750.668,
			2751.602,
			2752.069,
			2753.004,
			2753.953,
			2755.42,
			2756.398,
			2757.873,
			2758.367,
			2758.87,
			2760.454,
			2761.513,
			2762.578,
			2764.224,
			2765.404,
			2765.996,
			2767.185,
			2767.782,
			2768.381,
			2770.346,
			2771.02,
			2771.694,
			2773.724,
			2775.19,
			2776.66,
			2777.394,
			2778.862,
			2780.381,
			2781.136,
			2782.64,
			2783.389,
			2784.866,
			2786.32,
			2788.488,
			2789.925,
			2792.065,
			2792.775,
			2793.484,
			2795.545,
			2796.894,
			2798.909,
			2800.183,
			2801.433,
			2802.056,
			2803.298,
			2803.919,
			2805.132,
			2806.328,
			2806.924,
			2808.113,
			2809.3,
			2810.476,
			2811.648,
			2812.235,
			2813.413,
			2814.597,
			2815.186,
			2816.368,
			2816.96,
			2818.151,
			2819.349,
			2821.119,
			2822.305,
			2824.101,
			2824.692,
			2825.274,
			2827.03,
			2828.213,
			2829.993,
			2831.164,
			2832.347,
			2832.944,
			2834.148,
			2834.753,
			2835.969,
			2837.197,
			2837.817,
			2839.069,
			2840.345,
			2842.294,
			2843.613,
			2845.636,
			2846.323,
			2847.014,
			2849.111,
			2850.541,
			2851.994,
			2854.203,
			2855.702,
			2857.971,
			2858.74,
			2859.512,
			2861.856,
			2862.645,
			2863.438,
			2865.839,
			2867.458,
			2869.093,
			2869.915,
			2871.573,
			2872.408,
			2874.087,
			2875.78,
			2876.627,
			2878.321,
			2880.022,
			2882.595,
			2883.459,
			2884.327,
			2886.947,
			2887.826,
			2888.708,
			2891.374,
			2893.165,
			2895.875,
			2897.697,
			2899.531,
			2900.452,
			2902.306,
			2903.237,
			2904.171,
			2907.941,
			2908.891,
			2911.761,
			2913.69,
			2916.606,
			2918.566,
			2920.538,
			2921.529,
			2923.519,
			2924.519,
			2926.527,
			2928.547,
			2931.599,
			2933.648,
			2936.742,
			2937.779,
			2938.819,
			2941.954,
			2944.048,
			2947.193,
			2949.303,
			2951.424,
			2952.489,
			2954.626,
			2955.698,
			2957.852,
			2961.102,
			2963.283,
			2965.473,
			2967.674,
			2969.886,
			2970.996,
			2973.223,
			2975.46,
			2976.583,
			2978.834,
			2979.964,
			2982.231,
			2984.508,
			2987.944,
			2990.248,
			2993.725,
			2994.889,
			2996.057,
			2999.582,
			3000.764,
			3001.947,
			3005.535,
			3007.966,
			3010.399,
			3011.618,
			3014.074,
			3015.307,
			3017.78,
			3021.512,
			3024.019,
			3026.537,
			3030.334,
			3031.604,
			3032.876,
			3036.701,
			3037.979,
			3039.259,
			3043.109,
			3045.685,
			3048.269,
			3052.161,
			3054.763,
			3058.681,
			3059.99,
			3061.301,
			3065.213,
			3067.841,
			3071.774,
			3074.396,
			3077.016,
			3078.327,
			3080.94,
			3083.549,
			3084.849,
			3088.754,
			3091.36,
			3093.972,
			3097.902,
			3100.532,
			3104.494,
			3105.819,
			3107.146,
			3111.14,
			3113.813,
			3116.496,
			3120.534,
			3123.235,
			3127.299,
			3128.658,
			3130.018,
			3134.11,
			3135.478,
			3136.847,
			3140.966,
			3143.719,
			3147.863,
			3150.634,
			3153.41,
			3154.801,
			3157.587,
			3158.983,
			3161.782,
			3164.589,
			3168.814,
			3171.639,
			3175.889,
			3177.308,
			3178.729,
			3183.001,
			3185.855,
			3190.146,
			3193.014,
			3195.889,
			3197.329,
			3200.213,
			3201.657,
			3203.103,
			3207.445,
			3208.634
		],
		[
			0,
			0.051,
			0.113,
			0.159,
			0.236,
			0.268,
			0.297,
			0.377,
			0.424,
			0.484,
			0.548,
			0.593,
			0.609,
			0.671,
			0.702,
			0.734,
			0.796,
			0.81,
			0.856,
			0.918,
			0.982,
			1.043,
			1.105,
			1.137,
			1.168,
			1.244,
			1.291,
			1.354,
			1.417,
			1.461,
			1.54,
			1.57,
			1.6,
			1.68,
			1.725,
			1.792,
			1.851,
			1.91,
			1.974,
			2.005,
			2.035,
			2.097,
			2.113,
			2.16,
			2.221,
			2.284,
			2.329,
			2.346,
			2.408,
			2.438,
			2.468,
			2.53,
			2.545,
			2.595,
			2.654,
			2.719,
			2.762,
			2.84,
			2.872,
			2.905,
			2.965,
			3.028,
			3.088,
			3.15,
			3.196,
			3.214,
			3.277,
			3.308,
			3.338,
			3.413,
			3.462,
			3.523,
			3.585,
			3.632,
			3.71,
			3.74,
			3.771,
			3.834,
			3.896,
			3.959,
			4.019,
			4.067,
			4.143,
			4.173,
			4.207,
			4.284,
			4.329,
			4.394,
			4.453,
			4.502,
			4.516,
			4.576,
			4.609,
			4.64,
			4.701,
			4.717,
			4.763,
			4.827,
			4.888,
			4.95,
			5.013,
			5.044,
			5.073,
			5.135,
			5.196,
			5.26,
			5.323,
			5.369,
			5.384,
			5.447,
			5.476,
			5.509,
			5.586,
			5.633,
			5.694,
			5.757,
			5.803,
			5.817,
			5.88,
			5.917,
			5.941,
			6.005,
			6.019,
			6.067,
			6.129,
			6.19,
			6.238,
			6.315,
			6.346,
			6.377,
			6.453,
			6.5,
			6.562,
			6.625,
			6.67,
			6.687,
			6.749,
			6.78,
			6.809,
			6.887,
			6.935,
			6.996,
			7.059,
			7.104,
			7.12,
			7.182,
			7.211,
			7.243,
			7.306,
			7.322,
			7.369,
			7.429,
			7.493,
			7.555,
			7.617,
			7.649,
			7.679,
			7.741,
			7.757,
			7.802,
			7.865,
			7.927,
			7.987,
			8.052,
			8.08,
			8.113,
			8.174,
			8.191,
			8.237,
			8.3,
			8.359,
			8.408,
			8.485,
			8.515,
			8.546,
			8.608,
			8.67,
			8.733,
			8.794,
			8.841,
			8.857,
			8.917,
			8.95,
			8.979,
			9.059,
			9.104,
			9.168,
			9.23,
			9.275,
			9.29,
			9.351,
			9.383,
			9.413,
			9.475,
			9.493,
			9.54,
			9.602,
			9.661,
			9.709,
			9.787,
			9.818,
			9.849,
			9.909,
			9.971,
			10.034,
			10.097,
			10.143,
			10.16,
			10.221,
			10.252,
			10.283,
			10.344,
			10.359,
			10.407,
			10.469,
			10.532,
			10.576,
			10.592,
			10.655,
			10.685,
			10.718,
			10.778,
			10.794,
			10.841,
			10.904,
			10.966,
			11.012,
			11.09,
			11.121,
			11.15,
			11.213,
			11.274,
			11.338,
			11.4,
			11.447,
			11.46,
			11.524,
			11.555,
			11.585,
			11.664,
			11.709,
			11.771,
			11.834,
			11.88,
			11.896,
			11.957,
			11.988,
			12.018,
			12.101,
			12.144,
			12.204,
			12.267,
			12.328,
			12.39,
			12.421,
			12.452,
			12.516,
			12.576,
			12.64,
			12.701,
			12.746,
			12.762,
			12.827,
			12.856,
			12.888,
			12.949,
			12.965,
			13.012,
			13.073,
			13.134,
			13.183,
			13.259,
			13.29,
			13.321,
			13.4,
			13.447,
			13.509,
			13.571,
			13.63,
			13.695,
			13.724,
			13.754,
			13.833,
			13.88,
			13.942,
			14.005,
			14.05,
			14.064,
			14.127,
			14.163,
			14.189,
			14.25,
			14.267,
			14.312,
			14.376,
			14.437,
			14.485,
			14.562,
			14.593,
			14.624,
			14.684,
			14.747,
			14.81,
			14.873,
			14.933,
			14.996,
			15.026,
			15.058,
			15.121,
			15.134,
			15.183,
			15.244,
			15.307,
			15.353,
			15.367,
			15.429,
			15.459,
			15.493,
			15.553,
			15.569,
			15.616,
			15.679,
			15.74,
			15.786,
			15.865,
			15.894,
			15.924,
			16.005,
			16.051,
			16.112,
			16.174,
			16.237,
			16.297,
			16.33,
			16.36,
			16.421,
			16.438,
			16.485,
			16.546,
			16.609,
			16.671,
			16.733,
			16.763,
			16.793,
			16.856,
			16.872,
			16.917,
			16.98,
			17.041,
			17.105,
			17.166,
			17.196,
			17.227,
			17.291,
			17.351,
			17.415,
			17.475,
			17.523,
			17.539,
			17.6,
			17.632,
			17.662,
			17.723,
			17.738,
			17.787,
			17.849,
			17.909,
			17.957,
			18.034,
			18.065,
			18.095,
			18.159,
			18.221,
			18.287,
			18.345,
			18.391,
			18.467,
			18.499,
			18.529,
			18.592,
			18.654,
			18.718,
			18.779,
			18.823,
			18.841,
			18.902,
			18.933,
			18.965,
			19.028,
			19.042,
			19.09,
			19.152,
			19.214,
			19.259,
			19.338,
			19.368,
			19.399,
			19.461,
			19.523,
			19.585,
			19.647,
			19.693,
			19.708,
			19.772,
			19.802,
			19.832,
			19.894,
			19.911,
			19.957,
			20.018,
			20.079,
			20.127,
			20.143,
			20.204,
			20.235,
			20.267,
			20.33,
			20.351,
			20.391,
			20.454,
			20.516,
			20.562,
			20.639,
			20.671,
			20.701,
			20.764,
			20.824,
			20.887,
			20.949,
			20.996,
			21.009,
			21.071,
			21.104,
			21.135,
			21.196,
			21.212,
			21.259,
			21.319,
			21.384,
			21.43,
			21.444,
			21.506,
			21.536,
			21.568,
			21.647,
			21.693,
			21.754,
			21.817,
			21.862,
			21.942,
			21.973,
			22.004,
			22.065,
			22.126,
			22.19,
			22.251,
			22.313,
			22.376,
			22.438,
			22.516,
			22.56,
			22.624,
			22.684,
			22.732,
			22.809,
			22.841,
			22.87,
			22.934,
			22.995,
			23.059,
			23.12,
			23.166,
			23.243,
			23.275,
			23.304,
			23.367,
			23.382,
			23.429,
			23.49,
			23.554,
			23.6,
			23.616,
			23.677,
			23.709,
			23.739,
			23.801,
			23.818,
			23.862,
			23.925,
			23.987,
			24.034,
			24.112,
			24.143,
			24.172,
			24.234,
			24.296,
			24.36,
			24.42,
			24.484,
			24.546,
			24.576,
			24.608,
			24.669,
			24.684,
			24.733,
			24.794,
			24.854,
			24.918,
			24.98,
			25.009,
			25.041,
			25.103,
			25.119,
			25.166,
			25.227,
			25.289,
			25.351,
			25.414,
			25.445,
			25.477,
			25.538,
			25.601,
			25.662,
			25.722,
			25.77,
			25.785,
			25.847,
			25.88,
			25.909,
			25.971,
			25.986,
			26.033,
			26.101,
			26.157,
			26.205,
			26.218,
			26.282,
			26.312,
			26.343,
			26.405,
			26.42,
			26.466,
			26.534,
			26.59,
			26.637,
			26.717,
			26.746,
			26.778,
			26.854,
			26.901,
			26.963,
			27.027,
			27.071,
			27.088,
			27.15,
			27.18,
			27.212,
			27.274,
			27.289,
			27.337,
			27.399,
			27.461,
			27.507,
			27.521,
			27.584,
			27.615,
			27.646,
			27.709,
			27.771,
			27.833,
			27.893,
			27.942,
			28.019,
			28.047,
			28.08,
			28.143,
			28.157,
			28.205,
			28.268,
			28.329,
			28.374,
			28.389,
			28.453,
			28.483,
			28.515,
			28.575,
			28.597,
			28.638,
			28.702,
			28.762,
			28.81,
			28.886,
			28.918,
			28.947,
			29.011,
			29.073,
			29.134,
			29.196,
			29.241,
			29.257,
			29.321,
			29.351,
			29.384,
			29.445,
			29.459,
			29.505,
			29.568,
			29.63,
			29.676,
			29.693,
			29.753,
			29.785,
			29.816,
			29.879,
			29.894,
			29.94,
			30.002,
			30.064,
			30.112,
			30.187,
			30.218,
			30.25,
			30.314,
			30.376,
			30.436,
			30.5,
			30.545,
			30.56,
			30.622,
			30.658,
			30.684,
			30.762,
			30.81,
			30.87,
			30.932,
			30.979,
			30.996,
			31.058,
			31.088,
			31.118,
			31.179,
			31.197,
			31.243,
			31.305,
			31.367,
			31.429,
			31.49,
			31.521,
			31.554,
			31.615,
			31.678,
			31.738,
			31.801,
			31.862,
			31.924,
			31.955,
			31.987,
			32.05,
			32.062,
			32.109,
			32.174,
			32.234,
			32.283,
			32.359,
			32.39,
			32.421,
			32.483,
			32.544,
			32.607,
			32.667,
			32.729,
			32.794,
			32.822,
			32.854,
			32.918,
			32.933,
			32.979,
			33.04,
			33.104,
			33.151,
			33.163,
			33.227,
			33.258,
			33.29,
			33.35,
			33.368,
			33.412,
			33.477,
			33.537,
			33.585,
			33.662,
			33.691,
			33.724,
			33.784,
			33.846,
			33.909,
			33.972,
			34.018,
			34.034,
			34.094,
			34.126,
			34.158,
			34.235,
			34.282,
			34.343,
			34.406,
			34.451,
			34.468,
			34.53,
			34.559,
			34.591,
			34.654,
			34.668,
			34.715,
			34.776,
			34.782,
			34.838,
			34.887,
			34.962,
			34.994,
			35.025,
			35.087,
			35.149,
			35.212,
			35.272,
			35.321,
			35.335,
			35.396,
			35.429,
			35.458,
			35.52,
			35.538,
			35.584,
			35.646,
			35.706,
			35.769,
			35.832,
			35.864,
			35.893,
			35.97,
			36.017,
			36.08,
			36.141,
			36.188,
			36.265,
			36.297,
			36.327,
			36.391,
			36.451,
			36.512,
			36.574,
			36.621,
			36.638,
			36.7,
			36.731,
			36.76,
			36.843,
			36.884,
			36.947,
			37.009,
			37.055,
			37.071,
			37.133,
			37.163,
			37.195,
			37.257,
			37.32,
			37.381,
			37.444,
			37.49,
			37.567,
			37.6,
			37.631,
			37.707,
			37.753,
			37.816,
			37.879,
			37.94,
			38.001,
			38.033,
			38.063,
			38.125,
			38.142,
			38.187,
			38.25,
			38.313,
			38.359,
			38.435,
			38.468,
			38.497,
			38.561,
			38.622,
			38.684,
			38.747,
			38.793,
			38.808,
			38.87,
			38.904,
			38.932,
			38.994,
			39.01,
			39.056,
			39.118,
			39.18,
			39.226,
			39.243,
			39.304,
			39.334,
			39.367,
			39.429,
			39.444,
			39.491,
			39.551,
			39.613,
			39.676,
			39.738,
			39.768,
			39.8,
			39.863,
			39.924,
			39.988,
			40.049,
			40.094,
			40.109,
			40.171,
			40.202,
			40.235,
			40.311,
			40.359,
			40.42,
			40.483,
			40.528,
			40.543,
			40.607,
			40.638,
			40.669,
			40.747,
			40.791,
			40.854,
			40.917,
			40.979,
			41.041,
			41.071,
			41.101,
			41.179,
			41.227,
			41.287,
			41.351,
			41.397,
			41.413,
			41.475,
			41.504,
			41.537,
			41.6,
			41.613,
			41.662,
			41.724,
			41.783,
			41.832,
			41.908,
			41.939,
			41.972,
			42.049,
			42.095,
			42.157,
			42.22,
			42.266,
			42.342,
			42.374,
			42.405,
			42.467,
			42.483,
			42.53,
			42.59,
			42.653,
			42.715,
			42.776,
			42.806,
			42.838,
			42.901,
			42.916,
			42.963,
			43.03,
			43.087,
			43.134,
			43.211,
			43.241,
			43.271,
			43.335,
			43.395,
			43.459,
			43.522,
			43.583,
			43.646,
			43.677,
			43.707,
			43.767,
			43.784,
			43.83,
			43.893,
			43.955,
			44,
			44.017,
			44.079,
			44.109,
			44.141,
			44.204,
			44.219,
			44.263,
			44.327,
			44.387,
			44.435,
			44.513,
			44.545,
			44.574,
			44.638,
			44.697,
			44.761,
			44.822,
			44.869,
			44.884,
			44.947,
			44.978,
			45.009,
			45.072,
			45.092,
			45.133,
			45.195,
			45.258,
			45.302,
			45.318,
			45.38,
			45.412,
			45.443,
			45.52,
			45.568,
			45.628,
			45.69,
			45.753,
			45.816,
			45.846,
			45.877,
			45.939,
			46.001,
			46.062,
			46.126,
			46.173,
			46.187,
			46.249,
			46.28,
			46.31,
			46.374,
			46.388,
			46.435,
			46.497,
			46.559,
			46.607,
			46.683,
			46.715,
			46.746,
			46.806,
			46.824,
			46.869,
			46.935,
			46.993,
			47.055,
			47.117,
			47.153,
			47.18,
			47.256,
			47.304,
			47.366,
			47.427,
			47.474,
			47.489,
			47.55,
			47.583,
			47.614,
			47.674,
			47.691,
			47.738,
			47.8,
			47.861,
			47.908,
			47.986,
			48.018,
			48.046,
			48.124,
			48.171,
			48.234,
			48.295,
			48.343,
			48.358,
			48.421,
			48.451,
			48.483,
			48.544,
			48.559,
			48.606,
			48.668,
			48.729,
			48.793,
			48.855,
			48.886,
			48.916,
			48.976,
			48.994,
			49.04,
			49.101,
			49.163,
			49.225,
			49.287,
			49.319,
			49.349,
			49.428,
			49.474,
			49.535,
			49.598,
			49.643,
			49.659,
			49.721,
			49.753,
			49.784,
			49.847,
			49.862,
			49.908,
			49.97,
			50.032,
			50.078,
			50.093,
			50.156,
			50.188,
			50.219,
			50.28,
			50.297,
			50.341,
			50.404,
			50.467,
			50.512,
			50.591,
			50.622,
			50.653,
			50.729,
			50.777,
			50.839,
			50.901,
			50.947,
			50.962,
			51.023,
			51.055,
			51.087,
			51.162,
			51.209,
			51.276,
			51.334,
			51.395,
			51.457,
			51.489,
			51.52,
			51.583,
			51.644,
			51.705,
			51.768,
			51.812,
			51.891,
			51.924,
			51.954,
			52.016,
			52.078,
			52.141,
			52.201,
			52.263,
			52.327,
			52.358,
			52.389,
			52.45,
			52.464,
			52.512,
			52.575,
			52.637,
			52.684,
			52.759,
			52.79,
			52.823,
			52.885,
			52.947,
			53.009,
			53.069,
			53.117,
			53.133,
			53.196,
			53.226,
			53.255,
			53.318,
			53.338,
			53.381,
			53.443,
			53.504,
			53.567,
			53.629,
			53.658,
			53.691,
			53.754,
			53.767,
			53.813,
			53.877,
			53.94,
			54.001,
			54.062,
			54.095,
			54.124,
			54.187,
			54.249,
			54.311,
			54.374,
			54.435,
			54.495,
			54.526,
			54.558,
			54.621,
			54.635,
			54.682,
			54.746,
			54.807,
			54.854,
			54.869,
			54.929,
			54.962,
			54.993,
			55.054,
			55.069,
			55.118,
			55.179,
			55.24,
			55.304,
			55.366,
			55.4,
			55.427,
			55.488,
			55.55,
			55.614,
			55.676,
			55.72,
			55.737,
			55.797,
			55.831,
			55.862,
			55.923,
			55.939,
			55.984,
			56.047,
			56.109,
			56.155,
			56.171,
			56.234,
			56.264,
			56.294,
			56.371,
			56.419,
			56.48,
			56.543,
			56.59,
			56.668,
			56.697,
			56.73,
			56.79,
			56.808,
			56.854,
			56.915,
			56.976,
			57.024,
			57.038,
			57.102,
			57.133,
			57.162,
			57.224,
			57.242,
			57.288,
			57.35,
			57.41,
			57.461,
			57.535,
			57.566,
			57.596,
			57.66,
			57.722,
			57.784,
			57.844,
			57.893,
			57.907,
			57.97,
			58.001,
			58.029,
			58.093,
			58.109,
			58.154,
			58.217,
			58.278,
			58.325,
			58.34,
			58.404,
			58.434,
			58.466,
			58.527,
			58.543,
			58.588,
			58.655,
			58.714,
			58.758,
			58.837,
			58.868,
			58.9,
			58.977,
			59.024,
			59.085,
			59.148,
			59.194,
			59.208,
			59.272,
			59.302,
			59.333,
			59.411,
			59.457,
			59.524,
			59.579,
			59.643,
			59.704,
			59.736,
			59.769,
			59.83,
			59.844,
			59.893,
			59.954,
			60.016,
			60.062,
			60.14,
			60.17,
			60.2,
			60.262,
			60.327,
			60.387,
			60.451,
			60.496,
			60.512,
			60.574,
			60.604,
			60.635,
			60.698,
			60.712,
			60.759,
			60.823,
			60.884,
			60.929,
			60.946,
			61.006,
			61.037,
			61.069,
			61.146,
			61.195,
			61.254,
			61.318,
			61.366,
			61.44,
			61.473,
			61.505,
			61.566,
			61.585,
			61.627,
			61.689,
			61.753,
			61.799,
			61.815,
			61.877,
			61.907,
			61.938,
			62,
			62.016,
			62.061,
			62.123,
			62.185,
			62.247,
			62.31,
			62.341,
			62.373,
			62.434,
			62.496,
			62.557,
			62.62,
			62.667,
			62.682,
			62.745,
			62.775,
			62.805,
			62.868,
			62.884,
			62.929,
			62.993,
			63.054,
			63.1,
			63.117,
			63.179,
			63.208,
			63.239,
			63.301,
			63.318,
			63.365,
			63.425,
			63.488,
			63.535,
			63.613,
			63.647,
			63.673,
			63.736,
			63.799,
			63.861,
			63.922,
			63.967,
			63.984,
			64.046,
			64.077,
			64.109,
			64.17,
			64.187,
			64.233,
			64.293,
			64.355,
			64.403,
			64.419,
			64.481,
			64.513,
			64.544,
			64.604,
			64.621,
			64.668,
			64.728,
			64.791,
			64.838,
			64.915,
			64.946,
			64.977,
			65.037,
			65.099,
			65.162,
			65.224,
			65.27,
			65.287,
			65.347,
			65.379,
			65.41,
			65.472,
			65.487,
			65.534,
			65.596,
			65.659,
			65.71,
			65.783,
			65.812,
			65.844,
			65.921,
			65.97,
			66.029,
			66.091,
			66.14,
			66.218,
			66.249,
			66.28,
			66.341,
			66.357,
			66.402,
			66.465,
			66.526,
			66.573,
			66.587,
			66.651,
			66.68,
			66.712,
			66.773,
			66.79,
			66.837,
			66.9,
			66.96,
			67.006,
			67.023,
			67.085,
			67.117,
			67.145,
			67.209,
			67.271,
			67.334,
			67.395,
			67.44,
			67.458,
			67.52,
			67.55,
			67.579,
			67.66,
			67.704,
			67.769,
			67.829,
			67.891,
			67.954,
			67.984,
			68.013,
			68.077,
			68.091,
			68.138,
			68.2,
			68.263,
			68.309,
			68.388,
			68.418,
			68.45,
			68.511,
			68.574,
			68.637,
			68.696,
			68.744,
			68.759,
			68.821,
			68.852,
			68.884,
			68.962,
			69.007,
			69.07,
			69.13,
			69.178,
			69.192,
			69.256,
			69.287,
			69.317,
			69.379,
			69.396,
			69.441,
			69.504,
			69.566,
			69.613,
			69.69,
			69.721,
			69.752,
			69.814,
			69.834,
			69.876,
			69.936,
			70,
			70.047,
			70.06,
			70.124,
			70.153,
			70.185,
			70.262,
			70.31,
			70.371,
			70.435,
			70.481,
			70.496,
			70.557,
			70.59,
			70.62,
			70.697,
			70.743,
			70.804,
			70.869,
			70.913,
			70.991,
			71.024,
			71.054,
			71.114,
			71.177,
			71.238,
			71.301,
			71.348,
			71.362,
			71.425,
			71.457,
			71.487,
			71.566,
			71.611,
			71.673,
			71.737,
			71.784,
			71.859,
			71.896,
			71.921,
			71.983,
			72.047,
			72.106,
			72.17,
			72.217,
			72.293,
			72.324,
			72.354,
			72.419,
			72.433,
			72.479,
			72.543,
			72.605,
			72.666,
			72.727,
			72.76,
			72.79,
			72.852,
			72.868,
			72.914,
			72.975,
			73.038,
			73.085,
			73.163,
			73.192,
			73.225,
			73.285,
			73.347,
			73.41,
			73.472,
			73.518,
			73.535,
			73.597,
			73.627,
			73.657,
			73.719,
			73.738
		]
	],
	'1853_1854_7982': [
		[
			100.574
		],
		[
			0
		],
		[
			0,
			1.692,
			6.047,
			8.958,
			13.339,
			16.269,
			19.207,
			23.628,
			25.106,
			26.585,
			31.034,
			32.519,
			34.007,
			38.473,
			41.455,
			44.438,
			45.931,
			48.94,
			51.927,
			53.419,
			56.406,
			57.899,
			60.888,
			63.879,
			68.356,
			71.371,
			75.957,
			77.5,
			79.044,
			83.736,
			86.907,
			91.703,
			94.988,
			98.257,
			99.894,
			103.169,
			104.807,
			108.091,
			111.376,
			113.007,
			116.267,
			119.471,
			124.218,
			125.791,
			127.352,
			130.468,
			133.583,
			135.14,
			138.261,
			139.823,
			142.951,
			146.084,
			150.788,
			153.927,
			158.641,
			160.214,
			161.788,
			166.519,
			169.685,
			174.446,
			177.627,
			180.81,
			182.401,
			185.573,
			187.154,
			190.301,
			193.425,
			194.976,
			198.053,
			201.093,
			205.574,
			207.051,
			208.495,
			212.879,
			214.335,
			215.793,
			220.197,
			223.124,
			226.058,
			230.472,
			233.419,
			237.835,
			239.306,
			240.771,
			245.147,
			246.591,
			248.031,
			252.303,
			255.12,
			257.9,
			259.282,
			262.017,
			264.733,
			266.075,
			268.736,
			270.052,
			272.652,
			275.211,
			278.975,
			281.438,
			285.062,
			286.25,
			287.427,
			290.889,
			293.125,
			296.441,
			298.597,
			300.723,
			303.872,
			304.912,
			305.948,
			309.02,
			310.048,
			311.075,
			314.18,
			316.244,
			318.315,
			319.353,
			321.43,
			323.511,
			324.553,
			327.677,
			329.755,
			331.833,
			334.943,
			337.01,
			340.107,
			341.135,
			342.161,
			345.224,
			347.253,
			350.277,
			352.281,
			355.278,
			357.268,
			358.257,
			360.206,
			362.159,
			363.129,
			365.046,
			366.935,
			368.785,
			371.487,
			373.242,
			374.953,
			375.795,
			377.469,
			378.288,
			379.908,
			381.506,
			383.87,
			385.428,
			387.758,
			388.538,
			389.318,
			391.656,
			393.216,
			395.586,
			397.162,
			399.543,
			401.133,
			401.927,
			403.517,
			405.116,
			405.913,
			407.505,
			409.103,
			411.504,
			412.304,
			413.105,
			415.523,
			416.328,
			417.133,
			418.744,
			419.552,
			421.165,
			423.581,
			425.182,
			426.778,
			429.158,
			429.945,
			430.731,
			433.083,
			434.641,
			436.966,
			438.515,
			440.85,
			442.402,
			443.965,
			444.753,
			446.326,
			447.113,
			448.689,
			450.275,
			452.648,
			454.217,
			456.551,
			457.329,
			458.109,
			460.407,
			461.94,
			464.234,
			465.753,
			467.277,
			469.564,
			470.327,
			471.091,
			473.399,
			474.169,
			474.941,
			477.274,
			478.841,
			480.414,
			481.204,
			482.795,
			484.394,
			485.196,
			486.808,
			487.619,
			489.249,
			490.889,
			493.371,
			495.044,
			497.578,
			498.429,
			499.287,
			501.882,
			503.627,
			506.278,
			508.061,
			509.858,
			510.761,
			512.578,
			513.49,
			515.323,
			517.167,
			518.094,
			519.955,
			521.826,
			524.656,
			525.605,
			526.557,
			528.469,
			530.395,
			531.362,
			533.305,
			534.281,
			536.242,
			538.214,
			541.193,
			543.192,
			546.212,
			547.224,
			548.238,
			551.3,
			553.354,
			556.455,
			558.513,
			560.588,
			561.627,
			563.713,
			565.81,
			566.862,
			568.973,
			570.033,
			572.161,
			574.3,
			577.529,
			578.611,
			579.696,
			581.874,
			584.061,
			585.16,
			588.472,
			590.694,
			592.928,
			596.299,
			598.56,
			601.972,
			603.115,
			604.26,
			607.71,
			608.865,
			610.022,
			613.51,
			615.849,
			618.199,
			619.378,
			621.751,
			624.135,
			625.335,
			628.957,
			631.393,
			633.844,
			637.545,
			640.021,
			643.742,
			644.971,
			646.187,
			649.803,
			652.167,
			654.502,
			657.932,
			661.301,
			663.518,
			664.624,
			665.73,
			669.049,
			670.157,
			671.271,
			674.611,
			676.849,
			680.206,
			682.453,
			684.692,
			685.813,
			688.059,
			689.181,
			691.438,
			693.709,
			697.14,
			699.442,
			702.925,
			704.088,
			705.249,
			708.775,
			711.121,
			714.657,
			717.007,
			719.346,
			720.507,
			722.823,
			723.969,
			726.229,
			728.448,
			729.547,
			731.704,
			733.804,
			736.931,
			737.954,
			738.975,
			741.029,
			743.049,
			744.058,
			746.074,
			747.079,
			749.089,
			751.099,
			752.102,
			754.109,
			756.097,
			759.092,
			760.081,
			761.065,
			763.976,
			765.873,
			768.649,
			770.453,
			772.224,
			773.095,
			774.812,
			775.658,
			777.339,
			779.011,
			779.832,
			781.474,
			783.119,
			785.575,
			786.4,
			787.229,
			789.713,
			790.54,
			791.373,
			793.051,
			793.891,
			795.57,
			797.253,
			799.778,
			801.465,
			803.998,
			804.842,
			805.687,
			808.213,
			809.894,
			812.392,
			814.045,
			815.698,
			816.519,
			818.148,
			818.964,
			820.597,
			822.218,
			823.026,
			824.645,
			826.267,
			828.692,
			830.314,
			832.755,
			833.571,
			834.388,
			836.851,
			838.501,
			840.992,
			842.66,
			844.338,
			846.874,
			847.724,
			848.577,
			851.153,
			852.015,
			852.88,
			855.469,
			857.212,
			858.959,
			859.836,
			861.6,
			863.391,
			864.281,
			866.958,
			868.754,
			870.556,
			873.273,
			875.112,
			877.9,
			878.838,
			879.779,
			882.623,
			884.538,
			887.435,
			889.386,
			891.35,
			892.338,
			894.323,
			896.321,
			897.326,
			899.347,
			900.363,
			901.382,
			904.461,
			906.533,
			908.616,
			909.663,
			911.766,
			913.87,
			914.923,
			917.037,
			918.097,
			920.221,
			922.338,
			925.476,
			927.53,
			930.545,
			931.532,
			932.512,
			935.398,
			937.279,
			940.037,
			941.836,
			943.602,
			944.473,
			946.188,
			947.033,
			948.695,
			951.121,
			952.694,
			954.231,
			956.47,
			957.199,
			957.919,
			960.026,
			960.711,
			961.401,
			963.4,
			964.707,
			965.999,
			967.911,
			969.173,
			971.066,
			971.698,
			972.327,
			974.205,
			975.457,
			977.338,
			978.596,
			979.85,
			980.476,
			981.727,
			982.361,
			983.648,
			984.928,
			985.566,
			986.839,
			988.145,
			990.102,
			991.397,
			993.375,
			994.039,
			994.702,
			996.685,
			998.039,
			1000.085,
			1001.447,
			1003.517,
			1004.903,
			1005.595,
			1006.288,
			1008.364,
			1009.054,
			1009.743,
			1011.814,
			1013.188,
			1014.558,
			1015.244,
			1016.62,
			1017.993,
			1018.676,
			1020.044,
			1020.73,
			1022.107,
			1023.464,
			1025.5,
			1026.864,
			1028.887,
			1029.56,
			1030.233,
			1032.258,
			1033.597,
			1035.611,
			1036.962,
			1038.316,
			1038.995,
			1040.36,
			1041.046,
			1042.427,
			1043.819,
			1044.519,
			1045.927,
			1047.349,
			1048.789,
			1050.977,
			1052.456,
			1053.962,
			1054.721,
			1056.253,
			1057.025,
			1058.592,
			1060.18,
			1062.594,
			1064.236,
			1066.73,
			1067.57,
			1068.416,
			1070.955,
			1072.658,
			1075.246,
			1076.991,
			1078.75,
			1079.634,
			1081.416,
			1082.314,
			1084.114,
			1085.931,
			1086.846,
			1088.685,
			1090.522,
			1093.346,
			1094.288,
			1095.218,
			1098.077,
			1099.035,
			1099.997,
			1101.932,
			1102.905,
			1104.857,
			1107.812,
			1109.795,
			1111.792,
			1114.81,
			1115.822,
			1116.837,
			1119.901,
			1121.959,
			1125.069,
			1127.152,
			1129.237,
			1130.283,
			1132.381,
			1133.436,
			1135.554,
			1137.703,
			1138.774,
			1140.909,
			1143.092,
			1146.383,
			1147.485,
			1148.596,
			1151.969,
			1153.099,
			1154.243,
			1157.697,
			1160.02,
			1163.525,
			1165.863,
			1168.206,
			1171.703,
			1172.869,
			1174.033,
			1177.519,
			1178.682,
			1179.844,
			1183.33,
			1185.659,
			1187.988,
			1189.155,
			1191.49,
			1193.825,
			1194.994,
			1197.331,
			1198.499,
			1200.833,
			1203.167,
			1206.677,
			1209.024,
			1212.558,
			1213.739,
			1214.92,
			1218.477,
			1220.856,
			1224.444,
			1226.846,
			1229.259,
			1230.469,
			1232.894,
			1234.111,
			1236.552,
			1239.003,
			1240.239,
			1241.476,
			1245.206,
			1247.707,
			1251.474,
			1253.994,
			1256.511,
			1257.773,
			1260.298,
			1261.561,
			1264.092,
			1266.623,
			1270.429,
			1272.97,
			1276.791,
			1278.068,
			1279.346,
			1283.19,
			1285.761,
			1289.63,
			1292.22,
			1296.118,
			1298.725,
			1300.031,
			1302.651,
			1305.274,
			1306.589,
			1309.212,
			1311.836,
			1315.779,
			1317.073,
			1318.39,
			1321.028,
			1323.668,
			1324.991,
			1327.638,
			1328.964,
			1331.617,
			1334.274,
			1338.27,
			1340.942,
			1344.954,
			1346.288,
			1347.619,
			1351.578,
			1354.18,
			1358.025,
			1360.552,
			1363.052,
			1364.292,
			1366.748,
			1367.963,
			1370.368,
			1373.909,
			1376.226,
			1378.51,
			1381.88,
			1384.087,
			1387.335,
			1388.401,
			1389.458,
			1392.608,
			1394.671,
			1396.708,
			1399.795,
			1401.861,
			1404.962,
			1406.011,
			1407.042,
			1410.126,
			1411.151,
			1412.174,
			1415.234,
			1417.268,
			1419.299,
			1420.312,
			1422.337,
			1424.357,
			1425.365,
			1427.383,
			1428.391,
			1430.404,
			1432.426,
			1435.439,
			1437.491,
			1440.542,
			1441.566,
			1442.592,
			1445.666,
			1447.731,
			1450.831,
			1452.893,
			1454.944,
			1455.966,
			1457.993,
			1459,
			1460.999,
			1463.956,
			1465.894,
			1467.808,
			1469.7,
			1471.575,
			1472.51,
			1474.368,
			1476.218,
			1477.145,
			1479.91,
			1481.749,
			1483.588,
			1486.34,
			1488.178,
			1490.935,
			1491.858,
			1492.783,
			1495.562,
			1497.419,
			1500.205,
			1502.062,
			1503.918,
			1504.844,
			1506.695,
			1507.616,
			1509.454,
			1511.288,
			1512.197,
			1514.006,
			1515.811,
			1518.498,
			1519.389,
			1520.279,
			1522.941,
			1523.825,
			1524.707,
			1526.467,
			1527.345,
			1529.097,
			1530.842,
			1533.451,
			1535.182,
			1537.773,
			1538.634,
			1539.494,
			1542.07,
			1543.786,
			1546.356,
			1548.073,
			1550.646,
			1552.363,
			1553.224,
			1554.946,
			1556.668,
			1557.532,
			1559.264,
			1560.997,
			1563.611,
			1565.362,
			1568.002,
			1568.887,
			1569.773,
			1572.443,
			1574.234,
			1576.927,
			1578.738,
			1580.553,
			1583.288,
			1584.207,
			1585.125,
			1588.818,
			1589.747,
			1592.544,
			1594.418,
			1596.299,
			1597.242,
			1599.142,
			1601.05,
			1602.006,
			1604.909,
			1606.859,
			1608.825,
			1611.798,
			1613.792,
			1616.799,
			1617.809,
			1618.815,
			1621.856,
			1623.903,
			1626.994,
			1629.071,
			1631.157,
			1632.205,
			1634.312,
			1635.37,
			1636.43,
			1639.632,
			1640.705,
			1641.781,
			1645.021,
			1647.18,
			1650.429,
			1652.607,
			1654.796,
			1655.894,
			1658.098,
			1659.207,
			1661.431,
			1663.665,
			1667.041,
			1669.304,
			1672.714,
			1673.855,
			1674.997,
			1678.437,
			1680.733,
			1684.191,
			1686.5,
			1688.818,
			1689.976,
			1692.298,
			1693.462,
			1695.787,
			1699.288,
			1701.624,
			1703.967,
			1707.478,
			1709.823,
			1712.169,
			1714.522,
			1715.698,
			1718.056,
			1719.237,
			1721.605,
			1723.981,
			1727.56,
			1729.954,
			1733.559,
			1734.765,
			1735.973,
			1739.608,
			1742.042,
			1745.709,
			1748.164,
			1750.626,
			1751.86,
			1754.333,
			1755.571,
			1758.053,
			1761.788,
			1764.287,
			1766.791,
			1770.56,
			1771.82,
			1773.083,
			1776.862,
			1778.133,
			1779.405,
			1781.964,
			1783.246,
			1785.819,
			1789.702,
			1792.307,
			1794.914,
			1798.84,
			1800.152,
			1801.466,
			1805.417,
			1806.735,
			1808.056,
			1812.023,
			1814.671,
			1817.321,
			1818.647,
			1821.302,
			1823.959,
			1825.29,
			1829.286,
			1831.957,
			1834.634,
			1838.659,
			1841.349,
			1845.393,
			1846.743,
			1848.095,
			1852.161,
			1854.88,
			1858.97,
			1861.705,
			1864.447,
			1865.82,
			1868.572,
			1869.949,
			1871.329,
			1875.479,
			1876.865,
			1878.254,
			1882.429,
			1885.221,
			1888.022,
			1889.424,
			1892.235,
			1895.054,
			1896.466,
			1899.295,
			1900.712,
			1903.552,
			1906.397,
			1910.678,
			1913.539,
			1917.842,
			1919.279,
			1920.717,
			1925.04,
			1927.929,
			1932.272,
			1935.173,
			1938.079,
			1939.534,
			1942.447,
			1943.905,
			1946.825,
			1951.217,
			1954.152,
			1957.093,
			1960.038,
			1962.989,
			1964.466,
			1967.425,
			1970.391,
			1971.875,
			1974.848,
			1976.337,
			1979.318,
			1982.304,
			1986.795,
			1989.795,
			1994.302,
			1995.807,
			1997.313,
			2001.838,
			2004.858,
			2009.396,
			2012.425,
			2015.458,
			2016.976,
			2020.016,
			2021.538,
			2024.585,
			2029.16,
			2032.208,
			2035.243,
			2039.75,
			2042.714,
			2047.089,
			2048.527,
			2049.956,
			2052.785,
			2054.185,
			2056.956,
			2061.037,
			2063.709,
			2066.343,
			2070.224,
			2071.498,
			2072.763,
			2076.5,
			2077.726,
			2078.942,
			2082.533,
			2084.881,
			2087.191,
			2088.331,
			2090.581,
			2092.792,
			2093.883,
			2096.035,
			2097.097,
			2099.191,
			2101.248,
			2104.262,
			2106.223,
			2109.093,
			2110.03,
			2110.957,
			2113.685,
			2115.459,
			2118.055,
			2119.741,
			2121.39,
			2122.201,
			2123.797,
			2124.581,
			2126.137,
			2127.646,
			2128.388,
			2129.123,
			2131.291,
			2132.707,
			2134.106,
			2134.802,
			2136.178,
			2137.546,
			2138.228,
			2140.267,
			2141.614,
			2142.956,
			2144.969,
			2146.327,
			2148.354,
			2149.029,
			2149.704,
			2151.83,
			2153.253,
			2155.416,
			2156.994,
			2158.561,
			2159.342,
			2160.966,
			2161.809,
			2163.488,
			2165.164,
			2166.017,
			2167.716,
			2169.406,
			2171.88,
			2172.691,
			2173.499,
			2175.108,
			2176.597,
			2177.332,
			2178.795,
			2179.525,
			2180.919,
			2182.247,
			2184.227,
			2185.523,
			2187.349,
			2187.953,
			2188.556,
			2190.359,
			2190.95,
			2191.54,
			2193.293,
			2194.463,
			2195.646,
			2196.241,
			2197.421,
			2198.015,
			2199.217,
			2200.417,
			2201.034,
			2202.277,
			2203.531,
			2205.436,
			2206.745,
			2208.74,
			2209.412,
			2210.089,
			2212.163,
			2213.568,
			2214.99,
			2217.163,
			2218.634,
			2220.872,
			2221.627,
			2222.386,
			2225.445,
			2226.215,
			2228.522,
			2230.052,
			2231.566,
			2232.314,
			2233.793,
			2234.523,
			2235.959,
			2238.026,
			2239.358,
			2240.656,
			2242.511,
			2243.703,
			2245.443,
			2246.012,
			2246.57,
			2248.206,
			2249.276,
			2250.864,
			2251.912,
			2253.472,
			2254.509,
			2255.027,
			2256.063,
			2257.141,
			2257.682,
			2258.223,
			2259.844,
			2260.932,
			2262.083,
			2262.672,
			2263.853,
			2265.038,
			2265.632,
			2266.874,
			2267.536,
			2268.864,
			2270.199,
			2272.283,
			2273.746,
			2275.958,
			2276.714,
			2277.51,
			2279.902,
			2281.505,
			2284.066,
			2285.781,
			2287.512,
			2288.385,
			2290.128,
			2291,
			2292.704,
			2294.362,
			2295.189,
			2296.844,
			2298.389,
			2299.926,
			2302.218,
			2303.628,
			2305.04,
			2305.747,
			2307.165,
			2307.823,
			2309.142,
			2310.466,
			2312.462,
			2313.771,
			2315.748,
			2316.41,
			2317.075,
			2319.104,
			2320.469,
			2322.54,
			2323.959,
			2325.39,
			2326.11,
			2327.55,
			2328.289,
			2329.779,
			2331.286,
			2332.046,
			2333.582,
			2335.136,
			2337.499,
			2339.099,
			2341.545,
			2342.364,
			2343.193,
			2345.714,
			2347.415,
			2349.153,
			2351.794,
			2353.567,
			2356.289,
			2357.204,
			2358.122,
			2361.874,
			2362.819,
			2365.65,
			2367.552,
			2369.455,
			2370.408,
			2372.324,
			2374.247,
			2375.208,
			2377.142,
			2378.114,
			2380.052,
			2382,
			2384.933,
			2386.89,
			2389.836,
			2390.815,
			2391.782,
			2394.762,
			2396.747,
			2399.751,
			2401.774,
			2403.809,
			2406.906,
			2407.945,
			2410.038,
			2412.151,
			2413.212,
			2414.276,
			2417.506,
			2419.664,
			2422.936,
			2425.135,
			2427.357,
			2428.472,
			2430.717,
			2431.847,
			2434.115,
			2436.393,
			2439.828,
			2442.107,
			2445.586,
			2446.733,
			2447.883,
			2451.328,
			2452.452,
			2453.597,
			2457.019,
			2459.296,
			2462.699,
			2464.972,
			2466.108,
			2468.382,
			2470.668,
			2471.821,
			2474.135,
			2476.472,
			2480.026,
			2482.428,
			2484.849,
			2487.3,
			2488.519,
			2490.965,
			2492.188,
			2494.642,
			2498.359,
			2500.858,
			2503.392,
			2507.229,
			2508.522,
			2509.817,
			2513.719,
			2516.334,
			2520.256,
			2522.875,
			2526.785,
			2529.422,
			2530.731,
			2533.319,
			2535.939,
			2537.238,
			2539.795,
			2542.362,
			2546.154,
			2547.404,
			2548.642,
			2552.302,
			2553.521,
			2554.71,
			2558.205,
			2560.5,
			2562.761,
			2566.099,
			2568.3,
			2571.607,
			2572.7,
			2573.796,
			2578.176,
			2579.276,
			2582.576,
			2584.785,
			2586.998,
			2588.104,
			2590.33,
			2591.443,
			2593.671,
			2595.925,
			2597.052,
			2599.309,
			2601.577,
			2604.982,
			2606.118,
			2607.254,
			2610.653,
			2611.783,
			2612.913,
			2616.312,
			2618.588,
			2622.037,
			2624.35,
			2626.664,
			2627.825,
			2630.15,
			2631.309,
			2632.47,
			2637.125,
			2638.291,
			2641.792,
			2644.131,
			2647.638,
			2649.984,
			2652.298,
			2653.468,
			2656.977,
			2659.319,
			2661.668,
			2665.207,
			2667.598,
			2671.217,
			2672.447,
			2673.679,
			2677.421,
			2679.973,
			2683.821,
			2686.408,
			2690.309,
			2692.904,
			2694.2,
			2696.797,
			2699.391,
			2700.69,
			2703.304,
			2705.928,
			2708.578,
			2712.583,
			2715.276,
			2717.966,
			2719.308,
			2721.993,
			2723.324,
			2725.99,
			2728.644,
			2732.605,
			2735.223,
			2739.132,
			2740.43,
			2741.728,
			2745.609,
			2748.192,
			2752.052,
			2754.62,
			2757.186,
			2758.471,
			2761.045,
			2762.333,
			2764.915,
			2767.503,
			2768.799,
			2771.378,
			2773.984,
			2777.897,
			2779.204,
			2780.512,
			2783.134,
			2785.761,
			2787.077,
			2791.034,
			2793.681,
			2796.336,
			2800.333,
			2803.008,
			2807.035,
			2808.382,
			2809.73,
			2813.787,
			2816.502,
			2820.589,
			2823.323,
			2827.441,
			2830.198,
			2832.964,
			2834.35,
			2837.129,
			2838.522,
			2841.315,
			2844.116,
			2848.333,
			2851.155,
			2855.403,
			2856.823,
			2858.245,
			2862.523,
			2865.386,
			2869.694,
			2872.577,
			2875.468,
			2876.916,
			2879.818,
			2881.272,
			2884.186,
			2888.572,
			2890.038,
			2894.447,
			2897.397,
			2900.355,
			2901.837,
			2904.806,
			2907.782,
			2909.272,
			2912.252,
			2913.739,
			2916.699,
			2919.631,
			2923.963,
			2926.803,
			2930.992,
			2932.37,
			2933.738,
			2937.785,
			2940.436,
			2944.341,
			2946.898,
			2949.418,
			2950.664,
			2953.127,
			2954.345,
			2956.754,
			2959.128,
			2960.303,
			2962.626,
			2964.913,
			2968.271,
			2969.372,
			2970.464,
			2972.619,
			2974.737,
			2975.782,
			2977.844,
			2978.862,
			2980.87,
			2982.841,
			2985.729,
			2987.608,
			2990.362,
			2991.264,
			2992.157,
			2994.785,
			2996.493,
			2998.986,
			3000.602,
			3002.182,
			3002.958,
			3004.484,
			3005.233,
			3006.705,
			3008.141,
			3008.845,
			3010.228,
			3011.577,
			3013.539,
			3014.177,
			3014.806,
			3016.638,
			3017.231,
			3017.815,
			3019.526,
			3020.634,
			3021.724,
			3023.342,
			3024.403,
			3025.973,
			3026.492,
			3027.01,
			3028.557,
			3029.586,
			3031.124,
			3032.142,
			3033.666,
			3034.691,
			3035.219,
			3036.273,
			3037.325,
			3037.852,
			3038.906,
			3040.002,
			3041.746,
			3042.904,
			3044.644,
			3045.314,
			3045.982,
			3047.982,
			3049.316,
			3051.351,
			3052.708,
			3054.065,
			3054.745,
			3055.938,
			3056.527,
			3057.117,
			3058.883,
			3059.474,
			3060.044,
			3061.631,
			3062.687,
			3064.261,
			3065.289,
			3066.302,
			3066.813,
			3067.815,
			3068.314,
			3069.306,
			3070.318,
			3071.931,
			3072.996,
			3074.584,
			3075.111,
			3075.731,
			3077.607,
			3078.848,
			3080.772,
			3082.198,
			3083.606,
			3084.307,
			3085.721,
			3086.442,
			3087.871,
			3089.289,
			3089.995,
			3091.308,
			3092.567,
			3093.808,
			3095.037,
			3095.621,
			3096.655,
			3097.679,
			3098.189,
			3099.208,
			3099.717,
			3100.719,
			3101.65,
			3103.042,
			3103.97,
			3105.364,
			3105.829,
			3106.29,
			3107.672,
			3108.594,
			3109.982,
			3110.913,
			3111.854,
			3112.325,
			3113.27,
			3113.744,
			3114.696,
			3115.655,
			3116.14,
			3117.118,
			3118.101,
			3119.591,
			3120.595,
			3122.135,
			3122.654,
			3123.176,
			3124.227,
			3124.757,
			3125.826,
			3126.917,
			3128.579,
			3129.704,
			3131.421,
			3132.002,
			3132.587,
			3134.365,
			3135.573,
			3137.411,
			3138.656,
			3139.917,
			3140.543,
			3141.828,
			3143.128,
			3143.784,
			3145.108,
			3145.777,
			3147.126,
			3148.491,
			3150.57,
			3151.979,
			3154.126,
			3154.851,
			3155.579,
			3157.792,
			3159.29,
			3160.804,
			3163.109,
			3164.669,
			3165.455,
			3167.04,
			3167.839,
			3168.642,
			3171.074,
			3171.892,
			3172.715,
			3175.206,
			3176.884,
			3178.576,
			3179.423,
			3181.116,
			3182.816,
			3183.672,
			3185.393,
			3186.258,
			3187.998,
			3189.751,
			3192.42,
			3194.206,
			3196.909,
			3197.817,
			3198.729,
			3201.482,
			3203.337,
			3206.147,
			3208.038,
			3210.909,
			3212.839,
			3213.817,
			3215.786,
			3217.768,
			3218.769,
			3220.787,
			3222.817,
			3224.865,
			3227.96,
			3230.044,
			3232.143,
			3233.197,
			3235.321,
			3236.388,
			3238.531,
			3240.7,
			3243.973,
			3246.171,
			3249.473,
			3250.579,
			3251.688,
			3255.018,
			3257.237,
			3260.58,
			3262.816,
			3266.186,
			3268.44,
			3269.57,
			3271.822,
			3274.081,
			3275.211,
			3277.463,
			3279.725,
			3283.123,
			3285.395,
			3287.681,
			3289.973,
			3291.121,
			3294.578,
			3296.897,
			3299.228,
			3302.735,
			3305.082,
			3308.612,
			3309.793,
			3310.976,
			3314.515,
			3316.881,
			3320.435,
			3322.809,
			3326.382,
			3328.77,
			3331.167,
			3332.369,
			3335.99,
			3338.417,
			3340.833,
			3344.509,
			3346.991,
			3350.703,
			3351.945,
			3353.168,
			3356.915,
			3359.425,
			3363.207,
			3365.739,
			3368.278,
			3369.55,
			3372.1,
			3373.377,
			3374.656,
			3378.503,
			3379.79,
			3381.078,
			3384.955,
			3387.55,
			3390.149,
			3391.448,
			3394.052,
			3396.663,
			3397.971,
			3400.595,
			3401.91,
			3404.547,
			3407.193,
			3411.179,
			3413.845,
			3417.857,
			3419.197,
			3420.538,
			3424.569,
			3427.264,
			3431.321,
			3434.035,
			3436.759,
			3438.123,
			3440.858,
			3442.228,
			3444.974,
			3447.728,
			3449.107,
			3450.487,
			3454.637,
			3457.41,
			3460.191,
			3461.584,
			3464.376,
			3467.177,
			3468.581,
			3471.393,
			3472.802,
			3475.624,
			3478.452,
			3482.704,
			3485.547,
			3489.823,
			3491.251,
			3492.681,
			3496.983,
			3499.861,
			3504.189,
			3507.081,
			3509.979,
			3511.431,
			3514.34,
			3515.799,
			3518.728,
			3523.127,
			3526.06,
			3528.98,
			3533.328,
			3536.191,
			3539.019,
			3541.81,
			3543.191,
			3545.928,
			3547.283,
			3549.965,
			3552.61,
			3556.507,
			3559.06,
			3562.823,
			3564.059,
			3565.287,
			3568.916,
			3571.292,
			3574.791,
			3577.076,
			3579.323,
			3580.433,
			3582.623,
			3584.776,
			3585.838,
			3587.935,
			3588.969,
			3591.009,
			3593.013,
			3595.948,
			3597.857,
			3600.653,
			3601.567,
			3602.473,
			3605.139,
			3606.874,
			3609.406,
			3611.048,
			3612.653,
			3614.991,
			3615.752,
			3616.504,
			3619.421,
			3620.128,
			3622.197,
			3623.533,
			3624.835,
			3625.473,
			3626.723,
			3627.937,
			3628.531,
			3629.692,
			3630.261,
			3631.381,
			3632.479,
			3634.111,
			3635.186,
			3636.784,
			3637.314,
			3637.844,
			3639.436,
			3640.496,
			3642.077,
			3643.129,
			3644.725,
			3645.805,
			3646.343,
			3646.881,
			3648.492,
			3649.03,
			3650.155,
			3651.286,
			3652.413,
			3654.102,
			3655.284,
			3656.469,
			3657.059,
			3658.237,
			3658.825,
			3660.035,
			3661.255,
			3663.073,
			3664.284,
			3666.136,
			3666.75,
			3667.363,
			3669.2,
			3670.465,
			3672.355,
			3673.609,
			3674.869,
			3675.502,
			3676.765,
			3677.395,
			3678.65,
			3680.523,
			3681.759,
			3682.989,
			3684.828,
			3685.435,
			3686.038,
			3687.239,
			3688.436,
			3689.035,
			3690.812,
			3691.984,
			3693.737,
			3694.905,
			3696.064,
			3697.795,
			3698.373,
			3698.952,
			3700.705,
			3701.875,
			3703.638,
			3704.825,
			3706.025,
			3706.626,
			3707.833,
			3708.439,
			3709.659,
			3710.895,
			3711.516,
			3712.764,
			3714.023,
			3715.921,
			3717.196,
			3719.133,
			3719.779,
			3720.418,
			3722.354,
			3723.661,
			3724.97,
			3726.908,
			3728.22,
			3728.881,
			3730.204,
			3730.863,
			3731.525,
			3733.534,
			3734.211,
			3734.897,
			3736.993,
			3738.41,
			3739.846,
			3740.583,
			3742.068,
			3743.567,
			3744.323,
			3745.858,
			3746.632,
			3748.192,
			3749.768,
			3752.162,
			3753.763,
			3756.211,
			3757.035,
			3757.862,
			3760.363,
			3762.047,
			3764.584,
			3766.275,
			3767.977,
			3768.833,
			3770.555,
			3771.421,
			3773.164,
			3774.918,
			3775.8,
			3776.686,
			3779.361,
			3781.161,
			3782.973,
			3783.884,
			3785.715,
			3787.559,
			3788.485,
			3791.284,
			3793.165,
			3795.059,
			3797.921,
			3799.845,
			3802.752,
			3803.728,
			3804.706,
			3807.66,
			3809.644,
			3812.643,
			3814.656,
			3816.682,
			3817.7,
			3819.743,
			3821.798,
			3822.83,
			3824.901,
			3825.941,
			3828.03,
			3830.125,
			3833.268,
			3834.319,
			3835.372,
			3838.551,
			3839.615,
			3840.683,
			3842.827,
			3843.902,
			3846.062,
			3848.232,
			3851.507,
			3853.704,
			3857.017,
			3858.127,
			3859.239,
			3862.589,
			3864.835,
			3868.221,
			3870.49,
			3872.768,
			3873.91,
			3876.203,
			3877.353,
			3879.66,
			3881.978,
			3883.14,
			3885.471,
			3887.819,
			3891.365,
			3893.753,
			3897.374,
			3898.592,
			3899.812,
			3903.525,
			3906.019,
			3908.534,
			3912.345,
			3914.883,
			3918.699,
			3919.976,
			3921.253,
			3925.089,
			3927.628,
			3931.48,
			3934.056,
			3937.949,
			3940.538,
			3941.831,
			3944.421,
			3947.013,
			3948.308,
			3950.882,
			3953.473,
			3957.387,
			3959.986,
			3963.891,
			3965.196,
			3966.479,
			3970.402,
			3973.036,
			3976.943,
			3979.568,
			3982.198,
			3983.514,
			3986.151,
			3987.471,
			3990.115,
			3994.089,
			3995.416,
			3999.402,
			4002.067,
			4006.079,
			4008.769,
			4011.467,
			4012.823,
			4015.539,
			4016.901,
			4019.633,
			4022.372,
			4026.495,
			4029.252,
			4033.399,
			4034.785,
			4036.172,
			4040.341,
			4043.127,
			4047.316,
			4050.115,
			4052.92,
			4054.325,
			4057.138,
			4058.545,
			4061.365,
			4064.187,
			4065.6,
			4068.433,
			4071.272,
			4075.541,
			4078.395,
			4082.69,
			4084.125,
			4085.562,
			4089.884,
			4092.775,
			4095.672,
			4100.032,
			4101.181
		],
		[
			0,
			0.035,
			0.112,
			0.159,
			0.225,
			0.283,
			0.328,
			0.406,
			0.436,
			0.468,
			0.53,
			0.546,
			0.592,
			0.653,
			0.715,
			0.761,
			0.778,
			0.839,
			0.87,
			0.903,
			0.963,
			0.981,
			1.025,
			1.09,
			1.149,
			1.197,
			1.274,
			1.306,
			1.336,
			1.399,
			1.46,
			1.522,
			1.585,
			1.63,
			1.645,
			1.709,
			1.739,
			1.77,
			1.832,
			1.848,
			1.896,
			1.956,
			2.019,
			2.066,
			2.08,
			2.143,
			2.174,
			2.204,
			2.268,
			2.286,
			2.33,
			2.39,
			2.454,
			2.499,
			2.577,
			2.609,
			2.639,
			2.698,
			2.763,
			2.823,
			2.887,
			2.931,
			2.948,
			3.012,
			3.042,
			3.071,
			3.134,
			3.151,
			3.198,
			3.26,
			3.32,
			3.367,
			3.381,
			3.445,
			3.476,
			3.506,
			3.57,
			3.631,
			3.692,
			3.755,
			3.802,
			3.878,
			3.909,
			3.94,
			4.004,
			4.019,
			4.064,
			4.125,
			4.189,
			4.236,
			4.251,
			4.312,
			4.346,
			4.375,
			4.437,
			4.453,
			4.498,
			4.561,
			4.623,
			4.669,
			4.746,
			4.778,
			4.81,
			4.87,
			4.931,
			4.997,
			5.057,
			5.104,
			5.182,
			5.211,
			5.243,
			5.306,
			5.32,
			5.368,
			5.428,
			5.49,
			5.537,
			5.553,
			5.615,
			5.647,
			5.678,
			5.756,
			5.802,
			5.864,
			5.925,
			5.973,
			6.049,
			6.081,
			6.111,
			6.173,
			6.236,
			6.298,
			6.359,
			6.422,
			6.483,
			6.514,
			6.546,
			6.606,
			6.622,
			6.67,
			6.731,
			6.794,
			6.856,
			6.916,
			6.947,
			6.978,
			7.04,
			7.056,
			7.102,
			7.165,
			7.227,
			7.272,
			7.352,
			7.381,
			7.413,
			7.476,
			7.536,
			7.598,
			7.661,
			7.723,
			7.787,
			7.817,
			7.847,
			7.909,
			7.926,
			7.971,
			8.035,
			8.094,
			8.142,
			8.156,
			8.219,
			8.251,
			8.28,
			8.342,
			8.359,
			8.406,
			8.473,
			8.531,
			8.576,
			8.653,
			8.686,
			8.715,
			8.794,
			8.839,
			8.907,
			8.965,
			9.025,
			9.087,
			9.119,
			9.151,
			9.211,
			9.228,
			9.273,
			9.336,
			9.396,
			9.459,
			9.522,
			9.553,
			9.584,
			9.647,
			9.709,
			9.771,
			9.831,
			9.878,
			9.955,
			9.987,
			10.017,
			10.08,
			10.096,
			10.143,
			10.205,
			10.264,
			10.311,
			10.329,
			10.389,
			10.422,
			10.453,
			10.514,
			10.535,
			10.576,
			10.64,
			10.701,
			10.748,
			10.823,
			10.856,
			10.885,
			10.947,
			11.011,
			11.071,
			11.133,
			11.181,
			11.197,
			11.258,
			11.288,
			11.319,
			11.381,
			11.397,
			11.445,
			11.506,
			11.569,
			11.615,
			11.629,
			11.692,
			11.722,
			11.753,
			11.814,
			11.833,
			11.878,
			11.939,
			12.003,
			12.047,
			12.126,
			12.158,
			12.188,
			12.249,
			12.313,
			12.374,
			12.436,
			12.483,
			12.499,
			12.561,
			12.595,
			12.622,
			12.686,
			12.701,
			12.745,
			12.809,
			12.871,
			12.918,
			12.93,
			12.993,
			13.025,
			13.055,
			13.12,
			13.18,
			13.243,
			13.303,
			13.365,
			13.428,
			13.459,
			13.49,
			13.553,
			13.569,
			13.615,
			13.676,
			13.739,
			13.786,
			13.798,
			13.863,
			13.895,
			13.923,
			14.003,
			14.048,
			14.109,
			14.173,
			14.235,
			14.295,
			14.328,
			14.358,
			14.421,
			14.482,
			14.544,
			14.606,
			14.67,
			14.731,
			14.762,
			14.792,
			14.853,
			14.87,
			14.917,
			14.978,
			15.04,
			15.103,
			15.164,
			15.196,
			15.226,
			15.29,
			15.303,
			15.352,
			15.413,
			15.475,
			15.52,
			15.598,
			15.628,
			15.661,
			15.739,
			15.785,
			15.847,
			15.909,
			15.956,
			15.969,
			16.03,
			16.063,
			16.096,
			16.156,
			16.171,
			16.219,
			16.282,
			16.344,
			16.391,
			16.404,
			16.468,
			16.498,
			16.528,
			16.59,
			16.606,
			16.653,
			16.715,
			16.722,
			16.776,
			16.822,
			16.902,
			16.931,
			16.964,
			17.025,
			17.086,
			17.148,
			17.211,
			17.256,
			17.273,
			17.335,
			17.367,
			17.398,
			17.459,
			17.475,
			17.52,
			17.583,
			17.645,
			17.693,
			17.706,
			17.769,
			17.801,
			17.83,
			17.892,
			17.908,
			17.954,
			18.016,
			18.079,
			18.125,
			18.204,
			18.235,
			18.265,
			18.328,
			18.388,
			18.451,
			18.514,
			18.558,
			18.574,
			18.637,
			18.666,
			18.698,
			18.761,
			18.78,
			18.822,
			18.886,
			18.948,
			18.995,
			19.071,
			19.103,
			19.133,
			19.195,
			19.258,
			19.32,
			19.382,
			19.428,
			19.506,
			19.537,
			19.567,
			19.628,
			19.646,
			19.693,
			19.755,
			19.817,
			19.861,
			19.879,
			19.94,
			19.97,
			20.003,
			20.078,
			20.125,
			20.187,
			20.25,
			20.295,
			20.373,
			20.403,
			20.436,
			20.514,
			20.56,
			20.622,
			20.684,
			20.729,
			20.745,
			20.808,
			20.845,
			20.871,
			20.931,
			20.948,
			20.992,
			21.056,
			21.118,
			21.164,
			21.18,
			21.243,
			21.273,
			21.303,
			21.365,
			21.381,
			21.428,
			21.489,
			21.553,
			21.599,
			21.675,
			21.708,
			21.736,
			21.798,
			21.861,
			21.925,
			21.986,
			22.033,
			22.048,
			22.111,
			22.142,
			22.171,
			22.25,
			22.297,
			22.358,
			22.419,
			22.464,
			22.481,
			22.545,
			22.576,
			22.606,
			22.685,
			22.73,
			22.792,
			22.853,
			22.915,
			22.978,
			23.009,
			23.039,
			23.103,
			23.164,
			23.226,
			23.289,
			23.336,
			23.349,
			23.412,
			23.443,
			23.475,
			23.536,
			23.552,
			23.597,
			23.659,
			23.722,
			23.769,
			23.845,
			23.878,
			23.909,
			23.969,
			24.03,
			24.095,
			24.155,
			24.22,
			24.281,
			24.311,
			24.343,
			24.403,
			24.419,
			24.464,
			24.528,
			24.59,
			24.636,
			24.654,
			24.714,
			24.745,
			24.777,
			24.837,
			24.853,
			24.899,
			24.967,
			25.023,
			25.087,
			25.147,
			25.181,
			25.213,
			25.273,
			25.335,
			25.398,
			25.459,
			25.506,
			25.519,
			25.581,
			25.613,
			25.645,
			25.706,
			25.723,
			25.769,
			25.831,
			25.893,
			25.956,
			26.018,
			26.049,
			26.079,
			26.14,
			26.155,
			26.203,
			26.265,
			26.327,
			26.372,
			26.449,
			26.481,
			26.514,
			26.575,
			26.637,
			26.698,
			26.761,
			26.806,
			26.823,
			26.885,
			26.917,
			26.948,
			27.009,
			27.028,
			27.071,
			27.133,
			27.195,
			27.24,
			27.256,
			27.32,
			27.348,
			27.381,
			27.442,
			27.458,
			27.506,
			27.571,
			27.63,
			27.677,
			27.753,
			27.783,
			27.816,
			27.876,
			27.94,
			28.003,
			28.064,
			28.111,
			28.125,
			28.187,
			28.218,
			28.248,
			28.312,
			28.327,
			28.373,
			28.436,
			28.497,
			28.542,
			28.559,
			28.621,
			28.652,
			28.682,
			28.745,
			28.808,
			28.869,
			28.932,
			28.992,
			29.056,
			29.092,
			29.118,
			29.178,
			29.195,
			29.24,
			29.303,
			29.364,
			29.413,
			29.428,
			29.489,
			29.519,
			29.552,
			29.614,
			29.629,
			29.675,
			29.739,
			29.797,
			29.845,
			29.923,
			29.954,
			29.986,
			30.046,
			30.109,
			30.171,
			30.235,
			30.279,
			30.295,
			30.357,
			30.389,
			30.42,
			30.482,
			30.498,
			30.542,
			30.61,
			30.668,
			30.731,
			30.793,
			30.822,
			30.853,
			30.914,
			30.932,
			30.979,
			31.04,
			31.103,
			31.165,
			31.225,
			31.256,
			31.287,
			31.348,
			31.411,
			31.473,
			31.536,
			31.597,
			31.659,
			31.689,
			31.722,
			31.785,
			31.798,
			31.845,
			31.907,
			31.97,
			32.014,
			32.032,
			32.093,
			32.125,
			32.157,
			32.218,
			32.231,
			32.281,
			32.343,
			32.403,
			32.451,
			32.528,
			32.558,
			32.589,
			32.65,
			32.715,
			32.775,
			32.838,
			32.885,
			32.898,
			32.963,
			32.991,
			33.022,
			33.102,
			33.147,
			33.214,
			33.271,
			33.335,
			33.396,
			33.427,
			33.458,
			33.521,
			33.582,
			33.643,
			33.706,
			33.768,
			33.829,
			33.862,
			33.893,
			33.953,
			33.97,
			34.017,
			34.077,
			34.139,
			34.186,
			34.201,
			34.263,
			34.295,
			34.325,
			34.387,
			34.403,
			34.451,
			34.514,
			34.573,
			34.62,
			34.698,
			34.728,
			34.759,
			34.823,
			34.884,
			34.946,
			35.009,
			35.053,
			35.07,
			35.13,
			35.162,
			35.195,
			35.276,
			35.319,
			35.381,
			35.442,
			35.487,
			35.504,
			35.567,
			35.596,
			35.629,
			35.705,
			35.754,
			35.815,
			35.876,
			35.939,
			35.998,
			36.032,
			36.061,
			36.126,
			36.188,
			36.249,
			36.312,
			36.356,
			36.373,
			36.435,
			36.467,
			36.498,
			36.559,
			36.573,
			36.621,
			36.681,
			36.745,
			36.792,
			36.806,
			36.87,
			36.9,
			36.93,
			36.993,
			37.009,
			37.054,
			37.115,
			37.178,
			37.24,
			37.303,
			37.337,
			37.364,
			37.425,
			37.489,
			37.551,
			37.611,
			37.674,
			37.736,
			37.768,
			37.798,
			37.861,
			37.875,
			37.923,
			37.986,
			38.048,
			38.094,
			38.169,
			38.203,
			38.231,
			38.294,
			38.356,
			38.42,
			38.481,
			38.543,
			38.603,
			38.636,
			38.665,
			38.745,
			38.789,
			38.852,
			38.914,
			38.96,
			38.977,
			39.04,
			39.07,
			39.101,
			39.179,
			39.225,
			39.287,
			39.35,
			39.41,
			39.473,
			39.503,
			39.535,
			39.598,
			39.658,
			39.72,
			39.783,
			39.829,
			39.846,
			39.907,
			39.939,
			39.969,
			40.03,
			40.046,
			40.093,
			40.154,
			40.219,
			40.279,
			40.342,
			40.372,
			40.404,
			40.464,
			40.481,
			40.528,
			40.589,
			40.651,
			40.713,
			40.776,
			40.806,
			40.836,
			40.914,
			40.962,
			41.022,
			41.086,
			41.13,
			41.148,
			41.209,
			41.239,
			41.272,
			41.349,
			41.396,
			41.46,
			41.519,
			41.58,
			41.643,
			41.674,
			41.704,
			41.766,
			41.783,
			41.83,
			41.892,
			41.954,
			41.998,
			42.077,
			42.11,
			42.14,
			42.202,
			42.263,
			42.325,
			42.386,
			42.433,
			42.451,
			42.512,
			42.543,
			42.573,
			42.65,
			42.699,
			42.758,
			42.822,
			42.868,
			42.882,
			42.946,
			42.977,
			43.007,
			43.069,
			43.086,
			43.13,
			43.194,
			43.255,
			43.302,
			43.378,
			43.411,
			43.443,
			43.504,
			43.523,
			43.564,
			43.628,
			43.69,
			43.736,
			43.753,
			43.813,
			43.845,
			43.875,
			43.952,
			43.999,
			44.063,
			44.123,
			44.187,
			44.248,
			44.277,
			44.308,
			44.373,
			44.434,
			44.496,
			44.559,
			44.604,
			44.62,
			44.681,
			44.714,
			44.743,
			44.806,
			44.823,
			44.869,
			44.93,
			44.991,
			45.04,
			45.054,
			45.114,
			45.146,
			45.178,
			45.24,
			45.256,
			45.302,
			45.364,
			45.425,
			45.489,
			45.551,
			45.585,
			45.613,
			45.674,
			45.737,
			45.797,
			45.861,
			45.906,
			45.922,
			45.984,
			46.014,
			46.046,
			46.122,
			46.17,
			46.232,
			46.294,
			46.34,
			46.355,
			46.419,
			46.448,
			46.481,
			46.542,
			46.556,
			46.603,
			46.664,
			46.729,
			46.775,
			46.853,
			46.885,
			46.914,
			46.991,
			47.039,
			47.1,
			47.161,
			47.21,
			47.224,
			47.287,
			47.317,
			47.347,
			47.425,
			47.473,
			47.535,
			47.596,
			47.646,
			47.719,
			47.753,
			47.784,
			47.843,
			47.86,
			47.906,
			47.969,
			48.03,
			48.078,
			48.155,
			48.187,
			48.216,
			48.279,
			48.295,
			48.339,
			48.403,
			48.464,
			48.511,
			48.527,
			48.589,
			48.62,
			48.651,
			48.712,
			48.729,
			48.775,
			48.836,
			48.9,
			48.96,
			49.021,
			49.054,
			49.085,
			49.146,
			49.209,
			49.27,
			49.331,
			49.378,
			49.395,
			49.457,
			49.488,
			49.52,
			49.581,
			49.596,
			49.643,
			49.709,
			49.766,
			49.813,
			49.828,
			49.892,
			49.921,
			49.954,
			50.031,
			50.076,
			50.139,
			50.201,
			50.246,
			50.325,
			50.356,
			50.387,
			50.451,
			50.511,
			50.573,
			50.636,
			50.683,
			50.696,
			50.759,
			50.79,
			50.822,
			50.881,
			50.898,
			50.946,
			51.007,
			51.069,
			51.115,
			51.132,
			51.193,
			51.225,
			51.256,
			51.316,
			51.331,
			51.379,
			51.44,
			51.504,
			51.549,
			51.628,
			51.659,
			51.69,
			51.752,
			51.77,
			51.814,
			51.876,
			51.936,
			51.982,
			51.999,
			52.06,
			52.092,
			52.122,
			52.186,
			52.2,
			52.247,
			52.309,
			52.372,
			52.431,
			52.496,
			52.526,
			52.558,
			52.62,
			52.682,
			52.742,
			52.805,
			52.853,
			52.929,
			52.961,
			52.992,
			53.069,
			53.115,
			53.176,
			53.24,
			53.286,
			53.303,
			53.364,
			53.393,
			53.425,
			53.503,
			53.548,
			53.61,
			53.674,
			53.72,
			53.797,
			53.831,
			53.859,
			53.937,
			53.984,
			54.046,
			54.109,
			54.169,
			54.232,
			54.261,
			54.294,
			54.355,
			54.371,
			54.417,
			54.481,
			54.54,
			54.587,
			54.604,
			54.665,
			54.697,
			54.729,
			54.79,
			54.804,
			54.852,
			54.913,
			54.976,
			55.021,
			55.102,
			55.131,
			55.161,
			55.224,
			55.286,
			55.347,
			55.409,
			55.455,
			55.472,
			55.535,
			55.565,
			55.595,
			55.658,
			55.672,
			55.72,
			55.782,
			55.842,
			55.906,
			55.968,
			55.998,
			56.03,
			56.093,
			56.109,
			56.153,
			56.217,
			56.279,
			56.323,
			56.403,
			56.433,
			56.464,
			56.525,
			56.587,
			56.651,
			56.711,
			56.76,
			56.773,
			56.836,
			56.867,
			56.897,
			56.961,
			56.975,
			57.023,
			57.084,
			57.146,
			57.208,
			57.269,
			57.301,
			57.331,
			57.394,
			57.455,
			57.518,
			57.581,
			57.625,
			57.705,
			57.735,
			57.764,
			57.845,
			57.891,
			57.956,
			58.014,
			58.06,
			58.077,
			58.139,
			58.17,
			58.198,
			58.263,
			58.278,
			58.324,
			58.387,
			58.447,
			58.495,
			58.573,
			58.602,
			58.634,
			58.712,
			58.758,
			58.82,
			58.882,
			58.93,
			59.006,
			59.036,
			59.069,
			59.131,
			59.146,
			59.193,
			59.254,
			59.316,
			59.379,
			59.442,
			59.472,
			59.502,
			59.565,
			59.581,
			59.625,
			59.689,
			59.751,
			59.798,
			59.876,
			59.906,
			59.937,
			59.999,
			60.02,
			60.061,
			60.122,
			60.186,
			60.246,
			60.309,
			60.341,
			60.37,
			60.433,
			60.447,
			60.495,
			60.556,
			60.619,
			60.68,
			60.742,
			60.774,
			60.806,
			60.866,
			60.881,
			60.93,
			60.996,
			61.053,
			61.114,
			61.175,
			61.207,
			61.24,
			61.315,
			61.363,
			61.425,
			61.486,
			61.548,
			61.611,
			61.643,
			61.673,
			61.736,
			61.752,
			61.798,
			61.858,
			61.921,
			61.968,
			61.982,
			62.045,
			62.08,
			62.107,
			62.185,
			62.231,
			62.292,
			62.356,
			62.402,
			62.48,
			62.51,
			62.542,
			62.62,
			62.664,
			62.726,
			62.79,
			62.836,
			62.852,
			62.913,
			62.943,
			62.975,
			63.037,
			63.054,
			63.099,
			63.16,
			63.224,
			63.269,
			63.286,
			63.348,
			63.379,
			63.41,
			63.472,
			63.532,
			63.595,
			63.658,
			63.703,
			63.719,
			63.78,
			63.813,
			63.842,
			63.92,
			63.969,
			64.032,
			64.092,
			64.154,
			64.215,
			64.247,
			64.278,
			64.354,
			64.402,
			64.463,
			64.524,
			64.587,
			64.65,
			64.68,
			64.712,
			64.789,
			64.836,
			64.898,
			64.962,
			65.02,
			65.084,
			65.115,
			65.146,
			65.207,
			65.223,
			65.27,
			65.332,
			65.393,
			65.454,
			65.518,
			65.548,
			65.581,
			65.643,
			65.657,
			65.703,
			65.766,
			65.827,
			65.875,
			65.952,
			65.982,
			66.015,
			66.092,
			66.137,
			66.203,
			66.261,
			66.308,
			66.323,
			66.387,
			66.417,
			66.448,
			66.508,
			66.526,
			66.57,
			66.635,
			66.697,
			66.743,
			66.758,
			66.818,
			66.852,
			66.882,
			66.96,
			67.006,
			67.069,
			67.13,
			67.193,
			67.253,
			67.286,
			67.317,
			67.378,
			67.439,
			67.503,
			67.565,
			67.626,
			67.689,
			67.719,
			67.751,
			67.812,
			67.829,
			67.873,
			67.936,
			67.998,
			68.045,
			68.122,
			68.153,
			68.182,
			68.246,
			68.309,
			68.369,
			68.431,
			68.479,
			68.495,
			68.557,
			68.586,
			68.619,
			68.695,
			68.742,
			68.804,
			68.865,
			68.912,
			68.929,
			68.99,
			69.022,
			69.053,
			69.114,
			69.129,
			69.176,
			69.239,
			69.298,
			69.362,
			69.423,
			69.456,
			69.486,
			69.564,
			69.611,
			69.673,
			69.735,
			69.78,
			69.795,
			69.859,
			69.89,
			69.92,
			69.983,
			69.997,
			70.046,
			70.106,
			70.17,
			70.214,
			70.231,
			70.293,
			70.33,
			70.356,
			70.416,
			70.433,
			70.479,
			70.54,
			70.603,
			70.664,
			70.726,
			70.756,
			70.79,
			70.851,
			70.913,
			70.979,
			71.037,
			71.083,
			71.099,
			71.159,
			71.192,
			71.222,
			71.286,
			71.298,
			71.347,
			71.414,
			71.469,
			71.517,
			71.531,
			71.595,
			71.626,
			71.655,
			71.72,
			71.78,
			71.843,
			71.903,
			71.952,
			72.029,
			72.06,
			72.089,
			72.152,
			72.215,
			72.276,
			72.339,
			72.4,
			72.461,
			72.492,
			72.524,
			72.586,
			72.603,
			72.649,
			72.711,
			72.773,
			72.819,
			72.896,
			72.929,
			72.958,
			73.02,
			73.081,
			73.146,
			73.206,
			73.253,
			73.269,
			73.332,
			73.361,
			73.392,
			73.456,
			73.471,
			73.517,
			73.58,
			73.642,
			73.703,
			73.765,
			73.797,
			73.828,
			73.89,
			73.904,
			73.951,
			74.015,
			74.075,
			74.122,
			74.197,
			74.229,
			74.262,
			74.322,
			74.386,
			74.447,
			74.51,
			74.554,
			74.57,
			74.633,
			74.665,
			74.696,
			74.756,
			74.771,
			74.819,
			74.882,
			74.943,
			74.99,
			75.006,
			75.068,
			75.099,
			75.128,
			75.19,
			75.206,
			75.253,
			75.314,
			75.377,
			75.422,
			75.502,
			75.53,
			75.564,
			75.624,
			75.688,
			75.748,
			75.812,
			75.858,
			75.874,
			75.935,
			75.967,
			75.999,
			76.059,
			76.076,
			76.12,
			76.184,
			76.245,
			76.309,
			76.37,
			76.401,
			76.432,
			76.492,
			76.515,
			76.556,
			76.619,
			76.679,
			76.742,
			76.805,
			76.836,
			76.865,
			76.928,
			76.99,
			77.052,
			77.114,
			77.159,
			77.176,
			77.236,
			77.27,
			77.298,
			77.362,
			77.378,
			77.425,
			77.486,
			77.548,
			77.595,
			77.672,
			77.704,
			77.734,
			77.795,
			77.859,
			77.918,
			77.982,
			78.029,
			78.045,
			78.105,
			78.135,
			78.168,
			78.228,
			78.246,
			78.292,
			78.354,
			78.417,
			78.463,
			78.479,
			78.54,
			78.577,
			78.603,
			78.664,
			78.678,
			78.725,
			78.79,
			78.848,
			78.896,
			78.975,
			79.005,
			79.036,
			79.115,
			79.159,
			79.226,
			79.283,
			79.346,
			79.409,
			79.439,
			79.471,
			79.532,
			79.548,
			79.595,
			79.657,
			79.718,
			79.781,
			79.842,
			79.872,
			79.903,
			79.964,
			79.982,
			80.029,
			80.089,
			80.153,
			80.197,
			80.275,
			80.306,
			80.339,
			80.401,
			80.462,
			80.525,
			80.586,
			80.649,
			80.709,
			80.742,
			80.772,
			80.835,
			80.851,
			80.896,
			80.959,
			81.019,
			81.082,
			81.143,
			81.176,
			81.205,
			81.269,
			81.331,
			81.393,
			81.455,
			81.501,
			81.578,
			81.611,
			81.639,
			81.704,
			81.765,
			81.826,
			81.89,
			81.951,
			82.014,
			82.044,
			82.076,
			82.152,
			82.198,
			82.264,
			82.323,
			82.37,
			82.447,
			82.478,
			82.51,
			82.571,
			82.633,
			82.698,
			82.756,
			82.803,
			82.819,
			82.881,
			82.912,
			82.943,
			83.003,
			83.019,
			83.066,
			83.129,
			83.191,
			83.237,
			83.252,
			83.315,
			83.345,
			83.378,
			83.439,
			83.453,
			83.501,
			83.564,
			83.626,
			83.672,
			83.748,
			83.781,
			83.811,
			83.873,
			83.936,
			83.998,
			84.06,
			84.105,
			84.122,
			84.18,
			84.215,
			84.244,
			84.305,
			84.322,
			84.369,
			84.43,
			84.493,
			84.539,
			84.555,
			84.618,
			84.649,
			84.678,
			84.742,
			84.763,
			84.804,
			84.866,
			84.928,
			84.973,
			85.052,
			85.081,
			85.112,
			85.189,
			85.237,
			85.297,
			85.361,
			85.406,
			85.424,
			85.485,
			85.516,
			85.548,
			85.623,
			85.67,
			85.733,
			85.794,
			85.856,
			85.919,
			85.951,
			85.982,
			86.042,
			86.059,
			86.104,
			86.168,
			86.229,
			86.275,
			86.353,
			86.384,
			86.414,
			86.478,
			86.539,
			86.602,
			86.663,
			86.71,
			86.726,
			86.786,
			86.821,
			86.849,
			86.911,
			86.925,
			86.974,
			87.037,
			87.097,
			87.145,
			87.222,
			87.252,
			87.284,
			87.344,
			87.407,
			87.469,
			87.53,
			87.579,
			87.656,
			87.687,
			87.718,
			87.795,
			87.84,
			87.904,
			87.964,
			88.011,
			88.027,
			88.09,
			88.12,
			88.152,
			88.214,
			88.23,
			88.276,
			88.337,
			88.397,
			88.446,
			88.523,
			88.554,
			88.585,
			88.649,
			88.71,
			88.772,
			88.835,
			88.895,
			88.957,
			88.989,
			89.019,
			89.081,
			89.098,
			89.145,
			89.205,
			89.268,
			89.329,
			89.392,
			89.422,
			89.453,
			89.514,
			89.53,
			89.579,
			89.64,
			89.702,
			89.749,
			89.825,
			89.856,
			89.887,
			89.948,
			90.012,
			90.074,
			90.136,
			90.183,
			90.199,
			90.259,
			90.29,
			90.323,
			90.398,
			90.446,
			90.511,
			90.57,
			90.615,
			90.631,
			90.693,
			90.724,
			90.756,
			90.833,
			90.879,
			90.947,
			91.005,
			91.051,
			91.129,
			91.16,
			91.189,
			91.253,
			91.314,
			91.377,
			91.439,
			91.485,
			91.498,
			91.562,
			91.592,
			91.623,
			91.687,
			91.703,
			91.749,
			91.81,
			91.873,
			91.92,
			91.996,
			92.027,
			92.059,
			92.121,
			92.181,
			92.243,
			92.306,
			92.353,
			92.369,
			92.429,
			92.462,
			92.492,
			92.553,
			92.57,
			92.615,
			92.68,
			92.739,
			92.786,
			92.804,
			92.864,
			92.895,
			92.926,
			92.99,
			93.009,
			93.048,
			93.112,
			93.173,
			93.221,
			93.3,
			93.33,
			93.362,
			93.437,
			93.482,
			93.547,
			93.608,
			93.656,
			93.669,
			93.732,
			93.764,
			93.796,
			93.857,
			93.873,
			93.919,
			93.982,
			94.043,
			94.088,
			94.103,
			94.168,
			94.197,
			94.227,
			94.306,
			94.354,
			94.415,
			94.476,
			94.522,
			94.602,
			94.631,
			94.662,
			94.74,
			94.786,
			94.85,
			94.91,
			94.956,
			94.972,
			95.035,
			95.07,
			95.096,
			95.159,
			95.175,
			95.22,
			95.283,
			95.344,
			95.392,
			95.405,
			95.47,
			95.5,
			95.532,
			95.591,
			95.608,
			95.654,
			95.715,
			95.779,
			95.825,
			95.903,
			95.934,
			95.965,
			96.042,
			96.089,
			96.153,
			96.213,
			96.26,
			96.275,
			96.337,
			96.369,
			96.398,
			96.461,
			96.476,
			96.524,
			96.586,
			96.646,
			96.695,
			96.772,
			96.802,
			96.832,
			96.895,
			96.956,
			97.019,
			97.081,
			97.132,
			97.206,
			97.237,
			97.268,
			97.344,
			97.39,
			97.453,
			97.514,
			97.577,
			97.639,
			97.669,
			97.702,
			97.763,
			97.777,
			97.826,
			97.886,
			97.948,
			97.997,
			98.073,
			98.103,
			98.136,
			98.197,
			98.259,
			98.322,
			98.384,
			98.431,
			98.445,
			98.506,
			98.537,
			98.569,
			98.646,
			98.692,
			98.756,
			98.816,
			98.881,
			98.941,
			98.972,
			99.003,
			99.067,
			99.08,
			99.128,
			99.192,
			99.252,
			99.315,
			99.374,
			99.406,
			99.436,
			99.5,
			99.562,
			99.623,
			99.686,
			99.731,
			99.749,
			99.809,
			99.84,
			99.872,
			99.931,
			99.949,
			99.997,
			100.059,
			100.12,
			100.181,
			100.245,
			100.275,
			100.306,
			100.381,
			100.431,
			100.492,
			100.553,
			100.574
		]
	],
	'3869_3870_7982': [
		[
			143.065
		],
		[
			0
		],
		[
			0,
			0.761,
			4.796,
			6.145,
			7.495,
			11.555,
			14.271,
			16.993,
			21.089,
			23.828,
			27.948,
			29.325,
			30.703,
			34.846,
			37.616,
			41.784,
			44.57,
			47.362,
			48.76,
			51.561,
			52.964,
			55.776,
			60.004,
			62.831,
			65.663,
			69.924,
			71.347,
			72.772,
			77.056,
			78.487,
			79.92,
			84.228,
			87.107,
			91.438,
			94.332,
			97.233,
			101.595,
			103.052,
			104.508,
			108.867,
			111.757,
			116.044,
			118.858,
			123.007,
			125.724,
			128.402,
			129.727,
			133.643,
			136.205,
			138.729,
			142.443,
			144.87,
			148.442,
			149.615,
			150.779,
			154.216,
			156.459,
			159.751,
			161.898,
			164.007,
			165.047,
			167.099,
			168.11,
			169.112,
			172.061,
			173.025,
			174.925,
			176.787,
			178.614,
			180.406,
			181.288,
			183.028,
			184.73,
			185.567,
			187.212,
			188.02,
			189.608,
			191.159,
			193.414,
			194.87,
			196.985,
			197.672,
			198.351,
			200.334,
			201.613,
			203.466,
			204.653,
			206.366,
			207.462,
			207.997,
			209.045,
			210.066,
			210.569,
			211.56,
			212.537,
			213.502,
			214.467,
			214.94,
			216.354,
			216.823,
			217.293,
			218.232,
			218.703,
			219.635,
			220.546,
			221.899,
			222.798,
			224.147,
			224.599,
			225.05,
			226.386,
			227.264,
			228.573,
			229.446,
			230.333,
			230.78,
			231.668,
			232.109,
			232.985,
			233.857,
			234.291,
			235.17,
			236.149,
			237.604,
			238.569,
			240.021,
			240.563,
			241.166,
			242.974,
			244.182,
			246.139,
			247.765,
			249.399,
			251.957,
			252.849,
			253.743,
			257.32,
			258.207,
			260.88,
			262.357,
			263.684,
			264.351,
			265.693,
			266.994,
			267.545,
			269.208,
			270.329,
			271.463,
			273.104,
			274.194,
			275.853,
			276.413,
			276.978,
			278.735,
			279.925,
			281.737,
			282.984,
			284.265,
			284.912,
			286.217,
			286.876,
			287.54,
			289.579,
			290.267,
			290.959,
			293.062,
			294.498,
			296.683,
			298.161,
			299.663,
			300.421,
			301.949,
			302.719,
			304.275,
			305.85,
			308.242,
			309.858,
			312.297,
			313.122,
			313.951,
			316.457,
			318.132,
			320.655,
			322.355,
			324.07,
			324.932,
			326.669,
			327.544,
			329.302,
			331.967,
			333.763,
			335.573,
			338.315,
			339.237,
			340.162,
			342.023,
			343.899,
			344.843,
			346.742,
			347.697,
			349.618,
			351.554,
			354.484,
			356.457,
			359.44,
			360.442,
			361.446,
			364.479,
			366.516,
			369.593,
			371.66,
			374.779,
			376.873,
			378.968,
			380.015,
			383.172,
			385.294,
			387.427,
			390.647,
			392.808,
			396.068,
			397.16,
			398.255,
			401.554,
			403.765,
			405.987,
			409.338,
			411.586,
			414.979,
			416.115,
			417.255,
			420.689,
			421.84,
			422.994,
			426.472,
			428.804,
			431.148,
			432.324,
			434.684,
			437.056,
			438.246,
			440.634,
			441.832,
			444.238,
			446.654,
			450.301,
			452.746,
			456.434,
			457.669,
			458.907,
			462.64,
			465.142,
			468.916,
			471.444,
			473.984,
			477.812,
			479.093,
			480.377,
			484.244,
			485.537,
			486.833,
			490.738,
			493.353,
			495.967,
			497.277,
			499.901,
			502.537,
			503.859,
			507.835,
			510.495,
			513.163,
			517.181,
			519.87,
			523.919,
			525.272,
			526.628,
			530.711,
			533.443,
			537.555,
			540.308,
			544.455,
			547.23,
			548.621,
			551.409,
			554.205,
			555.606,
			558.415,
			561.232,
			564.055,
			566.886,
			568.304,
			572.57,
			573.997,
			575.426,
			578.289,
			579.724,
			582.6,
			586.932,
			589.83,
			592.734,
			597.109,
			598.572,
			600.036,
			604.431,
			607.37,
			611.793,
			614.747,
			617.706,
			619.188,
			622.157,
			623.643,
			626.621,
			631.1,
			634.093,
			637.093,
			641.605,
			644.62,
			649.156,
			650.671,
			652.188,
			656.749,
			659.797,
			664.383,
			667.449,
			670.523,
			675.146,
			676.69,
			678.236,
			682.882,
			684.434,
			685.988,
			690.659,
			693.781,
			696.91,
			698.476,
			701.613,
			703.184,
			706.33,
			709.483,
			711.06,
			714.221,
			717.388,
			722.149,
			725.33,
			730.106,
			731.7,
			733.295,
			738.089,
			741.291,
			746.104,
			749.318,
			752.538,
			757.378,
			758.995,
			760.612,
			767.096,
			770.347,
			773.603,
			776.864,
			780.131,
			781.766,
			785.039,
			788.317,
			789.958,
			794.887,
			798.18,
			801.478,
			806.434,
			809.744,
			814.719,
			816.38,
			818.043,
			823.037,
			826.373,
			831.386,
			834.735,
			838.087,
			839.765,
			843.126,
			844.808,
			848.177,
			851.579,
			853.267,
			856.645,
			860.025,
			863.378,
			866.757,
			868.475,
			873.531,
			875.212,
			876.89,
			880.245,
			881.921,
			885.275,
			888.631,
			893.685,
			897.068,
			902.194,
			903.908,
			905.65,
			910.883,
			914.375,
			919.582,
			923.082,
			926.511,
			928.25,
			931.667,
			933.367,
			936.763,
			940.155,
			941.851,
			945.248,
			948.643,
			953.716,
			955.398,
			957.075,
			962.061,
			963.707,
			965.342,
			970.188,
			973.363,
			976.496,
			981.133,
			984.185,
			988.756,
			990.269,
			991.786,
			996.292,
			997.787,
			999.274,
			1003.716,
			1006.665,
			1011.077,
			1014.01,
			1015.476,
			1018.402,
			1021.316,
			1022.77,
			1025.671,
			1028.562,
			1032.891,
			1034.332,
			1035.771,
			1040.047,
			1041.477,
			1042.905,
			1047.187,
			1050.074,
			1054.369,
			1057.218,
			1060.054,
			1061.494,
			1064.378,
			1065.812,
			1068.68,
			1071.552,
			1072.986,
			1074.423,
			1078.708,
			1081.557,
			1084.389,
			1085.804,
			1088.638,
			1091.487,
			1092.903,
			1095.73,
			1097.146,
			1099.957,
			1102.795,
			1107.062,
			1109.912,
			1114.199,
			1115.631,
			1117.068,
			1121.403,
			1124.351,
			1128.783,
			1131.869,
			1134.964,
			1136.515,
			1139.595,
			1141.137,
			1144.245,
			1147.285,
			1148.782,
			1151.776,
			1154.738,
			1159.148,
			1160.624,
			1162.101,
			1165.05,
			1168.002,
			1169.479,
			1172.43,
			1173.907,
			1176.863,
			1179.822,
			1184.267,
			1187.234,
			1191.694,
			1193.182,
			1194.671,
			1199.145,
			1202.133,
			1206.625,
			1209.63,
			1212.642,
			1214.152,
			1217.176,
			1218.69,
			1221.722,
			1224.76,
			1226.281,
			1229.328,
			1232.382,
			1236.969,
			1238.499,
			1240.031,
			1244.63,
			1246.166,
			1247.704,
			1252.328,
			1255.422,
			1260.071,
			1263.176,
			1266.283,
			1270.95,
			1272.507,
			1274.064,
			1278.741,
			1281.883,
			1286.608,
			1289.764,
			1292.908,
			1294.482,
			1297.632,
			1300.786,
			1302.364,
			1305.522,
			1307.102,
			1310.264,
			1313.429,
			1318.177,
			1321.346,
			1326.105,
			1327.695,
			1329.285,
			1334.062,
			1337.248,
			1342.033,
			1345.227,
			1348.423,
			1353.222,
			1354.824,
			1356.426,
			1361.237,
			1362.843,
			1364.45,
			1369.278,
			1372.5,
			1375.725,
			1377.339,
			1380.569,
			1383.803,
			1385.422,
			1388.663,
			1390.285,
			1393.532,
			1396.782,
			1401.666,
			1404.926,
			1409.826,
			1411.463,
			1413.101,
			1418.023,
			1421.312,
			1426.258,
			1429.562,
			1432.869,
			1434.524,
			1437.835,
			1439.492,
			1442.808,
			1446.126,
			1447.787,
			1449.449,
			1454.44,
			1459.442,
			1461.112,
			1462.784,
			1466.131,
			1469.486,
			1471.164,
			1474.519,
			1476.197,
			1479.549,
			1482.898,
			1487.915,
			1491.258,
			1496.27,
			1497.942,
			1499.614,
			1504.657,
			1507.975,
			1512.997,
			1516.348,
			1521.381,
			1524.74,
			1526.42,
			1529.782,
			1533.146,
			1534.828,
			1538.196,
			1541.568,
			1546.632,
			1548.323,
			1550.014,
			1555.096,
			1556.793,
			1558.492,
			1561.892,
			1563.593,
			1566.998,
			1572.108,
			1575.515,
			1578.924,
			1584.038,
			1585.744,
			1587.449,
			1592.568,
			1594.275,
			1595.983,
			1601.111,
			1604.533,
			1607.959,
			1609.673,
			1613.104,
			1616.538,
			1618.255,
			1621.693,
			1623.415,
			1626.859,
			1630.31,
			1635.49,
			1638.946,
			1644.136,
			1645.867,
			1647.598,
			1652.797,
			1656.266,
			1661.479,
			1664.959,
			1668.442,
			1670.185,
			1673.674,
			1675.419,
			1677.165,
			1682.406,
			1684.155,
			1685.904,
			1691.152,
			1694.651,
			1698.151,
			1699.9,
			1703.4,
			1706.902,
			1708.653,
			1712.158,
			1713.912,
			1717.421,
			1720.933,
			1726.206,
			1729.726,
			1735.012,
			1736.776,
			1738.54,
			1743.837,
			1747.373,
			1752.679,
			1756.221,
			1759.763,
			1761.535,
			1765.081,
			1766.855,
			1770.405,
			1773.957,
			1775.734,
			1777.511,
			1782.846,
			1786.406,
			1789.967,
			1791.748,
			1795.312,
			1798.878,
			1800.662,
			1804.231,
			1806.017,
			1809.59,
			1813.165,
			1818.531,
			1822.112,
			1827.486,
			1829.28,
			1831.073,
			1836.458,
			1840.051,
			1845.444,
			1849.042,
			1852.641,
			1854.442,
			1858.045,
			1859.847,
			1863.454,
			1868.867,
			1872.479,
			1876.092,
			1881.516,
			1885.135,
			1890.568,
			1892.379,
			1894.191,
			1897.817,
			1899.631,
			1903.258,
			1908.705,
			1912.338,
			1915.972,
			1921.427,
			1923.246,
			1925.066,
			1930.529,
			1934.173,
			1939.645,
			1943.295,
			1946.948,
			1948.775,
			1952.431,
			1956.089,
			1957.92,
			1961.582,
			1963.414,
			1967.079,
			1970.745,
			1976.246,
			1979.915,
			1985.419,
			1987.254,
			1989.09,
			1994.599,
			1998.275,
			2003.792,
			2007.472,
			2011.154,
			2012.997,
			2016.681,
			2018.525,
			2020.368,
			2027.745,
			2029.591,
			2035.13,
			2038.826,
			2042.524,
			2044.374,
			2048.075,
			2051.778,
			2053.631,
			2057.338,
			2059.193,
			2062.905,
			2066.618,
			2072.187,
			2075.899,
			2081.468,
			2083.325,
			2085.182,
			2090.749,
			2094.445,
			2099.941,
			2103.559,
			2107.135,
			2108.909,
			2112.426,
			2114.17,
			2117.631,
			2121.054,
			2122.75,
			2126.111,
			2129.431,
			2134.334,
			2135.949,
			2137.553,
			2140.732,
			2143.872,
			2145.427,
			2148.507,
			2150.033,
			2153.058,
			2156.046,
			2160.455,
			2163.344,
			2167.603,
			2169.004,
			2170.394,
			2174.506,
			2177.198,
			2181.163,
			2183.757,
			2186.312,
			2187.575,
			2190.073,
			2191.307,
			2193.748,
			2197.341,
			2199.693,
			2202.01,
			2205.414,
			2206.528,
			2207.633,
			2210.888,
			2211.954,
			2213.01,
			2215.094,
			2216.122,
			2218.149,
			2220.138,
			2223.054,
			2224.952,
			2227.742,
			2228.649,
			2229.549,
			2232.195,
			2233.914,
			2236.427,
			2238.058,
			2239.664,
			2240.461,
			2242.036,
			2242.816,
			2244.37,
			2245.919,
			2246.694,
			2248.24,
			2249.786,
			2252.131,
			2253.709,
			2256.09,
			2256.901,
			2257.712,
			2260.152,
			2261.796,
			2264.277,
			2265.93,
			2268.438,
			2270.104,
			2270.935,
			2271.781,
			2275.147,
			2275.984,
			2278.543,
			2280.239,
			2281.939,
			2282.791,
			2284.489,
			2286.179,
			2287.024,
			2288.707,
			2289.546,
			2291.221,
			2292.881,
			2295.362,
			2297.007,
			2299.46,
			2300.277,
			2301.093,
			2303.517,
			2305.132,
			2307.535,
			2309.129,
			2310.725,
			2311.517,
			2313.077,
			2313.867,
			2315.453,
			2317.027,
			2317.808,
			2319.38,
			2320.966,
			2322.533,
			2324.108,
			2324.9,
			2326.51,
			2328.135,
			2328.952,
			2330.593,
			2331.421,
			2333.135,
			2334.856,
			2337.482,
			2339.266,
			2341.968,
			2342.888,
			2343.808,
			2346.582,
			2348.484,
			2351.341,
			2353.281,
			2355.223,
			2356.195,
			2358.134,
			2359.103,
			2361.043,
			2362.959,
			2363.914,
			2365.823,
			2367.721,
			2370.558,
			2371.503,
			2372.442,
			2375.26,
			2376.2,
			2377.128,
			2379.895,
			2381.737,
			2383.542,
			2386.251,
			2388.034,
			2390.698,
			2391.587,
			2392.471,
			2395.12,
			2396.886,
			2399.544,
			2401.315,
			2403.1,
			2403.993,
			2405.781,
			2406.675,
			2408.474,
			2411.177,
			2412.987,
			2414.802,
			2417.536,
			2419.367,
			2422.125,
			2423.047,
			2423.971,
			2426.758,
			2428.612,
			2431.438,
			2433.336,
			2435.245,
			2438.128,
			2439.093,
			2441.028,
			2443.938,
			2444.909,
			2447.819,
			2449.754,
			2451.682,
			2452.662,
			2454.585,
			2456.501,
			2457.459,
			2459.374,
			2460.331,
			2462.244,
			2464.16,
			2467.031,
			2468.942,
			2471.807,
			2472.761,
			2473.716,
			2476.577,
			2478.492,
			2481.365,
			2483.293,
			2485.228,
			2486.196,
			2488.144,
			2489.124,
			2490.104,
			2493.045,
			2494.031,
			2495.016,
			2497.963,
			2499.924,
			2501.879,
			2502.854,
			2504.801,
			2506.742,
			2507.717,
			2510.648,
			2512.601,
			2514.578,
			2517.544,
			2519.542,
			2522.547,
			2523.542,
			2524.538,
			2527.535,
			2529.509,
			2532.486,
			2534.451,
			2536.426,
			2537.417,
			2539.373,
			2540.353,
			2542.319,
			2544.274,
			2545.253,
			2547.219,
			2549.191,
			2552.165,
			2553.164,
			2554.167,
			2557.192,
			2558.209,
			2559.231,
			2562.311,
			2564.383,
			2566.456,
			2569.572,
			2571.662,
			2574.795,
			2575.868,
			2576.925,
			2580.094,
			2581.162,
			2582.232,
			2585.459,
			2587.642,
			2589.819,
			2590.894,
			2593.088,
			2594.19,
			2596.401,
			2598.623,
			2599.739,
			2601.979,
			2604.233,
			2607.637,
			2608.778,
			2609.923,
			2613.373,
			2614.53,
			2615.688,
			2619.183,
			2621.528,
			2625.064,
			2627.436,
			2629.838,
			2631.033,
			2633.408,
			2634.627,
			2635.829,
			2639.426,
			2640.636,
			2641.849,
			2645.502,
			2647.949,
			2650.404,
			2651.636,
			2654.106,
			2656.584,
			2657.827,
			2661.567,
			2664.072,
			2666.585,
			2670.373,
			2672.909,
			2676.733,
			2678.012,
			2679.294,
			2683.153,
			2685.737,
			2689.63,
			2692.236,
			2694.842,
			2696.147,
			2698.762,
			2700.073,
			2702.7,
			2705.335,
			2706.656,
			2709.303,
			2711.959,
			2714.623,
			2717.294,
			2718.633,
			2721.318,
			2724.01,
			2725.359,
			2728.065,
			2729.42,
			2732.137,
			2734.862,
			2738.965,
			2741.709,
			2745.841,
			2747.221,
			2748.604,
			2752.761,
			2755.531,
			2759.654,
			2762.368,
			2765.045,
			2766.371,
			2768.993,
			2770.292,
			2772.86,
			2775.393,
			2776.646,
			2779.125,
			2781.568,
			2785.167,
			2787.525,
			2790.999,
			2792.139,
			2793.269,
			2795.503,
			2796.606,
			2798.785,
			2800.929,
			2804.078,
			2806.134,
			2809.15,
			2810.138,
			2811.117,
			2814.001,
			2815.876,
			2818.624,
			2820.412,
			2822.163,
			2823.026,
			2824.725,
			2825.558,
			2827.211,
			2828.817,
			2829.608,
			2831.159,
			2832.676,
			2834.895,
			2835.618,
			2836.333,
			2838.449,
			2839.145,
			2839.838,
			2841.897,
			2843.258,
			2845.295,
			2846.658,
			2848.022,
			2850.081,
			2850.78,
			2851.48,
			2853.587,
			2854.292,
			2854.999,
			2857.166,
			2858.614,
			2860.802,
			2862.276,
			2863.75,
			2864.487,
			2865.969,
			2866.711,
			2868.193,
			2869.673,
			2871.907,
			2873.395,
			2875.631,
			2876.384,
			2877.135,
			2879.388,
			2880.899,
			2883.172,
			2884.686,
			2886.205,
			2886.963,
			2888.477,
			2889.233,
			2890.748,
			2892.262,
			2893.019,
			2894.531,
			2896.052,
			2897.578,
			2899.103,
			2899.866,
			2901.409,
			2902.958,
			2903.732,
			2905.282,
			2906.068,
			2907.64,
			2909.212,
			2911.579,
			2913.158,
			2915.527,
			2916.318,
			2917.109,
			2919.48,
			2920.271,
			2921.064,
			2923.442,
			2925.024,
			2926.61,
			2927.403,
			2928.987,
			2929.779,
			2931.368,
			2932.956,
			2933.75,
			2935.339,
			2936.937,
			2939.331,
			2940.928,
			2942.536,
			2944.14,
			2944.943,
			2946.551,
			2947.355,
			2948.964,
			2950.577,
			2953.001,
			2954.621,
			2957.055,
			2957.866,
			2958.678,
			2961.126,
			2962.768,
			2965.244,
			2966.914,
			2968.596,
			2969.44,
			2971.135,
			2971.984,
			2973.687,
			2975.401,
			2976.259,
			2977.966,
			2979.684,
			2982.268,
			2983.993,
			2986.601,
			2987.468,
			2988.337,
			2990.969,
			2992.717,
			2995.361,
			2997.127,
			2998.891,
			3001.556,
			3002.445,
			3003.336,
			3006.923,
			3007.831,
			3010.561,
			3012.407,
			3014.271,
			3015.203,
			3017.081,
			3018.979,
			3019.929,
			3021.837,
			3022.799,
			3024.727,
			3026.659,
			3029.573,
			3031.523,
			3034.454,
			3035.435,
			3036.418,
			3039.362,
			3041.339,
			3044.311,
			3046.308,
			3048.294,
			3049.3,
			3051.322,
			3052.335,
			3054.369,
			3057.442,
			3059.502,
			3061.569,
			3063.635,
			3066.717,
			3068.75,
			3070.756,
			3071.748,
			3073.706,
			3074.672,
			3076.577,
			3078.448,
			3081.188,
			3082.972,
			3085.604,
			3086.463,
			3087.317,
			3089.86,
			3091.538,
			3094.051,
			3095.723,
			3097.397,
			3098.236,
			3099.915,
			3100.754,
			3102.434,
			3104.114,
			3104.954,
			3106.633,
			3108.308,
			3110.815,
			3111.651,
			3112.486,
			3114.978,
			3115.809,
			3116.641,
			3119.141,
			3120.81,
			3122.487,
			3125.038,
			3126.746,
			3129.377,
			3130.263,
			3131.152,
			3133.877,
			3136.667,
			3138.565,
			3140.497,
			3142.439,
			3143.429,
			3145.421,
			3147.426,
			3148.44,
			3151.503,
			3153.559,
			3155.624,
			3158.729,
			3159.757,
			3160.789,
			3163.878,
			3164.9,
			3165.925,
			3168.984,
			3171.008,
			3174.038,
			3176.046,
			3178.06,
			3181.062,
			3182.067,
			3183.073,
			3186.063,
			3187.062,
			3188.06,
			3191.023,
			3192.999,
			3194.969,
			3195.954,
			3197.919,
			3199.899,
			3200.889,
			3202.866,
			3203.861,
			3205.864,
			3207.868,
			3210.902,
			3212.935,
			3215.998,
			3217.024,
			3218.053,
			3221.155,
			3223.237,
			3226.372,
			3228.469,
			3230.569,
			3231.633,
			3233.767,
			3234.841,
			3236.997,
			3240.254,
			3241.346,
			3244.64,
			3246.852,
			3250.19,
			3252.428,
			3254.677,
			3255.803,
			3258.066,
			3259.201,
			3261.477,
			3263.764,
			3267.217,
			3269.533,
			3273.028,
			3274.198,
			3275.372,
			3278.908,
			3281.28,
			3284.859,
			3287.26,
			3289.673,
			3290.883,
			3293.313,
			3294.532,
			3296.979,
			3299.437,
			3300.67,
			3303.144,
			3305.629,
			3309.377,
			3310.631,
			3311.888,
			3314.409,
			3316.941,
			3318.21,
			3322.032,
			3324.592,
			3327.162,
			3331.034,
			3333.628,
			3337.536,
			3338.843,
			3340.149,
			3344.075,
			3346.702,
			3350.658,
			3353.307,
			3355.964,
			3357.296,
			3359.967,
			3362.645,
			3363.988,
			3366.679,
			3368.027,
			3370.731,
			3373.443,
			3377.527,
			3380.26,
			3384.375,
			3385.751,
			3387.129,
			3391.277,
			3394.054,
			3396.839,
			3401.031,
			3403.835,
			3408.058,
			3409.469,
			3410.883,
			3415.136,
			3416.558,
			3417.982,
			3422.266,
			3425.132,
			3429.445,
			3432.33,
			3435.222,
			3436.671,
			3439.574,
			3441.029,
			3443.943,
			3446.866,
			3451.263,
			3454.204,
			3458.63,
			3460.109,
			3461.59,
			3466.044,
			3469.024,
			3473.507,
			3476.505,
			3479.511,
			3484.033,
			3485.544,
			3487.056,
			3491.606,
			3493.126,
			3494.647,
			3499.222,
			3502.281,
			3506.88,
			3509.954,
			3513.034,
			3514.576,
			3517.667,
			3519.216,
			3522.319,
			3525.43,
			3530.11,
			3533.24,
			3537.948,
			3539.52,
			3541.093,
			3545.8,
			3548.915,
			3553.533,
			3556.567,
			3559.564,
			3561.048,
			3563.989,
			3565.445,
			3568.33,
			3572.587,
			3575.379,
			3578.133,
			3582.197,
			3583.533,
			3584.86,
			3588.788,
			3590.079,
			3591.361,
			3593.899,
			3595.154,
			3597.639,
			3600.089,
			3603.704,
			3606.074,
			3609.589,
			3610.737,
			3611.876,
			3615.245,
			3617.443,
			3620.677,
			3622.788,
			3625.895,
			3627.931,
			3628.943,
			3630.959,
			3632.971,
			3633.976,
			3635.992,
			3638.016,
			3641.055,
			3642.073,
			3643.091,
			3646.15,
			3647.175,
			3648.201,
			3651.279,
			3653.335,
			3656.421,
			3658.48,
			3660.54,
			3663.626,
			3664.654,
			3665.682,
			3668.765,
			3669.793,
			3670.823,
			3673.926,
			3676.003,
			3678.099,
			3679.149,
			3681.258,
			3683.384,
			3684.451,
			3686.596,
			3687.675,
			3689.843,
			3692.026,
			3695.328,
			3697.549,
			3700.903,
			3702.025,
			3703.149,
			3706.527,
			3708.78,
			3712.165,
			3714.419,
			3716.678,
			3717.804,
			3720.061,
			3721.192,
			3723.451,
			3726.846,
			3727.977,
			3731.373,
			3733.637,
			3737.042,
			3739.312,
			3741.591,
			3742.731,
			3745.01,
			3746.153,
			3748.438,
			3750.722,
			3754.153,
			3756.439,
			3759.873,
			3761.018,
			3762.165,
			3765.626,
			3767.941,
			3771.427,
			3773.751,
			3776.071,
			3777.23,
			3779.542,
			3780.696,
			3782.998,
			3785.301,
			3786.452,
			3788.753,
			3791.059,
			3794.514,
			3795.666,
			3796.817,
			3799.118,
			3801.416,
			3802.565,
			3804.858,
			3806.004,
			3808.295,
			3810.581,
			3814.015,
			3816.307,
			3819.759,
			3820.912,
			3822.068,
			3825.548,
			3827.877,
			3831.393,
			3833.752,
			3836.125,
			3837.316,
			3839.709,
			3840.914,
			3843.329,
			3845.76,
			3846.979,
			3849.424,
			3851.884,
			3855.593,
			3856.836,
			3858.081,
			3861.836,
			3863.093,
			3864.353,
			3868.152,
			3870.698,
			3873.254,
			3877.11,
			3879.692,
			3883.588,
			3884.892,
			3886.199,
			3890.128,
			3891.441,
			3892.756,
			3896.715,
			3899.369,
			3903.376,
			3906.062,
			3908.763,
			3910.116,
			3912.835,
			3914.198,
			3916.931,
			3919.672,
			3923.799,
			3926.559,
			3930.713,
			3932.1,
			3933.489,
			3937.664,
			3940.452,
			3944.645,
			3947.446,
			3951.66,
			3954.474,
			3955.883,
			3958.704,
			3961.53,
			3962.943,
			3965.774,
			3968.607,
			3971.446,
			3974.285,
			3975.707,
			3978.554,
			3981.405,
			3982.832,
			3985.688,
			3987.119,
			3989.98,
			3992.846,
			3997.153,
			4000.029,
			4004.33,
			4005.774,
			4007.22,
			4011.589,
			4014.497,
			4018.877,
			4021.816,
			4026.231,
			4029.208,
			4030.701,
			4033.691,
			4036.686,
			4038.186,
			4041.19,
			4044.2,
			4047.242,
			4051.776,
			4054.777,
			4057.807,
			4059.323,
			4062.359,
			4063.879,
			4066.924,
			4069.974,
			4074.56,
			4077.625,
			4082.232,
			4083.771,
			4085.31,
			4089.938,
			4093.033,
			4097.687,
			4100.799,
			4103.919,
			4105.482,
			4108.613,
			4110.181,
			4113.322,
			4116.469,
			4118.044,
			4121.198,
			4124.355,
			4129.101,
			4130.685,
			4132.271,
			4137.032,
			4138.622,
			4140.213,
			4144.991,
			4148.179,
			4152.964,
			4156.159,
			4159.357,
			4164.16,
			4165.763,
			4167.367,
			4172.184,
			4175.4,
			4180.231,
			4183.456,
			4188.301,
			4191.537,
			4194.777,
			4196.399,
			4199.646,
			4201.271,
			4204.514,
			4207.74,
			4212.528,
			4214.105,
			4215.674,
			4220.32,
			4221.85,
			4223.37,
			4227.876,
			4230.835,
			4235.204,
			4238.069,
			4240.897,
			4242.296,
			4245.067,
			4246.438,
			4249.15,
			4253.147,
			4254.46,
			4258.34,
			4260.877,
			4263.376,
			4264.61,
			4267.049,
			4269.45,
			4270.637,
			4272.984,
			4274.145,
			4276.439,
			4278.696,
			4282.033,
			4284.201,
			4287.384,
			4288.428,
			4289.464,
			4292.516,
			4294.512,
			4297.453,
			4299.393,
			4301.313,
			4302.271,
			4304.183,
			4305.135,
			4307.039,
			4308.945,
			4309.898,
			4311.804,
			4313.705,
			4316.55,
			4317.494,
			4318.434,
			4320.303,
			4322.17,
			4323.099,
			4324.951,
			4325.881,
			4327.735,
			4329.583,
			4332.355,
			4334.195,
			4336.958,
			4337.879,
			4338.798,
			4341.556,
			4343.387,
			4346.123,
			4347.944,
			4349.756,
			4350.661,
			4352.472,
			4353.373,
			4355.169,
			4356.967,
			4357.864,
			4359.652,
			4361.441,
			4364.123,
			4365.907,
			4367.702,
			4370.389,
			4373.085,
			4373.985,
			4374.885,
			4377.591,
			4379.399,
			4381.21,
			4383.929,
			4385.745,
			4388.485,
			4389.401,
			4390.317,
			4393.078,
			4394.925,
			4397.703,
			4399.564,
			4401.428,
			4402.361,
			4404.229,
			4405.164,
			4407.036,
			4408.906,
			4409.843,
			4411.722,
			4413.605,
			4416.443,
			4417.395,
			4418.349,
			4421.223,
			4422.187,
			4423.146,
			4426.037,
			4427.972,
			4430.877,
			4432.807,
			4434.748,
			4437.681,
			4438.653,
			4439.629,
			4442.576,
			4444.542,
			4447.506,
			4449.47,
			4452.383,
			4454.292,
			4456.185,
			4457.098,
			4458.946,
			4459.865,
			4461.695,
			4463.537,
			4466.287,
			4468.113,
			4470.876,
			4471.794,
			4472.709,
			4475.469,
			4477.306,
			4480.077,
			4481.931,
			4483.79,
			4484.724,
			4486.593,
			4487.527,
			4489.4,
			4491.274,
			4492.211,
			4493.148,
			4495.977,
			4497.863,
			4499.754,
			4500.7,
			4502.59,
			4504.481,
			4505.425,
			4507.313,
			4508.257,
			4510.154,
			4512.055,
			4514.912,
			4516.821,
			4519.691,
			4520.648,
			4521.605,
			4524.481,
			4526.386,
			4529.245,
			4531.139,
			4533.033,
			4533.98,
			4535.87,
			4536.814,
			4538.707,
			4540.596,
			4541.54,
			4543.43,
			4545.323,
			4548.16,
			4549.109,
			4550.059,
			4552.908,
			4553.862,
			4554.82,
			4557.692,
			4559.621,
			4561.55,
			4564.46,
			4566.403,
			4569.324,
			4570.303,
			4571.277,
			4574.213,
			4576.183,
			4579.153,
			4581.151,
			4583.176,
			4584.192,
			4586.226,
			4587.255,
			4589.319,
			4592.445,
			4594.551,
			4596.667,
			4599.862,
			4602.002,
			4605.228,
			4606.307,
			4607.371,
			4610.629,
			4612.815,
			4616.116,
			4618.332,
			4620.56,
			4623.922,
			4625.048,
			4626.177,
			4629.577,
			4630.716,
			4631.857,
			4635.299,
			4637.606,
			4639.926,
			4641.089,
			4643.423,
			4645.768,
			4646.944,
			4649.303,
			4650.486,
			4652.86,
			4655.243,
			4658.835,
			4661.241,
			4664.869,
			4666.083,
			4667.299,
			4670.961,
			4673.414,
			4677.108,
			4679.58,
			4682.061,
			4683.304,
			4685.795,
			4687.042,
			4688.292,
			4692.036,
			4693.28,
			4695.753,
			4698.201,
			4700.618,
			4702.998,
			4704.174,
			4706.496,
			4708.782,
			4709.91,
			4713.239,
			4715.412,
			4717.548,
			4720.684,
			4722.729,
			4725.729,
			4726.71,
			4727.683,
			4730.551,
			4732.419,
			4735.16,
			4736.95,
			4739.593,
			4741.316,
			4742.169,
			4743.863,
			4745.544,
			4746.381,
			4748.05,
			4749.717,
			4752.212,
			4753.043,
			4753.876,
			4755.542,
			4757.207,
			4758.04,
			4759.709,
			4760.544,
			4762.201,
			4763.861,
			4766.352,
			4768.004,
			4770.488,
			4771.324,
			4772.163,
			4774.679,
			4776.368,
			4778.935,
			4780.648,
			4782.386,
			4783.258,
			4785.003,
			4786.757,
			4787.644,
			4790.307,
			4792.094,
			4793.902,
			4796.62,
			4797.536,
			4798.454,
			4801.209,
			4802.132,
			4803.061,
			4805.846,
			4807.715,
			4810.538,
			4812.428,
			4814.329,
			4817.188,
			4818.144,
			4819.1,
			4821.969,
			4823.863,
			4826.699,
			4828.568,
			4831.351,
			4833.189,
			4834.999,
			4835.903,
			4838.6,
			4840.378,
			4842.16,
			4844.825,
			4846.603,
			4849.286,
			4850.184,
			4851.083,
			4853.792,
			4855.612,
			4858.356,
			4860.219,
			4862.087,
			4863.024,
			4864.93,
			4865.888,
			4866.849,
			4869.753,
			4870.732,
			4872.683,
			4874.66,
			4876.648,
			4878.649,
			4879.653,
			4881.666,
			4883.692,
			4884.709,
			4886.752,
			4887.778,
			4889.839,
			4891.916,
			4895.054,
			4897.158,
			4900.314,
			4901.369,
			4902.427,
			4905.616,
			4907.758,
			4910.991,
			4913.162,
			4916.436,
			4918.631,
			4919.732,
			4921.941,
			4924.16,
			4925.273,
			4927.503,
			4929.742,
			4933.112,
			4934.239,
			4935.388,
			4937.632,
			4939.899,
			4941.036,
			4943.306,
			4944.46,
			4946.732,
			4950.115,
			4952.359,
			4954.582,
			4957.898,
			4958.996,
			4960.092,
			4963.379,
			4965.568,
			4968.862,
			4971.064,
			4973.271,
			4974.379,
			4976.594,
			4977.703,
			4979.929,
			4982.156,
			4983.273,
			4985.512,
			4987.754,
			4991.142,
			4992.273,
			4993.408,
			4996.841,
			4997.989,
			4999.145,
			5002.637,
			5004.981,
			5007.337,
			5010.891,
			5013.271,
			5016.856,
			5018.053,
			5019.25,
			5022.854,
			5025.26,
			5028.877,
			5031.293,
			5033.711,
			5034.921,
			5037.357,
			5039.797,
			5041.015,
			5043.454,
			5044.675,
			5047.127,
			5049.587,
			5053.3,
			5055.787,
			5059.535,
			5060.788,
			5062.032,
			5065.777,
			5068.264,
			5071.994,
			5074.462,
			5076.935,
			5078.165,
			5080.629,
			5081.864,
			5084.331,
			5086.805,
			5088.044,
			5089.284,
			5093.017,
			5095.516,
			5098.021,
			5099.279,
			5101.8,
			5104.312,
			5105.582,
			5108.149,
			5109.427,
			5111.987,
			5114.558,
			5118.428,
			5121.016,
			5124.877,
			5126.175,
			5127.474,
			5131.378,
			5133.989,
			5137.941,
			5140.569,
			5144.523,
			5147.163,
			5148.486,
			5151.132,
			5153.784,
			5155.11,
			5157.765,
			5160.425,
			5164.399,
			5165.735,
			5167.073,
			5171.114,
			5172.454,
			5173.796,
			5176.48,
			5177.823,
			5180.515,
			5183.192,
			5187.274,
			5189.983,
			5194.064,
			5195.406,
			5196.771,
			5200.88,
			5203.628,
			5207.766,
			5210.538,
			5213.315,
			5214.707,
			5217.497,
			5218.897,
			5221.703,
			5224.515,
			5225.923,
			5228.745,
			5231.574,
			5235.829,
			5238.673,
			5242.951,
			5244.38,
			5245.81,
			5250.108,
			5252.982,
			5255.861,
			5260.19,
			5263.081,
			5267.429,
			5268.882,
			5270.336,
			5276.167,
			5277.629,
			5282.024,
			5284.963,
			5287.907,
			5289.381,
			5292.334,
			5295.293,
			5296.775,
			5299.743,
			5301.229,
			5304.205,
			5307.188,
			5311.676,
			5314.673,
			5319.18,
			5320.685,
			5322.191,
			5326.72,
			5329.748,
			5334.305,
			5337.351,
			5340.404,
			5341.933,
			5344.995,
			5346.529,
			5349.6,
			5352.676,
			5354.216,
			5357.298,
			5360.385,
			5363.475,
			5366.568,
			5368.117,
			5371.215,
			5374.317,
			5375.868,
			5378.972,
			5380.526,
			5383.636,
			5388.308,
			5391.429,
			5394.554,
			5399.251,
			5400.819,
			5402.389,
			5407.107,
			5410.261,
			5415.002,
			5418.172,
			5422.939,
			5426.126,
			5427.721,
			5430.912,
			5434.107,
			5435.706,
			5438.906,
			5442.109,
			5446.919,
			5450.129,
			5453.341,
			5456.558,
			5458.167,
			5461.388,
			5462.999,
			5466.225,
			5469.454,
			5474.304,
			5477.541,
			5482.405,
			5484.028,
			5485.652,
			5490.529,
			5493.784,
			5498.675,
			5501.94,
			5505.208,
			5506.843,
			5510.117,
			5511.755,
			5515.034,
			5518.316,
			5519.959,
			5523.247,
			5526.538,
			5531.48,
			5533.13,
			5534.78,
			5539.735,
			5541.389,
			5543.043,
			5548.011,
			5551.325,
			5556.303,
			5559.625,
			5562.952,
			5567.95,
			5569.618,
			5571.286,
			5576.296,
			5577.967,
			5579.64,
			5584.664,
			5588.017,
			5591.373,
			5593.051,
			5596.41,
			5599.773,
			5601.456,
			5606.511,
			5609.887,
			5613.266,
			5618.342,
			5621.729,
			5626.818,
			5628.516,
			5630.215,
			5635.314,
			5638.718,
			5643.857,
			5647.268,
			5650.682,
			5652.361,
			5655.781,
			5660.918,
			5664.345,
			5666.061,
			5667.777,
			5672.963,
			5676.406,
			5679.854,
			5681.581,
			5685.009,
			5688.474,
			5690.209,
			5695.426,
			5698.914,
			5702.443,
			5707.698,
			5711.211,
			5716.457,
			5718.216,
			5719.977,
			5725.292,
			5728.826,
			5734.135,
			5737.646,
			5742.959,
			5746.502,
			5748.303,
			5751.849,
			5755.398,
			5757.172,
			5760.719,
			5764.266,
			5769.559,
			5773.109,
			5778.433,
			5780.237,
			5782.012,
			5785.562,
			5787.336,
			5790.855,
			5794.405,
			5799.729,
			5803.276,
			5808.599,
			5810.374,
			5812.148,
			5817.473,
			5821.022,
			5826.351,
			5829.902,
			5833.455,
			5835.231,
			5838.784,
			5840.562,
			5844.115,
			5847.67,
			5849.448,
			5853.006,
			5856.566,
			5861.912,
			5863.695,
			5865.48,
			5870.843,
			5872.631,
			5874.421,
			5879.797,
			5883.385,
			5888.77,
			5892.364,
			5895.963,
			5901.369,
			5903.173,
			5904.977,
			5910.425,
			5914.036,
			5919.444,
			5923.042,
			5926.631,
			5928.391,
			5931.964,
			5935.526,
			5937.304,
			5940.884,
			5942.628,
			5946.198,
			5949.731,
			5955.016,
			5958.529,
			5963.788,
			5965.536,
			5967.283,
			5972.513,
			5975.958,
			5981.152,
			5984.604,
			5988.048,
			5989.794,
			5993.225,
			5994.934,
			5996.645,
			6003.477,
			6005.179,
			6010.286,
			6013.667,
			6017.02,
			6018.676,
			6021.971,
			6025.246,
			6026.876,
			6030.132,
			6031.759,
			6034.982,
			6038.26,
			6043.136,
			6046.383,
			6051.249,
			6052.87,
			6054.49,
			6059.352,
			6062.597,
			6067.43,
			6070.665,
			6073.917,
			6075.53,
			6078.742,
			6080.346,
			6083.536,
			6088.299,
			6091.463,
			6094.627,
			6097.794,
			6100.977,
			6102.569,
			6105.774,
			6108.986,
			6110.597,
			6113.82,
			6115.432,
			6118.659,
			6121.884,
			6126.705,
			6129.972,
			6134.85,
			6136.479,
			6138.083,
			6142.985,
			6146.261,
			6151.188,
			6154.478,
			6159.422,
			6162.723,
			6164.375,
			6167.681,
			6170.989,
			6172.644,
			6175.956,
			6179.272,
			6184.252,
			6185.913,
			6187.574,
			6192.56,
			6194.223,
			6195.888,
			6200.887,
			6204.225,
			6207.566,
			6212.586,
			6215.937,
			6220.968,
			6222.646,
			6224.326,
			6229.371,
			6232.738,
			6237.795,
			6241.168,
			6244.543,
			6246.232,
			6249.613,
			6251.305,
			6254.693,
			6258.085,
			6259.783,
			6263.18,
			6266.584,
			6271.701,
			6275.12,
			6280.264,
			6281.982,
			6283.701,
			6288.872,
			6292.327,
			6297.517,
			6300.98,
			6304.448,
			6306.183,
			6309.659,
			6311.399,
			6313.14,
			6318.366,
			6320.11,
			6323.601,
			6327.096,
			6330.593,
			6334.091,
			6335.84,
			6339.341,
			6342.84,
			6344.59,
			6348.088,
			6349.837,
			6353.335,
			6356.835,
			6362.087,
			6365.591,
			6370.849,
			6372.602,
			6374.354,
			6379.616,
			6383.126,
			6388.393,
			6391.907,
			6395.423,
			6397.182,
			6400.699,
			6402.459,
			6405.979,
			6409.501,
			6411.26,
			6414.773,
			6418.271,
			6423.472,
			6425.19,
			6426.899,
			6430.286,
			6433.632,
			6435.29,
			6438.574,
			6440.202,
			6443.425,
			6446.607,
			6451.309,
			6454.395,
			6458.953,
			6460.453,
			6461.944,
			6466.362,
			6469.258,
			6473.529,
			6476.328,
			6479.089,
			6480.455,
			6483.16,
			6484.498,
			6487.146,
			6491.045,
			6493.597,
			6496.111,
			6499.81,
			6501.024,
			6502.229,
			6504.61,
			6506.957,
			6508.117,
			6511.543,
			6513.779,
			6517.062,
			6519.201,
			6521.302,
			6524.377,
			6525.381,
			6526.376,
			6529.301,
			6530.255,
			6531.201,
			6533.979,
			6535.786,
			6537.558,
			6538.431,
			6540.152,
			6541.836,
			6542.664,
			6545.094,
			6546.669,
			6548.207,
			6550.443,
			6551.889,
			6553.987,
			6554.667,
			6555.337,
			6557.298,
			6558.563,
			6560.392,
			6561.562,
			6562.697,
			6563.251,
			6564.341,
			6564.869,
			6565.388,
			6567.391,
			6567.876,
			6569.305,
			6570.219,
			6571.111,
			6571.551,
			6572.421,
			6573.286,
			6573.717,
			6574.581,
			6575.012,
			6575.865,
			6576.71,
			6577.971,
			6578.812,
			6580.106,
			6580.55,
			6580.991,
			6582.312,
			6583.194,
			6584.525,
			6585.515,
			6586.525,
			6587.031,
			6588.042,
			6588.548,
			6589.565,
			6590.708,
			6591.311,
			6592.515,
			6593.721,
			6595.535,
			6596.137,
			6596.739,
			6597.947,
			6599.161,
			6599.771,
			6600.936,
			6601.507,
			6602.652,
			6603.802,
			6605.532,
			6606.63,
			6608.287,
			6608.842,
			6609.398,
			6611.061,
			6612.137,
			6613.77,
			6614.854,
			6616.463,
			6617.517,
			6618.038,
			6619.067,
			6620.081,
			6620.584,
			6621.593,
			6622.605,
			6624.095,
			6624.587,
			6625.079,
			6626.569,
			6627.081,
			6627.59,
			6629.099,
			6630.096,
			6631.089,
			6632.719,
			6633.791,
			6635.383,
			6635.912,
			6636.465,
			6638.276,
			6638.873,
			6639.467,
			6641.238,
			6642.464,
			6644.283,
			6645.487,
			6646.688,
			6647.286,
			6649.066,
			6650.245,
			6651.424,
			6653.134,
			6654.253,
			6655.929,
			6656.488,
			6657.042,
			6658.588,
			6659.62,
			6661.172,
			6662.213,
			6663.176,
			6664.614,
			6665.096,
			6666.065,
			6667.042,
			6667.525,
			6667.984,
			6669.373,
			6670.308,
			6671.251,
			6671.728,
			6672.687,
			6673.654,
			6674.142,
			6675.622,
			6676.623,
			6677.638,
			6679.232,
			6680.319,
			6681.978,
			6682.538,
			6683.103,
			6684.861,
			6686.053,
			6687.87,
			6689.11,
			6691.018,
			6692.308,
			6692.96,
			6694.277,
			6695.618,
			6696.294,
			6697.657,
			6699.023,
			6700.419,
			6701.843,
			6702.553,
			6703.982,
			6705.426,
			6706.143,
			6708.344,
			6709.828,
			6712.086,
			6713.61,
			6715.15,
			6717.49,
			6718.277,
			6719.068,
			6721.463,
			6723.077,
			6725.525,
			6727.175,
			6729.676,
			6731.358,
			6732.203,
			6733.896,
			6735.588,
			6736.434,
			6738.134,
			6739.847,
			6742.437,
			6743.306,
			6744.178,
			6746.829,
			6747.712,
			6748.599,
			6751.271,
			6753.059,
			6754.849,
			6757.567,
			6759.383,
			6762.113,
			6763.025,
			6763.938,
			6766.686,
			6767.603,
			6768.52,
			6771.268,
			6773.086,
			6774.924,
			6775.847,
			6777.699,
			6779.565,
			6780.503,
			6782.389,
			6783.336,
			6785.242,
			6787.163,
			6790.067,
			6792.022,
			6794.978,
			6795.969,
			6796.963,
			6799.964,
			6801.978,
			6804.002,
			6807.06,
			6809.112,
			6812.209,
			6813.247,
			6814.288,
			6817.424,
			6818.475,
			6819.523,
			6822.672,
			6824.779,
			6826.896,
			6827.957,
			6830.088,
			6832.229,
			6833.302,
			6835.457,
			6836.537,
			6838.705,
			6840.883,
			6844.168,
			6846.37,
			6849.69,
			6850.801,
			6851.915,
			6855.269,
			6857.517,
			6860.905,
			6863.176,
			6865.457,
			6866.601,
			6868.895,
			6870.045,
			6872.353,
			6874.67,
			6875.833,
			6878.164,
			6880.504,
			6882.853,
			6886.391,
			6888.76,
			6891.138,
			6892.33,
			6894.722,
			6895.92,
			6898.325,
			6900.739,
			6904.377,
			6906.815,
			6910.488,
			6911.717,
			6912.948,
			6916.655,
			6919.138,
			6922.877,
			6925.38,
			6927.892,
			6929.15,
			6931.673,
			6932.938,
			6935.474,
			6938.017,
			6939.291,
			6941.846,
			6944.409,
			6948.268,
			6950.85,
			6953.439,
			6956.036,
			6957.337,
			6961.243,
			6963.849,
			6966.463,
			6970.396,
			6973.028,
			6976.987,
			6978.311,
			6979.636,
			6979.677
		],
		[
			0,
			0.012,
			0.076,
			0.106,
			0.138,
			0.201,
			0.262,
			0.324,
			0.384,
			0.43,
			0.509,
			0.542,
			0.571,
			0.634,
			0.695,
			0.757,
			0.819,
			0.867,
			0.88,
			0.943,
			0.974,
			1.006,
			1.084,
			1.13,
			1.195,
			1.253,
			1.3,
			1.316,
			1.378,
			1.408,
			1.441,
			1.517,
			1.564,
			1.626,
			1.687,
			1.734,
			1.811,
			1.844,
			1.873,
			1.95,
			1.999,
			2.058,
			2.121,
			2.185,
			2.247,
			2.28,
			2.307,
			2.386,
			2.43,
			2.494,
			2.555,
			2.603,
			2.679,
			2.712,
			2.741,
			2.82,
			2.867,
			2.927,
			2.991,
			3.035,
			3.051,
			3.114,
			3.144,
			3.175,
			3.239,
			3.252,
			3.301,
			3.363,
			3.423,
			3.47,
			3.485,
			3.547,
			3.578,
			3.61,
			3.673,
			3.687,
			3.734,
			3.797,
			3.858,
			3.919,
			3.982,
			4.014,
			4.044,
			4.106,
			4.168,
			4.235,
			4.291,
			4.353,
			4.414,
			4.447,
			4.477,
			4.541,
			4.556,
			4.603,
			4.663,
			4.725,
			4.773,
			4.788,
			4.852,
			4.882,
			4.911,
			4.975,
			4.989,
			5.036,
			5.101,
			5.16,
			5.208,
			5.285,
			5.314,
			5.348,
			5.41,
			5.47,
			5.534,
			5.594,
			5.641,
			5.657,
			5.717,
			5.749,
			5.779,
			5.842,
			5.859,
			5.905,
			5.966,
			6.027,
			6.076,
			6.152,
			6.184,
			6.214,
			6.278,
			6.337,
			6.406,
			6.462,
			6.508,
			6.586,
			6.618,
			6.65,
			6.727,
			6.773,
			6.835,
			6.897,
			6.944,
			6.958,
			7.019,
			7.052,
			7.082,
			7.161,
			7.207,
			7.268,
			7.331,
			7.378,
			7.454,
			7.484,
			7.516,
			7.58,
			7.641,
			7.703,
			7.764,
			7.811,
			7.827,
			7.89,
			7.918,
			7.951,
			8.013,
			8.028,
			8.075,
			8.137,
			8.2,
			8.26,
			8.322,
			8.353,
			8.386,
			8.447,
			8.468,
			8.508,
			8.57,
			8.634,
			8.681,
			8.758,
			8.788,
			8.819,
			8.895,
			8.942,
			9.004,
			9.067,
			9.113,
			9.129,
			9.191,
			9.223,
			9.254,
			9.331,
			9.378,
			9.44,
			9.502,
			9.547,
			9.562,
			9.624,
			9.655,
			9.686,
			9.75,
			9.764,
			9.81,
			9.875,
			9.935,
			9.981,
			10.06,
			10.09,
			10.121,
			10.183,
			10.246,
			10.308,
			10.37,
			10.43,
			10.492,
			10.528,
			10.556,
			10.634,
			10.679,
			10.74,
			10.803,
			10.863,
			10.927,
			10.957,
			10.989,
			11.067,
			11.112,
			11.175,
			11.237,
			11.301,
			11.362,
			11.393,
			11.423,
			11.486,
			11.502,
			11.547,
			11.612,
			11.672,
			11.718,
			11.734,
			11.795,
			11.827,
			11.857,
			11.918,
			11.935,
			11.983,
			12.043,
			12.104,
			12.153,
			12.228,
			12.262,
			12.291,
			12.352,
			12.416,
			12.478,
			12.541,
			12.6,
			12.662,
			12.693,
			12.724,
			12.786,
			12.803,
			12.851,
			12.911,
			12.973,
			13.019,
			13.034,
			13.097,
			13.129,
			13.159,
			13.238,
			13.283,
			13.345,
			13.407,
			13.455,
			13.531,
			13.561,
			13.594,
			13.656,
			13.718,
			13.781,
			13.841,
			13.903,
			13.967,
			13.998,
			14.029,
			14.09,
			14.106,
			14.153,
			14.218,
			14.275,
			14.322,
			14.338,
			14.401,
			14.431,
			14.463,
			14.525,
			14.54,
			14.586,
			14.653,
			14.711,
			14.757,
			14.833,
			14.864,
			14.895,
			14.958,
			15.02,
			15.084,
			15.143,
			15.192,
			15.207,
			15.268,
			15.297,
			15.331,
			15.408,
			15.453,
			15.518,
			15.579,
			15.641,
			15.702,
			15.733,
			15.763,
			15.827,
			15.888,
			15.951,
			16.011,
			16.058,
			16.135,
			16.167,
			16.197,
			16.261,
			16.277,
			16.321,
			16.385,
			16.447,
			16.493,
			16.509,
			16.571,
			16.601,
			16.633,
			16.695,
			16.714,
			16.756,
			16.818,
			16.88,
			16.943,
			17.006,
			17.037,
			17.067,
			17.129,
			17.191,
			17.254,
			17.314,
			17.361,
			17.439,
			17.47,
			17.5,
			17.578,
			17.626,
			17.687,
			17.748,
			17.794,
			17.811,
			17.873,
			17.903,
			17.934,
			18.013,
			18.057,
			18.121,
			18.184,
			18.243,
			18.305,
			18.337,
			18.368,
			18.43,
			18.491,
			18.553,
			18.617,
			18.663,
			18.677,
			18.741,
			18.774,
			18.803,
			18.865,
			18.881,
			18.927,
			18.987,
			19.051,
			19.096,
			19.111,
			19.176,
			19.206,
			19.237,
			19.3,
			19.316,
			19.361,
			19.423,
			19.485,
			19.531,
			19.608,
			19.641,
			19.672,
			19.733,
			19.796,
			19.857,
			19.919,
			19.967,
			19.981,
			20.042,
			20.075,
			20.106,
			20.168,
			20.182,
			20.23,
			20.291,
			20.354,
			20.397,
			20.414,
			20.478,
			20.508,
			20.54,
			20.601,
			20.663,
			20.725,
			20.787,
			20.836,
			20.911,
			20.941,
			20.974,
			21.035,
			21.051,
			21.098,
			21.158,
			21.221,
			21.283,
			21.344,
			21.375,
			21.408,
			21.47,
			21.484,
			21.53,
			21.594,
			21.654,
			21.7,
			21.717,
			21.78,
			21.81,
			21.842,
			21.904,
			21.963,
			22.03,
			22.089,
			22.136,
			22.152,
			22.212,
			22.243,
			22.276,
			22.338,
			22.353,
			22.4,
			22.463,
			22.525,
			22.57,
			22.586,
			22.646,
			22.678,
			22.711,
			22.77,
			22.786,
			22.833,
			22.895,
			22.956,
			23.019,
			23.082,
			23.111,
			23.142,
			23.221,
			23.268,
			23.329,
			23.393,
			23.439,
			23.453,
			23.516,
			23.547,
			23.578,
			23.641,
			23.654,
			23.703,
			23.762,
			23.826,
			23.871,
			23.889,
			23.949,
			23.979,
			24.012,
			24.075,
			24.09,
			24.137,
			24.197,
			24.26,
			24.321,
			24.384,
			24.414,
			24.446,
			24.524,
			24.571,
			24.63,
			24.695,
			24.74,
			24.755,
			24.818,
			24.849,
			24.878,
			24.941,
			24.961,
			25.003,
			25.064,
			25.127,
			25.175,
			25.19,
			25.253,
			25.284,
			25.315,
			25.376,
			25.438,
			25.501,
			25.562,
			25.624,
			25.687,
			25.717,
			25.749,
			25.81,
			25.871,
			25.934,
			25.997,
			26.041,
			26.058,
			26.12,
			26.151,
			26.182,
			26.244,
			26.261,
			26.306,
			26.368,
			26.43,
			26.477,
			26.555,
			26.584,
			26.616,
			26.677,
			26.741,
			26.804,
			26.864,
			26.91,
			26.989,
			27.022,
			27.05,
			27.112,
			27.127,
			27.175,
			27.237,
			27.298,
			27.345,
			27.361,
			27.423,
			27.453,
			27.485,
			27.546,
			27.563,
			27.609,
			27.671,
			27.734,
			27.778,
			27.857,
			27.886,
			27.919,
			27.98,
			28.041,
			28.105,
			28.167,
			28.214,
			28.227,
			28.29,
			28.322,
			28.353,
			28.414,
			28.431,
			28.476,
			28.539,
			28.601,
			28.646,
			28.662,
			28.724,
			28.755,
			28.788,
			28.847,
			28.864,
			28.911,
			28.971,
			29.035,
			29.098,
			29.158,
			29.189,
			29.22,
			29.283,
			29.344,
			29.408,
			29.468,
			29.529,
			29.591,
			29.624,
			29.654,
			29.717,
			29.731,
			29.778,
			29.842,
			29.903,
			29.947,
			29.964,
			30.028,
			30.058,
			30.09,
			30.151,
			30.167,
			30.214,
			30.276,
			30.338,
			30.384,
			30.462,
			30.491,
			30.523,
			30.586,
			30.602,
			30.646,
			30.71,
			30.772,
			30.818,
			30.833,
			30.894,
			30.926,
			30.956,
			31.02,
			31.034,
			31.081,
			31.147,
			31.206,
			31.268,
			31.33,
			31.359,
			31.392,
			31.454,
			31.514,
			31.577,
			31.639,
			31.685,
			31.701,
			31.764,
			31.795,
			31.825,
			31.886,
			31.903,
			31.948,
			32.012,
			32.073,
			32.121,
			32.135,
			32.196,
			32.228,
			32.259,
			32.322,
			32.337,
			32.384,
			32.446,
			32.507,
			32.554,
			32.632,
			32.663,
			32.692,
			32.754,
			32.818,
			32.884,
			32.942,
			32.988,
			33.004,
			33.066,
			33.096,
			33.128,
			33.188,
			33.207,
			33.25,
			33.313,
			33.375,
			33.421,
			33.437,
			33.5,
			33.529,
			33.561,
			33.624,
			33.638,
			33.684,
			33.747,
			33.808,
			33.856,
			33.934,
			33.966,
			33.997,
			34.058,
			34.118,
			34.181,
			34.245,
			34.289,
			34.307,
			34.368,
			34.398,
			34.429,
			34.508,
			34.553,
			34.617,
			34.676,
			34.739,
			34.802,
			34.831,
			34.862,
			34.925,
			34.941,
			34.986,
			35.051,
			35.111,
			35.158,
			35.236,
			35.272,
			35.297,
			35.358,
			35.422,
			35.485,
			35.546,
			35.592,
			35.609,
			35.669,
			35.701,
			35.731,
			35.793,
			35.81,
			35.857,
			35.919,
			35.978,
			36.04,
			36.103,
			36.136,
			36.167,
			36.227,
			36.289,
			36.353,
			36.412,
			36.46,
			36.476,
			36.537,
			36.569,
			36.6,
			36.677,
			36.725,
			36.786,
			36.846,
			36.895,
			36.909,
			36.973,
			37.003,
			37.034,
			37.097,
			37.111,
			37.157,
			37.22,
			37.281,
			37.331,
			37.406,
			37.437,
			37.467,
			37.53,
			37.593,
			37.654,
			37.716,
			37.763,
			37.777,
			37.841,
			37.872,
			37.901,
			37.962,
			37.98,
			38.027,
			38.089,
			38.151,
			38.197,
			38.213,
			38.274,
			38.306,
			38.336,
			38.399,
			38.413,
			38.459,
			38.522,
			38.585,
			38.632,
			38.708,
			38.74,
			38.77,
			38.831,
			38.894,
			38.958,
			39.019,
			39.063,
			39.081,
			39.142,
			39.174,
			39.203,
			39.282,
			39.328,
			39.39,
			39.451,
			39.497,
			39.514,
			39.577,
			39.608,
			39.638,
			39.7,
			39.714,
			39.762,
			39.825,
			39.887,
			39.934,
			40.011,
			40.042,
			40.073,
			40.135,
			40.195,
			40.259,
			40.32,
			40.368,
			40.38,
			40.445,
			40.474,
			40.508,
			40.569,
			40.584,
			40.63,
			40.694,
			40.756,
			40.801,
			40.878,
			40.908,
			40.941,
			41.018,
			41.066,
			41.127,
			41.189,
			41.251,
			41.313,
			41.343,
			41.374,
			41.458,
			41.499,
			41.561,
			41.622,
			41.669,
			41.685,
			41.747,
			41.778,
			41.808,
			41.87,
			41.886,
			41.93,
			41.996,
			42.055,
			42.102,
			42.182,
			42.211,
			42.242,
			42.32,
			42.368,
			42.431,
			42.492,
			42.537,
			42.553,
			42.616,
			42.646,
			42.678,
			42.74,
			42.754,
			42.801,
			42.867,
			42.925,
			42.97,
			42.988,
			43.049,
			43.079,
			43.11,
			43.172,
			43.188,
			43.236,
			43.295,
			43.359,
			43.406,
			43.484,
			43.518,
			43.544,
			43.608,
			43.668,
			43.732,
			43.795,
			43.841,
			43.855,
			43.918,
			43.947,
			43.978,
			44.04,
			44.058,
			44.103,
			44.166,
			44.228,
			44.273,
			44.289,
			44.351,
			44.382,
			44.414,
			44.49,
			44.537,
			44.6,
			44.66,
			44.708,
			44.784,
			44.818,
			44.847,
			44.925,
			44.97,
			45.034,
			45.095,
			45.141,
			45.159,
			45.22,
			45.251,
			45.282,
			45.358,
			45.405,
			45.468,
			45.53,
			45.58,
			45.652,
			45.684,
			45.716,
			45.778,
			45.839,
			45.903,
			45.964,
			46.011,
			46.088,
			46.118,
			46.151,
			46.228,
			46.273,
			46.337,
			46.396,
			46.445,
			46.459,
			46.521,
			46.553,
			46.584,
			46.645,
			46.66,
			46.709,
			46.77,
			46.832,
			46.878,
			46.956,
			46.987,
			47.019,
			47.081,
			47.141,
			47.204,
			47.267,
			47.313,
			47.326,
			47.391,
			47.419,
			47.45,
			47.512,
			47.529,
			47.575,
			47.642,
			47.697,
			47.747,
			47.761,
			47.823,
			47.856,
			47.885,
			47.961,
			48.01,
			48.071,
			48.135,
			48.178,
			48.258,
			48.289,
			48.322,
			48.395,
			48.443,
			48.506,
			48.568,
			48.614,
			48.629,
			48.692,
			48.723,
			48.755,
			48.817,
			48.833,
			48.878,
			48.941,
			49.003,
			49.049,
			49.063,
			49.126,
			49.157,
			49.188,
			49.251,
			49.313,
			49.374,
			49.437,
			49.482,
			49.56,
			49.592,
			49.623,
			49.684,
			49.705,
			49.747,
			49.808,
			49.869,
			49.917,
			49.93,
			49.995,
			50.025,
			50.056,
			50.118,
			50.135,
			50.178,
			50.243,
			50.303,
			50.351,
			50.368,
			50.427,
			50.46,
			50.491,
			50.552,
			50.615,
			50.677,
			50.737,
			50.784,
			50.801,
			50.863,
			50.893,
			50.926,
			50.986,
			51.003,
			51.048,
			51.111,
			51.172,
			51.219,
			51.236,
			51.295,
			51.328,
			51.358,
			51.436,
			51.481,
			51.545,
			51.608,
			51.652,
			51.73,
			51.767,
			51.792,
			51.869,
			51.918,
			51.98,
			52.04,
			52.087,
			52.104,
			52.164,
			52.196,
			52.226,
			52.29,
			52.305,
			52.351,
			52.414,
			52.475,
			52.522,
			52.536,
			52.598,
			52.629,
			52.66,
			52.722,
			52.738,
			52.785,
			52.847,
			52.907,
			52.954,
			53.034,
			53.063,
			53.095,
			53.158,
			53.22,
			53.285,
			53.343,
			53.389,
			53.404,
			53.468,
			53.496,
			53.53,
			53.59,
			53.606,
			53.653,
			53.718,
			53.777,
			53.828,
			53.902,
			53.932,
			53.964,
			54.025,
			54.041,
			54.087,
			54.149,
			54.214,
			54.259,
			54.335,
			54.364,
			54.395,
			54.46,
			54.521,
			54.584,
			54.644,
			54.69,
			54.708,
			54.769,
			54.8,
			54.83,
			54.894,
			54.91,
			54.955,
			55.018,
			55.08,
			55.124,
			55.141,
			55.203,
			55.235,
			55.266,
			55.328,
			55.39,
			55.453,
			55.513,
			55.575,
			55.638,
			55.668,
			55.7,
			55.762,
			55.776,
			55.824,
			55.891,
			55.945,
			56.01,
			56.07,
			56.103,
			56.134,
			56.196,
			56.21,
			56.257,
			56.32,
			56.382,
			56.427,
			56.506,
			56.536,
			56.567,
			56.63,
			56.693,
			56.753,
			56.814,
			56.863,
			56.878,
			56.941,
			56.97,
			57.001,
			57.065,
			57.079,
			57.126,
			57.186,
			57.247,
			57.296,
			57.311,
			57.375,
			57.403,
			57.436,
			57.498,
			57.514,
			57.56,
			57.621,
			57.685,
			57.728,
			57.808,
			57.838,
			57.871,
			57.931,
			57.952,
			57.993,
			58.057,
			58.118,
			58.163,
			58.179,
			58.242,
			58.273,
			58.304,
			58.366,
			58.382,
			58.427,
			58.49,
			58.552,
			58.614,
			58.675,
			58.706,
			58.736,
			58.797,
			58.814,
			58.86,
			58.924,
			58.984,
			59.033,
			59.108,
			59.14,
			59.17,
			59.234,
			59.294,
			59.357,
			59.42,
			59.467,
			59.481,
			59.544,
			59.576,
			59.606,
			59.669,
			59.685,
			59.728,
			59.793,
			59.853,
			59.902,
			59.979,
			60.015,
			60.041,
			60.118,
			60.163,
			60.227,
			60.289,
			60.336,
			60.411,
			60.444,
			60.474,
			60.553,
			60.599,
			60.664,
			60.722,
			60.768,
			60.784,
			60.845,
			60.876,
			60.909,
			60.971,
			60.984,
			61.03,
			61.094,
			61.155,
			61.202,
			61.28,
			61.31,
			61.341,
			61.404,
			61.467,
			61.53,
			61.59,
			61.635,
			61.653,
			61.712,
			61.744,
			61.776,
			61.854,
			61.901,
			61.961,
			62.025,
			62.085,
			62.149,
			62.178,
			62.211,
			62.271,
			62.289,
			62.335,
			62.395,
			62.458,
			62.506,
			62.583,
			62.613,
			62.644,
			62.705,
			62.769,
			62.831,
			62.892,
			62.94,
			62.956,
			63.017,
			63.046,
			63.077,
			63.141,
			63.155,
			63.201,
			63.266,
			63.326,
			63.373,
			63.387,
			63.451,
			63.48,
			63.514,
			63.59,
			63.635,
			63.699,
			63.759,
			63.824,
			63.884,
			63.916,
			63.947,
			64.025,
			64.082,
			64.136,
			64.194,
			64.242,
			64.258,
			64.32,
			64.351,
			64.379,
			64.458,
			64.506,
			64.567,
			64.628,
			64.674,
			64.69,
			64.754,
			64.785,
			64.814,
			64.878,
			64.938,
			65.002,
			65.062,
			65.123,
			65.187,
			65.217,
			65.247,
			65.31,
			65.327,
			65.374,
			65.435,
			65.498,
			65.545,
			65.558,
			65.621,
			65.653,
			65.684,
			65.745,
			65.759,
			65.807,
			65.869,
			65.932,
			65.977,
			66.056,
			66.087,
			66.117,
			66.179,
			66.242,
			66.302,
			66.364,
			66.411,
			66.428,
			66.49,
			66.521,
			66.551,
			66.628,
			66.675,
			66.738,
			66.8,
			66.86,
			66.923,
			66.954,
			66.986,
			67.046,
			67.061,
			67.11,
			67.17,
			67.234,
			67.281,
			67.358,
			67.389,
			67.42,
			67.479,
			67.542,
			67.606,
			67.667,
			67.712,
			67.728,
			67.791,
			67.821,
			67.854,
			67.914,
			67.932,
			67.977,
			68.04,
			68.102,
			68.147,
			68.163,
			68.226,
			68.26,
			68.289,
			68.363,
			68.41,
			68.475,
			68.536,
			68.58,
			68.659,
			68.69,
			68.721,
			68.784,
			68.845,
			68.909,
			68.971,
			69.016,
			69.03,
			69.094,
			69.126,
			69.156,
			69.217,
			69.234,
			69.278,
			69.341,
			69.403,
			69.451,
			69.526,
			69.558,
			69.59,
			69.651,
			69.713,
			69.775,
			69.839,
			69.884,
			69.962,
			69.993,
			70.024,
			70.086,
			70.101,
			70.147,
			70.21,
			70.271,
			70.335,
			70.394,
			70.428,
			70.457,
			70.519,
			70.536,
			70.583,
			70.645,
			70.706,
			70.752,
			70.83,
			70.86,
			70.892,
			70.953,
			71.014,
			71.078,
			71.14,
			71.188,
			71.263,
			71.294,
			71.325,
			71.389,
			71.403,
			71.45,
			71.514,
			71.574,
			71.635,
			71.697,
			71.728,
			71.76,
			71.822,
			71.836,
			71.884,
			71.946,
			72.008,
			72.053,
			72.13,
			72.162,
			72.193,
			72.273,
			72.319,
			72.385,
			72.443,
			72.489,
			72.505,
			72.564,
			72.596,
			72.628,
			72.706,
			72.752,
			72.814,
			72.876,
			72.923,
			72.939,
			73.001,
			73.03,
			73.063,
			73.124,
			73.14,
			73.185,
			73.246,
			73.309,
			73.355,
			73.436,
			73.463,
			73.497,
			73.557,
			73.62,
			73.683,
			73.744,
			73.807,
			73.87,
			73.9,
			73.93,
			73.994,
			74.008,
			74.056,
			74.117,
			74.179,
			74.224,
			74.242,
			74.304,
			74.335,
			74.364,
			74.427,
			74.488,
			74.551,
			74.611,
			74.659,
			74.736,
			74.768,
			74.799,
			74.86,
			74.876,
			74.922,
			74.986,
			75.045,
			75.093,
			75.107,
			75.17,
			75.201,
			75.233,
			75.294,
			75.31,
			75.357,
			75.42,
			75.481,
			75.527,
			75.604,
			75.635,
			75.668,
			75.727,
			75.79,
			75.854,
			75.915,
			75.961,
			75.978,
			76.04,
			76.071,
			76.101,
			76.178,
			76.225,
			76.287,
			76.347,
			76.409,
			76.472,
			76.509,
			76.536,
			76.595,
			76.613,
			76.66,
			76.72,
			76.784,
			76.846,
			76.908,
			76.939,
			76.97,
			77.032,
			77.095,
			77.156,
			77.217,
			77.264,
			77.279,
			77.342,
			77.371,
			77.403,
			77.464,
			77.481,
			77.526,
			77.591,
			77.651,
			77.696,
			77.714,
			77.775,
			77.806,
			77.837,
			77.9,
			77.913,
			77.96,
			78.022,
			78.084,
			78.132,
			78.208,
			78.241,
			78.272,
			78.334,
			78.396,
			78.458,
			78.52,
			78.572,
			78.581,
			78.642,
			78.674,
			78.705,
			78.767,
			78.784,
			78.829,
			78.892,
			78.954,
			79,
			79.016,
			79.078,
			79.107,
			79.14,
			79.201,
			79.262,
			79.325,
			79.387,
			79.434,
			79.512,
			79.541,
			79.572,
			79.636,
			79.651,
			79.699,
			79.76,
			79.821,
			79.884,
			79.946,
			79.976,
			80.008,
			80.069,
			80.087,
			80.13,
			80.195,
			80.255,
			80.303,
			80.38,
			80.41,
			80.441,
			80.504,
			80.565,
			80.634,
			80.69,
			80.751,
			80.814,
			80.845,
			80.876,
			80.937,
			80.953,
			81.001,
			81.062,
			81.124,
			81.169,
			81.186,
			81.247,
			81.279,
			81.309,
			81.372,
			81.387,
			81.433,
			81.497,
			81.559,
			81.604,
			81.682,
			81.714,
			81.744,
			81.82,
			81.869,
			81.931,
			81.991,
			82.053,
			82.116,
			82.145,
			82.179,
			82.241,
			82.255,
			82.303,
			82.364,
			82.426,
			82.487,
			82.548,
			82.582,
			82.612,
			82.673,
			82.693,
			82.737,
			82.798,
			82.861,
			82.906,
			82.984,
			83.014,
			83.047,
			83.109,
			83.171,
			83.23,
			83.295,
			83.342,
			83.356,
			83.418,
			83.448,
			83.48,
			83.541,
			83.558,
			83.605,
			83.666,
			83.728,
			83.774,
			83.791,
			83.852,
			83.883,
			83.914,
			83.991,
			84.039,
			84.102,
			84.161,
			84.208,
			84.287,
			84.318,
			84.346,
			84.411,
			84.471,
			84.534,
			84.595,
			84.659,
			84.719,
			84.755,
			84.782,
			84.844,
			84.858,
			84.905,
			84.97,
			85.031,
			85.075,
			85.092,
			85.153,
			85.186,
			85.216,
			85.279,
			85.342,
			85.408,
			85.464,
			85.512,
			85.528,
			85.588,
			85.619,
			85.651,
			85.728,
			85.775,
			85.841,
			85.897,
			85.945,
			85.961,
			86.022,
			86.053,
			86.086,
			86.147,
			86.161,
			86.21,
			86.271,
			86.334,
			86.394,
			86.457,
			86.489,
			86.519,
			86.58,
			86.644,
			86.705,
			86.768,
			86.818,
			86.828,
			86.891,
			86.921,
			86.954,
			87.013,
			87.031,
			87.077,
			87.139,
			87.201,
			87.246,
			87.262,
			87.325,
			87.355,
			87.386,
			87.45,
			87.464,
			87.51,
			87.572,
			87.636,
			87.681,
			87.76,
			87.791,
			87.82,
			87.884,
			87.944,
			88.007,
			88.07,
			88.116,
			88.132,
			88.192,
			88.223,
			88.256,
			88.317,
			88.331,
			88.378,
			88.448,
			88.503,
			88.566,
			88.626,
			88.688,
			88.751,
			88.782,
			88.814,
			88.895,
			88.936,
			88.999,
			89.062,
			89.106,
			89.184,
			89.217,
			89.248,
			89.31,
			89.37,
			89.434,
			89.494,
			89.541,
			89.556,
			89.62,
			89.65,
			89.679,
			89.743,
			89.759,
			89.804,
			89.867,
			89.93,
			89.975,
			89.992,
			90.054,
			90.084,
			90.114,
			90.176,
			90.239,
			90.301,
			90.361,
			90.41,
			90.487,
			90.517,
			90.547,
			90.625,
			90.674,
			90.736,
			90.797,
			90.861,
			90.92,
			90.956,
			90.984,
			91.045,
			91.061,
			91.108,
			91.169,
			91.23,
			91.277,
			91.354,
			91.386,
			91.417,
			91.481,
			91.541,
			91.605,
			91.665,
			91.711,
			91.728,
			91.79,
			91.82,
			91.851,
			91.913,
			91.93,
			91.974,
			92.039,
			92.1,
			92.145,
			92.161,
			92.222,
			92.255,
			92.284,
			92.348,
			92.364,
			92.411,
			92.473,
			92.534,
			92.596,
			92.659,
			92.689,
			92.721,
			92.782,
			92.842,
			92.907,
			92.967,
			93.018,
			93.03,
			93.092,
			93.123,
			93.154,
			93.214,
			93.232,
			93.278,
			93.341,
			93.401,
			93.447,
			93.465,
			93.527,
			93.556,
			93.587,
			93.666,
			93.711,
			93.775,
			93.837,
			93.881,
			93.959,
			93.99,
			94.021,
			94.086,
			94.145,
			94.209,
			94.271,
			94.318,
			94.334,
			94.394,
			94.425,
			94.456,
			94.533,
			94.581,
			94.643,
			94.703,
			94.751,
			94.827,
			94.86,
			94.891,
			94.968,
			95.014,
			95.082,
			95.137,
			95.186,
			95.263,
			95.294,
			95.324,
			95.387,
			95.401,
			95.448,
			95.511,
			95.572,
			95.618,
			95.635,
			95.695,
			95.726,
			95.759,
			95.82,
			95.837,
			95.88,
			95.944,
			96.007,
			96.051,
			96.129,
			96.161,
			96.193,
			96.254,
			96.317,
			96.378,
			96.441,
			96.487,
			96.501,
			96.566,
			96.595,
			96.626,
			96.689,
			96.703,
			96.751,
			96.814,
			96.875,
			96.922,
			96.936,
			96.996,
			97.029,
			97.06,
			97.141,
			97.186,
			97.247,
			97.309,
			97.354,
			97.432,
			97.463,
			97.494,
			97.555,
			97.619,
			97.682,
			97.742,
			97.806,
			97.868,
			97.898,
			97.93,
			97.99,
			98.007,
			98.052,
			98.114,
			98.176,
			98.224,
			98.239,
			98.3,
			98.329,
			98.362,
			98.424,
			98.441,
			98.487,
			98.545,
			98.612,
			98.658,
			98.735,
			98.766,
			98.797,
			98.874,
			98.921,
			98.984,
			99.045,
			99.092,
			99.106,
			99.168,
			99.203,
			99.231,
			99.307,
			99.355,
			99.417,
			99.478,
			99.524,
			99.541,
			99.604,
			99.635,
			99.665,
			99.727,
			99.789,
			99.852,
			99.914,
			99.959,
			100.036,
			100.068,
			100.098,
			100.175,
			100.223,
			100.286,
			100.347,
			100.409,
			100.472,
			100.503,
			100.534,
			100.609,
			100.656,
			100.719,
			100.78,
			100.827,
			100.904,
			100.937,
			100.967,
			101.029,
			101.09,
			101.153,
			101.213,
			101.266,
			101.279,
			101.339,
			101.37,
			101.4,
			101.465,
			101.478,
			101.526,
			101.587,
			101.647,
			101.697,
			101.71,
			101.773,
			101.804,
			101.835,
			101.897,
			101.911,
			101.961,
			102.02,
			102.084,
			102.146,
			102.207,
			102.238,
			102.27,
			102.33,
			102.392,
			102.456,
			102.518,
			102.58,
			102.641,
			102.673,
			102.705,
			102.766,
			102.782,
			102.828,
			102.889,
			102.952,
			102.997,
			103.012,
			103.074,
			103.107,
			103.137,
			103.2,
			103.213,
			103.263,
			103.328,
			103.386,
			103.431,
			103.511,
			103.541,
			103.571,
			103.649,
			103.695,
			103.762,
			103.819,
			103.867,
			103.88,
			103.943,
			103.974,
			104.006,
			104.068,
			104.084,
			104.128,
			104.194,
			104.253,
			104.3,
			104.314,
			104.379,
			104.41,
			104.441,
			104.502,
			104.562,
			104.625,
			104.687,
			104.75,
			104.811,
			104.842,
			104.874,
			104.937,
			104.996,
			105.061,
			105.121,
			105.168,
			105.184,
			105.245,
			105.277,
			105.308,
			105.37,
			105.39,
			105.43,
			105.496,
			105.556,
			105.604,
			105.679,
			105.71,
			105.742,
			105.803,
			105.864,
			105.929,
			105.989,
			106.036,
			106.053,
			106.114,
			106.144,
			106.177,
			106.239,
			106.252,
			106.3,
			106.364,
			106.424,
			106.471,
			106.486,
			106.547,
			106.579,
			106.61,
			106.671,
			106.689,
			106.734,
			106.795,
			106.858,
			106.905,
			106.981,
			107.014,
			107.044,
			107.106,
			107.169,
			107.23,
			107.292,
			107.355,
			107.416,
			107.45,
			107.477,
			107.54,
			107.556,
			107.603,
			107.665,
			107.727,
			107.773,
			107.788,
			107.851,
			107.881,
			107.911,
			107.975,
			107.99,
			108.036,
			108.101,
			108.161,
			108.205,
			108.284,
			108.315,
			108.346,
			108.425,
			108.471,
			108.532,
			108.595,
			108.641,
			108.658,
			108.72,
			108.748,
			108.782,
			108.842,
			108.859,
			108.905,
			108.967,
			109.029,
			109.09,
			109.154,
			109.184,
			109.214,
			109.276,
			109.338,
			109.4,
			109.463,
			109.514,
			109.585,
			109.619,
			109.647,
			109.726,
			109.772,
			109.834,
			109.896,
			109.944,
			109.958,
			110.022,
			110.053,
			110.084,
			110.146,
			110.16,
			110.206,
			110.268,
			110.331,
			110.376,
			110.454,
			110.487,
			110.518,
			110.578,
			110.64,
			110.702,
			110.765,
			110.81,
			110.828,
			110.889,
			110.922,
			110.951,
			111.012,
			111.029,
			111.076,
			111.137,
			111.2,
			111.244,
			111.262,
			111.324,
			111.353,
			111.384,
			111.448,
			111.462,
			111.51,
			111.576,
			111.633,
			111.679,
			111.758,
			111.789,
			111.819,
			111.895,
			111.942,
			112.004,
			112.068,
			112.128,
			112.192,
			112.223,
			112.254,
			112.313,
			112.33,
			112.378,
			112.44,
			112.502,
			112.564,
			112.624,
			112.656,
			112.686,
			112.747,
			112.764,
			112.812,
			112.873,
			112.934,
			112.983,
			113.06,
			113.091,
			113.12,
			113.184,
			113.246,
			113.307,
			113.368,
			113.414,
			113.431,
			113.493,
			113.524,
			113.555,
			113.618,
			113.638,
			113.678,
			113.742,
			113.805,
			113.85,
			113.865,
			113.927,
			113.957,
			113.989,
			114.067,
			114.114,
			114.176,
			114.238,
			114.285,
			114.362,
			114.393,
			114.424,
			114.486,
			114.502,
			114.546,
			114.61,
			114.671,
			114.719,
			114.734,
			114.795,
			114.826,
			114.859,
			114.936,
			114.982,
			115.044,
			115.106,
			115.153,
			115.229,
			115.26,
			115.291,
			115.369,
			115.414,
			115.478,
			115.54,
			115.587,
			115.601,
			115.662,
			115.726,
			115.789,
			115.803,
			115.85,
			115.911,
			115.973,
			116.019,
			116.035,
			116.097,
			116.128,
			116.159,
			116.237,
			116.284,
			116.346,
			116.408,
			116.454,
			116.531,
			116.564,
			116.593,
			116.656,
			116.718,
			116.779,
			116.842,
			116.905,
			116.966,
			116.995,
			117.029,
			117.09,
			117.107,
			117.152,
			117.213,
			117.277,
			117.338,
			117.401,
			117.431,
			117.461,
			117.525,
			117.539,
			117.586,
			117.647,
			117.709,
			117.762,
			117.834,
			117.864,
			117.895,
			117.975,
			118.019,
			118.084,
			118.145,
			118.19,
			118.207,
			118.268,
			118.3,
			118.328,
			118.391,
			118.408,
			118.455,
			118.518,
			118.585,
			118.625,
			118.64,
			118.703,
			118.734,
			118.764,
			118.827,
			118.887,
			118.951,
			119.012,
			119.06,
			119.137,
			119.168,
			119.199,
			119.259,
			119.323,
			119.384,
			119.448,
			119.493,
			119.508,
			119.572,
			119.603,
			119.633,
			119.695,
			119.71,
			119.756,
			119.823,
			119.882,
			119.926,
			120.005,
			120.035,
			120.067,
			120.127,
			120.191,
			120.253,
			120.314,
			120.362,
			120.377,
			120.438,
			120.47,
			120.5,
			120.578,
			120.624,
			120.686,
			120.749,
			120.795,
			120.811,
			120.874,
			120.903,
			120.935,
			120.996,
			121.011,
			121.058,
			121.119,
			121.183,
			121.23,
			121.307,
			121.336,
			121.369,
			121.43,
			121.493,
			121.555,
			121.617,
			121.664,
			121.678,
			121.741,
			121.77,
			121.801,
			121.886,
			121.928,
			121.988,
			122.051,
			122.096,
			122.113,
			122.174,
			122.205,
			122.236,
			122.3,
			122.314,
			122.36,
			122.423,
			122.484,
			122.53,
			122.609,
			122.64,
			122.672,
			122.75,
			122.794,
			122.858,
			122.918,
			122.982,
			123.044,
			123.073,
			123.105,
			123.167,
			123.184,
			123.228,
			123.291,
			123.353,
			123.4,
			123.413,
			123.477,
			123.508,
			123.539,
			123.601,
			123.664,
			123.724,
			123.787,
			123.834,
			123.912,
			123.946,
			123.973,
			124.051,
			124.098,
			124.164,
			124.22,
			124.268,
			124.284,
			124.345,
			124.375,
			124.408,
			124.47,
			124.484,
			124.533,
			124.597,
			124.656,
			124.702,
			124.78,
			124.811,
			124.841,
			124.903,
			124.966,
			125.029,
			125.09,
			125.136,
			125.153,
			125.214,
			125.243,
			125.276,
			125.336,
			125.353,
			125.401,
			125.462,
			125.523,
			125.57,
			125.585,
			125.646,
			125.677,
			125.709,
			125.772,
			125.786,
			125.834,
			125.896,
			125.958,
			126.007,
			126.08,
			126.114,
			126.143,
			126.204,
			126.267,
			126.331,
			126.392,
			126.437,
			126.454,
			126.514,
			126.547,
			126.577,
			126.64,
			126.654,
			126.701,
			126.763,
			126.827,
			126.871,
			126.887,
			126.947,
			126.98,
			127.014,
			127.075,
			127.089,
			127.135,
			127.199,
			127.261,
			127.305,
			127.384,
			127.414,
			127.447,
			127.506,
			127.569,
			127.63,
			127.693,
			127.74,
			127.756,
			127.818,
			127.849,
			127.881,
			127.956,
			128.004,
			128.07,
			128.129,
			128.176,
			128.19,
			128.251,
			128.283,
			128.314,
			128.377,
			128.438,
			128.501,
			128.561,
			128.608,
			128.686,
			128.717,
			128.747,
			128.81,
			128.826,
			128.871,
			128.935,
			128.995,
			129.043,
			129.059,
			129.121,
			129.151,
			129.182,
			129.26,
			129.307,
			129.367,
			129.429,
			129.477,
			129.555,
			129.584,
			129.616,
			129.678,
			129.741,
			129.802,
			129.864,
			129.91,
			129.926,
			129.987,
			130.018,
			130.051,
			130.132,
			130.173,
			130.238,
			130.297,
			130.345,
			130.361,
			130.421,
			130.452,
			130.485,
			130.545,
			130.561,
			130.607,
			130.669,
			130.734,
			130.778,
			130.856,
			130.887,
			130.917,
			130.996,
			131.042,
			131.104,
			131.166,
			131.213,
			131.23,
			131.291,
			131.321,
			131.353,
			131.414,
			131.432,
			131.477,
			131.538,
			131.601,
			131.646,
			131.663,
			131.724,
			131.756,
			131.786,
			131.847,
			131.863,
			131.91,
			131.972,
			132.034,
			132.08,
			132.16,
			132.193,
			132.22,
			132.296,
			132.343,
			132.407,
			132.469,
			132.53,
			132.592,
			132.624,
			132.655,
			132.717,
			132.73,
			132.778,
			132.842,
			132.902,
			132.947,
			132.965,
			133.027,
			133.058,
			133.09,
			133.167,
			133.214,
			133.275,
			133.337,
			133.384,
			133.461,
			133.492,
			133.523,
			133.586,
			133.602,
			133.647,
			133.708,
			133.77,
			133.834,
			133.896,
			133.926,
			133.956,
			134.033,
			134.081,
			134.142,
			134.204,
			134.254,
			134.329,
			134.361,
			134.39,
			134.467,
			134.516,
			134.577,
			134.638,
			134.686,
			134.762,
			134.793,
			134.826,
			134.887,
			134.903,
			134.95,
			135.01,
			135.074,
			135.118,
			135.135,
			135.197,
			135.229,
			135.259,
			135.336,
			135.384,
			135.444,
			135.508,
			135.553,
			135.633,
			135.662,
			135.692,
			135.755,
			135.817,
			135.878,
			135.942,
			136.004,
			136.066,
			136.095,
			136.127,
			136.19,
			136.205,
			136.252,
			136.318,
			136.374,
			136.423,
			136.439,
			136.5,
			136.529,
			136.561,
			136.639,
			136.686,
			136.751,
			136.809,
			136.857,
			136.935,
			136.963,
			136.995,
			137.058,
			137.12,
			137.183,
			137.244,
			137.306,
			137.369,
			137.399,
			137.429,
			137.493,
			137.507,
			137.555,
			137.617,
			137.678,
			137.724,
			137.741,
			137.801,
			137.833,
			137.862,
			137.941,
			137.987,
			138.05,
			138.111,
			138.159,
			138.235,
			138.267,
			138.298,
			138.36,
			138.379,
			138.421,
			138.484,
			138.545,
			138.593,
			138.607,
			138.672,
			138.701,
			138.731,
			138.793,
			138.808,
			138.854,
			138.917,
			138.981,
			139.04,
			139.104,
			139.136,
			139.166,
			139.226,
			139.288,
			139.351,
			139.414,
			139.462,
			139.537,
			139.57,
			139.6,
			139.661,
			139.677,
			139.724,
			139.786,
			139.847,
			139.896,
			139.911,
			139.973,
			140.003,
			140.035,
			140.096,
			140.111,
			140.159,
			140.219,
			140.282,
			140.328,
			140.405,
			140.441,
			140.468,
			140.53,
			140.592,
			140.654,
			140.717,
			140.763,
			140.777,
			140.84,
			140.871,
			140.903,
			140.964,
			140.981,
			141.026,
			141.087,
			141.15,
			141.211,
			141.274,
			141.305,
			141.335,
			141.399,
			141.412,
			141.459,
			141.521,
			141.585,
			141.629,
			141.707,
			141.74,
			141.771,
			141.832,
			141.894,
			141.957,
			142.017,
			142.064,
			142.08,
			142.143,
			142.174,
			142.203,
			142.267,
			142.283,
			142.327,
			142.392,
			142.451,
			142.514,
			142.575,
			142.609,
			142.639,
			142.702,
			142.762,
			142.826,
			142.886,
			142.948,
			143.011,
			143.04,
			143.072,
			143.065
		]
	],
	'3869_4542_7982': [
		[
			141.499
		],
		[
			0
		],
		[
			0,
			1.793,
			3.349,
			6.465,
			9.587,
			11.151,
			14.283,
			15.851,
			18.992,
			22.139,
			26.871,
			30.033,
			34.787,
			36.375,
			37.964,
			42.74,
			45.931,
			50.719,
			53.917,
			57.121,
			58.724,
			61.936,
			63.544,
			66.765,
			69.992,
			71.607,
			73.224,
			78.082,
			81.327,
			86.204,
			89.461,
			92.723,
			94.356,
			97.625,
			99.262,
			102.538,
			105.82,
			110.753,
			114.048,
			119.001,
			120.654,
			122.309,
			127.283,
			130.605,
			135.599,
			138.935,
			143.949,
			147.298,
			148.974,
			152.33,
			155.691,
			157.373,
			160.739,
			164.108,
			169.164,
			170.851,
			172.567,
			177.634,
			179.324,
			181.015,
			186.096,
			189.492,
			192.893,
			198.025,
			201.468,
			206.673,
			208.433,
			210.194,
			215.477,
			217.239,
			218.969,
			224.234,
			227.745,
			231.163,
			232.907,
			236.318,
			237.988,
			241.371,
			246.399,
			249.745,
			253.028,
			257.878,
			261.061,
			265.762,
			267.307,
			268.843,
			273.385,
			276.36,
			280.746,
			283.635,
			286.493,
			287.925,
			290.772,
			292.191,
			293.617,
			297.871,
			299.293,
			300.712,
			304.969,
			307.817,
			310.67,
			312.099,
			314.958,
			317.832,
			319.269,
			322.147,
			323.588,
			326.471,
			329.355,
			333.682,
			336.57,
			340.897,
			342.34,
			343.784,
			348.115,
			351.009,
			355.362,
			358.248,
			361.115,
			362.568,
			365.482,
			366.94,
			369.843,
			372.752,
			374.204,
			375.658,
			380.011,
			382.9,
			385.782,
			387.219,
			390.099,
			392.992,
			394.43,
			397.304,
			398.743,
			401.601,
			404.488,
			408.831,
			411.734,
			416.102,
			417.561,
			419.023,
			423.465,
			426.444,
			430.963,
			434.089,
			438.829,
			442.019,
			443.561,
			446.728,
			449.812,
			451.336,
			454.384,
			457.405,
			461.901,
			464.936,
			469.417,
			470.921,
			472.423,
			475.428,
			476.932,
			479.94,
			482.952,
			487.476,
			490.496,
			495.034,
			496.548,
			498.063,
			502.615,
			505.655,
			510.229,
			513.293,
			516.364,
			517.903,
			520.985,
			524.074,
			525.62,
			530.268,
			533.373,
			536.483,
			541.153,
			544.27,
			548.954,
			550.52,
			552.086,
			556.798,
			559.945,
			564.672,
			567.825,
			570.981,
			572.559,
			575.718,
			577.298,
			578.879,
			583.645,
			585.24,
			586.836,
			591.638,
			594.825,
			599.61,
			602.799,
			605.991,
			607.587,
			612.38,
			615.577,
			618.775,
			623.573,
			626.775,
			631.587,
			633.194,
			634.802,
			639.63,
			642.852,
			647.692,
			650.922,
			655.772,
			659.01,
			660.63,
			662.252,
			668.749,
			670.376,
			675.261,
			678.521,
			681.784,
			683.416,
			686.685,
			689.957,
			691.595,
			696.513,
			699.796,
			703.083,
			708.023,
			711.321,
			716.279,
			717.935,
			719.593,
			724.575,
			726.239,
			727.905,
			732.91,
			736.251,
			739.595,
			741.269,
			744.617,
			746.292,
			749.646,
			753.002,
			754.683,
			758.076,
			761.418,
			764.795,
			769.873,
			773.266,
			776.659,
			778.355,
			781.745,
			783.439,
			786.823,
			790.201,
			795.266,
			798.642,
			803.706,
			805.422,
			807.11,
			812.148,
			815.527,
			820.601,
			823.987,
			827.376,
			829.071,
			832.463,
			834.16,
			837.555,
			842.652,
			846.054,
			849.46,
			854.577,
			857.994,
			863.129,
			864.843,
			866.558,
			871.706,
			875.139,
			878.574,
			883.729,
			887.167,
			892.326,
			894.046,
			895.767,
			900.933,
			902.656,
			904.38,
			909.558,
			913.013,
			916.471,
			918.201,
			921.664,
			925.13,
			926.865,
			930.337,
			932.075,
			935.553,
			939.034,
			944.259,
			947.745,
			952.979,
			954.725,
			956.472,
			961.718,
			965.22,
			970.48,
			973.991,
			977.505,
			979.264,
			982.781,
			984.541,
			988.062,
			993.347,
			995.108,
			1000.392,
			1003.916,
			1009.202,
			1012.729,
			1016.259,
			1018.025,
			1021.559,
			1023.326,
			1026.865,
			1030.406,
			1035.724,
			1039.273,
			1044.603,
			1046.381,
			1048.16,
			1053.499,
			1057.061,
			1062.408,
			1065.975,
			1069.544,
			1071.33,
			1074.903,
			1076.69,
			1080.266,
			1083.844,
			1085.634,
			1089.215,
			1092.797,
			1098.174,
			1099.968,
			1101.761,
			1107.146,
			1108.942,
			1110.739,
			1114.334,
			1116.132,
			1119.73,
			1123.33,
			1128.734,
			1132.34,
			1137.754,
			1139.559,
			1141.365,
			1146.786,
			1150.404,
			1155.833,
			1159.455,
			1163.078,
			1164.891,
			1168.518,
			1170.333,
			1173.962,
			1177.594,
			1179.411,
			1183.045,
			1186.682,
			1192.142,
			1195.783,
			1201.25,
			1203.072,
			1204.895,
			1210.368,
			1214.019,
			1219.497,
			1223.151,
			1226.808,
			1232.296,
			1234.126,
			1235.958,
			1241.455,
			1243.288,
			1245.122,
			1250.627,
			1254.299,
			1257.974,
			1259.813,
			1263.491,
			1265.331,
			1269.013,
			1272.695,
			1274.536,
			1278.219,
			1281.905,
			1287.433,
			1291.12,
			1296.653,
			1298.499,
			1300.345,
			1305.885,
			1309.581,
			1315.129,
			1318.83,
			1322.531,
			1324.383,
			1328.087,
			1329.94,
			1331.793,
			1339.209,
			1341.064,
			1346.634,
			1350.35,
			1355.927,
			1359.65,
			1363.374,
			1365.237,
			1368.963,
			1370.826,
			1374.553,
			1378.279,
			1383.869,
			1387.597,
			1393.184,
			1395.041,
			1396.892,
			1402.405,
			1406.033,
			1411.4,
			1414.928,
			1418.419,
			1420.15,
			1423.583,
			1425.285,
			1428.656,
			1433.635,
			1436.904,
			1440.133,
			1444.902,
			1446.472,
			1448.032,
			1451.122,
			1454.174,
			1455.687,
			1458.683,
			1460.168,
			1463.108,
			1466.007,
			1470.283,
			1473.084,
			1477.211,
			1478.567,
			1479.913,
			1483.893,
			1486.497,
			1490.33,
			1492.837,
			1495.307,
			1496.527,
			1498.941,
			1500.135,
			1502.497,
			1504.823,
			1505.972,
			1508.24,
			1510.468,
			1513.737,
			1514.808,
			1515.869,
			1518.994,
			1520.017,
			1521.031,
			1524.016,
			1525.977,
			1528.825,
			1530.678,
			1532.502,
			1535.181,
			1536.061,
			1536.937,
			1540.383,
			1541.235,
			1543.776,
			1545.458,
			1547.14,
			1547.981,
			1549.659,
			1550.498,
			1552.175,
			1553.864,
			1554.712,
			1556.406,
			1558.103,
			1560.689,
			1562.41,
			1565.018,
			1565.888,
			1566.758,
			1569.384,
			1571.141,
			1573.787,
			1575.572,
			1577.354,
			1578.248,
			1580.072,
			1580.982,
			1582.801,
			1584.641,
			1585.562,
			1586.482,
			1589.246,
			1591.09,
			1592.931,
			1593.853,
			1595.691,
			1597.525,
			1598.44,
			1600.265,
			1601.175,
			1602.993,
			1604.797,
			1607.494,
			1609.276,
			1611.933,
			1612.802,
			1613.679,
			1616.281,
			1618.017,
			1620.567,
			1622.266,
			1624.778,
			1626.432,
			1627.272,
			1628.929,
			1630.589,
			1631.417,
			1633.075,
			1634.77,
			1637.325,
			1638.175,
			1639.038,
			1640.776,
			1642.51,
			1643.376,
			1645.138,
			1646.018,
			1647.775,
			1649.555,
			1652.246,
			1654.047,
			1656.783,
			1657.694,
			1658.605,
			1661.341,
			1663.163,
			1665.869,
			1667.668,
			1669.464,
			1670.36,
			1672.152,
			1673.048,
			1674.836,
			1676.624,
			1677.504,
			1679.286,
			1681.068,
			1683.74,
			1684.616,
			1685.495,
			1688.151,
			1689.038,
			1689.917,
			1692.571,
			1694.349,
			1697.017,
			1698.807,
			1700.607,
			1703.32,
			1704.231,
			1705.144,
			1707.894,
			1708.813,
			1709.737,
			1712.515,
			1714.373,
			1717.17,
			1719.04,
			1719.978,
			1721.856,
			1724.684,
			1726.577,
			1728.48,
			1731.334,
			1733.262,
			1736.173,
			1737.149,
			1738.128,
			1741.08,
			1743.056,
			1746.025,
			1748,
			1749.974,
			1750.96,
			1752.928,
			1753.927,
			1754.911,
			1757.859,
			1758.84,
			1759.823,
			1762.772,
			1764.739,
			1766.707,
			1767.691,
			1769.66,
			1771.623,
			1772.605,
			1775.55,
			1777.513,
			1779.479,
			1782.445,
			1784.426,
			1787.435,
			1788.438,
			1789.444,
			1792.503,
			1794.546,
			1797.64,
			1799.703,
			1801.767,
			1802.799,
			1804.861,
			1805.891,
			1807.949,
			1810.011,
			1811.047,
			1812.082,
			1815.195,
			1818.329,
			1819.373,
			1820.421,
			1822.516,
			1824.609,
			1825.646,
			1827.718,
			1828.755,
			1830.811,
			1832.856,
			1835.911,
			1837.934,
			1840.955,
			1841.955,
			1842.956,
			1845.95,
			1847.939,
			1850.928,
			1852.925,
			1854.924,
			1855.931,
			1857.945,
			1858.953,
			1860.98,
			1863.016,
			1864.037,
			1866.089,
			1868.153,
			1871.269,
			1872.313,
			1873.359,
			1876.492,
			1877.537,
			1878.584,
			1881.742,
			1883.843,
			1887.04,
			1889.185,
			1891.341,
			1894.598,
			1895.689,
			1896.783,
			1900.081,
			1902.296,
			1905.638,
			1907.882,
			1910.137,
			1911.269,
			1913.542,
			1915.824,
			1916.969,
			1919.267,
			1920.42,
			1922.735,
			1925.061,
			1928.57,
			1930.923,
			1934.473,
			1935.661,
			1936.852,
			1940.44,
			1942.843,
			1946.467,
			1948.896,
			1951.334,
			1955.008,
			1956.237,
			1957.469,
			1961.177,
			1962.417,
			1963.659,
			1967.4,
			1969.905,
			1972.42,
			1973.681,
			1976.21,
			1978.749,
			1980.021,
			1982.573,
			1983.853,
			1986.419,
			1988.994,
			1992.873,
			1995.469,
			1999.377,
			2000.68,
			2001.985,
			2005.91,
			2008.537,
			2012.492,
			2015.14,
			2017.796,
			2019.127,
			2021.796,
			2023.133,
			2025.815,
			2028.505,
			2029.854,
			2032.556,
			2035.266,
			2039.347,
			2040.711,
			2042.077,
			2044.814,
			2047.56,
			2048.935,
			2051.692,
			2053.073,
			2055.835,
			2058.588,
			2062.675,
			2065.358,
			2069.313,
			2070.614,
			2071.905,
			2075.724,
			2078.225,
			2081.91,
			2084.322,
			2087.879,
			2090.208,
			2091.36,
			2093.635,
			2095.874,
			2096.979,
			2099.163,
			2101.311,
			2104.467,
			2105.501,
			2106.526,
			2109.55,
			2110.54,
			2111.521,
			2114.413,
			2116.295,
			2119.055,
			2120.853,
			2122.617,
			2125.209,
			2126.051,
			2126.884,
			2129.324,
			2130.909,
			2133.221,
			2134.723,
			2136.935,
			2138.381,
			2139.815,
			2140.528,
			2141.952,
			2142.662,
			2144.082,
			2145.503,
			2147.648,
			2149.086,
			2151.247,
			2151.969,
			2152.701,
			2154.901,
			2156.368,
			2158.597,
			2160.091,
			2161.583,
			2162.331,
			2163.842,
			2164.596,
			2165.349,
			2167.611,
			2168.37,
			2169.128,
			2171.399,
			2172.922,
			2174.452,
			2175.216,
			2176.745,
			2178.288,
			2179.062,
			2180.608,
			2181.38,
			2182.93,
			2184.487,
			2186.819,
			2188.38,
			2190.721,
			2191.501,
			2192.282,
			2194.632,
			2196.195,
			2198.547,
			2200.118,
			2202.469,
			2204.059,
			2204.853,
			2206.438,
			2208.839,
			2209.642,
			2212.052,
			2214.471,
			2215.278,
			2216.083,
			2217.697,
			2219.314,
			2220.122,
			2222.546,
			2224.171,
			2225.794,
			2228.231,
			2229.86,
			2232.297,
			2233.111,
			2233.925,
			2236.362,
			2237.986,
			2240.436,
			2242.065,
			2243.701,
			2244.521,
			2246.157,
			2246.974,
			2248.607,
			2250.237,
			2251.052,
			2252.681,
			2254.308,
			2256.75,
			2257.565,
			2258.379,
			2260.812,
			2261.625,
			2262.439,
			2264.885,
			2266.518,
			2268.157,
			2268.983,
			2270.636,
			2272.295,
			2274.786,
			2275.617,
			2276.45,
			2278.951,
			2280.611,
			2283.119,
			2284.778,
			2286.445,
			2287.284,
			2288.96,
			2290.635,
			2291.477,
			2293.171,
			2294.014,
			2295.707,
			2297.412,
			2299.98,
			2301.701,
			2304.308,
			2305.184,
			2306.062,
			2308.719,
			2310.522,
			2312.338,
			2315.111,
			2316.988,
			2319.842,
			2320.806,
			2322.745,
			2325.695,
			2326.687,
			2329.683,
			2331.698,
			2333.725,
			2334.741,
			2336.784,
			2338.838,
			2339.865,
			2341.929,
			2342.966,
			2345.043,
			2347.111,
			2350.244,
			2352.332,
			2355.475,
			2356.527,
			2357.581,
			2360.758,
			2362.887,
			2366.083,
			2368.201,
			2370.301,
			2371.342,
			2373.401,
			2374.418,
			2376.429,
			2378.404,
			2379.38,
			2380.348,
			2383.199,
			2385.061,
			2387.818,
			2389.623,
			2391.421,
			2392.315,
			2394.097,
			2394.989,
			2396.767,
			2398.539,
			2401.195,
			2402.962,
			2405.605,
			2406.485,
			2407.364,
			2409.996,
			2411.744,
			2414.365,
			2416.105,
			2418.716,
			2420.46,
			2422.208,
			2423.083,
			2424.841,
			2425.732,
			2427.517,
			2429.306,
			2432.08,
			2433.01,
			2433.942,
			2436.819,
			2437.789,
			2438.76,
			2441.729,
			2443.728,
			2445.749,
			2448.809,
			2450.864,
			2453.962,
			2454.999,
			2456.033,
			2459.145,
			2460.18,
			2461.205,
			2464.285,
			2466.318,
			2468.34,
			2469.353,
			2471.354,
			2472.351,
			2474.346,
			2476.327,
			2477.313,
			2479.287,
			2481.254,
			2484.199,
			2485.185,
			2486.161,
			2489.103,
			2490.09,
			2491.069,
			2494.022,
			2496.004,
			2498.991,
			2501.003,
			2503.037,
			2506.117,
			2507.155,
			2509.231,
			2511.318,
			2512.369,
			2513.422,
			2516.6,
			2518.73,
			2521.947,
			2524.104,
			2526.275,
			2527.369,
			2529.564,
			2530.666,
			2532.869,
			2535.101,
			2538.492,
			2540.755,
			2544.155,
			2545.301,
			2546.449,
			2549.914,
			2552.236,
			2555.738,
			2558.082,
			2560.438,
			2561.619,
			2563.989,
			2565.178,
			2567.564,
			2569.961,
			2571.164,
			2572.369,
			2576.001,
			2578.438,
			2580.884,
			2582.112,
			2584.577,
			2587.051,
			2588.293,
			2590.783,
			2592.033,
			2594.542,
			2598.324,
			2600.859,
			2603.405,
			2607.242,
			2608.527,
			2609.813,
			2613.688,
			2616.284,
			2620.194,
			2622.811,
			2625.429,
			2626.74,
			2629.369,
			2630.686,
			2633.327,
			2635.976,
			2637.304,
			2639.968,
			2642.641,
			2646.665,
			2648.011,
			2649.359,
			2652.063,
			2654.774,
			2656.132,
			2660.221,
			2662.957,
			2665.699,
			2669.83,
			2672.594,
			2676.756,
			2678.147,
			2679.54,
			2683.732,
			2686.538,
			2690.763,
			2693.59,
			2696.426,
			2697.847,
			2700.695,
			2702.121,
			2704.981,
			2707.848,
			2709.285,
			2712.164,
			2715.051,
			2719.396,
			2722.304,
			2726.677,
			2728.139,
			2729.602,
			2734.003,
			2736.947,
			2741.375,
			2744.336,
			2747.304,
			2751.768,
			2753.26,
			2754.754,
			2760.749,
			2762.252,
			2766.772,
			2769.795,
			2772.825,
			2774.343,
			2777.384,
			2780.433,
			2781.96,
			2785.019,
			2786.551,
			2789.621,
			2792.697,
			2797.325,
			2800.417,
			2805.069,
			2806.622,
			2808.177,
			2812.849,
			2815.972,
			2820.668,
			2823.811,
			2826.959,
			2828.537,
			2831.697,
			2834.865,
			2836.451,
			2839.629,
			2841.22,
			2842.813,
			2847.595,
			2850.774,
			2855.501,
			2858.614,
			2861.691,
			2863.215,
			2866.235,
			2867.731,
			2870.696,
			2873.623,
			2877.945,
			2880.78,
			2884.963,
			2886.34,
			2887.707,
			2891.753,
			2894.407,
			2898.319,
			2900.882,
			2904.662,
			2907.137,
			2908.363,
			2910.788,
			2913.18,
			2914.364,
			2916.709,
			2919.035,
			2922.429,
			2923.541,
			2924.644,
			2927.906,
			2928.977,
			2930.04,
			2933.188,
			2935.259,
			2937.31,
			2940.385,
			2942.434,
			2945.546,
			2946.586,
			2947.629,
			2950.783,
			2952.892,
			2956.066,
			2958.183,
			2960.301,
			2961.36,
			2963.474,
			2965.584,
			2966.638,
			2968.744,
			2969.797,
			2971.901,
			2974.009,
			2977.178,
			2979.301,
			2982.501,
			2983.573,
			2984.649,
			2987.894,
			2990.076,
			2993.373,
			2995.588,
			2998.927,
			3001.163,
			3002.282,
			3003.402,
			3007.877,
			3008.993,
			3012.331,
			3014.552,
			3016.767,
			3017.875,
			3020.089,
			3022.303,
			3023.412,
			3026.735,
			3028.961,
			3031.186,
			3034.538,
			3036.771,
			3040.125,
			3041.239,
			3042.354,
			3045.694,
			3047.91,
			3051.221,
			3053.417,
			3055.606,
			3056.698,
			3058.877,
			3059.965,
			3062.14,
			3064.31,
			3065.399,
			3067.582,
			3069.767,
			3071.966,
			3075.275,
			3077.486,
			3079.707,
			3080.82,
			3084.174,
			3086.426,
			3088.687,
			3092.097,
			3094.378,
			3097.807,
			3098.95,
			3100.095,
			3103.528,
			3105.817,
			3109.248,
			3111.538,
			3114.978,
			3117.281,
			3118.436,
			3120.752,
			3123.075,
			3124.24,
			3126.577,
			3128.918,
			3132.45,
			3134.82,
			3138.399,
			3139.597,
			3140.799,
			3144.434,
			3146.873,
			3149.327,
			3153.035,
			3155.523,
			3159.274,
			3160.53,
			3161.788,
			3165.586,
			3168.133,
			3171.975,
			3174.55,
			3177.135,
			3178.432,
			3181.033,
			3182.338,
			3184.957,
			3187.581,
			3188.894,
			3191.526,
			3194.167,
			3198.148,
			3200.817,
			3204.845,
			3206.193,
			3207.543,
			3211.609,
			3214.332,
			3217.064,
			3221.179,
			3223.933,
			3225.312,
			3228.075,
			3229.459,
			3230.844,
			3236.404,
			3237.798,
			3241.983,
			3244.78,
			3247.583,
			3248.987,
			3251.8,
			3254.619,
			3256.029,
			3260.269,
			3263.101,
			3265.935,
			3270.194,
			3273.039,
			3277.313,
			3278.742,
			3280.169,
			3284.46,
			3287.324,
			3291.63,
			3294.506,
			3298.827,
			3301.713,
			3303.158,
			3306.053,
			3308.929,
			3310.381,
			3313.289,
			3316.231,
			3319.157,
			3322.1,
			3323.575,
			3326.539,
			3329.49,
			3330.984,
			3333.977,
			3335.476,
			3338.479,
			3341.489,
			3346.013,
			3349.035,
			3353.576,
			3355.092,
			3356.607,
			3361.161,
			3364.202,
			3368.774,
			3371.831,
			3374.893,
			3376.426,
			3379.498,
			3381.035,
			3384.114,
			3388.742,
			3391.835,
			3394.935,
			3399.597,
			3401.154,
			3402.713,
			3407.403,
			3408.97,
			3410.539,
			3415.254,
			3418.405,
			3421.56,
			3426.302,
			3429.469,
			3434.227,
			3435.815,
			3437.405,
			3442.18,
			3445.366,
			3450.147,
			3453.338,
			3456.533,
			3458.132,
			3461.332,
			3464.535,
			3466.138,
			3470.953,
			3474.168,
			3477.387,
			3482.222,
			3485.451,
			3490.303,
			3491.922,
			3493.542,
			3498.411,
			3501.663,
			3506.549,
			3509.805,
			3513.052,
			3517.877,
			3519.47,
			3521.053,
			3525.741,
			3527.284,
			3528.818,
			3533.365,
			3536.352,
			3539.304,
			3540.766,
			3543.663,
			3546.52,
			3547.934,
			3550.733,
			3552.117,
			3554.858,
			3557.559,
			3561.539,
			3564.143,
			3567.975,
			3569.234,
			3570.504,
			3574.195,
			3576.611,
			3580.148,
			3582.475,
			3584.767,
			3585.9,
			3588.136,
			3589.242,
			3591.43,
			3594.663,
			3595.717,
			3598.826,
			3601.858,
			3602.851,
			3603.835,
			3605.779,
			3607.689,
			3608.631,
			3610.488,
			3611.404,
			3613.214,
			3614.998,
			3617.641,
			3619.391,
			3621.99,
			3622.855,
			3623.719,
			3626.299,
			3628.021,
			3630.609,
			3632.337,
			3634.075,
			3634.944,
			3636.683,
			3637.554,
			3639.303,
			3641.051,
			3641.925,
			3643.681,
			3645.438,
			3648.078,
			3649.847,
			3652.497,
			3653.385,
			3654.273,
			3656.933,
			3658.71,
			3660.49,
			3663.159,
			3664.941,
			3667.611,
			3668.503,
			3669.397,
			3672.075,
			3673.861,
			3676.542,
			3678.328,
			3681.009,
			3682.792,
			3683.684,
			3685.46,
			3687.233,
			3688.119,
			3689.893,
			3691.666,
			3694.325,
			3696.098,
			3698.757,
			3699.645,
			3700.534,
			3703.199,
			3704.977,
			3707.645,
			3709.427,
			3711.209,
			3713.889,
			3714.785,
			3715.682,
			3718.381,
			3719.284,
			3720.189,
			3722.912,
			3724.736,
			3726.564,
			3727.481,
			3729.321,
			3731.165,
			3732.09,
			3733.948,
			3734.879,
			3736.727,
			3738.602,
			3741.426,
			3743.32,
			3746.177,
			3747.132,
			3748.089,
			3750.962,
			3752.874,
			3755.745,
			3757.638,
			3759.524,
			3760.466,
			3762.349,
			3763.287,
			3765.158,
			3767.041,
			3767.979,
			3769.851,
			3771.735,
			3773.621,
			3776.456,
			3778.357,
			3780.261,
			3781.223,
			3783.157,
			3784.124,
			3786.063,
			3788.014,
			3790.942,
			3792.897,
			3795.828,
			3796.812,
			3797.794,
			3800.738,
			3802.7,
			3805.641,
			3807.6,
			3809.558,
			3810.537,
			3812.506,
			3813.492,
			3815.465,
			3817.44,
			3818.427,
			3820.401,
			3822.375,
			3825.333,
			3827.301,
			3829.259,
			3831.22,
			3832.196,
			3834.142,
			3835.116,
			3837.064,
			3839.009,
			3841.931,
			3843.876,
			3846.802,
			3847.776,
			3848.751,
			3851.686,
			3853.642,
			3856.589,
			3858.554,
			3860.526,
			3861.515,
			3863.493,
			3864.482,
			3866.471,
			3869.455,
			3871.449,
			3873.45,
			3876.456,
			3878.472,
			3881.51,
			3882.526,
			3883.549,
			3886.625,
			3888.691,
			3890.787,
			3893.934,
			3896.063,
			3899.292,
			3900.368,
			3901.447,
			3904.693,
			3905.778,
			3906.847,
			3910.123,
			3912.318,
			3914.543,
			3915.652,
			3917.876,
			3920.096,
			3921.221,
			3923.496,
			3924.629,
			3926.904,
			3929.168,
			3932.61,
			3934.918,
			3938.418,
			3939.584,
			3940.733,
			3944.251,
			3946.609,
			3950.163,
			3952.543,
			3954.934,
			3956.133,
			3958.536,
			3959.742,
			3962.16,
			3965.804,
			3967.023,
			3970.693,
			3973.15,
			3975.615,
			3976.851,
			3979.329,
			3981.816,
			3983.063,
			3985.563,
			3986.816,
			3989.325,
			3991.836,
			3995.59,
			3998.071,
			4001.743,
			4002.951,
			4004.148,
			4007.686,
			4009.999,
			4013.396,
			4015.615,
			4018.874,
			4021.001,
			4022.051,
			4024.124,
			4026.16,
			4027.165,
			4029.148,
			4031.095,
			4033.951,
			4034.885,
			4035.81,
			4038.541,
			4039.436,
			4040.323,
			4042.08,
			4042.967,
			4044.697,
			4046.413,
			4048.971,
			4050.67,
			4053.211,
			4054.055,
			4054.899,
			4057.431,
			4059.113,
			4061.637,
			4063.315,
			4064.986,
			4065.823,
			4067.499,
			4068.337,
			4070.014,
			4071.697,
			4072.54,
			4074.255,
			4075.979,
			4078.592,
			4080.373,
			4083.055,
			4083.967,
			4084.882,
			4087.632,
			4089.483,
			4092.277,
			4094.148,
			4096.042,
			4096.989,
			4098.883,
			4099.838,
			4100.794,
			4104.616,
			4105.575,
			4108.446,
			4110.368,
			4112.292,
			4113.254,
			4115.181,
			4117.109,
			4118.074,
			4120.005,
			4120.969,
			4122.898,
			4124.821,
			4127.673,
			4129.573,
			4132.365,
			4133.296,
			4134.227,
			4136.959,
			4138.775,
			4141.469,
			4143.257,
			4145.049,
			4145.943,
			4147.734,
			4148.63,
			4150.429,
			4153.145,
			4154.959,
			4156.79,
			4158.626,
			4160.472,
			4161.408,
			4163.286,
			4165.17,
			4166.125,
			4168.048,
			4169.014,
			4170.938,
			4172.901,
			4175.869,
			4177.858,
			4180.867,
			4181.874,
			4182.899,
			4185.93,
			4187.978,
			4191.07,
			4193.15,
			4195.242,
			4196.292,
			4198.397,
			4199.45,
			4201.557,
			4203.672,
			4204.733,
			4206.864,
			4209.007,
			4212.242,
			4213.325,
			4214.412,
			4216.594,
			4218.785,
			4219.884,
			4222.09,
			4223.196,
			4225.416,
			4227.646,
			4231.005,
			4233.255,
			4236.645,
			4237.779,
			4238.916,
			4242.334,
			4243.476,
			4244.635,
			4248.055,
			4250.323,
			4252.581,
			4253.708,
			4255.939,
			4257.051,
			4259.27,
			4262.564,
			4264.753,
			4266.941,
			4270.215,
			4271.31,
			4272.404,
			4275.692,
			4276.794,
			4277.896,
			4281.217,
			4283.441,
			4286.795,
			4289.041,
			4291.297,
			4294.694,
			4295.832,
			4296.973,
			4300.398,
			4301.549,
			4302.702,
			4306.166,
			4308.487,
			4310.809,
			4311.976,
			4314.31,
			4316.646,
			4317.816,
			4320.158,
			4321.33,
			4323.683,
			4326.042,
			4329.608,
			4331.997,
			4335.605,
			4336.812,
			4338.028,
			4341.691,
			4344.138,
			4347.822,
			4350.294,
			4352.775,
			4356.524,
			4357.778,
			4360.295,
			4362.82,
			4364.073,
			4365.327,
			4369.075,
			4371.578,
			4374.1,
			4375.34,
			4377.825,
			4380.298,
			4381.533,
			4384.008,
			4385.243,
			4387.719,
			4390.201,
			4393.937,
			4396.438,
			4400.205,
			4401.466,
			4402.729,
			4406.535,
			4409.084,
			4412.928,
			4415.502,
			4418.089,
			4419.385,
			4421.979,
			4423.276,
			4425.875,
			4428.457,
			4429.76,
			4432.372,
			4434.988,
			4438.944,
			4441.575,
			4444.211,
			4446.854,
			4448.175,
			4450.802,
			4452.128,
			4454.783,
			4457.442,
			4461.44,
			4464.109,
			4468.123,
			4469.464,
			4470.807,
			4474.841,
			4477.537,
			4481.586,
			4484.297,
			4487.015,
			4488.375,
			4491.122,
			4492.486,
			4495.221,
			4497.938,
			4499.312,
			4502.065,
			4504.824,
			4508.976,
			4511.756,
			4515.938,
			4517.336,
			4518.735,
			4522.95,
			4525.767,
			4530.004,
			4532.838,
			4535.677,
			4539.947,
			4541.373,
			4542.801,
			4547.091,
			4548.524,
			4549.958,
			4554.27,
			4557.151,
			4560.039,
			4561.484,
			4564.379,
			4565.828,
			4568.731,
			4571.641,
			4573.098,
			4576.016,
			4578.94,
			4583.338,
			4586.276,
			4590.696,
			4592.172,
			4593.649,
			4598.09,
			4601.058,
			4605.519,
			4608.5,
			4611.49,
			4615.984,
			4617.485,
			4620.49,
			4623.5,
			4625.007,
			4626.516,
			4631.053,
			4634.088,
			4638.655,
			4641.708,
			4644.767,
			4646.298,
			4649.366,
			4650.902,
			4653.979,
			4657.059,
			4661.688,
			4664.778,
			4669.421,
			4670.971,
			4672.521,
			4678.724,
			4680.276,
			4684.937,
			4688.048,
			4691.163,
			4692.723,
			4695.845,
			4697.408,
			4700.538,
			4703.673,
			4705.243,
			4708.386,
			4711.535,
			4714.69,
			4719.435,
			4724.192,
			4725.782,
			4727.374,
			4730.558,
			4732.15,
			4735.338,
			4738.531,
			4743.326,
			4746.527,
			4751.333,
			4752.937,
			4754.541,
			4759.361,
			4762.578,
			4767.41,
			4770.636,
			4773.865,
			4775.48,
			4778.715,
			4780.333,
			4783.573,
			4786.816,
			4788.439,
			4791.688,
			4794.941,
			4799.827,
			4803.089,
			4807.988,
			4809.623,
			4811.259,
			4816.171,
			4819.452,
			4824.377,
			4827.665,
			4830.957,
			4835.901,
			4837.551,
			4839.202,
			4844.159,
			4847.468,
			4852.437,
			4855.753,
			4860.733,
			4864.058,
			4867.386,
			4869.051,
			4872.384,
			4874.052,
			4877.391,
			4880.732,
			4885.752,
			4887.427,
			4889.104,
			4894.137,
			4895.815,
			4897.494,
			4902.539,
			4905.906,
			4910.965,
			4914.344,
			4917.725,
			4919.416,
			4922.803,
			4924.497,
			4926.192,
			4931.282,
			4932.98,
			4934.679,
			4939.78,
			4943.185,
			4946.592,
			4948.297,
			4951.71,
			4955.127,
			4956.837,
			4961.975,
			4965.404,
			4968.838,
			4973.998,
			4977.443,
			4982.651,
			4984.38,
			4986.112,
			4991.288,
			4994.766,
			4999.997,
			5003.528,
			5008.786,
			5012.299,
			5014.028,
			5017.547,
			5021.067,
			5022.829,
			5026.353,
			5029.884,
			5035.218,
			5036.988,
			5038.757,
			5042.295,
			5045.832,
			5047.601,
			5051.14,
			5052.91,
			5056.452,
			5059.994,
			5065.277,
			5068.819,
			5074.133,
			5075.903,
			5077.703,
			5083.015,
			5086.557,
			5091.838,
			5095.378,
			5100.687,
			5104.254,
			5106.023,
			5109.532,
			5113.07,
			5114.84,
			5118.378,
			5121.917,
			5127.231,
			5130.773,
			5136.088,
			5137.859,
			5139.631,
			5144.945,
			5148.489,
			5153.811,
			5157.365,
			5160.921,
			5166.266,
			5168.052,
			5169.838,
			5175.205,
			5178.79,
			5184.171,
			5187.762,
			5191.356,
			5193.154,
			5196.752,
			5198.553,
			5202.157,
			5205.766,
			5207.572,
			5211.185,
			5214.801,
			5220.228,
			5223.848,
			5229.278,
			5231.117,
			5232.927,
			5238.354,
			5241.972,
			5247.4,
			5251.021,
			5254.642,
			5260.073,
			5261.883,
			5263.691,
			5269.113,
			5270.919,
			5272.723,
			5278.124,
			5281.715,
			5285.297,
			5287.085,
			5290.652,
			5294.211,
			5295.986,
			5301.302,
			5304.854,
			5308.42,
			5313.804,
			5317.411,
			5322.787,
			5324.571,
			5326.356,
			5331.704,
			5335.267,
			5340.603,
			5344.152,
			5347.693,
			5349.459,
			5352.984,
			5354.744,
			5358.258,
			5361.768,
			5363.523,
			5367.032,
			5370.489,
			5375.692,
			5377.42,
			5379.14,
			5384.293,
			5385.985,
			5387.677,
			5392.719,
			5396.071,
			5399.394,
			5404.443,
			5407.857,
			5412.958,
			5414.663,
			5416.369,
			5421.49,
			5424.905,
			5430.014,
			5433.477,
			5436.918,
			5438.643,
			5442.095,
			5443.824,
			5447.284,
			5452.459,
			5455.935,
			5459.414,
			5464.639,
			5466.382,
			5468.125,
			5473.359,
			5475.104,
			5476.852,
			5480.346,
			5482.095,
			5485.593,
			5489.093,
			5494.345,
			5497.849,
			5503.111,
			5504.867,
			5506.625,
			5511.902,
			5515.424,
			5520.709,
			5524.236,
			5529.533,
			5533.067,
			5536.604,
			5538.374,
			5541.913,
			5543.683,
			5547.224,
			5550.77,
			5556.094,
			5559.648,
			5564.989,
			5566.771,
			5568.554,
			5573.913,
			5577.494,
			5582.878,
			5586.474,
			5590.08,
			5591.885,
			5595.497,
			5597.305,
			5599.112,
			5606.352,
			5608.163,
			5613.608,
			5617.273,
			5620.879,
			5622.699,
			5626.341,
			5629.987,
			5631.81,
			5637.283,
			5640.932,
			5644.579,
			5650.048,
			5653.693,
			5659.16,
			5660.983,
			5662.806,
			5668.276,
			5671.924,
			5677.397,
			5681.047,
			5684.699,
			5686.525,
			5690.177,
			5692.004,
			5695.66,
			5699.316,
			5701.144,
			5704.799,
			5708.447,
			5713.884,
			5715.683,
			5717.474,
			5721.027,
			5724.541,
			5726.282,
			5729.734,
			5731.444,
			5734.833,
			5738.18,
			5743.125,
			5746.37,
			5751.162,
			5752.739,
			5754.307,
			5758.95,
			5761.997,
			5766.498,
			5769.452,
			5773.809,
			5776.664,
			5778.077,
			5780.874,
			5783.632,
			5784.998,
			5787.699,
			5790.365,
			5794.291,
			5795.58,
			5796.859,
			5800.641,
			5801.882,
			5803.114,
			5806.753,
			5809.134,
			5811.48,
			5814.933,
			5817.187,
			5820.496,
			5821.58,
			5822.654,
			5825.816,
			5827.872,
			5830.884,
			5832.839,
			5835.702,
			5837.561,
			5838.477,
			5840.283,
			5842.055,
			5842.928,
			5844.648,
			5846.332,
			5848.792,
			5850.385,
			5852.704,
			5853.458,
			5854.203,
			5856.382,
			5857.784,
			5859.819,
			5861.134,
			5862.421,
			5863.046,
			5864.269,
			5864.865,
			5865.452,
			5867.159,
			5867.707,
			5868.246,
			5869.816,
			5870.832,
			5872.296,
			5873.231,
			5874.148,
			5874.601,
			5875.499,
			5875.946,
			5876.837,
			5877.725,
			5879.045,
			5879.922,
			5881.242,
			5881.684,
			5882.152,
			5883.567,
			5884.509,
			5885.927,
			5886.886,
			5887.97,
			5888.511,
			5889.594,
			5890.138,
			5891.23,
			5893.008,
			5893.642,
			5895.547,
			5897.458,
			5898.081,
			5898.703,
			5899.95,
			5901.201,
			5901.829,
			5903.037,
			5903.626,
			5904.805,
			5905.986,
			5907.756,
			5908.905,
			5910.636,
			5911.215,
			5911.795,
			5913.536,
			5914.697,
			5916.437,
			5917.594,
			5919.321,
			5920.479,
			5921.05,
			5922.189,
			5923.899,
			5925.029,
			5926.149,
			5927.818,
			5928.38,
			5928.961,
			5930.68,
			5931.246,
			5931.812,
			5932.938,
			5933.528,
			5934.772,
			5936.616,
			5937.834,
			5939.173,
			5941.332,
			5942.047,
			5942.761,
			5944.994,
			5946.498,
			5948.739,
			5950.216,
			5952.406,
			5953.861,
			5954.546,
			5955.893,
			5957.902,
			5959.221,
			5960.401,
			5962.161,
			5963.335,
			5965.03,
			5965.557,
			5966.082,
			5967.656,
			5968.708,
			5970.241,
			5971.225,
			5972.209,
			5973.693,
			5974.191,
			5974.691,
			5976.732,
			5977.247,
			5978.803,
			5979.85,
			5981.495,
			5982.608,
			5983.731,
			5984.298,
			5986.03,
			5987.216,
			5988.415,
			5990.239,
			5991.485,
			5993.396,
			5994.041,
			5994.689,
			5996.664,
			5997.995,
			6000.038,
			6001.42,
			6002.818,
			6003.535,
			6004.944,
			6005.661,
			6007.107,
			6008.568,
			6009.304,
			6010.787,
			6012.286,
			6014.564,
			6016.109,
			6017.648,
			6019.185,
			6019.964,
			6021.535,
			6022.326,
			6023.917,
			6025.524,
			6027.961,
			6029.604,
			6032.109,
			6032.945,
			6033.782,
			6036.297,
			6037.994,
			6040.545,
			6042.241,
			6043.942,
			6044.795,
			6046.507,
			6047.364,
			6049.08,
			6050.787,
			6051.651,
			6053.387,
			6055.131,
			6057.766,
			6058.65,
			6059.537,
			6062.211,
			6063.109,
			6064.01,
			6065.82,
			6066.744,
			6068.554,
			6070.39,
			6073.165,
			6075.03,
			6077.851,
			6078.798,
			6079.747,
			6082.614,
			6084.541,
			6087.456,
			6089.417,
			6092.382,
			6094.379,
			6096.387,
			6097.395,
			6099.42,
			6100.437,
			6102.478,
			6104.53,
			6107.626,
			6109.704,
			6112.841,
			6113.891,
			6114.94,
			6118.087,
			6120.193,
			6123.37,
			6125.5,
			6127.641,
			6130.87,
			6131.95,
			6134.118,
			6136.296,
			6137.388,
			6138.482,
			6141.78,
			6143.99,
			6146.209,
			6147.322,
			6149.556,
			6151.798,
			6152.923,
			6155.179,
			6156.31,
			6158.58,
			6160.857,
			6164.293,
			6166.596,
			6170.066,
			6171.228,
			6172.392,
			6175.897,
			6178.246,
			6181.784,
			6184.155,
			6187.724,
			6190.115,
			6191.313,
			6193.716,
			6197.336,
			6198.547,
			6202.193,
			6204.637,
			6207.088,
			6208.317,
			6210.784,
			6213.258,
			6214.499,
			6216.986,
			6218.234,
			6220.735,
			6223.245,
			6227.024,
			6229.555,
			6233.366,
			6234.64,
			6235.916,
			6239.755,
			6242.324,
			6246.191,
			6248.78,
			6251.375,
			6252.676,
			6255.278,
			6256.579,
			6259.184,
			6261.796,
			6263.104,
			6265.727,
			6268.356,
			6272.313,
			6273.635,
			6274.959,
			6278.941,
			6280.271,
			6281.604,
			6284.274,
			6285.612,
			6288.292,
			6292.325,
			6295.021,
			6297.725,
			6301.792,
			6303.151,
			6304.512,
			6309.97,
			6311.338,
			6315.454,
			6318.206,
			6322.345,
			6325.112,
			6327.886,
			6329.275,
			6333.453,
			6336.245,
			6339.045,
			6343.254,
			6344.662,
			6346.07,
			6350.305,
			6351.719,
			6353.135,
			6357.392,
			6360.238,
			6364.519,
			6367.38,
			6370.248,
			6371.684,
			6374.562,
			6376.003,
			6377.445,
			6381.78,
			6383.229,
			6384.678,
			6389.032,
			6391.937,
			6394.833,
			6396.273,
			6399.135,
			6401.963,
			6403.363,
			6406.132,
			6407.502,
			6410.213,
			6412.887,
			6416.823,
			6419.399,
			6423.191,
			6424.436,
			6425.671,
			6429.319,
			6431.705,
			6435.216,
			6437.512,
			6439.768,
			6440.881,
			6443.081,
			6444.166,
			6446.307,
			6448.41,
			6449.447,
			6450.475,
			6453.5,
			6456.44,
			6457.401,
			6458.354,
			6460.229,
			6462.067,
			6462.973,
			6464.759,
			6465.64,
			6467.373,
			6469.069,
			6471.54,
			6473.141,
			6475.47,
			6476.227,
			6476.975,
			6479.163,
			6480.575,
			6482.625,
			6483.949,
			6485.239,
			6485.87,
			6487.105,
			6487.709,
			6488.889,
			6490.034,
			6490.596,
			6491.695,
			6492.775,
			6494.37,
			6494.899,
			6495.427,
			6497.009,
			6497.529,
			6498.049,
			6499.086,
			6499.604,
			6500.641,
			6501.662,
			6503.159,
			6504.147,
			6505.626,
			6506.121,
			6506.615,
			6508.077,
			6508.559,
			6509.037,
			6510.464,
			6511.413,
			6512.378,
			6512.859,
			6513.813,
			6514.287,
			6515.229,
			6516.164,
			6516.631,
			6517.642,
			6518.668,
			6520.177,
			6520.674,
			6521.17,
			6522.778,
			6523.377,
			6523.973,
			6525.75,
			6526.93,
			6529.134,
			6530.712,
			6532.295,
			6534.925,
			6535.811,
			6536.697,
			6539.375,
			6540.272,
			6541.168,
			6543.714,
			6545.059,
			6546.404,
			6547.079,
			6548.432,
			6549.544,
			6550.086,
			6551.171,
			6551.713,
			6552.801,
			6553.872,
			6555.375,
			6556.381,
			6557.901,
			6558.412,
			6558.924,
			6560.476,
			6561.519,
			6563.099,
			6564.165,
			6565.268,
			6565.823,
			6566.942,
			6567.506,
			6568.073,
			6569.801,
			6570.391,
			6570.984,
			6572.784,
			6574.003,
			6575.244,
			6575.875,
			6577.148,
			6578.439,
			6579.091,
			6580.409,
			6581.077,
			6582.425,
			6584.479,
			6585.871,
			6587.282,
			6589.419,
			6590.144,
			6590.873,
			6593.087,
			6594.585,
			6596.862,
			6598.402,
			6600.743,
			6602.324,
			6603.121,
			6604.727,
			6606.349,
			6607.166,
			6608.813,
			6610.475,
			6612.99,
			6613.83,
			6614.669,
			6616.357,
			6618.06,
			6618.917,
			6620.642,
			6621.51,
			6623.257,
			6625.019,
			6627.692,
			6629.491,
			6632.22,
			6633.137,
			6634.057,
			6636.841,
			6638.716,
			6641.555,
			6643.465,
			6645.39,
			6646.356,
			6648.299,
			6649.276,
			6651.239,
			6653.215,
			6654.207,
			6656.201,
			6658.208,
			6661.242,
			6662.259,
			6663.279,
			6666.362,
			6667.396,
			6668.434,
			6671.564,
			6673.661,
			6675.757,
			6678.914,
			6681.034,
			6684.229,
			6685.3,
			6686.374,
			6689.609,
			6691.781,
			6695.058,
			6697.257,
			6699.469,
			6700.579,
			6702.807,
			6705.047,
			6706.171,
			6708.428,
			6709.561,
			6711.834,
			6714.12,
			6717.57,
			6719.884,
			6723.377,
			6724.547,
			6725.72,
			6729.254,
			6731.625,
			6735.205,
			6737.604,
			6741.225,
			6743.654,
			6744.873,
			6747.319,
			6749.776,
			6751.009,
			6753.482,
			6755.967,
			6758.461,
			6760.967,
			6762.224,
			6764.744,
			6767.274,
			6768.543,
			6771.09,
			6772.367,
			6774.929,
			6777.499,
			6781.372,
			6783.967,
			6787.877,
			6789.185,
			6790.494,
			6794.422,
			6797.048,
			6801.005,
			6803.654,
			6806.31,
			6807.642,
			6810.311,
			6811.648,
			6814.33,
			6817.021,
			6818.369,
			6821.072,
			6823.784,
			6827.868,
			6829.234,
			6830.602,
			6833.343,
			6836.093,
			6837.47,
			6840.234,
			6841.619,
			6844.393,
			6847.174,
			6851.36,
			6854.161,
			6858.381,
			6859.792,
			6861.204,
			6865.453,
			6868.297,
			6872.578,
			6875.44,
			6878.312,
			6879.752,
			6882.635,
			6884.078,
			6886.97,
			6889.869,
			6891.322,
			6894.233,
			6897.149,
			6901.537,
			6904.47,
			6908.883,
			6910.357,
			6911.833,
			6916.27,
			6919.236,
			6922.21,
			6926.679,
			6929.667,
			6934.159,
			6935.66,
			6937.165,
			6941.691,
			6943.203,
			6944.716,
			6949.265,
			6952.306,
			6955.355,
			6956.883,
			6959.94,
			6963.005,
			6964.54,
			6967.616,
			6969.156,
			6972.241,
			6975.333,
			6979.676
		],
		[
			0,
			0.037,
			0.053,
			0.114,
			0.145,
			0.176,
			0.24,
			0.253,
			0.301,
			0.362,
			0.426,
			0.472,
			0.547,
			0.58,
			0.61,
			0.672,
			0.736,
			0.796,
			0.858,
			0.904,
			0.921,
			0.981,
			1.013,
			1.046,
			1.105,
			1.121,
			1.167,
			1.234,
			1.293,
			1.355,
			1.417,
			1.447,
			1.477,
			1.539,
			1.555,
			1.603,
			1.665,
			1.726,
			1.775,
			1.849,
			1.882,
			1.913,
			1.976,
			2.035,
			2.1,
			2.16,
			2.224,
			2.283,
			2.316,
			2.346,
			2.41,
			2.425,
			2.47,
			2.538,
			2.595,
			2.642,
			2.658,
			2.72,
			2.748,
			2.782,
			2.844,
			2.906,
			2.969,
			3.029,
			3.077,
			3.152,
			3.185,
			3.216,
			3.277,
			3.298,
			3.339,
			3.401,
			3.464,
			3.51,
			3.526,
			3.588,
			3.617,
			3.65,
			3.728,
			3.772,
			3.836,
			3.897,
			3.959,
			4.02,
			4.053,
			4.082,
			4.145,
			4.207,
			4.271,
			4.331,
			4.377,
			4.394,
			4.457,
			4.486,
			4.517,
			4.58,
			4.596,
			4.643,
			4.705,
			4.764,
			4.812,
			4.828,
			4.889,
			4.92,
			4.95,
			5.014,
			5.03,
			5.076,
			5.137,
			5.198,
			5.245,
			5.324,
			5.359,
			5.387,
			5.448,
			5.51,
			5.573,
			5.635,
			5.679,
			5.694,
			5.757,
			5.787,
			5.821,
			5.882,
			5.897,
			5.943,
			6.004,
			6.068,
			6.114,
			6.13,
			6.192,
			6.222,
			6.254,
			6.314,
			6.331,
			6.379,
			6.44,
			6.502,
			6.548,
			6.626,
			6.658,
			6.688,
			6.75,
			6.812,
			6.874,
			6.936,
			6.996,
			7.059,
			7.089,
			7.121,
			7.182,
			7.2,
			7.244,
			7.306,
			7.37,
			7.431,
			7.494,
			7.526,
			7.557,
			7.617,
			7.633,
			7.679,
			7.743,
			7.803,
			7.849,
			7.926,
			7.96,
			7.988,
			8.052,
			8.114,
			8.177,
			8.238,
			8.286,
			8.3,
			8.361,
			8.394,
			8.423,
			8.5,
			8.549,
			8.611,
			8.673,
			8.734,
			8.797,
			8.828,
			8.859,
			8.921,
			8.982,
			9.044,
			9.106,
			9.152,
			9.169,
			9.23,
			9.261,
			9.293,
			9.355,
			9.369,
			9.417,
			9.482,
			9.541,
			9.603,
			9.665,
			9.696,
			9.726,
			9.802,
			9.851,
			9.913,
			9.973,
			10.037,
			10.097,
			10.129,
			10.16,
			10.22,
			10.285,
			10.345,
			10.408,
			10.471,
			10.531,
			10.563,
			10.593,
			10.673,
			10.718,
			10.78,
			10.843,
			10.889,
			10.906,
			10.965,
			10.996,
			11.03,
			11.106,
			11.154,
			11.214,
			11.277,
			11.322,
			11.399,
			11.432,
			11.464,
			11.525,
			11.544,
			11.587,
			11.649,
			11.71,
			11.758,
			11.771,
			11.835,
			11.866,
			11.897,
			11.96,
			11.975,
			12.019,
			12.081,
			12.143,
			12.205,
			12.268,
			12.3,
			12.331,
			12.393,
			12.406,
			12.453,
			12.517,
			12.578,
			12.626,
			12.702,
			12.732,
			12.764,
			12.843,
			12.888,
			12.949,
			13.014,
			13.06,
			13.075,
			13.137,
			13.168,
			13.2,
			13.276,
			13.321,
			13.386,
			13.446,
			13.51,
			13.571,
			13.606,
			13.632,
			13.695,
			13.756,
			13.817,
			13.881,
			13.926,
			14.004,
			14.037,
			14.068,
			14.129,
			14.145,
			14.192,
			14.254,
			14.314,
			14.36,
			14.377,
			14.438,
			14.47,
			14.502,
			14.562,
			14.579,
			14.625,
			14.689,
			14.748,
			14.797,
			14.872,
			14.903,
			14.934,
			14.997,
			15.06,
			15.121,
			15.182,
			15.23,
			15.244,
			15.306,
			15.339,
			15.369,
			15.447,
			15.493,
			15.556,
			15.616,
			15.679,
			15.74,
			15.771,
			15.804,
			15.864,
			15.881,
			15.926,
			15.987,
			16.051,
			16.097,
			16.175,
			16.204,
			16.238,
			16.314,
			16.362,
			16.424,
			16.484,
			16.533,
			16.547,
			16.61,
			16.642,
			16.671,
			16.733,
			16.748,
			16.797,
			16.858,
			16.919,
			16.966,
			16.982,
			17.044,
			17.075,
			17.105,
			17.168,
			17.182,
			17.229,
			17.29,
			17.354,
			17.417,
			17.476,
			17.508,
			17.541,
			17.616,
			17.663,
			17.727,
			17.789,
			17.834,
			17.85,
			17.911,
			17.943,
			17.972,
			18.035,
			18.05,
			18.097,
			18.16,
			18.221,
			18.269,
			18.345,
			18.377,
			18.409,
			18.47,
			18.531,
			18.594,
			18.656,
			18.703,
			18.78,
			18.811,
			18.843,
			18.903,
			18.92,
			18.966,
			19.029,
			19.088,
			19.137,
			19.152,
			19.213,
			19.243,
			19.277,
			19.339,
			19.352,
			19.399,
			19.464,
			19.523,
			19.569,
			19.647,
			19.679,
			19.71,
			19.773,
			19.835,
			19.896,
			19.956,
			20.006,
			20.021,
			20.081,
			20.112,
			20.144,
			20.223,
			20.266,
			20.33,
			20.392,
			20.454,
			20.517,
			20.548,
			20.577,
			20.641,
			20.655,
			20.702,
			20.764,
			20.826,
			20.872,
			20.951,
			20.982,
			21.011,
			21.073,
			21.135,
			21.199,
			21.261,
			21.305,
			21.321,
			21.384,
			21.414,
			21.447,
			21.525,
			21.57,
			21.633,
			21.694,
			21.741,
			21.754,
			21.817,
			21.854,
			21.88,
			21.942,
			21.956,
			22.004,
			22.067,
			22.127,
			22.192,
			22.251,
			22.282,
			22.314,
			22.377,
			22.439,
			22.501,
			22.562,
			22.61,
			22.624,
			22.686,
			22.717,
			22.747,
			22.81,
			22.826,
			22.873,
			22.936,
			22.995,
			23.043,
			23.059,
			23.12,
			23.153,
			23.183,
			23.245,
			23.307,
			23.37,
			23.429,
			23.478,
			23.554,
			23.585,
			23.617,
			23.694,
			23.742,
			23.803,
			23.866,
			23.917,
			23.926,
			23.988,
			24.018,
			24.05,
			24.114,
			24.128,
			24.176,
			24.238,
			24.297,
			24.346,
			24.421,
			24.454,
			24.485,
			24.546,
			24.61,
			24.672,
			24.733,
			24.778,
			24.794,
			24.857,
			24.888,
			24.919,
			24.981,
			24.996,
			25.043,
			25.105,
			25.167,
			25.213,
			25.23,
			25.29,
			25.322,
			25.352,
			25.413,
			25.431,
			25.477,
			25.538,
			25.602,
			25.646,
			25.723,
			25.755,
			25.786,
			25.864,
			25.912,
			25.977,
			26.035,
			26.097,
			26.16,
			26.189,
			26.221,
			26.283,
			26.298,
			26.344,
			26.409,
			26.47,
			26.515,
			26.532,
			26.592,
			26.624,
			26.655,
			26.718,
			26.733,
			26.778,
			26.841,
			26.902,
			26.949,
			27.028,
			27.058,
			27.089,
			27.152,
			27.214,
			27.277,
			27.338,
			27.383,
			27.4,
			27.46,
			27.493,
			27.523,
			27.585,
			27.6,
			27.649,
			27.71,
			27.77,
			27.817,
			27.834,
			27.896,
			27.927,
			27.956,
			28.019,
			28.08,
			28.145,
			28.205,
			28.252,
			28.328,
			28.359,
			28.392,
			28.455,
			28.469,
			28.517,
			28.576,
			28.638,
			28.701,
			28.764,
			28.793,
			28.826,
			28.904,
			28.949,
			29.012,
			29.073,
			29.121,
			29.198,
			29.228,
			29.259,
			29.321,
			29.383,
			29.447,
			29.506,
			29.555,
			29.57,
			29.631,
			29.663,
			29.693,
			29.756,
			29.772,
			29.817,
			29.881,
			29.941,
			29.987,
			30.005,
			30.064,
			30.101,
			30.126,
			30.204,
			30.251,
			30.314,
			30.377,
			30.421,
			30.498,
			30.532,
			30.561,
			30.624,
			30.685,
			30.748,
			30.81,
			30.858,
			30.871,
			30.935,
			30.963,
			30.996,
			31.056,
			31.073,
			31.119,
			31.182,
			31.244,
			31.29,
			31.306,
			31.369,
			31.399,
			31.43,
			31.493,
			31.507,
			31.554,
			31.616,
			31.677,
			31.723,
			31.802,
			31.832,
			31.865,
			31.942,
			31.989,
			32.05,
			32.112,
			32.162,
			32.175,
			32.235,
			32.266,
			32.299,
			32.361,
			32.376,
			32.421,
			32.485,
			32.547,
			32.593,
			32.606,
			32.67,
			32.7,
			32.732,
			32.795,
			32.856,
			32.92,
			32.981,
			33.028,
			33.103,
			33.136,
			33.165,
			33.228,
			33.29,
			33.356,
			33.413,
			33.461,
			33.477,
			33.538,
			33.57,
			33.599,
			33.661,
			33.679,
			33.724,
			33.788,
			33.849,
			33.894,
			33.973,
			34.003,
			34.035,
			34.098,
			34.16,
			34.225,
			34.283,
			34.329,
			34.406,
			34.437,
			34.467,
			34.53,
			34.547,
			34.593,
			34.655,
			34.716,
			34.764,
			34.778,
			34.839,
			34.871,
			34.903,
			34.966,
			34.979,
			35.027,
			35.089,
			35.149,
			35.197,
			35.275,
			35.304,
			35.335,
			35.398,
			35.461,
			35.524,
			35.586,
			35.631,
			35.648,
			35.709,
			35.741,
			35.771,
			35.834,
			35.847,
			35.895,
			35.956,
			36.019,
			36.064,
			36.081,
			36.142,
			36.175,
			36.204,
			36.267,
			36.286,
			36.33,
			36.39,
			36.451,
			36.516,
			36.576,
			36.608,
			36.638,
			36.701,
			36.762,
			36.826,
			36.888,
			36.949,
			37.012,
			37.042,
			37.071,
			37.136,
			37.151,
			37.196,
			37.259,
			37.321,
			37.368,
			37.382,
			37.445,
			37.476,
			37.506,
			37.569,
			37.631,
			37.694,
			37.756,
			37.8,
			37.88,
			37.91,
			37.942,
			38.003,
			38.064,
			38.126,
			38.187,
			38.252,
			38.314,
			38.349,
			38.376,
			38.436,
			38.453,
			38.499,
			38.56,
			38.624,
			38.67,
			38.748,
			38.779,
			38.81,
			38.87,
			38.934,
			38.997,
			39.057,
			39.104,
			39.119,
			39.181,
			39.212,
			39.243,
			39.306,
			39.321,
			39.366,
			39.429,
			39.493,
			39.539,
			39.553,
			39.617,
			39.646,
			39.676,
			39.738,
			39.756,
			39.801,
			39.864,
			39.927,
			39.973,
			40.048,
			40.082,
			40.112,
			40.172,
			40.236,
			40.297,
			40.359,
			40.422,
			40.483,
			40.516,
			40.545,
			40.624,
			40.669,
			40.731,
			40.794,
			40.839,
			40.854,
			40.919,
			40.949,
			40.98,
			41.058,
			41.104,
			41.166,
			41.229,
			41.275,
			41.352,
			41.384,
			41.415,
			41.489,
			41.537,
			41.6,
			41.66,
			41.709,
			41.722,
			41.784,
			41.817,
			41.847,
			41.91,
			41.926,
			41.971,
			42.034,
			42.097,
			42.143,
			42.157,
			42.221,
			42.25,
			42.282,
			42.344,
			42.406,
			42.468,
			42.471,
			42.531,
			42.577,
			42.655,
			42.685,
			42.717,
			42.778,
			42.841,
			42.903,
			42.965,
			43.01,
			43.027,
			43.089,
			43.12,
			43.148,
			43.212,
			43.227,
			43.274,
			43.335,
			43.399,
			43.446,
			43.521,
			43.552,
			43.585,
			43.647,
			43.709,
			43.769,
			43.832,
			43.878,
			43.956,
			43.988,
			44.019,
			44.096,
			44.143,
			44.206,
			44.267,
			44.313,
			44.328,
			44.391,
			44.42,
			44.453,
			44.514,
			44.534,
			44.576,
			44.637,
			44.701,
			44.747,
			44.825,
			44.856,
			44.887,
			44.949,
			45.011,
			45.072,
			45.133,
			45.181,
			45.195,
			45.259,
			45.289,
			45.322,
			45.383,
			45.397,
			45.443,
			45.508,
			45.567,
			45.63,
			45.694,
			45.723,
			45.755,
			45.816,
			45.833,
			45.879,
			45.939,
			46.004,
			46.049,
			46.127,
			46.159,
			46.189,
			46.266,
			46.312,
			46.373,
			46.436,
			46.5,
			46.561,
			46.596,
			46.622,
			46.686,
			46.7,
			46.747,
			46.81,
			46.871,
			46.917,
			46.931,
			46.994,
			47.027,
			47.055,
			47.119,
			47.182,
			47.243,
			47.306,
			47.35,
			47.429,
			47.46,
			47.489,
			47.553,
			47.569,
			47.616,
			47.677,
			47.739,
			47.785,
			47.801,
			47.864,
			47.893,
			47.926,
			47.986,
			48.003,
			48.049,
			48.111,
			48.173,
			48.218,
			48.236,
			48.298,
			48.327,
			48.359,
			48.421,
			48.483,
			48.544,
			48.606,
			48.66,
			48.731,
			48.762,
			48.794,
			48.854,
			48.871,
			48.918,
			48.98,
			49.042,
			49.102,
			49.164,
			49.197,
			49.227,
			49.288,
			49.306,
			49.351,
			49.413,
			49.475,
			49.521,
			49.598,
			49.63,
			49.662,
			49.737,
			49.786,
			49.847,
			49.91,
			49.955,
			49.971,
			50.034,
			50.064,
			50.094,
			50.158,
			50.174,
			50.218,
			50.281,
			50.343,
			50.391,
			50.406,
			50.467,
			50.499,
			50.529,
			50.591,
			50.605,
			50.654,
			50.721,
			50.776,
			50.839,
			50.9,
			50.932,
			50.965,
			51.041,
			51.089,
			51.15,
			51.212,
			51.258,
			51.272,
			51.336,
			51.365,
			51.397,
			51.46,
			51.476,
			51.521,
			51.582,
			51.647,
			51.693,
			51.707,
			51.768,
			51.8,
			51.83,
			51.91,
			51.954,
			52.019,
			52.079,
			52.126,
			52.202,
			52.236,
			52.266,
			52.342,
			52.389,
			52.452,
			52.513,
			52.561,
			52.576,
			52.637,
			52.668,
			52.698,
			52.762,
			52.782,
			52.824,
			52.886,
			52.947,
			52.994,
			53.073,
			53.103,
			53.134,
			53.196,
			53.258,
			53.322,
			53.382,
			53.429,
			53.504,
			53.537,
			53.566,
			53.645,
			53.692,
			53.753,
			53.816,
			53.862,
			53.879,
			53.939,
			53.97,
			54.002,
			54.065,
			54.079,
			54.127,
			54.189,
			54.251,
			54.298,
			54.374,
			54.404,
			54.437,
			54.515,
			54.559,
			54.621,
			54.685,
			54.732,
			54.746,
			54.806,
			54.845,
			54.871,
			54.931,
			54.947,
			54.993,
			55.056,
			55.117,
			55.18,
			55.243,
			55.274,
			55.304,
			55.367,
			55.381,
			55.429,
			55.488,
			55.552,
			55.599,
			55.676,
			55.706,
			55.739,
			55.8,
			55.863,
			55.928,
			55.987,
			56.049,
			56.11,
			56.142,
			56.171,
			56.235,
			56.25,
			56.296,
			56.359,
			56.419,
			56.467,
			56.483,
			56.545,
			56.577,
			56.606,
			56.683,
			56.73,
			56.793,
			56.853,
			56.905,
			56.979,
			57.011,
			57.04,
			57.103,
			57.166,
			57.227,
			57.288,
			57.336,
			57.351,
			57.413,
			57.444,
			57.475,
			57.538,
			57.551,
			57.598,
			57.66,
			57.721,
			57.769,
			57.846,
			57.878,
			57.91,
			57.972,
			58.034,
			58.095,
			58.157,
			58.219,
			58.281,
			58.311,
			58.342,
			58.419,
			58.467,
			58.529,
			58.591,
			58.636,
			58.652,
			58.713,
			58.747,
			58.777,
			58.853,
			58.9,
			58.966,
			59.023,
			59.071,
			59.15,
			59.182,
			59.21,
			59.288,
			59.334,
			59.397,
			59.459,
			59.505,
			59.52,
			59.582,
			59.615,
			59.646,
			59.706,
			59.723,
			59.769,
			59.833,
			59.893,
			59.954,
			60.018,
			60.049,
			60.079,
			60.155,
			60.202,
			60.265,
			60.328,
			60.39,
			60.452,
			60.481,
			60.514,
			60.573,
			60.636,
			60.699,
			60.761,
			60.821,
			60.884,
			60.917,
			60.947,
			61.01,
			61.029,
			61.071,
			61.134,
			61.196,
			61.256,
			61.32,
			61.349,
			61.382,
			61.459,
			61.505,
			61.568,
			61.63,
			61.677,
			61.752,
			61.783,
			61.814,
			61.876,
			61.941,
			62.002,
			62.063,
			62.11,
			62.123,
			62.186,
			62.218,
			62.249,
			62.31,
			62.327,
			62.373,
			62.436,
			62.499,
			62.561,
			62.621,
			62.653,
			62.684,
			62.745,
			62.807,
			62.869,
			62.931,
			62.977,
			62.995,
			63.054,
			63.091,
			63.116,
			63.194,
			63.243,
			63.305,
			63.364,
			63.412,
			63.429,
			63.489,
			63.521,
			63.552,
			63.63,
			63.676,
			63.737,
			63.799,
			63.86,
			63.923,
			63.954,
			63.985,
			64.049,
			64.109,
			64.173,
			64.234,
			64.296,
			64.358,
			64.388,
			64.421,
			64.482,
			64.496,
			64.544,
			64.605,
			64.667,
			64.713,
			64.731,
			64.792,
			64.823,
			64.853,
			64.917,
			64.931,
			64.977,
			65.038,
			65.102,
			65.153,
			65.227,
			65.255,
			65.289,
			65.349,
			65.412,
			65.475,
			65.535,
			65.582,
			65.599,
			65.661,
			65.691,
			65.721,
			65.8,
			65.847,
			65.909,
			65.971,
			66.017,
			66.033,
			66.095,
			66.125,
			66.156,
			66.235,
			66.28,
			66.343,
			66.404,
			66.449,
			66.529,
			66.559,
			66.588,
			66.667,
			66.713,
			66.776,
			66.839,
			66.884,
			66.899,
			66.961,
			66.994,
			67.023,
			67.101,
			67.147,
			67.213,
			67.27,
			67.319,
			67.397,
			67.428,
			67.458,
			67.521,
			67.581,
			67.644,
			67.706,
			67.754,
			67.831,
			67.861,
			67.893,
			67.955,
			67.971,
			68.018,
			68.079,
			68.142,
			68.186,
			68.204,
			68.264,
			68.295,
			68.326,
			68.389,
			68.404,
			68.451,
			68.514,
			68.575,
			68.62,
			68.699,
			68.73,
			68.759,
			68.823,
			68.884,
			68.947,
			69.006,
			69.054,
			69.072,
			69.134,
			69.164,
			69.194,
			69.277,
			69.319,
			69.38,
			69.444,
			69.489,
			69.505,
			69.568,
			69.598,
			69.628,
			69.691,
			69.704,
			69.754,
			69.814,
			69.875,
			69.923,
			70,
			70.033,
			70.063,
			70.125,
			70.187,
			70.248,
			70.311,
			70.359,
			70.371,
			70.435,
			70.467,
			70.497,
			70.559,
			70.573,
			70.621,
			70.682,
			70.744,
			70.808,
			70.869,
			70.899,
			70.931,
			71.01,
			71.054,
			71.116,
			71.18,
			71.24,
			71.304,
			71.337,
			71.364,
			71.427,
			71.487,
			71.553,
			71.612,
			71.676,
			71.736,
			71.769,
			71.8,
			71.862,
			71.877,
			71.923,
			71.986,
			72.048,
			72.094,
			72.171,
			72.203,
			72.233,
			72.296,
			72.358,
			72.421,
			72.481,
			72.526,
			72.604,
			72.635,
			72.667,
			72.729,
			72.744,
			72.792,
			72.852,
			72.915,
			72.961,
			72.976,
			73.039,
			73.07,
			73.102,
			73.162,
			73.179,
			73.226,
			73.289,
			73.349,
			73.401,
			73.471,
			73.504,
			73.536,
			73.596,
			73.659,
			73.721,
			73.785,
			73.829,
			73.847,
			73.906,
			73.939,
			73.97,
			74.031,
			74.048,
			74.094,
			74.155,
			74.218,
			74.28,
			74.34,
			74.371,
			74.405,
			74.466,
			74.48,
			74.526,
			74.589,
			74.653,
			74.715,
			74.776,
			74.807,
			74.838,
			74.899,
			74.96,
			75.024,
			75.086,
			75.132,
			75.147,
			75.21,
			75.241,
			75.271,
			75.334,
			75.35,
			75.397,
			75.464,
			75.519,
			75.583,
			75.643,
			75.675,
			75.705,
			75.767,
			75.784,
			75.828,
			75.893,
			75.954,
			76.001,
			76.076,
			76.109,
			76.139,
			76.202,
			76.263,
			76.327,
			76.389,
			76.434,
			76.451,
			76.512,
			76.543,
			76.575,
			76.652,
			76.697,
			76.76,
			76.821,
			76.885,
			76.947,
			76.977,
			77.006,
			77.071,
			77.133,
			77.194,
			77.255,
			77.317,
			77.38,
			77.411,
			77.443,
			77.504,
			77.526,
			77.565,
			77.628,
			77.689,
			77.736,
			77.751,
			77.815,
			77.845,
			77.875,
			77.938,
			77.953,
			78.001,
			78.066,
			78.124,
			78.185,
			78.249,
			78.279,
			78.311,
			78.386,
			78.435,
			78.498,
			78.559,
			78.605,
			78.619,
			78.681,
			78.713,
			78.744,
			78.821,
			78.868,
			78.931,
			78.992,
			79.038,
			79.056,
			79.115,
			79.146,
			79.179,
			79.239,
			79.256,
			79.303,
			79.363,
			79.426,
			79.473,
			79.549,
			79.586,
			79.613,
			79.675,
			79.738,
			79.797,
			79.861,
			79.922,
			79.986,
			80.016,
			80.047,
			80.106,
			80.124,
			80.171,
			80.232,
			80.295,
			80.342,
			80.355,
			80.42,
			80.449,
			80.48,
			80.543,
			80.559,
			80.605,
			80.667,
			80.728,
			80.773,
			80.852,
			80.884,
			80.914,
			80.976,
			81.039,
			81.099,
			81.163,
			81.21,
			81.225,
			81.287,
			81.318,
			81.35,
			81.411,
			81.426,
			81.471,
			81.535,
			81.596,
			81.649,
			81.72,
			81.751,
			81.782,
			81.846,
			81.906,
			81.969,
			82.032,
			82.077,
			82.094,
			82.155,
			82.186,
			82.217,
			82.294,
			82.341,
			82.403,
			82.466,
			82.512,
			82.527,
			82.589,
			82.62,
			82.652,
			82.713,
			82.73,
			82.775,
			82.838,
			82.9,
			82.944,
			83.023,
			83.053,
			83.086,
			83.162,
			83.21,
			83.273,
			83.332,
			83.38,
			83.394,
			83.456,
			83.488,
			83.52,
			83.597,
			83.644,
			83.711,
			83.768,
			83.814,
			83.83,
			83.891,
			83.923,
			83.953,
			84.016,
			84.029,
			84.078,
			84.138,
			84.202,
			84.263,
			84.326,
			84.355,
			84.386,
			84.45,
			84.51,
			84.575,
			84.634,
			84.681,
			84.696,
			84.759,
			84.792,
			84.821,
			84.882,
			84.899,
			84.946,
			85.006,
			85.069,
			85.116,
			85.131,
			85.194,
			85.224,
			85.254,
			85.318,
			85.332,
			85.38,
			85.442,
			85.503,
			85.551,
			85.628,
			85.659,
			85.689,
			85.751,
			85.773,
			85.815,
			85.877,
			85.938,
			85.982,
			85.999,
			86.062,
			86.094,
			86.123,
			86.202,
			86.249,
			86.31,
			86.371,
			86.417,
			86.434,
			86.495,
			86.526,
			86.559,
			86.634,
			86.68,
			86.744,
			86.806,
			86.852,
			86.931,
			86.96,
			86.993,
			87.053,
			87.069,
			87.114,
			87.177,
			87.241,
			87.285,
			87.303,
			87.363,
			87.395,
			87.426,
			87.487,
			87.502,
			87.549,
			87.612,
			87.675,
			87.719,
			87.797,
			87.834,
			87.86,
			87.936,
			87.983,
			88.049,
			88.106,
			88.154,
			88.231,
			88.262,
			88.295,
			88.354,
			88.371,
			88.419,
			88.481,
			88.543,
			88.589,
			88.604,
			88.666,
			88.697,
			88.729,
			88.791,
			88.806,
			88.853,
			88.914,
			88.976,
			89.021,
			89.101,
			89.132,
			89.163,
			89.222,
			89.287,
			89.349,
			89.41,
			89.456,
			89.471,
			89.535,
			89.566,
			89.596,
			89.659,
			89.674,
			89.721,
			89.781,
			89.844,
			89.907,
			89.968,
			89.999,
			90.031,
			90.092,
			90.108,
			90.154,
			90.215,
			90.278,
			90.341,
			90.403,
			90.434,
			90.463,
			90.527,
			90.589,
			90.649,
			90.711,
			90.759,
			90.773,
			90.837,
			90.868,
			90.899,
			90.96,
			90.977,
			91.023,
			91.086,
			91.146,
			91.208,
			91.271,
			91.302,
			91.333,
			91.396,
			91.457,
			91.521,
			91.58,
			91.628,
			91.706,
			91.736,
			91.767,
			91.829,
			91.844,
			91.889,
			91.956,
			92.014,
			92.06,
			92.078,
			92.138,
			92.169,
			92.2,
			92.264,
			92.278,
			92.326,
			92.388,
			92.448,
			92.496,
			92.571,
			92.604,
			92.636,
			92.697,
			92.758,
			92.821,
			92.884,
			92.93,
			93.008,
			93.038,
			93.069,
			93.129,
			93.146,
			93.193,
			93.256,
			93.318,
			93.378,
			93.441,
			93.472,
			93.504,
			93.564,
			93.579,
			93.627,
			93.689,
			93.75,
			93.796,
			93.876,
			93.906,
			93.937,
			94.019,
			94.06,
			94.125,
			94.186,
			94.232,
			94.246,
			94.31,
			94.339,
			94.371,
			94.432,
			94.449,
			94.494,
			94.557,
			94.618,
			94.681,
			94.744,
			94.775,
			94.804,
			94.867,
			94.883,
			94.929,
			94.991,
			95.053,
			95.099,
			95.176,
			95.206,
			95.238,
			95.302,
			95.364,
			95.425,
			95.488,
			95.535,
			95.549,
			95.61,
			95.643,
			95.674,
			95.735,
			95.752,
			95.797,
			95.86,
			95.922,
			95.969,
			96.046,
			96.08,
			96.109,
			96.185,
			96.231,
			96.294,
			96.355,
			96.418,
			96.479,
			96.511,
			96.541,
			96.618,
			96.664,
			96.729,
			96.788,
			96.852,
			96.913,
			96.945,
			96.977,
			97.037,
			97.052,
			97.1,
			97.162,
			97.223,
			97.269,
			97.286,
			97.346,
			97.377,
			97.41,
			97.471,
			97.532,
			97.597,
			97.658,
			97.703,
			97.719,
			97.782,
			97.814,
			97.843,
			97.906,
			97.92,
			97.967,
			98.029,
			98.093,
			98.143,
			98.152,
			98.215,
			98.246,
			98.277,
			98.354,
			98.402,
			98.466,
			98.526,
			98.586,
			98.65,
			98.681,
			98.71,
			98.787,
			98.835,
			98.899,
			98.959,
			99.02,
			99.084,
			99.116,
			99.145,
			99.206,
			99.224,
			99.27,
			99.332,
			99.395,
			99.442,
			99.456,
			99.518,
			99.549,
			99.581,
			99.643,
			99.659,
			99.704,
			99.766,
			99.829,
			99.873,
			99.952,
			99.982,
			100.015,
			100.093,
			100.138,
			100.203,
			100.261,
			100.325,
			100.385,
			100.417,
			100.447,
			100.509,
			100.526,
			100.571,
			100.634,
			100.696,
			100.744,
			100.82,
			100.852,
			100.881,
			100.944,
			101.006,
			101.069,
			101.131,
			101.192,
			101.255,
			101.286,
			101.317,
			101.377,
			101.439,
			101.504,
			101.564,
			101.609,
			101.627,
			101.689,
			101.718,
			101.75,
			101.812,
			101.828,
			101.875,
			101.938,
			101.999,
			102.046,
			102.121,
			102.153,
			102.184,
			102.246,
			102.31,
			102.371,
			102.433,
			102.479,
			102.556,
			102.587,
			102.618,
			102.68,
			102.696,
			102.742,
			102.805,
			102.865,
			102.914,
			102.928,
			102.99,
			103.023,
			103.051,
			103.131,
			103.177,
			103.239,
			103.301,
			103.364,
			103.425,
			103.456,
			103.486,
			103.55,
			103.609,
			103.672,
			103.735,
			103.781,
			103.796,
			103.86,
			103.888,
			103.919,
			103.982,
			103.997,
			104.044,
			104.108,
			104.169,
			104.214,
			104.232,
			104.294,
			104.326,
			104.355,
			104.433,
			104.478,
			104.539,
			104.602,
			104.649,
			104.726,
			104.756,
			104.789,
			104.866,
			104.914,
			104.975,
			105.035,
			105.084,
			105.099,
			105.161,
			105.193,
			105.221,
			105.302,
			105.347,
			105.411,
			105.471,
			105.516,
			105.534,
			105.596,
			105.627,
			105.657,
			105.718,
			105.736,
			105.781,
			105.843,
			105.905,
			105.95,
			106.029,
			106.059,
			106.092,
			106.152,
			106.215,
			106.277,
			106.338,
			106.402,
			106.464,
			106.495,
			106.525,
			106.588,
			106.602,
			106.648,
			106.711,
			106.771,
			106.82,
			106.896,
			106.929,
			106.959,
			107.02,
			107.084,
			107.145,
			107.208,
			107.254,
			107.269,
			107.331,
			107.361,
			107.393,
			107.47,
			107.517,
			107.579,
			107.642,
			107.688,
			107.704,
			107.766,
			107.796,
			107.827,
			107.904,
			107.951,
			108.014,
			108.077,
			108.138,
			108.199,
			108.231,
			108.262,
			108.322,
			108.386,
			108.451,
			108.51,
			108.555,
			108.571,
			108.634,
			108.666,
			108.695,
			108.756,
			108.773,
			108.82,
			108.881,
			108.945,
			108.989,
			109.006,
			109.067,
			109.097,
			109.129,
			109.193,
			109.207,
			109.254,
			109.314,
			109.376,
			109.425,
			109.501,
			109.534,
			109.563,
			109.626,
			109.688,
			109.75,
			109.811,
			109.873,
			109.936,
			109.965,
			109.997,
			110.059,
			110.075,
			110.121,
			110.184,
			110.246,
			110.292,
			110.306,
			110.375,
			110.401,
			110.433,
			110.514,
			110.556,
			110.618,
			110.68,
			110.743,
			110.803,
			110.836,
			110.866,
			110.944,
			110.989,
			111.052,
			111.113,
			111.176,
			111.237,
			111.268,
			111.299,
			111.362,
			111.377,
			111.425,
			111.486,
			111.549,
			111.61,
			111.671,
			111.702,
			111.734,
			111.797,
			111.859,
			111.919,
			111.981,
			112.029,
			112.044,
			112.105,
			112.138,
			112.169,
			112.229,
			112.246,
			112.293,
			112.36,
			112.415,
			112.479,
			112.539,
			112.577,
			112.602,
			112.664,
			112.681,
			112.726,
			112.788,
			112.85,
			112.896,
			112.975,
			113.004,
			113.037,
			113.098,
			113.159,
			113.22,
			113.284,
			113.331,
			113.347,
			113.41,
			113.439,
			113.471,
			113.547,
			113.594,
			113.655,
			113.72,
			113.765,
			113.78,
			113.842,
			113.874,
			113.903,
			113.966,
			113.983,
			114.03,
			114.089,
			114.152,
			114.214,
			114.278,
			114.309,
			114.337,
			114.403,
			114.462,
			114.523,
			114.586,
			114.647,
			114.712,
			114.742,
			114.771,
			114.851,
			114.898,
			114.959,
			115.021,
			115.068,
			115.083,
			115.146,
			115.176,
			115.206,
			115.267,
			115.283,
			115.331,
			115.394,
			115.454,
			115.517,
			115.578,
			115.61,
			115.642,
			115.702,
			115.764,
			115.827,
			115.888,
			115.95,
			116.014,
			116.043,
			116.076,
			116.151,
			116.198,
			116.262,
			116.324,
			116.386,
			116.447,
			116.477,
			116.51,
			116.57,
			116.634,
			116.701,
			116.756,
			116.818,
			116.881,
			116.912,
			116.943,
			117.021,
			117.067,
			117.129,
			117.192,
			117.253,
			117.314,
			117.345,
			117.377,
			117.454,
			117.502,
			117.565,
			117.625,
			117.67,
			117.748,
			117.779,
			117.812,
			117.874,
			117.936,
			117.998,
			118.059,
			118.104,
			118.121,
			118.183,
			118.215,
			118.245,
			118.305,
			118.322,
			118.369,
			118.431,
			118.494,
			118.556,
			118.616,
			118.648,
			118.68,
			118.742,
			118.76,
			118.804,
			118.865,
			118.926,
			118.974,
			119.051,
			119.082,
			119.114,
			119.175,
			119.237,
			119.298,
			119.362,
			119.406,
			119.423,
			119.485,
			119.518,
			119.547,
			119.609,
			119.623,
			119.672,
			119.734,
			119.796,
			119.843,
			119.858,
			119.92,
			119.949,
			119.982,
			120.043,
			120.06,
			120.104,
			120.168,
			120.229,
			120.277,
			120.354,
			120.384,
			120.415,
			120.478,
			120.539,
			120.601,
			120.664,
			120.726,
			120.789,
			120.821,
			120.851,
			120.911,
			120.927,
			120.974,
			121.036,
			121.098,
			121.144,
			121.223,
			121.252,
			121.284,
			121.347,
			121.406,
			121.47,
			121.531,
			121.578,
			121.656,
			121.686,
			121.719,
			121.779,
			121.795,
			121.842,
			121.905,
			121.965,
			122.012,
			122.029,
			122.089,
			122.121,
			122.153,
			122.215,
			122.23,
			122.277,
			122.339,
			122.4,
			122.447,
			122.523,
			122.556,
			122.586,
			122.663,
			122.71,
			122.771,
			122.834,
			122.896,
			122.956,
			122.989,
			123.02,
			123.097,
			123.144,
			123.206,
			123.268,
			123.315,
			123.331,
			123.393,
			123.424,
			123.453,
			123.517,
			123.531,
			123.579,
			123.639,
			123.703,
			123.748,
			123.827,
			123.858,
			123.889,
			123.965,
			124.012,
			124.072,
			124.136,
			124.182,
			124.198,
			124.259,
			124.292,
			124.321,
			124.384,
			124.398,
			124.446,
			124.509,
			124.569,
			124.616,
			124.631,
			124.695,
			124.725,
			124.758,
			124.817,
			124.833,
			124.881,
			124.946,
			125.004,
			125.051,
			125.13,
			125.16,
			125.191,
			125.269,
			125.316,
			125.377,
			125.439,
			125.502,
			125.563,
			125.595,
			125.625,
			125.701,
			125.749,
			125.81,
			125.872,
			125.918,
			125.934,
			125.996,
			126.028,
			126.058,
			126.135,
			126.184,
			126.246,
			126.308,
			126.352,
			126.37,
			126.431,
			126.463,
			126.493,
			126.553,
			126.57,
			126.617,
			126.678,
			126.741,
			126.786,
			126.802,
			126.863,
			126.896,
			126.926,
			126.989,
			127.01,
			127.051,
			127.113,
			127.176,
			127.221,
			127.297,
			127.329,
			127.36,
			127.438,
			127.486,
			127.546,
			127.61,
			127.655,
			127.671,
			127.733,
			127.763,
			127.795,
			127.856,
			127.872,
			127.919,
			127.981,
			128.044,
			128.088,
			128.104,
			128.167,
			128.199,
			128.229,
			128.292,
			128.305,
			128.352,
			128.415,
			128.478,
			128.525,
			128.601,
			128.632,
			128.663,
			128.739,
			128.787,
			128.849,
			128.911,
			128.958,
			128.972,
			129.034,
			129.069,
			129.098,
			129.159,
			129.175,
			129.221,
			129.282,
			129.345,
			129.393,
			129.407,
			129.47,
			129.5,
			129.531,
			129.592,
			129.61,
			129.654,
			129.716,
			129.779,
			129.826,
			129.902,
			129.933,
			129.964,
			130.026,
			130.044,
			130.09,
			130.151,
			130.213,
			130.259,
			130.276,
			130.336,
			130.368,
			130.4,
			130.461,
			130.475,
			130.524,
			130.585,
			130.646,
			130.693,
			130.71,
			130.771,
			130.801,
			130.832,
			130.896,
			130.958,
			131.02,
			131.081,
			131.132,
			131.204,
			131.236,
			131.267,
			131.329,
			131.345,
			131.391,
			131.453,
			131.515,
			131.561,
			131.578,
			131.639,
			131.671,
			131.701,
			131.762,
			131.78,
			131.826,
			131.889,
			131.949,
			132.011,
			132.072,
			132.104,
			132.136,
			132.196,
			132.261,
			132.323,
			132.384,
			132.43,
			132.446,
			132.508,
			132.54,
			132.568,
			132.632,
			132.646,
			132.693,
			132.754,
			132.818,
			132.865,
			132.88,
			132.943,
			132.971,
			133.004,
			133.067,
			133.081,
			133.129,
			133.194,
			133.253,
			133.298,
			133.376,
			133.406,
			133.437,
			133.501,
			133.562,
			133.625,
			133.685,
			133.748,
			133.81,
			133.841,
			133.872,
			133.935,
			133.949,
			133.997,
			134.062,
			134.121,
			134.166,
			134.183,
			134.244,
			134.275,
			134.304,
			134.368,
			134.385,
			134.43,
			134.493,
			134.553,
			134.6,
			134.677,
			134.709,
			134.74,
			134.802,
			134.865,
			134.927,
			134.989,
			135.036,
			135.05,
			135.111,
			135.143,
			135.176,
			135.236,
			135.255,
			135.297,
			135.36,
			135.421,
			135.468,
			135.485,
			135.546,
			135.577,
			135.606,
			135.671,
			135.733,
			135.793,
			135.858,
			135.903,
			135.981,
			136.01,
			136.042,
			136.105,
			136.167,
			136.228,
			136.291,
			136.338,
			136.352,
			136.415,
			136.444,
			136.477,
			136.539,
			136.553,
			136.599,
			136.663,
			136.723,
			136.787,
			136.848,
			136.879,
			136.911,
			136.988,
			137.035,
			137.101,
			137.158,
			137.222,
			137.282,
			137.319,
			137.344,
			137.408,
			137.423,
			137.469,
			137.532,
			137.592,
			137.639,
			137.655,
			137.718,
			137.749,
			137.779,
			137.839,
			137.856,
			137.902,
			137.966,
			138.026,
			138.071,
			138.149,
			138.182,
			138.212,
			138.276,
			138.336,
			138.4,
			138.459,
			138.509,
			138.521,
			138.585,
			138.616,
			138.646,
			138.709,
			138.725,
			138.771,
			138.832,
			138.894,
			138.942,
			138.957,
			139.018,
			139.049,
			139.08,
			139.143,
			139.158,
			139.204,
			139.266,
			139.33,
			139.391,
			139.453,
			139.484,
			139.516,
			139.577,
			139.638,
			139.7,
			139.764,
			139.81,
			139.826,
			139.886,
			139.919,
			139.95,
			140.01,
			140.026,
			140.071,
			140.137,
			140.198,
			140.259,
			140.321,
			140.352,
			140.381,
			140.445,
			140.508,
			140.568,
			140.631,
			140.693,
			140.755,
			140.785,
			140.817,
			140.88,
			140.896,
			140.942,
			141.004,
			141.065,
			141.113,
			141.126,
			141.187,
			141.22,
			141.25,
			141.314,
			141.33,
			141.376,
			141.436,
			141.499
		]
	],
	'3869_4543_7982': [
		[
			141.399
		],
		[
			0
		],
		[
			0,
			2.458,
			5.597,
			8.74,
			10.314,
			13.467,
			15.046,
			18.208,
			21.375,
			22.961,
			26.138,
			29.319,
			34.094,
			37.284,
			42.078,
			43.679,
			45.281,
			50.095,
			53.31,
			58.142,
			61.371,
			64.605,
			69.468,
			71.092,
			72.717,
			77.599,
			79.229,
			80.861,
			85.762,
			89.035,
			92.313,
			93.953,
			97.239,
			100.528,
			102.175,
			105.472,
			107.123,
			110.429,
			113.739,
			118.714,
			122.037,
			127.032,
			128.699,
			130.368,
			135.382,
			138.73,
			143.762,
			147.124,
			152.204,
			155.578,
			157.267,
			160.648,
			164.002,
			165.694,
			167.386,
			172.495,
			175.885,
			179.269,
			180.962,
			184.35,
			187.74,
			189.439,
			192.839,
			194.547,
			197.977,
			201.428,
			206.651,
			210.188,
			215.498,
			217.27,
			219.009,
			224.332,
			227.804,
			233.03,
			236.471,
			241.594,
			245.027,
			246.777,
			250.22,
			255.387,
			258.827,
			262.257,
			267.374,
			269.066,
			270.751,
			274.09,
			277.396,
			279.031,
			283.913,
			287.132,
			290.313,
			295.104,
			298.241,
			302.89,
			304.429,
			305.964,
			310.502,
			313.515,
			318.038,
			321.03,
			324.012,
			325.502,
			328.472,
			331.431,
			332.908,
			335.859,
			337.331,
			340.264,
			343.184,
			347.542,
			350.411,
			354.739,
			356.17,
			357.595,
			361.869,
			364.758,
			369.039,
			371.883,
			376.139,
			378.945,
			380.349,
			381.754,
			385.965,
			387.365,
			388.79,
			392.967,
			395.794,
			399.997,
			402.781,
			405.594,
			407.003,
			409.823,
			411.236,
			414.067,
			416.905,
			421.186,
			424.053,
			428.445,
			429.907,
			431.401,
			436.001,
			439.079,
			443.665,
			446.754,
			449.765,
			451.254,
			454.233,
			455.709,
			458.641,
			461.584,
			463.055,
			464.528,
			468.963,
			471.907,
			474.824,
			476.297,
			479.242,
			482.192,
			483.667,
			488.1,
			491.06,
			494.025,
			498.48,
			501.455,
			505.928,
			507.422,
			508.917,
			513.418,
			516.43,
			520.96,
			523.987,
			527.02,
			528.539,
			531.581,
			533.104,
			536.156,
			539.211,
			540.74,
			543.8,
			546.864,
			551.473,
			553.012,
			554.554,
			559.192,
			560.739,
			562.288,
			565.388,
			566.939,
			570.044,
			573.149,
			577.813,
			580.925,
			585.629,
			587.199,
			588.772,
			593.486,
			596.625,
			601.341,
			604.488,
			607.639,
			609.216,
			612.372,
			613.951,
			617.111,
			620.273,
			621.855,
			625.021,
			628.192,
			632.958,
			636.142,
			640.916,
			642.51,
			644.104,
			648.891,
			652.086,
			656.886,
			660.09,
			663.298,
			668.118,
			669.726,
			671.335,
			676.167,
			677.779,
			679.392,
			684.234,
			687.468,
			690.705,
			692.325,
			695.569,
			698.817,
			700.442,
			703.696,
			705.324,
			708.583,
			711.846,
			716.751,
			720.028,
			724.956,
			726.602,
			728.249,
			733.198,
			736.502,
			741.464,
			744.775,
			749.749,
			753.07,
			754.733,
			758.061,
			763.063,
			764.734,
			769.758,
			773.114,
			776.47,
			778.147,
			781.501,
			784.851,
			786.526,
			789.87,
			791.542,
			794.884,
			798.227,
			803.241,
			806.612,
			811.601,
			813.274,
			814.947,
			819.971,
			823.325,
			828.358,
			831.717,
			835.078,
			836.76,
			840.124,
			841.808,
			845.177,
			848.549,
			850.237,
			853.616,
			857.001,
			862.088,
			863.785,
			865.484,
			870.584,
			872.286,
			873.987,
			877.391,
			879.093,
			882.499,
			885.905,
			891.016,
			894.426,
			899.544,
			901.252,
			902.959,
			908.088,
			911.512,
			916.653,
			920.084,
			925.237,
			928.678,
			932.124,
			933.848,
			939.024,
			942.478,
			945.934,
			951.123,
			954.585,
			959.785,
			961.52,
			963.256,
			968.47,
			971.95,
			975.434,
			980.665,
			984.155,
			989.394,
			991.141,
			992.888,
			998.131,
			999.878,
			1001.626,
			1006.87,
			1010.369,
			1015.622,
			1019.127,
			1022.635,
			1024.39,
			1027.902,
			1029.66,
			1033.177,
			1036.697,
			1041.983,
			1045.511,
			1050.808,
			1052.575,
			1054.343,
			1059.648,
			1063.188,
			1068.5,
			1072.045,
			1075.593,
			1077.368,
			1080.919,
			1082.696,
			1086.25,
			1091.585,
			1095.143,
			1098.703,
			1102.266,
			1105.831,
			1107.614,
			1111.183,
			1114.754,
			1116.54,
			1120.114,
			1121.901,
			1125.478,
			1129.058,
			1134.432,
			1138.018,
			1143.402,
			1145.197,
			1146.993,
			1152.384,
			1155.98,
			1161.379,
			1164.98,
			1168.584,
			1170.387,
			1173.994,
			1175.798,
			1179.409,
			1183.021,
			1184.828,
			1188.444,
			1192.061,
			1197.491,
			1199.302,
			1201.113,
			1204.738,
			1208.363,
			1210.177,
			1215.62,
			1219.251,
			1222.884,
			1228.337,
			1231.975,
			1237.436,
			1239.257,
			1241.079,
			1246.549,
			1250.199,
			1255.678,
			1259.334,
			1262.992,
			1264.823,
			1268.484,
			1272.146,
			1273.978,
			1277.642,
			1279.473,
			1283.138,
			1286.805,
			1292.305,
			1294.139,
			1295.974,
			1301.482,
			1303.318,
			1305.155,
			1310.67,
			1314.349,
			1319.871,
			1323.554,
			1327.238,
			1332.769,
			1334.614,
			1336.459,
			1341.997,
			1345.691,
			1351.237,
			1354.938,
			1358.642,
			1360.494,
			1364.2,
			1367.908,
			1369.762,
			1373.471,
			1375.325,
			1379.033,
			1382.742,
			1388.308,
			1392.019,
			1397.578,
			1399.424,
			1401.264,
			1406.739,
			1410.343,
			1415.675,
			1419.182,
			1422.65,
			1424.37,
			1427.778,
			1429.467,
			1432.813,
			1436.119,
			1437.757,
			1439.385,
			1444.209,
			1447.377,
			1452.053,
			1455.122,
			1458.154,
			1459.656,
			1462.632,
			1464.106,
			1467.024,
			1469.902,
			1474.146,
			1476.926,
			1481.021,
			1482.366,
			1483.702,
			1487.651,
			1490.235,
			1494.04,
			1496.529,
			1498.981,
			1500.193,
			1502.591,
			1503.777,
			1506.122,
			1508.431,
			1509.57,
			1511.819,
			1514.03,
			1517.272,
			1518.334,
			1519.386,
			1522.486,
			1523.501,
			1524.505,
			1527.464,
			1529.387,
			1531.289,
			1534.051,
			1535.849,
			1538.484,
			1539.343,
			1540.194,
			1542.706,
			1544.339,
			1546.741,
			1548.319,
			1549.879,
			1550.654,
			1552.198,
			1552.969,
			1554.53,
			1556.092,
			1556.874,
			1558.453,
			1560.059,
			1562.481,
			1563.295,
			1564.117,
			1566.589,
			1567.416,
			1568.245,
			1569.921,
			1570.76,
			1572.438,
			1574.137,
			1574.991,
			1576.699,
			1578.416,
			1581.036,
			1581.907,
			1582.779,
			1585.426,
			1586.308,
			1587.189,
			1589.841,
			1591.608,
			1593.374,
			1594.258,
			1596.024,
			1597.787,
			1598.669,
			1600.431,
			1601.312,
			1603.075,
			1604.831,
			1607.466,
			1609.214,
			1611.82,
			1612.699,
			1613.575,
			1616.189,
			1617.948,
			1620.534,
			1622.277,
			1623.992,
			1624.835,
			1626.521,
			1627.364,
			1628.206,
			1630.744,
			1631.586,
			1633.265,
			1635,
			1636.74,
			1638.478,
			1639.37,
			1641.154,
			1642.936,
			1643.833,
			1645.652,
			1646.56,
			1648.377,
			1650.233,
			1653.022,
			1654.902,
			1657.736,
			1658.681,
			1659.625,
			1662.457,
			1664.34,
			1667.145,
			1669.019,
			1670.888,
			1671.824,
			1673.703,
			1674.638,
			1676.516,
			1678.401,
			1679.335,
			1681.199,
			1683.071,
			1685.844,
			1686.766,
			1687.689,
			1689.525,
			1691.345,
			1692.255,
			1694.977,
			1696.783,
			1698.587,
			1701.302,
			1703.11,
			1705.83,
			1706.737,
			1707.644,
			1710.368,
			1712.185,
			1714.911,
			1716.73,
			1719.46,
			1721.284,
			1723.109,
			1724.023,
			1725.851,
			1726.767,
			1728.604,
			1730.431,
			1733.212,
			1734.145,
			1735.079,
			1737.901,
			1738.847,
			1739.796,
			1742.656,
			1744.569,
			1747.441,
			1749.349,
			1751.269,
			1752.218,
			1754.113,
			1755.062,
			1756.01,
			1758.844,
			1759.787,
			1760.729,
			1763.546,
			1765.416,
			1767.28,
			1768.209,
			1770.059,
			1771.899,
			1772.816,
			1774.648,
			1775.563,
			1777.392,
			1779.219,
			1781.978,
			1783.818,
			1786.606,
			1787.538,
			1788.47,
			1791.297,
			1793.194,
			1796.064,
			1797.99,
			1799.924,
			1802.831,
			1803.804,
			1805.751,
			1808.682,
			1809.665,
			1812.641,
			1814.641,
			1817.688,
			1819.733,
			1821.794,
			1822.829,
			1824.904,
			1825.936,
			1828.008,
			1830.083,
			1833.174,
			1835.236,
			1838.309,
			1839.337,
			1840.357,
			1843.411,
			1845.449,
			1848.498,
			1850.538,
			1853.607,
			1855.666,
			1856.7,
			1858.765,
			1860.836,
			1861.876,
			1863.961,
			1866.059,
			1869.229,
			1870.29,
			1871.354,
			1873.489,
			1875.633,
			1876.708,
			1878.866,
			1879.948,
			1882.122,
			1885.383,
			1887.584,
			1889.813,
			1893.137,
			1894.256,
			1895.378,
			1898.762,
			1901.031,
			1904.459,
			1906.76,
			1910.233,
			1912.567,
			1913.737,
			1916.086,
			1919.631,
			1922.008,
			1924.395,
			1927.997,
			1930.411,
			1934.074,
			1935.273,
			1936.513,
			1940.165,
			1942.624,
			1946.33,
			1948.812,
			1951.325,
			1955.058,
			1956.313,
			1957.57,
			1961.354,
			1963.887,
			1967.702,
			1970.256,
			1972.818,
			1974.102,
			1976.678,
			1979.264,
			1980.56,
			1983.159,
			1984.461,
			1987.064,
			1989.673,
			1993.601,
			1996.23,
			2000.189,
			2001.513,
			2002.838,
			2006.826,
			2009.496,
			2013.514,
			2016.203,
			2018.901,
			2020.254,
			2022.964,
			2024.323,
			2025.684,
			2029.778,
			2031.147,
			2032.517,
			2036.641,
			2039.399,
			2042.166,
			2043.551,
			2046.329,
			2049.112,
			2050.504,
			2054.668,
			2057.417,
			2060.135,
			2064.145,
			2066.772,
			2070.644,
			2071.917,
			2073.181,
			2076.917,
			2079.365,
			2082.971,
			2085.334,
			2088.816,
			2091.091,
			2094.436,
			2096.62,
			2097.699,
			2099.83,
			2101.924,
			2105.001,
			2106.008,
			2107.007,
			2108.979,
			2110.916,
			2111.871,
			2114.684,
			2116.517,
			2118.315,
			2120.948,
			2122.657,
			2125.146,
			2125.959,
			2126.775,
			2129.123,
			2130.647,
			2132.881,
			2134.338,
			2135.778,
			2136.492,
			2137.907,
			2138.61,
			2140.008,
			2141.402,
			2142.099,
			2143.486,
			2144.869,
			2146.944,
			2148.332,
			2150.413,
			2151.108,
			2151.803,
			2153.924,
			2155.343,
			2156.766,
			2158.953,
			2160.421,
			2162.64,
			2163.391,
			2164.142,
			2166.4,
			2167.154,
			2167.914,
			2170.197,
			2171.72,
			2174.026,
			2175.567,
			2176.337,
			2177.889,
			2179.455,
			2180.237,
			2181.801,
			2183.377,
			2185.74,
			2187.312,
			2189.676,
			2190.461,
			2191.247,
			2193.607,
			2195.179,
			2197.54,
			2199.131,
			2200.719,
			2201.513,
			2203.112,
			2203.918,
			2204.723,
			2207.948,
			2208.764,
			2211.21,
			2212.843,
			2214.476,
			2215.291,
			2216.92,
			2218.549,
			2219.361,
			2220.984,
			2221.794,
			2223.417,
			2225.844,
			2227.459,
			2229.076,
			2231.494,
			2232.299,
			2233.105,
			2235.52,
			2237.126,
			2239.55,
			2241.166,
			2242.781,
			2243.596,
			2245.221,
			2246.032,
			2247.654,
			2249.278,
			2250.089,
			2250.9,
			2253.331,
			2254.95,
			2256.569,
			2257.379,
			2258.992,
			2260.602,
			2261.407,
			2263.021,
			2263.827,
			2265.442,
			2267.061,
			2269.508,
			2271.148,
			2273.62,
			2274.444,
			2275.271,
			2277.76,
			2279.415,
			2281.906,
			2283.575,
			2285.237,
			2286.072,
			2287.751,
			2288.59,
			2290.266,
			2291.953,
			2292.802,
			2294.494,
			2296.193,
			2298.763,
			2299.619,
			2300.478,
			2302.205,
			2303.945,
			2304.819,
			2306.576,
			2307.458,
			2309.241,
			2311.953,
			2313.781,
			2315.645,
			2318.464,
			2319.419,
			2320.379,
			2324.256,
			2325.239,
			2328.207,
			2330.205,
			2332.218,
			2333.229,
			2335.257,
			2337.296,
			2338.321,
			2340.374,
			2341.405,
			2343.476,
			2345.553,
			2348.673,
			2350.762,
			2353.902,
			2354.951,
			2356.003,
			2359.176,
			2361.3,
			2364.496,
			2366.624,
			2368.74,
			2371.876,
			2372.909,
			2373.933,
			2376.958,
			2377.95,
			2378.932,
			2381.834,
			2383.726,
			2385.586,
			2386.504,
			2388.316,
			2390.118,
			2391.004,
			2392.762,
			2393.634,
			2395.373,
			2397.103,
			2399.688,
			2401.413,
			2403.995,
			2404.855,
			2405.716,
			2408.294,
			2410.013,
			2412.583,
			2414.298,
			2416.005,
			2416.857,
			2418.562,
			2419.416,
			2421.124,
			2423.689,
			2425.416,
			2427.157,
			2429.785,
			2430.692,
			2431.601,
			2433.421,
			2435.274,
			2436.224,
			2438.129,
			2439.085,
			2441.036,
			2443.009,
			2446.006,
			2448.033,
			2451.1,
			2452.129,
			2453.161,
			2456.265,
			2458.338,
			2461.436,
			2463.492,
			2465.543,
			2466.555,
			2468.582,
			2469.598,
			2471.598,
			2473.596,
			2474.597,
			2476.578,
			2478.556,
			2481.519,
			2483.489,
			2486.441,
			2487.42,
			2488.401,
			2491.333,
			2493.272,
			2495.212,
			2498.112,
			2500.04,
			2502.963,
			2503.938,
			2504.914,
			2507.884,
			2509.876,
			2512.894,
			2514.92,
			2516.953,
			2517.973,
			2520.022,
			2522.082,
			2523.116,
			2526.238,
			2528.331,
			2530.426,
			2533.602,
			2535.717,
			2538.944,
			2540.025,
			2541.108,
			2544.376,
			2546.567,
			2549.874,
			2552.091,
			2555.439,
			2557.685,
			2558.812,
			2559.942,
			2563.349,
			2564.49,
			2565.635,
			2569.085,
			2571.4,
			2573.726,
			2574.893,
			2577.235,
			2579.588,
			2580.768,
			2583.137,
			2584.325,
			2586.711,
			2589.108,
			2592.725,
			2595.151,
			2598.812,
			2600.038,
			2601.266,
			2604.969,
			2607.452,
			2611.195,
			2613.703,
			2616.221,
			2617.483,
			2620.016,
			2621.286,
			2623.832,
			2626.388,
			2627.67,
			2630.24,
			2632.82,
			2635.409,
			2638.007,
			2639.31,
			2641.922,
			2644.535,
			2645.844,
			2648.467,
			2649.782,
			2652.418,
			2655.063,
			2659.046,
			2661.711,
			2665.726,
			2667.068,
			2668.412,
			2672.457,
			2675.164,
			2679.24,
			2681.968,
			2684.705,
			2686.076,
			2688.825,
			2690.203,
			2692.966,
			2695.736,
			2697.125,
			2699.908,
			2702.699,
			2706.902,
			2708.306,
			2709.713,
			2713.945,
			2715.36,
			2716.777,
			2719.617,
			2721.04,
			2723.891,
			2726.75,
			2731.054,
			2733.934,
			2738.267,
			2739.714,
			2741.164,
			2745.525,
			2748.442,
			2752.831,
			2755.766,
			2758.71,
			2760.185,
			2763.139,
			2764.619,
			2767.584,
			2770.558,
			2772.047,
			2775.032,
			2778.024,
			2782.526,
			2785.537,
			2790.066,
			2791.579,
			2793.095,
			2797.651,
			2800.697,
			2805.279,
			2808.341,
			2811.409,
			2812.946,
			2816.023,
			2817.565,
			2819.108,
			2823.75,
			2825.301,
			2826.854,
			2831.525,
			2834.649,
			2839.347,
			2842.489,
			2845.636,
			2847.212,
			2850.368,
			2851.948,
			2855.104,
			2858.251,
			2862.931,
			2866.014,
			2870.572,
			2872.073,
			2873.564,
			2877.981,
			2880.88,
			2885.159,
			2887.966,
			2890.737,
			2892.109,
			2894.826,
			2896.17,
			2898.833,
			2902.76,
			2905.335,
			2907.874,
			2911.635,
			2912.863,
			2914.083,
			2916.499,
			2918.86,
			2920.038,
			2923.522,
			2925.818,
			2928.06,
			2931.363,
			2933.539,
			2936.771,
			2937.847,
			2938.923,
			2942.146,
			2944.315,
			2947.577,
			2949.771,
			2953.067,
			2955.271,
			2956.373,
			2958.577,
			2960.782,
			2961.884,
			2964.082,
			2966.273,
			2969.552,
			2970.644,
			2971.734,
			2973.92,
			2976.112,
			2977.21,
			2979.421,
			2980.532,
			2982.758,
			2985,
			2988.381,
			2990.649,
			2994.064,
			2995.206,
			2996.349,
			2999.785,
			3002.082,
			3005.53,
			3007.828,
			3011.27,
			3013.564,
			3014.706,
			3016.99,
			3019.271,
			3020.407,
			3022.678,
			3024.942,
			3028.325,
			3030.573,
			3033.94,
			3035.06,
			3036.179,
			3039.544,
			3041.784,
			3044.033,
			3047.409,
			3049.67,
			3050.8,
			3053.067,
			3054.203,
			3055.339,
			3059.892,
			3061.037,
			3064.476,
			3066.79,
			3069.114,
			3070.281,
			3072.628,
			3074.984,
			3076.163,
			3078.525,
			3079.708,
			3082.074,
			3084.443,
			3088.01,
			3090.393,
			3093.984,
			3095.182,
			3096.382,
			3099.983,
			3102.387,
			3105.998,
			3108.41,
			3110.829,
			3112.038,
			3114.464,
			3115.68,
			3116.897,
			3120.562,
			3121.785,
			3124.236,
			3126.695,
			3130.391,
			3131.628,
			3132.869,
			3135.357,
			3137.858,
			3139.113,
			3141.632,
			3142.899,
			3145.44,
			3147.996,
			3151.848,
			3154.427,
			3158.312,
			3159.613,
			3160.915,
			3164.843,
			3167.473,
			3171.435,
			3174.089,
			3176.75,
			3178.086,
			3180.763,
			3182.105,
			3184.799,
			3187.501,
			3188.856,
			3191.57,
			3194.295,
			3198.398,
			3199.772,
			3201.148,
			3205.296,
			3206.683,
			3208.074,
			3212.266,
			3215.076,
			3217.895,
			3222.143,
			3224.986,
			3229.267,
			3230.697,
			3232.13,
			3236.437,
			3239.316,
			3243.645,
			3246.535,
			3250.883,
			3253.789,
			3255.242,
			3258.153,
			3262.527,
			3265.447,
			3268.372,
			3272.761,
			3275.691,
			3280.092,
			3281.56,
			3283.03,
			3287.442,
			3290.387,
			3294.811,
			3297.763,
			3300.719,
			3305.159,
			3306.616,
			3308.099,
			3312.555,
			3314.044,
			3315.534,
			3320.041,
			3323.049,
			3327.566,
			3330.612,
			3333.671,
			3335.203,
			3338.274,
			3339.812,
			3342.891,
			3346.001,
			3350.637,
			3353.731,
			3358.354,
			3359.906,
			3361.458,
			3366.12,
			3369.234,
			3373.916,
			3377.042,
			3381.743,
			3384.881,
			3386.452,
			3389.599,
			3392.751,
			3394.329,
			3397.491,
			3400.66,
			3402.247,
			3405.428,
			3407.021,
			3410.212,
			3415.004,
			3416.604,
			3421.409,
			3424.617,
			3427.829,
			3429.437,
			3432.655,
			3435.877,
			3437.49,
			3440.717,
			3442.333,
			3445.566,
			3450.425,
			3453.669,
			3456.916,
			3461.795,
			3463.423,
			3465.051,
			3469.943,
			3473.209,
			3478.114,
			3481.388,
			3484.666,
			3486.306,
			3489.588,
			3491.231,
			3494.52,
			3499.453,
			3502.729,
			3505.982,
			3510.798,
			3512.384,
			3513.961,
			3518.634,
			3520.172,
			3521.702,
			3526.234,
			3529.211,
			3533.605,
			3536.487,
			3539.331,
			3543.526,
			3544.905,
			3546.274,
			3550.326,
			3551.658,
			3552.979,
			3556.888,
			3559.447,
			3563.214,
			3565.678,
			3566.895,
			3569.301,
			3571.672,
			3572.845,
			3575.166,
			3577.452,
			3580.832,
			3583.028,
			3586.239,
			3587.295,
			3588.343,
			3591.449,
			3593.462,
			3596.416,
			3598.341,
			3600.231,
			3601.164,
			3603.003,
			3603.911,
			3605.708,
			3607.481,
			3608.361,
			3609.237,
			3611.853,
			3613.594,
			3615.337,
			3616.21,
			3617.969,
			3619.738,
			3620.624,
			3622.402,
			3623.298,
			3625.093,
			3626.891,
			3629.614,
			3631.432,
			3634.174,
			3635.089,
			3636.004,
			3638.752,
			3640.582,
			3643.321,
			3645.144,
			3646.958,
			3647.866,
			3649.682,
			3650.587,
			3652.392,
			3654.197,
			3655.095,
			3656.886,
			3658.674,
			3660.458,
			3662.235,
			3663.121,
			3664.894,
			3666.658,
			3667.539,
			3669.304,
			3670.186,
			3671.945,
			3673.704,
			3676.342,
			3678.098,
			3680.734,
			3681.612,
			3682.49,
			3685.121,
			3686.874,
			3689.509,
			3691.273,
			3693.04,
			3693.925,
			3695.701,
			3697.478,
			3698.367,
			3700.152,
			3701.044,
			3702.832,
			3704.621,
			3707.307,
			3708.204,
			3709.102,
			3710.899,
			3712.699,
			3713.602,
			3716.317,
			3718.134,
			3719.959,
			3722.712,
			3724.556,
			3727.328,
			3728.257,
			3729.188,
			3731.988,
			3732.926,
			3733.852,
			3736.676,
			3738.57,
			3740.469,
			3741.418,
			3743.326,
			3745.243,
			3746.202,
			3748.126,
			3749.089,
			3751.013,
			3752.928,
			3755.767,
			3757.63,
			3760.387,
			3761.302,
			3762.215,
			3764.956,
			3766.778,
			3769.5,
			3771.322,
			3773.15,
			3775.892,
			3776.811,
			3778.646,
			3780.479,
			3781.404,
			3782.33,
			3785.104,
			3786.964,
			3788.828,
			3789.76,
			3791.63,
			3793.502,
			3794.44,
			3796.321,
			3797.267,
			3799.159,
			3801.055,
			3803.909,
			3805.812,
			3808.667,
			3809.618,
			3810.57,
			3813.442,
			3815.362,
			3818.25,
			3820.18,
			3822.114,
			3823.083,
			3825.023,
			3825.995,
			3827.933,
			3829.874,
			3830.847,
			3832.791,
			3834.738,
			3837.671,
			3838.651,
			3839.633,
			3841.607,
			3843.58,
			3844.571,
			3847.556,
			3849.552,
			3852.563,
			3854.573,
			3856.595,
			3859.628,
			3860.643,
			3861.66,
			3864.703,
			3866.737,
			3869.774,
			3871.801,
			3874.837,
			3876.863,
			3877.877,
			3879.912,
			3881.944,
			3882.96,
			3885.021,
			3887.085,
			3890.223,
			3891.277,
			3892.332,
			3895.504,
			3896.573,
			3897.661,
			3900.888,
			3903.052,
			3905.222,
			3908.476,
			3910.667,
			3913.973,
			3915.082,
			3916.193,
			3919.543,
			3921.794,
			3925.19,
			3927.468,
			3929.755,
			3930.902,
			3933.204,
			3934.358,
			3936.676,
			3939.004,
			3940.172,
			3942.517,
			3944.871,
			3948.421,
			3949.609,
			3950.799,
			3954.386,
			3955.586,
			3956.789,
			3960.411,
			3962.838,
			3966.497,
			3968.948,
			3971.407,
			3975.113,
			3976.352,
			3977.593,
			3981.33,
			3982.579,
			3983.83,
			3987.594,
			3990.109,
			3992.62,
			3993.874,
			3996.37,
			3998.843,
			4000.07,
			4003.699,
			4006.072,
			4008.407,
			4011.839,
			4014.081,
			4017.373,
			4018.452,
			4019.521,
			4022.677,
			4024.734,
			4027.754,
			4029.723,
			4031.655,
			4032.609,
			4034.491,
			4036.337,
			4037.248,
			4039.94,
			4040.84,
			4043.471,
			4045.206,
			4047.789,
			4049.502,
			4051.209,
			4052.062,
			4053.758,
			4054.604,
			4056.292,
			4057.975,
			4060.482,
			4062.152,
			4064.639,
			4065.466,
			4066.294,
			4068.781,
			4070.441,
			4072.937,
			4074.631,
			4076.324,
			4077.171,
			4078.883,
			4079.746,
			4081.471,
			4083.197,
			4084.072,
			4085.818,
			4087.562,
			4090.196,
			4091.956,
			4094.607,
			4095.499,
			4096.391,
			4099.065,
			4100.865,
			4102.662,
			4105.365,
			4107.172,
			4109.889,
			4110.799,
			4111.709,
			4114.443,
			4116.274,
			4119.031,
			4120.874,
			4122.721,
			4123.647,
			4125.489,
			4127.328,
			4128.25,
			4130.089,
			4130.999,
			4132.826,
			4134.662,
			4137.364,
			4138.27,
			4139.178,
			4141.868,
			4142.765,
			4143.665,
			4146.366,
			4148.17,
			4150.894,
			4152.722,
			4154.556,
			4157.333,
			4158.263,
			4159.193,
			4162.028,
			4162.981,
			4163.938,
			4166.844,
			4168.811,
			4170.79,
			4171.792,
			4173.789,
			4175.812,
			4176.828,
			4178.867,
			4179.891,
			4181.945,
			4184.008,
			4187.122,
			4189.211,
			4192.356,
			4193.406,
			4194.458,
			4197.629,
			4199.759,
			4202.973,
			4205.129,
			4207.295,
			4208.384,
			4210.57,
			4211.667,
			4212.767,
			4217.195,
			4218.309,
			4221.665,
			4223.914,
			4226.19,
			4227.323,
			4229.592,
			4231.868,
			4233.011,
			4235.273,
			4236.417,
			4238.711,
			4241.025,
			4244.48,
			4246.787,
			4250.252,
			4251.408,
			4252.565,
			4256.037,
			4258.355,
			4261.833,
			4264.154,
			4266.488,
			4267.655,
			4269.993,
			4271.17,
			4273.522,
			4277.063,
			4279.427,
			4281.799,
			4285.36,
			4286.551,
			4287.742,
			4290.124,
			4292.518,
			4293.714,
			4296.114,
			4297.317,
			4299.729,
			4302.157,
			4305.822,
			4308.286,
			4311.995,
			4313.238,
			4314.481,
			4318.225,
			4320.727,
			4324.494,
			4327.013,
			4329.546,
			4330.814,
			4333.36,
			4334.637,
			4337.197,
			4341.068,
			4343.652,
			4346.239,
			4350.132,
			4352.733,
			4356.643,
			4357.947,
			4359.25,
			4363.156,
			4365.738,
			4368.322,
			4372.168,
			4375.993,
			4378.542,
			4379.81,
			4381.08,
			4384.896,
			4386.169,
			4387.443,
			4391.277,
			4393.839,
			4396.404,
			4397.688,
			4400.26,
			4402.841,
			4404.133,
			4406.705,
			4408.005,
			4410.631,
			4413.244,
			4417.18,
			4419.812,
			4423.753,
			4425.078,
			4426.403,
			4430.39,
			4433.056,
			4437.068,
			4439.771,
			4442.46,
			4443.807,
			4446.505,
			4447.855,
			4450.559,
			4453.266,
			4454.62,
			4455.976,
			4460.046,
			4462.741,
			4465.484,
			4466.847,
			4469.577,
			4472.312,
			4473.679,
			4476.418,
			4477.789,
			4480.508,
			4483.258,
			4487.396,
			4490.181,
			4494.335,
			4495.724,
			4497.091,
			4501.274,
			4504.071,
			4508.28,
			4511.102,
			4513.93,
			4515.346,
			4518.182,
			4519.605,
			4522.458,
			4525.319,
			4526.751,
			4529.621,
			4532.5,
			4535.384,
			4538.275,
			4539.722,
			4544.074,
			4545.527,
			4546.981,
			4551.352,
			4554.272,
			4557.197,
			4561.596,
			4564.533,
			4568.95,
			4570.426,
			4571.903,
			4577.825,
			4579.31,
			4583.771,
			4586.754,
			4589.742,
			4591.238,
			4594.235,
			4597.238,
			4598.742,
			4601.753,
			4603.261,
			4606.28,
			4609.305,
			4613.856,
			4616.896,
			4621.466,
			4622.992,
			4624.519,
			4629.11,
			4632.18,
			4635.256,
			4639.885,
			4642.979,
			4647.631,
			4649.184,
			4650.737,
			4655.409,
			4656.968,
			4658.528,
			4663.213,
			4666.341,
			4669.473,
			4671.04,
			4674.174,
			4677.312,
			4678.88,
			4682.021,
			4683.591,
			4686.736,
			4689.884,
			4694.615,
			4697.774,
			4702.522,
			4704.107,
			4705.694,
			4710.46,
			4713.64,
			4718.418,
			4721.612,
			4724.811,
			4726.413,
			4729.621,
			4731.228,
			4734.444,
			4737.665,
			4739.277,
			4742.503,
			4745.731,
			4748.964,
			4752.199,
			4753.818,
			4757.058,
			4760.301,
			4761.923,
			4766.796,
			4770.049,
			4773.306,
			4778.197,
			4781.462,
			4786.366,
			4788.003,
			4789.641,
			4794.56,
			4797.843,
			4802.776,
			4806.069,
			4811.016,
			4814.317,
			4815.969,
			4819.276,
			4822.586,
			4824.242,
			4827.558,
			4830.877,
			4835.862,
			4837.525,
			4839.188,
			4844.186,
			4845.853,
			4847.52,
			4850.857,
			4852.526,
			4855.867,
			4859.211,
			4864.234,
			4867.588,
			4872.627,
			4874.309,
			4875.99,
			4881.041,
			4884.413,
			4889.479,
			4892.859,
			4896.241,
			4897.933,
			4901.32,
			4903.015,
			4906.409,
			4909.807,
			4911.508,
			4914.913,
			4918.319,
			4923.434,
			4925.14,
			4926.847,
			4931.973,
			4933.683,
			4935.394,
			4940.531,
			4943.96,
			4947.393,
			4952.546,
			4956.016,
			4961.184,
			4962.909,
			4964.607,
			4969.792,
			4973.255,
			4978.459,
			4981.934,
			4985.442,
			4987.184,
			4990.672,
			4994.14,
			4995.892,
			4999.399,
			5001.159,
			5004.713,
			5008.239,
			5013.539,
			5017.077,
			5022.356,
			5024.126,
			5025.898,
			5031.252,
			5034.809,
			5040.142,
			5043.698,
			5049.031,
			5052.588,
			5054.338,
			5057.9,
			5061.46,
			5063.27,
			5066.828,
			5070.387,
			5073.947,
			5079.286,
			5082.844,
			5086.404,
			5088.183,
			5091.712,
			5093.491,
			5097.049,
			5100.637,
			5105.973,
			5109.501,
			5114.838,
			5116.617,
			5118.397,
			5123.737,
			5127.299,
			5132.644,
			5136.207,
			5141.551,
			5145.113,
			5146.895,
			5150.458,
			5154.025,
			5155.81,
			5159.38,
			5162.956,
			5168.33,
			5170.125,
			5171.921,
			5175.519,
			5179.12,
			5180.921,
			5184.528,
			5186.332,
			5189.941,
			5193.554,
			5198.98,
			5202.603,
			5208.042,
			5209.855,
			5211.67,
			5217.119,
			5220.753,
			5226.205,
			5229.87,
			5233.505,
			5235.321,
			5238.955,
			5240.771,
			5244.375,
			5249.824,
			5253.455,
			5257.084,
			5262.547,
			5264.354,
			5266.16,
			5271.53,
			5273.325,
			5275.118,
			5280.467,
			5284.039,
			5287.57,
			5292.854,
			5296.371,
			5301.646,
			5303.408,
			5305.177,
			5310.498,
			5312.276,
			5314.058,
			5319.413,
			5322.935,
			5326.482,
			5328.255,
			5331.832,
			5335.38,
			5337.154,
			5340.699,
			5342.47,
			5346.009,
			5349.539,
			5354.819,
			5358.331,
			5363.589,
			5365.341,
			5367.091,
			5372.315,
			5375.776,
			5380.929,
			5384.334,
			5387.713,
			5389.368,
			5392.721,
			5394.395,
			5397.74,
			5401.089,
			5402.768,
			5406.161,
			5409.538,
			5412.92,
			5416.302,
			5417.989,
			5421.363,
			5424.728,
			5426.411,
			5429.752,
			5431.436,
			5434.809,
			5438.185,
			5443.262,
			5446.684,
			5451.758,
			5453.462,
			5455.167,
			5460.288,
			5463.708,
			5468.843,
			5472.271,
			5475.703,
			5477.42,
			5480.885,
			5482.604,
			5486.015,
			5489.457,
			5491.178,
			5494.621,
			5498.065,
			5503.24,
			5504.966,
			5506.695,
			5510.153,
			5513.615,
			5515.347,
			5518.812,
			5520.545,
			5524.014,
			5527.484,
			5532.699,
			5536.179,
			5541.399,
			5543.141,
			5544.882,
			5550.113,
			5553.604,
			5558.849,
			5562.351,
			5565.857,
			5567.613,
			5571.127,
			5572.888,
			5576.412,
			5579.945,
			5581.713,
			5585.256,
			5588.805,
			5594.141,
			5597.702,
			5603.05,
			5604.835,
			5606.62,
			5611.983,
			5615.564,
			5620.94,
			5624.529,
			5628.121,
			5633.513,
			5635.31,
			5637.108,
			5642.503,
			5644.3,
			5646.098,
			5651.489,
			5655.083,
			5658.679,
			5660.477,
			5664.073,
			5665.872,
			5669.472,
			5674.872,
			5678.472,
			5682.074,
			5687.48,
			5691.086,
			5696.496,
			5698.301,
			5700.105,
			5705.521,
			5709.124,
			5714.496,
			5718.041,
			5721.548,
			5723.286,
			5726.732,
			5728.439,
			5730.136,
			5736.822,
			5738.469,
			5743.346,
			5746.546,
			5749.707,
			5751.271,
			5754.373,
			5757.435,
			5758.952,
			5761.959,
			5763.448,
			5766.399,
			5769.311,
			5773.605,
			5776.419,
			5780.57,
			5781.934,
			5783.289,
			5787.296,
			5789.922,
			5793.787,
			5796.316,
			5800.039,
			5802.474,
			5803.676,
			5806.057,
			5809.561,
			5811.853,
			5814.105,
			5816.321,
			5818.499,
			5819.573,
			5821.692,
			5823.773,
			5824.799,
			5826.818,
			5827.813,
			5829.775,
			5831.695,
			5834.502,
			5836.327,
			5838.998,
			5839.871,
			5840.736,
			5843.276,
			5844.104,
			5844.923,
			5847.329,
			5848.887,
			5851.155,
			5852.621,
			5853.34,
			5854.75,
			5856.126,
			5856.799,
			5858.121,
			5859.408,
			5861.275,
			5862.484,
			5864.216,
			5864.779,
			5865.335,
			5866.969,
			5868.027,
			5869.064,
			5870.596,
			5871.604,
			5873.071,
			5873.555,
			5874.037,
			5875.96,
			5876.44,
			5877.883,
			5878.836,
			5879.786,
			5880.261,
			5881.213,
			5882.196,
			5882.703,
			5883.713,
			5884.217,
			5885.226,
			5886.238,
			5887.884,
			5889.029,
			5890.743,
			5891.316,
			5891.891,
			5893.848,
			5895.157,
			5897.118,
			5898.398,
			5899.67,
			5900.303,
			5901.567,
			5902.2,
			5903.385,
			5904.562,
			5905.148,
			5905.735,
			5907.497,
			5908.615,
			5909.735,
			5910.295,
			5911.416,
			5912.537,
			5913.078,
			5914.159,
			5914.696,
			5915.768,
			5916.833,
			5918.418,
			5919.466,
			5921.015,
			5921.528,
			5922.041,
			5923.576,
			5924.592,
			5926.104,
			5927.104,
			5928.621,
			5929.668,
			5930.188,
			5931.22,
			5932.246,
			5932.757,
			5933.843,
			5934.979,
			5936.662,
			5937.776,
			5938.977,
			5940.287,
			5940.938,
			5942.231,
			5942.875,
			5944.2,
			5945.542,
			5947.54,
			5948.866,
			5950.82,
			5951.468,
			5952.115,
			5954.052,
			5955.254,
			5957.046,
			5958.239,
			5959.934,
			5960.998,
			5961.53,
			5962.595,
			5964.201,
			5965.198,
			5966.168,
			5967.631,
			5968.122,
			5968.614,
			5970.068,
			5970.536,
			5971.003,
			5971.946,
			5972.42,
			5973.375,
			5974.34,
			5975.816,
			5976.815,
			5978.335,
			5978.848,
			5979.364,
			5980.975,
			5982.089,
			5983.788,
			5984.94,
			5986.739,
			5987.966,
			5989.207,
			5989.833,
			5991.104,
			5991.755,
			5993.067,
			5994.392,
			5996.401,
			5997.771,
			5999.838,
			6000.536,
			6001.237,
			6003.368,
			6004.82,
			6007.01,
			6008.478,
			6009.973,
			6012.24,
			6013.001,
			6014.531,
			6016.069,
			6016.843,
			6017.62,
			6019.971,
			6021.557,
			6023.96,
			6025.593,
			6027.212,
			6028.034,
			6029.687,
			6030.519,
			6032.19,
			6033.868,
			6036.406,
			6038.101,
			6040.641,
			6041.494,
			6042.351,
			6044.935,
			6045.802,
			6046.687,
			6049.315,
			6051.062,
			6052.844,
			6053.732,
			6055.519,
			6056.415,
			6058.2,
			6060.012,
			6060.921,
			6062.75,
			6064.589,
			6066.455,
			6068.301,
			6069.237,
			6071.117,
			6073.009,
			6073.959,
			6075.869,
			6076.828,
			6078.755,
			6080.694,
			6083.626,
			6085.597,
			6088.578,
			6089.578,
			6090.581,
			6093.611,
			6095.648,
			6098.724,
			6100.788,
			6103.904,
			6105.993,
			6107.042,
			6109.142,
			6112.29,
			6114.396,
			6116.513,
			6119.705,
			6121.846,
			6125.074,
			6126.155,
			6127.238,
			6130.502,
			6132.689,
			6134.887,
			6138.199,
			6140.418,
			6143.766,
			6144.887,
			6146.009,
			6150.524,
			6151.658,
			6155.073,
			6157.362,
			6160.812,
			6163.123,
			6165.443,
			6166.607,
			6168.941,
			6170.111,
			6172.458,
			6174.814,
			6178.366,
			6180.744,
			6184.328,
			6185.526,
			6186.728,
			6190.343,
			6192.763,
			6196.409,
			6198.852,
			6201.302,
			6202.531,
			6204.995,
			6206.229,
			6208.707,
			6212.439,
			6213.688,
			6217.446,
			6219.963,
			6223.752,
			6226.289,
			6228.833,
			6230.107,
			6232.663,
			6233.944,
			6236.512,
			6239.087,
			6242.964,
			6245.558,
			6249.463,
			6250.765,
			6252.066,
			6255.98,
			6258.598,
			6262.538,
			6265.173,
			6267.814,
			6269.138,
			6271.79,
			6273.118,
			6275.781,
			6279.786,
			6282.465,
			6285.149,
			6289.189,
			6290.539,
			6291.89,
			6294.598,
			6297.311,
			6298.67,
			6301.394,
			6302.757,
			6305.49,
			6308.23,
			6312.353,
			6315.109,
			6319.256,
			6320.641,
			6322.028,
			6326.198,
			6328.987,
			6333.18,
			6335.984,
			6340.202,
			6343.022,
			6344.435,
			6347.263,
			6350.099,
			6351.519,
			6354.363,
			6357.215,
			6361.502,
			6364.371,
			6368.685,
			6370.126,
			6371.569,
			6374.458,
			6375.905,
			6378.803,
			6383.16,
			6386.072,
			6388.984,
			6393.341,
			6394.787,
			6396.227,
			6400.503,
			6403.309,
			6407.444,
			6410.153,
			6412.823,
			6414.144,
			6416.756,
			6419.331,
			6420.604,
			6423.12,
			6424.364,
			6426.822,
			6429.244,
			6432.807,
			6435.137,
			6438.565,
			6439.688,
			6440.802,
			6444.084,
			6446.225,
			6449.364,
			6451.409,
			6453.416,
			6456.356,
			6457.318,
			6458.27,
			6461.068,
			6461.983,
			6462.889,
			6465.557,
			6467.291,
			6468.987,
			6469.821,
			6471.46,
			6473.062,
			6473.849,
			6475.393,
			6476.151,
			6477.639,
			6479.09,
			6481.196,
			6482.556,
			6484.532,
			6485.173,
			6485.806,
			6487.648,
			6488.83,
			6490.537,
			6491.625,
			6493.188,
			6494.188,
			6494.677,
			6495.641,
			6496.582,
			6497.044,
			6497.966,
			6498.868,
			6499.764,
			6501.099,
			6501.963,
			6502.815,
			6503.238,
			6504.081,
			6504.501,
			6505.341,
			6506.604,
			6507.441,
			6508.266,
			6509.488,
			6509.894,
			6510.3,
			6511.52,
			6512.355,
			6513.602,
			6514.423,
			6515.649,
			6516.469,
			6516.881,
			6517.784,
			6518.686,
			6519.135,
			6520.033,
			6520.932,
			6522.332,
			6522.89,
			6523.45,
			6525.137,
			6525.705,
			6526.275,
			6528.207,
			6529.795,
			6531.399,
			6533.983,
			6535.821,
			6538.618,
			6539.568,
			6540.521,
			6543.382,
			6544.822,
			6547.001,
			6548.478,
			6549.702,
			6550.305,
			6551.516,
			6552.738,
			6553.354,
			6554.521,
			6555.096,
			6556.251,
			6557.419,
			6559.198,
			6560.403,
			6562.212,
			6562.816,
			6563.423,
			6565.286,
			6566.556,
			6568.491,
			6569.806,
			6571.832,
			6573.202,
			6573.894,
			6574.589,
			6576.723,
			6577.443,
			6578.167,
			6580.365,
			6581.859,
			6583.369,
			6584.13,
			6585.666,
			6587.222,
			6588.006,
			6589.586,
			6590.382,
			6591.988,
			6593.597,
			6596.059,
			6597.719,
			6600.233,
			6601.071,
			6601.91,
			6604.443,
			6606.15,
			6608.739,
			6610.484,
			6612.243,
			6613.127,
			6614.908,
			6615.804,
			6617.606,
			6619.422,
			6620.337,
			6622.176,
			6624.029,
			6626.839,
			6627.783,
			6628.73,
			6630.636,
			6632.557,
			6633.522,
			6635.464,
			6636.441,
			6638.406,
			6640.384,
			6643.376,
			6645.388,
			6648.426,
			6649.445,
			6650.468,
			6653.552,
			6655.621,
			6658.748,
			6660.841,
			6662.934,
			6663.982,
			6666.09,
			6667.149,
			6669.274,
			6672.483,
			6674.638,
			6676.802,
			6680.069,
			6681.164,
			6682.26,
			6684.458,
			6686.668,
			6687.777,
			6691.118,
			6693.359,
			6696.741,
			6699.01,
			6701.291,
			6704.732,
			6705.885,
			6707.041,
			6710.522,
			6712.858,
			6716.383,
			6718.747,
			6721.12,
			6722.31,
			6724.699,
			6727.099,
			6728.302,
			6730.717,
			6731.931,
			6734.364,
			6736.807,
			6740.492,
			6742.963,
			6746.69,
			6747.938,
			6749.189,
			6752.956,
			6755.481,
			6759.288,
			6761.837,
			6764.397,
			6768.257,
			6769.548,
			6772.138,
			6776.04,
			6777.345,
			6781.261,
			6783.881,
			6787.828,
			6790.471,
			6793.122,
			6794.45,
			6797.113,
			6798.448,
			6801.125,
			6803.81,
			6807.852,
			6810.558,
			6814.632,
			6815.995,
			6817.359,
			6821.465,
			6824.213,
			6828.353,
			6831.123,
			6833.901,
			6835.294,
			6838.084,
			6839.482,
			6842.285,
			6845.095,
			6846.503,
			6849.326,
			6852.155,
			6854.994,
			6859.268,
			6862.127,
			6864.994,
			6866.432,
			6869.313,
			6870.757,
			6873.648,
			6876.548,
			6880.917,
			6883.836,
			6888.225,
			6889.692,
			6891.161,
			6895.577,
			6898.527,
			6902.965,
			6905.931,
			6910.393,
			6913.375,
			6914.869,
			6917.863,
			6922.365,
			6925.375,
			6928.391,
			6932.927,
			6934.442,
			6935.959,
			6940.521,
			6942.044,
			6943.569,
			6946.625,
			6948.155,
			6951.222,
			6954.295,
			6958.917,
			6962.007,
			6966.654,
			6968.207,
			6969.761,
			6974.434,
			6977.557,
			6979.712
		],
		[
			0,
			0.038,
			0.1,
			0.144,
			0.161,
			0.224,
			0.253,
			0.284,
			0.347,
			0.363,
			0.409,
			0.471,
			0.533,
			0.581,
			0.658,
			0.687,
			0.721,
			0.782,
			0.843,
			0.907,
			0.967,
			1.014,
			1.091,
			1.123,
			1.153,
			1.217,
			1.232,
			1.278,
			1.34,
			1.402,
			1.447,
			1.464,
			1.524,
			1.556,
			1.587,
			1.65,
			1.665,
			1.713,
			1.772,
			1.834,
			1.899,
			1.96,
			1.991,
			2.022,
			2.084,
			2.147,
			2.209,
			2.27,
			2.332,
			2.394,
			2.424,
			2.457,
			2.518,
			2.535,
			2.578,
			2.643,
			2.703,
			2.749,
			2.767,
			2.828,
			2.858,
			2.891,
			2.951,
			2.968,
			3.015,
			3.077,
			3.137,
			3.185,
			3.262,
			3.293,
			3.325,
			3.401,
			3.447,
			3.512,
			3.573,
			3.635,
			3.696,
			3.726,
			3.758,
			3.836,
			3.883,
			3.943,
			4.007,
			4.053,
			4.068,
			4.129,
			4.164,
			4.193,
			4.255,
			4.315,
			4.378,
			4.44,
			4.488,
			4.563,
			4.595,
			4.625,
			4.687,
			4.751,
			4.812,
			4.873,
			4.922,
			4.937,
			4.997,
			5.03,
			5.059,
			5.122,
			5.136,
			5.184,
			5.248,
			5.308,
			5.37,
			5.432,
			5.464,
			5.494,
			5.558,
			5.617,
			5.681,
			5.741,
			5.806,
			5.868,
			5.896,
			5.929,
			5.989,
			6.006,
			6.052,
			6.114,
			6.175,
			6.24,
			6.299,
			6.33,
			6.363,
			6.425,
			6.44,
			6.485,
			6.55,
			6.611,
			6.657,
			6.733,
			6.765,
			6.795,
			6.857,
			6.92,
			6.984,
			7.044,
			7.091,
			7.107,
			7.169,
			7.201,
			7.23,
			7.291,
			7.307,
			7.353,
			7.418,
			7.479,
			7.524,
			7.54,
			7.604,
			7.635,
			7.665,
			7.742,
			7.787,
			7.851,
			7.914,
			7.973,
			8.038,
			8.069,
			8.099,
			8.175,
			8.223,
			8.29,
			8.346,
			8.392,
			8.41,
			8.472,
			8.501,
			8.533,
			8.596,
			8.61,
			8.658,
			8.72,
			8.78,
			8.828,
			8.844,
			8.906,
			8.937,
			8.966,
			9.028,
			9.046,
			9.092,
			9.154,
			9.214,
			9.26,
			9.339,
			9.371,
			9.402,
			9.477,
			9.524,
			9.585,
			9.65,
			9.696,
			9.711,
			9.773,
			9.802,
			9.836,
			9.896,
			9.913,
			9.958,
			10.02,
			10.084,
			10.146,
			10.207,
			10.239,
			10.27,
			10.33,
			10.393,
			10.456,
			10.518,
			10.564,
			10.641,
			10.673,
			10.702,
			10.764,
			10.781,
			10.827,
			10.89,
			10.952,
			10.998,
			11.012,
			11.076,
			11.107,
			11.138,
			11.199,
			11.214,
			11.262,
			11.324,
			11.386,
			11.432,
			11.51,
			11.542,
			11.57,
			11.633,
			11.697,
			11.758,
			11.82,
			11.88,
			11.944,
			11.974,
			12.006,
			12.084,
			12.128,
			12.191,
			12.254,
			12.3,
			12.314,
			12.379,
			12.412,
			12.44,
			12.501,
			12.518,
			12.563,
			12.627,
			12.685,
			12.735,
			12.81,
			12.842,
			12.874,
			12.936,
			12.998,
			13.061,
			13.122,
			13.169,
			13.183,
			13.244,
			13.277,
			13.308,
			13.37,
			13.384,
			13.432,
			13.495,
			13.556,
			13.602,
			13.617,
			13.681,
			13.71,
			13.741,
			13.803,
			13.82,
			13.865,
			13.927,
			13.99,
			14.037,
			14.113,
			14.145,
			14.176,
			14.252,
			14.301,
			14.362,
			14.424,
			14.485,
			14.547,
			14.58,
			14.61,
			14.685,
			14.735,
			14.796,
			14.857,
			14.905,
			14.982,
			15.014,
			15.045,
			15.106,
			15.167,
			15.229,
			15.293,
			15.339,
			15.416,
			15.447,
			15.477,
			15.54,
			15.556,
			15.602,
			15.663,
			15.725,
			15.786,
			15.849,
			15.88,
			15.911,
			15.974,
			15.99,
			16.036,
			16.098,
			16.159,
			16.208,
			16.283,
			16.314,
			16.347,
			16.409,
			16.471,
			16.536,
			16.594,
			16.641,
			16.657,
			16.717,
			16.748,
			16.78,
			16.858,
			16.905,
			16.967,
			17.029,
			17.076,
			17.09,
			17.152,
			17.184,
			17.214,
			17.277,
			17.291,
			17.339,
			17.402,
			17.461,
			17.509,
			17.585,
			17.619,
			17.647,
			17.71,
			17.772,
			17.835,
			17.896,
			17.942,
			17.96,
			18.022,
			18.05,
			18.081,
			18.145,
			18.159,
			18.206,
			18.269,
			18.331,
			18.378,
			18.394,
			18.454,
			18.485,
			18.517,
			18.58,
			18.639,
			18.702,
			18.764,
			18.828,
			18.89,
			18.918,
			18.951,
			19.012,
			19.073,
			19.135,
			19.197,
			19.246,
			19.261,
			19.323,
			19.355,
			19.384,
			19.446,
			19.463,
			19.509,
			19.571,
			19.634,
			19.679,
			19.696,
			19.758,
			19.787,
			19.818,
			19.88,
			19.943,
			20.006,
			20.067,
			20.115,
			20.19,
			20.222,
			20.253,
			20.314,
			20.377,
			20.441,
			20.502,
			20.548,
			20.563,
			20.624,
			20.66,
			20.685,
			20.749,
			20.764,
			20.811,
			20.874,
			20.934,
			20.982,
			21.06,
			21.09,
			21.12,
			21.198,
			21.244,
			21.307,
			21.37,
			21.415,
			21.432,
			21.492,
			21.525,
			21.556,
			21.616,
			21.632,
			21.678,
			21.742,
			21.802,
			21.865,
			21.927,
			21.957,
			21.99,
			22.05,
			22.068,
			22.112,
			22.175,
			22.239,
			22.284,
			22.36,
			22.393,
			22.424,
			22.485,
			22.546,
			22.609,
			22.672,
			22.722,
			22.732,
			22.796,
			22.825,
			22.858,
			22.92,
			22.935,
			22.982,
			23.044,
			23.106,
			23.151,
			23.167,
			23.231,
			23.26,
			23.29,
			23.355,
			23.417,
			23.479,
			23.54,
			23.587,
			23.663,
			23.695,
			23.726,
			23.787,
			23.85,
			23.912,
			23.973,
			24.021,
			24.037,
			24.098,
			24.129,
			24.158,
			24.22,
			24.237,
			24.282,
			24.345,
			24.408,
			24.452,
			24.471,
			24.532,
			24.562,
			24.592,
			24.654,
			24.672,
			24.717,
			24.779,
			24.784,
			24.842,
			24.886,
			24.965,
			24.998,
			25.028,
			25.09,
			25.106,
			25.152,
			25.213,
			25.276,
			25.322,
			25.338,
			25.399,
			25.43,
			25.461,
			25.524,
			25.539,
			25.587,
			25.651,
			25.708,
			25.757,
			25.835,
			25.865,
			25.896,
			25.958,
			26.019,
			26.083,
			26.144,
			26.19,
			26.207,
			26.267,
			26.298,
			26.329,
			26.393,
			26.408,
			26.455,
			26.518,
			26.579,
			26.624,
			26.641,
			26.701,
			26.732,
			26.765,
			26.825,
			26.844,
			26.887,
			26.949,
			27.011,
			27.074,
			27.135,
			27.166,
			27.197,
			27.261,
			27.322,
			27.386,
			27.447,
			27.493,
			27.509,
			27.569,
			27.6,
			27.633,
			27.694,
			27.71,
			27.757,
			27.818,
			27.881,
			27.926,
			27.941,
			28.003,
			28.034,
			28.066,
			28.142,
			28.19,
			28.251,
			28.314,
			28.361,
			28.439,
			28.469,
			28.502,
			28.579,
			28.624,
			28.687,
			28.749,
			28.811,
			28.873,
			28.907,
			28.935,
			28.998,
			29.011,
			29.057,
			29.122,
			29.184,
			29.23,
			29.243,
			29.307,
			29.338,
			29.369,
			29.43,
			29.493,
			29.556,
			29.615,
			29.663,
			29.68,
			29.74,
			29.77,
			29.802,
			29.864,
			29.881,
			29.927,
			29.99,
			30.052,
			30.098,
			30.112,
			30.174,
			30.206,
			30.236,
			30.298,
			30.315,
			30.362,
			30.424,
			30.486,
			30.532,
			30.609,
			30.64,
			30.672,
			30.733,
			30.794,
			30.858,
			30.92,
			30.969,
			31.043,
			31.073,
			31.106,
			31.183,
			31.229,
			31.29,
			31.352,
			31.416,
			31.478,
			31.508,
			31.54,
			31.6,
			31.617,
			31.662,
			31.724,
			31.787,
			31.848,
			31.91,
			31.943,
			31.974,
			32.035,
			32.095,
			32.16,
			32.221,
			32.283,
			32.345,
			32.376,
			32.407,
			32.468,
			32.484,
			32.531,
			32.594,
			32.656,
			32.701,
			32.718,
			32.778,
			32.809,
			32.841,
			32.904,
			32.919,
			32.965,
			33.034,
			33.089,
			33.135,
			33.213,
			33.244,
			33.275,
			33.352,
			33.399,
			33.463,
			33.523,
			33.584,
			33.646,
			33.677,
			33.709,
			33.786,
			33.835,
			33.894,
			33.958,
			34.005,
			34.081,
			34.113,
			34.143,
			34.206,
			34.267,
			34.33,
			34.392,
			34.437,
			34.515,
			34.546,
			34.578,
			34.653,
			34.702,
			34.763,
			34.825,
			34.873,
			34.886,
			34.951,
			34.98,
			35.012,
			35.074,
			35.095,
			35.136,
			35.198,
			35.26,
			35.32,
			35.384,
			35.414,
			35.445,
			35.509,
			35.57,
			35.633,
			35.692,
			35.741,
			35.757,
			35.819,
			35.847,
			35.879,
			35.943,
			35.957,
			36.002,
			36.069,
			36.127,
			36.173,
			36.191,
			36.252,
			36.283,
			36.314,
			36.391,
			36.439,
			36.504,
			36.56,
			36.607,
			36.685,
			36.716,
			36.749,
			36.808,
			36.873,
			36.936,
			36.996,
			37.058,
			37.12,
			37.181,
			37.244,
			37.26,
			37.306,
			37.369,
			37.431,
			37.477,
			37.492,
			37.553,
			37.584,
			37.615,
			37.695,
			37.739,
			37.802,
			37.864,
			37.927,
			37.987,
			38.018,
			38.051,
			38.112,
			38.174,
			38.237,
			38.299,
			38.346,
			38.36,
			38.421,
			38.452,
			38.483,
			38.547,
			38.561,
			38.609,
			38.669,
			38.732,
			38.78,
			38.857,
			38.889,
			38.918,
			38.996,
			39.042,
			39.103,
			39.167,
			39.229,
			39.291,
			39.319,
			39.353,
			39.414,
			39.43,
			39.477,
			39.539,
			39.599,
			39.663,
			39.725,
			39.754,
			39.787,
			39.848,
			39.864,
			39.91,
			39.973,
			40.033,
			40.08,
			40.158,
			40.19,
			40.221,
			40.283,
			40.344,
			40.406,
			40.469,
			40.514,
			40.532,
			40.594,
			40.625,
			40.654,
			40.733,
			40.779,
			40.841,
			40.904,
			40.948,
			40.965,
			41.026,
			41.057,
			41.09,
			41.151,
			41.165,
			41.213,
			41.281,
			41.336,
			41.399,
			41.461,
			41.492,
			41.524,
			41.585,
			41.647,
			41.708,
			41.772,
			41.818,
			41.833,
			41.896,
			41.926,
			41.957,
			42.019,
			42.033,
			42.079,
			42.143,
			42.203,
			42.251,
			42.266,
			42.327,
			42.36,
			42.391,
			42.451,
			42.469,
			42.516,
			42.577,
			42.64,
			42.685,
			42.764,
			42.795,
			42.824,
			42.902,
			42.949,
			43.011,
			43.073,
			43.12,
			43.134,
			43.198,
			43.229,
			43.259,
			43.319,
			43.341,
			43.384,
			43.446,
			43.508,
			43.551,
			43.569,
			43.629,
			43.663,
			43.692,
			43.754,
			43.768,
			43.817,
			43.883,
			43.942,
			43.986,
			44.065,
			44.097,
			44.128,
			44.205,
			44.25,
			44.314,
			44.375,
			44.422,
			44.436,
			44.5,
			44.53,
			44.562,
			44.624,
			44.639,
			44.686,
			44.747,
			44.809,
			44.857,
			44.933,
			44.965,
			44.994,
			45.074,
			45.119,
			45.183,
			45.243,
			45.304,
			45.368,
			45.402,
			45.429,
			45.49,
			45.507,
			45.553,
			45.616,
			45.677,
			45.725,
			45.74,
			45.802,
			45.833,
			45.865,
			45.925,
			45.941,
			45.989,
			46.05,
			46.111,
			46.174,
			46.235,
			46.266,
			46.296,
			46.36,
			46.422,
			46.483,
			46.544,
			46.593,
			46.606,
			46.669,
			46.699,
			46.733,
			46.809,
			46.856,
			46.918,
			46.981,
			47.027,
			47.042,
			47.104,
			47.134,
			47.166,
			47.228,
			47.242,
			47.29,
			47.352,
			47.414,
			47.464,
			47.537,
			47.568,
			47.6,
			47.661,
			47.724,
			47.787,
			47.846,
			47.894,
			47.911,
			47.973,
			48.003,
			48.033,
			48.097,
			48.11,
			48.157,
			48.219,
			48.281,
			48.345,
			48.407,
			48.438,
			48.468,
			48.53,
			48.592,
			48.654,
			48.715,
			48.762,
			48.84,
			48.872,
			48.901,
			48.965,
			49.027,
			49.089,
			49.15,
			49.197,
			49.213,
			49.274,
			49.305,
			49.336,
			49.415,
			49.46,
			49.521,
			49.585,
			49.647,
			49.709,
			49.74,
			49.769,
			49.831,
			49.895,
			49.957,
			50.019,
			50.08,
			50.141,
			50.172,
			50.203,
			50.266,
			50.28,
			50.328,
			50.391,
			50.452,
			50.5,
			50.513,
			50.576,
			50.608,
			50.639,
			50.699,
			50.717,
			50.762,
			50.825,
			50.885,
			50.932,
			51.01,
			51.04,
			51.073,
			51.136,
			51.197,
			51.259,
			51.32,
			51.366,
			51.382,
			51.445,
			51.476,
			51.506,
			51.567,
			51.589,
			51.631,
			51.694,
			51.753,
			51.8,
			51.816,
			51.878,
			51.91,
			51.94,
			52.003,
			52.017,
			52.065,
			52.128,
			52.186,
			52.25,
			52.312,
			52.343,
			52.376,
			52.436,
			52.5,
			52.56,
			52.623,
			52.669,
			52.684,
			52.745,
			52.779,
			52.807,
			52.872,
			52.886,
			52.933,
			52.996,
			53.057,
			53.104,
			53.118,
			53.18,
			53.21,
			53.242,
			53.304,
			53.32,
			53.367,
			53.429,
			53.491,
			53.536,
			53.614,
			53.651,
			53.677,
			53.737,
			53.8,
			53.864,
			53.926,
			53.972,
			53.988,
			54.049,
			54.079,
			54.11,
			54.173,
			54.189,
			54.235,
			54.298,
			54.36,
			54.406,
			54.482,
			54.514,
			54.544,
			54.608,
			54.668,
			54.732,
			54.792,
			54.84,
			54.855,
			54.916,
			54.948,
			54.98,
			55.04,
			55.058,
			55.103,
			55.165,
			55.227,
			55.289,
			55.352,
			55.382,
			55.414,
			55.476,
			55.49,
			55.537,
			55.599,
			55.66,
			55.721,
			55.786,
			55.815,
			55.846,
			55.91,
			55.972,
			56.033,
			56.096,
			56.143,
			56.157,
			56.22,
			56.25,
			56.281,
			56.359,
			56.405,
			56.469,
			56.53,
			56.576,
			56.592,
			56.652,
			56.683,
			56.714,
			56.792,
			56.839,
			56.901,
			56.963,
			57.01,
			57.087,
			57.118,
			57.149,
			57.21,
			57.272,
			57.336,
			57.397,
			57.46,
			57.521,
			57.553,
			57.585,
			57.645,
			57.662,
			57.708,
			57.773,
			57.83,
			57.879,
			57.894,
			57.953,
			57.986,
			58.016,
			58.079,
			58.095,
			58.142,
			58.202,
			58.266,
			58.327,
			58.39,
			58.42,
			58.452,
			58.528,
			58.577,
			58.638,
			58.698,
			58.761,
			58.825,
			58.853,
			58.885,
			58.947,
			58.964,
			59.008,
			59.073,
			59.134,
			59.181,
			59.258,
			59.287,
			59.318,
			59.382,
			59.445,
			59.504,
			59.569,
			59.614,
			59.63,
			59.692,
			59.724,
			59.751,
			59.835,
			59.877,
			59.941,
			60.001,
			60.048,
			60.063,
			60.127,
			60.158,
			60.188,
			60.251,
			60.266,
			60.313,
			60.374,
			60.436,
			60.497,
			60.56,
			60.59,
			60.622,
			60.685,
			60.746,
			60.809,
			60.871,
			60.917,
			60.932,
			60.994,
			61.024,
			61.054,
			61.119,
			61.133,
			61.181,
			61.243,
			61.305,
			61.351,
			61.365,
			61.428,
			61.46,
			61.49,
			61.551,
			61.568,
			61.615,
			61.676,
			61.738,
			61.8,
			61.861,
			61.897,
			61.924,
			61.986,
			62.049,
			62.11,
			62.173,
			62.218,
			62.235,
			62.297,
			62.327,
			62.36,
			62.419,
			62.436,
			62.482,
			62.546,
			62.607,
			62.653,
			62.669,
			62.73,
			62.762,
			62.793,
			62.868,
			62.916,
			62.978,
			63.042,
			63.086,
			63.166,
			63.197,
			63.227,
			63.29,
			63.351,
			63.413,
			63.476,
			63.536,
			63.599,
			63.629,
			63.66,
			63.737,
			63.785,
			63.848,
			63.91,
			63.959,
			64.034,
			64.065,
			64.096,
			64.157,
			64.219,
			64.28,
			64.342,
			64.403,
			64.466,
			64.498,
			64.529,
			64.591,
			64.607,
			64.654,
			64.716,
			64.776,
			64.837,
			64.902,
			64.931,
			64.962,
			65.024,
			65.041,
			65.087,
			65.149,
			65.212,
			65.273,
			65.334,
			65.366,
			65.397,
			65.457,
			65.52,
			65.582,
			65.645,
			65.707,
			65.77,
			65.8,
			65.83,
			65.893,
			65.907,
			65.972,
			66.019,
			66.034,
			66.093,
			66.124,
			66.157,
			66.235,
			66.28,
			66.342,
			66.403,
			66.451,
			66.466,
			66.528,
			66.558,
			66.592,
			66.654,
			66.669,
			66.713,
			66.78,
			66.838,
			66.886,
			66.962,
			66.994,
			67.025,
			67.085,
			67.149,
			67.21,
			67.274,
			67.319,
			67.335,
			67.396,
			67.429,
			67.459,
			67.536,
			67.583,
			67.646,
			67.708,
			67.753,
			67.767,
			67.832,
			67.861,
			67.894,
			67.97,
			68.016,
			68.083,
			68.141,
			68.187,
			68.265,
			68.296,
			68.327,
			68.389,
			68.405,
			68.452,
			68.512,
			68.574,
			68.637,
			68.699,
			68.729,
			68.761,
			68.824,
			68.838,
			68.885,
			68.947,
			69.008,
			69.055,
			69.132,
			69.162,
			69.195,
			69.273,
			69.32,
			69.381,
			69.444,
			69.491,
			69.506,
			69.566,
			69.599,
			69.63,
			69.692,
			69.706,
			69.752,
			69.817,
			69.876,
			69.924,
			69.939,
			70,
			70.033,
			70.064,
			70.124,
			70.143,
			70.186,
			70.248,
			70.312,
			70.358,
			70.436,
			70.466,
			70.497,
			70.56,
			70.622,
			70.685,
			70.745,
			70.79,
			70.806,
			70.869,
			70.899,
			70.93,
			70.994,
			71.007,
			71.057,
			71.117,
			71.179,
			71.224,
			71.24,
			71.303,
			71.333,
			71.365,
			71.427,
			71.444,
			71.491,
			71.552,
			71.613,
			71.659,
			71.736,
			71.769,
			71.8,
			71.877,
			71.924,
			71.99,
			72.047,
			72.093,
			72.11,
			72.17,
			72.207,
			72.234,
			72.295,
			72.312,
			72.357,
			72.418,
			72.482,
			72.529,
			72.544,
			72.604,
			72.637,
			72.667,
			72.746,
			72.791,
			72.853,
			72.916,
			72.961,
			73.041,
			73.072,
			73.102,
			73.165,
			73.18,
			73.224,
			73.288,
			73.349,
			73.396,
			73.411,
			73.473,
			73.506,
			73.534,
			73.598,
			73.612,
			73.66,
			73.72,
			73.783,
			73.83,
			73.909,
			73.939,
			73.969,
			74.032,
			74.093,
			74.156,
			74.218,
			74.272,
			74.341,
			74.374,
			74.405,
			74.467,
			74.482,
			74.528,
			74.59,
			74.651,
			74.698,
			74.714,
			74.775,
			74.806,
			74.838,
			74.9,
			74.917,
			74.961,
			75.024,
			75.086,
			75.133,
			75.21,
			75.24,
			75.273,
			75.334,
			75.395,
			75.459,
			75.52,
			75.566,
			75.583,
			75.643,
			75.674,
			75.706,
			75.767,
			75.785,
			75.83,
			75.892,
			75.956,
			76.001,
			76.017,
			76.079,
			76.11,
			76.14,
			76.217,
			76.265,
			76.33,
			76.387,
			76.436,
			76.513,
			76.544,
			76.573,
			76.637,
			76.698,
			76.76,
			76.823,
			76.884,
			76.947,
			76.978,
			77.01,
			77.068,
			77.085,
			77.132,
			77.196,
			77.257,
			77.304,
			77.318,
			77.38,
			77.413,
			77.442,
			77.505,
			77.566,
			77.629,
			77.69,
			77.752,
			77.816,
			77.844,
			77.876,
			77.939,
			77.999,
			78.063,
			78.126,
			78.171,
			78.185,
			78.25,
			78.279,
			78.311,
			78.373,
			78.394,
			78.435,
			78.496,
			78.557,
			78.603,
			78.62,
			78.683,
			78.72,
			78.745,
			78.823,
			78.869,
			78.935,
			78.992,
			79.037,
			79.116,
			79.148,
			79.178,
			79.241,
			79.256,
			79.303,
			79.367,
			79.427,
			79.473,
			79.487,
			79.551,
			79.581,
			79.612,
			79.691,
			79.736,
			79.799,
			79.86,
			79.907,
			79.986,
			80.014,
			80.046,
			80.108,
			80.172,
			80.233,
			80.295,
			80.341,
			80.358,
			80.418,
			80.457,
			80.481,
			80.559,
			80.603,
			80.668,
			80.729,
			80.79,
			80.853,
			80.884,
			80.913,
			80.977,
			80.991,
			81.04,
			81.102,
			81.163,
			81.209,
			81.287,
			81.319,
			81.349,
			81.41,
			81.473,
			81.535,
			81.598,
			81.645,
			81.66,
			81.719,
			81.752,
			81.783,
			81.845,
			81.86,
			81.906,
			81.971,
			82.032,
			82.093,
			82.155,
			82.186,
			82.216,
			82.296,
			82.342,
			82.404,
			82.466,
			82.527,
			82.59,
			82.619,
			82.652,
			82.729,
			82.774,
			82.837,
			82.899,
			82.946,
			82.961,
			83.025,
			83.056,
			83.086,
			83.146,
			83.164,
			83.21,
			83.27,
			83.332,
			83.379,
			83.396,
			83.458,
			83.487,
			83.519,
			83.582,
			83.644,
			83.707,
			83.768,
			83.813,
			83.891,
			83.924,
			83.953,
			84.017,
			84.03,
			84.079,
			84.14,
			84.202,
			84.247,
			84.265,
			84.327,
			84.358,
			84.387,
			84.45,
			84.464,
			84.511,
			84.578,
			84.636,
			84.682,
			84.759,
			84.791,
			84.823,
			84.884,
			84.946,
			85.008,
			85.071,
			85.115,
			85.133,
			85.193,
			85.224,
			85.253,
			85.335,
			85.378,
			85.443,
			85.504,
			85.549,
			85.566,
			85.627,
			85.659,
			85.69,
			85.752,
			85.767,
			85.814,
			85.877,
			85.939,
			85.984,
			86.062,
			86.094,
			86.125,
			86.185,
			86.249,
			86.309,
			86.373,
			86.419,
			86.435,
			86.496,
			86.526,
			86.557,
			86.641,
			86.681,
			86.743,
			86.807,
			86.854,
			86.867,
			86.929,
			86.96,
			86.993,
			87.054,
			87.071,
			87.116,
			87.179,
			87.239,
			87.287,
			87.365,
			87.396,
			87.425,
			87.489,
			87.551,
			87.612,
			87.674,
			87.719,
			87.737,
			87.799,
			87.828,
			87.86,
			87.939,
			87.984,
			88.046,
			88.108,
			88.17,
			88.231,
			88.262,
			88.293,
			88.357,
			88.42,
			88.479,
			88.542,
			88.605,
			88.667,
			88.702,
			88.728,
			88.79,
			88.807,
			88.854,
			88.915,
			88.977,
			89.024,
			89.038,
			89.101,
			89.133,
			89.162,
			89.226,
			89.239,
			89.285,
			89.353,
			89.41,
			89.457,
			89.534,
			89.566,
			89.596,
			89.658,
			89.719,
			89.783,
			89.845,
			89.892,
			89.907,
			89.969,
			89.999,
			90.03,
			90.093,
			90.108,
			90.153,
			90.215,
			90.277,
			90.326,
			90.34,
			90.401,
			90.433,
			90.464,
			90.527,
			90.542,
			90.587,
			90.652,
			90.712,
			90.774,
			90.835,
			90.869,
			90.9,
			90.961,
			91.024,
			91.086,
			91.146,
			91.193,
			91.207,
			91.272,
			91.302,
			91.332,
			91.396,
			91.409,
			91.457,
			91.519,
			91.58,
			91.627,
			91.642,
			91.705,
			91.735,
			91.768,
			91.83,
			91.891,
			91.952,
			92.014,
			92.061,
			92.14,
			92.169,
			92.202,
			92.28,
			92.325,
			92.387,
			92.449,
			92.494,
			92.512,
			92.572,
			92.605,
			92.634,
			92.696,
			92.712,
			92.759,
			92.825,
			92.882,
			92.93,
			93.008,
			93.039,
			93.069,
			93.131,
			93.192,
			93.253,
			93.318,
			93.363,
			93.442,
			93.472,
			93.502,
			93.566,
			93.582,
			93.626,
			93.691,
			93.752,
			93.797,
			93.812,
			93.875,
			93.906,
			93.938,
			93.999,
			94.016,
			94.06,
			94.124,
			94.184,
			94.232,
			94.309,
			94.341,
			94.372,
			94.432,
			94.495,
			94.559,
			94.621,
			94.667,
			94.682,
			94.743,
			94.775,
			94.807,
			94.867,
			94.888,
			94.931,
			94.991,
			95.053,
			95.099,
			95.115,
			95.179,
			95.21,
			95.24,
			95.317,
			95.362,
			95.427,
			95.486,
			95.533,
			95.612,
			95.645,
			95.674,
			95.736,
			95.797,
			95.861,
			95.923,
			95.984,
			96.045,
			96.077,
			96.107,
			96.17,
			96.185,
			96.233,
			96.294,
			96.357,
			96.403,
			96.417,
			96.481,
			96.51,
			96.543,
			96.602,
			96.619,
			96.666,
			96.728,
			96.791,
			96.851,
			96.914,
			96.949,
			96.977,
			97.038,
			97.101,
			97.162,
			97.224,
			97.271,
			97.287,
			97.347,
			97.378,
			97.41,
			97.473,
			97.488,
			97.533,
			97.595,
			97.658,
			97.704,
			97.721,
			97.782,
			97.814,
			97.843,
			97.922,
			97.972,
			98.029,
			98.093,
			98.152,
			98.216,
			98.247,
			98.278,
			98.341,
			98.403,
			98.464,
			98.527,
			98.573,
			98.587,
			98.651,
			98.68,
			98.712,
			98.773,
			98.79,
			98.837,
			98.898,
			98.959,
			99.011,
			99.083,
			99.115,
			99.145,
			99.208,
			99.271,
			99.334,
			99.393,
			99.457,
			99.517,
			99.548,
			99.58,
			99.642,
			99.658,
			99.705,
			99.766,
			99.828,
			99.89,
			99.952,
			99.984,
			100.014,
			100.075,
			100.091,
			100.14,
			100.2,
			100.264,
			100.324,
			100.387,
			100.417,
			100.447,
			100.525,
			100.573,
			100.636,
			100.697,
			100.758,
			100.82,
			100.852,
			100.881,
			100.944,
			100.96,
			101.007,
			101.073,
			101.13,
			101.177,
			101.194,
			101.252,
			101.286,
			101.316,
			101.379,
			101.395,
			101.44,
			101.502,
			101.565,
			101.61,
			101.69,
			101.719,
			101.75,
			101.829,
			101.876,
			101.937,
			101.998,
			102.045,
			102.062,
			102.124,
			102.154,
			102.183,
			102.263,
			102.31,
			102.369,
			102.432,
			102.478,
			102.494,
			102.557,
			102.589,
			102.619,
			102.695,
			102.742,
			102.804,
			102.866,
			102.912,
			102.99,
			103.023,
			103.052,
			103.116,
			103.135,
			103.176,
			103.24,
			103.301,
			103.346,
			103.364,
			103.424,
			103.456,
			103.485,
			103.549,
			103.563,
			103.611,
			103.675,
			103.736,
			103.796,
			103.86,
			103.891,
			103.919,
			103.983,
			104.044,
			104.107,
			104.17,
			104.215,
			104.232,
			104.292,
			104.323,
			104.356,
			104.417,
			104.432,
			104.48,
			104.541,
			104.604,
			104.651,
			104.664,
			104.726,
			104.757,
			104.79,
			104.852,
			104.867,
			104.914,
			104.977,
			105.036,
			105.085,
			105.16,
			105.198,
			105.223,
			105.302,
			105.348,
			105.411,
			105.472,
			105.518,
			105.535,
			105.595,
			105.627,
			105.658,
			105.72,
			105.736,
			105.782,
			105.844,
			105.905,
			105.952,
			105.969,
			106.029,
			106.06,
			106.09,
			106.152,
			106.17,
			106.216,
			106.277,
			106.339,
			106.387,
			106.464,
			106.495,
			106.527,
			106.601,
			106.651,
			106.711,
			106.775,
			106.819,
			106.835,
			106.896,
			106.928,
			106.959,
			107.022,
			107.038,
			107.084,
			107.146,
			107.207,
			107.257,
			107.332,
			107.362,
			107.392,
			107.456,
			107.518,
			107.58,
			107.642,
			107.689,
			107.766,
			107.796,
			107.827,
			107.89,
			107.905,
			107.95,
			108.013,
			108.075,
			108.12,
			108.138,
			108.198,
			108.229,
			108.262,
			108.34,
			108.385,
			108.447,
			108.51,
			108.557,
			108.635,
			108.666,
			108.695,
			108.757,
			108.819,
			108.881,
			108.945,
			108.992,
			109.007,
			109.069,
			109.098,
			109.129,
			109.207,
			109.254,
			109.316,
			109.379,
			109.424,
			109.44,
			109.503,
			109.532,
			109.563,
			109.626,
			109.642,
			109.686,
			109.751,
			109.813,
			109.86,
			109.936,
			109.967,
			109.997,
			110.059,
			110.123,
			110.184,
			110.247,
			110.308,
			110.371,
			110.401,
			110.433,
			110.511,
			110.557,
			110.618,
			110.679,
			110.727,
			110.741,
			110.802,
			110.836,
			110.867,
			110.928,
			110.943,
			110.99,
			111.052,
			111.114,
			111.16,
			111.24,
			111.27,
			111.301,
			111.361,
			111.385,
			111.424,
			111.485,
			111.55,
			111.61,
			111.673,
			111.703,
			111.735,
			111.796,
			111.811,
			111.858,
			111.921,
			111.982,
			112.03,
			112.106,
			112.137,
			112.168,
			112.246,
			112.291,
			112.353,
			112.416,
			112.462,
			112.541,
			112.573,
			112.603,
			112.68,
			112.727,
			112.79,
			112.851,
			112.896,
			112.913,
			112.975,
			113.006,
			113.036,
			113.099,
			113.115,
			113.162,
			113.224,
			113.284,
			113.345,
			113.409,
			113.444,
			113.469,
			113.532,
			113.596,
			113.657,
			113.718,
			113.765,
			113.781,
			113.843,
			113.873,
			113.906,
			113.965,
			113.982,
			114.028,
			114.09,
			114.152,
			114.199,
			114.215,
			114.277,
			114.307,
			114.34,
			114.4,
			114.416,
			114.463,
			114.525,
			114.588,
			114.633,
			114.711,
			114.741,
			114.773,
			114.851,
			114.898,
			114.959,
			115.022,
			115.083,
			115.144,
			115.177,
			115.207,
			115.269,
			115.284,
			115.33,
			115.392,
			115.455,
			115.507,
			115.578,
			115.609,
			115.642,
			115.704,
			115.717,
			115.764,
			115.828,
			115.889,
			115.937,
			116.014,
			116.045,
			116.074,
			116.153,
			116.199,
			116.262,
			116.324,
			116.384,
			116.446,
			116.478,
			116.509,
			116.586,
			116.635,
			116.694,
			116.758,
			116.802,
			116.82,
			116.882,
			116.913,
			116.942,
			117.004,
			117.022,
			117.068,
			117.129,
			117.193,
			117.252,
			117.316,
			117.346,
			117.377,
			117.44,
			117.502,
			117.569,
			117.625,
			117.688,
			117.748,
			117.782,
			117.812,
			117.875,
			117.89,
			117.937,
			117.999,
			118.059,
			118.106,
			118.185,
			118.215,
			118.246,
			118.308,
			118.369,
			118.433,
			118.493,
			118.54,
			118.617,
			118.649,
			118.681,
			118.742,
			118.757,
			118.802,
			118.865,
			118.928,
			118.989,
			119.051,
			119.083,
			119.115,
			119.176,
			119.191,
			119.237,
			119.301,
			119.36,
			119.409,
			119.485,
			119.517,
			119.546,
			119.611,
			119.631,
			119.673,
			119.734,
			119.795,
			119.843,
			119.859,
			119.918,
			119.952,
			119.981,
			120.045,
			120.059,
			120.107,
			120.17,
			120.229,
			120.276,
			120.293,
			120.354,
			120.385,
			120.416,
			120.477,
			120.492,
			120.54,
			120.602,
			120.665,
			120.725,
			120.787,
			120.82,
			120.85,
			120.911,
			120.974,
			121.036,
			121.099,
			121.16,
			121.223,
			121.251,
			121.285,
			121.361,
			121.407,
			121.47,
			121.533,
			121.593,
			121.657,
			121.692,
			121.718,
			121.781,
			121.843,
			121.904,
			121.966,
			122.012,
			122.091,
			122.122,
			122.151,
			122.23,
			122.277,
			122.34,
			122.401,
			122.462,
			122.524,
			122.555,
			122.585,
			122.648,
			122.665,
			122.71,
			122.774,
			122.834,
			122.881,
			122.957,
			122.99,
			123.021,
			123.083,
			123.144,
			123.207,
			123.269,
			123.314,
			123.331,
			123.393,
			123.424,
			123.455,
			123.532,
			123.579,
			123.641,
			123.704,
			123.765,
			123.826,
			123.857,
			123.89,
			123.951,
			123.966,
			124.012,
			124.075,
			124.136,
			124.183,
			124.261,
			124.292,
			124.324,
			124.385,
			124.447,
			124.508,
			124.571,
			124.617,
			124.632,
			124.694,
			124.724,
			124.757,
			124.833,
			124.88,
			124.944,
			125.005,
			125.051,
			125.065,
			125.129,
			125.159,
			125.191,
			125.252,
			125.268,
			125.315,
			125.378,
			125.44,
			125.486,
			125.561,
			125.594,
			125.624,
			125.702,
			125.75,
			125.815,
			125.874,
			125.936,
			125.997,
			126.028,
			126.06,
			126.122,
			126.135,
			126.183,
			126.245,
			126.308,
			126.369,
			126.432,
			126.463,
			126.492,
			126.553,
			126.571,
			126.617,
			126.68,
			126.742,
			126.802,
			126.864,
			126.896,
			126.927,
			126.99,
			127.051,
			127.113,
			127.174,
			127.22,
			127.237,
			127.299,
			127.331,
			127.36,
			127.424,
			127.44,
			127.485,
			127.548,
			127.61,
			127.669,
			127.733,
			127.765,
			127.794,
			127.878,
			127.918,
			127.982,
			128.043,
			128.091,
			128.166,
			128.198,
			128.228,
			128.29,
			128.307,
			128.354,
			128.417,
			128.477,
			128.523,
			128.54,
			128.601,
			128.633,
			128.662,
			128.725,
			128.741,
			128.787,
			128.849,
			128.912,
			128.958,
			129.035,
			129.068,
			129.098,
			129.16,
			129.223,
			129.283,
			129.346,
			129.407,
			129.469,
			129.501,
			129.532,
			129.593,
			129.609,
			129.655,
			129.718,
			129.779,
			129.842,
			129.904,
			129.939,
			129.966,
			130.028,
			130.042,
			130.091,
			130.157,
			130.213,
			130.261,
			130.336,
			130.37,
			130.4,
			130.46,
			130.524,
			130.59,
			130.647,
			130.71,
			130.772,
			130.803,
			130.834,
			130.895,
			130.911,
			130.957,
			131.021,
			131.08,
			131.127,
			131.145,
			131.206,
			131.236,
			131.268,
			131.33,
			131.391,
			131.452,
			131.515,
			131.563,
			131.641,
			131.67,
			131.703,
			131.779,
			131.825,
			131.889,
			131.949,
			132.002,
			132.013,
			132.075,
			132.106,
			132.135,
			132.198,
			132.213,
			132.259,
			132.322,
			132.385,
			132.431,
			132.507,
			132.539,
			132.571,
			132.631,
			132.695,
			132.756,
			132.819,
			132.881,
			132.942,
			132.972,
			133.002,
			133.066,
			133.082,
			133.127,
			133.19,
			133.252,
			133.299,
			133.314,
			133.376,
			133.408,
			133.438,
			133.501,
			133.514,
			133.561,
			133.624,
			133.685,
			133.732,
			133.81,
			133.842,
			133.873,
			133.948,
			133.996,
			134.064,
			134.119,
			134.166,
			134.181,
			134.245,
			134.275,
			134.307,
			134.369,
			134.385,
			134.432,
			134.491,
			134.555,
			134.602,
			134.616,
			134.679,
			134.71,
			134.741,
			134.802,
			134.819,
			134.864,
			134.928,
			134.987,
			135.035,
			135.113,
			135.143,
			135.174,
			135.237,
			135.298,
			135.361,
			135.423,
			135.468,
			135.485,
			135.546,
			135.579,
			135.609,
			135.686,
			135.732,
			135.794,
			135.858,
			135.904,
			135.918,
			135.979,
			136.013,
			136.043,
			136.124,
			136.166,
			136.23,
			136.29,
			136.336,
			136.414,
			136.445,
			136.476,
			136.539,
			136.601,
			136.661,
			136.726,
			136.772,
			136.785,
			136.848,
			136.88,
			136.912,
			136.974,
			136.989,
			137.035,
			137.097,
			137.16,
			137.205,
			137.282,
			137.313,
			137.346,
			137.423,
			137.47,
			137.533,
			137.592,
			137.639,
			137.716,
			137.748,
			137.78,
			137.857,
			137.904,
			137.971,
			138.028,
			138.09,
			138.151,
			138.185,
			138.214,
			138.275,
			138.291,
			138.337,
			138.399,
			138.46,
			138.507,
			138.584,
			138.617,
			138.648,
			138.708,
			138.771,
			138.835,
			138.895,
			138.94,
			138.958,
			139.018,
			139.049,
			139.082,
			139.143,
			139.158,
			139.205,
			139.267,
			139.328,
			139.391,
			139.452,
			139.485,
			139.515,
			139.577,
			139.593,
			139.64,
			139.702,
			139.763,
			139.824,
			139.889,
			139.918,
			139.948,
			140.012,
			140.073,
			140.137,
			140.196,
			140.259,
			140.322,
			140.353,
			140.383,
			140.462,
			140.507,
			140.568,
			140.632,
			140.677,
			140.694,
			140.755,
			140.787,
			140.817,
			140.879,
			140.896,
			140.942,
			141.004,
			141.066,
			141.111,
			141.19,
			141.22,
			141.252,
			141.313,
			141.376,
			141.399
		]
	],
	'6053_6055_7982': [
		[
			50.047
		],
		[
			0
		],
		[
			0,
			1.944,
			3.217,
			4.492,
			8.33,
			10.899,
			13.476,
			17.358,
			19.957,
			23.871,
			25.176,
			26.482,
			30.406,
			33.028,
			36.974,
			39.612,
			42.258,
			43.583,
			46.24,
			48.903,
			50.237,
			52.91,
			54.247,
			56.917,
			59.57,
			63.498,
			66.071,
			69.862,
			71.107,
			72.343,
			75.993,
			78.381,
			81.899,
			84.2,
			86.464,
			89.791,
			90.881,
			93.031,
			95.144,
			96.185,
			97.216,
			100.252,
			102.228,
			104.166,
			105.122,
			107.006,
			108.854,
			109.765,
			111.559,
			112.44,
			114.192,
			115.894,
			118.394,
			120.028,
			122.44,
			123.235,
			124.028,
			126.398,
			127.977,
			130.347,
			131.943,
			133.551,
			134.355,
			135.963,
			136.785,
			138.432,
			140.078,
			140.902,
			142.579,
			144.261,
			146.801,
			147.665,
			148.528,
			150.257,
			151.989,
			152.856,
			154.594,
			155.465,
			157.168,
			158.839,
			161.353,
			162.96,
			165.401,
			166.218,
			167.032,
			169.479,
			171.109,
			173.582,
			175.218,
			176.859,
			177.701,
			179.379,
			180.215,
			181.926,
			183.673,
			184.544,
			186.281,
			188.043,
			190.669,
			192.398,
			194.963,
			195.814,
			196.655,
			199.093,
			200.707,
			202.274,
			204.554,
			206.07,
			208.291,
			209.025,
			209.758,
			211.955,
			213.419,
			215.62,
			217.107,
			219.382,
			220.908,
			222.447,
			223.221,
			224.765,
			225.548,
			227.12,
			228.697,
			231.087,
			232.704,
			235.161,
			235.987,
			236.816,
			239.336,
			241.032,
			243.578,
			245.285,
			247.006,
			247.875,
			249.621,
			250.499,
			251.38,
			254.956,
			255.859,
			258.593,
			260.436,
			262.292,
			263.227,
			265.108,
			267.001,
			267.954,
			269.869,
			270.832,
			272.766,
			274.729,
			277.673,
			279.65,
			282.64,
			283.644,
			284.65,
			287.69,
			289.734,
			292.826,
			294.908,
			297.003,
			298.062,
			300.189,
			301.256,
			303.4,
			305.552,
			306.629,
			308.778,
			310.923,
			313.056,
			316.22,
			318.3,
			320.349,
			321.363,
			323.36,
			324.343,
			326.281,
			328.179,
			330.934,
			332.732,
			335.349,
			336.202,
			337.046,
			339.509,
			341.103,
			343.42,
			344.916,
			347.091,
			348.492,
			349.179,
			350.528,
			351.844,
			352.489,
			353.754,
			354.982,
			356.757,
			357.331,
			357.897,
			359.541,
			360.072,
			360.593,
			362.105,
			363.078,
			364.016,
			365.365,
			366.241,
			367.516,
			367.946,
			368.372,
			369.638,
			370.478,
			371.742,
			372.61,
			373.52,
			373.975,
			374.889,
			375.807,
			376.269,
			377.195,
			377.686,
			378.691,
			379.693,
			381.193,
			382.194,
			383.766,
			384.287,
			384.807,
			386.357,
			387.384,
			388.946,
			389.969,
			390.981,
			392.484,
			392.98,
			393.959,
			394.922,
			395.399,
			395.873,
			397.289,
			398.218,
			399.133,
			399.586,
			400.487,
			401.384,
			401.832,
			402.725,
			403.167,
			404.044,
			404.915,
			406.218,
			407.09,
			408.395,
			408.826,
			409.258,
			410.553,
			411.422,
			412.74,
			413.62,
			414.503,
			414.946,
			415.839,
			416.289,
			417.199,
			418.114,
			418.574,
			419.502,
			420.442,
			421.394,
			422.36,
			422.846,
			423.826,
			424.822,
			425.325,
			426.345,
			426.861,
			427.904,
			428.957,
			430.568,
			431.667,
			433.347,
			433.913,
			434.483,
			436.217,
			437.395,
			439.182,
			440.386,
			441.589,
			442.192,
			443.406,
			444.016,
			445.247,
			446.495,
			447.125,
			448.399,
			449.687,
			451.649,
			452.31,
			452.982,
			455.03,
			455.72,
			456.414,
			458.538,
			459.99,
			461.458,
			463.717,
			465.263,
			467.611,
			468.416,
			469.224,
			471.659,
			473.31,
			475.811,
			477.497,
			479.184,
			480.031,
			481.723,
			483.414,
			484.261,
			485.967,
			486.825,
			488.549,
			490.284,
			492.912,
			494.675,
			497.344,
			498.24,
			499.138,
			501.852,
			503.679,
			506.448,
			508.311,
			510.192,
			511.137,
			513.037,
			513.995,
			514.956,
			517.858,
			518.833,
			519.812,
			522.767,
			524.752,
			526.748,
			527.751,
			529.759,
			531.779,
			532.793,
			534.825,
			535.845,
			537.894,
			539.954,
			543.065,
			545.157,
			548.298,
			549.35,
			550.405,
			553.586,
			556.799,
			558.957,
			562.217,
			563.31,
			564.408,
			567.72,
			569.947,
			573.311,
			575.568,
			577.834,
			578.97,
			581.245,
			582.386,
			584.673,
			586.967,
			588.117,
			590.423,
			592.738,
			596.223,
			597.389,
			598.558,
			600.899,
			603.25,
			604.429,
			606.792,
			607.977,
			610.352,
			612.736,
			616.325,
			618.729,
			622.35,
			623.562,
			624.776,
			628.432,
			630.882,
			634.576,
			637.052,
			639.536,
			640.782,
			643.281,
			644.533,
			647.045,
			649.565,
			650.828,
			653.36,
			655.901,
			659.728,
			661.008,
			662.29,
			666.15,
			667.441,
			668.734,
			672.626,
			675.231,
			677.841,
			681.76,
			684.382,
			688.329,
			689.649,
			690.97,
			696.273,
			697.603,
			701.605,
			704.282,
			708.309,
			711.003,
			713.703,
			715.056,
			717.769,
			719.128,
			721.852,
			724.583,
			728.697,
			731.45,
			735.594,
			736.979,
			738.366,
			742.536,
			745.325,
			749.521,
			752.326,
			755.138,
			756.547,
			759.369,
			760.782,
			762.197,
			767.875,
			769.299,
			773.581,
			776.444,
			779.314,
			780.752,
			783.632,
			786.518,
			787.964,
			790.862,
			792.313,
			795.221,
			798.137,
			802.524,
			805.458,
			809.872,
			811.347,
			812.824,
			817.265,
			820.233,
			824.698,
			827.682,
			830.672,
			832.168,
			835.165,
			836.665,
			839.67,
			844.189,
			847.21,
			850.236,
			853.265,
			856.291,
			857.799,
			860.799,
			863.772,
			865.246,
			869.61,
			872.471,
			875.294,
			879.457,
			882.183,
			886.202,
			887.521,
			888.832,
			892.706,
			895.241,
			898.97,
			901.409,
			903.813,
			905.002,
			907.354,
			908.518,
			910.82,
			913.084,
			914.204,
			916.413,
			918.587,
			921.776,
			923.857,
			926.908,
			927.907,
			928.897,
			930.85,
			931.813,
			933.712,
			935.574,
			938.304,
			940.082,
			942.682,
			943.53,
			944.369,
			946.83,
			947.631,
			948.424,
			950.747,
			952.251,
			953.736,
			954.461,
			955.895,
			957.309,
			958.008,
			959.397,
			960.088,
			961.467,
			962.838,
			964.886,
			965.569,
			966.251,
			968.301,
			968.984,
			969.667,
			971.717,
			973.107,
			975.195,
			976.587,
			978.005,
			978.718,
			980.141,
			980.853,
			982.283,
			983.731,
			984.454,
			985.177,
			987.352,
			988.824,
			990.292,
			991.026,
			992.505,
			994.002,
			994.75,
			996.244,
			996.99,
			998.505,
			1000.016,
			1002.284,
			1003.811,
			1006.093,
			1006.853,
			1007.616,
			1009.9,
			1011.418,
			1013.687,
			1015.192,
			1016.695,
			1017.447,
			1018.948,
			1019.697,
			1021.194,
			1023.436,
			1024.181,
			1026.415,
			1027.901,
			1029.38,
			1030.119,
			1031.6,
			1033.076,
			1033.81,
			1035.278,
			1036.013,
			1037.483,
			1038.947,
			1041.14,
			1042.603,
			1044.796,
			1045.527,
			1046.258,
			1048.456,
			1049.922,
			1052.127,
			1053.6,
			1055.075,
			1055.814,
			1057.297,
			1058.039,
			1059.526,
			1061.019,
			1061.767,
			1063.27,
			1064.779,
			1067.057,
			1068.59,
			1070.908,
			1071.686,
			1072.468,
			1074.839,
			1076.436,
			1078.045,
			1080.481,
			1082.121,
			1084.586,
			1085.418,
			1086.254,
			1088.779,
			1090.477,
			1093.049,
			1094.775,
			1096.501,
			1097.36,
			1099.065,
			1100.743,
			1101.57,
			1103.196,
			1103.995,
			1105.567,
			1107.102,
			1109.338,
			1110.785,
			1112.888,
			1113.576,
			1114.259,
			1116.283,
			1117.622,
			1119.629,
			1120.949,
			1122.261,
			1122.916,
			1124.217,
			1124.865,
			1126.158,
			1127.448,
			1128.091,
			1128.732,
			1130.649,
			1131.925,
			1133.202,
			1133.84,
			1135.114,
			1136.388,
			1137.025,
			1138.958,
			1140.253,
			1141.548,
			1143.527,
			1144.897,
			1146.948,
			1147.631,
			1148.345,
			1150.553,
			1152.022,
			1154.286,
			1155.823,
			1157.358,
			1158.125,
			1159.65,
			1160.41,
			1161.927,
			1163.428,
			1164.154,
			1165.6,
			1167.043,
			1168.468,
			1170.524,
			1171.891,
			1173.256,
			1173.923,
			1175.254,
			1175.918,
			1177.247,
			1178.578,
			1180.567,
			1181.894,
			1183.88,
			1184.539,
			1185.196,
			1187.152,
			1187.798,
			1188.441,
			1190.339,
			1191.581,
			1192.801,
			1193.396,
			1194.572,
			1195.146,
			1196.266,
			1197.355,
			1197.889,
			1198.959,
			1200.015,
			1201.563,
			1202.072,
			1202.578,
			1204.139,
			1204.669,
			1205.197,
			1206.251,
			1206.777,
			1207.83,
			1209.559,
			1210.753,
			1211.947,
			1213.793,
			1214.468,
			1215.141,
			1217.843,
			1218.519,
			1220.497,
			1221.819,
			1223.776,
			1224.977,
			1225.579,
			1226.788,
			1228.608,
			1229.746,
			1230.893,
			1232.631,
			1233.781,
			1235.436,
			1235.994,
			1236.554,
			1238.257,
			1239.392,
			1241.111,
			1242.275,
			1243.456,
			1244.077,
			1245.329,
			1245.96,
			1246.595,
			1249.192,
			1249.865,
			1251.905,
			1253.284,
			1254.687,
			1255.394,
			1256.819,
			1258.258,
			1258.981,
			1261.165,
			1262.636,
			1264.116,
			1266.346,
			1267.838,
			1270.115,
			1270.88,
			1271.649,
			1273.977,
			1275.55,
			1277.936,
			1279.545,
			1281.169,
			1281.982,
			1283.606,
			1284.416,
			1286.022,
			1287.619,
			1288.402,
			1289.964,
			1291.512,
			1293.813,
			1295.347,
			1296.872,
			1298.39,
			1299.146,
			1300.65,
			1301.399,
			1302.89,
			1304.374,
			1306.582,
			1308.049,
			1310.248,
			1310.98,
			1311.711,
			1313.903,
			1315.391,
			1317.618,
			1319.11,
			1320.662,
			1321.438,
			1322.989,
			1323.765,
			1325.382,
			1327.005,
			1327.817,
			1329.447,
			1331.092,
			1333.556,
			1334.367,
			1335.168,
			1337.566,
			1338.365,
			1339.158,
			1341.459,
			1342.992,
			1344.516,
			1346.766,
			1348.27,
			1350.536,
			1351.292,
			1352.048,
			1354.326,
			1355.092,
			1355.857,
			1358.154,
			1359.689,
			1361.225,
			1361.991,
			1363.52,
			1364.281,
			1365.79,
			1367.285,
			1368.027,
			1369.483,
			1370.92,
			1373.027,
			1374.381,
			1376.353,
			1376.991,
			1377.621,
			1379.459,
			1380.649,
			1382.366,
			1383.47,
			1384.548,
			1385.079,
			1386.121,
			1386.633,
			1387.14,
			1388.635,
			1389.126,
			1389.614,
			1391.057,
			1392.009,
			1392.955,
			1393.428,
			1394.374,
			1395.324,
			1395.797,
			1396.745,
			1397.219,
			1398.168,
			1399.12,
			1400.59,
			1401.577,
			1403.062,
			1403.558,
			1404.056,
			1405.608,
			1406.651,
			1408.215,
			1409.258,
			1410.874,
			1411.949,
			1412.485,
			1413.553,
			1414.623,
			1415.166,
			1415.706,
			1417.314,
			1418.376,
			1419.968,
			1421.029,
			1422.078,
			1422.6,
			1424.158,
			1425.2,
			1426.236,
			1427.775,
			1428.796,
			1430.33,
			1430.839,
			1431.347,
			1432.864,
			1433.875,
			1435.397,
			1436.409,
			1437.419,
			1437.925,
			1438.939,
			1439.448,
			1440.479,
			1441.51,
			1442.026,
			1443.061,
			1444.102,
			1445.691,
			1446.223,
			1446.757,
			1448.367,
			1448.908,
			1449.453,
			1451.102,
			1452.21,
			1453.328,
			1455.03,
			1456.178,
			1457.918,
			1458.505,
			1459.095,
			1460.892,
			1462.103,
			1463.941,
			1465.188,
			1467.09,
			1468.371,
			1469.015,
			1470.327,
			1471.665,
			1472.338,
			1473.691,
			1475.061,
			1477.16,
			1478.575,
			1480.716,
			1481.434,
			1482.155,
			1484.341,
			1485.806,
			1488.026,
			1489.527,
			1491.028,
			1493.306,
			1494.073,
			1494.844,
			1497.143,
			1497.917,
			1498.695,
			1501.027,
			1502.584,
			1504.157,
			1504.948,
			1506.522,
			1508.097,
			1508.897,
			1510.511,
			1511.326,
			1512.967,
			1514.622,
			1517.158,
			1518.851,
			1521.428,
			1522.299,
			1523.174,
			1525.826,
			1527.624,
			1530.351,
			1532.215,
			1535.014,
			1536.911,
			1537.865,
			1538.822,
			1541.726,
			1542.703,
			1544.668,
			1546.651,
			1548.647,
			1550.653,
			1551.656,
			1553.672,
			1555.694,
			1556.702,
			1558.727,
			1559.744,
			1561.767,
			1563.798,
			1566.849,
			1568.889,
			1571.958,
			1572.983,
			1574.012,
			1577.091,
			1579.167,
			1582.298,
			1584.396,
			1587.541,
			1589.64,
			1590.694,
			1592.807,
			1595.996,
			1598.133,
			1600.281,
			1603.52,
			1605.692,
			1608.968,
			1610.065,
			1611.164,
			1614.475,
			1616.693,
			1618.922,
			1622.282,
			1624.536,
			1627.934,
			1629.071,
			1630.211,
			1633.646,
			1635.949,
			1639.42,
			1641.746,
			1644.081,
			1645.252,
			1647.602,
			1649.961,
			1651.144,
			1654.708,
			1657.095,
			1659.491,
			1663.103,
			1665.522,
			1669.168,
			1670.388,
			1671.611,
			1675.297,
			1677.765,
			1681.482,
			1683.969,
			1686.464,
			1687.713,
			1690.216,
			1691.471,
			1692.728,
			1697.772,
			1699.038,
			1700.75
		],
		[
			0,
			0.024,
			0.058,
			0.086,
			0.164,
			0.209,
			0.271,
			0.335,
			0.379,
			0.458,
			0.49,
			0.518,
			0.581,
			0.643,
			0.707,
			0.768,
			0.814,
			0.83,
			0.891,
			0.923,
			0.955,
			1.016,
			1.031,
			1.079,
			1.14,
			1.203,
			1.263,
			1.326,
			1.358,
			1.389,
			1.451,
			1.513,
			1.575,
			1.636,
			1.683,
			1.761,
			1.791,
			1.823,
			1.885,
			1.899,
			1.946,
			2.01,
			2.071,
			2.12,
			2.131,
			2.193,
			2.226,
			2.256,
			2.317,
			2.333,
			2.381,
			2.442,
			2.503,
			2.552,
			2.627,
			2.66,
			2.69,
			2.767,
			2.814,
			2.879,
			2.939,
			2.984,
			3,
			3.061,
			3.094,
			3.123,
			3.185,
			3.203,
			3.249,
			3.312,
			3.373,
			3.419,
			3.435,
			3.497,
			3.527,
			3.56,
			3.621,
			3.636,
			3.682,
			3.746,
			3.807,
			3.851,
			3.931,
			3.962,
			3.992,
			4.055,
			4.116,
			4.179,
			4.24,
			4.288,
			4.301,
			4.365,
			4.396,
			4.426,
			4.489,
			4.504,
			4.551,
			4.614,
			4.674,
			4.737,
			4.799,
			4.828,
			4.861,
			4.923,
			4.985,
			5.047,
			5.109,
			5.155,
			5.232,
			5.265,
			5.295,
			5.356,
			5.419,
			5.482,
			5.542,
			5.606,
			5.666,
			5.699,
			5.729,
			5.789,
			5.806,
			5.852,
			5.92,
			5.978,
			6.025,
			6.1,
			6.131,
			6.162,
			6.226,
			6.285,
			6.35,
			6.412,
			6.459,
			6.473,
			6.534,
			6.565,
			6.597,
			6.674,
			6.72,
			6.783,
			6.846,
			6.893,
			6.906,
			6.97,
			7.001,
			7.031,
			7.094,
			7.109,
			7.156,
			7.217,
			7.278,
			7.326,
			7.402,
			7.434,
			7.465,
			7.528,
			7.589,
			7.652,
			7.712,
			7.76,
			7.776,
			7.838,
			7.868,
			7.898,
			7.96,
			7.976,
			8.023,
			8.087,
			8.147,
			8.208,
			8.272,
			8.307,
			8.333,
			8.396,
			8.409,
			8.456,
			8.52,
			8.582,
			8.628,
			8.706,
			8.734,
			8.768,
			8.831,
			8.891,
			8.954,
			9.017,
			9.078,
			9.139,
			9.172,
			9.202,
			9.263,
			9.28,
			9.326,
			9.389,
			9.45,
			9.497,
			9.512,
			9.573,
			9.604,
			9.635,
			9.698,
			9.758,
			9.82,
			9.883,
			9.947,
			10.009,
			10.04,
			10.067,
			10.147,
			10.193,
			10.256,
			10.318,
			10.367,
			10.378,
			10.443,
			10.474,
			10.502,
			10.567,
			10.58,
			10.628,
			10.691,
			10.752,
			10.798,
			10.876,
			10.907,
			10.938,
			11.001,
			11.062,
			11.124,
			11.184,
			11.232,
			11.311,
			11.34,
			11.373,
			11.434,
			11.448,
			11.497,
			11.56,
			11.619,
			11.665,
			11.682,
			11.744,
			11.776,
			11.807,
			11.867,
			11.883,
			11.929,
			11.992,
			12.052,
			12.117,
			12.177,
			12.208,
			12.24,
			12.302,
			12.363,
			12.426,
			12.489,
			12.534,
			12.549,
			12.612,
			12.643,
			12.674,
			12.734,
			12.752,
			12.799,
			12.86,
			12.922,
			12.967,
			12.985,
			13.047,
			13.076,
			13.108,
			13.171,
			13.184,
			13.231,
			13.295,
			13.356,
			13.401,
			13.48,
			13.511,
			13.542,
			13.605,
			13.665,
			13.731,
			13.79,
			13.836,
			13.853,
			13.915,
			13.946,
			13.977,
			14.039,
			14.055,
			14.1,
			14.163,
			14.224,
			14.271,
			14.286,
			14.348,
			14.379,
			14.41,
			14.473,
			14.534,
			14.596,
			14.658,
			14.703,
			14.782,
			14.814,
			14.843,
			14.907,
			14.968,
			15.031,
			15.091,
			15.139,
			15.153,
			15.215,
			15.248,
			15.277,
			15.34,
			15.357,
			15.402,
			15.465,
			15.526,
			15.588,
			15.652,
			15.682,
			15.714,
			15.79,
			15.834,
			15.899,
			15.961,
			16.008,
			16.023,
			16.085,
			16.116,
			16.145,
			16.209,
			16.225,
			16.27,
			16.333,
			16.394,
			16.442,
			16.457,
			16.519,
			16.554,
			16.58,
			16.642,
			16.658,
			16.705,
			16.768,
			16.83,
			16.875,
			16.951,
			16.984,
			17.016,
			17.093,
			17.153,
			17.202,
			17.278,
			17.31,
			17.34,
			17.402,
			17.463,
			17.527,
			17.588,
			17.636,
			17.65,
			17.714,
			17.743,
			17.774,
			17.835,
			17.853,
			17.899,
			17.962,
			18.023,
			18.068,
			18.085,
			18.147,
			18.177,
			18.207,
			18.271,
			18.285,
			18.334,
			18.395,
			18.457,
			18.504,
			18.579,
			18.616,
			18.643,
			18.703,
			18.768,
			18.829,
			18.891,
			18.936,
			18.951,
			19.016,
			19.047,
			19.077,
			19.139,
			19.155,
			19.201,
			19.263,
			19.326,
			19.372,
			19.386,
			19.45,
			19.481,
			19.511,
			19.589,
			19.634,
			19.696,
			19.76,
			19.822,
			19.884,
			19.914,
			19.945,
			20.024,
			20.068,
			20.131,
			20.193,
			20.253,
			20.317,
			20.348,
			20.379,
			20.44,
			20.457,
			20.502,
			20.565,
			20.628,
			20.69,
			20.751,
			20.782,
			20.813,
			20.876,
			20.937,
			21,
			21.061,
			21.107,
			21.123,
			21.185,
			21.217,
			21.246,
			21.324,
			21.372,
			21.435,
			21.496,
			21.543,
			21.556,
			21.62,
			21.651,
			21.681,
			21.744,
			21.757,
			21.805,
			21.868,
			21.929,
			21.991,
			22.052,
			22.084,
			22.115,
			22.194,
			22.24,
			22.302,
			22.363,
			22.411,
			22.426,
			22.486,
			22.518,
			22.55,
			22.627,
			22.673,
			22.74,
			22.796,
			22.843,
			22.86,
			22.922,
			22.953,
			22.984,
			23.06,
			23.107,
			23.167,
			23.232,
			23.294,
			23.357,
			23.385,
			23.418,
			23.479,
			23.54,
			23.604,
			23.665,
			23.711,
			23.728,
			23.79,
			23.819,
			23.851,
			23.913,
			23.929,
			23.977,
			24.038,
			24.101,
			24.162,
			24.223,
			24.252,
			24.285,
			24.347,
			24.363,
			24.409,
			24.472,
			24.535,
			24.595,
			24.657,
			24.688,
			24.718,
			24.782,
			24.803,
			24.845,
			24.905,
			24.968,
			25.015,
			25.029,
			25.09,
			25.123,
			25.154,
			25.215,
			25.232,
			25.277,
			25.341,
			25.401,
			25.447,
			25.463,
			25.525,
			25.557,
			25.589,
			25.651,
			25.71,
			25.774,
			25.836,
			25.882,
			25.898,
			25.961,
			25.99,
			26.023,
			26.085,
			26.1,
			26.146,
			26.207,
			26.269,
			26.316,
			26.332,
			26.394,
			26.424,
			26.456,
			26.518,
			26.532,
			26.58,
			26.642,
			26.705,
			26.751,
			26.827,
			26.864,
			26.889,
			26.952,
			27.015,
			27.077,
			27.138,
			27.185,
			27.201,
			27.263,
			27.293,
			27.323,
			27.401,
			27.447,
			27.511,
			27.572,
			27.617,
			27.634,
			27.695,
			27.728,
			27.759,
			27.821,
			27.835,
			27.882,
			27.943,
			28.007,
			28.051,
			28.129,
			28.162,
			28.192,
			28.27,
			28.316,
			28.379,
			28.44,
			28.484,
			28.501,
			28.564,
			28.595,
			28.626,
			28.689,
			28.702,
			28.751,
			28.814,
			28.873,
			28.926,
			28.998,
			29.028,
			29.059,
			29.139,
			29.185,
			29.246,
			29.308,
			29.355,
			29.432,
			29.465,
			29.496,
			29.557,
			29.618,
			29.681,
			29.743,
			29.79,
			29.802,
			29.866,
			29.898,
			29.929,
			29.99,
			30.006,
			30.052,
			30.114,
			30.176,
			30.239,
			30.302,
			30.332,
			30.362,
			30.424,
			30.484,
			30.548,
			30.611,
			30.657,
			30.673,
			30.735,
			30.765,
			30.798,
			30.859,
			30.873,
			30.922,
			30.983,
			31.044,
			31.09,
			31.107,
			31.169,
			31.201,
			31.232,
			31.307,
			31.355,
			31.417,
			31.48,
			31.525,
			31.601,
			31.635,
			31.665,
			31.726,
			31.79,
			31.851,
			31.912,
			31.96,
			31.976,
			32.038,
			32.069,
			32.1,
			32.162,
			32.176,
			32.224,
			32.285,
			32.347,
			32.408,
			32.472,
			32.502,
			32.534,
			32.596,
			32.611,
			32.657,
			32.72,
			32.782,
			32.826,
			32.905,
			32.937,
			32.968,
			33.03,
			33.05,
			33.09,
			33.152,
			33.215,
			33.261,
			33.277,
			33.34,
			33.368,
			33.402,
			33.462,
			33.479,
			33.524,
			33.587,
			33.649,
			33.695,
			33.71,
			33.773,
			33.805,
			33.834,
			33.896,
			33.912,
			33.959,
			34.022,
			34.084,
			34.128,
			34.207,
			34.239,
			34.268,
			34.348,
			34.393,
			34.456,
			34.518,
			34.579,
			34.641,
			34.672,
			34.702,
			34.782,
			34.826,
			34.89,
			34.951,
			34.999,
			35.075,
			35.113,
			35.138,
			35.199,
			35.26,
			35.324,
			35.384,
			35.431,
			35.449,
			35.511,
			35.54,
			35.57,
			35.649,
			35.696,
			35.761,
			35.819,
			35.866,
			35.881,
			35.944,
			35.976,
			36.006,
			36.084,
			36.129,
			36.193,
			36.254,
			36.301,
			36.378,
			36.408,
			36.44,
			36.502,
			36.564,
			36.626,
			36.688,
			36.735,
			36.749,
			36.811,
			36.842,
			36.873,
			36.936,
			36.951,
			36.999,
			37.06,
			37.123,
			37.185,
			37.245,
			37.276,
			37.308,
			37.371,
			37.385,
			37.433,
			37.493,
			37.557,
			37.6,
			37.679,
			37.712,
			37.741,
			37.804,
			37.867,
			37.929,
			37.991,
			38.037,
			38.053,
			38.115,
			38.145,
			38.176,
			38.239,
			38.252,
			38.3,
			38.362,
			38.424,
			38.469,
			38.485,
			38.549,
			38.578,
			38.61,
			38.673,
			38.734,
			38.795,
			38.859,
			38.905,
			38.982,
			39.014,
			39.043,
			39.106,
			39.123,
			39.167,
			39.233,
			39.292,
			39.339,
			39.353,
			39.417,
			39.446,
			39.479,
			39.54,
			39.557,
			39.602,
			39.665,
			39.725,
			39.773,
			39.85,
			39.881,
			39.911,
			39.974,
			40.035,
			40.1,
			40.16,
			40.207,
			40.223,
			40.285,
			40.317,
			40.346,
			40.409,
			40.423,
			40.469,
			40.533,
			40.596,
			40.64,
			40.656,
			40.718,
			40.749,
			40.78,
			40.841,
			40.858,
			40.905,
			40.965,
			41.028,
			41.09,
			41.152,
			41.183,
			41.214,
			41.297,
			41.339,
			41.402,
			41.463,
			41.523,
			41.585,
			41.616,
			41.65,
			41.71,
			41.726,
			41.772,
			41.835,
			41.895,
			41.959,
			42.018,
			42.051,
			42.083,
			42.16,
			42.207,
			42.269,
			42.329,
			42.393,
			42.456,
			42.485,
			42.516,
			42.577,
			42.639,
			42.701,
			42.766,
			42.81,
			42.826,
			42.89,
			42.917,
			42.952,
			43.013,
			43.03,
			43.074,
			43.138,
			43.199,
			43.246,
			43.26,
			43.324,
			43.359,
			43.384,
			43.448,
			43.508,
			43.571,
			43.633,
			43.68,
			43.758,
			43.789,
			43.819,
			43.88,
			43.944,
			44.007,
			44.068,
			44.13,
			44.192,
			44.222,
			44.251,
			44.314,
			44.33,
			44.376,
			44.44,
			44.501,
			44.549,
			44.626,
			44.657,
			44.686,
			44.749,
			44.81,
			44.874,
			44.936,
			44.997,
			45.06,
			45.09,
			45.121,
			45.182,
			45.199,
			45.245,
			45.307,
			45.368,
			45.42,
			45.432,
			45.493,
			45.524,
			45.556,
			45.617,
			45.632,
			45.68,
			45.741,
			45.802,
			45.851,
			45.929,
			45.957,
			45.99,
			46.052,
			46.115,
			46.175,
			46.239,
			46.299,
			46.363,
			46.394,
			46.422,
			46.485,
			46.501,
			46.548,
			46.61,
			46.672,
			46.719,
			46.735,
			46.797,
			46.828,
			46.857,
			46.919,
			46.935,
			46.982,
			47.043,
			47.105,
			47.151,
			47.231,
			47.261,
			47.292,
			47.368,
			47.415,
			47.482,
			47.541,
			47.6,
			47.663,
			47.693,
			47.725,
			47.803,
			47.849,
			47.913,
			47.975,
			48.036,
			48.098,
			48.129,
			48.158,
			48.223,
			48.285,
			48.346,
			48.407,
			48.469,
			48.532,
			48.562,
			48.595,
			48.656,
			48.717,
			48.78,
			48.841,
			48.888,
			48.902,
			48.967,
			48.998,
			49.029,
			49.105,
			49.151,
			49.219,
			49.275,
			49.323,
			49.4,
			49.432,
			49.461,
			49.524,
			49.584,
			49.649,
			49.711,
			49.758,
			49.772,
			49.834,
			49.865,
			49.896,
			49.974,
			50.02,
			50.047
		]
	],
	'6053_6056_7982': [
		[
			36.799
		],
		[
			0
		],
		[
			0,
			1.949,
			3.135,
			5.516,
			6.709,
			9.103,
			11.506,
			15.126,
			16.337,
			17.548,
			21.192,
			22.408,
			23.627,
			27.298,
			29.761,
			33.477,
			35.967,
			38.466,
			39.719,
			42.23,
			43.489,
			44.75,
			49.815,
			51.086,
			54.912,
			57.479,
			60.063,
			61.359,
			63.965,
			66.578,
			67.876,
			70.477,
			71.777,
			74.356,
			76.935,
			80.809,
			83.421,
			87.373,
			88.706,
			90.042,
			94.051,
			96.728,
			100.733,
			103.41,
			106.092,
			107.436,
			110.128,
			111.476,
			112.827,
			116.888,
			118.245,
			120.964,
			123.69,
			126.423,
			129.161,
			130.531,
			133.267,
			135.987,
			137.337,
			140.013,
			141.337,
			143.955,
			146.536,
			150.336,
			152.824,
			156.49,
			157.691,
			158.88,
			162.396,
			164.699,
			168.087,
			170.303,
			172.479,
			173.548,
			175.66,
			176.701,
			178.758,
			181.774,
			183.739,
			185.668,
			187.558,
			189.41,
			190.322,
			192.122,
			193.889,
			194.76,
			197.316,
			198.973,
			200.592,
			202.952,
			204.48,
			206.703,
			207.426,
			208.14,
			210.228,
			211.577,
			213.54,
			214.808,
			216.64,
			217.83,
			218.408,
			219.536,
			220.629,
			221.164,
			222.214,
			223.231,
			224.712,
			225.679,
			227.11,
			227.584,
			228.055,
			229.458,
			230.393,
			231.8,
			232.748,
			233.705,
			234.184,
			235.145,
			235.626,
			236.109,
			237.572,
			238.117,
			238.66,
			240.281,
			241.363,
			242.45,
			243.061,
			244.292,
			245.521,
			246.136,
			247.368,
			248.027,
			249.365,
			250.699,
			252.7,
			254.034,
			256.021,
			256.681,
			257.342,
			259.249,
			260.499,
			262.365,
			263.569,
			265.36,
			266.557,
			267.158,
			267.747,
			269.951,
			270.5,
			272.152,
			273.245,
			274.331,
			274.876,
			275.971,
			277.074,
			277.628,
			278.738,
			279.295,
			280.416,
			281.546,
			283.263,
			284.42,
			286.177,
			286.768,
			287.364,
			289.17,
			290.39,
			292.245,
			293.509,
			294.79,
			295.436,
			296.741,
			297.4,
			298.721,
			300.056,
			300.73,
			302.089,
			303.463,
			305.552,
			306.257,
			306.966,
			308.39,
			309.852,
			310.589,
			312.076,
			312.825,
			314.344,
			315.88,
			318.212,
			319.784,
			322.172,
			322.974,
			323.776,
			326.205,
			327.842,
			330.332,
			332.004,
			334.522,
			336.217,
			337.927,
			338.79,
			340.526,
			341.4,
			343.157,
			344.929,
			347.613,
			349.415,
			352.141,
			353.057,
			353.974,
			356.747,
			358.612,
			360.49,
			363.332,
			366.204,
			368.134,
			369.104,
			370.078,
			373.016,
			374.003,
			374.994,
			377.984,
			379.993,
			382.014,
			383.029,
			385.066,
			387.114,
			388.143,
			390.208,
			391.245,
			393.327,
			395.42,
			398.567,
			400.668,
			403.835,
			404.896,
			405.96,
			409.166,
			411.316,
			414.56,
			416.736,
			420.019,
			422.221,
			423.325,
			425.543,
			427.77,
			428.887,
			431.13,
			433.382,
			435.645,
			439.059,
			441.347,
			443.644,
			444.797,
			447.11,
			448.27,
			450.598,
			452.936,
			456.457,
			458.816,
			462.367,
			463.555,
			464.747,
			468.338,
			470.747,
			474.381,
			476.815,
			479.259,
			480.484,
			482.941,
			484.174,
			486.644,
			489.124,
			490.367,
			492.859,
			495.359,
			497.866,
			500.381,
			501.641,
			504.168,
			506.701,
			507.97,
			510.517,
			511.793,
			514.352,
			518.191,
			520.759,
			523.333,
			527.197,
			528.485,
			529.776,
			533.673,
			536.35,
			540.47,
			543.336,
			546.176,
			547.588,
			550.372,
			551.715,
			554.398,
			558.31,
			560.842,
			563.318,
			567.01,
			568.231,
			569.424,
			573.145,
			574.378,
			575.676,
			578.3,
			579.61,
			582.565,
			585.522,
			589.822,
			592.251,
			595.792,
			596.927,
			598.059,
			601.451,
			603.701,
			607.113,
			609.373,
			611.707,
			612.871,
			615.164,
			617.384,
			618.48,
			620.65,
			621.727,
			623.853,
			625.991,
			629.192,
			631.186,
			634.039,
			634.97,
			635.853,
			638.456,
			640.148,
			642.626,
			644.232,
			645.809,
			648.115,
			648.865,
			649.607,
			651.766,
			652.467,
			653.161,
			655.206,
			656.555,
			657.896,
			658.565,
			659.913,
			661.253,
			661.921,
			663.249,
			663.911,
			665.23,
			666.544,
			668.5,
			669.795,
			671.724,
			672.363,
			673.001,
			674.908,
			676.187,
			678.095,
			679.364,
			680.64,
			681.281,
			682.558,
			683.834,
			684.473,
			685.753,
			686.395,
			687.035,
			688.96,
			690.913,
			691.576,
			692.239,
			693.569,
			694.904,
			695.592,
			697.012,
			697.723,
			699.148,
			700.592,
			702.826,
			704.323,
			706.568,
			707.315,
			708.063,
			710.315,
			711.79,
			714.002,
			715.482,
			717.73,
			719.225,
			719.973,
			721.483,
			722.993,
			723.747,
			725.252,
			726.766,
			729.027,
			730.531,
			732.835,
			733.601,
			734.367,
			736.646,
			738.151,
			739.655,
			741.866,
			743.306,
			745.508,
			746.241,
			746.977,
			749.198,
			749.944,
			750.693,
			752.96,
			754.486,
			756.015,
			756.768,
			758.285,
			759.049,
			760.587,
			762.118,
			762.888,
			764.44,
			766.007,
			768.381,
			769.979,
			772.424,
			773.246,
			774.072,
			776.567,
			778.238,
			780.741,
			782.419,
			784.103,
			784.948,
			786.651,
			787.508,
			788.368,
			791.808,
			792.676,
			795.276,
			796.993,
			799.54,
			801.22,
			802.878,
			803.697,
			806.1,
			807.656,
			809.176,
			809.922,
			811.387,
			812.817,
			814.897,
			815.577,
			816.252,
			818.236,
			819.526,
			821.439,
			822.707,
			823.981,
			824.631,
			825.917,
			826.562,
			827.85,
			829.136,
			829.778,
			831.059,
			832.333,
			834.234,
			834.865,
			835.494,
			836.741,
			837.98,
			838.596,
			840.437,
			841.67,
			842.892,
			844.71,
			845.925,
			847.74,
			848.34,
			848.938,
			850.733,
			851.939,
			853.731,
			854.919,
			856.137,
			856.749,
			857.967,
			858.574,
			859.787,
			861.024,
			861.649,
			862.894,
			864.136,
			866.021,
			866.657,
			867.293,
			869.2,
			869.837,
			870.478,
			872.417,
			873.711,
			875.647,
			876.896,
			878.147,
			880.036,
			880.658,
			881.271,
			883.122,
			884.369,
			886.26,
			887.53,
			889.432,
			890.707,
			891.97,
			892.603,
			893.828,
			894.43,
			895.617,
			896.788,
			898.506,
			899.633,
			901.296,
			901.842,
			902.387,
			904.016,
			905.1,
			906.748,
			907.846,
			909.487,
			910.592,
			912.398,
			913.598,
			914.197,
			914.796,
			916.781,
			918.179,
			919.577,
			920.276,
			921.774,
			923.283,
			924.036,
			925.538,
			926.195,
			927.5,
			928.796,
			930.706,
			931.811,
			933.452,
			933.996,
			934.539,
			936.144,
			937.18,
			938.719,
			939.741,
			940.77,
			941.295,
			942.345,
			942.871,
			943.924,
			945.506,
			946.575,
			947.647,
			949.262,
			949.802,
			950.344,
			951.418,
			952.493,
			953.033,
			954.117,
			954.663,
			955.761,
			956.877,
			958.575,
			959.726,
			961.507,
			962.11,
			962.726,
			964.6,
			965.868,
			967.794,
			969.098,
			970.418,
			971.081,
			972.411,
			973.081,
			974.435,
			975.805,
			976.497,
			977.894,
			979.306,
			981.462,
			982.194,
			982.931,
			985.164,
			985.916,
			986.672,
			988.964,
			990.511,
			992.835,
			994.399,
			995.978,
			998.366,
			999.17,
			999.977,
			1002.429,
			1004.086,
			1006.598,
			1008.289,
			1010.825,
			1012.546,
			1013.413,
			1015.156,
			1017.801,
			1019.58,
			1021.361,
			1023.992,
			1025.726,
			1028.273,
			1029.106,
			1029.93,
			1032.338,
			1033.9,
			1036.216,
			1037.724,
			1039.221,
			1039.967,
			1041.454,
			1042.2,
			1042.944,
			1045.17,
			1045.911,
			1046.65,
			1048.861,
			1050.331,
			1051.795,
			1052.519,
			1053.964,
			1055.407,
			1056.129,
			1057.589,
			1058.319,
			1059.773,
			1061.224,
			1063.429,
			1064.891,
			1067.1,
			1067.851,
			1068.599,
			1070.839,
			1072.369,
			1074.689,
			1076.233,
			1078.621,
			1080.214,
			1081.009,
			1082.598,
			1084.183,
			1084.962,
			1086.543,
			1088.132,
			1090.506,
			1092.088,
			1093.661,
			1095.242,
			1096.036,
			1098.361,
			1099.912,
			1101.474,
			1103.777,
			1105.325,
			1107.659,
			1108.438,
			1109.219,
			1111.576,
			1113.15,
			1115.524,
			1117.126,
			1118.752,
			1119.566,
			1121.201,
			1122.027,
			1123.693,
			1125.365,
			1126.203,
			1127.895,
			1129.594,
			1132.139,
			1132.996,
			1133.855,
			1136.451,
			1137.322,
			1138.197,
			1140.841,
			1142.62,
			1145.312,
			1147.125,
			1148.958,
			1151.73,
			1152.663,
			1153.601,
			1157.377,
			1158.326,
			1161.191,
			1163.112,
			1165.043,
			1166.013,
			1167.962,
			1169.922,
			1170.907,
			1172.885,
			1173.879,
			1175.876,
			1177.884,
			1180.916,
			1181.933,
			1182.952,
			1186.026,
			1187.057,
			1188.089,
			1191.204,
			1193.293,
			1196.435,
			1198.53,
			1200.633,
			1201.689,
			1203.806,
			1204.868,
			1207,
			1210.2,
			1211.271,
			1214.497,
			1216.66,
			1219.938,
			1222.137,
			1224.349,
			1225.458,
			1227.685,
			1228.802,
			1231.043,
			1233.297,
			1236.703,
			1238.996,
			1242.456,
			1243.61,
			1244.766,
			1248.243,
			1250.563,
			1254.062,
			1256.406,
			1257.929
		],
		[
			0,
			0.023,
			0.053,
			0.116,
			0.131,
			0.177,
			0.24,
			0.301,
			0.348,
			0.364,
			0.424,
			0.457,
			0.487,
			0.549,
			0.61,
			0.675,
			0.735,
			0.782,
			0.799,
			0.86,
			0.891,
			0.922,
			1,
			1.045,
			1.106,
			1.168,
			1.217,
			1.233,
			1.295,
			1.331,
			1.356,
			1.416,
			1.433,
			1.478,
			1.54,
			1.604,
			1.666,
			1.727,
			1.758,
			1.79,
			1.852,
			1.911,
			1.976,
			2.036,
			2.085,
			2.098,
			2.162,
			2.193,
			2.223,
			2.287,
			2.3,
			2.349,
			2.41,
			2.471,
			2.518,
			2.534,
			2.597,
			2.628,
			2.657,
			2.72,
			2.736,
			2.782,
			2.842,
			2.906,
			2.968,
			3.03,
			3.06,
			3.091,
			3.154,
			3.216,
			3.278,
			3.341,
			3.391,
			3.402,
			3.462,
			3.496,
			3.527,
			3.604,
			3.651,
			3.713,
			3.773,
			3.82,
			3.837,
			3.895,
			3.929,
			3.961,
			4.038,
			4.085,
			4.147,
			4.207,
			4.254,
			4.331,
			4.364,
			4.393,
			4.455,
			4.519,
			4.581,
			4.643,
			4.703,
			4.765,
			4.797,
			4.829,
			4.891,
			4.907,
			4.951,
			5.014,
			5.076,
			5.123,
			5.199,
			5.232,
			5.261,
			5.325,
			5.386,
			5.452,
			5.509,
			5.557,
			5.573,
			5.634,
			5.666,
			5.694,
			5.758,
			5.773,
			5.819,
			5.882,
			5.944,
			5.989,
			6.007,
			6.067,
			6.099,
			6.131,
			6.192,
			6.208,
			6.255,
			6.315,
			6.377,
			6.425,
			6.504,
			6.533,
			6.565,
			6.627,
			6.688,
			6.749,
			6.811,
			6.875,
			6.938,
			6.968,
			6.998,
			7.076,
			7.122,
			7.184,
			7.244,
			7.293,
			7.307,
			7.371,
			7.4,
			7.431,
			7.496,
			7.516,
			7.556,
			7.62,
			7.682,
			7.728,
			7.804,
			7.834,
			7.867,
			7.928,
			7.991,
			8.054,
			8.116,
			8.161,
			8.177,
			8.238,
			8.269,
			8.3,
			8.363,
			8.377,
			8.425,
			8.486,
			8.548,
			8.594,
			8.612,
			8.673,
			8.703,
			8.734,
			8.795,
			8.81,
			8.859,
			8.922,
			8.983,
			9.03,
			9.107,
			9.137,
			9.17,
			9.247,
			9.293,
			9.354,
			9.416,
			9.477,
			9.54,
			9.576,
			9.602,
			9.666,
			9.681,
			9.727,
			9.789,
			9.85,
			9.913,
			9.975,
			10.007,
			10.037,
			10.099,
			10.162,
			10.223,
			10.284,
			10.348,
			10.41,
			10.442,
			10.471,
			10.534,
			10.549,
			10.597,
			10.656,
			10.718,
			10.765,
			10.781,
			10.844,
			10.875,
			10.904,
			10.968,
			10.983,
			11.028,
			11.091,
			11.154,
			11.215,
			11.277,
			11.308,
			11.338,
			11.416,
			11.464,
			11.525,
			11.587,
			11.65,
			11.71,
			11.742,
			11.774,
			11.835,
			11.85,
			11.899,
			11.959,
			12.023,
			12.082,
			12.144,
			12.177,
			12.206,
			12.271,
			12.286,
			12.332,
			12.393,
			12.455,
			12.501,
			12.581,
			12.611,
			12.641,
			12.704,
			12.766,
			12.827,
			12.891,
			12.936,
			12.951,
			13.012,
			13.044,
			13.076,
			13.136,
			13.154,
			13.198,
			13.261,
			13.323,
			13.37,
			13.385,
			13.448,
			13.477,
			13.51,
			13.573,
			13.586,
			13.634,
			13.7,
			13.757,
			13.804,
			13.883,
			13.91,
			13.944,
			14.007,
			14.069,
			14.131,
			14.191,
			14.238,
			14.254,
			14.315,
			14.346,
			14.377,
			14.454,
			14.5,
			14.567,
			14.625,
			14.673,
			14.688,
			14.751,
			14.782,
			14.811,
			14.873,
			14.89,
			14.935,
			14.998,
			15.06,
			15.105,
			15.183,
			15.215,
			15.244,
			15.323,
			15.37,
			15.431,
			15.494,
			15.541,
			15.557,
			15.619,
			15.65,
			15.681,
			15.742,
			15.762,
			15.804,
			15.867,
			15.928,
			15.974,
			16.053,
			16.082,
			16.115,
			16.176,
			16.239,
			16.3,
			16.361,
			16.408,
			16.486,
			16.518,
			16.547,
			16.609,
			16.625,
			16.673,
			16.735,
			16.796,
			16.842,
			16.857,
			16.92,
			16.95,
			16.983,
			17.045,
			17.061,
			17.106,
			17.169,
			17.231,
			17.276,
			17.354,
			17.387,
			17.416,
			17.479,
			17.541,
			17.603,
			17.664,
			17.712,
			17.728,
			17.788,
			17.825,
			17.85,
			17.912,
			17.928,
			17.973,
			18.036,
			18.099,
			18.145,
			18.16,
			18.223,
			18.253,
			18.286,
			18.346,
			18.362,
			18.408,
			18.47,
			18.532,
			18.579,
			18.657,
			18.687,
			18.72,
			18.781,
			18.843,
			18.905,
			18.967,
			19.029,
			19.091,
			19.121,
			19.152,
			19.215,
			19.23,
			19.276,
			19.338,
			19.402,
			19.463,
			19.525,
			19.556,
			19.586,
			19.665,
			19.709,
			19.772,
			19.835,
			19.888,
			19.957,
			19.989,
			20.021,
			20.083,
			20.099,
			20.145,
			20.206,
			20.268,
			20.315,
			20.332,
			20.391,
			20.423,
			20.454,
			20.516,
			20.532,
			20.581,
			20.642,
			20.703,
			20.75,
			20.828,
			20.857,
			20.89,
			20.952,
			21.014,
			21.076,
			21.138,
			21.183,
			21.199,
			21.262,
			21.292,
			21.323,
			21.399,
			21.448,
			21.509,
			21.571,
			21.633,
			21.695,
			21.727,
			21.758,
			21.835,
			21.882,
			21.944,
			21.95,
			22.006,
			22.052,
			22.13,
			22.16,
			22.192,
			22.253,
			22.316,
			22.378,
			22.44,
			22.485,
			22.502,
			22.565,
			22.594,
			22.625,
			22.688,
			22.701,
			22.75,
			22.809,
			22.873,
			22.92,
			22.937,
			22.997,
			23.028,
			23.059,
			23.136,
			23.184,
			23.245,
			23.307,
			23.369,
			23.433,
			23.461,
			23.494,
			23.555,
			23.617,
			23.678,
			23.741,
			23.788,
			23.805,
			23.864,
			23.895,
			23.927,
			23.989,
			24.01,
			24.05,
			24.115,
			24.174,
			24.221,
			24.237,
			24.298,
			24.33,
			24.361,
			24.438,
			24.486,
			24.549,
			24.608,
			24.655,
			24.733,
			24.765,
			24.796,
			24.858,
			24.92,
			24.981,
			25.045,
			25.104,
			25.169,
			25.2,
			25.227,
			25.291,
			25.306,
			25.352,
			25.415,
			25.477,
			25.524,
			25.601,
			25.632,
			25.665,
			25.725,
			25.788,
			25.85,
			25.913,
			25.974,
			26.036,
			26.099,
			26.161,
			26.175,
			26.222,
			26.286,
			26.346,
			26.391,
			26.407,
			26.471,
			26.501,
			26.531,
			26.594,
			26.611,
			26.655,
			26.718,
			26.781,
			26.827,
			26.904,
			26.934,
			26.966,
			27.043,
			27.091,
			27.152,
			27.215,
			27.261,
			27.276,
			27.338,
			27.368,
			27.401,
			27.478,
			27.523,
			27.59,
			27.648,
			27.694,
			27.711,
			27.771,
			27.803,
			27.835,
			27.897,
			27.911,
			27.958,
			28.019,
			28.083,
			28.143,
			28.206,
			28.238,
			28.268,
			28.33,
			28.392,
			28.456,
			28.516,
			28.564,
			28.578,
			28.64,
			28.671,
			28.703,
			28.764,
			28.78,
			28.826,
			28.889,
			28.951,
			28.995,
			29.014,
			29.076,
			29.107,
			29.135,
			29.199,
			29.259,
			29.324,
			29.385,
			29.432,
			29.51,
			29.54,
			29.572,
			29.648,
			29.695,
			29.761,
			29.818,
			29.88,
			29.941,
			29.972,
			30.005,
			30.083,
			30.127,
			30.194,
			30.251,
			30.3,
			30.377,
			30.407,
			30.439,
			30.501,
			30.563,
			30.625,
			30.688,
			30.732,
			30.749,
			30.81,
			30.842,
			30.872,
			30.936,
			30.951,
			30.998,
			31.057,
			31.122,
			31.167,
			31.182,
			31.243,
			31.275,
			31.305,
			31.369,
			31.385,
			31.431,
			31.494,
			31.553,
			31.602,
			31.678,
			31.709,
			31.741,
			31.802,
			31.865,
			31.927,
			31.988,
			32.05,
			32.111,
			32.145,
			32.176,
			32.238,
			32.256,
			32.299,
			32.362,
			32.424,
			32.486,
			32.548,
			32.576,
			32.609,
			32.686,
			32.733,
			32.795,
			32.856,
			32.903,
			32.982,
			33.011,
			33.044,
			33.106,
			33.167,
			33.23,
			33.291,
			33.336,
			33.354,
			33.416,
			33.447,
			33.476,
			33.538,
			33.554,
			33.601,
			33.661,
			33.725,
			33.77,
			33.788,
			33.849,
			33.881,
			33.91,
			33.987,
			34.034,
			34.098,
			34.16,
			34.205,
			34.282,
			34.318,
			34.345,
			34.423,
			34.469,
			34.533,
			34.594,
			34.639,
			34.654,
			34.717,
			34.75,
			34.777,
			34.842,
			34.858,
			34.904,
			34.966,
			35.027,
			35.073,
			35.089,
			35.15,
			35.183,
			35.214,
			35.274,
			35.337,
			35.401,
			35.462,
			35.507,
			35.525,
			35.585,
			35.615,
			35.648,
			35.726,
			35.773,
			35.834,
			35.896,
			35.958,
			36.019,
			36.05,
			36.081,
			36.144,
			36.158,
			36.207,
			36.268,
			36.331,
			36.391,
			36.454,
			36.486,
			36.516,
			36.578,
			36.639,
			36.701,
			36.764,
			36.799
		]
	],
	'1840_2012_7982': [
		[
			50.617
		],
		[
			0
		],
		[
			0,
			0.77,
			4.07,
			9.03,
			10.686,
			12.343,
			17.321,
			20.646,
			25.642,
			28.979,
			32.321,
			33.993,
			37.342,
			39.018,
			42.373,
			45.734,
			47.417,
			49.101,
			54.159,
			57.537,
			60.92,
			62.614,
			66.005,
			69.404,
			71.106,
			74.514,
			76.22,
			79.636,
			83.055,
			88.191,
			91.619,
			96.763,
			98.479,
			100.194,
			105.346,
			108.785,
			113.95,
			117.4,
			120.855,
			122.584,
			126.046,
			127.779,
			131.25,
			134.726,
			136.465,
			139.948,
			143.435,
			148.673,
			150.422,
			152.172,
			155.675,
			159.182,
			160.937,
			164.45,
			166.208,
			169.728,
			173.252,
			178.546,
			182.08,
			187.389,
			189.16,
			190.933,
			196.258,
			199.813,
			205.153,
			208.718,
			212.287,
			214.073,
			217.647,
			219.436,
			223.017,
			226.601,
			228.395,
			231.986,
			235.579,
			240.97,
			242.764,
			244.554,
			249.888,
			251.651,
			253.405,
			258.608,
			262.029,
			265.412,
			270.414,
			273.701,
			278.56,
			280.16,
			281.751,
			286.466,
			288.019,
			289.562,
			294.134,
			297.138,
			300.106,
			301.577,
			304.492,
			305.936,
			308.796,
			313.018,
			315.789,
			318.524,
			322.564,
			325.239,
			329.154,
			330.443,
			331.724,
			335.494,
			337.978,
			341.633,
			344.022,
			347.538,
			349.845,
			350.986,
			352.117,
			355.483,
			356.59,
			357.687,
			360.91,
			363.017,
			366.16,
			368.216,
			370.268,
			371.288,
			373.318,
			374.333,
			376.354,
			378.369,
			381.381,
			383.389,
			386.439,
			387.458,
			388.478,
			391.549,
			393.604,
			396.702,
			398.774,
			400.845,
			401.882,
			403.959,
			404.998,
			407.078,
			409.161,
			410.207,
			412.297,
			414.386,
			416.47,
			418.558,
			419.603,
			421.695,
			423.787,
			424.835,
			426.932,
			427.982,
			430.094,
			432.227,
			435.436,
			437.567,
			440.788,
			441.857,
			442.927,
			446.13,
			448.233,
			451.333,
			453.383,
			456.403,
			458.373,
			459.345,
			461.278,
			464.1,
			465.96,
			467.803,
			470.546,
			471.459,
			472.37,
			475.099,
			476.007,
			476.913,
			479.626,
			481.425,
			484.116,
			485.906,
			487.692,
			490.372,
			491.27,
			492.167,
			494.862,
			496.67,
			499.387,
			501.216,
			503.058,
			503.981,
			505.839,
			507.726,
			508.671,
			510.57,
			511.535,
			513.47,
			515.41,
			518.322,
			520.268,
			523.176,
			524.146,
			525.116,
			528.071,
			530.044,
			533.016,
			534.997,
			536.976,
			539.936,
			540.925,
			541.923,
			544.911,
			545.906,
			546.901,
			549.878,
			551.865,
			553.852,
			554.843,
			556.85,
			558.876,
			559.885,
			561.899,
			562.901,
			564.898,
			566.891,
			569.868,
			571.854,
			574.834,
			575.824,
			576.809,
			579.745,
			581.698,
			584.624,
			586.568,
			589.459,
			591.378,
			592.331,
			594.229,
			596.119,
			597.055,
			598.88,
			600.697,
			603.372,
			604.247,
			605.121,
			606.863,
			608.59,
			609.449,
			611.164,
			612.02,
			613.72,
			615.43,
			618.012,
			619.736,
			622.331,
			623.195,
			624.059,
			626.657,
			628.396,
			631.001,
			632.761,
			634.564,
			635.462,
			637.269,
			638.184,
			640.045,
			641.918,
			642.859,
			644.742,
			646.637,
			649.494,
			650.449,
			651.392,
			654.292,
			655.267,
			656.245,
			659.19,
			661.16,
			663.141,
			664.134,
			666.126,
			668.13,
			671.173,
			672.192,
			673.215,
			676.31,
			678.386,
			681.52,
			683.617,
			685.709,
			686.756,
			688.858,
			690.972,
			692.032,
			694.161,
			695.229,
			697.374,
			699.529,
			702.779,
			704.958,
			708.244,
			709.345,
			710.447,
			713.77,
			715.997,
			719.355,
			721.606,
			723.866,
			725,
			727.274,
			728.415,
			729.558,
			734.156,
			735.311,
			738.792,
			741.125,
			743.467,
			744.64,
			746.99,
			749.357,
			750.528,
			752.86,
			754.019,
			756.319,
			758.592,
			761.939,
			764.127,
			767.346,
			768.405,
			769.46,
			772.604,
			774.686,
			777.806,
			779.884,
			781.971,
			783.019,
			785.116,
			786.165,
			787.226,
			790.406,
			791.471,
			793.633,
			795.792,
			799.078,
			800.174,
			801.269,
			803.484,
			805.696,
			806.804,
			809.023,
			810.132,
			812.352,
			814.575,
			817.892,
			820.089,
			823.34,
			824.413,
			825.486,
			828.69,
			830.822,
			834.044,
			836.199,
			838.365,
			839.449,
			841.621,
			843.799,
			844.89,
			847.073,
			848.16,
			850.322,
			852.495,
			855.734,
			857.884,
			861.086,
			862.145,
			863.194,
			866.315,
			868.346,
			870.351,
			873.322,
			875.314,
			878.322,
			879.347,
			880.358,
			883.414,
			884.439,
			885.465,
			888.562,
			890.638,
			892.723,
			893.769,
			895.86,
			896.904,
			899.014,
			901.117,
			902.168,
			904.285,
			906.39,
			909.582,
			910.646,
			911.709,
			914.977,
			916.068,
			917.156,
			920.426,
			922.577,
			925.694,
			927.725,
			930.66,
			932.6,
			933.557,
			934.513,
			937.37,
			938.334,
			939.296,
			942.178,
			944.137,
			947.073,
			949.052,
			951.033,
			952.023,
			953.987,
			954.966,
			956.924,
			958.845,
			961.707,
			963.604,
			966.433,
			967.38,
			968.327,
			971.177,
			973.09,
			975.982,
			977.922,
			979.869,
			980.846,
			982.807,
			983.792,
			985.769,
			987.754,
			988.753,
			990.757,
			992.753,
			994.777,
			996.809,
			997.828,
			999.872,
			1001.924,
			1002.953,
			1006.049,
			1008.124,
			1010.204,
			1013.342,
			1015.444,
			1018.608,
			1019.662,
			1020.732,
			1023.881,
			1025.974,
			1029.1,
			1031.177,
			1033.247,
			1034.282,
			1036.35,
			1037.38,
			1039.443,
			1041.509,
			1042.538,
			1044.6,
			1046.662,
			1049.739,
			1051.778,
			1054.824,
			1055.839,
			1056.85,
			1059.883,
			1061.91,
			1063.93,
			1066.986,
			1069.016,
			1072.053,
			1073.063,
			1074.072,
			1077.085,
			1079.091,
			1082.076,
			1084.063,
			1087.053,
			1089.046,
			1091.056,
			1092.061,
			1094.072,
			1095.094,
			1097.149,
			1099.202,
			1102.366,
			1104.478,
			1107.664,
			1108.725,
			1109.778,
			1112.882,
			1114.936,
			1117.949,
			1119.948,
			1121.915,
			1124.861,
			1125.83,
			1126.799,
			1129.71,
			1130.681,
			1131.652,
			1134.573,
			1136.528,
			1138.488,
			1139.471,
			1141.435,
			1143.406,
			1144.394,
			1146.373,
			1147.365,
			1149.355,
			1151.335,
			1154.344,
			1156.358,
			1159.39,
			1160.401,
			1161.41,
			1164.423,
			1166.425,
			1169.354,
			1171.268,
			1173.146,
			1174.072,
			1175.901,
			1176.807,
			1178.603,
			1180.387,
			1181.279,
			1182.17,
			1184.839,
			1186.628,
			1188.419,
			1189.315,
			1191.106,
			1192.897,
			1193.792,
			1195.581,
			1196.477,
			1198.266,
			1200.051,
			1202.729,
			1204.506,
			1207.173,
			1208.06,
			1208.946,
			1211.605,
			1213.384,
			1216.055,
			1217.851,
			1220.549,
			1222.378,
			1223.294,
			1225.126,
			1227.903,
			1229.756,
			1231.601,
			1234.354,
			1235.27,
			1236.179,
			1238.873,
			1239.768,
			1240.661,
			1243.331,
			1245.1,
			1246.872,
			1249.526,
			1251.29,
			1253.926,
			1254.803,
			1255.679,
			1258.285,
			1260.021,
			1262.633,
			1264.379,
			1266.13,
			1267.015,
			1268.787,
			1270.561,
			1271.453,
			1274.142,
			1275.94,
			1277.746,
			1280.459,
			1281.361,
			1282.255,
			1284.935,
			1285.828,
			1286.72,
			1289.387,
			1291.165,
			1293.854,
			1295.65,
			1297.457,
			1298.363,
			1300.179,
			1301.089,
			1301.996,
			1304.717,
			1305.627,
			1306.536,
			1309.24,
			1311.05,
			1313.752,
			1315.555,
			1316.459,
			1318.258,
			1320.962,
			1322.765,
			1324.569,
			1327.286,
			1329.101,
			1331.841,
			1332.756,
			1333.674,
			1336.441,
			1338.302,
			1341.113,
			1343.006,
			1344.913,
			1345.871,
			1347.798,
			1348.767,
			1350.714,
			1352.676,
			1353.664,
			1354.655,
			1357.647,
			1359.664,
			1362.71,
			1364.742,
			1366.801,
			1367.834,
			1369.91,
			1370.951,
			1373.043,
			1375.141,
			1378.29,
			1380.403,
			1383.597,
			1384.67,
			1385.744,
			1388.994,
			1391.153,
			1394.392,
			1396.549,
			1398.704,
			1399.783,
			1401.933,
			1403.005,
			1405.165,
			1407.307,
			1408.378,
			1410.519,
			1412.643,
			1415.812,
			1416.864,
			1417.913,
			1419.989,
			1422.053,
			1423.083,
			1425.136,
			1426.158,
			1428.195,
			1431.253,
			1433.277,
			1435.315,
			1438.354,
			1439.369,
			1440.381,
			1443.405,
			1445.418,
			1448.421,
			1450.42,
			1452.408,
			1453.399,
			1455.376,
			1456.36,
			1458.322,
			1460.28,
			1461.255,
			1463.199,
			1465.141,
			1468.042,
			1469.005,
			1469.965,
			1472.827,
			1473.776,
			1474.719,
			1477.53,
			1479.391,
			1482.158,
			1483.992,
			1485.815,
			1486.725,
			1488.547,
			1490.383,
			1493.146,
			1494.072,
			1495.008,
			1497.824,
			1499.715,
			1501.613,
			1502.564,
			1504.468,
			1506.366,
			1507.317,
			1509.222,
			1510.167,
			1512.064,
			1513.972,
			1516.809,
			1518.715,
			1521.566,
			1522.521,
			1523.478,
			1526.353,
			1528.262,
			1531.153,
			1533.075,
			1534.982,
			1535.929,
			1537.81,
			1538.742,
			1539.667,
			1543.287,
			1545.04,
			1546.754,
			1548.434,
			1550.083,
			1550.909,
			1552.521,
			1554.121,
			1554.918,
			1556.507,
			1557.301,
			1558.891,
			1560.502,
			1562.935,
			1564.558,
			1567.111,
			1567.962,
			1568.813,
			1571.443,
			1573.217,
			1575.876,
			1577.642,
			1579.404,
			1580.254,
			1581.927,
			1582.764,
			1584.436,
			1586.072,
			1586.885,
			1588.509,
			1590.128,
			1591.725,
			1593.319,
			1594.116,
			1596.499,
			1597.291,
			1598.082,
			1599.665,
			1600.458,
			1602.044,
			1603.629,
			1606.009,
			1607.596,
			1609.975,
			1610.769,
			1611.563,
			1613.943,
			1615.529,
			1617.906,
			1619.489,
			1621.07,
			1621.86,
			1623.439,
			1625.017,
			1625.808,
			1627.392,
			1628.183,
			1629.765,
			1631.355,
			1633.742,
			1635.333,
			1637.729,
			1638.527,
			1639.325,
			1641.734,
			1643.345,
			1644.956,
			1647.426,
			1649.076,
			1651.575,
			1652.414,
			1653.252,
			1655.767,
			1657.45,
			1659.971,
			1661.658,
			1664.188,
			1665.872,
			1667.549,
			1668.386,
			1670.891,
			1672.533,
			1674.174,
			1676.631,
			1678.263,
			1680.712,
			1681.536,
			1682.358,
			1684.824,
			1686.477,
			1688.961,
			1690.617,
			1692.282,
			1693.114,
			1694.777,
			1695.609,
			1697.278,
			1698.948,
			1699.783,
			1700.62,
			1703.154,
			1704.848,
			1706.563,
			1707.432,
			1709.171,
			1710.917,
			1711.802,
			1713.573,
			1714.461,
			1716.251,
			1718.072,
			1720.82,
			1722.663,
			1725.439,
			1726.356,
			1727.263,
			1729.997,
			1731.824,
			1734.568,
			1736.413,
			1738.28,
			1739.216,
			1741.095,
			1742.036,
			1743.921,
			1745.816,
			1746.758,
			1748.624,
			1750.498,
			1753.312,
			1755.194,
			1758.036,
			1758.989,
			1759.944,
			1761.866,
			1762.832,
			1764.774,
			1766.73,
			1769.688,
			1771.675,
			1774.674,
			1776.69,
			1779.733,
			1781.777,
			1784.867,
			1786.943,
			1789.032,
			1790.081,
			1792.183,
			1793.233,
			1795.337,
			1797.45,
			1798.511,
			1800.623,
			1802.764,
			1805.994,
			1808.16,
			1811.43,
			1812.525,
			1813.623,
			1816.933,
			1819.153,
			1821.383,
			1824.748,
			1827.004,
			1828.136,
			1830.407,
			1831.547,
			1832.69,
			1836.134,
			1837.287,
			1838.444,
			1841.931,
			1844.269,
			1847.797,
			1850.161,
			1852.535,
			1853.725,
			1856.111,
			1857.308,
			1859.709,
			1862.12,
			1865.756,
			1868.193,
			1871.87,
			1873.1,
			1874.331,
			1878.04,
			1880.521,
			1884.258,
			1886.758,
			1889.267,
			1890.523,
			1893.043,
			1894.307,
			1896.842,
			1900.663,
			1901.941,
			1905.793,
			1908.374,
			1910.967,
			1912.267,
			1914.873,
			1917.481,
			1918.787,
			1921.404,
			1922.716,
			1925.348,
			1927.99,
			1931.973,
			1934.638,
			1938.65,
			1939.992,
			1941.335,
			1945.375,
			1948.075,
			1952.139,
			1954.858,
			1958.948,
			1961.684,
			1963.055,
			1965.802,
			1968.557,
			1969.937,
			1972.704,
			1975.478,
			1978.26,
			1982.446,
			1986.649,
			1988.052,
			1989.458,
			1992.272,
			1993.682,
			1996.506,
			1999.336,
			2003.598,
			2006.449,
			2010.743,
			2012.178,
			2013.616,
			2017.941,
			2020.835,
			2025.191,
			2028.103,
			2031.023,
			2032.487,
			2035.418,
			2036.887,
			2039.831,
			2042.782,
			2044.26,
			2047.222,
			2050.19,
			2054.653,
			2057.635,
			2062.119,
			2063.616,
			2065.115,
			2069.623,
			2072.636,
			2077.169,
			2080.199,
			2083.236,
			2087.805,
			2089.331,
			2090.859,
			2095.453,
			2096.987,
			2098.523,
			2103.14,
			2106.225,
			2110.867,
			2113.969,
			2117.076,
			2118.632,
			2121.749,
			2123.31,
			2126.436,
			2129.568,
			2134.277,
			2137.423,
			2142.154,
			2143.734,
			2145.315,
			2150.067,
			2153.242,
			2158.016,
			2161.205,
			2164.393,
			2165.989,
			2169.185,
			2170.785,
			2172.386,
			2177.199,
			2178.805,
			2182.022,
			2185.245,
			2188.472,
			2191.705,
			2193.323,
			2196.563,
			2199.808,
			2201.433,
			2204.685,
			2206.313,
			2209.574,
			2212.839,
			2217.746,
			2221.022,
			2225.945,
			2227.587,
			2229.231,
			2234.171,
			2234.529
		],
		[
			0,
			0.007,
			0.055,
			0.131,
			0.162,
			0.192,
			0.255,
			0.319,
			0.38,
			0.439,
			0.487,
			0.503,
			0.565,
			0.596,
			0.629,
			0.69,
			0.703,
			0.752,
			0.815,
			0.874,
			0.923,
			0.937,
			1,
			1.034,
			1.062,
			1.123,
			1.139,
			1.186,
			1.246,
			1.308,
			1.371,
			1.435,
			1.466,
			1.495,
			1.558,
			1.621,
			1.682,
			1.745,
			1.789,
			1.805,
			1.867,
			1.898,
			1.929,
			1.993,
			2.008,
			2.054,
			2.114,
			2.179,
			2.224,
			2.24,
			2.301,
			2.332,
			2.362,
			2.424,
			2.441,
			2.488,
			2.55,
			2.611,
			2.658,
			2.734,
			2.767,
			2.798,
			2.858,
			2.921,
			2.984,
			3.045,
			3.095,
			3.107,
			3.169,
			3.201,
			3.231,
			3.294,
			3.31,
			3.354,
			3.418,
			3.481,
			3.524,
			3.54,
			3.604,
			3.635,
			3.666,
			3.743,
			3.79,
			3.851,
			3.913,
			3.962,
			4.037,
			4.07,
			4.1,
			4.162,
			4.179,
			4.224,
			4.288,
			4.348,
			4.396,
			4.41,
			4.471,
			4.502,
			4.535,
			4.612,
			4.657,
			4.721,
			4.781,
			4.844,
			4.907,
			4.937,
			4.968,
			5.03,
			5.093,
			5.157,
			5.215,
			5.279,
			5.341,
			5.37,
			5.401,
			5.464,
			5.48,
			5.527,
			5.588,
			5.649,
			5.713,
			5.773,
			5.806,
			5.837,
			5.898,
			5.914,
			5.958,
			6.023,
			6.085,
			6.147,
			6.207,
			6.24,
			6.271,
			6.332,
			6.394,
			6.456,
			6.518,
			6.565,
			6.579,
			6.643,
			6.674,
			6.704,
			6.765,
			6.782,
			6.829,
			6.891,
			6.952,
			6.999,
			7.015,
			7.078,
			7.108,
			7.138,
			7.202,
			7.221,
			7.264,
			7.323,
			7.387,
			7.433,
			7.512,
			7.541,
			7.572,
			7.635,
			7.697,
			7.758,
			7.821,
			7.882,
			7.945,
			7.976,
			8.006,
			8.084,
			8.129,
			8.193,
			8.255,
			8.3,
			8.315,
			8.378,
			8.41,
			8.442,
			8.518,
			8.566,
			8.628,
			8.687,
			8.734,
			8.812,
			8.845,
			8.873,
			8.936,
			8.998,
			9.064,
			9.124,
			9.17,
			9.186,
			9.248,
			9.282,
			9.308,
			9.37,
			9.386,
			9.434,
			9.495,
			9.556,
			9.605,
			9.682,
			9.712,
			9.742,
			9.806,
			9.867,
			9.929,
			9.99,
			10.037,
			10.115,
			10.147,
			10.177,
			10.24,
			10.255,
			10.302,
			10.362,
			10.425,
			10.471,
			10.488,
			10.548,
			10.581,
			10.611,
			10.671,
			10.688,
			10.736,
			10.798,
			10.86,
			10.907,
			10.983,
			11.015,
			11.046,
			11.123,
			11.168,
			11.231,
			11.293,
			11.354,
			11.417,
			11.448,
			11.479,
			11.541,
			11.558,
			11.604,
			11.666,
			11.728,
			11.775,
			11.788,
			11.852,
			11.882,
			11.914,
			11.974,
			11.99,
			12.036,
			12.1,
			12.162,
			12.207,
			12.285,
			12.318,
			12.348,
			12.408,
			12.471,
			12.535,
			12.597,
			12.641,
			12.658,
			12.718,
			12.749,
			12.782,
			12.844,
			12.858,
			12.905,
			12.968,
			13.029,
			13.075,
			13.092,
			13.154,
			13.184,
			13.216,
			13.278,
			13.338,
			13.4,
			13.408,
			13.462,
			13.508,
			13.586,
			13.619,
			13.65,
			13.712,
			13.773,
			13.835,
			13.897,
			13.944,
			13.961,
			14.022,
			14.053,
			14.083,
			14.145,
			14.161,
			14.208,
			14.269,
			14.33,
			14.379,
			14.455,
			14.485,
			14.519,
			14.596,
			14.641,
			14.705,
			14.765,
			14.812,
			14.828,
			14.89,
			14.921,
			14.952,
			15.028,
			15.077,
			15.138,
			15.199,
			15.246,
			15.262,
			15.324,
			15.355,
			15.385,
			15.448,
			15.467,
			15.508,
			15.571,
			15.635,
			15.681,
			15.758,
			15.788,
			15.819,
			15.882,
			15.942,
			16.008,
			16.068,
			16.116,
			16.129,
			16.191,
			16.222,
			16.253,
			16.316,
			16.332,
			16.378,
			16.439,
			16.503,
			16.549,
			16.563,
			16.627,
			16.656,
			16.687,
			16.749,
			16.765,
			16.812,
			16.873,
			16.937,
			16.982,
			17.06,
			17.091,
			17.122,
			17.185,
			17.245,
			17.307,
			17.371,
			17.416,
			17.433,
			17.494,
			17.53,
			17.556,
			17.618,
			17.632,
			17.679,
			17.744,
			17.803,
			17.867,
			17.928,
			17.958,
			17.99,
			18.068,
			18.116,
			18.175,
			18.238,
			18.285,
			18.362,
			18.394,
			18.424,
			18.488,
			18.503,
			18.549,
			18.609,
			18.671,
			18.718,
			18.734,
			18.796,
			18.827,
			18.86,
			18.919,
			18.936,
			18.981,
			19.048,
			19.106,
			19.152,
			19.168,
			19.231,
			19.262,
			19.291,
			19.354,
			19.415,
			19.478,
			19.539,
			19.604,
			19.664,
			19.695,
			19.725,
			19.789,
			19.803,
			19.85,
			19.912,
			19.974,
			20.038,
			20.098,
			20.129,
			20.162,
			20.223,
			20.238,
			20.285,
			20.346,
			20.41,
			20.455,
			20.534,
			20.562,
			20.596,
			20.657,
			20.718,
			20.782,
			20.841,
			20.889,
			20.906,
			20.968,
			20.998,
			21.029,
			21.09,
			21.106,
			21.153,
			21.215,
			21.277,
			21.323,
			21.338,
			21.4,
			21.432,
			21.463,
			21.54,
			21.587,
			21.652,
			21.711,
			21.757,
			21.835,
			21.867,
			21.896,
			21.958,
			22.021,
			22.083,
			22.146,
			22.19,
			22.208,
			22.27,
			22.299,
			22.331,
			22.394,
			22.407,
			22.456,
			22.518,
			22.58,
			22.641,
			22.703,
			22.735,
			22.765,
			22.828,
			22.888,
			22.95,
			23.012,
			23.061,
			23.138,
			23.168,
			23.199,
			23.262,
			23.323,
			23.386,
			23.447,
			23.508,
			23.57,
			23.603,
			23.634,
			23.695,
			23.715,
			23.758,
			23.82,
			23.881,
			23.929,
			24.005,
			24.038,
			24.068,
			24.129,
			24.191,
			24.254,
			24.315,
			24.362,
			24.44,
			24.471,
			24.5,
			24.563,
			24.58,
			24.625,
			24.688,
			24.749,
			24.797,
			24.813,
			24.872,
			24.904,
			24.934,
			24.998,
			25.012,
			25.06,
			25.123,
			25.184,
			25.231,
			25.309,
			25.339,
			25.37,
			25.432,
			25.494,
			25.561,
			25.618,
			25.665,
			25.679,
			25.743,
			25.777,
			25.804,
			25.865,
			25.881,
			25.928,
			25.99,
			26.052,
			26.097,
			26.114,
			26.177,
			26.207,
			26.238,
			26.298,
			26.315,
			26.362,
			26.423,
			26.487,
			26.531,
			26.608,
			26.64,
			26.671,
			26.733,
			26.795,
			26.858,
			26.921,
			26.982,
			27.044,
			27.076,
			27.107,
			27.185,
			27.23,
			27.293,
			27.354,
			27.402,
			27.416,
			27.479,
			27.51,
			27.539,
			27.603,
			27.665,
			27.726,
			27.787,
			27.838,
			27.913,
			27.942,
			27.975,
			28.037,
			28.098,
			28.161,
			28.221,
			28.27,
			28.284,
			28.347,
			28.378,
			28.407,
			28.486,
			28.531,
			28.595,
			28.656,
			28.702,
			28.719,
			28.78,
			28.811,
			28.84,
			28.92,
			28.965,
			29.028,
			29.091,
			29.136,
			29.153,
			29.215,
			29.245,
			29.278,
			29.337,
			29.353,
			29.402,
			29.463,
			29.524,
			29.588,
			29.649,
			29.678,
			29.712,
			29.788,
			29.834,
			29.902,
			29.957,
			30.006,
			30.083,
			30.113,
			30.145,
			30.221,
			30.27,
			30.331,
			30.394,
			30.439,
			30.453,
			30.517,
			30.546,
			30.579,
			30.639,
			30.657,
			30.702,
			30.765,
			30.825,
			30.887,
			30.95,
			30.981,
			31.012,
			31.073,
			31.091,
			31.137,
			31.198,
			31.261,
			31.323,
			31.384,
			31.416,
			31.447,
			31.508,
			31.57,
			31.633,
			31.695,
			31.741,
			31.757,
			31.818,
			31.85,
			31.88,
			31.942,
			31.965,
			32.006,
			32.07,
			32.13,
			32.177,
			32.189,
			32.252,
			32.284,
			32.316,
			32.375,
			32.393,
			32.439,
			32.505,
			32.562,
			32.61,
			32.686,
			32.719,
			32.749,
			32.812,
			32.872,
			32.934,
			32.998,
			33.044,
			33.059,
			33.121,
			33.151,
			33.184,
			33.245,
			33.262,
			33.306,
			33.371,
			33.432,
			33.478,
			33.493,
			33.556,
			33.587,
			33.616,
			33.678,
			33.742,
			33.803,
			33.865,
			33.912,
			33.928,
			33.988,
			34.05,
			34.113,
			34.129,
			34.174,
			34.238,
			34.3,
			34.345,
			34.362,
			34.422,
			34.455,
			34.484,
			34.548,
			34.562,
			34.61,
			34.671,
			34.732,
			34.78,
			34.858,
			34.888,
			34.92,
			34.996,
			35.044,
			35.105,
			35.166,
			35.214,
			35.23,
			35.291,
			35.324,
			35.352,
			35.432,
			35.479,
			35.544,
			35.602,
			35.649,
			35.663,
			35.724,
			35.758,
			35.787,
			35.851,
			35.865,
			35.912,
			35.977,
			36.036,
			36.098,
			36.161,
			36.19,
			36.221,
			36.284,
			36.345,
			36.409,
			36.47,
			36.517,
			36.53,
			36.592,
			36.623,
			36.655,
			36.717,
			36.734,
			36.78,
			36.843,
			36.903,
			36.95,
			36.967,
			37.029,
			37.06,
			37.09,
			37.151,
			37.167,
			37.214,
			37.277,
			37.339,
			37.383,
			37.462,
			37.491,
			37.523,
			37.587,
			37.647,
			37.71,
			37.771,
			37.818,
			37.834,
			37.896,
			37.928,
			37.959,
			38.021,
			38.034,
			38.083,
			38.15,
			38.204,
			38.267,
			38.331,
			38.361,
			38.393,
			38.454,
			38.515,
			38.578,
			38.64,
			38.686,
			38.764,
			38.795,
			38.827,
			38.889,
			38.949,
			39.011,
			39.073,
			39.137,
			39.199,
			39.23,
			39.258,
			39.337,
			39.384,
			39.445,
			39.507,
			39.555,
			39.632,
			39.664,
			39.695,
			39.755,
			39.819,
			39.881,
			39.94,
			39.99,
			40.003,
			40.065,
			40.098,
			40.129,
			40.19,
			40.212,
			40.252,
			40.315,
			40.377,
			40.422,
			40.439,
			40.501,
			40.53,
			40.562,
			40.623,
			40.639,
			40.686,
			40.75,
			40.812,
			40.858,
			40.933,
			40.967,
			40.995,
			41.075,
			41.12,
			41.183,
			41.246,
			41.29,
			41.307,
			41.368,
			41.399,
			41.432,
			41.493,
			41.507,
			41.556,
			41.617,
			41.678,
			41.739,
			41.804,
			41.834,
			41.865,
			41.927,
			41.94,
			41.988,
			42.052,
			42.112,
			42.174,
			42.236,
			42.298,
			42.376,
			42.422,
			42.486,
			42.548,
			42.591,
			42.608,
			42.67,
			42.7,
			42.734,
			42.795,
			42.811,
			42.856,
			42.922,
			42.982,
			43.028,
			43.104,
			43.136,
			43.165,
			43.23,
			43.291,
			43.352,
			43.415,
			43.463,
			43.478,
			43.539,
			43.57,
			43.6,
			43.662,
			43.68,
			43.726,
			43.787,
			43.85,
			43.912,
			43.974,
			44.003,
			44.035,
			44.097,
			44.113,
			44.158,
			44.221,
			44.282,
			44.345,
			44.408,
			44.438,
			44.468,
			44.531,
			44.594,
			44.655,
			44.717,
			44.763,
			44.779,
			44.841,
			44.872,
			44.904,
			44.982,
			45.025,
			45.091,
			45.15,
			45.198,
			45.214,
			45.277,
			45.305,
			45.337,
			45.399,
			45.416,
			45.462,
			45.523,
			45.585,
			45.63,
			45.708,
			45.739,
			45.772,
			45.848,
			45.896,
			45.956,
			46.019,
			46.081,
			46.143,
			46.174,
			46.204,
			46.267,
			46.283,
			46.328,
			46.398,
			46.452,
			46.515,
			46.579,
			46.61,
			46.64,
			46.702,
			46.718,
			46.765,
			46.825,
			46.886,
			46.935,
			47.012,
			47.044,
			47.074,
			47.136,
			47.196,
			47.26,
			47.321,
			47.368,
			47.384,
			47.446,
			47.477,
			47.507,
			47.571,
			47.585,
			47.631,
			47.695,
			47.755,
			47.803,
			47.88,
			47.91,
			47.942,
			48.004,
			48.066,
			48.129,
			48.191,
			48.252,
			48.313,
			48.345,
			48.374,
			48.438,
			48.457,
			48.5,
			48.562,
			48.622,
			48.686,
			48.749,
			48.779,
			48.811,
			48.871,
			48.887,
			48.933,
			48.996,
			49.056,
			49.12,
			49.183,
			49.213,
			49.245,
			49.304,
			49.368,
			49.432,
			49.491,
			49.538,
			49.555,
			49.615,
			49.647,
			49.678,
			49.738,
			49.755,
			49.803,
			49.863,
			49.926,
			49.971,
			49.988,
			50.049,
			50.081,
			50.112,
			50.173,
			50.188,
			50.236,
			50.298,
			50.358,
			50.407,
			50.485,
			50.519,
			50.546,
			50.607,
			50.617
		]
	],
	'1840_1841_7982': [
		[
			126.594
		],
		[
			0
		],
		[
			0,
			1.084,
			4.47,
			6.165,
			9.558,
			12.957,
			18.062,
			21.471,
			26.594,
			28.304,
			30.015,
			35.154,
			38.586,
			43.742,
			47.185,
			52.358,
			55.812,
			57.541,
			61.001,
			64.466,
			66.2,
			69.673,
			73.15,
			76.633,
			80.12,
			81.865,
			85.359,
			88.857,
			90.607,
			94.11,
			95.863,
			99.372,
			102.885,
			108.164,
			111.688,
			116.982,
			118.748,
			120.515,
			125.823,
			129.367,
			134.691,
			138.246,
			141.805,
			143.587,
			147.155,
			148.94,
			152.513,
			156.091,
			157.88,
			161.462,
			165.046,
			170.428,
			172.223,
			174.019,
			179.412,
			181.211,
			183.011,
			188.415,
			192.022,
			195.634,
			201.059,
			204.68,
			210.122,
			211.938,
			213.756,
			219.213,
			222.858,
			228.333,
			231.984,
			237.45,
			241.069,
			244.658,
			246.439,
			249.97,
			251.721,
			255.194,
			258.628,
			263.705,
			267.041,
			271.975,
			273.601,
			275.217,
			280.011,
			283.161,
			287.815,
			290.871,
			293.892,
			295.389,
			298.356,
			299.826,
			301.287,
			307.035,
			308.447,
			312.628,
			315.367,
			319.407,
			322.054,
			324.688,
			325.981,
			329.809,
			332.32,
			334.798,
			338.455,
			340.858,
			344.396,
			345.571,
			346.741,
			350.227,
			352.557,
			356.021,
			358.334,
			360.645,
			361.805,
			364.125,
			365.284,
			367.611,
			369.938,
			371.101,
			373.434,
			375.762,
			378.097,
			380.432,
			381.599,
			383.94,
			386.282,
			387.456,
			389.808,
			390.985,
			393.347,
			395.717,
			399.291,
			401.69,
			405.315,
			406.532,
			407.75,
			411.411,
			413.849,
			417.465,
			419.856,
			422.218,
			423.388,
			425.694,
			426.829,
			429.072,
			431.273,
			432.358,
			434.501,
			436.601,
			439.68,
			440.689,
			441.684,
			443.648,
			445.577,
			446.527,
			449.319,
			451.142,
			453.831,
			455.601,
			457.364,
			459.999,
			460.875,
			461.753,
			465.284,
			466.17,
			468.838,
			470.625,
			472.422,
			473.324,
			475.134,
			476.951,
			477.864,
			479.695,
			480.613,
			482.453,
			484.295,
			487.062,
			488.899,
			491.637,
			492.546,
			493.453,
			496.152,
			497.933,
			500.604,
			502.369,
			504.134,
			506.809,
			507.696,
			508.581,
			511.296,
			512.199,
			513.101,
			515.825,
			517.636,
			519.444,
			520.35,
			522.155,
			523.95,
			524.847,
			526.635,
			527.525,
			529.299,
			531.058,
			533.679,
			535.421,
			538.021,
			538.885,
			539.749,
			542.327,
			544.039,
			546.623,
			548.352,
			550.946,
			552.678,
			553.544,
			555.276,
			557.013,
			557.883,
			558.752,
			561.364,
			563.104,
			565.718,
			567.467,
			569.22,
			570.099,
			571.86,
			572.74,
			574.503,
			576.269,
			578.919,
			580.686,
			583.329,
			584.207,
			585.083,
			587.711,
			589.447,
			592.045,
			593.764,
			595.47,
			596.323,
			598.029,
			598.871,
			600.547,
			602.222,
			603.06,
			604.725,
			606.384,
			608.876,
			609.707,
			610.538,
			612.198,
			613.86,
			614.69,
			617.171,
			618.823,
			620.474,
			622.945,
			624.594,
			627.115,
			627.957,
			628.8,
			631.367,
			633.107,
			635.725,
			637.485,
			639.249,
			640.135,
			641.905,
			642.775,
			644.555,
			646.344,
			647.239,
			649.036,
			650.86,
			653.576,
			655.409,
			658.188,
			659.122,
			660.058,
			662.89,
			664.798,
			666.717,
			669.624,
			671.577,
			674.521,
			675.507,
			676.497,
			679.478,
			680.477,
			681.478,
			684.501,
			686.531,
			688.57,
			689.594,
			691.649,
			693.715,
			694.752,
			697.878,
			699.966,
			702.053,
			705.203,
			707.315,
			710.505,
			711.574,
			712.645,
			715.874,
			718.038,
			721.305,
			723.495,
			725.695,
			726.798,
			729.012,
			730.122,
			731.235,
			734.589,
			735.712,
			737.965,
			740.228,
			742.501,
			745.927,
			748.218,
			750.509,
			751.672,
			753.95,
			755.084,
			757.334,
			759.56,
			762.842,
			764.987,
			768.139,
			769.173,
			770.199,
			773.248,
			775.258,
			778.256,
			780.25,
			782.242,
			783.24,
			785.239,
			786.237,
			788.24,
			791.262,
			793.286,
			795.337,
			798.427,
			800.533,
			803.705,
			804.773,
			805.841,
			807.98,
			809.047,
			811.181,
			814.377,
			816.5,
			818.624,
			821.809,
			822.874,
			823.944,
			827.165,
			828.243,
			829.331,
			832.605,
			834.806,
			838.128,
			840.347,
			841.458,
			843.682,
			845.9,
			847.01,
			849.211,
			851.427,
			854.753,
			856.969,
			860.288,
			861.391,
			862.494,
			865.763,
			867.927,
			871.084,
			873.16,
			875.231,
			878.319,
			879.379,
			880.427,
			883.569,
			884.629,
			885.69,
			888.863,
			891.016,
			893.151,
			894.221,
			896.369,
			898.491,
			899.55,
			901.664,
			902.713,
			904.8,
			906.871,
			909.954,
			911.996,
			915.034,
			916.035,
			917.033,
			920.01,
			921.97,
			924.893,
			926.83,
			928.758,
			929.721,
			931.658,
			932.624,
			934.55,
			936.488,
			937.455,
			938.422,
			941.324,
			943.26,
			945.194,
			946.157,
			948.082,
			950.007,
			950.963,
			953.834,
			955.741,
			957.639,
			960.49,
			962.378,
			965.221,
			966.165,
			967.11,
			969.949,
			971.84,
			974.679,
			976.572,
			979.413,
			981.311,
			982.262,
			984.164,
			986.071,
			987.027,
			988.943,
			990.865,
			993.763,
			994.733,
			995.704,
			998.607,
			999.584,
			1000.563,
			1002.516,
			1003.495,
			1005.461,
			1007.428,
			1010.389,
			1012.386,
			1015.361,
			1016.358,
			1017.356,
			1020.353,
			1021.356,
			1022.359,
			1025.368,
			1027.374,
			1029.379,
			1030.38,
			1032.381,
			1033.38,
			1035.376,
			1037.366,
			1038.36,
			1040.345,
			1042.328,
			1045.295,
			1047.274,
			1050.239,
			1051.227,
			1052.221,
			1055.196,
			1057.189,
			1060.189,
			1062.198,
			1064.218,
			1067.254,
			1068.271,
			1069.289,
			1072.343,
			1074.382,
			1077.438,
			1079.472,
			1081.506,
			1082.526,
			1084.565,
			1086.605,
			1087.635,
			1090.72,
			1092.796,
			1094.88,
			1098.023,
			1100.129,
			1103.293,
			1104.349,
			1105.404,
			1108.566,
			1110.669,
			1113.801,
			1115.881,
			1117.95,
			1118.98,
			1121.042,
			1122.07,
			1124.113,
			1126.159,
			1127.182,
			1128.203,
			1131.272,
			1133.322,
			1136.407,
			1138.471,
			1140.543,
			1141.582,
			1143.657,
			1144.696,
			1146.765,
			1148.841,
			1151.969,
			1154.048,
			1157.181,
			1158.225,
			1159.27,
			1162.421,
			1164.531,
			1167.702,
			1169.819,
			1171.922,
			1172.969,
			1175.048,
			1176.078,
			1178.114,
			1180.118,
			1181.105,
			1183.055,
			1184.972,
			1187.786,
			1189.657,
			1191.494,
			1193.323,
			1194.236,
			1196.056,
			1196.964,
			1198.779,
			1200.593,
			1203.313,
			1205.127,
			1207.847,
			1208.754,
			1209.66,
			1212.376,
			1214.186,
			1216.895,
			1218.701,
			1220.506,
			1221.408,
			1223.211,
			1225.017,
			1225.919,
			1227.721,
			1228.624,
			1230.427,
			1232.231,
			1234.94,
			1236.748,
			1239.466,
			1240.374,
			1241.283,
			1244.017,
			1245.848,
			1248.602,
			1250.45,
			1252.303,
			1255.098,
			1256.032,
			1256.969,
			1259.79,
			1260.735,
			1261.68,
			1264.53,
			1266.441,
			1268.356,
			1269.317,
			1271.241,
			1273.168,
			1274.133,
			1276.064,
			1277.029,
			1278.961,
			1280.894,
			1283.792,
			1285.724,
			1288.621,
			1289.585,
			1290.548,
			1293.436,
			1295.364,
			1298.257,
			1300.191,
			1303.095,
			1305.034,
			1306.004,
			1306.974,
			1309.886,
			1310.856,
			1312.798,
			1314.742,
			1316.682,
			1319.6,
			1321.544,
			1323.495,
			1324.472,
			1326.429,
			1327.41,
			1329.377,
			1331.35,
			1334.32,
			1336.309,
			1339.301,
			1340.303,
			1341.306,
			1344.32,
			1346.34,
			1349.379,
			1351.416,
			1354.481,
			1356.534,
			1357.563,
			1359.626,
			1361.677,
			1362.714,
			1364.794,
			1366.873,
			1368.958,
			1371.036,
			1372.072,
			1374.149,
			1376.193,
			1377.209,
			1380.248,
			1382.27,
			1384.284,
			1385.293,
			1387.309,
			1389.319,
			1392.331,
			1393.331,
			1394.33,
			1397.315,
			1399.295,
			1402.251,
			1404.214,
			1406.172,
			1407.148,
			1409.096,
			1410.068,
			1412.006,
			1413.939,
			1414.904,
			1416.839,
			1418.774,
			1421.685,
			1422.66,
			1423.635,
			1426.573,
			1427.561,
			1428.549,
			1431.527,
			1433.532,
			1436.547,
			1438.567,
			1440.587,
			1443.627,
			1444.642,
			1445.658,
			1448.725,
			1449.75,
			1450.776,
			1453.893,
			1455.982,
			1458.114,
			1459.183,
			1461.334,
			1463.52,
			1464.613,
			1466.808,
			1467.909,
			1470.114,
			1472.308,
			1475.582,
			1477.743,
			1480.959,
			1482.025,
			1483.087,
			1486.26,
			1488.355,
			1491.48,
			1493.543,
			1495.598,
			1496.619,
			1498.648,
			1499.659,
			1501.673,
			1504.659,
			1505.652,
			1508.606,
			1510.57,
			1513.499,
			1515.448,
			1517.396,
			1518.368,
			1520.313,
			1521.287,
			1523.234,
			1525.185,
			1528.12,
			1530.084,
			1533.049,
			1534.044,
			1535.041,
			1538.051,
			1540.075,
			1543.133,
			1545.187,
			1547.251,
			1548.287,
			1550.367,
			1552.451,
			1553.491,
			1555.572,
			1556.615,
			1558.703,
			1560.781,
			1563.95,
			1565.003,
			1566.057,
			1568.17,
			1570.287,
			1571.348,
			1573.474,
			1574.538,
			1576.673,
			1578.814,
			1582.042,
			1584.205,
			1587.467,
			1588.563,
			1589.661,
			1592.968,
			1595.189,
			1598.517,
			1600.759,
			1603.01,
			1604.157,
			1606.42,
			1607.555,
			1609.829,
			1612.111,
			1613.255,
			1615.548,
			1617.851,
			1621.325,
			1622.488,
			1623.655,
			1627.168,
			1628.344,
			1629.523,
			1633.073,
			1635.453,
			1639.038,
			1641.44,
			1643.85,
			1645.058,
			1647.46,
			1648.694,
			1649.91,
			1653.565,
			1654.787,
			1656.009,
			1659.69,
			1662.155,
			1665.853,
			1668.362,
			1670.867,
			1672.123,
			1674.642,
			1675.906,
			1678.439,
			1680.98,
			1684.807,
			1687.366,
			1691.219,
			1692.507,
			1693.797,
			1697.685,
			1700.282,
			1704.191,
			1706.814,
			1709.445,
			1710.768,
			1713.418,
			1714.748,
			1716.081,
			1721.429,
			1724.116,
			1726.814,
			1729.519,
			1732.232,
			1733.592,
			1736.315,
			1739.046,
			1740.414,
			1743.156,
			1744.529,
			1747.284,
			1750.045,
			1751.429,
			1754.203,
			1756.985,
			1761.175,
			1762.575,
			1763.978,
			1768.197,
			1771.02,
			1775.272,
			1778.113,
			1780.963,
			1782.39,
			1785.251,
			1786.683,
			1789.553,
			1792.43,
			1793.871,
			1796.759,
			1799.655,
			1804.02,
			1805.479,
			1806.94,
			1811.332,
			1812.799,
			1814.268,
			1818.656,
			1821.629,
			1824.585,
			1829.002,
			1831.968,
			1836.426,
			1837.913,
			1839.402,
			1843.873,
			1846.858,
			1851.342,
			1854.335,
			1857.333,
			1858.834,
			1861.837,
			1863.341,
			1866.352,
			1869.365,
			1870.874,
			1873.894,
			1876.92,
			1881.466,
			1882.983,
			1884.499,
			1889.044,
			1890.555,
			1892.062,
			1896.552,
			1899.513,
			1903.889,
			1906.76,
			1909.597,
			1913.781,
			1915.156,
			1916.522,
			1921.891,
			1923.209,
			1927.106,
			1929.656,
			1932.169,
			1933.411,
			1935.866,
			1938.283,
			1939.477,
			1943.01,
			1945.325,
			1947.603,
			1950.953,
			1953.14,
			1956.352,
			1957.404,
			1958.448,
			1961.525,
			1963.551,
			1966.5,
			1968.43,
			1970.334,
			1971.278,
			1973.147,
			1974.073,
			1974.994,
			1977.735,
			1978.638,
			1979.539,
			1982.233,
			1984.017,
			1985.796,
			1986.685,
			1988.477,
			1990.276,
			1991.174,
			1993.005,
			1993.948,
			1995.833,
			1997.739,
			2000.706,
			2002.688,
			2005.678,
			2006.679,
			2007.67,
			2010.571,
			2012.512,
			2015.332,
			2017.228,
			2019.103,
			2020.044,
			2021.934,
			2022.883,
			2024.787,
			2026.703,
			2027.667,
			2029.611,
			2031.568,
			2034.536,
			2035.533,
			2036.533,
			2039.558,
			2040.573,
			2041.592,
			2044.672,
			2046.725,
			2048.811,
			2051.963,
			2054.087,
			2057.236,
			2058.297,
			2059.36,
			2062.564,
			2064.712,
			2067.952,
			2070.118,
			2073.385,
			2075.571,
			2076.667,
			2078.859,
			2081.049,
			2082.139,
			2084.308,
			2086.453,
			2089.611,
			2090.646,
			2091.671,
			2094.693,
			2095.683,
			2096.663,
			2098.596,
			2099.549,
			2101.428,
			2104.18,
			2105.974,
			2107.735,
			2110.311,
			2111.152,
			2111.983,
			2114.424,
			2115.22,
			2116.006,
			2118.312,
			2119.809,
			2121.272,
			2121.993,
			2123.417,
			2124.823,
			2125.52,
			2126.917,
			2127.608,
			2128.99,
			2130.369,
			2132.435,
			2133.81,
			2135.855,
			2136.535,
			2137.215,
			2139.25,
			2140.595,
			2142.608,
			2143.96,
			2145.324,
			2146.006,
			2147.37,
			2148.053,
			2148.756,
			2150.987,
			2151.731,
			2152.477,
			2154.868,
			2156.553,
			2158.243,
			2159.1,
			2160.83,
			2162.562,
			2163.43,
			2165.904,
			2167.551,
			2169.187,
			2171.592,
			2173.205,
			2175.585,
			2176.377,
			2177.173,
			2179.525,
			2181.053,
			2183.364,
			2184.876,
			2186.387,
			2187.147,
			2188.677,
			2189.447,
			2190.996,
			2192.558,
			2193.344,
			2194.138,
			2196.57,
			2199.032,
			2199.873,
			2200.718,
			2202.415,
			2204.128,
			2205.004,
			2206.765,
			2207.65,
			2209.431,
			2211.23,
			2213.94,
			2215.75,
			2218.479,
			2219.399,
			2220.317,
			2223.098,
			2224.952,
			2227.75,
			2229.629,
			2232.462,
			2234.344,
			2235.309,
			2237.211,
			2239.121,
			2240.063,
			2242.004,
			2243.925,
			2246.855,
			2247.839,
			2248.825,
			2251.805,
			2252.804,
			2253.807,
			2255.821,
			2256.833,
			2258.865,
			2261.937,
			2263.999,
			2266.072,
			2269.203,
			2270.251,
			2271.298,
			2274.441,
			2276.544,
			2279.72,
			2281.847,
			2283.985,
			2285.058,
			2287.209,
			2289.371,
			2290.455,
			2293.723,
			2295.914,
			2298.116,
			2301.437,
			2303.663,
			2307.022,
			2308.146,
			2309.273,
			2312.667,
			2314.942,
			2318.372,
			2320.67,
			2322.978,
			2326.456,
			2327.62,
			2329.955,
			2333.474,
			2334.652,
			2338.199,
			2340.576,
			2344.157,
			2346.556,
			2348.963,
			2350.17,
			2352.59,
			2353.804,
			2356.237,
			2358.68,
			2362.359,
			2364.823,
			2368.533,
			2369.774,
			2371.017,
			2374.758,
			2377.261,
			2381.031,
			2383.555,
			2386.086,
			2387.354,
			2389.896,
			2391.17,
			2393.723,
			2396.284,
			2397.567,
			2400.138,
			2402.717,
			2406.597,
			2407.894,
			2409.192,
			2411.794,
			2414.399,
			2415.7,
			2418.306,
			2419.611,
			2422.225,
			2424.846,
			2428.785,
			2431.438,
			2435.386,
			2436.704,
			2438.022,
			2441.984,
			2444.634,
			2448.622,
			2451.289,
			2453.957,
			2455.292,
			2457.962,
			2459.296,
			2461.961,
			2465.956,
			2468.619,
			2471.279,
			2475.285,
			2476.619,
			2477.961,
			2481.996,
			2483.343,
			2484.691,
			2488.729,
			2491.413,
			2495.414,
			2498.066,
			2500.701,
			2504.636,
			2505.942,
			2507.223,
			2512.398,
			2513.667,
			2517.418,
			2519.845,
			2522.255,
			2523.445,
			2525.795,
			2528.11,
			2529.252,
			2532.622,
			2534.82,
			2536.98,
			2540.149,
			2542.213,
			2545.234,
			2546.222,
			2547.2,
			2550.073,
			2551.941,
			2554.673,
			2556.449,
			2558.19,
			2559.046,
			2560.729,
			2561.557,
			2562.375,
			2564.771,
			2565.552,
			2566.322,
			2568.577,
			2570.031,
			2571.448,
			2572.142,
			2573.506,
			2574.833,
			2575.484,
			2577.385,
			2578.606,
			2579.786,
			2581.489,
			2582.577,
			2584.15,
			2584.659,
			2585.162,
			2586.642,
			2587.62,
			2589.064,
			2590.015,
			2590.958,
			2591.429,
			2592.369,
			2592.84,
			2593.788,
			2594.739,
			2595.209,
			2596.145,
			2597.076,
			2598.476,
			2598.951,
			2599.426,
			2600.365,
			2601.295,
			2601.757,
			2602.678,
			2603.139,
			2604.085,
			2605.054,
			2605.536,
			2606.494,
			2607.447,
			2608.899,
			2609.441,
			2609.978,
			2611.566,
			2612.612,
			2614.254,
			2615.454,
			2617.243,
			2618.435,
			2619.066,
			2620.373,
			2621.678,
			2622.331,
			2623.638,
			2624.978,
			2626.98,
			2627.647,
			2628.314,
			2629.639,
			2630.954,
			2631.612,
			2633.59,
			2634.886,
			2636.822,
			2638.119,
			2639.409,
			2641.32,
			2641.959,
			2642.601,
			2644.539,
			2645.185,
			2645.833,
			2647.79,
			2649.103,
			2650.375,
			2651.014,
			2652.299,
			2653.598,
			2654.219,
			2655.949,
			2657.114,
			2658.29,
			2659.99,
			2661.094,
			2662.767,
			2663.329,
			2663.894,
			2665.618,
			2666.775,
			2668.526,
			2669.724,
			2670.946,
			2672.787,
			2673.404,
			2674.022,
			2675.953,
			2676.6,
			2677.247,
			2679.198,
			2680.515,
			2681.838,
			2682.491,
			2683.825,
			2685.174,
			2685.855,
			2687.229,
			2687.922,
			2689.321,
			2690.759,
			2692.928,
			2694.383,
			2696.62,
			2697.374,
			2698.132,
			2700.423,
			2701.965,
			2704.307,
			2705.878,
			2707.462,
			2708.26,
			2709.866,
			2710.672,
			2712.294,
			2714.756,
			2716.415,
			2718.087,
			2720.609,
			2721.45,
			2722.292,
			2723.988,
			2725.699,
			2726.561,
			2729.165,
			2730.919,
			2732.687,
			2735.365,
			2737.168,
			2739.896,
			2740.813,
			2741.733,
			2744.512,
			2746.381,
			2749.21,
			2751.112,
			2753.99,
			2755.924,
			2756.896,
			2758.847,
			2760.812,
			2761.799,
			2763.782,
			2765.777,
			2768.792,
			2770.817,
			2773.876,
			2774.901,
			2775.929,
			2779.028,
			2781.108,
			2783.198,
			2786.348,
			2788.448,
			2791.609,
			2792.669,
			2793.731,
			2798.005,
			2799.081,
			2802.325,
			2804.503,
			2806.693,
			2807.793,
			2810.002,
			2812.222,
			2813.336,
			2815.573,
			2816.696,
			2818.952,
			2821.217,
			2824.64,
			2826.936,
			2830.401,
			2831.561,
			2832.721,
			2836.218,
			2838.559,
			2842.108,
			2844.471,
			2848.034,
			2850.421,
			2851.619,
			2852.818,
			2856.429,
			2857.64,
			2858.853,
			2862.506,
			2864.957,
			2867.418,
			2868.654,
			2871.132,
			2873.625,
			2874.875,
			2877.384,
			2878.641,
			2881.16,
			2883.689,
			2887.496,
			2890.04,
			2893.866,
			2895.143,
			2896.421,
			2900.255,
			2902.817,
			2906.662,
			2909.231,
			2911.804,
			2913.094,
			2915.674,
			2916.967,
			2919.555,
			2922.146,
			2923.44,
			2924.731,
			2928.61,
			2932.495,
			2935.09,
			2937.689,
			2940.294,
			2941.598,
			2944.21,
			2945.518,
			2948.139,
			2950.773,
			2954.741,
			2957.401,
			2961.413,
			2962.755,
			2964.099,
			2968.148,
			2970.858,
			2974.939,
			2977.668,
			2980.405,
			2981.776,
			2984.5,
			2985.897,
			2988.647,
			2992.769,
			2995.516,
			2998.238,
			3002.36,
			3005.137,
			3007.901,
			3010.673,
			3012.062,
			3016.242,
			3019.038,
			3023.248,
			3026.065,
			3028.89,
			3033.144,
			3034.566,
			3035.992,
			3040.262,
			3043.137,
			3047.463,
			3050.355,
			3053.273,
			3054.722,
			3057.624,
			3060.508,
			3061.966,
			3066.35,
			3069.291,
			3072.24,
			3076.684,
			3079.659,
			3084.135,
			3085.631,
			3087.128,
			3091.628,
			3094.629,
			3099.139,
			3102.153,
			3105.175,
			3106.689,
			3109.721,
			3111.24,
			3112.761,
			3117.334,
			3118.861,
			3120.39,
			3124.986,
			3128.058,
			3132.678,
			3135.789,
			3138.88,
			3140.428,
			3143.527,
			3145.08,
			3148.188,
			3151.304,
			3155.989,
			3159.118,
			3163.818,
			3165.385,
			3166.952,
			3171.658,
			3174.798,
			3179.517,
			3182.671,
			3187.417,
			3190.591,
			3192.182,
			3195.369,
			3198.564,
			3200.163,
			3203.364,
			3206.565,
			3211.37,
			3214.577,
			3217.79,
			3221.007,
			3222.616,
			3225.838,
			3227.449,
			3230.674,
			3233.903,
			3238.752,
			3241.991,
			3246.856,
			3248.481,
			3250.107,
			3254.993,
			3256.624,
			3258.255,
			3263.162,
			3266.443,
			3271.386,
			3274.688,
			3276.34,
			3279.647,
			3282.956,
			3284.611,
			3287.895,
			3291.208,
			3296.184,
			3297.873,
			3299.535,
			3302.86,
			3306.188,
			3307.852,
			3312.843,
			3316.164,
			3319.455,
			3324.431,
			3327.748,
			3332.726,
			3334.388,
			3336.05,
			3341.043,
			3344.401,
			3349.401,
			3352.736,
			3356.042,
			3357.709,
			3361.045,
			3364.378,
			3366.04,
			3369.382,
			3371.031,
			3374.311,
			3377.535,
			3382.351,
			3385.516,
			3390.192,
			3391.731,
			3393.26,
			3397.79,
			3400.764,
			3405.154,
			3408.031,
			3410.869,
			3412.273,
			3415.053,
			3416.429,
			3417.795,
			3421.839,
			3423.167,
			3424.487,
			3428.389,
			3430.943,
			3433.459,
			3434.703,
			3437.164,
			3439.587,
			3440.785,
			3443.156,
			3444.329,
			3446.648,
			3448.929,
			3452.281,
			3454.469,
			3457.687,
			3458.76,
			3459.806,
			3462.895,
			3464.914,
			3467.89,
			3469.844,
			3472.735,
			3474.643,
			3475.594,
			3476.538,
			3479.365,
			3480.306,
			3482.191,
			3484.074,
			3485.959,
			3488.809,
			3490.709,
			3492.629,
			3493.59,
			3496.485,
			3498.452,
			3500.42,
			3503.43,
			3505.453,
			3508.506,
			3509.527,
			3510.549,
			3513.618,
			3515.669,
			3518.77,
			3520.848,
			3524.005,
			3526.125,
			3527.191,
			3529.334,
			3531.488,
			3532.558,
			3534.697,
			3536.846,
			3540.037,
			3542.173,
			3545.354,
			3546.418,
			3547.484,
			3549.612,
			3550.679,
			3552.817,
			3554.953,
			3558.157,
			3560.283,
			3563.458,
			3564.508,
			3565.556,
			3568.681,
			3570.748,
			3573.837,
			3575.889,
			3577.933,
			3578.956,
			3580.991,
			3583.01,
			3584.016,
			3586.999,
			3588.958,
			3590.889,
			3593.707,
			3595.55,
			3598.269,
			3599.154,
			3600.031,
			3602.616,
			3604.296,
			3606.763,
			3608.383,
			3609.983,
			3610.779,
			3612.365,
			3613.156,
			3613.944,
			3616.299,
			3617.082,
			3617.863,
			3620.208,
			3621.767,
			3623.327,
			3624.108,
			3625.667,
			3627.224,
			3628.002,
			3629.559,
			3630.335,
			3631.886,
			3633.436,
			3635.762,
			3637.31,
			3639.638,
			3640.418,
			3641.197,
			3643.533,
			3645.102,
			3647.463,
			3649.035,
			3650.629,
			3651.427,
			3653.022,
			3653.82,
			3655.429,
			3657.042,
			3657.849,
			3658.656,
			3661.094,
			3662.726,
			3664.361,
			3665.185,
			3666.837,
			3668.494,
			3669.324,
			3671.004,
			3671.847,
			3673.536,
			3675.238,
			3677.833,
			3679.571,
			3682.23,
			3683.122,
			3684.015,
			3686.724,
			3688.546,
			3691.292,
			3693.132,
			3694.976,
			3695.898,
			3697.74,
			3698.662,
			3700.507,
			3703.272,
			3705.121,
			3706.973,
			3709.761,
			3710.693,
			3711.628,
			3714.44,
			3715.38,
			3716.328,
			3719.203,
			3721.125,
			3723.039,
			3725.919,
			3727.823,
			3730.679,
			3731.626,
			3732.565,
			3735.382,
			3737.256,
			3740.057,
			3741.922,
			3744.703,
			3746.557,
			3748.397,
			3749.317,
			3751.158,
			3752.077,
			3753.905,
			3755.734,
			3758.478,
			3760.309,
			3763.069,
			3763.994,
			3764.919,
			3767.711,
			3769.591,
			3772.425,
			3774.333,
			3776.25,
			3779.152,
			3780.125,
			3781.102,
			3785.045,
			3786.04,
			3789.041,
			3791.056,
			3794.1,
			3796.14,
			3798.193,
			3799.224,
			3802.309,
			3804.396,
			3806.474,
			3809.596,
			3811.706,
			3814.891,
			3815.958,
			3817.028,
			3820.272,
			3822.439,
			3825.689,
			3827.901,
			3830.083,
			3831.185,
			3833.408,
			3834.518,
			3836.751,
			3838.994,
			3840.121,
			3842.365,
			3844.64,
			3846.948,
			3849.226,
			3850.38,
			3852.697,
			3855.025,
			3856.195,
			3858.541,
			3859.719,
			3862.082,
			3864.456,
			3868.036,
			3870.436,
			3874.056,
			3875.268,
			3876.483,
			3880.14,
			3882.591,
			3886.287,
			3888.762,
			3891.248,
			3892.494,
			3894.993,
			3896.247,
			3898.76,
			3901.283,
			3902.548,
			3905.085,
			3907.631,
			3911.468,
			3914.037,
			3916.615,
			3919.202,
			3920.5,
			3923.102,
			3924.406,
			3927.016,
			3929.629,
			3933.563,
			3936.196,
			3940.159,
			3941.483,
			3942.81,
			3946.802,
			3949.472,
			3953.491,
			3956.18,
			3958.875,
			3960.226,
			3962.931,
			3965.644,
			3967.003,
			3969.728,
			3971.092,
			3973.827,
			3976.569,
			3980.696,
			3982.074,
			3983.455,
			3987.607,
			3988.995,
			3990.384,
			3994.562,
			3997.355,
			4001.558,
			4004.368,
			4007.185,
			4008.596,
			4011.423,
			4012.839,
			4014.257,
			4018.519,
			4019.942,
			4021.368,
			4025.654,
			4028.518,
			4031.388,
			4032.826,
			4035.706,
			4038.593,
			4040.039,
			4042.935,
			4044.385,
			4047.291,
			4050.202,
			4054.582,
			4057.509,
			4061.911,
			4063.382,
			4064.854,
			4069.278,
			4072.235,
			4076.68,
			4079.649,
			4084.114,
			4087.096,
			4088.59,
			4090.084,
			4094.575,
			4096.074,
			4099.078,
			4102.088,
			4106.612,
			4108.123,
			4109.635,
			4112.663,
			4115.697,
			4117.216,
			4120.259,
			4121.782,
			4124.832,
			4127.887,
			4132.479,
			4135.545,
			4140.156,
			4141.695,
			4143.235,
			4147.861,
			4150.951,
			4155.594,
			4158.695,
			4163.354,
			4166.467,
			4168.025,
			4171.145,
			4174.27,
			4175.834,
			4178.967,
			4182.107,
			4185.252,
			4189.978,
			4194.714,
			4196.296,
			4197.877,
			4201.044,
			4202.63,
			4205.804,
			4208.983,
			4213.756,
			4216.939,
			4221.721,
			4223.317,
			4224.915,
			4229.712,
			4232.916,
			4237.729,
			4240.943,
			4245.771,
			4248.996,
			4250.61,
			4253.84,
			4258.694,
			4261.936,
			4265.181,
			4270.057,
			4271.685,
			4273.313,
			4278.205,
			4279.837,
			4281.471,
			4286.378,
			4289.655,
			4294.579,
			4297.867,
			4302.806,
			4306.103,
			4307.753,
			4309.405,
			4314.365,
			4317.677,
			4322.652,
			4325.973,
			4330.963,
			4334.295,
			4337.63,
			4339.3,
			4344.315,
			4347.664,
			4351.016,
			4356.051,
			4359.412,
			4364.46,
			4366.145,
			4367.83,
			4372.889,
			4376.266,
			4381.339,
			4384.725,
			4388.119,
			4389.816,
			4393.217,
			4394.918,
			4398.326,
			4401.739,
			4403.446,
			4406.864,
			4410.285,
			4413.71,
			4417.138,
			4418.853,
			4422.286,
			4425.723,
			4427.443,
			4430.886,
			4432.609,
			4436.058,
			4439.511,
			4444.698,
			4448.16,
			4453.359,
			4455.095,
			4456.831,
			4462.041,
			4465.52,
			4470.743,
			4474.23,
			4477.721,
			4479.468,
			4482.963,
			4484.711,
			4488.208,
			4491.707,
			4493.455,
			4496.95,
			4500.448,
			4505.7,
			4507.453,
			4509.207,
			4512.716,
			4516.229,
			4517.987,
			4521.507,
			4523.267,
			4526.79,
			4530.317,
			4535.613,
			4539.147,
			4544.454,
			4546.225,
			4547.996,
			4553.314,
			4556.862,
			4562.177,
			4565.701,
			4569.195,
			4570.93,
			4574.369,
			4576.074,
			4579.455,
			4582.796,
			4584.451,
			4587.732,
			4590.975,
			4595.766,
			4597.343,
			4598.912,
			4603.562,
			4605.094,
			4606.618,
			4609.643,
			4611.143,
			4614.117,
			4618.509,
			4621.421,
			4624.263,
			4628.533,
			4629.952,
			4631.369,
			4635.596,
			4638.416,
			4642.567,
			4645.312,
			4648.046,
			4649.411,
			4652.152,
			4656.277,
			4659.037,
			4660.428,
			4663.23,
			4666.043,
			4670.285,
			4673.147,
			4677.445,
			4678.879,
			4680.312,
			4684.575,
			4687.395,
			4691.59,
			4694.332,
			4697.07,
			4698.423,
			4701.133,
			4702.488,
			4703.841,
			4707.907,
			4709.265,
			4710.624,
			4714.708,
			4717.438,
			4721.54,
			4724.277,
			4727.018,
			4728.393,
			4731.149,
			4732.529,
			4735.299,
			4738.074,
			4742.249,
			4745.04,
			4749.237,
			4750.636,
			4752.037,
			4756.236,
			4759.038,
			4763.25,
			4766.063,
			4768.879,
			4770.287,
			4773.101,
			4774.507,
			4777.308,
			4780.099,
			4781.49,
			4784.257,
			4787.01,
			4789.738,
			4792.444,
			4793.784,
			4796.438,
			4799.059,
			4800.338,
			4802.928,
			4804.22,
			4806.804,
			4809.395,
			4813.317,
			4815.929,
			4819.856,
			4821.168,
			4822.479,
			4826.415,
			4829.015,
			4832.952,
			4835.601,
			4838.233,
			4839.55,
			4842.189,
			4843.512,
			4846.166,
			4848.804,
			4850.139,
			4852.812,
			4855.517,
			4859.534,
			4862.237,
			4866.333,
			4867.695,
			4869.037,
			4873.152,
			4875.905,
			4878.667,
			4880.05,
			4882.819,
			4885.596,
			4889.773,
			4891.17,
			4892.568,
			4896.773,
			4898.178,
			4899.585,
			4903.816,
			4906.645,
			4910.899,
			4913.744,
			4916.597,
			4918.025,
			4920.888,
			4922.321,
			4925.193,
			4928.071,
			4932.4,
			4935.295,
			4939.649,
			4941.104,
			4942.56,
			4946.937,
			4949.862,
			4954.259,
			4957.191,
			4960.113,
			4964.452,
			4965.883,
			4967.306,
			4972.897,
			4974.271,
			4978.335,
			4980.997,
			4983.62,
			4984.918,
			4987.484,
			4990.012,
			4991.261,
			4994.952,
			4997.366,
			4999.744,
			5003.245,
			5005.535,
			5008.896,
			5009.998,
			5011.09,
			5014.309,
			5016.408,
			5019.486,
			5021.492,
			5023.459,
			5024.429,
			5026.341,
			5027.283,
			5029.139,
			5030.96,
			5031.858,
			5033.627,
			5035.361,
			5037.058,
			5038.713,
			5039.526,
			5041.124,
			5042.684,
			5043.446,
			5044.945,
			5045.68,
			5047.125,
			5048.533,
			5050.58,
			5051.903,
			5053.83,
			5054.458,
			5055.078,
			5056.88,
			5058.039,
			5059.728,
			5060.83,
			5061.918,
			5062.459,
			5063.538,
			5064.612,
			5065.146,
			5066.21,
			5066.748,
			5067.801,
			5068.854,
			5070.418,
			5070.934,
			5071.447,
			5072.979,
			5073.488,
			5073.995,
			5074.999,
			5075.497,
			5076.487,
			5077.967,
			5078.943,
			5079.903,
			5081.337,
			5081.813,
			5082.29,
			5083.723,
			5084.702,
			5086.163,
			5087.137,
			5088.602,
			5089.708,
			5090.271,
			5091.395,
			5092.522,
			5093.087,
			5094.321,
			5095.728,
			5097.825,
			5099.218,
			5101.267,
			5101.948,
			5102.627,
			5104.546,
			5105.68,
			5107.391,
			5108.541,
			5109.657,
			5110.197,
			5111.28,
			5111.823,
			5112.368,
			5113.999,
			5114.55,
			5115.104,
			5116.792,
			5117.93,
			5119.083,
			5119.666,
			5120.842,
			5122.028,
			5122.625,
			5124.423,
			5125.632,
			5126.841,
			5128.658,
			5129.876,
			5131.716,
			5132.33,
			5132.945,
			5134.803,
			5136.121,
			5138.098,
			5139.419,
			5140.873,
			5141.632,
			5143.15,
			5143.909,
			5145.489,
			5147.227,
			5148.096,
			5149.834,
			5151.54,
			5154.081,
			5154.93,
			5155.73,
			5157.142,
			5158.551,
			5159.255,
			5161.309,
			5162.576,
			5164.49,
			5165.777,
			5167.066,
			5169.013,
			5169.666,
			5170.322,
			5172.349,
			5173.727,
			5175.817,
			5177.259,
			5179.454,
			5180.937,
			5181.687,
			5183.208,
			5184.747,
			5185.521,
			5187.084,
			5188.651,
			5191.051,
			5191.859,
			5192.674,
			5195.144,
			5195.975,
			5196.81,
			5199.338,
			5201.042,
			5202.761,
			5205.36,
			5207.092,
			5209.732,
			5210.605,
			5211.497,
			5215.121,
			5216.032,
			5218.785,
			5220.646,
			5222.517,
			5223.456,
			5225.347,
			5226.296,
			5228.202,
			5230.112,
			5231.069,
			5232.989,
			5234.921,
			5237.834,
			5239.782,
			5242.705,
			5243.681,
			5244.658,
			5247.592,
			5249.55,
			5252.477,
			5254.427,
			5257.341,
			5259.282,
			5260.253,
			5261.222,
			5264.128,
			5265.096,
			5266.064,
			5268.97,
			5270.909,
			5272.852,
			5273.825,
			5275.762,
			5277.729,
			5278.718,
			5280.706,
			5281.723,
			5283.735,
			5285.763,
			5288.831,
			5290.893,
			5294.017,
			5295.066,
			5296.12,
			5299.302,
			5301.441,
			5304.665,
			5306.818,
			5308.983,
			5310.071,
			5312.256,
			5314.432,
			5315.531,
			5317.755,
			5318.863,
			5321.075,
			5323.293,
			5326.633,
			5327.748,
			5328.866,
			5332.234,
			5333.362,
			5334.492,
			5336.759,
			5337.896,
			5340.176,
			5342.465,
			5345.917,
			5348.23,
			5351.721,
			5352.888,
			5354.058,
			5357.582,
			5359.942,
			5363.5,
			5365.885,
			5369.479,
			5371.868,
			5373.075,
			5375.497,
			5377.928,
			5379.167,
			5381.611,
			5384.065,
			5387.761,
			5388.998,
			5390.235,
			5393.94,
			5395.188,
			5396.436,
			5400.217,
			5402.735,
			5405.242,
			5409.048,
			5411.596,
			5415.431,
			5416.713,
			5417.997,
			5421.859,
			5424.44,
			5428.331,
			5430.899,
			5433.496,
			5434.798,
			5437.406,
			5440.022,
			5441.333,
			5443.962,
			5445.279,
			5447.923,
			5450.575,
			5454.567,
			5457.239,
			5461.262,
			5462.606,
			5463.953,
			5468.006,
			5470.718,
			5474.809,
			5477.551,
			5480.307,
			5484.463,
			5485.854,
			5487.246,
			5492.836,
			5494.237,
			5498.452,
			5501.271,
			5504.096,
			5505.511,
			5508.346,
			5511.187,
			5512.609,
			5516.889,
			5519.75,
			5522.618,
			5526.935,
			5529.822,
			5534.171,
			5535.625,
			5537.082,
			5541.465,
			5544.397,
			5548.81,
			5551.76,
			5556.197,
			5559.164,
			5560.649,
			5563.626,
			5568.105,
			5571.101,
			5574.103,
			5577.112,
			5580.126,
			5581.636,
			5584.657,
			5587.685,
			5589.2,
			5593.755,
			5596.797,
			5599.846,
			5604.433,
			5607.496,
			5612.101,
			5613.64,
			5615.18,
			5619.811,
			5622.905,
			5627.556,
			5630.667,
			5633.786,
			5635.348,
			5638.478,
			5640.045,
			5643.185,
			5647.909,
			5651.067,
			5654.231,
			5658.989,
			5660.578,
			5662.168,
			5665.353,
			5668.542,
			5670.137,
			5673.328,
			5674.926,
			5678.125,
			5681.33,
			5686.146,
			5689.361,
			5694.195,
			5695.809,
			5697.423,
			5702.274,
			5705.514,
			5710.382,
			5713.634,
			5716.891,
			5718.521,
			5721.784,
			5723.418,
			5726.688,
			5729.964,
			5731.604,
			5734.888,
			5738.176,
			5743.116,
			5744.765,
			5746.414,
			5751.37,
			5753.024,
			5754.679,
			5759.652,
			5762.975,
			5767.966,
			5771.299,
			5774.64,
			5779.661,
			5781.337,
			5783.015,
			5788.054,
			5789.736,
			5791.419,
			5796.475,
			5799.852,
			5801.727
		],
		[
			0,
			0.016,
			0.078,
			0.092,
			0.14,
			0.202,
			0.263,
			0.309,
			0.388,
			0.418,
			0.449,
			0.513,
			0.575,
			0.636,
			0.699,
			0.762,
			0.822,
			0.853,
			0.884,
			0.946,
			0.961,
			1.009,
			1.07,
			1.132,
			1.18,
			1.194,
			1.258,
			1.286,
			1.319,
			1.381,
			1.396,
			1.442,
			1.505,
			1.565,
			1.613,
			1.691,
			1.722,
			1.752,
			1.815,
			1.876,
			1.937,
			2.002,
			2.048,
			2.062,
			2.125,
			2.156,
			2.187,
			2.249,
			2.263,
			2.31,
			2.373,
			2.435,
			2.481,
			2.496,
			2.559,
			2.593,
			2.619,
			2.699,
			2.746,
			2.807,
			2.869,
			2.914,
			2.992,
			3.025,
			3.054,
			3.118,
			3.179,
			3.241,
			3.302,
			3.366,
			3.428,
			3.458,
			3.488,
			3.552,
			3.566,
			3.612,
			3.675,
			3.736,
			3.799,
			3.862,
			3.893,
			3.921,
			4,
			4.048,
			4.11,
			4.17,
			4.216,
			4.234,
			4.296,
			4.327,
			4.355,
			4.435,
			4.482,
			4.542,
			4.606,
			4.666,
			4.73,
			4.759,
			4.792,
			4.868,
			4.915,
			4.98,
			5.04,
			5.085,
			5.164,
			5.195,
			5.225,
			5.285,
			5.35,
			5.412,
			5.474,
			5.519,
			5.536,
			5.598,
			5.629,
			5.659,
			5.72,
			5.738,
			5.784,
			5.847,
			5.908,
			5.954,
			5.968,
			6.031,
			6.062,
			6.092,
			6.156,
			6.17,
			6.218,
			6.281,
			6.341,
			6.388,
			6.465,
			6.497,
			6.528,
			6.587,
			6.652,
			6.718,
			6.777,
			6.821,
			6.838,
			6.9,
			6.931,
			6.962,
			7.025,
			7.039,
			7.085,
			7.148,
			7.21,
			7.257,
			7.271,
			7.332,
			7.364,
			7.396,
			7.458,
			7.519,
			7.587,
			7.645,
			7.689,
			7.768,
			7.798,
			7.83,
			7.908,
			7.952,
			8.015,
			8.077,
			8.125,
			8.141,
			8.201,
			8.233,
			8.265,
			8.325,
			8.342,
			8.387,
			8.449,
			8.513,
			8.558,
			8.635,
			8.666,
			8.699,
			8.76,
			8.819,
			8.884,
			8.945,
			9.008,
			9.071,
			9.102,
			9.131,
			9.193,
			9.211,
			9.254,
			9.319,
			9.381,
			9.425,
			9.442,
			9.504,
			9.535,
			9.566,
			9.629,
			9.643,
			9.688,
			9.752,
			9.814,
			9.876,
			9.936,
			9.968,
			9.999,
			10.077,
			10.125,
			10.187,
			10.248,
			10.31,
			10.371,
			10.403,
			10.434,
			10.496,
			10.512,
			10.557,
			10.621,
			10.682,
			10.743,
			10.807,
			10.841,
			10.869,
			10.929,
			10.947,
			10.993,
			11.054,
			11.116,
			11.179,
			11.24,
			11.27,
			11.302,
			11.363,
			11.426,
			11.487,
			11.549,
			11.596,
			11.613,
			11.674,
			11.706,
			11.738,
			11.799,
			11.815,
			11.861,
			11.923,
			11.984,
			12.03,
			12.047,
			12.108,
			12.14,
			12.17,
			12.247,
			12.295,
			12.355,
			12.42,
			12.481,
			12.544,
			12.574,
			12.604,
			12.667,
			12.729,
			12.791,
			12.852,
			12.903,
			12.913,
			12.976,
			13.008,
			13.039,
			13.099,
			13.117,
			13.162,
			13.226,
			13.288,
			13.349,
			13.41,
			13.44,
			13.472,
			13.534,
			13.597,
			13.657,
			13.719,
			13.766,
			13.843,
			13.876,
			13.907,
			13.968,
			13.985,
			14.03,
			14.093,
			14.156,
			14.202,
			14.215,
			14.278,
			14.311,
			14.341,
			14.418,
			14.465,
			14.528,
			14.587,
			14.635,
			14.713,
			14.744,
			14.774,
			14.838,
			14.9,
			14.967,
			15.024,
			15.07,
			15.085,
			15.147,
			15.179,
			15.208,
			15.271,
			15.286,
			15.334,
			15.396,
			15.457,
			15.519,
			15.581,
			15.611,
			15.643,
			15.704,
			15.721,
			15.767,
			15.83,
			15.891,
			15.954,
			16.015,
			16.046,
			16.078,
			16.154,
			16.201,
			16.263,
			16.326,
			16.371,
			16.386,
			16.45,
			16.479,
			16.51,
			16.587,
			16.635,
			16.696,
			16.759,
			16.819,
			16.883,
			16.913,
			16.945,
			17.007,
			17.028,
			17.07,
			17.135,
			17.192,
			17.254,
			17.316,
			17.348,
			17.38,
			17.441,
			17.458,
			17.504,
			17.566,
			17.626,
			17.688,
			17.752,
			17.783,
			17.813,
			17.876,
			17.892,
			17.936,
			17.999,
			18.062,
			18.108,
			18.187,
			18.216,
			18.247,
			18.31,
			18.372,
			18.434,
			18.495,
			18.543,
			18.619,
			18.651,
			18.682,
			18.742,
			18.759,
			18.805,
			18.868,
			18.931,
			18.975,
			18.992,
			19.054,
			19.091,
			19.115,
			19.178,
			19.193,
			19.24,
			19.302,
			19.364,
			19.425,
			19.488,
			19.519,
			19.549,
			19.61,
			19.674,
			19.736,
			19.799,
			19.843,
			19.86,
			19.92,
			19.954,
			19.984,
			20.046,
			20.062,
			20.107,
			20.173,
			20.232,
			20.278,
			20.293,
			20.355,
			20.385,
			20.419,
			20.497,
			20.541,
			20.603,
			20.666,
			20.728,
			20.788,
			20.819,
			20.853,
			20.914,
			20.976,
			21.037,
			21.099,
			21.161,
			21.224,
			21.255,
			21.286,
			21.348,
			21.364,
			21.41,
			21.473,
			21.535,
			21.58,
			21.596,
			21.658,
			21.689,
			21.719,
			21.781,
			21.798,
			21.843,
			21.906,
			21.969,
			22.016,
			22.093,
			22.121,
			22.155,
			22.215,
			22.233,
			22.278,
			22.34,
			22.401,
			22.449,
			22.465,
			22.526,
			22.557,
			22.587,
			22.649,
			22.665,
			22.712,
			22.776,
			22.835,
			22.883,
			22.959,
			22.992,
			23.021,
			23.083,
			23.148,
			23.213,
			23.269,
			23.316,
			23.394,
			23.424,
			23.456,
			23.519,
			23.58,
			23.643,
			23.703,
			23.752,
			23.767,
			23.83,
			23.859,
			23.891,
			23.969,
			24.015,
			24.078,
			24.139,
			24.202,
			24.262,
			24.293,
			24.326,
			24.386,
			24.448,
			24.51,
			24.574,
			24.618,
			24.635,
			24.696,
			24.726,
			24.759,
			24.821,
			24.836,
			24.882,
			24.946,
			25.007,
			25.069,
			25.129,
			25.162,
			25.192,
			25.256,
			25.275,
			25.318,
			25.377,
			25.441,
			25.487,
			25.563,
			25.595,
			25.625,
			25.704,
			25.75,
			25.814,
			25.875,
			25.921,
			25.935,
			25.999,
			26.03,
			26.06,
			26.124,
			26.138,
			26.185,
			26.248,
			26.31,
			26.37,
			26.433,
			26.463,
			26.496,
			26.557,
			26.573,
			26.619,
			26.682,
			26.743,
			26.803,
			26.867,
			26.898,
			26.93,
			26.991,
			27.052,
			27.116,
			27.176,
			27.225,
			27.239,
			27.302,
			27.335,
			27.363,
			27.425,
			27.442,
			27.487,
			27.552,
			27.612,
			27.658,
			27.736,
			27.766,
			27.798,
			27.86,
			27.921,
			27.984,
			28.046,
			28.092,
			28.169,
			28.201,
			28.23,
			28.293,
			28.31,
			28.353,
			28.418,
			28.478,
			28.526,
			28.541,
			28.604,
			28.633,
			28.666,
			28.726,
			28.743,
			28.787,
			28.853,
			28.914,
			28.96,
			29.037,
			29.069,
			29.1,
			29.163,
			29.224,
			29.285,
			29.347,
			29.409,
			29.473,
			29.502,
			29.533,
			29.596,
			29.611,
			29.658,
			29.721,
			29.781,
			29.843,
			29.903,
			29.935,
			29.968,
			30.029,
			30.046,
			30.093,
			30.153,
			30.217,
			30.263,
			30.341,
			30.369,
			30.402,
			30.478,
			30.527,
			30.593,
			30.65,
			30.713,
			30.774,
			30.805,
			30.836,
			30.899,
			30.913,
			30.958,
			31.021,
			31.082,
			31.129,
			31.145,
			31.208,
			31.237,
			31.269,
			31.333,
			31.393,
			31.454,
			31.46,
			31.519,
			31.563,
			31.642,
			31.674,
			31.705,
			31.766,
			31.829,
			31.891,
			31.951,
			32,
			32.014,
			32.076,
			32.107,
			32.137,
			32.199,
			32.215,
			32.263,
			32.326,
			32.385,
			32.432,
			32.449,
			32.511,
			32.541,
			32.572,
			32.651,
			32.697,
			32.759,
			32.821,
			32.867,
			32.943,
			32.976,
			33.007,
			33.067,
			33.085,
			33.131,
			33.193,
			33.254,
			33.299,
			33.316,
			33.379,
			33.41,
			33.44,
			33.502,
			33.521,
			33.563,
			33.626,
			33.688,
			33.733,
			33.812,
			33.842,
			33.874,
			33.936,
			33.998,
			34.06,
			34.123,
			34.168,
			34.183,
			34.246,
			34.276,
			34.308,
			34.384,
			34.432,
			34.496,
			34.558,
			34.619,
			34.68,
			34.712,
			34.743,
			34.803,
			34.819,
			34.865,
			34.929,
			34.991,
			35.052,
			35.113,
			35.145,
			35.176,
			35.239,
			35.302,
			35.363,
			35.425,
			35.472,
			35.488,
			35.549,
			35.584,
			35.611,
			35.674,
			35.688,
			35.735,
			35.798,
			35.858,
			35.905,
			35.921,
			35.982,
			36.014,
			36.046,
			36.104,
			36.123,
			36.17,
			36.232,
			36.292,
			36.356,
			36.416,
			36.448,
			36.479,
			36.541,
			36.602,
			36.666,
			36.727,
			36.774,
			36.788,
			36.85,
			36.881,
			36.912,
			36.975,
			36.991,
			37.035,
			37.099,
			37.159,
			37.207,
			37.221,
			37.285,
			37.316,
			37.348,
			37.409,
			37.469,
			37.534,
			37.594,
			37.647,
			37.658,
			37.718,
			37.749,
			37.782,
			37.844,
			37.859,
			37.905,
			37.967,
			38.029,
			38.091,
			38.153,
			38.185,
			38.214,
			38.276,
			38.293,
			38.339,
			38.403,
			38.463,
			38.525,
			38.588,
			38.618,
			38.649,
			38.711,
			38.774,
			38.836,
			38.896,
			38.944,
			38.96,
			39.021,
			39.052,
			39.082,
			39.159,
			39.208,
			39.269,
			39.331,
			39.377,
			39.394,
			39.454,
			39.487,
			39.518,
			39.579,
			39.593,
			39.642,
			39.703,
			39.708,
			39.766,
			39.812,
			39.887,
			39.921,
			39.951,
			40.012,
			40.076,
			40.137,
			40.199,
			40.247,
			40.261,
			40.321,
			40.353,
			40.385,
			40.447,
			40.464,
			40.509,
			40.572,
			40.634,
			40.68,
			40.695,
			40.758,
			40.787,
			40.819,
			40.896,
			40.945,
			41.007,
			41.067,
			41.113,
			41.191,
			41.221,
			41.254,
			41.316,
			41.379,
			41.441,
			41.501,
			41.549,
			41.564,
			41.627,
			41.656,
			41.688,
			41.751,
			41.771,
			41.812,
			41.874,
			41.934,
			41.981,
			41.999,
			42.06,
			42.091,
			42.12,
			42.184,
			42.245,
			42.308,
			42.371,
			42.432,
			42.494,
			42.525,
			42.555,
			42.633,
			42.678,
			42.742,
			42.803,
			42.849,
			42.867,
			42.929,
			42.959,
			42.991,
			43.068,
			43.115,
			43.175,
			43.236,
			43.284,
			43.362,
			43.393,
			43.425,
			43.485,
			43.548,
			43.61,
			43.672,
			43.719,
			43.734,
			43.795,
			43.832,
			43.858,
			43.919,
			43.936,
			43.981,
			44.044,
			44.107,
			44.153,
			44.168,
			44.23,
			44.262,
			44.293,
			44.353,
			44.37,
			44.417,
			44.477,
			44.54,
			44.588,
			44.664,
			44.695,
			44.727,
			44.805,
			44.849,
			44.912,
			44.975,
			45.022,
			45.037,
			45.097,
			45.129,
			45.16,
			45.221,
			45.236,
			45.284,
			45.345,
			45.408,
			45.454,
			45.469,
			45.533,
			45.563,
			45.595,
			45.673,
			45.718,
			45.781,
			45.842,
			45.902,
			45.967,
			45.997,
			46.027,
			46.091,
			46.152,
			46.216,
			46.276,
			46.336,
			46.402,
			46.43,
			46.462,
			46.524,
			46.538,
			46.587,
			46.648,
			46.711,
			46.758,
			46.774,
			46.834,
			46.866,
			46.895,
			46.958,
			46.973,
			47.021,
			47.084,
			47.144,
			47.191,
			47.269,
			47.301,
			47.33,
			47.392,
			47.408,
			47.456,
			47.518,
			47.577,
			47.626,
			47.641,
			47.703,
			47.734,
			47.763,
			47.826,
			47.841,
			47.888,
			47.954,
			48.013,
			48.06,
			48.136,
			48.167,
			48.2,
			48.26,
			48.323,
			48.385,
			48.448,
			48.494,
			48.508,
			48.569,
			48.601,
			48.632,
			48.693,
			48.71,
			48.758,
			48.819,
			48.882,
			48.927,
			48.942,
			49.006,
			49.036,
			49.066,
			49.144,
			49.192,
			49.255,
			49.315,
			49.36,
			49.44,
			49.469,
			49.5,
			49.562,
			49.625,
			49.687,
			49.748,
			49.796,
			49.812,
			49.874,
			49.904,
			49.936,
			49.998,
			50.015,
			50.058,
			50.122,
			50.184,
			50.23,
			50.244,
			50.307,
			50.339,
			50.37,
			50.43,
			50.447,
			50.494,
			50.554,
			50.616,
			50.68,
			50.742,
			50.773,
			50.803,
			50.879,
			50.927,
			50.991,
			51.051,
			51.112,
			51.176,
			51.204,
			51.237,
			51.299,
			51.315,
			51.363,
			51.425,
			51.485,
			51.531,
			51.548,
			51.61,
			51.642,
			51.67,
			51.732,
			51.748,
			51.797,
			51.858,
			51.919,
			51.966,
			52.043,
			52.079,
			52.105,
			52.168,
			52.228,
			52.292,
			52.352,
			52.399,
			52.416,
			52.477,
			52.509,
			52.54,
			52.616,
			52.662,
			52.727,
			52.789,
			52.834,
			52.911,
			52.942,
			52.974,
			53.037,
			53.096,
			53.16,
			53.223,
			53.268,
			53.344,
			53.375,
			53.409,
			53.486,
			53.532,
			53.593,
			53.655,
			53.719,
			53.778,
			53.812,
			53.842,
			53.903,
			53.919,
			53.965,
			54.028,
			54.091,
			54.14,
			54.214,
			54.245,
			54.276,
			54.354,
			54.398,
			54.462,
			54.525,
			54.57,
			54.587,
			54.648,
			54.678,
			54.709,
			54.773,
			54.786,
			54.835,
			54.896,
			54.959,
			55.003,
			55.02,
			55.081,
			55.114,
			55.143,
			55.207,
			55.223,
			55.268,
			55.331,
			55.391,
			55.438,
			55.515,
			55.546,
			55.578,
			55.656,
			55.702,
			55.765,
			55.827,
			55.871,
			55.889,
			55.949,
			55.981,
			56.013,
			56.09,
			56.137,
			56.199,
			56.259,
			56.307,
			56.323,
			56.384,
			56.416,
			56.445,
			56.509,
			56.569,
			56.634,
			56.694,
			56.741,
			56.819,
			56.85,
			56.88,
			56.958,
			57.002,
			57.065,
			57.129,
			57.175,
			57.191,
			57.253,
			57.283,
			57.315,
			57.392,
			57.439,
			57.503,
			57.562,
			57.61,
			57.686,
			57.719,
			57.748,
			57.811,
			57.87,
			57.936,
			57.996,
			58.042,
			58.06,
			58.12,
			58.152,
			58.182,
			58.245,
			58.265,
			58.304,
			58.368,
			58.429,
			58.476,
			58.493,
			58.553,
			58.585,
			58.616,
			58.694,
			58.742,
			58.802,
			58.865,
			58.928,
			58.988,
			59.02,
			59.051,
			59.112,
			59.176,
			59.236,
			59.299,
			59.345,
			59.359,
			59.423,
			59.454,
			59.485,
			59.545,
			59.563,
			59.609,
			59.669,
			59.733,
			59.779,
			59.794,
			59.857,
			59.889,
			59.918,
			59.981,
			59.995,
			60.041,
			60.102,
			60.11,
			60.165,
			60.229,
			60.292,
			60.326,
			60.352,
			60.414,
			60.478,
			60.54,
			60.602,
			60.662,
			60.726,
			60.756,
			60.787,
			60.849,
			60.863,
			60.912,
			60.974,
			61.034,
			61.083,
			61.097,
			61.158,
			61.19,
			61.22,
			61.283,
			61.346,
			61.409,
			61.47,
			61.516,
			61.592,
			61.625,
			61.654,
			61.716,
			61.734,
			61.78,
			61.841,
			61.903,
			61.95,
			61.965,
			62.026,
			62.06,
			62.088,
			62.166,
			62.212,
			62.276,
			62.336,
			62.398,
			62.46,
			62.491,
			62.524,
			62.584,
			62.646,
			62.709,
			62.771,
			62.832,
			62.896,
			62.925,
			62.958,
			63.018,
			63.035,
			63.082,
			63.143,
			63.204,
			63.251,
			63.269,
			63.33,
			63.36,
			63.392,
			63.453,
			63.47,
			63.517,
			63.578,
			63.639,
			63.686,
			63.764,
			63.795,
			63.826,
			63.904,
			63.951,
			64.01,
			64.074,
			64.121,
			64.137,
			64.199,
			64.228,
			64.259,
			64.336,
			64.384,
			64.452,
			64.508,
			64.555,
			64.569,
			64.632,
			64.663,
			64.695,
			64.771,
			64.818,
			64.879,
			64.941,
			64.989,
			65.066,
			65.097,
			65.129,
			65.187,
			65.253,
			65.315,
			65.374,
			65.436,
			65.498,
			65.53,
			65.561,
			65.624,
			65.638,
			65.687,
			65.748,
			65.808,
			65.872,
			65.934,
			65.965,
			65.997,
			66.059,
			66.119,
			66.181,
			66.244,
			66.291,
			66.367,
			66.398,
			66.43,
			66.512,
			66.552,
			66.619,
			66.678,
			66.726,
			66.74,
			66.802,
			66.834,
			66.865,
			66.927,
			66.942,
			66.989,
			67.049,
			67.113,
			67.16,
			67.236,
			67.267,
			67.298,
			67.36,
			67.42,
			67.486,
			67.545,
			67.609,
			67.669,
			67.7,
			67.732,
			67.794,
			67.809,
			67.857,
			67.919,
			67.98,
			68.027,
			68.041,
			68.105,
			68.135,
			68.166,
			68.229,
			68.243,
			68.291,
			68.352,
			68.415,
			68.46,
			68.539,
			68.573,
			68.6,
			68.661,
			68.724,
			68.785,
			68.848,
			68.894,
			68.911,
			68.971,
			69.003,
			69.035,
			69.097,
			69.111,
			69.158,
			69.221,
			69.284,
			69.344,
			69.408,
			69.438,
			69.469,
			69.532,
			69.546,
			69.593,
			69.654,
			69.717,
			69.763,
			69.839,
			69.87,
			69.902,
			69.981,
			70.027,
			70.087,
			70.15,
			70.198,
			70.213,
			70.275,
			70.304,
			70.336,
			70.414,
			70.459,
			70.521,
			70.585,
			70.647,
			70.707,
			70.74,
			70.77,
			70.849,
			70.895,
			70.958,
			71.019,
			71.079,
			71.142,
			71.174,
			71.203,
			71.268,
			71.329,
			71.392,
			71.451,
			71.499,
			71.515,
			71.577,
			71.608,
			71.638,
			71.715,
			71.763,
			71.826,
			71.888,
			71.949,
			72.012,
			72.041,
			72.073,
			72.15,
			72.197,
			72.261,
			72.321,
			72.367,
			72.384,
			72.445,
			72.477,
			72.507,
			72.568,
			72.584,
			72.632,
			72.698,
			72.755,
			72.818,
			72.88,
			72.909,
			72.94,
			73.004,
			73.019,
			73.065,
			73.128,
			73.189,
			73.251,
			73.313,
			73.345,
			73.376,
			73.436,
			73.499,
			73.562,
			73.624,
			73.685,
			73.746,
			73.778,
			73.809,
			73.87,
			73.888,
			73.934,
			73.995,
			74.058,
			74.119,
			74.18,
			74.212,
			74.243,
			74.304,
			74.323,
			74.368,
			74.43,
			74.492,
			74.539,
			74.617,
			74.645,
			74.677,
			74.738,
			74.76,
			74.801,
			74.865,
			74.925,
			74.988,
			75.049,
			75.08,
			75.112,
			75.173,
			75.187,
			75.236,
			75.297,
			75.359,
			75.406,
			75.42,
			75.483,
			75.513,
			75.545,
			75.608,
			75.669,
			75.732,
			75.792,
			75.856,
			75.918,
			75.949,
			75.98,
			76.057,
			76.103,
			76.167,
			76.226,
			76.275,
			76.287,
			76.352,
			76.383,
			76.414,
			76.475,
			76.491,
			76.536,
			76.599,
			76.663,
			76.708,
			76.785,
			76.823,
			76.848,
			76.911,
			76.973,
			77.033,
			77.095,
			77.143,
			77.158,
			77.22,
			77.249,
			77.281,
			77.344,
			77.358,
			77.407,
			77.468,
			77.531,
			77.576,
			77.592,
			77.653,
			77.684,
			77.716,
			77.779,
			77.792,
			77.841,
			77.904,
			77.963,
			78.026,
			78.087,
			78.119,
			78.15,
			78.213,
			78.274,
			78.337,
			78.398,
			78.46,
			78.523,
			78.554,
			78.583,
			78.646,
			78.661,
			78.708,
			78.771,
			78.831,
			78.884,
			78.956,
			78.988,
			79.019,
			79.096,
			79.143,
			79.204,
			79.265,
			79.329,
			79.391,
			79.42,
			79.453,
			79.53,
			79.577,
			79.637,
			79.7,
			79.763,
			79.825,
			79.856,
			79.885,
			79.949,
			79.964,
			80.01,
			80.071,
			80.134,
			80.196,
			80.258,
			80.29,
			80.319,
			80.381,
			80.399,
			80.446,
			80.508,
			80.568,
			80.616,
			80.693,
			80.721,
			80.754,
			80.816,
			80.878,
			80.944,
			81.004,
			81.05,
			81.065,
			81.125,
			81.158,
			81.186,
			81.267,
			81.313,
			81.376,
			81.437,
			81.484,
			81.562,
			81.59,
			81.623,
			81.685,
			81.745,
			81.809,
			81.871,
			81.916,
			81.933,
			81.996,
			82.025,
			82.057,
			82.118,
			82.135,
			82.181,
			82.244,
			82.305,
			82.352,
			82.367,
			82.429,
			82.46,
			82.491,
			82.552,
			82.569,
			82.615,
			82.678,
			82.739,
			82.784,
			82.862,
			82.894,
			82.924,
			82.988,
			83.049,
			83.112,
			83.173,
			83.219,
			83.235,
			83.298,
			83.328,
			83.359,
			83.42,
			83.437,
			83.483,
			83.545,
			83.608,
			83.653,
			83.67,
			83.731,
			83.763,
			83.794,
			83.854,
			83.871,
			83.917,
			83.978,
			84.041,
			84.103,
			84.166,
			84.195,
			84.226,
			84.289,
			84.351,
			84.412,
			84.475,
			84.52,
			84.537,
			84.6,
			84.629,
			84.662,
			84.738,
			84.784,
			84.846,
			84.91,
			84.954,
			84.971,
			85.033,
			85.067,
			85.096,
			85.158,
			85.218,
			85.28,
			85.342,
			85.406,
			85.466,
			85.497,
			85.529,
			85.592,
			85.654,
			85.716,
			85.776,
			85.84,
			85.902,
			85.933,
			85.964,
			86.025,
			86.041,
			86.088,
			86.151,
			86.213,
			86.258,
			86.335,
			86.366,
			86.398,
			86.459,
			86.521,
			86.584,
			86.644,
			86.693,
			86.771,
			86.802,
			86.83,
			86.91,
			86.956,
			87.018,
			87.078,
			87.141,
			87.204,
			87.235,
			87.265,
			87.343,
			87.387,
			87.452,
			87.515,
			87.56,
			87.636,
			87.668,
			87.698,
			87.776,
			87.824,
			87.886,
			87.947,
			87.993,
			88.009,
			88.072,
			88.102,
			88.133,
			88.197,
			88.211,
			88.258,
			88.321,
			88.382,
			88.428,
			88.442,
			88.507,
			88.537,
			88.569,
			88.629,
			88.647,
			88.692,
			88.755,
			88.815,
			88.879,
			88.937,
			88.972,
			89.003,
			89.078,
			89.126,
			89.192,
			89.25,
			89.297,
			89.311,
			89.374,
			89.403,
			89.437,
			89.498,
			89.512,
			89.56,
			89.621,
			89.684,
			89.745,
			89.807,
			89.84,
			89.87,
			89.931,
			89.947,
			89.994,
			90.054,
			90.119,
			90.179,
			90.243,
			90.274,
			90.302,
			90.365,
			90.429,
			90.491,
			90.552,
			90.598,
			90.615,
			90.676,
			90.708,
			90.736,
			90.799,
			90.816,
			90.862,
			90.924,
			90.986,
			91.032,
			91.048,
			91.111,
			91.142,
			91.172,
			91.235,
			91.295,
			91.359,
			91.419,
			91.466,
			91.483,
			91.545,
			91.576,
			91.607,
			91.669,
			91.684,
			91.729,
			91.792,
			91.853,
			91.902,
			91.916,
			91.979,
			92.009,
			92.041,
			92.102,
			92.117,
			92.166,
			92.226,
			92.289,
			92.349,
			92.411,
			92.445,
			92.475,
			92.552,
			92.598,
			92.665,
			92.721,
			92.785,
			92.846,
			92.879,
			92.907,
			92.969,
			92.985,
			93.033,
			93.096,
			93.158,
			93.203,
			93.22,
			93.282,
			93.316,
			93.342,
			93.405,
			93.419,
			93.466,
			93.529,
			93.592,
			93.652,
			93.713,
			93.744,
			93.775,
			93.839,
			93.899,
			93.962,
			94.025,
			94.085,
			94.15,
			94.178,
			94.209,
			94.273,
			94.289,
			94.335,
			94.398,
			94.458,
			94.522,
			94.583,
			94.615,
			94.646,
			94.708,
			94.722,
			94.77,
			94.833,
			94.893,
			94.938,
			95.016,
			95.048,
			95.078,
			95.142,
			95.204,
			95.265,
			95.327,
			95.388,
			95.451,
			95.482,
			95.514,
			95.591,
			95.636,
			95.701,
			95.761,
			95.807,
			95.824,
			95.885,
			95.915,
			95.947,
			96.009,
			96.072,
			96.134,
			96.196,
			96.258,
			96.319,
			96.351,
			96.381,
			96.444,
			96.506,
			96.566,
			96.63,
			96.69,
			96.752,
			96.785,
			96.814,
			96.892,
			96.937,
			97,
			97.063,
			97.11,
			97.187,
			97.218,
			97.249,
			97.31,
			97.374,
			97.436,
			97.499,
			97.543,
			97.559,
			97.621,
			97.653,
			97.684,
			97.746,
			97.76,
			97.809,
			97.87,
			97.93,
			97.979,
			97.993,
			98.055,
			98.086,
			98.118,
			98.181,
			98.195,
			98.243,
			98.304,
			98.367,
			98.411,
			98.487,
			98.519,
			98.552,
			98.614,
			98.676,
			98.738,
			98.799,
			98.845,
			98.863,
			98.921,
			98.955,
			98.987,
			99.047,
			99.062,
			99.108,
			99.173,
			99.233,
			99.279,
			99.296,
			99.358,
			99.387,
			99.419,
			99.482,
			99.501,
			99.543,
			99.606,
			99.667,
			99.715,
			99.792,
			99.824,
			99.852,
			99.916,
			99.977,
			100.041,
			100.101,
			100.15,
			100.163,
			100.225,
			100.258,
			100.288,
			100.349,
			100.365,
			100.412,
			100.474,
			100.537,
			100.582,
			100.597,
			100.66,
			100.692,
			100.722,
			100.783,
			100.799,
			100.846,
			100.908,
			100.971,
			101.032,
			101.093,
			101.126,
			101.156,
			101.218,
			101.281,
			101.343,
			101.405,
			101.452,
			101.466,
			101.528,
			101.59,
			101.653,
			101.668,
			101.714,
			101.777,
			101.838,
			101.885,
			101.963,
			101.993,
			102.025,
			102.102,
			102.148,
			102.216,
			102.27,
			102.318,
			102.333,
			102.396,
			102.426,
			102.457,
			102.519,
			102.536,
			102.582,
			102.645,
			102.708,
			102.769,
			102.829,
			102.862,
			102.893,
			102.954,
			102.969,
			103.016,
			103.078,
			103.141,
			103.187,
			103.266,
			103.296,
			103.327,
			103.388,
			103.449,
			103.513,
			103.574,
			103.626,
			103.638,
			103.7,
			103.728,
			103.762,
			103.822,
			103.838,
			103.885,
			103.946,
			104.008,
			104.053,
			104.07,
			104.132,
			104.163,
			104.196,
			104.257,
			104.271,
			104.318,
			104.381,
			104.442,
			104.487,
			104.568,
			104.596,
			104.629,
			104.692,
			104.752,
			104.816,
			104.877,
			104.924,
			104.94,
			105.002,
			105.031,
			105.061,
			105.125,
			105.14,
			105.188,
			105.249,
			105.31,
			105.358,
			105.434,
			105.465,
			105.495,
			105.558,
			105.622,
			105.682,
			105.688,
			105.745,
			105.792,
			105.869,
			105.901,
			105.931,
			105.994,
			106.009,
			106.056,
			106.118,
			106.178,
			106.241,
			106.304,
			106.335,
			106.366,
			106.428,
			106.443,
			106.487,
			106.551,
			106.614,
			106.661,
			106.738,
			106.767,
			106.799,
			106.861,
			106.924,
			106.984,
			107.048,
			107.094,
			107.172,
			107.203,
			107.232,
			107.31,
			107.357,
			107.418,
			107.481,
			107.526,
			107.544,
			107.605,
			107.637,
			107.666,
			107.75,
			107.792,
			107.854,
			107.916,
			107.961,
			108.037,
			108.069,
			108.1,
			108.162,
			108.224,
			108.287,
			108.35,
			108.395,
			108.412,
			108.473,
			108.504,
			108.535,
			108.596,
			108.614,
			108.658,
			108.721,
			108.782,
			108.83,
			108.846,
			108.908,
			108.939,
			108.97,
			109.032,
			109.047,
			109.093,
			109.156,
			109.218,
			109.263,
			109.342,
			109.372,
			109.402,
			109.465,
			109.526,
			109.588,
			109.65,
			109.698,
			109.715,
			109.776,
			109.809,
			109.836,
			109.9,
			109.914,
			109.962,
			110.024,
			110.087,
			110.132,
			110.148,
			110.209,
			110.24,
			110.272,
			110.333,
			110.35,
			110.395,
			110.459,
			110.52,
			110.566,
			110.643,
			110.675,
			110.707,
			110.768,
			110.83,
			110.893,
			110.952,
			111.015,
			111.079,
			111.107,
			111.141,
			111.202,
			111.218,
			111.265,
			111.327,
			111.389,
			111.435,
			111.51,
			111.543,
			111.575,
			111.635,
			111.699,
			111.761,
			111.82,
			111.872,
			111.883,
			111.946,
			111.976,
			112.008,
			112.069,
			112.085,
			112.131,
			112.199,
			112.256,
			112.304,
			112.318,
			112.379,
			112.412,
			112.443,
			112.518,
			112.566,
			112.628,
			112.688,
			112.752,
			112.813,
			112.845,
			112.876,
			112.953,
			112.999,
			113.062,
			113.125,
			113.17,
			113.185,
			113.247,
			113.279,
			113.31,
			113.373,
			113.387,
			113.435,
			113.495,
			113.559,
			113.606,
			113.619,
			113.682,
			113.713,
			113.743,
			113.821,
			113.869,
			113.934,
			113.993,
			114.039,
			114.116,
			114.148,
			114.178,
			114.241,
			114.303,
			114.365,
			114.427,
			114.488,
			114.549,
			114.581,
			114.613,
			114.675,
			114.691,
			114.736,
			114.8,
			114.861,
			114.907,
			114.921,
			114.985,
			115.015,
			115.046,
			115.109,
			115.171,
			115.232,
			115.296,
			115.342,
			115.418,
			115.448,
			115.482,
			115.558,
			115.605,
			115.667,
			115.729,
			115.776,
			115.791,
			115.852,
			115.882,
			115.916,
			115.976,
			115.996,
			116.037,
			116.101,
			116.163,
			116.21,
			116.285,
			116.317,
			116.349,
			116.409,
			116.473,
			116.535,
			116.597,
			116.66,
			116.72,
			116.752,
			116.781,
			116.846,
			116.859,
			116.907,
			116.968,
			117.03,
			117.076,
			117.093,
			117.156,
			117.186,
			117.217,
			117.279,
			117.294,
			117.341,
			117.403,
			117.465,
			117.511,
			117.588,
			117.621,
			117.652,
			117.73,
			117.775,
			117.837,
			117.899,
			117.945,
			117.962,
			118.023,
			118.059,
			118.085,
			118.148,
			118.163,
			118.21,
			118.272,
			118.334,
			118.379,
			118.396,
			118.458,
			118.488,
			118.518,
			118.579,
			118.596,
			118.643,
			118.706,
			118.766,
			118.829,
			118.891,
			118.921,
			118.953,
			119.013,
			119.076,
			119.139,
			119.199,
			119.263,
			119.325,
			119.357,
			119.385,
			119.449,
			119.463,
			119.511,
			119.572,
			119.634,
			119.681,
			119.696,
			119.758,
			119.791,
			119.82,
			119.898,
			119.944,
			120.007,
			120.068,
			120.131,
			120.193,
			120.224,
			120.254,
			120.332,
			120.379,
			120.442,
			120.503,
			120.551,
			120.565,
			120.628,
			120.659,
			120.688,
			120.752,
			120.766,
			120.814,
			120.877,
			120.937,
			120.985,
			121.061,
			121.093,
			121.123,
			121.186,
			121.248,
			121.309,
			121.372,
			121.419,
			121.496,
			121.525,
			121.554,
			121.635,
			121.681,
			121.743,
			121.803,
			121.852,
			121.867,
			121.929,
			121.96,
			121.991,
			122.069,
			122.115,
			122.18,
			122.24,
			122.302,
			122.364,
			122.393,
			122.425,
			122.503,
			122.549,
			122.611,
			122.673,
			122.736,
			122.796,
			122.829,
			122.859,
			122.938,
			122.984,
			123.046,
			123.107,
			123.152,
			123.17,
			123.233,
			123.263,
			123.295,
			123.371,
			123.417,
			123.479,
			123.543,
			123.588,
			123.666,
			123.696,
			123.728,
			123.787,
			123.851,
			123.913,
			123.975,
			124.023,
			124.036,
			124.1,
			124.131,
			124.16,
			124.244,
			124.287,
			124.348,
			124.409,
			124.454,
			124.472,
			124.532,
			124.564,
			124.595,
			124.657,
			124.671,
			124.719,
			124.782,
			124.843,
			124.89,
			124.966,
			124.998,
			125.028,
			125.091,
			125.153,
			125.216,
			125.276,
			125.321,
			125.34,
			125.401,
			125.431,
			125.464,
			125.526,
			125.54,
			125.587,
			125.648,
			125.712,
			125.757,
			125.774,
			125.835,
			125.865,
			125.895,
			125.974,
			126.022,
			126.088,
			126.146,
			126.191,
			126.27,
			126.304,
			126.332,
			126.393,
			126.408,
			126.456,
			126.519,
			126.578,
			126.594
		]
	],
	'1840_2013_7982': [
		[
			78.243
		],
		[
			0
		],
		[
			0,
			2.666,
			4.175,
			10.224,
			11.74,
			16.297,
			19.341,
			22.389,
			23.915,
			26.972,
			30.035,
			31.568,
			34.64,
			36.177,
			39.256,
			42.339,
			46.974,
			50.07,
			54.726,
			56.28,
			57.835,
			62.509,
			65.632,
			70.324,
			73.459,
			76.599,
			81.319,
			82.896,
			86.052,
			89.212,
			90.794,
			93.961,
			97.134,
			100.311,
			103.492,
			105.085,
			108.272,
			111.46,
			113.055,
			117.849,
			121.05,
			125.861,
			129.073,
			132.289,
			137.122,
			138.734,
			140.348,
			145.195,
			148.432,
			153.296,
			156.544,
			159.797,
			161.425,
			164.685,
			166.316,
			169.58,
			172.849,
			174.484,
			177.759,
			181.037,
			185.964,
			187.608,
			189.253,
			192.547,
			195.845,
			197.495,
			202.452,
			205.762,
			209.076,
			214.055,
			217.379,
			222.373,
			224.04,
			225.708,
			230.717,
			234.061,
			239.085,
			242.44,
			245.798,
			247.478,
			250.842,
			252.526,
			255.896,
			259.27,
			260.958,
			264.336,
			267.719,
			272.798,
			274.493,
			276.189,
			281.283,
			282.984,
			284.685,
			289.796,
			293.208,
			298.332,
			301.753,
			305.178,
			310.324,
			312.042,
			313.76,
			318.922,
			320.645,
			322.368,
			327.544,
			330.998,
			334.455,
			336.185,
			339.647,
			343.114,
			344.848,
			348.319,
			350.055,
			353.531,
			357.01,
			362.237,
			365.724,
			370.961,
			372.708,
			374.453,
			379.672,
			383.124,
			388.241,
			391.606,
			396.582,
			399.852,
			401.472,
			403.083,
			407.859,
			409.431,
			412.547,
			415.626,
			418.669,
			421.677,
			423.168,
			426.125,
			429.043,
			430.51,
			433.371,
			434.788,
			437.604,
			440.42,
			444.609,
			447.422,
			451.639,
			453.035,
			454.422,
			458.58,
			461.342,
			465.478,
			468.236,
			472.401,
			475.219,
			476.632,
			479.496,
			482.394,
			483.845,
			486.746,
			489.635,
			493.937,
			496.77,
			501.016,
			502.43,
			503.843,
			506.665,
			508.075,
			510.887,
			513.695,
			517.872,
			520.63,
			524.752,
			526.12,
			527.49,
			531.601,
			534.349,
			538.489,
			541.263,
			544.043,
			545.435,
			548.223,
			549.617,
			552.407,
			556.598,
			559.396,
			562.2,
			566.411,
			569.224,
			573.453,
			574.866,
			576.279,
			580.528,
			583.366,
			586.208,
			590.477,
			593.329,
			597.618,
			599.05,
			600.484,
			604.793,
			606.208,
			607.649,
			611.983,
			614.882,
			617.788,
			619.243,
			622.159,
			625.08,
			626.544,
			630.944,
			633.881,
			636.824,
			641.27,
			644.223,
			648.668,
			650.154,
			651.641,
			656.114,
			659.104,
			663.601,
			666.607,
			669.619,
			671.127,
			674.148,
			675.66,
			678.667,
			681.706,
			683.228,
			684.752,
			689.332,
			692.392,
			695.456,
			696.99,
			700.059,
			703.131,
			704.669,
			707.746,
			709.286,
			712.371,
			715.461,
			720.108,
			723.212,
			727.879,
			729.437,
			730.996,
			735.68,
			738.807,
			743.487,
			746.585,
			749.656,
			751.178,
			754.193,
			755.685,
			758.642,
			761.56,
			763.004,
			765.865,
			768.686,
			772.846,
			775.571,
			778.257,
			780.905,
			782.215,
			784.805,
			786.086,
			788.62,
			791.116,
			794.787,
			797.188,
			800.719,
			801.879,
			803.03,
			806.425,
			808.642,
			811.898,
			814.022,
			816.109,
			817.138,
			819.168,
			820.169,
			822.143,
			824.079,
			825.033,
			826.913,
			828.756,
			831.456,
			833.21,
			835.769,
			836.603,
			837.428,
			839.844,
			841.408,
			843.685,
			845.156,
			846.591,
			847.295,
			848.681,
			849.361,
			850.033,
			852.008,
			852.648,
			853.282,
			855.138,
			856.342,
			857.526,
			858.111,
			859.267,
			860.408,
			860.974,
			862.662,
			863.772,
			864.876,
			866.526,
			867.625,
			869.241,
			869.777,
			870.314,
			871.926,
			873.029,
			874.735,
			875.874,
			877.018,
			877.593,
			878.927,
			879.595,
			880.934,
			882.277,
			882.985,
			883.732,
			885.966,
			887.455,
			889.004,
			889.779,
			891.327,
			892.87,
			893.615,
			895.092,
			895.826,
			897.291,
			898.595,
			900.389,
			901.57,
			903.308,
			903.854,
			904.397,
			906.008,
			907.072,
			908.708,
			909.834,
			910.958,
			911.52,
			912.643,
			913.21,
			914.366,
			915.519,
			916.094,
			917.243,
			918.39,
			919.534,
			920.667,
			921.237,
			922.389,
			923.528,
			924.106,
			925.266,
			925.849,
			927.024,
			928.215,
			930.026,
			931.244,
			933.074,
			933.679,
			934.283,
			936.074,
			937.247,
			938.983,
			940.128,
			941.268,
			941.837,
			942.972,
			943.54,
			944.685,
			946.374,
			947.493,
			948.606,
			950.244,
			951.325,
			952.936,
			953.467,
			953.99,
			955.529,
			956.541,
			957.547,
			959.083,
			960.113,
			961.642,
			962.15,
			962.657,
			964.933,
			965.531,
			967.322,
			968.526,
			969.808,
			970.445,
			971.718,
			972.993,
			973.626,
			974.872,
			975.495,
			976.742,
			977.991,
			979.802,
			980.997,
			982.794,
			983.394,
			983.949,
			985.609,
			986.713,
			988.41,
			989.537,
			990.675,
			991.249,
			992.408,
			992.993,
			993.583,
			995.386,
			995.995,
			996.608,
			998.472,
			999.742,
			1001.029,
			1001.679,
			1002.991,
			1004.31,
			1004.981,
			1006.337,
			1007.022,
			1008.403,
			1009.798,
			1011.921,
			1013.358,
			1015.548,
			1016.287,
			1017.029,
			1019.283,
			1020.806,
			1023.121,
			1024.688,
			1026.273,
			1027.071,
			1028.679,
			1029.494,
			1031.136,
			1032.792,
			1033.626,
			1034.469,
			1037.02,
			1039.594,
			1040.457,
			1041.321,
			1043.062,
			1044.82,
			1045.705,
			1047.485,
			1048.381,
			1050.198,
			1052.973,
			1054.814,
			1056.684,
			1059.506,
			1060.441,
			1061.395,
			1064.213,
			1066.098,
			1068.954,
			1070.882,
			1072.824,
			1073.801,
			1075.767,
			1076.755,
			1078.742,
			1080.737,
			1081.738,
			1083.75,
			1085.774,
			1088.833,
			1090.885,
			1093.985,
			1095.022,
			1096.062,
			1099.198,
			1101.303,
			1104.47,
			1106.585,
			1108.708,
			1111.914,
			1112.988,
			1114.064,
			1117.311,
			1118.398,
			1119.488,
			1122.774,
			1124.977,
			1127.19,
			1128.301,
			1130.528,
			1132.763,
			1133.885,
			1136.132,
			1137.258,
			1139.518,
			1141.783,
			1145.2,
			1147.488,
			1150.938,
			1152.093,
			1153.249,
			1156.733,
			1159.068,
			1162.59,
			1164.95,
			1167.318,
			1168.506,
			1170.887,
			1172.08,
			1174.473,
			1178.077,
			1180.492,
			1182.919,
			1185.355,
			1187.806,
			1189.035,
			1191.501,
			1193.979,
			1195.221,
			1197.713,
			1198.962,
			1201.467,
			1203.98,
			1207.766,
			1210.297,
			1214.096,
			1215.36,
			1216.622,
			1220.4,
			1222.901,
			1226.606,
			1229.037,
			1232.614,
			1234.952,
			1236.107,
			1238.391,
			1240.656,
			1241.766,
			1243.961,
			1246.124,
			1249.325,
			1250.382,
			1251.432,
			1253.516,
			1255.587,
			1256.614,
			1258.66,
			1259.682,
			1261.727,
			1263.775,
			1266.861,
			1268.937,
			1272.069,
			1273.123,
			1274.177,
			1277.356,
			1278.421,
			1279.487,
			1282.685,
			1284.818,
			1286.936,
			1287.984,
			1290.084,
			1291.133,
			1293.197,
			1296.302,
			1298.378,
			1300.457,
			1303.604,
			1304.657,
			1305.71,
			1308.874,
			1309.93,
			1310.987,
			1314.158,
			1316.277,
			1318.416,
			1321.636,
			1323.8,
			1327.045,
			1328.14,
			1329.237,
			1332.544,
			1333.652,
			1334.762,
			1338.106,
			1340.347,
			1342.593,
			1343.717,
			1345.973,
			1347.104,
			1349.37,
			1351.646,
			1352.793,
			1355.095,
			1357.41,
			1360.904,
			1363.238,
			1366.725,
			1367.883,
			1369.037,
			1372.465,
			1374.719,
			1378.026,
			1380.177,
			1382.292,
			1383.332,
			1385.384,
			1386.397,
			1387.4,
			1390.352,
			1391.318,
			1392.274,
			1395.089,
			1396.921,
			1398.719,
			1399.606,
			1401.376,
			1403.11,
			1403.97,
			1405.678,
			1406.526,
			1408.215,
			1409.892,
			1412.399,
			1414.055,
			1416.527,
			1417.349,
			1418.18,
			1420.703,
			1422.382,
			1424.987,
			1426.732,
			1428.489,
			1429.383,
			1431.166,
			1432.055,
			1433.872,
			1435.745,
			1436.679,
			1438.567,
			1440.533,
			1442.488,
			1445.454,
			1447.429,
			1449.344,
			1450.277,
			1452.135,
			1453.062,
			1454.753,
			1456.417,
			1458.863,
			1460.4,
			1462.692,
			1463.455,
			1464.213,
			1466.481,
			1467.991,
			1470.379,
			1471.996,
			1473.619,
			1474.448,
			1476.106,
			1476.936,
			1478.602,
			1481.106,
			1482.782,
			1484.469,
			1487.025,
			1488.74,
			1491.324,
			1492.172,
			1493.034,
			1495.629,
			1497.38,
			1499.123,
			1501.761,
			1503.521,
			1506.188,
			1507.082,
			1507.98,
			1510.677,
			1511.581,
			1512.488,
			1515.225,
			1517.08,
			1518.932,
			1519.848,
			1521.729,
			1522.666,
			1524.552,
			1526.436,
			1527.406,
			1529.309,
			1531.241,
			1534.164,
			1536.125,
			1539.09,
			1540.083,
			1541.078,
			1544.082,
			1546.099,
			1549.147,
			1551.197,
			1554.293,
			1556.373,
			1557.418,
			1558.465,
			1562.666,
			1563.719,
			1566.893,
			1569.024,
			1572.238,
			1574.392,
			1576.553,
			1577.638,
			1579.813,
			1580.903,
			1583.091,
			1585.287,
			1588.597,
			1590.816,
			1594.162,
			1595.282,
			1596.405,
			1599.787,
			1602.054,
			1605.472,
			1607.763,
			1610.063,
			1611.216,
			1613.529,
			1614.688,
			1617.014,
			1619.348,
			1620.519,
			1622.866,
			1625.223,
			1628.776,
			1629.965,
			1631.156,
			1634.744,
			1635.944,
			1637.147,
			1639.56,
			1640.769,
			1643.195,
			1645.628,
			1649.295,
			1651.75,
			1655.447,
			1656.684,
			1657.923,
			1661.651,
			1664.146,
			1667.904,
			1670.419,
			1674.205,
			1676.738,
			1678.007,
			1680.552,
			1683.103,
			1684.381,
			1686.942,
			1689.509,
			1693.369,
			1694.658,
			1695.949,
			1699.833,
			1701.131,
			1702.432,
			1706.338,
			1708.948,
			1711.565,
			1715.495,
			1718.135,
			1722.05,
			1723.35,
			1724.647,
			1728.533,
			1731.12,
			1735.005,
			1737.595,
			1740.203,
			1741.506,
			1744.121,
			1746.742,
			1748.056,
			1752.015,
			1754.665,
			1757.319,
			1761.303,
			1763.97,
			1767.989,
			1769.345,
			1770.702,
			1774.781,
			1777.507,
			1781.538,
			1784.208,
			1786.857,
			1788.18,
			1790.81,
			1792.119,
			1794.722,
			1798.554,
			1799.823,
			1803.576,
			1806.027,
			1808.439,
			1809.634,
			1811.996,
			1814.323,
			1815.474,
			1818.864,
			1821.073,
			1823.242,
			1826.42,
			1828.489,
			1831.519,
			1832.51,
			1833.491,
			1836.378,
			1838.255,
			1841.002,
			1842.789,
			1845.403,
			1847.099,
			1847.933,
			1849.572,
			1851.959,
			1853.503,
			1855.009,
			1856.478,
			1857.908,
			1858.61,
			1859.986,
			1861.328,
			1861.987,
			1863.277,
			1863.91,
			1865.148,
			1866.346,
			1868.073,
			1869.179,
			1870.801,
			1871.321,
			1871.831,
			1873.32,
			1874.281,
			1875.738,
			1876.685,
			1877.616,
			1878.077,
			1878.994,
			1879.45,
			1880.479,
			1882.009,
			1883.021,
			1884.034,
			1885.67,
			1886.228,
			1886.785,
			1888.457,
			1889.017,
			1889.582,
			1890.768,
			1891.356,
			1892.532,
			1893.713,
			1895.486,
			1896.654,
			1898.405,
			1898.99,
			1899.578,
			1901.33,
			1902.498,
			1904.256,
			1905.49,
			1907.376,
			1908.631,
			1909.259,
			1910.495,
			1911.712,
			1912.319,
			1913.531,
			1914.747,
			1916.608,
			1917.845,
			1919.701,
			1920.347,
			1921.001,
			1922.96,
			1924.271,
			1926.157,
			1927.384,
			1928.616,
			1929.236,
			1930.429,
			1930.98,
			1931.531,
			1933.75,
			1934.309,
			1935.951,
			1937.025,
			1938.648,
			1939.741,
			1940.86,
			1941.425,
			1943.128,
			1944.272,
			1945.427,
			1947.184,
			1948.361,
			1950.136,
			1950.735,
			1951.337,
			1953.143,
			1954.366,
			1956.224,
			1957.473,
			1959.346,
			1960.612,
			1961.251,
			1962.545,
			1963.854,
			1964.514,
			1965.179,
			1967.213,
			1968.59,
			1969.984,
			1970.689,
			1972.117,
			1973.561,
			1974.289,
			1975.757,
			1976.496,
			1977.985,
			1979.49,
			1981.773,
			1983.311,
			1985.634,
			1986.421,
			1987.212,
			1989.607,
			1991.223,
			1993.678,
			1995.333,
			1997.004,
			1997.845,
			1999.525,
			2000.364,
			2002.05,
			2004.604,
			2006.323,
			2008.051,
			2010.668,
			2011.547,
			2012.429,
			2015.093,
			2015.988,
			2016.886,
			2018.693,
			2019.601,
			2021.428,
			2023.269,
			2026.054,
			2027.929,
			2030.767,
			2031.719,
			2032.676,
			2036.535,
			2037.524,
			2040.461,
			2042.434,
			2044.42,
			2045.417,
			2047.42,
			2049.438,
			2050.451,
			2052.487,
			2053.51,
			2055.564,
			2057.629,
			2060.751,
			2062.846,
			2065.992,
			2067.04,
			2068.089,
			2071.226,
			2073.341,
			2076.532,
			2078.671,
			2080.817,
			2081.894,
			2084.054,
			2085.137,
			2086.222,
			2089.495,
			2091.691,
			2095.005,
			2097.229,
			2099.483,
			2100.606,
			2102.859,
			2105.126,
			2106.263,
			2109.693,
			2111.993,
			2114.302,
			2117.785,
			2120.121,
			2123.644,
			2124.826,
			2126.012,
			2129.585,
			2131.985,
			2135.603,
			2138.029,
			2140.464,
			2141.685,
			2144.134,
			2145.366,
			2147.838,
			2150.321,
			2151.569,
			2152.819,
			2156.574,
			2160.334,
			2162.839,
			2165.34,
			2167.827,
			2169.069,
			2171.545,
			2172.781,
			2175.246,
			2177.722,
			2181.429,
			2183.901,
			2187.598,
			2188.827,
			2190.055,
			2193.735,
			2196.162,
			2199.832,
			2202.276,
			2204.743,
			2205.969,
			2208.415,
			2209.64,
			2212.092,
			2214.542,
			2215.77,
			2218.229,
			2220.676,
			2224.398,
			2225.644,
			2226.891,
			2230.666,
			2231.921,
			2233.178,
			2235.698,
			2236.961,
			2239.477,
			2242.022,
			2245.853,
			2248.415,
			2252.267,
			2253.551,
			2254.837,
			2258.706,
			2259.998,
			2261.292,
			2265.195,
			2267.807,
			2270.429,
			2271.742,
			2274.377,
			2277.021,
			2278.345,
			2282.334,
			2284.997,
			2287.665,
			2291.682,
			2294.373,
			2298.436,
			2299.799,
			2301.167,
			2305.282,
			2308.036,
			2312.174,
			2314.961,
			2319.08,
			2321.838,
			2323.219,
			2324.601,
			2330.137,
			2331.524,
			2335.72,
			2338.51,
			2341.283,
			2342.684,
			2345.49,
			2348.303,
			2349.712,
			2352.534,
			2353.948,
			2356.781,
			2359.62,
			2363.895,
			2366.754,
			2371.056,
			2372.492,
			2373.931,
			2378.281,
			2381.15,
			2385.506,
			2388.419,
			2391.34,
			2392.825,
			2395.753,
			2397.219,
			2400.13,
			2404.564,
			2406.036,
			2410.459,
			2413.417,
			2416.38,
			2417.863,
			2420.832,
			2423.807,
			2425.297,
			2428.282,
			2429.78,
			2432.78,
			2435.791,
			2440.321,
			2443.35,
			2447.902,
			2449.419,
			2450.937,
			2455.489,
			2458.526,
			2463.094,
			2466.15,
			2470.741,
			2473.803,
			2475.334,
			2478.387,
			2481.425,
			2482.936,
			2485.943,
			2488.9,
			2493.328,
			2494.793,
			2496.258,
			2500.662,
			2502.121,
			2503.578,
			2507.931,
			2510.81,
			2513.662,
			2517.884,
			2520.644,
			2524.793,
			2526.177,
			2527.562,
			2531.756,
			2534.552,
			2538.756,
			2541.568,
			2544.366,
			2545.779,
			2548.613,
			2550.031,
			2552.876,
			2555.754,
			2557.182,
			2560.042,
			2562.908,
			2567.219,
			2570.099,
			2574.403,
			2575.848,
			2577.294,
			2581.641,
			2584.568,
			2588.93,
			2591.843,
			2594.758,
			2599.133,
			2600.566,
			2602.025,
			2606.409,
			2609.336,
			2613.735,
			2616.696,
			2619.638,
			2621.11,
			2624.06,
			2627.015,
			2628.494,
			2631.434,
			2632.918,
			2635.892,
			2638.871,
			2643.348,
			2646.339,
			2650.833,
			2652.333,
			2653.834,
			2658.344,
			2661.355,
			2665.881,
			2668.908,
			2671.938,
			2673.453,
			2676.476,
			2677.981,
			2680.971,
			2685.394,
			2686.85,
			2691.163,
			2693.992,
			2696.783,
			2698.166,
			2700.901,
			2703.598,
			2704.932,
			2707.573,
			2708.88,
			2711.463,
			2714.007,
			2717.748,
			2720.193,
			2723.789,
			2724.97,
			2726.143,
			2729.606,
			2731.87,
			2735.23,
			2737.42,
			2740.666,
			2742.82,
			2743.896,
			2746.048,
			2748.186,
			2749.25,
			2751.38,
			2753.512,
			2756.709,
			2757.78,
			2758.853,
			2762.069,
			2763.146,
			2764.224,
			2767.458,
			2769.642,
			2771.822,
			2775.108,
			2777.296,
			2780.584,
			2781.678,
			2782.771,
			2786.054,
			2788.242,
			2791.539,
			2793.749,
			2797.09,
			2799.338,
			2801.598,
			2802.725,
			2804.967,
			2806.083,
			2808.307,
			2810.478,
			2813.709,
			2815.855,
			2819.07,
			2820.131,
			2821.191,
			2824.366,
			2826.467,
			2829.615,
			2831.712,
			2833.809,
			2836.907,
			2837.935,
			2838.962,
			2842.004,
			2843.008,
			2844.007,
			2847.001,
			2848.968,
			2850.88,
			2851.817,
			2853.667,
			2854.582,
			2856.39,
			2858.169,
			2859.049,
			2860.774,
			2862.49,
			2865.017,
			2866.692,
			2869.15,
			2869.964,
			2870.777,
			2873.197,
			2874.832,
			2877.271,
			2878.91,
			2881.393,
			2883.066,
			2883.907,
			2885.597,
			2887.298,
			2888.151,
			2889.862,
			2891.579,
			2893.297,
			2895.019,
			2895.879,
			2897.601,
			2899.316,
			2900.171,
			2901.878,
			2902.731,
			2904.429,
			2906.121,
			2908.662,
			2910.352,
			2912.893,
			2913.744,
			2914.593,
			2917.141,
			2918.854,
			2921.415,
			2923.125,
			2925.687,
			2927.395,
			2928.251,
			2929.963,
			2931.671,
			2932.533,
			2934.268,
			2936.001,
			2938.633,
			2939.52,
			2940.407,
			2943.086,
			2943.99,
			2944.895,
			2946.714,
			2947.627,
			2949.454,
			2951.292,
			2952.215,
			2954.07,
			2955.935,
			2958.758,
			2959.704,
			2960.653,
			2963.517,
			2965.446,
			2968.343,
			2970.273,
			2972.204,
			2973.169,
			2975.085,
			2976.042,
			2977.954,
			2980.816,
			2982.717,
			2984.614,
			2987.445,
			2988.385,
			2989.325,
			2992.134,
			2993.068,
			2994.001,
			2995.865,
			2996.794,
			2998.647,
			3001.395,
			3003.215,
			3005.035,
			3007.754,
			3008.656,
			3009.563,
			3012.284,
			3013.187,
			3014.089,
			3016.791,
			3018.583,
			3021.273,
			3023.062,
			3024.843,
			3025.727,
			3027.49,
			3028.368,
			3030.116,
			3031.831,
			3034.385,
			3036.074,
			3038.586,
			3039.421,
			3040.254,
			3042.733,
			3044.375,
			3046.831,
			3048.465,
			3050.092,
			3050.903,
			3052.523,
			3053.331,
			3054.938,
			3056.537,
			3057.339,
			3058.925,
			3060.522,
			3062.137,
			3063.747,
			3064.551,
			3066.158,
			3067.763,
			3068.565,
			3070.17,
			3070.974,
			3072.583,
			3074.193,
			3076.597,
			3078.222,
			3080.639,
			3081.444,
			3082.25,
			3084.654,
			3086.272,
			3088.722,
			3090.372,
			3092.037,
			3092.874,
			3094.562,
			3096.265,
			3097.122,
			3099.719,
			3101.472,
			3103.239,
			3105.922,
			3106.824,
			3107.73,
			3109.553,
			3111.392,
			3112.317,
			3114.177,
			3115.113,
			3116.995,
			3118.891,
			3121.761,
			3123.691,
			3126.61,
			3127.589,
			3128.571,
			3131.538,
			3133.531,
			3136.545,
			3138.569,
			3140.605,
			3141.628,
			3143.682,
			3144.714,
			3146.787,
			3148.875,
			3149.923,
			3152.02,
			3154.122,
			3157.295,
			3158.359,
			3159.426,
			3162.645,
			3163.724,
			3164.805,
			3168.068,
			3170.255,
			3173.556,
			3175.767,
			3177.987,
			3181.33,
			3182.449,
			3183.571,
			3186.954,
			3188.087,
			3189.225,
			3192.656,
			3194.958,
			3198.432,
			3200.762,
			3203.102,
			3204.275,
			3206.627,
			3207.806,
			3210.17,
			3212.541,
			3216.116,
			3218.513,
			3222.132,
			3223.344,
			3224.559,
			3228.218,
			3230.669,
			3234.354,
			3236.823,
			3239.301,
			3240.544,
			3243.042,
			3244.295,
			3246.807,
			3249.33,
			3250.594,
			3251.856,
			3255.655,
			3258.199,
			3260.751,
			3262.035,
			3264.609,
			3267.19,
			3268.483,
			3271.075,
			3272.373,
			3274.975,
			3277.584,
			3278.889,
			3281.499,
			3284.114,
			3288.045,
			3289.357,
			3290.671,
			3294.621,
			3297.263,
			3301.242,
			3303.906,
			3307.917,
			3310.602,
			3311.945,
			3314.636,
			3317.328,
			3318.671,
			3321.362,
			3324.056,
			3326.757,
			3330.855,
			3334.985,
			3336.367,
			3337.747,
			3340.51,
			3341.893,
			3344.657,
			3347.427,
			3351.592,
			3354.376,
			3358.565,
			3359.965,
			3361.366,
			3365.578,
			3368.389,
			3372.613,
			3375.432,
			3378.261,
			3379.68,
			3382.526,
			3385.395,
			3386.832,
			3389.715,
			3391.159,
			3394.043,
			3396.928,
			3401.26,
			3404.153,
			3408.501,
			3409.951,
			3411.403,
			3415.758,
			3418.667,
			3421.581,
			3425.974,
			3428.915,
			3433.344,
			3434.824,
			3436.306,
			3442.246,
			3443.734,
			3448.207,
			3451.193,
			3454.185,
			3455.683,
			3458.682,
			3461.69,
			3463.196,
			3464.627
		],
		[
			0,
			0.05,
			0.083,
			0.16,
			0.207,
			0.268,
			0.329,
			0.377,
			0.393,
			0.455,
			0.485,
			0.515,
			0.578,
			0.595,
			0.64,
			0.707,
			0.763,
			0.81,
			0.887,
			0.92,
			0.951,
			1.011,
			1.073,
			1.137,
			1.197,
			1.244,
			1.323,
			1.352,
			1.384,
			1.445,
			1.462,
			1.509,
			1.571,
			1.632,
			1.679,
			1.695,
			1.755,
			1.786,
			1.818,
			1.896,
			1.94,
			2.009,
			2.067,
			2.114,
			2.191,
			2.222,
			2.253,
			2.313,
			2.377,
			2.438,
			2.501,
			2.547,
			2.561,
			2.626,
			2.656,
			2.686,
			2.749,
			2.769,
			2.811,
			2.874,
			2.935,
			2.98,
			2.996,
			3.057,
			3.088,
			3.121,
			3.184,
			3.244,
			3.308,
			3.369,
			3.414,
			3.494,
			3.525,
			3.555,
			3.616,
			3.678,
			3.739,
			3.803,
			3.85,
			3.864,
			3.925,
			3.957,
			3.989,
			4.051,
			4.065,
			4.111,
			4.174,
			4.238,
			4.283,
			4.299,
			4.362,
			4.393,
			4.422,
			4.5,
			4.548,
			4.609,
			4.671,
			4.731,
			4.794,
			4.829,
			4.857,
			4.919,
			4.934,
			4.98,
			5.044,
			5.104,
			5.15,
			5.167,
			5.229,
			5.26,
			5.291,
			5.352,
			5.369,
			5.413,
			5.478,
			5.537,
			5.586,
			5.664,
			5.694,
			5.726,
			5.786,
			5.85,
			5.913,
			5.972,
			6.036,
			6.096,
			6.128,
			6.157,
			6.219,
			6.238,
			6.284,
			6.345,
			6.406,
			6.454,
			6.469,
			6.531,
			6.562,
			6.594,
			6.654,
			6.672,
			6.718,
			6.778,
			6.84,
			6.893,
			6.964,
			6.997,
			7.028,
			7.09,
			7.15,
			7.213,
			7.276,
			7.338,
			7.398,
			7.43,
			7.461,
			7.522,
			7.538,
			7.584,
			7.648,
			7.71,
			7.772,
			7.834,
			7.865,
			7.896,
			7.956,
			7.974,
			8.02,
			8.081,
			8.143,
			8.189,
			8.268,
			8.298,
			8.328,
			8.392,
			8.452,
			8.517,
			8.577,
			8.622,
			8.64,
			8.701,
			8.733,
			8.765,
			8.841,
			8.887,
			8.954,
			9.011,
			9.059,
			9.134,
			9.166,
			9.197,
			9.273,
			9.321,
			9.383,
			9.446,
			9.493,
			9.569,
			9.6,
			9.632,
			9.694,
			9.71,
			9.755,
			9.817,
			9.879,
			9.927,
			9.941,
			10.003,
			10.036,
			10.066,
			10.144,
			10.189,
			10.252,
			10.313,
			10.361,
			10.437,
			10.468,
			10.5,
			10.561,
			10.622,
			10.687,
			10.749,
			10.794,
			10.81,
			10.872,
			10.903,
			10.935,
			10.996,
			11.015,
			11.057,
			11.121,
			11.18,
			11.23,
			11.244,
			11.305,
			11.337,
			11.368,
			11.431,
			11.446,
			11.491,
			11.555,
			11.617,
			11.662,
			11.74,
			11.772,
			11.803,
			11.879,
			11.924,
			11.993,
			12.051,
			12.097,
			12.111,
			12.173,
			12.204,
			12.236,
			12.297,
			12.314,
			12.361,
			12.422,
			12.484,
			12.547,
			12.607,
			12.639,
			12.67,
			12.733,
			12.749,
			12.795,
			12.856,
			12.919,
			12.965,
			13.042,
			13.078,
			13.103,
			13.166,
			13.227,
			13.289,
			13.351,
			13.399,
			13.415,
			13.477,
			13.506,
			13.538,
			13.599,
			13.616,
			13.661,
			13.724,
			13.785,
			13.833,
			13.91,
			13.94,
			13.971,
			14.049,
			14.097,
			14.16,
			14.219,
			14.266,
			14.284,
			14.345,
			14.376,
			14.408,
			14.467,
			14.484,
			14.529,
			14.596,
			14.656,
			14.702,
			14.716,
			14.778,
			14.81,
			14.84,
			14.918,
			14.965,
			15.032,
			15.087,
			15.141,
			15.211,
			15.244,
			15.276,
			15.338,
			15.398,
			15.461,
			15.523,
			15.57,
			15.584,
			15.645,
			15.677,
			15.709,
			15.77,
			15.786,
			15.833,
			15.896,
			15.957,
			16.003,
			16.018,
			16.08,
			16.113,
			16.143,
			16.204,
			16.221,
			16.265,
			16.33,
			16.392,
			16.437,
			16.515,
			16.546,
			16.578,
			16.639,
			16.7,
			16.764,
			16.826,
			16.87,
			16.887,
			16.948,
			16.981,
			17.012,
			17.073,
			17.088,
			17.134,
			17.202,
			17.257,
			17.306,
			17.322,
			17.382,
			17.414,
			17.445,
			17.507,
			17.523,
			17.57,
			17.63,
			17.694,
			17.74,
			17.816,
			17.849,
			17.878,
			17.94,
			18.003,
			18.065,
			18.128,
			18.173,
			18.189,
			18.252,
			18.282,
			18.314,
			18.391,
			18.438,
			18.499,
			18.561,
			18.623,
			18.685,
			18.715,
			18.746,
			18.823,
			18.87,
			18.933,
			18.996,
			19.043,
			19.118,
			19.151,
			19.181,
			19.263,
			19.305,
			19.372,
			19.429,
			19.476,
			19.49,
			19.553,
			19.583,
			19.614,
			19.676,
			19.694,
			19.74,
			19.802,
			19.862,
			19.91,
			19.988,
			20.02,
			20.05,
			20.112,
			20.173,
			20.236,
			20.297,
			20.344,
			20.361,
			20.422,
			20.454,
			20.482,
			20.545,
			20.561,
			20.607,
			20.671,
			20.731,
			20.777,
			20.794,
			20.856,
			20.886,
			20.918,
			20.978,
			20.996,
			21.042,
			21.105,
			21.166,
			21.228,
			21.289,
			21.327,
			21.352,
			21.414,
			21.477,
			21.537,
			21.6,
			21.647,
			21.662,
			21.722,
			21.755,
			21.787,
			21.847,
			21.864,
			21.908,
			21.976,
			22.035,
			22.08,
			22.096,
			22.157,
			22.187,
			22.22,
			22.282,
			22.296,
			22.344,
			22.411,
			22.469,
			22.514,
			22.591,
			22.621,
			22.653,
			22.715,
			22.778,
			22.84,
			22.903,
			22.948,
			22.965,
			23.024,
			23.058,
			23.089,
			23.149,
			23.167,
			23.213,
			23.273,
			23.337,
			23.386,
			23.461,
			23.491,
			23.522,
			23.584,
			23.647,
			23.709,
			23.77,
			23.817,
			23.894,
			23.925,
			23.956,
			24.017,
			24.035,
			24.08,
			24.14,
			24.205,
			24.25,
			24.267,
			24.329,
			24.36,
			24.39,
			24.452,
			24.468,
			24.515,
			24.577,
			24.638,
			24.685,
			24.762,
			24.794,
			24.823,
			24.9,
			24.948,
			25.011,
			25.071,
			25.117,
			25.135,
			25.196,
			25.227,
			25.26,
			25.336,
			25.383,
			25.447,
			25.507,
			25.553,
			25.568,
			25.632,
			25.661,
			25.693,
			25.754,
			25.771,
			25.816,
			25.88,
			25.939,
			26.003,
			26.063,
			26.096,
			26.127,
			26.188,
			26.249,
			26.312,
			26.375,
			26.436,
			26.498,
			26.528,
			26.561,
			26.622,
			26.639,
			26.685,
			26.747,
			26.809,
			26.854,
			26.871,
			26.933,
			26.965,
			26.995,
			27.056,
			27.071,
			27.12,
			27.182,
			27.244,
			27.304,
			27.367,
			27.397,
			27.429,
			27.49,
			27.511,
			27.553,
			27.616,
			27.677,
			27.722,
			27.738,
			27.802,
			27.832,
			27.864,
			27.938,
			27.987,
			28.05,
			28.111,
			28.158,
			28.171,
			28.233,
			28.266,
			28.298,
			28.36,
			28.422,
			28.483,
			28.544,
			28.593,
			28.671,
			28.7,
			28.73,
			28.791,
			28.81,
			28.854,
			28.918,
			28.98,
			29.026,
			29.042,
			29.104,
			29.133,
			29.166,
			29.228,
			29.242,
			29.288,
			29.35,
			29.412,
			29.475,
			29.537,
			29.572,
			29.6,
			29.661,
			29.722,
			29.786,
			29.847,
			29.894,
			29.909,
			29.972,
			30.003,
			30.032,
			30.096,
			30.111,
			30.156,
			30.222,
			30.28,
			30.327,
			30.344,
			30.406,
			30.437,
			30.467,
			30.53,
			30.545,
			30.591,
			30.652,
			30.716,
			30.762,
			30.838,
			30.87,
			30.9,
			30.964,
			31.027,
			31.088,
			31.149,
			31.196,
			31.211,
			31.272,
			31.305,
			31.337,
			31.399,
			31.412,
			31.461,
			31.522,
			31.583,
			31.646,
			31.706,
			31.738,
			31.771,
			31.831,
			31.846,
			31.893,
			31.955,
			32.016,
			32.079,
			32.141,
			32.172,
			32.203,
			32.265,
			32.328,
			32.388,
			32.451,
			32.497,
			32.514,
			32.577,
			32.605,
			32.638,
			32.714,
			32.761,
			32.824,
			32.886,
			32.949,
			33.01,
			33.04,
			33.073,
			33.149,
			33.197,
			33.258,
			33.32,
			33.367,
			33.444,
			33.475,
			33.506,
			33.567,
			33.584,
			33.629,
			33.695,
			33.753,
			33.799,
			33.815,
			33.878,
			33.906,
			33.938,
			34.001,
			34.017,
			34.065,
			34.124,
			34.189,
			34.235,
			34.312,
			34.344,
			34.374,
			34.452,
			34.497,
			34.56,
			34.621,
			34.685,
			34.747,
			34.778,
			34.808,
			34.885,
			34.931,
			34.994,
			35.055,
			35.119,
			35.181,
			35.211,
			35.24,
			35.305,
			35.32,
			35.367,
			35.429,
			35.489,
			35.537,
			35.614,
			35.646,
			35.677,
			35.738,
			35.801,
			35.864,
			35.923,
			35.972,
			35.985,
			36.048,
			36.078,
			36.112,
			36.172,
			36.188,
			36.235,
			36.296,
			36.359,
			36.405,
			36.421,
			36.483,
			36.514,
			36.545,
			36.606,
			36.621,
			36.669,
			36.731,
			36.79,
			36.837,
			36.918,
			36.948,
			36.98,
			37.04,
			37.103,
			37.165,
			37.227,
			37.289,
			37.35,
			37.381,
			37.412,
			37.476,
			37.491,
			37.536,
			37.598,
			37.661,
			37.706,
			37.721,
			37.785,
			37.819,
			37.847,
			37.909,
			37.97,
			38.032,
			38.094,
			38.141,
			38.22,
			38.248,
			38.281,
			38.343,
			38.404,
			38.467,
			38.528,
			38.574,
			38.59,
			38.651,
			38.684,
			38.714,
			38.792,
			38.839,
			38.899,
			38.963,
			39.01,
			39.087,
			39.118,
			39.15,
			39.212,
			39.274,
			39.335,
			39.395,
			39.444,
			39.46,
			39.52,
			39.55,
			39.583,
			39.662,
			39.706,
			39.77,
			39.831,
			39.881,
			39.893,
			39.956,
			39.986,
			40.017,
			40.095,
			40.141,
			40.202,
			40.264,
			40.312,
			40.388,
			40.422,
			40.45,
			40.514,
			40.574,
			40.637,
			40.7,
			40.762,
			40.822,
			40.855,
			40.885,
			40.963,
			41.01,
			41.071,
			41.132,
			41.181,
			41.194,
			41.256,
			41.287,
			41.32,
			41.382,
			41.396,
			41.444,
			41.506,
			41.568,
			41.615,
			41.691,
			41.723,
			41.754,
			41.814,
			41.878,
			41.938,
			42.002,
			42.048,
			42.064,
			42.126,
			42.157,
			42.188,
			42.265,
			42.312,
			42.372,
			42.435,
			42.482,
			42.496,
			42.561,
			42.592,
			42.621,
			42.683,
			42.7,
			42.746,
			42.806,
			42.87,
			42.917,
			42.994,
			43.026,
			43.056,
			43.118,
			43.181,
			43.241,
			43.304,
			43.364,
			43.427,
			43.458,
			43.488,
			43.55,
			43.566,
			43.612,
			43.676,
			43.737,
			43.785,
			43.862,
			43.892,
			43.923,
			43.986,
			44.049,
			44.111,
			44.17,
			44.219,
			44.235,
			44.294,
			44.326,
			44.356,
			44.435,
			44.481,
			44.544,
			44.606,
			44.668,
			44.731,
			44.761,
			44.793,
			44.868,
			44.915,
			44.977,
			45.038,
			45.101,
			45.163,
			45.195,
			45.227,
			45.286,
			45.349,
			45.412,
			45.474,
			45.537,
			45.599,
			45.629,
			45.661,
			45.721,
			45.739,
			45.783,
			45.845,
			45.906,
			45.955,
			45.969,
			46.031,
			46.069,
			46.094,
			46.156,
			46.17,
			46.219,
			46.28,
			46.342,
			46.39,
			46.465,
			46.498,
			46.527,
			46.589,
			46.653,
			46.715,
			46.777,
			46.823,
			46.839,
			46.901,
			46.93,
			46.961,
			47.04,
			47.086,
			47.149,
			47.211,
			47.258,
			47.271,
			47.334,
			47.366,
			47.397,
			47.458,
			47.475,
			47.52,
			47.581,
			47.644,
			47.692,
			47.769,
			47.799,
			47.831,
			47.909,
			47.955,
			48.016,
			48.078,
			48.128,
			48.141,
			48.203,
			48.233,
			48.265,
			48.326,
			48.341,
			48.387,
			48.451,
			48.512,
			48.573,
			48.636,
			48.668,
			48.7,
			48.761,
			48.823,
			48.885,
			48.947,
			48.994,
			49.009,
			49.07,
			49.101,
			49.132,
			49.195,
			49.257,
			49.318,
			49.381,
			49.428,
			49.44,
			49.505,
			49.537,
			49.567,
			49.645,
			49.691,
			49.753,
			49.814,
			49.86,
			49.939,
			49.971,
			50.001,
			50.079,
			50.123,
			50.188,
			50.249,
			50.296,
			50.311,
			50.372,
			50.403,
			50.435,
			50.496,
			50.513,
			50.557,
			50.621,
			50.684,
			50.744,
			50.806,
			50.838,
			50.87,
			50.93,
			50.947,
			50.993,
			51.055,
			51.116,
			51.177,
			51.24,
			51.273,
			51.302,
			51.365,
			51.427,
			51.49,
			51.551,
			51.598,
			51.612,
			51.676,
			51.705,
			51.736,
			51.799,
			51.815,
			51.861,
			51.924,
			51.986,
			52.03,
			52.048,
			52.11,
			52.139,
			52.171,
			52.231,
			52.253,
			52.295,
			52.357,
			52.419,
			52.464,
			52.544,
			52.574,
			52.605,
			52.667,
			52.684,
			52.729,
			52.793,
			52.853,
			52.901,
			52.915,
			52.977,
			53.009,
			53.04,
			53.116,
			53.164,
			53.225,
			53.287,
			53.335,
			53.41,
			53.441,
			53.473,
			53.535,
			53.598,
			53.661,
			53.722,
			53.784,
			53.844,
			53.877,
			53.906,
			53.984,
			54.03,
			54.094,
			54.155,
			54.202,
			54.218,
			54.28,
			54.314,
			54.343,
			54.405,
			54.418,
			54.466,
			54.527,
			54.589,
			54.651,
			54.714,
			54.745,
			54.776,
			54.839,
			54.9,
			54.961,
			55.022,
			55.069,
			55.085,
			55.148,
			55.179,
			55.21,
			55.286,
			55.333,
			55.397,
			55.457,
			55.503,
			55.52,
			55.58,
			55.614,
			55.644,
			55.706,
			55.722,
			55.768,
			55.829,
			55.892,
			55.938,
			56.016,
			56.047,
			56.076,
			56.141,
			56.203,
			56.264,
			56.326,
			56.387,
			56.45,
			56.48,
			56.511,
			56.573,
			56.589,
			56.634,
			56.699,
			56.76,
			56.806,
			56.822,
			56.885,
			56.916,
			56.945,
			57.022,
			57.071,
			57.133,
			57.194,
			57.257,
			57.317,
			57.349,
			57.379,
			57.456,
			57.504,
			57.565,
			57.629,
			57.673,
			57.69,
			57.751,
			57.782,
			57.814,
			57.877,
			57.892,
			57.939,
			58,
			58.061,
			58.109,
			58.186,
			58.217,
			58.249,
			58.31,
			58.372,
			58.434,
			58.497,
			58.541,
			58.62,
			58.651,
			58.683,
			58.758,
			58.806,
			58.869,
			58.93,
			58.978,
			58.993,
			59.053,
			59.085,
			59.117,
			59.178,
			59.194,
			59.239,
			59.302,
			59.364,
			59.412,
			59.487,
			59.52,
			59.55,
			59.612,
			59.673,
			59.737,
			59.799,
			59.844,
			59.861,
			59.923,
			59.953,
			59.986,
			60.061,
			60.108,
			60.172,
			60.232,
			60.278,
			60.294,
			60.358,
			60.387,
			60.419,
			60.48,
			60.501,
			60.543,
			60.604,
			60.666,
			60.713,
			60.79,
			60.822,
			60.853,
			60.915,
			60.977,
			61.039,
			61.102,
			61.163,
			61.224,
			61.256,
			61.286,
			61.348,
			61.365,
			61.411,
			61.473,
			61.536,
			61.58,
			61.596,
			61.66,
			61.689,
			61.72,
			61.798,
			61.845,
			61.906,
			61.97,
			62.015,
			62.091,
			62.124,
			62.154,
			62.231,
			62.277,
			62.34,
			62.403,
			62.464,
			62.528,
			62.564,
			62.588,
			62.651,
			62.668,
			62.713,
			62.776,
			62.838,
			62.9,
			62.961,
			62.993,
			63.023,
			63.086,
			63.148,
			63.211,
			63.271,
			63.332,
			63.395,
			63.426,
			63.455,
			63.519,
			63.535,
			63.581,
			63.643,
			63.705,
			63.751,
			63.767,
			63.829,
			63.858,
			63.892,
			63.953,
			63.968,
			64.016,
			64.078,
			64.138,
			64.186,
			64.264,
			64.294,
			64.327,
			64.403,
			64.448,
			64.511,
			64.573,
			64.635,
			64.696,
			64.729,
			64.76,
			64.822,
			64.837,
			64.883,
			64.946,
			65.007,
			65.054,
			65.069,
			65.131,
			65.162,
			65.195,
			65.254,
			65.27,
			65.318,
			65.38,
			65.441,
			65.489,
			65.566,
			65.597,
			65.628,
			65.69,
			65.752,
			65.815,
			65.876,
			65.938,
			65.999,
			66.031,
			66.061,
			66.122,
			66.138,
			66.186,
			66.249,
			66.311,
			66.357,
			66.371,
			66.435,
			66.466,
			66.497,
			66.556,
			66.574,
			66.619,
			66.681,
			66.686,
			66.745,
			66.791,
			66.869,
			66.898,
			66.929,
			66.991,
			67.055,
			67.114,
			67.178,
			67.224,
			67.24,
			67.3,
			67.332,
			67.362,
			67.44,
			67.488,
			67.549,
			67.612,
			67.658,
			67.672,
			67.735,
			67.768,
			67.798,
			67.858,
			67.874,
			67.921,
			67.984,
			68.044,
			68.105,
			68.17,
			68.202,
			68.233,
			68.294,
			68.311,
			68.356,
			68.418,
			68.48,
			68.541,
			68.603,
			68.636,
			68.665,
			68.727,
			68.75,
			68.789,
			68.851,
			68.914,
			68.976,
			69.039,
			69.069,
			69.1,
			69.161,
			69.225,
			69.286,
			69.347,
			69.395,
			69.411,
			69.473,
			69.503,
			69.535,
			69.595,
			69.61,
			69.658,
			69.72,
			69.782,
			69.829,
			69.845,
			69.907,
			69.937,
			69.968,
			70.031,
			70.047,
			70.092,
			70.153,
			70.215,
			70.278,
			70.34,
			70.372,
			70.402,
			70.465,
			70.524,
			70.588,
			70.65,
			70.697,
			70.711,
			70.774,
			70.811,
			70.837,
			70.914,
			70.962,
			71.021,
			71.084,
			71.132,
			71.146,
			71.207,
			71.238,
			71.27,
			71.332,
			71.348,
			71.395,
			71.455,
			71.52,
			71.565,
			71.643,
			71.672,
			71.706,
			71.767,
			71.828,
			71.89,
			71.952,
			71.999,
			72.013,
			72.077,
			72.108,
			72.138,
			72.2,
			72.217,
			72.263,
			72.326,
			72.387,
			72.433,
			72.448,
			72.511,
			72.54,
			72.571,
			72.635,
			72.697,
			72.759,
			72.819,
			72.883,
			72.944,
			72.975,
			73.006,
			73.069,
			73.085,
			73.13,
			73.191,
			73.255,
			73.317,
			73.379,
			73.411,
			73.439,
			73.502,
			73.519,
			73.565,
			73.628,
			73.689,
			73.735,
			73.811,
			73.844,
			73.876,
			73.937,
			74,
			74.062,
			74.122,
			74.169,
			74.186,
			74.247,
			74.277,
			74.309,
			74.371,
			74.387,
			74.432,
			74.494,
			74.556,
			74.604,
			74.62,
			74.681,
			74.711,
			74.743,
			74.805,
			74.82,
			74.866,
			74.929,
			74.935,
			74.992,
			75.037,
			75.114,
			75.147,
			75.177,
			75.254,
			75.3,
			75.364,
			75.426,
			75.487,
			75.548,
			75.581,
			75.611,
			75.673,
			75.689,
			75.735,
			75.798,
			75.857,
			75.92,
			75.984,
			76.014,
			76.045,
			76.106,
			76.123,
			76.171,
			76.231,
			76.293,
			76.339,
			76.418,
			76.447,
			76.479,
			76.54,
			76.602,
			76.667,
			76.727,
			76.772,
			76.789,
			76.85,
			76.884,
			76.914,
			76.974,
			76.996,
			77.037,
			77.1,
			77.161,
			77.209,
			77.284,
			77.315,
			77.348,
			77.41,
			77.47,
			77.533,
			77.597,
			77.641,
			77.72,
			77.75,
			77.781,
			77.859,
			77.905,
			77.967,
			78.03,
			78.076,
			78.09,
			78.154,
			78.185,
			78.215,
			78.243
		]
	],
	'6657_7027_7982': [
		[
			81.993
		],
		[
			0
		],
		[
			0,
			1.693,
			5.525,
			6.807,
			8.091,
			11.957,
			14.542,
			18.435,
			21.04,
			23.645,
			24.949,
			27.561,
			28.869,
			31.492,
			34.123,
			35.441,
			38.082,
			40.73,
			44.716,
			46.048,
			47.382,
			50.056,
			52.737,
			54.081,
			58.123,
			60.826,
			63.537,
			67.617,
			70.346,
			74.454,
			75.827,
			77.202,
			81.339,
			84.105,
			88.27,
			91.06,
			95.263,
			98.076,
			100.895,
			102.307,
			106.546,
			109.378,
			112.212,
			116.479,
			119.333,
			123.635,
			125.073,
			126.512,
			130.842,
			133.739,
			138.1,
			141.02,
			143.948,
			148.356,
			149.83,
			151.302,
			155.727,
			157.205,
			158.685,
			163.137,
			166.115,
			169.1,
			170.596,
			173.592,
			176.595,
			178.099,
			181.112,
			182.621,
			185.645,
			188.675,
			193.233,
			196.28,
			200.863,
			202.39,
			203.919,
			208.51,
			211.576,
			216.209,
			219.315,
			222.426,
			223.982,
			227.097,
			228.656,
			231.778,
			234.905,
			236.471,
			239.607,
			242.75,
			245.898,
			250.633,
			253.796,
			256.965,
			258.552,
			261.73,
			263.322,
			266.507,
			269.694,
			274.484,
			277.684,
			282.492,
			284.098,
			285.705,
			290.534,
			293.76,
			298.609,
			301.848,
			305.092,
			306.716,
			309.968,
			311.596,
			314.855,
			318.12,
			319.754,
			323.026,
			326.304,
			331.23,
			332.875,
			334.52,
			337.815,
			341.116,
			342.768,
			346.076,
			347.732,
			351.048,
			354.368,
			359.358,
			362.691,
			367.7,
			369.372,
			371.046,
			377.751,
			379.431,
			384.473,
			387.833,
			392.855,
			396.177,
			399.47,
			401.104,
			404.344,
			405.95,
			409.134,
			412.28,
			416.927,
			419.98,
			424.493,
			425.98,
			427.458,
			431.834,
			434.705,
			438.941,
			441.718,
			444.458,
			445.814,
			448.498,
			449.826,
			451.145,
			456.328,
			457.601,
			461.364,
			463.827,
			466.253,
			467.453,
			469.828,
			472.17,
			473.329,
			476.747,
			478.98,
			481.175,
			484.396,
			486.498,
			489.582,
			490.593,
			491.595,
			494.547,
			496.468,
			499.279,
			501.11,
			503.795,
			505.544,
			506.408,
			508.108,
			509.772,
			510.59,
			511.401,
			513.78,
			515.323,
			516.845,
			517.589,
			519.049,
			520.476,
			521.178,
			522.553,
			523.229,
			524.563,
			525.879,
			527.801,
			529.05,
			530.899,
			531.511,
			532.123,
			533.948,
			535.157,
			536.974,
			538.242,
			540.136,
			541.4,
			542.033,
			543.343,
			544.65,
			545.304,
			546.612,
			547.941,
			549.946,
			550.614,
			551.282,
			553.332,
			554.026,
			554.72,
			556.8,
			558.236,
			559.692,
			561.868,
			563.306,
			565.449,
			566.164,
			566.878,
			568.963,
			570.334,
			572.393,
			573.724,
			575.048,
			575.714,
			577.052,
			578.393,
			579.061,
			581.083,
			582.451,
			583.835,
			585.934,
			587.351,
			589.508,
			590.234,
			590.963,
			593.172,
			594.693,
			597.006,
			598.565,
			600.153,
			602.561,
			603.371,
			604.184,
			606.626,
			607.452,
			608.282,
			610.79,
			612.473,
			614.156,
			615,
			616.702,
			618.418,
			619.282,
			621.02,
			621.895,
			623.656,
			625.429,
			628.108,
			629.911,
			632.641,
			633.558,
			634.478,
			637.264,
			639.14,
			641.98,
			643.891,
			645.815,
			646.782,
			648.725,
			649.701,
			651.664,
			653.639,
			654.631,
			655.626,
			658.63,
			660.642,
			662.658,
			663.665,
			665.671,
			667.656,
			668.638,
			671.534,
			673.419,
			675.267,
			677.975,
			679.746,
			682.344,
			683.196,
			684.042,
			686.559,
			688.227,
			690.732,
			692.384,
			694.029,
			694.848,
			696.481,
			697.295,
			698.915,
			700.52,
			701.32,
			702.917,
			704.498,
			706.852,
			707.636,
			708.419,
			710.774,
			711.557,
			712.34,
			714.746,
			716.386,
			718.024,
			720.662,
			722.477,
			725.24,
			726.176,
			727.112,
			729.884,
			730.782,
			731.679,
			734.366,
			736.13,
			737.892,
			738.773,
			740.547,
			742.328,
			743.203,
			744.992,
			745.865,
			747.615,
			749.374,
			751.933,
			753.632,
			756.166,
			757.002,
			757.841,
			760.376,
			762.084,
			763.803,
			766.407,
			768.159,
			770.81,
			771.699,
			772.591,
			775.284,
			776.188,
			777.094,
			779.816,
			781.655,
			783.506,
			784.436,
			786.306,
			788.185,
			789.127,
			791.957,
			793.84,
			795.717,
			798.52,
			800.394,
			803.166,
			804.085,
			805.001,
			807.739,
			809.552,
			812.258,
			814.07,
			816.774,
			818.583,
			819.488,
			821.294,
			823.104,
			824.019,
			824.932,
			827.667,
			829.508,
			831.348,
			832.267,
			834.114,
			835.964,
			836.889,
			838.747,
			839.687,
			841.566,
			843.447,
			846.312,
			848.225,
			851.079,
			852.03,
			852.981,
			855.807,
			857.684,
			860.479,
			862.333,
			864.19,
			865.116,
			866.971,
			867.901,
			869.765,
			872.572,
			874.453,
			876.333,
			879.166,
			880.109,
			881.05,
			883.877,
			884.822,
			885.768,
			887.659,
			888.606,
			890.503,
			893.372,
			895.286,
			897.206,
			900.088,
			901.049,
			902.008,
			904.888,
			906.808,
			909.663,
			911.563,
			913.44,
			914.37,
			916.209,
			917.114,
			918.898,
			920.662,
			921.524,
			923.234,
			924.917,
			927.388,
			928.211,
			929.031,
			931.504,
			932.325,
			933.146,
			935.61,
			937.256,
			939.722,
			941.365,
			943.007,
			943.827,
			945.467,
			946.286,
			947.107,
			950.395,
			951.217,
			953.72,
			955.402,
			957.083,
			957.945,
			959.688,
			961.434,
			962.308,
			964.993,
			966.791,
			968.604,
			971.352,
			973.191,
			975.928,
			976.849,
			977.772,
			980.438,
			982.221,
			984.856,
			986.599,
			989.234,
			991.001,
			991.89,
			993.678,
			995.48,
			996.386,
			998.207,
			1000.069,
			1001.956,
			1004.846,
			1006.848,
			1008.864,
			1009.885,
			1011.964,
			1013.001,
			1015.081,
			1017.155,
			1020.291,
			1022.403,
			1025.605,
			1026.693,
			1027.784,
			1031.058,
			1033.23,
			1036.492,
			1038.666,
			1040.846,
			1041.95,
			1044.157,
			1046.357,
			1047.444,
			1049.614,
			1050.697,
			1052.829,
			1054.943,
			1057.048,
			1059.135,
			1060.177,
			1062.258,
			1064.335,
			1065.375,
			1067.457,
			1068.498,
			1070.584,
			1072.671,
			1075.802,
			1077.894,
			1081.018,
			1082.061,
			1083.101,
			1086.209,
			1088.306,
			1091.495,
			1093.633,
			1095.801,
			1096.89,
			1099.06,
			1100.16,
			1102.368,
			1105.682,
			1107.899,
			1110.121,
			1113.469,
			1115.716,
			1119.107,
			1120.243,
			1121.381,
			1124.812,
			1127.111,
			1130.578,
			1132.901,
			1135.234,
			1138.751,
			1139.928,
			1141.105,
			1145.831,
			1147.017,
			1150.587,
			1152.983,
			1155.391,
			1156.598,
			1159.026,
			1161.464,
			1162.686,
			1165.142,
			1166.373,
			1168.845,
			1171.329,
			1175.071,
			1177.577,
			1181.347,
			1182.608,
			1183.87,
			1187.67,
			1190.214,
			1194.044,
			1196.609,
			1199.182,
			1200.471,
			1203.057,
			1204.352,
			1206.949,
			1210.857,
			1213.463,
			1216.076,
			1218.697,
			1221.325,
			1222.642,
			1225.282,
			1227.929,
			1229.255,
			1233.245,
			1235.913,
			1238.589,
			1242.617,
			1245.311,
			1249.367,
			1250.722,
			1252.079,
			1256.161,
			1258.893,
			1263.007,
			1265.76,
			1268.521,
			1269.904,
			1272.674,
			1274.061,
			1276.84,
			1279.623,
			1281.018,
			1283.813,
			1286.615,
			1290.831,
			1292.24,
			1293.651,
			1297.894,
			1299.312,
			1300.732,
			1303.576,
			1305,
			1307.855,
			1310.716,
			1315.021,
			1317.899,
			1322.229,
			1323.675,
			1325.124,
			1329.479,
			1332.391,
			1336.771,
			1339.699,
			1342.634,
			1344.104,
			1347.049,
			1348.524,
			1351.478,
			1355.922,
			1358.892,
			1361.868,
			1366.345,
			1367.841,
			1369.337,
			1373.838,
			1375.341,
			1376.845,
			1379.858,
			1381.367,
			1384.389,
			1388.936,
			1391.976,
			1395.022,
			1399.603,
			1401.132,
			1402.663,
			1408.8,
			1410.337,
			1414.958,
			1418.045,
			1421.138,
			1422.687,
			1425.788,
			1428.894,
			1430.449,
			1433.564,
			1435.122,
			1438.24,
			1441.355,
			1445.997,
			1449.059,
			1453.585,
			1455.074,
			1456.553,
			1460.931,
			1463.802,
			1468.035,
			1470.81,
			1473.546,
			1474.898,
			1477.573,
			1478.896,
			1481.513,
			1485.372,
			1487.899,
			1490.391,
			1492.847,
			1496.461,
			1498.827,
			1501.158,
			1502.311,
			1504.589,
			1505.713,
			1507.935,
			1510.119,
			1513.326,
			1515.417,
			1518.483,
			1519.487,
			1520.482,
			1523.411,
			1525.317,
			1528.109,
			1529.927,
			1531.712,
			1532.592,
			1534.326,
			1535.179,
			1536.86,
			1539.312,
			1540.903,
			1542.458,
			1544.74,
			1545.483,
			1546.218,
			1547.672,
			1549.107,
			1549.82,
			1551.241,
			1551.947,
			1553.355,
			1554.76,
			1556.875,
			1558.284,
			1560.39,
			1561.092,
			1561.808,
			1563.95,
			1565.37,
			1567.515,
			1568.943,
			1570.363,
			1571.071,
			1572.513,
			1573.237,
			1574.68,
			1576.118,
			1576.858,
			1578.403,
			1579.944,
			1582.284,
			1583.93,
			1586.392,
			1587.199,
			1587.988,
			1590.345,
			1591.913,
			1593.441,
			1595.714,
			1597.226,
			1599.49,
			1600.24,
			1600.99,
			1603.927,
			1604.65,
			1606.82,
			1608.235,
			1609.63,
			1610.325,
			1611.715,
			1613.094,
			1613.775,
			1615.815,
			1617.172,
			1618.496,
			1620.457,
			1621.762,
			1623.722,
			1624.375,
			1625.026,
			1626.983,
			1628.313,
			1630.348,
			1631.708,
			1633.075,
			1633.774,
			1635.174,
			1635.876,
			1637.287,
			1638.713,
			1639.433,
			1640.156,
			1642.337,
			1643.791,
			1645.27,
			1646.015,
			1647.518,
			1649.036,
			1649.802,
			1651.345,
			1652.123,
			1653.69,
			1655.272,
			1657.675,
			1659.296,
			1661.755,
			1662.583,
			1663.413,
			1665.927,
			1667.615,
			1670.146,
			1671.846,
			1673.56,
			1674.422,
			1676.152,
			1677.022,
			1678.771,
			1680.533,
			1681.418,
			1683.199,
			1684.993,
			1687.714,
			1688.627,
			1689.544,
			1691.39,
			1693.249,
			1694.184,
			1696.063,
			1697.007,
			1698.906,
			1700.818,
			1703.709,
			1705.652,
			1708.59,
			1709.576,
			1710.565,
			1713.549,
			1715.553,
			1718.583,
			1720.617,
			1722.663,
			1723.69,
			1725.753,
			1726.788,
			1728.868,
			1732.006,
			1734.101,
			1736.198,
			1739.359,
			1740.418,
			1741.479,
			1744.679,
			1745.75,
			1746.824,
			1750.054,
			1752.219,
			1755.48,
			1757.666,
			1759.872,
			1763.206,
			1764.328,
			1765.454,
			1769.984,
			1771.121,
			1774.544,
			1776.83,
			1780.296,
			1782.606,
			1784.906,
			1786.067,
			1789.568,
			1791.904,
			1794.249,
			1797.784,
			1800.152,
			1803.726,
			1804.922,
			1806.121,
			1809.734,
			1812.161,
			1815.84,
			1818.301,
			1820.763,
			1824.504,
			1825.752,
			1828.27,
			1830.794,
			1832.061,
			1834.602,
			1837.145,
			1839.682,
			1842.217,
			1843.474,
			1845.987,
			1848.488,
			1849.733,
			1852.219,
			1853.464,
			1855.951,
			1858.432,
			1862.143,
			1864.611,
			1868.309,
			1869.55,
			1870.791,
			1874.515,
			1877,
			1880.738,
			1883.239,
			1885.784,
			1887.068,
			1889.647,
			1890.95,
			1893.559,
			1896.186,
			1897.504,
			1900.136,
			1902.724,
			1906.577,
			1907.84,
			1909.104,
			1911.63,
			1914.15,
			1915.411,
			1917.936,
			1919.199,
			1921.728,
			1924.234,
			1928.056,
			1930.599,
			1934.426,
			1935.705,
			1936.984,
			1940.826,
			1943.392,
			1947.25,
			1949.83,
			1952.416,
			1953.711,
			1956.307,
			1957.608,
			1960.213,
			1964.12,
			1966.729,
			1969.344,
			1973.256,
			1975.886,
			1979.842,
			1981.164,
			1982.487,
			1986.46,
			1989.136,
			1993.121,
			1995.766,
			1998.454,
			2002.51,
			2003.871,
			2005.234,
			2009.33,
			2010.698,
			2012.064,
			2016.165,
			2018.901,
			2023.016,
			2025.767,
			2028.524,
			2029.905,
			2032.672,
			2034.058,
			2036.834,
			2039.616,
			2043.802,
			2046.599,
			2050.804,
			2052.209,
			2053.615,
			2057.841,
			2060.667,
			2064.915,
			2067.755,
			2070.601,
			2074.881,
			2076.31,
			2079.173,
			2082.042,
			2083.478,
			2084.914,
			2089.219,
			2092.073,
			2094.904,
			2096.308,
			2099.091,
			2101.834,
			2103.192,
			2107.203,
			2109.83,
			2112.418,
			2116.236,
			2118.739,
			2122.424,
			2123.631,
			2124.83,
			2128.366,
			2130.674,
			2134.057,
			2136.26,
			2138.422,
			2139.489,
			2141.592,
			2142.629,
			2144.678,
			2146.69,
			2147.682,
			2148.665,
			2151.574,
			2154.386,
			2155.306,
			2156.221,
			2158.032,
			2159.821,
			2160.708,
			2163.361,
			2165.13,
			2166.901,
			2169.57,
			2171.358,
			2174.044,
			2174.944,
			2175.846,
			2178.551,
			2180.356,
			2183.071,
			2184.876,
			2186.687,
			2187.591,
			2189.394,
			2190.294,
			2192.101,
			2193.901,
			2194.799,
			2196.596,
			2198.395,
			2201.082,
			2201.98,
			2202.878,
			2205.563,
			2206.457,
			2207.359,
			2209.16,
			2210.06,
			2211.862,
			2214.6,
			2216.425,
			2218.276,
			2221.062,
			2221.99,
			2222.924,
			2225.712,
			2227.561,
			2230.334,
			2232.173,
			2233.998,
			2234.907,
			2236.721,
			2238.526,
			2239.423,
			2241.216,
			2242.112,
			2243.894,
			2245.669,
			2248.325,
			2250.08,
			2252.713,
			2253.588,
			2254.462,
			2257.086,
			2258.826,
			2261.419,
			2263.15,
			2264.863,
			2265.721,
			2267.442,
			2268.291,
			2270.02,
			2271.761,
			2272.636,
			2273.514,
			2276.167,
			2277.95,
			2279.745,
			2280.647,
			2282.461,
			2284.287,
			2285.205,
			2287.971,
			2289.819,
			2291.666,
			2294.431,
			2296.282,
			2299.031,
			2299.953,
			2300.878,
			2303.66,
			2305.512,
			2308.295,
			2310.137,
			2311.959,
			2312.867,
			2314.678,
			2315.583,
			2317.383,
			2320.07,
			2321.862,
			2323.642,
			2325.416,
			2327.186,
			2328.066,
			2329.818,
			2331.567,
			2332.437,
			2335.03,
			2336.76,
			2338.49,
			2341.08,
			2342.816,
			2345.417,
			2346.286,
			2347.158,
			2349.778,
			2351.529,
			2354.166,
			2355.927,
			2357.683,
			2358.558,
			2360.311,
			2361.189,
			2362.941,
			2364.689,
			2365.565,
			2367.323,
			2369.116,
			2371.819,
			2372.729,
			2373.652,
			2376.429,
			2377.358,
			2378.297,
			2381.161,
			2383.076,
			2384.987,
			2387.867,
			2389.774,
			2392.645,
			2393.599,
			2394.548,
			2397.405,
			2398.36,
			2399.301,
			2402.136,
			2404.03,
			2406.859,
			2408.759,
			2409.707,
			2411.611,
			2413.527,
			2414.487,
			2416.405,
			2418.332,
			2421.23,
			2423.166,
			2426.07,
			2427.037,
			2428.004,
			2430.9,
			2432.823,
			2435.708,
			2437.626,
			2439.546,
			2440.51,
			2442.451,
			2443.424,
			2445.374,
			2447.35,
			2448.342,
			2449.337,
			2452.338,
			2454.357,
			2456.386,
			2457.407,
			2459.458,
			2461.519,
			2462.553,
			2464.613,
			2465.655,
			2467.747,
			2469.848,
			2472.997,
			2475.101,
			2478.272,
			2479.333,
			2480.396,
			2483.6,
			2485.746,
			2488.987,
			2491.162,
			2493.346,
			2494.441,
			2496.637,
			2497.739,
			2499.941,
			2503.257,
			2505.477,
			2507.707,
			2511.077,
			2512.207,
			2513.339,
			2515.615,
			2517.903,
			2519.05,
			2521.354,
			2522.509,
			2524.827,
			2527.154,
			2530.661,
			2533.011,
			2536.554,
			2537.739,
			2538.927,
			2542.504,
			2544.9,
			2548.51,
			2550.915,
			2553.305,
			2554.49,
			2556.836,
			2557.995,
			2560.284,
			2562.534,
			2563.645,
			2565.837,
			2567.991,
			2571.152,
			2573.211,
			2576.228,
			2577.215,
			2578.192,
			2581.067,
			2582.938,
			2584.773,
			2587.461,
			2589.21,
			2591.766,
			2592.599,
			2593.423,
			2595.839,
			2597.404,
			2599.682,
			2601.155,
			2602.591,
			2603.296,
			2604.682,
			2606.038,
			2606.707,
			2608.042,
			2608.7,
			2610.01,
			2611.315,
			2613.278,
			2614.588,
			2616.564,
			2617.227,
			2617.891,
			2619.89,
			2621.225,
			2623.228,
			2624.566,
			2625.906,
			2626.574,
			2627.911,
			2628.579,
			2629.248,
			2631.305,
			2631.99,
			2632.673,
			2634.722,
			2636.118,
			2637.509,
			2638.203,
			2639.587,
			2640.982,
			2641.684,
			2643.772,
			2645.155,
			2646.548,
			2648.623,
			2649.993,
			2652.057,
			2652.744,
			2653.427,
			2655.465,
			2656.85,
			2658.992,
			2660.405,
			2661.81,
			2662.509,
			2663.893,
			2664.581,
			2665.948,
			2667.291,
			2667.941,
			2669.23,
			2670.509,
			2671.782,
			2673.081,
			2673.724,
			2675.004,
			2676.28,
			2676.917,
			2678.208,
			2678.85,
			2680.128,
			2681.403,
			2683.297,
			2684.544,
			2686.411,
			2687.035,
			2687.66,
			2689.484,
			2690.094,
			2690.706,
			2692.546,
			2693.766,
			2694.98,
			2695.59,
			2696.813,
			2697.428,
			2698.667,
			2699.919,
			2700.548,
			2701.813,
			2703.089,
			2705.018,
			2705.666,
			2706.317,
			2708.293,
			2708.961,
			2709.633,
			2711.669,
			2713.036,
			2715.137,
			2716.557,
			2717.99,
			2720.159,
			2720.89,
			2721.625,
			2724.591,
			2725.341,
			2727.619,
			2729.169,
			2731.522,
			2733.112,
			2734.72,
			2735.529,
			2737.159,
			2737.979,
			2739.63,
			2741.295,
			2743.817,
			2745.502,
			2748.035,
			2748.886,
			2749.74,
			2752.32,
			2754.054,
			2756.675,
			2758.437,
			2760.209,
			2761.1,
			2762.891,
			2763.791,
			2764.697,
			2767.432,
			2769.273,
			2772.062,
			2773.938,
			2776.774,
			2778.681,
			2780.6,
			2781.564,
			2783.501,
			2784.474,
			2786.43,
			2788.397,
			2791.37,
			2793.366,
			2796.382,
			2797.393,
			2798.407,
			2801.465,
			2803.518,
			2806.619,
			2808.7,
			2810.792,
			2811.84,
			2813.934,
			2814.981,
			2817.079,
			2819.186,
			2820.243,
			2822.366,
			2824.5,
			2827.721,
			2828.801,
			2829.883,
			2832.054,
			2834.234,
			2835.328,
			2838.619,
			2840.823,
			2843.037,
			2846.374,
			2848.611,
			2851.984,
			2853.113,
			2854.244,
			2857.652,
			2859.935,
			2863.378,
			2865.685,
			2869.162,
			2871.492,
			2872.66,
			2875.004,
			2877.357,
			2878.537,
			2880.903,
			2883.279,
			2886.859,
			2889.257,
			2892.872,
			2894.081,
			2895.292,
			2898.939,
			2901.382,
			2903.833,
			2907.526,
			2909.999,
			2913.722,
			2914.968,
			2916.215,
			2919.97,
			2922.484,
			2926.27,
			2928.803,
			2932.618,
			2935.171,
			2936.451,
			2939.014,
			2941.584,
			2942.87,
			2945.446,
			2948.029,
			2951.917,
			2953.22,
			2954.522,
			2958.435,
			2959.743,
			2961.054,
			2964.992,
			2967.623,
			2971.583,
			2974.228,
			2976.874,
			2978.193,
			2980.821,
			2982.127,
			2983.427,
			2987.274,
			2988.538,
			2989.792,
			2993.496,
			2995.917,
			2998.301,
			2999.48,
			3001.812,
			3004.109,
			3005.242,
			3007.48,
			3008.585,
			3010.766,
			3012.908,
			3016.051,
			3018.099,
			3021.101,
			3022.082,
			3023.055,
			3025.916,
			3027.778,
			3030.505,
			3032.281,
			3034.877,
			3036.562,
			3037.39,
			3039.019,
			3040.611,
			3041.392,
			3042.162,
			3044.417,
			3046.588,
			3047.286,
			3047.976,
			3049.34,
			3050.651,
			3051.302,
			3052.584,
			3053.218,
			3054.475,
			3055.729,
			3057.595,
			3058.836,
			3060.693,
			3061.312,
			3061.934,
			3063.791,
			3065.024,
			3066.876,
			3068.112,
			3069.34,
			3069.953,
			3071.176,
			3071.788,
			3073.037,
			3074.283,
			3074.904,
			3076.143,
			3077.385,
			3079.291,
			3079.922,
			3080.552,
			3082.44,
			3083.72,
			3084.993,
			3085.627,
			3086.894,
			3088.185,
			3090.14,
			3091.438,
			3093.413,
			3094.094,
			3094.773,
			3096.806,
			3097.483,
			3098.164,
			3100.26,
			3101.65,
			3103.042,
			3103.746,
			3105.168,
			3105.875,
			3107.288,
			3109.434,
			3110.886,
			3112.33,
			3114.486,
			3115.902,
			3118.013,
			3118.717,
			3119.419,
			3121.506,
			3122.891,
			3124.981,
			3126.387,
			3128.499,
			3129.919,
			3130.64,
			3132.084,
			3133.534,
			3134.261,
			3134.987,
			3137.132,
			3138.566,
			3140.001,
			3140.693,
			3142.077,
			3143.465,
			3144.162,
			3145.534,
			3146.204,
			3147.545,
			3148.893,
			3150.927,
			3152.283,
			3154.332,
			3155.02,
			3155.72,
			3157.842,
			3159.262,
			3161.415,
			3162.868,
			3165.056,
			3166.527,
			3167.269,
			3168.756,
			3170.25,
			3170.999,
			3172.511,
			3174.034,
			3175.553,
			3177.102,
			3177.885,
			3179.462,
			3181.054,
			3181.856,
			3184.283,
			3185.919,
			3187.57,
			3190.072,
			3191.756,
			3194.284,
			3195.129,
			3195.976,
			3198.541,
			3200.267,
			3202.881,
			3204.641,
			3206.414,
			3207.305,
			3209.098,
			3210,
			3211.812,
			3213.638,
			3214.554,
			3216.397,
			3218.252,
			3221.058,
			3222,
			3222.944,
			3225.799,
			3226.757,
			3227.719,
			3230.624,
			3232.577,
			3234.543,
			3237.519,
			3239.518,
			3242.54,
			3243.552,
			3244.568,
			3247.63,
			3249.685,
			3252.788,
			3254.87,
			3256.963,
			3258.013,
			3260.111,
			3262.213,
			3263.267,
			3265.384,
			3266.447,
			3268.579,
			3270.721,
			3273.95,
			3276.115,
			3279.379,
			3280.472,
			3281.568,
			3284.87,
			3287.084,
			3290.424,
			3292.663,
			3294.913,
			3298.306,
			3299.442,
			3300.581,
			3304.012,
			3305.161,
			3306.313,
			3309.785,
			3312.114,
			3314.45,
			3315.622,
			3317.972,
			3320.329,
			3321.51,
			3323.881,
			3325.069,
			3327.453,
			3329.845,
			3333.447,
			3335.861,
			3339.498,
			3340.716,
			3341.937,
			3345.611,
			3348.073,
			3351.783,
			3354.268,
			3356.761,
			3358.011,
			3360.518,
			3361.774,
			3363.033,
			3366.821,
			3368.087,
			3370.626,
			3373.174,
			3374.943
		],
		[
			0,
			0.023,
			0.1,
			0.133,
			0.161,
			0.224,
			0.286,
			0.347,
			0.409,
			0.458,
			0.474,
			0.535,
			0.567,
			0.596,
			0.66,
			0.673,
			0.721,
			0.784,
			0.844,
			0.891,
			0.906,
			0.968,
			1.001,
			1.029,
			1.108,
			1.156,
			1.216,
			1.28,
			1.324,
			1.404,
			1.434,
			1.465,
			1.527,
			1.59,
			1.656,
			1.712,
			1.774,
			1.836,
			1.868,
			1.899,
			1.975,
			2.024,
			2.087,
			2.146,
			2.194,
			2.272,
			2.302,
			2.333,
			2.394,
			2.458,
			2.52,
			2.581,
			2.626,
			2.706,
			2.736,
			2.768,
			2.829,
			2.845,
			2.891,
			2.951,
			3.014,
			3.062,
			3.078,
			3.139,
			3.171,
			3.202,
			3.262,
			3.278,
			3.326,
			3.389,
			3.45,
			3.495,
			3.574,
			3.603,
			3.636,
			3.713,
			3.76,
			3.82,
			3.883,
			3.93,
			3.945,
			4.008,
			4.037,
			4.069,
			4.131,
			4.146,
			4.194,
			4.256,
			4.316,
			4.38,
			4.443,
			4.473,
			4.502,
			4.566,
			4.581,
			4.627,
			4.691,
			4.751,
			4.799,
			4.875,
			4.907,
			4.938,
			5,
			5.061,
			5.125,
			5.186,
			5.231,
			5.247,
			5.311,
			5.34,
			5.372,
			5.434,
			5.45,
			5.497,
			5.559,
			5.621,
			5.667,
			5.683,
			5.743,
			5.774,
			5.806,
			5.868,
			5.885,
			5.928,
			5.991,
			6.052,
			6.1,
			6.177,
			6.21,
			6.24,
			6.318,
			6.365,
			6.427,
			6.489,
			6.549,
			6.611,
			6.644,
			6.673,
			6.737,
			6.75,
			6.799,
			6.86,
			6.922,
			6.969,
			7.045,
			7.078,
			7.109,
			7.186,
			7.233,
			7.295,
			7.357,
			7.403,
			7.419,
			7.481,
			7.51,
			7.542,
			7.621,
			7.664,
			7.728,
			7.791,
			7.837,
			7.851,
			7.913,
			7.948,
			7.975,
			8.053,
			8.101,
			8.163,
			8.225,
			8.269,
			8.348,
			8.378,
			8.411,
			8.473,
			8.535,
			8.597,
			8.657,
			8.719,
			8.783,
			8.812,
			8.845,
			8.907,
			8.923,
			8.967,
			9.031,
			9.093,
			9.14,
			9.152,
			9.216,
			9.248,
			9.279,
			9.34,
			9.357,
			9.403,
			9.464,
			9.526,
			9.589,
			9.651,
			9.681,
			9.712,
			9.776,
			9.835,
			9.899,
			9.96,
			10.022,
			10.084,
			10.116,
			10.147,
			10.209,
			10.225,
			10.27,
			10.333,
			10.393,
			10.441,
			10.457,
			10.518,
			10.55,
			10.58,
			10.644,
			10.706,
			10.765,
			10.829,
			10.874,
			10.953,
			10.985,
			11.014,
			11.075,
			11.139,
			11.2,
			11.261,
			11.308,
			11.325,
			11.387,
			11.419,
			11.449,
			11.526,
			11.572,
			11.636,
			11.697,
			11.744,
			11.822,
			11.85,
			11.882,
			11.943,
			12.008,
			12.073,
			12.13,
			12.178,
			12.256,
			12.286,
			12.316,
			12.379,
			12.393,
			12.44,
			12.503,
			12.566,
			12.611,
			12.627,
			12.689,
			12.719,
			12.751,
			12.812,
			12.828,
			12.875,
			12.936,
			12.999,
			13.046,
			13.123,
			13.152,
			13.185,
			13.248,
			13.308,
			13.372,
			13.434,
			13.478,
			13.496,
			13.558,
			13.589,
			13.62,
			13.681,
			13.698,
			13.742,
			13.803,
			13.867,
			13.912,
			13.928,
			13.99,
			14.022,
			14.052,
			14.133,
			14.177,
			14.239,
			14.3,
			14.348,
			14.426,
			14.457,
			14.485,
			14.548,
			14.61,
			14.673,
			14.735,
			14.782,
			14.797,
			14.858,
			14.891,
			14.923,
			14.983,
			14.998,
			15.046,
			15.107,
			15.17,
			15.215,
			15.231,
			15.294,
			15.324,
			15.353,
			15.432,
			15.479,
			15.54,
			15.604,
			15.65,
			15.727,
			15.759,
			15.79,
			15.852,
			15.868,
			15.915,
			15.975,
			16.037,
			16.083,
			16.099,
			16.162,
			16.197,
			16.225,
			16.285,
			16.3,
			16.346,
			16.411,
			16.472,
			16.532,
			16.597,
			16.628,
			16.657,
			16.72,
			16.782,
			16.842,
			16.906,
			16.952,
			17.028,
			17.06,
			17.091,
			17.153,
			17.17,
			17.215,
			17.277,
			17.341,
			17.388,
			17.403,
			17.463,
			17.493,
			17.525,
			17.603,
			17.649,
			17.711,
			17.775,
			17.819,
			17.896,
			17.929,
			17.961,
			18.02,
			18.082,
			18.147,
			18.208,
			18.271,
			18.332,
			18.363,
			18.394,
			18.457,
			18.471,
			18.517,
			18.58,
			18.642,
			18.689,
			18.703,
			18.765,
			18.798,
			18.828,
			18.891,
			18.906,
			18.951,
			19.015,
			19.077,
			19.123,
			19.201,
			19.231,
			19.26,
			19.325,
			19.386,
			19.449,
			19.51,
			19.556,
			19.573,
			19.633,
			19.666,
			19.697,
			19.774,
			19.819,
			19.883,
			19.944,
			19.99,
			20.006,
			20.068,
			20.098,
			20.129,
			20.191,
			20.207,
			20.254,
			20.319,
			20.377,
			20.439,
			20.502,
			20.533,
			20.563,
			20.627,
			20.69,
			20.751,
			20.812,
			20.858,
			20.875,
			20.938,
			20.967,
			20.999,
			21.06,
			21.076,
			21.123,
			21.189,
			21.247,
			21.293,
			21.309,
			21.371,
			21.402,
			21.433,
			21.51,
			21.557,
			21.619,
			21.679,
			21.728,
			21.744,
			21.805,
			21.835,
			21.865,
			21.945,
			21.99,
			22.053,
			22.113,
			22.162,
			22.178,
			22.24,
			22.269,
			22.299,
			22.383,
			22.425,
			22.486,
			22.548,
			22.596,
			22.673,
			22.703,
			22.735,
			22.796,
			22.857,
			22.921,
			22.983,
			23.043,
			23.107,
			23.138,
			23.17,
			23.23,
			23.246,
			23.294,
			23.353,
			23.417,
			23.48,
			23.54,
			23.57,
			23.602,
			23.663,
			23.679,
			23.726,
			23.791,
			23.85,
			23.898,
			23.975,
			24.006,
			24.037,
			24.1,
			24.161,
			24.224,
			24.286,
			24.331,
			24.347,
			24.408,
			24.444,
			24.471,
			24.533,
			24.549,
			24.595,
			24.658,
			24.718,
			24.765,
			24.781,
			24.842,
			24.873,
			24.906,
			24.967,
			24.981,
			25.028,
			25.091,
			25.154,
			25.198,
			25.276,
			25.308,
			25.339,
			25.417,
			25.462,
			25.525,
			25.586,
			25.634,
			25.65,
			25.711,
			25.743,
			25.773,
			25.85,
			25.898,
			25.959,
			26.02,
			26.082,
			26.145,
			26.178,
			26.208,
			26.269,
			26.33,
			26.393,
			26.456,
			26.507,
			26.578,
			26.611,
			26.64,
			26.72,
			26.764,
			26.826,
			26.89,
			26.936,
			26.95,
			27.014,
			27.044,
			27.074,
			27.136,
			27.153,
			27.2,
			27.261,
			27.325,
			27.37,
			27.448,
			27.478,
			27.509,
			27.573,
			27.635,
			27.697,
			27.758,
			27.803,
			27.82,
			27.882,
			27.914,
			27.943,
			28.02,
			28.068,
			28.131,
			28.192,
			28.238,
			28.254,
			28.317,
			28.347,
			28.379,
			28.453,
			28.502,
			28.568,
			28.625,
			28.686,
			28.75,
			28.78,
			28.812,
			28.874,
			28.937,
			28.997,
			29.061,
			29.107,
			29.119,
			29.182,
			29.214,
			29.246,
			29.308,
			29.324,
			29.371,
			29.432,
			29.493,
			29.541,
			29.557,
			29.619,
			29.647,
			29.68,
			29.742,
			29.758,
			29.805,
			29.867,
			29.927,
			29.975,
			30.053,
			30.083,
			30.114,
			30.177,
			30.236,
			30.299,
			30.363,
			30.41,
			30.423,
			30.487,
			30.517,
			30.548,
			30.632,
			30.672,
			30.734,
			30.797,
			30.842,
			30.858,
			30.92,
			30.95,
			30.983,
			31.043,
			31.059,
			31.107,
			31.169,
			31.229,
			31.275,
			31.352,
			31.385,
			31.417,
			31.493,
			31.54,
			31.603,
			31.664,
			31.711,
			31.726,
			31.787,
			31.819,
			31.851,
			31.913,
			31.929,
			31.975,
			32.035,
			32.099,
			32.143,
			32.223,
			32.252,
			32.284,
			32.347,
			32.408,
			32.469,
			32.531,
			32.578,
			32.594,
			32.658,
			32.693,
			32.718,
			32.796,
			32.843,
			32.905,
			32.966,
			33.029,
			33.091,
			33.121,
			33.154,
			33.214,
			33.229,
			33.275,
			33.339,
			33.4,
			33.461,
			33.525,
			33.557,
			33.587,
			33.648,
			33.711,
			33.774,
			33.835,
			33.88,
			33.896,
			33.958,
			33.99,
			34.02,
			34.097,
			34.145,
			34.206,
			34.268,
			34.314,
			34.331,
			34.391,
			34.424,
			34.453,
			34.517,
			34.533,
			34.578,
			34.642,
			34.702,
			34.753,
			34.827,
			34.858,
			34.89,
			34.95,
			35.012,
			35.074,
			35.136,
			35.183,
			35.198,
			35.261,
			35.291,
			35.324,
			35.386,
			35.402,
			35.448,
			35.509,
			35.569,
			35.619,
			35.696,
			35.727,
			35.756,
			35.835,
			35.882,
			35.943,
			36.006,
			36.052,
			36.128,
			36.161,
			36.191,
			36.269,
			36.315,
			36.379,
			36.44,
			36.486,
			36.501,
			36.564,
			36.593,
			36.624,
			36.703,
			36.749,
			36.815,
			36.873,
			36.921,
			36.997,
			37.028,
			37.06,
			37.121,
			37.183,
			37.244,
			37.308,
			37.352,
			37.369,
			37.431,
			37.461,
			37.494,
			37.556,
			37.57,
			37.616,
			37.679,
			37.741,
			37.789,
			37.802,
			37.866,
			37.895,
			37.928,
			37.99,
			38.005,
			38.05,
			38.115,
			38.176,
			38.223,
			38.299,
			38.329,
			38.361,
			38.424,
			38.486,
			38.549,
			38.608,
			38.656,
			38.673,
			38.732,
			38.765,
			38.795,
			38.858,
			38.879,
			38.92,
			38.982,
			39.044,
			39.09,
			39.106,
			39.166,
			39.199,
			39.229,
			39.291,
			39.307,
			39.352,
			39.414,
			39.479,
			39.524,
			39.602,
			39.632,
			39.664,
			39.74,
			39.788,
			39.848,
			39.913,
			39.957,
			39.973,
			40.035,
			40.068,
			40.098,
			40.176,
			40.22,
			40.283,
			40.347,
			40.393,
			40.408,
			40.471,
			40.501,
			40.533,
			40.594,
			40.657,
			40.718,
			40.78,
			40.826,
			40.902,
			40.94,
			40.967,
			41.044,
			41.091,
			41.153,
			41.214,
			41.276,
			41.337,
			41.369,
			41.401,
			41.479,
			41.525,
			41.591,
			41.649,
			41.695,
			41.772,
			41.802,
			41.835,
			41.897,
			41.959,
			42.019,
			42.082,
			42.128,
			42.206,
			42.236,
			42.269,
			42.329,
			42.347,
			42.393,
			42.455,
			42.516,
			42.561,
			42.578,
			42.641,
			42.67,
			42.701,
			42.765,
			42.778,
			42.826,
			42.89,
			42.95,
			43.013,
			43.075,
			43.106,
			43.135,
			43.199,
			43.26,
			43.323,
			43.385,
			43.431,
			43.446,
			43.508,
			43.54,
			43.572,
			43.634,
			43.647,
			43.694,
			43.758,
			43.819,
			43.865,
			43.882,
			43.941,
			43.974,
			44.006,
			44.066,
			44.083,
			44.128,
			44.19,
			44.253,
			44.299,
			44.375,
			44.407,
			44.439,
			44.499,
			44.561,
			44.626,
			44.685,
			44.732,
			44.748,
			44.811,
			44.841,
			44.873,
			44.95,
			44.997,
			45.058,
			45.121,
			45.168,
			45.245,
			45.276,
			45.305,
			45.368,
			45.429,
			45.494,
			45.555,
			45.617,
			45.678,
			45.71,
			45.741,
			45.802,
			45.818,
			45.863,
			45.927,
			45.988,
			46.051,
			46.113,
			46.144,
			46.175,
			46.236,
			46.252,
			46.298,
			46.361,
			46.424,
			46.469,
			46.547,
			46.577,
			46.608,
			46.669,
			46.734,
			46.795,
			46.858,
			46.903,
			46.979,
			47.013,
			47.044,
			47.106,
			47.125,
			47.165,
			47.23,
			47.291,
			47.336,
			47.352,
			47.415,
			47.447,
			47.478,
			47.554,
			47.601,
			47.664,
			47.725,
			47.77,
			47.849,
			47.88,
			47.912,
			47.974,
			48.035,
			48.096,
			48.16,
			48.207,
			48.221,
			48.283,
			48.315,
			48.346,
			48.408,
			48.423,
			48.468,
			48.53,
			48.594,
			48.64,
			48.655,
			48.718,
			48.748,
			48.78,
			48.857,
			48.902,
			48.966,
			49.027,
			49.09,
			49.152,
			49.186,
			49.214,
			49.291,
			49.335,
			49.401,
			49.46,
			49.507,
			49.524,
			49.586,
			49.616,
			49.648,
			49.71,
			49.724,
			49.772,
			49.835,
			49.896,
			49.941,
			49.958,
			50.02,
			50.051,
			50.08,
			50.143,
			50.158,
			50.206,
			50.269,
			50.331,
			50.377,
			50.453,
			50.485,
			50.516,
			50.593,
			50.641,
			50.703,
			50.764,
			50.81,
			50.825,
			50.887,
			50.919,
			50.951,
			51.012,
			51.028,
			51.074,
			51.135,
			51.197,
			51.248,
			51.321,
			51.353,
			51.384,
			51.447,
			51.508,
			51.57,
			51.631,
			51.677,
			51.694,
			51.757,
			51.785,
			51.818,
			51.878,
			51.896,
			51.942,
			52.003,
			52.067,
			52.112,
			52.129,
			52.19,
			52.221,
			52.25,
			52.33,
			52.377,
			52.439,
			52.501,
			52.545,
			52.625,
			52.656,
			52.687,
			52.748,
			52.811,
			52.873,
			52.933,
			52.982,
			52.995,
			53.059,
			53.089,
			53.121,
			53.199,
			53.245,
			53.31,
			53.368,
			53.414,
			53.429,
			53.491,
			53.522,
			53.554,
			53.633,
			53.679,
			53.741,
			53.803,
			53.849,
			53.927,
			53.958,
			53.989,
			54.051,
			54.111,
			54.174,
			54.235,
			54.283,
			54.299,
			54.361,
			54.391,
			54.423,
			54.485,
			54.498,
			54.547,
			54.608,
			54.672,
			54.716,
			54.733,
			54.794,
			54.825,
			54.857,
			54.934,
			54.981,
			55.041,
			55.103,
			55.15,
			55.228,
			55.258,
			55.291,
			55.353,
			55.373,
			55.414,
			55.477,
			55.54,
			55.602,
			55.663,
			55.692,
			55.725,
			55.786,
			55.803,
			55.848,
			55.911,
			55.973,
			56.02,
			56.097,
			56.129,
			56.159,
			56.221,
			56.283,
			56.344,
			56.405,
			56.453,
			56.469,
			56.531,
			56.561,
			56.594,
			56.653,
			56.671,
			56.717,
			56.78,
			56.841,
			56.887,
			56.902,
			56.966,
			56.995,
			57.027,
			57.089,
			57.103,
			57.151,
			57.213,
			57.274,
			57.322,
			57.398,
			57.435,
			57.461,
			57.524,
			57.583,
			57.647,
			57.708,
			57.757,
			57.77,
			57.833,
			57.863,
			57.895,
			57.973,
			58.019,
			58.08,
			58.143,
			58.19,
			58.206,
			58.266,
			58.299,
			58.328,
			58.39,
			58.407,
			58.453,
			58.516,
			58.578,
			58.639,
			58.7,
			58.732,
			58.763,
			58.824,
			58.886,
			58.95,
			59.011,
			59.058,
			59.073,
			59.135,
			59.165,
			59.196,
			59.259,
			59.273,
			59.322,
			59.384,
			59.444,
			59.507,
			59.569,
			59.6,
			59.631,
			59.694,
			59.756,
			59.816,
			59.88,
			59.924,
			60.003,
			60.035,
			60.064,
			60.126,
			60.189,
			60.253,
			60.314,
			60.358,
			60.374,
			60.437,
			60.468,
			60.5,
			60.561,
			60.576,
			60.623,
			60.684,
			60.747,
			60.793,
			60.869,
			60.903,
			60.932,
			60.995,
			61.058,
			61.121,
			61.182,
			61.227,
			61.243,
			61.307,
			61.336,
			61.368,
			61.43,
			61.446,
			61.493,
			61.557,
			61.616,
			61.66,
			61.677,
			61.74,
			61.77,
			61.803,
			61.88,
			61.925,
			61.986,
			62.05,
			62.096,
			62.173,
			62.203,
			62.236,
			62.298,
			62.361,
			62.423,
			62.482,
			62.531,
			62.547,
			62.606,
			62.64,
			62.669,
			62.733,
			62.747,
			62.794,
			62.857,
			62.917,
			62.964,
			62.978,
			63.041,
			63.072,
			63.105,
			63.166,
			63.181,
			63.228,
			63.291,
			63.353,
			63.413,
			63.476,
			63.508,
			63.539,
			63.6,
			63.619,
			63.662,
			63.723,
			63.786,
			63.832,
			63.848,
			63.911,
			63.94,
			63.973,
			64.033,
			64.048,
			64.097,
			64.157,
			64.219,
			64.268,
			64.283,
			64.344,
			64.375,
			64.407,
			64.469,
			64.529,
			64.593,
			64.655,
			64.701,
			64.777,
			64.81,
			64.84,
			64.919,
			64.965,
			65.028,
			65.089,
			65.15,
			65.212,
			65.244,
			65.274,
			65.337,
			65.351,
			65.399,
			65.461,
			65.523,
			65.568,
			65.647,
			65.681,
			65.709,
			65.772,
			65.832,
			65.894,
			65.956,
			66.003,
			66.019,
			66.079,
			66.112,
			66.143,
			66.205,
			66.268,
			66.329,
			66.391,
			66.451,
			66.513,
			66.545,
			66.577,
			66.639,
			66.655,
			66.699,
			66.761,
			66.824,
			66.872,
			66.948,
			66.978,
			67.011,
			67.072,
			67.136,
			67.197,
			67.258,
			67.306,
			67.319,
			67.383,
			67.415,
			67.445,
			67.508,
			67.522,
			67.568,
			67.63,
			67.693,
			67.742,
			67.753,
			67.817,
			67.848,
			67.879,
			67.957,
			68.002,
			68.064,
			68.128,
			68.189,
			68.25,
			68.283,
			68.311,
			68.373,
			68.435,
			68.5,
			68.559,
			68.624,
			68.684,
			68.715,
			68.747,
			68.808,
			68.824,
			68.869,
			68.933,
			68.996,
			69.056,
			69.12,
			69.15,
			69.182,
			69.244,
			69.303,
			69.368,
			69.428,
			69.476,
			69.553,
			69.585,
			69.615,
			69.677,
			69.74,
			69.805,
			69.864,
			69.925,
			69.988,
			70.017,
			70.048,
			70.111,
			70.127,
			70.174,
			70.236,
			70.296,
			70.343,
			70.358,
			70.42,
			70.451,
			70.483,
			70.544,
			70.607,
			70.67,
			70.731,
			70.779,
			70.794,
			70.856,
			70.887,
			70.917,
			70.978,
			70.996,
			71.041,
			71.101,
			71.166,
			71.211,
			71.227,
			71.29,
			71.32,
			71.352,
			71.413,
			71.43,
			71.474,
			71.536,
			71.6,
			71.647,
			71.723,
			71.754,
			71.785,
			71.846,
			71.909,
			71.972,
			72.034,
			72.095,
			72.157,
			72.186,
			72.22,
			72.28,
			72.296,
			72.343,
			72.406,
			72.469,
			72.514,
			72.53,
			72.592,
			72.624,
			72.653,
			72.716,
			72.731,
			72.777,
			72.841,
			72.902,
			72.949,
			73.025,
			73.056,
			73.089,
			73.151,
			73.211,
			73.273,
			73.335,
			73.383,
			73.398,
			73.458,
			73.49,
			73.52,
			73.582,
			73.6,
			73.647,
			73.71,
			73.769,
			73.816,
			73.83,
			73.894,
			73.956,
			74.016,
			74.034,
			74.081,
			74.14,
			74.202,
			74.25,
			74.327,
			74.359,
			74.39,
			74.452,
			74.468,
			74.513,
			74.577,
			74.639,
			74.683,
			74.7,
			74.76,
			74.793,
			74.824,
			74.901,
			74.947,
			75.01,
			75.073,
			75.132,
			75.196,
			75.226,
			75.257,
			75.336,
			75.383,
			75.444,
			75.506,
			75.569,
			75.629,
			75.661,
			75.693,
			75.752,
			75.769,
			75.817,
			75.878,
			75.941,
			75.992,
			76.002,
			76.064,
			76.095,
			76.127,
			76.189,
			76.205,
			76.25,
			76.312,
			76.375,
			76.422,
			76.499,
			76.527,
			76.561,
			76.623,
			76.685,
			76.746,
			76.807,
			76.87,
			76.933,
			76.962,
			76.993,
			77.057,
			77.072,
			77.119,
			77.18,
			77.241,
			77.289,
			77.306,
			77.365,
			77.396,
			77.427,
			77.505,
			77.553,
			77.614,
			77.676,
			77.723,
			77.8,
			77.83,
			77.862,
			77.923,
			77.986,
			78.053,
			78.11,
			78.158,
			78.173,
			78.233,
			78.264,
			78.297,
			78.359,
			78.375,
			78.42,
			78.486,
			78.546,
			78.591,
			78.607,
			78.669,
			78.699,
			78.731,
			78.794,
			78.856,
			78.917,
			78.978,
			79.026,
			79.102,
			79.132,
			79.166,
			79.227,
			79.289,
			79.351,
			79.414,
			79.458,
			79.474,
			79.536,
			79.568,
			79.599,
			79.661,
			79.675,
			79.723,
			79.785,
			79.848,
			79.908,
			79.971,
			80.002,
			80.033,
			80.093,
			80.155,
			80.219,
			80.281,
			80.327,
			80.406,
			80.436,
			80.466,
			80.527,
			80.544,
			80.59,
			80.657,
			80.715,
			80.761,
			80.777,
			80.84,
			80.87,
			80.9,
			80.961,
			80.978,
			81.024,
			81.086,
			81.149,
			81.196,
			81.273,
			81.303,
			81.335,
			81.414,
			81.46,
			81.523,
			81.583,
			81.629,
			81.644,
			81.706,
			81.738,
			81.768,
			81.832,
			81.847,
			81.893,
			81.956,
			81.993
		]
	],
	'6657_6658_7982': [
		[
			111.13
		],
		[
			0
		],
		[
			0,
			0.254,
			1.692,
			4.574,
			7.461,
			11.804,
			14.708,
			19.075,
			20.534,
			21.995,
			26.386,
			29.322,
			33.737,
			36.688,
			39.645,
			41.126,
			44.093,
			45.578,
			48.555,
			51.537,
			53.03,
			56.022,
			59.019,
			63.527,
			65.032,
			66.539,
			71.075,
			72.59,
			74.108,
			78.676,
			81.74,
			84.832,
			89.484,
			92.591,
			97.24,
			98.791,
			100.334,
			104.968,
			108.057,
			112.663,
			115.71,
			118.711,
			120.201,
			123.162,
			124.629,
			127.558,
			130.462,
			131.91,
			134.785,
			137.64,
			141.859,
			144.612,
			148.669,
			150,
			151.322,
			155.232,
			157.789,
			161.549,
			163.999,
			166.416,
			169.988,
			171.161,
			172.326,
			176.892,
			179.116,
			181.302,
			183.451,
			185.563,
			186.605,
			188.661,
			190.68,
			191.676,
			194.608,
			196.517,
			198.406,
			201.152,
			202.943,
			205.571,
			206.432,
			207.286,
			209.813,
			211.48,
			213.962,
			215.618,
			217.282,
			218.111,
			219.778,
			220.616,
			222.301,
			223.976,
			224.817,
			226.505,
			228.195,
			229.883,
			231.572,
			232.416,
			234.138,
			235.861,
			236.719,
			238.451,
			240.21,
			241.082,
			242.812,
			243.678,
			245.391,
			247.076,
			249.578,
			251.219,
			253.63,
			254.45,
			255.263,
			257.666,
			259.251,
			261.617,
			263.181,
			265.513,
			267.062,
			267.836,
			269.391,
			271.75,
			273.328,
			274.873,
			277.153,
			277.914,
			278.677,
			280.827,
			281.533,
			282.24,
			283.655,
			284.361,
			285.722,
			287.08,
			289.116,
			290.476,
			292.508,
			293.185,
			293.861,
			295.908,
			297.28,
			299.334,
			300.726,
			302.147,
			302.856,
			304.272,
			305.695,
			306.414,
			307.849,
			308.565,
			309.994,
			311.407,
			313.506,
			314.902,
			316.963,
			317.643,
			318.322,
			320.358,
			321.702,
			323.039,
			325.047,
			326.398,
			328.435,
			329.115,
			330.479,
			331.874,
			332.576,
			333.279,
			335.396,
			336.838,
			338.303,
			339.037,
			340.513,
			342.015,
			342.779,
			344.311,
			345.081,
			346.636,
			348.244,
			350.677,
			352.36,
			354.996,
			355.881,
			356.77,
			359.48,
			360.389,
			361.3,
			363.975,
			365.748,
			368.416,
			370.196,
			371.089,
			372.875,
			374.653,
			375.545,
			377.334,
			379.083,
			380.835,
			382.594,
			383.464,
			385.21,
			386.965,
			387.847,
			389.625,
			390.518,
			392.314,
			394.13,
			396.883,
			398.732,
			401.517,
			402.452,
			403.389,
			406.204,
			408.096,
			410.971,
			412.89,
			415.833,
			417.817,
			418.83,
			420.837,
			422.859,
			423.857,
			425.897,
			427.95,
			431.051,
			432.09,
			433.132,
			435.224,
			437.326,
			438.377,
			441.532,
			443.643,
			445.765,
			448.953,
			451.09,
			454.288,
			455.359,
			456.431,
			459.655,
			461.817,
			465.086,
			467.275,
			469.481,
			470.587,
			472.809,
			473.925,
			476.166,
			478.417,
			479.548,
			481.815,
			484.099,
			487.545,
			488.705,
			489.867,
			493.369,
			494.544,
			495.72,
			498.081,
			499.263,
			501.631,
			504.009,
			507.576,
			511.158,
			513.552,
			514.752,
			515.953,
			519.567,
			520.777,
			521.988,
			525.635,
			528.079,
			531.761,
			534.228,
			536.704,
			537.946,
			541.683,
			544.185,
			546.695,
			550.476,
			553.007,
			556.819,
			558.094,
			559.371,
			563.215,
			565.787,
			569.661,
			572.253,
			574.853,
			576.155,
			578.763,
			580.066,
			582.677,
			585.294,
			586.606,
			587.919,
			591.869,
			594.51,
			597.159,
			598.487,
			601.146,
			603.812,
			605.148,
			607.824,
			609.165,
			611.852,
			614.546,
			618.599,
			621.31,
			625.39,
			626.753,
			628.118,
			632.224,
			634.971,
			639.106,
			641.872,
			646.034,
			648.816,
			650.209,
			653.001,
			655.798,
			657.199,
			660.005,
			662.818,
			667.05,
			668.464,
			669.879,
			674.134,
			675.556,
			676.978,
			681.255,
			684.114,
			686.978,
			691.284,
			694.163,
			698.494,
			699.941,
			701.39,
			705.749,
			708.663,
			713.047,
			715.978,
			718.915,
			720.386,
			723.333,
			726.285,
			727.764,
			730.726,
			732.209,
			735.18,
			738.157,
			742.634,
			745.626,
			750.127,
			751.629,
			753.134,
			757.657,
			760.681,
			765.236,
			768.282,
			771.333,
			775.918,
			777.447,
			778.974,
			785.09,
			786.62,
			791.22,
			794.295,
			797.376,
			798.918,
			802.005,
			803.545,
			806.616,
			811.174,
			814.17,
			817.127,
			821.49,
			824.35,
			828.568,
			829.954,
			831.331,
			835.405,
			838.072,
			842.002,
			844.574,
			847.108,
			848.361,
			850.84,
			852.065,
			854.487,
			856.874,
			858.054,
			859.226,
			862.688,
			864.949,
			867.172,
			868.269,
			870.435,
			872.564,
			873.615,
			876.712,
			878.731,
			880.713,
			883.615,
			885.504,
			888.268,
			889.172,
			890.068,
			892.704,
			894.421,
			896.941,
			898.568,
			900.165,
			900.951,
			902.504,
			903.272,
			904.794,
			906.292,
			907.034,
			908.512,
			909.989,
			912.187,
			912.918,
			913.649,
			915.113,
			916.588,
			917.328,
			918.807,
			919.547,
			921.059,
			922.683,
			925.116,
			926.876,
			929.667,
			930.596,
			931.548,
			934.464,
			936.389,
			939.046,
			940.813,
			943.386,
			945.082,
			945.929,
			947.615,
			949.294,
			950.134,
			951.806,
			953.396,
			955.779,
			956.574,
			957.345,
			959.579,
			960.322,
			961.065,
			962.543,
			963.27,
			964.721,
			966.171,
			968.338,
			969.773,
			970.49,
			971.926,
			972.647,
			973.369,
			975.537,
			976.262,
			976.988,
			979.223,
			980.735,
			982.251,
			983.012,
			984.587,
			986.164,
			986.955,
			988.544,
			989.352,
			990.974,
			992.602,
			995.046,
			995.86,
			996.676,
			999.126,
			999.932,
			1000.739,
			1003.164,
			1004.785,
			1007.22,
			1008.85,
			1010.481,
			1012.93,
			1013.748,
			1015.388,
			1017.033,
			1017.858,
			1018.687,
			1021.201,
			1022.895,
			1024.6,
			1025.469,
			1027.217,
			1028.976,
			1029.862,
			1031.664,
			1032.57,
			1034.391,
			1036.254,
			1039.096,
			1041.028,
			1043.987,
			1044.981,
			1045.992,
			1049.08,
			1051.162,
			1054.344,
			1056.486,
			1059.746,
			1061.931,
			1063.026,
			1065.224,
			1067.423,
			1068.523,
			1070.729,
			1072.955,
			1076.314,
			1077.44,
			1078.567,
			1080.826,
			1083.099,
			1084.24,
			1086.524,
			1087.674,
			1089.976,
			1092.275,
			1095.685,
			1097.953,
			1101.308,
			1102.429,
			1103.549,
			1106.911,
			1109.144,
			1112.486,
			1114.687,
			1116.884,
			1117.987,
			1120.167,
			1121.258,
			1123.447,
			1125.643,
			1126.744,
			1128.953,
			1131.175,
			1134.526,
			1135.65,
			1136.776,
			1139.034,
			1141.31,
			1142.452,
			1145.896,
			1148.207,
			1150.528,
			1154.011,
			1156.354,
			1159.883,
			1161.062,
			1162.243,
			1166.993,
			1168.187,
			1171.786,
			1174.202,
			1176.629,
			1177.848,
			1180.295,
			1182.752,
			1183.985,
			1187.697,
			1190.185,
			1192.681,
			1196.441,
			1198.958,
			1202.75,
			1204.018,
			1205.288,
			1209.112,
			1211.672,
			1215.527,
			1218.108,
			1220.697,
			1221.995,
			1224.596,
			1225.901,
			1227.207,
			1232.437,
			1235.064,
			1237.701,
			1240.345,
			1242.997,
			1244.325,
			1246.987,
			1249.654,
			1250.99,
			1255.006,
			1257.691,
			1260.381,
			1264.431,
			1267.139,
			1271.215,
			1272.578,
			1273.942,
			1278.045,
			1280.79,
			1284.921,
			1287.683,
			1291.841,
			1294.621,
			1296.014,
			1298.805,
			1303.005,
			1305.814,
			1308.63,
			1312.866,
			1315.699,
			1318.539,
			1321.386,
			1322.812,
			1325.669,
			1327.1,
			1329.967,
			1332.841,
			1337.166,
			1340.057,
			1344.406,
			1345.859,
			1347.313,
			1351.687,
			1354.61,
			1359.007,
			1361.947,
			1364.891,
			1366.366,
			1369.321,
			1372.283,
			1373.767,
			1376.739,
			1378.227,
			1381.21,
			1384.199,
			1388.694,
			1390.195,
			1391.698,
			1396.215,
			1397.724,
			1399.234,
			1403.772,
			1406.805,
			1411.365,
			1414.412,
			1417.462,
			1422.048,
			1423.579,
			1425.112,
			1429.723,
			1431.264,
			1432.808,
			1437.453,
			1440.56,
			1443.671,
			1445.228,
			1448.346,
			1451.468,
			1453.03,
			1456.157,
			1457.722,
			1460.855,
			1463.99,
			1468.691,
			1471.823,
			1476.521,
			1478.091,
			1479.667,
			1484.41,
			1487.582,
			1492.331,
			1495.465,
			1500.093,
			1503.119,
			1504.618,
			1507.579,
			1510.499,
			1511.943,
			1513.377,
			1517.622,
			1520.403,
			1524.502,
			1527.187,
			1529.833,
			1531.142,
			1533.732,
			1535.014,
			1537.548,
			1540.045,
			1543.723,
			1546.129,
			1549.672,
			1550.837,
			1551.992,
			1555.401,
			1557.627,
			1560.894,
			1563.025,
			1565.109,
			1566.135,
			1568.159,
			1569.156,
			1571.12,
			1573.048,
			1573.999,
			1574.944,
			1577.729,
			1580.442,
			1582.214,
			1584.809,
			1585.657,
			1586.496,
			1588.147,
			1588.972,
			1590.57,
			1592.131,
			1594.409,
			1595.892,
			1598.073,
			1598.791,
			1599.506,
			1601.621,
			1603.019,
			1605.103,
			1606.456,
			1608.479,
			1609.827,
			1611.158,
			1611.821,
			1613.811,
			1615.144,
			1616.528,
			1618.601,
			1619.293,
			1619.985,
			1622.198,
			1622.937,
			1623.677,
			1625.93,
			1627.482,
			1629.8,
			1631.396,
			1633.011,
			1633.816,
			1635.424,
			1636.251,
			1637.076,
			1639.541,
			1641.199,
			1643.73,
			1645.416,
			1647.166,
			1648.049,
			1649.815,
			1650.699,
			1652.516,
			1654.346,
			1655.262,
			1657.086,
			1658.888,
			1661.604,
			1663.316,
			1665.878,
			1666.735,
			1667.557,
			1669.95,
			1671.551,
			1673.788,
			1675.241,
			1676.698,
			1678.855,
			1679.57,
			1680.285,
			1683.185,
			1683.934,
			1686.186,
			1687.702,
			1689.257,
			1690.036,
			1691.6,
			1693.176,
			1693.972,
			1695.572,
			1696.375,
			1697.987,
			1699.617,
			1702.08,
			1703.738,
			1706.255,
			1707.1,
			1707.948,
			1710.514,
			1712.226,
			1714.836,
			1716.588,
			1718.352,
			1719.238,
			1721.015,
			1721.908,
			1723.704,
			1725.513,
			1726.423,
			1728.251,
			1730.093,
			1731.948,
			1733.813,
			1734.751,
			1737.574,
			1738.521,
			1739.471,
			1741.38,
			1742.339,
			1744.265,
			1747.183,
			1749.145,
			1751.12,
			1754.109,
			1755.111,
			1756.116,
			1759.15,
			1761.187,
			1764.258,
			1766.318,
			1768.376,
			1769.41,
			1771.484,
			1772.525,
			1774.613,
			1776.708,
			1777.761,
			1779.868,
			1781.973,
			1785.149,
			1786.212,
			1787.278,
			1790.491,
			1791.568,
			1792.646,
			1795.894,
			1798.072,
			1801.357,
			1803.56,
			1805.772,
			1809.107,
			1810.223,
			1811.342,
			1814.712,
			1816.97,
			1820.382,
			1822.671,
			1824.979,
			1826.139,
			1828.465,
			1830.805,
			1831.979,
			1834.333,
			1835.512,
			1837.898,
			1840.269,
			1843.834,
			1846.212,
			1849.788,
			1850.956,
			1852.145,
			1855.739,
			1858.125,
			1861.728,
			1864.147,
			1866.578,
			1867.799,
			1870.247,
			1871.474,
			1873.935,
			1876.407,
			1877.648,
			1878.892,
			1882.66,
			1885.203,
			1887.771,
			1889.064,
			1891.654,
			1894.248,
			1895.547,
			1898.176,
			1899.506,
			1902.177,
			1904.9,
			1908.988,
			1911.708,
			1915.754,
			1917.104,
			1918.453,
			1922.502,
			1925.212,
			1929.302,
			1932.061,
			1934.85,
			1936.256,
			1939.071,
			1940.479,
			1943.297,
			1946.076,
			1947.463,
			1950.218,
			1952.954,
			1957.054,
			1959.788,
			1962.549,
			1965.309,
			1966.69,
			1969.453,
			1970.834,
			1973.591,
			1976.342,
			1980.449,
			1983.187,
			1987.301,
			1988.674,
			1990.048,
			1994.144,
			1996.858,
			2000.897,
			2003.569,
			2006.245,
			2007.592,
			2010.292,
			2011.644,
			2014.385,
			2018.515,
			2021.282,
			2024.054,
			2028.214,
			2030.949,
			2035.086,
			2036.464,
			2037.866,
			2042.022,
			2044.806,
			2048.984,
			2051.804,
			2054.632,
			2058.9,
			2060.35,
			2061.779,
			2066.077,
			2067.49,
			2068.928,
			2073.259,
			2076.158,
			2079.067,
			2080.524,
			2083.436,
			2086.353,
			2087.81,
			2092.188,
			2095.108,
			2098.031,
			2102.419,
			2105.349,
			2109.748,
			2111.216,
			2112.685,
			2117.097,
			2120.043,
			2124.471,
			2127.429,
			2130.388,
			2131.868,
			2134.828,
			2136.306,
			2137.78,
			2142.178,
			2143.631,
			2145.077,
			2149.359,
			2152.166,
			2154.933,
			2156.302,
			2159.011,
			2161.678,
			2162.997,
			2166.893,
			2169.438,
			2171.944,
			2175.632,
			2178.044,
			2181.593,
			2182.76,
			2183.917,
			2187.333,
			2189.563,
			2192.838,
			2194.975,
			2197.073,
			2198.11,
			2200.171,
			2201.179,
			2203.167,
			2205.122,
			2206.085,
			2207.042,
			2209.876,
			2212.663,
			2213.584,
			2214.5,
			2216.327,
			2218.148,
			2219.055,
			2220.865,
			2221.769,
			2223.579,
			2225.371,
			2228.062,
			2229.857,
			2232.549,
			2233.448,
			2234.35,
			2237.084,
			2238.902,
			2241.635,
			2243.444,
			2245.239,
			2246.132,
			2247.905,
			2248.788,
			2250.553,
			2252.316,
			2253.194,
			2254.947,
			2256.711,
			2259.339,
			2260.214,
			2261.111,
			2263.784,
			2264.67,
			2265.566,
			2268.323,
			2270.149,
			2272.941,
			2274.799,
			2276.67,
			2279.485,
			2280.421,
			2281.369,
			2284.241,
			2286.149,
			2288.985,
			2290.872,
			2293.528,
			2295.287,
			2297.013,
			2297.859,
			2299.55,
			2300.395,
			2302.101,
			2303.827,
			2306.422,
			2308.175,
			2310.816,
			2311.7,
			2312.589,
			2315.27,
			2317.063,
			2319.751,
			2321.555,
			2323.318,
			2324.195,
			2325.957,
			2326.843,
			2328.568,
			2330.282,
			2332.022,
			2334.653,
			2336.422,
			2338.208,
			2339.109,
			2340.918,
			2342.73,
			2343.637,
			2345.45,
			2346.354,
			2348.156,
			2349.949,
			2352.624,
			2354.41,
			2357.05,
			2357.927,
			2358.809,
			2361.439,
			2363.186,
			2365.832,
			2367.584,
			2370.2,
			2371.931,
			2372.793,
			2374.508,
			2376.212,
			2377.061,
			2377.908,
			2380.515,
			2382.264,
			2384.916,
			2386.704,
			2388.487,
			2389.374,
			2392.013,
			2393.769,
			2395.489,
			2398.057,
			2399.79,
			2402.432,
			2403.313,
			2404.195,
			2406.88,
			2408.672,
			2411.341,
			2413.116,
			2414.896,
			2415.791,
			2417.582,
			2418.48,
			2420.283,
			2422.101,
			2423.011,
			2424.838,
			2426.713,
			2429.543,
			2430.488,
			2431.429,
			2434.256,
			2435.201,
			2436.127,
			2438.885,
			2440.731,
			2442.555,
			2445.302,
			2447.146,
			2449.93,
			2450.863,
			2451.806,
			2454.655,
			2456.567,
			2459.447,
			2461.382,
			2463.319,
			2464.29,
			2466.241,
			2467.215,
			2469.143,
			2471.081,
			2472.054,
			2473.963,
			2475.876,
			2478.751,
			2480.667,
			2483.556,
			2484.523,
			2485.491,
			2488.405,
			2490.349,
			2493.276,
			2495.246,
			2497.218,
			2500.201,
			2501.2,
			2502.199,
			2505.199,
			2506.2,
			2507.203,
			2510.21,
			2512.219,
			2515.257,
			2517.299,
			2519.355,
			2520.395,
			2522.476,
			2523.516,
			2525.606,
			2527.71,
			2530.869,
			2533.002,
			2536.22,
			2537.298,
			2538.378,
			2541.631,
			2543.811,
			2547.096,
			2549.297,
			2551.506,
			2552.613,
			2554.834,
			2555.948,
			2558.18,
			2561.545,
			2562.67,
			2566.059,
			2569.466,
			2570.606,
			2571.749,
			2574.039,
			2576.339,
			2577.492,
			2579.805,
			2580.965,
			2583.291,
			2585.627,
			2589.149,
			2591.509,
			2595.068,
			2596.26,
			2597.453,
			2601.049,
			2603.458,
			2607.088,
			2609.514,
			2613.15,
			2615.562,
			2616.761,
			2619.139,
			2621.484,
			2622.642,
			2624.929,
			2627.164,
			2630.441,
			2631.512,
			2632.574,
			2635.704,
			2636.738,
			2637.763,
			2640.782,
			2642.779,
			2644.737,
			2647.609,
			2649.481,
			2652.218,
			2653.108,
			2653.99,
			2656.58,
			2657.41,
			2658.23,
			2660.633,
			2662.187,
			2663.703,
			2664.441,
			2665.894,
			2666.61,
			2668.033,
			2669.44,
			2670.136,
			2671.521,
			2672.907,
			2674.993,
			2676.381,
			2678.452,
			2679.138,
			2679.823,
			2681.879,
			2683.224,
			2685.21,
			2686.534,
			2687.86,
			2688.525,
			2689.85,
			2690.511,
			2691.171,
			2693.166,
			2693.844,
			2694.521,
			2696.539,
			2697.886,
			2699.28,
			2699.973,
			2701.354,
			2702.734,
			2703.456,
			2704.93,
			2705.664,
			2707.132,
			2708.644,
			2711.077,
			2712.697,
			2715.286,
			2716.164,
			2717.041,
			2719.651,
			2721.366,
			2723.926,
			2725.562,
			2727.19,
			2728.003,
			2729.63,
			2730.441,
			2732.059,
			2733.673,
			2734.482,
			2736.1,
			2737.712,
			2740.056,
			2741.546,
			2743.031,
			2744.488,
			2745.165,
			2746.518,
			2747.193,
			2748.547,
			2749.895,
			2751.892,
			2753.227,
			2755.258,
			2755.95,
			2756.643,
			2758.729,
			2760.133,
			2762.263,
			2763.691,
			2765.126,
			2765.845,
			2767.287,
			2768.011,
			2769.466,
			2770.945,
			2771.694,
			2773.198,
			2774.713,
			2777.041,
			2777.826,
			2778.614,
			2780.97,
			2781.754,
			2782.542,
			2784.127,
			2784.925,
			2786.515,
			2788.107,
			2790.518,
			2792.156,
			2794.639,
			2795.473,
			2796.312,
			2798.862,
			2799.718,
			2800.577,
			2803.184,
			2804.938,
			2806.707,
			2807.597,
			2809.388,
			2810.288,
			2812.097,
			2814.839,
			2816.684,
			2818.54,
			2821.348,
			2823.234,
			2826.085,
			2827.041,
			2827.999,
			2830.892,
			2832.836,
			2835.771,
			2837.742,
			2839.725,
			2842.721,
			2843.725,
			2845.741,
			2847.769,
			2848.787,
			2849.808,
			2852.885,
			2854.95,
			2857.025,
			2858.067,
			2860.157,
			2862.253,
			2863.3,
			2866.444,
			2868.552,
			2870.669,
			2873.862,
			2876.002,
			2879.231,
			2880.312,
			2881.395,
			2884.658,
			2886.846,
			2890.144,
			2892.355,
			2894.574,
			2895.687,
			2897.92,
			2899.039,
			2901.284,
			2903.539,
			2904.67,
			2906.938,
			2909.218,
			2912.656,
			2913.807,
			2914.961,
			2917.277,
			2919.602,
			2920.769,
			2923.109,
			2924.282,
			2926.636,
			2929,
			2930.185,
			2932.562,
			2934.948,
			2938.543,
			2939.746,
			2940.951,
			2944.58,
			2947.008,
			2950.664,
			2953.108,
			2955.562,
			2956.79,
			2959.254,
			2960.489,
			2962.966,
			2966.699,
			2969.203,
			2971.715,
			2975.511,
			2976.781,
			2978.053,
			2980.605,
			2983.164,
			2984.445,
			2988.299,
			2990.874,
			2993.457,
			2997.342,
			2999.942,
			3003.851,
			3005.153,
			3006.457,
			3010.378,
			3013,
			3016.948,
			3019.587,
			3022.233,
			3023.558,
			3026.214,
			3028.874,
			3030.204,
			3032.861,
			3034.186,
			3036.821,
			3039.431,
			3043.288,
			3045.813,
			3049.529,
			3050.748,
			3051.958,
			3055.537,
			3057.875,
			3061.31,
			3063.538,
			3066.808,
			3068.938,
			3069.988,
			3071.03,
			3074.114,
			3075.125,
			3076.126,
			3079.081,
			3081.006,
			3082.895,
			3083.826,
			3085.662,
			3087.463,
			3088.351,
			3090.102,
			3090.963,
			3092.659,
			3094.318,
			3096.738,
			3098.306,
			3100.594,
			3101.337,
			3102.073,
			3104.24,
			3105.638,
			3107.691,
			3109.035,
			3110.36,
			3111.018,
			3112.321,
			3112.969,
			3114.238,
			3116.117,
			3116.74,
			3118.593,
			3119.773,
			3120.949,
			3121.535,
			3122.707,
			3123.883,
			3124.474,
			3125.652,
			3126.24,
			3127.417,
			3128.602,
			3130.477,
			3131.724,
			3133.604,
			3134.258,
			3134.91,
			3136.858,
			3138.156,
			3140.134,
			3141.452,
			3142.768,
			3143.425,
			3144.792,
			3145.486,
			3146.867,
			3148.933,
			3150.516,
			3152.124,
			3154.571,
			3155.461,
			3156.349,
			3159.002,
			3159.892,
			3160.782,
			3163.437,
			3165.179,
			3166.878,
			3169.417,
			3170.955,
			3173.194,
			3173.938,
			3174.684,
			3176.826,
			3177.53,
			3178.232,
			3180.341,
			3181.772,
			3183.195,
			3183.904,
			3185.326,
			3186.049,
			3187.487,
			3188.916,
			3189.629,
			3191.067,
			3192.52,
			3194.688,
			3196.122,
			3198.22,
			3198.918,
			3199.618,
			3201.634,
			3202.901,
			3204.799,
			3206.064,
			3207.289,
			3209.124,
			3209.737,
			3210.35,
			3212.849,
			3213.48,
			3215.383,
			3216.659,
			3218.611,
			3219.92,
			3221.237,
			3221.898,
			3223.22,
			3223.884,
			3225.22,
			3226.565,
			3228.613,
			3229.996,
			3232.101,
			3232.816,
			3233.538,
			3235.728,
			3237.207,
			3239.451,
			3240.965,
			3243.255,
			3244.813,
			3245.598,
			3247.178,
			3248.778,
			3249.585,
			3250.396,
			3252.85,
			3254.506,
			3256.176,
			3257.016,
			3258.706,
			3260.408,
			3261.26,
			3263.815,
			3265.524,
			3267.248,
			3269.855,
			3271.61,
			3274.265,
			3275.156,
			3276.051,
			3278.755,
			3280.573,
			3283.326,
			3285.177,
			3287.041,
			3287.978,
			3289.861,
			3290.808,
			3292.711,
			3294.626,
			3295.589,
			3297.523,
			3299.469,
			3302.412,
			3304.389,
			3307.377,
			3308.378,
			3309.384,
			3312.416,
			3314.452,
			3316.5,
			3319.593,
			3321.669,
			3324.803,
			3325.853,
			3326.901,
			3331.1,
			3332.155,
			3335.334,
			3337.466,
			3339.609,
			3340.684,
			3342.841,
			3343.924,
			3346.096,
			3348.279,
			3349.374,
			3351.572,
			3353.779,
			3357.11,
			3358.225,
			3359.343,
			3362.71,
			3363.838,
			3364.968,
			3368.372,
			3370.655,
			3374.097,
			3376.403,
			3378.72,
			3379.881,
			3382.212,
			3383.381,
			3384.552,
			3388.079,
			3389.259,
			3390.44,
			3394,
			3396.386,
			3398.781,
			3399.983,
			3402.393,
			3404.813,
			3406.026,
			3408.46,
			3409.681,
			3412.129,
			3414.586,
			3418.288,
			3420.767,
			3424.502,
			3425.752,
			3427.003,
			3430.772,
			3433.294,
			3437.094,
			3439.637,
			3442.189,
			3443.468,
			3446.031,
			3447.317,
			3449.892,
			3452.476,
			3453.771,
			3456.367,
			3458.97,
			3461.578,
			3464.186,
			3465.491,
			3468.109,
			3470.735,
			3472.05,
			3474.686,
			3476.007,
			3478.653,
			3481.306,
			3485.299,
			3487.971,
			3491.992,
			3493.337,
			3494.683,
			3498.733,
			3500.087,
			3501.443,
			3505.522,
			3508.251,
			3510.987,
			3512.358,
			3515.105,
			3516.481,
			3519.241,
			3522.007,
			3523.394,
			3526.171,
			3528.957,
			3533.149,
			3534.551,
			3535.954,
			3538.766,
			3541.586,
			3542.998,
			3547.247,
			3550.089,
			3552.938,
			3557.227,
			3560.096,
			3564.413,
			3565.855,
			3567.3,
			3571.645,
			3574.551,
			3578.924,
			3581.848,
			3586.249,
			3589.192,
			3590.666,
			3593.619,
			3596.58,
			3598.063,
			3601.034,
			3604.013,
			3608.493,
			3611.489,
			3615.996,
			3617.502,
			3619.009,
			3623.542,
			3626.572,
			3631.13,
			3634.177,
			3637.231,
			3638.761,
			3641.824,
			3643.359,
			3644.895,
			3649.512,
			3651.054,
			3652.598,
			3657.24,
			3660.342,
			3663.45,
			3665.006,
			3668.121,
			3671.241,
			3672.804,
			3675.933,
			3677.501,
			3680.642,
			3683.79,
			3688.525,
			3691.69,
			3696.465,
			3698.07,
			3699.678,
			3704.526,
			3707.766,
			3712.601,
			3715.778,
			3718.947,
			3720.52,
			3723.673,
			3725.26,
			3726.849,
			3731.668,
			3733.287,
			3734.917,
			3739.832,
			3743.095,
			3746.34,
			3747.955,
			3751.184,
			3754.41,
			3756.029,
			3759.284,
			3760.916,
			3764.205,
			3767.504,
			3772.466,
			3775.774,
			3780.742,
			3782.401,
			3784.063,
			3789.054,
			3792.386,
			3797.379,
			3800.707,
			3804.041,
			3805.711,
			3809.055,
			3810.729,
			3814.08,
			3817.433,
			3819.107,
			3822.442,
			3825.754,
			3830.658,
			3832.273,
			3833.879,
			3838.634,
			3840.199,
			3841.757,
			3844.844,
			3846.374,
			3849.406,
			3852.407,
			3856.845,
			3859.761,
			3864.066,
			3865.482,
			3866.888,
			3871.052,
			3873.782,
			3877.808,
			3880.446,
			3883.047,
			3884.333,
			3886.876,
			3889.38,
			3890.618,
			3893.054,
			3894.256,
			3896.632,
			3898.97,
			3902.422,
			3904.7,
			3908.058,
			3909.168,
			3910.269,
			3913.513,
			3915.626,
			3918.714,
			3920.712,
			3922.675,
			3925.532,
			3926.466,
			3927.391,
			3931.02,
			3931.908,
			3934.528,
			3936.237,
			3937.91,
			3938.735,
			3940.365,
			3941.958,
			3942.742,
			3945.057,
			3946.566,
			3948.051,
			3950.196,
			3951.585,
			3953.619,
			3954.285,
			3954.927,
			3956.81,
			3957.427,
			3958.039,
			3959.843,
			3961.003,
			3962.152,
			3962.724,
			3963.864,
			3964.434,
			3965.55,
			3966.656,
			3967.207,
			3967.758,
			3969.41,
			3970.559,
			3972.312,
			3973.481,
			3974.654,
			3975.271,
			3976.526,
			3977.152,
			3978.406,
			3979.662,
			3981.808,
			3983.268,
			3985.532,
			3986.39,
			3987.249,
			3989.828,
			3991.479,
			3993.918,
			3995.547,
			3996.955,
			3997.658,
			3999.068,
			3999.774,
			4001.169,
			4003.213,
			4004.581,
			4005.957,
			4008.035,
			4008.731,
			4009.429,
			4010.833,
			4012.195,
			4012.873,
			4014.921,
			4016.299,
			4017.655,
			4019.71,
			4021.095,
			4023.193,
			4023.897,
			4024.606,
			4026.754,
			4028.214,
			4030.432,
			4031.931,
			4034.216,
			4035.76,
			4036.537,
			4038.099,
			4039.67,
			4040.461,
			4042.054,
			4043.662,
			4046.099,
			4046.918,
			4047.74,
			4050.246,
			4051.086,
			4051.929,
			4053.631,
			4054.492,
			4056.226,
			4057.973,
			4060.628,
			4062.417,
			4065.131,
			4066.045,
			4066.948,
			4069.72,
			4071.584,
			4074.406,
			4076.292,
			4078.189,
			4079.143,
			4081.056,
			4082.978,
			4083.943,
			4085.884,
			4086.858,
			4088.815,
			4090.785,
			4093.753,
			4095.747,
			4098.746,
			4099.751,
			4100.759,
			4103.795,
			4105.832,
			4108.909,
			4110.975,
			4113.048,
			4114.084,
			4116.16,
			4117.201,
			4119.3,
			4121.409,
			4122.468,
			4124.606,
			4126.754,
			4128.934,
			4131.107,
			4132.198,
			4134.39,
			4136.591,
			4137.695,
			4139.915,
			4141.029,
			4143.265,
			4145.511,
			4148.896,
			4151.162,
			4154.576,
			4155.717,
			4156.86,
			4160.296,
			4162.593,
			4166.042,
			4168.347,
			4171.801,
			4174.111,
			4175.27,
			4177.594,
			4179.932,
			4181.131,
			4183.533,
			4185.992,
			4188.511,
			4191.096,
			4192.431,
			4195.089,
			4197.661,
			4198.937,
			4201.38,
			4202.542,
			4204.843,
			4207.083,
			4210.367,
			4212.498,
			4215.635,
			4216.667,
			4217.691,
			4220.733,
			4222.749,
			4225.759,
			4227.807,
			4229.848,
			4230.872,
			4232.942,
			4235.022,
			4236.066,
			4238.167,
			4239.222,
			4241.336,
			4243.46,
			4246.656,
			4247.72,
			4248.784,
			4251.97,
			4253.025,
			4254.079,
			4257.221,
			4259.29,
			4261.347,
			4264.418,
			4266.456,
			4269.502,
			4270.515,
			4271.527,
			4274.563,
			4275.574,
			4276.584,
			4279.622,
			4281.65,
			4284.725,
			4286.801,
			4287.841,
			4289.966,
			4292.11,
			4293.184,
			4295.382,
			4297.597,
			4300.961,
			4302.088,
			4303.215,
			4306.736,
			4307.926,
			4309.112,
			4312.949,
			4315.499,
			4319.268,
			4321.534,
			4323.732,
			4326.773,
			4327.741,
			4328.686,
			4331.435,
			4332.317,
			4333.193,
			4335.75,
			4337.377,
			4338.969,
			4339.766,
			4341.319,
			4342.844,
			4343.593,
			4345.818,
			4347.36,
			4348.882,
			4351.189,
			4352.851,
			4355.331,
			4356.183,
			4357.097,
			4359.828,
			4361.645,
			4364.358,
			4366.163,
			4367.85,
			4368.684,
			4370.351,
			4371.186,
			4372.892,
			4375.473,
			4377.2,
			4378.951,
			4381.575,
			4382.431,
			4383.261,
			4384.92,
			4386.58,
			4387.402,
			4388.94,
			4389.709,
			4391.248,
			4392.768,
			4394.935,
			4396.377,
			4398.542,
			4399.262,
			4399.981,
			4402.138,
			4403.583,
			4405.775,
			4407.235,
			4409.439,
			4410.916,
			4411.655,
			4413.134,
			4414.617,
			4415.347,
			4416.835,
			4418.332,
			4420.614,
			4421.382,
			4422.167,
			4424.504,
			4425.298,
			4426.097,
			4428.51,
			4430.124,
			4432.601,
			4434.269,
			4435.949,
			4438.492,
			4439.346,
			4440.203,
			4442.791,
			4443.66,
			4444.532,
			4447.167,
			4448.939,
			4451.622,
			4453.424,
			4455.238,
			4456.15,
			4458.905,
			4460.758,
			4462.623,
			4465.444,
			4467.341,
			4470.208,
			4471.17,
			4472.135,
			4475.048,
			4477.004,
			4479.96,
			4481.946,
			4484.946,
			4486.961,
			4487.972,
			4488.986,
			4492.047,
			4493.073,
			4495.133,
			4497.204,
			4499.285,
			4501.378,
			4502.428,
			4504.525,
			4506.625,
			4507.677,
			4510.85,
			4512.978,
			4515.114,
			4518.339,
			4520.501,
			4523.763,
			4524.855,
			4525.95,
			4529.25,
			4531.462,
			4534.798,
			4537.034,
			4539.28,
			4540.404,
			4542.659,
			4543.79,
			4546.057,
			4548.332,
			4549.473,
			4551.764,
			4554.066,
			4557.542,
			4558.707,
			4559.875,
			4563.394,
			4564.572,
			4565.753,
			4568.123,
			4569.312,
			4571.696,
			4574.096,
			4577.717,
			4580.146,
			4583.804,
			4585.026,
			4586.251,
			4589.935,
			4592.399,
			4596.109,
			4598.594,
			4601.085,
			4602.334,
			4604.838,
			4606.093,
			4608.61,
			4611.136,
			4612.401,
			4614.939,
			4617.485,
			4621.32,
			4622.603,
			4623.887,
			4627.751,
			4629.043,
			4630.337,
			4634.231,
			4636.837,
			4640.745,
			4643.358,
			4645.979,
			4649.924,
			4651.242,
			4652.562,
			4656.533,
			4657.861,
			4659.19,
			4663.188,
			4665.862,
			4668.543,
			4669.887,
			4672.579,
			4675.278,
			4676.631,
			4679.34,
			4680.696,
			4683.414,
			4686.137,
			4690.232,
			4692.972,
			4697.097,
			4698.476,
			4699.857,
			4704.011,
			4706.789,
			4710.97,
			4713.766,
			4716.568,
			4717.972,
			4720.786,
			4722.194,
			4723.605,
			4727.848,
			4729.266,
			4730.686,
			4734.955,
			4737.81,
			4740.671,
			4742.104,
			4744.994
		],
		[
			0,
			0.016,
			0.033,
			0.077,
			0.142,
			0.202,
			0.249,
			0.326,
			0.357,
			0.388,
			0.451,
			0.511,
			0.579,
			0.637,
			0.684,
			0.699,
			0.761,
			0.791,
			0.823,
			0.884,
			0.9,
			0.945,
			1.009,
			1.071,
			1.118,
			1.133,
			1.196,
			1.226,
			1.256,
			1.318,
			1.38,
			1.442,
			1.505,
			1.568,
			1.629,
			1.66,
			1.692,
			1.767,
			1.813,
			1.878,
			1.94,
			1.985,
			2.001,
			2.063,
			2.092,
			2.125,
			2.186,
			2.203,
			2.248,
			2.311,
			2.373,
			2.42,
			2.497,
			2.526,
			2.56,
			2.639,
			2.683,
			2.744,
			2.807,
			2.854,
			2.931,
			2.96,
			2.992,
			3.072,
			3.118,
			3.178,
			3.242,
			3.288,
			3.302,
			3.365,
			3.395,
			3.427,
			3.505,
			3.552,
			3.615,
			3.676,
			3.722,
			3.8,
			3.83,
			3.861,
			3.923,
			3.985,
			4.048,
			4.109,
			4.157,
			4.172,
			4.234,
			4.263,
			4.295,
			4.356,
			4.372,
			4.42,
			4.481,
			4.543,
			4.589,
			4.606,
			4.667,
			4.705,
			4.728,
			4.792,
			4.823,
			4.853,
			4.915,
			4.93,
			4.978,
			5.04,
			5.101,
			5.164,
			5.226,
			5.257,
			5.286,
			5.351,
			5.41,
			5.473,
			5.534,
			5.598,
			5.659,
			5.69,
			5.72,
			5.8,
			5.845,
			5.909,
			5.97,
			6.017,
			6.032,
			6.093,
			6.123,
			6.155,
			6.217,
			6.234,
			6.28,
			6.342,
			6.403,
			6.451,
			6.526,
			6.559,
			6.59,
			6.667,
			6.714,
			6.777,
			6.837,
			6.884,
			6.901,
			6.963,
			6.994,
			7.023,
			7.086,
			7.101,
			7.148,
			7.208,
			7.273,
			7.334,
			7.398,
			7.427,
			7.457,
			7.52,
			7.582,
			7.642,
			7.707,
			7.751,
			7.828,
			7.861,
			7.893,
			7.955,
			7.969,
			8.016,
			8.076,
			8.139,
			8.186,
			8.201,
			8.262,
			8.294,
			8.327,
			8.387,
			8.403,
			8.451,
			8.512,
			8.573,
			8.621,
			8.698,
			8.728,
			8.759,
			8.823,
			8.844,
			8.883,
			8.948,
			9.007,
			9.071,
			9.132,
			9.161,
			9.194,
			9.257,
			9.271,
			9.319,
			9.382,
			9.442,
			9.488,
			9.503,
			9.566,
			9.596,
			9.628,
			9.69,
			9.705,
			9.753,
			9.815,
			9.877,
			9.922,
			10.001,
			10.03,
			10.061,
			10.125,
			10.186,
			10.249,
			10.31,
			10.372,
			10.435,
			10.463,
			10.498,
			10.559,
			10.573,
			10.619,
			10.683,
			10.743,
			10.791,
			10.805,
			10.868,
			10.906,
			10.93,
			10.993,
			11.055,
			11.117,
			11.178,
			11.226,
			11.304,
			11.334,
			11.365,
			11.426,
			11.488,
			11.55,
			11.614,
			11.66,
			11.674,
			11.736,
			11.767,
			11.799,
			11.861,
			11.877,
			11.924,
			11.985,
			12.046,
			12.093,
			12.109,
			12.17,
			12.202,
			12.232,
			12.293,
			12.31,
			12.356,
			12.418,
			12.482,
			12.544,
			12.604,
			12.636,
			12.665,
			12.729,
			12.745,
			12.791,
			12.852,
			12.915,
			12.968,
			13.039,
			13.07,
			13.102,
			13.179,
			13.225,
			13.285,
			13.349,
			13.395,
			13.473,
			13.504,
			13.535,
			13.612,
			13.658,
			13.721,
			13.782,
			13.831,
			13.844,
			13.907,
			13.937,
			13.969,
			14.028,
			14.047,
			14.091,
			14.155,
			14.217,
			14.264,
			14.277,
			14.341,
			14.372,
			14.404,
			14.465,
			14.481,
			14.527,
			14.59,
			14.651,
			14.699,
			14.776,
			14.806,
			14.837,
			14.899,
			14.961,
			15.026,
			15.084,
			15.148,
			15.208,
			15.241,
			15.271,
			15.332,
			15.349,
			15.394,
			15.457,
			15.52,
			15.566,
			15.581,
			15.643,
			15.675,
			15.706,
			15.782,
			15.83,
			15.89,
			15.952,
			16.001,
			16.077,
			16.107,
			16.139,
			16.201,
			16.263,
			16.325,
			16.387,
			16.434,
			16.45,
			16.51,
			16.543,
			16.573,
			16.635,
			16.652,
			16.697,
			16.759,
			16.822,
			16.869,
			16.946,
			16.976,
			17.006,
			17.07,
			17.131,
			17.199,
			17.254,
			17.302,
			17.378,
			17.41,
			17.443,
			17.52,
			17.565,
			17.628,
			17.689,
			17.737,
			17.75,
			17.811,
			17.843,
			17.877,
			17.954,
			18,
			18.06,
			18.123,
			18.17,
			18.246,
			18.277,
			18.31,
			18.37,
			18.433,
			18.497,
			18.558,
			18.603,
			18.62,
			18.682,
			18.715,
			18.744,
			18.806,
			18.82,
			18.867,
			18.931,
			18.992,
			19.039,
			19.053,
			19.116,
			19.152,
			19.176,
			19.255,
			19.303,
			19.366,
			19.427,
			19.473,
			19.548,
			19.58,
			19.611,
			19.672,
			19.735,
			19.799,
			19.86,
			19.906,
			19.923,
			19.985,
			20.014,
			20.047,
			20.107,
			20.125,
			20.171,
			20.232,
			20.295,
			20.341,
			20.355,
			20.418,
			20.45,
			20.481,
			20.543,
			20.557,
			20.605,
			20.666,
			20.727,
			20.776,
			20.853,
			20.884,
			20.912,
			20.976,
			21.039,
			21.1,
			21.16,
			21.223,
			21.285,
			21.317,
			21.348,
			21.409,
			21.426,
			21.472,
			21.533,
			21.594,
			21.643,
			21.659,
			21.72,
			21.752,
			21.78,
			21.843,
			21.859,
			21.905,
			21.967,
			22.031,
			22.078,
			22.093,
			22.154,
			22.186,
			22.217,
			22.278,
			22.293,
			22.34,
			22.403,
			22.466,
			22.512,
			22.525,
			22.589,
			22.618,
			22.651,
			22.711,
			22.728,
			22.775,
			22.838,
			22.898,
			22.943,
			22.961,
			23.022,
			23.053,
			23.084,
			23.145,
			23.208,
			23.276,
			23.332,
			23.378,
			23.457,
			23.486,
			23.52,
			23.579,
			23.597,
			23.643,
			23.705,
			23.768,
			23.815,
			23.828,
			23.891,
			23.921,
			23.951,
			24.015,
			24.028,
			24.078,
			24.14,
			24.201,
			24.263,
			24.325,
			24.355,
			24.388,
			24.449,
			24.511,
			24.572,
			24.633,
			24.697,
			24.758,
			24.788,
			24.82,
			24.881,
			24.897,
			24.944,
			25.007,
			25.07,
			25.116,
			25.131,
			25.191,
			25.225,
			25.255,
			25.316,
			25.336,
			25.378,
			25.442,
			25.502,
			25.564,
			25.627,
			25.659,
			25.69,
			25.751,
			25.814,
			25.875,
			25.936,
			25.984,
			25.998,
			26.061,
			26.091,
			26.123,
			26.184,
			26.199,
			26.248,
			26.31,
			26.371,
			26.417,
			26.432,
			26.493,
			26.527,
			26.557,
			26.62,
			26.68,
			26.743,
			26.804,
			26.851,
			26.928,
			26.961,
			26.99,
			27.07,
			27.117,
			27.176,
			27.24,
			27.286,
			27.301,
			27.361,
			27.4,
			27.425,
			27.503,
			27.548,
			27.612,
			27.674,
			27.721,
			27.795,
			27.826,
			27.858,
			27.922,
			27.984,
			28.046,
			28.106,
			28.154,
			28.171,
			28.232,
			28.261,
			28.292,
			28.372,
			28.418,
			28.478,
			28.542,
			28.589,
			28.603,
			28.665,
			28.698,
			28.729,
			28.804,
			28.85,
			28.914,
			28.975,
			29.023,
			29.099,
			29.132,
			29.161,
			29.223,
			29.285,
			29.348,
			29.409,
			29.473,
			29.535,
			29.564,
			29.595,
			29.673,
			29.72,
			29.782,
			29.843,
			29.906,
			29.967,
			29.999,
			30.028,
			30.09,
			30.108,
			30.154,
			30.217,
			30.277,
			30.338,
			30.403,
			30.434,
			30.462,
			30.542,
			30.587,
			30.649,
			30.712,
			30.759,
			30.772,
			30.835,
			30.868,
			30.897,
			30.959,
			30.976,
			31.02,
			31.086,
			31.144,
			31.192,
			31.209,
			31.271,
			31.301,
			31.332,
			31.41,
			31.456,
			31.523,
			31.58,
			31.641,
			31.705,
			31.734,
			31.766,
			31.828,
			31.845,
			31.891,
			31.952,
			32.016,
			32.061,
			32.076,
			32.139,
			32.17,
			32.2,
			32.26,
			32.277,
			32.324,
			32.386,
			32.448,
			32.51,
			32.573,
			32.603,
			32.635,
			32.697,
			32.759,
			32.821,
			32.883,
			32.943,
			33.006,
			33.037,
			33.069,
			33.131,
			33.144,
			33.191,
			33.254,
			33.315,
			33.379,
			33.439,
			33.471,
			33.503,
			33.565,
			33.585,
			33.626,
			33.688,
			33.752,
			33.813,
			33.873,
			33.905,
			33.937,
			34.014,
			34.06,
			34.123,
			34.185,
			34.232,
			34.246,
			34.309,
			34.341,
			34.369,
			34.432,
			34.449,
			34.493,
			34.558,
			34.619,
			34.68,
			34.744,
			34.773,
			34.805,
			34.865,
			34.882,
			34.929,
			34.991,
			35.053,
			35.098,
			35.175,
			35.208,
			35.239,
			35.299,
			35.363,
			35.425,
			35.488,
			35.548,
			35.609,
			35.645,
			35.673,
			35.749,
			35.798,
			35.86,
			35.921,
			35.967,
			35.984,
			36.044,
			36.075,
			36.107,
			36.168,
			36.231,
			36.294,
			36.353,
			36.402,
			36.418,
			36.48,
			36.509,
			36.54,
			36.603,
			36.666,
			36.728,
			36.788,
			36.834,
			36.851,
			36.914,
			36.942,
			36.976,
			37.038,
			37.052,
			37.099,
			37.159,
			37.222,
			37.269,
			37.348,
			37.379,
			37.409,
			37.472,
			37.533,
			37.596,
			37.658,
			37.706,
			37.782,
			37.813,
			37.842,
			37.92,
			37.968,
			38.031,
			38.09,
			38.139,
			38.153,
			38.214,
			38.245,
			38.276,
			38.339,
			38.353,
			38.4,
			38.461,
			38.526,
			38.573,
			38.649,
			38.679,
			38.712,
			38.772,
			38.835,
			38.899,
			38.959,
			39.005,
			39.021,
			39.083,
			39.115,
			39.145,
			39.208,
			39.222,
			39.269,
			39.333,
			39.392,
			39.44,
			39.454,
			39.518,
			39.546,
			39.578,
			39.64,
			39.657,
			39.704,
			39.769,
			39.827,
			39.874,
			39.953,
			39.981,
			40.014,
			40.075,
			40.136,
			40.2,
			40.26,
			40.307,
			40.322,
			40.385,
			40.416,
			40.446,
			40.508,
			40.526,
			40.572,
			40.634,
			40.696,
			40.742,
			40.758,
			40.821,
			40.849,
			40.881,
			40.958,
			41.006,
			41.068,
			41.129,
			41.176,
			41.253,
			41.285,
			41.315,
			41.393,
			41.441,
			41.503,
			41.565,
			41.611,
			41.625,
			41.687,
			41.719,
			41.749,
			41.81,
			41.832,
			41.875,
			41.936,
			41.998,
			42.044,
			42.123,
			42.153,
			42.185,
			42.244,
			42.309,
			42.37,
			42.432,
			42.476,
			42.495,
			42.556,
			42.586,
			42.619,
			42.681,
			42.693,
			42.742,
			42.803,
			42.867,
			42.913,
			42.926,
			42.991,
			43.02,
			43.052,
			43.114,
			43.128,
			43.177,
			43.237,
			43.299,
			43.346,
			43.423,
			43.455,
			43.486,
			43.545,
			43.609,
			43.673,
			43.735,
			43.782,
			43.796,
			43.858,
			43.892,
			43.919,
			43.982,
			43.998,
			44.045,
			44.108,
			44.168,
			44.23,
			44.292,
			44.323,
			44.353,
			44.417,
			44.429,
			44.476,
			44.54,
			44.602,
			44.664,
			44.726,
			44.757,
			44.789,
			44.85,
			44.911,
			44.975,
			45.037,
			45.082,
			45.1,
			45.161,
			45.191,
			45.223,
			45.299,
			45.344,
			45.408,
			45.47,
			45.534,
			45.594,
			45.624,
			45.657,
			45.72,
			45.781,
			45.844,
			45.906,
			45.967,
			46.027,
			46.059,
			46.091,
			46.153,
			46.169,
			46.214,
			46.282,
			46.339,
			46.386,
			46.401,
			46.461,
			46.494,
			46.523,
			46.601,
			46.648,
			46.711,
			46.773,
			46.82,
			46.897,
			46.928,
			46.959,
			47.019,
			47.083,
			47.145,
			47.208,
			47.254,
			47.268,
			47.331,
			47.363,
			47.391,
			47.454,
			47.469,
			47.517,
			47.581,
			47.641,
			47.688,
			47.703,
			47.765,
			47.795,
			47.825,
			47.905,
			47.951,
			48.017,
			48.074,
			48.136,
			48.2,
			48.23,
			48.261,
			48.323,
			48.385,
			48.447,
			48.509,
			48.556,
			48.572,
			48.633,
			48.664,
			48.693,
			48.756,
			48.772,
			48.818,
			48.882,
			48.944,
			48.989,
			49.006,
			49.067,
			49.099,
			49.13,
			49.192,
			49.206,
			49.254,
			49.315,
			49.376,
			49.423,
			49.502,
			49.532,
			49.563,
			49.624,
			49.687,
			49.749,
			49.809,
			49.858,
			49.873,
			49.936,
			49.967,
			49.995,
			50.059,
			50.076,
			50.12,
			50.183,
			50.245,
			50.291,
			50.308,
			50.37,
			50.4,
			50.431,
			50.494,
			50.556,
			50.618,
			50.678,
			50.726,
			50.802,
			50.835,
			50.865,
			50.941,
			50.988,
			51.052,
			51.115,
			51.174,
			51.237,
			51.27,
			51.3,
			51.36,
			51.377,
			51.423,
			51.485,
			51.549,
			51.594,
			51.672,
			51.703,
			51.733,
			51.795,
			51.857,
			51.919,
			51.983,
			52.027,
			52.043,
			52.106,
			52.142,
			52.168,
			52.23,
			52.292,
			52.355,
			52.416,
			52.462,
			52.478,
			52.538,
			52.57,
			52.603,
			52.663,
			52.68,
			52.726,
			52.788,
			52.85,
			52.911,
			52.974,
			53.003,
			53.036,
			53.099,
			53.158,
			53.223,
			53.283,
			53.345,
			53.408,
			53.439,
			53.47,
			53.532,
			53.549,
			53.592,
			53.656,
			53.717,
			53.777,
			53.842,
			53.872,
			53.904,
			53.982,
			54.026,
			54.09,
			54.152,
			54.214,
			54.277,
			54.307,
			54.339,
			54.4,
			54.46,
			54.526,
			54.586,
			54.632,
			54.646,
			54.71,
			54.742,
			54.772,
			54.834,
			54.85,
			54.895,
			54.958,
			55.02,
			55.067,
			55.083,
			55.144,
			55.175,
			55.206,
			55.268,
			55.33,
			55.391,
			55.455,
			55.515,
			55.578,
			55.61,
			55.64,
			55.702,
			55.764,
			55.827,
			55.887,
			55.935,
			55.95,
			56.011,
			56.042,
			56.073,
			56.136,
			56.15,
			56.199,
			56.265,
			56.323,
			56.384,
			56.446,
			56.477,
			56.509,
			56.57,
			56.633,
			56.696,
			56.756,
			56.803,
			56.88,
			56.912,
			56.942,
			57.006,
			57.02,
			57.065,
			57.128,
			57.192,
			57.251,
			57.312,
			57.348,
			57.377,
			57.437,
			57.455,
			57.502,
			57.565,
			57.626,
			57.672,
			57.749,
			57.778,
			57.81,
			57.873,
			57.935,
			57.997,
			58.059,
			58.105,
			58.119,
			58.181,
			58.212,
			58.245,
			58.326,
			58.367,
			58.431,
			58.494,
			58.54,
			58.555,
			58.617,
			58.649,
			58.679,
			58.739,
			58.756,
			58.803,
			58.865,
			58.927,
			58.987,
			59.053,
			59.082,
			59.112,
			59.176,
			59.236,
			59.299,
			59.36,
			59.423,
			59.483,
			59.516,
			59.547,
			59.609,
			59.623,
			59.671,
			59.733,
			59.793,
			59.843,
			59.858,
			59.919,
			59.949,
			59.98,
			60.06,
			60.106,
			60.167,
			60.23,
			60.276,
			60.354,
			60.387,
			60.413,
			60.477,
			60.493,
			60.538,
			60.601,
			60.662,
			60.71,
			60.724,
			60.787,
			60.818,
			60.848,
			60.912,
			60.926,
			60.973,
			61.036,
			61.095,
			61.144,
			61.222,
			61.253,
			61.283,
			61.345,
			61.406,
			61.468,
			61.532,
			61.576,
			61.594,
			61.654,
			61.687,
			61.716,
			61.78,
			61.795,
			61.84,
			61.902,
			61.965,
			62.014,
			62.026,
			62.09,
			62.119,
			62.151,
			62.213,
			62.23,
			62.276,
			62.337,
			62.4,
			62.45,
			62.523,
			62.554,
			62.584,
			62.645,
			62.711,
			62.772,
			62.835,
			62.881,
			62.895,
			62.958,
			62.988,
			63.02,
			63.082,
			63.098,
			63.143,
			63.206,
			63.268,
			63.33,
			63.391,
			63.423,
			63.452,
			63.514,
			63.529,
			63.576,
			63.64,
			63.703,
			63.747,
			63.826,
			63.857,
			63.887,
			63.95,
			64.01,
			64.074,
			64.135,
			64.183,
			64.198,
			64.258,
			64.291,
			64.323,
			64.384,
			64.4,
			64.447,
			64.511,
			64.57,
			64.617,
			64.633,
			64.694,
			64.726,
			64.756,
			64.817,
			64.834,
			64.88,
			64.941,
			65.002,
			65.049,
			65.127,
			65.158,
			65.189,
			65.252,
			65.268,
			65.311,
			65.38,
			65.437,
			65.485,
			65.5,
			65.561,
			65.592,
			65.624,
			65.702,
			65.749,
			65.809,
			65.871,
			65.918,
			65.995,
			66.028,
			66.059,
			66.136,
			66.181,
			66.245,
			66.306,
			66.353,
			66.43,
			66.461,
			66.493,
			66.553,
			66.575,
			66.615,
			66.678,
			66.74,
			66.786,
			66.803,
			66.865,
			66.894,
			66.925,
			67.003,
			67.051,
			67.114,
			67.174,
			67.235,
			67.299,
			67.329,
			67.361,
			67.421,
			67.484,
			67.548,
			67.609,
			67.653,
			67.67,
			67.732,
			67.761,
			67.794,
			67.857,
			67.873,
			67.919,
			67.98,
			68.043,
			68.089,
			68.105,
			68.165,
			68.196,
			68.228,
			68.291,
			68.305,
			68.353,
			68.416,
			68.419,
			68.476,
			68.523,
			68.601,
			68.637,
			68.661,
			68.724,
			68.787,
			68.849,
			68.912,
			68.957,
			68.974,
			69.035,
			69.067,
			69.095,
			69.175,
			69.221,
			69.283,
			69.345,
			69.392,
			69.405,
			69.468,
			69.499,
			69.532,
			69.594,
			69.655,
			69.716,
			69.78,
			69.825,
			69.902,
			69.933,
			69.965,
			70.028,
			70.087,
			70.15,
			70.213,
			70.259,
			70.274,
			70.336,
			70.368,
			70.399,
			70.461,
			70.475,
			70.523,
			70.585,
			70.647,
			70.707,
			70.77,
			70.801,
			70.834,
			70.894,
			70.957,
			71.02,
			71.082,
			71.144,
			71.205,
			71.236,
			71.267,
			71.33,
			71.345,
			71.39,
			71.453,
			71.516,
			71.563,
			71.577,
			71.639,
			71.669,
			71.702,
			71.763,
			71.78,
			71.824,
			71.888,
			71.949,
			71.995,
			72.074,
			72.105,
			72.135,
			72.195,
			72.258,
			72.321,
			72.381,
			72.43,
			72.444,
			72.507,
			72.537,
			72.57,
			72.645,
			72.693,
			72.754,
			72.817,
			72.862,
			72.88,
			72.941,
			72.973,
			73.003,
			73.066,
			73.08,
			73.128,
			73.189,
			73.25,
			73.298,
			73.374,
			73.407,
			73.437,
			73.5,
			73.562,
			73.623,
			73.686,
			73.733,
			73.747,
			73.81,
			73.84,
			73.87,
			73.949,
			73.996,
			74.058,
			74.12,
			74.166,
			74.181,
			74.245,
			74.275,
			74.305,
			74.369,
			74.43,
			74.491,
			74.553,
			74.601,
			74.678,
			74.708,
			74.74,
			74.801,
			74.824,
			74.864,
			74.926,
			74.989,
			75.035,
			75.05,
			75.111,
			75.142,
			75.173,
			75.235,
			75.252,
			75.298,
			75.359,
			75.421,
			75.469,
			75.546,
			75.575,
			75.608,
			75.67,
			75.732,
			75.798,
			75.856,
			75.917,
			75.978,
			76.012,
			76.042,
			76.119,
			76.166,
			76.228,
			76.291,
			76.353,
			76.415,
			76.443,
			76.475,
			76.538,
			76.552,
			76.601,
			76.66,
			76.722,
			76.77,
			76.848,
			76.886,
			76.909,
			76.988,
			77.034,
			77.095,
			77.157,
			77.219,
			77.283,
			77.313,
			77.344,
			77.406,
			77.422,
			77.468,
			77.53,
			77.593,
			77.64,
			77.655,
			77.717,
			77.748,
			77.779,
			77.855,
			77.901,
			77.965,
			78.026,
			78.072,
			78.15,
			78.182,
			78.212,
			78.274,
			78.336,
			78.403,
			78.461,
			78.505,
			78.523,
			78.583,
			78.616,
			78.646,
			78.709,
			78.723,
			78.769,
			78.833,
			78.893,
			78.956,
			79.018,
			79.049,
			79.08,
			79.159,
			79.203,
			79.266,
			79.326,
			79.391,
			79.453,
			79.485,
			79.513,
			79.593,
			79.637,
			79.7,
			79.761,
			79.81,
			79.824,
			79.886,
			79.917,
			79.948,
			80.012,
			80.025,
			80.072,
			80.135,
			80.197,
			80.243,
			80.258,
			80.32,
			80.353,
			80.382,
			80.444,
			80.508,
			80.57,
			80.629,
			80.677,
			80.693,
			80.756,
			80.786,
			80.818,
			80.881,
			80.894,
			80.942,
			81.009,
			81.065,
			81.112,
			81.128,
			81.188,
			81.22,
			81.252,
			81.312,
			81.328,
			81.375,
			81.438,
			81.499,
			81.56,
			81.623,
			81.654,
			81.685,
			81.745,
			81.808,
			81.871,
			81.934,
			81.977,
			81.995,
			82.057,
			82.088,
			82.12,
			82.182,
			82.197,
			82.243,
			82.305,
			82.367,
			82.412,
			82.43,
			82.489,
			82.521,
			82.554,
			82.616,
			82.629,
			82.678,
			82.739,
			82.801,
			82.845,
			82.926,
			82.956,
			82.987,
			83.048,
			83.072,
			83.11,
			83.173,
			83.234,
			83.283,
			83.297,
			83.36,
			83.39,
			83.42,
			83.483,
			83.499,
			83.545,
			83.609,
			83.67,
			83.716,
			83.732,
			83.793,
			83.823,
			83.856,
			83.918,
			83.979,
			84.042,
			84.103,
			84.149,
			84.226,
			84.257,
			84.288,
			84.351,
			84.413,
			84.476,
			84.537,
			84.599,
			84.66,
			84.691,
			84.722,
			84.785,
			84.801,
			84.847,
			84.911,
			84.97,
			85.018,
			85.094,
			85.133,
			85.156,
			85.235,
			85.281,
			85.348,
			85.405,
			85.452,
			85.468,
			85.53,
			85.56,
			85.593,
			85.654,
			85.669,
			85.716,
			85.78,
			85.839,
			85.887,
			85.901,
			85.965,
			85.994,
			86.026,
			86.088,
			86.103,
			86.151,
			86.21,
			86.274,
			86.319,
			86.397,
			86.427,
			86.461,
			86.523,
			86.583,
			86.647,
			86.708,
			86.753,
			86.77,
			86.832,
			86.865,
			86.892,
			86.957,
			86.97,
			87.016,
			87.078,
			87.143,
			87.19,
			87.203,
			87.266,
			87.295,
			87.328,
			87.389,
			87.406,
			87.453,
			87.515,
			87.575,
			87.623,
			87.7,
			87.731,
			87.76,
			87.825,
			87.884,
			87.948,
			88.01,
			88.056,
			88.07,
			88.134,
			88.163,
			88.194,
			88.256,
			88.274,
			88.321,
			88.382,
			88.445,
			88.491,
			88.505,
			88.569,
			88.598,
			88.629,
			88.693,
			88.707,
			88.755,
			88.817,
			88.879,
			88.925,
			89.001,
			89.031,
			89.064,
			89.142,
			89.188,
			89.25,
			89.31,
			89.357,
			89.374,
			89.435,
			89.468,
			89.499,
			89.561,
			89.575,
			89.623,
			89.683,
			89.744,
			89.808,
			89.87,
			89.902,
			89.931,
			89.994,
			90.057,
			90.118,
			90.181,
			90.242,
			90.304,
			90.336,
			90.365,
			90.445,
			90.491,
			90.552,
			90.612,
			90.66,
			90.675,
			90.738,
			90.769,
			90.8,
			90.878,
			90.923,
			90.986,
			91.05,
			91.094,
			91.172,
			91.202,
			91.234,
			91.294,
			91.32,
			91.359,
			91.421,
			91.482,
			91.528,
			91.545,
			91.606,
			91.636,
			91.67,
			91.73,
			91.747,
			91.791,
			91.854,
			91.917,
			91.978,
			92.039,
			92.072,
			92.103,
			92.165,
			92.18,
			92.226,
			92.29,
			92.349,
			92.395,
			92.473,
			92.506,
			92.536,
			92.599,
			92.659,
			92.723,
			92.785,
			92.832,
			92.845,
			92.909,
			92.938,
			92.97,
			93.049,
			93.096,
			93.158,
			93.219,
			93.266,
			93.28,
			93.341,
			93.379,
			93.404,
			93.482,
			93.53,
			93.59,
			93.651,
			93.698,
			93.775,
			93.808,
			93.839,
			93.902,
			93.964,
			94.026,
			94.088,
			94.15,
			94.212,
			94.242,
			94.274,
			94.336,
			94.351,
			94.397,
			94.459,
			94.52,
			94.567,
			94.581,
			94.645,
			94.676,
			94.707,
			94.768,
			94.784,
			94.832,
			94.892,
			94.956,
			95.003,
			95.08,
			95.109,
			95.141,
			95.202,
			95.265,
			95.327,
			95.388,
			95.44,
			95.452,
			95.514,
			95.545,
			95.575,
			95.636,
			95.654,
			95.699,
			95.76,
			95.822,
			95.871,
			95.947,
			95.979,
			96.008,
			96.07,
			96.133,
			96.194,
			96.258,
			96.303,
			96.319,
			96.381,
			96.41,
			96.443,
			96.504,
			96.52,
			96.569,
			96.63,
			96.692,
			96.737,
			96.755,
			96.815,
			96.846,
			96.876,
			96.939,
			96.954,
			97.002,
			97.065,
			97.126,
			97.186,
			97.25,
			97.281,
			97.309,
			97.374,
			97.434,
			97.501,
			97.559,
			97.623,
			97.685,
			97.715,
			97.745,
			97.806,
			97.823,
			97.87,
			97.933,
			97.993,
			98.039,
			98.054,
			98.116,
			98.148,
			98.181,
			98.243,
			98.258,
			98.305,
			98.365,
			98.428,
			98.475,
			98.551,
			98.583,
			98.612,
			98.676,
			98.738,
			98.799,
			98.863,
			98.909,
			98.923,
			98.985,
			99.018,
			99.049,
			99.11,
			99.124,
			99.171,
			99.234,
			99.296,
			99.341,
			99.358,
			99.421,
			99.451,
			99.48,
			99.565,
			99.604,
			99.667,
			99.729,
			99.776,
			99.853,
			99.885,
			99.915,
			99.978,
			99.995,
			100.041,
			100.103,
			100.165,
			100.227,
			100.288,
			100.318,
			100.351,
			100.411,
			100.427,
			100.475,
			100.538,
			100.599,
			100.643,
			100.659,
			100.723,
			100.754,
			100.784,
			100.844,
			100.907,
			100.97,
			101.032,
			101.078,
			101.157,
			101.188,
			101.218,
			101.28,
			101.294,
			101.341,
			101.405,
			101.465,
			101.512,
			101.526,
			101.589,
			101.627,
			101.653,
			101.731,
			101.776,
			101.839,
			101.899,
			101.946,
			102.025,
			102.056,
			102.085,
			102.148,
			102.209,
			102.271,
			102.336,
			102.382,
			102.397,
			102.459,
			102.489,
			102.52,
			102.599,
			102.645,
			102.706,
			102.77,
			102.815,
			102.831,
			102.892,
			102.923,
			102.954,
			103.016,
			103.032,
			103.078,
			103.142,
			103.203,
			103.248,
			103.325,
			103.359,
			103.39,
			103.466,
			103.51,
			103.576,
			103.637,
			103.699,
			103.762,
			103.793,
			103.823,
			103.884,
			103.901,
			103.945,
			104.008,
			104.07,
			104.117,
			104.132,
			104.194,
			104.225,
			104.256,
			104.334,
			104.38,
			104.444,
			104.505,
			104.551,
			104.628,
			104.66,
			104.691,
			104.752,
			104.768,
			104.815,
			104.877,
			104.939,
			105.001,
			105.064,
			105.093,
			105.125,
			105.203,
			105.249,
			105.311,
			105.372,
			105.421,
			105.498,
			105.528,
			105.558,
			105.636,
			105.684,
			105.745,
			105.806,
			105.869,
			105.932,
			105.963,
			105.993,
			106.055,
			106.072,
			106.118,
			106.18,
			106.241,
			106.286,
			106.303,
			106.365,
			106.395,
			106.428,
			106.506,
			106.552,
			106.614,
			106.676,
			106.72,
			106.8,
			106.828,
			106.862,
			106.924,
			106.985,
			107.046,
			107.11,
			107.156,
			107.17,
			107.234,
			107.264,
			107.295,
			107.357,
			107.373,
			107.419,
			107.482,
			107.543,
			107.591,
			107.606,
			107.668,
			107.699,
			107.729,
			107.792,
			107.81,
			107.853,
			107.914,
			107.977,
			108.025,
			108.101,
			108.133,
			108.164,
			108.24,
			108.287,
			108.35,
			108.412,
			108.458,
			108.473,
			108.536,
			108.567,
			108.597,
			108.658,
			108.674,
			108.722,
			108.784,
			108.845,
			108.892,
			108.906,
			108.971,
			109.001,
			109.031,
			109.094,
			109.156,
			109.218,
			109.28,
			109.326,
			109.404,
			109.435,
			109.465,
			109.528,
			109.543,
			109.59,
			109.653,
			109.711,
			109.76,
			109.776,
			109.838,
			109.874,
			109.899,
			109.96,
			109.976,
			110.022,
			110.085,
			110.148,
			110.209,
			110.272,
			110.304,
			110.335,
			110.41,
			110.459,
			110.519,
			110.581,
			110.626,
			110.645,
			110.704,
			110.738,
			110.768,
			110.831,
			110.843,
			110.892,
			110.953,
			111.017,
			111.06,
			111.076,
			111.13
		]
	],
	'6657_7026_7982': [
		[
			35.916
		],
		[
			0
		],
		[
			0,
			1.565,
			4.475,
			8.851,
			11.774,
			13.238,
			16.169,
			19.106,
			20.578,
			23.528,
			26.484,
			30.932,
			33.904,
			38.375,
			39.868,
			41.361,
			45.848,
			48.845,
			53.353,
			56.368,
			59.391,
			63.933,
			65.446,
			66.959,
			71.511,
			73.033,
			74.557,
			79.181,
			82.263,
			85.341,
			86.88,
			89.96,
			93.047,
			94.593,
			97.692,
			99.244,
			102.355,
			105.47,
			110.147,
			113.263,
			117.92,
			119.464,
			121,
			125.564,
			128.56,
			132.981,
			135.88,
			140.156,
			142.959,
			144.347,
			147.093,
			149.8,
			151.14,
			153.791,
			156.403,
			158.979,
			161.517,
			162.771,
			165.253,
			167.698,
			168.906,
			172.479,
			174.818,
			177.122,
			180.507,
			182.717,
			185.962,
			187.025,
			188.079,
			191.185,
			193.21,
			196.179,
			198.112,
			200.008,
			200.942,
			202.783,
			203.691,
			205.481,
			207.25,
			208.115,
			209.818,
			211.488,
			213.126,
			215.533,
			217.89,
			218.671,
			219.452,
			221.007,
			221.783,
			223.337,
			224.892,
			227.233,
			228.793,
			231.149,
			231.941,
			232.733,
			235.102,
			236.693,
			239.074,
			240.66,
			242.247,
			243.04,
			244.624,
			245.417,
			247.061,
			248.708,
			249.531,
			251.207,
			252.961,
			255.59,
			257.393,
			260.112,
			261.018,
			261.888,
			264.478,
			266.201,
			267.905,
			270.454,
			272.163,
			274.748,
			275.607,
			276.467,
			278.998,
			279.838,
			280.677,
			283.136,
			284.738,
			286.336,
			287.132,
			288.683,
			290.231,
			291.003,
			292.547,
			293.313,
			294.842,
			296.369,
			298.685,
			300.234,
			302.557,
			303.331,
			304.105,
			306.422,
			307.964,
			310.253,
			311.776,
			313.307,
			314.08,
			315.623,
			316.393,
			317.933,
			320.266,
			321.041,
			323.362,
			324.895,
			326.426,
			327.191,
			328.725,
			330.263,
			331.031,
			332.567,
			333.339,
			334.912,
			336.484,
			338.856,
			340.463,
			342.88,
			343.689,
			344.509,
			346.975,
			348.627,
			351.17,
			352.877,
			354.614,
			355.513,
			357.318,
			358.223,
			360.087,
			362.041,
			363.021,
			364.97,
			366.87,
			369.733,
			370.634,
			371.529,
			374.223,
			375.12,
			376.001,
			377.765,
			378.649,
			380.423,
			382.176,
			384.821,
			386.589,
			389.249,
			390.141,
			391.037,
			393.75,
			395.574,
			398.343,
			400.203,
			402.08,
			403.025,
			404.924,
			405.878,
			407.79,
			410.679,
			412.621,
			414.559,
			417.517,
			419.511,
			422.525,
			423.536,
			424.55,
			426.586,
			427.608,
			429.661,
			431.726,
			434.843,
			437.983,
			440.078,
			441.125,
			442.173,
			445.334,
			447.454,
			450.651,
			452.794,
			454.947,
			456.027,
			458.195,
			460.373,
			461.467,
			464.76,
			466.966,
			469.183,
			472.526,
			473.645,
			474.768,
			478.15,
			479.283,
			480.417,
			483.835,
			486.126,
			489.581,
			491.897,
			494.222,
			495.388,
			497.726,
			498.899,
			501.25,
			504.794,
			505.98,
			509.547,
			511.936,
			514.334,
			515.537,
			517.949,
			521.586,
			524.024,
			525.246,
			527.696,
			530.156,
			533.862,
			536.344,
			540.083,
			541.333,
			542.586,
			546.356,
			548.879,
			552.68,
			555.224,
			559.055,
			561.619,
			562.904,
			565.48,
			568.064,
			569.359,
			571.955,
			574.557,
			578.466,
			579.77,
			581.075,
			583.691,
			586.313,
			587.628,
			591.581,
			594.226,
			596.877,
			600.866,
			603.534,
			607.549,
			608.891,
			610.235,
			614.277,
			616.98,
			621.046,
			623.766,
			627.857,
			630.593,
			631.964,
			634.71,
			637.463,
			638.842,
			641.606,
			644.377,
			648.545,
			651.332,
			655.525,
			656.926,
			658.329,
			662.547,
			665.367,
			668.194,
			672.447,
			675.29,
			679.568,
			680.997,
			682.428,
			688.169,
			689.608,
			693.934,
			696.826,
			701.176,
			704.083,
			705.539,
			708.455,
			712.842,
			715.774,
			718.712,
			723.131,
			726.084,
			730.526,
			732.009,
			733.494,
			737.958,
			740.942,
			745.428,
			748.425,
			751.423,
			752.918,
			755.895,
			757.374,
			760.306,
			763.2,
			764.633,
			766.056,
			770.267,
			773.026,
			775.747,
			777.094,
			779.758,
			782.384,
			783.683,
			786.252,
			787.522,
			790.035,
			792.51,
			796.152,
			798.536,
			802.047,
			803.2,
			804.344,
			807.718,
			809.92,
			813.153,
			815.261,
			817.333,
			818.354,
			820.369,
			821.363,
			823.323,
			825.246,
			826.194,
			828.062,
			829.894,
			832.579,
			833.458,
			834.327,
			836.04,
			837.716,
			838.54,
			840.16,
			840.958,
			842.526,
			844.057,
			846.287,
			847.728,
			849.823,
			850.504,
			851.189,
			853.161,
			854.44,
			856.309,
			857.523,
			858.723,
			859.319,
			860.51,
			861.105,
			862.293,
			864.073,
			865.26,
			866.45,
			868.23,
			868.822,
			869.414,
			871.196,
			871.809,
			872.42,
			874.249,
			875.466,
			876.722,
			878.674,
			880.623,
			881.949,
			882.628,
			883.306,
			885.343,
			886.023,
			886.711,
			888.812,
			890.212,
			891.616,
			892.323,
			893.733,
			895.843,
			897.915,
			899.276,
			900.641,
			902.655,
			903.967,
			905.944,
			906.605,
			907.257,
			909.184,
			910.464,
			912.435,
			913.776,
			915.125,
			917.165,
			917.905,
			918.659,
			921.697,
			922.462,
			924.901,
			926.531,
			928.162,
			928.969,
			930.58,
			932.193,
			932.998,
			935.396,
			936.991,
			938.575,
			940.915,
			942.474,
			944.723,
			945.463,
			946.204,
			948.43,
			949.904,
			952.119,
			953.599,
			955.824,
			957.298,
			958.042,
			959.537,
			961.044,
			961.803,
			962.566,
			964.878,
			967.227,
			968.813,
			970.414,
			972.031,
			972.845,
			974.485,
			975.31,
			976.968,
			978.631,
			981.115,
			982.763,
			985.236,
			986.051,
			986.864,
			989.294,
			990.906,
			993.314,
			994.918,
			996.512,
			997.308,
			998.896,
			999.686,
			1001.261,
			1003.599,
			1005.138,
			1006.66,
			1008.889,
			1010.357,
			1012.542,
			1013.261,
			1013.979,
			1016.123,
			1017.547,
			1019.671,
			1021.078,
			1022.474,
			1024.553,
			1025.243,
			1025.932,
			1027.982,
			1028.66,
			1029.336,
			1031.361,
			1032.728,
			1034.088,
			1034.765,
			1036.116,
			1036.795,
			1038.146,
			1040.163,
			1041.508,
			1042.855,
			1044.868,
			1046.209,
			1048.203,
			1048.866,
			1049.529,
			1051.519,
			1052.844,
			1054.833,
			1056.162,
			1058.225,
			1059.627,
			1060.328,
			1061.731,
			1063.157,
			1063.877,
			1064.595,
			1066.749,
			1068.195,
			1069.647,
			1070.372,
			1071.821,
			1073.274,
			1074.001,
			1075.449,
			1076.172,
			1077.617,
			1079.072,
			1081.243,
			1082.687,
			1084.935,
			1085.688,
			1086.44,
			1088.716,
			1090.28,
			1092.625,
			1094.208,
			1095.801,
			1096.598,
			1098.195,
			1098.978,
			1100.534,
			1102.091,
			1102.871,
			1104.405,
			1105.917,
			1107.43,
			1108.946,
			1109.693,
			1111.187,
			1112.685,
			1113.436,
			1114.929,
			1115.672,
			1117.162,
			1118.66,
			1120.877,
			1122.358,
			1124.589,
			1125.327,
			1126.068,
			1128.303,
			1129.809,
			1132.094,
			1133.634,
			1135.198,
			1135.991,
			1137.587,
			1139.198,
			1140.012,
			1141.658,
			1142.486,
			1144.152,
			1145.834,
			1148.36,
			1149.203,
			1150.05,
			1152.607,
			1153.466,
			1154.313,
			1156.044,
			1156.913,
			1158.66,
			1160.421,
			1163.087,
			1164.881,
			1167.598,
			1168.51,
			1169.426,
			1172.185,
			1174.035,
			1176.834,
			1178.716,
			1180.61,
			1181.562,
			1183.485,
			1185.42,
			1186.392,
			1188.354,
			1189.34,
			1191.321,
			1193.317,
			1196.336,
			1197.347,
			1198.359,
			1201.413,
			1202.437,
			1203.462,
			1206.554,
			1208.628,
			1211.76,
			1213.857,
			1215.952,
			1219.108,
			1220.165,
			1221.224,
			1225.484,
			1226.555,
			1229.783,
			1231.947,
			1235.211,
			1237.4,
			1239.6,
			1240.703,
			1242.916,
			1244.026,
			1246.254,
			1248.492,
			1251.867,
			1254.129,
			1257.541,
			1258.683,
			1259.827,
			1263.275,
			1265.586,
			1269.07,
			1271.405,
			1273.749,
			1274.924,
			1277.283,
			1278.466,
			1280.838,
			1283.221,
			1284.415,
			1286.812,
			1289.217,
			1292.843,
			1294.056,
			1295.272,
			1297.709,
			1300.156,
			1301.382,
			1305.064,
			1307.527,
			1311.223,
			1313.702,
			1316.195,
			1319.964,
			1321.231,
			1322.499,
			1326.327,
			1328.892,
			1332.742,
			1335.316,
			1337.894,
			1339.186,
			1341.776,
			1343.073,
			1345.674,
			1349.586,
			1352.195,
			1354.81,
			1358.745,
			1360.06,
			1361.377,
			1365.339,
			1366.662,
			1367.988,
			1371.974,
			1374.642,
			1377.315,
			1381.339,
			1384.03,
			1388.08,
			1389.434,
			1390.789,
			1396.228,
			1397.592,
			1401.695,
			1404.438,
			1408.567,
			1411.327,
			1414.095,
			1415.482,
			1419.652,
			1422.44,
			1425.236,
			1429.442,
			1432.254,
			1436.486,
			1437.9,
			1439.316,
			1443.573,
			1446.419,
			1450.702,
			1453.564,
			1456.434,
			1460.751,
			1462.197,
			1463.643,
			1467.346
		],
		[
			0,
			0.027,
			0.085,
			0.147,
			0.208,
			0.239,
			0.27,
			0.334,
			0.348,
			0.394,
			0.456,
			0.519,
			0.58,
			0.644,
			0.673,
			0.706,
			0.766,
			0.827,
			0.892,
			0.954,
			0.998,
			1.077,
			1.108,
			1.139,
			1.2,
			1.217,
			1.263,
			1.325,
			1.387,
			1.431,
			1.449,
			1.51,
			1.541,
			1.571,
			1.636,
			1.651,
			1.696,
			1.758,
			1.82,
			1.867,
			1.945,
			1.975,
			2.008,
			2.069,
			2.131,
			2.193,
			2.254,
			2.316,
			2.377,
			2.411,
			2.442,
			2.502,
			2.519,
			2.566,
			2.626,
			2.688,
			2.735,
			2.752,
			2.812,
			2.845,
			2.876,
			2.953,
			2.999,
			3.061,
			3.121,
			3.17,
			3.247,
			3.279,
			3.31,
			3.388,
			3.434,
			3.497,
			3.557,
			3.604,
			3.619,
			3.682,
			3.713,
			3.744,
			3.804,
			3.822,
			3.865,
			3.929,
			3.99,
			4.054,
			4.116,
			4.152,
			4.178,
			4.238,
			4.255,
			4.3,
			4.365,
			4.426,
			4.471,
			4.548,
			4.581,
			4.612,
			4.672,
			4.736,
			4.797,
			4.858,
			4.904,
			4.92,
			4.984,
			5.014,
			5.044,
			5.106,
			5.123,
			5.169,
			5.232,
			5.293,
			5.355,
			5.417,
			5.447,
			5.478,
			5.543,
			5.602,
			5.663,
			5.729,
			5.773,
			5.852,
			5.884,
			5.912,
			5.975,
			5.992,
			6.039,
			6.098,
			6.16,
			6.215,
			6.223,
			6.286,
			6.317,
			6.348,
			6.41,
			6.426,
			6.471,
			6.535,
			6.596,
			6.642,
			6.72,
			6.752,
			6.783,
			6.844,
			6.906,
			6.967,
			7.031,
			7.078,
			7.092,
			7.152,
			7.184,
			7.215,
			7.293,
			7.339,
			7.402,
			7.463,
			7.511,
			7.525,
			7.587,
			7.62,
			7.651,
			7.711,
			7.726,
			7.773,
			7.836,
			7.898,
			7.943,
			8.021,
			8.053,
			8.084,
			8.145,
			8.207,
			8.274,
			8.331,
			8.378,
			8.393,
			8.454,
			8.487,
			8.519,
			8.579,
			8.595,
			8.641,
			8.703,
			8.766,
			8.813,
			8.828,
			8.891,
			8.92,
			8.953,
			9.014,
			9.03,
			9.075,
			9.139,
			9.201,
			9.246,
			9.324,
			9.355,
			9.387,
			9.447,
			9.512,
			9.572,
			9.632,
			9.68,
			9.696,
			9.759,
			9.789,
			9.82,
			9.899,
			9.944,
			10.007,
			10.068,
			10.116,
			10.193,
			10.222,
			10.254,
			10.314,
			10.337,
			10.379,
			10.44,
			10.502,
			10.565,
			10.626,
			10.657,
			10.687,
			10.749,
			10.811,
			10.873,
			10.937,
			10.983,
			10.997,
			11.06,
			11.091,
			11.122,
			11.201,
			11.246,
			11.309,
			11.369,
			11.415,
			11.432,
			11.492,
			11.526,
			11.557,
			11.619,
			11.681,
			11.742,
			11.804,
			11.852,
			11.866,
			11.929,
			11.96,
			11.99,
			12.068,
			12.114,
			12.177,
			12.239,
			12.287,
			12.301,
			12.364,
			12.426,
			12.486,
			12.503,
			12.547,
			12.61,
			12.673,
			12.736,
			12.796,
			12.827,
			12.858,
			12.919,
			12.98,
			13.046,
			13.107,
			13.168,
			13.231,
			13.26,
			13.292,
			13.356,
			13.37,
			13.416,
			13.48,
			13.54,
			13.586,
			13.602,
			13.664,
			13.696,
			13.728,
			13.803,
			13.852,
			13.914,
			13.975,
			14.022,
			14.097,
			14.128,
			14.162,
			14.224,
			14.285,
			14.347,
			14.408,
			14.47,
			14.534,
			14.564,
			14.593,
			14.657,
			14.673,
			14.719,
			14.78,
			14.844,
			14.891,
			14.967,
			14.999,
			15.03,
			15.091,
			15.153,
			15.214,
			15.277,
			15.324,
			15.402,
			15.432,
			15.463,
			15.541,
			15.587,
			15.648,
			15.713,
			15.774,
			15.835,
			15.864,
			15.897,
			15.974,
			16.022,
			16.085,
			16.145,
			16.207,
			16.269,
			16.301,
			16.332,
			16.393,
			16.456,
			16.522,
			16.579,
			16.625,
			16.64,
			16.704,
			16.733,
			16.766,
			16.827,
			16.842,
			16.888,
			16.952,
			17.013,
			17.062,
			17.075,
			17.138,
			17.169,
			17.197,
			17.262,
			17.276,
			17.325,
			17.386,
			17.447,
			17.494,
			17.573,
			17.603,
			17.634,
			17.695,
			17.757,
			17.82,
			17.882,
			17.928,
			17.942,
			18.006,
			18.036,
			18.067,
			18.13,
			18.146,
			18.19,
			18.255,
			18.316,
			18.361,
			18.378,
			18.438,
			18.472,
			18.502,
			18.563,
			18.584,
			18.626,
			18.687,
			18.748,
			18.797,
			18.875,
			18.905,
			18.936,
			18.998,
			19.059,
			19.123,
			19.182,
			19.231,
			19.245,
			19.309,
			19.338,
			19.37,
			19.448,
			19.492,
			19.556,
			19.619,
			19.665,
			19.679,
			19.74,
			19.772,
			19.803,
			19.866,
			19.926,
			19.988,
			20.052,
			20.115,
			20.175,
			20.206,
			20.237,
			20.298,
			20.315,
			20.363,
			20.425,
			20.486,
			20.53,
			20.548,
			20.61,
			20.673,
			20.748,
			20.796,
			20.861,
			20.921,
			20.967,
			21.045,
			21.076,
			21.106,
			21.169,
			21.23,
			21.298,
			21.355,
			21.401,
			21.477,
			21.508,
			21.539,
			21.618,
			21.665,
			21.73,
			21.788,
			21.836,
			21.85,
			21.912,
			21.943,
			21.973,
			22.052,
			22.097,
			22.161,
			22.223,
			22.27,
			22.346,
			22.377,
			22.407,
			22.471,
			22.531,
			22.593,
			22.657,
			22.719,
			22.783,
			22.811,
			22.842,
			22.904,
			22.921,
			22.964,
			23.03,
			23.091,
			23.153,
			23.214,
			23.246,
			23.276,
			23.34,
			23.354,
			23.399,
			23.463,
			23.524,
			23.571,
			23.647,
			23.681,
			23.712,
			23.788,
			23.835,
			23.898,
			23.959,
			24.006,
			24.021,
			24.082,
			24.114,
			24.145,
			24.222,
			24.27,
			24.332,
			24.392,
			24.456,
			24.518,
			24.546,
			24.578,
			24.641,
			24.703,
			24.77,
			24.826,
			24.873,
			24.951,
			24.983,
			25.014,
			25.075,
			25.091,
			25.136,
			25.198,
			25.262,
			25.307,
			25.322,
			25.385,
			25.414,
			25.447,
			25.523,
			25.57,
			25.634,
			25.695,
			25.756,
			25.82,
			25.851,
			25.881,
			25.942,
			26.006,
			26.067,
			26.13,
			26.192,
			26.253,
			26.285,
			26.316,
			26.377,
			26.393,
			26.439,
			26.501,
			26.563,
			26.609,
			26.625,
			26.686,
			26.719,
			26.747,
			26.812,
			26.833,
			26.873,
			26.935,
			26.997,
			27.046,
			27.122,
			27.153,
			27.181,
			27.26,
			27.306,
			27.369,
			27.431,
			27.479,
			27.493,
			27.554,
			27.587,
			27.618,
			27.678,
			27.695,
			27.742,
			27.803,
			27.864,
			27.912,
			27.928,
			27.99,
			28.021,
			28.052,
			28.113,
			28.129,
			28.175,
			28.237,
			28.299,
			28.345,
			28.423,
			28.455,
			28.486,
			28.564,
			28.61,
			28.67,
			28.735,
			28.781,
			28.796,
			28.859,
			28.894,
			28.92,
			28.983,
			28.996,
			29.042,
			29.106,
			29.167,
			29.214,
			29.231,
			29.291,
			29.324,
			29.354,
			29.415,
			29.431,
			29.477,
			29.539,
			29.602,
			29.649,
			29.726,
			29.756,
			29.788,
			29.85,
			29.912,
			29.975,
			30.036,
			30.081,
			30.097,
			30.16,
			30.19,
			30.222,
			30.282,
			30.299,
			30.345,
			30.409,
			30.469,
			30.514,
			30.532,
			30.594,
			30.624,
			30.656,
			30.718,
			30.779,
			30.842,
			30.902,
			30.964,
			31.027,
			31.058,
			31.088,
			31.168,
			31.212,
			31.275,
			31.339,
			31.398,
			31.463,
			31.493,
			31.524,
			31.584,
			31.6,
			31.649,
			31.711,
			31.773,
			31.819,
			31.896,
			31.927,
			31.959,
			32.037,
			32.082,
			32.146,
			32.206,
			32.253,
			32.267,
			32.329,
			32.358,
			32.392,
			32.454,
			32.469,
			32.515,
			32.577,
			32.641,
			32.686,
			32.703,
			32.764,
			32.796,
			32.828,
			32.903,
			32.951,
			33.017,
			33.075,
			33.12,
			33.198,
			33.23,
			33.259,
			33.321,
			33.385,
			33.447,
			33.509,
			33.555,
			33.571,
			33.632,
			33.664,
			33.692,
			33.771,
			33.818,
			33.879,
			33.943,
			33.989,
			34.005,
			34.066,
			34.098,
			34.129,
			34.207,
			34.253,
			34.315,
			34.378,
			34.423,
			34.5,
			34.531,
			34.564,
			34.641,
			34.688,
			34.753,
			34.812,
			34.871,
			34.934,
			34.965,
			34.996,
			35.08,
			35.121,
			35.183,
			35.245,
			35.292,
			35.37,
			35.399,
			35.431,
			35.493,
			35.555,
			35.617,
			35.679,
			35.739,
			35.803,
			35.832,
			35.864,
			35.916
		]
	],
	'1855_1856_7982': [
		[
			86.188
		],
		[
			0
		],
		[
			0,
			1.34,
			2.689,
			4.04,
			8.106,
			10.824,
			14.913,
			17.649,
			21.764,
			24.514,
			25.893,
			28.653,
			31.422,
			32.809,
			35.588,
			38.374,
			42.565,
			43.965,
			45.366,
			49.58,
			50.988,
			52.397,
			55.221,
			56.635,
			59.469,
			62.307,
			66.574,
			69.422,
			73.702,
			75.13,
			76.56,
			80.853,
			83.719,
			88.024,
			90.898,
			95.216,
			98.101,
			100.99,
			102.437,
			105.334,
			106.784,
			109.689,
			112.599,
			116.974,
			118.436,
			119.898,
			124.295,
			125.764,
			127.233,
			131.65,
			134.602,
			139.042,
			142.008,
			144.982,
			149.453,
			150.946,
			153.937,
			156.933,
			158.433,
			159.935,
			164.448,
			167.465,
			170.487,
			172,
			175.031,
			178.069,
			179.59,
			182.639,
			184.165,
			187.225,
			190.291,
			194.906,
			197.995,
			202.672,
			204.228,
			205.787,
			210.48,
			213.626,
			218.35,
			221.538,
			226.352,
			229.583,
			231.203,
			234.48,
			237.734,
			239.362,
			240.99,
			245.821,
			249.035,
			252.239,
			253.834,
			257.021,
			260.199,
			261.786,
			264.958,
			266.545,
			269.723,
			272.908,
			277.697,
			280.901,
			285.721,
			287.331,
			288.944,
			293.781,
			296.996,
			301.787,
			304.973,
			308.099,
			309.647,
			312.693,
			314.215,
			317.232,
			321.69,
			324.614,
			327.502,
			331.761,
			333.162,
			334.554,
			338.673,
			340.028,
			341.375,
			344.042,
			345.363,
			347.976,
			350.552,
			354.348,
			356.832,
			360.49,
			361.69,
			362.882,
			366.405,
			368.712,
			372.102,
			374.315,
			377.57,
			379.695,
			380.745,
			382.818,
			384.854,
			385.86,
			387.845,
			389.794,
			392.65,
			394.51,
			397.239,
			398.133,
			399.018,
			401.623,
			403.315,
			405.784,
			407.385,
			408.951,
			409.721,
			411.235,
			411.978,
			412.725,
			414.872,
			415.57,
			416.26,
			418.274,
			419.578,
			420.857,
			421.48,
			422.701,
			423.899,
			424.49,
			426.242,
			427.378,
			428.502,
			430.174,
			431.285,
			432.971,
			433.533,
			434.095,
			435.786,
			436.937,
			438.696,
			439.873,
			441.662,
			442.91,
			443.536,
			444.792,
			446.055,
			446.702,
			447.378,
			449.417,
			451.472,
			452.184,
			452.9,
			454.334,
			455.767,
			456.483,
			457.889,
			458.588,
			459.978,
			461.358,
			463.346,
			464.644,
			466.566,
			467.204,
			467.841,
			469.734,
			470.987,
			472.867,
			474.117,
			475.359,
			475.978,
			477.212,
			477.824,
			479.039,
			480.845,
			482.04,
			483.229,
			484.99,
			485.573,
			486.155,
			487.898,
			488.476,
			489.051,
			490.198,
			490.77,
			491.912,
			493.051,
			494.746,
			495.871,
			497.564,
			498.13,
			498.695,
			500.387,
			501.516,
			503.235,
			504.396,
			506.143,
			507.315,
			508.534,
			509.15,
			511.006,
			512.255,
			513.541,
			515.499,
			516.818,
			518.839,
			519.524,
			520.211,
			522.294,
			523.722,
			525.178,
			527.388,
			528.886,
			531.166,
			531.934,
			532.708,
			535.047,
			535.834,
			536.624,
			539.023,
			540.642,
			542.279,
			543.103,
			544.709,
			546.329,
			547.145,
			548.755,
			549.534,
			551.1,
			552.677,
			554.985,
			556.52,
			558.842,
			559.625,
			560.41,
			562.775,
			564.375,
			566.801,
			568.426,
			570.067,
			570.888,
			572.534,
			573.358,
			575.015,
			577.498,
			578.334,
			580.864,
			582.552,
			584.248,
			585.104,
			586.827,
			588.562,
			589.439,
			592.1,
			593.89,
			595.704,
			598.452,
			600.301,
			603.102,
			604.043,
			604.987,
			607.844,
			609.764,
			612.666,
			614.616,
			617.575,
			619.535,
			620.528,
			622.522,
			624.529,
			625.533,
			627.543,
			629.567,
			632.605,
			634.643,
			636.683,
			638.733,
			639.762,
			641.827,
			642.863,
			644.944,
			647.037,
			648.085,
			650.178,
			652.277,
			655.446,
			656.507,
			657.571,
			660.778,
			662.928,
			666.171,
			668.343,
			670.525,
			671.62,
			673.815,
			676.02,
			677.126,
			680.458,
			682.689,
			684.925,
			688.283,
			690.516,
			693.84,
			694.938,
			696.03,
			699.261,
			701.373,
			703.448,
			706.492,
			708.478,
			709.457,
			711.39,
			712.344,
			713.292,
			716.098,
			717.011,
			717.922,
			720.61,
			722.372,
			724.118,
			724.983,
			726.697,
			728.397,
			729.24,
			730.919,
			731.755,
			733.417,
			735.074,
			737.557,
			739.203,
			741.675,
			742.5,
			743.318,
			745.772,
			747.409,
			749.884,
			751.533,
			753.196,
			754.041,
			755.729,
			756.572,
			757.416,
			760.027,
			760.9,
			761.772,
			764.415,
			766.185,
			768.831,
			770.58,
			772.326,
			773.198,
			774.949,
			775.822,
			777.563,
			779.314,
			781.941,
			783.693,
			786.34,
			787.221,
			788.102,
			790.82,
			792.633,
			795.361,
			797.178,
			798.987,
			799.875,
			801.651,
			803.428,
			804.297,
			806.017,
			806.877,
			808.596,
			810.291,
			812.823,
			813.667,
			814.505,
			817.004,
			817.838,
			818.673,
			820.336,
			821.167,
			822.836,
			824.515,
			827.048,
			828.75,
			831.321,
			832.183,
			833.049,
			835.665,
			837.425,
			840.086,
			841.872,
			843.671,
			844.575,
			846.391,
			848.219,
			849.138,
			850.997,
			851.933,
			853.815,
			855.721,
			858.601,
			859.572,
			860.549,
			863.499,
			864.488,
			865.479,
			868.466,
			870.47,
			873.484,
			875.508,
			877.541,
			880.611,
			881.642,
			882.675,
			886.837,
			887.886,
			891.036,
			893.138,
			895.248,
			896.308,
			898.435,
			900.572,
			901.644,
			903.801,
			904.883,
			907.056,
			909.239,
			912.532,
			914.739,
			918.065,
			919.176,
			920.291,
			923.646,
			925.893,
			929.282,
			931.553,
			933.835,
			934.979,
			937.276,
			938.428,
			939.583,
			944.227,
			945.395,
			948.912,
			951.269,
			953.635,
			954.821,
			957.194,
			959.559,
			960.735,
			963.066,
			964.219,
			966.497,
			968.738,
			969.844,
			972.027,
			974.173,
			977.323,
			978.373,
			979.397,
			982.422,
			984.405,
			987.347,
			989.296,
			991.237,
			992.208,
			994.157,
			995.126,
			997.069,
			999.018,
			999.987,
			1001.928,
			1003.872,
			1006.8,
			1007.777,
			1008.753,
			1010.712,
			1012.668,
			1013.641,
			1016.568,
			1018.503,
			1020.428,
			1023.309,
			1025.216,
			1028.054,
			1028.996,
			1029.936,
			1032.734,
			1034.59,
			1037.381,
			1039.236,
			1041.101,
			1042.042,
			1043.922,
			1044.861,
			1046.758,
			1048.654,
			1049.598,
			1051.491,
			1053.384,
			1056.205,
			1057.136,
			1058.067,
			1060.851,
			1061.777,
			1062.702,
			1065.475,
			1067.328,
			1070.112,
			1071.964,
			1073.816,
			1076.606,
			1077.538,
			1078.471,
			1081.285,
			1082.224,
			1083.166,
			1086.009,
			1087.905,
			1089.81,
			1090.768,
			1092.666,
			1093.614,
			1095.52,
			1097.428,
			1098.383,
			1100.302,
			1102.236,
			1105.167,
			1107.139,
			1110.137,
			1111.142,
			1112.152,
			1115.21,
			1117.263,
			1120.366,
			1122.449,
			1124.542,
			1125.59,
			1127.665,
			1128.714,
			1130.818,
			1133.991,
			1135.053,
			1138.256,
			1140.404,
			1142.561,
			1143.644,
			1145.816,
			1147.999,
			1149.094,
			1151.292,
			1152.394,
			1154.602,
			1156.818,
			1160.154,
			1162.388,
			1165.755,
			1166.883,
			1168.013,
			1171.425,
			1173.713,
			1177.167,
			1179.48,
			1181.804,
			1182.969,
			1185.305,
			1186.476,
			1188.825,
			1191.177,
			1192.356,
			1194.721,
			1197.092,
			1199.472,
			1201.86,
			1203.058,
			1206.667,
			1207.875,
			1209.085,
			1211.515,
			1212.734,
			1215.179,
			1218.869,
			1221.343,
			1223.826,
			1227.571,
			1228.824,
			1230.079,
			1233.863,
			1236.396,
			1240.213,
			1242.769,
			1246.616,
			1249.191,
			1250.481,
			1253.069,
			1255.664,
			1256.966,
			1259.575,
			1262.189,
			1266.11,
			1267.419,
			1268.73,
			1272.673,
			1273.991,
			1275.311,
			1279.278,
			1281.931,
			1285.921,
			1288.594,
			1291.275,
			1292.625,
			1295.331,
			1296.687,
			1298.05,
			1302.151,
			1303.519,
			1304.889,
			1309.006,
			1311.758,
			1314.515,
			1315.896,
			1318.662,
			1321.43,
			1322.817,
			1325.588,
			1326.976,
			1329.752,
			1332.532,
			1336.711,
			1339.503,
			1343.701,
			1345.103,
			1346.506,
			1350.727,
			1353.548,
			1357.792,
			1360.629,
			1363.472,
			1364.896,
			1367.75,
			1369.179,
			1372.044,
			1374.916,
			1376.356,
			1377.798,
			1382.132,
			1385.03,
			1387.934,
			1389.388,
			1392.301,
			1395.219,
			1396.681,
			1399.609,
			1401.073,
			1404,
			1406.919,
			1411.258,
			1414.114,
			1418.331,
			1419.716,
			1421.093,
			1425.163,
			1427.83,
			1431.761,
			1434.336,
			1436.873,
			1438.127,
			1440.606,
			1441.832,
			1444.254,
			1446.641,
			1447.821,
			1450.153,
			1452.45,
			1454.707,
			1456.926,
			1458.022,
			1460.185,
			1462.31,
			1463.358,
			1465.427,
			1466.448,
			1468.479,
			1470.456,
			1473.361,
			1475.256,
			1478.057,
			1478.975,
			1479.886,
			1482.606,
			1484.406,
			1487.089,
			1488.877,
			1490.671,
			1491.567,
			1493.36,
			1494.265,
			1496.092,
			1497.919,
			1498.833,
			1500.696,
			1502.56,
			1505.369,
			1506.31,
			1507.25,
			1510.074,
			1511.015,
			1511.956,
			1514.78,
			1516.671,
			1519.506,
			1521.436,
			1523.363,
			1526.278,
			1528.229,
			1531.172,
			1532.157,
			1533.143,
			1536.12,
			1538.121,
			1540.124,
			1541.141,
			1543.174,
			1545.208,
			1546.231,
			1548.275,
			1549.297,
			1551.345,
			1553.393,
			1556.446,
			1558.456,
			1561.453,
			1562.438,
			1563.421,
			1566.357,
			1568.273,
			1571.146,
			1573.044,
			1574.943,
			1575.893,
			1577.793,
			1578.744,
			1580.646,
			1582.553,
			1583.506,
			1585.416,
			1587.33,
			1589.247,
			1591.168,
			1592.131,
			1594.058,
			1595.991,
			1596.961,
			1599.874,
			1601.826,
			1603.785,
			1606.736,
			1608.713,
			1611.69,
			1612.688,
			1613.689,
			1616.705,
			1618.736,
			1621.811,
			1623.88,
			1625.966,
			1627.012,
			1629.114,
			1630.169,
			1632.269,
			1634.391,
			1635.453,
			1637.585,
			1639.721,
			1641.865,
			1644.018,
			1645.098,
			1647.264,
			1649.443,
			1650.537,
			1652.731,
			1653.832,
			1656.041,
			1658.259,
			1661.608,
			1663.852,
			1667.241,
			1668.374,
			1669.511,
			1672.937,
			1675.227,
			1678.673,
			1680.979,
			1683.291,
			1684.45,
			1686.774,
			1687.939,
			1690.276,
			1692.622,
			1693.798,
			1696.16,
			1698.53,
			1702.104,
			1703.3,
			1704.498,
			1708.108,
			1709.316,
			1710.527,
			1714.172,
			1716.614,
			1719.063,
			1722.75,
			1725.218,
			1728.928,
			1730.169,
			1731.412,
			1735.148,
			1737.649,
			1741.414,
			1743.934,
			1747.726,
			1750.264,
			1752.809,
			1754.084,
			1757.92,
			1760.487,
			1763.06,
			1766.932,
			1768.228,
			1769.524,
			1773.427,
			1774.733,
			1776.039,
			1779.958,
			1782.576,
			1786.513,
			1789.144,
			1791.779,
			1795.741,
			1797.064,
			1799.715,
			1802.371,
			1803.701,
			1806.366,
			1809.036,
			1811.714,
			1814.397,
			1815.741,
			1818.434,
			1821.133,
			1822.485,
			1825.194,
			1826.55,
			1829.268,
			1831.992,
			1836.09,
			1838.83,
			1842.951,
			1844.327,
			1845.704,
			1849.828,
			1852.563,
			1856.622,
			1859.289,
			1861.917,
			1863.217,
			1865.786,
			1867.057,
			1869.568,
			1872.04,
			1873.262,
			1875.677,
			1878.057,
			1880.401,
			1882.711,
			1883.852,
			1887.219,
			1888.323,
			1889.418,
			1892.668,
			1894.777,
			1896.852,
			1899.899,
			1901.888,
			1904.807,
			1905.764,
			1906.715,
			1909.536,
			1911.392,
			1914.161,
			1916.002,
			1917.843,
			1918.764,
			1920.622,
			1921.552,
			1923.417,
			1925.306,
			1926.256,
			1928.162,
			1930.094,
			1933.048,
			1934.038,
			1935.043,
			1938.092,
			1939.112,
			1940.144,
			1942.222,
			1943.262,
			1945.341,
			1947.414,
			1950.508,
			1952.539,
			1955.554,
			1956.532,
			1957.511,
			1960.424,
			1962.314,
			1965.161,
			1967.08,
			1969.004,
			1969.969,
			1971.964,
			1973.971,
			1974.977,
			1977.009,
			1978.028,
			1980.072,
			1982.117,
			1985.199,
			1987.262,
			1990.367,
			1991.405,
			1992.448,
			1995.573,
			1997.679,
			2000.875,
			2003.009,
			2005.153,
			2006.229,
			2008.388,
			2009.472,
			2010.558,
			2013.821,
			2014.909,
			2016,
			2019.268,
			2021.447,
			2023.631,
			2024.723,
			2026.912,
			2029.11,
			2030.214,
			2032.427,
			2033.537,
			2035.756,
			2037.98,
			2041.32,
			2043.549,
			2046.912,
			2048.036,
			2049.161,
			2052.553,
			2054.805,
			2058.235,
			2060.538,
			2062.884,
			2064.05,
			2066.393,
			2067.571,
			2069.913,
			2072.291,
			2073.483,
			2075.872,
			2078.274,
			2080.682,
			2083.096,
			2084.305,
			2086.727,
			2089.154,
			2090.37,
			2092.803,
			2094.021,
			2096.461,
			2098.906,
			2102.583,
			2105.045,
			2108.75,
			2109.991,
			2111.232,
			2114.968,
			2117.466,
			2121.226,
			2123.74,
			2127.523,
			2130.052,
			2131.319,
			2133.857,
			2136.4,
			2137.675,
			2140.227,
			2142.784,
			2146.63,
			2147.915,
			2149.202,
			2153.068,
			2154.36,
			2155.653,
			2159.54,
			2162.139,
			2164.743,
			2168.65,
			2171.256,
			2175.172,
			2176.481,
			2177.79,
			2181.725,
			2183.039,
			2184.354,
			2188.308,
			2190.95,
			2194.922,
			2197.577,
			2198.906,
			2201.567,
			2204.233,
			2205.567,
			2208.24,
			2210.917,
			2214.939,
			2217.625,
			2221.663,
			2223.011,
			2224.359,
			2228.418,
			2231.13,
			2235.209,
			2237.939,
			2240.675,
			2244.802,
			2246.181,
			2247.565,
			2251.725,
			2253.116,
			2254.508,
			2258.696,
			2261.497,
			2264.308,
			2265.715,
			2268.534,
			2271.357,
			2272.769,
			2275.595,
			2277.01,
			2279.837,
			2282.668,
			2286.914,
			2289.749,
			2294.006,
			2295.427,
			2296.848,
			2301.096,
			2303.902,
			2308.05,
			2310.767,
			2313.443,
			2314.767,
			2317.384,
			2318.677,
			2321.235,
			2323.752,
			2324.997,
			2327.455,
			2329.875,
			2333.432,
			2335.761,
			2338.057,
			2340.328,
			2341.457,
			2344.838,
			2347.094,
			2349.359,
			2352.795,
			2355.086,
			2358.539,
			2359.693,
			2360.85,
			2364.329,
			2366.632,
			2370.104,
			2372.4,
			2374.661,
			2375.78,
			2378.009,
			2379.1,
			2381.237,
			2383.359,
			2384.411,
			2386.489,
			2388.532,
			2391.536,
			2393.491,
			2396.349,
			2397.283,
			2398.208,
			2400.925,
			2402.693,
			2404.422,
			2406.938,
			2408.57,
			2410.943,
			2411.716,
			2412.479,
			2415.44,
			2416.157,
			2418.255,
			2419.614,
			2421.595,
			2422.886,
			2424.132,
			2424.742,
			2426.519,
			2427.659,
			2428.768,
			2430.373,
			2431.401,
			2432.902,
			2433.393,
			2433.88,
			2435.323,
			2436.271,
			2437.677,
			2438.61,
			2439.542,
			2440.986,
			2441.474,
			2441.961,
			2443.422,
			2443.91,
			2444.398,
			2445.925,
			2447.001,
			2448.078,
			2448.618,
			2449.705,
			2450.864,
			2451.523,
			2452.846,
			2453.511,
			2454.852,
			2456.279,
			2458.577,
			2460.122,
			2462.354,
			2463.085,
			2463.818,
			2465.999,
			2467.28,
			2469.214,
			2470.512,
			2471.708,
			2472.288,
			2473.451,
			2474.033,
			2475.2,
			2476.362,
			2476.937,
			2478.09,
			2479.238,
			2480.405,
			2481.584,
			2482.191,
			2483.4,
			2484.607,
			2485.21,
			2486.417,
			2487.075,
			2488.397,
			2489.726,
			2491.749,
			2493.168,
			2495.333,
			2496.065,
			2496.802,
			2499.053,
			2500.583,
			2502.918,
			2504.493,
			2506.895,
			2508.438,
			2509.21,
			2510.767,
			2512.34,
			2513.086,
			2514.586,
			2516.1,
			2518.379,
			2519.135,
			2519.894,
			2522.195,
			2522.967,
			2523.735,
			2526.062,
			2527.633,
			2529.224,
			2531.639,
			2533.273,
			2535.762,
			2536.593,
			2537.424,
			2539.942,
			2540.79,
			2541.642,
			2544.223,
			2545.964,
			2547.721,
			2548.606,
			2550.391,
			2551.288,
			2553.095,
			2554.919,
			2555.837,
			2557.682,
			2559.542,
			2562.35,
			2564.241,
			2567.106,
			2568.069,
			2569.036,
			2571.962,
			2573.915,
			2576.902,
			2578.911,
			2580.938,
			2581.958,
			2584.008,
			2585.037,
			2586.071,
			2590.233,
			2591.277,
			2594.419,
			2596.531,
			2598.656,
			2599.724,
			2601.868,
			2604.04,
			2605.121,
			2608.369,
			2610.537,
			2612.701,
			2615.93,
			2618.062,
			2621.217,
			2622.256,
			2623.289,
			2626.355,
			2628.38,
			2631.393,
			2633.393,
			2636.377,
			2638.357,
			2639.344,
			2641.311,
			2643.276,
			2644.257,
			2646.215,
			2648.173,
			2650.138,
			2653.085,
			2655.066,
			2657.047,
			2658.038,
			2661.022,
			2663.012,
			2665.005,
			2667.999,
			2670.005,
			2673.025,
			2674.039,
			2675.056,
			2678.118,
			2680.181,
			2683.291,
			2685.382,
			2687.481,
			2688.534,
			2690.658,
			2691.723,
			2693.861,
			2696.014,
			2697.093,
			2699.259,
			2701.433,
			2704.702,
			2706.88,
			2710.146,
			2711.236,
			2712.325,
			2714.504,
			2715.603,
			2717.801,
			2719.999,
			2723.311,
			2725.518,
			2728.835,
			2729.941,
			2731.045,
			2734.356,
			2736.566,
			2739.886,
			2742.105,
			2744.329,
			2745.442,
			2747.664,
			2749.88,
			2750.989,
			2753.2,
			2754.302,
			2756.506,
			2758.702,
			2761.991,
			2764.181,
			2767.467,
			2768.562,
			2769.656,
			2772.942,
			2775.132,
			2777.322,
			2780.612,
			2782.807,
			2786.115,
			2787.222,
			2788.33,
			2791.669,
			2792.787,
			2793.905,
			2797.286,
			2799.551,
			2801.826,
			2802.967,
			2805.254,
			2807.545,
			2808.694,
			2810.999,
			2812.151,
			2814.465,
			2816.789,
			2820.287,
			2822.633,
			2826.173,
			2827.359,
			2828.55,
			2832.144,
			2834.56,
			2838.189,
			2840.639,
			2844.334,
			2846.81,
			2848.052,
			2850.545,
			2854.303,
			2856.819,
			2859.344,
			2861.866,
			2864.397,
			2865.663,
			2868.2,
			2870.746,
			2872.021,
			2874.576,
			2875.854,
			2878.406,
			2880.947,
			2884.719,
			2887.198,
			2890.851,
			2892.052,
			2893.245,
			2896.768,
			2899.079,
			2902.475,
			2904.696,
			2907.96,
			2910.093,
			2911.146,
			2913.246,
			2916.308,
			2918.308,
			2920.278,
			2923.175,
			2924.13,
			2925.079,
			2927.904,
			2928.839,
			2929.771,
			2932.557,
			2934.402,
			2936.237,
			2938.982,
			2940.79,
			2943.502,
			2944.412,
			2945.321,
			2948.048,
			2948.958,
			2949.877,
			2952.631,
			2954.469,
			2956.316,
			2957.239,
			2959.082,
			2960.016,
			2961.886,
			2963.754,
			2964.692,
			2966.585,
			2968.476,
			2971.326,
			2973.232,
			2976.116,
			2977.091,
			2978.066,
			2981.025,
			2983.053,
			2985.086,
			2988.195,
			2990.277,
			2991.295,
			2993.311,
			2994.32,
			2995.332,
			2998.297,
			2999.281,
			3000.268,
			3003.225,
			3005.202,
			3007.189,
			3008.186,
			3010.185,
			3012.189,
			3013.191,
			3015.201,
			3016.21,
			3018.215,
			3020.22,
			3023.23,
			3025.234,
			3028.248,
			3029.252,
			3030.257,
			3033.279,
			3035.301,
			3038.345,
			3040.378,
			3042.42,
			3043.448,
			3045.511,
			3046.546,
			3048.686,
			3051.962,
			3053.046,
			3056.369,
			3058.567,
			3060.762,
			3061.863,
			3064.071,
			3066.29,
			3067.403,
			3069.636,
			3070.757,
			3073.007,
			3075.269,
			3078.682,
			3080.972,
			3084.426,
			3085.583,
			3086.743,
			3090.23,
			3092.567,
			3096.087,
			3098.447,
			3100.819,
			3102.008,
			3104.394,
			3105.59,
			3107.99,
			3110.394,
			3111.599,
			3114.013,
			3116.43,
			3120.07,
			3121.288,
			3122.507,
			3126.183,
			3127.412,
			3128.645,
			3132.357,
			3134.843,
			3137.337,
			3141.095,
			3143.612,
			3147.402,
			3148.669,
			3149.938,
			3153.759,
			3156.315,
			3160.151,
			3162.691,
			3165.205,
			3166.449,
			3168.908,
			3170.124,
			3172.526,
			3174.89,
			3176.058,
			3178.365,
			3180.634,
			3183.967,
			3186.142,
			3189.337,
			3190.384,
			3191.424,
			3194.487,
			3196.484,
			3199.412,
			3201.315,
			3203.18,
			3204.099,
			3205.911,
			3206.803,
			3207.687,
			3210.287,
			3211.135,
			3211.974,
			3214.435,
			3216.03,
			3217.588,
			3218.354,
			3219.857,
			3221.325,
			3222.045,
			3224.152,
			3225.514,
			3226.845,
			3228.777,
			3230.034,
			3231.841,
			3232.427,
			3233.006,
			3234.704,
			3235.812,
			3237.44,
			3238.509,
			3240.097,
			3241.14,
			3241.658,
			3242.692,
			3243.728,
			3244.246,
			3245.287,
			3246.306,
			3247.324,
			3248.345,
			3248.856,
			3249.884,
			3250.929,
			3251.459,
			3252.519,
			3253.049,
			3254.111,
			3255.175,
			3256.885,
			3258.042,
			3259.776,
			3260.354,
			3260.984,
			3262.972,
			3264.291,
			3266.326,
			3267.758,
			3269.184,
			3269.896,
			3271.318,
			3272.025,
			3273.431,
			3274.833,
			3275.535,
			3276.883,
			3278.214,
			3279.544,
			3281.466,
			3283.231,
			3283.82,
			3284.41,
			3285.595,
			3286.177,
			3287.295,
			3288.416,
			3290.108,
			3291.244,
			3292.947,
			3293.517,
			3294.089,
			3295.82,
			3296.997,
			3298.786,
			3299.991,
			3301.209,
			3301.828,
			3303.077,
			3304.337,
			3304.973,
			3306.256,
			3306.905,
			3308.216,
			3309.542,
			3311.564,
			3312.954,
			3315.066,
			3315.777,
			3316.492,
			3318.701,
			3320.185,
			3321.698,
			3323.994,
			3325.544,
			3326.326,
			3327.878,
			3328.657,
			3329.439,
			3331.81,
			3332.6,
			3333.395,
			3335.8,
			3337.423,
			3339.904,
			3341.566,
			3343.23,
			3344.071,
			3345.766,
			3346.593,
			3348.285,
			3349.992,
			3352.575,
			3354.314,
			3356.948,
			3357.833,
			3358.72,
			3361.405,
			3363.211,
			3365.948,
			3367.788,
			3369.645,
			3370.578,
			3372.454,
			3373.397,
			3375.292,
			3377.201,
			3378.16,
			3380.086,
			3382.024,
			3383.975,
			3386.923,
			3388.904,
			3390.896,
			3391.897,
			3393.907,
			3394.916,
			3396.944,
			3398.982,
			3402.065,
			3404.134,
			3407.264,
			3408.312,
			3409.363,
			3412.516,
			3414.624,
			3417.802,
			3419.934,
			3422.075,
			3423.149,
			3425.305,
			3426.385,
			3428.554,
			3430.732,
			3431.825,
			3434.019,
			3436.222,
			3439.546,
			3440.66,
			3441.777,
			3444.017,
			3446.268,
			3447.396,
			3450.798,
			3453.08,
			3455.371,
			3458.823,
			3461.137,
			3464.627,
			3465.795,
			3466.964,
			3470.488,
			3472.849,
			3476.409,
			3478.793,
			3481.187,
			3482.388,
			3484.797,
			3486.005,
			3488.427,
			3490.859,
			3492.079,
			3494.524,
			3496.979,
			3500.678,
			3501.915,
			3503.155,
			3506.887,
			3508.136,
			3509.387,
			3513.152,
			3515.673,
			3519.47,
			3522.011,
			3524.559,
			3528.396,
			3529.677,
			3530.961,
			3534.823,
			3536.113,
			3537.405,
			3541.29,
			3543.888,
			3546.491,
			3547.793,
			3550.397,
			3553.006,
			3554.313,
			3556.933,
			3558.245,
			3560.874,
			3563.51,
			3567.478,
			3570.131,
			3574.123,
			3575.458,
			3576.794,
			3580.814,
			3583.502,
			3585.537
		],
		[
			0,
			0.017,
			0.051,
			0.079,
			0.142,
			0.205,
			0.267,
			0.327,
			0.39,
			0.452,
			0.485,
			0.513,
			0.575,
			0.593,
			0.639,
			0.701,
			0.762,
			0.809,
			0.823,
			0.886,
			0.918,
			0.947,
			1.011,
			1.03,
			1.073,
			1.135,
			1.196,
			1.258,
			1.319,
			1.351,
			1.381,
			1.459,
			1.506,
			1.57,
			1.631,
			1.691,
			1.755,
			1.786,
			1.816,
			1.88,
			1.895,
			1.942,
			2.004,
			2.066,
			2.11,
			2.126,
			2.188,
			2.221,
			2.25,
			2.312,
			2.376,
			2.436,
			2.497,
			2.545,
			2.624,
			2.653,
			2.686,
			2.746,
			2.763,
			2.808,
			2.871,
			2.933,
			2.979,
			2.995,
			3.057,
			3.093,
			3.118,
			3.18,
			3.197,
			3.242,
			3.305,
			3.367,
			3.43,
			3.491,
			3.523,
			3.553,
			3.614,
			3.676,
			3.737,
			3.801,
			3.862,
			3.925,
			3.955,
			3.988,
			4.049,
			4.063,
			4.11,
			4.174,
			4.235,
			4.281,
			4.298,
			4.36,
			4.39,
			4.422,
			4.484,
			4.498,
			4.545,
			4.606,
			4.67,
			4.716,
			4.792,
			4.825,
			4.856,
			4.917,
			4.978,
			5.041,
			5.102,
			5.152,
			5.164,
			5.228,
			5.257,
			5.288,
			5.367,
			5.412,
			5.475,
			5.537,
			5.584,
			5.598,
			5.662,
			5.693,
			5.723,
			5.784,
			5.801,
			5.846,
			5.908,
			5.97,
			6.034,
			6.095,
			6.126,
			6.157,
			6.221,
			6.283,
			6.343,
			6.406,
			6.468,
			6.529,
			6.561,
			6.593,
			6.654,
			6.668,
			6.716,
			6.778,
			6.84,
			6.887,
			6.965,
			6.994,
			7.025,
			7.104,
			7.148,
			7.217,
			7.275,
			7.319,
			7.336,
			7.398,
			7.428,
			7.461,
			7.522,
			7.537,
			7.585,
			7.646,
			7.709,
			7.754,
			7.77,
			7.831,
			7.862,
			7.894,
			7.971,
			8.018,
			8.081,
			8.141,
			8.188,
			8.265,
			8.297,
			8.327,
			8.39,
			8.451,
			8.514,
			8.575,
			8.637,
			8.701,
			8.729,
			8.762,
			8.823,
			8.838,
			8.886,
			8.947,
			9.011,
			9.056,
			9.071,
			9.134,
			9.165,
			9.197,
			9.259,
			9.279,
			9.321,
			9.383,
			9.443,
			9.505,
			9.569,
			9.597,
			9.631,
			9.692,
			9.754,
			9.816,
			9.877,
			9.923,
			9.94,
			10.002,
			10.033,
			10.064,
			10.141,
			10.189,
			10.251,
			10.312,
			10.358,
			10.374,
			10.436,
			10.465,
			10.497,
			10.559,
			10.576,
			10.622,
			10.685,
			10.745,
			10.794,
			10.869,
			10.901,
			10.933,
			10.993,
			11.055,
			11.118,
			11.181,
			11.243,
			11.304,
			11.34,
			11.367,
			11.444,
			11.49,
			11.553,
			11.613,
			11.675,
			11.739,
			11.77,
			11.798,
			11.861,
			11.924,
			11.986,
			12.048,
			12.094,
			12.172,
			12.204,
			12.234,
			12.298,
			12.313,
			12.359,
			12.421,
			12.481,
			12.529,
			12.543,
			12.606,
			12.638,
			12.668,
			12.731,
			12.745,
			12.792,
			12.855,
			12.917,
			12.979,
			13.039,
			13.071,
			13.103,
			13.163,
			13.228,
			13.288,
			13.351,
			13.403,
			13.412,
			13.474,
			13.505,
			13.537,
			13.615,
			13.659,
			13.724,
			13.784,
			13.832,
			13.845,
			13.908,
			13.941,
			13.97,
			14.047,
			14.093,
			14.155,
			14.218,
			14.279,
			14.344,
			14.374,
			14.404,
			14.468,
			14.53,
			14.591,
			14.652,
			14.716,
			14.776,
			14.807,
			14.838,
			14.902,
			14.917,
			14.964,
			15.024,
			15.087,
			15.149,
			15.21,
			15.242,
			15.274,
			15.335,
			15.35,
			15.398,
			15.46,
			15.463,
			15.522,
			15.567,
			15.645,
			15.676,
			15.708,
			15.784,
			15.831,
			15.894,
			15.954,
			16.003,
			16.017,
			16.078,
			16.111,
			16.142,
			16.219,
			16.266,
			16.327,
			16.389,
			16.437,
			16.515,
			16.544,
			16.576,
			16.653,
			16.7,
			16.76,
			16.822,
			16.869,
			16.886,
			16.945,
			16.98,
			17.01,
			17.072,
			17.087,
			17.133,
			17.195,
			17.257,
			17.304,
			17.318,
			17.383,
			17.414,
			17.442,
			17.505,
			17.523,
			17.566,
			17.629,
			17.692,
			17.738,
			17.815,
			17.846,
			17.877,
			17.941,
			18.002,
			18.063,
			18.125,
			18.173,
			18.189,
			18.248,
			18.279,
			18.311,
			18.374,
			18.388,
			18.434,
			18.499,
			18.56,
			18.622,
			18.685,
			18.713,
			18.744,
			18.809,
			18.824,
			18.869,
			18.931,
			18.992,
			19.041,
			19.118,
			19.147,
			19.181,
			19.258,
			19.304,
			19.364,
			19.429,
			19.473,
			19.49,
			19.552,
			19.587,
			19.613,
			19.676,
			19.69,
			19.738,
			19.8,
			19.861,
			19.909,
			19.922,
			19.987,
			20.018,
			20.046,
			20.109,
			20.124,
			20.17,
			20.235,
			20.295,
			20.343,
			20.419,
			20.451,
			20.481,
			20.543,
			20.605,
			20.67,
			20.73,
			20.777,
			20.791,
			20.855,
			20.886,
			20.916,
			20.979,
			20.993,
			21.041,
			21.103,
			21.165,
			21.21,
			21.226,
			21.289,
			21.32,
			21.351,
			21.413,
			21.474,
			21.536,
			21.597,
			21.659,
			21.723,
			21.753,
			21.784,
			21.861,
			21.909,
			21.972,
			22.031,
			22.079,
			22.095,
			22.157,
			22.188,
			22.219,
			22.28,
			22.296,
			22.341,
			22.403,
			22.466,
			22.512,
			22.591,
			22.62,
			22.652,
			22.729,
			22.776,
			22.839,
			22.901,
			22.947,
			22.962,
			23.023,
			23.055,
			23.086,
			23.162,
			23.209,
			23.271,
			23.335,
			23.38,
			23.397,
			23.459,
			23.489,
			23.52,
			23.583,
			23.597,
			23.645,
			23.705,
			23.711,
			23.77,
			23.83,
			23.894,
			23.924,
			23.956,
			24.016,
			24.078,
			24.141,
			24.203,
			24.248,
			24.263,
			24.328,
			24.357,
			24.389,
			24.452,
			24.466,
			24.512,
			24.573,
			24.636,
			24.685,
			24.698,
			24.759,
			24.793,
			24.824,
			24.886,
			24.946,
			25.01,
			25.071,
			25.131,
			25.193,
			25.226,
			25.257,
			25.317,
			25.381,
			25.442,
			25.505,
			25.551,
			25.565,
			25.627,
			25.66,
			25.691,
			25.754,
			25.773,
			25.815,
			25.875,
			25.939,
			25.985,
			26.001,
			26.064,
			26.094,
			26.124,
			26.203,
			26.249,
			26.311,
			26.374,
			26.419,
			26.497,
			26.528,
			26.56,
			26.621,
			26.638,
			26.682,
			26.751,
			26.806,
			26.852,
			26.868,
			26.932,
			26.961,
			26.993,
			27.055,
			27.071,
			27.117,
			27.179,
			27.241,
			27.288,
			27.363,
			27.395,
			27.426,
			27.488,
			27.551,
			27.612,
			27.676,
			27.721,
			27.737,
			27.797,
			27.836,
			27.862,
			27.938,
			27.985,
			28.045,
			28.11,
			28.154,
			28.17,
			28.232,
			28.263,
			28.295,
			28.356,
			28.373,
			28.42,
			28.481,
			28.543,
			28.589,
			28.666,
			28.696,
			28.729,
			28.791,
			28.855,
			28.916,
			28.977,
			29.024,
			29.04,
			29.101,
			29.134,
			29.164,
			29.226,
			29.24,
			29.289,
			29.351,
			29.411,
			29.458,
			29.473,
			29.536,
			29.567,
			29.597,
			29.658,
			29.676,
			29.721,
			29.788,
			29.846,
			29.908,
			29.969,
			30.001,
			30.033,
			30.108,
			30.156,
			30.217,
			30.279,
			30.342,
			30.403,
			30.434,
			30.467,
			30.528,
			30.543,
			30.589,
			30.653,
			30.715,
			30.761,
			30.776,
			30.838,
			30.868,
			30.901,
			30.962,
			31.024,
			31.086,
			31.146,
			31.193,
			31.211,
			31.272,
			31.304,
			31.335,
			31.397,
			31.411,
			31.457,
			31.521,
			31.58,
			31.627,
			31.645,
			31.705,
			31.737,
			31.767,
			31.83,
			31.845,
			31.891,
			31.954,
			32.017,
			32.063,
			32.139,
			32.171,
			32.201,
			32.28,
			32.326,
			32.387,
			32.451,
			32.497,
			32.513,
			32.573,
			32.604,
			32.637,
			32.696,
			32.713,
			32.759,
			32.821,
			32.884,
			32.93,
			32.947,
			33.008,
			33.039,
			33.069,
			33.132,
			33.146,
			33.194,
			33.258,
			33.318,
			33.363,
			33.441,
			33.472,
			33.504,
			33.567,
			33.629,
			33.691,
			33.752,
			33.798,
			33.813,
			33.875,
			33.908,
			33.939,
			34.001,
			34.023,
			34.062,
			34.123,
			34.185,
			34.234,
			34.248,
			34.31,
			34.342,
			34.373,
			34.433,
			34.451,
			34.497,
			34.559,
			34.622,
			34.667,
			34.744,
			34.775,
			34.807,
			34.868,
			34.93,
			34.993,
			35.055,
			35.101,
			35.117,
			35.178,
			35.21,
			35.24,
			35.303,
			35.319,
			35.363,
			35.426,
			35.488,
			35.535,
			35.552,
			35.613,
			35.643,
			35.674,
			35.752,
			35.798,
			35.861,
			35.924,
			35.97,
			36.047,
			36.109,
			36.171,
			36.186,
			36.232,
			36.294,
			36.358,
			36.402,
			36.419,
			36.479,
			36.512,
			36.543,
			36.604,
			36.619,
			36.667,
			36.73,
			36.792,
			36.837,
			36.916,
			36.945,
			36.977,
			37.055,
			37.1,
			37.162,
			37.226,
			37.272,
			37.287,
			37.35,
			37.379,
			37.411,
			37.473,
			37.488,
			37.536,
			37.596,
			37.658,
			37.705,
			37.722,
			37.781,
			37.815,
			37.845,
			37.922,
			37.967,
			38.031,
			38.092,
			38.145,
			38.217,
			38.246,
			38.279,
			38.342,
			38.404,
			38.464,
			38.527,
			38.572,
			38.59,
			38.651,
			38.681,
			38.713,
			38.776,
			38.79,
			38.837,
			38.9,
			38.96,
			39.009,
			39.022,
			39.084,
			39.116,
			39.148,
			39.208,
			39.225,
			39.27,
			39.334,
			39.394,
			39.442,
			39.518,
			39.552,
			39.58,
			39.658,
			39.704,
			39.77,
			39.828,
			39.874,
			39.891,
			39.953,
			39.983,
			40.015,
			40.077,
			40.091,
			40.139,
			40.205,
			40.263,
			40.31,
			40.324,
			40.388,
			40.42,
			40.448,
			40.513,
			40.574,
			40.635,
			40.696,
			40.76,
			40.821,
			40.852,
			40.885,
			40.947,
			41.008,
			41.069,
			41.13,
			41.193,
			41.254,
			41.286,
			41.318,
			41.396,
			41.44,
			41.503,
			41.567,
			41.611,
			41.628,
			41.689,
			41.721,
			41.752,
			41.813,
			41.875,
			41.937,
			41.999,
			42.046,
			42.124,
			42.155,
			42.186,
			42.248,
			42.268,
			42.311,
			42.371,
			42.435,
			42.479,
			42.496,
			42.558,
			42.588,
			42.62,
			42.683,
			42.696,
			42.743,
			42.806,
			42.867,
			42.913,
			42.992,
			43.021,
			43.052,
			43.117,
			43.176,
			43.241,
			43.303,
			43.35,
			43.364,
			43.425,
			43.456,
			43.488,
			43.551,
			43.565,
			43.613,
			43.674,
			43.735,
			43.781,
			43.799,
			43.861,
			43.891,
			43.923,
			44,
			44.045,
			44.108,
			44.169,
			44.218,
			44.295,
			44.328,
			44.355,
			44.434,
			44.479,
			44.543,
			44.603,
			44.651,
			44.667,
			44.727,
			44.758,
			44.79,
			44.853,
			44.869,
			44.914,
			44.975,
			45.038,
			45.085,
			45.099,
			45.161,
			45.193,
			45.223,
			45.285,
			45.302,
			45.346,
			45.409,
			45.471,
			45.534,
			45.597,
			45.627,
			45.659,
			45.721,
			45.784,
			45.846,
			45.906,
			45.953,
			45.969,
			46.029,
			46.061,
			46.093,
			46.154,
			46.171,
			46.217,
			46.279,
			46.341,
			46.39,
			46.465,
			46.496,
			46.526,
			46.587,
			46.651,
			46.713,
			46.776,
			46.822,
			46.836,
			46.899,
			46.931,
			46.959,
			47.023,
			47.039,
			47.084,
			47.148,
			47.208,
			47.254,
			47.269,
			47.331,
			47.363,
			47.396,
			47.457,
			47.473,
			47.519,
			47.581,
			47.641,
			47.688,
			47.767,
			47.796,
			47.827,
			47.891,
			47.954,
			48.016,
			48.076,
			48.123,
			48.138,
			48.201,
			48.231,
			48.263,
			48.324,
			48.341,
			48.388,
			48.45,
			48.51,
			48.556,
			48.573,
			48.636,
			48.665,
			48.697,
			48.759,
			48.773,
			48.821,
			48.884,
			48.945,
			48.991,
			49.069,
			49.101,
			49.132,
			49.209,
			49.256,
			49.318,
			49.378,
			49.44,
			49.503,
			49.535,
			49.566,
			49.628,
			49.643,
			49.689,
			49.751,
			49.813,
			49.86,
			49.875,
			49.937,
			49.968,
			49.998,
			50.076,
			50.123,
			50.186,
			50.247,
			50.293,
			50.371,
			50.401,
			50.434,
			50.494,
			50.514,
			50.556,
			50.618,
			50.681,
			50.743,
			50.804,
			50.835,
			50.865,
			50.928,
			50.946,
			50.991,
			51.052,
			51.115,
			51.161,
			51.239,
			51.271,
			51.301,
			51.362,
			51.425,
			51.488,
			51.55,
			51.595,
			51.674,
			51.704,
			51.734,
			51.796,
			51.813,
			51.859,
			51.923,
			51.985,
			52.031,
			52.046,
			52.107,
			52.139,
			52.171,
			52.233,
			52.246,
			52.295,
			52.356,
			52.417,
			52.479,
			52.542,
			52.575,
			52.604,
			52.667,
			52.728,
			52.789,
			52.853,
			52.897,
			52.913,
			52.975,
			53.008,
			53.038,
			53.1,
			53.114,
			53.162,
			53.223,
			53.287,
			53.347,
			53.41,
			53.441,
			53.47,
			53.55,
			53.597,
			53.659,
			53.721,
			53.783,
			53.844,
			53.873,
			53.905,
			53.984,
			54.029,
			54.093,
			54.152,
			54.201,
			54.215,
			54.277,
			54.31,
			54.34,
			54.402,
			54.419,
			54.462,
			54.525,
			54.588,
			54.65,
			54.713,
			54.741,
			54.774,
			54.852,
			54.896,
			54.959,
			55.021,
			55.068,
			55.147,
			55.177,
			55.207,
			55.286,
			55.331,
			55.395,
			55.457,
			55.519,
			55.579,
			55.611,
			55.643,
			55.72,
			55.767,
			55.828,
			55.891,
			55.938,
			56.015,
			56.044,
			56.077,
			56.154,
			56.199,
			56.263,
			56.323,
			56.385,
			56.449,
			56.479,
			56.51,
			56.572,
			56.587,
			56.634,
			56.702,
			56.757,
			56.804,
			56.819,
			56.883,
			56.913,
			56.944,
			57.007,
			57.021,
			57.069,
			57.129,
			57.192,
			57.238,
			57.314,
			57.347,
			57.38,
			57.44,
			57.503,
			57.563,
			57.627,
			57.674,
			57.689,
			57.751,
			57.78,
			57.813,
			57.874,
			57.89,
			57.938,
			58,
			58.06,
			58.107,
			58.123,
			58.186,
			58.214,
			58.248,
			58.311,
			58.325,
			58.372,
			58.432,
			58.493,
			58.541,
			58.62,
			58.65,
			58.681,
			58.743,
			58.806,
			58.867,
			58.928,
			58.99,
			59.054,
			59.084,
			59.115,
			59.177,
			59.192,
			59.237,
			59.3,
			59.363,
			59.41,
			59.424,
			59.487,
			59.519,
			59.547,
			59.626,
			59.672,
			59.735,
			59.797,
			59.86,
			59.921,
			59.952,
			59.983,
			60.044,
			60.061,
			60.108,
			60.17,
			60.229,
			60.279,
			60.293,
			60.355,
			60.385,
			60.418,
			60.48,
			60.494,
			60.54,
			60.604,
			60.666,
			60.711,
			60.788,
			60.825,
			60.851,
			60.915,
			60.975,
			61.036,
			61.1,
			61.145,
			61.162,
			61.223,
			61.253,
			61.285,
			61.364,
			61.408,
			61.472,
			61.534,
			61.58,
			61.594,
			61.656,
			61.688,
			61.719,
			61.798,
			61.842,
			61.905,
			61.968,
			62.013,
			62.091,
			62.122,
			62.153,
			62.215,
			62.277,
			62.34,
			62.402,
			62.465,
			62.526,
			62.555,
			62.587,
			62.647,
			62.665,
			62.711,
			62.775,
			62.835,
			62.897,
			62.96,
			62.99,
			63.022,
			63.097,
			63.146,
			63.207,
			63.27,
			63.315,
			63.393,
			63.426,
			63.457,
			63.518,
			63.578,
			63.641,
			63.705,
			63.751,
			63.767,
			63.829,
			63.858,
			63.888,
			63.952,
			63.968,
			64.013,
			64.076,
			64.137,
			64.201,
			64.262,
			64.293,
			64.325,
			64.385,
			64.402,
			64.446,
			64.51,
			64.571,
			64.617,
			64.695,
			64.727,
			64.758,
			64.834,
			64.883,
			64.949,
			65.006,
			65.052,
			65.068,
			65.128,
			65.162,
			65.191,
			65.254,
			65.269,
			65.316,
			65.38,
			65.439,
			65.487,
			65.562,
			65.593,
			65.626,
			65.688,
			65.751,
			65.81,
			65.872,
			65.921,
			65.997,
			66.028,
			66.06,
			66.122,
			66.137,
			66.185,
			66.247,
			66.309,
			66.356,
			66.371,
			66.433,
			66.462,
			66.494,
			66.557,
			66.571,
			66.618,
			66.682,
			66.741,
			66.787,
			66.866,
			66.897,
			66.929,
			66.989,
			67.052,
			67.113,
			67.175,
			67.238,
			67.301,
			67.331,
			67.362,
			67.439,
			67.486,
			67.548,
			67.609,
			67.657,
			67.671,
			67.735,
			67.763,
			67.797,
			67.858,
			67.874,
			67.921,
			67.981,
			68.044,
			68.105,
			68.168,
			68.2,
			68.23,
			68.291,
			68.354,
			68.417,
			68.48,
			68.54,
			68.603,
			68.635,
			68.664,
			68.743,
			68.789,
			68.851,
			68.913,
			68.958,
			68.974,
			69.037,
			69.073,
			69.097,
			69.176,
			69.221,
			69.285,
			69.347,
			69.392,
			69.472,
			69.502,
			69.531,
			69.594,
			69.611,
			69.657,
			69.718,
			69.781,
			69.827,
			69.843,
			69.906,
			69.935,
			69.965,
			70.028,
			70.043,
			70.091,
			70.153,
			70.213,
			70.262,
			70.339,
			70.371,
			70.401,
			70.479,
			70.523,
			70.585,
			70.649,
			70.694,
			70.711,
			70.773,
			70.804,
			70.834,
			70.896,
			70.913,
			70.959,
			71.021,
			71.084,
			71.134,
			71.145,
			71.207,
			71.238,
			71.27,
			71.329,
			71.347,
			71.392,
			71.456,
			71.515,
			71.578,
			71.64,
			71.671,
			71.703,
			71.763,
			71.826,
			71.888,
			71.952,
			71.998,
			72.013,
			72.074,
			72.105,
			72.137,
			72.216,
			72.261,
			72.322,
			72.386,
			72.431,
			72.448,
			72.508,
			72.539,
			72.572,
			72.634,
			72.648,
			72.694,
			72.758,
			72.819,
			72.864,
			72.942,
			72.974,
			73.005,
			73.068,
			73.129,
			73.19,
			73.252,
			73.3,
			73.314,
			73.376,
			73.408,
			73.438,
			73.501,
			73.518,
			73.562,
			73.627,
			73.687,
			73.734,
			73.747,
			73.812,
			73.841,
			73.873,
			73.936,
			73.996,
			74.059,
			74.121,
			74.168,
			74.244,
			74.277,
			74.306,
			74.37,
			74.43,
			74.494,
			74.555,
			74.602,
			74.618,
			74.681,
			74.71,
			74.742,
			74.804,
			74.819,
			74.864,
			74.927,
			74.988,
			75.051,
			75.115,
			75.144,
			75.176,
			75.24,
			75.297,
			75.362,
			75.423,
			75.471,
			75.487,
			75.548,
			75.579,
			75.611,
			75.67,
			75.687,
			75.733,
			75.797,
			75.857,
			75.903,
			75.918,
			75.981,
			76.012,
			76.044,
			76.12,
			76.167,
			76.229,
			76.29,
			76.338,
			76.415,
			76.446,
			76.476,
			76.555,
			76.602,
			76.665,
			76.726,
			76.788,
			76.848,
			76.879,
			76.911,
			76.974,
			76.99,
			77.036,
			77.097,
			77.16,
			77.207,
			77.221,
			77.285,
			77.319,
			77.345,
			77.408,
			77.423,
			77.47,
			77.532,
			77.594,
			77.64,
			77.718,
			77.75,
			77.78,
			77.841,
			77.904,
			77.967,
			78.027,
			78.076,
			78.091,
			78.153,
			78.183,
			78.215,
			78.277,
			78.292,
			78.337,
			78.4,
			78.461,
			78.525,
			78.587,
			78.618,
			78.647,
			78.711,
			78.725,
			78.772,
			78.834,
			78.898,
			78.959,
			79.021,
			79.051,
			79.08,
			79.145,
			79.207,
			79.268,
			79.329,
			79.379,
			79.391,
			79.453,
			79.486,
			79.514,
			79.579,
			79.594,
			79.639,
			79.701,
			79.763,
			79.811,
			79.888,
			79.919,
			79.951,
			80.013,
			80.075,
			80.135,
			80.196,
			80.245,
			80.261,
			80.321,
			80.352,
			80.385,
			80.445,
			80.462,
			80.508,
			80.574,
			80.633,
			80.693,
			80.755,
			80.787,
			80.819,
			80.881,
			80.897,
			80.943,
			81.004,
			81.067,
			81.113,
			81.191,
			81.22,
			81.251,
			81.313,
			81.376,
			81.443,
			81.501,
			81.548,
			81.563,
			81.623,
			81.656,
			81.688,
			81.747,
			81.764,
			81.811,
			81.872,
			81.936,
			81.996,
			82.059,
			82.091,
			82.122,
			82.183,
			82.2,
			82.245,
			82.307,
			82.37,
			82.417,
			82.494,
			82.525,
			82.556,
			82.618,
			82.678,
			82.741,
			82.802,
			82.85,
			82.863,
			82.927,
			82.959,
			82.989,
			83.051,
			83.066,
			83.115,
			83.175,
			83.238,
			83.285,
			83.298,
			83.361,
			83.392,
			83.422,
			83.505,
			83.548,
			83.61,
			83.67,
			83.717,
			83.795,
			83.827,
			83.858,
			83.919,
			83.979,
			84.044,
			84.106,
			84.152,
			84.167,
			84.23,
			84.261,
			84.291,
			84.353,
			84.37,
			84.415,
			84.477,
			84.538,
			84.586,
			84.6,
			84.665,
			84.695,
			84.725,
			84.788,
			84.848,
			84.913,
			84.973,
			85.02,
			85.096,
			85.127,
			85.159,
			85.222,
			85.237,
			85.283,
			85.346,
			85.406,
			85.452,
			85.468,
			85.53,
			85.568,
			85.594,
			85.654,
			85.672,
			85.717,
			85.784,
			85.842,
			85.888,
			85.964,
			85.996,
			86.026,
			86.088,
			86.152,
			86.188
		]
	],
	'3463_3464_7982': [
		[
			96.452
		],
		[
			0
		],
		[
			0,
			3.099,
			6.179,
			7.722,
			10.811,
			13.906,
			15.456,
			17.007,
			21.67,
			24.786,
			27.907,
			29.469,
			32.598,
			35.732,
			37.3,
			40.442,
			42.014,
			45.164,
			48.318,
			53.058,
			56.225,
			60.985,
			62.575,
			64.165,
			68.943,
			72.133,
			76.921,
			80.118,
			83.319,
			84.922,
			88.13,
			89.736,
			92.95,
			96.17,
			97.781,
			101.007,
			104.236,
			109.089,
			112.329,
			115.573,
			118.821,
			120.447,
			123.702,
			125.331,
			128.593,
			131.862,
			136.777,
			140.057,
			144.981,
			146.623,
			148.265,
			153.196,
			156.488,
			161.433,
			164.734,
			169.693,
			173.004,
			174.66,
			177.976,
			181.295,
			182.957,
			186.284,
			189.616,
			194.622,
			196.293,
			197.964,
			202.985,
			204.661,
			206.337,
			211.373,
			214.735,
			219.784,
			223.155,
			226.53,
			231.601,
			233.293,
			234.986,
			240.069,
			241.765,
			243.462,
			248.559,
			251.963,
			257.075,
			260.487,
			263.903,
			265.612,
			270.739,
			274.15,
			277.541,
			282.571,
			285.877,
			290.763,
			292.373,
			293.973,
			298.712,
			301.821,
			306.399,
			309.403,
			312.372,
			313.847,
			316.773,
			318.222,
			321.092,
			323.926,
			325.331,
			326.727,
			330.862,
			333.571,
			337.535,
			340.132,
			342.691,
			343.96,
			346.496,
			347.75,
			350.226,
			352.662,
			356.231,
			358.558,
			361.966,
			363.081,
			364.187,
			367.417,
			369.529,
			372.65,
			374.689,
			377.703,
			379.666,
			380.633,
			382.539,
			385.329,
			387.145,
			388.928,
			390.689,
			393.241,
			394.899,
			396.523,
			397.324,
			398.908,
			399.691,
			401.231,
			402.747,
			404.988,
			406.469,
			408.669,
			409.4,
			410.13,
			412.357,
			413.839,
			416.087,
			417.622,
			419.156,
			419.923,
			421.489,
			422.28,
			423.862,
			425.448,
			426.27,
			427.929,
			429.592,
			432.146,
			433.011,
			433.876,
			436.465,
			437.317,
			438.169,
			440.731,
			442.363,
			444.808,
			446.428,
			448.024,
			448.821,
			450.415,
			451.211,
			452.004,
			454.379,
			455.962,
			458.32,
			459.889,
			461.46,
			462.245,
			463.814,
			465.384,
			466.17,
			467.75,
			468.542,
			470.127,
			471.716,
			474.115,
			475.719,
			478.138,
			478.949,
			479.76,
			482.204,
			483.857,
			486.35,
			488.026,
			490.584,
			492.3,
			493.163,
			494.904,
			497.535,
			498.417,
			501.079,
			502.866,
			504.667,
			505.573,
			507.393,
			509.225,
			510.147,
			511.999,
			512.93,
			514.795,
			516.651,
			519.457,
			521.299,
			524.072,
			525.003,
			525.934,
			528.738,
			530.63,
			533.547,
			535.504,
			538.48,
			540.472,
			541.464,
			543.449,
			545.437,
			546.434,
			548.434,
			550.44,
			553.469,
			555.496,
			557.524,
			559.538,
			560.557,
			562.6,
			563.622,
			565.675,
			567.74,
			570.861,
			572.94,
			576.081,
			577.133,
			578.188,
			581.373,
			583.509,
			586.733,
			588.895,
			592.157,
			594.344,
			595.441,
			597.643,
			599.854,
			600.963,
			603.188,
			605.423,
			608.794,
			611.054,
			614.463,
			615.605,
			616.75,
			620.2,
			622.513,
			626.002,
			628.34,
			630.687,
			634.229,
			635.414,
			636.602,
			640.181,
			641.379,
			642.578,
			646.188,
			648.605,
			651.03,
			652.246,
			654.683,
			657.13,
			658.357,
			660.817,
			662.05,
			664.524,
			667.006,
			670.747,
			673.251,
			677.024,
			678.285,
			679.549,
			683.352,
			685.897,
			689.729,
			692.294,
			696.156,
			698.739,
			700.034,
			702.629,
			706.536,
			707.839,
			711.752,
			714.37,
			716.994,
			718.309,
			720.945,
			723.588,
			724.911,
			727.562,
			728.89,
			731.55,
			734.217,
			738.229,
			740.911,
			744.946,
			746.295,
			747.645,
			751.707,
			754.424,
			758.514,
			761.249,
			765.358,
			768.104,
			769.477,
			772.231,
			776.382,
			779.159,
			781.943,
			784.738,
			787.539,
			788.942,
			793.159,
			794.567,
			795.977,
			798.8,
			800.213,
			803.044,
			805.884,
			810.157,
			813.042,
			817.347,
			818.786,
			820.226,
			824.556,
			827.45,
			831.812,
			834.745,
			839.165,
			842.204,
			845.256,
			846.793,
			851.442,
			854.549,
			857.648,
			862.253,
			863.784,
			865.254,
			869.703,
			871.178,
			872.63,
			876.994,
			879.915,
			884.358,
			887.357,
			890.337,
			894.856,
			896.386,
			897.892,
			902.416,
			903.925,
			905.436,
			909.949,
			912.98,
			916.017,
			917.538,
			920.609,
			923.635,
			925.163,
			928.221,
			929.75,
			932.812,
			935.873,
			940.467,
			943.536,
			948.156,
			949.7,
			951.246,
			955.894,
			959.001,
			963.67,
			966.791,
			971.482,
			974.614,
			976.181,
			977.75,
			982.464,
			984.037,
			985.608,
			990.306,
			993.407,
			997.99,
			1000.996,
			1003.963,
			1005.432,
			1008.342,
			1009.782,
			1012.634,
			1015.448,
			1019.597,
			1022.315,
			1026.321,
			1027.637,
			1028.944,
			1032.807,
			1035.335,
			1039.053,
			1041.484,
			1043.878,
			1045.061,
			1047.403,
			1048.561,
			1050.85,
			1053.099,
			1054.209,
			1056.402,
			1058.557,
			1061.719,
			1062.754,
			1063.781,
			1065.806,
			1067.796,
			1068.777,
			1070.711,
			1071.665,
			1073.545,
			1075.388,
			1078.091,
			1079.851,
			1082.426,
			1083.266,
			1084.097,
			1086.534,
			1088.113,
			1090.413,
			1091.902,
			1093.357,
			1094.071,
			1095.474,
			1096.163,
			1097.517,
			1098.837,
			1099.484,
			1100.755,
			1101.998,
			1103.789,
			1104.946,
			1106.635,
			1107.189,
			1107.737,
			1109.357,
			1110.423,
			1112.008,
			1113.053,
			1114.091,
			1115.646,
			1116.165,
			1116.684,
			1118.777,
			1119.302,
			1120.876,
			1121.926,
			1123.598,
			1124.755,
			1125.908,
			1126.486,
			1127.645,
			1128.269,
			1129.534,
			1130.798,
			1132.699,
			1134.1,
			1136.214,
			1136.921,
			1137.629,
			1139.583,
			1140.879,
			1142.833,
			1144.046,
			1145.848,
			1147.057,
			1147.665,
			1148.86,
			1150.051,
			1150.649,
			1151.249,
			1153.063,
			1154.275,
			1156.111,
			1157.348,
			1158.602,
			1159.237,
			1160.519,
			1161.165,
			1162.471,
			1163.796,
			1165.808,
			1167.176,
			1169.26,
			1169.963,
			1170.671,
			1172.819,
			1174.272,
			1176.481,
			1177.975,
			1180.25,
			1181.789,
			1182.565,
			1184.131,
			1185.728,
			1186.532,
			1188.152,
			1189.79,
			1192.28,
			1193.958,
			1195.672,
			1197.416,
			1198.292,
			1200.059,
			1200.945,
			1202.724,
			1204.502,
			1207.237,
			1209.047,
			1211.776,
			1212.687,
			1213.602,
			1216.338,
			1218.157,
			1220.906,
			1222.737,
			1224.581,
			1225.508,
			1227.365,
			1228.298,
			1230.175,
			1232.064,
			1233.014,
			1234.922,
			1236.843,
			1239.747,
			1240.722,
			1241.699,
			1244.65,
			1245.639,
			1246.632,
			1249.627,
			1251.64,
			1253.664,
			1256.724,
			1258.78,
			1261.886,
			1262.927,
			1263.972,
			1268.166,
			1269.214,
			1272.37,
			1274.486,
			1276.61,
			1277.677,
			1279.815,
			1281.963,
			1283.04,
			1286.286,
			1288.462,
			1290.649,
			1293.948,
			1296.16,
			1299.499,
			1300.619,
			1301.742,
			1305.126,
			1307.401,
			1310.832,
			1313.132,
			1316.603,
			1318.931,
			1320.098,
			1321.268,
			1324.792,
			1325.971,
			1327.152,
			1330.711,
			1333.096,
			1335.49,
			1336.69,
			1339.098,
			1341.516,
			1342.728,
			1346.38,
			1348.826,
			1351.283,
			1354.986,
			1357.467,
			1361.209,
			1362.461,
			1363.716,
			1367.495,
			1370.024,
			1373.834,
			1376.383,
			1380.222,
			1382.791,
			1384.079,
			1386.659,
			1389.249,
			1390.547,
			1393.148,
			1395.755,
			1399.668,
			1400.976,
			1402.286,
			1404.911,
			1407.544,
			1408.863,
			1412.833,
			1415.489,
			1418.152,
			1422.161,
			1424.866,
			1428.905,
			1430.255,
			1431.607,
			1435.651,
			1438.368,
			1442.456,
			1445.206,
			1449.304,
			1452.041,
			1453.41,
			1456.151,
			1460.255,
			1463.018,
			1465.801,
			1470.008,
			1471.452,
			1472.875,
			1477.186,
			1478.632,
			1480.084,
			1484.457,
			1487.397,
			1490.358,
			1494.754,
			1497.655,
			1501.984,
			1503.416,
			1504.848,
			1509.117,
			1511.948,
			1516.143,
			1518.946,
			1521.775,
			1523.18,
			1525.993,
			1527.402,
			1530.223,
			1533.046,
			1534.458,
			1537.286,
			1540.114,
			1544.335,
			1547.167,
			1551.449,
			1552.872,
			1554.297,
			1558.58,
			1561.42,
			1565.727,
			1568.606,
			1571.493,
			1575.836,
			1577.288,
			1578.742,
			1584.572,
			1586.032,
			1590.418,
			1593.348,
			1596.284,
			1597.754,
			1600.7,
			1603.655,
			1605.134,
			1608.1,
			1609.586,
			1612.562,
			1615.546,
			1620.037,
			1623.04,
			1627.557,
			1629.066,
			1630.577,
			1635.118,
			1638.153,
			1642.717,
			1645.767,
			1650.341,
			1653.376,
			1654.885,
			1657.885,
			1660.848,
			1662.316,
			1665.223,
			1668.087,
			1670.911,
			1673.694,
			1675.071,
			1677.796,
			1680.484,
			1681.815,
			1684.448,
			1685.75,
			1688.334,
			1690.882,
			1694.633,
			1697.084,
			1700.688,
			1701.872,
			1703.045,
			1706.512,
			1708.774,
			1712.097,
			1714.263,
			1716.392,
			1717.442,
			1719.516,
			1720.538,
			1722.556,
			1725.519,
			1727.448,
			1729.341,
			1732.113,
			1733.916,
			1736.556,
			1737.418,
			1738.272,
			1740.776,
			1742.4,
			1743.987,
			1746.298,
			1747.793,
			1749.969,
			1750.676,
			1751.374,
			1753.418,
			1754.74,
			1756.662,
			1757.901,
			1759.694,
			1760.86,
			1761.979,
			1762.525,
			1763.594,
			1764.117,
			1765.134,
			1766.117,
			1767.543,
			1768.008,
			1768.467,
			1769.821,
			1770.266,
			1770.707,
			1772.014,
			1772.877,
			1774.168,
			1775.035,
			1775.93,
			1777.267,
			1777.714,
			1778.16,
			1779.951,
			1780.432,
			1781.907,
			1782.886,
			1784.364,
			1785.371,
			1786.402,
			1786.919,
			1787.956,
			1788.476,
			1789.52,
			1790.592,
			1792.333,
			1793.485,
			1795.218,
			1795.841,
			1796.501,
			1798.469,
			1799.77,
			1801.821,
			1803.223,
			1804.612,
			1805.305,
			1806.657,
			1807.321,
			1808.651,
			1810.647,
			1811.299,
			1813.132,
			1814.351,
			1816.177,
			1817.205,
			1818.237,
			1818.755,
			1819.796,
			1820.318,
			1821.37,
			1822.375,
			1823.893,
			1824.915,
			1826.469,
			1826.989,
			1827.508,
			1829.082,
			1830.145,
			1831.766,
			1832.846,
			1833.937,
			1834.487,
			1835.598,
			1836.159,
			1837.312,
			1838.508,
			1839.11,
			1840.313,
			1841.516,
			1843.396,
			1844.039,
			1844.685,
			1846.648,
			1847.31,
			1847.983,
			1849.346,
			1850.033,
			1851.42,
			1852.822,
			1854.949,
			1856.374,
			1858.558,
			1859.292,
			1860.03,
			1862.266,
			1863.775,
			1866.068,
			1867.616,
			1869.181,
			1869.971,
			1871.562,
			1873.169,
			1873.978,
			1875.602,
			1876.419,
			1878.065,
			1879.721,
			1882.219,
			1883.9,
			1886.411,
			1887.247,
			1888.086,
			1890.626,
			1892.336,
			1894.924,
			1896.671,
			1898.43,
			1899.314,
			1901.095,
			1901.991,
			1902.891,
			1906.523,
			1907.441,
			1910.213,
			1912.08,
			1913.962,
			1914.908,
			1916.809,
			1918.725,
			1919.687,
			1921.621,
			1922.592,
			1924.542,
			1926.505,
			1929.467,
			1931.456,
			1934.459,
			1935.466,
			1936.476,
			1939.521,
			1941.564,
			1944.65,
			1946.72,
			1948.802,
			1949.846,
			1951.941,
			1952.989,
			1955.083,
			1958.235,
			1960.349,
			1962.472,
			1965.674,
			1967.821,
			1969.978,
			1972.143,
			1973.23,
			1975.41,
			1976.503,
			1978.698,
			1980.901,
			1984.225,
			1986.452,
			1989.811,
			1990.935,
			1992.062,
			1995.456,
			1997.73,
			2001.16,
			2003.457,
			2006.921,
			2009.242,
			2010.406,
			2012.74,
			2016.259,
			2018.617,
			2020.984,
			2023.36,
			2025.745,
			2026.941,
			2030.541,
			2031.745,
			2032.951,
			2036.583,
			2039.014,
			2042.677,
			2045.129,
			2047.59,
			2051.297,
			2052.537,
			2053.779,
			2058.767,
			2060.019,
			2063.788,
			2066.311,
			2068.841,
			2070.109,
			2072.651,
			2073.925,
			2076.477,
			2079.037,
			2080.32,
			2082.892,
			2085.471,
			2089.352,
			2091.95,
			2095.857,
			2097.16,
			2098.465,
			2102.387,
			2105.011,
			2108.958,
			2111.597,
			2114.244,
			2115.57,
			2118.228,
			2119.559,
			2120.892,
			2124.897,
			2126.235,
			2127.578,
			2131.618,
			2134.335,
			2137.061,
			2138.427,
			2141.165,
			2143.905,
			2145.274,
			2148.019,
			2149.396,
			2152.156,
			2154.925,
			2159.09,
			2161.872,
			2166.056,
			2167.455,
			2168.854,
			2173.064,
			2175.866,
			2180.074,
			2182.885,
			2187.112,
			2189.938,
			2191.353,
			2194.187,
			2197.025,
			2198.447,
			2201.294,
			2204.148,
			2208.439,
			2211.308,
			2214.184,
			2217.069,
			2218.515,
			2222.863,
			2225.77,
			2228.681,
			2233.057,
			2235.981,
			2240.377,
			2241.845,
			2243.315,
			2247.732,
			2250.684,
			2255.134,
			2258.113,
			2262.592,
			2265.579,
			2267.072,
			2270.057,
			2274.542,
			2277.537,
			2280.54,
			2285.057,
			2286.567,
			2288.077,
			2292.615,
			2294.127,
			2295.637,
			2298.642,
			2300.134,
			2303.093,
			2306.013,
			2310.319,
			2313.143,
			2317.307,
			2318.676,
			2320.036,
			2324.056,
			2326.688,
			2330.564,
			2333.101,
			2335.601,
			2336.837,
			2339.281,
			2341.689,
			2342.881,
			2345.237,
			2346.403,
			2348.704,
			2350.966,
			2354.287,
			2356.455,
			2359.635,
			2360.677,
			2361.708,
			2364.745,
			2366.722,
			2369.619,
			2371.504,
			2373.354,
			2376.064,
			2376.95,
			2377.828,
			2380.409,
			2381.25,
			2382.083,
			2384.525,
			2386.107,
			2388.41,
			2389.9,
			2391.354,
			2392.067,
			2393.472,
			2394.164,
			2395.526,
			2396.868,
			2398.805,
			2400.055,
			2401.859,
			2402.443,
			2403.019,
			2404.699,
			2405.783,
			2407.364,
			2408.393,
			2409.904,
			2410.888,
			2411.377,
			2412.35,
			2413.319,
			2413.803,
			2414.288,
			2415.767,
			2416.748,
			2418.22,
			2419.206,
			2420.267,
			2420.802,
			2422.41,
			2423.486,
			2424.569,
			2426.428,
			2427.68,
			2429.573,
			2430.225,
			2430.88,
			2432.847,
			2434.163,
			2436.222,
			2437.619,
			2439.014,
			2439.712,
			2441.081,
			2441.758,
			2443.108,
			2444.457,
			2445.133,
			2446.404,
			2447.661,
			2449.545,
			2450.791,
			2451.991,
			2453.192,
			2453.794,
			2455.002,
			2455.609,
			2456.731,
			2457.856,
			2459.556,
			2460.701,
			2462.375,
			2462.937,
			2463.5,
			2465.206,
			2465.78,
			2466.368,
			2468.179,
			2469.401,
			2470.636,
			2471.264,
			2472.55,
			2473.198,
			2474.505,
			2475.83,
			2476.5,
			2477.858,
			2479.233,
			2481.324,
			2482.738,
			2484.889,
			2485.603,
			2486.333,
			2488.543,
			2490.037,
			2492.308,
			2493.837,
			2495.382,
			2497.729,
			2498.519,
			2499.313,
			2502.525,
			2503.338,
			2505.798,
			2507.457,
			2509.127,
			2509.967,
			2511.651,
			2513.334,
			2514.177,
			2515.874,
			2516.727,
			2518.443,
			2520.174,
			2522.796,
			2524.56,
			2527.231,
			2528.127,
			2529.028,
			2531.747,
			2533.576,
			2536.342,
			2538.203,
			2540.076,
			2541.018,
			2542.909,
			2543.86,
			2545.771,
			2547.695,
			2548.661,
			2549.631,
			2552.559,
			2555.508,
			2557.481,
			2559.456,
			2561.426,
			2562.425,
			2564.383,
			2565.359,
			2567.308,
			2569.252,
			2572.16,
			2574.092,
			2576.983,
			2577.94,
			2578.897,
			2581.764,
			2583.66,
			2586.502,
			2588.396,
			2590.289,
			2591.235,
			2593.146,
			2594.103,
			2596.014,
			2597.968,
			2598.962,
			2600.951,
			2603.004,
			2606.222,
			2608.43,
			2610.705,
			2612.99,
			2614.165,
			2616.514,
			2617.689,
			2620.002,
			2622.31,
			2625.662,
			2627.872,
			2631.161,
			2632.256,
			2633.351,
			2636.552,
			2638.682,
			2641.785,
			2643.85,
			2645.883,
			2646.897,
			2648.927,
			2649.933,
			2651.939,
			2653.95,
			2654.956,
			2656.968,
			2658.99,
			2662.04,
			2664.088,
			2667.175,
			2668.209,
			2669.244,
			2672.352,
			2674.43,
			2676.502,
			2679.669,
			2681.794,
			2685.012,
			2686.09,
			2687.17,
			2690.431,
			2691.523,
			2692.618,
			2695.921,
			2698.136,
			2700.361,
			2701.477,
			2703.718,
			2705.968,
			2707.097,
			2709.363,
			2710.499,
			2712.78,
			2715.074,
			2718.535,
			2720.866,
			2724.383,
			2725.561,
			2726.741,
			2730.29,
			2732.653,
			2736.208,
			2738.582,
			2742.161,
			2744.559,
			2745.763,
			2746.971,
			2750.611,
			2751.831,
			2753.053,
			2756.735,
			2759.203,
			2761.68,
			2762.922,
			2765.413,
			2767.913,
			2769.166,
			2771.678,
			2772.938,
			2775.464,
			2777.998,
			2781.815,
			2784.371,
			2788.22,
			2789.507,
			2790.797,
			2794.677,
			2795.975,
			2797.274,
			2801.184,
			2803.791,
			2806.403,
			2807.712,
			2810.335,
			2811.649,
			2814.284,
			2816.926,
			2818.25,
			2820.905,
			2823.568,
			2827.577,
			2828.917,
			2830.259,
			2832.948,
			2835.644,
			2836.993,
			2839.697,
			2841.052,
			2843.766,
			2846.488,
			2850.583,
			2853.322,
			2857.445,
			2858.824,
			2860.204,
			2864.355,
			2867.131,
			2871.308,
			2874.102,
			2878.306,
			2881.117,
			2882.525,
			2885.346,
			2889.59,
			2892.429,
			2895.276,
			2899.559,
			2900.989,
			2902.42,
			2906.724,
			2908.162,
			2909.601,
			2913.928,
			2916.821,
			2921.172,
			2924.081,
			2926.995,
			2931.38,
			2932.844,
			2934.311,
			2938.719,
			2940.191,
			2941.666,
			2946.098,
			2949.06,
			2953.516,
			2956.493,
			2959.477,
			2960.971,
			2963.967,
			2965.468,
			2968.477,
			2971.494,
			2976.033,
			2979.063,
			2983.617,
			2985.138,
			2986.659,
			2991.231,
			2994.286,
			2998.877,
			3001.946,
			3005.02,
			3006.56,
			3009.642,
			3011.186,
			3014.277,
			3018.921,
			3020.472,
			3025.13,
			3028.236,
			3032.877,
			3035.942,
			3038.976,
			3040.478,
			3043.455,
			3044.928,
			3047.846,
			3050.725,
			3054.972,
			3057.754,
			3061.856,
			3063.204,
			3064.542,
			3068.499,
			3071.09,
			3074.908,
			3077.409,
			3079.873,
			3081.092,
			3083.502,
			3084.694,
			3087.052,
			3089.373,
			3090.518,
			3092.781,
			3095.006,
			3098.273,
			3099.343,
			3100.404,
			3103.531,
			3104.554,
			3105.568,
			3108.554,
			3110.499,
			3112.406,
			3115.2,
			3117.019,
			3119.684,
			3120.555,
			3121.417,
			3123.949,
			3125.59,
			3127.984,
			3129.533,
			3131.047,
			3131.79,
			3133.249,
			3134.671,
			3135.368,
			3136.738,
			3137.409,
			3138.728,
			3140.013,
			3141.895,
			3142.503,
			3143.104,
			3144.876,
			3145.457,
			3146.033,
			3147.731,
			3148.845,
			3150.505,
			3151.612,
			3152.716,
			3154.366,
			3154.916,
			3155.466,
			3157.133,
			3157.697,
			3158.26,
			3159.945,
			3161.071,
			3162.226,
			3162.83,
			3164.035,
			3165.241,
			3165.844,
			3167.051,
			3167.789,
			3169.314,
			3170.835,
			3173.117,
			3174.628,
			3176.888,
			3177.616,
			3178.271,
			3180.217,
			3181.502,
			3183.406,
			3184.653,
			3186.517,
			3187.77,
			3188.447,
			3189.794,
			3191.137,
			3191.808,
			3192.48,
			3194.54,
			3195.917,
			3197.297,
			3197.994,
			3199.458,
			3200.922,
			3201.656,
			3203.127,
			3203.871,
			3205.359,
			3206.851,
			3208.981,
			3210.203,
			3212.042,
			3212.658,
			3213.276,
			3215.121,
			3216.352,
			3218.207,
			3219.463,
			3220.729,
			3221.365,
			3222.639,
			3223.278,
			3224.561,
			3225.847,
			3226.491,
			3227.782,
			3229.076,
			3230.362,
			3231.666,
			3232.321,
			3234.319,
			3234.997,
			3235.679,
			3237.743,
			3239.137,
			3240.558,
			3242.717,
			3244.175,
			3246.373,
			3247.111,
			3247.852,
			3250.099,
			3251.614,
			3253.91,
			3255.454,
			3257.007,
			3257.789,
			3259.362,
			3260.153,
			3261.727,
			3263.328,
			3264.134,
			3265.767,
			3267.396,
			3269.838,
			3270.657,
			3271.485,
			3273.987,
			3274.828,
			3275.672,
			3278.237,
			3279.961,
			3281.705,
			3284.347,
			3286.126,
			3288.848,
			3289.76,
			3290.677,
			3293.441,
			3294.375,
			3295.312,
			3298.129,
			3300.019,
			3301.921,
			3302.876,
			3304.794,
			3306.725,
			3307.697,
			3309.658,
			3310.643,
			3312.621,
			3314.614,
			3317.626,
			3319.643,
			3322.688,
			3323.709,
			3324.731,
			3327.817,
			3329.89,
			3333.022,
			3335.121,
			3337.219,
			3338.27,
			3340.377,
			3341.435,
			3342.496,
			3345.689,
			3346.758,
			3347.83,
			3351.059,
			3353.223,
			3355.397,
			3356.489,
			3358.679,
			3360.878,
			3361.981,
			3365.303,
			3367.529,
			3369.763,
			3373.136,
			3375.396,
			3378.805,
			3379.947,
			3381.09,
			3384.536,
			3386.845,
			3390.326,
			3392.657,
			3394.998,
			3396.172,
			3398.526,
			3399.706,
			3402.074,
			3404.451,
			3405.643,
			3408.034,
			3410.433,
			3414.05,
			3416.471,
			3420.121,
			3421.342,
			3422.566,
			3425.019,
			3426.248,
			3428.714,
			3431.188,
			3434.916,
			3437.412,
			3441.173,
			3442.43,
			3443.69,
			3447.481,
			3450.019,
			3453.84,
			3456.397,
			3458.963,
			3460.249,
			3462.826,
			3464.118,
			3466.706,
			3470.604,
			3473.211,
			3475.817,
			3479.735,
			3482.355,
			3486.298,
			3487.616,
			3488.935,
			3492.906,
			3495.561,
			3499.557,
			3502.23,
			3504.91,
			3508.943,
			3510.291,
			3511.64,
			3515.697,
			3517.053,
			3518.411,
			3522.495,
			3525.226,
			3527.963,
			3529.334,
			3532.081,
			3534.834,
			3536.214,
			3538.978,
			3540.362,
			3543.136,
			3545.917,
			3550.099,
			3552.896,
			3557.106,
			3558.512,
			3559.92,
			3564.155,
			3566.986,
			3571.244,
			3574.089,
			3578.37,
			3581.232,
			3582.666,
			3584.101,
			3588.416,
			3589.857,
			3592.746,
			3595.643,
			3598.544,
			3601.452,
			3602.908,
			3605.823,
			3608.743,
			3610.205,
			3613.133,
			3614.6,
			3617.539,
			3620.485,
			3624.919,
			3627.885,
			3632.345,
			3633.836,
			3635.328,
			3639.827,
			3642.837,
			3647.369,
			3650.403,
			3653.448,
			3654.977,
			3658.037,
			3659.565,
			3662.625,
			3665.688,
			3667.22,
			3670.291,
			3673.367,
			3676.464,
			3679.567,
			3681.118,
			3684.223,
			3687.325,
			3688.878,
			3691.997,
			3693.561,
			3696.692,
			3699.829,
			3704.513,
			3707.637,
			3712.332,
			3713.901,
			3715.471,
			3720.188,
			3723.336,
			3728.062,
			3731.218,
			3734.387,
			3735.975,
			3739.151,
			3740.739,
			3743.919,
			3747.101,
			3748.694,
			3751.883,
			3755.075,
			3759.866,
			3761.465,
			3763.063,
			3767.868,
			3769.468,
			3771.068,
			3775.842,
			3778.992,
			3783.638,
			3786.678,
			3789.669,
			3794.082,
			3795.533,
			3796.974,
			3801.243,
			3802.649,
			3804.046,
			3808.181,
			3810.889,
			3814.881,
			3817.499,
			3820.077,
			3821.351,
			3823.87,
			3825.116,
			3827.582,
			3830.031,
			3833.611,
			3835.957,
			3839.392,
			3840.525,
			3841.645,
			3844.949,
			3847.096,
			3850.244,
			3852.332,
			3854.371,
			3855.378,
			3857.378,
			3858.368,
			3860.326,
			3862.245,
			3863.191,
			3864.134,
			3866.962,
			3868.862,
			3870.764,
			3871.716,
			3873.619,
			3875.522,
			3876.474,
			3878.412,
			3879.387,
			3881.335,
			3883.335,
			3886.42,
			3888.501,
			3891.653,
			3892.704,
			3893.75,
			3896.885,
			3898.946,
			3901.985,
			3904.009,
			3907.038,
			3909.073,
			3910.127,
			3912.239,
			3914.363,
			3915.436,
			3916.509,
			3919.729,
			3922.899,
			3924.976,
			3926.951,
			3928.936,
			3929.922,
			3932.832,
			3934.784,
			3936.761,
			3939.749,
			3941.765,
			3944.81,
			3945.83,
			3946.855,
			3949.937,
			3951.999,
			3955.102,
			3957.179,
			3959.261,
			3960.304,
			3962.391,
			3963.437,
			3965.533,
			3967.649,
			3968.719,
			3970.865,
			3973.022,
			3976.287,
			3978.456,
			3981.751,
			3982.855,
			3983.963,
			3987.322,
			3989.557,
			3991.796,
			3995.176,
			3997.44,
			4000.826,
			4001.967,
			4003.109,
			4006.556,
			4008.869,
			4012.362,
			4014.668,
			4016.992,
			4018.151,
			4020.475,
			4022.822,
			4023.968,
			4026.305,
			4027.476,
			4029.812,
			4032.16,
			4035.709,
			4038.089,
			4041.688,
			4042.894,
			4044.102,
			4047.747,
			4050.189,
			4053.873,
			4056.341,
			4058.819,
			4060.061,
			4062.553,
			4063.802,
			4065.053,
			4068.823,
			4070.084,
			4071.347,
			4075.148,
			4077.695,
			4081.536,
			4084.108,
			4086.689,
			4087.983,
			4091.88,
			4094.488,
			4097.104,
			4101.03,
			4103.656,
			4107.613,
			4108.933,
			4110.253,
			4114.223,
			4116.868,
			4120.845,
			4123.5,
			4126.17,
			4127.51,
			4130.194,
			4131.542,
			4134.241,
			4138.301,
			4141.015,
			4143.732,
			4147.826,
			4150.569,
			4153.323,
			4156.087,
			4157.472,
			4160.246,
			4161.635,
			4164.42,
			4167.212,
			4171.415,
			4174.225,
			4178.46,
			4179.875,
			4181.292,
			4185.555,
			4188.402,
			4192.683,
			4195.542,
			4198.407,
			4199.842,
			4202.716,
			4204.155,
			4207.039,
			4211.375,
			4214.273,
			4217.178,
			4221.548,
			4224.469,
			4228.862,
			4230.331,
			4231.8,
			4236.218,
			4239.171,
			4243.614,
			4246.583,
			4249.558,
			4254.032,
			4255.527,
			4257.022,
			4261.519,
			4263.021,
			4264.524,
			4269.043,
			4272.063,
			4276.604,
			4279.639,
			4282.679,
			4284.202,
			4287.251,
			4288.778,
			4291.835,
			4294.899,
			4299.505,
			4302.583,
			4305.771
		],
		[
			0,
			0.041,
			0.104,
			0.133,
			0.166,
			0.227,
			0.243,
			0.288,
			0.351,
			0.413,
			0.458,
			0.474,
			0.537,
			0.573,
			0.6,
			0.66,
			0.675,
			0.724,
			0.787,
			0.845,
			0.908,
			0.972,
			1.002,
			1.033,
			1.096,
			1.156,
			1.221,
			1.281,
			1.328,
			1.344,
			1.404,
			1.437,
			1.467,
			1.529,
			1.544,
			1.591,
			1.654,
			1.715,
			1.777,
			1.838,
			1.871,
			1.9,
			1.961,
			1.977,
			2.024,
			2.088,
			2.15,
			2.196,
			2.273,
			2.304,
			2.335,
			2.413,
			2.459,
			2.523,
			2.584,
			2.645,
			2.706,
			2.739,
			2.77,
			2.832,
			2.847,
			2.894,
			2.957,
			3.019,
			3.062,
			3.079,
			3.141,
			3.173,
			3.204,
			3.265,
			3.326,
			3.39,
			3.452,
			3.512,
			3.576,
			3.606,
			3.637,
			3.699,
			3.716,
			3.762,
			3.825,
			3.885,
			3.948,
			4.008,
			4.042,
			4.071,
			4.149,
			4.195,
			4.256,
			4.32,
			4.367,
			4.443,
			4.476,
			4.506,
			4.567,
			4.628,
			4.69,
			4.753,
			4.801,
			4.816,
			4.876,
			4.908,
			4.939,
			5,
			5.017,
			5.062,
			5.127,
			5.188,
			5.25,
			5.311,
			5.342,
			5.375,
			5.436,
			5.452,
			5.496,
			5.56,
			5.621,
			5.684,
			5.743,
			5.776,
			5.809,
			5.885,
			5.932,
			5.995,
			6.057,
			6.118,
			6.178,
			6.211,
			6.242,
			6.319,
			6.367,
			6.426,
			6.488,
			6.551,
			6.613,
			6.644,
			6.675,
			6.739,
			6.76,
			6.8,
			6.862,
			6.925,
			6.971,
			7.048,
			7.078,
			7.109,
			7.173,
			7.234,
			7.296,
			7.357,
			7.404,
			7.421,
			7.482,
			7.511,
			7.544,
			7.605,
			7.622,
			7.669,
			7.729,
			7.793,
			7.837,
			7.854,
			7.916,
			7.945,
			7.978,
			8.041,
			8.103,
			8.165,
			8.225,
			8.272,
			8.289,
			8.349,
			8.382,
			8.412,
			8.489,
			8.537,
			8.6,
			8.659,
			8.707,
			8.721,
			8.785,
			8.82,
			8.847,
			8.907,
			8.922,
			8.971,
			9.036,
			9.095,
			9.139,
			9.219,
			9.25,
			9.281,
			9.359,
			9.405,
			9.472,
			9.527,
			9.591,
			9.651,
			9.683,
			9.715,
			9.793,
			9.839,
			9.901,
			9.963,
			10.009,
			10.025,
			10.087,
			10.116,
			10.15,
			10.21,
			10.226,
			10.274,
			10.334,
			10.395,
			10.442,
			10.52,
			10.552,
			10.583,
			10.643,
			10.707,
			10.769,
			10.829,
			10.894,
			10.955,
			10.986,
			11.018,
			11.077,
			11.093,
			11.141,
			11.203,
			11.264,
			11.326,
			11.388,
			11.419,
			11.452,
			11.512,
			11.528,
			11.576,
			11.638,
			11.7,
			11.746,
			11.823,
			11.853,
			11.885,
			11.948,
			12.008,
			12.07,
			12.135,
			12.196,
			12.257,
			12.287,
			12.32,
			12.381,
			12.395,
			12.443,
			12.505,
			12.568,
			12.628,
			12.689,
			12.721,
			12.753,
			12.816,
			12.877,
			12.945,
			13,
			13.047,
			13.126,
			13.155,
			13.188,
			13.25,
			13.266,
			13.311,
			13.373,
			13.434,
			13.482,
			13.496,
			13.56,
			13.59,
			13.621,
			13.683,
			13.699,
			13.744,
			13.807,
			13.868,
			13.916,
			13.994,
			14.025,
			14.056,
			14.132,
			14.179,
			14.241,
			14.304,
			14.366,
			14.428,
			14.457,
			14.489,
			14.567,
			14.612,
			14.674,
			14.738,
			14.785,
			14.8,
			14.862,
			14.891,
			14.924,
			14.984,
			15.005,
			15.049,
			15.11,
			15.17,
			15.218,
			15.295,
			15.326,
			15.357,
			15.42,
			15.482,
			15.545,
			15.607,
			15.667,
			15.731,
			15.761,
			15.792,
			15.868,
			15.916,
			15.978,
			16.039,
			16.087,
			16.101,
			16.164,
			16.195,
			16.226,
			16.288,
			16.303,
			16.351,
			16.411,
			16.474,
			16.521,
			16.598,
			16.629,
			16.66,
			16.723,
			16.783,
			16.845,
			16.908,
			16.969,
			17.033,
			17.07,
			17.093,
			17.17,
			17.218,
			17.278,
			17.342,
			17.388,
			17.402,
			17.467,
			17.495,
			17.526,
			17.59,
			17.652,
			17.714,
			17.776,
			17.822,
			17.899,
			17.931,
			17.961,
			18.023,
			18.041,
			18.086,
			18.149,
			18.209,
			18.256,
			18.271,
			18.334,
			18.365,
			18.397,
			18.458,
			18.474,
			18.521,
			18.584,
			18.643,
			18.706,
			18.768,
			18.8,
			18.829,
			18.892,
			18.954,
			19.017,
			19.079,
			19.14,
			19.203,
			19.234,
			19.263,
			19.327,
			19.344,
			19.388,
			19.452,
			19.512,
			19.573,
			19.637,
			19.667,
			19.699,
			19.759,
			19.776,
			19.822,
			19.884,
			19.947,
			19.992,
			20.071,
			20.1,
			20.132,
			20.195,
			20.256,
			20.32,
			20.379,
			20.427,
			20.441,
			20.505,
			20.535,
			20.566,
			20.629,
			20.643,
			20.692,
			20.757,
			20.816,
			20.862,
			20.877,
			20.939,
			20.969,
			21,
			21.062,
			21.079,
			21.124,
			21.186,
			21.248,
			21.294,
			21.372,
			21.403,
			21.435,
			21.496,
			21.559,
			21.62,
			21.683,
			21.728,
			21.744,
			21.808,
			21.839,
			21.868,
			21.929,
			21.945,
			21.992,
			22.054,
			22.118,
			22.164,
			22.239,
			22.273,
			22.302,
			22.366,
			22.427,
			22.489,
			22.551,
			22.612,
			22.676,
			22.706,
			22.737,
			22.815,
			22.861,
			22.922,
			22.983,
			23.045,
			23.109,
			23.141,
			23.17,
			23.234,
			23.253,
			23.296,
			23.356,
			23.418,
			23.482,
			23.542,
			23.575,
			23.606,
			23.666,
			23.729,
			23.791,
			23.854,
			23.915,
			23.976,
			24.007,
			24.039,
			24.101,
			24.117,
			24.162,
			24.224,
			24.288,
			24.35,
			24.412,
			24.441,
			24.474,
			24.534,
			24.551,
			24.595,
			24.659,
			24.721,
			24.784,
			24.844,
			24.876,
			24.907,
			24.986,
			25.031,
			25.092,
			25.155,
			25.217,
			25.278,
			25.312,
			25.34,
			25.404,
			25.418,
			25.466,
			25.526,
			25.589,
			25.65,
			25.711,
			25.743,
			25.776,
			25.837,
			25.853,
			25.9,
			25.961,
			26.023,
			26.071,
			26.149,
			26.179,
			26.208,
			26.271,
			26.332,
			26.396,
			26.457,
			26.503,
			26.52,
			26.582,
			26.611,
			26.645,
			26.706,
			26.721,
			26.768,
			26.829,
			26.893,
			26.938,
			26.952,
			27.015,
			27.046,
			27.077,
			27.139,
			27.201,
			27.262,
			27.326,
			27.377,
			27.45,
			27.478,
			27.511,
			27.589,
			27.635,
			27.696,
			27.759,
			27.805,
			27.822,
			27.884,
			27.915,
			27.945,
			28.023,
			28.068,
			28.132,
			28.192,
			28.241,
			28.316,
			28.349,
			28.381,
			28.442,
			28.503,
			28.567,
			28.627,
			28.689,
			28.753,
			28.783,
			28.814,
			28.877,
			28.89,
			28.938,
			28.999,
			29.063,
			29.109,
			29.123,
			29.187,
			29.218,
			29.249,
			29.325,
			29.372,
			29.439,
			29.495,
			29.544,
			29.621,
			29.651,
			29.683,
			29.745,
			29.807,
			29.87,
			29.931,
			29.993,
			30.053,
			30.085,
			30.116,
			30.179,
			30.192,
			30.241,
			30.303,
			30.365,
			30.409,
			30.426,
			30.489,
			30.518,
			30.551,
			30.629,
			30.675,
			30.736,
			30.796,
			30.844,
			30.922,
			30.953,
			30.984,
			31.048,
			31.108,
			31.17,
			31.233,
			31.293,
			31.356,
			31.388,
			31.418,
			31.5,
			31.542,
			31.605,
			31.666,
			31.712,
			31.727,
			31.791,
			31.82,
			31.851,
			31.915,
			31.977,
			32.039,
			32.101,
			32.145,
			32.224,
			32.255,
			32.286,
			32.349,
			32.41,
			32.473,
			32.536,
			32.582,
			32.596,
			32.659,
			32.688,
			32.72,
			32.782,
			32.796,
			32.846,
			32.906,
			32.968,
			33.016,
			33.093,
			33.124,
			33.155,
			33.217,
			33.277,
			33.342,
			33.403,
			33.449,
			33.525,
			33.561,
			33.588,
			33.666,
			33.711,
			33.776,
			33.837,
			33.884,
			33.9,
			33.959,
			33.993,
			34.021,
			34.086,
			34.099,
			34.148,
			34.208,
			34.269,
			34.334,
			34.393,
			34.424,
			34.456,
			34.519,
			34.582,
			34.644,
			34.705,
			34.767,
			34.829,
			34.86,
			34.891,
			34.954,
			34.968,
			35.016,
			35.076,
			35.139,
			35.187,
			35.2,
			35.262,
			35.294,
			35.325,
			35.387,
			35.403,
			35.449,
			35.512,
			35.573,
			35.624,
			35.698,
			35.729,
			35.76,
			35.82,
			35.883,
			35.944,
			36.008,
			36.054,
			36.069,
			36.131,
			36.163,
			36.194,
			36.27,
			36.316,
			36.378,
			36.441,
			36.503,
			36.565,
			36.594,
			36.626,
			36.705,
			36.751,
			36.812,
			36.875,
			36.921,
			36.999,
			37.028,
			37.061,
			37.139,
			37.186,
			37.248,
			37.309,
			37.372,
			37.434,
			37.465,
			37.495,
			37.558,
			37.571,
			37.618,
			37.687,
			37.744,
			37.789,
			37.805,
			37.868,
			37.899,
			37.929,
			38.007,
			38.052,
			38.121,
			38.176,
			38.225,
			38.3,
			38.332,
			38.362,
			38.442,
			38.487,
			38.551,
			38.61,
			38.674,
			38.735,
			38.767,
			38.799,
			38.859,
			38.876,
			38.923,
			38.984,
			39.044,
			39.091,
			39.171,
			39.202,
			39.233,
			39.294,
			39.355,
			39.418,
			39.48,
			39.526,
			39.542,
			39.604,
			39.634,
			39.666,
			39.745,
			39.788,
			39.851,
			39.912,
			39.976,
			40.039,
			40.07,
			40.101,
			40.162,
			40.178,
			40.224,
			40.285,
			40.348,
			40.394,
			40.473,
			40.503,
			40.534,
			40.594,
			40.657,
			40.721,
			40.783,
			40.829,
			40.845,
			40.906,
			40.937,
			40.968,
			41.031,
			41.045,
			41.092,
			41.156,
			41.216,
			41.261,
			41.278,
			41.34,
			41.371,
			41.403,
			41.462,
			41.48,
			41.527,
			41.587,
			41.65,
			41.696,
			41.775,
			41.808,
			41.836,
			41.899,
			41.961,
			42.023,
			42.084,
			42.13,
			42.144,
			42.209,
			42.24,
			42.271,
			42.332,
			42.346,
			42.394,
			42.457,
			42.518,
			42.564,
			42.643,
			42.673,
			42.703,
			42.766,
			42.829,
			42.891,
			42.952,
			42.999,
			43.015,
			43.076,
			43.106,
			43.137,
			43.216,
			43.262,
			43.325,
			43.387,
			43.434,
			43.45,
			43.51,
			43.542,
			43.574,
			43.635,
			43.649,
			43.696,
			43.759,
			43.821,
			43.872,
			43.944,
			43.974,
			44.007,
			44.069,
			44.129,
			44.192,
			44.253,
			44.3,
			44.318,
			44.379,
			44.411,
			44.44,
			44.518,
			44.564,
			44.626,
			44.69,
			44.751,
			44.814,
			44.844,
			44.876,
			44.935,
			44.952,
			44.999,
			45.061,
			45.121,
			45.184,
			45.245,
			45.279,
			45.309,
			45.37,
			45.437,
			45.493,
			45.556,
			45.62,
			45.679,
			45.713,
			45.743,
			45.82,
			45.868,
			45.937,
			45.989,
			46.038,
			46.053,
			46.115,
			46.149,
			46.176,
			46.255,
			46.3,
			46.368,
			46.424,
			46.472,
			46.549,
			46.581,
			46.612,
			46.69,
			46.735,
			46.797,
			46.859,
			46.904,
			46.921,
			46.983,
			47.014,
			47.046,
			47.108,
			47.122,
			47.17,
			47.232,
			47.294,
			47.339,
			47.418,
			47.448,
			47.479,
			47.54,
			47.603,
			47.667,
			47.728,
			47.774,
			47.79,
			47.852,
			47.883,
			47.914,
			47.976,
			47.994,
			48.038,
			48.1,
			48.162,
			48.208,
			48.222,
			48.284,
			48.318,
			48.348,
			48.408,
			48.424,
			48.471,
			48.533,
			48.594,
			48.641,
			48.72,
			48.751,
			48.779,
			48.843,
			48.905,
			48.968,
			49.029,
			49.09,
			49.153,
			49.185,
			49.216,
			49.276,
			49.293,
			49.341,
			49.403,
			49.464,
			49.524,
			49.589,
			49.618,
			49.65,
			49.728,
			49.773,
			49.838,
			49.896,
			49.943,
			50.022,
			50.058,
			50.084,
			50.145,
			50.206,
			50.27,
			50.332,
			50.393,
			50.456,
			50.488,
			50.518,
			50.595,
			50.642,
			50.703,
			50.766,
			50.812,
			50.829,
			50.891,
			50.921,
			50.952,
			51.011,
			51.029,
			51.076,
			51.138,
			51.201,
			51.247,
			51.324,
			51.356,
			51.385,
			51.448,
			51.509,
			51.573,
			51.634,
			51.679,
			51.694,
			51.759,
			51.79,
			51.819,
			51.883,
			51.895,
			51.944,
			52.005,
			52.067,
			52.122,
			52.191,
			52.223,
			52.254,
			52.333,
			52.378,
			52.44,
			52.503,
			52.562,
			52.625,
			52.655,
			52.688,
			52.75,
			52.766,
			52.811,
			52.874,
			52.937,
			52.999,
			53.059,
			53.091,
			53.122,
			53.184,
			53.201,
			53.247,
			53.309,
			53.371,
			53.433,
			53.494,
			53.525,
			53.555,
			53.618,
			53.681,
			53.742,
			53.804,
			53.867,
			53.927,
			53.96,
			53.991,
			54.053,
			54.067,
			54.114,
			54.182,
			54.237,
			54.301,
			54.362,
			54.392,
			54.423,
			54.501,
			54.548,
			54.611,
			54.673,
			54.735,
			54.795,
			54.828,
			54.859,
			54.921,
			54.983,
			55.044,
			55.106,
			55.154,
			55.168,
			55.231,
			55.262,
			55.292,
			55.354,
			55.371,
			55.416,
			55.479,
			55.542,
			55.601,
			55.663,
			55.694,
			55.728,
			55.788,
			55.805,
			55.851,
			55.912,
			55.975,
			56.02,
			56.099,
			56.129,
			56.159,
			56.224,
			56.244,
			56.285,
			56.345,
			56.408,
			56.454,
			56.472,
			56.532,
			56.562,
			56.595,
			56.657,
			56.671,
			56.718,
			56.779,
			56.842,
			56.905,
			56.967,
			56.996,
			57.027,
			57.09,
			57.154,
			57.216,
			57.275,
			57.323,
			57.399,
			57.433,
			57.461,
			57.541,
			57.587,
			57.651,
			57.711,
			57.758,
			57.773,
			57.835,
			57.868,
			57.896,
			57.958,
			57.976,
			58.021,
			58.084,
			58.144,
			58.192,
			58.27,
			58.304,
			58.332,
			58.392,
			58.456,
			58.516,
			58.579,
			58.626,
			58.64,
			58.701,
			58.734,
			58.766,
			58.827,
			58.842,
			58.888,
			58.952,
			59.014,
			59.076,
			59.137,
			59.168,
			59.201,
			59.261,
			59.276,
			59.322,
			59.387,
			59.445,
			59.495,
			59.57,
			59.601,
			59.634,
			59.695,
			59.756,
			59.824,
			59.882,
			59.928,
			59.942,
			60.005,
			60.036,
			60.066,
			60.129,
			60.145,
			60.191,
			60.253,
			60.316,
			60.367,
			60.439,
			60.47,
			60.5,
			60.562,
			60.579,
			60.624,
			60.689,
			60.749,
			60.795,
			60.873,
			60.905,
			60.936,
			60.997,
			61.059,
			61.123,
			61.184,
			61.229,
			61.245,
			61.306,
			61.338,
			61.369,
			61.432,
			61.445,
			61.493,
			61.555,
			61.618,
			61.665,
			61.742,
			61.773,
			61.803,
			61.867,
			61.929,
			61.989,
			62.053,
			62.099,
			62.176,
			62.206,
			62.239,
			62.299,
			62.315,
			62.362,
			62.429,
			62.486,
			62.532,
			62.548,
			62.61,
			62.64,
			62.671,
			62.734,
			62.749,
			62.795,
			62.857,
			62.92,
			62.983,
			63.043,
			63.073,
			63.105,
			63.183,
			63.228,
			63.292,
			63.354,
			63.417,
			63.478,
			63.508,
			63.539,
			63.603,
			63.617,
			63.662,
			63.725,
			63.789,
			63.835,
			63.851,
			63.912,
			63.942,
			63.973,
			64.037,
			64.053,
			64.099,
			64.16,
			64.222,
			64.268,
			64.344,
			64.378,
			64.409,
			64.471,
			64.491,
			64.532,
			64.594,
			64.655,
			64.703,
			64.717,
			64.779,
			64.811,
			64.842,
			64.904,
			64.92,
			64.968,
			65.029,
			65.09,
			65.138,
			65.152,
			65.213,
			65.244,
			65.276,
			65.338,
			65.353,
			65.4,
			65.462,
			65.525,
			65.571,
			65.649,
			65.679,
			65.711,
			65.789,
			65.834,
			65.894,
			65.959,
			66.019,
			66.082,
			66.112,
			66.144,
			66.222,
			66.27,
			66.332,
			66.392,
			66.439,
			66.453,
			66.516,
			66.551,
			66.579,
			66.642,
			66.703,
			66.765,
			66.827,
			66.874,
			66.95,
			66.982,
			67.014,
			67.073,
			67.091,
			67.137,
			67.199,
			67.259,
			67.323,
			67.385,
			67.415,
			67.447,
			67.507,
			67.524,
			67.57,
			67.634,
			67.696,
			67.742,
			67.82,
			67.849,
			67.879,
			67.942,
			68.003,
			68.067,
			68.128,
			68.176,
			68.189,
			68.254,
			68.283,
			68.316,
			68.392,
			68.437,
			68.502,
			68.561,
			68.624,
			68.686,
			68.718,
			68.749,
			68.812,
			68.826,
			68.874,
			68.936,
			68.996,
			69.042,
			69.121,
			69.153,
			69.183,
			69.244,
			69.308,
			69.37,
			69.43,
			69.477,
			69.494,
			69.556,
			69.585,
			69.618,
			69.678,
			69.695,
			69.742,
			69.803,
			69.866,
			69.912,
			69.926,
			69.99,
			70.021,
			70.05,
			70.129,
			70.175,
			70.237,
			70.299,
			70.345,
			70.423,
			70.454,
			70.486,
			70.561,
			70.61,
			70.675,
			70.734,
			70.779,
			70.796,
			70.858,
			70.889,
			70.92,
			70.982,
			70.997,
			71.042,
			71.104,
			71.168,
			71.211,
			71.228,
			71.292,
			71.322,
			71.353,
			71.431,
			71.478,
			71.539,
			71.602,
			71.649,
			71.725,
			71.755,
			71.787,
			71.85,
			71.865,
			71.912,
			71.975,
			72.034,
			72.083,
			72.098,
			72.159,
			72.19,
			72.221,
			72.282,
			72.3,
			72.345,
			72.407,
			72.469,
			72.517,
			72.595,
			72.626,
			72.656,
			72.717,
			72.778,
			72.842,
			72.904,
			72.965,
			73.029,
			73.059,
			73.09,
			73.15,
			73.166,
			73.212,
			73.275,
			73.339,
			73.385,
			73.399,
			73.462,
			73.494,
			73.524,
			73.585,
			73.602,
			73.648,
			73.709,
			73.773,
			73.834,
			73.893,
			73.926,
			73.958,
			74.02,
			74.082,
			74.143,
			74.205,
			74.251,
			74.268,
			74.331,
			74.361,
			74.391,
			74.455,
			74.469,
			74.516,
			74.577,
			74.639,
			74.687,
			74.7,
			74.764,
			74.799,
			74.824,
			74.903,
			74.95,
			75.011,
			75.073,
			75.12,
			75.198,
			75.229,
			75.259,
			75.323,
			75.385,
			75.447,
			75.508,
			75.556,
			75.571,
			75.632,
			75.662,
			75.694,
			75.756,
			75.771,
			75.817,
			75.879,
			75.943,
			75.987,
			76.004,
			76.066,
			76.096,
			76.129,
			76.19,
			76.253,
			76.312,
			76.376,
			76.438,
			76.501,
			76.532,
			76.561,
			76.623,
			76.639,
			76.685,
			76.749,
			76.811,
			76.862,
			76.873,
			76.933,
			76.965,
			76.997,
			77.057,
			77.074,
			77.12,
			77.183,
			77.244,
			77.307,
			77.367,
			77.399,
			77.428,
			77.492,
			77.555,
			77.618,
			77.678,
			77.726,
			77.74,
			77.802,
			77.835,
			77.863,
			77.925,
			77.942,
			77.988,
			78.05,
			78.112,
			78.16,
			78.175,
			78.238,
			78.268,
			78.299,
			78.377,
			78.422,
			78.486,
			78.547,
			78.592,
			78.671,
			78.701,
			78.733,
			78.796,
			78.855,
			78.923,
			78.981,
			79.026,
			79.043,
			79.105,
			79.134,
			79.167,
			79.227,
			79.244,
			79.291,
			79.354,
			79.416,
			79.477,
			79.539,
			79.57,
			79.602,
			79.662,
			79.679,
			79.725,
			79.787,
			79.849,
			79.894,
			79.974,
			80.004,
			80.035,
			80.112,
			80.159,
			80.225,
			80.284,
			80.329,
			80.344,
			80.406,
			80.437,
			80.47,
			80.547,
			80.592,
			80.66,
			80.718,
			80.764,
			80.842,
			80.873,
			80.902,
			80.966,
			81.026,
			81.09,
			81.15,
			81.198,
			81.276,
			81.306,
			81.336,
			81.399,
			81.415,
			81.461,
			81.524,
			81.584,
			81.631,
			81.648,
			81.708,
			81.74,
			81.77,
			81.834,
			81.849,
			81.895,
			81.957,
			82.018,
			82.067,
			82.144,
			82.172,
			82.206,
			82.268,
			82.328,
			82.392,
			82.452,
			82.515,
			82.576,
			82.607,
			82.638,
			82.701,
			82.716,
			82.764,
			82.826,
			82.887,
			82.935,
			82.949,
			83.01,
			83.044,
			83.074,
			83.135,
			83.15,
			83.195,
			83.258,
			83.321,
			83.368,
			83.445,
			83.475,
			83.506,
			83.569,
			83.632,
			83.693,
			83.755,
			83.802,
			83.819,
			83.88,
			83.911,
			83.942,
			84.005,
			84.019,
			84.066,
			84.129,
			84.188,
			84.235,
			84.251,
			84.313,
			84.346,
			84.377,
			84.437,
			84.453,
			84.5,
			84.562,
			84.622,
			84.669,
			84.746,
			84.778,
			84.811,
			84.873,
			84.932,
			84.995,
			85.056,
			85.108,
			85.12,
			85.182,
			85.214,
			85.244,
			85.304,
			85.321,
			85.369,
			85.428,
			85.491,
			85.538,
			85.553,
			85.616,
			85.647,
			85.679,
			85.74,
			85.802,
			85.865,
			85.927,
			85.971,
			86.05,
			86.079,
			86.111,
			86.172,
			86.19,
			86.237,
			86.3,
			86.36,
			86.422,
			86.485,
			86.516,
			86.545,
			86.609,
			86.623,
			86.669,
			86.733,
			86.793,
			86.856,
			86.919,
			86.95,
			86.981,
			87.042,
			87.103,
			87.167,
			87.229,
			87.276,
			87.29,
			87.352,
			87.382,
			87.415,
			87.477,
			87.491,
			87.537,
			87.601,
			87.663,
			87.709,
			87.725,
			87.787,
			87.817,
			87.849,
			87.909,
			87.925,
			87.972,
			88.036,
			88.096,
			88.142,
			88.22,
			88.25,
			88.282,
			88.345,
			88.406,
			88.468,
			88.532,
			88.593,
			88.654,
			88.684,
			88.716,
			88.777,
			88.794,
			88.839,
			88.901,
			88.964,
			89.027,
			89.088,
			89.12,
			89.15,
			89.233,
			89.274,
			89.338,
			89.4,
			89.46,
			89.522,
			89.554,
			89.586,
			89.647,
			89.709,
			89.769,
			89.833,
			89.878,
			89.894,
			89.957,
			89.987,
			90.017,
			90.081,
			90.097,
			90.144,
			90.206,
			90.267,
			90.328,
			90.39,
			90.42,
			90.453,
			90.531,
			90.576,
			90.639,
			90.7,
			90.747,
			90.825,
			90.855,
			90.888,
			90.95,
			91.011,
			91.071,
			91.135,
			91.182,
			91.195,
			91.259,
			91.293,
			91.32,
			91.382,
			91.399,
			91.443,
			91.506,
			91.567,
			91.615,
			91.694,
			91.725,
			91.755,
			91.817,
			91.877,
			91.94,
			92.003,
			92.05,
			92.066,
			92.128,
			92.158,
			92.188,
			92.252,
			92.266,
			92.311,
			92.375,
			92.437,
			92.5,
			92.562,
			92.592,
			92.623,
			92.7,
			92.746,
			92.81,
			92.87,
			92.934,
			92.994,
			93.025,
			93.057,
			93.118,
			93.181,
			93.244,
			93.305,
			93.355,
			93.367,
			93.429,
			93.46,
			93.491,
			93.569,
			93.616,
			93.679,
			93.74,
			93.8,
			93.862,
			93.893,
			93.926,
			93.986,
			94.003,
			94.049,
			94.111,
			94.174,
			94.221,
			94.298,
			94.327,
			94.36,
			94.436,
			94.483,
			94.543,
			94.608,
			94.652,
			94.669,
			94.731,
			94.76,
			94.794,
			94.871,
			94.916,
			94.981,
			95.042,
			95.104,
			95.165,
			95.196,
			95.227,
			95.305,
			95.353,
			95.419,
			95.475,
			95.522,
			95.599,
			95.629,
			95.663,
			95.724,
			95.74,
			95.787,
			95.846,
			95.909,
			95.971,
			96.033,
			96.066,
			96.094,
			96.156,
			96.174,
			96.22,
			96.282,
			96.343,
			96.391,
			96.452
		]
	],
	'9464_9465_7982': [
		[
			56.484
		],
		[
			0
		],
		[
			0,
			1.799,
			3.031,
			5.498,
			6.735,
			7.972,
			9.206,
			10.443,
			11.682,
			12.924,
			14.17,
			15.419,
			16.669,
			17.921,
			19.172,
			20.424,
			21.679,
			22.936,
			24.193,
			25.453,
			26.714,
			27.979,
			29.247,
			30.517,
			31.789,
			33.063,
			34.339,
			35.616,
			36.896,
			38.178,
			39.461,
			40.746,
			42.033,
			43.322,
			44.612,
			45.905,
			47.2,
			48.497,
			49.797,
			51.098,
			52.398,
			53.701,
			55.008,
			56.316,
			57.625,
			58.933,
			60.243,
			62.868,
			64.181,
			65.496,
			66.813,
			68.132,
			69.451,
			70.772,
			72.095,
			73.42,
			74.747,
			77.405,
			78.737,
			81.402,
			84.065,
			85.395,
			86.72,
			88.041,
			89.377,
			91.985,
			93.277,
			94.562,
			95.838,
			98.348,
			99.601,
			100.848,
			102.088,
			104.552,
			105.776,
			106.996,
			108.213,
			109.427,
			110.637,
			111.844,
			113.047,
			114.248,
			115.446,
			116.642,
			117.834,
			119.023,
			120.208,
			121.389,
			122.564,
			123.736,
			124.921,
			126.081,
			127.234,
			128.381,
			129.528,
			130.669,
			131.804,
			132.934,
			134.073,
			135.211,
			137.48,
			139.797,
			142.132,
			143.279,
			144.462,
			145.66,
			146.811,
			147.976,
			149.131,
			150.29,
			151.416,
			152.527,
			153.193,
			154.658,
			155.689,
			156.693,
			157.721,
			158.715,
			159.684,
			160.644,
			161.592,
			162.526,
			163.43,
			164.328,
			165.218,
			166.098,
			167.835,
			168.691,
			169.541,
			170.385,
			171.224,
			172.058,
			172.888,
			173.719,
			174.56,
			175.402,
			176.246,
			177.092,
			177.956,
			178.817,
			179.692,
			180.569,
			181.45,
			182.334,
			183.223,
			184.136,
			185.058,
			185.981,
			186.907,
			187.835,
			188.288,
			189.711,
			190.65,
			191.586,
			192.521,
			193.456,
			194.397,
			195.332,
			196.261,
			198.1,
			199.004,
			199.892,
			200.774,
			201.652,
			202.525,
			203.394,
			204.243,
			205.083,
			205.92,
			206.753,
			207.584,
			209.233,
			210.05,
			211.682,
			213.31,
			214.122,
			214.933,
			215.745,
			216.559,
			217.375,
			218.192,
			219.011,
			219.833,
			220.656,
			221.482,
			222.311,
			223.141,
			224.808,
			225.645,
			226.484,
			227.326,
			228.17,
			229.017,
			229.868,
			230.723,
			232.441,
			233.306,
			234.174,
			235.048,
			235.926,
			236.807,
			237.693,
			238.581,
			239.473,
			240.369,
			241.268,
			242.17,
			243.076,
			243.97,
			244.882,
			245.797,
			246.715,
			247.637,
			248.562,
			250.426,
			251.365,
			252.306,
			253.251,
			255.152,
			256.108,
			257.067,
			258.029,
			258.995,
			259.963,
			260.934,
			261.908,
			262.886,
			263.866,
			264.849,
			265.835,
			266.824,
			267.815,
			268.81,
			269.807,
			270.806,
			271.809,
			273.822,
			274.832,
			275.844,
			276.858,
			277.875,
			279.917,
			280.941,
			281.968,
			282.998,
			284.03,
			285.063,
			287.135,
			288.175,
			289.216,
			290.259,
			291.302,
			292.361,
			293.405,
			294.451,
			295.497,
			297.579,
			298.631,
			299.683,
			300.733,
			301.802,
			302.853,
			303.904,
			304.953,
			306.001,
			307.048,
			308.093,
			309.138,
			311.224,
			313.306,
			314.345,
			316.422,
			317.458,
			318.494,
			319.528,
			320.561,
			321.592,
			322.623,
			323.653,
			324.682,
			325.712,
			326.741,
			327.77,
			328.798,
			329.828,
			330.863,
			332.936,
			333.975,
			335.015,
			336.057,
			337.1,
			338.145,
			339.192,
			340.241,
			341.292,
			342.345,
			343.401,
			344.459,
			345.527,
			346.603,
			347.681,
			348.763,
			349.849,
			350.944,
			352.041,
			353.142,
			354.246,
			355.352,
			356.456,
			357.564,
			358.674,
			359.787,
			360.887,
			361.987,
			363.071,
			364.177,
			365.281,
			366.382,
			367.485,
			368.59,
			369.698,
			370.804,
			371.911,
			373.019,
			374.128,
			375.237,
			376.343,
			377.448,
			378.549,
			379.646,
			380.734,
			381.811,
			382.882,
			383.946,
			385.02,
			386.064,
			387.101,
			388.117,
			389.146,
			390.173,
			391.192,
			393.226,
			394.241,
			396.27,
			398.313,
			399.325,
			400.335,
			401.344,
			402.352,
			403.358,
			404.362,
			405.365,
			406.365,
			408.362,
			409.359,
			410.355,
			412.346,
			414.338,
			415.335,
			416.335,
			417.336,
			418.339,
			419.343,
			420.349,
			421.36,
			422.374,
			424.405,
			425.425,
			426.451,
			427.478,
			428.507,
			429.539,
			430.573,
			431.608,
			432.645,
			433.683,
			434.724,
			435.767,
			436.815,
			437.864,
			438.914,
			439.966,
			441.016,
			442.064,
			443.112,
			444.16,
			445.208,
			446.254,
			447.297,
			448.339,
			449.38,
			450.42,
			451.455,
			452.487,
			453.517,
			454.546,
			455.573,
			456.595,
			457.614,
			458.631,
			459.647,
			460.662,
			462.687,
			463.697,
			464.707,
			465.714,
			466.727,
			467.738,
			468.748,
			469.756,
			470.762,
			471.77,
			472.777,
			473.781,
			474.785,
			475.787,
			476.791,
			477.796,
			478.8,
			480.81,
			482.829,
			484.852,
			485.864,
			486.878,
			487.891,
			488.905,
			489.92,
			491.953,
			492.97,
			493.988,
			495.007,
			496.027,
			497.045,
			498.064,
			499.084,
			500.106,
			501.13,
			502.154,
			503.18,
			504.208,
			505.237,
			507.302,
			508.338,
			509.375,
			510.415,
			511.456,
			512.5,
			514.594,
			515.643,
			516.695,
			517.748,
			518.803,
			519.859,
			520.918,
			521.98,
			523.046,
			524.115,
			525.185,
			526.258,
			527.334,
			528.395,
			529.476,
			530.559,
			531.664,
			533.842,
			534.934,
			536.029,
			537.128,
			538.229,
			539.334,
			540.441,
			541.55,
			543.786,
			544.908,
			546.014,
			547.142,
			548.273,
			549.406,
			550.542,
			551.68,
			552.821,
			553.964,
			555.11,
			556.258,
			557.408,
			558.561,
			559.716,
			560.874,
			562.033,
			563.192,
			564.352,
			565.514,
			566.678,
			567.845,
			569.014,
			570.185,
			571.358,
			572.535,
			573.716,
			574.901,
			576.087,
			577.276,
			579.664,
			580.862,
			582.062,
			583.264,
			584.47,
			585.677,
			586.887,
			588.1,
			589.315,
			590.532,
			591.752,
			592.974,
			594.197,
			595.423,
			597.881,
			599.114,
			601.585,
			602.824,
			604.066,
			605.309,
			607.803,
			610.308,
			611.564,
			612.822,
			614.083,
			615.345,
			616.609,
			617.876,
			619.144,
			620.414,
			622.961,
			624.237,
			625.517,
			626.798,
			628.081,
			629.366,
			630.653,
			631.942,
			633.232,
			634.524,
			635.818,
			638.41,
			639.709,
			641.01,
			643.613,
			644.914,
			646.216,
			647.52,
			648.825,
			650.132,
			651.44,
			652.75,
			654.062,
			655.375,
			656.689,
			658.006,
			659.325,
			660.646,
			661.968,
			663.293,
			664.619,
			665.948,
			667.278,
			669.944,
			672.618,
			673.958,
			675.3,
			676.643,
			677.989,
			679.336,
			680.685,
			682.037,
			683.389,
			684.742,
			686.095,
			687.448,
			688.799,
			690.146,
			691.487,
			692.823,
			694.151,
			695.471,
			696.782,
			698.084,
			699.377,
			700.66,
			701.935,
			703.2,
			704.455,
			705.701,
			706.938,
			708.165,
			709.383,
			710.591,
			711.79,
			712.981,
			714.163,
			715.336,
			716.5,
			717.656,
			718.802,
			719.938,
			721.066,
			722.184,
			723.293,
			724.392,
			725.482,
			726.562,
			727.633,
			728.694,
			729.746,
			730.788,
			731.82,
			732.843,
			733.857,
			734.861,
			735.856,
			736.842,
			737.818,
			738.786,
			739.744,
			740.694,
			741.635,
			742.567,
			743.491,
			744.407,
			745.315,
			747.107,
			747.991,
			748.868,
			749.752,
			750.613,
			752.315,
			753.157,
			753.994,
			754.827,
			755.656,
			756.48,
			757.303,
			758.123,
			758.943,
			759.763,
			760.582,
			761.4,
			762.219,
			763.039,
			763.86,
			765.506,
			766.331,
			767.157,
			767.985,
			768.813,
			769.643,
			770.476,
			771.311,
			772.148,
			773.823,
			774.663,
			775.509,
			776.361,
			777.213,
			778.067,
			778.923,
			779.78,
			780.653,
			781.534,
			782.414,
			783.295,
			784.177,
			785.061,
			785.96,
			786.856,
			787.75,
			788.641,
			789.532,
			790.421,
			791.306,
			792.186,
			793.063,
			793.936,
			794.807,
			795.672,
			796.53,
			797.385,
			798.237,
			799.086,
			799.933,
			800.779,
			801.62,
			802.459,
			803.295,
			804.129,
			805.793,
			806.625,
			807.454,
			808.281,
			809.929,
			810.751,
			811.571,
			812.389,
			813.206,
			814.021,
			814.834,
			815.647,
			816.459,
			817.271,
			818.083,
			819.708,
			820.52,
			821.335,
			822.149,
			822.964,
			823.78,
			824.596,
			825.412,
			826.227,
			827.043,
			827.859,
			828.676,
			829.493,
			830.311,
			831.127,
			831.943,
			832.758,
			833.574,
			834.39,
			835.206,
			836.026,
			836.848,
			837.672,
			838.496,
			840.148,
			840.978,
			841.812,
			842.647,
			843.484,
			844.323,
			845.164,
			846.005,
			846.847,
			847.691,
			848.537,
			849.386,
			850.223,
			851.078,
			851.936,
			852.798,
			853.664,
			854.532,
			855.404,
			856.28,
			857.161,
			858.044,
			858.932,
			859.822,
			860.715,
			861.612,
			862.512,
			863.415,
			864.319,
			865.226,
			866.134,
			867.042,
			867.95,
			869.762,
			870.679,
			871.575,
			872.464,
			873.347,
			875.096,
			875.962,
			876.82,
			877.673,
			878.522,
			879.369,
			880.213,
			881.056,
			881.895,
			882.73,
			883.565,
			884.398,
			885.229,
			886.06,
			886.89,
			887.718,
			888.544,
			889.369,
			890.193,
			891.016,
			891.838,
			892.659,
			893.479,
			894.297,
			895.931,
			896.746,
			897.562,
			898.377,
			899.191,
			900.005,
			900.818,
			901.631,
			902.445,
			903.259,
			904.072,
			904.885,
			905.698,
			906.511,
			907.326,
			908.142,
			908.958,
			910.59,
			911.407,
			912.23,
			913.056,
			913.883,
			914.711,
			916.37,
			917.205,
			918.043,
			918.882,
			919.722,
			920.562,
			921.404,
			922.248,
			923.091,
			923.934,
			924.778,
			926.469,
			927.318,
			929.022,
			929.875,
			930.729,
			931.584,
			932.441,
			933.297,
			934.155,
			935.013,
			935.872,
			936.734,
			937.592,
			938.448,
			940.166,
			941.891,
			942.758,
			943.627,
			944.497,
			945.369,
			946.243,
			947.117,
			948.003,
			948.888,
			949.774,
			950.662,
			951.553,
			952.45,
			953.354,
			954.26,
			955.167,
			956.075,
			956.986,
			957.902,
			958.819,
			959.736,
			960.654,
			961.573,
			962.493,
			963.413,
			964.333,
			965.252,
			966.171,
			967.089,
			968.917,
			969.829,
			970.742,
			971.654,
			972.566,
			973.477,
			974.387,
			975.297,
			976.207,
			977.117,
			978.026,
			978.935,
			979.844,
			980.753,
			981.662,
			982.532,
			983.483,
			984.395,
			985.306,
			986.218,
			987.131,
			988.044,
			988.959,
			989.875,
			990.793,
			991.711,
			992.632,
			993.559,
			994.49,
			995.423,
			996.359,
			998.243,
			999.193,
			1000.146,
			1001.102,
			1002.062,
			1003.025,
			1003.991,
			1004.96,
			1006.908,
			1007.887,
			1008.868,
			1009.852,
			1010.839,
			1011.83,
			1012.823,
			1013.823,
			1014.826,
			1015.815,
			1016.823,
			1017.835,
			1018.851,
			1019.871,
			1020.895,
			1021.921,
			1022.949,
			1023.983,
			1025.021,
			1026.062,
			1027.105,
			1028.151,
			1029.201,
			1030.253,
			1031.305,
			1032.374,
			1034.478,
			1035.515,
			1036.572,
			1037.633,
			1038.695,
			1039.76,
			1040.828,
			1041.897,
			1042.969,
			1044.044,
			1045.121,
			1046.2,
			1047.282,
			1048.366,
			1050.538,
			1051.628,
			1052.721,
			1053.815,
			1054.911,
			1056.009,
			1057.11,
			1058.213,
			1059.319,
			1060.427,
			1061.537,
			1062.649,
			1063.764,
			1064.883,
			1066.004,
			1067.128,
			1068.254,
			1069.383,
			1070.515,
			1071.649,
			1072.786,
			1073.925,
			1075.067,
			1076.212,
			1077.359,
			1078.508,
			1080.814,
			1081.971,
			1083.13,
			1084.292,
			1085.456,
			1086.623,
			1087.792,
			1088.964,
			1090.138,
			1091.314,
			1092.493,
			1093.674,
			1094.858,
			1096.044,
			1097.232,
			1098.423,
			1099.611,
			1100.81,
			1103.207,
			1104.408,
			1106.821,
			1108.03,
			1109.242,
			1110.456,
			1111.673,
			1112.892,
			1115.336,
			1116.56,
			1117.786,
			1119.015,
			1120.246,
			1121.478,
			1122.712,
			1123.949,
			1125.187,
			1126.428,
			1127.671,
			1128.916,
			1130.163,
			1131.413,
			1132.666,
			1133.92,
			1135.177,
			1137.696,
			1138.959,
			1140.223,
			1141.489,
			1142.756,
			1144.026,
			1145.297,
			1146.57,
			1147.844,
			1149.121,
			1150.4,
			1151.681,
			1152.964,
			1154.248,
			1155.535,
			1156.824,
			1158.116,
			1159.409,
			1160.704,
			1162.001,
			1163.3,
			1164.601,
			1165.903,
			1167.205,
			1168.506,
			1169.809,
			1172.419,
			1173.727,
			1175.037,
			1176.348,
			1178.976,
			1180.293,
			1181.612,
			1182.932,
			1184.254,
			1185.577,
			1186.902,
			1188.229,
			1190.888,
			1192.22,
			1193.554,
			1194.89,
			1196.228,
			1197.567,
			1198.908,
			1200.25,
			1201.594,
			1202.94,
			1204.288,
			1205.637,
			1206.988,
			1208.34,
			1209.694,
			1212.408,
			1213.767,
			1215.128,
			1216.491,
			1217.855,
			1219.221,
			1220.588,
			1221.957,
			1223.328,
			1224.7,
			1226.074,
			1227.45,
			1228.827,
			1230.207,
			1231.588,
			1232.971,
			1235.742,
			1237.13,
			1238.52,
			1239.912,
			1241.306,
			1242.701,
			1244.099,
			1245.498,
			1246.899,
			1248.303,
			1249.708,
			1251.116,
			1252.525,
			1253.936,
			1256.765,
			1258.182,
			1259.602,
			1261.023,
			1262.447,
			1263.872,
			1265.298,
			1266.727,
			1268.157,
			1269.589,
			1271.022,
			1272.457,
			1273.894,
			1275.333,
			1276.774,
			1278.216,
			1279.659,
			1281.104,
			1282.551,
			1283.999,
			1285.449,
			1286.901,
			1288.354,
			1289.81,
			1291.267,
			1292.725,
			1294.185,
			1295.647,
			1297.11,
			1298.575,
			1300.04,
			1301.507,
			1304.446,
			1305.917,
			1307.391,
			1308.865,
			1310.342,
			1311.82,
			1313.299,
			1314.781,
			1316.264,
			1317.748,
			1319.234,
			1320.723,
			1322.213,
			1323.704,
			1325.197,
			1326.69,
			1328.185,
			1329.682,
			1331.178,
			1332.674,
			1335.661,
			1338.632,
			1340.108,
			1341.576,
			1343.035,
			1344.484,
			1345.924,
			1347.355,
			1348.776,
			1350.187,
			1351.589,
			1352.981,
			1354.364,
			1357.101,
			1358.456,
			1359.801,
			1361.136,
			1362.462,
			1363.778,
			1365.085,
			1366.382,
			1367.67,
			1368.948,
			1370.217,
			1371.477,
			1372.728,
			1373.969,
			1375.201,
			1376.425,
			1377.64,
			1378.846,
			1380.044,
			1382.412,
			1383.584,
			1384.747,
			1385.9,
			1387.043,
			1388.176,
			1389.3,
			1391.519,
			1392.614,
			1393.7,
			1394.776,
			1395.843,
			1396.901,
			1397.95,
			1398.989,
			1400.02,
			1401.041,
			1402.053,
			1403.056,
			1404.05,
			1405.034,
			1406.009,
			1406.975,
			1407.93,
			1408.876,
			1409.813,
			1410.741,
			1411.661,
			1412.572,
			1413.474,
			1414.369,
			1415.255,
			1416.132,
			1417.001,
			1417.862,
			1418.728,
			1419.57,
			1420.404,
			1421.229,
			1422.045,
			1422.852,
			1423.65,
			1424.44,
			1425.222,
			1426.76,
			1427.514,
			1428.26,
			1428.997,
			1429.727,
			1430.448,
			1431.162,
			1431.868,
			1432.566,
			1433.258,
			1433.944,
			1434.626,
			1435.304,
			1435.979,
			1436.652,
			1437.315,
			1437.975,
			1438.635,
			1439.296,
			1439.957,
			1440.618,
			1441.279,
			1441.941,
			1442.601,
			1443.263,
			1443.925,
			1445.253,
			1445.919,
			1446.587,
			1447.258,
			1447.931,
			1448.606,
			1449.281,
			1449.959,
			1450.638,
			1451.32,
			1452.005,
			1452.694,
			1453.385,
			1454.077,
			1454.771,
			1455.467,
			1456.164,
			1457.576,
			1458.287,
			1459.714,
			1460.43,
			1461.147,
			1461.867,
			1462.607,
			1463.347,
			1464.087,
			1465.569,
			1466.312,
			1466.792,
			1467.81,
			1468.56,
			1470.055,
			1471.548,
			1472.29,
			1473.025,
			1473.759,
			1474.491,
			1475.222,
			1475.953,
			1476.684,
			1477.406,
			1478.123,
			1479.557,
			1480.275,
			1480.993,
			1481.711,
			1483.133,
			1483.843,
			1484.555,
			1485.268,
			1485.983,
			1486.699,
			1487.417,
			1488.136,
			1488.857,
			1489.579,
			1490.302,
			1491.016,
			1491.744,
			1492.471,
			1493.198,
			1493.927,
			1494.661,
			1495.399,
			1496.141,
			1496.886,
			1497.635,
			1499.144,
			1500.668,
			1501.435,
			1502.207,
			1502.98,
			1504.537,
			1505.321,
			1506.108,
			1506.9,
			1507.693,
			1508.489,
			1509.289,
			1510.092,
			1510.899,
			1511.71,
			1512.524,
			1514.163,
			1514.988,
			1515.817,
			1516.649,
			1518.323,
			1519.162,
			1520.84,
			1521.681,
			1522.527,
			1523.377,
			1524.231,
			1525.088,
			1525.949,
			1526.812,
			1527.679,
			1528.564,
			1529.438,
			1530.313,
			1531.19,
			1532.069,
			1532.948,
			1533.832,
			1534.715,
			1535.599,
			1536.482,
			1537.364,
			1538.251,
			1539.146,
			1540.039,
			1540.932,
			1541.825,
			1542.717,
			1543.619,
			1544.524,
			1545.427,
			1546.328,
			1547.228,
			1548.128,
			1549.036,
			1549.943,
			1551.749,
			1552.65,
			1553.55,
			1554.449,
			1555.345,
			1556.238,
			1557.13,
			1558.02,
			1558.91,
			1559.798,
			1560.682,
			1561.565,
			1563.262,
			1564.206,
			1565.084,
			1565.96,
			1566.833,
			1567.706,
			1568.576,
			1570.305,
			1571.167,
			1572.027,
			1572.886,
			1573.745,
			1574.604,
			1575.46,
			1576.315,
			1577.169,
			1578.022,
			1578.873,
			1579.721,
			1580.567,
			1581.412,
			1582.257,
			1583.1,
			1584.784,
			1585.625,
			1586.465,
			1587.305,
			1588.144,
			1588.985,
			1589.826,
			1590.667,
			1591.507,
			1592.346,
			1593.186,
			1594.025,
			1594.864,
			1595.703,
			1596.542,
			1597.382,
			1598.222,
			1599.063,
			1599.906,
			1600.75,
			1601.594,
			1602.441,
			1603.289,
			1604.14,
			1604.994,
			1605.85,
			1606.709,
			1607.571,
			1608.435,
			1609.305,
			1610.18,
			1611.058,
			1612.825,
			1613.681,
			1614.608,
			1615.507,
			1616.409,
			1617.315,
			1618.224,
			1619.137,
			1620.051,
			1620.969,
			1621.89,
			1622.815,
			1623.743,
			1624.673,
			1625.608,
			1626.545,
			1628.413,
			1629.361,
			1630.313,
			1631.269,
			1632.227,
			1633.189,
			1634.154,
			1635.125,
			1636.098,
			1637.075,
			1638.055,
			1639.037,
			1641.013,
			1642.005,
			1643,
			1643.998,
			1645,
			1646.004,
			1647.011,
			1648.021,
			1649.035,
			1650.051,
			1651.07,
			1652.091,
			1653.116,
			1654.143,
			1655.174,
			1656.208,
			1657.244,
			1658.283,
			1659.325,
			1660.369,
			1661.416,
			1662.466,
			1663.517,
			1664.568,
			1665.617,
			1666.666,
			1667.716,
			1668.769,
			1669.823,
			1670.881,
			1671.942,
			1673.004,
			1674.069,
			1675.136,
			1676.205,
			1677.276,
			1678.35,
			1680.504,
			1681.584,
			1682.666,
			1683.75,
			1684.837,
			1685.925,
			1687.016,
			1688.108,
			1690.301,
			1691.401,
			1692.503,
			1694.715,
			1695.825,
			1696.938,
			1698.054,
			1699.172,
			1700.292,
			1701.414,
			1702.539,
			1703.666,
			1704.795,
			1705.927,
			1707.061,
			1708.197,
			1709.336,
			1710.478,
			1711.622,
			1712.769,
			1715.069,
			1716.224,
			1718.539,
			1719.7,
			1720.863,
			1722.028,
			1723.195,
			1724.365,
			1725.537,
			1726.711,
			1727.887,
			1729.066,
			1730.246,
			1731.431,
			1732.617,
			1733.806,
			1734.997,
			1736.191,
			1737.388,
			1738.586,
			1739.787,
			1740.991,
			1742.196,
			1743.403,
			1744.613,
			1747.038,
			1748.254,
			1749.472,
			1750.692,
			1751.914,
			1753.137,
			1754.363,
			1755.591,
			1756.821,
			1758.053,
			1759.288,
			1760.524,
			1763.002,
			1764.243,
			1765.483,
			1766.72,
			1767.954,
			1769.182,
			1770.404,
			1771.618,
			1772.823,
			1774.019,
			1775.206,
			1776.383,
			1777.55,
			1778.708,
			1780.996,
			1782.125,
			1783.245,
			1784.355,
			1785.457,
			1786.549,
			1787.631,
			1788.704,
			1789.768,
			1790.824,
			1791.87,
			1792.908,
			1793.937,
			1794.958,
			1795.97,
			1796.973,
			1797.968,
			1798.954,
			1799.932,
			1800.902,
			1801.88,
			1802.834,
			1803.782,
			1804.726,
			1805.665,
			1806.599,
			1807.528,
			1809.379,
			1810.302,
			1811.223,
			1812.143,
			1813.061,
			1813.979,
			1814.897,
			1815.814,
			1816.732,
			1817.649,
			1818.566,
			1819.484,
			1820.401,
			1821.319,
			1822.236,
			1823.152,
			1824.07,
			1824.988,
			1825.906,
			1826.825,
			1827.746,
			1828.667,
			1829.588,
			1830.509,
			1831.431,
			1832.353,
			1833.276,
			1834.2,
			1835.125,
			1836.051,
			1836.978,
			1837.906,
			1838.835,
			1839.764,
			1840.694,
			1842.56,
			1843.496,
			1844.434,
			1845.372,
			1847.252,
			1848.205,
			1849.16,
			1850.115,
			1851.072,
			1852.03,
			1853.963,
			1854.931,
			1855.9,
			1856.871,
			1857.847,
			1858.827,
			1859.807,
			1860.788,
			1861.769,
			1862.752,
			1863.734,
			1864.716,
			1866.68,
			1868.651,
			1869.637,
			1870.623,
			1871.608,
			1872.593,
			1873.576,
			1874.558,
			1875.54,
			1876.522,
			1877.336,
			1878.479,
			1880.432,
			1881.407,
			1882.384,
			1883.355,
			1884.327,
			1885.299,
			1886.271,
			1887.243,
			1888.214,
			1889.185,
			1890.156,
			1891.127,
			1892.099,
			1893.071,
			1894.044,
			1895.017,
			1895.989,
			1896.961,
			1897.933,
			1898.904,
			1899.875,
			1900.845,
			1902.784,
			1903.753,
			1904.72,
			1905.686,
			1906.652,
			1907.617,
			1908.584,
			1910.518,
			1911.487,
			1912.457,
			1913.43,
			1915.385,
			1917.348,
			1918.334,
			1919.324,
			1920.317,
			1921.313,
			1922.311,
			1923.313,
			1924.32,
			1925.33,
			1927.358,
			1928.376,
			1929.399,
			1930.424,
			1931.452,
			1932.483,
			1933.516,
			1934.553,
			1935.593,
			1936.634,
			1937.661,
			1938.707,
			1939.756,
			1940.806,
			1941.859,
			1942.915,
			1943.972,
			1946.094,
			1947.158,
			1948.225,
			1949.294,
			1950.365,
			1951.438,
			1953.611,
			1954.691,
			1955.756,
			1956.841,
			1957.929,
			1959.019,
			1960.112,
			1961.207,
			1962.304,
			1964.509,
			1965.614,
			1966.718,
			1967.839,
			1968.92,
			1970.016,
			1971.105,
			1972.186,
			1973.259,
			1974.322,
			1975.376,
			1976.42,
			1977.456,
			1978.481,
			1979.498,
			1980.506,
			1981.504,
			1982.493,
			1983.473,
			1984.445,
			1985.407,
			1986.361,
			1987.305,
			1988.24,
			1989.167,
			1990.085,
			1991.896,
			1992.79,
			1993.674,
			1995.417,
			1996.275,
			1997.123,
			1997.962,
			1998.792,
			1999.613,
			2000.424,
			2001.227,
			2002.02,
			2002.804,
			2003.579,
			2004.345,
			2005.102,
			2005.849,
			2006.588,
			2008.037,
			2008.749,
			2009.449,
			2010.14,
			2010.823,
			2012.166,
			2012.825,
			2013.478,
			2014.123,
			2014.762,
			2015.397,
			2016.027,
			2016.653,
			2017.286,
			2018.523,
			2019.137,
			2019.748,
			2020.358,
			2020.966,
			2021.573,
			2022.177,
			2022.78,
			2023.381,
			2023.978,
			2024.569,
			2025.158,
			2025.746,
			2026.333,
			2026.918,
			2027.501,
			2028.084,
			2028.666,
			2029.245,
			2029.823,
			2030.401,
			2030.978,
			2031.556,
			2032.134,
			2032.711,
			2033.29,
			2034.458,
			2035.045,
			2035.633,
			2036.221,
			2036.81,
			2037.4,
			2037.992,
			2038.586,
			2039.189,
			2039.797,
			2040.406,
			2041.015,
			2041.625,
			2042.237,
			2043.468,
			2044.092,
			2045.36,
			2045.994,
			2046.628,
			2047.265,
			2047.903,
			2048.545,
			2049.197,
			2049.856,
			2050.514,
			2051.172,
			2051.829,
			2052.488,
			2053.149,
			2053.812,
			2054.47,
			2055.127,
			2055.784,
			2056.44,
			2057.097,
			2057.755,
			2058.415,
			2058.905,
			2059.74,
			2060.403,
			2061.065,
			2061.727,
			2062.389,
			2063.051,
			2064.383,
			2065.722,
			2066.39,
			2067.724,
			2068.392,
			2069.042,
			2069.745,
			2070.427,
			2071.783,
			2072.459,
			2073.133,
			2073.807,
			2074.479,
			2075.149,
			2075.816,
			2076.48,
			2077.143,
			2077.805,
			2078.466,
			2079.126,
			2079.781,
			2080.434,
			2081.087,
			2081.739,
			2082.392,
			2083.698,
			2085.007,
			2085.663,
			2086.319,
			2086.977,
			2087.636,
			2088.958,
			2089.623,
			2090.291,
			2090.961,
			2091.634,
			2092.31,
			2092.988,
			2093.67,
			2094.354,
			2095.044,
			2095.737,
			2096.433,
			2097.837,
			2098.544,
			2099.255,
			2099.972,
			2100.694,
			2102.148,
			2103.617,
			2104.358,
			2105.107,
			2105.861,
			2107.382,
			2108.148,
			2108.918,
			2109.693,
			2110.477,
			2111.264,
			2112.055,
			2113.65,
			2114.453,
			2115.266,
			2116.083,
			2116.903,
			2117.727,
			2118.555,
			2119.387,
			2120.221,
			2121.043,
			2121.883,
			2123.56,
			2124.399,
			2125.239,
			2126.082,
			2126.929,
			2128.633,
			2129.489,
			2130.351,
			2131.215,
			2132.083,
			2132.955,
			2133.829,
			2134.707,
			2135.588,
			2136.473,
			2137.36,
			2139.145,
			2140.945,
			2141.851,
			2142.76,
			2143.672,
			2144.587,
			2145.508,
			2146.434,
			2147.362,
			2148.293,
			2149.228,
			2150.167,
			2151.11,
			2152.057,
			2153.006,
			2153.959,
			2154.915,
			2155.871,
			2156.83,
			2157.792,
			2158.757,
			2159.726,
			2160.694,
			2161.665,
			2162.638,
			2164.595,
			2165.576,
			2166.558,
			2167.543,
			2168.531,
			2169.522,
			2170.518,
			2171.517,
			2172.519,
			2174.532,
			2175.545,
			2176.564,
			2177.586,
			2178.61,
			2179.637,
			2180.667,
			2181.7,
			2182.735,
			2183.773,
			2184.814,
			2186.901,
			2187.948,
			2188.996,
			2190.044,
			2192.136,
			2193.183,
			2194.231,
			2195.283,
			2196.337,
			2197.393,
			2198.451,
			2199.513,
			2200.576,
			2202.717,
			2203.791,
			2204.867,
			2205.946,
			2207.027,
			2208.111,
			2209.197,
			2210.285,
			2211.376,
			2212.468,
			2213.562,
			2215.759,
			2216.861,
			2217.966,
			2219.073,
			2220.183,
			2222.409,
			2223.525,
			2224.644,
			2225.766,
			2226.888,
			2228.013,
			2229.141,
			2230.271,
			2231.403,
			2232.539,
			2233.677,
			2234.817,
			2235.96,
			2237.109,
			2238.261,
			2239.414,
			2240.57,
			2241.728,
			2242.886,
			2244.048,
			2245.212,
			2246.379,
			2247.551,
			2248.725,
			2251.076,
			2252.237,
			2253.4,
			2254.565,
			2255.732,
			2256.902,
			2258.087,
			2260.484,
			2261.687,
			2262.891,
			2264.098,
			2265.31,
			2266.388
		],
		[
			0,
			0.019,
			0.066,
			0.094,
			0.125,
			0.157,
			0.186,
			0.204,
			0.218,
			0.265,
			0.279,
			0.296,
			0.333,
			0.346,
			0.378,
			0.405,
			0.423,
			0.451,
			0.485,
			0.499,
			0.529,
			0.544,
			0.577,
			0.608,
			0.629,
			0.643,
			0.675,
			0.706,
			0.736,
			0.75,
			0.782,
			0.814,
			0.827,
			0.843,
			0.874,
			0.905,
			0.941,
			0.956,
			0.974,
			1.006,
			1.021,
			1.049,
			1.079,
			1.095,
			1.127,
			1.157,
			1.173,
			1.225,
			1.255,
			1.286,
			1.304,
			1.319,
			1.345,
			1.378,
			1.394,
			1.43,
			1.451,
			1.497,
			1.541,
			1.571,
			1.619,
			1.649,
			1.691,
			1.709,
			1.724,
			1.771,
			1.802,
			1.833,
			1.865,
			1.9,
			1.932,
			1.962,
			1.972,
			2.021,
			2.052,
			2.083,
			2.098,
			2.129,
			2.161,
			2.176,
			2.196,
			2.228,
			2.245,
			2.286,
			2.301,
			2.333,
			2.362,
			2.38,
			2.394,
			2.424,
			2.457,
			2.473,
			2.499,
			2.529,
			2.545,
			2.581,
			2.593,
			2.632,
			2.648,
			2.678,
			2.725,
			2.771,
			2.831,
			2.847,
			2.878,
			2.895,
			2.929,
			2.945,
			2.977,
			3.003,
			3.034,
			3.049,
			3.078,
			3.094,
			3.13,
			3.145,
			3.178,
			3.208,
			3.228,
			3.245,
			3.276,
			3.306,
			3.321,
			3.351,
			3.384,
			3.4,
			3.444,
			3.476,
			3.507,
			3.524,
			3.556,
			3.572,
			3.6,
			3.632,
			3.649,
			3.681,
			3.695,
			3.725,
			3.76,
			3.776,
			3.806,
			3.823,
			3.852,
			3.884,
			3.898,
			3.929,
			3.945,
			3.976,
			4.008,
			4.036,
			4.052,
			4.073,
			4.102,
			4.135,
			4.151,
			4.18,
			4.195,
			4.225,
			4.25,
			4.295,
			4.329,
			4.344,
			4.384,
			4.418,
			4.434,
			4.45,
			4.479,
			4.494,
			4.528,
			4.557,
			4.571,
			4.619,
			4.651,
			4.701,
			4.746,
			4.779,
			4.805,
			4.82,
			4.851,
			4.883,
			4.899,
			4.929,
			4.945,
			4.975,
			5.002,
			5.034,
			5.05,
			5.098,
			5.135,
			5.166,
			5.179,
			5.195,
			5.232,
			5.257,
			5.274,
			5.318,
			5.35,
			5.382,
			5.397,
			5.434,
			5.447,
			5.478,
			5.509,
			5.526,
			5.555,
			5.571,
			5.601,
			5.629,
			5.645,
			5.678,
			5.708,
			5.723,
			5.745,
			5.775,
			5.824,
			5.853,
			5.87,
			5.902,
			5.944,
			5.977,
			6.008,
			6.039,
			6.058,
			6.073,
			6.103,
			6.12,
			6.168,
			6.182,
			6.214,
			6.23,
			6.244,
			6.276,
			6.309,
			6.324,
			6.352,
			6.384,
			6.429,
			6.444,
			6.477,
			6.506,
			6.523,
			6.574,
			6.604,
			6.621,
			6.651,
			6.691,
			6.709,
			6.745,
			6.781,
			6.812,
			6.828,
			6.843,
			6.888,
			6.902,
			6.92,
			6.95,
			6.994,
			7.041,
			7.058,
			7.074,
			7.103,
			7.12,
			7.151,
			7.182,
			7.216,
			7.227,
			7.249,
			7.278,
			7.322,
			7.368,
			7.399,
			7.445,
			7.478,
			7.515,
			7.529,
			7.545,
			7.575,
			7.6,
			7.633,
			7.649,
			7.679,
			7.694,
			7.725,
			7.757,
			7.773,
			7.813,
			7.845,
			7.876,
			7.911,
			7.929,
			7.959,
			7.976,
			8.004,
			8.033,
			8.049,
			8.08,
			8.094,
			8.129,
			8.146,
			8.178,
			8.21,
			8.226,
			8.262,
			8.276,
			8.317,
			8.332,
			8.348,
			8.379,
			8.394,
			8.423,
			8.445,
			8.479,
			8.51,
			8.524,
			8.545,
			8.576,
			8.593,
			8.622,
			8.656,
			8.669,
			8.702,
			8.732,
			8.76,
			8.777,
			8.808,
			8.823,
			8.854,
			8.886,
			8.902,
			8.919,
			8.95,
			8.979,
			8.997,
			9.043,
			9.058,
			9.077,
			9.107,
			9.123,
			9.156,
			9.185,
			9.201,
			9.263,
			9.278,
			9.325,
			9.371,
			9.406,
			9.439,
			9.454,
			9.47,
			9.514,
			9.528,
			9.559,
			9.592,
			9.62,
			9.653,
			9.684,
			9.722,
			9.768,
			9.796,
			9.827,
			9.858,
			9.873,
			9.899,
			9.932,
			9.946,
			9.977,
			10.035,
			10.052,
			10.083,
			10.099,
			10.128,
			10.158,
			10.175,
			10.204,
			10.22,
			10.251,
			10.283,
			10.298,
			10.333,
			10.35,
			10.381,
			10.395,
			10.422,
			10.452,
			10.468,
			10.515,
			10.531,
			10.565,
			10.577,
			10.608,
			10.634,
			10.646,
			10.679,
			10.696,
			10.729,
			10.749,
			10.781,
			10.812,
			10.825,
			10.858,
			10.887,
			10.904,
			10.949,
			10.979,
			10.996,
			11.027,
			11.062,
			11.076,
			11.107,
			11.134,
			11.151,
			11.182,
			11.196,
			11.242,
			11.258,
			11.275,
			11.307,
			11.322,
			11.355,
			11.394,
			11.45,
			11.496,
			11.529,
			11.559,
			11.575,
			11.608,
			11.637,
			11.67,
			11.698,
			11.728,
			11.745,
			11.77,
			11.801,
			11.832,
			11.848,
			11.878,
			11.906,
			11.918,
			11.953,
			11.983,
			12,
			12.047,
			12.078,
			12.094,
			12.122,
			12.155,
			12.169,
			12.222,
			12.253,
			12.269,
			12.312,
			12.329,
			12.358,
			12.373,
			12.4,
			12.431,
			12.448,
			12.478,
			12.512,
			12.534,
			12.551,
			12.584,
			12.599,
			12.625,
			12.674,
			12.705,
			12.721,
			12.75,
			12.783,
			12.797,
			12.829,
			12.849,
			12.895,
			12.929,
			12.953,
			12.969,
			12.999,
			13.029,
			13.062,
			13.077,
			13.108,
			13.123,
			13.162,
			13.177,
			13.209,
			13.225,
			13.252,
			13.268,
			13.312,
			13.329,
			13.345,
			13.375,
			13.406,
			13.422,
			13.453,
			13.492,
			13.507,
			13.525,
			13.553,
			13.58,
			13.595,
			13.626,
			13.673,
			13.704,
			13.718,
			13.75,
			13.776,
			13.806,
			13.821,
			13.856,
			13.873,
			13.905,
			13.921,
			13.951,
			13.984,
			14.012,
			14.044,
			14.075,
			14.121,
			14.155,
			14.186,
			14.204,
			14.249,
			14.296,
			14.343,
			14.359,
			14.374,
			14.407,
			14.435,
			14.45,
			14.482,
			14.499,
			14.544,
			14.592,
			14.609,
			14.624,
			14.654,
			14.67,
			14.7,
			14.734,
			14.752,
			14.782,
			14.799,
			14.846,
			14.892,
			14.902,
			14.95,
			14.981,
			14.995,
			15.035,
			15.051,
			15.083,
			15.1,
			15.128,
			15.162,
			15.176,
			15.207,
			15.232,
			15.253,
			15.278,
			15.294,
			15.324,
			15.35,
			15.383,
			15.413,
			15.444,
			15.494,
			15.535,
			15.55,
			15.582,
			15.595,
			15.627,
			15.659,
			15.679,
			15.695,
			15.725,
			15.758,
			15.781,
			15.811,
			15.829,
			15.852,
			15.884,
			15.912,
			15.929,
			15.944,
			15.98,
			15.994,
			16.025,
			16.057,
			16.075,
			16.094,
			16.123,
			16.155,
			16.168,
			16.202,
			16.23,
			16.261,
			16.292,
			16.308,
			16.323,
			16.356,
			16.388,
			16.404,
			16.418,
			16.449,
			16.479,
			16.496,
			16.528,
			16.557,
			16.59,
			16.609,
			16.626,
			16.657,
			16.672,
			16.717,
			16.731,
			16.746,
			16.792,
			16.807,
			16.823,
			16.856,
			16.869,
			16.902,
			16.938,
			16.955,
			16.971,
			17.002,
			17.029,
			17.044,
			17.098,
			17.129,
			17.145,
			17.176,
			17.209,
			17.252,
			17.283,
			17.302,
			17.332,
			17.348,
			17.379,
			17.394,
			17.433,
			17.45,
			17.481,
			17.498,
			17.527,
			17.552,
			17.584,
			17.598,
			17.645,
			17.682,
			17.713,
			17.726,
			17.758,
			17.774,
			17.805,
			17.821,
			17.866,
			17.895,
			17.927,
			17.944,
			17.992,
			18.006,
			18.022,
			18.054,
			18.085,
			18.101,
			18.131,
			18.147,
			18.18,
			18.197,
			18.228,
			18.245,
			18.272,
			18.303,
			18.323,
			18.35,
			18.382,
			18.397,
			18.428,
			18.457,
			18.474,
			18.513,
			18.527,
			18.544,
			18.576,
			18.602,
			18.633,
			18.646,
			18.677,
			18.695,
			18.729,
			18.756,
			18.771,
			18.824,
			18.857,
			18.873,
			18.896,
			18.945,
			18.985,
			19.017,
			19.033,
			19.048,
			19.078,
			19.11,
			19.127,
			19.159,
			19.176,
			19.206,
			19.248,
			19.278,
			19.295,
			19.326,
			19.356,
			19.372,
			19.406,
			19.428,
			19.46,
			19.473,
			19.506,
			19.528,
			19.559,
			19.575,
			19.607,
			19.622,
			19.651,
			19.669,
			19.699,
			19.741,
			19.758,
			19.776,
			19.805,
			19.822,
			19.872,
			19.901,
			19.919,
			19.965,
			19.979,
			19.995,
			20.027,
			20.062,
			20.077,
			20.109,
			20.126,
			20.153,
			20.182,
			20.215,
			20.231,
			20.245,
			20.291,
			20.307,
			20.323,
			20.353,
			20.375,
			20.406,
			20.422,
			20.452,
			20.481,
			20.495,
			20.526,
			20.549,
			20.583,
			20.599,
			20.629,
			20.645,
			20.69,
			20.72,
			20.767,
			20.779,
			20.812,
			20.841,
			20.873,
			20.904,
			20.919,
			20.966,
			20.982,
			21.003,
			21.019,
			21.052,
			21.082,
			21.104,
			21.121,
			21.151,
			21.183,
			21.195,
			21.228,
			21.245,
			21.282,
			21.304,
			21.32,
			21.368,
			21.382,
			21.395,
			21.432,
			21.448,
			21.496,
			21.525,
			21.556,
			21.589,
			21.617,
			21.632,
			21.649,
			21.678,
			21.696,
			21.731,
			21.746,
			21.777,
			21.808,
			21.825,
			21.854,
			21.87,
			21.9,
			21.946,
			21.978,
			21.993,
			22.026,
			22.059,
			22.076,
			22.132,
			22.162,
			22.179,
			22.195,
			22.225,
			22.246,
			22.278,
			22.307,
			22.324,
			22.357,
			22.373,
			22.419,
			22.45,
			22.496,
			22.526,
			22.56,
			22.576,
			22.606,
			22.622,
			22.652,
			22.683,
			22.713,
			22.729,
			22.762,
			22.776,
			22.823,
			22.873,
			22.908,
			22.922,
			22.953,
			22.981,
			23.011,
			23.027,
			23.048,
			23.083,
			23.1,
			23.131,
			23.145,
			23.191,
			23.206,
			23.221,
			23.254,
			23.269,
			23.298,
			23.329,
			23.345,
			23.383,
			23.399,
			23.429,
			23.446,
			23.476,
			23.504,
			23.523,
			23.553,
			23.586,
			23.625,
			23.657,
			23.671,
			23.702,
			23.734,
			23.75,
			23.782,
			23.797,
			23.836,
			23.851,
			23.882,
			23.9,
			23.936,
			23.952,
			23.982,
			24.015,
			24.03,
			24.045,
			24.076,
			24.118,
			24.135,
			24.151,
			24.183,
			24.198,
			24.232,
			24.249,
			24.278,
			24.312,
			24.326,
			24.357,
			24.372,
			24.433,
			24.45,
			24.479,
			24.495,
			24.528,
			24.545,
			24.576,
			24.616,
			24.649,
			24.679,
			24.71,
			24.726,
			24.749,
			24.779,
			24.795,
			24.842,
			24.846,
			24.879,
			24.894,
			24.933,
			24.95,
			24.981,
			24.996,
			25.028,
			25.043,
			25.079,
			25.111,
			25.142,
			25.156,
			25.169,
			25.199,
			25.231,
			25.246,
			25.283,
			25.325,
			25.354,
			25.37,
			25.41,
			25.443,
			25.456,
			25.473,
			25.495,
			25.529,
			25.559,
			25.575,
			25.605,
			25.62,
			25.651,
			25.708,
			25.723,
			25.757,
			25.789,
			25.81,
			25.823,
			25.857,
			25.873,
			25.901,
			25.919,
			25.949,
			25.981,
			26.006,
			26.025,
			26.055,
			26.072,
			26.102,
			26.138,
			26.154,
			26.185,
			26.201,
			26.231,
			26.248,
			26.279,
			26.294,
			26.337,
			26.369,
			26.4,
			26.423,
			26.454,
			26.469,
			26.513,
			26.529,
			26.545,
			26.593,
			26.606,
			26.636,
			26.653,
			26.686,
			26.7,
			26.731,
			26.766,
			26.782,
			26.797,
			26.845,
			26.874,
			26.922,
			26.953,
			26.983,
			27,
			27.029,
			27.045,
			27.095,
			27.125,
			27.158,
			27.172,
			27.205,
			27.22,
			27.251,
			27.283,
			27.3,
			27.332,
			27.346,
			27.375,
			27.407,
			27.423,
			27.455,
			27.469,
			27.502,
			27.546,
			27.583,
			27.599,
			27.633,
			27.646,
			27.673,
			27.706,
			27.719,
			27.752,
			27.782,
			27.796,
			27.829,
			27.86,
			27.876,
			27.902,
			27.933,
			27.95,
			27.979,
			28.001,
			28.018,
			28.049,
			28.079,
			28.095,
			28.126,
			28.158,
			28.172,
			28.231,
			28.246,
			28.278,
			28.299,
			28.346,
			28.376,
			28.409,
			28.439,
			28.455,
			28.47,
			28.502,
			28.541,
			28.573,
			28.605,
			28.628,
			28.644,
			28.673,
			28.699,
			28.733,
			28.746,
			28.779,
			28.809,
			28.84,
			28.855,
			28.886,
			28.901,
			28.925,
			28.975,
			29.006,
			29.021,
			29.051,
			29.082,
			29.099,
			29.128,
			29.167,
			29.183,
			29.215,
			29.233,
			29.254,
			29.271,
			29.301,
			29.318,
			29.364,
			29.394,
			29.425,
			29.453,
			29.469,
			29.501,
			29.529,
			29.548,
			29.57,
			29.599,
			29.631,
			29.658,
			29.673,
			29.707,
			29.722,
			29.769,
			29.803,
			29.834,
			29.849,
			29.879,
			29.895,
			29.942,
			29.952,
			29.968,
			29.999,
			30.029,
			30.046,
			30.083,
			30.1,
			30.131,
			30.146,
			30.178,
			30.197,
			30.229,
			30.244,
			30.283,
			30.3,
			30.329,
			30.345,
			30.376,
			30.399,
			30.428,
			30.462,
			30.478,
			30.497,
			30.529,
			30.544,
			30.6,
			30.629,
			30.662,
			30.678,
			30.713,
			30.728,
			30.759,
			30.774,
			30.807,
			30.829,
			30.858,
			30.874,
			30.905,
			30.92,
			30.951,
			30.983,
			30.998,
			31.027,
			31.057,
			31.089,
			31.12,
			31.17,
			31.201,
			31.232,
			31.262,
			31.279,
			31.309,
			31.341,
			31.355,
			31.372,
			31.404,
			31.434,
			31.451,
			31.498,
			31.528,
			31.544,
			31.573,
			31.605,
			31.622,
			31.656,
			31.669,
			31.7,
			31.734,
			31.745,
			31.779,
			31.794,
			31.824,
			31.866,
			31.882,
			31.897,
			31.929,
			31.967,
			32.001,
			32.032,
			32.047,
			32.078,
			32.096,
			32.124,
			32.149,
			32.196,
			32.229,
			32.259,
			32.282,
			32.299,
			32.331,
			32.346,
			32.379,
			32.394,
			32.423,
			32.456,
			32.472,
			32.502,
			32.534,
			32.55,
			32.581,
			32.6,
			32.633,
			32.646,
			32.677,
			32.702,
			32.733,
			32.766,
			32.781,
			32.796,
			32.827,
			32.858,
			32.874,
			32.912,
			32.926,
			32.944,
			32.975,
			32.999,
			33.028,
			33.045,
			33.084,
			33.115,
			33.145,
			33.177,
			33.209,
			33.223,
			33.257,
			33.272,
			33.304,
			33.333,
			33.348,
			33.378,
			33.41,
			33.425,
			33.454,
			33.47,
			33.502,
			33.522,
			33.555,
			33.571,
			33.602,
			33.618,
			33.653,
			33.669,
			33.702,
			33.732,
			33.748,
			33.778,
			33.825,
			33.856,
			33.871,
			33.904,
			33.929,
			33.948,
			33.978,
			33.995,
			34.019,
			34.049,
			34.081,
			34.096,
			34.138,
			34.155,
			34.171,
			34.202,
			34.233,
			34.271,
			34.302,
			34.348,
			34.379,
			34.409,
			34.425,
			34.455,
			34.47,
			34.518,
			34.548,
			34.578,
			34.593,
			34.625,
			34.649,
			34.696,
			34.754,
			34.783,
			34.8,
			34.831,
			34.845,
			34.869,
			34.899,
			34.929,
			34.962,
			34.978,
			35.024,
			35.066,
			35.083,
			35.098,
			35.144,
			35.173,
			35.206,
			35.22,
			35.251,
			35.268,
			35.3,
			35.329,
			35.345,
			35.382,
			35.412,
			35.428,
			35.445,
			35.482,
			35.5,
			35.528,
			35.545,
			35.576,
			35.609,
			35.639,
			35.654,
			35.698,
			35.744,
			35.776,
			35.794,
			35.824,
			35.883,
			35.895,
			35.928,
			35.945,
			35.975,
			35.994,
			36.026,
			36.06,
			36.075,
			36.096,
			36.126,
			36.174,
			36.203,
			36.221,
			36.251,
			36.295,
			36.327,
			36.373,
			36.404,
			36.424,
			36.453,
			36.47,
			36.499,
			36.532,
			36.547,
			36.578,
			36.606,
			36.626,
			36.658,
			36.674,
			36.704,
			36.732,
			36.746,
			36.779,
			36.794,
			36.827,
			36.857,
			36.888,
			36.904,
			36.919,
			36.957,
			36.989,
			37.004,
			37.019,
			37.045,
			37.077,
			37.112,
			37.135,
			37.15,
			37.179,
			37.212,
			37.253,
			37.284,
			37.299,
			37.333,
			37.345,
			37.378,
			37.41,
			37.425,
			37.449,
			37.479,
			37.512,
			37.527,
			37.569,
			37.601,
			37.631,
			37.647,
			37.681,
			37.698,
			37.728,
			37.768,
			37.798,
			37.829,
			37.862,
			37.876,
			37.898,
			37.932,
			37.947,
			37.977,
			38.004,
			38.035,
			38.049,
			38.082,
			38.096,
			38.129,
			38.145,
			38.2,
			38.231,
			38.245,
			38.278,
			38.294,
			38.329,
			38.347,
			38.378,
			38.408,
			38.438,
			38.455,
			38.47,
			38.512,
			38.529,
			38.559,
			38.574,
			38.609,
			38.628,
			38.645,
			38.675,
			38.702,
			38.734,
			38.748,
			38.78,
			38.795,
			38.826,
			38.86,
			38.875,
			38.907,
			38.924,
			38.962,
			38.977,
			39.032,
			39.049,
			39.079,
			39.096,
			39.127,
			39.16,
			39.173,
			39.207,
			39.236,
			39.244,
			39.276,
			39.314,
			39.323,
			39.354,
			39.37,
			39.416,
			39.457,
			39.473,
			39.505,
			39.518,
			39.551,
			39.573,
			39.603,
			39.619,
			39.649,
			39.681,
			39.697,
			39.728,
			39.771,
			39.803,
			39.819,
			39.851,
			39.87,
			39.901,
			39.933,
			39.948,
			39.98,
			40.01,
			40.042,
			40.057,
			40.071,
			40.104,
			40.121,
			40.151,
			40.185,
			40.215,
			40.229,
			40.261,
			40.275,
			40.306,
			40.324,
			40.354,
			40.369,
			40.402,
			40.418,
			40.45,
			40.483,
			40.496,
			40.541,
			40.558,
			40.573,
			40.614,
			40.629,
			40.662,
			40.675,
			40.718,
			40.747,
			40.782,
			40.797,
			40.826,
			40.855,
			40.871,
			40.904,
			40.95,
			40.982,
			40.996,
			41.049,
			41.078,
			41.112,
			41.119,
			41.15,
			41.183,
			41.196,
			41.229,
			41.245,
			41.275,
			41.308,
			41.328,
			41.349,
			41.379,
			41.397,
			41.429,
			41.449,
			41.495,
			41.526,
			41.574,
			41.603,
			41.619,
			41.663,
			41.682,
			41.712,
			41.728,
			41.745,
			41.778,
			41.808,
			41.825,
			41.849,
			41.878,
			41.895,
			41.941,
			41.965,
			41.981,
			41.994,
			42.028,
			42.058,
			42.077,
			42.109,
			42.123,
			42.169,
			42.2,
			42.233,
			42.249,
			42.281,
			42.296,
			42.342,
			42.359,
			42.371,
			42.403,
			42.433,
			42.449,
			42.495,
			42.528,
			42.559,
			42.575,
			42.595,
			42.627,
			42.658,
			42.676,
			42.701,
			42.733,
			42.745,
			42.776,
			42.809,
			42.84,
			42.871,
			42.911,
			42.926,
			42.958,
			42.976,
			42.995,
			43.027,
			43.058,
			43.086,
			43.101,
			43.132,
			43.163,
			43.178,
			43.195,
			43.228,
			43.245,
			43.275,
			43.308,
			43.332,
			43.355,
			43.373,
			43.402,
			43.423,
			43.449,
			43.481,
			43.497,
			43.524,
			43.571,
			43.606,
			43.621,
			43.65,
			43.683,
			43.698,
			43.729,
			43.744,
			43.775,
			43.806,
			43.823,
			43.857,
			43.87,
			43.904,
			43.928,
			43.958,
			43.975,
			44.006,
			44.021,
			44.051,
			44.083,
			44.1,
			44.138,
			44.157,
			44.17,
			44.204,
			44.219,
			44.252,
			44.269,
			44.315,
			44.329,
			44.346,
			44.379,
			44.409,
			44.44,
			44.472,
			44.504,
			44.519,
			44.549,
			44.597,
			44.628,
			44.66,
			44.675,
			44.704,
			44.735,
			44.771,
			44.805,
			44.818,
			44.851,
			44.878,
			44.909,
			44.923,
			44.946,
			44.982,
			44.997,
			45.029,
			45.058,
			45.102,
			45.149,
			45.177,
			45.208,
			45.225,
			45.254,
			45.268,
			45.299,
			45.336,
			45.348,
			45.379,
			45.401,
			45.45,
			45.478,
			45.506,
			45.521,
			45.567,
			45.582,
			45.598,
			45.63,
			45.645,
			45.674,
			45.712,
			45.729,
			45.745,
			45.778,
			45.802,
			45.833,
			45.849,
			45.878,
			45.896,
			45.924,
			45.957,
			45.971,
			46.029,
			46.044,
			46.078,
			46.107,
			46.129,
			46.145,
			46.176,
			46.219,
			46.25,
			46.279,
			46.295,
			46.344,
			46.408,
			46.423,
			46.444,
			46.476,
			46.506,
			46.522,
			46.552,
			46.568,
			46.6,
			46.645,
			46.676,
			46.706,
			46.724,
			46.755,
			46.772,
			46.802,
			46.833,
			46.847,
			46.879,
			46.896,
			46.925,
			46.959,
			46.976,
			47.006,
			47.022,
			47.067,
			47.1,
			47.129,
			47.145,
			47.176,
			47.208,
			47.224,
			47.274,
			47.306,
			47.321,
			47.353,
			47.382,
			47.397,
			47.427,
			47.443,
			47.474,
			47.521,
			47.55,
			47.57,
			47.603,
			47.636,
			47.666,
			47.678,
			47.695,
			47.727,
			47.757,
			47.784,
			47.814,
			47.829,
			47.845,
			47.875,
			47.902,
			47.92,
			47.95,
			47.983,
			47.993,
			48.04,
			48.055,
			48.073,
			48.103,
			48.12,
			48.15,
			48.195,
			48.233,
			48.249,
			48.297,
			48.319,
			48.366,
			48.382,
			48.399,
			48.428,
			48.444,
			48.474,
			48.516,
			48.534,
			48.549,
			48.582,
			48.613,
			48.633,
			48.661,
			48.7,
			48.734,
			48.75,
			48.778,
			48.796,
			48.847,
			48.876,
			48.908,
			48.93,
			48.946,
			48.991,
			49.018,
			49.033,
			49.049,
			49.096,
			49.126,
			49.159,
			49.174,
			49.208,
			49.223,
			49.246,
			49.276,
			49.307,
			49.324,
			49.345,
			49.369,
			49.4,
			49.432,
			49.456,
			49.474,
			49.504,
			49.536,
			49.553,
			49.569,
			49.6,
			49.632,
			49.647,
			49.678,
			49.695,
			49.724,
			49.771,
			49.8,
			49.832,
			49.849,
			49.878,
			49.894,
			49.926,
			49.954,
			49.972,
			50.003,
			50.019,
			50.049,
			50.084,
			50.099,
			50.145,
			50.173,
			50.221,
			50.251,
			50.282,
			50.296,
			50.328,
			50.345,
			50.375,
			50.396,
			50.428,
			50.445,
			50.476,
			50.514,
			50.531,
			50.546,
			50.576,
			50.608,
			50.639,
			50.655,
			50.669,
			50.714,
			50.729,
			50.76,
			50.776,
			50.81,
			50.841,
			50.855,
			50.872,
			50.902,
			50.95,
			50.995,
			51.04,
			51.074,
			51.104,
			51.12,
			51.153,
			51.168,
			51.229,
			51.245,
			51.277,
			51.308,
			51.325,
			51.354,
			51.371,
			51.403,
			51.432,
			51.448,
			51.478,
			51.495,
			51.539,
			51.556,
			51.572,
			51.602,
			51.64,
			51.672,
			51.718,
			51.747,
			51.779,
			51.807,
			51.84,
			51.869,
			51.902,
			51.938,
			51.954,
			51.97,
			52.002,
			52.033,
			52.055,
			52.069,
			52.102,
			52.133,
			52.148,
			52.195,
			52.226,
			52.251,
			52.283,
			52.299,
			52.346,
			52.395,
			52.426,
			52.459,
			52.474,
			52.52,
			52.562,
			52.593,
			52.609,
			52.626,
			52.658,
			52.674,
			52.72,
			52.751,
			52.784,
			52.799,
			52.829,
			52.845,
			52.875,
			52.907,
			52.922,
			52.954,
			52.969,
			53.018,
			53.049,
			53.079,
			53.095,
			53.124,
			53.172,
			53.206,
			53.219,
			53.252,
			53.284,
			53.3,
			53.329,
			53.363,
			53.393,
			53.406,
			53.439,
			53.471,
			53.518,
			53.55,
			53.582,
			53.611,
			53.626,
			53.657,
			53.673,
			53.7,
			53.731,
			53.761,
			53.793,
			53.815,
			53.832,
			53.846,
			53.892,
			53.912,
			53.928,
			53.96,
			53.976,
			54.015,
			54.03,
			54.047,
			54.078,
			54.093,
			54.146,
			54.175,
			54.209,
			54.224,
			54.245,
			54.277,
			54.316,
			54.332,
			54.348,
			54.394,
			54.423,
			54.444,
			54.475,
			54.506,
			54.523,
			54.559,
			54.59,
			54.605,
			54.62,
			54.652,
			54.698,
			54.728,
			54.758,
			54.79,
			54.821,
			54.849,
			54.879,
			54.895,
			54.929,
			54.951,
			54.982,
			54.996,
			55.027,
			55.071,
			55.103,
			55.135,
			55.149,
			55.179,
			55.211,
			55.224,
			55.249,
			55.28,
			55.297,
			55.328,
			55.384,
			55.4,
			55.433,
			55.45,
			55.476,
			55.522,
			55.554,
			55.57,
			55.6,
			55.633,
			55.647,
			55.679,
			55.699,
			55.743,
			55.761,
			55.789,
			55.803,
			55.832,
			55.85,
			55.879,
			55.912,
			55.927,
			55.959,
			55.975,
			56.012,
			56.027,
			56.043,
			56.092,
			56.101,
			56.149,
			56.179,
			56.209,
			56.225,
			56.257,
			56.272,
			56.303,
			56.343,
			56.373,
			56.407,
			56.431,
			56.461,
			56.484
		]
	],
	'9472_9473_7982': [
		[
			91.4931
		],
		[
			0
		],
		[
			0,
			3.16210938,
			6.28800774,
			7.853228,
			10.987196,
			14.1258287,
			17.2706413,
			18.8448887,
			21.9974823,
			23.5755367,
			26.734539,
			29.89765,
			31.48072,
			34.6499825,
			37.82359,
			41.0018,
			44.18475,
			47.3688622,
			48.9607658,
			52.14781,
			55.3386574,
			56.93572,
			60.1326332,
			63.3335953,
			64.93541,
			68.14196,
			71.35207,
			72.95828,
			74.5654144,
			77.78311,
			81.00493,
			84.23108,
			85.84555,
			89.0772247,
			90.69447,
			93.93232,
			97.16937,
			100.3964,
			103.598282,
			106.762978,
			108.3268,
			111.425972,
			112.960846,
			116.003296,
			119.010529,
			121.980042,
			123.450317,
			126.362137,
			129.2406,
			132.081177,
			134.884811,
			137.6479,
			139.014069,
			141.716827,
			143.05159,
			145.692871,
			148.292511,
			150.853638,
			152.118866,
			154.617767,
			155.85321,
			158.295029,
			160.700333,
			161.89035,
			164.245361,
			165.410934,
			167.7169,
			169.986389,
			171.126816,
			173.3452,
			175.530472,
			176.610779,
			178.746338,
			179.80162,
			181.888062,
			183.943115,
			185.9653,
			186.9643,
			188.937164,
			190.877869,
			192.785553,
			194.6586,
			196.499008,
			197.407761,
			199.203278,
			200.974152,
			201.852783,
			203.601318,
			205.3378,
			206.202621,
			207.928619,
			209.6576,
			211.395615,
			213.145859,
			214.0257,
			215.794769,
			217.576157,
			218.469666,
			220.265747,
			222.073914,
			223.888947,
			224.799133,
			226.624329,
			228.46109,
			230.305,
			231.226227,
			233.0704,
			234.919586,
			235.840118,
			237.671432,
			239.490814,
			241.289948,
			243.064575,
			243.94043,
			244.809845,
			246.5303,
			247.382248,
			249.080139,
			250.766052,
			252.445938,
			254.1435,
			255.850952,
			257.567749,
			259.300568,
			261.0434,
			261.9176,
			262.79364,
			264.5592,
			266.330841,
			268.108734,
			269.924927,
			270.834167,
			272.655457,
			274.510681,
			276.377625,
			278.246124,
			280.1242,
			281.062073,
			281.999023,
			283.8622,
			285.7066,
			287.5466,
			288.465881,
			290.304321,
			291.222473,
			292.140167,
			293.973755,
			295.803436,
			296.717041,
			298.5424,
			300.36438,
			302.1825,
			303.9969,
			304.901947,
			306.708679,
			308.511,
			310.290466,
			311.177979,
			312.949219,
			314.711975,
			315.590271,
			317.3443,
			319.103333,
			319.992981,
			321.774719,
			323.5625,
			325.352783,
			327.147736,
			328.945282,
			329.830322,
			331.603149,
			333.3822,
			335.1432,
			336.903473,
			337.787659,
			339.559784,
			341.333344,
			343.118347,
			344.016724,
			345.845032,
			346.763672,
			348.6119,
			350.489838,
			352.386322,
			354.297058,
			356.216064,
			357.181732,
			359.1255,
			361.07,
			362.049,
			364.021545,
			365.986725,
			366.9756,
			368.966461,
			370.921082,
			372.8897,
			374.8799,
			376.891846,
			377.901917,
			379.9324,
			381.983521,
			384.043549,
			385.076782,
			387.14624,
			388.183,
			390.29068,
			391.361481,
			393.508179,
			395.6646,
			396.746338,
			398.9156,
			399.9999,
			402.168152,
			404.341919,
			405.434,
			407.622253,
			409.812073,
			410.8791,
			413.071625,
			414.160461,
			416.302155,
			418.47937,
			419.5596,
			421.76947,
			422.885864,
			425.1434,
			427.386841,
			428.520233,
			430.788055,
			433.072723,
			434.207764,
			436.459442,
			438.746521,
			441.006622,
			443.2796,
			445.564331,
			446.6808,
			449.0102,
			450.186646,
			452.545563,
			454.9226,
			457.3057,
			458.4854,
			460.8496,
			462.034058,
			464.389526,
			466.7495,
			469.0926,
			471.434967,
			473.7905,
			476.1564,
			477.3418,
			479.732025,
			482.128723,
			483.328064,
			484.5288,
			486.935272,
			489.341125,
			491.751862,
			494.171631,
			495.384277,
			496.5985,
			499.0469,
			501.509033,
			502.742523,
			505.21463,
			507.693939,
			508.938843,
			511.427521,
			512.668762,
			515.1411,
			517.5855,
			519.992859,
			521.1818,
			522.360962,
			524.6887,
			525.8373,
			528.104065,
			530.330566,
			532.5164,
			533.5943,
			534.662231,
			536.7682,
			538.83374,
			540.859558,
			542.846436,
			544.7942,
			545.753357,
			547.6424,
			549.4923,
			551.3058,
			552.199,
			553.958,
			554.8236,
			556.52594,
			558.190063,
			559.816467,
			561.4053,
			562.9568,
			563.718262,
			565.213257,
			566.6715,
			568.0927,
			569.4766,
			570.167358,
			570.838562,
			572.157,
			573.435669,
			574.676453,
			575.8822,
			577.0563,
			577.633,
			581.970947,
			582.4976,
			583.545044,
			584.587341,
			585.6286,
			586.150146,
			587.197144,
			587.7239,
			588.789734,
			589.8612,
			590.3996,
			591.482666,
			592.574768,
			593.7002,
			594.8329,
			595.9712,
			597.116,
			597.69104,
			598.8955,
			600.102661,
			600.7073,
			601.9187,
			603.149841,
			604.411865,
			605.673767,
			606.3048,
			607.5678,
			608.8579,
			609.503052,
			610.7905,
			612.075439,
			612.717651,
			614.0214,
			615.3163,
			616.6043,
			617.8918,
			618.549438,
			619.8548,
			621.15094,
			621.796631,
			623.0919,
			624.3962,
			625.68396,
			626.3231,
			626.9602,
			628.2329,
			629.499451,
			630.7558,
			632.004639,
			633.2479,
			633.865234,
			635.090637,
			636.304932,
			637.5109,
			638.1148,
			639.318848,
			640.5139,
			641.7039,
			642.896362,
			644.1305,
			645.360535,
			645.975342,
			647.2062,
			648.4318,
			649.040039,
			650.254,
			651.467,
			652.0741,
			653.260559,
			654.398865,
			655.5388,
			656.683,
			657.257568,
			658.419861,
			659.0058,
			660.1803,
			661.3601,
			662.5489,
			663.7623,
			664.3737,
			664.9869,
			666.220337,
			667.465149,
			668.6897,
			669.907532,
			671.1366,
			671.7559,
			673.0045,
			674.255249,
			675.5182,
			676.7955,
			677.4402,
			678.7255,
			680.016235,
			681.323059,
			682.647644,
			683.3056,
			684.616455,
			685.2777,
			686.6128,
			687.9662,
			688.662231,
			690.0657,
			690.773254,
			691.4849,
			692.9213,
			694.411133,
			695.917358,
			696.6766,
			698.204468,
			699.7324,
			700.5021,
			702.052856,
			703.6273,
			705.2291,
			706.846558,
			707.661255,
			709.333862,
			711.0184,
			712.706543,
			713.55835,
			715.277466,
			716.1424,
			717.8823,
			718.7412,
			720.5115,
			722.280151,
			723.1695,
			724.951355,
			725.847534,
			727.6331,
			729.4435,
			730.353,
			732.1825,
			734.029053,
			734.958862,
			736.8282,
			737.767761,
			739.6578,
			741.5631,
			742.521,
			744.446045,
			745.4297,
			747.3591,
			749.3199,
			750.3054,
			752.2877,
			754.283936,
			755.2871,
			757.3038,
			759.3343,
			761.378662,
			763.4367,
			765.509033,
			766.5501,
			768.6413,
			770.7374,
			771.7858,
			773.892944,
			776.0217,
			777.090942,
			779.2443,
			781.4199,
			783.607,
			784.6997,
			786.8953,
			789.098755,
			791.303345,
			792.4108,
			794.6338,
			796.8647,
			799.1135,
			801.3315,
			802.435364,
			804.6043,
			806.7605,
			807.827,
			809.944031,
			812.0042,
			814.0084,
			815.984863,
			817.944031,
			818.8997,
			820.7776,
			821.704346,
			823.533447,
			825.3269,
			827.087769,
			828.8169,
			830.511353,
			831.347534,
			832.9982,
			834.6338,
			835.4427,
			837.0361,
			838.5989,
			839.3811,
			840.9238,
			841.6852,
			843.1895,
			844.6783,
			846.1499,
			846.8801,
			847.6074,
			849.0555,
			850.495056,
			851.2124,
			852.645,
			854.0709,
			854.7769,
			856.188843,
			857.602234,
			859.0133,
			860.412,
			861.811951,
			862.5132,
			863.9205,
			865.3344,
			866.041565,
			867.4573,
			868.166138,
			869.5888,
			870.3002,
			871.721558,
			873.143,
			874.5756,
			876.012451,
			877.448,
			878.165649,
			879.635742,
			881.123169,
			882.6079,
			884.1195,
			884.9152,
			886.5046,
			888.093,
			889.7462,
			890.593445,
			892.2876,
			893.1354,
			894.8126,
			896.4798,
			898.1499,
			899.7867,
			901.4049,
			902.215454,
			903.838257,
			905.4149,
			906.9962,
			908.5837,
			909.366,
			910.925659,
			911.7081,
			913.2789,
			914.063232,
			915.6205,
			917.186646,
			917.9736,
			920.317444,
			921.881958,
			922.669067,
			924.251038,
			925.839233,
			927.4396,
			928.2446,
			929.87854,
			931.533569,
			933.20105,
			934.8888,
			935.739746,
			937.4509,
			938.31134,
			940.0488,
			940.9239,
			942.6829,
			944.45575,
			946.2435,
			948.043,
			949.8492,
			950.7543,
			952.5738,
			954.4043,
			956.2427,
			957.16626,
			959.022766,
			959.9448,
			961.8444,
			963.7401,
			965.63916,
			967.5657,
			969.5046,
			970.479736,
			972.437866,
			974.407166,
			975.394958,
			977.394653,
			979.3728,
			981.3783,
			983.4108,
			984.4223,
			986.4359,
			988.477234,
			989.501038,
			991.5527,
			993.6093,
			994.6385,
			996.7008,
			997.7359,
			999.810364,
			1001.88733,
			1002.92609,
			1005.00085,
			1006.03912,
			1008.12146,
			1009.16663,
			1011.27081,
			1013.38428,
			1015.51379,
			1016.58386,
			1018.73108,
			1020.88806,
			1023.05444,
			1025.22693,
			1027.4054,
			1028.49792,
			1029.592,
			1031.78345,
			1033.98376,
			1036.193,
			1037.30078,
			1039.52393,
			1041.75781,
			1044.00073,
			1045.12622,
			1047.38452,
			1049.652,
			1051.92871,
			1053.07056,
			1055.36108,
			1057.66125,
			1059.9707,
			1062.28992,
			1063.453,
			1065.786,
			1068.12817,
			1070.47913,
			1071.658,
			1074.02209,
			1076.39587,
			1077.586,
			1079.973,
			1082.3689,
			1084.77319,
			1087.18542,
			1088.39465,
			1090.81921,
			1093.25183,
			1095.695,
			1096.92065,
			1099.37817,
			1100.61328,
			1103.08972,
			1105.5752,
			1108.06934,
			1109.31946,
			1111.82666,
			1114.34192,
			1116.8645,
			1119.395,
			1121.9314,
			1124.47559,
			1125.74707,
			1128.29553,
			1130.8479,
			1132.12512,
			1134.685,
			1137.25171,
			1139.822,
			1141.10864,
			1142.394,
			1144.95667,
			1146.23511,
			1148.77417,
			1151.286,
			1153.76416,
			1154.988,
			1156.20044,
			1158.59656,
			1160.95068,
			1163.26392,
			1165.54065,
			1166.66565,
			1168.888,
			1171.0769,
			1172.15857,
			1174.295,
			1176.397,
			1177.43481,
			1179.50146,
			1181.5144,
			1182.50781,
			1184.46912,
			1185.43713,
			1187.34717,
			1188.28955,
			1190.14978,
			1191.97632,
			1192.8772,
			1194.6571,
			1196.40845,
			1197.27515,
			1198.99365,
			1199.84656,
			1201.5376,
			1203.21741,
			1204.89429,
			1206.57361,
			1207.41455,
			1209.10144,
			1210.8,
			1211.65332,
			1213.36536,
			1215.08667,
			1216.82764,
			1218.5741,
			1220.33069,
			1221.21729,
			1222.99243,
			1224.77063,
			1225.6637,
			1227.45166,
			1229.24084,
			1230.13672,
			1231.93286,
			1233.72754,
			1234.62439,
			1236.42151,
			1237.31885,
			1239.11084,
			1240.9043,
			1242.69653,
			1243.59033,
			1244.48279,
			1246.277,
			1247.17346,
			1248.96021,
			1250.747,
			1252.53333,
			1254.3092,
			1256.08459,
			1257.85352,
			1258.73364,
			1260.4917,
			1261.374,
			1263.12878,
			1264.002,
			1265.74927,
			1267.49683,
			1268.36609,
			1270.09827,
			1270.96912,
			1272.70166,
			1274.42468,
			1275.28418,
			1276.99976,
			1278.707,
			1279.55811,
			1280.40771,
			1282.09692,
			1283.77783,
			1285.45178,
			1287.114,
			1288.77246,
			1290.43115,
			1291.261,
			1292.91968,
			1294.57886,
			1295.41248,
			1297.09827,
			1298.78345,
			1300.474,
			1302.19543,
			1303.91443,
			1304.77393,
			1306.49243,
			1307.3501,
			1309.0625,
			1310.76953,
			1311.61523,
			1313.30322,
			1314.98853,
			1316.6637,
			1318.332,
			1319.99768,
			1321.66321,
			1322.49487,
			1324.15613,
			1325.8114,
			1326.62952,
			1328.26355,
			1329.89612,
			1330.70825,
			1332.30762,
			1333.90649,
			1335.50586,
			1337.07153,
			1338.63818,
			1339.42236,
			1340.98779,
			1342.54382,
			1343.32275,
			1344.88367,
			1346.45642,
			1348.03857,
			1348.83142,
			1350.42151,
			1352.01843,
			1353.62207,
			1355.23389,
			1356.04517,
			1357.67627,
			1358.49512,
			1360.14026,
			1361.79932,
			1362.6333,
			1364.3103,
			1366.00208,
			1367.71472,
			1369.43884,
			1371.17749,
			1372.9209,
			1373.8042,
			1375.57971,
			1376.47266,
			1378.26782,
			1380.07532,
			1381.89331,
			1383.72314,
			1384.643,
			1386.491,
			1387.41907,
			1389.28467,
			1391.163,
			1393.05347,
			1394.95667,
			1395.91272,
			1397.83325,
			1399.76587,
			1400.73682,
			1402.6875,
			1404.66614,
			1406.62329,
			1408.608,
			1410.60413,
			1412.61108,
			1413.61865,
			1415.64209,
			1416.65735,
			1418.69592,
			1420.74524,
			1422.80493,
			1424.875,
			1425.91406,
			1427.99951,
			1430.09033,
			1431.135,
			1433.22485,
			1435.32288,
			1437.43127,
			1438.489,
			1440.61182,
			1442.74463,
			1443.8147,
			1445.96216,
			1448.11914,
			1450.28625,
			1452.46326,
			1453.55542,
			1455.747,
			1457.94812,
			1459.05225,
			1461.26782,
			1462.37915,
			1464.609,
			1466.84863,
			1469.09814,
			1471.35718,
			1472.49072,
			1474.765,
			1477.04858,
			1479.3418,
			1481.64478,
			1482.79993,
			1485.11731,
			1486.27954,
			1488.61121,
			1490.95239,
			1493.30664,
			1495.67078,
			1498.04468,
			1499.23511,
			1501.62256,
			1504.0166,
			1506.4198,
			1507.62463,
			1510.04126,
			1512.46729,
			1514.90271,
			1517.34851,
			1519.80518,
			1521.03674,
			1523.52173,
			1526.01733,
			1527.27271,
			1529.7926,
			1531.05579,
			1533.58582,
			1536.1239,
			1538.67017,
			1541.22461,
			1542.50464,
			1545.07068,
			1547.64368,
			1550.22314,
			1551.516,
			1554.10474,
			1556.70056,
			1557.998,
			1560.59924,
			1563.20142,
			1565.8075,
			1568.415,
			1571.01978,
			1572.32361,
			1574.93591,
			1577.5564,
			1580.185,
			1581.50208,
			1584.14014,
			1586.78479,
			1588.10864,
			1590.7616,
			1593.42188,
			1596.08936,
			1597.42639,
			1600.107,
			1601.45007,
			1602.79492,
			1605.49011,
			1606.84033,
			1609.54443,
			1612.25562,
			1614.97437,
			1616.33618,
			1619.06519,
			1621.8009,
			1624.54346,
			1627.2926,
			1630.04712,
			1631.427,
			1634.19141,
			1635.57642,
			1638.35254,
			1641.13672,
			1642.53088,
			1645.32336,
			1648.12231,
			1650.92725,
			1653.74,
			1656.55994,
			1659.38818,
			1660.80518,
			1663.64417,
			1665.06628,
			1667.91565,
			1670.77173,
			1672.20227,
			1675.06934,
			1677.94312,
			1680.82312,
			1683.71045,
			1686.60474,
			1689.50659,
			1690.96,
			1693.87207,
			1695.33081,
			1698.25366,
			1701.1842,
			1704.12317,
			1705.596,
			1708.54858,
			1710.0293,
			1712.99609,
			1714.481,
			1717.45593,
			1720.43335,
			1723.41528,
			1726.39966,
			1729.39063,
			1730.88843,
			1733.8916,
			1736.904,
			1739.9231,
			1742.94885,
			1745.9812,
			1749.02173,
			1752.07043,
			1753.59766,
			1756.65735,
			1758.18958,
			1761.25952,
			1764.33643,
			1767.42041,
			1768.96509,
			1770.51123,
			1773.60815,
			1776.712,
			1779.82324,
			1782.94092,
			1786.06506,
			1789.19653,
			1790.76514,
			1793.90625,
			1797.05408,
			1798.63086,
			1801.79028,
			1804.95728,
			1808.13171,
			1811.31079,
			1814.49048,
			1817.67627,
			1819.27124,
			1822.466,
			1824.06543,
			1827.26855,
			1830.477,
			1833.692,
			1835.30115,
			1836.91187,
			1840.13672,
			1843.36719,
			1846.60254,
			1849.84375,
			1853.0896,
			1856.34106,
			1857.96924,
			1861.22729,
			1864.49072,
			1866.12427,
			1869.395,
			1872.671,
			1875.95129,
			1877.59351,
			1880.88013,
			1884.171,
			1887.46631,
			1889.11609,
			1892.42029,
			1895.7301,
			1897.387,
			1900.70264,
			1904.02161,
			1907.34229,
			1910.66553,
			1913.991,
			1917.31812,
			1918.97925,
			1922.29077,
			1925.57837,
			1927.20972,
			1930.44458,
			1933.63806,
			1936.794,
			1938.35608,
			1939.907,
			1942.97681,
			1946.00464,
			1948.99243,
			1951.94824,
			1954.86523,
			1957.74512,
			1959.16992,
			1961.99036,
			1964.76978,
			1966.145,
			1968.86389,
			1971.5437,
			1974.18494,
			1976.78918,
			1979.35608,
			1980.62561,
			1983.13684,
			1985.60962,
			1986.83142,
			1989.26672,
			1991.638,
			1992.81152,
			1995.11926,
			1997.44165,
			1999.72961,
			2002.01013,
			2004.28833,
			2006.56824,
			2007.70972,
			2010.00024,
			2012.30347,
			2013.45813,
			2015.7832,
			2018.11963,
			2019.29028,
			2021.64429,
			2024.00256,
			2026.36292,
			2028.72461,
			2031.08252,
			2032.25928,
			2034.60815,
			2035.78418,
			2038.12988,
			2039.29907,
			2041.636,
			2042.79919,
			2043.958,
			2046.262,
			2047.40649,
			2049.68,
			2051.94482,
			2054.17676,
			2055.28735,
			2057.48828,
			2059.65527,
			2061.80078,
			2063.91724,
			2066.0127,
			2067.053,
			2069.12134,
			2071.183,
			2073.242,
			2074.27148,
			2076.33179,
			2077.36133,
			2079.42041,
			2081.477,
			2083.53149,
			2084.55835,
			2086.6084,
			2088.656,
			2090.70044,
			2092.74072,
			2094.78,
			2095.79785,
			2097.83179,
			2098.84814,
			2100.87769,
			2102.90259,
			2104.92627,
			2106.944,
			2108.962,
			2109.97144,
			2111.98462,
			2112.9917,
			2115.00732,
			2117.01685,
			2118.02271,
			2120.037,
			2122.04565,
			2124.05933,
			2126.07275,
			2128.088,
			2129.09888,
			2131.12378,
			2133.15356,
			2134.17188,
			2136.216,
			2137.24146,
			2139.29956,
			2140.33228,
			2143.44482,
			2145.531,
			2147.626,
			2149.72974,
			2150.783,
			2152.893,
			2153.95142,
			2156.073,
			2158.2002,
			2160.337,
			2162.48682,
			2163.565,
			2165.72656,
			2167.891,
			2168.97681,
			2171.15039,
			2173.32617,
			2175.49487,
			2177.70752,
			2178.81738,
			2181.05151,
			2183.31,
			2184.44263,
			2186.72021,
			2189.01025,
			2191.30884,
			2193.61523,
			2194.77222,
			2197.08545,
			2198.24438,
			2199.40552,
			2201.72827,
			2202.891,
			2205.22314,
			2207.561,
			2209.90771,
			2211.08667,
			2213.454,
			2214.64087,
			2217.02466,
			2219.419,
			2221.82178,
			2224.2334,
			2226.6543,
			2227.86816,
			2230.30225,
			2231.52368,
			2233.97266,
			2236.431,
			2238.898,
			2241.37329,
			2243.85645,
			2246.34717,
			2247.59473,
			2250.09644,
			2251.35034,
			2253.8645,
			2256.38721,
			2258.91846,
			2260.187,
			2262.72949,
			2265.28,
			2267.838,
			2268.1958,
			2270.75513,
			2273.32178,
			2275.896,
			2278.47754,
			2279.771,
			2282.36353,
			2283.66235,
			2286.26538,
			2288.87158,
			2291.47754,
			2292.78247,
			2295.397,
			2298.01636,
			2300.6416,
			2303.274,
			2305.90674,
			2307.221,
			2309.836,
			2311.1333,
			2313.70117,
			2316.232,
			2318.72314,
			2319.95483,
			2322.392,
			2324.794,
			2327.16064,
			2328.331,
			2330.64575,
			2332.92383,
			2334.04834,
			2336.27026,
			2338.456,
			2340.62378,
			2342.73975,
			2343.78442,
			2345.83032,
			2347.85449,
			2349.84351,
			2350.825,
			2352.75781,
			2354.65552,
			2355.607,
			2357.45215,
			2359.26636,
			2361.05225,
			2362.81128,
			2363.682,
			2365.40918,
			2367.11963,
			2367.97021,
			2369.66724,
			2371.364,
			2372.212,
			2373.90869,
			2375.60474,
			2377.31543,
			2379.02856,
			2380.74023,
			2381.61426,
			2383.376,
			2384.256,
			2386.01514,
			2387.9,
			2388.84229,
			2390.72534,
			2392.712,
			2394.7168,
			2396.71631,
			2398.70459,
			2399.69678,
			2401.64453,
			2403.51318,
			2404.44629,
			2406.30176,
			2408.08667,
			2409.86963,
			2410.761,
			2412.52515,
			2414.28369,
			2416.0415,
			2416.90063,
			2418.61621,
			2420.33716,
			2421.199,
			2422.88965,
			2424.587,
			2425.43872,
			2427.138,
			2428.838,
			2430.547,
			2432.27075,
			2433.1377,
			2434.88,
			2436.63574,
			2437.52051,
			2439.29736,
			2441.08545,
			2442.89355,
			2443.80225,
			2445.629,
			2447.47827,
			2449.3457,
			2450.28418,
			2452.176,
			2454.0874,
			2456.01221,
			2456.98071,
			2458.92773,
			2460.88745,
			2462.85474,
			2464.83179,
			2466.81958,
			2467.816,
			2468.815,
			2470.82031,
			2472.84131,
			2474.876,
			2475.89746,
			2477.95215,
			2480.01831,
			2481.056,
			2483.13818,
			2485.2207,
			2487.304,
			2489.39355,
			2491.494,
			2493.588,
			2494.639,
			2496.747,
			2498.86255,
			2499.92432,
			2502.05786,
			2503.13037,
			2505.28345,
			2507.44434,
			2509.612,
			2510.7,
			2511.79126,
			2513.9873,
			2515.08984,
			2517.28931,
			2519.53027,
			2520.65454,
			2522.92,
			2525.20264,
			2527.51733,
			2529.82813,
			2532.15015,
			2533.29736,
			2535.63721,
			2537.98779,
			2539.16748,
			2541.53271,
			2543.90283,
			2546.28247,
			2547.474,
			2548.667,
			2551.061,
			2553.47388,
			2555.90186,
			2557.12183,
			2559.58154,
			2560.81543,
			2562.05225,
			2564.52832,
			2567.01636,
			2569.50879,
			2572.0127,
			2573.26831,
			2575.78784,
			2578.318,
			2580.859,
			2582.1333,
			2584.68872,
			2585.97021,
			2588.54175,
			2591.124,
			2593.7146,
			2596.31445,
			2598.92383,
			2600.23145,
			2602.84644,
			2605.467,
			2606.78125,
			2609.41113,
			2612.04932,
			2613.37,
			2616.01758,
			2618.69678,
			2621.36255,
			2624.03418,
			2626.69,
			2628.05273,
			2630.73682,
			2632.0813,
			2634.77,
			2637.46387,
			2640.16357,
			2641.51514,
			2644.23022,
			2646.95313,
			2649.68848,
			2652.42871,
			2655.15479,
			2656.55371,
			2659.30933,
			2662.06982,
			2663.45264,
			2666.2207,
			2668.99414,
			2670.38281,
			2673.16382,
			2675.95068,
			2678.74268,
			2681.54053,
			2684.34131,
			2685.74219,
			2688.54248,
			2689.94019,
			2692.727,
			2694.11426,
			2696.87354,
			2699.60815,
			2702.311,
			2704.95361,
			2707.57959,
			2710.164,
			2711.44214,
			2713.969,
			2716.45728,
			2717.68726,
			2720.1272,
			2722.52979,
			2724.898,
			2726.06982,
			2728.39014,
			2730.6748,
			2732.92554,
			2735.1665,
			2736.26367,
			2738.43237,
			2739.50342,
			2741.62,
			2743.68457,
			2744.71167,
			2746.7417,
			2748.73926,
			2750.705,
			2752.65649,
			2754.56372,
			2755.50586,
			2757.368,
			2758.28687,
			2760.09961,
			2760.99658,
			2762.77173,
			2764.518,
			2766.24268,
			2767.95361,
			2769.63672,
			2771.333,
			2772.18042,
			2773.891,
			2775.588,
			2776.43555,
			2778.13354,
			2779.862,
			2781.59277,
			2783.33252,
			2785.09961,
			2785.98486,
			2787.76074,
			2789.57471,
			2790.48682,
			2792.31641,
			2794.15283,
			2795.07178,
			2796.91553,
			2798.76172,
			2800.604,
			2802.44678,
			2803.3667,
			2805.19922,
			2806.114,
			2807.94116,
			2809.73242,
			2811.516,
			2813.29443,
			2814.164,
			2815.0332,
			2816.77026,
			2818.50659,
			2820.242,
			2821.97681,
			2823.71655,
			2824.591,
			2826.34229,
			2828.09814,
			2829.857,
			2831.62256,
			2832.50879,
			2834.283,
			2836.06348,
			2837.85547,
			2838.758,
			2840.576,
			2842.40649,
			2843.327,
			2845.19531,
			2847.07666,
			2848.973,
			2850.88477,
			2851.84521,
			2853.77417,
			2855.70752,
			2857.65332,
			2858.62939,
			2860.583,
			2861.564,
			2863.5354,
			2865.51416,
			2866.50781,
			2868.504,
			2870.51147,
			2872.53052,
			2874.5498,
			2876.60254,
			2878.66724,
			2879.70947,
			2881.80029,
			2882.84644,
			2884.93335,
			2887.023,
			2889.1228,
			2891.233,
			2893.35352,
			2894.419,
			2896.55884,
			2898.709,
			2900.87061,
			2901.95532,
			2904.13184,
			2905.223,
			2907.413,
			2909.61426,
			2911.82715,
			2914.05029,
			2916.287,
			2917.40942,
			2918.53418,
			2920.79077,
			2923.05762,
			2925.3335,
			2927.61938,
			2928.766,
			2931.067,
			2933.3772,
			2935.69873,
			2938.031,
			2940.37476,
			2941.55127,
			2943.911,
			2946.287,
			2947.478,
			2949.862,
			2952.24219,
			2953.429,
			2955.784,
			2958.11377,
			2960.414,
			2962.67969,
			2964.90771,
			2966.00781,
			2968.18042,
			2970.316,
			2971.37,
			2973.451,
			2975.49536,
			2976.50366,
			2978.49316,
			2980.44775,
			2982.36768,
			2984.252,
			2986.10254,
			2987.01514,
			2988.81763,
			2990.60986,
			2991.48975,
			2993.23364,
			2994.95825,
			2995.813,
			2997.51123,
			2999.19922,
			3000.86768,
			3002.52783,
			3003.356,
			3005.0083,
			3006.65259,
			3007.473,
			3009.112,
			3010.76,
			3012.40674,
			3013.23,
			3014.88184,
			3016.54639,
			3018.20825,
			3019.04,
			3020.72729,
			3022.42041,
			3023.26758,
			3024.97754,
			3026.72119,
			3028.466,
			3030.22827,
			3031.12,
			3032.01221,
			3033.79932,
			3034.69727,
			3036.52734,
			3038.36084,
			3040.20752,
			3041.13818,
			3042.06934,
			3043.93652,
			3045.78516,
			3047.623,
			3049.46875,
			3050.388,
			3052.227,
			3054.07324,
			3055.905,
			3057.73071,
			3058.64551,
			3060.46118,
			3061.356,
			3063.149,
			3064.945,
			3065.83838,
			3067.62573,
			3069.41553,
			3071.212,
			3073.01074,
			3074.81177,
			3076.626,
			3077.53467,
			3079.356,
			3080.273,
			3082.123,
			3083.983,
			3085.86328,
			3087.76465,
			3088.72021,
			3090.64014,
			3091.605,
			3093.54321,
			3094.517,
			3096.47046,
			3098.43115,
			3099.41455,
			3100.401,
			3102.36572,
			3104.35669,
			3106.37939,
			3108.40039,
			3109.398,
			3111.44043,
			3113.51123,
			3114.54126,
			3116.6084,
			3118.66846,
			3119.71143,
			3121.81665,
			3123.90283,
			3125.97461,
			3128.07129,
			3130.17822,
			3131.23486,
			3133.356,
			3135.4873,
			3136.5564,
			3138.70215,
			3140.85767,
			3141.939,
			3144.10962,
			3146.29,
			3148.479,
			3150.67871,
			3152.88843,
			3153.997,
			3155.1084,
			3157.33887,
			3159.579,
			3161.82886,
			3162.95752,
			3165.22168,
			3167.495,
			3169.77832,
			3172.07031,
			3174.37134,
			3176.68286,
			3179.00439,
			3181.33643,
			3183.68066,
			3186.03442,
			3188.399,
			3189.58472,
			3191.96313,
			3193.15552,
			3195.54736,
			3196.74658,
			3199.15186,
			3201.56665,
			3203.99072,
			3206.42432,
			3208.86816,
			3211.32129,
			3212.55176,
			3215.019,
			3216.25635,
			3218.73926,
			3221.2312,
			3223.734,
			3226.24561,
			3227.50439,
			3230.02856,
			3232.562,
			3235.10376,
			3236.378,
			3238.93164,
			3240.212,
			3242.77563,
			3245.3457,
			3247.92456,
			3250.51221,
			3253.112,
			3255.71875,
			3258.32568,
			3260.93945,
			3263.558,
			3266.18335,
			3267.499,
			3270.13623,
			3272.78174,
			3275.435,
			3276.76465,
			3279.4292,
			3280.7644,
			3283.44043,
			3286.12476,
			3288.81665,
			3291.51587,
			3294.22314,
			3295.57959,
			3298.29883,
			3301.026,
			3302.39258,
			3305.13135,
			3307.87769,
			3309.25439,
			3312.013,
			3314.77979,
			3317.5542,
			3320.33667,
			3321.73047,
			3324.524,
			3327.325,
			3328.72827,
			3331.54126,
			3334.36084,
			3337.18823,
			3338.60278,
			3341.437,
			3344.27783,
			3347.125,
			3349.984,
			3351.4165,
			3354.28857,
			3357.1687,
			3358.6123,
			3361.505,
			3364.399,
			3365.84424,
			3368.72534,
			3371.58545,
			3374.41846,
			3377.21777,
			3378.60254,
			3381.34277,
			3384.04565,
			3385.3833,
			3388.03125,
			3390.64087,
			3393.21313,
			3395.74756,
			3397.00122,
			3398.24536,
			3400.70557,
			3403.12842,
			3405.51538,
			3407.86816,
			3409.032,
			3410.1875,
			3412.46973,
			3414.71436,
			3416.921,
			3419.09082,
			3420.162,
			3422.27661,
			3424.354,
			3426.39453,
			3428.39819,
			3429.38721,
			3431.33716,
			3433.25122,
			3435.14087,
			3436.98022,
			3437.88818,
			3439.66382,
			3440.54688,
			3442.30347,
			3444.012,
			3445.6853,
			3446.50928,
			3448.13452,
			3449.72656,
			3451.29248,
			3452.06738,
			3453.60474,
			3454.36572,
			3455.88086,
			3457.39185,
			3458.90771,
			3460.4397,
			3461.976,
			3463.51855,
			3464.2998,
			3465.86768,
			3467.439,
			3468.22632,
			3469.80347,
			3471.38281,
			3472.96436,
			3473.756,
			3475.36182,
			3476.165,
			3477.77148,
			3479.39087,
			3481.03467,
			3482.67676,
			3484.32324,
			3485.15332,
			3486.8125,
			3488.47119,
			3490.14551,
			3490.98535,
			3492.664,
			3494.34668,
			3496.03955,
			3497.73218,
			3498.57886,
			3500.27979,
			3501.98315,
			3502.83545,
			3504.53687,
			3506.226,
			3507.91846,
			3508.7666,
			3510.43286,
			3512.09473,
			3513.763,
			3515.44434,
			3516.289,
			3517.136,
			3518.837,
			3520.58154,
			3522.34717,
			3524.126,
			3525.88062,
			3526.76123,
			3528.53418,
			3530.30078,
			3532.06348,
			3532.94971,
			3534.73584,
			3535.63965,
			3537.4585,
			3539.29272,
			3541.141,
			3543.00342,
			3544.87646,
			3545.81055,
			3547.68848,
			3549.581,
			3550.52881,
			3552.435,
			3554.356,
			3555.32324,
			3557.26831,
			3559.227,
			3561.19775,
			3563.18164,
			3564.179,
			3566.17383,
			3567.17456,
			3569.1853,
			3571.19751,
			3573.21826,
			3575.24854,
			3576.26465,
			3578.30615,
			3580.35938,
			3582.417,
			3584.47363,
			3586.558,
			3587.60645,
			3589.7124,
			3591.84448,
			3592.91528,
			3595.0647,
			3596.143,
			3598.30664,
			3600.481,
			3602.66626,
			3603.7627,
			3605.95825,
			3608.15625,
			3610.36353,
			3611.46631,
			3613.6792,
			3614.78931,
			3617.001,
			3618.1084,
			3620.33,
			3621.44238,
			3623.67285,
			3624.79126,
			3627.039,
			3629.29688,
			3631.57617,
			3633.876,
			3635.0293,
			3637.35986,
			3638.53027,
			3640.877,
			3642.05151,
			3644.406,
			3645.58643,
			3647.95679,
			3650.33545,
			3652.72021,
			3655.113,
			3657.51074,
			3658.712,
			3661.12134,
			3663.5415,
			3664.755,
			3667.19043,
			3668.413,
			3670.86377,
			3673.32861,
			3674.56372,
			3677.04224,
			3679.53223,
			3680.78052,
			3683.28442,
			3684.53955,
			3687.03564,
			3688.29761,
			3690.84863,
			3693.395,
			3694.67188,
			3697.23486,
			3699.81152,
			3702.39258,
			3703.683,
			3706.26953,
			3708.86133,
			3711.439,
			3714.05273,
			3715.36182,
			3718.00879,
			3720.64478,
			3723.296,
			3724.62646,
			3727.26978,
			3729.93677,
			3731.273,
			3733.94922,
			3736.63257,
			3739.323,
			3740.671,
			3743.37183,
			3746.07959,
			3748.79,
			3751.506,
			3752.862,
			3755.57227,
			3756.93018,
			3759.64355,
			3762.36328,
			3765.09082,
			3767.826,
			3769.19849,
			3771.94922,
			3774.712,
			3777.483,
			3780.27026,
			3781.6665,
			3784.46387,
			3787.26855,
			3790.06641,
			3791.4668,
			3794.27026,
			3795.67383,
			3798.48657,
			3801.307,
			3802.71973,
			3805.55127,
			3808.38965,
			3811.23438,
			3814.087,
			3816.94629,
			3819.81445,
			3821.25146,
			3824.131,
			3827.019,
			3828.46533,
			3831.36353,
			3834.26855,
			3837.18115,
			3840.10376,
			3841.56836,
			3844.503,
			3847.445,
			3850.39038,
			3851.86475,
			3854.822,
			3856.30542,
			3859.27783,
			3862.2583,
			3863.75146,
			3866.74854,
			3869.7522,
			3872.7627,
			3875.7666,
			3877.268,
			3880.276,
			3883.29,
			3886.31323,
			3887.82739,
			3890.8623,
			3892.38257,
			3895.42529,
			3898.47241,
			3901.52344,
			3903.051,
			3904.58032,
			3907.643,
			3909.17676,
			3912.248,
			3915.32568,
			3918.41,
			3919.95361,
			3923.045,
			3926.141,
			3927.69141,
			3930.79639,
			3932.35083,
			3934.63428
		],
		[
			0,
			0.0507202148,
			0.09603882,
			0.126708984,
			0.17401123,
			0.220428467,
			0.266052246,
			0.31237793,
			0.357696533,
			0.38885498,
			0.4210205,
			0.4657898,
			0.512146,
			0.557373047,
			0.6016846,
			0.65045166,
			0.6976929,
			0.7435303,
			0.7897339,
			0.834625244,
			0.8673096,
			0.91204834,
			0.95980835,
			1.00213623,
			1.03536987,
			1.08206177,
			1.12838745,
			1.160553,
			1.17416382,
			1.22189331,
			1.26715088,
			1.315979,
			1.36230469,
			1.40704346,
			1.43481445,
			1.48400879,
			1.530304,
			1.57714844,
			1.6229248,
			1.666687,
			1.69992065,
			1.7467041,
			1.78994751,
			1.83428955,
			1.88250732,
			1.92932129,
			1.96057129,
			2.00732422,
			2.05474854,
			2.099884,
			2.14672852,
			2.19406128,
			2.223175,
			2.267517,
			2.30072021,
			2.34841919,
			2.39373779,
			2.44055176,
			2.486908,
			2.53158569,
			2.563263,
			2.61160278,
			2.65588379,
			2.68817139,
			2.7333374,
			2.74996948,
			2.79525757,
			2.84460449,
			2.88882446,
			2.92071533,
			2.96737671,
			2.99798584,
			3.0423584,
			3.08853149,
			3.1348877,
			3.18359375,
			3.22790527,
			3.25708,
			3.3024292,
			3.35067749,
			3.39892578,
			3.44271851,
			3.49053955,
			3.52172852,
			3.567566,
			3.61581421,
			3.66214,
			3.70675659,
			3.75372314,
			3.78479,
			3.83209229,
			3.87692261,
			3.923645,
			3.96853638,
			3.99963379,
			4.046417,
			4.091217,
			4.13497925,
			4.185272,
			4.232086,
			4.276367,
			4.306061,
			4.354492,
			4.398102,
			4.444458,
			4.48965454,
			4.53540039,
			4.56817627,
			4.61434937,
			4.66360474,
			4.70687866,
			4.75473,
			4.799835,
			4.833008,
			4.86322,
			4.9085083,
			4.93966675,
			4.98440552,
			5.03115845,
			5.078949,
			5.13931274,
			5.18505859,
			5.23181152,
			5.28057861,
			5.32485962,
			5.3550415,
			5.389221,
			5.431946,
			5.476715,
			5.523987,
			5.56835938,
			5.61502075,
			5.66333,
			5.70809937,
			5.75552368,
			5.8006897,
			5.84939575,
			5.881073,
			5.91177368,
			5.956024,
			6.00085449,
			6.04754639,
			6.07922363,
			6.12402344,
			6.1416626,
			6.187561,
			6.23434448,
			6.28103638,
			6.311249,
			6.35849,
			6.400818,
			6.45101929,
			6.49478149,
			6.52749634,
			6.573242,
			6.618469,
			6.6668396,
			6.712036,
			6.759857,
			6.80651855,
			6.834839,
			6.883026,
			6.928314,
			6.96057129,
			7.0071106,
			7.05151367,
			7.09927368,
			7.14559937,
			7.191864,
			7.239105,
			7.282898,
			7.327667,
			7.37445068,
			7.422699,
			7.452362,
			7.499634,
			7.543915,
			7.591675,
			7.63452148,
			7.6836853,
			7.71487427,
			7.761688,
			7.807434,
			7.852173,
			7.89944458,
			7.94619751,
			7.97692871,
			8.022156,
			8.069519,
			8.099091,
			8.147339,
			8.19516,
			8.238892,
			8.284149,
			8.332947,
			8.37973,
			8.424011,
			8.472565,
			8.502533,
			8.549683,
			8.594025,
			8.640442,
			8.686005,
			8.730255,
			8.762939,
			8.792664,
			8.839386,
			8.884155,
			8.932922,
			8.962616,
			9.00692749,
			9.022461,
			9.06778,
			9.117004,
			9.164795,
			9.212097,
			9.256348,
			9.2890625,
			9.31723,
			9.365021,
			9.396759,
			9.440521,
			9.487946,
			9.532074,
			9.548187,
			9.593994,
			9.640808,
			9.688568,
			9.733826,
			9.778656,
			9.811859,
			9.858643,
			9.900482,
			9.950195,
			9.993469,
			10.0422668,
			10.0734863,
			10.1204529,
			10.1650391,
			10.2113342,
			10.2591248,
			10.3059387,
			10.334198,
			10.3824768,
			10.4126892,
			10.4564514,
			10.5023193,
			10.5485229,
			10.5958557,
			10.6426086,
			10.690918,
			10.7342529,
			10.7834778,
			10.8292847,
			10.8599854,
			10.8901672,
			10.9360046,
			10.9842224,
			11.0305176,
			11.0768433,
			11.1065369,
			11.1221313,
			11.16748,
			11.2171936,
			11.2620239,
			11.3068237,
			11.3520508,
			11.38327,
			11.4325562,
			11.4637756,
			11.5095825,
			11.555481,
			11.600708,
			11.6323547,
			11.6464539,
			11.69223,
			11.7397766,
			11.7864075,
			11.8325806,
			11.8774109,
			11.9085693,
			11.9390564,
			11.9850464,
			12.0328369,
			12.0791626,
			12.124939,
			12.1671753,
			12.2149658,
			12.2632446,
			12.309082,
			12.3412781,
			12.3877258,
			12.4318237,
			12.46405,
			12.5108643,
			12.5552368,
			12.6015015,
			12.6482239,
			12.69455,
			12.7397766,
			12.78772,
			12.83194,
			12.8787231,
			12.9269714,
			12.9566956,
			12.9884949,
			13.0331421,
			13.0800171,
			13.1237488,
			13.1716919,
			13.2173462,
			13.2651062,
			13.448761,
			13.4804993,
			13.5267944,
			13.572052,
			13.6188354,
			13.6626282,
			13.7119141,
			13.7401428,
			13.7879639,
			13.8170471,
			13.8643494,
			13.9116821,
			13.9573975,
			14.0021973,
			14.0488892,
			14.0961609,
			14.1424866,
			14.1741638,
			14.2210388,
			14.2666626,
			14.312439,
			14.3581848,
			14.4041443,
			14.4507141,
			14.4949951,
			14.526123,
			14.569,
			14.6181641,
			14.6629333,
			14.70874,
			14.75647,
			14.7846069,
			14.8323669,
			14.8766479,
			14.9224548,
			14.9666443,
			15.0149231,
			15.0466309,
			15.0933838,
			15.1352234,
			15.1854248,
			15.2316895,
			15.2780151,
			15.3092346,
			15.3397217,
			15.387207,
			15.43338,
			15.4802246,
			15.526947,
			15.573761,
			15.6048279,
			15.6506348,
			15.6968994,
			15.7441406,
			15.7893982,
			15.8352051,
			15.8833923,
			15.9266663,
			15.9749756,
			16.020813,
			16.066925,
			16.0991211,
			16.14389,
			16.1911926,
			16.2359924,
			16.2807,
			16.3279724,
			16.35962,
			16.403595,
			16.4516,
			16.4973755,
			16.5421753,
			16.58841,
			16.6203,
			16.6648254,
			16.7126465,
			16.7589111,
			16.8021851,
			16.8493958,
			16.8815613,
			16.9118347,
			16.9595642,
			17.00467,
			17.0520325,
			17.0978088,
			17.1435852,
			17.1893616,
			17.2357178,
			17.283905,
			17.3281555,
			17.3754578,
			17.40216,
			17.45285,
			17.5001221,
			17.5439453,
			17.5916748,
			17.638,
			17.666687,
			17.69928,
			17.745636,
			17.7903442,
			17.8346558,
			17.868866,
			17.91504,
			17.9301453,
			17.9769287,
			18.0231934,
			18.06903,
			18.1137085,
			18.16101,
			18.1906433,
			18.2339783,
			18.2811584,
			18.3299255,
			18.3742371,
			18.4214478,
			18.4521179,
			18.4983826,
			18.5447083,
			18.5914612,
			18.6375427,
			18.6824646,
			18.71466,
			18.75998,
			18.7891541,
			18.8383179,
			18.8682251,
			18.9129639,
			18.94516,
			18.97583,
			19.0221252,
			19.06845,
			19.113678,
			19.1599426,
			19.2077637,
			19.2344666,
			19.26767,
			19.3139038,
			19.35971,
			19.406311,
			19.4347534,
			19.4854736,
			19.4995728,
			19.546875,
			19.5946655,
			19.6399536,
			19.6681824,
			19.7178955,
			19.76419,
			19.8085327,
			19.8573,
			19.90512,
			19.94986,
			19.9971619,
			20.0268555,
			20.0726624,
			20.120575,
			20.1521,
			20.1973877,
			20.2437134,
			20.28946,
			20.3379517,
			20.3830566,
			20.4308472,
			20.4600525,
			20.5061646,
			20.5510864,
			20.5973816,
			20.63211,
			20.6763916,
			20.723175,
			20.7685242,
			20.81723,
			20.8625488,
			20.9088745,
			20.9405518,
			20.9848022,
			21.0321045,
			21.0789185,
			21.12622,
			21.1723633,
			21.2167358,
			21.2640686,
			21.3093872,
			21.3401184,
			21.38379,
			21.4326172,
			21.4768677,
			21.52417,
			21.5676575,
			21.59961,
			21.6464539,
			21.6937256,
			21.73938,
			21.78479,
			21.8315735,
			21.86377,
			21.9065857,
			21.9358215,
			21.9860229,
			22.0298157,
			22.0781555,
			22.106842,
			22.122406,
			22.1682739,
			22.2160034,
			22.2622986,
			22.3081055,
			22.3553772,
			22.38504,
			22.4323425,
			22.4766235,
			22.5249329,
			22.5716553,
			22.6169434,
			22.6481323,
			22.6949768,
			22.7407837,
			22.7854919,
			22.8161926,
			22.86444,
			22.9087219,
			22.9397888,
			22.9841614,
			23.0329285,
			23.07669,
			23.122467,
			23.1677856,
			23.2144775,
			23.2602844,
			23.306366,
			23.3522339,
			23.3994751,
			23.4276428,
			23.4749451,
			23.5226746,
			23.5679932,
			23.5985718,
			23.64331,
			23.6895752,
			23.7348938,
			23.7830811,
			23.827301,
			23.8730774,
			23.921936,
			23.9536133,
			23.9992676,
			24.04602,
			24.0932617,
			24.1410828,
			24.1870117,
			24.21695,
			24.2637329,
			24.3094788,
			24.33963,
			24.3865356,
			24.4160461,
			24.4633789,
			24.52417,
			24.57129,
			24.6000977,
			24.6483459,
			24.6930847,
			24.7408752,
			24.7850952,
			24.8328552,
			24.8800964,
			24.9278564,
			24.9732971,
			25.0003052,
			25.0499878,
			25.0806885,
			25.1274414,
			25.1600647,
			25.1947937,
			25.243042,
			25.30362,
			25.3506165,
			25.39737,
			25.4290466,
			25.4733276,
			25.5175171,
			25.5662537,
			25.5950317,
			25.6411743,
			25.68991,
			25.7347412,
			25.7799377,
			25.827179,
			25.8728027,
			25.91867,
			25.9479065,
			25.9935913,
			26.040863,
			26.0850525,
			26.1322937,
			26.1776123,
			26.22284,
			26.2719421,
			26.3023376,
			26.3470154,
			26.39325,
			26.4400024,
			26.4857178,
			26.53247,
			26.5626526,
			26.608429,
			26.6395264,
			26.68695,
			26.7330322,
			26.76123,
			26.80896,
			26.8240356,
			26.8678589,
			26.9150085,
			26.9471741,
			26.993927,
			27.0407715,
			27.0684814,
			27.1339111,
			27.1796265,
			27.22638,
			27.272644,
			27.3173828,
			27.3485413,
			27.37973,
			27.4249878,
			27.47168,
			27.5179443,
			27.54712,
			27.59439,
			27.6558533,
			27.700531,
			27.73059,
			27.7773743,
			27.8251343,
			27.8668213,
			27.9146118,
			27.9623718,
			28.00653,
			28.0523682,
			28.09961,
			28.1322937,
			28.177063,
			28.2217712,
			28.2670288,
			28.31482,
			28.3616028,
			28.39029,
			28.4350586,
			28.4847412,
			28.5305481,
			28.5768433,
			28.62259,
			28.655304,
			28.7005615,
			28.7468567,
			28.792572,
			28.8354187,
			28.8825378,
			28.9151917,
			28.96399,
			29.0097351,
			29.0414429,
			29.0878,
			29.1345215,
			29.1826782,
			29.22696,
			29.2752686,
			29.32306,
			29.3657837,
			29.41455,
			29.44226,
			29.4909668,
			29.538269,
			29.5849915,
			29.6307373,
			29.677002,
			29.7077026,
			29.7355347,
			29.7845764,
			29.8147583,
			29.8605347,
			29.9067383,
			29.9520569,
			29.9682617,
			30.0134277,
			30.06076,
			30.1064758,
			30.15387,
			30.1979675,
			30.2286987,
			30.2744446,
			30.3212585,
			30.3648987,
			30.4141846,
			30.457428,
			30.4885864,
			30.5203857,
			30.5670166,
			30.61084,
			30.6571045,
			30.6878357,
			30.7330017,
			30.7481384,
			30.7948914,
			30.8416443,
			30.8848572,
			30.9321289,
			30.9779663,
			31.009613,
			31.055481,
			31.085022,
			31.1322937,
			31.1776123,
			31.2244263,
			31.27121,
			31.3150024,
			31.3618164,
			31.4081116,
			31.43924,
			31.48352,
			31.5297852,
			31.5766,
			31.621582,
			31.6670837,
			31.69928,
			31.7456055,
			31.79187,
			31.82306,
			31.8718262,
			31.9161377,
			31.9618835,
			32.008667,
			32.05591,
			32.08722,
			32.1323242,
			32.1630859,
			32.2073669,
			32.2550964,
			32.30246,
			32.3174438,
			32.36325,
			32.4094849,
			32.4391479,
			32.4833679,
			32.53064,
			32.57794,
			32.62271,
			32.6690674,
			32.7172241,
			32.7615356,
			32.8058167,
			32.8379822,
			32.8847656,
			32.9149475,
			32.9612427,
			33.0079956,
			33.0381775,
			33.08493,
			33.1000061,
			33.1459961,
			33.1911621,
			33.2379456,
			33.2831726,
			33.3299255,
			33.3616333,
			33.38977,
			33.43753,
			33.4833069,
			33.53058,
			33.5768433,
			33.6231079,
			33.66635,
			33.69754,
			33.7462769,
			33.7920837,
			33.83835,
			33.8845825,
			33.93033,
			33.97461,
			34.02188,
			34.0697021,
			34.10092,
			34.14514,
			34.17575,
			34.22403,
			34.26886,
			34.3150024,
			34.34674,
			34.3924561,
			34.4553833,
			34.5021362,
			34.546814,
			34.59305,
			34.6387939,
			34.6725159,
			34.7161865,
			34.7649841,
			34.81076,
			34.8574829,
			34.88513,
			34.9324036,
			34.9776,
			35.02536,
			35.07315,
			35.11685,
			35.1646423,
			35.1958,
			35.24063,
			35.28531,
			35.3320923,
			35.3804,
			35.4261475,
			35.4573975,
			35.50177,
			35.550415,
			35.5951843,
			35.64142,
			35.6896667,
			35.7178345,
			35.7641,
			35.7958069,
			35.8435364,
			35.8893127,
			35.9330139,
			35.9812927,
			36.0271,
			36.0733643,
			36.1216431,
			36.1653748,
			36.21115,
			36.24283,
			36.2886047,
			36.3349,
			36.384613,
			36.4299,
			36.4761353,
			36.5078735,
			36.5532227,
			36.5847473,
			36.6305237,
			36.6778564,
			36.72357,
			36.76938,
			36.815033,
			36.8623047,
			36.90909,
			36.93872,
			36.9866638,
			37.0297241,
			37.0769958,
			37.1247559,
			37.17154,
			37.2167664,
			37.2625732,
			37.2912,
			37.33896,
			37.3847046,
			37.4294739,
			37.4762268,
			37.522644,
			37.552124,
			37.5999146,
			37.6443176,
			37.6889954,
			37.73831,
			37.7845459,
			37.8152161,
			37.8605347,
			37.90634,
			37.9525757,
			37.98529,
			38.0320435,
			38.07483,
			38.1236572,
			38.17102,
			38.21512,
			38.260437,
			38.30725,
			38.3388977,
			38.3851624,
			38.4148254,
			38.4631348,
			38.5074158,
			38.5556641,
			38.6004333,
			38.6311035,
			38.677948,
			38.723175,
			38.7720032,
			38.81723,
			38.86203,
			38.9068,
			38.9389648,
			38.98523,
			39.0320129,
			39.0793152,
			39.12506,
			39.17389,
			39.20453,
			39.2502747,
			39.29654,
			39.34134,
			39.38925,
			39.4354858,
			39.4792175,
			39.5279846,
			39.5727539,
			39.6169739,
			39.64966,
			39.6954041,
			39.7427063,
			39.78894,
			39.8180847,
			39.86389,
			39.909668,
			39.9559326,
			40.0047,
			40.0499878,
			40.0811462,
			40.12494,
			40.17221,
			40.21747,
			40.26477,
			40.3121033,
			40.35739,
			40.38919,
			40.4354248,
			40.48163,
			40.5279236,
			40.5752563,
			40.6212158,
			40.6506653,
			40.6989746,
			40.7467651,
			40.7920837,
			40.8388672,
			40.88513,
			40.93091,
			40.96164,
			41.0079346,
			41.0521545,
			41.1004944,
			41.13217,
			41.17801,
			41.20816,
			41.22223,
			41.27008,
			41.3143,
			41.36261,
			41.4084167,
			41.45468,
			41.48535,
			41.5331726,
			41.57898,
			41.62326,
			41.67154,
			41.7178345,
			41.74701,
			41.79431,
			41.84012,
			41.8728027,
			41.91806,
			41.9643555,
			41.99707,
			42.0574646,
			42.1037,
			42.14853,
			42.1943359,
			42.24066,
			42.2719727,
			42.318573,
			42.3633423,
			42.3970337,
			42.4418335,
			42.489624,
			42.533844,
			42.581665,
			42.62796,
			42.67273,
			42.7221375,
			42.7673645,
			42.7974548,
			42.8447876,
			42.8900757,
			42.9364,
			42.9820557,
			43.0298462,
			43.0610657,
			43.0917358,
			43.13556,
			43.1677551,
			43.2144775,
			43.2603455,
			43.3070679,
			43.3518066,
			43.397583,
			43.4444275,
			43.488678,
			43.5384827,
			43.5847473,
			43.63202,
			43.67981,
			43.7250977,
			43.7726135,
			43.81607,
			43.8482361,
			43.89502,
			43.93878,
			43.9845276,
			44.03183,
			44.078064,
			44.10727,
			44.1399536,
			44.1857,
			44.23047,
			44.27826,
			44.322052,
			44.3688965,
			44.417572,
			44.4638672,
			44.495575,
			44.54184,
			44.57306,
			44.6183167,
			44.6796875,
			44.72751,
			44.7732849,
			44.8180237,
			44.8658447,
			44.8960571,
			44.94232,
			44.9886169,
			45.03598,
			45.081665,
			45.1274719,
			45.157196,
			45.188446,
			45.2337036,
			45.2799072,
			45.3262024,
			45.374054,
			45.4182739,
			45.4655762,
			45.51184,
			45.5440674,
			45.5903625,
			45.6387634,
			45.68344,
			45.72873,
			45.7750549,
			45.8057556,
			45.8536072,
			45.8987732,
			45.94461,
			45.97635,
			46.02359,
			46.0674133,
			46.1141052,
			46.1618958,
			46.20822,
			46.2550354,
			46.3003845,
			46.34909,
			46.39334,
			46.4396667,
			46.47238,
			46.5166321,
			46.56192,
			46.6081848,
			46.655,
			46.7018127,
			46.7324524,
			46.763092,
			46.80844,
			46.8553772,
			46.9006042,
			46.9467468,
			46.9946,
			47.0419,
			47.0715637,
			47.1199036,
			47.1672058,
			47.2118835,
			47.24411,
			47.3069763,
			47.3522644,
			47.3995667,
			47.4448547,
			47.47757,
			47.5238342,
			47.5697327,
			47.6139221,
			47.662262,
			47.70761,
			47.73831,
			47.78769,
			47.8317566,
			47.8805847,
			47.9248352,
			47.9722,
			48.0174255,
			48.0486145,
			48.09543,
			48.14023,
			48.1855164,
			48.23282,
			48.28061,
			48.309845,
			48.35611,
			48.401947,
			48.44815,
			48.49594,
			48.54178,
			48.5721741,
			48.617218,
			48.6625061,
			48.7073059,
			48.74008,
			48.78531,
			48.8144836,
			48.8321838,
			48.87845,
			48.907135,
			48.9523621,
			48.999176,
			49.0469666,
			49.0746765,
			49.1351,
			49.1823425,
			49.2296448,
			49.2749329,
			49.3222351,
			49.3535461,
			49.39969,
			49.44449,
			49.49234,
			49.5396423,
			49.5853577,
			49.61508,
			49.662384,
			49.7061462,
			49.7519226,
			49.7846375,
			49.8309631,
			49.8777771,
			49.9225769,
			49.9709778,
			50.0161438,
			50.06192,
			50.1057434,
			50.1390076,
			50.1847229,
			50.2305,
			50.2743225,
			50.3210754,
			50.3674622,
			50.39908,
			50.44388,
			50.473053,
			50.5213928,
			50.56778,
			50.61496,
			50.6441956,
			50.7060242,
			50.7503357,
			50.7966,
			50.8439636,
			50.88925,
			50.9219055,
			50.9656677,
			51.01248,
			51.0451965,
			51.08896,
			51.12216,
			51.1649475,
			51.2273865,
			51.27365,
			51.3210144,
			51.36783,
			51.4129944,
			51.4447327,
			51.4895325,
			51.53842,
			51.584137,
			51.629364,
			51.6777039,
			51.7089539,
			51.75522,
			51.8005676,
			51.8326721,
			51.8799133,
			51.9252625,
			51.97159,
			52.01877,
			52.06357,
			52.0957336,
			52.1410828,
			52.18747,
			52.2325745,
			52.2798767,
			52.32718,
			52.372467,
			52.4022522,
			52.44998,
			52.4806824,
			52.4962463,
			52.54312,
			52.5898743,
			52.6366272,
			52.681427,
			52.7292175,
			52.75992,
			52.8052063,
			52.83493,
			52.88315,
			52.93045,
			52.97525,
			53.0220642,
			53.06888,
			53.11508,
			53.16098,
			53.190155,
			53.23947,
			53.2847,
			53.3299866,
			53.3747864,
			53.4230652,
			53.46695,
			53.5002136,
			53.5458679,
			53.57614,
			53.6228333,
			53.66873,
			53.71646,
			53.74814,
			53.7934875,
			53.85544,
			53.9007263,
			53.9372253,
			53.9824524,
			54.03,
			54.0771332,
			54.12181,
			54.1520081,
			54.1983337,
			54.2448425,
			54.29039,
			54.33821,
			54.38449,
			54.4152069,
			54.4615479,
			54.50679,
			54.5530853,
			54.5997925,
			54.64679,
			54.67697,
			54.7216644,
			54.76796,
			54.8134155,
			54.8611145,
			54.9063721,
			54.9370728,
			54.98236,
			55.03041,
			55.0744324,
			55.1207275,
			55.1690369,
			55.1987457,
			55.2430725,
			55.2923737,
			55.3371124,
			55.3834076,
			55.43164,
			55.4599457,
			55.5067444,
			55.5520325,
			55.5992432,
			55.6426544,
			55.6914368,
			55.7221222,
			55.7679138,
			55.81079,
			55.858,
			55.9032745,
			55.95108,
			55.9825745,
			56.02762,
			56.0738831,
			56.120697,
			56.1654053,
			56.2128754,
			56.243576,
			56.28723,
			56.3350372,
			56.3789825,
			56.4266357,
			56.47339,
			56.50206,
			56.5489655,
			56.59311,
			56.6242676,
			56.6710358,
			56.7193451,
			56.76561,
			56.81099,
			56.8561554,
			56.90193,
			56.9487152,
			56.9945374,
			57.0261841,
			57.0714569,
			57.11772,
			57.16472,
			57.210907,
			57.2570953,
			57.287735,
			57.3325043,
			57.3789063,
			57.42302,
			57.4708252,
			57.5165863,
			57.5466156,
			57.59256,
			57.6393127,
			57.68358,
			57.71576,
			57.7615356,
			57.80777,
			57.8515472,
			57.9003448,
			57.9427032,
			57.9903564,
			58.0356445,
			58.06833,
			58.1133575,
			58.1599274,
			58.2046356,
			58.23633,
			58.28276,
			58.32634,
			58.374115,
			58.4198761,
			58.4651947,
			58.51111,
			58.5577545,
			58.5889435,
			58.6342,
			58.6806946,
			58.7268066,
			58.7740631,
			58.8222961,
			58.8539429,
			58.88301,
			58.92955,
			58.97676,
			59.0234833,
			59.0683136,
			59.1151276,
			59.1469269,
			59.19214,
			59.23842,
			59.2867126,
			59.33235,
			59.3768463,
			59.4250641,
			59.4713135,
			59.5175934,
			59.5649872,
			59.6102448,
			59.64186,
			59.6876068,
			59.718277,
			59.765564,
			59.81151,
			59.8560944,
			59.8873,
			59.90338,
			59.9486542,
			59.9955,
			60.0417328,
			60.07289,
			60.1176758,
			60.1655121,
			60.20923,
			60.2559967,
			60.3037872,
			60.34964,
			60.3932953,
			60.42653,
			60.47278,
			60.51854,
			60.565155,
			60.6112976,
			60.6563873,
			60.68657,
			60.7187653,
			60.7648926,
			60.8098755,
			60.8565826,
			60.88527,
			60.9302673,
			60.94867,
			60.9929352,
			61.0392,
			61.0859833,
			61.13144,
			61.1797333,
			61.2077179,
			61.2550049,
			61.3018036,
			61.34813,
			61.39238,
			61.4411469,
			61.4708252,
			61.5171051,
			61.5654144,
			61.609787,
			61.657486,
			61.7028046,
			61.7330933,
			61.7799377,
			61.8270721,
			61.87033,
			61.9181366,
			61.964035,
			61.99466,
			62.0419464,
			62.08824,
			62.13449,
			62.1817932,
			62.22609,
			62.2577667,
			62.302536,
			62.3342438,
			62.3802643,
			62.4263458,
			62.47162,
			62.51741,
			62.56523,
			62.6091,
			62.65628,
			62.7035675,
			62.74887,
			62.7806854,
			62.82637,
			62.8721466,
			62.9169159,
			62.9647522,
			63.00911,
			63.0406952,
			63.0865021,
			63.1322479,
			63.1770935,
			63.2238159,
			63.27159,
			63.30179,
			63.3476868,
			63.3763275,
			63.4235229,
			63.4688263,
			63.5151367,
			63.5483551,
			63.6077271,
			63.65503,
			63.7023468,
			63.74916,
			63.77896,
			63.82614,
			63.871933,
			63.9182129,
			63.9665527,
			64.0109253,
			64.05762,
			64.0872955,
			64.13251,
			64.1795349,
			64.22673,
			64.27402,
			64.3188,
			64.34801,
			64.3938,
			64.44209,
			64.47278,
			64.5185547,
			64.5640259,
			64.60919,
			64.6539154,
			64.70174,
			64.74762,
			64.7928,
			64.83957,
			64.86928,
			64.91591,
			64.94487,
			64.99307,
			65.03833,
			65.08412,
			65.13083,
			65.17679,
			65.224,
			65.27078,
			65.31592,
			65.36354,
			65.394104,
			65.4413757,
			65.4846344,
			65.53058,
			65.57684,
			65.62253,
			65.65474,
			65.7010651,
			65.74835,
			65.7936554,
			65.83942,
			65.8847046,
			65.9168854,
			65.96118,
			66.00694,
			66.05122,
			66.09813,
			66.14334,
			66.17497,
			66.21973,
			66.2685242,
			66.3119659,
			66.35962,
			66.40436,
			66.4360657,
			66.46825,
			66.5137939,
			66.55885,
			66.60515,
			66.6494,
			66.69681,
			66.7431,
			66.78975,
			66.835556,
			66.88486,
			66.93033,
			66.96138,
			67.00659,
			67.05287,
			67.09967,
			67.14456,
			67.19273,
			67.22188,
			67.26817,
			67.31598,
			67.3634644,
			67.4064941,
			67.45479,
			67.4869843,
			67.53052,
			67.57814,
			67.6238556,
			67.67064,
			67.71742,
			67.74538,
			67.77644,
			67.82411,
			67.87088,
			67.91568,
			67.96309,
			68.00925,
			68.0545349,
			68.10182,
			68.14813,
			68.1774,
			68.2245941,
			68.27086,
			68.31766,
			68.36308,
			68.41144,
			68.4565,
			68.5038,
			68.53551,
			68.58176,
			68.62604,
			68.6738,
			68.71907,
			68.765564,
			68.79303,
			68.8408356,
			68.88861,
			68.93388,
			68.97897,
			69.02646,
			69.05711,
			69.0878143,
			69.13353,
			69.17958,
			69.2261658,
			69.2739258,
			69.3192,
			69.3669739,
			69.41344,
			69.45955,
			69.504776,
			69.55104,
			69.58275,
			69.62805,
			69.6742859,
			69.719574,
			69.7517853,
			69.79671,
			69.84184,
			69.89061,
			69.9363861,
			69.98122,
			70.03015,
			70.0737152,
			70.10741,
			70.1521759,
			70.20009,
			70.2438049,
			70.28999,
			70.33827,
			70.36795,
			70.41481,
			70.4605255,
			70.50929,
			70.55304,
			70.60042,
			70.63104,
			70.67644,
			70.72208,
			70.76988,
			70.81569,
			70.85997,
			70.89116,
			70.93744,
			70.98474,
			71.02803,
			71.0748444,
			71.120575,
			71.1517639,
			71.1981049,
			71.24342,
			71.29115,
			71.3349152,
			71.38133,
			71.41354,
			71.45668,
			71.5049744,
			71.55226,
			71.5955658,
			71.64241,
			71.67554,
			71.7207947,
			71.7671051,
			71.8139,
			71.8596649,
			71.9074554,
			71.93663,
			71.9672241,
			72.0132446,
			72.04231,
			72.08908,
			72.1373749,
			72.1821747,
			72.19931,
			72.24463,
			72.2898254,
			72.337616,
			72.38391,
			72.43161,
			72.4625549,
			72.5076752,
			72.55397,
			72.60103,
			72.64717,
			72.69133,
			72.7240143,
			72.7698059,
			72.81558,
			72.84799,
			72.89403,
			72.9388,
			72.98508,
			73.0334,
			73.0772552,
			73.12697,
			73.172226,
			73.21953,
			73.2482147,
			73.292984,
			73.34128,
			73.38806,
			73.4333649,
			73.47977,
			73.5088043,
			73.5551,
			73.58627,
			73.63258,
			73.66502,
			73.70967,
			73.75635,
			73.77194,
			73.81772,
			73.86151,
			73.9088,
			73.95558,
			74.00136,
			74.03209,
			74.07802,
			74.1262054,
			74.1704559,
			74.21727,
			74.26155,
			74.29173,
			74.3390045,
			74.38629,
			74.4321,
			74.47748,
			74.52669,
			74.55786,
			74.60312,
			74.64842,
			74.69371,
			74.7419739,
			74.78725,
			74.816925,
			74.8634,
			74.910614,
			74.95529,
			75.0041351,
			75.0489044,
			75.078125,
			75.11081,
			75.15604,
			75.20279,
			75.24907,
			75.29286,
			75.34012,
			75.38838,
			75.43463,
			75.48192,
			75.5261841,
			75.57498,
			75.63631,
			75.683075,
			75.72951,
			75.775116,
			75.82138,
			75.85104,
			75.89809,
			75.94211,
			75.97377,
			76.02002,
			76.06737,
			76.11342,
			76.1603851,
			76.20764,
			76.25191,
			76.2994843,
			76.34456,
			76.37462,
			76.4198761,
			76.46666,
			76.5135956,
			76.5592041,
			76.6064758,
			76.6356354,
			76.6819153,
			76.72821,
			76.77394,
			76.81871,
			76.8641357,
			76.89317,
			76.9399261,
			76.98518,
			77.03294,
			77.07684,
			77.13078,
			77.1909,
			77.238205,
			77.2850342,
			77.32732,
			77.37509,
			77.40729,
			77.45355,
			77.5003357,
			77.5463257,
			77.5928955,
			77.6371155,
			77.6683044,
			77.71559,
			77.75986,
			77.80614,
			77.8534241,
			77.90021,
			77.92789,
			77.97414,
			78.022934,
			78.0687256,
			78.11479,
			78.1612854,
			78.19351,
			78.2387848,
			78.28403,
			78.32732,
			78.37509,
			78.40726,
			78.4525452,
			78.49835,
			78.5426,
			78.58986,
			78.63661,
			78.684906,
			78.7140961,
			78.76036,
			78.80661,
			78.85338,
			78.8988,
			78.94298,
			78.97612,
			79.02136,
			79.0681152,
			79.11377,
			79.15979,
			79.19095,
			79.238266,
			79.28302,
			79.327774,
			79.37305,
			79.42084,
			79.4655762,
			79.49692,
			79.5436859,
			79.59134,
			79.6356049,
			79.68388,
			79.7308044,
			79.7465057,
			79.7915344,
			79.84033,
			79.88461,
			79.931,
			79.97621,
			80.00729,
			80.03899,
			80.08478,
			80.13159,
			80.17795,
			80.22316,
			80.26845,
			80.31526,
			80.3616,
			80.40782,
			80.45615,
			80.48631,
			80.53212,
			80.57846,
			80.62317,
			80.67149,
			80.71677,
			80.74666,
			80.79326,
			80.8375244,
			80.88583,
			80.9307251,
			80.95981,
			81.0076,
			81.0533752,
			81.09836,
			81.1315155,
			81.17818,
			81.20734,
			81.25462,
			81.3019257,
			81.36133,
			81.40709,
			81.45438,
			81.49971,
			81.5445557,
			81.57622,
			81.62395,
			81.66873,
			81.71654,
			81.76347,
			81.81026,
			81.8413239,
			81.8871155,
			81.9183044,
			81.96472,
			82.00789,
			82.05667,
			82.10297,
			82.147995,
			82.1945648,
			82.2388153,
			82.28563,
			82.33241,
			82.36372,
			82.408905,
			82.4571838,
			82.5044861,
			82.55127,
			82.59459,
			82.62424,
			82.6710358,
			82.7157,
			82.76164,
			82.8089142,
			82.85669,
			82.88638,
			82.93219,
			82.97922,
			83.02425,
			83.07103,
			83.1173248,
			83.1450653,
			83.1933,
			83.23958,
			83.28589,
			83.332,
			83.37752,
			83.40662,
			83.45592,
			83.50221,
			83.54718,
			83.5943,
			83.63954,
			83.67024,
			83.71719,
			83.76485,
			83.80861,
			83.85591,
			83.90372,
			83.9336853,
			83.97777,
			84.02347,
			84.07025,
			84.11609,
			84.164,
			84.19252,
			84.24182,
			84.2870941,
			84.33226,
			84.37778,
			84.41002,
			84.45717,
			84.48836,
			84.53215,
			84.57802,
			84.62421,
			84.6724854,
			84.7192841,
			84.76514,
			84.81091,
			84.8570557,
			84.9038239,
			84.9496,
			84.980835,
			85.0250549,
			85.07127,
			85.11755,
			85.1639557,
			85.1940155,
			85.24129,
			85.28804,
			85.33479,
			85.36798,
			85.4139252,
			85.45897,
			85.50673,
			85.5368958,
			85.58319,
			85.61635,
			85.6606445,
			85.6913,
			85.7370453,
			85.7676849,
			85.8004456,
			85.8442841,
			85.8914642,
			85.93869,
			85.98447,
			86.03137,
			86.06142,
			86.10867,
			86.13986,
			86.18561,
			86.2172852,
			86.26326,
			86.294754,
			86.338974,
			86.38458,
			86.4321,
			86.47688,
			86.52605,
			86.5562,
			86.60297,
			86.6491241,
			86.6940155,
			86.74072,
			86.77089,
			86.81563,
			86.84836,
			86.8936157,
			86.9268,
			86.97153,
			87.01778,
			87.05052,
			87.07773,
			87.12744,
			87.17067,
			87.20184,
			87.24721,
			87.2939453,
			87.34062,
			87.385376,
			87.43233,
			87.46338,
			87.50658,
			87.55534,
			87.60126,
			87.64758,
			87.6930847,
			87.74135,
			87.78761,
			87.83389,
			87.86467,
			87.9104,
			87.95505,
			87.9852142,
			88.03168,
			88.07785,
			88.1235352,
			88.16977,
			88.21655,
			88.26245,
			88.30917,
			88.35483,
			88.3849945,
			88.4328,
			88.46196,
			88.508194,
			88.5554352,
			88.5997,
			88.64659,
			88.69119,
			88.73895,
			88.78522,
			88.83118,
			88.87889,
			88.90794,
			88.95364,
			89.00192,
			89.04796,
			89.0919342,
			89.1396942,
			89.16887,
			89.2176361,
			89.24837,
			89.29462,
			89.3408356,
			89.3856354,
			89.43143,
			89.4767,
			89.52492,
			89.5737152,
			89.60339,
			89.6491852,
			89.69614,
			89.74277,
			89.78853,
			89.83336,
			89.88162,
			89.9274139,
			89.9596252,
			90.00386,
			90.05115,
			90.09801,
			90.1412048,
			90.19,
			90.22069,
			90.2523956,
			90.29732,
			90.34509,
			90.39024,
			90.436554,
			90.4823456,
			90.52663,
			90.5593262,
			90.6025848,
			90.65088,
			90.69739,
			90.7263641,
			90.78772,
			90.81793,
			90.86502,
			90.90958,
			90.95677,
			90.9864655,
			91.00256,
			91.0480042,
			91.09366,
			91.13991,
			91.18518,
			91.23062,
			91.26218,
			91.3089447,
			91.35722,
			91.3874054,
			91.43291,
			91.4613953,
			91.4931
		]
	],
	'8366_8367_7982': [
		[
			110.485435
		],
		[
			0
		],
		[
			0,
			0.4814453,
			4.45676231,
			8.434085,
			10.4229183,
			14.4016,
			18.3813286,
			22.3621044,
			26.34364,
			30.325922,
			32.3174934,
			36.3017426,
			40.2877731,
			42.2811661,
			46.2685776,
			50.2561073,
			52.24997,
			56.237133,
			60.2248268,
			64.2133,
			68.236145,
			72.22726,
			74.22277,
			78.18104,
			80.1774445,
			84.1711655,
			88.16585,
			90.1630249,
			94.15712,
			98.18378,
			102.1759,
			106.1683,
			110.127052,
			114.119095,
			116.115417,
			120.108543,
			124.101883,
			126.098839,
			130.092957,
			134.086777,
			136.082825,
			140.07489,
			144.066818,
			148.090637,
			152.080643,
			156.037872,
			158.033279,
			162.025253,
			166.019333,
			168.01712,
			172.0462,
			176.009781,
			178.0086,
			182.006866,
			186.0361,
			190.025345,
			194.0013,
			197.954071,
			201.876709,
			205.7614,
			209.602478,
			213.408066,
			217.176208,
			219.0474,
			222.722366,
			226.385651,
			228.2319,
			231.836487,
			235.4047,
			237.166351,
			240.6595,
			244.098663,
			247.472916,
			250.837646,
			254.161987,
			255.809753,
			259.073822,
			262.301422,
			263.902039,
			267.0804,
			270.2557,
			271.818756,
			274.920074,
			277.981842,
			281.007324,
			283.994629,
			286.951416,
			288.4187,
			291.3294,
			294.218475,
			295.65033,
			298.502045,
			301.324524,
			302.726074,
			305.51358,
			308.254822,
			311.038361,
			313.789856,
			316.546722,
			317.926178,
			320.6879,
			323.453033,
			324.8351,
			327.593445,
			330.338959,
			331.705444,
			334.391418,
			337.070343,
			339.738953,
			342.351685,
			344.933258,
			346.2113,
			348.743469,
			351.223663,
			352.463654,
			354.9226,
			357.346619,
			358.548553,
			360.925537,
			363.270416,
			365.5863,
			367.8707,
			370.125122,
			371.23996,
			373.44162,
			375.605927,
			376.674316,
			378.786621,
			380.863922,
			381.8888,
			383.91037,
			385.895142,
			387.8447,
			389.758759,
			391.638245,
			392.565369,
			394.394226,
			395.2964,
			397.07605,
			398.8224,
			400.532654,
			401.37442,
			403.031036,
			404.651733,
			406.2377,
			407.7889,
			408.550385,
			410.0478,
			411.511475,
			412.2308,
			413.652466,
			415.0262,
			416.3543,
			417.011261,
			418.298767,
			419.554565,
			420.797668,
			421.4076,
			422.6154,
			423.80545,
			424.3953,
			425.567322,
			426.730957,
			427.887848,
			429.037018,
			429.608215,
			430.745,
			431.875916,
			432.439819,
			433.5641,
			434.681061,
			435.237457,
			436.347382,
			437.4552,
			438.562225,
			439.664764,
			440.7642,
			441.313477,
			442.412445,
			443.520477,
			444.075562,
			445.184631,
			446.2945,
			446.850464,
			447.9665,
			449.0983,
			450.231018,
			451.367249,
			452.5094,
			453.084778,
			454.245819,
			455.4121,
			455.997864,
			457.1757,
			458.367,
			458.969147,
			460.180054,
			461.4004,
			462.6315,
			463.886963,
			465.1576,
			465.797119,
			467.0857,
			468.392181,
			469.055573,
			470.3919,
			471.742371,
			472.423431,
			473.804016,
			475.204865,
			476.621277,
			478.054352,
			479.5139,
			480.249268,
			481.731781,
			483.231262,
			490.193176,
			490.984863,
			492.579254,
			494.18457,
			495.800354,
			497.430084,
			498.250519,
			499.8947,
			501.5516,
			503.2211,
			504.888428,
			505.7212,
			507.3932,
			508.234436,
			509.926,
			511.62854,
			513.344238,
			514.206848,
			515.940552,
			517.686157,
			519.4441,
			521.213135,
			522.994,
			523.8889,
			525.685669,
			526.5886,
			528.403931,
			530.231567,
			532.070557,
			533.9212,
			534.8513,
			536.71936,
			537.6574,
			539.5424,
			541.4381,
			543.345,
			545.263,
			546.226,
			548.1607,
			550.1103,
			552.0728,
			554.0465,
			555.037964,
			557.0286,
			559.0301,
			561.0402,
			562.0491,
			564.0745,
			566.1238,
			567.1523,
			569.2167,
			571.303345,
			573.3909,
			575.4762,
			576.534851,
			577.5958,
			579.724243,
			581.8601,
			584.0054,
			586.1537,
			588.3097,
			589.391357,
			591.5586,
			593.7354,
			594.8278,
			597.024048,
			599.249,
			601.4723,
			603.706543,
			605.9562,
			607.087036,
			609.3549,
			610.491638,
			612.7733,
			615.06366,
			617.360535,
			619.663269,
			621.9695,
			623.124451,
			625.4395,
			627.759644,
			628.9225,
			631.234741,
			633.575,
			634.748535,
			637.1022,
			639.463867,
			641.833252,
			644.212,
			646.6002,
			647.7975,
			650.199036,
			652.610046,
			655.030151,
			656.2433,
			658.6753,
			659.8944,
			662.3384,
			664.7906,
			667.2516,
			669.7212,
			672.1991,
			673.4408,
			675.9312,
			678.4508,
			679.703552,
			682.2155,
			684.714966,
			685.9783,
			688.5116,
			691.0522,
			693.6193,
			696.1733,
			698.7367,
			700.021362,
			702.597168,
			703.8883,
			706.474854,
			709.0583,
			711.6445,
			712.9403,
			715.5154,
			716.8161,
			719.445435,
			722.0597,
			724.6805,
			725.9933,
			728.624634,
			731.263,
			733.9088,
			736.5615,
			737.890442,
			740.5526,
			743.221436,
			744.5583,
			747.236938,
			749.924438,
			752.5964,
			753.947266,
			756.6538,
			759.3679,
			762.0891,
			764.8168,
			767.551,
			768.9209,
			771.6658,
			774.4179,
			775.796631,
			778.5598,
			781.3325,
			782.7213,
			785.504639,
			788.2948,
			791.0919,
			793.8959,
			795.3003,
			798.1124,
			799.5188,
			802.3297,
			805.1284,
			807.9057,
			810.6539,
			812.015137,
			814.710144,
			817.3677,
			819.9878,
			821.283569,
			823.846863,
			826.3725,
			827.62146,
			830.0919,
			832.524841,
			834.922852,
			837.286743,
			838.4558,
			840.767334,
			843.0405,
			845.2766,
			847.475,
			848.56,
			850.702,
			851.7589,
			853.8448,
			855.893555,
			857.90564,
			859.880737,
			860.8546,
			862.7749,
			864.6585,
			866.5054,
			868.3178,
			869.2115,
			870.096558,
			871.8408,
			873.549561,
			874.3901,
			876.039734,
			877.6516,
			879.226868,
			880.0002,
			881.5149,
			882.258545,
			883.7188,
			885.1423,
			886.526245,
			887.876343,
			889.1937,
			890.4783,
			891.108948,
			892.35,
			892.9708,
			894.1768,
			895.3696,
			896.553833,
			897.7307,
			898.906,
			899.4937,
			900.6748,
			901.8596,
			902.45166,
			903.6361,
			904.821838,
			905.4159,
			906.6024,
			907.7868,
			908.9702,
			910.1542,
			911.3421,
			911.9347,
			913.117554,
			914.2988,
			914.8894,
			916.079468,
			917.2686,
			917.8617,
			919.047,
			920.2324,
			921.428467,
			922.619568,
			923.8069,
			924.399841,
			925.5898,
			926.7971,
			927.3993,
			928.6023,
			929.8052,
			930.4073,
			931.661743,
			932.912964,
			934.162354,
			935.411438,
			936.7373,
			937.400146,
			938.724243,
			939.3858,
			940.7263,
			942.148,
			943.5653,
			944.2731,
			945.70166,
			947.2095,
			948.713135,
			950.2158,
			951.7838,
			952.574,
			954.151733,
			955.739136,
			957.3988,
			959.052246,
			959.8781,
			960.7011,
			962.3179,
			963.928162,
			965.5379,
			966.2991,
			967.809265,
			968.563354,
			970.0726,
			971.539368,
			972.97876,
			974.4192,
			975.858154,
			976.558655,
			977.9607,
			979.366638,
			980.0721,
			981.4557,
			982.815552,
			983.497253,
			984.866943,
			986.2331,
			987.575439,
			988.923767,
			990.2804,
			990.959045,
			992.2915,
			993.629944,
			994.976135,
			995.652649,
			996.9945,
			997.6659,
			999.0144,
			1000.37231,
			1001.05469,
			1002.41431,
			1003.78296,
			1004.47131,
			1005.85773,
			1007.25195,
			1008.65497,
			1010.07043,
			1011.50061,
			1012.21979,
			1013.66858,
			1014.39832,
			1015.86914,
			1017.35645,
			1018.85852,
			1019.61487,
			1021.13892,
			1022.68158,
			1024.2384,
			1025.80847,
			1027.394,
			1028.19189,
			1029.79626,
			1031.41284,
			1032.22607,
			1033.8595,
			1035.50391,
			1036.32666,
			1037.97534,
			1039.63037,
			1041.2981,
			1042.97876,
			1044.67139,
			1045.52222,
			1047.23438,
			1048.96692,
			1049.83765,
			1051.58862,
			1053.35352,
			1054.24072,
			1056.02527,
			1057.822,
			1059.62854,
			1061.447,
			1063.27673,
			1064.19543,
			1066.04187,
			1066.96973,
			1068.83862,
			1070.72412,
			1072.60571,
			1073.56,
			1075.48315,
			1077.418,
			1079.36816,
			1081.33362,
			1082.3208,
			1084.305,
			1086.30347,
			1087.30713,
			1089.323,
			1091.35059,
			1093.3894,
			1094.41382,
			1096.4729,
			1098.5426,
			1100.62292,
			1102.70361,
			1103.74377,
			1105.82959,
			1107.92578,
			1110.03357,
			1111.09253,
			1113.21838,
			1115.35437,
			1116.42664,
			1118.57825,
			1120.74011,
			1122.91211,
			1125.09424,
			1127.28687,
			1128.38684,
			1130.59473,
			1131.70288,
			1133.92664,
			1136.16052,
			1138.4043,
			1139.52991,
			1141.78845,
			1144.05688,
			1146.33508,
			1148.62354,
			1149.77136,
			1152.075,
			1154.38818,
			1155.54834,
			1157.877,
			1160.215,
			1162.56262,
			1163.73975,
			1166.10156,
			1168.473,
			1170.85376,
			1173.24524,
			1174.44458,
			1176.84985,
			1179.26392,
			1180.47437,
			1182.90247,
			1185.3396,
			1187.78564,
			1189.01184,
			1191.47107,
			1193.93945,
			1195.17688,
			1197.65808,
			1200.14783,
			1201.39587,
			1203.89868,
			1206.41,
			1208.92981,
			1211.45776,
			1213.994,
			1215.26526,
			1217.81372,
			1220.371,
			1221.65271,
			1224.2218,
			1226.79834,
			1228.08972,
			1230.67822,
			1233.27441,
			1235.87866,
			1238.48645,
			1241.09436,
			1242.40039,
			1245.01831,
			1247.64343,
			1248.95874,
			1251.59412,
			1254.23657,
			1255.56042,
			1258.21313,
			1260.873,
			1263.53979,
			1266.2135,
			1268.89429,
			1270.23682,
			1272.92834,
			1275.62671,
			1276.97852,
			1279.68774,
			1282.403,
			1283.76318,
			1286.48865,
			1289.22107,
			1291.96045,
			1294.70691,
			1297.45984,
			1298.83887,
			1301.60242,
			1304.373,
			1305.76086,
			1308.542,
			1311.33032,
			1312.727,
			1315.52588,
			1316.92749,
			1319.73608,
			1322.55188,
			1325.37476,
			1328.20386,
			1331.03955,
			1332.46033,
			1335.30676,
			1338.15942,
			1341.01807,
			1342.4502,
			1345.31946,
			1346.75647,
			1349.635,
			1352.52014,
			1355.41052,
			1358.3042,
			1361.2,
			1362.64771,
			1365.54126,
			1368.42407,
			1371.2876,
			1372.71045,
			1375.53589,
			1378.32874,
			1381.08459,
			1383.80469,
			1385.15234,
			1387.82129,
			1390.45593,
			1391.7821,
			1394.36584,
			1396.91638,
			1399.4364,
			1400.68823,
			1403.175,
			1405.62659,
			1408.09277,
			1410.55994,
			1411.79626,
			1414.27319,
			1415.535,
			1418.02148,
			1420.51465,
			1423.01514,
			1425.52271,
			1426.77966,
			1429.30017,
			1431.82788,
			1434.365,
			1435.63623,
			1438.185,
			1440.74231,
			1442.02368,
			1444.59363,
			1447.17078,
			1449.75537,
			1452.34741,
			1453.64612,
			1456.248,
			1458.85681,
			1461.47412,
			1464.09814,
			1465.4137,
			1468.05029,
			1469.37109,
			1472.019,
			1474.67346,
			1477.33643,
			1480.00635,
			1481.344,
			1484.02441,
			1486.71191,
			1489.40649,
			1490.75635,
			1493.462,
			1496.17456,
			1497.53357,
			1500.2561,
			1502.98523,
			1504.35242,
			1507.0918,
			1509.83984,
			1512.59473,
			1515.35376,
			1518.09631,
			1519.48169,
			1522.28052,
			1525.0614,
			1526.45374,
			1529.24316,
			1532.039,
			1534.81665,
			1537.62085,
			1539.02539,
			1541.83752,
			1544.65564,
			1547.47778,
			1548.89136,
			1551.72388,
			1553.14233,
			1555.98413,
			1558.83179,
			1561.68579,
			1564.54578,
			1567.41223,
			1568.84766,
			1571.72388,
			1574.60742,
			1577.49744,
			1578.94507,
			1581.84485,
			1584.75171,
			1586.2074,
			1589.12183,
			1592.04248,
			1594.96777,
			1597.89648,
			1599.36121,
			1602.28564,
			1605.19556,
			1608.08142,
			1609.513,
			1612.34985,
			1615.14844,
			1616.53333,
			1619.2749,
			1621.97852,
			1624.64392,
			1627.27209,
			1628.57214,
			1631.14429,
			1633.679,
			1636.17554,
			1638.6355,
			1639.8512,
			1642.25586,
			1643.44531,
			1645.79834,
			1648.11768,
			1650.399,
			1652.6394,
			1653.74536,
			1655.92883,
			1658.06958,
			1660.17261,
			1661.20972,
			1663.248,
			1664.25366,
			1666.23694,
			1668.17419,
			1670.07471,
			1671.93628,
			1673.75134,
			1674.64648,
			1676.41272,
			1678.13831,
			1679.82935,
			1680.662,
			1682.29858,
			1683.89648,
			1684.68237,
			1686.229,
			1686.98938,
			1688.4928,
			1689.94849,
			1691.37012,
			1692.75757,
			1694.10913,
			1694.77271,
			1696.07739,
			1697.3551,
			1697.98743,
			1699.23315,
			1700.45337,
			1701.65271,
			1702.83679,
			1704.00842,
			1704.58911,
			1705.74365,
			1706.31812,
			1707.46387,
			1708.60779,
			1709.75049,
			1710.32141,
			1711.46411,
			1712.6084,
			1713.75342,
			1714.89746,
			1715.47021,
			1716.61841,
			1717.76953,
			1718.34717,
			1719.50354,
			1720.65979,
			1721.81665,
			1722.39551,
			1723.553,
			1724.70044,
			1725.845,
			1726.988,
			1727.55969,
			1728.70264,
			1729.84155,
			1730.40991,
			1731.54529,
			1732.11255,
			1733.24707,
			1734.384,
			1735.521,
			1736.65576,
			1737.2229,
			1738.35645,
			1739.50171,
			1740.65564,
			1741.23145,
			1742.3811,
			1743.53015,
			1744.69714,
			1745.87207,
			1746.45776,
			1747.62708,
			1748.79553,
			1749.99622,
			1751.2002,
			1751.80078,
			1753.00024,
			1754.20459,
			1754.82629,
			1756.06592,
			1756.68445,
			1757.9198,
			1759.15686,
			1760.44141,
			1761.08154,
			1762.35986,
			1763.63831,
			1764.9397,
			1765.59937,
			1766.2583,
			1767.57642,
			1768.89771,
			1770.22937,
			1771.56567,
			1772.23511,
			1773.57874,
			1774.92493,
			1776.26221,
			1776.93311,
			1778.28052,
			1779.63745,
			1780.31055,
			1781.66211,
			1783.02222,
			1784.39319,
			1785.76965,
			1786.46045,
			1787.85034,
			1789.25293,
			1790.66687,
			1791.377,
			1792.80664,
			1794.25061,
			1794.97864,
			1796.441,
			1797.91736,
			1799.40894,
			1800.91638,
			1801.6748,
			1803.20068,
			1804.73938,
			1806.29248,
			1807.07275,
			1808.6394,
			1809.42639,
			1811.00964,
			1812.60217,
			1814.20313,
			1815.81409,
			1816.62317,
			1818.24524,
			1819.87341,
			1821.50793,
			1823.146,
			1823.96606,
			1825.6084,
			1827.25537,
			1828.07861,
			1829.7251,
			1831.37451,
			1833.029,
			1834.68286,
			1835.51,
			1837.16724,
			1838.82483,
			1839.653,
			1841.30823,
			1842.96521,
			1843.79224,
			1845.444,
			1847.09229,
			1848.73669,
			1850.37732,
			1852.01563,
			1852.83459,
			1854.4707,
			1856.09241,
			1856.91077,
			1858.551,
			1859.374,
			1861.02844,
			1862.69775,
			1864.38916,
			1865.22729,
			1866.905,
			1868.59473,
			1870.28369,
			1871.13989,
			1872.86182,
			1874.59656,
			1876.344,
			1877.22278,
			1878.991,
			1880.77148,
			1881.6665,
			1883.46667,
			1884.37158,
			1886.19043,
			1888.023,
			1888.94421,
			1890.79614,
			1891.727,
			1893.59912,
			1895.48462,
			1897.38293,
			1898.33716,
			1900.25586,
			1902.18677,
			1904.131,
			1906.08862,
			1907.0719,
			1909.04846,
			1911.03809,
			1912.0376,
			1914.045,
			1915.05347,
			1917.07861,
			1919.1156,
			1921.16431,
			1923.22461,
			1925.29565,
			1927.37781,
			1929.47083,
			1930.52051,
			1931.56982,
			1933.66724,
			1935.77,
			1936.825,
			1938.94312,
			1941.07092,
			1943.20923,
			1945.35742,
			1947.51514,
			1949.68311,
			1950.77087,
			1952.95422,
			1954.04956,
			1956.248,
			1958.45667,
			1959.56458,
			1961.78833,
			1964.02185,
			1966.26538,
			1968.51892,
			1970.782,
			1971.91711,
			1974.19446,
			1976.48145,
			1977.62891,
			1979.93066,
			1982.24207,
			1984.56323,
			1986.89429,
			1988.06311,
			1990.40771,
			1992.76184,
			1993.9425,
			1996.31079,
			1998.68884,
			1999.88135,
			2002.2738,
			2004.67456,
			2005.87854,
			2008.293,
			2010.71606,
			2013.14856,
			2015.59033,
			2018.04114,
			2019.26978,
			2021.73315,
			2024.20508,
			2025.44421,
			2027.92883,
			2030.42212,
			2032.924,
			2035.43433,
			2036.69263,
			2039.2157,
			2041.74731,
			2044.28711,
			2045.56018,
			2048.11182,
			2050.66626,
			2051.941,
			2054.47876,
			2055.73877,
			2058.23535,
			2060.69531,
			2063.117,
			2065.50024,
			2067.84619,
			2069.00537,
			2071.295,
			2073.54688,
			2074.65869,
			2076.85474,
			2079.01367,
			2080.079,
			2082.182,
			2084.247,
			2086.27515,
			2087.27539,
			2089.24756,
			2091.18213,
			2093.08,
			2094.94043,
			2095.85767,
			2097.6665,
			2099.44141,
			2100.31641,
			2102.04028,
			2103.72827,
			2105.38184,
			2106.195,
			2107.795,
			2109.36,
			2110.88965,
			2112.38452,
			2113.121,
			2114.57886,
			2115.30078,
			2116.73364,
			2118.16772,
			2119.583,
			2120.99072,
			2121.692,
			2123.08936,
			2124.48633,
			2125.88086,
			2127.272,
			2127.9668,
			2129.365,
			2130.0686,
			2131.47363,
			2132.878,
			2134.28857,
			2135.711,
			2136.421,
			2137.8396,
			2139.2644,
			2140.70947,
			2141.43115,
			2142.874,
			2144.323,
			2145.0603,
			2146.53271,
			2148.00317,
			2149.476,
			2150.95166,
			2151.68848,
			2152.42456,
			2153.89648,
			2155.35229,
			2156.79956,
			2158.24536,
			2158.96777,
			2159.68628,
			2160.39526,
			2161.81,
			2163.22314,
			2164.63477,
			2166.03369,
			2166.73145,
			2168.12524,
			2169.51758,
			2170.90576,
			2171.59814,
			2172.981,
			2173.67188,
			2175.05347,
			2176.4314,
			2177.119,
			2178.49219,
			2179.86426,
			2181.2334,
			2182.59863,
			2183.96167,
			2184.64331,
			2186.00781,
			2187.36816,
			2188.047,
			2189.40356,
			2190.76514,
			2192.12622,
			2193.484,
			2194.84033,
			2195.51978,
			2196.19971,
			2197.558,
			2198.9165,
			2200.27661,
			2200.959,
			2202.3252,
			2203.69141,
			2205.05859,
			2206.43237,
			2207.12231,
			2208.503,
			2209.886,
			2210.57861,
			2211.97144,
			2213.36938,
			2214.06958,
			2215.47339,
			2216.886,
			2218.30664,
			2219.73364,
			2221.168,
			2222.61353,
			2224.067,
			2224.79688,
			2225.52881,
			2227.00171,
			2228.487,
			2229.233,
			2230.73267,
			2231.48657,
			2232.24365,
			2233.76685,
			2234.53271,
			2236.07422,
			2237.63086,
			2239.20166,
			2240.78564,
			2241.58252,
			2243.189,
			2243.9978,
			2245.62427,
			2247.2644,
			2248.91943,
			2250.587,
			2252.26733,
			2253.11328,
			2254.81445,
			2255.66943,
			2257.39014,
			2259.12549,
			2260.87354,
			2262.63477,
			2264.41064,
			2266.19946,
			2267.09888,
			2268.90747,
			2270.73,
			2271.64551,
			2273.48657,
			2274.41357,
			2276.276,
			2278.151,
			2279.094,
			2280.99048,
			2282.89941,
			2284.82275,
			2286.75928,
			2287.73169,
			2289.68726,
			2291.65723,
			2293.63867,
			2294.63428,
			2296.63525,
			2298.647,
			2300.67358,
			2301.69165,
			2303.73584,
			2305.791,
			2307.85718,
			2309.93433,
			2312.02441,
			2313.07227,
			2315.17432,
			2317.28931,
			2318.34766,
			2320.46216,
			2322.584,
			2324.71533,
			2326.83936,
			2327.914,
			2330.08936,
			2332.25635,
			2333.34375,
			2335.525,
			2337.716,
			2338.81519,
			2341.02051,
			2343.234,
			2345.43652,
			2347.66675,
			2349.903,
			2351.02466,
			2353.27515,
			2355.55371,
			2356.6875,
			2358.94165,
			2361.22314,
			2363.51416,
			2364.66235,
			2366.96631,
			2369.27832,
			2371.59814,
			2372.76172,
			2375.094,
			2377.45459,
			2378.62842,
			2380.96216,
			2383.32446,
			2384.50928,
			2386.88574,
			2389.271,
			2391.6853,
			2394.08838,
			2396.48,
			2398.9,
			2400.11328,
			2402.54688,
			2405.00952,
			2406.23315,
			2408.68726,
			2411.15015,
			2413.60059,
			2414.86,
			2417.344,
			2419.83569,
			2422.336,
			2424.84424,
			2427.36,
			2428.621,
			2431.14844,
			2432.415,
			2434.954,
			2437.501,
			2440.05566,
			2441.33569,
			2443.90186,
			2446.45435,
			2449.05713,
			2450.35034,
			2452.94238,
			2455.54175,
			2456.844,
			2459.45044,
			2462.05664,
			2463.36157,
			2465.97681,
			2467.287,
			2468.59863,
			2471.22656,
			2472.543,
			2475.18066,
			2477.82544,
			2480.476,
			2481.8042,
			2483.13379,
			2484.465,
			2487.13184,
			2489.805,
			2492.485,
			2495.17139,
			2496.51685,
			2499.213,
			2501.91528,
			2504.623,
			2505.98047,
			2508.69775,
			2511.42139,
			2512.78516,
			2515.51685,
			2518.25439,
			2520.99658,
			2523.74561,
			2525.12256,
			2527.881,
			2530.645,
			2533.41577,
			2536.19336,
			2537.58447,
			2540.3728,
			2541.7688,
			2544.56641,
			2547.36865,
			2550.17822,
			2552.99414,
			2554.38135,
			2557.20752,
			2560.0415,
			2562.88281,
			2564.30566,
			2567.18018,
			2570.013,
			2571.44385,
			2574.30957,
			2575.74585,
			2578.6228,
			2581.50562,
			2584.41846,
			2585.8645,
			2588.76025,
			2591.662,
			2594.54541,
			2597.46045,
			2598.92041,
			2601.86841,
			2604.79688,
			2606.26318,
			2609.20068,
			2612.144,
			2615.07031,
			2618.05078,
			2621.0127,
			2623.98047,
			2625.46631,
			2628.44263,
			2629.93262,
			2632.892,
			2635.88428,
			2638.882,
			2641.886,
			2644.895,
			2646.42773,
			2649.447,
			2652.473,
			2655.50464,
			2657.022,
			2660.03418,
			2661.58057,
			2664.604,
			2667.683,
			2669.212,
			2672.27319,
			2675.31519,
			2678.38745,
			2681.465,
			2684.548,
			2687.63672,
			2689.20825,
			2692.30273,
			2693.85156,
			2696.95361,
			2700.06079,
			2703.17139,
			2704.72852,
			2707.82129,
			2709.38232,
			2710.9707,
			2714.09863,
			2717.23071,
			2718.79785,
			2721.937,
			2725.055,
			2726.629,
			2729.78076,
			2732.9624,
			2736.12158,
			2739.28467,
			2742.45239,
			2744.03857,
			2747.214,
			2750.36743,
			2751.9585,
			2755.16577,
			2758.35,
			2759.94336,
			2763.13257,
			2766.326,
			2769.52344,
			2772.72656,
			2775.933,
			2777.53784,
			2780.74951,
			2783.93848,
			2785.54761,
			2788.796,
			2790.4082,
			2793.63574,
			2796.867,
			2800.10156,
			2803.31348,
			2806.557,
			2809.83081,
			2811.45557,
			2814.70752,
			2817.96313,
			2819.565,
			2822.82666,
			2826.119,
			2827.752,
			2831.02075,
			2834.293,
			2837.56836,
			2840.84619,
			2844.12744,
			2845.769,
			2847.41162,
			2850.69873,
			2853.98877,
			2857.28149,
			2858.929,
			2862.227,
			2865.52881,
			2867.181,
			2870.48682,
			2873.79248,
			2877.09863,
			2880.415,
			2883.73364,
			2887.048,
			2888.70679,
			2892.02661,
			2895.35327,
			2897.01855,
			2900.35132,
			2903.68628,
			2905.35547,
			2908.69531,
			2912.044,
			2915.39624,
			2917.07153,
			2920.42432,
			2922.103,
			2925.4624,
			2928.8252,
			2932.19214,
			2935.56323,
			2938.93774,
			2940.626,
			2944.00562,
			2947.3894,
			2949.083,
			2952.47437,
			2955.87036,
			2959.2688,
			2960.969,
			2964.372,
			2967.77881,
			2969.4834,
			2972.89526,
			2974.60254,
			2978.0188,
			2981.44,
			2983.15137,
			2984.86426,
			2988.29248,
			2991.72534,
			2995.1626,
			2998.60449,
			3000.32739,
			3003.77466,
			3007.226,
			3010.68115,
			3012.4104,
			3015.84253,
			3019.336,
			3021.06958,
			3024.54,
			3026.27661,
			3029.752,
			3033.20264,
			3036.68652,
			3040.20239,
			3043.69238,
			3047.18726,
			3048.93579,
			3052.435,
			3055.93774,
			3057.69043,
			3061.19849,
			3064.70947,
			3068.224,
			3069.98242,
			3073.50146,
			3075.26221,
			3077.02319,
			3080.54858,
			3084.07666,
			3085.84131,
			3089.37329,
			3092.908,
			3094.67676,
			3098.21631,
			3101.75854,
			3105.30273,
			3107.07617,
			3110.62573,
			3114.17676,
			3117.73047,
			3121.2876,
			3123.067,
			3126.627,
			3130.19019,
			3131.97266,
			3135.54053,
			3139.1106,
			3142.68335,
			3146.25879,
			3149.836,
			3151.62573,
			3155.20654,
			3156.99829,
			3160.58325,
			3164.171,
			3165.96631,
			3169.55859,
			3173.15332,
			3176.75122,
			3180.352,
			3183.92651,
			3187.56445,
			3189.37061,
			3192.98486,
			3196.60278,
			3198.41284,
			3202.03564,
			3205.661,
			3207.445,
			3211.07568,
			3214.709,
			3216.52637,
			3220.19385,
			3223.80322,
			3225.62427,
			3227.44531,
			3231.12085,
			3232.91382,
			3236.56348,
			3240.21777,
			3243.87524,
			3245.706,
			3249.36914,
			3253.03564,
			3256.70532,
			3260.37842,
			3264.05518,
			3265.89453,
			3269.5752,
			3271.41675,
			3275.10156,
			3276.94531,
			3280.63452,
			3282.48047,
			3284.327,
			3288.02148,
			3291.719,
			3295.41943,
			3299.12427,
			3302.831,
			3304.6853,
			3308.39551,
			3310.25171,
			3313.96631,
			3317.68335,
			3321.40356,
			3323.2644,
			3326.98755,
			3328.85,
			3332.577,
			3336.3064,
			3338.172,
			3341.905,
			3343.77246,
			3347.50928,
			3349.379,
			3353.12036,
			3354.99219,
			3358.73755,
			3362.48584,
			3366.23633,
			3368.113,
			3371.86816,
			3375.625,
			3379.38477,
			3381.26514,
			3385.02783,
			3388.79224,
			3392.55933,
			3394.443,
			3398.213,
			3401.98413,
			3403.87061,
			3407.64526,
			3409.53345,
			3413.31152,
			3417.09229,
			3418.9834,
			3420.87524,
			3424.66016,
			3426.55371,
			3430.3418,
			3432.23682,
			3436.028,
			3439.82129,
			3443.61719,
			3447.41479,
			3451.21436,
			3455.016,
			3456.91821,
			3460.72266,
			3464.52979,
			3466.434,
			3470.24414,
			3472.14966,
			3475.9624,
			3479.777,
			3483.59448,
			3487.413,
			3491.23413,
			3495.05713,
			3498.88135,
			3500.79419,
			3502.70752,
			3506.536,
			3510.36646,
			3512.28247,
			3516.115,
			3519.95,
			3523.78687,
			3527.62573,
			3529.54614,
			3533.3877,
			3537.231,
			3539.153,
			3541.076,
			3544.92261,
			3548.771,
			3550.6958,
			3568.03931,
			3571.898,
			3575.75854,
			3579.621,
			3581.55322,
			3585.419,
			3589.28613,
			3591.221,
			3595.09229,
			3598.96436,
			3602.83887,
			3606.71533,
			3608.65356,
			3612.532,
			3616.41162,
			3618.35181,
			3622.23364,
			3626.11719,
			3628.05981,
			3631.94629,
			3635.83447,
			3639.72388,
			3643.61377,
			3647.506,
			3649.45264,
			3653.34521,
			3655.28955,
			3659.16748,
			3663.023,
			3666.84863,
			3668.7478,
			3672.5166,
			3676.245,
			3678.094,
			3681.762,
			3683.581,
			3687.189,
			3688.97754,
			3690.75659,
			3694.28442,
			3697.772,
			3701.21973,
			3702.92871,
			3706.31738,
			3709.66626,
			3712.97559,
			3716.24585,
			3719.47754,
			3721.07861,
			3724.2522,
			3725.82446,
			3728.94043,
			3732.018,
			3735.06,
			3738.06616,
			3739.5564,
			3742.508,
			3745.42041,
			3748.29419,
			3749.71631,
			3752.53174,
			3755.30835,
			3756.68213,
			3759.40039,
			3762.07959,
			3764.72021,
			3767.32324,
			3768.61084,
			3771.157,
			3772.41553,
			3774.90454,
			3777.35669,
			3779.77222,
			3780.967,
			3782.15381,
			3783.33179,
			3785.66187,
			3786.8147,
			3789.09277,
			3791.33374,
			3793.536,
			3795.70068,
			3797.82715,
			3799.91162,
			3800.94043,
			3802.98828,
			3803.98926,
			3805.96558,
			3807.90942,
			3809.82129,
			3811.70215,
			3813.55981,
			3814.48169,
			3816.307,
			3818.11035,
			3819.00659,
			3820.78467,
			3822.54614,
			3823.42236,
			3825.168,
			3826.90332,
			3828.63428,
			3830.36816,
			3832.09766,
			3832.961,
			3834.694,
			3836.43042,
			3837.29858,
			3839.03833,
			3840.79565,
			3841.67432,
			3843.42944,
			3845.18726,
			3846.94751,
			3848.69751,
			3849.58252,
			3851.36865,
			3853.16748,
			3854.98022,
			3855.89355,
			3857.73145,
			3859.582,
			3860.5127,
			3862.37061,
			3864.24121,
			3866.12378,
			3867.05859,
			3868.938,
			3870.82983,
			3872.72339,
			3874.62329,
			3875.57813,
			3877.497,
			3879.42383,
			3880.3916,
			3882.33325,
			3883.32446,
			3885.28076,
			3887.23779,
			3889.19531,
			3890.17578,
			3891.15454,
			3893.10718,
			3894.081,
			3896.02979,
			3897.97168,
			3898.9397,
			3900.873,
			3902.80225,
			3903.76563,
			3905.69482,
			3907.625,
			3909.55518,
			3911.49048,
			3913.42432,
			3914.39136,
			3916.32739,
			3918.2627,
			3920.19775,
			3921.16748,
			3923.10425,
			3924.072,
			3926.00586,
			3927.93628,
			3929.86426,
			3931.79053,
			3932.75244,
			3934.67529,
			3936.59546,
			3937.55371,
			3939.46826,
			3941.38013,
			3943.28613,
			3945.19019,
			3947.0957,
			3948.04736,
			3949.949,
			3950.9,
			3952.80371,
			3954.70459,
			3956.60425,
			3958.502,
			3959.44946,
			3961.343,
			3963.24023,
			3965.135,
			3966.08228,
			3967.983,
			3969.88477,
			3970.836,
			3972.7417,
			3974.65381,
			3976.56738,
			3978.48828,
			3980.41528,
			3981.37964,
			3982.34448,
			3984.288,
			3986.22339,
			3988.18457,
			3989.1748,
			3991.16455,
			3993.16577,
			3995.17969,
			3997.20557,
			3999.238,
			4001.27344,
			4002.29541,
			4004.33862,
			4005.35254,
			4007.38965,
			4009.435,
			4011.47852,
			4012.50464,
			4014.565,
			4016.64648,
			4018.73413,
			4020.825,
			4021.877,
			4023.98828,
			4025.048,
			4027.17871,
			4029.32129,
			4030.396,
			4032.55884,
			4034.73926,
			4036.9292,
			4039.13013,
			4041.341,
			4043.561,
			4044.6748,
			4046.91,
			4049.14941,
			4050.273,
			4052.52661,
			4054.78149,
			4055.9126,
			4058.18262,
			4060.4624,
			4062.75122,
			4063.901,
			4066.20874,
			4068.52637,
			4070.85327,
			4072.02026,
			4074.36133,
			4076.71,
			4079.06787,
			4081.43481,
			4082.62158,
			4085.00146,
			4086.19458,
			4088.58838,
			4090.991,
			4093.40186,
			4095.82129,
			4097.034,
			4099.48633,
			4101.927,
			4104.37646,
			4106.83545,
			4108.069,
			4110.52,
			4112.99951,
			4115.48633,
			4116.733,
			4119.232,
			4120.50439,
			4122.993,
			4125.51074,
			4128.035,
			4129.30029,
			4131.836,
			4134.37842,
			4135.653,
			4138.206,
			4140.76758,
			4143.336,
			4145.912,
			4148.496,
			4149.79053,
			4152.38525,
			4153.686,
			4156.28857,
			4158.891,
			4161.5,
			4164.116,
			4166.73828,
			4169.36768,
			4170.68555,
			4173.325,
			4174.648,
			4177.29932,
			4179.957,
			4181.288,
			4183.957,
			4186.632,
			4189.3125,
			4192.00049,
			4194.694,
			4196.04346,
			4198.74658,
			4201.455,
			4202.81152,
			4205.5293,
			4208.25244,
			4209.616,
			4212.348,
			4215.086,
			4217.83,
			4220.58154,
			4221.96,
			4224.71973,
			4227.484,
			4230.28125,
			4231.672,
			4234.45654,
			4237.245,
			4240.016,
			4242.82031,
			4245.63,
			4247.036,
			4248.44434,
			4251.287,
			4254.112,
			4256.941,
			4258.357,
			4261.19336,
			4264.01172,
			4265.459,
			4268.311,
			4269.73926,
			4272.60156,
			4275.471,
			4276.908,
			4279.78857,
			4281.231,
			4284.11865,
			4287.012,
			4289.911,
			4291.36328,
			4294.27148,
			4297.18555,
			4300.10449,
			4301.56641,
			4304.495,
			4307.43,
			4308.9,
			4311.843,
			4313.317,
			4316.269,
			4319.22656,
			4322.19,
			4325.159,
			4328.133,
			4329.622,
			4332.60449,
			4335.592,
			4338.58447,
			4340.083,
			4343.084,
			4346.09033,
			4349.102,
			4352.119,
			4353.63,
			4356.654,
			4359.683,
			4362.718,
			4364.2373,
			4367.281,
			4368.80469,
			4371.85547,
			4374.91162,
			4377.97363,
			4381.043,
			4384.118,
			4385.657,
			4388.739,
			4391.829,
			4393.376,
			4396.44873,
			4399.58,
			4402.66553,
			4404.223,
			4407.34229,
			4410.469,
			4412.034,
			4415.16943,
			4418.31,
			4421.45459,
			4424.60352,
			4426.17969,
			4429.33643,
			4432.498,
			4434.10742,
			4437.27734,
			4440.451,
			4443.62842,
			4446.78467,
			4449.9707,
			4451.563,
			4454.77539,
			4457.939,
			4459.53467,
			4462.756,
			4465.95361,
			4467.55371,
			4470.75635,
			4473.9624,
			4477.17139,
			4480.383,
			4483.59766,
			4485.206,
			4488.425,
			4490.03564,
			4493.2583,
			4496.48438,
			4499.714,
			4501.32959,
			4504.56445,
			4507.80225,
			4511.04346,
			4514.2876,
			4517.53564,
			4519.161,
			4522.41455,
			4524.043,
			4527.30225,
			4530.56445,
			4532.19727,
			4535.464,
			4538.7373,
			4542.014,
			4545.29,
			4548.57129,
			4550.214,
			4553.50439,
			4556.803,
			4558.454,
			4561.75439,
			4565.05859,
			4568.367,
			4570.02246,
			4571.65039,
			4574.966,
			4578.289,
			4581.61475,
			4584.968,
			4586.63232,
			4589.96533,
			4593.301,
			4594.969,
			4598.31,
			4599.982,
			4603.327,
			4606.676,
			4610.02539,
			4613.379,
			4616.73438,
			4620.095,
			4621.777,
			4625.14453,
			4628.51563,
			4631.89063,
			4633.579,
			4636.96045,
			4638.652,
			4642.038,
			4645.427,
			4648.81836,
			4650.51563,
			4653.91162,
			4657.31,
			4660.71143,
			4662.414,
			4665.82129,
			4669.231,
			4672.644,
			4674.35156,
			4677.77,
			4681.19141,
			4684.61572,
			4686.32861,
			4689.757,
			4691.47168,
			4693.188,
			4696.622,
			4700.05957,
			4701.7793,
			4705.221,
			4708.666,
			4710.389,
			4713.83838,
			4717.291,
			4720.747,
			4722.47754,
			4725.939,
			4729.40234,
			4732.869,
			4736.33838,
			4738.07471,
			4741.549,
			4743.28662,
			4746.76367,
			4750.243,
			4753.726,
			4757.21338,
			4760.704,
			4764.19727,
			4765.946,
			4767.695,
			4771.19531,
			4772.947,
			4776.45166,
			4779.96,
			4783.4707,
			4786.98438,
			4790.49854,
			4792.257,
			4795.77637,
			4799.299,
			4801.06152,
			4806.35254,
			4809.884,
			4811.65039,
			4815.18555,
			4818.72559,
			4820.49756,
			4824.04248,
			4827.58936,
			4831.14063,
			4834.69434,
			4836.47266,
			4840.03,
			4843.58838,
			4845.368,
			4848.93164,
			4852.497,
			4854.28027,
			4857.85,
			4861.393,
			4864.96875,
			4868.57666,
			4872.15527,
			4873.94531,
			4877.5293,
			4881.115,
			4882.91064,
			4886.50146,
			4890.09473,
			4893.691,
			4895.48975,
			4897.289,
			4900.88867,
			4902.68848,
			4906.291,
			4909.89746,
			4913.507,
			4917.11768,
			4920.73047,
			4924.34668,
			4927.96533,
			4929.776,
			4933.39746,
			4935.20947,
			4938.835,
			4942.4624,
			4946.092,
			4949.72559,
			4951.54346,
			4955.18066,
			4958.81934,
			4962.462,
			4966.10742,
			4969.75635,
			4971.58154,
			4973.408,
			4977.0625,
			4980.7207,
			4984.382,
			4986.214,
			4989.87842,
			4991.7124,
			4995.38232,
			4999.05469,
			5002.729,
			5006.40674,
			5008.24658,
			5011.92871,
			5015.61328,
			5019.3,
			5022.98828,
			5024.834,
			5028.52637,
			5030.373,
			5034.07,
			5037.769,
			5041.471,
			5045.17627,
			5047.03,
			5050.73828,
			5054.45,
			5058.16553,
			5061.885,
			5063.745,
			5067.468,
			5069.33,
			5073.05664,
			5076.78564,
			5078.619,
			5082.382,
			5086.115,
			5089.851,
			5093.591,
			5097.3335,
			5099.17627,
			5102.926,
			5106.709,
			5108.586,
			5112.31055,
			5116.06738,
			5117.94727,
			5121.7085,
			5125.471,
			5129.23535,
			5133.002,
			5136.77051,
			5138.657,
			5142.431,
			5144.31934,
			5148.09766,
			5151.88,
			5155.663,
			5157.55566,
			5161.343,
			5165.13232,
			5167.028,
			5170.82129,
			5174.6167,
			5176.51465,
			5180.3125,
			5182.2124,
			5184.1123,
			5187.915,
			5191.71875,
			5195.525,
			5199.333,
			5203.14258,
			5205.048,
			5208.861,
			5212.67627,
			5214.585,
			5218.4043,
			5222.22559,
			5224.13672,
			5227.96,
			5231.78662,
			5235.615,
			5239.446,
			5243.278,
			5245.195,
			5249.03,
			5252.866,
			5256.70459,
			5258.625,
			5262.46631,
			5264.388,
			5268.233,
			5272.079,
			5275.92773,
			5279.77832,
			5283.631,
			5285.558,
			5289.41357,
			5293.27051,
			5295.19971,
			5299.05859,
			5302.91943,
			5304.85059,
			5308.71338,
			5312.579,
			5316.44629,
			5320.31445,
			5324.18359,
			5326.119,
			5329.991,
			5333.86572,
			5335.803,
			5339.67969,
			5343.55664,
			5345.49658,
			5349.37646,
			5353.258,
			5357.14063,
			5361.02441,
			5364.90967,
			5366.853,
			5370.741,
			5374.63037,
			5376.57568,
			5380.468,
			5384.361,
			5386.30859,
			5390.204,
			5394.102,
			5398.00146,
			5401.90234,
			5405.804,
			5407.75635,
			5411.661,
			5415.56641,
			5417.52,
			5421.42773,
			5425.33643,
			5427.29053,
			5431.2,
			5435.111,
			5439.02344,
			5442.93652,
			5446.851,
			5448.80957,
			5452.727,
			5456.64648,
			5458.60645,
			5462.52832,
			5466.45068,
			5468.412,
			5472.337,
			5476.2627,
			5480.189,
			5484.11475,
			5488.04,
			5490.003,
			5493.92725,
			5495.88867,
			5499.845,
			5503.73535,
			5507.66,
			5509.623,
			5513.549,
			5517.473,
			5521.4,
			5525.328,
			5529.255,
			5531.21875,
			5535.153,
			5537.121,
			5541.05859,
			5544.998,
			5548.94141,
			5550.91357,
			5554.859,
			5558.80371,
			5562.74658,
			5566.68848,
			5568.65771,
			5572.62939,
			5576.56445,
			5578.532,
			5582.46729,
			5586.402,
			5590.337,
			5592.304,
			5596.23926,
			5600.17432,
			5604.11035,
			5608.04834,
			5610.018,
			5613.955,
			5617.89258,
			5619.862,
			5623.80029,
			5627.73975,
			5631.679,
			5633.64844,
			5635.617,
			5639.556,
			5643.49463,
			5647.40039,
			5651.37354,
			5655.28369,
			5657.25635,
			5661.20654,
			5665.162,
			5669.123,
			5673.10156,
			5675.09326,
			5679.077,
			5683.04834,
			5686.97754,
			5690.926,
			5692.882,
			5696.834,
			5698.8125,
			5702.73633,
			5706.69434,
			5710.65332,
			5714.614,
			5716.59473,
			5720.591,
			5724.52148,
			5728.486,
			5732.45,
			5734.433,
			5738.4,
			5740.385,
			5742.72559
		],
		[
			0,
			0.0244293213,
			0.07161713,
			0.117294312,
			0.147781372,
			0.19329071,
			0.237564087,
			0.286239624,
			0.332992554,
			0.3788147,
			0.409935,
			0.453674316,
			0.50302887,
			0.5478897,
			0.596466064,
			0.6423569,
			0.6734848,
			0.7181244,
			0.7633896,
			0.811683655,
			0.855438232,
			0.904274,
			0.935882568,
			0.981147766,
			1.01130676,
			1.05870056,
			1.10588074,
			1.15053558,
			1.196785,
			1.24314117,
			1.28881073,
			1.33453369,
			1.38179779,
			1.42757416,
			1.458252,
			1.50505066,
			1.55323029,
			1.60047913,
			1.64677429,
			1.69217682,
			1.72166443,
			1.76841736,
			1.81552887,
			1.8620224,
			1.9082489,
			1.954979,
			1.9851532,
			2.032898,
			2.07821655,
			2.121933,
			2.171257,
			2.217949,
			2.24885559,
			2.29492188,
			2.34918213,
			2.39644623,
			2.44081116,
			2.48693085,
			2.55028534,
			2.59555054,
			2.64183044,
			2.68654633,
			2.733818,
			2.763504,
			2.81083679,
			2.85661316,
			2.9008255,
			2.947319,
			2.99349976,
			3.023796,
			3.07147217,
			3.116188,
			3.1609726,
			3.20980835,
			3.25623322,
			3.28577423,
			3.33052063,
			3.37961578,
			3.42610931,
			3.470543,
			3.51165,
			3.54599762,
			3.59615326,
			3.64139557,
			3.68734741,
			3.73391724,
			3.7782135,
			3.808403,
			3.85688782,
			3.90251923,
			3.94745636,
			3.99401855,
			4.04233551,
			4.072006,
			4.1192627,
			4.16597748,
			4.211296,
			4.25906372,
			4.30596161,
			4.33496857,
			4.380966,
			4.428093,
			4.47441864,
			4.519127,
			4.568939,
			4.59861755,
			4.643425,
			4.689377,
			4.73496246,
			4.78138733,
			4.82764435,
			4.859253,
			4.90506,
			4.95227051,
			4.997093,
			5.043396,
			5.08914948,
			5.12029266,
			5.16607666,
			5.214432,
			5.26119232,
			5.306656,
			5.352257,
			5.381706,
			5.431267,
			5.47657,
			5.523445,
			5.56757355,
			5.61421967,
			5.64780426,
			5.693428,
			5.74025726,
			5.78643036,
			5.83118439,
			5.878502,
			5.907753,
			5.953453,
			5.999748,
			6.046013,
			6.09233856,
			6.137657,
			6.170372,
			6.21385956,
			6.26337433,
			6.30919647,
			6.35494232,
			6.40070343,
			6.43190765,
			6.47771454,
			6.52310944,
			6.56987762,
			6.617058,
			6.66233063,
			6.69216156,
			6.73786163,
			6.78408051,
			6.829857,
			6.87430573,
			6.922432,
			6.953636,
			6.99892426,
			7.044243,
			7.091545,
			7.13736725,
			7.181801,
			7.212776,
			7.25807953,
			7.303444,
			7.350136,
			7.396904,
			7.443718,
			7.47451,
			7.52063751,
			7.565895,
			7.61121368,
			7.65706635,
			7.70482635,
			7.734398,
			7.78366852,
			7.82942963,
			7.875374,
			7.92246246,
			7.968178,
			7.999855,
			8.047417,
			8.092964,
			8.137733,
			8.18589,
			8.232658,
			8.260902,
			8.308144,
			8.353355,
			8.401131,
			8.431801,
			8.479073,
			8.52491,
			8.570656,
			8.617332,
			8.661598,
			8.707863,
			8.755089,
			8.78626251,
			8.831017,
			8.879295,
			8.925056,
			8.971779,
			9.01804352,
			9.046989,
			9.096962,
			9.141792,
			9.187996,
			9.232231,
			9.280754,
			9.312172,
			9.359016,
			9.406883,
			9.630028,
			9.676338,
			9.721062,
			9.768349,
			9.816124,
			9.861,
			9.892128,
			9.936943,
			9.983101,
			10.0298691,
			10.0777206,
			10.1244431,
			10.1530838,
			10.1988449,
			10.2466049,
			10.2934036,
			10.3411636,
			10.3713913,
			10.41906,
			10.4653244,
			10.510643,
			10.5580521,
			10.6036758,
			10.64933,
			10.680809,
			10.7262955,
			10.77317,
			10.8202744,
			10.8660507,
			10.9118118,
			10.941658,
			10.98774,
			11.01844,
			11.06469,
			11.11097,
			11.154686,
			11.2024765,
			11.2490768,
			11.2950363,
			11.3414536,
			11.3885574,
			11.43383,
			11.4649887,
			11.5112991,
			11.5555191,
			11.6049118,
			11.6500626,
			11.6963272,
			11.7265549,
			11.7730179,
			11.8200607,
			11.86631,
			11.9115829,
			11.9574966,
			11.9875259,
			12.0197067,
			12.0654831,
			12.1122818,
			12.1606064,
			12.20533,
			12.2525864,
			12.2988663,
			12.3446426,
			12.3768234,
			12.4210434,
			12.4704361,
			12.5148392,
			12.5609818,
			12.6084061,
			12.6550217,
			12.6998062,
			12.7474136,
			12.7758713,
			12.82206,
			12.8688583,
			12.9134445,
			12.9609451,
			13.00515,
			13.038353,
			13.0830612,
			13.1303482,
			13.1615677,
			13.2054214,
			13.2541275,
			13.3009109,
			13.3475113,
			13.3923264,
			13.4409409,
			13.4860764,
			13.531868,
			13.5625687,
			13.6088943,
			13.6563644,
			13.7019272,
			13.7469559,
			13.7950363,
			13.8252335,
			13.8711014,
			13.91729,
			13.9630661,
			14.00898,
			14.0551376,
			14.0848465,
			14.1321335,
			14.1774368,
			14.2252426,
			14.27108,
			14.3151016,
			14.3477707,
			14.3925858,
			14.43972,
			14.4863663,
			14.5316391,
			14.5784531,
			14.6101761,
			14.6534195,
			14.6996841,
			14.7473984,
			14.7948074,
			14.8412094,
			14.8551559,
			14.9014359,
			14.9489365,
			14.99659,
			15.0434189,
			15.08712,
			15.11982,
			15.1680984,
			15.2136459,
			15.2587051,
			15.3045425,
			15.3507462,
			15.3807449,
			15.4292374,
			15.4760666,
			15.5234756,
			15.5685959,
			15.6146622,
			15.6450882,
			15.692543,
			15.73867,
			15.7859421,
			15.829216,
			15.8765488,
			15.9073868,
			15.9534836,
			16.0017776,
			16.0468216,
			16.091423,
			16.1381912,
			16.1704483,
			16.2161484,
			16.2624283,
			16.3077469,
			16.35299,
			16.3992844,
			16.43148,
			16.4753036,
			16.52259,
			16.5668411,
			16.6143723,
			16.6605148,
			16.6912766,
			16.737999,
			16.7827072,
			16.8310013,
			16.8754654,
			16.9215622,
			16.9527969,
			17.0005417,
			17.0453262,
			17.0926132,
			17.13787,
			17.18409,
			17.215004,
			17.2625351,
			17.3098984,
			17.3563156,
			17.4017868,
			17.44735,
			17.4765549,
			17.5219955,
			17.571373,
			17.6175461,
			17.6613235,
			17.7095871,
			17.7414169,
			17.7864914,
			17.8327255,
			17.879509,
			17.9273,
			17.97396,
			17.9908066,
			18.0364761,
			18.0836563,
			18.114006,
			18.1606522,
			18.206459,
			18.2526169,
			18.29937,
			18.3452225,
			18.3754959,
			18.421608,
			18.46949,
			18.51374,
			18.5609512,
			18.607872,
			18.6524582,
			18.69973,
			18.744545,
			18.7741928,
			18.8204422,
			18.8682327,
			18.9142227,
			18.96128,
			19.0085831,
			19.0383224,
			19.0829849,
			19.1302567,
			19.1760483,
			19.222908,
			19.26754,
			19.2977219,
			19.3454819,
			19.3887863,
			19.435997,
			19.4837875,
			19.5295334,
			19.5597458,
			19.606575,
			19.6537552,
			19.7010117,
			19.7470322,
			19.7925949,
			19.82122,
			19.8705978,
			19.91729,
			19.9610825,
			20.0079575,
			20.0556259,
			20.0873032,
			20.13256,
			20.1783829,
			20.2252884,
			20.2699814,
			20.31614,
			20.3470383,
			20.3941269,
			20.4384079,
			20.4870682,
			20.5318222,
			20.5781326,
			20.6093063,
			20.6562271,
			20.7012863,
			20.7490768,
			20.7968369,
			20.8417435,
			20.8733139,
			20.9200516,
			20.9637833,
			21.0115738,
			21.0553055,
			21.1031723,
			21.1352463,
			21.1807632,
			21.23484,
			21.2809982,
			21.3274612,
			21.3741379,
			21.3887253,
			21.4360733,
			21.4822311,
			21.5280075,
			21.5718155,
			21.60601,
			21.6501846,
			21.69593,
			21.7417221,
			21.79116,
			21.8347244,
			21.8822556,
			21.9136887,
			21.9590836,
			22.0038223,
			22.0505447,
			22.0837631,
			22.1300583,
			22.1748581,
			22.221611,
			22.2679062,
			22.3136978,
			22.3605576,
			22.4077988,
			22.439064,
			22.4862747,
			22.5315475,
			22.577858,
			22.6226425,
			22.6710129,
			22.6865311,
			22.7323074,
			22.7776413,
			22.8245468,
			22.871254,
			22.9174881,
			22.9466629,
			22.9929886,
			23.0368118,
			23.0855331,
			23.1303024,
			23.1781235,
			23.20884,
			23.2556229,
			23.301384,
			23.34748,
			23.3944931,
			23.4383316,
			23.4725418,
			23.51722,
			23.5634842,
			23.6118088,
			23.658577,
			23.7027969,
			23.7350235,
			23.7814713,
			23.8285751,
			23.8741074,
			23.9211044,
			23.9658737,
			23.99604,
			24.04438,
			24.08976,
			24.13697,
			24.181572,
			24.2299881,
			24.2602615,
			24.30503,
			24.3527145,
			24.3994827,
			24.4448013,
			24.4912186,
			24.52073,
			24.567009,
			24.6140976,
			24.6596146,
			24.7049026,
			24.7520676,
			24.7842636,
			24.8290482,
			24.87635,
			24.9215927,
			24.9673386,
			25.01432,
			25.04432,
			25.09211,
			25.1373978,
			25.18261,
			25.23037,
			25.27526,
			25.3080215,
			25.3535538,
			25.39933,
			25.4466019,
			25.4919357,
			25.53991,
			25.56884,
			25.6148148,
			25.66143,
			25.7087173,
			25.75547,
			25.8001938,
			25.8323746,
			25.8806534,
			25.92643,
			25.9736862,
			26.0209427,
			26.0652084,
			26.0949173,
			26.1431961,
			26.1863937,
			26.2336655,
			26.2789078,
			26.3268661,
			26.3584213,
			26.4027328,
			26.44767,
			26.4951859,
			26.5416336,
			26.5887527,
			26.6214066,
			26.6671677,
			26.7119522,
			26.759697,
			26.8044968,
			26.8496475,
			26.8807,
			26.9281082,
			26.9744644,
			27.0210648,
			27.0673447,
			27.1135483,
			27.1442947,
			27.1915665,
			27.2368546,
			27.2835312,
			27.3287735,
			27.3755417,
			27.4073257,
			27.4534531,
			27.4981918,
			27.5449753,
			27.59188,
			27.6314163,
			27.66256,
			27.7080154,
			27.7550583,
			27.7997971,
			27.8478165,
			27.8938828,
			27.924675,
			27.9708176,
			28.0159531,
			28.0622025,
			28.1079941,
			28.1541977,
			28.1873856,
			28.2331161,
			28.2779312,
			28.3247452,
			28.3693161,
			28.4160538,
			28.447258,
			28.4954758,
			28.5409012,
			28.58654,
			28.6332169,
			28.6784744,
			28.71215,
			28.75486,
			28.802742,
			28.8489456,
			28.89515,
			28.94294,
			28.9711227,
			29.02079,
			29.0640182,
			29.1128311,
			29.158226,
			29.2052841,
			29.2359848,
			29.281868,
			29.3284683,
			29.3747025,
			29.4219437,
			29.4681625,
			29.4978256,
			29.5446243,
			29.5910263,
			29.6366959,
			29.68386,
			29.7311172,
			29.7622757,
			29.808754,
			29.8537827,
			29.89901,
			29.9447556,
			29.9884872,
			30.0228348,
			30.0518112,
			30.10006,
			30.1448441,
			30.1885757,
			30.23841,
			30.2850876,
			30.331337,
			30.3761368,
			30.4244,
			30.4702682,
			30.5170059,
			30.5460587,
			30.5787277,
			30.6255417,
			30.6718216,
			30.7175064,
			30.7637558,
			30.81057,
			30.8553238,
			30.9000473,
			30.9479752,
			30.99459,
			31.0410233,
			31.07064,
			31.1172714,
			31.1650162,
			31.209816,
			31.2549973,
			31.2997971,
			31.3324661,
			31.378212,
			31.4246445,
			31.470314,
			31.5175247,
			31.5643082,
			31.5950089,
			31.641304,
			31.6880569,
			31.7348251,
			31.7808456,
			31.8255081,
			31.8550339,
			31.9012985,
			31.9472275,
			31.99636,
			32.0381851,
			32.0858536,
			32.11753,
			32.1637955,
			32.2096176,
			32.2553329,
			32.30108,
			32.3475723,
			32.3785477,
			32.4249344,
			32.4732132,
			32.5193863,
			32.5631332,
			32.61094,
			32.6421127,
			32.68786,
			32.73516,
			32.7808762,
			32.82772,
			32.8710556,
			32.9032364,
			32.9509354,
			32.99723,
			33.0435562,
			33.08825,
			33.1350174,
			33.1661758,
			33.2134933,
			33.2552567,
			33.3020248,
			33.3491745,
			33.3955765,
			33.42878,
			33.4737,
			33.5042953,
			33.5524979,
			33.5977554,
			33.64501,
			33.6888046,
			33.7361374,
			33.7823257,
			33.829628,
			33.87481,
			33.92257,
			33.95173,
			33.9979935,
			34.04531,
			34.0912552,
			34.1379166,
			34.18363,
			34.2141647,
			34.261116,
			34.308403,
			34.353157,
			34.3999252,
			34.4451675,
			34.4764023,
			34.5216141,
			34.5673752,
			34.6137772,
			34.6624069,
			34.7073746,
			34.739006,
			34.7856369,
			34.8329239,
			34.87919,
			34.92562,
			34.97175,
			35.0025253,
			35.0480576,
			35.09449,
			35.1412735,
			35.1874924,
			35.23478,
			35.2664566,
			35.3107529,
			35.35804,
			35.4027634,
			35.45054,
			35.49581,
			35.5265579,
			35.5727921,
			35.61904,
			35.66633,
			35.71264,
			35.7589645,
			35.7891464,
			35.83538,
			35.8841629,
			35.9309464,
			35.9772568,
			36.0215073,
			36.05468,
			36.1009445,
			36.14701,
			36.1930466,
			36.23777,
			36.284523,
			36.3162,
			36.3629532,
			36.407814,
			36.45246,
			36.4982071,
			36.5450058,
			36.57534,
			36.62095,
			36.667717,
			36.71421,
			36.76078,
			36.8051224,
			36.8368149,
			36.88349,
			36.930748,
			36.97753,
			37.0234,
			37.07012,
			37.102272,
			37.14785,
			37.1792068,
			37.2246323,
			37.26931,
			37.31652,
			37.36181,
			37.4076157,
			37.45495,
			37.5001144,
			37.5453568,
			37.59182,
			37.6212845,
			37.66755,
			37.7154465,
			37.7616043,
			37.804863,
			37.85264,
			37.8828354,
			37.9311142,
			37.9764175,
			38.020668,
			38.067955,
			38.11222,
			38.1444321,
			38.19027,
			38.2360458,
			38.2842331,
			38.3299942,
			38.3749466,
			38.4045334,
			38.4517,
			38.49694,
			38.5442123,
			38.5916367,
			38.6373367,
			38.66793,
			38.7139664,
			38.7597427,
			38.80526,
			38.85456,
			38.8993149,
			38.9310226,
			38.9778061,
			39.0247726,
			39.0548019,
			39.100563,
			39.1483078,
			39.1926956,
			39.23893,
			39.2861557,
			39.31684,
			39.3626022,
			39.4094467,
			39.45411,
			39.50141,
			39.5468674,
			39.594,
			39.6392441,
			39.6875076,
			39.7166672,
			39.76246,
			39.80889,
			39.8567429,
			39.9018173,
			39.948494,
			39.9792862,
			40.0271225,
			40.07438,
			40.10311,
			40.15081,
			40.1960678,
			40.2287674,
			40.27807,
			40.3243942,
			40.3707657,
			40.4151077,
			40.4632034,
			40.4787979,
			40.5256577,
			40.571373,
			40.6186752,
			40.6639328,
			40.71174,
			40.74044,
			40.7861862,
			40.83297,
			40.8782272,
			40.9256821,
			40.97036,
			41.00306,
			41.04844,
			41.0941238,
			41.1408768,
			41.1881332,
			41.2354355,
			41.2636642,
			41.310936,
			41.35824,
			41.405098,
			41.44851,
			41.4955673,
			41.5273361,
			41.57061,
			41.61834,
			41.6656265,
			41.71093,
			41.75686,
			41.7863846,
			41.83419,
			41.8811874,
			41.9273453,
			41.97207,
			42.0047531,
			42.0495377,
			42.09835,
			42.1442032,
			42.1904221,
			42.237236,
			42.26731,
			42.3138046,
			42.36094,
			42.40786,
			42.452446,
			42.50074,
			42.54554,
			42.5777359,
			42.622,
			42.6687851,
			42.713295,
			42.7609177,
			42.8076859,
			42.83796,
			42.8851547,
			42.92849,
			42.976326,
			43.0220261,
			43.06881,
			43.0990067,
			43.14483,
			43.19126,
			43.2384872,
			43.283165,
			43.33097,
			43.36019,
			43.4084854,
			43.4522,
			43.501,
			43.5316849,
			43.5750046,
			43.60781,
			43.6701431,
			43.71737,
			43.7473068,
			43.794426,
			43.8377,
			43.8864059,
			43.9161,
			43.96093,
			44.00837,
			44.05346,
			44.1002426,
			44.1460342,
			44.1933823,
			44.22152,
			44.2688065,
			44.3010025,
			44.3472977,
			44.39161,
			44.4097366,
			44.4554977,
			44.5007553,
			44.5477524,
			44.593895,
			44.64083,
			44.6709061,
			44.7171249,
			44.7634354,
			44.80973,
			44.85665,
			44.8866653,
			44.9339828,
			44.98066,
			45.0129471,
			45.05842,
			45.0884323,
			45.1352463,
			45.18,
			45.2415237,
			45.28765,
			45.33294,
			45.3782425,
			45.4265671,
			45.4556961,
			45.48638,
			45.5341873,
			45.5799942,
			45.6242752,
			45.6706161,
			45.71885,
			45.76612,
			45.8128738,
			45.85868,
			45.904,
			45.9502029,
			45.98211,
			46.0120773,
			46.0583878,
			46.10514,
			46.14939,
			46.1956863,
			46.2419662,
			46.28672,
			46.33599,
			46.38134,
			46.412056,
			46.4578934,
			46.5041428,
			46.5498123,
			46.5976028,
			46.643486,
			46.6977,
			46.7465,
			46.7616043,
			46.8080368,
			46.852623,
			46.8998947,
			46.9295731,
			46.97738,
			47.00808,
			47.07151,
			47.1166763,
			47.146904,
			47.19419,
			47.23751,
			47.2852249,
			47.3319778,
			47.37829,
			47.42521,
			47.4713821,
			47.51757,
			47.54798,
			47.59458,
			47.64045,
			47.6870651,
			47.73284,
			47.76304,
			47.8093033,
			47.8575821,
			47.9034348,
			47.9500961,
			47.9948654,
			48.04177,
			48.0713425,
			48.1045456,
			48.1507339,
			48.19503,
			48.2414474,
			48.2891159,
			48.3343735,
			48.3807144,
			48.4123,
			48.4596329,
			48.504982,
			48.53504,
			48.58158,
			48.6296158,
			48.6740646,
			48.72117,
			48.7689438,
			48.8137436,
			48.8449173,
			48.8891678,
			48.9375229,
			48.9847336,
			49.0295029,
			49.0754471,
			49.10766,
			49.15374,
			49.19799,
			49.2448044,
			49.2915726,
			49.3384171,
			49.36959,
			49.415535,
			49.4610519,
			49.5078354,
			49.5535049,
			49.59977,
			49.62847,
			49.6753,
			49.71998,
			49.7677536,
			49.81472,
			49.860405,
			49.8917,
			49.9388351,
			49.9838638,
			50.03031,
			50.07763,
			50.1218643,
			50.1550369,
			50.1987839,
			50.2456131,
			50.2934036,
			50.3377,
			50.38691,
			50.41761,
			50.46389,
			50.51023,
			50.5555038,
			50.6002579,
			50.6477737,
			50.6782761,
			50.72509,
			50.7714157,
			50.81762,
			50.86341,
			50.9097366,
			50.94039,
			50.97117,
			51.01989,
			51.063652,
			51.1104965,
			51.1537247,
			51.1869278,
			51.20405,
			51.2474747,
			51.2956161,
			51.3410873,
			51.3861771,
			51.4314651,
			51.46467,
			51.51098,
			51.5568,
			51.60363,
			51.6474533,
			51.695137,
			51.72486,
			51.755043,
			51.8033676,
			51.8491135,
			51.8949051,
			51.938652,
			51.9879532,
			52.03376,
			52.0812454,
			52.1255264,
			52.1575851,
			52.2018051,
			52.2501144,
			52.2954025,
			52.3413,
			52.3879776,
			52.4342728,
			52.4800644,
			52.5087967,
			52.5414658,
			52.5881729,
			52.6339645,
			52.6792526,
			52.72506,
			52.7703934,
			52.8186264,
			52.8628922,
			52.9102249,
			52.9405746,
			52.98568,
			53.03147,
			53.06167,
			53.1100235,
			53.1552658,
			53.2010422,
			53.2471542,
			53.2800522,
			53.3415,
			53.3867264,
			53.4350357,
			53.48014,
			53.52717,
			53.55535,
			53.5875,
			53.6337967,
			53.67858,
			53.72503,
			53.7551041,
			53.8008652,
			53.815712,
			53.86326,
			53.89147,
			53.9412766,
			53.9855423,
			54.0313339,
			54.07862,
			54.1214066,
			54.17028,
			54.20089,
			54.24668,
			54.2914963,
			54.3393478,
			54.3840561,
			54.43186,
			54.4625473,
			54.5098953,
			54.54119,
			54.58535,
			54.6474838,
			54.694603,
			54.7379379,
			54.78573,
			54.83149,
			54.8642044,
			54.9100571,
			54.9552841,
			54.98699,
			55.03277,
			55.0639572,
			55.1102524,
			55.1535263,
			55.1998062,
			55.2455521,
			55.2924728,
			55.33979,
			55.3869553,
			55.4154739,
			55.4629,
			55.50924,
			55.5539627,
			55.6003036,
			55.64657,
			55.6934128,
			55.7398,
			55.7693863,
			55.8170853,
			55.864357,
			55.9087,
			55.9549179,
			56.0037766,
			56.0477066,
			56.095253,
			56.14022,
			56.17225,
			56.2161636,
			56.2632675,
			56.3100967,
			56.35653,
			56.3865433,
			56.4348373,
			56.4796371,
			56.51084,
			56.5551376,
			56.603035,
			56.6497421,
			56.69503,
			56.7408371,
			56.78858,
			56.83338,
			56.8796768,
			56.91137,
			56.95774,
			57.0044479,
			57.04888,
			57.09704,
			57.1418839,
			57.186058,
			57.21775,
			57.2635422,
			57.31286,
			57.3573074,
			57.38633,
			57.4356461,
			57.4805374,
			57.51107,
			57.55892,
			57.604744,
			57.6494522,
			57.6967239,
			57.74357,
			57.788826,
			57.83619,
			57.88247,
			57.9291458,
			57.974968,
			58.0051956,
			58.0528946,
			58.0991745,
			58.146492,
			58.19239,
			58.2376633,
			58.2708664,
			58.3152542,
			58.36135,
			58.4087,
			58.4534531,
			58.5022659,
			58.53295,
			58.57821,
			58.6250534,
			58.6709061,
			58.71662,
			58.7634048,
			58.79458,
			58.8413925,
			58.88662,
			58.9339066,
			58.9625626,
			59.0098953,
			59.0563126,
			59.1013565,
			59.13154,
			59.17832,
			59.22515,
			59.2699051,
			59.3009872,
			59.31608,
			59.3618546,
			59.39234,
			59.4399338,
			59.4860764,
			59.5323563,
			59.56356,
			59.5791245,
			59.6259842,
			59.6716843,
			59.71743,
			59.7636948,
			59.81199,
			59.8431931,
			59.88691,
			59.9357071,
			59.9802933,
			60.0111923,
			60.0576248,
			60.1038437,
			60.15104,
			60.19686,
			60.2416458,
			60.28788,
			60.3362656,
			60.36788,
			60.4133224,
			60.4585037,
			60.5063858,
			60.55256,
			60.5973129,
			60.6285,
			60.6753464,
			60.7200851,
			60.7668839,
			60.8133774,
			60.86051,
			60.89083,
			60.9375534,
			60.983757,
			61.03106,
			61.0748672,
			61.1050339,
			61.15383,
			61.1855545,
			61.2303238,
			61.2761154,
			61.32189,
			61.36866,
			61.4164658,
			61.44703,
			61.4909744,
			61.53631,
			61.584053,
			61.62983,
			61.6761551,
			61.72091,
			61.7515945,
			61.7994,
			61.8447342,
			61.893074,
			61.9394455,
			61.9855576,
			62.0323563,
			62.0791855,
			62.1249771,
			62.1708145,
			62.2004,
			62.2478561,
			62.29351,
			62.3377762,
			62.386116,
			62.4313431,
			62.46354,
			62.5089035,
			62.5551224,
			62.60192,
			62.64736,
			62.6945267,
			62.7243423,
			62.77053,
			62.81675,
			62.8480148,
			62.895195,
			62.94065,
			62.98678,
			63.0325546,
			63.0783768,
			63.12728,
			63.1734848,
			63.204216,
			63.2504349,
			63.29722,
			63.3430557,
			63.38729,
			63.4215,
			63.4662857,
			63.4959335,
			63.5120621,
			63.5583878,
			63.60421,
			63.65043,
			63.69671,
			63.7420273,
			63.7716751,
			63.8194656,
			63.8667679,
			63.91108,
			63.95852,
			64.00469,
			64.0348,
			64.0806961,
			64.12789,
			64.1717453,
			64.22047,
			64.26626,
			64.2949753,
			64.34379,
			64.38802,
			64.43531,
			64.48144,
			64.52987,
			64.56007,
			64.6047745,
			64.65307,
			64.69984,
			64.73249,
			64.77628,
			64.82366,
			64.87086,
			64.91602,
			64.9612961,
			65.0086746,
			65.0554,
			65.086525,
			65.1322861,
			65.17759,
			65.2255,
			65.27012,
			65.3156357,
			65.3469,
			65.39474,
			65.43903,
			65.4862442,
			65.53298,
			65.57775,
			65.61094,
			65.64123,
			65.687355,
			65.73112,
			65.7784042,
			65.82481,
			65.87098,
			65.90255,
			65.95086,
			65.9966049,
			66.0424,
			66.08912,
			66.13537,
			66.18148,
			66.22692,
			66.27421,
			66.31138,
			66.35882,
			66.38778,
			66.4335251,
			66.4806442,
			66.51098,
			66.55927,
			66.60607,
			66.6522141,
			66.69796,
			66.7301254,
			66.77491,
			66.8185959,
			66.8663559,
			66.91331,
			66.95992,
			67.003685,
			67.0363846,
			67.08405,
			67.12881,
			67.17507,
			67.2197647,
			67.26702,
			67.31405,
			67.34342,
			67.39147,
			67.43699,
			67.46856,
			67.51444,
			67.5449753,
			67.59089,
			67.63848,
			67.6692,
			67.70028,
			67.7450943,
			67.7920456,
			67.8386459,
			67.884285,
			67.91465,
			67.96075,
			68.00715,
			68.0527,
			68.100975,
			68.14675,
			68.19169,
			68.22114,
			68.27,
			68.3005753,
			68.34719,
			68.39611,
			68.44187,
			68.487114,
			68.53387,
			68.58012,
			68.62589,
			68.6717,
			68.71937,
			68.75004,
			68.7973251,
			68.8416061,
			68.8863144,
			68.91898,
			68.9642258,
			68.99641,
			69.0115,
			69.05941,
			69.1050949,
			69.14939,
			69.1970139,
			69.2438,
			69.2741,
			69.31916,
			69.36692,
			69.41172,
			69.44396,
			69.4887,
			69.53498,
			69.58379,
			69.62859,
			69.6764145,
			69.7062454,
			69.75384,
			69.78269,
			69.84492,
			69.8917,
			69.93655,
			69.98376,
			70.02761,
			70.06025,
			70.10612,
			70.13726,
			70.1834641,
			70.2292252,
			70.27555,
			70.3229446,
			70.36753,
			70.41459,
			70.46064,
			70.50588,
			70.5526352,
			70.58282,
			70.628624,
			70.67745,
			70.72335,
			70.77003,
			70.81425,
			70.84536,
			70.89074,
			70.93699,
			70.96812,
			71.01559,
			71.06173,
			71.09144,
			71.1072,
			71.15276,
			71.19904,
			71.2471542,
			71.29368,
			71.3406448,
			71.3702,
			71.41892,
			71.46319,
			71.51092,
			71.55784,
			71.60442,
			71.63409,
			71.68041,
			71.71209,
			71.75535,
			71.78654,
			71.83433,
			71.86503,
			71.89571,
			71.94311,
			71.98678,
			72.03507,
			72.08167,
			72.12818,
			72.1594,
			72.2062454,
			72.25039,
			72.29719,
			72.34353,
			72.38726,
			72.42046,
			72.46377,
			72.4954453,
			72.5443039,
			72.59106,
			72.6187057,
			72.6670151,
			72.68214,
			72.7274246,
			72.75861,
			72.80599,
			72.83616,
			72.8828354,
			72.9296646,
			72.97846,
			73.02445,
			73.0696,
			73.11355,
			73.16111,
			73.19042,
			73.2366257,
			73.28382,
			73.33062,
			73.37595,
			73.42017,
			73.4523849,
			73.48188,
			73.53037,
			73.5606155,
			73.6080246,
			73.65365,
			73.7009354,
			73.71604,
			73.76183,
			73.79238,
			73.83637,
			73.87008,
			73.91529,
			73.9607,
			74.0219345,
			74.06916,
			74.11645,
			74.1622543,
			74.1909561,
			74.23673,
			74.2869339,
			74.3170853,
			74.36187,
			74.3915939,
			74.43946,
			74.48559,
			74.54806,
			74.59475,
			74.6389847,
			74.68626,
			74.7315,
			74.76319,
			74.79189,
			74.8377,
			74.8859,
			74.91657,
			74.96233,
			75.00762,
			75.07053,
			75.11822,
			75.1472855,
			75.19518,
			75.24198,
			75.2717361,
			75.286705,
			75.33198,
			75.3792343,
			75.42504,
			75.63787,
			75.6879959,
			75.7342453,
			75.7795,
			75.82581,
			75.8716354,
			75.9022141,
			75.951,
			75.9957657,
			76.04116,
			76.08781,
			76.13111,
			76.16424,
			76.208046,
			76.25428,
			76.30007,
			76.34806,
			76.3936539,
			76.42481,
			76.47106,
			76.51446,
			76.56161,
			76.60648,
			76.65264,
			76.68058,
			76.7291,
			76.77601,
			76.82229,
			76.8668442,
			76.9116,
			76.94434,
			76.99128,
			77.03643,
			77.06916,
			77.1123,
			77.14498,
			77.1887741,
			77.2044144,
			77.23763,
			77.28434,
			77.33012,
			77.37644,
			77.42115,
			77.4518356,
			77.51368,
			77.55952,
			77.604805,
			77.65452,
			77.70028,
			77.73246,
			77.77474,
			77.8220444,
			77.87035,
			77.91607,
			77.96186,
			77.99258,
			78.0369,
			78.0861,
			78.13088,
			78.1761856,
			78.22091,
			78.2530746,
			78.30035,
			78.34661,
			78.39141,
			78.43775,
			78.48493,
			78.5151,
			78.56095,
			78.59365,
			78.63694,
			78.68511,
			78.7304,
			78.7625961,
			78.7781754,
			78.8250046,
			78.85415,
			78.90095,
			78.94689,
			78.99407,
			79.03791,
			79.08413,
			79.13093,
			79.17725,
			79.2079544,
			79.2537155,
			79.28593,
			79.34753,
			79.391655,
			79.439476,
			79.48672,
			79.53353,
			79.56373,
			79.60854,
			79.65429,
			79.6870041,
			79.73229,
			79.7781143,
			79.8254,
			79.8697739,
			79.91694,
			79.9632339,
			80.00907,
			80.05485,
			80.08802,
			80.13181,
			80.18011,
			80.22547,
			80.27332,
			80.3194962,
			80.34919,
			80.39599,
			80.4408,
			80.48858,
			80.534874,
			80.56205,
			80.61088,
			80.65783,
			80.70405,
			80.74975,
			80.79705,
			80.8423843,
			80.87202,
			80.92031,
			80.9671249,
			81.01342,
			81.04366,
			81.09145,
			81.13729,
			81.18351,
			81.2288,
			81.27422,
			81.31988,
			81.36773,
			81.39739,
			81.4276047,
			81.474556,
			81.5201645,
			81.56797,
			81.61277,
			81.6449661,
			81.6575851,
			81.7053757,
			81.7366257,
			81.78286,
			81.82867,
			81.87411,
			81.9202652,
			81.9514542,
			81.99673,
			82.04168,
			82.08631,
			82.13511,
			82.18343,
			82.22924,
			82.27505,
			82.32128,
			82.36758,
			82.41186,
			82.4441147,
			82.4894943,
			82.5210648,
			82.56486,
			82.61318,
			82.65951,
			82.70382,
			82.73542,
			82.78086,
			82.82902,
			82.87298,
			82.919075,
			82.96381,
			83.01066,
			83.0570145,
			83.10279,
			83.1481857,
			83.19482,
			83.2247543,
			83.27137,
			83.3185959,
			83.36489,
			83.4093552,
			83.44138,
			83.48562,
			83.5329056,
			83.5792,
			83.62147,
			83.6687546,
			83.71604,
			83.7485,
			83.79356,
			83.83831,
			83.88657,
			83.93235,
			83.97764,
			84.01034,
			84.03858,
			84.08729,
			84.1340561,
			84.17783,
			84.22527,
			84.27187,
			84.31533,
			84.36143,
			84.40885,
			84.45348,
			84.50228,
			84.5319443,
			84.57871,
			84.62199,
			84.67181,
			84.71609,
			84.76185,
			84.79261,
			84.83831,
			84.88659,
			84.93185,
			84.9786453,
			85.02509,
			85.05462,
			85.09939,
			85.1321,
			85.17736,
			85.22382,
			85.27192,
			85.31826,
			85.365,
			85.41126,
			85.45727,
			85.50344,
			85.5481644,
			85.580864,
			85.6276,
			85.6750259,
			85.72065,
			85.76591,
			85.7955856,
			85.8424149,
			85.88766,
			85.93395,
			85.96615,
			86.01295,
			86.05787,
			86.1036,
			86.14977,
			86.19706,
			86.24384,
			86.2902756,
			86.33596,
			86.36756,
			86.41334,
			86.4435349,
			86.48777,
			86.5361252,
			86.5809555,
			86.62858,
			86.67356,
			86.72115,
			86.76791,
			86.81364,
			86.85862,
			86.89028,
			86.93488,
			86.98083,
			87.02793,
			87.07227,
			87.11945,
			87.15063,
			87.19639,
			87.24238,
			87.28962,
			87.3206253,
			87.36739,
			87.41169,
			87.44439,
			87.49033,
			87.53602,
			87.580925,
			87.6284561,
			87.6767654,
			87.7200241,
			87.7516861,
			87.80047,
			87.84574,
			87.89115,
			87.93687,
			87.9835739,
			88.0308151,
			88.07711,
			88.1218643,
			88.16865,
			88.19933,
			88.24613,
			88.2914658,
			88.32261,
			88.36887,
			88.41587,
			88.4624557,
			88.50846,
			88.55198,
			88.60027,
			88.6471939,
			88.69242,
			88.72205,
			88.77041,
			88.801506,
			88.847374,
			88.89362,
			88.93795,
			88.9851456,
			89.03286,
			89.06353,
			89.11285,
			89.1572952,
			89.20348,
			89.2489243,
			89.2949753,
			89.34078,
			89.38857,
			89.43336,
			89.47915,
			89.50936,
			89.54162,
			89.5853043,
			89.63209,
			89.6778946,
			89.7262344,
			89.77062,
			89.81686,
			89.84748,
			89.8939,
			89.9240341,
			89.9709244,
			90.0157547,
			90.03167,
			90.07795,
			90.1248856,
			90.1705551,
			90.21592,
			90.26202,
			90.29424,
			90.33808,
			90.38578,
			90.43055,
			90.46172,
			90.50909,
			90.5543,
			90.58799,
			90.63175,
			90.66343,
			90.71073,
			90.75501,
			90.80222,
			90.86561,
			90.91041,
			90.93905,
			90.98785,
			91.0346,
			91.07987,
			91.12589,
			91.1705246,
			91.2177353,
			91.26403,
			91.30982,
			91.34116,
			91.38677,
			91.432045,
			91.47985,
			91.52215,
			91.55584,
			91.60058,
			91.6463852,
			91.6946945,
			91.73847,
			91.78626,
			91.83102,
			91.86323,
			91.9105453,
			91.953804,
			91.98702,
			92.0343246,
			92.07914,
			92.12647,
			92.17025,
			92.21847,
			92.26374,
			92.29444,
			92.3424149,
			92.3874741,
			92.43525,
			92.48113,
			92.5242844,
			92.57111,
			92.61883,
			92.64948,
			92.69529,
			92.74174,
			92.78634,
			92.8351059,
			92.87885,
			92.9105759,
			92.9574356,
			93.00411,
			93.0498,
			93.0950851,
			93.14094,
			93.17007,
			93.2177658,
			93.2625351,
			93.30886,
			93.35505,
			93.4028854,
			93.43348,
			93.47825,
			93.52514,
			93.56983,
			93.6175461,
			93.66378,
			93.69448,
			93.73873,
			93.7885056,
			93.83326,
			93.87902,
			93.92683,
			93.9585953,
			94.0042648,
			94.03596,
			94.08042,
			94.12742,
			94.17329,
			94.2199,
			94.26516,
			94.31144,
			94.3588943,
			94.40403,
			94.43563,
			94.4821243,
			94.52865,
			94.57542,
			94.62118,
			94.66845,
			94.71522,
			94.7313,
			94.77625,
			94.82134,
			94.87017,
			94.91735,
			94.96314,
			94.9925,
			95.03858,
			95.0853348,
			95.11373,
			95.16175,
			95.19167,
			95.23823,
			95.2990341,
			95.34577,
			95.39205,
			95.43841,
			95.48353,
			95.5162,
			95.56196,
			95.60792,
			95.6534653,
			95.7002,
			95.73138,
			95.77567,
			95.82191,
			95.87023,
			95.91567,
			95.94727,
			95.99137,
			96.03863,
			96.0868759,
			96.11706,
			96.1623154,
			96.2091,
			96.25431,
			96.30007,
			96.34814,
			96.39364,
			96.43795,
			96.47163,
			96.51626,
			96.54763,
			96.5635147,
			96.60923,
			96.6549759,
			96.70074,
			96.7476,
			96.79279,
			96.8209152,
			96.86869,
			96.9152451,
			96.96168,
			96.99151,
			97.03669,
			97.0858841,
			97.13265,
			97.17745,
			97.2248459,
			97.27003,
			97.2996,
			97.34635,
			97.3931961,
			97.4379654,
			97.48765,
			97.53292,
			97.57917,
			97.60839,
			97.63862,
			97.6878,
			97.7194748,
			97.7657547,
			97.81106,
			97.85833,
			97.91918,
			97.96496,
			97.99612,
			98.04157,
			98.08672,
			98.1203842,
			98.18104,
			98.22657,
			98.2713242,
			98.31951,
			98.36527,
			98.39645,
			98.44279,
			98.48696,
			98.53475,
			98.58056,
			98.6263657,
			98.6585159,
			98.70385,
			98.7339249,
			98.7808456,
			98.827,
			98.87437,
			98.91953,
			98.96578,
			99.01319,
			99.05839,
			99.10369,
			99.134285,
			99.18183,
			99.22782,
			99.2624741,
			99.30979,
			99.35302,
			99.4028549,
			99.43296,
			99.46311,
			99.50941,
			99.5417557,
			99.58634,
			99.63459,
			99.68095,
			99.74178,
			99.78852,
			99.8347855,
			99.87905,
			99.92486,
			99.95722,
			100.00132,
			100.049583,
			100.0944,
			100.141655,
			100.187935,
			100.221107,
			100.267372,
			100.312126,
			100.359444,
			100.405251,
			100.452446,
			100.481621,
			100.526405,
			100.574364,
			100.621437,
			100.668221,
			100.699883,
			100.729576,
			100.776375,
			100.82328,
			100.868858,
			100.91613,
			100.961891,
			100.994621,
			101.04052,
			101.085625,
			101.132423,
			101.180168,
			101.224632,
			101.255104,
			101.299843,
			101.346825,
			101.3935,
			101.4398,
			101.485451,
			101.517113,
			101.563362,
			101.608345,
			101.653481,
			101.701637,
			101.746407,
			101.778206,
			101.825005,
			101.857719,
			101.90287,
			101.947395,
			101.994347,
			102.041649,
			102.085854,
			102.132576,
			102.18119,
			102.226143,
			102.2741,
			102.304634,
			102.350807,
			102.381126,
			102.42675,
			102.47155,
			102.517204,
			102.562935,
			102.611229,
			102.655952,
			102.7033,
			102.75045,
			102.781288,
			102.82589,
			102.870689,
			102.919884,
			102.96563,
			102.99485,
			103.042091,
			103.0888,
			103.119957,
			103.1657,
			103.211464,
			103.241646,
			103.287376,
			103.320564,
			103.351219,
			103.397453,
			103.444221,
			103.487968,
			103.534218,
			103.582191,
			103.612175,
			103.658592,
			103.7033,
			103.749458,
			103.798256,
			103.843056,
			103.874779,
			103.921471,
			103.966209,
			104.0135,
			104.060829,
			104.106163,
			104.136314,
			104.182121,
			104.229683,
			104.277016,
			104.322243,
			104.369011,
			104.400169,
			104.446892,
			104.491737,
			104.536552,
			104.582741,
			104.630013,
			104.660759,
			104.707039,
			104.752724,
			104.8005,
			104.844765,
			104.891747,
			104.921242,
			104.967979,
			105.013313,
			105.061028,
			105.107521,
			105.155037,
			105.186714,
			105.23246,
			105.278725,
			105.321983,
			105.369804,
			105.418037,
			105.449715,
			105.494987,
			105.540443,
			105.58654,
			105.6343,
			105.679085,
			105.711754,
			105.758064,
			105.804375,
			105.850563,
			105.895317,
			105.94265,
			105.97187,
			106.020027,
			106.065819,
			106.1135,
			106.1594,
			106.20623,
			106.235893,
			106.28257,
			106.329826,
			106.375648,
			106.423027,
			106.467674,
			106.499352,
			106.545128,
			106.591042,
			106.637245,
			106.683434,
			106.729179,
			106.761879,
			106.807686,
			106.8534,
			106.900673,
			106.946938,
			106.992744,
			107.024055,
			107.070229,
			107.116386,
			107.161232,
			107.20771,
			107.253807,
			107.2835,
			107.329277,
			107.375618,
			107.421455,
			107.468636,
			107.515938,
			107.5456,
			107.592979,
			107.638695,
			107.684441,
			107.732231,
			107.777565,
			107.807854,
			107.853447,
			107.901237,
			107.946022,
			107.992455,
			108.037651,
			108.068245,
			108.115547,
			108.161385,
			108.208153,
			108.25489,
			108.298683,
			108.33033,
			108.377678,
			108.424126,
			108.469185,
			108.516304,
			108.56176,
			108.59446,
			108.639748,
			108.6865,
			108.731361,
			108.7781,
			108.824989,
			108.855019,
			108.902916,
			108.949608,
			108.994408,
			109.04171,
			109.086479,
			109.117165,
			109.150871,
			109.196648,
			109.241112,
			109.287712,
			109.331978,
			109.378288,
			109.423714,
			109.470924,
			109.516106,
			109.560951,
			109.609779,
			109.639519,
			109.68615,
			109.732933,
			109.777748,
			109.826546,
			109.8729,
			109.903557,
			109.950264,
			109.993065,
			110.041008,
			110.086632,
			110.133415,
			110.165092,
			110.211464,
			110.257637,
			110.305458,
			110.351677,
			110.396965,
			110.4287,
			110.475014,
			110.485435
		]
	],
	'8366_8655_7982': [
		[
			102.143951
		],
		[
			0
		],
		[
			0,
			1.22167969,
			5.201932,
			9.170826,
			13.1385031,
			15.1242752,
			19.1308365,
			23.09851,
			27.0400352,
			29.0285149,
			33.0104828,
			36.9964447,
			38.99012,
			42.9756851,
			45.0000877,
			48.97979,
			52.9630928,
			54.92289,
			58.91153,
			60.9062042,
			64.89516,
			66.88858,
			70.8752747,
			72.9017258,
			76.88996,
			80.84641,
			84.83668,
			88.8259659,
			92.81091,
			96.7961,
			98.78883,
			102.812691,
			106.802513,
			108.7972,
			112.751175,
			116.740395,
			118.735687,
			122.731171,
			126.725113,
			130.7171,
			132.712036,
			136.702408,
			140.693756,
			142.690063,
			144.686127,
			148.707779,
			150.699463,
			154.647018,
			158.625336,
			162.602661,
			164.591354,
			168.569916,
			172.592636,
			176.558975,
			180.56546,
			182.603409,
			186.609863,
			188.609528,
			192.591141,
			196.537216,
			200.439957,
			204.298737,
			206.216,
			209.991028,
			213.777954,
			215.637024,
			219.321274,
			222.969208,
			224.778519,
			228.3667,
			231.915482,
			235.396423,
			237.137726,
			240.594711,
			244.027481,
			247.430786,
			250.797241,
			254.121979,
			257.399475,
			259.0222,
			260.6334,
			263.8214,
			266.965,
			268.52063,
			271.595581,
			274.625153,
			276.1241,
			279.094055,
			282.029327,
			283.4837,
			286.367126,
			289.21637,
			290.62854,
			293.453857,
			296.227051,
			297.6056,
			300.339661,
			303.0612,
			305.7535,
			308.4227,
			311.066956,
			312.382233,
			315.001862,
			317.5952,
			318.902283,
			321.5085,
			324.108459,
			326.725861,
			329.320953,
			331.91803,
			333.2174,
			335.815765,
			338.38385,
			339.673248,
			342.236633,
			343.5075,
			346.025,
			348.510681,
			350.960541,
			353.378479,
			355.762421,
			356.942322,
			359.2771,
			360.432037,
			362.708923,
			364.9484,
			367.162048,
			369.3036,
			370.371857,
			372.487854,
			374.5522,
			376.575684,
			378.5614,
			379.526337,
			381.469177,
			382.4296,
			384.3393,
			385.257446,
			387.115417,
			388.941956,
			390.738434,
			392.510864,
			394.252869,
			395.97876,
			396.82486,
			398.49,
			400.107574,
			400.913147,
			402.508057,
			404.082764,
			405.64444,
			407.19,
			407.9604,
			409.508545,
			411.0346,
			411.791077,
			413.298523,
			414.79895,
			415.5467,
			417.0382,
			418.522644,
			420.000763,
			421.4717,
			422.932556,
			423.659851,
			425.108734,
			426.54538,
			427.258,
			428.6769,
			430.089752,
			430.7944,
			432.190247,
			432.885345,
			434.271271,
			435.653748,
			437.028076,
			438.3962,
			439.761322,
			440.443634,
			441.8162,
			443.1881,
			443.87323,
			445.243347,
			446.634064,
			448.035828,
			449.437,
			450.841,
			452.282684,
			453.003357,
			453.724243,
			455.168121,
			455.893616,
			457.384369,
			458.8768,
			460.372742,
			461.1321,
			462.6819,
			464.234253,
			465.79126,
			466.595642,
			468.209778,
			469.0185,
			469.828522,
			471.463257,
			473.1197,
			473.95,
			475.6165,
			477.272217,
			478.931335,
			480.598846,
			482.237579,
			483.055847,
			484.699,
			486.342163,
			487.9662,
			488.78183,
			490.421478,
			492.060272,
			493.7039,
			494.530182,
			496.1922,
			497.8634,
			499.546753,
			500.393341,
			502.088837,
			503.7907,
			504.645538,
			506.359619,
			508.069122,
			509.786682,
			511.510162,
			512.37146,
			514.0986,
			514.964539,
			516.7029,
			518.4481,
			520.1991,
			521.0774,
			522.8421,
			524.612549,
			526.3882,
			528.165161,
			529.94635,
			530.8387,
			532.632568,
			534.4291,
			536.2275,
			537.1271,
			538.926331,
			539.8264,
			541.6273,
			543.427246,
			544.3287,
			546.135742,
			547.0429,
			548.860535,
			549.7711,
			551.5995,
			553.4432,
			554.366455,
			556.2178,
			557.1517,
			559.024536,
			560.9058,
			561.848267,
			563.739746,
			565.642944,
			567.5555,
			568.515564,
			569.4787,
			571.4142,
			572.3871,
			574.3253,
			576.2915,
			578.2719,
			579.2663,
			581.2634,
			582.268066,
			584.285461,
			586.3138,
			588.367432,
			590.4316,
			592.512756,
			593.5588,
			595.6546,
			597.7566,
			599.863159,
			600.934,
			603.0435,
			605.1537,
			607.271545,
			608.334351,
			610.4635,
			612.6295,
			614.822937,
			615.9224,
			618.202332,
			620.5537,
			622.925537,
			624.1227,
			626.5154,
			627.6555,
			629.939941,
			632.1956,
			634.3729,
			636.5774,
			638.7842,
			639.8909,
			642.112244,
			644.3457,
			646.5891,
			647.7098,
			649.958862,
			652.2137,
			653.340454,
			655.6014,
			656.734741,
			659.002563,
			660.1401,
			661.279968,
			663.5668,
			665.863342,
			668.178,
			670.505859,
			671.6741,
			674.0488,
			676.4331,
			678.868347,
			680.0918,
			682.589844,
			685.1168,
			686.3629,
			688.9673,
			691.5881,
			694.3315,
			697.055847,
			698.4007,
			699.7465,
			702.388062,
			704.8808,
			707.3408,
			709.7865,
			711.0024,
			713.4452,
			715.8982,
			718.365,
			720.843,
			723.334167,
			724.584351,
			727.091431,
			728.3511,
			730.8761,
			733.4116,
			734.662,
			737.2111,
			739.770569,
			742.339355,
			744.945557,
			747.516846,
			750.119263,
			751.4232,
			754.0314,
			755.336731,
			757.9458,
			760.55835,
			763.1777,
			764.4899,
			767.1195,
			769.7552,
			772.397766,
			775.0459,
			777.7005,
			779.0294,
			781.6929,
			783.026855,
			785.699646,
			788.379,
			791.064758,
			792.4101,
			795.1049,
			797.806763,
			800.515869,
			803.2323,
			804.593262,
			807.3206,
			808.6863,
			811.4167,
			814.136353,
			816.834351,
			819.5012,
			820.8211,
			823.4331,
			826.0082,
			828.5454,
			829.800049,
			832.2815,
			834.7268,
			835.935547,
			838.327454,
			840.685852,
			841.8521,
			844.1607,
			846.431458,
			847.555,
			849.786743,
			851.9808,
			854.1741,
			856.3358,
			857.403,
			858.4721,
			859.531738,
			861.622253,
			863.645752,
			865.6157,
			867.5455,
			868.480164,
			870.323364,
			871.232239,
			873.0194,
			874.7628,
			876.471,
			878.1334,
			878.9454,
			880.542358,
			882.1034,
			883.635,
			885.132446,
			885.8678,
			887.311646,
			888.0258,
			889.4293,
			890.1178,
			891.469849,
			892.788269,
			894.075562,
			895.3406,
			896.5859,
			897.8265,
			899.045044,
			899.651062,
			900.8567,
			902.0552,
			902.6525,
			903.842468,
			905.025757,
			905.6156,
			906.7931,
			907.9634,
			909.1195,
			910.271851,
			911.423,
			911.9988,
			913.1483,
			913.7205,
			914.863464,
			916.006348,
			917.151245,
			917.7289,
			918.905945,
			920.0825,
			921.2598,
			922.439453,
			923.6373,
			924.2368,
			925.4341,
			926.630859,
			927.229553,
			928.440369,
			929.047668,
			930.2596,
			931.469849,
			932.681946,
			933.9411,
			935.195435,
			935.8217,
			937.0738,
			938.3699,
			939.0355,
			940.363159,
			941.0255,
			942.3496,
			943.7339,
			944.4341,
			945.831665,
			947.228333,
			948.650757,
			949.3664,
			950.7944,
			952.2198,
			953.6471,
			955.0695,
			955.778931,
			957.196655,
			957.904358,
			958.6063,
			960.0057,
			961.4021,
			962.799744,
			964.2144,
			964.919434,
			966.3261,
			967.0285,
			968.4609,
			969.9163,
			971.367065,
			972.818237,
			973.554932,
			975.0236,
			976.489868,
			977.2233,
			978.69104,
			980.155151,
			981.6194,
			983.084839,
			983.812,
			985.2667,
			986.725,
			988.1759,
			988.881531,
			989.5873,
			991.7078,
			993.115051,
			993.7943,
			995.1531,
			996.5145,
			997.1969,
			998.5449,
			999.2097,
			1000.54126,
			1001.87671,
			1003.21075,
			1004.52576,
			1005.18488,
			1006.50793,
			1007.83881,
			1009.1427,
			1010.45227,
			1011.11011,
			1012.43286,
			1013.09326,
			1014.39771,
			1015.71179,
			1017.03632,
			1018.37115,
			1019.71533,
			1020.39233,
			1021.75671,
			1023.139,
			1024.55444,
			1025.26758,
			1026.70483,
			1028.16248,
			1029.66675,
			1031.18481,
			1031.9491,
			1033.49792,
			1034.28674,
			1035.87439,
			1037.47742,
			1039.10083,
			1039.91919,
			1041.56665,
			1043.22888,
			1044.91016,
			1046.59851,
			1048.28564,
			1049.13135,
			1050.83276,
			1052.54785,
			1053.409,
			1055.13416,
			1056.87219,
			1057.746,
			1059.49084,
			1061.24316,
			1063.0083,
			1064.76257,
			1065.64209,
			1067.39551,
			1069.1709,
			1070.95288,
			1071.84851,
			1073.65356,
			1074.57275,
			1076.42041,
			1078.28052,
			1080.18359,
			1082.10083,
			1084.03235,
			1085.00464,
			1086.95874,
			1088.92224,
			1089.905,
			1091.87952,
			1093.863,
			1095.84778,
			1096.84448,
			1098.84485,
			1100.84985,
			1102.86584,
			1104.89319,
			1105.91089,
			1107.95471,
			1108.98242,
			1111.05078,
			1113.11938,
			1115.19653,
			1117.28369,
			1118.33154,
			1120.4303,
			1122.53857,
			1124.65674,
			1125.71973,
			1127.853,
			1130,
			1131.07837,
			1133.24243,
			1134.328,
			1136.50562,
			1138.69324,
			1140.895,
			1143.10693,
			1145.331,
			1147.56616,
			1148.6875,
			1150.9314,
			1153.18408,
			1154.31372,
			1156.57825,
			1158.85229,
			1161.14307,
			1163.444,
			1164.59985,
			1166.92273,
			1168.08777,
			1170.42627,
			1172.77527,
			1175.13354,
			1176.31592,
			1178.68823,
			1181.07349,
			1182.27,
			1184.67017,
			1187.08008,
			1188.28857,
			1190.70923,
			1193.13745,
			1194.355,
			1196.79639,
			1199.2467,
			1201.70581,
			1204.17383,
			1206.64966,
			1207.89075,
			1210.37939,
			1212.87634,
			1214.128,
			1216.63843,
			1219.15723,
			1221.68555,
			1224.22229,
			1225.49377,
			1228.04333,
			1230.60083,
			1231.88257,
			1234.452,
			1237.02881,
			1239.61316,
			1240.90784,
			1243.50269,
			1244.80273,
			1247.40747,
			1250.012,
			1252.62122,
			1255.23718,
			1257.8595,
			1259.17334,
			1261.80688,
			1264.44775,
			1265.77124,
			1268.42407,
			1271.08447,
			1273.75269,
			1275.08972,
			1277.76782,
			1280.45264,
			1283.14282,
			1284.49048,
			1287.19067,
			1289.89734,
			1292.61145,
			1295.33276,
			1296.696,
			1299.4281,
			1300.797,
			1303.54077,
			1306.29077,
			1307.66821,
			1310.42847,
			1311.8114,
			1313.19629,
			1315.97119,
			1318.75342,
			1321.54248,
			1324.33716,
			1325.73584,
			1328.53137,
			1331.31531,
			1332.69934,
			1335.44751,
			1338.16272,
			1339.50635,
			1342.16541,
			1344.78674,
			1347.37036,
			1349.9165,
			1352.42529,
			1353.66577,
			1356.118,
			1358.53369,
			1359.72852,
			1362.09277,
			1363.26221,
			1365.57532,
			1367.85083,
			1370.08911,
			1372.29053,
			1374.45483,
			1375.523,
			1377.63208,
			1379.70435,
			1380.72668,
			1382.74329,
			1383.73779,
			1385.69922,
			1387.62341,
			1389.51123,
			1391.36292,
			1393.17993,
			1394.9635,
			1395.84253,
			1397.57324,
			1398.42468,
			1400.10022,
			1401.7395,
			1402.5459,
			1404.13159,
			1404.91077,
			1405.68091,
			1407.1936,
			1407.93628,
			1409.39441,
			1410.81677,
			1412.20288,
			1412.8833,
			1414.21948,
			1415.53491,
			1416.17566,
			1417.431,
			1418.04749,
			1419.261,
			1419.85962,
			1421.04358,
			1422.20264,
			1422.77612,
			1423.91577,
			1425.04785,
			1425.61243,
			1426.737,
			1427.85571,
			1428.97437,
			1429.53418,
			1430.65576,
			1431.21814,
			1431.79614,
			1432.95313,
			1434.112,
			1435.2738,
			1435.85583,
			1437.06763,
			1438.3009,
			1438.91748,
			1440.15173,
			1440.7699,
			1442.06934,
			1443.407,
			1444.74365,
			1446.08118,
			1447.49182,
			1448.20215,
			1449.62158,
			1450.33154,
			1451.74817,
			1453.15186,
			1454.55029,
			1455.94666,
			1457.29724,
			1458.62793,
			1459.2915,
			1460.61755,
			1461.91968,
			1462.55774,
			1463.833,
			1465.109,
			1465.748,
			1467.01074,
			1467.636,
			1468.8877,
			1470.14209,
			1471.39966,
			1472.022,
			1472.64526,
			1473.896,
			1474.52393,
			1475.78723,
			1477.055,
			1478.32,
			1478.96338,
			1480.26221,
			1481.57678,
			1482.904,
			1484.24768,
			1484.92542,
			1486.29285,
			1486.98267,
			1488.37256,
			1489.767,
			1490.46191,
			1491.83972,
			1493.19116,
			1494.50854,
			1495.79016,
			1496.41907,
			1497.65479,
			1498.85693,
			1500.02893,
			1500.60583,
			1501.74561,
			1502.87341,
			1503.43408,
			1504.54993,
			1505.6593,
			1506.77588,
			1507.88342,
			1508.989,
			1509.54126,
			1510.64587,
			1511.75281,
			1512.30737,
			1513.41309,
			1513.96484,
			1515.067,
			1516.16943,
			1516.71973,
			1517.26733,
			1518.35864,
			1519.44629,
			1520.53223,
			1521.0752,
			1522.17554,
			1522.72791,
			1523.82813,
			1524.92468,
			1526.021,
			1526.57617,
			1527.76245,
			1528.94287,
			1530.11951,
			1530.707,
			1531.92749,
			1533.23889,
			1534.5448,
			1535.1969,
			1536.50171,
			1537.155,
			1538.457,
			1539.7561,
			1541.05688,
			1542.27429,
			1543.44287,
			1544.02686,
			1545.19641,
			1546.37122,
			1546.93774,
			1548.05164,
			1549.16663,
			1550.28613,
			1551.413,
			1552.54663,
			1553.115,
			1554.25513,
			1554.82764,
			1555.97717,
			1557.15173,
			1558.34973,
			1559.553,
			1560.15662,
			1561.36829,
			1562.5907,
			1563.81885,
			1564.43494,
			1565.67175,
			1566.9137,
			1567.53516,
			1568.78418,
			1570.04346,
			1570.67773,
			1571.9574,
			1573.25269,
			1574.56384,
			1575.22546,
			1576.561,
			1577.916,
			1579.28687,
			1580.67358,
			1581.373,
			1582.78027,
			1584.2019,
			1584.9187,
			1586.36462,
			1587.83289,
			1589.3186,
			1590.06665,
			1591.57471,
			1593.107,
			1593.87939,
			1595.435,
			1597.00464,
			1598.581,
			1600.17139,
			1601.77625,
			1603.38062,
			1604.188,
			1605.81274,
			1607.45007,
			1609.09253,
			1609.91309,
			1611.55518,
			1612.38208,
			1614.03442,
			1615.71252,
			1617.40479,
			1619.113,
			1619.97168,
			1621.69849,
			1622.56909,
			1624.32117,
			1626.08557,
			1627.87085,
			1629.67578,
			1630.583,
			1632.4104,
			1633.33362,
			1635.19,
			1637.05872,
			1638.94214,
			1639.88867,
			1640.838,
			1641.79053,
			1643.70508,
			1645.632,
			1647.57129,
			1649.523,
			1650.50342,
			1651.48682,
			1653.46484,
			1655.45557,
			1657.45825,
			1659.47314,
			1661.49976,
			1663.536,
			1664.55811,
			1666.61084,
			1668.67383,
			1670.74768,
			1671.78857,
			1673.87915,
			1675.97559,
			1677.02307,
			1679.11926,
			1681.22363,
			1682.27991,
			1683.33887,
			1685.46387,
			1686.53027,
			1688.67029,
			1690.82019,
			1692.97974,
			1694.06311,
			1696.237,
			1698.42053,
			1700.61353,
			1702.816,
			1703.9209,
			1706.138,
			1707.25024,
			1709.481,
			1711.72119,
			1712.84424,
			1715.09534,
			1717.35632,
			1719.62573,
			1721.90479,
			1724.19556,
			1725.34485,
			1727.65125,
			1728.81042,
			1731.13562,
			1733.474,
			1735.82715,
			1738.19067,
			1740.56714,
			1741.75891,
			1744.14807,
			1746.545,
			1748.9458,
			1750.146,
			1752.54126,
			1753.73218,
			1756.09912,
			1758.43848,
			1760.74329,
			1763.01355,
			1765.24683,
			1766.35059,
			1768.532,
			1769.62769,
			1771.75537,
			1773.83,
			1774.86279,
			1776.90186,
			1778.90356,
			1780.87012,
			1782.804,
			1784.72327,
			1785.66162,
			1787.49854,
			1789.3208,
			1790.22021,
			1791.99561,
			1792.87146,
			1794.594,
			1795.44165,
			1796.29443,
			1797.93091,
			1798.742,
			1800.33813,
			1801.89929,
			1802.66687,
			1804.17456,
			1805.64624,
			1807.08313,
			1808.48376,
			1809.17017,
			1810.5188,
			1811.83472,
			1813.117,
			1814.36157,
			1815.57312,
			1816.17639,
			1817.33911,
			1817.90759,
			1819.013,
			1820.0835,
			1821.12256,
			1822.13721,
			1823.13879,
			1823.63892,
			1824.63062,
			1825.61133,
			1826.0979,
			1827.06641,
			1828.03381,
			1828.52209,
			1829.49463,
			1829.97876,
			1830.9436,
			1831.90356,
			1832.85864,
			1833.80286,
			1834.73682,
			1835.20068,
			1836.122,
			1837.03735,
			1837.94824,
			1838.3999,
			1839.29651,
			1840.18555,
			1841.06934,
			1841.95044,
			1842.83,
			1843.27588,
			1844.16638,
			1844.60913,
			1845.4917,
			1846.37329,
			1847.25647,
			1847.69946,
			1848.61914,
			1849.54932,
			1850.478,
			1850.94312,
			1851.87549,
			1852.814,
			1853.80835,
			1854.31055,
			1855.31519,
			1855.81848,
			1856.82874,
			1857.84631,
			1858.91211,
			1859.98779,
			1861.06714,
			1861.60852,
			1862.69531,
			1863.8241,
			1864.39526,
			1865.53687,
			1866.67944,
			1867.8241,
			1868.99524,
			1869.58069,
			1870.16528,
			1871.33228,
			1872.5,
			1873.67419,
			1874.26111,
			1875.4314,
			1876.60059,
			1877.77258,
			1878.94543,
			1880.11572,
			1881.28711,
			1881.87427,
			1883.0531,
			1884.23315,
			1884.82336,
			1886.00586,
			1887.19458,
			1887.79236,
			1888.98621,
			1890.18213,
			1891.38452,
			1892.59668,
			1893.79614,
			1894.39172,
			1895.58948,
			1896.19214,
			1897.40674,
			1898.61975,
			1899.827,
			1900.43481,
			1901.65869,
			1902.27539,
			1903.51672,
			1904.76709,
			1906.03015,
			1907.30737,
			1908.59814,
			1909.248,
			1910.54858,
			1911.87622,
			1913.21936,
			1913.89575,
			1915.26147,
			1916.64319,
			1918.04126,
			1919.45691,
			1920.1698,
			1921.60278,
			1922.3208,
			1923.762,
			1925.206,
			1926.64685,
			1928.082,
			1928.799,
			1930.23914,
			1931.65918,
			1933.07117,
			1933.77466,
			1935.17651,
			1935.87524,
			1937.27039,
			1938.66418,
			1940.05493,
			1941.44214,
			1942.13489,
			1943.52637,
			1944.935,
			1946.34253,
			1947.0459,
			1948.45825,
			1949.89868,
			1950.6178,
			1952.0553,
			1953.4989,
			1954.97925,
			1956.45667,
			1957.933,
			1958.68274,
			1960.20544,
			1961.72485,
			1962.48389,
			1964.019,
			1965.5708,
			1966.34521,
			1967.89282,
			1969.45361,
			1971.01392,
			1971.7926,
			1973.34937,
			1974.87451,
			1976.39282,
			1977.90662,
			1978.66345,
			1980.17432,
			1981.67773,
			1982.42761,
			1983.94958,
			1985.51,
			1987.06445,
			1987.84045,
			1989.43445,
			1991.04907,
			1992.66052,
			1993.46631,
			1995.0769,
			1996.6853,
			1998.294,
			1999.05432,
			2000.54785,
			2001.29517,
			2002.7915,
			2004.26245,
			2005.707,
			2007.14966,
			2007.87036,
			2009.32141,
			2010.781,
			2012.24341,
			2012.97632,
			2014.46411,
			2015.96692,
			2017.475,
			2018.99341,
			2020.52808,
			2021.299,
			2022.85059,
			2023.63171,
			2025.19153,
			2025.97729,
			2027.56177,
			2029.15479,
			2030.74646,
			2032.35364,
			2033.97192,
			2035.586,
			2037.21436,
			2038.0343,
			2039.68188,
			2040.50928,
			2042.17371,
			2043.85132,
			2044.69238,
			2046.38245,
			2048.08447,
			2049.79663,
			2051.51782,
			2053.244,
			2054.10571,
			2055.82739,
			2056.69043,
			2058.42285,
			2060.159,
			2061.02881,
			2062.77539,
			2064.53223,
			2066.29858,
			2067.18555,
			2068.96729,
			2070.76514,
			2072.574,
			2074.39868,
			2076.24243,
			2078.09814,
			2079.97314,
			2080.91724,
			2082.81445,
			2084.724,
			2085.68262,
			2087.609,
			2088.57715,
			2090.51245,
			2092.45752,
			2094.41357,
			2095.395,
			2096.37866,
			2098.35522,
			2100.32471,
			2101.321,
			2103.31982,
			2112.20361,
			2113.161,
			2115.053,
			2116.913,
			2118.7356,
			2120.51978,
			2121.39771,
			2123.126,
			2124.81934,
			2126.48242,
			2127.30664,
			2128.94458,
			2129.75757,
			2131.38916,
			2132.99976,
			2134.59961,
			2135.3916,
			2136.9707,
			2138.54517,
			2140.11841,
			2141.687,
			2142.46973,
			2144.03442,
			2145.62671,
			2146.423,
			2148.01416,
			2149.614,
			2151.24658,
			2152.87451,
			2154.50464,
			2155.33179,
			2156.98145,
			2158.626,
			2159.4502,
			2161.121,
			2161.954,
			2163.61621,
			2165.28149,
			2166.94531,
			2168.60449,
			2170.265,
			2171.095,
			2172.752,
			2174.40576,
			2176.0332,
			2176.8457,
			2178.46924,
			2180.06836,
			2180.85229,
			2182.41846,
			2183.98413,
			2184.76147,
			2186.30078,
			2187.83569,
			2189.368,
			2190.90332,
			2192.435,
			2193.201,
			2194.7356,
			2196.272,
			2197.041,
			2198.57983,
			2200.11987,
			2201.658,
			2203.198,
			2204.74121,
			2205.51318,
			2207.05884,
			2207.83276,
			2209.38428,
			2210.941,
			2211.7207,
			2213.28345,
			2214.85474,
			2216.4375,
			2218.02612,
			2219.6228,
			2221.2334,
			2222.0415,
			2223.66431,
			2224.479,
			2226.115,
			2227.76025,
			2229.41553,
			2230.24731,
			2231.92,
			2233.591,
			2235.28955,
			2237.00146,
			2237.86255,
			2239.59424,
			2241.34082,
			2242.21875,
			2243.98486,
			2245.7666,
			2247.563,
			2248.46631,
			2250.28369,
			2252.11768,
			2253.96436,
			2255.82739,
			2256.765,
			2258.64941,
			2259.59644,
			2261.50049,
			2263.41724,
			2264.38037,
			2266.31543,
			2268.2627,
			2270.22217,
			2272.19336,
			2274.177,
			2276.17261,
			2277.1748,
			2279.188,
			2280.199,
			2282.22925,
			2284.271,
			2286.32324,
			2287.35352,
			2289.42188,
			2291.50049,
			2293.58984,
			2295.68481,
			2296.731,
			2298.8252,
			2300.92725,
			2303.03882,
			2304.09814,
			2306.224,
			2308.35913,
			2310.50439,
			2311.58,
			2312.65845,
			2314.82275,
			2316.99878,
			2319.185,
			2321.38257,
			2323.59082,
			2325.80981,
			2326.924,
			2329.15967,
			2330.281,
			2332.53271,
			2334.794,
			2337.064,
			2339.34351,
			2341.63281,
			2343.93237,
			2346.24365,
			2347.405,
			2349.73486,
			2350.905,
			2353.25684,
			2355.61865,
			2357.99365,
			2359.18481,
			2361.574,
			2362.77222,
			2365.17554,
			2367.585,
			2370.00269,
			2372.42432,
			2374.85229,
			2376.06958,
			2378.50732,
			2380.95386,
			2383.41113,
			2384.643,
			2387.11426,
			2389.59521,
			2392.08447,
			2394.58252,
			2395.835,
			2398.34473,
			2399.60278,
			2402.12451,
			2404.65356,
			2407.19043,
			2409.734,
			2411.00854,
			2413.56348,
			2416.12622,
			2418.69775,
			2419.98633,
			2422.5708,
			2425.1626,
			2426.46167,
			2429.06519,
			2431.67236,
			2432.97559,
			2435.587,
			2438.20459,
			2440.82935,
			2443.46118,
			2446.1,
			2447.42188,
			2450.07129,
			2451.39868,
			2454.05859,
			2456.725,
			2459.4,
			2462.08228,
			2464.773,
			2466.12134,
			2468.82422,
			2471.55737,
			2474.27441,
			2475.63574,
			2478.36255,
			2481.07275,
			2482.442,
			2485.184,
			2487.933,
			2490.689,
			2492.06958,
			2494.837,
			2497.611,
			2500.39453,
			2503.18433,
			2504.58154,
			2507.38086,
			2508.78223,
			2511.59,
			2514.40161,
			2515.809,
			2518.62842,
			2521.45337,
			2524.28369,
			2525.70117,
			2528.54077,
			2531.38672,
			2532.812,
			2535.66748,
			2538.52979,
			2541.399,
			2544.27466,
			2545.71533,
			2548.60132,
			2551.495,
			2552.94434,
			2555.84814,
			2558.75781,
			2561.67432,
			2564.59741,
			2567.52661,
			2568.99365,
			2570.4624,
			2573.4043,
			2576.35278,
			2579.30762,
			2580.7876,
			2583.75146,
			2586.71973,
			2589.69385,
			2592.67358,
			2595.66,
			2597.15527,
			2600.15112,
			2603.15356,
			2604.657,
			2607.66724,
			2610.68359,
			2613.70581,
			2615.21875,
			2616.73315,
			2619.7666,
			2622.80566,
			2624.32739,
			2627.37476,
			2630.428,
			2631.95654,
			2633.48682,
			2636.55127,
			2639.62183,
			2641.15869,
			2644.23682,
			2647.32056,
			2650.41,
			2653.505,
			2656.60474,
			2659.71,
			2661.26465,
			2664.37769,
			2665.93652,
			2669.05762,
			2672.184,
			2675.31543,
			2678.45239,
			2680.023,
			2683.16748,
			2686.317,
			2689.472,
			2691.05127,
			2694.21387,
			2697.382,
			2698.96777,
			2702.14282,
			2705.32227,
			2708.506,
			2711.6897,
			2713.2832,
			2716.47314,
			2719.6665,
			2722.86084,
			2724.4563,
			2727.63916,
			2729.22363,
			2732.37256,
			2735.48877,
			2738.56787,
			2740.09351,
			2743.11865,
			2746.108,
			2747.58862,
			2750.52124,
			2751.973,
			2754.84644,
			2757.68164,
			2759.08521,
			2761.86328,
			2763.23828,
			2765.96,
			2768.642,
			2771.28638,
			2773.89355,
			2775.183,
			2777.734,
			2780.24658,
			2781.489,
			2783.94629,
			2786.36621,
			2787.56323,
			2789.93115,
			2792.26465,
			2793.419,
			2795.69873,
			2797.94238,
			2799.05078,
			2800.15039,
			2802.32227,
			2803.4126,
			2805.53125,
			2807.61475,
			2808.643,
			2810.67358,
			2812.67236,
			2814.64258,
			2816.59155,
			2818.518,
			2819.474,
			2821.37744,
			2823.27661,
			2824.22559,
			2826.122,
			2828.026,
			2829.943,
			2830.90186,
			2832.82861,
			2834.78662,
			2836.74463,
			2837.72852,
			2839.731,
			2840.73169,
			2842.734,
			2844.75757,
			2846.77783,
			2848.79468,
			2850.80664,
			2851.81128,
			2852.818,
			2854.85425,
			2856.88623,
			2858.95117,
			2859.996,
			2862.084,
			2864.13062,
			2866.15918,
			2868.162,
			2869.141,
			2871.10645,
			2873.07813,
			2875.00171,
			2875.96729,
			2877.906,
			2879.821,
			2881.74487,
			2882.71069,
			2884.64722,
			2886.59351,
			2887.5708,
			2889.53223,
			2891.50146,
			2893.489,
			2895.52344,
			2896.54,
			2898.625,
			2900.802,
			2902.97241,
			2904.09985,
			2906.3645,
			2908.625,
			2910.88867,
			2913.14453,
			2914.2356,
			2916.408,
			2918.55029,
			2919.54175,
			2921.519,
			2923.48462,
			2925.374,
			2926.316,
			2928.194,
			2930.08325,
			2931.9668,
			2933.84863,
			2934.796,
			2936.68384,
			2937.62549,
			2939.51563,
			2941.40723,
			2943.293,
			2945.18628,
			2946.13135,
			2948.01758,
			2948.961,
			2950.85156,
			2952.74072,
			2954.629,
			2955.57129,
			2957.45752,
			2959.34131,
			2961.218,
			2963.09448,
			2964.031,
			2965.88965,
			2966.81885,
			2968.67725,
			2970.52173,
			2972.36377,
			2974.21338,
			2975.15332,
			2976.093,
			2977.032,
			2978.90967,
			2980.91,
			2981.90942,
			2983.90723,
			2986.04858,
			2988.19531,
			2989.27881,
			2991.52759,
			2993.77612,
			2994.81323,
			2996.82642,
			2998.827,
			3000.67188,
			3002.501,
			3004.33643,
			3006.15967,
			3007.0752,
			3008.91284,
			3010.76416,
			3012.62549,
			3013.55933,
			3015.46533,
			3016.42969,
			3018.36572,
			3020.386,
			3021.42676,
			3023.517,
			3025.76123,
			3028.09082,
			3030.54834,
			3033.15039,
			3034.455,
			3037.05762,
			3038.36426,
			3040.82349,
			3043.1875,
			3044.37256,
			3046.50732,
			3048.64819,
			3050.76123,
			3052.86084,
			3054.97363,
			3056.03857,
			3058.176,
			3060.32715,
			3062.49414,
			3063.58154,
			3065.76367,
			3067.956,
			3070.15869,
			3072.37158,
			3073.48145,
			3075.71045,
			3077.95215,
			3079.07642,
			3081.33423,
			3082.46753,
			3084.74146,
			3087.027,
			3088.17334,
			3090.47363,
			3092.78467,
			3095.1062,
			3096.27173,
			3098.6084,
			3100.95557,
			3103.314,
			3105.682,
			3108.08057,
			3109.274,
			3111.6665,
			3112.86548,
			3115.269,
			3117.66138,
			3120.08252,
			3122.51343,
			3123.73218,
			3126.17554,
			3128.62256,
			3131.07764,
			3133.5542,
			3134.78564,
			3137.25269,
			3139.72559,
			3140.94434,
			3143.42822,
			3144.67285,
			3147.171,
			3148.42529,
			3149.68164,
			3152.204,
			3154.74121,
			3157.28857,
			3158.567,
			3161.12939,
			3162.41162,
			3164.981,
			3167.55566,
			3170.13379,
			3172.719,
			3175.30615,
			3176.599,
			3179.18823,
			3181.78418,
			3184.39282,
			3185.69971,
			3188.31885,
			3189.631,
			3192.2605,
			3194.89478,
			3197.53613,
			3200.1875,
			3202.845,
			3204.17529,
			3206.84058,
			3208.175,
			3210.849,
			3213.53076,
			3214.87549,
			3217.56982,
			3220.27686,
			3222.99048,
			3225.71338,
			3228.44263,
			3231.17749,
			3232.54736,
			3235.2915,
			3236.66577,
			3239.419,
			3242.17822,
			3244.944,
			3246.329,
			3249.10181,
			3251.87817,
			3254.65845,
			3257.44287,
			3258.8374,
			3261.63257,
			3263.03223,
			3265.84,
			3267.24658,
			3270.06543,
			3271.47754,
			3274.307,
			3275.724,
			3278.56323,
			3281.43213,
			3284.28345,
			3287.14014,
			3288.57056,
			3291.43652,
			3292.87158,
			3295.74634,
			3298.627,
			3301.51367,
			3304.40649,
			3305.85522,
			3307.30518,
			3310.20972,
			3313.12036,
			3316.037,
			3317.49854,
			3320.42456,
			3323.35449,
			3326.29,
			3329.23,
			3332.17358,
			3335.12256,
			3336.599,
			3339.557,
			3342.521,
			3344.005,
			3346.979,
			3349.95874,
			3351.45117,
			3354.4397,
			3357.434,
			3360.43335,
			3363.44,
			3364.94531,
			3367.96,
			3370.97949,
			3372.49146,
			3375.519,
			3378.55176,
			3381.589,
			3383.10938,
			3386.15381,
			3389.204,
			3392.26025,
			3395.32349,
			3396.85718,
			3399.93164,
			3401.471,
			3404.55518,
			3407.61938,
			3410.71436,
			3413.84131,
			3415.3938,
			3418.50342,
			3420.05957,
			3423.151,
			3426.27368,
			3429.40283,
			3432.538,
			3434.1084,
			3437.254,
			3438.83,
			3441.98633,
			3445.14917,
			3448.34448,
			3451.5166,
			3454.694,
			3457.876,
			3459.46924,
			3462.63281,
			3465.83,
			3467.45728,
			3470.664,
			3472.2688,
			3475.47827,
			3478.69238,
			3481.91455,
			3485.142,
			3488.377,
			3491.61816,
			3493.24072,
			3496.4917,
			3498.11914,
			3501.38184,
			3504.65283,
			3506.29565,
			3509.58521,
			3512.87646,
			3516.16455,
			3519.44287,
			3522.71436,
			3525.98315,
			3527.61523,
			3530.88232,
			3534.1543,
			3535.79175,
			3539.076,
			3540.71948,
			3544.014,
			3547.31274,
			3550.61523,
			3553.92358,
			3555.57935,
			3558.892,
			3562.20825,
			3565.52759,
			3567.18848,
			3570.482,
			3573.80762,
			3577.165,
			3580.50342,
			3582.14648,
			3585.49219,
			3588.84229,
			3592.19751,
			3595.55615,
			3597.23657,
			3600.599,
			3603.99316,
			3605.67676,
			3609.04663,
			3612.41528,
			3615.78516,
			3619.15625,
			3620.84424,
			3622.53369,
			3625.91479,
			3627.60718,
			3630.995,
			3634.387,
			3637.78271,
			3641.18213,
			3644.58447,
			3647.99146,
			3651.40186,
			3653.10864,
			3656.52661,
			3659.95166,
			3663.381,
			3666.814,
			3668.53223,
			3671.96875,
			3673.687,
			3677.12549,
			3680.5647,
			3684.00146,
			3687.43872,
			3690.87549,
			3692.59424,
			3696.03223,
			3699.476,
			3701.19922,
			3704.65,
			3708.105,
			3709.83447,
			3713.296,
			3716.761,
			3720.22925,
			3723.70068,
			3727.17529,
			3728.91431,
			3732.39453,
			3735.87744,
			3739.364,
			3741.109,
			3744.60132,
			3746.34863,
			3749.8457,
			3753.34668,
			3756.85132,
			3760.35938,
			3763.872,
			3765.629,
			3769.145,
			3770.90527,
			3774.42749,
			3777.953,
			3779.71655,
			3783.247,
			3786.78125,
			3790.318,
			3792.08936,
			3795.63379,
			3799.185,
			3802.74023,
			3806.29883,
			3808.07935,
			3811.641,
			3815.20386,
			3818.76538,
			3820.54663,
			3824.104,
			3827.66113,
			3831.21851,
			3834.77734,
			3836.55762,
			3840.12,
			3843.68652,
			3845.47144,
			3849.01465,
			3852.62085,
			3856.20068,
			3857.99219,
			3859.78418,
			3863.37085,
			3866.96045,
			3870.55518,
			3874.15186,
			3877.751,
			3879.55176,
			3883.15479,
			3886.76172,
			3890.37231,
			3893.98657,
			3895.79614,
			3899.41821,
			3903.04541,
			3906.67676,
			3910.31055,
			3912.12769,
			3915.76172,
			3917.57715,
			3921.2085,
			3924.83838,
			3928.46729,
			3932.09814,
			3933.91431,
			3935.73145,
			3939.3667,
			3943.00537,
			3946.647,
			3950.2915,
			3952.11475,
			3955.76318,
			3959.414,
			3963.06738,
			3966.72388,
			3970.383,
			3972.21362,
			3974.045,
			3977.70947,
			3981.37622,
			3985.04541,
			3986.88086,
			3990.554,
			3994.229,
			3997.90625,
			4001.58472,
			4005.26563,
			4008.948,
			4010.78955,
			4014.475,
			4016.31885,
			4020.00757,
			4023.69849,
			4025.54443,
			4029.23853,
			4032.93286,
			4036.629,
			4038.47754,
			4042.17676,
			4044.02686,
			4047.7312,
			4051.43872,
			4055.11865,
			4057.00635,
			4060.72168,
			4064.43848,
			4068.15674,
			4071.8772,
			4075.59814,
			4079.32056,
			4083.04443,
			4086.77026,
			4090.49756,
			4094.22656,
			4096.09131,
			4099.79,
			4103.55225,
			4107.28369,
			4110.98535,
			4114.72363,
			4116.59473,
			4120.343,
			4124.0957,
			4127.851,
			4129.72852,
			4133.4834,
			4137.244,
			4139.12549,
			4142.892,
			4146.66,
			4150.42969,
			4154.199,
			4157.968,
			4159.852,
			4163.6167,
			4165.49951,
			4169.26563,
			4171.151,
			4174.92725,
			4178.713,
			4180.60742,
			4184.401,
			4186.29834,
			4190.09326,
			4193.89063,
			4197.689,
			4199.588,
			4203.385,
			4205.283,
			4209.078,
			4212.876,
			4214.77734,
			4218.583,
			4222.39258,
			4226.20459,
			4230.01855,
			4233.835,
			4235.744,
			4239.563,
			4243.384,
			4245.29541,
			4249.119,
			4251.032,
			4254.859,
			4258.688,
			4260.603,
			4264.435,
			4266.35156,
			4268.26953,
			4272.105,
			4275.942,
			4277.86133,
			4281.701,
			4283.621,
			4285.54248,
			4289.386,
			4293.23242,
			4297.08,
			4300.93,
			4304.78174,
			4306.708,
			4310.56152,
			4312.48926,
			4316.346,
			4320.206,
			4324.06836,
			4326,
			4329.86768,
			4333.73926,
			4335.677,
			4339.55029,
			4343.423,
			4347.29346,
			4349.22949,
			4353.10156,
			4356.975,
			4360.84961,
			4362.788,
			4366.66553,
			4370.545,
			4374.426,
			4376.366,
			4380.248,
			4382.19043,
			4386.07568,
			4388.01855,
			4389.9624,
			4393.84961,
			4397.73926,
			4399.68457,
			4403.576,
			4407.46973,
			4411.365,
			4415.262,
			4419.161,
			4423.06055,
			4425.01074,
			4428.913,
			4430.86426,
			4434.76855,
			4436.721,
			4440.628,
			4444.53662,
			4446.491,
			4448.446,
			4452.356,
			4456.26758,
			4460.181,
			4462.138,
			4466.056,
			4468.01563,
			4471.9375,
			4475.85938,
			4479.78174,
			4483.70459,
			4487.627,
			4489.589,
			4493.5127,
			4497.43945,
			4501.3667,
			4503.33,
			4507.259,
			4509.22363,
			4513.1543,
			4517.087,
			4521.02051,
			4524.956,
			4528.8916,
			4530.86,
			4532.827,
			4536.7627,
			4540.69873,
			4544.636,
			4546.60547,
			4550.54443,
			4554.482,
			4558.418,
			4562.35352,
			4566.2876,
			4570.222,
			4572.19,
			4576.12646,
			4580.06836,
			4582.04,
			4585.98828,
			4589.96973,
			4591.94434,
			4595.89063,
			4597.863,
			4601.80957,
			4603.78271,
			4607.73145,
			4611.68262,
			4617.611,
			4619.587,
			4623.536,
			4627.48242,
			4631.425,
			4633.395,
			4637.338,
			4641.283,
			4645.232,
			4647.206,
			4651.1543,
			4655.10352,
			4657.07861,
			4661.03,
			4664.98145,
			4666.95752,
			4670.878,
			4674.83154,
			4678.7876,
			4680.766,
			4684.726,
			4688.68945,
			4692.6543,
			4694.63867,
			4696.62256,
			4700.59033,
			4704.556,
			4706.53857,
			4710.506,
			4714.457,
			4716.42871,
			4718.40869,
			4722.37158,
			4726.335,
			4730.29932,
			4734.265,
			4736.249,
			4740.24561,
			4744.232,
			4748.23242,
			4750.2085,
			4754.16943,
			4758.15137,
			4760.118,
			4762.406
		],
		[
			0,
			0.0244064331,
			0.07136536,
			0.118995667,
			0.165260315,
			0.197433472,
			0.24269104,
			0.289588928,
			0.335258484,
			0.380981445,
			0.426246643,
			0.474029541,
			0.5033417,
			0.550491333,
			0.5801544,
			0.6264267,
			0.6718292,
			0.70349884,
			0.747650146,
			0.762702942,
			0.8109665,
			0.8577423,
			0.8894119,
			0.9351883,
			0.981948853,
			1.02713776,
			1.07294464,
			1.11971283,
			1.16501617,
			1.21266937,
			1.25892639,
			1.28959656,
			1.336525,
			1.38311768,
			1.42886353,
			1.47462463,
			1.50629425,
			1.5530777,
			1.59732056,
			1.64458466,
			1.674263,
			1.72065735,
			1.76587677,
			1.79694366,
			1.81403351,
			1.86032867,
			1.90608215,
			1.95233154,
			2.00078583,
			2.04733276,
			2.07604218,
			2.124298,
			2.16762543,
			2.215271,
			2.26250458,
			2.306923,
			2.336296,
			2.383194,
			2.42841339,
			2.47718048,
			2.522087,
			2.565773,
			2.59635162,
			2.64461517,
			2.68890381,
			2.73376465,
			2.78089142,
			2.82865143,
			2.85782623,
			2.905861,
			2.95185852,
			2.99710846,
			3.02776337,
			3.07330322,
			3.11932373,
			3.17411041,
			3.21938324,
			3.26573181,
			3.31287384,
			3.35862732,
			3.37323761,
			3.41903687,
			3.46476746,
			3.49694061,
			3.5427475,
			3.58952332,
			3.63430023,
			3.68106842,
			3.71126556,
			3.75955963,
			3.806015,
			3.85066223,
			3.88240051,
			3.9306488,
			3.96083069,
			4.003273,
			4.0517807,
			4.09752655,
			4.14533234,
			4.189621,
			4.23658752,
			4.28268433,
			4.327446,
			4.37574768,
			4.40596,
			4.451256,
			4.497505,
			4.54480743,
			4.59260559,
			4.637932,
			4.66988373,
			4.71486664,
			4.76062775,
			4.79032135,
			4.83638763,
			4.88340759,
			4.92865,
			4.975975,
			5.02142334,
			5.06761932,
			5.11277,
			5.15908051,
			5.191757,
			5.235161,
			5.28083038,
			5.32959747,
			5.376381,
			5.421768,
			5.452347,
			5.49761963,
			5.54488373,
			5.59069061,
			5.63647461,
			5.68073273,
			5.71344757,
			5.758217,
			5.80703735,
			5.83472443,
			5.88402557,
			5.927742,
			5.97555542,
			6.02150726,
			6.06712341,
			6.113373,
			6.159157,
			6.20597839,
			6.236412,
			6.280403,
			6.327202,
			6.37310028,
			6.420944,
			6.46669,
			6.49523926,
			6.5425415,
			6.589859,
			6.63413239,
			6.6803894,
			6.72719574,
			6.75789642,
			6.803009,
			6.848961,
			6.89723969,
			6.942627,
			6.988472,
			7.018486,
			7.064743,
			7.11253357,
			7.15883636,
			7.20436859,
			7.250374,
			7.28103638,
			7.31221771,
			7.356514,
			7.40179443,
			7.45056152,
			7.495804,
			7.542595,
			7.59036255,
			7.635147,
			7.666939,
			7.71357727,
			7.75885,
			7.807152,
			7.85089874,
			7.89819336,
			7.944954,
			7.99085236,
			8.036331,
			8.067749,
			8.09787,
			8.144608,
			8.176804,
			8.222763,
			8.26799,
			8.313072,
			8.359329,
			8.406731,
			8.451866,
			8.497101,
			8.528267,
			8.57402,
			8.590111,
			8.619377,
			8.665115,
			8.71228,
			8.760025,
			8.803307,
			8.85257,
			8.89781952,
			8.94510651,
			8.990341,
			9.034645,
			9.067398,
			9.111534,
			9.160286,
			9.205589,
			9.252304,
			9.29805,
			9.344788,
			9.375969,
			9.420242,
			9.467087,
			9.51371,
			9.544876,
			9.59214,
			9.635414,
			9.683678,
			9.729897,
			9.777153,
			9.82206,
			9.869179,
			9.897804,
			9.944565,
			9.976738,
			10.0221252,
			10.0682831,
			10.1146317,
			10.1597672,
			10.206543,
			10.2508087,
			10.2986221,
			10.3427811,
			10.3908691,
			10.4223557,
			10.4670944,
			10.5132294,
			10.560997,
			10.6067581,
			10.65255,
			10.6842041,
			10.7299118,
			10.7756653,
			10.8063583,
			10.8526917,
			10.88427,
			10.9295044,
			10.9456024,
			10.9918671,
			11.0366516,
			11.066864,
			11.1125259,
			11.1588135,
			11.2060928,
			11.2364044,
			11.2814865,
			11.3297348,
			11.3739777,
			11.4187164,
			11.4519119,
			11.4675064,
			11.5127945,
			11.5595245,
			11.6063232,
			11.6526337,
			11.697319,
			11.7295151,
			11.7757492,
			11.806427,
			11.8516769,
			11.8984146,
			11.9451828,
			11.9899368,
			12.038147,
			12.08297,
			12.131218,
			12.1759644,
			12.2242661,
			12.255661,
			12.3012085,
			12.3474579,
			12.3947144,
			12.4253922,
			12.47155,
			12.5174332,
			12.5636673,
			12.6084061,
			12.654808,
			12.7005463,
			12.747673,
			12.7783356,
			12.825592,
			12.8558884,
			12.9015808,
			12.9478455,
			12.99511,
			13.0418854,
			13.0862427,
			13.1344986,
			13.1787491,
			13.2240753,
			13.271019,
			13.300621,
			13.3483124,
			13.3930511,
			13.4234009,
			13.4708252,
			13.5013428,
			13.5480194,
			13.5636139,
			13.6084137,
			13.6548615,
			13.7009735,
			13.7477722,
			13.7950287,
			13.8227234,
			13.8701477,
			13.9168243,
			13.9615326,
			14.0072937,
			14.0565948,
			14.0863342,
			14.13205,
			14.178833,
			14.2242126,
			14.2697449,
			14.31514,
			14.3448029,
			14.3770294,
			14.4223022,
			14.4686127,
			14.51387,
			14.5626678,
			14.608963,
			14.6544037,
			14.7011566,
			14.7478485,
			14.7925873,
			14.839859,
			14.86998,
			14.9153442,
			14.9601135,
			14.9928131,
			15.0396271,
			15.0838928,
			15.1301727,
			15.1784821,
			15.2227631,
			15.2676544,
			15.3173218,
			15.3625946,
			15.3927765,
			15.4393005,
			15.4838715,
			15.5311584,
			15.5779266,
			15.6247253,
			15.6555481,
			15.7003021,
			15.7474976,
			15.7947845,
			15.841095,
			15.8859558,
			15.91658,
			15.9643555,
			16.0086517,
			16.05397,
			16.1008148,
			16.1465,
			16.1782379,
			16.2229919,
			16.2688751,
			16.31456,
			16.3608551,
			16.40715,
			16.43779,
			16.4850769,
			16.53238,
			16.5765686,
			16.6223755,
			16.6677856,
			16.7009735,
			16.7476349,
			16.7919312,
			16.84024,
			16.88507,
			16.9324036,
			16.96402,
			17.0077972,
			17.0400085,
			17.0853119,
			17.13269,
			17.1788635,
			17.22615,
			17.2538757,
			17.30278,
			17.3474274,
			17.3952179,
			17.4410248,
			17.4727173,
			17.487915,
			17.5341644,
			17.5793762,
			17.6271515,
			17.6734467,
			17.7197266,
			17.74939,
			17.7981415,
			17.8288116,
			17.87558,
			17.9189,
			17.9667511,
			18.0128632,
			18.058136,
			18.105957,
			18.15274,
			18.1974335,
			18.2447357,
			18.2754517,
			18.31868,
			18.34938,
			18.397644,
			18.4272919,
			18.4751282,
			18.52037,
			18.5832367,
			18.6274567,
			18.67427,
			18.71959,
			18.7663727,
			18.7944336,
			18.8427277,
			18.8870239,
			18.9333649,
			18.980011,
			19.0252533,
			19.05606,
			19.1013184,
			19.14746,
			19.1942749,
			19.24054,
			19.2858429,
			19.31749,
			19.3627319,
			19.4090118,
			19.4572754,
			19.5040741,
			19.550827,
			19.5809937,
			19.62976,
			19.6740265,
			19.7198486,
			19.7640381,
			19.8123169,
			19.8424835,
			19.8897552,
			19.9355927,
			19.9802856,
			20.0109558,
			20.0582123,
			20.1042633,
			20.15129,
			20.19806,
			20.244812,
			20.2901,
			20.3348846,
			20.368576,
			20.41481,
			20.44548,
			20.4898376,
			20.5350189,
			20.5807648,
			20.627533,
			20.6602173,
			20.70697,
			20.7527618,
			20.79747,
			20.8291473,
			20.8749084,
			20.9347534,
			20.9659882,
			21.0136719,
			21.0428467,
			21.09111,
			21.13446,
			21.1504669,
			21.1976929,
			21.2444458,
			21.2897339,
			21.3364716,
			21.3822479,
			21.4144287,
			21.4586639,
			21.5070953,
			21.5527649,
			21.5974884,
			21.6452332,
			21.6779327,
			21.7227783,
			21.7700348,
			21.8017731,
			21.8479156,
			21.8921661,
			21.9404755,
			21.9852448,
			22.0299683,
			22.0782471,
			22.1245117,
			22.1696014,
			22.2005615,
			22.2322845,
			22.2920227,
			22.3383179,
			22.3831024,
			22.41478,
			22.4620667,
			22.4922485,
			22.5385132,
			22.5838318,
			22.6300964,
			22.6773987,
			22.7221832,
			22.7679749,
			22.8008118,
			22.8474579,
			22.8922272,
			22.9405518,
			22.9863434,
			23.0305786,
			23.0622711,
			23.1100616,
			23.1554565,
			23.2007751,
			23.2464142,
			23.29271,
			23.3405,
			23.3842773,
			23.4331665,
			23.477829,
			23.5110321,
			23.5564575,
			23.6011963,
			23.6473846,
			23.6956787,
			23.7402344,
			23.7710724,
			23.81746,
			23.8471069,
			23.8949127,
			23.9407349,
			23.98703,
			24.0323486,
			24.0780487,
			24.1243744,
			24.17131,
			24.2153931,
			24.2631836,
			24.2933655,
			24.3396759,
			24.3870239,
			24.4302368,
			24.47702,
			24.5243378,
			24.5530548,
			24.6004333,
			24.6460876,
			24.6949,
			24.7407074,
			24.7849731,
			24.817688,
			24.8639526,
			24.9107513,
			24.9399567,
			24.9852448,
			25.0305023,
			25.07779,
			25.1260986,
			25.1712189,
			25.2151642,
			25.2619781,
			25.30719,
			25.3403931,
			25.388504,
			25.4335327,
			25.4777222,
			25.5260162,
			25.57283,
			25.6029358,
			25.6472778,
			25.6945648,
			25.73938,
			25.78572,
			25.8325653,
			25.8631439,
			25.908432,
			25.9567566,
			26.0001373,
			26.0478058,
			26.0950928,
			26.12529,
			26.1708527,
			26.2178955,
			26.26265,
			26.2948456,
			26.34166,
			26.387619,
			26.43431,
			26.4795074,
			26.5102081,
			26.5565033,
			26.6029968,
			26.6490784,
			26.6973877,
			26.7411652,
			26.7880859,
			26.8347778,
			26.8805389,
			26.9112244,
			26.9560547,
			27.003006,
			27.0475464,
			27.0938263,
			27.1401062,
			27.1711884,
			27.2170868,
			27.2482452,
			27.2964935,
			27.3412933,
			27.3887787,
			27.4338379,
			27.4785461,
			27.5107117,
			27.5584869,
			27.6034088,
			27.651062,
			27.6837463,
			27.7305,
			27.7611542,
			27.8059235,
			27.8527527,
			27.8974457,
			27.9427185,
			27.98999,
			28.0352783,
			28.082016,
			28.1267548,
			28.1740417,
			28.2044525,
			28.2504883,
			28.2967834,
			28.3414764,
			28.388504,
			28.43457,
			28.465271,
			28.5114441,
			28.5587158,
			28.6033936,
			28.6492767,
			28.69751,
			28.72821,
			28.7754517,
			28.8071747,
			28.852951,
			28.9003,
			28.94548,
			28.9907532,
			29.0381927,
			29.0692139,
			29.1159515,
			29.1616821,
			29.2059631,
			29.251236,
			29.2995453,
			29.3462372,
			29.3764038,
			29.4202118,
			29.4674683,
			29.5137177,
			29.5594635,
			29.6069031,
			29.6520233,
			29.6999054,
			29.7455444,
			29.7752228,
			29.8216248,
			29.8538361,
			29.90007,
			29.9467316,
			29.9758911,
			30.0213623,
			30.0375366,
			30.082016,
			30.1277771,
			30.1735535,
			30.21936,
			30.2677,
			30.29779,
			30.34555,
			30.39032,
			30.4346771,
			30.4673767,
			30.51352,
			30.557785,
			30.6051636,
			30.6508484,
			30.6960754,
			30.74234,
			30.78775,
			30.8188477,
			30.8625336,
			30.9103088,
			30.9560852,
			30.9862976,
			31.032608,
			31.0802765,
			31.1240387,
			31.1717987,
			31.2176056,
			31.26535,
			31.31012,
			31.3403168,
			31.3871155,
			31.4303284,
			31.4781036,
			31.5092773,
			31.5560455,
			31.6013641,
			31.6460571,
			31.6933289,
			31.7397,
			31.7854,
			31.8322144,
			31.8637848,
			31.9080658,
			31.93982,
			31.9857025,
			32.01573,
			32.0605164,
			32.1078644,
			32.12204,
			32.1694336,
			32.19786,
			32.24469,
			32.2914734,
			32.3363953,
			32.38356,
			32.4147339,
			32.4624939,
			32.508316,
			32.5525665,
			32.5847473,
			32.63051,
			32.64511,
			32.68988,
			32.7377319,
			32.7824554,
			32.8297272,
			32.87703,
			32.90773,
			32.9561768,
			33.00099,
			33.04863,
			33.07779,
			33.12509,
			33.1537933,
			33.1720123,
			33.21817,
			33.2650757,
			33.3127441,
			33.3580322,
			33.40349,
			33.4361572,
			33.4808044,
			33.51094,
			33.5567169,
			33.6043854,
			33.649765,
			33.69702,
			33.7422943,
			33.787674,
			33.8187866,
			33.8645477,
			33.8957062,
			33.940506,
			34.00293,
			34.048172,
			34.09494,
			34.140213,
			34.18654,
			34.219223,
			34.26555,
			34.3112335,
			34.3570251,
			34.401413,
			34.4490967,
			34.47928,
			34.5265656,
			34.5557556,
			34.6031036,
			34.6463165,
			34.6945953,
			34.7252655,
			34.74138,
			34.78572,
			34.8330231,
			34.8787079,
			34.9249725,
			34.9710236,
			35.00197,
			35.0487366,
			35.095,
			35.14229,
			35.1895752,
			35.2192535,
			35.26561,
			35.2971954,
			35.34198,
			35.38797,
			35.434082,
			35.47928,
			35.5250549,
			35.57286,
			35.6171265,
			35.6472778,
			35.69507,
			35.74182,
			35.78827,
			35.8329926,
			35.877655,
			35.9254456,
			35.9561462,
			36.0029755,
			36.04825,
			36.0940247,
			36.1398163,
			36.1856079,
			36.2349243,
			36.2801666,
			36.3123627,
			36.3586121,
			36.3903046,
			36.4351044,
			36.4803467,
			36.52661,
			36.5552826,
			36.5729828,
			36.61917,
			36.66652,
			36.71164,
			36.7574,
			36.80327,
			36.8334351,
			36.87909,
			36.9278564,
			36.9726563,
			37.00287,
			37.0506,
			37.0958557,
			37.1406555,
			37.1844177,
			37.23329,
			37.2799072,
			37.3271942,
			37.3568726,
			37.388504,
			37.4339,
			37.4801025,
			37.52536,
			37.5698547,
			37.61641,
			37.66365,
			37.695343,
			37.74063,
			37.78691,
			37.833252,
			37.8778839,
			37.9226532,
			37.9713135,
			38.0171967,
			38.0619354,
			38.10872,
			38.1399078,
			38.18416,
			38.2325134,
			38.27768,
			38.32361,
			38.3710632,
			38.3999939,
			38.4456177,
			38.4923859,
			38.53917,
			38.5829163,
			38.63118,
			38.6623535,
			38.70813,
			38.7398224,
			38.7866364,
			38.8324432,
			38.8786163,
			38.92302,
			38.9691925,
			39.0003967,
			39.0455933,
			39.09337,
			39.1406555,
			39.18596,
			39.2306671,
			39.2779236,
			39.3237,
			39.35605,
			39.4016876,
			39.4479065,
			39.4926758,
			39.52237,
			39.57129,
			39.6184845,
			39.6491241,
			39.6948547,
			39.75772,
			39.8041229,
			39.84778,
			39.89653,
			39.9403076,
			39.9725037,
			40.0188,
			40.0645447,
			40.1112976,
			40.1560669,
			40.2018433,
			40.2325745,
			40.27397,
			40.3200226,
			40.36525,
			40.4115143,
			40.4597473,
			40.4895325,
			40.535202,
			40.5809479,
			40.6291962,
			40.67296,
			40.7197723,
			40.7499237,
			40.79715,
			40.8293457,
			40.8755951,
			40.9225159,
			40.9676361,
			40.9998779,
			41.0148926,
			41.0601349,
			41.10794,
			41.15483,
			41.20105,
			41.2476959,
			41.2788544,
			41.30803,
			41.35495,
			41.40074,
			41.4473267,
			41.4930878,
			41.53943,
			41.5856323,
			41.6313171,
			41.67807,
			41.723877,
			41.769455,
			41.800415,
			41.8470764,
			41.89485,
			41.92502,
			41.96965,
			42.01709,
			42.0477142,
			42.06482,
			42.1111145,
			42.1560364,
			42.2012329,
			42.24991,
			42.29663,
			42.326828,
			42.37262,
			42.4179077,
			42.4636536,
			42.51042,
			42.5562,
			42.5880432,
			42.6347351,
			42.664856,
			42.7111359,
			42.75699,
			42.8012848,
			42.8494263,
			42.8951721,
			42.9419556,
			42.9857635,
			43.0330963,
			43.0787659,
			43.10843,
			43.155426,
			43.20259,
			43.2478027,
			43.2955322,
			43.3392944,
			43.3713837,
			43.41678,
			43.4624939,
			43.5107269,
			43.5565033,
			43.60141,
			43.6330261,
			43.6802521,
			43.7255249,
			43.7696838,
			43.8180847,
			43.8623047,
			43.89511,
			43.93927,
			43.98401,
			44.0167236,
			44.0624542,
			44.09262,
			44.1390381,
			44.1998444,
			44.2455,
			44.29277,
			44.3405457,
			44.3848419,
			44.4144745,
			44.4602356,
			44.4924164,
			44.5376129,
			44.5684052,
			44.6131439,
			44.65941,
			44.67601,
			44.72278,
			44.7514648,
			44.79773,
			44.84453,
			44.87372,
			44.9211578,
			44.96782,
			45.0125275,
			45.0623,
			45.1060944,
			45.1508636,
			45.19922,
			45.2458649,
			45.29013,
			45.3364868,
			45.3826752,
			45.42891,
			45.4601,
			45.5058746,
			45.5506439,
			45.596344,
			45.6431122,
			45.68907,
			45.7191925,
			45.7660065,
			45.81369,
			45.8584442,
			45.90425,
			45.95053,
			45.97966,
			46.0123444,
			46.05623,
			46.1030121,
			46.1481,
			46.1968842,
			46.2406464,
			46.2894135,
			46.33426,
			46.3804474,
			46.42772,
			46.4729919,
			46.5028839,
			46.55037,
			46.5951233,
			46.6429,
			46.6897736,
			46.7361,
			46.76622,
			46.8108521,
			46.8571472,
			46.9028625,
			46.9491425,
			46.99536,
			47.0275574,
			47.0728149,
			47.11859,
			47.16646,
			47.1960144,
			47.24478,
			47.2910767,
			47.3359528,
			47.3811035,
			47.4117432,
			47.45851,
			47.5058136,
			47.5490875,
			47.5963135,
			47.6425629,
			47.68933,
			47.7346039,
			47.77884,
			47.81151,
			47.8573,
			47.9034576,
			47.94983,
			47.9945526,
			48.0403442,
			48.0731049,
			48.1034546,
			48.1489563,
			48.1952057,
			48.2404633,
			48.28473,
			48.3331146,
			48.38025,
			48.4234924,
			48.47139,
			48.51706,
			48.5622559,
			48.5954437,
			48.63971,
			48.68599,
			48.72969,
			48.77797,
			48.82373,
			48.8534546,
			48.8981934,
			48.9444122,
			48.9906921,
			49.03621,
			49.0857239,
			49.11435,
			49.16211,
			49.20839,
			49.2526855,
			49.30052,
			49.3466339,
			49.3783264,
			49.42308,
			49.454895,
			49.50009,
			49.54683,
			49.5935669,
			49.64084,
			49.6856079,
			49.7324371,
			49.77658,
			49.8228455,
			49.8700256,
			49.8993073,
			49.947464,
			49.992218,
			50.0404968,
			50.08635,
			50.12947,
			50.16165,
			50.2073975,
			50.2527,
			50.3019562,
			50.3471527,
			50.3934021,
			50.424118,
			50.46791,
			50.5146027,
			50.5623779,
			50.60913,
			50.651886,
			50.68358,
			50.72934,
			50.77864,
			50.8229065,
			50.8697968,
			50.8989563,
			50.9446259,
			50.9904327,
			51.0370026,
			51.0829926,
			51.13028,
			51.1765747,
			51.20575,
			51.25203,
			51.3009644,
			51.34813,
			51.3933868,
			51.44069,
			51.4700165,
			51.515152,
			51.56192,
			51.60872,
			51.6546173,
			51.7009277,
			51.73044,
			51.7777252,
			51.82303,
			51.8700256,
			51.90062,
			51.94728,
			51.9930573,
			52.0399628,
			52.08667,
			52.1298981,
			52.1782074,
			52.2230225,
			52.2557068,
			52.30052,
			52.3482971,
			52.39502,
			52.4252319,
			52.47174,
			52.5178223,
			52.5620575,
			52.6099548,
			52.65683,
			52.7019653,
			52.7482147,
			52.77841,
			52.82469,
			52.8539124,
			52.900238,
			52.9454346,
			52.992218,
			53.0396423,
			53.08333,
			53.1300659,
			53.175354,
			53.22177,
			53.2513428,
			53.29811,
			53.3448944,
			53.3927,
			53.4391479,
			53.4853058,
			53.5310669,
			53.56375,
			53.6085358,
			53.6402435,
			53.68402,
			53.731308,
			53.7775879,
			53.82489,
			53.8682556,
			53.9164734,
			53.96324,
			54.0110474,
			54.05388,
			54.08609,
			54.13292,
			54.1790924,
			54.224884,
			54.25627,
			54.3024139,
			54.3486938,
			54.39496,
			54.4402771,
			54.4865875,
			54.53296,
			54.5796356,
			54.609314,
			54.6405334,
			54.6868439,
			54.7326965,
			54.78038,
			54.82718,
			54.8729858,
			54.90477,
			54.9489441,
			54.995224,
			55.04303,
			55.08934,
			55.1431427,
			55.1914825,
			55.23529,
			55.2835846,
			55.3303375,
			55.37465,
			55.4034424,
			55.45215,
			55.4827728,
			55.52803,
			55.5753326,
			55.618103,
			55.65187,
			55.666,
			55.71376,
			55.76053,
			55.8057251,
			55.85251,
			56.0607147,
			56.1089935,
			56.152298,
			56.1985321,
			56.24681,
			56.2926025,
			56.32292,
			56.3690948,
			56.4153748,
			56.4620972,
			56.4922638,
			56.5405731,
			56.5849152,
			56.63112,
			56.67787,
			56.725174,
			56.7568665,
			56.80272,
			56.8479156,
			56.8947,
			56.9404755,
			56.9842834,
			57.0299835,
			57.076767,
			57.1074677,
			57.155426,
			57.20105,
			57.2473,
			57.2905579,
			57.3403625,
			57.3680573,
			57.4137878,
			57.4620819,
			57.4922638,
			57.53798,
			57.58435,
			57.6311035,
			57.6779022,
			57.72516,
			57.769455,
			57.81772,
			57.84993,
			57.89514,
			57.94246,
			57.9892426,
			58.0330658,
			58.080246,
			58.11194,
			58.1577148,
			58.20305,
			58.25078,
			58.2814636,
			58.3267059,
			58.3744965,
			58.418335,
			58.464035,
			58.5123138,
			58.55858,
			58.6027069,
			58.65013,
			58.6813049,
			58.72809,
			58.77437,
			58.8196564,
			58.86438,
			58.9131622,
			58.94284,
			58.9901123,
			59.0349121,
			59.0811462,
			59.1123352,
			59.1586151,
			59.2055359,
			59.2507172,
			59.29596,
			59.3447571,
			59.391037,
			59.43634,
			59.46556,
			59.51123,
			59.5590057,
			59.6030121,
			59.651062,
			59.6973267,
			59.7295074,
			59.7752838,
			59.8217163,
			59.8664246,
			59.91307,
			59.95836,
			59.9895477,
			60.0364838,
			60.0836639,
			60.12889,
			60.1746826,
			60.22113,
			60.25226,
			60.2974548,
			60.34677,
			60.3920441,
			60.4364777,
			60.48265,
			60.5128021,
			60.5455322,
			60.5892944,
			60.6365662,
			60.66832,
			60.713562,
			60.7613525,
			60.8227539,
			60.8671265,
			60.9133,
			60.9605865,
			61.0079041,
			61.0362854,
			61.0848846,
			61.13066,
			61.177948,
			61.2247467,
			61.25612,
			61.30182,
			61.34758,
			61.3938,
			61.4406128,
			61.4701233,
			61.5161133,
			61.5623474,
			61.61116,
			61.65695,
			61.7017365,
			61.7499847,
			61.7967377,
			61.82643,
			61.8571472,
			61.901947,
			61.95073,
			61.9949951,
			62.04129,
			62.08969,
			62.13539,
			62.1811523,
			62.212326,
			62.25963,
			62.30594,
			62.337738,
			62.39807,
			62.44487,
			62.492157,
			62.539978,
			62.5862732,
			62.61647,
			62.6627045,
			62.709,
			62.7554321,
			62.80066,
			62.848877,
			62.8775177,
			62.9243164,
			62.9565277,
			63.00186,
			63.0475769,
			63.0953522,
			63.14064,
			63.1894379,
			63.2338257,
			63.2799835,
			63.327774,
			63.3717651,
			63.4027557,
			63.4485,
			63.495285,
			63.5410767,
			63.58751,
			63.6332245,
			63.6633,
			63.7085724,
			63.7568665,
			63.802948,
			63.84793,
			63.8942261,
			63.9259033,
			63.972702,
			64.01697,
			64.0622253,
			64.1085052,
			64.15633,
			64.1860657,
			64.23291,
			64.2780457,
			64.32434,
			64.3570557,
			64.40346,
			64.44812,
			64.4943848,
			64.54068,
			64.5870056,
			64.63281,
			64.6785,
			64.70915,
			64.75639,
			64.80171,
			64.84842,
			64.8946838,
			64.9424438,
			64.9731445,
			65.0194244,
			65.0663,
			65.1109,
			65.15617,
			65.20453,
			65.23572,
			65.2809143,
			65.3296356,
			65.37541,
			65.42134,
			65.45187,
			65.49919,
			65.54588,
			65.58966,
			65.63612,
			65.68268,
			65.72894,
			65.7590942,
			65.8068848,
			65.83682,
			65.88435,
			65.92758,
			65.97534,
			66.0228,
			66.05194,
			66.09859,
			66.1453247,
			66.17551,
			66.2234039,
			66.2711,
			66.31378,
			66.3625641,
			66.40785,
			66.4548,
			66.50046,
			66.53319,
			66.57886,
			66.62457,
			66.66991,
			66.7187042,
			66.76291,
			66.79408,
			66.82376,
			66.87254,
			66.9168243,
			66.96358,
			66.99626,
			67.04002,
			67.09996,
			67.14662,
			67.19238,
			67.24017,
			67.283905,
			67.3145752,
			67.3638458,
			67.4086151,
			67.45538,
			67.5002441,
			67.54588,
			67.57805,
			67.6087341,
			67.6555,
			67.7012939,
			67.73143,
			67.77873,
			67.82349,
			67.84059,
			67.88435,
			67.92961,
			67.97638,
			68.00658,
			68.05559,
			68.10069,
			68.14743,
			68.1936646,
			68.2394257,
			68.28624,
			68.33046,
			68.36316,
			68.40842,
			68.45271,
			68.50014,
			68.5457,
			68.59297,
			68.62378,
			68.67142,
			68.7177,
			68.76245,
			68.8087,
			68.8575,
			68.88623,
			68.9325562,
			68.97919,
			69.02748,
			69.072876,
			69.11957,
			69.1492157,
			69.19647,
			69.24127,
			69.28812,
			69.33237,
			69.36246,
			69.40921,
			69.457,
			69.50313,
			69.54805,
			69.5802155,
			69.62752,
			69.67284,
			69.70256,
			69.75131,
			69.78102,
			69.8242645,
			69.87309,
			69.90341,
			69.93553,
			69.98129,
			70.02655,
			70.0733948,
			70.1187,
			70.16501,
			70.19461,
			70.243454,
			70.28923,
			70.33363,
			70.38136,
			70.42758,
			70.45776,
			70.50322,
			70.5509,
			70.58055,
			70.62836,
			70.67418,
			70.703476,
			70.7205048,
			70.7646942,
			70.7953949,
			70.84271,
			70.88652,
			70.93384,
			70.980545,
			71.02533,
			71.07263,
			71.11742,
			71.1642,
			71.2089539,
			71.24016,
			71.28758,
			71.33232,
			71.37802,
			71.42482,
			71.46861,
			71.49985,
			71.54608,
			71.59189,
			71.638,
			71.68347,
			71.72974,
			71.75943,
			71.8069,
			71.85309,
			71.89833,
			71.946106,
			71.9904,
			72.02211,
			72.05333,
			72.0986,
			72.14487,
			72.18967,
			72.2349548,
			72.28226,
			72.32851,
			72.37532,
			72.4221954,
			72.45134,
			72.4970551,
			72.54385,
			72.58914,
			72.63498,
			72.68071,
			72.7290039,
			72.7607,
			72.80702,
			72.85283,
			72.89807,
			72.93028,
			72.97504,
			73.0214539,
			73.06816,
			73.112915,
			73.1591949,
			73.2060242,
			73.25284,
			73.29608,
			73.3272858,
			73.37309,
			73.41994,
			73.467804,
			73.51245,
			73.55827,
			73.5895538,
			73.6352539,
			73.68054,
			73.72731,
			73.77261,
			73.81946,
			73.84859,
			73.89636,
			73.94316,
			73.99049,
			74.03529,
			74.08156,
			74.11377,
			74.15752,
			74.2057953,
			74.25107,
			74.29736,
			74.3446045,
			74.37579,
			74.4200745,
			74.45276,
			74.4991,
			74.544754,
			74.59001,
			74.63478,
			74.68106,
			74.72931,
			74.77309,
			74.82097,
			74.85205,
			74.89833,
			74.92795,
			74.97319,
			75.02194,
			75.06636,
			75.11348,
			75.14368,
			75.16025,
			75.2055359,
			75.25081,
			75.2991638,
			75.32875,
			75.37352,
			75.42189,
			75.46861,
			75.49989,
			75.5460052,
			75.58977,
			75.63515,
			75.6818542,
			75.7290955,
			75.77489,
			75.82335,
			75.86897,
			75.91672,
			75.94537,
			75.99115,
			76.03659,
			76.08525,
			76.1299744,
			76.1762543,
			76.20697,
			76.25433,
			76.3006,
			76.33121,
			76.3764648,
			76.4242554,
			76.46951,
			76.5153046,
			76.56206,
			76.59224,
			76.63634,
			76.68277,
			76.73056,
			76.7607,
			76.80647,
			76.85231,
			76.89856,
			76.94528,
			76.99054,
			77.03581,
			77.0830841,
			77.1288147,
			77.1756,
			77.22037,
			77.2511444,
			77.2983551,
			77.34508,
			77.39133,
			77.43564,
			77.4823761,
			77.51253,
			77.5603,
			77.60559,
			77.63623,
			77.6841,
			77.7298,
			77.77809,
			77.80928,
			77.8570557,
			77.89125,
			77.93622,
			77.98381,
			78.02704,
			78.07231,
			78.12073,
			78.16383,
			78.21109,
			78.25789,
			78.28931,
			78.33389,
			78.3811,
			78.42787,
			78.47365,
			78.51947,
			78.5506744,
			78.59636,
			78.64569,
			78.69246,
			78.73779,
			78.78102,
			78.81218,
			78.86044,
			78.90625,
			78.93648,
			78.98222,
			79.02797,
			79.06015,
			79.0737457,
			79.12218,
			79.16724,
			79.2124939,
			79.25728,
			79.2891846,
			79.33383,
			79.3820648,
			79.42784,
			79.47266,
			79.51897,
			79.56474,
			79.5949249,
			79.64223,
			79.68912,
			79.73535,
			79.77959,
			79.82739,
			79.8566,
			79.901474,
			79.94768,
			79.99646,
			80.04025,
			80.08815,
			80.11876,
			80.16249,
			80.19577,
			80.24199,
			80.28793,
			80.33261,
			80.37984,
			80.42764,
			80.47307,
			80.5203552,
			80.56558,
			80.61333,
			80.64453,
			80.69032,
			80.7346649,
			80.7803955,
			80.82718,
			80.8729858,
			80.9026642,
			80.94949,
			80.99675,
			81.04053,
			81.0884552,
			81.13373,
			81.16533,
			81.1954956,
			81.2402954,
			81.26979,
			81.31831,
			81.34796,
			81.39473,
			81.42592,
			81.46954,
			81.51857,
			81.5648041,
			81.611084,
			81.6568756,
			81.68959,
			81.73299,
			81.78015,
			81.82645,
			81.87222,
			81.91954,
			81.94719,
			81.97836,
			82.02518,
			82.07115,
			82.11574,
			82.1474457,
			82.19231,
			82.2552948,
			82.3,
			82.3446655,
			82.39096,
			82.4392853,
			82.46991,
			82.51526,
			82.56253,
			82.6078339,
			82.65213,
			82.7,
			82.72958,
			82.7788849,
			82.8221741,
			82.86975,
			82.91374,
			82.9449,
			82.99217,
			83.03862,
			83.08276,
			83.1300354,
			83.17534,
			83.22275,
			83.25281,
			83.29913,
			83.34578,
			83.39317,
			83.43846,
			83.4852,
			83.5153656,
			83.56012,
			83.60742,
			83.65076,
			83.69595,
			83.74426,
			83.77547,
			83.8187561,
			83.85101,
			83.89772,
			83.9445,
			83.98978,
			84.03609,
			84.08235,
			84.13115,
			84.1598053,
			84.2056,
			84.2523956,
			84.29819,
			84.3454742,
			84.3912659,
			84.4360657,
			84.48285,
			84.53012,
			84.5607758,
			84.5919647,
			84.63925,
			84.68353,
			84.7298,
			84.7750854,
			84.82233,
			84.86865,
			84.91437,
			84.96213,
			85.00842,
			85.0522461,
			85.0834045,
			85.1156,
			85.16182,
			85.20961,
			85.2565155,
			85.30263,
			85.34691,
			85.39519,
			85.4404755,
			85.48837,
			85.53461,
			85.5807648,
			85.61095,
			85.6562347,
			85.68941,
			85.73471,
			85.78096,
			85.82721,
			85.87349,
			85.92142,
			85.9519653,
			85.9988251,
			86.04501,
			86.08928,
			86.13457,
			86.18234,
			86.2296,
			86.27489,
			86.32216,
			86.35237,
			86.39923,
			86.44487,
			86.49065,
			86.53543,
			86.58423,
			86.6320648,
			86.6621552,
			86.7084351,
			86.75572,
			86.80159,
			86.8472443,
			86.8950043,
			86.92468,
			86.95601,
			87.00064,
			87.03128,
			87.08003,
			87.12581,
			87.17078,
			87.23251,
			87.27719,
			87.32295,
			87.36835,
			87.3994751,
			87.44568,
			87.4928741,
			87.54016,
			87.58644,
			87.63066,
			87.67746,
			87.7086639,
			87.75394,
			87.8013153,
			87.84798,
			87.89673,
			87.9424744,
			87.97267,
			88.01851,
			88.0621643,
			88.10994,
			88.15373,
			88.20116,
			88.2311554,
			88.27994,
			88.32518,
			88.37259,
			88.4193,
			88.46297,
			88.4941559,
			88.54195,
			88.59027,
			88.63608,
			88.68085,
			88.72861,
			88.75879,
			88.80301,
			88.85092,
			88.89464,
			88.9424438,
			88.98926,
			89.01845,
			89.06473,
			89.09592,
			89.14072,
			89.18605,
			89.2328644,
			89.28006,
			89.326355,
			89.3726654,
			89.40326,
			89.45064,
			89.49745,
			89.5427246,
			89.58864,
			89.6338654,
			89.68057,
			89.727356,
			89.77214,
			89.8033447,
			89.84865,
			89.8949,
			89.93919,
			89.9876556,
			90.03383,
			90.0624542,
			90.11076,
			90.1585541,
			90.20558,
			90.25067,
			90.29492,
			90.3271,
			90.35782,
			90.40265,
			90.447876,
			90.49466,
			90.54047,
			90.58893,
			90.63365,
			90.67882,
			90.72763,
			90.77304,
			90.81926,
			90.84941,
			90.8977051,
			90.9424744,
			90.98993,
			91.03633,
			91.08034,
			91.1135559,
			91.15932,
			91.20325,
			91.25258,
			91.29817,
			91.3449554,
			91.37515,
			91.403,
			91.44913,
			91.49542,
			91.5426941,
			91.58949,
			91.63284,
			91.681015,
			91.7267151,
			91.77316,
			91.81984,
			91.8640747,
			91.89679,
			91.9289856,
			91.97328,
			92.0200958,
			92.06534,
			92.095,
			92.14281,
			92.20343,
			92.24646,
			92.29527,
			92.33957,
			92.38689,
			92.4160461,
			92.46483,
			92.49501,
			92.54033,
			92.5866547,
			92.63254,
			92.67868,
			92.7249756,
			92.77144,
			92.8009644,
			92.8502655,
			92.88097,
			92.92523,
			92.9717255,
			93.01631,
			93.048996,
			93.0957642,
			93.1400757,
			93.18848,
			93.25723,
			93.30417,
			93.34827,
			93.39551,
			93.44278,
			93.50429,
			93.550415,
			93.57956,
			93.62784,
			93.6736145,
			93.7224,
			93.76625,
			93.81442,
			93.86017,
			93.89136,
			93.9368744,
			93.9858856,
			94.0301056,
			94.0778656,
			94.12366,
			94.15384,
			94.2007,
			94.24736,
			94.29263,
			94.34041,
			94.3857,
			94.43092,
			94.47768,
			94.50885,
			94.5553,
			94.58382,
			94.62955,
			94.6783142,
			94.70848,
			94.75578,
			94.77197,
			94.81714,
			94.86388,
			94.9111,
			94.95735,
			95.00119,
			95.03175,
			95.0810852,
			95.123764,
			95.1555939,
			95.2008362,
			95.24696,
			95.29372,
			95.34018,
			95.38577,
			95.43149,
			95.48079,
			95.52402,
			95.55519,
			95.58798,
			95.63368,
			95.67886,
			95.72363,
			95.75592,
			95.8001862,
			95.81517,
			95.84735,
			95.89511,
			95.9398651,
			95.97266,
			96.01836,
			96.04851,
			96.08069,
			96.12694,
			96.17284,
			96.21748,
			96.2632141,
			96.31099,
			96.34079,
			96.38815,
			96.43474,
			96.47998,
			96.52524,
			96.57277,
			96.60222,
			96.65047,
			96.69769,
			96.727356,
			96.7736359,
			96.82054,
			96.86577,
			96.89534,
			96.94313,
			96.98892,
			97.03525,
			97.08101,
			97.12776,
			97.17503,
			97.21936,
			97.25102,
			97.29625,
			97.3269348,
			97.37271,
			97.3891144,
			97.4345856,
			97.4823456,
			97.5270844,
			97.55777,
			97.605545,
			97.65082,
			97.6975555,
			97.7428,
			97.78874,
			97.835434,
			97.8806152,
			97.9132843,
			97.95805,
			97.9899,
			98.03453,
			98.0829,
			98.11151,
			98.15727,
			98.1733551,
			98.2196655,
			98.26495,
			98.31268,
			98.3448639,
			98.390686,
			98.43443,
			98.48274,
			98.52847,
			98.574234,
			98.62201,
			98.66527,
			98.6991,
			98.74324,
			98.78903,
			98.83534,
			98.8820953,
			98.92836,
			98.9575348,
			99.00473,
			99.05113,
			99.0979,
			99.14368,
			99.18945,
			99.22017,
			99.2523956,
			99.29506,
			99.34282,
			99.38988,
			99.43497,
			99.4832,
			99.5294647,
			99.5737457,
			99.6200562,
			99.66533,
			99.71211,
			99.74428,
			99.78905,
			99.83641,
			99.88109,
			99.92787,
			99.97516,
			100.004883,
			100.036606,
			100.081406,
			100.128159,
			100.159851,
			100.206268,
			100.2514,
			100.31221,
			100.357986,
			100.402832,
			100.450058,
			100.497345,
			100.527008,
			100.573288,
			100.618073,
			100.665329,
			100.694977,
			100.741257,
			100.789566,
			100.818268,
			100.86348,
			100.911743,
			100.95903,
			101.003418,
			101.039536,
			101.085419,
			101.13324,
			101.178345,
			101.225632,
			101.270035,
			101.302994,
			101.3329,
			101.380569,
			101.424835,
			101.455734,
			101.500412,
			101.548065,
			101.56369,
			101.608932,
			101.655838,
			101.702469,
			101.747742,
			101.792465,
			101.824173,
			101.871262,
			101.917267,
			101.963013,
			102.008759,
			102.056549,
			102.085724,
			102.132584,
			102.143951
		]
	],
	'8366_8648_7982': [
		[
			49.5975571
		],
		[
			0
		],
		[
			0,
			0.7529297,
			2.77905273,
			6.82702351,
			8.849868,
			12.8881264,
			16.927269,
			20.97088,
			25.0201759,
			29.0740318,
			33.1279945,
			35.154892,
			39.20782,
			43.2580452,
			45.2829933,
			49.3327637,
			53.3818,
			57.4298553,
			61.4765,
			63.49922,
			67.54511,
			69.5680847,
			73.64995,
			77.69972,
			79.72496,
			83.77582,
			87.82636,
			91.8430252,
			95.89329,
			99.94279,
			103.989876,
			106.046661,
			110.091423,
			114.134621,
			116.156219,
			120.165848,
			124.21051,
			126.233368,
			130.280731,
			132.305344,
			136.355118,
			138.380142,
			142.430145,
			146.513336,
			148.537216,
			152.551071,
			156.598114,
			158.62085,
			162.666687,
			164.689713,
			168.735291,
			172.814514,
			176.826538,
			180.871765,
			184.94989,
			188.995819,
			191.019058,
			195.069641,
			197.09549,
			201.148224,
			205.202881,
			209.259232,
			211.287857,
			213.31601,
			217.373062,
			219.368057,
			223.460114,
			225.489655,
			229.548767,
			233.574478,
			235.604553,
			239.664337,
			243.724487,
			247.785233,
			251.846344,
			253.877075,
			257.938416,
			261.9987,
			266.0581,
			268.088,
			272.148315,
			276.209869,
			278.24054,
			282.301941,
			284.332275,
			288.393829,
			292.456177,
			296.518036,
			298.548737,
			302.609222,
			306.66925,
			310.729431,
			314.790558,
			316.821747,
			320.8851,
			324.9488,
			326.980743,
			331.04425,
			335.1081,
			337.140259,
			341.204224,
			343.2363,
			347.3007,
			351.364716,
			355.430267,
			359.496277,
			363.562378,
			367.6283,
			369.661041,
			373.7265,
			375.7597,
			379.8257,
			383.891083,
			385.924225,
			389.9908,
			394.0575,
			398.123535,
			400.156677,
			404.224,
			408.290863,
			412.358032,
			414.391846,
			418.4593,
			422.527222,
			426.5951,
			430.66333,
			434.73114,
			436.765564,
			440.834045,
			442.868164,
			446.937164,
			451.005737,
			455.074554,
			457.108765,
			461.178284,
			465.247955,
			469.3178,
			473.3869,
			475.4219,
			479.4915,
			481.526672,
			485.597137,
			489.667236,
			493.737427,
			497.808044,
			501.878632,
			505.9497,
			507.985229,
			512.0563,
			516.127,
			518.1629,
			522.2347,
			526.306152,
			528.342041,
			532.414063,
			536.4863,
			540.5585,
			544.6307,
			546.666748,
			550.739441,
			554.8122,
			556.848633,
			560.921448,
			562.9582,
			567.0316,
			571.103943,
			575.1778,
			579.2517,
			583.325562,
			587.399231,
			589.43634,
			593.510132,
			597.583862,
			599.62085,
			603.694458,
			605.7311,
			609.8041,
			613.876,
			617.946655,
			622.0164,
			626.0864,
			630.1557,
			632.1903,
			636.2593,
			638.293457,
			642.36084,
			644.3943,
			648.46106,
			652.5278,
			654.5947,
			656.6278,
			660.6952,
			662.6948,
			666.7962,
			670.865234,
			674.9346,
			676.969055,
			681.0393,
			685.109863,
			687.1449,
			691.214844,
			695.284546,
			699.353943,
			703.421448,
			707.488342,
			711.554932,
			713.5883,
			717.653931,
			719.687256,
			723.7536,
			725.7873,
			729.854,
			733.92,
			737.983948,
			742.0465,
			746.107544,
			750.1678,
			752.197754,
			756.258057,
			760.319,
			762.3497,
			766.411255,
			770.4718,
			774.530945,
			778.5888,
			782.647,
			784.6759,
			788.733,
			792.790161,
			794.818848,
			798.8763,
			802.9342,
			804.963,
			809.020447,
			813.0772,
			815.1051,
			819.1606,
			823.2163,
			827.2727,
			831.3302,
			835.3885,
			837.418152,
			841.4773,
			845.537048,
			847.566956,
			851.626831,
			855.687866,
			859.75116,
			863.814941,
			867.8783,
			869.9098,
			871.94165,
			876.006,
			880.075,
			884.1438,
			888.2114,
			890.2449,
			894.3124,
			898.3803,
			902.447632,
			906.5134,
			910.5767,
			912.6073,
			914.6376,
			918.695435,
			922.75116,
			926.805542,
			928.8325,
			932.8865,
			936.9409,
			940.9967,
			945.054443,
			949.114,
			953.175049,
			955.206055,
			959.2702,
			963.3368,
			965.371155,
			969.440735,
			973.5125,
			975.5503,
			979.627441,
			983.7069,
			987.7891,
			991.871948,
			995.9558,
			997.998,
			1002.08276,
			1006.1676,
			1008.20984,
			1012.28949,
			1016.36487,
			1018.36755,
			1022.43488,
			1024.502,
			1028.56763,
			1030.60193,
			1034.66992,
			1038.73657,
			1040.769,
			1044.7998,
			1048.89832,
			1050.93152,
			1054.99756,
			1059.063,
			1061.09546,
			1063.12769,
			1067.19092,
			1071.25623,
			1073.28943,
			1077.35718,
			1081.42651,
			1085.4989,
			1089.57556,
			1091.6156,
			1095.69666,
			1099.77954,
			1103.86316,
			1105.90723,
			1109.99561,
			1114.08362,
			1118.16956,
			1122.25708,
			1124.3009,
			1128.38855,
			1132.47607,
			1136.563,
			1138.606,
			1142.69214,
			1146.77588,
			1150.85657,
			1152.89526,
			1156.968,
			1161.03479,
			1165.09558,
			1167.126,
			1171.187,
			1175.25037,
			1179.31628,
			1183.38489,
			1185.41956,
			1189.49316,
			1191.53,
			1195.604,
			1199.67468,
			1201.71033,
			1205.78369,
			1209.856,
			1211.89124,
			1215.95874,
			1220.02417,
			1224.08777,
			1226.1189,
			1230.181,
			1232.21191,
			1234.24243,
			1238.3042,
			1242.36743,
			1246.433,
			1250.50171,
			1252.53662,
			1254.57373,
			1258.64844,
			1262.72351,
			1266.799,
			1270.876,
			1272.91479,
			1276.993,
			1281.07,
			1283.108,
			1287.18091,
			1291.24939,
			1295.31592,
			1299.38,
			1303.43909,
			1307.49561,
			1309.52344,
			1313.5791,
			1315.60571,
			1319.65845,
			1321.68457,
			1325.73657,
			1329.78882,
			1333.84424,
			1337.90442,
			1341.967,
			1343.99878,
			1348.06775,
			1352.14087,
			1354.17883,
			1358.25085,
			1362.323,
			1364.35938,
			1368.43127,
			1372.50244,
			1376.57239,
			1380.63953,
			1384.70447,
			1388.77417,
			1390.80981,
			1394.88782,
			1398.97,
			1401.0116,
			1405.0968,
			1409.17786,
			1413.25415,
			1417.32324,
			1421.38281,
			1423.41028,
			1427.46387,
			1429.49,
			1433.54468,
			1437.602,
			1439.63159,
			1443.69287,
			1447.75488,
			1449.78564,
			1453.84436,
			1457.8999,
			1461.95251,
			1466.00146,
			1468.02429,
			1472.06873,
			1476.11084,
			1478.13159,
			1482.17188,
			1486.20911,
			1490.24146,
			1494.27148,
			1498.29907,
			1500.31177,
			1504.33667,
			1508.32739,
			1510.37268,
			1514.36316,
			1518.4209,
			1522.44446,
			1526.4679,
			1528.47949,
			1530.491,
			1534.48059,
			1538.50342,
			1542.5271,
			1546.55249,
			1550.57825,
			1552.62488,
			1556.61926,
			1558.63342,
			1562.66235,
			1566.69238,
			1570.72388,
			1574.75684,
			1578.791,
			1582.82764,
			1584.84619,
			1588.88416,
			1590.90332,
			1594.94189,
			1598.98083,
			1603.02026,
			1607.05957,
			1611.09888,
			1613.11877,
			1617.15881,
			1621.19922,
			1625.2395,
			1627.25977,
			1631.3009,
			1635.34277,
			1637.36365,
			1641.40576,
			1645.44812,
			1649.49023,
			1653.53345,
			1655.55493,
			1659.59863,
			1663.64343,
			1665.66565,
			1669.70972,
			1673.75488,
			1675.77722,
			1679.82312,
			1683.86914,
			1685.892,
			1689.938,
			1693.98462,
			1698.03113,
			1702.07861,
			1706.127,
			1708.151,
			1712.198,
			1716.24719,
			1718.27148,
			1722.32,
			1726.36926,
			1730.4187,
			1734.46814,
			1736.49341,
			1740.54358,
			1744.594,
			1746.61938,
			1750.6709,
			1752.69678,
			1756.748,
			1760.79883,
			1764.85034,
			1768.90393,
			1772.95667,
			1777.00977,
			1779.0365,
			1783.08911,
			1787.14282,
			1789.16968,
			1793.22375,
			1795.25061,
			1799.30481,
			1803.36047,
			1807.416,
			1811.47144,
			1815.52722,
			1819.58386,
			1821.61157,
			1825.66772,
			1827.696,
			1831.75244,
			1835.80957,
			1837.83887,
			1841.89722,
			1845.95581,
			1850.014,
			1854.07227,
			1858.13159,
			1862.19238,
			1864.22314,
			1868.28687,
			1870.31885,
			1874.38293,
			1878.446,
			1882.50708,
			1886.56787,
			1888.598,
			1892.658,
			1896.71838,
			1900.7793,
			1902.80981,
			1906.87085,
			1910.93213,
			1912.96289,
			1917.02441,
			1921.0863,
			1925.14844,
			1929.21045,
			1931.24182,
			1935.30566,
			1939.37012,
			1943.435,
			1945.46765,
			1949.53271,
			1951.56519,
			1955.63,
			1959.69385,
			1961.72559,
			1965.78882,
			1967.82068,
			1971.885,
			1973.91724,
			1977.98193,
			1982.047,
			1984.0791,
			1988.1438,
			1992.2085,
			1994.24121,
			1998.30664,
			2002.37134,
			2006.436,
			2008.469,
			2012.53564,
			2016.60315,
			2020.66931,
			2024.7356,
			2028.80249,
			2030.83594,
			2034.90222,
			2036.93591,
			2041.00342,
			2043.03735,
			2047.10522,
			2051.17236,
			2053.206,
			2057.27344,
			2059.30737,
			2063.375,
			2065.40869,
			2069.47632,
			2073.54468,
			2075.579,
			2079.649,
			2083.71826,
			2087.787,
			2091.85571,
			2095.9248,
			2097.95972,
			2102.02979,
			2106.10059,
			2110.17188,
			2112.20776,
			2116.281,
			2118.31738,
			2122.3894,
			2126.46118,
			2130.53345,
			2134.60767,
			2138.684,
			2140.72217,
			2144.798,
			2146.836,
			2150.91113,
			2154.986,
			2157.02344,
			2161.09839,
			2165.173,
			2169.248,
			2171.2876,
			2175.37,
			2179.459,
			2183.55444,
			2187.64917,
			2189.695,
			2193.78223,
			2197.864,
			2201.94043,
			2206.01416,
			2208.05078,
			2212.12354,
			2216.19653,
			2220.26978,
			2222.30615,
			2226.37939,
			2230.45313,
			2232.49072,
			2236.56543,
			2240.639,
			2244.71338,
			2248.787,
			2250.82422,
			2254.89819,
			2258.97314,
			2261.01074,
			2265.08545,
			2269.16,
			2271.197,
			2275.27124,
			2279.34619,
			2283.42139,
			2285.45972,
			2289.53467,
			2293.60986,
			2297.68579,
			2301.76147,
			2305.83716,
			2307.87451,
			2311.95,
			2316.02563,
			2318.06348,
			2322.14,
			2326.216,
			2328.25415,
			2330.29248,
			2334.36914,
			2340.4834,
			2344.56,
			2346.59863,
			2350.67529,
			2354.752,
			2356.79,
			2360.86719,
			2364.94434,
			2366.983,
			2371.06,
			2375.13721,
			2379.21484,
			2383.29224,
			2387.37,
			2389.40918,
			2393.48657,
			2397.56445,
			2399.60352,
			2403.681,
			2407.75879,
			2411.83643,
			2415.91455,
			2419.993,
			2422.032,
			2426.1106,
			2430.189,
			2432.22827,
			2436.307,
			2440.3855,
			2442.42456,
			2446.50342,
			2448.54248,
			2452.62085,
			2456.69873,
			2458.7373,
			2462.81445,
			2464.85254,
			2466.891,
			2470.96729,
			2475.04053,
			2479.1106,
			2483.17627,
			2485.20654,
			2489.26514,
			2493.319,
			2495.378,
			2499.42749,
			2503.47681,
			2505.50122,
			2509.5498,
			2513.59814,
			2517.649,
			2521.69971,
			2525.75,
			2527.77563,
			2531.82715,
			2535.87866,
			2537.905,
			2541.958,
			2546.01123,
			2548.038,
			2552.0918,
			2554.11865,
			2558.17334,
			2562.22852,
			2564.256,
			2568.31055,
			2572.36279,
			2576.414,
			2580.465,
			2584.515,
			2588.565,
			2590.59033,
			2594.64136,
			2598.694,
			2600.721,
			2604.77515,
			2606.80176,
			2610.85352,
			2614.90381,
			2618.95264,
			2623,
			2627.04639,
			2629.06982,
			2633.11475,
			2637.15942,
			2639.182,
			2643.22656,
			2647.27173,
			2649.29443,
			2653.33887,
			2657.3833,
			2661.42529,
			2665.46582,
			2669.50537,
			2673.545,
			2675.56445,
			2679.603,
			2683.64136,
			2685.66162,
			2689.6687,
			2693.71,
			2695.76514,
			2711.93823,
			2715.98169,
			2718.003,
			2722.04565,
			2724.067,
			2728.10986,
			2732.15332,
			2736.197,
			2738.219,
			2742.26367,
			2746.30884,
			2748.331,
			2752.37549,
			2756.41919,
			2760.463,
			2764.508,
			2768.55371,
			2770.57642,
			2774.62378,
			2778.67139,
			2780.695,
			2784.74438,
			2788.79443,
			2792.845,
			2796.89551,
			2800.94629,
			2802.97217,
			2804.99829,
			2809.05029,
			2813.102,
			2817.15381,
			2819.17969,
			2823.23169,
			2827.28369,
			2831.33545,
			2835.38574,
			2837.41016,
			2841.457,
			2845.505,
			2849.55176,
			2853.59814,
			2855.621,
			2859.66846,
			2863.7168,
			2865.742,
			2869.79248,
			2873.84424,
			2877.89746,
			2881.95117,
			2886.00586,
			2888.0332,
			2892.0874,
			2894.1145,
			2898.16919,
			2900.19629,
			2904.249,
			2908.29883,
			2912.34839,
			2916.39722,
			2920.44556,
			2924.49365,
			2926.5188,
			2930.56934,
			2932.59619,
			2936.65039,
			2938.67773,
			2942.73486,
			2946.79346,
			2950.853,
			2954.912,
			2958.97314,
			2963.03418,
			2965.06445,
			2969.12549,
			2971.15576,
			2975.2168,
			2979.277,
			2981.30713,
			2985.36768,
			2989.426,
			2993.48364,
			2997.54053,
			3001.59644,
			3003.623,
			3007.67578,
			3011.72583,
			3013.75146,
			3017.80127,
			3019.8252,
			3023.87354,
			3027.921,
			3029.945,
			3033.993,
			3036.0166,
			3040.065,
			3044.11353,
			3046.138,
			3050.18652,
			3052.21,
			3056.25879,
			3060.30859,
			3064.35986,
			3066.38623,
			3070.44043,
			3072.46777,
			3076.52441,
			3082.61255,
			3084.64258,
			3088.70313,
			3092.76416,
			3094.79443,
			3098.85425,
			3102.91235,
			3104.94067,
			3111.02,
			3115.069,
			3119.117,
			3121.14063,
			3125.1875,
			3129.234,
			3131.25781,
			3135.30664,
			3139.35669,
			3141.38232,
			3145.43384,
			3147.46021,
			3151.51416,
			3155.57153,
			3157.59888,
			3161.65381,
			3163.68213,
			3167.73853,
			3171.79517,
			3173.82422,
			3177.88184,
			3179.91016,
			3183.96826,
			3188.025,
			3192.08057,
			3194.10913,
			3198.16553,
			3202.2207,
			3204.2478,
			3206.275,
			3210.32813,
			3214.38159,
			3216.40845,
			3220.461,
			3224.51221,
			3226.5376,
			3230.5874,
			3234.637,
			3238.685,
			3242.73218,
			3246.77954,
			3248.80273,
			3252.84766,
			3254.86938,
			3258.9126,
			3262.956,
			3264.97754,
			3269.01978,
			3273.0603,
			3275.08,
			3279.11938,
			3283.15869,
			3287.19653,
			3291.2334,
			3295.26978,
			3299.30518,
			3301.32275,
			3305.35718,
			3309.39,
			3311.406,
			3315.43726,
			3319.46631,
			3323.49463,
			3327.52222,
			3331.54834,
			3333.561,
			3335.57349,
			3339.59766,
			3343.62158,
			3347.64551,
			3349.65723,
			3353.67969,
			3355.691,
			3359.68042,
			3363.70337,
			3365.74854,
			3369.7395,
			3373.76587,
			3377.79346,
			3381.82373,
			3385.85474,
			3387.87061,
			3391.90234,
			3395.93237,
			3397.94727,
			3401.97754,
			3403.99219,
			3408.021,
			3412.04932,
			3414.06348,
			3416.07837,
			3420.1084,
			3424.14,
			3428.17432,
			3430.192,
			3434.22827,
			3438.2666,
			3440.28638,
			3442.30566,
			3446.34448,
			3450.38354,
			3452.40234,
			3456.44,
			3458.4585,
			3460.47729,
			3464.51318,
			3468.54858,
			3472.58423,
			3476.62061,
			3478.63843,
			3482.67383,
			3486.70972,
			3490.74658,
			3494.78418,
			3498.82349,
			3500.84351,
			3504.88525,
			3506.90649,
			3510.94971,
			3514.99438,
			3517.017,
			3521.0625,
			3525.1084,
			3529.154,
			3533.201,
			3537.24878,
			3541.29639,
			3543.31982,
			3547.36768,
			3551.41528,
			3553.43945,
			3557.48779,
			3561.53638,
			3563.56055,
			3567.60913,
			3571.6582,
			3575.70825,
			3579.758,
			3581.7832,
			3585.83374,
			3587.85913,
			3591.9104,
			3595.962,
			3597.98779,
			3602.03979,
			3606.09,
			3610.13867,
			3614.18652,
			3618.23462,
			3622.284,
			3624.30933,
			3628.361,
			3632.41431,
			3634.441,
			3638.49463,
			3640.522,
			3644.577,
			3648.632,
			3652.68726,
			3656.74316,
			3660.79932,
			3662.82764,
			3666.88379,
			3670.94043,
			3672.96924,
			3677.02686,
			3681.085,
			3685.14282,
			3689.202,
			3691.23145,
			3695.29077,
			3699.35,
			3703.409,
			3705.43848,
			3709.4978,
			3713.55713,
			3715.5874,
			3719.648,
			3723.70947,
			3725.74048,
			3729.80371,
			3733.867,
			3737.93018,
			3741.993,
			3744.024,
			3748.08643,
			3752.149,
			3756.21069,
			3758.2417,
			3762.30371,
			3766.36621,
			3770.4292,
			3774.49243,
			3776.52417,
			3780.588,
			3784.65137,
			3788.71484,
			3790.747,
			3794.81152,
			3796.84351,
			3800.90771,
			3804.97217,
			3807.004,
			3811.06787,
			3813.1,
			3817.163,
			3819.19458,
			3823.257,
			3827.31885,
			3829.34937,
			3833.4082,
			3835.43677,
			3839.49121,
			3843.54443,
			3847.59814,
			3849.625,
			3853.71631,
			3857.77637,
			3861.838,
			3865.89868,
			3869.95752,
			3871.98755,
			3876.049,
			3880.11279,
			3882.145,
			3886.21,
			3888.24268,
			3892.30786,
			3894.34,
			3898.4043,
			3902.46826,
			3904.499,
			3908.561,
			3912.62354,
			3914.6543,
			3918.71582,
			3920.74658,
			3924.80786,
			3928.86914,
			3930.9,
			3934.962,
			3939.02344,
			3943.0498,
			3947.14258,
			3951.167,
			3953.19556,
			3957.2522,
			3961.308,
			3965.36475,
			3967.39355,
			3971.45166,
			3973.481,
			3977.539,
			3981.58984,
			3985.63623,
			3989.68652,
			3993.77344,
			3995.81543,
			3999.89453,
			4003.93823,
			4007.99268,
			4010.022,
			4012.05469,
			4016.121,
			4020.18726,
			4024.249,
			4025.329
		],
		[
			0,
			0.00537109375,
			0.0515556335,
			0.08115387,
			0.129508972,
			0.174743652,
			0.219432831,
			0.265655518,
			0.312984467,
			0.358139038,
			0.406547546,
			0.453140259,
			0.498397827,
			0.5302124,
			0.5747986,
			0.6220398,
			0.665298462,
			0.713180542,
			0.758811951,
			0.788002,
			0.835762,
			0.8663254,
			0.912727356,
			0.959396362,
			1.00418854,
			1.05294037,
			1.09916687,
			1.14549255,
			1.19017792,
			1.23844147,
			1.284729,
			1.31480408,
			1.35952,
			1.406456,
			1.45153809,
			1.49880219,
			1.530571,
			1.57724762,
			1.62299347,
			1.65363312,
			1.698822,
			1.72922516,
			1.77479553,
			1.8225708,
			1.83612823,
			1.88285828,
			1.93131256,
			1.97586823,
			2.022644,
			2.05381775,
			2.09853363,
			2.14637,
			2.193016,
			2.23739624,
			2.28255463,
			2.329979,
			2.358963,
			2.40677643,
			2.452011,
			2.49887848,
			2.542984,
			2.59226227,
			2.62195587,
			2.6526413,
			2.69901276,
			2.72914124,
			2.775772,
			2.804428,
			2.85119629,
			2.88237762,
			2.92713928,
			2.97489929,
			3.02068329,
			3.06639862,
			3.11274719,
			3.14283752,
			3.188652,
			3.23538971,
			3.282135,
			3.32898712,
			3.37319183,
			3.40549469,
			3.450592,
			3.48277283,
			3.52751923,
			3.575821,
			3.61906433,
			3.66601563,
			3.69903564,
			3.74476624,
			3.79153442,
			3.837944,
			3.88359833,
			3.91680145,
			3.96316528,
			4.008362,
			4.05365,
			4.10089874,
			4.14720154,
			4.177849,
			4.20755,
			4.25484467,
			4.30116272,
			4.347458,
			4.3916626,
			4.439476,
			4.48627472,
			4.533539,
			4.57949066,
			4.60952,
			4.647278,
			4.69203949,
			4.739479,
			4.76953125,
			4.81481934,
			4.86170959,
			4.909912,
			4.954735,
			5.000984,
			5.04781342,
			5.09404755,
			5.124748,
			5.171074,
			5.21784973,
			5.265007,
			5.31040955,
			5.355713,
			5.40350342,
			5.448166,
			5.479439,
			5.52510834,
			5.569908,
			5.6177063,
			5.649887,
			5.69525146,
			5.73996735,
			5.78673553,
			5.832985,
			5.87779236,
			5.9095,
			5.954811,
			6.002144,
			6.04694366,
			6.092125,
			6.14043427,
			6.18471527,
			6.232048,
			6.26371,
			6.30948639,
			6.35630035,
			6.402611,
			6.44837952,
			6.49462128,
			6.525818,
			6.57066345,
			6.61539459,
			6.66226959,
			6.7074585,
			6.75325775,
			6.78547668,
			6.83124542,
			6.87599945,
			6.92132568,
			6.95301056,
			7.00132,
			7.04715729,
			7.092354,
			7.14028931,
			7.18444061,
			7.231125,
			7.2786026,
			7.30921173,
			7.35700226,
			7.4013443,
			7.431732,
			7.47991943,
			7.52609253,
			7.572487,
			7.61764526,
			7.66401672,
			7.70922852,
			7.755043,
			7.80131531,
			7.83249664,
			7.865288,
			7.91043854,
			7.94165039,
			7.987465,
			8.033073,
			8.079666,
			8.096214,
			8.14189148,
			8.173645,
			8.217857,
			8.263733,
			8.308441,
			8.354759,
			8.401039,
			8.447327,
			8.476486,
			8.524284,
			8.568596,
			8.615387,
			8.66066,
			8.708954,
			8.755791,
			8.801079,
			8.846848,
			8.875511,
			8.921814,
			8.952499,
			8.99976349,
			9.04805,
			9.092819,
			9.14109,
			9.186371,
			9.233139,
			9.277351,
			9.30954,
			9.357307,
			9.403107,
			9.448357,
			9.496643,
			9.540894,
			9.588707,
			9.636009,
			9.667648,
			9.714119,
			9.75869,
			9.803963,
			9.849731,
			9.898117,
			9.929306,
			9.975479,
			10.0057983,
			10.050499,
			10.0987473,
			10.1439819,
			10.1892624,
			10.2350311,
			10.2832718,
			10.3260269,
			10.37381,
			10.4216232,
			10.4508133,
			10.4993591,
			10.5454025,
			10.5901718,
			10.6369171,
			10.6831894,
			10.714035,
			10.7430344,
			10.7913284,
			10.8366165,
			10.8843765,
			10.9302444,
			10.9749069,
			11.02272,
			11.0700073,
			11.1157913,
			11.159523,
			11.2093048,
			11.2395935,
			11.2686691,
			11.3159714,
			11.362999,
			11.4071045,
			11.4533157,
			11.50209,
			11.5458984,
			11.5926285,
			11.6384964,
			11.6836624,
			11.7327042,
			11.7627258,
			11.8093948,
			11.8566895,
			11.9029541,
			11.9496994,
			11.99556,
			12.0266724,
			12.0716629,
			12.1167068,
			12.1636505,
			12.2087479,
			12.2555618,
			12.288765,
			12.3340073,
			12.3807831,
			12.4245682,
			12.4708328,
			12.5175629,
			12.5502548,
			12.5804214,
			12.6266785,
			12.6588593,
			12.7047882,
			12.7504578,
			12.79689,
			12.8098068,
			12.8571091,
			12.90551,
			12.9486465,
			12.9969254,
			13.0421829,
			13.0698624,
			13.1015472,
			13.1484451,
			13.1930618,
			13.225769,
			13.2736816,
			13.3203583,
			13.3676605,
			13.41494,
			13.4425964,
			13.490921,
			13.5372543,
			13.5829926,
			13.6293716,
			13.675621,
			13.7224808,
			13.7696915,
			13.8144913,
			13.8471756,
			13.8934555,
			13.9388657,
			13.9860687,
			14.0177536,
			14.0626221,
			14.1078186,
			14.15564,
			14.1994019,
			14.2468033,
			14.2929764,
			14.3407974,
			14.3700485,
			14.4152756,
			14.4626465,
			14.5098572,
			14.556694,
			14.6029587,
			14.632782,
			14.6774368,
			14.7247009,
			14.7569427,
			14.8022842,
			14.8504944,
			14.8947906,
			14.9239655,
			14.9697571,
			15.0175705,
			15.0624084,
			15.0935211,
			15.1404648,
			15.1564331,
			15.2022324,
			15.2484894,
			15.2942657,
			15.3420715,
			15.3878555,
			15.4185486,
			15.4482346,
			15.4940033,
			15.5413055,
			15.5881119,
			15.6343994,
			15.663147,
			15.7264557,
			15.7576447,
			15.8039474,
			15.8512421,
			15.8961105,
			15.943306,
			15.9886093,
			16.0343857,
			16.0811768,
			16.1259537,
			16.156662,
			16.20295,
			16.2497025,
			16.27948,
			16.3261948,
			16.3731,
			16.4182816,
			16.4655762,
			16.5098343,
			16.5415649,
			16.587326,
			16.63414,
			16.6794586,
			16.7262039,
			16.7584,
			16.8023,
			16.85048,
			16.8968811,
			16.9415665,
			16.9893875,
			17.03666,
			17.0809326,
			17.1246948,
			17.1727371,
			17.2177811,
			17.2499771,
			17.29538,
			17.34256,
			17.3878632,
			17.4324417,
			17.4805069,
			17.5106125,
			17.5574341,
			17.60321,
			17.6489868,
			17.68119,
			17.7254868,
			17.75866,
			17.8180313,
			17.8497162,
			17.893013,
			17.941864,
			17.98706,
			18.033844,
			18.065033,
			18.10926,
			18.15757,
			18.20282,
			18.251091,
			18.293335,
			18.3416519,
			18.38739,
			18.4331055,
			18.4795761,
			18.5267334,
			18.55693,
			18.6036987,
			18.6479645,
			18.69268,
			18.74096,
			18.784729,
			18.8189316,
			18.85112,
			18.895462,
			18.9421234,
			18.9878922,
			19.0346527,
			19.0814056,
			19.127655,
			19.175499,
			19.2036133,
			19.2513733,
			19.29631,
			19.3439255,
			19.3911819,
			19.4354477,
			19.4822083,
			19.5264587,
			19.5739059,
			19.6039047,
			19.6516724,
			19.6975174,
			19.7422028,
			19.7899933,
			19.83628,
			19.8674164,
			19.9149017,
			19.95945,
			20.0077362,
			20.0520325,
			20.0987854,
			20.1300125,
			20.17524,
			20.22097,
			20.2687378,
			20.31501,
			20.3602448,
			20.389946,
			20.4357224,
			20.4844666,
			20.5136871,
			20.5609131,
			20.6077118,
			20.652977,
			20.7001419,
			20.7466125,
			20.7751617,
			20.8219681,
			20.8692551,
			20.9164734,
			20.9592285,
			21.0070343,
			21.0383148,
			21.0824738,
			21.1303787,
			21.1744843,
			21.2197571,
			21.266037,
			21.3147964,
			21.3600464,
			21.4038544,
			21.4360733,
			21.4818115,
			21.52755,
			21.5719,
			21.604599,
			21.6507568,
			21.6814423,
			21.7433014,
			21.7905731,
			21.8373489,
			21.8820953,
			21.9289856,
			21.9617538,
			22.0073166,
			22.03807,
			22.081749,
			22.129097,
			22.17427,
			22.22055,
			22.2661133,
			22.3141251,
			22.3608627,
			22.4052658,
			22.4503937,
			22.4831085,
			22.529,
			22.5590668,
			22.6064453,
			22.6375122,
			22.68325,
			22.7310867,
			22.7904282,
			22.8372269,
			22.8839951,
			22.9318314,
			22.9604721,
			23.0092926,
			23.0515442,
			23.1003571,
			23.1471558,
			23.1924286,
			23.2372665,
			23.2694473,
			23.3167038,
			23.3599777,
			23.4073257,
			23.45362,
			23.5008774,
			23.530632,
			23.5768585,
			23.620636,
			23.6679459,
			23.7152481,
			23.76001,
			23.79171,
			23.8374939,
			23.8853149,
			23.9316254,
			23.96077,
			24.0070877,
			24.0539169,
			24.0845337,
			24.1328278,
			24.1776581,
			24.2238922,
			24.2530975,
			24.3008652,
			24.3169556,
			24.36296,
			24.4070435,
			24.453331,
			24.485527,
			24.53183,
			24.5791779,
			24.6233749,
			24.6701736,
			24.7179565,
			24.7506485,
			24.7969666,
			24.8422318,
			24.888031,
			24.933815,
			24.9821167,
			25.0258636,
			25.0706482,
			25.1033859,
			25.1340485,
			25.17987,
			25.2084961,
			25.2563171,
			25.30056,
			25.34835,
			25.36419,
			25.4087372,
			25.4545517,
			25.50283,
			25.534523,
			25.5789413,
			25.62658,
			25.6730042,
			25.7166519,
			25.7650375,
			25.81025,
			25.8429413,
			25.8887558,
			25.9340363,
			25.9818268,
			26.014122,
			26.0597839,
			26.0894852,
			26.13578,
			26.1976547,
			26.2424316,
			26.2882614,
			26.332016,
			26.3798828,
			26.4089966,
			26.4407043,
			26.4880066,
			26.5326614,
			26.57914,
			26.626358,
			26.6565857,
			26.717926,
			26.7481232,
			26.7939,
			26.8417053,
			26.8870163,
			26.9312973,
			26.9786148,
			27.025322,
			27.0705872,
			27.1153412,
			27.1621857,
			27.1937485,
			27.2401276,
			27.28479,
			27.3316422,
			27.3767929,
			27.4079742,
			27.4567871,
			27.50203,
			27.5472565,
			27.59565,
			27.6413269,
			27.6860962,
			27.7177734,
			27.7631,
			27.8097687,
			27.8404846,
			27.885704,
			27.9324875,
			27.9797821,
			28.0265274,
			28.0723648,
			28.1195145,
			28.1501923,
			28.19696,
			28.2417145,
			28.2894974,
			28.3342667,
			28.3805237,
			28.4257736,
			28.4736862,
			28.50541,
			28.5500259,
			28.5978317,
			28.6301117,
			28.67424,
			28.70343,
			28.7511749,
			28.814064,
			28.8583221,
			28.88903,
			28.9352951,
			28.9830627,
			29.0294418,
			29.07357,
			29.1208649,
			29.1505127,
			29.1992722,
			29.243042,
			29.28984,
			29.336647,
			29.3829117,
			29.42987,
			29.4593582,
			29.50576,
			29.5509338,
			29.5978088,
			29.64653,
			29.69172,
			29.738678,
			29.7853165,
			29.8149719,
			29.8628616,
			29.90953,
			29.9548187,
			30.0005646,
			30.04692,
			30.0764847,
			30.1237488,
			30.1529236,
			30.2001877,
			30.24752,
			30.27761,
			30.32338,
			30.3374863,
			30.3756714,
			30.4234543,
			30.4687653,
			30.5135422,
			30.5598,
			30.58947,
			30.6363068,
			30.68254,
			30.7272949,
			30.7589722,
			30.8072662,
			30.85405,
			30.9002838,
			30.9467087,
			30.9918289,
			31.03762,
			31.0849,
			31.1145782,
			31.1598358,
			31.2096024,
			31.2533646,
			31.300148,
			31.3343735,
			31.3635178,
			31.4102859,
			31.44246,
			31.4882965,
			31.5335464,
			31.5656815,
			31.61,
			31.6710129,
			31.7196884,
			31.7629929,
			31.809166,
			31.85846,
			31.8881683,
			31.93396,
			31.98169,
			32.0249176,
			32.057106,
			32.10241,
			32.1334076,
			32.19391,
			32.2417145,
			32.28752,
			32.3342819,
			32.3796158,
			32.4087372,
			32.458046,
			32.5033264,
			32.5515976,
			32.5984955,
			32.62755,
			32.6734619,
			32.7212753,
			32.7674179,
			32.8152161,
			32.8589935,
			32.90779,
			32.93654,
			32.98326,
			33.0324554,
			33.0753174,
			33.1241,
			33.16839,
			33.1993332,
			33.38771,
			33.43299,
			33.46325,
			33.509407,
			33.5411453,
			33.5889359,
			33.6346664,
			33.68052,
			33.7257,
			33.77307,
			33.8192825,
			33.8489532,
			33.8959045,
			33.9414978,
			33.98828,
			34.03508,
			34.0819,
			34.1260529,
			34.1734238,
			34.2201462,
			34.24929,
			34.29625,
			34.3418045,
			34.3886261,
			34.43541,
			34.4831772,
			34.514473,
			34.543045,
			34.5893631,
			34.63514,
			34.6839828,
			34.7288742,
			34.775528,
			34.8223572,
			34.8686,
			34.91436,
			34.9440079,
			34.9917755,
			35.0391769,
			35.0838547,
			35.130188,
			35.1758881,
			35.2222061,
			35.2679443,
			35.2986526,
			35.345932,
			35.3906555,
			35.43592,
			35.4836273,
			35.53003,
			35.5596,
			35.6060638,
			35.6370468,
			35.68331,
			35.72973,
			35.759758,
			35.8085938,
			35.868927,
			35.9147034,
			35.9599838,
			36.0073166,
			36.0541229,
			36.08423,
			36.12918,
			36.17626,
			36.2046127,
			36.2507,
			36.2965469,
			36.3422241,
			36.3890457,
			36.43534,
			36.481575,
			36.5259171,
			36.5585175,
			36.60228,
			36.65052,
			36.6812134,
			36.7286,
			36.77188,
			36.8190536,
			36.8661346,
			36.90953,
			36.9573135,
			37.0010529,
			37.0472946,
			37.0930328,
			37.124733,
			37.15692,
			37.2026367,
			37.2488937,
			37.28157,
			37.3277969,
			37.37162,
			37.38764,
			37.43493,
			37.48066,
			37.5278931,
			37.57376,
			37.60495,
			37.65107,
			37.69886,
			37.7312,
			37.7762451,
			37.82103,
			37.8531952,
			37.89952,
			37.9618759,
			37.9934235,
			38.040184,
			38.08442,
			38.1292953,
			38.1759033,
			38.22371,
			38.2533646,
			38.3147049,
			38.36091,
			38.4091873,
			38.4389725,
			38.4861374,
			38.5330353,
			38.5787735,
			38.62391,
			38.66922,
			38.70189,
			38.7335129,
			38.7777557,
			38.80992,
			38.8567352,
			38.90149,
			38.9487076,
			38.9629,
			39.0085144,
			39.0568237,
			39.1010437,
			39.1317139,
			39.1795578,
			39.2237,
			39.2608643,
			39.3061676,
			39.35189,
			39.3967361,
			39.4433365,
			39.47406,
			39.4896,
			39.5368576,
			39.5820923,
			39.6263046,
			39.674614,
			39.7193,
			39.7509766,
			39.79772,
			39.8439331,
			39.8906937,
			39.9364624,
			39.9811935,
			40.0143967,
			40.0586243,
			40.0907974,
			40.13701,
			40.18129,
			40.21444,
			40.25966,
			40.3225174,
			40.35369,
			40.39907,
			40.44523,
			40.4924,
			40.5371323,
			40.58441,
			40.6312637,
			40.6758957,
			40.720665,
			40.7694778,
			40.79908,
			40.8432846,
			40.8910828,
			40.9395,
			40.98455,
			41.0320129,
			41.0599442,
			41.0906143,
			41.1384964,
			41.1846237,
			41.2305,
			41.27659,
			41.3238678,
			41.3545151,
			41.4008026,
			41.434,
			41.47986,
			41.52648,
			41.57235,
			41.61798,
			41.6639023,
			41.70854,
			41.74077,
			41.78804,
			41.8332672,
			41.87961,
			41.9097,
			41.95497,
			41.98413,
			42.0319138,
			42.0766068,
			42.09221,
			42.14012,
			42.1853256,
			42.2331,
			42.27636,
			42.32418,
			42.355835,
			42.3860321,
			42.42993,
			42.4599457,
			42.5067062,
			42.5519943,
			42.597847,
			42.6153336,
			42.6444855,
			42.69075,
			42.7365875,
			42.783844,
			42.830246,
			42.8607178,
			42.9216232,
			42.9665375,
			43.01332,
			43.0595245,
			43.1073227,
			43.136,
			43.18374,
			43.2296,
			43.26232,
			43.30623,
			43.3542557,
			43.3996124,
			43.4442368,
			43.49202,
			43.53781,
			43.58663,
			43.63275,
			43.66462,
			43.709816,
			43.75564,
			43.80037,
			43.84722,
			43.8919067,
			43.9236069,
			43.97049,
			44.0151672,
			44.06302,
			44.1067352,
			44.15148,
			44.1842422,
			44.22962,
			44.2752838,
			44.32103,
			44.3522339,
			44.4010162,
			44.4464264,
			44.4925842,
			44.53856,
			44.584198,
			44.6308975,
			44.6757431,
			44.70701,
			44.7552567,
			44.78647,
			44.8317337,
			44.8764877,
			44.9253,
			44.96962,
			45.01687,
			45.06273,
			45.10794,
			45.15374,
			45.20153,
			45.2312241,
			45.2785568,
			45.3243256,
			45.3717422,
			45.4163742,
			45.4622726,
			45.4923172,
			45.5392456,
			45.5839,
			45.63321,
			45.6765137,
			45.7247772,
			45.7565,
			45.8007736,
			45.83248,
			45.8807526,
			45.9245148,
			45.972435,
			46.0191269,
			46.0659866,
			46.11068,
			46.1403732,
			46.1872177,
			46.23346,
			46.2802658,
			46.3245239,
			46.3707733,
			46.41855,
			46.4628754,
			46.50905,
			46.5402374,
			46.5865326,
			46.6343079,
			46.68058,
			46.7248077,
			46.75701,
			46.8027954,
			46.83293,
			46.88031,
			46.92546,
			46.9566574,
			47.0014343,
			47.0497131,
			47.06385,
			47.1112061,
			47.1573639,
			47.2026062,
			47.2338028,
			47.2790527,
			47.3257675,
			47.37005,
			47.4178162,
			47.44851,
			47.49543,
			47.5410538,
			47.5893631,
			47.6330872,
			47.6814041,
			47.7261734,
			47.7726059,
			47.8176727,
			47.84887,
			47.89312,
			47.9263077,
			47.973732,
			48.004303,
			48.05053,
			48.08319,
			48.1136,
			48.15963,
			48.2069473,
			48.23561,
			48.2833481,
			48.315033,
			48.358757,
			48.40757,
			48.4533234,
			48.49922,
			48.54637,
			48.5916138,
			48.6390839,
			48.6847,
			48.7295227,
			48.7742462,
			48.8199844,
			48.86776,
			48.8994,
			48.9467163,
			48.97589,
			49.0226746,
			49.0679169,
			49.1146622,
			49.1610641,
			49.2057724,
			49.2514267,
			49.2987,
			49.3450165,
			49.39073,
			49.4219055,
			49.45308,
			49.49891,
			49.546196,
			49.5918655,
			49.5975571
		]
	],
	'6586_7976_7982': [
		[
			63.36714
		],
		[
			0
		],
		[
			0,
			1.03613281,
			3.629228,
			6.248412,
			8.86749649,
			11.4911118,
			14.1162548,
			15.427187,
			18.05427,
			20.6842957,
			23.32137,
			24.6430359,
			27.291647,
			29.9482517,
			32.6125336,
			33.94732,
			36.62206,
			39.3034363,
			41.9911537,
			43.337616,
			46.034584,
			47.385704,
			50.0859,
			52.7891541,
			55.4991531,
			58.215847,
			60.9504471,
			63.6928864,
			65.067894,
			67.82303,
			69.20302,
			71.9679947,
			74.7401,
			76.12894,
			78.91178,
			81.70256,
			84.5007,
			87.3071,
			90.12111,
			91.5312,
			94.35525,
			97.1812744,
			100.013206,
			101.431091,
			104.271523,
			107.117805,
			109.970848,
			111.400032,
			114.2633,
			117.132744,
			120.008629,
			121.449173,
			124.3349,
			127.227036,
			128.675461,
			131.578522,
			134.489258,
			137.41539,
			140.350281,
			141.82196,
			144.768387,
			147.713318,
			149.1881,
			152.1368,
			155.090973,
			156.569855,
			159.532135,
			162.498749,
			165.470947,
			166.959167,
			169.939926,
			172.9268,
			174.42244,
			177.418243,
			180.419952,
			183.427658,
			186.44101,
			187.949829,
			190.972046,
			194.000473,
			197.033875,
			198.552536,
			201.594391,
			204.641968,
			206.16745,
			209.2224,
			212.281082,
			215.3446,
			218.41333,
			219.9497,
			223.027435,
			226.111481,
			227.656448,
			230.750351,
			233.849518,
			235.4011,
			238.508163,
			241.620453,
			244.737885,
			247.8606,
			249.423828,
			252.553864,
			255.688812,
			258.828217,
			260.399719,
			263.546326,
			265.1215,
			268.276123,
			271.43512,
			274.597473,
			277.76416,
			280.935455,
			284.1129,
			285.7034,
			288.885925,
			290.477142,
			293.6635,
			296.853943,
			298.450378,
			301.646667,
			304.847626,
			308.0526,
			311.261719,
			314.474945,
			316.0831,
			319.302277,
			322.525726,
			324.139,
			327.3686,
			328.984863,
			332.220459,
			335.460236,
			337.0817,
			340.327942,
			343.578,
			346.832123,
			350.090881,
			353.353333,
			354.9859,
			358.253845,
			361.525482,
			364.8017,
			368.081451,
			369.7227,
			373.008453,
			376.298431,
			377.944519,
			381.2396,
			384.5396,
			387.84317,
			389.496124,
			392.805054,
			394.4611,
			397.776245,
			401.095581,
			404.418945,
			407.7462,
			411.077057,
			414.410522,
			416.078583,
			419.414429,
			422.753448,
			424.42453,
			427.7708,
			429.446716,
			432.801941,
			434.481567,
			437.843628,
			439.52597,
			442.8952,
			446.271332,
			447.961823,
			451.346375,
			454.729736,
			456.422424,
			459.808716,
			463.1987,
			466.5928,
			469.99054,
			473.392151,
			475.094452,
			478.502441,
			481.914825,
			483.62204,
			487.039429,
			490.46167,
			492.174225,
			495.6021,
			499.033569,
			502.46875,
			505.908051,
			509.351135,
			511.0741,
			514.522461,
			517.9745,
			519.702,
			523.160034,
			524.8904,
			528.3539,
			531.8209,
			535.2904,
			538.763062,
			542.2392,
			543.979248,
			547.462341,
			550.9483,
			552.6923,
			556.1816,
			559.663269,
			563.1266,
			564.848145,
			568.266052,
			571.6463,
			574.9881,
			578.2912,
			579.928345,
			583.1734,
			586.3793,
			587.967834,
			591.116,
			592.6758,
			595.767,
			598.8218,
			601.8418,
			604.826233,
			607.774231,
			610.686951,
			612.129,
			614.983643,
			617.799561,
			619.1918,
			621.9476,
			624.6658,
			627.346252,
			628.672241,
			631.295837,
			633.882141,
			636.4311,
			638.9431,
			640.185242,
			642.641846,
			645.0621,
			646.259338,
			648.6284,
			650.9638,
			653.2608,
			654.393,
			656.6301,
			658.8301,
			660.9933,
			663.1208,
			664.1724,
			666.2483,
			667.2726,
			669.294556,
			671.2801,
			673.2294,
			675.1428,
			676.0862,
			677.9469,
			678.8655,
			680.678833,
			682.4608,
			684.2129,
			685.9304,
			686.776062,
			688.4567,
			690.090332,
			691.6924,
			693.266663,
			694.0431,
			695.577637,
			697.0913,
			697.839844,
			699.302063,
			700.7487,
			701.4676,
			702.895935,
			704.2912,
			705.6821,
			707.0723,
			708.4657,
			709.1634,
			710.5588,
			711.9554,
			712.6546,
			714.0829,
			715.5156,
			716.2329,
			717.670349,
			719.1404,
			720.6172,
			722.0991,
			723.601,
			724.36084,
			725.1218,
			726.6472,
			728.180359,
			729.7259,
			730.500061,
			732.0518,
			733.628662,
			735.230957,
			736.8382,
			738.4641,
			740.1191,
			740.9491,
			747.6682,
			749.3672,
			751.072,
			752.784058,
			754.5118,
			756.245239,
			757.114136,
			758.863647,
			760.6233,
			761.5041,
			763.2712,
			765.057739,
			766.845642,
			767.7402,
			769.569,
			771.4039,
			773.2419,
			775.117,
			776.053,
			777.922546,
			778.855469,
			780.716431,
			782.5723,
			783.493042,
			785.3146,
			787.1279,
			788.9177,
			790.6786,
			792.432739,
			794.1554,
			795.004761,
			796.699768,
			797.546265,
			799.202454,
			800.83374,
			802.4618,
			804.061035,
			804.8487,
			806.4227,
			807.9966,
			808.777832,
			810.3353,
			811.8927,
			813.451538,
			814.2305,
			815.7884,
			816.567749,
			818.1289,
			819.698,
			821.269531,
			822.845154,
			824.4386,
			825.238647,
			826.8426,
			828.4556,
			829.275146,
			830.917969,
			831.74176,
			833.3964,
			835.0737,
			836.758057,
			838.4508,
			840.1421,
			841.8422,
			842.6962,
			844.4013,
			846.1084,
			846.9657,
			848.6862,
			850.405,
			852.1343,
			853.8747,
			855.6218,
			856.498352,
			858.2587,
			860.0322,
			860.9219,
			862.707764,
			863.6049,
			865.422241,
			867.245361,
			869.08,
			870.932,
			872.7881,
			874.6514,
			875.5851,
			877.456238,
			878.395264,
			880.2734,
			882.1544,
			884.044067,
			884.995239,
			886.901062,
			888.8147,
			890.749146,
			891.7189,
			893.6637,
			895.618042,
			896.5823,
			898.5515,
			900.5456,
			901.538635,
			903.5335,
			905.5359,
			907.5491,
			909.573853,
			911.609741,
			913.6567,
			914.6849,
			916.749268,
			917.7855,
			919.866455,
			921.953064,
			924.0377,
			926.127869,
			927.1763,
			929.2809,
			931.3947,
			933.51825,
			934.5841,
			936.7233,
			938.872131,
			939.951965,
			942.1185,
			944.2961,
			946.4882,
			948.689941,
			949.799438,
			950.912231,
			953.145,
			955.3904,
			957.6466,
			959.909363,
			962.177368,
			963.3149,
			965.5947,
			967.882935,
			970.1791,
			971.329956,
			973.6388,
			975.9558,
			977.117554,
			979.448059,
			981.7877,
			984.1364,
			985.313965,
			987.675659,
			990.045959,
			992.4243,
			993.6169,
			995.9889,
			998.3895,
			999.5936,
			1002.00916,
			1004.43341,
			1006.86694,
			1009.309,
			1010.53296,
			1012.9873,
			1015.44922,
			1017.9187,
			1019.15656,
			1021.63867,
			1022.88287,
			1025.3783,
			1027.88257,
			1030.39514,
			1032.91589,
			1035.44348,
			1036.70947,
			1039.24768,
			1040.519,
			1043.068,
			1045.62524,
			1048.1908,
			1049.47644,
			1052.05261,
			1054.63635,
			1057.2251,
			1059.817,
			1061.11292,
			1063.70032,
			1066.27307,
			1067.5509,
			1070.08484,
			1072.58533,
			1075.04785,
			1076.26489,
			1077.47229,
			1079.85864,
			1082.20752,
			1084.51855,
			1085.66016,
			1087.915,
			1090.13232,
			1092.312,
			1094.45435,
			1095.51147,
			1097.59766,
			1098.62671,
			1099.64661,
			1101.65869,
			1102.65088,
			1104.6073,
			1106.5271,
			1108.40991,
			1110.25708,
			1111.16809,
			1112.9646,
			1114.727,
			1116.45447,
			1118.14465,
			1118.976,
			1120.61121,
			1122.21094,
			1123.78125,
			1124.55774,
			1126.09937,
			1127.6272,
			1128.40076,
			1129.92041,
			1131.44031,
			1132.958,
			1134.47607,
			1135.234,
			1136.74585,
			1138.25659,
			1139.76843,
			1140.51563,
			1142.00525,
			1143.49353,
			1144.238,
			1145.70374,
			1147.15125,
			1148.59937,
			1150.04944,
			1150.77332,
			1152.22,
			1153.666,
			1154.3894,
			1155.89612,
			1157.439,
			1158.21045,
			1159.75439,
			1161.32727,
			1162.90588,
			1164.48694,
			1166.09814,
			1166.91089,
			1168.53711,
			1170.17383,
			1171.038,
			1172.76538,
			1174.4928,
			1175.37244,
			1177.18164,
			1178.9895,
			1180.83618,
			1182.73364,
			1183.68152,
			1185.59265,
			1186.56665,
			1188.51208,
			1190.44,
			1192.3042,
			1194.1676,
			1195.09766,
			1196.85083,
			1198.60437,
			1200.34448,
			1202.00684,
			1202.83862,
			1204.50488,
			1205.32788,
			1206.91431,
			1208.50354,
			1210.09656,
			1211.68335,
			1212.477,
			1214.06592,
			1214.86169,
			1216.47144,
			1218.08533,
			1219.70178,
			1221.33,
			1222.14587,
			1223.77954,
			1224.598,
			1226.2384,
			1227.88208,
			1229.52991,
			1231.17456,
			1232.80493,
			1233.62817,
			1235.27527,
			1236.91736,
			1238.56128,
			1239.38391,
			1241.04846,
			1242.70508,
			1244.36243,
			1246.02515,
			1246.85889,
			1248.526,
			1250.19348,
			1251.86353,
			1252.698,
			1254.36646,
			1255.20056,
			1256.86523,
			1258.52808,
			1260.19141,
			1261.8457,
			1263.49939,
			1264.32678,
			1265.979,
			1267.62549,
			1268.44946,
			1270.10034,
			1270.9292,
			1272.59,
			1274.25366,
			1275.087,
			1276.77893,
			1278.47815,
			1279.3291,
			1281.04529,
			1281.91382,
			1283.65381,
			1285.39868,
			1287.20056,
			1288.10278,
			1289.90991,
			1290.84314,
			1292.78687,
			1294.73218,
			1296.77808,
			1298.88525,
			1300.96729,
			1301.9679,
			1303.96826,
			1304.96814,
			1306.89478,
			1307.84375,
			1309.74182,
			1311.65063,
			1313.56738,
			1315.48572,
			1317.33057,
			1319.17432,
			1320.09851,
			1321.85864,
			1323.58667,
			1324.45447,
			1326.18652,
			1327.89868,
			1329.62183,
			1330.48792,
			1332.26538,
			1334.06189,
			1335.87256,
			1336.80249,
			1338.67163,
			1340.55334,
			1341.50171,
			1343.40942,
			1345.32947,
			1347.25916,
			1349.19946,
			1350.17419,
			1351.1521,
			1353.117,
			1355.09338,
			1357.08057,
			1359.07837,
			1361.0708,
			1362.08069,
			1364.10876,
			1366.148,
			1368.19861,
			1370.26025,
			1371.29529,
			1373.37476,
			1375.46033,
			1377.54553,
			1378.59009,
			1380.686,
			1382.79187,
			1383.84827,
			1385.969,
			1388.09619,
			1390.23315,
			1392.38232,
			1393.46155,
			1395.62756,
			1397.80713,
			1399.99841,
			1401.09766,
			1403.3053,
			1404.41284,
			1406.63684,
			1408.87744,
			1411.12793,
			1413.38879,
			1415.65955,
			1416.797,
			1419.072,
			1421.34509,
			1422.47656,
			1424.72351,
			1426.94019,
			1428.03516,
			1430.19727,
			1432.32227,
			1434.41028,
			1436.46118,
			1438.47412,
			1439.46667,
			1441.42444,
			1443.34583,
			1444.29272,
			1446.15942,
			1447.9917,
			1448.89526,
			1450.677,
			1452.42432,
			1453.28442,
			1454.97681,
			1456.63257,
			1458.25183,
			1459.83447,
			1460.61218,
			1462.14038,
			1463.6322,
			1465.08813,
			1465.80237,
			1467.20447,
			1468.57275,
			1469.90808,
			1471.215,
			1472.5022,
			1473.13892,
			1474.40063,
			1475.02771,
			1476.27759,
			1477.52393,
			1478.77246,
			1479.38843,
			1480.61548,
			1481.83862,
			1483.05835,
			1484.272,
			1485.483,
			1486.08838,
			1487.28394,
			1487.87354,
			1489.05066,
			1489.63867,
			1490.81519,
			1491.97949,
			1493.10742,
			1494.23547,
			1495.3656,
			1496.50012,
			1497.63867,
			1498.20789,
			1499.34692,
			1499.91687,
			1501.05786,
			1501.62915,
			1502.84473,
			1504.06055,
			1505.27417,
			1506.48682,
			1507.15112,
			1508.53662,
			1509.91614,
			1511.29077,
			1512.79871,
			1513.58057,
			1515.14,
			1516.69653,
			1518.28711,
			1519.08044,
			1520.66406,
			1522.22754,
			1523.74707,
			1525.26245,
			1526.77466,
			1527.50476,
			1528.961,
			1529.6875,
			1531.13989,
			1532.55884,
			1533.94434,
			1534.6333,
			1536.00708,
			1536.69519,
			1537.99011,
			1539.27661,
			1540.56177,
			1541.84363,
			1543.02734,
			1543.61914,
			1544.80273,
			1545.98914,
			1547.17188,
			1547.75525,
			1548.92493,
			1550.09814,
			1551.27393,
			1551.8623,
			1553.04321,
			1553.63159,
			1554.80212,
			1555.95264,
			1557.13,
			1558.3689,
			1559.5752,
			1560.16626,
			1561.32629,
			1562.6333,
			1563.36353,
			1564.80347,
			1566.20862,
			1566.90332,
			1568.69971,
			1570.47241,
			1572.234,
			1574.02783,
			1575.80884,
			1576.69666,
			1578.18115,
			1578.89722,
			1580.324,
			1581.74963,
			1582.75781,
			1583.22571,
			1584.16162,
			1585.09668,
			1586.03125,
			1586.96851,
			1587.37769,
			1588.187,
			1589.00781,
			1589.42139,
			1590.25659,
			1591.10388,
			1591.96338,
			1592.48267,
			1593.5459,
			1594.62354,
			1595.71655,
			1596.27808,
			1597.53259,
			1599.05493,
			1599.82336,
			1601.37183,
			1603.09448,
			1604.95728,
			1606.8457,
			1607.66833,
			1609.26855,
			1610.884,
			1612.42444,
			1613.08472,
			1614.415,
			1615.75879,
			1616.4353,
			1617.76917,
			1619.0918,
			1620.426,
			1621.77319,
			1622.45435,
			1623.14026,
			1624.51978,
			1625.21375,
			1626.61047,
			1628.01929,
			1629.437,
			1630.86414,
			1632.302,
			1633.75317,
			1635.21021,
			1636.6731,
			1637.41028,
			1638.15381,
			1639.64307,
			1641.13574,
			1642.6438,
			1644.17212,
			1644.936,
			1646.465,
			1648.01147,
			1649.5697,
			1651.12793,
			1652.6853,
			1653.46277,
			1655.016,
			1655.79175,
			1657.33447,
			1658.82227,
			1659.56567,
			1661.03943,
			1662.51868,
			1663.98755,
			1665.45459,
			1666.93372,
			1668.43225,
			1669.18726,
			1669.946,
			1671.47375,
			1672.24207,
			1673.80078,
			1674.58057,
			1676.13892,
			1676.917,
			1678.51587,
			1680.1167,
			1681.70618,
			1683.33191,
			1684.15015,
			1685.77185,
			1686.5752,
			1688.16736,
			1689.73438,
			1691.27344,
			1692.79614,
			1693.55713,
			1695.05566,
			1696.52539,
			1698.01,
			1699.51221,
			1700.254,
			1701.72168,
			1702.45227,
			1703.92554,
			1705.38391,
			1706.10913,
			1707.54688,
			1708.945,
			1710.34009,
			1711.73535,
			1713.10486,
			1714.45447,
			1715.12964,
			1716.48157,
			1717.15918,
			1718.547,
			1719.94067,
			1720.63782,
			1722.034,
			1723.47168,
			1724.92053,
			1726.369,
			1727.80688,
			1728.51245,
			1729.92041,
			1731.32666,
			1732.02966,
			1733.45166,
			1734.87537,
			1735.58667,
			1737.00916,
			1738.476,
			1739.21606,
			1740.69409,
			1741.43262,
			1742.913,
			1744.39209,
			1745.13025,
			1745.86792,
			1747.34546,
			1748.82288,
			1750.29785,
			1751.03564,
			1752.51367,
			1753.25049,
			1754.72217,
			1756.19421,
			1757.67065,
			1759.14844,
			1760.62744,
			1761.36816,
			1762.85779,
			1764.36011,
			1765.11157,
			1766.61621,
			1768.10779,
			1768.84082,
			1770.30469,
			1771.03638,
			1772.50024,
			1773.89331,
			1774.58716,
			1775.97266,
			1776.66467,
			1778.03076,
			1779.36157,
			1780.02588,
			1781.35291,
			1782.67554,
			1783.31128,
			1784.58142,
			1785.85083,
			1787.12146,
			1787.75232,
			1788.98755,
			1790.224,
			1791.4635,
			1792.08484,
			1793.34155,
			1794.60864,
			1795.87988,
			1796.5177,
			1797.80322,
			1799.12048,
			1800.44373,
			1801.77478,
			1803.12927,
			1803.82092,
			1805.21021,
			1805.90833,
			1807.31287,
			1808.7345,
			1809.44958,
			1810.888,
			1812.339,
			1813.80444,
			1815.28223,
			1816.77356,
			1817.52478,
			1819.03247,
			1820.55347,
			1821.31934,
			1822.86035,
			1824.39331,
			1825.95361,
			1826.73938,
			1828.32178,
			1829.91907,
			1831.53162,
			1833.17358,
			1833.99438,
			1835.64624,
			1837.31152,
			1838.1488,
			1839.82788,
			1841.5127,
			1843.21326,
			1844.071,
			1845.791,
			1847.517,
			1849.24841,
			1850.9812,
			1851.848,
			1853.582,
			1854.44873,
			1856.18018,
			1857.91089,
			1858.78137,
			1860.519,
			1862.25269,
			1864.00879,
			1865.7699,
			1867.52441,
			1869.33374,
			1870.24072,
			1872.0481,
			1872.9541,
			1874.81567,
			1876.66809,
			1878.49487,
			1880.28516,
			1881.17749,
			1882.9585,
			1884.73975,
			1886.51782,
			1887.407,
			1889.21924,
			1891.04431,
			1891.95789,
			1893.762,
			1895.54053,
			1897.32275,
			1899.10583,
			1899.99829,
			1901.78687,
			1903.59277,
			1904.5061,
			1906.33948,
			1908.183,
			1909.10938,
			1910.96973,
			1912.84106,
			1914.72314,
			1916.616,
			1918.522,
			1919.47852,
			1921.40039,
			1923.33777,
			1924.3186,
			1926.2876,
			1928.27344,
			1929.28638,
			1931.31824,
			1933.35083,
			1935.35364,
			1937.3634,
			1938.35828,
			1940.301,
			1942.24866,
			1943.22339,
			1945.1626,
			1947.105,
			1949.04919,
			1950.02124,
			1951.96606,
			1953.89807,
			1955.80835,
			1957.71606,
			1958.65417,
			1960.50317,
			1962.34827,
			1963.26868,
			1965.08447,
			1966.90039,
			1968.7229,
			1969.64026,
			1971.478,
			1973.32324,
			1975.18457,
			1977.05444,
			1977.99292,
			1979.88281,
			1980.83179,
			1982.73889,
			1984.66113,
			1986.596,
			1987.56763,
			1989.52185,
			1991.4884,
			1993.46606,
			1995.459,
			1997.46265,
			1998.46912,
			2000.501,
			2002.52612,
			2003.552,
			2005.62061,
			2006.65869,
			2008.743,
			2010.83765,
			2012.93225,
			2015.02759,
			2016.07788,
			2018.18628,
			2020.30469,
			2022.43225,
			2023.5,
			2025.64136,
			2027.79248,
			2028.8717,
			2031.03711,
			2033.21216,
			2035.39648,
			2037.59082,
			2039.79492,
			2040.90063,
			2043.11975,
			2045.3479,
			2046.46533,
			2048.708,
			2050.96045,
			2053.22266,
			2055.49463,
			2056.63452,
			2058.92163,
			2061.21826,
			2063.52515,
			2064.68237,
			2067.004,
			2069.33521,
			2070.505,
			2072.85181,
			2075.20752,
			2077.57275,
			2079.94727,
			2081.13818,
			2083.52637,
			2085.92529,
			2088.3335,
			2089.54126,
			2091.96387,
			2093.17822,
			2095.62036,
			2098.07373,
			2100.541,
			2103.02,
			2105.50317,
			2106.74634,
			2109.239,
			2110.48535,
			2112.98486,
			2114.23779,
			2116.75,
			2118.00928,
			2119.271,
			2121.80078,
			2124.33936,
			2126.88745,
			2129.44336,
			2132.00537,
			2133.28857,
			2134.57227,
			2137.1394,
			2139.69873,
			2142.24072,
			2143.502,
			2146.002,
			2147.2395,
			2149.68848,
			2152.10229,
			2154.48071,
			2156.82227,
			2157.97925,
			2160.266,
			2162.51587,
			2163.62671,
			2165.821,
			2167.97852,
			2169.04346,
			2171.14624,
			2173.2124,
			2175.242,
			2177.235,
			2179.192,
			2180.15674,
			2182.05981,
			2183.92773,
			2184.84937,
			2186.66919,
			2188.45776,
			2189.33984,
			2191.07275,
			2191.92578,
			2193.606,
			2195.247,
			2196.05225,
			2197.63916,
			2199.97217,
			2201.51025,
			2202.28662,
			2203.80664,
			2205.32568,
			2206.087,
			2207.6062,
			2209.125,
			2210.64,
			2211.39575,
			2212.90771,
			2214.42114,
			2215.91284,
			2216.656,
			2218.142,
			2219.629,
			2221.09448,
			2221.82617,
			2223.28857,
			2224.75146,
			2226.22314,
			2227.69238,
			2229.1604,
			2229.9,
			2231.4187,
			2232.17725,
			2233.69238,
			2235.23022,
			2236.81934,
			2237.61328,
			2239.20044,
			2240.8208,
			2242.455,
			2244.08643,
			2244.90283,
			2246.542,
			2248.17871,
			2248.99658,
			2250.63037,
			2252.26025,
			2253.8894,
			2254.70459,
			2256.35449,
			2258.00537,
			2259.65869,
			2260.49219,
			2262.1626,
			2262.999,
			2264.6748,
			2266.36768,
			2268.065,
			2268.91577,
			2270.62354,
			2271.47925,
			2272.33545,
			2274.051,
			2274.911,
			2276.646,
			2278.38159,
			2280.12158,
			2280.99756,
			2282.747,
			2284.496,
			2286.22144,
			2287.934,
			2289.64551,
			2290.49829,
			2292.19458,
			2293.89038,
			2294.739,
			2296.45435,
			2298.174,
			2299.03442,
			2300.76074,
			2302.49219,
			2304.224,
			2305.955,
			2307.68213,
			2308.546,
			2310.27417,
			2311.133,
			2312.85,
			2314.56836,
			2315.42676,
			2317.12915,
			2318.83374,
			2320.5376,
			2322.22119,
			2323.06445,
			2324.75464,
			2326.4248,
			2328.08716,
			2328.92,
			2330.58081,
			2332.20435,
			2333.01733,
			2334.64648,
			2336.25317,
			2337.84277,
			2338.63965,
			2340.23877,
			2341.84863,
			2343.46582,
			2344.27734,
			2345.9248,
			2347.61816,
			2349.32178,
			2351.04883,
			2351.92627,
			2353.689,
			2355.46484,
			2357.27417,
			2359.09521,
			2360.01074,
			2361.8606,
			2363.72583,
			2364.663,
			2366.5498,
			2368.45215,
			2370.3667,
			2371.32837,
			2373.26025,
			2375.20459,
			2377.15967,
			2379.10986,
			2380.098,
			2382.07959,
			2383.07446,
			2385.07227,
			2387.08081,
			2389.09961,
			2391.12964,
			2392.14941,
			2394.19751,
			2396.256,
			2397.289,
			2399.36279,
			2401.44727,
			2402.49341,
			2404.58838,
			2405.63525,
			2407.72949,
			2409.832,
			2411.945,
			2413.00513,
			2414.06763,
			2416.20044,
			2418.34424,
			2420.49829,
			2421.57959,
			2423.74951,
			2424.83838,
			2427.02368,
			2429.219,
			2431.42456,
			2433.64014,
			2435.86572,
			2436.98218,
			2439.223,
			2440.347,
			2442.60254,
			2444.86768,
			2447.14282,
			2448.28418,
			2450.57446,
			2452.87451,
			2454.028,
			2456.34277,
			2457.50366,
			2459.83276,
			2462.17139,
			2464.51978,
			2466.8772,
			2469.24463,
			2471.62134,
			2472.81323,
			2475.204,
			2477.60376,
			2478.80713,
			2481.22021,
			2483.64331,
			2484.85815,
			2487.29443,
			2489.739,
			2492.193,
			2494.65771,
			2495.89331,
			2498.37,
			2499.61157,
			2502.10083,
			2504.59863,
			2507.10522,
			2509.62427,
			2510.887,
			2513.41479,
			2515.94824,
			2518.48926,
			2521.03882,
			2523.59619,
			2524.878,
			2527.42285,
			2528.69482,
			2531.2522,
			2532.39185
		],
		[
			0,
			0.0287780762,
			0.07514191,
			0.121871948,
			0.167991638,
			0.214378357,
			0.260650635,
			0.3064499,
			0.351295471,
			0.4000702,
			0.4441986,
			0.475921631,
			0.5226364,
			0.567024231,
			0.614707947,
			0.644844055,
			0.691635132,
			0.736228943,
			0.783912659,
			0.83039093,
			0.8752365,
			0.9053726,
			0.9526596,
			0.996841431,
			1.044136,
			1.09143829,
			1.13668823,
			1.18399811,
			1.22868347,
			1.26039886,
			1.30566406,
			1.336586,
			1.38418579,
			1.42988586,
			1.47719574,
			1.52502441,
			1.56999969,
			1.61405182,
			1.66336823,
			1.70667267,
			1.75497437,
			1.78668976,
			1.8325882,
			1.87922668,
			1.924057,
			1.97136688,
			2.017685,
			2.04730225,
			2.09312439,
			2.13941956,
			2.186226,
			2.22993469,
			2.27679443,
			2.307495,
			2.35428619,
			2.39966583,
			2.446846,
			2.49216461,
			2.53612518,
			2.568245,
			2.61336517,
			2.66163635,
			2.704918,
			2.74459839,
			2.79188538,
			2.8237,
			2.867424,
			2.91457367,
			2.96033478,
			3.00662231,
			3.05290985,
			3.08507538,
			3.1308136,
			3.17462921,
			3.221878,
			3.26805115,
			3.31242371,
			3.34514618,
			3.39148712,
			3.43672943,
			3.48457336,
			3.52979279,
			3.57604218,
			3.60733032,
			3.65254974,
			3.69680786,
			3.74311066,
			3.78839874,
			3.83654785,
			3.86870575,
			3.91358185,
			3.95841217,
			4.005211,
			4.05104065,
			4.09672546,
			4.129936,
			4.174263,
			4.22303772,
			4.26808167,
			4.31407166,
			4.34375763,
			4.39008331,
			4.437378,
			4.4857254,
			4.53153229,
			4.561722,
			4.60553,
			4.65087128,
			4.700096,
			4.74584961,
			4.79211426,
			4.83739471,
			4.88484955,
			4.91381836,
			4.96159363,
			4.98982239,
			5.038101,
			5.085373,
			5.12908936,
			5.16078949,
			5.221695,
			5.267601,
			5.313736,
			5.360504,
			5.4058,
			5.4375,
			5.48290253,
			5.529045,
			5.561737,
			5.606018,
			5.652817,
			5.69654846,
			5.730278,
			5.77600861,
			5.819763,
			5.86807251,
			5.91332245,
			5.96062469,
			6.005966,
			6.052246,
			6.09960175,
			6.14620972,
			6.192482,
			6.22068,
			6.26818848,
			6.312622,
			6.34380341,
			6.390587,
			6.43689728,
			6.48423,
			6.529381,
			6.574173,
			6.60368347,
			6.65209961,
			6.69950867,
			6.74612427,
			6.791954,
			6.83721161,
			6.88458252,
			6.930702,
			6.96239471,
			7.00769043,
			7.052414,
			7.097145,
			7.12982941,
			7.17424774,
			7.2042923,
			7.251648,
			7.267761,
			7.3123703,
			7.359627,
			7.404953,
			7.45227051,
			7.500038,
			7.530678,
			7.57444763,
			7.62323,
			7.66941071,
			7.71587372,
			7.760475,
			7.792656,
			7.83894348,
			7.88487244,
			7.930931,
			7.97872162,
			8.009926,
			8.05368,
			8.100578,
			8.147682,
			8.192947,
			8.238235,
			8.285065,
			8.314148,
			8.360901,
			8.407692,
			8.437889,
			8.484711,
			8.529892,
			8.57769,
			8.622444,
			8.670067,
			8.714058,
			8.761871,
			8.805664,
			8.837074,
			8.884674,
			8.930847,
			8.976593,
			9.023384,
			9.06724548,
			9.099937,
			9.146042,
			9.190338,
			9.238113,
			9.28392,
			9.330589,
			9.362762,
			9.408058,
			9.455826,
			9.484528,
			9.530174,
			9.577438,
			9.624222,
			9.67041,
			9.71743,
			9.763481,
			9.810257,
			9.856033,
			9.888222,
			9.9331665,
			9.979187,
			10.024971,
			10.07074,
			10.1175842,
			10.150383,
			10.1939163,
			10.2417374,
			10.287468,
			10.33445,
			10.3794708,
			10.4116364,
			10.4584274,
			10.5035095,
			10.5496521,
			10.5957413,
			10.6405258,
			10.6727448,
			10.7190018,
			10.7642136,
			10.8120422,
			10.8572845,
			10.9040833,
			10.93335,
			10.9799728,
			11.0267563,
			11.0755463,
			11.1199188,
			11.164093,
			11.1957779,
			11.2415543,
			11.2707367,
			11.320076,
			11.3642731,
			11.4120483,
			11.4573746,
			11.502327,
			11.5495071,
			11.5956268,
			11.6414185,
			11.6867294,
			11.7194443,
			11.7626419,
			11.8109436,
			11.8557281,
			11.9005737,
			11.94622,
			11.97892,
			12.0242081,
			12.0724792,
			12.1192627,
			12.163063,
			12.2102737,
			12.2419739,
			12.2867355,
			12.3347168,
			12.3813629,
			12.4265442,
			12.4738541,
			12.5030365,
			12.5514221,
			12.5955963,
			12.6413574,
			12.6871643,
			12.7331238,
			12.7621078,
			12.7943268,
			12.8406448,
			12.8869705,
			12.9324112,
			12.980072,
			13.02536,
			13.0706558,
			13.1175995,
			13.1636887,
			13.2129822,
			13.25827,
			13.2874527,
			13.4902039,
			13.534111,
			13.5957947,
			13.6425781,
			13.68737,
			13.73571,
			13.7804031,
			13.8141022,
			13.858429,
			13.9034042,
			13.9529877,
			13.9972229,
			14.0440369,
			14.0757065,
			14.1215057,
			14.1691742,
			14.213028,
			14.2618179,
			14.3046112,
			14.3373489,
			14.3810806,
			14.4127731,
			14.4575424,
			14.5036087,
			14.5511856,
			14.5978622,
			14.6436386,
			14.68998,
			14.7356949,
			14.78315,
			14.8292923,
			14.8605118,
			14.9048309,
			14.9526367,
			14.9994812,
			15.044632,
			15.0924225,
			15.1241531,
			15.16835,
			15.2121506,
			15.2453461,
			15.2906647,
			15.3374634,
			15.3829041,
			15.4138947,
			15.45771,
			15.5045013,
			15.5513763,
			15.5986862,
			15.6418381,
			15.68766,
			15.7334824,
			15.7811356,
			15.8274078,
			15.8732147,
			15.9031219,
			15.949234,
			15.9797821,
			16.0285568,
			16.0733948,
			16.1191864,
			16.163887,
			16.2121658,
			16.2580032,
			16.3034134,
			16.3507233,
			16.39579,
			16.4275055,
			16.4738235,
			16.51854,
			16.5680161,
			16.6140442,
			16.6578369,
			16.6890488,
			16.73523,
			16.7828064,
			16.8117523,
			16.8600082,
			16.9043274,
			16.9516983,
			16.9989777,
			17.0446243,
			17.090416,
			17.13691,
			17.1825638,
			17.2146149,
			17.2624512,
			17.305687,
			17.3525085,
			17.4003754,
			17.44709,
			17.475769,
			17.5220261,
			17.56881,
			17.6145782,
			17.646759,
			17.69104,
			17.73751,
			17.7815552,
			17.8137665,
			17.858551,
			17.90432,
			17.9520874,
			17.997345,
			18.0446167,
			18.0899429,
			18.1363754,
			18.1840363,
			18.2297287,
			18.25991,
			18.30574,
			18.3525238,
			18.3962326,
			18.4445114,
			18.4903412,
			18.5225143,
			18.56826,
			18.61248,
			18.6577911,
			18.7050552,
			18.7518234,
			18.7832489,
			18.8287582,
			18.87358,
			18.9208145,
			18.9683762,
			19.0138321,
			19.0455322,
			19.0767517,
			19.1219864,
			19.1686478,
			19.2140121,
			19.260788,
			19.3076324,
			19.3452911,
			19.39009,
			19.43792,
			19.4839172,
			19.5313721,
			19.5605469,
			19.6068878,
			19.6526413,
			19.697876,
			19.7441635,
			19.7904739,
			19.8226471,
			19.8670425,
			19.9131622,
			19.9589233,
			20.0056839,
			20.0519485,
			20.08274,
			20.1303711,
			20.1766663,
			20.2229,
			20.2677841,
			20.3144226,
			20.3435974,
			20.391922,
			20.4367142,
			20.484024,
			20.5302277,
			20.576004,
			20.6047058,
			20.6515274,
			20.69725,
			20.7420578,
			20.7873611,
			20.8337479,
			20.864296,
			20.909584,
			20.9544,
			21.0002823,
			21.045929,
			21.0942154,
			21.1239471,
			21.1722488,
			21.21811,
			21.2612152,
			21.3085022,
			21.3547363,
			21.3869476,
			21.4328384,
			21.4790268,
			21.52536,
			21.5721741,
			21.6194534,
			21.64753,
			21.6792221,
			21.72454,
			21.77185,
			21.817688,
			21.8467941,
			21.8966064,
			21.9404678,
			21.98822,
			22.0337143,
			22.0787735,
			22.1265717,
			22.1558075,
			22.1734238,
			22.2179337,
			22.2495117,
			22.2966537,
			22.3429565,
			22.3882675,
			22.4341583,
			22.4803467,
			22.525116,
			22.5719376,
			22.6167679,
			22.6624832,
			22.6956863,
			22.7420044,
			22.787796,
			22.8331757,
			22.86589,
			22.9115677,
			22.9573975,
			23.0046921,
			23.0506516,
			23.096756,
			23.1435547,
			23.1888657,
			23.22007,
			23.26744,
			23.3126221,
			23.3594284,
			23.40525,
			23.4520264,
			23.4852448,
			23.5299988,
			23.5768051,
			23.62062,
			23.6680527,
			23.71267,
			23.7443771,
			23.7886581,
			23.8345566,
			23.8792267,
			23.9265671,
			23.9567719,
			24.0019226,
			24.0500641,
			24.0966263,
			24.1409378,
			24.1887283,
			24.2331314,
			24.2658157,
			24.3105011,
			24.356842,
			24.4026413,
			24.4478683,
			24.4946671,
			24.5233917,
			24.5716934,
			24.6166458,
			24.6607056,
			24.70803,
			24.7542877,
			24.7845383,
			24.8302536,
			24.8755417,
			24.92189,
			24.96724,
			25.0160446,
			25.0461121,
			25.09143,
			25.1370773,
			25.1860352,
			25.2325439,
			25.2800522,
			25.3077545,
			25.354538,
			25.4011,
			25.4493256,
			25.4954,
			25.5397186,
			25.5714035,
			25.61782,
			25.6500244,
			25.69461,
			25.7419434,
			25.7872314,
			25.8345871,
			25.8802414,
			25.9245453,
			25.955246,
			26.00193,
			26.0462341,
			26.0935211,
			26.1399,
			26.1861267,
			26.2174988,
			26.2630844,
			26.3088684,
			26.357193,
			26.4018555,
			26.4477234,
			26.4945221,
			26.5418549,
			26.58612,
			26.6168365,
			26.6635361,
			26.71083,
			26.7576523,
			26.8021469,
			26.850769,
			26.87838,
			26.9251556,
			26.9714737,
			27.0178,
			27.0639954,
			27.1088028,
			27.1405411,
			27.1862869,
			27.2332611,
			27.2798,
			27.3246231,
			27.3558044,
			27.4029236,
			27.4323425,
			27.4800186,
			27.5238113,
			27.5715942,
			27.6003647,
			27.64753,
			27.6679077,
			27.7123642,
			27.7601547,
			27.8074722,
			27.8527069,
			27.8964462,
			27.9291382,
			27.97499,
			28.0192337,
			28.0656128,
			28.1122971,
			28.157608,
			28.1877747,
			28.2356873,
			28.2808228,
			28.3271332,
			28.35579,
			28.4019318,
			28.4494553,
			28.4931412,
			28.5424042,
			28.5877228,
			28.6330719,
			28.6812286,
			28.7109222,
			28.75676,
			28.8036652,
			28.848938,
			28.8950424,
			28.9408951,
			28.9715424,
			29.01886,
			29.0640945,
			29.1098557,
			29.155159,
			29.2044678,
			29.2342453,
			29.28141,
			29.3277359,
			29.3724823,
			29.4188156,
			29.4683456,
			29.4976883,
			29.5303879,
			29.57566,
			29.6194458,
			29.6641388,
			29.7124023,
			29.75772,
			29.8045349,
			29.8518448,
			29.8980789,
			29.9433517,
			29.9901962,
			30.0218658,
			30.0673676,
			30.113945,
			30.1582413,
			30.2060623,
			30.2528534,
			30.2841873,
			30.330307,
			30.3746338,
			30.4214172,
			30.4692,
			30.5155487,
			30.545166,
			30.5914917,
			30.63527,
			30.682785,
			30.7288361,
			30.7726212,
			30.8027649,
			30.8502884,
			30.89489,
			30.9402084,
			30.98748,
			31.0338974,
			31.06659,
			31.1137466,
			31.1580887,
			31.2049789,
			31.252182,
			31.29956,
			31.3291168,
			31.3734589,
			31.4197159,
			31.4675827,
			31.5127487,
			31.5605469,
			31.59127,
			31.6359711,
			31.684906,
			31.728569,
			31.7607651,
			31.8076172,
			31.8538666,
			31.8992538,
			31.9464188,
			31.9756546,
			32.0219421,
			32.06727,
			32.11241,
			32.1602173,
			32.2054977,
			32.2517929,
			32.3002,
			32.3463364,
			32.37657,
			32.4223557,
			32.4671936,
			32.5143661,
			32.56066,
			32.6074829,
			32.63765,
			32.6844864,
			32.7301941,
			32.7774734,
			32.8227768,
			32.8700638,
			32.9003372,
			32.9469833,
			32.9927673,
			33.04065,
			33.086853,
			33.132782,
			33.16381,
			33.20861,
			33.2388077,
			33.2865829,
			33.33033,
			33.3771439,
			33.40885,
			33.47223,
			33.52054,
			33.56427,
			33.612587,
			33.65937,
			33.6895981,
			33.7366333,
			33.7795639,
			33.81373,
			33.8564377,
			33.90355,
			33.9500732,
			33.9967575,
			34.043045,
			34.07527,
			34.1210175,
			34.16693,
			34.2125778,
			34.2574,
			34.30468,
			34.351944,
			34.39894,
			34.44297,
			34.4761734,
			34.521965,
			34.568573,
			34.6144943,
			34.66078,
			34.7071381,
			34.7382736,
			34.7842255,
			34.8308029,
			34.87461,
			34.9203873,
			34.9685,
			34.9999161,
			35.0450668,
			35.0772552,
			35.1225281,
			35.1706161,
			35.2161942,
			35.262825,
			35.3091354,
			35.3529358,
			35.3993149,
			35.4464569,
			35.49125,
			35.52292,
			35.56845,
			35.61296,
			35.6607132,
			35.705,
			35.7527924,
			35.7809219,
			35.8282,
			35.8745346,
			35.9187622,
			35.9639969,
			36.010788,
			36.0394745,
			36.08676,
			36.1333237,
			36.1787872,
			36.2261047,
			36.2574234,
			36.30314,
			36.34783,
			36.39611,
			36.44243,
			36.4856873,
			36.5334167,
			36.5641022,
			36.60887,
			36.6556473,
			36.70125,
			36.7471924,
			36.79354,
			36.8247452,
			36.8705063,
			36.9168167,
			36.96351,
			37.0097733,
			37.0545578,
			37.08629,
			37.13263,
			37.1767731,
			37.22207,
			37.2687,
			37.3145981,
			37.34478,
			37.39258,
			37.43788,
			37.48668,
			37.5174026,
			37.5626,
			37.6103745,
			37.65567,
			37.7017822,
			37.7472229,
			37.7955246,
			37.84182,
			37.8725357,
			37.9178772,
			37.96572,
			38.0113754,
			38.0566559,
			38.1043777,
			38.1365738,
			38.18126,
			38.2295532,
			38.27334,
			38.3201065,
			38.368454,
			38.3960266,
			38.4292374,
			38.47412,
			38.5047531,
			38.55008,
			38.5967636,
			38.64055,
			38.7023468,
			38.74769,
			38.7954636,
			38.84227,
			38.8885269,
			38.9197159,
			38.9500656,
			38.9930878,
			39.0414276,
			39.0862122,
			39.1330643,
			39.1792221,
			39.22554,
			39.2713547,
			39.3172531,
			39.3623428,
			39.4086761,
			39.4383926,
			39.4847031,
			39.5303955,
			39.57669,
			39.608902,
			39.6552048,
			39.7019577,
			39.74727,
			39.79354,
			39.8419266,
			39.8881836,
			39.93416,
			39.9641037,
			39.9973068,
			40.04261,
			40.07232,
			40.11766,
			40.14943,
			40.1955948,
			40.22677,
			40.27108,
			40.3175354,
			40.3631134,
			40.4094162,
			40.45372,
			40.4859161,
			40.53118,
			40.57698,
			40.6243134,
			40.6702957,
			40.7174454,
			40.74653,
			40.7938232,
			40.8402023,
			40.8844757,
			40.9333878,
			40.9785,
			41.0091858,
			41.0414352,
			41.0886765,
			41.1350021,
			41.1807022,
			41.2265,
			41.2733459,
			41.3195953,
			41.3659744,
			41.41366,
			41.4584656,
			41.5057526,
			41.53691,
			41.5816727,
			41.61287,
			41.65867,
			41.7036819,
			41.7514343,
			41.7970428,
			41.8428345,
			41.88965,
			41.93476,
			41.9796753,
			42.0274734,
			42.0576935,
			42.09041,
			42.13719,
			42.183197,
			42.2316666,
			42.28045,
			42.30863,
			42.34037,
			42.38562,
			42.43135,
			42.478157,
			42.5088577,
			42.55567,
			42.5685577,
			42.6145248,
			42.6628342,
			42.708168,
			42.7519226,
			42.79767,
			42.8308563,
			42.87767,
			42.9219742,
			42.9692,
			43.0156479,
			43.060318,
			43.0915451,
			43.1375046,
			43.1847763,
			43.2303848,
			43.2761841,
			43.3224945,
			43.3521652,
			43.3841171,
			43.43061,
			43.46029,
			43.508606,
			43.5544128,
			43.600174,
			43.6163864,
			43.6630554,
			43.7093277,
			43.7556534,
			43.8012772,
			43.84664,
			43.8783035,
			43.9220657,
			43.96823,
			44.0130844,
			44.0458,
			44.0920868,
			44.1378479,
			44.1851273,
			44.2303619,
			44.2791443,
			44.32292,
			44.3712158,
			44.4024124,
			44.4481659,
			44.4943848,
			44.53768,
			44.58451,
			44.6328278,
			44.663002,
			44.7106552,
			44.7423553,
			44.788147,
			44.83248,
			44.8811951,
			44.9258575,
			44.973175,
			45.01818,
			45.062645,
			45.1119156,
			45.1556931,
			45.1858749,
			45.2345657,
			45.2814026,
			45.32566,
			45.3739929,
			45.4187546,
			45.4514771,
			45.49666,
			45.5434036,
			45.59021,
			45.6353149,
			45.6812057,
			45.71038,
			45.75718,
			45.80181,
			45.851265,
			45.8959656,
			45.9417877,
			45.97548,
			46.0187759,
			46.0670929,
			46.11271,
			46.1614838,
			46.2047653,
			46.2375031,
			46.2679,
			46.3143768,
			46.3586731,
			46.4032974,
			46.4522629,
			46.4842529,
			46.54528,
			46.5905838,
			46.63733,
			46.6837769,
			46.7298355,
			46.76159,
			46.80635,
			46.8525772,
			46.89834,
			46.9451065,
			46.9893723,
			47.0215759,
			47.0668335,
			47.1140823,
			47.1583862,
			47.2035065,
			47.2516174,
			47.282692,
			47.3272934,
			47.3730774,
			47.4198532,
			47.4684143,
			47.5138626,
			47.54303,
			47.5918274,
			47.63584,
			47.6808243,
			47.7301331,
			47.77392,
			47.8050842,
			47.85041,
			47.89559,
			47.94139,
			47.9891434,
			48.03595,
			48.06459,
			48.1108856,
			48.1581421,
			48.2015152,
			48.2489548,
			48.29538,
			48.3265533,
			48.3723526,
			48.4196548,
			48.4655151,
			48.5116272,
			48.5559,
			48.5870972,
			48.63347,
			48.6791229,
			48.72589,
			48.77108,
			48.8195038,
			48.8476257,
			48.89692,
			48.9407578,
			48.98699,
			49.0328979,
			49.080513,
			49.1127,
			49.1585236,
			49.2063141,
			49.2511368,
			49.29682,
			49.345108,
			49.3738022,
			49.4216,
			49.4665756,
			49.51413,
			49.5584259,
			49.60521,
			49.63562,
			49.68167,
			49.73047,
			49.7601547,
			49.8080063,
			49.85531,
			49.885025,
			49.9463272,
			49.994133,
			50.0384445,
			50.08622,
			50.13147,
			50.1631622,
			50.2090149,
			50.2517471,
			50.30017,
			50.3302765,
			50.3755646,
			50.421814,
			50.4683075,
			50.5138626,
			50.5481339,
			50.5929031,
			50.63867,
			50.685524,
			50.7307053,
			50.7769852,
			50.807724,
			50.85447,
			50.9021454,
			50.9460373,
			50.99385,
			51.0397339,
			51.084,
			51.1307,
			51.1785049,
			51.2077255,
			51.2560043,
			51.301384,
			51.347023,
			51.3938141,
			51.44014,
			51.4713821,
			51.51912,
			51.56436,
			51.6086578,
			51.6554871,
			51.70086,
			51.7331161,
			51.7787247,
			51.825058,
			51.87233,
			51.91815,
			51.963356,
			51.9945068,
			52.040802,
			52.0860748,
			52.1324463,
			52.1781464,
			52.22445,
			52.2546234,
			52.29686,
			52.3446579,
			52.39245,
			52.43728,
			52.48462,
			52.51744,
			52.5630264,
			52.59275,
			52.63909,
			52.6678467,
			52.71447,
			52.74566,
			52.77487,
			52.8226471,
			52.86753,
			52.9153442,
			52.9614868,
			53.00733,
			53.0380249,
			53.068222,
			53.1139679,
			53.1607437,
			53.20807,
			53.2538528,
			53.2850952,
			53.3287659,
			53.374588,
			53.4193649,
			53.4641037,
			53.51288,
			53.5415726,
			53.5873871,
			53.63433,
			53.6808853,
			53.7281647,
			53.7730026,
			53.80417,
			53.85151,
			53.89621,
			53.943,
			53.9912949,
			54.0376434,
			54.0679321,
			54.11458,
			54.15886,
			54.20572,
			54.2520828,
			54.29772,
			54.3294144,
			54.3591232,
			54.40541,
			54.4517059,
			54.49871,
			54.5291977,
			54.5739975,
			54.6378937,
			54.68422,
			54.7284,
			54.7741852,
			54.8204956,
			54.8508377,
			54.897934,
			54.9432068,
			54.98751,
			55.0212936,
			55.0676651,
			55.1128235,
			55.1586075,
			55.204422,
			55.2512665,
			55.29647,
			55.346283,
			55.3774948,
			55.42431,
			55.46837,
			55.5159836,
			55.560112,
			55.60794,
			55.6371231,
			55.6841431,
			55.7296829,
			55.77697,
			55.8213043,
			55.869194,
			55.89724,
			55.94303,
			55.9908752,
			56.0364075,
			56.08355,
			56.12922,
			56.1614075,
			56.20723,
			56.2515564,
			56.2972565,
			56.3450775,
			56.39286,
			56.42408,
			56.4668274,
			56.5141,
			56.56038,
			56.6046677,
			56.65296,
			56.68112,
			56.72689,
			56.7742462,
			56.81949,
			56.8518,
			56.89643,
			56.9281158,
			56.9422455,
			56.9875336,
			57.01776,
			57.0669632,
			57.112236,
			57.15806,
			57.2025375,
			57.2492371,
			57.29734,
			57.3421021,
			57.3879,
			57.43515,
			57.46644,
			57.5115967,
			57.5593872,
			57.60524,
			57.6511078,
			57.69671,
			57.7278976,
			57.7742081,
			57.821,
			57.8673859,
			57.91201,
			57.9573135,
			57.9895248,
			58.0351868,
			58.08046,
			58.1121521,
			58.1580048,
			58.2052765,
			58.2516174,
			58.2973328,
			58.3431854,
			58.38893,
			58.4206619,
			58.46392,
			58.5122223,
			58.5575562,
			58.5887375,
			58.6358032,
			58.6824646,
			58.7286224,
			58.77143,
			58.81923,
			58.86348,
			58.8951874,
			58.940033,
			58.98684,
			59.0347137,
			59.0793762,
			59.127182,
			59.17301,
			59.2178574,
			59.2645645,
			59.29727,
			59.34314,
			59.39044,
			59.43576,
			59.4840546,
			59.52829,
			59.5604858,
			59.6067963,
			59.6525726,
			59.69684,
			59.7436371,
			59.79097,
			59.82213,
			59.8681641,
			59.9142151,
			59.96202,
			60.0068359,
			60.05313,
			60.0829468,
			60.1295929,
			60.17444,
			60.2201843,
			60.2666,
			60.3137665,
			60.3444672,
			60.3907928,
			60.4346924,
			60.4798279,
			60.5271454,
			60.5729828,
			60.6056824,
			60.648407,
			60.68112,
			60.72792,
			60.7616272,
			60.80797,
			60.8552551,
			60.8703766,
			60.914093,
			60.963913,
			61.008728,
			61.0550232,
			61.08458,
			61.1304779,
			61.17929,
			61.2251129,
			61.27025,
			61.3172455,
			61.3639832,
			61.39418,
			61.44101,
			61.47058,
			61.5181274,
			61.5632477,
			61.61206,
			61.6553955,
			61.70247,
			61.7501831,
			61.77864,
			61.8269348,
			61.85666,
			61.90497,
			61.94998,
			61.99652,
			62.0418549,
			62.0891724,
			62.1353455,
			62.1678162,
			62.213913,
			62.2592,
			62.30327,
			62.3504944,
			62.39708,
			62.42778,
			62.47464,
			62.5214233,
			62.5658569,
			62.61145,
			62.6432037,
			62.688446,
			62.7212067,
			62.76758,
			62.81273,
			62.8605042,
			62.90683,
			62.95311,
			62.99907,
			63.045166,
			63.0909576,
			63.1387329,
			63.1837921,
			63.2146759,
			63.26042,
			63.3056946,
			63.3515472,
			63.36714
		]
	],
	'6586_6587_7982': [
		[
			127.311386
		],
		[
			0
		],
		[
			0,
			1.79469275,
			3.05810928,
			5.59112358,
			8.137225,
			9.413849,
			11.974473,
			14.5456409,
			17.124382,
			18.4164619,
			21.0063953,
			23.6035423,
			26.2075539,
			28.8123569,
			31.42133,
			32.7289619,
			35.3496323,
			36.66249,
			39.2933769,
			41.9298019,
			44.56858,
			47.2127762,
			49.86082,
			52.5159454,
			53.8462944,
			56.51242,
			57.8496475,
			60.5293121,
			63.22004,
			65.91951,
			67.27196,
			69.98221,
			72.69959,
			75.4242,
			76.78908,
			79.5241852,
			82.2672348,
			83.64289,
			86.39932,
			89.16887,
			90.55643,
			93.33184,
			96.11086,
			97.50057,
			100.281723,
			103.071045,
			105.868607,
			108.6736,
			110.0788,
			112.894089,
			115.715874,
			118.544678,
			121.380348,
			124.22168,
			125.644638,
			128.495514,
			131.352875,
			134.216766,
			135.651154,
			138.52475,
			139.963943,
			142.847092,
			144.290924,
			147.183456,
			150.082443,
			151.5343,
			154.443008,
			155.899765,
			158.817566,
			160.278656,
			163.205292,
			166.1379,
			169.075836,
			170.546814,
			173.493927,
			176.448151,
			179.4086,
			182.375031,
			183.860733,
			186.836655,
			189.8188,
			191.3122,
			194.302856,
			197.2991,
			200.299133,
			201.80127,
			204.801865,
			207.80455,
			210.8161,
			212.324036,
			215.352844,
			216.870331,
			219.910522,
			222.957016,
			226.013153,
			229.07515,
			230.608368,
			233.677826,
			235.211975,
			238.28421,
			241.360168,
			242.900116,
			245.9858,
			249.077209,
			250.625214,
			253.724945,
			256.829071,
			259.938843,
			263.0544,
			266.174347,
			267.736176,
			270.863953,
			272.4298,
			275.565125,
			278.705261,
			280.2774,
			281.85083,
			285.001,
			288.1556,
			291.3157,
			292.897675,
			296.0647,
			297.6499,
			300.823883,
			304.0026,
			305.593658,
			308.7788,
			311.96344,
			315.151917,
			318.344818,
			321.542175,
			323.1425,
			326.345825,
			327.948975,
			331.158447,
			334.371948,
			337.588867,
			340.81015,
			344.035858,
			345.650024,
			348.8813,
			352.117676,
			355.358459,
			358.603271,
			360.226929,
			363.476624,
			365.103,
			368.358948,
			371.6188,
			374.8825,
			378.150055,
			381.4217,
			383.059021,
			386.33667,
			387.97702,
			391.2607,
			394.5483,
			397.838379,
			399.4848,
			402.780151,
			406.072937,
			409.35672,
			412.6403,
			415.9315,
			417.587524,
			420.9037,
			424.2288,
			425.8932,
			429.226379,
			432.56543,
			434.237061,
			437.579468,
			440.923279,
			442.595673,
			445.943329,
			449.294739,
			450.971863,
			452.650269,
			456.0099,
			459.374237,
			462.741821,
			464.426758,
			466.112732,
			469.4934,
			472.878479,
			476.267944,
			479.656738,
			483.048553,
			486.443359,
			488.1421,
			489.8418,
			493.244568,
			496.651642,
			500.0618,
			501.7684,
			505.18515,
			508.6057,
			510.317383,
			513.743652,
			517.1737,
			520.6074,
			524.0453,
			525.765747,
			529.2094,
			532.6575,
			536.1124,
			539.5711,
			541.3019,
			544.7632,
			546.4946,
			549.9606,
			553.430542,
			556.904358,
			558.643555,
			562.123962,
			565.6071,
			579.296753,
			582.6096,
			585.884644,
			587.5076,
			590.7142,
			592.302734,
			595.445557,
			598.551636,
			601.6279,
			604.671631,
			607.683167,
			609.1758,
			612.134155,
			615.055,
			616.50116,
			619.3648,
			620.782349,
			623.588867,
			626.3579,
			629.089233,
			631.782959,
			634.439453,
			637.0586,
			638.3542,
			640.9171,
			642.1846,
			644.6918,
			647.161865,
			649.5956,
			650.7993,
			653.1813,
			655.529541,
			657.8435,
			660.1187,
			662.3571,
			663.462036,
			665.6426,
			666.7192,
			668.841064,
			670.916138,
			672.9548,
			674.946655,
			675.9281,
			676.901,
			678.822937,
			680.717041,
			682.5774,
			684.41394,
			686.2267,
			687.1217,
			688.8912,
			690.646851,
			692.3552,
			694.03125,
			694.8565,
			696.4828,
			698.078735,
			698.863647,
			700.4066,
			701.924866,
			702.6764,
			704.1599,
			704.891052,
			706.3474,
			707.8007,
			709.2525,
			710.7029,
			711.4296,
			712.886963,
			713.617737,
			715.0865,
			716.558167,
			718.0335,
			719.532959,
			720.290039,
			721.805054,
			723.3217,
			724.87,
			726.426147,
			727.982544,
			728.7617,
			730.329956,
			731.8971,
			733.464233,
			734.2554,
			735.8438,
			737.4318,
			739.0221,
			740.619751,
			741.419067,
			743.0196,
			744.631531,
			745.4422,
			747.0665,
			748.6956,
			750.3642,
			751.2002,
			752.036743,
			753.712646,
			755.3756,
			757.036,
			758.6979,
			760.359741,
			761.1896,
			762.848145,
			764.5229,
			765.3744,
			767.0758,
			768.7776,
			770.5,
			772.2229,
			773.9467,
			774.818359,
			776.5632,
			777.436157,
			779.1874,
			780.973145,
			782.7609,
			784.56665,
			786.4213,
			787.3503,
			789.2135,
			791.099243,
			792.0443,
			793.9404,
			795.8214,
			796.763062,
			798.653259,
			800.5128,
			801.437561,
			803.2943,
			805.106934,
			806.873047,
			808.649536,
			810.3936,
			811.2566,
			812.990356,
			814.735046,
			816.4879,
			817.3683,
			819.138062,
			820.9241,
			822.7224,
			824.5339,
			825.4461,
			827.2794,
			829.125854,
			830.06,
			831.9442,
			833.8407,
			834.795044,
			836.7064,
			838.6465,
			840.596741,
			842.5577,
			844.5314,
			845.5222,
			847.5126,
			848.5122,
			850.521362,
			852.543335,
			853.5747,
			855.6113,
			857.6548,
			858.676758,
			860.725342,
			862.7754,
			864.819763,
			866.8987,
			868.9707,
			870.0086,
			872.1616,
			874.3058,
			876.4553,
			877.529236,
			879.6706,
			881.7678,
			882.810059,
			884.890869,
			886.9481,
			887.9738,
			890.022644,
			891.049,
			893.0996,
			894.124,
			896.1647,
			898.252441,
			899.2907,
			901.386536,
			902.443848,
			904.567566,
			905.633057,
			907.765564,
			909.91,
			912.052246,
			914.201965,
			916.348267,
			918.4846,
			920.6288,
			921.674438,
			923.7739,
			924.827637,
			926.9164,
			928.991455,
			931.0724,
			932.118042,
			934.1992,
			936.308655,
			938.449646,
			940.5825,
			941.6541,
			943.805359,
			944.8845,
			947.0519,
			949.212036,
			951.400146,
			953.599731,
			954.7029,
			956.9165,
			959.139648,
			960.254639,
			962.4916,
			964.737549,
			966.9923,
			968.1228,
			970.3904,
			972.6658,
			974.949341,
			977.2409,
			978.3895,
			979.5404,
			981.8484,
			984.1642,
			986.4887,
			988.82135,
			989.990967,
			992.33606,
			994.6891,
			997.050354,
			998.233765,
			1000.60718,
			1001.79681,
			1004.18054,
			1006.57312,
			1007.77148,
			1010.17017,
			1012.56775,
			1014.95703,
			1017.32562,
			1019.68677,
			1020.82532,
			1023.11609,
			1025.37231,
			1026.48816,
			1028.71631,
			1030.90234,
			1033.07056,
			1035.22729,
			1037.37451,
			1038.44189,
			1040.57141,
			1041.63354,
			1043.74915,
			1045.856,
			1046.90637,
			1048.99548,
			1051.07666,
			1052.1145,
			1054.18408,
			1056.24609,
			1058.30615,
			1060.36292,
			1062.4165,
			1063.44556,
			1065.50134,
			1067.55737,
			1068.58765,
			1070.64917,
			1072.71423,
			1074.78528,
			1076.85938,
			1078.94006,
			1079.98218,
			1082.07031,
			1083.11865,
			1085.22119,
			1087.329,
			1088.38672,
			1090.50391,
			1092.62231,
			1094.7406,
			1096.85645,
			1097.91284,
			1100.02075,
			1102.12134,
			1104.21509,
			1106.30371,
			1107.34729,
			1109.43579,
			1111.52429,
			1113.6145,
			1114.6604,
			1116.75146,
			1118.84106,
			1120.9292,
			1121.97375,
			1124.06689,
			1126.16431,
			1127.21423,
			1129.32349,
			1131.43787,
			1133.5542,
			1135.67114,
			1136.73,
			1138.84656,
			1139.90344,
			1142.019,
			1144.13879,
			1146.26355,
			1147.32813,
			1149.46289,
			1150.53271,
			1152.67883,
			1154.83154,
			1155.91064,
			1158.07581,
			1159.16028,
			1161.33521,
			1163.51965,
			1164.616,
			1166.81519,
			1169.02441,
			1170.13281,
			1172.35547,
			1174.58557,
			1176.82117,
			1179.06116,
			1180.18274,
			1182.4259,
			1184.66858,
			1186.9082,
			1188.02612,
			1190.25684,
			1192.4812,
			1193.59155,
			1195.80762,
			1198.01733,
			1199.12012,
			1201.32178,
			1203.51624,
			1205.70947,
			1207.896,
			1210.08386,
			1211.17871,
			1213.363,
			1214.45715,
			1216.65381,
			1218.84351,
			1221.0498,
			1223.25085,
			1224.35156,
			1226.56543,
			1228.77173,
			1230.96973,
			1232.06653,
			1234.25745,
			1236.42908,
			1238.60034,
			1239.68652,
			1241.85938,
			1242.94556,
			1245.11731,
			1247.28735,
			1249.45447,
			1251.5813,
			1253.7063,
			1254.76343,
			1256.86011,
			1258.95459,
			1260.00256,
			1262.09973,
			1264.19788,
			1266.30469,
			1268.4165,
			1270.53564,
			1271.59875,
			1273.73145,
			1274.80115,
			1276.94531,
			1279.08142,
			1281.24316,
			1283.41406,
			1284.5033,
			1286.69,
			1288.88684,
			1289.98914,
			1292.20215,
			1294.42529,
			1295.54028,
			1296.65771,
			1297.77771,
			1300.02551,
			1302.282,
			1303.414,
			1305.685,
			1307.96558,
			1310.25586,
			1312.55542,
			1314.86548,
			1317.18579,
			1318.34985,
			1320.68579,
			1321.85754,
			1324.20874,
			1326.57043,
			1327.75513,
			1330.1322,
			1332.53979,
			1334.93774,
			1337.34656,
			1338.55444,
			1340.97778,
			1343.40771,
			1345.84277,
			1348.278,
			1349.49573,
			1351.92773,
			1353.14136,
			1355.56555,
			1357.98193,
			1360.393,
			1362.79883,
			1365.19873,
			1367.59985,
			1368.79919,
			1371.194,
			1372.39355,
			1374.78821,
			1377.18225,
			1378.38,
			1380.77124,
			1383.17139,
			1385.5686,
			1387.97827,
			1390.38965,
			1391.59741,
			1394.08752,
			1395.3302,
			1397.832,
			1400.35059,
			1402.83752,
			1405.29211,
			1407.75671,
			1408.99451,
			1411.47742,
			1414.01624,
			1415.30444,
			1417.91284,
			1420.57056,
			1421.90356,
			1424.5719,
			1425.89807,
			1428.45386,
			1430.92651,
			1433.36475,
			1434.56177,
			1436.96252,
			1439.35022,
			1441.74634,
			1444.161,
			1446.58423,
			1447.79968,
			1450.2345,
			1452.65576,
			1455.05737,
			1456.24731,
			1458.60132,
			1459.76453,
			1462.0625,
			1464.3269,
			1466.5564,
			1468.76013,
			1470.93652,
			1472.0127,
			1474.1936,
			1476.3562,
			1477.42419,
			1479.63623,
			1480.73254,
			1482.8999,
			1485.04492,
			1487.15222,
			1489.21118,
			1490.21289,
			1492.18774,
			1493.17273,
			1494.17114,
			1496.14038,
			1497.1123,
			1499.05713,
			1500.98682,
			1501.94141,
			1503.7854,
			1505.55518,
			1507.31042,
			1509.03052,
			1510.72412,
			1512.41382,
			1513.264,
			1514.99609,
			1515.86194,
			1517.595,
			1519.35938,
			1521.14038,
			1522.03259,
			1523.82788,
			1525.6438,
			1527.46338,
			1529.30713,
			1530.23975,
			1532.10828,
			1533.04419,
			1534.92236,
			1536.80273,
			1538.6792,
			1540.5033,
			1541.41528,
			1543.24036,
			1544.99878,
			1546.74316,
			1547.615,
			1549.35132,
			1550.2146,
			1551.94031,
			1553.6665,
			1554.53516,
			1556.27136,
			1557.13916,
			1558.87427,
			1559.73926,
			1561.46765,
			1563.19531,
			1564.05737,
			1565.77356,
			1567.48828,
			1568.34546,
			1570.052,
			1571.75366,
			1573.454,
			1575.14551,
			1575.98889,
			1577.67419,
			1579.35632,
			1581.02612,
			1581.86035,
			1583.52844,
			1585.18811,
			1586.01514,
			1587.66785,
			1588.49365,
			1590.1394,
			1591.77917,
			1593.41833,
			1595.054,
			1596.68542,
			1597.50159,
			1598.31848,
			1599.95813,
			1601.60571,
			1603.25427,
			1604.90649,
			1606.565,
			1607.39136,
			1609.03674,
			1610.67041,
			1611.48,
			1613.08032,
			1614.65271,
			1616.199,
			1617.71191,
			1618.45764,
			1619.9303,
			1620.65686,
			1622.09949,
			1623.53369,
			1624.24927,
			1625.66089,
			1627.06348,
			1628.47,
			1629.883,
			1631.28333,
			1632.68945,
			1633.39465,
			1634.80981,
			1636.22668,
			1636.93616,
			1638.35645,
			1639.77661,
			1641.1947,
			1641.90161,
			1642.60645,
			1644.00964,
			1645.40735,
			1646.81909,
			1648.21094,
			1648.89832,
			1650.25574,
			1651.5968,
			1652.91821,
			1654.22644,
			1655.51733,
			1656.14673,
			1657.399,
			1658.02148,
			1659.26025,
			1660.49207,
			1661.71387,
			1662.32141,
			1663.53064,
			1664.73413,
			1665.93945,
			1667.14209,
			1667.74048,
			1668.93433,
			1670.126,
			1671.324,
			1671.92175,
			1673.114,
			1674.30432,
			1674.89966,
			1676.09485,
			1677.2915,
			1678.4873,
			1679.68494,
			1680.89026,
			1681.49829,
			1682.71362,
			1683.32141,
			1684.53784,
			1685.75793,
			1686.37476,
			1687.605,
			1688.83325,
			1690.06262,
			1691.3009,
			1692.545,
			1693.78711,
			1694.4082,
			1695.65234,
			1696.29675,
			1697.61145,
			1698.92688,
			1699.58545,
			1700.90515,
			1702.32251,
			1703.75049,
			1705.1825,
			1705.90063,
			1707.422,
			1708.96143,
			1709.73315,
			1711.28687,
			1712.08911,
			1713.698,
			1714.50537,
			1716.12659,
			1716.94055,
			1718.57422,
			1720.21777,
			1721.044,
			1722.64209,
			1724.23718,
			1725.03918,
			1725.84424,
			1726.633,
			1728.11572,
			1728.86108,
			1730.36121,
			1731.85547,
			1733.28809,
			1734.73389,
			1735.46167,
			1736.93115,
			1738.42822,
			1739.18188,
			1740.69934,
			1742.23926,
			1743.81177,
			1745.39722,
			1746.99756,
			1748.63647,
			1749.4563,
			1751.09473,
			1752.74976,
			1753.58716,
			1755.27185,
			1756.96863,
			1757.82971,
			1759.563,
			1760.43408,
			1762.185,
			1763.958,
			1765.74365,
			1767.54187,
			1769.35352,
			1770.26318,
			1772.09192,
			1773.93335,
			1774.84326,
			1776.70264,
			1778.5741,
			1779.51392,
			1781.40259,
			1783.302,
			1785.229,
			1787.15234,
			1789.08777,
			1790.06,
			1792.01318,
			1793.96143,
			1795.95044,
			1796.94043,
			1798.92773,
			1800.90674,
			1801.9082,
			1803.91858,
			1805.93713,
			1807.96692,
			1808.986,
			1811.032,
			1813.08838,
			1814.11975,
			1816.18872,
			1818.26733,
			1820.3418,
			1822.41626,
			1824.49707,
			1825.54041,
			1827.63416,
			1829.73718,
			1830.79224,
			1832.90942,
			1835.03662,
			1837.17407,
			1839.32153,
			1840.39929,
			1842.56213,
			1844.7356,
			1846.92017,
			1848.01624,
			1850.21655,
			1852.428,
			1853.53735,
			1855.76563,
			1858.00513,
			1859.12854,
			1861.38647,
			1863.65454,
			1865.93579,
			1868.22852,
			1870.53247,
			1872.84863,
			1875.194,
			1877.53247,
			1879.88,
			1882.239,
			1884.60718,
			1886.986,
			1888.17847,
			1889.3728,
			1891.76672,
			1894.16626,
			1896.57031,
			1897.77344,
			1900.18213,
			1902.58838,
			1904.995,
			1907.39746,
			1909.798,
			1912.19873,
			1913.39893,
			1915.80066,
			1917.00623,
			1919.42285,
			1921.84937,
			1924.29358,
			1925.51868,
			1927.98291,
			1930.45972,
			1932.95117,
			1934.20215,
			1936.70728,
			1939.221,
			1940.47632,
			1942.9834,
			1945.459,
			1946.69934,
			1949.16211,
			1950.38135,
			1951.59143,
			1953.98218,
			1956.33484,
			1958.64966,
			1959.79272,
			1962.0509,
			1963.16455,
			1965.36255,
			1967.52319,
			1968.58667,
			1970.70154,
			1972.75977,
			1974.77393,
			1975.75024,
			1977.70813,
			1979.62329,
			1981.50391,
			1983.36633,
			1984.27612,
			1986.074,
			1987.84375,
			1989.58313,
			1991.294,
			1992.9801,
			1993.814,
			1995.46545,
			1997.09912,
			1997.91064,
			1999.52588,
			2001.13647,
			2001.94214,
			2003.55847,
			2005.1853,
			2006.81689,
			2008.45227,
			2010.09717,
			2010.92065,
			2012.56824,
			2013.3927,
			2015.03809,
			2015.86047,
			2017.50549,
			2019.14954,
			2020.78857,
			2022.42688,
			2023.246,
			2024.88171,
			2026.51343,
			2028.144,
			2029.77417,
			2030.58813,
			2032.2146,
			2033.84082,
			2035.4696,
			2036.28308,
			2037.9093,
			2039.53955,
			2041.17529,
			2042.80884,
			2044.44434,
			2045.26758,
			2046.91089,
			2047.73145,
			2049.371,
			2051.016,
			2051.837,
			2053.47656,
			2055.11816,
			2056.76318,
			2057.58472,
			2059.22778,
			2060.88159,
			2061.70947,
			2062.537,
			2064.19336,
			2065.02417,
			2066.69727,
			2068.37036,
			2069.208,
			2070.895,
			2072.59033,
			2074.28662,
			2075.13574,
			2076.86279,
			2078.58862,
			2079.452,
			2082.08374,
			2082.9668,
			2084.7334,
			2086.50464,
			2087.39087,
			2089.1626,
			2090.93335,
			2091.816,
			2093.57935,
			2094.46069,
			2096.22534,
			2097.992,
			2098.87451,
			2099.75659,
			2101.522,
			2103.28613,
			2105.04883,
			2106.80127,
			2107.67236,
			2109.412,
			2111.151,
			2112.02124,
			2113.75879,
			2115.49438,
			2116.3623,
			2117.22925,
			2118.09521,
			2119.82471,
			2121.55225,
			2122.41357,
			2124.1333,
			2125.85156,
			2127.582,
			2128.44775,
			2130.17749,
			2131.04224,
			2132.79053,
			2134.5415,
			2136.29224,
			2138.06445,
			2139.84,
			2141.61768,
			2143.43945,
			2144.35059,
			2146.17529,
			2148.0332,
			2148.96826,
			2150.84082,
			2152.732,
			2153.68579,
			2155.59717,
			2157.51123,
			2158.466,
			2160.37964,
			2162.298,
			2164.20117,
			2165.15479,
			2167.067,
			2168.93433,
			2170.8,
			2172.663,
			2173.57373,
			2175.39941,
			2177.23169,
			2178.14282,
			2179.96533,
			2181.796,
			2183.62378,
			2184.53687,
			2186.36987,
			2187.29028,
			2189.12769,
			2190.04834,
			2191.89746,
			2192.827,
			2193.75854,
			2195.62939,
			2197.51147,
			2199.39966,
			2201.29614,
			2202.249,
			2204.1604,
			2206.07935,
			2208.00977,
			2208.9812,
			2210.93555,
			2211.917,
			2213.88745,
			2215.8623,
			2216.85352,
			2218.84424,
			2220.83765,
			2222.8252,
			2224.85547,
			2226.90967,
			2228.982,
			2230.02,
			2232.10278,
			2233.13037,
			2235.23169,
			2237.34521,
			2239.46924,
			2241.609,
			2243.75854,
			2244.837,
			2246.99927,
			2249.17188,
			2250.25977,
			2252.44116,
			2254.63257,
			2255.72974,
			2257.93164,
			2260.1626,
			2262.371,
			2263.48853,
			2265.7356,
			2267.998,
			2270.26953,
			2271.409,
			2273.69482,
			2275.99121,
			2277.14355,
			2279.45557,
			2281.77734,
			2284.1084,
			2286.44922,
			2287.623,
			2289.97827,
			2291.15942,
			2292.343,
			2293.529,
			2295.9082,
			2298.297,
			2300.6958,
			2303.106,
			2304.315,
			2306.74023,
			2307.95728,
			2310.39771,
			2312.84619,
			2315.30176,
			2317.76563,
			2319,
			2321.47559,
			2323.96,
			2326.45337,
			2328.955,
			2330.20923,
			2332.724,
			2335.24634,
			2337.777,
			2339.045,
			2341.586,
			2344.1355,
			2345.41382,
			2347.97656,
			2350.54883,
			2353.13037,
			2354.424,
			2357.01831,
			2359.62085,
			2362.22656,
			2364.83276,
			2366.13818,
			2368.75464,
			2370.06567,
			2371.37842,
			2372.693,
			2375.32813,
			2377.971,
			2380.621,
			2383.27832,
			2384.60962,
			2385.94287,
			2388.61426,
			2391.29272,
			2392.635,
			2395.32471,
			2398.02173,
			2399.373,
			2400.72632,
			2403.4375,
			2406.15576,
			2408.88135,
			2411.61377,
			2412.98242,
			2415.72217,
			2418.46924,
			2419.845,
			2422.60156,
			2423.98242,
			2426.75049,
			2428.13721,
			2429.52612,
			2432.31,
			2435.10132,
			2437.90039,
			2440.70679,
			2442.1123,
			2444.929,
			2447.7522,
			2449.166,
			2451.99927,
			2454.839,
			2456.262,
			2459.1123,
			2461.96973,
			2464.834,
			2467.705,
			2470.58325,
			2472.02515,
			2474.91431,
			2477.81,
			2479.26,
			2482.165,
			2485.07617,
			2486.534,
			2489.454,
			2492.38062,
			2493.84619,
			2496.78223,
			2499.725,
			2502.67432,
			2505.62964,
			2508.59131,
			2511.55884,
			2513.04517,
			2516.02222,
			2517.51318,
			2520.5,
			2523.493,
			2526.4917,
			2527.99316,
			2531.00146,
			2534.01587,
			2535.52539,
			2538.54858,
			2541.578,
			2544.61279,
			2546.13281,
			2549.17676,
			2552.227,
			2555.28247,
			2556.81226,
			2559.87646,
			2562.94678,
			2564.484,
			2566.02222,
			2569.10327,
			2572.19,
			2573.7356,
			2576.831,
			2579.932,
			2581.48438,
			2584.59326,
			2587.70752,
			2590.82715,
			2593.952,
			2597.08154,
			2598.64868,
			2601.787,
			2604.931,
			2606.50464,
			2609.65576,
			2611.23315,
			2614.3916,
			2617.55542,
			2620.724,
			2622.3103,
			2625.48584,
			2628.66626,
			2631.85132,
			2635.036,
			2638.225,
			2639.82153,
			2643.018,
			2644.618,
			2647.821,
			2651.02856,
			2654.24048,
			2655.84814,
			2659.06543,
			2662.28662,
			2665.5127,
			2668.74268,
			2670.359,
			2673.59448,
			2676.83472,
			2680.07886,
			2681.70239,
			2684.95239,
			2688.20752,
			2689.83667,
			2693.09717,
			2696.36157,
			2699.63013,
			2701.26587,
			2704.54053,
			2707.81934,
			2711.102,
			2712.74487,
			2716.03369,
			2717.67969,
			2720.97437,
			2724.27319,
			2727.576,
			2730.88281,
			2732.537,
			2735.849,
			2737.50684,
			2740.82568,
			2742.48633,
			2745.81055,
			2749.13867,
			2750.8042,
			2752.471,
			2755.80664,
			2759.147,
			2762.49121,
			2765.839,
			2767.5144,
			2770.86841,
			2774.22632,
			2775.90674,
			2779.27026,
			2782.63745,
			2786.0083,
			2787.695,
			2791.07153,
			2794.45166,
			2797.836,
			2801.224,
			2802.91943,
			2804.616,
			2808.01147,
			2811.411,
			2814.81348,
			2816.51636,
			2819.92554,
			2821.63135,
			2823.33838,
			2826.75439,
			2828.464,
			2831.88574,
			2835.31079,
			2837.025,
			2838.74,
			2842.17285,
			2843.89063,
			2847.32886,
			2850.77075,
			2854.21582,
			2855.94,
			2857.664,
			2859.38965,
			2862.84326,
			2866.30029,
			2868.03076,
			2871.49341,
			2874.95972,
			2878.42969,
			2881.903,
			2885.38,
			2888.86084,
			2890.60254,
			2894.08887,
			2895.83325,
			2899.32471,
			2902.81934,
			2904.568,
			2908.06714,
			2911.5708,
			2915.078,
			2918.588,
			2922.10132,
			2923.85938,
			2927.37817,
			2929.13867,
			2932.662,
			2936.18921,
			2937.95386,
			2941.48535,
			2945.01953,
			2948.55737,
			2952.099,
			2955.6438,
			2957.41748,
			2960.9668,
			2964.51953,
			2966.29736,
			2969.855,
			2973.41626,
			2975.19775,
			2978.76367,
			2982.33228,
			2984.11743,
			2985.90259,
			2989.46973,
			2993.025,
			2994.79468,
			2998.31323,
			3000.061,
			3003.52734,
			3006.955,
			3010.34351,
			3013.69385,
			3015.35425,
			3018.645,
			3021.89722,
			3025.11035,
			3028.28345,
			3029.85547,
			3032.971,
			3036.048,
			3037.57422,
			3040.59937,
			3043.59717,
			3046.56055,
			3049.49023,
			3050.94043,
			3053.80859,
			3055.22754,
			3058.035,
			3059.42261,
			3062.1687,
			3063.52466,
			3066.20776,
			3067.535,
			3070.16,
			3072.74683,
			3075.29663,
			3076.55713,
			3079.05029,
			3081.506,
			3082.72,
			3085.121,
			3087.4873,
			3089.81982,
			3092.117,
			3093.251,
			3095.49121,
			3097.69385,
			3098.781,
			3100.92676,
			3101.9856,
			3104.07544,
			3106.12842,
			3108.144,
			3109.13843,
			3111.09888,
			3113.02271,
			3113.97046,
			3114.90967,
			3116.76025,
			3117.67261,
			3119.4707,
			3121.234,
			3122.9624,
			3123.813,
			3125.47485,
			3127.1,
			3127.899,
			3129.47,
			3131.00537,
			3132.504,
			3133.9668,
			3134.68652,
			3136.1,
			3137.47852,
			3138.15552,
			3139.48486,
			3140.79028,
			3142.05176,
			3143.279,
			3144.47559,
			3145.62427,
			3146.187,
			3147.293,
			3148.37744,
			3149.44458,
			3149.95728,
			3150.96729,
			3151.4668,
			3152.457,
			3153.44019,
			3153.93042,
			3154.90845,
			3155.874,
			3156.83423,
			3157.792,
			3158.74951,
			3159.22852,
			3160.20044,
			3161.18481,
			3161.67627,
			3162.65869,
			3163.643,
			3164.63135,
			3165.645,
			3166.66846,
			3167.18115,
			3168.209,
			3168.72534,
			3169.7627,
			3170.299,
			3171.42017,
			3172.54639,
			3173.68,
			3174.82324,
			3176.09644,
			3176.75244,
			3178.06787,
			3179.38965,
			3180.05347,
			3181.53125,
			3183.0127,
			3184.49634,
			3186.061,
			3186.87842,
			3188.51074,
			3189.32715,
			3191.00464,
			3191.87964,
			3193.62744,
			3195.37354,
			3196.3125,
			3197.264,
			3199.16187,
			3201.082,
			3203.063,
			3205.03442,
			3206.008,
			3207.91016,
			3209.80054,
			3211.418,
			3212.0957,
			3213.443,
			3214.11377,
			3215.45215,
			3216.56543,
			3217.60913,
			3218.64746,
			3219.68164,
			3220.198,
			3221.23315,
			3222.268,
			3222.7832,
			3223.81055,
			3224.8335,
			3225.344,
			3226.388,
			3227.44556,
			3228.499,
			3229.54077,
			3230.58936,
			3231.11914,
			3231.6626,
			3232.74854,
			3233.30078,
			3234.39258,
			3234.94116,
			3236.04736,
			3236.61914,
			3237.76831,
			3238.92529,
			3240.0918,
			3241.27124,
			3242.46948,
			3243.07275,
			3244.28931,
			3245.51953,
			3246.14038,
			3247.40063,
			3248.03662,
			3249.31982,
			3250.61914,
			3251.27466,
			3252.61914,
			3253.98047,
			3254.6665,
			3256.04956,
			3256.748,
			3258.16064,
			3258.87256,
			3260.30762,
			3261.03052,
			3262.481,
			3263.94238,
			3265.41919,
			3266.9126,
			3268.40674,
			3269.15967,
			3270.67627,
			3272.206,
			3272.972,
			3274.501,
			3276.05737,
			3277.6228,
			3279.19482,
			3279.98584,
			3281.57837,
			3283.17773,
			3284.78418,
			3286.39453,
			3287.19946,
			3288.80566,
			3290.40259,
			3291.19531,
			3292.76733,
			3294.33032,
			3295.89673,
			3296.67139,
			3298.226,
			3299.78418,
			3301.33569,
			3302.10938,
			3303.66235,
			3304.439,
			3305.98633,
			3307.526,
			3309.067,
			3310.59814,
			3312.11938,
			3312.87671,
			3314.38428,
			3315.88135,
			3316.627,
			3318.11328,
			3319.58887,
			3320.32422,
			3326.89355,
			3328.346,
			3329.07935,
			3330.55029,
			3332.02,
			3333.49072,
			3335.017,
			3335.7876,
			3337.328,
			3338.098,
			3339.67017,
			3341.29272,
			3342.91113,
			3343.71973,
			3344.5354,
			3345.36084,
			3347.0083,
			3348.654,
			3350.314,
			3351.14868,
			3352.817,
			3354.48657,
			3356.17676,
			3357.86816,
			3358.715,
			3360.41138,
			3362.10913,
			3362.95947,
			3364.665,
			3366.36035,
			3368.058,
			3369.76343,
			3370.598,
			3372.25635,
			3373.92334,
			3374.7605,
			3376.37671,
			3377.98877,
			3378.79834,
			3380.41382,
			3381.959,
			3382.73486,
			3384.29541,
			3385.85742,
			3386.629,
			3388.95947,
			3390.52856,
			3392.14233,
			3392.95117,
			3394.56665,
			3395.376,
			3397.02148,
			3397.853,
			3399.52368,
			3401.20874,
			3402.063,
			3403.78076,
			3405.51074,
			3407.26367,
			3409.032,
			3410.81226,
			3411.71118,
			3413.52148,
			3415.34351,
			3416.25952,
			3418.103,
			3419.9585,
			3421.823,
			3422.75537,
			3423.69,
			3425.56787,
			3427.44019,
			3429.30273,
			3431.17627,
			3433.03174,
			3433.95581,
			3435.81128,
			3436.74243,
			3438.60962,
			3440.4873,
			3442.37646,
			3444.27734,
			3446.186,
			3447.14331,
			3449.064,
			3450.99414,
			3451.963,
			3453.916,
			3455.88037,
			3457.85181,
			3459.814,
			3461.776,
			3462.75928,
			3464.723,
			3465.71631,
			3467.70947,
			3469.74683,
			3470.771,
			3472.84253,
			3474.94873,
			3477.054,
			3479.19263,
			3481.35,
			3483.51758,
			3484.60425,
			3486.785,
			3487.87842,
			3490.072,
			3492.27441,
			3494.48486,
			3495.59375,
			3497.81738,
			3500.05054,
			3501.171,
			3503.418,
			3505.6748,
			3506.8064,
			3507.94043,
			3510.215,
			3511.35571,
			3513.644,
			3515.94141,
			3517.09326,
			3519.40381,
			3521.72217,
			3524.04956,
			3526.38574,
			3528.73047,
			3531.08447,
			3532.26465,
			3534.632,
			3535.819,
			3538.2002,
			3540.594,
			3541.79688,
			3544.21021,
			3546.65234,
			3549.11035,
			3550.34473,
			3552.83838,
			3555.33936,
			3556.58643,
			3557.83545,
			3559.08643,
			3561.57983,
			3564.065,
			3565.31079,
			3567.78613,
			3570.2666,
			3572.75146,
			3575.24365,
			3576.492,
			3578.99219,
			3581.49561,
			3583.99561,
			3586.484,
			3587.721,
			3590.176,
			3592.60059,
			3594.9895,
			3596.17,
			3597.341,
			3599.65576,
			3601.93237,
			3604.17236,
			3605.27832,
			3607.462,
			3609.61,
			3611.72046,
			3613.795,
			3614.81787,
			3616.83643,
			3618.81836,
			3619.795,
			3621.72,
			3623.609,
			3625.46069,
			3627.27759,
			3628.17432,
			3629.94263,
			3631.67578,
			3632.52832,
			3634.20752,
			3635.84888,
			3636.65552,
			3638.2417,
			3639.792,
			3641.30518,
			3642.782,
			3644.225,
			3644.933,
			3646.32275,
			3647.00562,
			3648.34741,
			3649.66113,
			3650.95459,
			3651.584,
			3652.82251,
			3653.43286,
			3654.64014,
			3655.82813,
			3657.003,
			3658.172,
			3659.32837,
			3660.47656,
			3661.05078,
			3662.2002,
			3662.77539,
			3663.92163,
			3665.04834,
			3666.17285,
			3667.29663,
			3668.42065,
			3669.53052,
			3670.082,
			3671.18262,
			3672.281,
			3672.82983,
			3673.928,
			3675.02881,
			3675.57813,
			3676.67334,
			3677.76733,
			3678.314,
			3679.42285,
			3679.99268,
			3681.129,
			3681.69556,
			3682.82764,
			3683.959,
			3684.5376,
			3685.69971,
			3686.85742,
			3687.4353,
			3688.58984,
			3689.75586,
			3690.937,
			3692.11475,
			3693.29,
			3693.87744,
			3695.07813,
			3695.69458,
			3696.92432,
			3698.15039,
			3699.37622,
			3700.01,
			3701.32,
			3702.62842,
			3703.28247,
			3704.59326,
			3706.00659,
			3707.45166,
			3708.174,
			3709.62036,
			3710.368,
			3711.95923,
			3713.54932,
			3715.1416,
			3716.77832,
			3718.4165,
			3720.05859,
			3721.715,
			3722.54541,
			3724.211,
			3725.04639,
			3726.70825,
			3728.369,
			3730.03687,
			3730.85327,
			3732.38623,
			3733.92578,
			3735.476,
			3736.883,
			3737.57,
			3738.95068,
			3740.34155,
			3741.03955,
			3742.38232,
			3743.73364,
			3745.09644,
			3746.46753,
			3747.14844,
			3748.517,
			3749.89746,
			3750.59229,
			3751.9624,
			3753.30371,
			3753.97754,
			3755.33154,
			3756.69287,
			3758.02539,
			3758.693,
			3760.03223,
			3761.37769,
			3762.74121,
			3763.42773,
			3764.80322,
			3766.18213,
			3767.58,
			3768.29468,
			3769.72534,
			3771.15845,
			3771.87573,
			3773.34375,
			3774.828,
			3775.57031,
			3777.05444,
			3778.55542,
			3780.059,
			3781.56055,
			3782.311,
			3783.82764,
			3785.3457,
			3786.8606,
			3788.378,
			3789.13818,
			3790.65479,
			3791.4126,
			3792.927,
			3794.445,
			3795.96216,
			3796.708,
			3798.222,
			3798.98584,
			3799.73755,
			3801.23877,
			3802.74146,
			3803.496,
			3805.009,
			3806.51074,
			3807.26953,
			3808.79248,
			3809.55786,
			3811.1,
			3811.877,
			3813.44067,
			3815.023,
			3815.82,
			3817.42627,
			3818.235,
			3819.86133,
			3821.501,
			3822.32617,
			3823.98486,
			3825.64844,
			3826.484,
			3828.15552,
			3829.828,
			3831.51123,
			3832.358,
			3834.0603,
			3835.77344,
			3837.499,
			3839.23755,
			3840.11255,
			3841.87256,
			3843.64526,
			3844.538,
			3846.33716,
			3847.2417,
			3849.061,
			3850.87842,
			3852.70654,
			3854.54565,
			3855.46216,
			3857.30469,
			3859.15918,
			3861.019,
			3862.88916,
			3863.829,
			3865.71924,
			3866.66968,
			3868.57959,
			3870.502,
			3872.4375,
			3873.40967,
			3875.363,
			3876.34839,
			3878.32813,
			3880.31958,
			3881.3335,
			3882.35156,
			3884.39673,
			3886.46143,
			3888.54834,
			3889.59619,
			3891.69531,
			3893.794,
			3895.89819,
			3898.01221,
			3899.07349,
			3901.203,
			3903.3418,
			3904.415,
			3906.56836,
			3907.648,
			3909.81421,
			3911.9873,
			3914.16455,
			3915.25684,
			3917.44458,
			3919.63867,
			3921.84375,
			3924.05859,
			3926.28369,
			3927.4043,
			3929.65527,
			3931.91724,
			3934.19775,
			3936.48828,
			3937.63721,
			3939.942,
			3942.25635,
			3943.416,
			3945.74316,
			3948.08057,
			3949.253,
			3951.60547,
			3953.96924,
			3956.34375,
			3958.72949,
			3961.12744,
			3962.33057,
			3964.74463,
			3967.16968,
			3968.3855,
			3970.82568,
			3973.275,
			3974.50317,
			3976.96631,
			3979.43848,
			3981.92017,
			3984.411,
			3985.66016,
			3988.16479,
			3989.42065,
			3991.939,
			3994.46582,
			3997.00146,
			3998.273,
			4000.82129,
			4003.378,
			4005.94336,
			4007.229,
			4009.8064,
			4012.39258,
			4013.68872,
			4016.287,
			4018.88574,
			4021.48926,
			4024.10059,
			4025.40869,
			4028.03076,
			4030.66064,
			4033.29834,
			4034.61987,
			4037.27051,
			4038.599,
			4041.26245,
			4042.59717,
			4045.27222,
			4047.954,
			4050.64355,
			4053.3418,
			4054.6936,
			4057.4043,
			4060.124,
			4062.85156,
			4064.21851,
			4066.958,
			4069.70166,
			4071.07666,
			4073.8335,
			4075.215,
			4077.98438,
			4079.37256,
			4082.15479,
			4084.94873,
			4086.34863,
			4089.15576,
			4091.97217,
			4094.79639,
			4097.62842,
			4099.046,
			4100.463,
			4103.303,
			4104.724,
			4107.56836,
			4110.41943,
			4113.27734,
			4116.142,
			4119.01465,
			4121.892,
			4123.333,
			4126.221,
			4129.1167,
			4132.02,
			4134.931,
			4137.84375,
			4139.30273,
			4142.219,
			4145.139,
			4146.60156,
			4149.53125,
			4151,
			4153.971,
			4156.926,
			4159.86426,
			4162.83057,
			4165.80127,
			4167.28857,
			4170.268,
			4173.254,
			4174.74854,
			4177.74268,
			4180.742,
			4182.244,
			4185.251,
			4188.292,
			4191.291,
			4194.32568,
			4197.367,
			4198.89258,
			4201.948,
			4205.043,
			4206.581,
			4209.66846,
			4211.217,
			4214.32129,
			4217.41,
			4220.56445,
			4223.703,
			4226.85547,
			4228.43359,
			4231.592,
			4234.758,
			4237.94336,
			4239.539,
			4242.74072,
			4245.958,
			4249.19531,
			4252.40625,
			4255.644,
			4257.2583,
			4260.5127,
			4262.11426,
			4265.32031,
			4268.518,
			4271.72656,
			4273.31,
			4276.53467,
			4279.766,
			4282.99268,
			4284.606,
			4287.834,
			4291.063,
			4294.29248,
			4295.908,
			4299.1333,
			4302.3584,
			4305.577,
			4307.1875,
			4310.39844,
			4312.00439,
			4315.212,
			4318.42041,
			4321.63574,
			4323.24463,
			4326.469,
			4329.69775,
			4331.31445,
			4334.55,
			4336.17,
			4339.413,
			4342.65967,
			4345.904,
			4349.137,
			4352.35156,
			4353.948,
			4357.11768,
			4360.25049,
			4363.34668,
			4366.408,
			4367.927,
			4370.939,
			4373.91943,
			4376.865,
			4378.326,
			4381.2207,
			4382.65332,
			4385.491,
			4388.29,
			4389.675,
			4392.416,
			4395.12,
			4396.45752,
			4399.10547,
			4401.715,
			4403.006,
			4405.55957,
			4406.82227,
			4409.31934,
			4411.779,
			4414.201,
			4415.39941,
			4417.77,
			4418.94238,
			4421.26172,
			4423.544,
			4425.788,
			4426.89648,
			4430.16357,
			4431.23438,
			4433.347,
			4434.39,
			4436.44775,
			4438.46875,
			4440.453,
			4442.40137,
			4443.362,
			4445.255,
			4447.1123,
			4448.93652,
			4449.836,
			4451.611,
			4453.368,
			4455.06934,
			4456.7334,
			4457.553,
			4459.168,
			4460.738,
			4461.51074,
			4463.03125,
			4464.52051,
			4465.97461,
			4467.39648,
			4468.095,
			4469.47168,
			4470.817,
			4472.134,
			4472.783,
			4474.064,
			4474.69727,
			4475.969,
			4477.22754,
			4478.47363,
			4479.093,
			4479.71045,
			4480.96729,
			4481.59961,
			4482.86328,
			4484.126,
			4484.758,
			4486.034,
			4487.32373,
			4488.617,
			4489.917,
			4490.57129,
			4491.89648,
			4493.22656,
			4493.894,
			4495.23047,
			4496.591,
			4497.9624,
			4498.64844,
			4500.025,
			4501.40234,
			4502.77148,
			4504.143,
			4504.83,
			4506.2085,
			4506.89844,
			4508.276,
			4509.64941,
			4510.335,
			4511.702,
			4512.38525,
			4513.745,
			4514.41943,
			4515.09,
			4516.42139,
			4517.74658,
			4519.07129,
			4519.72949,
			4521.04,
			4522.34473,
			4523,
			4524.34668,
			4525.683,
			4527.01074,
			4528.337,
			4529.682,
			4531.01465,
			4531.67725,
			4532.996,
			4533.656,
			4534.98145,
			4535.64,
			4536.95166,
			4538.257,
			4539.566,
			4540.22266,
			4541.532,
			4542.18555,
			4543.48926,
			4544.14063,
			4545.461,
			4546.78467,
			4548.107,
			4548.768,
			4549.429,
			4550.7417,
			4552.04834,
			4553.35547,
			4554.66455,
			4555.975,
			4557.284,
			4557.939,
			4559.253,
			4560.571,
			4561.878,
			4562.53125,
			4563.839,
			4565.14844,
			4566.465,
			4567.784,
			4568.44238,
			4569.759,
			4570.416,
			4571.73926,
			4573.06836,
			4574.3916,
			4575.052,
			4576.368,
			4577.72559,
			4578.40625,
			4579.76025,
			4580.43359,
			4581.775,
			4582.45361,
			4583.825,
			4584.50635,
			4585.85938,
			4587.204,
			4588.555,
			4589.89941,
			4590.56738,
			4591.89941,
			4592.56445,
			4593.88,
			4595.184,
			4596.4834,
			4597.78027,
			4598.42871,
			4599.72949,
			4601.02637,
			4602.322,
			4603.618,
			4604.25537,
			4605.52539,
			4606.796,
			4608.06934,
			4609.343,
			4609.971,
			4611.23047,
			4611.86133,
			4613.12744,
			4614.39648,
			4615.63574,
			4616.881,
			4617.506,
			4618.763,
			4619.99561,
			4621.211,
			4622.43457,
			4623.05029,
			4624.29053,
			4624.88867,
			4626.08252,
			4627.287,
			4627.893,
			4629.116,
			4629.73242,
			4630.35352,
			4631.603,
			4632.23242,
			4633.50146,
			4634.78564,
			4636.11035,
			4637.458,
			4638.137,
			4639.506,
			4640.894,
			4641.60156,
			4643.027,
			4644.467,
			4645.193,
			4646.66748,
			4647.412,
			4648.9126,
			4650.429,
			4651.964,
			4653.51563,
			4655.082,
			4656.66455,
			4657.46,
			4659.0625,
			4659.86963,
			4661.47949,
			4663.113,
			4663.93457,
			4665.589,
			4666.421,
			4668.089,
			4668.927,
			4669.765,
			4671.44238,
			4673.11572,
			4674.781,
			4676.438,
			4677.27051,
			4678.94434,
			4680.596,
			4681.42,
			4683.07471,
			4684.7207,
			4686.35742,
			4687.994,
			4689.626,
			4690.44,
			4692.06641,
			4693.69775,
			4694.5127,
			4696.1416,
			4697.775,
			4699.42236,
			4701.068,
			4702.713,
			4703.542,
			4705.198,
			4706.85254,
			4707.679,
			4709.34668,
			4711.0166,
			4711.85156,
			4713.51953,
			4714.37158,
			4716.078,
			4717.783,
			4719.497,
			4721.22852,
			4722.96045,
			4723.827,
			4725.57666,
			4726.45459,
			4728.213,
			4729.978,
			4731.7583,
			4733.543,
			4735.335,
			4737.13574,
			4738.039,
			4739.85,
			4740.757,
			4742.56738,
			4744.386,
			4746.209,
			4748.006,
			4748.9043,
			4750.699,
			4752.477,
			4754.251,
			4756.0376,
			4756.936,
			4758.753,
			4759.6665,
			4761.501,
			4763.353,
			4764.287,
			4766.16064,
			4768.043,
			4769.924,
			4771.812,
			4772.7583,
			4774.652,
			4776.549,
			4777.499,
			4779.39355,
			4781.28,
			4783.168,
			4785.052,
			4786.93164,
			4787.872,
			4788.8125,
			4790.67871,
			4792.53857,
			4794.39746,
			4795.32,
			4797.15625,
			4798.98975,
			4800.815,
			4801.71875,
			4803.52637,
			4805.33545,
			4807.129,
			4808.92,
			4809.81738,
			4811.616,
			4813.42236,
			4814.328,
			4816.148,
			4818.001,
			4819.868,
			4821.748,
			4823.65625,
			4824.61572,
			4826.54346,
			4827.51563,
			4829.45752,
			4831.42725,
			4833.414,
			4835.418,
			4836.42432,
			4837.433,
			4839.46143,
			4841.501,
			4843.548,
			4845.59766,
			4847.658,
			4848.693,
			4850.76953,
			4852.85645,
			4854.949,
			4857.05664,
			4858.085,
			4859.1333,
			4861.2373,
			4862.29346,
			4864.412,
			4866.54,
			4868.67773,
			4870.823,
			4872.99365,
			4874.073,
			4876.221,
			4878.39648,
			4880.59766,
			4882.77,
			4883.868,
			4886.07227,
			4888.284,
			4890.507,
			4891.62158,
			4893.858,
			4896.104,
			4897.231,
			4899.489,
			4900.62158,
			4902.894,
			4904.03271,
			4906.318,
			4908.61328,
			4909.76465,
			4912.075,
			4913.23438,
			4915.562,
			4917.89941,
			4920.24756,
			4922.606,
			4924.975,
			4927.35547,
			4928.549,
			4929.745,
			4932.14355,
			4934.552,
			4935.759,
			4938.17969,
			4940.60938,
			4943.04736,
			4945.49463,
			4947.953,
			4950.42041,
			4951.65771,
			4954.13867,
			4956.62939,
			4957.878,
			4960.382,
			4962.89453,
			4964.155,
			4966.681,
			4969.215,
			4971.75732,
			4974.30664,
			4976.8584,
			4979.408,
			4980.67969,
			4983.212,
			4984.4707,
			4986.96826,
			4989.435,
			4990.65527,
			4993.06836,
			4995.443,
			4996.616,
			4998.935,
			5001.215,
			5003.4585,
			5005.66357,
			5007.832,
			5008.903,
			5011.01855,
			5013.09863,
			5015.14258,
			5016.151,
			5018.14,
			5020.09326,
			5022.01,
			5023.891,
			5024.818,
			5026.64746,
			5027.55029,
			5029.332,
			5031.081,
			5031.94336,
			5033.64063,
			5035.303,
			5036.944,
			5037.746,
			5039.333,
			5040.89258,
			5042.43164,
			5043.96,
			5045.47852,
			5046.23242,
			5047.73926,
			5049.247,
			5050.002,
			5051.506,
			5053.00928,
			5054.51367,
			5056.01758,
			5057.51855,
			5058.269,
			5059.77051,
			5061.28027,
			5062.036,
			5063.547,
			5065.05664,
			5066.58838,
			5067.355,
			5068.12158,
			5069.65332,
			5071.19971,
			5072.76367,
			5074.325,
			5075.886,
			5076.6875,
			5078.29248,
			5079.895,
			5081.501,
			5083.14941,
			5083.97266,
			5085.617,
			5087.255,
			5088.06934,
			5089.69727,
			5090.51025,
			5092.13135,
			5093.74072,
			5094.545,
			5096.155,
			5097.78955,
			5099.45,
			5101.114,
			5102.799,
			5103.66748,
			5105.40625,
			5107.15039,
			5108.03027,
			5109.82,
			5110.716,
			5112.511,
			5114.325,
			5116.15039,
			5117.97949,
			5119.762,
			5121.53125,
			5122.417,
			5124.17871,
			5125.911,
			5126.77734,
			5128.511,
			5130.28174,
			5132.07031,
			5133.85938,
			5134.75049,
			5136.528,
			5138.304,
			5139.192,
			5140.95361,
			5142.709,
			5144.463,
			5146.155,
			5146.99756,
			5147.839,
			5149.52148,
			5150.354,
			5151.99854,
			5153.63672,
			5155.271,
			5156.082,
			5156.891,
			5158.506,
			5160.118,
			5160.92139,
			5162.507,
			5164.09,
			5165.672,
			5167.214,
			5167.977,
			5169.501,
			5171.023,
			5171.778,
			5173.284,
			5174.037,
			5175.545,
			5176.30029,
			5177.83,
			5179.36426,
			5180.133,
			5181.67432,
			5182.45264,
			5184.019,
			5185.59033,
			5186.379,
			5187.974,
			5189.60059,
			5190.417,
			5192.05664,
			5192.88428,
			5194.559,
			5196.245,
			5197.09375,
			5198.80762,
			5199.671,
			5201.408,
			5203.162,
			5204.941,
			5205.835,
			5206.73242,
			5208.5376,
			5210.342,
			5212.174,
			5213.09473,
			5214.94531,
			5215.876,
			5216.80957,
			5218.687,
			5220.593,
			5221.527,
			5223.43652,
			5224.39648,
			5225.35938,
			5227.29346,
			5228.26465,
			5230.217,
			5232.18066,
			5234.156,
			5236.137,
			5238.128,
			5239.129,
			5241.13574,
			5242.14355,
			5244.16748,
			5246.205,
			5247.22852,
			5249.28369,
			5250.31641,
			5252.39063,
			5253.432,
			5255.526,
			5256.581,
			5258.69,
			5260.801,
			5262.92627,
			5265.0625,
			5267.19434,
			5268.26172,
			5269.332,
			5271.478,
			5273.629,
			5275.78955,
			5277.96045,
			5279.05,
			5281.23535,
			5283.43164,
			5284.533,
			5286.74365,
			5288.966,
			5291.19727,
			5293.43848,
			5295.689,
			5297.949,
			5299.0835,
			5301.3584,
			5302.5,
			5304.789,
			5305.937,
			5308.241,
			5310.556,
			5312.88037,
			5315.215,
			5317.558,
			5319.912,
			5321.09229,
			5323.46,
			5325.8374,
			5327.03,
			5329.42,
			5331.81836,
			5334.22559,
			5336.64258,
			5339.06934,
			5340.28564,
			5342.72559,
			5345.174,
			5346.40137,
			5348.86328,
			5351.34375,
			5352.5874,
			5356.32471,
			5357.573,
			5360.07666,
			5362.58838,
			5365.1084,
			5366.369,
			5368.89648,
			5368.943
		],
		[
			0,
			0.03010559,
			0.060256958,
			0.106506348,
			0.147766113,
			0.194030762,
			0.225311279,
			0.287078857,
			0.33291626,
			0.364120483,
			0.408309937,
			0.4555664,
			0.503356934,
			0.5486603,
			0.5956116,
			0.6396942,
			0.6729126,
			0.7166748,
			0.763687134,
			0.8092499,
			0.855011,
			0.903305054,
			0.948104858,
			0.995605469,
			1.02711487,
			1.07185364,
			1.11865234,
			1.16416931,
			1.21095276,
			1.25602722,
			1.287735,
			1.33158875,
			1.37966919,
			1.4251709,
			1.455307,
			1.50109863,
			1.54808044,
			1.5932312,
			1.63996887,
			1.68424988,
			1.71546936,
			1.76156616,
			1.806015,
			1.83772278,
			1.8835144,
			1.93032837,
			1.97613525,
			2.02238464,
			2.06867981,
			2.11448669,
			2.16148376,
			2.20704651,
			2.25534058,
			2.301651,
			2.32987976,
			2.376175,
			2.42396545,
			2.47021484,
			2.51400757,
			2.56030273,
			2.59048462,
			2.62216187,
			2.66795349,
			2.70066833,
			2.74594116,
			2.793274,
			2.83752441,
			2.85261536,
			2.89945984,
			2.943161,
			2.98890686,
			3.036209,
			3.08300781,
			3.11473083,
			3.16052246,
			3.20579529,
			3.25257874,
			3.29898071,
			3.32974243,
			3.37538147,
			3.42114258,
			3.469452,
			3.51475525,
			3.56056213,
			3.606842,
			3.63801575,
			3.68479919,
			3.73268127,
			3.77690125,
			3.807602,
			3.85539246,
			3.88760376,
			3.93441772,
			3.97935486,
			4.02296448,
			4.07026672,
			4.11755371,
			4.14842224,
			4.194092,
			4.22271729,
			4.271042,
			4.31729126,
			4.36112976,
			4.40739441,
			4.43907166,
			4.486389,
			4.531769,
			4.576462,
			4.62324524,
			4.669998,
			4.70068359,
			4.74665833,
			4.775696,
			4.82244873,
			4.870758,
			4.91552734,
			4.932678,
			4.97909546,
			5.025223,
			5.070984,
			5.117264,
			5.161606,
			5.194336,
			5.224472,
			5.27171326,
			5.316986,
			5.363495,
			5.41012573,
			5.455887,
			5.503662,
			5.548477,
			5.580658,
			5.62542725,
			5.65710449,
			5.70387268,
			5.765335,
			5.810135,
			5.857788,
			5.90356445,
			5.93582153,
			5.9805603,
			6.027359,
			6.07409668,
			6.11988831,
			6.165741,
			6.21452332,
			6.243683,
			6.291443,
			6.337723,
			6.38352966,
			6.42894,
			6.47608948,
			6.505234,
			6.55302429,
			6.58270264,
			6.63156128,
			6.67527771,
			6.72254944,
			6.768326,
			6.81460571,
			6.861435,
			6.90869141,
			6.95599365,
			7.00074768,
			7.030945,
			7.07733154,
			7.121994,
			7.168808,
			7.218567,
			7.26441956,
			7.294571,
			7.33929443,
			7.37049866,
			7.41777039,
			7.464615,
			7.508316,
			7.542053,
			7.554611,
			7.60290527,
			7.64820862,
			7.694687,
			7.722641,
			7.768921,
			7.801605,
			7.862152,
			7.90979,
			7.95603943,
			7.999344,
			8.048111,
			8.076263,
			8.108475,
			8.152725,
			8.200073,
			8.246872,
			8.293121,
			8.339844,
			8.37155151,
			8.41835,
			8.463638,
			8.50740051,
			8.5546875,
			8.60199,
			8.63472,
			8.681229,
			8.727814,
			8.77302551,
			8.81983948,
			8.851517,
			8.898499,
			8.944229,
			8.991959,
			9.038193,
			9.081467,
			9.11329651,
			9.15947,
			9.206238,
			9.403931,
			9.448227,
			9.494629,
			9.541824,
			9.57399,
			9.619278,
			9.664795,
			9.710358,
			9.756226,
			9.802368,
			9.847656,
			9.89296,
			9.923111,
			9.969879,
			10.0010681,
			10.0473938,
			10.0791016,
			10.1243744,
			10.1711273,
			10.2345428,
			10.280304,
			10.3276367,
			10.3733521,
			10.4191437,
			10.4488373,
			10.4810638,
			10.5263367,
			10.5736237,
			10.6199341,
			10.6647949,
			10.7110443,
			10.7578125,
			10.8061066,
			10.8498993,
			10.8947449,
			10.94046,
			10.9721527,
			11.0195465,
			11.0653229,
			11.1101837,
			11.156311,
			11.2046356,
			11.234314,
			11.2646027,
			11.3119507,
			11.354599,
			11.4019318,
			11.4497375,
			11.4951782,
			11.540329,
			11.5845947,
			11.6303864,
			11.67775,
			11.7229614,
			11.7551727,
			11.802475,
			11.8477936,
			11.89209,
			11.9388733,
			11.9851837,
			12.01532,
			12.0617981,
			12.0918121,
			12.13858,
			12.1843872,
			12.231308,
			12.2760315,
			12.3077087,
			12.3539886,
			12.3846893,
			12.4305115,
			12.4763489,
			12.5220947,
			12.5714111,
			12.6177216,
			12.66301,
			12.7088318,
			12.7561188,
			12.8024292,
			12.8469238,
			12.8940582,
			12.93779,
			12.98761,
			13.033493,
			13.0636139,
			13.1073761,
			13.1551666,
			13.2014771,
			13.2472839,
			13.2930908,
			13.3227386,
			13.3725586,
			13.4168549,
			13.4632721,
			13.5099945,
			13.5552521,
			13.5859528,
			13.61615,
			13.6630859,
			13.7104492,
			13.7545471,
			13.8028564,
			13.8481293,
			13.8939819,
			13.9402466,
			13.9729309,
			14.0177155,
			14.0635529,
			14.1120605,
			14.1576538,
			14.2039185,
			14.2472076,
			14.2940674,
			14.340332,
			14.3694916,
			14.4158173,
			14.4632568,
			14.5094452,
			14.557251,
			14.6029968,
			14.6317749,
			14.6785889,
			14.7253265,
			14.7565155,
			14.8027954,
			14.8471069,
			14.8934326,
			14.9396667,
			14.9703827,
			15.0166321,
			15.0650482,
			15.1097565,
			15.1560059,
			15.2012634,
			15.2490387,
			15.2773285,
			15.3239594,
			15.3727264,
			15.4199829,
			15.4648132,
			15.5095215,
			15.5583038,
			15.6035309,
			15.6502991,
			15.680481,
			15.72374,
			15.7705231,
			15.8167725,
			15.8642731,
			15.91037,
			15.9394989,
			15.9877625,
			16.0335388,
			16.0803375,
			16.1265869,
			16.1738586,
			16.2045441,
			16.24881,
			16.2941437,
			16.34137,
			16.3715363,
			16.4178162,
			16.4638062,
			16.494812,
			16.5415649,
			16.5878143,
			16.634613,
			16.6790924,
			16.72258,
			16.77237,
			16.8171234,
			16.86354,
			16.9097137,
			16.9539642,
			16.9856415,
			17.030426,
			17.0716248,
			17.11737,
			17.1642456,
			17.21103,
			17.2401276,
			17.271759,
			17.3185272,
			17.34871,
			17.3934937,
			17.439743,
			17.4719086,
			17.50409,
			17.5473785,
			17.5791016,
			17.62532,
			17.65599,
			17.7032471,
			17.7480011,
			17.8093872,
			17.85817,
			17.90393,
			17.9501953,
			17.9974976,
			18.0272064,
			18.0735321,
			18.1177826,
			18.16513,
			18.2093658,
			18.2556,
			18.2857819,
			18.3311157,
			18.37709,
			18.4261627,
			18.47136,
			18.51712,
			18.547821,
			18.5936737,
			18.6393738,
			18.686142,
			18.7315826,
			18.7767181,
			18.8099365,
			18.8531342,
			18.8995056,
			18.9326782,
			18.9784088,
			19.0246429,
			19.0699,
			19.11621,
			19.1631622,
			19.2093048,
			19.2555542,
			19.3018341,
			19.3345337,
			19.3642426,
			19.4102631,
			19.4568024,
			19.5040741,
			19.5518646,
			19.5830231,
			19.6290588,
			19.67508,
			19.7213287,
			19.7680817,
			19.8143921,
			19.8430939,
			19.8769379,
			19.921524,
			19.9693146,
			20.0155945,
			20.0603943,
			20.1061249,
			20.1529236,
			20.1987762,
			20.2430115,
			20.2913055,
			20.33606,
			20.366333,
			20.4125519,
			20.4573364,
			20.5036011,
			20.5503845,
			20.5971832,
			20.6259155,
			20.6711273,
			20.7164,
			20.76323,
			20.809021,
			20.8391571,
			20.88797,
			20.9332733,
			20.966095,
			21.0123444,
			21.0590973,
			21.1038513,
			21.1500854,
			21.197113,
			21.2302246,
			21.2748718,
			21.3231659,
			21.3674469,
			21.4105682,
			21.4590149,
			21.5062866,
			21.5510559,
			21.5973511,
			21.6270142,
			21.6728058,
			21.7049561,
			21.7507782,
			21.7962189,
			21.82843,
			21.8730469,
			21.92035,
			21.9822388,
			22.0270386,
			22.0607452,
			22.1075134,
			22.1532745,
			22.1986237,
			22.2460785,
			22.2906189,
			22.33841,
			22.3827057,
			22.4299927,
			22.46019,
			22.504425,
			22.5532379,
			22.5975342,
			22.6444855,
			22.6906281,
			22.721817,
			22.7675934,
			22.815033,
			22.86023,
			22.9075012,
			22.952774,
			22.9829712,
			23.0309143,
			23.0605164,
			23.1062622,
			23.1525574,
			23.1974487,
			23.24266,
			23.2733459,
			23.3176575,
			23.36499,
			23.3966217,
			23.4434052,
			23.4886932,
			23.5042877,
			23.55008,
			23.5973969,
			23.6276245,
			23.6734,
			23.72017,
			23.7674713,
			23.8129272,
			23.8580475,
			23.9058533,
			23.95015,
			23.9944458,
			24.0261536,
			24.0759888,
			24.1227264,
			24.1695251,
			24.198288,
			24.2470245,
			24.2757416,
			24.322998,
			24.3708038,
			24.4156036,
			24.4614258,
			24.5071564,
			24.5564423,
			24.600708,
			24.6468353,
			24.692276,
			24.72194,
			24.7692261,
			24.81549,
			24.8607941,
			24.9085541,
			24.9537964,
			24.9844971,
			25.02829,
			25.0755615,
			25.122818,
			25.16861,
			25.2149048,
			25.25917,
			25.3064575,
			25.3386383,
			25.3838654,
			25.417572,
			25.4633484,
			25.5102234,
			25.556366,
			25.60019,
			25.6484222,
			25.6795349,
			25.7248688,
			25.7711334,
			25.81491,
			25.8607483,
			25.9074554,
			25.9557343,
			25.99855,
			26.04692,
			26.0935211,
			26.1226654,
			26.1684418,
			26.2142334,
			26.2605133,
			26.306778,
			26.3540649,
			26.383255,
			26.4301758,
			26.4775085,
			26.5064545,
			26.55574,
			26.601532,
			26.6322784,
			26.6483,
			26.6802368,
			26.724762,
			26.77356,
			26.81929,
			26.8651428,
			26.908844,
			26.9586334,
			27.00438,
			27.0481262,
			27.0961,
			27.1406555,
			27.1728668,
			27.2040863,
			27.25035,
			27.2972412,
			27.3424225,
			27.38916,
			27.4369354,
			27.4832153,
			27.52919,
			27.56018,
			27.6074524,
			27.6526947,
			27.6990814,
			27.747757,
			27.7930145,
			27.8216858,
			27.8679657,
			27.9152527,
			27.960556,
			28.0093231,
			28.0535736,
			28.0994568,
			28.1464539,
			28.1914368,
			28.22409,
			28.267334,
			28.2996063,
			28.3466034,
			28.3930969,
			28.4373169,
			28.4845886,
			28.5305176,
			28.5773773,
			28.6234589,
			28.6687164,
			28.7140045,
			28.7442627,
			28.7914734,
			28.8367,
			28.8819885,
			28.9284363,
			28.9760742,
			29.0072327,
			29.052475,
			29.1012726,
			29.1435242,
			29.1918182,
			29.2224731,
			29.25264,
			29.3004456,
			29.3327026,
			29.3791351,
			29.4252777,
			29.4705811,
			29.5193634,
			29.5631714,
			29.6104889,
			29.6552429,
			29.7050629,
			29.75035,
			29.7810822,
			29.8263855,
			29.8726349,
			29.9199524,
			29.9672546,
			30.0123138,
			30.0432587,
			30.0900269,
			30.1358337,
			30.18164,
			30.2254333,
			30.2722015,
			30.3049164,
			30.34822,
			30.3966522,
			30.4403229,
			30.473526,
			30.5193024,
			30.5672455,
			30.61444,
			30.6587372,
			30.70604,
			30.73671,
			30.7810211,
			30.8125763,
			30.8253021,
			30.87259,
			30.9188843,
			30.95259,
			30.9984741,
			31.0276184,
			31.0738678,
			31.1362762,
			31.1820831,
			31.2280579,
			31.2731781,
			31.319931,
			31.3501282,
			31.3956,
			31.4417267,
			31.4890289,
			31.5353241,
			31.58162,
			31.6131439,
			31.6586,
			31.70639,
			31.7501984,
			31.7975,
			31.84282,
			31.87297,
			31.9192657,
			31.966629,
			32.0104065,
			32.0587,
			32.1044464,
			32.13318,
			32.1796265,
			32.2252655,
			32.2715149,
			32.3158264,
			32.3643341,
			32.3938446,
			32.4406281,
			32.4864044,
			32.516098,
			32.5636,
			32.59311,
			32.6378632,
			32.65448,
			32.69983,
			32.74759,
			32.79187,
			32.8381653,
			32.88344,
			32.9161835,
			32.96257,
			33.0077667,
			33.0535278,
			33.0999,
			33.1326141,
			33.17685,
			33.22516,
			33.2704468,
			33.31723,
			33.3642426,
			33.4103546,
			33.4399872,
			33.4726868,
			33.5189819,
			33.56537,
			33.60907,
			33.6564026,
			33.7041626,
			33.75,
			33.78035,
			33.8173981,
			33.86122,
			33.907486,
			33.9537659,
			34.0015564,
			34.0483551,
			34.0936279,
			34.1398621,
			34.18309,
			34.2158051,
			34.2601,
			34.3088531,
			34.35562,
			34.39946,
			34.444397,
			34.4769745,
			34.50702,
			34.5552673,
			34.6015472,
			34.63182,
			34.677597,
			34.7227631,
			34.78563,
			34.8309479,
			34.876236,
			34.9224548,
			34.9687347,
			34.9984741,
			35.0460358,
			35.08893,
			35.13672,
			35.18347,
			35.23027,
			35.2604675,
			35.29216,
			35.3373871,
			35.3836823,
			35.4281921,
			35.4742279,
			35.5195,
			35.5668335,
			35.6107635,
			35.65834,
			35.70613,
			35.7529144,
			35.7836151,
			35.8306122,
			35.85858,
			35.90686,
			35.95262,
			35.9989624,
			36.0436554,
			36.09044,
			36.1372223,
			36.1815033,
			36.22728,
			36.2604828,
			36.3062439,
			36.3524933,
			36.3973846,
			36.4430542,
			36.48828,
			36.5204773,
			36.56633,
			36.614563,
			36.6578064,
			36.7060852,
			36.7508545,
			36.79712,
			36.8267975,
			36.8730469,
			36.9052277,
			36.95099,
			36.9975128,
			37.0269775,
			37.0732422,
			37.13707,
			37.18335,
			37.22661,
			37.2744,
			37.32016,
			37.3513336,
			37.3966522,
			37.4445343,
			37.4896851,
			37.5349579,
			37.56816,
			37.6126862,
			37.6582031,
			37.7044373,
			37.7502,
			37.79399,
			37.84027,
			37.87143,
			37.9016266,
			37.9479065,
			37.9801331,
			38.0253754,
			38.0570679,
			38.1028137,
			38.13205,
			38.1799164,
			38.22554,
			38.2556763,
			38.30197,
			38.34726,
			38.37935,
			38.39482,
			38.4403229,
			38.4719849,
			38.51773,
			38.56154,
			38.6102753,
			38.6545258,
			38.70128,
			38.7310333,
			38.7768555,
			38.8224945,
			38.85472,
			38.9000854,
			38.9603577,
			39.00766,
			39.0559235,
			39.09973,
			39.1466675,
			39.1767273,
			39.2229,
			39.2701721,
			39.3144379,
			39.3596649,
			39.40799,
			39.4391022,
			39.484375,
			39.5145874,
			39.5603943,
			39.60869,
			39.655426,
			39.7017059,
			39.7464752,
			39.79428,
			39.8229065,
			39.8701935,
			39.9164429,
			39.96399,
			40.01004,
			40.04065,
			40.08641,
			40.1337128,
			40.1805267,
			40.22328,
			40.2700348,
			40.31781,
			40.3632965,
			40.40837,
			40.4530945,
			40.4842834,
			40.53116,
			40.57733,
			40.60811,
			40.6528473,
			40.7006531,
			40.7466278,
			40.7922668,
			40.83795,
			40.8857574,
			40.9169464,
			40.9612732,
			41.0107727,
			41.0558,
			41.1027069,
			41.1474,
			41.1941833,
			41.24193,
			41.27159,
			41.31685,
			41.36383,
			41.4104767,
			41.45517,
			41.5024567,
			41.5327,
			41.58046,
			41.6242371,
			41.67096,
			41.71724,
			41.76384,
			41.7963562,
			41.8425,
			41.8903046,
			41.92047,
			41.9642334,
			42.01198,
			42.057373,
			42.1036835,
			42.1499634,
			42.1969147,
			42.25357,
			42.2994232,
			42.36203,
			42.4059,
			42.45569,
			42.50197,
			42.54773,
			42.57698,
			42.608078,
			42.6548462,
			42.7001648,
			42.7478943,
			42.7931366,
			42.838913,
			42.88568,
			42.93045,
			42.9762421,
			43.0219879,
			43.06978,
			43.10196,
			43.14824,
			43.194046,
			43.24031,
			43.2840576,
			43.33142,
			43.3611145,
			43.408844,
			43.45256,
			43.4994049,
			43.5440826,
			43.5888519,
			43.62053,
			43.6517029,
			43.6985626,
			43.74579,
			43.792038,
			43.83676,
			43.8689575,
			43.88205,
			43.9290161,
			43.9750671,
			44.0207977,
			44.06807,
			44.1143341,
			44.144165,
			44.1913147,
			44.2375641,
			44.26776,
			44.3145752,
			44.36032,
			44.4050446,
			44.4372559,
			44.48352,
			44.52983,
			44.57608,
			44.6228333,
			44.6675873,
			44.7168732,
			44.76013,
			44.80893,
			44.85466,
			44.9009552,
			44.9312134,
			44.9753876,
			45.0221558,
			45.06891,
			45.0991974,
			45.1465149,
			45.192688,
			45.236908,
			45.28618,
			45.33203,
			45.37628,
			45.4234467,
			45.45462,
			45.5019073,
			45.53215,
			45.5769348,
			45.6096039,
			45.65428,
			45.7035522,
			45.7614441,
			45.79593,
			45.8403473,
			45.8881531,
			45.93393,
			45.9807129,
			46.0254669,
			46.057663,
			46.1039124,
			46.14966,
			46.1969757,
			46.2402344,
			46.2865,
			46.3342743,
			46.3810577,
			46.42531,
			46.47307,
			46.50226,
			46.5480347,
			46.5812225,
			46.6260223,
			46.67325,
			46.717514,
			46.7663574,
			46.8100739,
			46.8583527,
			46.8890076,
			46.9347534,
			46.9812164,
			47.01294,
			47.0279846,
			47.0725555,
			47.10373,
			47.1504974,
			47.1969,
			47.2425537,
			47.28929,
			47.3345337,
			47.3808136,
			47.4104767,
			47.45926,
			47.5044861,
			47.53665,
			47.5971527,
			47.64229,
			47.6890259,
			47.7342834,
			47.7641754,
			47.81053,
			47.855957,
			47.88765,
			47.93347,
			47.9651642,
			48.01036,
			48.05864,
			48.0737152,
			48.1174469,
			48.16478,
			48.210495,
			48.25824,
			48.3035126,
			48.33368,
			48.3798981,
			48.42581,
			48.457428,
			48.50363,
			48.54808,
			48.5806274,
			48.5947723,
			48.6404877,
			48.6867828,
			48.7335663,
			48.7639465,
			48.8099823,
			48.8557129,
			48.90251,
			48.931366,
			48.97647,
			49.00766,
			49.0554352,
			49.1022034,
			49.1632538,
			49.2093658,
			49.2555847,
			49.3034973,
			49.3486633,
			49.3771667,
			49.4256134,
			49.47136,
			49.51915,
			49.5636139,
			49.60817,
			49.63983,
			49.6846,
			49.73044,
			49.7610931,
			49.80635,
			49.85263,
			49.8985,
			49.942215,
			49.9915161,
			50.0383,
			50.08354,
			50.1263123,
			50.1589966,
			50.2047577,
			50.2505951,
			50.29431,
			50.34262,
			50.3904266,
			50.42108,
			50.46599,
			50.4965363,
			50.5328064,
			50.5765228,
			50.60971,
			50.6539764,
			50.670578,
			50.7153473,
			50.7601,
			50.8093872,
			50.8561859,
			50.9019623,
			50.9322357,
			50.97931,
			51.0257874,
			51.0715027,
			51.1177826,
			51.16278,
			51.19377,
			51.2400665,
			51.2863159,
			51.31749,
			51.3638,
			51.4113464,
			51.4548035,
			51.5010223,
			51.5478973,
			51.59482,
			51.6398468,
			51.68863,
			51.7193146,
			51.7646942,
			51.8104553,
			51.85611,
			51.901886,
			51.9476624,
			51.9803925,
			52.02568,
			52.0708923,
			52.1177063,
			52.1634979,
			52.2087555,
			52.24095,
			52.28569,
			52.3309937,
			52.3770752,
			52.4100037,
			52.4552,
			52.50351,
			52.5478058,
			52.5805054,
			52.6252747,
			52.6700134,
			52.7168121,
			52.7616425,
			52.8078766,
			52.85617,
			52.8994751,
			52.93216,
			52.9753876,
			53.00804,
			53.02162,
			53.0688934,
			53.1146545,
			53.1599426,
			53.2102661,
			53.2554932,
			53.2861481,
			53.3329926,
			53.3636169,
			53.41104,
			53.4561462,
			53.50139,
			53.54767,
			53.59291,
			53.63864,
			53.6833954,
			53.73082,
			53.7775574,
			53.8071,
			53.85588,
			53.90114,
			53.94896,
			53.9921875,
			54.0419769,
			54.0726166,
			54.11685,
			54.16388,
			54.21054,
			54.25566,
			54.28885,
			54.33412,
			54.3809357,
			54.4277344,
			54.473465,
			54.5041962,
			54.54947,
			54.580658,
			54.5973969,
			54.64302,
			54.69081,
			54.7350464,
			54.7813263,
			54.8271027,
			54.8567963,
			54.88745,
			54.93422,
			54.9805145,
			55.0124359,
			55.0590057,
			55.103714,
			55.119812,
			55.166687,
			55.21199,
			55.2586823,
			55.30391,
			55.3527374,
			55.3834229,
			55.4313049,
			55.4749756,
			55.50717,
			55.55443,
			55.5861359,
			55.6303864,
			55.6476135,
			55.69124,
			55.7374878,
			55.7847748,
			55.8300629,
			55.8753357,
			55.9075,
			55.95276,
			55.9981842,
			56.02826,
			56.07602,
			56.1222534,
			56.1661072,
			56.21431,
			56.2585754,
			56.30533,
			56.3526,
			56.39688,
			56.42607,
			56.4753876,
			56.52063,
			56.5684052,
			56.6147,
			56.6604767,
			56.69165,
			56.7394257,
			56.77112,
			56.8173981,
			56.86528,
			56.9094849,
			56.9582977,
			57.0040436,
			57.04782,
			57.09488,
			57.14038,
			57.186142,
			57.2173157,
			57.26436,
			57.30887,
			57.3551331,
			57.38881,
			57.4340668,
			57.4815369,
			57.50905,
			57.5562744,
			57.6029968,
			57.64827,
			57.6941376,
			57.7403259,
			57.7876129,
			57.8349152,
			57.86438,
			57.90988,
			57.9561768,
			57.9868469,
			58.00244,
			58.0497437,
			58.0965576,
			58.1433563,
			58.1886139,
			58.2359161,
			58.2676239,
			58.31357,
			58.3592529,
			58.40805,
			58.4548035,
			58.4986725,
			58.53093,
			58.57611,
			58.62288,
			58.65306,
			58.69896,
			58.7316742,
			58.7787781,
			58.8383331,
			58.87053,
			58.919342,
			58.9636536,
			59.00894,
			59.0552063,
			59.1030121,
			59.14981,
			59.18103,
			59.2212677,
			59.2666168,
			59.31302,
			59.35965,
			59.4039154,
			59.4361267,
			59.48143,
			59.5277557,
			59.57605,
			59.62384,
			59.6675873,
			59.7138977,
			59.7592,
			59.8054657,
			59.83716,
			59.8824768,
			59.9283142,
			59.9599762,
			60.0052338,
			60.0530548,
			60.09842,
			60.1416473,
			60.1884155,
			60.2226257,
			60.27043,
			60.3016357,
			60.3479462,
			60.39377,
			60.4385071,
			60.4878235,
			60.5331726,
			60.5797729,
			60.6102448,
			60.65532,
			60.68753,
			60.7343445,
			60.76512,
			60.8093262,
			60.8550873,
			60.8701935,
			60.917984,
			60.9633026,
			61.008606,
			61.0548553,
			61.1011658,
			61.1319275,
			61.1775665,
			61.2254639,
			61.256134,
			61.30342,
			61.3482361,
			61.395752,
			61.44284,
			61.48764,
			61.5349121,
			61.5807343,
			61.6260376,
			61.6582336,
			61.6884,
			61.7357025,
			61.7830048,
			61.8263245,
			61.85852,
			61.9068,
			61.91989,
			61.95259,
			61.99846,
			62.0441742,
			62.0920258,
			62.1387939,
			62.1684875,
			62.183075,
			62.2303925,
			62.2622528,
			62.306366,
			62.35263,
			62.3970947,
			62.4271545,
			62.445282,
			62.4909821,
			62.5387726,
			62.5845642,
			62.61624,
			62.6633,
			62.70781,
			62.75609,
			62.80237,
			62.8471527,
			62.89473,
			62.94226,
			62.9714355,
			63.002182,
			63.0484772,
			63.095932,
			63.1420746,
			63.18834,
			63.23265,
			63.27707,
			63.3236542,
			63.37195,
			63.41774,
			63.46376,
			63.4927673,
			63.5400238,
			63.58577,
			63.61702,
			63.66237,
			63.70848,
			63.7557526,
			63.80011,
			63.8478546,
			63.8916321,
			63.93889,
			63.985672,
			64.0159,
			64.06319,
			64.11058,
			64.140686,
			64.18596,
			64.2328339,
			64.2635956,
			64.28107,
			64.32536,
			64.37312,
			64.40381,
			64.45009,
			64.48079,
			64.52507,
			64.58641,
			64.6347046,
			64.68002,
			64.70967,
			64.75746,
			64.8042145,
			64.84854,
			64.8964539,
			64.94212,
			64.9873657,
			65.0326843,
			65.06383,
			65.10861,
			65.15488,
			65.20218,
			65.248,
			65.29123,
			65.3224,
			65.3546143,
			65.399,
			65.43074,
			65.4771,
			65.50606,
			65.55539,
			65.58409,
			65.6315155,
			65.67624,
			65.72298,
			65.76828,
			65.80148,
			65.84729,
			65.89261,
			65.93834,
			65.9846649,
			66.0309753,
			66.07678,
			66.1064758,
			66.1547546,
			66.19957,
			66.2323456,
			66.27754,
			66.30771,
			66.35498,
			66.39981,
			66.4471,
			66.4939,
			66.53912,
			66.5864258,
			66.6156,
			66.6302,
			66.67792,
			66.7086639,
			66.75493,
			66.8022156,
			66.8475342,
			66.89281,
			66.93909,
			66.98639,
			67.01558,
			67.06319,
			67.10869,
			67.15497,
			67.2022858,
			67.23305,
			67.2789,
			67.32411,
			67.36937,
			67.41472,
			67.4634857,
			67.50934,
			67.556076,
			67.6028748,
			67.64969,
			67.68042,
			67.725235,
			67.77048,
			67.81981,
			67.86467,
			67.91051,
			67.94272,
			67.99045,
			68.02112,
			68.067,
			68.11069,
			68.15851,
			68.20529,
			68.25209,
			68.29797,
			68.34364,
			68.38899,
			68.4368,
			68.46652,
			68.5138245,
			68.5596161,
			68.60539,
			68.65018,
			68.6966858,
			68.72621,
			68.774,
			68.80316,
			68.84947,
			68.89378,
			68.94057,
			68.98888,
			69.0341644,
			69.07764,
			69.1268158,
			69.15494,
			69.20175,
			69.24773,
			69.2939148,
			69.34119,
			69.3864441,
			69.43291,
			69.4790344,
			69.5103149,
			69.55603,
			69.58826,
			69.6326141,
			69.66327,
			69.70955,
			69.75482,
			69.77298,
			69.81673,
			69.86423,
			69.90886,
			69.95561,
			70.00192,
			70.032196,
			70.07945,
			70.12369,
			70.1730042,
			70.2172852,
			70.2475,
			70.2933044,
			70.3401,
			70.38689,
			70.43419,
			70.47897,
			70.52528,
			70.55646,
			70.60326,
			70.64807,
			70.6933746,
			70.74014,
			70.78743,
			70.81714,
			70.86409,
			70.90874,
			70.95601,
			71.00331,
			71.05112,
			71.0808258,
			71.11267,
			71.15831,
			71.18848,
			71.23428,
			71.2661,
			71.3092957,
			71.3404846,
			71.38727,
			71.43407,
			71.48039,
			71.52771,
			71.57245,
			71.60365,
			71.64998,
			71.69595,
			71.7281952,
			71.7727356,
			71.80444,
			71.84924,
			71.89569,
			71.9428253,
			71.98857,
			72.03386,
			72.06308,
			72.11034,
			72.1264648,
			72.17119,
			72.2028656,
			72.24815,
			72.29445,
			72.34224,
			72.38646,
			72.43181,
			72.47652,
			72.5253143,
			72.55649,
			72.6037445,
			72.64769,
			72.69377,
			72.7406158,
			72.78735,
			72.8322,
			72.8791046,
			72.90959,
			72.95436,
			73.00212,
			73.04791,
			73.09488,
			73.14046,
			73.17265,
			73.2199249,
			73.26424,
			73.311554,
			73.35524,
			73.40199,
			73.43375,
			73.47963,
			73.52469,
			73.572464,
			73.61572,
			73.6615143,
			73.6936646,
			73.73793,
			73.7832,
			73.83156,
			73.87575,
			73.9225159,
			73.9551849,
			73.99902,
			74.0463257,
			74.09206,
			74.1247559,
			74.1705,
			74.21625,
			74.44049,
			74.473175,
			74.51793,
			74.5648041,
			74.61276,
			74.6582947,
			74.70503,
			74.73421,
			74.78099,
			74.8121948,
			74.85797,
			74.90471,
			74.95047,
			74.98067,
			74.99692,
			75.04152,
			75.08827,
			75.13504,
			75.18033,
			75.2095,
			75.2578,
			75.30151,
			75.34784,
			75.39574,
			75.44237,
			75.48764,
			75.51979,
			75.56514,
			75.60933,
			75.65662,
			75.70384,
			75.74814,
			75.78,
			75.82559,
			75.8708344,
			75.9171,
			75.96498,
			76.01022,
			76.0403748,
			76.08716,
			76.13443,
			76.16423,
			76.21054,
			76.25566,
			76.2883453,
			76.35219,
			76.3995361,
			76.44556,
			76.49205,
			76.53728,
			76.55287,
			76.6002045,
			76.6436,
			76.67621,
			76.72096,
			76.76726,
			76.79755,
			76.8608856,
			76.90462,
			76.95245,
			76.9998,
			77.04385,
			77.07623,
			77.1229553,
			77.1692352,
			77.2155151,
			77.25981,
			77.30556,
			77.33624,
			77.36742,
			77.41373,
			77.46005,
			77.5063,
			77.55051,
			77.59697,
			77.6426239,
			77.68941,
			77.71767,
			77.76547,
			77.8118439,
			77.85797,
			77.90576,
			77.95204,
			77.99432,
			78.0421,
			78.08734,
			78.11803,
			78.1649,
			78.21039,
			78.25491,
			78.3036957,
			78.34808,
			78.37744,
			78.42549,
			78.45616,
			78.50192,
			78.54918,
			78.59354,
			78.6417847,
			78.6860352,
			78.73381,
			78.7812653,
			78.82541,
			78.87262,
			78.90288,
			78.95059,
			78.99391,
			79.0412,
			79.08795,
			79.13373,
			79.16293,
			79.20871,
			79.2570343,
			79.2877045,
			79.336,
			79.38097,
			79.41272,
			79.42563,
			79.4728546,
			79.50406,
			79.55034,
			79.5963,
			79.64285,
			79.68964,
			79.7358551,
			79.78218,
			79.82547,
			79.87375,
			79.92154,
			79.95224,
			79.99861,
			80.0262451,
			80.07405,
			80.12184,
			80.16521,
			80.21083,
			80.25874,
			80.30499,
			80.3366852,
			80.3829956,
			80.42978,
			80.46004,
			80.47455,
			80.5193,
			80.55301,
			80.59837,
			80.64354,
			80.68837,
			80.72159,
			80.78398,
			80.82893,
			80.85951,
			80.9062653,
			80.95355,
			80.99986,
			81.04552,
			81.09137,
			81.13812,
			81.18443,
			81.23044,
			81.25789,
			81.2915649,
			81.33731,
			81.38362,
			81.42993,
			81.4601746,
			81.50539,
			81.5693054,
			81.60152,
			81.64833,
			81.6926,
			81.7399139,
			81.77057,
			81.8183746,
			81.86345,
			81.9100952,
			81.95529,
			82.00308,
			82.0318451,
			82.0797,
			82.12537,
			82.15602,
			82.2008057,
			82.24863,
			82.29443,
			82.3417053,
			82.386,
			82.431366,
			82.4776,
			82.5229,
			82.55661,
			82.60089,
			82.63165,
			82.6804047,
			82.72621,
			82.76996,
			82.81726,
			82.84749,
			82.89435,
			82.9395752,
			82.9873657,
			83.03415,
			83.08005,
			83.1268,
			83.17305,
			83.20372,
			83.2495,
			83.2807159,
			83.3255,
			83.38937,
			83.43323,
			83.48094,
			83.52728,
			83.57559,
			83.60623,
			83.65001,
			83.69739,
			83.74312,
			83.7894,
			83.8366547,
			83.8673553,
			83.9142761,
			83.95996,
			83.99164,
			84.03841,
			84.06909,
			84.11388,
			84.128006,
			84.17473,
			84.2220154,
			84.25319,
			84.29903,
			84.3452454,
			84.3920441,
			84.43878,
			84.48355,
			84.52904,
			84.5751648,
			84.62044,
			84.6521149,
			84.6960144,
			84.74217,
			84.7889557,
			84.8352356,
			84.88104,
			84.910965,
			84.9590454,
			85.0038147,
			85.0365143,
			85.0813141,
			85.12613,
			85.17383,
			85.20351,
			85.2482758,
			85.2945557,
			85.34184,
			85.38809,
			85.43436,
			85.48114,
			85.52748,
			85.5737,
			85.62094,
			85.6642,
			85.6969757,
			85.7426758,
			85.78841,
			85.83618,
			85.88097,
			85.91237,
			85.9589844,
			86.00571,
			86.0495,
			86.09691,
			86.14313,
			86.18938,
			86.22107,
			86.2644348,
			86.3101654,
			86.35948,
			86.4047241,
			86.45102,
			86.48135,
			86.52603,
			86.5722961,
			86.6175842,
			86.6630554,
			86.70923,
			86.73837,
			86.78516,
			86.83046,
			86.87694,
			86.90643,
			86.95323,
			86.99957,
			87.04613,
			87.09163,
			87.13992,
			87.1836853,
			87.2320862,
			87.2592,
			87.30751,
			87.35478,
			87.3844757,
			87.43135,
			87.47557,
			87.50778,
			87.56917,
			87.614975,
			87.65976,
			87.70653,
			87.73772,
			87.78203,
			87.829834,
			87.87358,
			87.92038,
			87.96768,
			88.01297,
			88.04317,
			88.08794,
			88.13475,
			88.181076,
			88.2102661,
			88.25601,
			88.2892151,
			88.304306,
			88.35062,
			88.397995,
			88.4427,
			88.48898,
			88.53427,
			88.56607,
			88.6138153,
			88.64403,
			88.69028,
			88.71945,
			88.76633,
			88.79805,
			88.82619,
			88.87244,
			88.91722,
			88.9646149,
			88.99646,
			89.0430145,
			89.0898,
			89.12149,
			89.1677856,
			89.2133942,
			89.2594,
			89.30821,
			89.3378754,
			89.3861847,
			89.4301147,
			89.47629,
			89.523056,
			89.56735,
			89.5996246,
			89.6461,
			89.6916656,
			89.73744,
			89.76865,
			89.81444,
			89.8602448,
			89.905,
			89.95329,
			89.98399,
			90.03052,
			90.07564,
			90.1234,
			90.16968,
			90.215,
			90.26346,
			90.29399,
			90.33876,
			90.387085,
			90.43346,
			90.46327,
			90.50836,
			90.53905,
			90.58685,
			90.63321,
			90.64827,
			90.69354,
			90.7383,
			90.7866058,
			90.83438,
			90.86435,
			90.90933,
			90.95763,
			91.0038,
			91.04756,
			91.0928345,
			91.14163,
			91.17177,
			91.21854,
			91.24773,
			91.29198,
			91.33923,
			91.38649,
			91.4313354,
			91.46364,
			91.5097656,
			91.556,
			91.6017456,
			91.64853,
			91.6959839,
			91.74007,
			91.78679,
			91.83461,
			91.879776,
			91.92514,
			91.95529,
			92.00157,
			92.0475159,
			92.0936,
			92.13989,
			92.18713,
			92.2172546,
			92.26376,
			92.30977,
			92.356,
			92.40126,
			92.44621,
			92.47575,
			92.522995,
			92.57077,
			92.61453,
			92.66031,
			92.7060547,
			92.73872,
			92.786026,
			92.83136,
			92.87932,
			92.92534,
			92.96956,
			93.0023041,
			93.0315247,
			93.07927,
			93.12503,
			93.16977,
			93.21704,
			93.24826,
			93.30408,
			93.34784,
			93.39363,
			93.44142,
			93.48866,
			93.5188446,
			93.56389,
			93.61105,
			93.65517,
			93.70094,
			93.73219,
			93.77641,
			93.82265,
			93.86992,
			93.89867,
			93.9463348,
			93.97658,
			94.02281,
			94.06804,
			94.11432,
			94.16008,
			94.2043152,
			94.25159,
			94.2944,
			94.32654,
			94.37331,
			94.42107,
			94.46785,
			94.51039,
			94.5583954,
			94.59158,
			94.6383362,
			94.6685,
			94.71428,
			94.74396,
			94.791275,
			94.8219,
			94.86717,
			94.91446,
			94.9607544,
			95.007,
			95.05327,
			95.0834351,
			95.11432,
			95.16051,
			95.19112,
			95.23685,
			95.28464,
			95.33095,
			95.39284,
			95.4225159,
			95.47081,
			95.51659,
			95.56308,
			95.6082,
			95.6529541,
			95.70126,
			95.7475739,
			95.79085,
			95.83864,
			95.86984,
			95.91414,
			95.96103,
			95.9911346,
			96.03891,
			96.08421,
			96.1320953,
			96.1763,
			96.2231,
			96.26788,
			96.31619,
			96.3484039,
			96.39218,
			96.4374542,
			96.4852753,
			96.51648,
			96.562,
			96.60759,
			96.65236,
			96.70073,
			96.7465057,
			96.79378,
			96.8405762,
			96.88434,
			96.91455,
			96.9472656,
			96.99153,
			97.03783,
			97.08563,
			97.1299438,
			97.175766,
			97.22249,
			97.2688,
			97.31612,
			97.3635559,
			97.4097,
			97.4409,
			97.4866943,
			97.5315552,
			97.5762939,
			97.62509,
			97.67035,
			97.70155,
			97.74753,
			97.7926941,
			97.83844,
			97.88423,
			97.9306,
			97.9603,
			98.00754,
			98.05281,
			98.10019,
			98.1444855,
			98.19122,
			98.2229,
			98.2702,
			98.315506,
			98.35878,
			98.40556,
			98.45387,
			98.48308,
			98.52687,
			98.56058,
			98.60434,
			98.65315,
			98.69853,
			98.74228,
			98.79158,
			98.837326,
			98.86853,
			98.91435,
			98.94353,
			98.99185,
			99.03862,
			99.0834351,
			99.12775,
			99.17351,
			99.20419,
			99.25049,
			99.3127441,
			99.3582153,
			99.40651,
			99.43718,
			99.48297,
			99.52739,
			99.57254,
			99.60324,
			99.65054,
			99.68176,
			99.727066,
			99.77281,
			99.81911,
			99.86449,
			99.89676,
			99.94141,
			99.9902039,
			100.034988,
			100.0526,
			100.096909,
			100.142624,
			100.18837,
			100.236145,
			100.28093,
			100.310257,
			100.358414,
			100.389084,
			100.434341,
			100.481628,
			100.5264,
			100.55957,
			100.622009,
			100.650635,
			100.697495,
			100.742752,
			100.78949,
			100.836227,
			100.882523,
			100.92627,
			100.957993,
			101.004761,
			101.052551,
			101.097992,
			101.142609,
			101.191925,
			101.23616,
			101.282944,
			101.330887,
			101.361023,
			101.406265,
			101.453506,
			101.486206,
			101.530487,
			101.576263,
			101.623566,
			101.66983,
			101.71611,
			101.763138,
			101.808716,
			101.856491,
			101.886642,
			101.934448,
			101.964386,
			102.012985,
			102.059219,
			102.105957,
			102.135132,
			102.151245,
			102.19809,
			102.244171,
			102.288559,
			102.319748,
			102.3651,
			102.411942,
			102.457581,
			102.503342,
			102.548111,
			102.593979,
			102.64064,
			102.672333,
			102.716583,
			102.763153,
			102.808167,
			102.852905,
			102.88559,
			102.930954,
			102.977631,
			103.0255,
			103.070236,
			103.116043,
			103.161377,
			103.193054,
			103.224716,
			103.272995,
			103.316772,
			103.349472,
			103.393768,
			103.440018,
			103.456146,
			103.487289,
			103.532654,
			103.5804,
			103.625183,
			103.669418,
			103.701614,
			103.760513,
			103.792175,
			103.837936,
			103.885742,
			103.93158,
			103.9783,
			104.024567,
			104.070313,
			104.115616,
			104.161407,
			104.191544,
			104.236816,
			104.268524,
			104.313812,
			104.3586,
			104.405365,
			104.436523,
			104.483337,
			104.499512,
			104.545746,
			104.590988,
			104.63826,
			104.686035,
			104.73204,
			104.76149,
			104.791138,
			104.837357,
			104.885651,
			104.9315,
			104.976227,
			105.022461,
			105.07074,
			105.115051,
			105.162048,
			105.20813,
			105.253876,
			105.28508,
			105.330887,
			105.377594,
			105.424988,
			105.472733,
			105.519012,
			105.549713,
			105.593,
			105.639816,
			105.689087,
			105.734879,
			105.763565,
			105.811066,
			105.857605,
			105.887772,
			105.933548,
			105.964249,
			106.010193,
			106.039185,
			106.070877,
			106.117645,
			106.163834,
			106.210876,
			106.256012,
			106.303284,
			106.334991,
			106.381439,
			106.411194,
			106.458252,
			106.504,
			106.5508,
			106.598145,
			106.644547,
			106.691635,
			106.738388,
			106.783676,
			106.828,
			106.860184,
			106.9039,
			106.953217,
			106.998077,
			107.045959,
			107.089584,
			107.120255,
			107.165131,
			107.215363,
			107.259613,
			107.306885,
			107.352676,
			107.385864,
			107.431244,
			107.47641,
			107.522659,
			107.570953,
			107.616226,
			107.648422,
			107.69371,
			107.738968,
			107.772675,
			107.817459,
			107.849167,
			107.894012,
			107.909546,
			107.956329,
			107.988007,
			108.034271,
			108.080582,
			108.124359,
			108.170624,
			108.216385,
			108.24707,
			108.296066,
			108.342636,
			108.3889,
			108.42009,
			108.464935,
			108.496246,
			108.54335,
			108.588623,
			108.6344,
			108.682693,
			108.729218,
			108.772736,
			108.821518,
			108.852219,
			108.898605,
			108.926163,
			108.991119,
			109.035339,
			109.068527,
			109.1136,
			109.144196,
			109.188843,
			109.204391,
			109.236588,
			109.281372,
			109.3288,
			109.374969,
			109.421692,
			109.467468,
			109.514282,
			109.546066,
			109.591766,
			109.637024,
			109.683273,
			109.729691,
			109.77536,
			109.820068,
			109.865906,
			109.911163,
			109.959412,
			109.988556,
			110.036331,
			110.081619,
			110.126358,
			110.17569,
			110.2224,
			110.252075,
			110.2979,
			110.345383,
			110.391388,
			110.4371,
			110.48288,
			110.516068,
			110.561615,
			110.592545,
			110.637268,
			110.686539,
			110.732376,
			110.7778,
			110.82489,
			110.856049,
			110.898865,
			110.943893,
			110.977448,
			111.021988,
			111.086365,
			111.130692,
			111.175446,
			111.222183,
			111.267944,
			111.298767,
			111.34404,
			111.392715,
			111.439468,
			111.485229,
			111.53,
			111.561859,
			111.605408,
			111.652695,
			111.699051,
			111.74585,
			111.78949,
			111.821152,
			111.86647,
			111.912216,
			111.957932,
			111.9881,
			112.034363,
			112.08165,
			112.1264,
			112.172653,
			112.217407,
			112.2652,
			112.310471,
			112.343094,
			112.387314,
			112.436584,
			112.480865,
			112.528412,
			112.574417,
			112.604065,
			112.636261,
			112.682037,
			112.726837,
			112.774109,
			112.818848,
			112.867096,
			112.910507,
			112.956116,
			112.9893,
			113.03508,
			113.079849,
			113.124557,
			113.1723,
			113.217056,
			113.247879,
			113.294586,
			113.339752,
			113.38501,
			113.432358,
			113.476547,
			113.524826,
			113.570129,
			113.6169,
			113.647,
			113.693787,
			113.739014,
			113.784256,
			113.832596,
			113.8768,
			113.907974,
			113.940628,
			113.98436,
			114.032227,
			114.076965,
			114.124191,
			114.170425,
			114.217682,
			114.265045,
			114.308228,
			114.353958,
			114.40274,
			114.433,
			114.464172,
			114.508392,
			114.539063,
			114.584839,
			114.631668,
			114.678619,
			114.723175,
			114.770432,
			114.817245,
			114.86319,
			114.9093,
			114.954544,
			115.002853,
			115.030533,
			115.080841,
			115.126129,
			115.170853,
			115.218628,
			115.2655,
			115.2946,
			115.341873,
			115.373062,
			115.419312,
			115.464752,
			115.480667,
			115.525467,
			115.570206,
			115.617477,
			115.647675,
			115.692459,
			115.738724,
			115.786026,
			115.833374,
			115.8766,
			115.925415,
			115.970123,
			116.000809,
			116.031082,
			116.078781,
			116.12204,
			116.169342,
			116.201035,
			116.247314,
			116.309738,
			116.354935,
			116.400681,
			116.447983,
			116.493256,
			116.524429,
			116.571213,
			116.618,
			116.664841,
			116.696121,
			116.742249,
			116.788513,
			116.837265,
			116.881561,
			116.929504,
			116.97316,
			117.019928,
			117.052109,
			117.097488,
			117.14267,
			117.189438,
			117.220627,
			117.2655,
			117.296768,
			117.358521,
			117.388168,
			117.434464,
			117.481781,
			117.52977,
			117.575363,
			117.620117,
			117.666412,
			117.71344,
			117.759506,
			117.804306,
			117.834488,
			117.882782,
			117.9281,
			117.975372,
			118.020615,
			118.066895,
			118.097183,
			118.142868,
			118.190659,
			118.2374,
			118.267593,
			118.313507,
			118.358658,
			118.404419,
			118.436127,
			118.481934,
			118.532791,
			118.579269,
			118.624786,
			118.671005,
			118.715317,
			118.748,
			118.794968,
			118.840576,
			118.887314,
			118.93309,
			118.979584,
			119.02565,
			119.071915,
			119.117676,
			119.147873,
			119.194672,
			119.238419,
			119.286194,
			119.331528,
			119.377396,
			119.408432,
			119.43959,
			119.485855,
			119.530655,
			119.579987,
			119.62468,
			119.669891,
			119.717148,
			119.764267,
			119.810272,
			119.853485,
			119.902283,
			119.930481,
			119.976791,
			120.023026,
			120.0688,
			120.098572,
			120.142273,
			120.175461,
			120.236267,
			120.267456,
			120.313324,
			120.359024,
			120.404755,
			120.453049,
			120.4984,
			120.54364,
			120.588837,
			120.622513,
			120.666779,
			120.710754,
			120.742752,
			120.788467,
			120.835724,
			120.881027,
			120.926285,
			120.972031,
			121.019791,
			121.064117,
			121.109848,
			121.155106,
			121.188293,
			121.235565,
			121.280991,
			121.3251,
			121.372345,
			121.419128,
			121.464081,
			121.494583,
			121.540344,
			121.588074,
			121.632889,
			121.67778,
			121.726395,
			121.75502,
			121.788712,
			121.833527,
			121.864731,
			121.911163,
			121.9577,
			122.003983,
			122.020065,
			122.0654,
			122.1091,
			122.155365,
			122.188553,
			122.234344,
			122.281616,
			122.325394,
			122.372665,
			122.416931,
			122.463943,
			122.508987,
			122.540176,
			122.570343,
			122.617615,
			122.663605,
			122.693115,
			122.741913,
			122.787643,
			122.803741,
			122.849533,
			122.879532,
			122.925537,
			122.972794,
			123.019196,
			123.063843,
			123.094589,
			123.142349,
			123.172485,
			123.218262,
			123.264633,
			123.295944,
			123.324448,
			123.372696,
			123.417953,
			123.464813,
			123.510223,
			123.558273,
			123.588943,
			123.618637,
			123.665466,
			123.712936,
			123.755905,
			123.789139,
			123.834915,
			123.848007,
			123.877335,
			123.924973,
			123.972244,
			124.018539,
			124.064407,
			124.093521,
			124.109116,
			124.158432,
			124.188705,
			124.23439,
			124.282211,
			124.326492,
			124.373337,
			124.420059,
			124.463364,
			124.496262,
			124.541367,
			124.588638,
			124.634933,
			124.664291,
			124.708939,
			124.739578,
			124.78685,
			124.819077,
			124.864456,
			124.89357,
			124.942368,
			124.988632,
			125.03392,
			125.080368,
			125.126022,
			125.155136,
			125.186356,
			125.234146,
			125.28096,
			125.326736,
			125.373062,
			125.419327,
			125.463608,
			125.5099,
			125.539627,
			125.587875,
			125.634659,
			125.681412,
			125.726669,
			125.7729,
			125.819687,
			125.865555,
			125.910873,
			125.939957,
			125.97319,
			126.017471,
			126.065323,
			126.111465,
			126.156326,
			126.203629,
			126.250412,
			126.296738,
			126.341537,
			126.387894,
			126.435104,
			126.465378,
			126.510269,
			126.5594,
			126.603653,
			126.652969,
			126.697464,
			126.730148,
			126.775772,
			126.82048,
			126.865372,
			126.912827,
			126.959885,
			126.991074,
			127.052963,
			127.085159,
			127.129959,
			127.176254,
			127.222031,
			127.2527,
			127.31514,
			127.311386
		]
	],
	'6586_7978_7982': [
		[
			101.623459
		],
		[
			0
		],
		[
			0,
			2.59876871,
			5.21802473,
			7.83054972,
			9.136804,
			10.4446306,
			13.0720005,
			15.7080231,
			18.358345,
			19.6865444,
			22.3490658,
			25.01939,
			27.6966362,
			30.38057,
			33.0699158,
			34.41689,
			37.11602,
			39.8217621,
			41.1772957,
			43.89379,
			46.6171837,
			49.347393,
			50.715004,
			53.45531,
			54.828064,
			57.5784,
			60.3355255,
			63.0996857,
			64.48437,
			67.25892,
			70.0403442,
			72.82816,
			75.62248,
			77.0222855,
			79.82729,
			82.63868,
			84.04663,
			86.8675842,
			89.6951141,
			92.52928,
			93.9489746,
			96.7931747,
			99.6438751,
			102.5012,
			105.365349,
			106.799843,
			109.673546,
			112.553856,
			113.996681,
			116.887077,
			119.782913,
			122.685394,
			124.139267,
			127.051682,
			129.969559,
			132.8938,
			135.824326,
			137.291855,
			140.230988,
			143.176575,
			144.652008,
			147.607315,
			150.568146,
			153.535065,
			155.020721,
			157.9969,
			160.979462,
			163.967743,
			166.961578,
			168.460709,
			171.463333,
			172.96582,
			175.972809,
			178.9857,
			182.004532,
			185.032867,
			186.549377,
			189.5874,
			192.63089,
			195.674744,
			197.198792,
			200.2424,
			203.286438,
			204.804382,
			207.846832,
			210.91922,
			214.012,
			217.128769,
			218.6823,
			221.786133,
			224.886383,
			227.988647,
			229.543213,
			232.656342,
			235.775482,
			237.337,
			240.463364,
			242.028412,
			245.162262,
			248.300964,
			251.443573,
			254.591522,
			257.7446,
			260.902679,
			262.483521,
			265.6496,
			267.2345,
			270.407684,
			273.5854,
			276.767334,
			279.9505,
			283.1343,
			284.7224,
			287.901062,
			291.0838,
			292.6767,
			295.884155,
			299.0998,
			300.706268,
			303.920471,
			307.1281,
			310.34375,
			313.56662,
			316.795929,
			318.4122,
			321.6479,
			323.267365,
			326.5093,
			329.756653,
			333.011566,
			334.641541,
			337.906769,
			339.539246,
			342.802124,
			346.067017,
			349.335236,
			350.9723,
			354.249573,
			357.532837,
			360.819244,
			364.108856,
			367.400757,
			369.048157,
			372.345673,
			373.9959,
			377.2991,
			380.6061,
			383.916931,
			385.574158,
			388.891724,
			392.2127,
			395.5371,
			398.864624,
			402.1939,
			403.859741,
			407.19574,
			410.535858,
			412.207275,
			415.5525,
			418.90033,
			420.5738,
			423.9201,
			427.2722,
			430.630737,
			433.9995,
			437.369324,
			439.0552,
			442.423462,
			444.107941,
			447.482361,
			450.863525,
			454.254517,
			455.9515,
			459.348541,
			462.750061,
			466.155457,
			469.56427,
			471.2704,
			474.6858,
			478.104431,
			481.526978,
			483.239868,
			486.668274,
			490.1002,
			491.817627,
			495.255219,
			498.697327,
			502.143036,
			503.867218,
			507.318359,
			510.773163,
			514.2318,
			517.6945,
			519.4274,
			522.8954,
			526.366943,
			528.1045,
			531.58374,
			535.070251,
			538.56134,
			542.054138,
			545.5474,
			547.2945,
			550.791138,
			552.5412,
			556.043457,
			559.545,
			563.037231,
			564.7765,
			568.2356,
			571.6624,
			575.0515,
			578.401855,
			581.7133,
			583.354431,
			586.6079,
			588.221069,
			591.4184,
			594.5795,
			597.700134,
			599.2451,
			602.306335,
			605.3305,
			606.829468,
			609.8012,
			612.7352,
			615.6309,
			617.064453,
			619.903,
			622.703247,
			624.0891,
			626.832336,
			629.537231,
			632.2045,
			634.834351,
			637.4271,
			638.709839,
			641.2472,
			643.7468,
			644.9827,
			647.427063,
			649.83606,
			652.210632,
			654.5508,
			655.7081,
			657.995361,
			660.245239,
			661.3563,
			663.5499,
			665.7068,
			666.771362,
			668.872559,
			670.936768,
			672.9645,
			674.9558,
			676.911133,
			677.875244,
			679.776733,
			681.642334,
			683.467651,
			684.368042,
			686.1448,
			687.0189,
			688.7323,
			690.4114,
			692.0563,
			693.6788,
			695.2544,
			696.0303,
			696.798767,
			698.321167,
			699.814941,
			701.2839,
			702.729736,
			703.4443,
			704.8593,
			706.2596,
			707.641235,
			709.006653,
			710.365,
			711.0428,
			712.3956,
			713.0691,
			714.413635,
			715.756531,
			717.101868,
			717.780334,
			719.136353,
			720.492737,
			721.8509,
			723.255,
			723.958252,
			725.366333,
			726.071655,
			727.496338,
			728.9429,
			730.3936,
			731.849731,
			732.589966,
			734.0808,
			735.5777,
			737.0821,
			737.8422,
			739.367554,
			740.90094,
			741.6712,
			743.217,
			744.7709,
			746.3351,
			747.922668,
			748.72644,
			750.3422,
			751.969543,
			753.663452,
			754.5178,
			756.234,
			757.9891,
			758.8865,
			760.687561,
			762.5003,
			764.339966,
			766.184631,
			767.1093,
			768.9227,
			770.734253,
			772.5427,
			773.425,
			775.1896,
			776.9554,
			777.8387,
			779.6034,
			780.4851,
			782.247864,
			784.037842,
			785.8259,
			787.6171,
			789.4513,
			790.365967,
			792.193,
			794.0635,
			794.999634,
			796.869141,
			798.7522,
			800.6367,
			802.517456,
			804.3684,
			805.2932,
			807.144043,
			808.0618,
			809.8777,
			811.6947,
			813.466,
			814.32135,
			816.0335,
			817.7494,
			819.3939,
			821.0396,
			821.864258,
			823.517151,
			825.1732,
			826.0029,
			827.6662,
			829.3457,
			831.029663,
			831.873169,
			833.5675,
			835.2712,
			836.9801,
			837.8372,
			839.563049,
			841.294556,
			842.163,
			843.9147,
			845.681,
			847.4554,
			849.2624,
			850.174255,
			851.0885,
			852.925232,
			854.7965,
			856.6787,
			857.6239,
			859.533447,
			861.453857,
			863.3803,
			864.3409,
			866.265747,
			868.1951,
			869.1439,
			871.045166,
			871.997742,
			873.905945,
			875.8181,
			877.731567,
			879.640869,
			881.552246,
			883.466,
			884.413452,
			886.313965,
			888.224854,
			889.18,
			891.0973,
			893.0268,
			894.9678,
			896.9204,
			898.8862,
			899.8759,
			901.864136,
			903.8644,
			904.869751,
			906.8888,
			908.9199,
			909.9401,
			911.988831,
			914.0489,
			916.12,
			918.201843,
			920.294556,
			921.3447,
			923.452,
			924.5068,
			926.614868,
			928.727844,
			930.854736,
			931.922,
			934.0453,
			936.1942,
			938.3707,
			940.5347,
			942.7069,
			943.7967,
			944.887939,
			947.0773,
			949.2761,
			951.483154,
			952.5902,
			954.811,
			957.02124,
			959.2592,
			961.502869,
			963.774658,
			966.0327,
			967.164734,
			969.4351,
			971.7084,
			972.8485,
			975.1112,
			977.3928,
			978.537048,
			980.8301,
			983.130859,
			985.4673,
			987.8026,
			990.1506,
			991.332764,
			993.7038,
			994.893433,
			997.282959,
			999.681458,
			1002.09216,
			1003.30078,
			1005.72406,
			1008.15491,
			1010.59167,
			1013.03143,
			1014.25446,
			1016.70007,
			1019.15137,
			1020.37964,
			1022.8396,
			1025.30811,
			1027.788,
			1029.03088,
			1031.53113,
			1034.04517,
			1036.54993,
			1039.086,
			1040.35681,
			1042.90234,
			1045.4552,
			1046.73291,
			1049.29407,
			1051.86023,
			1054.43115,
			1055.71716,
			1058.28674,
			1060.84827,
			1063.38916,
			1065.907,
			1067.15588,
			1069.62842,
			1072.06458,
			1073.26855,
			1075.64783,
			1077.987,
			1080.28833,
			1081.42419,
			1083.667,
			1085.87219,
			1088.03931,
			1090.16907,
			1091.22,
			1093.29407,
			1094.31738,
			1096.3363,
			1098.3197,
			1100.26636,
			1102.17871,
			1103.12183,
			1104.98059,
			1106.80481,
			1108.597,
			1109.48071,
			1111.22375,
			1112.93616,
			1113.77881,
			1115.45178,
			1117.07983,
			1117.88354,
			1119.47021,
			1121.03271,
			1122.57788,
			1124.105,
			1125.6178,
			1126.37073,
			1127.87024,
			1128.61963,
			1130.12073,
			1131.62646,
			1133.13184,
			1134.63989,
			1136.15076,
			1136.90588,
			1138.4126,
			1139.91968,
			1140.67371,
			1142.17749,
			1143.66785,
			1144.4126,
			1145.90161,
			1147.37769,
			1148.83423,
			1150.2887,
			1151.743,
			1152.47717,
			1153.944,
			1154.67627,
			1156.13989,
			1157.63147,
			1159.14722,
			1159.90381,
			1161.41638,
			1162.97266,
			1164.55249,
			1166.12842,
			1167.73914,
			1168.5686,
			1170.22363,
			1171.04993,
			1172.738,
			1174.48389,
			1176.22717,
			1177.09875,
			1178.91565,
			1180.7312,
			1182.54919,
			1184.37622,
			1185.28809,
			1187.11035,
			1188.95093,
			1189.87158,
			1191.71228,
			1193.51843,
			1195.302,
			1196.19385,
			1197.95886,
			1199.665,
			1201.37231,
			1202.227,
			1203.86975,
			1205.48364,
			1206.29126,
			1207.90271,
			1209.45129,
			1211.00122,
			1212.55493,
			1213.33264,
			1214.88892,
			1216.44836,
			1217.2301,
			1218.80334,
			1220.38513,
			1221.178,
			1222.769,
			1224.36853,
			1225.97485,
			1227.58862,
			1229.19617,
			1230.0105,
			1231.64893,
			1233.30042,
			1234.13049,
			1235.80115,
			1237.49963,
			1238.34668,
			1240.04712,
			1241.75708,
			1243.47668,
			1245.20618,
			1246.073,
			1247.81067,
			1249.554,
			1250.42651,
			1252.17432,
			1253.92236,
			1255.66748,
			1256.54053,
			1258.288,
			1260.02026,
			1261.75256,
			1263.48694,
			1264.34741,
			1266.06909,
			1266.93066,
			1268.65625,
			1270.378,
			1272.10254,
			1273.83215,
			1274.70264,
			1276.44531,
			1278.19153,
			1279.07446,
			1280.856,
			1282.64063,
			1283.53467,
			1285.36816,
			1287.20972,
			1289.06042,
			1290.94788,
			1292.8363,
			1293.78113,
			1295.72168,
			1297.66223,
			1299.64136,
			1300.66589,
			1302.7146,
			1304.80066,
			1305.87524,
			1308.02368,
			1310.11035,
			1312.1355,
			1314.16528,
			1315.127,
			1317.0498,
			1318.97925,
			1319.94409,
			1321.87671,
			1323.817,
			1324.77075,
			1326.65283,
			1328.54456,
			1330.38916,
			1332.20715,
			1334.03369,
			1334.95032,
			1336.78857,
			1337.71033,
			1339.56421,
			1341.48169,
			1342.44263,
			1344.37024,
			1346.36865,
			1348.37927,
			1350.39771,
			1352.42334,
			1353.44006,
			1355.47119,
			1357.49878,
			1358.51672,
			1360.561,
			1362.61572,
			1364.6814,
			1365.71887,
			1367.78442,
			1369.87793,
			1371.97766,
			1374.07581,
			1375.12634,
			1377.23315,
			1378.29077,
			1380.41333,
			1382.54541,
			1384.68774,
			1386.84082,
			1387.92114,
			1390.08984,
			1392.2666,
			1394.45337,
			1396.65039,
			1397.75269,
			1399.96484,
			1401.07434,
			1403.30017,
			1405.536,
			1407.782,
			1410.03772,
			1411.16943,
			1413.44019,
			1414.57922,
			1416.865,
			1419.16113,
			1420.31274,
			1422.6228,
			1424.9425,
			1427.272,
			1429.61133,
			1431.96045,
			1434.31958,
			1435.50269,
			1437.87659,
			1439.06714,
			1441.4552,
			1443.85254,
			1446.25891,
			1447.4657,
			1449.88623,
			1452.31592,
			1454.75488,
			1457.20313,
			1458.43066,
			1460.8927,
			1463.36328,
			1464.602,
			1467.08582,
			1469.57886,
			1472.08057,
			1473.33472,
			1475.84961,
			1478.37292,
			1480.90479,
			1483.44507,
			1484.71826,
			1487.27112,
			1488.55066,
			1491.11584,
			1493.68945,
			1496.27161,
			1498.86157,
			1500.15955,
			1502.76123,
			1505.367,
			1507.9729,
			1509.27832,
			1511.89429,
			1514.51636,
			1517.14417,
			1518.46069,
			1521.09668,
			1523.74036,
			1526.394,
			1527.72351,
			1530.38965,
			1533.06372,
			1535.74512,
			1538.43347,
			1539.78052,
			1541.12915,
			1543.83191,
			1546.54187,
			1549.259,
			1551.98315,
			1553.3479,
			1556.08289,
			1557.453,
			1560.19873,
			1562.9519,
			1565.71216,
			1568.47925,
			1569.86548,
			1572.64307,
			1575.42761,
			1578.219,
			1581.01758,
			1582.41968,
			1583.82349,
			1586.63623,
			1589.45532,
			1592.282,
			1593.69775,
			1596.53491,
			1597.95618,
			1600.80347,
			1603.65625,
			1605.08521,
			1607.94763,
			1610.81677,
			1613.69287,
			1615.133,
			1618.0188,
			1620.911,
			1623.80969,
			1626.7146,
			1628.16943,
			1631.084,
			1634.00488,
			1636.93213,
			1639.86548,
			1642.80542,
			1644.27747,
			1647.2262,
			1650.18066,
			1651.66016,
			1654.62427,
			1657.594,
			1660.56982,
			1662.06,
			1665.04553,
			1668.03674,
			1671.03345,
			1674.03442,
			1675.53625,
			1678.54468,
			1681.55859,
			1684.57788,
			1686.0896,
			1689.1178,
			1690.634,
			1693.6709,
			1696.713,
			1699.76221,
			1702.81763,
			1705.87939,
			1707.41248,
			1710.4834,
			1713.56,
			1716.64258,
			1718.18579,
			1721.27576,
			1722.82263,
			1725.9209,
			1729.02661,
			1732.14307,
			1735.2666,
			1738.39539,
			1739.958,
			1741.522,
			1744.65234,
			1747.78577,
			1750.92456,
			1754.0686,
			1755.64258,
			1758.79419,
			1761.9502,
			1763.53,
			1766.69385,
			1769.86194,
			1771.44727,
			1774.62073,
			1777.79773,
			1780.98,
			1784.16284,
			1787.35059,
			1788.94592,
			1792.14087,
			1793.73987,
			1796.94092,
			1800.14587,
			1803.35413,
			1804.96,
			1808.1748,
			1811.38843,
			1814.602,
			1817.81592,
			1821.03967,
			1822.65649,
			1825.89478,
			1827.51636,
			1830.7627,
			1832.38708,
			1835.63867,
			1838.89355,
			1842.15247,
			1845.41541,
			1848.683,
			1851.95435,
			1855.22974,
			1856.86865,
			1860.14966,
			1861.79175,
			1865.07922,
			1868.37109,
			1871.667,
			1873.31616,
			1876.6178,
			1879.92407,
			1883.23438,
			1886.54846,
			1889.8656,
			1891.52588,
			1894.84937,
			1898.17712,
			1899.84229,
			1903.176,
			1906.51355,
			1908.18408,
			1911.52783,
			1914.87573,
			1918.22729,
			1919.90454,
			1923.26147,
			1926.622,
			1929.98608,
			1933.35413,
			1935.03967,
			1938.41309,
			1941.78931,
			1943.47876,
			1946.86023,
			1950.24854,
			1953.64087,
			1957.03552,
			1960.42688,
			1962.12048,
			1965.49841,
			1967.17957,
			1970.52039,
			1973.82568,
			1977.09229,
			1978.71118,
			1981.91907,
			1985.08862,
			1988.21936,
			1989.77,
			1992.8429,
			1995.87964,
			1997.38452,
			2000.367,
			2003.31189,
			2006.21875,
			2009.0874,
			2010.50708,
			2013.318,
			2016.09058,
			2018.825,
			2020.17773,
			2022.85437,
			2025.492,
			2026.79663,
			2029.37708,
			2031.91992,
			2034.42468,
			2036.8916,
			2038.11084,
			2040.523,
			2042.90015,
			2045.24353,
			2047.55164,
			2048.69116,
			2050.94141,
			2052.05249,
			2054.24561,
			2056.40137,
			2058.51929,
			2060.6,
			2061.62646,
			2063.65161,
			2065.63965,
			2066.61987,
			2068.55249,
			2070.44775,
			2071.38135,
			2073.22266,
			2075.02979,
			2076.803,
			2078.54175,
			2080.24365,
			2081.08057,
			2082.727,
			2084.33667,
			2085.128,
			2086.68237,
			2088.2002,
			2088.94556,
			2090.40723,
			2091.12451,
			2092.53223,
			2093.90552,
			2095.23755,
			2096.53418,
			2097.809,
			2099.041,
			2099.63647,
			2100.7915,
			2101.91235,
			2102.46045,
			2103.53125,
			2104.567,
			2105.55835,
			2106.51978,
			2107.45581,
			2107.91577,
			2108.82129,
			2109.26733,
			2110.16235,
			2111.0437,
			2111.47925,
			2112.34326,
			2113.2002,
			2113.6272,
			2114.48022,
			2115.34644,
			2116.21045,
			2117.07373,
			2117.938,
			2118.37085,
			2119.23877,
			2120.12769,
			2120.57617,
			2121.47437,
			2122.37622,
			2123.2832,
			2124.19727,
			2125.14014,
			2125.62085,
			2126.585,
			2127.06885,
			2128.03931,
			2129.01416,
			2130.013,
			2131.05371,
			2131.57324,
			2132.61182,
			2133.65,
			2134.689,
			2135.25244,
			2136.37573,
			2137.49316,
			2138.05029,
			2139.16113,
			2140.298,
			2141.46753,
			2142.62573,
			2143.20117,
			2144.34668,
			2145.52051,
			2146.71924,
			2147.31567,
			2148.50342,
			2149.68848,
			2150.29614,
			2151.53174,
			2152.76221,
			2153.9917,
			2155.23267,
			2155.8728,
			2156.51221,
			2157.79028,
			2159.071,
			2160.36426,
			2161.684,
			2163.006,
			2163.66943,
			2165.0022,
			2166.17822,
			2167.33643,
			2168.50122,
			2169.087,
			2170.26733,
			2171.22168,
			2171.69775,
			2172.655,
			2173.621,
			2174.1084,
			2175.09229,
			2176.08423,
			2177.08179,
			2178.089,
			2179.1084,
			2179.62329,
			2180.675,
			2181.806,
			2182.37549,
			2183.52246,
			2184.68115,
			2185.26514,
			2186.47241,
			2187.69946,
			2188.93359,
			2190.17188,
			2191.42,
			2192.048,
			2193.30859,
			2194.571,
			2195.204,
			2196.47852,
			2197.75879,
			2198.39941,
			2199.68457,
			2200.97437,
			2202.26025,
			2203.54736,
			2204.837,
			2205.48242,
			2206.761,
			2207.406,
			2208.701,
			2210.00635,
			2211.32617,
			2211.99146,
			2213.33447,
			2214.69287,
			2216.06714,
			2217.457,
			2218.8623,
			2219.5708,
			2220.99951,
			2221.71924,
			2223.17041,
			2224.63647,
			2225.37549,
			2226.86426,
			2228.36841,
			2229.88745,
			2231.42139,
			2232.97217,
			2234.5376,
			2235.326,
			2236.11743,
			2237.71631,
			2239.32813,
			2240.94775,
			2242.59351,
			2243.41162,
			2245.04736,
			2246.68237,
			2248.323,
			2249.14038,
			2250.76978,
			2251.58154,
			2253.20825,
			2254.825,
			2256.43262,
			2258.03662,
			2259.635,
			2260.43286,
			2262.027,
			2263.618,
			2264.41284,
			2266.00342,
			2267.59229,
			2268.38428,
			2269.968,
			2271.55127,
			2273.128,
			2274.69775,
			2276.26563,
			2277.04956,
			2278.595,
			2279.36426,
			2280.901,
			2282.43677,
			2283.2002,
			2284.72388,
			2286.24463,
			2287.77563,
			2289.34668,
			2290.91357,
			2291.69629,
			2293.30713,
			2294.1416,
			2295.80615,
			2297.468,
			2299.21265,
			2300.96021,
			2301.83325,
			2303.61963,
			2305.43677,
			2307.251,
			2308.171,
			2310.03174,
			2311.88916,
			2312.81567,
			2314.63354,
			2316.447,
			2318.25049,
			2320.02051,
			2320.905,
			2322.67383,
			2324.46313,
			2325.35938,
			2327.15381,
			2328.95142,
			2329.851,
			2331.65283,
			2333.45142,
			2335.17871,
			2336.91016,
			2338.63062,
			2339.43335,
			2341.0437,
			2342.663,
			2343.47632,
			2345.02515,
			2346.58618,
			2347.37134,
			2348.95264,
			2350.55078,
			2352.149,
			2353.74731,
			2355.38086,
			2356.20239,
			2357.85327,
			2358.68335,
			2360.37646,
			2362.081,
			2363.79419,
			2364.66821,
			2366.426,
			2368.19043,
			2369.97,
			2371.761,
			2372.6582,
			2374.45679,
			2376.25879,
			2377.161,
			2378.96924,
			2379.875,
			2381.68945,
			2383.509,
			2385.32324,
			2387.139,
			2388.9624,
			2389.857,
			2391.64185,
			2393.43652,
			2394.33765,
			2396.12451,
			2397.92188,
			2399.73047,
			2400.638,
			2402.46045,
			2404.2937,
			2406.13159,
			2407.97852,
			2408.90625,
			2410.794,
			2411.75049,
			2413.67163,
			2415.62036,
			2417.59814,
			2419.58643,
			2421.57129,
			2422.56714,
			2424.56641,
			2426.554,
			2427.55933,
			2429.578,
			2431.61963,
			2432.646,
			2440.94653,
			2443.05029,
			2444.10547,
			2446.22559,
			2448.35767,
			2449.427,
			2451.57471,
			2453.734,
			2454.817,
			2456.98877,
			2459.1687,
			2461.35718,
			2463.55322,
			2465.75854,
			2466.86377,
			2469.08154,
			2470.19385,
			2472.42383,
			2474.6626,
			2476.9104,
			2478.0376,
			2480.29956,
			2482.57056,
			2484.851,
			2487.14185,
			2488.2915,
			2490.598,
			2492.91626,
			2494.079,
			2496.41235,
			2498.75659,
			2501.11035,
			2502.28735,
			2504.64526,
			2507.01147,
			2509.38184,
			2511.76172,
			2512.95557,
			2515.35,
			2517.75269,
			2518.958,
			2521.37378,
			2523.80322,
			2526.24341,
			2527.46655,
			2529.92432,
			2532.38916,
			2534.85742,
			2537.33423,
			2538.57056,
			2541.04785,
			2542.29,
			2544.7832,
			2547.28174,
			2549.79248,
			2552.30054,
			2553.55127,
			2556.039,
			2558.50269,
			2560.93677,
			2562.14038,
			2564.519,
			2566.85938,
			2568.015,
			2570.29736,
			2572.5415,
			2574.75024,
			2576.92139,
			2577.99268,
			2580.11377,
			2582.19751,
			2584.241,
			2586.24463,
			2587.23218,
			2589.176,
			2590.13184,
			2592.01538,
			2593.86182,
			2595.67334,
			2597.44971,
			2598.325,
			2600.04761,
			2601.73242,
			2603.37964,
			2604.18921,
			2605.78052,
			2606.562,
			2608.09741,
			2609.59375,
			2611.052,
			2612.47363,
			2613.858,
			2614.53052,
			2615.85156,
			2617.156,
			2617.79443,
			2619.049,
			2620.26025,
			2621.45264,
			2622.043,
			2623.21484,
			2624.372,
			2625.512,
			2626.64355,
			2627.207,
			2628.33154,
			2629.45728,
			2630.021,
			2631.14355,
			2632.262,
			2632.82031,
			2633.93677,
			2635.07031,
			2635.637,
			2636.768,
			2637.89868,
			2639.031,
			2640.18237,
			2640.75879,
			2641.91187,
			2643.067,
			2644.22656,
			2644.822,
			2646.015,
			2647.206,
			2648.398,
			2649.59717,
			2650.81421,
			2651.42041,
			2652.63086,
			2653.23584,
			2654.44849,
			2655.711,
			2656.96875,
			2657.59717,
			2658.22559,
			2659.48975,
			2660.85156,
			2661.532,
			2662.89331,
			2664.258,
			2664.96777,
			2666.40625,
			2667.12622,
			2668.57056,
			2669.29565,
			2670.75439,
			2672.2168,
			2673.684,
			2675.18262,
			2676.71533,
			2678.25,
			2679.797,
			2680.61621,
			2682.252,
			2683.06982,
			2684.706,
			2686.24561,
			2687.77881,
			2688.54468,
			2690.05518,
			2691.421,
			2692.7832,
			2694.14453,
			2694.826,
			2696.13379,
			2697.435,
			2698.08569,
			2699.38867,
			2700.68164,
			2701.96167,
			2702.60181,
			2703.88452,
			2704.52783,
			2705.81128,
			2707.08984,
			2708.372,
			2709.66113,
			2710.309,
			2711.63672,
			2712.96924,
			2714.308,
			2714.98047,
			2716.32471,
			2717.67,
			2719.01855,
			2720.37231,
			2721.05054,
			2722.40942,
			2723.09033,
			2724.45654,
			2725.82837,
			2727.203,
			2728.584,
			2729.97266,
			2730.65845,
			2732.08374,
			2733.52612,
			2734.25342,
			2735.71924,
			2737.242,
			2738.02637,
			2739.581,
			2741.15161,
			2742.74463,
			2744.33887,
			2745.9624,
			2746.78076,
			2748.42969,
			2750.09326,
			2750.93066,
			2752.61572,
			2754.30249,
			2755.14551,
			2756.8396,
			2758.54883,
			2760.27148,
			2762.00952,
			2762.88428,
			2764.64355,
			2766.416,
			2767.30615,
			2769.0957,
			2770.89917,
			2772.71045,
			2773.62036,
			2775.4502,
			2777.292,
			2779.14551,
			2781.01172,
			2781.949,
			2783.831,
			2785.72559,
			2786.67773,
			2788.58862,
			2790.512,
			2792.44531,
			2793.413,
			2795.358,
			2797.309,
			2799.25537,
			2800.23267,
			2802.19629,
			2803.18164,
			2805.1604,
			2807.15283,
			2809.17163,
			2811.20166,
			2813.249,
			2814.27954,
			2816.34766,
			2818.42017,
			2820.49658,
			2821.5376,
			2823.63281,
			2824.686,
			2826.79932,
			2828.92773,
			2831.069,
			2832.14355,
			2834.306,
			2836.47974,
			2838.667,
			2840.86768,
			2843.07983,
			2844.19165,
			2846.42334,
			2848.66675,
			2849.79346,
			2852.0542,
			2854.32129,
			2855.458,
			2857.73828,
			2860.021,
			2862.31274,
			2864.6123,
			2865.76563,
			2868.07935,
			2870.40283,
			2871.56836,
			2873.90674,
			2876.255,
			2877.43262,
			2879.79468,
			2882.16577,
			2884.54688,
			2886.9375,
			2889.33838,
			2891.75,
			2892.95947,
			2895.3938,
			2897.844,
			2899.07251,
			2901.55615,
			2904.04883,
			2906.559,
			2909.07935,
			2910.3418,
			2912.87329,
			2915.40771,
			2916.672,
			2919.20825,
			2921.74146,
			2924.28027,
			2925.5542,
			2928.11,
			2930.67627,
			2933.25586,
			2935.84448,
			2937.14355,
			2939.74756,
			2942.35522,
			2943.66064,
			2946.26758,
			2948.873,
			2950.179,
			2952.796,
			2955.42065,
			2958.05273,
			2960.69214,
			2963.34033,
			2964.66748,
			2967.327,
			2968.65918,
			2971.33057,
			2974.01025,
			2976.69775,
			2978.044,
			2980.74268,
			2983.449,
			2986.16284,
			2988.88428,
			2991.61328,
			2992.98047,
			2995.72021,
			2998.46753,
			2999.84375,
			3002.60278,
			3005.37,
			3006.75635,
			3009.5354,
			3012.322,
			3015.11621,
			3017.91821,
			3020.72729,
			3022.13477,
			3024.95361,
			3027.77954,
			3029.19531,
			3032.03125,
			3034.878,
			3036.305,
			3039.16553,
			3042.036,
			3044.92,
			3047.81616,
			3050.716,
			3052.168,
			3055.065,
			3056.50977,
			3059.40552,
			3062.3103,
			3065.225,
			3066.68677,
			3069.615,
			3072.5498,
			3075.49146,
			3078.44,
			3079.9165,
			3082.87524,
			3085.83984,
			3087.32446,
			3090.29834,
			3093.278,
			3096.2644,
			3097.75977,
			3100.75537,
			3103.75684,
			3106.76416,
			3109.77734,
			3111.28564,
			3114.30664,
			3115.819,
			3118.84814,
			3121.88184,
			3124.92017,
			3127.962,
			3129.48486,
			3132.53369,
			3135.588,
			3138.65015,
			3140.20947,
			3143.29175,
			3144.8374,
			3147.91846,
			3151.041,
			3154.1897,
			3157.38379,
			3160.58838,
			3162.19458,
			3165.42261,
			3168.63623,
			3170.23535,
			3173.427,
			3176.604,
			3178.19336,
			3181.37915,
			3184.55957,
			3187.73657,
			3190.89551,
			3194.05322,
			3195.63525,
			3198.8042,
			3200.399,
			3203.56543,
			3206.796,
			3210.00684,
			3211.61523,
			3214.83447,
			3218.05469,
			3221.27661,
			3224.50122,
			3226.10986,
			3229.30347,
			3232.516,
			3234.12378,
			3237.36621,
			3240.58154,
			3243.78564,
			3245.38843,
			3248.55615,
			3251.752,
			3254.94873,
			3258.14966,
			3259.75146,
			3262.95142,
			3266.15161,
			3267.747,
			3270.94067,
			3274.13232,
			3275.72949,
			3278.93115,
			3282.13867,
			3283.745,
			3286.96045,
			3290.18262,
			3293.41,
			3296.64233,
			3298.26172,
			3301.504,
			3304.74951,
			3307.991,
			3311.21777,
			3312.82422,
			3316.018,
			3319.18164,
			3322.30981,
			3325.40039,
			3326.932,
			3329.96973,
			3331.476,
			3334.4624,
			3337.411,
			3340.31934,
			3343.188,
			3344.608,
			3347.41846,
			3350.19141,
			3352.927,
			3354.28027,
			3356.958,
			3358.283,
			3360.90381,
			3363.4873,
			3366.03271,
			3368.5415,
			3371.0127,
			3372.23438,
			3374.65063,
			3377.03174,
			3378.20923,
			3380.538,
			3382.83,
			3383.96167,
			3386.197,
			3388.39453,
			3390.55444,
			3392.67676,
			3394.761,
			3395.789,
			3397.81714,
			3399.808,
			3400.78931,
			3402.724,
			3404.62256,
			3405.55786,
			3407.40186,
			3408.31079,
			3410.103,
			3411.8623,
			3413.5813,
			3415.25977,
			3416.902,
			3417.723,
			3419.2876,
			3420.81055,
			3422.29932,
			3423.03149,
			3424.445,
			3425.82617,
			3427.17969,
			3427.84717,
			3429.16284,
			3429.81226,
			3430.457,
			3431.73438,
			3432.36816,
			3433.63477,
			3434.90015,
			3436.15381,
			3436.77759,
			3438.02173,
			3439.28149,
			3440.539,
			3441.79248,
			3442.41846,
			3443.67163,
			3444.3,
			3445.55444,
			3446.80713,
			3448.06,
			3449.31787,
			3449.94824,
			3451.2085,
			3452.47021,
			3453.735,
			3454.37451,
			3455.659,
			3456.30176,
			3457.59,
			3458.88452,
			3460.20581,
			3461.53418,
			3462.86768,
			3463.537,
			3464.89014,
			3466.2583,
			3466.94434,
			3468.3208,
			3469.012,
			3470.40527,
			3471.80322,
			3473.20459,
			3474.61377,
			3476.046,
			3477.477,
			3478.90942,
			3480.36328,
			3481.82568,
			3483.28564,
			3484.74927,
			3485.496,
			3486.984,
			3488.46729,
			3489.95728,
			3490.7146,
			3492.22314,
			3492.974,
			3494.47266,
			3496.00415,
			3496.76855,
			3498.29248,
			3499.81226,
			3501.32715,
			3502.8374,
			3504.34473,
			3505.09351,
			3506.58081,
			3508.06641,
			3508.80859,
			3510.30371,
			3511.062,
			3512.57715,
			3514.092,
			3515.623,
			3517.17041,
			3518.71582,
			3520.26318,
			3521.04053,
			3522.59131,
			3524.13916,
			3524.91333,
			3526.42822,
			3527.934,
			3529.437,
			3530.942,
			3532.445,
			3533.19482,
			3534.69214,
			3535.44067,
			3536.96338,
			3538.486,
			3540.00562,
			3540.76514,
			3542.308,
			3543.08,
			3544.62134,
			3546.16064,
			3547.69336,
			3549.22217,
			3549.9856,
			3551.51318,
			3553.08765,
			3554.65747,
			3556.225,
			3557.0144,
			3558.61768,
			3560.2168,
			3561.81543,
			3562.59619,
			3564.137,
			3565.67383,
			3566.44141,
			3567.92822,
			3569.34888,
			3570.766,
			3572.18359,
			3572.86426,
			3574.18262,
			3575.499,
			3576.81641,
			3577.476,
			3578.77686,
			3580.07446,
			3580.72339,
			3582.02246,
			3583.32349,
			3583.973,
			3585.26953,
			3586.565,
			3587.8606,
			3589.17578,
			3589.832,
			3591.14258,
			3592.452,
			3593.1084,
			3594.489,
			3595.86816,
			3597.2478,
			3598.6377,
			3600.05981,
			3600.77124,
			3602.19556,
			3603.62427,
			3604.34619,
			3605.79346,
			3607.24756,
			3607.97754,
			3609.443,
			3610.915,
			3612.398,
			3613.89282,
			3615.353,
			3616.09473,
			3617.59033,
			3619.10083,
			3620.61084,
			3621.37085,
			3622.90332,
			3623.6748,
			3625.23462,
			3626.81372,
			3628.40356,
			3630.017,
			3631.64282,
			3632.457,
			3634.08838,
			3635.73,
			3636.55518,
			3638.20557,
			3639.85767,
			3640.68237,
			3642.329,
			3643.15234,
			3644.79834,
			3646.43921,
			3648.07617,
			3649.71216,
			3651.358,
			3652.18384,
			3653.83374,
			3655.48145,
			3657.166,
			3658.00732,
			3659.68872,
			3661.38623,
			3663.105,
			3664.82153,
			3666.543,
			3667.40674,
			3669.13354,
			3669.99683,
			3671.71729,
			3673.43,
			3674.28638,
			3676.001,
			3677.68945,
			3679.379,
			3681.07251,
			3682.778,
			3683.632,
			3685.34375,
			3686.20166,
			3687.959,
			3689.72266,
			3691.49487,
			3693.291,
			3694.19141,
			3695.99487,
			3697.81055,
			3699.63721,
			3700.55469,
			3702.39575,
			3703.31738,
			3705.16943,
			3707.034,
			3707.95654,
			3709.809,
			3711.67432,
			3713.53784,
			3715.406,
			3717.28638,
			3719.18823,
			3720.14355,
			3722.062,
			3723.02979,
			3724.984,
			3726.94653,
			3728.94336,
			3730.96826,
			3733.002,
			3734.031,
			3735.06274,
			3737.1333,
			3739.20215,
			3741.26221,
			3743.333,
			3744.35645,
			3746.38965,
			3748.43262,
			3750.42261,
			3752.408,
			3754.399,
			3755.38818,
			3757.375,
			3758.37231,
			3760.36816,
			3762.36914,
			3764.381,
			3765.39,
			3767.414,
			3769.44849,
			3771.4917,
			3773.544,
			3775.59839,
			3776.62939,
			3778.69629,
			3779.716,
			3781.80176,
			3783.89844,
			3786.00684,
			3787.068,
			3789.19775,
			3791.33887,
			3793.49365,
			3795.65771,
			3796.74487,
			3798.927,
			3801.11865,
			3802.21924,
			3804.42822,
			3806.64673,
			3808.87842,
			3809.998,
			3812.24365,
			3814.51929,
			3816.7854,
			3819.04248,
			3821.34619,
			3822.4917,
			3824.78784,
			3825.94043,
			3828.254,
			3830.55762,
			3832.9082,
			3834.07715,
			3835.229,
			3837.57813,
			3839.935,
			3842.30176,
			3844.67725,
			3847.06177,
			3849.455,
			3850.65576,
			3853.06323,
			3855.4812,
			3857.9082,
			3859.125,
			3861.56641,
			3864.01636,
			3866.475,
			3868.94263,
			3870.17969,
			3872.66016,
			3873.90381,
			3876.39868,
			3878.90234,
			3881.41528,
			3883.93677,
			3885.20117,
			3887.73584,
			3890.27881,
			3892.83081,
			3895.391,
			3896.67432,
			3899.247,
			3900.53613,
			3903.121,
			3905.71387,
			3908.31543,
			3910.92139,
			3912.22388,
			3914.8335,
			3916.14063,
			3918.76074,
			3921.38354,
			3924.00439,
			3925.311,
			3927.91016,
			3930.48535,
			3933.03076,
			3934.291,
			3936.78564,
			3939.246,
			3940.46387,
			3942.875,
			3945.25171,
			3947.59424,
			3949.89966,
			3951.0376,
			3953.28662,
			3955.49756,
			3957.67261,
			3958.74683,
			3960.86816,
			3962.95313,
			3963.982,
			3966.01172,
			3968.005,
			3969.96143,
			3971.88184,
			3972.82837,
			3974.69482,
			3976.52832,
			3978.33057,
			3979.22021,
			3980.97583,
			3982.69751,
			3983.546,
			3985.217,
			3986.85376,
			3988.45532,
			3990.03369,
			3990.805,
			3992.32837,
			3993.82178,
			3994.55566,
			3996.01172,
			3997.458,
			3998.17969,
			3999.60352,
			4001.024,
			4002.44434,
			4003.86963,
			4005.30176,
			4006.018,
			4007.45215,
			4008.17041,
			4009.61475,
			4011.062,
			4012.50879,
			4013.23267,
			4014.6853,
			4016.13916,
			4017.593,
			4019.04858,
			4020.52539,
			4021.26367,
			4022.73926,
			4023.477,
			4024.972,
			4025.725,
			4027.23,
			4027.98145,
			4028.73242,
			4030.252,
			4031.0166,
			4032.54346,
			4034.069,
			4035.62231,
			4037.19043,
			4037.97388,
			4039.54053,
			4041.143,
			4042.75049,
			4044.357,
			4045.16235,
			4045.97241,
			4047.5918,
			4049.21216,
			4050.83228,
			4052.44751,
			4053.255,
			4054.87134,
			4056.51123,
			4058.15869,
			4059.80664,
			4061.48315,
			4062.32813,
			4064.0166,
			4065.705,
			4066.54883,
			4068.23315,
			4069.91553,
			4070.75684,
			4072.41016,
			4074.062,
			4075.71533,
			4077.33276,
			4078.14,
			4079.75586,
			4081.37744,
			4082.19043,
			4083.81787,
			4084.633,
			4086.27124,
			4087.94287,
			4089.61963,
			4090.46021,
			4092.15479,
			4093.86133,
			4095.57471,
			4097.29,
			4098.147,
			4099.8667,
			4101.596,
			4102.45264,
			4104.166,
			4105.02637,
			4105.88867,
			4107.61865,
			4109.35254,
			4111.095,
			4111.9707,
			4113.75439,
			4114.65039,
			4116.44727,
			4118.234,
			4120.015,
			4121.80371,
			4122.685,
			4124.40967,
			4125.274,
			4127.008,
			4128.699,
			4130.38,
			4132.06836,
			4132.91748,
			4134.626,
			4136.34326,
			4138.077,
			4139.84863,
			4140.73828,
			4142.52637,
			4143.43164,
			4145.26563,
			4146.1875,
			4148.04,
			4149.92,
			4151.814,
			4153.72266,
			4155.65234,
			4157.59375,
			4158.56934,
			4160.53857,
			4161.52734,
			4163.514,
			4165.517,
			4167.53174,
			4169.559,
			4171.598,
			4172.622,
			4174.677,
			4176.72168,
			4177.756,
			4179.83154,
			4181.912,
			4182.95654,
			4185.04834,
			4187.13672,
			4189.23047,
			4191.33447,
			4193.44873,
			4194.51,
			4196.641,
			4197.711,
			4199.858,
			4202.01855,
			4203.103,
			4205.2793,
			4207.466,
			4209.6626,
			4210.76465,
			4212.97559,
			4215.197,
			4217.42871,
			4219.671,
			4221.92773,
			4223.0625,
			4225.33838,
			4227.62256,
			4228.76758,
			4231.066,
			4232.215,
			4234.52,
			4236.83545,
			4239.161,
			4241.49658,
			4243.84326,
			4246.199,
			4247.38037,
			4249.75,
			4252.12842,
			4253.32227,
			4255.717,
			4258.11963,
			4260.53174,
			4262.952,
			4264.165,
			4266.59668,
			4267.816,
			4270.26074,
			4272.71436,
			4273.94434,
			4276.411,
			4278.8877,
			4281.37646,
			4283.87354,
			4286.38,
			4287.63574,
			4290.15234,
			4292.676,
			4293.94043,
			4296.47656,
			4299.02051,
			4301.55566,
			4304.099,
			4305.379,
			4306.66162,
			4309.23242,
			4311.81055,
			4314.397,
			4316.992,
			4319.59668,
			4320.83
		],
		[
			0,
			0.0429229736,
			0.09069824,
			0.1377716,
			0.1676178,
			0.198806763,
			0.244094849,
			0.291854858,
			0.338409424,
			0.3825531,
			0.428604126,
			0.473098755,
			0.520080566,
			0.566452,
			0.611618042,
			0.659927368,
			0.6905823,
			0.7368164,
			0.7835846,
			0.828445435,
			0.8781738,
			0.92741394,
			0.941482544,
			0.988723755,
			1.03547668,
			1.08184814,
			1.12709045,
			1.17378235,
			1.2052002,
			1.25222778,
			1.2959137,
			1.343216,
			1.388977,
			1.4352417,
			1.46604919,
			1.51170349,
			1.55845642,
			1.60394287,
			1.6504364,
			1.694809,
			1.72489929,
			1.77146912,
			1.82092285,
			1.86619568,
			1.91297913,
			1.95985413,
			1.98947144,
			2.03622437,
			2.083023,
			2.12832642,
			2.17663574,
			2.22291565,
			2.25408936,
			2.30238342,
			2.34565735,
			2.39196777,
			2.43753052,
			2.48553467,
			2.517746,
			2.56253052,
			2.60736084,
			2.65307617,
			2.7013855,
			2.74519348,
			2.7774353,
			2.82318115,
			2.86994934,
			2.91223145,
			2.9616394,
			3.00630188,
			3.03755188,
			3.08428955,
			3.12957764,
			3.176422,
			3.22218323,
			3.26895142,
			3.299652,
			3.345932,
			3.39175415,
			3.43907166,
			3.48480225,
			3.531723,
			3.56178284,
			3.60862732,
			3.654358,
			3.70266724,
			3.74909973,
			3.79489136,
			3.823471,
			3.87023926,
			3.91755676,
			3.96188354,
			4.008621,
			4.05697632,
			4.086624,
			4.117325,
			4.16471863,
			4.209915,
			4.25515747,
			4.303711,
			4.34944153,
			4.395035,
			4.440857,
			4.48657227,
			4.51779175,
			4.5657196,
			4.60987854,
			4.65716553,
			4.70368958,
			4.749542,
			4.797119,
			4.843338,
			4.873001,
			4.918762,
			4.965149,
			5.00987244,
			5.056656,
			5.101898,
			5.1341095,
			5.178879,
			5.22419739,
			5.2714386,
			5.31777954,
			5.36157227,
			5.39530945,
			5.44154358,
			5.484314,
			5.533615,
			5.577408,
			5.625244,
			5.65640259,
			5.688614,
			5.733902,
			5.78273,
			5.828491,
			5.87149048,
			5.904709,
			5.951782,
			5.99871826,
			6.044388,
			6.08966064,
			6.136444,
			6.16766357,
			6.2129364,
			6.25723267,
			6.305237,
			6.34985352,
			6.39874268,
			6.427902,
			6.47615051,
			6.522415,
			6.57020569,
			6.618042,
			6.66281128,
			6.696045,
			6.740326,
			6.78858948,
			6.834854,
			6.87963867,
			6.927475,
			6.957184,
			7.005234,
			7.050232,
			7.09550476,
			7.14183044,
			7.189087,
			7.22077942,
			7.265564,
			7.310379,
			7.357666,
			7.40356445,
			7.450714,
			7.483124,
			7.528702,
			7.574539,
			7.62031555,
			7.66712952,
			7.697876,
			7.743103,
			7.789871,
			7.83616638,
			7.88447571,
			7.92774963,
			7.975601,
			8.00827,
			8.053528,
			8.100449,
			8.147293,
			8.177856,
			8.224609,
			8.271317,
			8.315674,
			8.362961,
			8.408234,
			8.453003,
			8.500809,
			8.53257751,
			8.576309,
			8.624588,
			8.670349,
			8.717636,
			8.761887,
			8.793701,
			8.838654,
			8.885254,
			8.934036,
			8.979813,
			9.027206,
			9.056274,
			9.102524,
			9.148819,
			9.195114,
			9.240921,
			9.287186,
			9.317383,
			9.365204,
			9.408447,
			9.45726,
			9.503021,
			9.549545,
			9.580002,
			9.626389,
			9.672592,
			9.703278,
			9.748199,
			9.793945,
			9.83844,
			9.869324,
			9.917633,
			9.962402,
			10.0081787,
			10.0534515,
			10.0997925,
			10.147171,
			10.1934509,
			10.2390442,
			10.2849121,
			10.3328094,
			10.362915,
			10.4087524,
			10.453476,
			10.5003052,
			10.5445862,
			10.5919342,
			10.624115,
			10.669342,
			10.7161407,
			10.7480621,
			10.7940979,
			10.8388977,
			10.8846283,
			10.9319458,
			10.9767609,
			11.0235443,
			11.0709534,
			11.1176453,
			11.1473694,
			11.1927032,
			11.2386932,
			11.28717,
			11.3331909,
			11.3787537,
			11.4095,
			11.4552307,
			11.5035248,
			11.546814,
			11.594223,
			11.6393738,
			11.6695862,
			11.7012939,
			11.7460632,
			11.7938843,
			11.8392487,
			11.8859863,
			11.93161,
			11.9771118,
			12.021347,
			12.0701752,
			12.1154785,
			12.1607666,
			12.1904755,
			12.2372437,
			12.28508,
			12.3298645,
			12.37619,
			12.421875,
			12.4535217,
			12.4993134,
			12.5446472,
			12.5888977,
			12.6371918,
			12.6840057,
			12.7136841,
			12.7574921,
			12.8057251,
			12.8510895,
			12.8963623,
			12.9431915,
			12.9748993,
			13.0201416,
			13.0684814,
			13.1132355,
			13.1585541,
			13.207016,
			13.2371674,
			13.28334,
			13.328125,
			13.3759918,
			13.4222107,
			13.4669952,
			13.4992828,
			13.5439758,
			13.59288,
			13.6379547,
			13.6833649,
			13.7291565,
			13.7613678,
			13.80661,
			13.852951,
			13.9002838,
			13.9455566,
			13.9913635,
			14.0240784,
			14.07074,
			14.1176758,
			14.16098,
			14.2072754,
			14.2540588,
			14.2857513,
			14.3300323,
			14.36174,
			14.4080505,
			14.4538269,
			14.5006561,
			14.5475464,
			14.5928345,
			14.6384125,
			14.6853027,
			14.72908,
			14.7759094,
			14.80661,
			14.8533783,
			14.8982391,
			14.9450073,
			14.9902649,
			15.0370636,
			15.06575,
			15.1136627,
			15.1594543,
			15.2041321,
			15.25145,
			15.2957306,
			15.3279266,
			15.3742676,
			15.4220428,
			15.4683228,
			15.5142517,
			15.5605774,
			15.5906525,
			15.6372223,
			15.6828156,
			15.7266388,
			15.7714539,
			15.8201141,
			15.8518066,
			15.8961029,
			15.9424438,
			15.9887085,
			16.0355225,
			16.08284,
			16.1129913,
			16.1568,
			16.2048187,
			16.2503662,
			16.2961426,
			16.3409424,
			16.37059,
			16.4027863,
			16.450592,
			16.4963531,
			16.5426788,
			16.5733185,
			16.6210785,
			16.66681,
			16.713562,
			16.7589111,
			16.80664,
			16.8518829,
			16.8825989,
			16.9143982,
			16.9596558,
			17.00592,
			17.052597,
			17.09851,
			17.143692,
			17.19046,
			17.2357025,
			17.28212,
			17.3277588,
			17.3755951,
			17.4039612,
			17.4515228,
			17.49881,
			17.5445862,
			17.5903625,
			17.6383972,
			17.6698151,
			17.7171173,
			17.7628784,
			17.8091431,
			17.8549042,
			17.9006958,
			17.9328766,
			17.97818,
			18.023407,
			18.0708923,
			18.1184845,
			18.1632385,
			18.19403,
			18.2402649,
			18.2854919,
			18.3343048,
			18.3780518,
			18.42485,
			18.4544983,
			18.5007782,
			18.5490875,
			18.5939636,
			18.6406555,
			18.68692,
			18.7206116,
			18.7492981,
			18.79712,
			18.84288,
			18.8891449,
			18.934906,
			18.9823151,
			19.0260925,
			19.0726776,
			19.1200562,
			19.1663818,
			19.2126,
			19.2433624,
			19.2900848,
			19.33583,
			19.3821411,
			19.42752,
			19.4747314,
			19.5056915,
			19.5511627,
			19.59845,
			19.6422272,
			19.69101,
			19.7367554,
			19.76796,
			19.8127136,
			19.8596039,
			19.9068451,
			19.9545441,
			19.99881,
			20.0279846,
			20.0762939,
			20.12053,
			20.1698,
			20.21257,
			20.2599487,
			20.28952,
			20.338028,
			20.3835449,
			20.4288025,
			20.47461,
			20.5223389,
			20.5535126,
			20.60028,
			20.6465454,
			20.6923676,
			20.7373657,
			20.7710876,
			20.8153229,
			20.8613586,
			20.90866,
			20.9538879,
			21.0021973,
			21.0465088,
			21.07666,
			21.1255035,
			21.1720123,
			21.21698,
			21.2627411,
			21.308548,
			21.3417664,
			21.3874817,
			21.4328461,
			21.479538,
			21.52536,
			21.5718384,
			21.6022949,
			21.64917,
			21.6949615,
			21.7406921,
			21.7858887,
			21.8322,
			21.8619232,
			21.9096985,
			21.9544373,
			22.00122,
			22.0481415,
			22.0939178,
			22.1235046,
			22.1710968,
			22.2165527,
			22.2639313,
			22.3096619,
			22.3553925,
			22.387558,
			22.4329224,
			22.4781342,
			22.5108337,
			22.5570984,
			22.60083,
			22.6460876,
			22.6929932,
			22.7380371,
			22.78798,
			22.833252,
			22.87703,
			22.9102325,
			22.9555359,
			23.0027618,
			23.048172,
			23.0924072,
			23.1390533,
			23.16977,
			23.216568,
			23.2623444,
			23.3081818,
			23.3538818,
			23.4011841,
			23.4309235,
			23.4771881,
			23.523941,
			23.5691833,
			23.61345,
			23.66275,
			23.6909485,
			23.7372131,
			23.7840271,
			23.8292847,
			23.87613,
			23.9233856,
			23.9540558,
			24.0018616,
			24.045105,
			24.0930176,
			24.1402588,
			24.18599,
			24.2166748,
			24.263443,
			24.310257,
			24.3565063,
			24.4022675,
			24.4465332,
			24.4782715,
			24.5215,
			24.5682678,
			24.6175842,
			24.66185,
			24.709671,
			24.73935,
			24.7866058,
			24.8323975,
			24.8796539,
			24.92244,
			24.9701843,
			25.00035,
			25.04773,
			25.09401,
			25.1379547,
			25.1844635,
			25.2318573,
			25.26294,
			25.310257,
			25.3539886,
			25.4002838,
			25.4472046,
			25.4918518,
			25.5220032,
			25.5677948,
			25.6160889,
			25.6589661,
			25.7055817,
			25.7369843,
			25.7836914,
			25.8288269,
			25.8746643,
			25.9208679,
			25.9676361,
			26.0129242,
			26.0416412,
			26.08992,
			26.1356812,
			26.1829376,
			26.2277069,
			26.2723541,
			26.3053741,
			26.3499451,
			26.3977966,
			26.4430084,
			26.48822,
			26.5349884,
			26.56668,
			26.6119843,
			26.6577911,
			26.7052612,
			26.7517853,
			26.7955627,
			26.8273468,
			26.8736572,
			26.9187775,
			26.9670868,
			27.0123444,
			27.058136,
			27.0877838,
			27.1345367,
			27.1809387,
			27.2271881,
			27.2705231,
			27.3175964,
			27.34938,
			27.3946533,
			27.4403076,
			27.4855652,
			27.5333557,
			27.5801086,
			27.6098328,
			27.654541,
			27.7028046,
			27.748703,
			27.7939758,
			27.84166,
			27.8715363,
			27.91806,
			27.96634,
			28.012146,
			28.0408173,
			28.0900879,
			28.1358337,
			28.1786041,
			28.2278748,
			28.2713013,
			28.3199,
			28.365799,
			28.3958435,
			28.4401245,
			28.48735,
			28.5336456,
			28.5779419,
			28.6262817,
			28.6543274,
			28.7026062,
			28.7475128,
			28.7932739,
			28.8419647,
			28.88617,
			28.91687,
			28.9651337,
			28.9939117,
			29.0415955,
			29.0873413,
			29.1331482,
			29.1798553,
			29.22464,
			29.2710571,
			29.3191833,
			29.3649445,
			29.40921,
			29.439682,
			29.4866333,
			29.53241,
			29.5786438,
			29.6239471,
			29.6701813,
			29.7028961,
			29.7493744,
			29.79541,
			29.8401947,
			29.8864441,
			29.9322052,
			29.9644928,
			30.0086823,
			30.0569611,
			30.1022186,
			30.149353,
			30.1973877,
			30.22493,
			30.27092,
			30.31749,
			30.365921,
			30.4115448,
			30.4568329,
			30.487,
			30.5353088,
			30.579071,
			30.6259918,
			30.6741638,
			30.71289,
			30.7451172,
			30.7893524,
			30.8346558,
			30.86737,
			30.9116516,
			30.9590149,
			31.0061188,
			31.0529633,
			31.0957489,
			31.1435547,
			31.1903381,
			31.2370758,
			31.2672882,
			31.3120728,
			31.3604431,
			31.4056244,
			31.4519043,
			31.498291,
			31.5284424,
			31.57373,
			31.6194763,
			31.6677551,
			31.7130127,
			31.7578125,
			31.7884521,
			31.8357239,
			31.8822021,
			31.9274139,
			31.9750977,
			32.01985,
			32.05307,
			32.098526,
			32.14366,
			32.1889038,
			32.23741,
			32.2829742,
			32.3126678,
			32.3600159,
			32.40776,
			32.4529877,
			32.50078,
			32.5445557,
			32.576767,
			32.6250763,
			32.6687927,
			32.7158051,
			32.74527,
			32.7926636,
			32.8408661,
			32.8887024,
			32.93544,
			32.9792023,
			33.0245056,
			33.0715332,
			33.10437,
			33.15175,
			33.1981659,
			33.2448273,
			33.29167,
			33.3354034,
			33.351532,
			33.3989258,
			33.4426422,
			33.4888458,
			33.5366364,
			33.58342,
			33.61261,
			33.6605072,
			33.7044525,
			33.75392,
			33.8002167,
			33.8444824,
			33.8751678,
			33.92247,
			33.9687347,
			34.0165558,
			34.0623474,
			34.1071777,
			34.1222076,
			34.16948,
			34.2137451,
			34.262558,
			34.3093719,
			34.3535919,
			34.3842926,
			34.4171143,
			34.4628,
			34.5070343,
			34.5547943,
			34.6011047,
			34.6463776,
			34.67711,
			34.7243958,
			34.7722168,
			34.816925,
			34.86223,
			34.9105377,
			34.9573364,
			35.003067,
			35.05008,
			35.094635,
			35.1400146,
			35.1712952,
			35.22029,
			35.26535,
			35.30983,
			35.35756,
			35.40576,
			35.43599,
			35.4827576,
			35.5271149,
			35.57428,
			35.6205139,
			35.6657867,
			35.6964874,
			35.7427673,
			35.79001,
			35.83725,
			35.8836365,
			35.92926,
			35.960556,
			36.0063171,
			36.0509644,
			36.0983276,
			36.1440125,
			36.18927,
			36.2209625,
			36.26924,
			36.3141479,
			36.36087,
			36.4071045,
			36.45131,
			36.4830933,
			36.5278168,
			36.57451,
			36.61975,
			36.6675262,
			36.7118378,
			36.7449951,
			36.78018,
			36.82747,
			36.87378,
			36.921524,
			36.9663849,
			36.9964752,
			37.04123,
			37.08696,
			37.1337433,
			37.17752,
			37.22429,
			37.254425,
			37.3031921,
			37.3491821,
			37.3948059,
			37.44005,
			37.48726,
			37.5169678,
			37.5640259,
			37.6095428,
			37.6567841,
			37.7047577,
			37.7503052,
			37.78,
			37.82637,
			37.8731842,
			37.92035,
			37.96663,
			38.0128937,
			38.04361,
			38.0898743,
			38.12056,
			38.1655273,
			38.2106171,
			38.256897,
			38.3044128,
			38.35048,
			38.39885,
			38.44455,
			38.4902954,
			38.537323,
			38.5672455,
			38.6120453,
			38.6599274,
			38.7066345,
			38.75438,
			38.8011627,
			38.8309326,
			38.8761444,
			38.92488,
			38.969635,
			39.0161438,
			39.0622253,
			39.0935059,
			39.1396332,
			39.18596,
			39.2324677,
			39.2775726,
			39.325943,
			39.35553,
			39.4013062,
			39.4485931,
			39.49536,
			39.52516,
			39.5715332,
			39.61676,
			39.6628723,
			39.7106476,
			39.7564545,
			39.8012238,
			39.8464966,
			39.8777,
			39.9245148,
			39.9702759,
			40.0185547,
			40.06183,
			40.11113,
			40.1413269,
			40.1865845,
			40.23288,
			40.27968,
			40.3250732,
			40.3704376,
			40.40143,
			40.44722,
			40.4936371,
			40.54033,
			40.5851,
			40.6324921,
			40.6630859,
			40.7098846,
			40.7541351,
			40.8004456,
			40.84796,
			40.8942566,
			40.9242554,
			40.97101,
			41.0182953,
			41.06523,
			41.10988,
			41.156662,
			41.18634,
			41.2346344,
			41.2799072,
			41.3263245,
			41.372406,
			41.41829,
			41.44998,
			41.49527,
			41.5410767,
			41.5878143,
			41.63562,
			41.683075,
			41.7121277,
			41.756424,
			41.80371,
			41.85051,
			41.89479,
			41.94362,
			41.97284,
			42.02005,
			42.06685,
			42.09459,
			42.1403351,
			42.1891022,
			42.2333832,
			42.2776947,
			42.3255,
			42.3728943,
			42.420517,
			42.46591,
			42.49498,
			42.539444,
			42.5865326,
			42.6353455,
			42.66603,
			42.7118225,
			42.75609,
			42.8023834,
			42.8330841,
			42.87886,
			42.9252777,
			42.9712067,
			43.017746,
			43.0625153,
			43.11081,
			43.15715,
			43.2042542,
			43.249176,
			43.2798462,
			43.3257446,
			43.37114,
			43.4181976,
			43.46512,
			43.510788,
			43.5430145,
			43.5902863,
			43.6204529,
			43.6672668,
			43.7125244,
			43.7589722,
			43.8040924,
			43.8523865,
			43.8821869,
			43.9288635,
			43.9741821,
			44.0209351,
			44.0677338,
			44.112,
			44.1594238,
			44.20662,
			44.2362823,
			44.2835846,
			44.3288727,
			44.374176,
			44.4214325,
			44.4677124,
			44.51248,
			44.5582581,
			44.5909576,
			44.6351929,
			44.68216,
			44.72737,
			44.7755737,
			44.8218231,
			44.852005,
			44.8983765,
			44.9436035,
			44.990387,
			45.0200348,
			45.06581,
			45.1130829,
			45.15947,
			45.20558,
			45.2529144,
			45.29721,
			45.3445435,
			45.37422,
			45.42047,
			45.4662781,
			45.51465,
			45.559967,
			45.60443,
			45.6380463,
			45.683075,
			45.728363,
			45.77414,
			45.82196,
			45.8692322,
			45.8998871,
			45.93007,
			45.9778,
			46.023056,
			46.06981,
			46.11508,
			46.1624,
			46.2047729,
			46.2539063,
			46.2967072,
			46.3434753,
			46.38977,
			46.41992,
			46.4677277,
			46.5129852,
			46.5604248,
			46.6043854,
			46.65181,
			46.68199,
			46.7282867,
			46.7740631,
			46.8202667,
			46.8685455,
			46.91548,
			46.9445343,
			46.991806,
			47.0365448,
			47.085434,
			47.1331329,
			47.1769257,
			47.21025,
			47.2553558,
			47.30162,
			47.3485565,
			47.3951569,
			47.4399567,
			47.47142,
			47.51738,
			47.56166,
			47.6084137,
			47.6556854,
			47.70143,
			47.7296,
			47.7763824,
			47.82469,
			47.8704071,
			47.9167023,
			47.96245,
			47.99327,
			48.0384369,
			48.0836945,
			48.1304474,
			48.17729,
			48.2230072,
			48.2536774,
			48.2999725,
			48.3463,
			48.3931732,
			48.4403534,
			48.4870758,
			48.52028,
			48.5625458,
			48.60933,
			48.65764,
			48.70372,
			48.7335663,
			48.7778473,
			48.8272858,
			48.8785553,
			48.9252167,
			48.9712067,
			49.0212555,
			49.03685,
			49.0832367,
			49.12892,
			49.1726837,
			49.2224579,
			49.2672577,
			49.2989349,
			49.34578,
			49.39055,
			49.4363251,
			49.4836273,
			49.52794,
			49.55922,
			49.60405,
			49.65068,
			49.6964569,
			49.74382,
			49.7905731,
			49.8197174,
			49.86705,
			49.9133148,
			49.9576263,
			50.0041046,
			50.050705,
			50.082016,
			50.12874,
			50.1728058,
			50.2212677,
			50.2671051,
			50.31285,
			50.34459,
			50.38884,
			50.4210358,
			50.46782,
			50.51259,
			50.55635,
			50.6036224,
			50.6504364,
			50.6952362,
			50.74312,
			50.7873077,
			50.8343048,
			50.8643951,
			50.9106,
			50.9579,
			51.0011444,
			51.0449371,
			51.09288,
			51.1239166,
			51.1707,
			51.2169952,
			51.2618256,
			51.3091278,
			51.3543549,
			51.38658,
			51.4298248,
			51.4776459,
			51.5219269,
			51.5692,
			51.61653,
			51.6457062,
			51.6946259,
			51.7394257,
			51.78508,
			51.8318939,
			51.8786774,
			51.9098969,
			51.9551849,
			52.00145,
			52.04973,
			52.09462,
			52.1408234,
			52.17137,
			52.21727,
			52.2651825,
			52.31038,
			52.3556366,
			52.4009247,
			52.4321442,
			52.47795,
			52.5263519,
			52.5706635,
			52.6163177,
			52.6621246,
			52.6913,
			52.73732,
			52.78441,
			52.8291779,
			52.87703,
			52.92125,
			52.953476,
			52.99977,
			53.0440826,
			53.0919037,
			53.137558,
			53.1834564,
			53.2162628,
			53.26146,
			53.3082733,
			53.33757,
			53.38423,
			53.42952,
			53.47484,
			53.52263,
			53.5694427,
			53.6162262,
			53.65959,
			53.70732,
			53.7379,
			53.7842865,
			53.8300934,
			53.8744049,
			53.9226837,
			53.9528351,
			53.99968,
			54.0434723,
			54.090744,
			54.1374664,
			54.1828156,
			54.2281036,
			54.258316,
			54.3036346,
			54.3509369,
			54.39882,
			54.4440155,
			54.4877777,
			54.520462,
			54.5659027,
			54.6130524,
			54.65947,
			54.70546,
			54.75093,
			54.78273,
			54.9793549,
			55.012558,
			55.0442963,
			55.0895844,
			55.13582,
			55.18062,
			55.2289276,
			55.2747345,
			55.3053436,
			55.3526764,
			55.398056,
			55.44478,
			55.4900665,
			55.5376434,
			55.56804,
			55.61635,
			55.660202,
			55.70793,
			55.7527,
			55.798996,
			55.827774,
			55.8749847,
			55.9202423,
			55.966507,
			56.01329,
			56.0596771,
			56.0912628,
			56.1360321,
			56.1822968,
			56.2281036,
			56.276413,
			56.3231659,
			56.3543549,
			56.3996429,
			56.4464264,
			56.4922638,
			56.5378876,
			56.5842438,
			56.61293,
			56.6627045,
			56.7064667,
			56.75374,
			56.8000336,
			56.8447723,
			56.87651,
			56.9227448,
			56.9705048,
			57.01555,
			57.0606842,
			57.10524,
			57.1379852,
			57.18486,
			57.2312469,
			57.27693,
			57.3237457,
			57.3679962,
			57.399826,
			57.4464874,
			57.4918365,
			57.5365448,
			57.583847,
			57.6322479,
			57.66234,
			57.7076569,
			57.7554474,
			57.8011932,
			57.8495026,
			57.8938751,
			57.9234467,
			57.97142,
			58.01799,
			58.0648956,
			58.1111,
			58.1568756,
			58.1885529,
			58.23436,
			58.2795868,
			58.3284454,
			58.3721161,
			58.4199371,
			58.4506073,
			58.4944,
			58.5402374,
			58.5878448,
			58.63463,
			58.67891,
			58.70912,
			58.7553864,
			58.8042145,
			58.8497162,
			58.896286,
			58.9410858,
			58.97194,
			59.01906,
			59.06636,
			59.1096649,
			59.157486,
			59.20323,
			59.2359161,
			59.28282,
			59.3285065,
			59.37538,
			59.420578,
			59.4663849,
			59.49507,
			59.5413971,
			59.58925,
			59.6354523,
			59.6797333,
			59.7285919,
			59.75728,
			59.80403,
			59.8367462,
			59.88304,
			59.9269257,
			59.97206,
			60.02092,
			60.0654144,
			60.11052,
			60.1567841,
			60.20436,
			60.24684,
			60.2811737,
			60.3275,
			60.3719635,
			60.41893,
			60.46675,
			60.5110321,
			60.541214,
			60.589035,
			60.63481,
			60.67958,
			60.7249,
			60.77147,
			60.8013763,
			60.83409,
			60.87889,
			60.9111176,
			60.95842,
			61.0036469,
			61.0514374,
			61.0661469,
			61.1113434,
			61.1450348,
			61.1887665,
			61.23558,
			61.2835236,
			61.3296356,
			61.37651,
			61.4227448,
			61.467514,
			61.5159149,
			61.5607147,
			61.5908051,
			61.6396942,
			61.68535,
			61.73021,
			61.77745,
			61.82219,
			61.8549042,
			61.8991852,
			61.9444427,
			61.9928741,
			62.03856,
			62.083786,
			62.1165,
			62.16179,
			62.2070465,
			62.25328,
			62.3006134,
			62.34636,
			62.3780975,
			62.42134,
			62.45253,
			62.4998322,
			62.5466766,
			62.58992,
			62.637558,
			62.684433,
			62.72917,
			62.77443,
			62.82274,
			62.85341,
			62.89882,
			62.9445343,
			62.9887543,
			63.0370331,
			63.0803375,
			63.12773,
			63.1599274,
			63.20375,
			63.251358,
			63.2946625,
			63.34294,
			63.38768,
			63.4203949,
			63.4651947,
			63.5124664,
			63.5567169,
			63.60298,
			63.65074,
			63.67943,
			63.7277069,
			63.7711639,
			63.8202362,
			63.86754,
			63.9123077,
			63.9445343,
			63.9887238,
			64.036026,
			64.07924,
			64.12767,
			64.17229,
			64.20537,
			64.25127,
			64.29503,
			64.34181,
			64.38756,
			64.43532,
			64.46651,
			64.51131,
			64.55653,
			64.60451,
			64.64952,
			64.6949,
			64.72554,
			64.7729,
			64.8190155,
			64.86531,
			64.91258,
			64.9563141,
			64.990036,
			65.03627,
			65.08058,
			65.1288,
			65.17461,
			65.22087,
			65.25255,
			65.29741,
			65.3431244,
			65.38884,
			65.4345856,
			65.4823761,
			65.5106049,
			65.5558,
			65.603775,
			65.65105,
			65.6956,
			65.7428741,
			65.77312,
			65.8203,
			65.86711,
			65.91087,
			65.95766,
			66.00374,
			66.03508,
			66.08336,
			66.1291351,
			66.1739044,
			66.20543,
			66.25081,
			66.29765,
			66.34285,
			66.38808,
			66.4368744,
			66.48418,
			66.52794,
			66.5607452,
			66.6043243,
			66.65364,
			66.69893,
			66.72807,
			66.7753754,
			66.82112,
			66.86644,
			66.9121857,
			66.960556,
			67.00618,
			67.05299,
			67.08421,
			67.12947,
			67.16167,
			67.2099457,
			67.25722,
			67.30348,
			67.350296,
			67.39415,
			67.4428253,
			67.4875946,
			67.53435,
			67.57863,
			67.61134,
			67.6585846,
			67.70381,
			67.74959,
			67.79494,
			67.84114,
			67.87157,
			67.91905,
			67.96498,
			68.00963,
			68.05794,
			68.10368,
			68.13637,
			68.18013,
			68.22493,
			68.27278,
			68.31999,
			68.3665,
			68.3976,
			68.44127,
			68.48958,
			68.53337,
			68.56566,
			68.6114044,
			68.65718,
			68.7045746,
			68.7488556,
			68.79463,
			68.8413239,
			68.88658,
			68.9178,
			68.9646454,
			69.0099,
			69.05565,
			69.10396,
			69.15175,
			69.18263,
			69.22774,
			69.27455,
			69.3213043,
			69.3691254,
			69.4156342,
			69.4451141,
			69.49251,
			69.537674,
			69.58351,
			69.62926,
			69.67607,
			69.70677,
			69.75453,
			69.79982,
			69.84511,
			69.89143,
			69.93721,
			69.9698944,
			70.01567,
			70.06148,
			70.10503,
			70.15303,
			70.1993256,
			70.2306061,
			70.27736,
			70.3231354,
			70.36839,
			70.4156952,
			70.46254,
			70.49065,
			70.53694,
			70.58324,
			70.63002,
			70.67638,
			70.72313,
			70.75328,
			70.79857,
			70.84639,
			70.89119,
			70.9377,
			70.98274,
			71.01195,
			71.061264,
			71.10655,
			71.15129,
			71.1986847,
			71.24489,
			71.27327,
			71.32185,
			71.3691254,
			71.41243,
			71.46138,
			71.506546,
			71.5367,
			71.584,
			71.62877,
			71.6750946,
			71.72133,
			71.76863,
			71.79857,
			71.84514,
			71.88939,
			71.93736,
			71.9834747,
			72.02928,
			72.05849,
			72.10402,
			72.151535,
			72.19734,
			72.24367,
			72.29048,
			72.3201141,
			72.36543,
			72.41173,
			72.4565,
			72.50456,
			72.5501251,
			72.58089,
			72.6261444,
			72.6739044,
			72.7181549,
			72.76543,
			72.81224,
			72.84093,
			72.88719,
			72.93346,
			72.97975,
			73.0251,
			73.07381,
			73.10414,
			73.14876,
			73.19502,
			73.24129,
			73.28755,
			73.3343353,
			73.36656,
			73.412796,
			73.45711,
			73.5052643,
			73.55113,
			73.5984344,
			73.63008,
			73.67488,
			73.72063,
			73.76689,
			73.81264,
			73.8579254,
			73.89009,
			73.93764,
			73.9841461,
			74.0288849,
			74.0612,
			74.10741,
			74.15361,
			74.1999054,
			74.23006,
			74.27684,
			74.32106,
			74.36986,
			74.41579,
			74.460495,
			74.50868,
			74.5524139,
			74.59941,
			74.64455,
			74.67674,
			74.723465,
			74.7705841,
			74.81648,
			74.86229,
			74.90755,
			74.93797,
			74.98396,
			75.02873,
			75.0760345,
			75.12077,
			75.16853,
			75.1987457,
			75.243515,
			75.2888,
			75.33656,
			75.38234,
			75.4286346,
			75.45949,
			75.50383,
			75.55083,
			75.59508,
			75.64296,
			75.69072,
			75.72087,
			75.76564,
			75.81291,
			75.85979,
			75.90419,
			75.952774,
			75.98308,
			76.0283661,
			76.0767059,
			76.1202545,
			76.16957,
			76.21532,
			76.24449,
			76.2917938,
			76.33653,
			76.38481,
			76.4322052,
			76.47792,
			76.50856,
			76.53804,
			76.58498,
			76.63039,
			76.6745148,
			76.72179,
			76.76851,
			76.81178,
			76.8596954,
			76.90419,
			76.95262,
			76.998,
			77.0271759,
			77.07286,
			77.12062,
			77.1664,
			77.19508,
			77.2428741,
			77.2719,
			77.2881,
			77.33592,
			77.383194,
			77.42796,
			77.47478,
			77.52104,
			77.55272,
			77.59651,
			77.64433,
			77.68959,
			77.7358856,
			77.77962,
			77.81233,
			77.85866,
			77.9034,
			77.95125,
			77.9947052,
			78.04082,
			78.07109,
			78.11925,
			78.16576,
			78.21089,
			78.25566,
			78.3054657,
			78.33513,
			78.37941,
			78.42583,
			78.47356,
			78.51781,
			78.56471,
			78.5942841,
			78.6426544,
			78.6884,
			78.71855,
			78.7659149,
			78.8101654,
			78.85841,
			78.90437,
			78.94948,
			78.9952545,
			79.0400848,
			79.0959,
			79.1562653,
			79.2054,
			79.25185,
			79.29823,
			79.3419647,
			79.37416,
			79.41945,
			79.46623,
			79.51204,
			79.5583038,
			79.60487,
			79.6332855,
			79.6822052,
			79.72789,
			79.7592,
			79.80357,
			79.85167,
			79.89711,
			79.9434052,
			79.98903,
			80.03432,
			80.0796051,
			80.12547,
			80.1581,
			80.20412,
			80.23555,
			80.2788239,
			80.32762,
			80.37184,
			80.4191742,
			80.46709,
			80.5128,
			80.5565643,
			80.60234,
			80.64931,
			80.68092,
			80.72624,
			80.77312,
			80.820694,
			80.8661041,
			80.91176,
			80.94301,
			80.98976,
			81.03505,
			81.08284,
			81.12959,
			81.17442,
			81.20671,
			81.23753,
			81.28056,
			81.328476,
			81.371994,
			81.4209442,
			81.4659,
			81.5105438,
			81.5578156,
			81.60356,
			81.64934,
			81.6951752,
			81.7264557,
			81.771286,
			81.8178253,
			81.86632,
			81.9104156,
			81.95622,
			81.98888,
			82.03517,
			82.08043,
			82.1273651,
			82.17409,
			82.22032,
			82.2505,
			82.29799,
			82.3416,
			82.38881,
			82.43556,
			82.48186,
			82.51103,
			82.5578156,
			82.6037445,
			82.64986,
			82.6822357,
			82.7264252,
			82.77318,
			82.81987,
			82.86568,
			82.9104462,
			82.95622,
			83.00371,
			83.03462,
			83.08293,
			83.1252,
			83.17284,
			83.22127,
			83.26662,
			83.29518,
			83.34306,
			83.39076,
			83.4355,
			83.47975,
			83.52669,
			83.55727,
			83.60512,
			83.65132,
			83.6987152,
			83.7429047,
			83.79167,
			83.8213348,
			83.8696442,
			83.91551,
			83.9612,
			84.0065155,
			84.05476,
			84.08241,
			84.129715,
			84.173996,
			84.22075,
			84.2671356,
			84.31383,
			84.34453,
			84.38878,
			84.43788,
			84.48335,
			84.5277252,
			84.57442,
			84.60542,
			84.65236,
			84.6822052,
			84.72981,
			84.77562,
			84.8213348,
			84.8661346,
			84.91341,
			84.95766,
			85.004715,
			85.05223,
			85.10048,
			85.12865,
			85.17598,
			85.22276,
			85.26903,
			85.31279,
			85.36119,
			85.390274,
			85.43849,
			85.4828,
			85.5276947,
			85.57591,
			85.60686,
			85.65236,
			85.69914,
			85.74342,
			85.79018,
			85.83696,
			85.88274,
			85.91142,
			85.95979,
			86.00371,
			86.0518,
			86.09856,
			86.14287,
			86.17305,
			86.22029,
			86.26659,
			86.31285,
			86.35863,
			86.40465,
			86.43559,
			86.4823761,
			86.5135345,
			86.5583344,
			86.60713,
			86.6544,
			86.70036,
			86.74547,
			86.7912445,
			86.83739,
			86.88432,
			86.92909,
			86.9593658,
			87.00661,
			87.05437,
			87.09914,
			87.144455,
			87.19173,
			87.22038,
			87.2541046,
			87.29991,
			87.34468,
			87.3926239,
			87.43727,
			87.4840851,
			87.53148,
			87.5767059,
			87.6245,
			87.6709747,
			87.71657,
			87.74843,
			87.79466,
			87.82478,
			87.8720856,
			87.91838,
			87.96263,
			88.00795,
			88.05678,
			88.10301,
			88.15001,
			88.19322,
			88.24098,
			88.27138,
			88.3174,
			88.3492,
			88.394,
			88.43971,
			88.48598,
			88.52925,
			88.5771,
			88.62184,
			88.67012,
			88.715744,
			88.76033,
			88.793045,
			88.83876,
			88.8835,
			88.9328156,
			88.9776459,
			89.02501,
			89.05409,
			89.10188,
			89.1472,
			89.19411,
			89.2387543,
			89.28606,
			89.31676,
			89.36253,
			89.40828,
			89.45558,
			89.5028839,
			89.54918,
			89.57738,
			89.61058,
			89.65428,
			89.70259,
			89.75011,
			89.79564,
			89.84044,
			89.88716,
			89.93306,
			89.97972,
			90.02507,
			90.07286,
			90.10304,
			90.14882,
			90.1956,
			90.24089,
			90.2871552,
			90.33345,
			90.3625946,
			90.4104156,
			90.45668,
			90.5024261,
			90.5507355,
			90.59361,
			90.62584,
			90.67311,
			90.71974,
			90.7625,
			90.81084,
			90.855545,
			90.8862152,
			90.93349,
			90.978775,
			91.0262,
			91.07942,
			91.12523,
			91.13985,
			91.18913,
			91.23537,
			91.28017,
			91.32698,
			91.37227,
			91.40343,
			91.45073,
			91.49461,
			91.5403,
			91.585556,
			91.63283,
			91.66414,
			91.7083,
			91.75404,
			91.8013153,
			91.8460846,
			91.8943939,
			91.92412,
			91.96983,
			92.01665,
			92.0634,
			92.10817,
			92.15547,
			92.18611,
			92.2328644,
			92.28026,
			92.32555,
			92.373764,
			92.42,
			92.4492,
			92.49596,
			92.541275,
			92.5895538,
			92.63432,
			92.6822052,
			92.71278,
			92.75856,
			92.80379,
			92.85057,
			92.89748,
			92.94112,
			92.9728241,
			93.01909,
			93.06593,
			93.11012,
			93.15794,
			93.2016754,
			93.23439,
			93.27913,
			93.32292,
			93.37041,
			93.41652,
			93.46077,
			93.49196,
			93.53746,
			93.58498,
			93.62825,
			93.67656,
			93.7228241,
			93.75346,
			93.80086,
			93.8455048,
			93.89342,
			93.93724,
			93.98628,
			94.0164948,
			94.06084,
			94.10631,
			94.13756,
			94.18446,
			94.21425,
			94.26051,
			94.276535,
			94.32326,
			94.35292,
			94.399704,
			94.4435,
			94.49077,
			94.53752,
			94.5829,
			94.62807,
			94.67384,
			94.7216339,
			94.76787,
			94.7965546,
			94.82823,
			94.87407,
			94.92027,
			94.96709,
			95.01085,
			95.05974,
			95.1053,
			95.15065,
			95.19539,
			95.24019,
			95.2894745,
			95.3195953,
			95.36284,
			95.41066,
			95.45387,
			95.50163,
			95.5464,
			95.57759,
			95.62285,
			95.67009,
			95.71788,
			95.76262,
			95.80742,
			95.8401,
			95.88634,
			95.93312,
			95.9764252,
			96.00755,
			96.05583,
			96.1006,
			96.14485,
			96.1780243,
			96.22432,
			96.2700653,
			96.31682,
			96.36159,
			96.39336,
			96.4384,
			96.4857941,
			96.5327,
			96.57741,
			96.6096039,
			96.62517,
			96.67128,
			96.71724,
			96.7640839,
			96.79286,
			96.84004,
			96.88426,
			96.9321747,
			96.97826,
			97.01247,
			97.06084,
			97.1060944,
			97.1374359,
			97.184494,
			97.22826,
			97.27507,
			97.3213654,
			97.37013,
			97.4008,
			97.44609,
			97.4934845,
			97.53923,
			97.58592,
			97.6302,
			97.6609039,
			97.70723,
			97.75398,
			97.7852,
			97.83299,
			97.87874,
			97.9245758,
			97.97029,
			98.01659,
			98.0634,
			98.10814,
			98.15538,
			98.18355,
			98.2323456,
			98.2776947,
			98.3213959,
			98.3697052,
			98.41618,
			98.44566,
			98.49095,
			98.5388,
			98.58449,
			98.63136,
			98.67705,
			98.70723,
			98.75096,
			98.79872,
			98.844986,
			98.89143,
			98.93733,
			98.9682159,
			99.01619,
			99.0457,
			99.0930939,
			99.13725,
			99.18355,
			99.23091,
			99.27461,
			99.32033,
			99.3525543,
			99.39986,
			99.4451447,
			99.49303,
			99.53737,
			99.58595,
			99.63225,
			99.67851,
			99.72594,
			99.75597,
			99.78615,
			99.83394,
			99.87822,
			99.92561,
			99.97267,
			100.019058,
			100.064987,
			100.111618,
			100.157425,
			100.204147,
			100.24913,
			100.27916,
			100.324905,
			100.371048,
			100.41597,
			100.462296,
			100.509567,
			100.53804,
			100.568893,
			100.616196,
			100.661514,
			100.707321,
			100.753036,
			100.798813,
			100.845566,
			100.89299,
			100.93895,
			100.98439,
			101.031265,
			101.061417,
			101.107162,
			101.1519,
			101.198685,
			101.243454,
			101.291214,
			101.3239,
			101.353043,
			101.400345,
			101.448227,
			101.4944,
			101.5399,
			101.585922,
			101.623459
		]
	],
	'6586_7977_7982': [
		[
			88.33837
		],
		[
			0
		],
		[
			0,
			1.12182617,
			3.73562741,
			5.0427475,
			7.66259861,
			10.2908764,
			11.6076508,
			14.2466059,
			16.89261,
			19.54528,
			22.204834,
			23.5377,
			26.20856,
			28.8867645,
			31.5718231,
			32.91698,
			35.61253,
			38.31445,
			41.02236,
			43.72483,
			46.423275,
			47.7749062,
			50.482933,
			53.2045135,
			54.5762024,
			57.3246078,
			60.0834045,
			61.4654465,
			64.23453,
			65.6216,
			68.4013748,
			71.1892242,
			72.58565,
			75.3832855,
			79.588974,
			82.3987961,
			83.8047943,
			86.62126,
			88.0319748,
			90.85846,
			93.692955,
			96.5365143,
			99.3865662,
			102.24292,
			103.6736,
			106.539795,
			109.412689,
			110.851669,
			113.734451,
			116.623962,
			119.519562,
			120.96965,
			123.877182,
			126.796295,
			129.729828,
			132.674667,
			134.145981,
			137.091675,
			140.034515,
			141.506287,
			144.454041,
			147.407608,
			150.367889,
			151.850357,
			154.819489,
			156.306244,
			159.284286,
			160.77536,
			163.762,
			166.754333,
			168.252655,
			169.752609,
			172.756882,
			175.767609,
			178.784164,
			180.294342,
			183.318924,
			186.348145,
			189.38298,
			192.423462,
			193.945633,
			196.993347,
			200.046722,
			201.57547,
			204.636368,
			207.701263,
			210.765915,
			213.834122,
			215.372345,
			216.9119,
			219.998459,
			223.094818,
			226.19696,
			229.30423,
			230.859436,
			233.97377,
			237.093048,
			240.2178,
			243.348053,
			246.482452,
			248.051392,
			251.1933,
			254.340271,
			257.4923,
			259.070251,
			262.229675,
			263.811249,
			266.9771,
			270.147766,
			273.3235,
			276.5041,
			278.096222,
			281.2808,
			284.466339,
			287.656647,
			289.253418,
			292.448944,
			295.647369,
			297.247223,
			300.448,
			302.048157,
			305.251434,
			308.4585,
			310.06488,
			313.2836,
			318.124939,
			319.741333,
			322.9772,
			326.217041,
			327.838379,
			331.084137,
			334.334045,
			337.587952,
			340.846,
			344.108368,
			347.374451,
			349.008972,
			352.28186,
			355.559,
			357.198547,
			360.480652,
			363.766357,
			365.410675,
			368.702423,
			370.349731,
			373.647522,
			376.9491,
			380.254517,
			381.908447,
			385.219177,
			388.534119,
			391.853058,
			393.5141,
			396.83902,
			398.503,
			401.833649,
			405.1682,
			408.5065,
			411.848541,
			415.194275,
			416.8687,
			420.22052,
			423.575684,
			426.9348,
			428.616,
			430.298157,
			433.6651,
			437.0357,
			440.40976,
			443.787933,
			447.169861,
			450.5553,
			452.249329,
			455.6399,
			457.336853,
			460.7339,
			464.134766,
			467.539551,
			469.2434,
			472.6541,
			474.361023,
			477.7778,
			479.487732,
			482.909851,
			484.621948,
			488.049469,
			491.481323,
			493.19873,
			496.636383,
			498.356476,
			501.799438,
			505.246521,
			508.6974,
			512.1521,
			515.6111,
			517.341858,
			520.8053,
			524.272644,
			526.007751,
			529.4806,
			532.957336,
			536.438354,
			538.18,
			541.6657,
			545.1555,
			548.6488,
			552.144958,
			553.893433,
			557.3878,
			560.872,
			562.606567,
			566.0554,
			569.4722,
			571.1667,
			574.5272,
			577.849243,
			581.131958,
			584.37854,
			585.9882,
			589.1792,
			592.332336,
			593.8927,
			596.9842,
			600.038452,
			603.057434,
			606.042358,
			608.9898,
			610.4487,
			613.3383,
			616.1902,
			617.602,
			620.396667,
			623.153442,
			624.5179,
			627.218445,
			628.554565,
			631.1984,
			633.8048,
			636.3739,
			637.644531,
			638.906,
			641.4012,
			643.859741,
			645.075562,
			647.4816,
			649.8542,
			651.027832,
			653.3491,
			654.4953,
			656.7599,
			657.8784,
			660.0875,
			662.2597,
			663.332,
			665.4486,
			666.493347,
			668.555359,
			670.580566,
			672.569153,
			673.5499,
			675.4846,
			677.3838,
			679.243958,
			680.159546,
			681.9668,
			682.858459,
			684.6025,
			686.306,
			687.9896,
			689.629761,
			691.238,
			692.030762,
			693.5976,
			695.1624,
			696.7018,
			697.4631,
			698.9682,
			700.4458,
			701.177734,
			702.6306,
			704.0639,
			705.473755,
			706.881042,
			707.584961,
			708.994751,
			710.406433,
			711.114441,
			712.5354,
			713.9679,
			714.6952,
			716.154541,
			717.6205,
			719.1022,
			720.6195,
			722.1406,
			722.9026,
			724.456543,
			726.05426,
			726.85376,
			728.4547,
			729.263855,
			730.902,
			732.5409,
			734.178,
			735.7988,
			737.421143,
			738.233459,
			739.852844,
			741.4685,
			742.277466,
			743.898,
			745.5343,
			747.171936,
			747.9913,
			749.638,
			751.2939,
			752.9502,
			753.7792,
			755.469543,
			757.162231,
			758.0084,
			759.7034,
			761.3983,
			763.0918,
			764.7779,
			765.6143,
			767.2864,
			768.958,
			770.665466,
			772.3747,
			773.229065,
			774.9694,
			775.858643,
			777.6361,
			779.419556,
			780.335449,
			782.165649,
			783.080261,
			783.9949,
			784.9306,
			786.8151,
			788.7035,
			789.6524,
			791.5592,
			793.4734,
			795.378052,
			797.2755,
			799.1826,
			800.1141,
			801.970459,
			803.8346,
			805.6339,
			806.5206,
			808.3,
			810.0397,
			811.7107,
			812.549255,
			814.2335,
			815.9085,
			816.7479,
			818.4344,
			820.142944,
			821.8798,
			822.752441,
			824.507263,
			825.3998,
			827.1947,
			829.0028,
			830.8305,
			832.6582,
			834.5138,
			836.379,
			838.257446,
			840.1481,
			841.097,
			843.005,
			843.96405,
			845.8912,
			847.830566,
			848.8049,
			850.765259,
			852.755,
			853.7447,
			855.7308,
			857.721558,
			859.7107,
			860.7048,
			861.6966,
			862.6852,
			864.6522,
			866.622559,
			868.591431,
			870.5637,
			871.5559,
			873.538269,
			875.528564,
			877.546,
			879.5581,
			880.575745,
			882.6294,
			883.6538,
			885.7224,
			887.847839,
			888.910339,
			891.105,
			893.3887,
			895.707,
			898.1445,
			900.5875,
			901.819336,
			904.2865,
			906.6769,
			907.8468,
			910.1913,
			912.284546,
			914.3704,
			916.3871,
			917.363037,
			919.32196,
			921.286133,
			922.268433,
			924.2416,
			926.2306,
			927.232056,
			929.2436,
			931.2693,
			933.293335,
			934.317932,
			936.3754,
			938.444031,
			940.523,
			942.6024,
			943.641,
			945.71936,
			946.764038,
			948.8611,
			950.9686,
			953.094543,
			955.2299,
			957.375366,
			959.530457,
			960.61145,
			962.779541,
			964.956543,
			966.048462,
			968.238342,
			970.437256,
			971.540649,
			973.7554,
			975.979,
			978.2117,
			980.453369,
			982.703857,
			983.8324,
			986.0962,
			987.2318,
			989.51,
			991.7974,
			992.945068,
			995.247,
			997.55896,
			999.881165,
			1002.21332,
			1004.55566,
			1005.72937,
			1008.09705,
			1009.27124,
			1011.61945,
			1013.96228,
			1016.30011,
			1018.63336,
			1019.79688,
			1022.11694,
			1024.42529,
			1026.73022,
			1027.87915,
			1030.17627,
			1032.46912,
			1033.61011,
			1035.89026,
			1038.16089,
			1039.29163,
			1041.54785,
			1043.804,
			1046.0542,
			1048.30151,
			1049.42358,
			1051.6438,
			1052.76257,
			1055.0144,
			1057.24048,
			1059.46509,
			1061.68152,
			1062.79175,
			1065.01172,
			1067.22351,
			1069.45117,
			1071.67041,
			1072.78174,
			1075.01025,
			1077.231,
			1078.34277,
			1080.56274,
			1082.77734,
			1084.98157,
			1087.18457,
			1089.37817,
			1090.47363,
			1092.66479,
			1093.76306,
			1095.95862,
			1098.15271,
			1099.24829,
			1101.438,
			1103.62891,
			1104.72485,
			1106.9165,
			1109.121,
			1111.33081,
			1112.43555,
			1114.65674,
			1116.87817,
			1119.10754,
			1121.34192,
			1123.58716,
			1124.71826,
			1125.85034,
			1128.11938,
			1130.40088,
			1132.68481,
			1133.82153,
			1136.09351,
			1138.362,
			1139.48364,
			1141.72876,
			1143.98242,
			1146.24756,
			1148.5249,
			1150.83862,
			1153.16077,
			1154.319,
			1156.63892,
			1157.80115,
			1160.10059,
			1161.24829,
			1163.54968,
			1165.86218,
			1168.18335,
			1169.3501,
			1171.69141,
			1172.8656,
			1175.20593,
			1176.377,
			1178.725,
			1179.87537,
			1182.2207,
			1184.56482,
			1185.73315,
			1188.06689,
			1190.3894,
			1192.69739,
			1195.01318,
			1197.294,
			1199.56323,
			1200.69946,
			1202.9635,
			1205.23535,
			1207.51038,
			1208.64539,
			1210.934,
			1213.219,
			1215.52344,
			1216.67981,
			1218.98755,
			1221.33521,
			1222.50684,
			1224.84949,
			1227.19592,
			1228.36707,
			1230.7041,
			1233.0376,
			1235.362,
			1237.67786,
			1238.83545,
			1241.11719,
			1242.25415,
			1244.52161,
			1246.74316,
			1248.96436,
			1250.04773,
			1252.1897,
			1254.3313,
			1256.43909,
			1258.54529,
			1259.59875,
			1261.71045,
			1263.81909,
			1264.87451,
			1266.991,
			1269.10657,
			1271.23218,
			1273.35913,
			1275.488,
			1277.61865,
			1278.683,
			1280.81335,
			1281.87915,
			1284.00977,
			1285.07568,
			1287.213,
			1289.352,
			1291.49878,
			1293.65161,
			1295.81177,
			1297.98,
			1299.06738,
			1301.24573,
			1302.33679,
			1304.50781,
			1306.70166,
			1308.90234,
			1310.00623,
			1312.21375,
			1314.42993,
			1316.65149,
			1318.88086,
			1319.99939,
			1322.24243,
			1324.495,
			1325.62537,
			1327.91333,
			1330.1886,
			1332.47485,
			1333.61987,
			1335.91406,
			1337.0647,
			1339.36792,
			1341.67627,
			1343.9884,
			1346.30286,
			1347.46082,
			1349.77722,
			1352.094,
			1353.25244,
			1355.57,
			1356.72766,
			1359.04309,
			1362.51343,
			1364.8252,
			1365.9801,
			1368.28516,
			1370.58984,
			1371.73926,
			1374.0376,
			1375.187,
			1377.48889,
			1379.792,
			1380.94568,
			1383.26941,
			1384.43127,
			1385.59326,
			1387.95349,
			1390.31763,
			1392.731,
			1393.94507,
			1396.38464,
			1398.875,
			1401.36572,
			1403.854,
			1405.09985,
			1407.58667,
			1410.07507,
			1412.5791,
			1413.83679,
			1416.35974,
			1418.903,
			1420.17822,
			1422.74243,
			1425.3208,
			1427.91687,
			1430.50537,
			1433.07593,
			1434.35242,
			1436.89111,
			1439.34375,
			1440.57043,
			1442.99316,
			1445.3855,
			1447.7511,
			1448.92139,
			1451.25427,
			1453.5155,
			1455.76453,
			1456.87756,
			1459.079,
			1461.24658,
			1462.33154,
			1464.4165,
			1466.48645,
			1468.53687,
			1470.5769,
			1471.59619,
			1473.669,
			1475.77258,
			1477.89856,
			1478.9823,
			1481.15723,
			1483.365,
			1484.47864,
			1486.709,
			1488.95923,
			1491.20972,
			1493.525,
			1494.70349,
			1495.87769,
			1498.18494,
			1499.3136,
			1501.54541,
			1503.67078,
			1505.73083,
			1507.7821,
			1509.88281,
			1511.95081,
			1514.01367,
			1515.0343,
			1517.04871,
			1518.96667,
			1519.90869,
			1521.754,
			1522.67346,
			1524.49548,
			1526.3075,
			1528.10022,
			1529.00562,
			1530.80933,
			1532.60938,
			1533.54175,
			1535.40808,
			1536.34216,
			1537.277,
			1539.13928,
			1540.99915,
			1541.929,
			1543.71973,
			1545.45776,
			1547.19116,
			1548.05164,
			1549.74268,
			1551.42651,
			1553.10107,
			1554.75525,
			1555.5791,
			1557.22192,
			1558.83459,
			1559.63062,
			1561.22021,
			1562.80774,
			1564.37646,
			1565.94019,
			1567.50354,
			1568.28552,
			1569.84766,
			1571.4093,
			1572.19043,
			1573.75464,
			1575.31958,
			1576.10181,
			1577.66638,
			1579.21631,
			1580.75659,
			1582.296,
			1583.06592,
			1584.60962,
			1586.152,
			1586.92285,
			1588.46313,
			1589.98645,
			1590.74707,
			1592.26758,
			1593.78271,
			1595.27173,
			1596.75952,
			1598.24768,
			1599.01062,
			1600.5575,
			1602.10474,
			1602.87927,
			1604.44873,
			1606.03516,
			1606.82959,
			1608.42151,
			1609.99524,
			1611.56934,
			1613.14819,
			1614.72717,
			1615.5166,
			1617.09839,
			1618.68469,
			1619.47864,
			1621.06738,
			1622.6571,
			1624.2467,
			1625.836,
			1627.41736,
			1628.20374,
			1629.76416,
			1631.30237,
			1632.04956,
			1633.54785,
			1634.28589,
			1635.74023,
			1637.17773,
			1638.58044,
			1639.96753,
			1640.65649,
			1642.0271,
			1643.39087,
			1644.74878,
			1645.42529,
			1646.776,
			1648.12549,
			1649.475,
			1650.14819,
			1651.49365,
			1652.8396,
			1654.18762,
			1655.52661,
			1656.19482,
			1657.53052,
			1658.86707,
			1659.53442,
			1660.85449,
			1662.17188,
			1663.48853,
			1664.14685,
			1665.4718,
			1666.79663,
			1668.11963,
			1669.4425,
			1670.12109,
			1671.48486,
			1672.16553,
			1673.52576,
			1674.89233,
			1675.58716,
			1676.97327,
			1678.35645,
			1679.73889,
			1680.42578,
			1681.79456,
			1683.15967,
			1684.52466,
			1685.8916,
			1686.57349,
			1687.93555,
			1688.61621,
			1689.98108,
			1691.38562,
			1692.78613,
			1694.18457,
			1695.6145,
			1696.34924,
			1697.81482,
			1698.54614,
			1700.00647,
			1701.54858,
			1703.09607,
			1703.86853,
			1705.41553,
			1707.07715,
			1707.90649,
			1716.88977,
			1717.84546,
			1719.75854,
			1721.51038,
			1723.12683,
			1723.93628,
			1725.55872,
			1726.2981,
			1727.72217,
			1729.14941,
			1730.58179,
			1731.99744,
			1733.40918,
			1734.11719,
			1735.53809,
			1736.95215,
			1737.65576,
			1739.06787,
			1740.48779,
			1741.20129,
			1742.63342,
			1743.35254,
			1744.7981,
			1746.25354,
			1746.99512,
			1748.48682,
			1749.23682,
			1749.9895,
			1751.50464,
			1753.074,
			1754.65552,
			1756.24976,
			1757.06152,
			1758.71533,
			1760.38159,
			1762.07007,
			1763.81885,
			1765.58069,
			1766.4668,
			1768.24658,
			1770.03735,
			1770.93726,
			1772.73132,
			1774.51184,
			1775.40674,
			1777.20691,
			1779.02563,
			1780.85388,
			1782.69348,
			1784.54248,
			1785.45581,
			1787.33716,
			1789.19751,
			1790.13245,
			1792.01221,
			1793.8833,
			1794.83032,
			1796.73291,
			1798.64551,
			1799.60522,
			1801.53345,
			1803.47229,
			1805.42114,
			1807.38184,
			1809.35229,
			1810.34155,
			1812.329,
			1814.32788,
			1815.33154,
			1817.34729,
			1818.35938,
			1820.3916,
			1822.43433,
			1823.46008,
			1825.52075,
			1826.555,
			1828.6272,
			1829.66333,
			1831.737,
			1833.81934,
			1835.91431,
			1836.96509,
			1839.07568,
			1841.19812,
			1843.33032,
			1845.475,
			1846.551,
			1848.71057,
			1849.79529,
			1851.9718,
			1854.15833,
			1855.25635,
			1857.45972,
			1859.674,
			1861.9,
			1864.136,
			1866.38489,
			1867.513,
			1869.7771,
			1872.05322,
			1873.195,
			1875.487,
			1877.80908,
			1880.11938,
			1882.43591,
			1883.59509,
			1885.91675,
			1888.24072,
			1890.56628,
			1891.72949,
			1894.05615,
			1895.21948,
			1897.54443,
			1898.7074,
			1901.03271,
			1903.35693,
			1905.68237,
			1908.00513,
			1909.16846,
			1911.49438,
			1913.82227,
			1916.15369,
			1917.31775,
			1919.65149,
			1921.98877,
			1923.15918,
			1925.50623,
			1927.88916,
			1930.28076,
			1932.73926,
			1935.21326,
			1936.47266,
			1939.02148,
			1941.5824,
			1942.87183,
			1945.42883,
			1948.00732,
			1950.57324,
			1953.08569,
			1954.32813,
			1956.78088,
			1959.174,
			1961.52869,
			1963.85425,
			1965.0022,
			1967.27686,
			1969.52539,
			1970.63489,
			1972.82166,
			1973.89917,
			1976.0238,
			1977.06311,
			1979.0968,
			1980.09937,
			1982.06567,
			1983.02124,
			1984.90649,
			1986.75757,
			1988.581,
			1989.47363,
			1991.23669,
			1992.10779,
			1993.82861,
			1995.52124,
			1997.18982,
			1998.016,
			1999.65271,
			2001.27344,
			2002.88623,
			2003.6908,
			2005.30029,
			2006.91321,
			2008.53418,
			2010.15808,
			2011.78516,
			2012.60278,
			2014.24121,
			2015.87964,
			2016.69958,
			2018.3446,
			2019.98975,
			2021.63428,
			2023.28027,
			2024.92383,
			2025.74475,
			2027.386,
			2029.02356,
			2029.841,
			2031.47412,
			2032.29065,
			2033.921,
			2036.36121,
			2037.17432,
			2038.80566,
			2040.43518,
			2042.063,
			2042.88135,
			2043.70483,
			2045.34912,
			2046.99146,
			2047.81555,
			2049.49951,
			2051.18262,
			2052.86719,
			2054.57959,
			2056.29175,
			2057.14844,
			2058.87622,
			2059.74561,
			2061.485,
			2063.23218,
			2065.00635,
			2066.78345,
			2068.577,
			2069.48682,
			2071.3103,
			2073.14038,
			2074.08447,
			2075.97656,
			2077.87524,
			2078.82666,
			2079.77832,
			2081.68457,
			2083.58643,
			2085.45679,
			2087.32959,
			2089.20337,
			2091.07617,
			2092.0127,
			2093.90332,
			2094.868,
			2096.796,
			2098.72729,
			2100.66846,
			2102.60864,
			2104.55859,
			2106.51343,
			2107.49048,
			2109.44385,
			2110.41943,
			2112.36963,
			2114.316,
			2115.28418,
			2117.21875,
			2119.144,
			2120.088,
			2121.97437,
			2123.86157,
			2125.77271,
			2127.68359,
			2129.6145,
			2130.5957,
			2132.55957,
			2134.529,
			2135.51733,
			2137.496,
			2139.47681,
			2141.448,
			2143.42432,
			2145.42236,
			2146.42969,
			2148.44653,
			2150.486,
			2151.51782,
			2153.582,
			2154.61475,
			2156.67358,
			2158.732,
			2160.79272,
			2162.85352,
			2163.88354,
			2165.953,
			2166.98975,
			2169.06152,
			2171.13672,
			2173.21118,
			2175.28052,
			2177.331,
			2179.38,
			2181.35645,
			2182.33,
			2184.27637,
			2185.24487,
			2187.11377,
			2188.04736,
			2189.913,
			2191.76123,
			2192.68213,
			2194.52173,
			2196.35156,
			2197.26221,
			2199.083,
			2200.89966,
			2202.70068,
			2204.50269,
			2206.2998,
			2208.08887,
			2209.88281,
			2210.78223,
			2212.584,
			2214.39551,
			2216.2168,
			2217.12915,
			2218.963,
			2220.80884,
			2221.73,
			2223.581,
			2225.44482,
			2227.32471,
			2229.21851,
			2230.16968,
			2232.085,
			2234.01563,
			2234.98535,
			2236.93628,
			2237.90259,
			2239.87646,
			2241.866,
			2243.8916,
			2245.92847,
			2247.98779,
			2250.08228,
			2251.108,
			2252.15332,
			2254.249,
			2256.35474,
			2258.46265,
			2259.51685,
			2261.63062,
			2263.75732,
			2265.89551,
			2266.96851,
			2269.11743,
			2271.276,
			2273.44385,
			2275.62158,
			2276.714,
			2278.90283,
			2281.099,
			2283.30518,
			2284.41138,
			2286.63135,
			2288.86035,
			2291.09814,
			2293.3457,
			2294.47241,
			2296.7334,
			2297.86743,
			2300.14185,
			2302.426,
			2304.71826,
			2307.01953,
			2308.174,
			2310.48853,
			2312.8125,
			2315.14478,
			2316.31445,
			2318.66064,
			2321.01563,
			2322.19678,
			2324.56567,
			2326.94385,
			2329.331,
			2331.72729,
			2332.929,
			2335.33936,
			2337.75854,
			2338.97168,
			2341.40576,
			2343.84863,
			2345.07373,
			2347.53076,
			2349.99683,
			2351.2334,
			2353.71313,
			2356.20239,
			2358.70044,
			2361.20776,
			2363.724,
			2366.25,
			2367.516,
			2370.05542,
			2371.32813,
			2373.88013,
			2376.442,
			2379.0127,
			2380.301,
			2382.88379,
			2385.47461,
			2388.07275,
			2390.67114,
			2393.27417,
			2394.57813,
			2397.19238,
			2398.50244,
			2401.12769,
			2403.76074,
			2405.07983,
			2407.724,
			2410.37573,
			2411.70459,
			2413.03516,
			2415.702,
			2418.37646,
			2419.71631,
			2422.40161,
			2425.09424,
			2426.443,
			2429.14746,
			2431.85864,
			2434.57739,
			2437.30322,
			2440.03467,
			2441.39966,
			2444.122,
			2446.82471,
			2448.16626,
			2450.82373,
			2453.44531,
			2454.74268,
			2457.30957,
			2459.8396,
			2461.09033,
			2463.56348,
			2465.99756,
			2467.20117,
			2469.58252,
			2470.76025,
			2473.09,
			2475.38281,
			2477.63818,
			2479.85669,
			2482.038,
			2484.18262,
			2485.2417,
			2487.33154,
			2488.36255,
			2490.39648,
			2492.393,
			2493.37744,
			2495.31787,
			2497.2207,
			2499.0874,
			2500.91943,
			2501.82251,
			2503.604,
			2505.351,
			2506.211,
			2507.903,
			2509.55884,
			2511.17773,
			2512.76025,
			2514.3064,
			2515.81641,
			2516.55786,
			2518.01367,
			2518.72827,
			2520.13062,
			2521.49951,
			2522.83545,
			2524.14038,
			2524.783,
			2526.05469,
			2527.315,
			2528.547,
			2529.1582,
			2530.37354,
			2530.979,
			2532.185,
			2533.3877,
			2534.588,
			2535.7832,
			2536.96826,
			2537.55957,
			2538.7417,
			2539.92432,
			2540.5166,
			2541.6665,
			2542.81128,
			2543.38379,
			2544.5293,
			2545.10278,
			2546.23755,
			2546.796,
			2547.91064,
			2549.02441,
			2549.58081,
			2550.69434,
			2551.26245,
			2552.397,
			2553.52783,
			2554.09253,
			2555.22144,
			2556.39453,
			2557.01123,
			2558.24048,
			2559.466,
			2560.69067,
			2562.02832,
			2563.41113,
			2564.10156,
			2565.48145,
			2566.17334,
			2567.63647,
			2569.09619,
			2570.55518,
			2572.07642,
			2573.65723,
			2575.23584,
			2576.02515,
			2577.63037,
			2579.24365,
			2580.0498,
			2581.66,
			2583.232,
			2584.80127,
			2585.586,
			2587.15234,
			2587.92773,
			2589.47827,
			2591.03027,
			2592.45874,
			2593.744,
			2594.3877,
			2595.681,
			2596.33057,
			2597.586,
			2598.79785,
			2599.40552,
			2600.0144,
			2601.2373,
			2602.47388,
			2603.105,
			2604.36914,
			2605.62134,
			2606.25146,
			2607.51538,
			2608.756,
			2609.36768,
			2610.57227,
			2611.75366,
			2612.961,
			2614.21582,
			2614.83643,
			2616.06665,
			2617.285,
			2618.84277,
			2619.7002,
			2621.40747,
			2623.292,
			2625.79614,
			2628.16553,
			2630.03076,
			2630.961,
			2632.81787,
			2633.941,
			2634.50024,
			2635.61816,
			2636.735,
			2637.29346,
			2638.35742,
			2639.31836,
			2640.274,
			2641.24268,
			2641.73047,
			2642.71558,
			2643.7522,
			2644.292,
			2645.38184,
			2646.486,
			2647.61426,
			2648.1792,
			2649.7146,
			2651.35132,
			2652.99658,
			2653.91113,
			2655.91748,
			2657.9353,
			2658.91748,
			2660.82178,
			2662.72778,
			2664.34448,
			2665.762,
			2666.4707,
			2667.89087,
			2669.23242,
			2669.861,
			2671.12451,
			2672.3916,
			2673.026,
			2674.31665,
			2675.6333,
			2676.94873,
			2678.26367,
			2679.59229,
			2680.2627,
			2680.93237,
			2682.27051,
			2683.6084,
			2684.95239,
			2686.29736,
			2686.96973,
			2688.31567,
			2689.66772,
			2691.02539,
			2692.38525,
			2693.74976,
			2694.43579,
			2695.81445,
			2697.19629,
			2697.88916,
			2699.28052,
			2700.68066,
			2701.38257,
			2702.7915,
			2703.499,
			2704.922,
			2706.35522,
			2707.79785,
			2709.25073,
			2710.70825,
			2712.17261,
			2713.638,
			2714.38232,
			2715.87573,
			2716.626,
			2718.13452,
			2719.64966,
			2721.164,
			2722.68555,
			2724.18286,
			2724.92578,
			2726.39355,
			2727.11816,
			2728.549,
			2729.95581,
			2731.351,
			2732.04346,
			2733.4126,
			2734.76465,
			2736.11719,
			2737.501,
			2738.87329,
			2739.556,
			2740.23633,
			2741.6333,
			2743.0918,
			2744.5415,
			2745.98438,
			2746.73315,
			2748.23535,
			2749.73071,
			2751.22168,
			2751.99487,
			2753.55518,
			2755.11182,
			2756.66846,
			2758.238,
			2759.02173,
			2760.58887,
			2762.12549,
			2762.85864,
			2764.3252,
			2765.059,
			2766.52954,
			2767.961,
			2769.37646,
			2770.793,
			2772.21265,
			2773.646,
			2774.36182,
			2775.79248,
			2777.22388,
			2777.94946,
			2779.39819,
			2780.84448,
			2782.29077,
			2783.78174,
			2785.27051,
			2786.01367,
			2787.49951,
			2789.03223,
			2789.79834,
			2791.32715,
			2792.85327,
			2793.60645,
			2795.10742,
			2796.604,
			2798.102,
			2799.62915,
			2800.39063,
			2801.91064,
			2803.43018,
			2804.21021,
			2805.76563,
			2807.31787,
			2808.09424,
			2809.63354,
			2811.16235,
			2812.68945,
			2814.206,
			2815.69653,
			2816.44,
			2817.924,
			2819.38379,
			2820.09619,
			2821.51514,
			2822.931,
			2823.63843,
			2825.01563,
			2826.379,
			2827.74023,
			2828.42139,
			2829.745,
			2831.0415,
			2832.3374,
			2832.98633,
			2834.2854,
			2835.54883,
			2836.18115,
			2837.44824,
			2838.72021,
			2839.99072,
			2840.61914,
			2841.88,
			2843.147,
			2844.42236,
			2845.72217,
			2846.37573,
			2847.689,
			2849.01172,
			2850.36426,
			2851.74414,
			2852.43848,
			2853.83521,
			2855.25146,
			2856.6958,
			2857.42236,
			2858.8855,
			2860.369,
			2861.122,
			2862.6377,
			2863.40039,
			2864.93726,
			2866.48438,
			2868.0415,
			2869.61035,
			2871.18433,
			2871.973,
			2873.55664,
			2875.15015,
			2875.948,
			2877.54736,
			2879.15479,
			2880.76025,
			2882.41113,
			2883.23,
			2884.86768,
			2886.51953,
			2888.18359,
			2889.01538,
			2890.67969,
			2892.35645,
			2893.19482,
			2894.87231,
			2896.55322,
			2897.395,
			2899.07959,
			2899.92334,
			2901.6106,
			2902.453,
			2904.14063,
			2904.98633,
			2906.67871,
			2908.37256,
			2909.22,
			2910.91333,
			2912.64063,
			2914.36938,
			2916.09961,
			2917.86255,
			2918.74268,
			2920.501,
			2921.382,
			2923.1582,
			2924.92847,
			2926.69653,
			2928.47046,
			2929.355,
			2931.11938,
			2932.89575,
			2934.671,
			2936.44238,
			2937.31836,
			2939.06665,
			2939.94,
			2941.68579,
			2942.55786,
			2944.29956,
			2946.03955,
			2947.81055,
			2948.71045,
			2950.51172,
			2952.331,
			2954.23486,
			2956.14136,
			2958.06348,
			2959.03735,
			2960.9856,
			2962.90674,
			2964.725,
			2966.53979,
			2967.44531,
			2968.33228,
			2970.07642,
			2971.815,
			2973.55127,
			2975.28662,
			2977.018,
			2977.88281,
			2979.62842,
			2980.50439,
			2982.2583,
			2984.01831,
			2985.78882,
			2987.56543,
			2989.36646,
			2990.28076,
			2992.117,
			2993.96582,
			2994.89648,
			2996.76758,
			2998.65186,
			3000.54248,
			3002.44214,
			3004.34863,
			3005.28149,
			3007.15381,
			3009.038,
			3009.96558,
			3011.78833,
			3013.623,
			3014.54541,
			3016.35645,
			3017.26367,
			3019.0874,
			3020.92554,
			3022.778,
			3024.642,
			3026.52148,
			3028.41553,
			3029.36719,
			3031.276,
			3033.18848,
			3034.1333,
			3036.063,
			3037.9873,
			3039.92236,
			3041.87061,
			3043.837,
			3044.82373,
			3046.80762,
			3047.81348,
			3049.83374,
			3051.8645,
			3053.919,
			3054.95068,
			3057.02222,
			3059.11133,
			3061.2063,
			3062.253,
			3064.34473,
			3066.444,
			3068.5542,
			3069.61377,
			3071.74048,
			3072.80762,
			3074.95313,
			3077.10913,
			3079.277,
			3081.45654,
			3082.5498,
			3084.74365,
			3086.94727,
			3089.16138,
			3090.27539,
			3092.51123,
			3094.75732,
			3097.01514,
			3098.14771,
			3100.41357,
			3102.684,
			3103.82324,
			3106.10034,
			3108.38379,
			3109.5293,
			3111.82666,
			3112.97949,
			3114.13428,
			3116.45264,
			3118.78149,
			3119.94946,
			3122.293,
			3124.64673,
			3127.00635,
			3129.375,
			3131.74463,
			3132.93164,
			3135.31226,
			3136.50781,
			3138.906,
			3141.31958,
			3143.74927,
			3146.1875,
			3148.6333,
			3149.85962,
			3152.31885,
			3153.55127,
			3156.024,
			3158.50879,
			3159.75439,
			3162.25244,
			3164.75879,
			3167.27441,
			3169.79858,
			3172.33154,
			3174.873,
			3176.147,
			3178.70166,
			3181.265,
			3182.54932,
			3185.12378,
			3187.70166,
			3188.99023,
			3191.5603,
			3194.1145,
			3196.64478,
			3197.89868,
			3200.38135,
			3202.82935,
			3205.24121,
			3206.43384,
			3208.79175,
			3211.11377,
			3213.399,
			3214.527,
			3216.75635,
			3218.949,
			3221.106,
			3223.22729,
			3224.27441,
			3226.34131,
			3227.361,
			3229.373,
			3231.34814,
			3232.32227,
			3234.243,
			3236.128,
			3237.97827,
			3239.796,
			3241.58179,
			3242.4624,
			3244.19653,
			3245.89526,
			3246.731,
			3248.376,
			3249.98462,
			3251.55859,
			3252.33252,
			3253.85376,
			3255.33838,
			3256.79883,
			3257.51416,
			3258.22388,
			3259.63135,
			3261.01221,
			3262.37817,
			3263.7373,
			3264.41528,
			3265.76074,
			3267.093,
			3267.7583,
			3269.089,
			3270.41577,
			3271.72754,
			3273.03833,
			3274.34985,
			3275.00635,
			3276.32471,
			3276.98413,
			3278.30225,
			3278.96167,
			3280.28027,
			3281.615,
			3282.95117,
			3284.28613,
			3285.62183,
			3286.97974,
			3287.65723,
			3289.01074,
			3289.68726,
			3291.0437,
			3292.41284,
			3293.0957,
			3294.45947,
			3295.82324,
			3297.20459,
			3298.58667,
			3299.9668,
			3301.347,
			3302.04834,
			3303.44824,
			3304.14746,
			3305.54541,
			3306.95313,
			3307.66455,
			3309.086,
			3310.50854,
			3311.221,
			3312.66455,
			3314.11426,
			3314.83984,
			3316.29468,
			3317.774,
			3318.51758,
			3319.262,
			3320.755,
			3322.263,
			3323.02466,
			3324.55176,
			3325.31787,
			3326.08643,
			3327.643,
			3329.21875,
			3330.801,
			3331.59546,
			3333.21216,
			3334.84424,
			3335.663,
			3337.308,
			3339.00732,
			3340.7124,
			3342.42554,
			3343.303,
			3345.06763,
			3346.838,
			3348.62061,
			3350.41235,
			3351.31,
			3353.09937,
			3353.97485,
			3355.72778,
			3357.485,
			3359.23315,
			3360.98047,
			3361.85645,
			3362.73364,
			3364.52246,
			3366.32471,
			3368.13477,
			3369.03687,
			3370.844,
			3372.65967,
			3374.443,
			3376.21143,
			3377.9895,
			3379.72217,
			3380.585,
			3382.31763,
			3384.04761,
			3384.9043,
			3386.62451,
			3388.355,
			3389.21826,
			3390.946,
			3392.686,
			3394.43115,
			3396.17627,
			3397.93384,
			3398.81714,
			3400.57739,
			3401.462,
			3403.24072,
			3405.03931,
			3406.852,
			3407.76318,
			3409.61084,
			3411.4917,
			3412.43677,
			3414.34082,
			3416.27246,
			3418.21558,
			3420.179,
			3421.168,
			3423.1543,
			3425.15869,
			3426.16748,
			3427.17944,
			3428.17749,
			3429.19482,
			3431.239,
			3432.26538,
			3434.32666,
			3436.396,
			3438.476,
			3439.52051,
			3440.56982,
			3442.672,
			3444.77368,
			3446.89014,
			3449.0166,
			3450.08276,
			3452.21924,
			3453.29175,
			3455.441,
			3457.595,
			3459.75952,
			3460.84888,
			3463.03564,
			3465.23169,
			3466.3335,
			3468.54443,
			3470.764,
			3472.98877,
			3475.22363,
			3476.34424,
			3478.59229,
			3480.85034,
			3483.11572,
			3484.252,
			3486.53369,
			3488.82715,
			3491.13037,
			3492.28662,
			3494.60547,
			3496.9314,
			3499.26367,
			3501.60474,
			3502.77759,
			3505.13086,
			3507.494,
			3508.6792,
			3511.0564,
			3513.44385,
			3515.84082,
			3518.24658,
			3520.66187,
			3521.873,
			3524.30176,
			3525.51953,
			3527.96216,
			3530.41357,
			3531.64258,
			3534.107,
			3536.58,
			3539.06152,
			3541.55151,
			3542.79956,
			3545.30127,
			3547.81152,
			3550.32959,
			3551.59155,
			3554.12231,
			3556.662,
			3559.21,
			3560.48755,
			3563.04858,
			3565.601,
			3568.159,
			3569.44971,
			3572.04053,
			3574.63916,
			3577.24414,
			3578.54639,
			3581.154,
			3583.358
		],
		[
			0,
			0.0281677246,
			0.07398987,
			0.104171753,
			0.150527954,
			0.183235168,
			0.230438232,
			0.2747574,
			0.321182251,
			0.3688507,
			0.413574219,
			0.4467926,
			0.4901123,
			0.5389099,
			0.5821533,
			0.630462646,
			0.676269531,
			0.721435547,
			0.7684479,
			0.8121109,
			0.8604202,
			0.8901291,
			0.936477661,
			0.9832916,
			1.02899933,
			1.07682037,
			1.122673,
			1.15234375,
			1.19753265,
			1.22923279,
			1.27852631,
			1.32235718,
			1.35481262,
			1.40154266,
			1.46272278,
			1.50853729,
			1.53872681,
			1.58424377,
			1.63127136,
			1.67806244,
			1.7206955,
			1.76914215,
			1.81491089,
			1.860199,
			1.90600586,
			1.93870544,
			1.98470306,
			2.029274,
			2.0770874,
			2.12342072,
			2.16972351,
			2.198349,
			2.24716949,
			2.29149628,
			2.339798,
			2.384735,
			2.43080139,
			2.46149445,
			2.5083313,
			2.55466461,
			2.5983963,
			2.6461792,
			2.69202423,
			2.72318268,
			2.76845551,
			2.80126953,
			2.84592438,
			2.876625,
			2.924469,
			2.96923828,
			2.98538971,
			3.03108215,
			3.07634735,
			3.123146,
			3.16893,
			3.199112,
			3.24689484,
			3.29219818,
			3.337471,
			3.383995,
			3.43100739,
			3.46121216,
			3.50849915,
			3.55484772,
			3.60108948,
			3.64685059,
			3.692665,
			3.73648071,
			3.76785278,
			3.798317,
			3.846634,
			3.889946,
			3.936264,
			3.98312378,
			4.0308075,
			4.077095,
			4.12243652,
			4.1692276,
			4.215477,
			4.26125336,
			4.293457,
			4.339218,
			4.385193,
			4.43174,
			4.47650146,
			4.523842,
			4.552658,
			4.60037231,
			4.646988,
			4.691765,
			4.737068,
			4.769745,
			4.817108,
			4.86376953,
			4.90858459,
			4.954109,
			5.00120544,
			5.04787445,
			5.07803345,
			5.12387848,
			5.154381,
			5.20035553,
			5.246063,
			5.27722931,
			5.32496643,
			5.38540649,
			5.41751862,
			5.46266174,
			5.50894165,
			5.55347443,
			5.60054,
			5.64772034,
			5.69104,
			5.739792,
			5.785652,
			5.83351135,
			5.86448669,
			5.91126251,
			5.95903,
			6.004799,
			6.05112457,
			6.09732056,
			6.111397,
			6.15917969,
			6.20496368,
			6.248665,
			6.29396057,
			6.34124756,
			6.374405,
			6.418892,
			6.46286774,
			6.5112,
			6.55443573,
			6.60174561,
			6.631424,
			6.676689,
			6.724518,
			6.769287,
			6.81403351,
			6.860817,
			6.89151,
			6.938797,
			6.98416138,
			7.03183746,
			7.061508,
			7.106285,
			7.1516037,
			7.197853,
			7.245041,
			7.28982544,
			7.33711243,
			7.38298035,
			7.41455841,
			7.460327,
			7.505661,
			7.55198669,
			7.598175,
			7.645439,
			7.67563629,
			7.706848,
			7.751274,
			7.782959,
			7.830559,
			7.875839,
			7.90452576,
			7.937233,
			7.981476,
			8.013672,
			8.06044,
			8.105743,
			8.151176,
			8.182838,
			8.243065,
			8.290886,
			8.335655,
			8.381401,
			8.428169,
			8.457405,
			8.50415,
			8.547882,
			8.596657,
			8.64147949,
			8.67314148,
			8.720688,
			8.765205,
			8.811966,
			8.857773,
			8.905029,
			8.9515,
			8.981972,
			9.027252,
			9.072548,
			9.118889,
			9.148972,
			9.19677,
			9.242065,
			9.289391,
			9.336189,
			9.381447,
			9.429253,
			9.476006,
			9.504234,
			9.552048,
			9.597733,
			9.645042,
			9.689865,
			9.73761,
			9.766899,
			9.812515,
			9.860321,
			9.90612,
			9.95200348,
			9.996651,
			10.0283432,
			10.0736618,
			10.1038589,
			10.1511917,
			10.1978683,
			10.2436295,
			10.2733307,
			10.2894669,
			10.3348083,
			10.3814926,
			10.4287872,
			10.4741211,
			10.5190353,
			10.5512238,
			10.5818481,
			10.6270218,
			10.6577454,
			10.70253,
			10.7503357,
			10.7965927,
			10.81163,
			10.8569489,
			10.9047318,
			10.9474792,
			10.9942551,
			11.0411072,
			11.0728,
			11.11866,
			11.1653748,
			11.2101517,
			11.255455,
			11.3018494,
			11.3309021,
			11.3791962,
			11.4245148,
			11.4718323,
			11.5183029,
			11.562851,
			11.5950241,
			11.64283,
			11.6864777,
			11.7340622,
			11.7650833,
			11.8108749,
			11.8572006,
			11.9049606,
			11.9508133,
			11.9959946,
			12.0422668,
			12.0878372,
			12.1184464,
			12.1640244,
			12.2118225,
			12.2561493,
			12.3044281,
			12.3507767,
			12.3788757,
			12.4271622,
			12.4724884,
			12.517868,
			12.564064,
			12.6093521,
			12.64061,
			12.6876984,
			12.732132,
			12.7769089,
			12.8096237,
			12.8559189,
			12.9022369,
			12.9474716,
			12.9937515,
			13.041069,
			13.084465,
			13.1172562,
			13.1628265,
			13.2101059,
			13.2557907,
			13.3037491,
			13.3479843,
			13.394783,
			13.42598,
			13.4692764,
			13.5171661,
			13.5628128,
			13.5939941,
			13.6398468,
			13.6876831,
			13.7313843,
			13.7786865,
			13.8270035,
			13.8718491,
			13.9186935,
			13.947258,
			13.9930573,
			14.0398865,
			14.08786,
			14.1330261,
			14.1802292,
			14.2093964,
			14.2557144,
			14.3035278,
			14.3332748,
			14.3789215,
			14.4086456,
			14.4538727,
			14.4690247,
			14.5012054,
			14.5459518,
			14.5912781,
			14.6249619,
			14.6717148,
			14.7177124,
			14.7768631,
			14.8226624,
			14.8694077,
			14.9016037,
			14.945343,
			14.991188,
			15.0365067,
			15.0696411,
			15.1143951,
			15.1636887,
			15.2085037,
			15.2544937,
			15.3013,
			15.3463516,
			15.377533,
			15.423378,
			15.4695892,
			15.5128326,
			15.5465469,
			15.5923309,
			15.6225662,
			15.6693344,
			15.7135773,
			15.7603531,
			15.8205338,
			15.86866,
			15.9137878,
			15.9610748,
			16.0088654,
			16.0380936,
			16.08181,
			16.1295776,
			16.1773682,
			16.2210312,
			16.253685,
			16.2986221,
			16.3464127,
			16.3755875,
			16.42118,
			16.4683914,
			16.5149536,
			16.54464,
			16.5612411,
			16.606041,
			16.6515427,
			16.7002411,
			16.7469025,
			16.7911911,
			16.8223724,
			16.8696518,
			16.91439,
			16.9616852,
			17.0075226,
			17.0518646,
			17.0845642,
			17.1302338,
			17.1770248,
			17.20774,
			17.2541885,
			17.301033,
			17.3466263,
			17.3929062,
			17.4397354,
			17.4851761,
			17.5307541,
			17.5770264,
			17.6072617,
			17.6511536,
			17.69677,
			17.74607,
			17.7908859,
			17.8366547,
			17.86699,
			17.9141235,
			17.9589233,
			17.9884949,
			18.0374146,
			18.0828323,
			18.1284866,
			18.17781,
			18.220932,
			18.2699585,
			18.3003,
			18.3463745,
			18.3936615,
			18.4379883,
			18.4849243,
			18.5300369,
			18.5763626,
			18.60556,
			18.6519775,
			18.6981354,
			18.7464447,
			18.7912674,
			18.8370819,
			18.8829346,
			18.9140015,
			18.9613037,
			19.0065842,
			19.0524979,
			19.0991211,
			19.1446915,
			19.17585,
			19.22116,
			19.2690048,
			19.3136215,
			19.3593979,
			19.4072342,
			19.4374313,
			19.4843216,
			19.5289917,
			19.575798,
			19.6209335,
			19.6519241,
			19.6997,
			19.7443848,
			19.7912369,
			19.8349838,
			19.8817825,
			19.9285812,
			19.9597855,
			20.0050888,
			20.0509949,
			20.0976715,
			20.1439819,
			20.1903229,
			20.2204437,
			20.26751,
			20.3120728,
			20.35791,
			20.3886032,
			20.43644,
			20.48217,
			20.5274734,
			20.56118,
			20.6090012,
			20.6536942,
			20.7016068,
			20.7463837,
			20.7936859,
			20.8375015,
			20.86924,
			20.9144669,
			20.944664,
			20.9924774,
			21.0543289,
			21.1003571,
			21.1465,
			21.17817,
			21.2219772,
			21.26783,
			21.3135223,
			21.3593521,
			21.4062042,
			21.4535217,
			21.4966965,
			21.5289,
			21.5727158,
			21.6212387,
			21.6673889,
			21.713089,
			21.7598877,
			21.7891083,
			21.835907,
			21.8650665,
			21.9113617,
			21.9582214,
			22.00599,
			22.0487671,
			22.0945663,
			22.12629,
			22.1700974,
			22.2170029,
			22.2636414,
			22.29235,
			22.3550568,
			22.4010544,
			22.4468231,
			22.49263,
			22.5374527,
			22.5686264,
			22.60041,
			22.6455917,
			22.69094,
			22.7377319,
			22.7814636,
			22.8126755,
			22.8756027,
			22.9058075,
			22.9507751,
			22.9973373,
			23.0436325,
			23.0875778,
			23.1362534,
			23.18251,
			23.2142029,
			23.2594986,
			23.30426,
			23.3511581,
			23.3806839,
			23.4284744,
			23.4722824,
			23.51995,
			23.5513687,
			23.5964813,
			23.6120682,
			23.6593475,
			23.6905975,
			23.7348022,
			23.78106,
			23.8127441,
			23.8595581,
			23.90532,
			23.9521484,
			23.9973221,
			24.0440979,
			24.09243,
			24.1367111,
			24.1835861,
			24.2286911,
			24.27597,
			24.3227921,
			24.3685226,
			24.3971786,
			24.44397,
			24.4887619,
			24.5360413,
			24.5797729,
			24.6270523,
			24.6587448,
			24.7025681,
			24.73523,
			24.7819977,
			24.8277435,
			24.8735733,
			24.9174271,
			24.96508,
			25.01136,
			25.0410538,
			25.0848618,
			25.1305389,
			25.1778259,
			25.22361,
			25.26838,
			25.2997131,
			25.3458328,
			25.39161,
			25.4368973,
			25.4842148,
			25.5308838,
			25.5620651,
			25.6088333,
			25.6535416,
			25.6973419,
			25.7446213,
			25.7899246,
			25.8367081,
			25.88552,
			25.9317017,
			25.9618835,
			26.00869,
			26.0546875,
			26.0847321,
			26.1309357,
			26.1796341,
			26.2264252,
			26.27063,
			26.31749,
			26.3606644,
			26.408432,
			26.4396362,
			26.4851379,
			26.5311279,
			26.5774,
			26.6227188,
			26.6684418,
			26.6986,
			26.7468719,
			26.79264,
			26.838913,
			26.88266,
			26.9314346,
			26.977211,
			27.0074539,
			27.0523071,
			27.1001282,
			27.14721,
			27.1929855,
			27.2227631,
			27.2700043,
			27.3014069,
			27.3469238,
			27.393219,
			27.4389954,
			27.48494,
			27.53051,
			27.5611954,
			27.6085129,
			27.651474,
			27.6980286,
			27.7312164,
			27.777504,
			27.8358841,
			27.8816147,
			27.9294128,
			27.9747238,
			28.02224,
			28.05243,
			28.0969849,
			28.1291962,
			28.17501,
			28.2223434,
			28.2515564,
			28.2982788,
			28.3138657,
			28.34507,
			28.3904648,
			28.436203,
			28.4835663,
			28.5126343,
			28.5614548,
			28.6228943,
			28.6691589,
			28.7143936,
			28.74408,
			28.7904434,
			28.8377151,
			28.8869934,
			28.9307632,
			28.9775772,
			29.0223923,
			29.05484,
			29.1000443,
			29.1466141,
			29.19291,
			29.2367249,
			29.2856216,
			29.33078,
			29.3614731,
			29.408783,
			29.45346,
			29.5013885,
			29.5466614,
			29.5924911,
			29.6201172,
			29.6694717,
			29.7142258,
			29.7625351,
			29.8053284,
			29.8512115,
			29.8849945,
			29.9311523,
			29.9769211,
			30.0227737,
			30.06852,
			30.114296,
			30.1450348,
			30.190834,
			30.2386246,
			30.28347,
			30.3291779,
			30.3754883,
			30.4082489,
			30.4540863,
			30.4999466,
			30.5460815,
			30.5904,
			30.6351929,
			30.6653214,
			30.6950226,
			30.74134,
			30.7725525,
			30.8189468,
			30.86361,
			30.9124069,
			30.9723434,
			31.017189,
			31.0628738,
			31.11068,
			31.1560135,
			31.18789,
			31.2347565,
			31.2636414,
			31.3124542,
			31.342659,
			31.387764,
			31.4343414,
			31.49511,
			31.52681,
			31.5726013,
			31.6185074,
			31.6485748,
			31.6968765,
			31.7119751,
			31.7406845,
			31.7876282,
			31.8363113,
			31.8795319,
			31.9278336,
			31.9570847,
			32.0189972,
			32.0501862,
			32.096405,
			32.14171,
			32.1878281,
			32.2334,
			32.2795868,
			32.3273621,
			32.35907,
			32.4038849,
			32.4486237,
			32.49491,
			32.5417023,
			32.58766,
			32.63482,
			32.67946,
			32.7126465,
			32.75795,
			32.80371,
			32.8510666,
			32.88212,
			32.92736,
			32.97213,
			33.0184937,
			33.06462,
			33.1113968,
			33.1421127,
			33.18818,
			33.23522,
			33.26378,
			33.3286438,
			33.3598175,
			33.4056168,
			33.45195,
			33.4976044,
			33.5413971,
			33.5870132,
			33.63491,
			33.6671677,
			33.7128143,
			33.7595978,
			33.7903061,
			33.8512039,
			33.88349,
			33.93004,
			33.9753036,
			34.02208,
			34.0652847,
			34.1135635,
			34.1608353,
			34.20562,
			34.25341,
			34.2976151,
			34.3277664,
			34.3770676,
			34.4201126,
			34.4677048,
			34.5138321,
			34.5601273,
			34.5898056,
			34.6365738,
			34.68293,
			34.7134933,
			34.7597733,
			34.8060532,
			34.8518753,
			34.8970261,
			34.94378,
			34.9911423,
			35.02143,
			35.0690231,
			35.1137466,
			35.1594925,
			35.2063065,
			35.2521439,
			35.298317,
			35.3445663,
			35.37426,
			35.4212875,
			35.4668961,
			35.51301,
			35.5582962,
			35.60604,
			35.63624,
			35.6835861,
			35.72825,
			35.7739944,
			35.822258,
			35.8670731,
			35.8971176,
			35.94442,
			35.98855,
			36.03489,
			36.08213,
			36.1299057,
			36.1585922,
			36.2053757,
			36.25181,
			36.29638,
			36.32853,
			36.3763046,
			36.4210739,
			36.4665,
			36.4955063,
			36.5437851,
			36.58857,
			36.63584,
			36.6815567,
			36.7283249,
			36.7590561,
			36.80329,
			36.85163,
			36.8967972,
			36.94404,
			36.9882278,
			37.0351639,
			37.07978,
			37.1280441,
			37.1562576,
			37.1934433,
			37.23873,
			37.28556,
			37.3307266,
			37.3780441,
			37.42536,
			37.4550247,
			37.6874466,
			37.7181778,
			37.76487,
			37.81266,
			37.8584671,
			37.9057236,
			37.95118,
			37.9796371,
			38.02793,
			38.0737534,
			38.1206741,
			38.1653061,
			38.211586,
			38.2417831,
			38.2878647,
			38.3359,
			38.38114,
			38.4274063,
			38.4742355,
			38.5054245,
			38.5512466,
			38.5803452,
			38.628624,
			38.6739426,
			38.70565,
			38.7520523,
			38.7677231,
			38.7982254,
			38.8429947,
			38.8885269,
			38.93609,
			38.982338,
			39.0275955,
			39.074913,
			39.1202927,
			39.16494,
			39.21273,
			39.261055,
			39.29075,
			39.33703,
			39.3844833,
			39.43107,
			39.4773636,
			39.5209579,
			39.55256,
			39.5985947,
			39.646904,
			39.6907272,
			39.7385025,
			39.78486,
			39.8133926,
			39.8611526,
			39.9089432,
			39.95231,
			40.001152,
			40.04731,
			40.07647,
			40.1252975,
			40.17057,
			40.2012253,
			40.247,
			40.29377,
			40.3390732,
			40.38772,
			40.4320755,
			40.4778671,
			40.5252,
			40.5720749,
			40.60369,
			40.63398,
			40.678627,
			40.7264481,
			40.7566757,
			40.8019333,
			40.83419,
			40.8642349,
			40.91004,
			40.95469,
			41.00112,
			41.0474,
			41.0946732,
			41.1249161,
			41.1691971,
			41.2149429,
			41.2607346,
			41.3085556,
			41.3544846,
			41.3851242,
			41.430748,
			41.4769974,
			41.520607,
			41.55433,
			41.5987167,
			41.6455154,
			41.6939163,
			41.73861,
			41.7845078,
			41.8296432,
			41.8769455,
			41.9081955,
			41.95417,
			41.9986954,
			42.04644,
			42.09371,
			42.13801,
			42.1691666,
			42.2154,
			42.2616653,
			42.3069229,
			42.351265,
			42.39953,
			42.428566,
			42.475853,
			42.5060349,
			42.5535049,
			42.5990524,
			42.64428,
			42.69059,
			42.72056,
			42.76902,
			42.8142471,
			42.859,
			42.88845,
			42.93789,
			42.996788,
			43.0289536,
			43.0752335,
			43.12136,
			43.1682663,
			43.21601,
			43.2632675,
			43.2924423,
			43.3372421,
			43.385582,
			43.42974,
			43.47901,
			43.52384,
			43.56904,
			43.61477,
			43.6469345,
			43.69419,
			43.7415237,
			43.7874527,
			43.8319778,
			43.8797379,
			43.9274979,
			43.97178,
			44.00344,
			44.0347366,
			44.0789566,
			44.1085129,
			44.1541824,
			44.1856,
			44.2307053,
			44.2633743,
			44.29354,
			44.3398056,
			44.3856735,
			44.4317856,
			44.4770279,
			44.52233,
			44.5533829,
			44.5982132,
			44.6469955,
			44.6908035,
			44.720253,
			44.7683029,
			44.8290176,
			44.8742752,
			44.9034653,
			44.9498672,
			44.99746,
			45.0422,
			45.08947,
			45.13624,
			45.17994,
			45.2277,
			45.2734756,
			45.305748,
			45.350975,
			45.3981552,
			45.44393,
			45.4897079,
			45.537468,
			45.5672379,
			45.6119,
			45.6577377,
			45.7054977,
			45.75129,
			45.7803726,
			45.82862,
			45.88241,
			45.9311447,
			45.9774246,
			46.0226974,
			46.06846,
			46.0852432,
			46.1312943,
			46.1609421,
			46.20823,
			46.2520523,
			46.3003769,
			46.3449631,
			46.3912735,
			46.4365158,
			46.4828568,
			46.5299759,
			46.5757065,
			46.6059036,
			46.65142,
			46.69738,
			46.744194,
			46.78942,
			46.836174,
			46.8669052,
			46.91358,
			46.95939,
			47.0057,
			47.05149,
			47.0966721,
			47.1273727,
			47.1558151,
			47.203392,
			47.2497177,
			47.29389,
			47.3406754,
			47.3876572,
			47.43377,
			47.47741,
			47.50817,
			47.5541458,
			47.5991135,
			47.6468735,
			47.6936264,
			47.73945,
			47.78527,
			47.83303,
			47.8796768,
			47.9108658,
			47.9545059,
			48.00248,
			48.0461655,
			48.0784073,
			48.12516,
			48.16941,
			48.20072,
			48.2473526,
			48.2931,
			48.33837,
			48.38572,
			48.4318771,
			48.4771652,
			48.5244827,
			48.57174,
			48.6014023,
			48.64666,
			48.69494,
			48.7417831,
			48.7884,
			48.8344345,
			48.8795853,
			48.9278336,
			48.95652,
			49.0038223,
			49.03408,
			49.0782242,
			49.1227951,
			49.1688156,
			49.2150345,
			49.2613144,
			49.2925339,
			49.3393326,
			49.37104,
			49.416893,
			49.4625473,
			49.5209732,
			49.568718,
			49.613472,
			49.6597672,
			49.7065659,
			49.7357559,
			49.78414,
			49.8141861,
			49.86149,
			49.90577,
			49.93647,
			49.983345,
			50.0295029,
			50.0767746,
			50.1231,
			50.15455,
			50.1975327,
			50.2453232,
			50.30471,
			50.3520737,
			50.3967667,
			50.4450455,
			50.489357,
			50.5186234,
			50.5669327,
			50.61306,
			50.6568527,
			50.6877823,
			50.7363358,
			50.7833023,
			50.8283463,
			50.87464,
			50.919,
			50.96685,
			51.01098,
			51.0426559,
			51.0895,
			51.13488,
			51.1644058,
			51.2112045,
			51.24241,
			51.2897263,
			51.3485641,
			51.39637,
			51.441658,
			51.49102,
			51.536232,
			51.5653763,
			51.59606,
			51.6443253,
			51.6901321,
			51.7353745,
			51.7811356,
			51.828392,
			51.8732224,
			51.9180374,
			51.948616,
			51.9948044,
			52.04162,
			52.08747,
			52.1347275,
			52.1804123,
			52.2271957,
			52.2725143,
			52.3189,
			52.3474045,
			52.39672,
			52.4425278,
			52.4880142,
			52.5347061,
			52.5782852,
			52.61097,
			52.6545639,
			52.7025681,
			52.7468033,
			52.7916031,
			52.83938,
			52.86904,
			52.9143,
			52.9636,
			53.00936,
			53.0547562,
			53.1011124,
			53.1316,
			53.17939,
			53.2266769,
			53.27198,
			53.3188248,
			53.36399,
			53.3926926,
			53.4409866,
			53.4847946,
			53.530983,
			53.57978,
			53.6255722,
			53.6538773,
			53.70153,
			53.7343674,
			53.77903,
			53.8263,
			53.873085,
			53.91848,
			53.9636,
			54.0113754,
			54.0577164,
			54.08773,
			54.1361771,
			54.18142,
			54.2267227,
			54.271965,
			54.3189,
			54.35357,
			54.3976974,
			54.4449844,
			54.49032,
			54.5360641,
			54.5844345,
			54.6135025,
			54.66085,
			54.70611,
			54.7388077,
			54.78367,
			54.83033,
			54.8776321,
			54.92176,
			54.9539566,
			54.96923,
			55.0150223,
			55.06231,
			55.106102,
			55.13877,
			55.18467,
			55.23133,
			55.27761,
			55.32345,
			55.36869,
			55.41398,
			55.4607468,
			55.48948,
			55.53878,
			55.58512,
			55.6312943,
			55.6780777,
			55.72541,
			55.7415237,
			55.788063,
			55.8346176,
			55.880806,
			55.92663,
			55.9718857,
			56.001564,
			56.03443,
			56.080513,
			56.1278152,
			56.17312,
			56.218956,
			56.26467,
			56.3129654,
			56.3573227,
			56.4025955,
			56.43531,
			56.47953,
			56.5273361,
			56.55709,
			56.6018448,
			56.6466,
			56.6959,
			56.7386856,
			56.78515,
			56.81684,
			56.86396,
			56.90876,
			56.955513,
			57.00289,
			57.0346146,
			57.09443,
			57.141777,
			57.1882858,
			57.2343826,
			57.27964,
			57.3092728,
			57.354454,
			57.40162,
			57.44767,
			57.49346,
			57.5392838,
			57.5689621,
			57.6178665,
			57.66352,
			57.7088242,
			57.7517,
			57.8014145,
			57.8301,
			57.876915,
			57.92472,
			57.96853,
			58.0142746,
			58.0600662,
			58.09179,
			58.13961,
			58.1834335,
			58.2296371,
			58.2774277,
			58.32055,
			58.3514938,
			58.3982162,
			58.43093,
			58.47573,
			58.5054245,
			58.5503159,
			58.5970078,
			58.6121,
			58.65795,
			58.68801,
			58.7364273,
			58.78219,
			58.826973,
			58.8742752,
			58.9204941,
			58.9508743,
			58.998558,
			59.0443649,
			59.0902023,
			59.1359634,
			59.1833267,
			59.22953,
			59.2768173,
			59.30404,
			59.35137,
			59.3971024,
			59.4448929,
			59.4902267,
			59.53756,
			59.5849075,
			59.6290665,
			59.6592636,
			59.70713,
			59.7514725,
			59.7997971,
			59.843956,
			59.89083,
			59.92202,
			59.9693069,
			60.00109,
			60.04676,
			60.0906,
			60.1383743,
			60.1832657,
			60.23098,
			60.2616348,
			60.3054428,
			60.3376541,
			60.38511,
			60.42971,
			60.4468155,
			60.4916458,
			60.5364151,
			60.58068,
			60.613884,
			60.6591873,
			60.7054825,
			60.75245,
			60.7824326,
			60.8287277,
			60.8755264,
			60.919,
			60.96505,
			61.01435,
			61.0440445,
			61.0918045,
			61.13607,
			61.1834335,
			61.228096,
			61.27639,
			61.3204422,
			61.3659439,
			61.41372,
			61.4580154,
			61.48974,
			61.5365067,
			61.5817337,
			61.6285172,
			61.67434,
			61.7212143,
			61.75196,
			61.79856,
			61.8428268,
			61.8883743,
			61.935936,
			61.9645767,
			62.0123825,
			62.0571976,
			62.10444,
			62.1503677,
			62.1954727,
			62.2427444,
			62.271965,
			62.318306,
			62.3649979,
			62.40876,
			62.4419479,
			62.48539,
			62.5320053,
			62.5797958,
			62.6231,
			62.66941,
			62.717247,
			62.7623978,
			62.793602,
			62.8394241,
			62.8853226,
			62.9309464,
			62.97721,
			63.00843,
			63.0552139,
			63.10096,
			63.1472244,
			63.1920242,
			63.2393265,
			63.28521,
			63.31373,
			63.3449326,
			63.39126,
			63.4380569,
			63.4843979,
			63.5320663,
			63.5783463,
			63.62319,
			63.67095,
			63.7151871,
			63.76246,
			63.80976,
			63.837944,
			63.8844223,
			63.9336929,
			63.9793777,
			64.0246658,
			64.07194,
			64.10412,
			64.134346,
			64.18102,
			64.226326,
			64.27412,
			64.3190842,
			64.3673,
			64.4134,
			64.45867,
			64.50698,
			64.55251,
			64.59849,
			64.63018,
			64.67695,
			64.7233047,
			64.76859,
			64.81472,
			64.86402,
			64.8942,
			64.93902,
			64.9692,
			65.0154343,
			65.06373,
			65.10949,
			65.15418,
			65.20124,
			65.24882,
			65.29409,
			65.3403854,
			65.38836,
			65.41798,
			65.4470139,
			65.49531,
			65.54258,
			65.58802,
			65.6348,
			65.6794052,
			65.7277,
			65.77253,
			65.8174057,
			65.84844,
			65.89573,
			65.94155,
			65.98812,
			66.03419,
			66.0788345,
			66.11103,
			66.15688,
			66.2011,
			66.23179,
			66.2795944,
			66.32392,
			66.3722,
			66.41755,
			66.46427,
			66.51151,
			66.5573654,
			66.60412,
			66.65159,
			66.69715,
			66.7283554,
			66.77566,
			66.82131,
			66.867836,
			66.91349,
			66.9593,
			66.98951,
			67.03779,
			67.0815353,
			67.12884,
			67.17568,
			67.2218246,
			67.25121,
			67.30001,
			67.34669,
			67.3924942,
			67.4388046,
			67.46899,
			67.51472,
			67.561,
			67.6063,
			67.6369858,
			67.68383,
			67.7295151,
			67.77535,
			67.82136,
			67.86941,
			67.9146652,
			67.96148,
			68.00524,
			68.0364761,
			68.08175,
			68.11399,
			68.16128,
			68.2066,
			68.2510147,
			68.29763,
			68.3449,
			68.39074,
			68.4208145,
			68.46726,
			68.51292,
			68.55726,
			68.58941,
			68.63622,
			68.68307,
			68.7277451,
			68.77506,
			68.8223648,
			68.8686447,
			68.89828,
			68.94405,
			68.9913559,
			69.0361557,
			69.08239,
			69.12867,
			69.17599,
			69.22161,
			69.26717,
			69.3133,
			69.344,
			69.39036,
			69.4361038,
			69.4829941,
			69.52866,
			69.57595,
			69.60668,
			69.63636,
			69.68537,
			69.72942,
			69.77767,
			69.82078,
			69.86736,
			69.91352,
			69.96082,
			69.99203,
			70.0378647,
			70.08323,
			70.1309,
			70.1761856,
			70.22353,
			70.2692642,
			70.31504,
			70.34522,
			70.39298,
			70.4382858,
			70.48414,
			70.5303,
			70.57456,
			70.62122,
			70.65119,
			70.69578,
			70.74456,
			70.77471,
			70.81855,
			70.84864,
			70.89744,
			70.91303,
			70.9593,
			70.98946,
			71.03675,
			71.08149,
			71.12978,
			71.17458,
			71.2214,
			71.2672348,
			71.3138657,
			71.35912,
			71.40389,
			71.43658,
			71.48031,
			71.52909,
			71.57488,
			71.62051,
			71.6670151,
			71.69659,
			71.74436,
			71.78803,
			71.8364639,
			71.8816757,
			71.92646,
			71.95817,
			72.00497,
			72.05131,
			72.0803757,
			72.13015,
			72.17643,
			72.22126,
			72.25329,
			72.29978,
			72.34646,
			72.39225,
			72.4370346,
			72.48336,
			72.52851,
			72.57632,
			72.6213455,
			72.66889,
			72.71517,
			72.74636,
			72.7775345,
			72.82435,
			72.8691,
			72.91645,
			72.96162,
			73.0079,
			73.05183,
			73.09846,
			73.13015,
			73.1758957,
			73.22114,
			73.26852,
			73.3147659,
			73.36002,
			73.40533,
			73.43554,
			73.48441,
			73.5134354,
			73.57578,
			73.6204,
			73.66891,
			73.71259,
			73.75939,
			73.79064,
			73.83637,
			73.8815842,
			73.92893,
			73.97463,
			74.02154,
			74.0507,
			74.09832,
			74.1305161,
			74.1773148,
			74.2226,
			74.2689,
			74.31635,
			74.3614,
			74.40771,
			74.45342,
			74.50036,
			74.54801,
			74.57717,
			74.62499,
			74.6687546,
			74.715004,
			74.76177,
			74.80956,
			74.83929,
			74.88625,
			74.93127,
			74.97805,
			75.0220261,
			75.06861,
			75.10142,
			75.14657,
			75.1924057,
			75.2391739,
			75.26885,
			75.31411,
			75.36141,
			75.4067154,
			75.4394,
			75.4848251,
			75.53095,
			75.57824,
			75.62565,
			75.67036,
			75.71407,
			75.7447739,
			75.79061,
			75.83639,
			75.88427,
			75.9289,
			75.9741745,
			76.02181,
			76.06876,
			76.09739,
			76.1467056,
			76.19103,
			76.22109,
			76.26894,
			76.31368,
			76.3464,
			76.3936844,
			76.40981,
			76.4547653,
			76.5015945,
			76.53155,
			76.57986,
			76.62467,
			76.67199,
			76.7174454,
			76.7640152,
			76.808815,
			76.85438,
			76.90244,
			76.93108,
			76.97938,
			77.02616,
			77.07,
			77.1180038,
			77.1625443,
			77.19375,
			77.23954,
			77.2687454,
			77.31765,
			77.36431,
			77.3940048,
			77.43835,
			77.499794,
			77.54694,
			77.59376,
			77.63908,
			77.68546,
			77.71502,
			77.76383,
			77.807106,
			77.852,
			77.90087,
			77.9444046,
			77.9750748,
			78.0196,
			78.06829,
			78.11443,
			78.14413,
			78.19245,
			78.23725,
			78.28565,
			78.33082,
			78.3751,
			78.42243,
			78.4683151,
			78.49789,
			78.5446854,
			78.59298,
			78.6363144,
			78.68365,
			78.71523,
			78.7625351,
			78.80634,
			78.83755,
			78.8829,
			78.9296,
			78.9764,
			79.0213852,
			79.06812,
			79.1142654,
			79.16006,
			79.2038651,
			79.25121,
			79.2823257,
			79.3300858,
			79.37386,
			79.42104,
			79.46757,
			79.49868,
			79.54397,
			79.58982,
			79.63511,
			79.66474,
			79.7014847,
			79.746254,
			79.79302,
			79.84083,
			79.88523,
			79.93137,
			79.9786453,
			80.02343,
			80.05224,
			80.09835,
			80.14666,
			80.19244,
			80.237236,
			80.28196,
			80.31364,
			80.36092,
			80.40374,
			80.43595,
			80.48118,
			80.52895,
			80.57523,
			80.62057,
			80.6670456,
			80.71261,
			80.7604,
			80.80624,
			80.83692,
			80.88116,
			80.92747,
			80.9747238,
			81.00492,
			81.05187,
			81.09644,
			81.1437,
			81.18955,
			81.2363052,
			81.283165,
			81.32986,
			81.3615341,
			81.40534,
			81.43653,
			81.48177,
			81.51244,
			81.56124,
			81.60752,
			81.65339,
			81.70104,
			81.7463,
			81.77749,
			81.82279,
			81.86904,
			81.88531,
			81.9179153,
			81.96204,
			82.01133,
			82.04101,
			82.08741,
			82.11872,
			82.14913,
			82.1939,
			82.24122,
			82.28405,
			82.33076,
			82.36144,
			82.4087143,
			82.45426,
			82.503334,
			82.54655,
			82.59231,
			82.64112,
			82.6687851,
			82.71815,
			82.762825,
			82.8106155,
			82.85647,
			82.90371,
			82.93397,
			82.97961,
			83.0254,
			83.06965,
			83.1177,
			83.16265,
			83.19436,
			83.22408,
			83.27181,
			83.3176651,
			83.36381,
			83.39399,
			83.43926,
			83.50364,
			83.54688,
			83.59519,
			83.64149,
			83.68806,
			83.71851,
			83.7631454,
			83.80991,
			83.84214,
			83.8878555,
			83.93484,
			83.97941,
			84.02668,
			84.07246,
			84.11886,
			84.16497,
			84.21024,
			84.24197,
			84.28788,
			84.3170547,
			84.3641739,
			84.41091,
			84.4582443,
			84.50399,
			84.55082,
			84.59399,
			84.62617,
			84.67037,
			84.7183151,
			84.76328,
			84.8100052,
			84.8562851,
			84.88453,
			84.93217,
			84.96285,
			85.00562,
			85.0213547,
			85.05146,
			85.09817,
			85.13038,
			85.17672,
			85.223,
			85.2703,
			85.28448,
			85.3311539,
			85.3789749,
			85.4222946,
			85.47109,
			85.51383,
			85.54655,
			85.59284,
			85.62122,
			85.6685257,
			85.7135544,
			85.76237,
			85.80564,
			85.83788,
			85.8837662,
			85.929924,
			85.97526,
			86.02209,
			86.067955,
			86.11407,
			86.14527,
			86.19259,
			86.23637,
			86.28535,
			86.33094,
			86.37475,
			86.42205,
			86.46528,
			86.49798,
			86.54427,
			86.59058,
			86.63639,
			86.68163,
			86.7289352,
			86.7752762,
			86.8212051,
			86.8518,
			86.89901,
			86.94431,
			86.9906845,
			87.03742,
			87.08169,
			87.1139,
			87.1587,
			87.18989,
			87.23591,
			87.2840652,
			87.33074,
			87.37754,
			87.42237,
			87.46812,
			87.5144,
			87.54658,
			87.59101,
			87.63668,
			87.68357,
			87.73072,
			87.7770157,
			87.82171,
			87.8671951,
			87.8998947,
			87.94657,
			87.99038,
			88.03619,
			88.080925,
			88.13024,
			88.16244,
			88.20675,
			88.2548447,
			88.29931,
			88.33837
		]
	],
	'8703_9043_7982': [
		[
			101.076
		],
		[
			0
		],
		[
			0,
			1.734,
			3.291,
			6.408,
			7.966,
			11.086,
			14.211,
			18.91,
			22.053,
			28.371,
			29.955,
			34.714,
			37.894,
			42.671,
			45.857,
			50.645,
			57.044,
			58.648,
			61.862,
			63.485,
			66.734,
			69.992,
			74.848,
			78.073,
			84.53,
			86.148,
			91.012,
			94.26,
			99.14,
			102.396,
			107.274,
			110.506,
			112.112,
			115.301,
			118.453,
			120.016,
			123.112,
			126.17,
			130.69,
			133.658,
			139.476,
			140.907,
			145.142,
			147.917,
			152.008,
			154.689,
			158.639,
			162.503,
			165.032,
			168.754,
			171.189,
			173.587,
			177.121,
			179.433,
			182.832,
			183.947,
			185.052,
			188.313,
			190.454,
			193.596,
			195.647,
			197.661,
			200.592,
			201.55,
			203.436,
			205.282,
			206.192,
			207.093,
			209.748,
			211.474,
			213.179,
			214.011,
			215.65,
			218.062,
			220.416,
			221.961,
			223.491,
			225.739,
			227.228,
			230.156,
			230.878,
			233.042,
			234.461,
			236.571,
			237.977,
			240.091,
			241.5,
			242.91,
			243.623,
			245.137,
			245.894,
			247.411,
			249.006,
			251.702,
			253.504,
			256.614,
			257.656,
			258.707,
			262.05,
			264.285,
			267.653,
			269.844,
			273.06,
			274.949,
			277.632,
			279.295,
			280.039,
			281.527,
			283.015,
			285.238,
			286.717,
			288.95,
			289.715,
			290.48,
			292.776,
			294.323,
			296.67,
			298.234,
			300.611,
			302.994,
			304.587,
			306.182,
			306.98,
			308.576,
			310.189,
			312.614,
			314.225,
			316.699,
			318.353,
			320.847,
			322.535,
			324.25,
			326.824,
			328.54,
			331.164,
			332.929,
			336.495,
			337.395,
			340.112,
			341.938,
			344.704,
			346.567,
			349.384,
			352.223,
			354.131,
			356.052,
			358.955,
			360.907,
			363.859,
			364.849,
			365.843,
			368.843,
			369.852,
			370.866,
			373.941,
			376,
			379.107,
			383.278,
			384.323,
			386.421,
			387.471,
			389.572,
			391.675,
			394.832,
			396.938,
			400.101,
			401.165,
			402.229,
			405.433,
			407.609,
			410.911,
			413.179,
			416.585,
			418.856,
			422.158,
			425.384,
			427.482,
			429.578,
			432.769,
			434.905,
			439.246,
			440.334,
			443.604,
			445.783,
			449.001,
			451.146,
			454.331,
			457.545,
			459.706,
			461.885,
			462.98,
			465.173,
			467.353,
			470.62,
			472.815,
			476.116,
			478.313,
			481.611,
			483.792,
			487.077,
			489.275,
			492.596,
			494.826,
			495.944,
			498.188,
			501.568,
			503.815,
			506.071,
			509.425,
			511.665,
			516.118,
			517.238,
			519.485,
			520.611,
			522.889,
			526.334,
			528.655,
			530.985,
			534.507,
			536.868,
			541.612,
			542.81,
			546.415,
			548.828,
			552.463,
			554.898,
			558.566,
			562.25,
			564.712,
			567.181,
			570.894,
			573.377,
			578.365,
			579.616,
			583.382,
			585.902,
			589.71,
			592.216,
			596.037,
			601.088,
			602.357,
			604.901,
			606.195,
			608.743,
			611.294,
			615.132,
			617.674,
			621.52,
			622.799,
			624.073,
			627.883,
			630.371,
			634.041,
			636.441,
			639.976,
			643.451,
			645.734,
			648.009,
			649.139,
			651.389,
			653.634,
			656.99,
			659.223,
			663.676,
			664.793,
			668.139,
			670.387,
			673.762,
			676.051,
			679.499,
			683.014,
			685.38,
			688.948,
			691.358,
			693.772,
			697.428,
			698.651,
			699.873,
			703.547,
			704.778,
			706.013,
			709.727,
			712.218,
			715.932,
			718.384,
			722.012,
			724.409,
			725.595,
			727.957,
			731.46,
			732.617,
			736.057,
			738.324,
			740.582,
			741.705,
			743.949,
			746.189,
			747.307,
			750.653,
			752.872,
			755.087,
			758.385,
			760.583,
			764.982,
			766.087,
			769.408,
			771.639,
			775.005,
			777.279,
			779.561,
			780.708,
			785.323,
			786.483,
			789.977,
			792.298,
			794.647,
			798.185,
			800.554,
			804.126,
			805.32,
			806.518,
			810.123,
			812.536,
			816.166,
			818.59,
			822.237,
			824.675,
			825.897,
			828.339,
			830.789,
			832.016,
			834.473,
			836.937,
			840.645,
			843.122,
			846.849,
			849.341,
			853.093,
			855.606,
			859.392,
			861.927,
			865.744,
			869.579,
			872.145,
			876.008,
			878.593,
			881.184,
			885.082,
			887.681,
			892.899,
			894.208,
			898.145,
			900.78,
			904.745,
			907.397,
			910.056,
			911.388,
			915.395,
			918.073,
			922.094,
			923.432,
			927.45,
			930.089,
			933.994,
			936.536,
			940.325,
			944.06,
			946.534,
			949.021,
			952.703,
			955.15,
			960.03,
			961.257,
			966.148,
			967.385,
			971.101,
			973.589,
			977.359,
			982.409,
			983.682,
			986.277,
			987.601,
			990.26,
			993.055,
			997.225,
			999.99,
			1003.954,
			1005.271,
			1006.534,
			1010.306,
			1012.795,
			1016.527,
			1019.011,
			1022.715,
			1025.16,
			1027.578,
			1028.775,
			1031.127,
			1032.3,
			1034.623,
			1036.906,
			1040.277,
			1042.457,
			1045.655,
			1046.699,
			1047.733,
			1050.79,
			1052.793,
			1055.736,
			1057.693,
			1060.595,
			1062.52,
			1063.466,
			1065.336,
			1067.159,
			1068.067,
			1069.858,
			1071.618,
			1074.197,
			1075.873,
			1079.144,
			1079.944,
			1082.318,
			1083.891,
			1086.238,
			1087.809,
			1090.133,
			1091.674,
			1092.443,
			1093.975,
			1096.261,
			1097.02,
			1099.283,
			1101.526,
			1102.998,
			1105.178,
			1105.901,
			1106.622,
			1108.751,
			1110.158,
			1111.559,
			1113.661,
			1115.057,
			1117.146,
			1117.855,
			1118.562,
			1121.386,
			1122.092,
			1124.255,
			1125.696,
			1127.883,
			1130.879,
			1131.629,
			1133.99,
			1135.596,
			1137.203,
			1139.769,
			1141.485,
			1144.105,
			1145.866,
			1148.538,
			1150.35,
			1153.065,
			1154.875,
			1157.584,
			1160.247,
			1162.02,
			1164.535,
			1166.182,
			1168.607,
			1170.17,
			1171.736,
			1174.868,
			1175.652,
			1178.008,
			1179.559,
			1181.895,
			1183.435,
			1185.692,
			1187.205,
			1187.965,
			1189.456,
			1190.952,
			1191.703,
			1193.213,
			1194.765,
			1197.103,
			1198.678,
			1201.875,
			1202.676,
			1205.109,
			1206.739,
			1209.201,
			1210.855,
			1212.517,
			1213.352,
			1215.032,
			1217.562,
			1220.135,
			1221.865,
			1223.608,
			1226.246,
			1228.02,
			1230.704,
			1231.605,
			1232.509,
			1235.238,
			1237.073,
			1238.92,
			1241.712,
			1243.589,
			1246.427,
			1247.379,
			1248.335,
			1252.188,
			1253.159,
			1256.092,
			1258.063,
			1260.065,
			1261.063,
			1263.068,
			1265.069,
			1266.083,
			1268.118,
			1269.141,
			1271.191,
			1273.252,
			1276.361,
			1278.444,
			1281.583,
			1283.673,
			1286.819,
			1288.929,
			1292.122,
			1294.267,
			1297.551,
			1299.748,
			1303.096,
			1306.499,
			1308.814,
			1311.156,
			1314.755,
			1317.173,
			1320.808,
			1323.166,
			1326.688,
			1329.006,
			1332.498,
			1334.837,
			1338.297,
			1340.617,
			1344.055,
			1347.529,
			1349.844,
			1352.168,
			1355.669,
			1358.013,
			1361.545,
			1363.89,
			1367.452,
			1369.836,
			1373.446,
			1375.848,
			1379.464,
			1383.097,
			1385.528,
			1387.944,
			1389.164,
			1391.611,
			1394.063,
			1397.775,
			1400.244,
			1403.96,
			1405.202,
			1406.445,
			1410.188,
			1412.695,
			1416.482,
			1419.027,
			1422.862,
			1426.704,
			1429.262,
			1433.085,
			1434.353,
			1438.128,
			1440.591,
			1444.253,
			1449.007,
			1450.174,
			1453.615,
			1455.87,
			1458.071,
			1461.288,
			1463.393,
			1466.484,
			1467.504,
			1468.518,
			1471.524,
			1473.485,
			1476.363,
			1478.239,
			1480.081,
			1480.99,
			1482.784,
			1484.551,
			1485.423,
			1487.988,
			1489.663,
			1491.321,
			1493.722,
			1495.281,
			1497.558,
			1499.042,
			1501.224,
			1502.665,
			1504.817,
			1506.249,
			1507.685,
			1508.405,
			1509.848,
			1512.021,
			1514.206,
			1515.66,
			1517.117,
			1519.3,
			1520.026,
			1520.751,
			1522.911,
			1524.343,
			1526.51,
			1527.942,
			1530.101,
			1531.553,
			1532.996,
			1533.715,
			1535.175,
			1535.909,
			1537.37,
			1539.564,
			1541.069,
			1542.565,
			1544.83,
			1545.622,
			1546.412,
			1548.777,
			1549.574,
			1550.406,
			1552.879,
			1554.516,
			1556.985,
			1558.616,
			1561.03,
			1562.62,
			1563.411,
			1564.202,
			1566.507,
			1568.028,
			1570.257,
			1571.665,
			1573.069,
			1573.771,
			1575.145,
			1576.473,
			1577.136,
			1578.464,
			1579.129,
			1580.443,
			1581.747,
			1583.709,
			1585.016,
			1587.611,
			1588.265,
			1590.892,
			1591.55,
			1593.544,
			1594.892,
			1596.96,
			1598.357,
			1599.77,
			1600.501,
			1601.977,
			1602.72,
			1604.219,
			1605.74,
			1608.06,
			1609.624,
			1612.008,
			1612.811,
			1613.617,
			1616.058,
			1617.703,
			1620.198,
			1621.874,
			1624.389,
			1626.073,
			1628.608,
			1630.322,
			1631.198,
			1632.914,
			1634.659,
			1637.302,
			1639.08,
			1642.674,
			1643.58,
			1646.318,
			1648.16,
			1650.945,
			1652.822,
			1655.66,
			1658.524,
			1660.448,
			1663.354,
			1665.304,
			1667.269,
			1670.244,
			1672.246,
			1676.293,
			1677.314,
			1680.396,
			1682.464,
			1685.586,
			1687.68,
			1690.832,
			1693.981,
			1696.088,
			1698.21,
			1699.278,
			1701.422,
			1703.58,
			1706.841,
			1709.022,
			1712.307,
			1713.405,
			1714.503,
			1717.812,
			1720.027,
			1723.367,
			1725.601,
			1727.846,
			1731.232,
			1734.643,
			1738.081,
			1739.233,
			1742.706,
			1746.202,
			1747.372,
			1748.545,
			1750.897,
			1753.26,
			1754.445,
			1758.014,
			1760.405,
			1762.806,
			1766.426,
			1768.851,
			1772.506,
			1773.729,
			1774.955,
			1779.883,
			1781.12,
			1784.848,
			1787.345,
			1791.109,
			1793.629,
			1796.158,
			1797.425,
			1801.236,
			1803.776,
			1806.304,
			1810.054,
			1812.515,
			1816.141,
			1817.333,
			1818.515,
			1822.006,
			1824.289,
			1827.643,
			1829.834,
			1831.988,
			1833.052,
			1835.151,
			1836.187,
			1838.232,
			1840.241,
			1841.232,
			1843.188,
			1845.108,
			1847.92,
			1849.753,
			1852.44,
			1853.32,
			1854.19,
			1856.75,
			1858.411,
			1860.838,
			1862.412,
			1863.956,
			1864.718,
			1866.238,
			1866.986,
			1868.471,
			1870.68,
			1872.145,
			1873.595,
			1875.751,
			1876.468,
			1877.184,
			1879.308,
			1880.012,
			1880.715,
			1882.821,
			1884.213,
			1886.295,
			1887.683,
			1889.785,
			1891.187,
			1891.888,
			1892.589,
			1895.467,
			1896.913,
			1898.384,
			1899.903,
			1901.424,
			1902.185,
			1903.756,
			1905.387,
			1906.202,
			1908.677,
			1910.395,
			1912.112,
			1914.726,
			1916.483,
			1919.112,
			1920.854,
			1923.457,
			1925.128,
			1927.627,
			1929.244,
			1930.822,
			1931.611,
			1933.19,
			1935.536,
			1937.884,
			1939.46,
			1941.039,
			1943.373,
			1944.917,
			1947.211,
			1948.716,
			1950.875,
			1952.274,
			1954.292,
			1955.572,
			1957.432,
			1959.785,
			1960.352,
			1961.468,
			1962.019,
			1963.111,
			1964.197,
			1966.917,
			1968.536,
			1969.078,
			1969.631,
			1971.284,
			1972.381,
			1974.023,
			1975.177,
			1976.325,
			1976.897,
			1978.037,
			1978.605,
			1979.776,
			1981.549,
			1982.72,
			1983.886,
			1985.679,
			1986.867,
			1988.635,
			1989.227,
			1989.854,
			1991.719,
			1992.955,
			1994.856,
			1996.197,
			1998.201,
			1999.548,
			2000.256,
			2001.665,
			2003.778,
			2005.167,
			2006.538,
			2008.606,
			2009.937,
			2011.823,
			2013.085,
			2014.948,
			2016.114,
			2017.287,
			2019.063,
			2020.217,
			2022.502,
			2023.66,
			2024.816,
			2025.374,
			2026.495,
			2027.627,
			2028.771,
			2030.507,
			2032.853,
			2033.448,
			2034.65,
			2035.27,
			2036.519,
			2037.782,
			2039.707,
			2041.033,
			2043.045,
			2043.723,
			2044.404,
			2047.215,
			2047.926,
			2050.079,
			2051.529,
			2053.724,
			2055.201,
			2057.426,
			2059.697,
			2061.244,
			2062.794,
			2065.151,
			2066.74,
			2069.962,
			2070.77,
			2073.212,
			2074.858,
			2077.298,
			2078.954,
			2081.427,
			2083.08,
			2083.911,
			2085.582,
			2087.244,
			2088.08,
			2089.761,
			2091.479,
			2094.109,
			2095.888,
			2099.581,
			2100.513,
			2103.313,
			2105.196,
			2108.018,
			2109.909,
			2112.768,
			2115.653,
			2117.613,
			2119.588,
			2120.58,
			2122.57,
			2124.569,
			2127.576,
			2129.584,
			2133.652,
			2134.678,
			2137.776,
			2139.862,
			2143.014,
			2145.128,
			2148.303,
			2151.489,
			2153.618,
			2155.756,
			2156.83,
			2158.989,
			2161.158,
			2163.344,
			2166.646,
			2169.983,
			2172.221,
			2175.61,
			2177.885,
			2180.175,
			2183.632,
			2185.95,
			2189.435,
			2191.758,
			2196.38,
			2197.528,
			2200.953,
			2203.209,
			2205.442,
			2206.545,
			2208.732,
			2210.888,
			2211.954,
			2214.068,
			2217.19,
			2219.252,
			2222.324,
			2224.388,
			2227.498,
			2228.547,
			2229.607,
			2232.796,
			2234.942,
			2238.179,
			2240.355,
			2243.628,
			2245.797,
			2246.882,
			2249.049,
			2251.18,
			2252.246,
			2254.372,
			2256.475,
			2259.622,
			2261.728,
			2264.891,
			2265.955,
			2267.017,
			2270.215,
			2272.37,
			2275.604,
			2277.762,
			2280.968,
			2283.078,
			2286.213,
			2289.316,
			2291.394,
			2293.475,
			2296.617,
			2298.723,
			2301.915,
			2302.985,
			2304.057,
			2307.344,
			2309.56,
			2312.966,
			2315.252,
			2318.709,
			2321.011,
			2323.301,
			2326.709,
			2327.827,
			2328.947,
			2332.232,
			2334.38,
			2337.575,
			2341.82,
			2342.885,
			2345.023,
			2346.096,
			2348.254,
			2350.42,
			2353.691,
			2355.884,
			2359.182,
			2360.267,
			2361.373,
			2364.708,
			2366.931,
			2370.283,
			2372.532,
			2375.93,
			2378.191,
			2380.482,
			2381.632,
			2383.938,
			2385.095,
			2387.413,
			2390.896,
			2393.22,
			2395.542,
			2399.043,
			2400.215,
			2401.388,
			2406.13,
			2407.324,
			2410.95,
			2413.387,
			2417.085,
			2419.556,
			2422.03,
			2423.269,
			2425.734,
			2426.969,
			2429.445,
			2431.925,
			2435.66,
			2438.182,
			2441.946,
			2443.204,
			2444.442,
			2448.251,
			2450.762,
			2454.59,
			2457.133,
			2460.937,
			2466.074,
			2467.355,
			2471.206,
			2473.779,
			2476.335,
			2480.234,
			2482.819,
			2486.68,
			2489.276,
			2494.507,
			2495.813,
			2499.715,
			2502.331,
			2506.259,
			2510.18,
			2512.797,
			2516.734,
			2519.37,
			2522.018,
			2526.02,
			2527.364,
			2528.71,
			2532.763,
			2535.476,
			2539.557,
			2542.287,
			2546.394,
			2549.142,
			2553.275,
			2556.039,
			2560.195,
			2564.363,
			2565.756,
			2569.94,
			2572.737,
			2576.942,
			2579.752,
			2583.978,
			2588.219,
			2591.055,
			2593.897,
			2598.173,
			2601.032,
			2606.769,
			2608.208,
			2612.533,
			2613.979,
			2615.427,
			2619.781,
			2622.692,
			2625.611,
			2627.073,
			2630.002,
			2632.94,
			2634.411,
			2637.358,
			2638.833,
			2641.791,
			2644.754,
			2649.211,
			2652.188,
			2656.663,
			2659.65,
			2664.141,
			2667.142,
			2671.654,
			2674.669,
			2679.201,
			2682.229,
			2685.262,
			2686.782,
			2689.826,
			2691.35,
			2694.403,
			2697.461,
			2702.062,
			2705.134,
			2709.754,
			2711.298,
			2712.843,
			2717.486,
			2720.59,
			2725.26,
			2728.381,
			2733.078,
			2736.216,
			2737.787,
			2740.935,
			2745.666,
			2748.826,
			2751.992,
			2756.751,
			2759.931,
			2766.289,
			2767.88,
			2772.655,
			2775.844,
			2780.633,
			2783.834,
			2788.649,
			2793.478,
			2796.709,
			2799.956,
			2801.586,
			2804.859,
			2808.128,
			2813.019,
			2816.274,
			2822.906,
			2824.571,
			2829.524,
			2832.803,
			2837.705,
			2840.979,
			2844.264,
			2850.864,
			2852.519,
			2857.496,
			2859.156,
			2860.818,
			2865.804,
			2869.131,
			2874.13,
			2877.469,
			2880.812,
			2882.486,
			2887.514,
			2890.871,
			2894.234,
			2899.289,
			2917.9,
			2919.598,
			2924.711,
			2928.129,
			2933.269,
			2936.702,
			2940.14,
			2941.86,
			2947.03,
			2950.481,
			2953.936,
			2959.127,
			2962.594,
			2967.799,
			2971.277,
			2976.499,
			2979.986,
			2985.226,
			2988.724,
			2993.985,
			2997.508,
			3002.837,
			3006.42,
			3011.767,
			3015.292,
			3020.552,
			3024.056,
			3029.32,
			3031.077,
			3032.835,
			3038.118,
			3041.646,
			3046.949,
			3050.493,
			3055.823,
			3061.177,
			3064.763,
			3070.146,
			3073.717,
			3077.284,
			3082.633,
			3086.203,
			3091.565,
			3093.354,
			3095.145,
			3100.524,
			3104.115,
			3109.507,
			3113.106,
			3116.708,
			3118.511,
			3122.121,
			3127.537,
			3131.15,
			3136.575,
			3140.194,
			3145.628,
			3147.442,
			3149.255,
			3152.885,
			3156.517,
			3158.334,
			3163.789,
			3167.432,
			3171.08,
			3176.566,
			3180.232,
			3185.743,
			3187.581,
			3189.421,
			3194.945,
			3196.789,
			3198.634,
			3204.173,
			3207.87,
			3213.423,
			3220.836,
			3222.691,
			3226.403,
			3228.26,
			3231.977,
			3235.696,
			3241.279,
			3245.005,
			3250.6,
			3252.466,
			3254.333,
			3259.938,
			3263.675,
			3269.276,
			3273.006,
			3276.739,
			3278.607,
			3282.343,
			3287.96,
			3293.594,
			3297.356,
			3301.123,
			3306.781,
			3310.556,
			3316.228,
			3318.12,
			3320.012,
			3325.695,
			3329.486,
			3335.178,
			3338.977,
			3344.68,
			3348.485,
			3350.389,
			3354.198,
			3359.916,
			3363.731,
			3367.549,
			3373.28,
			3375.191,
			3377.104,
			3382.845,
			3386.676,
			3392.428,
			3396.267,
			3402.037,
			3405.894,
			3411.677,
			3415.529,
			3421.309,
			3427.094,
			3430.953,
			3434.814,
			3440.612,
			3442.545,
			3444.479,
			3450.285,
			3452.221,
			3454.157,
			3459.97,
			3463.849,
			3469.673,
			3473.559,
			3477.444,
			3483.272,
			3489.105,
			3494.946,
			3496.894,
			3502.744,
			3506.646,
			3512.503,
			3516.411,
			3522.277,
			3528.146,
			3532.062,
			3535.98,
			3541.861,
			3545.783,
			3551.671,
			3553.634,
			3555.598,
			3561.493,
			3565.425,
			3571.325,
			3575.262,
			3579.201,
			3581.172,
			3585.115,
			3587.087,
			3591.031,
			3594.977,
			3596.951,
			3600.899,
			3604.849,
			3610.778,
			3614.732,
			3620.669,
			3622.649,
			3624.629,
			3632.56,
			3634.544,
			3640.496,
			3644.465,
			3650.419,
			3654.391,
			3656.377,
			3660.352,
			3666.316,
			3670.294,
			3674.273,
			3680.246,
			3684.229,
			3692.197,
			3694.188,
			3700.162,
			3704.144,
			3710.119,
			3714.103,
			3718.09,
			3720.084,
			3726.07,
			3730.066,
			3736.065,
			3740.068,
			3744.073,
			3750.083,
			3752.088,
			3754.093,
			3760.109,
			3762.114,
			3764.12,
			3770.14,
			3774.155,
			3778.172,
			3784.199,
			3788.219,
			3790.229,
			3794.252,
			3798.278,
			3806.415,
			3808.464,
			3814.607,
			3818.653,
			3824.681,
			3832.707,
			3834.712,
			3838.703,
			3840.698,
			3844.698,
			3848.715,
			3854.764,
			3858.803,
			3864.865,
			3868.909,
			3877.002,
			3879.027,
			3885.102,
			3889.153,
			3893.205,
			3895.232,
			3899.288,
			3903.354,
			3905.396,
			3911.544,
			3915.635,
			3919.696,
			3925.772,
			3929.825,
			3937.928,
			3939.951,
			3946.016,
			3950.065,
			3956.131,
			3960.167,
			3966.228,
			3970.283,
			3976.379,
			3982.482,
			3986.552,
			3990.625,
			3996.746,
			4000.834,
			4006.959,
			4011.036,
			4017.146,
			4021.221,
			4027.333,
			4031.408,
			4037.522,
			4043.639,
			4045.678,
			4051.794,
			4053.833,
			4057.912,
			4061.99,
			4068.085,
			4072.12,
			4080.079,
			4082.044,
			4085.945,
			4087.882,
			4091.725,
			4095.527,
			4101.149,
			4104.847,
			4110.317,
			4112.12,
			4113.914,
			4120.988,
			4122.732,
			4127.903,
			4131.302,
			4134.662,
			4136.327,
			4139.628,
			4144.507,
			4149.299,
			4152.445,
			4155.553,
			4160.143,
			4163.158,
			4167.613,
			4169.079,
			4170.536,
			4174.847,
			4177.673,
			4181.84,
			4184.569,
			4187.26,
			4188.592,
			4191.227,
			4195.108,
			4198.907,
			4201.395,
			4203.848,
			4207.483,
			4209.854,
			4213.353,
			4215.642,
			4219.009,
			4221.212,
			4224.468,
			4226.619,
			4229.821,
			4231.973,
			4233.052,
			4235.23,
			4237.398,
			4238.485,
			4240.667,
			4242.85,
			4246.151,
			4248.35,
			4251.669,
			4253.878,
			4257.205,
			4259.417,
			4262.753,
			4264.979,
			4267.211,
			4268.327,
			4270.568,
			4271.694,
			4273.946,
			4277.33,
			4279.583,
			4281.839,
			4285.209,
			4287.446,
			4290.789,
			4293.018,
			4296.349,
			4298.559,
			4301.859,
			4304.051,
			4306.233,
			4307.319,
			4309.489,
			4311.657,
			4315.994,
			4317.071,
			4320.302,
			4322.442,
			4324.57,
			4325.633,
			4327.767,
			4330.967,
			4334.189,
			4336.336,
			4338.489,
			4341.716,
			4343.886,
			4347.149,
			4348.271,
			4349.404,
			4352.793,
			4353.907,
			4355.019,
			4358.316,
			4360.41,
			4362.497,
			4363.539,
			4365.622,
			4368.738,
			4371.874,
			4373.964,
			4376.086,
			4379.266,
			4381.366,
			4384.506,
			4385.528,
			4386.55,
			4389.614,
			4391.66,
			4394.723,
			4396.792,
			4398.855,
			4399.885,
			4401.937,
			4403.983,
			4405.003,
			4407.995,
			4409.983,
			4411.945,
			4414.883,
			4416.853,
			4419.801,
			4420.784,
			4421.767,
			4424.715,
			4426.683,
			4429.636,
			4431.606,
			4434.562,
			4436.532,
			4437.517,
			4439.485,
			4441.455,
			4442.441,
			4444.412,
			4446.385,
			4449.365,
			4450.357,
			4451.353,
			4454.374,
			4455.38,
			4456.386,
			4459.383,
			4461.378,
			4464.305,
			4466.244,
			4468.174,
			4469.134,
			4471.053,
			4473.934,
			4476.813,
			4478.731,
			4480.647,
			4483.518,
			4485.428,
			4487.336,
			4489.239,
			4490.189,
			4493.04,
			4494.94,
			4497.794,
			4499.712,
			4501.644,
			4505.565,
			4506.568,
			4509.599,
			4511.743,
			4514.992,
			4517.231,
			4520.563,
			4524.907,
			4525.965,
			4529.152,
			4531.303,
			4533.467,
			4536.73,
			4538.958,
			4543.371,
			4544.477,
			4547.761,
			4549.933,
			4553.164,
			4555.317,
			4558.489,
			4562.727,
			4563.807,
			4567.012,
			4569.168,
			4571.315,
			4574.604,
			4576.81,
			4581.297,
			4582.425,
			4585.824,
			4586.961,
			4588.096,
			4591.513,
			4593.804,
			4597.256,
			4600.731,
			4603.059,
			4605.396,
			4606.568,
			4608.92,
			4611.28,
			4614.839,
			4616.03,
			4617.223,
			4620.815,
			4622.018,
			4623.221,
			4626.853,
			4629.284,
			4632.95,
			4635.404,
			4637.866,
			4639.1,
			4641.572,
			4642.811,
			4645.291,
			4649.022,
			4651.519,
			4654.022,
			4656.534,
			4659.054,
			4660.316,
			4664.134,
			4665.383,
			4666.676,
			4670.482,
			4673.043,
			4675.612,
			4679.48,
			4683.366,
			4685.966,
			4687.265,
			4688.565,
			4692.472,
			4693.778,
			4695.085,
			4699.017,
			4701.646,
			4704.282,
			4705.602,
			4708.248,
			4712.23,
			4716.227,
			4718.9,
			4721.58,
			4725.611,
			4728.309,
			4733.724,
			4735.082,
			4739.169,
			4740.535,
			4741.903,
			4746.017,
			4748.768,
			4752.908,
			4758.453,
			4759.844,
			4762.629,
			4764.024,
			4766.82,
			4769.622,
			4773.837,
			4776.656,
			4782.315,
			4783.734,
			4788.001,
			4790.854,
			4795.146,
			4798.016,
			4802.332,
			4806.664,
			4809.561,
			4812.463,
			4813.916,
			4816.826,
			4819.742,
			4824.113,
			4827.032,
			4832.892,
			4834.365,
			4838.796,
			4841.76,
			4846.218,
			4849.198,
			4853.68,
			4858.175,
			4861.179,
			4865.695,
			4868.713,
			4871.738,
			4876.288,
			4879.329,
			4885.429,
			4886.959,
			4891.573,
			4894.663,
			4899.296,
			4902.384,
			4907.021,
			4910.134,
			4914.81,
			4919.491,
			4922.597,
			4925.709,
			4928.816,
			4930.28
		],
		[
			0,
			0.022,
			0.053,
			0.115,
			0.13,
			0.177,
			0.24,
			0.301,
			0.363,
			0.458,
			0.487,
			0.551,
			0.612,
			0.676,
			0.739,
			0.8,
			0.895,
			0.926,
			0.986,
			1.004,
			1.049,
			1.112,
			1.174,
			1.236,
			1.331,
			1.362,
			1.423,
			1.484,
			1.554,
			1.61,
			1.673,
			1.734,
			1.767,
			1.797,
			1.86,
			1.875,
			1.923,
			1.986,
			2.045,
			2.108,
			2.204,
			2.235,
			2.297,
			2.358,
			2.421,
			2.483,
			2.547,
			2.641,
			2.671,
			2.748,
			2.796,
			2.857,
			2.919,
			2.98,
			3.044,
			3.074,
			3.106,
			3.169,
			3.231,
			3.295,
			3.356,
			3.417,
			3.479,
			3.511,
			3.543,
			3.604,
			3.625,
			3.667,
			3.728,
			3.792,
			3.839,
			3.855,
			3.917,
			3.978,
			4.057,
			4.102,
			4.167,
			4.227,
			4.274,
			4.384,
			4.415,
			4.492,
			4.54,
			4.603,
			4.663,
			4.727,
			4.787,
			4.82,
			4.851,
			4.914,
			4.928,
			4.975,
			5.041,
			5.099,
			5.163,
			5.226,
			5.257,
			5.287,
			5.35,
			5.411,
			5.474,
			5.537,
			5.598,
			5.661,
			5.724,
			5.785,
			5.801,
			5.848,
			5.911,
			5.972,
			6.034,
			6.097,
			6.128,
			6.159,
			6.22,
			6.283,
			6.346,
			6.408,
			6.47,
			6.564,
			6.594,
			6.658,
			6.673,
			6.721,
			6.783,
			6.846,
			6.908,
			6.969,
			7.032,
			7.108,
			7.156,
			7.218,
			7.28,
			7.341,
			7.404,
			7.467,
			7.545,
			7.591,
			7.653,
			7.716,
			7.778,
			7.84,
			7.903,
			7.982,
			8.029,
			8.091,
			8.152,
			8.199,
			8.277,
			8.31,
			8.341,
			8.402,
			8.419,
			8.463,
			8.528,
			8.59,
			8.653,
			8.746,
			8.777,
			8.84,
			8.854,
			8.9,
			8.963,
			9.025,
			9.089,
			9.149,
			9.182,
			9.214,
			9.292,
			9.338,
			9.399,
			9.461,
			9.526,
			9.587,
			9.65,
			9.727,
			9.774,
			9.836,
			9.898,
			9.96,
			10.053,
			10.086,
			10.147,
			10.21,
			10.274,
			10.334,
			10.398,
			10.49,
			10.52,
			10.584,
			10.6,
			10.647,
			10.709,
			10.772,
			10.818,
			10.895,
			10.958,
			11.035,
			11.081,
			11.146,
			11.206,
			11.268,
			11.332,
			11.363,
			11.395,
			11.47,
			11.519,
			11.584,
			11.644,
			11.707,
			11.799,
			11.831,
			11.892,
			11.913,
			11.953,
			12.019,
			12.078,
			12.143,
			12.204,
			12.267,
			12.345,
			12.39,
			12.452,
			12.517,
			12.577,
			12.64,
			12.702,
			12.781,
			12.826,
			12.891,
			12.952,
			13.013,
			13.108,
			13.14,
			13.201,
			13.263,
			13.327,
			13.386,
			13.451,
			13.544,
			13.574,
			13.636,
			13.652,
			13.698,
			13.762,
			13.824,
			13.887,
			13.949,
			13.986,
			14.011,
			14.09,
			14.135,
			14.199,
			14.261,
			14.323,
			14.416,
			14.448,
			14.509,
			14.525,
			14.573,
			14.634,
			14.696,
			14.758,
			14.853,
			14.885,
			14.945,
			15.008,
			15.071,
			15.133,
			15.195,
			15.29,
			15.319,
			15.399,
			15.446,
			15.507,
			15.57,
			15.615,
			15.633,
			15.694,
			15.727,
			15.757,
			15.819,
			15.883,
			15.944,
			16.004,
			16.069,
			16.129,
			16.161,
			16.194,
			16.271,
			16.317,
			16.381,
			16.443,
			16.489,
			16.505,
			16.565,
			16.599,
			16.628,
			16.708,
			16.753,
			16.817,
			16.877,
			16.936,
			17.035,
			17.065,
			17.128,
			17.191,
			17.251,
			17.313,
			17.362,
			17.378,
			17.47,
			17.501,
			17.579,
			17.627,
			17.69,
			17.751,
			17.799,
			17.875,
			17.906,
			17.936,
			18.015,
			18.062,
			18.13,
			18.185,
			18.248,
			18.311,
			18.341,
			18.373,
			18.436,
			18.452,
			18.498,
			18.561,
			18.624,
			18.686,
			18.748,
			18.809,
			18.89,
			18.934,
			18.996,
			19.059,
			19.124,
			19.217,
			19.248,
			19.325,
			19.371,
			19.433,
			19.495,
			19.559,
			19.653,
			19.681,
			19.744,
			19.808,
			19.87,
			19.931,
			19.977,
			19.993,
			20.086,
			20.119,
			20.201,
			20.242,
			20.303,
			20.367,
			20.431,
			20.491,
			20.555,
			20.632,
			20.68,
			20.742,
			20.803,
			20.866,
			20.958,
			20.99,
			21.068,
			21.114,
			21.178,
			21.241,
			21.303,
			21.396,
			21.427,
			21.49,
			21.503,
			21.552,
			21.613,
			21.676,
			21.74,
			21.8,
			21.832,
			21.862,
			21.926,
			21.987,
			22.05,
			22.112,
			22.174,
			22.238,
			22.274,
			22.3,
			22.361,
			22.379,
			22.424,
			22.487,
			22.55,
			22.611,
			22.674,
			22.706,
			22.735,
			22.799,
			22.861,
			22.924,
			22.985,
			23.047,
			23.11,
			23.141,
			23.17,
			23.234,
			23.249,
			23.296,
			23.359,
			23.422,
			23.485,
			23.576,
			23.608,
			23.669,
			23.733,
			23.794,
			23.857,
			23.918,
			23.982,
			24.011,
			24.044,
			24.12,
			24.168,
			24.23,
			24.294,
			24.345,
			24.418,
			24.448,
			24.479,
			24.558,
			24.604,
			24.668,
			24.728,
			24.792,
			24.854,
			24.886,
			24.916,
			24.994,
			25.041,
			25.103,
			25.165,
			25.226,
			25.32,
			25.351,
			25.429,
			25.476,
			25.539,
			25.6,
			25.662,
			25.726,
			25.789,
			25.867,
			25.913,
			25.975,
			26.036,
			26.098,
			26.192,
			26.225,
			26.301,
			26.347,
			26.418,
			26.474,
			26.534,
			26.629,
			26.661,
			26.723,
			26.786,
			26.846,
			26.909,
			26.97,
			27.034,
			27.064,
			27.095,
			27.159,
			27.175,
			27.222,
			27.284,
			27.344,
			27.409,
			27.501,
			27.534,
			27.611,
			27.658,
			27.72,
			27.783,
			27.829,
			27.846,
			27.908,
			27.969,
			28.046,
			28.093,
			28.158,
			28.219,
			28.281,
			28.344,
			28.373,
			28.406,
			28.488,
			28.531,
			28.593,
			28.655,
			28.701,
			28.779,
			28.809,
			28.843,
			28.92,
			28.965,
			29.028,
			29.09,
			29.139,
			29.154,
			29.217,
			29.247,
			29.277,
			29.34,
			29.357,
			29.403,
			29.467,
			29.527,
			29.59,
			29.652,
			29.716,
			29.792,
			29.84,
			29.903,
			29.964,
			30.026,
			30.087,
			30.15,
			30.23,
			30.275,
			30.337,
			30.402,
			30.446,
			30.525,
			30.589,
			30.664,
			30.712,
			30.776,
			30.837,
			30.9,
			30.962,
			31.024,
			31.101,
			31.147,
			31.21,
			31.273,
			31.319,
			31.399,
			31.46,
			31.539,
			31.584,
			31.651,
			31.708,
			31.772,
			31.865,
			31.896,
			31.957,
			31.974,
			32.02,
			32.081,
			32.145,
			32.207,
			32.27,
			32.301,
			32.331,
			32.393,
			32.457,
			32.519,
			32.581,
			32.643,
			32.736,
			32.769,
			32.846,
			32.892,
			32.953,
			33.017,
			33.078,
			33.173,
			33.202,
			33.281,
			33.327,
			33.39,
			33.453,
			33.498,
			33.577,
			33.607,
			33.637,
			33.717,
			33.762,
			33.826,
			33.887,
			33.935,
			33.95,
			34.011,
			34.043,
			34.074,
			34.152,
			34.197,
			34.262,
			34.323,
			34.37,
			34.447,
			34.511,
			34.59,
			34.636,
			34.702,
			34.761,
			34.808,
			34.821,
			34.884,
			34.947,
			35.024,
			35.07,
			35.135,
			35.196,
			35.242,
			35.258,
			35.322,
			35.383,
			35.46,
			35.507,
			35.569,
			35.632,
			35.678,
			35.695,
			35.758,
			35.786,
			35.82,
			35.896,
			35.944,
			36.007,
			36.07,
			36.115,
			36.13,
			36.193,
			36.225,
			36.254,
			36.333,
			36.382,
			36.444,
			36.506,
			36.568,
			36.629,
			36.661,
			36.691,
			36.754,
			36.817,
			36.881,
			36.941,
			36.989,
			37.004,
			37.066,
			37.097,
			37.127,
			37.191,
			37.206,
			37.253,
			37.315,
			37.376,
			37.441,
			37.534,
			37.564,
			37.643,
			37.688,
			37.751,
			37.814,
			37.875,
			37.934,
			37.969,
			38,
			38.062,
			38.079,
			38.126,
			38.187,
			38.251,
			38.313,
			38.376,
			38.406,
			38.439,
			38.515,
			38.561,
			38.625,
			38.687,
			38.749,
			38.811,
			38.875,
			38.934,
			38.954,
			39,
			39.061,
			39.124,
			39.187,
			39.279,
			39.311,
			39.387,
			39.436,
			39.497,
			39.56,
			39.623,
			39.717,
			39.748,
			39.825,
			39.872,
			39.933,
			39.995,
			40.06,
			40.152,
			40.182,
			40.245,
			40.308,
			40.369,
			40.434,
			40.494,
			40.589,
			40.619,
			40.683,
			40.698,
			40.745,
			40.807,
			40.869,
			40.93,
			40.993,
			41.024,
			41.056,
			41.12,
			41.18,
			41.245,
			41.304,
			41.352,
			41.43,
			41.494,
			41.572,
			41.617,
			41.679,
			41.743,
			41.79,
			41.805,
			41.867,
			41.897,
			41.928,
			42.007,
			42.053,
			42.114,
			42.177,
			42.226,
			42.303,
			42.333,
			42.365,
			42.443,
			42.49,
			42.557,
			42.614,
			42.675,
			42.739,
			42.769,
			42.801,
			42.878,
			42.925,
			42.991,
			43.052,
			43.112,
			43.175,
			43.206,
			43.237,
			43.314,
			43.36,
			43.426,
			43.486,
			43.532,
			43.549,
			43.611,
			43.643,
			43.674,
			43.736,
			43.751,
			43.797,
			43.861,
			43.923,
			43.983,
			44.046,
			44.079,
			44.109,
			44.186,
			44.233,
			44.298,
			44.359,
			44.406,
			44.422,
			44.482,
			44.515,
			44.544,
			44.624,
			44.67,
			44.731,
			44.795,
			44.841,
			44.858,
			44.92,
			44.951,
			44.981,
			45.044,
			45.107,
			45.17,
			45.231,
			45.293,
			45.354,
			45.386,
			45.418,
			45.497,
			45.543,
			45.603,
			45.667,
			45.712,
			45.73,
			45.791,
			45.824,
			45.854,
			45.931,
			45.978,
			46.041,
			46.102,
			46.15,
			46.226,
			46.29,
			46.367,
			46.413,
			46.478,
			46.539,
			46.587,
			46.603,
			46.664,
			46.726,
			46.804,
			46.851,
			46.912,
			46.975,
			47.024,
			47.102,
			47.164,
			47.241,
			47.287,
			47.351,
			47.413,
			47.475,
			47.568,
			47.6,
			47.661,
			47.676,
			47.725,
			47.786,
			47.896,
			47.974,
			48.004,
			48.036,
			48.098,
			48.161,
			48.226,
			48.285,
			48.332,
			48.346,
			48.409,
			48.442,
			48.473,
			48.55,
			48.596,
			48.659,
			48.72,
			48.768,
			48.847,
			48.877,
			48.908,
			48.972,
			49.032,
			49.096,
			49.159,
			49.22,
			49.282,
			49.315,
			49.346,
			49.423,
			49.47,
			49.533,
			49.594,
			49.658,
			49.72,
			49.782,
			49.859,
			49.907,
			49.968,
			50.029,
			50.092,
			50.186,
			50.218,
			50.279,
			50.295,
			50.343,
			50.403,
			50.467,
			50.529,
			50.624,
			50.655,
			50.717,
			50.731,
			50.778,
			50.841,
			50.902,
			50.965,
			51.029,
			51.059,
			51.091,
			51.169,
			51.214,
			51.281,
			51.34,
			51.401,
			51.463,
			51.528,
			51.604,
			51.651,
			51.715,
			51.777,
			51.84,
			51.931,
			51.962,
			52.042,
			52.088,
			52.148,
			52.212,
			52.275,
			52.336,
			52.368,
			52.398,
			52.462,
			52.477,
			52.525,
			52.587,
			52.648,
			52.711,
			52.805,
			52.835,
			52.913,
			52.957,
			53.022,
			53.084,
			53.145,
			53.241,
			53.27,
			53.334,
			53.354,
			53.397,
			53.459,
			53.521,
			53.582,
			53.677,
			53.708,
			53.786,
			53.831,
			53.894,
			53.956,
			54.018,
			54.112,
			54.144,
			54.206,
			54.221,
			54.268,
			54.33,
			54.392,
			54.454,
			54.518,
			54.58,
			54.658,
			54.703,
			54.768,
			54.829,
			54.891,
			54.953,
			55.015,
			55.093,
			55.141,
			55.202,
			55.265,
			55.311,
			55.327,
			55.39,
			55.426,
			55.452,
			55.514,
			55.576,
			55.64,
			55.701,
			55.763,
			55.825,
			55.857,
			55.888,
			55.965,
			56.012,
			56.075,
			56.135,
			56.198,
			56.261,
			56.292,
			56.324,
			56.385,
			56.401,
			56.447,
			56.51,
			56.574,
			56.634,
			56.698,
			56.729,
			56.759,
			56.822,
			56.885,
			56.947,
			57.008,
			57.072,
			57.134,
			57.197,
			57.274,
			57.321,
			57.382,
			57.446,
			57.508,
			57.57,
			57.601,
			57.633,
			57.71,
			57.758,
			57.82,
			57.881,
			57.945,
			58.006,
			58.068,
			58.131,
			58.146,
			58.192,
			58.254,
			58.318,
			58.378,
			58.473,
			58.505,
			58.566,
			58.582,
			58.629,
			58.693,
			58.753,
			58.817,
			58.879,
			58.909,
			58.939,
			59.003,
			59.065,
			59.127,
			59.19,
			59.254,
			59.315,
			59.346,
			59.378,
			59.44,
			59.456,
			59.503,
			59.569,
			59.628,
			59.675,
			59.752,
			59.784,
			59.816,
			59.894,
			59.933,
			60.003,
			60.065,
			60.125,
			60.19,
			60.22,
			60.252,
			60.314,
			60.33,
			60.376,
			60.437,
			60.5,
			60.546,
			60.624,
			60.657,
			60.687,
			60.765,
			60.811,
			60.876,
			60.934,
			60.999,
			61.093,
			61.125,
			61.201,
			61.249,
			61.312,
			61.374,
			61.436,
			61.497,
			61.561,
			61.645,
			61.686,
			61.748,
			61.811,
			61.874,
			61.966,
			61.997,
			62.075,
			62.12,
			62.184,
			62.245,
			62.292,
			62.309,
			62.371,
			62.433,
			62.511,
			62.557,
			62.621,
			62.682,
			62.743,
			62.807,
			62.869,
			62.947,
			62.992,
			63.053,
			63.116,
			63.179,
			63.242,
			63.303,
			63.383,
			63.428,
			63.493,
			63.552,
			63.601,
			63.713,
			63.742,
			63.804,
			63.82,
			63.867,
			63.932,
			63.99,
			64.036,
			64.051,
			64.115,
			64.146,
			64.177,
			64.24,
			64.256,
			64.302,
			64.365,
			64.426,
			64.473,
			64.551,
			64.615,
			64.676,
			64.737,
			64.801,
			64.863,
			64.926,
			64.987,
			65.02,
			65.051,
			65.112,
			65.127,
			65.175,
			65.236,
			65.299,
			65.361,
			65.424,
			65.456,
			65.486,
			65.565,
			65.613,
			65.673,
			65.735,
			65.798,
			65.861,
			65.891,
			65.921,
			66.001,
			66.047,
			66.112,
			66.174,
			66.234,
			66.334,
			66.359,
			66.421,
			66.485,
			66.547,
			66.608,
			66.671,
			66.764,
			66.797,
			66.859,
			66.875,
			66.921,
			66.983,
			67.046,
			67.107,
			67.199,
			67.231,
			67.294,
			67.358,
			67.419,
			67.482,
			67.542,
			67.636,
			67.667,
			67.731,
			67.745,
			67.793,
			67.857,
			67.918,
			67.98,
			68.041,
			68.073,
			68.103,
			68.183,
			68.229,
			68.292,
			68.354,
			68.62,
			68.667,
			68.729,
			68.791,
			68.854,
			68.914,
			68.948,
			68.976,
			69.056,
			69.102,
			69.165,
			69.227,
			69.29,
			69.352,
			69.413,
			69.491,
			69.539,
			69.602,
			69.663,
			69.725,
			69.786,
			69.851,
			69.913,
			69.972,
			70.035,
			70.1,
			70.161,
			70.224,
			70.256,
			70.285,
			70.363,
			70.41,
			70.474,
			70.535,
			70.596,
			70.692,
			70.721,
			70.801,
			70.846,
			70.911,
			70.97,
			71.019,
			71.095,
			71.127,
			71.158,
			71.236,
			71.283,
			71.345,
			71.408,
			71.454,
			71.469,
			71.533,
			71.596,
			71.658,
			71.72,
			71.782,
			71.844,
			71.891,
			71.906,
			71.967,
			72.004,
			72.031,
			72.108,
			72.156,
			72.218,
			72.279,
			72.328,
			72.406,
			72.437,
			72.466,
			72.53,
			72.545,
			72.59,
			72.657,
			72.715,
			72.776,
			72.873,
			72.904,
			72.965,
			72.981,
			73.028,
			73.09,
			73.152,
			73.213,
			73.276,
			73.308,
			73.34,
			73.417,
			73.465,
			73.527,
			73.589,
			73.635,
			73.651,
			73.712,
			73.776,
			73.853,
			73.901,
			73.962,
			74.023,
			74.087,
			74.147,
			74.18,
			74.21,
			74.289,
			74.336,
			74.398,
			74.46,
			74.523,
			74.586,
			74.615,
			74.647,
			74.726,
			74.773,
			74.834,
			74.896,
			74.934,
			74.959,
			75.02,
			75.084,
			75.16,
			75.208,
			75.272,
			75.332,
			75.395,
			75.459,
			75.522,
			75.598,
			75.646,
			75.708,
			75.769,
			75.817,
			75.832,
			75.894,
			75.924,
			75.957,
			76.033,
			76.081,
			76.146,
			76.206,
			76.251,
			76.329,
			76.393,
			76.469,
			76.517,
			76.579,
			76.642,
			76.704,
			76.767,
			76.828,
			76.907,
			76.952,
			77.015,
			77.078,
			77.14,
			77.203,
			77.232,
			77.264,
			77.342,
			77.386,
			77.452,
			77.514,
			77.558,
			77.575,
			77.636,
			77.668,
			77.701,
			77.764,
			77.779,
			77.826,
			77.888,
			77.948,
			77.996,
			78.072,
			78.104,
			78.136,
			78.219,
			78.261,
			78.323,
			78.386,
			78.446,
			78.511,
			78.54,
			78.573,
			78.652,
			78.696,
			78.761,
			78.82,
			78.885,
			78.978,
			79.009,
			79.069,
			79.133,
			79.196,
			79.257,
			79.305,
			79.319,
			79.413,
			79.444,
			79.524,
			79.569,
			79.632,
			79.693,
			79.741,
			79.757,
			79.819,
			79.851,
			79.881,
			79.96,
			80.003,
			80.067,
			80.128,
			80.176,
			80.193,
			80.255,
			80.315,
			80.396,
			80.442,
			80.504,
			80.567,
			80.628,
			80.723,
			80.754,
			80.814,
			80.832,
			80.877,
			80.935,
			81.001,
			81.064,
			81.126,
			81.19,
			81.268,
			81.313,
			81.376,
			81.439,
			81.485,
			81.501,
			81.564,
			81.593,
			81.624,
			81.703,
			81.749,
			81.811,
			81.874,
			81.935,
			82.03,
			82.061,
			82.124,
			82.186,
			82.246,
			82.311,
			82.373,
			82.436,
			82.497,
			82.575,
			82.62,
			82.685,
			82.744,
			82.808,
			82.87,
			82.932,
			83.01,
			83.057,
			83.12,
			83.18,
			83.244,
			83.338,
			83.367,
			83.429,
			83.445,
			83.493,
			83.557,
			83.619,
			83.681,
			83.775,
			83.803,
			83.867,
			83.883,
			83.93,
			83.992,
			84.055,
			84.117,
			84.179,
			84.21,
			84.243,
			84.319,
			84.366,
			84.429,
			84.491,
			84.537,
			84.554,
			84.617,
			84.678,
			84.756,
			84.803,
			84.867,
			84.928,
			84.991,
			85.052,
			85.084,
			85.113,
			85.193,
			85.239,
			85.303,
			85.364,
			85.411,
			85.426,
			85.489,
			85.551,
			85.629,
			85.675,
			85.737,
			85.8,
			85.847,
			85.925,
			85.986,
			86.065,
			86.111,
			86.176,
			86.236,
			86.298,
			86.361,
			86.39,
			86.423,
			86.485,
			86.504,
			86.548,
			86.611,
			86.673,
			86.718,
			86.797,
			86.859,
			86.923,
			86.984,
			87.045,
			87.108,
			87.156,
			87.172,
			87.234,
			87.263,
			87.295,
			87.374,
			87.421,
			87.483,
			87.545,
			87.608,
			87.67,
			87.732,
			87.811,
			87.857,
			87.919,
			87.981,
			88.027,
			88.044,
			88.106,
			88.167,
			88.245,
			88.293,
			88.353,
			88.417,
			88.465,
			88.479,
			88.542,
			88.606,
			88.681,
			88.728,
			88.792,
			88.853,
			88.915,
			88.977,
			89.01,
			89.04,
			89.104,
			89.118,
			89.165,
			89.229,
			89.29,
			89.336,
			89.351,
			89.415,
			89.477,
			89.555,
			89.601,
			89.665,
			89.726,
			89.774,
			89.851,
			89.883,
			89.914,
			89.976,
			90.036,
			90.102,
			90.163,
			90.21,
			90.225,
			90.286,
			90.318,
			90.35,
			90.427,
			90.475,
			90.536,
			90.597,
			90.661,
			90.724,
			90.753,
			90.785,
			90.848,
			90.911,
			90.971,
			91.035,
			91.095,
			91.157,
			91.191,
			91.22,
			91.284,
			91.3,
			91.344,
			91.408,
			91.472,
			91.517,
			91.532,
			91.595,
			91.627,
			91.658,
			91.719,
			91.781,
			91.845,
			91.908,
			91.951,
			91.97,
			92.03,
			92.093,
			92.17,
			92.219,
			92.281,
			92.344,
			92.406,
			92.467,
			92.498,
			92.531,
			92.609,
			92.655,
			92.721,
			92.778,
			92.841,
			92.934,
			92.965,
			93.028,
			93.091,
			93.153,
			93.215,
			93.278,
			93.37,
			93.403,
			93.48,
			93.526,
			93.59,
			93.652,
			93.714,
			93.807,
			93.837,
			93.917,
			93.963,
			94.025,
			94.087,
			94.151,
			94.245,
			94.275,
			94.353,
			94.4,
			94.462,
			94.523,
			94.585,
			94.679,
			94.71,
			94.771,
			94.794,
			94.837,
			94.897,
			94.96,
			95.022,
			95.116,
			95.146,
			95.21,
			95.225,
			95.272,
			95.334,
			95.396,
			95.442,
			95.459,
			95.521,
			95.55,
			95.583,
			95.644,
			95.708,
			95.771,
			95.832,
			95.879,
			95.896,
			95.957,
			95.986,
			96.018,
			96.095,
			96.144,
			96.204,
			96.267,
			96.316,
			96.33,
			96.393,
			96.425,
			96.455,
			96.518,
			96.58,
			96.643,
			96.704,
			96.768,
			96.829,
			96.867,
			96.892,
			96.955,
			96.969,
			97.017,
			97.08,
			97.14,
			97.188,
			97.204,
			97.268,
			97.328,
			97.408,
			97.453,
			97.518,
			97.577,
			97.642,
			97.735,
			97.766,
			97.828,
			97.843,
			97.89,
			97.956,
			98.016,
			98.078,
			98.172,
			98.203,
			98.263,
			98.279,
			98.328,
			98.391,
			98.451,
			98.513,
			98.608,
			98.639,
			98.701,
			98.764,
			98.826,
			98.889,
			98.949,
			99.042,
			99.075,
			99.137,
			99.153,
			99.198,
			99.261,
			99.324,
			99.386,
			99.479,
			99.511,
			99.574,
			99.637,
			99.697,
			99.76,
			99.823,
			99.917,
			99.947,
			100.023,
			100.071,
			100.134,
			100.195,
			100.259,
			100.35,
			100.382,
			100.446,
			100.507,
			100.57,
			100.631,
			100.693,
			100.757,
			100.82,
			100.898,
			100.944,
			101.009,
			101.068,
			101.076
		]
	],
	'8703_8704_7982': [
		[
			123.446
		],
		[
			0
		],
		[
			0,
			2.949,
			7.639,
			12.335,
			15.472,
			20.191,
			23.349,
			26.516,
			31.282,
			34.465,
			39.242,
			40.836,
			42.432,
			48.825,
			50.427,
			55.237,
			58.451,
			61.67,
			63.298,
			66.556,
			69.822,
			71.457,
			76.31,
			79.542,
			82.778,
			87.645,
			90.897,
			95.783,
			99.043,
			103.934,
			107.186,
			112.028,
			115.218,
			119.935,
			126.093,
			127.61,
			132.104,
			135.054,
			137.964,
			142.257,
			145.072,
			149.222,
			150.586,
			151.941,
			155.948,
			158.572,
			162.438,
			164.967,
			167.458,
			168.689,
			171.124,
			174.711,
			178.22,
			180.514,
			182.77,
			186.085,
			188.249,
			192.491,
			193.529,
			196.59,
			198.579,
			201.478,
			203.363,
			205.207,
			206.116,
			207.909,
			208.793,
			210.536,
			212.256,
			213.096,
			214.748,
			216.367,
			218.74,
			219.515,
			220.284,
			222.56,
			223.31,
			224.047,
			226.232,
			227.675,
			229.797,
			231.193,
			232.587,
			233.284,
			234.649,
			235.329,
			236.688,
			238.046,
			238.727,
			239.408,
			241.45,
			243.495,
			244.226,
			244.957,
			247.155,
			248.645,
			251.253,
			252.998,
			255.937,
			257.962,
			260.088,
			263.349,
			265.538,
			269.872,
			270.921,
			274.002,
			275.756,
			278.387,
			281.355,
			282.084,
			284.271,
			285.721,
			287.171,
			289.369,
			290.863,
			293.103,
			293.853,
			294.615,
			297.657,
			298.416,
			300.722,
			302.261,
			303.8,
			304.575,
			306.124,
			308.452,
			310.811,
			312.395,
			313.993,
			316.424,
			318.061,
			321.378,
			322.215,
			324.731,
			326.433,
			329.009,
			330.746,
			333.372,
			336.021,
			337.803,
			339.597,
			340.498,
			342.311,
			344.137,
			346.906,
			348.768,
			352.52,
			353.466,
			356.322,
			358.241,
			361.145,
			363.096,
			366.049,
			368.033,
			371.04,
			373.064,
			374.095,
			376.14,
			378.19,
			381.276,
			383.338,
			387.472,
			388.507,
			391.612,
			393.681,
			396.785,
			398.855,
			401.978,
			405.115,
			407.249,
			409.383,
			410.47,
			412.693,
			414.915,
			418.255,
			419.367,
			420.466,
			423.632,
			424.685,
			425.727,
			428.81,
			430.877,
			434.024,
			436.137,
			438.277,
			441.493,
			443.644,
			447.912,
			448.974,
			452.149,
			454.26,
			457.469,
			461.806,
			462.899,
			466.17,
			468.334,
			470.502,
			473.782,
			475.976,
			479.268,
			480.37,
			481.463,
			484.732,
			486.92,
			490.215,
			492.429,
			495.771,
			500.262,
			501.388,
			504.757,
			507.003,
			509.237,
			512.585,
			514.808,
			519.285,
			520.41,
			523.827,
			526.123,
			529.602,
			531.935,
			535.461,
			539.016,
			541.379,
			543.774,
			544.975,
			547.382,
			549.796,
			553.435,
			555.872,
			560.77,
			561.999,
			565.692,
			568.162,
			571.878,
			574.364,
			576.857,
			578.128,
			580.632,
			584.38,
			588.164,
			590.713,
			593.226,
			597.036,
			599.578,
			603.418,
			605.973,
			609.8,
			612.371,
			614.944,
			618.825,
			621.391,
			626.484,
			627.724,
			632.713,
			633.934,
			637.54,
			639.898,
			642.226,
			643.378,
			646.398,
			649.362,
			651.315,
			653.268,
			656.209,
			658.169,
			662.087,
			663.064,
			666.958,
			667.929,
			670.823,
			672.756,
			675.659,
			678.597,
			680.599,
			683.647,
			685.741,
			687.843,
			691.111,
			693.311,
			697.829,
			698.979,
			702.448,
			704.735,
			708.16,
			710.407,
			713.788,
			716.039,
			717.167,
			719.422,
			721.679,
			722.811,
			725.053,
			727.298,
			730.656,
			732.899,
			736.317,
			737.461,
			738.61,
			742.078,
			744.396,
			747.882,
			750.193,
			753.663,
			758.237,
			759.376,
			762.802,
			765.107,
			767.422,
			770.913,
			773.251,
			777.964,
			779.152,
			781.542,
			782.741,
			785.147,
			787.563,
			791.208,
			793.649,
			798.539,
			799.766,
			804.701,
			805.923,
			809.685,
			812.189,
			815.955,
			820.979,
			822.245,
			826.052,
			828.62,
			831.174,
			835.014,
			837.559,
			841.418,
			843.997,
			849.171,
			850.467,
			854.353,
			856.948,
			860.851,
			866.068,
			867.374,
			869.992,
			871.303,
			873.931,
			876.566,
			880.532,
			883.186,
			888.517,
			889.854,
			893.881,
			896.576,
			900.624,
			903.341,
			907.358,
			912.668,
			913.97,
			916.619,
			917.945,
			920.595,
			923.241,
			927.227,
			929.867,
			933.82,
			936.451,
			940.365,
			943.001,
			946.915,
			949.518,
			953.408,
			957.291,
			959.884,
			962.498,
			963.815,
			966.447,
			969.082,
			973.071,
			975.702,
			981.026,
			982.389,
			986.491,
			989.361,
			993.69,
			996.601,
			1000.695,
			1004.604,
			1007.173,
			1009.756,
			1011.045,
			1013.601,
			1016.148,
			1019.886,
			1022.34,
			1027.283,
			1028.527,
			1032.255,
			1034.736,
			1037.183,
			1040.794,
			1043.157,
			1046.621,
			1047.757,
			1048.903,
			1053.32,
			1054.385,
			1057.582,
			1059.669,
			1061.72,
			1062.733,
			1064.73,
			1067.658,
			1070.505,
			1072.358,
			1074.18,
			1076.852,
			1078.594,
			1081.141,
			1082.795,
			1085.214,
			1086.782,
			1089.073,
			1090.57,
			1092.022,
			1092.739,
			1094.155,
			1095.552,
			1096.244,
			1098.306,
			1099.668,
			1101.026,
			1103.048,
			1104.379,
			1106.366,
			1107.687,
			1109.643,
			1110.943,
			1112.895,
			1114.198,
			1115.499,
			1116.15,
			1117.453,
			1118.115,
			1119.442,
			1121.431,
			1122.762,
			1124.11,
			1126.131,
			1127.48,
			1130.228,
			1130.915,
			1132.986,
			1134.46,
			1136.661,
			1138.151,
			1140.595,
			1142.216,
			1143.025,
			1144.639,
			1146.247,
			1147.05,
			1148.649,
			1150.239,
			1152.614,
			1153.438,
			1154.29,
			1156.837,
			1157.685,
			1158.579,
			1161.356,
			1163.217,
			1166.085,
			1167.999,
			1169.722,
			1170.579,
			1172.294,
			1173.154,
			1173.945,
			1176.319,
			1177.112,
			1177.908,
			1180.113,
			1181.567,
			1183.725,
			1185.084,
			1187.138,
			1188.52,
			1189.218,
			1190.621,
			1192.745,
			1194.186,
			1195.645,
			1197.857,
			1198.603,
			1199.362,
			1201.657,
			1203.204,
			1205.574,
			1207.173,
			1208.786,
			1209.602,
			1211.243,
			1212.898,
			1213.73,
			1215.407,
			1216.249,
			1217.931,
			1219.613,
			1222.141,
			1223.852,
			1226.444,
			1227.315,
			1228.188,
			1230.828,
			1232.604,
			1235.291,
			1237.098,
			1239.831,
			1241.668,
			1243.518,
			1244.448,
			1246.317,
			1247.256,
			1249.144,
			1251.045,
			1253.92,
			1255.853,
			1259.756,
			1260.739,
			1263.707,
			1265.701,
			1268.714,
			1270.738,
			1273.795,
			1275.846,
			1276.876,
			1278.943,
			1281.02,
			1282.061,
			1284.152,
			1286.247,
			1289.385,
			1290.433,
			1291.486,
			1294.661,
			1296.796,
			1300.027,
			1302.214,
			1305.553,
			1307.856,
			1310.202,
			1311.379,
			1313.859,
			1315.1,
			1317.547,
			1320.011,
			1321.234,
			1323.534,
			1325.795,
			1329.178,
			1337.02,
			1340.433,
			1342.723,
			1345.027,
			1348.502,
			1350.831,
			1355.516,
			1356.693,
			1360.238,
			1361.424,
			1362.614,
			1366.204,
			1368.617,
			1372.268,
			1377.137,
			1378.352,
			1380.772,
			1381.985,
			1384.412,
			1386.845,
			1390.512,
			1392.968,
			1397.902,
			1399.141,
			1402.867,
			1405.363,
			1409.123,
			1411.621,
			1415.416,
			1419.228,
			1421.776,
			1425.608,
			1428.17,
			1430.737,
			1434.592,
			1437.154,
			1440.968,
			1443.478,
			1447.173,
			1449.588,
			1453.133,
			1455.448,
			1457.723,
			1458.847,
			1461.064,
			1462.158,
			1464.317,
			1467.482,
			1469.544,
			1471.567,
			1474.53,
			1475.499,
			1476.459,
			1479.285,
			1480.209,
			1481.126,
			1483.833,
			1485.598,
			1488.221,
			1489.917,
			1491.583,
			1494.823,
			1495.612,
			1497.944,
			1498.709,
			1499.469,
			1501.721,
			1503.208,
			1505.432,
			1508.389,
			1509.128,
			1510.606,
			1511.344,
			1512.808,
			1514.271,
			1516.464,
			1517.912,
			1520.082,
			1521.53,
			1523.716,
			1525.17,
			1527.37,
			1528.846,
			1531.057,
			1532.552,
			1534.048,
			1534.795,
			1537.046,
			1538.563,
			1540.075,
			1542.359,
			1543.902,
			1546.205,
			1547.819,
			1550.276,
			1551.93,
			1554.576,
			1556.335,
			1558.141,
			1559.051,
			1560.867,
			1563.551,
			1566.208,
			1567.919,
			1569.575,
			1572.059,
			1573.636,
			1575.996,
			1577.568,
			1579.905,
			1581.464,
			1583.774,
			1585.304,
			1586.838,
			1587.597,
			1589.089,
			1589.837,
			1591.336,
			1593.556,
			1595.015,
			1596.481,
			1598.676,
			1599.405,
			1600.137,
			1602.344,
			1603.861,
			1606.163,
			1607.711,
			1610.118,
			1611.738,
			1613.375,
			1614.202,
			1615.864,
			1616.7,
			1618.378,
			1620.903,
			1622.603,
			1624.324,
			1626.933,
			1628.691,
			1631.347,
			1632.258,
			1633.157,
			1635.868,
			1637.678,
			1640.438,
			1642.293,
			1644.163,
			1646.999,
			1647.951,
			1648.904,
			1651.781,
			1652.746,
			1653.714,
			1656.623,
			1658.578,
			1660.539,
			1661.524,
			1663.503,
			1665.493,
			1666.492,
			1668.5,
			1669.508,
			1671.533,
			1673.569,
			1676.647,
			1678.713,
			1681.834,
			1682.88,
			1683.927,
			1687.068,
			1689.167,
			1692.335,
			1694.46,
			1697.667,
			1699.818,
			1701.98,
			1703.064,
			1705.24,
			1706.333,
			1708.525,
			1710.726,
			1714.048,
			1716.276,
			1720.761,
			1721.888,
			1725.285,
			1727.562,
			1730.996,
			1733.298,
			1736.769,
			1740.263,
			1742.604,
			1746.135,
			1748.501,
			1750.877,
			1754.459,
			1756.859,
			1761.687,
			1762.9,
			1766.553,
			1769.001,
			1772.691,
			1775.162,
			1778.887,
			1782.633,
			1785.142,
			1788.923,
			1791.454,
			1793.996,
			1797.824,
			1800.389,
			1802.956,
			1805.515,
			1806.788,
			1809.31,
			1810.558,
			1813.026,
			1815.455,
			1819.03,
			1821.368,
			1825.932,
			1827.051,
			1830.35,
			1831.431,
			1832.503,
			1835.666,
			1837.73,
			1839.758,
			1840.758,
			1842.732,
			1845.626,
			1848.44,
			1850.273,
			1852.074,
			1854.713,
			1856.428,
			1858.933,
			1860.56,
			1862.932,
			1864.473,
			1866.735,
			1868.217,
			1870.427,
			1871.913,
			1872.653,
			1874.135,
			1876.357,
			1877.843,
			1879.328,
			1881.54,
			1883.016,
			1885.217,
			1886.676,
			1888.864,
			1890.329,
			1892.522,
			1893.983,
			1896.224,
			1898.46,
			1899.995,
			1902.351,
			1903.922,
			1905.651,
			1908.284,
			1909.163,
			1910.046,
			1912.695,
			1914.446,
			1916.949,
			1918.615,
			1921.154,
			1922.855,
			1924.559,
			1927.127,
			1927.983,
			1928.839,
			1932.317,
			1933.19,
			1935.814,
			1937.562,
			1940.156,
			1943.486,
			1944.312,
			1945.878,
			1946.649,
			1948.178,
			1949.69,
			1951.877,
			1953.292,
			1955.399,
			1956.088,
			1956.769,
			1958.773,
			1960.073,
			1961.954,
			1963.174,
			1964.372,
			1964.954,
			1966.083,
			1967.18,
			1967.717,
			1968.769,
			1969.285,
			1970.3,
			1971.292,
			1972.745,
			1973.697,
			1975.109,
			1976.078,
			1978.004,
			1978.487,
			1979.94,
			1980.997,
			1982.608,
			1984.229,
			1985.336,
			1987.127,
			1988.324,
			1989.523,
			1991.369,
			1992.612,
			1994.471,
			1995.708,
			1997.546,
			1998.766,
			2000.601,
			2001.825,
			2003.652,
			2004.868,
			2005.479,
			2006.748,
			2008.014,
			2008.648,
			2009.917,
			2011.194,
			2013.106,
			2014.384,
			2016.278,
			2016.895,
			2017.513,
			2019.377,
			2020.622,
			2022.348,
			2023.506,
			2024.673,
			2025.261,
			2026.369,
			2028.001,
			2029.655,
			2030.772,
			2031.893,
			2033.025,
			2034.745,
			2035.911,
			2037.098,
			2037.697,
			2039.516,
			2040.748,
			2041.996,
			2043.899,
			2045.189,
			2045.841,
			2047.179,
			2048.534,
			2050.599,
			2052.011,
			2054.167,
			2055.625,
			2057.096,
			2057.837,
			2059.331,
			2061.603,
			2063.906,
			2065.462,
			2067.025,
			2069.382,
			2070.97,
			2073.341,
			2074.934,
			2077.349,
			2078.965,
			2081.39,
			2083.023,
			2085.498,
			2088.868,
			2089.722,
			2092.318,
			2094.089,
			2095.875,
			2098.593,
			2100.425,
			2103.2,
			2105.068,
			2107.89,
			2109.781,
			2112.634,
			2114.538,
			2116.456,
			2117.417,
			2119.344,
			2120.311,
			2122.257,
			2125.208,
			2127.191,
			2129.193,
			2132.218,
			2134.257,
			2137.34,
			2138.375,
			2139.412,
			2142.521,
			2144.609,
			2147.763,
			2149.886,
			2153.09,
			2155.243,
			2156.324,
			2157.407,
			2161.779,
			2163.985,
			2166.205,
			2169.554,
			2170.676,
			2171.8,
			2174.056,
			2176.323,
			2177.461,
			2180.897,
			2183.2,
			2186.68,
			2189.005,
			2191.333,
			2194.819,
			2197.135,
			2201.731,
			2202.874,
			2206.292,
			2208.563,
			2210.832,
			2211.964,
			2214.222,
			2217.611,
			2220.999,
			2223.261,
			2225.533,
			2228.957,
			2231.246,
			2234.676,
			2236.945,
			2240.335,
			2242.581,
			2245.93,
			2248.157,
			2251.49,
			2255.923,
			2257.031,
			2260.35,
			2262.558,
			2264.76,
			2268.068,
			2270.268,
			2273.559,
			2274.653,
			2275.749,
			2279.034,
			2281.213,
			2284.465,
			2286.628,
			2288.79,
			2289.871,
			2292.035,
			2295.288,
			2298.564,
			2300.754,
			2302.995,
			2306.375,
			2308.683,
			2313.315,
			2314.477,
			2317.9,
			2320.15,
			2323.455,
			2325.641,
			2328.906,
			2332.184,
			2334.385,
			2336.595,
			2337.705,
			2339.931,
			2342.167,
			2345.53,
			2347.781,
			2350.036,
			2353.429,
			2356.833,
			2359.104,
			2361.382,
			2364.803,
			2367.091,
			2370.53,
			2372.834,
			2377.482,
			2378.658,
			2382.198,
			2384.572,
			2388.15,
			2390.548,
			2392.934,
			2394.14,
			2397.77,
			2400.183,
			2402.601,
			2406.27,
			2408.694,
			2413.684,
			2414.941,
			2420,
			2421.236,
			2425.035,
			2427.558,
			2431.333,
			2436.439,
			2437.693,
			2440.27,
			2441.55,
			2444.114,
			2446.663,
			2450.553,
			2453.142,
			2457.033,
			2458.33,
			2459.627,
			2463.497,
			2466.12,
			2470.01,
			2472.624,
			2475.243,
			2476.553,
			2479.177,
			2480.492,
			2483.122,
			2487.078,
			2489.723,
			2492.373,
			2496.361,
			2499.027,
			2503.037,
			2504.377,
			2505.719,
			2509.754,
			2512.452,
			2516.512,
			2519.228,
			2521.95,
			2523.313,
			2526.044,
			2530.155,
			2534.289,
			2537.056,
			2539.833,
			2544.011,
			2546.804,
			2549.602,
			2553.811,
			2558.033,
			2560.855,
			2565.1,
			2567.938,
			2570.786,
			2576.502,
			2577.936,
			2583.69,
			2585.133,
			2589.473,
			2592.375,
			2596.739,
			2602.581,
			2604.045,
			2606.979,
			2608.447,
			2611.389,
			2614.338,
			2618.771,
			2621.734,
			2626.189,
			2629.168,
			2633.644,
			2636.635,
			2641.132,
			2644.136,
			2648.652,
			2653.18,
			2656.206,
			2660.754,
			2663.792,
			2666.835,
			2671.412,
			2674.476,
			2680.628,
			2682.17,
			2686.787,
			2689.863,
			2694.481,
			2697.565,
			2702.201,
			2705.299,
			2709.956,
			2714.623,
			2717.739,
			2720.861,
			2725.554,
			2728.69,
			2734.967,
			2736.536,
			2741.247,
			2744.385,
			2749.079,
			2752.183,
			2755.26,
			2756.787,
			2761.31,
			2764.269,
			2767.19,
			2768.635,
			2771.497,
			2774.329,
			2778.512,
			2781.258,
			2785.311,
			2786.645,
			2787.969,
			2791.891,
			2794.459,
			2798.243,
			2800.716,
			2804.357,
			2806.741,
			2809.089,
			2813.683,
			2814.808,
			2818.13,
			2820.294,
			2823.47,
			2827.545,
			2828.543,
			2830.516,
			2831.489,
			2833.408,
			2835.306,
			2838.088,
			2839.901,
			2843.435,
			2844.299,
			2846.853,
			2847.684,
			2848.506,
			2850.926,
			2852.499,
			2854.796,
			2857.751,
			2858.474,
			2859.908,
			2860.617,
			2862.021,
			2863.412,
			2865.475,
			2866.837,
			2868.872,
			2870.198,
			2872.185,
			2873.51,
			2875.517,
			2876.856,
			2878.87,
			2880.303,
			2881.023,
			2882.464,
			2883.911,
			2884.682,
			2886.248,
			2887.818,
			2890.314,
			2892.085,
			2894.822,
			2895.811,
			2896.801,
			2899.786,
			2901.772,
			2904.674,
			2906.288,
			2908.729,
			2910.266,
			2911.006,
			2912.494,
			2914.757,
			2916.31,
			2917.875,
			2920.266,
			2921.082,
			2921.901,
			2924.376,
			2925.215,
			2926.059,
			2928.608,
			2930.33,
			2932.946,
			2934.707,
			2936.487,
			2937.383,
			2939.183,
			2940.999,
			2944.67,
			2946.527,
			2948.396,
			2951.208,
			2952.156,
			2953.108,
			2955.981,
			2957.911,
			2960.827,
			2962.785,
			2964.755,
			2967.729,
			2969.727,
			2972.743,
			2973.754,
			2974.768,
			2978.849,
			2979.875,
			2982.973,
			2985.051,
			2988.188,
			2990.279,
			2992.373,
			2993.437,
			2995.529,
			2996.574,
			2998.661,
			3000.741,
			3003.852,
			3005.917,
			3010.031,
			3011.058,
			3014.14,
			3016.194,
			3019.284,
			3021.344,
			3024.486,
			3026.585,
			3028.732,
			3029.811,
			3033.069,
			3035.285,
			3037.5,
			3040.811,
			3043.003,
			3046.252,
			3047.335,
			3048.406,
			3051.613,
			3053.731,
			3056.877,
			3058.965,
			3062.085,
			3064.208,
			3065.277,
			3067.411,
			3070.693,
			3072.888,
			3075.087,
			3078.385,
			3080.551,
			3083.792,
			3084.851,
			3085.91,
			3089.073,
			3091.145,
			3094.247,
			3096.294,
			3099.373,
			3101.415,
			3102.439,
			3104.493,
			3106.556,
			3107.591,
			3109.668,
			3111.749,
			3114.887,
			3116.973,
			3119.068,
			3121.157,
			3122.201,
			3125.346,
			3127.449,
			3129.561,
			3132.784,
			3136.048,
			3139.361,
			3140.474,
			3143.86,
			3144.994,
			3147.284,
			3149.588,
			3151.892,
			3155.332,
			3157.635,
			3159.942,
			3161.098,
			3163.439,
			3164.585,
			3166.921,
			3169.265,
			3172.798,
			3175.185,
			3178.733,
			3179.947,
			3181.142,
			3185.903,
			3187.102,
			3190.71,
			3193.145,
			3195.547,
			3196.757,
			3199.175,
			3201.582,
			3202.779,
			3206.339,
			3208.678,
			3210.981,
			3214.371,
			3216.587,
			3219.854,
			3221.998,
			3225.143,
			3226.175,
			3227.199,
			3230.213,
			3232.174,
			3235.044,
			3236.91,
			3238.741,
			3239.647,
			3241.437,
			3242.319,
			3244.059,
			3246.607,
			3248.261,
			3249.88,
			3252.242,
			3253.772,
			3256.736,
			3257.46,
			3259.605,
			3261.031,
			3263.133,
			3264.527,
			3265.914,
			3266.604,
			3267.982,
			3268.67,
			3270.038,
			3272.061,
			3273.406,
			3274.749,
			3276.711,
			3278.017,
			3279.978,
			3281.286,
			3283.246,
			3284.553,
			3286.61,
			3287.993,
			3289.376,
			3290.075,
			3291.559,
			3293.781,
			3296.079,
			3297.693,
			3299.308,
			3301.859,
			3302.734,
			3303.61,
			3306.269,
			3308.078,
			3310.772,
			3312.433,
			3314.935,
			3316.483,
			3317.963,
			3320.94,
			3321.672,
			3324.617,
			3325.36,
			3327.635,
			3329.168,
			3331.491,
			3334.635,
			3335.425,
			3337.802,
			3339.39,
			3340.978,
			3343.339,
			3344.903,
			3347.205,
			3348.716,
			3350.926,
			3352.363,
			3354.467,
			3355.823,
			3357.162,
			3357.825,
			3359.134,
			3361.055,
			3362.942,
			3364.179,
			3365.403,
			3367.223,
			3368.422,
			3370.214,
			3371.408,
			3373.172,
			3374.338,
			3376.089,
			3377.252,
			3378.957,
			3380.095,
			3381.81,
			3383.62,
			3384.842,
			3386.071,
			3388.063,
			3389.516,
			3392.501,
			3393.384,
			3396.041,
			3397.908,
			3400.957,
			3402.952,
			3405.832,
			3407.666,
			3409.995,
			3412.332,
			3413.639,
			3414.946,
			3416.92,
			3418.229,
			3420.184,
			3421.495,
			3423.489,
			3424.837,
			3426.872,
			3428.248,
			3429.632,
			3431.75,
			3433.933,
			3436.152,
			3436.9,
			3439.185,
			3440.733,
			3442.298,
			3443.088,
			3444.688,
			3447.116,
			3449.585,
			3451.25,
			3452.93,
			3455.464,
			3457.153,
			3459.711,
			3460.556,
			3461.417,
			3464.892,
			3465.769,
			3468.432,
			3470.192,
			3472.842,
			3476.41,
			3477.306,
			3479.101,
			3480.011,
			3481.833,
			3483.658,
			3486.393,
			3488.249,
			3491.944,
			3492.879,
			3495.701,
			3497.609,
			3500.454,
			3502.379,
			3504.317,
			3505.291,
			3507.266,
			3509.252,
			3510.249,
			3513.262,
			3515.286,
			3517.297,
			3520.33,
			3522.351,
			3525.402,
			3526.426,
			3527.453,
			3530.553,
			3532.642,
			3535.784,
			3537.886,
			3540,
			3541.061,
			3543.191,
			3545.332,
			3546.406,
			3548.562,
			3549.644,
			3551.816,
			3553.997,
			3557.289,
			3559.499,
			3562.832,
			3563.949,
			3565.068,
			3568.442,
			3570.704,
			3574.116,
			3576.402,
			3578.699,
			3579.853,
			3582.166,
			3585.658,
			3589.174,
			3591.53,
			3593.896,
			3597.457,
			3598.648,
			3599.843,
			3603.436,
			3604.638,
			3605.844,
			3609.476,
			3611.91,
			3615.583,
			3618.044,
			3620.514,
			3621.753,
			3625.483,
			3627.981,
			3631.746,
			3634.266,
			3636.796,
			3639.334,
			3641.881,
			3643.158,
			3645.719,
			3648.288,
			3649.576,
			3653.452,
			3656.047,
			3658.65,
			3662.556,
			3665.167,
			3669.099,
			3671.73,
			3677.016,
			3678.342,
			3682.332,
			3685.002,
			3687.68,
			3689.022,
			3691.712,
			3694.409,
			3695.76,
			3699.825,
			3702.545,
			3705.272,
			3709.377,
			3712.124,
			3716.258,
			3717.639,
			3719.023,
			3723.187,
			3725.971,
			3730.163,
			3732.966,
			3735.777,
			3737.185,
			3740.006,
			3744.252,
			3748.514,
			3751.364,
			3754.222,
			3758.523,
			3761.399,
			3765.727,
			3767.173,
			3768.621,
			3772.976,
			3775.888,
			3780.269,
			3783.198,
			3786.134,
			3787.604,
			3790.551,
			3792.027,
			3794.984,
			3799.432,
			3802.406,
			3805.387,
			3809.87,
			3811.368,
			3812.867,
			3817.375,
			3820.39,
			3824.923,
			3827.952,
			3832.508,
			3835.552,
			3838.602,
			3840.129,
			3843.188,
			3844.719,
			3847.787,
			3850.86,
			3852.399,
			3855.482,
			3858.57,
			3863.216,
			3864.768,
			3866.322,
			3870.991,
			3872.551,
			3874.113,
			3878.807,
			3881.943,
			3886.659,
			3889.811,
			3892.968,
			3897.714,
			3900.886,
			3907.245,
			3908.838,
			3913.616,
			3916.808,
			3920.005,
			3921.605,
			3924.809,
			3928.019,
			3929.625,
			3934.454,
			3937.678,
			3940.908,
			3945.762,
			3949.004,
			3953.875,
			3955.501,
			3957.128,
			3962.017,
			3963.649,
			3965.283,
			3970.191,
			3973.469,
			3978.395,
			3981.685,
			3984.98,
			3986.629,
			3989.931,
			3991.584,
			3994.892,
			3998.205,
			4006.51,
			4011.508,
			4013.176,
			4014.846,
			4019.861,
			4021.536,
			4023.212,
			4028.249,
			4031.614,
			4036.668,
			4040.042,
			4043.419,
			4045.109,
			4050.185,
			4053.575,
			4056.969,
			4062.068,
			4065.474,
			4070.59,
			4072.298,
			4074.007,
			4079.141,
			4082.569,
			4087.72,
			4091.159,
			4096.326,
			4099.776,
			4101.502,
			4104.958,
			4110.145,
			4113.607,
			4117.074,
			4122.279,
			4124.016,
			4125.754,
			4130.978,
			4132.722,
			4134.468,
			4139.71,
			4143.212,
			4148.473,
			4151.984,
			4155.5,
			4157.259,
			4160.779,
			4166.068,
			4171.367,
			4174.903,
			4178.443,
			4183.761,
			4187.311,
			4192.642,
			4194.421,
			4196.201,
			4201.546,
			4205.115,
			4210.478,
			4214.059,
			4217.644,
			4224.819,
			4226.613,
			4232.003,
			4233.802,
			4235.601,
			4241.002,
			4244.607,
			4250.02,
			4253.632,
			4257.249,
			4259.058,
			4262.678,
			4264.488,
			4268.112,
			4271.738,
			4277.18,
			4280.812,
			4286.266,
			4288.085,
			4289.905,
			4295.372,
			4299.021,
			4304.5,
			4308.157,
			4311.817,
			4313.647,
			4317.311,
			4320.977,
			4322.811,
			4328.317,
			4331.992,
			4335.669,
			4341.191,
			4344.875,
			4350.405,
			4352.251,
			4354.097,
			4359.639,
			4363.336,
			4368.888,
			4372.592,
			4378.154,
			4381.864,
			4383.721,
			4387.437,
			4393.016,
			4396.737,
			4400.462,
			4406.053,
			4409.783,
			4415.382,
			4417.25,
			4419.118,
			4424.727,
			4428.468,
			4434.071,
			4437.783,
			4443.297,
			4446.923,
			4448.72,
			4452.287,
			4457.562,
			4461.028,
			4464.454,
			4469.519,
			4472.845,
			4479.381,
			4480.99,
			4485.759,
			4488.891,
			4493.515,
			4496.551,
			4501.037,
			4503.98,
			4508.324,
			4512.579,
			4515.366,
			4518.115,
			4520.825,
			4524.819,
			4530.009,
			4531.282,
			4535.045,
			4537.507,
			4541.129,
			4543.499,
			4546.989,
			4550.395,
			4552.616,
			4554.8,
			4555.878,
			4558.005,
			4560.094,
			4562.146,
			4565.152,
			4569.025,
			4569.971,
			4571.833,
			4572.751,
			4574.562,
			4576.337,
			4578.935,
			4580.62,
			4583.877,
			4584.668,
			4587.739,
			4588.483,
			4590.663,
			4592.069,
			4593.44,
			4594.113,
			4596.722,
			4597.354,
			4598.591,
			4599.196,
			4600.379,
			4601.529,
			4603.185,
			4604.245,
			4605.768,
			4606.258,
			4606.74,
			4608.135,
			4609.022,
			4610.304,
			4611.123,
			4612.315,
			4613.083,
			4614.191,
			4615.275,
			4615.988,
			4616.694,
			4617.718,
			4618.391,
			4619.391,
			4620.055,
			4621.057,
			4621.729,
			4622.74,
			4623.409,
			4624.075,
			4624.408,
			4625.076,
			4625.412,
			4626.088,
			4627.148,
			4627.91,
			4628.668,
			4629.803,
			4630.181,
			4630.561,
			4631.704,
			4632.697,
			4634.308,
			4635.375,
			4636.973,
			4638.583,
			4640.245,
			4642.608,
			4643.278,
			4644.606,
			4646.573,
			4647.222,
			4649.096,
			4650.328,
			4652.167,
			4654.656,
			4655.272,
			4657.115,
			4658.017,
			4658.852,
			4660.097,
			4660.926,
			4662.178,
			4662.562,
			4662.913,
			4663.966,
			4664.671,
			4665.738,
			4666.458,
			4667.556,
			4669.076,
			4669.462,
			4670.632,
			4671.426,
			4672.232,
			4673.514,
			4674.397,
			4675.748,
			4676.667,
			4678.104,
			4679.121,
			4680.68,
			4681.743,
			4683.381,
			4685.068,
			4686.21,
			4687.95,
			4689.134,
			4690.33,
			4692.147,
			4693.372,
			4695.229,
			4695.854,
			4696.483,
			4698.373,
			4699.617,
			4701.523,
			4702.81,
			4704.116,
			4704.774,
			4706.103,
			4706.773,
			4708.127,
			4710.199,
			4711.613,
			4713.032,
			4715.209,
			4715.943,
			4716.682,
			4718.17,
			4719.685,
			4720.448,
			4722.762,
			4724.336,
			4726.743,
			4728.363,
			4730.029,
			4732.558,
			4733.407,
			4734.274,
			4736.923,
			4737.81,
			4738.699,
			4741.489,
			4743.36,
			4746.274,
			4748.236,
			4750.201,
			4751.183,
			4753.146,
			4754.123,
			4755.839,
			4757.553,
			4760.062,
			4761.672,
			4764.087,
			4764.901,
			4765.716,
			4768.162,
			4768.978,
			4769.799,
			4772.27,
			4773.921,
			4776.421,
			4778.081,
			4779.77,
			4780.629,
			4782.357,
			4783.227,
			4784.993,
			4786.768,
			4789.455,
			4791.269,
			4794.014,
			4794.936,
			4795.862,
			4798.659,
			4800.539,
			4803.382,
			4805.292,
			4807.214,
			4808.179,
			4810.117,
			4811.087,
			4813.026,
			4815.936,
			4817.881,
			4819.837,
			4822.795,
			4824.781,
			4828.817,
			4829.838,
			4832.918,
			4834.988,
			4838.104,
			4840.187,
			4842.269,
			4843.307,
			4846.433,
			4847.48,
			4850.641,
			4859.202,
			4861.366,
			4863.539,
			4865.725,
			4866.821,
			4870.124,
			4872.337,
			4875.675,
			4877.91,
			4881.279,
			4884.666,
			4885.798,
			4889.207,
			4890.347,
			4892.631,
			4894.923,
			4897.221,
			4899.524,
			4900.679,
			4903.006,
			4906.517,
			4910.093,
			4912.511,
			4914.977,
			4918.757,
			4921.339,
			4925.135,
			4927.639,
			4931.278,
			4933.703,
			4937.335,
			4939.816,
			4943.571,
			4946.131,
			4949.969,
			4953.836,
			4956.413,
			4958.929,
			4962.559,
			4964.899,
			4968.347,
			4969.495,
			4970.645,
			4974.109,
			4976.436,
			4979.954,
			4982.342,
			4985.915,
			4989.537,
			4991.958,
			4994.388,
			4995.605,
			4998.046,
			5000.499,
			5004.194,
			5006.672,
			5011.65,
			5012.9,
			5016.661,
			5019.177,
			5022.969,
			5025.508,
			5028.059,
			5031.899,
			5033.184,
			5035.758,
			5038.34,
			5039.634,
			5040.929,
			5044.816,
			5047.406,
			5051.298,
			5056.524,
			5057.835,
			5061.779,
			5064.417,
			5067.083,
			5071.039,
			5073.723,
			5077.725,
			5079.061,
			5080.376,
			5085.765,
			5087.088,
			5091.136,
			5093.843,
			5097.916,
			5100.636,
			5104.719,
			5108.804,
			5111.53,
			5114.292,
			5118.403,
			5121.188,
			5125.39,
			5126.792,
			5128.195,
			5132.412,
			5135.23,
			5139.487,
			5142.32,
			5146.571,
			5149.413,
			5150.835,
			5153.674,
			5156.521,
			5157.949,
			5160.811,
			5163.688,
			5168.013,
			5170.903,
			5175.246,
			5176.683,
			5178.119,
			5182.444,
			5185.344,
			5189.766,
			5192.715,
			5195.669,
			5197.148,
			5200.112,
			5201.596,
			5204.566,
			5207.538,
			5209.025,
			5212.004,
			5214.991,
			5219.481,
			5220.98,
			5222.48,
			5225.485,
			5230.001,
			5234.533,
			5237.56,
			5240.593,
			5245.146,
			5248.182,
			5252.745,
			5257.326,
			5261.93,
			5263.47,
			5268.1,
			5271.194,
			5274.292,
			5275.843,
			5278.946,
			5283.611,
			5288.298,
			5291.435,
			5294.576,
			5299.282,
			5302.419,
			5307.133,
			5310.283,
			5315.022,
			5318.188,
			5322.956,
			5326.15,
			5329.36,
			5330.964,
			5334.157,
			5338.932,
			5342.08
		],
		[
			0,
			0.062,
			0.124,
			0.188,
			0.25,
			0.329,
			0.373,
			0.437,
			0.5,
			0.56,
			0.623,
			0.655,
			0.687,
			0.763,
			0.809,
			0.873,
			0.935,
			0.983,
			0.999,
			1.06,
			1.092,
			1.122,
			1.198,
			1.248,
			1.309,
			1.372,
			1.434,
			1.497,
			1.558,
			1.636,
			1.684,
			1.747,
			1.809,
			1.871,
			1.964,
			1.996,
			2.074,
			2.121,
			2.183,
			2.243,
			2.308,
			2.371,
			2.401,
			2.432,
			2.509,
			2.555,
			2.62,
			2.682,
			2.728,
			2.741,
			2.805,
			2.868,
			2.946,
			2.993,
			3.059,
			3.117,
			3.178,
			3.272,
			3.304,
			3.381,
			3.428,
			3.493,
			3.555,
			3.6,
			3.616,
			3.677,
			3.709,
			3.741,
			3.803,
			3.818,
			3.865,
			3.926,
			3.988,
			4.036,
			4.051,
			4.114,
			4.145,
			4.175,
			4.254,
			4.301,
			4.369,
			4.424,
			4.472,
			4.488,
			4.55,
			4.581,
			4.613,
			4.676,
			4.689,
			4.737,
			4.798,
			4.863,
			4.908,
			4.924,
			4.988,
			5.048,
			5.126,
			5.175,
			5.241,
			5.299,
			5.36,
			5.422,
			5.484,
			5.564,
			5.609,
			5.673,
			5.735,
			5.797,
			5.892,
			5.923,
			6,
			6.046,
			6.109,
			6.17,
			6.219,
			6.296,
			6.328,
			6.36,
			6.44,
			6.482,
			6.546,
			6.608,
			6.655,
			6.67,
			6.733,
			6.794,
			6.874,
			6.92,
			6.981,
			7.043,
			7.107,
			7.2,
			7.233,
			7.31,
			7.358,
			7.42,
			7.481,
			7.544,
			7.636,
			7.667,
			7.73,
			7.746,
			7.793,
			7.856,
			7.918,
			7.981,
			8.073,
			8.105,
			8.166,
			8.23,
			8.292,
			8.355,
			8.416,
			8.478,
			8.541,
			8.603,
			8.619,
			8.665,
			8.729,
			8.792,
			8.854,
			8.948,
			8.976,
			9.04,
			9.102,
			9.165,
			9.226,
			9.289,
			9.382,
			9.415,
			9.474,
			9.49,
			9.54,
			9.602,
			9.664,
			9.709,
			9.726,
			9.788,
			9.82,
			9.851,
			9.929,
			9.975,
			10.038,
			10.098,
			10.146,
			10.224,
			10.286,
			10.363,
			10.412,
			10.474,
			10.536,
			10.598,
			10.69,
			10.722,
			10.8,
			10.845,
			10.91,
			10.97,
			11.033,
			11.097,
			11.128,
			11.159,
			11.221,
			11.282,
			11.347,
			11.408,
			11.47,
			11.563,
			11.595,
			11.671,
			11.719,
			11.781,
			11.842,
			11.889,
			11.998,
			12.031,
			12.108,
			12.155,
			12.217,
			12.28,
			12.34,
			12.435,
			12.467,
			12.53,
			12.546,
			12.591,
			12.656,
			12.715,
			12.778,
			12.872,
			12.902,
			12.964,
			13.027,
			13.09,
			13.153,
			13.197,
			13.214,
			13.276,
			13.339,
			13.417,
			13.464,
			13.527,
			13.589,
			13.651,
			13.713,
			13.774,
			13.854,
			13.9,
			13.962,
			14.023,
			14.084,
			14.179,
			14.21,
			14.288,
			14.335,
			14.397,
			14.46,
			14.507,
			14.157,
			14.22,
			14.297,
			14.346,
			14.407,
			14.47,
			14.532,
			14.624,
			14.656,
			14.738,
			14.782,
			14.844,
			14.905,
			14.968,
			15.062,
			15.091,
			15.172,
			15.218,
			15.281,
			15.343,
			15.404,
			15.499,
			15.529,
			15.59,
			15.655,
			15.717,
			15.78,
			15.839,
			15.904,
			15.933,
			15.964,
			16.029,
			16.044,
			16.089,
			16.153,
			16.215,
			16.277,
			16.338,
			16.372,
			16.403,
			16.48,
			16.526,
			16.589,
			16.651,
			16.714,
			16.813,
			16.838,
			16.915,
			16.963,
			17.025,
			17.088,
			17.148,
			17.244,
			17.274,
			17.336,
			17.353,
			17.4,
			17.46,
			17.525,
			17.587,
			17.681,
			17.711,
			17.789,
			17.836,
			17.9,
			17.961,
			18.023,
			18.115,
			18.148,
			18.225,
			18.272,
			18.336,
			18.398,
			18.461,
			18.523,
			18.585,
			18.662,
			18.709,
			18.771,
			18.835,
			18.897,
			18.99,
			19.02,
			19.082,
			19.098,
			19.146,
			19.21,
			19.271,
			19.318,
			19.426,
			19.458,
			19.519,
			19.582,
			19.645,
			19.705,
			19.769,
			19.863,
			19.889,
			19.954,
			19.97,
			20.018,
			20.08,
			20.142,
			20.204,
			20.266,
			20.329,
			20.407,
			20.453,
			20.515,
			20.577,
			20.64,
			20.735,
			20.766,
			20.829,
			20.844,
			20.89,
			20.958,
			21.014,
			21.078,
			21.172,
			21.203,
			21.279,
			21.326,
			21.389,
			21.452,
			21.515,
			21.608,
			21.639,
			21.701,
			21.717,
			21.764,
			21.827,
			21.888,
			21.95,
			22.044,
			22.073,
			22.152,
			22.2,
			22.26,
			22.324,
			22.387,
			22.448,
			22.48,
			22.51,
			22.587,
			22.636,
			22.698,
			22.76,
			22.807,
			22.824,
			22.883,
			22.947,
			23.031,
			23.071,
			23.132,
			23.195,
			23.241,
			23.321,
			23.382,
			23.461,
			23.506,
			23.57,
			23.631,
			23.679,
			23.693,
			23.758,
			23.788,
			23.82,
			23.896,
			23.944,
			24.006,
			24.067,
			24.115,
			24.193,
			24.255,
			24.332,
			24.378,
			24.442,
			24.505,
			24.549,
			24.566,
			24.628,
			24.658,
			24.692,
			24.77,
			24.814,
			24.879,
			24.941,
			25.003,
			25.1,
			25.126,
			25.205,
			25.252,
			25.319,
			25.375,
			25.439,
			25.502,
			25.532,
			25.565,
			25.626,
			25.642,
			25.688,
			25.75,
			25.814,
			25.86,
			25.877,
			25.938,
			25.969,
			25.999,
			26.063,
			26.124,
			26.188,
			26.249,
			26.295,
			26.312,
			26.373,
			26.405,
			26.437,
			26.499,
			26.515,
			26.559,
			26.622,
			26.686,
			26.748,
			26.81,
			26.872,
			26.933,
			26.949,
			26.998,
			27.065,
			27.122,
			27.183,
			27.248,
			27.279,
			27.31,
			27.371,
			27.434,
			27.497,
			27.557,
			27.605,
			27.62,
			27.683,
			27.714,
			27.746,
			27.807,
			27.823,
			27.87,
			27.934,
			27.995,
			28.057,
			28.12,
			28.152,
			28.181,
			28.244,
			28.308,
			28.369,
			28.432,
			28.496,
			28.557,
			28.588,
			28.619,
			28.681,
			28.697,
			28.742,
			28.805,
			28.868,
			28.93,
			29.022,
			29.053,
			29.115,
			29.177,
			29.245,
			29.302,
			29.365,
			29.427,
			29.457,
			29.488,
			29.553,
			29.567,
			29.615,
			29.677,
			29.739,
			29.786,
			29.803,
			29.864,
			29.925,
			30.003,
			30.051,
			30.112,
			30.176,
			30.22,
			30.238,
			30.299,
			30.331,
			30.363,
			30.423,
			30.442,
			30.487,
			30.55,
			30.613,
			30.798,
			30.877,
			30.924,
			30.986,
			31.048,
			31.109,
			31.204,
			31.233,
			31.297,
			31.314,
			31.36,
			31.422,
			31.484,
			31.547,
			31.641,
			31.671,
			31.733,
			31.748,
			31.795,
			31.856,
			31.919,
			31.982,
			32.076,
			32.107,
			32.185,
			32.231,
			32.295,
			32.357,
			32.42,
			32.512,
			32.543,
			32.62,
			32.668,
			32.731,
			32.792,
			32.839,
			32.916,
			32.977,
			33.054,
			33.103,
			33.165,
			33.226,
			33.273,
			33.288,
			33.352,
			33.386,
			33.414,
			33.492,
			33.537,
			33.601,
			33.663,
			33.708,
			33.724,
			33.787,
			33.819,
			33.851,
			33.912,
			33.974,
			34.038,
			34.098,
			34.16,
			34.254,
			34.286,
			34.348,
			34.364,
			34.41,
			34.473,
			34.533,
			34.595,
			34.689,
			34.722,
			34.783,
			34.799,
			34.846,
			34.908,
			34.972,
			35.017,
			35.095,
			35.158,
			35.236,
			35.282,
			35.345,
			35.406,
			35.47,
			35.532,
			35.564,
			35.595,
			35.673,
			35.72,
			35.781,
			35.844,
			35.891,
			35.969,
			36.031,
			36.107,
			36.156,
			36.218,
			36.279,
			36.327,
			36.344,
			36.405,
			36.469,
			36.544,
			36.591,
			36.655,
			36.715,
			36.764,
			36.842,
			36.903,
			36.983,
			37.028,
			37.093,
			37.155,
			37.2,
			37.215,
			37.279,
			37.31,
			37.339,
			37.419,
			37.465,
			37.531,
			37.589,
			37.637,
			37.652,
			37.714,
			37.778,
			37.839,
			37.904,
			37.966,
			38.028,
			38.074,
			38.089,
			38.151,
			38.183,
			38.214,
			38.291,
			38.339,
			38.399,
			38.464,
			38.525,
			38.588,
			38.618,
			38.649,
			38.727,
			38.774,
			38.837,
			38.898,
			38.946,
			39.023,
			39.054,
			39.086,
			39.148,
			39.164,
			39.21,
			39.272,
			39.334,
			39.38,
			39.396,
			39.459,
			39.489,
			39.522,
			39.583,
			39.604,
			39.646,
			39.71,
			39.772,
			39.819,
			39.895,
			39.927,
			39.956,
			40.02,
			40.083,
			40.148,
			40.208,
			40.271,
			40.333,
			40.363,
			40.395,
			40.457,
			40.472,
			40.519,
			40.582,
			40.645,
			40.689,
			40.8,
			40.83,
			40.893,
			40.954,
			41.015,
			41.079,
			41.142,
			41.236,
			41.268,
			41.344,
			41.392,
			41.454,
			41.515,
			41.578,
			41.676,
			41.703,
			41.781,
			41.829,
			41.889,
			41.952,
			42.014,
			42.108,
			42.138,
			42.217,
			42.263,
			42.324,
			42.387,
			42.45,
			42.512,
			42.543,
			42.574,
			42.637,
			42.653,
			42.7,
			42.762,
			42.823,
			42.883,
			42.978,
			43.01,
			43.072,
			43.088,
			43.135,
			43.197,
			43.259,
			43.306,
			43.323,
			43.385,
			43.446,
			43.525,
			43.569,
			43.632,
			43.695,
			43.756,
			43.819,
			43.879,
			43.957,
			44.007,
			44.069,
			44.131,
			44.191,
			44.256,
			44.287,
			44.318,
			44.395,
			44.443,
			44.505,
			44.566,
			44.63,
			44.691,
			44.753,
			44.831,
			44.877,
			44.94,
			45.003,
			45.065,
			45.16,
			45.189,
			45.268,
			45.315,
			45.377,
			45.44,
			45.486,
			45.5,
			45.564,
			45.625,
			45.704,
			45.751,
			45.816,
			45.875,
			45.937,
			45.999,
			46.031,
			46.059,
			46.139,
			46.186,
			46.247,
			46.31,
			46.372,
			46.467,
			46.495,
			46.56,
			46.574,
			46.622,
			46.683,
			46.746,
			46.808,
			46.872,
			46.903,
			46.932,
			46.996,
			47.059,
			47.122,
			47.183,
			47.23,
			47.244,
			47.308,
			47.337,
			47.369,
			47.43,
			47.446,
			47.495,
			47.558,
			47.62,
			47.68,
			47.744,
			47.807,
			47.891,
			47.929,
			47.994,
			48.054,
			48.117,
			48.212,
			48.241,
			48.319,
			48.367,
			48.429,
			48.493,
			48.555,
			48.614,
			48.679,
			48.755,
			48.803,
			48.867,
			48.929,
			48.99,
			49.053,
			49.083,
			49.115,
			49.177,
			49.193,
			49.24,
			49.304,
			49.364,
			49.425,
			49.488,
			49.52,
			49.55,
			49.628,
			49.676,
			49.74,
			49.8,
			49.849,
			49.864,
			49.926,
			49.989,
			50.065,
			50.113,
			50.175,
			50.237,
			50.299,
			50.362,
			50.391,
			50.424,
			50.503,
			50.548,
			50.611,
			50.673,
			50.719,
			50.737,
			50.798,
			50.86,
			50.937,
			50.985,
			51.048,
			51.108,
			51.156,
			51.171,
			51.234,
			51.296,
			51.377,
			51.42,
			51.485,
			51.545,
			51.608,
			51.671,
			51.733,
			51.811,
			51.857,
			51.919,
			51.982,
			52.044,
			52.138,
			52.17,
			52.248,
			52.294,
			52.358,
			52.417,
			52.479,
			52.544,
			52.607,
			52.683,
			52.731,
			52.792,
			52.856,
			52.9,
			52.916,
			52.979,
			53.01,
			53.04,
			53.119,
			53.165,
			53.227,
			53.289,
			53.337,
			53.415,
			53.446,
			53.478,
			53.54,
			53.602,
			53.668,
			53.727,
			53.788,
			53.85,
			53.882,
			53.911,
			53.991,
			54.037,
			54.105,
			54.163,
			54.21,
			54.225,
			54.287,
			54.319,
			54.349,
			54.427,
			54.475,
			54.538,
			54.598,
			54.646,
			54.724,
			54.786,
			54.864,
			54.909,
			54.974,
			55.033,
			55.081,
			55.097,
			55.16,
			55.223,
			55.299,
			55.348,
			55.409,
			55.472,
			55.533,
			55.595,
			55.657,
			55.736,
			55.782,
			55.843,
			55.908,
			55.969,
			56.062,
			56.093,
			56.177,
			56.219,
			56.279,
			56.343,
			56.39,
			56.468,
			56.498,
			56.529,
			56.592,
			56.655,
			56.718,
			56.781,
			56.826,
			56.843,
			56.902,
			56.966,
			57.044,
			57.09,
			57.154,
			57.214,
			57.278,
			57.37,
			57.404,
			57.481,
			57.527,
			57.592,
			57.652,
			57.716,
			57.807,
			57.84,
			57.899,
			57.917,
			57.964,
			58.026,
			58.087,
			58.151,
			58.211,
			58.274,
			58.351,
			58.399,
			58.46,
			58.523,
			58.572,
			58.647,
			58.71,
			58.79,
			58.837,
			58.898,
			58.959,
			59.021,
			59.083,
			59.115,
			59.147,
			59.225,
			59.27,
			59.332,
			59.395,
			59.442,
			59.553,
			59.582,
			59.662,
			59.708,
			59.772,
			59.831,
			59.895,
			59.987,
			60.02,
			60.08,
			60.097,
			60.142,
			60.207,
			60.268,
			60.33,
			60.393,
			60.424,
			60.455,
			60.532,
			60.58,
			60.646,
			60.706,
			60.753,
			60.767,
			60.829,
			60.86,
			60.892,
			60.97,
			61.014,
			61.077,
			61.139,
			61.203,
			61.265,
			61.294,
			61.327,
			61.404,
			61.45,
			61.515,
			61.577,
			61.622,
			61.637,
			61.701,
			61.763,
			61.842,
			61.887,
			61.948,
			62.012,
			62.075,
			62.137,
			62.199,
			62.277,
			62.322,
			62.391,
			62.449,
			62.51,
			62.604,
			62.635,
			62.714,
			62.76,
			62.823,
			62.883,
			62.947,
			63.038,
			63.071,
			63.134,
			63.149,
			63.196,
			63.256,
			63.321,
			63.383,
			63.445,
			63.507,
			63.585,
			63.631,
			63.695,
			63.757,
			63.819,
			63.905,
			63.942,
			64.02,
			64.067,
			64.129,
			64.191,
			64.254,
			64.347,
			64.379,
			64.461,
			64.504,
			64.565,
			64.629,
			64.691,
			64.754,
			64.816,
			64.892,
			64.94,
			65.002,
			65.064,
			65.128,
			65.219,
			65.253,
			65.33,
			65.375,
			65.438,
			65.502,
			65.547,
			65.563,
			65.657,
			65.688,
			65.75,
			65.765,
			65.814,
			65.874,
			65.938,
			66,
			66.062,
			66.093,
			66.125,
			66.203,
			66.25,
			66.313,
			66.374,
			66.437,
			66.498,
			66.56,
			66.639,
			66.685,
			66.749,
			66.81,
			66.874,
			66.965,
			66.996,
			67.061,
			67.076,
			67.121,
			67.186,
			67.245,
			67.309,
			67.402,
			67.432,
			67.495,
			67.512,
			67.559,
			67.62,
			67.682,
			67.746,
			67.839,
			67.87,
			67.931,
			67.947,
			67.994,
			68.057,
			68.12,
			68.181,
			68.242,
			68.304,
			68.385,
			68.429,
			68.492,
			68.555,
			68.618,
			68.68,
			68.71,
			68.741,
			68.806,
			68.821,
			68.867,
			68.929,
			68.989,
			69.054,
			69.115,
			69.148,
			69.178,
			69.241,
			69.302,
			69.366,
			69.427,
			69.491,
			69.55,
			69.584,
			69.613,
			69.693,
			69.74,
			69.803,
			69.864,
			69.91,
			69.926,
			69.988,
			70.02,
			70.049,
			70.13,
			70.175,
			70.239,
			70.3,
			70.348,
			70.362,
			70.426,
			70.487,
			70.564,
			70.613,
			70.68,
			70.737,
			70.781,
			70.798,
			70.862,
			70.923,
			71.002,
			71.049,
			71.111,
			71.172,
			71.235,
			71.297,
			71.328,
			71.359,
			71.437,
			71.484,
			71.546,
			71.608,
			71.672,
			71.733,
			71.764,
			71.797,
			71.857,
			71.875,
			71.921,
			71.982,
			72.046,
			72.091,
			72.202,
			72.233,
			72.294,
			72.357,
			72.419,
			72.482,
			72.545,
			72.607,
			72.637,
			72.67,
			72.75,
			72.793,
			72.857,
			72.919,
			72.98,
			73.042,
			73.074,
			73.106,
			73.181,
			73.23,
			73.293,
			73.355,
			73.418,
			73.48,
			73.512,
			73.542,
			73.619,
			73.667,
			73.73,
			73.792,
			73.838,
			73.914,
			73.945,
			73.977,
			74.054,
			74.103,
			74.165,
			74.225,
			74.29,
			74.35,
			74.383,
			74.414,
			74.477,
			74.491,
			74.537,
			74.6,
			74.665,
			74.726,
			74.787,
			74.824,
			74.851,
			74.928,
			74.974,
			75.037,
			75.099,
			75.162,
			75.254,
			75.287,
			75.348,
			75.364,
			75.412,
			75.477,
			75.536,
			75.598,
			75.662,
			75.692,
			75.722,
			75.784,
			75.802,
			75.847,
			75.91,
			75.972,
			76.033,
			76.096,
			76.127,
			76.16,
			76.237,
			76.284,
			76.346,
			76.408,
			76.456,
			76.47,
			76.535,
			76.564,
			76.595,
			76.673,
			76.72,
			76.782,
			76.845,
			76.907,
			76.97,
			77.015,
			77.096,
			77.127,
			77.156,
			77.219,
			77.282,
			77.343,
			77.405,
			77.453,
			77.469,
			77.531,
			77.561,
			77.595,
			77.671,
			77.719,
			77.781,
			77.843,
			77.907,
			78,
			78.031,
			78.109,
			78.156,
			78.219,
			78.28,
			78.326,
			78.343,
			78.405,
			78.437,
			78.465,
			78.545,
			78.593,
			78.653,
			78.716,
			78.764,
			78.839,
			78.902,
			78.985,
			79.028,
			79.09,
			79.153,
			79.199,
			79.215,
			79.276,
			79.339,
			79.416,
			79.464,
			79.527,
			79.588,
			79.636,
			79.649,
			79.714,
			79.775,
			79.853,
			79.899,
			79.963,
			80.024,
			80.087,
			80.179,
			80.212,
			80.288,
			80.337,
			80.399,
			80.459,
			80.523,
			80.617,
			80.647,
			80.726,
			80.771,
			80.833,
			80.896,
			80.959,
			81.02,
			81.082,
			81.161,
			81.209,
			81.272,
			81.332,
			81.378,
			81.395,
			81.458,
			81.52,
			81.597,
			81.645,
			81.707,
			81.77,
			81.83,
			81.893,
			81.956,
			82.033,
			82.081,
			82.144,
			82.204,
			82.267,
			82.329,
			82.392,
			82.469,
			82.517,
			82.581,
			82.642,
			82.704,
			82.798,
			82.829,
			82.907,
			82.952,
			83.019,
			83.078,
			83.141,
			83.2,
			83.264,
			83.342,
			83.388,
			83.45,
			83.514,
			83.575,
			83.638,
			83.699,
			83.779,
			83.825,
			83.888,
			83.949,
			84.011,
			84.075,
			84.138,
			84.214,
			84.261,
			84.324,
			84.387,
			84.433,
			84.449,
			84.511,
			84.572,
			84.652,
			84.698,
			84.76,
			84.822,
			84.869,
			84.947,
			84.979,
			85.011,
			85.089,
			85.136,
			85.203,
			85.259,
			85.323,
			85.415,
			85.446,
			85.51,
			85.526,
			85.571,
			85.633,
			85.697,
			85.76,
			85.852,
			85.883,
			85.962,
			86.007,
			86.07,
			86.131,
			86.179,
			86.195,
			86.259,
			86.288,
			86.319,
			86.398,
			86.445,
			86.507,
			86.569,
			86.615,
			86.695,
			86.726,
			86.756,
			86.833,
			86.88,
			86.943,
			87.005,
			87.053,
			87.069,
			87.131,
			87.162,
			87.193,
			87.254,
			87.276,
			87.318,
			87.381,
			87.443,
			87.49,
			87.566,
			87.596,
			87.628,
			87.705,
			87.753,
			87.816,
			87.876,
			87.924,
			87.939,
			88.003,
			88.065,
			88.144,
			88.189,
			88.253,
			88.314,
			88.361,
			88.377,
			88.439,
			88.472,
			88.502,
			88.58,
			88.627,
			88.688,
			88.75,
			88.798,
			88.813,
			88.907,
			88.937,
			89.015,
			89.062,
			89.124,
			89.186,
			89.232,
			89.247,
			89.31,
			89.349,
			89.372,
			89.452,
			89.498,
			89.56,
			89.622,
			89.686,
			89.748,
			89.81,
			89.888,
			89.933,
			89.997,
			90.059,
			90.104,
			90.12,
			90.181,
			90.213,
			90.247,
			90.324,
			90.368,
			90.434,
			90.496,
			90.556,
			90.619,
			90.651,
			90.683,
			90.758,
			90.806,
			90.87,
			90.932,
			90.976,
			90.992,
			91.055,
			91.119,
			91.195,
			91.241,
			91.307,
			91.368,
			91.431,
			91.492,
			91.524,
			91.554,
			91.616,
			91.678,
			91.741,
			91.805,
			91.851,
			91.866,
			91.927,
			91.959,
			91.988,
			92.066,
			92.116,
			92.177,
			92.24,
			92.284,
			92.303,
			92.364,
			92.425,
			92.504,
			92.55,
			92.613,
			92.674,
			92.723,
			92.738,
			92.799,
			92.833,
			92.863,
			92.925,
			92.94,
			92.989,
			93.048,
			93.113,
			93.159,
			93.175,
			93.237,
			93.269,
			93.298,
			93.363,
			93.425,
			93.49,
			93.549,
			93.596,
			93.675,
			93.736,
			93.815,
			93.86,
			93.923,
			93.986,
			94.032,
			94.047,
			94.111,
			94.142,
			94.174,
			94.249,
			94.296,
			94.359,
			94.423,
			94.47,
			94.548,
			94.579,
			94.61,
			94.672,
			94.687,
			94.732,
			94.796,
			94.86,
			94.92,
			94.982,
			95.015,
			95.046,
			95.107,
			95.123,
			95.17,
			95.234,
			95.343,
			95.421,
			95.45,
			95.482,
			95.545,
			95.565,
			95.607,
			95.669,
			95.733,
			95.794,
			95.858,
			95.888,
			95.92,
			95.997,
			96.042,
			96.106,
			96.17,
			96.23,
			96.294,
			96.324,
			96.355,
			96.419,
			96.482,
			96.543,
			96.606,
			96.667,
			96.731,
			96.76,
			96.791,
			96.87,
			96.915,
			96.979,
			97.04,
			97.086,
			97.103,
			97.165,
			97.196,
			97.226,
			97.305,
			97.351,
			97.415,
			97.477,
			97.525,
			97.539,
			97.602,
			97.664,
			97.742,
			97.788,
			97.852,
			97.913,
			97.974,
			98.038,
			98.067,
			98.099,
			98.178,
			98.225,
			98.287,
			98.348,
			98.396,
			98.505,
			98.536,
			98.599,
			98.614,
			98.66,
			98.724,
			98.786,
			98.847,
			98.907,
			98.94,
			98.972,
			99.035,
			99.048,
			99.096,
			99.158,
			99.22,
			99.284,
			99.347,
			99.377,
			99.407,
			99.487,
			99.533,
			99.595,
			99.659,
			99.709,
			99.721,
			99.784,
			99.814,
			99.844,
			99.921,
			99.97,
			100.033,
			100.094,
			100.139,
			100.216,
			100.248,
			100.281,
			100.343,
			100.404,
			100.47,
			100.531,
			100.592,
			100.655,
			100.684,
			100.718,
			100.795,
			100.841,
			100.905,
			100.967,
			101.028,
			101.09,
			101.121,
			101.153,
			101.232,
			101.278,
			101.341,
			101.403,
			101.464,
			101.526,
			101.557,
			101.59,
			101.667,
			101.714,
			101.78,
			101.839,
			101.899,
			101.996,
			102.026,
			102.105,
			102.15,
			102.213,
			102.275,
			102.338,
			102.399,
			102.463,
			102.54,
			102.588,
			102.65,
			102.711,
			102.774,
			102.869,
			102.9,
			102.978,
			103.023,
			103.088,
			103.149,
			103.213,
			103.305,
			103.338,
			103.398,
			103.414,
			103.462,
			103.524,
			103.586,
			103.646,
			103.739,
			103.772,
			103.834,
			103.854,
			103.897,
			103.959,
			104.022,
			104.082,
			104.177,
			104.207,
			104.287,
			104.334,
			104.396,
			104.457,
			104.504,
			104.519,
			104.612,
			104.643,
			104.707,
			104.722,
			104.769,
			104.831,
			104.893,
			104.955,
			105.017,
			105.05,
			105.081,
			105.157,
			105.206,
			105.269,
			105.331,
			105.392,
			105.454,
			105.517,
			105.596,
			105.64,
			105.705,
			105.767,
			105.814,
			105.891,
			105.954,
			106.03,
			106.077,
			106.14,
			106.203,
			106.248,
			106.265,
			106.326,
			106.357,
			106.387,
			106.467,
			106.514,
			106.574,
			106.638,
			106.686,
			106.701,
			106.763,
			106.825,
			106.904,
			106.948,
			107.014,
			107.075,
			107.136,
			107.2,
			107.23,
			107.262,
			107.34,
			107.387,
			107.45,
			107.511,
			107.574,
			107.668,
			107.698,
			107.777,
			107.822,
			107.884,
			107.947,
			108.009,
			108.071,
			108.103,
			108.134,
			108.196,
			108.259,
			108.321,
			108.383,
			108.446,
			108.539,
			108.57,
			108.648,
			108.696,
			108.758,
			108.82,
			108.881,
			108.944,
			109.006,
			109.083,
			109.131,
			109.193,
			109.255,
			109.319,
			109.41,
			109.444,
			109.522,
			109.565,
			109.631,
			109.691,
			109.737,
			109.816,
			109.847,
			109.878,
			109.94,
			110.003,
			110.07,
			110.127,
			110.175,
			110.19,
			110.253,
			110.283,
			110.316,
			110.392,
			110.438,
			110.5,
			110.564,
			110.61,
			110.625,
			110.686,
			110.72,
			110.749,
			110.812,
			110.874,
			110.937,
			110.998,
			111.062,
			111.124,
			111.156,
			111.184,
			111.248,
			111.264,
			111.31,
			111.374,
			111.434,
			111.497,
			111.56,
			111.589,
			111.622,
			111.682,
			111.698,
			111.746,
			111.809,
			111.87,
			111.931,
			111.993,
			112.024,
			112.057,
			112.119,
			112.138,
			112.181,
			112.244,
			112.305,
			112.369,
			112.431,
			112.463,
			112.493,
			112.554,
			112.57,
			112.619,
			112.681,
			112.743,
			112.789,
			112.865,
			112.898,
			112.928,
			112.992,
			113.055,
			113.115,
			113.179,
			113.225,
			113.241,
			113.304,
			113.335,
			113.366,
			113.443,
			113.491,
			113.554,
			113.616,
			113.678,
			113.77,
			113.802,
			113.864,
			113.927,
			113.988,
			114.052,
			114.099,
			114.114,
			114.211,
			114.237,
			114.298,
			114.487,
			114.548,
			114.612,
			114.644,
			114.675,
			114.738,
			114.798,
			114.863,
			114.925,
			114.987,
			115.08,
			115.111,
			115.173,
			115.188,
			115.237,
			115.3,
			115.36,
			115.406,
			115.422,
			115.486,
			115.548,
			115.626,
			115.672,
			115.733,
			115.798,
			115.845,
			115.921,
			115.983,
			116.06,
			116.107,
			116.172,
			116.233,
			116.295,
			116.357,
			116.42,
			116.496,
			116.543,
			116.607,
			116.67,
			116.732,
			116.792,
			116.826,
			116.857,
			116.931,
			116.98,
			117.042,
			117.105,
			117.166,
			117.26,
			117.292,
			117.354,
			117.369,
			117.415,
			117.478,
			117.541,
			117.602,
			117.696,
			117.726,
			117.805,
			117.853,
			117.914,
			117.976,
			118.037,
			118.1,
			118.132,
			118.162,
			118.226,
			118.239,
			118.286,
			118.354,
			118.411,
			118.473,
			118.568,
			118.6,
			118.675,
			118.724,
			118.784,
			118.847,
			118.894,
			118.972,
			119.003,
			119.034,
			119.113,
			119.16,
			119.222,
			119.283,
			119.347,
			119.407,
			119.472,
			119.548,
			119.596,
			119.658,
			119.719,
			119.765,
			119.845,
			119.877,
			119.906,
			119.969,
			120.031,
			120.095,
			120.154,
			120.217,
			120.281,
			120.31,
			120.342,
			120.405,
			120.425,
			120.468,
			120.53,
			120.591,
			120.654,
			120.717,
			120.749,
			120.78,
			120.857,
			120.903,
			120.965,
			121.026,
			121.073,
			121.088,
			121.149,
			121.184,
			121.214,
			121.276,
			121.292,
			121.338,
			121.4,
			121.464,
			121.51,
			121.526,
			121.587,
			121.649,
			121.727,
			121.775,
			121.837,
			121.899,
			121.945,
			122.025,
			122.087,
			122.165,
			122.212,
			122.275,
			122.337,
			122.383,
			122.399,
			122.46,
			122.522,
			122.601,
			122.648,
			122.71,
			122.773,
			122.82,
			122.891,
			122.959,
			123.037,
			123.082,
			123.147,
			123.208,
			123.256,
			123.27,
			123.333,
			123.396,
			123.446
		]
	],
	'8703_9040_7982': [
		[
			119.344
		],
		[
			0
		],
		[
			0,
			3.899,
			5.459,
			7.021,
			10.149,
			13.295,
			14.873,
			19.607,
			22.759,
			27.485,
			30.643,
			33.803,
			38.551,
			41.724,
			48.089,
			49.681,
			54.463,
			57.656,
			62.455,
			65.66,
			70.477,
			75.302,
			78.525,
			81.751,
			86.598,
			89.835,
			94.698,
			97.943,
			102.806,
			106.032,
			110.822,
			113.974,
			118.631,
			121.689,
			123.205,
			126.21,
			129.179,
			130.648,
			133.559,
			136.43,
			140.665,
			143.44,
			148.877,
			150.213,
			154.162,
			156.747,
			160.554,
			163.045,
			166.711,
			169.109,
			172.642,
			176.096,
			178.351,
			180.568,
			183.824,
			185.947,
			190.083,
			191.094,
			194.071,
			196.01,
			198.85,
			200.698,
			203.407,
			206.04,
			206.9,
			209.426,
			210.25,
			211.064,
			213.455,
			215.019,
			217.277,
			218.74,
			220.168,
			220.872,
			222.261,
			222.949,
			224.307,
			225.645,
			227.63,
			228.937,
			230.864,
			231.505,
			232.145,
			234.062,
			234.694,
			235.327,
			237.225,
			238.493,
			240.43,
			243.024,
			243.678,
			245.082,
			245.785,
			247.193,
			248.606,
			250.89,
			252.418,
			254.805,
			255.641,
			256.475,
			259.011,
			260.985,
			263.975,
			266.451,
			270.106,
			273.67,
			275.135,
			277.257,
			278.672,
			279.854,
			281.59,
			282.748,
			285.139,
			285.753,
			287.602,
			288.843,
			290.798,
			292.112,
			294.106,
			295.468,
			297.532,
			299.633,
			300.346,
			302.505,
			304.71,
			305.461,
			306.215,
			308.499,
			310.048,
			312.402,
			313.991,
			315.603,
			318.047,
			319.695,
			322.194,
			323.876,
			327.249,
			328.093,
			330.644,
			332.345,
			334.072,
			334.938,
			336.694,
			339.329,
			341.972,
			343.759,
			345.554,
			348.27,
			350.095,
			352.855,
			354.711,
			357.517,
			359.404,
			362.261,
			364.181,
			367.088,
			369.042,
			371.01,
			371.999,
			374.995,
			376.991,
			378.988,
			381.986,
			383.981,
			386.972,
			387.968,
			388.965,
			391.959,
			393.955,
			396.974,
			398.994,
			402.058,
			404.114,
			405.142,
			407.273,
			409.406,
			410.483,
			412.736,
			414.986,
			418.293,
			420.481,
			423.546,
			424.565,
			425.579,
			428.538,
			430.51,
			433.47,
			435.445,
			437.433,
			438.429,
			440.424,
			441.426,
			443.433,
			446.457,
			448.482,
			450.513,
			453.588,
			454.617,
			455.649,
			458.81,
			460.935,
			464.178,
			466.345,
			469.574,
			471.714,
			473.844,
			474.912,
			477.069,
			479.247,
			483.622,
			484.721,
			487.993,
			490.169,
			493.443,
			495.634,
			497.835,
			498.939,
			501.154,
			502.262,
			504.483,
			506.712,
			510.06,
			512.299,
			515.666,
			516.791,
			517.914,
			521.296,
			523.557,
			526.964,
			529.26,
			532.726,
			535.061,
			537.404,
			538.58,
			542.116,
			544.451,
			546.813,
			550.368,
			552.755,
			557.577,
			558.79,
			562.437,
			564.877,
			568.542,
			570.986,
			574.663,
			578.365,
			580.856,
			584.617,
			587.138,
			589.665,
			593.464,
			596.001,
			601.093,
			602.369,
			606.205,
			608.766,
			612.604,
			615.146,
			618.908,
			621.365,
			624.98,
			628.508,
			630.839,
			633.115,
			635.356,
			638.662,
			642.986,
			644.054,
			646.176,
			647.235,
			649.329,
			652.482,
			654.602,
			656.702,
			659.861,
			660.913,
			663.023,
			665.135,
			666.193,
			667.252,
			670.457,
			672.613,
			675.938,
			678.193,
			681.701,
			685.248,
			687.62,
			689.98,
			693.514,
			695.848,
			700.53,
			701.671,
			706.139,
			707.227,
			710.471,
			712.614,
			715.824,
			719.033,
			721.156,
			723.275,
			724.336,
			726.455,
			728.577,
			731.755,
			733.877,
			738.096,
			739.154,
			742.324,
			743.384,
			744.445,
			747.619,
			749.737,
			752.888,
			754.985,
			756.024,
			758.096,
			761.196,
			763.258,
			765.318,
			768.441,
			770.524,
			773.664,
			775.762,
			778.926,
			781.044,
			784.237,
			786.374,
			789.591,
			791.741,
			794.959,
			798.21,
			800.388,
			802.585,
			805.9,
			808.132,
			811.496,
			813.751,
			817.148,
			819.421,
			822.845,
			825.139,
			827.441,
			828.595,
			830.909,
			834.392,
			837.892,
			840.234,
			842.58,
			844.932,
			847.29,
			848.47,
			852.024,
			854.401,
			857.982,
			860.378,
			862.783,
			866.406,
			868.831,
			873.707,
			874.931,
			878.616,
			879.849,
			881.084,
			884.804,
			887.294,
			889.795,
			891.049,
			893.563,
			897.352,
			901.162,
			903.712,
			906.27,
			910.121,
			912.698,
			916.574,
			917.869,
			919.166,
			923.066,
			925.664,
			929.568,
			932.177,
			934.791,
			936.1,
			938.723,
			941.349,
			942.665,
			946.618,
			949.26,
			951.906,
			955.886,
			958.555,
			962.529,
			963.843,
			965.155,
			969.045,
			971.584,
			975.374,
			977.869,
			980.364,
			981.609,
			984.098,
			985.337,
			987.788,
			991.404,
			993.775,
			996.117,
			999.595,
			1001.89,
			1005.319,
			1006.453,
			1007.587,
			1010.954,
			1013.189,
			1016.507,
			1018.711,
			1021.994,
			1025.264,
			1027.43,
			1030.665,
			1032.805,
			1034.939,
			1038.124,
			1040.251,
			1044.52,
			1045.597,
			1048.825,
			1050.979,
			1054.199,
			1056.333,
			1059.5,
			1061.566,
			1062.598,
			1063.622,
			1066.638,
			1067.624,
			1069.569,
			1071.477,
			1074.269,
			1076.091,
			1079.641,
			1080.507,
			1083.053,
			1084.71,
			1086.331,
			1088.709,
			1090.231,
			1093.207,
			1093.928,
			1096.046,
			1096.738,
			1097.425,
			1099.464,
			1100.816,
			1102.839,
			1104.184,
			1105.528,
			1106.199,
			1107.537,
			1108.205,
			1109.54,
			1110.861,
			1112.826,
			1114.135,
			1116.728,
			1117.372,
			1119.304,
			1120.605,
			1122.588,
			1123.908,
			1125.233,
			1125.934,
			1127.332,
			1129.426,
			1131.59,
			1133.057,
			1134.523,
			1136.778,
			1138.294,
			1140.568,
			1142.099,
			1144.387,
			1145.901,
			1148.138,
			1149.627,
			1151.891,
			1153.408,
			1155.705,
			1158.12,
			1159.73,
			1161.372,
			1163.866,
			1165.534,
			1168.917,
			1169.764,
			1172.283,
			1173.958,
			1176.377,
			1177.894,
			1180.175,
			1181.597,
			1183.695,
			1185.103,
			1185.809,
			1187.215,
			1188.633,
			1190.785,
			1192.246,
			1194.465,
			1195.212,
			1195.965,
			1198.268,
			1199.822,
			1201.396,
			1203.798,
			1205.417,
			1208.725,
			1210.395,
			1212.071,
			1212.912,
			1213.757,
			1216.312,
			1218.904,
			1220.647,
			1222.405,
			1224.175,
			1225.065,
			1226.854,
			1227.752,
			1229.558,
			1231.376,
			1234.118,
			1235.962,
			1238.723,
			1239.654,
			1240.588,
			1243.402,
			1244.345,
			1245.291,
			1248.145,
			1250.062,
			1252.962,
			1256.877,
			1257.866,
			1259.854,
			1260.869,
			1262.879,
			1264.904,
			1267.965,
			1270.022,
			1278.36,
			1280.458,
			1283.605,
			1285.711,
			1288.887,
			1291.013,
			1292.08,
			1294.218,
			1297.441,
			1299.62,
			1301.809,
			1305.183,
			1306.321,
			1307.461,
			1310.975,
			1313.34,
			1316.91,
			1319.318,
			1322.898,
			1325.213,
			1328.719,
			1331.041,
			1332.206,
			1334.54,
			1338.056,
			1339.227,
			1342.749,
			1346.273,
			1347.45,
			1348.63,
			1352.182,
			1353.37,
			1354.562,
			1358.154,
			1360.58,
			1362.996,
			1366.634,
			1369.07,
			1372.737,
			1373.964,
			1375.193,
			1378.894,
			1380.132,
			1381.372,
			1385.099,
			1387.586,
			1391.326,
			1393.826,
			1396.33,
			1397.584,
			1401.333,
			1403.853,
			1406.404,
			1410.216,
			1412.772,
			1416.625,
			1417.914,
			1419.183,
			1424.355,
			1425.649,
			1429.534,
			1432.122,
			1434.699,
			1435.982,
			1438.552,
			1442.299,
			1445.988,
			1448.402,
			1450.783,
			1454.292,
			1456.59,
			1459.968,
			1461.076,
			1462.174,
			1465.413,
			1467.524,
			1470.624,
			1472.646,
			1475.615,
			1477.568,
			1478.523,
			1480.408,
			1483.174,
			1484.976,
			1486.745,
			1489.336,
			1490.183,
			1491.022,
			1493.479,
			1495.078,
			1497.415,
			1498.929,
			1501.14,
			1502.577,
			1503.987,
			1506.06,
			1506.742,
			1507.42,
			1510.107,
			1510.775,
			1512.79,
			1514.14,
			1516.168,
			1518.884,
			1519.562,
			1521.597,
			1522.949,
			1524.297,
			1526.312,
			1527.656,
			1530.338,
			1531.008,
			1533.733,
			1534.418,
			1536.472,
			1537.868,
			1539.974,
			1542.822,
			1543.558,
			1545.026,
			1545.759,
			1547.249,
			1548.903,
			1551.372,
			1553.087,
			1555.735,
			1557.497,
			1560.125,
			1561.872,
			1564.262,
			1565.83,
			1568.125,
			1571.069,
			1571.806,
			1574.025,
			1575.506,
			1576.99,
			1579.269,
			1580.801,
			1583.811,
			1584.544,
			1586.75,
			1588.18,
			1590.22,
			1591.588,
			1593.65,
			1595.029,
			1597.114,
			1599.249,
			1600.693,
			1602.148,
			1604.381,
			1605.894,
			1608.192,
			1609.757,
			1612.132,
			1613.738,
			1616.182,
			1617.828,
			1619.494,
			1620.333,
			1622.015,
			1624.524,
			1627.074,
			1628.789,
			1630.519,
			1633.15,
			1634.014,
			1634.913,
			1637.569,
			1639.365,
			1642.083,
			1643.91,
			1645.749,
			1648.532,
			1650.406,
			1654.196,
			1655.15,
			1658.993,
			1659.953,
			1662.85,
			1664.794,
			1667.725,
			1671.685,
			1672.684,
			1674.692,
			1675.703,
			1677.734,
			1679.777,
			1682.869,
			1684.944,
			1688.077,
			1690.172,
			1693.311,
			1695.412,
			1698.583,
			1700.71,
			1703.919,
			1708.234,
			1709.319,
			1712.59,
			1714.783,
			1716.986,
			1720.309,
			1722.537,
			1727.023,
			1728.15,
			1731.548,
			1733.826,
			1737.261,
			1739.565,
			1743.038,
			1745.365,
			1748.876,
			1752.41,
			1754.778,
			1757.156,
			1760.742,
			1761.942,
			1763.145,
			1766.767,
			1767.979,
			1769.194,
			1772.853,
			1775.305,
			1779,
			1781.475,
			1785.206,
			1787.705,
			1788.958,
			1791.472,
			1793.994,
			1795.258,
			1796.525,
			1800.338,
			1802.886,
			1806.696,
			1809.214,
			1811.703,
			1812.935,
			1816.579,
			1818.963,
			1821.311,
			1824.765,
			1827.021,
			1830.337,
			1831.424,
			1832.502,
			1835.682,
			1837.756,
			1840.802,
			1842.787,
			1845.699,
			1847.596,
			1850.373,
			1852.183,
			1853.076,
			1854.836,
			1856.563,
			1859.086,
			1860.725,
			1863.12,
			1863.901,
			1864.689,
			1866.975,
			1868.475,
			1870.695,
			1872.163,
			1874.355,
			1877.254,
			1877.98,
			1879.426,
			1880.143,
			1881.578,
			1883.013,
			1885.163,
			1886.592,
			1888.736,
			1889.457,
			1890.185,
			1892.366,
			1893.818,
			1896.061,
			1897.559,
			1899.058,
			1899.817,
			1902.095,
			1903.612,
			1905.147,
			1905.92,
			1907.466,
			1909.009,
			1911.477,
			1913.14,
			1915.722,
			1916.617,
			1917.511,
			1920.167,
			1921.875,
			1924.433,
			1926.059,
			1928.476,
			1930.856,
			1931.636,
			1934.765,
			1936.321,
			1937.884,
			1940.239,
			1941.032,
			1941.826,
			1944.196,
			1945.755,
			1948.09,
			1949.612,
			1951.777,
			1953.168,
			1954.526,
			1956.457,
			1957.691,
			1960.053,
			1960.623,
			1962.279,
			1963.341,
			1964.372,
			1964.878,
			1965.877,
			1966.864,
			1967.354,
			1968.334,
			1968.823,
			1969.8,
			1970.783,
			1972.272,
			1973.255,
			1974.724,
			1975.213,
			1975.717,
			1977.251,
			1978.266,
			1979.781,
			1980.824,
			1981.935,
			1982.487,
			1984.679,
			1985.225,
			1986.408,
			1987.021,
			1988.24,
			1989.453,
			1991.283,
			1992.516,
			1994.352,
			1994.961,
			1995.57,
			1997.374,
			1998.568,
			2000.359,
			2001.548,
			2003.32,
			2004.501,
			2005.093,
			2006.268,
			2007.956,
			2009.084,
			2010.216,
			2011.852,
			2012.9,
			2014.484,
			2015.016,
			2015.551,
			2017.128,
			2018.173,
			2019.756,
			2020.824,
			2022.424,
			2023.495,
			2024.035,
			2025.123,
			2026.769,
			2027.857,
			2028.957,
			2030.635,
			2031.768,
			2033.471,
			2034.046,
			2034.625,
			2036.386,
			2037.593,
			2039.434,
			2040.681,
			2041.956,
			2042.615,
			2043.943,
			2044.614,
			2045.289,
			2048.063,
			2048.776,
			2050.94,
			2052.408,
			2053.898,
			2054.65,
			2056.166,
			2058.47,
			2060.812,
			2062.394,
			2063.991,
			2066.417,
			2068.039,
			2070.483,
			2072.132,
			2075.389,
			2076.205,
			2078.675,
			2080.323,
			2082.846,
			2084.515,
			2087.044,
			2089.563,
			2091.255,
			2092.968,
			2095.59,
			2097.354,
			2101.039,
			2101.97,
			2104.831,
			2106.762,
			2109.689,
			2111.658,
			2114.621,
			2116.607,
			2119.59,
			2122.589,
			2124.611,
			2126.647,
			2128.693,
			2130.749,
			2131.781,
			2134.856,
			2135.875,
			2136.896,
			2139.966,
			2142.022,
			2145.137,
			2147.231,
			2150.401,
			2152.53,
			2153.599,
			2155.755,
			2157.921,
			2159.012,
			2161.21,
			2163.418,
			2165.644,
			2169.003,
			2171.256,
			2173.518,
			2174.654,
			2178.078,
			2180.378,
			2182.69,
			2186.184,
			2188.527,
			2192.083,
			2193.274,
			2194.471,
			2199.306,
			2200.52,
			2204.152,
			2206.554,
			2210.116,
			2212.444,
			2214.741,
			2215.877,
			2218.122,
			2219.232,
			2221.443,
			2223.627,
			2226.887,
			2229.043,
			2232.271,
			2233.343,
			2234.415,
			2237.645,
			2239.807,
			2243.074,
			2245.259,
			2247.455,
			2248.553,
			2251.871,
			2254.091,
			2256.333,
			2257.462,
			2259.709,
			2261.961,
			2265.304,
			2267.433,
			2270.585,
			2271.605,
			2272.622,
			2275.667,
			2277.711,
			2280.773,
			2282.84,
			2285.938,
			2288.014,
			2289.051,
			2291.123,
			2293.191,
			2294.226,
			2296.287,
			2298.332,
			2301.392,
			2303.415,
			2307.469,
			2308.484,
			2311.548,
			2313.618,
			2316.752,
			2318.878,
			2322.086,
			2324.241,
			2325.322,
			2327.483,
			2330.738,
			2332.907,
			2335.082,
			2338.311,
			2340.458,
			2344.721,
			2345.79,
			2349.021,
			2351.188,
			2353.37,
			2356.661,
			2358.826,
			2363.205,
			2364.299,
			2367.594,
			2368.695,
			2369.798,
			2373.121,
			2375.344,
			2377.575,
			2378.692,
			2380.932,
			2384.312,
			2387.713,
			2389.999,
			2392.293,
			2395.758,
			2398.08,
			2402.753,
			2403.927,
			2407.462,
			2409.831,
			2413.401,
			2415.793,
			2418.194,
			2419.397,
			2421.811,
			2425.448,
			2429.104,
			2431.552,
			2434.009,
			2437.711,
			2440.189,
			2445.169,
			2446.418,
			2450.18,
			2452.697,
			2456.488,
			2459.024,
			2461.566,
			2462.839,
			2465.392,
			2469.237,
			2471.809,
			2473.097,
			2475.68,
			2478.268,
			2482.162,
			2484.76,
			2488.651,
			2489.95,
			2491.249,
			2495.145,
			2497.74,
			2501.638,
			2504.244,
			2506.863,
			2508.18,
			2510.821,
			2512.148,
			2513.479,
			2517.482,
			2518.82,
			2520.16,
			2524.183,
			2526.869,
			2530.893,
			2533.579,
			2536.27,
			2537.619,
			2541.684,
			2544.414,
			2547.152,
			2551.278,
			2554.035,
			2558.182,
			2560.953,
			2566.513,
			2567.907,
			2572.097,
			2574.898,
			2577.705,
			2579.111,
			2581.928,
			2584.751,
			2586.165,
			2590.415,
			2593.256,
			2596.102,
			2600.379,
			2603.236,
			2607.532,
			2610.406,
			2614.731,
			2617.627,
			2621.983,
			2624.895,
			2629.275,
			2632.203,
			2636.605,
			2641.02,
			2643.97,
			2646.926,
			2651.371,
			2654.342,
			2660.302,
			2661.796,
			2666.288,
			2669.289,
			2673.805,
			2676.826,
			2679.857,
			2681.375,
			2684.418,
			2688.996,
			2693.587,
			2696.653,
			2699.719,
			2704.326,
			2707.401,
			2712.021,
			2715.106,
			2719.744,
			2722.843,
			2727.499,
			2730.609,
			2735.283,
			2738.405,
			2743.081,
			2747.725,
			2750.787,
			2753.814,
			2758.284,
			2761.215,
			2766.961,
			2768.373,
			2772.552,
			2775.291,
			2779.326,
			2781.969,
			2785.863,
			2789.674,
			2790.925,
			2794.626,
			2795.841,
			2797.047,
			2800.613,
			2802.948,
			2806.388,
			2810.847,
			2811.938,
			2815.156,
			2817.254,
			2819.308,
			2822.324,
			2824.29,
			2827.173,
			2829.065,
			2831.838,
			2833.655,
			2836.324,
			2838.065,
			2839.788,
			2840.629,
			2843.915,
			2844.718,
			2846.302,
			2847.085,
			2848.629,
			2850.149,
			2852.391,
			2853.856,
			2856.031,
			2856.753,
			2857.474,
			2859.622,
			2861.054,
			2863.203,
			2864.632,
			2866.782,
			2868.938,
			2870.378,
			2871.823,
			2872.548,
			2874.017,
			2875.494,
			2877.717,
			2879.297,
			2881.709,
			2883.365,
			2886.205,
			2888.105,
			2891.277,
			2893.382,
			2896.387,
			2898.366,
			2899.251,
			2901.02,
			2903.627,
			2905.206,
			2906.788,
			2908.365,
			2909.842,
			2910.581,
			2912.063,
			2913.552,
			2914.303,
			2916.561,
			2918.073,
			2919.618,
			2921.955,
			2923.525,
			2925.934,
			2926.74,
			2927.55,
			2930.01,
			2930.839,
			2931.67,
			2934.184,
			2935.882,
			2937.593,
			2938.453,
			2940.189,
			2941.94,
			2942.82,
			2945.466,
			2947.271,
			2949.073,
			2951.786,
			2953.618,
			2956.389,
			2958.25,
			2962.013,
			2962.962,
			2965.826,
			2967.75,
			2970.657,
			2972.609,
			2975.558,
			2978.535,
			2980.533,
			2982.543,
			2985.578,
			2987.613,
			2990.687,
			2991.719,
			2992.754,
			2995.875,
			2997.97,
			3001.143,
			3003.249,
			3006.401,
			3008.492,
			3010.562,
			3011.589,
			3013.627,
			3014.635,
			3016.608,
			3018.598,
			3021.492,
			3023.422,
			3027.243,
			3028.205,
			3031.08,
			3033.033,
			3036.039,
			3038.063,
			3041.158,
			3043.199,
			3044.199,
			3046.197,
			3048.169,
			3049.121,
			3051.024,
			3052.925,
			3055.739,
			3057.618,
			3060.406,
			3061.334,
			3062.264,
			3065.04,
			3066.886,
			3069.668,
			3071.534,
			3073.403,
			3074.344,
			3077.195,
			3079.1,
			3081.027,
			3081.992,
			3083.928,
			3085.85,
			3088.74,
			3090.657,
			3092.569,
			3094.489,
			3095.448,
			3098.332,
			3100.268,
			3102.22,
			3105.17,
			3108.136,
			3111.125,
			3112.122,
			3116.138,
			3117.143,
			3120.172,
			3122.201,
			3125.259,
			3129.381,
			3130.413,
			3132.488,
			3133.531,
			3135.608,
			3137.714,
			3140.894,
			3143.033,
			3147.347,
			3148.433,
			3151.708,
			3152.806,
			3153.907,
			3157.227,
			3159.453,
			3162.813,
			3165.069,
			3166.201,
			3168.471,
			3171.893,
			3174.184,
			3176.485,
			3179.953,
			3182.276,
			3185.776,
			3188.123,
			3191.679,
			3194.029,
			3197.594,
			3199.965,
			3203.482,
			3205.795,
			3206.938,
			3209.199,
			3211.426,
			3212.528,
			3214.705,
			3216.848,
			3220.008,
			3222.069,
			3226.09,
			3227.073,
			3229.969,
			3231.854,
			3234.617,
			3236.418,
			3239.058,
			3241.618,
			3243.279,
			3244.904,
			3245.703,
			3247.275,
			3248.811,
			3251.047,
			3252.495,
			3253.906,
			3255.285,
			3255.964,
			3257.976,
			3259.287,
			3260.583,
			3262.494,
			3263.756,
			3265.641,
			3266.26,
			3266.874,
			3269.326,
			3269.938,
			3271.771,
			3272.988,
			3274.814,
			3277.272,
			3277.891,
			3279.13,
			3279.75,
			3280.99,
			3282.266,
			3282.907,
			3284.19,
			3285.472,
			3287.48,
			3288.858,
			3290.921,
			3292.412,
			3294.792,
			3296.377,
			3298.957,
			3302.344,
			3303.103,
			3305.384,
			3306.908,
			3308.273,
			3310.329,
			3311.706,
			3314.468,
			3315.162,
			3317.256,
			3318.662,
			3320.786,
			3322.214,
			3324.348,
			3325.784,
			3327.958,
			3330.151,
			3331.63,
			3333.124,
			3335.395,
			3336.927,
			3339.236,
			3340.794,
			3343.125,
			3344.66,
			3346.914,
			3348.377,
			3349.807,
			3351.886,
			3352.562,
			3353.242,
			3355.846,
			3357.113,
			3358.365,
			3360.221,
			3360.834,
			3361.446,
			3363.277,
			3363.881,
			3364.485,
			3366.3,
			3367.517,
			3368.735,
			3370.548,
			3371.764,
			3373.601,
			3374.828,
			3377.292,
			3377.91,
			3379.869,
			3381.191,
			3382.509,
			3383.166,
			3384.633,
			3386.137,
			3386.885,
			3389.237,
			3391.018,
			3392.787,
			3395.756,
			3397.817,
			3400.698,
			3402.55,
			3404.894,
			3406.224,
			3408.196,
			3409.396,
			3411.051,
			3413.227,
			3413.767,
			3415.363,
			3416.415,
			3417.46,
			3419.016,
			3420.072,
			3421.643,
			3422.683,
			3424.233,
			3425.285,
			3426.865,
			3427.908,
			3429.479,
			3430.538,
			3432.148,
			3433.782,
			3434.897,
			3436.033,
			3437.769,
			3438.356,
			3438.947,
			3440.748,
			3441.97,
			3443.835,
			3445.102,
			3447.039,
			3448.35,
			3449.679,
			3451.708,
			3453.772,
			3455.871,
			3456.578,
			3458.722,
			3460.172,
			3462.37,
			3463.853,
			3466.107,
			3468.399,
			3469.945,
			3471.506,
			3472.293,
			3473.877,
			3475.478,
			3478.714,
			3479.527,
			3481.993,
			3483.629,
			3486.13,
			3487.806,
			3490.322,
			3493.717,
			3494.574,
			3497.163,
			3498.907,
			3500.664,
			3503.325,
			3505.114,
			3507.824,
			3509.648,
			3512.408,
			3514.265,
			3517.076,
			3518.966,
			3520.869,
			3521.825,
			3523.748,
			3526.655,
			3529.59,
			3531.562,
			3533.548,
			3536.548,
			3538.563,
			3541.609,
			3543.654,
			3546.746,
			3548.822,
			3551.957,
			3554.05,
			3556.145,
			3557.196,
			3559.306,
			3562.492,
			3565.702,
			3567.854,
			3570.018,
			3573.284,
			3575.474,
			3579.887,
			3580.996,
			3584.341,
			3586.583,
			3589.967,
			3592.235,
			3595.658,
			3599.103,
			3601.413,
			3604.897,
			3606.063,
			3609.578,
			3611.935,
			3615.488,
			3617.87,
			3621.461,
			3625.074,
			3627.494,
			3629.924,
			3633.587,
			3636.042,
			3639.74,
			3642.218,
			3645.953,
			3648.454,
			3652.222,
			3654.746,
			3657.278,
			3658.548,
			3661.094,
			3663.649,
			3664.93,
			3668.785,
			3671.364,
			3673.951,
			3677.847,
			3680.451,
			3684.361,
			3685.668,
			3686.978,
			3690.917,
			3693.554,
			3697.525,
			3700.183,
			3704.183,
			3706.86,
			3709.545,
			3710.89,
			3713.586,
			3714.937,
			3717.645,
			3720.36,
			3724.448,
			3727.184,
			3731.301,
			3732.677,
			3734.054,
			3738.199,
			3740.972,
			3745.145,
			3747.936,
			3750.734,
			3752.137,
			3754.947,
			3756.354,
			3759.176,
			3763.421,
			3766.261,
			3769.108,
			3773.392,
			3776.257,
			3780.568,
			3783.452,
			3787.79,
			3790.69,
			3795.055,
			3797.973,
			3802.364,
			3805.3,
			3806.771,
			3809.717,
			3812.67,
			3814.149,
			3817.113,
			3820.084,
			3824.553,
			3827.54,
			3830.534,
			3833.534,
			3835.037,
			3839.556,
			3842.576,
			3845.603,
			3850.157,
			3853.2,
			3854.724,
			3857.777,
			3860.836,
			3865.437,
			3866.973,
			3868.512,
			3873.137,
			3876.228,
			3880.875,
			3883.981,
			3887.092,
			3888.65,
			3893.332,
			3896.461,
			3899.596,
			3904.308,
			3907.457,
			3912.191,
			3915.354,
			3920.11,
			3923.287,
			3928.06,
			3931.244,
			3936.03,
			3939.228,
			3942.43,
			3944.033,
			3948.85,
			3952.066,
			3955.288,
			3960.129,
			3963.364,
			3969.846,
			3971.47,
			3976.349,
			3979.607,
			3984.506,
			3987.776,
			3992.691,
			3995.974,
			4000.906,
			4005.849,
			4009.151,
			4012.457,
			4017.427,
			4020.746,
			4027.397,
			4029.062,
			4034.067,
			4037.409,
			4042.43,
			4045.784,
			4050.823,
			4054.188,
			4055.872,
			4059.243,
			4062.62,
			4064.31,
			4067.693,
			4071.081,
			4076.171,
			4079.571,
			4084.679,
			4086.384,
			4088.09,
			4093.214,
			4096.636,
			4101.777,
			4105.21,
			4108.647,
			4110.368,
			4113.811,
			4115.535,
			4118.985,
			4124.169,
			4125.899,
			4131.096,
			4134.565,
			4139.776,
			4143.256,
			4146.74,
			4148.483,
			4151.972,
			4153.718,
			4157.213,
			4160.712,
			4165.967,
			4169.475,
			4174.745,
			4176.502,
			4178.26,
			4183.53,
			4187.042,
			4192.31,
			4195.833,
			4199.364,
			4201.133,
			4204.675,
			4208.224,
			4210,
			4215.337,
			4218.902,
			4222.474,
			4227.834,
			4231.411,
			4236.78,
			4240.364,
			4245.748,
			4249.339,
			4254.73,
			4258.316,
			4263.66,
			4267.185,
			4272.399,
			4277.524,
			4280.892,
			4284.22,
			4289.14,
			4292.371,
			4298.716,
			4300.278,
			4304.909,
			4307.958,
			4312.481,
			4315.453,
			4319.831,
			4322.681,
			4326.876,
			4330.978,
			4333.672,
			4336.328,
			4340.248,
			4342.816,
			4346.605,
			4347.85,
			4349.086,
			4352.741,
			4355.132,
			4358.653,
			4360.958,
			4364.354,
			4366.591,
			4367.688,
			4369.854,
			4373.045,
			4375.137,
			4377.209,
			4379.273,
			4381.314,
			4382.345,
			4384.429,
			4387.551,
			4390.683,
			4392.782,
			4394.884,
			4398.056,
			4400.177,
			4404.437,
			4405.505,
			4408.71,
			4409.779,
			4410.851,
			4414.068,
			4416.215,
			4418.364,
			4419.437,
			4421.584,
			4423.733,
			4424.807,
			4428.036,
			4430.19,
			4432.353,
			4435.602,
			4437.777,
			4441.041,
			4442.132,
			4443.228,
			4446.508,
			4448.697,
			4451.975,
			4454.134,
			4457.366,
			4459.487,
			4462.661,
			4465.803,
			4467.894,
			4469.987,
			4473.123,
			4475.22,
			4479.408,
			4480.456,
			4483.598,
			4485.701,
			4488.851,
			4490.96,
			4493.062,
			4494.11,
			4496.244,
			4497.311,
			4499.436,
			4502.706,
			4504.883,
			4507.071,
			4510.349,
			4512.463,
			4515.622,
			4517.679,
			4520.768,
			4522.856,
			4525.993,
			4528.102,
			4530.211,
			4533.336,
			4535.411,
			4539.522,
			4540.548,
			4543.64,
			4545.708,
			4547.787,
			4548.832,
			4550.927,
			4553.035,
			4554.101,
			4557.302,
			4559.486,
			4561.684,
			4565,
			4567.217,
			4570.501,
			4571.593,
			4572.687,
			4575.922,
			4578.072,
			4581.319,
			4583.485,
			4585.7,
			4586.808,
			4589.021,
			4592.375,
			4595.698,
			4597.891,
			4600.064,
			4603.237,
			4605.341,
			4609.5,
			4610.534,
			4613.628,
			4615.681,
			4618.748,
			4620.784,
			4623.823,
			4625.844,
			4628.872,
			4630.891,
			4631.894,
			4633.896,
			4635.901,
			4638.928,
			4640.95,
			4644.051,
			4646.131,
			4649.293,
			4651.408,
			4654.585,
			4656.705,
			4659.921,
			4662.073,
			4665.389,
			4668.742,
			4670.998,
			4673.251,
			4676.626,
			4677.749,
			4678.864,
			4682.201,
			4683.287,
			4684.366,
			4687.634,
			4689.713,
			4692.842,
			4694.898,
			4696.964,
			4700.086,
			4702.182,
			4706.412,
			4708.543,
			4710.688,
			4712.823,
			4714.991,
			4716.079,
			4718.263,
			4720.48,
			4721.586,
			4724.917,
			4727.136,
			4729.384,
			4732.784,
			4735.062,
			4738.498,
			4740.799,
			4750.089,
			4752.435,
			4755.971,
			4760.735,
			4761.907,
			4765.495,
			4767.897,
			4770.308,
			4773.943,
			4776.376,
			4781.265,
			4782.492,
			4786.184,
			4788.654,
			4792.374,
			4794.861,
			4798.605,
			4801.111,
			4804.901,
			4808.656,
			4811.195,
			4813.714,
			4817.48,
			4820.016,
			4825.131,
			4826.408,
			4830.22,
			4832.756,
			4836.557,
			4839.091,
			4841.645,
			4842.922,
			4845.484,
			4849.335,
			4853.172,
			4855.772,
			4858.333,
			4862.215,
			4864.811,
			4868.717,
			4871.33,
			4875.264,
			4877.896,
			4881.858,
			4884.509,
			4888.498,
			4891.166,
			4895.185,
			4899.223,
			4900.572,
			4904.634,
			4908.712,
			4910.075,
			4911.439,
			4914.174,
			4918.291,
			4922.425,
			4925.19,
			4927.963,
			4932.125,
			4934.905,
			4939.088,
			4941.883,
			4947.496,
			4948.903,
			4953.135,
			4955.964,
			4960.221,
			4965.921,
			4967.351,
			4971.647,
			4974.52,
			4977.398,
			4981.728,
			4984.624,
			4988.981,
			4990.438,
			4991.896,
			4996.277,
			4999.206,
			5003.609,
			5006.552,
			5009.502,
			5010.979,
			5013.938,
			5015.42,
			5018.387,
			5022.839,
			5025.81,
			5028.788,
			5033.275,
			5036.279,
			5040.797,
			5043.817,
			5048.37,
			5051.417,
			5055.975,
			5058.988,
			5063.511,
			5066.597,
			5068.156,
			5071.278,
			5073.261
		],
		[
			0,
			0.052,
			0.096,
			0.112,
			0.177,
			0.207,
			0.239,
			0.318,
			0.365,
			0.428,
			0.487,
			0.535,
			0.611,
			0.674,
			0.758,
			0.799,
			0.86,
			0.924,
			0.986,
			1.049,
			1.11,
			1.188,
			1.235,
			1.296,
			1.36,
			1.406,
			1.485,
			1.546,
			1.624,
			1.669,
			1.734,
			1.793,
			1.857,
			1.92,
			1.951,
			1.98,
			2.044,
			2.06,
			2.107,
			2.17,
			2.23,
			2.277,
			2.387,
			2.419,
			2.48,
			2.543,
			2.605,
			2.668,
			2.73,
			2.791,
			2.854,
			2.93,
			2.979,
			3.042,
			3.104,
			3.165,
			3.257,
			3.289,
			3.369,
			3.415,
			3.478,
			3.539,
			3.602,
			3.694,
			3.726,
			3.79,
			3.804,
			3.851,
			3.912,
			3.975,
			4.039,
			4.101,
			4.13,
			4.162,
			4.226,
			4.241,
			4.288,
			4.351,
			4.412,
			4.473,
			4.538,
			4.568,
			4.599,
			4.66,
			4.677,
			4.723,
			4.784,
			4.848,
			4.91,
			5.005,
			5.034,
			5.098,
			5.113,
			5.16,
			5.223,
			5.284,
			5.33,
			5.409,
			5.44,
			5.472,
			5.533,
			5.595,
			5.657,
			5.719,
			5.783,
			5.876,
			5.906,
			5.985,
			6.032,
			6.095,
			6.157,
			6.219,
			6.311,
			6.343,
			6.406,
			6.469,
			6.53,
			6.588,
			6.655,
			6.714,
			6.778,
			6.857,
			6.901,
			6.969,
			7.028,
			7.074,
			7.09,
			7.152,
			7.215,
			7.293,
			7.34,
			7.402,
			7.463,
			7.51,
			7.589,
			7.65,
			7.728,
			7.776,
			7.838,
			7.901,
			7.948,
			7.964,
			8.024,
			8.087,
			8.166,
			8.212,
			8.275,
			8.337,
			8.383,
			8.461,
			8.524,
			8.6,
			8.646,
			8.71,
			8.773,
			8.836,
			8.898,
			8.928,
			8.96,
			9.042,
			9.085,
			9.146,
			9.21,
			9.255,
			9.335,
			9.364,
			9.396,
			9.474,
			9.522,
			9.583,
			9.644,
			9.707,
			9.768,
			9.801,
			9.83,
			9.893,
			9.909,
			9.955,
			10.019,
			10.082,
			10.142,
			10.205,
			10.238,
			10.269,
			10.346,
			10.393,
			10.456,
			10.518,
			10.561,
			10.579,
			10.641,
			10.674,
			10.703,
			10.78,
			10.828,
			10.891,
			10.953,
			11,
			11.016,
			11.078,
			11.141,
			11.217,
			11.263,
			11.327,
			11.39,
			11.435,
			11.452,
			11.515,
			11.575,
			11.653,
			11.699,
			11.767,
			11.826,
			11.887,
			11.95,
			11.979,
			12.011,
			12.074,
			12.09,
			12.136,
			12.2,
			12.262,
			12.309,
			12.385,
			12.416,
			12.446,
			12.512,
			12.573,
			12.636,
			12.696,
			12.758,
			12.823,
			12.853,
			12.885,
			12.963,
			13.009,
			13.072,
			13.134,
			13.195,
			13.29,
			13.322,
			13.384,
			13.446,
			13.507,
			13.571,
			13.631,
			13.725,
			13.758,
			13.835,
			13.882,
			13.943,
			14.007,
			14.069,
			14.16,
			14.194,
			14.255,
			14.317,
			14.378,
			14.442,
			14.502,
			14.556,
			14.628,
			14.706,
			14.752,
			14.816,
			14.877,
			14.94,
			15.035,
			15.064,
			15.127,
			15.141,
			15.191,
			15.258,
			15.313,
			15.376,
			15.44,
			15.469,
			15.502,
			15.561,
			15.578,
			15.625,
			15.689,
			15.75,
			15.813,
			15.874,
			15.937,
			16.015,
			16.062,
			16.123,
			16.186,
			16.249,
			16.341,
			16.373,
			16.452,
			16.499,
			16.56,
			16.621,
			16.683,
			16.776,
			16.808,
			16.871,
			16.887,
			16.933,
			17,
			17.057,
			17.121,
			17.213,
			17.245,
			17.308,
			17.327,
			17.37,
			17.433,
			17.495,
			17.556,
			17.62,
			17.65,
			17.68,
			17.76,
			17.805,
			17.869,
			17.932,
			17.977,
			18.055,
			18.119,
			18.196,
			18.243,
			18.307,
			18.366,
			18.429,
			18.492,
			18.555,
			18.633,
			18.679,
			18.742,
			18.804,
			18.866,
			18.927,
			18.991,
			19.068,
			19.116,
			19.179,
			19.241,
			19.286,
			19.302,
			19.365,
			19.428,
			19.504,
			19.552,
			19.616,
			19.676,
			19.724,
			19.74,
			19.803,
			19.863,
			19.942,
			19.99,
			20.051,
			20.113,
			20.175,
			20.268,
			20.3,
			20.363,
			20.379,
			20.424,
			20.487,
			20.55,
			20.596,
			20.613,
			20.675,
			20.737,
			20.813,
			20.86,
			20.923,
			20.985,
			21.049,
			21.112,
			21.142,
			21.172,
			21.25,
			21.299,
			21.36,
			21.422,
			21.474,
			21.486,
			21.548,
			21.577,
			21.609,
			21.686,
			21.735,
			21.797,
			21.857,
			21.921,
			21.984,
			22.015,
			22.046,
			22.107,
			22.171,
			22.235,
			22.296,
			22.34,
			22.358,
			22.421,
			22.451,
			22.483,
			22.56,
			22.607,
			22.67,
			22.729,
			22.794,
			22.854,
			22.887,
			22.918,
			22.982,
			23.043,
			23.105,
			23.168,
			23.229,
			23.323,
			23.355,
			23.433,
			23.479,
			23.545,
			23.605,
			23.667,
			23.761,
			23.79,
			23.853,
			23.916,
			23.978,
			24.04,
			24.102,
			24.162,
			24.195,
			24.226,
			24.289,
			24.306,
			24.352,
			24.416,
			24.478,
			24.539,
			24.634,
			24.664,
			24.742,
			24.787,
			24.849,
			24.912,
			24.974,
			25.07,
			25.1,
			25.162,
			25.177,
			25.224,
			25.287,
			25.35,
			25.412,
			25.474,
			25.506,
			25.536,
			25.598,
			25.619,
			25.66,
			25.724,
			25.784,
			25.847,
			25.942,
			25.974,
			26.049,
			26.098,
			26.16,
			26.221,
			26.268,
			26.284,
			26.348,
			26.41,
			26.486,
			26.535,
			26.595,
			26.658,
			26.721,
			26.783,
			26.845,
			26.923,
			26.971,
			27.03,
			27.094,
			27.157,
			27.218,
			27.282,
			27.359,
			27.407,
			27.467,
			27.529,
			27.593,
			27.691,
			27.719,
			27.779,
			27.842,
			27.907,
			27.969,
			28.03,
			28.093,
			28.153,
			28.217,
			28.233,
			28.279,
			28.344,
			28.404,
			28.467,
			28.529,
			28.559,
			28.591,
			28.669,
			28.715,
			28.776,
			28.84,
			28.901,
			28.995,
			29.027,
			29.088,
			29.105,
			29.152,
			29.215,
			29.277,
			29.339,
			29.401,
			29.432,
			29.463,
			29.525,
			29.54,
			29.588,
			29.649,
			29.712,
			29.775,
			29.837,
			29.868,
			29.9,
			29.959,
			29.977,
			30.023,
			30.085,
			30.147,
			30.21,
			30.304,
			30.333,
			30.395,
			30.413,
			30.459,
			30.521,
			30.583,
			30.646,
			30.849,
			30.894,
			30.957,
			31.02,
			31.08,
			31.143,
			31.174,
			31.207,
			31.284,
			31.331,
			31.394,
			31.454,
			31.501,
			31.518,
			31.579,
			31.639,
			31.704,
			31.763,
			31.83,
			31.89,
			31.952,
			32.013,
			32.045,
			32.078,
			32.155,
			32.201,
			32.265,
			32.328,
			32.374,
			32.387,
			32.452,
			32.483,
			32.512,
			32.59,
			32.638,
			32.7,
			32.761,
			32.81,
			32.888,
			32.917,
			32.949,
			33.011,
			33.028,
			33.073,
			33.138,
			33.196,
			33.262,
			33.322,
			33.354,
			33.387,
			33.462,
			33.509,
			33.574,
			33.633,
			33.68,
			33.759,
			33.79,
			33.82,
			33.904,
			33.945,
			34.007,
			34.07,
			34.117,
			34.13,
			34.195,
			34.256,
			34.334,
			34.382,
			34.442,
			34.504,
			34.553,
			34.629,
			34.661,
			34.693,
			34.77,
			34.817,
			34.879,
			34.941,
			35.005,
			35.066,
			35.098,
			35.13,
			35.207,
			35.254,
			35.319,
			35.378,
			35.424,
			35.44,
			35.504,
			35.562,
			35.627,
			35.689,
			35.753,
			35.815,
			35.876,
			35.941,
			35.974,
			36.002,
			36.079,
			36.126,
			36.188,
			36.252,
			36.313,
			36.407,
			36.44,
			36.502,
			36.563,
			36.625,
			36.687,
			36.749,
			36.843,
			36.873,
			36.954,
			37,
			37.061,
			37.123,
			37.186,
			37.28,
			37.312,
			37.372,
			37.39,
			37.436,
			37.499,
			37.559,
			37.622,
			37.685,
			37.746,
			37.824,
			37.871,
			37.934,
			37.996,
			38.06,
			38.153,
			38.184,
			38.261,
			38.309,
			38.371,
			38.434,
			38.494,
			38.589,
			38.62,
			38.683,
			38.745,
			38.807,
			38.87,
			38.932,
			38.995,
			39.057,
			39.136,
			39.179,
			39.243,
			39.307,
			39.368,
			39.432,
			39.493,
			39.569,
			39.617,
			39.683,
			39.741,
			39.79,
			39.804,
			39.867,
			39.93,
			40.006,
			40.055,
			40.121,
			40.179,
			40.226,
			40.242,
			40.304,
			40.363,
			40.442,
			40.49,
			40.551,
			40.612,
			40.674,
			40.769,
			40.801,
			40.879,
			40.924,
			40.987,
			41.05,
			41.113,
			41.204,
			41.237,
			41.3,
			41.315,
			41.362,
			41.424,
			41.487,
			41.533,
			41.609,
			41.673,
			41.75,
			41.798,
			41.86,
			41.922,
			41.983,
			42.077,
			42.109,
			42.191,
			42.23,
			42.294,
			42.356,
			42.42,
			42.514,
			42.544,
			42.607,
			42.668,
			42.73,
			42.793,
			42.855,
			42.918,
			42.981,
			43.058,
			43.103,
			43.168,
			43.23,
			43.275,
			43.291,
			43.354,
			43.385,
			43.417,
			43.479,
			43.539,
			43.604,
			43.666,
			43.729,
			43.79,
			43.822,
			43.852,
			43.913,
			43.928,
			43.977,
			44.044,
			44.101,
			44.165,
			44.226,
			44.262,
			44.289,
			44.367,
			44.414,
			44.476,
			44.537,
			44.6,
			44.663,
			44.694,
			44.723,
			44.788,
			44.851,
			44.913,
			44.975,
			45.038,
			45.1,
			45.16,
			45.223,
			45.24,
			45.287,
			45.35,
			45.41,
			45.457,
			45.535,
			45.566,
			45.597,
			45.674,
			45.72,
			45.787,
			45.847,
			45.91,
			46.003,
			46.035,
			46.097,
			46.113,
			46.158,
			46.221,
			46.283,
			46.345,
			46.407,
			46.438,
			46.469,
			46.532,
			46.594,
			46.657,
			46.717,
			46.766,
			46.781,
			46.874,
			46.907,
			46.969,
			46.985,
			47.029,
			47.093,
			47.155,
			47.216,
			47.28,
			47.309,
			47.342,
			47.405,
			47.466,
			47.529,
			47.59,
			47.652,
			47.746,
			47.777,
			47.855,
			47.902,
			47.963,
			48.027,
			48.074,
			48.09,
			48.152,
			48.214,
			48.292,
			48.339,
			48.406,
			48.463,
			48.51,
			48.589,
			48.649,
			48.727,
			48.774,
			48.838,
			48.899,
			48.946,
			48.962,
			49.025,
			49.054,
			49.086,
			49.147,
			49.164,
			49.21,
			49.273,
			49.336,
			49.399,
			49.46,
			49.492,
			49.523,
			49.601,
			49.646,
			49.709,
			49.772,
			49.818,
			49.833,
			49.927,
			49.957,
			50.019,
			50.038,
			50.084,
			50.146,
			50.208,
			50.269,
			50.332,
			50.364,
			50.395,
			50.458,
			50.521,
			50.583,
			50.644,
			50.706,
			50.77,
			50.8,
			50.83,
			50.909,
			50.955,
			51.019,
			51.081,
			51.129,
			51.205,
			51.236,
			51.268,
			51.346,
			51.391,
			51.456,
			51.516,
			51.578,
			51.642,
			51.672,
			51.704,
			51.782,
			51.829,
			51.892,
			51.954,
			52.013,
			52.078,
			52.109,
			52.141,
			52.217,
			52.263,
			52.327,
			52.39,
			52.437,
			52.452,
			52.516,
			52.551,
			52.576,
			52.654,
			52.701,
			52.763,
			52.825,
			52.873,
			52.889,
			52.949,
			53.013,
			53.089,
			53.137,
			53.199,
			53.26,
			53.307,
			53.385,
			53.449,
			53.527,
			53.572,
			53.635,
			53.695,
			53.759,
			53.821,
			53.883,
			53.96,
			54.006,
			54.069,
			54.132,
			54.194,
			54.288,
			54.319,
			54.395,
			54.442,
			54.505,
			54.564,
			54.629,
			54.692,
			54.755,
			54.833,
			54.879,
			54.943,
			55.002,
			55.05,
			55.066,
			55.127,
			55.16,
			55.191,
			55.268,
			55.316,
			55.379,
			55.44,
			55.503,
			55.562,
			55.596,
			55.628,
			55.69,
			55.705,
			55.752,
			55.812,
			55.875,
			55.937,
			56,
			56.03,
			56.063,
			56.141,
			56.188,
			56.25,
			56.312,
			56.358,
			56.439,
			56.468,
			56.499,
			56.577,
			56.624,
			56.691,
			56.747,
			56.81,
			56.873,
			56.905,
			56.936,
			56.997,
			57.012,
			57.059,
			57.123,
			57.186,
			57.249,
			57.309,
			57.341,
			57.371,
			57.449,
			57.495,
			57.559,
			57.621,
			57.668,
			57.684,
			57.775,
			57.807,
			57.87,
			57.886,
			57.933,
			57.996,
			58.056,
			58.12,
			58.183,
			58.213,
			58.243,
			58.306,
			58.369,
			58.43,
			58.494,
			58.556,
			58.619,
			58.65,
			58.678,
			58.741,
			58.761,
			58.803,
			58.868,
			58.928,
			58.991,
			59.086,
			59.117,
			59.179,
			59.241,
			59.305,
			59.366,
			59.427,
			59.491,
			59.523,
			59.554,
			59.631,
			59.677,
			59.739,
			59.802,
			59.863,
			59.958,
			59.989,
			60.052,
			60.114,
			60.175,
			60.238,
			60.3,
			60.394,
			60.424,
			60.486,
			60.503,
			60.547,
			60.612,
			60.673,
			60.721,
			60.738,
			60.799,
			60.862,
			60.938,
			60.986,
			61.047,
			61.109,
			61.173,
			61.267,
			61.298,
			61.36,
			61.421,
			61.486,
			61.546,
			61.589,
			61.609,
			61.672,
			61.733,
			61.811,
			61.858,
			61.919,
			61.982,
			62.045,
			62.137,
			62.169,
			62.247,
			62.295,
			62.358,
			62.42,
			62.466,
			62.482,
			62.543,
			62.606,
			62.667,
			62.683,
			62.728,
			62.793,
			62.854,
			62.905,
			62.978,
			63.01,
			63.041,
			63.119,
			63.166,
			63.229,
			63.289,
			63.337,
			63.352,
			63.415,
			63.445,
			63.476,
			63.538,
			63.555,
			63.6,
			63.663,
			63.725,
			63.787,
			63.85,
			63.882,
			63.912,
			63.99,
			64.037,
			64.101,
			64.162,
			64.225,
			64.287,
			64.349,
			64.427,
			64.475,
			64.538,
			64.599,
			64.646,
			64.661,
			64.722,
			64.754,
			64.784,
			64.862,
			64.909,
			64.976,
			65.035,
			65.082,
			65.159,
			65.221,
			65.299,
			65.345,
			65.409,
			65.471,
			65.533,
			65.595,
			65.657,
			65.736,
			65.783,
			65.846,
			65.906,
			65.97,
			66.062,
			66.094,
			66.173,
			66.219,
			66.28,
			66.345,
			66.392,
			66.407,
			66.469,
			66.53,
			66.608,
			66.655,
			66.718,
			66.779,
			66.843,
			66.905,
			66.967,
			67.049,
			67.092,
			67.156,
			67.217,
			67.278,
			67.341,
			67.403,
			67.483,
			67.527,
			67.59,
			67.653,
			67.713,
			67.807,
			67.837,
			67.902,
			67.964,
			68.027,
			68.089,
			68.152,
			68.245,
			68.276,
			68.337,
			68.354,
			68.4,
			68.463,
			68.526,
			68.585,
			68.679,
			68.712,
			68.789,
			68.835,
			68.9,
			68.961,
			69.023,
			69.087,
			69.149,
			69.211,
			69.273,
			69.335,
			69.398,
			69.445,
			69.458,
			69.553,
			69.584,
			69.645,
			69.66,
			69.709,
			69.772,
			69.833,
			69.894,
			69.956,
			69.988,
			70.018,
			70.095,
			70.145,
			70.206,
			70.269,
			70.329,
			70.424,
			70.454,
			70.519,
			70.534,
			70.579,
			70.643,
			70.702,
			70.766,
			70.828,
			70.891,
			70.968,
			71.013,
			71.077,
			71.14,
			71.203,
			71.265,
			71.297,
			71.327,
			71.404,
			71.452,
			71.513,
			71.576,
			71.622,
			71.638,
			71.702,
			71.733,
			71.761,
			71.841,
			71.888,
			71.95,
			72.012,
			72.074,
			72.138,
			72.169,
			72.2,
			72.262,
			72.277,
			72.326,
			72.388,
			72.45,
			72.496,
			72.512,
			72.574,
			72.607,
			72.638,
			72.715,
			72.763,
			72.824,
			72.885,
			72.933,
			73.012,
			73.073,
			73.152,
			73.199,
			73.265,
			73.322,
			73.384,
			73.446,
			73.509,
			73.588,
			73.635,
			73.696,
			73.759,
			73.82,
			73.884,
			73.913,
			73.945,
			74.009,
			74.07,
			74.135,
			74.194,
			74.258,
			74.32,
			74.352,
			74.38,
			74.444,
			74.462,
			74.508,
			74.571,
			74.631,
			74.694,
			74.788,
			74.818,
			74.883,
			74.945,
			75.006,
			75.069,
			75.132,
			75.195,
			75.224,
			75.257,
			75.317,
			75.338,
			75.379,
			75.443,
			75.505,
			75.568,
			75.629,
			75.66,
			75.691,
			75.769,
			75.817,
			75.878,
			75.942,
			75.987,
			76.004,
			76.098,
			76.13,
			76.192,
			76.207,
			76.252,
			76.316,
			76.379,
			76.441,
			76.502,
			76.535,
			76.563,
			76.642,
			76.69,
			76.751,
			76.813,
			76.877,
			76.97,
			77.001,
			77.08,
			77.124,
			77.188,
			77.251,
			77.312,
			77.41,
			77.438,
			77.499,
			77.515,
			77.561,
			77.624,
			77.687,
			77.749,
			77.841,
			77.874,
			77.937,
			77.952,
			77.998,
			78.06,
			78.121,
			78.185,
			78.246,
			78.277,
			78.309,
			78.386,
			78.435,
			78.495,
			78.558,
			78.62,
			78.682,
			78.744,
			78.822,
			78.868,
			78.932,
			78.995,
			79.057,
			79.12,
			79.15,
			79.18,
			79.243,
			79.259,
			79.305,
			79.369,
			79.43,
			79.492,
			79.586,
			79.617,
			79.694,
			79.741,
			79.805,
			79.867,
			79.928,
			80.023,
			80.053,
			80.115,
			80.131,
			80.177,
			80.24,
			80.302,
			80.365,
			80.425,
			80.459,
			80.49,
			80.567,
			80.612,
			80.676,
			80.739,
			80.801,
			80.863,
			80.893,
			80.924,
			81.002,
			81.049,
			81.112,
			81.174,
			81.235,
			81.33,
			81.361,
			81.422,
			81.437,
			81.485,
			81.548,
			81.554,
			81.609,
			81.674,
			81.736,
			81.798,
			81.874,
			81.923,
			81.985,
			82.048,
			82.11,
			82.202,
			82.234,
			82.312,
			82.357,
			82.422,
			82.484,
			82.546,
			82.639,
			82.67,
			82.73,
			82.793,
			82.857,
			82.917,
			82.978,
			83.042,
			83.105,
			83.183,
			83.231,
			83.291,
			83.355,
			83.402,
			83.478,
			83.542,
			83.604,
			83.666,
			83.727,
			83.79,
			83.838,
			83.916,
			83.946,
			83.977,
			84.055,
			84.103,
			84.166,
			84.228,
			84.272,
			84.29,
			84.353,
			84.384,
			84.413,
			84.492,
			84.537,
			84.602,
			84.662,
			84.709,
			84.786,
			84.85,
			84.927,
			84.973,
			85.038,
			85.099,
			85.145,
			85.16,
			85.224,
			85.255,
			85.287,
			85.365,
			85.412,
			85.472,
			85.534,
			85.597,
			85.658,
			85.721,
			85.801,
			85.845,
			85.91,
			85.972,
			86.035,
			86.127,
			86.157,
			86.235,
			86.283,
			86.347,
			86.409,
			86.455,
			86.533,
			86.594,
			86.672,
			86.719,
			86.779,
			86.843,
			86.904,
			86.967,
			87.029,
			87.108,
			87.154,
			87.216,
			87.279,
			87.324,
			87.342,
			87.405,
			87.467,
			87.544,
			87.591,
			87.653,
			87.713,
			87.776,
			87.839,
			87.902,
			87.978,
			88.027,
			88.089,
			88.151,
			88.213,
			88.274,
			88.338,
			88.415,
			88.463,
			88.524,
			88.53,
			88.586,
			88.649,
			88.741,
			88.773,
			88.85,
			88.897,
			88.962,
			89.024,
			89.085,
			89.178,
			89.211,
			89.287,
			89.335,
			89.399,
			89.46,
			89.507,
			89.584,
			89.645,
			89.723,
			89.771,
			89.833,
			89.895,
			89.94,
			89.959,
			90.019,
			90.082,
			90.161,
			90.207,
			90.268,
			90.332,
			90.379,
			90.456,
			90.52,
			90.596,
			90.642,
			90.706,
			90.767,
			90.812,
			90.831,
			90.891,
			90.954,
			91.03,
			91.079,
			91.141,
			91.203,
			91.266,
			91.36,
			91.391,
			91.469,
			91.517,
			91.578,
			91.64,
			91.702,
			91.794,
			91.827,
			91.91,
			91.951,
			92.012,
			92.074,
			92.138,
			92.2,
			92.263,
			92.342,
			92.387,
			92.45,
			92.511,
			92.557,
			92.638,
			92.696,
			92.776,
			92.823,
			92.89,
			92.949,
			92.994,
			93.01,
			93.073,
			93.105,
			93.135,
			93.212,
			93.26,
			93.324,
			93.385,
			93.432,
			93.51,
			93.54,
			93.571,
			93.65,
			93.696,
			93.76,
			93.821,
			93.884,
			93.945,
			93.979,
			94.009,
			94.072,
			94.086,
			94.133,
			94.197,
			94.259,
			94.321,
			94.384,
			94.413,
			94.445,
			94.523,
			94.569,
			94.632,
			94.695,
			94.74,
			94.755,
			94.818,
			94.846,
			94.88,
			94.957,
			95.005,
			95.068,
			95.13,
			95.177,
			95.256,
			95.316,
			95.395,
			95.442,
			95.505,
			95.563,
			95.629,
			95.692,
			95.721,
			95.755,
			95.816,
			95.831,
			95.877,
			95.942,
			96.004,
			96.064,
			96.127,
			96.158,
			96.191,
			96.267,
			96.316,
			96.376,
			96.439,
			96.486,
			96.502,
			96.561,
			96.624,
			96.687,
			96.705,
			96.75,
			96.813,
			96.874,
			96.938,
			97.001,
			97.03,
			97.061,
			97.139,
			97.187,
			97.247,
			97.312,
			97.373,
			97.435,
			97.499,
			97.576,
			97.624,
			97.686,
			97.747,
			97.81,
			97.873,
			97.904,
			97.934,
			98.011,
			98.06,
			98.12,
			98.184,
			98.246,
			98.339,
			98.371,
			98.433,
			98.496,
			98.556,
			98.618,
			98.683,
			98.746,
			98.806,
			98.885,
			98.93,
			98.996,
			99.056,
			99.12,
			99.213,
			99.244,
			99.32,
			99.367,
			99.429,
			99.491,
			99.556,
			99.616,
			99.649,
			99.678,
			99.741,
			99.756,
			99.804,
			99.866,
			99.929,
			99.99,
			100.053,
			100.082,
			100.115,
			100.177,
			100.239,
			100.303,
			100.363,
			100.411,
			100.427,
			100.49,
			100.52,
			100.552,
			100.628,
			100.676,
			100.738,
			100.799,
			100.861,
			100.924,
			100.955,
			100.987,
			101.049,
			101.065,
			101.111,
			101.176,
			101.236,
			101.283,
			101.36,
			101.391,
			101.422,
			101.501,
			101.547,
			101.611,
			101.673,
			101.72,
			101.735,
			101.796,
			101.829,
			101.86,
			101.939,
			101.985,
			102.051,
			102.11,
			102.157,
			102.234,
			102.298,
			102.373,
			102.42,
			102.484,
			102.545,
			102.608,
			102.669,
			102.732,
			102.809,
			102.858,
			102.919,
			102.982,
			103.044,
			103.137,
			103.169,
			103.248,
			103.294,
			103.356,
			103.42,
			103.482,
			103.542,
			103.604,
			103.683,
			103.73,
			103.791,
			103.854,
			103.917,
			103.978,
			104.009,
			104.042,
			104.105,
			104.167,
			104.229,
			104.292,
			104.354,
			104.416,
			104.448,
			104.477,
			104.554,
			104.603,
			104.665,
			104.727,
			104.774,
			104.79,
			104.85,
			104.913,
			104.992,
			105.039,
			105.101,
			105.162,
			105.208,
			105.319,
			105.35,
			105.411,
			105.428,
			105.476,
			105.538,
			105.6,
			105.646,
			105.661,
			105.724,
			105.755,
			105.785,
			105.864,
			105.91,
			105.972,
			106.035,
			106.08,
			106.161,
			106.191,
			106.222,
			106.3,
			106.347,
			106.412,
			106.472,
			106.534,
			106.588,
			106.658,
			106.737,
			106.784,
			106.845,
			106.907,
			106.969,
			107.064,
			107.094,
			107.171,
			107.22,
			107.282,
			107.344,
			107.389,
			107.406,
			107.468,
			107.5,
			107.531,
			107.607,
			107.654,
			107.717,
			107.778,
			107.84,
			107.903,
			107.966,
			108.042,
			108.088,
			108.152,
			108.214,
			108.26,
			108.339,
			108.401,
			108.483,
			108.525,
			108.589,
			108.647,
			108.698,
			108.713,
			108.774,
			108.804,
			108.838,
			108.914,
			108.96,
			109.024,
			109.086,
			109.149,
			109.211,
			109.242,
			109.272,
			109.351,
			109.397,
			109.46,
			109.523,
			109.569,
			109.584,
			109.647,
			109.71,
			109.788,
			109.835,
			109.898,
			109.96,
			110.02,
			110.115,
			110.147,
			110.225,
			110.272,
			110.334,
			110.395,
			110.458,
			110.519,
			110.583,
			110.644,
			110.66,
			110.706,
			110.768,
			110.832,
			110.894,
			110.957,
			111.017,
			111.095,
			111.144,
			111.205,
			111.266,
			111.328,
			111.392,
			111.454,
			111.532,
			111.578,
			111.642,
			111.704,
			111.75,
			111.766,
			111.827,
			111.861,
			111.891,
			111.954,
			112.015,
			112.083,
			112.139,
			112.201,
			112.262,
			112.326,
			112.405,
			112.452,
			112.515,
			112.576,
			112.625,
			112.639,
			112.701,
			112.733,
			112.764,
			112.84,
			112.888,
			112.95,
			113.013,
			113.058,
			113.137,
			113.2,
			113.385,
			113.447,
			113.51,
			113.604,
			113.634,
			113.713,
			113.76,
			113.823,
			113.884,
			113.947,
			114.041,
			114.073,
			114.136,
			114.195,
			114.26,
			114.321,
			114.384,
			114.447,
			114.509,
			114.585,
			114.633,
			114.701,
			114.757,
			114.819,
			114.913,
			114.943,
			115.022,
			115.067,
			115.13,
			115.193,
			115.239,
			115.254,
			115.317,
			115.379,
			115.458,
			115.506,
			115.567,
			115.629,
			115.69,
			115.753,
			115.817,
			115.895,
			115.939,
			116.004,
			116.063,
			116.128,
			116.189,
			116.253,
			116.329,
			116.376,
			116.44,
			116.503,
			116.549,
			116.561,
			116.623,
			116.687,
			116.77,
			116.811,
			116.874,
			116.936,
			117,
			117.061,
			117.122,
			117.203,
			117.246,
			117.311,
			117.374,
			117.436,
			117.528,
			117.554,
			117.639,
			117.684,
			117.747,
			117.809,
			117.856,
			117.934,
			117.965,
			117.997,
			118.074,
			118.121,
			118.185,
			118.245,
			118.292,
			118.308,
			118.371,
			118.4,
			118.433,
			118.509,
			118.554,
			118.62,
			118.68,
			118.728,
			118.807,
			118.869,
			118.931,
			118.993,
			119.055,
			119.117,
			119.181,
			119.242,
			119.273,
			119.304,
			119.344
		]
	],
	'8703_9042_7982': [
		[
			60.396
		],
		[
			0
		],
		[
			0,
			2.381,
			5.483,
			8.593,
			10.15,
			13.284,
			18.008,
			22.747,
			25.913,
			29.084,
			33.849,
			37.032,
			41.81,
			45,
			51.408,
			53.03,
			57.909,
			61.159,
			64.374,
			65.978,
			69.188,
			72.401,
			74.013,
			78.857,
			82.094,
			85.335,
			90.206,
			93.459,
			98.346,
			99.976,
			101.607,
			106.495,
			109.74,
			114.566,
			117.744,
			122.44,
			125.523,
			128.568,
			130.076,
			134.55,
			137.487,
			140.388,
			144.668,
			147.473,
			151.61,
			152.969,
			154.32,
			158.313,
			160.928,
			164.779,
			167.299,
			171.009,
			173.435,
			175.826,
			177.009,
			180.504,
			182.789,
			185.034,
			188.332,
			190.484,
			194.676,
			195.701,
			198.72,
			200.687,
			203.569,
			205.446,
			208.196,
			210.873,
			211.749,
			214.338,
			215.178,
			216.012,
			218.476,
			220.888,
			222.478,
			224.059,
			225.626,
			226.409,
			227.974,
			228.757,
			230.301,
			231.843,
			234.149,
			235.652,
			238.653,
			239.401,
			241.635,
			243.12,
			245.446,
			247.025,
			249.479,
			253.17,
			254.117,
			256.196,
			257.232,
			259.324,
			261.499,
			264.742,
			266.879,
			270.907,
			271.856,
			274.593,
			276.204,
			278.62,
			280.105,
			282.272,
			283.714,
			284.438,
			285.888,
			287.333,
			288.055,
			289.512,
			290.991,
			293.207,
			294.693,
			297.69,
			298.44,
			300.709,
			302.232,
			304.536,
			306.094,
			308.453,
			310.854,
			312.475,
			314.936,
			316.602,
			318.279,
			320.824,
			321.679,
			322.538,
			325.116,
			325.986,
			326.873,
			329.504,
			331.278,
			333.942,
			335.741,
			337.552,
			340.304,
			341.22,
			342.137,
			345.809,
			346.713,
			349.484,
			351.344,
			354.156,
			357.964,
			358.904,
			361.817,
			363.782,
			365.762,
			368.762,
			370.769,
			373.803,
			375.824,
			379.875,
			380.889,
			383.93,
			385.955,
			388.981,
			390.994,
			393.005,
			394.007,
			397.042,
			399.069,
			401.107,
			404.188,
			406.241,
			409.325,
			410.353,
			411.381,
			414.466,
			416.533,
			419.649,
			421.753,
			424.94,
			427.081,
			428.155,
			430.31,
			432.45,
			433.522,
			435.672,
			437.806,
			441.011,
			443.097,
			446.222,
			447.25,
			448.279,
			451.372,
			453.451,
			456.585,
			458.709,
			460.834,
			461.914,
			464.082,
			465.167,
			467.342,
			470.617,
			472.805,
			475,
			478.254,
			479.33,
			480.407,
			483.624,
			485.773,
			489.023,
			491.202,
			493.364,
			496.62,
			499.873,
			502.052,
			505.336,
			508.635,
			510.843,
			513.056,
			515.273,
			517.488,
			518.593,
			520.795,
			522.971,
			524.048,
			527.231,
			529.271,
			532.29,
			534.266,
			536.223,
			539.128,
			541.039,
			544.802,
			545.727,
			548.448,
			550.216,
			552.81,
			554.503,
			556.17,
			556.994,
			559.448,
			561.073,
			562.69,
			565.095,
			566.668,
			568.99,
			569.749,
			570.501,
			572.706,
			574.126,
			576.187,
			577.52,
			578.819,
			579.456,
			580.706,
			581.925,
			582.526,
			584.315,
			585.488,
			586.661,
			588.403,
			589.563,
			591.293,
			592.443,
			594.17,
			595.317,
			597.035,
			598.177,
			599.877,
			601.573,
			602.704,
			604.399,
			605.525,
			606.649,
			608.393,
			609.62,
			612.065,
			612.675,
			615.168,
			616.86,
			619.706,
			621.73,
			624.415,
			626.059,
			626.883,
			628.419,
			630.435,
			631.785,
			633.132,
			635.067,
			636.363,
			638.965,
			639.616,
			641.576,
			642.892,
			644.774,
			646.031,
			647.297,
			647.934,
			649.159,
			650.385,
			652.872,
			654.144,
			655.432,
			657.389,
			658.723,
			660.771,
			662.155,
			664.267,
			665.7,
			667.876,
			669.352,
			670.843,
			673.109,
			673.878,
			674.653,
			676.997,
			677.786,
			678.582,
			681.002,
			682.63,
			685.086,
			686.733,
			688.386,
			689.215,
			690.867,
			691.702,
			693.367,
			695.026,
			697.451,
			699.04,
			701.383,
			702.156,
			702.927,
			705.22,
			706.734,
			708.986,
			710.469,
			712.676,
			714.134,
			715.578,
			716.299,
			717.741,
			718.463,
			719.9,
			721.337,
			723.497,
			724.937,
			727.815,
			728.535,
			730.71,
			732.16,
			734.361,
			735.888,
			738.176,
			741.49,
			742.338,
			744.037,
			744.926,
			746.702,
			748.476,
			751.14,
			752.913,
			756.485,
			757.38,
			760.058,
			761.833,
			764.497,
			766.26,
			768.921,
			770.657,
			771.525,
			773.271,
			774.991,
			775.833,
			776.676,
			779.229,
			781.679,
			782.5,
			783.324,
			784.945,
			786.525,
			787.318,
			789.709,
			791.31,
			793.715,
			795.338,
			796.979,
			797.801,
			799.449,
			800.29,
			801.973,
			804.505,
			805.362,
			807.935,
			809.656,
			812.263,
			814.007,
			815.762,
			816.642,
			818.408,
			819.294,
			821.073,
			822.859,
			825.554,
			827.361,
			830.09,
			831.003,
			831.92,
			835.599,
			836.528,
			839.332,
			841.206,
			843.077,
			844.008,
			845.856,
			847.678,
			848.578,
			850.353,
			851.229,
			852.954,
			854.645,
			857.122,
			858.731,
			861.089,
			862.633,
			864.921,
			866.442,
			868.729,
			870.238,
			871.751,
			872.506,
			874.012,
			875.513,
			876.268,
			877.772,
			880.018,
			881.516,
			883.755,
			885.241,
			887.476,
			888.219,
			888.962,
			891.192,
			892.701,
			894.958,
			896.479,
			898.802,
			901.13,
			902.722,
			904.313,
			905.109,
			906.714,
			908.328,
			910.754,
			911.571,
			912.392,
			914.852,
			915.672,
			916.496,
			918.972,
			920.621,
			923.102,
			924.754,
			927.227,
			928.867,
			929.686,
			931.326,
			933.762,
			934.574,
			937.007,
			938.605,
			941.016,
			944.142,
			944.922,
			947.261,
			948.805,
			950.359,
			952.711,
			954.282,
			957.492,
			958.303,
			961.571,
			962.39,
			964.842,
			966.469,
			968.904,
			970.534,
			972.146,
			972.951,
			974.556,
			975.356,
			976.952,
			978.545,
			980.92,
			982.501,
			984.872,
			985.659,
			986.446,
			988.846,
			990.448,
			992.891,
			994.57,
			996.246,
			997.084,
			998.845,
			1000.604,
			1001.484,
			1004.161,
			1005.953,
			1007.737,
			1010.371,
			1012.13,
			1014.624,
			1016.285,
			1018.747,
			1020.375,
			1022.832,
			1024.49,
			1026.99,
			1028.689,
			1029.547,
			1031.27,
			1033.007,
			1033.888,
			1035.656,
			1037.434,
			1040.091,
			1041.875,
			1044.504,
			1045.378,
			1046.255,
			1048.876,
			1050.616,
			1053.25,
			1055.017,
			1057.694,
			1059.486,
			1060.386,
			1062.198,
			1064.018,
			1064.93,
			1066.766,
			1068.618,
			1071.433,
			1073.327,
			1076.213,
			1077.181,
			1078.153,
			1081.097,
			1083.059,
			1085.053,
			1088.068,
			1090.095,
			1094.186,
			1095.216,
			1098.325,
			1099.368,
			1100.414,
			1103.563,
			1105.673,
			1108.839,
			1110.951,
			1114.075,
			1117.108,
			1119.084,
			1121.005,
			1123.816,
			1125.645,
			1129.203,
			1130.072,
			1133.462,
			1134.287,
			1136.71,
			1138.28,
			1140.568,
			1143.494,
			1144.203,
			1146.279,
			1147.621,
			1148.931,
			1150.836,
			1152.062,
			1153.834,
			1154.408,
			1154.973,
			1156.627,
			1157.691,
			1159.231,
			1160.232,
			1161.222,
			1161.714,
			1163.196,
			1164.183,
			1165.668,
			1166.656,
			1167.644,
			1169.125,
			1170.114,
			1171.562,
			1172.521,
			1173.952,
			1174.903,
			1176.28,
			1177.18,
			1178.076,
			1179.415,
			1180.779,
			1182.176,
			1182.639,
			1184.021,
			1185.434,
			1186.662,
			1189.1,
			1189.707,
			1192.092,
			1193.858,
			1196.741,
			1198.848,
			1201.316,
			1203.154,
			1203.766,
			1205.54,
			1205.992,
			1206.444,
			1207.8,
			1208.706,
			1210.07,
			1210.994,
			1211.928,
			1212.396,
			1213.328,
			1213.801,
			1214.751,
			1215.714,
			1217.186,
			1218.177,
			1219.666,
			1220.159,
			1220.653,
			1222.137,
			1223.118,
			1224.584,
			1225.561,
			1227.119,
			1228.147,
			1229.688,
			1231.359,
			1232.588,
			1233.812,
			1235.651,
			1237.172,
			1239.45,
			1240.209,
			1240.968,
			1243.237,
			1244.747,
			1246.73,
			1247.901,
			1249.076,
			1249.664,
			1250.826,
			1252.235,
			1253.177,
			1253.65,
			1254.599,
			1255.557,
			1257.056,
			1258.067,
			1259.599,
			1260.115,
			1260.633,
			1262.274,
			1263.391,
			1265.091,
			1266.247,
			1267.432,
			1268.031,
			1269.239,
			1269.849,
			1271.082,
			1272.989,
			1274.281,
			1275.587,
			1277.587,
			1278.941,
			1280.996,
			1281.689,
			1282.386,
			1284.493,
			1285.923,
			1288.114,
			1289.586,
			1291.074,
			1293.339,
			1294.089,
			1295.625,
			1297.97,
			1298.754,
			1301.134,
			1302.726,
			1305.162,
			1306.817,
			1308.468,
			1309.299,
			1310.957,
			1311.795,
			1313.464,
			1315.14,
			1317.68,
			1319.387,
			1321.975,
			1323.715,
			1327.234,
			1328.119,
			1330.794,
			1332.595,
			1334.411,
			1335.325,
			1337.161,
			1339.945,
			1342.759,
			1344.649,
			1346.552,
			1349.428,
			1351.361,
			1354.29,
			1356.26,
			1359.268,
			1361.306,
			1364.44,
			1366.577,
			1369.825,
			1372.032,
			1375.271,
			1377.404,
			1378.484,
			1380.654,
			1382.849,
			1386.188,
			1388.441,
			1391.865,
			1393.005,
			1394.126,
			1397.461,
			1399.667,
			1402.946,
			1405.12,
			1407.293,
			1408.384,
			1410.578,
			1411.68,
			1413.891,
			1417.256,
			1419.514,
			1421.787,
			1425.215,
			1426.363,
			1427.513,
			1430.978,
			1433.3,
			1436.803,
			1439.151,
			1441.51,
			1445.066,
			1448.644,
			1451.042,
			1452.244,
			1453.449,
			1458.291,
			1460.726,
			1463.17,
			1465.624,
			1468.085,
			1469.32,
			1473.037,
			1474.281,
			1475.53,
			1478.038,
			1481.851,
			1484.426,
			1488.374,
			1491.052,
			1495.202,
			1498.034,
			1502.199,
			1504.955,
			1508.874,
			1511.463,
			1514.032,
			1515.296,
			1517.874,
			1521.751,
			1525.64,
			1528.247,
			1530.86,
			1534.802,
			1537.44,
			1541.412,
			1544.069,
			1548.068,
			1550.743,
			1554.767,
			1557.457,
			1560.153,
			1561.504,
			1564.208,
			1568.277,
			1572.363,
			1575.103,
			1577.849,
			1581.982,
			1584.744,
			1588.901,
			1591.682,
			1595.865,
			1598.659,
			1602.858,
			1605.662,
			1609.879,
			1612.694,
			1614.103,
			1616.921,
			1621.146,
			1623.951,
			1626.743,
			1630.881,
			1633.598,
			1637.607,
			1640.239,
			1644.123,
			1646.692,
			1650.477,
			1652.956,
			1655.406,
			1658.977,
			1662.476,
			1665.91,
			1667.041,
			1670.395,
			1672.602,
			1675.872,
			1678.035,
			1681.258,
			1684.469,
			1686.612,
			1688.756,
			1691.99,
			1695.228,
			1697.387,
			1698.467,
			1699.547,
			1702.814,
			1703.903,
			1704.997,
			1708.308,
			1710.515,
			1713.826,
			1718.222,
			1719.317,
			1722.608,
			1724.801,
			1726.999,
			1730.294,
			1732.493,
			1736.884,
			1737.981,
			1741.273,
			1743.47,
			1746.765,
			1748.961,
			1751.157,
			1752.255,
			1754.448,
			1757.742,
			1761.034,
			1763.234,
			1765.43,
			1768.74,
			1770.946,
			1775.365,
			1776.471,
			1779.79,
			1781.993,
			1785.29,
			1787.471,
			1789.65,
			1790.74,
			1792.922,
			1796.196,
			1799.495,
			1801.7,
			1803.92,
			1807.267,
			1808.396,
			1809.524,
			1812.95,
			1814.106,
			1815.261,
			1818.696,
			1820.972,
			1824.285,
			1826.479,
			1828.631,
			1829.705,
			1831.853,
			1835.084,
			1838.336,
			1839.429,
			1848.341,
			1851.741,
			1853.934,
			1857.191,
			1859.281,
			1861.364,
			1864.479,
			1866.548,
			1869.666,
			1870.704,
			1871.74,
			1875.897,
			1876.933,
			1880.038,
			1882.099,
			1884.146,
			1885.165,
			1887.201,
			1890.254,
			1893.311,
			1895.366,
			1897.419,
			1900.509,
			1902.568,
			1905.588,
			1907.594,
			1910.539,
			1912.495,
			1915.422,
			1917.371,
			1919.326,
			1920.305,
			1922.264,
			1923.244,
			1925.208,
			1928.157,
			1930.125,
			1932.093,
			1935.048,
			1937.02,
			1939.992,
			1940.986,
			1941.982,
			1944.997,
			1947.028,
			1950.116,
			1952.204,
			1955.426,
			1957.623,
			1959.852,
			1960.98,
			1964.352,
			1966.523,
			1968.702,
			1971.886,
			1974.018,
			1977.257,
			1978.341,
			1979.431,
			1982.726,
			1984.938,
			1988.282,
			1990.532,
			1992.795,
			1993.93,
			1996.172,
			1997.295,
			1999.531,
			2001.684,
			2002.764,
			2004.923,
			2007.075,
			2010.351,
			2011.453,
			2012.576,
			2014.84,
			2017.162,
			2018.326,
			2021.822,
			2024.159,
			2027.662,
			2030.005,
			2032.351,
			2037.059,
			2038.239,
			2041.791,
			2042.979,
			2044.169,
			2047.746,
			2050.139,
			2053.739,
			2056.151,
			2058.574,
			2059.789,
			2062.229,
			2063.454,
			2065.907,
			2069.594,
			2072.057,
			2074.526,
			2079.482,
			2080.726,
			2084.468,
			2086.974,
			2090.756,
			2093.315,
			2097.143,
			2099.693,
			2102.273,
			2103.587,
			2106.155,
			2107.471,
			2110.06,
			2112.634,
			2116.552,
			2119.146,
			2124.324,
			2125.628,
			2129.548,
			2132.17,
			2136.114,
			2138.752,
			2142.722,
			2146.705,
			2149.369,
			2152.041,
			2153.378,
			2156.078,
			2158.737,
			2162.768,
			2165.464,
			2169.521,
			2170.877,
			2172.235,
			2176.317,
			2179.048,
			2183.157,
			2185.906,
			2190.041,
			2192.807,
			2194.192,
			2196.969,
			2199.752,
			2201.146,
			2203.94,
			2206.74,
			2210.952,
			2213.77,
			2219.425,
			2220.843,
			2225.107,
			2227.958,
			2232.245,
			2235.111,
			2239.423,
			2242.306,
			2246.642,
			2250.992,
			2253.899,
			2256.813,
			2261.196,
			2262.66,
			2264.126,
			2268.534,
			2271.48,
			2275.912,
			2278.874,
			2283.327,
			2286.305,
			2289.288,
			2290.782,
			2293.775,
			2296.774,
			2302.789,
			2304.296,
			2308.827,
			2311.854,
			2314.888,
			2316.408,
			2319.451,
			2324.026,
			2328.612,
			2331.669,
			2334.729,
			2339.323,
			2342.402,
			2347.035,
			2348.583,
			2350.143,
			2354.854,
			2357.918,
			2362.495,
			2365.626,
			2370.446,
			2372.594
		],
		[
			0,
			0.041,
			0.102,
			0.15,
			0.166,
			0.227,
			0.288,
			0.367,
			0.413,
			0.475,
			0.539,
			0.585,
			0.664,
			0.726,
			0.807,
			0.849,
			0.913,
			0.976,
			1.022,
			1.037,
			1.098,
			1.13,
			1.162,
			1.24,
			1.287,
			1.348,
			1.41,
			1.459,
			1.534,
			1.566,
			1.594,
			1.675,
			1.72,
			1.785,
			1.846,
			1.907,
			1.97,
			2.003,
			2.034,
			2.111,
			2.156,
			2.22,
			2.283,
			2.33,
			2.408,
			2.439,
			2.468,
			2.53,
			2.592,
			2.656,
			2.719,
			2.78,
			2.842,
			2.879,
			2.906,
			2.983,
			3.031,
			3.093,
			3.155,
			3.217,
			3.309,
			3.342,
			3.42,
			3.465,
			3.528,
			3.589,
			3.653,
			3.744,
			3.776,
			3.839,
			3.856,
			3.902,
			3.965,
			4.028,
			4.088,
			4.151,
			4.181,
			4.215,
			4.277,
			4.293,
			4.339,
			4.401,
			4.463,
			4.526,
			4.62,
			4.65,
			4.713,
			4.775,
			4.838,
			4.9,
			4.96,
			5.055,
			5.086,
			5.15,
			5.165,
			5.21,
			5.274,
			5.334,
			5.397,
			5.491,
			5.521,
			5.583,
			5.647,
			5.708,
			5.771,
			5.835,
			5.897,
			5.927,
			5.96,
			6.021,
			6.035,
			6.083,
			6.145,
			6.208,
			6.271,
			6.363,
			6.395,
			6.457,
			6.517,
			6.582,
			6.643,
			6.705,
			6.799,
			6.83,
			6.908,
			6.956,
			7.017,
			7.079,
			7.126,
			7.142,
			7.205,
			7.236,
			7.265,
			7.342,
			7.39,
			7.453,
			7.516,
			7.576,
			7.64,
			7.67,
			7.702,
			7.78,
			7.825,
			7.89,
			7.951,
			8.014,
			8.108,
			8.14,
			8.218,
			8.265,
			8.326,
			8.388,
			8.434,
			8.514,
			8.575,
			8.653,
			8.699,
			8.765,
			8.825,
			8.887,
			8.949,
			8.98,
			9.012,
			9.094,
			9.135,
			9.199,
			9.26,
			9.307,
			9.385,
			9.415,
			9.448,
			9.508,
			9.571,
			9.633,
			9.698,
			9.758,
			9.822,
			9.851,
			9.883,
			9.947,
			9.959,
			10.008,
			10.071,
			10.132,
			10.18,
			10.257,
			10.289,
			10.319,
			10.398,
			10.445,
			10.508,
			10.57,
			10.616,
			10.632,
			10.694,
			10.725,
			10.757,
			10.835,
			10.881,
			10.942,
			11.006,
			11.051,
			11.068,
			11.131,
			11.194,
			11.27,
			11.318,
			11.377,
			11.442,
			11.504,
			11.567,
			11.629,
			11.707,
			11.754,
			11.817,
			11.876,
			11.923,
			11.939,
			12.002,
			12.032,
			12.065,
			12.143,
			12.189,
			12.254,
			12.314,
			12.377,
			12.439,
			12.499,
			12.58,
			12.625,
			12.69,
			12.751,
			12.813,
			12.874,
			12.906,
			12.938,
			13.015,
			13.064,
			13.125,
			13.187,
			13.25,
			13.312,
			13.342,
			13.373,
			13.452,
			13.497,
			13.56,
			13.623,
			13.669,
			13.685,
			13.747,
			13.78,
			13.81,
			13.888,
			13.934,
			13.999,
			14.06,
			14.123,
			14.183,
			14.247,
			14.326,
			14.371,
			14.435,
			14.496,
			14.557,
			14.651,
			14.682,
			14.76,
			14.807,
			14.872,
			14.931,
			14.992,
			15.087,
			15.119,
			15.181,
			15.243,
			15.307,
			15.367,
			15.431,
			15.494,
			15.523,
			15.555,
			15.632,
			15.681,
			15.743,
			15.804,
			15.868,
			15.961,
			15.993,
			16.054,
			16.116,
			16.18,
			16.24,
			16.288,
			16.305,
			16.367,
			16.426,
			16.506,
			16.554,
			16.615,
			16.678,
			16.74,
			16.803,
			16.864,
			16.943,
			16.99,
			17.054,
			17.116,
			17.162,
			17.24,
			17.27,
			17.302,
			17.364,
			17.386,
			17.427,
			17.49,
			17.55,
			17.613,
			17.677,
			17.707,
			17.737,
			17.801,
			17.817,
			17.863,
			17.925,
			17.986,
			18.035,
			18.112,
			18.143,
			18.176,
			18.251,
			18.3,
			18.361,
			18.424,
			18.485,
			18.548,
			18.58,
			18.61,
			18.674,
			18.688,
			18.736,
			18.799,
			18.86,
			18.921,
			19.014,
			19.047,
			19.11,
			19.171,
			19.235,
			19.297,
			19.358,
			19.455,
			19.482,
			19.546,
			19.56,
			19.607,
			19.67,
			19.731,
			19.795,
			19.888,
			19.92,
			19.981,
			20.042,
			20.106,
			20.168,
			20.23,
			20.292,
			20.325,
			20.357,
			20.418,
			20.432,
			20.477,
			20.543,
			20.604,
			20.65,
			20.666,
			20.727,
			20.761,
			20.791,
			20.87,
			20.914,
			20.979,
			21.04,
			21.087,
			21.104,
			21.165,
			21.198,
			21.228,
			21.305,
			21.351,
			21.415,
			21.475,
			21.539,
			21.602,
			21.632,
			21.663,
			21.726,
			21.74,
			21.787,
			21.85,
			21.914,
			21.96,
			22.038,
			22.069,
			22.099,
			22.178,
			22.225,
			22.287,
			22.35,
			22.396,
			22.413,
			22.472,
			22.504,
			22.535,
			22.598,
			22.615,
			22.66,
			22.726,
			22.786,
			22.847,
			22.91,
			22.973,
			23.035,
			23.099,
			23.159,
			23.222,
			23.27,
			23.285,
			23.346,
			23.379,
			23.41,
			23.473,
			23.533,
			23.597,
			23.658,
			23.722,
			23.784,
			23.814,
			23.844,
			23.906,
			23.97,
			24.033,
			24.093,
			24.155,
			24.25,
			24.281,
			24.344,
			24.36,
			24.405,
			24.468,
			24.53,
			24.576,
			24.593,
			24.654,
			24.685,
			24.718,
			24.793,
			24.842,
			24.906,
			24.966,
			25.029,
			25.091,
			25.123,
			25.153,
			25.233,
			25.276,
			25.34,
			25.402,
			25.466,
			25.559,
			25.589,
			25.671,
			25.715,
			25.776,
			25.84,
			25.902,
			25.995,
			26.025,
			26.105,
			26.152,
			26.215,
			26.275,
			26.339,
			26.401,
			26.431,
			26.463,
			26.523,
			26.54,
			26.587,
			26.648,
			26.711,
			26.774,
			26.835,
			26.867,
			26.899,
			26.959,
			27.023,
			27.087,
			27.147,
			27.196,
			27.209,
			27.273,
			27.304,
			27.335,
			27.413,
			27.458,
			27.521,
			27.583,
			27.646,
			27.708,
			27.771,
			27.833,
			27.894,
			27.958,
			28.02,
			28.083,
			28.143,
			28.175,
			28.206,
			28.27,
			28.286,
			28.331,
			28.393,
			28.457,
			28.517,
			28.581,
			28.611,
			28.643,
			28.72,
			28.768,
			28.831,
			28.892,
			28.953,
			29.018,
			29.048,
			29.08,
			29.142,
			29.158,
			29.204,
			29.265,
			29.33,
			29.389,
			29.454,
			29.482,
			29.514,
			29.594,
			29.64,
			29.702,
			29.764,
			29.827,
			29.919,
			29.95,
			30.014,
			30.03,
			30.075,
			30.14,
			30.201,
			30.264,
			30.325,
			30.387,
			30.466,
			30.513,
			30.573,
			30.637,
			30.699,
			30.792,
			30.824,
			30.903,
			30.948,
			31.01,
			31.072,
			31.134,
			31.229,
			31.261,
			31.338,
			31.384,
			31.448,
			31.509,
			31.572,
			31.633,
			31.664,
			31.693,
			31.757,
			31.82,
			31.888,
			31.943,
			31.992,
			32.007,
			32.1,
			32.131,
			32.21,
			32.255,
			32.318,
			32.381,
			32.429,
			32.506,
			32.569,
			32.646,
			32.692,
			32.756,
			32.817,
			32.877,
			32.943,
			33.004,
			33.082,
			33.126,
			33.19,
			33.254,
			33.315,
			33.409,
			33.44,
			33.517,
			33.565,
			33.628,
			33.69,
			33.753,
			33.843,
			33.877,
			33.938,
			33.957,
			34.001,
			34.065,
			34.126,
			34.189,
			34.251,
			34.281,
			34.313,
			34.376,
			34.39,
			34.439,
			34.501,
			34.563,
			34.623,
			34.688,
			34.718,
			34.749,
			34.827,
			34.874,
			34.936,
			34.998,
			35.059,
			35.122,
			35.186,
			35.264,
			35.309,
			35.374,
			35.435,
			35.498,
			35.56,
			35.592,
			35.622,
			35.699,
			35.747,
			35.807,
			35.872,
			35.919,
			35.934,
			35.996,
			36.057,
			36.121,
			36.137,
			36.184,
			36.246,
			36.309,
			36.371,
			36.433,
			36.465,
			36.493,
			36.573,
			36.62,
			36.682,
			36.743,
			36.79,
			36.806,
			36.868,
			36.899,
			36.931,
			37.009,
			37.055,
			37.118,
			37.18,
			37.242,
			37.306,
			37.334,
			37.365,
			37.431,
			37.489,
			37.554,
			37.617,
			37.677,
			37.739,
			37.771,
			37.803,
			37.88,
			37.927,
			37.988,
			38.051,
			38.103,
			38.177,
			38.208,
			38.239,
			38.301,
			38.316,
			38.363,
			38.426,
			38.487,
			38.533,
			38.613,
			38.676,
			38.753,
			38.799,
			38.863,
			38.925,
			38.97,
			38.986,
			39.048,
			39.111,
			39.188,
			39.235,
			39.298,
			39.359,
			39.424,
			39.485,
			39.548,
			39.624,
			39.672,
			39.733,
			39.797,
			39.86,
			39.919,
			39.984,
			40.045,
			40.061,
			40.108,
			40.175,
			40.231,
			40.295,
			40.356,
			40.387,
			40.42,
			40.498,
			40.544,
			40.606,
			40.669,
			40.714,
			40.73,
			40.793,
			40.823,
			40.856,
			40.933,
			40.98,
			41.042,
			41.104,
			41.151,
			41.167,
			41.23,
			41.292,
			41.369,
			41.417,
			41.477,
			41.539,
			41.604,
			41.665,
			41.697,
			41.727,
			41.807,
			41.853,
			41.914,
			41.977,
			42.023,
			42.04,
			42.103,
			42.133,
			42.165,
			42.226,
			42.289,
			42.351,
			42.415,
			42.459,
			42.537,
			42.6,
			42.676,
			42.724,
			42.788,
			42.849,
			42.896,
			42.912,
			42.973,
			43.036,
			43.114,
			43.161,
			43.223,
			43.285,
			43.348,
			43.41,
			43.472,
			43.549,
			43.596,
			43.663,
			43.721,
			43.769,
			43.784,
			43.846,
			43.909,
			43.985,
			44.034,
			44.094,
			44.158,
			44.204,
			44.281,
			44.343,
			44.423,
			44.468,
			44.531,
			44.594,
			44.657,
			44.719,
			44.75,
			44.781,
			44.858,
			44.906,
			44.969,
			45.03,
			45.078,
			45.154,
			45.217,
			45.295,
			45.342,
			45.405,
			45.468,
			45.513,
			45.592,
			45.653,
			45.731,
			45.776,
			45.842,
			45.902,
			45.965,
			46.026,
			46.089,
			46.168,
			46.215,
			46.276,
			46.34,
			46.403,
			46.465,
			46.495,
			46.525,
			46.589,
			46.603,
			46.65,
			46.714,
			46.776,
			46.838,
			46.931,
			46.963,
			47.041,
			47.087,
			47.149,
			47.213,
			47.275,
			47.368,
			47.399,
			47.461,
			47.523,
			47.586,
			47.648,
			47.695,
			47.711,
			47.773,
			47.834,
			47.912,
			47.96,
			48.021,
			48.084,
			48.145,
			48.239,
			48.269,
			48.332,
			48.396,
			48.461,
			48.518,
			48.567,
			48.582,
			48.645,
			48.705,
			48.784,
			48.831,
			48.894,
			48.954,
			49.001,
			49.018,
			49.08,
			49.11,
			49.143,
			49.221,
			49.268,
			49.334,
			49.392,
			49.439,
			49.453,
			49.517,
			49.578,
			49.656,
			49.702,
			49.889,
			49.954,
			50.015,
			50.093,
			50.139,
			50.202,
			50.266,
			50.311,
			50.39,
			50.419,
			50.452,
			50.534,
			50.573,
			50.637,
			50.7,
			50.746,
			50.762,
			50.823,
			50.885,
			50.964,
			51.011,
			51.073,
			51.134,
			51.181,
			51.26,
			51.322,
			51.399,
			51.447,
			51.51,
			51.571,
			51.617,
			51.632,
			51.695,
			51.725,
			51.757,
			51.835,
			51.882,
			51.943,
			52.006,
			52.071,
			52.132,
			52.164,
			52.195,
			52.257,
			52.318,
			52.382,
			52.444,
			52.505,
			52.568,
			52.603,
			52.631,
			52.707,
			52.756,
			52.818,
			52.881,
			52.928,
			53.004,
			53.037,
			53.067,
			53.145,
			53.191,
			53.255,
			53.316,
			53.364,
			53.38,
			53.443,
			53.472,
			53.504,
			53.565,
			53.583,
			53.63,
			53.693,
			53.753,
			53.8,
			53.817,
			53.877,
			53.909,
			53.939,
			54.017,
			54.065,
			54.128,
			54.19,
			54.251,
			54.344,
			54.376,
			54.441,
			54.455,
			54.502,
			54.564,
			54.627,
			54.69,
			54.752,
			54.782,
			54.814,
			54.875,
			54.893,
			54.938,
			55.003,
			55.064,
			55.126,
			55.219,
			55.251,
			55.326,
			55.376,
			55.438,
			55.497,
			55.56,
			55.625,
			55.655,
			55.687,
			55.748,
			55.764,
			55.81,
			55.875,
			55.934,
			55.997,
			56.092,
			56.123,
			56.201,
			56.249,
			56.31,
			56.372,
			56.434,
			56.526,
			56.558,
			56.62,
			56.637,
			56.685,
			56.751,
			56.809,
			56.87,
			56.933,
			56.965,
			56.995,
			57.057,
			57.12,
			57.184,
			57.245,
			57.306,
			57.37,
			57.402,
			57.432,
			57.494,
			57.508,
			57.556,
			57.623,
			57.681,
			57.744,
			57.836,
			57.869,
			57.93,
			57.993,
			58.056,
			58.117,
			58.181,
			58.243,
			58.305,
			58.383,
			58.43,
			58.493,
			58.555,
			58.6,
			58.616,
			58.679,
			58.741,
			58.822,
			58.864,
			58.928,
			58.989,
			59.035,
			59.053,
			59.115,
			59.177,
			59.255,
			59.302,
			59.364,
			59.426,
			59.472,
			59.483,
			59.549,
			59.611,
			59.69,
			59.737,
			59.8,
			59.861,
			59.908,
			59.985,
			60.018,
			60.047,
			60.126,
			60.173,
			60.235,
			60.298,
			60.359,
			60.396
		]
	],
	'8703_9041_7982': [
		[
			70.411
		],
		[
			0
		],
		[
			0,
			2.643,
			5.837,
			10.605,
			12.19,
			13.772,
			18.533,
			21.72,
			26.548,
			29.772,
			33,
			34.616,
			37.851,
			39.471,
			42.714,
			45.96,
			47.584,
			50.836,
			54.092,
			58.985,
			62.254,
			67.167,
			68.808,
			70.45,
			75.381,
			78.673,
			83.616,
			86.913,
			90.214,
			96.823,
			100.114,
			103.383,
			105.007,
			108.228,
			111.41,
			114.555,
			119.201,
			125.269,
			126.764,
			129.724,
			131.189,
			134.09,
			136.953,
			141.174,
			143.941,
			148.02,
			149.36,
			150.691,
			155.924,
			157.209,
			161.017,
			163.509,
			165.961,
			167.173,
			169.566,
			173.086,
			176.527,
			178.773,
			180.981,
			184.223,
			186.337,
			189.438,
			191.459,
			194.422,
			196.351,
			199.175,
			201.012,
			202.815,
			203.704,
			205.457,
			208.018,
			210.498,
			212.106,
			213.678,
			215.969,
			216.728,
			217.466,
			219.645,
			220.361,
			221.071,
			223.172,
			224.549,
			226.591,
			227.943,
			229.933,
			231.246,
			231.901,
			233.209,
			234.486,
			236.383,
			237.645,
			238.899,
			240.129,
			240.744,
			241.973,
			243.203,
			243.827,
			245.734,
			247.007,
			248.284,
			250.586,
			252.177,
			254.856,
			256.971,
			261.084,
			262.09,
			264.845,
			266.468,
			268.093,
			268.897,
			270.384,
			272.619,
			274.856,
			276.346,
			277.84,
			280.037,
			281.48,
			283.657,
			284.357,
			285.049,
			287.138,
			288.543,
			290.67,
			292.101,
			293.543,
			294.278,
			295.771,
			298.036,
			300.351,
			301.921,
			303.505,
			305.924,
			307.556,
			310.037,
			311.708,
			314.215,
			315.907,
			318.469,
			320.198,
			322.817,
			325.469,
			327.255,
			329.955,
			331.768,
			333.592,
			336.379,
			337.317,
			338.243,
			341.097,
			343.018,
			345.887,
			347.796,
			350.671,
			352.592,
			355.496,
			357.445,
			360.392,
			363.367,
			364.364,
			367.377,
			370.41,
			371.424,
			372.437,
			375.483,
			376.488,
			377.489,
			380.476,
			382.46,
			385.421,
			387.388,
			389.347,
			392.272,
			393.243,
			394.212,
			397.141,
			398.116,
			399.089,
			402.066,
			404.057,
			406.097,
			407.137,
			411.253,
			412.26,
			414.274,
			415.269,
			417.167,
			419.065,
			421.897,
			423.779,
			426.611,
			427.558,
			428.506,
			431.361,
			433.274,
			436.159,
			438.1,
			441.036,
			443.028,
			445.028,
			446.044,
			448.105,
			449.138,
			451.218,
			453.323,
			456.483,
			458.573,
			461.7,
			462.726,
			463.756,
			466.852,
			468.918,
			472.048,
			474.157,
			476.28,
			477.352,
			479.493,
			480.562,
			482.711,
			485.953,
			488.119,
			490.294,
			493.56,
			495.747,
			499.023,
			500.119,
			501.216,
			504.496,
			506.689,
			509.964,
			512.145,
			514.314,
			515.398,
			517.558,
			518.633,
			520.773,
			523.984,
			526.101,
			528.226,
			531.362,
			532.375,
			533.396,
			536.424,
			537.405,
			538.377,
			541.242,
			543.118,
			545.88,
			547.693,
			549.485,
			552.133,
			554.735,
			557.29,
			558.133,
			560.632,
			562.255,
			564.648,
			566.2,
			567.711,
			568.45,
			570.614,
			572.01,
			574.033,
			575.34,
			576.618,
			578.491,
			579.108,
			579.721,
			582.161,
			582.784,
			584.607,
			585.816,
			587.626,
			588.835,
			590.04,
			590.643,
			591.848,
			592.454,
			593.67,
			594.883,
			596.705,
			597.927,
			600.367,
			600.976,
			602.812,
			604.045,
			605.884,
			607.106,
			608.434,
			609.105,
			610.442,
			611.776,
			612.47,
			614.852,
			616.436,
			618.028,
			620.427,
			622.029,
			624.266,
			625.738,
			627.933,
			629.351,
			631.485,
			632.886,
			634.23,
			634.904,
			636.256,
			638.269,
			640.255,
			641.59,
			642.923,
			644.9,
			646.231,
			648.219,
			648.865,
			649.514,
			651.48,
			652.806,
			654.809,
			656.161,
			658.228,
			659.641,
			660.354,
			661.789,
			663.99,
			665.486,
			666.996,
			669.326,
			670.911,
			674.166,
			675.005,
			677.543,
			679.204,
			681.737,
			683.417,
			685.057,
			687.512,
			690.018,
			692.543,
			693.376,
			695.867,
			698.341,
			699.979,
			703.232,
			704.034,
			706.425,
			708.009,
			709.585,
			711.929,
			713.483,
			715.797,
			717.331,
			720.364,
			721.116,
			723.367,
			724.859,
			727.087,
			728.571,
			730.103,
			730.874,
			733.185,
			734.816,
			736.515,
			739.073,
			740.847,
			743.509,
			745.264,
			747.883,
			749.632,
			752.264,
			754.015,
			756.684,
			758.46,
			761.109,
			763.743,
			765.48,
			767.205,
			769.78,
			771.463,
			773.978,
			774.807,
			775.622,
			778.055,
			779.668,
			782.015,
			783.574,
			785.116,
			785.868,
			787.371,
			789.625,
			791.868,
			793.365,
			794.867,
			797.176,
			798.721,
			801.07,
			802.656,
			805.051,
			806.651,
			809.092,
			810.739,
			812.4,
			813.25,
			814.932,
			815.78,
			817.488,
			820.075,
			821.821,
			823.58,
			826.245,
			827.14,
			828.023,
			830.738,
			832.562,
			835.325,
			837.18,
			839.973,
			841.824,
			843.655,
			846.345,
			848.092,
			851.483,
			852.309,
			854.737,
			856.315,
			858.624,
			860.123,
			862.329,
			864.498,
			865.935,
			867.37,
			869.526,
			870.951,
			873.8,
			874.508,
			876.629,
			878.037,
			880.138,
			881.54,
			883.65,
			885.051,
			887.196,
			889.376,
			890.828,
			892.282,
			894.461,
			895.913,
			898.042,
			899.454,
			901.584,
			903.028,
			905.191,
			906.661,
			908.995,
			911.328,
			912.995,
			915.494,
			917.169,
			918.852,
			921.378,
			922.966,
			926.133,
			926.897,
			929.114,
			930.599,
			932.842,
			934.347,
			936.625,
			938.162,
			940.489,
			942.827,
			944.39,
			945.966,
			948.335,
			949.128,
			949.924,
			952.317,
			953.934,
			956.384,
			958.03,
			959.682,
			962.159,
			963.802,
			964.621,
			966.256,
			967.885,
			971.136,
			971.942,
			974.347,
			975.942,
			977.534,
			978.329,
			979.915,
			982.285,
			984.698,
			986.303,
			987.94,
			990.479,
			992.171,
			994.876,
			996.689,
			999.439,
			1001.279,
			1003.938,
			1005.659,
			1007.381,
			1008.216,
			1009.879,
			1012.376,
			1014.972,
			1016.709,
			1018.471,
			1021.167,
			1022.967,
			1025.657,
			1026.557,
			1027.458,
			1030.13,
			1031.915,
			1034.56,
			1036.313,
			1038.067,
			1038.917,
			1040.62,
			1041.473,
			1043.182,
			1045.688,
			1047.368,
			1049.054,
			1051.597,
			1053.305,
			1055.899,
			1056.769,
			1057.642,
			1060.29,
			1062.073,
			1064.778,
			1066.602,
			1068.44,
			1069.366,
			1072.163,
			1073.102,
			1075.941,
			1076.894,
			1078.81,
			1080.739,
			1082.663,
			1085.596,
			1089.549,
			1090.544,
			1093.549,
			1095.57,
			1098.626,
			1100.681,
			1102.75,
			1105.868,
			1106.908,
			1107.945,
			1112.045,
			1113.053,
			1116.021,
			1117.954,
			1120.783,
			1122.622,
			1124.426,
			1125.314,
			1127.923,
			1129.617,
			1131.274,
			1133.693,
			1135.261,
			1137.546,
			1139.025,
			1141.177,
			1142.568,
			1144.593,
			1145.902,
			1147.804,
			1150.216,
			1150.801,
			1151.945,
			1152.503,
			1153.596,
			1154.664,
			1156.235,
			1157.27,
			1159.324,
			1159.836,
			1161.373,
			1161.88,
			1162.385,
			1163.889,
			1164.885,
			1166.362,
			1167.333,
			1167.816,
			1168.778,
			1170.211,
			1171.136,
			1172.054,
			1173.422,
			1174.331,
			1176.101,
			1176.531,
			1177.818,
			1178.674,
			1179.963,
			1180.861,
			1182.225,
			1183.588,
			1184.5,
			1185.492,
			1186.142,
			1186.793,
			1188.747,
			1190.953,
			1192.997,
			1195.042,
			1197.263,
			1198.385,
			1200.506,
			1201.144,
			1202.425,
			1204.353,
			1205.582,
			1207.049,
			1208.527,
			1209.024,
			1211.036,
			1211.55,
			1213.099,
			1214.14,
			1215.702,
			1216.76,
			1218.331,
			1219.359,
			1219.869,
			1220.889,
			1221.91,
			1223.426,
			1224.438,
			1225.947,
			1226.972,
			1228.491,
			1229.497,
			1231.049,
			1232.175,
			1233.29,
			1233.845,
			1234.95,
			1236.161,
			1236.864,
			1238.259,
			1238.953,
			1240.332,
			1241.766,
			1243.924,
			1245.351,
			1247.586,
			1248.115,
			1249.698,
			1250.751,
			1252.052,
			1252.913,
			1254.202,
			1255.064,
			1256.385,
			1257.289,
			1257.743,
			1258.651,
			1260.023,
			1260.948,
			1261.907,
			1263.357,
			1264.333,
			1265.81,
			1266.311,
			1266.815,
			1268.34,
			1269.369,
			1270.934,
			1271.994,
			1273.614,
			1274.705,
			1276.386,
			1278.109,
			1279.279,
			1280.465,
			1282.276,
			1282.888,
			1283.505,
			1285.379,
			1286.012,
			1286.649,
			1288.587,
			1289.9,
			1291.902,
			1293.262,
			1294.639,
			1297.443,
			1298.154,
			1300.314,
			1301.042,
			1301.774,
			1303.988,
			1305.485,
			1307.763,
			1309.303,
			1310.86,
			1311.644,
			1313.23,
			1314.029,
			1315.638,
			1317.262,
			1319.724,
			1321.384,
			1323.897,
			1324.737,
			1325.577,
			1328.949,
			1329.8,
			1332.372,
			1334.1,
			1335.841,
			1336.717,
			1338.475,
			1340.246,
			1341.136,
			1343.823,
			1345.628,
			1347.447,
			1350.201,
			1352.053,
			1354.897,
			1356.814,
			1359.733,
			1361.701,
			1364.652,
			1366.628,
			1369.644,
			1371.68,
			1373.777,
			1374.857,
			1378.145,
			1380.393,
			1382.67,
			1385.978,
			1388.198,
			1391.358,
			1392.421,
			1393.479,
			1396.637,
			1398.741,
			1401.909,
			1404.037,
			1406.178,
			1407.253,
			1409.412,
			1412.672,
			1415.959,
			1418.164,
			1420.377,
			1423.716,
			1424.832,
			1425.951,
			1429.324,
			1431.586,
			1434.998,
			1437.286,
			1439.584,
			1443.05,
			1445.373,
			1448.877,
			1451.225,
			1455.951,
			1458.329,
			1460.716,
			1463.113,
			1466.724,
			1469.144,
			1472.79,
			1475.232,
			1476.456,
			1478.91,
			1481.379,
			1485.105,
			1487.672,
			1491.594,
			1492.938,
			1494.285,
			1499.72,
			1501.058,
			1504.987,
			1507.56,
			1510.122,
			1511.406,
			1513.977,
			1516.534,
			1517.827,
			1521.719,
			1524.321,
			1526.93,
			1530.842,
			1533.453,
			1537.378,
			1539.993,
			1543.925,
			1546.554,
			1550.518,
			1553.172,
			1555.835,
			1557.169,
			1559.845,
			1563.875,
			1567.924,
			1570.633,
			1573.351,
			1577.442,
			1580.181,
			1584.302,
			1587.059,
			1591.208,
			1593.983,
			1598.159,
			1600.951,
			1603.75,
			1605.152,
			1607.961,
			1612.186,
			1615.011,
			1616.427,
			1619.262,
			1622.104,
			1626.38,
			1629.239,
			1634.975,
			1636.412,
			1640.735,
			1643.626,
			1647.973,
			1650.88,
			1655.254,
			1659.641,
			1662.573,
			1665.512,
			1666.983,
			1669.931,
			1672.885,
			1675.845,
			1680.299,
			1683.275,
			1686.258,
			1687.75,
			1692.239,
			1695.239,
			1698.245,
			1702.765,
			1705.786,
			1710.329,
			1711.846,
			1713.365,
			1719.453,
			1720.979,
			1725.563,
			1728.626,
			1731.695,
			1733.231,
			1736.307,
			1739.389,
			1740.931,
			1745.568,
			1748.665,
			1751.768,
			1756.432,
			1759.547,
			1764.233,
			1767.37,
			1772.092,
			1775.243,
			1779.957,
			1783.076,
			1787.69,
			1790.716,
			1792.214,
			1795.179,
			1799.554,
			1802.424,
			1805.256,
			1809.433,
			1812.17,
			1816.204,
			1817.529,
			1818.845,
			1822.735,
			1825.281,
			1829.026,
			1831.475,
			1833.888,
			1835.081,
			1837.439,
			1840.909,
			1844.293,
			1846.5,
			1848.669,
			1851.851,
			1852.892,
			1853.925,
			1856.967,
			1858.949,
			1861.852,
			1863.74,
			1866.5,
			1868.294,
			1870.054,
			1870.92,
			1872.626,
			1873.465,
			1875.115,
			1877.527,
			1879.089,
			1880.614,
			1882.833,
			1883.555,
			1884.268,
			1885.667,
			1887.701,
			1889.663,
			1890.93,
			1892.162,
			1893.942,
			1895.085,
			1896.734,
			1897.267,
			1897.791,
			1899.806,
			1900.288,
			1901.685,
			1902.574,
			1903.431,
			1903.848,
			1904.658,
			1905.444,
			1905.833,
			1906.981,
			1907.749,
			1908.514,
			1909.682,
			1910.477,
			1911.683,
			1912.498,
			1913.734,
			1914.567,
			1915.824,
			1916.648,
			1917.869,
			1918.673,
			1919.469,
			1919.865,
			1921.048,
			1921.828,
			1922.588,
			1923.697,
			1924.416,
			1925.472,
			1925.837,
			1926.211,
			1927.309,
			1928.025,
			1929.085,
			1929.787,
			1930.49,
			1930.925,
			1931.902,
			1933.355,
			1934.788,
			1935.785,
			1937.352,
			1939.675,
			1940.447,
			1941.193,
			1943.368,
			1944.807,
			1946.56,
			1947.532,
			1948.984,
			1949.952,
			1951.328,
			1952.178,
			1953.451,
			1954.733,
			1955.165,
			1956.439,
			1957.69,
			1958.529,
			1960.234,
			1960.669,
			1961.822,
			1962.595,
			1963.379,
			1964.577,
			1965.394,
			1967.085,
			1967.518,
			1969.291,
			1969.745,
			1971.147,
			1972.138,
			1973.659,
			1974.697,
			1975.755,
			1976.304,
			1977.982,
			1979.123,
			1980.283,
			1982.076,
			1983.299,
			1985.166,
			1986.433,
			1988.349,
			1989.625,
			1991.56,
			1992.863,
			1994.851,
			1996.858,
			1998.22,
			1999.598,
			2000.293,
			2001.695,
			2003.113,
			2005.27,
			2006.73,
			2009.704,
			2010.448,
			2012.697,
			2014.2,
			2016.459,
			2017.98,
			2024.088,
			2026.389,
			2027.949,
			2029.506,
			2031.852,
			2033.467,
			2035.882,
			2037.549,
			2040.115,
			2041.825,
			2044.472,
			2046.228,
			2048.725,
			2050.366,
			2052.702,
			2054.894,
			2056.354,
			2057.769,
			2059.875,
			2061.287,
			2064.156,
			2064.881,
			2067.082,
			2068.581,
			2070.856,
			2072.395,
			2073.955,
			2077.12,
			2078.733,
			2080.36,
			2081.179,
			2082.001,
			2084.505,
			2086.186,
			2088.711,
			2090.41,
			2092.983,
			2095.587,
			2097.339,
			2099.104,
			2101.776,
			2103.573,
			2106.276,
			2108.104,
			2111.796,
			2112.727,
			2115.537,
			2117.425,
			2119.323,
			2120.277,
			2122.192,
			2125.082,
			2127.995,
			2129.948,
			2131.911,
			2134.874,
			2136.861,
			2139.862,
			2141.876,
			2144.918,
			2146.96,
			2150.044,
			2152.114,
			2154.194,
			2155.237,
			2157.321,
			2160.454,
			2162.553,
			2163.607,
			2165.727,
			2167.856,
			2171.071,
			2173.245,
			2176.497,
			2178.677,
			2181.963,
			2184.164,
			2187.481,
			2189.702,
			2193.043,
			2195.277,
			2196.395,
			2198.633,
			2200.882,
			2202.009,
			2204.264,
			2206.553,
			2209.998,
			2212.338,
			2215.884,
			2217.097,
			2218.31,
			2222.066,
			2224.632,
			2228.458,
			2230.945,
			2233.285,
			2237.864,
			2240.114,
			2242.37,
			2243.5,
			2245.761,
			2248.055,
			2250.373,
			2253.926,
			2256.29,
			2259.778,
			2263.238,
			2265.536,
			2267.819,
			2271.286,
			2273.608,
			2277.108,
			2278.278,
			2279.451,
			2284.164,
			2285.348,
			2288.917,
			2291.31,
			2293.713,
			2294.919,
			2297.336,
			2299.767,
			2300.985,
			2304.655,
			2307.113,
			2309.58,
			2313.297,
			2315.786,
			2319.535,
			2320.789,
			2322.045,
			2325.826,
			2328.356,
			2332.167,
			2334.719,
			2337.278,
			2338.56,
			2341.132,
			2342.421,
			2345.004,
			2348.892,
			2351.487,
			2354.082,
			2357.972,
			2360.592,
			2364.497,
			2367.09,
			2371.045,
			2373.657,
			2377.621,
			2380.271,
			2384.259,
			2386.948,
			2390.939,
			2394.971,
			2397.67,
			2400.377,
			2404.454,
			2405.816,
			2407.18,
			2409.911,
			2414.021,
			2418.152,
			2420.924,
			2425.108,
			2427.908,
			2430.707,
			2432.109,
			2436.312,
			2439.118,
			2441.926,
			2443.333,
			2444.739,
			2448.968,
			2453.212,
			2454.632,
			2456.053,
			2458.9,
			2463.187,
			2467.488,
			2470.363,
			2473.246,
			2477.582,
			2480.48,
			2484.842,
			2486.299,
			2487.758,
			2493.608,
			2495.074,
			2499.482,
			2502.428,
			2505.381,
			2506.86,
			2509.823,
			2514.28,
			2518.751,
			2521.74,
			2524.737,
			2532.271,
			2536.815,
			2538.331,
			2539.846,
			2544.398,
			2547.438,
			2552.005,
			2555.057,
			2558.114,
			2559.649,
			2562.721,
			2565.805,
			2567.349,
			2571.983,
			2575.073,
			2578.162,
			2582.796,
			2585.888,
			2590.534,
			2593.642,
			2598.323,
			2601.457,
			2606.176,
			2609.341,
			2612.514,
			2614.103,
			2618.809,
			2621.903,
			2624.97,
			2626.557,
			2629.734,
			2632.963,
			2637.892,
			2639.178
		],
		[
			0,
			0.036,
			0.1,
			0.161,
			0.193,
			0.224,
			0.303,
			0.347,
			0.414,
			0.472,
			0.52,
			0.534,
			0.598,
			0.628,
			0.66,
			0.719,
			0.737,
			0.783,
			0.848,
			0.908,
			0.969,
			1.032,
			1.064,
			1.096,
			1.158,
			1.22,
			1.283,
			1.343,
			1.404,
			1.503,
			1.532,
			1.594,
			1.61,
			1.657,
			1.72,
			1.78,
			1.843,
			1.936,
			1.968,
			2.028,
			2.043,
			2.091,
			2.155,
			2.216,
			2.262,
			2.34,
			2.372,
			2.402,
			2.482,
			2.527,
			2.591,
			2.653,
			2.699,
			2.715,
			2.776,
			2.837,
			2.918,
			2.964,
			3.026,
			3.088,
			3.15,
			3.212,
			3.275,
			3.353,
			3.399,
			3.461,
			3.523,
			3.575,
			3.585,
			3.647,
			3.711,
			3.787,
			3.835,
			3.897,
			3.96,
			4.004,
			4.021,
			4.083,
			4.116,
			4.145,
			4.224,
			4.27,
			4.334,
			4.395,
			4.457,
			4.521,
			4.55,
			4.582,
			4.646,
			4.708,
			4.769,
			4.83,
			4.877,
			4.893,
			4.954,
			4.987,
			5.017,
			5.095,
			5.144,
			5.203,
			5.266,
			5.313,
			5.392,
			5.453,
			5.533,
			5.579,
			5.647,
			5.704,
			5.749,
			5.764,
			5.827,
			5.89,
			5.967,
			6.014,
			6.076,
			6.14,
			6.186,
			6.263,
			6.293,
			6.327,
			6.404,
			6.45,
			6.514,
			6.574,
			6.623,
			6.639,
			6.699,
			6.762,
			6.841,
			6.888,
			6.95,
			7.011,
			7.075,
			7.136,
			7.197,
			7.276,
			7.322,
			7.387,
			7.446,
			7.51,
			7.603,
			7.634,
			7.716,
			7.76,
			7.82,
			7.882,
			7.93,
			7.947,
			8.009,
			8.071,
			8.149,
			8.195,
			8.258,
			8.321,
			8.383,
			8.444,
			8.507,
			8.584,
			8.63,
			8.695,
			8.757,
			8.801,
			8.818,
			8.881,
			8.911,
			8.942,
			9.019,
			9.067,
			9.131,
			9.191,
			9.252,
			9.317,
			9.347,
			9.38,
			9.441,
			9.458,
			9.504,
			9.565,
			9.629,
			9.676,
			9.69,
			9.787,
			9.814,
			9.878,
			9.892,
			9.94,
			10.003,
			10.064,
			10.112,
			10.189,
			10.22,
			10.252,
			10.314,
			10.377,
			10.437,
			10.501,
			10.564,
			10.626,
			10.657,
			10.688,
			10.751,
			10.767,
			10.812,
			10.876,
			10.938,
			10.985,
			11.063,
			11.093,
			11.125,
			11.201,
			11.249,
			11.313,
			11.374,
			11.42,
			11.436,
			11.499,
			11.529,
			11.561,
			11.638,
			11.685,
			11.749,
			11.811,
			11.873,
			11.933,
			11.967,
			11.995,
			12.059,
			12.122,
			12.183,
			12.246,
			12.294,
			12.309,
			12.37,
			12.403,
			12.433,
			12.511,
			12.557,
			12.621,
			12.683,
			12.729,
			12.745,
			12.808,
			12.836,
			12.87,
			12.946,
			12.994,
			13.058,
			13.12,
			13.164,
			13.245,
			13.306,
			13.383,
			13.43,
			13.493,
			13.553,
			13.617,
			13.68,
			13.713,
			13.739,
			13.82,
			13.866,
			13.934,
			13.992,
			14.035,
			14.115,
			14.148,
			14.179,
			14.257,
			14.304,
			14.366,
			14.426,
			14.488,
			14.552,
			14.584,
			14.613,
			14.676,
			14.691,
			14.738,
			14.801,
			14.864,
			14.911,
			15.02,
			15.052,
			15.114,
			15.175,
			15.236,
			15.301,
			15.346,
			15.362,
			15.424,
			15.457,
			15.486,
			15.564,
			15.611,
			15.675,
			15.735,
			15.782,
			15.86,
			15.924,
			15.985,
			16.049,
			16.111,
			16.17,
			16.22,
			16.234,
			16.297,
			16.359,
			16.438,
			16.483,
			16.547,
			16.609,
			16.656,
			16.734,
			16.764,
			16.797,
			16.858,
			16.921,
			16.982,
			17.046,
			17.108,
			17.169,
			17.202,
			17.233,
			17.311,
			17.357,
			17.42,
			17.482,
			17.544,
			17.639,
			17.669,
			17.732,
			17.795,
			17.857,
			17.917,
			17.98,
			18.042,
			18.106,
			18.183,
			18.23,
			18.292,
			18.356,
			18.418,
			18.511,
			18.543,
			18.62,
			18.668,
			18.727,
			18.791,
			18.836,
			18.914,
			18.978,
			19.056,
			19.103,
			19.165,
			19.226,
			19.289,
			19.351,
			19.383,
			19.415,
			19.493,
			19.538,
			19.602,
			19.663,
			19.709,
			19.786,
			19.852,
			19.928,
			19.975,
			20.036,
			20.1,
			20.163,
			20.225,
			20.287,
			20.365,
			20.412,
			20.475,
			20.537,
			20.585,
			20.661,
			20.694,
			20.723,
			20.801,
			20.85,
			20.911,
			20.974,
			21.02,
			21.034,
			21.099,
			21.161,
			21.239,
			21.284,
			21.352,
			21.41,
			21.457,
			21.535,
			21.596,
			21.675,
			21.721,
			21.784,
			21.845,
			21.892,
			21.909,
			21.971,
			22.001,
			22.033,
			22.111,
			22.158,
			22.225,
			22.282,
			22.329,
			22.345,
			22.408,
			22.47,
			22.547,
			22.592,
			22.657,
			22.717,
			22.764,
			22.841,
			22.903,
			22.983,
			23.027,
			23.091,
			23.153,
			23.215,
			23.278,
			23.341,
			23.418,
			23.464,
			23.527,
			23.59,
			23.651,
			23.746,
			23.775,
			23.854,
			23.9,
			23.963,
			24.027,
			24.089,
			24.15,
			24.212,
			24.295,
			24.338,
			24.4,
			24.461,
			24.525,
			24.586,
			24.65,
			24.727,
			24.771,
			24.836,
			24.897,
			24.961,
			25.055,
			25.084,
			25.163,
			25.209,
			25.273,
			25.335,
			25.396,
			25.491,
			25.52,
			25.583,
			25.645,
			25.708,
			25.77,
			25.832,
			25.895,
			25.958,
			26.036,
			26.083,
			26.144,
			26.206,
			26.255,
			26.269,
			26.332,
			26.392,
			26.469,
			26.519,
			26.58,
			26.643,
			26.691,
			26.706,
			26.767,
			26.828,
			26.908,
			26.954,
			27.015,
			27.078,
			27.125,
			27.138,
			27.202,
			27.265,
			27.342,
			27.388,
			27.451,
			27.514,
			27.577,
			27.638,
			27.7,
			27.778,
			27.825,
			27.886,
			27.949,
			27.998,
			28.012,
			28.073,
			28.136,
			28.213,
			28.261,
			28.325,
			28.387,
			28.447,
			28.511,
			28.543,
			28.571,
			28.651,
			28.697,
			28.759,
			28.822,
			28.869,
			28.883,
			28.945,
			28.978,
			29.008,
			29.086,
			29.133,
			29.195,
			29.258,
			29.32,
			29.383,
			29.415,
			29.445,
			29.52,
			29.571,
			29.633,
			29.693,
			29.738,
			29.756,
			29.85,
			29.88,
			29.944,
			29.958,
			30.006,
			30.068,
			30.129,
			30.192,
			30.288,
			30.318,
			30.381,
			30.442,
			30.508,
			30.566,
			30.629,
			30.691,
			30.72,
			30.739,
			30.831,
			30.877,
			30.941,
			31.003,
			31.065,
			31.127,
			31.159,
			31.19,
			31.268,
			31.314,
			31.376,
			31.44,
			31.484,
			31.564,
			31.626,
			31.686,
			31.751,
			31.813,
			31.876,
			31.936,
			32.031,
			32.062,
			32.124,
			32.141,
			32.187,
			32.25,
			32.311,
			32.374,
			32.466,
			32.499,
			32.561,
			32.581,
			32.624,
			32.686,
			32.748,
			32.81,
			32.87,
			32.905,
			32.934,
			33.013,
			33.058,
			33.12,
			33.183,
			33.246,
			33.341,
			33.37,
			33.434,
			33.495,
			33.559,
			33.618,
			33.684,
			33.776,
			33.808,
			33.869,
			33.886,
			33.93,
			33.995,
			34.057,
			34.119,
			34.18,
			34.211,
			34.242,
			34.304,
			34.32,
			34.366,
			34.431,
			34.492,
			34.556,
			34.653,
			34.679,
			34.758,
			34.804,
			34.867,
			34.928,
			34.992,
			35.053,
			35.114,
			35.178,
			35.194,
			35.24,
			35.303,
			35.366,
			35.411,
			35.49,
			35.553,
			35.629,
			35.676,
			35.738,
			35.801,
			35.847,
			35.863,
			35.926,
			35.958,
			35.986,
			36.051,
			36.065,
			36.114,
			36.175,
			36.238,
			36.283,
			36.394,
			36.424,
			36.485,
			36.55,
			36.61,
			36.674,
			36.736,
			36.797,
			36.859,
			36.92,
			36.953,
			36.984,
			37.061,
			37.108,
			37.172,
			37.233,
			37.297,
			37.358,
			37.389,
			37.419,
			37.5,
			37.545,
			37.608,
			37.671,
			37.733,
			37.794,
			37.857,
			37.936,
			37.983,
			38.045,
			38.107,
			38.153,
			38.168,
			38.231,
			38.263,
			38.294,
			38.37,
			38.418,
			38.481,
			38.542,
			38.603,
			38.699,
			38.729,
			38.792,
			38.811,
			38.853,
			38.917,
			38.979,
			39.042,
			39.102,
			39.135,
			39.165,
			39.228,
			39.244,
			39.291,
			39.352,
			39.415,
			39.476,
			39.54,
			39.57,
			39.602,
			39.681,
			39.726,
			39.787,
			39.852,
			39.898,
			39.914,
			39.975,
			40.007,
			40.037,
			40.116,
			40.164,
			40.227,
			40.287,
			40.335,
			40.411,
			40.476,
			40.551,
			40.599,
			40.662,
			40.723,
			40.786,
			40.847,
			40.884,
			40.911,
			40.99,
			41.034,
			41.098,
			41.161,
			41.208,
			41.285,
			41.317,
			41.346,
			41.425,
			41.47,
			41.535,
			41.595,
			41.643,
			41.659,
			41.718,
			41.783,
			41.861,
			41.907,
			41.97,
			42.031,
			42.078,
			42.094,
			42.158,
			42.22,
			42.297,
			42.344,
			42.404,
			42.468,
			42.53,
			42.593,
			42.653,
			42.733,
			42.781,
			42.844,
			42.903,
			42.966,
			43.03,
			43.091,
			43.152,
			43.17,
			43.215,
			43.278,
			43.342,
			43.403,
			43.465,
			43.496,
			43.529,
			43.607,
			43.652,
			43.714,
			43.778,
			43.824,
			43.838,
			43.902,
			43.932,
			43.965,
			44.042,
			44.086,
			44.151,
			44.212,
			44.26,
			44.338,
			44.4,
			44.48,
			44.525,
			44.589,
			44.65,
			44.696,
			44.713,
			44.775,
			44.838,
			44.914,
			44.962,
			45.03,
			45.085,
			45.147,
			45.211,
			45.274,
			45.351,
			45.397,
			45.46,
			45.522,
			45.569,
			45.585,
			45.648,
			45.71,
			45.77,
			45.786,
			45.833,
			45.897,
			45.958,
			46.019,
			46.115,
			46.144,
			46.209,
			46.269,
			46.333,
			46.394,
			46.458,
			46.551,
			46.581,
			46.643,
			46.658,
			46.706,
			46.767,
			46.83,
			46.892,
			46.954,
			46.986,
			47.017,
			47.08,
			47.141,
			47.204,
			47.267,
			47.328,
			47.391,
			47.421,
			47.453,
			47.531,
			47.578,
			47.639,
			47.7,
			47.749,
			47.764,
			47.827,
			47.858,
			47.887,
			47.966,
			48.012,
			48.076,
			48.135,
			48.183,
			48.262,
			48.325,
			48.403,
			48.45,
			48.516,
			48.574,
			48.635,
			48.7,
			48.72,
			48.759,
			48.84,
			48.884,
			48.949,
			49.011,
			49.058,
			49.136,
			49.169,
			49.198,
			49.276,
			49.323,
			49.385,
			49.446,
			49.494,
			49.509,
			49.57,
			49.634,
			49.71,
			49.759,
			49.822,
			49.883,
			49.929,
			49.946,
			50.008,
			50.07,
			50.149,
			50.195,
			50.257,
			50.32,
			50.365,
			50.381,
			50.443,
			50.477,
			50.507,
			50.584,
			50.633,
			50.694,
			50.756,
			50.802,
			50.816,
			50.88,
			50.943,
			51.019,
			51.066,
			51.128,
			51.192,
			51.253,
			51.316,
			51.347,
			51.378,
			51.456,
			51.503,
			51.566,
			51.629,
			51.675,
			51.69,
			51.751,
			51.784,
			51.814,
			51.891,
			51.937,
			52,
			52.063,
			52.11,
			52.186,
			52.252,
			52.328,
			52.374,
			52.436,
			52.5,
			52.562,
			52.624,
			52.656,
			52.686,
			52.764,
			52.811,
			52.875,
			52.935,
			52.984,
			53.062,
			53.092,
			53.123,
			53.201,
			53.248,
			53.312,
			53.37,
			53.42,
			53.436,
			53.498,
			53.559,
			53.636,
			53.685,
			53.747,
			53.809,
			53.854,
			53.869,
			53.933,
			53.995,
			54.073,
			54.118,
			54.183,
			54.244,
			54.307,
			54.37,
			54.432,
			54.508,
			54.555,
			54.618,
			54.681,
			54.739,
			54.834,
			54.867,
			54.945,
			54.992,
			55.053,
			55.116,
			55.177,
			55.271,
			55.304,
			55.386,
			55.427,
			55.492,
			55.551,
			55.615,
			55.676,
			55.708,
			55.737,
			55.815,
			55.862,
			55.926,
			55.988,
			56.035,
			56.113,
			56.176,
			56.253,
			56.301,
			56.361,
			56.424,
			56.485,
			56.579,
			56.611,
			56.675,
			56.69,
			56.735,
			56.8,
			56.861,
			56.924,
			57.016,
			57.049,
			57.109,
			57.17,
			57.236,
			57.297,
			57.483,
			57.562,
			57.608,
			57.67,
			57.735,
			57.796,
			57.859,
			57.92,
			57.998,
			58.045,
			58.109,
			58.17,
			58.234,
			58.296,
			58.358,
			58.434,
			58.483,
			58.545,
			58.607,
			58.669,
			58.761,
			58.793,
			58.872,
			58.919,
			58.983,
			59.042,
			59.103,
			59.198,
			59.231,
			59.293,
			59.308,
			59.354,
			59.418,
			59.48,
			59.542,
			59.602,
			59.666,
			59.743,
			59.792,
			59.852,
			59.915,
			59.978,
			60.041,
			60.102,
			60.181,
			60.228,
			60.291,
			60.353,
			60.399,
			60.414,
			60.478,
			60.539,
			60.617,
			60.664,
			60.727,
			60.788,
			60.833,
			60.913,
			60.976,
			61.054,
			61.099,
			61.162,
			61.225,
			61.269,
			61.286,
			61.349,
			61.411,
			61.474,
			61.49,
			61.537,
			61.602,
			61.661,
			61.708,
			61.786,
			61.847,
			61.925,
			61.969,
			62.036,
			62.097,
			62.159,
			62.22,
			62.252,
			62.283,
			62.345,
			62.36,
			62.408,
			62.469,
			62.531,
			62.596,
			62.658,
			62.69,
			62.72,
			62.795,
			62.845,
			62.908,
			62.969,
			63.03,
			63.124,
			63.156,
			63.218,
			63.232,
			63.281,
			63.343,
			63.402,
			63.466,
			63.528,
			63.591,
			63.675,
			63.716,
			63.778,
			63.841,
			63.887,
			63.965,
			63.996,
			64.026,
			64.105,
			64.151,
			64.213,
			64.276,
			64.323,
			64.336,
			64.4,
			64.432,
			64.464,
			64.542,
			64.587,
			64.65,
			64.712,
			64.775,
			64.837,
			64.867,
			64.898,
			64.978,
			65.024,
			65.086,
			65.148,
			65.197,
			65.21,
			65.274,
			65.304,
			65.336,
			65.413,
			65.459,
			65.524,
			65.586,
			65.632,
			65.71,
			65.77,
			65.832,
			65.894,
			65.958,
			66.019,
			66.082,
			66.144,
			66.207,
			66.285,
			66.333,
			66.393,
			66.457,
			66.504,
			66.518,
			66.58,
			66.643,
			66.707,
			66.767,
			66.831,
			66.893,
			66.94,
			66.956,
			67.05,
			67.08,
			67.144,
			67.158,
			67.203,
			67.268,
			67.331,
			67.377,
			67.391,
			67.454,
			67.516,
			67.594,
			67.643,
			67.704,
			67.767,
			67.828,
			67.892,
			67.92,
			67.953,
			68.031,
			68.079,
			68.141,
			68.203,
			68.25,
			68.264,
			68.328,
			68.39,
			68.467,
			68.514,
			68.576,
			68.684,
			68.764,
			68.793,
			68.826,
			68.903,
			68.951,
			69.013,
			69.076,
			69.121,
			69.135,
			69.2,
			69.232,
			69.263,
			69.341,
			69.385,
			69.45,
			69.51,
			69.574,
			69.636,
			69.699,
			69.762,
			69.824,
			69.891,
			69.948,
			69.993,
			70.01,
			70.104,
			70.134,
			70.198,
			70.212,
			70.26,
			70.32,
			70.383,
			70.411
		]
	],
	'7272_7273_7982': [
		[
			109.383
		],
		[
			0
		],
		[
			0,
			1.1,
			2.416,
			6.374,
			9.021,
			13.005,
			15.669,
			18.342,
			19.68,
			22.363,
			26.4,
			30.453,
			33.164,
			35.882,
			39.973,
			42.71,
			46.827,
			49.581,
			53.724,
			56.496,
			60.664,
			63.452,
			66.246,
			67.646,
			70.451,
			74.672,
			78.909,
			81.743,
			84.582,
			88.854,
			90.281,
			91.709,
			94.57,
			98.874,
			103.193,
			106.081,
			110.424,
			113.326,
			116.233,
			120.606,
			123.527,
			129.385,
			130.854,
			135.291,
			138.251,
			142.688,
			148.594,
			150.074,
			154.522,
			157.494,
			160.47,
			164.941,
			167.926,
			172.409,
			175.401,
			179.908,
			182.938,
			187.537,
			190.654,
			195.417,
			198.649,
			200.279,
			203.554,
			208.476,
			211.747,
			214.967,
			219.789,
			222.997,
			227.785,
			229.36,
			230.936,
			235.625,
			238.742,
			243.407,
			246.498,
			251.086,
			254.101,
			255.594,
			258.55,
			262.907,
			265.765,
			268.586,
			272.744,
			275.467,
			280.797,
			282.106,
			285.981,
			288.519,
			292.256,
			294.702,
			298.302,
			300.661,
			301.827,
			304.136,
			306.408,
			307.529,
			309.746,
			311.925,
			314.068,
			317.215,
			321.284,
			322.278,
			325.212,
			327.124,
			329.004,
			331.76,
			333.577,
			337.086,
			337.944,
			340.471,
			341.298,
			342.119,
			344.541,
			346.124,
			348.457,
			349.985,
			351.498,
			352.25,
			353.736,
			354.474,
			355.945,
			357.414,
			359.577,
			361.012,
			363.846,
			364.542,
			366.627,
			368.012,
			370.059,
			371.425,
			373.553,
			375.056,
			377.319,
			380.086,
			382.012,
			384.136,
			387.516,
			389.674,
			392.883,
			393.779,
			394.677,
			397.376,
			399.078,
			401.584,
			403.266,
			404.968,
			405.821,
			407.529,
			410.076,
			412.629,
			414.303,
			415.972,
			418.474,
			420.106,
			422.566,
			423.381,
			424.185,
			426.61,
			428.239,
			430.712,
			432.374,
			434.914,
			437.491,
			439.237,
			441.889,
			442.779,
			445.49,
			448.232,
			449.157,
			450.086,
			452.889,
			454.776,
			457.627,
			459.527,
			461.45,
			464.332,
			466.239,
			469.057,
			470.895,
			474.461,
			475.329,
			477.879,
			479.533,
			481.946,
			485.036,
			485.786,
			487.258,
			487.981,
			489.41,
			490.815,
			492.895,
			494.276,
			496.354,
			497.042,
			497.728,
			499.777,
			500.455,
			501.131,
			503.143,
			504.469,
			505.779,
			506.431,
			509.007,
			509.631,
			510.873,
			511.491,
			512.724,
			513.933,
			515.679,
			516.836,
			518.568,
			519.145,
			519.721,
			521.443,
			522.587,
			524.377,
			525.636,
			527.526,
			528.836,
			529.62,
			531.189,
			532.761,
			533.549,
			535.805,
			538.097,
			541.526,
			543.834,
			547.643,
			548.835,
			549.654,
			552.118,
			553.745,
			555.512,
			556.694,
			558.477,
			559.79,
			560.462,
			561.812,
			563.169,
			563.855,
			565.284,
			566.722,
			568.896,
			570.35,
			572.551,
			573.291,
			574.035,
			576.288,
			577.806,
			580.112,
			581.67,
			584.021,
			585.615,
			586.418,
			588.035,
			590.487,
			592.14,
			593.807,
			595.486,
			597.167,
			598.007,
			599.695,
			601.397,
			602.253,
			604.841,
			606.583,
			608.352,
			611.008,
			612.795,
			615.497,
			616.404,
			617.314,
			620.991,
			621.919,
			624.742,
			626.681,
			628.633,
			629.632,
			631.662,
			634.715,
			637.736,
			639.761,
			641.786,
			644.868,
			646.979,
			650.205,
			652.424,
			655.843,
			658.183,
			661.706,
			664.062,
			667.466,
			669.728,
			673.07,
			676.396,
			678.612,
			680.771,
			683.988,
			686.097,
			690.33,
			691.396,
			694.608,
			696.772,
			700.038,
			702.233,
			705.548,
			707.773,
			708.889,
			711.13,
			713.383,
			714.512,
			716.776,
			719.049,
			722.465,
			724.751,
			729.339,
			730.496,
			733.979,
			736.322,
			739.854,
			742.22,
			744.595,
			748.174,
			751.774,
			755.392,
			757.816,
			760.248,
			762.669,
			766.347,
			771.301,
			772.54,
			776.254,
			778.757,
			782.55,
			785.055,
			787.589,
			791.404,
			793.958,
			799.082,
			800.367,
			804.231,
			806.815,
			809.405,
			810.7,
			815.887,
			817.189,
			819.799,
			821.107,
			823.729,
			826.357,
			830.311,
			832.953,
			836.928,
			839.587,
			843.593,
			846.27,
			850.292,
			852.975,
			857.022,
			862.46,
			863.826,
			866.564,
			867.935,
			870.683,
			873.439,
			877.586,
			880.36,
			884.535,
			887.327,
			892.932,
			894.338,
			898.565,
			901.391,
			905.643,
			909.91,
			912.777,
			917.104,
			920.001,
			922.873,
			927.124,
			929.907,
			935.36,
			936.7,
			940.662,
			943.256,
			947.076,
			949.577,
			953.272,
			955.695,
			959.274,
			962.776,
			965.064,
			967.311,
			970.61,
			972.763,
			975.922,
			976.956,
			977.981,
			981.001,
			982.968,
			984.898,
			987.721,
			989.557,
			993.119,
			993.988,
			996.536,
			997.365,
			998.185,
			1000.591,
			1002.15,
			1004.421,
			1007.336,
			1008.043,
			1010.113,
			1011.457,
			1012.77,
			1014.691,
			1015.923,
			1018.283,
			1018.851,
			1020.506,
			1021.562,
			1023.09,
			1024.079,
			1025.048,
			1025.525,
			1026.453,
			1027.365,
			1027.817,
			1029.161,
			1030.052,
			1030.926,
			1032.213,
			1033.07,
			1034.791,
			1035.223,
			1036.481,
			1037.314,
			1038.57,
			1039.412,
			1040.708,
			1041.664,
			1042.143,
			1043.102,
			1044.063,
			1044.545,
			1045.511,
			1046.968,
			1049.16,
			1050.713,
			1056.266,
			1057.44,
			1060.756,
			1061.917,
			1063.662,
			1064.83,
			1066.422,
			1067.897,
			1068.885,
			1069.88,
			1070.38,
			1071.391,
			1072.409,
			1073.949,
			1074.467,
			1074.988,
			1076.582,
			1077.667,
			1079.312,
			1080.434,
			1081.579,
			1083.329,
			1084.515,
			1086.952,
			1087.575,
			1090.108,
			1090.754,
			1092.754,
			1094.088,
			1096.114,
			1098.888,
			1099.581,
			1100.988,
			1101.722,
			1103.188,
			1104.65,
			1106.871,
			1108.363,
			1110.591,
			1111.323,
			1112.051,
			1114.228,
			1115.673,
			1118.09,
			1119.697,
			1122.308,
			1125.932,
			1126.749,
			1129.193,
			1130.809,
			1132.268,
			1134.453,
			1135.904,
			1138.018,
			1139.423,
			1141.53,
			1142.93,
			1145.024,
			1146.456,
			1148.658,
			1150.127,
			1150.867,
			1152.386,
			1153.907,
			1154.668,
			1156.176,
			1157.634,
			1159.826,
			1161.277,
			1164.125,
			1164.84,
			1167.004,
			1168.458,
			1170.652,
			1172.118,
			1173.59,
			1174.33,
			1175.817,
			1176.558,
			1178.047,
			1180.298,
			1181.797,
			1183.296,
			1185.568,
			1187.088,
			1189.386,
			1190.157,
			1190.932,
			1193.276,
			1194.851,
			1197.239,
			1198.843,
			1200.455,
			1202.907,
			1205.374,
			1207.891,
			1208.734,
			1211.266,
			1212.946,
			1214.627,
			1215.468,
			1217.955,
			1219.606,
			1222.084,
			1223.737,
			1225.388,
			1227.856,
			1229.498,
			1231.951,
			1232.764,
			1233.578,
			1236.027,
			1237.647,
			1240.082,
			1241.707,
			1243.331,
			1244.143,
			1245.769,
			1248.174,
			1250.574,
			1252.152,
			1253.719,
			1256.069,
			1257.632,
			1259.977,
			1260.76,
			1261.553,
			1263.956,
			1265.563,
			1268.024,
			1269.679,
			1271.344,
			1272.188,
			1273.873,
			1274.717,
			1276.415,
			1279.005,
			1280.747,
			1282.504,
			1285.159,
			1286.943,
			1289.637,
			1290.539,
			1291.442,
			1294.158,
			1295.976,
			1298.702,
			1300.523,
			1303.245,
			1305.058,
			1305.965,
			1307.763,
			1310.453,
			1312.23,
			1313.991,
			1316.627,
			1318.367,
			1321.85,
			1322.733,
			1325.375,
			1327.199,
			1330.043,
			1331.993,
			1334.058,
			1338.192,
			1339.227,
			1343.217,
			1344.177,
			1347.014,
			1348.82,
			1351.536,
			1355.164,
			1356.074,
			1358.854,
			1360.719,
			1362.599,
			1363.544,
			1365.441,
			1367.35,
			1370.238,
			1372.176,
			1375.099,
			1377.061,
			1380.016,
			1382.001,
			1385,
			1389.038,
			1390.054,
			1393.122,
			1395.181,
			1397.26,
			1400.424,
			1402.557,
			1405.8,
			1407.976,
			1411.262,
			1413.432,
			1416.695,
			1418.872,
			1421.059,
			1422.158,
			1424.367,
			1427.696,
			1431.039,
			1433.273,
			1435.512,
			1438.876,
			1441.125,
			1444.499,
			1445.627,
			1446.758,
			1450.138,
			1452.398,
			1455.786,
			1458.058,
			1460.342,
			1461.488,
			1463.812,
			1467.329,
			1470.907,
			1473.311,
			1475.729,
			1479.368,
			1481.803,
			1485.472,
			1487.934,
			1491.659,
			1494.146,
			1497.925,
			1500.446,
			1504.224,
			1508.003,
			1510.526,
			1513.055,
			1514.322,
			1516.862,
			1519.406,
			1523.233,
			1525.792,
			1529.643,
			1530.93,
			1532.219,
			1536.094,
			1538.678,
			1542.562,
			1545.158,
			1547.761,
			1551.679,
			1554.301,
			1558.247,
			1560.888,
			1564.865,
			1567.527,
			1571.536,
			1574.22,
			1576.912,
			1578.261,
			1582.319,
			1585.034,
			1587.757,
			1591.857,
			1594.602,
			1598.739,
			1601.515,
			1605.695,
			1608.488,
			1612.68,
			1615.483,
			1618.292,
			1619.7,
			1622.52,
			1626.761,
			1631.019,
			1633.865,
			1636.717,
			1640.983,
			1643.805,
			1647.979,
			1650.716,
			1654.75,
			1657.392,
			1661.285,
			1663.834,
			1666.344,
			1667.586,
			1670.04,
			1673.653,
			1677.188,
			1679.501,
			1681.777,
			1685.121,
			1687.304,
			1690.507,
			1692.596,
			1695.66,
			1697.656,
			1700.581,
			1702.485,
			1704.352,
			1705.273,
			1707.088,
			1709.748,
			1712.33,
			1714.007,
			1715.646,
			1718.037,
			1718.816,
			1719.586,
			1721.841,
			1722.574,
			1723.299,
			1725.418,
			1726.788,
			1728.779,
			1730.062,
			1731.312,
			1731.922,
			1733.117,
			1734.271,
			1736.469,
			1736.997,
			1738.53,
			1739.509,
			1740.936,
			1741.874,
			1742.794,
			1743.251,
			1744.156,
			1744.606,
			1745.504,
			1746.401,
			1747.745,
			1748.639,
			1749.963,
			1750.841,
			1752.157,
			1753.036,
			1754.351,
			1755.22,
			1756.085,
			1756.516,
			1757.379,
			1758.702,
			1760.09,
			1761.01,
			1761.928,
			1763.304,
			1764.287,
			1765.758,
			1766.734,
			1768.195,
			1769.178,
			1770.644,
			1771.616,
			1773.073,
			1774.069,
			1775.569,
			1777.058,
			1778.049,
			1779.057,
			1780.57,
			1781.572,
			1783.071,
			1784.144,
			1785.801,
			1786.899,
			1788.58,
			1790.089,
			1792.342,
			1793.952,
			1796.843,
			1799.585,
			1801.281,
			1802.977,
			1805.247,
			1806.707,
			1809.393,
			1809.962,
			1811.674,
			1812.825,
			1813.899,
			1815.309,
			1816.254,
			1818.169,
			1818.649,
			1820.508,
			1820.977,
			1822.4,
			1823.363,
			1824.879,
			1825.909,
			1827.478,
			1829.115,
			1830.279,
			1831.458,
			1833.253,
			1834.506,
			1836.432,
			1837.736,
			1839.737,
			1841.099,
			1843.172,
			1844.575,
			1846.707,
			1848.149,
			1850.34,
			1852.555,
			1854.058,
			1855.574,
			1857.877,
			1859.431,
			1861.787,
			1862.58,
			1863.377,
			1865.788,
			1867.414,
			1869.879,
			1871.539,
			1874.053,
			1876.575,
			1878.262,
			1880.822,
			1882.544,
			1884.281,
			1886.914,
			1888.686,
			1891.367,
			1893.17,
			1895.911,
			1897.757,
			1900.549,
			1902.426,
			1905.257,
			1907.154,
			1910.021,
			1912.916,
			1914.861,
			1916.819,
			1919.778,
			1921.767,
			1925.779,
			1926.79,
			1929.839,
			1931.885,
			1934.976,
			1937.051,
			1940.182,
			1943.326,
			1945.422,
			1947.527,
			1948.583,
			1950.703,
			1952.834,
			1954.975,
			1958.205,
			1960.371,
			1963.638,
			1966.928,
			1969.134,
			1971.35,
			1972.461,
			1974.691,
			1976.929,
			1981.431,
			1982.561,
			1987.102,
			1988.242,
			1991.68,
			1993.985,
			1996.299,
			1997.459,
			1999.788,
			2003.301,
			2006.843,
			2009.217,
			2011.601,
			2015.195,
			2017.603,
			2021.232,
			2022.446,
			2023.662,
			2027.325,
			2029.778,
			2033.474,
			2035.948,
			2038.432,
			2039.677,
			2042.173,
			2044.679,
			2045.935,
			2049.716,
			2052.248,
			2054.789,
			2058.618,
			2061.181,
			2065.043,
			2067.632,
			2072.834,
			2074.14,
			2078.052,
			2080.663,
			2083.278,
			2084.589,
			2087.213,
			2091.165,
			2095.135,
			2097.792,
			2100.455,
			2104.458,
			2107.135,
			2112.512,
			2113.86,
			2117.916,
			2120.628,
			2124.711,
			2127.442,
			2130.178,
			2131.55,
			2135.674,
			2138.432,
			2141.195,
			2142.578,
			2145.343,
			2148.105,
			2152.224,
			2153.586,
			2154.94,
			2158.952,
			2160.271,
			2161.58,
			2165.45,
			2167.984,
			2170.48,
			2174.154,
			2176.557,
			2180.098,
			2181.261,
			2183.561,
			2186.938,
			2189.143,
			2191.31,
			2194.491,
			2195.533,
			2196.565,
			2198.602,
			2201.588,
			2204.492,
			2206.382,
			2208.236,
			2210.954,
			2212.725,
			2215.321,
			2217.023,
			2220.296,
			2221.095,
			2223.451,
			2224.991,
			2226.504,
			2227.253,
			2228.737,
			2230.934,
			2233.091,
			2234.523,
			2235.945,
			2238.047,
			2239.448,
			2241.522,
			2242.885,
			2244.931,
			2246.272,
			2248.253,
			2249.576,
			2251.667,
			2253.13,
			2255.329,
			2258.313,
			2260.373,
			2263.38,
			2267.509,
			2269.927,
			2273.202,
			2273.954,
			2276.179,
			2277.593,
			2279.715,
			2281.14,
			2283.302,
			2285.466,
			2286.932,
			2288.403,
			2289.138,
			2290.614,
			2292.117,
			2294.386,
			2295.915,
			2298.261,
			2299.84,
			2302.252,
			2303.883,
			2306.356,
			2308.03,
			2310.563,
			2313.133,
			2314.863,
			2317.486,
			2319.252,
			2321.03,
			2323.716,
			2324.622,
			2325.532,
			2327.356,
			2330.114,
			2332.891,
			2334.754,
			2344.255,
			2346.193,
			2350.102,
			2351.086,
			2354.059,
			2356.055,
			2359.07,
			2361.094,
			2364.152,
			2367.233,
			2369.302,
			2371.38,
			2374.516,
			2376.609,
			2379.751,
			2381.858,
			2385.035,
			2387.166,
			2390.379,
			2392.533,
			2395.782,
			2399.052,
			2401.233,
			2403.406,
			2404.486,
			2406.629,
			2408.742,
			2411.845,
			2413.868,
			2417.805,
			2418.766,
			2421.597,
			2423.438,
			2426.141,
			2427.918,
			2429.647,
			2430.5,
			2432.186,
			2433.021,
			2434.672,
			2437.101,
			2438.668,
			2440.228,
			2442.533,
			2443.289,
			2444.041,
			2446.292,
			2447.032,
			2447.765,
			2449.95,
			2451.39,
			2453.517,
			2454.917,
			2456.31,
			2457.005,
			2458.479,
			2459.211,
			2460.667,
			2462.98,
			2463.827,
			2466.346,
			2468.222,
			2470.236,
			2471.24,
			2473.798,
			2476.715,
			2477.858,
			2480.971,
			2482.649,
			2484.497,
			2485.723,
			2486.946,
			2488.719,
			2489.297,
			2489.885,
			2492.249,
			2492.859,
			2494.714,
			2495.949,
			2497.188,
			2497.883,
			2499.271,
			2501.354,
			2503.791,
			2505.579,
			2507.391,
			2510.6,
			2512.757,
			2516.11,
			2518.347,
			2521.699,
			2523.829,
			2526.946,
			2528.51,
			2529.943,
			2530.657,
			2532.087,
			2533.293,
			2533.869,
			2535.593,
			2536.741,
			2537.923,
			2539.761,
			2540.988,
			2542.851,
			2544.14,
			2546.082,
			2547.382,
			2549.384,
			2550.726,
			2552.758,
			2554.145,
			2556.239,
			2558.366,
			2559.808,
			2561.26,
			2563.468,
			2564.963,
			2567.23,
			2568.764,
			2571.094,
			2572.666,
			2575.051,
			2576.658,
			2579.091,
			2580.727,
			2583.206,
			2584.874,
			2585.713,
			2587.394,
			2589.061,
			2591.555,
			2593.24,
			2595.775,
			2596.626,
			2597.48,
			2600.048,
			2601.774,
			2604.385,
			2606.142,
			2607.911,
			2608.802,
			2610.588,
			2611.482,
			2612.374,
			2615.941,
			2616.816,
			2619.407,
			2621.101,
			2622.761,
			2623.585,
			2625.216,
			2627.625,
			2630.023,
			2631.622,
			2633.223,
			2635.629,
			2637.233,
			2639.64,
			2640.442,
			2641.244,
			2643.647,
			2645.244,
			2647.635,
			2649.222,
			2651.59,
			2654.725,
			2655.501,
			2657.829,
			2659.374,
			2660.914,
			2663.219,
			2664.827,
			2668.044,
			2668.93,
			2671.709,
			2672.633,
			2673.584,
			2676.752,
			2678.831,
			2681.823,
			2684.826,
			2686.842,
			2688.876,
			2689.927,
			2692.025,
			2694.042,
			2696.821,
			2698.654,
			2702.067,
			2702.922,
			2705.448,
			2707.134,
			2709.648,
			2711.314,
			2712.994,
			2716.3,
			2717.13,
			2719.603,
			2720.417,
			2722.055,
			2723.687,
			2725.281,
			2727.694,
			2729.302,
			2731.708,
			2734.154,
			2735.847,
			2737.553,
			2740.292,
			2742.228,
			2745.345,
			2747.557,
			2751.933,
			2753.002,
			2756.111,
			2758.135,
			2761.228,
			2765.431,
			2766.501,
			2768.648,
			2769.677,
			2771.689,
			2773.711,
			2776.632,
			2778.582,
			2782.521,
			2783.513,
			2786.506,
			2788.515,
			2791.55,
			2793.586,
			2795.632,
			2796.658,
			2798.717,
			2801.843,
			2805.004,
			2807.142,
			2809.279,
			2812.512,
			2814.685,
			2819.128,
			2820.263,
			2823.675,
			2825.974,
			2829.441,
			2831.69,
			2833.948,
			2835.063,
			2837.266,
			2840.581,
			2843.895,
			2846.096,
			2848.278,
			2851.5,
			2853.605,
			2857.691,
			2858.688,
			2861.61,
			2863.508,
			2866.299,
			2868.122,
			2870.796,
			2873.394,
			2874.241,
			2876.723,
			2877.531,
			2879.12,
			2880.678,
			2882.204,
			2884.422,
			2885.845,
			2887.906,
			2889.892,
			2891.15,
			2892.371,
			2894.135,
			2895.266,
			2896.901,
			2897.425,
			2897.942,
			2899.938,
			2900.415,
			2901.817,
			2902.734,
			2903.643,
			2904.094,
			2904.996,
			2906.282,
			2907.138,
			2907.566,
			2908.424,
			2909.285,
			2910.583,
			2911.448,
			2912.742,
			2913.173,
			2913.604,
			2914.901,
			2915.806,
			2917.176,
			2918.085,
			2918.993,
			2919.447,
			2920.369,
			2920.857,
			2921.827,
			2923.273,
			2924.234,
			2925.197,
			2926.757,
			2927.788,
			2929.329,
			2929.843,
			2930.365,
			2931.989,
			2933.061,
			2934.666,
			2935.767,
			2936.893,
			2937.453,
			2938.57,
			2939.129,
			2940.249,
			2941.472,
			2943.311,
			2944.538,
			2946.445,
			2947.743,
			2949.698,
			2950.355,
			2951.032,
			2953.06,
			2954.417,
			2956.481,
			2957.869,
			2959.26,
			2961.992,
			2962.665,
			2965.376,
			2966.045,
			2968.059,
			2969.412,
			2971.475,
			2974.272,
			2974.98,
			2977.053,
			2978.435,
			2979.831,
			2981.79,
			2983.06,
			2984.988,
			2985.631,
			2986.214,
			2987.976,
			2989.163,
			2990.953,
			2992.129,
			2993.908,
			2995.104,
			2996.342,
			2996.98,
			2998.261,
			2998.904,
			3000.196,
			3001.553,
			3003.661,
			3005.078,
			3007.239,
			3007.967,
			3008.697,
			3010.908,
			3012.402,
			3014.669,
			3016.198,
			3018.518,
			3020.086,
			3021.666,
			3022.459,
			3024.043,
			3024.847,
			3026.466,
			3028.096,
			3030.57,
			3032.236,
			3034.756,
			3036.438,
			3038.977,
			3040.687,
			3043.279,
			3045.026,
			3046.786,
			3047.67,
			3049.45,
			3052.145,
			3054.869,
			3056.703,
			3058.552,
			3061.351,
			3063.232,
			3066.08,
			3067.995,
			3070.893,
			3072.841,
			3075.788,
			3077.768,
			3080.762,
			3083.784,
			3085.813,
			3088.878,
			3090.935,
			3093.003,
			3096.124,
			3097.167,
			3098.225,
			3100.308,
			3103.447,
			3106.588,
			3108.702,
			3110.842,
			3114.038,
			3117.252,
			3119.397,
			3121.552,
			3125.867,
			3126.946,
			3130.172,
			3132.31,
			3135.479,
			3139.675,
			3140.712,
			3143.844,
			3145.954,
			3149.305,
			3151.683,
			3154.305,
			3158.348,
			3160.663,
			3164.742,
			3165.639,
			3168.308,
			3169.971,
			3172.408,
			3173.954,
			3176.194,
			3178.358,
			3179.763,
			3181.138,
			3183.184,
			3184.545,
			3187.28,
			3187.979,
			3190.335,
			3191.911,
			3194.725,
			3196.831,
			3199.127,
			3200.379,
			3202.886,
			3206.959,
			3210.698,
			3213.064,
			3214.643,
			3217.013,
			3218.513,
			3220.521,
			3221.19,
			3221.859,
			3223.896,
			3225.283,
			3227.354,
			3228.747,
			3230.153,
			3230.874,
			3232.293,
			3233.003,
			3234.454,
			3235.91,
			3236.638,
			3238.091,
			3239.562,
			3241.753,
			3243.197,
			3245.355,
			3246.065,
			3246.771,
			3248.868,
			3250.262,
			3252.334,
			3253.714,
			3255.101,
			3255.793,
			3257.173,
			3257.859,
			3259.222,
			3260.572,
			3261.24,
			3262.562,
			3263.871,
			3265.166,
			3267.09,
			3268.365,
			3269.626,
			3270.254,
			3271.504,
			3272.127,
			3273.369,
			3274.587,
			3276.396,
			3278.188,
			3279.931,
			3280.503,
			3282.209,
			3282.775,
			3283.34,
			3284.983,
			3286.063,
			3287.674,
			3288.745,
			3290.356,
			3291.96,
			3293.028,
			3294.138,
			3295.923,
			3297.113,
			3298.926,
			3299.628,
			3300.33,
			3302.441,
			3303.148,
			3303.856,
			3306.276,
			3307.899,
			3310.378,
			3312.053,
			3313.736,
			3314.544,
			3316.906,
			3318.487,
			3320,
			3322.206,
			3323.683,
			3325.932,
			3326.687,
			3327.443,
			3329.743,
			3331.332,
			3333.731,
			3335.384,
			3337.075,
			3337.924,
			3339.63,
			3342.212,
			3344.796,
			3346.396,
			3348.008,
			3350.378,
			3351.128,
			3351.88,
			3354.155,
			3355.661,
			3357.92,
			3359.442,
			3361.69,
			3363.2,
			3364.723,
			3365.464,
			3366.95,
			3367.698,
			3369.203,
			3370.71,
			3371.464,
			3372.98,
			3374.509,
			3376.875,
			3378.474,
			3380.905,
			3381.727,
			3382.549,
			3385.034,
			3386.727,
			3389.283,
			3391.008,
			3392.749,
			3395.38,
			3396.267,
			3398.049,
			3400.743,
			3402.558,
			3404.382,
			3407.142,
			3408.067,
			3408.996,
			3412.73,
			3413.67,
			3415.559,
			3416.506,
			3418.409,
			3420.325,
			3423.209,
			3425.147,
			3428.052,
			3429.009,
			3429.985,
			3432.913,
			3433.893,
			3434.876,
			3437.828,
			3439.796,
			3442.725,
			3444.647,
			3446.538,
			3447.472,
			3450.22,
			3452.014,
			3453.774,
			3456.35,
			3458.029,
			3461.286,
			3462.087,
			3464.478,
			3466.048,
			3468.405,
			3469.971,
			3472.321,
			3473.885,
			3475.444,
			3476.223,
			3477.781,
			3478.557,
			3480.106,
			3481.65,
			3483.958,
			3485.488,
			3488.517,
			3489.269,
			3491.51,
			3492.983,
			3495.18,
			3496.633,
			3498.781,
			3500.921,
			3502.32,
			3503.71,
			3504.404,
			3505.791,
			3507.188,
			3509.285,
			3510.682,
			3513.121,
			3513.975,
			3514.83,
			3517.787,
			3520.102,
			3523.432,
			3525.593,
			3527.6,
			3530.434,
			3531.377,
			3532.293,
			3535.91,
			3536.805,
			3539.382,
			3541.1,
			3542.764,
			3543.584,
			3546.853,
			3547.624,
			3549.165,
			3549.936,
			3551.477,
			3552.975,
			3555.208,
			3556.698,
			3559.69,
			3560.44,
			3563.455,
			3564.2,
			3566.482,
			3568.017,
			3570.342,
			3573.485,
			3574.279,
			3575.875,
			3576.678,
			3578.292,
			3579.917,
			3582.365,
			3583.996,
			3587.263,
			3588.074,
			3590.497,
			3592.104,
			3594.504,
			3596.095,
			3598.463,
			3600.027,
			3601.584,
			3602.361,
			3603.895,
			3604.658,
			3606.18,
			3607.697,
			3609.975,
			3611.484,
			3614.604,
			3615.396,
			3617.777,
			3619.524,
			3622.136,
			3623.965,
			3626.776,
			3628.607,
			3631.272,
			3633.847,
			3635.469,
			3637.093,
			3639.5,
			3641.09,
			3644.278,
			3645.075,
			3647.467,
			3649.061,
			3651.451,
			3653.046,
			3655.389,
			3657.703,
			3659.219,
			3660.716,
			3661.448,
			3662.892,
			3664.298,
			3665.674,
			3667.698,
			3669.022,
			3670.977,
			3672.907,
			3674.179,
			3676.064,
			3677.316,
			3678.563,
			3680.396,
			3681.614,
			3683.439,
			3684.684,
			3686.553,
			3687.801,
			3689.823,
			3691.243,
			3693.375,
			3695.662,
			3697.203,
			3698.743,
			3701.229,
			3702.884,
			3706.557,
			3707.508,
			3710.311,
			3712.147,
			3714.696,
			3716.143,
			3718.319,
			3719.67,
			3720.306,
			3721.58,
			3723.497,
			3724.753,
			3725.996,
			3727.869,
			3729.128,
			3731.671,
			3732.312,
			3734.255,
			3735.605,
			3737.649,
			3739.026,
			3740.437,
			3741.148,
			3742.58,
			3744.761,
			3747,
			3748.511,
			3750.043,
			3752.382,
			3753.96,
			3757.173,
			3757.986,
			3760.448,
			3762.109,
			3764.625,
			3766.314,
			3768.001,
			3768.846,
			3770.543,
			3773.094,
			3775.683,
			3777.42,
			3779.169,
			3781.794,
			3782.673,
			3783.556,
			3786.203,
			3787.971,
			3790.643,
			3792.435,
			3795.148,
			3797.008,
			3799.82,
			3801.738,
			3804.644,
			3807.58,
			3808.564,
			3811.531,
			3814.519,
			3815.52,
			3816.525,
			3818.542,
			3821.59,
			3824.666,
			3826.729,
			3828.804,
			3831.93,
			3834.013,
			3837.149,
			3839.252,
			3843.479,
			3844.541,
			3847.742,
			3849.89,
			3853.143,
			3857.521,
			3858.62,
			3861.93,
			3864.146,
			3866.369,
			3869.705,
			3871.935,
			3875.291,
			3877.55,
			3880.968,
			3883.273,
			3886.754,
			3889.087,
			3892.605,
			3894.961,
			3898.515,
			3902.091,
			3904.495,
			3906.909,
			3910.554,
			3912.992,
			3916.661,
			3917.905,
			3919.157,
			3922.913,
			3925.401,
			3929.14,
			3931.626,
			3934.12,
			3935.364,
			3937.854,
			3941.624,
			3945.425,
			3947.972,
			3950.529,
			3954.382,
			3955.669,
			3956.958,
			3960.837,
			3962.134,
			3963.433,
			3967.337,
			3969.941,
			3973.859,
			3976.479,
			3978.429
		],
		[
			0,
			0.019,
			0.051,
			0.129,
			0.176,
			0.238,
			0.299,
			0.347,
			0.363,
			0.423,
			0.485,
			0.563,
			0.612,
			0.675,
			0.734,
			0.799,
			0.86,
			0.923,
			1,
			1.047,
			1.113,
			1.171,
			1.219,
			1.235,
			1.297,
			1.359,
			1.434,
			1.484,
			1.545,
			1.609,
			1.655,
			1.668,
			1.732,
			1.794,
			1.874,
			1.92,
			1.986,
			2.043,
			2.107,
			2.166,
			2.229,
			2.308,
			2.354,
			2.42,
			2.478,
			2.541,
			2.635,
			2.666,
			2.745,
			2.791,
			2.852,
			2.915,
			2.962,
			3.039,
			3.101,
			3.181,
			3.226,
			3.291,
			3.352,
			3.414,
			3.476,
			3.507,
			3.539,
			3.617,
			3.664,
			3.727,
			3.789,
			3.836,
			3.914,
			3.944,
			3.975,
			4.038,
			4.101,
			4.162,
			4.224,
			4.286,
			4.35,
			4.38,
			4.41,
			4.49,
			4.535,
			4.598,
			4.661,
			4.723,
			4.816,
			4.847,
			4.925,
			4.973,
			5.036,
			5.097,
			5.159,
			5.222,
			5.253,
			5.283,
			5.345,
			5.361,
			5.408,
			5.469,
			5.532,
			5.595,
			5.69,
			5.719,
			5.783,
			5.845,
			5.907,
			5.969,
			6.032,
			6.129,
			6.15,
			6.218,
			6.232,
			6.279,
			6.342,
			6.404,
			6.466,
			6.53,
			6.56,
			6.592,
			6.654,
			6.67,
			6.716,
			6.779,
			6.84,
			6.901,
			6.998,
			7.028,
			7.09,
			7.151,
			7.214,
			7.277,
			7.339,
			7.4,
			7.463,
			7.541,
			7.588,
			7.651,
			7.713,
			7.761,
			7.838,
			7.867,
			7.901,
			7.977,
			8.024,
			8.09,
			8.15,
			8.2,
			8.211,
			8.273,
			8.335,
			8.413,
			8.46,
			8.524,
			8.585,
			8.648,
			8.71,
			8.742,
			8.774,
			8.852,
			8.897,
			8.96,
			9.022,
			9.086,
			9.177,
			9.209,
			9.285,
			9.332,
			9.396,
			9.457,
			9.503,
			9.519,
			9.583,
			9.644,
			9.724,
			9.768,
			9.832,
			9.896,
			9.94,
			10.019,
			10.082,
			10.159,
			10.207,
			10.273,
			10.33,
			10.394,
			10.487,
			10.518,
			10.581,
			10.595,
			10.642,
			10.706,
			10.766,
			10.831,
			10.893,
			10.924,
			10.954,
			11.015,
			11.033,
			11.079,
			11.142,
			11.205,
			11.249,
			11.266,
			11.359,
			11.391,
			11.453,
			11.467,
			11.515,
			11.577,
			11.64,
			11.702,
			11.766,
			11.796,
			11.827,
			11.889,
			11.952,
			12.014,
			12.076,
			12.139,
			12.199,
			12.231,
			12.263,
			12.323,
			12.345,
			12.385,
			12.45,
			12.511,
			12.574,
			12.635,
			12.667,
			12.698,
			12.777,
			12.824,
			12.885,
			12.949,
			13.01,
			13.074,
			13.103,
			13.136,
			13.196,
			13.212,
			13.258,
			13.323,
			13.385,
			13.445,
			13.51,
			13.539,
			13.572,
			13.633,
			13.697,
			13.758,
			13.82,
			13.883,
			13.944,
			13.976,
			14.009,
			14.084,
			14.133,
			14.193,
			14.257,
			14.303,
			14.317,
			14.381,
			14.414,
			14.443,
			14.519,
			14.567,
			14.629,
			14.691,
			14.756,
			14.818,
			14.848,
			14.88,
			14.958,
			15.003,
			15.068,
			15.129,
			15.173,
			15.19,
			15.251,
			15.315,
			15.394,
			15.44,
			15.503,
			15.564,
			15.628,
			15.69,
			15.751,
			15.83,
			15.875,
			15.939,
			16.001,
			16.063,
			16.125,
			16.184,
			16.264,
			16.311,
			16.376,
			16.437,
			16.497,
			16.592,
			16.623,
			16.702,
			16.748,
			16.81,
			16.874,
			16.934,
			16.999,
			17.027,
			17.06,
			17.123,
			17.138,
			17.186,
			17.248,
			17.309,
			17.372,
			17.465,
			17.498,
			17.574,
			17.62,
			17.684,
			17.745,
			17.807,
			17.872,
			17.933,
			18.011,
			18.059,
			18.12,
			18.179,
			18.244,
			18.336,
			18.369,
			18.446,
			18.494,
			18.56,
			18.618,
			18.679,
			18.742,
			18.805,
			18.884,
			18.929,
			18.992,
			19.055,
			19.101,
			19.116,
			19.21,
			19.242,
			19.301,
			19.318,
			19.365,
			19.429,
			19.491,
			19.537,
			19.614,
			19.677,
			19.756,
			19.803,
			19.865,
			19.927,
			19.989,
			20.083,
			20.114,
			20.176,
			20.191,
			20.24,
			20.302,
			20.364,
			20.426,
			20.489,
			20.551,
			20.634,
			20.674,
			20.736,
			20.801,
			20.862,
			20.956,
			20.988,
			21.065,
			21.111,
			21.174,
			21.234,
			21.297,
			21.392,
			21.423,
			21.486,
			21.547,
			21.609,
			21.673,
			21.734,
			21.796,
			21.858,
			21.935,
			21.984,
			22.047,
			22.109,
			22.168,
			22.233,
			22.264,
			22.293,
			22.374,
			22.418,
			22.481,
			22.543,
			22.607,
			22.704,
			22.732,
			22.795,
			22.809,
			22.856,
			22.918,
			22.981,
			23.043,
			23.136,
			23.167,
			23.246,
			23.291,
			23.356,
			23.417,
			23.48,
			23.572,
			23.603,
			23.682,
			23.728,
			23.79,
			23.853,
			23.899,
			23.915,
			23.978,
			24.008,
			24.04,
			24.119,
			24.165,
			24.227,
			24.289,
			24.351,
			24.443,
			24.477,
			24.539,
			24.601,
			24.664,
			24.725,
			24.786,
			24.851,
			24.881,
			24.911,
			24.975,
			24.99,
			25.038,
			25.099,
			25.162,
			25.224,
			25.317,
			25.347,
			25.427,
			25.473,
			25.535,
			25.599,
			25.661,
			25.753,
			25.786,
			25.848,
			25.861,
			25.91,
			25.973,
			26.035,
			26.079,
			26.096,
			26.158,
			26.218,
			26.297,
			26.345,
			26.407,
			26.468,
			26.532,
			26.625,
			26.657,
			26.736,
			26.782,
			26.848,
			26.907,
			26.968,
			27.063,
			27.093,
			27.155,
			27.169,
			27.216,
			27.28,
			27.342,
			27.404,
			27.465,
			27.497,
			27.529,
			27.59,
			27.654,
			27.716,
			27.777,
			27.84,
			27.933,
			27.964,
			28.041,
			28.089,
			28.15,
			28.213,
			28.274,
			28.338,
			28.401,
			28.479,
			28.524,
			28.588,
			28.649,
			28.714,
			28.775,
			28.807,
			28.835,
			28.9,
			28.919,
			28.961,
			29.027,
			29.088,
			29.15,
			29.242,
			29.274,
			29.335,
			29.398,
			29.462,
			29.524,
			29.568,
			29.584,
			29.648,
			29.68,
			29.71,
			29.788,
			29.835,
			29.897,
			29.959,
			30.02,
			30.085,
			30.115,
			30.148,
			30.208,
			30.271,
			30.334,
			30.395,
			30.457,
			30.52,
			30.584,
			30.66,
			30.706,
			30.77,
			30.831,
			30.877,
			30.894,
			30.957,
			31.019,
			31.097,
			31.142,
			31.207,
			31.266,
			31.314,
			31.392,
			31.423,
			31.453,
			31.532,
			31.58,
			31.643,
			31.705,
			31.75,
			31.765,
			31.826,
			31.89,
			31.967,
			32.014,
			32.078,
			32.14,
			32.201,
			32.265,
			32.295,
			32.326,
			32.405,
			32.452,
			32.513,
			32.577,
			32.623,
			32.638,
			32.699,
			32.732,
			32.761,
			32.84,
			32.886,
			32.95,
			33.013,
			33.063,
			33.135,
			33.168,
			33.197,
			33.262,
			33.324,
			33.385,
			33.447,
			33.509,
			33.573,
			33.605,
			33.635,
			33.712,
			33.76,
			33.823,
			33.884,
			33.945,
			34.039,
			34.071,
			34.149,
			34.196,
			34.259,
			34.32,
			34.382,
			34.476,
			34.507,
			34.585,
			34.632,
			34.695,
			34.757,
			34.817,
			34.913,
			34.942,
			35.022,
			35.067,
			35.131,
			35.134,
			35.191,
			35.253,
			35.316,
			35.379,
			35.456,
			35.502,
			35.567,
			35.627,
			35.69,
			35.784,
			35.814,
			35.894,
			35.941,
			36.007,
			36.065,
			36.126,
			36.19,
			36.253,
			36.329,
			36.376,
			36.439,
			36.502,
			36.548,
			36.564,
			36.625,
			36.689,
			36.765,
			36.813,
			36.875,
			36.938,
			36.984,
			37.063,
			37.092,
			37.125,
			37.184,
			37.249,
			37.313,
			37.374,
			37.419,
			37.435,
			37.499,
			37.561,
			37.639,
			37.686,
			37.747,
			37.809,
			37.858,
			37.935,
			37.997,
			38.074,
			38.123,
			38.184,
			38.247,
			38.309,
			38.401,
			38.434,
			38.496,
			38.511,
			38.559,
			38.622,
			38.683,
			38.745,
			38.807,
			38.84,
			38.871,
			38.947,
			38.996,
			39.058,
			39.12,
			39.182,
			39.243,
			39.306,
			39.382,
			39.43,
			39.493,
			39.555,
			39.618,
			39.678,
			39.71,
			39.742,
			39.819,
			39.866,
			39.927,
			39.992,
			40.037,
			40.115,
			40.178,
			40.256,
			40.303,
			40.365,
			40.427,
			40.473,
			40.489,
			40.55,
			40.614,
			40.693,
			40.738,
			40.801,
			40.863,
			40.926,
			40.987,
			41.051,
			41.129,
			41.174,
			41.236,
			41.3,
			41.35,
			41.361,
			41.423,
			41.485,
			41.565,
			41.61,
			41.673,
			41.737,
			41.783,
			41.861,
			41.922,
			42,
			42.048,
			42.11,
			42.167,
			42.219,
			42.234,
			42.296,
			42.359,
			42.435,
			42.484,
			42.547,
			42.607,
			42.655,
			42.668,
			42.734,
			42.765,
			42.795,
			42.873,
			42.918,
			42.986,
			43.045,
			43.092,
			43.108,
			43.169,
			43.231,
			43.308,
			43.356,
			43.422,
			43.48,
			43.543,
			43.606,
			43.636,
			43.667,
			43.73,
			43.746,
			43.791,
			43.856,
			43.918,
			43.965,
			44.043,
			44.103,
			44.178,
			44.228,
			44.29,
			44.352,
			44.4,
			44.414,
			44.476,
			44.539,
			44.617,
			44.663,
			44.727,
			44.788,
			44.85,
			44.913,
			44.976,
			45.052,
			45.1,
			45.162,
			45.224,
			45.286,
			45.348,
			45.412,
			45.493,
			45.535,
			45.599,
			45.661,
			45.723,
			45.785,
			45.848,
			45.926,
			45.973,
			46.035,
			46.097,
			46.158,
			46.219,
			46.284,
			46.362,
			46.409,
			46.47,
			46.535,
			46.596,
			46.689,
			46.721,
			46.797,
			46.844,
			46.907,
			46.968,
			47.032,
			47.124,
			47.157,
			47.234,
			47.281,
			47.343,
			47.406,
			47.469,
			47.53,
			47.592,
			47.669,
			47.719,
			47.782,
			47.841,
			47.904,
			47.968,
			48.03,
			48.107,
			48.154,
			48.217,
			48.278,
			48.341,
			48.402,
			48.466,
			48.542,
			48.59,
			48.652,
			48.715,
			48.776,
			48.839,
			48.869,
			48.901,
			48.963,
			49.027,
			49.089,
			49.151,
			49.213,
			49.307,
			49.338,
			49.417,
			49.463,
			49.526,
			49.588,
			49.639,
			49.713,
			49.776,
			49.853,
			49.9,
			49.962,
			50.025,
			50.085,
			50.149,
			50.21,
			50.288,
			50.334,
			50.399,
			50.461,
			50.522,
			50.617,
			50.648,
			50.724,
			50.772,
			50.833,
			50.897,
			50.96,
			51.051,
			51.083,
			51.146,
			51.162,
			51.207,
			51.274,
			51.332,
			51.395,
			51.456,
			51.52,
			51.596,
			51.643,
			51.707,
			51.71,
			51.769,
			51.831,
			51.924,
			51.957,
			52.034,
			52.082,
			52.144,
			52.205,
			52.252,
			52.267,
			52.33,
			52.391,
			52.468,
			52.517,
			52.579,
			52.642,
			52.703,
			52.767,
			52.796,
			52.828,
			52.906,
			52.952,
			53.016,
			53.077,
			53.123,
			53.14,
			53.201,
			53.234,
			53.264,
			53.343,
			53.389,
			53.451,
			53.515,
			53.562,
			53.639,
			53.7,
			53.784,
			53.826,
			53.888,
			53.951,
			53.997,
			54.013,
			54.074,
			54.137,
			54.213,
			54.26,
			54.323,
			54.385,
			54.446,
			54.542,
			54.574,
			54.65,
			54.697,
			54.76,
			54.822,
			54.867,
			54.885,
			54.979,
			55.01,
			55.073,
			55.085,
			55.135,
			55.197,
			55.258,
			55.304,
			55.32,
			55.383,
			55.415,
			55.444,
			55.524,
			55.568,
			55.632,
			55.693,
			55.743,
			55.819,
			55.856,
			55.883,
			55.96,
			56.007,
			56.07,
			56.133,
			56.179,
			56.194,
			56.254,
			56.318,
			56.397,
			56.442,
			56.503,
			56.569,
			56.63,
			56.691,
			56.755,
			56.834,
			56.879,
			56.942,
			57.005,
			57.052,
			57.068,
			57.13,
			57.191,
			57.268,
			57.317,
			57.38,
			57.441,
			57.488,
			57.566,
			57.627,
			57.706,
			57.751,
			57.815,
			57.877,
			57.94,
			58,
			58.064,
			58.143,
			58.188,
			58.249,
			58.313,
			58.376,
			58.467,
			58.5,
			58.576,
			58.624,
			58.684,
			58.75,
			58.812,
			58.903,
			58.936,
			58.997,
			59.014,
			59.059,
			59.124,
			59.184,
			59.247,
			59.31,
			59.372,
			59.449,
			59.497,
			59.559,
			59.619,
			59.683,
			59.778,
			59.808,
			59.887,
			59.933,
			59.996,
			60.058,
			60.103,
			60.119,
			60.181,
			60.244,
			60.322,
			60.369,
			60.619,
			60.679,
			60.758,
			60.803,
			60.868,
			60.93,
			60.991,
			61.054,
			61.117,
			61.194,
			61.24,
			61.301,
			61.366,
			61.427,
			61.49,
			61.551,
			61.629,
			61.678,
			61.74,
			61.802,
			61.865,
			61.957,
			61.987,
			62.051,
			62.07,
			62.113,
			62.177,
			62.238,
			62.3,
			62.392,
			62.425,
			62.487,
			62.549,
			62.613,
			62.675,
			62.721,
			62.737,
			62.799,
			62.83,
			62.861,
			62.94,
			62.985,
			63.049,
			63.11,
			63.157,
			63.173,
			63.234,
			63.264,
			63.297,
			63.36,
			63.419,
			63.485,
			63.546,
			63.593,
			63.609,
			63.67,
			63.7,
			63.733,
			63.81,
			63.857,
			63.918,
			63.981,
			64.03,
			64.046,
			64.106,
			64.142,
			64.167,
			64.248,
			64.294,
			64.358,
			64.419,
			64.482,
			64.542,
			64.574,
			64.607,
			64.684,
			64.732,
			64.793,
			64.855,
			64.902,
			64.919,
			64.979,
			65.041,
			65.12,
			65.165,
			65.23,
			65.291,
			65.353,
			65.415,
			65.478,
			65.557,
			65.603,
			65.668,
			65.727,
			65.774,
			65.79,
			65.852,
			65.885,
			65.915,
			65.992,
			66.04,
			66.102,
			66.163,
			66.225,
			66.289,
			66.349,
			66.428,
			66.474,
			66.538,
			66.6,
			66.663,
			66.726,
			66.788,
			66.865,
			66.911,
			66.974,
			67.035,
			67.084,
			67.161,
			67.223,
			67.301,
			67.346,
			67.409,
			67.472,
			67.534,
			67.595,
			67.659,
			67.719,
			67.735,
			67.784,
			67.847,
			67.908,
			67.97,
			68.033,
			68.063,
			68.094,
			68.173,
			68.22,
			68.288,
			68.344,
			68.391,
			68.407,
			68.469,
			68.501,
			68.532,
			68.61,
			68.656,
			68.719,
			68.779,
			68.826,
			68.842,
			68.905,
			68.967,
			69.045,
			69.092,
			69.154,
			69.216,
			69.279,
			69.339,
			69.372,
			69.403,
			69.48,
			69.528,
			69.591,
			69.65,
			69.714,
			69.807,
			69.84,
			69.916,
			69.964,
			70.027,
			70.089,
			70.149,
			70.245,
			70.275,
			70.338,
			70.357,
			70.4,
			70.462,
			70.525,
			70.586,
			70.681,
			70.711,
			70.773,
			70.79,
			70.835,
			70.897,
			70.96,
			71.022,
			71.116,
			71.147,
			71.209,
			71.27,
			71.334,
			71.394,
			71.457,
			71.552,
			71.582,
			71.646,
			71.66,
			71.707,
			71.769,
			71.832,
			71.895,
			71.957,
			72.019,
			72.096,
			72.143,
			72.207,
			72.267,
			72.33,
			72.393,
			72.456,
			72.533,
			72.579,
			72.643,
			72.704,
			72.767,
			72.86,
			72.89,
			72.951,
			72.969,
			73.015,
			73.078,
			73.14,
			73.201,
			73.296,
			73.327,
			73.389,
			73.452,
			73.515,
			73.577,
			73.623,
			73.64,
			73.701,
			73.763,
			73.843,
			73.888,
			73.95,
			74.013,
			74.075,
			74.169,
			74.199,
			74.261,
			74.323,
			74.384,
			74.449,
			74.5,
			74.511,
			74.574,
			74.635,
			74.712,
			74.759,
			74.823,
			74.885,
			74.946,
			75.04,
			75.072,
			75.149,
			75.196,
			75.258,
			75.318,
			75.383,
			75.476,
			75.507,
			75.568,
			75.585,
			75.633,
			75.694,
			75.757,
			75.82,
			75.881,
			75.945,
			76.023,
			76.069,
			76.132,
			76.193,
			76.241,
			76.318,
			76.35,
			76.381,
			76.459,
			76.505,
			76.57,
			76.63,
			76.677,
			76.693,
			76.754,
			76.817,
			76.879,
			76.895,
			76.942,
			77.002,
			77.067,
			77.113,
			77.189,
			77.222,
			77.251,
			77.33,
			77.377,
			77.441,
			77.501,
			77.549,
			77.564,
			77.627,
			77.657,
			77.69,
			77.767,
			77.812,
			77.875,
			77.937,
			77.998,
			78.061,
			78.092,
			78.125,
			78.186,
			78.247,
			78.311,
			78.374,
			78.42,
			78.436,
			78.497,
			78.528,
			78.559,
			78.621,
			78.683,
			78.747,
			78.808,
			78.871,
			78.934,
			78.965,
			78.995,
			79.059,
			79.12,
			79.184,
			79.245,
			79.307,
			79.4,
			79.431,
			79.51,
			79.557,
			79.619,
			79.682,
			79.742,
			79.837,
			79.869,
			79.945,
			79.993,
			80.057,
			80.118,
			80.181,
			80.242,
			80.273,
			80.303,
			80.366,
			80.43,
			80.492,
			80.553,
			80.617,
			80.679,
			80.715,
			80.741,
			80.803,
			80.817,
			80.865,
			80.928,
			80.99,
			81.035,
			81.115,
			81.145,
			81.177,
			81.253,
			81.3,
			81.363,
			81.425,
			81.487,
			81.55,
			81.582,
			81.614,
			81.674,
			81.691,
			81.737,
			81.801,
			81.862,
			81.925,
			81.987,
			82.048,
			82.127,
			82.168,
			82.235,
			82.299,
			82.346,
			82.362,
			82.423,
			82.484,
			82.564,
			82.61,
			82.673,
			82.734,
			82.797,
			82.86,
			82.922,
			83,
			83.046,
			83.111,
			83.17,
			83.233,
			83.326,
			83.357,
			83.435,
			83.484,
			83.547,
			83.609,
			83.656,
			83.669,
			83.732,
			83.794,
			83.873,
			83.918,
			83.981,
			84.044,
			84.107,
			84.17,
			84.231,
			84.309,
			84.356,
			84.42,
			84.482,
			84.542,
			84.635,
			84.667,
			84.745,
			84.793,
			84.859,
			84.917,
			84.965,
			85.043,
			85.102,
			85.183,
			85.229,
			85.292,
			85.353,
			85.416,
			85.478,
			85.541,
			85.619,
			85.666,
			85.728,
			85.792,
			85.852,
			85.947,
			85.978,
			86.055,
			86.103,
			86.164,
			86.227,
			86.273,
			86.289,
			86.35,
			86.414,
			86.491,
			86.539,
			86.602,
			86.662,
			86.709,
			86.788,
			86.818,
			86.851,
			86.912,
			86.974,
			87.036,
			87.1,
			87.147,
			87.163,
			87.225,
			87.256,
			87.285,
			87.349,
			87.365,
			87.409,
			87.474,
			87.535,
			87.599,
			87.661,
			87.691,
			87.723,
			87.802,
			87.848,
			87.909,
			87.973,
			88.018,
			88.035,
			88.097,
			88.128,
			88.16,
			88.22,
			88.235,
			88.284,
			88.347,
			88.407,
			88.471,
			88.531,
			88.565,
			88.596,
			88.657,
			88.672,
			88.719,
			88.782,
			88.844,
			88.907,
			89.005,
			89.03,
			89.095,
			89.109,
			89.156,
			89.219,
			89.281,
			89.342,
			89.405,
			89.467,
			89.546,
			89.593,
			89.655,
			89.716,
			89.779,
			89.843,
			89.874,
			89.903,
			89.966,
			89.983,
			90.028,
			90.091,
			90.153,
			90.216,
			90.277,
			90.309,
			90.34,
			90.417,
			90.464,
			90.527,
			90.589,
			90.636,
			90.715,
			90.744,
			90.776,
			90.853,
			90.9,
			90.962,
			91.026,
			91.075,
			91.086,
			91.149,
			91.213,
			91.29,
			91.335,
			91.4,
			91.461,
			91.507,
			91.523,
			91.585,
			91.648,
			91.725,
			91.773,
			91.834,
			91.898,
			91.943,
			91.96,
			92.023,
			92.052,
			92.084,
			92.146,
			92.163,
			92.209,
			92.273,
			92.335,
			92.396,
			92.458,
			92.49,
			92.521,
			92.583,
			92.646,
			92.708,
			92.771,
			92.832,
			92.895,
			92.925,
			92.959,
			93.035,
			93.083,
			93.15,
			93.208,
			93.252,
			93.269,
			93.363,
			93.393,
			93.456,
			93.472,
			93.52,
			93.579,
			93.644,
			93.706,
			93.768,
			93.799,
			93.829,
			93.893,
			93.909,
			93.954,
			94.019,
			94.081,
			94.143,
			94.205,
			94.234,
			94.265,
			94.343,
			94.391,
			94.452,
			94.516,
			94.579,
			94.672,
			94.703,
			94.764,
			94.827,
			94.89,
			94.953,
			95.015,
			95.077,
			95.109,
			95.14,
			95.201,
			95.218,
			95.262,
			95.326,
			95.388,
			95.45,
			95.542,
			95.573,
			95.637,
			95.7,
			95.761,
			95.824,
			95.885,
			95.98,
			96.01,
			96.074,
			96.088,
			96.134,
			96.197,
			96.26,
			96.323,
			96.385,
			96.415,
			96.447,
			96.508,
			96.571,
			96.635,
			96.697,
			96.757,
			96.82,
			96.851,
			96.881,
			96.962,
			97.007,
			97.07,
			97.132,
			97.178,
			97.193,
			97.29,
			97.317,
			97.381,
			97.395,
			97.442,
			97.506,
			97.568,
			97.629,
			97.723,
			97.753,
			97.833,
			97.878,
			97.943,
			98.005,
			98.067,
			98.158,
			98.191,
			98.253,
			98.269,
			98.314,
			98.377,
			98.44,
			98.502,
			98.596,
			98.628,
			98.706,
			98.751,
			98.815,
			98.877,
			98.94,
			99.001,
			99.033,
			99.063,
			99.125,
			99.142,
			99.188,
			99.249,
			99.312,
			99.373,
			99.467,
			99.497,
			99.562,
			99.624,
			99.687,
			99.747,
			99.81,
			99.874,
			99.935,
			100.012,
			100.059,
			100.124,
			100.185,
			100.246,
			100.34,
			100.37,
			100.448,
			100.496,
			100.559,
			100.621,
			100.683,
			100.775,
			100.808,
			100.868,
			100.884,
			100.933,
			100.996,
			101.057,
			101.118,
			101.179,
			101.243,
			101.32,
			101.368,
			101.435,
			101.492,
			101.539,
			101.617,
			101.679,
			101.757,
			101.805,
			101.867,
			101.928,
			101.991,
			102.053,
			102.117,
			102.192,
			102.24,
			102.303,
			102.365,
			102.428,
			102.519,
			102.553,
			102.613,
			102.676,
			102.74,
			102.801,
			102.863,
			102.926,
			102.957,
			102.99,
			103.067,
			103.112,
			103.168,
			103.238,
			103.301,
			103.393,
			103.425,
			103.507,
			103.551,
			103.611,
			103.674,
			103.722,
			103.735,
			103.8,
			103.861,
			103.939,
			103.985,
			104.048,
			104.11,
			104.172,
			104.266,
			104.297,
			104.374,
			104.419,
			104.486,
			104.546,
			104.592,
			104.608,
			104.672,
			104.735,
			104.812,
			104.859,
			104.922,
			104.984,
			105.03,
			105.045,
			105.109,
			105.171,
			105.249,
			105.295,
			105.358,
			105.419,
			105.482,
			105.546,
			105.607,
			105.686,
			105.732,
			105.794,
			105.857,
			105.904,
			105.919,
			105.982,
			106.045,
			106.122,
			106.167,
			106.23,
			106.294,
			106.357,
			106.417,
			106.479,
			106.557,
			106.605,
			106.668,
			106.73,
			106.792,
			106.884,
			106.918,
			106.995,
			107.042,
			107.105,
			107.167,
			107.213,
			107.291,
			107.353,
			107.43,
			107.478,
			107.542,
			107.603,
			107.664,
			107.726,
			107.789,
			107.867,
			107.913,
			107.977,
			108.038,
			108.1,
			108.163,
			108.195,
			108.224,
			108.304,
			108.35,
			108.412,
			108.474,
			108.519,
			108.535,
			108.597,
			108.661,
			108.739,
			108.786,
			108.847,
			108.909,
			108.957,
			108.972,
			109.035,
			109.065,
			109.098,
			109.168,
			109.219,
			109.283,
			109.345,
			109.383
		]
	],
	'7272_8309_7982': [
		[
			105.499
		],
		[
			0
		],
		[
			0,
			2.383,
			5.032,
			9.013,
			11.677,
			15.675,
			17.008,
			18.344,
			22.358,
			23.701,
			25.049,
			29.107,
			31.823,
			34.545,
			35.908,
			38.639,
			41.377,
			42.748,
			46.875,
			49.635,
			52.403,
			56.567,
			59.351,
			63.54,
			64.94,
			66.343,
			71.967,
			73.377,
			77.618,
			80.454,
			84.721,
			87.572,
			90.443,
			91.886,
			94.783,
			96.24,
			99.157,
			102.057,
			106.401,
			109.295,
			113.646,
			115.1,
			116.557,
			120.937,
			123.864,
			128.264,
			131.201,
			135.615,
			138.562,
			141.516,
			142.996,
			145.96,
			147.444,
			150.416,
			153.392,
			157.865,
			160.852,
			165.338,
			166.835,
			168.333,
			172.838,
			175.851,
			180.386,
			183.421,
			186.464,
			187.99,
			191.052,
			192.593,
			195.692,
			200.424,
			203.627,
			206.864,
			211.796,
			215.13,
			220.047,
			221.629,
			223.21,
			227.927,
			231.071,
			235.828,
			239.009,
			243.741,
			248.401,
			251.455,
			254.464,
			255.956,
			258.913,
			261.829,
			266.123,
			267.531,
			268.93,
			273.064,
			274.423,
			275.772,
			279.761,
			282.374,
			286.222,
			288.74,
			292.447,
			294.872,
			296.071,
			297.261,
			300.779,
			301.934,
			304.216,
			306.46,
			308.665,
			311.903,
			315.059,
			316.093,
			317.117,
			320.135,
			322.101,
			324.032,
			326.861,
			328.706,
			332.298,
			333.177,
			335.779,
			336.627,
			337.468,
			339.944,
			341.564,
			343.955,
			345.52,
			347.066,
			347.831,
			349.35,
			350.104,
			351.604,
			353.087,
			355.302,
			356.766,
			358.934,
			359.655,
			360.376,
			362.503,
			363.202,
			363.9,
			365.996,
			367.375,
			368.748,
			369.435,
			372.245,
			372.98,
			374.452,
			375.189,
			376.729,
			378.522,
			381.222,
			383.305,
			386.488,
			387.527,
			388.568,
			391.703,
			393.804,
			396.983,
			399.162,
			402.392,
			406.536,
			407.481,
			410.318,
			412.113,
			413.767,
			416.258,
			417.876,
			421.102,
			421.914,
			424.363,
			426.004,
			428.47,
			430.121,
			432.61,
			434.276,
			435.953,
			436.796,
			438.502,
			439.359,
			441.082,
			442.834,
			445.497,
			447.295,
			450.042,
			450.964,
			451.889,
			454.712,
			456.62,
			459.495,
			461.423,
			464.298,
			467.158,
			469.044,
			470.905,
			471.825,
			473.64,
			475.418,
			478.019,
			479.708,
			482.976,
			483.77,
			486.096,
			487.603,
			489.798,
			491.219,
			493.313,
			494.678,
			495.356,
			496.704,
			498.043,
			498.709,
			500.031,
			501.345,
			502.649,
			504.578,
			507.125,
			507.75,
			509.608,
			510.84,
			512.069,
			513.87,
			515.659,
			517.443,
			518.024,
			520.344,
			520.925,
			522.693,
			523.932,
			525.792,
			527.035,
			528.501,
			529.273,
			530.816,
			531.588,
			533.323,
			535.292,
			538.363,
			540.615,
			543.85,
			545.892,
			548.622,
			550.205,
			552.578,
			554.013,
			556.173,
			557.622,
			559.793,
			561.981,
			563.454,
			564.938,
			567.18,
			568.678,
			571.694,
			572.456,
			574.699,
			576.208,
			578.487,
			580.015,
			581.544,
			582.321,
			583.89,
			586.274,
			588.692,
			590.323,
			591.969,
			594.467,
			596.145,
			598.665,
			600.36,
			602.93,
			604.658,
			607.275,
			609.034,
			611.711,
			613.501,
			616.204,
			618.934,
			620.767,
			622.612,
			625.412,
			627.296,
			630.176,
			631.147,
			632.121,
			635.097,
			637.11,
			640.172,
			642.232,
			645.363,
			647.466,
			650.707,
			654,
			656.253,
			658.505,
			661.841,
			662.953,
			664.055,
			667.334,
			669.513,
			672.764,
			674.92,
			678.151,
			680.269,
			682.382,
			683.444,
			685.515,
			687.59,
			691.722,
			693.793,
			695.873,
			697.979,
			701.186,
			704.401,
			705.476,
			706.554,
			709.806,
			711.99,
			715.264,
			717.47,
			720.788,
			723.007,
			724.121,
			725.257,
			729.751,
			732.007,
			734.27,
			736.518,
			739.913,
			742.199,
			744.482,
			745.654,
			749.203,
			751.554,
			753.952,
			757.521,
			759.908,
			763.471,
			764.671,
			765.873,
			769.493,
			771.941,
			775.57,
			778.004,
			781.662,
			784.108,
			786.578,
			787.817,
			790.309,
			791.566,
			794.087,
			796.609,
			800.402,
			802.935,
			806.76,
			808.042,
			809.326,
			813.188,
			815.768,
			819.642,
			822.232,
			826.12,
			831.317,
			832.62,
			835.231,
			836.539,
			839.161,
			841.789,
			845.745,
			848.391,
			853.706,
			855.039,
			859.05,
			861.733,
			865.772,
			868.474,
			872.542,
			875.264,
			876.63,
			879.365,
			882.107,
			883.48,
			886.236,
			889.006,
			893.178,
			895.967,
			901.546,
			902.945,
			907.151,
			909.965,
			914.196,
			917.025,
			921.28,
			924.122,
			925.542,
			928.374,
			931.187,
			932.583,
			935.349,
			938.078,
			942.1,
			944.733,
			948.613,
			951.156,
			954.908,
			957.363,
			960.979,
			963.348,
			966.839,
			970.251,
			972.478,
			974.667,
			975.748,
			977.882,
			979.978,
			983.053,
			985.057,
			987.993,
			988.954,
			989.905,
			991.779,
			992.703,
			994.525,
			996.313,
			998.93,
			1001.467,
			1003.112,
			1003.92,
			1004.72,
			1007.067,
			1007.832,
			1008.588,
			1010.802,
			1012.246,
			1013.642,
			1014.329,
			1016.993,
			1017.639,
			1018.906,
			1019.527,
			1020.743,
			1021.921,
			1023.621,
			1024.713,
			1026.797,
			1027.294,
			1029.217,
			1029.685,
			1031.063,
			1031.944,
			1033.234,
			1034.928,
			1035.351,
			1036.196,
			1036.604,
			1037.406,
			1038.208,
			1039.409,
			1040.211,
			1041.85,
			1042.277,
			1043.558,
			1044.409,
			1045.681,
			1046.653,
			1048.71,
			1051.578,
			1053.147,
			1057.235,
			1059.084,
			1060.93,
			1062.479,
			1063.358,
			1064.678,
			1065.561,
			1066.883,
			1067.758,
			1069.075,
			1069.958,
			1071.291,
			1072.218,
			1073.624,
			1075.045,
			1076.003,
			1076.973,
			1078.447,
			1079.443,
			1080.953,
			1081.467,
			1081.993,
			1083.597,
			1084.687,
			1086.352,
			1087.483,
			1088.63,
			1089.21,
			1090.382,
			1092.181,
			1094.002,
			1095.236,
			1096.485,
			1098.398,
			1099.688,
			1101.654,
			1103.089,
			1105.261,
			1106.749,
			1109.441,
			1111.244,
			1113.021,
			1113.905,
			1115.667,
			1116.545,
			1118.033,
			1120.225,
			1121.675,
			1123.117,
			1125.247,
			1125.952,
			1126.656,
			1128.918,
			1130.424,
			1132.698,
			1134.231,
			1136.518,
			1138.037,
			1139.545,
			1140.295,
			1141.792,
			1143.938,
			1146.064,
			1146.771,
			1148.86,
			1150.25,
			1151.641,
			1152.355,
			1153.799,
			1155.24,
			1155.96,
			1158.129,
			1159.578,
			1161.027,
			1163.178,
			1164.601,
			1166.737,
			1168.121,
			1170.188,
			1171.571,
			1173.566,
			1174.886,
			1176.879,
			1178.198,
			1179.52,
			1180.183,
			1182.19,
			1183.551,
			1184.92,
			1186.995,
			1188.4,
			1190.527,
			1191.243,
			1191.962,
			1194.149,
			1195.624,
			1197.867,
			1199.383,
			1200.914,
			1201.686,
			1203.261,
			1205.661,
			1208.109,
			1209.781,
			1211.462,
			1214.019,
			1215.742,
			1218.337,
			1219.2,
			1220.065,
			1222.665,
			1224.365,
			1226.921,
			1228.616,
			1231.15,
			1232.841,
			1234.534,
			1235.379,
			1237.07,
			1237.913,
			1239.589,
			1241.264,
			1243.755,
			1245.4,
			1247.864,
			1248.681,
			1249.497,
			1251.943,
			1253.565,
			1255.986,
			1257.598,
			1259.994,
			1262.39,
			1263.999,
			1265.612,
			1266.42,
			1268.043,
			1269.686,
			1272.161,
			1273.831,
			1276.347,
			1277.189,
			1278.038,
			1280.631,
			1282.375,
			1285.024,
			1286.805,
			1289.496,
			1291.302,
			1292.208,
			1293.117,
			1296.766,
			1298.6,
			1300.432,
			1303.192,
			1305.013,
			1306.837,
			1309.567,
			1312.285,
			1314.097,
			1315.907,
			1318.619,
			1320.42,
			1324.041,
			1324.967,
			1328.691,
			1329.677,
			1332.636,
			1334.675,
			1337.796,
			1341.813,
			1342.81,
			1345.694,
			1347.597,
			1349.486,
			1352.304,
			1354.192,
			1357.998,
			1358.958,
			1361.868,
			1363.818,
			1366.766,
			1368.741,
			1371.718,
			1373.716,
			1376.714,
			1379.737,
			1381.767,
			1383.809,
			1386.906,
			1388.984,
			1393.157,
			1394.2,
			1397.329,
			1399.43,
			1402.607,
			1404.743,
			1406.898,
			1407.979,
			1410.159,
			1413.458,
			1416.767,
			1418.983,
			1421.19,
			1424.515,
			1426.736,
			1430.078,
			1432.303,
			1435.643,
			1437.869,
			1441.213,
			1443.448,
			1446.847,
			1450.291,
			1452.621,
			1456.123,
			1458.464,
			1460.816,
			1464.364,
			1466.77,
			1470.399,
			1472.835,
			1476.516,
			1478.985,
			1482.704,
			1485.19,
			1488.925,
			1491.425,
			1495.168,
			1498.946,
			1501.472,
			1504.005,
			1507.816,
			1510.36,
			1514.168,
			1515.441,
			1516.715,
			1520.557,
			1523.144,
			1527.035,
			1529.63,
			1532.222,
			1533.521,
			1536.125,
			1540.043,
			1543.978,
			1546.61,
			1549.25,
			1553.222,
			1555.881,
			1559.883,
			1562.564,
			1566.599,
			1569.298,
			1573.359,
			1576.076,
			1578.799,
			1582.899,
			1585.642,
			1591.152,
			1592.535,
			1596.697,
			1599.481,
			1602.271,
			1603.669,
			1606.471,
			1610.689,
			1614.923,
			1617.753,
			1620.589,
			1624.855,
			1627.708,
			1632,
			1633.434,
			1634.869,
			1639.184,
			1642.058,
			1646.34,
			1649.16,
			1651.944,
			1653.321,
			1656.048,
			1658.738,
			1660.068,
			1664.001,
			1666.576,
			1669.111,
			1672.845,
			1675.287,
			1678.883,
			1681.238,
			1684.705,
			1686.969,
			1690.296,
			1692.467,
			1695.653,
			1697.73,
			1699.77,
			1700.776,
			1703.739,
			1705.669,
			1707.561,
			1710.33,
			1712.134,
			1714.776,
			1716.494,
			1719.002,
			1720.628,
			1723,
			1724.536,
			1726.036,
			1726.772,
			1728.219,
			1728.928,
			1730.32,
			1731.679,
			1732.346,
			1733.656,
			1734.934,
			1736.798,
			1737.984,
			1739.699,
			1740.253,
			1740.8,
			1742.38,
			1743.387,
			1744.844,
			1745.785,
			1746.704,
			1747.134,
			1747.98,
			1749.224,
			1750.449,
			1751.26,
			1752.067,
			1753.266,
			1754.062,
			1755.654,
			1756.054,
			1757.293,
			1758.153,
			1759.446,
			1760.314,
			1761.187,
			1761.627,
			1762.558,
			1763.03,
			1763.973,
			1765.396,
			1766.35,
			1767.323,
			1768.308,
			1769.291,
			1769.781,
			1771.255,
			1771.747,
			1772.253,
			1773.764,
			1774.764,
			1776.255,
			1777.282,
			1778.878,
			1779.93,
			1780.454,
			1780.978,
			1783.209,
			1783.786,
			1785.504,
			1786.646,
			1787.885,
			1788.513,
			1789.761,
			1791.003,
			1791.623,
			1793.69,
			1795.086,
			1797.199,
			1798.715,
			1800.228,
			1802.503,
			1803.26,
			1804.016,
			1806.289,
			1807.713,
			1809.698,
			1811.028,
			1812.799,
			1813.807,
			1814.82,
			1815.33,
			1816.876,
			1817.831,
			1818.766,
			1820.186,
			1821.147,
			1822.637,
			1823.16,
			1823.686,
			1825.284,
			1826.368,
			1828.062,
			1829.235,
			1830.423,
			1831.024,
			1832.237,
			1834.135,
			1836.055,
			1837.338,
			1838.645,
			1840.63,
			1841.971,
			1844.027,
			1845.416,
			1847.533,
			1848.962,
			1851.134,
			1852.59,
			1854.811,
			1857.07,
			1858.586,
			1860.116,
			1860.886,
			1862.439,
			1864.01,
			1866.393,
			1868.005,
			1870.46,
			1871.286,
			1872.115,
			1874.624,
			1876.307,
			1878.832,
			1880.529,
			1883.097,
			1884.823,
			1885.691,
			1886.562,
			1889.195,
			1890.079,
			1891.856,
			1893.649,
			1896.366,
			1898.193,
			1901.888,
			1902.82,
			1905.634,
			1907.526,
			1910.388,
			1912.311,
			1915.219,
			1917.172,
			1918.154,
			1919.138,
			1922.109,
			1923.106,
			1924.105,
			1927.122,
			1929.147,
			1932.208,
			1936.329,
			1937.366,
			1939.448,
			1940.494,
			1942.588,
			1944.679,
			1947.827,
			1949.94,
			1954.202,
			1955.278,
			1958.52,
			1959.605,
			1960.693,
			1963.967,
			1966.156,
			1969.462,
			1971.683,
			1973.914,
			1975.033,
			1977.278,
			1978.404,
			1980.656,
			1982.917,
			1986.328,
			1988.614,
			1993.22,
			1994.378,
			1997.865,
			2000.2,
			2003.718,
			2006.076,
			2009.631,
			2014.415,
			2015.617,
			2018.028,
			2019.236,
			2021.659,
			2024.091,
			2027.757,
			2030.21,
			2033.905,
			2036.376,
			2040.099,
			2042.593,
			2046.35,
			2048.865,
			2052.655,
			2056.462,
			2059.011,
			2061.568,
			2062.849,
			2065.418,
			2067.994,
			2071.874,
			2074.47,
			2078.376,
			2080.981,
			2084.901,
			2087.523,
			2091.469,
			2094.108,
			2098.081,
			2102.067,
			2104.734,
			2108.749,
			2111.435,
			2114.127,
			2118.179,
			2120.888,
			2126.331,
			2127.697,
			2131.804,
			2134.551,
			2138.682,
			2141.445,
			2145.596,
			2148.36,
			2149.739,
			2152.48,
			2156.544,
			2159.212,
			2161.841,
			2165.713,
			2168.245,
			2173.198,
			2174.414,
			2178.056,
			2180.451,
			2182.834,
			2186.319,
			2189.674,
			2191.847,
			2193.975,
			2198.117,
			2199.129,
			2202.114,
			2205.019,
			2206.91,
			2208.766,
			2211.488,
			2214.14,
			2215.881,
			2217.574,
			2220.05,
			2221.662,
			2224.811,
			2225.586,
			2228.637,
			2229.394,
			2231.646,
			2233.131,
			2235.358,
			2238.271,
			2238.997,
			2240.451,
			2241.17,
			2242.593,
			2244.014,
			2246.146,
			2247.55,
			2249.651,
			2251.064,
			2253.373,
			2254.909,
			2257.546,
			2259.483,
			2262.692,
			2266.236,
			2268.89,
			2272.509,
			2274.626,
			2276.553,
			2278.8,
			2280.298,
			2282.468,
			2283.885,
			2286.016,
			2287.483,
			2289.699,
			2291.187,
			2293.473,
			2295.009,
			2297.349,
			2299.729,
			2301.333,
			2302.964,
			2305.434,
			2307.101,
			2310.484,
			2311.337,
			2313.929,
			2315.672,
			2318.316,
			2320.095,
			2322.794,
			2325.513,
			2327.353,
			2330.139,
			2332.009,
			2333.882,
			2342.442,
			2343.407,
			2346.319,
			2348.271,
			2351.219,
			2353.2,
			2356.192,
			2358.206,
			2359.218,
			2360.233,
			2363.294,
			2364.321,
			2366.381,
			2368.453,
			2371.582,
			2373.685,
			2377.895,
			2378.951,
			2381.07,
			2382.133,
			2384.268,
			2386.412,
			2389.642,
			2392.89,
			2395.063,
			2396.153,
			2397.246,
			2400.544,
			2401.648,
			2402.758,
			2406.105,
			2408.347,
			2411.723,
			2413.977,
			2416.239,
			2417.373,
			2420.79,
			2423.079,
			2425.378,
			2428.845,
			2431.168,
			2435.842,
			2437.015,
			2440.549,
			2442.917,
			2446.484,
			2448.88,
			2452.498,
			2454.937,
			2457.384,
			2458.606,
			2461.055,
			2462.283,
			2464.73,
			2467.185,
			2470.883,
			2473.36,
			2478.341,
			2479.59,
			2483.346,
			2485.855,
			2489.634,
			2492.163,
			2495.972,
			2499.799,
			2502.359,
			2504.928,
			2506.216,
			2508.795,
			2511.382,
			2515.275,
			2517.877,
			2521.786,
			2524.41,
			2528.371,
			2531.018,
			2534.986,
			2537.629,
			2541.664,
			2545.742,
			2548.468,
			2552.498,
			2555.167,
			2557.829,
			2561.802,
			2564.425,
			2569.574,
			2570.84,
			2574.587,
			2577.042,
			2580.666,
			2583.041,
			2586.526,
			2588.799,
			2589.918,
			2592.129,
			2595.39,
			2597.524,
			2599.626,
			2602.735,
			2603.745,
			2604.74,
			2607.681,
			2609.603,
			2612.449,
			2614.334,
			2617.156,
			2619.04,
			2620.922,
			2624.689,
			2625.626,
			2629.367,
			2630.293,
			2633.064,
			2634.902,
			2637.641,
			2639.475,
			2642.256,
			2645.126,
			2647.132,
			2650.261,
			2652.473,
			2654.673,
			2659.033,
			2660.062,
			2664.166,
			2665.213,
			2668.354,
			2670.465,
			2673.652,
			2677.682,
			2678.691,
			2681.546,
			2683.431,
			2685.305,
			2688.104,
			2689.986,
			2692.839,
			2694.759,
			2697.703,
			2699.682,
			2702.738,
			2704.793,
			2707.947,
			2711.15,
			2713.317,
			2716.694,
			2718.977,
			2721.286,
			2724.754,
			2726.99,
			2730.367,
			2732.588,
			2735.933,
			2738.172,
			2741.539,
			2743.833,
			2747.245,
			2749.526,
			2752.896,
			2756.269,
			2758.522,
			2760.795,
			2764.25,
			2766.584,
			2771.288,
			2772.476,
			2776.094,
			2778.518,
			2782.258,
			2784.769,
			2787.246,
			2788.481,
			2790.934,
			2794.495,
			2798.064,
			2800.437,
			2802.8,
			2806.31,
			2808.616,
			2812.009,
			2814.222,
			2817.473,
			2819.595,
			2822.73,
			2824.781,
			2827.792,
			2830.719,
			2832.604,
			2835.364,
			2837.156,
			2838.912,
			2841.472,
			2842.307,
			2843.131,
			2844.754,
			2847.118,
			2849.399,
			2850.873,
			2853.017,
			2854.402,
			2856.416,
			2857.713,
			2858.35,
			2858.977,
			2861.393,
			2861.972,
			2863.664,
			2864.74,
			2865.784,
			2866.288,
			2867.265,
			2868.68,
			2870.042,
			2870.927,
			2871.783,
			2873.041,
			2873.869,
			2875.103,
			2875.923,
			2877.617,
			2878.04,
			2879.305,
			2880.148,
			2880.993,
			2881.438,
			2882.334,
			2883.229,
			2883.676,
			2885.027,
			2885.936,
			2886.872,
			2888.286,
			2889.232,
			2890.663,
			2891.648,
			2893.153,
			2894.156,
			2895.663,
			2896.713,
			2897.8,
			2898.341,
			2899.419,
			2901.034,
			2902.75,
			2903.893,
			2905.031,
			2906.771,
			2907.997,
			2909.826,
			2911.048,
			2912.951,
			2914.227,
			2916.149,
			2917.481,
			2918.827,
			2919.502,
			2920.859,
			2922.93,
			2925.023,
			2926.434,
			2927.843,
			2929.973,
			2930.691,
			2931.413,
			2933.536,
			2934.964,
			2937.116,
			2938.521,
			2940.648,
			2942.054,
			2944.076,
			2945.431,
			2946.111,
			2947.384,
			2949.187,
			2950.391,
			2951.599,
			2953.295,
			2953.857,
			2954.419,
			2956.107,
			2957.258,
			2959.024,
			2960.201,
			2961.972,
			2963.202,
			2964.429,
			2965.042,
			2966.27,
			2968.146,
			2970.058,
			2971.333,
			2972.616,
			2974.569,
			2975.888,
			2977.222,
			2978.564,
			2979.241,
			2981.299,
			2982.698,
			2984.119,
			2986.283,
			2987.737,
			2990.725,
			2991.481,
			2993.777,
			2994.55,
			2995.327,
			2997.682,
			2999.267,
			3001.674,
			3003.296,
			3004.933,
			3005.758,
			3008.25,
			3009.92,
			3011.59,
			3014.103,
			3015.793,
			3018.355,
			3019.216,
			3020.081,
			3023.6,
			3024.502,
			3027.228,
			3029.071,
			3030.928,
			3031.862,
			3033.719,
			3035.578,
			3036.513,
			3038.392,
			3039.336,
			3041.233,
			3043.146,
			3046.047,
			3047.997,
			3050.947,
			3051.936,
			3052.929,
			3055.925,
			3057.938,
			3060.984,
			3063.03,
			3065.102,
			3066.134,
			3068.206,
			3069.244,
			3071.324,
			3074.439,
			3076.522,
			3078.614,
			3081.762,
			3083.87,
			3087.04,
			3088.102,
			3089.161,
			3092.348,
			3094.469,
			3097.637,
			3099.742,
			3102.852,
			3104.947,
			3105.989,
			3108.048,
			3111.331,
			3113.501,
			3116.266,
			3120.507,
			3123.369,
			3126.512,
			3127.497,
			3128.489,
			3130.998,
			3132.619,
			3135.007,
			3136.568,
			3138.848,
			3140.344,
			3141.08,
			3142.527,
			3143.946,
			3144.654,
			3146.068,
			3147.458,
			3149.583,
			3151.062,
			3153.294,
			3154.063,
			3154.893,
			3157.4,
			3159.117,
			3162.052,
			3164.029,
			3166.19,
			3167.273,
			3169.397,
			3170.383,
			3171.366,
			3174.228,
			3175,
			3175.77,
			3178.068,
			3179.541,
			3181.596,
			3182.95,
			3184.297,
			3184.983,
			3186.347,
			3187.025,
			3188.377,
			3189.729,
			3191.762,
			3193.115,
			3195.836,
			3196.519,
			3198.563,
			3199.244,
			3199.949,
			3202.07,
			3203.479,
			3205.602,
			3208.428,
			3209.116,
			3210.508,
			3211.198,
			3212.57,
			3213.935,
			3215.987,
			3217.362,
			3220.059,
			3220.727,
			3222.716,
			3224.033,
			3225.99,
			3227.283,
			3229.215,
			3231.752,
			3232.382,
			3233.636,
			3234.262,
			3235.485,
			3236.685,
			3238.471,
			3239.655,
			3241.927,
			3242.491,
			3244.176,
			3245.312,
			3247.036,
			3248.182,
			3249.903,
			3251.116,
			3251.722,
			3252.934,
			3254.146,
			3254.752,
			3256.037,
			3257.325,
			3259.261,
			3260.594,
			3263.332,
			3264.017,
			3266.125,
			3267.551,
			3269.689,
			3271.152,
			3273.353,
			3275.573,
			3277.081,
			3278.588,
			3279.343,
			3280.916,
			3282.544,
			3284.999,
			3286.878,
			3289.719,
			3290.651,
			3291.573,
			3294.36,
			3296.128,
			3298.675,
			3300.388,
			3302.985,
			3305.595,
			3307.261,
			3308.938,
			3309.782,
			3311.3,
			3312.736,
			3314.914,
			3316.306,
			3319.045,
			3319.738,
			3321.854,
			3323.288,
			3325.466,
			3326.958,
			3329.222,
			3330.749,
			3331.524,
			3333.083,
			3334.656,
			3335.447,
			3337.067,
			3338.701,
			3340.341,
			3342.801,
			3346.108,
			3346.945,
			3349.466,
			3351.165,
			3352.906,
			3355.534,
			3357.305,
			3360.883,
			3361.782,
			3364.497,
			3365.408,
			3366.322,
			3369.065,
			3370.908,
			3373.682,
			3377.417,
			3378.357,
			3380.245,
			3381.193,
			3383.072,
			3384.975,
			3387.853,
			3389.786,
			3393.697,
			3394.685,
			3397.662,
			3398.659,
			3399.659,
			3402.677,
			3404.698,
			3407.715,
			3411.669,
			3412.64,
			3414.556,
			3415.5,
			3417.363,
			3419.194,
			3421.872,
			3423.619,
			3426.176,
			3427.012,
			3427.846,
			3430.313,
			3431.952,
			3434.382,
			3435.994,
			3438.403,
			3440.799,
			3442.388,
			3443.972,
			3444.761,
			3446.334,
			3447.9,
			3450.233,
			3451.782,
			3454.844,
			3455.605,
			3457.879,
			3459.369,
			3461.596,
			3463.08,
			3465.296,
			3466.771,
			3467.509,
			3469.087,
			3470.692,
			3471.494,
			3473.119,
			3474.941,
			3477.67,
			3479.754,
			3484.142,
			3485.335,
			3488.773,
			3490.76,
			3493.641,
			3495.322,
			3497.824,
			3500.213,
			3501.781,
			3503.343,
			3504.104,
			3505.62,
			3507.128,
			3509.378,
			3510.866,
			3513.081,
			3513.813,
			3514.541,
			3516.711,
			3518.149,
			3520.287,
			3521.692,
			3523.808,
			3525.932,
			3526.633,
			3528.734,
			3529.437,
			3530.831,
			3532.238,
			3534.36,
			3535.788,
			3538.683,
			3539.426,
			3541.633,
			3543.122,
			3544.619,
			3546.88,
			3548.405,
			3551.489,
			3552.266,
			3555.405,
			3556.196,
			3558.582,
			3560.175,
			3562.571,
			3564.177,
			3566.582,
			3568.995,
			3570.656,
			3572.326,
			3574.878,
			3576.691,
			3579.412,
			3581.317,
			3585.136,
			3586.108,
			3589.015,
			3590.922,
			3592.812,
			3593.754,
			3595.575,
			3598.206,
			3600.757,
			3602.386,
			3604.01,
			3606.422,
			3608.012,
			3611.164,
			3611.949,
			3614.267,
			3615.797,
			3618.042,
			3619.497,
			3621.617,
			3623.004,
			3625.024,
			3627.008,
			3628.31,
			3629.6,
			3631.517,
			3632.786,
			3635.32,
			3635.941,
			3637.803,
			3639.044,
			3640.904,
			3642.136,
			3643.369,
			3643.986,
			3645.223,
			3647.278,
			3649.344,
			3650.825,
			3652.616,
			3655.302,
			3657.335,
			3660.414,
			3662.403,
			3665.375,
			3667.519,
			3670.742,
			3672.786,
			3675.783,
			3678.12,
			3679.678,
			3681.772,
			3683.055,
			3684.341,
			3686.239,
			3686.846,
			3687.454,
			3689.288,
			3690.52,
			3692.44,
			3693.734,
			3695.693,
			3697.05,
			3699.115,
			3700.508,
			3702.656,
			3704.843,
			3705.579,
			3707.841,
			3710.142,
			3711.701,
			3713.283,
			3714.88,
			3715.684,
			3718.122,
			3719.765,
			3721.422,
			3723.924,
			3725.608,
			3728.134,
			3729.823,
			3733.204,
			3734.034,
			3736.585,
			3738.285,
			3740.857,
			3742.596,
			3745.237,
			3747.914,
			3749.718,
			3751.535,
			3754.32,
			3756.192,
			3759.037,
			3759.992,
			3760.951,
			3763.829,
			3765.759,
			3768.654,
			3770.587,
			3772.534,
			3773.516,
			3775.487,
			3778.487,
			3781.54,
			3783.594,
			3785.662,
			3788.785,
			3790.88,
			3794.02,
			3796.118,
			3799.278,
			3801.396,
			3804.584,
			3806.72,
			3808.865,
			3809.941,
			3812.102,
			3814.279,
			3815.373,
			3818.672,
			3820.885,
			3823.108,
			3826.463,
			3828.716,
			3832.129,
			3834.416,
			3837.865,
			3840.146,
			3843.574,
			3845.869,
			3848.173,
			3849.342,
			3851.695,
			3855.249,
			3858.829,
			3861.219,
			3863.618,
			3867.233,
			3869.656,
			3873.305,
			3874.525,
			3875.747,
			3879.426,
			3881.892,
			3885.617,
			3888.124,
			3891.904,
			3894.433,
			3895.688,
			3898.205,
			3900.721,
			3901.979,
			3904.501,
			3907.038,
			3910.856,
			3913.412,
			3918.559,
			3919.852,
			3923.742,
			3926.344,
			3930.243,
			3932.846,
			3936.797,
			3939.438,
			3939.647
		],
		[
			0,
			0.042,
			0.105,
			0.166,
			0.23,
			0.293,
			0.322,
			0.355,
			0.415,
			0.432,
			0.479,
			0.543,
			0.602,
			0.649,
			0.665,
			0.729,
			0.764,
			0.79,
			0.867,
			0.916,
			0.977,
			1.04,
			1.101,
			1.165,
			1.196,
			1.228,
			1.306,
			1.351,
			1.414,
			1.477,
			1.538,
			1.6,
			1.632,
			1.663,
			1.725,
			1.741,
			1.787,
			1.851,
			1.913,
			1.958,
			2.037,
			2.07,
			2.101,
			2.178,
			2.224,
			2.286,
			2.351,
			2.413,
			2.475,
			2.507,
			2.536,
			2.598,
			2.616,
			2.661,
			2.724,
			2.786,
			2.848,
			2.911,
			2.942,
			2.972,
			3.035,
			3.097,
			3.16,
			3.222,
			3.27,
			3.285,
			3.348,
			3.377,
			3.409,
			3.488,
			3.533,
			3.597,
			3.659,
			3.706,
			3.783,
			3.816,
			3.847,
			3.908,
			3.972,
			4.035,
			4.096,
			4.159,
			4.251,
			4.282,
			4.346,
			4.36,
			4.408,
			4.47,
			4.531,
			4.579,
			4.595,
			4.657,
			4.689,
			4.719,
			4.796,
			4.843,
			4.911,
			4.969,
			5.031,
			5.095,
			5.124,
			5.155,
			5.218,
			5.233,
			5.282,
			5.345,
			5.404,
			5.468,
			5.531,
			5.562,
			5.593,
			5.671,
			5.718,
			5.78,
			5.843,
			5.904,
			5.997,
			6.029,
			6.093,
			6.108,
			6.153,
			6.216,
			6.28,
			6.341,
			6.402,
			6.434,
			6.467,
			6.528,
			6.543,
			6.592,
			6.653,
			6.714,
			6.776,
			6.841,
			6.871,
			6.903,
			6.964,
			6.983,
			7.026,
			7.09,
			7.151,
			7.198,
			7.213,
			7.308,
			7.34,
			7.402,
			7.418,
			7.464,
			7.528,
			7.59,
			7.651,
			7.714,
			7.746,
			7.775,
			7.837,
			7.901,
			7.965,
			8.025,
			8.087,
			8.182,
			8.213,
			8.292,
			8.337,
			8.401,
			8.463,
			8.525,
			8.618,
			8.649,
			8.712,
			8.775,
			8.837,
			8.9,
			8.96,
			9.025,
			9.058,
			9.085,
			9.149,
			9.164,
			9.211,
			9.274,
			9.336,
			9.398,
			9.46,
			9.49,
			9.524,
			9.601,
			9.648,
			9.709,
			9.773,
			9.835,
			9.926,
			9.96,
			10.021,
			10.037,
			10.085,
			10.148,
			10.21,
			10.271,
			10.364,
			10.396,
			10.458,
			10.52,
			10.584,
			10.645,
			10.707,
			10.769,
			10.801,
			10.833,
			10.894,
			10.91,
			10.958,
			11.019,
			11.081,
			11.131,
			11.238,
			11.269,
			11.332,
			11.393,
			11.454,
			11.517,
			11.581,
			11.673,
			11.703,
			11.782,
			11.829,
			11.894,
			11.953,
			12.017,
			12.079,
			12.111,
			12.142,
			12.204,
			12.22,
			12.266,
			12.328,
			12.391,
			12.438,
			12.516,
			12.579,
			12.656,
			12.704,
			12.764,
			12.827,
			12.89,
			12.953,
			13.015,
			13.093,
			13.139,
			13.205,
			13.264,
			13.325,
			13.421,
			13.45,
			13.527,
			13.577,
			13.639,
			13.701,
			13.748,
			13.763,
			13.823,
			13.887,
			13.965,
			14.012,
			14.075,
			14.137,
			14.2,
			14.26,
			14.323,
			14.402,
			14.447,
			14.511,
			14.572,
			14.635,
			14.697,
			14.758,
			14.839,
			14.885,
			14.947,
			15.01,
			15.057,
			15.134,
			15.167,
			15.197,
			15.279,
			15.322,
			15.383,
			15.446,
			15.508,
			15.571,
			15.633,
			15.712,
			15.758,
			15.824,
			15.883,
			15.929,
			15.946,
			16.008,
			16.07,
			16.149,
			16.193,
			16.258,
			16.32,
			16.367,
			16.382,
			16.445,
			16.505,
			16.583,
			16.632,
			16.693,
			16.755,
			16.819,
			16.882,
			16.913,
			16.945,
			17.021,
			17.068,
			17.132,
			17.193,
			17.256,
			17.318,
			17.353,
			17.381,
			17.459,
			17.506,
			17.567,
			17.631,
			17.691,
			17.754,
			17.785,
			17.817,
			17.893,
			17.941,
			18.005,
			18.066,
			18.113,
			18.191,
			18.223,
			18.254,
			18.33,
			18.376,
			18.442,
			18.504,
			18.566,
			18.626,
			18.658,
			18.691,
			18.753,
			18.767,
			18.814,
			18.876,
			18.94,
			19.001,
			19.064,
			19.096,
			19.127,
			19.205,
			19.252,
			19.315,
			19.377,
			19.437,
			19.533,
			19.564,
			19.627,
			19.64,
			19.689,
			19.751,
			19.814,
			19.875,
			19.97,
			20,
			20.061,
			20.124,
			20.186,
			20.249,
			20.311,
			20.372,
			20.404,
			20.436,
			20.499,
			20.514,
			20.56,
			20.624,
			20.686,
			20.747,
			20.841,
			20.872,
			20.934,
			20.998,
			21.058,
			21.121,
			21.184,
			21.247,
			21.276,
			21.309,
			21.371,
			21.387,
			21.433,
			21.5,
			21.557,
			21.621,
			21.684,
			21.746,
			21.824,
			21.87,
			21.937,
			21.996,
			22.058,
			22.149,
			22.183,
			22.243,
			22.258,
			22.308,
			22.371,
			22.432,
			22.495,
			22.557,
			22.587,
			22.62,
			22.68,
			22.697,
			22.744,
			22.805,
			22.869,
			22.931,
			22.993,
			23.024,
			23.056,
			23.118,
			23.134,
			23.179,
			23.242,
			23.304,
			23.351,
			23.366,
			23.459,
			23.491,
			23.555,
			23.572,
			23.616,
			23.679,
			23.74,
			23.803,
			23.896,
			23.927,
			24.007,
			24.053,
			24.115,
			24.176,
			24.24,
			24.334,
			24.364,
			24.425,
			24.443,
			24.488,
			24.552,
			24.614,
			24.676,
			24.768,
			24.801,
			24.863,
			24.926,
			24.987,
			25.05,
			25.114,
			25.206,
			25.238,
			25.315,
			25.362,
			25.425,
			25.487,
			25.534,
			25.613,
			25.674,
			25.753,
			25.798,
			25.863,
			25.924,
			25.987,
			26.048,
			26.109,
			26.188,
			26.234,
			26.298,
			26.361,
			26.423,
			26.484,
			26.515,
			26.547,
			26.625,
			26.672,
			26.734,
			26.797,
			26.844,
			26.859,
			26.92,
			26.982,
			27.06,
			27.107,
			27.171,
			27.232,
			27.296,
			27.357,
			27.42,
			27.498,
			27.544,
			27.607,
			27.67,
			27.72,
			27.731,
			27.795,
			27.825,
			27.858,
			27.934,
			27.982,
			28.044,
			28.106,
			28.153,
			28.169,
			28.232,
			28.293,
			28.373,
			28.417,
			28.482,
			28.543,
			28.591,
			28.606,
			28.667,
			28.731,
			28.809,
			28.855,
			28.918,
			28.98,
			29.026,
			29.042,
			29.105,
			29.134,
			29.166,
			29.244,
			29.292,
			29.354,
			29.417,
			29.464,
			29.54,
			29.604,
			29.68,
			29.726,
			29.793,
			29.851,
			29.914,
			29.979,
			30.009,
			30.039,
			30.118,
			30.164,
			30.227,
			30.289,
			30.337,
			30.414,
			30.445,
			30.475,
			30.555,
			30.601,
			30.664,
			30.727,
			30.772,
			30.788,
			30.85,
			30.913,
			30.991,
			31.038,
			31.099,
			31.161,
			31.224,
			31.288,
			31.317,
			31.348,
			31.428,
			31.473,
			31.538,
			31.598,
			31.66,
			31.723,
			31.756,
			31.786,
			31.848,
			31.868,
			31.909,
			31.973,
			32.035,
			32.098,
			32.16,
			32.191,
			32.223,
			32.284,
			32.348,
			32.409,
			32.472,
			32.534,
			32.628,
			32.657,
			32.721,
			32.737,
			32.783,
			32.846,
			32.908,
			32.971,
			33.032,
			33.065,
			33.094,
			33.173,
			33.219,
			33.283,
			33.345,
			33.408,
			33.47,
			33.501,
			33.531,
			33.61,
			33.657,
			33.72,
			33.781,
			33.843,
			33.904,
			33.967,
			34.047,
			34.093,
			34.154,
			34.217,
			34.28,
			34.375,
			34.405,
			34.483,
			34.53,
			34.591,
			34.653,
			34.717,
			34.81,
			34.841,
			34.919,
			34.967,
			35.029,
			35.091,
			35.154,
			35.247,
			35.277,
			35.339,
			35.404,
			35.466,
			35.527,
			35.588,
			35.651,
			35.714,
			35.793,
			35.838,
			35.903,
			35.963,
			36.026,
			36.121,
			36.151,
			36.229,
			36.276,
			36.338,
			36.399,
			36.447,
			36.463,
			36.524,
			36.587,
			36.665,
			36.713,
			36.775,
			36.836,
			36.9,
			36.962,
			37.024,
			37.101,
			37.15,
			37.21,
			37.273,
			37.336,
			37.43,
			37.458,
			37.537,
			37.584,
			37.647,
			37.711,
			37.757,
			37.834,
			37.897,
			37.975,
			38.023,
			38.087,
			38.146,
			38.208,
			38.272,
			38.334,
			38.41,
			38.458,
			38.521,
			38.584,
			38.647,
			38.707,
			38.738,
			38.77,
			38.849,
			38.895,
			38.958,
			39.02,
			39.067,
			39.082,
			39.143,
			39.207,
			39.285,
			39.331,
			39.395,
			39.457,
			39.518,
			39.581,
			39.643,
			39.721,
			39.767,
			39.831,
			39.892,
			39.94,
			40.017,
			40.079,
			40.162,
			40.204,
			40.27,
			40.329,
			40.375,
			40.391,
			40.454,
			40.516,
			40.593,
			40.641,
			40.704,
			40.765,
			40.829,
			40.89,
			40.921,
			40.952,
			41.03,
			41.079,
			41.14,
			41.203,
			41.25,
			41.265,
			41.328,
			41.358,
			41.391,
			41.468,
			41.515,
			41.575,
			41.639,
			41.684,
			41.763,
			41.824,
			41.902,
			41.949,
			42.013,
			42.074,
			42.136,
			42.2,
			42.233,
			42.262,
			42.34,
			42.385,
			42.449,
			42.51,
			42.558,
			42.634,
			42.698,
			42.775,
			42.823,
			42.886,
			42.948,
			42.993,
			43.009,
			43.072,
			43.104,
			43.134,
			43.197,
			43.211,
			43.258,
			43.321,
			43.384,
			43.432,
			43.508,
			43.541,
			43.57,
			43.648,
			43.696,
			43.758,
			43.821,
			43.867,
			43.884,
			43.946,
			44.008,
			44.087,
			44.134,
			44.198,
			44.258,
			44.321,
			44.414,
			44.444,
			44.506,
			44.57,
			44.631,
			44.692,
			44.741,
			44.758,
			44.818,
			44.851,
			44.881,
			44.96,
			45.007,
			45.067,
			45.13,
			45.176,
			45.194,
			45.256,
			45.286,
			45.319,
			45.381,
			45.442,
			45.506,
			45.568,
			45.631,
			45.691,
			45.724,
			45.755,
			45.833,
			45.88,
			45.941,
			46.005,
			46.052,
			46.066,
			46.129,
			46.161,
			46.191,
			46.27,
			46.317,
			46.382,
			46.439,
			46.502,
			46.565,
			46.597,
			46.626,
			46.689,
			46.753,
			46.815,
			46.875,
			46.939,
			47.001,
			47.032,
			47.063,
			47.14,
			47.188,
			47.25,
			47.313,
			47.359,
			47.438,
			47.467,
			47.498,
			47.577,
			47.624,
			47.688,
			47.75,
			47.795,
			47.81,
			47.874,
			47.936,
			48.014,
			48.061,
			48.124,
			48.184,
			48.247,
			48.31,
			48.372,
			48.454,
			48.498,
			48.559,
			48.622,
			48.685,
			48.777,
			48.809,
			48.872,
			48.887,
			48.933,
			48.998,
			49.059,
			49.12,
			49.184,
			49.215,
			49.244,
			49.309,
			49.37,
			49.431,
			49.496,
			49.558,
			49.621,
			49.649,
			49.681,
			49.745,
			49.76,
			49.806,
			49.871,
			49.932,
			49.992,
			50.087,
			50.117,
			50.181,
			50.244,
			50.307,
			50.367,
			50.431,
			50.494,
			50.527,
			50.555,
			50.616,
			50.634,
			50.679,
			50.743,
			50.804,
			50.868,
			50.96,
			50.99,
			51.054,
			51.069,
			51.115,
			51.179,
			51.24,
			51.302,
			51.396,
			51.429,
			51.488,
			51.506,
			51.551,
			51.616,
			51.675,
			51.738,
			51.801,
			51.831,
			51.865,
			51.926,
			51.942,
			51.989,
			52.05,
			52.113,
			52.176,
			52.27,
			52.299,
			52.362,
			52.424,
			52.487,
			52.548,
			52.613,
			52.707,
			52.737,
			52.799,
			52.816,
			52.861,
			52.925,
			52.987,
			53.049,
			53.112,
			53.171,
			53.252,
			53.298,
			53.36,
			53.423,
			53.486,
			53.58,
			53.608,
			53.671,
			53.689,
			53.734,
			53.798,
			53.858,
			53.923,
			53.984,
			54.048,
			54.126,
			54.171,
			54.233,
			54.296,
			54.358,
			54.453,
			54.483,
			54.562,
			54.607,
			54.674,
			54.732,
			54.796,
			54.89,
			54.921,
			54.983,
			55.045,
			55.108,
			55.17,
			55.233,
			55.292,
			55.325,
			55.357,
			55.434,
			55.481,
			55.543,
			55.607,
			55.669,
			55.762,
			55.793,
			55.872,
			55.919,
			55.981,
			56.043,
			56.106,
			56.168,
			56.229,
			56.308,
			56.355,
			56.416,
			56.481,
			56.54,
			56.603,
			56.667,
			56.748,
			56.791,
			56.853,
			56.917,
			56.979,
			57.072,
			57.104,
			57.182,
			57.229,
			57.29,
			57.352,
			57.416,
			57.508,
			57.539,
			57.603,
			57.619,
			57.665,
			57.727,
			57.789,
			57.85,
			57.915,
			57.975,
			58.055,
			58.101,
			58.164,
			58.225,
			58.289,
			58.38,
			58.414,
			58.49,
			58.539,
			58.6,
			58.661,
			58.724,
			58.788,
			58.849,
			58.91,
			58.975,
			59.037,
			59.098,
			59.162,
			59.223,
			59.287,
			59.364,
			59.41,
			59.472,
			59.535,
			59.598,
			59.69,
			59.721,
			59.801,
			59.847,
			59.909,
			59.971,
			60.034,
			60.129,
			60.158,
			60.236,
			60.283,
			60.346,
			60.564,
			60.593,
			60.658,
			60.719,
			60.782,
			60.843,
			60.907,
			60.97,
			61.001,
			61.03,
			61.093,
			61.108,
			61.157,
			61.218,
			61.281,
			61.342,
			61.436,
			61.467,
			61.53,
			61.546,
			61.591,
			61.654,
			61.718,
			61.781,
			61.843,
			61.872,
			61.904,
			61.967,
			61.983,
			62.03,
			62.091,
			62.152,
			62.216,
			62.279,
			62.311,
			62.341,
			62.418,
			62.465,
			62.527,
			62.591,
			62.654,
			62.746,
			62.777,
			62.839,
			62.902,
			62.967,
			63.026,
			63.088,
			63.153,
			63.182,
			63.213,
			63.275,
			63.293,
			63.339,
			63.4,
			63.462,
			63.524,
			63.62,
			63.651,
			63.729,
			63.774,
			63.837,
			63.898,
			63.96,
			64.055,
			64.086,
			64.147,
			64.163,
			64.21,
			64.274,
			64.336,
			64.398,
			64.459,
			64.523,
			64.601,
			64.647,
			64.711,
			64.771,
			64.836,
			64.929,
			64.96,
			65.041,
			65.083,
			65.147,
			65.208,
			65.272,
			65.365,
			65.396,
			65.459,
			65.521,
			65.584,
			65.646,
			65.707,
			65.77,
			65.801,
			65.832,
			65.911,
			65.958,
			66.02,
			66.083,
			66.129,
			66.145,
			66.206,
			66.27,
			66.348,
			66.395,
			66.458,
			66.518,
			66.58,
			66.675,
			66.704,
			66.784,
			66.831,
			66.891,
			66.955,
			67.018,
			67.081,
			67.143,
			67.22,
			67.268,
			67.332,
			67.393,
			67.455,
			67.549,
			67.579,
			67.657,
			67.704,
			67.766,
			67.827,
			67.891,
			67.983,
			68.016,
			68.093,
			68.139,
			68.201,
			68.265,
			68.326,
			68.388,
			68.45,
			68.53,
			68.576,
			68.638,
			68.701,
			68.763,
			68.855,
			68.887,
			68.967,
			69.014,
			69.074,
			69.136,
			69.2,
			69.262,
			69.324,
			69.401,
			69.448,
			69.513,
			69.574,
			69.637,
			69.698,
			69.761,
			69.839,
			69.884,
			69.949,
			70.01,
			70.072,
			70.166,
			70.198,
			70.276,
			70.323,
			70.383,
			70.448,
			70.493,
			70.51,
			70.572,
			70.634,
			70.712,
			70.758,
			70.821,
			70.884,
			70.943,
			71.007,
			71.07,
			71.148,
			71.196,
			71.258,
			71.319,
			71.381,
			71.475,
			71.506,
			71.584,
			71.631,
			71.693,
			71.757,
			71.803,
			71.82,
			71.88,
			71.943,
			72.022,
			72.067,
			72.131,
			72.191,
			72.256,
			72.317,
			72.349,
			72.38,
			72.458,
			72.505,
			72.565,
			72.627,
			72.676,
			72.692,
			72.754,
			72.816,
			72.895,
			72.941,
			73.004,
			73.065,
			73.112,
			73.191,
			73.252,
			73.334,
			73.378,
			73.439,
			73.502,
			73.548,
			73.565,
			73.627,
			73.659,
			73.688,
			73.767,
			73.814,
			73.88,
			73.939,
			73.986,
			74.064,
			74.125,
			74.203,
			74.25,
			74.313,
			74.375,
			74.423,
			74.439,
			74.498,
			74.562,
			74.639,
			74.687,
			74.749,
			74.813,
			74.858,
			74.937,
			74.998,
			75.077,
			75.124,
			75.187,
			75.249,
			75.296,
			75.31,
			75.373,
			75.434,
			75.514,
			75.559,
			75.623,
			75.686,
			75.73,
			75.748,
			75.81,
			75.872,
			75.949,
			75.997,
			76.058,
			76.122,
			76.185,
			76.247,
			76.279,
			76.308,
			76.385,
			76.433,
			76.494,
			76.558,
			76.604,
			76.62,
			76.683,
			76.744,
			76.824,
			76.87,
			76.934,
			76.993,
			77.041,
			77.057,
			77.118,
			77.181,
			77.259,
			77.307,
			77.368,
			77.431,
			77.493,
			77.554,
			77.585,
			77.618,
			77.696,
			77.742,
			77.805,
			77.866,
			77.93,
			78.022,
			78.055,
			78.116,
			78.131,
			78.179,
			78.242,
			78.304,
			78.367,
			78.429,
			78.46,
			78.492,
			78.569,
			78.617,
			78.677,
			78.741,
			78.788,
			78.865,
			78.896,
			78.926,
			79.006,
			79.051,
			79.113,
			79.176,
			79.224,
			79.239,
			79.3,
			79.333,
			79.364,
			79.426,
			79.442,
			79.49,
			79.555,
			79.613,
			79.675,
			79.737,
			79.769,
			79.801,
			79.879,
			79.924,
			79.988,
			80.049,
			80.097,
			80.113,
			80.174,
			80.204,
			80.236,
			80.314,
			80.36,
			80.423,
			80.486,
			80.548,
			80.61,
			80.64,
			80.673,
			80.734,
			80.798,
			80.864,
			80.923,
			80.983,
			81.048,
			81.077,
			81.109,
			81.188,
			81.234,
			81.297,
			81.359,
			81.406,
			81.483,
			81.514,
			81.546,
			81.608,
			81.67,
			81.734,
			81.793,
			81.858,
			81.92,
			81.95,
			81.983,
			82.046,
			82.06,
			82.108,
			82.17,
			82.232,
			82.292,
			82.356,
			82.386,
			82.419,
			82.498,
			82.543,
			82.607,
			82.667,
			82.716,
			82.732,
			82.794,
			82.825,
			82.854,
			82.917,
			82.934,
			82.98,
			83.042,
			83.105,
			83.168,
			83.23,
			83.262,
			83.291,
			83.355,
			83.371,
			83.416,
			83.48,
			83.541,
			83.604,
			83.701,
			83.729,
			83.791,
			83.807,
			83.854,
			83.916,
			83.977,
			84.042,
			84.134,
			84.167,
			84.229,
			84.243,
			84.291,
			84.353,
			84.414,
			84.478,
			84.572,
			84.603,
			84.665,
			84.727,
			84.791,
			84.85,
			84.915,
			85.007,
			85.04,
			85.101,
			85.118,
			85.164,
			85.227,
			85.287,
			85.351,
			85.443,
			85.477,
			85.539,
			85.6,
			85.664,
			85.724,
			85.787,
			85.85,
			85.88,
			85.912,
			85.973,
			85.99,
			86.037,
			86.101,
			86.16,
			86.223,
			86.318,
			86.35,
			86.411,
			86.473,
			86.535,
			86.598,
			86.659,
			86.755,
			86.786,
			86.847,
			86.862,
			86.91,
			86.971,
			87.035,
			87.097,
			87.159,
			87.191,
			87.222,
			87.283,
			87.347,
			87.413,
			87.471,
			87.534,
			87.625,
			87.658,
			87.72,
			87.736,
			87.784,
			87.849,
			87.908,
			87.971,
			88.064,
			88.094,
			88.157,
			88.219,
			88.281,
			88.343,
			88.407,
			88.468,
			88.499,
			88.531,
			88.593,
			88.609,
			88.657,
			88.718,
			88.78,
			88.843,
			88.937,
			88.967,
			89.031,
			89.093,
			89.154,
			89.217,
			89.28,
			89.374,
			89.403,
			89.467,
			89.482,
			89.527,
			89.591,
			89.654,
			89.717,
			89.809,
			89.84,
			89.901,
			89.923,
			89.964,
			90.029,
			90.09,
			90.15,
			90.245,
			90.277,
			90.338,
			90.356,
			90.402,
			90.465,
			90.525,
			90.588,
			90.683,
			90.713,
			90.776,
			90.791,
			90.837,
			90.901,
			90.962,
			91.026,
			91.088,
			91.12,
			91.15,
			91.229,
			91.276,
			91.337,
			91.398,
			91.46,
			91.554,
			91.586,
			91.648,
			91.664,
			91.71,
			91.773,
			91.837,
			91.898,
			91.997,
			92.023,
			92.087,
			92.149,
			92.21,
			92.274,
			92.335,
			92.398,
			92.429,
			92.461,
			92.523,
			92.537,
			92.585,
			92.646,
			92.708,
			92.772,
			92.866,
			92.897,
			92.958,
			93.022,
			93.083,
			93.145,
			93.209,
			93.301,
			93.332,
			93.395,
			93.41,
			93.459,
			93.52,
			93.581,
			93.643,
			93.707,
			93.737,
			93.77,
			93.831,
			93.893,
			93.957,
			94.02,
			94.081,
			94.174,
			94.205,
			94.269,
			94.283,
			94.331,
			94.392,
			94.456,
			94.517,
			94.611,
			94.642,
			94.721,
			94.768,
			94.83,
			94.891,
			94.954,
			95.049,
			95.08,
			95.158,
			95.204,
			95.268,
			95.329,
			95.391,
			95.453,
			95.516,
			95.593,
			95.64,
			95.701,
			95.765,
			95.827,
			95.891,
			95.951,
			96.031,
			96.077,
			96.143,
			96.202,
			96.249,
			96.265,
			96.326,
			96.389,
			96.467,
			96.513,
			96.576,
			96.639,
			96.701,
			96.793,
			96.825,
			96.887,
			96.949,
			97.01,
			97.074,
			97.138,
			97.198,
			97.262,
			97.339,
			97.388,
			97.45,
			97.511,
			97.575,
			97.667,
			97.698,
			97.776,
			97.823,
			97.885,
			97.947,
			97.993,
			98.008,
			98.074,
			98.134,
			98.216,
			98.26,
			98.323,
			98.383,
			98.447,
			98.51,
			98.57,
			98.648,
			98.696,
			98.759,
			98.822,
			98.883,
			98.977,
			99.007,
			99.084,
			99.131,
			99.194,
			99.258,
			99.304,
			99.32,
			99.381,
			99.445,
			99.521,
			99.567,
			99.631,
			99.692,
			99.756,
			99.817,
			99.881,
			99.957,
			100.004,
			100.067,
			100.131,
			100.193,
			100.254,
			100.291,
			100.317,
			100.396,
			100.441,
			100.504,
			100.567,
			100.614,
			100.692,
			100.755,
			100.831,
			100.879,
			100.943,
			101.005,
			101.065,
			101.127,
			101.189,
			101.269,
			101.314,
			101.376,
			101.44,
			101.486,
			101.565,
			101.596,
			101.627,
			101.705,
			101.751,
			101.814,
			101.876,
			101.924,
			101.94,
			102.001,
			102.065,
			102.142,
			102.188,
			102.251,
			102.313,
			102.375,
			102.438,
			102.501,
			102.58,
			102.624,
			102.689,
			102.751,
			102.796,
			102.813,
			102.874,
			102.906,
			102.937,
			103.015,
			103.061,
			103.124,
			103.187,
			103.233,
			103.31,
			103.373,
			103.45,
			103.5,
			103.563,
			103.624,
			103.67,
			103.685,
			103.748,
			103.812,
			103.889,
			103.934,
			103.997,
			104.06,
			104.108,
			104.183,
			104.215,
			104.246,
			104.31,
			104.372,
			104.438,
			104.497,
			104.558,
			104.623,
			104.651,
			104.683,
			104.746,
			104.762,
			104.81,
			104.871,
			104.934,
			104.981,
			105.09,
			105.12,
			105.197,
			105.245,
			105.308,
			105.37,
			105.431,
			105.493,
			105.499
		]
	],
	'7272_8310_7982': [
		[
			95.969
		],
		[
			0
		],
		[
			0,
			3.235,
			7.262,
			9.952,
			13.999,
			16.706,
			19.421,
			20.781,
			23.506,
			24.872,
			27.599,
			30.328,
			34.433,
			37.182,
			41.327,
			42.714,
			44.102,
			48.278,
			51.071,
			55.271,
			58.08,
			62.306,
			65.132,
			67.965,
			69.384,
			73.65,
			76.503,
			79.36,
			83.658,
			86.531,
			92.299,
			93.745,
			98.095,
			101.002,
			105.376,
			108.299,
			112.694,
			115.631,
			118.572,
			120.044,
			124.47,
			127.428,
			130.392,
			134.847,
			137.824,
			142.297,
			143.789,
			145.283,
			149.771,
			152.768,
			157.27,
			160.277,
			163.288,
			164.795,
			167.812,
			169.322,
			172.347,
			175.382,
			176.901,
			179.96,
			183.031,
			187.67,
			189.228,
			190.788,
			203.412,
			206.643,
			211.544,
			214.773,
			219.566,
			222.738,
			224.325,
			227.502,
			230.662,
			232.242,
			235.39,
			238.531,
			243.219,
			246.314,
			252.414,
			253.917,
			258.367,
			261.281,
			265.575,
			268.388,
			271.155,
			272.524,
			275.238,
			276.582,
			277.916,
			283.167,
			284.458,
			288.274,
			290.774,
			294.455,
			298.055,
			299.237,
			300.409,
			303.875,
			306.141,
			308.37,
			311.644,
			313.78,
			316.917,
			317.943,
			318.962,
			321.963,
			322.946,
			323.92,
			326.794,
			328.682,
			331.434,
			333.231,
			335.003,
			335.878,
			337.607,
			338.462,
			340.153,
			342.648,
			344.285,
			345.9,
			349.091,
			349.877,
			352.229,
			353.011,
			353.792,
			356.1,
			357.635,
			359.908,
			362.898,
			363.646,
			365.839,
			367.298,
			368.76,
			370.991,
			372.482,
			375.668,
			376.504,
			379.069,
			381.11,
			384.23,
			386.55,
			390.004,
			393.405,
			395.594,
			397.764,
			398.85,
			400.914,
			402.976,
			405.854,
			407.714,
			411.205,
			412.051,
			415.417,
			416.252,
			418.767,
			420.459,
			423.011,
			425.573,
			427.285,
			429.006,
			429.858,
			431.567,
			433.283,
			435.879,
			437.621,
			440.279,
			441.185,
			442.093,
			444.836,
			446.693,
			449.495,
			451.371,
			454.205,
			457.068,
			458.989,
			461.869,
			463.8,
			465.713,
			468.539,
			470.383,
			473.954,
			474.825,
			477.395,
			479.063,
			481.506,
			483.091,
			485.399,
			486.892,
			488.349,
			491.161,
			492.523,
			493.863,
			495.19,
			497.16,
			499.12,
			499.767,
			500.414,
			502.341,
			503.619,
			504.892,
			506.781,
			508.66,
			510.512,
			511.118,
			512.932,
			513.536,
			514.14,
			515.911,
			517.074,
			518.234,
			518.814,
			519.979,
			521.746,
			523.504,
			524.674,
			526.036,
			528.077,
			529.434,
			531.838,
			533.475,
			536.984,
			537.895,
			540.68,
			542.61,
			545.518,
			547.468,
			550.35,
			552.133,
			553.028,
			554.819,
			556.267,
			558.456,
			559.916,
			561.925,
			563.276,
			565.329,
			566.724,
			568.839,
			570.266,
			572.432,
			573.895,
			576.113,
			578.363,
			579.87,
			581.409,
			583.746,
			585.323,
			587.718,
			589.333,
			591.784,
			593.436,
			595.94,
			597.621,
			600.141,
			601.834,
			604.398,
			606.992,
			608.736,
			610.495,
			613.155,
			614.048,
			614.959,
			617.661,
			618.568,
			619.479,
			622.225,
			624.071,
			626.872,
			628.758,
			630.662,
			631.627,
			633.566,
			634.54,
			636.542,
			639.6,
			640.647,
			643.88,
			647.097,
			648.164,
			649.233,
			652.421,
			654.54,
			657.843,
			660.067,
			662.351,
			665.78,
			668.043,
			671.401,
			672.494,
			673.589,
			677.846,
			678.891,
			681.993,
			684.045,
			686.102,
			687.129,
			689.191,
			692.289,
			695.391,
			697.461,
			699.576,
			702.741,
			704.863,
			708.071,
			709.146,
			710.223,
			714.562,
			715.653,
			718.931,
			721.128,
			723.331,
			724.435,
			726.653,
			729.998,
			733.375,
			735.641,
			737.906,
			741.313,
			743.577,
			746.996,
			749.295,
			752.769,
			755.122,
			758.676,
			761.078,
			763.448,
			764.646,
			767.046,
			769.453,
			770.66,
			774.263,
			776.671,
			779.081,
			782.731,
			785.189,
			788.911,
			790.158,
			791.407,
			795.166,
			797.681,
			801.469,
			804.003,
			806.544,
			807.818,
			810.37,
			814.212,
			818.067,
			820.645,
			823.229,
			827.117,
			829.706,
			833.6,
			836.205,
			840.128,
			842.753,
			846.704,
			849.345,
			851.994,
			853.322,
			855.982,
			859.988,
			864.012,
			866.703,
			869.402,
			873.465,
			876.183,
			880.275,
			881.643,
			883.013,
			887.131,
			889.886,
			894.031,
			896.804,
			900.977,
			903.767,
			907.965,
			912.179,
			914.997,
			917.82,
			922.066,
			923.484,
			924.901,
			927.729,
			931.938,
			936.103,
			938.833,
			942.85,
			945.463,
			948.039,
			951.838,
			955.562,
			959.204,
			960.401,
			963.94,
			966.256,
			969.664,
			971.889,
			974.076,
			975.155,
			977.286,
			978.337,
			980.412,
			983.455,
			985.438,
			987.383,
			990.232,
			992.085,
			994.802,
			996.572,
			999.163,
			1000.846,
			1002.492,
			1003.302,
			1004.895,
			1006.452,
			1007.218,
			1009.475,
			1010.927,
			1012.352,
			1014.445,
			1015.818,
			1017.856,
			1018.528,
			1019.198,
			1021.199,
			1022.528,
			1024.49,
			1025.789,
			1027.087,
			1027.736,
			1028.995,
			1030.874,
			1032.755,
			1033.989,
			1035.218,
			1037.059,
			1038.311,
			1040.247,
			1041.533,
			1043.579,
			1045.123,
			1047.428,
			1049.379,
			1051.507,
			1052.569,
			1055.304,
			1056.727,
			1059.221,
			1061.441,
			1062.548,
			1064.128,
			1065.552,
			1067.676,
			1068.971,
			1070.851,
			1071.475,
			1072.1,
			1073.986,
			1075.252,
			1077.152,
			1078.424,
			1079.709,
			1080.351,
			1081.628,
			1082.274,
			1083.583,
			1085.551,
			1086.875,
			1088.208,
			1090.213,
			1090.884,
			1091.555,
			1093.573,
			1094.25,
			1094.926,
			1096.949,
			1098.295,
			1100.375,
			1101.756,
			1103.132,
			1105.32,
			1107.508,
			1109.776,
			1111.322,
			1112.86,
			1115.23,
			1116.025,
			1116.818,
			1118.402,
			1120.813,
			1123.216,
			1124.755,
			1127.037,
			1128.556,
			1129.961,
			1132.065,
			1133.469,
			1135.571,
			1136.27,
			1136.969,
			1139.118,
			1140.654,
			1142.957,
			1144.588,
			1146.276,
			1147.12,
			1149.665,
			1151.371,
			1153.078,
			1155.465,
			1157.024,
			1159.317,
			1160.045,
			1160.774,
			1162.964,
			1163.697,
			1164.435,
			1166.65,
			1168.133,
			1170.314,
			1171.761,
			1173.215,
			1173.943,
			1176.087,
			1177.526,
			1178.977,
			1181.178,
			1182.654,
			1184.891,
			1186.395,
			1188.662,
			1190.181,
			1192.466,
			1193.994,
			1195.528,
			1196.295,
			1197.831,
			1200.141,
			1202.459,
			1204.01,
			1205.574,
			1207.931,
			1209.522,
			1211.943,
			1212.755,
			1213.571,
			1216.06,
			1217.734,
			1220.271,
			1221.976,
			1224.547,
			1226.262,
			1227.983,
			1228.846,
			1231.402,
			1233.11,
			1234.811,
			1237.342,
			1239.031,
			1241.545,
			1242.383,
			1243.222,
			1245.724,
			1247.385,
			1249.867,
			1251.507,
			1253.142,
			1253.958,
			1255.574,
			1257.985,
			1260.388,
			1261.986,
			1263.588,
			1266.024,
			1267.66,
			1270.126,
			1270.961,
			1271.797,
			1274.321,
			1276.027,
			1278.609,
			1280.355,
			1283.009,
			1284.791,
			1285.688,
			1287.487,
			1290.201,
			1292.016,
			1293.836,
			1296.573,
			1297.488,
			1298.402,
			1300.232,
			1302.057,
			1302.971,
			1304.798,
			1305.703,
			1307.513,
			1310.224,
			1312.011,
			1313.797,
			1317.352,
			1318.239,
			1320.914,
			1321.813,
			1322.712,
			1325.446,
			1327.342,
			1330.221,
			1334.345,
			1335.404,
			1337.57,
			1338.653,
			1340.772,
			1342.837,
			1345.854,
			1347.758,
			1350.604,
			1352.471,
			1355.285,
			1357.177,
			1360.031,
			1361.95,
			1363.878,
			1364.847,
			1366.798,
			1369.745,
			1372.72,
			1374.719,
			1376.728,
			1379.763,
			1381.801,
			1384.878,
			1386.945,
			1390.068,
			1392.174,
			1395.342,
			1397.458,
			1400.649,
			1402.787,
			1406.013,
			1408.177,
			1409.262,
			1411.442,
			1413.634,
			1416.948,
			1419.174,
			1422.519,
			1424.754,
			1428.104,
			1430.345,
			1433.716,
			1435.97,
			1438.216,
			1439.343,
			1441.592,
			1442.713,
			1444.961,
			1448.327,
			1450.579,
			1452.858,
			1456.306,
			1458.649,
			1463.378,
			1464.569,
			1468.162,
			1470.57,
			1474.208,
			1476.646,
			1479.095,
			1480.323,
			1482.788,
			1486.503,
			1490.24,
			1491.491,
			1495.256,
			1499.032,
			1501.576,
			1506.675,
			1507.938,
			1511.738,
			1514.274,
			1518.108,
			1520.681,
			1524.555,
			1527.146,
			1531.031,
			1534.926,
			1537.53,
			1540.142,
			1542.76,
			1546.702,
			1551.983,
			1553.307,
			1557.291,
			1559.956,
			1562.629,
			1566.653,
			1569.346,
			1574.754,
			1576.11,
			1581.558,
			1582.925,
			1587.037,
			1589.788,
			1593.93,
			1599.48,
			1600.872,
			1603.662,
			1605.059,
			1607.859,
			1612.074,
			1614.893,
			1617.719,
			1621.97,
			1624.813,
			1629.091,
			1631.951,
			1636.248,
			1639.119,
			1643.421,
			1649.099,
			1650.499,
			1654.642,
			1657.357,
			1660.036,
			1663.978,
			1666.554,
			1670.347,
			1672.83,
			1676.491,
			1678.887,
			1682.417,
			1684.729,
			1687.004,
			1688.127,
			1690.346,
			1691.441,
			1693.603,
			1696.774,
			1698.842,
			1700.872,
			1703.848,
			1705.786,
			1708.624,
			1710.471,
			1713.178,
			1714.94,
			1717.516,
			1719.188,
			1720.822,
			1721.625,
			1723.206,
			1725.507,
			1727.726,
			1729.157,
			1730.552,
			1732.58,
			1733.234,
			1733.88,
			1735.77,
			1736.393,
			1736.997,
			1738.75,
			1739.874,
			1741.496,
			1742.537,
			1744.003,
			1745.396,
			1746.289,
			1747.157,
			1747.585,
			1747.995,
			1749.196,
			1749.978,
			1751.133,
			1751.891,
			1753.026,
			1754.169,
			1754.927,
			1755.683,
			1756.821,
			1757.583,
			1759.199,
			1759.604,
			1761.234,
			1761.645,
			1762.897,
			1763.76,
			1765.059,
			1765.928,
			1767.242,
			1768.595,
			1769.502,
			1770.407,
			1771.763,
			1772.668,
			1774.126,
			1775.087,
			1776.991,
			1777.465,
			1778.983,
			1779.996,
			1781.003,
			1781.504,
			1782.506,
			1784.115,
			1785.723,
			1786.79,
			1787.874,
			1789.719,
			1790.94,
			1792.766,
			1794.35,
			1796.716,
			1798.405,
			1801.296,
			1803.205,
			1805.047,
			1805.967,
			1807.809,
			1809.062,
			1809.686,
			1810.937,
			1811.564,
			1812.826,
			1813.77,
			1815.183,
			1816.134,
			1818.056,
			1818.523,
			1819.938,
			1820.894,
			1822.35,
			1823.345,
			1824.36,
			1824.873,
			1825.909,
			1826.432,
			1827.492,
			1829.093,
			1830.175,
			1831.275,
			1832.959,
			1834.101,
			1835.844,
			1837.02,
			1838.816,
			1840.049,
			1841.922,
			1843.198,
			1845.173,
			1846.508,
			1847.183,
			1848.553,
			1850.655,
			1852.077,
			1853.52,
			1855.715,
			1856.458,
			1857.207,
			1859.494,
			1860.268,
			1861.046,
			1863.401,
			1864.978,
			1867.373,
			1868.965,
			1870.556,
			1872.97,
			1875.427,
			1877.917,
			1878.759,
			1881.294,
			1882.984,
			1884.685,
			1885.54,
			1887.261,
			1888.993,
			1889.862,
			1892.49,
			1894.258,
			1896.038,
			1898.733,
			1900.546,
			1904.219,
			1905.151,
			1907.967,
			1908.913,
			1909.863,
			1912.732,
			1914.648,
			1917.539,
			1919.477,
			1921.424,
			1922.402,
			1925.355,
			1927.338,
			1929.335,
			1932.355,
			1934.383,
			1937.448,
			1938.475,
			1939.506,
			1942.615,
			1944.7,
			1947.844,
			1949.938,
			1953.092,
			1955.207,
			1957.332,
			1958.399,
			1960.539,
			1961.614,
			1963.769,
			1965.935,
			1969.203,
			1971.393,
			1974.699,
			1975.807,
			1976.918,
			1980.271,
			1982.524,
			1985.92,
			1988.196,
			1990.474,
			1991.615,
			1993.904,
			1995.049,
			1997.347,
			2000.811,
			2003.132,
			2005.466,
			2008.989,
			2011.349,
			2016.099,
			2017.292,
			2020.887,
			2023.295,
			2026.924,
			2029.354,
			2033.018,
			2035.471,
			2036.701,
			2039.167,
			2042.883,
			2045.371,
			2047.869,
			2051.634,
			2052.893,
			2054.155,
			2057.96,
			2059.232,
			2060.509,
			2064.352,
			2066.92,
			2070.782,
			2073.361,
			2077.246,
			2081.148,
			2083.751,
			2086.359,
			2087.665,
			2088.973,
			2092.908,
			2095.539,
			2098.178,
			2099.5,
			2103.476,
			2104.804,
			2106.135,
			2110.138,
			2112.816,
			2116.847,
			2119.544,
			2122.246,
			2126.313,
			2127.673,
			2129.035,
			2133.131,
			2134.5,
			2135.872,
			2140.001,
			2142.761,
			2146.925,
			2149.711,
			2152.496,
			2153.885,
			2156.633,
			2157.99,
			2160.678,
			2163.319,
			2167.205,
			2169.746,
			2173.487,
			2174.715,
			2175.935,
			2180.723,
			2181.895,
			2185.358,
			2187.619,
			2190.941,
			2195.241,
			2196.291,
			2198.363,
			2199.385,
			2201.405,
			2203.39,
			2206.298,
			2208.188,
			2210.956,
			2211.862,
			2212.759,
			2215.406,
			2217.152,
			2219.694,
			2221.363,
			2223.014,
			2223.831,
			2225.453,
			2227.055,
			2227.85,
			2230.221,
			2231.785,
			2233.338,
			2235.661,
			2237.184,
			2240.216,
			2240.969,
			2243.18,
			2244.65,
			2246.817,
			2248.229,
			2250.347,
			2251.846,
			2252.615,
			2254.15,
			2255.684,
			2256.721,
			2258.934,
			2261.167,
			2264.758,
			2267.067,
			2270.407,
			2271.403,
			2272.298,
			2274.978,
			2276.598,
			2278.739,
			2280.166,
			2282.296,
			2284.418,
			2285.837,
			2287.335,
			2288.09,
			2289.605,
			2291.132,
			2293.495,
			2295.081,
			2298.268,
			2299.069,
			2301.491,
			2303.129,
			2305.607,
			2307.284,
			2308.977,
			2309.829,
			2311.542,
			2312.405,
			2313.272,
			2315.889,
			2316.768,
			2318.52,
			2320.298,
			2322.987,
			2324.794,
			2328.444,
			2329.365,
			2331.214,
			2332.144,
			2334.012,
			2335.893,
			2338.737,
			2340.648,
			2343.537,
			2344.506,
			2345.478,
			2348.413,
			2349.397,
			2350.385,
			2353.366,
			2355.369,
			2357.384,
			2358.395,
			2360.425,
			2362.465,
			2363.489,
			2365.543,
			2366.574,
			2368.644,
			2370.724,
			2373.859,
			2375.949,
			2379.096,
			2380.15,
			2381.206,
			2385.455,
			2386.523,
			2389.744,
			2391.903,
			2394.072,
			2395.16,
			2397.341,
			2400.636,
			2402.82,
			2403.908,
			2406.064,
			2408.18,
			2411.274,
			2413.289,
			2416.24,
			2417.206,
			2418.166,
			2420.989,
			2422.825,
			2425.513,
			2427.264,
			2429.822,
			2432.288,
			2433.881,
			2436.22,
			2437.75,
			2439.253,
			2441.489,
			2442.967,
			2445.901,
			2446.635,
			2448.807,
			2450.246,
			2452.399,
			2453.804,
			2455.205,
			2455.905,
			2457.304,
			2458.062,
			2459.577,
			2461.844,
			2463.661,
			2465.625,
			2468.747,
			2469.871,
			2470.992,
			2474.409,
			2475.56,
			2476.708,
			2479.729,
			2481.667,
			2483.996,
			2485.412,
			2487.516,
			2489.425,
			2490.708,
			2491.98,
			2492.631,
			2494.014,
			2495.396,
			2496.778,
			2499.246,
			2501.925,
			2502.833,
			2503.774,
			2506.593,
			2508.506,
			2511.425,
			2513.354,
			2516.201,
			2518.06,
			2518.934,
			2519.806,
			2522.418,
			2523.34,
			2525.219,
			2527.098,
			2528.819,
			2531.281,
			2532.901,
			2534.329,
			2535.042,
			2536.469,
			2537.183,
			2538.594,
			2540.693,
			2542.094,
			2543.499,
			2545.609,
			2546.314,
			2547.021,
			2549.177,
			2549.903,
			2550.631,
			2552.826,
			2554.336,
			2556.615,
			2558.148,
			2559.704,
			2560.485,
			2562.845,
			2564.435,
			2566.037,
			2568.463,
			2570.1,
			2573.412,
			2574.248,
			2576.774,
			2577.623,
			2578.473,
			2581.042,
			2582.769,
			2584.505,
			2585.377,
			2587.116,
			2588.879,
			2589.763,
			2592.434,
			2594.225,
			2596.026,
			2598.749,
			2600.576,
			2603.339,
			2604.267,
			2605.197,
			2608.007,
			2609.903,
			2612.758,
			2614.655,
			2617.464,
			2619.299,
			2620.204,
			2621.985,
			2623.731,
			2624.592,
			2626.286,
			2627.943,
			2630.363,
			2631.932,
			2634.22,
			2634.966,
			2635.716,
			2637.876,
			2639.275,
			2641.316,
			2642.636,
			2644.564,
			2647.034,
			2647.635,
			2648.826,
			2649.419,
			2650.594,
			2651.76,
			2653.504,
			2654.681,
			2656.45,
			2657.039,
			2657.626,
			2659.385,
			2660.552,
			2662.274,
			2663.402,
			2665.072,
			2666.69,
			2667.734,
			2668.752,
			2669.26,
			2670.26,
			2671.236,
			2672.661,
			2673.592,
			2675.058,
			2675.552,
			2676.043,
			2677.512,
			2678.482,
			2680.034,
			2681.103,
			2682.697,
			2684.305,
			2685.442,
			2687.143,
			2688.278,
			2689.436,
			2690.633,
			2692.427,
			2694.827,
			2695.426,
			2696.618,
			2697.213,
			2698.402,
			2699.59,
			2701.353,
			2702.517,
			2704.831,
			2705.399,
			2707.085,
			2707.643,
			2708.199,
			2709.868,
			2710.97,
			2712.603,
			2713.688,
			2714.776,
			2715.316,
			2716.929,
			2717.999,
			2719.07,
			2720.618,
			2721.638,
			2723.678,
			2724.192,
			2725.695,
			2726.69,
			2728.195,
			2729.21,
			2730.765,
			2732.862,
			2733.392,
			2734.459,
			2734.983,
			2736.038,
			2737.103,
			2738.721,
			2739.801,
			2741.384,
			2742.456,
			2744.093,
			2745.231,
			2746.987,
			2748.179,
			2750.009,
			2751.266,
			2753.181,
			2755.123,
			2756.412,
			2757.7,
			2759.644,
			2760.95,
			2762.935,
			2763.603,
			2764.274,
			2766.301,
			2767.671,
			2769.754,
			2771.149,
			2773.29,
			2774.738,
			2776.941,
			2779.182,
			2780.698,
			2782.224,
			2784.509,
			2786.014,
			2788.958,
			2789.672,
			2791.777,
			2793.141,
			2795.146,
			2796.477,
			2798.446,
			2799.749,
			2800.404,
			2801.711,
			2803.016,
			2803.667,
			2804.967,
			2806.263,
			2808.195,
			2808.836,
			2809.475,
			2811.381,
			2812.012,
			2812.642,
			2814.527,
			2815.768,
			2817,
			2818.843,
			2820.674,
			2822.483,
			2823.086,
			2824.897,
			2825.505,
			2826.115,
			2827.943,
			2829.776,
			2831.057,
			2833.646,
			2834.294,
			2836.296,
			2837.655,
			2839.012,
			2841.088,
			2842.5,
			2845.331,
			2846.082,
			2849.074,
			2849.821,
			2852.2,
			2853.801,
			2856.282,
			2858.009,
			2860.616,
			2863.329,
			2865.137,
			2866.944,
			2869.656,
			2871.408,
			2873.996,
			2874.86,
			2875.686,
			2878.867,
			2879.665,
			2881.946,
			2883.444,
			2884.952,
			2885.698,
			2887.154,
			2888.616,
			2889.35,
			2891.504,
			2892.907,
			2894.317,
			2896.4,
			2897.759,
			2900.5,
			2901.184,
			2903.246,
			2904.632,
			2906.722,
			2908.124,
			2910.247,
			2912.39,
			2913.833,
			2916.033,
			2917.543,
			2919.067,
			2921.407,
			2923.021,
			2925.466,
			2927.128,
			2929.651,
			2931.351,
			2933.906,
			2935.616,
			2938.217,
			2940.847,
			2942.62,
			2944.405,
			2945.303,
			2947.107,
			2948.923,
			2951.67,
			2952.591,
			2953.514,
			2956.302,
			2958.168,
			2960.992,
			2962.863,
			2965.716,
			2967.623,
			2970.504,
			2973.397,
			2975.339,
			2978.274,
			2980.243,
			2982.217,
			2985.169,
			2987.118,
			2989.996,
			2991.877,
			2994.64,
			2996.436,
			2999.074,
			3000.788,
			3002.468,
			3005.723,
			3007.303,
			3008.855,
			3009.622,
			3010.382,
			3012.65,
			3014.167,
			3016.414,
			3019.398,
			3020.141,
			3022.36,
			3023.833,
			3025.297,
			3027.483,
			3028.929,
			3031.801,
			3032.51,
			3034.627,
			3036.031,
			3038.113,
			3039.488,
			3041.545,
			3042.892,
			3044.895,
			3046.895,
			3048.253,
			3049.608,
			3051.642,
			3053.164,
			3055.508,
			3056.29,
			3057.085,
			3059.854,
			3061.698,
			3064.647,
			3066.631,
			3068.644,
			3069.653,
			3071.673,
			3074.552,
			3077.369,
			3078.973,
			3080.578,
			3082.909,
			3084.355,
			3086.531,
			3087.258,
			3087.984,
			3090.165,
			3091.623,
			3093.833,
			3095.315,
			3096.802,
			3097.55,
			3099.057,
			3101.328,
			3103.618,
			3105.156,
			3106.69,
			3109.032,
			3110.609,
			3112.999,
			3113.802,
			3114.608,
			3117.045,
			3118.681,
			3121.141,
			3122.781,
			3125.253,
			3127.695,
			3129.317,
			3130.937,
			3131.745,
			3133.361,
			3134.972,
			3137.384,
			3138.983,
			3141.373,
			3142.167,
			3142.961,
			3145.317,
			3146.883,
			3149.239,
			3150.81,
			3153.172,
			3154.82,
			3155.641,
			3157.281,
			3159.868,
			3161.616,
			3163.385,
			3166.17,
			3167.096,
			3168.02,
			3171.298,
			3172.394,
			3173.485,
			3176.72,
			3178.718,
			3181.179,
			3182.809,
			3185.07,
			3186.545,
			3187.282,
			3188.752,
			3194.056,
			3195.591,
			3197.889,
			3199.402,
			3200.921,
			3201.676,
			3203.921,
			3205.376,
			3206.808,
			3208.922,
			3210.312,
			3212.385,
			3213.762,
			3216.473,
			3217.145,
			3219.137,
			3220.428,
			3222.345,
			3223.615,
			3224.84,
			3225.447,
			3226.657,
			3227.26,
			3228.464,
			3229.681,
			3231.503,
			3232.715,
			3234.667,
			3235.378,
			3236.09,
			3238.224,
			3239.928,
			3242.769,
			3244.787,
			3248.049,
			3250.254,
			3252.481,
			3253.598,
			3256.588,
			3258.566,
			3260.125,
			3262.318,
			3263.792,
			3265.668,
			3266.283,
			3266.901,
			3268.765,
			3270.062,
			3272.04,
			3273.372,
			3275.446,
			3276.863,
			3277.576,
			3279.012,
			3280.48,
			3281.22,
			3282.711,
			3284.217,
			3286.51,
			3288.058,
			3290.441,
			3291.251,
			3292.066,
			3294.534,
			3296.215,
			3298.755,
			3300.433,
			3302.955,
			3304.651,
			3307.214,
			3308.938,
			3309.789,
			3311.543,
			3313.294,
			3315.931,
			3317.697,
			3321.25,
			3322.151,
			3324.866,
			3326.677,
			3329.418,
			3331.271,
			3333.136,
			3335.975,
			3336.935,
			3338.864,
			3341.742,
			3343.664,
			3345.597,
			3347.542,
			3349.498,
			3350.497,
			3353.535,
			3354.552,
			3355.582,
			3358.695,
			3360.782,
			3362.877,
			3366.025,
			3368.121,
			3371.282,
			3372.342,
			3373.403,
			3377.676,
			3378.75,
			3381.988,
			3384.158,
			3386.338,
			3387.433,
			3389.628,
			3391.833,
			3392.94,
			3396.273,
			3398.506,
			3400.75,
			3404.134,
			3406.402,
			3409.824,
			3412.117,
			3416.733,
			3417.894,
			3421.39,
			3423.734,
			3426.088,
			3427.269,
			3429.636,
			3433.223,
			3436.837,
			3439.26,
			3441.683,
			3445.321,
			3447.753,
			3451.418,
			3452.644,
			3453.871,
			3457.557,
			3460.019,
			3463.734,
			3466.225,
			3468.735,
			3469.993,
			3472.521,
			3476.331,
			3478.881,
			3480.16,
			3482.719,
			3485.285,
			3489.157,
			3491.75,
			3495.651,
			3498.256,
			3502.202,
			3504.909,
			3508.882,
			3511.512,
			3514.148,
			3515.469,
			3519.373,
			3521.054
		],
		[
			0,
			0.068,
			0.132,
			0.191,
			0.254,
			0.318,
			0.349,
			0.379,
			0.442,
			0.457,
			0.504,
			0.567,
			0.63,
			0.677,
			0.755,
			0.786,
			0.816,
			0.894,
			0.94,
			1.005,
			1.067,
			1.128,
			1.189,
			1.222,
			1.253,
			1.332,
			1.378,
			1.44,
			1.502,
			1.564,
			1.657,
			1.69,
			1.751,
			1.814,
			1.878,
			1.94,
			2.003,
			2.064,
			2.1,
			2.128,
			2.204,
			2.25,
			2.314,
			2.376,
			2.437,
			2.5,
			2.531,
			2.563,
			2.64,
			2.688,
			2.755,
			2.812,
			2.86,
			2.875,
			2.937,
			2.967,
			3,
			3.063,
			3.078,
			3.123,
			3.187,
			3.249,
			3.295,
			3.31,
			3.515,
			3.561,
			3.622,
			3.687,
			3.749,
			3.811,
			3.84,
			3.873,
			3.936,
			3.95,
			3.997,
			4.06,
			4.123,
			4.185,
			4.278,
			4.31,
			4.372,
			4.434,
			4.498,
			4.557,
			4.605,
			4.622,
			4.684,
			4.715,
			4.745,
			4.824,
			4.869,
			4.933,
			4.994,
			5.056,
			5.121,
			5.151,
			5.183,
			5.246,
			5.308,
			5.37,
			5.432,
			5.495,
			5.557,
			5.588,
			5.62,
			5.682,
			5.697,
			5.743,
			5.806,
			5.868,
			5.93,
			5.993,
			6.023,
			6.055,
			6.119,
			6.134,
			6.179,
			6.246,
			6.306,
			6.368,
			6.462,
			6.49,
			6.555,
			6.571,
			6.617,
			6.68,
			6.74,
			6.803,
			6.898,
			6.929,
			7.005,
			7.054,
			7.117,
			7.178,
			7.24,
			7.333,
			7.365,
			7.427,
			7.49,
			7.557,
			7.616,
			7.678,
			7.77,
			7.802,
			7.865,
			7.879,
			7.927,
			7.99,
			8.051,
			8.113,
			8.206,
			8.239,
			8.321,
			8.363,
			8.426,
			8.488,
			8.551,
			8.644,
			8.674,
			8.737,
			8.752,
			8.799,
			8.86,
			8.924,
			8.986,
			9.049,
			9.079,
			9.111,
			9.173,
			9.236,
			9.298,
			9.36,
			9.424,
			9.518,
			9.548,
			9.627,
			9.673,
			9.736,
			9.798,
			9.86,
			9.952,
			9.984,
			10.048,
			10.11,
			10.173,
			10.234,
			10.297,
			10.357,
			10.42,
			10.498,
			10.546,
			10.607,
			10.67,
			10.733,
			10.797,
			10.827,
			10.858,
			10.935,
			10.981,
			11.045,
			11.106,
			11.171,
			11.263,
			11.294,
			11.355,
			11.371,
			11.418,
			11.481,
			11.544,
			11.589,
			11.606,
			11.667,
			11.731,
			11.808,
			11.856,
			11.919,
			11.981,
			12.044,
			12.104,
			12.167,
			12.246,
			12.29,
			12.355,
			12.416,
			12.478,
			12.539,
			12.603,
			12.665,
			12.68,
			12.728,
			12.791,
			12.853,
			12.914,
			12.978,
			13.04,
			13.118,
			13.165,
			13.227,
			13.29,
			13.352,
			13.414,
			13.477,
			13.555,
			13.601,
			13.662,
			13.724,
			13.787,
			13.851,
			13.913,
			13.991,
			14.038,
			14.1,
			14.161,
			14.225,
			14.286,
			14.347,
			14.428,
			14.472,
			14.54,
			14.597,
			14.643,
			14.661,
			14.722,
			14.753,
			14.785,
			14.862,
			14.91,
			14.972,
			15.035,
			15.08,
			15.096,
			15.159,
			15.19,
			15.222,
			15.3,
			15.345,
			15.409,
			15.472,
			15.516,
			15.533,
			15.597,
			15.656,
			15.735,
			15.783,
			15.844,
			15.905,
			15.969,
			16.033,
			16.064,
			16.095,
			16.173,
			16.218,
			16.281,
			16.343,
			16.39,
			16.406,
			16.47,
			16.532,
			16.613,
			16.656,
			16.719,
			16.78,
			16.828,
			16.904,
			16.936,
			16.968,
			17.046,
			17.094,
			17.156,
			17.217,
			17.263,
			17.28,
			17.342,
			17.404,
			17.481,
			17.528,
			17.593,
			17.654,
			17.7,
			17.778,
			17.842,
			17.918,
			17.966,
			18.029,
			18.089,
			18.138,
			18.153,
			18.215,
			18.246,
			18.278,
			18.355,
			18.403,
			18.465,
			18.527,
			18.575,
			18.653,
			18.687,
			18.713,
			18.793,
			18.838,
			18.902,
			18.962,
			19.011,
			19.026,
			19.089,
			19.151,
			19.229,
			19.276,
			19.337,
			19.4,
			19.463,
			19.523,
			19.587,
			19.666,
			19.712,
			19.773,
			19.836,
			19.882,
			19.9,
			19.961,
			20.024,
			20.102,
			20.149,
			20.21,
			20.274,
			20.321,
			20.399,
			20.428,
			20.46,
			20.523,
			20.586,
			20.648,
			20.71,
			20.773,
			20.835,
			20.898,
			20.976,
			21.022,
			21.086,
			21.147,
			21.191,
			21.207,
			21.27,
			21.333,
			21.412,
			21.457,
			21.522,
			21.583,
			21.629,
			21.709,
			21.771,
			21.848,
			21.895,
			21.956,
			22.02,
			22.082,
			22.144,
			22.175,
			22.206,
			22.269,
			22.284,
			22.33,
			22.398,
			22.456,
			22.503,
			22.581,
			22.641,
			22.719,
			22.768,
			22.834,
			22.893,
			22.938,
			22.955,
			23.018,
			23.049,
			23.079,
			23.156,
			23.204,
			23.265,
			23.33,
			23.375,
			23.454,
			23.485,
			23.517,
			23.594,
			23.64,
			23.704,
			23.766,
			23.811,
			23.828,
			23.89,
			23.952,
			24.031,
			24.077,
			24.14,
			24.202,
			24.247,
			24.327,
			24.389,
			24.466,
			24.514,
			24.577,
			24.639,
			24.686,
			24.7,
			24.763,
			24.795,
			24.826,
			24.889,
			24.906,
			24.95,
			25.013,
			25.072,
			25.137,
			25.199,
			25.231,
			25.262,
			25.339,
			25.387,
			25.448,
			25.512,
			25.559,
			25.572,
			25.635,
			25.668,
			25.698,
			25.776,
			25.822,
			25.884,
			25.948,
			25.995,
			26.011,
			26.073,
			26.102,
			26.135,
			26.213,
			26.257,
			26.322,
			26.385,
			26.431,
			26.51,
			26.571,
			26.648,
			26.696,
			26.757,
			26.822,
			26.867,
			26.882,
			26.945,
			27.006,
			27.086,
			27.131,
			27.196,
			27.257,
			27.319,
			27.381,
			27.445,
			27.505,
			27.523,
			27.569,
			27.632,
			27.694,
			27.757,
			27.819,
			27.849,
			27.882,
			27.959,
			28.005,
			28.068,
			28.13,
			28.177,
			28.255,
			28.286,
			28.318,
			28.381,
			28.396,
			28.443,
			28.505,
			28.567,
			28.63,
			28.692,
			28.724,
			28.754,
			28.832,
			28.879,
			28.942,
			29.004,
			29.055,
			29.129,
			29.191,
			29.268,
			29.317,
			29.379,
			29.44,
			29.488,
			29.502,
			29.566,
			29.628,
			29.705,
			29.752,
			29.816,
			29.878,
			29.939,
			30.003,
			30.033,
			30.064,
			30.143,
			30.189,
			30.253,
			30.314,
			30.377,
			30.439,
			30.471,
			30.501,
			30.578,
			30.626,
			30.688,
			30.75,
			30.796,
			30.873,
			30.907,
			30.936,
			31.015,
			31.063,
			31.129,
			31.187,
			31.232,
			31.25,
			31.312,
			31.372,
			31.453,
			31.498,
			31.56,
			31.623,
			31.686,
			31.747,
			31.778,
			31.808,
			31.872,
			31.934,
			31.998,
			32.057,
			32.122,
			32.185,
			32.217,
			32.248,
			32.324,
			32.371,
			32.435,
			32.497,
			32.543,
			32.558,
			32.619,
			32.652,
			32.682,
			32.745,
			32.76,
			32.809,
			32.871,
			32.933,
			32.994,
			33.087,
			33.119,
			33.182,
			33.2,
			33.245,
			33.307,
			33.37,
			33.43,
			33.525,
			33.555,
			33.619,
			33.634,
			33.68,
			33.744,
			33.806,
			33.868,
			33.931,
			33.993,
			34.07,
			34.117,
			34.18,
			34.24,
			34.288,
			34.303,
			34.366,
			34.43,
			34.507,
			34.555,
			34.617,
			34.679,
			34.741,
			34.804,
			34.867,
			34.945,
			34.991,
			35.054,
			35.115,
			35.177,
			35.241,
			35.303,
			35.364,
			35.38,
			35.426,
			35.491,
			35.552,
			35.615,
			35.677,
			35.74,
			35.816,
			35.863,
			35.927,
			35.988,
			36.035,
			36.051,
			36.113,
			36.144,
			36.174,
			36.252,
			36.3,
			36.363,
			36.423,
			36.485,
			36.581,
			36.61,
			36.673,
			36.735,
			36.798,
			36.859,
			36.906,
			36.922,
			36.986,
			37.046,
			37.125,
			37.17,
			37.237,
			37.296,
			37.358,
			37.452,
			37.484,
			37.546,
			37.61,
			37.671,
			37.734,
			37.797,
			37.856,
			37.921,
			37.998,
			38.046,
			38.108,
			38.169,
			38.232,
			38.326,
			38.357,
			38.436,
			38.482,
			38.544,
			38.606,
			38.668,
			38.761,
			38.794,
			38.872,
			38.918,
			38.98,
			39.043,
			39.106,
			39.198,
			39.229,
			39.292,
			39.306,
			39.355,
			39.422,
			39.479,
			39.542,
			39.605,
			39.665,
			39.744,
			39.789,
			39.856,
			39.915,
			39.978,
			40.072,
			40.103,
			40.18,
			40.229,
			40.29,
			40.352,
			40.415,
			40.478,
			40.54,
			40.618,
			40.665,
			40.728,
			40.788,
			40.835,
			40.852,
			40.914,
			40.945,
			40.976,
			41.054,
			41.102,
			41.163,
			41.223,
			41.289,
			41.35,
			41.411,
			41.494,
			41.538,
			41.601,
			41.662,
			41.71,
			41.725,
			41.787,
			41.849,
			41.927,
			41.973,
			42.035,
			42.099,
			42.145,
			42.16,
			42.224,
			42.253,
			42.284,
			42.363,
			42.411,
			42.473,
			42.534,
			42.596,
			42.688,
			42.722,
			42.783,
			42.8,
			42.845,
			42.908,
			42.969,
			43.032,
			43.094,
			43.156,
			43.235,
			43.283,
			43.344,
			43.406,
			43.469,
			43.565,
			43.595,
			43.673,
			43.718,
			43.781,
			43.843,
			43.906,
			43.969,
			44.029,
			44.107,
			44.154,
			44.219,
			44.279,
			44.343,
			44.404,
			44.466,
			44.546,
			44.593,
			44.655,
			44.716,
			44.764,
			44.779,
			44.84,
			44.903,
			44.981,
			45.029,
			45.091,
			45.154,
			45.2,
			45.276,
			45.34,
			45.418,
			45.465,
			45.528,
			45.589,
			45.64,
			45.651,
			45.713,
			45.746,
			45.777,
			45.84,
			45.855,
			45.902,
			45.964,
			46.027,
			46.088,
			46.181,
			46.213,
			46.291,
			46.336,
			46.4,
			46.462,
			46.508,
			46.523,
			46.587,
			46.619,
			46.649,
			46.727,
			46.772,
			46.836,
			46.898,
			46.946,
			47.022,
			47.086,
			47.164,
			47.212,
			47.272,
			47.336,
			47.398,
			47.461,
			47.492,
			47.523,
			47.6,
			47.648,
			47.712,
			47.771,
			47.818,
			47.834,
			47.898,
			47.927,
			47.958,
			48.036,
			48.084,
			48.146,
			48.207,
			48.256,
			48.334,
			48.396,
			48.474,
			48.52,
			48.584,
			48.644,
			48.69,
			48.706,
			48.769,
			48.8,
			48.832,
			48.907,
			48.956,
			49.02,
			49.083,
			49.144,
			49.238,
			49.268,
			49.332,
			49.346,
			49.394,
			49.457,
			49.517,
			49.581,
			49.643,
			49.674,
			49.704,
			49.786,
			49.831,
			49.89,
			49.954,
			50.001,
			50.08,
			50.111,
			50.139,
			50.22,
			50.266,
			50.328,
			50.389,
			50.454,
			50.516,
			50.548,
			50.577,
			50.64,
			50.655,
			50.703,
			50.764,
			50.828,
			50.874,
			50.951,
			50.984,
			51.014,
			51.091,
			51.138,
			51.202,
			51.263,
			51.31,
			51.326,
			51.386,
			51.419,
			51.449,
			51.529,
			51.576,
			51.638,
			51.698,
			51.761,
			51.857,
			51.887,
			51.948,
			52.011,
			52.074,
			52.136,
			52.199,
			52.26,
			52.291,
			52.324,
			52.4,
			52.448,
			52.512,
			52.571,
			52.618,
			52.634,
			52.696,
			52.729,
			52.758,
			52.823,
			52.884,
			52.946,
			53.01,
			53.071,
			53.166,
			53.197,
			53.257,
			53.274,
			53.32,
			53.384,
			53.444,
			53.49,
			53.506,
			53.571,
			53.602,
			53.634,
			53.712,
			53.757,
			53.821,
			53.883,
			53.945,
			54.006,
			54.039,
			54.07,
			54.132,
			54.147,
			54.194,
			54.256,
			54.319,
			54.381,
			54.444,
			54.473,
			54.505,
			54.568,
			54.584,
			54.631,
			54.694,
			54.755,
			54.801,
			54.88,
			54.912,
			54.943,
			55.021,
			55.067,
			55.129,
			55.192,
			55.255,
			55.348,
			55.38,
			55.441,
			55.457,
			55.505,
			55.57,
			55.629,
			55.691,
			55.754,
			55.784,
			55.817,
			55.878,
			55.939,
			56.007,
			56.066,
			56.113,
			56.128,
			56.19,
			56.221,
			56.252,
			56.33,
			56.378,
			56.44,
			56.501,
			56.565,
			56.657,
			56.689,
			56.751,
			56.813,
			56.881,
			56.939,
			57,
			57.064,
			57.095,
			57.126,
			57.189,
			57.204,
			57.25,
			57.312,
			57.376,
			57.436,
			57.499,
			57.531,
			57.564,
			57.624,
			57.688,
			57.75,
			57.812,
			57.873,
			57.967,
			57.998,
			58.059,
			58.08,
			58.124,
			58.186,
			58.248,
			58.311,
			58.402,
			58.435,
			58.498,
			58.56,
			58.622,
			58.684,
			58.731,
			58.747,
			58.807,
			58.839,
			58.87,
			58.932,
			58.949,
			58.997,
			59.057,
			59.121,
			59.183,
			59.277,
			59.306,
			59.368,
			59.385,
			59.431,
			59.495,
			59.555,
			59.62,
			59.682,
			59.714,
			59.744,
			59.805,
			59.823,
			59.868,
			59.932,
			59.994,
			60.04,
			60.056,
			60.118,
			60.152,
			60.181,
			60.243,
			60.259,
			60.304,
			60.369,
			60.429,
			60.478,
			60.554,
			60.587,
			60.616,
			60.696,
			60.74,
			60.805,
			60.867,
			60.913,
			60.929,
			60.992,
			61.052,
			61.116,
			61.132,
			61.179,
			61.24,
			61.303,
			61.348,
			61.428,
			61.46,
			61.49,
			61.567,
			61.616,
			61.678,
			61.74,
			61.801,
			61.894,
			61.927,
			62.003,
			62.052,
			62.112,
			62.176,
			62.237,
			62.332,
			62.364,
			62.424,
			62.487,
			62.551,
			62.613,
			62.658,
			62.674,
			62.738,
			62.769,
			62.8,
			62.877,
			62.924,
			62.988,
			63.048,
			63.095,
			63.11,
			63.172,
			63.205,
			63.237,
			63.313,
			63.362,
			63.423,
			63.484,
			63.549,
			63.64,
			63.673,
			63.734,
			63.749,
			63.798,
			63.861,
			63.92,
			63.985,
			64.047,
			64.078,
			64.11,
			64.188,
			64.233,
			64.3,
			64.36,
			64.421,
			64.482,
			64.514,
			64.544,
			64.608,
			64.624,
			64.671,
			64.732,
			64.795,
			64.856,
			64.919,
			64.951,
			64.982,
			65.045,
			65.06,
			65.107,
			65.173,
			65.231,
			65.295,
			65.355,
			65.387,
			65.418,
			65.481,
			65.496,
			65.544,
			65.607,
			65.669,
			65.729,
			65.794,
			65.823,
			65.856,
			65.932,
			65.981,
			66.044,
			66.104,
			66.168,
			66.26,
			66.293,
			66.355,
			66.373,
			66.417,
			66.479,
			66.54,
			66.587,
			66.605,
			66.665,
			66.696,
			66.729,
			66.805,
			66.852,
			66.915,
			66.978,
			67.039,
			67.102,
			67.133,
			67.165,
			67.228,
			67.29,
			67.352,
			67.414,
			67.478,
			67.54,
			67.57,
			67.602,
			67.665,
			67.679,
			67.726,
			67.789,
			67.851,
			67.914,
			67.974,
			68.007,
			68.039,
			68.102,
			68.163,
			68.223,
			68.288,
			68.351,
			68.447,
			68.473,
			68.536,
			68.552,
			68.599,
			68.662,
			68.724,
			68.786,
			68.849,
			68.88,
			68.911,
			68.99,
			69.037,
			69.097,
			69.161,
			69.223,
			69.317,
			69.348,
			69.411,
			69.426,
			69.472,
			69.536,
			69.596,
			69.66,
			69.722,
			69.752,
			69.785,
			69.863,
			69.907,
			69.972,
			70.033,
			70.097,
			70.188,
			70.222,
			70.298,
			70.346,
			70.409,
			70.469,
			70.532,
			70.627,
			70.657,
			70.72,
			70.736,
			70.783,
			70.845,
			70.906,
			70.969,
			71.064,
			71.095,
			71.155,
			71.171,
			71.219,
			71.28,
			71.344,
			71.406,
			71.468,
			71.5,
			71.53,
			71.606,
			71.655,
			71.718,
			71.78,
			71.843,
			71.936,
			71.967,
			72.03,
			72.09,
			72.155,
			72.216,
			72.277,
			72.372,
			72.404,
			72.467,
			72.482,
			72.528,
			72.595,
			72.653,
			72.7,
			72.777,
			72.841,
			72.919,
			72.964,
			73.027,
			73.09,
			73.152,
			73.213,
			73.276,
			73.355,
			73.402,
			73.464,
			73.524,
			73.588,
			73.651,
			73.681,
			73.714,
			73.792,
			73.836,
			73.902,
			73.963,
			74.024,
			74.086,
			74.149,
			74.227,
			74.274,
			74.337,
			74.399,
			74.462,
			74.555,
			74.585,
			74.647,
			74.711,
			74.773,
			74.835,
			74.896,
			74.96,
			74.989,
			75.023,
			75.084,
			75.099,
			75.147,
			75.21,
			75.273,
			75.318,
			75.334,
			75.398,
			75.428,
			75.46,
			75.536,
			75.583,
			75.645,
			75.707,
			75.772,
			75.865,
			75.895,
			75.957,
			75.975,
			76.019,
			76.085,
			76.146,
			76.208,
			76.302,
			76.331,
			76.411,
			76.458,
			76.519,
			76.581,
			76.644,
			76.741,
			76.77,
			76.846,
			76.895,
			76.957,
			77.017,
			77.082,
			77.143,
			77.204,
			77.285,
			77.33,
			77.393,
			77.456,
			77.517,
			77.579,
			77.611,
			77.643,
			77.721,
			77.768,
			77.829,
			77.89,
			77.938,
			77.955,
			78.015,
			78.048,
			78.08,
			78.157,
			78.203,
			78.265,
			78.328,
			78.373,
			78.483,
			78.513,
			78.593,
			78.639,
			78.704,
			78.764,
			78.827,
			78.919,
			78.95,
			79.03,
			79.077,
			79.138,
			79.201,
			79.263,
			79.325,
			79.388,
			79.465,
			79.514,
			79.575,
			79.637,
			79.699,
			79.791,
			79.823,
			79.886,
			79.901,
			79.948,
			80.01,
			80.073,
			80.118,
			80.135,
			80.198,
			80.26,
			80.337,
			80.386,
			80.446,
			80.51,
			80.572,
			80.665,
			80.697,
			80.774,
			80.821,
			80.888,
			80.947,
			81.01,
			81.071,
			81.132,
			81.211,
			81.256,
			81.322,
			81.382,
			81.444,
			81.539,
			81.571,
			81.632,
			81.648,
			81.694,
			81.756,
			81.817,
			81.882,
			81.973,
			82.005,
			82.083,
			82.13,
			82.194,
			82.256,
			82.319,
			82.412,
			82.443,
			82.52,
			82.568,
			82.63,
			82.692,
			82.753,
			82.815,
			82.878,
			82.962,
			83.003,
			83.068,
			83.13,
			83.19,
			83.252,
			83.285,
			83.317,
			83.394,
			83.439,
			83.503,
			83.564,
			83.611,
			83.628,
			83.691,
			83.752,
			83.829,
			83.878,
			83.939,
			84.002,
			84.065,
			84.128,
			84.157,
			84.189,
			84.268,
			84.314,
			84.377,
			84.437,
			84.486,
			84.5,
			84.564,
			84.624,
			84.705,
			84.75,
			84.813,
			84.874,
			84.936,
			85,
			85.035,
			85.062,
			85.141,
			85.187,
			85.253,
			85.31,
			85.373,
			85.467,
			85.499,
			85.562,
			85.576,
			85.623,
			85.685,
			85.749,
			85.796,
			85.872,
			85.903,
			85.936,
			86.013,
			86.06,
			86.124,
			86.186,
			86.248,
			86.31,
			86.342,
			86.372,
			86.45,
			86.498,
			86.557,
			86.621,
			86.668,
			86.684,
			86.747,
			86.778,
			86.81,
			86.886,
			86.933,
			86.997,
			87.057,
			87.122,
			87.184,
			87.215,
			87.246,
			87.434,
			87.493,
			87.557,
			87.619,
			87.65,
			87.682,
			87.758,
			87.805,
			87.869,
			87.93,
			87.979,
			88.055,
			88.118,
			88.197,
			88.244,
			88.306,
			88.368,
			88.429,
			88.493,
			88.523,
			88.555,
			88.617,
			88.633,
			88.678,
			88.743,
			88.805,
			88.866,
			88.93,
			88.96,
			88.99,
			89.055,
			89.116,
			89.181,
			89.239,
			89.303,
			89.366,
			89.397,
			89.428,
			89.506,
			89.552,
			89.614,
			89.677,
			89.723,
			89.802,
			89.833,
			89.865,
			89.927,
			89.988,
			90.055,
			90.113,
			90.177,
			90.237,
			90.27,
			90.3,
			90.363,
			90.379,
			90.423,
			90.487,
			90.548,
			90.598,
			90.674,
			90.707,
			90.738,
			90.815,
			90.861,
			90.924,
			90.986,
			91.048,
			91.111,
			91.172,
			91.235,
			91.256,
			91.298,
			91.36,
			91.424,
			91.484,
			91.578,
			91.61,
			91.672,
			91.733,
			91.798,
			91.856,
			91.92,
			91.983,
			92.015,
			92.046,
			92.123,
			92.171,
			92.232,
			92.295,
			92.34,
			92.357,
			92.421,
			92.452,
			92.483,
			92.56,
			92.608,
			92.67,
			92.731,
			92.794,
			92.857,
			92.888,
			92.918,
			92.998,
			93.044,
			93.107,
			93.169,
			93.215,
			93.23,
			93.294,
			93.329,
			93.356,
			93.433,
			93.481,
			93.544,
			93.606,
			93.652,
			93.73,
			93.791,
			93.871,
			93.917,
			93.981,
			94.04,
			94.088,
			94.104,
			94.165,
			94.23,
			94.306,
			94.355,
			94.416,
			94.477,
			94.526,
			94.603,
			94.634,
			94.665,
			94.744,
			94.789,
			94.854,
			94.915,
			94.963,
			94.978,
			95.04,
			95.102,
			95.164,
			95.179,
			95.228,
			95.291,
			95.351,
			95.413,
			95.476,
			95.539,
			95.618,
			95.664,
			95.728,
			95.788,
			95.835,
			95.852,
			95.944,
			95.969
		]
	],
	'7272_8311_7982': [
		[
			93.139
		],
		[
			0
		],
		[
			0,
			2.377,
			3.702,
			6.356,
			10.349,
			14.357,
			17.038,
			19.727,
			23.773,
			25.124,
			26.478,
			30.547,
			31.907,
			33.269,
			37.365,
			40.104,
			44.226,
			46.983,
			51.131,
			53.904,
			58.077,
			62.265,
			65.066,
			67.874,
			70.688,
			74.924,
			80.594,
			82.016,
			86.297,
			89.16,
			93.465,
			96.338,
			99.218,
			100.661,
			103.553,
			105.005,
			107.914,
			110.829,
			112.289,
			115.207,
			118.129,
			122.521,
			125.469,
			129.903,
			132.865,
			137.294,
			140.25,
			143.211,
			147.657,
			150.626,
			156.578,
			158.068,
			164.039,
			165.533,
			170.022,
			173.02,
			177.537,
			180.57,
			183.616,
			185.149,
			189.763,
			192.855,
			195.966,
			200.679,
			203.854,
			208.641,
			210.219,
			211.799,
			216.55,
			219.731,
			224.51,
			227.676,
			232.41,
			235.565,
			240.283,
			244.951,
			248.023,
			251.052,
			255.517,
			258.44,
			262.749,
			265.578,
			269.752,
			272.488,
			276.524,
			279.169,
			283.067,
			285.618,
			289.372,
			293.034,
			295.433,
			297.799,
			301.288,
			303.572,
			306.921,
			309.107,
			312.321,
			314.419,
			317.501,
			319.512,
			321.492,
			322.469,
			324.397,
			327.249,
			330.01,
			331.813,
			333.586,
			336.186,
			337.879,
			340.362,
			341.984,
			344.372,
			345.931,
			348.24,
			349.764,
			352.025,
			353.531,
			354.286,
			355.776,
			358.014,
			359.509,
			360.98,
			363.192,
			363.931,
			364.672,
			366.894,
			367.636,
			368.378,
			370.705,
			372.313,
			374.752,
			376.522,
			378.292,
			381.015,
			382.866,
			386.615,
			387.561,
			390.423,
			392.369,
			394.312,
			395.319,
			397.36,
			400.427,
			403.515,
			405.472,
			407.304,
			410.013,
			411.619,
			414.036,
			414.838,
			415.608,
			418.7,
			419.476,
			421.797,
			423.351,
			425.708,
			427.299,
			429.702,
			432.176,
			433.841,
			435.526,
			438.097,
			439.826,
			442.447,
			444.209,
			446.884,
			448.683,
			451.426,
			453.281,
			455.149,
			456.092,
			457.985,
			459.872,
			460.824,
			463.683,
			465.586,
			467.486,
			470.291,
			472.134,
			474.832,
			475.7,
			476.554,
			479.062,
			480.685,
			483.04,
			484.567,
			486.807,
			488.259,
			488.973,
			490.382,
			492.458,
			493.841,
			495.208,
			497.246,
			498.594,
			500.6,
			501.919,
			503.881,
			505.18,
			507.09,
			508.354,
			510.24,
			511.463,
			512.071,
			513.285,
			515.103,
			516.272,
			517.431,
			519.168,
			520.329,
			522.667,
			523.251,
			525.011,
			526.408,
			527.857,
			530.03,
			532.749,
			534.599,
			535.592,
			536.745,
			541.327,
			543.573,
			545.689,
			548.235,
			549.086,
			549.938,
			551.398,
			552.797,
			553.499,
			554.908,
			555.599,
			556.945,
			558.977,
			560.342,
			561.728,
			563.822,
			564.526,
			565.232,
			568.116,
			568.845,
			571.054,
			572.549,
			574.817,
			576.352,
			577.905,
			578.687,
			581.058,
			582.662,
			584.28,
			586.721,
			588.374,
			590.88,
			591.721,
			592.565,
			595.096,
			596.789,
			599.356,
			601.083,
			602.823,
			603.698,
			607.246,
			608.137,
			609.93,
			610.832,
			612.643,
			614.467,
			617.223,
			619.074,
			621.87,
			622.806,
			623.746,
			626.586,
			628.496,
			631.402,
			633.384,
			635.375,
			636.402,
			638.476,
			639.514,
			641.656,
			644.984,
			647.299,
			649.654,
			653.213,
			655.589,
			660.056,
			661.153,
			664.332,
			666.463,
			669.681,
			671.801,
			674.936,
			678.01,
			680.048,
			682.081,
			683.094,
			685.128,
			687.167,
			690.238,
			692.307,
			695.426,
			696.466,
			697.509,
			700.654,
			702.747,
			705.93,
			708.081,
			710.224,
			711.299,
			713.457,
			716.711,
			719.987,
			722.182,
			724.382,
			727.704,
			729.932,
			733.291,
			734.416,
			735.543,
			738.938,
			741.213,
			744.651,
			746.953,
			750.415,
			752.736,
			753.901,
			755.068,
			758.59,
			759.77,
			760.952,
			764.511,
			766.876,
			769.291,
			770.493,
			772.882,
			776.511,
			780.145,
			782.574,
			785.003,
			788.673,
			791.137,
			794.887,
			796.143,
			797.402,
			801.191,
			802.455,
			803.718,
			807.518,
			810.058,
			813.881,
			816.438,
			819.001,
			820.286,
			824.154,
			826.744,
			829.338,
			833.23,
			835.831,
			839.747,
			841.056,
			842.367,
			846.312,
			848.951,
			852.923,
			855.581,
			858.246,
			859.581,
			862.257,
			864.941,
			866.285,
			870.33,
			873.036,
			875.75,
			879.835,
			882.567,
			886.678,
			888.052,
			889.427,
			893.566,
			896.334,
			900.498,
			903.283,
			906.075,
			907.473,
			910.275,
			914.49,
			918.719,
			921.546,
			924.375,
			928.605,
			931.403,
			935.547,
			936.91,
			938.264,
			942.269,
			944.891,
			948.757,
			951.293,
			953.795,
			955.032,
			957.479,
			958.69,
			961.085,
			964.614,
			966.923,
			969.195,
			972.532,
			973.626,
			974.71,
			977.908,
			979.993,
			983.051,
			985.042,
			989.858,
			992.639,
			994.45,
			995.344,
			997.105,
			998.831,
			999.681,
			1000.521,
			1002.987,
			1005.375,
			1006.153,
			1006.922,
			1009.176,
			1009.91,
			1010.635,
			1012.058,
			1012.757,
			1014.143,
			1016.154,
			1017.468,
			1018.761,
			1020.657,
			1022.519,
			1023.742,
			1024.344,
			1024.944,
			1026.734,
			1027.922,
			1029.659,
			1031.369,
			1031.938,
			1032.506,
			1033.636,
			1034.183,
			1035.273,
			1036.362,
			1037.998,
			1039.177,
			1041.654,
			1042.275,
			1044.377,
			1045.224,
			1046.072,
			1048.661,
			1050.949,
			1054.383,
			1058.805,
			1059.536,
			1060.995,
			1061.724,
			1063.184,
			1064.478,
			1066.34,
			1067.584,
			1069.469,
			1070.737,
			1072.647,
			1073.933,
			1075.892,
			1077.195,
			1079.19,
			1081.224,
			1082.589,
			1084.634,
			1085.99,
			1087.339,
			1089.355,
			1090.709,
			1093.373,
			1094.046,
			1096.096,
			1097.452,
			1099.515,
			1100.932,
			1103.043,
			1104.463,
			1105.18,
			1106.611,
			1108.75,
			1110.206,
			1111.658,
			1113.835,
			1115.327,
			1118.309,
			1119.052,
			1121.256,
			1122.728,
			1124.912,
			1126.35,
			1128.507,
			1129.961,
			1130.689,
			1132.142,
			1134.322,
			1135.771,
			1137.218,
			1139.377,
			1140.791,
			1142.907,
			1143.613,
			1144.323,
			1146.459,
			1147.88,
			1150.047,
			1151.511,
			1153.71,
			1155.944,
			1156.691,
			1158.935,
			1159.701,
			1161.239,
			1162.778,
			1164.313,
			1166.527,
			1168.003,
			1170.167,
			1172.272,
			1173.681,
			1175.805,
			1177.226,
			1178.653,
			1181.542,
			1182.27,
			1184.463,
			1185.196,
			1185.93,
			1188.146,
			1189.634,
			1191.871,
			1193.375,
			1195.651,
			1197.95,
			1199.502,
			1201.074,
			1203.454,
			1205.059,
			1207.492,
			1208.307,
			1209.126,
			1211.612,
			1212.448,
			1213.286,
			1215.823,
			1217.53,
			1220.103,
			1221.819,
			1223.538,
			1224.4,
			1226.959,
			1228.665,
			1230.368,
			1232.897,
			1234.587,
			1237.099,
			1238.773,
			1241.27,
			1242.926,
			1245.413,
			1247.076,
			1249.566,
			1251.229,
			1253.726,
			1255.39,
			1256.219,
			1257.878,
			1259.54,
			1262.018,
			1263.671,
			1266.982,
			1267.811,
			1270.315,
			1272.027,
			1274.61,
			1276.375,
			1279.055,
			1281.757,
			1283.568,
			1285.384,
			1286.292,
			1288.11,
			1289.93,
			1292.657,
			1294.475,
			1297.193,
			1298.097,
			1299.001,
			1301.698,
			1303.485,
			1306.16,
			1307.932,
			1310.592,
			1313.255,
			1315.034,
			1316.819,
			1317.713,
			1319.503,
			1321.313,
			1324.068,
			1325.924,
			1328.827,
			1330.773,
			1333.882,
			1335.955,
			1339.056,
			1341.095,
			1343.944,
			1346.778,
			1348.641,
			1351.463,
			1353.38,
			1355.306,
			1357.249,
			1360.183,
			1364.146,
			1365.144,
			1368.159,
			1370.18,
			1372.209,
			1375.263,
			1377.307,
			1381.416,
			1382.445,
			1386.583,
			1387.619,
			1390.729,
			1392.803,
			1395.931,
			1400.154,
			1401.217,
			1404.434,
			1406.594,
			1408.77,
			1412.051,
			1414.251,
			1417.561,
			1419.774,
			1424.187,
			1425.292,
			1428.618,
			1430.847,
			1434.213,
			1438.725,
			1439.856,
			1443.249,
			1445.515,
			1447.792,
			1451.224,
			1453.55,
			1457.058,
			1458.231,
			1459.406,
			1462.956,
			1465.342,
			1468.974,
			1471.417,
			1475.106,
			1477.58,
			1481.314,
			1485.07,
			1487.575,
			1490.072,
			1493.807,
			1496.318,
			1501.371,
			1502.633,
			1506.421,
			1508.953,
			1512.771,
			1515.336,
			1519.2,
			1523.079,
			1525.666,
			1529.554,
			1532.154,
			1534.759,
			1538.68,
			1541.303,
			1546.571,
			1547.893,
			1551.868,
			1554.527,
			1558.53,
			1561.208,
			1563.895,
			1565.241,
			1567.94,
			1572.003,
			1576.085,
			1578.817,
			1581.557,
			1585.683,
			1588.444,
			1593.991,
			1595.382,
			1599.566,
			1602.364,
			1606.576,
			1609.392,
			1613.63,
			1616.464,
			1620.727,
			1625.006,
			1626.435,
			1630.738,
			1635.048,
			1637.916,
			1640.763,
			1643.585,
			1644.985,
			1649.131,
			1651.848,
			1654.526,
			1658.473,
			1661.057,
			1664.861,
			1667.349,
			1672.215,
			1673.409,
			1676.937,
			1679.247,
			1682.644,
			1684.862,
			1688.119,
			1691.291,
			1693.358,
			1695.389,
			1698.365,
			1700.304,
			1703.142,
			1704.07,
			1704.989,
			1707.696,
			1708.581,
			1709.458,
			1712.036,
			1713.709,
			1716.151,
			1719.28,
			1720.04,
			1722.266,
			1723.704,
			1725.108,
			1727.151,
			1728.472,
			1730.393,
			1731.017,
			1731.641,
			1733.431,
			1734.578,
			1736.224,
			1737.28,
			1738.306,
			1738.809,
			1739.779,
			1741.179,
			1742.538,
			1743.428,
			1744.303,
			1745.571,
			1746.41,
			1748.083,
			1748.502,
			1749.777,
			1750.637,
			1751.927,
			1752.79,
			1754.092,
			1755.477,
			1756.404,
			1757.798,
			1758.731,
			1759.689,
			1761.153,
			1762.125,
			1763.583,
			1764.566,
			1766.055,
			1767.038,
			1768.508,
			1769.499,
			1771.02,
			1772.526,
			1773.527,
			1775.112,
			1776.214,
			1777.307,
			1778.938,
			1780.125,
			1782.562,
			1783.167,
			1785.062,
			1786.412,
			1788.432,
			1789.806,
			1791.937,
			1793.354,
			1795.477,
			1797.589,
			1798.998,
			1800.353,
			1801.683,
			1803.015,
			1803.684,
			1805.589,
			1806.815,
			1808.662,
			1809.838,
			1811.436,
			1812.507,
			1813.588,
			1815.636,
			1816.128,
			1817.618,
			1818.119,
			1818.624,
			1820.178,
			1821.252,
			1822.337,
			1822.884,
			1823.989,
			1825.724,
			1827.519,
			1828.735,
			1830.595,
			1831.855,
			1833.132,
			1835.082,
			1836.412,
			1838.436,
			1839.807,
			1841.907,
			1843.327,
			1845.488,
			1848.428,
			1849.173,
			1850.662,
			1851.418,
			1852.942,
			1854.482,
			1856.819,
			1858.396,
			1861.594,
			1862.402,
			1865.673,
			1866.5,
			1869,
			1870.682,
			1872.365,
			1873.207,
			1874.896,
			1876.6,
			1877.456,
			1880.046,
			1881.784,
			1883.535,
			1886.188,
			1887.973,
			1890.673,
			1892.484,
			1895.224,
			1897.066,
			1899.853,
			1901.738,
			1903.637,
			1904.592,
			1906.512,
			1909.417,
			1912.347,
			1914.315,
			1916.296,
			1919.288,
			1921.295,
			1925.34,
			1926.355,
			1929.413,
			1931.468,
			1934.577,
			1936.667,
			1939.81,
			1941.909,
			1945.074,
			1947.198,
			1948.264,
			1950.403,
			1952.558,
			1955.81,
			1957.99,
			1961.279,
			1963.488,
			1966.821,
			1969.057,
			1972.422,
			1974.673,
			1978.063,
			1981.474,
			1983.761,
			1987.208,
			1988.362,
			1991.838,
			1994.167,
			1997.677,
			2000.03,
			2002.393,
			2003.578,
			2007.146,
			2009.536,
			2011.936,
			2015.553,
			2017.977,
			2021.63,
			2024.075,
			2028.992,
			2030.227,
			2033.945,
			2036.434,
			2038.933,
			2040.185,
			2042.697,
			2046.48,
			2050.282,
			2052.826,
			2055.379,
			2059.224,
			2061.797,
			2065.67,
			2066.965,
			2068.263,
			2072.166,
			2074.77,
			2078.685,
			2081.304,
			2085.245,
			2087.881,
			2091.848,
			2095.83,
			2098.494,
			2101.165,
			2105.185,
			2107.874,
			2111.921,
			2114.627,
			2118.701,
			2121.425,
			2125.527,
			2128.27,
			2132.396,
			2137.915,
			2139.295,
			2142.048,
			2143.419,
			2146.143,
			2148.839,
			2152.817,
			2155.423,
			2159.266,
			2161.784,
			2165.504,
			2167.938,
			2171.52,
			2173.857,
			2177.29,
			2179.53,
			2182.821,
			2186.028,
			2188.12,
			2190.177,
			2193.193,
			2195.156,
			2198.97,
			2199.899,
			2202.635,
			2204.417,
			2207.027,
			2208.724,
			2210.386,
			2211.218,
			2212.827,
			2213.62,
			2215.184,
			2217.484,
			2218.99,
			2220.478,
			2222.693,
			2223.425,
			2224.155,
			2226.343,
			2227.073,
			2227.803,
			2229.968,
			2231.411,
			2233.569,
			2234.988,
			2236.41,
			2238.529,
			2240.595,
			2242.66,
			2243.335,
			2245.315,
			2247.29,
			2247.948,
			2248.755,
			2250.419,
			2252.913,
			2257.025,
			2259.589,
			2263.175,
			2264.731,
			2266.288,
			2268.568,
			2269.253,
			2269.939,
			2272.689,
			2273.387,
			2275.544,
			2276.988,
			2278.444,
			2279.198,
			2280.71,
			2282.99,
			2285.314,
			2286.876,
			2288.449,
			2290.861,
			2292.48,
			2294.952,
			2296.622,
			2299.154,
			2300.865,
			2303.455,
			2305.208,
			2307.859,
			2311.446,
			2312.35,
			2315.057,
			2316.882,
			2318.719,
			2321.49,
			2323.352,
			2326.169,
			2328.062,
			2330.924,
			2332.848,
			2335.757,
			2337.711,
			2339.677,
			2340.665,
			2343.646,
			2345.647,
			2348.671,
			2350.701,
			2352.741,
			2355.821,
			2357.888,
			2362.051,
			2363.097,
			2366.234,
			2368.33,
			2371.494,
			2373.615,
			2376.813,
			2380.033,
			2382.192,
			2385.449,
			2387.632,
			2389.825,
			2393.137,
			2395.359,
			2398.711,
			2400.955,
			2404.336,
			2406.599,
			2410.011,
			2412.298,
			2414.595,
			2415.747,
			2418.059,
			2421.544,
			2425.052,
			2427.402,
			2429.761,
			2433.317,
			2435.699,
			2440.49,
			2441.693,
			2445.317,
			2447.743,
			2451.399,
			2453.848,
			2456.308,
			2460.015,
			2463.74,
			2467.494,
			2470.01,
			2472.564,
			2475.124,
			2478.96,
			2484.042,
			2485.313,
			2489.133,
			2491.689,
			2494.253,
			2498.114,
			2500.697,
			2504.587,
			2507.188,
			2512.393,
			2513.698,
			2517.621,
			2520.244,
			2524.192,
			2526.832,
			2530.802,
			2534.788,
			2537.452,
			2540.117,
			2544.095,
			2546.726,
			2551.894,
			2553.163,
			2558.143,
			2559.364,
			2562.973,
			2565.334,
			2568.809,
			2573.307,
			2574.407,
			2576.579,
			2579.765,
			2581.841,
			2584.884,
			2586.865,
			2589.77,
			2590.719,
			2591.663,
			2594.455,
			2596.272,
			2599.059,
			2600.87,
			2603.527,
			2605.25,
			2607.695,
			2610.006,
			2611.499,
			2612.93,
			2615.009,
			2616.352,
			2618.3,
			2619.557,
			2621.375,
			2622.537,
			2624.212,
			2625.295,
			2626.342,
			2626.853,
			2627.855,
			2629.322,
			2630.758,
			2631.703,
			2632.623,
			2633.996,
			2634.909,
			2636.278,
			2637.175,
			2638.493,
			2639.365,
			2640.669,
			2641.536,
			2642.858,
			2643.732,
			2645.034,
			2646.333,
			2647.251,
			2648.207,
			2649.633,
			2650.585,
			2652.06,
			2653.195,
			2654.895,
			2656.03,
			2657.889,
			2659.206,
			2661.181,
			2663.296,
			2664.744,
			2666.915,
			2667.677,
			2669.948,
			2672.227,
			2672.994,
			2673.758,
			2675.273,
			2677.56,
			2679.901,
			2681.463,
			2683.094,
			2685.563,
			2688.006,
			2689.615,
			2692.018,
			2694.188,
			2694.908,
			2697.062,
			2698.26,
			2699.451,
			2700.046,
			2701.235,
			2702.383,
			2702.905,
			2704.458,
			2705.491,
			2706.526,
			2708.054,
			2709.059,
			2710.571,
			2711.077,
			2711.585,
			2713.622,
			2714.133,
			2715.673,
			2716.707,
			2718.271,
			2719.322,
			2720.38,
			2720.913,
			2721.984,
			2722.526,
			2723.622,
			2724.727,
			2726.406,
			2727.542,
			2729.864,
			2730.453,
			2732.244,
			2733.451,
			2735.294,
			2736.545,
			2738.435,
			2739.72,
			2741.022,
			2741.679,
			2743.003,
			2743.671,
			2745.019,
			2746.385,
			2748.464,
			2749.873,
			2752.018,
			2752.742,
			2753.47,
			2755.676,
			2757.155,
			2759.379,
			2760.865,
			2763.062,
			2764.527,
			2765.259,
			2766.722,
			2768.185,
			2768.916,
			2770.376,
			2771.834,
			2774.007,
			2775.447,
			2777.604,
			2778.309,
			2779.012,
			2781.114,
			2782.512,
			2784.583,
			2785.957,
			2787.328,
			2788.016,
			2789.393,
			2790.08,
			2791.449,
			2793.51,
			2794.895,
			2796.273,
			2798.346,
			2799.744,
			2802.518,
			2803.218,
			2805.335,
			2806.734,
			2808.859,
			2810.306,
			2812.463,
			2814.701,
			2816.204,
			2817.704,
			2818.482,
			2820.085,
			2821.682,
			2824.193,
			2825.983,
			2827.768,
			2829.692,
			2830.673,
			2833.562,
			2835.282,
			2837.004,
			2839.452,
			2840.99,
			2844.05,
			2845.563,
			2847.075,
			2847.831,
			2848.586,
			2850.814,
			2852.295,
			2854.471,
			2855.881,
			2857.296,
			2858.007,
			2859.404,
			2860.093,
			2861.475,
			2863.556,
			2864.895,
			2866.233,
			2868.251,
			2868.921,
			2869.58,
			2871.566,
			2872.898,
			2874.929,
			2876.3,
			2878.374,
			2879.785,
			2881.211,
			2881.929,
			2884.1,
			2885.57,
			2887.052,
			2889.298,
			2890.796,
			2893.07,
			2893.835,
			2894.608,
			2896.949,
			2898.528,
			2900.97,
			2902.625,
			2904.296,
			2905.144,
			2906.837,
			2907.683,
			2909.38,
			2911.071,
			2911.921,
			2913.631,
			2915.352,
			2917.957,
			2919.716,
			2923.3,
			2924.205,
			2926.933,
			2928.768,
			2931.516,
			2933.371,
			2936.167,
			2938.044,
			2938.986,
			2940.873,
			2942.77,
			2943.724,
			2945.639,
			2947.565,
			2950.482,
			2952.443,
			2956.363,
			2957.339,
			2960.239,
			2962.141,
			2964.93,
			2966.747,
			2969.404,
			2971.136,
			2971.989,
			2973.667,
			2975.312,
			2976.123,
			2977.722,
			2979.304,
			2981.603,
			2983.127,
			2985.413,
			2986.169,
			2986.923,
			2989.17,
			2990.666,
			2992.907,
			2994.398,
			2996.63,
			2998.114,
			3000.334,
			3002.532,
			3003.264,
			3005.45,
			3007.609,
			3009.044,
			3011.909,
			3012.623,
			3014.761,
			3016.247,
			3018.488,
			3019.979,
			3021.661,
			3024.194,
			3025.038,
			3026.024,
			3029.032,
			3030.031,
			3031.13,
			3034.494,
			3036.883,
			3040.596,
			3045.224,
			3046.097,
			3047.718,
			3048.526,
			3050.139,
			3051.555,
			3053.578,
			3054.921,
			3056.939,
			3057.61,
			3058.281,
			3060.972,
			3061.663,
			3063.736,
			3065.12,
			3067.225,
			3070.048,
			3070.756,
			3072.895,
			3074.313,
			3075.75,
			3077.926,
			3079.392,
			3082.362,
			3083.113,
			3085.385,
			3086.918,
			3089.243,
			3090.81,
			3093.185,
			3096.388,
			3097.189,
			3098.792,
			3099.594,
			3101.201,
			3102.804,
			3105.209,
			3106.811,
			3109.206,
			3110.802,
			3113.213,
			3114.82,
			3117.269,
			3118.931,
			3121.431,
			3124.856,
			3125.712,
			3128.33,
			3130.08,
			3131.839,
			3134.503,
			3136.277,
			3138.978,
			3139.877,
			3140.775,
			3143.497,
			3145.314,
			3148.02,
			3149.812,
			3152.459,
			3155.032,
			3156.739,
			3159.216,
			3160.863,
			3162.502,
			3164.925,
			3166.527,
			3169.631,
			3170.386,
			3172.603,
			3174.028,
			3176.124,
			3177.498,
			3178.855,
			3180.879,
			3182.871,
			3184.828,
			3186.126,
			3187.411,
			3189.301,
			3190.555,
			3191.809,
			3193.692,
			3195.569,
			3196.822,
			3198.146,
			3200.16,
			3201.504,
			3203.661,
			3205.136,
			3208.223,
			3209.064,
			3211.585,
			3213.367,
			3216.132,
			3217.961,
			3220.665,
			3223.23,
			3224.783,
			3226.339,
			3228.498,
			3229.836,
			3231.854,
			3233.189,
			3235.841,
			3236.509,
			3238.537,
			3239.909,
			3241.291,
			3241.985,
			3243.398,
			3244.835,
			3245.558,
			3247.751,
			3249.249,
			3250.762,
			3253.064,
			3254.63,
			3257.005,
			3258.613,
			3261.059,
			3262.707,
			3265.199,
			3266.87,
			3269.356,
			3271.016,
			3273.526,
			3276.049,
			3277.747,
			3279.478,
			3282.105,
			3283.866,
			3286.568,
			3288.383,
			3291.114,
			3292.95,
			3295.717,
			3297.575,
			3299.445,
			3300.384,
			3302.271,
			3305.125,
			3308.003,
			3309.936,
			3311.881,
			3314.822,
			3316.799,
			3319.796,
			3321.817,
			3324.881,
			3326.947,
			3330.062,
			3332.148,
			3334.233,
			3335.273,
			3337.357,
			3340.509,
			3343.69,
			3345.834,
			3347.989,
			3351.241,
			3353.421,
			3356.705,
			3357.804,
			3358.906,
			3362.223,
			3364.447,
			3367.801,
			3370.049,
			3372.307,
			3375.713,
			3376.854,
			3379.142,
			3382.59,
			3383.744,
			3387.222,
			3389.552,
			3391.893,
			3393.066,
			3395.422,
			3398.979,
			3402.563,
			3404.966,
			3407.397,
			3411.059,
			3413.509,
			3417.181,
			3418.404,
			3419.629,
			3423.315,
			3424.548,
			3425.784,
			3429.505,
			3432,
			3434.504,
			3435.759,
			3438.273,
			3442.063,
			3445.876,
			3448.431,
			3450.994,
			3454.847,
			3457.423,
			3461.301,
			3463.906,
			3467.813,
			3470.41,
			3474.314,
			3476.925,
			3479.511,
			3480.8,
			3483.402,
			3485.735
		],
		[
			0,
			0.045,
			0.061,
			0.123,
			0.186,
			0.264,
			0.31,
			0.373,
			0.435,
			0.481,
			0.498,
			0.56,
			0.595,
			0.623,
			0.699,
			0.747,
			0.81,
			0.872,
			0.933,
			0.997,
			1.059,
			1.136,
			1.182,
			1.247,
			1.307,
			1.37,
			1.463,
			1.496,
			1.574,
			1.621,
			1.684,
			1.744,
			1.792,
			1.808,
			1.87,
			1.901,
			1.932,
			1.995,
			2.01,
			2.057,
			2.12,
			2.182,
			2.245,
			2.307,
			2.369,
			2.448,
			2.493,
			2.556,
			2.619,
			2.681,
			2.774,
			2.806,
			2.883,
			2.93,
			2.992,
			3.055,
			3.117,
			3.179,
			3.211,
			3.243,
			3.319,
			3.366,
			3.428,
			3.491,
			3.554,
			3.616,
			3.646,
			3.679,
			3.755,
			3.803,
			3.867,
			3.928,
			3.989,
			4.051,
			4.115,
			4.192,
			4.238,
			4.302,
			4.363,
			4.426,
			4.488,
			4.549,
			4.612,
			4.673,
			4.74,
			4.8,
			4.861,
			4.923,
			4.987,
			5.063,
			5.111,
			5.174,
			5.236,
			5.298,
			5.361,
			5.422,
			5.5,
			5.548,
			5.611,
			5.672,
			5.719,
			5.734,
			5.797,
			5.861,
			5.938,
			5.985,
			6.047,
			6.111,
			6.172,
			6.234,
			6.297,
			6.374,
			6.421,
			6.484,
			6.547,
			6.607,
			6.67,
			6.703,
			6.733,
			6.815,
			6.857,
			6.92,
			6.984,
			7.029,
			7.045,
			7.108,
			7.139,
			7.169,
			7.247,
			7.294,
			7.357,
			7.42,
			7.466,
			7.543,
			7.605,
			7.684,
			7.732,
			7.794,
			7.856,
			7.902,
			7.919,
			7.979,
			8.041,
			8.121,
			8.166,
			8.231,
			8.291,
			8.339,
			8.416,
			8.447,
			8.479,
			8.558,
			8.603,
			8.667,
			8.728,
			8.791,
			8.854,
			8.916,
			8.993,
			9.039,
			9.101,
			9.166,
			9.211,
			9.29,
			9.352,
			9.428,
			9.477,
			9.538,
			9.6,
			9.647,
			9.664,
			9.724,
			9.757,
			9.788,
			9.867,
			9.913,
			9.975,
			10.038,
			10.101,
			10.162,
			10.194,
			10.225,
			10.301,
			10.35,
			10.412,
			10.474,
			10.537,
			10.598,
			10.631,
			10.661,
			10.739,
			10.785,
			10.848,
			10.911,
			10.972,
			11.036,
			11.096,
			11.174,
			11.222,
			11.285,
			11.348,
			11.41,
			11.473,
			11.502,
			11.534,
			11.613,
			11.659,
			11.721,
			11.782,
			11.844,
			11.938,
			11.971,
			12.047,
			12.095,
			12.156,
			12.219,
			12.282,
			12.345,
			12.374,
			12.405,
			12.484,
			12.532,
			12.594,
			12.657,
			12.702,
			12.72,
			12.781,
			12.812,
			12.845,
			12.906,
			12.921,
			12.967,
			13.034,
			13.093,
			13.141,
			13.219,
			13.248,
			13.28,
			13.358,
			13.404,
			13.468,
			13.529,
			13.591,
			13.655,
			13.686,
			13.717,
			13.794,
			13.84,
			13.905,
			13.965,
			14.028,
			14.091,
			14.122,
			14.153,
			14.214,
			14.278,
			14.341,
			14.403,
			14.451,
			14.466,
			14.558,
			14.59,
			14.653,
			14.667,
			14.715,
			14.778,
			14.839,
			14.9,
			14.965,
			14.995,
			15.025,
			15.088,
			15.15,
			15.212,
			15.275,
			15.322,
			15.337,
			15.399,
			15.431,
			15.462,
			15.541,
			15.587,
			15.65,
			15.712,
			15.773,
			15.868,
			15.9,
			15.978,
			16.024,
			16.087,
			16.147,
			16.211,
			16.305,
			16.336,
			16.396,
			16.412,
			16.461,
			16.524,
			16.585,
			16.648,
			16.708,
			16.741,
			16.773,
			16.851,
			16.898,
			16.958,
			17.022,
			17.069,
			17.084,
			17.147,
			17.207,
			17.285,
			17.334,
			17.394,
			17.458,
			17.52,
			17.582,
			17.615,
			17.644,
			17.721,
			17.77,
			17.832,
			17.892,
			17.958,
			18.018,
			18.051,
			18.081,
			18.144,
			18.16,
			18.205,
			18.269,
			18.33,
			18.378,
			18.392,
			18.455,
			18.519,
			18.595,
			18.643,
			18.705,
			18.767,
			18.828,
			18.891,
			18.924,
			18.953,
			19.018,
			19.033,
			19.079,
			19.142,
			19.205,
			19.267,
			19.328,
			19.36,
			19.391,
			19.468,
			19.517,
			19.578,
			19.639,
			19.687,
			19.764,
			19.795,
			19.827,
			19.905,
			19.952,
			20.014,
			20.077,
			20.124,
			20.139,
			20.202,
			20.232,
			20.262,
			20.34,
			20.388,
			20.451,
			20.514,
			20.574,
			20.638,
			20.669,
			20.7,
			20.778,
			20.823,
			20.888,
			20.95,
			20.996,
			21.011,
			21.073,
			21.136,
			21.214,
			21.262,
			21.328,
			21.387,
			21.433,
			21.512,
			21.541,
			21.572,
			21.635,
			21.699,
			21.761,
			21.824,
			21.869,
			21.884,
			21.948,
			21.98,
			22.011,
			22.088,
			22.134,
			22.198,
			22.26,
			22.305,
			22.321,
			22.384,
			22.447,
			22.525,
			22.572,
			22.695,
			22.759,
			22.821,
			22.853,
			22.884,
			22.947,
			22.962,
			23.006,
			23.072,
			23.133,
			23.18,
			23.195,
			23.258,
			23.289,
			23.32,
			23.382,
			23.403,
			23.444,
			23.508,
			23.57,
			23.632,
			23.694,
			23.757,
			23.819,
			23.834,
			23.882,
			23.944,
			24.005,
			24.069,
			24.132,
			24.163,
			24.194,
			24.255,
			24.272,
			24.318,
			24.38,
			24.441,
			24.505,
			24.599,
			24.629,
			24.691,
			24.708,
			24.754,
			24.816,
			24.879,
			24.941,
			25.035,
			25.065,
			25.129,
			25.144,
			25.191,
			25.253,
			25.314,
			25.379,
			25.439,
			25.501,
			25.564,
			25.628,
			25.69,
			25.752,
			25.815,
			25.906,
			25.94,
			26.018,
			26.064,
			26.127,
			26.188,
			26.251,
			26.345,
			26.375,
			26.437,
			26.498,
			26.561,
			26.624,
			26.687,
			26.748,
			26.78,
			26.812,
			26.889,
			26.936,
			26.998,
			27.061,
			27.122,
			27.217,
			27.247,
			27.326,
			27.372,
			27.435,
			27.496,
			27.561,
			27.623,
			27.654,
			27.684,
			27.761,
			27.807,
			27.871,
			27.933,
			27.995,
			28.059,
			28.09,
			28.121,
			28.198,
			28.245,
			28.308,
			28.371,
			28.432,
			28.526,
			28.556,
			28.62,
			28.635,
			28.682,
			28.745,
			28.805,
			28.867,
			28.931,
			28.994,
			29.071,
			29.117,
			29.182,
			29.244,
			29.304,
			29.398,
			29.43,
			29.493,
			29.509,
			29.555,
			29.622,
			29.679,
			29.743,
			29.804,
			29.867,
			29.944,
			29.992,
			30.055,
			30.117,
			30.179,
			30.24,
			30.271,
			30.304,
			30.367,
			30.382,
			30.429,
			30.49,
			30.553,
			30.615,
			30.678,
			30.707,
			30.74,
			30.819,
			30.864,
			30.925,
			30.989,
			31.036,
			31.114,
			31.177,
			31.255,
			31.302,
			31.365,
			31.426,
			31.489,
			31.551,
			31.612,
			31.677,
			31.698,
			31.738,
			31.8,
			31.862,
			31.924,
			32.019,
			32.05,
			32.112,
			32.174,
			32.239,
			32.299,
			32.363,
			32.457,
			32.487,
			32.549,
			32.564,
			32.611,
			32.673,
			32.736,
			32.799,
			32.862,
			32.891,
			32.924,
			33.002,
			33.049,
			33.111,
			33.173,
			33.236,
			33.327,
			33.361,
			33.421,
			33.438,
			33.484,
			33.546,
			33.608,
			33.671,
			33.735,
			33.798,
			33.874,
			33.92,
			33.984,
			34.047,
			34.109,
			34.201,
			34.234,
			34.311,
			34.358,
			34.42,
			34.481,
			34.545,
			34.639,
			34.668,
			34.732,
			34.794,
			34.855,
			34.919,
			34.981,
			35.074,
			35.106,
			35.184,
			35.231,
			35.294,
			35.355,
			35.418,
			35.511,
			35.541,
			35.619,
			35.666,
			35.728,
			35.791,
			35.841,
			35.917,
			35.979,
			36.057,
			36.104,
			36.166,
			36.229,
			36.291,
			36.384,
			36.415,
			36.493,
			36.541,
			36.601,
			36.665,
			36.712,
			36.789,
			36.819,
			36.853,
			36.929,
			36.975,
			37.038,
			37.1,
			37.164,
			37.226,
			37.286,
			37.367,
			37.413,
			37.475,
			37.536,
			37.6,
			37.694,
			37.723,
			37.802,
			37.85,
			37.914,
			37.974,
			38.035,
			38.128,
			38.161,
			38.238,
			38.287,
			38.347,
			38.411,
			38.473,
			38.567,
			38.599,
			38.66,
			38.721,
			38.786,
			38.847,
			38.894,
			38.91,
			38.973,
			39.033,
			39.112,
			39.159,
			39.223,
			39.283,
			39.347,
			39.438,
			39.471,
			39.547,
			39.595,
			39.658,
			39.72,
			39.782,
			39.845,
			39.908,
			39.991,
			40.031,
			40.095,
			40.157,
			40.218,
			40.281,
			40.313,
			40.344,
			40.421,
			40.469,
			40.53,
			40.594,
			40.656,
			40.718,
			40.781,
			40.857,
			40.904,
			40.967,
			41.029,
			41.091,
			41.155,
			41.218,
			41.294,
			41.341,
			41.404,
			41.467,
			41.53,
			41.59,
			41.621,
			41.654,
			41.715,
			41.732,
			41.778,
			41.844,
			41.902,
			41.965,
			42.064,
			42.091,
			42.168,
			42.216,
			42.277,
			42.339,
			42.402,
			42.465,
			42.495,
			42.525,
			42.604,
			42.652,
			42.713,
			42.777,
			42.822,
			42.839,
			42.901,
			42.962,
			43.041,
			43.089,
			43.152,
			43.212,
			43.274,
			43.367,
			43.4,
			43.478,
			43.524,
			43.587,
			43.65,
			43.712,
			43.805,
			43.837,
			43.914,
			43.962,
			44.023,
			44.087,
			44.137,
			44.212,
			44.274,
			44.352,
			44.399,
			44.462,
			44.522,
			44.585,
			44.677,
			44.708,
			44.789,
			44.835,
			44.897,
			44.96,
			45.022,
			45.115,
			45.145,
			45.225,
			45.272,
			45.333,
			45.394,
			45.457,
			45.52,
			45.584,
			45.662,
			45.707,
			45.771,
			45.832,
			45.878,
			45.894,
			45.958,
			46.019,
			46.098,
			46.145,
			46.211,
			46.269,
			46.331,
			46.424,
			46.456,
			46.517,
			46.533,
			46.581,
			46.642,
			46.705,
			46.753,
			46.768,
			46.83,
			46.891,
			46.969,
			47.017,
			47.084,
			47.142,
			47.189,
			47.265,
			47.33,
			47.406,
			47.454,
			47.515,
			47.579,
			47.639,
			47.733,
			47.766,
			47.828,
			47.843,
			47.889,
			47.952,
			48.014,
			48.077,
			48.17,
			48.202,
			48.283,
			48.326,
			48.389,
			48.45,
			48.497,
			48.512,
			48.574,
			48.608,
			48.638,
			48.716,
			48.763,
			48.826,
			48.887,
			48.934,
			49.012,
			49.074,
			49.151,
			49.199,
			49.261,
			49.324,
			49.369,
			49.385,
			49.448,
			49.511,
			49.588,
			49.635,
			49.698,
			49.76,
			49.823,
			49.915,
			49.947,
			50.026,
			50.073,
			50.134,
			50.197,
			50.261,
			50.321,
			50.384,
			50.447,
			50.462,
			50.507,
			50.57,
			50.634,
			50.694,
			50.758,
			50.82,
			50.898,
			50.944,
			51.011,
			51.071,
			51.133,
			51.226,
			51.257,
			51.335,
			51.381,
			51.444,
			51.505,
			51.568,
			51.632,
			51.663,
			51.695,
			51.771,
			51.819,
			51.879,
			51.944,
			51.99,
			52.068,
			52.131,
			52.208,
			52.255,
			52.318,
			52.381,
			52.429,
			52.441,
			52.505,
			52.568,
			52.645,
			52.691,
			52.754,
			52.817,
			52.863,
			52.94,
			52.971,
			53.002,
			53.081,
			53.129,
			53.19,
			53.253,
			53.316,
			53.379,
			53.44,
			53.519,
			53.563,
			53.628,
			53.69,
			53.737,
			53.815,
			53.877,
			53.955,
			54.002,
			54.062,
			54.126,
			54.187,
			54.283,
			54.314,
			54.375,
			54.391,
			54.438,
			54.503,
			54.561,
			54.624,
			54.687,
			54.749,
			54.827,
			54.874,
			54.94,
			54.999,
			55.062,
			55.124,
			55.184,
			55.262,
			55.308,
			55.374,
			55.435,
			55.498,
			55.591,
			55.622,
			55.683,
			55.748,
			55.809,
			55.871,
			55.918,
			55.935,
			55.996,
			56.028,
			56.058,
			56.136,
			56.185,
			56.247,
			56.309,
			56.354,
			56.372,
			56.432,
			56.464,
			56.495,
			56.574,
			56.619,
			56.682,
			56.745,
			56.807,
			56.869,
			56.932,
			57.01,
			57.057,
			57.117,
			57.182,
			57.228,
			57.245,
			57.306,
			57.368,
			57.445,
			57.493,
			57.557,
			57.619,
			57.665,
			57.741,
			57.773,
			57.805,
			57.883,
			57.929,
			57.991,
			58.054,
			58.1,
			58.118,
			58.179,
			58.24,
			58.318,
			58.365,
			58.429,
			58.49,
			58.554,
			58.615,
			58.678,
			58.756,
			58.803,
			58.865,
			58.928,
			58.99,
			59.083,
			59.113,
			59.191,
			59.239,
			59.301,
			59.365,
			59.427,
			59.488,
			59.551,
			59.63,
			59.674,
			59.74,
			59.8,
			59.846,
			59.861,
			59.955,
			59.988,
			60.066,
			60.111,
			60.173,
			60.237,
			60.3,
			60.392,
			60.423,
			60.501,
			60.55,
			60.611,
			60.673,
			60.737,
			60.829,
			60.862,
			60.939,
			60.987,
			61.047,
			61.111,
			61.174,
			61.234,
			61.297,
			61.376,
			61.422,
			61.483,
			61.547,
			61.594,
			61.609,
			61.673,
			61.734,
			61.812,
			61.86,
			61.922,
			61.984,
			62.045,
			62.14,
			62.171,
			62.25,
			62.295,
			62.357,
			62.421,
			62.466,
			62.545,
			62.607,
			62.684,
			62.732,
			62.8,
			62.856,
			62.919,
			63.011,
			63.045,
			63.121,
			63.17,
			63.23,
			63.293,
			63.357,
			63.418,
			63.48,
			63.559,
			63.605,
			63.666,
			63.729,
			63.791,
			63.854,
			63.917,
			63.996,
			64.041,
			64.105,
			64.166,
			64.229,
			64.323,
			64.352,
			64.432,
			64.478,
			64.541,
			64.604,
			64.664,
			64.757,
			64.79,
			64.851,
			64.914,
			64.977,
			65.04,
			65.101,
			65.163,
			65.194,
			65.227,
			65.29,
			65.352,
			65.413,
			65.474,
			65.537,
			65.601,
			65.662,
			65.741,
			65.787,
			65.85,
			65.913,
			65.975,
			66.038,
			66.099,
			66.178,
			66.224,
			66.287,
			66.35,
			66.397,
			66.412,
			66.475,
			66.535,
			66.615,
			66.661,
			66.723,
			66.786,
			66.846,
			66.91,
			66.972,
			67.051,
			67.096,
			67.161,
			67.221,
			67.284,
			67.348,
			67.41,
			67.487,
			67.533,
			67.6,
			67.658,
			67.721,
			67.784,
			67.845,
			67.923,
			67.97,
			68.032,
			68.095,
			68.157,
			68.251,
			68.283,
			68.361,
			68.407,
			68.47,
			68.532,
			68.579,
			68.594,
			68.657,
			68.719,
			68.797,
			68.844,
			68.905,
			68.968,
			69.032,
			69.095,
			69.157,
			69.234,
			69.28,
			69.343,
			69.405,
			69.451,
			69.467,
			69.529,
			69.561,
			69.593,
			69.669,
			69.717,
			69.779,
			69.84,
			69.889,
			69.968,
			69.998,
			70.03,
			70.108,
			70.153,
			70.215,
			70.28,
			70.34,
			70.404,
			70.435,
			70.465,
			70.528,
			70.545,
			70.589,
			70.654,
			70.715,
			70.762,
			70.872,
			70.902,
			70.965,
			71.028,
			71.094,
			71.151,
			71.215,
			71.277,
			71.308,
			71.34,
			71.402,
			71.417,
			71.462,
			71.527,
			71.588,
			71.634,
			71.712,
			71.745,
			71.774,
			71.852,
			71.901,
			71.964,
			72.024,
			72.087,
			72.15,
			72.18,
			72.212,
			72.274,
			72.29,
			72.336,
			72.399,
			72.462,
			72.525,
			72.586,
			72.617,
			72.648,
			72.712,
			72.773,
			72.836,
			72.899,
			72.945,
			72.961,
			73.022,
			73.055,
			73.084,
			73.168,
			73.208,
			73.272,
			73.335,
			73.397,
			73.49,
			73.521,
			73.584,
			73.646,
			73.708,
			73.771,
			73.834,
			73.927,
			73.957,
			74.021,
			74.036,
			74.083,
			74.145,
			74.208,
			74.27,
			74.331,
			74.364,
			74.394,
			74.473,
			74.519,
			74.58,
			74.644,
			74.706,
			74.8,
			74.832,
			74.894,
			74.909,
			74.954,
			75.018,
			75.079,
			75.14,
			75.204,
			75.239,
			75.268,
			75.33,
			75.344,
			75.393,
			75.458,
			75.517,
			75.58,
			75.64,
			75.672,
			75.705,
			75.767,
			75.828,
			75.89,
			75.952,
			76.017,
			76.079,
			76.11,
			76.139,
			76.219,
			76.264,
			76.327,
			76.39,
			76.451,
			76.514,
			76.544,
			76.577,
			76.638,
			76.702,
			76.763,
			76.827,
			76.872,
			76.889,
			76.951,
			76.982,
			77.012,
			77.075,
			77.091,
			77.139,
			77.201,
			77.264,
			77.326,
			77.42,
			77.45,
			77.512,
			77.574,
			77.636,
			77.699,
			77.76,
			77.825,
			77.855,
			77.887,
			77.949,
			77.963,
			78.011,
			78.073,
			78.137,
			78.183,
			78.293,
			78.322,
			78.402,
			78.446,
			78.511,
			78.572,
			78.635,
			78.698,
			78.727,
			78.76,
			78.822,
			78.837,
			78.885,
			78.946,
			79.01,
			79.072,
			79.133,
			79.165,
			79.197,
			79.257,
			79.32,
			79.387,
			79.447,
			79.509,
			79.57,
			79.633,
			79.711,
			79.756,
			79.82,
			79.882,
			79.945,
			80.038,
			80.068,
			80.133,
			80.194,
			80.257,
			80.32,
			80.38,
			80.444,
			80.474,
			80.506,
			80.567,
			80.583,
			80.631,
			80.694,
			80.756,
			80.818,
			80.911,
			80.944,
			81.004,
			81.019,
			81.066,
			81.129,
			81.192,
			81.253,
			81.317,
			81.346,
			81.38,
			81.461,
			81.503,
			81.567,
			81.628,
			81.689,
			81.785,
			81.816,
			81.892,
			81.94,
			82.001,
			82.065,
			82.128,
			82.22,
			82.252,
			82.313,
			82.377,
			82.439,
			82.5,
			82.564,
			82.658,
			82.688,
			82.749,
			82.765,
			82.812,
			82.876,
			82.938,
			83,
			83.061,
			83.126,
			83.204,
			83.25,
			83.313,
			83.374,
			83.436,
			83.535,
			83.56,
			83.638,
			83.686,
			83.75,
			83.811,
			83.872,
			83.936,
			83.967,
			83.998,
			84.076,
			84.122,
			84.184,
			84.248,
			84.31,
			84.402,
			84.435,
			84.512,
			84.557,
			84.621,
			84.684,
			84.747,
			84.84,
			84.87,
			84.933,
			84.995,
			85.057,
			85.119,
			85.165,
			85.244,
			85.307,
			85.386,
			85.432,
			85.495,
			85.557,
			85.62,
			85.681,
			85.744,
			85.82,
			85.868,
			85.931,
			85.994,
			86.041,
			86.119,
			86.18,
			86.257,
			86.303,
			86.367,
			86.43,
			86.491,
			86.554,
			86.617,
			86.694,
			86.741,
			86.803,
			86.866,
			86.928,
			86.99,
			87.052,
			87.132,
			87.178,
			87.242,
			87.301,
			87.349,
			87.364,
			87.427,
			87.458,
			87.489,
			87.567,
			87.615,
			87.68,
			87.739,
			87.785,
			87.865,
			87.927,
			88.005,
			88.052,
			88.114,
			88.174,
			88.239,
			88.3,
			88.362,
			88.44,
			88.487,
			88.55,
			88.613,
			88.66,
			88.736,
			88.8,
			88.878,
			88.924,
			88.987,
			89.05,
			89.096,
			89.112,
			89.172,
			89.236,
			89.315,
			89.361,
			89.423,
			89.486,
			89.533,
			89.611,
			89.673,
			89.754,
			89.797,
			89.858,
			89.923,
			89.969,
			89.984,
			90.047,
			90.109,
			90.188,
			90.234,
			90.296,
			90.36,
			90.422,
			90.483,
			90.513,
			90.545,
			90.624,
			90.67,
			90.732,
			90.795,
			90.842,
			90.92,
			90.951,
			90.982,
			91.061,
			91.106,
			91.17,
			91.231,
			91.278,
			91.295,
			91.356,
			91.418,
			91.498,
			91.545,
			91.606,
			91.669,
			91.731,
			91.793,
			91.829,
			91.854,
			91.918,
			91.933,
			91.979,
			92.043,
			92.105,
			92.152,
			92.167,
			92.23,
			92.291,
			92.371,
			92.416,
			92.48,
			92.543,
			92.587,
			92.666,
			92.728,
			92.806,
			92.854,
			92.915,
			92.979,
			93.023,
			93.041,
			93.103,
			93.139
		]
	],
	'7272_8314_7982': [
		[
			46.467
		],
		[
			0
		],
		[
			0,
			2.26,
			4.921,
			7.618,
			8.968,
			11.67,
			15.721,
			19.772,
			22.48,
			25.195,
			29.28,
			32.012,
			37.498,
			38.874,
			43.013,
			45.781,
			49.946,
			52.731,
			55.522,
			56.921,
			62.531,
			63.938,
			68.176,
			71.016,
			73.863,
			78.138,
			80.992,
			86.716,
			88.151,
			92.465,
			95.348,
			99.685,
			102.587,
			105.494,
			106.95,
			109.867,
			111.328,
			114.255,
			118.657,
			121.599,
			124.546,
			128.977,
			131.937,
			136.388,
			137.875,
			139.362,
			143.83,
			146.829,
			151.345,
			154.363,
			157.364,
			158.867,
			161.862,
			163.361,
			166.36,
			170.861,
			173.865,
			176.872,
			181.398,
			184.427,
			189.019,
			190.572,
			192.132,
			196.847,
			200.012,
			204.764,
			207.941,
			212.727,
			215.931,
			217.537,
			220.742,
			223.939,
			225.52,
			228.67,
			231.82,
			236.545,
			238.118,
			239.684,
			244.334,
			245.86,
			247.376,
			251.861,
			254.8,
			259.118,
			261.943,
			266.111,
			268.842,
			270.194,
			272.869,
			276.811,
			279.392,
			281.937,
			285.684,
			286.915,
			288.136,
			291.743,
			292.928,
			294.105,
			297.586,
			299.861,
			303.203,
			305.385,
			307.53,
			308.589,
			310.678,
			311.707,
			312.727,
			316.713,
			317.685,
			320.553,
			322.426,
			324.264,
			325.173,
			326.967,
			328.744,
			329.613,
			332.171,
			333.834,
			335.469,
			337.867,
			339.429,
			341.724,
			342.476,
			343.223,
			345.428,
			346.871,
			349.015,
			350.432,
			352.542,
			353.952,
			355.36,
			356.06,
			358.166,
			359.577,
			360.97,
			363.055,
			364.449,
			366.541,
			367.239,
			367.936,
			370.039,
			371.562,
			373.844,
			375.411,
			377.954,
			381.366,
			382.228,
			383.953,
			384.816,
			386.539,
			388.263,
			390.936,
			392.848,
			395.78,
			396.833,
			397.887,
			400.971,
			402.892,
			405.717,
			407.433,
			410.021,
			411.651,
			412.462,
			414.09,
			415.697,
			416.472,
			418.028,
			419.592,
			421.925,
			423.485,
			426.653,
			427.456,
			429.884,
			431.539,
			434.053,
			435.751,
			438.342,
			440.965,
			442.738,
			445.422,
			447.234,
			449.059,
			451.822,
			453.666,
			456.48,
			457.424,
			458.37,
			461.219,
			463.122,
			465.962,
			467.834,
			470.592,
			472.388,
			473.272,
			475.012,
			476.717,
			477.556,
			479.206,
			480.818,
			482.389,
			483.924,
			484.678,
			487.6,
			488.309,
			490.392,
			491.758,
			493.111,
			495.129,
			496.471,
			498.479,
			499.14,
			499.8,
			501.762,
			502.409,
			503.055,
			504.98,
			506.244,
			508.111,
			509.347,
			510.578,
			511.174,
			512.359,
			512.949,
			514.127,
			515.303,
			517.016,
			518.148,
			520.413,
			521.006,
			522.81,
			523.412,
			524.015,
			525.898,
			527.429,
			529.731,
			531.314,
			532.951,
			533.77,
			535.412,
			536.272,
			538.014,
			539.762,
			542.723,
			544.807,
			548.482,
			549.375,
			551.803,
			553.272,
			555.493,
			556.918,
			559.06,
			560.502,
			562.693,
			564.912,
			566.408,
			567.915,
			570.203,
			571.741,
			574.072,
			574.856,
			575.645,
			578.035,
			579.646,
			582.093,
			583.745,
			586.235,
			588.767,
			590.454,
			592.148,
			593,
			594.715,
			596.443,
			599.06,
			599.939,
			600.822,
			603.491,
			604.387,
			605.286,
			608.021,
			609.852,
			612.625,
			614.491,
			617.322,
			619.231,
			620.19,
			622.13,
			624.088,
			625.072,
			627.047,
			629.03,
			631.021,
			634.026,
			636.033,
			638.055,
			639.065,
			641.085,
			642.118,
			644.182,
			647.338,
			649.504,
			651.679,
			654.982,
			656.082,
			657.125,
			660.255,
			661.291,
			662.298,
			665.33,
			667.374,
			670.464,
			672.568,
			674.695,
			675.762,
			677.927,
			679.016,
			681.203,
			683.335,
			686.541,
			688.622,
			692.789,
			693.826,
			696.949,
			697.998,
			699.049,
			702.203,
			704.333,
			707.568,
			711.896,
			712.982,
			715.143,
			716.237,
			718.424,
			720.621,
			723.954,
			726.175,
			730.624,
			731.742,
			735.108,
			737.373,
			740.807,
			743.105,
			746.568,
			748.864,
			750.044,
			752.372,
			754.707,
			755.878,
			758.226,
			760.583,
			764.155,
			766.553,
			771.39,
			772.603,
			776.232,
			778.698,
			782.383,
			784.854,
			788.548,
			792.273,
			794.759,
			797.255,
			798.508,
			801.02,
			803.543,
			807.345,
			809.888,
			813.715,
			814.994,
			816.275,
			820.126,
			822.702,
			826.579,
			829.17,
			833.058,
			836.961,
			839.573,
			842.192,
			843.505,
			846.136,
			848.775,
			860.752,
			862.092,
			866.124,
			868.822,
			871.528,
			875.601,
			879.691,
			882.426,
			883.796,
			885.168,
			889.294,
			890.673,
			893.436,
			896.206,
			898.984,
			901.768,
			903.162,
			905.956,
			908.757,
			910.161,
			912.972,
			914.38,
			917.201,
			921.445,
			924.288,
			927.133,
			931.378,
			932.78,
			934.17,
			938.287,
			939.64,
			940.984,
			944.957,
			947.56,
			951.424,
			953.962,
			957.708,
			960.147,
			961.353,
			963.734,
			966.074,
			969.517,
			971.763,
			975.064,
			976.147,
			977.22,
			980.385,
			982.449,
			985.474,
			987.444,
			989.377,
			990.329,
			992.207,
			994.048,
			994.956,
			997.63,
			999.363,
			1001.058,
			1003.533,
			1005.14,
			1008.247,
			1009.005,
			1011.226,
			1012.664,
			1014.782,
			1016.159,
			1017.521,
			1018.196,
			1019.532,
			1020.854,
			1021.51,
			1022.819,
			1023.468,
			1024.755,
			1026.034,
			1027.946,
			1029.187,
			1031.03,
			1031.643,
			1032.255,
			1034.039,
			1035.191,
			1036.914,
			1038.061,
			1039.864,
			1041.062,
			1041.66,
			1042.853,
			1044.333,
			1045.123,
			1046.701,
			1048.312,
			1051.573,
			1054.003,
			1058.521,
			1059.447,
			1060.372,
			1063.152,
			1064.361,
			1066.127,
			1067.309,
			1069.112,
			1070.335,
			1070.949,
			1072.181,
			1073.426,
			1074.072,
			1075.37,
			1076.677,
			1078.664,
			1080.017,
			1082.756,
			1083.455,
			1084.872,
			1085.587,
			1087.028,
			1089.237,
			1090.747,
			1092.272,
			1094.674,
			1095.486,
			1096.316,
			1099.776,
			1100.709,
			1103.486,
			1105.42,
			1108.326,
			1110.281,
			1112.228,
			1113.205,
			1115.021,
			1115.919,
			1117.722,
			1120.234,
			1121.864,
			1123.506,
			1126.847,
			1127.69,
			1130.296,
			1131.182,
			1132.071,
			1134.762,
			1136.575,
			1139.321,
			1141.174,
			1143.039,
			1143.975,
			1146.8,
			1148.699,
			1150.6,
			1153.474,
			1155.405,
			1158.325,
			1159.304,
			1160.286,
			1163.25,
			1165.238,
			1168.243,
			1170.258,
			1173.302,
			1177.389,
			1178.417,
			1180.481,
			1181.516,
			1183.594,
			1185.682,
			1188.821,
			1190.948,
			1194.161,
			1195.245,
			1196.331,
			1199.6,
			1201.784,
			1205.094,
			1207.278,
			1210.61,
			1212.85,
			1215.106,
			1216.24,
			1219.673,
			1221.953,
			1224.243,
			1227.695,
			1230.016,
			1234.708,
			1235.889,
			1239.444,
			1241.813,
			1245.381,
			1247.747,
			1251.376,
			1255.022,
			1257.477,
			1259.934,
			1261.158,
			1263.611,
			1266.033,
			1269.659,
			1272.078,
			1275.697,
			1278.132,
			1281.804,
			1284.284,
			1287.993,
			1290.454,
			1292.94,
			1297.898,
			1300.349,
			1303.964,
			1306.327,
			1308.655,
			1312.071,
			1314.302,
			1318.661,
			1319.729,
			1322.888,
			1324.949,
			1327.973,
			1329.943,
			1331.878,
			1334.715,
			1337.48,
			1340.171,
			1341.052,
			1343.64,
			1346.145,
			1346.961,
			1347.768,
			1349.356,
			1351.677,
			1353.948,
			1355.456,
			1357.682,
			1359.157,
			1360.63,
			1362.828,
			1363.557,
			1364.284,
			1367.17,
			1367.887,
			1370.026,
			1371.423,
			1373.51,
			1374.894,
			1376.927,
			1378.954,
			1380.311,
			1381.672,
			1383.71,
			1385.103,
			1388.056,
			1388.795,
			1391.134,
			1392.756,
			1395.254,
			1397.123,
			1398.993,
			1399.958,
			1402.263,
			1403.415,
			1405.593,
			1408.552,
			1410.398,
			1412.004,
			1414.424,
			1416.019,
			1418.401,
			1419.999,
			1422.382,
			1423.98,
			1426.356,
			1427.932,
			1430.31,
			1433.462,
			1434.256,
			1436.651,
			1438.26,
			1439.868,
			1442.321,
			1443.973,
			1446.476,
			1448.163,
			1450.718,
			1452.439,
			1455.037,
			1456.767,
			1459.354,
			1461.072,
			1463.587,
			1466.062,
			1467.695,
			1469.321,
			1471.738,
			1473.336,
			1476.505,
			1477.29,
			1479.632,
			1481.192,
			1483.523,
			1485.069,
			1487.475,
			1489.078,
			1489.878,
			1491.579,
			1494.182,
			1495.955,
			1497.806,
			1500.572,
			1502.358,
			1505.035,
			1506.774,
			1509.363,
			1511.095,
			1513.704,
			1515.445,
			1518.042,
			1520.638,
			1522.323,
			1524.855,
			1525.687,
			1528.107,
			1529.718,
			1531.305,
			1532.075,
			1533.63,
			1535.921,
			1538.147,
			1539.604,
			1541.024,
			1543.119,
			1544.498,
			1546.547,
			1547.902,
			1550.608,
			1551.275,
			1553.273,
			1554.606,
			1556.583,
			1557.887,
			1559.845,
			1561.801,
			1563.102,
			1564.401,
			1566.527,
			1568.126,
			1570.523,
			1572.4,
			1575.24,
			1577.066,
			1579.774,
			1581.66,
			1583.628,
			1584.612,
			1586.516,
			1588.339,
			1589.25,
			1591.756,
			1593.256,
			1594.761,
			1596.899,
			1598.264,
			1600.322,
			1601.685,
			1603.729,
			1605.102,
			1607.2,
			1608.616,
			1610.042,
			1610.759,
			1612.236,
			1613.725,
			1614.474,
			1616.756,
			1618.307,
			1619.871,
			1622.26,
			1623.878,
			1626.333,
			1627.995,
			1630.513,
			1632.18,
			1634.677,
			1636.348,
			1638.816,
			1642.139,
			1642.97,
			1645.482,
			1647.196,
			1648.935,
			1651.565,
			1653.385,
			1656.139,
			1658,
			1660.816,
			1662.71,
			1665.574,
			1667.5,
			1670.414,
			1672.371,
			1675.331,
			1678.316,
			1680.321,
			1682.337,
			1685.384,
			1687.427,
			1691.54,
			1692.562,
			1695.644,
			1697.7,
			1700.78,
			1702.851,
			1704.935,
			1705.981,
			1708.087,
			1709.144,
			1711.266,
			1714.493,
			1716.657,
			1718.835,
			1722.121,
			1724.323,
			1727.644,
			1729.87,
			1733.229,
			1735.48,
			1738.875,
			1741.151,
			1744.584,
			1748.038,
			1750.352,
			1753.841,
			1755.009,
			1758.528,
			1762.069,
			1763.255,
			1764.444,
			1766.829,
			1770.425,
			1774.04,
			1776.461,
			1778.889,
			1782.548,
			1784.998,
			1788.69,
			1791.163,
			1796.132,
			1797.38,
			1801.135,
			1803.649,
			1806.173,
			1807.443,
			1809.994,
			1812.552,
			1813.832,
			1817.682,
			1820.248,
			1822.82,
			1826.693,
			1829.249,
			1833.112,
			1835.715,
			1839.632,
			1842.252,
			1846.242,
			1848.885,
			1851.5,
			1852.807,
			1854.063
		],
		[
			0,
			0.04,
			0.104,
			0.15,
			0.166,
			0.227,
			0.29,
			0.368,
			0.415,
			0.478,
			0.539,
			0.601,
			0.695,
			0.725,
			0.804,
			0.852,
			0.915,
			0.975,
			1.021,
			1.038,
			1.131,
			1.162,
			1.24,
			1.287,
			1.351,
			1.413,
			1.474,
			1.569,
			1.601,
			1.679,
			1.725,
			1.79,
			1.851,
			1.896,
			1.912,
			1.974,
			2.005,
			2.036,
			2.116,
			2.162,
			2.223,
			2.285,
			2.349,
			2.412,
			2.44,
			2.473,
			2.537,
			2.599,
			2.662,
			2.722,
			2.768,
			2.784,
			2.848,
			2.879,
			2.909,
			2.989,
			3.035,
			3.098,
			3.161,
			3.207,
			3.284,
			3.317,
			3.347,
			3.426,
			3.471,
			3.534,
			3.595,
			3.658,
			3.721,
			3.753,
			3.784,
			3.847,
			3.864,
			3.909,
			3.97,
			4.034,
			4.08,
			4.096,
			4.158,
			4.189,
			4.22,
			4.284,
			4.346,
			4.407,
			4.469,
			4.533,
			4.596,
			4.625,
			4.658,
			4.735,
			4.782,
			4.844,
			4.907,
			4.954,
			4.97,
			5.031,
			5.063,
			5.094,
			5.157,
			5.218,
			5.284,
			5.343,
			5.391,
			5.406,
			5.469,
			5.499,
			5.529,
			5.607,
			5.654,
			5.719,
			5.779,
			5.828,
			5.842,
			5.904,
			5.939,
			5.967,
			6.045,
			6.092,
			6.154,
			6.217,
			6.279,
			6.341,
			6.371,
			6.402,
			6.465,
			6.527,
			6.592,
			6.653,
			6.714,
			6.778,
			6.807,
			6.84,
			6.918,
			6.965,
			7.03,
			7.09,
			7.15,
			7.215,
			7.245,
			7.277,
			7.337,
			7.402,
			7.464,
			7.523,
			7.586,
			7.681,
			7.713,
			7.774,
			7.791,
			7.837,
			7.9,
			7.961,
			8.013,
			8.087,
			8.118,
			8.149,
			8.228,
			8.275,
			8.335,
			8.398,
			8.462,
			8.524,
			8.554,
			8.584,
			8.649,
			8.664,
			8.71,
			8.774,
			8.833,
			8.898,
			8.989,
			9.023,
			9.084,
			9.146,
			9.21,
			9.27,
			9.333,
			9.428,
			9.458,
			9.535,
			9.584,
			9.646,
			9.708,
			9.77,
			9.833,
			9.864,
			9.894,
			9.973,
			10.018,
			10.085,
			10.145,
			10.207,
			10.27,
			10.3,
			10.331,
			10.394,
			10.407,
			10.456,
			10.517,
			10.58,
			10.628,
			10.643,
			10.735,
			10.767,
			10.846,
			10.893,
			10.954,
			11.016,
			11.079,
			11.141,
			11.172,
			11.205,
			11.267,
			11.281,
			11.33,
			11.391,
			11.455,
			11.517,
			11.578,
			11.61,
			11.641,
			11.704,
			11.719,
			11.766,
			11.829,
			11.89,
			11.935,
			12.046,
			12.076,
			12.139,
			12.159,
			12.201,
			12.265,
			12.328,
			12.388,
			12.451,
			12.482,
			12.514,
			12.575,
			12.591,
			12.638,
			12.7,
			12.762,
			12.826,
			12.919,
			12.949,
			13.012,
			13.073,
			13.137,
			13.199,
			13.261,
			13.324,
			13.386,
			13.463,
			13.511,
			13.574,
			13.635,
			13.697,
			13.758,
			13.79,
			13.823,
			13.885,
			13.947,
			14.009,
			14.073,
			14.134,
			14.23,
			14.26,
			14.322,
			14.338,
			14.383,
			14.446,
			14.509,
			14.554,
			14.57,
			14.634,
			14.664,
			14.696,
			14.757,
			14.82,
			14.884,
			14.945,
			15.006,
			15.07,
			15.102,
			15.132,
			15.194,
			15.211,
			15.257,
			15.323,
			15.381,
			15.443,
			15.504,
			15.537,
			15.568,
			15.63,
			15.646,
			15.694,
			15.756,
			15.816,
			15.864,
			15.943,
			15.973,
			16.003,
			16.068,
			16.083,
			16.129,
			16.191,
			16.255,
			16.317,
			16.379,
			16.411,
			16.44,
			16.502,
			16.518,
			16.567,
			16.629,
			16.69,
			16.752,
			16.846,
			16.878,
			16.94,
			16.956,
			17.001,
			17.066,
			17.126,
			17.188,
			17.283,
			17.314,
			17.376,
			17.392,
			17.438,
			17.502,
			17.562,
			17.624,
			17.719,
			17.751,
			17.813,
			17.874,
			17.938,
			17.999,
			18.063,
			18.123,
			18.154,
			18.185,
			18.249,
			18.265,
			18.311,
			18.378,
			18.435,
			18.498,
			18.59,
			18.621,
			18.684,
			18.747,
			18.81,
			18.871,
			18.935,
			19.029,
			19.06,
			19.122,
			19.138,
			19.183,
			19.247,
			19.31,
			19.372,
			19.432,
			19.465,
			19.497,
			19.559,
			19.621,
			19.683,
			19.745,
			19.807,
			19.9,
			19.933,
			19.995,
			20.011,
			20.058,
			20.124,
			20.337,
			20.368,
			20.432,
			20.494,
			20.555,
			20.617,
			20.681,
			20.744,
			20.774,
			20.805,
			20.867,
			20.883,
			20.931,
			20.992,
			21.055,
			21.101,
			21.118,
			21.179,
			21.212,
			21.241,
			21.304,
			21.321,
			21.368,
			21.431,
			21.49,
			21.538,
			21.618,
			21.648,
			21.678,
			21.741,
			21.757,
			21.804,
			21.867,
			21.928,
			21.991,
			22.054,
			22.115,
			22.178,
			22.194,
			22.239,
			22.303,
			22.364,
			22.428,
			22.491,
			22.523,
			22.553,
			22.63,
			22.678,
			22.74,
			22.801,
			22.848,
			22.864,
			22.927,
			22.959,
			22.987,
			23.065,
			23.113,
			23.177,
			23.239,
			23.301,
			23.394,
			23.426,
			23.503,
			23.551,
			23.613,
			23.673,
			23.72,
			23.736,
			23.801,
			23.832,
			23.863,
			23.924,
			23.941,
			23.988,
			24.05,
			24.111,
			24.159,
			24.236,
			24.266,
			24.299,
			24.362,
			24.421,
			24.486,
			24.548,
			24.607,
			24.673,
			24.705,
			24.735,
			24.796,
			24.812,
			24.858,
			24.923,
			24.984,
			25.045,
			25.109,
			25.14,
			25.171,
			25.25,
			25.296,
			25.358,
			25.421,
			25.482,
			25.545,
			25.576,
			25.609,
			25.669,
			25.685,
			25.732,
			25.796,
			25.857,
			25.919,
			26.013,
			26.044,
			26.105,
			26.121,
			26.168,
			26.231,
			26.294,
			26.354,
			26.418,
			26.45,
			26.48,
			26.557,
			26.604,
			26.67,
			26.729,
			26.79,
			26.855,
			26.884,
			26.918,
			26.98,
			26.995,
			27.04,
			27.107,
			27.165,
			27.23,
			27.321,
			27.354,
			27.417,
			27.431,
			27.478,
			27.539,
			27.603,
			27.666,
			27.728,
			27.758,
			27.791,
			27.868,
			27.914,
			27.978,
			28.039,
			28.1,
			28.163,
			28.195,
			28.226,
			28.304,
			28.351,
			28.414,
			28.476,
			28.539,
			28.631,
			28.662,
			28.726,
			28.746,
			28.789,
			28.85,
			28.913,
			28.974,
			29.038,
			29.068,
			29.098,
			29.177,
			29.224,
			29.287,
			29.349,
			29.412,
			29.474,
			29.506,
			29.537,
			29.615,
			29.661,
			29.725,
			29.784,
			29.848,
			29.94,
			29.972,
			30.05,
			30.099,
			30.161,
			30.22,
			30.284,
			30.379,
			30.41,
			30.471,
			30.485,
			30.533,
			30.596,
			30.657,
			30.721,
			30.784,
			30.846,
			30.924,
			30.971,
			31.032,
			31.095,
			31.155,
			31.251,
			31.282,
			31.361,
			31.408,
			31.471,
			31.532,
			31.595,
			31.686,
			31.72,
			31.797,
			31.845,
			31.906,
			31.967,
			32.03,
			32.091,
			32.156,
			32.233,
			32.28,
			32.341,
			32.406,
			32.452,
			32.469,
			32.53,
			32.59,
			32.671,
			32.718,
			32.781,
			32.84,
			32.904,
			32.967,
			32.997,
			33.029,
			33.107,
			33.154,
			33.22,
			33.278,
			33.339,
			33.403,
			33.465,
			33.544,
			33.591,
			33.652,
			33.714,
			33.777,
			33.87,
			33.902,
			33.979,
			34.027,
			34.091,
			34.152,
			34.198,
			34.214,
			34.275,
			34.305,
			34.339,
			34.417,
			34.463,
			34.525,
			34.588,
			34.635,
			34.712,
			34.776,
			34.852,
			34.9,
			34.967,
			35.024,
			35.086,
			35.181,
			35.213,
			35.291,
			35.335,
			35.399,
			35.461,
			35.524,
			35.585,
			35.649,
			35.727,
			35.774,
			35.836,
			35.899,
			35.96,
			36.023,
			36.085,
			36.163,
			36.209,
			36.273,
			36.333,
			36.396,
			36.49,
			36.52,
			36.6,
			36.646,
			36.708,
			36.771,
			36.834,
			36.895,
			36.925,
			36.959,
			37.038,
			37.082,
			37.145,
			37.207,
			37.269,
			37.332,
			37.394,
			37.473,
			37.518,
			37.583,
			37.645,
			37.707,
			37.8,
			37.832,
			37.908,
			37.954,
			38.019,
			38.08,
			38.127,
			38.143,
			38.205,
			38.267,
			38.345,
			38.393,
			38.455,
			38.517,
			38.58,
			38.643,
			38.704,
			38.784,
			38.829,
			38.892,
			38.955,
			39.016,
			39.078,
			39.141,
			39.22,
			39.265,
			39.33,
			39.39,
			39.438,
			39.515,
			39.579,
			39.655,
			39.702,
			39.765,
			39.828,
			39.874,
			39.89,
			39.952,
			39.984,
			40.016,
			40.092,
			40.139,
			40.204,
			40.264,
			40.327,
			40.389,
			40.452,
			40.53,
			40.577,
			40.639,
			40.7,
			40.747,
			40.762,
			40.827,
			40.857,
			40.889,
			40.967,
			41.012,
			41.074,
			41.137,
			41.2,
			41.262,
			41.325,
			41.404,
			41.449,
			41.512,
			41.573,
			41.636,
			41.731,
			41.761,
			41.839,
			41.887,
			41.948,
			42.012,
			42.058,
			42.135,
			42.197,
			42.275,
			42.321,
			42.387,
			42.446,
			42.508,
			42.573,
			42.635,
			42.713,
			42.758,
			42.823,
			42.884,
			42.947,
			43.041,
			43.07,
			43.148,
			43.197,
			43.263,
			43.321,
			43.367,
			43.383,
			43.447,
			43.478,
			43.506,
			43.584,
			43.633,
			43.695,
			43.756,
			43.82,
			43.881,
			43.945,
			44.023,
			44.069,
			44.131,
			44.194,
			44.257,
			44.349,
			44.381,
			44.459,
			44.506,
			44.568,
			44.631,
			44.676,
			44.692,
			44.754,
			44.815,
			44.895,
			44.941,
			45.004,
			45.066,
			45.128,
			45.189,
			45.254,
			45.335,
			45.378,
			45.44,
			45.503,
			45.55,
			45.564,
			45.628,
			45.658,
			45.689,
			45.769,
			45.814,
			45.875,
			45.939,
			45.985,
			46.064,
			46.127,
			46.204,
			46.25,
			46.316,
			46.374,
			46.423,
			46.436,
			46.467
		]
	],
	'9176_9344_7982': [
		[
			109.461
		],
		[
			0
		],
		[
			0,
			1.114,
			4.236,
			8.924,
			12.053,
			16.763,
			23.091,
			24.676,
			29.431,
			32.602,
			35.778,
			40.551,
			43.735,
			48.52,
			51.715,
			56.515,
			59.722,
			64.54,
			67.758,
			72.594,
			79.057,
			80.676,
			85.54,
			88.789,
			92.042,
			96.931,
			100.195,
			105.102,
			106.74,
			108.379,
			113.306,
			116.596,
			121.542,
			124.845,
			128.154,
			129.81,
			133.127,
			138.111,
			143.101,
			146.431,
			149.767,
			154.782,
			158.133,
			164.853,
			166.535,
			171.584,
			174.953,
			180.01,
			183.378,
			186.732,
			188.4,
			191.715,
			193.36,
			196.616,
			201.433,
			204.597,
			207.728,
			212.358,
			215.401,
			219.901,
			222.857,
			227.228,
			230.097,
			234.332,
			237.107,
			241.191,
			245.185,
			247.799,
			251.651,
			254.173,
			256.658,
			260.315,
			262.708,
			266.239,
			267.4,
			268.552,
			271.961,
			274.189,
			277.468,
			279.607,
			281.708,
			284.79,
			287.786,
			290.701,
			292.616,
			294.482,
			296.314,
			299.009,
			300.772,
			303.384,
			305.961,
			307.672,
			309.384,
			311.939,
			313.648,
			316.215,
			317.928,
			321.403,
			322.278,
			324.924,
			326.718,
			329.42,
			331.269,
			334.049,
			336.962,
			338.914,
			340.906,
			343.911,
			345.95,
			349.023,
			350.06,
			351.1,
			354.221,
			356.203,
			359.168,
			361.126,
			364.054,
			368.049,
			369.046,
			372.029,
			374.007,
			375.905,
			378.707,
			380.533,
			383.233,
			385.026,
			387.699,
			389.48,
			392.117,
			393.875,
			395.62,
			396.49,
			398.229,
			399.967,
			400.834,
			403.435,
			405.182,
			406.947,
			409.601,
			411.409,
			414.127,
			415.954,
			418.708,
			420.556,
			423.349,
			425.209,
			427.097,
			428.046,
			429.954,
			430.912,
			432.837,
			434.775,
			435.752,
			437.716,
			439.694,
			442.692,
			444.706,
			448.774,
			449.799,
			452.899,
			454.981,
			458.122,
			460.216,
			463.372,
			466.554,
			468.688,
			470.834,
			471.911,
			474.073,
			476.245,
			479.524,
			481.723,
			485.043,
			486.154,
			487.269,
			490.628,
			492.882,
			496.282,
			498.565,
			502.011,
			504.324,
			505.484,
			507.817,
			510.161,
			511.337,
			513.698,
			516.069,
			519.647,
			522.043,
			525.656,
			526.863,
			528.073,
			531.718,
			534.158,
			537.836,
			540.3,
			544.014,
			547.75,
			549,
			554.026,
			555.288,
			559.087,
			561.631,
			565.464,
			569.316,
			570.604,
			571.895,
			575.78,
			578.379,
			582.282,
			584.888,
			588.814,
			591.442,
			592.759,
			594.079,
			598.049,
			599.398,
			600.726,
			604.725,
			607.401,
			611.428,
			616.817,
			618.167,
			620.872,
			622.223,
			624.929,
			627.641,
			631.724,
			634.46,
			638.583,
			641.343,
			646.913,
			648.322,
			652.573,
			655.441,
			658.326,
			659.774,
			662.667,
			666.999,
			671.307,
			674.18,
			677.056,
			681.375,
			684.257,
			688.594,
			691.496,
			695.88,
			698.793,
			703.141,
			706.022,
			708.897,
			710.334,
			713.207,
			717.519,
			721.841,
			724.728,
			727.618,
			731.961,
			734.863,
			740.682,
			742.14,
			746.512,
			749.426,
			753.801,
			756.721,
			761.111,
			765.514,
			768.459,
			771.412,
			772.895,
			775.87,
			778.853,
			783.343,
			786.346,
			792.367,
			793.875,
			798.406,
			801.43,
			805.95,
			808.952,
			813.444,
			816.442,
			817.943,
			820.932,
			823.953,
			825.467,
			828.499,
			831.534,
			836.095,
			839.14,
			843.711,
			845.234,
			846.759,
			851.313,
			854.339,
			858.871,
			861.895,
			864.921,
			866.436,
			869.467,
			870.984,
			874.021,
			878.583,
			881.628,
			884.675,
			889.249,
			890.773,
			892.298,
			896.896,
			898.421,
			899.921,
			904.514,
			907.591,
			912.248,
			915.323,
			919.92,
			922.975,
			926.027,
			932.117,
			933.639,
			938.222,
			942.851,
			944.4,
			945.952,
			949.054,
			952.157,
			953.707,
			956.8,
			958.345,
			961.408,
			964.455,
			968.98,
			971.971,
			976.439,
			979.409,
			983.856,
			985.338,
			986.818,
			991.263,
			994.226,
			998.669,
			1001.62,
			1004.56,
			1006.024,
			1010.401,
			1013.307,
			1016.205,
			1020.521,
			1023.413,
			1027.75,
			1029.199,
			1030.645,
			1035.011,
			1037.905,
			1042.251,
			1045.129,
			1048.037,
			1049.494,
			1052.412,
			1055.337,
			1056.801,
			1061.203,
			1064.146,
			1067.095,
			1071.53,
			1074.494,
			1080.441,
			1081.932,
			1086.411,
			1089.402,
			1093.901,
			1096.91,
			1099.926,
			1101.438,
			1104.464,
			1107.497,
			1109.017,
			1112.06,
			1113.583,
			1116.639,
			1119.704,
			1124.349,
			1127.417,
			1132.12,
			1135.245,
			1140.067,
			1143.237,
			1147.95,
			1151.071,
			1155.73,
			1160.467,
			1163.644,
			1166.812,
			1168.42,
			1171.653,
			1174.843,
			1179.561,
			1182.664,
			1187.381,
			1188.947,
			1190.514,
			1195.226,
			1198.378,
			1203.13,
			1206.318,
			1209.527,
			1211.133,
			1214.351,
			1219.188,
			1224.005,
			1227.238,
			1230.501,
			1235.348,
			1238.566,
			1244.988,
			1246.568,
			1251.396,
			1254.651,
			1259.505,
			1262.748,
			1267.617,
			1270.869,
			1272.497,
			1275.755,
			1280.653,
			1283.897,
			1287.172,
			1292.119,
			1295.404,
			1300.313,
			1301.961,
			1303.613,
			1308.58,
			1311.899,
			1316.893,
			1320.162,
			1325.112,
			1328.421,
			1330.078,
			1333.401,
			1338.397,
			1340.064,
			1345.068,
			1350.076,
			1351.747,
			1353.415,
			1356.753,
			1360.087,
			1361.755,
			1366.748,
			1370.073,
			1373.401,
			1378.399,
			1381.736,
			1386.747,
			1388.419,
			1390.093,
			1395.117,
			1396.794,
			1398.47,
			1403.504,
			1406.861,
			1411.902,
			1418.639,
			1420.325,
			1423.702,
			1425.391,
			1428.773,
			1432.159,
			1437.246,
			1440.643,
			1447.447,
			1449.15,
			1454.262,
			1457.674,
			1462.796,
			1466.215,
			1471.35,
			1474.777,
			1478.207,
			1479.923,
			1483.357,
			1485.076,
			1488.515,
			1491.958,
			1497.128,
			1500.578,
			1505.759,
			1507.488,
			1509.217,
			1514.41,
			1517.875,
			1523.079,
			1526.552,
			1531.769,
			1536.992,
			1540.479,
			1543.971,
			1545.72,
			1549.22,
			1552.719,
			1557.963,
			1561.455,
			1566.694,
			1568.442,
			1570.193,
			1575.457,
			1578.974,
			1584.256,
			1587.781,
			1593.076,
			1598.379,
			1601.917,
			1605.457,
			1607.227,
			1610.77,
			1614.314,
			1619.637,
			1623.19,
			1630.301,
			1632.081,
			1637.427,
			1640.994,
			1646.352,
			1649.926,
			1655.292,
			1658.884,
			1660.682,
			1664.288,
			1669.691,
			1673.276,
			1676.852,
			1682.213,
			1685.799,
			1693,
			1694.803,
			1700.222,
			1703.842,
			1709.278,
			1712.901,
			1716.524,
			1718.335,
			1723.77,
			1727.397,
			1731.026,
			1732.842,
			1736.475,
			1740.11,
			1745.567,
			1747.387,
			1749.208,
			1754.672,
			1758.316,
			1763.788,
			1767.437,
			1772.91,
			1776.562,
			1780.218,
			1785.711,
			1791.214,
			1796.726,
			1800.401,
			1804.077,
			1809.593,
			1813.271,
			1820.636,
			1822.479,
			1826.166,
			1828.01,
			1831.699,
			1835.392,
			1840.934,
			1844.631,
			1850.179,
			1853.88,
			1861.291,
			1863.144,
			1868.705,
			1872.415,
			1876.129,
			1877.987,
			1881.704,
			1887.285,
			1892.871,
			1896.598,
			1900.326,
			1905.922,
			1909.655,
			1915.258,
			1918.996,
			1926.481,
			1928.354,
			1933.976,
			1937.723,
			1943.348,
			1947.1,
			1952.731,
			1958.366,
			1962.126,
			1965.886,
			1971.532,
			1975.298,
			1982.835,
			1984.72,
			1990.379,
			1994.153,
			1999.818,
			2003.597,
			2009.267,
			2013.05,
			2018.728,
			2024.408,
			2028.197,
			2031.988,
			2037.671,
			2041.447,
			2047.061,
			2050.76,
			2056.232,
			2059.828,
			2065.146,
			2068.642,
			2072.097,
			2073.811,
			2077.209,
			2082.229,
			2087.16,
			2090.4,
			2093.601,
			2098.327,
			2101.43,
			2106.017,
			2107.529,
			2109.033,
			2113.5,
			2116.44,
			2120.785,
			2123.635,
			2126.448,
			2127.841,
			2130.598,
			2134.664,
			2138.645,
			2141.251,
			2143.821,
			2147.606,
			2150.084,
			2153.736,
			2156.127,
			2159.654,
			2161.985,
			2165.385,
			2167.612,
			2170.863,
			2174.041,
			2176.122,
			2179.219,
			2181.282,
			2183.354,
			2186.493,
			2188.602,
			2191.79,
			2192.858,
			2193.934,
			2197.178,
			2199.367,
			2202.675,
			2204.905,
			2208.255,
			2211.613,
			2213.84,
			2217.148,
			2219.315,
			2221.444,
			2224.574,
			2225.603,
			2226.626,
			2229.681,
			2230.697,
			2231.713,
			2234.783,
			2236.849,
			2239.986,
			2242.1,
			2244.224,
			2247.43,
			2248.503,
			2250.64,
			2253.852,
			2254.917,
			2258.112,
			2260.258,
			2262.399,
			2263.471,
			2265.655,
			2268.914,
			2271.089,
			2272.172,
			2274.319,
			2277.496,
			2279.59,
			2281.666,
			2284.771,
			2285.802,
			2286.835,
			2289.936,
			2292.003,
			2295.115,
			2297.199,
			2299.281,
			2300.325,
			2302.408,
			2304.478,
			2305.504,
			2307.549,
			2308.569,
			2310.583,
			2312.588,
			2315.585,
			2317.578,
			2320.573,
			2321.573,
			2322.574,
			2325.583,
			2326.589,
			2327.595,
			2330.628,
			2332.657,
			2334.693,
			2335.717,
			2337.765,
			2339.813,
			2340.837,
			2343.911,
			2345.945,
			2347.982,
			2351.046,
			2353.096,
			2357.252,
			2358.301,
			2361.475,
			2362.54,
			2363.608,
			2366.819,
			2368.972,
			2371.126,
			2372.206,
			2375.467,
			2377.663,
			2379.872,
			2380.987,
			2383.226,
			2385.485,
			2388.913,
			2391.234,
			2395.924,
			2397.099,
			2400.634,
			2402.997,
			2406.592,
			2409.022,
			2412.744,
			2415.244,
			2416.485,
			2418.978,
			2422.632,
			2425.062,
			2427.492,
			2431.151,
			2433.594,
			2437.27,
			2439.729,
			2443.419,
			2445.883,
			2449.561,
			2452.014,
			2455.702,
			2458.168,
			2460.642,
			2461.884,
			2464.375,
			2465.623,
			2468.127,
			2470.639,
			2474.426,
			2475.693,
			2476.966,
			2480.796,
			2482.079,
			2483.365,
			2487.213,
			2489.806,
			2493.733,
			2496.338,
			2500.238,
			2502.864,
			2504.179,
			2506.813,
			2509.451,
			2510.773,
			2513.424,
			2516.082,
			2520.082,
			2522.755,
			2526.773,
			2528.115,
			2529.458,
			2533.5,
			2536.203,
			2538.916,
			2543.001,
			2545.734,
			2549.859,
			2551.238,
			2552.62,
			2558.16,
			2559.546,
			2563.714,
			2566.499,
			2570.692,
			2576.308,
			2577.717,
			2580.54,
			2581.954,
			2584.786,
			2587.626,
			2591.9,
			2594.761,
			2599.066,
			2601.946,
			2607.731,
			2609.182,
			2613.545,
			2616.461,
			2619.382,
			2620.841,
			2623.751,
			2628.076,
			2632.334,
			2635.125,
			2637.879,
			2641.941,
			2644.604,
			2648.529,
			2651.102,
			2654.895,
			2657.38,
			2661.034,
			2663.425,
			2666.946,
			2669.252,
			2672.645,
			2675.956,
			2678.116,
			2680.239,
			2683.355,
			2685.386,
			2689.34,
			2690.304,
			2693.146,
			2694.968,
			2697.659,
			2699.406,
			2701.129,
			2701.984,
			2703.69,
			2704.552,
			2706.267,
			2708.833,
			2710.557,
			2712.277,
			2714.851,
			2716.56,
			2719.115,
			2720.798,
			2723.322,
			2725,
			2727.517,
			2729.203,
			2731.761,
			2734.336,
			2736.086,
			2738.72,
			2740.513,
			2742.317,
			2745.053,
			2746.906,
			2750.658,
			2751.609,
			2754.474,
			2756.405,
			2759.312,
			2761.251,
			2763.193,
			2764.166,
			2766.083,
			2768.956,
			2771.806,
			2773.703,
			2775.607,
			2778.467,
			2780.38,
			2783.258,
			2784.216,
			2785.18,
			2788.077,
			2790.014,
			2792.958,
			2794.925,
			2796.949,
			2797.96,
			2799.985,
			2803.073,
			2806.143,
			2807.157,
			2810.197,
			2813.203,
			2815.211,
			2818.236,
			2819.246,
			2820.259,
			2823.307,
			2825.345,
			2827.392,
			2830.468,
			2832.525,
			2835.616,
			2836.645,
			2837.675,
			2840.77,
			2841.798,
			2842.828,
			2845.923,
			2847.979,
			2850.044,
			2851.079,
			2853.151,
			2856.276,
			2859.418,
			2861.524,
			2863.638,
			2866.838,
			2868.995,
			2872.251,
			2873.341,
			2874.434,
			2877.674,
			2879.825,
			2883.044,
			2885.196,
			2887.352,
			2888.432,
			2890.6,
			2892.772,
			2893.86,
			2897.136,
			2899.326,
			2901.523,
			2904.825,
			2907.039,
			2911.496,
			2912.623,
			2916.017,
			2918.3,
			2921.746,
			2924.041,
			2927.531,
			2929.873,
			2932.225,
			2933.404,
			2936.958,
			2939.336,
			2941.726,
			2945.333,
			2947.751,
			2951.4,
			2952.622,
			2953.848,
			2957.538,
			2960.009,
			2963.733,
			2966.227,
			2969.985,
			2972.502,
			2976.297,
			2978.838,
			2980.112,
			2982.668,
			2985.235,
			2989.103,
			2991.691,
			2995.577,
			2996.875,
			2998.175,
			3002.087,
			3004.704,
			3008.646,
			3011.293,
			3013.948,
			3015.279,
			3017.948,
			3019.28,
			3021.949,
			3024.625,
			3025.964,
			3028.65,
			3031.343,
			3035.396,
			3038.107,
			3042.192,
			3043.557,
			3044.926,
			3049.044,
			3051.799,
			3055.948,
			3058.724,
			3061.507,
			3062.902,
			3065.697,
			3067.098,
			3069.904,
			3074.126,
			3076.951,
			3079.783,
			3084.045,
			3086.895,
			3092.621,
			3094.057,
			3096.938,
			3098.38,
			3101.272,
			3105.625,
			3108.536,
			3111.457,
			3115.852,
			3117.319,
			3118.789,
			3123.206,
			3124.677,
			3126.147,
			3130.528,
			3133.414,
			3137.674,
			3140.467,
			3143.221,
			3144.585,
			3147.278,
			3148.61,
			3151.247,
			3153.848,
			3157.69,
			3160.209,
			3163.926,
			3165.146,
			3166.358,
			3169.943,
			3171.121,
			3172.288,
			3175.741,
			3177.991,
			3181.296,
			3183.451,
			3185.568,
			3186.613,
			3188.674,
			3189.691,
			3191.694,
			3193.658,
			3196.549,
			3198.416,
			3202.05,
			3202.94,
			3205.58,
			3207.307,
			3209.858,
			3211.542,
			3213.202,
			3214.028,
			3215.68,
			3217.339,
			3218.169,
			3219.833,
			3220.665,
			3222.354,
			3224.059,
			3226.629,
			3228.392,
			3231.044,
			3232.862,
			3235.627,
			3237.488,
			3240.306,
			3242.187,
			3244.99,
			3246.863,
			3248.731,
			3249.664,
			3252.464,
			3254.337,
			3256.198,
			3258.987,
			3260.835,
			3264.521,
			3265.438,
			3268.198,
			3270.039,
			3272.793,
			3274.63,
			3277.375,
			3279.246,
			3280.177,
			3282.033,
			3283.918,
			3284.86,
			3286.735,
			3288.582,
			3291.314,
			3292.219,
			3293.109,
			3295.735,
			3296.604,
			3297.472,
			3300.071,
			3301.793,
			3304.388,
			3306.114,
			3307.834,
			3308.691,
			3310.398,
			3311.25,
			3312.948,
			3314.634,
			3315.475,
			3317.155,
			3318.829,
			3321.329,
			3322.995,
			3325.497,
			3327.165,
			3329.673,
			3331.347,
			3333.864,
			3335.545,
			3338.075,
			3340.615,
			3342.315,
			3344.02,
			3344.874,
			3346.585,
			3348.305,
			3350.895,
			3352.633,
			3354.373,
			3356.118,
			3356.994,
			3359.635,
			3361.406,
			3364.083,
			3365.881,
			3367.689,
			3370.415,
			3371.328,
			3372.244,
			3375.002,
			3375.925,
			3376.85,
			3379.635,
			3381.501,
			3384.316,
			3386.198,
			3389.039,
			3391.891,
			3393.805,
			3395.726,
			3398.626,
			3400.578,
			3403.526,
			3404.514,
			3405.506,
			3408.495,
			3409.496,
			3410.499,
			3413.52,
			3415.545,
			3418.597,
			3422.667,
			3423.694,
			3426.767,
			3428.813,
			3430.88,
			3433.97,
			3436.063,
			3440.29,
			3441.355,
			3445.625,
			3446.704,
			3449.954,
			3452.13,
			3455.41,
			3457.606,
			3460.914,
			3464.239,
			3466.464,
			3468.696,
			3472.06,
			3474.313,
			3478.842,
			3479.979,
			3483.408,
			3485.705,
			3489.173,
			3491.496,
			3494.996,
			3497.341,
			3500.874,
			3504.427,
			3506.805,
			3509.192,
			3512.788,
			3515.194,
			3518.821,
			3521.249,
			3524.907,
			3527.357,
			3531.048,
			3533.519,
			3537.24,
			3539.731,
			3540.979,
			3543.482,
			3545.994,
			3547.252,
			3549.774,
			3552.304,
			3556.109,
			3558.655,
			3562.486,
			3565.05,
			3568.91,
			3571.492,
			3575.378,
			3577.978,
			3580.581,
			3581.881,
			3584.487,
			3588.406,
			3592.34,
			3594.971,
			3597.608,
			3601.575,
			3602.901,
			3604.228,
			3608.22,
			3609.553,
			3610.889,
			3614.904,
			3617.59,
			3621.629,
			3624.33,
			3627.038,
			3628.394,
			3631.111,
			3635.2,
			3639.302,
			3642.045,
			3644.794,
			3648.93,
			3651.695,
			3655.854,
			3658.634,
			3662.815,
			3665.611,
			3668.413,
			3672.628,
			3675.447,
			3679.684,
			3682.518,
			3686.781,
			3688.204,
			3689.63,
			3693.916,
			3696.781,
			3699.652,
			3701.09,
			3703.97,
			3706.856,
			3708.302,
			3712.648,
			3722.84,
			3725.766,
			3731.636,
			3733.107,
			3737.529,
			3740.484,
			3744.928,
			3747.898,
			3752.364,
			3758.34,
			3759.838,
			3762.837,
			3764.339,
			3767.347,
			3770.361,
			3774.892,
			3777.92,
			3782.473,
			3785.515,
			3790.089,
			3793.145,
			3797.739,
			3800.808,
			3805.423,
			3811.594,
			3813.14,
			3816.235,
			3817.783,
			3820.882,
			3823.984,
			3828.651,
			3831.769,
			3837.998,
			3839.544,
			3844.14,
			3847.16,
			3851.617,
			3854.539,
			3858.851,
			3861.678,
			3863.076,
			3865.845,
			3869.925,
			3872.599,
			3875.241,
			3879.139,
			3881.7,
			3885.466,
			3887.927,
			3891.535,
			3893.896,
			3897.373,
			3899.646,
			3902.986,
			3906.24,
			3908.362,
			3911.476,
			3913.506,
			3915.5,
			3918.421,
			3920.322,
			3923.105,
			3924.919,
			3927.579,
			3929.311,
			3931.869,
			3933.53,
			3935.166,
			3938.369,
			3939.942,
			3941.488,
			3942.257,
			3943.023,
			3945.301,
			3947.542,
			3949.032,
			3952.025,
			3952.773,
			3955.029,
			3956.583,
			3958.139,
			3960.483,
			3962.852,
			3965.229,
			3966.823,
			3969.222,
			3970.03,
			3972.5,
			3974.153,
			3976.713,
			3980.185,
			3981.08,
			3982.872,
			3983.77,
			3985.565,
			3987.347,
			3990.028,
			3991.833,
			3995.464,
			3996.413,
			4000.216,
			4001.181,
			4004.082,
			4005.983,
			4008.753,
			4010.598,
			4012.402,
			4013.306,
			4016.03,
			4017.849,
			4019.677,
			4022.418,
			4024.249,
			4027.897,
			4028.809,
			4032.451,
			4033.36,
			4036.095,
			4037.918,
			4040.667,
			4044.352,
			4045.279,
			4047.138,
			4048.071,
			4049.945,
			4051.822,
			4054.643,
			4056.531,
			4060.306,
			4061.258,
			4064.129,
			4066.053,
			4069,
			4070.973,
			4072.955,
			4073.947,
			4075.933,
			4077.931,
			4078.933,
			4081.945,
			4083.974,
			4086.007,
			4089.071,
			4091.13,
			4095.275,
			4096.318,
			4099.44,
			4101.534,
			4104.676,
			4106.783,
			4109.965,
			4113.172,
			4115.346,
			4118.576,
			4120.752,
			4122.938,
			4126.233,
			4128.459,
			4131.762,
			4133.994,
			4137.308,
			4139.558,
			4142.904,
			4145.16,
			4147.426,
			4148.562,
			4150.844,
			4154.285,
			4157.749,
			4160.071,
			4162.403,
			4165.919,
			4167.097,
			4168.275,
			4171.827,
			4173.015,
			4174.205,
			4177.79,
			4180.189,
			4183.803,
			4186.222,
			4188.648,
			4189.864,
			4193.521,
			4195.97,
			4198.423,
			4199.649,
			4200.877,
			4204.548,
			4208.181,
			4210.57,
			4214.093,
			4216.394,
			4219.773,
			4221.978,
			4224.144,
			4227.319,
			4229.389,
			4232.421,
			4234.394,
			4238.225,
			4239.159,
			4241.905,
			4243.695,
			4245.449,
			4246.313,
			4249.701,
			4250.524,
			4252.151,
			4252.956,
			4254.546,
			4256.12,
			4258.424,
			4259.945,
			4262.949,
			4263.691,
			4265.914,
			4266.654,
			4267.394,
			4269.609,
			4271.086,
			4272.578,
			4273.328,
			4274.828,
			4276.331,
			4277.089,
			4279.382,
			4280.914,
			4282.458,
			4284.789,
			4286.344,
			4288.697,
			4290.27,
			4292.634,
			4294.218,
			4296.592,
			4298.185,
			4300.574,
			4302.167,
			4303.768,
			4304.567,
			4306.166,
			4306.965,
			4308.581,
			4310.196,
			4312.624,
			4314.266,
			4316.723,
			4317.542,
			4318.379,
			4320.883,
			4322.548,
			4325.078,
			4326.763,
			4328.451,
			4329.298,
			4330.99,
			4333.535,
			4335.243,
			4336.097,
			4337.807,
			4339.504,
			4342.052,
			4343.75,
			4346.289,
			4347.137,
			4347.987,
			4350.523,
			4352.22,
			4354.773,
			4356.479,
			4359.055,
			4361.655,
			4363.395,
			4366.062,
			4367.845,
			4369.654,
			4372.402,
			4374.246,
			4377.068,
			4378.012,
			4378.957,
			4381.771,
			4383.654,
			4386.447,
			4388.312,
			4391.044,
			4392.848,
			4393.752,
			4395.516,
			4397.249,
			4398.12,
			4399.863,
			4401.587,
			4404.195,
			4405.078,
			4405.973,
			4408.676,
			4409.583,
			4410.512,
			4413.339,
			4415.246,
			4418.183,
			4420.157,
			4423.144,
			4426.123,
			4427.118,
			4430.119,
			4431.116,
			4433.116,
			4435.128,
			4438.156,
			4440.188,
			4443.267,
			4444.298,
			4445.333,
			4448.449,
			4450.534,
			4453.657,
			4455.747,
			4457.857,
			4461.043,
			4462.11,
			4463.18,
			4466.402,
			4467.481,
			4468.563,
			4471.825,
			4474.014,
			4477.316,
			4479.53,
			4481.755,
			4482.871,
			4485.111,
			4486.235,
			4488.491,
			4490.757,
			4494.174,
			4496.464,
			4499.918,
			4501.075,
			4502.233,
			4506.891,
			4508.062,
			4511.587,
			4513.949,
			4517.509,
			4522.289,
			4523.49,
			4527.112,
			4529.538,
			4531.974,
			4535.64,
			4538.092,
			4541.786,
			4543.021,
			4544.259,
			4547.985,
			4549.231,
			4550.479,
			4554.236,
			4556.752,
			4559.275,
			4560.54,
			4563.076,
			4565.62,
			4566.895,
			4570.731,
			4573.299,
			4575.874,
			4579.751,
			4582.346,
			4586.249,
			4587.551,
			4588.853,
			4592.767,
			4595.385,
			4599.324,
			4601.959,
			4605.923,
			4608.574,
			4609.902,
			4612.564,
			4615.232,
			4616.568,
			4619.235,
			4621.889,
			4625.826,
			4628.409,
			4632.212,
			4633.461,
			4634.701,
			4638.363,
			4640.761,
			4644.296,
			4646.612,
			4650.038,
			4652.265,
			4653.366,
			4655.545,
			4658.763,
			4660.875,
			4662.971,
			4666.081,
			4668.16,
			4672.308,
			4673.335,
			4676.406,
			4678.444,
			4681.526,
			4683.587,
			4685.674,
			4686.731,
			4689.895,
			4692.029,
			4694.16,
			4695.223,
			4697.334,
			4699.443,
			4702.569,
			4704.648,
			4707.818,
			4708.878,
			4709.94,
			4713.201,
			4715.384,
			4718.644,
			4720.816,
			4722.951,
			4724.022,
			4726.166,
			4729.369,
			4732.612,
			4733.704,
			4737.009,
			4739.249,
			4742.656,
			4744.963,
			4748.46,
			4750.801,
			4751.973,
			4754.317,
			4756.654,
			4760.126,
			4762.415,
			4766.89,
			4767.975,
			4772.287,
			4773.331,
			4776.455,
			4778.517,
			4780.566,
			4781.592,
			4783.65,
			4786.75,
			4789.876,
			4791.974,
			4794.086,
			4797.281,
			4799.431,
			4802.68,
			4803.77,
			4804.864,
			4808.164,
			4810.377,
			4813.715,
			4815.935,
			4819.271,
			4821.495,
			4823.721,
			4824.841,
			4827.094,
			4828.223,
			4830.474,
			4832.778,
			4836.223,
			4838.512,
			4841.99,
			4844.339,
			4847.826,
			4850.169,
			4853.707,
			4856.055,
			4858.386,
			4859.582,
			4861.916,
			4865.477,
			4869.015,
			4871.398,
			4873.791,
			4877.416,
			4879.83,
			4883.457,
			4884.667,
			4885.878,
			4889.516,
			4891.966,
			4895.66,
			4898.135,
			4900.618,
			4901.862,
			4904.357,
			4905.608,
			4908.115,
			4911.889,
			4914.415,
			4916.95,
			4920.763,
			4923.307,
			4927.105,
			4929.612,
			4933.319,
			4935.747,
			4939.32,
			4941.656,
			4945.091,
			4948.444,
			4950.633,
			4953.848,
			4955.947,
			4958.011,
			4961.04,
			4962.032,
			4963.015,
			4965.915,
			4966.864,
			4967.806,
			4970.612,
			4972.447,
			4975.177,
			4976.984,
			4979.681,
			4981.465,
			4984.131,
			4986.78,
			4988.54,
			4990.3,
			4992.936,
			4994.692,
			4997.345,
			4999.113,
			5001.788,
			5003.577,
			5005.372,
			5008.094,
			5009.913,
			5012.677,
			5014.528,
			5018.317,
			5019.274,
			5022.186,
			5024.153,
			5027.109,
			5029.082,
			5032.055,
			5035.045,
			5037.057,
			5039.082,
			5042.139,
			5044.189,
			5048.313,
			5049.349,
			5052.472,
			5054.565,
			5057.723,
			5059.835,
			5061.959,
			5063.022,
			5065.157,
			5068.375,
			5071.595,
			5073.754,
			5075.926,
			5079.198,
			5081.39,
			5084.685,
			5086.889,
			5090.204,
			5092.422,
			5095.748,
			5097.971,
			5101.305,
			5103.536,
			5105.76,
			5106.874,
			5110.231,
			5112.48,
			5114.74,
			5118.155,
			5120.444,
			5123.877,
			5126.188,
			5129.669,
			5132.022,
			5135.52,
			5137.876,
			5140.243,
			5141.43,
			5143.812,
			5147.412,
			5151.041,
			5153.479,
			5155.928,
			5159.62,
			5162.093,
			5167.065,
			5168.314,
			5172.076,
			5174.595,
			5178.389,
			5180.929,
			5183.478,
			5184.755,
			5187.316,
			5188.601,
			5191.174,
			5193.757,
			5195.051,
			5197.645,
			5200.247,
			5204.156,
			5206.766,
			5210.695,
			5212.009,
			5213.324,
			5217.28,
			5219.927,
			5223.912,
			5226.577,
			5229.25,
			5230.589,
			5233.274,
			5234.618,
			5237.313,
			5240.016,
			5241.37,
			5244.083,
			5246.804,
			5250.899,
			5252.268,
			5253.639,
			5257.763,
			5260.522,
			5264.674,
			5267.453,
			5271.637,
			5274.437,
			5277.244,
			5281.466,
			5282.875,
			5285.699,
			5288.529,
			5289.946,
			5292.786,
			5295.632,
			5298.486,
			5302.779,
			5305.647,
			5308.521,
			5309.959,
			5314.282,
			5317.175,
			5320.077,
			5324.449,
			5327.373,
			5331.767,
			5333.233,
			5334.702,
			5340.582,
			5342.056,
			5346.49,
			5349.459,
			5353.93,
			5356.918,
			5359.912,
			5361.411,
			5364.413,
			5365.917,
			5368.927,
			5371.943,
			5376.477,
			5379.509,
			5384.069,
			5385.592,
			5387.117,
			5391.704,
			5394.773,
			5399.39,
			5402.473,
			5405.559,
			5407.104,
			5410.194,
			5413.291,
			5414.841,
			5417.945,
			5419.5,
			5422.614,
			5425.734,
			5430.414,
			5433.535,
			5436.216
		],
		[
			0,
			0.032,
			0.078,
			0.143,
			0.204,
			0.267,
			0.361,
			0.391,
			0.47,
			0.517,
			0.577,
			0.642,
			0.688,
			0.767,
			0.829,
			0.906,
			0.953,
			1.015,
			1.078,
			1.142,
			1.235,
			1.266,
			1.344,
			1.391,
			1.452,
			1.515,
			1.561,
			1.638,
			1.675,
			1.703,
			1.781,
			1.827,
			1.89,
			1.952,
			1.999,
			2.014,
			2.075,
			2.139,
			2.217,
			2.265,
			2.327,
			2.388,
			2.452,
			2.544,
			2.575,
			2.654,
			2.701,
			2.764,
			2.826,
			2.872,
			2.887,
			2.949,
			2.982,
			3.011,
			3.091,
			3.137,
			3.199,
			3.261,
			3.31,
			3.388,
			3.449,
			3.527,
			3.575,
			3.637,
			3.699,
			3.762,
			3.853,
			3.887,
			3.963,
			4.011,
			4.072,
			4.136,
			4.199,
			4.26,
			4.29,
			4.323,
			4.401,
			4.448,
			4.51,
			4.571,
			4.634,
			4.696,
			4.76,
			4.836,
			4.885,
			4.946,
			5.007,
			5.071,
			5.134,
			5.195,
			5.273,
			5.321,
			5.384,
			5.445,
			5.492,
			5.57,
			5.632,
			5.711,
			5.757,
			5.824,
			5.882,
			5.945,
			6.006,
			6.07,
			6.146,
			6.195,
			6.256,
			6.319,
			6.365,
			6.442,
			6.474,
			6.507,
			6.567,
			6.63,
			6.694,
			6.755,
			6.817,
			6.91,
			6.942,
			7.02,
			7.067,
			7.131,
			7.192,
			7.255,
			7.317,
			7.378,
			7.458,
			7.502,
			7.57,
			7.629,
			7.675,
			7.691,
			7.753,
			7.786,
			7.815,
			7.899,
			7.94,
			8.003,
			8.066,
			8.111,
			8.191,
			8.253,
			8.329,
			8.377,
			8.441,
			8.503,
			8.549,
			8.565,
			8.626,
			8.657,
			8.689,
			8.753,
			8.768,
			8.815,
			8.876,
			8.94,
			9.002,
			9.094,
			9.124,
			9.204,
			9.25,
			9.313,
			9.376,
			9.437,
			9.533,
			9.561,
			9.626,
			9.641,
			9.688,
			9.751,
			9.813,
			9.875,
			9.936,
			9.971,
			10,
			10.062,
			10.123,
			10.187,
			10.249,
			10.311,
			10.374,
			10.406,
			10.436,
			10.5,
			10.515,
			10.562,
			10.624,
			10.687,
			10.749,
			10.811,
			10.841,
			10.874,
			10.935,
			10.999,
			11.061,
			11.122,
			11.185,
			11.279,
			11.308,
			11.388,
			11.434,
			11.498,
			11.558,
			11.621,
			11.685,
			11.715,
			11.748,
			11.81,
			11.872,
			11.934,
			11.997,
			12.06,
			12.122,
			12.152,
			12.184,
			12.244,
			12.261,
			12.307,
			12.372,
			12.432,
			12.496,
			12.589,
			12.619,
			12.683,
			12.698,
			12.744,
			12.807,
			12.869,
			12.933,
			12.994,
			13.057,
			13.136,
			13.182,
			13.246,
			13.307,
			13.353,
			13.368,
			13.431,
			13.494,
			13.57,
			13.617,
			13.679,
			13.743,
			13.805,
			13.869,
			13.931,
			14.006,
			14.054,
			14.121,
			14.18,
			14.227,
			14.243,
			14.304,
			14.368,
			14.445,
			14.491,
			14.554,
			14.616,
			14.677,
			14.772,
			14.804,
			14.879,
			14.927,
			14.989,
			15.052,
			15.116,
			15.207,
			15.241,
			15.303,
			15.318,
			15.362,
			15.427,
			15.488,
			15.552,
			15.646,
			15.676,
			15.737,
			15.802,
			15.865,
			15.926,
			15.989,
			16.049,
			16.082,
			16.112,
			16.175,
			16.195,
			16.238,
			16.299,
			16.362,
			16.426,
			16.487,
			16.518,
			16.549,
			16.612,
			16.673,
			16.736,
			16.8,
			16.844,
			16.862,
			16.924,
			16.955,
			16.987,
			17.065,
			17.11,
			17.172,
			17.236,
			17.282,
			17.298,
			17.36,
			17.392,
			17.422,
			17.501,
			17.548,
			17.609,
			17.671,
			17.734,
			17.798,
			17.858,
			17.937,
			17.984,
			18.045,
			18.109,
			18.154,
			18.172,
			18.234,
			18.268,
			18.297,
			18.359,
			18.373,
			18.42,
			18.483,
			18.544,
			18.593,
			18.67,
			18.734,
			18.795,
			18.811,
			18.857,
			18.922,
			18.982,
			19.043,
			19.107,
			19.137,
			19.168,
			19.248,
			19.293,
			19.357,
			19.419,
			19.48,
			19.543,
			19.574,
			19.605,
			19.683,
			19.732,
			19.793,
			19.855,
			19.902,
			19.917,
			19.981,
			20.011,
			20.041,
			20.12,
			20.167,
			20.231,
			20.292,
			20.353,
			20.448,
			20.478,
			20.543,
			20.603,
			20.669,
			20.727,
			20.775,
			20.791,
			20.854,
			20.885,
			20.916,
			20.979,
			20.993,
			21.04,
			21.103,
			21.166,
			21.211,
			21.289,
			21.351,
			21.415,
			21.478,
			21.541,
			21.602,
			21.665,
			21.758,
			21.789,
			21.853,
			21.868,
			21.914,
			21.976,
			22.039,
			22.086,
			22.165,
			22.194,
			22.226,
			22.303,
			22.35,
			22.417,
			22.476,
			22.522,
			22.539,
			22.6,
			22.661,
			22.741,
			22.786,
			22.851,
			22.911,
			22.973,
			23.069,
			23.1,
			23.161,
			23.224,
			23.286,
			23.349,
			23.412,
			23.472,
			23.503,
			23.537,
			23.612,
			23.66,
			23.722,
			23.786,
			23.848,
			23.91,
			23.942,
			23.973,
			24.051,
			24.098,
			24.161,
			24.222,
			24.285,
			24.347,
			24.379,
			24.409,
			24.491,
			24.534,
			24.598,
			24.659,
			24.704,
			24.721,
			24.784,
			24.815,
			24.846,
			24.923,
			24.971,
			25.034,
			25.095,
			25.158,
			25.221,
			25.25,
			25.284,
			25.344,
			25.36,
			25.407,
			25.47,
			25.533,
			25.594,
			25.686,
			25.718,
			25.782,
			25.798,
			25.845,
			25.906,
			25.969,
			26.015,
			26.126,
			26.156,
			26.217,
			26.281,
			26.344,
			26.405,
			26.467,
			26.529,
			26.565,
			26.591,
			26.655,
			26.669,
			26.717,
			26.779,
			26.843,
			26.904,
			26.967,
			26.998,
			27.027,
			27.107,
			27.155,
			27.216,
			27.278,
			27.34,
			27.433,
			27.467,
			27.529,
			27.544,
			27.589,
			27.652,
			27.716,
			27.779,
			27.839,
			27.871,
			27.904,
			27.979,
			28.028,
			28.089,
			28.152,
			28.216,
			28.308,
			28.34,
			28.401,
			28.417,
			28.465,
			28.527,
			28.589,
			28.651,
			28.744,
			28.777,
			28.837,
			28.902,
			28.965,
			29.026,
			29.089,
			29.15,
			29.182,
			29.213,
			29.291,
			29.336,
			29.401,
			29.462,
			29.525,
			29.62,
			29.65,
			29.711,
			29.776,
			29.837,
			29.899,
			29.945,
			29.961,
			30.056,
			30.086,
			30.15,
			30.165,
			30.21,
			30.275,
			30.337,
			30.383,
			30.4,
			30.461,
			30.522,
			30.602,
			30.648,
			30.715,
			30.774,
			30.834,
			30.899,
			30.961,
			31.039,
			31.086,
			31.149,
			31.209,
			31.271,
			31.366,
			31.398,
			31.459,
			31.473,
			31.522,
			31.584,
			31.646,
			31.693,
			31.771,
			31.832,
			31.912,
			31.957,
			32.02,
			32.083,
			32.128,
			32.146,
			32.206,
			32.27,
			32.348,
			32.395,
			32.458,
			32.52,
			32.583,
			32.645,
			32.706,
			32.788,
			32.833,
			32.896,
			32.957,
			33.02,
			33.081,
			33.143,
			33.221,
			33.269,
			33.332,
			33.393,
			33.455,
			33.55,
			33.581,
			33.658,
			33.706,
			33.767,
			33.831,
			33.892,
			33.955,
			34.018,
			34.094,
			34.142,
			34.204,
			34.266,
			34.314,
			34.39,
			34.455,
			34.532,
			34.579,
			34.641,
			34.702,
			34.749,
			34.765,
			34.828,
			34.89,
			34.969,
			35.016,
			35.079,
			35.139,
			35.187,
			35.266,
			35.295,
			35.328,
			35.388,
			35.45,
			35.515,
			35.577,
			35.624,
			35.639,
			35.701,
			35.764,
			35.843,
			35.887,
			35.952,
			36.012,
			36.076,
			36.137,
			36.2,
			36.279,
			36.323,
			36.389,
			36.449,
			36.513,
			36.604,
			36.636,
			36.716,
			36.762,
			36.825,
			36.886,
			36.936,
			37.011,
			37.043,
			37.074,
			37.151,
			37.199,
			37.261,
			37.323,
			37.386,
			37.48,
			37.511,
			37.589,
			37.635,
			37.699,
			37.761,
			37.806,
			37.821,
			37.884,
			37.916,
			37.946,
			38.026,
			38.071,
			38.135,
			38.197,
			38.243,
			38.321,
			38.353,
			38.385,
			38.461,
			38.508,
			38.572,
			38.634,
			38.679,
			38.694,
			38.758,
			38.821,
			38.882,
			38.899,
			38.944,
			39.012,
			39.069,
			39.116,
			39.195,
			39.226,
			39.256,
			39.32,
			39.383,
			39.444,
			39.506,
			39.554,
			39.569,
			39.633,
			39.661,
			39.695,
			39.756,
			39.771,
			39.819,
			39.882,
			39.943,
			40.007,
			40.067,
			40.1,
			40.131,
			40.194,
			40.209,
			40.255,
			40.318,
			40.38,
			40.428,
			40.444,
			40.505,
			40.536,
			40.568,
			40.644,
			40.693,
			40.755,
			40.818,
			40.878,
			40.973,
			41.003,
			41.067,
			41.087,
			41.128,
			41.19,
			41.254,
			41.3,
			41.317,
			41.408,
			41.441,
			41.503,
			41.52,
			41.565,
			41.627,
			41.69,
			41.752,
			41.845,
			41.876,
			41.941,
			42.001,
			42.065,
			42.127,
			42.188,
			42.25,
			42.284,
			42.312,
			42.391,
			42.439,
			42.502,
			42.563,
			42.624,
			42.687,
			42.751,
			42.812,
			42.876,
			42.938,
			42.999,
			43.063,
			43.123,
			43.161,
			43.186,
			43.25,
			43.265,
			43.311,
			43.375,
			43.436,
			43.484,
			43.499,
			43.561,
			43.591,
			43.624,
			43.687,
			43.746,
			43.811,
			43.874,
			43.936,
			43.998,
			44.029,
			44.06,
			44.121,
			44.136,
			44.185,
			44.248,
			44.309,
			44.371,
			44.434,
			44.466,
			44.497,
			44.573,
			44.622,
			44.683,
			44.746,
			44.808,
			44.871,
			44.901,
			44.932,
			45.011,
			45.058,
			45.121,
			45.183,
			45.245,
			45.337,
			45.369,
			45.432,
			45.447,
			45.495,
			45.558,
			45.619,
			45.667,
			45.743,
			45.804,
			45.885,
			45.931,
			45.994,
			46.054,
			46.101,
			46.117,
			46.181,
			46.242,
			46.321,
			46.368,
			46.431,
			46.493,
			46.553,
			46.618,
			46.68,
			46.756,
			46.804,
			46.866,
			46.927,
			46.992,
			47.053,
			47.117,
			47.193,
			47.242,
			47.306,
			47.366,
			47.429,
			47.521,
			47.553,
			47.63,
			47.679,
			47.741,
			47.802,
			47.849,
			47.866,
			47.927,
			47.958,
			47.99,
			48.069,
			48.115,
			48.181,
			48.238,
			48.301,
			48.364,
			48.427,
			48.505,
			48.551,
			48.614,
			48.676,
			48.739,
			48.833,
			48.862,
			48.942,
			48.987,
			49.05,
			49.112,
			49.175,
			49.268,
			49.301,
			49.362,
			49.425,
			49.487,
			49.55,
			49.594,
			49.612,
			49.674,
			49.737,
			49.816,
			49.862,
			49.925,
			49.987,
			50.049,
			50.11,
			50.143,
			50.173,
			50.236,
			50.297,
			50.361,
			50.423,
			50.47,
			50.485,
			50.546,
			50.611,
			50.689,
			50.734,
			50.799,
			50.86,
			50.922,
			50.985,
			51.015,
			51.046,
			51.125,
			51.171,
			51.234,
			51.295,
			51.343,
			51.42,
			51.456,
			51.483,
			51.545,
			51.562,
			51.607,
			51.67,
			51.733,
			51.778,
			51.795,
			51.856,
			51.92,
			51.999,
			52.045,
			52.108,
			52.17,
			52.231,
			52.294,
			52.323,
			52.357,
			52.433,
			52.479,
			52.544,
			52.607,
			52.653,
			52.669,
			52.729,
			52.761,
			52.792,
			52.869,
			52.918,
			52.981,
			53.042,
			53.088,
			53.198,
			53.229,
			53.293,
			53.354,
			53.416,
			53.479,
			53.54,
			53.604,
			53.635,
			53.667,
			53.745,
			53.791,
			53.852,
			53.916,
			53.962,
			54.039,
			54.07,
			54.102,
			54.164,
			54.228,
			54.29,
			54.351,
			54.414,
			54.477,
			54.538,
			54.602,
			54.617,
			54.662,
			54.727,
			54.789,
			54.836,
			54.913,
			54.944,
			54.976,
			55.053,
			55.101,
			55.163,
			55.226,
			55.273,
			55.287,
			55.349,
			55.382,
			55.411,
			55.475,
			55.49,
			55.537,
			55.604,
			55.662,
			55.723,
			55.787,
			55.818,
			55.849,
			55.912,
			55.974,
			56.038,
			56.098,
			56.145,
			56.161,
			56.224,
			56.253,
			56.287,
			56.365,
			56.41,
			56.473,
			56.535,
			56.597,
			56.69,
			56.723,
			56.783,
			56.8,
			56.848,
			56.914,
			56.971,
			57.034,
			57.095,
			57.128,
			57.159,
			57.22,
			57.236,
			57.283,
			57.347,
			57.407,
			57.471,
			57.534,
			57.564,
			57.595,
			57.658,
			57.678,
			57.719,
			57.784,
			57.846,
			57.907,
			57.969,
			58.001,
			58.033,
			58.094,
			58.11,
			58.157,
			58.22,
			58.281,
			58.343,
			58.406,
			58.436,
			58.47,
			58.532,
			58.546,
			58.594,
			58.657,
			58.718,
			58.782,
			58.874,
			58.905,
			58.967,
			59.03,
			59.096,
			59.154,
			59.201,
			59.218,
			59.278,
			59.311,
			59.343,
			59.405,
			59.42,
			59.467,
			59.529,
			59.592,
			59.653,
			59.716,
			59.778,
			59.841,
			59.904,
			59.966,
			60.028,
			60.09,
			60.152,
			60.185,
			60.215,
			60.294,
			60.339,
			60.404,
			60.465,
			60.527,
			60.621,
			60.653,
			60.73,
			60.776,
			60.84,
			60.901,
			60.964,
			61.026,
			61.058,
			61.089,
			61.15,
			61.166,
			61.214,
			61.277,
			61.339,
			61.384,
			61.399,
			61.464,
			61.493,
			61.526,
			61.604,
			61.65,
			61.714,
			61.775,
			61.826,
			61.838,
			61.9,
			61.93,
			61.961,
			62.024,
			62.04,
			62.087,
			62.15,
			62.213,
			62.274,
			62.336,
			62.399,
			62.461,
			62.523,
			62.587,
			62.648,
			62.71,
			62.805,
			62.835,
			62.898,
			62.912,
			62.96,
			63.022,
			63.085,
			63.145,
			63.209,
			63.239,
			63.272,
			63.349,
			63.396,
			63.46,
			63.521,
			63.582,
			63.646,
			63.678,
			63.709,
			63.769,
			63.788,
			63.832,
			63.901,
			63.959,
			64.021,
			64.083,
			64.146,
			64.222,
			64.271,
			64.332,
			64.394,
			64.441,
			64.519,
			64.55,
			64.582,
			64.645,
			64.66,
			64.707,
			64.769,
			64.831,
			64.894,
			64.987,
			65.019,
			65.096,
			65.143,
			65.206,
			65.267,
			65.315,
			65.423,
			65.455,
			65.534,
			65.58,
			65.642,
			65.706,
			65.768,
			65.828,
			65.892,
			65.973,
			66.017,
			66.078,
			66.142,
			66.205,
			66.298,
			66.328,
			66.405,
			66.454,
			66.517,
			66.578,
			66.639,
			66.703,
			66.766,
			66.843,
			66.889,
			66.953,
			67.015,
			67.076,
			67.139,
			67.203,
			67.28,
			67.327,
			67.39,
			67.452,
			67.514,
			67.577,
			67.608,
			67.638,
			67.7,
			67.715,
			67.762,
			67.827,
			67.888,
			67.936,
			68.012,
			68.076,
			68.154,
			68.2,
			68.263,
			68.324,
			68.371,
			68.388,
			68.45,
			68.511,
			68.591,
			68.637,
			68.701,
			68.761,
			68.808,
			68.823,
			68.885,
			68.918,
			68.949,
			69.026,
			69.072,
			69.136,
			69.199,
			69.245,
			69.261,
			69.322,
			69.385,
			69.464,
			69.51,
			69.572,
			69.636,
			69.695,
			69.76,
			69.821,
			69.899,
			69.947,
			70.008,
			70.07,
			70.132,
			70.196,
			70.257,
			70.319,
			70.336,
			70.383,
			70.445,
			70.506,
			70.554,
			70.57,
			70.632,
			70.663,
			70.695,
			70.772,
			70.943,
			71.005,
			71.1,
			71.131,
			71.194,
			71.255,
			71.317,
			71.379,
			71.443,
			71.537,
			71.566,
			71.63,
			71.645,
			71.693,
			71.755,
			71.818,
			71.878,
			71.941,
			72.005,
			72.082,
			72.129,
			72.195,
			72.253,
			72.316,
			72.409,
			72.439,
			72.503,
			72.519,
			72.565,
			72.628,
			72.69,
			72.751,
			72.846,
			72.877,
			72.955,
			73.002,
			73.065,
			73.126,
			73.19,
			73.25,
			73.283,
			73.312,
			73.39,
			73.437,
			73.499,
			73.564,
			73.625,
			73.688,
			73.749,
			73.829,
			73.874,
			73.938,
			74,
			74.061,
			74.155,
			74.188,
			74.269,
			74.312,
			74.374,
			74.437,
			74.499,
			74.561,
			74.624,
			74.702,
			74.748,
			74.81,
			74.873,
			74.934,
			75.027,
			75.06,
			75.121,
			75.138,
			75.184,
			75.248,
			75.31,
			75.371,
			75.464,
			75.498,
			75.56,
			75.621,
			75.683,
			75.747,
			75.81,
			75.902,
			75.934,
			76.01,
			76.058,
			76.122,
			76.183,
			76.246,
			76.342,
			76.37,
			76.432,
			76.448,
			76.495,
			76.557,
			76.62,
			76.682,
			76.776,
			76.806,
			76.885,
			76.931,
			76.995,
			77.057,
			77.118,
			77.182,
			77.212,
			77.243,
			77.32,
			77.367,
			77.431,
			77.494,
			77.555,
			77.65,
			77.68,
			77.759,
			77.806,
			77.867,
			77.928,
			77.992,
			78.085,
			78.116,
			78.177,
			78.194,
			78.242,
			78.303,
			78.367,
			78.43,
			78.523,
			78.553,
			78.632,
			78.679,
			78.742,
			78.803,
			78.849,
			78.866,
			78.927,
			78.959,
			78.989,
			79.068,
			79.115,
			79.179,
			79.239,
			79.303,
			79.397,
			79.427,
			79.49,
			79.551,
			79.616,
			79.676,
			79.738,
			79.832,
			79.864,
			79.942,
			79.989,
			80.052,
			80.113,
			80.175,
			80.238,
			80.301,
			80.379,
			80.424,
			80.49,
			80.549,
			80.597,
			80.612,
			80.673,
			80.737,
			80.815,
			80.861,
			80.924,
			80.985,
			81.032,
			81.049,
			81.111,
			81.141,
			81.172,
			81.251,
			81.299,
			81.361,
			81.422,
			81.47,
			81.486,
			81.579,
			81.609,
			81.672,
			81.687,
			81.733,
			81.798,
			81.86,
			81.922,
			81.985,
			82.046,
			82.124,
			82.17,
			82.233,
			82.295,
			82.344,
			82.421,
			82.484,
			82.565,
			82.608,
			82.67,
			82.732,
			82.78,
			82.795,
			82.889,
			82.919,
			82.983,
			82.996,
			83.044,
			83.107,
			83.168,
			83.232,
			83.325,
			83.355,
			83.419,
			83.434,
			83.481,
			83.543,
			83.606,
			83.652,
			83.669,
			83.732,
			83.762,
			83.792,
			83.871,
			83.919,
			83.979,
			84.044,
			84.089,
			84.167,
			84.23,
			84.291,
			84.354,
			84.417,
			84.478,
			84.542,
			84.603,
			84.639,
			84.667,
			84.728,
			84.743,
			84.792,
			84.855,
			84.917,
			84.979,
			85.041,
			85.072,
			85.104,
			85.181,
			85.227,
			85.291,
			85.352,
			85.399,
			85.416,
			85.478,
			85.54,
			85.602,
			85.619,
			85.665,
			85.728,
			85.79,
			85.836,
			85.915,
			85.944,
			85.977,
			86.038,
			86.102,
			86.166,
			86.227,
			86.289,
			86.383,
			86.414,
			86.493,
			86.539,
			86.601,
			86.663,
			86.724,
			86.788,
			86.819,
			86.851,
			86.911,
			86.975,
			87.037,
			87.101,
			87.161,
			87.224,
			87.255,
			87.287,
			87.349,
			87.366,
			87.413,
			87.476,
			87.535,
			87.582,
			87.599,
			87.662,
			87.694,
			87.725,
			87.785,
			87.848,
			87.911,
			87.974,
			88.036,
			88.13,
			88.159,
			88.221,
			88.239,
			88.286,
			88.349,
			88.411,
			88.473,
			88.534,
			88.566,
			88.596,
			88.674,
			88.722,
			88.789,
			88.848,
			88.908,
			88.97,
			89.001,
			89.033,
			89.095,
			89.111,
			89.157,
			89.22,
			89.283,
			89.345,
			89.409,
			89.439,
			89.469,
			89.532,
			89.549,
			89.596,
			89.658,
			89.72,
			89.766,
			89.844,
			89.876,
			89.907,
			89.986,
			90.033,
			90.095,
			90.155,
			90.219,
			90.311,
			90.342,
			90.421,
			90.469,
			90.532,
			90.593,
			90.654,
			90.717,
			90.75,
			90.779,
			90.843,
			90.861,
			90.904,
			90.968,
			91.028,
			91.077,
			91.092,
			91.155,
			91.186,
			91.217,
			91.294,
			91.342,
			91.404,
			91.467,
			91.513,
			91.589,
			91.623,
			91.654,
			91.716,
			91.778,
			91.841,
			91.902,
			91.966,
			92.026,
			92.058,
			92.09,
			92.151,
			92.167,
			92.213,
			92.278,
			92.338,
			92.402,
			92.464,
			92.495,
			92.525,
			92.603,
			92.651,
			92.715,
			92.774,
			92.837,
			92.899,
			92.934,
			92.963,
			93.042,
			93.088,
			93.151,
			93.211,
			93.275,
			93.368,
			93.399,
			93.462,
			93.524,
			93.587,
			93.65,
			93.696,
			93.712,
			93.804,
			93.835,
			93.899,
			93.915,
			93.96,
			94.024,
			94.085,
			94.148,
			94.211,
			94.241,
			94.272,
			94.351,
			94.398,
			94.461,
			94.522,
			94.568,
			94.585,
			94.647,
			94.709,
			94.787,
			94.833,
			94.896,
			94.958,
			95.019,
			95.083,
			95.146,
			95.207,
			95.225,
			95.271,
			95.332,
			95.396,
			95.456,
			95.552,
			95.583,
			95.66,
			95.707,
			95.771,
			95.832,
			95.878,
			95.893,
			95.955,
			96.018,
			96.097,
			96.143,
			96.208,
			96.267,
			96.316,
			96.394,
			96.424,
			96.454,
			96.534,
			96.58,
			96.643,
			96.703,
			96.767,
			96.829,
			96.861,
			96.892,
			96.955,
			96.97,
			97.016,
			97.081,
			97.143,
			97.187,
			97.267,
			97.329,
			97.408,
			97.454,
			97.516,
			97.577,
			97.626,
			97.642,
			97.704,
			97.766,
			97.843,
			97.889,
			97.952,
			98.015,
			98.06,
			98.139,
			98.171,
			98.201,
			98.265,
			98.327,
			98.388,
			98.452,
			98.498,
			98.514,
			98.575,
			98.607,
			98.639,
			98.716,
			98.763,
			98.827,
			98.887,
			98.95,
			99.011,
			99.074,
			99.157,
			99.2,
			99.262,
			99.325,
			99.385,
			99.48,
			99.512,
			99.589,
			99.636,
			99.699,
			99.76,
			99.807,
			99.822,
			99.887,
			99.916,
			99.948,
			100.027,
			100.071,
			100.135,
			100.197,
			100.26,
			100.321,
			100.384,
			100.461,
			100.51,
			100.571,
			100.635,
			100.697,
			100.759,
			100.82,
			100.898,
			100.946,
			101.007,
			101.07,
			101.116,
			101.195,
			101.257,
			101.336,
			101.382,
			101.445,
			101.506,
			101.57,
			101.632,
			101.694,
			101.772,
			101.82,
			101.882,
			101.945,
			102.006,
			102.099,
			102.132,
			102.208,
			102.255,
			102.318,
			102.379,
			102.427,
			102.442,
			102.504,
			102.568,
			102.645,
			102.692,
			102.754,
			102.817,
			102.878,
			102.942,
			103.002,
			103.083,
			103.129,
			103.192,
			103.254,
			103.316,
			103.379,
			103.41,
			103.439,
			103.518,
			103.564,
			103.629,
			103.69,
			103.736,
			103.816,
			103.877,
			103.956,
			104.001,
			104.065,
			104.127,
			104.173,
			104.19,
			104.253,
			104.314,
			104.391,
			104.437,
			104.502,
			104.564,
			104.611,
			104.719,
			104.75,
			104.828,
			104.875,
			104.94,
			105.001,
			105.048,
			105.062,
			105.126,
			105.156,
			105.188,
			105.25,
			105.267,
			105.312,
			105.377,
			105.438,
			105.498,
			105.563,
			105.594,
			105.624,
			105.687,
			105.75,
			105.81,
			105.873,
			105.919,
			105.937,
			105.999,
			106.03,
			106.06,
			106.124,
			106.138,
			106.186,
			106.25,
			106.311,
			106.358,
			106.373,
			106.436,
			106.497,
			106.574,
			106.622,
			106.685,
			106.748,
			106.808,
			106.871,
			106.903,
			106.934,
			106.998,
			107.012,
			107.059,
			107.121,
			107.184,
			107.245,
			107.307,
			107.341,
			107.371,
			107.452,
			107.496,
			107.558,
			107.621,
			107.667,
			107.746,
			107.777,
			107.807,
			107.886,
			107.931,
			107.995,
			108.058,
			108.119,
			108.182,
			108.214,
			108.243,
			108.307,
			108.322,
			108.369,
			108.43,
			108.493,
			108.541,
			108.619,
			108.65,
			108.679,
			108.758,
			108.804,
			108.869,
			108.928,
			108.977,
			108.993,
			109.055,
			109.085,
			109.116,
			109.18,
			109.195,
			109.241,
			109.304,
			109.367,
			109.429,
			109.461
		]
	],
	'9176_9324_7982': [
		[
			113.297
		],
		[
			0
		],
		[
			0,
			2.158,
			5.21,
			9.799,
			12.865,
			15.938,
			20.558,
			23.647,
			29.842,
			31.395,
			34.504,
			36.061,
			39.178,
			42.3,
			46.993,
			50.129,
			56.418,
			57.993,
			64.306,
			78.582,
			81.766,
			86.553,
			89.748,
			91.348,
			94.552,
			97.762,
			102.588,
			105.81,
			110.648,
			113.878,
			120.349,
			121.97,
			126.837,
			130.086,
			134.968,
			141.502,
			143.14,
			148.059,
			151.343,
			154.632,
			159.574,
			162.874,
			167.834,
			171.147,
			176.123,
			179.446,
			184.432,
			187.747,
			191.043,
			192.681,
			195.933,
			199.151,
			200.747,
			205.477,
			208.583,
			211.652,
			216.188,
			219.169,
			223.572,
			226.461,
			230.723,
			233.517,
			237.636,
			240.335,
			244.312,
			246.917,
			249.485,
			250.755,
			254.51,
			256.967,
			259.388,
			262.958,
			265.298,
			268.745,
			269.876,
			270.998,
			274.306,
			276.466,
			279.635,
			281.702,
			283.735,
			284.738,
			286.716,
			289.618,
			292.456,
			294.294,
			296.103,
			298.77,
			300.519,
			303.102,
			303.953,
			304.801,
			307.33,
			309.003,
			311.51,
			313.177,
			314.842,
			315.676,
			317.344,
			318.178,
			319.846,
			322.356,
			324.051,
			325.746,
			328.332,
			329.207,
			330.083,
			332.742,
			334.587,
			337.376,
			339.323,
			342.262,
			344.283,
			347.33,
			349.38,
			350.408,
			352.464,
			355.516,
			357.547,
			359.54,
			362.524,
			363.496,
			364.467,
			367.379,
			368.333,
			369.285,
			372.14,
			374.023,
			375.901,
			378.697,
			380.546,
			383.307,
			385.131,
			387.858,
			388.754,
			389.65,
			392.333,
			394.102,
			396.751,
			398.516,
			400.28,
			401.163,
			403.822,
			405.604,
			407.389,
			410.097,
			411.911,
			414.661,
			415.585,
			416.511,
			419.315,
			421.203,
			424.065,
			425.993,
			427.936,
			428.915,
			430.883,
			433.843,
			436.851,
			438.871,
			440.904,
			443.976,
			446.039,
			449.156,
			450.201,
			451.247,
			454.384,
			456.481,
			459.647,
			461.771,
			463.909,
			464.983,
			467.14,
			469.31,
			470.4,
			473.687,
			475.892,
			478.108,
			481.449,
			483.69,
			487.069,
			489.336,
			492.754,
			495.045,
			498.5,
			500.818,
			503.146,
			504.315,
			506.663,
			507.84,
			510.205,
			513.773,
			516.165,
			518.568,
			522.188,
			524.613,
			528.267,
			529.49,
			530.714,
			534.403,
			536.875,
			540.602,
			543.099,
			545.605,
			546.862,
			549.383,
			553.183,
			557.003,
			559.561,
			562.128,
			565.994,
			568.582,
			572.48,
			573.783,
			575.085,
			578.997,
			581.614,
			585.557,
			588.196,
			590.844,
			592.17,
			594.829,
			596.16,
			598.851,
			602.865,
			605.545,
			608.229,
			610.915,
			613.603,
			614.948,
			617.641,
			620.315,
			621.667,
			625.755,
			628.475,
			632.574,
			635.317,
			638.07,
			642.217,
			643.603,
			644.993,
			650.585,
			653.398,
			656.223,
			659.059,
			663.33,
			669.057,
			670.494,
			673.373,
			674.816,
			677.711,
			680.617,
			682.076,
			685.001,
			687.942,
			693.848,
			695.329,
			701.266,
			702.755,
			707.226,
			710.208,
			714.675,
			720.623,
			722.109,
			725.078,
			726.562,
			729.529,
			732.493,
			736.935,
			739.897,
			745.833,
			747.317,
			751.773,
			754.747,
			759.215,
			762.199,
			766.683,
			772.677,
			774.177,
			778.685,
			781.694,
			784.705,
			789.225,
			792.24,
			796.766,
			799.791,
			804.331,
			807.359,
			811.902,
			814.935,
			819.491,
			822.531,
			824.052,
			827.094,
			831.664,
			834.713,
			837.738,
			842.314,
			845.365,
			849.941,
			851.467,
			852.992,
			857.57,
			860.626,
			865.211,
			868.268,
			871.317,
			872.836,
			875.867,
			880.384,
			884.849,
			887.79,
			890.703,
			895.034,
			897.872,
			902.101,
			903.508,
			904.914,
			909.123,
			911.923,
			916.103,
			918.908,
			921.74,
			923.146,
			925.959,
			928.772,
			930.18,
			934.4,
			937.211,
			940.016,
			944.211,
			946.997,
			951.161,
			952.546,
			953.93,
			958.08,
			960.848,
			964.993,
			967.753,
			971.896,
			976.051,
			978.829,
			981.611,
			983.004,
			985.788,
			988.576,
			992.752,
			995.537,
			999.72,
			1001.116,
			1002.515,
			1006.72,
			1009.531,
			1013.752,
			1016.571,
			1020.81,
			1025.06,
			1027.872,
			1032.156,
			1035,
			1037.847,
			1040.672,
			1044.95,
			1050.663,
			1052.093,
			1054.957,
			1056.39,
			1059.258,
			1062.131,
			1066.452,
			1069.34,
			1073.684,
			1075.135,
			1076.588,
			1080.957,
			1082.416,
			1083.877,
			1088.276,
			1091.218,
			1095.651,
			1098.62,
			1101.599,
			1103.092,
			1107.586,
			1110.592,
			1113.604,
			1118.133,
			1121.16,
			1127.237,
			1128.761,
			1133.344,
			1134.875,
			1136.407,
			1141.013,
			1144.089,
			1148.715,
			1151.806,
			1154.9,
			1156.449,
			1159.549,
			1161.102,
			1164.209,
			1167.321,
			1172,
			1175.152,
			1179.851,
			1181.42,
			1182.993,
			1187.72,
			1190.881,
			1195.61,
			1198.789,
			1203.594,
			1209.978,
			1211.576,
			1214.775,
			1216.376,
			1219.581,
			1222.789,
			1227.578,
			1230.821,
			1235.646,
			1237.255,
			1238.865,
			1243.699,
			1246.898,
			1251.775,
			1255.016,
			1259.887,
			1263.137,
			1264.761,
			1267.987,
			1272.873,
			1276.136,
			1279.431,
			1284.313,
			1287.592,
			1294.16,
			1295.802,
			1300.733,
			1304.023,
			1308.964,
			1312.288,
			1317.237,
			1320.54,
			1322.164,
			1325.47,
			1328.778,
			1330.433,
			1333.744,
			1337.055,
			1342.025,
			1345.341,
			1351.979,
			1353.64,
			1358.623,
			1361.947,
			1366.937,
			1370.266,
			1375.262,
			1380.265,
			1383.602,
			1386.943,
			1388.615,
			1391.96,
			1395.308,
			1400.336,
			1403.692,
			1408.733,
			1412.099,
			1417.154,
			1420.528,
			1425.597,
			1428.98,
			1434.065,
			1439.159,
			1442.561,
			1447.666,
			1451.073,
			1454.483,
			1459.603,
			1463.021,
			1469.865,
			1471.578,
			1476.722,
			1480.155,
			1485.313,
			1488.755,
			1493.924,
			1497.373,
			1500.825,
			1507.74,
			1511.201,
			1514.664,
			1519.864,
			1523.334,
			1530.285,
			1532.025,
			1537.249,
			1540.735,
			1544.224,
			1549.464,
			1552.961,
			1558.213,
			1561.718,
			1568.736,
			1570.493,
			1575.766,
			1579.284,
			1584.567,
			1588.093,
			1593.386,
			1598.686,
			1602.223,
			1605.763,
			1611.077,
			1614.624,
			1619.949,
			1623.503,
			1630.617,
			1632.397,
			1637.743,
			1641.312,
			1644.884,
			1646.67,
			1650.245,
			1653.824,
			1655.614,
			1660.99,
			1664.577,
			1668.167,
			1673.556,
			1677.153,
			1682.551,
			1684.351,
			1686.152,
			1691.557,
			1695.162,
			1700.572,
			1704.182,
			1707.794,
			1709.602,
			1713.218,
			1718.647,
			1724.082,
			1727.709,
			1731.34,
			1736.792,
			1740.429,
			1745.89,
			1747.71,
			1749.528,
			1754.969,
			1758.574,
			1763.926,
			1767.446,
			1770.926,
			1772.651,
			1776.073,
			1777.769,
			1781.129,
			1786.102,
			1789.375,
			1792.607,
			1797.388,
			1800.529,
			1805.168,
			1808.213,
			1812.713,
			1815.667,
			1820.032,
			1822.897,
			1827.125,
			1829.894,
			1831.263,
			1833.968,
			1837.95,
			1840.553,
			1843.117,
			1846.894,
			1849.365,
			1853.001,
			1854.196,
			1855.382,
			1858.89,
			1861.187,
			1864.587,
			1866.799,
			1868.978,
			1870.055,
			1872.182,
			1873.23,
			1875.285,
			1878.347,
			1880.33,
			1882.285,
			1885.175,
			1887.08,
			1889.91,
			1890.849,
			1891.787,
			1894.616,
			1896.502,
			1899.413,
			1901.378,
			1903.405,
			1906.571,
			1907.627,
			1908.706,
			1913.016,
			1914.043,
			1917.197,
			1920.208,
			1921.2,
			1922.194,
			1924.143,
			1927.023,
			1928.952,
			1929.914,
			1931.845,
			1933.782,
			1936.697,
			1938.652,
			1941.6,
			1942.588,
			1943.579,
			1947.579,
			1948.588,
			1951.646,
			1953.702,
			1955.821,
			1956.879,
			1959.043,
			1961.3,
			1962.425,
			1965.872,
			1968.186,
			1970.489,
			1973.92,
			1976.105,
			1979.349,
			1980.381,
			1981.412,
			1984.489,
			1986.491,
			1989.497,
			1991.5,
			1994.507,
			1996.519,
			1998.532,
			1999.54,
			2002.572,
			2004.594,
			2006.62,
			2009.657,
			2011.681,
			2014.717,
			2015.728,
			2016.738,
			2019.768,
			2021.785,
			2024.81,
			2026.83,
			2029.867,
			2031.941,
			2032.979,
			2035.084,
			2037.307,
			2038.42,
			2040.688,
			2043.015,
			2046.456,
			2048.716,
			2051.949,
			2053.005,
			2054.063,
			2057.128,
			2059.159,
			2062.22,
			2064.27,
			2066.347,
			2067.397,
			2070.568,
			2072.725,
			2074.892,
			2075.987,
			2078.199,
			2080.422,
			2083.815,
			2086.116,
			2089.593,
			2090.759,
			2091.936,
			2095.477,
			2097.867,
			2101.463,
			2103.873,
			2107.493,
			2109.906,
			2111.112,
			2113.495,
			2117.047,
			2119.375,
			2121.689,
			2125.1,
			2127.358,
			2131.837,
			2132.95,
			2136.287,
			2138.51,
			2141.854,
			2144.094,
			2147.471,
			2150.874,
			2153.156,
			2155.447,
			2156.595,
			2157.746,
			2161.203,
			2164.656,
			2166.947,
			2169.221,
			2171.474,
			2172.593,
			2175.92,
			2178.125,
			2180.308,
			2183.632,
			2185.837,
			2189.139,
			2191.335,
			2195.709,
			2196.8,
			2200.068,
			2202.244,
			2205.509,
			2209.863,
			2210.952,
			2213.128,
			2214.217,
			2216.392,
			2218.565,
			2221.831,
			2224.012,
			2227.292,
			2228.387,
			2229.486,
			2232.787,
			2233.891,
			2234.999,
			2238.331,
			2240.565,
			2243.921,
			2248.399,
			2249.519,
			2251.754,
			2252.867,
			2255.084,
			2257.284,
			2260.539,
			2262.678,
			2265.834,
			2266.875,
			2267.91,
			2271.004,
			2273.062,
			2276.154,
			2278.221,
			2281.333,
			2283.415,
			2285.503,
			2286.549,
			2289.694,
			2291.798,
			2293.906,
			2297.073,
			2299.185,
			2303.41,
			2304.463,
			2307.615,
			2309.708,
			2312.833,
			2314.907,
			2318.001,
			2320.055,
			2321.08,
			2323.124,
			2325.16,
			2326.176,
			2328.203,
			2330.223,
			2333.246,
			2335.252,
			2338.25,
			2339.246,
			2340.241,
			2343.22,
			2345.2,
			2348.168,
			2350.145,
			2352.122,
			2353.111,
			2355.09,
			2356.081,
			2358.067,
			2360.056,
			2361.051,
			2363.047,
			2365.048,
			2368.063,
			2370.083,
			2374.156,
			2375.182,
			2378.277,
			2380.355,
			2383.496,
			2385.604,
			2388.79,
			2390.928,
			2392.002,
			2394.159,
			2397.417,
			2399.607,
			2401.81,
			2405.142,
			2407.378,
			2410.749,
			2413.01,
			2416.42,
			2418.709,
			2422.164,
			2424.482,
			2426.811,
			2430.32,
			2433.851,
			2437.401,
			2438.59,
			2442.169,
			2444.567,
			2448.181,
			2453.019,
			2454.23,
			2457.875,
			2460.312,
			2462.757,
			2466.432,
			2468.892,
			2473.835,
			2475.075,
			2480.057,
			2481.307,
			2485.069,
			2487.588,
			2491.385,
			2496.456,
			2497.733,
			2501.578,
			2504.15,
			2506.729,
			2510.609,
			2513.197,
			2518.393,
			2519.697,
			2523.629,
			2526.262,
			2530.23,
			2532.891,
			2535.56,
			2536.898,
			2539.581,
			2543.619,
			2547.676,
			2550.391,
			2553.116,
			2557.216,
			2559.959,
			2564.081,
			2566.837,
			2570.98,
			2573.75,
			2577.919,
			2580.708,
			2584.904,
			2589.117,
			2591.935,
			2596.177,
			2599.016,
			2601.861,
			2606.146,
			2609.01,
			2613.32,
			2616.203,
			2620.54,
			2623.44,
			2627.805,
			2630.724,
			2633.65,
			2635.115,
			2638.049,
			2642.436,
			2646.775,
			2649.627,
			2652.443,
			2656.596,
			2659.319,
			2664.655,
			2665.967,
			2669.845,
			2672.385,
			2676.127,
			2678.577,
			2682.186,
			2684.551,
			2688.04,
			2691.452,
			2693.681,
			2695.874,
			2698.03,
			2701.199,
			2705.302,
			2706.323,
			2709.284,
			2711.215,
			2714.05,
			2715.899,
			2717.722,
			2721.306,
			2722.194,
			2725.732,
			2726.614,
			2729.258,
			2731.021,
			2732.785,
			2733.668,
			2735.441,
			2738.101,
			2740.778,
			2742.564,
			2744.357,
			2747.054,
			2748.853,
			2751.566,
			2753.373,
			2757.001,
			2757.907,
			2760.632,
			2762.45,
			2765.185,
			2767.016,
			2769.764,
			2772.528,
			2774.37,
			2776.222,
			2779.003,
			2780.866,
			2783.673,
			2785.549,
			2788.379,
			2790.268,
			2793.114,
			2795.011,
			2796.914,
			2797.865,
			2799.767,
			2802.63,
			2805.497,
			2807.412,
			2809.329,
			2812.203,
			2814.116,
			2816.989,
			2818.905,
			2821.781,
			2823.705,
			2826.59,
			2828.527,
			2830.467,
			2831.438,
			2833.392,
			2836.334,
			2839.288,
			2841.263,
			2843.248,
			2846.24,
			2848.243,
			2851.259,
			2853.281,
			2856.324,
			2858.359,
			2861.416,
			2863.456,
			2865.497,
			2866.518,
			2868.561,
			2871.621,
			2874.677,
			2876.712,
			2878.747,
			2881.798,
			2883.832,
			2886.866,
			2888.892,
			2891.907,
			2893.924,
			2896.937,
			2898.959,
			2901.99,
			2905.045,
			2907.09,
			2910.18,
			2912.253,
			2914.327,
			2917.448,
			2918.493,
			2919.542,
			2922.705,
			2923.765,
			2924.828,
			2928.036,
			2930.189,
			2933.44,
			2935.622,
			2937.796,
			2938.897,
			2941.107,
			2944.448,
			2947.815,
			2948.944,
			2952.344,
			2955.768,
			2958.063,
			2962.685,
			2963.847,
			2967.35,
			2969.699,
			2972.059,
			2975.618,
			2978.005,
			2981.604,
			2982.809,
			2984.017,
			2988.875,
			2990.096,
			2993.778,
			2996.245,
			2998.724,
			2999.967,
			3002.46,
			3006.217,
			3009.999,
			3012.532,
			3015.078,
			3018.914,
			3021.483,
			3025.356,
			3026.651,
			3027.948,
			3031.849,
			3034.458,
			3038.371,
			3040.985,
			3043.608,
			3044.922,
			3047.556,
			3050.197,
			3051.521,
			3055.503,
			3058.168,
			3060.842,
			3064.868,
			3067.562,
			3071.618,
			3074.332,
			3078.418,
			3081.152,
			3085.269,
			3088.023,
			3092.17,
			3094.944,
			3097.727,
			3099.12,
			3103.315,
			3106.122,
			3108.936,
			3113.171,
			3116.003,
			3120.268,
			3123.118,
			3127.411,
			3130.28,
			3134.6,
			3137.489,
			3140.384,
			3141.831,
			3144.718,
			3147.585,
			3149.009,
			3153.228,
			3155.994,
			3158.723,
			3162.745,
			3165.379,
			3169.265,
			3170.542,
			3171.81,
			3175.558,
			3178.011,
			3181.621,
			3183.985,
			3187.464,
			3190.861,
			3193.079,
			3195.259,
			3196.335,
			3198.458,
			3200.543,
			3203.597,
			3205.601,
			3208.507,
			3209.458,
			3210.399,
			3213.16,
			3214.965,
			3217.621,
			3219.365,
			3221.092,
			3221.948,
			3223.649,
			3224.495,
			3226.182,
			3228.698,
			3230.373,
			3232.061,
			3234.598,
			3236.3,
			3238.88,
			3239.741,
			3240.604,
			3243.217,
			3244.966,
			3247.611,
			3249.383,
			3252.06,
			3253.861,
			3254.763,
			3256.571,
			3259.322,
			3261.156,
			3263.014,
			3265.808,
			3266.738,
			3267.677,
			3269.551,
			3271.42,
			3272.357,
			3275.169,
			3277.035,
			3279.827,
			3281.678,
			3284.444,
			3286.275,
			3287.187,
			3289,
			3290.802,
			3291.698,
			3293.484,
			3295.257,
			3297.898,
			3299.644,
			3301.381,
			3303.112,
			3303.975,
			3306.548,
			3308.256,
			3310.809,
			3312.511,
			3314.218,
			3316.777,
			3317.632,
			3318.486,
			3321.05,
			3321.906,
			3322.763,
			3325.328,
			3327.041,
			3329.603,
			3333.016,
			3333.867,
			3335.567,
			3336.418,
			3338.119,
			3339.815,
			3342.36,
			3344.056,
			3346.596,
			3347.444,
			3348.293,
			3350.835,
			3351.682,
			3352.53,
			3355.07,
			3356.76,
			3358.45,
			3359.292,
			3360.976,
			3362.659,
			3363.501,
			3366.026,
			3367.709,
			3369.397,
			3371.933,
			3373.627,
			3376.163,
			3377.01,
			3377.858,
			3380.408,
			3382.112,
			3384.685,
			3386.412,
			3389.017,
			3390.768,
			3392.526,
			3393.409,
			3396.079,
			3397.87,
			3399.672,
			3402.396,
			3404.221,
			3406.974,
			3407.895,
			3408.817,
			3411.592,
			3413.451,
			3416.251,
			3418.128,
			3420.966,
			3422.873,
			3424.79,
			3425.757,
			3427.698,
			3428.656,
			3430.613,
			3432.598,
			3435.555,
			3437.569,
			3440.588,
			3441.606,
			3442.628,
			3445.709,
			3447.771,
			3450.888,
			3452.969,
			3456.107,
			3458.209,
			3459.264,
			3461.38,
			3463.508,
			3464.575,
			3466.717,
			3468.87,
			3472.115,
			3474.29,
			3477.568,
			3478.665,
			3479.764,
			3483.076,
			3485.294,
			3488.638,
			3490.879,
			3494.256,
			3496.519,
			3497.653,
			3499.93,
			3502.215,
			3503.36,
			3505.658,
			3507.964,
			3511.44,
			3512.603,
			3513.769,
			3517.277,
			3518.451,
			3519.627,
			3523.169,
			3525.541,
			3529.114,
			3531.507,
			3535.112,
			3537.525,
			3538.735,
			3541.161,
			3543.596,
			3544.816,
			3547.262,
			3549.717,
			3553.413,
			3555.887,
			3559.613,
			3560.859,
			3562.107,
			3565.864,
			3568.377,
			3572.163,
			3574.696,
			3577.237,
			3581.062,
			3582.341,
			3584.904,
			3588.763,
			3590.053,
			3593.933,
			3596.529,
			3600.426,
			3605.633,
			3606.939,
			3609.555,
			3610.865,
			3613.492,
			3616.124,
			3620.086,
			3622.735,
			3626.721,
			3628.053,
			3629.387,
			3634.738,
			3636.08,
			3640.113,
			3642.809,
			3645.511,
			3646.864,
			3649.575,
			3652.292,
			3653.653,
			3656.38,
			3657.746,
			3660.483,
			3663.227,
			3667.355,
			3670.116,
			3674.268,
			3675.656,
			3677.044,
			3681.22,
			3682.615,
			3684.012,
			3688.21,
			3691.017,
			3695.239,
			3698.062,
			3700.89,
			3702.307,
			3706.566,
			3709.413,
			3712.265,
			3716.557,
			3719.426,
			3725.184,
			3726.627,
			3730.966,
			3733.867,
			3738.228,
			3741.144,
			3745.529,
			3748.46,
			3749.928,
			3752.869,
			3755.815,
			3757.291,
			3760.247,
			3763.209,
			3767.663,
			3770.64,
			3776.61,
			3778.106,
			3782.603,
			3785.608,
			3790.126,
			3793.146,
			3797.684,
			3800.717,
			3803.756,
			3805.277,
			3808.324,
			3809.85,
			3812.906,
			3815.967,
			3820.569,
			3823.644,
			3829.808,
			3831.353,
			3835.994,
			3839.094,
			3843.753,
			3846.86,
			3851.505,
			3856.101,
			3859.126,
			3862.114,
			3863.593,
			3866.524,
			3869.417,
			3873.688,
			3876.486,
			3881.969,
			3883.317,
			3887.303,
			3889.914,
			3893.761,
			3896.28,
			3898.76,
			3899.986,
			3902.412,
			3903.611,
			3904.801,
			3909.477,
			3911.76,
			3914.004,
			3916.211,
			3919.451,
			3923.641,
			3924.666,
			3926.688,
			3927.686,
			3929.652,
			3931.582,
			3934.407,
			3936.246,
			3938.943,
			3940.717,
			3944.151,
			3944.994,
			3947.486,
			3949.123,
			3951.534,
			3954.699,
			3955.484,
			3957.819,
			3959.37,
			3960.926,
			3963.268,
			3964.826,
			3967.212,
			3968.812,
			3971.232,
			3972.897,
			3975.396,
			3977.093,
			3978.799,
			3979.654,
			3981.371,
			3983.983,
			3986.61,
			3988.387,
			3991.064,
			3992.884,
			3994.72,
			3998.398,
			3999.315,
			4002.071,
			4003.893,
			4006.623,
			4008.433,
			4010.235,
			4011.135,
			4012.929,
			4015.596,
			4018.256,
			4020.017,
			4021.777,
			4024.392,
			4026.123,
			4028.716,
			4030.43,
			4033.004,
			4034.714,
			4037.275,
			4038.986,
			4041.553,
			4043.27,
			4045.85,
			4048.441,
			4050.175,
			4051.914,
			4054.535,
			4056.289,
			4058.937,
			4059.826,
			4060.719,
			4063.412,
			4065.226,
			4067.985,
			4069.84,
			4072.675,
			4074.581,
			4077.472,
			4080.392,
			4082.354,
			4084.328,
			4087.311,
			4089.312,
			4092.34,
			4094.375,
			4097.45,
			4099.513,
			4102.6,
			4104.671,
			4106.751,
			4107.796,
			4109.896,
			4110.948,
			4113.064,
			4116.279,
			4118.407,
			4120.566,
			4123.824,
			4124.915,
			4126.009,
			4129.308,
			4130.413,
			4131.521,
			4134.863,
			4137.106,
			4140.489,
			4142.758,
			4151.936,
			4154.255,
			4155.418,
			4156.584,
			4160.096,
			4162.447,
			4165.989,
			4168.36,
			4170.74,
			4171.933,
			4174.326,
			4175.525,
			4177.931,
			4180.344,
			4183.979,
			4186.413,
			4190.079,
			4191.304,
			4192.532,
			4196.229,
			4197.464,
			4198.703,
			4202.433,
			4204.93,
			4207.432,
			4208.683,
			4213.671,
			4214.91,
			4217.369,
			4218.587,
			4221.001,
			4223.377,
			4226.87,
			4229.15,
			4232.496,
			4233.592,
			4234.678,
			4237.878,
			4239.964,
			4243.022,
			4245.015,
			4246.969,
			4247.931,
			4249.829,
			4250.763,
			4252.605,
			4255.305,
			4257.06,
			4258.795,
			4261.308,
			4262.94,
			4265.337,
			4266.124,
			4266.904,
			4269.213,
			4270.73,
			4272.992,
			4274.49,
			4276.731,
			4278.24,
			4278.995,
			4280.505,
			4282.789,
			4284.327,
			4285.869,
			4288.213,
			4289.8,
			4292.993,
			4293.802,
			4296.229,
			4297.851,
			4300.291,
			4301.917,
			4304.354,
			4305.975,
			4306.785,
			4308.406,
			4310.032,
			4310.843,
			4312.465,
			4314.092,
			4316.542,
			4318.174,
			4320.624,
			4321.439,
			4322.254,
			4324.701,
			4326.331,
			4328.77,
			4330.387,
			4332.802,
			4334.409,
			4335.215,
			4336.823,
			4338.425,
			4339.225,
			4340.826,
			4342.422,
			4344.812,
			4346.409,
			4349.593,
			4350.393,
			4352.79,
			4354.386,
			4356.79,
			4358.397,
			4360.012,
			4362.448,
			4363.264,
			4364.08,
			4366.544,
			4367.37,
			4368.197,
			4370.693,
			4372.364,
			4374.886,
			4376.585,
			4378.302,
			4379.164,
			4380.899,
			4381.766,
			4383.507,
			4385.261,
			4387.905,
			4389.678,
			4392.341,
			4393.224,
			4394.108,
			4396.776,
			4397.652,
			4398.53,
			4401.178,
			4402.946,
			4405.6,
			4407.382,
			4409.174,
			4410.074,
			4412.792,
			4414.63,
			4416.484,
			4419.295,
			4421.199,
			4424.077,
			4425.048,
			4426.023,
			4428.965,
			4430.942,
			4433.913,
			4435.918,
			4438.947,
			4443.024,
			4444.05,
			4446.114,
			4447.15,
			4449.231,
			4451.327,
			4454.483,
			4456.589,
			4460.828,
			4461.892,
			4465.097,
			4467.244,
			4470.478,
			4472.646,
			4475.915,
			4478.107,
			4479.208,
			4481.417,
			4484.751,
			4486.987,
			4489.229,
			4492.612,
			4494.878,
			4499.442,
			4500.59,
			4504.048,
			4506.366,
			4509.861,
			4512.203,
			4515.733,
			4519.284,
			4521.662,
			4525.246,
			4527.646,
			4530.057,
			4533.689,
			4536.121,
			4539.788,
			4541.015,
			4542.244,
			4545.946,
			4548.426,
			4552.162,
			4554.663,
			4558.431,
			4560.952,
			4562.216,
			4564.751,
			4567.294,
			4568.568,
			4571.122,
			4573.684,
			4577.542,
			4580.125,
			4584.012,
			4585.312,
			4586.612,
			4590.519,
			4593.125,
			4597.048,
			4599.671,
			4602.302,
			4603.619,
			4606.26,
			4607.583,
			4608.907,
			4612.891,
			4614.222,
			4615.554,
			4619.563,
			4622.244,
			4626.269,
			4628.94,
			4631.592,
			4632.908,
			4636.804,
			4639.354,
			4641.866,
			4645.564,
			4647.984,
			4651.551,
			4652.724,
			4653.889,
			4657.35,
			4658.481,
			4659.605,
			4662.946,
			4665.15,
			4668.427,
			4672.774,
			4673.857,
			4676.022,
			4677.103,
			4679.258,
			4681.412,
			4684.64,
			4686.795,
			4691.115,
			4692.203,
			4695.466,
			4697.649,
			4700.92,
			4703.112,
			4706.396,
			4710.801,
			4711.899,
			4715.179,
			4717.356,
			4719.519,
			4722.761,
			4724.926,
			4729.279,
			4730.378,
			4733.677,
			4735.876,
			4739.186,
			4741.402,
			4744.725,
			4749.139,
			4750.252,
			4752.485,
			4753.604,
			4755.843,
			4758.087,
			4761.47,
			4763.734,
			4768.267,
			4769.403,
			4772.805,
			4775.076,
			4778.465,
			4780.729,
			4784.123,
			4787.528,
			4789.798,
			4792.072,
			4793.208,
			4795.483,
			4797.758,
			4801.166,
			4803.438,
			4807.978,
			4809.116,
			4812.528,
			4814.801,
			4818.188,
			4820.467,
			4823.908,
			4827.353,
			4829.659,
			4831.958,
			4833.139,
			4835.47,
			4837.812,
			4841.341,
			4843.682,
			4847.229,
			4849.593,
			4853.129,
			4855.481,
			4859.004,
			4861.355,
			4864.895,
			4868.454,
			4870.858,
			4874.447,
			4876.828,
			4879.26,
			4882.884,
			4885.292,
			4890.17,
			4891.394,
			4895.076,
			4897.543,
			4901.261,
			4903.749,
			4907.498,
			4911.265,
			4912.524,
			4917.583,
			4918.853,
			4922.673,
			4925.227,
			4929.05,
			4931.577,
			4935.312,
			4938.964,
			4941.352,
			4944.866,
			4947.164,
			4949.427,
			4953.847,
			4954.931,
			4958.148,
			4960.239,
			4963.325,
			4965.356,
			4968.38,
			4970.386,
			4973.386,
			4976.384,
			4978.38,
			4980.376,
			4983.362,
			4985.349,
			4988.314,
			4990.283,
			4993.225,
			4995.183,
			4998.126,
			5000.089,
			5003.048,
			5007.013,
			5008.017,
			5010.021,
			5011.024,
			5013.038,
			5015.058,
			5018.096,
			5020.139,
			5023.207,
			5025.266,
			5028.354,
			5030.427,
			5033.534,
			5035.612,
			5037.687,
			5038.725,
			5040.805,
			5042.883,
			5043.921,
			5047.037,
			5049.116,
			5051.207,
			5054.348,
			5056.444,
			5060.646,
			5061.695,
			5064.853,
			5066.946,
			5070.091,
			5072.188,
			5075.339,
			5077.446,
			5080.608,
			5083.777,
			5085.896,
			5088.021,
			5091.22,
			5093.359,
			5096.575,
			5097.651,
			5098.728,
			5101.959,
			5104.121,
			5107.358,
			5109.518,
			5112.748,
			5115.966,
			5118.102,
			5120.231,
			5121.292,
			5123.409,
			5125.521,
			5128.682,
			5130.771,
			5133.949,
			5135.028,
			5136.094,
			5139.309,
			5141.465,
			5144.725,
			5146.892,
			5149.092,
			5150.196,
			5152.413,
			5155.783,
			5159.151,
			5161.408,
			5163.669,
			5167.08,
			5168.202,
			5169.369,
			5172.806,
			5173.965,
			5175.128,
			5178.632,
			5180.981,
			5184.523,
			5186.895,
			5189.276,
			5190.471,
			5192.866,
			5195.272,
			5200.118,
			5201.335,
			5205.004,
			5207.459,
			5211.158,
			5216.117,
			5217.363,
			5221.121,
			5223.64,
			5226.169,
			5229.978,
			5232.526,
			5236.357,
			5237.638,
			5238.92,
			5244.067,
			5245.358,
			5249.246,
			5251.843,
			5255.741,
			5258.34,
			5262.264,
			5266.217,
			5268.874,
			5271.534,
			5275.52,
			5278.184,
			5283.538,
			5284.885,
			5288.932,
			5291.637,
			5295.705,
			5298.421,
			5302.508,
			5307.982,
			5309.358,
			5313.497,
			5316.265,
			5319.04,
			5323.215,
			5326.008,
			5330.209,
			5331.613,
			5333.02,
			5337.25,
			5340.078,
			5344.332,
			5347.176,
			5351.451,
			5357.168,
			5358.6,
			5362.905,
			5365.782,
			5368.669,
			5373.017,
			5375.925,
			5380.3,
			5381.761,
			5383.224,
			5387.623,
			5390.567,
			5394.999,
			5397.963,
			5400.933,
			5402.419,
			5405.396,
			5409.873,
			5414.365,
			5417.368,
			5420.376,
			5424.899,
			5426.411,
			5427.923,
			5432.47,
			5435.507,
			5440.073,
			5443.125,
			5443.83
		],
		[
			0,
			0.046,
			0.077,
			0.154,
			0.201,
			0.266,
			0.327,
			0.387,
			0.481,
			0.514,
			0.575,
			0.591,
			0.637,
			0.699,
			0.763,
			0.825,
			0.918,
			0.95,
			1.028,
			1.262,
			1.323,
			1.386,
			1.447,
			1.462,
			1.512,
			1.574,
			1.635,
			1.698,
			1.76,
			1.821,
			1.901,
			1.947,
			2.01,
			2.073,
			2.134,
			2.228,
			2.259,
			2.338,
			2.384,
			2.447,
			2.509,
			2.556,
			2.632,
			2.696,
			2.775,
			2.82,
			2.884,
			2.946,
			2.993,
			3.007,
			3.069,
			3.102,
			3.133,
			3.211,
			3.257,
			3.321,
			3.382,
			3.432,
			3.505,
			3.569,
			3.646,
			3.693,
			3.76,
			3.82,
			3.881,
			3.943,
			3.976,
			4.006,
			4.083,
			4.13,
			4.194,
			4.257,
			4.303,
			4.381,
			4.412,
			4.443,
			4.52,
			4.567,
			4.63,
			4.692,
			4.738,
			4.754,
			4.816,
			4.88,
			4.958,
			5.005,
			5.067,
			5.128,
			5.192,
			5.254,
			5.286,
			5.316,
			5.394,
			5.442,
			5.506,
			5.566,
			5.613,
			5.629,
			5.692,
			5.721,
			5.753,
			5.831,
			5.878,
			5.939,
			6.002,
			6.048,
			6.064,
			6.128,
			6.191,
			6.267,
			6.315,
			6.378,
			6.438,
			6.502,
			6.566,
			6.594,
			6.627,
			6.703,
			6.752,
			6.815,
			6.876,
			6.923,
			6.938,
			7.001,
			7.032,
			7.064,
			7.142,
			7.186,
			7.25,
			7.314,
			7.36,
			7.439,
			7.499,
			7.563,
			7.581,
			7.623,
			7.688,
			7.75,
			7.813,
			7.875,
			7.905,
			7.936,
			8.015,
			8.061,
			8.123,
			8.185,
			8.233,
			8.31,
			8.341,
			8.373,
			8.449,
			8.498,
			8.56,
			8.621,
			8.668,
			8.683,
			8.747,
			8.809,
			8.887,
			8.934,
			8.997,
			9.057,
			9.12,
			9.184,
			9.215,
			9.246,
			9.325,
			9.371,
			9.434,
			9.495,
			9.542,
			9.557,
			9.62,
			9.656,
			9.681,
			9.761,
			9.807,
			9.869,
			9.931,
			9.977,
			10.055,
			10.119,
			10.195,
			10.243,
			10.304,
			10.369,
			10.416,
			10.431,
			10.493,
			10.521,
			10.556,
			10.632,
			10.679,
			10.743,
			10.806,
			10.852,
			10.929,
			10.959,
			10.992,
			11.07,
			11.116,
			11.18,
			11.24,
			11.288,
			11.304,
			11.365,
			11.427,
			11.506,
			11.554,
			11.62,
			11.678,
			11.74,
			11.803,
			11.834,
			11.864,
			11.943,
			11.988,
			12.053,
			12.114,
			12.161,
			12.176,
			12.239,
			12.271,
			12.301,
			12.381,
			12.426,
			12.488,
			12.551,
			12.599,
			12.614,
			12.676,
			12.707,
			12.739,
			12.802,
			12.862,
			12.927,
			12.989,
			13.05,
			13.113,
			13.144,
			13.175,
			13.254,
			13.301,
			13.363,
			13.423,
			13.487,
			13.579,
			13.611,
			13.675,
			13.688,
			13.736,
			13.8,
			13.804,
			13.862,
			13.925,
			14.016,
			14.049,
			14.126,
			14.174,
			14.236,
			14.299,
			14.362,
			14.454,
			14.486,
			14.547,
			14.562,
			14.611,
			14.674,
			14.734,
			14.797,
			14.889,
			14.922,
			14.999,
			15.047,
			15.11,
			15.173,
			15.234,
			15.329,
			15.359,
			15.436,
			15.484,
			15.547,
			15.606,
			15.654,
			15.734,
			15.797,
			15.859,
			15.921,
			15.983,
			16.046,
			16.105,
			16.17,
			16.201,
			16.233,
			16.31,
			16.354,
			16.42,
			16.482,
			16.528,
			16.606,
			16.637,
			16.668,
			16.747,
			16.793,
			16.855,
			16.919,
			16.965,
			16.981,
			17.042,
			17.105,
			17.183,
			17.23,
			17.296,
			17.354,
			17.401,
			17.481,
			17.512,
			17.544,
			17.619,
			17.667,
			17.73,
			17.792,
			17.84,
			17.855,
			17.917,
			17.951,
			17.978,
			18.058,
			18.103,
			18.167,
			18.228,
			18.292,
			18.354,
			18.383,
			18.415,
			18.494,
			18.54,
			18.603,
			18.665,
			18.728,
			18.822,
			18.852,
			18.915,
			18.931,
			18.978,
			19.04,
			19.101,
			19.165,
			19.226,
			19.257,
			19.288,
			19.368,
			19.414,
			19.477,
			19.54,
			19.601,
			19.694,
			19.726,
			19.803,
			19.851,
			19.912,
			19.975,
			20.037,
			20.131,
			20.163,
			20.225,
			20.238,
			20.287,
			20.351,
			20.412,
			20.475,
			20.537,
			20.568,
			20.6,
			20.66,
			20.678,
			20.723,
			20.787,
			20.849,
			20.911,
			20.973,
			21.005,
			21.037,
			21.113,
			21.162,
			21.223,
			21.285,
			21.347,
			21.442,
			21.471,
			21.535,
			21.551,
			21.598,
			21.66,
			21.723,
			21.784,
			21.846,
			21.879,
			21.91,
			21.971,
			21.986,
			22.034,
			22.099,
			22.16,
			22.22,
			22.284,
			22.314,
			22.345,
			22.409,
			22.471,
			22.534,
			22.597,
			22.656,
			22.753,
			22.784,
			22.845,
			22.86,
			22.906,
			22.971,
			23.033,
			23.094,
			23.156,
			23.189,
			23.219,
			23.282,
			23.344,
			23.406,
			23.47,
			23.532,
			23.594,
			23.625,
			23.655,
			23.734,
			23.782,
			23.844,
			23.907,
			23.967,
			24.061,
			24.093,
			24.156,
			24.218,
			24.281,
			24.344,
			24.405,
			24.467,
			24.499,
			24.529,
			24.593,
			24.609,
			24.656,
			24.718,
			24.78,
			24.843,
			24.937,
			24.967,
			25.045,
			25.091,
			25.155,
			25.217,
			25.279,
			25.372,
			25.404,
			25.465,
			25.481,
			25.529,
			25.59,
			25.653,
			25.717,
			25.778,
			25.84,
			25.918,
			25.964,
			26.029,
			26.089,
			26.151,
			26.25,
			26.276,
			26.356,
			26.401,
			26.465,
			26.526,
			26.589,
			26.684,
			26.714,
			26.777,
			26.837,
			26.903,
			26.963,
			27.026,
			27.088,
			27.15,
			27.229,
			27.276,
			27.338,
			27.401,
			27.462,
			27.557,
			27.587,
			27.666,
			27.711,
			27.773,
			27.837,
			27.898,
			27.961,
			28.024,
			28.102,
			28.149,
			28.211,
			28.273,
			28.335,
			28.399,
			28.461,
			28.538,
			28.586,
			28.649,
			28.71,
			28.756,
			28.836,
			28.897,
			28.976,
			29.021,
			29.086,
			29.146,
			29.193,
			29.209,
			29.273,
			29.302,
			29.334,
			29.411,
			29.459,
			29.52,
			29.584,
			29.631,
			29.706,
			29.741,
			29.772,
			29.849,
			29.896,
			29.962,
			30.021,
			30.068,
			30.083,
			30.145,
			30.205,
			30.285,
			30.332,
			30.399,
			30.455,
			30.52,
			30.581,
			30.613,
			30.643,
			30.723,
			30.77,
			30.832,
			30.893,
			30.94,
			30.955,
			31.019,
			31.048,
			31.082,
			31.158,
			31.205,
			31.27,
			31.331,
			31.377,
			31.455,
			31.517,
			31.596,
			31.643,
			31.705,
			31.766,
			31.83,
			31.892,
			31.921,
			31.953,
			32.032,
			32.08,
			32.14,
			32.205,
			32.251,
			32.329,
			32.36,
			32.388,
			32.473,
			32.515,
			32.578,
			32.639,
			32.686,
			32.702,
			32.764,
			32.795,
			32.827,
			32.906,
			32.951,
			33.015,
			33.076,
			33.139,
			33.202,
			33.234,
			33.264,
			33.341,
			33.387,
			33.451,
			33.514,
			33.561,
			33.639,
			33.67,
			33.699,
			33.779,
			33.823,
			33.888,
			33.951,
			33.996,
			34.012,
			34.073,
			34.138,
			34.2,
			34.215,
			34.263,
			34.325,
			34.385,
			34.449,
			34.51,
			34.544,
			34.575,
			34.652,
			34.697,
			34.76,
			34.824,
			34.871,
			34.886,
			34.947,
			34.977,
			35.011,
			35.089,
			35.136,
			35.197,
			35.26,
			35.322,
			35.384,
			35.416,
			35.446,
			35.51,
			35.571,
			35.634,
			35.697,
			35.758,
			35.821,
			35.853,
			35.884,
			35.96,
			36.009,
			36.07,
			36.132,
			36.179,
			36.256,
			36.288,
			36.32,
			36.398,
			36.444,
			36.507,
			36.569,
			36.631,
			36.694,
			36.723,
			36.755,
			36.818,
			36.834,
			36.882,
			36.945,
			37.006,
			37.069,
			37.131,
			37.162,
			37.193,
			37.271,
			37.318,
			37.382,
			37.443,
			37.489,
			37.505,
			37.598,
			37.629,
			37.692,
			37.706,
			37.753,
			37.817,
			37.878,
			37.941,
			38.004,
			38.035,
			38.065,
			38.128,
			38.19,
			38.254,
			38.315,
			38.377,
			38.44,
			38.472,
			38.504,
			38.581,
			38.627,
			38.694,
			38.753,
			38.815,
			38.909,
			38.94,
			39.001,
			39.064,
			39.128,
			39.189,
			39.252,
			39.346,
			39.376,
			39.439,
			39.454,
			39.5,
			39.565,
			39.626,
			39.688,
			39.751,
			39.782,
			39.814,
			39.892,
			39.937,
			40.001,
			40.061,
			40.125,
			40.186,
			40.25,
			40.328,
			40.373,
			40.438,
			40.5,
			40.562,
			40.655,
			40.686,
			40.748,
			40.769,
			40.811,
			40.871,
			40.936,
			40.998,
			41.06,
			41.092,
			41.122,
			41.186,
			41.201,
			41.248,
			41.312,
			41.371,
			41.434,
			41.528,
			41.561,
			41.623,
			41.638,
			41.683,
			41.748,
			41.81,
			41.872,
			41.934,
			41.965,
			41.996,
			42.058,
			42.122,
			42.185,
			42.247,
			42.309,
			42.372,
			42.402,
			42.432,
			42.512,
			42.556,
			42.621,
			42.682,
			42.729,
			42.843,
			42.87,
			42.947,
			42.994,
			43.06,
			43.12,
			43.181,
			43.244,
			43.275,
			43.308,
			43.37,
			43.384,
			43.431,
			43.495,
			43.555,
			43.617,
			43.681,
			43.711,
			43.744,
			43.804,
			43.867,
			43.931,
			43.993,
			44.038,
			44.054,
			44.117,
			44.149,
			44.179,
			44.243,
			44.259,
			44.305,
			44.367,
			44.428,
			44.492,
			44.586,
			44.616,
			44.693,
			44.74,
			44.803,
			44.865,
			44.928,
			44.991,
			45.021,
			45.054,
			45.13,
			45.177,
			45.24,
			45.302,
			45.366,
			45.426,
			45.488,
			45.551,
			45.614,
			45.677,
			45.739,
			45.785,
			45.864,
			45.926,
			46.002,
			46.05,
			46.114,
			46.175,
			46.236,
			46.33,
			46.361,
			46.441,
			46.486,
			46.549,
			46.61,
			46.672,
			46.767,
			46.797,
			46.876,
			46.922,
			46.989,
			47.048,
			47.11,
			47.203,
			47.234,
			47.312,
			47.36,
			47.423,
			47.483,
			47.545,
			47.638,
			47.672,
			47.75,
			47.796,
			47.858,
			47.92,
			47.967,
			47.983,
			48.046,
			48.106,
			48.185,
			48.233,
			48.295,
			48.355,
			48.42,
			48.48,
			48.543,
			48.62,
			48.667,
			48.731,
			48.793,
			48.854,
			48.95,
			48.98,
			49.062,
			49.103,
			49.168,
			49.229,
			49.29,
			49.354,
			49.416,
			49.494,
			49.542,
			49.604,
			49.667,
			49.714,
			49.729,
			49.789,
			49.852,
			49.93,
			49.977,
			50.04,
			50.102,
			50.165,
			50.256,
			50.29,
			50.351,
			50.415,
			50.477,
			50.54,
			50.602,
			50.663,
			50.727,
			50.804,
			50.851,
			50.913,
			50.975,
			51.039,
			51.136,
			51.164,
			51.241,
			51.288,
			51.351,
			51.412,
			51.473,
			51.569,
			51.599,
			51.677,
			51.723,
			51.787,
			51.848,
			51.896,
			51.911,
			51.972,
			52.037,
			52.113,
			52.162,
			52.221,
			52.286,
			52.333,
			52.411,
			52.471,
			52.551,
			52.596,
			52.661,
			52.722,
			52.785,
			52.846,
			52.91,
			52.988,
			53.032,
			53.096,
			53.16,
			53.221,
			53.282,
			53.347,
			53.422,
			53.47,
			53.534,
			53.594,
			53.64,
			53.656,
			53.721,
			53.781,
			53.859,
			53.906,
			53.97,
			54.031,
			54.093,
			54.157,
			54.22,
			54.297,
			54.344,
			54.404,
			54.469,
			54.515,
			54.532,
			54.593,
			54.656,
			54.735,
			54.78,
			54.843,
			54.904,
			54.952,
			55.031,
			55.093,
			55.169,
			55.218,
			55.28,
			55.342,
			55.388,
			55.404,
			55.467,
			55.529,
			55.606,
			55.655,
			55.718,
			55.779,
			55.826,
			55.904,
			55.965,
			56.044,
			56.09,
			56.152,
			56.216,
			56.278,
			56.37,
			56.402,
			56.481,
			56.527,
			56.589,
			56.653,
			56.7,
			56.714,
			56.778,
			56.807,
			56.839,
			56.918,
			56.964,
			57.026,
			57.088,
			57.136,
			57.151,
			57.213,
			57.276,
			57.356,
			57.4,
			57.462,
			57.526,
			57.588,
			57.682,
			57.713,
			57.789,
			57.837,
			57.899,
			57.962,
			58.026,
			58.087,
			58.118,
			58.15,
			58.226,
			58.275,
			58.338,
			58.399,
			58.447,
			58.462,
			58.525,
			58.586,
			58.662,
			58.71,
			58.771,
			58.836,
			58.897,
			58.96,
			58.992,
			59.022,
			59.084,
			59.148,
			59.211,
			59.271,
			59.319,
			59.336,
			59.398,
			59.432,
			59.459,
			59.536,
			59.583,
			59.646,
			59.71,
			59.772,
			59.833,
			59.896,
			59.974,
			60.021,
			60.084,
			60.146,
			60.208,
			60.27,
			60.301,
			60.333,
			60.411,
			60.458,
			60.52,
			60.582,
			60.628,
			60.706,
			60.77,
			60.849,
			60.894,
			60.956,
			61.019,
			61.065,
			61.082,
			61.143,
			61.176,
			61.207,
			61.284,
			61.332,
			61.394,
			61.456,
			61.506,
			61.582,
			61.612,
			61.644,
			61.722,
			61.769,
			61.83,
			61.894,
			61.957,
			62.049,
			62.08,
			62.144,
			62.159,
			62.206,
			62.268,
			62.331,
			62.377,
			62.454,
			62.487,
			62.518,
			62.594,
			62.642,
			62.704,
			62.766,
			62.813,
			62.829,
			62.893,
			62.922,
			62.954,
			63.032,
			63.08,
			63.142,
			63.205,
			63.267,
			63.327,
			63.36,
			63.389,
			63.452,
			63.516,
			63.583,
			63.639,
			63.704,
			63.764,
			63.796,
			63.828,
			63.906,
			63.953,
			64.016,
			64.078,
			64.125,
			64.14,
			64.2,
			64.234,
			64.264,
			64.342,
			64.387,
			64.451,
			64.513,
			64.577,
			64.639,
			64.669,
			64.702,
			64.764,
			64.778,
			64.826,
			64.892,
			64.951,
			65.013,
			65.075,
			65.105,
			65.139,
			65.201,
			65.263,
			65.326,
			65.387,
			65.448,
			65.511,
			65.543,
			65.574,
			65.636,
			65.655,
			65.699,
			65.762,
			65.823,
			65.886,
			65.98,
			66.01,
			66.072,
			66.09,
			66.136,
			66.197,
			66.26,
			66.306,
			66.386,
			66.416,
			66.446,
			66.51,
			66.526,
			66.571,
			66.634,
			66.696,
			66.744,
			66.759,
			66.821,
			66.854,
			66.884,
			66.962,
			67.009,
			67.07,
			67.133,
			67.195,
			67.259,
			67.29,
			67.32,
			67.384,
			67.445,
			67.506,
			67.571,
			67.632,
			67.696,
			67.731,
			67.756,
			67.836,
			67.881,
			67.945,
			68.006,
			68.054,
			68.131,
			68.163,
			68.194,
			68.271,
			68.319,
			68.383,
			68.444,
			68.505,
			68.569,
			68.601,
			68.631,
			68.694,
			68.71,
			68.756,
			68.818,
			68.879,
			68.927,
			69.005,
			69.036,
			69.069,
			69.146,
			69.193,
			69.255,
			69.317,
			69.381,
			69.442,
			69.473,
			69.503,
			69.567,
			69.582,
			69.628,
			69.693,
			69.755,
			69.815,
			69.878,
			69.909,
			69.94,
			70.003,
			70.067,
			70.13,
			70.192,
			70.253,
			70.316,
			70.347,
			70.378,
			70.439,
			70.455,
			70.503,
			70.566,
			70.629,
			70.673,
			70.689,
			70.752,
			70.785,
			70.815,
			70.877,
			70.938,
			71.004,
			71.064,
			71.126,
			71.188,
			71.22,
			71.251,
			71.312,
			71.328,
			71.377,
			71.438,
			71.502,
			71.563,
			71.626,
			71.657,
			71.688,
			71.767,
			71.812,
			71.878,
			71.939,
			71.984,
			72.061,
			72.094,
			72.126,
			72.203,
			72.251,
			72.313,
			72.375,
			72.437,
			72.531,
			72.562,
			72.623,
			72.639,
			72.686,
			72.748,
			72.81,
			72.856,
			72.936,
			72.967,
			72.998,
			73.077,
			73.123,
			73.187,
			73.247,
			73.295,
			73.311,
			73.373,
			73.404,
			73.434,
			73.496,
			73.512,
			73.56,
			73.621,
			73.685,
			73.747,
			73.81,
			73.841,
			73.872,
			73.933,
			73.953,
			73.995,
			74.06,
			74.12,
			74.182,
			74.246,
			74.277,
			74.307,
			74.387,
			74.432,
			74.497,
			74.556,
			74.619,
			74.713,
			74.745,
			74.822,
			74.87,
			74.933,
			74.994,
			75.056,
			75.12,
			75.151,
			75.182,
			75.243,
			75.26,
			75.305,
			75.37,
			75.432,
			75.494,
			75.586,
			75.617,
			75.679,
			75.744,
			75.805,
			75.867,
			75.929,
			75.993,
			76.027,
			76.054,
			76.117,
			76.133,
			76.178,
			76.242,
			76.303,
			76.367,
			76.459,
			76.492,
			76.568,
			76.615,
			76.682,
			76.741,
			76.803,
			76.896,
			76.927,
			76.989,
			77.004,
			77.052,
			77.114,
			77.178,
			77.239,
			77.333,
			77.365,
			77.426,
			77.487,
			77.552,
			77.614,
			77.66,
			77.676,
			77.739,
			77.768,
			77.801,
			77.88,
			77.926,
			77.987,
			78.049,
			78.113,
			78.206,
			78.236,
			78.3,
			78.316,
			78.362,
			78.425,
			78.486,
			78.533,
			78.611,
			78.675,
			78.753,
			78.797,
			78.861,
			78.925,
			78.986,
			79.079,
			79.111,
			79.19,
			79.236,
			79.299,
			79.361,
			79.406,
			79.486,
			79.546,
			79.625,
			79.671,
			79.736,
			79.796,
			79.843,
			79.859,
			79.92,
			79.984,
			80.062,
			80.11,
			80.177,
			80.234,
			80.296,
			80.389,
			80.421,
			80.5,
			80.545,
			80.607,
			80.671,
			80.717,
			80.734,
			80.794,
			80.858,
			80.937,
			80.983,
			81.046,
			81.106,
			81.169,
			81.231,
			81.294,
			81.373,
			81.418,
			81.482,
			81.543,
			81.607,
			81.67,
			81.731,
			81.81,
			81.856,
			81.92,
			81.98,
			82.026,
			82.104,
			82.136,
			82.167,
			82.231,
			82.293,
			82.355,
			82.416,
			82.478,
			82.542,
			82.604,
			82.682,
			82.73,
			82.792,
			82.854,
			82.915,
			82.978,
			83.042,
			83.103,
			83.165,
			83.227,
			83.29,
			83.338,
			83.353,
			83.416,
			83.447,
			83.477,
			83.554,
			83.602,
			83.664,
			83.727,
			83.773,
			83.79,
			83.851,
			83.883,
			83.914,
			83.992,
			84.04,
			84.101,
			84.164,
			84.351,
			84.412,
			84.428,
			84.473,
			84.536,
			84.6,
			84.663,
			84.726,
			84.755,
			84.788,
			84.85,
			84.866,
			84.913,
			84.975,
			85.037,
			85.083,
			85.161,
			85.192,
			85.223,
			85.286,
			85.302,
			85.347,
			85.412,
			85.474,
			85.52,
			85.535,
			85.629,
			85.66,
			85.723,
			85.737,
			85.784,
			85.848,
			85.91,
			85.97,
			86.033,
			86.064,
			86.096,
			86.175,
			86.221,
			86.283,
			86.344,
			86.396,
			86.406,
			86.471,
			86.5,
			86.533,
			86.611,
			86.656,
			86.72,
			86.781,
			86.843,
			86.906,
			86.936,
			86.969,
			87.031,
			87.093,
			87.157,
			87.219,
			87.281,
			87.342,
			87.375,
			87.405,
			87.484,
			87.531,
			87.594,
			87.656,
			87.701,
			87.812,
			87.842,
			87.92,
			87.966,
			88.029,
			88.092,
			88.155,
			88.217,
			88.248,
			88.28,
			88.341,
			88.356,
			88.404,
			88.468,
			88.527,
			88.591,
			88.652,
			88.685,
			88.716,
			88.779,
			88.84,
			88.904,
			88.965,
			89.028,
			89.089,
			89.122,
			89.151,
			89.215,
			89.23,
			89.277,
			89.34,
			89.402,
			89.465,
			89.556,
			89.588,
			89.651,
			89.713,
			89.777,
			89.838,
			89.9,
			89.964,
			89.994,
			90.024,
			90.089,
			90.103,
			90.149,
			90.213,
			90.275,
			90.336,
			90.4,
			90.431,
			90.462,
			90.525,
			90.543,
			90.586,
			90.649,
			90.71,
			90.773,
			90.837,
			90.866,
			90.897,
			90.96,
			90.977,
			91.024,
			91.085,
			91.147,
			91.21,
			91.274,
			91.304,
			91.336,
			91.414,
			91.459,
			91.522,
			91.585,
			91.631,
			91.709,
			91.74,
			91.771,
			91.849,
			91.897,
			91.96,
			92.021,
			92.083,
			92.178,
			92.205,
			92.27,
			92.285,
			92.333,
			92.394,
			92.458,
			92.52,
			92.617,
			92.645,
			92.707,
			92.77,
			92.833,
			92.893,
			92.956,
			93.018,
			93.05,
			93.081,
			93.16,
			93.207,
			93.27,
			93.331,
			93.393,
			93.486,
			93.519,
			93.579,
			93.642,
			93.706,
			93.767,
			93.829,
			93.924,
			93.954,
			94.033,
			94.077,
			94.142,
			94.203,
			94.251,
			94.329,
			94.361,
			94.39,
			94.454,
			94.515,
			94.578,
			94.638,
			94.702,
			94.765,
			94.796,
			94.828,
			94.89,
			94.906,
			94.952,
			95.014,
			95.076,
			95.14,
			95.202,
			95.233,
			95.263,
			95.342,
			95.39,
			95.451,
			95.513,
			95.561,
			95.576,
			95.639,
			95.669,
			95.7,
			95.762,
			95.778,
			95.825,
			95.888,
			95.949,
			96.012,
			96.075,
			96.105,
			96.137,
			96.215,
			96.263,
			96.323,
			96.386,
			96.449,
			96.512,
			96.543,
			96.573,
			96.637,
			96.652,
			96.699,
			96.765,
			96.823,
			96.885,
			96.979,
			97.01,
			97.072,
			97.088,
			97.134,
			97.199,
			97.261,
			97.321,
			97.415,
			97.447,
			97.51,
			97.571,
			97.635,
			97.697,
			97.76,
			97.853,
			97.883,
			97.961,
			98.009,
			98.071,
			98.134,
			98.196,
			98.287,
			98.321,
			98.383,
			98.446,
			98.507,
			98.57,
			98.632,
			98.727,
			98.755,
			98.818,
			98.84,
			98.881,
			98.944,
			99.008,
			99.068,
			99.161,
			99.193,
			99.254,
			99.317,
			99.381,
			99.444,
			99.505,
			99.598,
			99.631,
			99.694,
			99.708,
			99.756,
			99.818,
			99.879,
			99.943,
			100.036,
			100.068,
			100.146,
			100.193,
			100.258,
			100.317,
			100.38,
			100.473,
			100.504,
			100.567,
			100.581,
			100.63,
			100.692,
			100.754,
			100.816,
			100.877,
			100.939,
			101.003,
			101.066,
			101.129,
			101.188,
			101.253,
			101.345,
			101.377,
			101.454,
			101.502,
			101.564,
			101.627,
			101.69,
			101.784,
			101.814,
			101.877,
			101.938,
			102.002,
			102.064,
			102.127,
			102.219,
			102.25,
			102.328,
			102.375,
			102.438,
			102.5,
			102.561,
			102.622,
			102.687,
			102.765,
			102.812,
			102.876,
			102.935,
			102.998,
			103.093,
			103.123,
			103.186,
			103.248,
			103.314,
			103.373,
			103.434,
			103.498,
			103.559,
			103.638,
			103.685,
			103.747,
			103.81,
			103.871,
			103.935,
			103.997,
			104.076,
			104.122,
			104.184,
			104.246,
			104.309,
			104.401,
			104.433,
			104.494,
			104.51,
			104.557,
			104.619,
			104.682,
			104.744,
			104.806,
			104.87,
			104.947,
			104.995,
			105.06,
			105.12,
			105.165,
			105.181,
			105.243,
			105.276,
			105.306,
			105.385,
			105.431,
			105.494,
			105.556,
			105.618,
			105.712,
			105.743,
			105.805,
			105.868,
			105.93,
			105.993,
			106.055,
			106.116,
			106.179,
			106.257,
			106.305,
			106.368,
			106.43,
			106.476,
			106.555,
			106.586,
			106.617,
			106.695,
			106.74,
			106.804,
			106.865,
			106.929,
			107.021,
			107.052,
			107.115,
			107.133,
			107.178,
			107.24,
			107.303,
			107.365,
			107.427,
			107.459,
			107.49,
			107.568,
			107.614,
			107.677,
			107.738,
			107.787,
			107.802,
			107.863,
			107.927,
			108.005,
			108.051,
			108.114,
			108.177,
			108.222,
			108.238,
			108.302,
			108.332,
			108.363,
			108.441,
			108.487,
			108.551,
			108.612,
			108.659,
			108.676,
			108.738,
			108.8,
			108.879,
			108.923,
			108.987,
			109.049,
			109.112,
			109.21,
			109.237,
			109.316,
			109.361,
			109.423,
			109.487,
			109.549,
			109.611,
			109.643,
			109.674,
			109.751,
			109.798,
			109.861,
			109.921,
			109.985,
			110.047,
			110.11,
			110.188,
			110.236,
			110.298,
			110.36,
			110.422,
			110.514,
			110.547,
			110.623,
			110.67,
			110.735,
			110.796,
			110.858,
			110.952,
			110.984,
			111.06,
			111.108,
			111.172,
			111.233,
			111.295,
			111.358,
			111.387,
			111.42,
			111.498,
			111.544,
			111.611,
			111.67,
			111.73,
			111.826,
			111.857,
			111.933,
			111.98,
			112.044,
			112.106,
			112.151,
			112.231,
			112.261,
			112.293,
			112.369,
			112.417,
			112.48,
			112.543,
			112.59,
			112.603,
			112.666,
			112.73,
			112.806,
			112.854,
			112.916,
			112.979,
			113.025,
			113.042,
			113.103,
			113.165,
			113.243,
			113.291,
			113.297
		]
	],
	'9176_9343_7982': [
		[
			70.312
		],
		[
			0
		],
		[
			0,
			0.133,
			4.819,
			9.498,
			12.616,
			15.748,
			20.483,
			23.653,
			28.422,
			31.611,
			36.408,
			39.603,
			44.397,
			47.593,
			50.794,
			52.395,
			55.602,
			60.421,
			65.251,
			68.477,
			71.707,
			76.561,
			79.803,
			84.675,
			86.302,
			87.929,
			92.818,
			96.084,
			100.991,
			104.268,
			109.192,
			112.481,
			117.424,
			122.376,
			125.684,
			128.996,
			133.971,
			137.293,
			143.953,
			145.622,
			150.633,
			153.98,
			159.009,
			162.368,
			167.414,
			170.783,
			172.469,
			175.845,
			180.914,
			184.291,
			187.663,
			192.693,
			194.358,
			196.015,
			200.937,
			202.56,
			204.174,
			208.965,
			212.115,
			216.763,
			219.812,
			222.824,
			224.317,
			227.277,
			228.743,
			230.2,
			235.93,
			237.338,
			241.51,
			244.247,
			246.953,
			248.292,
			250.94,
			254.839,
			258.647,
			261.139,
			263.594,
			267.209,
			269.577,
			273.067,
			274.212,
			275.349,
			278.702,
			279.802,
			280.892,
			284.109,
			286.21,
			289.296,
			293.308,
			294.286,
			297.168,
			299.05,
			300.9,
			303.632,
			305.425,
			308.086,
			308.964,
			309.832,
			313.272,
			314.122,
			316.642,
			318.317,
			320.849,
			322.538,
			324.245,
			325.122,
			327.756,
			329.547,
			331.388,
			334.175,
			336.148,
			339.131,
			340.181,
			341.233,
			344.404,
			346.525,
			349.685,
			351.728,
			353.779,
			354.774,
			356.757,
			359.737,
			362.717,
			364.715,
			366.728,
			369.749,
			371.761,
			374.755,
			376.706,
			379.606,
			381.481,
			384.289,
			386.131,
			387.975,
			388.899,
			390.736,
			391.656,
			393.498,
			395.338,
			396.258,
			398.103,
			399.956,
			402.747,
			404.619,
			407.454,
			409.352,
			412.211,
			414.125,
			417.008,
			418.936,
			421.845,
			423.795,
			424.775,
			426.745,
			428.726,
			429.723,
			431.73,
			433.732,
			436.808,
			438.847,
			441.953,
			442.991,
			444.029,
			446.116,
			447.164,
			449.269,
			452.461,
			454.603,
			456.757,
			457.838,
			460.007,
			461.095,
			463.28,
			466.576,
			467.68,
			471.008,
			473.24,
			476.608,
			480.001,
			482.275,
			485.707,
			488.01,
			491.487,
			493.819,
			497.335,
			499.694,
			500.879,
			502.066,
			506.846,
			508.049,
			511.665,
			514.086,
			517.731,
			522.625,
			523.855,
			526.323,
			527.56,
			530.042,
			532.534,
			536.291,
			538.807,
			542.6,
			543.868,
			545.14,
			550.246,
			551.529,
			555.39,
			557.975,
			560.569,
			561.869,
			564.474,
			567.08,
			568.385,
			571,
			572.31,
			574.937,
			577.571,
			581.538,
			584.192,
			588.187,
			589.523,
			590.86,
			594.885,
			597.579,
			601.634,
			604.345,
			608.437,
			611.152,
			613.866,
			615.224,
			619.292,
			621.998,
			624.701,
			628.758,
			631.471,
			635.561,
			636.933,
			638.307,
			642.446,
			645.218,
			649.391,
			652.181,
			656.385,
			659.206,
			660.622,
			663.48,
			666.352,
			667.796,
			670.69,
			673.591,
			677.96,
			680.884,
			686.763,
			688.238,
			692.676,
			695.644,
			700.105,
			703.085,
			707.56,
			712.036,
			715.022,
			718.007,
			719.5,
			722.489,
			725.482,
			729.985,
			732.993,
			737.515,
			739.025,
			740.536,
			745.075,
			748.108,
			752.666,
			755.71,
			760.281,
			763.331,
			767.911,
			772.497,
			775.556,
			778.62,
			783.22,
			786.289,
			792.432,
			793.965,
			798.558,
			801.613,
			806.199,
			809.274,
			813.888,
			816.963,
			818.499,
			821.57,
			824.65,
			826.19,
			827.708,
			832.343,
			836.982,
			840.076,
			846.271,
			847.821,
			852.477,
			855.582,
			860.245,
			863.352,
			866.458,
			871.104,
			872.652,
			875.744,
			878.832,
			880.374,
			881.914,
			886.516,
			889.572,
			894.138,
			900.22,
			901.731,
			906.276,
			909.309,
			912.344,
			916.861,
			919.888,
			924.456,
			927.488,
			933.599,
			935.141,
			939.81,
			942.962,
			947.79,
			950.969,
			955.671,
			960.34,
			963.466,
			966.621,
			971.39,
			974.595,
			981.056,
			982.677,
			987.526,
			990.709,
			995.427,
			998.542,
			1003.213,
			1006.346,
			1011.021,
			1015.652,
			1018.719,
			1021.776,
			1026.356,
			1029.382,
			1033.968,
			1037.061,
			1041.624,
			1044.674,
			1049.234,
			1052.273,
			1056.844,
			1061.429,
			1064.494,
			1069.104,
			1072.185,
			1075.269,
			1079.896,
			1082.983,
			1087.632,
			1089.185,
			1090.743,
			1095.433,
			1098.581,
			1103.326,
			1106.483,
			1111.215,
			1114.372,
			1119.115,
			1123.899,
			1127.077,
			1130.266,
			1135.089,
			1138.341,
			1143.244,
			1146.53,
			1151.484,
			1154.78,
			1159.803,
			1163.08,
			1166.305,
			1167.919,
			1171.143,
			1176.007,
			1180.872,
			1184.135,
			1187.408,
			1192.322,
			1193.961,
			1195.6,
			1200.516,
			1203.796,
			1208.721,
			1212.009,
			1216.946,
			1220.241,
			1223.541,
			1225.193,
			1228.476,
			1233.478,
			1238.466,
			1241.796,
			1245.129,
			1250.135,
			1251.806,
			1253.479,
			1258.503,
			1260.18,
			1261.858,
			1266.888,
			1270.234,
			1275.195,
			1278.517,
			1281.87,
			1286.841,
			1290.182,
			1296.913,
			1298.604,
			1303.686,
			1308.775,
			1310.472,
			1312.17,
			1315.568,
			1320.667,
			1324.07,
			1325.772,
			1329.172,
			1332.569,
			1337.656,
			1341.052,
			1346.165,
			1349.575,
			1356.389,
			1358.116,
			1363.185,
			1366.578,
			1371.665,
			1375.061,
			1380.189,
			1385.267,
			1388.673,
			1392.081,
			1397.198,
			1400.616,
			1405.748,
			1407.461,
			1409.175,
			1414.321,
			1416.039,
			1417.757,
			1422.916,
			1426.361,
			1429.808,
			1431.533,
			1434.984,
			1440.165,
			1445.353,
			1448.815,
			1452.28,
			1457.483,
			1460.956,
			1466.17,
			1469.65,
			1474.875,
			1478.362,
			1483.599,
			1487.094,
			1492.341,
			1497.595,
			1501.101,
			1506.366,
			1509.879,
			1513.395,
			1518.675,
			1522.198,
			1527.488,
			1531.018,
			1536.317,
			1539.854,
			1545.164,
			1548.707,
			1552.253,
			1554.027,
			1557.578,
			1562.908,
			1568.244,
			1571.805,
			1575.368,
			1580.717,
			1584.287,
			1589.647,
			1593.224,
			1598.596,
			1602.18,
			1607.56,
			1611.15,
			1614.743,
			1616.54,
			1620.137,
			1625.537,
			1630.942,
			1634.549,
			1638.159,
			1643.577,
			1645.385,
			1647.193,
			1652.621,
			1656.243,
			1661.68,
			1665.307,
			1670.752,
			1674.386,
			1679.842,
			1683.481,
			1685.302,
			1688.946,
			1694.413,
			1698.062,
			1701.714,
			1707.194,
			1709.022,
			1710.851,
			1716.341,
			1720.003,
			1725.501,
			1729.169,
			1734.675,
			1738.348,
			1742.024,
			1747.541,
			1751.222,
			1758.589,
			1760.433,
			1765.965,
			1769.656,
			1773.349,
			1775.197,
			1778.892,
			1784.439,
			1788.141,
			1789.991,
			1793.694,
			1797.4,
			1802.963,
			1806.674,
			1812.243,
			1814.101,
			1815.959,
			1823.396,
			1825.257,
			1830.841,
			1834.566,
			1840.158,
			1843.888,
			1849.486,
			1855.081,
			1858.809,
			1862.542,
			1868.152,
			1871.898,
			1877.525,
			1881.279,
			1888.792,
			1890.671,
			1896.308,
			1900.067,
			1903.827,
			1905.708,
			1909.471,
			1915.118,
			1920.769,
			1924.539,
			1928.31,
			1933.97,
			1937.747,
			1943.416,
			1945.309,
			1947.202,
			1952.889,
			1956.679,
			1962.363,
			1966.151,
			1969.941,
			1971.836,
			1975.628,
			1981.319,
			1987.014,
			1990.813,
			1994.613,
			2000.315,
			2004.119,
			2009.824,
			2013.63,
			2019.338,
			2023.137,
			2028.837,
			2032.647,
			2036.452,
			2038.35,
			2042.128,
			2044.005,
			2047.728,
			2051.41,
			2053.235,
			2056.854,
			2060.433,
			2065.724,
			2069.201,
			2074.341,
			2076.035,
			2077.72,
			2082.715,
			2085.998,
			2090.853,
			2094.041,
			2098.744,
			2101.831,
			2103.36,
			2106.392,
			2110.876,
			2113.824,
			2116.742,
			2121.059,
			2123.893,
			2129.454,
			2130.821,
			2134.869,
			2137.521,
			2141.429,
			2143.986,
			2147.752,
			2150.219,
			2151.439,
			2153.855,
			2156.259,
			2157.439,
			2159.776,
			2162.077,
			2165.466,
			2167.682,
			2170.941,
			2173.069,
			2176.204,
			2178.264,
			2181.339,
			2183.393,
			2186.508,
			2189.66,
			2191.797,
			2195.027,
			2197.212,
			2199.406,
			2202.723,
			2203.833,
			2204.947,
			2208.282,
			2209.399,
			2210.517,
			2213.878,
			2216.138,
			2218.417,
			2221.868,
			2224.188,
			2228.834,
			2229.991,
			2233.452,
			2234.599,
			2235.744,
			2239.17,
			2241.446,
			2243.715,
			2244.845,
			2247.073,
			2250.367,
			2253.569,
			2255.675,
			2257.766,
			2260.877,
			2262.958,
			2267.125,
			2268.172,
			2271.321,
			2273.428,
			2276.593,
			2278.705,
			2281.87,
			2286.081,
			2287.132,
			2289.229,
			2290.277,
			2292.367,
			2294.452,
			2297.566,
			2299.634,
			2302.721,
			2304.768,
			2307.825,
			2309.853,
			2312.881,
			2314.891,
			2316.896,
			2317.895,
			2319.892,
			2322.875,
			2325.852,
			2327.819,
			2329.786,
			2332.75,
			2334.738,
			2337.754,
			2338.771,
			2339.789,
			2342.867,
			2344.931,
			2348.036,
			2350.11,
			2353.228,
			2355.311,
			2356.356,
			2358.44,
			2361.583,
			2363.685,
			2365.798,
			2369.012,
			2371.175,
			2375.577,
			2376.686,
			2380.039,
			2382.289,
			2385.687,
			2387.969,
			2390.263,
			2391.414,
			2393.724,
			2394.883,
			2397.208,
			2400.694,
			2403.022,
			2405.352,
			2408.864,
			2411.217,
			2414.783,
			2417.176,
			2420.799,
			2423.22,
			2426.849,
			2429.256,
			2432.84,
			2436.391,
			2438.74,
			2442.245,
			2444.569,
			2446.885,
			2450.343,
			2451.493,
			2452.642,
			2456.09,
			2458.389,
			2461.843,
			2464.149,
			2466.46,
			2469.937,
			2473.431,
			2475.767,
			2476.939,
			2479.286,
			2481.642,
			2482.823,
			2485.184,
			2487.555,
			2491.115,
			2492.307,
			2493.498,
			2497.078,
			2498.274,
			2499.468,
			2503.064,
			2505.459,
			2507.863,
			2513.868,
			2517.483,
			2518.694,
			2519.908,
			2523.567,
			2524.795,
			2526.005,
			2529.708,
			2532.189,
			2534.682,
			2535.933,
			2538.441,
			2540.96,
			2542.222,
			2546.023,
			2548.567,
			2551.121,
			2554.968,
			2557.544,
			2561.424,
			2562.722,
			2564.042,
			2569.233,
			2570.56,
			2574.474,
			2577.069,
			2581.024,
			2583.63,
			2586.281,
			2587.596,
			2590.215,
			2591.538,
			2594.188,
			2596.843,
			2600.837,
			2603.509,
			2607.531,
			2608.875,
			2610.221,
			2614.268,
			2616.976,
			2621.072,
			2623.793,
			2627.854,
			2630.602,
			2631.965,
			2634.705,
			2637.454,
			2638.829,
			2641.56,
			2644.321,
			2648.496,
			2651.25,
			2655.428,
			2658.221,
			2662.425,
			2665.235,
			2669.464,
			2672.291,
			2676.543,
			2679.388,
			2680.812,
			2683.667,
			2686.529,
			2687.962,
			2690.833,
			2693.711,
			2698.041,
			2700.936,
			2705.29,
			2706.744,
			2708.2,
			2712.578,
			2715.505,
			2719.907,
			2722.85,
			2727.276,
			2730.234,
			2731.715,
			2734.683,
			2737.657,
			2739.146,
			2742.128,
			2745.116,
			2749.609,
			2752.612,
			2757.127,
			2758.636,
			2760.147,
			2764.688,
			2767.722,
			2772.285,
			2775.333,
			2778.388,
			2782.98,
			2784.514,
			2787.585,
			2790.662,
			2792.203,
			2795.289,
			2798.38,
			2803.026,
			2806.131,
			2810.799,
			2812.358,
			2813.917,
			2817.04,
			2818.604,
			2821.735,
			2824.871,
			2826.44,
			2829.584,
			2832.732,
			2837.465,
			2839.045,
			2840.627,
			2845.38,
			2846.967,
			2848.555,
			2853.328,
			2856.51,
			2861.291,
			2864.484,
			2867.681,
			2869.282,
			2874.091,
			2877.302,
			2880.519,
			2885.356,
			2888.592,
			2893.457,
			2895.08,
			2896.701,
			2901.568,
			2904.817,
			2909.697,
			2912.955,
			2917.847,
			2924.382,
			2926.019,
			2929.294,
			2930.933,
			2934.214,
			2937.501,
			2942.442,
			2945.743,
			2952.356,
			2954.013,
			2958.988,
			2960.648,
			2962.309,
			2967.299,
			2970.632,
			2975.637,
			2978.979,
			2982.326,
			2984.001,
			2987.353,
			2989.031,
			2992.389,
			2995.75,
			3000.799,
			3004.169,
			3009.231,
			3010.921,
			3012.61,
			3017.686,
			3021.074,
			3026.163,
			3029.562,
			3032.96,
			3034.657,
			3038.036,
			3039.716,
			3043.047,
			3046.336,
			3047.967,
			3051.198,
			3054.389,
			3059.104,
			3062.198,
			3068.272,
			3069.768,
			3074.202,
			3077.112,
			3081.406,
			3084.22,
			3088.37,
			3093.772,
			3095.101,
			3097.73,
			3099.031,
			3101.604,
			3104.139,
			3107.872,
			3110.314,
			3115.098,
			3116.274,
			3119.774,
			3122.049,
			3125.401,
			3127.599,
			3130.851,
			3132.996,
			3134.065,
			3136.192,
			3139.392,
			3141.539,
			3143.695,
			3146.965,
			3149.161,
			3153.621,
			3154.742,
			3157.034,
			3158.183,
			3160.496,
			3164.017,
			3166.352,
			3168.677,
			3172.127,
			3173.267,
			3175.538,
			3178.933,
			3180.055,
			3183.473,
			3185.747,
			3189.18,
			3192.616,
			3193.761,
			3194.904,
			3198.303,
			3200.557,
			3202.765,
			3206.055,
			3208.219,
			3212.527,
			3213.601,
			3217.863,
			3218.921,
			3222.093,
			3224.202,
			3227.371,
			3229.487,
			3232.655,
			3235.814,
			3237.907,
			3239.997,
			3243.117,
			3245.192,
			3248.301,
			3250.375,
			3253.504,
			3255.602,
			3258.753,
			3260.878,
			3264.105,
			3268.42,
			3269.488,
			3272.776,
			3274.968,
			3277.168,
			3280.463,
			3282.669,
			3285.987,
			3288.217,
			3291.592,
			3293.861,
			3297.289,
			3299.589,
			3303.06,
			3306.561,
			3308.889,
			3312.429,
			3314.798,
			3317.176,
			3320.749,
			3323.156,
			3326.744,
			3329.137,
			3332.714,
			3335.119,
			3338.743,
			3341.169,
			3344.824,
			3347.291,
			3348.496,
			3350.969,
			3354.64,
			3357.109,
			3359.587,
			3363.321,
			3372.101,
			3375.894,
			3378.432,
			3382.254,
			3384.813,
			3387.377,
			3388.662,
			3391.23,
			3392.512,
			3395.067,
			3398.858,
			3400.107,
			3403.802,
			3407.413,
			3409.773,
			3413.244,
			3414.381,
			3415.51,
			3418.841,
			3421.015,
			3424.21,
			3426.296,
			3428.349,
			3429.362,
			3431.384,
			3433.365,
			3437.275,
			3438.246,
			3441.146,
			3443.072,
			3445.95,
			3447.859,
			3449.767,
			3450.72,
			3453.573,
			3455.473,
			3457.377,
			3460.231,
			3462.142,
			3465.011,
			3465.97,
			3466.935,
			3469.829,
			3470.794,
			3471.772,
			3474.71,
			3476.683,
			3479.678,
			3481.683,
			3483.705,
			3484.716,
			3487.753,
			3489.779,
			3491.805,
			3494.838,
			3496.862,
			3500.911,
			3501.928,
			3504.988,
			3506.01,
			3507.033,
			3510.111,
			3512.168,
			3514.229,
			3515.26,
			3517.318,
			3519.366,
			3520.393,
			3522.45,
			3523.479,
			3525.542,
			3527.624,
			3530.784,
			3532.915,
			3536.142,
			3538.326,
			3541.629,
			3543.836,
			3547.156,
			3549.324,
			3552.58,
			3555.809,
			3557.977,
			3560.161,
			3561.256,
			3563.47,
			3565.702,
			3569.079,
			3571.348,
			3574.742,
			3575.875,
			3577.01,
			3580.394,
			3582.656,
			3586.017,
			3588.261,
			3591.619,
			3593.853,
			3594.983,
			3597.269,
			3599.555,
			3600.703,
			3603.006,
			3605.326,
			3608.809,
			3611.161,
			3615.905,
			3617.098,
			3620.694,
			3623.104,
			3626.74,
			3629.175,
			3631.618,
			3632.844,
			3635.3,
			3636.531,
			3639,
			3642.719,
			3645.21,
			3647.712,
			3651.483,
			3654.008,
			3657.813,
			3659.085,
			3660.36,
			3662.915,
			3664.195,
			3666.763,
			3670.631,
			3673.219,
			3675.818,
			3679.735,
			3681.044,
			3682.354,
			3686.291,
			3687.608,
			3688.927,
			3692.888,
			3695.535,
			3699.511,
			3702.169,
			3704.835,
			3706.171,
			3710.19,
			3712.9,
			3715.619,
			3719.729,
			3722.461,
			3726.557,
			3727.92,
			3729.284,
			3734.744,
			3736.109,
			3740.196,
			3742.936,
			3747.07,
			3749.855,
			3752.652,
			3754.055,
			3758.278,
			3761.104,
			3763.931,
			3768.179,
			3771.016,
			3775.285,
			3776.712,
			3778.14,
			3782.436,
			3783.871,
			3785.309,
			3789.63,
			3792.519,
			3795.415,
			3796.866,
			3799.772,
			3804.143,
			3808.527,
			3811.453,
			3814.385,
			3818.799,
			3821.758,
			3826.208,
			3827.696,
			3829.183,
			3833.654,
			3836.641,
			3841.135,
			3844.14,
			3847.156,
			3848.665,
			3851.688,
			3853.202,
			3856.229,
			3859.262,
			3860.781,
			3863.822,
			3866.868,
			3871.446,
			3874.507,
			3879.108,
			3882.165,
			3886.771,
			3889.851,
			3894.518,
			3897.642,
			3902.366,
			3906.084
		],
		[
			0,
			0.01,
			0.075,
			0.153,
			0.197,
			0.262,
			0.322,
			0.37,
			0.448,
			0.509,
			0.588,
			0.634,
			0.698,
			0.76,
			0.805,
			0.821,
			0.883,
			0.947,
			1.025,
			1.071,
			1.134,
			1.197,
			1.258,
			1.322,
			1.351,
			1.384,
			1.465,
			1.507,
			1.571,
			1.631,
			1.695,
			1.759,
			1.82,
			1.898,
			1.945,
			2.007,
			2.07,
			2.132,
			2.225,
			2.256,
			2.334,
			2.38,
			2.443,
			2.506,
			2.569,
			2.63,
			2.662,
			2.694,
			2.772,
			2.818,
			2.882,
			2.943,
			2.991,
			3.006,
			3.068,
			3.098,
			3.129,
			3.208,
			3.256,
			3.317,
			3.379,
			3.427,
			3.443,
			3.505,
			3.541,
			3.567,
			3.644,
			3.692,
			3.754,
			3.816,
			3.864,
			3.879,
			3.942,
			4.005,
			4.083,
			4.128,
			4.19,
			4.254,
			4.315,
			4.376,
			4.409,
			4.441,
			4.503,
			4.52,
			4.566,
			4.629,
			4.689,
			4.752,
			4.846,
			4.876,
			4.954,
			5.002,
			5.065,
			5.126,
			5.174,
			5.251,
			5.283,
			5.312,
			5.393,
			5.438,
			5.502,
			5.563,
			5.627,
			5.688,
			5.72,
			5.75,
			5.829,
			5.876,
			5.939,
			6.001,
			6.047,
			6.124,
			6.157,
			6.188,
			6.265,
			6.313,
			6.374,
			6.438,
			6.484,
			6.498,
			6.563,
			6.623,
			6.701,
			6.75,
			6.813,
			6.873,
			6.921,
			6.999,
			7.06,
			7.122,
			7.184,
			7.247,
			7.311,
			7.356,
			7.372,
			7.435,
			7.467,
			7.497,
			7.559,
			7.576,
			7.621,
			7.688,
			7.747,
			7.792,
			7.871,
			7.935,
			8.011,
			8.059,
			8.121,
			8.182,
			8.246,
			8.308,
			8.34,
			8.37,
			8.433,
			8.449,
			8.494,
			8.558,
			8.618,
			8.668,
			8.744,
			8.776,
			8.808,
			8.867,
			8.884,
			8.932,
			8.993,
			9.057,
			9.104,
			9.119,
			9.181,
			9.213,
			9.243,
			9.322,
			9.366,
			9.431,
			9.491,
			9.554,
			9.618,
			9.678,
			9.761,
			9.805,
			9.868,
			9.93,
			9.993,
			10.054,
			10.087,
			10.117,
			10.194,
			10.241,
			10.305,
			10.365,
			10.429,
			10.521,
			10.554,
			10.614,
			10.632,
			10.677,
			10.74,
			10.801,
			10.849,
			10.928,
			10.959,
			10.988,
			11.067,
			11.114,
			11.178,
			11.24,
			11.286,
			11.301,
			11.364,
			11.394,
			11.426,
			11.489,
			11.504,
			11.55,
			11.615,
			11.676,
			11.738,
			11.799,
			11.836,
			11.864,
			11.926,
			11.988,
			12.051,
			12.113,
			12.174,
			12.237,
			12.269,
			12.298,
			12.379,
			12.425,
			12.487,
			12.549,
			12.597,
			12.675,
			12.704,
			12.736,
			12.798,
			12.861,
			12.925,
			12.985,
			13.048,
			13.111,
			13.142,
			13.172,
			13.237,
			13.251,
			13.297,
			13.364,
			13.422,
			13.485,
			13.579,
			13.609,
			13.673,
			13.734,
			13.798,
			13.86,
			13.922,
			14.015,
			14.046,
			14.11,
			14.124,
			14.172,
			14.232,
			14.296,
			14.358,
			14.42,
			14.451,
			14.483,
			14.562,
			14.607,
			14.671,
			14.732,
			14.796,
			14.858,
			14.92,
			14.998,
			15.044,
			15.108,
			15.171,
			15.233,
			15.325,
			15.355,
			15.418,
			15.481,
			15.544,
			15.606,
			15.67,
			15.732,
			15.762,
			15.794,
			15.856,
			15.872,
			15.917,
			15.98,
			16.042,
			16.106,
			16.199,
			16.231,
			16.293,
			16.355,
			16.418,
			16.479,
			16.541,
			16.604,
			16.636,
			16.668,
			16.728,
			16.744,
			16.792,
			16.854,
			16.915,
			16.979,
			17.072,
			17.103,
			17.182,
			17.227,
			17.29,
			17.354,
			17.415,
			17.477,
			17.539,
			17.618,
			17.664,
			17.727,
			17.79,
			17.851,
			17.914,
			17.976,
			18.057,
			18.099,
			18.163,
			18.226,
			18.288,
			18.381,
			18.412,
			18.492,
			18.538,
			18.601,
			18.661,
			18.725,
			18.788,
			18.849,
			18.927,
			18.975,
			19.037,
			19.1,
			19.145,
			19.223,
			19.287,
			19.363,
			19.412,
			19.473,
			19.535,
			19.599,
			19.691,
			19.723,
			19.8,
			19.847,
			19.909,
			19.973,
			20.02,
			20.098,
			20.133,
			20.159,
			20.238,
			20.283,
			20.348,
			20.41,
			20.472,
			20.532,
			20.596,
			20.675,
			20.721,
			20.785,
			20.846,
			20.909,
			20.971,
			21.035,
			21.11,
			21.159,
			21.221,
			21.283,
			21.331,
			21.346,
			21.407,
			21.471,
			21.549,
			21.595,
			21.658,
			21.72,
			21.766,
			21.782,
			21.844,
			21.906,
			21.985,
			22.033,
			22.094,
			22.157,
			22.206,
			22.219,
			22.281,
			22.344,
			22.422,
			22.47,
			22.532,
			22.593,
			22.64,
			22.656,
			22.717,
			22.75,
			22.781,
			22.843,
			22.906,
			22.968,
			23.031,
			23.092,
			23.155,
			23.215,
			23.295,
			23.341,
			23.404,
			23.468,
			23.515,
			23.53,
			23.592,
			23.655,
			23.717,
			23.733,
			23.778,
			23.841,
			23.905,
			23.949,
			24.028,
			24.092,
			24.167,
			24.216,
			24.282,
			24.34,
			24.404,
			24.466,
			24.529,
			24.606,
			24.654,
			24.715,
			24.777,
			24.84,
			24.902,
			24.933,
			24.963,
			25.026,
			25.042,
			25.089,
			25.152,
			25.213,
			25.261,
			25.277,
			25.339,
			25.4,
			25.48,
			25.525,
			25.589,
			25.649,
			25.698,
			25.775,
			25.837,
			25.915,
			25.963,
			26.026,
			26.088,
			26.149,
			26.242,
			26.273,
			26.355,
			26.4,
			26.461,
			26.523,
			26.571,
			26.649,
			26.712,
			26.789,
			26.835,
			26.897,
			26.961,
			27.007,
			27.023,
			27.084,
			27.148,
			27.226,
			27.272,
			27.335,
			27.398,
			27.458,
			27.523,
			27.585,
			27.663,
			27.708,
			27.772,
			27.834,
			27.88,
			27.895,
			27.958,
			28.022,
			28.098,
			28.144,
			28.209,
			28.271,
			28.316,
			28.333,
			28.394,
			28.456,
			28.535,
			28.581,
			28.647,
			28.707,
			28.768,
			28.833,
			28.862,
			28.893,
			28.972,
			29.018,
			29.082,
			29.143,
			29.19,
			29.205,
			29.268,
			29.329,
			29.409,
			29.456,
			29.518,
			29.579,
			29.641,
			29.704,
			29.767,
			29.844,
			29.892,
			29.956,
			30.016,
			30.064,
			30.079,
			30.14,
			30.204,
			30.266,
			30.282,
			30.328,
			30.391,
			30.454,
			30.505,
			30.579,
			30.609,
			30.642,
			30.718,
			30.766,
			30.829,
			30.891,
			30.953,
			31.014,
			31.076,
			31.155,
			31.203,
			31.265,
			31.326,
			31.373,
			31.451,
			31.515,
			31.592,
			31.638,
			31.699,
			31.764,
			31.81,
			31.825,
			31.889,
			31.95,
			32.029,
			32.076,
			32.139,
			32.201,
			32.263,
			32.325,
			32.355,
			32.388,
			32.45,
			32.512,
			32.577,
			32.637,
			32.683,
			32.698,
			32.76,
			32.823,
			32.902,
			32.949,
			33.011,
			33.073,
			33.12,
			33.197,
			33.261,
			33.338,
			33.384,
			33.449,
			33.51,
			33.558,
			33.574,
			33.634,
			33.665,
			33.697,
			33.761,
			33.774,
			33.823,
			33.883,
			33.948,
			33.994,
			34.072,
			34.104,
			34.133,
			34.211,
			34.26,
			34.322,
			34.384,
			34.445,
			34.51,
			34.539,
			34.571,
			34.654,
			34.695,
			34.759,
			34.821,
			34.883,
			34.977,
			35.007,
			35.087,
			35.133,
			35.196,
			35.256,
			35.32,
			35.381,
			35.412,
			35.445,
			35.508,
			35.521,
			35.568,
			35.633,
			35.693,
			35.755,
			35.818,
			35.881,
			35.958,
			36.006,
			36.069,
			36.131,
			36.193,
			36.287,
			36.318,
			36.395,
			36.442,
			36.505,
			36.568,
			36.613,
			36.628,
			36.693,
			36.727,
			36.755,
			36.833,
			36.877,
			36.94,
			37.005,
			37.065,
			37.159,
			37.191,
			37.253,
			37.269,
			37.315,
			37.378,
			37.439,
			37.488,
			37.503,
			37.565,
			37.629,
			37.705,
			37.753,
			37.815,
			37.878,
			37.939,
			38.032,
			38.065,
			38.141,
			38.188,
			38.25,
			38.313,
			38.376,
			38.471,
			38.501,
			38.562,
			38.579,
			38.625,
			38.689,
			38.751,
			38.801,
			38.876,
			38.938,
			39.016,
			39.063,
			39.126,
			39.187,
			39.235,
			39.25,
			39.311,
			39.374,
			39.451,
			39.498,
			39.563,
			39.623,
			39.671,
			39.75,
			39.781,
			39.811,
			39.888,
			39.936,
			39.997,
			40.061,
			40.123,
			40.185,
			40.217,
			40.248,
			40.326,
			40.373,
			40.434,
			40.498,
			40.559,
			40.654,
			40.684,
			40.762,
			40.808,
			40.874,
			40.935,
			40.98,
			40.995,
			41.059,
			41.09,
			41.122,
			41.198,
			41.247,
			41.307,
			41.371,
			41.418,
			41.494,
			41.558,
			41.634,
			41.683,
			41.746,
			41.807,
			41.87,
			41.963,
			41.996,
			42.073,
			42.12,
			42.185,
			42.244,
			42.291,
			42.306,
			42.368,
			42.431,
			42.508,
			42.557,
			42.617,
			42.681,
			42.744,
			42.806,
			42.838,
			42.869,
			42.929,
			42.951,
			42.994,
			43.057,
			43.118,
			43.163,
			43.179,
			43.243,
			43.274,
			43.305,
			43.383,
			43.43,
			43.491,
			43.6,
			43.679,
			43.711,
			43.742,
			43.804,
			43.819,
			43.866,
			43.929,
			43.99,
			44.039,
			44.053,
			44.117,
			44.147,
			44.176,
			44.257,
			44.302,
			44.365,
			44.427,
			44.474,
			44.551,
			44.584,
			44.613,
			44.693,
			44.738,
			44.801,
			44.862,
			44.927,
			44.989,
			45.024,
			45.049,
			45.112,
			45.129,
			45.174,
			45.238,
			45.301,
			45.362,
			45.425,
			45.455,
			45.487,
			45.565,
			45.611,
			45.673,
			45.738,
			45.798,
			45.861,
			45.892,
			45.925,
			45.987,
			46,
			46.047,
			46.112,
			46.172,
			46.236,
			46.298,
			46.36,
			46.424,
			46.485,
			46.546,
			46.61,
			46.673,
			46.733,
			46.766,
			46.796,
			46.86,
			46.875,
			46.921,
			46.982,
			47.047,
			47.109,
			47.171,
			47.2,
			47.233,
			47.296,
			47.357,
			47.421,
			47.482,
			47.545,
			47.607,
			47.638,
			47.67,
			47.733,
			47.746,
			47.794,
			47.858,
			47.918,
			47.98,
			48.045,
			48.074,
			48.107,
			48.168,
			48.232,
			48.294,
			48.355,
			48.416,
			48.479,
			48.512,
			48.543,
			48.605,
			48.621,
			48.669,
			48.73,
			48.792,
			48.853,
			48.918,
			48.949,
			48.98,
			49.042,
			49.056,
			49.105,
			49.166,
			49.171,
			49.23,
			49.29,
			49.354,
			49.386,
			49.417,
			49.479,
			49.495,
			49.54,
			49.604,
			49.666,
			49.729,
			49.79,
			49.823,
			49.851,
			49.931,
			49.978,
			50.04,
			50.103,
			50.164,
			50.228,
			50.259,
			50.291,
			50.35,
			50.415,
			50.478,
			50.538,
			50.602,
			50.694,
			50.725,
			50.787,
			50.805,
			50.849,
			50.914,
			50.977,
			51.038,
			51.132,
			51.163,
			51.225,
			51.245,
			51.288,
			51.35,
			51.412,
			51.474,
			51.537,
			51.569,
			51.599,
			51.662,
			51.678,
			51.724,
			51.788,
			51.849,
			51.912,
			51.972,
			52.006,
			52.037,
			52.115,
			52.162,
			52.227,
			52.287,
			52.333,
			52.347,
			52.412,
			52.442,
			52.474,
			52.534,
			52.55,
			52.599,
			52.662,
			52.722,
			52.784,
			52.878,
			52.91,
			52.988,
			53.032,
			53.098,
			53.159,
			53.222,
			53.319,
			53.347,
			53.41,
			53.425,
			53.471,
			53.532,
			53.595,
			53.657,
			53.752,
			53.781,
			53.845,
			53.909,
			53.97,
			54.033,
			54.095,
			54.157,
			54.188,
			54.219,
			54.298,
			54.345,
			54.407,
			54.469,
			54.53,
			54.624,
			54.657,
			54.717,
			54.735,
			54.78,
			54.843,
			54.904,
			54.967,
			55.029,
			55.062,
			55.092,
			55.171,
			55.215,
			55.282,
			55.341,
			55.392,
			55.467,
			55.497,
			55.529,
			55.607,
			55.653,
			55.715,
			55.778,
			55.84,
			55.934,
			55.965,
			56.044,
			56.09,
			56.153,
			56.214,
			56.277,
			56.339,
			56.401,
			56.479,
			56.526,
			56.589,
			56.652,
			56.699,
			56.777,
			56.838,
			56.916,
			56.963,
			57.025,
			57.088,
			57.149,
			57.243,
			57.275,
			57.353,
			57.399,
			57.466,
			57.524,
			57.586,
			57.649,
			57.711,
			57.788,
			57.837,
			57.898,
			57.96,
			58.023,
			58.117,
			58.148,
			58.225,
			58.271,
			58.336,
			58.396,
			58.444,
			58.523,
			58.583,
			58.662,
			58.708,
			58.772,
			58.832,
			58.896,
			58.958,
			58.989,
			59.021,
			59.1,
			59.147,
			59.208,
			59.271,
			59.457,
			59.541,
			59.583,
			59.644,
			59.708,
			59.755,
			59.769,
			59.831,
			59.862,
			59.894,
			59.972,
			60.017,
			60.083,
			60.143,
			60.205,
			60.27,
			60.299,
			60.332,
			60.393,
			60.457,
			60.518,
			60.581,
			60.628,
			60.643,
			60.706,
			60.767,
			60.845,
			60.892,
			60.956,
			61.017,
			61.08,
			61.141,
			61.173,
			61.205,
			61.283,
			61.329,
			61.392,
			61.454,
			61.516,
			61.579,
			61.614,
			61.641,
			61.704,
			61.719,
			61.767,
			61.829,
			61.891,
			61.951,
			62.015,
			62.045,
			62.077,
			62.155,
			62.203,
			62.264,
			62.327,
			62.389,
			62.483,
			62.515,
			62.577,
			62.593,
			62.639,
			62.7,
			62.763,
			62.81,
			62.826,
			62.888,
			62.92,
			62.949,
			63.012,
			63.029,
			63.077,
			63.139,
			63.2,
			63.245,
			63.324,
			63.388,
			63.465,
			63.512,
			63.576,
			63.637,
			63.699,
			63.792,
			63.823,
			63.886,
			63.9,
			63.948,
			64.012,
			64.072,
			64.135,
			64.198,
			64.229,
			64.26,
			64.339,
			64.386,
			64.447,
			64.509,
			64.571,
			64.634,
			64.665,
			64.695,
			64.759,
			64.774,
			64.822,
			64.882,
			64.946,
			65.009,
			65.102,
			65.132,
			65.196,
			65.259,
			65.321,
			65.382,
			65.429,
			65.445,
			65.508,
			65.54,
			65.57,
			65.648,
			65.695,
			65.76,
			65.819,
			65.883,
			65.943,
			65.974,
			66.006,
			66.067,
			66.083,
			66.132,
			66.193,
			66.254,
			66.317,
			66.381,
			66.412,
			66.442,
			66.505,
			66.521,
			66.566,
			66.629,
			66.691,
			66.755,
			66.816,
			66.847,
			66.879,
			66.958,
			67.004,
			67.065,
			67.129,
			67.191,
			67.254,
			67.286,
			67.315,
			67.393,
			67.44,
			67.504,
			67.565,
			67.628,
			67.69,
			67.722,
			67.753,
			67.832,
			67.877,
			67.941,
			68.002,
			68.049,
			68.127,
			68.156,
			68.187,
			68.25,
			68.268,
			68.313,
			68.376,
			68.439,
			68.486,
			68.5,
			68.562,
			68.625,
			68.704,
			68.75,
			68.814,
			68.874,
			68.938,
			69,
			69.032,
			69.062,
			69.125,
			69.187,
			69.249,
			69.312,
			69.358,
			69.374,
			69.436,
			69.465,
			69.5,
			69.56,
			69.577,
			69.623,
			69.687,
			69.749,
			69.794,
			69.873,
			69.936,
			70.013,
			70.059,
			70.122,
			70.186,
			70.247,
			70.312
		]
	],
	'9176_9177_7982': [
		[
			74.878
		],
		[
			0
		],
		[
			0,
			0.158,
			4.741,
			7.804,
			10.872,
			12.409,
			15.486,
			20.113,
			23.205,
			24.753,
			27.853,
			30.96,
			35.63,
			38.75,
			43.44,
			45.006,
			46.574,
			51.285,
			54.432,
			59.165,
			62.326,
			65.492,
			67.077,
			70.25,
			73.429,
			75.02,
			79.794,
			82.983,
			86.175,
			90.973,
			94.178,
			100.602,
			102.211,
			107.046,
			110.275,
			115.128,
			118.369,
			123.24,
			126.494,
			129.751,
			131.382,
			136.281,
			139.554,
			142.831,
			147.756,
			151.044,
			157.636,
			159.287,
			164.246,
			167.557,
			172.533,
			175.857,
			180.85,
			184.184,
			185.852,
			189.181,
			192.492,
			194.139,
			197.408,
			200.643,
			205.425,
			208.566,
			213.208,
			214.736,
			216.256,
			220.763,
			223.724,
			228.095,
			230.961,
			233.789,
			235.189,
			237.96,
			239.332,
			242.047,
			244.725,
			248.671,
			251.256,
			255.066,
			257.559,
			261.229,
			262.434,
			263.631,
			267.171,
			269.49,
			272.9,
			275.127,
			278.398,
			280.533,
			281.587,
			283.669,
			286.725,
			288.72,
			290.699,
			293.581,
			294.525,
			295.462,
			298.224,
			299.129,
			300.027,
			302.692,
			304.444,
			307.058,
			308.787,
			310.506,
			313.071,
			315.606,
			318.14,
			318.987,
			321.527,
			324.096,
			325.824,
			328.435,
			329.324,
			330.213,
			332.885,
			334.724,
			337.488,
			339.384,
			341.296,
			344.211,
			345.201,
			346.192,
			350.246,
			351.279,
			354.432,
			356.578,
			358.735,
			359.816,
			361.985,
			365.202,
			368.4,
			370.493,
			372.591,
			375.7,
			377.769,
			380.837,
			381.858,
			382.88,
			386.919,
			387.927,
			390.928,
			392.925,
			394.9,
			395.884,
			397.85,
			399.773,
			400.725,
			403.575,
			405.431,
			407.283,
			410.08,
			411.955,
			414.795,
			415.753,
			416.713,
			419.59,
			421.503,
			424.385,
			426.307,
			429.217,
			431.191,
			433.161,
			434.161,
			437.202,
			439.246,
			441.311,
			444.43,
			446.519,
			449.669,
			450.716,
			451.763,
			454.917,
			457.035,
			460.231,
			462.375,
			464.529,
			465.611,
			467.783,
			468.873,
			471.061,
			473.259,
			474.363,
			476.578,
			478.804,
			482.163,
			484.415,
			487.816,
			488.954,
			490.096,
			493.536,
			495.843,
			499.324,
			501.659,
			504.004,
			505.18,
			507.543,
			508.728,
			511.106,
			514.693,
			517.098,
			519.514,
			523.158,
			524.377,
			525.6,
			529.281,
			531.748,
			535.468,
			537.959,
			541.714,
			544.229,
			548.017,
			550.554,
			551.826,
			554.375,
			556.933,
			558.215,
			560.786,
			563.365,
			567.252,
			568.552,
			569.854,
			573.763,
			575.068,
			576.374,
			580.303,
			582.933,
			586.892,
			589.541,
			592.197,
			593.529,
			596.197,
			597.534,
			600.212,
			602.921,
			604.267,
			606.964,
			609.669,
			613.736,
			616.454,
			620.532,
			621.894,
			623.257,
			627.354,
			630.1,
			632.854,
			637.01,
			639.798,
			644,
			645.407,
			646.816,
			652.488,
			653.917,
			658.221,
			661.11,
			665.464,
			668.378,
			671.309,
			672.777,
			675.714,
			677.185,
			680.127,
			683.072,
			687.499,
			690.458,
			694.894,
			696.367,
			697.836,
			703.657,
			705.097,
			709.37,
			712.173,
			714.939,
			716.309,
			719.022,
			721.712,
			723.049,
			725.716,
			727.052,
			729.725,
			732.41,
			736.455,
			739.166,
			743.242,
			744.604,
			745.966,
			750.061,
			752.798,
			756.912,
			759.658,
			763.785,
			766.541,
			769.304,
			770.687,
			774.843,
			777.618,
			780.389,
			784.553,
			787.333,
			791.525,
			792.925,
			794.328,
			798.542,
			801.357,
			805.589,
			808.416,
			812.672,
			815.51,
			818.347,
			819.768,
			822.611,
			824.034,
			826.89,
			829.751,
			834.056,
			836.905,
			842.653,
			844.091,
			848.391,
			851.255,
			855.552,
			858.42,
			862.737,
			865.626,
			867.077,
			869.984,
			874.35,
			877.262,
			880.169,
			884.528,
			887.436,
			893.258,
			894.714,
			899.108,
			902.022,
			906.388,
			909.297,
			913.662,
			918.03,
			920.924,
			923.85,
			925.317,
			928.279,
			931.228,
			935.664,
			938.631,
			943.107,
			946.11,
			950.623,
			953.636,
			958.159,
			961.166,
			965.661,
			968.644,
			973.097,
			977.526,
			980.446,
			983.409,
			987.804,
			990.729,
			996.564,
			998.015,
			1000.886,
			1002.326,
			1005.224,
			1009.517,
			1012.374,
			1015.23,
			1019.514,
			1022.374,
			1028.105,
			1029.539,
			1033.824,
			1036.727,
			1041.055,
			1046.813,
			1048.261,
			1052.611,
			1055.515,
			1058.424,
			1062.792,
			1065.71,
			1070.096,
			1073.026,
			1078.904,
			1080.378,
			1084.806,
			1087.765,
			1090.73,
			1092.215,
			1095.188,
			1099.66,
			1104.146,
			1107.143,
			1110.147,
			1114.665,
			1117.686,
			1122.246,
			1125.331,
			1129.916,
			1133.005,
			1137.649,
			1140.758,
			1145.445,
			1151.713,
			1153.284,
			1156.432,
			1158.009,
			1161.188,
			1164.315,
			1169.052,
			1172.217,
			1176.975,
			1180.151,
			1184.932,
			1188.155,
			1192.963,
			1196.177,
			1200.995,
			1207.417,
			1209.024,
			1213.852,
			1217.071,
			1220.287,
			1225.108,
			1228.33,
			1233.172,
			1236.408,
			1241.258,
			1244.492,
			1249.323,
			1252.6,
			1257.497,
			1260.766,
			1265.669,
			1270.573,
			1273.813,
			1277.085,
			1282.031,
			1285.288,
			1290.248,
			1293.541,
			1298.462,
			1301.761,
			1306.71,
			1310.013,
			1313.323,
			1314.98,
			1318.332,
			1323.303,
			1328.311,
			1331.653,
			1334.997,
			1340.008,
			1343.344,
			1348.348,
			1351.684,
			1356.713,
			1360.021,
			1365.058,
			1368.374,
			1373.391,
			1378.411,
			1381.794,
			1386.808,
			1390.176,
			1393.549,
			1398.613,
			1401.992,
			1405.371,
			1408.748,
			1410.438,
			1415.507,
			1418.895,
			1423.989,
			1427.391,
			1432.502,
			1435.913,
			1441.034,
			1446.163,
			1447.875,
			1453.013,
			1456.443,
			1461.594,
			1468.473,
			1470.195,
			1475.365,
			1478.815,
			1483.996,
			1487.454,
			1490.915,
			1496.112,
			1499.581,
			1506.526,
			1508.264,
			1513.483,
			1516.966,
			1522.196,
			1525.687,
			1530.928,
			1536.175,
			1539.677,
			1543.182,
			1548.444,
			1551.956,
			1557.23,
			1558.989,
			1560.749,
			1567.796,
			1569.559,
			1574.853,
			1578.386,
			1581.922,
			1583.691,
			1587.231,
			1592.547,
			1597.869,
			1601.42,
			1604.975,
			1610.312,
			1613.874,
			1619.221,
			1622.789,
			1629.933,
			1631.721,
			1637.088,
			1640.67,
			1646.047,
			1649.636,
			1653.227,
			1655.024,
			1660.42,
			1664.022,
			1667.627,
			1673.043,
			1676.657,
			1682.08,
			1685.7,
			1691.133,
			1694.755,
			1700.187,
			1703.807,
			1707.423,
			1709.232,
			1712.854,
			1718.296,
			1723.75,
			1727.393,
			1731.04,
			1736.513,
			1740.165,
			1745.646,
			1747.473,
			1749.301,
			1754.782,
			1758.427,
			1763.858,
			1767.441,
			1770.986,
			1772.744,
			1776.229,
			1781.383,
			1786.446,
			1789.781,
			1793.08,
			1797.953,
			1801.156,
			1805.89,
			1807.448,
			1808.998,
			1813.587,
			1816.6,
			1821.052,
			1823.972,
			1828.278,
			1831.102,
			1832.499,
			1835.263,
			1839.337,
			1842.004,
			1844.632,
			1848.506,
			1849.779,
			1851.043,
			1854.777,
			1856.004,
			1857.221,
			1860.822,
			1863.181,
			1866.66,
			1868.955,
			1872.304,
			1874.495,
			1877.716,
			1880.877,
			1882.946,
			1884.992,
			1888.018,
			1890.027,
			1893.037,
			1894.042,
			1895.047,
			1898.122,
			1900.185,
			1903.369,
			1905.509,
			1907.71,
			1908.824,
			1911.05,
			1914.472,
			1917.906,
			1919.051,
			1922.462,
			1924.706,
			1927.994,
			1930.09,
			1933.227,
			1935.293,
			1936.33,
			1938.403,
			1940.484,
			1943.616,
			1945.711,
			1948.864,
			1949.916,
			1950.967,
			1954.138,
			1955.222,
			1956.282,
			1959.474,
			1961.598,
			1964.771,
			1966.871,
			1968.977,
			1970.044,
			1972.137,
			1973.18,
			1975.217,
			1978.253,
			1980.244,
			1982.22,
			1985.164,
			1986.139,
			1987.112,
			1990.017,
			1991.94,
			1994.804,
			1996.697,
			1999.527,
			2001.405,
			2003.279,
			2004.215,
			2006.088,
			2007.025,
			2008.898,
			2010.775,
			2013.595,
			2015.478,
			2018.309,
			2019.253,
			2020.199,
			2023.056,
			2024.965,
			2027.864,
			2029.804,
			2032.744,
			2035.705,
			2037.719,
			2040.75,
			2042.804,
			2044.86,
			2047.966,
			2050.03,
			2053.144,
			2054.169,
			2055.198,
			2058.255,
			2060.269,
			2063.26,
			2065.257,
			2068.265,
			2070.278,
			2071.289,
			2073.315,
			2076.37,
			2078.419,
			2080.477,
			2083.592,
			2085.692,
			2089.964,
			2091.046,
			2094.339,
			2096.547,
			2099.869,
			2102.089,
			2105.423,
			2108.767,
			2111.002,
			2113.241,
			2114.358,
			2116.572,
			2118.807,
			2122.151,
			2124.371,
			2128.804,
			2129.915,
			2133.249,
			2135.474,
			2138.813,
			2141.041,
			2144.381,
			2146.608,
			2149.948,
			2153.295,
			2155.532,
			2157.773,
			2161.151,
			2163.411,
			2165.68,
			2167.963,
			2169.107,
			2171.404,
			2172.559,
			2174.872,
			2178.353,
			2180.676,
			2182.995,
			2186.46,
			2187.608,
			2188.753,
			2192.163,
			2193.292,
			2194.418,
			2197.787,
			2200.025,
			2203.37,
			2207.805,
			2208.907,
			2211.104,
			2212.199,
			2214.389,
			2216.572,
			2219.835,
			2222.002,
			2225.245,
			2226.323,
			2227.399,
			2230.623,
			2231.695,
			2232.764,
			2235.969,
			2238.099,
			2241.288,
			2245.519,
			2246.573,
			2248.677,
			2249.726,
			2251.828,
			2253.926,
			2257.08,
			2259.187,
			2263.422,
			2264.483,
			2267.676,
			2269.807,
			2273.008,
			2275.143,
			2278.343,
			2280.475,
			2282.601,
			2283.659,
			2285.774,
			2286.831,
			2288.938,
			2291.041,
			2294.199,
			2296.304,
			2300.533,
			2301.592,
			2304.772,
			2306.893,
			2310.075,
			2312.196,
			2315.375,
			2317.491,
			2318.548,
			2320.657,
			2322.762,
			2323.814,
			2325.923,
			2328.031,
			2331.204,
			2333.32,
			2337.552,
			2338.609,
			2341.788,
			2343.909,
			2347.081,
			2349.193,
			2352.362,
			2355.536,
			2357.657,
			2359.781,
			2360.845,
			2362.978,
			2365.112,
			2368.32,
			2370.459,
			2373.679,
			2374.754,
			2375.83,
			2379.07,
			2381.234,
			2384.479,
			2386.647,
			2388.817,
			2389.903,
			2392.083,
			2393.177,
			2395.374,
			2397.579,
			2398.688,
			2400.915,
			2403.153,
			2406.535,
			2408.802,
			2412.228,
			2413.375,
			2414.528,
			2418.006,
			2420.34,
			2422.688,
			2426.241,
			2428.63,
			2433.456,
			2434.671,
			2439.559,
			2440.784,
			2444.462,
			2446.912,
			2450.584,
			2455.438,
			2456.641,
			2459.025,
			2460.209,
			2462.565,
			2464.903,
			2468.379,
			2470.675,
			2475.236,
			2476.37,
			2479.763,
			2480.891,
			2482.017,
			2485.383,
			2487.622,
			2489.859,
			2490.978,
			2495.459,
			2496.581,
			2498.83,
			2499.956,
			2502.21,
			2504.474,
			2507.885,
			2510.172,
			2513.615,
			2514.767,
			2515.922,
			2519.396,
			2521.723,
			2525.225,
			2527.57,
			2531.097,
			2533.458,
			2534.641,
			2537.014,
			2539.396,
			2540.59,
			2542.986,
			2545.371,
			2548.992,
			2551.414,
			2556.282,
			2557.504,
			2561.182,
			2563.643,
			2567.357,
			2569.846,
			2573.602,
			2577.379,
			2579.909,
			2583.72,
			2586.27,
			2588.829,
			2592.682,
			2595.26,
			2599.143,
			2600.441,
			2601.741,
			2605.649,
			2608.276,
			2612.174,
			2614.817,
			2618.736,
			2622.691,
			2625.337,
			2627.989,
			2629.339,
			2631.976,
			2634.642,
			2638.652,
			2641.333,
			2646.737,
			2648.063,
			2652.12,
			2654.855,
			2658.915,
			2661.667,
			2665.751,
			2668.499,
			2669.875,
			2672.633,
			2675.397,
			2676.782,
			2679.558,
			2682.34,
			2686.526,
			2687.924,
			2689.325,
			2694.944,
			2696.353,
			2700.591,
			2703.425,
			2706.265,
			2710.538,
			2714.827,
			2717.693,
			2719.129,
			2722.006,
			2724.889,
			2726.333,
			2729.227,
			2732.127,
			2735.033,
			2739.406,
			2742.33,
			2745.259,
			2746.726,
			2749.666,
			2751.138,
			2754.087,
			2757.042,
			2761.486,
			2764.456,
			2768.922,
			2770.414,
			2771.907,
			2776.395,
			2777.894,
			2779.394,
			2783.905,
			2786.919,
			2791.453,
			2794.482,
			2797.517,
			2799.037,
			2802.081,
			2803.605,
			2806.658,
			2809.717,
			2814.315,
			2817.388,
			2822.008,
			2823.551,
			2825.095,
			2829.736,
			2832.837,
			2837.499,
			2840.614,
			2845.296,
			2848.425,
			2851.558,
			2853.127,
			2856.269,
			2857.841,
			2860.991,
			2864.146,
			2868.889,
			2872.058,
			2876.82,
			2878.41,
			2880,
			2884.778,
			2887.964,
			2892.752,
			2895.95,
			2900.754,
			2903.963,
			2905.569,
			2908.785,
			2912.005,
			2913.616,
			2916.843,
			2920.073,
			2924.927,
			2928.169,
			2933.039,
			2934.665,
			2936.291,
			2941.177,
			2944.44,
			2949.343,
			2952.616,
			2957.534,
			2960.818,
			2962.462,
			2965.752,
			2969.047,
			2970.696,
			2973.996,
			2977.301,
			2982.266,
			2985.582,
			2990.562,
			2992.224,
			2993.887,
			2998.882,
			3002.217,
			3007.227,
			3010.572,
			3013.92,
			3015.596,
			3018.95,
			3020.628,
			3023.988,
			3027.351,
			3029.034,
			3032.403,
			3035.775,
			3040.84,
			3044.222,
			3049.3,
			3050.995,
			3052.689,
			3057.767,
			3061.136,
			3066.146,
			3069.448,
			3072.713,
			3077.544,
			3079.136,
			3082.291,
			3085.406,
			3086.946,
			3088.477,
			3093.013,
			3095.992,
			3100.389,
			3103.273,
			3106.12,
			3107.53,
			3111.704,
			3114.442,
			3117.141,
			3121.117,
			3123.72,
			3127.554,
			3128.813,
			3130.064,
			3133.763,
			3134.979,
			3136.186,
			3139.782,
			3142.131,
			3145.597,
			3147.873,
			3150.117,
			3151.23,
			3153.433,
			3154.525,
			3156.684,
			3158.855,
			3162.126,
			3164.339,
			3168.769,
			3169.882,
			3173.249,
			3175.509,
			3178.923,
			3181.207,
			3184.633,
			3186.91,
			3189.187,
			3190.335,
			3198.349,
			3201.792,
			3204.087,
			3207.507,
			3208.641,
			3209.773,
			3213.141,
			3215.368,
			3218.664,
			3220.843,
			3224.093,
			3227.327,
			3229.477,
			3232.687,
			3234.819,
			3236.945,
			3240.123,
			3242.237,
			3245.39,
			3246.442,
			3247.493,
			3250.627,
			3252.717,
			3255.83,
			3257.906,
			3261,
			3264.088,
			3266.138,
			3269.215,
			3271.263,
			3273.314,
			3276.404,
			3278.472,
			3282.632,
			3283.677,
			3286.829,
			3288.941,
			3292.13,
			3294.277,
			3297.519,
			3300.803,
			3303.003,
			3305.201,
			3306.33,
			3308.538,
			3310.757,
			3314.098,
			3316.352,
			3320.836,
			3321.97,
			3325.385,
			3327.7,
			3331.156,
			3333.472,
			3335.775,
			3336.958,
			3340.459,
			3342.777,
			3345.138,
			3346.312,
			3348.663,
			3350.998,
			3354.559,
			3356.913,
			3361.688,
			3362.887,
			3365.291,
			3366.496,
			3368.912,
			3371.337,
			3374.992,
			3377.44,
			3381.126,
			3382.359,
			3383.594,
			3387.312,
			3388.555,
			3389.801,
			3393.55,
			3396.06,
			3398.578,
			3399.84,
			3402.371,
			3406.181,
			3409.992,
			3412.521,
			3415.034,
			3418.754,
			3421.191,
			3424.778,
			3425.955,
			3427.122,
			3430.562,
			3432.808,
			3436.105,
			3438.259,
			3441.427,
			3443.496,
			3446.565,
			3448.569,
			3449.564,
			3451.542,
			3453.51,
			3456.438,
			3458.383,
			3462.263,
			3463.231,
			3466.139,
			3468.077,
			3470.984,
			3472.929,
			3475.842,
			3477.79,
			3479.736,
			3480.709,
			3482.658,
			3483.632,
			3485.578,
			3487.529,
			3490.457,
			3492.414,
			3495.364,
			3496.347,
			3497.337,
			3500.324,
			3502.318,
			3505.329,
			3507.333,
			3510.374,
			3513.41,
			3515.438,
			3517.464,
			3518.474,
			3520.481,
			3522.487,
			3525.489,
			3527.488,
			3531.478,
			3532.475,
			3535.478,
			3537.488,
			3540.505,
			3542.519,
			3544.538,
			3545.547,
			3548.584,
			3550.613,
			3553.666,
			3555.707,
			3557.749,
			3560.824,
			3562.873,
			3565.96,
			3568.015,
			3571.112,
			3573.17,
			3576.271,
			3578.336,
			3580.407,
			3581.445,
			3583.522,
			3586.647,
			3589.8,
			3591.908,
			3594.023,
			3597.204,
			3599.323,
			3603.543,
			3604.6,
			3607.779,
			3609.911,
			3613.135,
			3615.299,
			3617.475,
			3620.772,
			3624.092,
			3627.479,
			3629.742,
			3632.012,
			3635.436,
			3637.706,
			3641.158,
			3643.484,
			3646.952,
			3649.305,
			3652.821,
			3655.159,
			3658.714,
			3661.095,
			3663.485,
			3668.296,
			3669.505,
			3673.146,
			3675.584,
			3678.032,
			3679.26,
			3681.721,
			3685.431,
			3689.161,
			3691.66,
			3694.167,
			3697.947,
			3700.48,
			3704.296,
			3706.851,
			3711.982,
			3713.269,
			3717.142,
			3719.734,
			3722.334,
			3723.637,
			3726.24,
			3728.848,
			3730.154,
			3732.774,
			3734.086,
			3736.717,
			3739.355,
			3743.326,
			3745.983,
			3749.982,
			3752.658,
			3756.68,
			3759.367,
			3763.396,
			3766.089,
			3768.788,
			3770.14,
			3772.861,
			3775.591,
			3776.963,
			3781.092,
			3783.86,
			3786.64,
			3790.845,
			3793.653,
			3797.857,
			3800.642,
			3804.827,
			3807.629,
			3811.862,
			3814.696,
			3817.538,
			3818.963,
			3821.818,
			3826.111,
			3830.421,
			3833.302,
			3836.189,
			3840.534,
			3843.438,
			3847.807,
			3849.267,
			3850.729,
			3855.123,
			3858.068,
			3862.52,
			3865.512,
			3869.994,
			3872.977,
			3874.462,
			3877.438,
			3880.407,
			3881.895,
			3884.875,
			3887.87,
			3892.409,
			3895.458,
			3900.023,
			3901.545,
			3903.062,
			3907.611,
			3910.645,
			3915.213,
			3918.271,
			3921.338,
			3922.874,
			3922.892
		],
		[
			0,
			0.015,
			0.079,
			0.141,
			0.188,
			0.204,
			0.266,
			0.327,
			0.389,
			0.406,
			0.454,
			0.515,
			0.578,
			0.64,
			0.703,
			0.734,
			0.765,
			0.842,
			0.889,
			0.953,
			1.014,
			1.061,
			1.076,
			1.14,
			1.169,
			1.202,
			1.28,
			1.325,
			1.389,
			1.451,
			1.512,
			1.608,
			1.638,
			1.698,
			1.764,
			1.828,
			1.886,
			1.95,
			2.012,
			2.043,
			2.074,
			2.152,
			2.199,
			2.261,
			2.324,
			2.372,
			2.479,
			2.512,
			2.574,
			2.637,
			2.697,
			2.761,
			2.821,
			2.885,
			2.915,
			2.946,
			3.01,
			3.026,
			3.072,
			3.135,
			3.197,
			3.259,
			3.323,
			3.353,
			3.385,
			3.463,
			3.509,
			3.573,
			3.635,
			3.681,
			3.697,
			3.757,
			3.79,
			3.819,
			3.883,
			3.945,
			4.009,
			4.071,
			4.131,
			4.195,
			4.226,
			4.258,
			4.32,
			4.383,
			4.445,
			4.507,
			4.57,
			4.631,
			4.662,
			4.695,
			4.772,
			4.82,
			4.881,
			4.944,
			4.99,
			5.007,
			5.069,
			5.101,
			5.13,
			5.208,
			5.256,
			5.32,
			5.381,
			5.427,
			5.505,
			5.569,
			5.646,
			5.692,
			5.754,
			5.818,
			5.88,
			5.942,
			5.978,
			6.004,
			6.08,
			6.128,
			6.193,
			6.253,
			6.301,
			6.379,
			6.41,
			6.441,
			6.518,
			6.564,
			6.628,
			6.691,
			6.736,
			6.752,
			6.814,
			6.876,
			6.956,
			7.002,
			7.066,
			7.128,
			7.19,
			7.252,
			7.282,
			7.314,
			7.392,
			7.438,
			7.503,
			7.563,
			7.61,
			7.625,
			7.687,
			7.719,
			7.752,
			7.829,
			7.876,
			7.938,
			8.001,
			8.063,
			8.125,
			8.157,
			8.187,
			8.25,
			8.313,
			8.375,
			8.438,
			8.498,
			8.561,
			8.593,
			8.623,
			8.703,
			8.747,
			8.812,
			8.874,
			8.919,
			8.999,
			9.028,
			9.059,
			9.138,
			9.185,
			9.249,
			9.31,
			9.357,
			9.372,
			9.435,
			9.465,
			9.497,
			9.558,
			9.574,
			9.623,
			9.685,
			9.748,
			9.809,
			9.872,
			9.904,
			9.934,
			10.011,
			10.06,
			10.127,
			10.181,
			10.231,
			10.244,
			10.307,
			10.34,
			10.369,
			10.448,
			10.496,
			10.559,
			10.62,
			10.665,
			10.684,
			10.746,
			10.808,
			10.886,
			10.931,
			10.995,
			11.057,
			11.12,
			11.182,
			11.213,
			11.243,
			11.305,
			11.322,
			11.37,
			11.431,
			11.493,
			11.541,
			11.557,
			11.619,
			11.648,
			11.679,
			11.757,
			11.807,
			11.872,
			11.929,
			11.977,
			11.994,
			12.056,
			12.087,
			12.119,
			12.179,
			12.198,
			12.242,
			12.303,
			12.367,
			12.43,
			12.492,
			12.523,
			12.555,
			12.632,
			12.679,
			12.742,
			12.804,
			12.85,
			12.928,
			12.96,
			12.991,
			13.069,
			13.115,
			13.179,
			13.241,
			13.303,
			13.364,
			13.397,
			13.429,
			13.491,
			13.506,
			13.554,
			13.615,
			13.677,
			13.741,
			13.803,
			13.835,
			13.863,
			13.943,
			13.988,
			14.054,
			14.115,
			14.16,
			14.178,
			14.24,
			14.275,
			14.302,
			14.364,
			14.379,
			14.427,
			14.488,
			14.551,
			14.613,
			14.676,
			14.707,
			14.739,
			14.802,
			14.862,
			14.927,
			14.988,
			15.051,
			15.113,
			15.144,
			15.174,
			15.254,
			15.3,
			15.362,
			15.425,
			15.472,
			15.549,
			15.581,
			15.612,
			15.673,
			15.736,
			15.799,
			15.861,
			15.924,
			15.987,
			16.018,
			16.047,
			16.111,
			16.127,
			16.174,
			16.235,
			16.298,
			16.36,
			16.454,
			16.485,
			16.546,
			16.61,
			16.671,
			16.735,
			16.796,
			16.86,
			16.891,
			16.922,
			16.998,
			17.046,
			17.11,
			17.172,
			17.234,
			17.328,
			17.358,
			17.435,
			17.483,
			17.544,
			17.607,
			17.671,
			17.764,
			17.794,
			17.857,
			17.874,
			17.919,
			17.981,
			18.046,
			18.108,
			18.17,
			18.231,
			18.31,
			18.357,
			18.422,
			18.482,
			18.543,
			18.607,
			18.669,
			18.747,
			18.793,
			18.856,
			18.918,
			18.981,
			19.074,
			19.104,
			19.165,
			19.182,
			19.228,
			19.293,
			19.355,
			19.401,
			19.479,
			19.541,
			19.619,
			19.665,
			19.728,
			19.79,
			19.854,
			19.947,
			19.978,
			20.057,
			20.102,
			20.166,
			20.227,
			20.29,
			20.352,
			20.414,
			20.496,
			20.54,
			20.603,
			20.665,
			20.712,
			20.727,
			20.789,
			20.852,
			20.929,
			20.977,
			21.038,
			21.101,
			21.162,
			21.227,
			21.288,
			21.365,
			21.413,
			21.477,
			21.538,
			21.601,
			21.694,
			21.724,
			21.787,
			21.802,
			21.848,
			21.913,
			21.973,
			22.036,
			22.099,
			22.161,
			22.241,
			22.287,
			22.35,
			22.411,
			22.475,
			22.571,
			22.599,
			22.675,
			22.723,
			22.787,
			22.849,
			22.91,
			22.972,
			23.036,
			23.113,
			23.159,
			23.224,
			23.285,
			23.347,
			23.408,
			23.472,
			23.55,
			23.597,
			23.66,
			23.722,
			23.769,
			23.846,
			23.908,
			23.987,
			24.034,
			24.097,
			24.157,
			24.206,
			24.221,
			24.283,
			24.346,
			24.425,
			24.471,
			24.532,
			24.595,
			24.657,
			24.718,
			24.781,
			24.86,
			24.908,
			24.969,
			25.031,
			25.094,
			25.187,
			25.22,
			25.297,
			25.343,
			25.405,
			25.469,
			25.531,
			25.592,
			25.624,
			25.656,
			25.732,
			25.78,
			25.843,
			25.906,
			25.969,
			26.031,
			26.092,
			26.169,
			26.216,
			26.281,
			26.342,
			26.404,
			26.497,
			26.529,
			26.607,
			26.655,
			26.719,
			26.779,
			26.826,
			26.903,
			26.965,
			27.044,
			27.089,
			27.152,
			27.214,
			27.277,
			27.34,
			27.403,
			27.48,
			27.528,
			27.59,
			27.653,
			27.714,
			27.777,
			27.809,
			27.839,
			27.917,
			27.963,
			28.027,
			28.089,
			28.136,
			28.151,
			28.213,
			28.277,
			28.353,
			28.402,
			28.465,
			28.526,
			28.588,
			28.651,
			28.714,
			28.795,
			28.837,
			28.899,
			28.963,
			29.024,
			29.085,
			29.119,
			29.149,
			29.226,
			29.273,
			29.336,
			29.398,
			29.445,
			29.523,
			29.585,
			29.663,
			29.712,
			29.774,
			29.836,
			29.881,
			29.899,
			29.96,
			30.023,
			30.101,
			30.148,
			30.212,
			30.273,
			30.319,
			30.397,
			30.429,
			30.459,
			30.537,
			30.586,
			30.648,
			30.708,
			30.756,
			30.771,
			30.835,
			30.896,
			30.975,
			31.02,
			31.085,
			31.146,
			31.193,
			31.272,
			31.302,
			31.332,
			31.395,
			31.457,
			31.52,
			31.581,
			31.644,
			31.707,
			31.739,
			31.771,
			31.848,
			31.896,
			31.959,
			32.019,
			32.065,
			32.081,
			32.145,
			32.177,
			32.207,
			32.285,
			32.331,
			32.395,
			32.455,
			32.519,
			32.58,
			32.643,
			32.723,
			32.769,
			32.832,
			32.893,
			32.944,
			33.019,
			33.047,
			33.08,
			33.144,
			33.205,
			33.268,
			33.329,
			33.377,
			33.393,
			33.455,
			33.518,
			33.595,
			33.641,
			33.705,
			33.765,
			33.83,
			33.891,
			33.953,
			34.014,
			34.031,
			34.079,
			34.141,
			34.203,
			34.265,
			34.329,
			34.36,
			34.39,
			34.453,
			34.469,
			34.514,
			34.577,
			34.641,
			34.702,
			34.765,
			34.797,
			34.828,
			34.888,
			34.906,
			34.953,
			35.018,
			35.077,
			35.123,
			35.202,
			35.234,
			35.264,
			35.325,
			35.388,
			35.451,
			35.514,
			35.575,
			35.638,
			35.669,
			35.699,
			35.761,
			35.777,
			35.826,
			35.888,
			35.949,
			36.012,
			36.074,
			36.107,
			36.137,
			36.198,
			36.26,
			36.323,
			36.385,
			36.448,
			36.541,
			36.572,
			36.652,
			36.697,
			36.762,
			36.823,
			36.886,
			36.946,
			36.98,
			37.01,
			37.072,
			37.135,
			37.199,
			37.259,
			37.323,
			37.385,
			37.415,
			37.447,
			37.524,
			37.572,
			37.634,
			37.695,
			37.759,
			37.853,
			37.882,
			37.945,
			38.009,
			38.07,
			38.131,
			38.193,
			38.288,
			38.321,
			38.382,
			38.397,
			38.444,
			38.507,
			38.57,
			38.631,
			38.725,
			38.757,
			38.835,
			38.881,
			38.943,
			39.007,
			39.069,
			39.131,
			39.194,
			39.271,
			39.319,
			39.381,
			39.443,
			39.505,
			39.565,
			39.598,
			39.629,
			39.691,
			39.708,
			39.755,
			39.817,
			39.877,
			39.941,
			40.003,
			40.035,
			40.065,
			40.129,
			40.143,
			40.191,
			40.256,
			40.314,
			40.377,
			40.47,
			40.502,
			40.563,
			40.581,
			40.627,
			40.69,
			40.752,
			40.816,
			40.878,
			40.908,
			40.94,
			41.003,
			41.018,
			41.064,
			41.128,
			41.189,
			41.252,
			41.345,
			41.375,
			41.439,
			41.453,
			41.501,
			41.564,
			41.626,
			41.687,
			41.78,
			41.813,
			41.875,
			41.937,
			42,
			42.063,
			42.125,
			42.186,
			42.218,
			42.249,
			42.311,
			42.328,
			42.374,
			42.436,
			42.498,
			42.561,
			42.655,
			42.685,
			42.747,
			42.81,
			42.873,
			42.936,
			42.998,
			43.06,
			43.091,
			43.123,
			43.186,
			43.199,
			43.248,
			43.313,
			43.373,
			43.435,
			43.528,
			43.559,
			43.622,
			43.682,
			43.747,
			43.807,
			43.869,
			43.963,
			43.996,
			44.057,
			44.073,
			44.119,
			44.182,
			44.244,
			44.307,
			44.369,
			44.398,
			44.433,
			44.493,
			44.557,
			44.618,
			44.68,
			44.728,
			44.743,
			44.806,
			44.837,
			44.868,
			44.929,
			44.946,
			44.993,
			45.054,
			45.118,
			45.179,
			45.243,
			45.273,
			45.304,
			45.367,
			45.428,
			45.491,
			45.554,
			45.617,
			45.711,
			45.741,
			45.82,
			45.865,
			45.929,
			45.991,
			46.052,
			46.146,
			46.176,
			46.24,
			46.255,
			46.303,
			46.365,
			46.428,
			46.49,
			46.582,
			46.614,
			46.677,
			46.693,
			46.737,
			46.802,
			46.864,
			46.909,
			46.925,
			47.02,
			47.049,
			47.113,
			47.129,
			47.174,
			47.237,
			47.299,
			47.361,
			47.425,
			47.46,
			47.488,
			47.55,
			47.611,
			47.675,
			47.736,
			47.798,
			47.859,
			47.891,
			47.923,
			47.987,
			48.001,
			48.048,
			48.112,
			48.174,
			48.221,
			48.329,
			48.361,
			48.422,
			48.485,
			48.549,
			48.61,
			48.673,
			48.765,
			48.796,
			48.876,
			48.92,
			48.985,
			49.046,
			49.107,
			49.172,
			49.203,
			49.234,
			49.297,
			49.359,
			49.422,
			49.482,
			49.546,
			49.637,
			49.669,
			49.732,
			49.747,
			49.796,
			49.857,
			49.92,
			49.981,
			50.074,
			50.106,
			50.17,
			50.23,
			50.295,
			50.355,
			50.418,
			50.482,
			50.513,
			50.543,
			50.607,
			50.622,
			50.668,
			50.731,
			50.793,
			50.84,
			50.855,
			50.949,
			50.979,
			51.043,
			51.105,
			51.165,
			51.23,
			51.293,
			51.355,
			51.384,
			51.417,
			51.479,
			51.495,
			51.542,
			51.607,
			51.664,
			51.728,
			51.791,
			51.821,
			51.854,
			51.914,
			51.931,
			51.978,
			52.042,
			52.102,
			52.15,
			52.228,
			52.257,
			52.29,
			52.352,
			52.368,
			52.415,
			52.478,
			52.538,
			52.603,
			52.664,
			52.695,
			52.725,
			52.788,
			52.805,
			52.851,
			52.913,
			52.975,
			53.038,
			53.101,
			53.132,
			53.164,
			53.225,
			53.288,
			53.35,
			53.412,
			53.474,
			53.538,
			53.568,
			53.599,
			53.662,
			53.68,
			53.725,
			53.788,
			53.849,
			53.897,
			53.974,
			54.004,
			54.036,
			54.099,
			54.162,
			54.224,
			54.285,
			54.348,
			54.41,
			54.442,
			54.472,
			54.535,
			54.551,
			54.596,
			54.659,
			54.722,
			54.769,
			54.847,
			54.878,
			54.908,
			54.986,
			55.032,
			55.096,
			55.158,
			55.222,
			55.284,
			55.315,
			55.344,
			55.408,
			55.422,
			55.469,
			55.534,
			55.595,
			55.657,
			55.719,
			55.755,
			55.78,
			55.843,
			55.908,
			55.97,
			56.032,
			56.079,
			56.092,
			56.157,
			56.188,
			56.219,
			56.28,
			56.295,
			56.342,
			56.408,
			56.469,
			56.531,
			56.593,
			56.624,
			56.656,
			56.718,
			56.78,
			56.843,
			56.905,
			56.967,
			57.028,
			57.06,
			57.092,
			57.153,
			57.171,
			57.214,
			57.278,
			57.341,
			57.402,
			57.465,
			57.496,
			57.528,
			57.606,
			57.653,
			57.714,
			57.778,
			57.839,
			57.903,
			57.933,
			57.963,
			58.028,
			58.043,
			58.09,
			58.152,
			58.215,
			58.276,
			58.338,
			58.37,
			58.402,
			58.462,
			58.478,
			58.526,
			58.589,
			58.651,
			58.698,
			58.807,
			58.837,
			58.916,
			58.961,
			59.024,
			59.088,
			59.15,
			59.212,
			59.244,
			59.275,
			59.461,
			59.524,
			59.571,
			59.65,
			59.681,
			59.711,
			59.788,
			59.836,
			59.902,
			59.96,
			60.023,
			60.115,
			60.147,
			60.226,
			60.273,
			60.335,
			60.396,
			60.461,
			60.523,
			60.553,
			60.585,
			60.647,
			60.709,
			60.773,
			60.834,
			60.895,
			60.99,
			61.02,
			61.098,
			61.145,
			61.208,
			61.272,
			61.333,
			61.426,
			61.457,
			61.521,
			61.582,
			61.644,
			61.707,
			61.769,
			61.863,
			61.894,
			61.956,
			61.977,
			62.019,
			62.081,
			62.144,
			62.206,
			62.301,
			62.332,
			62.395,
			62.455,
			62.52,
			62.581,
			62.628,
			62.643,
			62.735,
			62.768,
			62.831,
			62.846,
			62.893,
			62.954,
			63.017,
			63.08,
			63.173,
			63.204,
			63.265,
			63.283,
			63.329,
			63.391,
			63.454,
			63.515,
			63.577,
			63.61,
			63.641,
			63.702,
			63.719,
			63.766,
			63.829,
			63.889,
			63.937,
			63.953,
			64.014,
			64.078,
			64.156,
			64.202,
			64.264,
			64.327,
			64.39,
			64.452,
			64.482,
			64.513,
			64.576,
			64.638,
			64.704,
			64.764,
			64.827,
			64.889,
			64.952,
			65.012,
			65.028,
			65.075,
			65.137,
			65.201,
			65.247,
			65.357,
			65.388,
			65.448,
			65.511,
			65.574,
			65.638,
			65.697,
			65.762,
			65.792,
			65.823,
			65.887,
			65.903,
			65.947,
			66.012,
			66.074,
			66.135,
			66.197,
			66.229,
			66.261,
			66.339,
			66.385,
			66.449,
			66.51,
			66.573,
			66.664,
			66.697,
			66.76,
			66.775,
			66.821,
			66.885,
			66.947,
			67.01,
			67.104,
			67.134,
			67.196,
			67.257,
			67.322,
			67.382,
			67.43,
			67.447,
			67.54,
			67.571,
			67.648,
			67.695,
			67.759,
			67.82,
			67.868,
			67.945,
			68.007,
			68.085,
			68.133,
			68.198,
			68.257,
			68.305,
			68.319,
			68.382,
			68.444,
			68.522,
			68.569,
			68.631,
			68.695,
			68.756,
			68.849,
			68.881,
			68.958,
			69.005,
			69.068,
			69.129,
			69.177,
			69.254,
			69.318,
			69.396,
			69.443,
			69.505,
			69.567,
			69.628,
			69.693,
			69.755,
			69.834,
			69.878,
			69.945,
			70.003,
			70.067,
			70.128,
			70.192,
			70.272,
			70.314,
			70.378,
			70.442,
			70.488,
			70.503,
			70.564,
			70.627,
			70.706,
			70.753,
			70.814,
			70.878,
			70.94,
			71.003,
			71.065,
			71.143,
			71.188,
			71.251,
			71.315,
			71.362,
			71.378,
			71.438,
			71.471,
			71.502,
			71.563,
			71.578,
			71.625,
			71.687,
			71.752,
			71.797,
			71.874,
			71.936,
			72.015,
			72.062,
			72.125,
			72.187,
			72.234,
			72.249,
			72.312,
			72.346,
			72.374,
			72.451,
			72.499,
			72.562,
			72.622,
			72.67,
			72.749,
			72.812,
			72.888,
			72.936,
			73.002,
			73.059,
			73.107,
			73.121,
			73.185,
			73.248,
			73.324,
			73.372,
			73.435,
			73.497,
			73.544,
			73.62,
			73.652,
			73.685,
			73.76,
			73.808,
			73.872,
			73.934,
			73.995,
			74.057,
			74.087,
			74.119,
			74.183,
			74.198,
			74.245,
			74.307,
			74.37,
			74.431,
			74.495,
			74.525,
			74.556,
			74.636,
			74.682,
			74.743,
			74.806,
			74.852,
			74.868,
			74.878
		]
	],
	'8074_8327_7982': [
		[
			50.003
		],
		[
			0
		],
		[
			0,
			0.677,
			3.149,
			6.864,
			9.351,
			11.847,
			13.098,
			15.607,
			16.864,
			19.385,
			21.914,
			23.182,
			25.725,
			28.275,
			32.114,
			34.684,
			38.556,
			39.851,
			41.149,
			45.063,
			47.681,
			51.603,
			54.218,
			58.149,
			60.779,
			62.097,
			64.738,
			67.386,
			68.713,
			71.372,
			74.037,
			78.048,
			79.388,
			80.73,
			84.767,
			86.115,
			87.466,
			91.526,
			94.242,
			98.328,
			101.06,
			105.17,
			109.294,
			112.053,
			114.818,
			116.203,
			118.979,
			121.761,
			124.549,
			128.741,
			134.353,
			135.76,
			138.579,
			139.991,
			142.819,
			145.654,
			149.917,
			152.764,
			157.031,
			158.446,
			159.857,
			165.426,
			166.796,
			170.849,
			173.503,
			177.412,
			179.969,
			183.735,
			187.415,
			189.822,
			192.193,
			195.69,
			197.976,
			202.436,
			203.527,
			207.791,
			208.836,
			211.919,
			213.927,
			216.869,
			220.65,
			221.572,
			223.39,
			224.285,
			226.051,
			227.782,
			230.309,
			231.947,
			234.335,
			235.881,
			238.131,
			239.585,
			241.699,
			243.066,
			244.399,
			245.052,
			246.336,
			248.195,
			249.972,
			251.112,
			252.216,
			253.807,
			254.823,
			256.751,
			257.214,
			258.577,
			259.468,
			260.784,
			261.663,
			262.978,
			263.861,
			265.206,
			266.578,
			267.505,
			268.432,
			269.783,
			270.682,
			272.476,
			272.922,
			274.259,
			275.136,
			276.429,
			277.276,
			278.552,
			279.859,
			280.709,
			281.959,
			282.78,
			283.623,
			284.909,
			285.754,
			287.009,
			287.841,
			289.13,
			289.991,
			291.27,
			292.118,
			292.966,
			293.395,
			294.264,
			294.696,
			295.56,
			296.856,
			297.724,
			298.616,
			299.993,
			300.453,
			300.915,
			302.31,
			302.779,
			303.251,
			304.791,
			305.828,
			306.877,
			308.493,
			309.677,
			311.481,
			312.09,
			313.325,
			314.633,
			315.292,
			315.956,
			317.979,
			319.259,
			321.163,
			322.456,
			323.729,
			324.33,
			326.157,
			327.394,
			328.642,
			330.51,
			331.771,
			334.31,
			334.939,
			337.459,
			338.094,
			339.982,
			341.26,
			343.203,
			344.5,
			346.453,
			348.415,
			349.809,
			351.2,
			353.29,
			354.828,
			357.925,
			358.719,
			361.185,
			362.831,
			365.425,
			367.18,
			368.933,
			369.8,
			371.537,
			374.074,
			376.421,
			377.992,
			379.437,
			381.53,
			382.931,
			385.016,
			386.402,
			388.485,
			389.903,
			392.028,
			393.444,
			394.885,
			395.604,
			397.038,
			399.184,
			401.326,
			402.737,
			404.133,
			406.178,
			407.515,
			409.463,
			410.718,
			412.532,
			413.704,
			415.444,
			416.58,
			418.27,
			419.395,
			421.072,
			422.74,
			423.865,
			425.011,
			426.721,
			427.857,
			429.662,
			430.901,
			432.748,
			434.024,
			436.063,
			437.416,
			438.769,
			439.444,
			440.791,
			442.81,
			444.722,
			445.948,
			447.177,
			449.014,
			449.61,
			450.207,
			452.003,
			453.21,
			455.003,
			456.201,
			458.014,
			459.225,
			461.041,
			462.261,
			464.114,
			465.987,
			467.243,
			468.509,
			470.413,
			471.049,
			471.686,
			472.964,
			474.247,
			474.888,
			476.813,
			478.099,
			480.036,
			481.328,
			482.619,
			484.567,
			485.87,
			488.481,
			489.139,
			491.119,
			492.442,
			494.442,
			497.132,
			497.809,
			499.858,
			501.234,
			502.619,
			504.722,
			506.14,
			508.286,
			509.743,
			512.696,
			513.443,
			515.726,
			517.264,
			519.608,
			521.198,
			523.602,
			526.05,
			527.692,
			529.34,
			531.816,
			533.473,
			535.933,
			536.753,
			537.573,
			540.847,
			541.664,
			544.117,
			545.751,
			547.386,
			548.203,
			549.833,
			552.26,
			554.72,
			556.361,
			558.011,
			560.512,
			562.193,
			564.724,
			566.415,
			568.957,
			570.666,
			573.231,
			574.936,
			576.635,
			577.483,
			579.173,
			581.681,
			584.17,
			585.809,
			587.44,
			589.862,
			591.455,
			593.832,
			594.63,
			595.426,
			597.805,
			599.39,
			601.784,
			603.371,
			605.738,
			607.307,
			609.661,
			612.031,
			613.602,
			615.208,
			617.621,
			619.225,
			621.62,
			622.416,
			623.212,
			625.671,
			627.329,
			629.849,
			631.573,
			634.154,
			635.88,
			636.741,
			638.46,
			641.097,
			642.863,
			644.608,
			647.165,
			648.868,
			651.332,
			652.15,
			652.966,
			655.478,
			657.185,
			659.786,
			661.584,
			663.385,
			665.972,
			666.822,
			668.525,
			670.179,
			670.994,
			671.811,
			674.269,
			675.897,
			678.353,
			680.775,
			681.579,
			682.386,
			684.006,
			684.8,
			686.39,
			687.99,
			690.418,
			692.054,
			694.533,
			695.372,
			696.214,
			699.606,
			700.463,
			703.04,
			704.762,
			706.486,
			707.346,
			709.062,
			711.621,
			713.312,
			714.152,
			715.816,
			717.458,
			719.873,
			721.431,
			723.725,
			724.472,
			725.208,
			728.062,
			728.754,
			730.775,
			732.082,
			733.362,
			733.994,
			735.251,
			736.493,
			737.111,
			738.341,
			738.953,
			740.198,
			741.427,
			743.264,
			744.489,
			746.333,
			747.559,
			749.401,
			750.636,
			752.483,
			753.713,
			755.612,
			756.889,
			758.167,
			758.807,
			760.116,
			760.778,
			762.101,
			763.426,
			765.451,
			766.817,
			768.869,
			769.568,
			770.287,
			772.445,
			773.885,
			776.081,
			777.548,
			779.751,
			781.215,
			781.946,
			783.41,
			784.871,
			785.596,
			787.047,
			788.502,
			790.699,
			792.171,
			794.392,
			795.129,
			795.868,
			798.095,
			799.588,
			801.796,
			803.279,
			805.492,
			806.956,
			807.692,
			809.172,
			810.623,
			811.348,
			812.806,
			814.277,
			816.509,
			818.008,
			821.05,
			821.819,
			824.135,
			825.703,
			828.071,
			829.658,
			832.063,
			833.668,
			834.471,
			836.073,
			838.468,
			840.055,
			841.629,
			843.946,
			845.486,
			846.983,
			848.448,
			849.168,
			851.287,
			852.648,
			853.987,
			855.937,
			857.205,
			859.03,
			859.622,
			860.209,
			861.924,
			862.482,
			863.033,
			864.654,
			865.721,
			867.285,
			868.311,
			869.327,
			869.841,
			871.393,
			872.422,
			873.45,
			875.007,
			876.07,
			877.662,
			878.193,
			878.725,
			880.346,
			881.441,
			883.088,
			884.193,
			885.895,
			887.039,
			888.19,
			888.768,
			889.95,
			890.568,
			891.809,
			893.059,
			894.982,
			896.371,
			898.476,
			899.185,
			899.906,
			902.13,
			903.63,
			905.84,
			907.283,
			909.477,
			910.92,
			911.642,
			913.094,
			915.314,
			916.837,
			918.369,
			920.658,
			922.161,
			924.428,
			925.16,
			925.869,
			927.999,
			929.425,
			931.528,
			932.925,
			935.021,
			936.4,
			937.089,
			938.465,
			940.537,
			941.922,
			943.306,
			945.381,
			946.764,
			948.839,
			949.531,
			950.223,
			952.296,
			953.68,
			955.757,
			957.139,
			959.221,
			960.614,
			961.312,
			962.71,
			964.116,
			964.823,
			966.249,
			967.684,
			969.855,
			971.32,
			974.283,
			975.031,
			976.534,
			977.289,
			978.809,
			981.105,
			982.644,
			984.196,
			987.36,
			988.161,
			990.582,
			991.404,
			993.05,
			994.697,
			996.343,
			998.803,
			1001.266,
			1002.088,
			1002.908,
			1005.351,
			1006.98,
			1008.594,
			1010.98,
			1012.542,
			1014.853,
			1015.613,
			1016.367,
			1019.334,
			1020.063,
			1022.207,
			1023.604,
			1025.632,
			1028.218,
			1028.844,
			1030.069,
			1030.668,
			1031.838,
			1032.974,
			1034.613,
			1035.662,
			1037.174,
			1037.661,
			1038.14,
			1039.524,
			1040.408,
			1041.709,
			1042.554,
			1043.803,
			1045.498,
			1045.939,
			1046.828,
			1047.275,
			1048.175,
			1049.082,
			1050.455,
			1051.384,
			1053.224,
			1053.68,
			1055.034,
			1055.918,
			1057.203,
			1058.037,
			1059.256,
			1060.052,
			1060.822,
			1061.195,
			1061.931,
			1062.295,
			1063.012,
			1063.721,
			1064.774,
			1065.482,
			1066.672,
			1067.455,
			1068.621,
			1069.395,
			1070.588,
			1071.523,
			1072.914,
			1073.84,
			1075.237,
			1076.655,
			1077.6,
			1078.548,
			1079.983,
			1080.944,
			1082.383,
			1083.35,
			1084.821,
			1085.801,
			1087.245,
			1088.22,
			1089.208,
			1089.707,
			1090.699,
			1091.172,
			1092.126,
			1093.582,
			1094.571,
			1095.577,
			1097.094,
			1098.121,
			1099.692,
			1100.76,
			1102.383,
			1103.481,
			1105.151,
			1106.287,
			1108.014,
			1109.76,
			1110.936,
			1112.713,
			1113.907,
			1115.109,
			1116.928,
			1117.538,
			1118.149,
			1119.375,
			1120.608,
			1121.227,
			1123.083,
			1124.325,
			1125.568,
			1127.445,
			1128.7,
			1130.588,
			1131.856,
			1134.407,
			1135.05,
			1137.002,
			1138.322,
			1140.331,
			1143.046,
			1143.734,
			1145.819,
			1147.216,
			1148.627,
			1150.773,
			1152.214,
			1154.402,
			1155.879,
			1158.114,
			1159.622,
			1161.928,
			1163.496,
			1165.077,
			1165.875,
			1167.532,
			1169.195,
			1170.027,
			1172.555,
			1174.265,
			1175.987,
			1178.585,
			1180.342,
			1182.996,
			1184.779,
			1187.476,
			1189.289,
			1192.03,
			1193.874,
			1196.663,
			1200.422,
			1201.369,
			1203.273,
			1204.23,
			1206.152,
			1208.088,
			1211.012,
			1212.976,
			1215.945,
			1217.939,
			1220.952,
			1222.975,
			1226.03,
			1228.082,
			1231.173,
			1234.262,
			1236.301,
			1239.31,
			1241.273,
			1243.2,
			1246.018,
			1247.85,
			1250.527,
			1252.265,
			1254.803,
			1256.45,
			1258.866,
			1260.452,
			1262.036,
			1262.818,
			1264.376,
			1266.708,
			1269.032,
			1270.58,
			1272.122,
			1274.423,
			1275.189,
			1275.955,
			1278.213,
			1278.962,
			1279.71,
			1281.946,
			1283.419,
			1285.623,
			1287.109,
			1288.612,
			1289.363,
			1291.625,
			1293.211,
			1294.794,
			1297.209,
			1298.878,
			1301.378,
			1303.113,
			1305.715,
			1306.586,
			1307.479,
			1310.155,
			1311.944,
			1314.653,
			1316.455,
			1318.274,
			1321.901,
			1323.71,
			1326.413,
			1327.312,
			1329.994,
			1331.777,
			1333.578,
			1334.476,
			1336.266,
			1338.961,
			1341.647,
			1343.357,
			1345.062,
			1347.547,
			1349.119,
			1351.478,
			1352.966,
			1355.907,
			1356.642,
			1358.775,
			1360.194,
			1362.328,
			1363.75,
			1365.189,
			1365.912,
			1368.129,
			1369.65,
			1371.172,
			1373.515,
			1375.103,
			1377.518,
			1379.162,
			1381.652,
			1383.333,
			1385.881,
			1387.594,
			1390.18,
			1391.92,
			1394.555,
			1397.202,
			1398.995,
			1400.801,
			1403.533,
			1405.37,
			1408.15,
			1409.083,
			1410.019,
			1412.846,
			1414.745,
			1417.618,
			1419.549,
			1421.492,
			1422.468,
			1424.428,
			1427.391,
			1430.378,
			1432.383,
			1434.388,
			1437.417,
			1439.45,
			1442.522,
			1444.595,
			1447.724,
			1449.812,
			1452.944,
			1455.034,
			1458.185,
			1460.293,
			1461.351,
			1463.477,
			1466.689,
			1468.851,
			1471.027,
			1474.315,
			1475.418,
			1476.522,
			1479.851,
			1480.965,
			1482.082,
			1485.44,
			1487.69,
			1491.08,
			1493.352,
			1496.779,
			1499.073,
			1500.224,
			1502.532,
			1506.016,
			1508.348,
			1510.685,
			1513.031,
			1515.383,
			1516.563,
			1520.155,
			1521.362,
			1522.571,
			1526.199,
			1528.628,
			1532.284,
			1534.736,
			1536.664
		],
		[
			0,
			0.018,
			0.065,
			0.127,
			0.189,
			0.235,
			0.251,
			0.315,
			0.344,
			0.377,
			0.439,
			0.458,
			0.502,
			0.564,
			0.626,
			0.689,
			0.749,
			0.783,
			0.812,
			0.877,
			0.937,
			1.002,
			1.064,
			1.125,
			1.188,
			1.218,
			1.251,
			1.31,
			1.328,
			1.374,
			1.438,
			1.499,
			1.546,
			1.56,
			1.623,
			1.655,
			1.685,
			1.764,
			1.81,
			1.874,
			1.935,
			1.999,
			2.092,
			2.124,
			2.184,
			2.199,
			2.248,
			2.311,
			2.371,
			2.435,
			2.533,
			2.56,
			2.62,
			2.636,
			2.684,
			2.747,
			2.807,
			2.87,
			2.932,
			2.964,
			2.994,
			3.074,
			3.12,
			3.183,
			3.245,
			3.307,
			3.369,
			3.432,
			3.511,
			3.557,
			3.62,
			3.682,
			3.743,
			3.837,
			3.869,
			3.948,
			3.995,
			4.057,
			4.117,
			4.18,
			4.274,
			4.305,
			4.367,
			4.383,
			4.43,
			4.492,
			4.555,
			4.604,
			4.68,
			4.741,
			4.82,
			4.866,
			4.928,
			4.99,
			5.038,
			5.055,
			5.117,
			5.18,
			5.258,
			5.304,
			5.365,
			5.427,
			5.491,
			5.585,
			5.615,
			5.693,
			5.741,
			5.803,
			5.865,
			5.928,
			5.99,
			6.052,
			6.131,
			6.176,
			6.239,
			6.302,
			6.365,
			6.458,
			6.488,
			6.567,
			6.614,
			6.676,
			6.739,
			6.801,
			6.894,
			6.927,
			7.004,
			7.05,
			7.113,
			7.175,
			7.223,
			7.3,
			7.362,
			7.441,
			7.486,
			7.553,
			7.612,
			7.659,
			7.675,
			7.737,
			7.768,
			7.8,
			7.877,
			7.924,
			7.987,
			8.05,
			8.095,
			8.11,
			8.174,
			8.205,
			8.236,
			8.315,
			8.36,
			8.422,
			8.485,
			8.532,
			8.609,
			8.64,
			8.673,
			8.735,
			8.755,
			8.795,
			8.86,
			8.921,
			8.985,
			9.047,
			9.077,
			9.108,
			9.188,
			9.233,
			9.294,
			9.357,
			9.419,
			9.512,
			9.543,
			9.624,
			9.669,
			9.732,
			9.794,
			9.857,
			9.92,
			9.982,
			10.06,
			10.108,
			10.17,
			10.232,
			10.295,
			10.387,
			10.419,
			10.496,
			10.543,
			10.607,
			10.669,
			10.716,
			10.731,
			10.793,
			10.854,
			10.933,
			10.979,
			11.042,
			11.104,
			11.152,
			11.231,
			11.291,
			11.369,
			11.416,
			11.477,
			11.541,
			11.588,
			11.604,
			11.665,
			11.727,
			11.805,
			11.854,
			11.916,
			11.977,
			12.025,
			12.103,
			12.165,
			12.243,
			12.289,
			12.352,
			12.414,
			12.477,
			12.539,
			12.602,
			12.679,
			12.725,
			12.788,
			12.852,
			12.914,
			12.976,
			13.038,
			13.117,
			13.164,
			13.227,
			13.287,
			13.333,
			13.349,
			13.413,
			13.476,
			13.553,
			13.599,
			13.662,
			13.725,
			13.772,
			13.788,
			13.85,
			13.912,
			13.99,
			14.038,
			14.099,
			14.16,
			14.223,
			14.286,
			14.35,
			14.427,
			14.473,
			14.537,
			14.599,
			14.645,
			14.66,
			14.722,
			14.753,
			14.786,
			14.864,
			14.911,
			14.977,
			15.035,
			15.083,
			15.161,
			15.221,
			15.299,
			15.347,
			15.413,
			15.471,
			15.535,
			15.626,
			15.66,
			15.737,
			15.783,
			15.845,
			15.907,
			15.954,
			16.033,
			16.094,
			16.173,
			16.221,
			16.283,
			16.346,
			16.407,
			16.469,
			16.532,
			16.609,
			16.656,
			16.719,
			16.782,
			16.829,
			16.907,
			16.937,
			16.969,
			17.052,
			17.093,
			17.158,
			17.218,
			17.264,
			17.281,
			17.343,
			17.405,
			17.484,
			17.528,
			17.594,
			17.655,
			17.716,
			17.779,
			17.841,
			17.921,
			17.967,
			18.028,
			18.092,
			18.139,
			18.154,
			18.215,
			18.277,
			18.356,
			18.402,
			18.467,
			18.527,
			18.574,
			18.654,
			18.683,
			18.715,
			18.794,
			18.841,
			18.903,
			18.964,
			19.027,
			19.089,
			19.152,
			19.227,
			19.276,
			19.34,
			19.4,
			19.462,
			19.527,
			19.557,
			19.588,
			19.651,
			19.711,
			19.774,
			19.839,
			19.901,
			19.962,
			19.992,
			20.025,
			20.102,
			20.15,
			20.211,
			20.274,
			20.337,
			20.4,
			20.431,
			20.463,
			20.54,
			20.585,
			20.649,
			20.709,
			20.772,
			20.836,
			20.867,
			20.899,
			20.959,
			20.974,
			21.022,
			21.086,
			21.146,
			21.21,
			21.273,
			21.302,
			21.335,
			21.397,
			21.412,
			21.459,
			21.522,
			21.584,
			21.631,
			21.71,
			21.74,
			21.772,
			21.85,
			21.895,
			21.957,
			22.02,
			22.067,
			22.084,
			22.144,
			22.208,
			22.271,
			22.286,
			22.333,
			22.394,
			22.458,
			22.52,
			22.583,
			22.614,
			22.643,
			22.723,
			22.768,
			22.832,
			22.895,
			22.94,
			22.957,
			23.017,
			23.049,
			23.081,
			23.144,
			23.16,
			23.206,
			23.271,
			23.329,
			23.377,
			23.455,
			23.518,
			23.595,
			23.641,
			23.704,
			23.767,
			23.829,
			23.893,
			23.923,
			23.954,
			24.016,
			24.03,
			24.077,
			24.141,
			24.203,
			24.265,
			24.329,
			24.359,
			24.39,
			24.469,
			24.515,
			24.579,
			24.64,
			24.701,
			24.765,
			24.796,
			24.826,
			24.888,
			24.904,
			24.952,
			25.015,
			25.075,
			25.139,
			25.202,
			25.233,
			25.262,
			25.346,
			25.387,
			25.452,
			25.512,
			25.574,
			25.638,
			25.669,
			25.7,
			25.762,
			25.778,
			25.826,
			25.886,
			25.95,
			26.013,
			26.106,
			26.136,
			26.198,
			26.262,
			26.324,
			26.385,
			26.449,
			26.51,
			26.543,
			26.573,
			26.651,
			26.699,
			26.76,
			26.823,
			26.884,
			26.947,
			26.977,
			27.01,
			27.087,
			27.133,
			27.197,
			27.259,
			27.321,
			27.383,
			27.418,
			27.445,
			27.509,
			27.523,
			27.569,
			27.634,
			27.695,
			27.757,
			27.82,
			27.851,
			27.883,
			27.96,
			28.007,
			28.07,
			28.132,
			28.177,
			28.257,
			28.288,
			28.318,
			28.397,
			28.443,
			28.505,
			28.569,
			28.631,
			28.693,
			28.725,
			28.756,
			28.819,
			28.833,
			28.879,
			28.943,
			29.004,
			29.068,
			29.13,
			29.161,
			29.19,
			29.269,
			29.315,
			29.377,
			29.44,
			29.504,
			29.565,
			29.595,
			29.627,
			29.706,
			29.753,
			29.815,
			29.878,
			29.941,
			30.001,
			30.033,
			30.065,
			30.127,
			30.19,
			30.252,
			30.315,
			30.377,
			30.439,
			30.469,
			30.501,
			30.579,
			30.624,
			30.689,
			30.751,
			30.812,
			30.875,
			30.907,
			30.938,
			31.016,
			31.063,
			31.124,
			31.186,
			31.25,
			31.312,
			31.344,
			31.374,
			31.437,
			31.452,
			31.499,
			31.567,
			31.624,
			31.686,
			31.778,
			31.81,
			31.872,
			31.889,
			31.935,
			31.999,
			32.06,
			32.108,
			32.217,
			32.246,
			32.31,
			32.326,
			32.373,
			32.435,
			32.495,
			32.56,
			32.623,
			32.651,
			32.683,
			32.761,
			32.809,
			32.872,
			32.935,
			32.995,
			33.057,
			33.091,
			33.121,
			33.199,
			33.244,
			33.309,
			33.369,
			33.433,
			33.527,
			33.558,
			33.621,
			33.641,
			33.683,
			33.744,
			33.807,
			33.869,
			33.933,
			33.964,
			33.993,
			34.056,
			34.117,
			34.181,
			34.243,
			34.306,
			34.399,
			34.431,
			34.492,
			34.508,
			34.556,
			34.617,
			34.681,
			34.742,
			34.835,
			34.867,
			34.928,
			34.993,
			35.054,
			35.117,
			35.179,
			35.243,
			35.273,
			35.305,
			35.367,
			35.382,
			35.428,
			35.493,
			35.553,
			35.616,
			35.677,
			35.741,
			35.804,
			35.865,
			35.928,
			35.99,
			36.054,
			36.116,
			36.176,
			36.256,
			36.301,
			36.365,
			36.427,
			36.49,
			36.552,
			36.614,
			36.691,
			36.737,
			36.801,
			36.862,
			36.91,
			36.925,
			36.987,
			37.019,
			37.051,
			37.127,
			37.176,
			37.237,
			37.301,
			37.362,
			37.423,
			37.486,
			37.565,
			37.612,
			37.673,
			37.736,
			37.8,
			37.892,
			37.924,
			38.001,
			38.049,
			38.11,
			38.173,
			38.22,
			38.234,
			38.297,
			38.328,
			38.36,
			38.438,
			38.485,
			38.547,
			38.608,
			38.672,
			38.733,
			38.797,
			38.874,
			38.921,
			38.985,
			39.045,
			39.108,
			39.202,
			39.232,
			39.312,
			39.356,
			39.42,
			39.483,
			39.529,
			39.608,
			39.668,
			39.747,
			39.794,
			39.86,
			39.917,
			39.966,
			39.981,
			40.042,
			40.075,
			40.106,
			40.184,
			40.23,
			40.292,
			40.355,
			40.401,
			40.479,
			40.541,
			40.62,
			40.668,
			40.729,
			40.793,
			40.855,
			40.949,
			40.979,
			41.041,
			41.057,
			41.102,
			41.166,
			41.229,
			41.289,
			41.351,
			41.415,
			41.493,
			41.54,
			41.603,
			41.665,
			41.727,
			41.821,
			41.852,
			41.934,
			41.976,
			42.038,
			42.1,
			42.149,
			42.226,
			42.288,
			42.367,
			42.415,
			42.477,
			42.538,
			42.585,
			42.601,
			42.661,
			42.726,
			42.803,
			42.85,
			42.915,
			42.974,
			43.021,
			43.035,
			43.099,
			43.131,
			43.163,
			43.24,
			43.285,
			43.35,
			43.41,
			43.459,
			43.473,
			43.567,
			43.598,
			43.661,
			43.723,
			43.785,
			43.848,
			43.909,
			43.972,
			44.007,
			44.036,
			44.113,
			44.16,
			44.222,
			44.283,
			44.345,
			44.441,
			44.472,
			44.55,
			44.597,
			44.659,
			44.721,
			44.767,
			44.783,
			44.847,
			44.909,
			44.986,
			45.033,
			45.095,
			45.157,
			45.221,
			45.283,
			45.345,
			45.423,
			45.471,
			45.534,
			45.594,
			45.657,
			45.72,
			45.749,
			45.781,
			45.859,
			45.905,
			45.97,
			46.031,
			46.082,
			46.157,
			46.217,
			46.297,
			46.344,
			46.405,
			46.467,
			46.531,
			46.593,
			46.655,
			46.733,
			46.78,
			46.845,
			46.903,
			46.965,
			47.028,
			47.059,
			47.092,
			47.154,
			47.216,
			47.279,
			47.34,
			47.387,
			47.402,
			47.466,
			47.528,
			47.605,
			47.652,
			47.715,
			47.777,
			47.84,
			47.902,
			47.964,
			48.042,
			48.089,
			48.154,
			48.212,
			48.277,
			48.338,
			48.369,
			48.401,
			48.479,
			48.525,
			48.587,
			48.649,
			48.695,
			48.71,
			48.775,
			48.807,
			48.838,
			48.916,
			48.961,
			49.023,
			49.087,
			49.15,
			49.21,
			49.243,
			49.274,
			49.35,
			49.398,
			49.461,
			49.522,
			49.569,
			49.585,
			49.648,
			49.678,
			49.709,
			49.787,
			49.834,
			49.898,
			49.958,
			50.003
		]
	],
	'8074_8075_7982': [
		[
			90.359
		],
		[
			0
		],
		[
			0,
			0.17,
			2.664,
			5.167,
			6.422,
			10.2,
			12.731,
			16.542,
			19.094,
			22.938,
			26.803,
			28.096,
			31.994,
			33.299,
			35.92,
			38.553,
			41.183,
			45.134,
			50.39,
			51.707,
			54.347,
			55.669,
			58.319,
			60.976,
			64.974,
			67.648,
			71.672,
			73.017,
			74.363,
			79.767,
			81.122,
			85.195,
			87.92,
			92.019,
			97.507,
			98.884,
			101.641,
			103.021,
			105.788,
			108.561,
			112.732,
			115.521,
			119.718,
			121.12,
			122.523,
			126.743,
			129.563,
			133.807,
			136.644,
			139.487,
			140.911,
			143.764,
			145.192,
			148.054,
			150.922,
			152.358,
			155.229,
			158.089,
			162.333,
			165.12,
			169.228,
			170.578,
			171.918,
			175.882,
			178.476,
			182.296,
			184.795,
			188.472,
			190.877,
			193.247,
			194.419,
			196.736,
			197.881,
			200.142,
			202.365,
			205.628,
			207.755,
			210.876,
			211.898,
			212.91,
			215.89,
			217.83,
			220.671,
			222.519,
			225.227,
			227.86,
			229.571,
			231.246,
			232.069,
			233.688,
			235.27,
			237.575,
			239.066,
			241.943,
			242.64,
			244.681,
			246,
			247.917,
			249.15,
			250.931,
			252.075,
			252.633,
			253.723,
			254.779,
			255.296,
			256.313,
			257.31,
			258.777,
			259.268,
			259.749,
			261.186,
			262.129,
			263.519,
			264.444,
			265.831,
			266.755,
			268.113,
			269.463,
			269.912,
			271.26,
			271.712,
			272.178,
			273.568,
			274.948,
			275.867,
			277.765,
			278.247,
			279.205,
			279.682,
			280.634,
			281.586,
			283.077,
			284.061,
			285.528,
			286.017,
			286.506,
			288.046,
			288.559,
			289.07,
			290.604,
			291.629,
			293.293,
			294.406,
			295.52,
			296.077,
			297.245,
			297.869,
			299.114,
			300.361,
			302.284,
			303.665,
			306.435,
			307.142,
			309.304,
			310.026,
			310.751,
			312.84,
			314.18,
			316.202,
			317.481,
			318.696,
			319.305,
			320.53,
			321.146,
			322.343,
			323.486,
			325.215,
			326.381,
			328.099,
			329.243,
			330.981,
			332.158,
			333.953,
			335.167,
			337.005,
			339.546,
			340.191,
			342.147,
			343.516,
			344.88,
			346.94,
			348.402,
			350.606,
			351.342,
			352.08,
			354.362,
			355.883,
			358.191,
			359.744,
			362.082,
			364.432,
			366.003,
			368.34,
			369.876,
			371.414,
			373.675,
			375.157,
			378.099,
			378.813,
			380.953,
			382.382,
			384.503,
			385.917,
			388.043,
			389.467,
			390.18,
			391.607,
			393.759,
			395.185,
			396.617,
			398.735,
			400.115,
			402.775,
			403.416,
			405.284,
			406.484,
			408.228,
			409.366,
			411.048,
			412.713,
			413.848,
			414.983,
			415.543,
			416.657,
			417.772,
			419.552,
			420.141,
			420.729,
			422.488,
			423.687,
			425.473,
			426.649,
			427.818,
			429.541,
			430.673,
			432.905,
			434.024,
			435.678,
			436.225,
			437.855,
			438.917,
			440.489,
			442.56,
			443.069,
			444.063,
			444.556,
			445.538,
			446.516,
			447.005,
			447.982,
			448.953,
			450.401,
			451.365,
			453.301,
			453.791,
			455.26,
			456.241,
			457.225,
			457.72,
			458.725,
			459.739,
			460.247,
			461.78,
			462.811,
			463.857,
			465.44,
			466.505,
			468.667,
			469.213,
			470.869,
			471.987,
			473.694,
			474.858,
			476.034,
			476.628,
			477.828,
			479.678,
			481.569,
			482.85,
			484.156,
			486.152,
			487.502,
			490.258,
			490.957,
			493.795,
			494.516,
			496.699,
			498.174,
			500.419,
			501.934,
			504.235,
			506.572,
			508.145,
			509.737,
			512.154,
			513.782,
			516.219,
			517.857,
			520.321,
			521.962,
			524.441,
			526.101,
			528.609,
			531.144,
			532.842,
			535.387,
			537.093,
			538.797,
			541.398,
			542.271,
			543.147,
			545.791,
			546.677,
			547.567,
			550.254,
			552.058,
			554.774,
			556.594,
			559.326,
			561.12,
			563.776,
			566.359,
			568.061,
			569.742,
			572.221,
			573.041,
			573.858,
			576.312,
			577.929,
			580.341,
			581.945,
			584.339,
			585.93,
			587.52,
			589.889,
			592.257,
			594.611,
			595.392,
			597.757,
			599.325,
			601.685,
			603.265,
			605.622,
			608.01,
			609.595,
			611.183,
			613.586,
			615.183,
			618.414,
			619.223,
			622.475,
			623.293,
			625.745,
			627.401,
			629.89,
			633.225,
			634.061,
			635.733,
			636.571,
			638.256,
			639.938,
			642.456,
			644.13,
			646.642,
			648.304,
			651.619,
			652.44,
			654.899,
			656.537,
			658.971,
			662.198,
			662.995,
			665.389,
			666.983,
			668.555,
			670.917,
			672.483,
			674.798,
			676.347,
			678.656,
			680.196,
			682.525,
			684.093,
			686.464,
			688.063,
			690.487,
			692.94,
			694.577,
			696.24,
			698.748,
			700.419,
			703.721,
			704.531,
			706.91,
			708.451,
			710.696,
			712.149,
			713.577,
			714.282,
			715.68,
			717.757,
			719.829,
			721.22,
			722.599,
			724.654,
			726.014,
			728.039,
			729.384,
			731.384,
			732.708,
			734.718,
			736.059,
			738.062,
			740.12,
			741.499,
			743.561,
			744.962,
			746.358,
			748.452,
			749.153,
			749.855,
			751.955,
			753.357,
			755.453,
			756.845,
			758.932,
			760.358,
			762.499,
			763.923,
			766.107,
			768.294,
			769.751,
			771.199,
			772.642,
			774.085,
			774.808,
			776.244,
			778.398,
			780.553,
			781.981,
			783.41,
			785.557,
			786.98,
			789.118,
			790.541,
			793.358,
			794.063,
			796.162,
			797.546,
			799.631,
			801.031,
			803.144,
			805.273,
			806.713,
			808.162,
			810.354,
			811.836,
			814.079,
			815.59,
			817.896,
			819.449,
			821.787,
			823.364,
			824.94,
			825.723,
			827.276,
			829.552,
			831.754,
			833.18,
			834.572,
			836.595,
			837.905,
			839.808,
			841.059,
			842.9,
			844.121,
			845.953,
			847.187,
			848.429,
			849.052,
			850.301,
			852.208,
			854.154,
			855.454,
			856.767,
			858.818,
			860.183,
			862.242,
			863.633,
			865.711,
			867.092,
			869.153,
			870.517,
			871.876,
			872.55,
			873.888,
			874.553,
			875.877,
			877.847,
			879.143,
			880.43,
			882.352,
			882.988,
			883.621,
			885.501,
			886.743,
			888.588,
			889.801,
			891.604,
			892.804,
			893.999,
			894.594,
			895.78,
			897.558,
			899.331,
			900.507,
			901.682,
			903.443,
			904.029,
			904.615,
			906.371,
			906.958,
			907.547,
			909.31,
			910.487,
			912.261,
			913.444,
			915.221,
			916.412,
			917.01,
			917.611,
			919.996,
			920.595,
			922.409,
			923.616,
			925.427,
			926.647,
			927.879,
			928.498,
			930.367,
			931.629,
			932.905,
			934.905,
			936.266,
			938.336,
			939.049,
			939.767,
			942.677,
			943.414,
			945.624,
			947.115,
			948.621,
			949.366,
			950.867,
			953.144,
			955.454,
			957.01,
			958.579,
			960.949,
			962.542,
			964.933,
			965.731,
			966.531,
			968.951,
			970.583,
			973.054,
			974.72,
			976.407,
			977.255,
			978.96,
			980.69,
			981.562,
			984.194,
			985.979,
			987.777,
			990.505,
			992.332,
			995.117,
			996.998,
			999.841,
			1001.782,
			1004.717,
			1006.708,
			1009.724,
			1011.75,
			1012.767,
			1014.812,
			1017.878,
			1019.931,
			1021.981,
			1025.058,
			1027.119,
			1030.214,
			1031.245,
			1032.286,
			1035.425,
			1037.539,
			1040.745,
			1042.897,
			1046.144,
			1048.318,
			1049.406,
			1051.59,
			1053.783,
			1054.882,
			1057.087,
			1059.302,
			1062.643,
			1064.881,
			1068.251,
			1069.373,
			1070.493,
			1073.824,
			1076.011,
			1079.225,
			1081.32,
			1084.393,
			1087.382,
			1089.328,
			1091.237,
			1092.178,
			1094.032,
			1095.851,
			1098.518,
			1100.255,
			1102.795,
			1103.624,
			1104.458,
			1106.873,
			1108.451,
			1110.791,
			1112.341,
			1113.894,
			1116.225,
			1118.551,
			1120.868,
			1122.394,
			1123.918,
			1125.442,
			1127.738,
			1130.789,
			1131.562,
			1133.102,
			1133.868,
			1135.397,
			1136.949,
			1139.277,
			1140.819,
			1143.952,
			1144.731,
			1147.071,
			1147.853,
			1148.632,
			1150.962,
			1152.517,
			1154.841,
			1157.94,
			1158.718,
			1160.271,
			1161.047,
			1162.605,
			1164.167,
			1166.509,
			1168.076,
			1171.214,
			1171.998,
			1174.364,
			1175.942,
			1178.326,
			1179.923,
			1182.324,
			1183.924,
			1185.527,
			1186.331,
			1187.932,
			1188.721,
			1190.302,
			1191.889,
			1194.238,
			1195.79,
			1198.901,
			1199.664,
			1201.982,
			1203.511,
			1205.758,
			1207.218,
			1209.347,
			1212.093,
			1212.76,
			1214.082,
			1214.735,
			1216.028,
			1217.306,
			1219.206,
			1220.473,
			1222.365,
			1222.994,
			1223.623,
			1225.542,
			1226.846,
			1228.8,
			1230.117,
			1231.468,
			1232.142,
			1233.49,
			1235.519,
			1237.559,
			1238.916,
			1240.274,
			1242.329,
			1243.692,
			1245.742,
			1247.116,
			1249.167,
			1250.532,
			1252.601,
			1253.975,
			1256.037,
			1257.411,
			1258.098,
			1259.471,
			1261.54,
			1262.922,
			1264.301,
			1266.37,
			1267.058,
			1267.745,
			1269.8,
			1271.169,
			1273.22,
			1274.583,
			1276.627,
			1277.988,
			1280.024,
			1281.383,
			1283.422,
			1285.46,
			1286.823,
			1288.194,
			1290.251,
			1290.939,
			1291.628,
			1293.01,
			1294.392,
			1295.085,
			1297.174,
			1298.569,
			1300.668,
			1302.076,
			1303.483,
			1305.598,
			1306.304,
			1307.011,
			1309.824,
			1310.526,
			1312.633,
			1314.722,
			1316.111,
			1318.191,
			1319.573,
			1321.635,
			1323.006,
			1324.385,
			1326.44,
			1327.803,
			1329.846,
			1330.523,
			1331.199,
			1333.884,
			1334.55,
			1336.536,
			1337.844,
			1339.126,
			1339.764,
			1341.028,
			1342.281,
			1342.916,
			1344.783,
			1346.024,
			1347.256,
			1349.088,
			1350.305,
			1352.118,
			1352.717,
			1353.313,
			1355.707,
			1356.312,
			1358.113,
			1359.303,
			1360.515,
			1361.141,
			1362.386,
			1363.623,
			1364.239,
			1366.119,
			1367.383,
			1368.642,
			1370.534,
			1371.807,
			1373.707,
			1374.339,
			1374.972,
			1376.876,
			1378.137,
			1380.029,
			1381.314,
			1383.24,
			1384.52,
			1386.432,
			1388.328,
			1389.591,
			1390.852,
			1392.697,
			1393.925,
			1395.768,
			1396.355,
			1396.936,
			1398.676,
			1399.839,
			1401.578,
			1402.694,
			1403.813,
			1404.374,
			1405.5,
			1407.202,
			1408.91,
			1410.058,
			1411.214,
			1412.982,
			1414.172,
			1415.971,
			1417.182,
			1419.012,
			1420.239,
			1422.091,
			1423.35,
			1424.614,
			1425.248,
			1426.522,
			1427.162,
			1428.445,
			1429.734,
			1430.381,
			1431.682,
			1432.996,
			1434.995,
			1435.665,
			1436.335,
			1438.363,
			1439.739,
			1441.828,
			1443.23,
			1445.342,
			1446.754,
			1448.891,
			1450.331,
			1451.052,
			1451.774,
			1454.704,
			1456.187,
			1457.695,
			1459.213,
			1460.736,
			1461.503,
			1463.84,
			1464.617,
			1465.396,
			1467.753,
			1469.343,
			1470.95,
			1473.384,
			1475.009,
			1475.831,
			1477.485,
			1478.304,
			1479.125,
			1481.604,
			1483.285,
			1485.833,
			1487.555,
			1489.303,
			1490.174,
			1491.914,
			1493.68,
			1494.568,
			1496.368,
			1497.252,
			1499.061,
			1500.881,
			1503.641,
			1505.5,
			1509.26,
			1510.208,
			1513.073,
			1514.036,
			1515.001,
			1517.914,
			1519.884,
			1521.868,
			1522.862,
			1524.861,
			1526.86,
			1527.856,
			1529.835,
			1530.815,
			1532.755,
			1534.66,
			1537.434,
			1539.235,
			1541.862,
			1542.719,
			1543.566,
			1546.047,
			1547.652,
			1549.99,
			1551.496,
			1552.965,
			1553.686,
			1555.1,
			1556.485,
			1557.17,
			1559.199,
			1560.542,
			1561.894,
			1563.891,
			1565.213,
			1567.172,
			1567.819,
			1568.464,
			1570.385,
			1571.645,
			1573.506,
			1574.737,
			1575.964,
			1576.581,
			1577.81,
			1578.422,
			1579.643,
			1581.482,
			1582.723,
			1583.96,
			1585.815,
			1587.103,
			1589.049,
			1589.699,
			1590.349,
			1592.383,
			1593.783,
			1595.892,
			1597.338,
			1599.536,
			1601.01,
			1601.756,
			1603.287,
			1604.826,
			1605.599,
			1607.157,
			1608.733,
			1611.115,
			1612.703,
			1615.079,
			1615.875,
			1616.674,
			1619.016,
			1620.577,
			1622.877,
			1624.34,
			1626.54,
			1627.999,
			1628.72,
			1630.174,
			1632.398,
			1633.891,
			1635.398,
			1637.684,
			1639.26,
			1641.652,
			1642.454,
			1643.26,
			1645.746,
			1647.412,
			1649.967,
			1651.703,
			1653.443,
			1654.331,
			1656.125,
			1657.023,
			1658.821,
			1661.529,
			1663.333,
			1665.131,
			1666.92,
			1669.597,
			1671.352,
			1673.103,
			1673.979,
			1675.698,
			1676.547,
			1678.242,
			1680.764,
			1682.418,
			1684.065,
			1687.318,
			1688.119,
			1690.479,
			1691.257,
			1692.029,
			1694.307,
			1695.807,
			1697.297,
			1698.052,
			1699.534,
			1701.014,
			1701.755,
			1703.231,
			1703.966,
			1705.432,
			1706.919,
			1709.158,
			1710.641,
			1712.932,
			1713.699,
			1714.464,
			1716.762,
			1717.549,
			1718.333,
			1720.678,
			1722.25,
			1724.642,
			1727.847,
			1728.658,
			1731.094,
			1732.703,
			1734.303,
			1736.713,
			1738.265,
			1740.587,
			1742.144,
			1745.126,
			1745.875,
			1748.118,
			1749.603,
			1751.854,
			1753.371,
			1755.672,
			1758.009,
			1759.579,
			1761.165,
			1763.575,
			1765.198,
			1768.488,
			1769.322,
			1771.832,
			1773.524,
			1776.111,
			1777.853,
			1779.612,
			1780.497,
			1782.279,
			1784.977,
			1787.705,
			1789.541,
			1791.389,
			1794.187,
			1796.078,
			1798.925,
			1800.85,
			1803.762,
			1805.719,
			1808.676,
			1810.662,
			1813.663,
			1815.678,
			1818.722,
			1821.797,
			1823.864,
			1825.944,
			1829.08,
			1831.172,
			1834.332,
			1836.451,
			1839.647,
			1841.788,
			1845.009,
			1847.173,
			1849.35,
			1850.444,
			1852.644,
			1855.971,
			1859.341,
			1861.605,
			1863.884,
			1867.32,
			1868.465,
			1869.613,
			1873.069,
			1875.379,
			1878.859,
			1881.177,
			1884.63,
			1886.901,
			1890.254,
			1893.534,
			1895.685,
			1897.799,
			1898.842,
			1900.899,
			1902.918,
			1904.893,
			1907.781,
			1909.659,
			1912.409,
			1915.084,
			1916.826,
			1919.371,
			1921.022,
			1922.638,
			1925.762,
			1926.52,
			1929.466,
			1930.18,
			1932.268,
			1933.619,
			1935.584,
			1938.088,
			1938.692,
			1940.452,
			1941.573,
			1942.658,
			1944.224,
			1945.236,
			1946.679,
			1947.607,
			1948.958,
			1949.841,
			1951.145,
			1952.002,
			1953.277,
			1954.972,
			1955.395,
			1956.666,
			1957.511,
			1958.352,
			1959.608,
			1960.444,
			1961.725,
			1962.155,
			1962.583,
			1963.855,
			1964.695,
			1965.944,
			1966.796,
			1968.06,
			1968.887,
			1970.112,
			1971.326,
			1972.156,
			1972.972,
			1974.178,
			1974.974,
			1976.165,
			1976.979,
			1978.196,
			1978.997,
			1980.192,
			1980.989,
			1982.215,
			1983.045,
			1984.286,
			1985.53,
			1986.367,
			1987.23,
			1988.557,
			1989.446,
			1990.792,
			1991.702,
			1993.128,
			1994.092,
			1995.557,
			1996.55,
			1998.086,
			1999.657,
			2000.723,
			2002.354,
			2003.461,
			2004.583,
			2006.299,
			2007.464,
			2009.203,
			2010.384,
			2012.187,
			2013.374,
			2015.156,
			2016.362,
			2018.188,
			2019.384,
			2021.181,
			2023.008,
			2024.277,
			2025.562,
			2027.516,
			2028.857,
			2030.227,
			2032.308,
			2034.437,
			2035.877,
			2038.064,
			2039.54,
			2041.028,
			2043.288,
			2044.818,
			2047.919,
			2048.704,
			2051.086,
			2052.679,
			2054.299,
			2055.112,
			2056.75,
			2059.234,
			2061.731,
			2063.397,
			2065.074,
			2067.616,
			2069.326,
			2072.789,
			2073.663,
			2077.196,
			2078.088,
			2080.783,
			2082.598,
			2084.425,
			2085.344,
			2087.194,
			2089.993,
			2092.827,
			2094.731,
			2096.652,
			2099.557,
			2101.511,
			2104.484,
			2106.471,
			2109.476,
			2111.495,
			2114.545,
			2116.593,
			2119.685,
			2121.76,
			2124.891,
			2128.039,
			2130.135,
			2132.236,
			2135.393,
			2137.509,
			2140.702,
			2141.77,
			2142.841,
			2146.089,
			2148.267,
			2151.557,
			2153.762,
			2157.099,
			2159.335,
			2162.754,
			2166.21,
			2168.542,
			2170.885,
			2174.421,
			2176.797,
			2181.605,
			2182.821,
			2186.482,
			2188.945,
			2192.646,
			2195.105,
			2198.792,
			2202.479,
			2204.957,
			2208.697,
			2211.211,
			2213.732,
			2217.517,
			2220.027,
			2223.805,
			2226.315,
			2236.429,
			2238.961,
			2241.542,
			2242.824,
			2245.374,
			2249.231,
			2253.094,
			2255.676,
			2258.261,
			2262.153,
			2263.452,
			2264.754,
			2268.662,
			2269.983,
			2271.284,
			2275.176,
			2277.792,
			2281.734,
			2284.372,
			2288.344,
			2291.001,
			2295,
			2299.014,
			2301.698,
			2304.389,
			2308.435,
			2311.141,
			2315.21,
			2316.57,
			2317.93,
			2322.021,
			2324.756,
			2328.871,
			2331.622,
			2334.379,
			2335.76,
			2338.528,
			2339.914,
			2341.302,
			2345.478,
			2346.874,
			2349.671,
			2352.475,
			2355.288,
			2359.541,
			2362.367,
			2366.614,
			2370.867,
			2373.705,
			2377.963,
			2380.804,
			2383.648,
			2387.916,
			2389.34,
			2390.764,
			2396.471,
			2397.902,
			2402.21,
			2405.092,
			2407.988,
			2409.438,
			2412.346,
			2416.724,
			2421.126,
			2424.079,
			2427.04,
			2431.498,
			2434.48,
			2438.938,
			2440.459,
			2441.957,
			2446.462,
			2447.942,
			2449.448,
			2454.003,
			2457.031,
			2460.065,
			2461.583,
			2464.625,
			2469.197,
			2473.754,
			2476.814,
			2479.879,
			2484.484,
			2487.561,
			2492.184,
			2493.728,
			2495.271,
			2499.936,
			2503.008,
			2507.658,
			2510.759,
			2515.413,
			2518.518,
			2521.626,
			2523.182,
			2527.858,
			2530.982,
			2534.113,
			2538.816,
			2541.955,
			2546.663,
			2549.806,
			2554.529,
			2557.684,
			2562.43,
			2565.606,
			2568.79,
			2570.387,
			2573.581,
			2578.38,
			2583.182,
			2586.385,
			2589.59,
			2594.403,
			2597.615,
			2602.442,
			2604.053,
			2605.666,
			2610.511,
			2613.747,
			2618.609,
			2621.855,
			2625.106,
			2626.733,
			2629.99,
			2634.883,
			2639.785,
			2643.058,
			2646.336,
			2651.26,
			2652.903,
			2654.547,
			2659.486,
			2661.135,
			2662.784,
			2667.738,
			2671.046,
			2676.015,
			2679.333,
			2684.316,
			2687.644,
			2689.31,
			2692.644,
			2695.981,
			2697.652,
			2699.323,
			2704.344,
			2709.374,
			2711.053,
			2712.732,
			2716.094,
			2721.143,
			2726.2,
			2729.577,
			2734.649,
			2738.036,
			2741.426,
			2746.518,
			2748.217,
			2749.917,
			2756.723,
			2758.423,
			2763.495,
			2766.844,
			2770.157,
			2771.798,
			2775.052,
			2778.267,
			2779.859,
			2783.016,
			2784.58,
			2787.679,
			2790.74,
			2795.264,
			2798.235,
			2802.619,
			2805.494,
			2809.733,
			2811.127,
			2812.512,
			2816.607,
			2819.29,
			2823.243,
			2825.83,
			2828.379,
			2829.64,
			2832.133,
			2833.365,
			2835.803,
			2838.206,
			2841.743,
			2844.058,
			2847.461,
			2848.576,
			2849.682,
			2852.943,
			2855.071,
			2858.192,
			2860.225,
			2862.222,
			2863.207,
			2865.148,
			2866.105,
			2867.991,
			2869.841,
			2870.753,
			2872.553,
			2874.319,
			2876.902,
			2878.579,
			2881.845,
			2882.642,
			2885.002,
			2886.555,
			2888.864,
			2890.398,
			2891.93,
			2892.69,
			2894.209,
			2894.968,
			2896.487,
			2897.995,
			2898.745,
			2900.244,
			2901.746,
			2904.032,
			2905.569,
			2907.888,
			2908.712,
			2909.537,
			2912.016,
			2913.679,
			2916.192,
			2917.869,
			2920.417,
			2922.121,
			2922.974,
			2924.747,
			2926.561,
			2927.467,
			2929.296,
			2931.197,
			2934.044,
			2935.006,
			2935.969,
			2938.851,
			2939.814,
			2940.778,
			2943.661,
			2945.583,
			2948.458,
			2950.344,
			2952.178,
			2953.093,
			2954.924,
			2957.623,
			2960.301,
			2961.999,
			2963.695,
			2966.195,
			2967.791,
			2970.989,
			2971.757,
			2974.064,
			2975.607,
			2977.935,
			2979.491,
			2981.048,
			2981.828,
			2983.4,
			2984.186,
			2984.972,
			2988.144,
			2989.751,
			2991.362,
			2992.98,
			2994.605,
			2995.42,
			2997.056,
			2998.709,
			2999.539,
			3002.048,
			3003.751,
			3005.476,
			3008.088,
			3009.831,
			3012.492,
			3013.385,
			3014.279,
			3016.978,
			3017.884,
			3018.793,
			3021.535,
			3023.379,
			3025.233,
			3026.165,
			3028.037,
			3029.92,
			3030.866,
			3033.722,
			3035.64,
			3037.569,
			3040.491,
			3042.458,
			3045.435,
			3046.436,
			3047.44,
			3050.471,
			3052.508,
			3055.583,
			3057.642,
			3060.748,
			3062.821,
			3064.895,
			3065.933,
			3069.061,
			3071.166,
			3073.289,
			3076.489,
			3078.632,
			3081.863,
			3082.943,
			3084.026,
			3087.293,
			3089.487,
			3092.797,
			3095.016,
			3098.367,
			3101.743,
			3104.005,
			3106.278,
			3107.417,
			3109.706,
			3112.006,
			3115.47,
			3117.791,
			3121.291,
			3122.462,
			3123.636,
			3127.168,
			3129.535,
			3133.103,
			3135.492,
			3137.891,
			3139.094,
			3141.507,
			3142.717,
			3145.144,
			3148.801,
			3151.25,
			3153.708,
			3157.416,
			3158.935
		],
		[
			0,
			0.002,
			0.063,
			0.097,
			0.127,
			0.19,
			0.252,
			0.318,
			0.375,
			0.44,
			0.532,
			0.562,
			0.625,
			0.641,
			0.689,
			0.751,
			0.814,
			0.876,
			0.971,
			1.001,
			1.063,
			1.077,
			1.125,
			1.186,
			1.25,
			1.297,
			1.375,
			1.406,
			1.437,
			1.516,
			1.56,
			1.624,
			1.687,
			1.748,
			1.843,
			1.875,
			1.936,
			1.952,
			1.999,
			2.061,
			2.122,
			2.184,
			2.247,
			2.279,
			2.309,
			2.371,
			2.435,
			2.499,
			2.56,
			2.607,
			2.622,
			2.684,
			2.714,
			2.747,
			2.809,
			2.824,
			2.872,
			2.934,
			2.997,
			3.057,
			3.121,
			3.151,
			3.183,
			3.245,
			3.308,
			3.37,
			3.433,
			3.494,
			3.556,
			3.589,
			3.618,
			3.681,
			3.697,
			3.745,
			3.806,
			3.87,
			3.931,
			3.994,
			4.026,
			4.057,
			4.134,
			4.18,
			4.244,
			4.306,
			4.368,
			4.463,
			4.491,
			4.555,
			4.57,
			4.619,
			4.681,
			4.741,
			4.804,
			4.897,
			4.929,
			4.991,
			5.055,
			5.12,
			5.178,
			5.241,
			5.305,
			5.334,
			5.367,
			5.426,
			5.444,
			5.491,
			5.552,
			5.616,
			5.66,
			5.677,
			5.74,
			5.804,
			5.865,
			5.926,
			5.991,
			6.052,
			6.115,
			6.207,
			6.239,
			6.302,
			6.316,
			6.364,
			6.426,
			6.49,
			6.552,
			6.645,
			6.676,
			6.737,
			6.754,
			6.801,
			6.863,
			6.925,
			6.986,
			7.049,
			7.081,
			7.113,
			7.174,
			7.196,
			7.236,
			7.3,
			7.362,
			7.424,
			7.488,
			7.518,
			7.55,
			7.612,
			7.626,
			7.673,
			7.737,
			7.799,
			7.861,
			7.955,
			7.985,
			8.048,
			8.064,
			8.109,
			8.173,
			8.234,
			8.298,
			8.36,
			8.391,
			8.422,
			8.485,
			8.501,
			8.548,
			8.609,
			8.67,
			8.734,
			8.797,
			8.859,
			8.936,
			8.985,
			9.047,
			9.108,
			9.172,
			9.268,
			9.296,
			9.373,
			9.421,
			9.484,
			9.544,
			9.608,
			9.669,
			9.702,
			9.732,
			9.793,
			9.856,
			9.921,
			9.981,
			10.043,
			10.136,
			10.169,
			10.246,
			10.294,
			10.355,
			10.418,
			10.48,
			10.574,
			10.605,
			10.682,
			10.73,
			10.792,
			10.856,
			10.918,
			10.979,
			11.01,
			11.042,
			11.12,
			11.167,
			11.229,
			11.29,
			11.354,
			11.447,
			11.479,
			11.557,
			11.603,
			11.667,
			11.726,
			11.791,
			11.885,
			11.915,
			11.975,
			11.992,
			12.04,
			12.103,
			12.164,
			12.212,
			12.226,
			12.289,
			12.35,
			12.43,
			12.475,
			12.538,
			12.602,
			12.663,
			12.758,
			12.789,
			12.866,
			12.913,
			12.98,
			13.037,
			13.101,
			13.193,
			13.226,
			13.288,
			13.302,
			13.35,
			13.413,
			13.414,
			13.474,
			13.521,
			13.6,
			13.662,
			13.74,
			13.785,
			13.848,
			13.91,
			13.957,
			13.973,
			14.035,
			14.067,
			14.098,
			14.175,
			14.222,
			14.286,
			14.348,
			14.408,
			14.504,
			14.534,
			14.597,
			14.659,
			14.721,
			14.783,
			14.831,
			14.846,
			14.909,
			14.972,
			15.05,
			15.096,
			15.159,
			15.22,
			15.283,
			15.376,
			15.407,
			15.489,
			15.532,
			15.596,
			15.656,
			15.719,
			15.782,
			15.845,
			15.921,
			15.97,
			16.033,
			16.093,
			16.141,
			16.219,
			16.281,
			16.359,
			16.405,
			16.469,
			16.531,
			16.592,
			16.686,
			16.717,
			16.793,
			16.842,
			16.905,
			16.966,
			17.014,
			17.029,
			17.093,
			17.123,
			17.154,
			17.232,
			17.278,
			17.343,
			17.404,
			17.466,
			17.527,
			17.59,
			17.668,
			17.715,
			17.776,
			17.84,
			17.887,
			17.901,
			17.966,
			18.027,
			18.104,
			18.15,
			18.216,
			18.275,
			18.323,
			18.4,
			18.464,
			18.541,
			18.589,
			18.651,
			18.713,
			18.776,
			18.837,
			18.901,
			18.976,
			19.026,
			19.087,
			19.15,
			19.213,
			19.305,
			19.338,
			19.415,
			19.462,
			19.524,
			19.588,
			19.648,
			19.743,
			19.773,
			19.837,
			19.853,
			19.897,
			19.962,
			20.023,
			20.07,
			20.149,
			20.209,
			20.289,
			20.335,
			20.398,
			20.46,
			20.521,
			20.616,
			20.647,
			20.724,
			20.772,
			20.835,
			20.896,
			20.959,
			21.02,
			21.083,
			21.163,
			21.209,
			21.271,
			21.334,
			21.396,
			21.459,
			21.519,
			21.598,
			21.646,
			21.71,
			21.769,
			21.833,
			21.925,
			21.957,
			22.034,
			22.082,
			22.143,
			22.207,
			22.253,
			22.268,
			22.331,
			22.391,
			22.47,
			22.517,
			22.581,
			22.641,
			22.705,
			22.766,
			22.83,
			22.907,
			22.955,
			23.018,
			23.079,
			23.14,
			23.234,
			23.266,
			23.343,
			23.39,
			23.451,
			23.515,
			23.56,
			23.578,
			23.639,
			23.702,
			23.783,
			23.826,
			23.89,
			23.95,
			24.014,
			24.074,
			24.139,
			24.216,
			24.264,
			24.325,
			24.388,
			24.434,
			24.451,
			24.513,
			24.576,
			24.653,
			24.699,
			24.762,
			24.824,
			24.872,
			24.95,
			25.01,
			25.09,
			25.136,
			25.2,
			25.259,
			25.322,
			25.387,
			25.448,
			25.526,
			25.574,
			25.635,
			25.697,
			25.743,
			25.821,
			25.885,
			25.963,
			26.009,
			26.072,
			26.134,
			26.181,
			26.197,
			26.26,
			26.321,
			26.399,
			26.446,
			26.509,
			26.57,
			26.633,
			26.696,
			26.756,
			26.834,
			26.883,
			26.943,
			27.008,
			27.053,
			27.07,
			27.133,
			27.195,
			27.272,
			27.319,
			27.382,
			27.442,
			27.491,
			27.567,
			27.63,
			27.708,
			27.756,
			27.819,
			27.88,
			27.931,
			27.942,
			28.004,
			28.035,
			28.068,
			28.146,
			28.192,
			28.256,
			28.316,
			28.363,
			28.38,
			28.44,
			28.503,
			28.582,
			28.629,
			28.692,
			28.753,
			28.799,
			28.814,
			28.879,
			28.941,
			29.018,
			29.066,
			29.127,
			29.19,
			29.236,
			29.253,
			29.315,
			29.343,
			29.376,
			29.453,
			29.5,
			29.564,
			29.626,
			29.69,
			29.751,
			29.782,
			29.814,
			29.892,
			29.936,
			30.004,
			30.063,
			30.126,
			30.187,
			30.218,
			30.249,
			30.328,
			30.374,
			30.437,
			30.5,
			30.562,
			30.623,
			30.654,
			30.686,
			30.764,
			30.81,
			30.877,
			30.936,
			30.982,
			30.997,
			31.059,
			31.124,
			31.201,
			31.249,
			31.309,
			31.373,
			31.435,
			31.497,
			31.527,
			31.56,
			31.637,
			31.684,
			31.747,
			31.809,
			31.855,
			31.87,
			31.934,
			31.966,
			31.996,
			32.077,
			32.12,
			32.182,
			32.246,
			32.292,
			32.371,
			32.433,
			32.511,
			32.558,
			32.62,
			32.681,
			32.743,
			32.806,
			32.837,
			32.868,
			32.947,
			32.994,
			33.059,
			33.12,
			33.164,
			33.243,
			33.274,
			33.307,
			33.367,
			33.43,
			33.493,
			33.555,
			33.618,
			33.681,
			33.711,
			33.742,
			33.805,
			33.821,
			33.866,
			33.93,
			33.991,
			34.055,
			34.116,
			34.153,
			34.179,
			34.24,
			34.303,
			34.365,
			34.427,
			34.492,
			34.583,
			34.615,
			34.677,
			34.693,
			34.741,
			34.802,
			34.866,
			34.925,
			34.991,
			35.021,
			35.052,
			35.115,
			35.176,
			35.24,
			35.301,
			35.363,
			35.425,
			35.489,
			35.566,
			35.614,
			35.677,
			35.738,
			35.8,
			35.893,
			35.927,
			35.988,
			36.005,
			36.05,
			36.113,
			36.174,
			36.236,
			36.332,
			36.363,
			36.425,
			36.44,
			36.487,
			36.553,
			36.613,
			36.675,
			36.767,
			36.797,
			36.862,
			36.876,
			36.924,
			36.985,
			37.049,
			37.112,
			37.204,
			37.235,
			37.297,
			37.362,
			37.424,
			37.485,
			37.547,
			37.609,
			37.642,
			37.673,
			37.734,
			37.75,
			37.797,
			37.859,
			37.921,
			37.984,
			38.079,
			38.108,
			38.171,
			38.234,
			38.301,
			38.36,
			38.421,
			38.515,
			38.546,
			38.607,
			38.623,
			38.669,
			38.734,
			38.796,
			38.856,
			38.921,
			38.95,
			38.982,
			39.059,
			39.108,
			39.17,
			39.232,
			39.279,
			39.293,
			39.356,
			39.419,
			39.497,
			39.545,
			39.608,
			39.668,
			39.73,
			39.793,
			39.857,
			39.935,
			39.98,
			40.042,
			40.106,
			40.168,
			40.23,
			40.261,
			40.291,
			40.372,
			40.418,
			40.48,
			40.541,
			40.589,
			40.603,
			40.666,
			40.73,
			40.807,
			40.855,
			40.92,
			40.979,
			41.042,
			41.104,
			41.166,
			41.243,
			41.289,
			41.351,
			41.416,
			41.46,
			41.479,
			41.539,
			41.572,
			41.601,
			41.679,
			41.728,
			41.789,
			41.852,
			41.898,
			41.975,
			42.009,
			42.038,
			42.117,
			42.162,
			42.225,
			42.289,
			42.352,
			42.414,
			42.475,
			42.553,
			42.601,
			42.662,
			42.724,
			42.771,
			42.851,
			42.881,
			42.913,
			42.99,
			43.037,
			43.1,
			43.162,
			43.209,
			43.224,
			43.285,
			43.316,
			43.349,
			43.427,
			43.473,
			43.536,
			43.597,
			43.661,
			43.724,
			43.754,
			43.783,
			43.864,
			43.91,
			43.977,
			44.035,
			44.08,
			44.096,
			44.159,
			44.19,
			44.221,
			44.299,
			44.346,
			44.409,
			44.472,
			44.521,
			44.596,
			44.625,
			44.659,
			44.737,
			44.783,
			44.846,
			44.908,
			44.969,
			45.033,
			45.095,
			45.173,
			45.219,
			45.283,
			45.343,
			45.407,
			45.469,
			45.499,
			45.53,
			45.595,
			45.656,
			45.718,
			45.781,
			45.826,
			45.843,
			45.906,
			45.969,
			46.047,
			46.094,
			46.157,
			46.219,
			46.28,
			46.343,
			46.404,
			46.483,
			46.53,
			46.596,
			46.654,
			46.7,
			46.716,
			46.78,
			46.81,
			46.842,
			46.903,
			46.918,
			46.965,
			47.029,
			47.091,
			47.138,
			47.153,
			47.215,
			47.279,
			47.357,
			47.401,
			47.465,
			47.527,
			47.59,
			47.653,
			47.683,
			47.713,
			47.793,
			47.839,
			47.902,
			47.964,
			48.011,
			48.026,
			48.089,
			48.121,
			48.151,
			48.23,
			48.277,
			48.337,
			48.4,
			48.447,
			48.464,
			48.526,
			48.556,
			48.588,
			48.651,
			48.713,
			48.775,
			48.838,
			48.883,
			48.899,
			48.962,
			48.992,
			49.023,
			49.085,
			49.102,
			49.149,
			49.209,
			49.275,
			49.337,
			49.43,
			49.46,
			49.524,
			49.539,
			49.587,
			49.647,
			49.71,
			49.757,
			49.773,
			49.834,
			49.865,
			49.898,
			49.96,
			49.975,
			50.023,
			50.085,
			50.146,
			50.194,
			50.273,
			50.304,
			50.333,
			50.413,
			50.457,
			50.523,
			50.584,
			50.63,
			50.647,
			50.708,
			50.742,
			50.771,
			50.849,
			50.896,
			50.959,
			51.02,
			51.083,
			51.144,
			51.177,
			51.207,
			51.27,
			51.332,
			51.393,
			51.457,
			51.503,
			51.518,
			51.582,
			51.613,
			51.645,
			51.723,
			51.768,
			51.832,
			51.892,
			51.939,
			52.018,
			52.049,
			52.079,
			52.142,
			52.204,
			52.267,
			52.33,
			52.391,
			52.456,
			52.487,
			52.518,
			52.58,
			52.596,
			52.642,
			52.705,
			52.767,
			52.83,
			52.891,
			52.923,
			52.953,
			53.033,
			53.077,
			53.14,
			53.203,
			53.266,
			53.329,
			53.36,
			53.39,
			53.469,
			53.514,
			53.576,
			53.639,
			53.701,
			53.766,
			53.797,
			53.827,
			53.889,
			53.952,
			54.015,
			54.078,
			54.123,
			54.14,
			54.201,
			54.234,
			54.264,
			54.341,
			54.389,
			54.452,
			54.512,
			54.575,
			54.638,
			54.668,
			54.7,
			54.763,
			54.78,
			54.825,
			54.891,
			54.949,
			55.013,
			55.106,
			55.137,
			55.199,
			55.216,
			55.261,
			55.324,
			55.386,
			55.435,
			55.45,
			55.512,
			55.543,
			55.573,
			55.638,
			55.651,
			55.698,
			55.762,
			55.825,
			55.886,
			55.947,
			55.98,
			56.01,
			56.074,
			56.089,
			56.135,
			56.199,
			56.26,
			56.323,
			56.416,
			56.447,
			56.525,
			56.573,
			56.634,
			56.697,
			56.76,
			56.823,
			56.884,
			56.967,
			57.008,
			57.071,
			57.134,
			57.197,
			57.258,
			57.322,
			57.399,
			57.447,
			57.508,
			57.571,
			57.634,
			57.727,
			57.759,
			57.835,
			57.883,
			57.946,
			58.007,
			58.054,
			58.069,
			58.132,
			58.193,
			58.274,
			58.319,
			58.381,
			58.444,
			58.506,
			58.569,
			58.631,
			58.71,
			58.756,
			58.822,
			58.881,
			58.942,
			59.006,
			59.067,
			59.145,
			59.194,
			59.255,
			59.317,
			59.365,
			59.44,
			59.505,
			59.583,
			59.63,
			59.691,
			59.754,
			59.8,
			59.816,
			59.879,
			59.942,
			60.019,
			60.065,
			60.129,
			60.19,
			60.236,
			60.252,
			60.316,
			60.376,
			60.456,
			60.501,
			60.565,
			60.628,
			60.689,
			60.783,
			60.814,
			60.878,
			60.891,
			60.94,
			61.001,
			61.064,
			61.125,
			61.188,
			61.251,
			61.329,
			61.374,
			61.439,
			61.499,
			61.564,
			61.657,
			61.688,
			61.766,
			61.812,
			61.876,
			61.937,
			62,
			62.093,
			62.124,
			62.201,
			62.249,
			62.313,
			62.373,
			62.42,
			62.498,
			62.559,
			62.637,
			62.685,
			62.749,
			62.808,
			62.872,
			62.965,
			62.997,
			63.076,
			63.123,
			63.185,
			63.246,
			63.294,
			63.371,
			63.403,
			63.434,
			63.513,
			63.559,
			63.626,
			63.683,
			63.746,
			63.809,
			63.869,
			63.949,
			63.996,
			64.057,
			64.121,
			64.183,
			64.245,
			64.306,
			64.385,
			64.433,
			64.493,
			64.556,
			64.618,
			64.68,
			64.743,
			64.822,
			64.868,
			64.931,
			64.992,
			65.056,
			65.119,
			65.181,
			65.263,
			65.306,
			65.368,
			65.43,
			65.492,
			65.586,
			65.617,
			65.696,
			65.742,
			65.805,
			65.865,
			65.93,
			65.992,
			66.054,
			66.133,
			66.177,
			66.242,
			66.303,
			66.366,
			66.427,
			66.491,
			66.567,
			66.616,
			66.677,
			66.741,
			66.801,
			66.864,
			66.928,
			67.005,
			67.052,
			67.115,
			67.177,
			67.238,
			67.302,
			67.364,
			67.443,
			67.488,
			67.551,
			67.613,
			67.66,
			67.676,
			67.738,
			67.799,
			67.878,
			67.924,
			67.988,
			68.051,
			68.113,
			68.205,
			68.238,
			68.315,
			68.36,
			68.426,
			68.488,
			68.534,
			68.549,
			68.612,
			68.675,
			68.751,
			68.799,
			68.862,
			68.924,
			68.984,
			69.047,
			69.109,
			69.188,
			69.235,
			69.299,
			69.359,
			69.423,
			69.484,
			69.547,
			69.624,
			69.671,
			69.733,
			69.797,
			69.842,
			69.92,
			69.951,
			69.984,
			70.06,
			70.107,
			70.171,
			70.232,
			70.296,
			70.357,
			70.421,
			70.498,
			70.545,
			70.608,
			70.67,
			70.732,
			70.826,
			70.858,
			70.935,
			70.982,
			71.044,
			71.107,
			71.168,
			71.263,
			71.294,
			71.37,
			71.418,
			71.484,
			71.544,
			71.59,
			71.667,
			71.73,
			71.917,
			71.98,
			72.026,
			72.042,
			72.105,
			72.167,
			72.246,
			72.292,
			72.357,
			72.417,
			72.463,
			72.48,
			72.54,
			72.572,
			72.603,
			72.681,
			72.726,
			72.791,
			72.854,
			72.916,
			72.976,
			73.039,
			73.116,
			73.165,
			73.225,
			73.289,
			73.351,
			73.414,
			73.446,
			73.478,
			73.559,
			73.602,
			73.665,
			73.725,
			73.772,
			73.79,
			73.85,
			73.883,
			73.914,
			73.975,
			73.991,
			74.039,
			74.102,
			74.163,
			74.225,
			74.287,
			74.35,
			74.428,
			74.474,
			74.539,
			74.599,
			74.647,
			74.725,
			74.755,
			74.787,
			74.866,
			74.913,
			74.976,
			75.037,
			75.082,
			75.1,
			75.16,
			75.224,
			75.303,
			75.349,
			75.412,
			75.474,
			75.521,
			75.597,
			75.633,
			75.662,
			75.723,
			75.739,
			75.785,
			75.849,
			75.909,
			75.956,
			75.973,
			76.034,
			76.098,
			76.175,
			76.221,
			76.284,
			76.346,
			76.394,
			76.471,
			76.503,
			76.534,
			76.612,
			76.657,
			76.721,
			76.784,
			76.846,
			76.908,
			76.94,
			76.971,
			77.049,
			77.096,
			77.157,
			77.221,
			77.266,
			77.345,
			77.408,
			77.485,
			77.532,
			77.595,
			77.658,
			77.707,
			77.718,
			77.781,
			77.843,
			77.921,
			77.969,
			78.032,
			78.092,
			78.14,
			78.218,
			78.249,
			78.28,
			78.341,
			78.405,
			78.466,
			78.53,
			78.576,
			78.592,
			78.654,
			78.716,
			78.795,
			78.842,
			78.903,
			78.967,
			79.013,
			79.029,
			79.09,
			79.123,
			79.152,
			79.231,
			79.279,
			79.34,
			79.402,
			79.465,
			79.526,
			79.558,
			79.59,
			79.653,
			79.668,
			79.713,
			79.78,
			79.84,
			79.887,
			79.902,
			79.964,
			80.025,
			80.104,
			80.151,
			80.214,
			80.275,
			80.337,
			80.401,
			80.432,
			80.463,
			80.54,
			80.586,
			80.65,
			80.713,
			80.76,
			80.775,
			80.837,
			80.869,
			80.898,
			80.96,
			80.978,
			81.023,
			81.087,
			81.147,
			81.195,
			81.273,
			81.337,
			81.399,
			81.414,
			81.459,
			81.522,
			81.584,
			81.647,
			81.71,
			81.74,
			81.773,
			81.835,
			81.854,
			81.897,
			81.959,
			82.023,
			82.084,
			82.146,
			82.176,
			82.208,
			82.287,
			82.334,
			82.397,
			82.458,
			82.506,
			82.522,
			82.583,
			82.613,
			82.645,
			82.708,
			82.723,
			82.771,
			82.833,
			82.895,
			82.956,
			83.05,
			83.081,
			83.144,
			83.207,
			83.271,
			83.332,
			83.379,
			83.393,
			83.457,
			83.486,
			83.52,
			83.58,
			83.597,
			83.643,
			83.705,
			83.767,
			83.814,
			83.891,
			83.926,
			83.955,
			84.017,
			84.079,
			84.143,
			84.206,
			84.266,
			84.33,
			84.359,
			84.393,
			84.453,
			84.471,
			84.518,
			84.58,
			84.64,
			84.687,
			84.703,
			84.767,
			84.797,
			84.829,
			84.907,
			84.954,
			85.019,
			85.077,
			85.124,
			85.139,
			85.204,
			85.266,
			85.344,
			85.39,
			85.452,
			85.516,
			85.575,
			85.669,
			85.703,
			85.78,
			85.825,
			85.89,
			85.952,
			86.002,
			86.014,
			86.075,
			86.106,
			86.137,
			86.216,
			86.264,
			86.325,
			86.387,
			86.434,
			86.45,
			86.513,
			86.544,
			86.574,
			86.654,
			86.699,
			86.762,
			86.823,
			86.888,
			86.949,
			86.98,
			87.011,
			87.074,
			87.09,
			87.135,
			87.199,
			87.26,
			87.308,
			87.324,
			87.384,
			87.418,
			87.449,
			87.526,
			87.573,
			87.635,
			87.697,
			87.76,
			87.821,
			87.852,
			87.884,
			87.946,
			88.008,
			88.074,
			88.133,
			88.197,
			88.259,
			88.29,
			88.322,
			88.399,
			88.444,
			88.508,
			88.571,
			88.632,
			88.695,
			88.726,
			88.757,
			88.835,
			88.88,
			88.942,
			89.006,
			89.068,
			89.164,
			89.193,
			89.254,
			89.273,
			89.319,
			89.381,
			89.442,
			89.505,
			89.569,
			89.598,
			89.629,
			89.709,
			89.754,
			89.817,
			89.88,
			89.925,
			89.941,
			90.005,
			90.036,
			90.066,
			90.148,
			90.192,
			90.255,
			90.316,
			90.359
		]
	]
};

export default function getRecordedLapsData(trackingString: string) {
	const lapData: number[][] = [[], [], [], []];
	if (recordedLapsData[trackingString] !== undefined) {
		lapData[0][0] = recordedLapsData[trackingString][0][0];
		lapData[1][0] = recordedLapsData[trackingString][1][0];
		lapData[2] = recordedLapsData[trackingString][2].filter(() => {
			return true;
		});
		lapData[3] = recordedLapsData[trackingString][3].filter(() => {
			return true;
		});
		// showDebugMessage(`${lapData[2][5]}`);
		return recordedLapsData[trackingString];
	}
	return [[-1], [0], [0], [0]];
}

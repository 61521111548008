// tslint:disable:max-line-length
// tslint:disable:quotemark
// tslint:disable:object-literal-key-quotes
const translations = {
	"de": {
		"Aerodynamics": "Karosserie",
		"All Fuel/Lap Tracking data got deleted!": "Alle Sprit/Runden daten wurden gelöscht!",
		"Auto Hide": "Automatisch ausblenden",
		"Auto-Clear Data for Race-Session": "Daten Autom. löschen: Zum Rennen",
		"Auto-Clear Data on Session-Change": "Daten Autom. löschen: Alle Sessions",
		"Auto-Hide Pit-Times": "Autom. ausblenden Boxen Zeiten",
		"Average Laptime:": "Durchschn. Rundenzeit:",
		"Average Speed:": "Durchschn. Tempo:",
		"Background Blinking": "Hintergrund Blinken",
		"Best Lap": "Beste Runde",
		"Best Laptime:": "Beste Rundenzeit:",
		"Black flag": "Schwarze Flagge",
		"Blue flag 1st warning": "Blaue Flagge, 1. Verwarnung",
		"Blue flag 2nd warning": "Blaue Flagge, 2. Verwarnung",
		"Brake Bias:": "Bremsverhältnis:",
		"Car assists": "Fahrassistenten",
		"Change Logo URL": "Ändern der Logo URL",
		"Checkered flag: Last lap!": "Karierte Flagge: Letzte Runde!",
		"Clear": "Zurücksetzen",
		"Clear Personal Best-Laptime?": "Beste Persönliche Rundenzeit löschen?",
		"Clear all Stored Data": "Daten löschen: Alle",
		"Clear this Combo Stored Data": "Daten löschen: Diese Kombination",
		"Click again to confirm Layout reset": "Erneut klicken zum bestätigen des Layout resets",
		"Click here to change": "Zum ändern hier klicken",
		"Clock": "Uhr",
		"Close": "Schließen",
		"Completed Laps": "Abg. Runden",
		"Crew Chief": "",
		"Current Logo:": "Momentanes Logo:",
		"Current speed": "Akt. Geschwindigkeit",
		"Current version:": "Aktuelle version:",
		"Cutting track": "Abgekürzt",
		"Damage": "Schaden",
		"Delta": "Zeitdifferenz",
		"Delta bars": "Zeitdifferenz als Balken",
		"Delta text": "Zeitdifferenz als Text",
		"Delta to Best-Lap in Race": "Zeitdifferenz Beste Runde im Rennen",
		"Dist:": "Distanz:",
		"Drive Through Penalty": "Durchfahrts Strafe",
		"Driver Change": "Fahrerwechsel",
		"Drivers Ahead/Behind": "Fahrer Voraus/Zurück",
		"Enable WebHud integration": "Enable WebHud integration",
		"Engine": "Motor",
		"Engine Braking:": "Motor-Bremse:",
		"Engine Map:": "Motor-Stufe:",
		"English": "Englisch",
		"Est. Pos": "Vorauss. Position",
		"Est. Time": "Vorauss. Zeit",
		"Est.L. left / Est.L. total": "Voraussichtliche\nRunden rest / Runden total",
		"Estimated Laps left": "Voraussichtliche\nRunden verbleibend",
		"Estimated Laps total": "Voraussichtliche\Runden total",
		"Estimated Laps:": "Vorauss. Runden",
		"Estimated lap time": "Vorauss. Rundenzeit",
		"Estimated position": "Vorauss. Position",
		"Flags": "Flaggen",
		"French": "Französisch",
		"Front tires": "Vorderreifen",
		"Front wing": "Frontflügel",
		"Fuel": "Treibstoff",
		"Fuel & Lap Details": "Treibst. & Rd. Details",
		"Fuel / Lap": "Pro Runde",
		"Fuel Calculator - Right click to close": "Treibstoffrechner - Rechtsklick zum schließen",
		"Fuel Remain": "Verbleibend",
		"Fuel To Add": "Nachtanken",
		"Fuel To End": "Benötigt",
		"Fuel needed": "Benötigt",
		"Fuel/Lap Tracking data for this Combination got deleted!": "Daten für diese Kombination wurden gelöscht",
		"G-Force": "G-Kräfte",
		"German": "Deutsch",
		"Go!": "Los!",
		"Hazard on the track": "Gefahr auf der Strecke",
		"Hide in race": "Im Rennen ausblenden",
		"High Performance Mode": "Hoher Leistungsmodus",
		"IGNITION": "ZÜNDUNG",
		"In Pit Lane": "In Boxengasse",
		"In Progress": "In Bearbeitung",
		"Incidents": "Vorfälle",
		"Inputs": "Eingaben",
		"Italian": "Italienisch",
		"Just-White": "Einfach schlicht",
		"KMH": "",
		"Kph": "",
		"L": "R",
		"Lap": "Runde",
		"Lap time": "Rundenzeit",
		"Laps": "Runden",
		"Laps Estimate": "Reichw. Rd.",
		"Last Lap": "Letzte Runde",
		"Layout reseted!": "Layout zurückgesetzt!",
		"Layout:": "",
		"Length:": "Länge:",
		"Loaded game state from clip board": "Spiel Status aus Zwischenablage geladen",
		"Low Performance Mode": "Niedriger Leistungsmodus",
		"MPH": "",
		"Mandatory\nPit": "Pflicht\nBoxenstopp",
		"Mandatory Pit In Progress": "Pflicht Boxenstopp in Bearbeitung",
		"Minimum Pit time remain": "Mindest Standzeit verbleibend",
		"Minimum Pit time served!": "Mindest Standzeit absolviert",
		"Minutes": "Minuten",
		"Motec": "Motec",
		"NO": "NEIN",
		"NO Data-Feed detected": "KEIN Daten-Strom erkannt",
		"No Radar - Beep only": "Kein Radar - Nur Ton",
		"Next lap will not count": "Nächste Runde ungültig",
		"Next position": "Nächste Platzierung",
		"Normal Performance Mode": "Normaler Leistungsmodus",
		"Only show 5 Ahead/Behind": "Nur 5 Voraus/Zurück anzeigen",
		"Or enable this option in Crew-Chief:": "Oder Aktiviere diese Option in Crew-Chief",
		"OtterHud Start-Parameter set correctly": "OtterHud Start-Parameter korrekt",
		"P2P/DRS": "P2P/DRS",
		"PIT MENU APPEARS HERE": "BOXEN MENÜ ERSCHEINT HIER",
		"PIT-LANE": "BOXENGASSE",
		"Pause UI": "Pausiere UI",
		"Pit Remain": "Box Verbl.",
		"Pit Stop Penalty": "Boxenstopp Strafe",
		"Pit Total": "Box Total",
		"Pit limiter": "Tempobegrenzer",
		"Pit-Entrance": "Boxeneingang",
		"Pit-Spot in": "Boxenplatz in",
		"Pit-Stop requested": "Boxenstopp Beauftragt",
		"Pit-Stop requested - Pit In": "Boxenstopp Beauftragt - An die Box",
		"Pit-Window closes": "Boxengasse schließt",
		"Pit-Window closes in": "Boxengasse schließt in",
		"Pit-Window open for": "Boxengasse offen für",
		"Pit-Window opens": "Boxengasse öffnet",
		"Pit-Window opens in": "Boxengasse öffnet in",
		"Pit-Window\ncloses": "Boxengasse\nschließt",
		"Pit-Window\ncloses in": "Boxengasse\nschließt in",
		"Pit-Window\nopen for": "Boxengasse\noffen für",
		"Pit-Window\nopens": "Boxengasse\nöffnet",
		"Pit-Window\nopens in": "Boxengasse\nöffnet in",
		"PitLimiter Blink": "Tempobegrenzer Blinken",
		"Pitstop": "Boxenstopp",
		"Polish": "Polnisch",
		"Portuguese": "Portugiesisch",
		"Position": "Position",
		"Position Class": "Position Klasse",
		"Position bar": "Positionsleiste",
		"Practice": "Training",
		"Preparing": "Vorbereitung",
		"PRESS CLUTCH / BRAKE / THROTTLE - OR CLICK HERE - TO CLOSE": "Drücke Kupplung / Bremse / Gas - oder klicke hier - zum schließen",
		"Qualification": "Qualifizierung",
		"Qualifying": "Qualifikation",
		"Race": "Rennen",
		"Race info": "Renn Infos",
		"Race start lights": "Startampel",
		"Really delete ALL data?": "Wirklich alle Daten löschen?",
		"Really delete Combination data?": "Wirklich Kombinations Daten löschen?",
		"Rear tires": "Hinterreifen",
		"Rear wing": "Heckflügel",
		"Reason: Accumulating the Maximum Number of Penalties Permitted": "Grund: Maximal erlaubte Strafen überschritten",
		"Reason: Driving the wrong Way": "Grund: Fahren in falscher Richtung",
		"Reason: Driving too slow": "Grund: Zu langsames Fahren",
		"Reason: Entering Pit under Red": "Grund: Einfahrt in die Box unter Rot",
		"Reason: Excessive Cutting of the Track": "Grund: Übertriebenes Abkürzen der Strecke",
		"Reason: Exiting Pits under Red": "Grund: Ausfahrt aus der Box unter Rot",
		"Reason: False Start": "Grund: Frühstart",
		"Reason: Ignoring Blue Flags": "Grund: Ignorieren der Blauen Flaggen",
		"Reason: Ignoring Drive Through Penalty": "Grund: Ignorieren der Durchfahrts Strafe",
		"Reason: Ignoring Mandatory Pit-Stop": "Grund: Ignorieren des Pflicht Boxenstopps",
		"Reason: Ignoring Pit-Stop Penalty": "Grund: Ignorieren der Boxenstopp Strafe",
		"Reason: Ignoring Slow Down Warnings": "Grund: Ignorieren der Langsam fahren Warnung",
		"Reason: Ignoring Stop and Go Penalty": "Grund: Ignorieren der Stop and Go Strafe",
		"Reason: Ignoring Time Penalty": "Grund: Ignorieren der Zeit Strafe",
		"Reason: Ignoring the Driver Change": "Grund: Ignorieren des Pflicht Fahrerwechsels",
		"Reason: Ignoring the minimum Pit-Stop duration": "Grund: Ignorieren der Mindest Standzeit",
		"Reason: Illegally Passing before Green": "Grund: Unerlaubtes Überholen vor Grün",
		"Reason: Illegally Passing before the Finish": "Grund: Unerlaubtes Überholen vor der Ziellinie",
		"Reason: Illegally Passing before the Pit Entrance": "Grund: Unerlaubtes Überholen vor dem Boxen Eingang",
		"Reason: Mandatory Pit-Stop taken outside Pit-Window": "Grund: Pflichtboxenstopp außerhalb des Fensters absolviert",
		"Reason: Multiple Drive Through Penalties in 1 Lap": "Grund: Mehrfache Durchfahrtsstrafen in 1 Runde",
		"Reason: Multiple Track Limit Abuse": "Grund: Wiederholtes Abkürzen der Strecke",
		"Reason: Overtaking under Yellow": "Grund: Überholen unter Gelb",
		"Reason: Speeding in the Pitlane": "Grund: Zu schnell in der Boxengasse",
		"Reason: Track Limits Abuse": "Grund: Verletzung der Streckenbegrenzung",
		"Received Layout-Settings: Saved to Layout": "Layout-Einstellungen Empfangen: Gespeichert als Layout",
		"Received Layout-Settings: [ERROR] Data was corrupt": "Layout-Einstellungen Empfangen: [ERROR] Daten waren Fehlerhaft",
		"Refueling": "Auftanken",
		"Relative": "Relativ",
		"Reset": "",
		"Reset Settings": "Einstellungen Zurücksetzen",
		"Reset values (c)": "Werte zurücksetzen (c)",
		"Restored Layout 1": "Layout 1 Geladen",
		"Restored Layout 2": "Layout 2 Geladen",
		"Restored Layout 3": "Layout 3 Geladen",
		"Restored UI": "UI Wiederhergestellt",
		"Saved Layout-Settings to Clipboard": "Layout-Einstellungen in Zwischenablage kopiert",
		"Saved game state to clip board as JSON": "Spiel-Status als JSON in die Zwischenablage kopiert",
		"Serve": "Absolvieren",
		"Serve within": "Absolvieren innerhalb von",
		"Should beep": "Ton ausgeben",
		"Show Best-Lap": "Zeige Beste Runde",
		"Show Car Names": "Zeige Fahrzeugnamen",
		"Show Changelog / Help": "Zeige Änderungen / Hilfe",
		"Show Class Logos": "Zeige Klassen Logos",
		"Show Completed Laps": "Zeige Absolvierte Runden",
		"Show Corner Names": "Zeige Kurven Namen",
		"Show Current Position": "Zeige Aktuelle Position",
		"Show Drivers UserId": "Zeige Fahrer UserId",
		"Show Electronics": "Zeige Elektronik",
		"Show Estimate Time": "Zeige Voraussichtliche Zeit",
		"Show Estimated Laps": "Zeige Voraussichtliche Runden",
		"Show Estimated Laps left": "Zeige Vorauss. Runden verbleibend",
		"Show Gaps in Seconds": "Zeige Abstände in Sekunden",
		"Show Incident Points": "Zeige anzahl der Vorfälle",
		"Show Inner & Outer": "Zeige Außen & Innen",
		"Show Lap Details": "Zeige Runden Details",
		"Show Lap-Time": "Zeige Rundenzeit",
		"Show Last-Lap": "Zeige Letzte Runde",
		"Show Last-Lap-Times": "Zeige Letzte Rundenzeiten",
		"Show Logo": "Zeige Logo",
		"Show Manufacturer Logos": "Zeige Hersteller Logos",
		"Show Numbers": "Zeige Nummern",
		"Show Pit-Status": "Zeige Box-Status",
		"Show Pit-Time only": "Zeige nur Boxenzeit",
		"Show Pit-Times": "Zeige Boxen Zeiten",
		"Show Pit-Window": "Zeige Boxen Fenster",
		"Show Pit-Window Info": "Zeige Boxen Fenster Info",
		"Show Ranking Data": "Zeige Ranking Daten",
		"Show Sectors as time": "Zeige Sektoren als Zeit",
		"Show Session Info": "Zeige Sitzungs Info",
		"Show Session-Time": "Zeige Sitzungs Zeit",
		"Show Standings": "Zeige Positionierung",
		"Show Stopped Drivers": "Zeige Ausgeschiedene Fahrer",
		"Show Strength of Field": "Zeige Stärke des Felds",
		"Show TC in percent if available": "Zeige TC in prozent wenn verfügbar",
		"Show Tire Est. Laps-Left": "Zeige Vorauss. Runden verbleibend",
		"Show Tire Infos": "Zeige Reifen Informationen",
		"Show Tire-Pressure numbers": "Zeige Reifen Drücke",
		"Show Tire-Temp numbers": "Zeige Reifen Temperaturen",
		"Show Tire-Wear numbers": "Zeige Reifen Abnutzung",
		"Show Tire-Wear per Lap": "Zeige Reifen Abnutzung pro Runde",
		"Show full Driver Grid": "Zeige Gesamtes Fahrerfeld",
		"Show full Lastname": "Zeige Ganze Nachnamen",
		"Show in all Sessions": "Zeige in allen Sitzungen",
		"Show only Own Class": "Zeige nur Eigene Klasse",
		"Slow Down Penalty": "Langsam Fahren Strafe",
		"Slow cars ahead": "Langsame Fahrzeuge voraus",
		"Spanish": "Spanisch",
		"Speed in MPH": "Geschwindigkeit in MPH",
		"Spotting / Radar": "Fahrzeugradar",
		"Steering wheel": "Zeige Lenkrad",
		"Stop And Go Penalty": "Stop und Go Strafe",
		"Strength of Field": "Stärke des Felds",
		"Suspension": "Fahrwerk",
		"TV Tower": "TV Tower",
		"Team Radio": "Boxenfunk",
		"Telemetry": "Telemetrie",
		"This lap will not count": "Runde ungültig",
		"Time Deduction Penalty": "Zeit Strafe",
		"Time Estimate": "Reichw. Zeit",
		"Tire-Pressure in PSI": "Zeige Reifen Druck in PSI",
		"Tire-Temp in Celsius": "Zeige Reifen Temperatur in Celsius",
		"Tires": "Reifen",
		"Track Info": "Strecken Info",
		"Track Name & Details": "Strecken Details",
		"Track:": "Strecke:",
		"Traction Control:": "Traktionskontrolle:",
		"Transmission": "Getriebe",
		"Type to filter keys": "Nach Stichworten filtern",
		"UI IS NOW LOCKED - UNLOCK WITH CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNO FURTHER MESSAGES": "UI IST JETZT GESPERRT - ENTSPERRE MIT CTRL+ALT+SHIFT+L !!!\n\t\t\t\tKEINE WEITEREN MELDUNGEN",
		"UI IS NOW UNLOCKED": "UI IST JETZT ENTSPERRT",
		"UI is paused! Press SHIFT+SPACEBAR to Unpause": "UI ist Pausiert! Drücke SHIFT+LEERTASTE zum fortfahren",
		"Unpause UI": "UI nicht mehr Pausiert",
		"Updates/sec:": "Aktual./sek.:",
		"Version mismatch": "Versions Konflikt",
		"Visit the Forum-Thread to Download": "Besuche den Forums-Beitrag zum Herunterladen",
		"VOL:": "VOL:",
		"Warmup": "Aufwärmen",
		"Warn Front": "Warne voraus",
		"We have no Data yet!": "Wir haben noch keine Daten!",
		"Widgets Grid-Snap Disabled": "Widgets Ausrichtung Deaktiviert",
		"Widgets Grid-Snap Enabled": "Widgets Ausrichtung Aktiviert",
		"Widgets-TEST-Mode": "Widgets-TEST-Modus",
		"Widgets-TEST-Mode Active!\n\t\t\t\tDisable in Settings or press SHIFT+DOWNARROW": "Widgets-TEST-Modus Aktiv!\n\t\t\t\tDeaktivieren in den Einstellungen oder drücke SHIFT+PFEIL_UNTEN",
		"Wrong way": "Falsche Richtung",
		"YES": "JA",
		"Yield to the car behind": "Verfolger passieren lassen",
		"You need to re-download dash.exe": "Du mußt die dash.exe erneut Herunterladen",
		"You need to download/run dash.exe": "Du mußt die dash.exe Herunterladen/Ausführen",
		"Your dash.exe is OUTDATED": "Deine dash.exe ist VERALTET",
		"after this lap": "nach dieser Runde",
		"in 1 minute": "in 1 minute",
		"lap": "Runde",
		"laps": "Runden",
		"minute": "",
		"minutes": "minuten",
		"pL": "pR",
		"this lap": "diese Runde"
	},
	"en": {
		"Aerodynamics": "Aerodynamics",
		"All Fuel/Lap Tracking data got deleted!": "All Fuel/Lap Tracking data got deleted!",
		"Auto Hide": "Auto Hide",
		"Auto-Clear Data for Race-Session": "Auto-Clear Data for Race-Session",
		"Auto-Clear Data on Session-Change": "Auto-Clear Data on Session-Change",
		"Auto-Hide Pit-Times": "Auto-Hide Pit-Times",
		"Average Laptime:": "Average Laptime:",
		"Average Speed:": "Average Speed:",
		"Background Blinking": "Background Blinking",
		"Best Lap": "Best Lap",
		"Best Laptime:": "Best Laptime:",
		"Black flag": "Black flag",
		"Blue flag 1st warning": "Blue flag 1st warning",
		"Blue flag 2nd warning": "Blue flag 2nd warning",
		"Brake Bias:": "Brake Bias:",
		"Car assists": "Car assists",
		"Change Logo URL": "Change Logo URL",
		"Checkered flag: Last lap!": "Checkered flag: Last lap!",
		"Clear": "Clear",
		"Clear Personal Best-Laptime?": "Clear Personal Best-Laptime?",
		"Clear all Stored Data": "Clear all Stored Data",
		"Clear this Combo Stored Data": "Clear this Combo Stored Data",
		"Click again to confirm Layout reset": "Click again to confirm Layout reset",
		"Click here to change": "Click here to change",
		"Clock": "Clock",
		"Close": "Close",
		"Completed Laps": "Completed Laps",
		"Crew Chief": "Crew Chief",
		"Current Logo:": "Current Logo:",
		"Current speed": "Current speed",
		"Current version:": "Current version:",
		"Cutting track": "Cutting track",
		"Damage": "Damage",
		"Delta": "Delta",
		"Delta bars": "Delta bars",
		"Delta text": "Delta text",
		"Delta to Best-Lap in Race": "Delta to Best-Lap in Race",
		"Dist:": "Dist:",
		"Drive Through Penalty": "Drive Through Penalty",
		"Driver Change": "Driver-Change",
		"Drivers Ahead/Behind": "Drivers Ahead/Behind",
		"Enable WebHud integration": "Enable WebHud integration",
		"Engine": "Engine",
		"Engine Braking:": "Engine Braking:",
		"Engine Map:": "Engine Map:",
		"English": "English",
		"Est. Pos": "Est. Pos",
		"Est. Time": "Est. Time",
		"Est.L. left / Est.L. total": "Est.L. left / Est.L. total",
		"Estimated Laps left": "Estimated Laps left",
		"Estimated Laps total": "Estimated Laps total",
		"Estimated Laps:": "Estimated Laps:",
		"Estimated lap time": "Estimated lap time",
		"Estimated position": "Estimated position",
		"Flags": "Flags",
		"French": "French",
		"Front tires": "Front-Tires",
		"Front wing": "Front-Wing",
		"Fuel": "Fuel",
		"Fuel & Lap Details": "Fuel & Lap Details",
		"Fuel / Lap": "Fuel / Lap",
		"Fuel Calculator - Right click to close": "Fuel Calculator - Right click to close",
		"Fuel Remain": "Fuel Remain",
		"Fuel To Add": "Fuel To Add",
		"Fuel To End": "Fuel To End",
		"Fuel needed": "Fuel needed",
		"Fuel/Lap Tracking data for this Combination got deleted!": "Fuel/Lap Tracking data for this Combination got deleted!",
		"G-Force": "G-Force",
		"German": "German",
		"Go!": "Go!",
		"Hazard on the track": "Hazard on the track",
		"Hide in race": "Hide in race",
		"High Performance Mode": "High Performance Mode",
		"IGNITION": "IGNITION",
		"In Pit Lane": "In Pit Lane",
		"In Progress": "In Progress",
		"Incidents": "Incidents",
		"Inputs": "Inputs",
		"Italian": "Italian",
		"Just-White": "Just-White",
		"KMH": "KMH",
		"Kph": "Kph",
		"L": "L",
		"Lap": "Lap",
		"Lap time": "Lap time",
		"Laps": "Laps",
		"Laps Estimate": "Laps Estimate",
		"Last Lap": "Last Lap",
		"Layout reseted!": "Layout reseted!",
		"Layout:": "Layout:",
		"Length:": "Length:",
		"Loaded game state from clip board": "Loaded game state from clip board",
		"Low Performance Mode": "Low Performance Mode",
		"MPH": "MPH",
		"Mandatory\nPit": "Mandatory\nPit",
		"Mandatory Pit In Progress": "Mandatory Pit In Progress",
		"Minimum Pit time remain": "Minimum Pit time remain",
		"Minimum Pit time served!": "Minimum Pit time served!",
		"Minutes": "Minutes",
		"Motec": "Motec",
		"Next lap will not count": "Next lap will not count",
		"NO": "NO",
		"NO Data-Feed detected": "NO Data-Feed detected",
		"No Radar - Beep only": "No Radar - Beep only",
		"Next position": "Next position",
		"Normal Performance Mode": "Normal Performance Mode",
		"Only show 5 Ahead/Behind": "Only show 5 Ahead/Behind",
		"Or enable this option in Crew-Chief:": "Or enable this option in Crew-Chief:",
		"OtterHud Start-Parameter set correctly": "OtterHud Start-Parameter set correctly",
		"P2P/DRS": "P2P/DRS",
		"PIT MENU APPEARS HERE": "PIT MENU APPEARS HERE",
		"PIT-LANE": "PIT-LANE",
		"Pause UI": "Pause UI",
		"Pit Remain": "Pit Remain",
		"Pit Stop Penalty": "Pit Stop Penalty",
		"Pit Total": "Pit Total",
		"Pit limiter": "Pit limiter",
		"Pit-Entrance": "Pit-Entrance",
		"Pit-Spot in": "Pit-Spot in",
		"Pit-Stop requested": "Pit-Stop requested",
		"Pit-Stop requested - Pit In": "Pit-Stop requested - Pit In",
		"Pit-Window closes": "Pit-Window closes",
		"Pit-Window closes in": "Pit-Window closes in",
		"Pit-Window open for": "Pit-Window open for",
		"Pit-Window opens": "Pit-Window opens",
		"Pit-Window opens in": "Pit-Window opens in",
		"Pit-Window\ncloses": "Pit-Window\ncloses",
		"Pit-Window\ncloses in": "Pit-Window\ncloses in",
		"Pit-Window\nopen for": "Pit-Window\nopen for",
		"Pit-Window\nopens": "Pit-Window\nopens",
		"Pit-Window\nopens in": "Pit-Window\nopens in",
		"PitLimiter Blink": "PitLimiter Blink",
		"Pitstop": "Pitstop",
		"Polish": "Polish",
		"Portuguese": "Portuguese",
		"Position": "Position",
		"Position Class": "Position Class",
		"Position bar": "Position bar",
		"Practice": "Practice",
		"Preparing": "Preparing",
		"PRESS CLUTCH / BRAKE / THROTTLE - OR CLICK HERE - TO CLOSE": "PRESS CLUTCH / BRAKE / THROTTLE - OR CLICK HERE - TO CLOSE",
		"Qualification": "Qualification",
		"Qualifying": "Qualifying",
		"Race": "Race",
		"Race info": "Race info",
		"Race start lights": "Race start lights",
		"Really delete ALL data?": "Really delete ALL data?",
		"Really delete Combination data?": "Really delete Combination data?",
		"Rear tires": "Rear-Tires",
		"Rear wing": "Rear-Wing",
		"Reason: Accumulating the Maximum Number of Penalties Permitted": "Reason: Accumulating the Maximum Number of Penalties Permitted",
		"Reason: Driving the wrong Way": "Reason: Driving the wrong Way",
		"Reason: Driving too slow": "Reason: Driving too slow",
		"Reason: Entering Pit under Red": "Reason: Entering Pit under Red",
		"Reason: Excessive Cutting of the Track": "Reason: Excessive Cutting of the Track",
		"Reason: Exiting Pits under Red": "Reason: Exiting Pits under Red",
		"Reason: False Start": "Reason: False Start",
		"Reason: Ignoring Blue Flags": "Reason: Ignoring Blue Flags",
		"Reason: Ignoring Drive Through Penalty": "Reason: Ignoring Drive Through Penalty",
		"Reason: Ignoring Mandatory Pit-Stop": "Reason: Ignoring Mandatory Pit-Stop",
		"Reason: Ignoring Pit-Stop Penalty": "Reason: Ignoring Pit-Stop Penalty",
		"Reason: Ignoring Slow Down Warnings": "Reason: Ignoring Slow Down Warnings",
		"Reason: Ignoring Stop and Go Penalty": "Reason: Ignoring Stop and Go Penalty",
		"Reason: Ignoring Time Penalty": "Reason: Ignoring Time Penalty",
		"Reason: Ignoring the Driver Change": "Reason: Ignoring the Driver Change",
		"Reason: Ignoring the minimum Pit-Stop duration": "Reason: Ignoring the minimum Pit-Stop duration",
		"Reason: Illegally Passing before Green": "Reason: Illegally Passing before Green",
		"Reason: Illegally Passing before the Finish": "Reason: Illegally Passing before the Finish",
		"Reason: Illegally Passing before the Pit Entrance": "Reason: Illegally Passing before the Pit Entrance",
		"Reason: Mandatory Pit-Stop taken outside Pit-Window": "Reason: Mandatory Pit-Stop taken outside Pit-Window",
		"Reason: Multiple Drive Through Penalties in 1 Lap": "Reason: Multiple Drive Through Penalties in 1 Lap",
		"Reason: Multiple Track Limit Abuse": "Reason: Multiple Track Limit Abuse",
		"Reason: Overtaking under Yellow": "Reason: Overtaking under Yellow",
		"Reason: Speeding in the Pitlane": "Reason: Speeding in the Pitlane",
		"Reason: Track Limits Abuse": "Reason: Track Limits Abuse",
		"Received Layout-Settings: Saved to Layout": "Received Layout-Settings: Saved to Layout",
		"Received Layout-Settings: [ERROR] Data was corrupt": "Received Layout-Settings: [ERROR] Data was corrupt",
		"Refueling": "Refueling",
		"Relative": "Relative",
		"Reset": "Reset",
		"Reset Settings": "Reset Settings",
		"Reset values (c)": "Reset values (c)",
		"Restored Layout 1": "Restored Layout 1",
		"Restored Layout 2": "Restored Layout 2",
		"Restored Layout 3": "Restored Layout 3",
		"Restored UI": "Restored UI",
		"Saved Layout-Settings to Clipboard": "Saved Layout-Settings to Clipboard",
		"Saved game state to clip board as JSON": "Saved game state to clip board as JSON",
		"Serve": "Serve",
		"Serve within": "Serve within",
		"Should beep": "Should beep",
		"Show Best-Lap": "Show Best-Lap",
		"Show Car Names": "Show Car Names",
		"Show Changelog / Help": "Show Changelog / Help",
		"Show Class Logos": "Show Class Logos",
		"Show Completed Laps": "Show Completed Laps",
		"Show Corner Names": "Show Corner Names",
		"Show Current Position": "Show Current Position",
		"Show Drivers UserId": "Show Drivers UserId",
		"Show Electronics": "Show Electronics",
		"Show Estimate Time": "Show Estimate Time",
		"Show Estimated Laps": "Show Estimated Laps",
		"Show Estimated Laps left": "Show Estimated Laps left",
		"Show Gaps in Seconds": "Show Gaps in Seconds",
		"Show Incident Points": "Show Incident Points",
		"Show Inner & Outer": "Show Inner & Outer",
		"Show Lap Details": "Show Lap Details",
		"Show Lap-Time": "Show Lap-Time",
		"Show Last-Lap": "Show Last-Lap",
		"Show Last-Lap-Times": "Show Last-Lap-Times",
		"Show Logo": "Show Logo",
		"Show Manufacturer Logos": "Show Manufacturer Logos",
		"Show Numbers": "Show Numbers",
		"Show Pit-Status": "Show Pit-Status",
		"Show Pit-Time only": "Show Pit-Time only",
		"Show Pit-Times": "Show Pit-Times",
		"Show Pit-Window": "Show Pit-Window",
		"Show Pit-Window Info": "Show Pit-Window Info",
		"Show Ranking Data": "Show Ranking Data",
		"Show Sectors as time": "Show Sectors as time",
		"Show Session Info": "Show Session Info",
		"Show Session-Time": "Show Session-Time",
		"Show Standings": "Show Standings",
		"Show Stopped Drivers": "Show Stopped Drivers",
		"Show Strength of Field": "Show Strength of Field",
		"Show TC in percent if available": "Show TC in percent if available",
		"Show Tire Est. Laps-Left": "Show Tire Est. Laps-Left",
		"Show Tire Infos": "Show Tire Infos",
		"Show Tire-Pressure numbers": "Show Tire-Pressure numbers",
		"Show Tire-Temp numbers": "Show Tire-Temp numbers",
		"Show Tire-Wear numbers": "Show Tire-Wear numbers",
		"Show Tire-Wear per Lap": "Show Tire-Wear per Lap",
		"Show full Driver Grid": "Show full Driver Grid",
		"Show full Lastname": "Show full Lastname",
		"Show in all Sessions": "Show in all Sessions",
		"Show only Own Class": "Show only Own Class",
		"Slow Down Penalty": "Slow Down Penalty",
		"Slow cars ahead": "Slow cars ahead",
		"Spanish": "Spanish",
		"Speed in MPH": "Speed in MPH",
		"Spotting / Radar": "Spotting / Radar",
		"Steering wheel": "Steering wheel",
		"Stop And Go Penalty": "Stop And Go Penalty",
		"Strength of Field": "Strength of Field",
		"Suspension": "Suspension",
		"TV Tower": "TV Tower",
		"Team Radio": "Team Radio",
		"Telemetry": "Telemetry",
		"This lap will not count": "This lap will not count",
		"Time Deduction Penalty": "Time Deduction Penalty",
		"Time Estimate": "Time Estimate",
		"Tire-Pressure in PSI": "Tire-Pressure in PSI",
		"Tire-Temp in Celsius": "Tire-Temp in Celsius",
		"Tires": "Tires",
		"Track Info": "Track Info",
		"Track Name & Details": "Track Name & Details",
		"Track:": "Track:",
		"Traction Control:": "Traction Control:",
		"Transmission": "Transmission",
		"Type to filter keys": "Type to filter keys",
		"UI IS NOW LOCKED - UNLOCK WITH CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNO FURTHER MESSAGES": "UI IS NOW LOCKED - UNLOCK WITH CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNO FURTHER MESSAGES",
		"UI IS NOW UNLOCKED": "UI IS NOW UNLOCKED",
		"UI is paused! Press SHIFT+SPACEBAR to Unpause": "UI is paused! Press SHIFT+SPACEBAR to Unpause",
		"Unpause UI": "Unpause UI",
		"Updates/sec:": "Updates/sec:",
		"Version mismatch": "Version mismatch",
		"Visit the Forum-Thread to Download": "Visit the Forum-Thread to Download",
		"VOL:": "VOL:",
		"Warmup": "Warmup",
		"Warn Front": "Warn Front",
		"We have no Data yet!": "We have no Data yet!",
		"Widgets Grid-Snap Disabled": "Widgets Grid-Snap Disabled",
		"Widgets Grid-Snap Enabled": "Widgets Grid-Snap Enabled",
		"Widgets-TEST-Mode": "Widgets-TEST-Mode",
		"Widgets-TEST-Mode Active!\n\t\t\t\tDisable in Settings or press SHIFT+DOWNARROW": "Widgets-TEST-Mode Active!\n\t\t\t\tDisable in Settings or press SHIFT+DOWNARROW",
		"Wrong way": "Wrong way",
		"YES": "YES",
		"Yield to the car behind": "Yield to the car behind",
		"You need to download/run dash.exe": "You need to download/run dash.exe",
		"You need to re-download dash.exe": "You need to re-download dash.exe",
		"Your dash.exe is OUTDATED": "Your dash.exe is OUTDATED",
		"after this lap": "after this lap",
		"in 1 minute": "in 1 minute",
		"lap": "lap",
		"laps": "laps",
		"minute": "minute",
		"minutes": "minutes",
		"pL": "pL",
		"this lap": "this lap"
	},
	"fr": {
		"Aerodynamics": "Aéro",
		"All Fuel/Lap Tracking data got deleted!": "Toutes les données de Conso/Tour ont été effacées!",
		"Auto Hide": "Masquage Automatique",
		"Auto-Clear Data for Race-Session": "Effacement Données: Avant Course",
		"Auto-Clear Data on Session-Change": "Effacement Données: A Chaque Session",
		"Auto-Hide Pit-Times": "Masquage Auto Temps aux Stands",
		"Average Laptime:": "Temps Moyen au Tour:",
		"Average Speed:": "Vitesse Moyenne:",
		"Background Blinking": "Clignotement Arrière-plan",
		"Best Lap": "Meilleur Tour",
		"Best Laptime:": "Meilleur Temps:",
		"Black flag": "Drapeau noir",
		"Blue flag 1st warning": "Drapeau bleu 1er avertissement",
		"Blue flag 2nd warning": "Drapeau bleu 2ème avertissement",
		"Brake Bias:": "Balance des Freins:",
		"Car assists": "Aides à la conduite",
		"Change Logo URL": "Changer URL du Logo",
		"Checkered flag: Last lap!": "Drapeau à damier: Dernier Tour!",
		"Clear": "Effacer",
		"Clear Personal Best-Laptime?": "Effacer Record du Tour Perso?",
		"Clear all Stored Data": "Effacer toutes les Données Locales",
		"Clear this Combo Stored Data": "Effacer les Données Locales de ce Combo",
		"Click again to confirm Layout reset": "Cliquez à nouveau pour restaurer l'affichage par défaut",
		"Click here to change": "Cliquez ici pour changer",
		"Clock": "Horloge",
		"Close": "Fermer",
		"Completed Laps": "Tours Complétés",
		"Crew Chief": "Crew Chief",
		"Current Logo:": "Logo Actuel:",
		"Current speed": "Vitesse Actuelle",
		"Current version:": "Version Installée:",
		"Cutting track": "Hors Limites",
		"Damage": "Dégats",
		"Delta": "Delta",
		"Delta bars": "Barres Delta",
		"Delta text": "Texte Delta",
		"Delta to Best-Lap in Race": "Delta Meilleur Temps en Course",
		"Dist:": "Dist:",
		"Drive Through Penalty": "Pénalité - Passage par les Stands",
		"Driver Change": "Changement\nde Pilote",
		"Drivers Ahead/Behind": "Pilotes Devant/Derrière",
		"Enable WebHud integration": "Activer Integration WebHud",
		"Engine": "Moteur",
		"Engine Braking:": "Frein Moteur:",
		"Engine Map:": "Cartographie Moteur:",
		"English": "Anglais",
		"Est. Pos": "Pos. Est.",
		"Est. Time": "Temps Est.",
		"Est.L. left / Est.L. total": "Est. Nombre de\nTr restant / Tr total",
		"Estimated Laps left": "Nombre de Tours restant Estimé",
		"Estimated Laps total": "Nombre Total de Tours Estimé",
		"Estimated Laps:": "Nombre de Tours Estimé:",
		"Estimated lap time": "Temps au tour Estimé",
		"Estimated position": "Position Estimée",
		"Flags": "Drapeaux",
		"French": "Français",
		"Front tires": "Pneus\navant",
		"Front wing": "Aileron\navant",
		"Fuel": "Carburant",
		"Fuel & Lap Details": "Détails du Carburant & Tour",
		"Fuel / Lap": "Cons. / Tour",
		"Fuel Calculator - Right click to close": "Calculateur de Conso - Clique droit pour fermer",
		"Fuel Remain": "Restant",
		"Fuel To Add": "Ajouter",
		"Fuel To End": "Arrivée",
		"Fuel needed": "Nécessaire",
		"Fuel/Lap Tracking data for this Combination got deleted!": "Données de Consommation pour ce Combo supprimées!",
		"G-Force": "Force G",
		"German": "Allemand",
		"Go!": "Go!",
		"Hazard on the track": "Danger sur la piste",
		"Hide in race": "Masquer en course",
		"High Performance Mode": "Mode Haute Performance",
		"IGNITION": "CONTACT",
		"In Pit Lane": "Dans la Voie des Stands",
		"In Progress": "En Cours",
		"Incidents": "Incidents",
		"Inputs": "Actions (contrôleurs)",
		"Italian": "Italien",
		"Just-White": "Juste-Blanc",
		"KMH": "",
		"Kph": "",
		"L": "T",
		"Lap": "Tour",
		"Lap time": "Temps au Tour",
		"Laps": "Tours",
		"Laps Estimate": "Tours Estimés",
		"Last Lap": "Tour Précédent",
		"Layout reseted!": "Affichage Réinitialisé!",
		"Layout:": "Variant:",
		"Length:": "Long:",
		"Loaded game state from clip board": "Etat du Jeu Chargé depuis presse-papier",
		"Low Performance Mode": "Mode Basse Performance",
		"MPH": "",
		"Mandatory\nPit": "Arrêt\nObligatoire",
		"Mandatory Pit In Progress": "Arrêt Obligatoire En Cours",
		"Minimum Pit time remain": "Temps d'Arrêt Minimal Restant",
		"Minimum Pit time served!": "Temps d'Arrêt Minimal Effectué",
		"Minutes": "Minutes",
		"Motec": "Motec",
		"NO": "NON",
		"NO Data-Feed detected": "Pas de Données détectées",
		"No Radar - Beep only": "Pas de Radar - Alerte uniquement",
		"Next lap will not count": "Prochain tour non comptabilisé",
		"Next position": "Position suivante",
		"Normal Performance Mode": "Mode Normal Performance",
		"Only show 5 Ahead/Behind": "N'afficher que les 5 Devant/Derrière",
		"Or enable this option in Crew-Chief:": "Ou activer cette option dans Crew-Chief:",
		"OtterHud Start-Parameter set correctly": "Paramètres de démarrage du OtterHud Corrects",
		"P2P/DRS": "P2P/DRS",
		"PIT MENU APPEARS HERE": "MENU-ARRETS\nAPPARAIT ICI",
		"PIT-LANE": "VOIE DES STANDS",
		"Pause UI": "Pause Interface",
		"Pit Remain": "Temps d'Arrêt restant",
		"Pit Stop Penalty": "Pénalité - Arrêt aux Stands",
		"Pit Total": "Temps d'Arrêt Total",
		"Pit limiter": "Limiteur de vitesse",
		"Pit-Entrance": "Entrée-Stands",
		"Pit-Spot in": "Emplacement",
		"Pit-Stop requested": "Arrêt aux Stand demandé",
		"Pit-Stop requested - Pit In": "Arrêt aux Stand demandé - Prêt",
		"Pit-Window closes": "Fenêtre-ARRETS fermée",
		"Pit-Window closes in": "Fenêtre-ARRETS ferme dans",
		"Pit-Window open for": "Fenêtre-ARRETS ouverte pendant",
		"Pit-Window opens": "Fenêtre-ARRETS ouverte",
		"Pit-Window opens in": "Fenêtre-ARRETS ouvre dans",
		"Pit-Window\ncloses": "Fenêtre-ARRETS\nFermée",
		"Pit-Window\ncloses in": "Fenêtre-ARRETS\nFerme dans",
		"Pit-Window\nopen for": "Fenêtre-ARRETS\nOuverte pendant",
		"Pit-Window\nopens": "Fenêtre-ARRETS\nOuverte",
		"Pit-Window\nopens in": "Fenêtre-ARRETS\nOuvre dans",
		"PitLimiter Blink": "Limiteur de vitesse",
		"Pitstop": "Arrêt aux Stands",
		"Polish": "Polonais",
		"Portuguese": "Portugais",
		"Position": "Position",
		"Position Class": "Position Classe",
		"Position bar": "Barre de position",
		"Practice": "Essais",
		"Preparing": "Préparation",
		"PRESS CLUTCH / BRAKE / THROTTLE - OR CLICK HERE - TO CLOSE": "Appuyez sur Embrayage/Frein/Accélérateur - ou cliquez ici - Pour fermer",
		"Qualification": "Qualifications",
		"Qualifying": "Qualifications",
		"Race": "Course",
		"Race info": "Infos Course",
		"Race start lights": "Feux de départ",
		"Really delete ALL data?": "Confirmation, supprimer toutes les données?",
		"Really delete Combination data?": "Confirmation, supprimer les données du Combo?",
		"Rear tires": "Pneus\narrière",
		"Rear wing": "Aileron\narrière",
		"Reason: Accumulating the Maximum Number of Penalties Permitted": "Raison: Atteinte du Nombre Maximal de Pénalités Autorisées",
		"Reason: Driving the wrong Way": "Raison: Conduite à Contresens",
		"Reason: Driving too slow": "Raison: Conduite Trop Lente",
		"Reason: Entering Pit under Red": "Raison: Entrée aux stands sous feu rouge",
		"Reason: Excessive Cutting of the Track": "Raison: Dépassements excessifs des limites du circuit",
		"Reason: Exiting Pits under Red": "Raison: Sortie des stands sous feu rouge",
		"Reason: False Start": "Raison: Faux Départ",
		"Reason: Ignoring Blue Flags": "Raison: Ignore les drapeaux bleus",
		"Reason: Ignoring Drive Through Penalty": "Raison: Ignore la pénalité de passage par les Stands",
		"Reason: Ignoring Mandatory Pit-Stop": "Raison: Ignore l'arrêt obligatoire aux stands",
		"Reason: Ignoring Pit-Stop Penalty": "Raison: Ignore la pénalité d'arrêt aux stands",
		"Reason: Ignoring Slow Down Warnings": "Raison: Ignore les alertes de ralentissement",
		"Reason: Ignoring Stop and Go Penalty": "Raison: Ignore la pénalité de Stop & Go",
		"Reason: Ignoring Time Penalty": "Raison: Ignore la pénalité de Temps",
		"Reason: Ignoring the Driver Change": "Raison: Ignore le Changement de Pilote",
		"Reason: Ignoring the minimum Pit-Stop duration": "Raison: Ignore le Temps d'arrêt aux stands minimal",
		"Reason: Illegally Passing before Green": "Raison: Dépassement illégal avant drapeau vert",
		"Reason: Illegally Passing before the Finish": "Raison: Dépassement illégal avant la ligne de départ",
		"Reason: Illegally Passing before the Pit Entrance": "Raison: Dépassement illégal avant la voie des stands",
		"Reason: Mandatory Pit-Stop taken outside Pit-Window": "Raison: Arrêt obligatoire effectué en dehors de la fenêtre autorisée",
		"Reason: Multiple Drive Through Penalties in 1 Lap": "Raison: Multiples pénalités de passage par la voie des stands sur un Tour",
		"Reason: Multiple Track Limit Abuse": "Raison: Dépassement répété des limites du circuit",
		"Reason: Overtaking under Yellow": "Raison: Dépassement sous drapeau jaune",
		"Reason: Speeding in the Pitlane": "Raison: Dépassement de la limite de vitesse dans la voie des stands",
		"Reason: Track Limits Abuse": "Raison: Dépassement des limites du circuit",
		"Received Layout-Settings: Saved to Layout": "Réception des Paramètres d'Affichage: Sauvegardés vers Affichage",
		"Received Layout-Settings: [ERROR] Data was corrupt": "Réception des Paramètres d'Affichage: [ERREUR] Données corrompues",
		"Refueling": "Ravitaillement\nen carburant",
		"Relative": "Relatif",
		"Reset": "Réinit.",
		"Reset Settings": "Réinitialisation des Paramètres",
		"Reset values (c)": "Réinitialisation des valeurs (c)",
		"Restored Layout 1": "Affichage 1 Restauré",
		"Restored Layout 2": "Affichage 2 Restauré",
		"Restored Layout 3": "Affichage 3 Restauré",
		"Restored UI": "Interface Restaurée",
		"Saved Layout-Settings to Clipboard": "Paramètres de l'Affichage Sauvegardés vers presse-papier",
		"Saved game state to clip board as JSON": "Etat du Jeu Sauvegardé vers presse-papier en JSON",
		"Serve": "Effectuer",
		"Serve within": "Effectuer sous",
		"Should beep": "Émet un bip",
		"Show Best-Lap": "Afficher Meilleur Tour",
		"Show Car Names": "Afficher Nom des Voitures",
		"Show Changelog / Help": "Changements de Version / Aide",
		"Show Class Logos": "Afficher Logos des Classes de Voitures",
		"Show Completed Laps": "Afficher Nombre de Tours Complétés",
		"Show Corner Names": "Afficher le Nom des Virages",
		"Show Current Position": "Afficher Ma Position",
		"Show Drivers UserId": "Afficher Id des Pilotes",
		"Show Electronics": "Afficher Aides",
		"Show Estimate Time": "Afficher Temps Estimés",
		"Show Estimated Laps": "Afficher Nombre de Tours Estimés",
		"Show Estimated Laps left": "Afficher Nombre de Tours restant Estimé",
		"Show Gaps in Seconds": "Afficher Ecart en Secondes",
		"Show Incident Points": "Afficher Compteur d'Incidents",
		"Show Inner & Outer": "Afficher Températures Int. et Ext.",
		"Show Lap Details": "Afficher Détails au Tour",
		"Show Lap-Time": "Afficher Temps au Tour",
		"Show Last-Lap": "Afficher Tour Précédent",
		"Show Last-Lap-Times": "Afficher Temps du Tour Précédent",
		"Show Logo": "Afficher Logo",
		"Show Manufacturer Logos": "Afficher Logos Constructeur",
		"Show Numbers": "Afficher Numéros",
		"Show Pit-Status": "Afficher Statut Voie des Stands",
		"Show Pit-Time only": "Afficher le PIT-Temps uniquement",
		"Show Pit-Times": "Afficher les PIT-Temps",
		"Show Pit-Window": "Afficher PIT-Fenêtre",
		"Show Pit-Window Info": "Afficher Infos PIT-Fenêtre",
		"Show Ranking Data": "Afficher Données Ranking",
		"Show Sectors as time": "Afficher les Secteurs en Temps",
		"Show Session Info": "Afficher les Infos de Session",
		"Show Session-Time": "Afficher le Compte à rebours de Session",
		"Show Standings": "Afficher les Classements",
		"Show Stopped Drivers": "Afficher les Pilotes Arrêtés aux Stands",
		"Show Strength of Field": "Afficher le Niveau Moy. Pilotes",
		"Show TC in percent if available": "Afficher TC en pourcents si disponible",
		"Show Tire Est. Laps-Left": "Afficher Est. Tours restant des Pneus",
		"Show Tire Infos": "Afficher les Infos des Pneus",
		"Show Tire-Pressure numbers": "Afficher Pressions des Pneus",
		"Show Tire-Temp numbers": "Afficher Températures des Pneus",
		"Show Tire-Wear numbers": "Afficher Usure des Pneus",
		"Show Tire-Wear per Lap": "Afficher Usure des Pneus par Tour",
		"Show full Driver Grid": "Afficher la liste complète des Pilotes",
		"Show full Lastname": "Afficher Noms de famille complets",
		"Show in all Sessions": "Afficher dans toutes les Sessions",
		"Show only Own Class": "Afficher uniquement ma Classe",
		"Slow Down Penalty": "Pénalité - Ralentissement",
		"Slow cars ahead": "Véhicules lents devant",
		"Spanish": "Espagnol",
		"Speed in MPH": "Vitesse en Miles/Heure",
		"Spotting / Radar": "Radar de proximité",
		"Steering wheel": "Volant",
		"Stop And Go Penalty": "Pénalité - Stop & Go",
		"Strength of Field": "Niveau Moy. Pilotes",
		"Suspension": "Suspension",
		"TV Tower": "Classement TV",
		"Team Radio": "Radio Equipe",
		"Telemetry": "Télémétrie",
		"This lap will not count": "Ce tour ne comptera pas",
		"Time Deduction Penalty": "Pénalité - de Temps",
		"Time Estimate": "Est. du Temps",
		"Tire-Pressure in PSI": "Pressions des Pneus en PSI",
		"Tire-Temp in Celsius": "Pression des Pneus en Celcius",
		"Tires": "Pneus",
		"Track Info": "Infos Circuit",
		"Track Name & Details": "Nom du Circuit & Détails",
		"Track:": "Circuit:",
		"Traction Control:": "Contrôle de Traction:",
		"Transmission": "Transmission",
		"Type to filter keys": "Saisissez Texte pour filtrer les touches",
		"UI IS NOW LOCKED - UNLOCK WITH CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNO FURTHER MESSAGES": "UI VERROUILLÉ - DÉVEROUILLER AVEC CTRL+ALT+MAJ+L !!!\n\t\t\t\tPLUS DE MESSAGES",
		"UI IS NOW UNLOCKED": "UI DÉVERROUILLÉ",
		"UI is paused! Press SHIFT+SPACEBAR to Unpause": "Interface en Pause! Faites MAJ+ESPACE pour Reprendre",
		"Unpause UI": "Reprendre Interface",
		"Updates/sec:": "Rafraîchi./sec:",
		"Version mismatch": "Incohérence de Versions",
		"Visit the Forum-Thread to Download": "Visitez le Sujet sur le Forum pour Télécharger",
		"VOL:": "VOL:",
		"Warmup": "Tour de Chauffe",
		"Warn Front": "Avertissement Devant",
		"We have no Data yet!": "Pas de Données disponibles!",
		"Widgets Grid-Snap Disabled": "Accrochage-Widgets Désactivé",
		"Widgets Grid-Snap Enabled": "Accrochage-Widgets Activé",
		"Widgets-TEST-Mode": "Mode-TEST-Widgets",
		"Widgets-TEST-Mode Active!\n\t\t\t\tDisable in Settings or press SHIFT+DOWNARROW": "Mode-TEST-Widgets Actif!\n\t\t\t\tDésactivable dans Paramètres ou faites MAJ+FLECHEBAS",
		"Wrong way": "Sens Inverse",
		"YES": "OUI",
		"Yield to the car behind": "Laissez passer la voiture derrière vous",
		"You need to re-download dash.exe": "Vous devez re-télécharger dash.exe",
		"You need to download/run dash.exe": "Vous devez télécharger/exécuter dash.exe",
		"Your dash.exe is OUTDATED": "Votre version de dash.exe n'est PAS A JOUR",
		"after this lap": "après ce Tour",
		"in 1 minute": "dans 1 minute",
		"lap": "tour",
		"laps": "tours",
		"minute": "minute",
		"minutes": "minutes",
		"pL": "pT",
		"this lap": "ce tour"
	},
	"pt": {
		"Aerodynamics": "Aerodinâmica",
		"All Fuel/Lap Tracking data got deleted!": "Combustível total/Dados de acompanhamento da volta apagados!",
		"Auto Hide": "Esconder automaticamente",
		"Auto-Clear Data for Race-Session": "Apagar dados para sessão de corrida",
		"Auto-Clear Data on Session-Change": "Apagar dados na mudança de sessão",
		"Auto-Hide Pit-Times": "Esconder tempo de pit automaticamente",
		"Average Laptime:": "Tempo médio:",
		"Average Speed:": "Velocidade média:",
		"Background Blinking": "Fundo piscante",
		"Best Lap": "Melhor volta",
		"Best Laptime:": "Melhor tempo de volta:",
		"Black flag": "Bandeira preta",
		"Blue flag 1st warning": "1º. aviso de bandeira azul",
		"Blue flag 2nd warning": "2º. aviso de bandeira azul",
		"Brake Bias:": "Polarização do freio",
		"Car assists": "Assistencias do carro",
		"Change Logo URL": "URL de mudança do logotipo",
		"Checkered flag: Last lap!": "Bandeira quadriculada: Última volta",
		"Clear": "Limpar",
		"Clear Personal Best-Laptime?": "Limpar melhor tempo de volta pessoal?",
		"Clear all Stored Data": "Limpar todos os dados armazenados",
		"Clear this Combo Stored Data": "Limpar dados armazenados deste combo",
		"Click again to confirm Layout reset": "Clique novamente para confirmar o reset",
		"Click here to change": "CLique aqui para mudar",
		"Clock": "Relógio",
		"Close": "Fechar",
		"Completed Laps": "Voltas completas",
		"Crew Chief": "Crew Chief",
		"Current Logo:": "Logotipo atual:",
		"Current speed": "Velocidade atual",
		"Current version:": "Versão atual:",
		"Cutting track": "Cortou a pista",
		"Damage": "Dano",
		"Delta": "Delta",
		"Delta bars": "Barras do delta",
		"Delta text": "Texto do delta",
		"Delta to Best-Lap in Race": "Delta para a melhor volta da corrida",
		"Dist:": "Dist:",
		"Drive Through Penalty": "Penalidade de Drive Through",
		"Driver Change": "Troca\nde Piloto",
		"Drivers Ahead/Behind": "Pilotos a frente/atrás",
		"Enable WebHud integration": "Ativar integração com o Webhud",
		"Engine": "Motor",
		"Engine Braking:": "Trava de motor:",
		"Engine Map:": "Mapa do motor:",
		"English": "Inglês",
		"Est. Pos": "Pos. Est.",
		"Est. Time": "Tempo Est.",
		"Est.L. left / Est.L. total": "Estimado\nVolta rest. / Volta totais",
		"Estimated Laps left": "Voltas estimadas restantes",
		"Estimated Laps total": "Voltas estimadas totais",
		"Estimated Laps:": "Voltas estimadas:",
		"Estimated lap time": "Tempo de volta estimado",
		"Estimated position": "Posição estimada",
		"Flags": "Bandeiras",
		"French": "Francês",
		"Front tires": "Pneus\ndianteiros",
		"Front wing": "Asa\ndianteira",
		"Fuel": "Combustível",
		"Fuel & Lap Details": "Det. de Comb./Volta",
		"Fuel / Lap": "Comb. / Volta",
		"Fuel Calculator - Right click to close": "Calculadora de Combustivel - botão direito para fechar",
		"Fuel Remain": "Comb. rest.",
		"Fuel To Add": "Comb. adicionar",
		"Fuel To End": "Comb. final",
		"Fuel needed": "Comb. necess.",
		"Fuel/Lap Tracking data for this Combination got deleted!": "Dados de acompanhamento desta combinação Combustível/Volta foram apagados",
		"G-Force": "Força G",
		"German": "Alemão",
		"Go!": "Vai!",
		"Hazard on the track": "Perigo na pista",
		"Hide in race": "Ocultar na corrida",
		"High Performance Mode": "Modo alto de Performance",
		"IGNITION": "Ignição",
		"In Pit Lane": "Linha do pit",
		"In Progress": "Em progresso",
		"Incidents": "Incidentes",
		"Inputs": "Entrada",
		"Italian": "Italiano",
		"Just-White": "Apenas branco",
		"KMH": "",
		"Kph": "",
		"L": "V",
		"Lap": "Volta",
		"Lap time": "Tempo de volta",
		"Laps": "Voltas",
		"Laps Estimate": "Est. de voltas",
		"Last Lap": "Última volta",
		"Layout reseted!": "Layout reiniciado!",
		"Layout:": "Layout:",
		"Length:": "Compr.:",
		"Loaded game state from clip board": "Estado do jogo carregado do clipboard",
		"Low Performance Mode": "Modo baixo de Performance",
		"MPH": "",
		"Mandatory\nPit": "Pit-stop\nobrigatório",
		"Mandatory Pit In Progress": "Pit-stop obrigatório em progresso",
		"Minimum Pit time remain": "Tempo mínimo restante de pit",
		"Minimum Pit time served!": "Tempo mínimo de pit servido!",
		"Minutes": "Minutos",
		"Motec": "Motec",
		"Next lap will not count": "A próxima volta não contará",
		"NO": "NÃO",
		"NO Data-Feed detected": "Nenhum feed de dados encontrado",
		"No Radar - Beep only": "Sem radar - apenas o bip",
		"Next position": "Próxima Posição",
		"Normal Performance Mode": "Modo normal de Performance",
		"Only show 5 Ahead/Behind": "Mostrar apenas 5 a frente/atrás",
		"Or enable this option in Crew-Chief:": "Ou ativar esta opção no CrewChief:",
		"OtterHud Start-Parameter set correctly": "Parâmetro de inicialização setado corretamente no Otterhud",
		"P2P/DRS": "P2P/DRS",
		"PIT MENU APPEARS HERE": "PIT MENU APARECE AQUI",
		"PIT-LANE": "LINHA DO PIT",
		"Pause UI": "Pausar UI",
		"Pit Remain": "Permanecer no Pit",
		"Pit Stop Penalty": "Penalidade de Pit Stop",
		"Pit Total": "Total no Pit",
		"Pit limiter": "Limitador no Pit",
		"Pit-Entrance": "Pit-Entrada",
		"Pit-Spot in": "Boxes-Área em",
		"Pit-Stop requested": "Pit-Stop solicitado",
		"Pit-Stop requested - Pit In": "Pit-Stop solicitado - Entrada no Pit",
		"Pit-Window closes": "Janela do Pit fecha",
		"Pit-Window closes in": "Janela do Pit fecha em",
		"Pit-Window open for": "Janela do Pit abre para",
		"Pit-Window opens": "Janela do Pit abre",
		"Pit-Window opens in": "Janela do Pit abre em",
		"Pit-Window\ncloses": "Janela do Pit\nfecha",
		"Pit-Window\ncloses in": "Janela do Pit\nfecha em",
		"Pit-Window\nopen for": "Janela do Pit\nabre para",
		"Pit-Window\nopens": "Janela do Pit\nabre",
		"Pit-Window\nopens in": "Janela do Pit\nabre em",
		"PitLimiter Blink": "Limitador do Pit Piscante",
		"Pitstop": "Parada",
		"Polish": "Polonês",
		"Portuguese": "Português",
		"Position": "Posição",
		"Position Class": "Posição na Classe",
		"Position bar": "Barra de Posição",
		"Practice": "Praticar",
		"Preparing": "Preparo",
		"PRESS CLUTCH / BRAKE / THROTTLE - OR CLICK HERE - TO CLOSE": "Pressione embreagem / Freio / Acelerador - ou clique aqui - para fechar",
		"Qualification": "Qualificação",
		"Qualifying": "Qualificação",
		"Race": "Corrida",
		"Race info": "Inf. de Corrida",
		"Race start lights": "Luzes de largada",
		"Really delete ALL data?": "Deseja apagar TODOS os dados?",
		"Really delete Combination data?": "Deseja apagar dados de combinação?",
		"Rear tires": "Pneus\ntraseiros",
		"Rear wing": "Asa\ntraseira",
		"Reason: Accumulating the Maximum Number of Penalties Permitted": "Motivo: Acumulou o numero máximo de penalidades permitidas",
		"Reason: Driving the wrong Way": "Motivo: Dirigindo no sentido errado",
		"Reason: Driving too slow": "Motivo: Dirigindo devagar demais",
		"Reason: Entering Pit under Red": "Motivo: entrando no Pit na luz vermelha",
		"Reason: Excessive Cutting of the Track": "Motivo: Corte excessivo da pista",
		"Reason: Exiting Pits under Red": "Motivo: Saindo do Pit na luz vermelha",
		"Reason: False Start": "Motivo: Queimou a largada",
		"Reason: Ignoring Blue Flags": "Motivo: Ignorando bandeiras azuis",
		"Reason: Ignoring Drive Through Penalty": "Motivo: Ignorou penalidade de Drive Through",
		"Reason: Ignoring Mandatory Pit-Stop": "Motivo: Ignorou Pit-Stop obrigatório",
		"Reason: Ignoring Pit-Stop Penalty": "Motivo: Ignorou penalidade de Pit-Stop",
		"Reason: Ignoring Slow Down Warnings": "Motivo: Ignorou avisos de redução de velocidade",
		"Reason: Ignoring Stop and Go Penalty": "Motivo: Ignorou penalidade Stop and Go",
		"Reason: Ignoring Time Penalty": "Motivo: Ignorou penalidade de tempo",
		"Reason: Ignoring the Driver Change": "Motivo: Ignorou mudança de piloto",
		"Reason: Ignoring the minimum Pit-Stop duration": "Motivo: Ignorou a duração mínima de Pit-Stop",
		"Reason: Illegally Passing before Green": "Motivo: Ultrapassou ilegalmente antes da bandeira verde",
		"Reason: Illegally Passing before the Finish": "Motivo: Ultrapassou ilegalmente antes do fim",
		"Reason: Illegally Passing before the Pit Entrance": "Motivo: Ultrapassou ilegalmente antes da linha do Pit",
		"Reason: Mandatory Pit-Stop taken outside Pit-Window": "Motivo: Pit-Stop obrigatório feito fora da Janela do Pit",
		"Reason: Multiple Drive Through Penalties in 1 Lap": "Motivo: Multiplas penalidades de Drive Through em 1 volta",
		"Reason: Multiple Track Limit Abuse": "Motivo: Multiplos abusos do limite da pista",
		"Reason: Overtaking under Yellow": "Motivo: Ultrapassou durante a bandeira amarela",
		"Reason: Speeding in the Pitlane": "Motivo: Excesso de velocidade no Pit",
		"Reason: Track Limits Abuse": "Motivo: Abuso dos limites da pista",
		"Received Layout-Settings: Saved to Layout": "Configurações de layout recebidas: Salvo para o layout",
		"Received Layout-Settings: [ERROR] Data was corrupt": "Configurações de layout recebidas: [ERRO] Dados corrompidos",
		"Refueling": "Reabastecendo",
		"Relative": "Relativo",
		"Reset": "Reiniciar",
		"Reset Settings": "Reiniciar configurações",
		"Reset values (c)": "Reiniciar valores (c)",
		"Restored Layout 1": "Layout restaurado 1",
		"Restored Layout 2": "Layout restaurado 2",
		"Restored Layout 3": "Layout restaurado 3",
		"Restored UI": "UI Restaurada",
		"Saved Layout-Settings to Clipboard": "Configurações de layout salvas para o clipboard",
		"Saved game state to clip board as JSON": "Salvar estado do jogo para o clipboard como JSON",
		"Serve": "Servir",
		"Serve within": "Servir em ",
		"Should beep": "Deve bipar",
		"Show Best-Lap": "Mostrar melhor volta",
		"Show Car Names": "Mostrar nome dos carros",
		"Show Changelog / Help": "Mostrar log de mudanças / ajuda",
		"Show Class Logos": "Mostrar Logos de Classe",
		"Show Completed Laps": "Mostrar voltas completadas",
		"Show Corner Names": "Mostrar nome das curvas",
		"Show Current Position": "Mostrar posição atual",
		"Show Drivers UserId": "Mostrar Id do Piloto",
		"Show Electronics": "Mostrar eletrônicos",
		"Show Estimate Time": "Mostrar tempo estimado",
		"Show Estimated Laps": "Mostrar voltas estimadas",
		"Show Estimated Laps left": "Mostrar voltas estimadas restantes",
		"Show Gaps in Seconds": "Mostrar diferenças em segundos",
		"Show Incident Points": "Mostrar pontos de incidente",
		"Show Inner & Outer": "Mostrar interior e exterior",
		"Show Lap Details": "Mostrar detalhes da volta",
		"Show Lap-Time": "Mostrar tempo de volta",
		"Show Last-Lap": "Mostrar última volta",
		"Show Last-Lap-Times": "Mostrar tempos das últimas voltas",
		"Show Logo": "Mostrar Logotipo",
		"Show Manufacturer Logos": "Mostrar Logotipos do Fabricantes",
		"Show Numbers": "Mostrar números",
		"Show Pit-Status": "Mostrar status do Pit",
		"Show Pit-Time only": "Mostrar apenas tempo no Pit",
		"Show Pit-Times": "Mostrar tempos no Pit",
		"Show Pit-Window": "Mostrar da Janela do Pit",
		"Show Pit-Window Info": "Mostrar inform. da Janela do Pit",
		"Show Ranking Data": "Mostrar dados do Ranking",
		"Show Sectors as time": "Mostrar setores como tempo",
		"Show Session Info": "Mostrar Inform. da Sessão",
		"Show Session-Time": "Mostrar tempo da Sessão",
		"Show Standings": "Mostrar Classificação",
		"Show Stopped Drivers": "Mostrar pilotos parados",
		"Show Strength of Field": "Mostrar SoF",
		"Show TC in percent if available": "Mostrar TC em porcentual se disponível",
		"Show Tire Est. Laps-Left": "Mostrar voltas estim. restantes",
		"Show Tire Infos": "Mostrar inform. dos pneus",
		"Show Tire-Pressure numbers": "Mostrar números de Pressão",
		"Show Tire-Temp numbers": "Mostrar números da temperatura",
		"Show Tire-Wear numbers": "Mostrar números do desgaste dos pneus",
		"Show Tire-Wear per Lap": "Mostrar desgaste dos pneus por volta",
		"Show full Driver Grid": "Mostrar grid dos pilotos completo",
		"Show full Lastname": "Mostrar último nome completo",
		"Show in all Sessions": "Mostrar em todas as sessões",
		"Show only Own Class": "Mostrar apenas na própria classe",
		"Slow Down Penalty": "Penalidade de Slow Dowm",
		"Slow cars ahead": "Veiculo lento a frente",
		"Spanish": "Espanhol",
		"Speed in MPH": "Velocidade em MPH",
		"Spotting / Radar": "Radar de veículos",
		"Steering wheel": "Volante",
		"Stop And Go Penalty": "Penalidade Stop and Go",
		"Strength of Field": "SoF",
		"Suspension": "Suspensão",
		"TV Tower": "Torre de TV",
		"Team Radio": "Caixa-Radio",
		"Telemetry": "Telemetria",
		"This lap will not count": "Essa volta não contará",
		"Time Deduction Penalty": "Penalidade de dedução de tempo",
		"Time Estimate": "Tempo Est.",
		"Tire-Pressure in PSI": "Pressão dos pneus em PSI",
		"Tire-Temp in Celsius": "Temperatura dos pneus em Celsius",
		"Tires": "Pneus",
		"Track Info": "Inform. da Pista",
		"Track Name & Details": "Nome da pista e detalhes",
		"Track:": "Pista:",
		"Traction Control:": "Controle de Tração:",
		"Transmission": "Transmissão",
		"Type to filter keys": "Digite para filtrar chaves",
		"UI IS NOW LOCKED - UNLOCK WITH CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNO FURTHER MESSAGES": "AGORA A UI ESTÁ TRAVADA - DESTRAVE COM CTRL+ALT+SHIFT+L !!!\n\t\t\t\tSEM MAIS MENSAGENS",
		"UI IS NOW UNLOCKED": "AGORA A UI ESTÁ DESTRAVADA",
		"UI is paused! Press SHIFT+SPACEBAR to Unpause": "UI está pausada! Pressione SHIFT+BBARRA_DE_ESPAÇO para retomar",
		"Unpause UI": "Retomar UI",
		"Updates/sec:": "Atual./Seg.:",
		"Version mismatch": "Versão incompatível",
		"Visit the Forum-Thread to Download": "Visite a thread do fórum para baixar",
		"VOL:": "VOL:",
		"Warmup": "Aquecimento",
		"Warn Front": "Alertar Veículo a frente",
		"We have no Data yet!": "Ainda não temos dados!",
		"Widgets Grid-Snap Disabled": "Grid-Snap Desativadas",
		"Widgets Grid-Snap Enabled": "Grid-Snap Ativadas",
		"Widgets-TEST-Mode": "Modo-Teste-Ferramentas",
		"Widgets-TEST-Mode Active!\n\t\t\t\tDisable in Settings or press SHIFT+DOWNARROW": "Modo-Teste-Ferramentas Ativo!\n\t\t\t\tDesative em Configurações ou pressione SHIFT+SETA_PARA_BAIXO",
		"Wrong way": "Sentido contrário",
		"YES": "SIM",
		"Yield to the car behind": "Ceda passagem para o carro de trás",
		"You need to re-download dash.exe": "Você precisa baixar novamente o dash.exe",
		"You need to download/run dash.exe": "Você precisa baixar/executar dash.exe",
		"Your dash.exe is OUTDATED": "Seu dash.exe está desatualizado",
		"after this lap": "após essa volta",
		"in 1 minute": "em 1 minuto",
		"lap": "volta",
		"laps": "voltas",
		"minute": "minuto",
		"minutes": "minutos",
		"pL": "pV",
		"this lap": "esta volta"
	},
	"it": {
		"Aerodynamics": "Aerodinamica",
		"All Fuel/Lap Tracking data got deleted!": "Tutti i dati consumo/giro sono stati eliminati!",
		"Auto Hide": "Nascondi autom.",
		"Auto-Clear Data for Race-Session": "Autoelimina dati delle Gare",
		"Auto-Clear Data on Session-Change": "Autoelimina dati al cambio sessione",
		"Auto-Hide Pit-Times": "Nascondi autom. i tempi Pit",
		"Average Laptime:": "Tempo medio:",
		"Average Speed:": "Velocitá media:",
		"Background Blinking": "Sfondo lampeggiante",
		"Best Lap": "Giro migliore",
		"Best Laptime:": "Tempo migliore:",
		"Black flag": "Bandiera Nera",
		"Blue flag 1st warning": "Bandiera Blu 1 avviso",
		"Blue flag 2nd warning": "Bandiera Blu 2 avviso",
		"Brake Bias:": "Bilanciamento freni:",
		"Car assists": "Aiuti",
		"Change Logo URL": "Cambia URL logo",
		"Checkered flag: Last lap!": "Bandiara a scacchi: ultimo giro!",
		"Clear": "Cancella",
		"Clear Personal Best-Laptime?": "Cancellare i giri migliori personali?",
		"Clear all Stored Data": "Cancella tutti i dati salvati",
		"Clear this Combo Stored Data": "Cancella i dati per questa Combo",
		"Click again to confirm Layout reset": "Conferma il reset del Layout",
		"Click here to change": "Clicca qui per cambiare",
		"Clock": "Orologio",
		"Close": "Chiudi",
		"Completed Laps": "Giri completati",
		"Crew Chief": "Crew Chief",
		"Current Logo:": "Logo attuale:",
		"Current speed": "Velocitá attuale",
		"Current version:": "Versione attuale:",
		"Cutting track": "Taglio pista",
		"Damage": "Danni",
		"Delta": "Delta",
		"Delta bars": "Indicatori delta",
		"Delta text": "Testo delta",
		"Delta to Best-Lap in Race": "Delta con miglior giro in gara",
		"Dist:": "Dist:",
		"Drive Through Penalty": "Penalitá Drive Through",
		"Driver Change": "Cambio pilota",
		"Drivers Ahead/Behind": "Piloti davanti/dietro",
		"Enable WebHud integration": "Abilita integrazione WebHud",
		"Engine": "Motore",
		"Engine Braking:": "Freno motore:",
		"Engine Map:": "Mappa motore:",
		"English": "Inglese",
		"Est. Pos": "Pos. stim.",
		"Est. Time": "Tempo stim.",
		"Est.L. left / Est.L. total": "Stima\nGiri Rim. / Giri Tot.",
		"Estimated Laps left": "Giri rimasti stimati",
		"Estimated Laps total": "Giri totali stimati",
		"Estimated Laps:": "Giri stimati:",
		"Estimated lap time": "Tempo stimato",
		"Estimated position": "Posizione stimata",
		"Flags": "Bandiere",
		"French": "Francese",
		"Front tires": "Gomme ant.",
		"Front wing": "Ala ant.",
		"Fuel": "Consumo",
		"Fuel & Lap Details": "Dettagli giro e carb.",
		"Fuel / Lap": "Carb. / Giro",
		"Fuel Calculator - Right click to close": "Calcolatore carburante - click dx per chiudere",
		"Fuel Remain": "Carb. rim.",
		"Fuel To Add": "Carb. da agg.",
		"Fuel To End": "Carb. tot.",
		"Fuel needed": "Carb. necess.",
		"Fuel/Lap Tracking data for this Combination got deleted!": "I dati Carburante/Giro per questa combinazione sono stati eliminati!",
		"G-Force": "Forza G",
		"German": "Tedesco",
		"Go!": "Vai!",
		"Hazard on the track": "Pericolo in pista",
		"Hide in race": "Nascondi in gara",
		"High Performance Mode": "Modalitá alte prestazioni",
		"IGNITION": "AVVIAMENTO",
		"In Pit Lane": "Limite Pit",
		"In Progress": "In corso",
		"Incidents": "Incidenti",
		"Inputs": "Inputs",
		"Italian": "Italiano",
		"Just-White": "Solo traccia",
		"KMH": "KMH",
		"Kph": "Kph",
		"L": "G",
		"Lap": "Giro",
		"Lap time": "Tempo sul giro",
		"Laps": "Giri",
		"Laps Estimate": "Giri stimati",
		"Last Lap": "Ultimo giro",
		"Layout reseted!": "Layout reimpostato!",
		"Layout:": "Layout:",
		"Length:": "Lungh.:",
		"Loaded game state from clip board": "Stato del gioco caricato dagli appunti",
		"Low Performance Mode": "Modalitá basse prestazioni",
		"MPH": "MPH",
		"Mandatory\nPit": "Pit\nobbligatorio",
		"Mandatory Pit In Progress": "Pit obbligatorio in corso",
		"Minimum Pit time remain": "Tempo minimo pit rimanente",
		"Minimum Pit time served!": "Tempo minimo pit trascorso!",
		"Minutes": "Minuti",
		"Motec": "Motec",
		"Next lap will not count": "Prossimo giro non valido",
		"NO": "NO",
		"NO Data-Feed detected": "Feed dati non rilevato",
		"No Radar - Beep only": "Nessun radar - Solo avviso",
		"Next position": "Prossima posizione",
		"Normal Performance Mode": "Modalitá prestazioni normali",
		"Only show 5 Ahead/Behind": "Mostra solo 5 davanti/dietro",
		"Or enable this option in Crew-Chief:": "Oppure abilita questa opzione in Crew-Chief:",
		"OtterHud Start-Parameter set correctly": "Parametro avvio di OtterHud impostato correttamente",
		"P2P/DRS": "P2P/DRS",
		"PIT MENU APPEARS HERE": "IL MENÚ PIT\nAPPARE QUI",
		"PIT-LANE": "PIT-LANE",
		"Pause UI": "UI in pausa",
		"Pit Remain": "Pit restanti",
		"Pit Stop Penalty": "Penalitá Pit Stop",
		"Pit Total": "Totale Pit",
		"Pit limiter": "Limitatore Pit",
		"Pit-Entrance": "",
		"Pit-Spot in": "",
		"Pit-Stop requested": "Pit-Stop richiesto",
		"Pit-Stop requested - Pit In": "Pit-Stop richiesto - entra ai pit",
		"Pit-Window closes": "Finestra Pit in chiusura",
		"Pit-Window closes in": "Finestra Pit chiusa in",
		"Pit-Window open for": "Finestra Pit aperta per",
		"Pit-Window opens": "Finestra Pit in apertura",
		"Pit-Window opens in": "Finestra Pit aperta tra",
		"Pit-Window\ncloses": "Finestra Pit\nin chiusura",
		"Pit-Window\ncloses in": "Finestra Pit\nchiusa in",
		"Pit-Window\nopen for": "Finestra Pit\naperta per",
		"Pit-Window\nopens": "Finestra Pit\nin apertura",
		"Pit-Window\nopens in": "Finestra Pit\napre tra",
		"PitLimiter Blink": "Lampeggio Limitatore Pit",
		"Pitstop": "Pitstop",
		"Polish": "Polacco",
		"Portuguese": "Portoghese",
		"Position": "Posizione",
		"Position Class": "Pos. di Classe",
		"Position bar": "Barra Posizione",
		"Practice": "Pratica",
		"Preparing": "In preparazione",
		"PRESS CLUTCH / BRAKE / THROTTLE - OR CLICK HERE - TO CLOSE": "PREMERE FRIZIONE / FRENO / ACCELERATORE - O FARE CLIC QUI - PER CHIUDERE",
		"Qualification": "Qualifiche",
		"Qualifying": "Qualifica",
		"Race": "Gara",
		"Race info": "Info Gara",
		"Race start lights": "Semaforo",
		"Really delete ALL data?": "Sicuro di cancellare TUTTI i dati?",
		"Really delete Combination data?": "Sicuro di cancellare i dati Combinazioni?",
		"Rear tires": "Gomme post.",
		"Rear wing": "Ala post.",
		"Reason: Accumulating the Maximum Number of Penalties Permitted": "Motivo: raggiunto numero massimo di penalitá concesse",
		"Reason: Driving the wrong Way": "Motivo: contromano",
		"Reason: Driving too slow": "Motivo: guida troppo lenta",
		"Reason: Entering Pit under Red": "Motivo: ingresso ai pit con rosso",
		"Reason: Excessive Cutting of the Track": "Motivo: taglio eccessivo della pista",
		"Reason: Exiting Pits under Red": "Motivo: uscita dai pit con rosso",
		"Reason: False Start": "Motivo: Falsa partenza",
		"Reason: Ignoring Blue Flags": "Motivo: ignorate bandiere blu",
		"Reason: Ignoring Drive Through Penalty": "Motivo: ignorato Drive Through",
		"Reason: Ignoring Mandatory Pit-Stop": "Motivo: ignorato Pit-Stop obbligatorio",
		"Reason: Ignoring Pit-Stop Penalty": "Motivo: ignorata penalitá Pit-Stop",
		"Reason: Ignoring Slow Down Warnings": "Motivo: ignorati avvisi di rallentare",
		"Reason: Ignoring Stop and Go Penalty": "Motivo: ignorata penalitá Stop and Go",
		"Reason: Ignoring Time Penalty": "Motivo: ignorata penalitá tempo",
		"Reason: Ignoring the Driver Change": "Motivo: ignorato cambio pilota",
		"Reason: Ignoring the minimum Pit-Stop duration": "Motivo: ignorata durata minima Pit-Stop",
		"Reason: Illegally Passing before Green": "Motivo: sorpasso vietato prima della bandiera Verde",
		"Reason: Illegally Passing before the Finish": "Motivo: sorpasso vietato prima dell'arrivo",
		"Reason: Illegally Passing before the Pit Entrance": "Motivo: sorpasso vietato prima dell'ingresso ai Pit",
		"Reason: Mandatory Pit-Stop taken outside Pit-Window": "Motivo: Pit-Stop obbligatorio eseguito fuori dalla finestra per i Pit",
		"Reason: Multiple Drive Through Penalties in 1 Lap": "Motivo: multiple penalitá Drive Through in 1 giro",
		"Reason: Multiple Track Limit Abuse": "Motivo: troppe volte oltre i limiti tracciato",
		"Reason: Overtaking under Yellow": "Motivo: sorpasso con bandiera Gialla",
		"Reason: Speeding in the Pitlane": "Motivo: velocitá eccessiva in Pitlane",
		"Reason: Track Limits Abuse": "Motivo: oltre i limiti tracciato",
		"Received Layout-Settings: Saved to Layout": "Ricevuti Layout-Settings: Layout salvato",
		"Received Layout-Settings: [ERROR] Data was corrupt": "Ricevuti Layout-Settings: [ERROR] dati corrotti",
		"Refueling": "Rifornimento",
		"Relative": "Distacco",
		"Reset": "Reset",
		"Reset Settings": "Reset Impostazioni",
		"Reset values (c)": "Reset valori (c)",
		"Restored Layout 1": "Ripristinato Layout 1",
		"Restored Layout 2": "Ripristinato Layout 2",
		"Restored Layout 3": "Ripristinato Layout 3",
		"Restored UI": "UI ripristinata",
		"Saved Layout-Settings to Clipboard": "Layout-Settings salvati negli appunti",
		"Saved game state to clip board as JSON": "Stato del gioco salvato negli appunti come JSON",
		"Serve": "Esegui",
		"Serve within": "Esegui in",
		"Should beep": "Avviso vocale",
		"Show Best-Lap": "Mostra giro migliore",
		"Show Car Names": "Mostra nomi auto",
		"Show Changelog / Help": "Mostra Changelog / Aiuto",
		"Show Class Logos": "Mostra loghi classi",
		"Show Completed Laps": "Mostra giri completati",
		"Show Corner Names": "Mostra nomi curve",
		"Show Current Position": "Mostra posizione corrente",
		"Show Drivers UserId": "Mostra UserId piloti",
		"Show Electronics": "Mostra Elettronica",
		"Show Estimate Time": "Mostra tempi stimati",
		"Show Estimated Laps": "Mostra giri stimati",
		"Show Estimated Laps left": "Mostra giri rimanenti stimati",
		"Show Gaps in Seconds": "Mostra ritardi in secondi",
		"Show Incident Points": "Mostra punti incidenti",
		"Show Inner & Outer": "Mostra interno ed esterno",
		"Show Lap Details": "Mostra dettagli giro",
		"Show Lap-Time": "Mostra tempi",
		"Show Last-Lap": "Mostra ultimo giro",
		"Show Last-Lap-Times": "Mostra tempi ultimo giro",
		"Show Logo": "Mostra logo",
		"Show Manufacturer Logos": "Mostra loghi produttori",
		"Show Numbers": "Mostra valori",
		"Show Pit-Status": "Mostra stato Pit",
		"Show Pit-Time only": "Mostra solo tempo Pit",
		"Show Pit-Times": "Mostra tempi Pit",
		"Show Pit-Window": "Mostra finestra Pit",
		"Show Pit-Window Info": "Mostra info finestra Pit",
		"Show Ranking Data": "Mostra dati classifica",
		"Show Sectors as time": "Mostra settori come tempo",
		"Show Session Info": "Mostra info sessione",
		"Show Session-Time": "Mostra tempo sessione",
		"Show Standings": "Mostra classifica",
		"Show Stopped Drivers": "Mostra piloti fermi",
		"Show Strength of Field": "Mostra SoF",
		"Show TC in percent if available": "Mostra TC in % se disponibile",
		"Show Tire Est. Laps-Left": "Mostra stima durata gomme in giri",
		"Show Tire Infos": "Mostra info gomme",
		"Show Tire-Pressure numbers": "Mostra valori pressione gomme",
		"Show Tire-Temp numbers": "Mostra valori temperatura gomme",
		"Show Tire-Wear numbers": "Mostra valori consumo gomme",
		"Show Tire-Wear per Lap": "Mostra consumo gomme per giro",
		"Show full Driver Grid": "Mostra griglia piloti completa",
		"Show full Lastname": "Mostra cognome intero",
		"Show in all Sessions": "Mostra in tutte le sessioni",
		"Show only Own Class": "Mostra solo la mia Classe",
		"Slow Down Penalty": "Penalitá rallentamneto",
		"Slow cars ahead": "Auto lente davanti",
		"Spanish": "Spagnolo",
		"Speed in MPH": "Velocitá in MPH",
		"Spotting / Radar": "Spotter / Radar",
		"Steering wheel": "Volante",
		"Stop And Go Penalty": "Penalitá Stop And Go",
		"Strength of Field": "SoF",
		"Suspension": "Sospensione",
		"TV Tower": "TV Tower",
		"Team Radio": "Team radio",
		"Telemetry": "Telemetria",
		"This lap will not count": "Giro non valido",
		"Time Deduction Penalty": "Penalitá sottrazione tempo",
		"Time Estimate": "Tempo stim.",
		"Tire-Pressure in PSI": "Pressione in PSI",
		"Tire-Temp in Celsius": "Temperatura in Celsius",
		"Tires": "Gomme",
		"Track Info": "Info Pista",
		"Track Name & Details": "Nome Pista e Dettagli",
		"Track:": "Pista:",
		"Traction Control:": "Controllo Trazione:",
		"Transmission": "Trasmissione",
		"Type to filter keys": "Digitare per filtrare",
		"UI IS NOW LOCKED - UNLOCK WITH CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNO FURTHER MESSAGES": "UI BLOCCATA - SBLOCCA CON CTRL+ALT+MAIUSC+L !!!\n\t\t\t\tNESSUN ALTRO MESSAGGIO",
		"UI IS NOW UNLOCKED": "UI SBLOCCATA",
		"UI is paused! Press SHIFT+SPACEBAR to Unpause": "UI in pausa! Premi SHIFT+SPAZIO per sbloccare",
		"Unpause UI": "Sblocca UI",
		"Updates/sec:": "Aggiorn./sec:",
		"Version mismatch": "Versione differente",
		"Visit the Forum-Thread to Download": "Visita la discussione sul Forum per il Download",
		"VOL:": "VOL:",
		"Warmup": "Warmup",
		"Warn Front": "Avviso auto davanti",
		"We have no Data yet!": "Non abbiamo ancora dati!",
		"Widgets Grid-Snap Disabled": "Aggancio Griglia Disabilitato",
		"Widgets Grid-Snap Enabled": "Aggancio Griglia Abilitato",
		"Widgets-TEST-Mode": "Modalitá-TEST-Widget",
		"Widgets-TEST-Mode Active!\n\t\t\t\tDisable in Settings or press SHIFT+DOWNARROW": "Modalitá-TEST-Widget Attiva!\n\t\t\t\tDisabilitala nelle Impostazioni o premi SHIFT+FRECCIAGIÚ",
		"Wrong way": "Contromano",
		"YES": "SI",
		"Yield to the car behind": "Fai passare l'auto dietro",
		"You need to re-download dash.exe": "Devi scaricare nuovamente dash.exe",
		"You need to download/run dash.exe": "Devi scaricare/avviare dash.exe",
		"Your dash.exe is OUTDATED": "Dash.exe é VECCHIO",
		"after this lap": "dopo questo giro",
		"in 1 minute": "entro 1 minuto",
		"lap": "giro",
		"laps": "giri",
		"minute": "minuto",
		"minutes": "minuti",
		"pL": "pG",
		"this lap": "in questo giro"
	},
	"es": {
		"Aerodynamics": "Aerodinámica",
		"All Fuel/Lap Tracking data got deleted!": "Datos de Fuel/Vuelta eliminados",
		"Auto Hide": "Auto Ocultar",
		"Auto-Clear Data for Race-Session": "Auto-Borrado datos sesión de carrera",
		"Auto-Clear Data on Session-Change": "Auto-Borrado datos cambio de sesión",
		"Auto-Hide Pit-Times": "Auto-Ocultar Tiempo Parada",
		"Average Laptime:": "Tiempo medio por vuelta:",
		"Average Speed:": "Velicidad media:",
		"Background Blinking": "Fondo Parpadeando",
		"Best Lap": "Mejor Vuelta",
		"Best Laptime:": "Mejor Tiempo:",
		"Black flag": "Bandera Negra",
		"Blue flag 1st warning": "Bandera Azul 1er Aviso",
		"Blue flag 2nd warning": "Bandera Azul 2do Aviso",
		"Brake Bias:": "Reparto Frenada:",
		"Car assists": "Ayudas del coche",
		"Change Logo URL": "Cambiar URL del logo",
		"Checkered flag: Last lap!": "Bandera a cuadros: Última vuelta!",
		"Clear": "Despejado",
		"Clear Personal Best-Laptime?": "Borrar Mejor Tiempo personal?",
		"Clear all Stored Data": "Borrar todos los datos almacenados",
		"Clear this Combo Stored Data": "Borrar selección de datos",
		"Click again to confirm Layout reset": "Click de nuevo para restablecer el Layout",
		"Click here to change": "Click aquí para cambiar",
		"Clock": "Reloj",
		"Close": "Cerrar",
		"Completed Laps": "Vueltas Completadas",
		"Crew Chief": "Crew Chief",
		"Current Logo:": "Logo Actual:",
		"Current speed": "Velocidad Actual",
		"Current version:": "Versión Actual:",
		"Cutting track": "Corte de Pista",
		"Damage": "Daños",
		"Delta": "Delta",
		"Delta bars": "Delta en Barras",
		"Delta text": "Delta en texto",
		"Delta to Best-Lap in Race": "Delta contra mejor tiempo en carrera",
		"Dist:": "Dist:",
		"Drive Through Penalty": "Penalización: Drive Through",
		"Driver Change": "Cambio de Piloto",
		"Drivers Ahead/Behind": "Pilotos Delante/Detrás",
		"Enable WebHud integration": "Enable WebHud integration",
		"Engine": "Motor",
		"Engine Braking:": "Freno Motor:",
		"Engine Map:": "Mapa Motor",
		"English": "Inglés",
		"Est. Pos": "Pos. Est.",
		"Est. Time": "Tiempo Est.",
		"Est.L. left / Est.L. total": "Estimada\nV.Quedan / V.Total",
		"Estimated Laps left": "Vueltas Estimadas que Quedan",
		"Estimated Laps total": "Vueltas Estimadas Totales",
		"Estimated Laps:": "Vueltas Estimadas",
		"Estimated lap time": "Tiempo Vuelta Estimado",
		"Estimated position": "Posición Estimada",
		"Flags": "Banderas",
		"French": "Francés",
		"Front tires": "Neumáticos\nDelanteros",
		"Front wing": "Alerón\nDelantero",
		"Fuel": "Fuel",
		"Fuel & Lap Details": "Detalle Fuel y vueltas",
		"Fuel / Lap": "Fuel / Vuelta",
		"Fuel Calculator - Right click to close": "Calculadora Fuel- Click Derecho para cerrar",
		"Fuel Remain": "Fuel restante",
		"Fuel To Add": "Fuel a Añadir",
		"Fuel To End": "Fuel para acabar",
		"Fuel needed": "Fuel necesario",
		"Fuel/Lap Tracking data for this Combination got deleted!": "Necesaria info de Fuel/Vuelta para esta combinación!",
		"G-Force": "Fuerzas G",
		"German": "Alemán",
		"Go!": "Vamos!",
		"Hazard on the track": "Peligro en la pista",
		"Hide in race": "Ocultar en Carrera",
		"High Performance Mode": "Modo Alto Rendimiento",
		"IGNITION": "ARRANQUE",
		"In Pit Lane": "En Pitlane",
		"In Progress": "En curso",
		"Incidents": "Incidentes",
		"Inputs": "Entradas",
		"Italian": "Italiano",
		"Just-White": "Todo en Blanco",
		"KMH": "",
		"Kph": "",
		"L": "V",
		"Lap": "Vuelta",
		"Lap time": "Tiempo de Vuelta",
		"Laps": "Vueltas",
		"Laps Estimate": "Vueltas Est.",
		"Last Lap": "Última Vuelta",
		"Layout reseted!": "Layout Restablecido!",
		"Layout:": "Layout:",
		"Length:": "Tamaño:",
		"Loaded game state from clip board": "Cargado estado juego desde el portapapeles",
		"Low Performance Mode": "Modo Bajo Rendimiento",
		"MPH": "",
		"Mandatory\nPit": "Pit\nObligatoro",
		"Mandatory Pit In Progress": "Pit Obligatorio en curso",
		"Minimum Pit time remain": "Tiempo mínimo en Pit pendiente",
		"Minimum Pit time served!": "Tiempo mínimo en Pit servido!",
		"Minutes": "Minutos",
		"Motec": "Motec",
		"Next lap will not count": "Siguiente vuelta no cuenta",
		"NO": "NO",
		"NO Data-Feed detected": "No se han detectado datos",
		"No Radar - Beep only": "No Radar - Solo Sonido",
		"Next position": "Siguiente Posición",
		"Normal Performance Mode": "Modo Normal Rendimiento",
		"Only show 5 Ahead/Behind": "Mostrar sólo 5 Delante/Detrás",
		"Or enable this option in Crew-Chief:": "O activa esta opción en CrewChief:",
		"OtterHud Start-Parameter set correctly": "Parámetro de arraque de OtterHud correcto",
		"P2P/DRS": "P2P/DRS",
		"PIT MENU APPEARS HERE": "PITS MENU APARECE AQUÍ",
		"PIT-LANE": "PIT-LANE",
		"Pause UI": "Pausar UI",
		"Pit Remain": "Tiempo de restante",
		"Pit Stop Penalty": "Penalización: PitStop",
		"Pit Total": "Tiempo de parada",
		"Pit limiter": "Limitador Pit",
		"Pit-Entrance": "Entrada al Pit",
		"Pit-Spot in": "Cajón del pit",
		"Pit-Stop requested": "Parada Solicitada",
		"Pit-Stop requested - Pit In": "Parada Solicitada - Entrando",
		"Pit-Window closes": "Ventana Pits Cerrada",
		"Pit-Window closes in": "Ventana Pits cierra en",
		"Pit-Window open for": "Ventana Pits abierta durante",
		"Pit-Window opens": "Ventana Pits abierta",
		"Pit-Window opens in": "Ventana Pits abre en",
		"Pit-Window\ncloses": "Ventana Pits\ncerrada",
		"Pit-Window\ncloses in": "Ventana Pits\ncierra en",
		"Pit-Window\nopen for": "Ventana Pits\nabierta durante",
		"Pit-Window\nopens": "Ventana Pits\nabierta",
		"Pit-Window\nopens in": "Ventana Pits\nabre en",
		"PitLimiter Blink": "Parpadeo Limitador",
		"Pitstop": "Parada",
		"Polish": "Polaco",
		"Portuguese": "Portugués",
		"Position": "Posición",
		"Position Class": "Posición Clase",
		"Position bar": "Barra Posiciones",
		"Practice": "Práctica",
		"Preparing": "Preparando",
		"PRESS CLUTCH / BRAKE / THROTTLE - OR CLICK HERE - TO CLOSE": "Pulsa Embrague / Freno / Acelerador - o click aquí - para cerrar",
		"Qualification": "Clasificación",
		"Qualifying": "Clasificación",
		"Race": "Carrera",
		"Race info": "Info Carrera",
		"Race start lights": "Luces Inicio Carrera",
		"Really delete ALL data?": "Confirmas borrar TODOS los datos?",
		"Really delete Combination data?": "Confirmas borrar esta combinación de datos?",
		"Rear tires": "Neumáticos\nTraseros",
		"Rear wing": "Alerón\nTrasero",
		"Reason: Accumulating the Maximum Number of Penalties Permitted": "Razón: Acumular Máximo Número de Penalizaciones Permitido",
		"Reason: Driving the wrong Way": "Razón: Conducir en sentido contrario",
		"Reason: Driving too slow": "Razón: Conducir demasiado lento",
		"Reason: Entering Pit under Red": "Razón: Entrar al Pit en Rojo",
		"Reason: Excessive Cutting of the Track": "Razón: Demasiados cortes de pista",
		"Reason: Exiting Pits under Red": "Razón: Salir del Pit en Rojo",
		"Reason: False Start": "Razón: Salida en falso",
		"Reason: Ignoring Blue Flags": "Razón: Ignorar Banderas Azules",
		"Reason: Ignoring Drive Through Penalty": "Razón: Ignorar Penalización Drive Through",
		"Reason: Ignoring Mandatory Pit-Stop": "Razón: Ignorar Parada Obligatoria",
		"Reason: Ignoring Pit-Stop Penalty": "Razón: Ignorar Penalización PitStop",
		"Reason: Ignoring Slow Down Warnings": "Razón: Ignorar Avisos de Slow-Down",
		"Reason: Ignoring Stop and Go Penalty": "Razón: Ignorar Penalización Stop&Go",
		"Reason: Ignoring Time Penalty": "Razón: Ignorar Penalización por Tiempo",
		"Reason: Ignoring the Driver Change": "Razón: Ignorar cambio de piloto",
		"Reason: Ignoring the minimum Pit-Stop duration": "Razón: Ignorar tiempo mínimo de parada",
		"Reason: Illegally Passing before Green": "Razón: Adelantar Ilegalmente antes del Verde",
		"Reason: Illegally Passing before the Finish": "Razón: Adelantar Ilegalmente antes de Meta",
		"Reason: Illegally Passing before the Pit Entrance": "Razón: Adelantar Ilegalmente antes de la entrada al Pit",
		"Reason: Mandatory Pit-Stop taken outside Pit-Window": "Razón: Parada realizada fuera de ventana",
		"Reason: Multiple Drive Through Penalties in 1 Lap": "Razón: Varios Drive Through en Vuelta 1",
		"Reason: Multiple Track Limit Abuse": "Razón: Varios abusos límites de pista",
		"Reason: Overtaking under Yellow": "Razón: Adelantar bajo amarilla",
		"Reason: Speeding in the Pitlane": "Razón: Sobrepasar velocidad del Pitlane",
		"Reason: Track Limits Abuse": "Razón: Abuso Límites de pista",
		"Received Layout-Settings: Saved to Layout": "Configuración Layout Recibida: Guardado en Layout",
		"Received Layout-Settings: [ERROR] Data was corrupt": "Configuración Layout Recibida: [ERROR] Datos Corruptos",
		"Refueling": "Repostando",
		"Relative": "Relativo",
		"Reset": "Restab.",
		"Reset Settings": "Restablecer Configuración",
		"Reset values (c)": "Restableer Valores (c)",
		"Restored Layout 1": "Layout 1 Restablecido",
		"Restored Layout 2": "Layout 2 Restablecido",
		"Restored Layout 3": "Layout 3 Restablecido",
		"Restored UI": "UI Restablecida",
		"Saved Layout-Settings to Clipboard": "Config-Layout copiada al portapapeles",
		"Saved game state to clip board as JSON": "Estado de Juego copiado como JSON al portapapeles",
		"Serve": "Servir",
		"Serve within": "Servir en",
		"Should beep": "Activar Sonido",
		"Show Best-Lap": "Mostrar Mejor Vuelta",
		"Show Car Names": "Mostrar Nombres Coches",
		"Show Changelog / Help": "Mostrar Cambios / Ayuda",
		"Show Class Logos": "Mostrar Logos de Clase",
		"Show Completed Laps": "Mostrar Vueltas Completadas",
		"Show Corner Names": "Mostrar Nombres de Curvas",
		"Show Current Position": "Mostrar Posición Actual",
		"Show Drivers UserId": "Mostrar UserID de Pilotos",
		"Show Electronics": "Mostrar Electrónica",
		"Show Estimate Time": "Mostrar Tiempo Estimado",
		"Show Estimated Laps": "Mostrar Vueltas Estimadas",
		"Show Estimated Laps left": "Mostrar Vueltas Estimadas que quedan",
		"Show Gaps in Seconds": "Mostrar Gap en Segundos",
		"Show Incident Points": "Mostrar Incidentes",
		"Show Inner & Outer": "Mostrar Interior & Exterior",
		"Show Lap Details": "Mostrar Detalles de Vuelta",
		"Show Lap-Time": "Mostrar Tiempo Vuelta",
		"Show Last-Lap": "Mostrar Última Vuelta",
		"Show Last-Lap-Times": "Mostrar Tiempo Última Vuelta",
		"Show Logo": "Mostrar Logo",
		"Show Manufacturer Logos": "Mostrar Logo Marcas",
		"Show Numbers": "Mostrar Números",
		"Show Pit-Status": "Mostrar Estado Pits",
		"Show Pit-Time only": "Mostrar sólo Tiempo Pits",
		"Show Pit-Times": "Mostrar Tiempo Pits",
		"Show Pit-Window": "Mostrar Ventana Parada",
		"Show Pit-Window Info": "Mostrar Info Ventana Parada",
		"Show Ranking Data": "Mostrar Datos de Ranking",
		"Show Sectors as time": "Mostrar Sectores como tiempo",
		"Show Session Info": "Mostrar Info Sesión",
		"Show Session-Time": "Mostrar Tiempo Sesión",
		"Show Standings": "Mostrar Posiciones",
		"Show Stopped Drivers": "Mostrar pilotos fuera de carrera",
		"Show Strength of Field": "Mostrar SoF",
		"Show TC in percent if available": "Mostrar TC en % si disponible",
		"Show Tire Est. Laps-Left": "Mostrar Vueltas Est. Neumático",
		"Show Tire Infos": "Mostrar Información Neumáticos",
		"Show Tire-Pressure numbers": "Mostrar Presión Neumático en Números",
		"Show Tire-Temp numbers": "Mostrar Temp. Neumático en Números",
		"Show Tire-Wear numbers": "Mostrar Desgaste Neumático en Números",
		"Show Tire-Wear per Lap": "Mostrar Desgaste Numático por Vuelta",
		"Show full Driver Grid": "Mostrar Parrilla Completa",
		"Show full Lastname": "Mostrar Apellido Completo",
		"Show in all Sessions": "Mostrar en Todas las Sesiones",
		"Show only Own Class": "Mostrar Solo misma Clase",
		"Slow Down Penalty": "Penalización: Slow Down",
		"Slow cars ahead": "Coches lentos delante",
		"Spanish": "Español",
		"Speed in MPH": "Velocidad en MPH",
		"Spotting / Radar": "Radar",
		"Steering wheel": "Volante",
		"Stop And Go Penalty": "Penalización Stop&Go",
		"Strength of Field": "SoF",
		"Suspension": "Suspensiones",
		"TV Tower": "Torre TV",
		"Team Radio": "Radio Equipo",
		"Telemetry": "Telemetría",
		"This lap will not count": "Esta vuelta no cuenta",
		"Time Deduction Penalty": "Penalización Reducción Tiempo",
		"Time Estimate": "Tiempo Est.",
		"Tire-Pressure in PSI": "Presión Numáticos en PSI",
		"Tire-Temp in Celsius": "Temp. Neumáticos en Celsius",
		"Tires": "Neumáticos",
		"Track Info": "Información Circuito",
		"Track Name & Details": "Nombre Circuito y Detalles",
		"Track:": "Circuito:",
		"Traction Control:": "Control de Tracción:",
		"Transmission": "Transmisión",
		"Type to filter keys": "Teclea para filtrar",
		"UI IS NOW LOCKED - UNLOCK WITH CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNO FURTHER MESSAGES": "UI BLOQUEADA - DESBLOQUEO CON CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNO MAS MENSAJES",
		"UI IS NOW UNLOCKED": "UI DESBLOQUEADA",
		"UI is paused! Press SHIFT+SPACEBAR to Unpause": "La UI está PAUSADA! Pulsa SHIFT+SPACEBAR para reactivar",
		"Unpause UI": "Reactivar UI",
		"Updates/sec:": "Actual./segundo",
		"Version mismatch": "Versión no coincidente",
		"Visit the Forum-Thread to Download": "Visita el hilo del foro para Descargar:",
		"VOL:": "VOL:",
		"Warmup": "Calentamiento",
		"Warn Front": "Aviso Coche Delante",
		"We have no Data yet!": "Aún no tenemos datos!",
		"Widgets Grid-Snap Disabled": "Alinear Widgets Deshabilitado",
		"Widgets Grid-Snap Enabled": "Alinear Widgets Habilitado",
		"Widgets-TEST-Mode": "Widgets-MODO-TEST",
		"Widgets-TEST-Mode Active!\n\t\t\t\tDisable in Settings or press SHIFT+DOWNARROW": "Widgets-MODO-TEST Activado\n\t\t\t\tDesactívalo en Configuración o pulsa SHIFT+DOWNARROW",
		"Wrong way": "Sentido Contrario",
		"YES": "SI",
		"Yield to the car behind": "Deja pasar al coche de detrás",
		"You need to re-download dash.exe": "Necesitas descargar de nuevo dash.exe",
		"You need to download/run dash.exe": "Necesitas descargar/ejecutar dash.exe",
		"Your dash.exe is OUTDATED": "Tu dash.exe está DESACTUALIZADO",
		"after this lap": "tras esta vuelta",
		"in 1 minute": "en 1 minuto",
		"lap": "vuelta",
		"laps": "vueltas",
		"minute": "minuto",
		"minutes": "minutos",
		"pL": "pV",
		"this lap": "esta vuelta"
	},
	"pl": {
		"Aerodynamics": "Karoseria",
		"All Fuel/Lap Tracking data got deleted!": "Wszystkie dane na temat paliwa zostały usunięte!",
		"Auto Hide": "Automatyczne ukrywanie",
		"Auto-Clear Data for Race-Session": "Auto. kasowanie dla sesji wyścigowej",
		"Auto-Clear Data on Session-Change": "Auto. kasowanie przy zmianie sesji",
		"Auto-Hide Pit-Times": "Autoukrywanie czasów pit stopu",
		"Average Laptime:": "Średni czas:",
		"Average Speed:": "Średnia prędkość:",
		"Background Blinking": "Miganie tła",
		"Best Lap": "Najlepsze okrążenie",
		"Best Laptime:": "Najlepszy czas:",
		"Black flag": "Czarna flaga",
		"Blue flag 1st warning": "Niebieska Flaga 1 ostrzeżenie",
		"Blue flag 2nd warning": "Niebieska Flaga 2 ostrzeżenie",
		"Brake Bias:": "Balans Hamulców:",
		"Car assists": "Asysty Samochodu",
		"Change Logo URL": "Zmień logo URL",
		"Checkered flag: Last lap!": "Flaga z szachownicą: Ostatnie okrążenie!",
		"Clear": "Czysto",
		"Clear Personal Best-Laptime?": "Wyczyść najlepszy czas okrążenia?",
		"Clear all Stored Data": "Wyczysć wszystkie dane",
		"Clear this Combo Stored Data": "Wyczyść dane dotyczące tego combo",
		"Click again to confirm Layout reset": "Kliknij jescze raz by potwierdzić reset ustawień okienek",
		"Click here to change": "Naciśnij tutaj by zmienić",
		"Clock": "Zegar",
		"Close": "Zamknij",
		"Completed Laps": "Przejechane Okr.",
		"Crew Chief": "Crew Chief",
		"Current Logo:": "Obecne Logo:",
		"Current speed": "Obecna prędkość",
		"Current version:": "Obecna wersja:",
		"Cutting track": "Ścinanie zakrętów",
		"Damage": "Zniszczenia",
		"Delta": "Delta",
		"Delta bars": "Pasek Delta",
		"Delta text": "Tekst Delta",
		"Delta to Best-Lap in Race": "Delta do najlepszego czasu w wyścigu",
		"Dist:": "Dystans:",
		"Drive Through Penalty": "Przejazd przez boksy",
		"Driver Change": "Zmiana kierowcy",
		"Drivers Ahead/Behind": "Kierowcy przed/za",
		"Enable WebHud integration": "Włącz Integrację z WebHud",
		"Engine": "Silnik",
		"Engine Braking:": "Hamowanie Silnikiem:",
		"Engine Map:": "Mapa silnika:",
		"English": "Angielski",
		"Est. Pos": "Szac. Pozycja",
		"Est. Time": "Szac. Czas",
		"Est.L. left / Est.L. total": "Szacowany\nOkr. poz. / Okr. razem",
		"Estimated Laps left": "Szac. poz. liczba Okr.",
		"Estimated Laps total": "Szac. Okr. razem",
		"Estimated Laps:": "Szac. Okr.:",
		"Estimated lap time": "Szac. czas Okr.",
		"Estimated position": "Szac. Pozycja",
		"Flags": "Flagi",
		"French": "Francuski",
		"Front tires": "Opony\nPrzednie",
		"Front wing": "Przednie\nskrzydło",
		"Fuel": "Paliwo",
		"Fuel & Lap Details": "Szczegóły Paliwa i Okr.",
		"Fuel / Lap": "Paliwo / Okr.",
		"Fuel Calculator - Right click to close": "Kalkulator Paliwa - Kliknij prawym by zamknąć",
		"Fuel Remain": "Pozostałe Pal.",
		"Fuel To Add": "Pal. do uzup.",
		"Fuel To End": "Pal. na mecie",
		"Fuel needed": "Potrzebne Pal.",
		"Fuel/Lap Tracking data for this Combination got deleted!": "Dane Paliwa/Okr zostały usunięte!",
		"G-Force": "Przeciążenie",
		"German": "Niemiecki",
		"Go!": "Jedź!",
		"Hazard on the track": "Niebezpieczeństwo na torze",
		"Hide in race": "Ukryj podczas wyścigu",
		"High Performance Mode": "Tryb Wysokiej Wydajności",
		"IGNITION": "ZAPŁON",
		"In Pit Lane": "W Pit Lane",
		"In Progress": "Trwa",
		"Incidents": "Incydenty",
		"Inputs": "Wejścia",
		"Italian": "Włoski",
		"Just-White": "Po prostu biały",
		"KMH": "",
		"Kph": "",
		"L": "O",
		"Lap": "Okr.",
		"Lap time": "Czas Okrążenie",
		"Laps": "Okrążeń",
		"Laps Estimate": "Szac. Okr.",
		"Last Lap": "Ostatnie Okr.",
		"Layout reseted!": "Nakładka zresetowana!",
		"Layout:": "Układ:",
		"Length:": "Długość:",
		"Loaded game state from clip board": "Załadowany zapis ze schowka",
		"Low Performance Mode": "Tryb Niskiej Wydajności",
		"MPH": "",
		"Mandatory\nPit": "Obowiązkowy\nPostój",
		"Mandatory Pit In Progress": "Obowiązkowy Pit Trwa",
		"Minimum Pit time remain": "Minimalny Pozostały Czas Postoju",
		"Minimum Pit time served!": "Minimalny czas Postoju zakończony!",
		"Minutes": "Minuty",
		"Motec": "",
		"NO": "NIE",
		"NO Data-Feed detected": "NIE wykryto Strumienia danych",
		"No Radar - Beep only": "Bez radaru - tylko pikanie",
		"Next lap will not count": "Następne okrążenie nie będzie się liczyć",
		"Next position": "Następna pozycja",
		"Normal Performance Mode": "Tryb Normalnej Wydajności",
		"Only show 5 Ahead/Behind": "Okaż tylko 5 Przed/Za Tobą",
		"Or enable this option in Crew-Chief:": "Albo włącz tą opcje w Crew Chief:",
		"OtterHud Start-Parameter set correctly": "OtterHud - Parametry Startowe ustawione poprawnie",
		"P2P/DRS": "",
		"PIT MENU APPEARS HERE": "TU POJAWIA SIĘ MENU PITSTOPU",
		"PIT-LANE": "",
		"Pause UI": "Wstrzymaj UI",
		"Pit Remain": "Pozostałe zjazdy",
		"Pit Stop Penalty": "Kara Pit Stopu",
		"Pit Total": "Czas Postoju",
		"Pit limiter": "Ogranicznik Prędkości",
		"Pit-Entrance": "Wjazd do Alei",
		"Pit-Spot in": "Odległość do pitu",
		"Pit-Stop requested": "Pit-Stop zarządany",
		"Pit-Stop requested - Pit In": "Pit-Stop zarządany - Zjedź teraz",
		"Pit-Window closes": "Okno Serwisowe zamyka się",
		"Pit-Window closes in": "Okno Serwisowe zamyka się za",
		"Pit-Window open for": "Okno Serwisowe otwarte przez",
		"Pit-Window opens": "Okno Serwisowe otwiera się",
		"Pit-Window opens in": "Okno Serwisowe otwiera się za",
		"Pit-Window\ncloses": "Okno Serwisowe\nzamyka się",
		"Pit-Window\ncloses in": "Okno Serwisowe\nzamyka się za",
		"Pit-Window\nopen for": "Okno Serwisowe\notwarte przez",
		"Pit-Window\nopens": "Okno Serwisowe\notwiera się",
		"Pit-Window\nopens in": "Okno Serwisowe\notwiera się za",
		"PitLimiter Blink": "Miganie Ogranicznika Prędkości",
		"Pitstop": "",
		"Polish": "Polski",
		"Portuguese": "Portugalski",
		"Position": "Pozycja",
		"Position Class": "Pozycja w Klasie",
		"Position bar": "Pasek pozycji",
		"Practice": "Trening",
		"Preparing": "Przygotowywanie",
		"PRESS CLUTCH / BRAKE / THROTTLE - OR CLICK HERE - TO CLOSE": "WCIŚNIJ SPRZĘGŁO / HAMULEC / GAZ - ALBO KLIKNIJ TUTAJ - BY ZAMKNĄĆ",
		"Qualification": "Kwalifikacje",
		"Qualifying": "Kwalifikacja",
		"Race": "Wyścig",
		"Race info": "Info o wyścigu",
		"Race start lights": "Światła Startowe",
		"Really delete ALL data?": "Na pewno usunąć WSZYSTKIE dane?",
		"Really delete Combination data?": "Na pewno usunąć dane kombinacji?",
		"Rear tires": "Opony\nTylne",
		"Rear wing": "Tylne\nskrzydło",
		"Reason: Accumulating the Maximum Number of Penalties Permitted": "Powód: Zebranie maksymalnej ilości przewinień",
		"Reason: Driving the wrong Way": "Powód: Jazda w złym kierunku",
		"Reason: Driving too slow": "Powód: Zbyt wolna jazda",
		"Reason: Entering Pit under Red": "Powód: Wjazd do Pitlane podczas Czerwonej Flagi",
		"Reason: Excessive Cutting of the Track": "Powód: Nadmierne ścinanie zakrętów",
		"Reason: Exiting Pits under Red": "Powód: Wyjazd z Pitlane podczas Czerwonej Flagi",
		"Reason: False Start": "Powód: Falstart",
		"Reason: Ignoring Blue Flags": "Powód: Ignorowanie Niebieskich Flag",
		"Reason: Ignoring Drive Through Penalty": "Powód: Ignorowanie Kary przejazdu przez Boksy",
		"Reason: Ignoring Mandatory Pit-Stop": "Powód: Ignorowanie Obowiązkowego Postoju",
		"Reason: Ignoring Pit-Stop Penalty": "Powód: Ignorowanie kary Pit-Stopu",
		"Reason: Ignoring Slow Down Warnings": "Powód: Ignorowanie ostrzeżeń o zmniejszeniu prędkości",
		"Reason: Ignoring Stop and Go Penalty": "Powód: Ignorowanie kary Stop and Go",
		"Reason: Ignoring Time Penalty": "Powód: Ignorowanie Kary Czasowej",
		"Reason: Ignoring the Driver Change": "Powód: Ignorowanie Zmiany Kierowcy",
		"Reason: Ignoring the minimum Pit-Stop duration": "Powód: Ignorowanie czasu minimalnego postoju",
		"Reason: Illegally Passing before Green": "Powód: Niedozwolone wyprzedzanie przed Zielonym Światłem",
		"Reason: Illegally Passing before the Finish": "Powód: Niedozwolone wyprzedzanie przed Metą",
		"Reason: Illegally Passing before the Pit Entrance": "Powód: Niedozwolone wyprzedzanie podczas wjazdu do Pitu",
		"Reason: Mandatory Pit-Stop taken outside Pit-Window": "Powód: Obowiązkowy Pit-Stop odbyty poza oknem serwisowym",
		"Reason: Multiple Drive Through Penalties in 1 Lap": "Powód: Wielokrotne Kary przejazdu przez boksy w 1 Okrążeniu",
		"Reason: Multiple Track Limit Abuse": "Powód: Wielokrotne Wyjazdy Poza Limity Toru",
		"Reason: Overtaking under Yellow": "Powód: Wyprzedzanie podczas żółtych flag",
		"Reason: Speeding in the Pitlane": "Powód: Przekroczenie prędkości w Pitlane",
		"Reason: Track Limits Abuse": "Powód: Wyjazd poza limit toru",
		"Received Layout-Settings: Saved to Layout": "Otrzymano Ustawienia Nakładki: Zapisano do Nakładki",
		"Received Layout-Settings: [ERROR] Data was corrupt": "Otrzymano Ustawienia Nakładki: [BŁĄD] Dane są uszkodzone",
		"Refueling": "Tankowanie",
		"Relative": "Względna",
		"Reset": "",
		"Reset Settings": "Zresetuj Ustawienia",
		"Reset values (c)": "Zresetuj wartośći (c)",
		"Restored Layout 1": "Zapisany Układ 1",
		"Restored Layout 2": "Zapisany Układ 2",
		"Restored Layout 3": "Zapisany Układ 3",
		"Restored UI": "Przywrócone UI",
		"Saved Layout-Settings to Clipboard": "Zapisz ustawienia nakładki do schowka",
		"Saved game state to clip board as JSON": "Zapsz stan gry do schowka jako JSON",
		"Serve": "Odbyj",
		"Serve within": "Odbyj w ciągu",
		"Should beep": "Powinien piszczeć",
		"Show Best-Lap": "Pokaż Najlepsze Okr.",
		"Show Car Names": "Pokaż Nazwy Samochodów",
		"Show Changelog": "Pokaż changelog",
		"Show Class Logos": "Pokaż loga klas",
		"Show Completed Laps": "Pokaż ukończone Okr.",
		"Show Corner Names": "Pokaż nazwy zakrętów",
		"Show Current Position": "Pokaż Obecną Pozycję",
		"Show Drivers UserId": "Pokaż Id Kierowców",
		"Show Electronics": "Pokaż Elektronike",
		"Show Estimate Time": "Pokaż Szac. Czas",
		"Show Estimated Laps": "Pokaż Szac. Okr.",
		"Show Estimated Laps left": "Pokaż  Szac. liczbę Okr.",
		"Show Gaps in Seconds": "Pokaż odstęp w sekundach",
		"Show Incident Points": "Pokaż Punkty Incydentów",
		"Show Inner & Outer": "Pokaż Wewn. i Zewn.",
		"Show Lap Details": "Pokaż Szczegóły Okr.",
		"Show Lap-Time": "Pokaż Czas Okr.",
		"Show Last-Lap": "Pokaż ostatnie Okr.",
		"Show Last-Lap-Times": "Pokaż czasy ostanich okrążeń",
		"Show Logo": "Pokaż Logo",
		"Show Manufacturer Logos": "Pokaż Logo Producentów",
		"Show Numbers": "Pokaż Numery",
		"Show Pit-Status": "Pokaż Pit-Status",
		"Show Pit-Time only": "Pokaż tylko czas Pit-Stopu",
		"Show Pit-Times": "Pokaż czasy Pit-Stopów",
		"Show Pit-Window": "Pokaż okno serwisowe",
		"Show Pit-Window Info": "Pokaż Info o oknie serwisowym",
		"Show Ranking Data": "Pokaż Dane Rankingu",
		"Show Sectors as time": "Pokaż Sektory jako czas",
		"Show Session Info": "Pokaż Info o Sesji",
		"Show Session-Time": "Pokaż Czas Sesji",
		"Show Standings": "Pokaż miejsca",
		"Show Stopped Drivers": "Pokaż zatrzymanych kierowców",
		"Show Strength of Field": "Pokaż siłę stawki",
		"Show TC in percent if available": "Pokaż TC w % jeżeli możliwe",
		"Show Tire Est. Laps-Left": "Pokaż na ile Okr. pozostało opon",
		"Show Tire Infos": "Pokaż info o oponach",
		"Show Tire-Pressure numbers": "Pokaż ciśnienie opon",
		"Show Tire-Temp numbers": "Pokaż temp opon",
		"Show Tire-Wear numbers": "Pokaż zużycie opon",
		"Show Tire-Wear per Lap": "Pokaż zużycie opon na Okr.",
		"Show full Driver Grid": "Pokaż całą stawkę",
		"Show full Lastname": "Pokaż pełne nazwiska",
		"Show in all Sessions": "Pokaż we wszystkich sesjach",
		"Show only Own Class": "Pokaż tylko swoją klasę",
		"Slow Down Penalty": "Kara spowolnienia",
		"Slow cars ahead": "Wolniejsze auta przed tobą",
		"Spanish": "Hiszpański",
		"Speed in MPH": "Prędkość w MPH",
		"Spotting / Radar": "Radar",
		"Steering wheel": "Kierownica",
		"Stop And Go Penalty": "Kara Stop and Go",
		"Strength of Field": "",
		"Suspension": "Zawieszenie",
		"TV Tower": "Wieża TV",
		"Team Radio": "",
		"Telemetry": "Telemetria",
		"This lap will not count": "To okrążenie nie będzie się liczyć",
		"Time Deduction Penalty": "Kara czasowa",
		"Time Estimate": "Szac. czas",
		"Tire-Pressure in PSI": "Ciśnienie opon w PSI",
		"Tire-Temp in Celsius": "Temperatura opon w °C",
		"Tires": "Opony",
		"Track Info": "Informacje o torze",
		"Track Name & Details": "Nazwa toru i szczegóły",
		"Track:": "Tor:",
		"Traction Control:": "Kontrola Trakcji:",
		"Transmission": "Skrzynia",
		"Type to filter keys": "Filtruj po tekście",
		"UI IS NOW LOCKED - UNLOCK WITH CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNO FURTHER MESSAGES": "UI JEST ZABLOKOWANE - Odblokuj wciskając CTRL+ALT+SHIFT+L !!!\n\t\t\t\tNie będzie więcej wiadomości",
		"UI IS NOW UNLOCKED": "UI JEST ODBLOKOWANE",
		"UI is paused! Press SHIFT+SPACEBAR to Unpause": "UI jest zatrzymane! Wciśnij SHIFT+SPACJA by wznowić",
		"Unpause UI": "Wznów UI",
		"Updates/sec:": "Odśw./sek:",
		"Version mismatch": "Wersja się nie zgadza",
		"Visit the Forum-Thread to Download": "Odwiedź wątek na forum by pobrać",
		"VOL:": "",
		"Warmup": "Rozgrzewka",
		"Warn Front": "Ostrzegaj z przodu",
		"We have no Data yet!": "Nie mamy jeszcze żadnych danych!",
		"Widgets Grid-Snap Disabled": "Widget wyrównanie Wyłączony",
		"Widgets Grid-Snap Enabled": "Widget wyrównanie Włączony",
		"Widgets-TEST-Mode": "Tryb-TESTOWANIA",
		"Widgets-TEST-Mode Active!\n\t\t\t\tDisable in Settings or press SHIFT+DOWNARROW": "Tryb-TESTOWANIA Włączony!\n\t\t\t\tWyłacz w opcjach lub wciśnij SHIFT+STRZAŁKA W DÓŁ",
		"Wrong way": "Zły kierunek",
		"YES": "TAK",
		"Yield to the car behind": "Przepuść samochód z tyłu",
		"You need to re-download dash.exe": "Musisz pobrać ponownie dash.exe",
		"You need to download/run dash.exe": "Musisz pobrać/uruchomić dash.exe",
		"Your dash.exe is OUTDATED": "Twój dash.exe jest nieaktualny",
		"after this lap": "po tym okrążeniu",
		"in 1 minute": "za 1 minute",
		"lap": "okrążenie",
		"laps": "okrążenia",
		"minute": "minuta",
		"minutes": "minuty",
		"pL": "pO",
		"this lap": "to okrążenie"
	}
};
export default translations;

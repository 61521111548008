export const carNames = [
	'Aquila CR1 Sports GT',
	'134 Judd V8',
	'Saleen S7R',
	'Canhard R51',
	'BMW Alpina B6 GT3',
	'BMW 320 Turbo',
	'BMW M3 GT2',
	'BMW Z4 GT3',
	'Canhard R52',
	'Carlsson C25 GT',
	'Chevrolet Corvette C6R GT2',
	'Cougar C14-1',
	'Cougar C14-2',
	'DMD P20',
	'DMD P21',
	'Ford GT GT1',
	'Ford Mustang GT3',
	'Gumpert Apollo Sport',
	'Koenigsegg CCGT',
	'McLaren-Mercedes SLR 722 GT',
	'Mistral M530',
	'Mistral M531',
	'Nissan GT-R GT1',
	'Nissan GT-R GT3',
	'Nissan Silvia Turbo',
	'Nissan Skyline 2000RS',
	'P4-5 Competizione GT3',
	'Pagani Zonda R',
	'Radical SR9 AER',
	'Radical SR9 Judd',
	'RUF CTR3',
	'RUF RT12R',
	'BMW 635 CSI',
	'McLaren MP4-12C GT3',
	'Audi R8 LMS Ultra',
	'Zakspeed Capri',
	'BMW M3 E30 Gr.A',
	'Volvo 240 Turbo',
	'Chevrolet Daytona Prototype',
	'Chevrolet Camaro GT3',
	'BMW M1 Procar',
	'Audi 90 Quattro',
	'Ford GT GT3',
	'Cadillac CTS-V.R',
	'DTM Mercedes AMG C-Coupé',
	'BMW M3 DTM ',
	'Audi RS 5 DTM',
	'Mercedes-Benz SLS AMG GT3',
	'Chevrolet Corvette Z06.R GT3',
	'BMW E90 320 TC',
	'Honda Civic WTCC',
	'SEAT León WTCC',
	'Lada Granta WTCC',
	'Chevrolet Cruze WTCC',
	'Carlsson SLK 340 JUDD',
	'Fabcar 935',
	'Nissan 300ZX Z32',
	'Audi R8 LMS Ultra',
	'BMW Z4 GT3',
	'Chevrolet Camaro GT3',
	'Nissan GT-R GT3',
	'Mercedes-Benz SLS AMG GT3',
	'Audi RS 5 DTM 2014',
	'Mercedes-Benz SLS AMG GT3',
	'BMW M4 DTM',
	'DTM Mercedes AMG C Coupe 14',
	'Audi R8 LMS Ultra',
	'Chevrolet Corvette Z06.R GT3',
	'RUF RT12R GTR3',
	'McLaren MP4-12C GT3',
	'BMW Z4 GT3',
	'Chevrolet Camaro GT3',
	'Mercedes 190E Evo II DTM',
	'BMW M3 Sport Evolution',
	'Chevrolet Corvette Z06.R GT3',
	'Audi V8 DTM',
	'Ford GT GT3',
	'Ford Mustang GT DTM',
	'Opel Omega 3000 Evo500',
	'Citroen C Elysee WTCC',
	'Nissan Skyline GTR R32',
	'Chevrolet RML Cruze TC1',
	'LADA Granta 1.6T',
	'Chevrolet Corvette Greenwood 1977',
	'Nissan R90CK',
	'Chevrolet Dekon Monza',
	'Honda Civic WTCC 2014',
	'Audi RS 5 DTM 2015',
	'Mercedes-AMG C63 DTM',
	'BMW M4 DTM 2015',
	'Audi TT RS VLN',
	'Audi R18',
	'Ford Mustang IMSA GTO',
	'Audi R8 LMS Ultra',
	'BMW Z4 GT3',
	'Mercedes-Benz SLS AMG GT3',
	'Honda Civic WTCC 2015',
	'Nissan GT-R GT3',
	'Chevrolet Corvette Z06.R GT3 GTM15',
	'Chevrolet RML Cruze TC1 2015',
	'Chevrolet Camaro GT3',
	'Citroen C Elysee WTCC 2015',
	'Formula RaceRoom 2',
	'Porsche 911 GT3 Cup',
	'Audi TT cup 2015',
	'Lada Vesta WTCC 2015',
	'NSU TTS',
	'Formula RaceRoom Junior',
	'Tatuus F4',
	'Bentley Continental GT3',
	'Bentley Continental GT3',
	'P4-5 Competizione GT2',
	'Mercedes-AMG C 63 DTM',
	'KTM X-Bow RR',
	'Formula RaceRoom US',
	'Formula RaceRoom 3',
	'BMW M4 DTM 2016',
	'Mercedes-AMG C 63 DTM 2016',
	'Audi RS 5 DTM 2016',
	'Audi TT cup 2016',
	'KTM X-Bow GT4',
	'McLaren 650S GT3',
	'BMW M6 GT3',
	'Formula Raceroom X-17',
	'Volvo S60 Polestar TC1',
	'Chevrolet RML Cruze TC1 2016',
	'Citroen C Elysee WTCC 2016',
	'Honda Civic WTCC 2016',
	'Lada Vesta WTCC 2016',
	'Audi R8 LMS',
	'Mercedes AMG GT3',
	'Callaway Corvette C7 GT3-R',
	'Chevrolet RML Cruze eSports',
	'Citroen C Elysee eSports',
	'Honda Civic eSports',
	'Lada Vesta eSports',
	'Volvo S60 Polestar eSports',
	'BMW M235i Racing',
	'Chevrolet RML Cruze TC1 2017',
	'Citroen C Elysee WTCC 2017',
	'Honda Civic WTCC 2017',
	'Lada Vesta WTCC 2017',
	'Volvo S60 Polestar TC1',
	'Porsche 911 GT3 R',
	'Porsche Cayman GT4 Clubsport',
	'Audi RS 3 LMS',
	'Porsche Cayman GT4 CS MR',
	'AMG-Mercedes CLK DTM 2003',
	'AMG-Mercedes C-Klasse DTM 2005',
	'AMG-Mercedes 190 E 2.5-16 Evolution II 1992',
	'Mercedes-AMG C 63 DTM 2015',
	'AMG-Mercedes C-Klasse DTM 1995',
	'Cupra TCR',
	'Audi RS 3 LMS',
	'Alfa Romeo Giulietta TCR',
	'Peugeot 308 TCR',
	'Porsche Cayman GT4 Clubsport',
	'AMG-Mercedes C-Klasse DTM 1995',
	'Volkswagen Golf GTI TCR',
	'Hyundai i30 N TCR',
	'Honda Civic TCR',
	'Lotus Evora GT4',
	'AMG-Mercedes CLK DTM',
	'AMG-Mercedes C-Klasse DTM',
	'Formula RR 90 V8',
	'Formula RR 90 V12',
	'Formula RR 90 V10',
	'Porsche 934 Turbo RSR',
	'Porsche 911 Carrera Cup (964)',
	'Porsche 962 C Team Joest',
	'BMW M6 GT3 GTM18',
	'Audi R8 LMS GTM18',
	'Callaway Corvette C7 GT3-R GTM18',
	'Mercedes AMG GT3 GTM18',
	'Porsche 911 GT3 R GTM18',
	'BMW M1 Gr. 4',
	'Volkswagen ID. R',
	'Alfa Romeo Giulietta TCR',
	'Audi RS 3 LMS',
	'Cupra TCR',
	'Honda Civic TCR',
	'Hyundai i30 N TCR',
	'Volkswagen Golf GTI TCR',
	'Lynk & Co 03 TCR',
	'Porsche 911 GT3 Cup',
	'Cupra TCR',
	'BMW M4 GT4',
	'E36 V8 JUDD',
	'Opel Astra',
	'Porsche 911 GT3 Cup',
	'Porsche 718 Cayman GT4 Clubsport',
	'Porsche 911 GT2 RS Clubsport',
	'Porsche 911 GT3 R (2019)',
	'Honda Civic Type R',
	'Porsche 911 RSR 2019',
	'Volkswagen Scirocco Gr2',
	'Bentley Continental GT3 EVO',
	'Audi R8 LMS GT3 EVO',
	'Audi R8 LMS GT2 2019',
	'Audi RS 3 LMS',
	'Hyundai i30 N TCR',
	'Shopping Cart',
	'Lada Vesta',
	'Hyundai i30 N TCR',
	'Lynk & Co 03 TCR',
	'CUPRA Leon Competición',
	'CUPRA Leon e-Racer',
	'Honda Civic TCR',
	'Alfa Romeo Giulietta TCR',
	'Peugeot 308',
	'CUPRA Leon Competición',
	'Porsche 911 GT3 Cup',
	'Audi R8 LMS GT4 2020',
	'BMW M6 GT3',
	'Porsche 911 GT3 R (2019)',
	'Mercedes AMG GT3',
	'Callaway Corvette C7 GT3-R',
	'Audi R8 LMS',
	'BMW M4 DTM 2020e',
	'Audi RS 5 DTM 2020e',
	'Lynk & Co 03 TCR',
	'Audi RS 5 DTM 2020',
	'BMW M4 DTM 2020',
	'Hyundai i30 N TCR',
	'Lynk & Co 03 TCR',
	'Alfa Romeo Giulietta TCR',
	'Audi RS 3 LMS',
	'Honda Civic TCR',
	'Cupra Leon Competición',
	'Renault Mégane TCR',
	'Audi R8 LMS GT3 EVO',
	'Bentley Continental GT3 EVO',
	'Mercedes AMG GT3 Evo',
	'Mercedes AMG GT3 Evo',
	'Mercedes AMG GT4 2020',
	'Audi R8 LMS GT3 EVO',
	'BMW M6 GT3',
	'Bentley Continental GT3 EVO',
	'Mercedes AMG GT3 Evo',
	'Porsche 911 GT3 R (2019)',
	'Callaway Corvette C7 GT3-R'
];

export const carIds = [
	252,
	258,
	1686,
	1694,
	1695,
	1700,
	1733,
	1738,
	1741,
	1744,
	1747,
	1750,
	1753,
	1756,
	1759,
	1779,
	1782,
	1788,
	1794,
	1797,
	1800,
	1803,
	1807,
	1811,
	1815,
	1818,
	1821,
	1824,
	1827,
	1831,
	1834,
	1837,
	1862,
	1909,
	2037,
	2044,
	2116,
	2275,
	2333,
	2338,
	2339,
	2405,
	2409,
	2410,
	2413,
	2417,
	2420,
	2533,
	2611,
	2705,
	2728,
	2804,
	2842,
	2846,
	2849,
	2859,
	2868,
	2923,
	2924,
	2925,
	2926,
	2927,
	3380,
	3384,
	3408,
	3479,
	3500,
	3508,
	3516,
	3527,
	3530,
	3534,
	3539,
	3549,
	3626,
	3662,
	3754,
	3842,
	3874,
	3899,
	3964,
	4046,
	4067,
	4075,
	4114,
	4145,
	4197,
	4261,
	4264,
	4267,
	4299,
	4308,
	4386,
	4518,
	4544,
	4551,
	4554,
	4557,
	4570,
	4579,
	4582,
	4585,
	4598,
	4675,
	4681,
	4720,
	4810,
	4865,
	5051,
	5152,
	5170,
	5214,
	5259,
	5342,
	5348,
	5396,
	5399,
	5402,
	5411,
	5588,
	5767,
	5786,
	5818,
	5821,
	6002,
	6011,
	6017,
	6024,
	6031,
	6057,
	6168,
	6174,
	6177,
	6187,
	6195,
	6203,
	6208,
	6262,
	6310,
	6314,
	6321,
	6329,
	6334,
	6349,
	6568,
	6588,
	6623,
	6791,
	6849,
	6860,
	6949,
	6978,
	7005,
	7011,
	7029,
	7036,
	7040,
	7076,
	7105,
	7117,
	7125,
	7162,
	7169,
	7183,
	7215,
	7218,
	7220,
	7282,
	7396,
	7399,
	7629,
	7630,
	7640,
	7643,
	7647,
	7805,
	7810,
	7845,
	7847,
	7850,
	7853,
	7856,
	7859,
	7926,
	7983,
	8006,
	8053,
	8115,
	8149,
	8166,
	8201,
	8242,
	8257,
	8467,
	8487,
	8498,
	8593,
	8604,
	8652,
	8657,
	8661,
	8677,
	8698,
	8715,
	8719,
	8726,
	8757,
	8776,
	8782,
	8785,
	8788,
	8792,
	8900,
	8936,
	8938,
	8940,
	8942,
	9031,
	9094,
	9102,
	9154,
	9206,
	9220,
	9234,
	9241,
	9252,
	9256,
	9266,
	9278,
	9467,
	9548,
	9565,
	9581,
	9603,
	9644,
	10051,
	10076,
	10085,
	10110,
	10119,
	10127
];

export const carClassNames = [
	'FRJ Cup',
	'Aquila CR1 Cup',
	'Hillclimb Icons',
	'GTR 1',
	'GTR 3',
	'GTR 2',
	'German Nationals',
	'Group 5',
	'Touring Classics',
	'GTO Classics',
	'P1',
	'Silhouette Series',
	'DTM 2013',
	'WTCC 2013',
	'P2',
	'Procar',
	'ADAC GT Masters 2013',
	'DTM 2014',
	'ADAC GT Masters 2014',
	'DTM 1992',
	'WTCC 2014',
	'Group C',
	'DTM 2015',
	'ADAC GT Masters 2015',
	'WTCC 2015',
	'FR2 Cup',
	'Audi Sport TT Cup 2015',
	'NSU TTS Cup',
	'Tatuus F4 Cup',
	'Audi TT RS cup',
	'DTM 2016',
	'FR US Cup',
	'KTM X-Bow RR Cup',
	'Mercedes-AMG Motorsport',
	'FR3 Cup',
	'Audi Sport TT Cup 2016',
	'FR X-17 Cup',
	'GTR 4',
	'WTCC 2016',
	'RaceRoom Esports',
	'WTCC 2017',
	'BMW M235i Racing Cup',
	'Porsche 911 GT3 Cup',
	'Cayman GT4 Trophy by Manthey-Racing',
	'Esports WTCR Prologue',
	'Mercedes-AMG Motorsport 30 Years of DTM',
	'WTCR 2018',
	'Super Racer',
	'C-Klasse DTM 1995',
	'Zonda R Cup',
	'CLK DTM 2003',
	'C-Klasse DTM 2005',
	'FR X-90 Cup',
	'ADAC GT Masters 2018',
	'Porsche Carrera Cup Classic',
	'Group 4',
	'Volkswagen ID. R',
	'ADAC GT Masters 2020',
	'WTCR 2019',
	'Porsche Carrera Cup Deutschland 2019',
	'Porsche Esports Carrera Cup Scandinavia',
	'GT2',
	'Group 2',
	'GTE',
	'eSports WTCR',
	'Touring Cars Cup',
	'Shopping Cart',
	'Porsche Carrera Cup Deutschland 2020',
	'CUPRA Leon e-Racer',
	'DTM 2020 Esport',
	'DTM 2020',
	'WTCR 2020',
	'ADAC GT Masters 2021'
];

export const carClassNameIDS = [
	253,
	255,
	1685,
	1687,
	1703,
	1704,
	1706,
	1708,
	1712,
	1713,
	1714,
	1717,
	1921,
	1922,
	1923,
	2378,
	2922,
	3086,
	3375,
	3499,
	3905,
	4121,
	4260,
	4516,
	4517,
	4597,
	4680,
	4813,
	4867,
	5234,
	5262,
	5383,
	5385,
	5634,
	5652,
	5726,
	5824,
	5825,
	6036,
	6172,
	6309,
	6344,
	6345,
	6648,
	6783,
	6794,
	7009,
	7041,
	7075,
	7110,
	7167,
	7168,
	7214,
	7278,
	7287,
	7304,
	7765,
	7767,
	7844,
	7982,
	8165,
	8248,
	8483,
	8600,
	8656,
	8660,
	8680,
	8681,
	8682,
	9101,
	9205,
	9233,
	10049
];

export default function getCarName(modelID: number) {
	const indexNum = carIds.indexOf(modelID);
	return carNames[indexNum];
}

export function getCarClassName(classID: number) {
	const indexNum = carClassNameIDS.indexOf(classID);
	return carClassNames[indexNum];
}
